import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";
import GridContainer from "../../components/Grid/GridContainer";
import { Button } from "@material-ui/core";
import { getDatabase, onValue, ref, set } from "firebase/database";
import { useEffect, useState } from "react";
import { collection, onSnapshot, getDocs } from "firebase/firestore";
import db from "../../api/firebase";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { REGIONS } from "../../assets/data";
import { LANGUAGES } from "../../assets/data/Languages";
import { getAuth } from "firebase/auth";

// const getTotalMemberCount = async () => {
//   try {
//     const membersCollection = collection(db, "members");
//     const membersSnapshot = await getDocs(membersCollection);
//     const totalMemberCount = membersSnapshot.size; // Total number of documents in the collection
//     console.log(`Total members: ${totalMemberCount}`);
//     return totalMemberCount;
//   } catch (error) {
//     console.error("Error getting total member count: ", error);
//   }
// };

export default function TargetsPage(props) {
  const { ...rest } = props;
  // const [members, setMembers] = useState();
  const [total, setTotal] = useState(0);
  const [totalMembers, setTotalMembers] = useState(0);
  const [totalGS, setTotalGS] = useState(0);
  const [TotalINV, setTotalINV] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [regionsData, setRegionsData] = useState([]);
  const rtdb = getDatabase();
  const auth = getAuth();

  // const [totalMemberCount, setTotalMemberCount] = useState(0);

  // useEffect(() => {
  //   const fetchMemberCount = async () => {
  //     try {
  //       const membersCollection = collection(db, "members");
  //       const membersSnapshot = await getDocs(membersCollection);
  //       setTotalMemberCount(membersSnapshot.size); // Set the total number of documents in the collection
  //     } catch (error) {
  //       console.error("Error getting total member count: ", error);
  //     }
  //   };

  //   fetchMemberCount();
  // }, []);  
  // const getTotalCountRealtime = (collectionName, setTotalCount) => {
  //   return onSnapshot(collection(db, collectionName), (snapshot) => {
  //     console.log(snapshot)
  //     setTotalCount(snapshot.size);
  //   }, (error) => {
  //     console.error("Error fetching document count: ", error);
  //     setTotalCount(0); // Handle the error case
  //   });
  // };
  
  // useEffect(() => {
  //   const unsubscribe = getTotalCountRealtime('members', setTotalMembers);
  //   return () => unsubscribe(); // Cleanup on unmount
  // }, []);
  
  useEffect(() => {

    const countRef = ref(rtdb, "regions");
    setIsLoading(true);
    onValue(countRef, (snapshot) => {
      let regionsArray = [];
      const data = snapshot.val();

      console.log(data)

      // GOOD STANDING = ACTIVE
      // BAD STANDING = TOTAL - GOOD_STANDING

      REGIONS.forEach((region) => {
        const selectedRegion = region.Name;
        if (selectedRegion === "ZF MAQCAWU/SIYANDA") {
          const zfdata = data["ZF MAQCAWU"].SIYANDA;
          regionsArray = [...regionsArray, { ...zfdata, id: zfdata.regionId }];
          setRegionsData(regionsArray);
        } else if (data[selectedRegion]) {
          regionsArray = [
            ...regionsArray,
            { ...data[selectedRegion], id: data[selectedRegion].regionId },
          ];
          setRegionsData(regionsArray);
        }
        setIsLoading(false);
      });
    });
  }, [rtdb]);

  useEffect(() => {
    let count = 0;
    let gsCount = 0;
    let invCount = 0;
    regionsData.forEach((region) => {
      count = count + region.total;
      gsCount = gsCount + region.goodStanding;
      invCount = invCount + (region.total - region.goodStanding)
    });
    setTotal(count);
    setTotalGS(gsCount);
    setTotalINV(invCount);
  }, [regionsData]);
  
  const authState = useSelector((state) => state);
  
  let display_total = TotalINV + totalGS
  let today = new Date();
  
  if (auth.currentUser && auth.currentUser === null) return <Navigate to="/" />;
  
  
  return (
    <div>
      <Header
        absolute
        color="dark"
        brand="Membership Administration"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <GridContainer
        style={{
          height: "90vh",
          width: "90%",
          marginRight: 40,
          marginLeft: 40,
          display: "flex",
        }}
        justify="center"
      >
        
        {
          display_total > 0 
          ? <h3
            style={{
              marginTop: "20%",
              textAlign: "center", 
              fontSize: "60px",
            }}
          >
              <span style={{ textTransform: "uppercase", fontSize: "20px" }}>Total members</span> <br />
              <span style={{ color: "green" }}>{display_total.toLocaleString()}</span> / <span style={{ color: "black" }}>1,000,000</span>
          </h3>
          : <p>LOADING...</p>
        }

      </GridContainer>
      <Footer />
    </div>
  );
}
