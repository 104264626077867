import { View, Text, Image, Document, Page } from "@react-pdf/renderer";
import { Fragment } from "react";
import { styles } from "./styles";
import logo from "../../assets/images/eff-logo.jpeg";
import moment from "moment";

const TableRow = ({ items }) => {
  console.log("Items: ", items);
  const rows = items.map((item, index) => {
    return (
      <View style={styles.dataRow} key={index} wrap={false}>
        <Text style={{ ...styles.dataColumn, width: "10%" }}>{index + 1}</Text>
        <Text style={styles.dataColumn}>{item.name}</Text>
        <Text style={styles.dataColumn}>{item.wardNumber}</Text>
        <Text style={styles.dataColumn}>{item.idNumber}</Text>
        <Text style={styles.dataColumn}>{item.cellNumber}</Text>
        <Text style={styles.dataColumn}>{item.status}</Text>
        <Text style={styles.dataColumn}> </Text>
        <Text style={styles.dataColumnLast}> </Text>
      </View>
    );
  });
  return <Fragment>{rows}</Fragment>;
};

const TableHeader = () => {
  return (
    <View fixed style={styles.row}>
      <Text style={{ ...styles.column, width: "10%" }}>NUMBER</Text>
      <Text style={styles.column}>NAME</Text>
      <Text style={styles.column}>WARD NUMBER</Text>
      <Text style={styles.column}>ID NUMBER</Text>
      <Text style={styles.column}>CELL NUMBER</Text>
      <Text style={styles.column}>STATUS</Text>
      <Text style={styles.column}>SIGNATURE</Text>
      <Text style={styles.columnLast}>NEW CELL NUMBER</Text>
    </View>
  );
};

// const ItemsTable = ({ data }) => {
//   console.log("Items table items: ", data);
//   return (
//     <View style={styles.tableContainer}>
//       <TableHeader />
//       <TableRow items={data} />
//       {/*<TableFooter items={data.items} />*/}
//     </View>
//   );
// };

const Template = ({ data }) => {
  console.log("data status: ", data);
  if (!data) {
    console.log("Data is null bro");
    return;
  }
  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.page}>
        {data.items.length > 0 ? (
          <View>
            <View style={styles.headerView}>
              <Text style={styles.heading}>FORM A: ATTENDANCE REGISTER</Text>
              <View>
                <Image style={styles.image} src={logo} />
              </View>
            </View>

            <View
              style={{ ...styles.infoView, justifyContent: "space-between" }}
            >
              <Text style={styles.infoText}>PROVINCE: {data.province}</Text>
              <Text style={styles.infoText}>REGION: {data.region}</Text>
              <Text style={styles.infoText}>SUB-REGION: {data.subRegion}</Text>
              <Text style={styles.infoText}>WARD: {data.ward}</Text>
            </View>

            <View style={styles.infoView}>
              <Text style={styles.infoText}>
                TOTAL MEMBERSHIP IN GOOD STANDING: {data.totalMembers}
              </Text>
            </View>

            <View
              style={{ ...styles.infoView, justifyContent: "space-between" }}
            >
              <Text style={styles.infoText}>
                QUORUM: {(data.totalMembers / 2 + 1).toFixed(0)}
              </Text>
              <Text style={styles.infoText}>BPA|_| BGA|_|</Text>
              <Text style={styles.infoText}>
                DATE OF BPA/BGA:_________________
              </Text>
            </View>

            <View style={styles.infoView}>
              <Text style={styles.infoText}>
                The quorum is derived from the membership in Good Standing.
              </Text>
            </View>
            {/* <ItemsTable data={data.items} /> */}
            <TableHeader />
            <TableRow items={data.items} />
          </View>
        ) : (
          <View></View>
        )}
      </Page>
    </Document>
  );
};

export default Template;
