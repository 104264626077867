import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { collection, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import GridContainer from "../../components/Grid/GridContainer";
import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";
import db from "../../api/firebase";
import { getDatabase, onValue, ref, set } from "firebase/database";
import { Box, Button, Modal, Typography } from "@mui/material";
import { TextField } from "@material-ui/core";
import moment from "moment";
import { alpha, styled } from "@mui/material/styles";
import { renewMember } from "../../api/firestoreContext";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { getAuth } from "firebase/auth";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.true`]: {
    backgroundColor: theme.palette.error.light,
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

export default function ListMembersPage(props) {
  const [members, setMembers] = useState();
  const [open, setOpen] = useState(false);
  const [currentMember, setCurrentMember] = useState();
  const [numberOfMembers, setNumberOfMembers] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const rtdb = getDatabase();
  const { ...rest } = props;
  const auth = getAuth();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (!members) {
      return;
    }
    const validMembers = members?.filter((mem) => mem.valid === true);
    set(ref(rtdb, "members"), {
      valid: validMembers.length,
      invalid: members.length - validMembers.length,
    }).catch();
  }, [rtdb, members]);

  useEffect(() => {
    const countRef = ref(rtdb, "members");
    onValue(countRef, (snapshot) => {
      const data = snapshot.val();
      setNumberOfMembers(
        data.filter(
          (item) => moment().format() < moment(item.expiryDate).format()
        ).length
      );
    });
  }, [rtdb]);

  const editModal = () => {
    setOpen(true);
  };

  // const renewMember = async () => {
  //   const cityRef = doc(db, "members", currentMember.idNumber);
  //   setDoc(
  //     cityRef,
  //     {
  //       lastPayment: moment(currentMember.lastPayment).format(),
  //       expiryDate: moment(currentMember.lastPayment)
  //         .add(2, "years")
  //         .format(),
  //     },
  //     { merge: true }
  //   );
  // };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
    },
    {
      field: "surname",
      headerName: "Surname",
      width: 150,
    },
    { field: "id", headerName: "RSA ID", width: 150 },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      width: 110,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 150,
    },
    {
      field: "dateJoined",
      headerName: "Date Joined",
      width: 150,
    },
    {
      field: "lastPayment",
      headerName: "Pmnt Received",
      width: 150,
      editable: true,
      type: "date",
    },
    {
      field: "membershipAmount",
      headerName: "Amount",
      width: 150,
    },
    {
      field: "expiryDate",
      headerName: "Expire On",
      width: 150,
      type: "date",
    },
  ];

  // useEffect(() => {
  //   onSnapshot(collection(db, "members"), (snapshot) =>
  //     setMembers(
  //       snapshot.docs.map((doc) => ({
  //         ...doc.data(),
  //         id: doc.data().idNumber,
  //         region: doc.data().region ? doc.data().region : doc.data().regionId,
  //         name: doc.data().name ? doc.data().name : doc.data().firstName,
  //         membershipAmount:
  //           doc.data().membershipAmount || doc.data().regionId
  //             ? "R10.00"
  //             : "R0.00",
  //         valid: doc.data().memeberStatus
  //           ? doc.data().memeberStatus.trim() === "Good Standing"
  //           : true,
  //       }))
  //     )
  //   );
  // }, []);

  const authState = useSelector((state) => state);

  if (auth.currentUser && auth.currentUser === null) return <Navigate to="/" />;

  return (
    <div>
      <Header
        absolute
        color="dark"
        brand="Membership Administration"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <GridContainer
        style={{
          height: 600,
          width: "90%",
          marginTop: 80,
          marginRight: 40,
          marginLeft: 40,
          display: "flex",
          flexDirection: "column",
        }}
        justify="center"
      >
        <h1>Members List</h1>
        <div>
          <div style={{ display: "flex", flexDirection: "row", margin: 0 }}>
            <h4 style={{ margin: 0 }}>Number of Members: </h4>
            <h4 style={{ marginTop: 0, marginBottom: 2, marginLeft: 10 }}>
              {numberOfMembers}
            </h4>
          </div>
          <div style={{ display: "flex", flexDirection: "row", margin: 0 }}>
            <h4 style={{ margin: 0 }}>Progress:</h4>
            <h4 style={{ marginTop: 0, marginBottom: 10, marginLeft: 10 }}>
              {(numberOfMembers / 1000000) * 100} %
            </h4>
          </div>
        </div>
        {/* <DataGrid
          rows={members}
          columns={columns}
          pageSize={30}
          rowsPerPageOptions={[5]}
          //   checkboxSelection
          disableSelectionOnClick
          onRowClick={(data) => {
            console.log("data: ", data);
            console.log(
              "NEW DATE: ",
              moment(data.row.lastPayment).add(2, "years").calendar()
            );
            setCurrentMember(data.row);
            editModal();
          }}
        /> */}
        <StripedDataGrid
          loading={isLoading}
          rows={members}
          columns={columns}
          pageSize={150}
          paginationMode="client"
          onPageChange={(prop) => {}}
          disableSelectionOnClick
          getRowClassName={(params) =>
            moment().format() > moment(params.row.expiryDate).format()
              ? "true"
              : "false"
          }
          onRowClick={(data) => {
            setCurrentMember(data.row);
            editModal();
          }}
        />
      </GridContainer>
      <Footer />
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Member
          </Typography>

          <TextField
            id="lastPaymentRecieved"
            label="Last Payment Received"
            type="date"
            fullWidth
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            value={currentMember?.lastPayment}
            onChange={(value) =>
              setCurrentMember({
                ...currentMember,
                lastPayment: value.target.value,
              })
            }
            style={{ marginTop: 10 }}
          />
          <Button
            // simple
            style={{
              color: "black",
              backgroundColor: "#a50100",
              marginTop: 20,
            }}
            // size="lg"
            onClick={() => {
              renewMember(
                currentMember.idNumber,
                currentMember.lastPayment,
                false
              )
                .then(() => {
                  alert("Member has been edited!");
                  setOpen(false);
                })
                .catch((err) => {
                  console.log("error: ", err);
                });
            }}
            fullWidth
          >
            <h4 style={{ margin: 0, color: "white" }}>Submit</h4>
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
