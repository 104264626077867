import moment from "moment";
import { deleteMember, editMember, renewMember } from "./firestoreContext";

const dateCheck = (idDate) => {
  const dateStr = `${idDate.substr(0, 2)}-${idDate.substr(
    2,
    2
  )}-${idDate.substr(4, 2)}`;

  const regex = /^\d{2}-\d{2}-\d{2}$/;

  const dateString = dateStr.trim();
  if (dateString.match(regex) === null) {
    return false;
  }

  const date = new Date(dateString);

  const timestamp = date.getTime();
  if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
    return false;
  }
  return true;
};

export const getAge = (idNumber) => {
  const idDate = idNumber.trim().substr(0, 6);

  const dateStr = `${idDate.substr(2, 2)}/${idDate.substr(
    4,
    2
  )}/${idDate.substr(0, 2)}`;

  const dateString = dateStr.trim();

  var dob = new Date(dateString);
  //calculate month difference from current date in time
  var month_diff = Date.now() - dob.getTime();

  //convert the calculated difference in date format
  var age_dt = new Date(month_diff);
  //extract year from date
  var year = age_dt.getUTCFullYear();

  //now calculate the age of the user
  var age = Math.abs(year - 1970);

  if (/^\d+$/.test(age)) {
    return age;
  }

  //display the calculated age
  // deleteMember(idNumber.trim());
  return "";
};

export const getGender = (idGender) => {
  if (parseInt(idGender) <= 4999) {
    return "Female";
  } else if (parseInt(idGender) > 4999) {
    return "Male";
  } else return "";
};
const citizenshipStatusCheck = (statusStr) => {
  const valid = statusStr === "0" || statusStr === "1";
  return valid;
};

var luhn = {
  /* Calculates the Luhn checksum */
  calculate: function (digits) {
    var sum = this.sum(digits, false);
    return (sum * 9) % 10;
  },

  /* Verifies if a number is a valid Luhn checksum */
  verify: function (digits) {
    var sum = this.sum(digits, true);
    return sum > 0 && sum % 10 === 0;
  },

  /* Sum each digit from right to left, and double
       every second digit. If the double exceeds 9,
       then sum its digits (i.e., 654321 -> 358341
       -> 24) */
  sum: function (digits, even) {
    var sum = 0;
    var digit = 0;
    var i = digits.length;
    var varEven = even;

    while (i--) {
      digit = Number(digits[i]);
      varEven = !varEven;
      sum += varEven ? this.computed[digit] : digit;
    }

    return sum;
  },

  /* Create a precomputed list based on doubling
       each digit, as described in sum(). */
  computed: [0, 2, 4, 6, 8, 1, 3, 5, 7, 9],
};

var helpers = {
  /* Appends a Luhn checksum to the end of a number */
  createLuhnId: function (number) {
    var digits = String(number);
    return digits + luhn.calculate(digits);
  },

  /* Checks if a credit card or IMEI number is valid */
  isLuhnId: function (number) {
    return luhn.verify(String(number));
  },
};

export const idCheck = (idNumber) => {
  if (idNumber === undefined) {
    const valid = false;
    return valid;
  }
  const valid =
    idNumber.length === 13 &&
    dateCheck(idNumber.substr(0, 6)) &&
    citizenshipStatusCheck(idNumber.substr(10, 1)) &&
    helpers.isLuhnId(idNumber);

  return valid;
};

export const getLastPayment = (docData) => {
  let lastPayment = docData.lastPayment
    ? docData.lastPayment
    : docData.lastMembershipPaymentReceived;

  if (lastPayment === "Invalid date") {
    const newDate =
      docData.dateJoined === "Invalid date"
        ? moment("01/29/2021").format()
        : docData.dateJoined;

    renewMember(docData.idNumber, newDate, true)
      .then((result) => {
        lastPayment = newDate;
      })
      .catch((error) => {
        console.log("EDIT ERROR: ", error);
      });
    return newDate;
  }
  if (
    lastPayment.includes("Today") |
    (moment(lastPayment).format() < moment("01/01/2013").format())
  ) {
    if (docData.expiryDate) {
      const lastPaymnetDate = moment(docData.expiryDate)
        .subtract(2, "years")
        .format();
      renewMember(docData.idNumber, lastPaymnetDate, true)
        .then((result) => {
          console.log("FIXED DATE: ", result);
          return lastPaymnetDate;
        })
        .catch((error) => {
          console.log("EDIT ERROR: ", error);
        });
    } else {
      const lastPaymnetDatePlaceHolder = moment(
        "Jul 5, 2022 5:56:55 PM"
      ).format();

      renewMember(docData.idNumber, lastPaymnetDatePlaceHolder, true)
        .then((result) => {
          return lastPaymnetDatePlaceHolder;
        })
        .catch((error) => {
          console.log("EDIT ERROR: ", error);
        });
    }
    // editMember({ ...docData, lastPayment: "Jun 29, 2022 9:55:12 AM" });
  }

  return lastPayment;
};

export const getDateJoined = (docData) => {
  editMember({
    ...docData,
    dateJoined: moment("01/29/2021").format(),
  })
    .then((result) => {
      return moment("01/29/2021").format();
    })
    .catch((error) => {
      console.log("EDIT ERROR: ", error);
    });
  return moment("01/29/2021").format();
};
