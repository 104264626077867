const REGIONS = [
  {
    RegionId: "1",
    Name: "NELSON MANDELA",
    ProvinceId: "6",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "2",
    Name: "BUFFALO CITY",
    ProvinceId: "6",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "3",
    Name: "AMATHOLE",
    ProvinceId: "6",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "4",
    Name: "CACADU",
    ProvinceId: "6",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "5",
    Name: "ALFRED NZO",
    ProvinceId: "6",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "6",
    Name: "OR TAMBO",
    ProvinceId: "6",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "7",
    Name: "JOE GQABI",
    ProvinceId: "6",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "8",
    Name: "CHRIS HANI",
    ProvinceId: "6",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "9",
    Name: "FEZILE DABI",
    ProvinceId: "2",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "10",
    Name: "LEJWELEPUTSWA",
    ProvinceId: "2",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "11",
    Name: "MANGAUNG",
    ProvinceId: "2",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "12",
    Name: "THABO MOFUTSANYANA",
    ProvinceId: "2",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "13",
    Name: "XHARIEP",
    ProvinceId: "2",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "14",
    Name: "JOHANNESBURG",
    ProvinceId: "1",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "15",
    Name: "TSHWANE",
    ProvinceId: "1",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "16",
    Name: "EKURHULENI",
    ProvinceId: "1",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "17",
    Name: "SEDIBENG",
    ProvinceId: "1",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "18",
    Name: "WEST RAND",
    ProvinceId: "1",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "19",
    Name: "ETHEKWINI",
    ProvinceId: "5",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "20",
    Name: "UMGUNGUNDLOVU",
    ProvinceId: "5",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "21",
    Name: "KING CETSHWAYO",
    ProvinceId: "5",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: "DATARQ01",
    Audit_ModifiedOn: "2021-06-26 11:09:46",
  },
  {
    RegionId: "22",
    Name: "HARRY GWALA",
    ProvinceId: "5",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: "DATARQ01",
    Audit_ModifiedOn: "2021-06-26 11:06:16",
  },
  {
    RegionId: "23",
    Name: "UTHUKELA",
    ProvinceId: "5",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "24",
    Name: "UGU",
    ProvinceId: "5",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "25",
    Name: "UMZINYATHI",
    ProvinceId: "5",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "26",
    Name: "UMKHANYAKUDE",
    ProvinceId: "5",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "27",
    Name: "ILEMBE",
    ProvinceId: "5",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "28",
    Name: "AMAJUBA",
    ProvinceId: "5",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "29",
    Name: "ZULULAND",
    ProvinceId: "5",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "30",
    Name: "CAPRICORN",
    ProvinceId: "3",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "31",
    Name: "WATERBERG",
    ProvinceId: "3",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "32",
    Name: "MOPANI",
    ProvinceId: "3",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "33",
    Name: "VHEMBE",
    ProvinceId: "3",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "34",
    Name: "SEKHUKHUNE",
    ProvinceId: "3",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "35",
    Name: "NKANGALA",
    ProvinceId: "7",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "36",
    Name: "EHLANZENI",
    ProvinceId: "7",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "37",
    Name: "BOHLABELO",
    ProvinceId: "7",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "38",
    Name: "GERT SIBANDE",
    ProvinceId: "7",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "39",
    Name: "BOJANALA",
    ProvinceId: "4",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "40",
    Name: "NGAKA MODIRI",
    ProvinceId: "4",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "41",
    Name: "DR KENNETH KAUNDA",
    ProvinceId: "4",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "42",
    Name: "DR RUTH SEGOMOTSI MOMPATI",
    ProvinceId: "4",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "43",
    Name: "JOHN TAOLO GAETSEWE",
    ProvinceId: "8",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "44",
    Name: "FRANCIS BAARD",
    ProvinceId: "8",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "45",
    Name: "PIXELY KA SEME",
    ProvinceId: "8",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "46",
    Name: "ZF MAQCAWU/SIYANDA",
    ProvinceId: "8",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "47",
    Name: "NAMAKWA",
    ProvinceId: "8",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "48",
    Name: "CAPE METRO",
    ProvinceId: "9",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "49",
    Name: "CAPE WINELANDS",
    ProvinceId: "9",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "50",
    Name: "WEST COAST",
    ProvinceId: "9",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "51",
    Name: "OVERBERG",
    ProvinceId: "9",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "52",
    Name: "CENTRAL KAROO",
    ProvinceId: "9",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
  {
    RegionId: "53",
    Name: "SOUTHERN CAPE",
    ProvinceId: "9",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2020-02-10 05:24:20",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2020-02-10 05:24:20",
  },
];

const convertToMap = (array) => {
  const mapInit = {};
  if (Object.keys(mapInit).length === 0) {
    array.forEach((item) => {
      mapInit[item.RegionId] = item.Name.trim();
    });
  }
  return mapInit;
};

const convertToID = (array) => {
  const mapInit = {};
  if (Object.keys(mapInit).length === 0) {
    array.forEach((item) => {
      mapInit[item.Name.toLocaleLowerCase().trim()] = item.RegionId;
    });
  }
  return mapInit;
};

export const getRegions = () => {
  const regions = REGIONS;
  const regionMap = convertToMap(REGIONS);
  const regionIdMap = convertToID(REGIONS);

  return { regions, regionMap, regionIdMap };
};

export default REGIONS;
