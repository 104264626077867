const initState = {
  authError: null,
  user: null,
  studentLoggedIn: false,
  adminLoggedIn: false,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS_ADMIN":
      return {
        ...state,
        authError: null,
        adminLoggedIn: true,
      };
    case "LOGIN_ERROR":
      if (action.err.message === "Firebase: Error (auth/user-not-found).") {
        return {
          ...state,
          authError: "User was not found",
        };
      }

      if (action.err.message === "Firebase: Error (auth/wrong-password).") {
        return {
          ...state,
          authError: "The password is wrong",
        };
      }
      if (
        action.err.message === "Firebase: Error (auth/network-request-failed)."
      ) {
        return {
          ...state,
          authError: "Connection error",
        };
      }
      return {
        ...state,
        authError: action.err.message,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        authError: null,
        studentLoggedIn: true,
        user: action.userEmail,
      };
    case "SIGNOUT_SUCCESS":
      return {
        authError: null,
        user: {},
        userCourses: [],
        userAmount: 0,
        profiles: [],
        studentLoggedIn: false,
        adminLoggedIn: false,
      };
    case "SIGNIP_SUCCESS":
      return {
        ...state,
        authError: null,
      };
    case "SIGNUP_ERROR":
      return {
        ...state,
        authError: action.err.message,
      };
    case "START_SIGNUP":
      return { ...state, user: action.userInfo };

    case "LOAD_USERS":
      return { ...state, profiles: action.allProfiles };
    case "SELECT_COURSES":
      return {
        ...state,
        userCourses: action.chosenCourses,
        userAmount: action.total,
      };
    default:
      return state;
  }
};

export default authReducer;
