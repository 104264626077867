export const columns = [
  {
    field: "province",
    headerName: "Province",
    width: 150,
  },
  {
    field: "distribution",
    headerName: "Valid Members",
    width: 150,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 150,
  },
];

export const regionColumns = [
  {
    field: "region",
    headerName: "Region",
    width: 200,
  },
  {
    field: "goodStanding",
    headerName: "Valid Members",
    width: 150,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 150,
  },
];

export const municipalityColumns = [
  {
    field: "municipality",
    headerName: "Municipality",
    width: 200,
  },
  {
    field: "goodStanding",
    headerName: "Valid Members",
    width: 150,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 150,
  },
];

export const wardColumns = [
  {
    field: "ward",
    headerName: "Ward",
    width: 200,
  },
  {
    field: "goodStanding",
    headerName: "Valid Members",
    width: 150,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 150,
  },
];

// GENDER

export const genderColumns = [
  {
    field: "province",
    headerName: "Province",
    width: 150,
  },
  {
    field: "maleGoodStanding",
    headerName: "Valid Male Members",
    width: 180,
  },
  {
    field: "maleTotal",
    headerName: "Total Male Members",
    width: 180,
  },
  {
    field: "femaleGoodStanding",
    headerName: "Valid Female Members",
    width: 180,
  },
  {
    field: "femaleTotal",
    headerName: "Total Female Members",
    width: 180,
  },
  {
    field: "totalGoodStanding",
    headerName: "Valid Members",
    width: 180,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 180,
  },
];

export const genderRegionColumns = [
  {
    field: "region",
    headerName: "Region",
    width: 200,
  },
  {
    field: "maleGoodStanding",
    headerName: "Valid Male Members",
    width: 180,
  },
  {
    field: "maleTotal",
    headerName: "Total Male Members",
    width: 180,
  },
  {
    field: "femaleGoodStanding",
    headerName: "Valid Female Members",
    width: 180,
  },
  {
    field: "femaleTotal",
    headerName: "Total Female Members",
    width: 180,
  },
  {
    field: "totalGoodStanding",
    headerName: "Valid Members",
    width: 180,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 180,
  },
];

export const genderMunicipalityColumns = [
  {
    field: "municipality",
    headerName: "Municipality",
    width: 200,
  },
  {
    field: "maleGoodStanding",
    headerName: "Valid Male Members",
    width: 180,
  },
  {
    field: "maleTotal",
    headerName: "Total Male Members",
    width: 180,
  },
  {
    field: "femaleGoodStanding",
    headerName: "Valid Female Members",
    width: 180,
  },
  {
    field: "femaleTotal",
    headerName: "Total Female Members",
    width: 180,
  },
  {
    field: "totalGoodStanding",
    headerName: "Valid Members",
    width: 180,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 180,
  },
];

export const genderWardColumns = [
  {
    field: "ward",
    headerName: "Ward",
    width: 200,
  },
  {
    field: "maleGoodStanding",
    headerName: "Valid Male Members",
    width: 180,
  },
  {
    field: "maleTotal",
    headerName: "Total Male Members",
    width: 180,
  },
  {
    field: "femaleGoodStanding",
    headerName: "Valid Female Members",
    width: 180,
  },
  {
    field: "femaleTotal",
    headerName: "Total Female Members",
    width: 180,
  },
  {
    field: "totalGoodStanding",
    headerName: "Valid Members",
    width: 180,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 180,
  },
];

// Race

export const raceColumns = [
  {
    field: "province",
    headerName: "Province",
    width: 150,
  },
  {
    field: "blackGoodStanding",
    headerName: "Valid Black Members",
    width: 180,
  },
  {
    field: "blackTotal",
    headerName: "Total Black Members",
    width: 180,
  },
  {
    field: "colouredGoodStanding",
    headerName: "Valid Coloured Members",
    width: 180,
  },
  {
    field: "colouredTotal",
    headerName: "Total Coloured Members",
    width: 180,
  },
  {
    field: "indianGoodStanding",
    headerName: "Valid Indian Members",
    width: 180,
  },
  {
    field: "indianTotal",
    headerName: "Total Indian Members",
    width: 180,
  },
  {
    field: "whiteGoodStanding",
    headerName: "Valid White Members",
    width: 180,
  },
  {
    field: "whiteTotal",
    headerName: "Total White Members",
    width: 180,
  },
  {
    field: "otherGoodStanding",
    headerName: "Valid Other Members",
    width: 180,
  },
  {
    field: "otherTotal",
    headerName: "Total Other Members",
    width: 180,
  },
  {
    field: "totalGoodStanding",
    headerName: "Valid Members",
    width: 180,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 180,
  },
];

export const raceRegionColumns = [
  {
    field: "region",
    headerName: "Region",
    width: 200,
  },
  {
    field: "blackGoodStanding",
    headerName: "Valid Black Members",
    width: 180,
  },
  {
    field: "blackTotal",
    headerName: "Total Black Members",
    width: 180,
  },
  {
    field: "colouredGoodStanding",
    headerName: "Valid Coloured Members",
    width: 180,
  },
  {
    field: "colouredTotal",
    headerName: "Total Coloured Members",
    width: 180,
  },
  {
    field: "indianGoodStanding",
    headerName: "Valid Indian Members",
    width: 180,
  },
  {
    field: "indianTotal",
    headerName: "Total Indian Members",
    width: 180,
  },
  {
    field: "whiteGoodStanding",
    headerName: "Valid White Members",
    width: 180,
  },
  {
    field: "whiteTotal",
    headerName: "Total White Members",
    width: 180,
  },
  {
    field: "otherGoodStanding",
    headerName: "Valid Other Members",
    width: 180,
  },
  {
    field: "otherTotal",
    headerName: "Total Other Members",
    width: 180,
  },
  {
    field: "totalGoodStanding",
    headerName: "Valid Members",
    width: 180,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 180,
  },
];

export const raceMunicipalityColumns = [
  {
    field: "municipality",
    headerName: "Municipality",
    width: 200,
  },
  {
    field: "blackGoodStanding",
    headerName: "Valid Black Members",
    width: 180,
  },
  {
    field: "blackTotal",
    headerName: "Total Black Members",
    width: 180,
  },
  {
    field: "colouredGoodStanding",
    headerName: "Valid Coloured Members",
    width: 180,
  },
  {
    field: "colouredTotal",
    headerName: "Total Coloured Members",
    width: 180,
  },
  {
    field: "indianGoodStanding",
    headerName: "Valid Indian Members",
    width: 180,
  },
  {
    field: "indianTotal",
    headerName: "Total Indian Members",
    width: 180,
  },
  {
    field: "whiteGoodStanding",
    headerName: "Valid White Members",
    width: 180,
  },
  {
    field: "whiteTotal",
    headerName: "Total White Members",
    width: 180,
  },
  {
    field: "otherGoodStanding",
    headerName: "Valid Other Members",
    width: 180,
  },
  {
    field: "otherTotal",
    headerName: "Total Other Members",
    width: 180,
  },
  {
    field: "totalGoodStanding",
    headerName: "Valid Members",
    width: 180,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 180,
  },
];

export const raceWardColumns = [
  {
    field: "ward",
    headerName: "Ward",
    width: 200,
  },
  {
    field: "blackGoodStanding",
    headerName: "Valid Black Members",
    width: 180,
  },
  {
    field: "blackTotal",
    headerName: "Total Black Members",
    width: 180,
  },
  {
    field: "colouredGoodStanding",
    headerName: "Valid Coloured Members",
    width: 180,
  },
  {
    field: "colouredTotal",
    headerName: "Total Coloured Members",
    width: 180,
  },
  {
    field: "indianGoodStanding",
    headerName: "Valid Indian Members",
    width: 180,
  },
  {
    field: "indianTotal",
    headerName: "Total Indian Members",
    width: 180,
  },
  {
    field: "whiteGoodStanding",
    headerName: "Valid White Members",
    width: 180,
  },
  {
    field: "whiteTotal",
    headerName: "Total White Members",
    width: 180,
  },
  {
    field: "otherGoodStanding",
    headerName: "Valid Other Members",
    width: 180,
  },
  {
    field: "otherTotal",
    headerName: "Total Other Members",
    width: 180,
  },
  {
    field: "totalGoodStanding",
    headerName: "Valid Members",
    width: 180,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 180,
  },
];

//////////////////////////////////////////////// Age
export const ageColumns = [
  {
    field: "province",
    headerName: "Province",
    width: 150,
  },
  {
    field: "below16GoodStanding",
    headerName: "Valid Members Under 16",
    width: 180,
  },
  {
    field: "below16Total",
    headerName: "Total Members Under 16",
    width: 180,
  },
  {
    field: "age16GoodStanding",
    headerName: "Valid Members Age 16",
    width: 180,
  },
  {
    field: "age16Total",
    headerName: "Total Members Age 16",
    width: 180,
  },
  {
    field: "age17GoodStanding",
    headerName: "Valid Members Age 17",
    width: 180,
  },
  {
    field: "age17Total",
    headerName: "Total Members Age 17",
    width: 180,
  },
  {
    field: "ages18to35GoodStanding",
    headerName: "Valid Members Ages 18-35",
    width: 180,
  },
  {
    field: "ages18to35Total",
    headerName: "Total Members Ages 18-35",
    width: 180,
  },
  {
    field: "ages36to49GoodStanding",
    headerName: "Valid Members Ages 36-49",
    width: 180,
  },
  {
    field: "ages36to49Total",
    headerName: "Total Members Ages 36-49",
    width: 180,
  },
  {
    field: "ages50to65GoodStanding",
    headerName: "Valid Members Ages 50-65",
    width: 180,
  },
  {
    field: "ages50to65Total",
    headerName: "Total Members Ages 50-65",
    width: 180,
  },
  {
    field: "over65GoodStanding",
    headerName: "Valid Members Over 65",
    width: 180,
  },
  {
    field: "over65Total",
    headerName: "Total Members Over 65",
    width: 180,
  },
  {
    field: "totalGoodStanding",
    headerName: "Valid Members",
    width: 180,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 180,
  },
];

export const ageRegionColumns = [
  {
    field: "region",
    headerName: "Region",
    width: 200,
  },
  {
    field: "below16GoodStanding",
    headerName: "Valid Members Under 16",
    width: 180,
  },
  {
    field: "below16Total",
    headerName: "Total Members Under 16",
    width: 180,
  },
  {
    field: "age16GoodStanding",
    headerName: "Valid Members Age 16",
    width: 180,
  },
  {
    field: "age16Total",
    headerName: "Total Members Age 16",
    width: 180,
  },
  {
    field: "age17GoodStanding",
    headerName: "Valid Members Age 17",
    width: 180,
  },
  {
    field: "age17Total",
    headerName: "Total Members Age 17",
    width: 180,
  },
  {
    field: "ages18to35GoodStanding",
    headerName: "Valid Members Ages 18-35",
    width: 180,
  },
  {
    field: "ages18to35Total",
    headerName: "Total Members Ages 18-35",
    width: 180,
  },
  {
    field: "ages36to49GoodStanding",
    headerName: "Valid Members Ages 36-49",
    width: 180,
  },
  {
    field: "ages36to49Total",
    headerName: "Total Members Ages 36-49",
    width: 180,
  },
  {
    field: "ages50to65GoodStanding",
    headerName: "Valid Members Ages 50-65",
    width: 180,
  },
  {
    field: "ages50to65Total",
    headerName: "Total Members Ages 50-65",
    width: 180,
  },
  {
    field: "over65GoodStanding",
    headerName: "Valid Members Over 65",
    width: 180,
  },
  {
    field: "over65Total",
    headerName: "Total Members Over 65",
    width: 180,
  },
  {
    field: "totalGoodStanding",
    headerName: "Valid Members",
    width: 180,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 180,
  },
];

export const ageMunicipalityColumns = [
  {
    field: "municipality",
    headerName: "Municipality",
    width: 200,
  },
  {
    field: "below16GoodStanding",
    headerName: "Valid Members Under 16",
    width: 180,
  },
  {
    field: "below16Total",
    headerName: "Total Members Under 16",
    width: 180,
  },
  {
    field: "age16GoodStanding",
    headerName: "Valid Members Age 16",
    width: 180,
  },
  {
    field: "age16Total",
    headerName: "Total Members Age 16",
    width: 180,
  },
  {
    field: "age17GoodStanding",
    headerName: "Valid Members Age 17",
    width: 180,
  },
  {
    field: "age17Total",
    headerName: "Total Members Age 17",
    width: 180,
  },
  {
    field: "ages18to35GoodStanding",
    headerName: "Valid Members Ages 18-35",
    width: 180,
  },
  {
    field: "ages18to35Total",
    headerName: "Total Members Ages 18-35",
    width: 180,
  },
  {
    field: "ages36to49GoodStanding",
    headerName: "Valid Members Ages 36-49",
    width: 180,
  },
  {
    field: "ages36to49Total",
    headerName: "Total Members Ages 36-49",
    width: 180,
  },
  {
    field: "ages50to65GoodStanding",
    headerName: "Valid Members Ages 50-65",
    width: 180,
  },
  {
    field: "ages50to65Total",
    headerName: "Total Members Ages 50-65",
    width: 180,
  },
  {
    field: "over65GoodStanding",
    headerName: "Valid Members Over 65",
    width: 180,
  },
  {
    field: "over65Total",
    headerName: "Total Members Over 65",
    width: 180,
  },
  {
    field: "totalGoodStanding",
    headerName: "Valid Members",
    width: 180,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 180,
  },
];

export const ageWardColumns = [
  {
    field: "ward",
    headerName: "Ward",
    width: 200,
  },
  {
    field: "below16GoodStanding",
    headerName: "Valid Members Under 16",
    width: 180,
  },
  {
    field: "below16Total",
    headerName: "Total Members Under 16",
    width: 180,
  },
  {
    field: "age16GoodStanding",
    headerName: "Valid Members Age 16",
    width: 180,
  },
  {
    field: "age16Total",
    headerName: "Total Members Age 16",
    width: 180,
  },
  {
    field: "age17GoodStanding",
    headerName: "Valid Members Age 17",
    width: 180,
  },
  {
    field: "age17Total",
    headerName: "Total Members Age 17",
    width: 180,
  },
  {
    field: "ages18to35GoodStanding",
    headerName: "Valid Members Ages 18-35",
    width: 180,
  },
  {
    field: "ages18to35Total",
    headerName: "Total Members Ages 18-35",
    width: 180,
  },
  {
    field: "ages36to49GoodStanding",
    headerName: "Valid Members Ages 36-49",
    width: 180,
  },
  {
    field: "ages36to49Total",
    headerName: "Total Members Ages 36-49",
    width: 180,
  },
  {
    field: "ages50to65GoodStanding",
    headerName: "Valid Members Ages 50-65",
    width: 180,
  },
  {
    field: "ages50to65Total",
    headerName: "Total Members Ages 50-65",
    width: 180,
  },
  {
    field: "over65GoodStanding",
    headerName: "Valid Members Over 65",
    width: 180,
  },
  {
    field: "over65Total",
    headerName: "Total Members Over 65",
    width: 180,
  },
  {
    field: "totalGoodStanding",
    headerName: "Valid Members",
    width: 180,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 180,
  },
];

// LANGUAGES

export const languageColumns = [
  {
    field: "province",
    headerName: "Province",
    width: 150,
  },
  {
    field: "zuluGoodStanding",
    headerName: "Valid Zulu Members",
    width: 180,
  },
  {
    field: "zuluTotal",
    headerName: "Total Zulu Members",
    width: 180,
  },
  {
    field: "ndebeleGoodStanding",
    headerName: "Valid Ndebele Members",
    width: 180,
  },
  {
    field: "ndebeleTotal",
    headerName: "Total Ndebele Members",
    width: 180,
  },
  {
    field: "northernSothoGoodStanding",
    headerName: "Valid Northern Sotho Members",
    width: 180,
  },
  {
    field: "northernSothoTotal",
    headerName: "Total Northern Sotho Members",
    width: 180,
  },
  {
    field: "sothoGoodStanding",
    headerName: "Valid Sotho Members",
    width: 180,
  },
  {
    field: "sothoTotal",
    headerName: "Total Sotho Members",
    width: 180,
  },
  {
    field: "swaziGoodStanding",
    headerName: "Valid Swazi Members",
    width: 180,
  },
  {
    field: "swaziTotal",
    headerName: "Total Swazi Members",
    width: 180,
  },
  {
    field: "tsongaGoodStanding",
    headerName: "Valid Tsonga Members",
    width: 180,
  },
  {
    field: "tsongaTotal",
    headerName: "Total Tsonga Members",
    width: 180,
  },
  {
    field: "tswanaGoodStanding",
    headerName: "Valid Tswana Members",
    width: 180,
  },
  {
    field: "tswanaTotal",
    headerName: "Total Tswana Members",
    width: 180,
  },
  {
    field: "vendaGoodStanding",
    headerName: "Valid Venda Members",
    width: 180,
  },
  {
    field: "vendaTotal",
    headerName: "Total Venda Members",
    width: 180,
  },
  {
    field: "xhosaGoodStanding",
    headerName: "Valid Xhosa Members",
    width: 180,
  },
  {
    field: "xhosaTotal",
    headerName: "Total Xhosa Members",
    width: 180,
  },
  {
    field: "afrikaansGoodStanding",
    headerName: "Valid Afrikaans Members",
    width: 180,
  },
  {
    field: "afrikaansTotal",
    headerName: "Total Afrikaans Members",
    width: 180,
  },
  {
    field: "englishGoodStanding",
    headerName: "Valid English Members",
    width: 180,
  },
  {
    field: "englishTotal",
    headerName: "Total English Members",
    width: 180,
  },
  {
    field: "notSpecifiedGoodStanding",
    headerName: "Valid Unspecified Members",
    width: 180,
  },
  {
    field: "notSpecifiedTotal",
    headerName: "Total Unspecified Members",
    width: 180,
  },

  {
    field: "totalGoodStanding",
    headerName: "Valid Members",
    width: 180,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 180,
  },
];

export const languageRegionColumns = [
  {
    field: "region",
    headerName: "Region",
    width: 200,
  },
  {
    field: "zuluGoodStanding",
    headerName: "Valid Zulu Members",
    width: 180,
  },
  {
    field: "zuluTotal",
    headerName: "Total Zulu Members",
    width: 180,
  },
  {
    field: "ndebeleGoodStanding",
    headerName: "Valid Ndebele Members",
    width: 180,
  },
  {
    field: "ndebeleTotal",
    headerName: "Total Ndebele Members",
    width: 180,
  },
  {
    field: "northernSothoGoodStanding",
    headerName: "Valid Northern Sotho Members",
    width: 180,
  },
  {
    field: "northernSothoTotal",
    headerName: "Total Northern Sotho Members",
    width: 180,
  },
  {
    field: "sothoGoodStanding",
    headerName: "Valid Sotho Members",
    width: 180,
  },
  {
    field: "sothoTotal",
    headerName: "Total Sotho Members",
    width: 180,
  },
  {
    field: "swaziGoodStanding",
    headerName: "Valid Swazi Members",
    width: 180,
  },
  {
    field: "swaziTotal",
    headerName: "Total Swazi Members",
    width: 180,
  },
  {
    field: "tsongaGoodStanding",
    headerName: "Valid Tsonga Members",
    width: 180,
  },
  {
    field: "tsongaTotal",
    headerName: "Total Tsonga Members",
    width: 180,
  },
  {
    field: "tswanaGoodStanding",
    headerName: "Valid Tswana Members",
    width: 180,
  },
  {
    field: "tswanaTotal",
    headerName: "Total Tswana Members",
    width: 180,
  },
  {
    field: "vendaGoodStanding",
    headerName: "Valid Venda Members",
    width: 180,
  },
  {
    field: "vendaTotal",
    headerName: "Total Venda Members",
    width: 180,
  },
  {
    field: "xhosaGoodStanding",
    headerName: "Valid Xhosa Members",
    width: 180,
  },
  {
    field: "xhosaTotal",
    headerName: "Total Xhosa Members",
    width: 180,
  },
  {
    field: "afrikaansGoodStanding",
    headerName: "Valid Afrikaans Members",
    width: 180,
  },
  {
    field: "afrikaansTotal",
    headerName: "Total Afrikaans Members",
    width: 180,
  },
  {
    field: "englishGoodStanding",
    headerName: "Valid English Members",
    width: 180,
  },
  {
    field: "englishTotal",
    headerName: "Total English Members",
    width: 180,
  },
  {
    field: "notSpecifiedGoodStanding",
    headerName: "Valid Unspecified Members",
    width: 180,
  },
  {
    field: "notSpecifiedTotal",
    headerName: "Total Unspecified Members",
    width: 180,
  },

  {
    field: "totalGoodStanding",
    headerName: "Valid Members",
    width: 180,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 180,
  },
];

export const languageMunicipalityColumns = [
  {
    field: "municipality",
    headerName: "Municipality",
    width: 200,
  },
  {
    field: "zuluGoodStanding",
    headerName: "Valid Zulu Members",
    width: 180,
  },
  {
    field: "zuluTotal",
    headerName: "Total Zulu Members",
    width: 180,
  },
  {
    field: "ndebeleGoodStanding",
    headerName: "Valid Ndebele Members",
    width: 180,
  },
  {
    field: "ndebeleTotal",
    headerName: "Total Ndebele Members",
    width: 180,
  },
  {
    field: "northernSothoGoodStanding",
    headerName: "Valid Northern Sotho Members",
    width: 180,
  },
  {
    field: "northernSothoTotal",
    headerName: "Total Northern Sotho Members",
    width: 180,
  },
  {
    field: "sothoGoodStanding",
    headerName: "Valid Sotho Members",
    width: 180,
  },
  {
    field: "sothoTotal",
    headerName: "Total Sotho Members",
    width: 180,
  },
  {
    field: "swaziGoodStanding",
    headerName: "Valid Swazi Members",
    width: 180,
  },
  {
    field: "swaziTotal",
    headerName: "Total Swazi Members",
    width: 180,
  },
  {
    field: "tsongaGoodStanding",
    headerName: "Valid Tsonga Members",
    width: 180,
  },
  {
    field: "tsongaTotal",
    headerName: "Total Tsonga Members",
    width: 180,
  },
  {
    field: "tswanaGoodStanding",
    headerName: "Valid Tswana Members",
    width: 180,
  },
  {
    field: "tswanaTotal",
    headerName: "Total Tswana Members",
    width: 180,
  },
  {
    field: "vendaGoodStanding",
    headerName: "Valid Venda Members",
    width: 180,
  },
  {
    field: "vendaTotal",
    headerName: "Total Venda Members",
    width: 180,
  },
  {
    field: "xhosaGoodStanding",
    headerName: "Valid Xhosa Members",
    width: 180,
  },
  {
    field: "xhosaTotal",
    headerName: "Total Xhosa Members",
    width: 180,
  },
  {
    field: "afrikaansGoodStanding",
    headerName: "Valid Afrikaans Members",
    width: 180,
  },
  {
    field: "afrikaansTotal",
    headerName: "Total Afrikaans Members",
    width: 180,
  },
  {
    field: "englishGoodStanding",
    headerName: "Valid English Members",
    width: 180,
  },
  {
    field: "englishTotal",
    headerName: "Total English Members",
    width: 180,
  },
  {
    field: "notSpecifiedGoodStanding",
    headerName: "Valid Unspecified Members",
    width: 180,
  },
  {
    field: "notSpecifiedTotal",
    headerName: "Total Unspecified Members",
    width: 180,
  },

  {
    field: "totalGoodStanding",
    headerName: "Valid Members",
    width: 180,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 180,
  },
];

export const languageWardColumns = [
  {
    field: "ward",
    headerName: "Ward",
    width: 200,
  },
  {
    field: "zuluGoodStanding",
    headerName: "Valid Zulu Members",
    width: 180,
  },
  {
    field: "zuluTotal",
    headerName: "Total Zulu Members",
    width: 180,
  },
  {
    field: "ndebeleGoodStanding",
    headerName: "Valid Ndebele Members",
    width: 180,
  },
  {
    field: "ndebeleTotal",
    headerName: "Total Ndebele Members",
    width: 180,
  },
  {
    field: "northernSothoGoodStanding",
    headerName: "Valid Northern Sotho Members",
    width: 180,
  },
  {
    field: "northernSothoTotal",
    headerName: "Total Northern Sotho Members",
    width: 180,
  },
  {
    field: "sothoGoodStanding",
    headerName: "Valid Sotho Members",
    width: 180,
  },
  {
    field: "sothoTotal",
    headerName: "Total Sotho Members",
    width: 180,
  },
  {
    field: "swaziGoodStanding",
    headerName: "Valid Swazi Members",
    width: 180,
  },
  {
    field: "swaziTotal",
    headerName: "Total Swazi Members",
    width: 180,
  },
  {
    field: "tsongaGoodStanding",
    headerName: "Valid Tsonga Members",
    width: 180,
  },
  {
    field: "tsongaTotal",
    headerName: "Total Tsonga Members",
    width: 180,
  },
  {
    field: "tswanaGoodStanding",
    headerName: "Valid Tswana Members",
    width: 180,
  },
  {
    field: "tswanaTotal",
    headerName: "Total Tswana Members",
    width: 180,
  },
  {
    field: "vendaGoodStanding",
    headerName: "Valid Venda Members",
    width: 180,
  },
  {
    field: "vendaTotal",
    headerName: "Total Venda Members",
    width: 180,
  },
  {
    field: "xhosaGoodStanding",
    headerName: "Valid Xhosa Members",
    width: 180,
  },
  {
    field: "xhosaTotal",
    headerName: "Total Xhosa Members",
    width: 180,
  },
  {
    field: "afrikaansGoodStanding",
    headerName: "Valid Afrikaans Members",
    width: 180,
  },
  {
    field: "afrikaansTotal",
    headerName: "Total Afrikaans Members",
    width: 180,
  },
  {
    field: "englishGoodStanding",
    headerName: "Valid English Members",
    width: 180,
  },
  {
    field: "englishTotal",
    headerName: "Total English Members",
    width: 180,
  },
  {
    field: "notSpecifiedGoodStanding",
    headerName: "Valid Unspecified Members",
    width: 180,
  },
  {
    field: "notSpecifiedTotal",
    headerName: "Total Unspecified Members",
    width: 180,
  },

  {
    field: "totalGoodStanding",
    headerName: "Valid Members",
    width: 180,
  },
  {
    field: "total",
    headerName: "Total Members",
    width: 180,
  },
];
