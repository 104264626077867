import moment from "moment";
import CSVReader from "react-csv-reader";
import { Button } from "@material-ui/core";
import CsvDownload from "react-json-to-csv";
import React, { useEffect, useState } from "react";

import { renewIecToken } from "../../api/checks.js";
import {
  editMember,
  getIecData,
  renewMember,
} from "../../api/firestoreContext.js";
import { checkMember } from "../../api/checkMember.js";

export const CheckMembersForm = () => {
  const [report, setReport] = useState([]);
  const [csvFile, setCsvFile] = useState([]);
  const [addMembers, setAddMembers] = useState(false);
  const [uploadingState, setUploadingState] = useState("");
  const [btnText, setBtnText] = useState("Add a CSV file");
  const [willEditMember, setWillEditMember] = useState(false);

  const currentDate = moment().format("MMMM Do YYYY, h:mm:ss a");

  useEffect(() => {
    if (csvFile.length > 0 && report.length === csvFile.length - 1) {
      setUploadingState("Done");
    }
  }, [csvFile, report]);

  const checkMembers = async () => {
    renewIecToken();
    const iecData = await getIecData();
    for (let i = 1; i < csvFile.length; i++) {
      if (i % 50 === 0) {
        setTimeout(function () {}, 5000);
      }
      setUploadingState(`Adding ... ${csvFile[i][7]}`);
      const data = {
        province: csvFile[i][0],
        region: csvFile[i][1],
        municipality: csvFile[i][2],
        votingStation: csvFile[i][3],
        ward: csvFile[i][4],
        name: csvFile[i][5],
        surname: csvFile[i][6],
        idNumber: csvFile[i][7],
        age: csvFile[i][8],
        gender: csvFile[i][9],
        race: csvFile[i][10],
        citizenship: csvFile[i][11],
        language: csvFile[i][12],
        resAddress: csvFile[i][13],
        cellNumber: csvFile[i][14],
        landlineNumber: csvFile[i][15],
        email: csvFile[i][16],
        occupation: csvFile[i][17],
        qualification: csvFile[i][18],
        dateJoined: csvFile[i][19],
        lastPayment: csvFile[i][20],
        subscription: csvFile[i][21],
        membershipAmount: csvFile[i][22],
      };
      checkMember({ ...data, token: iecData.token })
        .then((result) => {
          const creationData = {
            ...data,
            status: result,
          };
          setReport((report) => [...report, creationData]);
        })
        .catch((error) => {
          const creationData = {
            ...data,
            status: "ERROR",
          };
          setReport((report) => [...report, creationData]);
        });
    }
    setBtnText("Check Members");
  };

  return (
    <div>
      <div style={{ marginTop: 50, marginRight: 20, marginLeft: 20 }}>
        <h1>Check Members</h1>
        <CSVReader
          onFileLoaded={(data) => {
            setCsvFile(data);
            setBtnText("Check Members");
            setAddMembers(true);
          }}
        />
        <p style={{ color: "grey" }}>{uploadingState}</p>

        <Button
          style={{
            color: "black",
            backgroundColor: !addMembers ? "grey" : "#a50100",
            marginTop: 20,
          }}
          onClick={() => {
            setBtnText("Checking members...");
            setReport([]);
            checkMembers();
            setWillEditMember(false);
          }}
          fullWidth
          disabled={!addMembers}
        >
          <h4 style={{ margin: 0, color: "white" }}>{btnText}</h4>
        </Button>

        <div>
          <p style={{ fontStyle: "italic" }}>
            Kindly make sure it is a csv file and it is in this format:{" "}
            <a href="https://firebasestorage.googleapis.com/v0/b/eff-menbers.appspot.com/o/DR%20KK%20MEMBERSHIP%20Matlosana%20Data%202021.11.09%202.csv?alt=media&token=6493b660-e030-42e7-8fac-017880a06745">
              Example CSV File
            </a>
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          marginRight: 20,
          marginLeft: 20,
        }}
      >
        {report && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p>Good</p>
            <p>
              {report.filter((item) => item.status === "Good Standing").length}
            </p>
          </div>
        )}
        {report && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p>Expired</p>
            <p>{report.filter((item) => item.status === "Expired").length}</p>
          </div>
        )}
        {report && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p>Invalid</p>
            <p>
              {
                report.filter((item) => item.status.substr(0, 7) === "INVALID")
                  .length
              }
            </p>
          </div>
        )}
        {report && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p>Errors</p>
            <p>
              {
                report.filter((item) => item.status.substr(0, 5) === "ERROR")
                  .length
              }
            </p>
          </div>
        )}
        {report && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p>Done</p>
            <p> {report.length}</p>
          </div>
        )}
        {report && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p>Total</p>
            <p>{csvFile.length === 0 ? 0 : csvFile.length - 1}</p>
          </div>
        )}
      </div>
      <div style={{ marginRight: 20, marginLeft: 20 }}>
        <Button
          style={{
            color: "black",
            backgroundColor: report.length === 0 ? "grey" : "#a50100",
          }}
          size="large"
          disabled={report.length === 0}
          onClick={() => {
            document.getElementById("csv-check").click();
          }}
          fullWidth
        >
          <h4 style={{ margin: 0, color: "white" }}>Download report</h4>
        </Button>
        <div style={{ display: "none" }}>
          <CsvDownload
            id="csv-check"
            filename={`Member-Check-${currentDate}.csv`}
            data={report}
          />
        </div>
      </div>
    </div>
  );
};
