// hooks/Auth/PrivateRoute.js
import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ element }) => {
  const authState = useSelector((state) => state.auth);

  // Check if the user is logged in
  if (!authState.studentLoggedIn) {
    return <Navigate to="/" />;  // Redirect to login page
  }

  return element;  // If logged in, return the requested page
};

export default PrivateRoute;
