import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import WARDS from "../../assets/data/Wards.js";
import REGIONS from "../../assets/data/Regions.js";
import PROVINCES from "../../assets/data/Provinces.js";
import { createMember } from "../../api/firestoreContext.js";
import MUNICIPALITIES from "../../assets/data/Municipalities.js";
import VOTINGSTATIONS from "../../assets/data/VotingStations.js";
import { registeredVoterCheck, renewIecToken } from "../../api/checks.js";

export default function CreateMemberForm(props) {
  const [age, setAge] = useState();
  const [name, setName] = useState();
  const [race, setRace] = useState();
  const [ward, setWard] = useState();
  const [email, setEmail] = useState();
  const [gender, setGender] = useState();
  const [region, setRegion] = useState();
  const [surname, setSurname] = useState();
  const [province, setProvince] = useState("");
  const [idNumber, setIdNumber] = useState();
  const [language, setLanguage] = useState();
  const [resAddress, setResAddress] = useState();
  const [cellNumber, setCellNumber] = useState();
  const [occupation, setOccupation] = useState();
  const [dateJoined, setDateJoined] = useState();
  const [wardOptions, setWardOptions] = useState();
  const [citizenship, setCitizenship] = useState();
  const [lastPayment, setLastPayment] = useState();
  const [subscription, setSubscription] = useState();
  const [municipality, setMunicipality] = useState();
  const [regionOptions, setRegionOptions] = useState();
  const [votingStation, setVotingStation] = useState();
  const [qualification, setQualification] = useState();
  const [landlineNumber, setLandlineNumber] = useState();
  const [membershipAmount, setMembershipAmount] = useState();
  const [municipalityOptions, setMunicipalityOptions] = useState();
  const [votingStationOptions, setVotingStationOptions] = useState();

  const [buttonStatus, setButtonStatus] = useState("Complete form");
  const [status, setStatus] = useState("");

  const submitForm = () => {
    renewIecToken();
    const data = {
      province,
      region,
      municipality,
      ward,
      votingStation,
      name,
      surname,
      idNumber,
      age,
      gender,
      citizenship: "South Africa",
      race,
      language,
      resAddress,
      cellNumber,
      landlineNumber,
      email,
      occupation,
      qualification,
      dateJoined,
      lastPayment,
      subscription,
      membershipAmount,
    };
    createMember(data)
      .then((result) => {
        if (result.reason !== "Successful") {
          setStatus(`Member rejected: ${result.reason}`);
        } else {
          setStatus("Successful");
          setButtonStatus("Done");
        }
      })
      .catch((error) => {
        console.log("Error");
        setStatus("Error");
        setButtonStatus("Retry");
      });
  };

  // registeredVoterCheck(
  //   "0102061290080",
  //   "wH1Y-hiGxB5Y0Cj8sojHuF2zyQsLgnkORXeSqxtcHsMCSiQMhHMycIm1pglxux7fh1q6sR89FkHFMxBmnDjZYYg06ot0CySglx2LiJdcfnx1inlxBfOnAVsb5qXs6rmOE5G6aONdogtikIszPfwXynUo8kA4l2Zg2Vu9CWspPxducXhCRF9qS8vnttLJPzp_XbI28t-7P-mRcr40Uofp5mLWT0Ogn2wQQXx9F_N_cz9_fg68KLhS-hBRymAkIZCqD0MzO7Z7dDZgL7Mwbag5opXv1LL1gpMfSEWhha72IYPwZwsbE4guXWhauhuUyVC4jo85ILNPRed9o3wy4k4WWxX5yJwRdwuQ8jhz6VBzRA_EQJFiFJaZli0fVjrHSWRMt_2pdwTJmckwiDUbnTXMrDG984kH736xxaLiKlG9dbSS5AIO"
  // );

  useEffect(() => {
    if (
      province &&
      region &&
      municipality &&
      ward &&
      votingStation &&
      name &&
      surname &&
      idNumber &&
      age &&
      gender &&
      race &&
      language &&
      resAddress &&
      cellNumber &&
      landlineNumber &&
      email &&
      occupation &&
      qualification &&
      dateJoined &&
      lastPayment &&
      subscription &&
      membershipAmount
    ) {
      setButtonStatus("Submit");
    }
  }, [
    province,
    region,
    municipality,
    ward,
    votingStation,
    name,
    surname,
    idNumber,
    age,
    gender,
    race,
    language,
    resAddress,
    cellNumber,
    landlineNumber,
    email,
    occupation,
    qualification,
    dateJoined,
    lastPayment,
    subscription,
    membershipAmount,
  ]);

  useEffect(() => {
    const selectedProvince = PROVINCES.find((prov) => prov.Name === province);
    if (selectedProvince) {
      setRegionOptions(
        REGIONS.filter(
          (region) => region.ProvinceId === selectedProvince.ProvinceId
        )
      );
    }
  }, [province]);

  useEffect(() => {
    const selectedRegion = REGIONS.find((prov) => prov.Name === region);
    if (selectedRegion) {
      setMunicipalityOptions(
        MUNICIPALITIES.filter(
          (region) => region.Region_RegionId === selectedRegion.RegionId
        )
      );
    }
  }, [region]);

  useEffect(() => {
    const selectedMunicipality = MUNICIPALITIES.find(
      (prov) => prov.Name === municipality
    );
    if (selectedMunicipality) {
      setWardOptions(
        WARDS.filter(
          (region) =>
            region.MunicipalityId === selectedMunicipality.MunicipalityId
        )
      );
    }
  }, [municipality]);

  useEffect(() => {
    const selectedWard = WARDS.find((prov) => prov.WardName === ward);
    if (selectedWard) {
      setVotingStationOptions(
        VOTINGSTATIONS.filter(
          (region) => region.WardLookupId === selectedWard.WardId
        )
      );
    }
  }, [ward]);

  return (
    <div style={{ marginTop: 120, marginRight: 20, marginLeft: 20 }}>
      <h1>Create Member</h1>

      <FormControl style={{ marginBottom: 10 }} fullWidth>
        <InputLabel id="demo-simple-select-label">Province</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={province}
          label="province"
          onChange={(value) => setProvince(value.target.value)}
        >
          <MenuItem value="Eastern Cape">Eastern Cape</MenuItem>
          <MenuItem value={"Free State"}>Free State</MenuItem>
          <MenuItem value={"Gauteng"}>Gauteng</MenuItem>
          <MenuItem value={"KwaZulu-Natal"}>KwaZulu-Natal</MenuItem>
          <MenuItem value={"Limpopo"}>Limpopo</MenuItem>
          <MenuItem value={"Mpumalanga"}>Mpumalanga</MenuItem>
          <MenuItem value={"North West"}>North West</MenuItem>
          <MenuItem value={"Northern Cape"}>Northern Cape</MenuItem>
          <MenuItem value={"Western Cape"}>Western Cape</MenuItem>
        </Select>
      </FormControl>

      <FormControl style={{ marginBottom: 10 }} fullWidth>
        <InputLabel id="demo-simple-select-label">Region</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={region}
          defaultValue={""}
          label="region"
          onChange={(value) => setRegion(value.target.value.Name)}
        >
          {regionOptions &&
            regionOptions.map((item) => {
              return <MenuItem value={item}>{item.Name}</MenuItem>;
            })}
        </Select>
      </FormControl>

      <FormControl style={{ marginBottom: 10 }} fullWidth>
        <InputLabel id="demo-simple-select-label">Municipality</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="municipality"
          value={municipality}
          defaultValue={""}
          label="municipality"
          onChange={(value) => setMunicipality(value.target.value.Name)}
        >
          {municipalityOptions &&
            municipalityOptions.map((item) => {
              return <MenuItem value={item}>{item.Name}</MenuItem>;
            })}
        </Select>
      </FormControl>

      <FormControl style={{ marginBottom: 10 }} fullWidth>
        <InputLabel id="demo-simple-select-label">Ward</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="ward"
          value={ward}
          defaultValue={""}
          label="ward"
          onChange={(value) => setWard(value.target.value.WardName)}
        >
          {wardOptions &&
            wardOptions.map((item) => {
              return <MenuItem value={item}>{item.WardName}</MenuItem>;
            })}
        </Select>
      </FormControl>

      <FormControl style={{ marginBottom: 10 }} fullWidth>
        <InputLabel id="demo-simple-select-label">Voting Station</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="votingStation"
          value={votingStation}
          label="Voting Station"
          defaultValue={""}
          onChange={(value) =>
            setVotingStation(value.target.value.VotingStationName)
          }
        >
          {votingStationOptions &&
            votingStationOptions.map((item) => {
              return <MenuItem value={item}>{item.VotingStationName}</MenuItem>;
            })}
        </Select>
      </FormControl>

      <TextField
        id="name"
        value={name}
        onChange={(value) => setName(value.target.value)}
        fullWidth
        label="Name"
        variant="filled"
        style={{ marginBottom: 10 }}
      />
      <TextField
        id="surname"
        value={surname}
        onChange={(value) => setSurname(value.target.value)}
        fullWidth
        label="Surname"
        variant="filled"
        style={{ marginBottom: 10 }}
      />
      <TextField
        id="id"
        value={idNumber}
        onChange={(value) => setIdNumber(value.target.value)}
        fullWidth
        label="RSA Identity Number"
        variant="filled"
        style={{ marginBottom: 10 }}
      />
      <TextField
        id="age"
        value={age}
        onChange={(value) => setAge(value.target.value)}
        // fullWidth
        label="Age"
        variant="filled"
        style={{ marginBottom: 10 }}
        type="number"
      />

      <FormControl style={{ marginBottom: 10 }} fullWidth>
        <InputLabel id="demo-simple-select-label">Gender</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={gender}
          label="Gender"
          defaultValue={""}
          onChange={(value) => setGender(value.target.value)}
        >
          <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
          <MenuItem value="Other">Other</MenuItem>
        </Select>
      </FormControl>

      <TextField
        id="citizenship"
        value={citizenship}
        onChange={(value) => setCitizenship(value.target.value)}
        fullWidth
        label="Citizenship"
        variant="filled"
        style={{ marginBottom: 10 }}
        disabled
      />

      <FormControl style={{ marginBottom: 10 }} fullWidth>
        <InputLabel id="demo-simple-select-label">Race</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="race"
          value={race}
          defaultValue={""}
          label="Race"
          onChange={(value) => setRace(value.target.value)}
        >
          <MenuItem value="Black">Black</MenuItem>
          <MenuItem value="Coloured">Coloured</MenuItem>
          <MenuItem value="Indian">Indian</MenuItem>
          <MenuItem value="White">White</MenuItem>
        </Select>
      </FormControl>

      <FormControl style={{ marginBottom: 10 }} fullWidth>
        <InputLabel id="demo-simple-select-label">Language</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="language"
          value={language}
          label="Language"
          defaultValue={""}
          onChange={(value) => setLanguage(value.target.value)}
        >
          <MenuItem value="Afrikaans">Afrikaans</MenuItem>
          <MenuItem value={"English"}>English</MenuItem>
          <MenuItem value={"Ndebele"}>Ndebele</MenuItem>
          <MenuItem value={"Northern Sotho"}>Northern Sotho</MenuItem>
          <MenuItem value={"Sotho"}>Sotho</MenuItem>
          <MenuItem value={"Swazi"}>Swazi</MenuItem>
          <MenuItem value={"Tsonga"}>Tsonga</MenuItem>
          <MenuItem value={"Tswana"}>Tswana</MenuItem>
          <MenuItem value={"Venda"}>Venda</MenuItem>
          <MenuItem value={"Xhosa"}>Xhosa</MenuItem>
          <MenuItem value={"Zulu"}>Zulu</MenuItem>
        </Select>
      </FormControl>

      <TextField
        id="residentialAddress"
        value={resAddress}
        onChange={(value) => setResAddress(value.target.value)}
        fullWidth
        label="Residential Address"
        variant="filled"
        style={{ marginBottom: 10 }}
      />

      <TextField
        id="cellphoneNumber"
        value={cellNumber}
        onChange={(value) => setCellNumber(value.target.value)}
        fullWidth
        label="Cellphone Number"
        variant="filled"
        style={{ marginBottom: 10 }}
      />

      <TextField
        id="landlineNumber"
        value={landlineNumber}
        onChange={(value) => setLandlineNumber(value.target.value)}
        fullWidth
        label="Landline Number"
        variant="filled"
        style={{ marginBottom: 10 }}
      />

      <TextField
        id="email"
        value={email}
        onChange={(value) => setEmail(value.target.value)}
        fullWidth
        label="Email"
        variant="filled"
        style={{ marginBottom: 10 }}
      />
      <TextField
        id="occupation"
        value={occupation}
        onChange={(value) => setOccupation(value.target.value)}
        fullWidth
        label="Occupation"
        variant="filled"
        required
        style={{ marginBottom: 10 }}
      />
      <TextField
        id="qualification"
        value={qualification}
        onChange={(value) => setQualification(value.target.value)}
        fullWidth
        label="Qualification"
        variant="filled"
        style={{ marginBottom: 10 }}
      />
      <TextField
        id="dateJoined"
        label="Date Joined"
        type="date"
        fullWidth
        variant="filled"
        InputLabelProps={{
          shrink: true,
        }}
        value={dateJoined}
        onChange={(value) => setDateJoined(value.target.value)}
        style={{ marginBottom: 10 }}
      />
      <TextField
        id="lastPaymentRecieved"
        label="Last Payment Received"
        type="date"
        fullWidth
        variant="filled"
        InputLabelProps={{
          shrink: true,
        }}
        value={lastPayment}
        onChange={(value) => setLastPayment(value.target.value)}
        style={{ marginBottom: 10 }}
      />

      <FormControl style={{ marginBottom: 10 }} fullWidth>
        <InputLabel id="demo-simple-select-label">Subscription</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="Subscription"
          value={subscription}
          label="Subscription"
          defaultValue={""}
          onChange={(value) => setSubscription(value.target.value)}
        >
          <MenuItem value="New Subscription">New Subscription</MenuItem>
          <MenuItem value="Renewal">Renewal</MenuItem>
        </Select>
      </FormControl>

      <FormControl style={{ marginBottom: 10 }} fullWidth>
        <InputLabel id="demo-simple-select-label">Membership Amount</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="membership"
          value={membershipAmount}
          defaultValue={""}
          label="Membership Amount"
          onChange={(value) => setMembershipAmount(value.target.value)}
        >
          <MenuItem value={"10.00"}>R 10 (2 Year)</MenuItem>
        </Select>
      </FormControl>
      <p>{status}</p>
      <Button
        // simple
        style={{
          color: "black",
          backgroundColor: "#a50100",
          marginTop: 40,
        }}
        size="large"
        onClick={() => {
          setStatus("Adding ...");
          submitForm();
        }}
        fullWidth
      >
        <h4 style={{ margin: 0, color: "white" }}>{buttonStatus}</h4>
      </Button>
    </div>
  );
}
