export const LANGUAGES = [
  "NDEBELE",
  "NORTHERN SOTHO",
  "SOTHO",
  "SWAZI",
  "TSONGA",
  "TSWANA",
  "VENDA",
  "XHOSA",
  "ZULU",
  "AFRIKAANS",
  "ENGLISH",
];
