import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";
import GridContainer from "../../components/Grid/GridContainer";
import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import db from "../../api/firebase";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import CsvDownload from "react-json-to-csv";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { pdf } from "@react-pdf/renderer";
import Template from "../../components/PDF/AttendanceRegister";
import { saveAs } from "file-saver";
import { Timestamp } from "firebase/firestore";
import {
  MUNICIPALITIES,
  PROVINCES,
  REGIONS,
  VOTINGSTATIONS,
  WARDS,
} from "../../assets/data";
import { alpha, styled } from "@mui/material/styles";
import moment from "moment";
import { Box, Modal, Typography } from "@mui/material";
import { TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { getProvinces } from "../../assets/data/Provinces";
import { getMunicipalities } from "../../assets/data/Municipalities";
import { getWards } from "../../assets/data/Wards";
import { getRegions } from "../../assets/data/Regions";
import {
  getAge,
  getDateJoined,
  getGender,
  getLastPayment,
} from "../../api/validation";
import EditeMemberForm from "../../components/Forms/EditMemberForm";
import { getDatabase, ref, set } from "firebase/database";
import { LANGUAGES } from "../../assets/data/Languages";
import { RACES } from "../../assets/data/Races";
import { getAuth } from "firebase/auth";

// import Preview from "../../components/PDF/Preview";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.true`]: {
    backgroundColor: theme.palette.error.light,
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));

export default function MemberDetailsPage(props) {
  const [members, setMembers] = useState([]);
  const [data, setData] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [province, setProvince] = useState("");
  const [region, setRegion] = useState("");
  const [regionOptions, setRegionOptions] = useState();
  const [municipality, setMunicipality] = useState("");
  const [municipalityOptions, setMunicipalityOptions] = useState();
  const [ward, setWard] = useState("");
  const [wardOptions, setWardOptions] = useState();
  const [votingStation, setVotingStation] = useState("");
  const [votingStationOptions, setVotingStationOptions] = useState();
  const [isFiltered, setIsFiltered] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentMember, setCurrentMember] = useState();
  const [filterBtn, setFilterBtn] = useState(
    "Please select a province and region"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [formatedMembers, setFormatedMembers] = useState([]);
  const [identityNumber, setIdentityNumber] = useState();

  const auth = getAuth();

  useEffect(() => {
    if (!members) {
      return;
    }
    setData({
      items: members
        .filter((item) => moment().format() < moment(item.expiryDate).format())
        .map((item) => {
          return {
            name: item.name
              ? `${item.name + " " + item.surname}`.substring(0, 30)
              : `${item.surname}`,
            // name: item.name,
            wardNumber: item.ward,
            idNumber: item.idNumber,
            cellNumber: item.cellNumber,
            dateJoined: item.dateJoined,
            status: "Registered",
          };
        }),
      province: province,
      region: region,
      subRegion: municipality,
      ward: ward,
      totalMembers: members.filter(
        (item) => moment().format() < moment(item.expiryDate).format()
      ).length,
      registered: members.filter(
        (item) => moment().format() < moment(item.expiryDate).format()
      ).length,
      notRegistered: 0,
    });
  }, [members, province, region, municipality, ward, votingStation]);

  useEffect(() => {
    if (!members) {
      return;
    }
    setFormatedMembers(
      members.map((item) => ({
        Province: item.province,
        Region: item.region,
        Municipality: item.municipality,
        "Voting Station": item.votingStation,
        Ward: ward ? ward : item.ward,
        Firstname: item.name,
        Surname: item.surname,
        "ID Number": item.idNumber,
        Age: item.age,
        Gender: item.gender,
        Race: item.race,
        Citizenship: item.citizenship,
        Language: item.language,
        "Residential Address": item.residentialAddress,
        "Cell Number": item.cellNumber,
        "Landline Number": item.landlineNumber,
        Email: item.email,
        Occupation: item.occupation,
        Qualification: item.qualification,
        "Date Joined": item.dateJoined,
        "Last Payment": item.lastPayment,
        "Expiry Date": item.expiryDate,
        Subscription: item.subscription,
        "Memebership Amount": item.membershipAmount,
        Status: item.memberStatus,
      }))
    );
  }, [members, province, region, municipality, ward, votingStation]);

  const { ...rest } = props;

  useEffect(() => {
    if (region !== "") setFilterBtn("Filter");
  }, [region]);

  const generateDoc = async (documentData, fileName) => {
    const blob = await pdf(<Template data={documentData} />).toBlob();
    saveAs(blob, fileName);
  };

  const editModal = () => {
    setOpen(true);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: 650,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const columns = [
    {
      field: "province",
      headerName: "Province",
      width: 150,
    },
    {
      field: "region",
      headerName: "Region",
      width: 150,
    },
    {
      field: "municipality",
      headerName: "Municipality",
      width: 150,
    },
    {
      field: "ward",
      headerName: "Ward",
      width: 150,
    },
    {
      field: "votingStation",
      headerName: "Voting Station",
      width: 150,
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
    },
    {
      field: "surname",
      headerName: "Surname",
      width: 150,
    },
    { field: "idNumber", headerName: "RSA ID", width: 150 },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      width: 110,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 150,
    },
    {
      field: "dateJoined",
      headerName: "Date Joined",
      width: 150,
    },
    {
      field: "lastPayment",
      headerName: "Pmnt Received",
      width: 150,
    },
    {
      field: "membershipAmount",
      headerName: "Amount",
      width: 150,
    },
    {
      field: "expiryDate",
      headerName: "Expire On",
      width: 150,
    },
  ];

  const { provinceMap } = getProvinces();
  const { municipalityMap } = getMunicipalities();
  const { wardMap } = getWards();
  const { regionMap } = getRegions();

  const getMembers = () => {
    setIsLoading(true);
    const ref = collection(db, "members");
    if (province === "") {
      setIsLoading(false);
      return;
    }

    const filterParam = () => {
      let value = "";
      let param = "";
      if (province) {
        value = province;
        param = "province";
      }
      if (region) {
        value = region;
        param = "region";
      }
      if (municipality) {
        value = municipality;
        param = "municipality";
      }
      if (ward) {
        value = ward;
        param = "ward";
      }
      return {
        value,
        param,
      };
    };

    const filterValue = filterParam();

    let arrayOfMembers = [];
    const dataQuery = query(
      ref,
      where(filterValue.param, "==", filterValue.value)
    );

    getDocs(dataQuery).then((value) => {
      const firstArray = arrayOfMembers.concat(value.docs);

      let uniqueIds = [];
      let uniqueDocs = [];
      let duplicateDocs = [];

      firstArray.forEach((doc) => {
        const id = doc.data().idNumber;
        if (!uniqueIds.includes(id)) {
          uniqueDocs.push(doc);
          uniqueIds.push(id);
        } else {
          duplicateDocs.push(doc);
        }
      });

      // if (startDate && endDate) {
      //   uniqueDocs = uniqueDocs.filter(
      //     (item) =>
      //       getLastPayment(item.data()) >= moment(startDate).format() &&
      //       getLastPayment(item.data()) <= moment(endDate).format()
      //   );
      // }
      setMembers(
        uniqueDocs
          .filter((item) => item.data().invalid === false)
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
            name: doc.data().name ? doc.data().name : doc.data().firstName,
            membershipAmount:
              doc.data().membershipAmount || doc.data().regionId
                ? "R10.00"
                : "R0.00",
            province: /^\d+$/.test(doc.data().province)
              ? provinceMap[doc.data().province]
              : doc.data().province,
            region: doc.data().region
              ? doc.data().region
              : regionMap[doc.data().regionId],
            municipality: /^\d+$/.test(doc.data().municipality)
              ? municipalityMap[doc.data().municipality]
              : doc.data().municipality,
            ward:
              doc.data().ward.trim().length < 5
                ? wardMap[doc.data().ward]
                : doc.data().ward,
            // votingStation: /^\d+$/.test(doc.data().votingStation)
            //   ? provinceMap[doc.data().votingStation]
            //   : doc.data().votingStation,
            votingStation:
              doc.data().votingStation === "NULL" ||
              !doc.data().votingStation ||
              (doc.data().votingStation && doc.data().votingStation.length < 2)
                ? "No VotingStation"
                : doc.data().votingStation,
            memberStatus:
              moment().format() <
              moment(getLastPayment(doc.data())).add(2, "years").format()
                ? "Good Standing"
                : "Invalid",
            // ? doc.data().memeberStatus.trim() === "Good Standing"
            // : true,
            gender: doc.data().idNumber
              ? getGender(doc.data().idNumber.substr(6, 4))
              : getGender(doc.data().rsaIdentityNo.substr(6, 4)),
            age: doc.data().idNumber
              ? getAge(doc.data().idNumber)
              : getAge(doc.data().rsaIdentityNo),
            lastPayment: getLastPayment(doc.data()),
            dateJoined:
              doc.data().dateJoined === "Invalid date"
                ? getDateJoined({ ...doc.data(), id: doc.id })
                : doc.data().dateJoined,
            idNumber: doc.data().rsaIdentityNo
              ? doc.data().rsaIdentityNo
              : doc.data().idNumber,
            expiryDate: moment(getLastPayment(doc.data()))
              .add(2, "years")
              .format(),
            residentialAddress:
              doc.data().residentialAddress &&
              doc.data().residentialAddress.length > 2
                ? doc.data().residentialAddress
                : doc.data().residentialAdress &&
                  doc.data().residentialAdress.length > 2
                ? doc.data().residentialAdress
                : "No Address",
          }))
      );
      setIsLoading(false);
    });
  };

  const getMember = () => {
    setIsLoading(true);
    const ref = collection(db, "members");
    let arrayOfMembers = [];
    const dataQuery = query(ref, where("idNumber", "==", identityNumber));

    const secondQuery = query(
      ref,
      where("rsaIdentityNo", "==", identityNumber)
    );

    getDocs(dataQuery).then((value) => {
      const firstArray = arrayOfMembers.concat(value.docs);
      getDocs(secondQuery).then((docs) => {
        let uniqueIds = [];
        let uniqueDocs = [];
        let duplicateDocs = [];

        const secondArray = firstArray.concat(docs.docs);
        secondArray.forEach((doc) => {
          const id = doc.data().rsaIdentityNo
            ? doc.data().rsaIdentityNo
            : doc.data().idNumber;
          if (!uniqueIds.includes(id)) {
            uniqueDocs.push(doc);
            uniqueIds.push(id);
          } else {
            duplicateDocs.push(doc);
            // deleteMember(id);
          }
        });

        setMembers(
          uniqueDocs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            name: doc.data().name ? doc.data().name : doc.data().firstName,
            membershipAmount:
              doc.data().membershipAmount || doc.data().regionId
                ? "R10.00"
                : "R0.00",
            province: /^\d+$/.test(doc.data().province)
              ? provinceMap[doc.data().province]
              : doc.data().province,
            region: doc.data().region
              ? doc.data().region
              : regionMap[doc.data().regionId],
            municipality: /^\d+$/.test(doc.data().municipality)
              ? municipalityMap[doc.data().municipality]
              : doc.data().municipality,
            ward:
              doc.data().ward.trim().length < 5
                ? wardMap[doc.data().ward]
                : doc.data().ward,
            // votingStation: /^\d+$/.test(doc.data().votingStation)
            //   ? provinceMap[doc.data().votingStation]
            //   : doc.data().votingStation,
            votingStation:
              doc.data().votingStation === "NULL" ||
              !doc.data().votingStation ||
              (doc.data().votingStation && doc.data().votingStation.length < 2)
                ? "No VotingStation"
                : doc.data().votingStation,
            memberStatus:
              moment().format() <
              moment(getLastPayment(doc.data())).add(2, "years").format()
                ? "Good Standing"
                : "Invalid",
            // ? doc.data().memeberStatus.trim() === "Good Standing"
            // : true,
            gender: doc.data().idNumber
              ? getGender(doc.data().idNumber.substr(6, 4))
              : getGender(doc.data().rsaIdentityNo.substr(6, 4)),
            age: doc.data().idNumber
              ? getAge(doc.data().idNumber)
              : getAge(doc.data().rsaIdentityNo),
            lastPayment: getLastPayment(doc.data()),
            dateJoined:
              doc.data().dateJoined === "Invalid date"
                ? getDateJoined({ ...doc.data(), id: doc.id })
                : doc.data().dateJoined,
            idNumber: doc.data().rsaIdentityNo
              ? doc.data().rsaIdentityNo
              : doc.data().idNumber,
            expiryDate: moment(getLastPayment(doc.data()))
              .add(2, "years")
              .format(),
            residentialAddress:
              doc.data().residentialAddress &&
              doc.data().residentialAddress.length > 2
                ? doc.data().residentialAddress
                : doc.data().residentialAdress &&
                  doc.data().residentialAdress.length > 2
                ? doc.data().residentialAdress
                : "No Address",
          }))
        );
        setIsLoading(false);
      });
    });
  };

  useEffect(() => {
    const selectedProvince = PROVINCES.find((prov) => prov.Name === province);
    if (selectedProvince) {
      setRegionOptions(
        REGIONS.filter(
          (region) => region.ProvinceId === selectedProvince.ProvinceId
        )
      );
    }
  }, [province]);

  useEffect(() => {
    const selectedRegion = REGIONS.find((prov) => prov.Name === region);
    if (selectedRegion) {
      setMunicipalityOptions(
        MUNICIPALITIES.filter(
          (region) => region.Region_RegionId === selectedRegion.RegionId
        )
      );
    }
  }, [region]);

  useEffect(() => {
    const selectedMunicipality = MUNICIPALITIES.find(
      (prov) => prov.Name === municipality
    );
    if (selectedMunicipality) {
      setWardOptions(
        WARDS.filter(
          (region) =>
            region.MunicipalityId === selectedMunicipality.MunicipalityId
        )
      );
    }
  }, [municipality]);

  useEffect(() => {
    const selectedWard = WARDS.find((prov) => prov.WardName === ward);
    if (selectedWard) {
      setVotingStationOptions(
        VOTINGSTATIONS.filter(
          (region) => region.WardLookupId === selectedWard.WardId
        )
      );
    }
  }, [ward]);

  const authState = useSelector((state) => state);

  if (auth.currentUser && auth.currentUser === null) return <Navigate to="/" />;

  return (
    <div
      style={
        {
          // width: "100%",
          // height: 600,
        }
      }
    >
      <Header
        absolute
        color="dark"
        brand="Membership Administration"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <GridContainer
        style={{
          width: "90%",
          marginTop: 80,
          marginRight: 40,
          marginLeft: 40,
          display: "flex",
          flexDirection: "column",
          marginBottom: 40,
        }}
        justifyContent="center"
      >
        <div style={{ marginBottom: 20 }}>
          <h1>Member Details</h1>
          <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1, padding: 10 }}>
              <FormControl style={{ marginBottom: 10 }} fullWidth>
                <InputLabel id="demo-simple-select-label">Province</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={province}
                  label="province"
                  onChange={(value) => setProvince(value.target.value)}
                  renderValue={(value) => value}
                >
                  <MenuItem value="Eastern Cape">Eastern Cape</MenuItem>
                  <MenuItem value={"Free State"}>Free State</MenuItem>
                  <MenuItem value={"Gauteng"}>Gauteng</MenuItem>
                  <MenuItem value={"KwaZulu-Natal"}>KwaZulu-Natal</MenuItem>
                  <MenuItem value={"Limpopo"}>Limpopo</MenuItem>
                  <MenuItem value={"Mpumalanga"}>Mpumalanga</MenuItem>
                  <MenuItem value={"North West"}>North West</MenuItem>
                  <MenuItem value={"Northern Cape"}>Northern Cape</MenuItem>
                  <MenuItem value={"Western Cape"}>Western Cape</MenuItem>
                </Select>
              </FormControl>

              <FormControl style={{ marginBottom: 10 }} fullWidth>
                <InputLabel id="demo-simple-select-label">Region</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={region}
                  defaultValue={region}
                  displayEmpty
                  renderValue={(value) => {
                    if (region !== null) {
                      return value;
                    }
                    return "";
                  }}
                  label="region"
                  onChange={(value) => setRegion(value.target.value.Name)}
                >
                  {regionOptions &&
                    regionOptions.map((item) => {
                      return <MenuItem value={item}>{item.Name}</MenuItem>;
                    })}
                </Select>
              </FormControl>

              <TextField
                id="startDate"
                label="Start Date"
                type="date"
                fullWidth
                variant="filled"
                disabled={region === ""}
                InputLabelProps={{
                  shrink: true,
                }}
                value={startDate}
                onChange={(value) => setStartDate(value.target.value)}
                style={{ marginBottom: 10 }}
              />
              <TextField
                id="endDate"
                label="End Date"
                type="date"
                fullWidth
                variant="filled"
                disabled={region === ""}
                InputLabelProps={{
                  shrink: true,
                }}
                value={endDate}
                onChange={(value) => setEndDate(value.target.value)}
                style={{ marginBottom: 10 }}
              />

              <Button
                style={{
                  color: "black",
                  backgroundColor: "#a50100",
                  marginTop: 10,
                }}
                size="lg"
                fullWidth
                onClick={() => {
                  // filterMembers();
                  getMembers();
                }}
              >
                <h4 style={{ margin: 0, color: "white" }}>{filterBtn}</h4>
              </Button>
              <Button
                style={{
                  color: "black",
                  backgroundColor: "black",
                  marginTop: 10,
                }}
                size="lg"
                fullWidth
                onClick={() => {
                  setProvince("");
                  setRegion("");
                  setMunicipality("");
                  setWard("");
                  setVotingStation("");
                  setIsFiltered(false);
                  setFilterBtn("Please select a province and region");
                  setMembers([]);
                  setIdentityNumber("");
                  setEndDate("");
                  setStartDate("");
                }}
              >
                <h4 style={{ margin: 0, color: "white" }}>Reset</h4>
              </Button>
            </div>
            <div style={{ flex: 1, padding: 10 }}>
              <FormControl style={{ marginBottom: 10 }} fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Municipality
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="municipality"
                  value={municipality}
                  label="municipality"
                  onChange={(value) => setMunicipality(value.target.value.Name)}
                  defaultValue={municipality}
                  displayEmpty
                  renderValue={(value) => {
                    if (municipality !== null) {
                      return value;
                    }
                    return "";
                  }}
                >
                  {municipalityOptions &&
                    municipalityOptions.map((item) => {
                      return <MenuItem value={item}>{item.Name}</MenuItem>;
                    })}
                </Select>
              </FormControl>

              <FormControl style={{ marginBottom: 10 }} fullWidth>
                <InputLabel id="demo-simple-select-label">Ward</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="ward"
                  value={ward}
                  label="ward"
                  onChange={(value) => setWard(value.target.value.WardName)}
                  defaultValue={ward}
                  displayEmpty
                  renderValue={(value) => {
                    if (ward !== null) {
                      return value;
                    }
                    return "";
                  }}
                >
                  {wardOptions &&
                    wardOptions.map((item) => {
                      return <MenuItem value={item}>{item.WardName}</MenuItem>;
                    })}
                </Select>
              </FormControl>

              <FormControl style={{ marginBottom: 10 }} fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Voting Station
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="votingStation"
                  value={votingStation}
                  label="Voting Station"
                  onChange={(value) =>
                    setVotingStation(value.target.value.VotingStationName)
                  }
                  defaultValue={votingStation}
                  displayEmpty
                  renderValue={(value) => {
                    if (votingStation !== null) {
                      return value;
                    }
                    return "";
                  }}
                >
                  {votingStationOptions &&
                    votingStationOptions.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.VotingStationName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <div>
                <TextField
                  id="citizenship"
                  value={identityNumber}
                  onChange={(value) => setIdentityNumber(value.target.value)}
                  fullWidth
                  label="ID Number"
                  placeholder="Enter an id number"
                  variant="outlined"
                  style={{ marginBottom: 10 }}
                  color="primary"
                />
                <Button
                  style={{
                    color: "black",
                    backgroundColor: "#a50100",
                    marginTop: 10,
                  }}
                  size="lg"
                  fullWidth
                  onClick={() => {
                    getMember();
                  }}
                >
                  <h4 style={{ margin: 0, color: "white" }}>
                    Search by ID number
                  </h4>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: 600 }}>
          {/* <DataGrid
            rows={isFiltered ? filteredMembers : members}
            columns={columns}
            pageSize={30}
            rowsPerPageOptions={[5]}
            //   checkboxSelection
            disableSelectionOnClick
          /> */}
          <StripedDataGrid
            loading={isLoading}
            rows={members}
            columns={columns}
            pageSize={100}
            disableSelectionOnClick
            getRowClassName={(params) =>
              moment().format() > moment(params.row.expiryDate).format()
                ? "true"
                : "false"
            }
            onRowClick={(data) => {
              setCurrentMember(data.row);
              editModal();
            }}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <Button
            // simple
            style={{
              color: "black",
              backgroundColor: "#a50100",
              marginRight: 40,
            }}
            onClick={() => {
              municipality
                ? generateDoc(data, "Attendance Sheet")
                : alert("Make sure a ward is included in the filter");
            }}
          >
            <h4
              style={{
                margin: 0,
                color: "white",
              }}
            >
              Download PDF
            </h4>
          </Button>
          <Button
            // simple
            style={{
              color: "black",
              marginRight: 40,
              backgroundColor: "#a50100",
            }}
            // size="lg"
            onClick={() => {
              document.getElementById("csvAtten").click();
            }}
          >
            <h4 style={{ margin: 0, color: "white" }}>Download CSV</h4>
          </Button>
          <Button
            // simple
            style={{
              color: "black",
              backgroundColor: "#a50100",
            }}
            // size="lg"
            onClick={() => {
              document.getElementById("csvExpired").click();
            }}
          >
            <h4 style={{ margin: 0, color: "white" }}>
              Download expired members
            </h4>
          </Button>
          <div style={{ display: "none" }}>
            <CsvDownload
              id="csvAtten"
              filename="Member-Details.csv"
              data={
                formatedMembers &&
                formatedMembers.filter(
                  // (item) => moment().format() < moment(item.expiryDate).format()
                  (item) => item.Status === "Good Standing"
                )
              }
            />
          </div>
          <div style={{ display: "none" }}>
            <CsvDownload
              id="csvExpired"
              filename="Expired-Member-Details.csv"
              data={
                formatedMembers &&
                formatedMembers.filter(
                  // (item) => moment().format() > moment(item.expiryDate).format()
                  (item) => item.Status !== "Good Standing"
                )
              }
            />
          </div>
        </div>
      </GridContainer>
      <Footer />
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ overflowY: "scroll" }}>
            <EditeMemberForm data={currentMember} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}
