import db from "./firebase";
import {
  onSnapshot,
  collection,
  setDoc,
  doc,
  getDoc,
  query,
  where,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import moment from "moment";
import { idCheck } from "./validation";
import { child, get, getDatabase, ref } from "firebase/database";
import { registeredVoterCheck } from "./checks";

export const checkMember = async ({
  province,
  region,
  municipality,
  ward,
  votingStation,
  name,
  surname,
  idNumber,
  age,
  gender,
  citizenship,
  race,
  language,
  resAddress,
  cellNumber,
  landlineNumber,
  email,
  occupation,
  qualification,
  dateJoined,
  lastPayment,
  subscription,
  membershipAmount,
  token,
}) => {
  const ref = collection(db, "members");
  let identity;
  let document;

  const dataQuery1 = query(ref, where("idNumber", "==", idNumber));
  await getDocs(dataQuery1)
    .then(async (data) => {
      if (data.docs.length > 0) {
        document = data.docs[0].data();
      } else {
        const dataQuery2 = query(ref, where("rsaIdentityNo", "==", idNumber));
        await getDocs(dataQuery2)
          .then((results) => {
            document = results.docs[0].data();
          })
          .catch(() => {
            // alert(`ID number ${idNumber} is invalid`);
            return "INVALID: The id number is invalid";
          });
      }
    })
    .catch((err) => {
      console.log("ERROR: ", err);
      return "INVALID: The id number is invalid";
    });

  if (identity === null) {
    return "INVALID: The id number is invalid";
  }

  if (typeof document === "undefined") {
    return "INVALID: The id number is invalid";
  }
  if (moment(document.expiryDate).format() < moment().format()) {
    return "Expired";
  }

  const regCheck = await registeredVoterCheck(idNumber, token);
  if (!regCheck.check) {
    if (regCheck.error) {
      return "ERROR (IEC registration check)";
    } else {
      return "INVALID:The id number is not registered";
    }
  }

  return "Good Standing";
};
