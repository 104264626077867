import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";
import GridContainer from "../../components/Grid/GridContainer";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";

import { Button } from "@material-ui/core";
import { Navigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { getAuth } from "firebase/auth";

export default function WardPage(props) {
  const [province, setProvince] = useState();
  const [ward, setWard] = useState();
  const [message, setMessage] = useState();
  const auth = getAuth();

  const { ...rest } = props;

  const authState = useSelector((state) => state);

  if (auth.currentUser && auth.currentUser === null) return <Navigate to="/" />;

  return (
    <div>
      <Header
        absolute
        color="dark"
        brand="Membership Administration"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <GridContainer
        style={{
          height: 600,
          width: "90%",
          marginTop: 80,
          marginRight: 40,
          marginLeft: 40,
          display: "flex",
          flexDirection: "column",
        }}
        justify="center"
      >
        <h1>Communication To Members</h1>
        <p>SMS alerts for members</p>

        {/* <div style={{ marginTop: 120, marginRight: 20, marginLeft: 20 }}> */}

        <FormControl style={{ marginBottom: 10 }} fullWidth>
          <InputLabel id="demo-simple-select-label">Province</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={province}
            label="province"
            onChange={(value) => setProvince(value.target.value)}
          >
            <MenuItem value="Eastern Cape">Eastern Cape</MenuItem>
            <MenuItem value={"Free State"}>Free State</MenuItem>
            <MenuItem value={"Gauteng"}>Gauteng</MenuItem>
            <MenuItem value={"KwaZulu-Natal"}>KwaZulu-Natal</MenuItem>
            <MenuItem value={"Limpopo"}>Limpopo</MenuItem>
            <MenuItem value={"Mpumalanga"}>Mpumalanga</MenuItem>
            <MenuItem value={"North West"}>North West</MenuItem>
            <MenuItem value={"Northern Cape"}>Northern Cape</MenuItem>
            <MenuItem value={"Western Cape"}>Western Cape</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="ward"
          value={ward}
          onChange={(value) => setWard(value.target.value)}
          fullWidth
          label="Ward"
          variant="filled"
          style={{ marginBottom: 10 }}
        />
        <TextField
          id="message"
          value={message}
          onChange={(value) => setMessage(value.target.value)}
          fullWidth
          label="Message"
          variant="filled"
          style={{ marginBottom: 10 }}
          multiline
        />
        <Button
          // simple
          style={{
            color: "black",
            backgroundColor: "#a50100",
            marginTop: 40,
          }}
          // size="lg"
          onClick={() => {
            alert("Message has been sent!");
          }}
          fullWidth
        >
          <h4 style={{ margin: 0, color: "white" }}>
            Send message to all members
          </h4>
        </Button>
      </GridContainer>
      <Footer />
    </div>
  );
}
