import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./hooks/Auth/PrivateRoute";  // Import the PrivateRoute component
import LoginPage from "./screens/Login/LoginPage";
import CreateMemberPage from "./screens/CreateMember/CreateMemberPage";
import ListMembersPage from "./screens/ListMembers/ListMembersPage";
import ReportDirectoryPage from "./screens/Reports/ReportDirectoryPage";
import MemberDetailsPage from "./screens/Reports/MemberDetailsPage";
import DistributionsPage from "./screens/Reports/DistributionsPage";
import UnwantedStatsPage from "./screens/Reports/UnwantedStatsPage";
import WardPage from "./screens/Ward/Ward";
import TargetsPage from "./screens/Targets/Targets";
import AddAccount from "./screens/AddAccount/AddAccount";
import RaceDemographicsPage from "./screens/Demographics/RaceDemographicsPage";
import GenderDemographicsPage from "./screens/Demographics/GenderDemographicsPage";
import AgeDemographicsPage from "./screens/Demographics/AgeDemographicsPage";
import LanguageDemographicsPage from "./screens/Demographics/LanguageDemographicsPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LoginPage />} />
        <Route exact path="/targets" element={<PrivateRoute element={<TargetsPage />} />} />
        <Route path="/create-member" element={<PrivateRoute element={<CreateMemberPage />} />} />
        <Route path="/members-list" element={<PrivateRoute element={<MemberDetailsPage />} />} />
        <Route path="/reports" element={<PrivateRoute element={<ReportDirectoryPage />} />} />
        <Route path="/member-details" element={<PrivateRoute element={<MemberDetailsPage />} />} />
        <Route path="/distributions" element={<PrivateRoute element={<DistributionsPage />} />} />
        <Route path="/age-demographics" element={<PrivateRoute element={<AgeDemographicsPage />} />} />
        <Route path="/race-demographics" element={<PrivateRoute element={<RaceDemographicsPage />} />} />
        <Route path="/gender-demographics" element={<PrivateRoute element={<GenderDemographicsPage />} />} />
        <Route path="/language-demographics" element={<PrivateRoute element={<LanguageDemographicsPage />} />} />
        <Route path="/unwanted-stats" element={<PrivateRoute element={<UnwantedStatsPage />} />} />
        <Route path="/add-account" element={<PrivateRoute element={<AddAccount />} />} />
        <Route path="/wards" element={<PrivateRoute element={<WardPage />} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
