import GridContainer from "../../components/Grid/GridContainer";
import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Card from "../../components/Card/Card";
import { Button, InputAdornment } from "@material-ui/core";
import CustomInput from "../../components/CustomInput/CustomInput";
import { Email } from "@material-ui/icons";
import { useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import * as authActions from "../../store/actions/authActions";
import { getAuth } from "firebase/auth";

export default function AddAccount(props) {
  const { ...rest } = props;
  const authState = useSelector((state) => state);
  const auth = getAuth();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordType, setPasswordType] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const dispatch = useDispatch();

  const user = { email, password };
  const inputChangeE = (value) => {
    setEmail(value.target.value);
  };

  const inputChangeP = (value) => {
    setPassword(value.target.value);
  };

  const togglePassword = () => {
    passwordType === "text"
      ? setPasswordType("password")
      : setPasswordType("text");
  };

  const authHandler = async () => {
    let action;

    action = authActions.signupAdmin(user.email, user.password);
    setError(null);
    setIsLoading(true);
    try {
      await dispatch(action);
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };

  if (auth.currentUser && auth.currentUser === null) return <Navigate to="/" />;

  return (
    <div>
      <Header
        absolute
        color="dark"
        brand="Membership Administration"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <GridContainer
        style={{
          width: "90%",
          marginTop: 80,
          marginRight: 40,
          marginLeft: 40,
          display: "flex",
          flexDirection: "column",
          marginBottom: 40,
        }}
        justifyContent="center"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Card style={{ width: "50%", padding: 15 }}>
            <h3>Add New Account</h3>
            <h4>Please signup the user below:</h4>
            <CustomInput
              labelText="Email..."
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (text) => {
                  inputChangeE(text);
                },
                type: "email",
                endAdornment: (
                  <InputAdornment position="end">
                    <Email />
                  </InputAdornment>
                ),
              }}
            />
            <CustomInput
              labelText="Password..."
              id="Password"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (text) => {
                  inputChangeP(text);
                },
                type: passwordType,
                endAdornment: (
                  <InputAdornment position="end">
                    <div
                      onClick={() => {
                        togglePassword();
                      }}
                    >
                      <VisibilityIcon />
                    </div>
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
            />
            <Button
              simple
              style={{ color: "white", backgroundColor: "#a50100" }}
              size="lg"
              onClick={authHandler}
            >
              Sign Up Admin
            </Button>
          </Card>
        </div>
      </GridContainer>
      <Footer />
    </div>
  );
}
