// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDgEhebJVO9VbPEKQi_NuEProFObMZs1z0",
  authDomain: "eff-menbers.firebaseapp.com",
  projectId: "eff-menbers",
  storageBucket: "eff-menbers.appspot.com",
  messagingSenderId: "746158386879",
  appId: "1:746158386879:web:99706c3b95799242aca00a",
  measurementId: "G-GZ50PK8H6W",
  databaseURL: "https://eff-menbers-default-rtdb.firebaseio.com/",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default getFirestore();
