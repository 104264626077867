/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link, NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown.js";
import Button from "../CustomButtons/Button.js";
import styles from "../../assets/jss/material-kit-react/components/headerLinksStyle.js";
import { logout } from "../../store/actions/authActions.js";
import ADMINS from "../../assets/data/Admins.js";
import { getAuth } from "firebase/auth";
// import * as authActions from "../../store/actions/authActions";

const useStyles = makeStyles(styles);

const LinkItem = ({ name, link }) => {
  const classes = useStyles();
  return (
    <ListItem
      className={classes.listItem}
      style={{
        marginRight: 10,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <NavLink
        style={{
          color: "#BABABA",
          textDecorationLine: "none",
          marginRight: 10,
        }}
        to={link}
      >
        <Button
          color="transparent"
          target="_blank"
          className={classes.navLink}
          style={{
            fontWeight: "bold",
            fontSize: 14,
          }}
        >
          {name}
        </Button>
      </NavLink>
    </ListItem>
  );
};

const LogoutLink = ({ name, func }) => {
  const classes = useStyles();
  return (
    <ListItem
      className={classes.listItem}
      style={{
        marginRight: 10,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <NavLink
        style={{
          color: "#BABABA",
          textDecorationLine: "none",
          marginRight: 10,
        }}
        to="/"
      >
        <Button
          color="transparent"
          target="_blank"
          className={classes.navLink}
          style={{
            fontWeight: "bold",
            fontSize: 14,
          }}
          onClick={func}
        >
          {name}
        </Button>
      </NavLink>
    </ListItem>
  );
};

export default function HeaderLinks(props) {
  const classes = useStyles();
  const dispacth = useDispatch();
  const auth = getAuth();

  return (
    <List className={classes.list}>
      <LinkItem name="Create Member" link="/create-member" />
      <LinkItem name="Member List" link="/members-list" />
      <LinkItem name="Wards" link="/wards" />
      <LinkItem name="Reports" link="/reports" />
      {auth.currentUser && ADMINS.includes(auth.currentUser.email) && (
        <LinkItem name="Add Accounts" link="/add-account" />
      )}
      {/* <LinkItem name="Admin" link="/admin" /> */}
      <LogoutLink
        name="Logout"
        func={() => {
          dispacth(logout());
        }}
      />
    </List>
  );
}
