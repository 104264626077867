import moment from "moment";
import { getDatabase, onValue, ref, set } from "firebase/database";

export const registeredVoterCheck = (idNumber, token) => {
  const requestOptions = {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({
      token:
        "FbZgHZ7NMLYZOWCZgAnExVtgW2lAag1_Nj65FSTKd0Rh62sJ8J897TYNmLah4V6aSnFokipJqn9ZaAKBUG7NHrdDQq_2YlcQankMZMofwOllS3QEBex2e5Q2z4pnKeJcCEVsqiXUuJHTCk1Qg8fS7bE5uP3ZqExN8OBpmK4E8pNWdZRQP9tU_ooexmYsrN9qzEGAS4fVbNFE26XL4wgx1QD7k43hyvwdE1HMgfgnQZ-XlOvdzvOhH5MPHC42agmIvgWHJk2XNktNf8XSdsDM3HgaKxR0ut-60-5BnG40IkepiXsrj7onUiRA-IYIyFf0OIsFvRhBeL9iSzPlVpHlHUjg4czoR1UIFN3SrdR1ERg6uO8LRUJlbgZHWs_p1lffovpJKsgKIqMBqLX6bPp6JEB0gedcFT-Nx04VxCu2lXsPCNQG",
      idNumber: "9903205143080",
    }),
  };

  const isRegistered = fetch(
    `https://boiling-temple-37318.herokuapp.com/regCheck/${idNumber}/${token}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((data) => {
      return { check: data.VoterStatusID !== 0, error: false };
    })
    .catch((err) => {
      return { check: false, error: true };
    });

  return isRegistered;
};

export const renewIecToken = () => {
  const currentDate = new Date();
  const rtdb = getDatabase();
  const countRef = ref(rtdb, "iec");
  onValue(countRef, (snapshot) => {
    const data = snapshot.val();
    if (
      moment().isAfter(moment(data.lastFetch).add(1, "days").format()) ||
      !data.token
    ) {
      const requestOptions = {
        method: "GET",
      };
      fetch(
        "https://boiling-temple-37318.herokuapp.com/renewToken",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          set(ref(rtdb, "iec"), {
            token: data.access_token,
            lastFetch: moment(currentDate).format(),
          });
        })
        .catch((err) => {
          console.log("renew error: ", err);
        });
    } else {
      return;
    }
    return;
  });
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase();
}
