import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";
import GridContainer from "../../components/Grid/GridContainer";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { collection, getDocs, where } from "firebase/firestore";
import db from "../../api/firebase";
import {
  ADMINS,
  MUNICIPALITIES,
  PROVINCES,
  REGIONS,
  WARDS,
} from "../../assets/data";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { getDatabase, onValue, query, ref } from "firebase/database";
import { getProvinces } from "../../assets/data/Provinces";
import moment from "moment";
import { Button, CircularProgress } from "@mui/material";
import StopImage from "../../assets/images/pexels-mwabonje-1806900.jpg";
import GridItem from "../../components/Grid/GridItem";
import {
  ageColumns,
  ageMunicipalityColumns,
  ageRegionColumns,
  ageWardColumns,
} from "../Reports/Columns";
import CsvDownload from "react-json-to-csv";
import { getAge } from "../../api/validation";
import { getAuth } from "firebase/auth";

export default function AgeDemographicsPage(props) {
  const [members, setMembers] = useState();
  const [data, setData] = useState([]);
  const [regions, setRegions] = useState();
  const [municipalities, setMunicipalities] = useState();
  const [wards, setWards] = useState();
  const [regionsData, setRegionsData] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalGS, setTotalGS] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [provinceTitle, setProvinceTitle] = useState("");
  const [regionTitle, setRegionTitle] = useState("");
  const [municipalityTitle, setMunicipalityTitle] = useState("");

  const dbRef = collection(db, "members");
  const auth = getAuth();

  const sumOfMembers = (arrayOfMembers) => {
    let total = 0;
    for (let i = 0; i < arrayOfMembers.length; i++) {
      total = total + arrayOfMembers[i];
    }
    return total;
  };

  const rtdb = getDatabase();
  useEffect(() => {
    const countRef = ref(rtdb, "regions");
    setIsLoading(true);
    onValue(countRef, (snapshot) => {
      let regionsArray = [];
      const data = snapshot.val();

      REGIONS.forEach((region) => {
        const selectedRegion = region.Name;
        if (selectedRegion === "ZF MAQCAWU/SIYANDA") {
          const zfdata = data["ZF MAQCAWU"].SIYANDA;
          regionsArray = [...regionsArray, { ...zfdata, id: zfdata.regionId }];
          setRegionsData(regionsArray);
        } else if (data[selectedRegion]) {
          regionsArray = [
            ...regionsArray,
            { ...data[selectedRegion], id: data[selectedRegion].regionId },
          ];
          setRegionsData(regionsArray);
        }
        setIsLoading(false);
      });
    });
  }, [rtdb]);

  useEffect(() => {
    let count = 0;
    let gsCount = 0;
    regionsData.forEach((region) => {
      count = count + region.total;
      gsCount = gsCount + region.goodStanding;
    });
    setTotal(count);
    setTotalGS(gsCount);
  }, [regionsData]);

  const regionDistributions = (province) => {
    setIsLoading(true);
    const provinceId =
      getProvinces().provinceIdMap[province.toLocaleLowerCase().trim()];
    const filteredRegions = regionsData.filter(
      (region) => region.provinceId === provinceId
    );

    const regionDemographics = filteredRegions.map((region) => ({
      region: region.region,
      id: region.id,
      below16GoodStanding: region.ageDemographics.below16.goodStanding,
      below16Total: region.ageDemographics.below16.total,
      age16GoodStanding: region.ageDemographics.age16.goodStanding,
      age16Total: region.ageDemographics.age16.total,
      age17GoodStanding: region.ageDemographics.age17.goodStanding,
      age17Total: region.ageDemographics.age17.total,
      ages18to35GoodStanding: region.ageDemographics.ages18to35.goodStanding,
      ages18to35Total: region.ageDemographics.ages18to35.total,
      ages36to49GoodStanding: region.ageDemographics.ages36to49.goodStanding,
      ages36to49Total: region.ageDemographics.ages36to49.total,
      ages50to65GoodStanding: region.ageDemographics.ages50to65.goodStanding,
      ages50to65Total: region.ageDemographics.ages50to65.total,
      over65GoodStanding: region.ageDemographics.over65.goodStanding,
      over65Total: region.ageDemographics.over65.total,
      totalGoodStanding: region.goodStanding,
      total: region.total,
    }));

    setRegions(regionDemographics);
    setIsLoading(false);
  };

  const municipalityDistributions = (region) => {
    setIsLoading(true);

    let arrayOfMembers = [];
    const dataQuery = query(dbRef, where("region", "==", region.region));

    getDocs(dataQuery).then((value) => {
      const secondArray = arrayOfMembers.concat(value.docs);
      let uniqueIds = [];
      let uniqueDocs = [];
      let duplicateDocs = [];

      secondArray.forEach((doc) => {
        const id = doc.data().idNumber;
        if (!uniqueIds.includes(id)) {
          uniqueDocs.push(doc);
          uniqueIds.push(id);
        } else {
          duplicateDocs.push(doc);
          // deleteMember(id);
        }
      });

      setMembers(uniqueDocs);

      const municipalityInfo = MUNICIPALITIES.filter(
        (item) => item.Region_RegionId === region.id
      );

      let municpalitiesArray = [];
      municipalityInfo.forEach((muni) => {
        const totalInMuni = uniqueDocs.filter(
          (doc) =>
            doc.data().municipality === muni.Name &&
            doc.data().invalid === false
        );

        const totalInMuniGoodStanding = totalInMuni.filter(
          (doc) =>
            moment(doc.data().lastPayment).format() >
            moment().subtract(2, "years").format()
        );

        const muniDistObject = {
          id: muni.MunicipalityId,
          regionId: muni.Region_RegionId,
          municipality: muni.Name,
          municipalityId: muni.MunicipalityId,
          below16GoodStanding: totalInMuniGoodStanding.filter(
            (doc) => getAge(doc.data().idNumber) < 16
          ).length,
          below16Total: totalInMuni.filter(
            (doc) => getAge(doc.data().idNumber) < 16
          ).length,

          age16GoodStanding: totalInMuniGoodStanding.filter(
            (doc) => getAge(doc.data().idNumber) === 16
          ).length,
          age16Total: totalInMuni.filter(
            (doc) => getAge(doc.data().idNumber) === 16
          ).length,

          age17GoodStanding: totalInMuniGoodStanding.filter(
            (doc) => getAge(doc.data().idNumber) === 17
          ).length,
          age17Total: totalInMuni.filter(
            (doc) => getAge(doc.data().idNumber) === 17
          ).length,

          ages18to35GoodStanding: totalInMuniGoodStanding.filter(
            (doc) =>
              getAge(doc.data().idNumber) >= 18 &&
              getAge(doc.data().idNumber) <= 35
          ).length,
          ages18to35Total: totalInMuni.filter(
            (doc) =>
              getAge(doc.data().idNumber) >= 18 &&
              getAge(doc.data().idNumber) <= 35
          ).length,

          ages36to49GoodStanding: totalInMuniGoodStanding.filter(
            (doc) =>
              getAge(doc.data().idNumber) >= 36 &&
              getAge(doc.data().idNumber) <= 49
          ).length,
          ages36to49Total: totalInMuni.filter(
            (doc) =>
              getAge(doc.data().idNumber) >= 36 &&
              getAge(doc.data().idNumber) <= 49
          ).length,

          ages50to65GoodStanding: totalInMuniGoodStanding.filter(
            (doc) =>
              getAge(doc.data().idNumber) >= 50 &&
              getAge(doc.data().idNumber) <= 65
          ).length,
          ages50to65Total: totalInMuni.filter(
            (doc) =>
              getAge(doc.data().idNumber) >= 50 &&
              getAge(doc.data().idNumber) <= 65
          ).length,

          over65GoodStanding: totalInMuniGoodStanding.filter(
            (doc) => getAge(doc.data().idNumber) > 65
          ).length,
          over65Total: totalInMuni.filter(
            (doc) => getAge(doc.data().idNumber) > 65
          ).length,

          totalGoodStanding: totalInMuniGoodStanding.length,
          total: totalInMuni.length,
        };
        municpalitiesArray = [...municpalitiesArray, muniDistObject];
        setMunicipalities(municpalitiesArray);
      });
      setIsLoading(false);
    });
  };

  const wardDistributions = (municipality) => {
    setIsLoading(true);
    const wardInfo = WARDS.filter(
      (item) => item.MunicipalityId === municipality.id
    );

    let wardArray = [];
    wardInfo.forEach((ward) => {
      const totalInWard = members.filter(
        (doc) =>
          doc.data().ward === ward.WardName && doc.data().invalid === false
      );

      const totalInWardGoodStanding = totalInWard.filter(
        (doc) =>
          moment(doc.data().lastPayment).format() >
          moment().subtract(2, "years").format()
      );

      const wardDistObject = {
        id: ward.WardName,
        municipalityId: ward.MunicipalityId,
        ward: ward.WardName,
        wardId: ward.WardId,
        below16GoodStanding: totalInWardGoodStanding.filter(
          (doc) => getAge(doc.data().idNumber) < 16
        ).length,
        below16Total: totalInWard.filter(
          (doc) => getAge(doc.data().idNumber) < 16
        ).length,

        age16GoodStanding: totalInWardGoodStanding.filter(
          (doc) => getAge(doc.data().idNumber) === 16
        ).length,
        age16Total: totalInWard.filter(
          (doc) => getAge(doc.data().idNumber) === 16
        ).length,

        age17GoodStanding: totalInWardGoodStanding.filter(
          (doc) => getAge(doc.data().idNumber) === 17
        ).length,
        age17Total: totalInWard.filter(
          (doc) => getAge(doc.data().idNumber) === 17
        ).length,

        ages18to35GoodStanding: totalInWardGoodStanding.filter(
          (doc) =>
            getAge(doc.data().idNumber) >= 18 &&
            getAge(doc.data().idNumber) <= 35
        ).length,
        ages18to35Total: totalInWard.filter(
          (doc) =>
            getAge(doc.data().idNumber) >= 18 &&
            getAge(doc.data().idNumber) <= 35
        ).length,

        ages36to49GoodStanding: totalInWardGoodStanding.filter(
          (doc) =>
            getAge(doc.data().idNumber) >= 36 &&
            getAge(doc.data().idNumber) <= 49
        ).length,
        ages36to49Total: totalInWard.filter(
          (doc) =>
            getAge(doc.data().idNumber) >= 36 &&
            getAge(doc.data().idNumber) <= 49
        ).length,

        ages50to65GoodStanding: totalInWardGoodStanding.filter(
          (doc) =>
            getAge(doc.data().idNumber) >= 50 &&
            getAge(doc.data().idNumber) <= 65
        ).length,
        ages50to65Total: totalInWard.filter(
          (doc) =>
            getAge(doc.data().idNumber) >= 50 &&
            getAge(doc.data().idNumber) <= 65
        ).length,

        over65GoodStanding: totalInWardGoodStanding.filter(
          (doc) => getAge(doc.data().idNumber) > 65
        ).length,
        over65Total: totalInWard.filter(
          (doc) => getAge(doc.data().idNumber) > 65
        ).length,

        totalGoodStanding: totalInWardGoodStanding.length,
        total: totalInWard.length,
      };
      wardArray = [...wardArray, wardDistObject];

      setWards(wardArray);
      setIsLoading(false);
    });
  };
  const { ...rest } = props;

  useEffect(() => {
    let provinceArray = [];
    if (regionsData) {
      PROVINCES.forEach((province) => {
        let provinceBelow16Total = 0;
        let provinceBelow16TotalGs = 0;
        let provinceAge16Total = 0;
        let provinceAge16TotalGs = 0;
        let provinceAge17Total = 0;
        let provinceAge17TotalGs = 0;
        let provinceAges18to35Total = 0;
        let provinceAges18to35TotalGs = 0;
        let provinceAges36to49Total = 0;
        let provinceAges36to49TotalGs = 0;
        let provinceAges50to65Total = 0;
        let provinceAges50to65TotalGs = 0;
        let provinceOver65Total = 0;
        let provinceOver65TotalGs = 0;
        let total = 0;
        let totalGs = 0;
        regionsData
          .filter((item) => item.provinceId === province.ProvinceId)
          .forEach((region) => {
            provinceBelow16Total =
              provinceBelow16Total + region.ageDemographics.below16.total;
            provinceBelow16TotalGs =
              provinceBelow16TotalGs +
              region.ageDemographics.below16.goodStanding;
            provinceAge16Total =
              provinceAge16Total + region.ageDemographics.age16.total;
            provinceAge16TotalGs =
              provinceAge16TotalGs + region.ageDemographics.age16.goodStanding;
            provinceAge17Total =
              provinceAge17Total + region.ageDemographics.age17.total;
            provinceAge17TotalGs =
              provinceAge17TotalGs + region.ageDemographics.age17.goodStanding;
            provinceAges18to35Total =
              provinceAges18to35Total + region.ageDemographics.ages18to35.total;
            provinceAges18to35TotalGs =
              provinceAges18to35TotalGs +
              region.ageDemographics.ages18to35.goodStanding;
            provinceAges36to49Total =
              provinceAges36to49Total + region.ageDemographics.ages36to49.total;
            provinceAges36to49TotalGs =
              provinceAges36to49TotalGs +
              region.ageDemographics.ages36to49.goodStanding;
            provinceAges50to65Total =
              provinceAges50to65Total + region.ageDemographics.ages50to65.total;
            provinceAges50to65TotalGs =
              provinceAges50to65TotalGs +
              region.ageDemographics.ages50to65.goodStanding;
            provinceOver65Total =
              provinceOver65Total + region.ageDemographics.over65.total;
            provinceOver65TotalGs =
              provinceOver65TotalGs +
              region.ageDemographics.over65.goodStanding;
            total = total + region.total;
            totalGs = totalGs + region.goodStanding;
          });
        let prvObject = {
          province: province.Name,
          id: province.ProvinceId,
          below16GoodStanding: provinceBelow16TotalGs,
          below16Total: provinceBelow16Total,
          age16GoodStanding: provinceAge16TotalGs,
          age16Total: provinceAge16Total,
          age17GoodStanding: provinceAge17TotalGs,
          age17Total: provinceAge17Total,
          ages18to35GoodStanding: provinceAges18to35TotalGs,
          ages18to35Total: provinceAges18to35Total,
          ages36to49GoodStanding: provinceAges36to49TotalGs,
          ages36to49Total: provinceAges36to49Total,
          ages50to65GoodStanding: provinceAges50to65TotalGs,
          ages50to65Total: provinceAges50to65Total,
          over65GoodStanding: provinceOver65TotalGs,
          over65Total: provinceOver65Total,
          totalGoodStanding: totalGs,
          total: total,
        };
        provinceArray = [...provinceArray, prvObject];
      });
      setData(provinceArray);
    }
  }, [regionsData]);

  if (auth.currentUser && auth.currentUser === null) return <Navigate to="/" />;

  if (auth.currentUser && !ADMINS.includes(auth.currentUser.email)) {
    return (
      <div
        style={{
          backgroundImage: "url(" + StopImage + ")",
          // backgroundSize: "repeat",
          backgroundPosition: "right 55% bottom 75%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Header
          absolute
          color="dark"
          brand="Membership Administration"
          rightLinks={<HeaderLinks />}
          {...rest}
        />

        <GridContainer
          style={{
            height: 600,
            width: "90%",
            marginTop: 80,
            marginRight: 40,
            marginLeft: 40,
            display: "flex",
            flexDirection: "column",
            marginBottom: 30,
          }}
        >
          <GridItem xs={12} sm={12} md={4}>
            <div>
              <h1 style={{ fontWeight: "bolder" }}>
                YOU DO NOT HAVE PERMISSION TO VIEW THE DISTRIBUTIONS
              </h1>
            </div>
          </GridItem>
        </GridContainer>
        <Footer />
      </div>
    );
  }
  return (
    <div>
      <Header
        absolute
        color="dark"
        brand="Membership Administration"
        rightLinks={<HeaderLinks />}
        {...rest}
      />

      <GridContainer
        style={{
          height: 600,
          width: "90%",
          marginTop: 80,
          marginRight: 40,
          marginLeft: 40,
          display: "flex",
          flexDirection: "column",
          marginBottom: 30,
        }}
      >
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1 style={{ marginTop: 30 }}>Age Demographics</h1>
            <div
              style={{
                width: 250,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <p style={{ fontWeight: "bolder" }}>Total members:</p>
                <p style={{ fontWeight: "bolder", color: "#a50100" }}>
                  {total}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <p style={{ fontWeight: "bolder" }}>Total valid members:</p>
                <p style={{ fontWeight: "bolder", color: "#a50100" }}>
                  {totalGS}
                </p>
              </div>
            </div>
          </div>
          <div style={{ marginBottom: 50 }}>
            <h2 style={{ marginTop: 10 }}>Province</h2>
            <div style={{ height: 600, display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1, marginRight: 20 }}>
                <DataGrid
                  rows={data}
                  columns={ageColumns}
                  pageSize={30}
                  rowsPerPageOptions={[5]}
                  //   checkboxSelection
                  disableSelectionOnClick
                  onRowClick={(data) => {
                    setProvinceTitle(data.row.province);
                    regionDistributions(data.row.province);
                  }}
                />
              </div>
            </div>
          </div>

          {regions && (
            <div style={{ marginBottom: 50 }}>
              <h2 style={{ marginTop: 10 }}>Regions in {provinceTitle}</h2>
              <div
                style={{ height: 600, display: "flex", flexDirection: "row" }}
              >
                <div style={{ flex: 1, marginRight: 20 }}>
                  <DataGrid
                    rows={regions}
                    columns={ageRegionColumns}
                    pageSize={30}
                    rowsPerPageOptions={[5]}
                    //   checkboxSelection
                    disableSelectionOnClick
                    onRowClick={(data) => {
                      setRegionTitle(data.row.region);
                      municipalityDistributions(data.row);
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {municipalities && (
            <div style={{ marginBottom: 50 }}>
              <h2 style={{ marginTop: 10 }}>Municipalities in {regionTitle}</h2>
              <div
                style={{ height: 600, display: "flex", flexDirection: "row" }}
              >
                <div style={{ flex: 1, marginRight: 20 }}>
                  <DataGrid
                    rows={municipalities}
                    columns={ageMunicipalityColumns}
                    pageSize={30}
                    rowsPerPageOptions={[5]}
                    //   checkboxSelection
                    disableSelectionOnClick
                    onRowClick={(data) => {
                      setMunicipalityTitle(data.row.municipality);
                      wardDistributions(data.row);
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {wards && (
            <div style={{ marginBottom: 50 }}>
              <h2 style={{ marginTop: 10 }}>Wards in {municipalityTitle}</h2>
              <div
                style={{ height: 600, display: "flex", flexDirection: "row" }}
              >
                <div style={{ flex: 1, marginRight: 20 }}>
                  <DataGrid
                    rows={wards}
                    columns={ageWardColumns}
                    pageSize={100}
                    rowsPerPageOptions={[5]}
                    //   checkboxSelection
                    disableSelectionOnClick
                    onRowClick={(data) => {
                      // municipalityDistributions(data.row);
                    }}
                  />
                </div>
              </div>
              <Button
                style={{
                  color: "black",
                  backgroundColor: "#a50100",
                  marginTop: 50,
                }}
                onClick={() => {
                  document.getElementById("wardDistributions").click();
                }}
              >
                <h4 style={{ margin: 0, color: "white" }}>
                  Download Ward Distributions
                </h4>
              </Button>
              <div style={{ display: "none" }}>
                <CsvDownload
                  id="wardDistributions"
                  filename={`Ward-Distributions-${municipalityTitle}.csv`}
                  data={wards.map((item) => ({
                    Ward: item.ward,
                    "Valid Members": item.goodStanding,
                    "Total Members": item.total,
                  }))}
                />
              </div>
            </div>
          )}
        </div>
      </GridContainer>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            zIndex: 7,
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            // width: "100vh",
            // height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {/* <div style={{ marginTop: 50 }}>
        <Footer />
      </div> */}
    </div>
  );
}
