import { useState } from "react";

const VOTINGSTATIONS = [
  {
    VotingStationId: "1",
    WardLookupId: "2863",
    VDNumber: "10590151",
    RegisteredPopulation: "2637",
    VotingStationName: "PEFFERVILLE CLINIC",
    Address: "3619 ROTTERDAM ROAD  PEFFERVILLE  EAST LONDON",
    Latitude: "-33.0119",
    Longitude: "27.8807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2",
    WardLookupId: "2863",
    VDNumber: "10590588",
    RegisteredPopulation: "2010",
    VotingStationName: "EAST LONDON HIGH SCHOOL",
    Address: "70 MAPLE LEAF AVENUE  BRAELYN  EAST LONDON",
    Latitude: "-33.0024",
    Longitude: "27.8864",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "3",
    WardLookupId: "2863",
    VDNumber: "10590858",
    RegisteredPopulation: "2549",
    VotingStationName: "SHAD MASHOLOGU MEMORIAL BAPTIST CHURCH",
    Address: "117 BASHE STREET  DUNCAN VILLAGE  EAST LONDON",
    Latitude: "-33.0065",
    Longitude: "27.8777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "4",
    WardLookupId: "2863",
    VDNumber: "10590869",
    RegisteredPopulation: "1496",
    VotingStationName: "MASAKHE PUBLIC SCHOOL",
    Address: "35 MAZWI STREET  DUNCAN VILLAGE  EAST LONDON",
    Latitude: "-33.0074",
    Longitude: "27.8796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "5",
    WardLookupId: "2863",
    VDNumber: "10591297",
    RegisteredPopulation: "568",
    VotingStationName: "BRAELYN COMMUNITY HALL",
    Address: "VERSUVIUS ROAD  BRAELYN   EAST LONDON",
    Latitude: "-32.9999",
    Longitude: "27.8794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "6",
    WardLookupId: "2864",
    VDNumber: "10590847",
    RegisteredPopulation: "2368",
    VotingStationName: "ST PETERS CLOVER CATHOLIC CHURCH",
    Address: "2 GODLO STREET  DUNCAN VILLAGE  EAST LONDON",
    Latitude: "-33.006",
    Longitude: "27.8751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "7",
    WardLookupId: "2864",
    VDNumber: "10591017",
    RegisteredPopulation: "2073",
    VotingStationName: "GOMPO COMMUNITY HALL",
    Address: "DOUGLAS SMITH HIGHWAY  GOMPO  EAST LONDON",
    Latitude: "-33.0097",
    Longitude: "27.8747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "8",
    WardLookupId: "2864",
    VDNumber: "10591039",
    RegisteredPopulation: "1448",
    VotingStationName: "BHEBHELELE EDUCARE CENTRE",
    Address: "NDZUZO STREET  DUNCAN VILLAGE  EAST LONDON",
    Latitude: "-33.0102",
    Longitude: "27.8689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "9",
    WardLookupId: "2864",
    VDNumber: "10591354",
    RegisteredPopulation: "940",
    VotingStationName: "NEW   JESUS APOSTOLIC CHURCH IN ZION",
    Address: "F1300 C SECTION   DUNCAN VILLAGE   EAST LONDON",
    Latitude: "-33.0104",
    Longitude: "27.8676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "10",
    WardLookupId: "2864",
    VDNumber: "10591398",
    RegisteredPopulation: "1083",
    VotingStationName: "MASINCEDANE DAY CARE",
    Address: "38830 NONYAMEKO MXENGE STREET   DUNCAN VILLAGE   EAST LONDON",
    Latitude: "-33.0073",
    Longitude: "27.8652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "11",
    WardLookupId: "2865",
    VDNumber: "10590656",
    RegisteredPopulation: "2621",
    VotingStationName: "COACH HOUSE & ANN BRYANT ART GALLERY",
    Address: "9 ST. MARKS ROAD  SOUTHERNWOOD  EAST LONDON",
    Latitude: "-33.0004",
    Longitude: "27.8974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "12",
    WardLookupId: "2865",
    VDNumber: "10590667",
    RegisteredPopulation: "3468",
    VotingStationName: "BUFFALO CITY PUBLIC FET COLLEGE",
    Address: "LUKIN ROAD  SOUTHERNWOOD  EAST LONDON",
    Latitude: "-32.9962",
    Longitude: "27.8994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "13",
    WardLookupId: "2865",
    VDNumber: "10591107",
    RegisteredPopulation: "1489",
    VotingStationName: "BAYSVILLE SPECIAL SCHOOL",
    Address: "3 BURNETT ROAD  BAYSVILLE  EAST LONDON",
    Latitude: "-32.9963",
    Longitude: "27.9133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "14",
    WardLookupId: "2866",
    VDNumber: "10590522",
    RegisteredPopulation: "2543",
    VotingStationName: "VOORPOS PRIMARY SCHOOL",
    Address: "FRONTIER RD  CAMBRIDGE WEST  EAST LONDON",
    Latitude: "-32.9743",
    Longitude: "27.8679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "15",
    WardLookupId: "2866",
    VDNumber: "10590678",
    RegisteredPopulation: "2547",
    VotingStationName: "HUDSON PARK HIGH SCHOOL",
    Address: "DEVEREUX AVENUE  VINCENT  EAST LONDON",
    Latitude: "-32.9856",
    Longitude: "27.9161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "16",
    WardLookupId: "2866",
    VDNumber: "10590780",
    RegisteredPopulation: "3063",
    VotingStationName: "CAMBRIDGE TOWN HALL",
    Address: "MAYFAIR AVE  CAMBRIDGE  EAST LONDON",
    Latitude: "-32.9738",
    Longitude: "27.8861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "17",
    WardLookupId: "2866",
    VDNumber: "10591141",
    RegisteredPopulation: "1318",
    VotingStationName: "CAMBRIDGE HIGH SCHOOL",
    Address: "GARCIA STREET  CAMBRIDGE   BUFFALO CITY [EAST LONDON]",
    Latitude: "-32.9817",
    Longitude: "27.8831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "18",
    WardLookupId: "2867",
    VDNumber: "10590498",
    RegisteredPopulation: "1657",
    VotingStationName: "MBULELO PUBLIC SCHOOL",
    Address: "37187 PHASE 1  SCENERY PARK  EAST LONDON",
    Latitude: "-32.9962",
    Longitude: "27.8195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "19",
    WardLookupId: "2867",
    VDNumber: "10590892",
    RegisteredPopulation: "1673",
    VotingStationName: "ZWELEMFUNDO PUBLIC SCHOOL",
    Address: "37650 PHASE 3  SCENERY PARK   EAST LONDON",
    Latitude: "-32.9967",
    Longitude: "27.8296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "20",
    WardLookupId: "2867",
    VDNumber: "10590904",
    RegisteredPopulation: "3254",
    VotingStationName: "JOHN DUBE CLINIC",
    Address: "35616 PHASE 1  SCENERY PARK  EAST LONDON",
    Latitude: "-32.9912",
    Longitude: "27.8161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "21",
    WardLookupId: "2867",
    VDNumber: "10591208",
    RegisteredPopulation: "1247",
    VotingStationName: "UVIWE HIGH SCHOOL",
    Address: "35811 PHASE 1  SCENERY PARK   BUFFALO CITY [EAST LONDON]",
    Latitude: "-32.9935",
    Longitude: "27.8242",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "22",
    WardLookupId: "2867",
    VDNumber: "10591365",
    RegisteredPopulation: "1171",
    VotingStationName: "HIGHWAY GARDENS (TENT)",
    Address: "DICKING ROAD  HIGHWAYS GARDENS  EAST LONDON",
    Latitude: "-32.9925",
    Longitude: "27.8242",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "23",
    WardLookupId: "2867",
    VDNumber: "10591387",
    RegisteredPopulation: "484",
    VotingStationName: "AIPORT TENT (SCENERY PARK)",
    Address: "43163 PHASE 3 AIRPORT   SCENERY PARK   EAST LONDON",
    Latitude: "-32.9975",
    Longitude: "27.8155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "24",
    WardLookupId: "2868",
    VDNumber: "10590601",
    RegisteredPopulation: "2084",
    VotingStationName: "NATIONAL BAPTIST CHURCH",
    Address: "XEWU STREET   DUNCAN VILLAGE  EAST LONDON",
    Latitude: "-33.0126",
    Longitude: "27.871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "25",
    WardLookupId: "2868",
    VDNumber: "10590803",
    RegisteredPopulation: "2256",
    VotingStationName: "ELUXOLWENI COMMUNITY HALL",
    Address: "CC LOYD SECTION,BOND STREET   DUNCAN VILLAGE  EAST LONDON",
    Latitude: "-33.0132",
    Longitude: "27.8752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "26",
    WardLookupId: "2868",
    VDNumber: "10590814",
    RegisteredPopulation: "1725",
    VotingStationName: "PARKSIDE COMMUNITY HALL",
    Address: "WYNDRIDGE ROAD  PARKSIDE   EAST LONDON",
    Latitude: "-33.0152",
    Longitude: "27.8901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "27",
    WardLookupId: "2868",
    VDNumber: "10590915",
    RegisteredPopulation: "1916",
    VotingStationName: "WORKBENCH CENTRE PROTECTIVE WORKSHOP",
    Address: "4 VILLETE STREET  PARKRIDGE  EAST LONDON",
    Latitude: "-33.014",
    Longitude: "27.8802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "28",
    WardLookupId: "2868",
    VDNumber: "10591321",
    RegisteredPopulation: "194",
    VotingStationName: "MOSCOW INFORMAL SETTLEMENT (TENT)",
    Address: "MOMOTI STREET   DUNCAN VILLAGE  EAST LONDON",
    Latitude: "-33.0176",
    Longitude: "27.8668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "29",
    WardLookupId: "2869",
    VDNumber: "10590533",
    RegisteredPopulation: "1604",
    VotingStationName: "GOMPO PRESBYTERIAN CHURCH",
    Address: "29291 CORNER MANGALA & SOMTHUNZI STR  GOMPO TOWN  EAST LONDON",
    Latitude: "-33.0112",
    Longitude: "27.8591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "30",
    WardLookupId: "2869",
    VDNumber: "10590544",
    RegisteredPopulation: "2692",
    VotingStationName: "C-CLINIC",
    Address: "JABAVU & DOUGLAS STREET  DUNCAN VILLAGE  EAST LONDON",
    Latitude: "-33.012",
    Longitude: "27.8639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "31",
    WardLookupId: "2869",
    VDNumber: "10590791",
    RegisteredPopulation: "1926",
    VotingStationName: "INYATI PUBLIC PRIMARY SCHOOL",
    Address: "29649 MAKATALA HIGHWAY  GOMPO TOWN  EAST LONDON",
    Latitude: "-33.0084",
    Longitude: "27.8625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "32",
    WardLookupId: "2869",
    VDNumber: "10591006",
    RegisteredPopulation: "704",
    VotingStationName: "EBENEZER MAJOMBOZI HIGH SCHOOL",
    Address: "2A MAKATALA STREET, DOUGLAS SMITH  DUNCAN VILLAGE  EAST LONDON",
    Latitude: "-33.0161",
    Longitude: "27.852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "33",
    WardLookupId: "2870",
    VDNumber: "10590612",
    RegisteredPopulation: "1147",
    VotingStationName: "DUNCAN VILLAGE LIBRARY",
    Address: "24 BOTTOMAN STREET  DUNCAN VILLAGE  EAST LONDON",
    Latitude: "-33.0144",
    Longitude: "27.8584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "34",
    WardLookupId: "2870",
    VDNumber: "10590623",
    RegisteredPopulation: "1562",
    VotingStationName: "MAKINANA LOWER/HIGHER PRIMARY SCHOOL",
    Address: "JWAYI STREET  DUNCAN VILLAGE  EAST LONDON",
    Latitude: "-33.0167",
    Longitude: "27.8589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "35",
    WardLookupId: "2870",
    VDNumber: "10590836",
    RegisteredPopulation: "2005",
    VotingStationName: "KUSILE COMPREHENSIVE SCHOOL",
    Address: "MSIMANGO STREET  DUNCAN VILLAGE  EAST LONDON",
    Latitude: "-33.0154",
    Longitude: "27.8658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "36",
    WardLookupId: "2870",
    VDNumber: "10590982",
    RegisteredPopulation: "2108",
    VotingStationName: "ASPIRANZA PRIMARY SCHOOL",
    Address: "DIPPENAAR CIRCLE  BUFFALO FLATS  EAST LONDON",
    Latitude: "-33.0163",
    Longitude: "27.8466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "37",
    WardLookupId: "2870",
    VDNumber: "10590993",
    RegisteredPopulation: "523",
    VotingStationName: "GREENPOINT SECONDARY SCHOOL",
    Address: "19 DUNOON ROAD  BUFFALO FLATS  EAST LONDON",
    Latitude: "-33.02",
    Longitude: "27.8543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "38",
    WardLookupId: "2870",
    VDNumber: "10591118",
    RegisteredPopulation: "643",
    VotingStationName: "SIKHULILE EDUCARE CENTRE",
    Address: "30781 MAGOSWANA STREET  GOMPO TOWN  EAST LONDON",
    Latitude: "-33.0206",
    Longitude: "27.8615",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "39",
    WardLookupId: "2870",
    VDNumber: "10591129",
    RegisteredPopulation: "795",
    VotingStationName: "GOMPO FOR THE AGED",
    Address:
      "33656 MPUTSHE STREEET, CNR DOUGLAS     DUNCAN VILLAGE    EAST LONDON",
    Latitude: "-33.0138",
    Longitude: "27.861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "40",
    WardLookupId: "2871",
    VDNumber: "10590577",
    RegisteredPopulation: "2178",
    VotingStationName: "CHRIST CHURCH",
    Address: "116 AMALINDA MAIN ROAD  AMALINDA  EAST LONDON",
    Latitude: "-32.9906",
    Longitude: "27.8624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "41",
    WardLookupId: "2871",
    VDNumber: "10590870",
    RegisteredPopulation: "2582",
    VotingStationName: "BRAELYN LIBRARY",
    Address: "BRAELYN EXT.10  BRAELYN  BUFFALO CITY",
    Latitude: "-33.0042",
    Longitude: "27.8611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "42",
    WardLookupId: "2871",
    VDNumber: "10591040",
    RegisteredPopulation: "2155",
    VotingStationName: "AMALINDA BAPTIST CHURCH",
    Address: "AMALINDA  BUFFALO CITY",
    Latitude: "-32.9951",
    Longitude: "27.8768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "43",
    WardLookupId: "2871",
    VDNumber: "10591130",
    RegisteredPopulation: "2151",
    VotingStationName: "AMALINDA PLAY CENTRE (TENT)",
    Address: "WELLBELOVED STREET  AMALINDA  EAST LONDON",
    Latitude: "-32.9952",
    Longitude: "27.8696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "44",
    WardLookupId: "2872",
    VDNumber: "10590465",
    RegisteredPopulation: "2000",
    VotingStationName: "ALPHENDALE SECONDARY SCHOOL",
    Address: "134 ALPHEN ROAD  BUFFALO FLATS  EAST LONDON",
    Latitude: "-33.0145",
    Longitude: "27.8434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "45",
    WardLookupId: "2872",
    VDNumber: "10590476",
    RegisteredPopulation: "3310",
    VotingStationName: "RIVER  OF LIFE  DAY CARE  CENTRE",
    Address: "70 ELEPHANT ROAD  EAST BANK  EAST LONDON",
    Latitude: "-33.0082",
    Longitude: "27.8354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "46",
    WardLookupId: "2872",
    VDNumber: "10590487",
    RegisteredPopulation: "1088",
    VotingStationName: "CLEMENT  KADALIE   COMMUNITY HALL",
    Address: "20 MZWANELE ROAD  BRAELYN  EAST LONDON",
    Latitude: "-33.0064",
    Longitude: "27.8575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "47",
    WardLookupId: "2872",
    VDNumber: "10591196",
    RegisteredPopulation: "1600",
    VotingStationName: "NATIONAL BAPTIST CHURCH",
    Address: "WOOLWASH ROAD  AMALINDA  BUFFALO CITY [EAST LONDON]",
    Latitude: "-33.0008",
    Longitude: "27.8353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "48",
    WardLookupId: "2872",
    VDNumber: "10591219",
    RegisteredPopulation: "1747",
    VotingStationName: "NEW GENERATION SCHOOL",
    Address: "EMERALD STREET  EGOLI  EAST LONDON",
    Latitude: "-33.0036",
    Longitude: "27.8436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "49",
    WardLookupId: "2873",
    VDNumber: "10590173",
    RegisteredPopulation: "2274",
    VotingStationName: "EQULENI JUNIOR PRIMARY  SCHOOL",
    Address: "6172 NU 2  MDANTSANE  MDANTSANE",
    Latitude: "-32.9483",
    Longitude: "27.7493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "50",
    WardLookupId: "2873",
    VDNumber: "10590263",
    RegisteredPopulation: "1399",
    VotingStationName: "LANGELITSHA PRIMARY SCHOOL",
    Address: "5108 NU 2  MDANTSANE  EAST LONDON",
    Latitude: "-32.951",
    Longitude: "27.7514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "51",
    WardLookupId: "2873",
    VDNumber: "10590274",
    RegisteredPopulation: "2627",
    VotingStationName: "NZALISEKO SENIOR PRIMARY SCHOOL",
    Address: "NU 1  MDANTSANE  EAST LONDON",
    Latitude: "-32.9554",
    Longitude: "27.7392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "52",
    WardLookupId: "2873",
    VDNumber: "10591231",
    RegisteredPopulation: "1002",
    VotingStationName: "N.U. 5 RENT OFFICE",
    Address: "BUFFALO CITY [EAST LONDON]  ",
    Latitude: "-32.9516",
    Longitude: "27.7422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "53",
    WardLookupId: "2873",
    VDNumber: "10591422",
    RegisteredPopulation: "344",
    VotingStationName: "SILVER TOWN TENT",
    Address: "NU 1  MDANTSANE  EAST LONDON",
    Latitude: "-32.9537",
    Longitude: "27.7785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "54",
    WardLookupId: "2874",
    VDNumber: "10590128",
    RegisteredPopulation: "1902",
    VotingStationName: "MZOMHLE HIGH SCHOOL",
    Address: "2004 NU1  MDANTSANE  MDANTSANE",
    Latitude: "-32.9338",
    Longitude: "27.7677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "55",
    WardLookupId: "2874",
    VDNumber: "10590218",
    RegisteredPopulation: "2174",
    VotingStationName: "NONTSIKELELO LOWER & HIGHER PRIMARY SCHOOL",
    Address: "SIYO STREET, NU1  MDANTSANE  EAST LONDON",
    Latitude: "-32.934",
    Longitude: "27.7793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "56",
    WardLookupId: "2874",
    VDNumber: "10590229",
    RegisteredPopulation: "2874",
    VotingStationName: "JOHN KNOX BOKWE COLLEGE",
    Address: "NU 1 MFAXA STREET  MDANTSANE  EAST LONDON",
    Latitude: "-32.9431",
    Longitude: "27.7827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "57",
    WardLookupId: "2874",
    VDNumber: "10591242",
    RegisteredPopulation: "911",
    VotingStationName: "N.U. 1 COMMUNITY HALL",
    Address: "BUFFALO CITY [EAST LONDON]  ",
    Latitude: "-32.9387",
    Longitude: "27.7727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "58",
    WardLookupId: "2874",
    VDNumber: "10820607",
    RegisteredPopulation: "375",
    VotingStationName: "APOSTOLIC FAITH MISSION OF    SA",
    Address: "SMILLING VALLEY  NEW LANDS  EAST LONDON",
    Latitude: "-32.9259",
    Longitude: "27.7808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "59",
    WardLookupId: "2874",
    VDNumber: "10820999",
    RegisteredPopulation: "150",
    VotingStationName: "IKHAZILETHU DAYCARE CENTRE",
    Address: "CUBA LOCATION  NXARHUNI  EAST LONDON",
    Latitude: "-32.9044",
    Longitude: "27.7584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "60",
    WardLookupId: "2875",
    VDNumber: "10590511",
    RegisteredPopulation: "1320",
    VotingStationName: "NKANGELEKO PRIMARY SCHOOL",
    Address: "DRUMMOND ROAD  REESTON  EAST LONDON",
    Latitude: "-32.9707",
    Longitude: "27.8135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "61",
    WardLookupId: "2875",
    VDNumber: "10590599",
    RegisteredPopulation: "805",
    VotingStationName: "KHAYELITSHA BAPTIST CHURCH",
    Address: "02 WATER WORKS  KHAYELITSHA  BUFFALO CITY",
    Latitude: "-32.9945",
    Longitude: "27.7986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "62",
    WardLookupId: "2875",
    VDNumber: "10591163",
    RegisteredPopulation: "1204",
    VotingStationName: "CHICKEN FARM",
    Address: "CHICKEN FARM  REESTON  EAST LONDON",
    Latitude: "-32.9691",
    Longitude: "27.7721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "63",
    WardLookupId: "2875",
    VDNumber: "10591174",
    RegisteredPopulation: "663",
    VotingStationName: "FAMILY RESTORATION CHURCH",
    Address: "BAKANA  REESTON  EAST LONDON",
    Latitude: "-32.9863",
    Longitude: "27.794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "64",
    WardLookupId: "2875",
    VDNumber: "10591185",
    RegisteredPopulation: "2497",
    VotingStationName: "SOPHATHISANA HIGH SCHOOL",
    Address:
      "DICE STREET, REESTON, EAST LONDON   BUFFALO CITY [EAST LONDON]  BUFFALO CITY [EAST LONDON]",
    Latitude: "-32.9736",
    Longitude: "27.8033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "65",
    WardLookupId: "2875",
    VDNumber: "10591309",
    RegisteredPopulation: "1139",
    VotingStationName: "CHRISTIAN MISSION CHURCH",
    Address: "KHAYELITSHA  EAST LONDON",
    Latitude: "-32.9857",
    Longitude: "27.7817",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "66",
    WardLookupId: "2875",
    VDNumber: "10591332",
    RegisteredPopulation: "910",
    VotingStationName: "CHUMANI PRIMARY SCHOOL",
    Address: "REESTON  EAST LONDON  REESTON",
    Latitude: "-32.9739",
    Longitude: "27.7916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "67",
    WardLookupId: "2875",
    VDNumber: "10591411",
    RegisteredPopulation: "783",
    VotingStationName: "CHICKEN FARM PHASE 2",
    Address: "NEW LIFE  REESTON  EAST LONDON",
    Latitude: "-32.9727",
    Longitude: "27.7586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "68",
    WardLookupId: "2876",
    VDNumber: "10590094",
    RegisteredPopulation: "2296",
    VotingStationName: "HLOKOMA HIGH SCHOOL",
    Address: "9647 NU3  MDANTSANE  EAST LONDON",
    Latitude: "-32.9332",
    Longitude: "27.7527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "69",
    WardLookupId: "2876",
    VDNumber: "10590106",
    RegisteredPopulation: "1460",
    VotingStationName: "KHANYISA JUNIOR PRIMARY SCHOOL",
    Address: "9705 NU 3  MDANTSANE",
    Latitude: "-32.9298",
    Longitude: "27.765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "70",
    WardLookupId: "2876",
    VDNumber: "10590117",
    RegisteredPopulation: "2216",
    VotingStationName: "ISITHEMBISO HIGH SCHOOL",
    Address: "9494 NU3  MDANTSANE  EAST LONDON",
    Latitude: "-32.9324",
    Longitude: "27.762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "71",
    WardLookupId: "2876",
    VDNumber: "10590207",
    RegisteredPopulation: "1402",
    VotingStationName: "MFUNDO SENIOR PRIMARY SCHOOL",
    Address: "5100 NU2  MDANTSANE  EAST LONDON",
    Latitude: "-32.9465",
    Longitude: "27.7545",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "72",
    WardLookupId: "2877",
    VDNumber: "10590757",
    RegisteredPopulation: "4415",
    VotingStationName: "SAKHIKAMVA HIGH SCHOOL",
    Address: "27 NOMPUMELELO  BEACON BAY  EAST LONDON",
    Latitude: "-32.9516",
    Longitude: "27.9241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "73",
    WardLookupId: "2877",
    VDNumber: "10590881",
    RegisteredPopulation: "2723",
    VotingStationName: "ABBOTSFORD TENNIS CLUB",
    Address: "ABBOTSFORD  ABBOTSFORD  EAST LONDON",
    Latitude: "-32.9628",
    Longitude: "27.9186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "74",
    WardLookupId: "2877",
    VDNumber: "10591455",
    RegisteredPopulation: "641",
    VotingStationName: "FLORADALE PRIMARY SCHOOL",
    Address: "NOMPUMELELO TOWNSHIP  BEACON BAY  EAST LONDON",
    Latitude: "-32.9544",
    Longitude: "27.9251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "75",
    WardLookupId: "2877",
    VDNumber: "10820898",
    RegisteredPopulation: "1788",
    VotingStationName: "DUCATS COMMUNITY HALL",
    Address: "DUCATS  BEACON BAY  EAST LONDON",
    Latitude: "-32.9288",
    Longitude: "27.9068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "76",
    WardLookupId: "2878",
    VDNumber: "10590555",
    RegisteredPopulation: "1161",
    VotingStationName: "AQUA VISTA PRIMARY SCHOOL",
    Address: "WINDMILL ROAD  DAWN  EAST LONDON",
    Latitude: "-32.9683",
    Longitude: "27.8348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "77",
    WardLookupId: "2878",
    VDNumber: "10590768",
    RegisteredPopulation: "2654",
    VotingStationName: "GCOBANI COMMUNITY HALL",
    Address: "CAMBRIDGE TOWNSHIP  CAMBRIDGE  EAST LONDON",
    Latitude: "-32.9674",
    Longitude: "27.8841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "78",
    WardLookupId: "2878",
    VDNumber: "10591051",
    RegisteredPopulation: "3083",
    VotingStationName: "LIESBETH VAN DAMME EDUCARE",
    Address: "53560 SINEBHONGO  AMALINDA  EAST LONDON",
    Latitude: "-32.9822",
    Longitude: "27.8579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "79",
    WardLookupId: "2878",
    VDNumber: "10591152",
    RegisteredPopulation: "815",
    VotingStationName: "OLD APOSTOLIC CHURCH TENT",
    Address: "ROWLAND STREET  MORNINGSIDE  EAST LONDON",
    Latitude: "-32.9811",
    Longitude: "27.8657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "80",
    WardLookupId: "2878",
    VDNumber: "10591220",
    RegisteredPopulation: "2247",
    VotingStationName: "HAVEN HILLS TENT",
    Address: "DOREEN STREET  HAVEN HILLS  EAST LONDON",
    Latitude: "-32.994",
    Longitude: "27.8509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "81",
    WardLookupId: "2878",
    VDNumber: "10591433",
    RegisteredPopulation: "82",
    VotingStationName: "PAT CAMP TENT",
    Address: "NJALA AVENUE  CAMBRIDGE WEST  EAST LONDON",
    Latitude: "-32.9698",
    Longitude: "27.859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "82",
    WardLookupId: "2879",
    VDNumber: "10590061",
    RegisteredPopulation: "1689",
    VotingStationName: "DALUKUKHANYA SENIOR PRIMARY SCHOOL",
    Address: "9602 NU 3  MDANTSANE  EAST LONDON",
    Latitude: "-32.9357",
    Longitude: "27.7425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "83",
    WardLookupId: "2879",
    VDNumber: "10590072",
    RegisteredPopulation: "1786",
    VotingStationName: "DICKSON DYANI HIGHER PRIMARY SCHOOL",
    Address: "1960 NU 9  MDANTSANE  EAST LONDON",
    Latitude: "-32.9234",
    Longitude: "27.7408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "84",
    WardLookupId: "2879",
    VDNumber: "10590083",
    RegisteredPopulation: "2346",
    VotingStationName: "LM YAKO JUNIOR PRIMARY SCHOOL",
    Address: "9622 NU 4   MDANTSANE  EAST LONDON",
    Latitude: "-32.9257",
    Longitude: "27.7523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "85",
    WardLookupId: "2879",
    VDNumber: "10820179",
    RegisteredPopulation: "1094",
    VotingStationName: "SANDISIWE PUBLIC SCHOOL",
    Address: "MPONGO PARK ROAD   NEWLANDS  EAST LONDON",
    Latitude: "-32.9105",
    Longitude: "27.7575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "86",
    WardLookupId: "2879",
    VDNumber: "10820865",
    RegisteredPopulation: "648",
    VotingStationName: "CUBA COMMUNITY HALL",
    Address: "100601 CUBA LOCATION  NEWLANDS  EAST LONDON",
    Latitude: "-32.8982",
    Longitude: "27.7576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "87",
    WardLookupId: "2880",
    VDNumber: "10590320",
    RegisteredPopulation: "2267",
    VotingStationName: "KINGSWAY TENNIS CLUB",
    Address: "SCHULTZ ROAD  NAHOON  EAST LONDON",
    Latitude: "-32.9844",
    Longitude: "27.9257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "88",
    WardLookupId: "2880",
    VDNumber: "10590689",
    RegisteredPopulation: "3786",
    VotingStationName: "STIRLING BADMINTON HALL",
    Address: "CNR GALWAY AND EPSOM ROAD  STIRLING  EAST LONDON",
    Latitude: "-32.9929",
    Longitude: "27.9275",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "89",
    WardLookupId: "2880",
    VDNumber: "10590779",
    RegisteredPopulation: "2326",
    VotingStationName: "VINCENT METHODIST CHURCH",
    Address: "23 PRINCES ROAD  VINCENT  EAST LONDON",
    Latitude: "-32.9816",
    Longitude: "27.9066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "90",
    WardLookupId: "2881",
    VDNumber: "10590432",
    RegisteredPopulation: "1867",
    VotingStationName: "WEST BANK HIGH SCHOOL",
    Address: "40 HOOD STREET  WEST BANK  EAST LONDON",
    Latitude: "-33.034",
    Longitude: "27.906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "91",
    WardLookupId: "2881",
    VDNumber: "10590443",
    RegisteredPopulation: "2111",
    VotingStationName: "GEORGE RANDELL PRIMARY SCHOOL",
    Address: "JAN SMUTS AVENUE  GREEN FIELDS  EAST LONDON",
    Latitude: "-33.0354",
    Longitude: "27.8575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "92",
    WardLookupId: "2881",
    VDNumber: "10590454",
    RegisteredPopulation: "1616",
    VotingStationName: "BILLY FRANCES HALL",
    Address: "GREENPOINT RD  BUFFALO FLATS  EAST LONDON",
    Latitude: "-33.0212",
    Longitude: "27.8573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "93",
    WardLookupId: "2881",
    VDNumber: "10590500",
    RegisteredPopulation: "1919",
    VotingStationName: "BUFFALO FLATS PRIMARY SCHOOL",
    Address: "84 GREENPOINT ROAD  BUFFALO FLATS  EAST LONDON",
    Latitude: "-33.0227",
    Longitude: "27.872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "94",
    WardLookupId: "2881",
    VDNumber: "10590959",
    RegisteredPopulation: "591",
    VotingStationName: "AW BARNES PRIMARY SCHOOL",
    Address: "WOODSTOCK ROAD  PARKSIDE  EAST LONDON",
    Latitude: "-33.0188",
    Longitude: "27.889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "95",
    WardLookupId: "2882",
    VDNumber: "10590139",
    RegisteredPopulation: "2186",
    VotingStationName: "GCOBANI HIGHER PRIMARY SCHOOL",
    Address: "NKONYENI ROAD  MDANTSANE  EAST LONDON",
    Latitude: "-32.9383",
    Longitude: "27.7294",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "96",
    WardLookupId: "2882",
    VDNumber: "10590162",
    RegisteredPopulation: "2172",
    VotingStationName: "ZANOKUKHANYA JUNIOR PRIMARY  SCHOOL",
    Address: "1175 NU 6  MDANTSANE  EAST LONDON",
    Latitude: "-32.9409",
    Longitude: "27.7375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "97",
    WardLookupId: "2882",
    VDNumber: "10590184",
    RegisteredPopulation: "1555",
    VotingStationName: "RH GODLO LOWER PRIMARY SCHOOL",
    Address: "1334 NU 6  MDANTSANE  EAST LONDON",
    Latitude: "-32.9388",
    Longitude: "27.7447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "98",
    WardLookupId: "2882",
    VDNumber: "10590285",
    RegisteredPopulation: "2547",
    VotingStationName: "N U 7 COMMUNITY HALL",
    Address: "2859 NU 7  MDANTSANE  EAST LONDON",
    Latitude: "-32.9522",
    Longitude: "27.7243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "99",
    WardLookupId: "2882",
    VDNumber: "10591264",
    RegisteredPopulation: "681",
    VotingStationName: "SIYANDA PRIMARY SCHOOL",
    Address: "62Q NU 16  MDANTSANE",
    Latitude: "-32.9612",
    Longitude: "27.6977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "100",
    WardLookupId: "2883",
    VDNumber: "10590016",
    RegisteredPopulation: "2791",
    VotingStationName: "NU 12 COMMUNITY HALL",
    Address: "NU 12  MDANTSANE  EAST LONDON",
    Latitude: "-32.9276",
    Longitude: "27.7116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "101",
    WardLookupId: "2883",
    VDNumber: "10590296",
    RegisteredPopulation: "2165",
    VotingStationName: "NOWONGA JUNIOR PRIMARY SCHOOL",
    Address: "225 NU 13  MDANTSANE  EAST LONDON",
    Latitude: "-32.9343",
    Longitude: "27.7053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "102",
    WardLookupId: "2883",
    VDNumber: "10590308",
    RegisteredPopulation: "2013",
    VotingStationName: "LUKHANYO JUNIOR PRIMARY SCHOOL",
    Address: "911 NU 13  MDANTSANE  EAST LONDON",
    Latitude: "-32.9458",
    Longitude: "27.704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "103",
    WardLookupId: "2884",
    VDNumber: "10590353",
    RegisteredPopulation: "2267",
    VotingStationName: "MANYANO JUNIOR PRIMARY SCHOOL",
    Address: "904 NU 17  MDANTSANE  EAST LONDON",
    Latitude: "-32.9529",
    Longitude: "27.6919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "104",
    WardLookupId: "2884",
    VDNumber: "10590386",
    RegisteredPopulation: "1240",
    VotingStationName: "THANDULWAZI JUNIOR PRIMARY  SCHOOL",
    Address: "1296 NU 18  MDANTSANE  EAST LONDON",
    Latitude: "-32.9629",
    Longitude: "27.6611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "105",
    WardLookupId: "2884",
    VDNumber: "10590409",
    RegisteredPopulation: "1570",
    VotingStationName: "POTSDAM COMMUNITY HALL",
    Address: "POTSDAM   MDANTSANE  EAST LONDON",
    Latitude: "-32.9806",
    Longitude: "27.6444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "106",
    WardLookupId: "2884",
    VDNumber: "10591253",
    RegisteredPopulation: "1642",
    VotingStationName: "N.U. 17 RENT OFFICE",
    Address: "NU 17  MDANTSANE  BUFFALO CITY [EAST LONDON]",
    Latitude: "-32.9489",
    Longitude: "27.6863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "107",
    WardLookupId: "2884",
    VDNumber: "10820247",
    RegisteredPopulation: "700",
    VotingStationName: "MNCOTSHO PRIMARY SCHOOL",
    Address: "EMNCOTSHO VILLAGE  BERLIN  EAST LONDON",
    Latitude: "-32.9354",
    Longitude: "27.599",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "108",
    WardLookupId: "2884",
    VDNumber: "10820258",
    RegisteredPopulation: "682",
    VotingStationName: "EMNTLABATI LOWER/HIGHER PRIMARY SCHOOL",
    Address: "MNTLABATI VILLAGE  BERLIN  EAST LONDON",
    Latitude: "-32.9695",
    Longitude: "27.616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "109",
    WardLookupId: "2884",
    VDNumber: "10820483",
    RegisteredPopulation: "675",
    VotingStationName: "SILIMELA HIGH SCHOOL",
    Address: "MBOLOMPENI VILLAGE  BERLIN  EAST LONDON",
    Latitude: "-32.9512",
    Longitude: "27.599",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "110",
    WardLookupId: "2884",
    VDNumber: "10820506",
    RegisteredPopulation: "435",
    VotingStationName: "NQONQWENI LOWER/HIGHER PRIMARY SCHOOL",
    Address: "NQONQWENI VILLAGE  BERLIN  EAST LONDON",
    Latitude: "-32.9181",
    Longitude: "27.6212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "111",
    WardLookupId: "2885",
    VDNumber: "10590342",
    RegisteredPopulation: "2910",
    VotingStationName: "SANDISIWE HIGH SCHOOL",
    Address: "747 NU 14  MDANTSANE  EAST LONDON",
    Latitude: "-32.9508",
    Longitude: "27.6971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "112",
    WardLookupId: "2885",
    VDNumber: "10590364",
    RegisteredPopulation: "1495",
    VotingStationName: "ZIMASA JUNIOR PRIMARY SCHOOL",
    Address: "1243 NU 14  MDANTSANE  EAST LONDON",
    Latitude: "-32.9599",
    Longitude: "27.706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "113",
    WardLookupId: "2885",
    VDNumber: "10590375",
    RegisteredPopulation: "2013",
    VotingStationName: "MOSES MABHIDA SENIOR SECONDARY  SCHOOL",
    Address: "806 NU 16  MDANTSANE  EAST LONDON",
    Latitude: "-32.964",
    Longitude: "27.6886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "114",
    WardLookupId: "2885",
    VDNumber: "10591275",
    RegisteredPopulation: "1047",
    VotingStationName: "MAKUKHANYE HIGHER PRIMARY SCHOOL",
    Address: "1553 NU 16  MDANTSANE  BUFFALO CITY [EAST LONDON]",
    Latitude: "-32.9702",
    Longitude: "27.6781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "115",
    WardLookupId: "2885",
    VDNumber: "10591286",
    RegisteredPopulation: "908",
    VotingStationName: "ST PETERS APOSTOLIC  CHURCH",
    Address: "141 N U 18  MDANTSANE  EAST LONDON",
    Latitude: "-32.9554",
    Longitude: "27.6751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "116",
    WardLookupId: "2886",
    VDNumber: "10590319",
    RegisteredPopulation: "1741",
    VotingStationName: "IMIZAMO HIGH SCHOOL",
    Address: "885 NU 15  MDANTSANE  EAST LONDON",
    Latitude: "-32.9376",
    Longitude: "27.6989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "117",
    WardLookupId: "2886",
    VDNumber: "10590331",
    RegisteredPopulation: "1556",
    VotingStationName: "GIL NTONI SENIOR PRIMARY SCHOOL",
    Address: "326 NU 15  MDANTSANE  EAST LONDON",
    Latitude: "-32.9428",
    Longitude: "27.6949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "118",
    WardLookupId: "2886",
    VDNumber: "10590397",
    RegisteredPopulation: "2005",
    VotingStationName: "MBEKWENI JUNIOR PRIMARY SCHOOL",
    Address: "1755 MBEKWENI TOWNSHIP  MBEKWENI TOWNSHIP,POTSDAM  EAST LONDON",
    Latitude: "-32.9273",
    Longitude: "27.6675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "119",
    WardLookupId: "2886",
    VDNumber: "10590937",
    RegisteredPopulation: "1599",
    VotingStationName: "BORDER TECHNIKON MAIN CAMPUS",
    Address: "OLD KWT ROAD  POTSDAM  EAST LONDON",
    Latitude: "-32.902",
    Longitude: "27.6647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "120",
    WardLookupId: "2886",
    VDNumber: "10591310",
    RegisteredPopulation: "869",
    VotingStationName: "BHONGOLWETHU EDUCARE CENTRE",
    Address: "KHAYELITSHA LOCATION  POTSDAM  EAST LONDON",
    Latitude: "-32.9113",
    Longitude: "27.6828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "121",
    WardLookupId: "2886",
    VDNumber: "10591376",
    RegisteredPopulation: "2348",
    VotingStationName: "UNIT P TENT",
    Address: "UNIT P   POTSDAM  EAST LONDON",
    Latitude: "-32.929",
    Longitude: "27.6808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "122",
    WardLookupId: "2886",
    VDNumber: "10820191",
    RegisteredPopulation: "341",
    VotingStationName: "NXAMKWANA PRIMARY SCHOOL",
    Address: "NXAMKWANA VILLAGE  MDANTSANE  EAST LONDON",
    Latitude: "-32.9626",
    Longitude: "27.6377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "123",
    WardLookupId: "2887",
    VDNumber: "10670015",
    RegisteredPopulation: "1432",
    VotingStationName: "PHAKAMISA JUNIOR PRIMARY SCHOOL",
    Address: "2442 PHAKAMISA  PAKAMISA TOWNSHIP  KING WILLIAMS TOWN",
    Latitude: "-32.9334",
    Longitude: "27.4537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "124",
    WardLookupId: "2887",
    VDNumber: "10670082",
    RegisteredPopulation: "1535",
    VotingStationName: "NZONDELELO HIGHER PRIMARY SCHOOL",
    Address: "ZONE 9  ZWELITSHA   KING WILLIAMS TOWN",
    Latitude: "-32.9278",
    Longitude: "27.4289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "125",
    WardLookupId: "2887",
    VDNumber: "10670149",
    RegisteredPopulation: "1751",
    VotingStationName: "PHAKAMISA COMMUNITY HALL",
    Address: "PHAKAMISA  KING WILLIAMS TOWN",
    Latitude: "-32.9319",
    Longitude: "27.46",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "126",
    WardLookupId: "2887",
    VDNumber: "10670262",
    RegisteredPopulation: "1604",
    VotingStationName: "NGQIKA JUNIOR PRIMARY SCHOOL",
    Address: "ZONE 8  ZWELITSHA ZONE 8  KING WILLIAMS TOWN",
    Latitude: "-32.9254",
    Longitude: "27.4354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "127",
    WardLookupId: "2887",
    VDNumber: "10870231",
    RegisteredPopulation: "612",
    VotingStationName: "MASIKHANYISE HIGHER  PRIMARY SCHOOL",
    Address: "CLIFF LOCATION   PHAKAMISA  KING WILLIAMS TOWN",
    Latitude: "-32.9284",
    Longitude: "27.4641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "128",
    WardLookupId: "2888",
    VDNumber: "10820113",
    RegisteredPopulation: "1728",
    VotingStationName: "KWETYANA COMMUNITY HALL",
    Address: "NXARHUNI LOCATION  NEWLANDS  EAST LONDON",
    Latitude: "-32.8377",
    Longitude: "27.798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "129",
    WardLookupId: "2888",
    VDNumber: "10820135",
    RegisteredPopulation: "1429",
    VotingStationName: "NKQONKQWENI COMMUNITY HALL",
    Address: "NKQONKQWENI LOCATION  NEWLANDS  EAST LONDON",
    Latitude: "-32.882",
    Longitude: "27.795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "130",
    WardLookupId: "2888",
    VDNumber: "10820146",
    RegisteredPopulation: "638",
    VotingStationName: "IKHANYA PRE SCHOOL",
    Address: "MATHANGA VILLAGE  ZIKWABA  EAST LONDON",
    Latitude: "-32.8502",
    Longitude: "27.7739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "131",
    WardLookupId: "2888",
    VDNumber: "10820157",
    RegisteredPopulation: "1124",
    VotingStationName: "OLD TRIBAL OFFICE",
    Address: "MPUNDU VILLAGE  EAST LONDON",
    Latitude: "-32.878",
    Longitude: "27.7495",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "132",
    WardLookupId: "2888",
    VDNumber: "10820168",
    RegisteredPopulation: "300",
    VotingStationName: "ST LUKES PUBLIC SCHOOL",
    Address: "10507 GWIQI LOCATION  NXARUNI VILLAGE  EAST LONDON",
    Latitude: "-32.8832",
    Longitude: "27.7482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "133",
    WardLookupId: "2888",
    VDNumber: "10820180",
    RegisteredPopulation: "53",
    VotingStationName: "ON HAVEN TENT",
    Address: "ON HAVEN FARM  BERLIN  EAST LONDON",
    Latitude: "-32.8808",
    Longitude: "27.6928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "134",
    WardLookupId: "2888",
    VDNumber: "10820595",
    RegisteredPopulation: "580",
    VotingStationName: "MACLEANTOWN TOWN HALL",
    Address: "CATHRIN  MACLEANTOWN  EAST LONDON",
    Latitude: "-32.7893",
    Longitude: "27.7466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "135",
    WardLookupId: "2888",
    VDNumber: "10820821",
    RegisteredPopulation: "349",
    VotingStationName: "NTSOKOTHA SENIOR SECONDARY SCHOOL",
    Address: "NXARHUNI LOCATION  NEWLANDS  EAST LONDON",
    Latitude: "-32.8747",
    Longitude: "27.7759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "136",
    WardLookupId: "2888",
    VDNumber: "10820832",
    RegisteredPopulation: "860",
    VotingStationName: "ST MARYS COMMUNITY HALL",
    Address: "NXARHUNI LOCATION  NEWLANDS  EAST LONDON",
    Latitude: "-32.8877",
    Longitude: "27.765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "137",
    WardLookupId: "2888",
    VDNumber: "10820900",
    RegisteredPopulation: "375",
    VotingStationName: "NOMZAMO HALL",
    Address: "90444 NOMZAMO, NXARHUNI LOCATION  NEWLANDS  EAST LONDON",
    Latitude: "-32.8631",
    Longitude: "27.8191",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "138",
    WardLookupId: "2888",
    VDNumber: "10820911",
    RegisteredPopulation: "1011",
    VotingStationName: "NGWENYATHI SECONDARY SCHOOL",
    Address: "ST LUKES ROAD, GWIQI LOCATION  NEWLANDS  EAST LONDON",
    Latitude: "-32.8827",
    Longitude: "27.7508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "139",
    WardLookupId: "2889",
    VDNumber: "10590724",
    RegisteredPopulation: "2654",
    VotingStationName: "MZAMOMHLE COMMUNITY HALL",
    Address: "MZAMOMHLE TOWNSHIP GONUBIE  EAST LONDON",
    Latitude: "-32.9528",
    Longitude: "27.9901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "140",
    WardLookupId: "2889",
    VDNumber: "10590926",
    RegisteredPopulation: "1761",
    VotingStationName: "OLD APOSTOLIC CHURCH",
    Address: "MZAMOMHLE  GONUBIE  EAST LONDON",
    Latitude: "-32.9529",
    Longitude: "27.9846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "141",
    WardLookupId: "2889",
    VDNumber: "10591028",
    RegisteredPopulation: "1064",
    VotingStationName: "PET HAVEN TENT",
    Address: "FARM 809 PORTION 11, MAIN ROAD  GONUBIE  KLINKRADT",
    Latitude: "-32.9424",
    Longitude: "27.9667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "142",
    WardLookupId: "2889",
    VDNumber: "10591073",
    RegisteredPopulation: "1318",
    VotingStationName: "RIEGERTON PARK TENT",
    Address: "WINDSWAEL DRIVE, RIEGRTON  GONUBIE  EAST LONDON",
    Latitude: "-32.9372",
    Longitude: "27.9893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "143",
    WardLookupId: "2889",
    VDNumber: "10591084",
    RegisteredPopulation: "1793",
    VotingStationName: "MZAMOWETHU PUBLIC SCHOOL",
    Address: "SANTINI LOCATION  GONUBIE  EAST LONDON",
    Latitude: "-32.9541",
    Longitude: "27.9912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "144",
    WardLookupId: "2890",
    VDNumber: "10590690",
    RegisteredPopulation: "4230",
    VotingStationName: "BEACONHURST PRIMAY SCHOOL (VC)",
    Address: "AVALON ROAD  BEACON BAY   EAST LONDON",
    Latitude: "-32.971",
    Longitude: "27.9355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "145",
    WardLookupId: "2890",
    VDNumber: "10590702",
    RegisteredPopulation: "2397",
    VotingStationName: "KENNERSLEY PARK RETIREMENT HOME",
    Address: "104 BONZA BAY ROAD  BEACON BAY  EAST LONDON",
    Latitude: "-32.9707",
    Longitude: "27.9538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "146",
    WardLookupId: "2890",
    VDNumber: "10590713",
    RegisteredPopulation: "2874",
    VotingStationName: "ST CRUCIS LUTHERAN CHURCH EDUCARE CENTRE",
    Address:
      "52 EDGE ROAD                    FRONTIER RD  BEACON BAY                               EAST LONDON",
    Latitude: "-32.9577",
    Longitude: "27.9401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "147",
    WardLookupId: "2891",
    VDNumber: "10590735",
    RegisteredPopulation: "4250",
    VotingStationName: "ALASTAIR CARNEGIE HALL",
    Address: "MAIN ROAD  GONUBIE  EAST LONDON",
    Latitude: "-32.9407",
    Longitude: "28.0214",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "148",
    WardLookupId: "2891",
    VDNumber: "10590746",
    RegisteredPopulation: "3425",
    VotingStationName: "GONUBIE PRIMARY SCHOOL",
    Address: "24 MAIN ROAD  GONUBIE  EAST LONDON",
    Latitude: "-32.9369",
    Longitude: "28.008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "149",
    WardLookupId: "2892",
    VDNumber: "10590027",
    RegisteredPopulation: "2328",
    VotingStationName: "LUZUKO HIGHER PRIMARY SCHOOL",
    Address: "1942 NU 11  MDANTSANE  EAST LONDON",
    Latitude: "-32.9254",
    Longitude: "27.7194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "150",
    WardLookupId: "2892",
    VDNumber: "10590050",
    RegisteredPopulation: "2246",
    VotingStationName: "THEMBEKA LOWER PRIMARY SCHOOL",
    Address: "1084 NU 9  MDANTSANE  EAST LONDON",
    Latitude: "-32.9259",
    Longitude: "27.7379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "151",
    WardLookupId: "2892",
    VDNumber: "10591062",
    RegisteredPopulation: "2304",
    VotingStationName: "PHILEMON NGCELWANE HIGH SCHOOL",
    Address: "242 NU 9  MDANTSANE  EAST LONDON",
    Latitude: "-32.9221",
    Longitude: "27.7336",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "152",
    WardLookupId: "2892",
    VDNumber: "10820966",
    RegisteredPopulation: "260",
    VotingStationName: "CUBA PHASE 2 TENT",
    Address: "CUBA VILLAGE  NXARHUNI  MACLEANTOWN",
    Latitude: "-32.914",
    Longitude: "27.7414",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "153",
    WardLookupId: "2893",
    VDNumber: "10590566",
    RegisteredPopulation: "1690",
    VotingStationName: "FORT GREY CLINIC",
    Address:
      "FORT GREY INFORMAL SETTLEMENT  FORT GREY,NEAR AIRPORT  EAST LONDON",
    Latitude: "-33.0371",
    Longitude: "27.8032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "154",
    WardLookupId: "2893",
    VDNumber: "10591400",
    RegisteredPopulation: "758",
    VotingStationName: "BHONGWENI COMMUNITY HALL",
    Address: "BHONGWENI  BHONGWENI LOCATION  EAST LONDON",
    Latitude: "-33.0434",
    Longitude: "27.7895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "155",
    WardLookupId: "2893",
    VDNumber: "10770050",
    RegisteredPopulation: "1058",
    VotingStationName: "KIDDS BEACH COMMUNITY HALL",
    Address: "256 MAIN ROAD  KIDDS BEACH   EAST LONDON",
    Latitude: "-33.1512",
    Longitude: "27.6962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "156",
    WardLookupId: "2893",
    VDNumber: "10820270",
    RegisteredPopulation: "280",
    VotingStationName: "OVERTON TENT",
    Address: "IGODI OUTSPAN  IGODI ADMIN AREA  EAST LONDON",
    Latitude: "-33.0762",
    Longitude: "27.7683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "157",
    WardLookupId: "2893",
    VDNumber: "10820281",
    RegisteredPopulation: "830",
    VotingStationName: "SIFIKILE PRIMARY SCHOOL",
    Address: "VILLAGE 9  NCERA VILLAGE9  EAST LONDON",
    Latitude: "-33.0484",
    Longitude: "27.5903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "158",
    WardLookupId: "2893",
    VDNumber: "10820315",
    RegisteredPopulation: "458",
    VotingStationName: "NCERA VILLAGE 1 COMMUNITY HALL",
    Address: "VILLAGE 1  NCERA VILLAGE1  EAST LONDON",
    Latitude: "-33.1146",
    Longitude: "27.592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "159",
    WardLookupId: "2893",
    VDNumber: "10820360",
    RegisteredPopulation: "1107",
    VotingStationName: "NCERA VILLAGE 2 COMMUNITY HALL",
    Address: "VILLAGE 2  NCERA VILLAGE , CHALUMNA  EAST LONDON",
    Latitude: "-33.145",
    Longitude: "27.5967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "160",
    WardLookupId: "2893",
    VDNumber: "10820382",
    RegisteredPopulation: "471",
    VotingStationName: "NCERA VILLAGE 4 COMMUNITY HALL",
    Address: "NCERA VILLAGE 4  KIDDS BEACH  EAST LONDON",
    Latitude: "-33.1427",
    Longitude: "27.5702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "161",
    WardLookupId: "2893",
    VDNumber: "10820405",
    RegisteredPopulation: "424",
    VotingStationName: "VILLAGE 6 COMMUNITY HALL",
    Address: "VILLAGE 6  NCERA VILLAGE 6  EAST LONDON",
    Latitude: "-33.106",
    Longitude: "27.5655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "162",
    WardLookupId: "2893",
    VDNumber: "10820416",
    RegisteredPopulation: "502",
    VotingStationName: "VILLAGE 7 COMMUNITY HALL",
    Address: "70105 NCERA VILLAGE 7  EAST LONDON",
    Latitude: "-33.0791",
    Longitude: "27.553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "163",
    WardLookupId: "2893",
    VDNumber: "10820775",
    RegisteredPopulation: "644",
    VotingStationName: "BRAESIDE PRIMARY SCHOOL",
    Address: "SUNNY SOUTH FARM  KIDDS BEACH  EAST LONDON",
    Latitude: "-33.0749",
    Longitude: "27.6352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "164",
    WardLookupId: "2893",
    VDNumber: "10820922",
    RegisteredPopulation: "224",
    VotingStationName: "SINOVUYO DAY CARE CENTRE",
    Address: "MOUNT COKE ROAD  EAST LONDON  EAST LONDON",
    Latitude: "-33.0145",
    Longitude: "27.663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "165",
    WardLookupId: "2893",
    VDNumber: "10821002",
    RegisteredPopulation: "116",
    VotingStationName: "EBOMPINI COMMUNITY HALL",
    Address: "EBOMPINI VILLAGE  FORT GREY  EAST LONDON",
    Latitude: "-33.0316",
    Longitude: "27.789",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "166",
    WardLookupId: "2893",
    VDNumber: "10821013",
    RegisteredPopulation: "161",
    VotingStationName: "BOXWOOD SINOVUYO DAY CARE",
    Address: "BOXWOOD LOCATION  EAST LONDON  EAST LONDON",
    Latitude: "-33.0215",
    Longitude: "27.6726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "167",
    WardLookupId: "2893",
    VDNumber: "10821024",
    RegisteredPopulation: "94",
    VotingStationName: "JIFF NCERA COMMUNITY HALL",
    Address: "JIFF NCERA  NCERA VILLAGE  KIDDZ BEACH",
    Latitude: "-33.0648",
    Longitude: "27.5618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "168",
    WardLookupId: "2894",
    VDNumber: "10770061",
    RegisteredPopulation: "599",
    VotingStationName: "KAYSERS BEACH SOCIAL CLUB",
    Address: "111 LARK STREET  KAYSERS BEACH  EAST LONDON",
    Latitude: "-33.208",
    Longitude: "27.6041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "169",
    WardLookupId: "2894",
    VDNumber: "10820292",
    RegisteredPopulation: "258",
    VotingStationName: "DYAM-DYAM PRIMARY SCHOOL",
    Address: "DYAM-DYAM VILLAGE  DYAM DYAM VILLAGE, CHALUMNA  EAST LONDON",
    Latitude: "-33.2079",
    Longitude: "27.5357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "170",
    WardLookupId: "2894",
    VDNumber: "10820304",
    RegisteredPopulation: "786",
    VotingStationName: "LILLY VALE DAYCARE CENTRE",
    Address: "LILLY VALE  KIDDS BEACH  EAST LONDON",
    Latitude: "-33.1592",
    Longitude: "27.6246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "171",
    WardLookupId: "2894",
    VDNumber: "10820326",
    RegisteredPopulation: "606",
    VotingStationName: "MSOBOMVU HIGH SCHOOL",
    Address: "ZIKHOVA VILLAGE  ZIKHOVA VILLAGE CHALUMNA  EAST LONDON",
    Latitude: "-33.2364",
    Longitude: "27.4792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "172",
    WardLookupId: "2894",
    VDNumber: "10820337",
    RegisteredPopulation: "458",
    VotingStationName: "NGCAMAMA INTERMIDIATE SCHOOL",
    Address: "DOWU VILLAGE  DOWU VILLAGE CHALUMNA  EAST LONDON",
    Latitude: "-33.2025",
    Longitude: "27.4878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "173",
    WardLookupId: "2894",
    VDNumber: "10820348",
    RegisteredPopulation: "588",
    VotingStationName: "KIWANE PRIMARY SCHOOL",
    Address: "KIWANE VILLAGE  KIWANE VILLAGE CHALUMNA  EAST LONDON",
    Latitude: "-33.1609",
    Longitude: "27.468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "174",
    WardLookupId: "2894",
    VDNumber: "10820359",
    RegisteredPopulation: "528",
    VotingStationName: "SANDILE PRIMARY SCHOOL",
    Address: "SANDILE VILLAGE  SANDILE VILLAGE CHALUMNA  EAST LONDON",
    Latitude: "-33.1493",
    Longitude: "27.5447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "175",
    WardLookupId: "2894",
    VDNumber: "10820393",
    RegisteredPopulation: "351",
    VotingStationName: "NCERA VILLAGE 5 COMMUNITY HALL",
    Address: "VILLAGE 5  CHALUMNA, KIDDS BEACH  EAST LONDON",
    Latitude: "-33.128",
    Longitude: "27.5355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "176",
    WardLookupId: "2894",
    VDNumber: "10820528",
    RegisteredPopulation: "305",
    VotingStationName: "RHALA PRIMARY SCHOOL",
    Address: "RHALA VILLAGE  RHALA VILLAGE, CHALUMNA  EAST LONDON",
    Latitude: "-33.2256",
    Longitude: "27.4536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "177",
    WardLookupId: "2894",
    VDNumber: "10820539",
    RegisteredPopulation: "307",
    VotingStationName: "WESLEYVILLE PRIMARY SCHOOL",
    Address: "KALIKENI VILLAGE  KALIKENI VILLAGE, CHALUMNA  EAST LONDON",
    Latitude: "-33.1311",
    Longitude: "27.4511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "178",
    WardLookupId: "2894",
    VDNumber: "10820540",
    RegisteredPopulation: "213",
    VotingStationName: "JOJWENI PRIMARY SCHOOL",
    Address: "JOJWENI VILLAGE  JOJWENI VILLAGE CHALUMNA  EAST LONDON",
    Latitude: "-33.1816",
    Longitude: "27.4446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "179",
    WardLookupId: "2894",
    VDNumber: "10820551",
    RegisteredPopulation: "165",
    VotingStationName: "MHALA PRIMARY SCHOOL",
    Address: "XESI VILLAGE  XESI VILLAGE, CHALUMNA  EAST LONDON",
    Latitude: "-33.1848",
    Longitude: "27.4001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "180",
    WardLookupId: "2894",
    VDNumber: "10820618",
    RegisteredPopulation: "740",
    VotingStationName: "ZWELIYANDILA HIGH SCHOOL",
    Address: "OPENSHAW VILLAGE, SWELIYANDILA  CHALUMNA  EAST LONDON",
    Latitude: "-33.1594",
    Longitude: "27.4481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "181",
    WardLookupId: "2894",
    VDNumber: "10820629",
    RegisteredPopulation: "608",
    VotingStationName: "TSHOLOMNQA HIGH SCHOOL",
    Address: "MPONGO VILLAGE  CHALUMNA  EAST LONDON",
    Latitude: "-33.1612",
    Longitude: "27.5282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "182",
    WardLookupId: "2894",
    VDNumber: "10820764",
    RegisteredPopulation: "238",
    VotingStationName: "TSABA PRIMARY SCHOOL",
    Address: "TSABA VILLAGE  TSHOLOMNQA  EAST LONDON",
    Latitude: "-33.1436",
    Longitude: "27.5145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "183",
    WardLookupId: "2894",
    VDNumber: "10820887",
    RegisteredPopulation: "593",
    VotingStationName: "XHAMENI COMMUNITY HALL",
    Address: "XHAMENI VILLAGE  TSHOLOMNQA   EAST LONDON",
    Latitude: "-33.1708",
    Longitude: "27.5376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "184",
    WardLookupId: "2894",
    VDNumber: "10820977",
    RegisteredPopulation: "383",
    VotingStationName: "PHOZI PRIMARY SCHOOL",
    Address: "PHOZI VILLAGE  TSHOLOMNQA  EAST LONDON",
    Latitude: "-33.2038",
    Longitude: "27.5598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "185",
    WardLookupId: "2894",
    VDNumber: "10821057",
    RegisteredPopulation: "299",
    VotingStationName: "GQALA PRIMARY SCHOOL",
    Address: "GQALA VILLAGE  TSHOLOMNQA  EASTLONDON",
    Latitude: "-33.1753",
    Longitude: "27.5191",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "186",
    WardLookupId: "2895",
    VDNumber: "10820269",
    RegisteredPopulation: "1706",
    VotingStationName: "NEEDS CAMP COMMUNITY HALL",
    Address: "PHUMLANI  NEEDS CAMP  EAST LONDON",
    Latitude: "-32.9959",
    Longitude: "27.6515",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "187",
    WardLookupId: "2895",
    VDNumber: "10820517",
    RegisteredPopulation: "331",
    VotingStationName: "TWECWANA COMBINED PRIMARY SCHOOL",
    Address: "TWECWANA VILLAGE  TWECWANA VILLAGE CHALUMNA  EAST LONDON",
    Latitude: "-33.1424",
    Longitude: "27.4857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "188",
    WardLookupId: "2895",
    VDNumber: "10820843",
    RegisteredPopulation: "632",
    VotingStationName: "UNITING REFORMED CHURCH",
    Address: "NEEDS CAMP  NEEDS CAMP  VILLAGE  EAST LONDON",
    Latitude: "-33.0007",
    Longitude: "27.6463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "189",
    WardLookupId: "2895",
    VDNumber: "10820854",
    RegisteredPopulation: "938",
    VotingStationName: "NTSASA PUBLIC SCHOOL",
    Address: "PHUMLANI LOCATION  NEEDS CAMP  EAST LONDON",
    Latitude: "-33.0118",
    Longitude: "27.6413",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "190",
    WardLookupId: "2895",
    VDNumber: "10820933",
    RegisteredPopulation: "658",
    VotingStationName: "LUMANYANO PRIMARY SCHOOL",
    Address: "PHUMLANI LOCATION  NEEDS CAMP  EAST LONDON",
    Latitude: "-32.9921",
    Longitude: "27.6555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "191",
    WardLookupId: "2895",
    VDNumber: "10870017",
    RegisteredPopulation: "400",
    VotingStationName: "FEZIWE LOWER PRIMARY SCHOOL",
    Address: "QHURU FARM AREA  K.W.T  EAST LONDON",
    Latitude: "-33.0026",
    Longitude: "27.5688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "192",
    WardLookupId: "2895",
    VDNumber: "10870028",
    RegisteredPopulation: "630",
    VotingStationName: "KUNI PRIMARY SCHOOL",
    Address: "KUNI VILLAGE  KUNI  KING WILLIAMS TOWN",
    Latitude: "-33.034",
    Longitude: "27.5826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "193",
    WardLookupId: "2895",
    VDNumber: "10870039",
    RegisteredPopulation: "468",
    VotingStationName: "WELCOME WOOD COMMUNITY HALL",
    Address: "WELCOME WOOD LOCATION  CHALUMNA  EAST LONDON",
    Latitude: "-33.0258",
    Longitude: "27.5041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "194",
    WardLookupId: "2895",
    VDNumber: "10870040",
    RegisteredPopulation: "314",
    VotingStationName: "FUNIWE SENIOR SECONDARY SCHOOL",
    Address: "QHURU FARM AREA  BANGILIZWE A/A  K.W.T",
    Latitude: "-32.9972",
    Longitude: "27.5376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "195",
    WardLookupId: "2895",
    VDNumber: "10870602",
    RegisteredPopulation: "501",
    VotingStationName: "JIM MVABAZA SENIOR SECONDARY SCHOOL",
    Address: "THWECU VILLAGE  IMIQHAYI ADMIN AREA  KING WMS TOWN",
    Latitude: "-33.1114",
    Longitude: "27.4218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "196",
    WardLookupId: "2895",
    VDNumber: "10870860",
    RegisteredPopulation: "154",
    VotingStationName: "HILL LOWER/HIGHER PRIMARY SCHOOL",
    Address: "HILL VILLAGE  HILL  KING WILLIAMS TOWN",
    Latitude: "-33.1033",
    Longitude: "27.4512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "197",
    WardLookupId: "2895",
    VDNumber: "10870949",
    RegisteredPopulation: "336",
    VotingStationName: "GXETU LOWER AND HIGHER PRIMARY SCHOOL",
    Address: "GXETU LOCATION  KING WILLIAMS TOWN",
    Latitude: "-33.0647",
    Longitude: "27.452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "198",
    WardLookupId: "2895",
    VDNumber: "10871210",
    RegisteredPopulation: "194",
    VotingStationName: "NORRINGDALE LOWER/HIGHER PRIMARY SCHOOL",
    Address: "ALTILE VILLAGE  EAST LONDON",
    Latitude: "-33.1233",
    Longitude: "27.4735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "199",
    WardLookupId: "2895",
    VDNumber: "10871221",
    RegisteredPopulation: "233",
    VotingStationName: "HILLINGDALE PRIMARY SCHOOL",
    Address: "GWILIGWILI LOCATION  GWILIGWILI VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-33.0658",
    Longitude: "27.5098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "200",
    WardLookupId: "2895",
    VDNumber: "10871232",
    RegisteredPopulation: "608",
    VotingStationName: "NTSASA PRIMARY SCHOOL",
    Address: "KUNI  TEMBISA VILLAGE  EAST LONDON",
    Latitude: "-33.0259",
    Longitude: "27.5884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "201",
    WardLookupId: "2895",
    VDNumber: "10871377",
    RegisteredPopulation: "160",
    VotingStationName: "MASINCEDANE DAY CARE CENTRE",
    Address: "GWILIGWILI LOCATION  CORNFIELD  EAST LONDON",
    Latitude: "-33.0631",
    Longitude: "27.5267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "202",
    WardLookupId: "2896",
    VDNumber: "10670150",
    RegisteredPopulation: "966",
    VotingStationName: "RICHARD VARHA SENIOR SECONDARY SCHOOL",
    Address: "TEMBISA  DIMBAZA TOWNSHIP  KING WILLIAMS TOWN",
    Latitude: "-32.8442",
    Longitude: "27.232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "203",
    WardLookupId: "2896",
    VDNumber: "10670172",
    RegisteredPopulation: "1633",
    VotingStationName: "POLAR PARK HALL",
    Address: "RIRIE TRUST  DIMBAMZA TOWNSHIP  KING WILLIAMS TOWN",
    Latitude: "-32.8482",
    Longitude: "27.2114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "204",
    WardLookupId: "2896",
    VDNumber: "10670183",
    RegisteredPopulation: "1059",
    VotingStationName: "NOBUNTU HIGHER PRIMARY SCHOOL",
    Address: "DIMBAZA TOWNSHIP  KING WILLIAMS TOWN",
    Latitude: "-32.8403",
    Longitude: "27.2247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "205",
    WardLookupId: "2896",
    VDNumber: "10670194",
    RegisteredPopulation: "1753",
    VotingStationName: "MOSES TWEBE HALL",
    Address: "MAIN STREET  DIMBAZA TOWNSHIP  KING WILLIAMS TOWN",
    Latitude: "-32.8349",
    Longitude: "27.2133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "206",
    WardLookupId: "2896",
    VDNumber: "10670295",
    RegisteredPopulation: "642",
    VotingStationName: "KWASA JUNIOR PRIMARY SCHOOL",
    Address: "DIMBAZA  DIMBAZA TOWNSHIP  KING WILLIAMS TOWN",
    Latitude: "-32.835",
    Longitude: "27.2282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "207",
    WardLookupId: "2896",
    VDNumber: "10670307",
    RegisteredPopulation: "1895",
    VotingStationName: "POLAR PARK EAST GROUNDS TENT",
    Address: "EAST GROUND POLAR PARK  DIMBAZA  KING WILLIAMS TOWN",
    Latitude: "-32.8522",
    Longitude: "27.2208",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "208",
    WardLookupId: "2896",
    VDNumber: "10670318",
    RegisteredPopulation: "496",
    VotingStationName: "ST JOHNS APOSTOLIC FAITH MISSION OF SA",
    Address: "884 DIMBAZA  DIMBAZA TOWNSHIP  KING WILLIAMS TOWN",
    Latitude: "-32.8383",
    Longitude: "27.2153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "209",
    WardLookupId: "2897",
    VDNumber: "10670341",
    RegisteredPopulation: "358",
    VotingStationName: "PM MOTEL",
    Address: "BALASI VALLEY  DIMBAZA   KING WILLIAMS TOWN",
    Latitude: "-32.8464",
    Longitude: "27.4037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "210",
    WardLookupId: "2897",
    VDNumber: "10870152",
    RegisteredPopulation: "1187",
    VotingStationName: "BALASI LOWER/HIGHER PRIMARY SCHOOL",
    Address: "BALASI VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.8307",
    Longitude: "27.4427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "211",
    WardLookupId: "2897",
    VDNumber: "10870208",
    RegisteredPopulation: "840",
    VotingStationName: "SIYAQALA JUNIOR PRIMARY SCHOOL",
    Address: "VILLAGE  QUZINI  KING WILLIAMS TOWN",
    Latitude: "-32.8157",
    Longitude: "27.3554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "212",
    WardLookupId: "2897",
    VDNumber: "10870501",
    RegisteredPopulation: "400",
    VotingStationName: "NOMVUZO JUNIOR AND SENIOR PRIMARY SCHOOL",
    Address: "CWENGCWE VILLAGE   KING WILLIAMS TOWN",
    Latitude: "-32.7453",
    Longitude: "27.35",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "213",
    WardLookupId: "2897",
    VDNumber: "10870534",
    RegisteredPopulation: "408",
    VotingStationName: "ATHENKOSI LOWER/HIGHER PRIMARY SCHOOL",
    Address: "ELUPHONDWENI  KING WILLIAMS TOWN",
    Latitude: "-32.7864",
    Longitude: "27.3696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "214",
    WardLookupId: "2897",
    VDNumber: "10870679",
    RegisteredPopulation: "506",
    VotingStationName: "JITYAZA COMBINED PRIMARY SCHOOL",
    Address: "TUY-TYU VILLAGE  STUTTERHEIM",
    Latitude: "-32.8358",
    Longitude: "27.3858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "215",
    WardLookupId: "2897",
    VDNumber: "10870680",
    RegisteredPopulation: "657",
    VotingStationName: "ANDILE NTSEPE LOWER/HIGHER PRIMARY SCHOOL",
    Address: "RAMNYIBA  KING WILLIAMS TOWN",
    Latitude: "-32.7982",
    Longitude: "27.3464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "216",
    WardLookupId: "2897",
    VDNumber: "10870691",
    RegisteredPopulation: "1359",
    VotingStationName: "ZINYOKA COMMUNITY HALL",
    Address: "ZINYOKA  KING WILLIAMS TOWN",
    Latitude: "-32.8091",
    Longitude: "27.4204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "217",
    WardLookupId: "2897",
    VDNumber: "10870781",
    RegisteredPopulation: "990",
    VotingStationName: "QUZINI COMMUNITY HALL",
    Address: "QUZINI  KING WILLIAMS TOWN",
    Latitude: "-32.8181",
    Longitude: "27.367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "218",
    WardLookupId: "2897",
    VDNumber: "10870927",
    RegisteredPopulation: "535",
    VotingStationName: "MZONTSUNDU SENIOR SECONDAY SCHOOL",
    Address: "TYUTYU VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.8256",
    Longitude: "27.3791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "219",
    WardLookupId: "2897",
    VDNumber: "10871018",
    RegisteredPopulation: "117",
    VotingStationName: "SILOSITSHA COMMUNITY HALL",
    Address: "SILOSITSHA  MOUNTAIN VIEW  KING WILLIAMS TOWN",
    Latitude: "-32.7717",
    Longitude: "27.358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "220",
    WardLookupId: "2897",
    VDNumber: "10871029",
    RegisteredPopulation: "961",
    VotingStationName: "TYUTYU VILLAGE TENT",
    Address: "TYUTYU VILLAGE  TYU-TYU VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.8298",
    Longitude: "27.393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "221",
    WardLookupId: "2897",
    VDNumber: "10871074",
    RegisteredPopulation: "536",
    VotingStationName: "APOSTOLIC FAITH MISSION OF SA",
    Address: "KUWAIT SETTLEMENT  KING WILLIAMS TOWN",
    Latitude: "-32.8063",
    Longitude: "27.367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "222",
    WardLookupId: "2897",
    VDNumber: "10871456",
    RegisteredPopulation: "114",
    VotingStationName: "LUYTEVILLE LOWER/HIGHER PRIMARY SCHOOL",
    Address: "QUZINI LOCATION  QUZINI A/A  KING WILLIAMS TOWN",
    Latitude: "-32.8221",
    Longitude: "27.3551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "223",
    WardLookupId: "2898",
    VDNumber: "10670161",
    RegisteredPopulation: "940",
    VotingStationName: "EZIKWENI LOWER PRIMARY SCHOOL",
    Address: "RIRIE TRUST  DIMBAZA TOWNSHIP  KING WILLIAMS TOWN",
    Latitude: "-32.8413",
    Longitude: "27.2385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "224",
    WardLookupId: "2898",
    VDNumber: "10670206",
    RegisteredPopulation: "738",
    VotingStationName: "KUYASA HIGH SCHOOL",
    Address: "DIMBAZA  DIMBAZA TOWNSHIP  KING WILLIAMS TOWN",
    Latitude: "-32.8334",
    Longitude: "27.2206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "225",
    WardLookupId: "2898",
    VDNumber: "10670217",
    RegisteredPopulation: "1128",
    VotingStationName: "DIMBAZA LOWER PRIMARY SCHOOL",
    Address: "MANNENBURG  DIMBAZA TOWNSHIP  KING WILLIAMS TOWN",
    Latitude: "-32.8367",
    Longitude: "27.2084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "226",
    WardLookupId: "2898",
    VDNumber: "10670408",
    RegisteredPopulation: "352",
    VotingStationName: "IMIGUDU JUNIOR/SENIOR PRIMARY SCHOOL",
    Address: "PHASE 1 DIMBAZA WEST  DIMBAZA  DIMBAZA",
    Latitude: "-32.8313",
    Longitude: "27.1819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "227",
    WardLookupId: "2898",
    VDNumber: "10870196",
    RegisteredPopulation: "587",
    VotingStationName: "NOMGWADLA PRIMARY SCHOOL",
    Address: "NOMGWADLA  NOMGWADLA VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.8234",
    Longitude: "27.244",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "228",
    WardLookupId: "2898",
    VDNumber: "10870219",
    RegisteredPopulation: "1442",
    VotingStationName: "HOHO HIGH SCHOOL",
    Address: "MNGQESHA  MNGQESHA VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.8156",
    Longitude: "27.1967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "229",
    WardLookupId: "2898",
    VDNumber: "10870286",
    RegisteredPopulation: "453",
    VotingStationName: "PIRIE MISSION PRIMARY SCHOOL",
    Address: "PIRIE MISSION  DIMBAZA  KING WILLIAMS TOWN",
    Latitude: "-32.7905",
    Longitude: "27.2526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "230",
    WardLookupId: "2898",
    VDNumber: "10870512",
    RegisteredPopulation: "379",
    VotingStationName: "NAKANI PRIMARY SCHOOL",
    Address: "NAKANI  NAKANI VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.796",
    Longitude: "27.2182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "231",
    WardLookupId: "2898",
    VDNumber: "10870613",
    RegisteredPopulation: "269",
    VotingStationName: "JIKOLO COMMUNITY HALL",
    Address: "KING WILLIAMS TOWN  NEW REST,PIRIE AREA  KWT",
    Latitude: "-32.7811",
    Longitude: "27.2631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "232",
    WardLookupId: "2898",
    VDNumber: "10870804",
    RegisteredPopulation: "434",
    VotingStationName: "MZINTSHANE LOWER/HIGHER PRIMARY SCHOOL",
    Address: "MZINTSHANE  MZINTSHANE VILLAGE,KWT  KWT",
    Latitude: "-32.8107",
    Longitude: "27.2524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "233",
    WardLookupId: "2898",
    VDNumber: "10871344",
    RegisteredPopulation: "673",
    VotingStationName: "ZABALAZA LOWER & HIGHER PRIMARY SCHOOL",
    Address: "DIMBAZA TOWNSHIP  KING WILLIAMS TOWN    EAST LONDON",
    Latitude: "-32.8254",
    Longitude: "27.2229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "234",
    WardLookupId: "2899",
    VDNumber: "10670048",
    RegisteredPopulation: "2664",
    VotingStationName: "WAR MEMORIAL HALL",
    Address: "QUEENS ROAD  KING WILLIAMS TOWN  KING WILLIAMS TOWN",
    Latitude: "-32.8733",
    Longitude: "27.399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "235",
    WardLookupId: "2899",
    VDNumber: "10670138",
    RegisteredPopulation: "1135",
    VotingStationName: "WEST BANK PRIMARY SCHOOL",
    Address: "BRIDGE STREET  WEST BANK  KING WILLIAMS TOWN",
    Latitude: "-32.8653",
    Longitude: "27.3794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "236",
    WardLookupId: "2899",
    VDNumber: "10670330",
    RegisteredPopulation: "1341",
    VotingStationName: "KING WILLIAMS TOWN - TOWN HALL",
    Address: "MACLEAN STREET  KING WILLIAMS TOWN  EAST LONDON",
    Latitude: "-32.8772",
    Longitude: "27.3883",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "237",
    WardLookupId: "2899",
    VDNumber: "10670420",
    RegisteredPopulation: "1071",
    VotingStationName: "CENTRAL PRIMARY SCHOOL",
    Address: "JACARANDA ROAD  KING WILLIAMS TOWN    EAST LONDON",
    Latitude: "-32.8875",
    Longitude: "27.3998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "238",
    WardLookupId: "2899",
    VDNumber: "10870556",
    RegisteredPopulation: "1016",
    VotingStationName: "TOLOFIYENI LOWER/HIGHER PRIMARY SCHOOL",
    Address: "KING WILLIAMS TOWN  TOLOFIYENI VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.867",
    Longitude: "27.3399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "239",
    WardLookupId: "2899",
    VDNumber: "10870714",
    RegisteredPopulation: "451",
    VotingStationName: "MGQAKWEBE LOWER/HIGHER PRIMARY SCHOOL",
    Address:
      "MGQAKWEBE, KING WILLIAMS TOWN  MDINGI VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.8238",
    Longitude: "27.33",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "240",
    WardLookupId: "2899",
    VDNumber: "10870769",
    RegisteredPopulation: "763",
    VotingStationName: "HARPERTON LOWER/HIGHER PRIMARY SCHOOL",
    Address: "MASINGATA VILLAGE  K.W.T",
    Latitude: "-32.849",
    Longitude: "27.3696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "241",
    WardLookupId: "2899",
    VDNumber: "10871164",
    RegisteredPopulation: "391",
    VotingStationName: "KUSILE LOWER PRIMARY SCHOOL",
    Address: "MXHAXHO B  MXHAXHO VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.8843",
    Longitude: "27.3075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "242",
    WardLookupId: "2899",
    VDNumber: "10871175",
    RegisteredPopulation: "384",
    VotingStationName: "NOLUVO DAY CARE CENTRE",
    Address: "TOLOFIYENI LOCATION  KING WILLIAMS TOWN",
    Latitude: "-32.8672",
    Longitude: "27.3283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "243",
    WardLookupId: "2900",
    VDNumber: "10870095",
    RegisteredPopulation: "697",
    VotingStationName: "MXHALANGA LOWER/HIGHER SCHOOL",
    Address: "MXHALANGA LOCATION  KING WILLIAMS TOWN",
    Latitude: "-32.7388",
    Longitude: "27.3651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "244",
    WardLookupId: "2900",
    VDNumber: "10870130",
    RegisteredPopulation: "584",
    VotingStationName: "MBOMBO PRIMARY SCHOOL",
    Address: "MNGQESHA  LOWER MNGQESHA VILLAGE  K.W.T",
    Latitude: "-32.8245",
    Longitude: "27.274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "245",
    WardLookupId: "2900",
    VDNumber: "10870387",
    RegisteredPopulation: "331",
    VotingStationName: "EMDIZENI LOWER/HIGHER PRIMARY SCHOOL",
    Address: "EMDIZENI  ANDERS MISSION  KING WMS TOWN",
    Latitude: "-32.8802",
    Longitude: "27.2324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "246",
    WardLookupId: "2900",
    VDNumber: "10870398",
    RegisteredPopulation: "877",
    VotingStationName: "NONCAMPA LOWER/HIGHER PRIMARY SCHOOL",
    Address: "KING WILLIAMS TOWN  NONCAMPA VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.9082",
    Longitude: "27.2965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "247",
    WardLookupId: "2900",
    VDNumber: "10870567",
    RegisteredPopulation: "480",
    VotingStationName: "BULEMBU PRIMARY SCHOOL",
    Address: "BULEMBU  BULEMBU VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.8737",
    Longitude: "27.2678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "248",
    WardLookupId: "2900",
    VDNumber: "10870578",
    RegisteredPopulation: "859",
    VotingStationName: "ZAMA LOWER/HIGHER PRIMARY SCHOOL",
    Address: "KING WILLIAMS TOWN  MXAXO VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.8683",
    Longitude: "27.2949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "249",
    WardLookupId: "2900",
    VDNumber: "10870589",
    RegisteredPopulation: "645",
    VotingStationName: "ZUKHANYE JUNIOR SECONDARY SCHOOL",
    Address: "KING WILLIAMS TOWN  MZANTSI VILLAGE  K.W.T.",
    Latitude: "-32.8448",
    Longitude: "27.2944",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "250",
    WardLookupId: "2900",
    VDNumber: "10870624",
    RegisteredPopulation: "643",
    VotingStationName: "NONTO JUNIOR PRIMARY SCHOOL",
    Address: "KING WILLIAMS TOWN  TYHUSHA VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.7693",
    Longitude: "27.3255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "251",
    WardLookupId: "2900",
    VDNumber: "10870792",
    RegisteredPopulation: "594",
    VotingStationName: "MAMATA LOWER/HIGHER PRIMARY SCHOOL",
    Address: "UPPER MAMATA  MAMATA VILLAGE,KWT  K.W.T.",
    Latitude: "-32.8558",
    Longitude: "27.2483",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "252",
    WardLookupId: "2900",
    VDNumber: "10870826",
    RegisteredPopulation: "592",
    VotingStationName: "MNQESHA PRIMARY SCHOOL",
    Address: "MNGQESHA  KING WILLIAMS TOWN",
    Latitude: "-32.8327",
    Longitude: "27.2808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "253",
    WardLookupId: "2900",
    VDNumber: "10870905",
    RegisteredPopulation: "172",
    VotingStationName: "ST THOMAS CATHOLIC CHURCH COMMUNITY CENTRE",
    Address: "KWANOTHENGA  ST THOMAS SCHOOL FOR THE DEAF, WOODLANDS  KWT",
    Latitude: "-32.6987",
    Longitude: "27.371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "254",
    WardLookupId: "2900",
    VDNumber: "10870938",
    RegisteredPopulation: "243",
    VotingStationName: "ZIKHALINI PRIMARY SCHOOL",
    Address: "ZIKHALINI  ZIKHALINI VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.9068",
    Longitude: "27.2536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "255",
    WardLookupId: "2900",
    VDNumber: "10871142",
    RegisteredPopulation: "227",
    VotingStationName: "NONZAME PRIMARY SCHOOL",
    Address: "LENGE  LENGE VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.8875",
    Longitude: "27.251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "256",
    WardLookupId: "2900",
    VDNumber: "10871153",
    RegisteredPopulation: "397",
    VotingStationName: "BIZOKHULU LOWER/HIGHER PRIMARY SCHOOL",
    Address: "GWABA  GWABA VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.9083",
    Longitude: "27.2758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "257",
    WardLookupId: "2900",
    VDNumber: "10871186",
    RegisteredPopulation: "521",
    VotingStationName: "LUKHANYO SENIOR SECONDARY SCHOOL",
    Address: "KWABELEKILE  JAFTA VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.7974",
    Longitude: "27.3065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "258",
    WardLookupId: "2900",
    VDNumber: "10871355",
    RegisteredPopulation: "198",
    VotingStationName: "LINGELETHU SHOP",
    Address: "NGQELENI  KING WILLIAMS TOWN",
    Latitude: "-32.7899",
    Longitude: "27.3216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "259",
    WardLookupId: "2901",
    VDNumber: "10670026",
    RegisteredPopulation: "2203",
    VotingStationName: "GINSBERG COMMUNITY HALL",
    Address: "BIKO STREET  BIKO VILLAGE GINSBERG TOWNSHIP  KING WILLIAMS TOWN",
    Latitude: "-32.8928",
    Longitude: "27.3801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "260",
    WardLookupId: "2901",
    VDNumber: "10670037",
    RegisteredPopulation: "912",
    VotingStationName: "SCHORNVILLE COMMUNITY HALL",
    Address: "CENTRAL STREET  CENTRAL SCHORNVILLE  KING WILLIAMS TOWN",
    Latitude: "-32.8971",
    Longitude: "27.3981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "261",
    WardLookupId: "2901",
    VDNumber: "10670329",
    RegisteredPopulation: "1113",
    VotingStationName: "GINSBERG COMMUNITY CENTRE",
    Address: "GINSBERG  GINSBERG LOCATION  KING WILLIAMS TOWN",
    Latitude: "-32.8862",
    Longitude: "27.3781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "262",
    WardLookupId: "2901",
    VDNumber: "10670385",
    RegisteredPopulation: "739",
    VotingStationName: "WEIR COMMUNITY HALL",
    Address: "STEVE BIKO STREET  GINSBERG LOCATION  KING WILLIAMS TOWN",
    Latitude: "-32.8924",
    Longitude: "27.386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "263",
    WardLookupId: "2901",
    VDNumber: "10670396",
    RegisteredPopulation: "905",
    VotingStationName: "BROWNLEE JUNIOR PRIMARY SCHOOL",
    Address: "GINSBERG  GINSBERG LOCATION  KING WILLIAMS TOWN",
    Latitude: "-32.8978",
    Longitude: "27.3849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "264",
    WardLookupId: "2901",
    VDNumber: "10870309",
    RegisteredPopulation: "658",
    VotingStationName: "PHILLIP MZAMO SENIOR SECONDARY SCHOOL",
    Address: "NGXWALANE  NGXWALANE VILLAGE  K.W.T",
    Latitude: "-32.9081",
    Longitude: "27.3483",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "265",
    WardLookupId: "2901",
    VDNumber: "10870635",
    RegisteredPopulation: "673",
    VotingStationName: "BONKE LOWER/HIGHER PRIMARY SCHOOL",
    Address: "KING WILLIAMS TOWN  BONKE VILLAGE  KING WMS TOWN",
    Latitude: "-32.9109",
    Longitude: "27.3898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "266",
    WardLookupId: "2901",
    VDNumber: "10870815",
    RegisteredPopulation: "483",
    VotingStationName: "RHAYI PRIMARY SCHOOL",
    Address: "RHAYI  RHAYI VILLAGE,KWT  K.W.T",
    Latitude: "-32.8911",
    Longitude: "27.326",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "267",
    WardLookupId: "2901",
    VDNumber: "10870916",
    RegisteredPopulation: "866",
    VotingStationName: "SESETHU LOWER/HIGHER PRIMARY SCHOOL",
    Address: "KWALINI  KWALINI VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.9046",
    Longitude: "27.3664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "268",
    WardLookupId: "2902",
    VDNumber: "10820214",
    RegisteredPopulation: "344",
    VotingStationName: "ZUKILE LOWER/HIGHER PRIMARY SCHOOL",
    Address: "TSHABO 3/MZANTSI  TSHABO 3 BERLIN  EAST LONDON",
    Latitude: "-32.9617",
    Longitude: "27.5091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "269",
    WardLookupId: "2902",
    VDNumber: "10820225",
    RegisteredPopulation: "880",
    VotingStationName: "TSHABO 2 COMMUNITY HALL",
    Address: "TSHABO 2 VILLAGE  BERLIN  KING WILLIAMS TOWN",
    Latitude: "-32.9305",
    Longitude: "27.5287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "270",
    WardLookupId: "2902",
    VDNumber: "10820236",
    RegisteredPopulation: "492",
    VotingStationName: "DONGWE PRIMARY SCHOOL",
    Address: "DONGWE VILLAGE  DONGWE,BERLIN  EAST LONDON",
    Latitude: "-32.9414",
    Longitude: "27.5615",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "271",
    WardLookupId: "2902",
    VDNumber: "10820562",
    RegisteredPopulation: "476",
    VotingStationName: "FORT MURRAY LOWER/HIGHER PRIMARY SCHOOL",
    Address: "FORT MURRAY  FORT MURRAY VILLAGE  EAST LONDON",
    Latitude: "-32.9412",
    Longitude: "27.4821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "272",
    WardLookupId: "2902",
    VDNumber: "10820944",
    RegisteredPopulation: "141",
    VotingStationName: "MIMOSA PARK JUNIOR PRIMARY SCHOOL",
    Address: "KING WILLIAMS TOWN  MOUNT COKE   BUFFALO CITY",
    Latitude: "-33.0117",
    Longitude: "27.5026",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "273",
    WardLookupId: "2902",
    VDNumber: "10870107",
    RegisteredPopulation: "712",
    VotingStationName: "IMIQHAYI HIGH SCHOOL",
    Address: "MOUNT COKE MISSION  KING WILLIAMS TOWN",
    Latitude: "-32.9825",
    Longitude: "27.4431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "274",
    WardLookupId: "2902",
    VDNumber: "10870297",
    RegisteredPopulation: "455",
    VotingStationName: "DUBU LOWER & HIGHER PRIMARY SCHOOL",
    Address: "DUBHU VILLAGE  KING WILLIAM`S TOWN ADMIN AREA  KING WMS TOWN",
    Latitude: "-32.9318",
    Longitude: "27.3783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "275",
    WardLookupId: "2902",
    VDNumber: "10870310",
    RegisteredPopulation: "770",
    VotingStationName: "MASELE LOWER / HIGHER PRIMARY SCHOOL",
    Address: "N/A  MASELE  KING WMS TOWN",
    Latitude: "-32.9385",
    Longitude: "27.3312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "276",
    WardLookupId: "2902",
    VDNumber: "10870332",
    RegisteredPopulation: "378",
    VotingStationName: "SHUSHU COMMUNITY HALL",
    Address: "N/A  KWA-SHUSHU VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.9987",
    Longitude: "27.3004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "277",
    WardLookupId: "2902",
    VDNumber: "10870354",
    RegisteredPopulation: "629",
    VotingStationName: "NONIBE LOWER HIGHER PRIMARY SCHOOL",
    Address: "THAMARA VILLAGE  THAMARA ADMIN AREA  NGQUSHWA",
    Latitude: "-32.9847",
    Longitude: "27.2591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "278",
    WardLookupId: "2902",
    VDNumber: "10870545",
    RegisteredPopulation: "687",
    VotingStationName: "MLAKALAKA LOWER / HIGHER PRIMARY SCHOOL",
    Address: "MLAKALAKA  MLAKALAKA VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.9401",
    Longitude: "27.4391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "279",
    WardLookupId: "2902",
    VDNumber: "10870668",
    RegisteredPopulation: "662",
    VotingStationName: "SOBI LOWER/HIGHER PRIMARY SCHOOL",
    Address: "N/A  QAGA  KING WMS TOWN",
    Latitude: "-32.9462",
    Longitude: "27.2876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "280",
    WardLookupId: "2902",
    VDNumber: "10870758",
    RegisteredPopulation: "658",
    VotingStationName: "NDILEKA LOWER / HIGHER PRIMARY SCHOOL",
    Address: "ZWELITSHA  NDILEKA VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.9485",
    Longitude: "27.407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "281",
    WardLookupId: "2902",
    VDNumber: "10870871",
    RegisteredPopulation: "760",
    VotingStationName: "QONGQOTHA LOWER/HIGHER PRIMARY SCHOOL",
    Address: "KING WILLIAMS TOWN  QONGQOTHA VILLAGE  K.W.T.",
    Latitude: "-32.9619",
    Longitude: "27.4654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "282",
    WardLookupId: "2902",
    VDNumber: "10871197",
    RegisteredPopulation: "339",
    VotingStationName: "GODIDI LOWER AND SENIOR PRIMARY SCHOOL",
    Address: "GODIDI  GODIDI VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.9398",
    Longitude: "27.3982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "283",
    WardLookupId: "2902",
    VDNumber: "10871276",
    RegisteredPopulation: "498",
    VotingStationName: "JUBISA LOWER & HIGHER PRIMARY SCHOOL",
    Address: "JUBISA VILLAGE  THAMARA ADMIN AREA  KING WILLIAMS TOWN",
    Latitude: "-32.9711",
    Longitude: "27.3008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "284",
    WardLookupId: "2903",
    VDNumber: "10670093",
    RegisteredPopulation: "1640",
    VotingStationName: "QAQAMBA JUNIOR PRIMARY SCHOOL",
    Address: "ZONE 4  ZWELITSHA ZONE 4  KING WILLIAMS TOWN",
    Latitude: "-32.9201",
    Longitude: "27.4322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "285",
    WardLookupId: "2903",
    VDNumber: "10670105",
    RegisteredPopulation: "1419",
    VotingStationName: "ZWELITSHA HIGHER PRIMARY SCHOOL",
    Address: "1661 ZONE 7  ZWELITSHA ZONE 7  KING WILLIAMS TOWN",
    Latitude: "-32.9218",
    Longitude: "27.4194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "286",
    WardLookupId: "2903",
    VDNumber: "10670116",
    RegisteredPopulation: "2244",
    VotingStationName: "OR TAMBO HALL",
    Address: "MAIN STREET  ZWELITSHA  KING WILLIAMSTOWN",
    Latitude: "-32.9141",
    Longitude: "27.4217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "287",
    WardLookupId: "2903",
    VDNumber: "10670127",
    RegisteredPopulation: "639",
    VotingStationName: "NOBANTU LOWER PRIMARY SCHOOL",
    Address: "ZONE 2  ZWELITSHA ZONE 2  KING WILLIAMS TOWN",
    Latitude: "-32.9156",
    Longitude: "27.4115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "288",
    WardLookupId: "2903",
    VDNumber: "10670363",
    RegisteredPopulation: "480",
    VotingStationName: "THEMBALABANTU HIGH SCHOOL",
    Address: "ZONE 6  ZWELITSHA  KING WILLIAMS TOWN",
    Latitude: "-32.9179",
    Longitude: "27.4138",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "289",
    WardLookupId: "2903",
    VDNumber: "10670453",
    RegisteredPopulation: "753",
    VotingStationName: "HECTOR PETERSON HIGH SCHOOL",
    Address: "958 ZONE 4  ZWELITSHA  KING WILIAMS TOWN",
    Latitude: "-32.9222",
    Longitude: "27.427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "290",
    WardLookupId: "2904",
    VDNumber: "10590195",
    RegisteredPopulation: "2590",
    VotingStationName: "MZINGISI LOWER PRIMARY SCHOOL",
    Address: "3052 NU 1  MDANTSANE  EAST LONDON",
    Latitude: "-32.9408",
    Longitude: "27.7679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "291",
    WardLookupId: "2904",
    VDNumber: "10590230",
    RegisteredPopulation: "1807",
    VotingStationName: "FUNULWAZI LOWER PRIMARY SCHOOL",
    Address: "3414 NU2  MDANTSANE  EAST LONDON",
    Latitude: "-32.946",
    Longitude: "27.7639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "292",
    WardLookupId: "2904",
    VDNumber: "10590241",
    RegisteredPopulation: "1672",
    VotingStationName: "ULWAZI HIGH SCHOOL",
    Address: "4388 NU 2  MDANTSANE  EAST LONDON",
    Latitude: "-32.9528",
    Longitude: "27.7613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "293",
    WardLookupId: "2904",
    VDNumber: "10590252",
    RegisteredPopulation: "776",
    VotingStationName: "EBHOTWE LOWER PRIMARY SCHOOL",
    Address: "4391 NU 2  MDANTSANE  EAST LONDON",
    Latitude: "-32.9512",
    Longitude: "27.7615",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "294",
    WardLookupId: "2905",
    VDNumber: "10670228",
    RegisteredPopulation: "1177",
    VotingStationName: "ROJI SKENJANA HALL",
    Address: "PHALO AVENUE  BHISHO  KING WILLIAMS TOWN",
    Latitude: "-32.8494",
    Longitude: "27.4384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "295",
    WardLookupId: "2905",
    VDNumber: "10670251",
    RegisteredPopulation: "2131",
    VotingStationName: "BISHO LOWER/HIGHER PRIMARY SCHOOL",
    Address: "2 NGQIKA AVENUE  BISHO  KING WILLIAMS TOWN",
    Latitude: "-32.849",
    Longitude: "27.4416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "296",
    WardLookupId: "2905",
    VDNumber: "10670419",
    RegisteredPopulation: "633",
    VotingStationName: "GOOD NEWS CHRISTIAN CHURCH",
    Address: "CNR WOODHOUSE & MAITLAND ROAD  WOODHOUSE  KING WILLIAMS TOWN",
    Latitude: "-32.871",
    Longitude: "27.4175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "297",
    WardLookupId: "2905",
    VDNumber: "10670431",
    RegisteredPopulation: "691",
    VotingStationName: "SINAKO LOWER/HIGHER PRIMARY SCHOOL",
    Address: "53 SALAYI CLOSE, BISHO PARK, BISHO  BHISHO  KING WILLIAMS TOWN",
    Latitude: "-32.8398",
    Longitude: "27.4478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "298",
    WardLookupId: "2905",
    VDNumber: "10670442",
    RegisteredPopulation: "1314",
    VotingStationName: "TYUTYU LOWER/HIGHER PRIMARY SCHOOL",
    Address: "TYUTYU VILLAGE, BISHO   BHISHO  KING WILLIAMS TOWN",
    Latitude: "-32.8539",
    Longitude: "27.4496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "299",
    WardLookupId: "2905",
    VDNumber: "10870118",
    RegisteredPopulation: "765",
    VotingStationName: "SIYAZAMA HIGH SCHOOL",
    Address: "MAJALI VILLAGE  PEELTON ADMIN AREA  KING WILLIAMS TOWN",
    Latitude: "-32.751",
    Longitude: "27.5132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "300",
    WardLookupId: "2905",
    VDNumber: "10870129",
    RegisteredPopulation: "1074",
    VotingStationName: "NCEMERA LOWER/HIGHER PRIMARY SCHOOL",
    Address: "NCEMERHA VILLAGE  PEELTON ADMIN AREA  KING WILLIAMS TOWN",
    Latitude: "-32.7732",
    Longitude: "27.5011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "301",
    WardLookupId: "2905",
    VDNumber: "10871209",
    RegisteredPopulation: "388",
    VotingStationName: "PHILLIP MTYWAKU HIGH SCHOOL",
    Address: "ESIXEKWENI VILLAGE  PEELTON ADMIN AREA  KING WILLIAMS TOWN",
    Latitude: "-32.7883",
    Longitude: "27.4964",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "302",
    WardLookupId: "2905",
    VDNumber: "10871366",
    RegisteredPopulation: "694",
    VotingStationName: "BHALITI JUNIOR PRIMARY SCHOOL",
    Address: "PEELTON  MDANGE LOCATION  KING WILLIAMS TOWN",
    Latitude: "-32.7684",
    Longitude: "27.4859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "303",
    WardLookupId: "2906",
    VDNumber: "10670059",
    RegisteredPopulation: "2850",
    VotingStationName: "BREIDBACH COMMUNITY HALL",
    Address: "PROTEA DRIVE  BREIDBACH  KING WILLIAMS TOWN",
    Latitude: "-32.8853",
    Longitude: "27.4469",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "304",
    WardLookupId: "2906",
    VDNumber: "10670273",
    RegisteredPopulation: "1800",
    VotingStationName: "SWEETWATER CLINIC",
    Address: "SWEETWATERS  KING WILLIAMS TOWN",
    Latitude: "-32.9012",
    Longitude: "27.4153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "305",
    WardLookupId: "2906",
    VDNumber: "10670352",
    RegisteredPopulation: "695",
    VotingStationName: "EPHESIANS CHURCH",
    Address: "SEWENDELAND  BREIDBACH  KING WILLIAMS TOWN",
    Latitude: "-32.8919",
    Longitude: "27.4559",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "306",
    WardLookupId: "2906",
    VDNumber: "10670464",
    RegisteredPopulation: "1552",
    VotingStationName: "SWEETWATERS COMMUNITY CENTRE",
    Address: "SWEETWATER LOCATION  ZWELITSHA  KING WILLIAMS TOWN",
    Latitude: "-32.9016",
    Longitude: "27.423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "307",
    WardLookupId: "2906",
    VDNumber: "10670475",
    RegisteredPopulation: "491",
    VotingStationName: "FUNDISA LOWER/HIGHER PRIMARY SCHOOL",
    Address: "8734 SWEETWATERS  KING WILLIAMS TOWN",
    Latitude: "-32.9031",
    Longitude: "27.4145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "308",
    WardLookupId: "2906",
    VDNumber: "10670486",
    RegisteredPopulation: "203",
    VotingStationName: "QHALASHE TENT",
    Address: "QHALASHE LOCATION  BREIDBACH  KING WILLIAMS TOWN",
    Latitude: "-32.9097",
    Longitude: "27.4589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "309",
    WardLookupId: "2906",
    VDNumber: "10670497",
    RegisteredPopulation: "144",
    VotingStationName: "SIYATHEMBA  TENT",
    Address: "SIYATHEMBA LOCATION  BREIDBACH  KING WILLIAMS TOWN",
    Latitude: "-32.8762",
    Longitude: "27.4846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "310",
    WardLookupId: "2906",
    VDNumber: "10870141",
    RegisteredPopulation: "882",
    VotingStationName: "TSHATSHU COMMUNITY HALL",
    Address: "TSHATSHU  TSHATSHU VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.9166",
    Longitude: "27.4494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "311",
    WardLookupId: "2906",
    VDNumber: "10870275",
    RegisteredPopulation: "1024",
    VotingStationName: "TSHATSHU PRIMARY SCHOOL",
    Address: "ZWELITSHA  TSHATSHU VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.9146",
    Longitude: "27.4481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "312",
    WardLookupId: "2907",
    VDNumber: "10590825",
    RegisteredPopulation: "1772",
    VotingStationName: "BERLIN TOWN HALL",
    Address: "LEGION STREET  BERLIN  EAST LONDON",
    Latitude: "-32.8835",
    Longitude: "27.5812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "313",
    WardLookupId: "2907",
    VDNumber: "10670060",
    RegisteredPopulation: "882",
    VotingStationName: "THEMBALESIZWE SENIOR SECONDARY  SCHOOL",
    Address: "1 MAIN ROAD  ILITHA  KING WILLIAMS TOWN",
    Latitude: "-32.8871",
    Longitude: "27.5267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "314",
    WardLookupId: "2907",
    VDNumber: "10670071",
    RegisteredPopulation: "976",
    VotingStationName: "SOSEBENZA LOWER PRIMARY SCHOOL",
    Address: "1403 ILITHA  KING WILLIAMS TOWN",
    Latitude: "-32.8903",
    Longitude: "27.5354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "315",
    WardLookupId: "2907",
    VDNumber: "10670374",
    RegisteredPopulation: "1635",
    VotingStationName: "ILITHA COMMUNITY DEVELOPMENT TRUST",
    Address: "ILITHA LOCATION  ILITHA  KING WILLIAMS TOWN",
    Latitude: "-32.8944",
    Longitude: "27.5306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "316",
    WardLookupId: "2907",
    VDNumber: "10820203",
    RegisteredPopulation: "906",
    VotingStationName: "NOBUTHO LOWER/HIGHER PRIMARY SCHOOL",
    Address: "NXAMKWANA VILLAGE  BERLIN  KING WILLIAMS TOWN",
    Latitude: "-32.901",
    Longitude: "27.5433",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "317",
    WardLookupId: "2907",
    VDNumber: "10820809",
    RegisteredPopulation: "708",
    VotingStationName: "NTABOZUKO HIGH SCHOOL",
    Address: "NQONQWENI LOCATION  MACLEAN TOWN  BERLIN",
    Latitude: "-32.9113",
    Longitude: "27.6212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "318",
    WardLookupId: "2907",
    VDNumber: "10870747",
    RegisteredPopulation: "771",
    VotingStationName: "BULELANI LOWER/HIGHER PRIMARY SCHOOL",
    Address: "HANOVER VILLAGE  BHISHO  KING WLLIAMS TOWN",
    Latitude: "-32.8361",
    Longitude: "27.4711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "319",
    WardLookupId: "2907",
    VDNumber: "10870770",
    RegisteredPopulation: "1061",
    VotingStationName: "SKOBENI LOWER/HIGHER PRIMARY SCHOOL",
    Address: "SKOBENI VILLAGE  SKHOBENI  KING WILLIAMS TOWN",
    Latitude: "-32.8196",
    Longitude: "27.4829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "320",
    WardLookupId: "2908",
    VDNumber: "10590421",
    RegisteredPopulation: "2915",
    VotingStationName: "GEORGE RANDELL HIGH SCHOOL",
    Address: "CLOVELLY ROAD  SUNNYRIDGE  EAST LONDON",
    Latitude: "-33.0366",
    Longitude: "27.8658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "321",
    WardLookupId: "2908",
    VDNumber: "10590948",
    RegisteredPopulation: "2107",
    VotingStationName: "ASSEMBLY OF GO   BACK TO  GOD",
    Address: "508 MERINO ROAD  ORANGE GROVE  EASTR LONDON",
    Latitude: "-33.0311",
    Longitude: "27.844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "322",
    WardLookupId: "2908",
    VDNumber: "10590960",
    RegisteredPopulation: "1812",
    VotingStationName: "SINOMONDE PUBLIC SCHOOL",
    Address: "3 MJELO STREET  ORANGE GROOVE  EAST LONDON",
    Latitude: "-33.0257",
    Longitude: "27.828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "323",
    WardLookupId: "2908",
    VDNumber: "10590971",
    RegisteredPopulation: "764",
    VotingStationName: "WILLOW PARK PRIMARY SCHOOL",
    Address: "WILLOW AVENUE, WILLOWPARK  WILLOW PARK  EAST LONDON",
    Latitude: "-33.0422",
    Longitude: "27.8135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "324",
    WardLookupId: "2908",
    VDNumber: "10591343",
    RegisteredPopulation: "1325",
    VotingStationName: "COVE RIDGE PRIMARY SCHOOL",
    Address: "AIRPOT PHASE  LEACHES BAY  EAST LONDON",
    Latitude: "-33.0462",
    Longitude: "27.8421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "325",
    WardLookupId: "2908",
    VDNumber: "10770038",
    RegisteredPopulation: "548",
    VotingStationName: "WINTERSTRAND WORKSHOP",
    Address: "132 MUNRO DRIVE  WINTERSTRAND VILLAGE  EAST LONDON",
    Latitude: "-33.095",
    Longitude: "27.7877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "326",
    WardLookupId: "2909",
    VDNumber: "10590634",
    RegisteredPopulation: "2953",
    VotingStationName: "CITY HALL (VC)",
    Address: "1 OXFORD STREET  EAST LONDON  EAST LONDON",
    Latitude: "-33.0146",
    Longitude: "27.905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "327",
    WardLookupId: "2909",
    VDNumber: "10590645",
    RegisteredPopulation: "4624",
    VotingStationName: "ORIENT THEATRE",
    Address: "GANTEAUME CRESENT  QUIGNEY  EAST LONDON",
    Latitude: "-33.0233",
    Longitude: "27.9148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "328",
    WardLookupId: "2909",
    VDNumber: "10591444",
    RegisteredPopulation: "255",
    VotingStationName: "GRENS HIGH SCHOOL",
    Address: "MC JANNET DRIVE  BAYSVILLE  EASTLONDON",
    Latitude: "-32.9996",
    Longitude: "27.916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "329",
    WardLookupId: "2910",
    VDNumber: "10590038",
    RegisteredPopulation: "2066",
    VotingStationName: "NOKULUNGA JUNIOR PRIMARY SCHOOL",
    Address: "921 NU 10    MDANTSANE  EAST LONDON",
    Latitude: "-32.9371",
    Longitude: "27.7267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "330",
    WardLookupId: "2910",
    VDNumber: "10590049",
    RegisteredPopulation: "1359",
    VotingStationName: "NOBHOTWE JUNIOR PRIMARY SCHOOL",
    Address: "2436 NU 11 B  MDANTSANE  EAST LONDON",
    Latitude: "-32.9299",
    Longitude: "27.7255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "331",
    WardLookupId: "2910",
    VDNumber: "10590140",
    RegisteredPopulation: "1967",
    VotingStationName: "ASSEMBLY OF CHRIST FEDERAL",
    Address: "487 NU 10  MDANTSANE  EAST LONDON",
    Latitude: "-32.9407",
    Longitude: "27.7229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "332",
    WardLookupId: "2910",
    VDNumber: "10590410",
    RegisteredPopulation: "1984",
    VotingStationName: "PHUMELELANI HIGHER PRIMARY SCHOOL",
    Address: "4821 NU8  MDANTSANE  EAST LONDON",
    Latitude: "-32.9482",
    Longitude: "27.7161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "333",
    WardLookupId: "2911",
    VDNumber: "10820876",
    RegisteredPopulation: "1264",
    VotingStationName: "EKUPHUMLENI PRIMARY SCHOOL",
    Address: "EKUPHUMLENI  TSHATSHU VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.9359",
    Longitude: "27.4759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "334",
    WardLookupId: "2911",
    VDNumber: "10870220",
    RegisteredPopulation: "1422",
    VotingStationName: "MPUMELELO HIGH SCHOOL",
    Address: "HIGHWAY  NDEVANA  KING WILLIAMS TOWN",
    Latitude: "-32.9102",
    Longitude: "27.5041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "335",
    WardLookupId: "2911",
    VDNumber: "10870242",
    RegisteredPopulation: "2204",
    VotingStationName: "SIZAMILE HIGH SCHOOL",
    Address: "VELWANO SECTION  NDEVANA VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.9264",
    Longitude: "27.4971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "336",
    WardLookupId: "2911",
    VDNumber: "10870523",
    RegisteredPopulation: "1349",
    VotingStationName: "ENOCH SONTONGA SENIOR SECONDARY SCHOOL",
    Address: "MASIJONGANE SECTION  NDEVANA  KING WILLIAMS TOWN",
    Latitude: "-32.915",
    Longitude: "27.5072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "337",
    WardLookupId: "2911",
    VDNumber: "10870646",
    RegisteredPopulation: "1742",
    VotingStationName: "NDEVANA HIGHER PRIMARY SCHOOL",
    Address: "NDEVANA LOCATION  NDEVANA  KING WILLIAMS TOWN",
    Latitude: "-32.9064",
    Longitude: "27.5139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "338",
    WardLookupId: "2912",
    VDNumber: "10770027",
    RegisteredPopulation: "669",
    VotingStationName: "SUNRISE ON SEA RATEPAYERS ASSOCIATION HALL",
    Address: "EQUINOX ROAD  SUNRISE ON SEA  EAST LONDON",
    Latitude: "-32.9182",
    Longitude: "28.0505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "339",
    WardLookupId: "2912",
    VDNumber: "10820078",
    RegisteredPopulation: "1182",
    VotingStationName: "KWELEGHA PUBLIC SCHOOL",
    Address: "TUBA LOCATION  KWELERHA A/A  EAST LONDON",
    Latitude: "-32.8469",
    Longitude: "27.9928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "340",
    WardLookupId: "2912",
    VDNumber: "10820089",
    RegisteredPopulation: "529",
    VotingStationName: "GWABA COMBINED SCHOOL",
    Address: "GWABA VILLAGE   KWELERA  EAST LONDON",
    Latitude: "-32.7562",
    Longitude: "27.952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "341",
    WardLookupId: "2912",
    VDNumber: "10820090",
    RegisteredPopulation: "451",
    VotingStationName: "ZOZO PUBLIC SCHOOL",
    Address: "ZOZO LOCATION  KWELERA  EAST LONDON",
    Latitude: "-32.7983",
    Longitude: "27.9798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "342",
    WardLookupId: "2912",
    VDNumber: "10820102",
    RegisteredPopulation: "281",
    VotingStationName: "DWADWA PUBLIC SCHOOL",
    Address: "ELUNDINI FARM  BRAKFONTEIN  GONUBIE",
    Latitude: "-32.8592",
    Longitude: "27.9704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "343",
    WardLookupId: "2912",
    VDNumber: "10820124",
    RegisteredPopulation: "407",
    VotingStationName: "NONCEDO PUBLIC SCHOOL",
    Address: "THORNPARK  KWELERA  EAST LONDON",
    Latitude: "-32.906",
    Longitude: "27.8819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "344",
    WardLookupId: "2912",
    VDNumber: "10820472",
    RegisteredPopulation: "702",
    VotingStationName: "KING`S MISSION PUBLIC PRIMARY SCHOOL",
    Address: "JONGILANGA VILLAGE  KWELERA  KWELERHA",
    Latitude: "-32.8211",
    Longitude: "27.9693",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "345",
    WardLookupId: "2912",
    VDNumber: "10820584",
    RegisteredPopulation: "1024",
    VotingStationName: "NKWEZANA PUBLIC SCHOOL",
    Address: "CROSSWAYS  KWELERA  EAST LONDON",
    Latitude: "-32.9053",
    Longitude: "28.0226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "346",
    WardLookupId: "2912",
    VDNumber: "10820630",
    RegisteredPopulation: "356",
    VotingStationName: "KOMANISHI COMMUNITY HALL",
    Address: "KOMANISHI  KWELERHA   EAST LONDON",
    Latitude: "-32.8298",
    Longitude: "27.9675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "347",
    WardLookupId: "2912",
    VDNumber: "10820708",
    RegisteredPopulation: "610",
    VotingStationName: "RED CROSS COMMUNITY HALL",
    Address: "MTYANA LOCATION  KWELERA   EAST LONDON",
    Latitude: "-32.7682",
    Longitude: "27.9451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "348",
    WardLookupId: "2912",
    VDNumber: "10820731",
    RegisteredPopulation: "465",
    VotingStationName: "MTUNZI TSOLEKILE PUBLIC SCHOOL",
    Address: "ZOZO LOCATION  KWELERA   EAST LONDON",
    Latitude: "-32.7947",
    Longitude: "27.962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "349",
    WardLookupId: "2912",
    VDNumber: "10820742",
    RegisteredPopulation: "370",
    VotingStationName: "NOKHALA COMMUNITY HALL",
    Address: "NOKHALA LOCATION   KWELERA  EAST LONDON",
    Latitude: "-32.8236",
    Longitude: "27.987",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "350",
    WardLookupId: "2912",
    VDNumber: "10821035",
    RegisteredPopulation: "174",
    VotingStationName: "TIKINIKI INFORMAL SETTLEMENT KWELERA TENT",
    Address: "TIKKINIKIN LOCATION  KWELERHA  EAST LONDON",
    Latitude: "-32.9073",
    Longitude: "27.9798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "351",
    WardLookupId: "2912",
    VDNumber: "10821046",
    RegisteredPopulation: "89",
    VotingStationName: "BOTA INFORMAL SETTLEMENT TENT",
    Address: "BOTA LOCATION  KWELERHA  EAST LONDON",
    Latitude: "-32.8145",
    Longitude: "27.9527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "352",
    WardLookupId: "2912",
    VDNumber: "10821068",
    RegisteredPopulation: "196",
    VotingStationName: "MZAMO DAYCARE CENTRE",
    Address: "MANDELA PARK  KWELERHA  EAST LONDON",
    Latitude: "-32.8003",
    Longitude: "27.9605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "353",
    WardLookupId: "538",
    VDNumber: "10020014",
    RegisteredPopulation: "2282",
    VotingStationName: "SOPKOMBUISSAAL LOTUSVILLE (HALL)",
    Address: "BACON  LOTUSVILLE  ABERDEEN",
    Latitude: "-32.4855",
    Longitude: "24.0602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "354",
    WardLookupId: "538",
    VDNumber: "10020036",
    RegisteredPopulation: "939",
    VotingStationName: "LUXOLO INTERMEDIATE SCHOOL",
    Address: "LUXOLO STREET  THEMBALISIZWE  ABERDEEN",
    Latitude: "-32.4888",
    Longitude: "24.075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "355",
    WardLookupId: "538",
    VDNumber: "10320017",
    RegisteredPopulation: "276",
    VotingStationName: "MOBILE VOTING STATION (DOORNDRAAI FARM SHED)",
    Address: "DOORNDRAAI FARM   GRAAFF REINET   ABERDEEN",
    Latitude: "-32.8291",
    Longitude: "23.828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "356",
    WardLookupId: "538",
    VDNumber: "10520075",
    RegisteredPopulation: "124",
    VotingStationName: "PRETORIUSKUIL FARM",
    Address: "PRETORIUSKUIL  ABERDEEN  ABERDEEN PLAIN",
    Latitude: "-32.5118",
    Longitude: "23.6451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "357",
    WardLookupId: "539",
    VDNumber: "10090033",
    RegisteredPopulation: "2197",
    VotingStationName: "TE WATER SAAL",
    Address: "BOURKE STREET  GRAAFF-REINET  GRAAFF-REINET",
    Latitude: "-32.25",
    Longitude: "24.5331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "358",
    WardLookupId: "539",
    VDNumber: "10200014",
    RegisteredPopulation: "1063",
    VotingStationName: "COMMUNITY HALL",
    Address: "HUDSON STREET  PIENAARSIG  NIEU-BETHESDA",
    Latitude: "-31.8642",
    Longitude: "24.5616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "359",
    WardLookupId: "540",
    VDNumber: "10090134",
    RegisteredPopulation: "1156",
    VotingStationName: "GRAAFF- REINET PRIMARY SCHOOL",
    Address: "RANONKEL ROAD  ASHERVILLE  GRAAFF-REINET",
    Latitude: "-32.2844",
    Longitude: "24.5636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "360",
    WardLookupId: "540",
    VDNumber: "10090156",
    RegisteredPopulation: "1200",
    VotingStationName: "ASHERVILLE SECONDARY SCHOOL",
    Address: "13 AFFODIL STREET  ASHERVILLE  GRAAFF-REINET",
    Latitude: "-32.2824",
    Longitude: "24.5586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "361",
    WardLookupId: "541",
    VDNumber: "10090022",
    RegisteredPopulation: "1585",
    VotingStationName: "ISIBANE PRIMARY SCHOOL",
    Address: "MOTSOLO  UMASIZAKHE  GRAAFF-REINET",
    Latitude: "-32.2461",
    Longitude: "24.5438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "362",
    WardLookupId: "541",
    VDNumber: "10090044",
    RegisteredPopulation: "702",
    VotingStationName: "SAP ACADEMY",
    Address: "COLLEGE ROAD  SPANDAUVILLE  GRAAFF-REINET",
    Latitude: "-32.2615",
    Longitude: "24.5392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "363",
    WardLookupId: "541",
    VDNumber: "10090123",
    RegisteredPopulation: "767",
    VotingStationName: "UMASIZAKHE COMMUNITY HALL",
    Address: "BAARTMAN SQAURE  UMASIZAKHE  GRAAFF-REINET",
    Latitude: "-32.2419",
    Longitude: "24.5404",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "364",
    WardLookupId: "541",
    VDNumber: "10090145",
    RegisteredPopulation: "285",
    VotingStationName: "MORNING STAR HOUSE",
    Address: "LIEBENBERG  SPANDAUVILLE  GRAAFF-REINET",
    Latitude: "-32.2654",
    Longitude: "24.5364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "365",
    WardLookupId: "542",
    VDNumber: "10090055",
    RegisteredPopulation: "2571",
    VotingStationName: "LINGCOM PRIMARY SCHOOL",
    Address: "ASTERSTRAAT  KROONVALE  GRAAFF-REINET",
    Latitude: "-32.2514",
    Longitude: "24.5653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "366",
    WardLookupId: "542",
    VDNumber: "10090101",
    RegisteredPopulation: "986",
    VotingStationName: "ALEX LAING HALL",
    Address: "MOSSIE STREET  KROONVALE  GRAAFF-REINET",
    Latitude: "-32.2555",
    Longitude: "24.5611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "367",
    WardLookupId: "543",
    VDNumber: "10090011",
    RegisteredPopulation: "2643",
    VotingStationName: "NQWEBA SECONDARY SCHOOL",
    Address: "2037 KHIWANE  GRAAFF-REINET  UMASIZAKHE",
    Latitude: "-32.2349",
    Longitude: "24.5397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "368",
    WardLookupId: "544",
    VDNumber: "10020025",
    RegisteredPopulation: "898",
    VotingStationName: "LIBRARY HALL",
    Address: "ANDRIES PRETORIUS STR  ABERDEEN  ABERDEEN",
    Latitude: "-32.4742",
    Longitude: "24.0651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "369",
    WardLookupId: "544",
    VDNumber: "10090099",
    RegisteredPopulation: "517",
    VotingStationName: "ADENDORP TOWN HALL",
    Address: "BERRANGE ROAD  ADENDORP  GRAAFF-REINET",
    Latitude: "-32.3024",
    Longitude: "24.5487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "370",
    WardLookupId: "544",
    VDNumber: "10320028",
    RegisteredPopulation: "203",
    VotingStationName: "PLATDRIFT",
    Address: "PLATDRIFT  GRAAFF-REINET  ABERDEEN",
    Latitude: "-32.3329",
    Longitude: "23.9668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "371",
    WardLookupId: "544",
    VDNumber: "10380035",
    RegisteredPopulation: "245",
    VotingStationName: "VREDE HALL",
    Address: "VREDE  GRAAFF-REINET  CAMDEBOO",
    Latitude: "-32.222",
    Longitude: "24.2193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "372",
    WardLookupId: "545",
    VDNumber: "10310027",
    RegisteredPopulation: "845",
    VotingStationName: "WILLOWMORE TOWN HALL",
    Address: "56 KNYSNA STREET  WILLOWMORE  WILLOWMORE",
    Latitude: "-33.2936",
    Longitude: "23.4877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "373",
    WardLookupId: "545",
    VDNumber: "10310049",
    RegisteredPopulation: "313",
    VotingStationName: "WILLOWMORE SECONDARY SCHOOL",
    Address: "22 JOHANNA STREET  HILL VIEW  WILLOWMORE",
    Latitude: "-33.2867",
    Longitude: "23.4931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "374",
    WardLookupId: "545",
    VDNumber: "10520020",
    RegisteredPopulation: "888",
    VotingStationName: "RIETBRON COMMUNITY HALL",
    Address: "13 MAIN STREET  MANENZA SQUARE  RIETBRON",
    Latitude: "-32.8922",
    Longitude: "23.1583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "375",
    WardLookupId: "545",
    VDNumber: "10520053",
    RegisteredPopulation: "559",
    VotingStationName: "FUCC PRIMARY SCHOOL",
    Address: "ZAAIMANSHOEK  BAVIAANSKLOOF  BAVIAANS",
    Latitude: "-33.5478",
    Longitude: "24.0093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "376",
    WardLookupId: "545",
    VDNumber: "10520064",
    RegisteredPopulation: "105",
    VotingStationName: "UCC CHURCH SCHOOL",
    Address: "VONDELING  VONDELING FARM  WILLOWMORE",
    Latitude: "-33.3397",
    Longitude: "23.12",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "377",
    WardLookupId: "546",
    VDNumber: "10310016",
    RegisteredPopulation: "2445",
    VotingStationName: "WILLOWMORE SPORTS GROUND",
    Address: "EAST ROAD  HILLVIEW  WILLOWMORE",
    Latitude: "-33.2816",
    Longitude: "23.4937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "378",
    WardLookupId: "547",
    VDNumber: "10190012",
    RegisteredPopulation: "1090",
    VotingStationName: "PRINSVALE",
    Address: "54 MAX MAMASE  PRINSVALE  KLIPPLAAT",
    Latitude: "-33.0239",
    Longitude: "24.3274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "379",
    WardLookupId: "547",
    VDNumber: "10410039",
    RegisteredPopulation: "145",
    VotingStationName: "RATELSKLOOF FARMSHED",
    Address: "01 RAVELSKLOOF FARM  JANSENVILLE  JANSENVILLE",
    Latitude: "-32.8686",
    Longitude: "24.7229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "380",
    WardLookupId: "547",
    VDNumber: "10410040",
    RegisteredPopulation: "992",
    VotingStationName: "KLIPPLAAT TOWN HALL",
    Address: "MAINSTREET 12  RATELSKLOOF  JANSENVILLE",
    Latitude: "-33.0178",
    Longitude: "24.3399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "381",
    WardLookupId: "548",
    VDNumber: "10130016",
    RegisteredPopulation: "1494",
    VotingStationName: "JANSENVILLE TOWN HALL",
    Address: "25 MAIN STREET  JANSENVILLE  JANSENVILLE",
    Latitude: "-32.9445",
    Longitude: "24.664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "382",
    WardLookupId: "548",
    VDNumber: "10130027",
    RegisteredPopulation: "1698",
    VotingStationName: "TP MEJANE MEMORIAL HALL",
    Address: "23 GUNGULUZA  DRAAILOCATION  IKWEZI [JANSENVILLE]",
    Latitude: "-32.94",
    Longitude: "24.6838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "383",
    WardLookupId: "549",
    VDNumber: "10290013",
    RegisteredPopulation: "1274",
    VotingStationName: "STEYTLERVILLE TOWN HALL",
    Address: "3 SAREL CILLIERS STREET  STEYTLERVILLE  STEYTLERVILLE",
    Latitude: "-33.3288",
    Longitude: "24.3446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "384",
    WardLookupId: "549",
    VDNumber: "10410017",
    RegisteredPopulation: "241",
    VotingStationName: "WATERFORD",
    Address: "WATERFORD  JANSENVILLE",
    Latitude: "-33.0751",
    Longitude: "25.0084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "385",
    WardLookupId: "549",
    VDNumber: "10410028",
    RegisteredPopulation: "155",
    VotingStationName: "SKILPADFONTEIN SHED",
    Address: "SKILPADFONTEIN  JANSENVILLE",
    Latitude: "-33.0257",
    Longitude: "24.6578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "386",
    WardLookupId: "549",
    VDNumber: "10410051",
    RegisteredPopulation: "102",
    VotingStationName: "WOLWEFONTEIN COMMUNITY HALL",
    Address: "02 MAIN  WOLWEFONTEIN  WOLWEFONTEIN",
    Latitude: "-33.292",
    Longitude: "24.8249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "387",
    WardLookupId: "549",
    VDNumber: "10500017",
    RegisteredPopulation: "96",
    VotingStationName: "SANDKRAAL",
    Address: "SANDKRAAL FARM  STEYTLERVILLE  STEYTLERVILLE",
    Latitude: "-33.2529",
    Longitude: "24.0156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "388",
    WardLookupId: "549",
    VDNumber: "10500040",
    RegisteredPopulation: "145",
    VotingStationName: "STEYTLERVILLE SHOW GROUND",
    Address: "STEYTLERVILLE  STEYTLERVILLE",
    Latitude: "-33.3214",
    Longitude: "24.3447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "389",
    WardLookupId: "549",
    VDNumber: "10500051",
    RegisteredPopulation: "51",
    VotingStationName: "THE OLD POST OFFICE",
    Address: "2 RAILWAY STREET  MOUNT STEWARD  STEYTLERVILLE",
    Latitude: "-33.1479",
    Longitude: "24.4379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "390",
    WardLookupId: "550",
    VDNumber: "10290024",
    RegisteredPopulation: "1111",
    VotingStationName: "TOM KASIBE PRIMARY SCHOOL",
    Address: "12 MIGGELS VALLEY  VUYOLWETHU TOWN SHIP  STEYTLERVILLE",
    Latitude: "-33.342",
    Longitude: "24.3325",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "391",
    WardLookupId: "550",
    VDNumber: "10310038",
    RegisteredPopulation: "174",
    VotingStationName: "FULLARTON PRIMARY",
    Address: "FULLARTON STATION  FULLARTON  WILLOWMORE",
    Latitude: "-33.1797",
    Longitude: "23.8418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "392",
    WardLookupId: "550",
    VDNumber: "10310050",
    RegisteredPopulation: "1072",
    VotingStationName: "MORNINGSIDE COMMUNITY HALL",
    Address: "30 KLIPBOK STREET  MORNINGSIDE  WILLOWMORE",
    Latitude: "-33.2725",
    Longitude: "23.4993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "393",
    WardLookupId: "550",
    VDNumber: "10500028",
    RegisteredPopulation: "113",
    VotingStationName: "DRIE KUILEN SCHOOL",
    Address: "DRIE KUILEN  UITSIG  STEYTLERVILLE",
    Latitude: "-33.4236",
    Longitude: "24.6568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "394",
    WardLookupId: "550",
    VDNumber: "10500039",
    RegisteredPopulation: "89",
    VotingStationName: "BEULAH SCHOOL",
    Address: "1 BEULAH  STEYTLERVILLE  STEYTLERVILLE",
    Latitude: "-33.5713",
    Longitude: "24.4296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "395",
    WardLookupId: "550",
    VDNumber: "10520031",
    RegisteredPopulation: "111",
    VotingStationName: "MILLER CONGREGATIONAL SCHOOL",
    Address: "1 MILLER  MILLER FARM  WILLOWMORE",
    Latitude: "-33.0842",
    Longitude: "23.9221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "396",
    WardLookupId: "551",
    VDNumber: "10090066",
    RegisteredPopulation: "1069",
    VotingStationName: "SPANDAU PUBLIC HIGH SCHOOL",
    Address: "BREE STREET  KROONVALE  GRAAFF-REINET",
    Latitude: "-32.2605",
    Longitude: "24.5585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "397",
    WardLookupId: "551",
    VDNumber: "10090077",
    RegisteredPopulation: "2082",
    VotingStationName: "RYNEVELD PRIMARY SCHOOL",
    Address: "DORING STREET  SANTAVILLE  GRAAFF-REINET",
    Latitude: "-32.2717",
    Longitude: "24.5596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "398",
    WardLookupId: "551",
    VDNumber: "10090088",
    RegisteredPopulation: "1696",
    VotingStationName: "NARSINGSTRAAT PRIMARY SCHOOL",
    Address: "NARSINGSTRAAT  ASHERVILLE  GRAAFF-REINET",
    Latitude: "-32.2791",
    Longitude: "24.561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "399",
    WardLookupId: "2913",
    VDNumber: "10070019",
    RegisteredPopulation: "1160",
    VotingStationName: "MADIBA COMMUNITY HALL",
    Address: "PEDDIE STREET  MSOBOMVU LOCATION  COOKHOUSE",
    Latitude: "-32.7415",
    Longitude: "25.7976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "400",
    WardLookupId: "2913",
    VDNumber: "10070020",
    RegisteredPopulation: "1319",
    VotingStationName: "COOKHOUSE TOWN HALL",
    Address: "06 MAIN ROAD  COOKHOUSE  COOKHOUSE",
    Latitude: "-32.7459",
    Longitude: "25.8075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "401",
    WardLookupId: "2913",
    VDNumber: "10490060",
    RegisteredPopulation: "179",
    VotingStationName: "THORNGROVE SCHOOL",
    Address: "FARM  KOKSKRAAL  COOKHOUSE",
    Latitude: "-32.6293",
    Longitude: "25.8236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "402",
    WardLookupId: "2913",
    VDNumber: "10490116",
    RegisteredPopulation: "248",
    VotingStationName: "WITMOS PRIMARY SCHOOL",
    Address: "FARM  COOKHOUSE  COOKHOUSE",
    Latitude: "-32.6032",
    Longitude: "25.7491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "403",
    WardLookupId: "2913",
    VDNumber: "10780028",
    RegisteredPopulation: "111",
    VotingStationName: "BAVIAANS KRANTZ",
    Address: "FARM  BAVIAANS KRANTZ  COOKHOOUSE",
    Latitude: "-32.7695",
    Longitude: "25.8952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "404",
    WardLookupId: "2913",
    VDNumber: "10780039",
    RegisteredPopulation: "81",
    VotingStationName: "DE KLERKSDAAL TENT",
    Address: "DE KLERKSDAAL FARM  BEDFORD AREA  COOKHOUSE",
    Latitude: "-32.6004",
    Longitude: "25.9048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "405",
    WardLookupId: "2913",
    VDNumber: "10780040",
    RegisteredPopulation: "92",
    VotingStationName: "KRIEGERSKRAAL",
    Address: "KRIEGERSKRAAL FARM  BEDFORD  BEDFORD",
    Latitude: "-32.9652",
    Longitude: "25.9449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "406",
    WardLookupId: "2914",
    VDNumber: "10270022",
    RegisteredPopulation: "1319",
    VotingStationName: "TOWN HALL",
    Address: "67 NOJOLI STREET  SOMERSET EAST  SOMERSET EAST",
    Latitude: "-32.7197",
    Longitude: "25.5835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "407",
    WardLookupId: "2914",
    VDNumber: "10270055",
    RegisteredPopulation: "2340",
    VotingStationName: "PROFESSOR JAKES GERWEL MULTI-PURPOSE CENTRE",
    Address: "PERSEVERANCE  AEROVILLE  SOMERSET EAST",
    Latitude: "-32.7467",
    Longitude: "25.5993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "408",
    WardLookupId: "2915",
    VDNumber: "10270011",
    RegisteredPopulation: "2084",
    VotingStationName: "METHODIST CHURCH",
    Address: "18 JACK PETERS  MNANDI LOCATION  SOMERSET EAST",
    Latitude: "-32.729",
    Longitude: "25.602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "409",
    WardLookupId: "2915",
    VDNumber: "10270077",
    RegisteredPopulation: "1096",
    VotingStationName: "GILBERT XUZA PRIMARY SCHOOL",
    Address: "2ND AVENUE (NEW BRIGHTON)  NEW BRIGHTON  SOMERSET EAST",
    Latitude: "-32.7262",
    Longitude: "25.5979",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "410",
    WardLookupId: "2916",
    VDNumber: "10230017",
    RegisteredPopulation: "1003",
    VotingStationName: "PEARSTON SENIOR SECONDARY SCHOOL",
    Address: "BUITEKANT STREET  PEARSTON",
    Latitude: "-32.5786",
    Longitude: "25.1389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "411",
    WardLookupId: "2916",
    VDNumber: "10230028",
    RegisteredPopulation: "671",
    VotingStationName: "KHANYISO HALL",
    Address: "MAIN STREET  KHANYISO LOCATION  PEARSTON",
    Latitude: "-32.5883",
    Longitude: "25.1391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "412",
    WardLookupId: "2916",
    VDNumber: "10230039",
    RegisteredPopulation: "867",
    VotingStationName: "NELSIG COMMUNITY HALL",
    Address: "VAN DER VYVER  NELSIG  PEARSTON",
    Latitude: "-32.584",
    Longitude: "25.1504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "413",
    WardLookupId: "2916",
    VDNumber: "10450022",
    RegisteredPopulation: "73",
    VotingStationName: "WALSINGHAM",
    Address: "FARM  PEARSTON  PEARSTON",
    Latitude: "-32.4882",
    Longitude: "25.1241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "414",
    WardLookupId: "2916",
    VDNumber: "10450033",
    RegisteredPopulation: "70",
    VotingStationName: "EBENEZER FARM",
    Address: "R337  EBENEZER  PEARSTON",
    Latitude: "-32.7214",
    Longitude: "24.909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "415",
    WardLookupId: "2916",
    VDNumber: "10490048",
    RegisteredPopulation: "162",
    VotingStationName: "DOORNBOS",
    Address: "DOORNBOS FARM HOUSE  DOORNBOS  SOMERSET EAST",
    Latitude: "-32.5029",
    Longitude: "25.4253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "416",
    WardLookupId: "2917",
    VDNumber: "10270033",
    RegisteredPopulation: "1786",
    VotingStationName: "FRANCESVALE HALL",
    Address: "PAULET STREET  FRANCESVALE  SOMERSET EAST",
    Latitude: "-32.7158",
    Longitude: "25.5939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "417",
    WardLookupId: "2917",
    VDNumber: "10270044",
    RegisteredPopulation: "1301",
    VotingStationName: "GLEN AVON",
    Address: "GLEN AVON ROAD  OLD LOCATION  SOMERSET EAST",
    Latitude: "-32.7204",
    Longitude: "25.5976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "418",
    WardLookupId: "2918",
    VDNumber: "10070031",
    RegisteredPopulation: "730",
    VotingStationName: "COOKHOUSE SECONDARY SCHOOL",
    Address: "936 DUBULA   BONGWENI LOCATION   COOKHOUSE",
    Latitude: "-32.7466",
    Longitude: "25.7931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "419",
    WardLookupId: "2918",
    VDNumber: "10450044",
    RegisteredPopulation: "81",
    VotingStationName: "BLOUKRANS FARM",
    Address: "R63  BLAAUWKRANTZ  PEARSTON",
    Latitude: "-32.7656",
    Longitude: "25.2049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "420",
    WardLookupId: "2918",
    VDNumber: "10490015",
    RegisteredPopulation: "196",
    VotingStationName: "BRACEFIELD PRIMARY SCHOOL",
    Address: "BRACEFIELD   SOMERSET EAST",
    Latitude: "-33.1805",
    Longitude: "25.6953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "421",
    WardLookupId: "2918",
    VDNumber: "10490026",
    RegisteredPopulation: "155",
    VotingStationName: "MIDDLEWATER",
    Address: "SOMERSET EAST  ",
    Latitude: "-33.049",
    Longitude: "25.2519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "422",
    WardLookupId: "2918",
    VDNumber: "10490037",
    RegisteredPopulation: "68",
    VotingStationName: "DRIE FONTEIN FARM",
    Address: "CONSTANT FARM  SOMERSET EAST  SOMERSET EAST",
    Latitude: "-32.7292",
    Longitude: "25.5061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "423",
    WardLookupId: "2918",
    VDNumber: "10490059",
    RegisteredPopulation: "294",
    VotingStationName: "GOLDEN VALLEY",
    Address: "1 MAIN ROAD  COOKHOUSE  COOKHOUSE",
    Latitude: "-32.8095",
    Longitude: "25.796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "424",
    WardLookupId: "2918",
    VDNumber: "10490071",
    RegisteredPopulation: "125",
    VotingStationName: "UITKEER",
    Address: "FISH SUNDAYS RIVER  UITKEER  SOMERSET EAST",
    Latitude: "-32.7646",
    Longitude: "25.6667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "425",
    WardLookupId: "2918",
    VDNumber: "10490082",
    RegisteredPopulation: "92",
    VotingStationName: "THE RIDGES",
    Address: "RIDGES FARM  SOMERSET EAST  SOMERSET EAST",
    Latitude: "-32.8749",
    Longitude: "25.4059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "426",
    WardLookupId: "2918",
    VDNumber: "10490093",
    RegisteredPopulation: "53",
    VotingStationName: "GREILINGSKRAAL",
    Address: "GREILINGSKRAAL FARM  SOMERSET EAST  SOMERSET EAST",
    Latitude: "-32.9311",
    Longitude: "25.5424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "427",
    WardLookupId: "2918",
    VDNumber: "10490105",
    RegisteredPopulation: "366",
    VotingStationName: "MIDDLETON",
    Address: "N10  COOKHOUSE  COOKHOUSE",
    Latitude: "-32.954",
    Longitude: "25.8135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "428",
    WardLookupId: "2919",
    VDNumber: "10100237",
    RegisteredPopulation: "739",
    VotingStationName: "HOOGGENOEG HALL",
    Address: "GRAHAMSTOWN  HOOGGENNOEG",
    Latitude: "-33.2872",
    Longitude: "26.5363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "429",
    WardLookupId: "2919",
    VDNumber: "10260010",
    RegisteredPopulation: "573",
    VotingStationName: "ALFRED DIKE KOTA HALL",
    Address: "BEHREN  RIEBEECK EAST  RIEBEECK EAST",
    Latitude: "-33.1997",
    Longitude: "26.1483",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "430",
    WardLookupId: "2919",
    VDNumber: "10330018",
    RegisteredPopulation: "410",
    VotingStationName: "CARLISLE BRIDGE FARM SCHOOL",
    Address: "CARLISLE BRIDGE  ",
    Latitude: "-33.0694",
    Longitude: "26.2231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "431",
    WardLookupId: "2919",
    VDNumber: "10330074",
    RegisteredPopulation: "391",
    VotingStationName: "KOONAP COMMUNITY HALL",
    Address: "EC104 - MAKANA [GRAHAMSTOWN]  ",
    Latitude: "-33.1305",
    Longitude: "26.6195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "432",
    WardLookupId: "2919",
    VDNumber: "10330119",
    RegisteredPopulation: "96",
    VotingStationName: "DOUGLAS HEIGHTS TENT",
    Address:
      "DOUGLAS HEIGHTS FARM  DOUGLAS HEIGHTS FARM AREA  DOUGLAS HEIGHTS FARM",
    Latitude: "-33.0657",
    Longitude: "26.4881",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "433",
    WardLookupId: "2920",
    VDNumber: "10100035",
    RegisteredPopulation: "1136",
    VotingStationName: "NTLEBI LOWER PRIMARY SCHOOL",
    Address: "00 DANIELS STREET  MAKANASKOP  GRAHAMSTOWN",
    Latitude: "-33.29",
    Longitude: "26.5556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "434",
    WardLookupId: "2920",
    VDNumber: "10100046",
    RegisteredPopulation: "1753",
    VotingStationName: "NOLUTHANDO HALL",
    Address: "NOMPONDO STREET  MAKANSKOP  GRAHAMSTOWN",
    Latitude: "-33.292",
    Longitude: "26.5593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "435",
    WardLookupId: "2921",
    VDNumber: "10100079",
    RegisteredPopulation: "2076",
    VotingStationName: "MARY WATERS SENIOR SECONDARY SCHOOL",
    Address: "01 ANDREW HIMRO ROAD  GRAHAMSTOWN NORTH  GRAHAMSTOWN",
    Latitude: "-33.2922",
    Longitude: "26.5387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "436",
    WardLookupId: "2921",
    VDNumber: "10100170",
    RegisteredPopulation: "2044",
    VotingStationName: "ASSUMPTION CLINIC TENT",
    Address: "BISHOLO STREET  PHUMLANI LOCATION  GRAHAMSTOWN",
    Latitude: "-33.2878",
    Longitude: "26.5528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "437",
    WardLookupId: "2922",
    VDNumber: "10100136",
    RegisteredPopulation: "1079",
    VotingStationName: "GRAEME COLLEGE",
    Address: "00 TEMPLETON DRIVE  SOMERSET HEIGHTS  GRAHAMSTOWN",
    Latitude: "-33.2955",
    Longitude: "26.5205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "438",
    WardLookupId: "2922",
    VDNumber: "10100147",
    RegisteredPopulation: "1922",
    VotingStationName: "RECREATION HALL",
    Address: "ALBANY ROAD  GRAHAMSTOWN NORTH  GRAHAMSTOWN",
    Latitude: "-33.3007",
    Longitude: "26.5366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "439",
    WardLookupId: "2922",
    VDNumber: "10100158",
    RegisteredPopulation: "1992",
    VotingStationName: "ST AIDANS COMPLEX",
    Address: "00 CNR MILNER/CONSTITUTION STREET  OATLANDS  GRAHAMSTOWN",
    Latitude: "-33.3051",
    Longitude: "26.5219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "440",
    WardLookupId: "2923",
    VDNumber: "10100181",
    RegisteredPopulation: "1842",
    VotingStationName: "UMZI WASETIYOPIYA CATHOLIC CHURCH",
    Address: "ERF 8649 HOBE STREET  EXTENSION 9 JOZA LOCATION  GRAHAMSTOWN",
    Latitude: "-33.2833",
    Longitude: "26.5569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "441",
    WardLookupId: "2923",
    VDNumber: "10100204",
    RegisteredPopulation: "1970",
    VotingStationName: "NEW COMMUNITY HALL",
    Address: "MAKANA  ",
    Latitude: "-33.2817",
    Longitude: "26.5592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "442",
    WardLookupId: "2924",
    VDNumber: "10100024",
    RegisteredPopulation: "1832",
    VotingStationName: "INDOOR SPORTS CENTRE",
    Address: "N/A MANDELA STREET  EXTENSION 6  GRAHAMSTOWN",
    Latitude: "-33.2882",
    Longitude: "26.5715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "443",
    WardLookupId: "2924",
    VDNumber: "10100226",
    RegisteredPopulation: "632",
    VotingStationName: "IKUTLISO DANIELS HIGH SCHOOL",
    Address: "NCAME STREET  EXTENSION 4  GRAHAMSTOWN",
    Latitude: "-33.2864",
    Longitude: "26.5649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "444",
    WardLookupId: "2924",
    VDNumber: "10100248",
    RegisteredPopulation: "458",
    VotingStationName: "D.D.SIWISA PRIMARY SCHOOL",
    Address: "66666 EXTENTION 6  GRAHAMSTOWN  GRAHAMSTOWN",
    Latitude: "-33.2854",
    Longitude: "26.5776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "445",
    WardLookupId: "2925",
    VDNumber: "10100068",
    RegisteredPopulation: "1677",
    VotingStationName: "TANTYI HALL",
    Address: "Z STREET  MAKANASKOP  GRAHAMSTOWN",
    Latitude: "-33.295",
    Longitude: "26.5466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "446",
    WardLookupId: "2925",
    VDNumber: "10100080",
    RegisteredPopulation: "1527",
    VotingStationName: "HLALANI HALL",
    Address: "N/A  HLALANI LOCATION  HLALANI LOCATION",
    Latitude: "-33.3013",
    Longitude: "26.5473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "447",
    WardLookupId: "2926",
    VDNumber: "10100125",
    RegisteredPopulation: "2702",
    VotingStationName: "PUBLIC LIBRARY ACTIVITIES ROOM",
    Address: "HILL STREET  GARAHAMSTOWN  GRAHAMSTOWN",
    Latitude: "-33.3111",
    Longitude: "26.5269",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "448",
    WardLookupId: "2927",
    VDNumber: "10100057",
    RegisteredPopulation: "1195",
    VotingStationName: "NOMBULELO SENIOR SECONDARY SCHOOL",
    Address: "00 SANI STREET  MAKANSKOP  GRAHAMSTOWN",
    Latitude: "-33.2929",
    Longitude: "26.5614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "449",
    WardLookupId: "2927",
    VDNumber: "10100103",
    RegisteredPopulation: "1359",
    VotingStationName: "FIKIZOLO LOWER PRIMARY SCHOOL",
    Address: "00 UPPER RAEMAN RD  FINGO  GRAHAMSTOWN",
    Latitude: "-33.3045",
    Longitude: "26.5502",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "450",
    WardLookupId: "2928",
    VDNumber: "10100091",
    RegisteredPopulation: "1872",
    VotingStationName: "BB ZONDANI HALL",
    Address: "VICTORIA ROAD  FINGO  GRAHAMSTOWN",
    Latitude: "-33.305",
    Longitude: "26.5419",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "451",
    WardLookupId: "2928",
    VDNumber: "10100114",
    RegisteredPopulation: "804",
    VotingStationName: "NATHANIEL NYALUZA  HIGH SCHOOL",
    Address: "00 A STREET  OLD LOCATION  GRAHAMSTOWN",
    Latitude: "-33.3114",
    Longitude: "26.5484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "452",
    WardLookupId: "2928",
    VDNumber: "10100260",
    RegisteredPopulation: "610",
    VotingStationName: "APOSTOLIC FAITH MISSION CHURCH",
    Address: "5 Z STREET  GRAHAMSTOWN  GRAHAMSTOWN",
    Latitude: "-33.3033",
    Longitude: "26.5415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "453",
    WardLookupId: "2929",
    VDNumber: "10100013",
    RegisteredPopulation: "2093",
    VotingStationName: "NTSIKA SENIOR SECONDARY SCHOOL",
    Address: "EXTENSION 07  GRAHAMSTOWN",
    Latitude: "-33.2794",
    Longitude: "26.5838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "454",
    WardLookupId: "2929",
    VDNumber: "10100192",
    RegisteredPopulation: "1079",
    VotingStationName: "ETHOPIAN ORTHODOX CHURCH OF SOUTH AFRICA",
    Address: "SANI STREET  EXTENSION 6  GRAHAMSTOWN",
    Latitude: "-33.2783",
    Longitude: "26.5758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "455",
    WardLookupId: "2930",
    VDNumber: "10100169",
    RegisteredPopulation: "3484",
    VotingStationName: "STEVE BIKO HALL",
    Address: "PRINCE ALFRED STREET  GRAHAMSTOWN  GRAHAMSTOWN",
    Latitude: "-33.3127",
    Longitude: "26.5175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "456",
    WardLookupId: "2931",
    VDNumber: "10100215",
    RegisteredPopulation: "1591",
    VotingStationName: "VUKANI ( TENT SITE)",
    Address: "REV LOLWANA STREET  VUKANI LOCATION  GRAHAMSTOWN",
    Latitude: "-33.3095",
    Longitude: "26.557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "457",
    WardLookupId: "2931",
    VDNumber: "10330052",
    RegisteredPopulation: "523",
    VotingStationName: "SALEM CLUB HALL",
    Address: "SALEM CLUB  PO SALEM  GRAHAMSTOWN",
    Latitude: "-33.4703",
    Longitude: "26.4842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "458",
    WardLookupId: "2931",
    VDNumber: "10330063",
    RegisteredPopulation: "390",
    VotingStationName: "MANLEY FLATS SPORTS CLUB",
    Address: "MANLEY FLATS  GRAHAMSTOWN",
    Latitude: "-33.352",
    Longitude: "26.6827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "459",
    WardLookupId: "2931",
    VDNumber: "10330085",
    RegisteredPopulation: "95",
    VotingStationName: "FRASERS CAMP SHOP",
    Address: "FRASERS CAMP  GRAHAMSTOWN  GRAHAMSTOWN",
    Latitude: "-33.2822",
    Longitude: "26.9023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "460",
    WardLookupId: "2931",
    VDNumber: "10330096",
    RegisteredPopulation: "160",
    VotingStationName: "COMMITTEES DRIFT POLICE STATION (TENT)",
    Address: "IN FRONT OF POLICE STATION  COMMITTEES DRIFT  COMMITTEESDRIFT",
    Latitude: "-33.1602",
    Longitude: "26.8364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "461",
    WardLookupId: "2931",
    VDNumber: "10330108",
    RegisteredPopulation: "87",
    VotingStationName: "COLLINGHAM (PINEWOOD SCHOOL)",
    Address: "EC104 - MAKANA [GRAHAMSTOWN]  ",
    Latitude: "-33.2739",
    Longitude: "26.6465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "462",
    WardLookupId: "2931",
    VDNumber: "10350065",
    RegisteredPopulation: "115",
    VotingStationName: "GARDENERS GATE TENT",
    Address: "GARDENE`S GATE FARM  GARDENERS GATE  GRAHAMSTOWN",
    Latitude: "-33.5181",
    Longitude: "26.5321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "463",
    WardLookupId: "2931",
    VDNumber: "10350087",
    RegisteredPopulation: "61",
    VotingStationName: "FARMER FIELD SCHOOL",
    Address: "FARMMERFIELD  GRAHAMSTOWN",
    Latitude: "-33.492",
    Longitude: "26.547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "464",
    WardLookupId: "2932",
    VDNumber: "10040016",
    RegisteredPopulation: "1964",
    VotingStationName: "ALICEDALE TOWN HALL",
    Address: "MAIN STREET  GRAHAMSTOWN  ALICEDALE",
    Latitude: "-33.3167",
    Longitude: "26.0814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "465",
    WardLookupId: "2932",
    VDNumber: "10330029",
    RegisteredPopulation: "182",
    VotingStationName: "SIDBURY HALL",
    Address: "SIDBURY HALL  SIDBURY  GRAHAMSTOWN",
    Latitude: "-33.4216",
    Longitude: "26.1675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "466",
    WardLookupId: "2932",
    VDNumber: "10330030",
    RegisteredPopulation: "98",
    VotingStationName: "HIGHLANDS TENT",
    Address: "HIGHLANDS FARM  HIGHLANDS  GRAHAMSTOWN",
    Latitude: "-33.3378",
    Longitude: "26.3166",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "467",
    WardLookupId: "2932",
    VDNumber: "10330041",
    RegisteredPopulation: "655",
    VotingStationName: "SEVEN FOUNTAINS SPORTS CLUB",
    Address: "SEVEN FOUNTAINS  GRAHAMSTOWN",
    Latitude: "-33.4342",
    Longitude: "26.3264",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "468",
    WardLookupId: "552",
    VDNumber: "10030015",
    RegisteredPopulation: "1633",
    VotingStationName: "UKHANYO SCHOOL",
    Address: "558 TAKUTA STREET  KWANONQUBELA  ALEXANDRIA",
    Latitude: "-33.6619",
    Longitude: "26.4287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "469",
    WardLookupId: "552",
    VDNumber: "10030037",
    RegisteredPopulation: "1498",
    VotingStationName: "KWANONQUBELA COMMUNITY HALL",
    Address: "WINNIE MADIKIZELA ST  KWANONQUBELA  ALEXANDRIA",
    Latitude: "-33.6568",
    Longitude: "26.4194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "470",
    WardLookupId: "552",
    VDNumber: "10370012",
    RegisteredPopulation: "728",
    VotingStationName: "DANIEL SCHEEPERS COMMUNITY HALL",
    Address: "MAIN STREET  BOKNES  BOKNES",
    Latitude: "-33.7296",
    Longitude: "26.5797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "471",
    WardLookupId: "553",
    VDNumber: "10030026",
    RegisteredPopulation: "1806",
    VotingStationName: "WENTZEL PARK COMMUNITY HALL",
    Address: "SENDING  ALEXANDRIA  PORT ALFRED",
    Latitude: "-33.6515",
    Longitude: "26.4145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "472",
    WardLookupId: "553",
    VDNumber: "10030048",
    RegisteredPopulation: "697",
    VotingStationName: "BALINDI CRECHE",
    Address: "LUTHULI  KWANONQUBELA  ALEXANDRIA",
    Latitude: "-33.655",
    Longitude: "26.4168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "473",
    WardLookupId: "553",
    VDNumber: "10340053",
    RegisteredPopulation: "175",
    VotingStationName: "GEELHOUTBOOM PRIMARY SCHOOL",
    Address: "GEELHOUTBOOM FARM  ALEXANDRIA DISTRICT (TRC)",
    Latitude: "-33.5896",
    Longitude: "26.3998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "474",
    WardLookupId: "554",
    VDNumber: "10060018",
    RegisteredPopulation: "1295",
    VotingStationName: "MARSELLE HALL",
    Address: "103 MJUZA STREET  MARSELLE  BOESMANSRIVIERMOND",
    Latitude: "-33.6801",
    Longitude: "26.6353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "475",
    WardLookupId: "554",
    VDNumber: "10060029",
    RegisteredPopulation: "414",
    VotingStationName: "BOESMANSRIVIER MOND MUNICIPAL HALL",
    Address: "LOERIE STREET  BOESMANSRIVIERMOND  PORT ALFRED",
    Latitude: "-33.688",
    Longitude: "26.6581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "476",
    WardLookupId: "554",
    VDNumber: "10060030",
    RegisteredPopulation: "1523",
    VotingStationName: "MASIBULELE CRECHE",
    Address: "958 MAKENKE  MARSELLE  BOESMANSRIVIERMOND",
    Latitude: "-33.6822",
    Longitude: "26.6279",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "477",
    WardLookupId: "554",
    VDNumber: "10340020",
    RegisteredPopulation: "533",
    VotingStationName: "KLIPFONTEIN HALL",
    Address: "KLIPFONTEIN  BOESMANSRIVIERMOND",
    Latitude: "-33.6904",
    Longitude: "26.6337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "478",
    WardLookupId: "555",
    VDNumber: "10170010",
    RegisteredPopulation: "1201",
    VotingStationName: "KENTON-ON-SEA MUNICIPALITY",
    Address: "KENTON ROAD  KENTON-ON-SEA  KENTON-ON-SEA",
    Latitude: "-33.6838",
    Longitude: "26.6731",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "479",
    WardLookupId: "555",
    VDNumber: "10170021",
    RegisteredPopulation: "2206",
    VotingStationName: "EKUPHUMLENI COMMUNITY HALL",
    Address: "2943 ZABA SQUARE  EKUPHUMLENI  KENTON-ON-SEA",
    Latitude: "-33.6703",
    Longitude: "26.6633",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "480",
    WardLookupId: "555",
    VDNumber: "10350010",
    RegisteredPopulation: "81",
    VotingStationName: "BELTON HIKING TRAIL HOUSE",
    Address: "BELCHREST FARM  BELCHREST FARM AREA  BATHURST DISTRICT (TRC)",
    Latitude: "-33.5627",
    Longitude: "26.5587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "481",
    WardLookupId: "556",
    VDNumber: "10050017",
    RegisteredPopulation: "2097",
    VotingStationName: "NOLUKHANYO COMMUNITY HALL",
    Address: "ERF 1216  NOLUKHANYO  BATHURST",
    Latitude: "-33.4935",
    Longitude: "26.8319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "482",
    WardLookupId: "556",
    VDNumber: "10050028",
    RegisteredPopulation: "1340",
    VotingStationName: "FREESTONE SPORTS CLUB HALL",
    Address: "2044 NOLUKHANYO TOWNSHIP  NOLUKHANYO  BATHURST",
    Latitude: "-33.484",
    Longitude: "26.8363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "483",
    WardLookupId: "557",
    VDNumber: "10350021",
    RegisteredPopulation: "362",
    VotingStationName: "SOUTHWELL COMMUNITY HALL",
    Address: "SOUTHWELL ROAD  SOUTHWELL  BATHURST",
    Latitude: "-33.5341",
    Longitude: "26.6865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "484",
    WardLookupId: "557",
    VDNumber: "10350032",
    RegisteredPopulation: "372",
    VotingStationName: "WILSON PARTY SCHOOL",
    Address: "KLIPKOP MARTINDALE FARM  MARTINDALE FARM  BATHURST DISTRICT",
    Latitude: "-33.4025",
    Longitude: "26.7452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "485",
    WardLookupId: "557",
    VDNumber: "10350043",
    RegisteredPopulation: "462",
    VotingStationName: "SHAW PARK SCHOOL",
    Address: "SHAW PARK  BATHURST DISTRICT",
    Latitude: "-33.4554",
    Longitude: "26.9466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "486",
    WardLookupId: "557",
    VDNumber: "10350054",
    RegisteredPopulation: "89",
    VotingStationName: "COOMBS HALL",
    Address: "COOMBS  BATHURST DISTRICT",
    Latitude: "-33.3064",
    Longitude: "26.8288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "487",
    WardLookupId: "557",
    VDNumber: "10350076",
    RegisteredPopulation: "152",
    VotingStationName: "TRAPPES VALLEY HALL",
    Address: "TRAPPES VALLEY ROAD  BATHURST DISTRICT  BATHURST",
    Latitude: "-33.4441",
    Longitude: "26.8882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "488",
    WardLookupId: "557",
    VDNumber: "10350098",
    RegisteredPopulation: "797",
    VotingStationName: "TITI JONAS MULTI-PURPOSE CENTRE",
    Address: "THORN HILL  PORT ALFRED",
    Latitude: "-33.5654",
    Longitude: "26.8969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "489",
    WardLookupId: "557",
    VDNumber: "10470013",
    RegisteredPopulation: "477",
    VotingStationName: "SEAFIELD COMMUNITY HALL",
    Address: "NATURES WAY  SEAFIELD/KLEINEMONDE  SEAFIELD",
    Latitude: "-33.5354",
    Longitude: "27.0477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "490",
    WardLookupId: "558",
    VDNumber: "10240052",
    RegisteredPopulation: "3013",
    VotingStationName: "KUYASA SCHOOL",
    Address: "JOE SLOVO STREET  NEMATO  PORT ALFRED",
    Latitude: "-33.5651",
    Longitude: "26.8928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "491",
    WardLookupId: "559",
    VDNumber: "10240029",
    RegisteredPopulation: "1283",
    VotingStationName: "NOMZAMO SCHOOL",
    Address: "2908 JOE SLOVO STREET  NEMATO  PORT ALFRED",
    Latitude: "-33.5736",
    Longitude: "26.891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "492",
    WardLookupId: "559",
    VDNumber: "10240041",
    RegisteredPopulation: "1411",
    VotingStationName: "DAMBUZA SCHOOL",
    Address: "1516 MBUNDWINI STREET  NEMATO  PORT ALFRED",
    Latitude: "-33.5814",
    Longitude: "26.8751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "493",
    WardLookupId: "560",
    VDNumber: "10240030",
    RegisteredPopulation: "1894",
    VotingStationName: "JAUKA HALL",
    Address: "1176 RUNELI DRIVE  NEMATO  PORT ALFRED",
    Latitude: "-33.5782",
    Longitude: "26.8934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "494",
    WardLookupId: "560",
    VDNumber: "10240063",
    RegisteredPopulation: "1771",
    VotingStationName: "STATION HILL COMMUNITY HALL",
    Address: "BOOYSENS  STATION HILL  PORT ALFRED",
    Latitude: "-33.5819",
    Longitude: "26.8957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "495",
    WardLookupId: "560",
    VDNumber: "10240074",
    RegisteredPopulation: "1186",
    VotingStationName: "UNITED ETHIOPIAN CHURCH",
    Address: "MSWELA  NEMATO  PORT ALFRED",
    Latitude: "-33.5816",
    Longitude: "26.8852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "496",
    WardLookupId: "561",
    VDNumber: "10240018",
    RegisteredPopulation: "3856",
    VotingStationName: "MEMORIAL HALL",
    Address: "CIVIC CENTRE, 1 CAUSEWAY  PORT ALFRED  PORT ALFRED",
    Latitude: "-33.5933",
    Longitude: "26.8873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "497",
    WardLookupId: "562",
    VDNumber: "10180011",
    RegisteredPopulation: "844",
    VotingStationName: "METHODIST CHURCH",
    Address: "613 BEN KIRE STREET  MOSES MABIDA TOWNSHIP  KIRKWOOD",
    Latitude: "-33.3962",
    Longitude: "25.4782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "498",
    WardLookupId: "562",
    VDNumber: "10180022",
    RegisteredPopulation: "1487",
    VotingStationName: "MOSES MABIDA SENIOR SECONDARY SCHOOL",
    Address: "MOSES MABIDA TOWNSHIP  KIRKWOOD",
    Latitude: "-33.3996",
    Longitude: "25.4838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "499",
    WardLookupId: "562",
    VDNumber: "10180044",
    RegisteredPopulation: "1152",
    VotingStationName: "UNITING REFORMED CHURCH",
    Address: "RAMAPOSA STREET  MOSES MABIDA  KIRKWOOD",
    Latitude: "-33.3931",
    Longitude: "25.4839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "500",
    WardLookupId: "563",
    VDNumber: "10180033",
    RegisteredPopulation: "1650",
    VotingStationName: "SAINT COLMCILLE PUBLIC SCHOOL",
    Address: "KANAAL STREET  KIRKWOOD  KIRKWOOD",
    Latitude: "-33.3939",
    Longitude: "25.4465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "501",
    WardLookupId: "563",
    VDNumber: "10180055",
    RegisteredPopulation: "1261",
    VotingStationName: "MASIPHATHISANE DAY CARE SERVICES",
    Address: "MSENGENI AREA  MOSES MABIDA TOWNSHIP  KIRKWOOD",
    Latitude: "-33.3985",
    Longitude: "25.4913",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "502",
    WardLookupId: "563",
    VDNumber: "10180066",
    RegisteredPopulation: "779",
    VotingStationName: "BERGSIG COMMUNITY HALL",
    Address: "AREND STREET   BERGSIG LOCATION   KIRKWOOD",
    Latitude: "-33.3877",
    Longitude: "25.4507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "503",
    WardLookupId: "563",
    VDNumber: "10430187",
    RegisteredPopulation: "127",
    VotingStationName: "NOMZAMO FARM",
    Address:
      "JAGTVLKTE RD  JAGHVLAKTE VILLAGE  SUNDAYS RIVER VALLEY [KIRKWOOD]",
    Latitude: "-33.4195",
    Longitude: "25.4829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "504",
    WardLookupId: "564",
    VDNumber: "10340031",
    RegisteredPopulation: "454",
    VotingStationName: "NANAGA FARM STALL TENT",
    Address:
      "CONNER OF MAIN ROAD N2 & R72  NANAGA AREA  ALEXANDRIA DISTRICT (TRC)",
    Latitude: "-33.6072",
    Longitude: "25.9236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "505",
    WardLookupId: "564",
    VDNumber: "10340042",
    RegisteredPopulation: "129",
    VotingStationName: "ZAMUKUKHANYA COMMUNITY HALL",
    Address: "WOODY CAPE NATURE RESERVE  LANGBOS  ALEXANDRIA DISTRICT (TRC)",
    Latitude: "-33.7097",
    Longitude: "26.367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "506",
    WardLookupId: "564",
    VDNumber: "10430121",
    RegisteredPopulation: "1545",
    VotingStationName: "VALENCIA COMMUNITY HALL",
    Address: "ALOES STREET  VALENCIA LOCATION  ADDO",
    Latitude: "-33.5556",
    Longitude: "25.6941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "507",
    WardLookupId: "564",
    VDNumber: "10430198",
    RegisteredPopulation: "1779",
    VotingStationName: "PLACE OF MERCY AND HOPE",
    Address: "EMAKHALENI AREA   VALENCIA AREA  ADDO",
    Latitude: "-33.5557",
    Longitude: "25.698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "508",
    WardLookupId: "565",
    VDNumber: "10220016",
    RegisteredPopulation: "2080",
    VotingStationName: "SINAKHO HALL",
    Address: "ALEXANDRIA ROAD  KWA - ZENZELE LOCATION  PATERSON",
    Latitude: "-33.4461",
    Longitude: "25.9726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "509",
    WardLookupId: "565",
    VDNumber: "10340064",
    RegisteredPopulation: "466",
    VotingStationName: "ZUNEY COMBINED PRIMARY SCHOOL",
    Address: "MORRIS HILL FARM R72  ZUNEY AREA   ALEXANDRIA DISTRICT",
    Latitude: "-33.6295",
    Longitude: "26.2153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "510",
    WardLookupId: "565",
    VDNumber: "10340075",
    RegisteredPopulation: "175",
    VotingStationName: "NICOSHOOP TENT",
    Address: "NICOSHOOP TENT  PATERSON  ALEXANDRIA DISTRICT (TRC)",
    Latitude: "-33.4681",
    Longitude: "26.0313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "511",
    WardLookupId: "566",
    VDNumber: "10430019",
    RegisteredPopulation: "1234",
    VotingStationName: "SAMKELWE SENIOR SECONDARY SCHOOL",
    Address: "MAIN STR  NOMATHAMSANQA  ADDO",
    Latitude: "-33.5159",
    Longitude: "25.706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "512",
    WardLookupId: "566",
    VDNumber: "10430064",
    RegisteredPopulation: "887",
    VotingStationName: "MASIZAKHE COMMUNITY HALL",
    Address: "MAIN STREET  NOMATHAMSANQA  ADDO",
    Latitude: "-33.5222",
    Longitude: "25.7111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "513",
    WardLookupId: "566",
    VDNumber: "10430200",
    RegisteredPopulation: "1322",
    VotingStationName: "UNITING REFORMED CHURCH ADDO",
    Address: "LUTHANDO AREA  NOMATHAMSANQA LOCATION  ADDO",
    Latitude: "-33.522",
    Longitude: "25.7125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "514",
    WardLookupId: "567",
    VDNumber: "10430075",
    RegisteredPopulation: "318",
    VotingStationName: "ST. IGNATIUS PRIMARY SCHOOL",
    Address: "MAIN STREET  HERMITAGE  ADDO",
    Latitude: "-33.5279",
    Longitude: "25.6797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "515",
    WardLookupId: "567",
    VDNumber: "10430086",
    RegisteredPopulation: "669",
    VotingStationName: "SUNDAYS RIVER PRIMARY SCHOOL",
    Address: "MAIN STREET  SUNLANDS  ADDO",
    Latitude: "-33.5032",
    Longitude: "25.6257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "516",
    WardLookupId: "567",
    VDNumber: "10430132",
    RegisteredPopulation: "1532",
    VotingStationName: "AV BUKANI PRIMARY SCHOOL",
    Address: "BUKANI STR  NOMATHAMSANQA  ADDO",
    Latitude: "-33.5268",
    Longitude: "25.7139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "517",
    WardLookupId: "567",
    VDNumber: "10430211",
    RegisteredPopulation: "192",
    VotingStationName: "LANGBOS CRECHE & CARE CENTRE",
    Address: "LANGBOS  ADDO   KIRKWOOD",
    Latitude: "-33.4964",
    Longitude: "25.6915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "518",
    WardLookupId: "568",
    VDNumber: "10430042",
    RegisteredPopulation: "319",
    VotingStationName: "KIRKWOOD PRISON",
    Address: "KIRKWOOD CORRECTIONAL SERVICES  KIRKWOOD  KIRKWOOD",
    Latitude: "-33.4",
    Longitude: "25.3207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "519",
    WardLookupId: "568",
    VDNumber: "10430053",
    RegisteredPopulation: "562",
    VotingStationName: "MALMAISON PRIMARY SCHOOL",
    Address: "MALMAISON  KIRKWOOD  KIRKWOOD",
    Latitude: "-33.4043",
    Longitude: "25.3672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "520",
    WardLookupId: "568",
    VDNumber: "10430097",
    RegisteredPopulation: "443",
    VotingStationName: "ST REGINALDS PRIMARY SCHOOL",
    Address: "MAIN ROAD  WESBANK  KIRKWOOD",
    Latitude: "-33.4235",
    Longitude: "25.3919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "521",
    WardLookupId: "568",
    VDNumber: "10430176",
    RegisteredPopulation: "370",
    VotingStationName: "SIVUYISENI CRECH",
    Address: "ROAD R336 KIRKWOOD TO ADDO  SHOWHILL  KIRKWOOD",
    Latitude: "-33.4382",
    Longitude: "25.4662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "522",
    WardLookupId: "568",
    VDNumber: "10430222",
    RegisteredPopulation: "473",
    VotingStationName: "RIETBERG PRIMARY SCHOOL",
    Address: "SONOP STREET  KIRKWOOD  KIRKWOOD",
    Latitude: "-33.4034",
    Longitude: "25.4452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "523",
    WardLookupId: "568",
    VDNumber: "10510029",
    RegisteredPopulation: "150",
    VotingStationName: "PALMIETRIVIER PRIMARY SCHOOL",
    Address: "COMCKSCOMB GRAVEL ROAD  WINTERHOEK  UITENHAGE",
    Latitude: "-33.55",
    Longitude: "25.2447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "524",
    WardLookupId: "568",
    VDNumber: "10510063",
    RegisteredPopulation: "241",
    VotingStationName: "KLEINPOORT HALL",
    Address: "MAIN STREET  KLEINPOORT,R75  ABERDEEN PLAIN",
    Latitude: "-33.3296",
    Longitude: "24.8827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "525",
    WardLookupId: "568",
    VDNumber: "10510074",
    RegisteredPopulation: "144",
    VotingStationName: "SANDRIVIER GARAGE",
    Address: "SAND RIVER  ELANDS RIVER,UITENHAGE  KIRKWOOD",
    Latitude: "-33.7283",
    Longitude: "25.0983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "526",
    WardLookupId: "568",
    VDNumber: "10510085",
    RegisteredPopulation: "189",
    VotingStationName: "GLENCONNER PRIMARY SCHOOL",
    Address: "R75  GLEN CONNER  KIRKWOOD",
    Latitude: "-33.3928",
    Longitude: "25.1568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "527",
    WardLookupId: "569",
    VDNumber: "10220027",
    RegisteredPopulation: "639",
    VotingStationName: "MORESON PRIMARY SCHOOL",
    Address: "KERK STRAAT  MORESON TOWNSHIP  PATERSON",
    Latitude: "-33.4391",
    Longitude: "25.9742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "528",
    WardLookupId: "569",
    VDNumber: "10340086",
    RegisteredPopulation: "128",
    VotingStationName: "ADDO ELEPHANT PARK MULTI-PURPOSE HALL",
    Address: "R335 ADDO MAIN ROAD  ELEPHANT PARK  ADDO",
    Latitude: "-33.4403",
    Longitude: "25.749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "529",
    WardLookupId: "569",
    VDNumber: "10430020",
    RegisteredPopulation: "90",
    VotingStationName: "BLUE CLIFF FARM STALL",
    Address: "R75  BLUECLIFF AREA   KIRKWOOD",
    Latitude: "-33.5528",
    Longitude: "25.4245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "530",
    WardLookupId: "569",
    VDNumber: "10430031",
    RegisteredPopulation: "711",
    VotingStationName: "MHLOPHEKAZI SCHOOL",
    Address: "BERSHEBA  ENON  KIRKWOOD",
    Latitude: "-33.4175",
    Longitude: "25.5504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "531",
    WardLookupId: "569",
    VDNumber: "10430109",
    RegisteredPopulation: "308",
    VotingStationName: "SRCC HALL",
    Address: "MAIN ROAD  KIRKWOOD  SUMMERVILLE",
    Latitude: "-33.4908",
    Longitude: "25.5923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "532",
    WardLookupId: "569",
    VDNumber: "10430110",
    RegisteredPopulation: "450",
    VotingStationName: "DUNBRODY PRIMARY SCHOOL",
    Address: "DUNBRODY  DUNBRODY AREA   KIRKWOOD",
    Latitude: "-33.4692",
    Longitude: "25.5418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "533",
    WardLookupId: "569",
    VDNumber: "10430154",
    RegisteredPopulation: "606",
    VotingStationName: "SIEMBAMBA CRECHE",
    Address: "31 AGTER STRAAT  ENON BERSHEBAH  KIRKWOOD",
    Latitude: "-33.3952",
    Longitude: "25.5446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "534",
    WardLookupId: "2933",
    VDNumber: "10280023",
    RegisteredPopulation: "1852",
    VotingStationName: "SEA VISTA COMMUNITY HALL A",
    Address: "STEENBRAS  SEA VISTA  SEA VISTA",
    Latitude: "-34.1741",
    Longitude: "24.8182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "535",
    WardLookupId: "2933",
    VDNumber: "10400049",
    RegisteredPopulation: "494",
    VotingStationName: "OYSTERBAY HALL",
    Address: "1 OYSTER AVENUE  OYSTERBAY BAY  HUMANSDORP",
    Latitude: "-34.167",
    Longitude: "24.6605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "536",
    WardLookupId: "2933",
    VDNumber: "10400094",
    RegisteredPopulation: "220",
    VotingStationName: "LEEUBOS FARM",
    Address: "FARM LEEUBOS  LEEUBOS  HUMANSDORP",
    Latitude: "-34.0215",
    Longitude: "24.6353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "537",
    WardLookupId: "2933",
    VDNumber: "10440010",
    RegisteredPopulation: "419",
    VotingStationName: "KOKKIEWIET CRECHE",
    Address: "JACOBS  MZAMOWETHU  OYSTER BAY",
    Latitude: "-34.1697",
    Longitude: "24.6536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "538",
    WardLookupId: "2934",
    VDNumber: "10140040",
    RegisteredPopulation: "1829",
    VotingStationName: "PELLSRUS COMMUNITY HALL",
    Address: "1 HARDER STREET  PELLSRUS  JEFFREYS BAY",
    Latitude: "-34.0587",
    Longitude: "24.9226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "539",
    WardLookupId: "2934",
    VDNumber: "10140051",
    RegisteredPopulation: "2110",
    VotingStationName: "TOKYO SEXWALE CRECHE",
    Address: "SOLOMON MAHLANGU  JEFFFREYS BAY  KOUGA [HUMANSDORP]",
    Latitude: "-34.0589",
    Longitude: "24.9148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "540",
    WardLookupId: "2935",
    VDNumber: "10140017",
    RegisteredPopulation: "2454",
    VotingStationName: "KABELJOUS BEACH PARK TENT (TECOMA STREET).",
    Address: "ERF NO.1259 TECOMA STREET  KABELJOUS  JEFFREYS BAY",
    Latitude: "-34.0236",
    Longitude: "24.9278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "541",
    WardLookupId: "2936",
    VDNumber: "10120037",
    RegisteredPopulation: "3600",
    VotingStationName: "KRUISFONTEIN PRIMARY SCHOOL",
    Address: "FELIX STR  KRUISFONTEIN PROPER  HUMANSDORP",
    Latitude: "-34.0071",
    Longitude: "24.7457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "542",
    WardLookupId: "2937",
    VDNumber: "10120015",
    RegisteredPopulation: "2468",
    VotingStationName: "KRUISFONTEIN CIVIC CENTRE",
    Address: "KRUISFONTEIN  HUMANSDORP",
    Latitude: "-34.0208",
    Longitude: "24.746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "543",
    WardLookupId: "2937",
    VDNumber: "10120060",
    RegisteredPopulation: "1318",
    VotingStationName: "VAALDAM COMMUNITY CRECHE",
    Address: "FANIE RADEMEYERS,VAALDAM  KRUISFONTEIN  HUMANSDORP",
    Latitude: "-34.0176",
    Longitude: "24.7376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "544",
    WardLookupId: "2938",
    VDNumber: "10120059",
    RegisteredPopulation: "3171",
    VotingStationName: "LUNGISO PUBLIC SCHOOL",
    Address: "MANDELA STR  KWANOMZAMO  HUMANSDORP",
    Latitude: "-34.0402",
    Longitude: "24.7792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "545",
    WardLookupId: "2938",
    VDNumber: "10120071",
    RegisteredPopulation: "732",
    VotingStationName: "MZINGISI PRIMARY SCHOOL",
    Address: "349 NANTO   KWANOMZAMO  HUMANSDORP",
    Latitude: "-34.0356",
    Longitude: "24.7761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "546",
    WardLookupId: "2939",
    VDNumber: "10390058",
    RegisteredPopulation: "1594",
    VotingStationName: "LOERIE HEUWEL COMMUNITY HALL",
    Address: "JUDY STREET  LOERIE  HANKEY",
    Latitude: "-33.876",
    Longitude: "25.019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "547",
    WardLookupId: "2939",
    VDNumber: "10390069",
    RegisteredPopulation: "1671",
    VotingStationName: "KATRINA FELIX COMMUNITY HALL",
    Address: "THORNHILL  ",
    Latitude: "-33.895",
    Longitude: "25.14",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "548",
    WardLookupId: "2939",
    VDNumber: "10390070",
    RegisteredPopulation: "210",
    VotingStationName: "ROOIDRAAI CRECHE",
    Address: "ROOIDRAAI  ",
    Latitude: "-33.8769",
    Longitude: "24.8978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "549",
    WardLookupId: "2939",
    VDNumber: "10390092",
    RegisteredPopulation: "114",
    VotingStationName: "HIGHLANDS FARM",
    Address: "GAMTOOS  KOUGA [HUMANSDORP]",
    Latitude: "-33.9003",
    Longitude: "24.962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "550",
    WardLookupId: "2939",
    VDNumber: "10390104",
    RegisteredPopulation: "252",
    VotingStationName: "LONGMORE COMMUNITY HALL",
    Address: "THORNHILL  KOUGA [HUMANSDORP]",
    Latitude: "-33.8635",
    Longitude: "25.1124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "551",
    WardLookupId: "2939",
    VDNumber: "10400140",
    RegisteredPopulation: "259",
    VotingStationName: "MONDPLAAS PRIMARY SCHOOL",
    Address: "MONDPLAAS  JEFFREYS BAY",
    Latitude: "-33.9406",
    Longitude: "24.9867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "552",
    WardLookupId: "2940",
    VDNumber: "10140062",
    RegisteredPopulation: "5671",
    VotingStationName: "VICTORY CHURCH",
    Address: "CNR BLOMBOS AND SEETUIN  WAVECREST  JEFFREYS BAY",
    Latitude: "-34.0262",
    Longitude: "24.9087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "553",
    WardLookupId: "2941",
    VDNumber: "10110014",
    RegisteredPopulation: "2024",
    VotingStationName: "VUZUMZI LANDU HALL",
    Address: "SCHOOL STREET  CENTERTON  HANKEY",
    Latitude: "-33.8273",
    Longitude: "24.8676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "554",
    WardLookupId: "2941",
    VDNumber: "10110036",
    RegisteredPopulation: "1604",
    VotingStationName: "HANKEY CIVIC CENTRE",
    Address: "MAIN STREET  HANKEY  HANKEY",
    Latitude: "-33.8345",
    Longitude: "24.8822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "555",
    WardLookupId: "2941",
    VDNumber: "10390047",
    RegisteredPopulation: "173",
    VotingStationName: "M AND N KLEINFONTEIN BOERDERY",
    Address: "KLEINFONTEIN  HANKEY",
    Latitude: "-33.8004",
    Longitude: "24.9407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "556",
    WardLookupId: "2942",
    VDNumber: "10210026",
    RegisteredPopulation: "2381",
    VotingStationName: "DAN SANDI COMMUNITY HALL",
    Address: "TIGER STREET  CYRIL RAMAPHOSA TOWNSHIP  PATENSIE",
    Latitude: "-33.7428",
    Longitude: "24.7972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "557",
    WardLookupId: "2942",
    VDNumber: "10390014",
    RegisteredPopulation: "212",
    VotingStationName: "COCKSCOMBE PRIMARY SCHOOL",
    Address: "COCKSCOMBE  CAMBRIA  HANKEY",
    Latitude: "-33.6633",
    Longitude: "24.5367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "558",
    WardLookupId: "2942",
    VDNumber: "10390025",
    RegisteredPopulation: "677",
    VotingStationName: "ANDRIESKRAAL HALL",
    Address: "ANDRIESKRAAL  PATENSIE",
    Latitude: "-33.7476",
    Longitude: "24.6435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "559",
    WardLookupId: "2942",
    VDNumber: "10390036",
    RegisteredPopulation: "691",
    VotingStationName: "QUAGGA PRIMARY SCHOOL",
    Address: "QUAGGA ROAD  PATENSIE  PATENSIE",
    Latitude: "-33.7267",
    Longitude: "24.7825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "560",
    WardLookupId: "2943",
    VDNumber: "10140028",
    RegisteredPopulation: "2753",
    VotingStationName: "NEWTON HALL",
    Address: "17 GOEDEHOOP STREET  JEFFREYS BAY  JEFFREYS BAY",
    Latitude: "-34.052",
    Longitude: "24.9189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "561",
    WardLookupId: "2944",
    VDNumber: "10140084",
    RegisteredPopulation: "734",
    VotingStationName: "PARADISE BEACH CARAVAN PARK",
    Address: "864 EMERGROEN AVENUE  PARADISE  JEFFREY`S BAY",
    Latitude: "-34.0886",
    Longitude: "24.8962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "562",
    WardLookupId: "2944",
    VDNumber: "10280012",
    RegisteredPopulation: "2522",
    VotingStationName: "ST FRANCIS BAY VILLAGE HALL",
    Address: "2 ASSISSI DRIVE  ST FRANCIS BAY  ST FRANCIS BAY",
    Latitude: "-34.1649",
    Longitude: "24.8267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "563",
    WardLookupId: "2944",
    VDNumber: "10400050",
    RegisteredPopulation: "619",
    VotingStationName: "VANGELIST APOSTILIC CHURCH",
    Address: "GULF  KWANOMZAMO  HUMANSDORP",
    Latitude: "-34.0413",
    Longitude: "24.7716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "564",
    WardLookupId: "2945",
    VDNumber: "10110025",
    RegisteredPopulation: "1163",
    VotingStationName: "WESTON COMMUNITY HALL",
    Address: "CHRIS HANI RD  WESTON  HANKEY",
    Latitude: "-33.8482",
    Longitude: "24.8656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "565",
    WardLookupId: "2945",
    VDNumber: "10110058",
    RegisteredPopulation: "2116",
    VotingStationName: "ETHEMBENI- VUYO KATOO COMMUNITY CRECHE",
    Address: "20 TSHUME STREET  CENTERTON EXTENTION 4  HANKEY",
    Latitude: "-33.825",
    Longitude: "24.874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "566",
    WardLookupId: "2945",
    VDNumber: "10210015",
    RegisteredPopulation: "1053",
    VotingStationName: "LAERSKOOL GAMTOOSVALLEI",
    Address: "P FEREIRA STREET  GAMTOOS VALLEI  PATENSIE",
    Latitude: "-33.7608",
    Longitude: "24.8112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "567",
    WardLookupId: "2945",
    VDNumber: "10390081",
    RegisteredPopulation: "228",
    VotingStationName: "EX ENATHI FARM",
    Address: "ROOIVLAKTE  MILTON  HANKEY",
    Latitude: "-33.8035",
    Longitude: "24.8568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "568",
    WardLookupId: "2946",
    VDNumber: "10140039",
    RegisteredPopulation: "1582",
    VotingStationName: "ASTON BAY HALL",
    Address: "MAIN BEACH-ASTON BAY  ASTON BAY  JEFFREYS BAY",
    Latitude: "-34.0853",
    Longitude: "24.9117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "569",
    WardLookupId: "2946",
    VDNumber: "10140073",
    RegisteredPopulation: "1004",
    VotingStationName: "MADIBA BAY CRECHE",
    Address: "1244 SARAH BAARTMAN STREET  TOKYO SEXWALE  JEFFREY`S BAY",
    Latitude: "-34.0632",
    Longitude: "24.9145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "570",
    WardLookupId: "2946",
    VDNumber: "10400151",
    RegisteredPopulation: "3277",
    VotingStationName: "JEFFREYS BAY TAXI RANK HALL",
    Address: "DOLPHIN RD  OCEAN VIEW  JEFFREYS BAY",
    Latitude: "-34.0599",
    Longitude: "24.9121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "571",
    WardLookupId: "2947",
    VDNumber: "10120026",
    RegisteredPopulation: "2211",
    VotingStationName: "HUMANSDORP COUNCIL CHAMBER KOUGA",
    Address: "22 DU PLESSIS  HUMANSDORP TOWN  HUMANSDORP",
    Latitude: "-34.0281",
    Longitude: "24.7712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "572",
    WardLookupId: "2947",
    VDNumber: "10120048",
    RegisteredPopulation: "1879",
    VotingStationName: "ST PATRICKS PRIMARY SCHOOL",
    Address: "KOERAT STREET  GRASLAAGTE  HUMANSDORP",
    Latitude: "-34.0253",
    Longitude: "24.7556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "573",
    WardLookupId: "2948",
    VDNumber: "10420018",
    RegisteredPopulation: "1800",
    VotingStationName: "V G KERKSAAL",
    Address: "STASIE KOP STREET  MISGUND  MISGUND",
    Latitude: "-33.7557",
    Longitude: "23.4983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "574",
    WardLookupId: "2948",
    VDNumber: "10420030",
    RegisteredPopulation: "364",
    VotingStationName: "PROEFPLAAS HALL",
    Address: "R62 ROAD  PROEFPLAAS  LOUTERWATER",
    Latitude: "-33.7745",
    Longitude: "23.5862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "575",
    WardLookupId: "2948",
    VDNumber: "10420041",
    RegisteredPopulation: "2514",
    VotingStationName: "LOUTERWATER COMMUNITY HALL",
    Address: "CHURCH STREET  LOUTERWATER  LOUTERWATER",
    Latitude: "-33.79",
    Longitude: "23.6314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "576",
    WardLookupId: "2949",
    VDNumber: "10400016",
    RegisteredPopulation: "1021",
    VotingStationName: "COLDSTREAM COMMUNITY HALL",
    Address: "MAIN STREET  COLD STREAM DISTRICT  COLDSTREAM",
    Latitude: "-33.962",
    Longitude: "23.701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "577",
    WardLookupId: "2949",
    VDNumber: "10420029",
    RegisteredPopulation: "1388",
    VotingStationName: "KRAKEELRIVIER COMMUNITY HALL",
    Address: "MAIN STREET  KRAKEELRIVIER  KRAKEELRIVIER",
    Latitude: "-33.8141",
    Longitude: "23.7276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "578",
    WardLookupId: "2949",
    VDNumber: "10420063",
    RegisteredPopulation: "747",
    VotingStationName: "LANGKLOOF UNITED CONGREGATIONAL CHURCH",
    Address: "10 WILLIAM STEWART  JOUBERTINA  JOUBERTINA",
    Latitude: "-33.8278",
    Longitude: "23.8493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "579",
    WardLookupId: "2949",
    VDNumber: "10420085",
    RegisteredPopulation: "81",
    VotingStationName: "FARM BRANDHOEK SCHOOL",
    Address: "FARM  FARM BRANDHOEK  JOUBERTINA",
    Latitude: "-33.759",
    Longitude: "23.9107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "580",
    WardLookupId: "2949",
    VDNumber: "10420096",
    RegisteredPopulation: "225",
    VotingStationName: "OUDRIF LANDGOED PTY LTD",
    Address: "0 MAIN ROAD  JOUBERTINA  LOUTERWATER",
    Latitude: "-33.8035",
    Longitude: "23.6665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "581",
    WardLookupId: "2950",
    VDNumber: "10150018",
    RegisteredPopulation: "2640",
    VotingStationName: "RAVINIA COMMUNITY HALL",
    Address: "MAIN STREET  RAVINIA  JOUBERTINA",
    Latitude: "-33.8293",
    Longitude: "23.878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "582",
    WardLookupId: "2950",
    VDNumber: "10420052",
    RegisteredPopulation: "325",
    VotingStationName: "NGK TWEERIVIER HALL",
    Address: "01 MAIN STREET  TWEERIVIER  TWEERIVIER",
    Latitude: "-33.8396",
    Longitude: "23.8909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "583",
    WardLookupId: "2950",
    VDNumber: "10420074",
    RegisteredPopulation: "92",
    VotingStationName: "TEA ENTERPRISES",
    Address: "MAIN ROAD  JOUBERTINA  HEIGHTS",
    Latitude: "-33.86",
    Longitude: "23.9784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "584",
    WardLookupId: "2951",
    VDNumber: "10160019",
    RegisteredPopulation: "2732",
    VotingStationName: "KAGISO HEIGHTS HALL",
    Address: "MAIN ROAD  KAGISO HEIGHTS  KAREEDOUW",
    Latitude: "-33.9518",
    Longitude: "24.3016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "585",
    WardLookupId: "2951",
    VDNumber: "10400106",
    RegisteredPopulation: "121",
    VotingStationName: "JAGERSBOS STOOR",
    Address: "JAGERSBOS FARM  JAGERSBOS/KAREEDOUW",
    Latitude: "-33.9096",
    Longitude: "24.1634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "586",
    WardLookupId: "2951",
    VDNumber: "10400117",
    RegisteredPopulation: "237",
    VotingStationName: "ESSENBOS FARM",
    Address: "FARM ESSENBOS  ESSENBOS  ESSENBOSCH/KAREEDOUW",
    Latitude: "-33.9586",
    Longitude: "24.413",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "587",
    WardLookupId: "2951",
    VDNumber: "10400162",
    RegisteredPopulation: "50",
    VotingStationName: "CHURCH HILL DAM",
    Address: "MAIN ROAD  CHURCHILL DAM  KAREEDOUW",
    Latitude: "-34.0064",
    Longitude: "24.5171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "588",
    WardLookupId: "2952",
    VDNumber: "10400038",
    RegisteredPopulation: "515",
    VotingStationName: "GUSTAV REICHEL SCHOOL",
    Address: "EERSTE RIVIER  KAREEDOUW",
    Latitude: "-34.0455",
    Longitude: "24.2459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "589",
    WardLookupId: "2952",
    VDNumber: "10400072",
    RegisteredPopulation: "262",
    VotingStationName: "DIE STAL HALL",
    Address: "MAIN STREET  SAFCOL  WITELSBOS",
    Latitude: "-33.993",
    Longitude: "24.117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "590",
    WardLookupId: "2952",
    VDNumber: "10400083",
    RegisteredPopulation: "1558",
    VotingStationName: "CLARKSON COMMUNITY HALL",
    Address: "MAIN STREET  CLARKSON  CLARKSON",
    Latitude: "-34.0156",
    Longitude: "24.3543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "591",
    WardLookupId: "2952",
    VDNumber: "10400139",
    RegisteredPopulation: "1223",
    VotingStationName: "WOODLANDS COMMUNITY HALL",
    Address: "MAIN ROAD  WOODLANDS  WOODLANDS",
    Latitude: "-34.0034",
    Longitude: "24.1647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "592",
    WardLookupId: "2953",
    VDNumber: "10400027",
    RegisteredPopulation: "1835",
    VotingStationName: "SANDDRIF COMMUNITY HALL",
    Address: "MAIN STREET  NOMPUMELELO VILLAGE  SANDDRIFT",
    Latitude: "-33.9836",
    Longitude: "23.97",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "593",
    WardLookupId: "2953",
    VDNumber: "10400061",
    RegisteredPopulation: "1172",
    VotingStationName: "STORMS RIVER COMMUNITY HALL",
    Address: "MAIN STREET  STORMS RIVER  STORMSRIVER",
    Latitude: "-33.9752",
    Longitude: "23.8924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "594",
    WardLookupId: "2953",
    VDNumber: "10400128",
    RegisteredPopulation: "67",
    VotingStationName: "TSITSIKAMMA NATIONAL PARK",
    Address: "P.O. STORMSRIVER  TSITSIKAMMA NATIONAL PARK  HUMANSDORP DISTRICT",
    Latitude: "-34.0214",
    Longitude: "23.8974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "595",
    WardLookupId: "2954",
    VDNumber: "10620010",
    RegisteredPopulation: "3075",
    VotingStationName: "IDUTYWA TOWNHALL VC",
    Address: "RICHARDSON STREET DUTYWA  DUTYWA  DUTYWA",
    Latitude: "-32.0989",
    Longitude: "28.3046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "596",
    WardLookupId: "2954",
    VDNumber: "10620021",
    RegisteredPopulation: "920",
    VotingStationName: "TRC HALL",
    Address: "MAIN STREET   IDUTYWA TOWN  IDUTYWA",
    Latitude: "-32.0988",
    Longitude: "28.3076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "597",
    WardLookupId: "2955",
    VDNumber: "10850048",
    RegisteredPopulation: "294",
    VotingStationName: "MTETO SENIOR PRIMARY SCHOOL",
    Address: "SHESHEGU VILLAGE  NGXAKAXA A/A  IDUTYWA",
    Latitude: "-32.1329",
    Longitude: "28.2744",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "598",
    WardLookupId: "2955",
    VDNumber: "10850420",
    RegisteredPopulation: "680",
    VotingStationName: "TS MATSILIZA JUNIOR SECONDARY SCHOOL",
    Address: "GOOD-HOPE VILLAGE  NGXAKAXA A/A  IDUTYWA",
    Latitude: "-32.1342",
    Longitude: "28.3093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "599",
    WardLookupId: "2955",
    VDNumber: "10850431",
    RegisteredPopulation: "711",
    VotingStationName: "MAMFENENI JUNIOR SECONDARY SCHOOL",
    Address: "ELUSIZINI VILLAGE  NGXAKAXA A/A  IDUTYWA",
    Latitude: "-32.1677",
    Longitude: "28.279",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "600",
    WardLookupId: "2955",
    VDNumber: "10850497",
    RegisteredPopulation: "401",
    VotingStationName: "UPPER QORA JUNIOR SECONDARY SCHOOL",
    Address: "QORA A/A  IDUTYWA",
    Latitude: "-32.1988",
    Longitude: "28.2659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "601",
    WardLookupId: "2955",
    VDNumber: "10850778",
    RegisteredPopulation: "426",
    VotingStationName: "NGONYAMA JUNIOR SECONDARY SCHOOL",
    Address: "NGONYAMA VILLAGE  MPUTI A/A  IDUTYWA",
    Latitude: "-32.1087",
    Longitude: "28.256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "602",
    WardLookupId: "2955",
    VDNumber: "10850789",
    RegisteredPopulation: "648",
    VotingStationName: "FLOWER JUNIOR SECONDARY SCHOOL",
    Address: "SHESHEGU VILLAGE  NGXAKAXA A/A  IDUTYWA",
    Latitude: "-32.1256",
    Longitude: "28.2703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "603",
    WardLookupId: "2955",
    VDNumber: "10850790",
    RegisteredPopulation: "391",
    VotingStationName: "BONKOLO SECONDARY SECONDARY SCHOOL",
    Address: "PHESHEYA KWEDIPHU VILLAGE  NGXAKAXA A/A  IDUTYWA",
    Latitude: "-32.1438",
    Longitude: "28.2843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "604",
    WardLookupId: "2955",
    VDNumber: "10850802",
    RegisteredPopulation: "570",
    VotingStationName: "MCITWA SENIOR PRIMARY SCHOOL",
    Address: "MCITWA VILLAGE  NGCINGWANE A/A  IDUTYWA",
    Latitude: "-32.1465",
    Longitude: "28.32",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "605",
    WardLookupId: "2955",
    VDNumber: "10851241",
    RegisteredPopulation: "200",
    VotingStationName: "GQUKESI METHODIST CHURCH",
    Address: "GQUKESI VILLAGE   QORHA A/A  DUTYWA",
    Latitude: "-32.2034",
    Longitude: "28.2553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "606",
    WardLookupId: "2956",
    VDNumber: "10840081",
    RegisteredPopulation: "529",
    VotingStationName: "NQADU JUNIOR SECONDARY SCHOOL",
    Address: "MKHWEZENI VILLAGE  NQADU A/A  WILLOWVALE",
    Latitude: "-32.2169",
    Longitude: "28.3857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "607",
    WardLookupId: "2956",
    VDNumber: "10850037",
    RegisteredPopulation: "442",
    VotingStationName: "LENCANE JUNIOR SECONDARY SCHOOL",
    Address: "LENCANE VILLAGE  NGCINGWANA A/A  IDUTYWA",
    Latitude: "-32.1449",
    Longitude: "28.3418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "608",
    WardLookupId: "2956",
    VDNumber: "10850442",
    RegisteredPopulation: "402",
    VotingStationName: "MORRISON JUNIOR SECONDARY SCHOOL",
    Address: "MORRISON LOCATION  NGCINGWANE A/A  IDUTYWA",
    Latitude: "-32.1587",
    Longitude: "28.3641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "609",
    WardLookupId: "2956",
    VDNumber: "10850453",
    RegisteredPopulation: "389",
    VotingStationName: "GWADANA JUNIOR SECONDARY SCHOOL",
    Address: "GWADANA NGAPHANTSI VILLAGE  GWADANA A/A  DUTYWA",
    Latitude: "-32.2156",
    Longitude: "28.2926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "610",
    WardLookupId: "2956",
    VDNumber: "10850475",
    RegisteredPopulation: "375",
    VotingStationName: "MAHLIWANE JUNIOR SECONDARY SCHOOL",
    Address: "MAHLIWANE VILLAGE  GWADANA A/A  IDUTYWA",
    Latitude: "-32.2301",
    Longitude: "28.2917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "611",
    WardLookupId: "2956",
    VDNumber: "10850509",
    RegisteredPopulation: "418",
    VotingStationName: "GWADANA TRIBAL AUTHORITY",
    Address: "EZIHAGWINI VILLAGE  GWADANA A/A  IDUTYWA",
    Latitude: "-32.2148",
    Longitude: "28.2896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "612",
    WardLookupId: "2956",
    VDNumber: "10850824",
    RegisteredPopulation: "611",
    VotingStationName: "MATOLWENI JUNIOR SECONDARY SCHOOL",
    Address: "MATOLWENI VILLAGE  NGCINGWANE A/A   IDUTYWA",
    Latitude: "-32.137",
    Longitude: "28.344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "613",
    WardLookupId: "2956",
    VDNumber: "10850846",
    RegisteredPopulation: "120",
    VotingStationName: "QELANA COMMUNITY HALL",
    Address: "QELANA VILLAGE  NGCINGWANA A/A  IDUTYWA",
    Latitude: "-32.1673",
    Longitude: "28.3331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "614",
    WardLookupId: "2956",
    VDNumber: "10850857",
    RegisteredPopulation: "168",
    VotingStationName: "MPEPENI CHURCH HALL",
    Address: "MPEPENI VILLAGE   NGCINGWANE A/A  IDUTYWA",
    Latitude: "-32.1727",
    Longitude: "28.3427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "615",
    WardLookupId: "2956",
    VDNumber: "10851072",
    RegisteredPopulation: "500",
    VotingStationName: "TYOLOMI JUNIOR SECONDARY SCHOOL",
    Address: "TYOLOMI VILLAGE   GWADANA AA  IDUTYWA",
    Latitude: "-32.1917",
    Longitude: "28.3427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "616",
    WardLookupId: "2956",
    VDNumber: "10851151",
    RegisteredPopulation: "291",
    VotingStationName: "MAVATHA J.S.S",
    Address: "QORA A/A  BUTTERWORTH",
    Latitude: "-32.2098",
    Longitude: "28.2801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "617",
    WardLookupId: "2957",
    VDNumber: "10850026",
    RegisteredPopulation: "833",
    VotingStationName: "MPUTI JUNIOR SECONDARY SCHOOL",
    Address: "MPUTI VILLAGE  MPUTI A/A  IDUTYWA",
    Latitude: "-32.0896",
    Longitude: "28.2688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "618",
    WardLookupId: "2957",
    VDNumber: "10850262",
    RegisteredPopulation: "536",
    VotingStationName: "ZAMUXOLO JUNIOR SECONDARY SCHOOL",
    Address: "MBANDE VILLAGE  COLOSA A/A  IDUTYWA",
    Latitude: "-32.0332",
    Longitude: "28.3331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "619",
    WardLookupId: "2957",
    VDNumber: "10850273",
    RegisteredPopulation: "481",
    VotingStationName: "MAXHAMA JUNIOR SECONDARY SCHOOL",
    Address: "MAXHAMA VILLAGE  ESIQUMENI A/A  IDUTYWA",
    Latitude: "-31.9717",
    Longitude: "28.2681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "620",
    WardLookupId: "2957",
    VDNumber: "10850329",
    RegisteredPopulation: "467",
    VotingStationName: "GXARA JUNIOR SECONDARY SCHOOL",
    Address: "GXARA KOMKHULU VILLAGE  GXARA A/A  IDUTYWA",
    Latitude: "-31.9992",
    Longitude: "28.2882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "621",
    WardLookupId: "2957",
    VDNumber: "10850363",
    RegisteredPopulation: "377",
    VotingStationName: "TIMANE JUNIOR SECONDARY SCHOOL",
    Address: "KWATAYI VILLAGE  COLOSA A/A  IDUTYWA",
    Latitude: "-32.0284",
    Longitude: "28.2686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "622",
    WardLookupId: "2957",
    VDNumber: "10850419",
    RegisteredPopulation: "498",
    VotingStationName: "MTOMBOTI JUNIOR SECONDARY SCHOOL",
    Address: "MAGQOSININI VILLAGE  MPUTI A/A  IDUTYWA",
    Latitude: "-32.0655",
    Longitude: "28.2627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "623",
    WardLookupId: "2957",
    VDNumber: "10850600",
    RegisteredPopulation: "415",
    VotingStationName: "NTSHINGENI JUNIOR SECONDARY SCHOOL",
    Address: "NTSHINGENI VILLAGE  ESINQUMENI A/A  IDUTYWA",
    Latitude: "-32.0061",
    Longitude: "28.2365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "624",
    WardLookupId: "2957",
    VDNumber: "10850712",
    RegisteredPopulation: "327",
    VotingStationName: "ZWELAKHE JUNIOR SECONDARY SCHOOL",
    Address: "EZIFAMA VILLAGE  TIMANEAA  IDUTYWA",
    Latitude: "-32.0262",
    Longitude: "28.3411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "625",
    WardLookupId: "2957",
    VDNumber: "10851128",
    RegisteredPopulation: "223",
    VotingStationName: "UPPER COLOSA JUNIOR SECONDARY SCHOOL",
    Address: "EMANGQOSININI VILLAGE  COLOSA A/A  IDUTYWA",
    Latitude: "-32.0578",
    Longitude: "28.2802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "626",
    WardLookupId: "2957",
    VDNumber: "10851229",
    RegisteredPopulation: "233",
    VotingStationName: "PAKAMILE SENIOR PRIMARY SCHOOL",
    Address: "ERWANTSINI VILLAGE  GXARA A/A/  DUTYWA",
    Latitude: "-31.9713",
    Longitude: "28.2978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "627",
    WardLookupId: "2957",
    VDNumber: "10851230",
    RegisteredPopulation: "360",
    VotingStationName: "JOJWENI JUNIOR SECONDARY SCHOOL",
    Address: "JOJWENI VILLAGE  ESINQUMENI A/A  DUTYWA",
    Latitude: "-32.0309",
    Longitude: "28.2424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "628",
    WardLookupId: "2958",
    VDNumber: "10850015",
    RegisteredPopulation: "612",
    VotingStationName: "BOLOTWA JUNIOR SECONDARY SCHOOL",
    Address: "BOLOTWA VILLAGE  BOLOTWA A/A  IDUTYWA",
    Latitude: "-31.9169",
    Longitude: "28.2921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "629",
    WardLookupId: "2958",
    VDNumber: "10850161",
    RegisteredPopulation: "384",
    VotingStationName: "VININDWA JUNIOR SECONDARY SCHOOL",
    Address: "MAXHAMA VILLAGE  SINQUMENI A/A  IDUTYWA",
    Latitude: "-31.9423",
    Longitude: "28.2235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "630",
    WardLookupId: "2958",
    VDNumber: "10850172",
    RegisteredPopulation: "583",
    VotingStationName: "UPPER BOLOTWA JUNIOR SECONDARY SCHOOL",
    Address: "UPPER BOLOTWA VILLAGE  BOLOTWA A/A  IDUTYWA",
    Latitude: "-31.9011",
    Longitude: "28.2335",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "631",
    WardLookupId: "2958",
    VDNumber: "10850183",
    RegisteredPopulation: "446",
    VotingStationName: "JADEZWENI JUNIOR SECONDARY SCHOOL",
    Address: "JADEZWENI VILLAGE  BOLOTWA A/A  IDUTYWA",
    Latitude: "-31.8591",
    Longitude: "28.2769",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "632",
    WardLookupId: "2958",
    VDNumber: "10850510",
    RegisteredPopulation: "502",
    VotingStationName: "MAZIZINI JUNIOR SECONDARY SCHOOL",
    Address: "MAZIZINI VILLAGE  BOLOTWA A/A  IDUTYWA",
    Latitude: "-31.9325",
    Longitude: "28.2715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "633",
    WardLookupId: "2958",
    VDNumber: "10850622",
    RegisteredPopulation: "203",
    VotingStationName: "NYAKATA JUNIOR SECONDARY SCHOOL",
    Address: "NYAKATHA LOCATION  SINQUMENI A/A   IDUTYWA",
    Latitude: "-31.9767",
    Longitude: "28.2288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "634",
    WardLookupId: "2958",
    VDNumber: "10851184",
    RegisteredPopulation: "220",
    VotingStationName: "SINGENI JUNIOR SECONDARY SCHOOL",
    Address: "LOTHA VILLAGE  BOLOTWA A/A  DUTYWA",
    Latitude: "-31.826",
    Longitude: "28.2895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "635",
    WardLookupId: "2958",
    VDNumber: "10851195",
    RegisteredPopulation: "401",
    VotingStationName: "ZANOHLANGA SENIOR PRIMARY SCHOOL",
    Address: "UPPER BOLOTWA  BOLOTWA A/A  IDUTYWA",
    Latitude: "-31.8905",
    Longitude: "28.2585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "636",
    WardLookupId: "2958",
    VDNumber: "11570778",
    RegisteredPopulation: "529",
    VotingStationName: "MBANGA JUNIOR SECONDARY SCHOOL",
    Address: "MBANGA KOMKHULU VILLAGE  MBANGA A/A  IDUTYWA",
    Latitude: "-31.8196",
    Longitude: "28.2579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "637",
    WardLookupId: "2959",
    VDNumber: "10850093",
    RegisteredPopulation: "290",
    VotingStationName: "MOZWENI JUNIOR SECONDARY SCHOOL",
    Address: "MAKHOBOKENI VILLAGE  LOTA A/A  IDUTYWA",
    Latitude: "-31.8573",
    Longitude: "28.3171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "638",
    WardLookupId: "2959",
    VDNumber: "10850194",
    RegisteredPopulation: "438",
    VotingStationName: "LOTA JUNIOR SECONDARY SCHOOL",
    Address: "NYANDENI VILLAGE  LOTA A/A   IDUTYWA",
    Latitude: "-31.8675",
    Longitude: "28.339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "639",
    WardLookupId: "2959",
    VDNumber: "10850206",
    RegisteredPopulation: "462",
    VotingStationName: "NQABANE JUNIOR SECONDARY SCHOOL",
    Address: "NQABANE VILLAGE  NQABANE A/A  IDUTYWA",
    Latitude: "-31.8944",
    Longitude: "28.356",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "640",
    WardLookupId: "2959",
    VDNumber: "10850396",
    RegisteredPopulation: "349",
    VotingStationName: "MHUKU SENIOR PRIMARY SCHOOL",
    Address: "MHUKU VILLAGE  NQABANE A/A  IDUTYWA",
    Latitude: "-31.9131",
    Longitude: "28.3387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "641",
    WardLookupId: "2959",
    VDNumber: "10850565",
    RegisteredPopulation: "346",
    VotingStationName: "NJEMANE JUNIOR SECONDARY SCHOOL",
    Address: "NJEMANE VILLAGE  NQABANE A/A  IDUTYWA",
    Latitude: "-31.8684",
    Longitude: "28.3689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "642",
    WardLookupId: "2959",
    VDNumber: "10850598",
    RegisteredPopulation: "584",
    VotingStationName: "NIMROD JUNIOR SECONDARY SCHOOL",
    Address: "NGWEMNYAMA VILLAGE  NYWARA A/A  IDUTYWA",
    Latitude: "-31.9084",
    Longitude: "28.4018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "643",
    WardLookupId: "2959",
    VDNumber: "10850633",
    RegisteredPopulation: "126",
    VotingStationName: "ILITHALETHU JUNIOR SECONDARY SCHOOL",
    Address: "ESIHLABENI VILLAGE  BOLOTWA A/A  IDUTYWA",
    Latitude: "-31.9142",
    Longitude: "28.3156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "644",
    WardLookupId: "2959",
    VDNumber: "10850699",
    RegisteredPopulation: "329",
    VotingStationName: "NGUBESIZWE COMMUNITY HALL",
    Address: "MANTSHILIBENI VILLAGE  NQABANE A/A  IDUTYWA",
    Latitude: "-31.9228",
    Longitude: "28.3711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "645",
    WardLookupId: "2959",
    VDNumber: "10851094",
    RegisteredPopulation: "246",
    VotingStationName: "NOMBULELO JUNIOR SECONDARY SCHOOL",
    Address: "MABHELENI VILLAGE  NQABANE A/A  IDUTYWA",
    Latitude: "-31.9144",
    Longitude: "28.3749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "646",
    WardLookupId: "2959",
    VDNumber: "10851106",
    RegisteredPopulation: "502",
    VotingStationName: "DAYIMANE JUNIOR SECONDARY SCHOOL",
    Address: "DAYIMANE VILLAGE  LOTA A/A  IDUTYWA",
    Latitude: "-31.8825",
    Longitude: "28.3182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "647",
    WardLookupId: "2959",
    VDNumber: "10851140",
    RegisteredPopulation: "282",
    VotingStationName: "NOMPUMELELO SENIOR PRIMARY SCHOOL",
    Address: "SILITYIWA VILLAGE  BOLOTWA A/A  IDUTYWA",
    Latitude: "-31.8816",
    Longitude: "28.2995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "648",
    WardLookupId: "2959",
    VDNumber: "11650519",
    RegisteredPopulation: "280",
    VotingStationName: "BANGWENI JUNIOR SECONDARY SCHOOL",
    Address: "BANGWENI VILLAGE  BOLOTWA AA  IDUTYWA",
    Latitude: "-31.9436",
    Longitude: "28.2963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "649",
    WardLookupId: "2960",
    VDNumber: "10850228",
    RegisteredPopulation: "409",
    VotingStationName: "LUDONDOLO JUNIOR SECONDARY SCHOOL",
    Address: "LUDONDOLO VILLAGE  LUDONDOLO A/A  IDUTYWA",
    Latitude: "-31.9435",
    Longitude: "28.4655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "650",
    WardLookupId: "2960",
    VDNumber: "10850251",
    RegisteredPopulation: "488",
    VotingStationName: "CANDU JUNIOR SECONDARY SCHOOL",
    Address: "MATOLWENI VILLAGE  CANDU A/A  IDUTYWA",
    Latitude: "-31.9492",
    Longitude: "28.4202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "651",
    WardLookupId: "2960",
    VDNumber: "10850284",
    RegisteredPopulation: "402",
    VotingStationName: "MBELO JUNIOR SECONDARY SCHOOL",
    Address: "MBELO VILLAGE  NYWARA A/A  IDUTYWA",
    Latitude: "-31.9805",
    Longitude: "28.3702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "652",
    WardLookupId: "2960",
    VDNumber: "10850341",
    RegisteredPopulation: "307",
    VotingStationName: "NDABANKULU  JUNIOR SECONDARY SCHOOL",
    Address: "MJELO VILLAGE  NYWARA A/A  DUTYWA",
    Latitude: "-31.9841",
    Longitude: "28.3374",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "653",
    WardLookupId: "2960",
    VDNumber: "10850374",
    RegisteredPopulation: "328",
    VotingStationName: "MHALA JUNIOR SECONDARY SCHOOL",
    Address: "MHALA VILLAGE  OLD IDUTYWA A/A  IDUTYWA",
    Latitude: "-32.0145",
    Longitude: "28.3765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "654",
    WardLookupId: "2960",
    VDNumber: "10850521",
    RegisteredPopulation: "358",
    VotingStationName: "MTSHOTSHISA CHURCH HALL",
    Address: "MISSION LOCATION  NYWARA A/A.  DUTYWA",
    Latitude: "-31.9413",
    Longitude: "28.3683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "655",
    WardLookupId: "2960",
    VDNumber: "10850554",
    RegisteredPopulation: "304",
    VotingStationName: "MUNYU JUNIOR SECONDARY SCHOOL",
    Address: "MUNYU KOMKHULU  VILLAGE  MUNYU A/A  IDUTYWA",
    Latitude: "-32.0073",
    Longitude: "28.3329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "656",
    WardLookupId: "2960",
    VDNumber: "10850644",
    RegisteredPopulation: "258",
    VotingStationName: "DUTYWA BAPTIST CHURCH",
    Address: "MPOZOLO VILLAGE  NYWARA A/A  DUTYWA",
    Latitude: "-31.9625",
    Longitude: "28.3473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "657",
    WardLookupId: "2960",
    VDNumber: "10850655",
    RegisteredPopulation: "189",
    VotingStationName: "SIJADU SENIOR PRIMARY SCHOOL",
    Address: "LUXHOMO VILLAGE  NYHWARA AA  IDUTYWA",
    Latitude: "-31.9559",
    Longitude: "28.3792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "658",
    WardLookupId: "2960",
    VDNumber: "10850666",
    RegisteredPopulation: "171",
    VotingStationName: "MABHELENI SENIOR PRIMARY SCHOOL",
    Address: "MABHELENI VILLAGE  NHYWARA A/A  IDUTYWA",
    Latitude: "-31.9765",
    Longitude: "28.4101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "659",
    WardLookupId: "2960",
    VDNumber: "10850688",
    RegisteredPopulation: "166",
    VotingStationName: "NDESI JUNIOR SECONDARY SCHOOL",
    Address: "NDESI VILLAGE  OLD IDUTYWA A/A  IDUTYWA",
    Latitude: "-31.9643",
    Longitude: "28.456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "660",
    WardLookupId: "2960",
    VDNumber: "10850701",
    RegisteredPopulation: "210",
    VotingStationName: "TAMSANQA SENIOR PRIMARY SCHOOL",
    Address: "LUDOMDOLO VILLAGE  LUDONDOLO AA  IDUTYWA",
    Latitude: "-31.9443",
    Longitude: "28.4923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "661",
    WardLookupId: "2960",
    VDNumber: "10850745",
    RegisteredPopulation: "337",
    VotingStationName: "ZETHENJINI JUNIOR SECONDARY SCHOOL",
    Address: "ZITHENJINI  VILLAGE  OLD IDUTYWA A/A  IDUTYWA",
    Latitude: "-32.0275",
    Longitude: "28.3668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "662",
    WardLookupId: "2960",
    VDNumber: "10851173",
    RegisteredPopulation: "311",
    VotingStationName: "UPPER NYWARHA SENIOR PRIMARY SCHOOL",
    Address: "UPPER NYWARHA VILLAGE  NYWARHA A/A  IDUTYWA",
    Latitude: "-31.9531",
    Longitude: "28.3288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "663",
    WardLookupId: "2960",
    VDNumber: "10851252",
    RegisteredPopulation: "201",
    VotingStationName: "XHOLO SHOP",
    Address: "XHOLO VILLAGE   CANDU A/A  DUTYWA",
    Latitude: "-31.9833",
    Longitude: "28.4243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "664",
    WardLookupId: "2961",
    VDNumber: "10850149",
    RegisteredPopulation: "517",
    VotingStationName: "GEM JUNIOR SECONDARY SCHOOL",
    Address: "CHACHAZELE VILLAGE  CHIZELE AA  IDUTYWA",
    Latitude: "-32.0497",
    Longitude: "28.477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "665",
    WardLookupId: "2961",
    VDNumber: "10850217",
    RegisteredPopulation: "387",
    VotingStationName: "OLD DALE JUNIOR SECONDARY SCHOOL",
    Address: "SINGENI VILLAGE  CHIZELE A/A  IDUTYWA",
    Latitude: "-32.0168",
    Longitude: "28.4787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "666",
    WardLookupId: "2961",
    VDNumber: "10850239",
    RegisteredPopulation: "406",
    VotingStationName: "TSHWATI JUNIOR SECONDARY SCHOOL",
    Address: "TSHWATI VILLAGE  OLD IDUTYWA A/A  IDUTYWA",
    Latitude: "-32.0301",
    Longitude: "28.4535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "667",
    WardLookupId: "2961",
    VDNumber: "10850408",
    RegisteredPopulation: "488",
    VotingStationName: "THOMPSON JUNIOR SECONDARY SCHOOL",
    Address: "SUNDWANA VILLAGE  CHIZELA A/A  IDUTYWA",
    Latitude: "-32.015",
    Longitude: "28.5348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "668",
    WardLookupId: "2961",
    VDNumber: "10850532",
    RegisteredPopulation: "198",
    VotingStationName: "MSIKITI SENIOR PRIMARY SCHOOL",
    Address: "MSIKITI VILLAGE  SUNDWANA A/A  IDUTYWA",
    Latitude: "-32.0048",
    Longitude: "28.5907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "669",
    WardLookupId: "2961",
    VDNumber: "10850543",
    RegisteredPopulation: "358",
    VotingStationName: "NAKI JUNIOR SECONDARY SCHOOL",
    Address: "MNGCITHA VILLAGE  CHIZELE A/A  IDUTYWA",
    Latitude: "-32.0259",
    Longitude: "28.5042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "670",
    WardLookupId: "2961",
    VDNumber: "10850969",
    RegisteredPopulation: "387",
    VotingStationName: "XOBO JUNIOR SECONDARY SCHOOL",
    Address: "XOBO VILLAGE  CHIZELA A/A  IDUTYWA",
    Latitude: "-32.0626",
    Longitude: "28.5086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "671",
    WardLookupId: "2961",
    VDNumber: "10850970",
    RegisteredPopulation: "210",
    VotingStationName: "MDUNYISWA SENIOR PRIMARY SCHOOL",
    Address: "MBONDA VILLAGE  XOBO AA  IDUTYWA",
    Latitude: "-32.05",
    Longitude: "28.5457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "672",
    WardLookupId: "2961",
    VDNumber: "10850981",
    RegisteredPopulation: "496",
    VotingStationName: "COLLYWOBLES JUNIOR SECONDARY SCHOOL",
    Address: "MANGCE VILLAGE  SUNDWANA A/A  IDUTYWA",
    Latitude: "-32.0117",
    Longitude: "28.5633",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "673",
    WardLookupId: "2961",
    VDNumber: "10851139",
    RegisteredPopulation: "292",
    VotingStationName: "LAPHUMILANGA JUNIOR SECONDARY SCHOOL",
    Address: "TSHOTHI VILLAGE  CHIZELE A/A  IDUTYWA",
    Latitude: "-31.9965",
    Longitude: "28.5113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "674",
    WardLookupId: "2961",
    VDNumber: "10851218",
    RegisteredPopulation: "97",
    VotingStationName: "SIYIBANE SENIOR PRIMARY SCHOOL",
    Address: "SIBANE VILLAGE  SUNDWANE A/A  DUTYWA",
    Latitude: "-32.0269",
    Longitude: "28.6254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "675",
    WardLookupId: "2961",
    VDNumber: "10851263",
    RegisteredPopulation: "236",
    VotingStationName: "VULITHUBA SENIOR SECONDARY SCHOOL",
    Address: "GXOGXA VILLAGE   CHIZELE A/A  DUTYWA",
    Latitude: "-32.0312",
    Longitude: "28.5177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "676",
    WardLookupId: "2962",
    VDNumber: "10620032",
    RegisteredPopulation: "1243",
    VotingStationName: "GOVAN MBEKI TOWNSHIP HALL",
    Address: "GOVAN MBEKI TOWNSHIP  IDUTYWA",
    Latitude: "-32.0933",
    Longitude: "28.3151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "677",
    WardLookupId: "2962",
    VDNumber: "10620043",
    RegisteredPopulation: "766",
    VotingStationName: "MBHASHE SENIOR PRIMARY SCHOOL",
    Address: "DOTI LOCATION  MANGATHI A/A  IDUTYWA",
    Latitude: "-32.0875",
    Longitude: "28.3288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "678",
    WardLookupId: "2962",
    VDNumber: "10850071",
    RegisteredPopulation: "562",
    VotingStationName: "SIZINI JUNIOR SECONDARY SCHOOL",
    Address: "SIZINI VILLAGE  COLOSA A/A  IDUTYWA",
    Latitude: "-32.0705",
    Longitude: "28.3531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "679",
    WardLookupId: "2962",
    VDNumber: "10850307",
    RegisteredPopulation: "392",
    VotingStationName: "BONGWENI JUNIOR SECONDARY SCHOOL",
    Address: "BOMGWENI VILLAGE  MANGATHI A/A  IDUTYWA",
    Latitude: "-32.1036",
    Longitude: "28.351",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "680",
    WardLookupId: "2962",
    VDNumber: "10850385",
    RegisteredPopulation: "658",
    VotingStationName: "VULINDLELA SENIOR PRIMARY SCHOOL",
    Address: "KOMKHULU VILLAGE  COLOSA A/A  IDUTYWA",
    Latitude: "-32.0671",
    Longitude: "28.3133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "681",
    WardLookupId: "2962",
    VDNumber: "10850813",
    RegisteredPopulation: "228",
    VotingStationName: "A.B SIWENDU JUNIOR SECONDARY SCHOOL",
    Address: "AUCKLAND VILLAGE  MANGATHI A/A   IDUTYWA",
    Latitude: "-32.1109",
    Longitude: "28.3425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "682",
    WardLookupId: "2962",
    VDNumber: "10850879",
    RegisteredPopulation: "367",
    VotingStationName: "COLOSA JUNIOR SECONDARY SCHOOL",
    Address: "MISSION VILLAGE  COLOSA A/A  IDUTYWA",
    Latitude: "-32.059",
    Longitude: "28.3311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "683",
    WardLookupId: "2962",
    VDNumber: "10850903",
    RegisteredPopulation: "628",
    VotingStationName: "NONKQUBELA SENIOR PRIMARY SCHOOL",
    Address: "GUBEVU VILLAGE  MANGATHI A/A  IDUTYWA",
    Latitude: "-32.0857",
    Longitude: "28.374",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "684",
    WardLookupId: "2962",
    VDNumber: "10851162",
    RegisteredPopulation: "166",
    VotingStationName: "SIWENDU CAFÃ‰",
    Address: "AUCKLAND VILLAGE  MANGATHI A/A  DUTYWA",
    Latitude: "-32.1168",
    Longitude: "28.3512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "685",
    WardLookupId: "2963",
    VDNumber: "10840171",
    RegisteredPopulation: "450",
    VotingStationName: "QAKAZANA JUNIOR SECONDARY SCHOOL",
    Address: "QAKAZANA VILLAGE  QAKAZANA A/A  WILLOWVALE",
    Latitude: "-32.1813",
    Longitude: "28.4751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "686",
    WardLookupId: "2963",
    VDNumber: "10850059",
    RegisteredPopulation: "650",
    VotingStationName: "TALENI   JUNIOR SECONDARY SCHOOL",
    Address: "MTHUVI LOCATION  TALENI A/A  IDUTYWA",
    Latitude: "-32.1454",
    Longitude: "28.4147",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "687",
    WardLookupId: "2963",
    VDNumber: "10850060",
    RegisteredPopulation: "351",
    VotingStationName: "DUFF JUNIOR SECONDARY SCHOOL",
    Address: "BOMELA VILLAGE  MANGATI A/A  IDUTYWA",
    Latitude: "-32.0929",
    Longitude: "28.4029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "688",
    WardLookupId: "2963",
    VDNumber: "10850486",
    RegisteredPopulation: "500",
    VotingStationName: "MNGEKA JUNIOR SECONDARY SCHOOL",
    Address: "BEDE LOCATION  TALENI A/A  IDUTYWA",
    Latitude: "-32.1376",
    Longitude: "28.4401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "689",
    WardLookupId: "2963",
    VDNumber: "10850576",
    RegisteredPopulation: "398",
    VotingStationName: "UMZIMKULU JUNIOR SECONDARY SCHOOL",
    Address: "BOMVANA VILLAGE  TALENI  A/A  IDUTYWA",
    Latitude: "-32.1644",
    Longitude: "28.3872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "690",
    WardLookupId: "2963",
    VDNumber: "10850925",
    RegisteredPopulation: "329",
    VotingStationName: "NOHOLLAND SENIOR PRIMARY SCHOOL",
    Address: "RWANTSINI VILLAGE  NQABARA A/A  IDUTYWA",
    Latitude: "-32.1172",
    Longitude: "28.4194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "691",
    WardLookupId: "2963",
    VDNumber: "10851038",
    RegisteredPopulation: "395",
    VotingStationName: "BANGISO JUNIOR SECONDARY SCHOOL",
    Address: "WILLO ZEMBE LOCATION   NGCINGWANA A/A  IDUTYWA",
    Latitude: "-32.144",
    Longitude: "28.3873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "692",
    WardLookupId: "2963",
    VDNumber: "10851050",
    RegisteredPopulation: "479",
    VotingStationName: "NOTINARHA JUNIOR SECONDARY SCHOOL",
    Address: "ETAFENI VIILAGE  NQABARA A/A  DUTYWA",
    Latitude: "-32.1245",
    Longitude: "28.4397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "693",
    WardLookupId: "2964",
    VDNumber: "10840069",
    RegisteredPopulation: "696",
    VotingStationName: "LOWER DADAMBA JUNIOR SECONDARY SCHOOL",
    Address: "LOWER DADAMBA LOC  DADAMBA AA  WILLOWVALE",
    Latitude: "-32.2792",
    Longitude: "28.3667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "694",
    WardLookupId: "2964",
    VDNumber: "10840070",
    RegisteredPopulation: "571",
    VotingStationName: "UPPER DADAMBA JUNIOR SECONDARY SCHOOL",
    Address: "DADAMBA LOC  DADAMBA AA    WILLOWVALE",
    Latitude: "-32.2366",
    Longitude: "28.3366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "695",
    WardLookupId: "2964",
    VDNumber: "10840092",
    RegisteredPopulation: "640",
    VotingStationName: "EBOTWE TRIBAL AUTHORITY",
    Address: "KOMKHULU VILLAGE  NQADU A/A  WILLOWVALE",
    Latitude: "-32.2393",
    Longitude: "28.4212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "696",
    WardLookupId: "2964",
    VDNumber: "10840148",
    RegisteredPopulation: "314",
    VotingStationName: "NCEDANA JUNIOR SECONDARY SCHOOL",
    Address: "NTSIZWANA VILLAGE  NCEDANA A/A  WILLOWVALE",
    Latitude: "-32.2976",
    Longitude: "28.4514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "697",
    WardLookupId: "2964",
    VDNumber: "10840216",
    RegisteredPopulation: "357",
    VotingStationName: "NGQAQINI JUNIOR SECONDARY SCHOOL",
    Address: "NGQAQINI A/A  WILLOWVALE",
    Latitude: "-32.3464",
    Longitude: "28.4434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "698",
    WardLookupId: "2964",
    VDNumber: "10840227",
    RegisteredPopulation: "296",
    VotingStationName: "NKANGA JUNIOR SECONDARY SCHOOL",
    Address: "KOMKHULU VILLAGE  NKANGA A/A  WILLOWVALE",
    Latitude: "-32.3248",
    Longitude: "28.4565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "699",
    WardLookupId: "2964",
    VDNumber: "10840676",
    RegisteredPopulation: "177",
    VotingStationName: "NKONKWANE JUNIOR SECONDARY SCHOOL",
    Address: "NKONKWANE A/A  WILLOWVALE",
    Latitude: "-32.3323",
    Longitude: "28.5013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "700",
    WardLookupId: "2964",
    VDNumber: "10840801",
    RegisteredPopulation: "232",
    VotingStationName: "NOJONGILE JUNIOR SECONDARY SCHOOL",
    Address: "LOWER WEZA VILLAGE  WEZA A/A  WILLOWVALE",
    Latitude: "-32.27",
    Longitude: "28.4547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "701",
    WardLookupId: "2964",
    VDNumber: "10841048",
    RegisteredPopulation: "239",
    VotingStationName: "BONDE JUNIOR SECONDARY SCHOOL",
    Address: "BONDE VILLAGE  QWANINGA A/A  WILLOWVALE",
    Latitude: "-32.3164",
    Longitude: "28.5762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "702",
    WardLookupId: "2964",
    VDNumber: "10841318",
    RegisteredPopulation: "142",
    VotingStationName: "BAWANA SENIOR PRIMARY SCHOOL",
    Address: "N/A  NTSONYANA A/A  KENTANI",
    Latitude: "-32.3817",
    Longitude: "28.4303",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "703",
    WardLookupId: "2964",
    VDNumber: "10841329",
    RegisteredPopulation: "360",
    VotingStationName: "MENTE JUNIOR SECONDARY SCHOOL",
    Address: "N/A  MENTE  WILLOWVALE",
    Latitude: "-32.3639",
    Longitude: "28.4897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "704",
    WardLookupId: "2964",
    VDNumber: "10841330",
    RegisteredPopulation: "182",
    VotingStationName: "MERNE SENIOR PRIMARY SCHOOL",
    Address: "N/A  MERNE  CENTANE",
    Latitude: "-32.3352",
    Longitude: "28.533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "705",
    WardLookupId: "2964",
    VDNumber: "10841363",
    RegisteredPopulation: "226",
    VotingStationName: "ZWELITHOBILE JUNIOR SECONDARY SCHOOL",
    Address: "N/A  KENTANI  BUTTERWORTH",
    Latitude: "-32.3306",
    Longitude: "28.4747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "706",
    WardLookupId: "2964",
    VDNumber: "10841420",
    RegisteredPopulation: "274",
    VotingStationName: "ZENZELE JUNIOR SECONDARY SCHOOL",
    Address: "MJAZO VILLAGE  DADAMBA A/A  WILLOWVALE",
    Latitude: "-32.3072",
    Longitude: "28.4048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "707",
    WardLookupId: "2965",
    VDNumber: "10840328",
    RegisteredPopulation: "429",
    VotingStationName: "MNANDI JUNIOR SECONDARY SCHOOL",
    Address: "MNANDI VILLAGE  FORTMALAN A/A  WILLOWVALE",
    Latitude: "-32.1193",
    Longitude: "28.5341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "708",
    WardLookupId: "2965",
    VDNumber: "10850082",
    RegisteredPopulation: "300",
    VotingStationName: "UPPER FALAKAHLA JUNIOR SCHOOL",
    Address: "FALAKAHLA VILLAGE  NQABARA A/A  DUTYWA",
    Latitude: "-32.0606",
    Longitude: "28.4362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "709",
    WardLookupId: "2965",
    VDNumber: "10850240",
    RegisteredPopulation: "335",
    VotingStationName: "TYELEKEBENDE JUNIOR SECONDARY SCHOOL",
    Address: "TYELEKEBENDE VILLAGE  NQABARA A/A  IDUTYWA",
    Latitude: "-32.1025",
    Longitude: "28.4373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "710",
    WardLookupId: "2965",
    VDNumber: "10850295",
    RegisteredPopulation: "584",
    VotingStationName: "IDUTYWA RIVER JUNIOR SECONDARY SCHOOL",
    Address: "CILO VILLAGE  OLD IDUTYWA A/A  IDUTYWA",
    Latitude: "-32.0444",
    Longitude: "28.4093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "711",
    WardLookupId: "2965",
    VDNumber: "10850318",
    RegisteredPopulation: "350",
    VotingStationName: "LOWER FALAKAHLA JUNIOR SECONDARY SCHOOL",
    Address: "EC121 - MBHASHE [IDUTYWA]  ",
    Latitude: "-32.076",
    Longitude: "28.4934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "712",
    WardLookupId: "2965",
    VDNumber: "10850330",
    RegisteredPopulation: "329",
    VotingStationName: "NQABARA CLINIC",
    Address: "CUNGCINI VILLAGE  NQABARA A/A  IDUTYWA",
    Latitude: "-32.1011",
    Longitude: "28.4538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "713",
    WardLookupId: "2965",
    VDNumber: "10850352",
    RegisteredPopulation: "380",
    VotingStationName: "CUNGCWINI JUNIOR SECONDARY SCHOOL",
    Address: "CUNGCWINI  VILLAGE  NQABARA A/A  IDUTYWA",
    Latitude: "-32.1215",
    Longitude: "28.4675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "714",
    WardLookupId: "2965",
    VDNumber: "10850891",
    RegisteredPopulation: "190",
    VotingStationName: "MZOMTSHA SENIOR PRIMARY SCHOOL",
    Address: "EMANGWEVINI VILLAGE  NQABARA A/A  IDUTYWA",
    Latitude: "-32.0679",
    Longitude: "28.4074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "715",
    WardLookupId: "2965",
    VDNumber: "10850958",
    RegisteredPopulation: "188",
    VotingStationName: "NQABISILE SENIOR SECONDARY SCHOOL",
    Address: "FALAKAHLA VILLAGE  NQABARA A/A  IDUTYWA",
    Latitude: "-32.0545",
    Longitude: "28.4415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "716",
    WardLookupId: "2965",
    VDNumber: "10851016",
    RegisteredPopulation: "324",
    VotingStationName: "XABAJIYANE JUNIOR SECONDARY SCHOOL",
    Address: "XABAJIYANE VILLAGE  CHIZELE AA  IDUTYWA",
    Latitude: "-32.081",
    Longitude: "28.5386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "717",
    WardLookupId: "2965",
    VDNumber: "10851274",
    RegisteredPopulation: "165",
    VotingStationName: "OLD IDUTYWA COMMUNITY HALL",
    Address: "OLD DUTYWA VILLAGE   OLD IDUTYWA A/A  DUTYWA",
    Latitude: "-32.0237",
    Longitude: "28.4195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "718",
    WardLookupId: "2966",
    VDNumber: "11480015",
    RegisteredPopulation: "912",
    VotingStationName: "ELLIOTDALE TOWN HALL",
    Address: "MAIN STREET  ELLIOTDALE  ELLIOTDALE",
    Latitude: "-31.9665",
    Longitude: "28.6836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "719",
    WardLookupId: "2966",
    VDNumber: "11480026",
    RegisteredPopulation: "229",
    VotingStationName: "WALTER SISULU TENT",
    Address: "WALTER SISULU TOWNSHIP  ELLIOTDALE",
    Latitude: "-31.9659",
    Longitude: "28.6766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "720",
    WardLookupId: "2966",
    VDNumber: "11650171",
    RegisteredPopulation: "548",
    VotingStationName: "FAMENI JUNIOR SECONDARY SCHOOL",
    Address: "FAMENI VILLAGE  NGCIHANA EAST A/A  ELLIOTDALE",
    Latitude: "-32.0156",
    Longitude: "28.7158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "721",
    WardLookupId: "2966",
    VDNumber: "11650339",
    RegisteredPopulation: "809",
    VotingStationName: "KASA JUNIOR SECONDARY SCHOOL",
    Address: "KASA VILLAGE  NCIHANA WEST A/A  ELLIOTDALE",
    Latitude: "-32.0211",
    Longitude: "28.6713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "722",
    WardLookupId: "2966",
    VDNumber: "11650340",
    RegisteredPopulation: "416",
    VotingStationName: "PONGOMA SENIOR PRIMARY SCHOOL",
    Address: "KASA VILLAGE  NCIHANA WEST A/A  ELLIOTDALE",
    Latitude: "-32.0489",
    Longitude: "28.6511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "723",
    WardLookupId: "2966",
    VDNumber: "11650430",
    RegisteredPopulation: "168",
    VotingStationName: "RIVERVIEW SENIOR PRIMARY SCHOOL",
    Address: "NCIHANA WEST VILLAGE  NCIHANA A/A  ELLIOTDALE",
    Latitude: "-32.0471",
    Longitude: "28.6193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "724",
    WardLookupId: "2966",
    VDNumber: "11650441",
    RegisteredPopulation: "596",
    VotingStationName: "MANDULUKA SENIOR PRIMARY SCHOOL",
    Address: "QUNTSULA VILLAGE  NCIHANA WEST A/A  ELLIOTDALE",
    Latitude: "-32.0394",
    Longitude: "28.6861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "725",
    WardLookupId: "2966",
    VDNumber: "11650531",
    RegisteredPopulation: "457",
    VotingStationName: "NCIHANA JUNIOR SECONDARY SCHOOL",
    Address: "NCIHANA HEAD VILLAGE  NCIHANA A/A  ELLIOTDALE",
    Latitude: "-31.9872",
    Longitude: "28.6879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "726",
    WardLookupId: "2967",
    VDNumber: "10840339",
    RegisteredPopulation: "256",
    VotingStationName: "MAHLEZANA JUNIOR SECONDARY SCHOOL",
    Address: "MAHLEZANA VILLAGE  MDUDU A/A  WILLOWVALE",
    Latitude: "-32.0735",
    Longitude: "28.5637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "727",
    WardLookupId: "2967",
    VDNumber: "10840340",
    RegisteredPopulation: "431",
    VotingStationName: "RAMRA JUNIOR SECONDARY SCHOOL",
    Address: "LUKHANYISWENI VILLAGE  RAMRA A/A  WILLOWVALE",
    Latitude: "-32.1197",
    Longitude: "28.6171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "728",
    WardLookupId: "2967",
    VDNumber: "10840351",
    RegisteredPopulation: "408",
    VotingStationName: "LUKOZANA JUNIOR SECONDARY SCHOOL",
    Address: "LUKOZANA VILLAGE  MBHANGCOLO A/A  WILLOWVALE",
    Latitude: "-32.1506",
    Longitude: "28.7074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "729",
    WardLookupId: "2967",
    VDNumber: "10840373",
    RegisteredPopulation: "366",
    VotingStationName: "LOWER BIKANE  JUNIOR SECONDARY SCHOOL",
    Address: "LOWER BIKANE VILLAGE   BIKANE A/A  WILLOWVALE",
    Latitude: "-32.1888",
    Longitude: "28.6514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "730",
    WardLookupId: "2967",
    VDNumber: "10840520",
    RegisteredPopulation: "281",
    VotingStationName: "LOWER MBANGCOLO JUNIOR SECONDARY SCHOOL",
    Address: "LOWER MBANGCOLO VILLAGE  MBANGCOLO A/A  WILLOVALE",
    Latitude: "-32.1954",
    Longitude: "28.7164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "731",
    WardLookupId: "2967",
    VDNumber: "10840564",
    RegisteredPopulation: "327",
    VotingStationName: "MNDUNDU JUNIOR SECONDARY SCHOOL",
    Address: "MNDUNDU VILLAGE  MNDUNDU A/A  WILLOWVALE",
    Latitude: "-32.0888",
    Longitude: "28.6313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "732",
    WardLookupId: "2967",
    VDNumber: "10840575",
    RegisteredPopulation: "294",
    VotingStationName: "LUSUNGULO JUNIOR SECONDARY SCHOOL",
    Address: "NQABENI VILLAGE  BIKANA A/A  WILLOWVALE",
    Latitude: "-32.1203",
    Longitude: "28.5853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "733",
    WardLookupId: "2967",
    VDNumber: "10840610",
    RegisteredPopulation: "326",
    VotingStationName: "MANQOBA JUNIOR SECONDARY SCHOOL",
    Address: "KASINI VILLAGE  MNDUNDU A/A  WILLOWVALE",
    Latitude: "-32.0844",
    Longitude: "28.5959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "734",
    WardLookupId: "2967",
    VDNumber: "10840687",
    RegisteredPopulation: "680",
    VotingStationName: "FORT BOWKER JUNIOR SECONDARY SCHOOL",
    Address: "MFULA LOCATION  FORT BOWKER  WILLOWVALE",
    Latitude: "-32.0914",
    Longitude: "28.5665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "735",
    WardLookupId: "2967",
    VDNumber: "10840711",
    RegisteredPopulation: "155",
    VotingStationName: "HLAKOTI SENIOR PRIMARY SCHOOL",
    Address: "HLAKOTI VILLAGE   NTSIMBAKAZI  A/A  WILLOWVALE",
    Latitude: "-32.1699",
    Longitude: "28.736",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "736",
    WardLookupId: "2967",
    VDNumber: "10840935",
    RegisteredPopulation: "323",
    VotingStationName: "MADAKANE JUNIOR SECONDARY SCHOOL",
    Address: "MADAKANE LOCATION  RHAMRA A/A  WILLOWVALE",
    Latitude: "-32.1291",
    Longitude: "28.6409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "737",
    WardLookupId: "2967",
    VDNumber: "10840946",
    RegisteredPopulation: "145",
    VotingStationName: "UPPER MBANGCOLO JUNIOR SECONDARY SCHOOL",
    Address: "SUNDUZA VILLAGE  MBANGCOLO A/A  WILLOWVALE",
    Latitude: "-32.1419",
    Longitude: "28.6867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "738",
    WardLookupId: "2967",
    VDNumber: "10841228",
    RegisteredPopulation: "92",
    VotingStationName: "MAGABA SENIOR PRIMARY SCHOOL",
    Address: "QINGQALA VILLAGE  LOWER MBHANGCOLO A/A  WILLOWVALE",
    Latitude: "-32.1925",
    Longitude: "28.6908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "739",
    WardLookupId: "2968",
    VDNumber: "11650227",
    RegisteredPopulation: "334",
    VotingStationName: "NGQAKAYI JUNIOR SECONDARY SCHOOL",
    Address: "NGQAKAYI VILLAGE  SHOLORA A/A  ELLIOTDALE",
    Latitude: "-32.1302",
    Longitude: "28.7761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "740",
    WardLookupId: "2968",
    VDNumber: "11650238",
    RegisteredPopulation: "364",
    VotingStationName: "MELITAFA JUNIOR SECONDARY SCHOOL",
    Address: "NGADINI VILLAGE  NCIHANA WEST A/A  ELLIOTDALE",
    Latitude: "-32.0806",
    Longitude: "28.6842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "741",
    WardLookupId: "2968",
    VDNumber: "11650294",
    RegisteredPopulation: "352",
    VotingStationName: "XHUBA JUNIOR SECONDARY SCHOOL",
    Address: "XHUBA VILLAGE  XAMA A/A  ELLIOTDALE",
    Latitude: "-32.0655",
    Longitude: "28.7362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "742",
    WardLookupId: "2968",
    VDNumber: "11650362",
    RegisteredPopulation: "301",
    VotingStationName: "MILLER JUNIOR SECONDARY SCHOOL",
    Address: "MILLER MISSION VILLAGE  XAMA A/A  ELLIOTDALE",
    Latitude: "-32.0328",
    Longitude: "28.7365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "743",
    WardLookupId: "2968",
    VDNumber: "11650407",
    RegisteredPopulation: "320",
    VotingStationName: "TANDIWE HOUSE",
    Address: "XUBA VILLAGE   XAMA A/A  ELLIOTDALE",
    Latitude: "-32.0781",
    Longitude: "28.7398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "744",
    WardLookupId: "2968",
    VDNumber: "11650508",
    RegisteredPopulation: "139",
    VotingStationName: "MCELWANE SENIOR PRIMARY SCHOOL",
    Address: "MCELWANE VILLAGE  NDABUKA  A/A  ELLIOTDALE",
    Latitude: "-32.1195",
    Longitude: "28.723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "745",
    WardLookupId: "2968",
    VDNumber: "11650553",
    RegisteredPopulation: "193",
    VotingStationName: "QAMATA JUNIOR SECONDARY SCHOOL",
    Address: "MILLER MISSION VILLAGE  NCIHANA EAST A/A  ELLIOTDALE",
    Latitude: "-32.0208",
    Longitude: "28.7297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "746",
    WardLookupId: "2968",
    VDNumber: "11650575",
    RegisteredPopulation: "177",
    VotingStationName: "MDABUKA SENIOR PRIMARY SCHOOL",
    Address: "BHANGQO VILLAGE  MCELWANA AA  IDUTYWA",
    Latitude: "-32.0903",
    Longitude: "28.7318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "747",
    WardLookupId: "2968",
    VDNumber: "11650597",
    RegisteredPopulation: "201",
    VotingStationName: "PEWULA SENIOR PRIMARY SCHOOL",
    Address: "XHUBA VILLAGE  XHAMA A/A  ELLIOTDALE",
    Latitude: "-32.0617",
    Longitude: "28.7175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "748",
    WardLookupId: "2968",
    VDNumber: "11650609",
    RegisteredPopulation: "285",
    VotingStationName: "TUBENI STORE",
    Address: "TUBENI VILLAGE  XHAMA AA  ELLIOTDALE",
    Latitude: "-32.0451",
    Longitude: "28.7462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "749",
    WardLookupId: "2968",
    VDNumber: "11650654",
    RegisteredPopulation: "212",
    VotingStationName: "SARHILI SENIOR PRIMARY SCHOOL",
    Address: "SARHILI LOCATION  NGQAKAYI AA  IDUTYWA",
    Latitude: "-32.1356",
    Longitude: "28.7531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "750",
    WardLookupId: "2968",
    VDNumber: "11650676",
    RegisteredPopulation: "246",
    VotingStationName: "SHOLORA JUNIOR SECONDARY SCHOOL",
    Address: "NTILINI VILLAGE  SHOLORA A/A  ELLIOTDALE",
    Latitude: "-32.1462",
    Longitude: "28.7957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "751",
    WardLookupId: "2968",
    VDNumber: "11650856",
    RegisteredPopulation: "256",
    VotingStationName: "NJUQWANA SENIOR PRIMARY SCHOOL",
    Address: "MADLUNJA VILLAGE  SHOLORA A/A  ELLIOTDALE",
    Latitude: "-32.1198",
    Longitude: "28.7716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "752",
    WardLookupId: "2968",
    VDNumber: "11650890",
    RegisteredPopulation: "345",
    VotingStationName: "MWEZENI-SENIOR-PRIMARY-SCHOOL",
    Address: "MWEZENI VILLAGE  MCELWANA AA  ELLIOTDALE",
    Latitude: "-32.0995",
    Longitude: "28.6982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "753",
    WardLookupId: "2969",
    VDNumber: "11650159",
    RegisteredPopulation: "774",
    VotingStationName: "SIRUNU JUNIOR SECONDARY SCHOOL",
    Address: "KULOKHAMISA VILLAGE  NTLONYANE A/A  ELLIOTDALE",
    Latitude: "-32.1063",
    Longitude: "28.8112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "754",
    WardLookupId: "2969",
    VDNumber: "11650205",
    RegisteredPopulation: "428",
    VotingStationName: "QOTONGO JUNIOR SECONDARY SCHOOL",
    Address: "KULOGAXA VILLAGE  GUSI A/A  ELLIOTDALE",
    Latitude: "-32.1296",
    Longitude: "28.8602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "755",
    WardLookupId: "2969",
    VDNumber: "11650306",
    RegisteredPopulation: "226",
    VotingStationName: "POKOLOSHE SENIOR SECONIOR SCHOOL",
    Address: "NOMTHOMBE VILLAGE  MBANYANE A/A  ELLIOTDALE",
    Latitude: "-32.1415",
    Longitude: "28.8282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "756",
    WardLookupId: "2969",
    VDNumber: "11650317",
    RegisteredPopulation: "657",
    VotingStationName: "MBANYANA  JUNIOR SECONDARY SCHOOL",
    Address: "MBANYANA VILLAGE  MBANYANA  A/A  ELLIOTDALE",
    Latitude: "-32.1187",
    Longitude: "28.8325",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "757",
    WardLookupId: "2969",
    VDNumber: "11650496",
    RegisteredPopulation: "419",
    VotingStationName: "JONGULWANDLE JUNIOR SECONDARY SCHOOL",
    Address: "JELEZINI VILLAGE  NTLONYANA A/A  ELLIOTDALE",
    Latitude: "-32.0651",
    Longitude: "28.7979",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "758",
    WardLookupId: "2969",
    VDNumber: "11650610",
    RegisteredPopulation: "182",
    VotingStationName: "GOBIZEMBE SENIOR PRIMARY SCHOOL",
    Address: "ZINDENI VILLAGE  KWELOMTHOMBE A/A  ELLIOTDALE",
    Latitude: "-32.1653",
    Longitude: "28.8223",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "759",
    WardLookupId: "2969",
    VDNumber: "11650632",
    RegisteredPopulation: "562",
    VotingStationName: "MNGAZANA JUNIOR SECONDARY SCHOOL",
    Address: "JIKA VILLAGE  MNGAZANA A/A  ELLIOTDALE",
    Latitude: "-32.0898",
    Longitude: "28.7991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "760",
    WardLookupId: "2969",
    VDNumber: "11650722",
    RegisteredPopulation: "267",
    VotingStationName: "KOSANI SENIOR PRIMARY SCHOOL",
    Address: "ZITHENJINI VILLAGE  NTLONYANE A/A  ELLIOTDALE",
    Latitude: "-32.0962",
    Longitude: "28.8516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "761",
    WardLookupId: "2969",
    VDNumber: "11650889",
    RegisteredPopulation: "358",
    VotingStationName: "NTABEZULU-SENIOR-PRIMARY-SCHOOL",
    Address: "NTABEZULU VILAGE  MBANYANA AA  ELLIOTDALE",
    Latitude: "-32.1255",
    Longitude: "28.8075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "762",
    WardLookupId: "2970",
    VDNumber: "11650036",
    RegisteredPopulation: "493",
    VotingStationName: "NTSINGIZI JUNIOR SECONDARY SCHOOL",
    Address: "MGOJWENI VILLAGE  NTSINGIZI A/A  ELLIOTDALE",
    Latitude: "-31.9649",
    Longitude: "28.9228",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "763",
    WardLookupId: "2970",
    VDNumber: "11650047",
    RegisteredPopulation: "665",
    VotingStationName: "LOWER BUFUMBA SENIOR PRIMARY SCHOOL",
    Address: "KULOMBETHE VILLAGE  LOWER BUFUMBA A/A  ELLIOTDALE",
    Latitude: "-32.0067",
    Longitude: "28.9556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "764",
    WardLookupId: "2970",
    VDNumber: "11650148",
    RegisteredPopulation: "650",
    VotingStationName: "MPAKAMA JUNIOR SECONDARY SCHOOL",
    Address: "MQOTHONGWENI VILLAGE  QIQANA SOUTH A/A  ELLIOTDALE",
    Latitude: "-31.9854",
    Longitude: "28.8845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "765",
    WardLookupId: "2970",
    VDNumber: "11650182",
    RegisteredPopulation: "306",
    VotingStationName: "NQAYIYA JUNIOR SECONDARY SCHOOL",
    Address: "STONE DRIFT VILLAGE  QHORA A/A  ELLIOTDALE",
    Latitude: "-32.0209",
    Longitude: "28.8535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "766",
    WardLookupId: "2970",
    VDNumber: "11650328",
    RegisteredPopulation: "424",
    VotingStationName: "QHINQANA JUNIOR SECONDARY SCHOOL",
    Address: "QHINQANA NORTH VILLAGE  QINNQANA A/A  ELLIOTDALE",
    Latitude: "-32.0068",
    Longitude: "28.8439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "767",
    WardLookupId: "2970",
    VDNumber: "11650452",
    RegisteredPopulation: "775",
    VotingStationName: "J.TYALI JUNIOR SECONDARY SCHOOL",
    Address: "LOWER BUFUMBA VILLAGE  LOWER BUFUMBA A/A  ELLIOTDALE",
    Latitude: "-31.9741",
    Longitude: "28.934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "768",
    WardLookupId: "2970",
    VDNumber: "11650463",
    RegisteredPopulation: "482",
    VotingStationName: "MANZI JUNIOR SECONDARY SCHOOL",
    Address: "MANZI VILLAGE  UPPER MNCWASA A/A  ELLIOTDALE",
    Latitude: "-31.9601",
    Longitude: "28.8949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "769",
    WardLookupId: "2970",
    VDNumber: "11650902",
    RegisteredPopulation: "272",
    VotingStationName: "MAGOXO-SENIOR-PRIMARY-SCHOOL",
    Address: "MAGOXO VILLAGE  BUFUMBA AA  ELLIOTDALE",
    Latitude: "-31.9977",
    Longitude: "28.9172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "770",
    WardLookupId: "2970",
    VDNumber: "11650913",
    RegisteredPopulation: "351",
    VotingStationName: "QINQANA-METHODIST-CHURCH",
    Address: "JELEVINI VILLAGE  QINQANA AA  ELLIOTDALE",
    Latitude: "-31.9951",
    Longitude: "28.8631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "771",
    WardLookupId: "2970",
    VDNumber: "11650935",
    RegisteredPopulation: "496",
    VotingStationName: "MTSHEKELWENI HOUSE",
    Address: "MTSHEKELWENI VILLAGE  MTSHEKELWENI A/A  ELLIOTDALE",
    Latitude: "-32.0489",
    Longitude: "28.8847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "772",
    WardLookupId: "2971",
    VDNumber: "11650069",
    RegisteredPopulation: "456",
    VotingStationName: "PUTUMA JUNIOR SECONDARY SCHOOL",
    Address: "PUTUMA VILLAGE  TALIMOFU AA  ELLIOTDALE",
    Latitude: "-32.0158",
    Longitude: "29.0153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "773",
    WardLookupId: "2971",
    VDNumber: "11650070",
    RegisteredPopulation: "670",
    VotingStationName: "KHOTYANA JUNIOR SECONDARY SCHOOL",
    Address: "KHOTYANA  VILLAGE  MNCWASA WEST A/A  ELLIOTDALE",
    Latitude: "-32.0274",
    Longitude: "29.0311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "774",
    WardLookupId: "2971",
    VDNumber: "11650092",
    RegisteredPopulation: "707",
    VotingStationName: "MNDWAKA JUNIOR SECONDARY SCHOOL",
    Address: "MDWAKA VILLAGE  MNDWAKA A/A  ELLIOTDALE",
    Latitude: "-32.0531",
    Longitude: "28.9794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "775",
    WardLookupId: "2971",
    VDNumber: "11650126",
    RegisteredPopulation: "892",
    VotingStationName: "KWANTSHUNQE JUNIOR SECONDARY SCHOOL",
    Address: "TAFALEHASHE VILLAGE  NKWALINI A/A  ELLIOTDALE",
    Latitude: "-32.0322",
    Longitude: "28.9427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "776",
    WardLookupId: "2971",
    VDNumber: "11650351",
    RegisteredPopulation: "245",
    VotingStationName: "ZIKOLOKOTHA SENIOR PRIMARY SCHOOL",
    Address: "BHAKALENI VILLAGE  ZIKOLOKOTHA A/A  ELLIOTDALE",
    Latitude: "-31.9912",
    Longitude: "28.989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "777",
    WardLookupId: "2971",
    VDNumber: "11650799",
    RegisteredPopulation: "614",
    VotingStationName: "GWEBITYALA SENIOR SECONDARY SCHOOL",
    Address: "KOTYANA VILLAGE  NCWASA A/A  ELLIOTDALE",
    Latitude: "-32.0348",
    Longitude: "28.9852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "778",
    WardLookupId: "2971",
    VDNumber: "11650834",
    RegisteredPopulation: "236",
    VotingStationName: "TALIMOFU MISSION",
    Address: "TALIMOFU MISSION VILLAGE  MNCWASA EAST A/A  ELLIOTDALE",
    Latitude: "-32.0104",
    Longitude: "29.0112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "779",
    WardLookupId: "2972",
    VDNumber: "11590040",
    RegisteredPopulation: "597",
    VotingStationName: "SEA VIEW JUNIOR SECONDARY SCHOOL",
    Address: "SEA VIEW LOC  LOWER MPAKO A/A  ELLIOTDALE",
    Latitude: "-32.0468",
    Longitude: "29.0563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "780",
    WardLookupId: "2972",
    VDNumber: "11650081",
    RegisteredPopulation: "549",
    VotingStationName: "MANZIBOMVU JUNIOR SECONDARY SCHOOL",
    Address: "GABHU VILLAGE  MDWAKU A/A  ELLIOTDALE",
    Latitude: "-32.0719",
    Longitude: "29.0213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "781",
    WardLookupId: "2972",
    VDNumber: "11650104",
    RegisteredPopulation: "506",
    VotingStationName: "MPAME JUNIOR SECONDARY SCHOOL",
    Address: "NQAKANQA VILLAGE  MPAME A/A  ELLIOTDALE",
    Latitude: "-32.097",
    Longitude: "29.04",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "782",
    WardLookupId: "2972",
    VDNumber: "11650249",
    RegisteredPopulation: "479",
    VotingStationName: "V.G.K.ZITULELE CHURCH HALL",
    Address: "ZITULELE MISSION VILLAGE  ZITULELE A/A  ELLIOTDALE",
    Latitude: "-32.0494",
    Longitude: "29.0602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "783",
    WardLookupId: "2972",
    VDNumber: "11650801",
    RegisteredPopulation: "575",
    VotingStationName: "MHLAHLANE SENIOR SECONDARY SCHOOL",
    Address: "MHLAHLANE VVILLAGE  NCWASA A/A  ELLIOTDALE",
    Latitude: "-32.0498",
    Longitude: "29.0809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "784",
    WardLookupId: "2972",
    VDNumber: "11650823",
    RegisteredPopulation: "448",
    VotingStationName: "LUMKWANA SENIOR PRIMARY SCHOOL",
    Address: "MDIKANE VILLAGE  MPAME A/A  ELLIOTDALE",
    Latitude: "-32.0778",
    Longitude: "29.0607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "785",
    WardLookupId: "2972",
    VDNumber: "11650845",
    RegisteredPopulation: "444",
    VotingStationName: "NO-OFISI -SENIOR PRIMARY SCHOOL",
    Address: "JALAMBA VILLAGE   XHORA  MOUTH AA  ELLIOTDALE",
    Latitude: "-32.134",
    Longitude: "28.9922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "786",
    WardLookupId: "2972",
    VDNumber: "11650878",
    RegisteredPopulation: "543",
    VotingStationName: "NDALATHA SENIOR PRIMARY SCHOOL",
    Address: "MANZAMNYAMA VILLAGE  MPAME A/A  ELLIOTDALE",
    Latitude: "-32.097",
    Longitude: "29.0119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "787",
    WardLookupId: "2972",
    VDNumber: "11650946",
    RegisteredPopulation: "234",
    VotingStationName: "LUBANZI JUNIOR SECONDARY SCHOOL",
    Address: "GINYINTSIMBI VILLAGE   MNCWASA A/A  ELLIOTDALE",
    Latitude: "-32.0552",
    Longitude: "29.0541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "788",
    WardLookupId: "2973",
    VDNumber: "11650058",
    RegisteredPopulation: "692",
    VotingStationName: "MANGANYELA JUNIOR SECONDARY SCHOOL",
    Address: "MANGANYELA VILLAGE  MANGANYELA A/A  ELLIOTDALE",
    Latitude: "-32.1515",
    Longitude: "28.8772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "789",
    WardLookupId: "2973",
    VDNumber: "11650193",
    RegisteredPopulation: "959",
    VotingStationName: "HOBENI JUNIOR SECONDARY SCHOOL",
    Address: "NGQUNGQUMBE VILLAGE  HOBENI A/A  ELLIOTDALE",
    Latitude: "-32.1851",
    Longitude: "28.8802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "790",
    WardLookupId: "2973",
    VDNumber: "11650250",
    RegisteredPopulation: "633",
    VotingStationName: "CWEBE JUNIOR SECONDARY SCHOOL",
    Address: "NYANDENI VILLAGE  CWEBE A/A  ELLIOTDALE",
    Latitude: "-32.1825",
    Longitude: "28.9071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "791",
    WardLookupId: "2973",
    VDNumber: "11650418",
    RegisteredPopulation: "503",
    VotingStationName: "GEYA JUNIOR SECONDARY SCHOOL",
    Address: "MNQWANENI VILLAGE  CWEBE A/A  ELLIOTDALE",
    Latitude: "-32.1954",
    Longitude: "28.9344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "792",
    WardLookupId: "2973",
    VDNumber: "11650429",
    RegisteredPopulation: "547",
    VotingStationName: "NGUBENYATHI SENIOR PRIMARY SCHOOL",
    Address: "NTLANGANISWENI VILLAGE  HOBENI A/A  ELLIOTDALE",
    Latitude: "-32.2152",
    Longitude: "28.8749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "793",
    WardLookupId: "2973",
    VDNumber: "11650485",
    RegisteredPopulation: "217",
    VotingStationName: "NKINTSHANA SENIOR PRIMARY SCHOOL",
    Address: "MAKAMESO VILLAGE  MBANYNA  A/A  ELLIOTDALE",
    Latitude: "-32.1641",
    Longitude: "28.8393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "794",
    WardLookupId: "2973",
    VDNumber: "11650924",
    RegisteredPopulation: "314",
    VotingStationName: "ZWELEBANGO-SENIOR-PRIMARY-SCHOOL",
    Address: "ZWELEBANGO VILLAGE  MANGANYELA AA  ELLIOTDALE",
    Latitude: "-32.1407",
    Longitude: "28.8645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "795",
    WardLookupId: "2973",
    VDNumber: "11650957",
    RegisteredPopulation: "202",
    VotingStationName: "KWA-NOMANESI SHOP",
    Address: "GEYA VILLAGE   CWEBE A/A  ELLIOTDALE",
    Latitude: "-32.176",
    Longitude: "28.941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "796",
    WardLookupId: "2974",
    VDNumber: "10840014",
    RegisteredPopulation: "648",
    VotingStationName: "MPOZOLO SENIOR PRIMARY SCHOOL",
    Address: "MPOZOLO VILLAGE  MENDU AA  WILLOWVALE",
    Latitude: "-32.1788",
    Longitude: "28.7756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "797",
    WardLookupId: "2974",
    VDNumber: "10840025",
    RegisteredPopulation: "328",
    VotingStationName: "NTSIMBAKAZI JUNIOR SECONDARY SCHOOL",
    Address: "NTSIMBAKAZI VILLAGE  NTSIMBAKAZI AA  WILLOWVALE",
    Latitude: "-32.1975",
    Longitude: "28.7365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "798",
    WardLookupId: "2974",
    VDNumber: "10840384",
    RegisteredPopulation: "324",
    VotingStationName: "LURWAYIZO JUNIOR SECONDARY SCHOOL",
    Address: "LURWAYIZO VILLAGE  LURWAYIZO A/A  WILLOWVALE",
    Latitude: "-32.2182",
    Longitude: "28.8428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "799",
    WardLookupId: "2974",
    VDNumber: "10840395",
    RegisteredPopulation: "443",
    VotingStationName: "MSENDO JUNIOR SECONDARY SCHOOL",
    Address: "MSENDO VILLAGE  MSENDO A/A  WILLOWVALE",
    Latitude: "-32.1901",
    Longitude: "28.7712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "800",
    WardLookupId: "2974",
    VDNumber: "10840531",
    RegisteredPopulation: "368",
    VotingStationName: "MENDWANA JUNIOR SECONDARY SCHOOL",
    Address: "MENDWANA VILLAGE  MENDU A/A  WILLOWVALE",
    Latitude: "-32.2334",
    Longitude: "28.8535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "801",
    WardLookupId: "2974",
    VDNumber: "10840542",
    RegisteredPopulation: "501",
    VotingStationName: "MENDU JUNIOR SECONDARY SCHOOL",
    Address: "MENDU VILLAGE  MENDU A/A  WILLOWVALE",
    Latitude: "-32.2046",
    Longitude: "28.793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "802",
    WardLookupId: "2974",
    VDNumber: "10841273",
    RegisteredPopulation: "257",
    VotingStationName: "NKONJANE JUNIOR SECONDARY SCHOOL",
    Address: "DAYI VILLAGE  MSENDO AA  WILLOWVALE",
    Latitude: "-32.2289",
    Longitude: "28.7834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "803",
    WardLookupId: "2974",
    VDNumber: "10841284",
    RegisteredPopulation: "163",
    VotingStationName: "MAMPONDWENI JUNIOR SECONDARY SCHOOL",
    Address: "NGOMA VILLAGE  MENDU AA  WILLOWVALE",
    Latitude: "-32.2517",
    Longitude: "28.8124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "804",
    WardLookupId: "2974",
    VDNumber: "10841341",
    RegisteredPopulation: "171",
    VotingStationName: "NTLANGANO-NGOMA SENIOR PRIMARY SCHOOL",
    Address: "NTLANGANO VILLAGE  MENDU  AA  WILLOWVALE",
    Latitude: "-32.2395",
    Longitude: "28.8299",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "805",
    WardLookupId: "2975",
    VDNumber: "10840294",
    RegisteredPopulation: "807",
    VotingStationName: "NGADLA JUNIOR SECONDARY SCHOOL",
    Address: "NGADLA VILLAGE  SHIXENI A/A  WILLOWVALE",
    Latitude: "-32.3757",
    Longitude: "28.6821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "806",
    WardLookupId: "2975",
    VDNumber: "10840306",
    RegisteredPopulation: "400",
    VotingStationName: "DUMALISILE JUNIOR SECONDARY SCHOOL",
    Address: "DUMALISILE VILLAGE  SHIXENI A/A  WILLOWVALE",
    Latitude: "-32.3331",
    Longitude: "28.6582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "807",
    WardLookupId: "2975",
    VDNumber: "10840407",
    RegisteredPopulation: "387",
    VotingStationName: "BEECHAMWOOD JUNIOR SECONDARY SCHOOL",
    Address: "CELANI VILLAGE  MASANA A/A  WILLOWVALE",
    Latitude: "-32.3351",
    Longitude: "28.7255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "808",
    WardLookupId: "2975",
    VDNumber: "10840418",
    RegisteredPopulation: "520",
    VotingStationName: "NQUBA JUNIOR SECONDARY SCHOOL",
    Address: "NQUBA LOCATION  WILLOWVALE",
    Latitude: "-32.3168",
    Longitude: "28.7046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "809",
    WardLookupId: "2975",
    VDNumber: "10840632",
    RegisteredPopulation: "305",
    VotingStationName: "GOJELA JUNIOR SECONDARY SCHOOL",
    Address: "DIMBENI VILLAGE  QORA A/A  WILLOWVALE",
    Latitude: "-32.4333",
    Longitude: "28.6666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "810",
    WardLookupId: "2975",
    VDNumber: "10841172",
    RegisteredPopulation: "333",
    VotingStationName: "ZANEMVULA SENIOR PRIMARY SCHOOL",
    Address: "TENZA VILLAGE  MAHASANA AA  WILLOWVALE",
    Latitude: "-32.3474",
    Longitude: "28.7153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "811",
    WardLookupId: "2975",
    VDNumber: "10841194",
    RegisteredPopulation: "306",
    VotingStationName: "CHAMSHE JUNIOR SECONDARY SCHOOL",
    Address: "CHAMSHE VILLAGE  MAHASANA A/A  WILLOWVALE",
    Latitude: "-32.3011",
    Longitude: "28.6969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "812",
    WardLookupId: "2975",
    VDNumber: "10841408",
    RegisteredPopulation: "182",
    VotingStationName: "LOWER GOQO JUNIOR SECONDARY SCHOOL",
    Address: "KUNENE VILLAGE  MAHASANA A/A  WILLOWVALE",
    Latitude: "-32.3519",
    Longitude: "28.7583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "813",
    WardLookupId: "2975",
    VDNumber: "10841431",
    RegisteredPopulation: "224",
    VotingStationName: "HLABIZULU JUNIOR SECONDARY SCHOOL",
    Address: "NTLAHLANA VILLAGE  MAHASANA A/A  WILLOVALE",
    Latitude: "-32.334",
    Longitude: "28.709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "814",
    WardLookupId: "2975",
    VDNumber: "10841442",
    RegisteredPopulation: "254",
    VotingStationName: "ZWELINZIMA -SENIOR-PRIMARY -SCHOOL",
    Address: "DUMALISILE   NQABARA AA  WILLOWVALE",
    Latitude: "-32.3549",
    Longitude: "28.6676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "815",
    WardLookupId: "2976",
    VDNumber: "10840249",
    RegisteredPopulation: "280",
    VotingStationName: "MGWEBI JUNIOR SECONDARY SCHOOL",
    Address: "MGWEBI  A/A  MGWEBI A/A  WILLOWVALE",
    Latitude: "-32.4068",
    Longitude: "28.5588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "816",
    WardLookupId: "2976",
    VDNumber: "10840250",
    RegisteredPopulation: "298",
    VotingStationName: "ZANENQABA SENIOR PRIMARYSCHOOL",
    Address: "NGCIZELE VILLAGE  KWANINGA A/A  WILLOWVALE",
    Latitude: "-32.3896",
    Longitude: "28.6107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "817",
    WardLookupId: "2976",
    VDNumber: "10840272",
    RegisteredPopulation: "190",
    VotingStationName: "MAKHOSONKE SENIOR PRIMARY SCHOOL",
    Address: "FUKULA VILLAGE  QWANINGA AA  WILLOWVALE",
    Latitude: "-32.3485",
    Longitude: "28.5698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "818",
    WardLookupId: "2976",
    VDNumber: "10840283",
    RegisteredPopulation: "738",
    VotingStationName: "BOJENI JUNIOR SECONDARY SCHOOL",
    Address: "BOJENI VILLAGE  JUJURA A/A  WILLOWVALE",
    Latitude: "-32.3305",
    Longitude: "28.5962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "819",
    WardLookupId: "2976",
    VDNumber: "10840496",
    RegisteredPopulation: "447",
    VotingStationName: "ZWELIDUMILE SENIOR SECONDARY SCHOOL",
    Address: "LUVUNDU VILLAGE  NGOZI A/A  WILLOWVALE",
    Latitude: "-32.2987",
    Longitude: "28.5805",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "820",
    WardLookupId: "2976",
    VDNumber: "10840519",
    RegisteredPopulation: "399",
    VotingStationName: "HADI JUNIOR SECONDARY SCHOOL",
    Address: "QWANINGA VILLAGE  SEBELI A/A   WILLOWVALE",
    Latitude: "-32.4131",
    Longitude: "28.6095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "821",
    WardLookupId: "2976",
    VDNumber: "10840654",
    RegisteredPopulation: "233",
    VotingStationName: "MHLAHLANE JUNIOR SECONDARY SCHOOL",
    Address: "MHLAHLANE A/A  WILLOWVALE",
    Latitude: "-32.3582",
    Longitude: "28.5364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "822",
    WardLookupId: "2976",
    VDNumber: "10840665",
    RegisteredPopulation: "277",
    VotingStationName: "ZANEWONGA JUNIOR SECONDARY SCHOOL",
    Address: "MHLAHLANE A/A  WILLOWVALE",
    Latitude: "-32.3797",
    Longitude: "28.518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "823",
    WardLookupId: "2976",
    VDNumber: "10840700",
    RegisteredPopulation: "163",
    VotingStationName: "UPPER NGXUTYANA JUNIOR SECONDARY SCHOOL",
    Address: "UPPER NGXUTYANA VILLAGE  NGXUTYANA A/A  WILLOWVALE",
    Latitude: "-32.3384",
    Longitude: "28.5815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "824",
    WardLookupId: "2976",
    VDNumber: "10840733",
    RegisteredPopulation: "267",
    VotingStationName: "JUJURA SENIOR PRIMARY SCHOOL",
    Address: "JUJURA VILLAGE  JUJURA A/A  WILLOVALE",
    Latitude: "-32.3808",
    Longitude: "28.6488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "825",
    WardLookupId: "2976",
    VDNumber: "10841059",
    RegisteredPopulation: "375",
    VotingStationName: "MATHUMBU SENIOR SECONDARY SCHOOL",
    Address: "KULONGOMA VILLAGE  QWANINGA AA  WILLOWVALE",
    Latitude: "-32.3653",
    Longitude: "28.5717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "826",
    WardLookupId: "2976",
    VDNumber: "10841071",
    RegisteredPopulation: "189",
    VotingStationName: "NAKAZANA SENIOR  SECONDARY SCHOOL",
    Address: "NAKAZANA VILLAGE  NAKAZANA A/A   WILLOWVALE",
    Latitude: "-32.3899",
    Longitude: "28.6301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "827",
    WardLookupId: "2976",
    VDNumber: "10841082",
    RegisteredPopulation: "156",
    VotingStationName: "MGCAWEZULU SENIOR PRIMARY SCHOOL",
    Address: "MUNCU VILLAGE  QWANINGA A/A  WILLOWVALE",
    Latitude: "-32.4058",
    Longitude: "28.6557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "828",
    WardLookupId: "2976",
    VDNumber: "10841093",
    RegisteredPopulation: "259",
    VotingStationName: "LOWER NGXUTYANA JUNIOR SECONDARY SCHOOL",
    Address: "LOWER NGXUTYANA VILLAGE  QWANINGA AA  WILLOWVALE",
    Latitude: "-32.375",
    Longitude: "28.6018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "829",
    WardLookupId: "2977",
    VDNumber: "10840429",
    RegisteredPopulation: "390",
    VotingStationName: "NTLAHLANE JUNIOR SECONDARY SCHOOL",
    Address: "NTLAHLANA VILLAGE  MAHASANA A/A  WILLOWVALE",
    Latitude: "-32.3098",
    Longitude: "28.6806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "830",
    WardLookupId: "2977",
    VDNumber: "10840474",
    RegisteredPopulation: "620",
    VotingStationName: "LOWER GWADU JUNIOR SECONDARY SCHOOL",
    Address: "LOWER GWADU VILLAGE  FORT MALAN A/A  WILLOWVALE",
    Latitude: "-32.2204",
    Longitude: "28.6676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "831",
    WardLookupId: "2977",
    VDNumber: "10840485",
    RegisteredPopulation: "383",
    VotingStationName: "MSENGENI JUNIOR SECONDARY SCHOOL",
    Address: "NKELEKETHE VILLAGE  NKELEKETHE A/A  WILLOWVALE",
    Latitude: "-32.2198",
    Longitude: "28.6262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "832",
    WardLookupId: "2977",
    VDNumber: "10840553",
    RegisteredPopulation: "455",
    VotingStationName: "PATILIZWE JUNIOR SECONDARY SCHOOL",
    Address: "NQUMA VILLAGE  XONYENI A/A  WILLOWVALE",
    Latitude: "-32.2397",
    Longitude: "28.6443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "833",
    WardLookupId: "2977",
    VDNumber: "10840609",
    RegisteredPopulation: "515",
    VotingStationName: "SHIXINI PRIMARY SECONDARY SCHOOL",
    Address: "SHIXINI VILLAGE  XONYENI A/A  WILLOWVALE",
    Latitude: "-32.2601",
    Longitude: "28.6573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "834",
    WardLookupId: "2977",
    VDNumber: "10840698",
    RegisteredPopulation: "261",
    VotingStationName: "MBOZI JUNIOR SECONDARY SCHOOL",
    Address: "CIKO VILLAGE  MBOZI A/A  WILLOWVALE",
    Latitude: "-32.272",
    Longitude: "28.5831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "835",
    WardLookupId: "2977",
    VDNumber: "10841127",
    RegisteredPopulation: "292",
    VotingStationName: "JONGIKWEZI JUNIOR SECONDARY SCHOOL",
    Address: "VULANDI VILLAGE  DUMALISILE A/A  WILLOWVALE",
    Latitude: "-32.3166",
    Longitude: "28.6272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "836",
    WardLookupId: "2977",
    VDNumber: "10841138",
    RegisteredPopulation: "185",
    VotingStationName: "MHLABULUNGILE SENIOR PRIMARY SCHOOL",
    Address: "MABHELENI VILLAGE  XONYENI A/A  WILLOWVALE",
    Latitude: "-32.261",
    Longitude: "28.6148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "837",
    WardLookupId: "2977",
    VDNumber: "10841217",
    RegisteredPopulation: "136",
    VotingStationName: "XONYENI JUNIOR SECODARY SCHOOL",
    Address: "XONYENI VILLAGE  BHONXA AA  WILLOWVALE",
    Latitude: "-32.2309",
    Longitude: "28.6236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "838",
    WardLookupId: "2977",
    VDNumber: "10841239",
    RegisteredPopulation: "189",
    VotingStationName: "NOMAWAKA SENIOR PRIMARY SCHOOL",
    Address: "BADI VILLAGE   LOWER GWADU AA  WILLOWVALE",
    Latitude: "-32.2322",
    Longitude: "28.6749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "839",
    WardLookupId: "2978",
    VDNumber: "10750014",
    RegisteredPopulation: "1819",
    VotingStationName: "WILLOWVALE TOWN HALL",
    Address: "40 MAIN STREET  WILOWVALE  WILLOWVALE",
    Latitude: "-32.2653",
    Longitude: "28.5027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "840",
    WardLookupId: "2978",
    VDNumber: "10750025",
    RegisteredPopulation: "349",
    VotingStationName: "JONGINTABA J.S.S.",
    Address: "JONGINTABA LOC  WEZA A/A  WILLOWVALE",
    Latitude: "-32.2632",
    Longitude: "28.4864",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "841",
    WardLookupId: "2978",
    VDNumber: "10840159",
    RegisteredPopulation: "564",
    VotingStationName: "ZWELILUNGILE SENIOR PRIMARY SCHOOL",
    Address: "XOLINGADA VILLAGE  NQANQASHE A/A  WILLOWVALE",
    Latitude: "-32.2883",
    Longitude: "28.4846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "842",
    WardLookupId: "2978",
    VDNumber: "10840160",
    RegisteredPopulation: "497",
    VotingStationName: "GOSANI SENIOR PRIMARY SCHOOL",
    Address: "GOSANI VILLAGE  KHWANYANA A/A  WILLOWVALE",
    Latitude: "-32.2891",
    Longitude: "28.5155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "843",
    WardLookupId: "2978",
    VDNumber: "10840205",
    RegisteredPopulation: "325",
    VotingStationName: "CIKO SENIOR PRIMARY SCHOOL",
    Address: "CIKO LOCATION  CIKO A/A  WILLOWVALE",
    Latitude: "-32.2485",
    Longitude: "28.5774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "844",
    WardLookupId: "2978",
    VDNumber: "10841004",
    RegisteredPopulation: "184",
    VotingStationName: "TEMBISA JUNIOR SECONDARY SCHOOL",
    Address: "TEMBISA VILLAGE  BHONXA AA  WILLOWVA LE",
    Latitude: "-32.2517",
    Longitude: "28.5346",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "845",
    WardLookupId: "2978",
    VDNumber: "10841015",
    RegisteredPopulation: "539",
    VotingStationName: "BONGOLETHU-SENIOR-PRIMARY-SCHOOL",
    Address: "BONGWENI VILLAGE  BONGWENI A/A  WILLOWVALE",
    Latitude: "-32.2701",
    Longitude: "28.5219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "846",
    WardLookupId: "2978",
    VDNumber: "10841026",
    RegisteredPopulation: "172",
    VotingStationName: "MQOTWANE SENIOR PRIMARY SCHOOL",
    Address: "MQOTWANE VILLAGE  BONXA AA  WILLOWVALE",
    Latitude: "-32.2378",
    Longitude: "28.5544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "847",
    WardLookupId: "2978",
    VDNumber: "10841037",
    RegisteredPopulation: "206",
    VotingStationName: "NYOKANA SENIOR PRIMARY SCHOOL",
    Address: "NYOKANA VILLAGE  MBOZI A/A  WILLOWVALE",
    Latitude: "-32.2964",
    Longitude: "28.549",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "848",
    WardLookupId: "2979",
    VDNumber: "11650014",
    RegisteredPopulation: "396",
    VotingStationName: "SHINIRA JUNIOR SECONDARY SCHOOL",
    Address: "KOMKHULU VILLAGE  SHINIRA  A/A  ELLIOTDALE",
    Latitude: "-32.0347",
    Longitude: "28.8012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "849",
    WardLookupId: "2979",
    VDNumber: "11650137",
    RegisteredPopulation: "635",
    VotingStationName: "NGQWANGELE JUNIOR SECONDARY SCHOOL.",
    Address: "KULOGQWETHA VILLAGE  NTLONYANA A/A  ELLIOTDALE",
    Latitude: "-32.0718",
    Longitude: "28.8381",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "850",
    WardLookupId: "2979",
    VDNumber: "11650261",
    RegisteredPopulation: "707",
    VotingStationName: "NYANGILIZWE JUNIOR SECONDARY SCHOOL",
    Address: "KHOHLO VILLAGE  DABANE A/A  ELLIOTDALE",
    Latitude: "-32.0433",
    Longitude: "28.765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "851",
    WardLookupId: "2979",
    VDNumber: "11650373",
    RegisteredPopulation: "640",
    VotingStationName: "NGQATYANA JUNIOR SECONDARY SCHOOL",
    Address: "NGQATYANA VILLAGE  NGQATYANA A/A  ELLIOTDALE",
    Latitude: "-32.0573",
    Longitude: "28.8526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "852",
    WardLookupId: "2979",
    VDNumber: "11650698",
    RegisteredPopulation: "291",
    VotingStationName: "BOTWE BIG HOUSE",
    Address: "BOTWE VILLAGE  SHINIRA AA  ELLIOTDALE",
    Latitude: "-32.0199",
    Longitude: "28.8127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "853",
    WardLookupId: "2979",
    VDNumber: "11650700",
    RegisteredPopulation: "196",
    VotingStationName: "FUDUMELE SENIOR PRIMARY SCHOOL",
    Address: "NTLONYANE VILLAGE  SHINIRA AA  ELLIOTDALE",
    Latitude: "-32.0541",
    Longitude: "28.8191",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "854",
    WardLookupId: "2979",
    VDNumber: "11650711",
    RegisteredPopulation: "413",
    VotingStationName: "NOWONGA JUNIOR SECONDARY SCHOOL",
    Address: "PHUNGULA VILLAGE  NTLONYANA AA  ELLIOTDALE",
    Latitude: "-32.0704",
    Longitude: "28.8132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "855",
    WardLookupId: "2979",
    VDNumber: "11650968",
    RegisteredPopulation: "223",
    VotingStationName: "MPHAFENI BIG HOUSE",
    Address: "MPHAFENI VILLAGE   SHINIRA A/A  ELLIOTDALE",
    Latitude: "-32.0279",
    Longitude: "28.832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "856",
    WardLookupId: "2980",
    VDNumber: "10840182",
    RegisteredPopulation: "398",
    VotingStationName: "MFEZANE JUNIOR SECONDARY SCHOOL",
    Address: "MFEZANE VILLAGE  QHAKAZANA A/A  WILLOWVALE",
    Latitude: "-32.1971",
    Longitude: "28.5281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "857",
    WardLookupId: "2980",
    VDNumber: "10840193",
    RegisteredPopulation: "698",
    VotingStationName: "FORT MALAN SENIOR PRIMARY SCHOOL",
    Address: "FORTMALAN VILLAGE  NTSHATSONGO  A/A  WILLOWVALE",
    Latitude: "-32.1509",
    Longitude: "28.5192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "858",
    WardLookupId: "2980",
    VDNumber: "10840317",
    RegisteredPopulation: "392",
    VotingStationName: "LUBOMVINI JUNIOR SECONDARY SCHOOL",
    Address: "LUBOMVINI VILLAGE  NTSHATSHONGO A/A  WILLOWVALE",
    Latitude: "-32.1918",
    Longitude: "28.5752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "859",
    WardLookupId: "2980",
    VDNumber: "10840362",
    RegisteredPopulation: "508",
    VotingStationName: "UPPER GWADU JUNIOR SECONDARY SCHOOL",
    Address: "UPPER GWADU  VILLAGE  FORT MALAN A/A  WILLOWVALE",
    Latitude: "-32.1967",
    Longitude: "28.6271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "860",
    WardLookupId: "2980",
    VDNumber: "10840586",
    RegisteredPopulation: "393",
    VotingStationName: "TYWAKA JUNIOR SECONDARY SCHOOL",
    Address: "TYWAKA VILLAGE  NTSHATSHONGO A/A  WILLOWVALE",
    Latitude: "-32.164",
    Longitude: "28.5603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "861",
    WardLookupId: "2980",
    VDNumber: "10840867",
    RegisteredPopulation: "167",
    VotingStationName: "MHLOHLOZI SENIOR PRIMARY SCHOOL",
    Address: "MHLOHLOZI VILLAGE  QHAKAZANA A/A  WILLOWVALE",
    Latitude: "-32.1745",
    Longitude: "28.5386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "862",
    WardLookupId: "2980",
    VDNumber: "10840890",
    RegisteredPopulation: "356",
    VotingStationName: "NOKATANA JUNIOR SECONDARY SCHOOL",
    Address: "NOKATANA LOCATION  QHAKAZANA A/A   WILLOWVALE",
    Latitude: "-32.1493",
    Longitude: "28.5084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "863",
    WardLookupId: "2980",
    VDNumber: "10840991",
    RegisteredPopulation: "295",
    VotingStationName: "NKELEKETHE JUNIOR SECONDARY SCHOOL",
    Address: "LOKISHINI VILLAGE  NKELEKETHE A/A  WILLOWVALE",
    Latitude: "-32.215",
    Longitude: "28.5856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "864",
    WardLookupId: "2980",
    VDNumber: "10841419",
    RegisteredPopulation: "145",
    VotingStationName: "SICAM SENIOR PRIMARY SCHOOL",
    Address: "GXAKA-GXAKA VILLAGE  NTSHATSHONGO A/A  WILLOWVALE",
    Latitude: "-32.1324",
    Longitude: "28.5575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "865",
    WardLookupId: "2980",
    VDNumber: "10850587",
    RegisteredPopulation: "187",
    VotingStationName: "LAZAMAKHAKA JUNIOR SECONDARY SCHOOL",
    Address: "LAZAMAKHAKA VILLAGE  NQABARA A/A  IDUTYWA",
    Latitude: "-32.1338",
    Longitude: "28.4995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "866",
    WardLookupId: "2981",
    VDNumber: "11650025",
    RegisteredPopulation: "905",
    VotingStationName: "MQHELE JUNIOR SECONDARY SCHOOL",
    Address: "MTSHEKELWENI VILLAGE  MQHELE AA  ELLIOTDALE",
    Latitude: "-32.0225",
    Longitude: "28.8939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "867",
    WardLookupId: "2981",
    VDNumber: "11650272",
    RegisteredPopulation: "695",
    VotingStationName: "XHORA MOUTH JUNIOR SECONDARY SCHOOL",
    Address: "TSHEZI VILLAGE  XHORA MOUTH A/A  ELLIOTDALE",
    Latitude: "-32.1136",
    Longitude: "28.9827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "868",
    WardLookupId: "2981",
    VDNumber: "11650283",
    RegisteredPopulation: "545",
    VotingStationName: "MBUTYE SENIOR PRIMARY SCHOOL",
    Address: "MBUTYE VILLAGE  MBUTYE A/A   ELLIOTDALE",
    Latitude: "-32.0831",
    Longitude: "28.9309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "869",
    WardLookupId: "2981",
    VDNumber: "11650384",
    RegisteredPopulation: "382",
    VotingStationName: "MELIBUWA SENIOR PRIMARY SCHOOL",
    Address: "MGOTYWENI VILLAGE  XHORA MOUTH A/A  ELLIOTDALE",
    Latitude: "-32.1072",
    Longitude: "28.9461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "870",
    WardLookupId: "2981",
    VDNumber: "11650474",
    RegisteredPopulation: "583",
    VotingStationName: "MKATAZO JUNIOR SECONDARY SCHOOL",
    Address: "MKATAZO VILLAGE  MKATAZO A/A  ELLIOTDALE",
    Latitude: "-32.0624",
    Longitude: "28.9319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "871",
    WardLookupId: "2981",
    VDNumber: "11650755",
    RegisteredPopulation: "617",
    VotingStationName: "BAFAZI JUNIOR SECONDARY SCHOOL",
    Address: "KHAWULA VILLAGE  BAFAZI A/A  ELLIOTDALE",
    Latitude: "-32.0534",
    Longitude: "28.9176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "872",
    WardLookupId: "2981",
    VDNumber: "11650867",
    RegisteredPopulation: "398",
    VotingStationName: "THWALIKHULU SENIOR PRIMARY SCHOOL",
    Address: "NDITYA VILLAGE  MQHATHAZO A/A  ELLIOTDALE",
    Latitude: "-32.0891",
    Longitude: "28.9507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "873",
    WardLookupId: "2982",
    VDNumber: "10840430",
    RegisteredPopulation: "578",
    VotingStationName: "MEVANA SENIOR PRIMARY SCHOOL",
    Address: "MEVANA VILLAGE  NQABARA A/A  WILLOWVALE",
    Latitude: "-32.2493",
    Longitude: "28.674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "874",
    WardLookupId: "2982",
    VDNumber: "10840441",
    RegisteredPopulation: "257",
    VotingStationName: "MPUME JUNIOR SECONDARY SCHOOL",
    Address: "MPUMEVILLAGE  MSENDO A/A  WILLOWVALE",
    Latitude: "-32.284",
    Longitude: "28.7927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "875",
    WardLookupId: "2982",
    VDNumber: "10840452",
    RegisteredPopulation: "723",
    VotingStationName: "NQABARA JUNIOR SECONDARY SCHOOL",
    Address: "NQABARA VILLAGE   NQABARA A/A  WILLOWVALE",
    Latitude: "-32.2509",
    Longitude: "28.7276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "876",
    WardLookupId: "2982",
    VDNumber: "10840463",
    RegisteredPopulation: "321",
    VotingStationName: "NDUKU JUNIOR SECONDARY SCHOOL",
    Address: "MHLANGA VILLAGE  NQABARA A/A  WILLOWVALE",
    Latitude: "-32.237",
    Longitude: "28.6921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "877",
    WardLookupId: "2982",
    VDNumber: "10840508",
    RegisteredPopulation: "263",
    VotingStationName: "NGWANE JUNIOR SECONDARY SCHOOL",
    Address: "NGWANE VILLAGE  NQABARA A/A  WILLOWVALE",
    Latitude: "-32.2882",
    Longitude: "28.7499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "878",
    WardLookupId: "2982",
    VDNumber: "10840621",
    RegisteredPopulation: "242",
    VotingStationName: "NGQEZA SENIOR SECONDARY SCHOOL",
    Address: "NGQEZA VILLAGE  NQABARA A/A  WILLOWVALE",
    Latitude: "-32.3181",
    Longitude: "28.7682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "879",
    WardLookupId: "2982",
    VDNumber: "10841149",
    RegisteredPopulation: "188",
    VotingStationName: "KUNENE SENIOR PRIMARY SCHOOL",
    Address: "KUNENE VILLAGE  MEVANA A/A  WILLOWVALE",
    Latitude: "-32.2802",
    Longitude: "28.6654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "880",
    WardLookupId: "2982",
    VDNumber: "10841150",
    RegisteredPopulation: "181",
    VotingStationName: "MTOKWANE JUNIOR SECONDARY SCHOOL",
    Address: "MTOKWANA VILLAGE  NQABARA A/A  WILLOWVALE",
    Latitude: "-32.2758",
    Longitude: "28.7363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "881",
    WardLookupId: "2982",
    VDNumber: "10841183",
    RegisteredPopulation: "120",
    VotingStationName: "NKATHA SENIOR PRIMARY SCHOOL",
    Address: "NKATHA VILLAGE  MEVANA A/A  WILLOWVALE",
    Latitude: "-32.2724",
    Longitude: "28.6932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "882",
    WardLookupId: "2982",
    VDNumber: "10841240",
    RegisteredPopulation: "170",
    VotingStationName: "LOWER NDUKU JUNIOR SECONDARY SCHOOL",
    Address: "NDUKU LOCATION  MHLANGA AA  IDUTYWA",
    Latitude: "-32.2252",
    Longitude: "28.7042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "883",
    WardLookupId: "2982",
    VDNumber: "10841295",
    RegisteredPopulation: "350",
    VotingStationName: "NTUBENI JUNIOR SECONDARY SCHOOL",
    Address: "NTUBENI VILLAGE  MSENDO A/A  WILLOWVALE",
    Latitude: "-32.3084",
    Longitude: "28.8102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "884",
    WardLookupId: "2982",
    VDNumber: "10841352",
    RegisteredPopulation: "162",
    VotingStationName: "MSENDO CLINIC",
    Address: "MPUME VILLAGE  MPUME AA  WILLOWVALE",
    Latitude: "-32.2713",
    Longitude: "28.7899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "885",
    WardLookupId: "2982",
    VDNumber: "10841385",
    RegisteredPopulation: "145",
    VotingStationName: "NONDOBO JUNIOR SECONDARY SCHOOL",
    Address: "NONDOBO VILLAGE  NQABARA A/A  WILLOWVALE",
    Latitude: "-32.2716",
    Longitude: "28.7532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "886",
    WardLookupId: "2983",
    VDNumber: "10840104",
    RegisteredPopulation: "624",
    VotingStationName: "NTLABANE JUNIOR SECONDARY SCHOOL",
    Address: "GOTYIBENI VILLAGE  NTLABANE A/A  WILLOWVALE",
    Latitude: "-32.1943",
    Longitude: "28.4198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "887",
    WardLookupId: "2983",
    VDNumber: "10840115",
    RegisteredPopulation: "331",
    VotingStationName: "KULOKHALA SENIOR PRIMARY SCHOOL",
    Address: "KULOKHALA VILLAGE  NTLABANE  A/A  WILLOWVALE",
    Latitude: "-32.1908",
    Longitude: "28.4504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "888",
    WardLookupId: "2983",
    VDNumber: "10840126",
    RegisteredPopulation: "295",
    VotingStationName: "GANGATA JUNIOR SECONDARY SCHOOL",
    Address: "GANGATHA VILLAGE  NTLABANE A/A   WILLOWVALE",
    Latitude: "-32.2145",
    Longitude: "28.4438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "889",
    WardLookupId: "2983",
    VDNumber: "10840137",
    RegisteredPopulation: "773",
    VotingStationName: "MBOYA JUNIOR SECONDARY SCHOOL",
    Address: "MBOYA VILLAGE  NTLABANE A/A  WILLOWVALE",
    Latitude: "-32.2346",
    Longitude: "28.468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "890",
    WardLookupId: "2983",
    VDNumber: "10840597",
    RegisteredPopulation: "448",
    VotingStationName: "KULOZULU JUNIOR SECONDARY SCHOOL",
    Address: "MATOLWENI VILLAGE  NQADU A/A  WILLOWVALE",
    Latitude: "-32.2172",
    Longitude: "28.4252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "891",
    WardLookupId: "2983",
    VDNumber: "10840643",
    RegisteredPopulation: "292",
    VotingStationName: "WEZA JUNIOR SECONDARY SCHOOL",
    Address: "KOMKHULU VILLAGE  WEZA A/A  WILLOWVALE",
    Latitude: "-32.26",
    Longitude: "28.4708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "892",
    WardLookupId: "2983",
    VDNumber: "10841374",
    RegisteredPopulation: "356",
    VotingStationName: "MANDLUNTSHA JUNIOR SECONDARY SCHOOL",
    Address: "MBOYA  VILLAGE  NTLABANE  AA  WILLOWVALE",
    Latitude: "-32.2394",
    Longitude: "28.5028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "893",
    WardLookupId: "2983",
    VDNumber: "10851061",
    RegisteredPopulation: "555",
    VotingStationName: "TSWELELITYE JUNIOR SECONDARY SCHOOL",
    Address: "TSWELENI VILLAGE  TALENI A/A   IDUTYWA",
    Latitude: "-32.1879",
    Longitude: "28.3966",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "894",
    WardLookupId: "2984",
    VDNumber: "10850105",
    RegisteredPopulation: "621",
    VotingStationName: "DOUGLAS SENIOR PRIMARY SCHOOL",
    Address: "MAZIZINI  VILLAGE  ESIKHOBENI A/A  IDUTYWA",
    Latitude: "-32.0376",
    Longitude: "28.0692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "895",
    WardLookupId: "2984",
    VDNumber: "10850116",
    RegisteredPopulation: "633",
    VotingStationName: "KETI JUNIOR SECONDARY SCHOOL",
    Address: "ELALINI  VILLAGE   KETI VILLAGE  IDUTYWA",
    Latitude: "-32.0421",
    Longitude: "28.1154",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "896",
    WardLookupId: "2984",
    VDNumber: "10850127",
    RegisteredPopulation: "295",
    VotingStationName: "ZIWUNDWANA JUNIOR SECONDARY SCHOOL",
    Address: "NABILEYO VILLAGE  ZIWUNDWANA AA  IDUTYWA",
    Latitude: "-32.0179",
    Longitude: "28.1453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "897",
    WardLookupId: "2984",
    VDNumber: "10850138",
    RegisteredPopulation: "529",
    VotingStationName: "THORNVILLE JUNIOR SECONDARY SCHOOL",
    Address: "THORNVILLE VILLAGE  MQONCI  AA  DUTYWA",
    Latitude: "-31.9785",
    Longitude: "28.1779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "898",
    WardLookupId: "2984",
    VDNumber: "10850150",
    RegisteredPopulation: "497",
    VotingStationName: "EWING JUNIOR SECONDARY SCHOOL",
    Address: "MBEWULENI  VILLAGE  MBEWULENI A/A  IDUTYWA",
    Latitude: "-32.0285",
    Longitude: "28.2073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "899",
    WardLookupId: "2984",
    VDNumber: "10851083",
    RegisteredPopulation: "517",
    VotingStationName: "MHLABENI JUNIOR SECONDARY SCHOOL",
    Address: "MHLABENI VILLAGE  ZIWUNDWANA AA  IDUTYWA",
    Latitude: "-32.0068",
    Longitude: "28.1656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "900",
    WardLookupId: "2984",
    VDNumber: "10851207",
    RegisteredPopulation: "229",
    VotingStationName: "VUYISANANI CAFE",
    Address: "MBEWANA VILLAGE  ZIWUNDWANA  AA  DUTYWA",
    Latitude: "-32.0225",
    Longitude: "28.1264",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "901",
    WardLookupId: "2984",
    VDNumber: "11570802",
    RegisteredPopulation: "351",
    VotingStationName: "PHUMLANI JUNIOR SECONDARY SCHOOL",
    Address: "LUTHUTHU VILLAGE  CHABA AA  IDUTYWA",
    Latitude: "-32.0338",
    Longitude: "28.0549",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "902",
    WardLookupId: "2985",
    VDNumber: "11650115",
    RegisteredPopulation: "1599",
    VotingStationName: "URCSA MADWALENI CHURCH HALL",
    Address: "MADWALENI VILLAGE  GUSI A/A  ELLIOTDALE",
    Latitude: "-32.0963",
    Longitude: "28.8801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "903",
    WardLookupId: "2985",
    VDNumber: "11650160",
    RegisteredPopulation: "961",
    VotingStationName: "QATYWA JUNIOR SECONDARY SCHOOL",
    Address: "QATYWA VILLAGE  NKANYA A/A  ELLIOTDALE",
    Latitude: "-32.1591",
    Longitude: "28.9649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "904",
    WardLookupId: "2985",
    VDNumber: "11650216",
    RegisteredPopulation: "1096",
    VotingStationName: "NGUBEZULU TRADITIONAL COUNCIL",
    Address: "KULONGXELESHE VILLAGE  GUSI A/A  ELLIOTDALE",
    Latitude: "-32.1322",
    Longitude: "28.9074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "905",
    WardLookupId: "2985",
    VDNumber: "11650395",
    RegisteredPopulation: "682",
    VotingStationName: "TONGANI JUNIOR SECONDARY SCHOOL",
    Address: "KWAZIMTSHE VILLAGE  NKANYA A/A  ELLIOTDALE",
    Latitude: "-32.1571",
    Longitude: "28.9481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "906",
    WardLookupId: "2985",
    VDNumber: "11650812",
    RegisteredPopulation: "619",
    VotingStationName: "VUYISILE SENIOR PRIMARY SCHOOL",
    Address: "KWA TSHUTSHA VILLAGE  GUSI A/A  ELLIOTDALE",
    Latitude: "-32.1265",
    Longitude: "28.9194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "907",
    WardLookupId: "570",
    VDNumber: "10570014",
    RegisteredPopulation: "2404",
    VotingStationName: "TOWN HALL",
    Address: "CORNER UMTATA & KING STREET  BUTTERWORTH  BUTTERWORTH",
    Latitude: "-32.3326",
    Longitude: "28.1451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "908",
    WardLookupId: "570",
    VDNumber: "10570148",
    RegisteredPopulation: "572",
    VotingStationName: "CHRISTIAN CENTRE",
    Address: "EXT 6  BUTTERWORTH EXT 6  BUTTERWORTH",
    Latitude: "-32.3244",
    Longitude: "28.142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "909",
    WardLookupId: "570",
    VDNumber: "10570160",
    RegisteredPopulation: "885",
    VotingStationName: "AM BAM JUNIOR SECONDARY SCHOOL",
    Address: "EXT 7  BUTTERWORTH",
    Latitude: "-32.3357",
    Longitude: "28.1401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "910",
    WardLookupId: "570",
    VDNumber: "10790311",
    RegisteredPopulation: "615",
    VotingStationName: "BUTTERWORTH REVIVAL CHRISTIAN SCHOOL",
    Address: "BUNGENI SQUATER CAMP  BUTTERWORTH",
    Latitude: "-32.3225",
    Longitude: "28.1514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "911",
    WardLookupId: "571",
    VDNumber: "10570025",
    RegisteredPopulation: "1369",
    VotingStationName: "ST CHARDS ANGLICAN CHURCH",
    Address: "02 MNYONI ROAD  COLOURED T/SHIP  BUTTERWORTH",
    Latitude: "-32.327",
    Longitude: "28.1538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "912",
    WardLookupId: "571",
    VDNumber: "10570081",
    RegisteredPopulation: "1818",
    VotingStationName: "NOZUKO CLINIC TENT",
    Address: "MSOBOMVU LOCATION  MSOBOMVU   BUTTERWORTH",
    Latitude: "-32.3235",
    Longitude: "28.1678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "913",
    WardLookupId: "571",
    VDNumber: "10790063",
    RegisteredPopulation: "1061",
    VotingStationName: "SIYANDA JUNIOR SECONDARY SCHOOL",
    Address: "SIYANDA SQUARTERS  SIYANDA INFORMAL SETTLEMENT  BUTTERWORTH",
    Latitude: "-32.3179",
    Longitude: "28.1604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "914",
    WardLookupId: "572",
    VDNumber: "10570036",
    RegisteredPopulation: "1872",
    VotingStationName: "MSOBOMVU COMMUNITY HALL",
    Address: "MSOBOMVU  MSOBOMVU T/SHIP  BUTTERWORTH",
    Latitude: "-32.324",
    Longitude: "28.1784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "915",
    WardLookupId: "572",
    VDNumber: "10570092",
    RegisteredPopulation: "2106",
    VotingStationName: "SW MBANGA SCHOOL",
    Address: "MCUBAKAZI  MCUBAKAZI T/SHIP  BUTTERWORTH",
    Latitude: "-32.3208",
    Longitude: "28.1906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "916",
    WardLookupId: "573",
    VDNumber: "10570058",
    RegisteredPopulation: "2321",
    VotingStationName: "NOLITHA COMPREHENSIVE TECH HIGH",
    Address: "CUBA TOWNSHIP  CUBA   BUTTERWORTH",
    Latitude: "-32.3168",
    Longitude: "28.2046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "917",
    WardLookupId: "573",
    VDNumber: "10570070",
    RegisteredPopulation: "1064",
    VotingStationName: "HOLY WISDOM ANGLICAN CHURCH",
    Address: "CUBA TOWNSHIP  CUBA  BUTTERWORTH",
    Latitude: "-32.3139",
    Longitude: "28.2088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "918",
    WardLookupId: "573",
    VDNumber: "10790388",
    RegisteredPopulation: "864",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "MCHUBAKAZI TOWNSHIP  MCHUBAKAZI  BUTTERWORTH",
    Latitude: "-32.319",
    Longitude: "28.1864",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "919",
    WardLookupId: "574",
    VDNumber: "10570069",
    RegisteredPopulation: "1423",
    VotingStationName: "ZIZAMELE JUNIOR SECONDARY SCHOOL",
    Address: "ZIZAMELE T/SHIP  ZIZAMELE  BUTTERWORTH",
    Latitude: "-32.3325",
    Longitude: "28.1938",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "920",
    WardLookupId: "574",
    VDNumber: "10570104",
    RegisteredPopulation: "1173",
    VotingStationName: "MZAMOMHLE JUNIOR SECONDARY SCHOOL",
    Address: "MADIBA SQUATER CAMP  BUTTERWORTH",
    Latitude: "-32.309",
    Longitude: "28.223",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "921",
    WardLookupId: "574",
    VDNumber: "10570137",
    RegisteredPopulation: "553",
    VotingStationName: "HIGH VIEW PRIMARY SCHOOL",
    Address: "ZIZAMELE  ZIZAMELE TOWNSHIP  BUTTERWORTH",
    Latitude: "-32.3321",
    Longitude: "28.2005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "922",
    WardLookupId: "574",
    VDNumber: "10570171",
    RegisteredPopulation: "607",
    VotingStationName: "IBIKA TENT",
    Address: "IBIKA TOWNSHIP  IBIKA   BUTTERWORTH",
    Latitude: "-32.3057",
    Longitude: "28.2122",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "923",
    WardLookupId: "574",
    VDNumber: "10790085",
    RegisteredPopulation: "279",
    VotingStationName: "MAYEKISO TENT",
    Address: "MAYEKISO FARM AREA  MAYEKISO LOCATION  BUTTERWORTH",
    Latitude: "-32.3322",
    Longitude: "28.2387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "924",
    WardLookupId: "574",
    VDNumber: "10790771",
    RegisteredPopulation: "451",
    VotingStationName: "KING HINTSA FET COLLEGE - TEKO CAMPUS",
    Address: "TEKO VOCATIONAL  TEKO A/A  CENTANE",
    Latitude: "-32.3522",
    Longitude: "28.2455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "925",
    WardLookupId: "575",
    VDNumber: "10570115",
    RegisteredPopulation: "966",
    VotingStationName: "KULILE JUNIOR SECONDARY SCHOOL",
    Address: "IBIKA  IBIKA TOWNSHIP  BUTTERWORTH",
    Latitude: "-32.3028",
    Longitude: "28.2119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "926",
    WardLookupId: "575",
    VDNumber: "10790131",
    RegisteredPopulation: "2274",
    VotingStationName: "EASTERN CAPE TECHNIKON",
    Address: "IBIKA  IBIKA TOWNSHIP  BUTTERWORTH",
    Latitude: "-32.3002",
    Longitude: "28.2047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "927",
    WardLookupId: "575",
    VDNumber: "10790614",
    RegisteredPopulation: "592",
    VotingStationName: "ZANOKHANYO JUNIOR SECONDARY SCHOOL",
    Address: "MCUBAKAZI LOCATION  ZAZULWANA  BUTTERWORTH",
    Latitude: "-32.3094",
    Longitude: "28.1757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "928",
    WardLookupId: "575",
    VDNumber: "10790715",
    RegisteredPopulation: "374",
    VotingStationName: "HIGHWAY LODGE",
    Address: "SIYANDA SQUATER CAMP  SIYANDA  BUTTERWORTH",
    Latitude: "-32.3174",
    Longitude: "28.1708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "929",
    WardLookupId: "576",
    VDNumber: "10570047",
    RegisteredPopulation: "1292",
    VotingStationName: "EMQHAPHINI JUNIOR SECONDARY SCHOOL",
    Address: "IBIKA TOWNSHIP  IBIKA  BUTTERWORTH",
    Latitude: "-32.2986",
    Longitude: "28.2135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "930",
    WardLookupId: "576",
    VDNumber: "10790119",
    RegisteredPopulation: "471",
    VotingStationName: "JF MTSHIKI JUNIOR SECONDARY SCHOOL",
    Address: "MNCUNCUZO LOCATION  MGOMANZI A/A  BUTTERWORTH",
    Latitude: "-32.2782",
    Longitude: "28.1059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "931",
    WardLookupId: "576",
    VDNumber: "10790120",
    RegisteredPopulation: "89",
    VotingStationName: "JAMES NGUZA MAWUSHENI TENT",
    Address: "MAWUSHENI LOCATION  MGAGASI A/A  BUTTERWORTH",
    Latitude: "-32.2684",
    Longitude: "28.1593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "932",
    WardLookupId: "576",
    VDNumber: "10790300",
    RegisteredPopulation: "520",
    VotingStationName: "BULUBE JUNIOR SECONDARY SCHOOL",
    Address: "TANGA LOCATION  TANGA A/A  BUTTERWORTH",
    Latitude: "-32.2502",
    Longitude: "28.183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "933",
    WardLookupId: "576",
    VDNumber: "10790322",
    RegisteredPopulation: "587",
    VotingStationName: "IZAGWITYI JUNIOR SECONDARY SCHOOL",
    Address: "IZAGWITYI LOCATION  IZAGWITYI A/A  BUTTERWORTH",
    Latitude: "-32.3046",
    Longitude: "28.1025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "934",
    WardLookupId: "576",
    VDNumber: "10790377",
    RegisteredPopulation: "394",
    VotingStationName: "VELDMAN JUNIOR SECONDARY SCHOOL",
    Address: "ZAZULWANA LOCATION  ZAZULWANA A/A  BUTTERWORTH",
    Latitude: "-32.2951",
    Longitude: "28.1579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "935",
    WardLookupId: "576",
    VDNumber: "10790490",
    RegisteredPopulation: "548",
    VotingStationName: "IBIKA JUNIOR SECONDARY SCHOOL",
    Address: "IBIKA TOWNSHIP  IBIKA   BUTTERWORTH",
    Latitude: "-32.2918",
    Longitude: "28.1993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "936",
    WardLookupId: "576",
    VDNumber: "10790782",
    RegisteredPopulation: "170",
    VotingStationName: "SOPAKAMA JUNIOR SECONDARY SCHOOL",
    Address: "MGAGASI R6 LOCATION   MGAGASI A/A  BUTTERWORTH",
    Latitude: "-32.2674",
    Longitude: "28.1231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "937",
    WardLookupId: "577",
    VDNumber: "10790030",
    RegisteredPopulation: "418",
    VotingStationName: "LUKHANYO SECONDARY PRIMARY SCHOOL",
    Address: "MISSION LOCATION  NGXALATI A/A  BUTTERWORTH",
    Latitude: "-32.3265",
    Longitude: "28.1146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "938",
    WardLookupId: "577",
    VDNumber: "10790041",
    RegisteredPopulation: "705",
    VotingStationName: "MGOMANZI JUNIOR SECONDARY SCHOOL",
    Address: "R3 LOCATION  MGOMANZI A/A  BUTTERWORTH",
    Latitude: "-32.2906",
    Longitude: "28.0673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "939",
    WardLookupId: "577",
    VDNumber: "10790153",
    RegisteredPopulation: "499",
    VotingStationName: "CEGCUWANA JUNIOR SECONDARY SCHOOL",
    Address: "CEGCUWANA LOCATION  CEGCUWANA A/A  BUTTERWORTH",
    Latitude: "-32.3381",
    Longitude: "28.0434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "940",
    WardLookupId: "577",
    VDNumber: "10790333",
    RegisteredPopulation: "269",
    VotingStationName: "UPPER MGOMANZI JUNIOR SECONDARY SCHOOL",
    Address: "R4 LOCATION  MGOMANZI A/A  BUTTERWORTH",
    Latitude: "-32.2698",
    Longitude: "28.0367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "941",
    WardLookupId: "577",
    VDNumber: "10790366",
    RegisteredPopulation: "264",
    VotingStationName: "GUGULETHU SENIOR PRIMARY SCHOOL",
    Address: "MGOMANZI A/A  BUTTERWORTH",
    Latitude: "-32.3021",
    Longitude: "28.0443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "942",
    WardLookupId: "577",
    VDNumber: "10790579",
    RegisteredPopulation: "396",
    VotingStationName: "ZAZINI SENIOR PRIMARY SCHOOL",
    Address: "ZAZINI LOCATION  CEGCUWANA A/A  BUTTERWORTH",
    Latitude: "-32.3374",
    Longitude: "28.0575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "943",
    WardLookupId: "577",
    VDNumber: "10790580",
    RegisteredPopulation: "444",
    VotingStationName: "UPPER CERU JUNIOR SECONDARY SCHOOL",
    Address: "DYUSHU LOCATION  CERU A/A  BUTTERWORTH",
    Latitude: "-32.3617",
    Longitude: "28.0484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "944",
    WardLookupId: "577",
    VDNumber: "10790681",
    RegisteredPopulation: "286",
    VotingStationName: "NDABANKULU SENIOR SECONDARY SCHOOL",
    Address: "CEGCUWANA LOCATION  CEGCUWANA A/A  BUTTERWORTH",
    Latitude: "-32.3471",
    Longitude: "28.0301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "945",
    WardLookupId: "577",
    VDNumber: "10790726",
    RegisteredPopulation: "207",
    VotingStationName: "G S MAGOBIANE JUNIOR SECONDARY SCHOOL",
    Address: "NDENXE LOCATION  CEGCUWANA A/A  BUTTERWORTH",
    Latitude: "-32.3308",
    Longitude: "28.0147",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "946",
    WardLookupId: "577",
    VDNumber: "10790748",
    RegisteredPopulation: "281",
    VotingStationName: "KANYISA JUNIOR SECONDARY SCHOOL",
    Address: "NTLAMBONKULU LOCATION  IZAGWITYI A/A  BUTTERWORTH",
    Latitude: "-32.2753",
    Longitude: "28.073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "947",
    WardLookupId: "577",
    VDNumber: "10790793",
    RegisteredPopulation: "207",
    VotingStationName: "NONGONGOMANE METHODIST CHURCH",
    Address: "GOSO R5 LOCATION  MGOMANZI A/A  BUTTERWORTH",
    Latitude: "-32.28",
    Longitude: "28.0245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "948",
    WardLookupId: "578",
    VDNumber: "10790052",
    RegisteredPopulation: "1114",
    VotingStationName: "DAVIES JUNIOR SECONDARY SCHOOL",
    Address: "MISSION A/A  BUTTERWORTH",
    Latitude: "-32.3434",
    Longitude: "28.1313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "949",
    WardLookupId: "578",
    VDNumber: "10790164",
    RegisteredPopulation: "364",
    VotingStationName: "CERU JUNIOR SECONDARY SCHOOL",
    Address: "CERU A/A  BUTTERWORTH",
    Latitude: "-32.374",
    Longitude: "28.0724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "950",
    WardLookupId: "578",
    VDNumber: "10790175",
    RegisteredPopulation: "311",
    VotingStationName: "MPENDUZA JUNIOR SECONDARY SCHOOL",
    Address: "MPENDUZA A/A  BUTTERWORTH",
    Latitude: "-32.362",
    Longitude: "28.0989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "951",
    WardLookupId: "578",
    VDNumber: "10790287",
    RegisteredPopulation: "465",
    VotingStationName: "EMPUMALANGA JUNIOR SECONDARY SCHOOL",
    Address: "MPENDUZA A/A  BUTTERWORTH",
    Latitude: "-32.3801",
    Longitude: "28.1275",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "952",
    WardLookupId: "578",
    VDNumber: "10790298",
    RegisteredPopulation: "824",
    VotingStationName: "TOBOTSHANA JUNIOR SECONDARY SCHOOL",
    Address: "TOBOTSHANA A/A  TOBOSHANA A/A  BUTTERWORTH",
    Latitude: "-32.3533",
    Longitude: "28.1467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "953",
    WardLookupId: "578",
    VDNumber: "10790535",
    RegisteredPopulation: "292",
    VotingStationName: "LOWER TOBOTSHANA SCHOOL",
    Address: "TOBOSHANA LOCATION  LOWER TOBOTSHANA A/A  BUTTERWORTH",
    Latitude: "-32.3691",
    Longitude: "28.1454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "954",
    WardLookupId: "578",
    VDNumber: "10790603",
    RegisteredPopulation: "438",
    VotingStationName: "MZAZI SENIOR PRIMARY SCHOOL",
    Address: "MZAZI LOCATION  MPENDUZA A/A  BUTTERWORTH",
    Latitude: "-32.3608",
    Longitude: "28.0826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "955",
    WardLookupId: "579",
    VDNumber: "10790029",
    RegisteredPopulation: "453",
    VotingStationName: "TONGWANE JUNIOR SECONDARY SCHOOL",
    Address: "MTULU A/A  BUTTERWORTH",
    Latitude: "-32.4386",
    Longitude: "28.0658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "956",
    WardLookupId: "579",
    VDNumber: "10790096",
    RegisteredPopulation: "549",
    VotingStationName: "DALUHLANGA SENIOR SECONDARY SCHOOL",
    Address: "MTINTSILANA A/A  BUTTERWORTH  BUTTERWORTH",
    Latitude: "-32.3938",
    Longitude: "28.0661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "957",
    WardLookupId: "579",
    VDNumber: "10790210",
    RegisteredPopulation: "443",
    VotingStationName: "VUKANI JUNIOR SECONDARY SCHOOL",
    Address: "ZANGWA A/A  BUTTERWORTH",
    Latitude: "-32.418",
    Longitude: "28.0101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "958",
    WardLookupId: "579",
    VDNumber: "10790276",
    RegisteredPopulation: "517",
    VotingStationName: "MQAMBELI JUNIOR SECONDARY SCHOOL",
    Address: "MQAMBELI A/A  BUTTERWORTH",
    Latitude: "-32.4229",
    Longitude: "28.11",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "959",
    WardLookupId: "579",
    VDNumber: "10790344",
    RegisteredPopulation: "272",
    VotingStationName: "BAWA JUNIOR SECONDARY SCHOOL",
    Address: "BAWA A/A  BUTTERWORTH",
    Latitude: "-32.4043",
    Longitude: "28.0986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "960",
    WardLookupId: "579",
    VDNumber: "10790399",
    RegisteredPopulation: "249",
    VotingStationName: "MKRWAQA JUNIOR SECONDARY SCHOOL",
    Address: "TONGWANA A/A  TONGWANA LOC.  BUTTERWORTH",
    Latitude: "-32.4643",
    Longitude: "28.0991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "961",
    WardLookupId: "579",
    VDNumber: "10790401",
    RegisteredPopulation: "259",
    VotingStationName: "MTINTSILANA JUNIOR SECONDARY SCHOOL",
    Address: "MTINTSILANA  BUTTERWORTH",
    Latitude: "-32.396",
    Longitude: "28.0563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "962",
    WardLookupId: "579",
    VDNumber: "10790513",
    RegisteredPopulation: "261",
    VotingStationName: "BHELENI JUNIOR SECONDARY SCHOOL",
    Address: "JOJWENI  NDABAKAZI  BUTTERWORTH",
    Latitude: "-32.413",
    Longitude: "28.0439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "963",
    WardLookupId: "579",
    VDNumber: "10790524",
    RegisteredPopulation: "251",
    VotingStationName: "NDOTSHANGA JUNIOR SECONDARY SCHOOL",
    Address: "NDOTSHANGA LOCATION  BAWA A/A  BUTTERWORTH",
    Latitude: "-32.3912",
    Longitude: "28.1143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "964",
    WardLookupId: "579",
    VDNumber: "10790557",
    RegisteredPopulation: "147",
    VotingStationName: "MZANTSI SENIOR PRIMARY SCHOOL",
    Address: "MZINJANE SENIOR PRIMARY SCHOOL  MQAMBELI A/A  BUTTERWORTH",
    Latitude: "-32.4439",
    Longitude: "28.1082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "965",
    WardLookupId: "579",
    VDNumber: "10790669",
    RegisteredPopulation: "129",
    VotingStationName: "VULUHLANGA JUNIOR SECONDARY SCHOOL",
    Address: "MZINJANA LOCATION  MQAMBELI A/A  BUTTERWORTH",
    Latitude: "-32.4259",
    Longitude: "28.0422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "966",
    WardLookupId: "580",
    VDNumber: "10790108",
    RegisteredPopulation: "362",
    VotingStationName: "MACLAY JUNIOR SECONDARY SCHOOL",
    Address: "XAXASHIMBA A/A  BUTTERWORTH",
    Latitude: "-32.4607",
    Longitude: "28.0829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "967",
    WardLookupId: "580",
    VDNumber: "10790221",
    RegisteredPopulation: "513",
    VotingStationName: "NGQUTU JUNIOR SECONDARY SCHOOL",
    Address: "NGQUTU A/A  BUTTERWORTH",
    Latitude: "-32.4427",
    Longitude: "27.9376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "968",
    WardLookupId: "580",
    VDNumber: "10790232",
    RegisteredPopulation: "298",
    VotingStationName: "MAHLUBINI JUNIOR SECONDARY SCHOOL",
    Address: "MAHLUBINI LOCATION  MAHLUBINI A/A  BUTTERWORTH",
    Latitude: "-32.4642",
    Longitude: "27.9768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "969",
    WardLookupId: "580",
    VDNumber: "10790243",
    RegisteredPopulation: "332",
    VotingStationName: "NONKQUBELA SENIOR PRIMARY SCHOOL",
    Address: "BUMBANE LOCATION  KOBODI A/A  BUTTERWORTH",
    Latitude: "-32.4573",
    Longitude: "28.0507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "970",
    WardLookupId: "580",
    VDNumber: "10790254",
    RegisteredPopulation: "410",
    VotingStationName: "XAXASHIMBA JUNIOR SECONDARY SCHOOL",
    Address: "XAXASHIMBA A/A  BUTTERWORTH",
    Latitude: "-32.5017",
    Longitude: "28.0838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "971",
    WardLookupId: "580",
    VDNumber: "10790265",
    RegisteredPopulation: "330",
    VotingStationName: "DIYA JUNIOR SECONDARY SCHOOL",
    Address: "DIYA A/A  BUTTERWORTH",
    Latitude: "-32.5067",
    Longitude: "28.1695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "972",
    WardLookupId: "580",
    VDNumber: "10790355",
    RegisteredPopulation: "270",
    VotingStationName: "DEPLU JUNIOR SECONDARY SCHOOL",
    Address: "N/A  KWA-DLEPU VILLAGE  BUTTERWORTH",
    Latitude: "-32.4795",
    Longitude: "28.0323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "973",
    WardLookupId: "580",
    VDNumber: "10790445",
    RegisteredPopulation: "253",
    VotingStationName: "FUNDISA JUNIOR SECONDARY SCHOOL",
    Address: "ZANGWA A/A  BUTTERWORTH",
    Latitude: "-32.444",
    Longitude: "28.0395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "974",
    WardLookupId: "580",
    VDNumber: "10790456",
    RegisteredPopulation: "614",
    VotingStationName: "MANQULO JUNIOR SECONDARY SCHOOL",
    Address: "MANQULO LOCATION  MANQULO A/A  BUTTERWORTH",
    Latitude: "-32.4608",
    Longitude: "28.0054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "975",
    WardLookupId: "580",
    VDNumber: "10790467",
    RegisteredPopulation: "183",
    VotingStationName: "MBONGENDLU SENIOR PRIMARY SCHOOL",
    Address: "KOBODI A/A  BUTTERWORTH",
    Latitude: "-32.5025",
    Longitude: "28.0573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "976",
    WardLookupId: "580",
    VDNumber: "10790478",
    RegisteredPopulation: "137",
    VotingStationName: "NOMPUCUKO SENIOR SECONDARY SCHOOL",
    Address: "EDRAYINI LOCATION  DIYA A/A  BUTTERWORTH",
    Latitude: "-32.4941",
    Longitude: "28.1432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "977",
    WardLookupId: "580",
    VDNumber: "10790489",
    RegisteredPopulation: "265",
    VotingStationName: "SPRINGS JUNIOR SECONDARY SCHOOL",
    Address: "GWANE  DYOSINI A/A  BUTTERWORTH",
    Latitude: "-32.4761",
    Longitude: "28.1139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "978",
    WardLookupId: "580",
    VDNumber: "10790502",
    RegisteredPopulation: "83",
    VotingStationName: "ZIBHITYOLO SENIOR PRIMARY SCHOOL",
    Address: "ZIBHITYOLO LOCATION  NQUTU A/A  BUTTERWORTH",
    Latitude: "-32.4473",
    Longitude: "27.8975",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "979",
    WardLookupId: "580",
    VDNumber: "10790625",
    RegisteredPopulation: "121",
    VotingStationName: "SAMARIA SENIOR PRIMARY SCHOOL",
    Address: "KWADLEPU LOCATION  DLEPU A/A  BUTTERWORTH",
    Latitude: "-32.504",
    Longitude: "28.0011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "980",
    WardLookupId: "580",
    VDNumber: "10790670",
    RegisteredPopulation: "65",
    VotingStationName: "SESIMENI TENT",
    Address: "SISEMENI LOCATION  NGQUTHU A/A  BUTTERWORTH",
    Latitude: "-32.451",
    Longitude: "27.9216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "981",
    WardLookupId: "580",
    VDNumber: "10790737",
    RegisteredPopulation: "140",
    VotingStationName: "NTABITSOLO JUNIOR SECONDARY SCHOOL",
    Address: "ENCIBA LOCATION  NGQUTU A/A  BUTTERWORTH",
    Latitude: "-32.4665",
    Longitude: "27.9396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "982",
    WardLookupId: "581",
    VDNumber: "10790074",
    RegisteredPopulation: "467",
    VotingStationName: "QEQE JUNIOR SECONDARY SCHOOL",
    Address: "QEQE LOCATION  QEQE A/A  BUTTERWORTH",
    Latitude: "-32.3883",
    Longitude: "27.9352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "983",
    WardLookupId: "581",
    VDNumber: "10790186",
    RegisteredPopulation: "700",
    VotingStationName: "TOLENI JUNIOR SECONDARY SCHOOL",
    Address: "NGOBOZI LOCATION  TOLENI A/A  BUTTERWORTH",
    Latitude: "-32.3572",
    Longitude: "27.9831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "984",
    WardLookupId: "581",
    VDNumber: "10790197",
    RegisteredPopulation: "379",
    VotingStationName: "NDABAKAZI JUNIOR SECONDARY SCHOOL",
    Address: "TOLENI LOCATION  TOLENI A/A  BUTTERWORTH",
    Latitude: "-32.392",
    Longitude: "27.9989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "985",
    WardLookupId: "581",
    VDNumber: "10790209",
    RegisteredPopulation: "368",
    VotingStationName: "SAWUTANA JUNIOR SECONDARY SCHOOL",
    Address: "TOLENI LOCATION  TOLENI A/A  BUTTERWORTH",
    Latitude: "-32.4213",
    Longitude: "27.9649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "986",
    WardLookupId: "581",
    VDNumber: "10790423",
    RegisteredPopulation: "494",
    VotingStationName: "LENGENI JUNIOR SECONDARY SCHOOL",
    Address: "TOLENI A/A  BUTTERWORTH",
    Latitude: "-32.3656",
    Longitude: "28.0323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "987",
    WardLookupId: "581",
    VDNumber: "10790434",
    RegisteredPopulation: "476",
    VotingStationName: "NKULULEKO JUNIOR SECONDARY SCHOOL",
    Address: "MAMBENDENI LOCATION  TOLENI A/A  BUTTERWORTH",
    Latitude: "-32.3686",
    Longitude: "28.0026",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "988",
    WardLookupId: "581",
    VDNumber: "10790568",
    RegisteredPopulation: "441",
    VotingStationName: "DALUKHANYO SENIOR PRIMARY SCHOOL",
    Address: "TOLENI LOCATION  TOLENI A/A  BUTTERWORTH",
    Latitude: "-32.3948",
    Longitude: "27.9824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "989",
    WardLookupId: "581",
    VDNumber: "10790636",
    RegisteredPopulation: "111",
    VotingStationName: "NGXAKHULU PRE SCHOOL",
    Address: "NGXAKHULU LOCATION  NGQUTHU A/A  BUTTERWORTH",
    Latitude: "-32.3883",
    Longitude: "27.8972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "990",
    WardLookupId: "581",
    VDNumber: "10790692",
    RegisteredPopulation: "336",
    VotingStationName: "NOZULU SENIOR PRIMARY SCHOOL",
    Address: "KWANOZULU LOCATION  NDABAKAZI A/A  BUTTERWORTH",
    Latitude: "-32.3763",
    Longitude: "28.0162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "991",
    WardLookupId: "581",
    VDNumber: "10790759",
    RegisteredPopulation: "231",
    VotingStationName: "NALILISO PRE SCHOOL",
    Address: "MAHLUBINI LOCATION  THOLENI A/A  BUTTERWORTH",
    Latitude: "-32.3441",
    Longitude: "28.0089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "992",
    WardLookupId: "581",
    VDNumber: "10790760",
    RegisteredPopulation: "156",
    VotingStationName: "NONURSE PRE SCHOOL",
    Address: "TOLENI KOMKHULU LOCATION  TOLENI A/A  BUTTERWORTH",
    Latitude: "-32.4018",
    Longitude: "27.9536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "993",
    WardLookupId: "582",
    VDNumber: "10790142",
    RegisteredPopulation: "515",
    VotingStationName: "BETHEL JUNIOR SECONDARY SCHOOL",
    Address: "KWAMKIVA  MKIVA A/A  NQAMAKWE",
    Latitude: "-32.3044",
    Longitude: "28.0013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "994",
    WardLookupId: "582",
    VDNumber: "10910225",
    RegisteredPopulation: "512",
    VotingStationName: "MANGONDINI JUNIOR SECONDARY SCHOOL",
    Address: "NDAKANA A/A  NQAMAKWE",
    Latitude: "-32.2501",
    Longitude: "27.9175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "995",
    WardLookupId: "582",
    VDNumber: "10910236",
    RegisteredPopulation: "477",
    VotingStationName: "KWEZI JUNIOR SECONDARY SCHOOL",
    Address: "ZAZELA A/A  NQAMAKWE",
    Latitude: "-32.2808",
    Longitude: "27.9401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "996",
    WardLookupId: "582",
    VDNumber: "10910247",
    RegisteredPopulation: "793",
    VotingStationName: "CEGCUWANA JUNIOR SECONDARY SCHOOL",
    Address: "SOKAPASE A/A  NQAMAKWE  NQAMAKWE",
    Latitude: "-32.2705",
    Longitude: "27.9699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "997",
    WardLookupId: "582",
    VDNumber: "10910269",
    RegisteredPopulation: "346",
    VotingStationName: "LOWER GCEGCUANE JUNIOR SECONDARY SCHOOL",
    Address: "LOWER CEGCUANE LOCATION  SOKAPASE A/A  NQAMAKWE",
    Latitude: "-32.2858",
    Longitude: "27.9961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "998",
    WardLookupId: "582",
    VDNumber: "10910281",
    RegisteredPopulation: "594",
    VotingStationName: "BLYTHSWOOD JUNIOR SECONDARY SCHOOL",
    Address: "SOKAPASE A/A  NQAMAKWE",
    Latitude: "-32.2266",
    Longitude: "27.9836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "999",
    WardLookupId: "582",
    VDNumber: "10910854",
    RegisteredPopulation: "218",
    VotingStationName: "SOKAPASE JUNIOR SECONDARY SCHOOL",
    Address: "SOKAPASE LOCATION  SOKAPASE A/A  NQAMAKWE",
    Latitude: "-32.2526",
    Longitude: "28.0128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1000",
    WardLookupId: "582",
    VDNumber: "10911013",
    RegisteredPopulation: "310",
    VotingStationName: "NDAKANA JUNIOR SECONDARY SCHOOL",
    Address: "NDAKANA LOCATION  NDAKANA A/A  NQAMAKWE",
    Latitude: "-32.2638",
    Longitude: "27.906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1001",
    WardLookupId: "583",
    VDNumber: "10910056",
    RegisteredPopulation: "171",
    VotingStationName: "NYILI JUNIOR SECONDARY SCHOOL",
    Address: "NYILI A/A  MPUKANE A/A  NQAMAKWE",
    Latitude: "-32.3594",
    Longitude: "27.8473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1002",
    WardLookupId: "583",
    VDNumber: "10910089",
    RegisteredPopulation: "349",
    VotingStationName: "LANGA JUNIOR SECONDARY SCHOOL",
    Address: "NTSHATSHONGO LOCATION  MAGODLA A/A  NQAMAKWE",
    Latitude: "-32.3484",
    Longitude: "27.9485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1003",
    WardLookupId: "583",
    VDNumber: "10910090",
    RegisteredPopulation: "342",
    VotingStationName: "TANDA JUNIOR SECONDARY SCHOOL",
    Address: "MBIZA LOCATION  NTANDATHU A/A  NQAMAKWE",
    Latitude: "-32.3113",
    Longitude: "27.8733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1004",
    WardLookupId: "583",
    VDNumber: "10910102",
    RegisteredPopulation: "427",
    VotingStationName: "DIPENDE CHURCH",
    Address: "NTANDATHU A/A  NQAMAKWE",
    Latitude: "-32.3292",
    Longitude: "27.8913",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1005",
    WardLookupId: "583",
    VDNumber: "10910113",
    RegisteredPopulation: "190",
    VotingStationName: "NGCINGCINIKWE JUNIOR SECONDARY SCHOOL",
    Address: "NGCINGCINIKWE A/A  MPUKANE A/A  NQAMAKWE",
    Latitude: "-32.3836",
    Longitude: "27.8393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1006",
    WardLookupId: "583",
    VDNumber: "10910124",
    RegisteredPopulation: "501",
    VotingStationName: "JONGIBANDLA JUNIOR SECONDARY SCHOOL",
    Address: "MGOBOZWENI A/A  ZAZELA A/A  NQAMAKWE",
    Latitude: "-32.3332",
    Longitude: "27.9601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1007",
    WardLookupId: "583",
    VDNumber: "10910135",
    RegisteredPopulation: "630",
    VotingStationName: "TYNIRA JUNIOR SECONDARY SCHOOL",
    Address: "ZAZELA A/A  NQAMAKWE",
    Latitude: "-32.3081",
    Longitude: "27.9576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1008",
    WardLookupId: "583",
    VDNumber: "10910157",
    RegisteredPopulation: "279",
    VotingStationName: "JONGABANTU SENIOR SECONDARY SCHOOL",
    Address: "MBIZA A/A  NQAMAKWE",
    Latitude: "-32.2901",
    Longitude: "27.9168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1009",
    WardLookupId: "583",
    VDNumber: "10910652",
    RegisteredPopulation: "159",
    VotingStationName: "KIVIET SUKWANA JUNIOR SECONDARY SCHOOL",
    Address: "NTANDATHU A/A  NQAMAKWE",
    Latitude: "-32.3537",
    Longitude: "27.8807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1010",
    WardLookupId: "583",
    VDNumber: "10910731",
    RegisteredPopulation: "329",
    VotingStationName: "MACHIBINI JUNIOR SECONDARY SCHOOL",
    Address: "NTANDATU A/A  MACHIBINI  NQAMAKWE",
    Latitude: "-32.3198",
    Longitude: "27.9104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1011",
    WardLookupId: "583",
    VDNumber: "10910742",
    RegisteredPopulation: "214",
    VotingStationName: "MBIZA JUNIOR SECONDARY SCHOOL",
    Address: "MBIZA LOCATION  MBIZA A/A  NQAMAKWE",
    Latitude: "-32.2915",
    Longitude: "27.8714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1012",
    WardLookupId: "583",
    VDNumber: "10910786",
    RegisteredPopulation: "384",
    VotingStationName: "NTSHATSHONGA JUNIOR SECONDARY SCHOOL",
    Address: "KWAMAGODLA LOCATION  KWAMAGODLA A/A  NQAMAKWE",
    Latitude: "-32.3449",
    Longitude: "27.9252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1013",
    WardLookupId: "584",
    VDNumber: "10910012",
    RegisteredPopulation: "341",
    VotingStationName: "LOWER NQANCULE JUNIOR SECONDARY SCHOOL",
    Address: "XHAMANE LOCATION  NQANCULE A/A  NQAMAKWE",
    Latitude: "-32.3759",
    Longitude: "27.7613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1014",
    WardLookupId: "584",
    VDNumber: "10910034",
    RegisteredPopulation: "428",
    VotingStationName: "JOJWENI JUNIOR SECONDARY SCHOOL",
    Address: "JOJWENI LOCATION  NQANCULE A/A  NQAMAKWE",
    Latitude: "-32.347",
    Longitude: "27.7922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1015",
    WardLookupId: "584",
    VDNumber: "10910067",
    RegisteredPopulation: "279",
    VotingStationName: "GXOJANA JUNIOR SECONDARY SCHOOL",
    Address: "GXOJANA A/A  NQAMAKWE",
    Latitude: "-32.2853",
    Longitude: "27.7922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1016",
    WardLookupId: "584",
    VDNumber: "10910168",
    RegisteredPopulation: "208",
    VotingStationName: "NTLEBI JUNIOR SECONDARY SCHOOL",
    Address: "MATOLWENI A/A  NQAMAKWE",
    Latitude: "-32.2585",
    Longitude: "27.8417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1017",
    WardLookupId: "584",
    VDNumber: "10910180",
    RegisteredPopulation: "237",
    VotingStationName: "LOWER NDAKANA JUNIOR SECONDARY SCHOOL",
    Address: "MATOLWENI A/A  NQAMAKWE",
    Latitude: "-32.2807",
    Longitude: "27.8237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1018",
    WardLookupId: "584",
    VDNumber: "10910191",
    RegisteredPopulation: "199",
    VotingStationName: "MBASA JUNIOR SECONDARY SCHOOL",
    Address: "RWANTSANA A/A  NQAMAKWE",
    Latitude: "-32.2152",
    Longitude: "27.7983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1019",
    WardLookupId: "584",
    VDNumber: "10910203",
    RegisteredPopulation: "531",
    VotingStationName: "HEBE-HEBE JUNIOR SECONDARY SCHOOL",
    Address: "HEBE-HEBE A/A  NQAMAKWE",
    Latitude: "-32.2322",
    Longitude: "27.8277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1020",
    WardLookupId: "584",
    VDNumber: "10910630",
    RegisteredPopulation: "243",
    VotingStationName: "ZAKHELE SENIOR PRIMARY SCHOOL",
    Address: "NQANCULE A/A  NQAMAKWE",
    Latitude: "-32.3647",
    Longitude: "27.7386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1021",
    WardLookupId: "584",
    VDNumber: "10910641",
    RegisteredPopulation: "173",
    VotingStationName: "NYIDLANA JUNIOR SECONDARY SCHOOL",
    Address: "NYIDLANA A/A  NQAMAKWE",
    Latitude: "-32.3256",
    Longitude: "27.8083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1022",
    WardLookupId: "584",
    VDNumber: "10910719",
    RegisteredPopulation: "55",
    VotingStationName: "ZENZELE SENIOR PRIMARY SCHOOL",
    Address: "MAZANTSI LOCATION  NQANCULE A/A  NQAMAKWE",
    Latitude: "-32.4038",
    Longitude: "27.7733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1023",
    WardLookupId: "584",
    VDNumber: "10910865",
    RegisteredPopulation: "320",
    VotingStationName: "SIVANXA JUNIOR SECONDARY SCHOOL",
    Address: "SIVANXA LOCATION  NQANCULE A/A  NQAMAKWE",
    Latitude: "-32.3639",
    Longitude: "27.8226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1024",
    WardLookupId: "584",
    VDNumber: "10910876",
    RegisteredPopulation: "96",
    VotingStationName: "ZIVUKELE SENIOR PRIMARY SCHOOL",
    Address: "MAJAMANENI LOCATION  MAJAMANENI A/A  NQAMAKWE",
    Latitude: "-32.3451",
    Longitude: "27.7729",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1025",
    WardLookupId: "584",
    VDNumber: "10910887",
    RegisteredPopulation: "165",
    VotingStationName: "NGUNIKAZI JUNIOR PRIMARY SCHOOL",
    Address: "HEBE HEBANA LOCATION  BHONGITHOLE A/A  NQAMAKWE",
    Latitude: "-32.2579",
    Longitude: "27.7934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1026",
    WardLookupId: "584",
    VDNumber: "10910898",
    RegisteredPopulation: "158",
    VotingStationName: "DALUKUKHANYA SENIOR PRIMARY SCHOOL",
    Address: "EDIPHINI LOCATION  KWAMAVI A/A   NQAMAKWE",
    Latitude: "-32.2553",
    Longitude: "27.8605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1027",
    WardLookupId: "584",
    VDNumber: "10910900",
    RegisteredPopulation: "99",
    VotingStationName: "BIYANA JUNIOR SECONDARY SCHOOL",
    Address: "BIYANA LOCATION  BIYANA A/A  NQAMAKWE",
    Latitude: "-32.2213",
    Longitude: "27.7705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1028",
    WardLookupId: "584",
    VDNumber: "10911024",
    RegisteredPopulation: "153",
    VotingStationName: "KUYASA SENIOR PRIMARY SCHOOL",
    Address: "SKHOBENI LOCATION  NYIDLANA A/A  NQAMAKWE",
    Latitude: "-32.3015",
    Longitude: "27.8289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1029",
    WardLookupId: "585",
    VDNumber: "10910023",
    RegisteredPopulation: "305",
    VotingStationName: "NOBANDA JUNIOR SECONDARY SCHOOL",
    Address: "NGCULU LOCATION  NOBANDA A/A  NQAMAKWE",
    Latitude: "-32.2142",
    Longitude: "27.8968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1030",
    WardLookupId: "585",
    VDNumber: "10910045",
    RegisteredPopulation: "260",
    VotingStationName: "NGCISININDE JUNIOR SECONDARY SCHOOL",
    Address: "KWANDIKI LOCATION  NGCISININDE A/A  NQAMAKWE",
    Latitude: "-32.1574",
    Longitude: "27.8568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1031",
    WardLookupId: "585",
    VDNumber: "10910078",
    RegisteredPopulation: "278",
    VotingStationName: "MWELI JUNIOR SECONDARY SCHOOL",
    Address: "NOFOTYO A/A  NQAMAKWE",
    Latitude: "-32.1835",
    Longitude: "27.8518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1032",
    WardLookupId: "585",
    VDNumber: "10910258",
    RegisteredPopulation: "281",
    VotingStationName: "DINGISWAYO JUNIOR SECONDARY SCHOOL",
    Address: "NYULULA A/A  NQAMAKWE",
    Latitude: "-32.1102",
    Longitude: "27.8076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1033",
    WardLookupId: "585",
    VDNumber: "10910304",
    RegisteredPopulation: "910",
    VotingStationName: "AMABELE TRIBAL AUTHORITY",
    Address: "NOBANDA A/A  NQAMAKWE",
    Latitude: "-32.1968",
    Longitude: "27.9354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1034",
    WardLookupId: "585",
    VDNumber: "10910618",
    RegisteredPopulation: "264",
    VotingStationName: "SIYABULELA SENIOR SECONDARY SCHOOL",
    Address: "NDIKI LOCATION  GCISININDE A/A   NQAMAKWE",
    Latitude: "-32.1601",
    Longitude: "27.8995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1035",
    WardLookupId: "585",
    VDNumber: "10910663",
    RegisteredPopulation: "349",
    VotingStationName: "GONTSHI JUNIOR SECONDARY SCHOOL",
    Address: "GONTSHI LOCATION  GONTSHI A/A  NQAMAKWE",
    Latitude: "-32.2445",
    Longitude: "27.882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1036",
    WardLookupId: "585",
    VDNumber: "10910720",
    RegisteredPopulation: "220",
    VotingStationName: "MASIBAMBANE JUNIOR PRIMARY SCHOOL",
    Address: "MAMELA LOCATION  NGCISININDE A/A  NQAMAKWE",
    Latitude: "-32.1329",
    Longitude: "27.8566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1037",
    WardLookupId: "585",
    VDNumber: "10910832",
    RegisteredPopulation: "200",
    VotingStationName: "MAGWAZA JUNIOR SECONDARY SCHOOL",
    Address: "SIDUTYINI LOCATION  GCINISINDE A/A  NQAMAKWE",
    Latitude: "-32.1303",
    Longitude: "27.8999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1038",
    WardLookupId: "585",
    VDNumber: "10910911",
    RegisteredPopulation: "440",
    VotingStationName: "UPPER NGCULU JUNIOR SECONDARY SCHOOL",
    Address: "NGCULU LOCATION  NGCULU A/A  NQAMAKWE",
    Latitude: "-32.2126",
    Longitude: "27.9268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1039",
    WardLookupId: "585",
    VDNumber: "10910922",
    RegisteredPopulation: "156",
    VotingStationName: "UPPER NOFOTYO JUNIOR SECONDARY SCHOOL",
    Address: "UPPER NOFOTYO LOCATION  UPPER NOFOTYO A/A  NQAMAKWE",
    Latitude: "-32.1929",
    Longitude: "27.8182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1040",
    WardLookupId: "585",
    VDNumber: "10910933",
    RegisteredPopulation: "391",
    VotingStationName: "MXHAKA JUNIOR PRIMARY SCHOOL",
    Address: "MXHAKA LOCATION  NGCISININDE A/A  NQAMAKWE",
    Latitude: "-32.1798",
    Longitude: "27.8806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1041",
    WardLookupId: "585",
    VDNumber: "10910944",
    RegisteredPopulation: "167",
    VotingStationName: "NYULULA JUNIOR SECONDARY SCHOOL",
    Address: "NYULULA LOCATION  NYULULA A/A  NQAMAKWE",
    Latitude: "-32.1009",
    Longitude: "27.8338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1042",
    WardLookupId: "586",
    VDNumber: "10910337",
    RegisteredPopulation: "303",
    VotingStationName: "NOMAHEYA JUNIOR SECONDARY SCHOOL",
    Address: "NOMAHEYA A/A  NQAMAKWE",
    Latitude: "-32.1426",
    Longitude: "27.961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1043",
    WardLookupId: "586",
    VDNumber: "10910371",
    RegisteredPopulation: "491",
    VotingStationName: "MNGCANGCATELO JUNIOR SECONDARY SCHOOL",
    Address: "NOMAHEYA A/A  NQAMAKWE",
    Latitude: "-32.1423",
    Longitude: "27.9915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1044",
    WardLookupId: "586",
    VDNumber: "10910461",
    RegisteredPopulation: "316",
    VotingStationName: "LAMBATA JUNIOR SECONDARY SCHOOL",
    Address: "LAMBATA A/A  NQAMAKWE",
    Latitude: "-32.0897",
    Longitude: "27.9202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1045",
    WardLookupId: "586",
    VDNumber: "10910573",
    RegisteredPopulation: "572",
    VotingStationName: "GUDLA JUNIOR SECONDARY SCHOOL",
    Address: "NOMAHEYA A/A  NQAMAKWE",
    Latitude: "-32.1397",
    Longitude: "28.0388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1046",
    WardLookupId: "586",
    VDNumber: "10910584",
    RegisteredPopulation: "391",
    VotingStationName: "MERILEES JUNIOR SECONDARY SCHOOL",
    Address: "TOBOYI A/A  NQAMAKWE",
    Latitude: "-32.118",
    Longitude: "28.0112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1047",
    WardLookupId: "586",
    VDNumber: "10910607",
    RegisteredPopulation: "699",
    VotingStationName: "DUDUMASHE JUNIOR SECONDARY SCHOOL",
    Address: "DUDUMASHE A/A  NQAMAKWE",
    Latitude: "-32.1281",
    Longitude: "27.9325",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1048",
    WardLookupId: "586",
    VDNumber: "10910629",
    RegisteredPopulation: "399",
    VotingStationName: "TOBOYI JUNIOR SECONDARY SCHOOL",
    Address: "TOBOYI A/A  NQAMAKWE",
    Latitude: "-32.1089",
    Longitude: "27.9757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1049",
    WardLookupId: "586",
    VDNumber: "10910810",
    RegisteredPopulation: "301",
    VotingStationName: "ZITHULELE JUNIOR SECONDARY SCHOOL",
    Address: "ZITHULELE LOCATION  DUDUMASHE A/A  NQAMAKWE",
    Latitude: "-32.0985",
    Longitude: "27.8991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1050",
    WardLookupId: "586",
    VDNumber: "10910955",
    RegisteredPopulation: "244",
    VotingStationName: "KUNENE JUNIOR SECONDAY SCHOOL",
    Address: "KUNENE LOCATION  KUNENE A/A  NQAMAKWE",
    Latitude: "-32.0891",
    Longitude: "27.9725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1051",
    WardLookupId: "586",
    VDNumber: "10940532",
    RegisteredPopulation: "75",
    VotingStationName: "XUME JUNIOR SECONDARY SCHOOL",
    Address: "XUME LOCATION  XUME A/A  TSOMO",
    Latitude: "-32.0597",
    Longitude: "27.901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1052",
    WardLookupId: "587",
    VDNumber: "10700019",
    RegisteredPopulation: "663",
    VotingStationName: "NQAMAKWE TOWN HALL",
    Address: "41 MAIN ROAD  NQAMAKWE  NQAMAKWE",
    Latitude: "-32.2024",
    Longitude: "27.9422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1053",
    WardLookupId: "587",
    VDNumber: "10810662",
    RegisteredPopulation: "263",
    VotingStationName: "NONTLOKO  JUNIOR SECONDARY SCHOOL",
    Address: "UPPER NGCWAZI LOCATION  NGCWAZI A/A  NQAMAKWE",
    Latitude: "-32.1821",
    Longitude: "28.0644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1054",
    WardLookupId: "587",
    VDNumber: "10910292",
    RegisteredPopulation: "340",
    VotingStationName: "TUNGA JUNIOR SECONDARY SCHOOL",
    Address: "MTWAKU LOCATION  MTHWAKU A/A  NQAMAKWE",
    Latitude: "-32.1906",
    Longitude: "27.9803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1055",
    WardLookupId: "587",
    VDNumber: "10910315",
    RegisteredPopulation: "543",
    VotingStationName: "NQAMAKWE JUNIOR SECONDARY SCHOOL",
    Address: "MANTUNZELENI A/A  MANTUNZELA A/A  NQAMAKWE",
    Latitude: "-32.2366",
    Longitude: "28.055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1056",
    WardLookupId: "587",
    VDNumber: "10910326",
    RegisteredPopulation: "356",
    VotingStationName: "MEKENI JUNIOR SECONDARY SCHOOL",
    Address: "MTWAKU A/A  MTHWAKU  A/A  NQAMAKWE",
    Latitude: "-32.1837",
    Longitude: "27.9481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1057",
    WardLookupId: "587",
    VDNumber: "10910359",
    RegisteredPopulation: "379",
    VotingStationName: "MPAHLENI JUNIOR SECONDARY SCHOOL",
    Address: "MPAHLENI A/A  NQAMAKWE",
    Latitude: "-32.1963",
    Longitude: "28.037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1058",
    WardLookupId: "587",
    VDNumber: "10910360",
    RegisteredPopulation: "386",
    VotingStationName: "GOVAN JUNIOR SECONDARY SCHOOL",
    Address: "NTWALA A/A  NQAMAKWE",
    Latitude: "-32.2021",
    Longitude: "28.0774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1059",
    WardLookupId: "587",
    VDNumber: "10910674",
    RegisteredPopulation: "311",
    VotingStationName: "EZIFLATINI JUNIOR SECONDARY SCHOOL",
    Address: "MTHWAKU A/A  NQAMAKWE",
    Latitude: "-32.1709",
    Longitude: "28.0042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1060",
    WardLookupId: "587",
    VDNumber: "10910753",
    RegisteredPopulation: "380",
    VotingStationName: "LOWER NQAMAKWE JUNIOR SECONDARY SCHOOL",
    Address: "KATYEKANA LOCATION  MANTUNDZELENI A/A  NQAMAKWE",
    Latitude: "-32.2419",
    Longitude: "28.0803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1061",
    WardLookupId: "587",
    VDNumber: "10910966",
    RegisteredPopulation: "224",
    VotingStationName: "VUKUKANYE JUNIOR SECONDARY SCHOOL",
    Address: "MPAHLENI LOCATION  MPAHLENI A/A  NQAMAKWE",
    Latitude: "-32.21",
    Longitude: "28.0564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1062",
    WardLookupId: "588",
    VDNumber: "10910494",
    RegisteredPopulation: "294",
    VotingStationName: "MNTLA JUNIOR SECONDARY SCHOOL",
    Address: "KOTANA A/A  NQAMAKWE",
    Latitude: "-32.1285",
    Longitude: "28.1614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1063",
    WardLookupId: "588",
    VDNumber: "10910528",
    RegisteredPopulation: "603",
    VotingStationName: "SIHLABENI JUNIOR SECONDARY SCHOOL",
    Address: "SIHLABENI A/A  NQAMAKWE",
    Latitude: "-32.1544",
    Longitude: "28.084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1064",
    WardLookupId: "588",
    VDNumber: "10910539",
    RegisteredPopulation: "388",
    VotingStationName: "GUBEVU JUNIOR SECONDARY SCHOOL",
    Address: "NTSESHE A/A  NTSETHE A/A  NQAMAKWE",
    Latitude: "-32.1195",
    Longitude: "28.1083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1065",
    WardLookupId: "588",
    VDNumber: "10910540",
    RegisteredPopulation: "356",
    VotingStationName: "MASELENI JUNIOR SECONDARY SCHOOL",
    Address: "NTSESHE A/A  NQAMAKWE",
    Latitude: "-32.0663",
    Longitude: "28.1176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1066",
    WardLookupId: "588",
    VDNumber: "10910551",
    RegisteredPopulation: "448",
    VotingStationName: "NTSESHE JUNIOR SECONDARY SCHOOL",
    Address: "NTSESHE A/A  NQAMAKWE",
    Latitude: "-32.1001",
    Longitude: "28.089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1067",
    WardLookupId: "588",
    VDNumber: "10910562",
    RegisteredPopulation: "283",
    VotingStationName: "SIQITHINI JUNIOR SECONDARY SCHOOL",
    Address: "XILINXA A/A  NQAMAKWE",
    Latitude: "-32.0939",
    Longitude: "28.0197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1068",
    WardLookupId: "588",
    VDNumber: "10910595",
    RegisteredPopulation: "238",
    VotingStationName: "PETER QONGO CHURCH",
    Address: "XILINXA A/A  NQAMAKWE",
    Latitude: "-32.0587",
    Longitude: "27.9918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1069",
    WardLookupId: "588",
    VDNumber: "10910764",
    RegisteredPopulation: "332",
    VotingStationName: "XILINXA SENIOR SECONDARY SCHOOL",
    Address: "XILINXA  XILINXA A/A  NQAMAKWE",
    Latitude: "-32.0635",
    Longitude: "28.0183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1070",
    WardLookupId: "588",
    VDNumber: "10910775",
    RegisteredPopulation: "295",
    VotingStationName: "JEKEZI JUNIOR SECONDARY SCHOOL",
    Address: "JEKEZI LOCATION  XILINXA A/A  NQAMAKWE",
    Latitude: "-32.0977",
    Longitude: "28.058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1071",
    WardLookupId: "588",
    VDNumber: "10911002",
    RegisteredPopulation: "250",
    VotingStationName: "KOTANA VILLAGE TENT",
    Address: "SIMELANE LOCATION  KOTANA A/A  NQAMAKWE",
    Latitude: "-32.1466",
    Longitude: "28.1347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1072",
    WardLookupId: "588",
    VDNumber: "10911035",
    RegisteredPopulation: "161",
    VotingStationName: "NJEKENI JUNIOR SECONDARY SCHOOL",
    Address: "NJEKENI LOCATION  NXILINXA A/A  NQAMAKWE",
    Latitude: "-32.0774",
    Longitude: "27.9885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1073",
    WardLookupId: "589",
    VDNumber: "10790018",
    RegisteredPopulation: "437",
    VotingStationName: "ZINGQAYI JUNIOR SECONDARY SCHOOL",
    Address: "ZINGQAYI A/A  BUTTERWORTH",
    Latitude: "-32.2118",
    Longitude: "28.1909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1074",
    WardLookupId: "589",
    VDNumber: "10790658",
    RegisteredPopulation: "521",
    VotingStationName: "KHAYALETHU JUNIOR SECONDARY SCHOOL",
    Address: "TANGA LOCATION  TANGA A/A  BUTTERWORTH",
    Latitude: "-32.2319",
    Longitude: "28.1912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1075",
    WardLookupId: "589",
    VDNumber: "10810640",
    RegisteredPopulation: "206",
    VotingStationName: "NKQUBELA SENIOR PRIMARY SCHOOL",
    Address: "NGCWAZI LOCATION  NKQUBELA A/A  BUTTERWORTH",
    Latitude: "-32.2045",
    Longitude: "28.1106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1076",
    WardLookupId: "589",
    VDNumber: "10910348",
    RegisteredPopulation: "498",
    VotingStationName: "MPETA JUNIOR SECONDARY SCHOOL",
    Address: "MPETA A/A  NQAMAKWE",
    Latitude: "-32.2555",
    Longitude: "28.1134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1077",
    WardLookupId: "589",
    VDNumber: "10910382",
    RegisteredPopulation: "193",
    VotingStationName: "QIMA JUNIOR SECONDARY SCHOOL",
    Address: "QIMA LOCATION  MPETHA A/A  BUTTERWORTH",
    Latitude: "-32.2203",
    Longitude: "28.1364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1078",
    WardLookupId: "589",
    VDNumber: "10910393",
    RegisteredPopulation: "470",
    VotingStationName: "NOMJANA JUNIOR SECONDARY SCHOOL",
    Address: "KOTANA A/A  NQAMAKWE",
    Latitude: "-32.1683",
    Longitude: "28.1678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1079",
    WardLookupId: "589",
    VDNumber: "10910506",
    RegisteredPopulation: "595",
    VotingStationName: "NOXHAKAZA JUNIOR SECONDARY SCHOOL",
    Address: "MTEBELE A/A  NQAMAKWE",
    Latitude: "-32.1879",
    Longitude: "28.1144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1080",
    WardLookupId: "589",
    VDNumber: "10910517",
    RegisteredPopulation: "411",
    VotingStationName: "KOTANA JUNIOR SECONDARY SCHOOL",
    Address: "KOTANA A/A  NQAMAKWE",
    Latitude: "-32.1615",
    Longitude: "28.145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1081",
    WardLookupId: "589",
    VDNumber: "10910977",
    RegisteredPopulation: "416",
    VotingStationName: "LINDSAY JUNIOR SECONDARY SCHOOL",
    Address: "N/A  CENTANE  MNQUMA [BUTTERWORTH]",
    Latitude: "-32.207",
    Longitude: "28.1304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1082",
    WardLookupId: "589",
    VDNumber: "10910999",
    RegisteredPopulation: "280",
    VotingStationName: "MZANTSI-MPETHA JUNIOR PRIMARY SCHOOL",
    Address: "MZANTSI-MPETHA LOCATION  MPETHA A/A  NQAMAKWE",
    Latitude: "-32.2465",
    Longitude: "28.1375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1083",
    WardLookupId: "590",
    VDNumber: "10790647",
    RegisteredPopulation: "295",
    VotingStationName: "KUYASA SENIOR PRIMARY SCHOOL",
    Address: "KUYASA LOCATION  ZINGQAYI A/A  BUTTERWORTH",
    Latitude: "-32.2165",
    Longitude: "28.2093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1084",
    WardLookupId: "590",
    VDNumber: "10910416",
    RegisteredPopulation: "325",
    VotingStationName: "EMGCWE JUNIOR SECONDARY SCHOOL",
    Address: "EMGCWE A/A  NQAMAKWE",
    Latitude: "-32.1741",
    Longitude: "28.1943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1085",
    WardLookupId: "590",
    VDNumber: "10910427",
    RegisteredPopulation: "205",
    VotingStationName: "MANKIHLANA JUNIOR SECONDARY SCHOOL",
    Address: "EMGCWE A/A  NQAMAKWE",
    Latitude: "-32.1406",
    Longitude: "28.2267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1086",
    WardLookupId: "590",
    VDNumber: "10910438",
    RegisteredPopulation: "357",
    VotingStationName: "QOLIWE JUNIOR SECONDARY SCHOOL",
    Address: "EMGCWE A/A  NQAMAKWE",
    Latitude: "-32.1262",
    Longitude: "28.2026",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1087",
    WardLookupId: "590",
    VDNumber: "10910449",
    RegisteredPopulation: "315",
    VotingStationName: "EZIZWENI SENIOR SECONDARY SCHOOL",
    Address: "EMGCWE A/A  NQAMAKWE",
    Latitude: "-32.1379",
    Longitude: "28.191",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1088",
    WardLookupId: "590",
    VDNumber: "10910450",
    RegisteredPopulation: "270",
    VotingStationName: "UKHOZI JUNIOR SECONDARY SCHOOL",
    Address: "HLOBO A/A  NQAMAKWE",
    Latitude: "-32.0695",
    Longitude: "28.1742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1089",
    WardLookupId: "590",
    VDNumber: "10910472",
    RegisteredPopulation: "237",
    VotingStationName: "HLOBO JUNIOR SECONDARY SCHOOL",
    Address: "HLOBO A/A  NQAMAKWE",
    Latitude: "-32.1061",
    Longitude: "28.1672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1090",
    WardLookupId: "590",
    VDNumber: "10910483",
    RegisteredPopulation: "309",
    VotingStationName: "SISEKO JUNIOR SECONDARY SCHOOL",
    Address: "SISEKO LOCATION  HLOBO A/A  NQAMAKWE",
    Latitude: "-32.1102",
    Longitude: "28.1949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1091",
    WardLookupId: "590",
    VDNumber: "10910685",
    RegisteredPopulation: "406",
    VotingStationName: "TSHAZIBANE JUNIOR SECONDARY SCHOOL",
    Address: "HLOBO A/A  NQAMAKWE",
    Latitude: "-32.0802",
    Longitude: "28.214",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1092",
    WardLookupId: "590",
    VDNumber: "10910797",
    RegisteredPopulation: "307",
    VotingStationName: "LUNDI JUNIOR SECONDARY SCHOOL",
    Address: "MAZIZINI  MAZIZINI A/A  NQAMAKWE",
    Latitude: "-32.0614",
    Longitude: "28.1898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1093",
    WardLookupId: "590",
    VDNumber: "10910809",
    RegisteredPopulation: "211",
    VotingStationName: "MALONGWENI JUNIOR SECONDARY SCHOOL",
    Address: "MALONGWENI LOCATION  MGCWE A/A  BUTTERWORTH",
    Latitude: "-32.1931",
    Longitude: "28.1937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1094",
    WardLookupId: "590",
    VDNumber: "10910821",
    RegisteredPopulation: "254",
    VotingStationName: "NOKHAYO JUNIOR PRIMARY SCHOOL",
    Address: "HLOBO  HLOBO A/A  NQAMAKWE",
    Latitude: "-32.0795",
    Longitude: "28.1577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1095",
    WardLookupId: "590",
    VDNumber: "10910988",
    RegisteredPopulation: "376",
    VotingStationName: "LINGELETHU SENIOR PRIMARY SCHOOL",
    Address: "LINGELETHU LOCATION  MGCWE A/A  BUTTERWORTH",
    Latitude: "-32.1612",
    Longitude: "28.2223",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1096",
    WardLookupId: "591",
    VDNumber: "10790591",
    RegisteredPopulation: "583",
    VotingStationName: "KWAMAGALAKANGA JUNIOR SECONDARY SCHOOL",
    Address: "MAGALAKANGQA LOCATION  KWAMAGAGALAKANGA A/A  BUTTERWORTH",
    Latitude: "-32.2682",
    Longitude: "28.2331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1097",
    WardLookupId: "591",
    VDNumber: "10840036",
    RegisteredPopulation: "925",
    VotingStationName: "CAFUTWENI JUNIOR SECONDARY SCHOOL",
    Address: "CAFUTWENI A/A  WILLOWVALE",
    Latitude: "-32.2658",
    Longitude: "28.2889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1098",
    WardLookupId: "591",
    VDNumber: "10840047",
    RegisteredPopulation: "526",
    VotingStationName: "MFANZI JUNIOR SECONDARY SCHOOL",
    Address: "CAFUTWENI A/A  WILLOWVALE",
    Latitude: "-32.2702",
    Longitude: "28.3278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1099",
    WardLookupId: "591",
    VDNumber: "10840058",
    RegisteredPopulation: "617",
    VotingStationName: "BUSILA JUNIOR SECONDARY SCHOOL",
    Address: "BUSILA A/A  WILLOWVALE",
    Latitude: "-32.3124",
    Longitude: "28.3898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1100",
    WardLookupId: "591",
    VDNumber: "10841307",
    RegisteredPopulation: "456",
    VotingStationName: "ZWELANDILE JUNIOR SECONDARY SCHOOL",
    Address: "BUSILA LOCATION  BUSILA A/A  WILLOWVALE",
    Latitude: "-32.3108",
    Longitude: "28.3468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1101",
    WardLookupId: "591",
    VDNumber: "10850464",
    RegisteredPopulation: "557",
    VotingStationName: "LOWER QORA JUNIOR SECONDARY SCHOOL",
    Address: "QORA A/A  IDUTYWA",
    Latitude: "-32.2488",
    Longitude: "28.2367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1102",
    WardLookupId: "591",
    VDNumber: "10851117",
    RegisteredPopulation: "371",
    VotingStationName: "BLUE SKY JUNIOR SECONDARY SCHOOL",
    Address: "BLUE SKY LOCATION  BLUE SKY A/A   BUTTERWORTH",
    Latitude: "-32.2412",
    Longitude: "28.2043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1103",
    WardLookupId: "592",
    VDNumber: "10810224",
    RegisteredPopulation: "340",
    VotingStationName: "ENGLAND SENIOR PRIMARY SCHOOL",
    Address: "KOMKHULU LOCATION  RWANTSANA  CENTANE",
    Latitude: "-32.3766",
    Longitude: "28.1842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1104",
    WardLookupId: "592",
    VDNumber: "10810246",
    RegisteredPopulation: "310",
    VotingStationName: "WONGALETHU JUNIOR SECONDARY SCHOOL",
    Address: "TEKO SPRINGS A/A  TEKO SPRINGS ADMIN AREA  CENTANE",
    Latitude: "-32.4015",
    Longitude: "28.2686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1105",
    WardLookupId: "592",
    VDNumber: "10810257",
    RegisteredPopulation: "951",
    VotingStationName: "BURU JUNIOR SECONDARY SCHOOL",
    Address: "TUTURA A/A  TUTURA ADMINISTRATIVE AREA  CENTANE",
    Latitude: "-32.3803",
    Longitude: "28.2364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1106",
    WardLookupId: "592",
    VDNumber: "10810279",
    RegisteredPopulation: "409",
    VotingStationName: "ISIGINGQINI SENIOR PRIMARY SCHOOL",
    Address: "NKONDWANE A/A  CENTANE",
    Latitude: "-32.3948",
    Longitude: "28.1957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1107",
    WardLookupId: "592",
    VDNumber: "10810291",
    RegisteredPopulation: "793",
    VotingStationName: "QOBOQOBO JUNIOR SECONDARY SCHOOL",
    Address: "QOBOQOBO A/A  QOBOQOBO ADMIN AREA  CENTANE",
    Latitude: "-32.4221",
    Longitude: "28.2624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1108",
    WardLookupId: "592",
    VDNumber: "10810314",
    RegisteredPopulation: "278",
    VotingStationName: "MAPASA JUNIOR SECONDARY SCHOOL",
    Address: "TUTURA A/A  TUTURA ADMINISTRATIVE AREA  CENTANE",
    Latitude: "-32.4137",
    Longitude: "28.2341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1109",
    WardLookupId: "592",
    VDNumber: "10810459",
    RegisteredPopulation: "295",
    VotingStationName: "XHOBANI JUNIOR SECONDARY SCHOOL",
    Address: "XHOBANI LOCATION  QINA A/A  CENTANE",
    Latitude: "-32.4409",
    Longitude: "28.1534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1110",
    WardLookupId: "592",
    VDNumber: "10810819",
    RegisteredPopulation: "201",
    VotingStationName: "MTUNZI JUNIOR SECONDARY SCHOOL",
    Address: "N/A  NKONDWANE  MNQUMA [BUTTERWORTH]",
    Latitude: "-32.4159",
    Longitude: "28.1928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1111",
    WardLookupId: "592",
    VDNumber: "10810842",
    RegisteredPopulation: "508",
    VotingStationName: "MRAUZELI  TENT",
    Address: "MRAUZELI LOCATION  QOBO QOBO A/A  CENTANE",
    Latitude: "-32.4201",
    Longitude: "28.2449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1112",
    WardLookupId: "593",
    VDNumber: "10790704",
    RegisteredPopulation: "392",
    VotingStationName: "BOOI FARM JUNIOR SECONDARY SCHOOL",
    Address: "MAYEKISO FARM  MSOBOMVU  MNQUMA [BUTTERWORTH]",
    Latitude: "-32.3156",
    Longitude: "28.2365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1113",
    WardLookupId: "593",
    VDNumber: "10810202",
    RegisteredPopulation: "546",
    VotingStationName: "ANTA JUNIOR PRIMARY SCHOOL",
    Address: "TEKO C A/A  TEKO C ADMINISTRATIVE AREA  CENTANE",
    Latitude: "-32.3666",
    Longitude: "28.3217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1114",
    WardLookupId: "593",
    VDNumber: "10810213",
    RegisteredPopulation: "644",
    VotingStationName: "MAQELE JUNIOR SECONDARY SCHOOL",
    Address: "QORA A A/A  QORA A ADMINISTRATIVE AREA  CENTANE",
    Latitude: "-32.338",
    Longitude: "28.3195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1115",
    WardLookupId: "593",
    VDNumber: "10810280",
    RegisteredPopulation: "772",
    VotingStationName: "TEKO SPRINGS JUNIOR SECONDARY SCHOOL",
    Address: "TEKO SPRINGS  TEKO SPRINGS ADMIN AREA  CENTANE",
    Latitude: "-32.3857",
    Longitude: "28.3058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1116",
    WardLookupId: "593",
    VDNumber: "10810303",
    RegisteredPopulation: "559",
    VotingStationName: "TEKO KONA JUNIOR SECONDARY SCHOOL",
    Address: "TEKO KONA A/A  TEKO KONA ADMINISTRATIVE AREA  CENTANE",
    Latitude: "-32.3531",
    Longitude: "28.2807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1117",
    WardLookupId: "593",
    VDNumber: "10810606",
    RegisteredPopulation: "246",
    VotingStationName: "QUMBULWANA JUNIOR SECONDARY SCHOOL",
    Address: "QUMBULWANA LOC   NGUNDUZA A/A  CENTANE",
    Latitude: "-32.3711",
    Longitude: "28.3556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1118",
    WardLookupId: "593",
    VDNumber: "10810651",
    RegisteredPopulation: "647",
    VotingStationName: "THEKO FIHLA JUNIOR SECONDARY SCHOOL",
    Address: "TEKO FIHLA LOC   TEKO A/A  KENTANI",
    Latitude: "-32.369",
    Longitude: "28.2577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1119",
    WardLookupId: "593",
    VDNumber: "10810831",
    RegisteredPopulation: "220",
    VotingStationName: "SALAKUPHATHWA JUNIOR SECONDARY SCHOOL",
    Address: "NGQOKWENI LOC  NGQOKWENI A/A  CENTANE",
    Latitude: "-32.3278",
    Longitude: "28.2624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1120",
    WardLookupId: "593",
    VDNumber: "10810864",
    RegisteredPopulation: "176",
    VotingStationName: "NGUBEZULU J.S.S",
    Address: "MKHONKOTHO  MKHONKOTHO A/A  KENTANI",
    Latitude: "-32.3019",
    Longitude: "28.2652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1121",
    WardLookupId: "593",
    VDNumber: "10810886",
    RegisteredPopulation: "421",
    VotingStationName: "MAKI JUNIOR SECONDARY SCHOOL",
    Address: "KWAMAKI LOCATION  NGQOKWENI   CENTANE",
    Latitude: "-32.3295",
    Longitude: "28.2824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1122",
    WardLookupId: "593",
    VDNumber: "10810897",
    RegisteredPopulation: "231",
    VotingStationName: "DYANTYI  JUNIOR SECONDARY SCHOOL",
    Address: "NKELEKETHE LOCATION  TEKO A/A  CENTANE",
    Latitude: "-32.3933",
    Longitude: "28.3298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1123",
    WardLookupId: "594",
    VDNumber: "10810055",
    RegisteredPopulation: "734",
    VotingStationName: "TIYO SOGA JUNIOR SECONDARY SCHOOL",
    Address: "KABAKAZI A/A  KABAKAZI ADMINISTRATIVE AREA  CENTANE",
    Latitude: "-32.4285",
    Longitude: "28.4718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1124",
    WardLookupId: "594",
    VDNumber: "10810077",
    RegisteredPopulation: "578",
    VotingStationName: "NGUNDUZA JUNIOR PRIMARY SCHOOL",
    Address: "QORA B A/A  QORA ADMINISTRATIVE AREA  CENTANE",
    Latitude: "-32.3756",
    Longitude: "28.3718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1125",
    WardLookupId: "594",
    VDNumber: "10810101",
    RegisteredPopulation: "658",
    VotingStationName: "CENTULI JUNIOR SECONDARY SCHOOL",
    Address: "CENTULI A/A  CENTULI ADMINISTRATIVE AREA  CENTANE",
    Latitude: "-32.4265",
    Longitude: "28.3717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1126",
    WardLookupId: "594",
    VDNumber: "10810189",
    RegisteredPopulation: "664",
    VotingStationName: "DALIBANGO JUNIOR SECONDARY SCHOOL",
    Address: "CENTULI A/A  CENTULI ADMINISTRATIVE AREA  CENTANE",
    Latitude: "-32.4141",
    Longitude: "28.3829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1127",
    WardLookupId: "594",
    VDNumber: "10810594",
    RegisteredPopulation: "494",
    VotingStationName: "KWA KHABAKAZI SCHOOL",
    Address: "KABAKAZI LOC   KABAKAZI A/A  CENTANI",
    Latitude: "-32.4205",
    Longitude: "28.5074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1128",
    WardLookupId: "594",
    VDNumber: "10810909",
    RegisteredPopulation: "105",
    VotingStationName: "KWAMATHOLE SENIOR PRIMARY SCHOOL",
    Address: "EMRHOTSHOZWENI LOATION  CENTULI  CENTANE",
    Latitude: "-32.4153",
    Longitude: "28.3449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1129",
    WardLookupId: "594",
    VDNumber: "10840238",
    RegisteredPopulation: "256",
    VotingStationName: "NDONQA JUNIOR SECONDARY SCHOOL",
    Address: "NDOQA LOC  NDONQA A/A  CENTANE",
    Latitude: "-32.4011",
    Longitude: "28.4423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1130",
    WardLookupId: "594",
    VDNumber: "10841396",
    RegisteredPopulation: "436",
    VotingStationName: "NYITYHABA JUNIOR SECONDARY SCHOOL",
    Address: "NYITYHABA LOC  KABAKAZI A/A  CENTANE",
    Latitude: "-32.4237",
    Longitude: "28.4598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1131",
    WardLookupId: "595",
    VDNumber: "10810099",
    RegisteredPopulation: "288",
    VotingStationName: "VUSANI JUNIOR SECONDARY SCHOOL",
    Address: "ZINGCUKA A/A  ZINGUKA ADMINISTRATIVE AREA  CENTANE",
    Latitude: "-32.4309",
    Longitude: "28.4481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1132",
    WardLookupId: "595",
    VDNumber: "10810167",
    RegisteredPopulation: "490",
    VotingStationName: "DONDASHE SECONDARY SCHOOL",
    Address: "ZIBUNU A/A  CENTANE",
    Latitude: "-32.4884",
    Longitude: "28.4036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1133",
    WardLookupId: "595",
    VDNumber: "10810178",
    RegisteredPopulation: "656",
    VotingStationName: "GODIDI JUNIOR SECONDARY SCHOOL",
    Address: "GODIDI A/A  GODIDI ADMINISTRATIVE AREA  CENTANE",
    Latitude: "-32.4464",
    Longitude: "28.394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1134",
    WardLookupId: "595",
    VDNumber: "10810392",
    RegisteredPopulation: "395",
    VotingStationName: "MACIBE SENIOR SECONDARY SCHOOL",
    Address: "MACIBE A/A  MACIBE ADMINISTRATIVE AREA  CENTANE",
    Latitude: "-32.4828",
    Longitude: "28.33",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1135",
    WardLookupId: "595",
    VDNumber: "10810404",
    RegisteredPopulation: "408",
    VotingStationName: "MAGIQWENI JUNIOR SECONDARY SCHOOL",
    Address: "QOLORA A VILLAGE  QLORA A ADMIN. AREA  CENTANE",
    Latitude: "-32.491",
    Longitude: "28.2813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1136",
    WardLookupId: "595",
    VDNumber: "10810493",
    RegisteredPopulation: "473",
    VotingStationName: "NJINGINI JUNIOR SECONDARY SCHOOL",
    Address: "NJINGINI  NJINGINI A/A  CENTANE",
    Latitude: "-32.4626",
    Longitude: "28.2699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1137",
    WardLookupId: "595",
    VDNumber: "10810572",
    RegisteredPopulation: "275",
    VotingStationName: "NXAXO JUNIOR SECONDARY SCHOOL",
    Address: "NXAXO  BUTTERWORTH",
    Latitude: "-32.4609",
    Longitude: "28.4261",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1138",
    WardLookupId: "595",
    VDNumber: "10810729",
    RegisteredPopulation: "215",
    VotingStationName: "MANDENDE JUNIOR SECONDARY SCHOOL",
    Address: "EMNTLA GWELANE LOC  GWELANE A/A  CENTANE",
    Latitude: "-32.4426",
    Longitude: "28.4662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1139",
    WardLookupId: "595",
    VDNumber: "10810730",
    RegisteredPopulation: "291",
    VotingStationName: "HLOKOMILE JUNIOR SECONDARY SCHOOL",
    Address: "NXAXO LOC  NXAXO A/A  CENTANE",
    Latitude: "-32.4739",
    Longitude: "28.4193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1140",
    WardLookupId: "595",
    VDNumber: "10810763",
    RegisteredPopulation: "40",
    VotingStationName: "COLUMBER CHURCH",
    Address: "COLUMBER MISSION LOC  MACIBE A/A  CENTANE",
    Latitude: "-32.4812",
    Longitude: "28.3638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1141",
    WardLookupId: "595",
    VDNumber: "10810774",
    RegisteredPopulation: "199",
    VotingStationName: "NZIMA SENIOR PRIMARY SCHOOL",
    Address: "N/A  CENTANE  MNQUMA [BUTTERWORTH]",
    Latitude: "-32.465",
    Longitude: "28.3435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1142",
    WardLookupId: "595",
    VDNumber: "10810785",
    RegisteredPopulation: "405",
    VotingStationName: "KHANYISA SENIOR PRIMARY SCHOOL",
    Address: "HUKU LOC   MACIBE A/A  CENTANE",
    Latitude: "-32.4796",
    Longitude: "28.2852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1143",
    WardLookupId: "595",
    VDNumber: "10810910",
    RegisteredPopulation: "84",
    VotingStationName: "NCALUKENI SENIOR PRIMARY SCHOOL",
    Address: "NCALUKENI LOCATION  QOMBOLO A/A  CENTANE",
    Latitude: "-32.5262",
    Longitude: "28.2673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1144",
    WardLookupId: "596",
    VDNumber: "10810011",
    RegisteredPopulation: "708",
    VotingStationName: "GCINA JUNIOR PRIMARY SCHOOL",
    Address: "GCINA A/A  CENTANE",
    Latitude: "-32.4618",
    Longitude: "28.6279",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1145",
    WardLookupId: "596",
    VDNumber: "10810033",
    RegisteredPopulation: "431",
    VotingStationName: "GQUNQE JUNIOR PRIMARY SCHOOL",
    Address: "GQUNQE A/A  CENTANE",
    Latitude: "-32.4702",
    Longitude: "28.5678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1146",
    WardLookupId: "596",
    VDNumber: "10810145",
    RegisteredPopulation: "471",
    VotingStationName: "LUSIZI JUNIOR SECONDARY SCHOOL",
    Address: "CEBE A/A  CENTANE",
    Latitude: "-32.4727",
    Longitude: "28.4879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1147",
    WardLookupId: "596",
    VDNumber: "10810527",
    RegisteredPopulation: "588",
    VotingStationName: "CEBE JUNIOR SECONDARY SCHOOL",
    Address: "CEBE A/A  CENTANI",
    Latitude: "-32.5201",
    Longitude: "28.5305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1148",
    WardLookupId: "596",
    VDNumber: "10810538",
    RegisteredPopulation: "455",
    VotingStationName: "NGQWARHA JUNIOR SECONDARY SCHOOL",
    Address: "NGQWARHA A/A  NQAMAKWE",
    Latitude: "-32.4343",
    Longitude: "28.5559",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1149",
    WardLookupId: "596",
    VDNumber: "10810549",
    RegisteredPopulation: "274",
    VotingStationName: "MANYUBE TENT",
    Address: "MANYUBE  MUNYUBE A/A  BUTTERWORTH",
    Latitude: "-32.4544",
    Longitude: "28.6269",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1150",
    WardLookupId: "596",
    VDNumber: "10810550",
    RegisteredPopulation: "559",
    VotingStationName: "MAQOMA JUNIOR SECONDARY SCHOOL",
    Address: "GQUNQE  BUTTERWORTH",
    Latitude: "-32.4407",
    Longitude: "28.5235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1151",
    WardLookupId: "596",
    VDNumber: "10810561",
    RegisteredPopulation: "343",
    VotingStationName: "NDEVUZIBOMVU JUNIOR SECONDARY SCHOOL",
    Address: "ZINGCUKA  BUTTERWORTH",
    Latitude: "-32.4965",
    Longitude: "28.5266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1152",
    WardLookupId: "596",
    VDNumber: "10810583",
    RegisteredPopulation: "188",
    VotingStationName: "GREVE JUNIOR SECONDARY SCHOOL",
    Address: "GREVE LOC  GREVE A/A  CENTANE",
    Latitude: "-32.5103",
    Longitude: "28.553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1153",
    WardLookupId: "596",
    VDNumber: "10810718",
    RegisteredPopulation: "183",
    VotingStationName: "DIKO SENIOR PRIMARY SCHOOL",
    Address: "DIKO LOC  LUSIZI A/A  CENTANE",
    Latitude: "-32.4569",
    Longitude: "28.5071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1154",
    WardLookupId: "596",
    VDNumber: "10810853",
    RegisteredPopulation: "338",
    VotingStationName: "ZALU JUNIOR SECONDARY SCHOOL",
    Address: "ZALU LOC  GQUNQE A/A  CENTANE",
    Latitude: "-32.4888",
    Longitude: "28.5798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1155",
    WardLookupId: "597",
    VDNumber: "10810022",
    RegisteredPopulation: "425",
    VotingStationName: "NQUSI JUNIOR SECONDARY SCHOOL",
    Address: "NQUSI A/A  CENTANE",
    Latitude: "-32.5308",
    Longitude: "28.4376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1156",
    WardLookupId: "597",
    VDNumber: "10810044",
    RegisteredPopulation: "465",
    VotingStationName: "NCERANA JUNIOR SECONDARY SCHOOL",
    Address: "NCERANA LOC  TAKAZI A/A  CENTANI",
    Latitude: "-32.5545",
    Longitude: "28.4184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1157",
    WardLookupId: "597",
    VDNumber: "10810066",
    RegisteredPopulation: "192",
    VotingStationName: "MNYAKA JUNIOR SECONDARY SCHOOL",
    Address: "TAKAZI A/A  CENTANE",
    Latitude: "-32.5798",
    Longitude: "28.4284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1158",
    WardLookupId: "597",
    VDNumber: "10810134",
    RegisteredPopulation: "622",
    VotingStationName: "KOBONQABA MOUTH JUNIOR SECONDARY SCHOOL",
    Address: "KOBONQABA A/A  CENTANE",
    Latitude: "-32.5653",
    Longitude: "28.4884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1159",
    WardLookupId: "597",
    VDNumber: "10810156",
    RegisteredPopulation: "602",
    VotingStationName: "WILI JUNIOR SECONDARY SCHOOL",
    Address: "NXAXO B A/A  NXAXO ADMINISTRATIVE AREA  CENTANE",
    Latitude: "-32.5152",
    Longitude: "28.4825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1160",
    WardLookupId: "597",
    VDNumber: "10810190",
    RegisteredPopulation: "507",
    VotingStationName: "CENTANE JUNIOR SECONDARY SCHOOL",
    Address: "NYUTURA A/A  NYUTURA ADMINISTRATIVE AREA  CENTANE",
    Latitude: "-32.5163",
    Longitude: "28.3383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1161",
    WardLookupId: "597",
    VDNumber: "10810268",
    RegisteredPopulation: "826",
    VotingStationName: "MANYANO JUNIOR SECONDARY SCHOOL",
    Address: "NGCIZELE A/A  CENTANE",
    Latitude: "-32.5962",
    Longitude: "28.4322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1162",
    WardLookupId: "597",
    VDNumber: "10810673",
    RegisteredPopulation: "414",
    VotingStationName: "LESLIE JUNIOR SECONDARY SCHOOL",
    Address: "NCERANA  CENTANE",
    Latitude: "-32.5125",
    Longitude: "28.393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1163",
    WardLookupId: "597",
    VDNumber: "10810752",
    RegisteredPopulation: "230",
    VotingStationName: "MGOBOZI SENIOR PRIMARY SCHOOL",
    Address: "MGOBOZI LOC  MACIBE A/A  CENTANE",
    Latitude: "-32.497",
    Longitude: "28.3371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1164",
    WardLookupId: "597",
    VDNumber: "10810921",
    RegisteredPopulation: "225",
    VotingStationName: "ANGLICAN  CHURCH",
    Address: "GOTYIBENI LOCATION  MACIBE A/A  CENTANE",
    Latitude: "-32.4917",
    Longitude: "28.3248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1165",
    WardLookupId: "598",
    VDNumber: "10810088",
    RegisteredPopulation: "379",
    VotingStationName: "TSHONGWENI JUNIOR SECONDARY SCHOOL",
    Address: "QOLORA D A/A  QOLORA ADMINISTRATIVE AREA  CENTANE",
    Latitude: "-32.585",
    Longitude: "28.3749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1166",
    WardLookupId: "598",
    VDNumber: "10810112",
    RegisteredPopulation: "510",
    VotingStationName: "QOLORA BY SEA JUNIOR SECONDARY SCHOOL",
    Address: "QOLORA E A/A  QOLORA ADMINISTRATIVE AREA  CENTANE",
    Latitude: "-32.6338",
    Longitude: "28.4133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1167",
    WardLookupId: "598",
    VDNumber: "10810235",
    RegisteredPopulation: "684",
    VotingStationName: "LUKHANYO JUNIOR SECONDARY SCHOOL",
    Address: "GXARA A/A  GXARA ADMINISTRATIVE AREA  CENTANE",
    Latitude: "-32.5882",
    Longitude: "28.3296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1168",
    WardLookupId: "598",
    VDNumber: "10810381",
    RegisteredPopulation: "453",
    VotingStationName: "PONANA LUBISI JUNIOR SECONDARY SCHOOL",
    Address: "KEI MOUTH A/A  KEI MOUTH ADMIN.AREA  CENTANE",
    Latitude: "-32.6362",
    Longitude: "28.3784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1169",
    WardLookupId: "598",
    VDNumber: "10810448",
    RegisteredPopulation: "706",
    VotingStationName: "QOLORA FENI JUNIOR SECONDARY SCHOOL",
    Address: "ZIMBABA LOC  QOLORA  ADMIN. AREA  CENTANE",
    Latitude: "-32.5843",
    Longitude: "28.2917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1170",
    WardLookupId: "598",
    VDNumber: "10810505",
    RegisteredPopulation: "323",
    VotingStationName: "NYUMAGA JUNIOR SECONDARY SCHOOL",
    Address: "KU MANYUBA LOC  NYUMAGA A/A  CENTANE",
    Latitude: "-32.557",
    Longitude: "28.2738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1171",
    WardLookupId: "598",
    VDNumber: "10810516",
    RegisteredPopulation: "165",
    VotingStationName: "MAKUKHANYE JUNIOR SECONDARY SCHOOL",
    Address: "KOBONQABA  MOUTH  KOBONQABA MOUTH A/A  CENTANE",
    Latitude: "-32.5998",
    Longitude: "28.4017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1172",
    WardLookupId: "598",
    VDNumber: "10810684",
    RegisteredPopulation: "441",
    VotingStationName: "SISEKO JUNIOR SECONDARY SCHOOL",
    Address: "CWEBENI LOC  KWA NONTSHINGA A/A  CENTANE",
    Latitude: "-32.607",
    Longitude: "28.3581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1173",
    WardLookupId: "598",
    VDNumber: "10810695",
    RegisteredPopulation: "162",
    VotingStationName: "SEZELA JUNIOR SECONDARY SCHOOL",
    Address: "SEZELA LOC  KWA FENI A/A  CENTANE",
    Latitude: "-32.5968",
    Longitude: "28.2545",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1174",
    WardLookupId: "598",
    VDNumber: "10810707",
    RegisteredPopulation: "276",
    VotingStationName: "TSHAWE SENIOR PRIMARY SCHOOL",
    Address: "TSHAWE LOC  FENI A/A  CENTANE",
    Latitude: "-32.6039",
    Longitude: "28.3007",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1175",
    WardLookupId: "598",
    VDNumber: "10810796",
    RegisteredPopulation: "234",
    VotingStationName: "DALUXOLO JUNIOR SECONDARY SCHOOL",
    Address: "DALUXOLO LOC  QOLORA A/A  CENTANE",
    Latitude: "-32.5563",
    Longitude: "28.3001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1176",
    WardLookupId: "599",
    VDNumber: "10660014",
    RegisteredPopulation: "1602",
    VotingStationName: "PATRICIA NOAH JUNIOR SECONDARY SCHOOL",
    Address: "CENTANE  VILLAGE   MGOBOZI A/A  CENTANE VILLAGE",
    Latitude: "-32.505",
    Longitude: "28.3113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1177",
    WardLookupId: "599",
    VDNumber: "10810358",
    RegisteredPopulation: "383",
    VotingStationName: "NZANZANA JUNIOR SECONDARY SCHOOL",
    Address: "NGEDE A/A  NGEDE ADMINISTRATIVE AREA  CENTANE",
    Latitude: "-32.5599",
    Longitude: "28.3625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1178",
    WardLookupId: "599",
    VDNumber: "10810370",
    RegisteredPopulation: "281",
    VotingStationName: "MSENTO JUNIOR SECONDARY SCHOOL",
    Address: "MACHELESI LOC  MSENTO A/A  CENTANE",
    Latitude: "-32.5232",
    Longitude: "28.3151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1179",
    WardLookupId: "599",
    VDNumber: "10810415",
    RegisteredPopulation: "349",
    VotingStationName: "DE VILLIERS MPANGELE JUNIOR SECONDARY SCHOOL",
    Address: "QOMBOLO A A/A  QOMBOLO ADMIN. AREA  CENTANE",
    Latitude: "-32.5242",
    Longitude: "28.2253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1180",
    WardLookupId: "599",
    VDNumber: "10810426",
    RegisteredPopulation: "340",
    VotingStationName: "KULOMBOMBO SENIOR SECONDARY SCHOOL",
    Address: "MNGQALASINI LOC  QOMBOLO A/A  CENTANE",
    Latitude: "-32.5635",
    Longitude: "28.2248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1181",
    WardLookupId: "599",
    VDNumber: "10810437",
    RegisteredPopulation: "298",
    VotingStationName: "MAMBALU JUNIOR PRIMARY SCHOOL",
    Address: "KWAGOLO LOC  NCALUKENI A/A  CENTANE",
    Latitude: "-32.5373",
    Longitude: "28.2621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1182",
    WardLookupId: "599",
    VDNumber: "10810482",
    RegisteredPopulation: "491",
    VotingStationName: "QOMBOLO JUNIOR SECONDARY SCHOOL",
    Address: "JOJWENI LOC  QOMBOLO A/A  CENTANE",
    Latitude: "-32.5417",
    Longitude: "28.2451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1183",
    WardLookupId: "599",
    VDNumber: "10810628",
    RegisteredPopulation: "285",
    VotingStationName: "MPENTSE JUNIOR SECONDARY SCHOOL",
    Address: "MPENTSE LOCATION  MPENTSE A/A  CENTANE",
    Latitude: "-32.5315",
    Longitude: "28.2999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1184",
    WardLookupId: "599",
    VDNumber: "10810875",
    RegisteredPopulation: "290",
    VotingStationName: "MASIZAKHE  JUNIOR SECONDARY  SCHOOL",
    Address: "JINGQI A/A  KQAYISO  NGEDE",
    Latitude: "-32.5473",
    Longitude: "28.3226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1185",
    WardLookupId: "600",
    VDNumber: "10810325",
    RegisteredPopulation: "310",
    VotingStationName: "THALA JUNIOR SECONDARY SCHOOL",
    Address: "KWA THALA LOC  QINA A/A  CENTANE",
    Latitude: "-32.4834",
    Longitude: "28.1681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1186",
    WardLookupId: "600",
    VDNumber: "10810336",
    RegisteredPopulation: "467",
    VotingStationName: "HLANGANI JUNIOR SECONDARY SCHOOL",
    Address: "HLANGANI LOC   QINA A/A  CENTANE",
    Latitude: "-32.4524",
    Longitude: "28.1825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1187",
    WardLookupId: "600",
    VDNumber: "10810347",
    RegisteredPopulation: "485",
    VotingStationName: "GOBE JUNIOR SECONDARY SCHOOL",
    Address: "GOBE LOCATION  GOBE A/A  CENTANE",
    Latitude: "-32.4509",
    Longitude: "28.2807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1188",
    WardLookupId: "600",
    VDNumber: "10810369",
    RegisteredPopulation: "408",
    VotingStationName: "NONYEMBEZI JUNIOR SECONDARY SCHOOL",
    Address: "NONYEMBEZI LOC  MNYAMENI ADMIN. AREA  CENTANE",
    Latitude: "-32.4831",
    Longitude: "28.2216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1189",
    WardLookupId: "600",
    VDNumber: "10810460",
    RegisteredPopulation: "410",
    VotingStationName: "JONGINTABA JUNIOR SECONDARY SCHOOL",
    Address: "JOJWENI LOCATION  QINA A/A  CENTANE",
    Latitude: "-32.4363",
    Longitude: "28.2284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1190",
    WardLookupId: "600",
    VDNumber: "10810471",
    RegisteredPopulation: "486",
    VotingStationName: "MDANGE JUNIOR SECONDARY SCHOOL",
    Address: "QOMBOLO A/A  CENTANE",
    Latitude: "-32.5023",
    Longitude: "28.2518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1191",
    WardLookupId: "600",
    VDNumber: "10810617",
    RegisteredPopulation: "562",
    VotingStationName: "GOBE COMMERCIAL SENIOR SECONDARY SCHOOL",
    Address: "GOBE  GOBE S.S.S  CENTANE",
    Latitude: "-32.4663",
    Longitude: "28.2861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1192",
    WardLookupId: "600",
    VDNumber: "10810639",
    RegisteredPopulation: "437",
    VotingStationName: "ISIGANGALA SCHOOL",
    Address: "ISIGANGALA  ISIGANGALA A/A  CENTANE",
    Latitude: "-32.4562",
    Longitude: "28.2406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1193",
    WardLookupId: "600",
    VDNumber: "10810741",
    RegisteredPopulation: "255",
    VotingStationName: "NGQANDA JUNIOR SECONDARY SCHOOL",
    Address: "MAZANGWENI LOC   NGQANDA A/A  CENTANE",
    Latitude: "-32.4298",
    Longitude: "28.3061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1194",
    WardLookupId: "600",
    VDNumber: "10810808",
    RegisteredPopulation: "299",
    VotingStationName: "TEMBANI JUNIOR SECONDARY SCHOOL",
    Address: "THEMBANI LOCATION  QINA A/A  CENTANE",
    Latitude: "-32.4667",
    Longitude: "28.1962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1195",
    WardLookupId: "600",
    VDNumber: "10810820",
    RegisteredPopulation: "215",
    VotingStationName: "SEKU SENIOR PRIMARY SCHOOL",
    Address: "KWA SEKU  LOC  NGQANDA A/A  CENTANE",
    Latitude: "-32.4388",
    Longitude: "28.2778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1196",
    WardLookupId: "601",
    VDNumber: "10770049",
    RegisteredPopulation: "1048",
    VotingStationName: "CINTSA COMMUNITY HALL",
    Address: "CINTSA EAST LOCATION  EAST LONDON",
    Latitude: "-32.8175",
    Longitude: "28.108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1197",
    WardLookupId: "601",
    VDNumber: "10770072",
    RegisteredPopulation: "519",
    VotingStationName: "CINTSA COUNTRY CLUB",
    Address: "CINTSA COUNTRY CLUB  CINTSA EAST",
    Latitude: "-32.8275",
    Longitude: "28.1152",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1198",
    WardLookupId: "601",
    VDNumber: "10820067",
    RegisteredPopulation: "375",
    VotingStationName: "BULUGHA FARM SCHOOL",
    Address: "BULUGHA  BULUGHA FARM  CINTSA",
    Latitude: "-32.8791",
    Longitude: "28.0516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1199",
    WardLookupId: "601",
    VDNumber: "10820494",
    RegisteredPopulation: "173",
    VotingStationName: "EMMANUEL MISSION",
    Address: "BYLETTS FARM  CINTSA EAST  KOMGA",
    Latitude: "-32.8278",
    Longitude: "28.0529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1200",
    WardLookupId: "601",
    VDNumber: "10820955",
    RegisteredPopulation: "670",
    VotingStationName: "GLEN EDEN CHURCH",
    Address: "GLEN EDEN  CINTSA",
    Latitude: "-32.8869",
    Longitude: "28.0919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1201",
    WardLookupId: "602",
    VDNumber: "10820034",
    RegisteredPopulation: "501",
    VotingStationName: "NGXINGXOLO PUBLIC SCHOOL",
    Address: "NGXINGXOLO LOCATION  MOOIPLAAS LOCATION  KOMGA",
    Latitude: "-32.7366",
    Longitude: "28.0541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1202",
    WardLookupId: "602",
    VDNumber: "10820056",
    RegisteredPopulation: "413",
    VotingStationName: "CEFANE PUBLIC SCHOOL",
    Address: "CEFANE VILLAGE  MOOIPLAAS  KOMGA",
    Latitude: "-32.765",
    Longitude: "28.0553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1203",
    WardLookupId: "602",
    VDNumber: "10820449",
    RegisteredPopulation: "517",
    VotingStationName: "MAKHAZI PUBLIC SCHOOL",
    Address: "MAKHAZI VILLAGE  MOOIPLAAS  KOMGA",
    Latitude: "-32.7206",
    Longitude: "28.0558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1204",
    WardLookupId: "602",
    VDNumber: "10820461",
    RegisteredPopulation: "119",
    VotingStationName: "PHAPHAMA PUBLIC SCHOOL",
    Address: "MAGRANGXENI VILLAGE  GREAT KEI",
    Latitude: "-32.7276",
    Longitude: "28.1006",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1205",
    WardLookupId: "602",
    VDNumber: "10820720",
    RegisteredPopulation: "520",
    VotingStationName: "SILATSHA PUBLIC SCHOOL",
    Address: "P.O.BOX 1561,MAKAZI  MOOIPLAAS  GREAT KEI",
    Latitude: "-32.7336",
    Longitude: "28.0679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1206",
    WardLookupId: "602",
    VDNumber: "10820988",
    RegisteredPopulation: "151",
    VotingStationName: "KWANOBOMVU",
    Address: "TAINTON ROAD  MOOIPLAAS  KOMGA",
    Latitude: "-32.753",
    Longitude: "28.0244",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1207",
    WardLookupId: "603",
    VDNumber: "10820045",
    RegisteredPopulation: "694",
    VotingStationName: "NYARHA COMMUNITY HALL",
    Address: "NCALUKENI VILLAGE  MOOIPLAAS  KOMGA",
    Latitude: "-32.7395",
    Longitude: "28.1347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1208",
    WardLookupId: "603",
    VDNumber: "10820427",
    RegisteredPopulation: "146",
    VotingStationName: "LINGELETHU CRECHE",
    Address: "STHUNGU LOCATION  MOOIPLAAS  EAST LONDON",
    Latitude: "-32.7575",
    Longitude: "28.1333",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1209",
    WardLookupId: "603",
    VDNumber: "10820438",
    RegisteredPopulation: "182",
    VotingStationName: "LUKHANYISO DAY CARE CENTRE",
    Address: "BHOLA LOCATION  MOOIPLAAS  EAST LONDON",
    Latitude: "-32.7658",
    Longitude: "28.1555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1210",
    WardLookupId: "603",
    VDNumber: "10820450",
    RegisteredPopulation: "419",
    VotingStationName: "UMZUVUKILE SENIOR SECONDARY SCHOOL",
    Address: "SOTHO LOCATION  MOOIPLAAS  KOMGA",
    Latitude: "-32.6993",
    Longitude: "28.1336",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1211",
    WardLookupId: "603",
    VDNumber: "10820641",
    RegisteredPopulation: "340",
    VotingStationName: "DIPHINI COMMUNITY HALL",
    Address: "DIPHINI LOCATION,MOOIPLAAS  DIPHINI LOCATION  GREAT KEI",
    Latitude: "-32.728",
    Longitude: "28.1256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1212",
    WardLookupId: "603",
    VDNumber: "10820753",
    RegisteredPopulation: "326",
    VotingStationName: "ZAMUKUKHANYA DAY CARE CENTRE",
    Address: "SOTHO LOCATION  KOMGA  GREAT KEI",
    Latitude: "-32.6956",
    Longitude: "28.1277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1213",
    WardLookupId: "603",
    VDNumber: "10880063",
    RegisteredPopulation: "243",
    VotingStationName: "FORTWARWICK FARM SCHOOL",
    Address: "LYOD  MOOIPLAAS  KOMGA",
    Latitude: "-32.6797",
    Longitude: "28.0949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1214",
    WardLookupId: "604",
    VDNumber: "10820012",
    RegisteredPopulation: "331",
    VotingStationName: "LUSASA COMBINED SCHOOL",
    Address: "P.O.BOX 25,LUSASA VILLAGE  MOOIPLAAS  GREAT KEI",
    Latitude: "-32.6952",
    Longitude: "28.0749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1215",
    WardLookupId: "604",
    VDNumber: "10820023",
    RegisteredPopulation: "609",
    VotingStationName: "MZWINI COMMUNITY HALL",
    Address: "MZWINI VILLAGE  MOOIPLAAS  GREAT KEI",
    Latitude: "-32.7045",
    Longitude: "28.0341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1216",
    WardLookupId: "604",
    VDNumber: "10820573",
    RegisteredPopulation: "194",
    VotingStationName: "BLUE WATER TENT",
    Address: "BLUE WATER  KOMGA",
    Latitude: "-32.7274",
    Longitude: "27.9515",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1217",
    WardLookupId: "604",
    VDNumber: "10820652",
    RegisteredPopulation: "268",
    VotingStationName: "MANGQUKELA HALL",
    Address: "P.O.BOX 57,MANGQUKELA,MOOIPLAAS  MANGQUKELA VILLAGE  GREAT KEI",
    Latitude: "-32.6823",
    Longitude: "28.0673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1218",
    WardLookupId: "604",
    VDNumber: "10820663",
    RegisteredPopulation: "272",
    VotingStationName: "MKOMATI SPORTS FIELD",
    Address: "MZWINI LOCATION  MOOIPLAAS  EAST LONDON",
    Latitude: "-32.7013",
    Longitude: "28.0451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1219",
    WardLookupId: "604",
    VDNumber: "10820674",
    RegisteredPopulation: "265",
    VotingStationName: "BELEKUMNTWANA COMMUNITY HALL",
    Address: "BELEKUMNTWANA  MOOIPLAAS  EAST LONDON",
    Latitude: "-32.6952",
    Longitude: "28.0991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1220",
    WardLookupId: "604",
    VDNumber: "10820685",
    RegisteredPopulation: "543",
    VotingStationName: "ELITYENI DAY CARE CENTRE",
    Address: "ENKULULEKWENI STREET,SOTHO LOCATION  SOTHO LOCATION  GREAT KEI",
    Latitude: "-32.6865",
    Longitude: "28.12",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1221",
    WardLookupId: "604",
    VDNumber: "10820696",
    RegisteredPopulation: "434",
    VotingStationName: "MANDELA PARK CRECHE",
    Address: "MANDELA PARK  MOOPLAAS  EAST LONDON",
    Latitude: "-32.7049",
    Longitude: "28.0235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1222",
    WardLookupId: "605",
    VDNumber: "10630011",
    RegisteredPopulation: "328",
    VotingStationName: "KEI MOUTH TOWN HALL",
    Address: "29 FRONTIER RD  KEI MOUTH  KOMGA DISTRICT",
    Latitude: "-32.6815",
    Longitude: "28.3795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1223",
    WardLookupId: "605",
    VDNumber: "10630022",
    RegisteredPopulation: "1082",
    VotingStationName: "ICWILI  COMMUNITY HALL",
    Address: "ICWILI TOWNSHIP  KEI MOUTH",
    Latitude: "-32.6774",
    Longitude: "28.3631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1224",
    WardLookupId: "605",
    VDNumber: "10630033",
    RegisteredPopulation: "525",
    VotingStationName: "MORGAN BAY COMMUNITY HALL",
    Address: "IGXARHA VILLAGE  MORGAN BAY  MORGAN BAY",
    Latitude: "-32.7021",
    Longitude: "28.3292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1225",
    WardLookupId: "605",
    VDNumber: "10770016",
    RegisteredPopulation: "205",
    VotingStationName: "HAGA HAGA COMMUNITY HALL",
    Address: "HAGA HAGA  MARSHSTRAND  KOMGA",
    Latitude: "-32.7639",
    Longitude: "28.2452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1226",
    WardLookupId: "605",
    VDNumber: "10880030",
    RegisteredPopulation: "122",
    VotingStationName: "EXTENSION FARM SCHOOL",
    Address: "P.O.BOX 54, HAGA-HAGA,  HAGA HAGA VILLAGE  GREAT KEI",
    Latitude: "-32.7287",
    Longitude: "28.2355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1227",
    WardLookupId: "605",
    VDNumber: "10880085",
    RegisteredPopulation: "94",
    VotingStationName: "FERNDALE PUBLIC SCHOOL",
    Address: "FERNDALE FARM  MORGAN BAY  KOMGA",
    Latitude: "-32.6652",
    Longitude: "28.2954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1228",
    WardLookupId: "605",
    VDNumber: "10880119",
    RegisteredPopulation: "101",
    VotingStationName: "SHAFTON FARM",
    Address: "SHAFTON FARM  KEI MOUTH  KEI MOUTH",
    Latitude: "-32.644",
    Longitude: "28.2049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1229",
    WardLookupId: "606",
    VDNumber: "10680016",
    RegisteredPopulation: "776",
    VotingStationName: "KOMGA TOWN HALL",
    Address: "0 CRN OF NORTH AND MAIN STREET  KOMGA  KOMGA",
    Latitude: "-32.5764",
    Longitude: "27.8924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1230",
    WardLookupId: "606",
    VDNumber: "10680049",
    RegisteredPopulation: "1667",
    VotingStationName: "KOMGA GREAT HALL",
    Address: "MZOMHLE LOCATION  KOMGA",
    Latitude: "-32.5935",
    Longitude: "27.883",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1231",
    WardLookupId: "606",
    VDNumber: "10880029",
    RegisteredPopulation: "107",
    VotingStationName: "WATERFALL FARM SCHOOL",
    Address: "WATERFALLS FARM  NGWENKALA  KOMGA",
    Latitude: "-32.6662",
    Longitude: "27.9694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1232",
    WardLookupId: "606",
    VDNumber: "10880041",
    RegisteredPopulation: "140",
    VotingStationName: "FORT WARDEN FARM",
    Address: "FORT WARDEN  KOMGA  KOMGA",
    Latitude: "-32.5589",
    Longitude: "27.9984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1233",
    WardLookupId: "607",
    VDNumber: "10680027",
    RegisteredPopulation: "1757",
    VotingStationName: "SIVIWE PRIMARY SCHOOL",
    Address: "MLULEKI GEORGE STREET,  SIVIWE LOCATION,KOMGA  GREAT KEI",
    Latitude: "-32.5933",
    Longitude: "27.875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1234",
    WardLookupId: "607",
    VDNumber: "10680038",
    RegisteredPopulation: "527",
    VotingStationName: "MORGENSTER JUNIOR SECONDARY SCHOOL",
    Address: "EXTENSION 2  COLOURED TOWNSHIP  KOMGA",
    Latitude: "-32.5849",
    Longitude: "27.8836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1235",
    WardLookupId: "607",
    VDNumber: "10680050",
    RegisteredPopulation: "97",
    VotingStationName: "ZONE 10 TENT",
    Address: "ZONE 10  SIVIWE  KOMGA",
    Latitude: "-32.6006",
    Longitude: "27.8742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1236",
    WardLookupId: "607",
    VDNumber: "10880018",
    RegisteredPopulation: "62",
    VotingStationName: "BURNELY FARM",
    Address: "BURNELY FARM  KOMGA  KOMGA",
    Latitude: "-32.6688",
    Longitude: "27.7625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1237",
    WardLookupId: "607",
    VDNumber: "10880052",
    RegisteredPopulation: "272",
    VotingStationName: "MASAKHE SERVICE CENTRE",
    Address: "DRAAIBOSCH  KOMGA",
    Latitude: "-32.5865",
    Longitude: "27.7716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1238",
    WardLookupId: "607",
    VDNumber: "10880074",
    RegisteredPopulation: "75",
    VotingStationName: "SUNNY SIDE FARM",
    Address: "SUNNY SIDE FARM  KOMGA",
    Latitude: "-32.7398",
    Longitude: "27.8383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1239",
    WardLookupId: "607",
    VDNumber: "10880096",
    RegisteredPopulation: "11",
    VotingStationName: "IRENE FARM TENT",
    Address: "IRENE FARM  KOMGA  KOMGA",
    Latitude: "-32.535",
    Longitude: "27.8811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1240",
    WardLookupId: "2986",
    VDNumber: "10650013",
    RegisteredPopulation: "1069",
    VotingStationName: "SIVUYILE PRIMARY SCHOOL",
    Address: "CEMETERY AVENUE  KEISKAMMAHOEK  KEISKAMMAHOEK",
    Latitude: "-32.6821",
    Longitude: "27.1477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1241",
    WardLookupId: "2986",
    VDNumber: "10650046",
    RegisteredPopulation: "599",
    VotingStationName: "MASONWABE LOWER AND HIGHER PRIMARY SCHOOL",
    Address: "ELUKHANYWENI LOCATION  KEISKAMMAHOEK",
    Latitude: "-32.6821",
    Longitude: "27.1162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1242",
    WardLookupId: "2986",
    VDNumber: "10860083",
    RegisteredPopulation: "722",
    VotingStationName: "LOWER GXULU COMMUNITY HALL",
    Address: "LOWER GXULU  KEISKAMMAHOEK",
    Latitude: "-32.6749",
    Longitude: "27.1032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1243",
    WardLookupId: "2986",
    VDNumber: "10860094",
    RegisteredPopulation: "471",
    VotingStationName: "UPPER EZINGCUKA L/H PRIMARY",
    Address: "ZINGCUKA  UPPER ZINGCUKA  KEISKAMMAHOEK NU",
    Latitude: "-32.6748",
    Longitude: "27.0395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1244",
    WardLookupId: "2986",
    VDNumber: "10860184",
    RegisteredPopulation: "203",
    VotingStationName: "FORT COX PRIMARY SCHOOL",
    Address: "NGXONDORENI LOCATION  KEISKAMMAHOEK",
    Latitude: "-32.7297",
    Longitude: "27.0266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1245",
    WardLookupId: "2987",
    VDNumber: "10650035",
    RegisteredPopulation: "492",
    VotingStationName: "LUNGELOLETHU LOWER/HIGHER PRIMARY SCHOOL",
    Address: "K.K. HOEK  KOM LOCATION  KEISKAMMAHOEK",
    Latitude: "-32.6503",
    Longitude: "27.1682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1246",
    WardLookupId: "2987",
    VDNumber: "10650057",
    RegisteredPopulation: "175",
    VotingStationName: "NOKUZOLA LOWER PRIMARY SCHOOL",
    Address: "NGQUDELA LOCATION  KEISKAMMAHOEK",
    Latitude: "-32.6803",
    Longitude: "27.1777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1247",
    WardLookupId: "2987",
    VDNumber: "10650114",
    RegisteredPopulation: "459",
    VotingStationName: "ASEMBLEY OF GOD CHURCH",
    Address: "BUMBANE VILLAGE  KEISKAMMAHOEK",
    Latitude: "-32.6697",
    Longitude: "27.1494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1248",
    WardLookupId: "2987",
    VDNumber: "10860027",
    RegisteredPopulation: "697",
    VotingStationName: "DALIWONGA MBANGAMTHI HALL",
    Address: "CATA  CATA AREA  KEISKAMMAHOEK NU",
    Latitude: "-32.5941",
    Longitude: "27.1245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1249",
    WardLookupId: "2987",
    VDNumber: "10860151",
    RegisteredPopulation: "410",
    VotingStationName: "LOWER EMNYAMENI PRIMARY SCHOOL",
    Address: "KWANDONGA  LOWER MNYAMENI VILLAGE  KEISKAMMA HOEK",
    Latitude: "-32.6214",
    Longitude: "27.107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1250",
    WardLookupId: "2987",
    VDNumber: "10860173",
    RegisteredPopulation: "453",
    VotingStationName: "DUMANI LOWER PRIMARY SCHOOL",
    Address: "UPPER MNYAMENI LOCATION  KEISKAMMAHOEK",
    Latitude: "-32.6179",
    Longitude: "27.0746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1251",
    WardLookupId: "2987",
    VDNumber: "10860331",
    RegisteredPopulation: "407",
    VotingStationName: "UPPER GXULU LOWER/HIGHER PRIMARY SCHOOL",
    Address: "UPPER GXULU LOCATION  KEISKAMMAHOEK",
    Latitude: "-32.6465",
    Longitude: "27.1116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1252",
    WardLookupId: "2988",
    VDNumber: "10860038",
    RegisteredPopulation: "339",
    VotingStationName: "MQUKWANA PRIMARY SCHOOL",
    Address: "MQUKWANA VILLAGE  KEISKAMMAHOEK",
    Latitude: "-32.6214",
    Longitude: "27.1769",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1253",
    WardLookupId: "2988",
    VDNumber: "10860049",
    RegisteredPopulation: "819",
    VotingStationName: "MNANDI LOWER/HIGHER PRIMARY SCHOOL",
    Address: "GWILI GWILI VILLAGE  KEISKAMMA HOEK  KEISKAMMAHOEK",
    Latitude: "-32.6753",
    Longitude: "27.1976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1254",
    WardLookupId: "2988",
    VDNumber: "10860139",
    RegisteredPopulation: "526",
    VotingStationName: "NGOBOZANA PRIMARY SCHOOL",
    Address: "NGOBOZANA VILLAGE  KEISKAMMAHOEK",
    Latitude: "-32.619",
    Longitude: "27.2172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1255",
    WardLookupId: "2988",
    VDNumber: "10860162",
    RegisteredPopulation: "481",
    VotingStationName: "ST MATHEWS PRIMARY SCHOOL",
    Address: "MTHWAKU LOCATION  KEISKAMMAHOEK",
    Latitude: "-32.6409",
    Longitude: "27.1938",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1256",
    WardLookupId: "2988",
    VDNumber: "10860364",
    RegisteredPopulation: "262",
    VotingStationName: "MTHWAKU SENIOR SECONDARY SCHOOL",
    Address: "NDLOVINI VILLAGE  KEISKAMMAHOEK  STUTTERHEIM",
    Latitude: "-32.632",
    Longitude: "27.2012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1257",
    WardLookupId: "2988",
    VDNumber: "10930070",
    RegisteredPopulation: "163",
    VotingStationName: "ENGCOBO TENT",
    Address: "NGCOBO VILLAGE  STUTTERHEIM  STUTTERHEIM",
    Latitude: "-32.6155",
    Longitude: "27.3353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1258",
    WardLookupId: "2988",
    VDNumber: "10930306",
    RegisteredPopulation: "925",
    VotingStationName: "SITE OFFICE",
    Address: "KUBUSIE VILLAGE  STUTTERHEIM",
    Latitude: "-32.5953",
    Longitude: "27.3956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1259",
    WardLookupId: "2989",
    VDNumber: "10580015",
    RegisteredPopulation: "981",
    VotingStationName: "CATHCART TOWN HALL",
    Address: "MAIN STREET  CATHCART  CATHCART",
    Latitude: "-32.2985",
    Longitude: "27.1462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1260",
    WardLookupId: "2989",
    VDNumber: "10580037",
    RegisteredPopulation: "324",
    VotingStationName: "GOSHEN COMMUNITY HALL",
    Address: "GOSHEN  CATHCART  CATHCART",
    Latitude: "-32.2783",
    Longitude: "27.0534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1261",
    WardLookupId: "2989",
    VDNumber: "10580048",
    RegisteredPopulation: "1262",
    VotingStationName: "ANTA COMMUNITY HALL",
    Address: "CATHCART OLD LOCATION  CATHCART",
    Latitude: "-32.3016",
    Longitude: "27.1519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1262",
    WardLookupId: "2989",
    VDNumber: "10580082",
    RegisteredPopulation: "147",
    VotingStationName: "TWEEDALE SHEARING SHED",
    Address: "TWEEDALE FARM  CATHCART",
    Latitude: "-32.442",
    Longitude: "27.0356",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1263",
    WardLookupId: "2989",
    VDNumber: "10800054",
    RegisteredPopulation: "120",
    VotingStationName: "G.H. COTTERRELL BARN",
    Address: "ELLERSLIE FARM  SEYMOUR",
    Latitude: "-32.4176",
    Longitude: "26.8847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1264",
    WardLookupId: "2989",
    VDNumber: "11100182",
    RegisteredPopulation: "83",
    VotingStationName: "MANDLAKAPELI HIGHER PRIMARY SCHOOL",
    Address: "DIPHALA VILLAGE  WHITLESEA  STUTTERHEIM",
    Latitude: "-32.3428",
    Longitude: "26.7966",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1265",
    WardLookupId: "2989",
    VDNumber: "11160177",
    RegisteredPopulation: "158",
    VotingStationName: "SOPHAKAMA JUNIOR SECONDARY SCHOOL",
    Address: "LANGE DRAAI  WHITTLESEA",
    Latitude: "-32.3018",
    Longitude: "26.8784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1266",
    WardLookupId: "2990",
    VDNumber: "10580026",
    RegisteredPopulation: "1529",
    VotingStationName: "KATI-KATI COMMUNITY HALL",
    Address: "KATIKATI TOWNSHIP  KATIKATI TOWNSHIP; CATHCART  CATHCART",
    Latitude: "-32.2904",
    Longitude: "27.1594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1267",
    WardLookupId: "2990",
    VDNumber: "10800021",
    RegisteredPopulation: "252",
    VotingStationName: "THE HART HALL",
    Address: "OLD THOMAS RIVER  CATHCART",
    Latitude: "-32.4587",
    Longitude: "27.267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1268",
    WardLookupId: "2990",
    VDNumber: "10930036",
    RegisteredPopulation: "618",
    VotingStationName: "NDUMANGENI COMMUNITY HALL",
    Address: "NDUMANGENI VILLAGE  WARTBURG",
    Latitude: "-32.4436",
    Longitude: "27.4187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1269",
    WardLookupId: "2990",
    VDNumber: "10930092",
    RegisteredPopulation: "153",
    VotingStationName: "BOLO FARM SCHOOL",
    Address: "MAIN STREET  BOLO  STUTTERHEIM",
    Latitude: "-32.3917",
    Longitude: "27.6239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1270",
    WardLookupId: "2990",
    VDNumber: "10930159",
    RegisteredPopulation: "328",
    VotingStationName: "WARTBURG PUBLIC SCHOOL",
    Address: "RAWINI  RAWINI VILLAGE  STUTTERHEIM",
    Latitude: "-32.459",
    Longitude: "27.4127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1271",
    WardLookupId: "2990",
    VDNumber: "10930182",
    RegisteredPopulation: "77",
    VotingStationName: "QUANTI FARM SCHOOL",
    Address: "QUANTI  STUTTERHEIM",
    Latitude: "-32.3606",
    Longitude: "27.5016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1272",
    WardLookupId: "2990",
    VDNumber: "10930205",
    RegisteredPopulation: "55",
    VotingStationName: "UNDULA FARM",
    Address: "UNDULA  CATHCART",
    Latitude: "-32.3932",
    Longitude: "27.3634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1273",
    WardLookupId: "2990",
    VDNumber: "10930216",
    RegisteredPopulation: "395",
    VotingStationName: "NGQANDA COMMUNITY HALL",
    Address: "NGQANDA VILLAGE  WARTBURG",
    Latitude: "-32.4517",
    Longitude: "27.4045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1274",
    WardLookupId: "2990",
    VDNumber: "10930339",
    RegisteredPopulation: "250",
    VotingStationName: "MASIBULELE DAY CARE CENTRE",
    Address: "NDLOVINI LOCATION  WARTBURG  STUTTERHEIM",
    Latitude: "-32.4545",
    Longitude: "27.4356",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1275",
    WardLookupId: "2990",
    VDNumber: "11080921",
    RegisteredPopulation: "125",
    VotingStationName: "KEILANDS MISSION SCHOOL",
    Address: "KEILANDS MISSION  KEILANDS MISSION,STUTTERHEIM  INTSIKA YETHU",
    Latitude: "-32.2083",
    Longitude: "27.5276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1276",
    WardLookupId: "2991",
    VDNumber: "10730113",
    RegisteredPopulation: "647",
    VotingStationName: "AMATOLLAVILLE",
    Address: "MARIGOLD STREET  AMATOLLAVILLE  STUTTERHEIM",
    Latitude: "-32.5723",
    Longitude: "27.4087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1277",
    WardLookupId: "2991",
    VDNumber: "10730157",
    RegisteredPopulation: "129",
    VotingStationName: "TEMPORARY VOTING STATION (OHLSONS DRIFT FARM)-TENT",
    Address: "OHLSONS DRIFT FARM  STUTTERHEIM  STUTTERHEIM",
    Latitude: "-32.6026",
    Longitude: "27.4526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1278",
    WardLookupId: "2991",
    VDNumber: "10930014",
    RegisteredPopulation: "1241",
    VotingStationName: "ISIDENGE COMMUNITY HALL",
    Address: "ISIDENGE  ISIDENGE VILLAGE  STUTTERHEIM",
    Latitude: "-32.6279",
    Longitude: "27.397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1279",
    WardLookupId: "2991",
    VDNumber: "10930137",
    RegisteredPopulation: "1368",
    VotingStationName: "KUBUSIE PRE-SCHOOL",
    Address: "KUBUSIE VILLAGE  STUTTERHEIM",
    Latitude: "-32.5963",
    Longitude: "27.4099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1280",
    WardLookupId: "2991",
    VDNumber: "10930328",
    RegisteredPopulation: "164",
    VotingStationName: "TOIS FARM SCHOOL",
    Address: "TOIS FARM STUTTERHEIM  STUTTERHEIM  STUTTERHEIM",
    Latitude: "-32.674",
    Longitude: "27.3214",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1281",
    WardLookupId: "2992",
    VDNumber: "10870163",
    RegisteredPopulation: "977",
    VotingStationName: "MATSA HIGH SCHOOL",
    Address: "ETHEMBENI  ETHEMBENI LOC.  KING WILLIAMS TOWN",
    Latitude: "-32.7666",
    Longitude: "27.4306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1282",
    WardLookupId: "2992",
    VDNumber: "10870174",
    RegisteredPopulation: "944",
    VotingStationName: "MATHEW GONIWE SCHOOL",
    Address: "THEMBENI  ETHEMBENI VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.761",
    Longitude: "27.416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1283",
    WardLookupId: "2992",
    VDNumber: "10870185",
    RegisteredPopulation: "822",
    VotingStationName: "NXAWE PRIMARY SCHOOL",
    Address: "ETHEMBENI VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.7682",
    Longitude: "27.4225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1284",
    WardLookupId: "2992",
    VDNumber: "10870657",
    RegisteredPopulation: "699",
    VotingStationName: "L. F. MAY LOWER/HIGHER PRIMARY SCHOOL",
    Address: "MBAXA VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.7593",
    Longitude: "27.4094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1285",
    WardLookupId: "2993",
    VDNumber: "10640012",
    RegisteredPopulation: "767",
    VotingStationName: "KEI ROAD COMMUNITY HALL",
    Address: "EMTHONJENI  KEIROAD",
    Latitude: "-32.701",
    Longitude: "27.5542",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1286",
    WardLookupId: "2993",
    VDNumber: "10640023",
    RegisteredPopulation: "186",
    VotingStationName: "KEI ROAD LIBRARY",
    Address: "MAIN STREET  KEI ROAD  KEI ROAD",
    Latitude: "-32.7042",
    Longitude: "27.5467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1287",
    WardLookupId: "2993",
    VDNumber: "10870444",
    RegisteredPopulation: "543",
    VotingStationName: "ZAMULWAZI LOWER/HIGHER PRIMARY SCHOOL",
    Address: "KHAYELITSHA  FRANKFURT",
    Latitude: "-32.7032",
    Longitude: "27.4695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1288",
    WardLookupId: "2993",
    VDNumber: "10870455",
    RegisteredPopulation: "878",
    VotingStationName: "KUHLE PRIMARY SCHOOL",
    Address: "NOMZAMO  NOMZAMO LOCATION  KING WILLIAMS TOWN",
    Latitude: "-32.7221",
    Longitude: "27.5075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1289",
    WardLookupId: "2993",
    VDNumber: "10870950",
    RegisteredPopulation: "540",
    VotingStationName: "NOMZAMO TENT",
    Address: "NOMZAMO VILLAGE  KING WILLIAMS TOWN  FRANKFORT",
    Latitude: "-32.7227",
    Longitude: "27.4993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1290",
    WardLookupId: "2993",
    VDNumber: "10871108",
    RegisteredPopulation: "649",
    VotingStationName: "NDAKANA SCHOOL",
    Address: "NDAKANA  KING WILLIAMS TOWN",
    Latitude: "-32.6405",
    Longitude: "27.4615",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1291",
    WardLookupId: "2994",
    VDNumber: "10870466",
    RegisteredPopulation: "512",
    VotingStationName: "NDAKANA COMMUNITY HALL",
    Address: "NDAKANA VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.6555",
    Longitude: "27.4925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1292",
    WardLookupId: "2994",
    VDNumber: "10870477",
    RegisteredPopulation: "826",
    VotingStationName: "NONYAMEKO LOWER/HIGHER PRIMARY SCHOOL",
    Address: "FRESHWATER LOCATION  NDAKANA",
    Latitude: "-32.6683",
    Longitude: "27.4999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1293",
    WardLookupId: "2994",
    VDNumber: "10870488",
    RegisteredPopulation: "192",
    VotingStationName: "SMILLING VALLEY FARM SCHOOL",
    Address: "SMILLING VALLEY FARM  KEI ROAD",
    Latitude: "-32.7601",
    Longitude: "27.655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1294",
    WardLookupId: "2994",
    VDNumber: "10871490",
    RegisteredPopulation: "543",
    VotingStationName: "MASIPHUMLE LOWER PRIMARY SCHOOL",
    Address: "NDAKANA LOCATION  AMAHLATI  NDAKANA LOCATION",
    Latitude: "-32.6549",
    Longitude: "27.4751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1295",
    WardLookupId: "2994",
    VDNumber: "10930025",
    RegisteredPopulation: "217",
    VotingStationName: "AMABELE CLINIC",
    Address: "AMABELE  STUTTERHEIM",
    Latitude: "-32.6629",
    Longitude: "27.5248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1296",
    WardLookupId: "2994",
    VDNumber: "10930047",
    RegisteredPopulation: "507",
    VotingStationName: "MGWALI COMMUNITY HALL",
    Address: "NO. 3 MGWALI VILLAGE  MGWALI VILLAGE  STUTTERHEIM",
    Latitude: "-32.4622",
    Longitude: "27.6065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1297",
    WardLookupId: "2994",
    VDNumber: "10930069",
    RegisteredPopulation: "61",
    VotingStationName: "KINNERSLEY FARM SHED",
    Address: "LUJILO  STUTTERHEIM",
    Latitude: "-32.4647",
    Longitude: "27.7038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1298",
    WardLookupId: "2994",
    VDNumber: "10930160",
    RegisteredPopulation: "290",
    VotingStationName: "GASELA COMMUNITY HALL",
    Address: "GASELA VILLAGE  STUTTERHEIM",
    Latitude: "-32.6348",
    Longitude: "27.4814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1299",
    WardLookupId: "2994",
    VDNumber: "10930261",
    RegisteredPopulation: "69",
    VotingStationName: "APEFIELD CRECHE",
    Address: "LUJILO FARMING AREA  ESTUTTERHEIM  STUTTERHEIM",
    Latitude: "-32.4438",
    Longitude: "27.8183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1300",
    WardLookupId: "2994",
    VDNumber: "10930283",
    RegisteredPopulation: "503",
    VotingStationName: "CWENGCWANA PUBLIC SCHOOL",
    Address: "MGWALI VILLAGE  STUTTERHEIM",
    Latitude: "-32.4392",
    Longitude: "27.5999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1301",
    WardLookupId: "2994",
    VDNumber: "10930340",
    RegisteredPopulation: "43",
    VotingStationName: "KUBUSIEDRIFT TENT",
    Address: "KUBUSIEDRIFT  STUTTERHEIM",
    Latitude: "-32.568",
    Longitude: "27.6258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1302",
    WardLookupId: "2995",
    VDNumber: "10860016",
    RegisteredPopulation: "534",
    VotingStationName: "DEBE JUNIOR SENIOR PRIMARY SCHOOL",
    Address: "RABE VILLAGE  KEISKAMMA HOEK  ",
    Latitude: "-32.8198",
    Longitude: "27.0879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1303",
    WardLookupId: "2995",
    VDNumber: "10860106",
    RegisteredPopulation: "483",
    VotingStationName: "BURNSHILL COMMUNITY HALL",
    Address: "BURNSHILL  KEISKAMMAHOEK  BURNSHILL LOCATION",
    Latitude: "-32.7716",
    Longitude: "27.0559",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1304",
    WardLookupId: "2995",
    VDNumber: "10860117",
    RegisteredPopulation: "417",
    VotingStationName: "ZANYOKWE PRIMARY SCHOOL",
    Address: "ZANYOKWE VILLAGE  KEISKAMMA HOEK  ",
    Latitude: "-32.7642",
    Longitude: "27.1027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1305",
    WardLookupId: "2995",
    VDNumber: "10860128",
    RegisteredPopulation: "621",
    VotingStationName: "WHITEVILLE JUNIOR SENIOR PRIMARY SCHOOL",
    Address: "NGCAMNGENI VILLAGE  MIDDLEDRIFT",
    Latitude: "-32.8235",
    Longitude: "27.1381",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1306",
    WardLookupId: "2995",
    VDNumber: "10860195",
    RegisteredPopulation: "318",
    VotingStationName: "LENYE PRIMARY SCHOOL",
    Address: "LENYE LOCATION  KEISKAMMAHOEK",
    Latitude: "-32.7533",
    Longitude: "27.0802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1307",
    WardLookupId: "2995",
    VDNumber: "10860342",
    RegisteredPopulation: "308",
    VotingStationName: "MADUBELA LOWER PRIMARY SCHOOL",
    Address: "MADUBELA  MADUBELA VILAGE  STUTTERHEIM",
    Latitude: "-32.83",
    Longitude: "27.0823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1308",
    WardLookupId: "2995",
    VDNumber: "10860353",
    RegisteredPopulation: "437",
    VotingStationName: "FORT COX COLLEGE",
    Address: "FORT COX  KEISKAMMAHOEK",
    Latitude: "-32.7831",
    Longitude: "27.0274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1309",
    WardLookupId: "2996",
    VDNumber: "10650024",
    RegisteredPopulation: "996",
    VotingStationName: "KHAYALABANTU PRIMARY SCHOOL",
    Address: "TSHOXA LOCATION  KEISKAMMAHOEK",
    Latitude: "-32.6987",
    Longitude: "27.1586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1310",
    WardLookupId: "2996",
    VDNumber: "10860050",
    RegisteredPopulation: "655",
    VotingStationName: "SONWABILE PRIMARY SCHOOL",
    Address: "RABULA VILLAGE  KEISKAMMAHOEK",
    Latitude: "-32.7662",
    Longitude: "27.161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1311",
    WardLookupId: "2996",
    VDNumber: "10860061",
    RegisteredPopulation: "279",
    VotingStationName: "WOLFRIVER PRIMARY SCHOOL",
    Address: "LOWER ZINGCUKA VILLAGE  KEISKAMMA HOEK  KEISKAMMAHOEK",
    Latitude: "-32.7236",
    Longitude: "27.0992",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1312",
    WardLookupId: "2996",
    VDNumber: "10860072",
    RegisteredPopulation: "335",
    VotingStationName: "NGUDLE PRIMARY SCHOOL",
    Address: "KWANGUDLE AREA  RABULA VILLAGE  KEISKAMMA HOEK",
    Latitude: "-32.7455",
    Longitude: "27.1676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1313",
    WardLookupId: "2996",
    VDNumber: "10860140",
    RegisteredPopulation: "595",
    VotingStationName: "NGQUMEYA LOWER/HIGHER PRIMARY SCHOOL",
    Address: "NGQUMEYA VILLAGE  KEISKAMMAHOEK",
    Latitude: "-32.7271",
    Longitude: "27.1278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1314",
    WardLookupId: "2996",
    VDNumber: "10860296",
    RegisteredPopulation: "166",
    VotingStationName: "HLABAHLABA LOWER/HIGHER SCHOOL",
    Address:
      "LOWER NGQUMEYA LOCATION  LOWER NGQUMEYA LOCATION, KEISKAMMAHOEK  KEISKAMMAHOEK",
    Latitude: "-32.7296",
    Longitude: "27.1117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1315",
    WardLookupId: "2996",
    VDNumber: "10860308",
    RegisteredPopulation: "343",
    VotingStationName: "GRANTVILLE PRIMARY SCHOOL",
    Address: "N/A  RABULA  KEISKAMMAHOEK",
    Latitude: "-32.7361",
    Longitude: "27.18",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1316",
    WardLookupId: "2997",
    VDNumber: "10870400",
    RegisteredPopulation: "451",
    VotingStationName: "VULINGQONDO PRIMARY SCHOOL",
    Address: "NOMPUMELELO VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.7434",
    Longitude: "27.4925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1317",
    WardLookupId: "2997",
    VDNumber: "10870411",
    RegisteredPopulation: "436",
    VotingStationName: "NOTHENGA PRIMARY SCHOOL",
    Address: "KWA NOTHENGA VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.7035",
    Longitude: "27.3977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1318",
    WardLookupId: "2997",
    VDNumber: "10870422",
    RegisteredPopulation: "759",
    VotingStationName: "A. M. S. SITYANA HIGH SCHOOL",
    Address: "KWA - GUBEVU  KWA GUBEVU VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.7314",
    Longitude: "27.4272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1319",
    WardLookupId: "2997",
    VDNumber: "10870433",
    RegisteredPopulation: "1263",
    VotingStationName: "ZANOVUYO HIGH SCHOOL",
    Address: "KING WILLIAMS TOWN  DONQABA  KING WILLIAMS TOWN",
    Latitude: "-32.7314",
    Longitude: "27.46",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1320",
    WardLookupId: "2997",
    VDNumber: "10870972",
    RegisteredPopulation: "123",
    VotingStationName: "MALINDANA TENT",
    Address: "FRANKFORD  AMAHLATI  FRANKFORD VILLAGE",
    Latitude: "-32.7547",
    Longitude: "27.482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1321",
    WardLookupId: "2997",
    VDNumber: "10871445",
    RegisteredPopulation: "225",
    VotingStationName: "HOKWANA HIGHER PRIMARY SCHOOL",
    Address: "HOKWANA  HOKWANA LOCATION  FRANKFORT",
    Latitude: "-32.7601",
    Longitude: "27.4641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1322",
    WardLookupId: "2997",
    VDNumber: "10871489",
    RegisteredPopulation: "228",
    VotingStationName: "ISHMAYELI APOSTOLIC CHURCH",
    Address: "NTSHANGE VILLAGE  NOTHENGA  STUTTERHEIM",
    Latitude: "-32.7082",
    Longitude: "27.3788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1323",
    WardLookupId: "2997",
    VDNumber: "10930294",
    RegisteredPopulation: "335",
    VotingStationName: "UPPER IZELI TENT",
    Address: "MAIN ROAD, UPPER IZELI VILLAGE  KING WILLIAMS TOWN  NOTHENGA",
    Latitude: "-32.6914",
    Longitude: "27.3893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1324",
    WardLookupId: "2998",
    VDNumber: "10730012",
    RegisteredPopulation: "1450",
    VotingStationName: "SIYAKHULA HIGH SCHOOL",
    Address: "LOUISA STREET  STUTTERHEIM  STUTTERHEIM",
    Latitude: "-32.5717",
    Longitude: "27.4245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1325",
    WardLookupId: "2998",
    VDNumber: "10730168",
    RegisteredPopulation: "1277",
    VotingStationName: "KOLOGHA CRECHE",
    Address: "KOLOGHA LOCATION  STUTTERHEIM",
    Latitude: "-32.5651",
    Longitude: "27.3997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1326",
    WardLookupId: "2998",
    VDNumber: "10930148",
    RegisteredPopulation: "316",
    VotingStationName: "HECKEL COMMUNITY HALL",
    Address: "HECKEL VILLAGE  STUTTERHEIM",
    Latitude: "-32.4533",
    Longitude: "27.534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1327",
    WardLookupId: "2998",
    VDNumber: "10930250",
    RegisteredPopulation: "190",
    VotingStationName: "DOHNE AGRICULTURAL INSTITUTE",
    Address: "DOHNE  STUTTERHEIM",
    Latitude: "-32.5287",
    Longitude: "27.4593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1328",
    WardLookupId: "2998",
    VDNumber: "10930272",
    RegisteredPopulation: "682",
    VotingStationName: "CUMMING MEMORIAL SCHOOL",
    Address: "MGWALI VILLAGE  STUTTERHEIM",
    Latitude: "-32.4645",
    Longitude: "27.5926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1329",
    WardLookupId: "2998",
    VDNumber: "10930317",
    RegisteredPopulation: "75",
    VotingStationName: "ZAMUKHAYO FARM SCHOOL",
    Address: "LOWER KOLOGHA  STUTTERHEIM  STUTTERHEIM",
    Latitude: "-32.5413",
    Longitude: "27.3916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1330",
    WardLookupId: "2999",
    VDNumber: "10730023",
    RegisteredPopulation: "1348",
    VotingStationName: "SIKHULULE PUBLIC SCHOOL",
    Address: "MZAMOMHLE  MZAMOMHLE VILLAGE  STUTTERHEIM",
    Latitude: "-32.5737",
    Longitude: "27.4595",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1331",
    WardLookupId: "2999",
    VDNumber: "10730045",
    RegisteredPopulation: "1967",
    VotingStationName: "MLUNGISI PUBLIC SCHOOL",
    Address: "MLUNGISI  MLUNGISI LOCATION  STUTTERHEIM",
    Latitude: "-32.563",
    Longitude: "27.449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1332",
    WardLookupId: "3000",
    VDNumber: "10730034",
    RegisteredPopulation: "1174",
    VotingStationName: "MLUNGISI COMMUNITY HALL",
    Address: "MLUNGISI  STUTTERHEIM",
    Latitude: "-32.5572",
    Longitude: "27.4476",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1333",
    WardLookupId: "3000",
    VDNumber: "10730067",
    RegisteredPopulation: "440",
    VotingStationName: "NOMATHEMBA LOWER PRIMARY SCHOOL",
    Address: "MLUNGISI LOCATION  STUTTEHREIM",
    Latitude: "-32.5589",
    Longitude: "27.4497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1334",
    WardLookupId: "3000",
    VDNumber: "10930115",
    RegisteredPopulation: "600",
    VotingStationName: "CENYU COMMUNITY HALL",
    Address: "CENYU VILLAGE  CENYU LOCATION  STUTTERHEIM",
    Latitude: "-32.5508",
    Longitude: "27.452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1335",
    WardLookupId: "3000",
    VDNumber: "10930171",
    RegisteredPopulation: "969",
    VotingStationName: "CENYULANDS COMMUNITY HALL",
    Address: "CENYULANDS LOCATION   CENYULANDS  ,STUTTERHEIM",
    Latitude: "-32.556",
    Longitude: "27.4606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1336",
    WardLookupId: "608",
    VDNumber: "10870365",
    RegisteredPopulation: "579",
    VotingStationName: "SAKUPHUMELELA SENIOR SECONDARY SCHOOL",
    Address: "ZALARA  THAMARA  KING WMS TOWN",
    Latitude: "-32.9906",
    Longitude: "27.1685",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1337",
    WardLookupId: "608",
    VDNumber: "10870376",
    RegisteredPopulation: "272",
    VotingStationName: "GUSHIPHELE HIGH SCHOOL",
    Address: "N/A  MTATI VILLAGE  KING WILLIAMS TOWN",
    Latitude: "-32.9604",
    Longitude: "27.1822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1338",
    WardLookupId: "608",
    VDNumber: "10870848",
    RegisteredPopulation: "336",
    VotingStationName: "TYENI LOWER & HIGHER PRIMARY SCHOOL",
    Address: "TYENI VILLAGE  THAMARA ADMIN AREA  KING WMS TOWN",
    Latitude: "-32.9612",
    Longitude: "27.2142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1339",
    WardLookupId: "608",
    VDNumber: "10870882",
    RegisteredPopulation: "489",
    VotingStationName: "IMIDUSHANE HIGH SCHOOL",
    Address: "NGQWELE VILLAGE  THAMARA  KING WILLIAMS TOWN",
    Latitude: "-32.9033",
    Longitude: "27.2164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1340",
    WardLookupId: "608",
    VDNumber: "10871265",
    RegisteredPopulation: "334",
    VotingStationName: "NTSIKIZINI HIGHER PRIMARY SCHOOL",
    Address: "BHELE VILAGE  KING WILLIAMS TOWN",
    Latitude: "-32.9305",
    Longitude: "27.237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1341",
    WardLookupId: "608",
    VDNumber: "10871322",
    RegisteredPopulation: "515",
    VotingStationName: "TAMARA L/HP SCHOOL",
    Address: "TAMARA LOCATION  IMIDUSHANE A/A  KING WILLIAMS TOWN",
    Latitude: "-32.9927",
    Longitude: "27.2387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1342",
    WardLookupId: "608",
    VDNumber: "10871333",
    RegisteredPopulation: "583",
    VotingStationName: "SPRUELL LOWER & HIGHER PRIMARY SCHOOL",
    Address: "GOBOZANA VILLAGE  IMIDUSHANE AA  KING WILLIAMS TOWN",
    Latitude: "-32.938",
    Longitude: "27.1835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1343",
    WardLookupId: "608",
    VDNumber: "10871401",
    RegisteredPopulation: "263",
    VotingStationName: "BEBULE JUNIOR & SENIOR PRIMARY SCHOOL",
    Address:
      "NXOPHO VILLAGE  KWA-NXOPHO  IMIDUSHANE TRIBAL AUTHORITY/ KING WILLIAM`S TOWN",
    Latitude: "-32.9469",
    Longitude: "27.2296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1344",
    WardLookupId: "609",
    VDNumber: "10870084",
    RegisteredPopulation: "228",
    VotingStationName: "DANIEL MPENDU LOWER/HIGHER PRIMARY SCHOOL",
    Address: "UPPER MTHOMBE VILLAGE  MTHOMBE ADMIN AREA  KING WILLIAMS  TOWN",
    Latitude: "-33.0553",
    Longitude: "27.3316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1345",
    WardLookupId: "609",
    VDNumber: "10870321",
    RegisteredPopulation: "758",
    VotingStationName: "QUGQWALA LOWER & HIGHER PRIMARY SCHOOL",
    Address: "QHUGQWALA VILLAGE  THAMARA ADMIN AREA  KING WILLIAMS TOWN",
    Latitude: "-33.0064",
    Longitude: "27.3366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1346",
    WardLookupId: "609",
    VDNumber: "10870859",
    RegisteredPopulation: "447",
    VotingStationName: "TYHATA LOWER & HIGHER PRIMARY SCHOOL",
    Address: "TYHATHA VILLAGE  IMIQHAYI ADMIN AREA  KING WMS TOWN",
    Latitude: "-33.0751",
    Longitude: "27.3581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1347",
    WardLookupId: "609",
    VDNumber: "10870893",
    RegisteredPopulation: "417",
    VotingStationName: "ZONDEKA LOWER & HIGHER PRIMARY SCHOOL",
    Address: "ZONDEKA VILLAGE  IMIQHAYI ADMIN AREA  KWT",
    Latitude: "-32.9927",
    Longitude: "27.3583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1348",
    WardLookupId: "609",
    VDNumber: "10871298",
    RegisteredPopulation: "475",
    VotingStationName: "ZIMBABA LOWER & HIGHER PRIMARY SCHOOL",
    Address: "ZIMBABA VILLAGE  IMIQHAYI ADMIN AREA  KING WILLIAMS TOWN",
    Latitude: "-32.9639",
    Longitude: "27.3466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1349",
    WardLookupId: "609",
    VDNumber: "10871388",
    RegisteredPopulation: "178",
    VotingStationName: "ENYATYORA PRIMARY SCHOOL",
    Address: "ENYATYORA VILLAGE  IMIQHAYI ADMIN AREA  NGQUSHWA [PEDDIE]",
    Latitude: "-33.0359",
    Longitude: "27.3969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1350",
    WardLookupId: "609",
    VDNumber: "10871399",
    RegisteredPopulation: "319",
    VotingStationName: "DLOVA COMMUNITY HALL",
    Address: "DLOVA VILLAGE  IMIQHAYI ADMIN AREA  KING WILLIAMS TOWN",
    Latitude: "-32.9978",
    Longitude: "27.3872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1351",
    WardLookupId: "609",
    VDNumber: "10871434",
    RegisteredPopulation: "148",
    VotingStationName: "MTHOMBE METHODIST CHURCH OF SOUTH AFRICA",
    Address: "LOWER MTHOMBE VILLAGE  IMIQHYI ADMIN AREA  KING WILLIAMS TOWN",
    Latitude: "-33.0719",
    Longitude: "27.3239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1352",
    WardLookupId: "609",
    VDNumber: "10871467",
    RegisteredPopulation: "280",
    VotingStationName: "TILDIN LOWER & HIGHER PRIMARY SCHOOL",
    Address: "TILDIN VILLAGE  IMIQHAYI ADMIN AREA  KING WILLIAMS TOWN",
    Latitude: "-33.049",
    Longitude: "27.3669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1353",
    WardLookupId: "609",
    VDNumber: "10871478",
    RegisteredPopulation: "171",
    VotingStationName: "DONDASHE SENIOR SECONDARY SCHOOL",
    Address: "MAVATHULANA VILLAGE  IMIQHAYI ADMIN AREA  IMIQHAYI",
    Latitude: "-33.0614",
    Longitude: "27.3876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1354",
    WardLookupId: "610",
    VDNumber: "10870343",
    RegisteredPopulation: "304",
    VotingStationName: "MABEFU LOWER & HIGHER PRIMARY SCHOOL",
    Address: "MABHONGO VILLAGE  IMIDUSHANE ADMIN AREA  KING WMS TOWN",
    Latitude: "-33.0413",
    Longitude: "27.2964",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1355",
    WardLookupId: "610",
    VDNumber: "10871287",
    RegisteredPopulation: "193",
    VotingStationName: "SAKHEKILE LOWER PRIMARY SCHOOL",
    Address: "SHUSHU - A VILLAGE  IMIDUSHANE ADMIN AREA  KING WILLIAMS TOWN",
    Latitude: "-33.0203",
    Longitude: "27.2768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1356",
    WardLookupId: "610",
    VDNumber: "10871412",
    RegisteredPopulation: "452",
    VotingStationName: "QAUKENI LOWER & HIGHER PRIMARY SCHOOL",
    Address: "QAUKENI VILLAGE  IMIDUSHANE ADMIN AREA  KING WILLIAMS TOWN",
    Latitude: "-33.027",
    Longitude: "27.2554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1357",
    WardLookupId: "610",
    VDNumber: "10871423",
    RegisteredPopulation: "424",
    VotingStationName: "KALANA LOWER/HIGHER PRIMARY SCHOOL",
    Address: "KHALANA VILLAGE  IMIDUSHANE A/A  KING WILLIAMS TOWN",
    Latitude: "-33.0566",
    Longitude: "27.2658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1358",
    WardLookupId: "610",
    VDNumber: "10920079",
    RegisteredPopulation: "119",
    VotingStationName: "GCINISA LOWER PRIMARY SCHOOL",
    Address: "GCINISA NORTH VILLAGE  TYHEFU ADMIN AREA  PEDDIE",
    Latitude: "-33.0234",
    Longitude: "27.0813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1359",
    WardLookupId: "610",
    VDNumber: "10920259",
    RegisteredPopulation: "484",
    VotingStationName: "RHESHWA SENIOR SECONDARY SCHOOL",
    Address: "HLOSINI VILLAGE  TYHEFU ADMIN AREA  PEDDIE",
    Latitude: "-33.13",
    Longitude: "27.0922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1360",
    WardLookupId: "610",
    VDNumber: "10920282",
    RegisteredPopulation: "331",
    VotingStationName: "MQWASHU LOWER & HIGHER PRIMARY SCHOOL",
    Address: "MQWASHINI VILLAGE  TYHEFU ADMIN AREA  PEDDIE",
    Latitude: "-33.0989",
    Longitude: "27.0637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1361",
    WardLookupId: "610",
    VDNumber: "10920305",
    RegisteredPopulation: "226",
    VotingStationName: "NTSENEKANA COMMUNITY HALL",
    Address:
      "NTSINEKANA VILLAGE  NTSENEKANA VILLAGE  AMAMBALU TRIBAL AUTHORITY",
    Latitude: "-33.0847",
    Longitude: "27.1607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1362",
    WardLookupId: "610",
    VDNumber: "10920316",
    RegisteredPopulation: "284",
    VotingStationName: "NQWENERANA LOWER PRIMARY SCHOOL",
    Address: "NQWNERENA VILLAGE  AMAMBALU ADMIN AREA  PEDDIE",
    Latitude: "-33.1334",
    Longitude: "27.2311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1363",
    WardLookupId: "610",
    VDNumber: "10920428",
    RegisteredPopulation: "89",
    VotingStationName: "HLOSINI LOWER & HIGHER PRIMARY SCHOOL",
    Address: "EMAKHUZENI VILLAGE  TYHEFU ADMIN AREA  PEDDIE",
    Latitude: "-33.123",
    Longitude: "27.0826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1364",
    WardLookupId: "610",
    VDNumber: "10920439",
    RegisteredPopulation: "179",
    VotingStationName: "RIPPLEMEAD LOWER & HIGHER PRIMARY SCHOOL",
    Address:
      "BALTAIN VILLAGE  TYHEFU ADMIN AREA  PEDDIE / TYHEFU TRIBAL AUTHORITY",
    Latitude: "-33.0263",
    Longitude: "26.9799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1365",
    WardLookupId: "610",
    VDNumber: "10920462",
    RegisteredPopulation: "239",
    VotingStationName: "CROSSMAN COMMUNITY HALL",
    Address: "CROSSMAN  CROSSMAN VILLAGE  DABI TRIBAL AUTHORITY",
    Latitude: "-33.1447",
    Longitude: "27.1279",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1366",
    WardLookupId: "611",
    VDNumber: "10870062",
    RegisteredPopulation: "1044",
    VotingStationName: "ZANEMFUNDO HIGH SCHOOL",
    Address: "MACHIBI VILLAGE  IMIQHAYI ADMIN AREA  KING WMS TOWN",
    Latitude: "-33.0154",
    Longitude: "27.4647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1367",
    WardLookupId: "611",
    VDNumber: "10870073",
    RegisteredPopulation: "315",
    VotingStationName: "DUBE LOWER & HIGHER PRIMARY SCHOOL",
    Address: "DUBE VILLAGE  IMIQHAYI AD,IN AREA  KING WMS TOWN",
    Latitude: "-33.1095",
    Longitude: "27.3702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1368",
    WardLookupId: "611",
    VDNumber: "10870264",
    RegisteredPopulation: "428",
    VotingStationName: "MDOLOMBA LOWER & HIGHER PRIMARY SCHOOL",
    Address: "MDOLOMBA VILLAGE  IMIQHAYI ADMIN AREA  KING WMS TOWN",
    Latitude: "-33.0128",
    Longitude: "27.4071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1369",
    WardLookupId: "611",
    VDNumber: "10870736",
    RegisteredPopulation: "377",
    VotingStationName: "MADLIKI LOWER & HIGHER PRIMARY SCHOOL",
    Address: "MADLIKI VILLAGE  IMIQHAYI ADMIN AREA  KING WMS TOWN",
    Latitude: "-33.0483",
    Longitude: "27.4256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1370",
    WardLookupId: "611",
    VDNumber: "10871243",
    RegisteredPopulation: "265",
    VotingStationName: "PHOLE COMMUNITY HALL",
    Address: "PHOLE LOCATION  PHOLE CPMMUNITY HALL  IMIQHAYI TRIBAL AUTHORITY",
    Latitude: "-33.0758",
    Longitude: "27.411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1371",
    WardLookupId: "611",
    VDNumber: "10871254",
    RegisteredPopulation: "509",
    VotingStationName: "ENTILINI JUNIOR PRIMARY SCHOOL",
    Address: "NXWASHU VILLAGE  IMIQHAYI ADMIN AREA  KING WILLIAMS TOWN",
    Latitude: "-33.0009",
    Longitude: "27.4908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1372",
    WardLookupId: "611",
    VDNumber: "10871502",
    RegisteredPopulation: "65",
    VotingStationName: "NGXAKAXHA LOWER / HIGHER PRIMARY SCHOOL",
    Address: "NGXAKAXHA VILLAGE  DUBE A/A  PEDDIE",
    Latitude: "-33.1048",
    Longitude: "27.3353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1373",
    WardLookupId: "612",
    VDNumber: "10920057",
    RegisteredPopulation: "498",
    VotingStationName: "TUWA LOWER & HIGHER PRIMARY SCHOOL",
    Address: "BELL VILLAGE  AMARHAWULE ADMIN AREA  PEDDIE",
    Latitude: "-33.2528",
    Longitude: "27.3758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1374",
    WardLookupId: "612",
    VDNumber: "10920068",
    RegisteredPopulation: "866",
    VotingStationName: "NTYATYAMBO LOWER & HIGHER PRIMARY SCHOOL",
    Address: "CROSSROAD VILLAGE  AMARAWULE ADMIN AREA  PEDDIE TOWN",
    Latitude: "-33.1737",
    Longitude: "27.2915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1375",
    WardLookupId: "612",
    VDNumber: "10920080",
    RegisteredPopulation: "483",
    VotingStationName: "TUKU A COMMUNITY HALL",
    Address: "TUKU A  NGQUSHWA  PEDDIE",
    Latitude: "-33.2182",
    Longitude: "27.3174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1376",
    WardLookupId: "612",
    VDNumber: "10920091",
    RegisteredPopulation: "272",
    VotingStationName: "WOOLRIDGE COMMUNITY HALL",
    Address: "WOOLRIDGE VILLAGE  AMARAWULE ADMIN AREA  NGQUSHWA",
    Latitude: "-33.2173",
    Longitude: "27.2446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1377",
    WardLookupId: "612",
    VDNumber: "10920383",
    RegisteredPopulation: "397",
    VotingStationName: "ZANETHEMBA LOWER & HIGHER PRIMARY SCHOOL",
    Address: "LOVER TWIST VILLAGE  AMARHAWULE ADMIN AREA  NGQUSHWA [PEDDIE]",
    Latitude: "-33.215",
    Longitude: "27.3479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1378",
    WardLookupId: "612",
    VDNumber: "10920394",
    RegisteredPopulation: "204",
    VotingStationName: "BODIUM LOWERHIGHER PRIMARY SCHOOL",
    Address: "BODIUM VILLAGE  AMARAWULE ADMIN AREA  PEDDIE",
    Latitude: "-33.2506",
    Longitude: "27.4273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1379",
    WardLookupId: "612",
    VDNumber: "10920518",
    RegisteredPopulation: "230",
    VotingStationName: "TUKU CRECHE",
    Address: "TUKU B LOCATION  PEDDIE",
    Latitude: "-33.2538",
    Longitude: "27.2908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1380",
    WardLookupId: "612",
    VDNumber: "10920530",
    RegisteredPopulation: "148",
    VotingStationName: "LEQENI COMMUNITY HALL",
    Address: "LEQENI VILLAGE  AMARHAWULE  MRWEBO A/A",
    Latitude: "-33.1954",
    Longitude: "27.3257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1381",
    WardLookupId: "612",
    VDNumber: "10920596",
    RegisteredPopulation: "168",
    VotingStationName: "VUYOLWETHU LOWER HIGHER PRIMARY SCHOOL",
    Address: "KWANDABA VILLAGE  TYUKU C A/A  PEDDIE",
    Latitude: "-33.2496",
    Longitude: "27.3281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1382",
    WardLookupId: "613",
    VDNumber: "10920147",
    RegisteredPopulation: "577",
    VotingStationName: "NYANISO JUNIOR PRIMARY SCHOOL",
    Address: "NYANISO VILLAGE  AMAHLUBI ADMIN AREA  PEDDIE",
    Latitude: "-33.2378",
    Longitude: "27.1379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1383",
    WardLookupId: "613",
    VDNumber: "10920226",
    RegisteredPopulation: "311",
    VotingStationName: "NCALA JUNIOR PRIMARY SCHOOL",
    Address: "NCALA VILLAGE  AMABHELE ADMIN AREA  PEDDIE",
    Latitude: "-33.1613",
    Longitude: "27.168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1384",
    WardLookupId: "613",
    VDNumber: "10920237",
    RegisteredPopulation: "662",
    VotingStationName: "FENI COMMUNITY HALL",
    Address: "FENI(EMAGQAZENI)  NGQUSHWA  AMABHELE AA",
    Latitude: "-33.2052",
    Longitude: "27.1583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1385",
    WardLookupId: "613",
    VDNumber: "10920350",
    RegisteredPopulation: "455",
    VotingStationName: "CHELETYUMA LOWER HIGHER PRIMARY",
    Address: "CELETYUMA VILLAGE  AMARHELEDWANA AA  NGQUSHWA",
    Latitude: "-33.2685",
    Longitude: "27.1501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1386",
    WardLookupId: "613",
    VDNumber: "10920372",
    RegisteredPopulation: "464",
    VotingStationName: "CISIRA COMBINED PRIMARY SCHOOL",
    Address: "CHISIRA VILLAGE  AMABHELE ADMIN AREA  PEDDIE",
    Latitude: "-33.1607",
    Longitude: "27.1473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1387",
    WardLookupId: "613",
    VDNumber: "10920451",
    RegisteredPopulation: "438",
    VotingStationName: "FENI LOWERHIGHER PRIMARY SCHOOL",
    Address: "FENI (MVEMVE)  AMABHELE AA  PEDDIE",
    Latitude: "-33.2126",
    Longitude: "27.1655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1388",
    WardLookupId: "613",
    VDNumber: "10920541",
    RegisteredPopulation: "194",
    VotingStationName: "QINGQA MNTWANA JUNIOR PRIMARY SCHOOL",
    Address: "MAKHAHLANE VILLAGE  AMARHAWULE AA  MRWEBO A/A",
    Latitude: "-33.1764",
    Longitude: "27.2053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1389",
    WardLookupId: "614",
    VDNumber: "10920103",
    RegisteredPopulation: "181",
    VotingStationName: "QAMNYANA COMMUNITY HALL",
    Address: "QAMNYANA VILLAGE  TYHEFU AA  PEDDIE",
    Latitude: "-33.1193",
    Longitude: "26.9272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1390",
    WardLookupId: "614",
    VDNumber: "10920192",
    RegisteredPopulation: "577",
    VotingStationName: "QHAYIYA SENIOR SECONDARY SCHOOL.",
    Address: "GLENMORE TOWNSHIP  TYHEFU AA  PEDDIE TOWN",
    Latitude: "-33.1408",
    Longitude: "26.8613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1391",
    WardLookupId: "614",
    VDNumber: "10920204",
    RegisteredPopulation: "415",
    VotingStationName: "NDWAYANA LOWER & HIGHER PRIMARY SCHOOL",
    Address: "NDWAYANA VILLAGE  TYHEFU AA  PEDDIE",
    Latitude: "-33.1725",
    Longitude: "26.8679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1392",
    WardLookupId: "614",
    VDNumber: "10920215",
    RegisteredPopulation: "627",
    VotingStationName: "KANANA SENIOR SECONDARY SCHOOL",
    Address: "NDLAMBE VILLAGE  TYHEFU AA  PEDDIE",
    Latitude: "-33.1606",
    Longitude: "26.9174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1393",
    WardLookupId: "614",
    VDNumber: "10920260",
    RegisteredPopulation: "393",
    VotingStationName: "HORTON LOWER & HIGHER PRIMARY SCHOOL",
    Address: "HORTON VILLAGE  TYHEFU AA  PEDDIE",
    Latitude: "-33.1094",
    Longitude: "27.0219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1394",
    WardLookupId: "614",
    VDNumber: "10920271",
    RegisteredPopulation: "219",
    VotingStationName: "RURA LOWER & HIGHER PRIMARY SCHOOL",
    Address: "RURA VILLAGE  TYHEFU AA  PEDDIE",
    Latitude: "-33.1574",
    Longitude: "27.0343",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1395",
    WardLookupId: "614",
    VDNumber: "10920440",
    RegisteredPopulation: "128",
    VotingStationName: "GWABENI COMMUNITY HALL",
    Address: "GWABENI VILLAGE  TYHEFU AA  PEDDIE TOWN",
    Latitude: "-33.0913",
    Longitude: "26.9607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1396",
    WardLookupId: "614",
    VDNumber: "10920529",
    RegisteredPopulation: "242",
    VotingStationName: "MPENDULO LOWER PRIMARY SCHOOL",
    Address: "NONYANDA DLAMINI VILLAGE  TYHEFU AA  PEDDIE",
    Latitude: "-33.1289",
    Longitude: "27.0371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1397",
    WardLookupId: "614",
    VDNumber: "10920563",
    RegisteredPopulation: "182",
    VotingStationName: "ELUXOLWENI  COMMUNITY  HALL",
    Address: "ELUXOLWENI  COMMUNITY HALL  HLOSINI  ADMIN  AREA  NGQUSHWA  TOWN",
    Latitude: "-33.186",
    Longitude: "27.0892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1398",
    WardLookupId: "614",
    VDNumber: "10920574",
    RegisteredPopulation: "78",
    VotingStationName: "MCSA HLOSINI A METHODIST CHURCH",
    Address: "KWAMAQHOSHA  HLOSINI ADMIN AREA  NGQUSHWA TOWN",
    Latitude: "-33.1411",
    Longitude: "27.0715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1399",
    WardLookupId: "615",
    VDNumber: "10710010",
    RegisteredPopulation: "1310",
    VotingStationName: "NCUMISA KHONDLO SPORT CENTRE",
    Address: "HAMBURG ROAD  NGQUSHWA TOWN  PEDDIE TOWN",
    Latitude: "-33.1964",
    Longitude: "27.1203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1400",
    WardLookupId: "615",
    VDNumber: "10710021",
    RegisteredPopulation: "1581",
    VotingStationName: "FEZEKA LOWERHIGHER PRIMARY SCHOOL",
    Address: "PEDDIE EXTENTION  NGQUSHWA [PEDDIE]",
    Latitude: "-33.1988",
    Longitude: "27.1064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1401",
    WardLookupId: "615",
    VDNumber: "10710032",
    RegisteredPopulation: "612",
    VotingStationName: "AILIF LOWER & HIGHER PRIMARY SCHOOL",
    Address: "DURBAN VILLAGE  AMABHELE AA  PEDDIE",
    Latitude: "-33.2057",
    Longitude: "27.1443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1402",
    WardLookupId: "616",
    VDNumber: "10920181",
    RegisteredPopulation: "555",
    VotingStationName: "MGWALANA LOWER & HIGHER PRIMARY SCHOOL",
    Address: "MGWALANA VILLAGE  MARHELEDWANA  PEDDIE",
    Latitude: "-33.2662",
    Longitude: "27.1089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1403",
    WardLookupId: "616",
    VDNumber: "10920248",
    RegisteredPopulation: "287",
    VotingStationName: "MTHONJENI LOWER & HIGHER PRIMARY SCHOOL",
    Address: "RUNLETTS VILLAGE  MHALA AA  PEDDIE",
    Latitude: "-33.2271",
    Longitude: "27.0495",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1404",
    WardLookupId: "616",
    VDNumber: "10920293",
    RegisteredPopulation: "414",
    VotingStationName: "WOOD LANDS COMMUNITY HALL",
    Address: "WOODLANDS  WOODLANDS  VILLAGE  PEDDIE",
    Latitude: "-33.255",
    Longitude: "27.0561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1405",
    WardLookupId: "616",
    VDNumber: "10920327",
    RegisteredPopulation: "345",
    VotingStationName: "H M MAKINANA SENIOR SECONDARY SCHOOL",
    Address: "PIKOLI VILLAGE  MHALA AA.  PEDDIE",
    Latitude: "-33.2066",
    Longitude: "26.9824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1406",
    WardLookupId: "616",
    VDNumber: "10920361",
    RegisteredPopulation: "449",
    VotingStationName: "NTLOKO LOWER & HIGHER PRIMARY SCHOOL",
    Address: "NTLOKO VILLAGE  NJOKWENI AA  PEDDIE",
    Latitude: "-33.3012",
    Longitude: "27.0554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1407",
    WardLookupId: "616",
    VDNumber: "10920473",
    RegisteredPopulation: "498",
    VotingStationName: "NOBUMBA LOWER & HIGHER PRIMARY SCHOOL",
    Address: "NOBUMBA VILLAGE  MHALA AA  PEDDIE",
    Latitude: "-33.2692",
    Longitude: "27.027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1408",
    WardLookupId: "616",
    VDNumber: "10920484",
    RegisteredPopulation: "359",
    VotingStationName: "PIKOLI COMMUNITY HALL",
    Address: "PIKOLI VILLAGE  MHALA AA  PEDDIE",
    Latitude: "-33.1874",
    Longitude: "26.9764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1409",
    WardLookupId: "617",
    VDNumber: "10920136",
    RegisteredPopulation: "782",
    VotingStationName: "LUJIKO JAJI`S PRIMARY SCHOOL",
    Address: "LUJIKO VILLAGE  NJOKWENI AA  NGQUSHWA (PEDDIE)",
    Latitude: "-33.3685",
    Longitude: "27.036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1410",
    WardLookupId: "617",
    VDNumber: "10920158",
    RegisteredPopulation: "350",
    VotingStationName: "NGQOWA COMMUNITY HALL",
    Address: "NGQOWA VILLAGE  MSUTHU AA  PEDDIE",
    Latitude: "-33.3514",
    Longitude: "27.1132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1411",
    WardLookupId: "617",
    VDNumber: "10920169",
    RegisteredPopulation: "618",
    VotingStationName: "ZANOLWAZI SECONDARY SCHOOL",
    Address: "GWALANA VILLAGE  NJOKWENI AA  NGQUSHWA (PEDDIE)",
    Latitude: "-33.3179",
    Longitude: "27.0572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1412",
    WardLookupId: "617",
    VDNumber: "10920170",
    RegisteredPopulation: "719",
    VotingStationName: "EMFUNDWENI HIGH SCHOOL",
    Address: "QETO VILLAGE  MSUTHU AA  PEDDIE TOWN",
    Latitude: "-33.3294",
    Longitude: "27.102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1413",
    WardLookupId: "617",
    VDNumber: "10920495",
    RegisteredPopulation: "387",
    VotingStationName: "LOWER MGWALANA LOWER HIGHER PRIMAR SCHOOL",
    Address: "LOWER MGWALANA VILLAGE  AMARHELEDWANA AA  NGQUSHWA [PEDDIE]",
    Latitude: "-33.3027",
    Longitude: "27.1266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1414",
    WardLookupId: "617",
    VDNumber: "10920507",
    RegisteredPopulation: "190",
    VotingStationName: "TUBA / LOWER QETO COMMUNITY HALL",
    Address: "(TUBA) LOWER QETO  MSUTHU AA  NGQUSHWA [PEDDIE]",
    Latitude: "-33.3268",
    Longitude: "27.1512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1415",
    WardLookupId: "617",
    VDNumber: "10920552",
    RegisteredPopulation: "52",
    VotingStationName: "NEWTONDALE LOWER PRIMARY SCHOOL",
    Address: "NEWTONDALE VILLAGE  NJOKWENI AA  PEDDIE",
    Latitude: "-33.3422",
    Longitude: "27.0633",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1416",
    WardLookupId: "617",
    VDNumber: "10920585",
    RegisteredPopulation: "221",
    VotingStationName: "KOMKHULU PRE-SCHOOL",
    Address: "KOMKHULU VILLAGE  MTHATHI ADMIN AREA  AMAZIZI TRIBAL AUTHORITY",
    Latitude: "-33.3705",
    Longitude: "27.0837",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1417",
    WardLookupId: "618",
    VDNumber: "10920013",
    RegisteredPopulation: "1031",
    VotingStationName: "MASIBAMBISANE LOWER/HIGHER PRIMARY SCHOOL",
    Address: "MGABABA LOC  PEDDIE",
    Latitude: "-33.3997",
    Longitude: "27.0805",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1418",
    WardLookupId: "618",
    VDNumber: "10920024",
    RegisteredPopulation: "533",
    VotingStationName: "TATSHANA LOWER & HIGHER PRIMARY SCHOOL",
    Address: "PRUDHOE VILLAGE  NJOKWENI AA  PEDDIE TOWN",
    Latitude: "-33.399",
    Longitude: "27.1022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1419",
    WardLookupId: "618",
    VDNumber: "10920114",
    RegisteredPopulation: "931",
    VotingStationName: "MPEKO LOWER & HIGHER PRIMARY SCHOOL",
    Address: "MPEKWENI VILLAGE  NJOKWENI AA  PEDDIE",
    Latitude: "-33.382",
    Longitude: "27.0884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1420",
    WardLookupId: "618",
    VDNumber: "10920125",
    RegisteredPopulation: "359",
    VotingStationName: "GOPHE LOWER & HIGHER PRIMARY SCHOOL",
    Address: "MKHANYENI VILLAGE  NJOKWENI AA  PEDDIE",
    Latitude: "-33.374",
    Longitude: "27.1177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1421",
    WardLookupId: "619",
    VDNumber: "10610019",
    RegisteredPopulation: "759",
    VotingStationName: "HAMBURG TOWN HALL",
    Address: "225 MAIN STREET  HAMBURG TOWN  PEDDIE",
    Latitude: "-33.2878",
    Longitude: "27.4657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1422",
    WardLookupId: "619",
    VDNumber: "10920035",
    RegisteredPopulation: "462",
    VotingStationName: "BENTON LOWER PRIMARY SCHOOL",
    Address: "BENTON VILLAGE  MSUTHU AA  PEDDIE",
    Latitude: "-33.3851",
    Longitude: "27.1961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1423",
    WardLookupId: "619",
    VDNumber: "10920046",
    RegisteredPopulation: "680",
    VotingStationName: "DANINGE LOWER & HIGHER PRIMARY SCHOOL",
    Address: "GCINISA SOUTH  PEDDIE TOWN",
    Latitude: "-33.29",
    Longitude: "27.349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1424",
    WardLookupId: "619",
    VDNumber: "10920349",
    RegisteredPopulation: "951",
    VotingStationName: "SIBONELE JUNIOR SECONDARY SCHOOL",
    Address: "WESLEY TOWNSHIP  NGQUSHWA",
    Latitude: "-33.3221",
    Longitude: "27.3385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1425",
    WardLookupId: "619",
    VDNumber: "10920406",
    RegisteredPopulation: "200",
    VotingStationName: "BHINGQALA PUBLIC PRIMARY SCHOOL",
    Address: "BHINGQALA LOCATION  NGQUSHWA [PEDDIE]",
    Latitude: "-33.3102",
    Longitude: "27.197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1426",
    WardLookupId: "619",
    VDNumber: "10920417",
    RegisteredPopulation: "329",
    VotingStationName: "HAMILTON MZAMOMHLE JUNIOR & SENIOR PRIMARY SCHOOL",
    Address: "SOWETHO LOCATION  SOWETHO LOCATION  NGQUSHWA [PEDDIE]",
    Latitude: "-33.3589",
    Longitude: "27.2403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1427",
    WardLookupId: "620",
    VDNumber: "10890187",
    RegisteredPopulation: "408",
    VotingStationName: "MGXOTYENI LOWER/HIGHER PRIMARY SCHOOL",
    Address: "MGXOTYENI PRIMARY  MGXOTYENI LOC  DEBENEK-MIDDLEDRIFT",
    Latitude: "-32.9387",
    Longitude: "27.0976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1428",
    WardLookupId: "620",
    VDNumber: "10890200",
    RegisteredPopulation: "486",
    VotingStationName: "KNOX PRIMARY SCHOOL",
    Address: "XHUKWANE PRIMARY  XHUKWANE LOCATION  DEBENEK-MIDDLEDRIFT",
    Latitude: "-32.8932",
    Longitude: "27.1657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1429",
    WardLookupId: "620",
    VDNumber: "10890222",
    RegisteredPopulation: "309",
    VotingStationName: "QAMDOBOWA PRIMARY SCHOOL",
    Address: "QAMDOBOWA LOCATION  DEBENEK  MIDDLEDRIFT",
    Latitude: "-33.014",
    Longitude: "27.0994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1430",
    WardLookupId: "620",
    VDNumber: "10890233",
    RegisteredPopulation: "375",
    VotingStationName: "MNQABA LOWER/HIGHER PRIMARY SCHOOL",
    Address: "MNQABA PRIMARY  MNQABA LOCATION  DEBENEK-MIDDLEDRIFT",
    Latitude: "-32.8867",
    Longitude: "27.1072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1431",
    WardLookupId: "620",
    VDNumber: "10890277",
    RegisteredPopulation: "678",
    VotingStationName: "SABANE LOWER/HIGHER PRIMARY SCHOOL",
    Address: "SABANE PRIMARY  KHULILE LOCATION  DEBENEK-MIDDLEDRIFT",
    Latitude: "-32.9154",
    Longitude: "27.1212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1432",
    WardLookupId: "620",
    VDNumber: "10890288",
    RegisteredPopulation: "450",
    VotingStationName: "ZIGODLO LOWER/HIGHER PRIMARY SCHOOL",
    Address: "ZIGODLO PRIMARY  ZIGODLO LOCATION  DEBENEK-MIDDLEDRIFT",
    Latitude: "-32.9778",
    Longitude: "27.0763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1433",
    WardLookupId: "620",
    VDNumber: "10890389",
    RegisteredPopulation: "293",
    VotingStationName: "NDINDWA COMBINED SCHOOL",
    Address: "NDINDWA LOCATION  DEBE A/A  NKONKOBE",
    Latitude: "-32.9625",
    Longitude: "27.0785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1434",
    WardLookupId: "621",
    VDNumber: "10550012",
    RegisteredPopulation: "2396",
    VotingStationName: "EYETHU COMMUNITY HALL NTSELAMANZI",
    Address: "N/A  NISELAMANZI LOCATION  ALICE",
    Latitude: "-32.7689",
    Longitude: "26.8506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1435",
    WardLookupId: "621",
    VDNumber: "10950061",
    RegisteredPopulation: "941",
    VotingStationName: "IMINGCANGATHELO HIGH SCHOOL",
    Address: "LOWER  GQUMASHE LOCATION  ALICE",
    Latitude: "-32.753",
    Longitude: "26.8596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1436",
    WardLookupId: "621",
    VDNumber: "10950342",
    RegisteredPopulation: "214",
    VotingStationName: "UPPER GQUMASHE L/H PRIMARY SCHOOL",
    Address: "UPPER GQUMASHE   TYHALI  ALICE",
    Latitude: "-32.7702",
    Longitude: "26.8724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1437",
    WardLookupId: "622",
    VDNumber: "10600030",
    RegisteredPopulation: "1861",
    VotingStationName: "WASHINGTON BONGCO LIBRARY",
    Address: "MBEWU STREET   MPOLWENI LOCATION  FORT BEAUFORT",
    Latitude: "-32.7684",
    Longitude: "26.6557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1438",
    WardLookupId: "622",
    VDNumber: "10600074",
    RegisteredPopulation: "665",
    VotingStationName: "CAPE COLLEGE",
    Address: "DEPARTMENT OF EDUCATION  FORT BEAUFORT  NKONKOBE",
    Latitude: "-32.76",
    Longitude: "26.6573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1439",
    WardLookupId: "622",
    VDNumber: "10600096",
    RegisteredPopulation: "642",
    VotingStationName: "NQABA LOWER PRIMARY SCHOOL",
    Address: "QEQE STREET  ZWELITSHA LOCATION  FORT BEAUFORT",
    Latitude: "-32.7761",
    Longitude: "26.6597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1440",
    WardLookupId: "622",
    VDNumber: "10950117",
    RegisteredPopulation: "675",
    VotingStationName: "GOBIZEMBE HIGH SCHOOL",
    Address: "MXHELO LOCATION  MXHELO VILLAGE  ALICE",
    Latitude: "-32.7845",
    Longitude: "26.7266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1441",
    WardLookupId: "623",
    VDNumber: "10720011",
    RegisteredPopulation: "1305",
    VotingStationName: "JEANETT MAHONGA PRIMARY SCHOOL",
    Address: "277 JOE SLOVO STREET  SEYMOUR",
    Latitude: "-32.5536",
    Longitude: "26.7627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1442",
    WardLookupId: "623",
    VDNumber: "10900033",
    RegisteredPopulation: "433",
    VotingStationName: "MZAMOMHLE PRIMARY SCHOOL",
    Address: "CANGCA LOCATION  LUSHINGTON  SEYMOUR",
    Latitude: "-32.6275",
    Longitude: "26.8245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1443",
    WardLookupId: "623",
    VDNumber: "10900088",
    RegisteredPopulation: "323",
    VotingStationName: "MASAKHANE STORES",
    Address: "CARTHCART VALE  SEYMOUR",
    Latitude: "-32.5444",
    Longitude: "26.7925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1444",
    WardLookupId: "623",
    VDNumber: "10900112",
    RegisteredPopulation: "167",
    VotingStationName: "HERTZOG PRIMER",
    Address: "HERTZOG  SEYMOUR  SEYMOUR",
    Latitude: "-32.577",
    Longitude: "26.7185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1445",
    WardLookupId: "623",
    VDNumber: "10900123",
    RegisteredPopulation: "258",
    VotingStationName: "TAMBOKIESVLEIS COMMUNITY HALL",
    Address: "TAMBOKIESVLEI  MAQOMA A/A  MHLANGENI SEYMOUR",
    Latitude: "-32.5894",
    Longitude: "26.7263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1446",
    WardLookupId: "623",
    VDNumber: "10900145",
    RegisteredPopulation: "228",
    VotingStationName: "PLATFORM COMMUNITY HALL",
    Address: "PLATFORM VILLAGE  BALFOUR  FORT BEAUFORT",
    Latitude: "-32.5902",
    Longitude: "26.6795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1447",
    WardLookupId: "623",
    VDNumber: "11120016",
    RegisteredPopulation: "643",
    VotingStationName: "CRAB-BUSH",
    Address: "WOODLANDS  HOGSBACK  NKONKOBE",
    Latitude: "-32.5909",
    Longitude: "26.9231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1448",
    WardLookupId: "624",
    VDNumber: "10950083",
    RegisteredPopulation: "772",
    VotingStationName: "MASIVUYE LOWER PRIMARY SCHOOL",
    Address: "MGQUBA VILLAGE  ALICE  NKONKOBE",
    Latitude: "-32.758",
    Longitude: "26.7983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1449",
    WardLookupId: "624",
    VDNumber: "10950094",
    RegisteredPopulation: "974",
    VotingStationName: "MPAMBANIMZIMBA HIGH SCHOOL",
    Address: "GAGA SIKOLWENI VILLAGE  ALICE  NKONKOBE",
    Latitude: "-32.7588",
    Longitude: "26.7883",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1450",
    WardLookupId: "624",
    VDNumber: "10950106",
    RegisteredPopulation: "180",
    VotingStationName: "ROXENI PRESBYTERIAN CHURCH HALL",
    Address: "ROXENI   ROXENI VILLAGE  ALICE",
    Latitude: "-32.772",
    Longitude: "26.7508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1451",
    WardLookupId: "624",
    VDNumber: "10950230",
    RegisteredPopulation: "530",
    VotingStationName: "EDIKENI LOWER/HIGHER PRIMARY SCHOOL",
    Address: "NKOBO-NKOBO VILLAGE  ALICE",
    Latitude: "-32.7725",
    Longitude: "26.8045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1452",
    WardLookupId: "624",
    VDNumber: "10950308",
    RegisteredPopulation: "573",
    VotingStationName: "MAVUSO PRIMARY SCHOOL",
    Address: "KWAMAVUSO  MAVUSO VILLAGE  ALICE",
    Latitude: "-32.7743",
    Longitude: "26.8153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1453",
    WardLookupId: "625",
    VDNumber: "10550034",
    RegisteredPopulation: "1928",
    VotingStationName: "ALICE TOWN HALL",
    Address: "MAIN STREET  ALICE  ALICE",
    Latitude: "-32.788",
    Longitude: "26.8357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1454",
    WardLookupId: "625",
    VDNumber: "10550045",
    RegisteredPopulation: "1180",
    VotingStationName: "GOLF COURSE COMMUNITY HALL",
    Address: "GOLF COURSE  ALICE",
    Latitude: "-32.7828",
    Longitude: "26.8239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1455",
    WardLookupId: "626",
    VDNumber: "10830013",
    RegisteredPopulation: "542",
    VotingStationName: "LOWER BLANKWATER PRIMARY SCHOOL",
    Address: "LOWER BLANKWATER LOCATION  FORT BEAUFORT",
    Latitude: "-32.7027",
    Longitude: "26.5857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1456",
    WardLookupId: "626",
    VDNumber: "10830057",
    RegisteredPopulation: "714",
    VotingStationName: "HEALDTOWN COMPREHENSIVE SCHOOL",
    Address: "HEALDTOWN  HEALDTOWN LOCATION  FORT BEAUFORT",
    Latitude: "-32.732",
    Longitude: "26.7043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1457",
    WardLookupId: "626",
    VDNumber: "10830068",
    RegisteredPopulation: "301",
    VotingStationName: "WEZO PRIMARY SCHOOL",
    Address: "WEZO   WEZO VILLAGE  NKONKOBE",
    Latitude: "-32.7242",
    Longitude: "26.6479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1458",
    WardLookupId: "626",
    VDNumber: "10830125",
    RegisteredPopulation: "359",
    VotingStationName: "NGCWENGXA PRIMARY SCHOOL",
    Address: "NTILINI  NTILINI VILLAGE  NKONKOBE",
    Latitude: "-32.7059",
    Longitude: "26.6042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1459",
    WardLookupId: "626",
    VDNumber: "10830136",
    RegisteredPopulation: "501",
    VotingStationName: "CIMEZILE COMMUNITY HALL",
    Address: "CIMEZILE  FORT BEAUFORT (NKONKOBE)",
    Latitude: "-32.7308",
    Longitude: "26.6033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1460",
    WardLookupId: "626",
    VDNumber: "10830147",
    RegisteredPopulation: "225",
    VotingStationName: "DALUXOLO PRIMARY SCHOOL",
    Address: "EMABHELENI LOCATION( HEALDTOWN)  FORT BEAUFORT",
    Latitude: "-32.7164",
    Longitude: "26.6858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1461",
    WardLookupId: "626",
    VDNumber: "10900055",
    RegisteredPopulation: "140",
    VotingStationName: "MANKAZANA UCC PRIMARY SCHOOL",
    Address: "ALICE  AMHERST  ALICE",
    Latitude: "-32.6817",
    Longitude: "26.7434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1462",
    WardLookupId: "626",
    VDNumber: "10900077",
    RegisteredPopulation: "265",
    VotingStationName: "AMHERST FARM HOUSE",
    Address: "AMHERST FARM  BALFOUR  NKONKOBE [ALICE]",
    Latitude: "-32.6431",
    Longitude: "26.6883",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1463",
    WardLookupId: "627",
    VDNumber: "10600052",
    RegisteredPopulation: "1452",
    VotingStationName: "FORT BEAUFORT MUNICIPALITY HALL",
    Address: "CORNER MARKET & CAMPBELL STREET  FORT BEAUFORT",
    Latitude: "-32.7781",
    Longitude: "26.6313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1464",
    WardLookupId: "627",
    VDNumber: "10600063",
    RegisteredPopulation: "2143",
    VotingStationName: "NEWTOWN COMMUNITY HALL",
    Address: "NEW TOWN  FORT BEAUFORT  FORT BEAUFORT",
    Latitude: "-32.7746",
    Longitude: "26.6149",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1465",
    WardLookupId: "627",
    VDNumber: "10760048",
    RegisteredPopulation: "102",
    VotingStationName: "WAGONSDRIFT FARM",
    Address: "N/A   ADELAIDE   ADELAIDE",
    Latitude: "-32.8161",
    Longitude: "26.2661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1466",
    WardLookupId: "627",
    VDNumber: "10760060",
    RegisteredPopulation: "33",
    VotingStationName: "MOLWENI GAME RESERVE TENT",
    Address: "TARKASTAD ROAD  ADELAIDE  ADELAIDE",
    Latitude: "-32.6673",
    Longitude: "26.3462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1467",
    WardLookupId: "627",
    VDNumber: "10830024",
    RegisteredPopulation: "128",
    VotingStationName: "RIETFONTEIN FARM",
    Address: "RIETFONTEIN FARM  FORT BEAUFORT",
    Latitude: "-32.8711",
    Longitude: "26.506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1468",
    WardLookupId: "627",
    VDNumber: "10830046",
    RegisteredPopulation: "155",
    VotingStationName: "SPARKINGTON TENT",
    Address: "ROOIKOP RESERVE  FORT BEAUFORT  FORT BEAUFORT",
    Latitude: "-32.9571",
    Longitude: "26.6205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1469",
    WardLookupId: "627",
    VDNumber: "10830158",
    RegisteredPopulation: "261",
    VotingStationName: "MOUNT PLEASANT COM HALL",
    Address: "KROEMIE FARM  ALICE  ALICE",
    Latitude: "-32.7634",
    Longitude: "26.4356",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1470",
    WardLookupId: "628",
    VDNumber: "10760015",
    RegisteredPopulation: "169",
    VotingStationName: "WINTERBERG COUNTRY CLUB",
    Address: "WINTERBERG CC  FARM  ADELAIDE",
    Latitude: "-32.5027",
    Longitude: "26.3516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1471",
    WardLookupId: "628",
    VDNumber: "10760059",
    RegisteredPopulation: "147",
    VotingStationName: "POST RETIEF SPORT CLUB",
    Address: "JUNCTION-DEWAALSKLOOF/PRIESKLO  TENNIS CLUB  ADELAIDE",
    Latitude: "-32.4879",
    Longitude: "26.5246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1472",
    WardLookupId: "628",
    VDNumber: "10780062",
    RegisteredPopulation: "85",
    VotingStationName: "GLENTHORN STORE",
    Address: "GLEN THORN FARM  ADELAIDE  ADELAIDE",
    Latitude: "-32.4732",
    Longitude: "26.2341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1473",
    WardLookupId: "628",
    VDNumber: "10900011",
    RegisteredPopulation: "1005",
    VotingStationName: "BALFOUR COMMUNITY HALL",
    Address: "MAIN STREET  BALFOUR  NKONKOBE",
    Latitude: "-32.5469",
    Longitude: "26.6794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1474",
    WardLookupId: "628",
    VDNumber: "10900022",
    RegisteredPopulation: "350",
    VotingStationName: "FAIRBAIRN PRIMARY SCHOOL",
    Address: "FAIRBAIRN  ",
    Latitude: "-32.5536",
    Longitude: "26.7086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1475",
    WardLookupId: "628",
    VDNumber: "10900044",
    RegisteredPopulation: "204",
    VotingStationName: "READSDALE PRIMARY SCHOOL",
    Address: "READSDALE FARM  BALFOUR  NKONKOBE",
    Latitude: "-32.4639",
    Longitude: "26.7152",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1476",
    WardLookupId: "628",
    VDNumber: "10900066",
    RegisteredPopulation: "298",
    VotingStationName: "BUXTON PRIMARY SCHOOL",
    Address: "BUXTON VILLAGE  BALFOUR  NKONKOBE",
    Latitude: "-32.5728",
    Longitude: "26.6233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1477",
    WardLookupId: "628",
    VDNumber: "10900101",
    RegisteredPopulation: "208",
    VotingStationName: "KATBERG PRIMARY SCHOOL",
    Address: "KARTBERG  KATBERG  NKONKOBE",
    Latitude: "-32.4914",
    Longitude: "26.6801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1478",
    WardLookupId: "628",
    VDNumber: "10900134",
    RegisteredPopulation: "303",
    VotingStationName: "MAASDORP PRIMARY SCHOOL",
    Address: "MAASDORP FARM  BALFOUR  ALICE",
    Latitude: "-32.5212",
    Longitude: "26.6766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1479",
    WardLookupId: "628",
    VDNumber: "10900156",
    RegisteredPopulation: "141",
    VotingStationName: "NOJOLI  PRIMARY SCHOOL",
    Address: "EKUPHUMLENI VILLAGE  MAQOMA A/A  BALFOUR",
    Latitude: "-32.5539",
    Longitude: "26.6496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1480",
    WardLookupId: "628",
    VDNumber: "10900167",
    RegisteredPopulation: "98",
    VotingStationName: "BLACKHOOD  HALL",
    Address: "BLACKHOOD  MAQOMA A/A  SEYMOUR",
    Latitude: "-32.5106",
    Longitude: "26.7407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1481",
    WardLookupId: "628",
    VDNumber: "10900178",
    RegisteredPopulation: "101",
    VotingStationName: "PHILLIPTON PRIMARY SCHOOL",
    Address: "PHILLIPTON VILLAGE  AMAGINGQI A/A  BALFOUR",
    Latitude: "-32.5153",
    Longitude: "26.7121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1482",
    WardLookupId: "628",
    VDNumber: "11100148",
    RegisteredPopulation: "509",
    VotingStationName: "KOLOMANI COMMUNITY HALL",
    Address: "KOLOMANI  WHITTLESEA",
    Latitude: "-32.4217",
    Longitude: "26.7882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1483",
    WardLookupId: "628",
    VDNumber: "11100373",
    RegisteredPopulation: "52",
    VotingStationName: "GRAFTON SHEARING SHED",
    Address: "DIPALA  KOLOMANI",
    Latitude: "-32.3541",
    Longitude: "26.7452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1484",
    WardLookupId: "629",
    VDNumber: "10890019",
    RegisteredPopulation: "358",
    VotingStationName: "MHLAMBISO LOWER/HIGHER PRIMARY SCHOOL",
    Address: "THYALI LOCATION  KOMKHULU   MIDDLEDRIFT (NKONKOBE)",
    Latitude: "-32.6959",
    Longitude: "26.9968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1485",
    WardLookupId: "629",
    VDNumber: "10890020",
    RegisteredPopulation: "507",
    VotingStationName: "ZIXINENE LOWER/HIGHER PRIMARY SCHOOL",
    Address: "KOMKHULU  NKONKOBE",
    Latitude: "-32.7126",
    Longitude: "26.9993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1486",
    WardLookupId: "629",
    VDNumber: "10890503",
    RegisteredPopulation: "111",
    VotingStationName: "MDLANKOMO LOWER/HIGHER PRIMARY SCHOOL",
    Address: "MDLANKOMO  MIDDLEDRIFT",
    Latitude: "-32.6999",
    Longitude: "26.9639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1487",
    WardLookupId: "629",
    VDNumber: "10950016",
    RegisteredPopulation: "341",
    VotingStationName: "GEORGE MQALO HIGH SCHOOL",
    Address: "GILTON LOCATION  ALICE",
    Latitude: "-32.6608",
    Longitude: "26.9489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1488",
    WardLookupId: "629",
    VDNumber: "10950027",
    RegisteredPopulation: "920",
    VotingStationName: "SIYABONGA HIGH SCHOOL",
    Address: "AUCKLAND LOC  ALICE  ALICE (NKONKOBE)",
    Latitude: "-32.6283",
    Longitude: "26.922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1489",
    WardLookupId: "629",
    VDNumber: "10950151",
    RegisteredPopulation: "231",
    VotingStationName: "SOMPONDO PRIMARY SCHOOL",
    Address: "SOMPONDO LOCATION  ALICE",
    Latitude: "-32.6345",
    Longitude: "26.9466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1490",
    WardLookupId: "629",
    VDNumber: "10950162",
    RegisteredPopulation: "527",
    VotingStationName: "BINFIELD PARK COMBINED SCHOOL",
    Address: "BINFIELD PARK  BINFIELD LOCATION  ALICE",
    Latitude: "-32.6701",
    Longitude: "26.8981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1491",
    WardLookupId: "629",
    VDNumber: "10950241",
    RegisteredPopulation: "574",
    VotingStationName: "AMAKHUZE TRIBAL HALL",
    Address: "AMAKHUZE VILLAGE  ALICE",
    Latitude: "-32.6835",
    Longitude: "26.934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1492",
    WardLookupId: "630",
    VDNumber: "10550023",
    RegisteredPopulation: "4041",
    VotingStationName: "UNIVERSITY OF FORT HARE",
    Address: "UNIVERSITY OF FORT HARE  ALICE  ALICE",
    Latitude: "-32.7875",
    Longitude: "26.8475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1493",
    WardLookupId: "630",
    VDNumber: "10950218",
    RegisteredPopulation: "493",
    VotingStationName: "YAMALA PRIMARY SCHOOL",
    Address: "DYAMALA  DYAMALA VILLAGE  ALICE",
    Latitude: "-32.769",
    Longitude: "26.8894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1494",
    WardLookupId: "630",
    VDNumber: "10950319",
    RegisteredPopulation: "161",
    VotingStationName: "GUBURA PRESBYTERIAN CHURCH",
    Address: "GUBURA  IMINGCANGATHELO  NKONKOBE [ALICE]",
    Latitude: "-32.8085",
    Longitude: "26.854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1495",
    WardLookupId: "631",
    VDNumber: "10890514",
    RegisteredPopulation: "153",
    VotingStationName: "DISHI PRIMARY SCHOOL",
    Address: "DISHI LOCATION  KWAMATHOLE  MIDDLEDRIFT",
    Latitude: "-32.7307",
    Longitude: "26.969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1496",
    WardLookupId: "631",
    VDNumber: "10950038",
    RegisteredPopulation: "491",
    VotingStationName: "IMPEY SIWISA SCHOOL",
    Address: "KWEZANA VILLAGE  ALICE  NKONKOBE",
    Latitude: "-32.7072",
    Longitude: "26.9212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1497",
    WardLookupId: "631",
    VDNumber: "10950049",
    RegisteredPopulation: "489",
    VotingStationName: "FESTILE SOGA PRIMARY SCHOOL",
    Address: "BERGPLAAS LOCATION  ALICE",
    Latitude: "-32.6941",
    Longitude: "26.8727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1498",
    WardLookupId: "631",
    VDNumber: "10950173",
    RegisteredPopulation: "464",
    VotingStationName: "AMABHELE HIGH SCHOOL",
    Address: "KRWAKRWA  KRWAKRWA LOCATION  ALICE",
    Latitude: "-32.7437",
    Longitude: "26.9096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1499",
    WardLookupId: "631",
    VDNumber: "10950252",
    RegisteredPopulation: "208",
    VotingStationName: "MCFARLANE PRIMARY SCHOOL",
    Address: "MACFALANE  TYHUME  ALICE",
    Latitude: "-32.7146",
    Longitude: "26.8918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1500",
    WardLookupId: "631",
    VDNumber: "10950263",
    RegisteredPopulation: "955",
    VotingStationName: "ELUKHANYISWENI HIGH SCHOOL",
    Address: "EMSOBOMVU  MSOBOMMVU  ALICE",
    Latitude: "-32.699",
    Longitude: "26.8568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1501",
    WardLookupId: "631",
    VDNumber: "10950274",
    RegisteredPopulation: "218",
    VotingStationName: "GWALI  LOWER PRIMARY SCHOOL",
    Address: "GWALI LOCATION  GWALI  ALICE",
    Latitude: "-32.6654",
    Longitude: "26.8645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1502",
    WardLookupId: "631",
    VDNumber: "10950296",
    RegisteredPopulation: "486",
    VotingStationName: "TYALI SECONDARY SCHOOL",
    Address: "MELANI VILLAGE  ALICE  TYHUME",
    Latitude: "-32.7272",
    Longitude: "26.8757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1503",
    WardLookupId: "631",
    VDNumber: "10950320",
    RegisteredPopulation: "304",
    VotingStationName: "NCERA PRIMARY SCHOOL",
    Address: "UPPER NCERA VILLAGE  MABANDLA A/A  ALICE",
    Latitude: "-32.7321",
    Longitude: "26.9273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1504",
    WardLookupId: "632",
    VDNumber: "10890143",
    RegisteredPopulation: "550",
    VotingStationName: "DIKIDIKANA COMMUNITY HALL",
    Address: "DIKIDIKANA LOCATION  MIDDLEDRIFT",
    Latitude: "-32.9212",
    Longitude: "26.9716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1505",
    WardLookupId: "632",
    VDNumber: "10890154",
    RegisteredPopulation: "653",
    VotingStationName: "ZWELIMJONGILE SENIOR SECONDARY SCHOOL",
    Address: "NGCABASA LOCATION  NGCABASA LOCOTION  DEBENEK-MIDDLEDRIFT",
    Latitude: "-32.9756",
    Longitude: "26.9829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1506",
    WardLookupId: "632",
    VDNumber: "10890165",
    RegisteredPopulation: "210",
    VotingStationName: "WASHINGTON LOWER/HIGHER PRIMARY SCHOOL",
    Address: "WASHINGTON PRIMARY  NGCABASA LOC  DEBENEK-MIDDLEDRIFT",
    Latitude: "-32.993",
    Longitude: "27.0088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1507",
    WardLookupId: "632",
    VDNumber: "10890299",
    RegisteredPopulation: "427",
    VotingStationName: "QIBIRA PRIMARY SCHOOL",
    Address: "QIBIRA LOCATION  DEBENEK  MIDDLEDRIFT",
    Latitude: "-32.9413",
    Longitude: "27.039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1508",
    WardLookupId: "632",
    VDNumber: "10890301",
    RegisteredPopulation: "225",
    VotingStationName: "ZALAZE PRIMARY SCHOOL",
    Address: "ZALAZE LOCATION  DEBENEK  MIDDLEDRIFT",
    Latitude: "-33.0337",
    Longitude: "27.0583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1509",
    WardLookupId: "632",
    VDNumber: "10890378",
    RegisteredPopulation: "377",
    VotingStationName: "TYARA PRIMARY SCHOOL",
    Address: "DEBE MARELE  ",
    Latitude: "-32.9015",
    Longitude: "27.0412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1510",
    WardLookupId: "632",
    VDNumber: "10890390",
    RegisteredPopulation: "147",
    VotingStationName: "PAKAMA  PRIMARY SCHOOL",
    Address: "QUTUBENI LOCATION  ZIGODLO  DEBENEK",
    Latitude: "-33.0011",
    Longitude: "27.0332",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1511",
    WardLookupId: "632",
    VDNumber: "10890402",
    RegisteredPopulation: "332",
    VotingStationName: "NGQOLOWA SHED HALL",
    Address: "NGQOLOWA VILLAGE  DEBE-NEK  NKONKOBE",
    Latitude: "-32.9513",
    Longitude: "26.996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1512",
    WardLookupId: "632",
    VDNumber: "10890569",
    RegisteredPopulation: "142",
    VotingStationName: "QOMFO LOWER AND HIGH PRIMARY SCHOOL",
    Address: "QOMFO VILLAGE  MIDDLEDRIFT  AA AMAGQUNUKHWEBE",
    Latitude: "-32.9266",
    Longitude: "27.0107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1513",
    WardLookupId: "633",
    VDNumber: "10890075",
    RegisteredPopulation: "262",
    VotingStationName: "BILL GANTO PRIMARY SCHOOL",
    Address: "GAQA/NGWENYA LOCATION  MIDDLEDRIFT",
    Latitude: "-32.846",
    Longitude: "26.9136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1514",
    WardLookupId: "633",
    VDNumber: "10890110",
    RegisteredPopulation: "457",
    VotingStationName: "NJWAXA PRIMARY SCHOOL",
    Address: "NJWAXA  NJWAXA VILLAGE  MIDDLEDRIFT",
    Latitude: "-32.87",
    Longitude: "26.9094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1515",
    WardLookupId: "633",
    VDNumber: "10890121",
    RegisteredPopulation: "513",
    VotingStationName: "MBIZANA LOWER/HIGHER PRIMARY SCHOOL",
    Address: "MBIZANA LOC  MIDDLEDRIFT",
    Latitude: "-32.8846",
    Longitude: "26.9541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1516",
    WardLookupId: "633",
    VDNumber: "10890132",
    RegisteredPopulation: "265",
    VotingStationName: "JOSI-MARELA SENIOR SECONDARY SCHOOL",
    Address: "JOSI-MARELA SSS  DEBE-MARELA LOC  MIDDLEDRIFT",
    Latitude: "-32.8964",
    Longitude: "27.0105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1517",
    WardLookupId: "633",
    VDNumber: "10890244",
    RegisteredPopulation: "248",
    VotingStationName: "SAKI COMMUINITY  HALL",
    Address: "SAKI LOCATION  MIDDLEDRIFT  MIDDLEDRIFT",
    Latitude: "-32.8423",
    Longitude: "26.9641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1518",
    WardLookupId: "633",
    VDNumber: "10890255",
    RegisteredPopulation: "421",
    VotingStationName: "MXUMBU PRIMARY SCHOOL",
    Address: "MXUMBU LOCATION  MXUMBU VILLAGE  MIDDLEDRIFT",
    Latitude: "-32.867",
    Longitude: "27.0481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1519",
    WardLookupId: "633",
    VDNumber: "10890413",
    RegisteredPopulation: "453",
    VotingStationName: "SITYI COMBINED SCHOOL",
    Address: "MIDDLEDRIFT  ",
    Latitude: "-32.8417",
    Longitude: "26.8957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1520",
    WardLookupId: "633",
    VDNumber: "10890424",
    RegisteredPopulation: "252",
    VotingStationName: "NTLANGANWENI JUNIOR PRIMARY SCHOOL",
    Address: "NJWAXA LOCATION  MIDDLEDRIFT",
    Latitude: "-32.8845",
    Longitude: "26.9231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1521",
    WardLookupId: "633",
    VDNumber: "10890435",
    RegisteredPopulation: "186",
    VotingStationName: "KNAPSOPE UNITED CONGREGATIONAL CHURCH",
    Address: "GQADOSHE LOCATION  GQADUSHE  MIDDLEDRIFT",
    Latitude: "-32.866",
    Longitude: "26.9769",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1522",
    WardLookupId: "633",
    VDNumber: "10890446",
    RegisteredPopulation: "44",
    VotingStationName: "BANZI CHURCH /SCHOOL HALL",
    Address: "BANZI VILLAGE  BANZI  MIDDLEDRIFT",
    Latitude: "-32.8885",
    Longitude: "26.9743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1523",
    WardLookupId: "633",
    VDNumber: "10890525",
    RegisteredPopulation: "322",
    VotingStationName: "IMVISISWANO JUNIOR PRIMARY SCHOOL",
    Address: "SAKI LOCATION  MIDDLEDRIFT",
    Latitude: "-32.8353",
    Longitude: "26.9543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1524",
    WardLookupId: "633",
    VDNumber: "10890536",
    RegisteredPopulation: "287",
    VotingStationName: "INGWENYA PRIMARY SCHOOL",
    Address: "NGWENYA LOCATION  ALICE  NKONKOBE [ALICE]",
    Latitude: "-32.8394",
    Longitude: "26.931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1525",
    WardLookupId: "634",
    VDNumber: "10890031",
    RegisteredPopulation: "243",
    VotingStationName: "VUKANI HIGH SCHOOL",
    Address: "VUKANI H SCHOOL  ZIBI LOCATION  MIDDLEDRIFT",
    Latitude: "-32.7542",
    Longitude: "26.9664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1526",
    WardLookupId: "634",
    VDNumber: "10890053",
    RegisteredPopulation: "726",
    VotingStationName: "NGQELE PRIMARY SCHOOL",
    Address: "NGQELE PRIMARY  NQGELE LOC  MIDDLEDRIFT",
    Latitude: "-32.8108",
    Longitude: "26.9149",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1527",
    WardLookupId: "634",
    VDNumber: "10890064",
    RegisteredPopulation: "849",
    VotingStationName: "NANGU PRIMARY SCHOOL",
    Address: "NCERA SKHWEYISA  NCERA SKHWEYIYO  ALICE",
    Latitude: "-32.8189",
    Longitude: "26.8767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1528",
    WardLookupId: "634",
    VDNumber: "10890468",
    RegisteredPopulation: "164",
    VotingStationName: "MXHAMLI PRIMARY SCHOOL",
    Address: "MABHELENI -THYALI LOCATION  KOMKHULU  NKONKOBE",
    Latitude: "-32.76",
    Longitude: "26.9791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1529",
    WardLookupId: "634",
    VDNumber: "10890479",
    RegisteredPopulation: "227",
    VotingStationName: "TYUTYUZA COMMUNITY HALL",
    Address: "TYUTYUZA LOCATION  TYUTYUZA  MIDDLEDRIFT",
    Latitude: "-32.7908",
    Longitude: "26.9324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1530",
    WardLookupId: "634",
    VDNumber: "10890547",
    RegisteredPopulation: "307",
    VotingStationName: "MASAKHE CRECHE",
    Address: "NGQELE 2  NGQELE  NKONKOBE [ALICE]",
    Latitude: "-32.8006",
    Longitude: "26.9119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1531",
    WardLookupId: "634",
    VDNumber: "10950050",
    RegisteredPopulation: "457",
    VotingStationName: "THULISILE LOWER PRIMARY FRANCES",
    Address: "FRANCES LOC  THYALI LOCATION  ALICE",
    Latitude: "-32.7734",
    Longitude: "26.9213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1532",
    WardLookupId: "634",
    VDNumber: "10950285",
    RegisteredPopulation: "237",
    VotingStationName: "NCEREZANTSI PRIMARY SCHOOL",
    Address: "LOWER NCERA  AMAGQUNUKHWEBE  MIDDLEDRIFT",
    Latitude: "-32.7627",
    Longitude: "26.9243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1533",
    WardLookupId: "635",
    VDNumber: "10690017",
    RegisteredPopulation: "825",
    VotingStationName: "MIDDLEDRIFT COMMUNITY HALL",
    Address: "TOM STREET  MIDDLEDRIFT TOWN  MIDDLEDRIFT",
    Latitude: "-32.8184",
    Longitude: "26.9899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1534",
    WardLookupId: "635",
    VDNumber: "10890042",
    RegisteredPopulation: "363",
    VotingStationName: "CILDARA PRIMARY SCHOOL",
    Address: "CILDARA VILLAGE  AMAGQUNUKHWEBE AREA  MIDDLEDRIFT TOWN",
    Latitude: "-32.7814",
    Longitude: "27.0025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1535",
    WardLookupId: "635",
    VDNumber: "10890086",
    RegisteredPopulation: "861",
    VotingStationName: "A.M TAPA SENIOR SECONDARY SCHOOL",
    Address: "LOWER REGU LOC  MIDDLEDRIFT (NKONKOBE)",
    Latitude: "-32.8134",
    Longitude: "26.9649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1536",
    WardLookupId: "635",
    VDNumber: "10890097",
    RegisteredPopulation: "408",
    VotingStationName: "ANN SHAW CRECHE HALL",
    Address: "ANNSHAW  MIDDLEDRIFT",
    Latitude: "-32.8266",
    Longitude: "26.9887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1537",
    WardLookupId: "635",
    VDNumber: "10890109",
    RegisteredPopulation: "790",
    VotingStationName: "CWARU LOWER/HIGHER PRIMARY SCHOOL",
    Address: "CWARU PRIMARY  CWARU LOC  MIDDLEDRIFT",
    Latitude: "-32.8098",
    Longitude: "27.0407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1538",
    WardLookupId: "635",
    VDNumber: "10890457",
    RegisteredPopulation: "177",
    VotingStationName: "UPPER REGU SCHOOL",
    Address: "NKONKOBE  MIDDLEDRIFT  MIDDLEDRIFT",
    Latitude: "-32.7788",
    Longitude: "26.9451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1539",
    WardLookupId: "635",
    VDNumber: "10890558",
    RegisteredPopulation: "275",
    VotingStationName: "NGELE PRIMARY SCHOOL",
    Address: "NGELE LOCATION  MIDDLEDRIFT  ALICE (NKONKOBE)",
    Latitude: "-32.7643",
    Longitude: "27.0101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1540",
    WardLookupId: "636",
    VDNumber: "10890176",
    RegisteredPopulation: "683",
    VotingStationName: "BHONGWELIHLE PRIMARY SCHOOL",
    Address: "DEBE-NEK  THAFENI LOCATION  ALICE (NKONKOBE)",
    Latitude: "-32.8658",
    Longitude: "27.1131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1541",
    WardLookupId: "636",
    VDNumber: "10890198",
    RegisteredPopulation: "285",
    VotingStationName: "SISEKO HIGH SCHOOL",
    Address: "PERKSDALE VILLAGE  MIDDLEDRIFT",
    Latitude: "-32.8962",
    Longitude: "27.0751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1542",
    WardLookupId: "636",
    VDNumber: "10890211",
    RegisteredPopulation: "465",
    VotingStationName: "MDIZENI PRIMARY SCHOOL",
    Address: "MAYIPASE  DEBENECK",
    Latitude: "-32.8648",
    Longitude: "27.2075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1543",
    WardLookupId: "636",
    VDNumber: "10890266",
    RegisteredPopulation: "329",
    VotingStationName: "QANDA PRIMARY SCHOOL",
    Address: "QANDA LOCATION  MIDDLEDRIFT",
    Latitude: "-32.8458",
    Longitude: "27.0594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1544",
    WardLookupId: "636",
    VDNumber: "10890312",
    RegisteredPopulation: "923",
    VotingStationName: "ZIHLAHLENI LOWER/HIGHER PRIMARY SCHOOL",
    Address: "ZIHLAHLENI VILLAGE  AMAGQUNUKHWEBE  DEBENEK-MIDDLEDRIFT",
    Latitude: "-32.8617",
    Longitude: "27.183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1545",
    WardLookupId: "636",
    VDNumber: "10890334",
    RegisteredPopulation: "285",
    VotingStationName: "NONGWANE PRIMARY SCHOOL",
    Address: "NONALITI LOCATION  AMAGQUNUKHWEBE  DEBE-NEK",
    Latitude: "-32.8823",
    Longitude: "27.1377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1546",
    WardLookupId: "636",
    VDNumber: "10890345",
    RegisteredPopulation: "319",
    VotingStationName: "NTONGA PRIMARY SCHOOL",
    Address: "NTONGA VILLAGE  DEBE A/A  NKONKOBE",
    Latitude: "-32.8784",
    Longitude: "27.1999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1547",
    WardLookupId: "636",
    VDNumber: "10890356",
    RegisteredPopulation: "265",
    VotingStationName: "DAWUWA PRIMARY SCHOOL",
    Address: "DAWUWA LOCATION  DEBENECK,TRUSTINI  NKONKOBE",
    Latitude: "-32.8466",
    Longitude: "27.136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1548",
    WardLookupId: "637",
    VDNumber: "10950128",
    RegisteredPopulation: "377",
    VotingStationName: "DALUBUHLE HIGH SCHOOL",
    Address: "SESHEGU LOCATION  ALICE  ALICE",
    Latitude: "-32.9001",
    Longitude: "26.7934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1549",
    WardLookupId: "637",
    VDNumber: "10950139",
    RegisteredPopulation: "358",
    VotingStationName: "SHESHEGU LOWER AND HIGHER PRIMARY SCHOOL",
    Address: "SHESHEGU VILLAGE  ZULU A/A  ALICE",
    Latitude: "-32.913",
    Longitude: "26.8187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1550",
    WardLookupId: "637",
    VDNumber: "10950140",
    RegisteredPopulation: "707",
    VotingStationName: "SIPHUMILE PRIMARY SCHOOL",
    Address: "LLOYD LOCATION  TYHALI LOCATION  ALICE",
    Latitude: "-32.815",
    Longitude: "26.7613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1551",
    WardLookupId: "637",
    VDNumber: "10950184",
    RegisteredPopulation: "915",
    VotingStationName: "SISONKE LOWER PRIMARY SCHOOL",
    Address: "THEMBISA LOCATION  ALICE",
    Latitude: "-32.8406",
    Longitude: "26.7887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1552",
    WardLookupId: "637",
    VDNumber: "10950195",
    RegisteredPopulation: "56",
    VotingStationName: "FREE CHURCH IN SOUTHERN AFRICA",
    Address: "KWA NOMTAYI FARM  ZULU A/A  ALICE",
    Latitude: "-32.9582",
    Longitude: "26.8171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1553",
    WardLookupId: "637",
    VDNumber: "10950229",
    RegisteredPopulation: "263",
    VotingStationName: "CEASER MLUMBI PRIMARY SCHOOL",
    Address: "KWEZANA VILLAGE  ALICE  NKONKOBE",
    Latitude: "-32.7994",
    Longitude: "26.7641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1554",
    WardLookupId: "637",
    VDNumber: "10950331",
    RegisteredPopulation: "303",
    VotingStationName: "SIVUYISIWE PRIMARY SCHOOL",
    Address: "PHUMLANI  PHUMLANI LOCATION  ALICE",
    Latitude: "-32.8079",
    Longitude: "26.7948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1555",
    WardLookupId: "637",
    VDNumber: "10950353",
    RegisteredPopulation: "142",
    VotingStationName: "BALURA PRIMARY SCHOOL",
    Address: "BALURA  ZULU A/A  ALICE",
    Latitude: "-32.8823",
    Longitude: "26.7584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1556",
    WardLookupId: "637",
    VDNumber: "10950364",
    RegisteredPopulation: "42",
    VotingStationName: "TUKULU COMMUNITY HALL",
    Address: "TUKULU  IMINGCANGATHELO  ALICE",
    Latitude: "-32.8302",
    Longitude: "26.8488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1557",
    WardLookupId: "638",
    VDNumber: "10600018",
    RegisteredPopulation: "1878",
    VotingStationName: "INYIBIBA PUBLIC SCHOOL",
    Address: "CALEB STREET  BOFOLO LOCATION  FORT BEAUFORT",
    Latitude: "-32.766",
    Longitude: "26.6526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1558",
    WardLookupId: "638",
    VDNumber: "10600029",
    RegisteredPopulation: "1655",
    VotingStationName: "EYABANTU HIGH SCHOOL",
    Address: "JANUARY STREET  DORRINGTON LOCATION  FORT BEAUFORT",
    Latitude: "-32.773",
    Longitude: "26.6545",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1559",
    WardLookupId: "638",
    VDNumber: "10600108",
    RegisteredPopulation: "372",
    VotingStationName: "ILINGELABANTU PRIMARY SCHOOL",
    Address: "DORRINGTON  DUBU LCATION  FORT BEAUFORT",
    Latitude: "-32.77",
    Longitude: "26.6461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1560",
    WardLookupId: "639",
    VDNumber: "10600041",
    RegisteredPopulation: "1927",
    VotingStationName: "HILLSIDE COMMUNITY HALL",
    Address: "HILLSDE LOCATION  FORT BEAUFORT",
    Latitude: "-32.7512",
    Longitude: "26.6379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1561",
    WardLookupId: "639",
    VDNumber: "10600085",
    RegisteredPopulation: "344",
    VotingStationName: "KING`S KIDS PRE SCHOOL",
    Address: "23 SOMERSET STREET  FORT BEAUFORT  FORT BEAUFORT",
    Latitude: "-32.7706",
    Longitude: "26.6296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1562",
    WardLookupId: "639",
    VDNumber: "10830035",
    RegisteredPopulation: "887",
    VotingStationName: "LINDANI HIGH SCHOOL",
    Address: "NTOLENI  FORT BEAUFORT",
    Latitude: "-32.7442",
    Longitude: "26.6509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1563",
    WardLookupId: "640",
    VDNumber: "10540022",
    RegisteredPopulation: "1485",
    VotingStationName: "PHAKAMISANI  CRECHE",
    Address: "NGXULELO  LINGELETHU LOCATION  ADELAIDE",
    Latitude: "-32.6903",
    Longitude: "26.3135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1564",
    WardLookupId: "640",
    VDNumber: "10540033",
    RegisteredPopulation: "1393",
    VotingStationName: "LINGELETHU COMMUNITY CENTRE",
    Address: "NGALO LANE  OLD LINGELETHU  ADELAIDE",
    Latitude: "-32.6963",
    Longitude: "26.3063",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1565",
    WardLookupId: "640",
    VDNumber: "10540055",
    RegisteredPopulation: "732",
    VotingStationName: "TOWN HALL ADELAIDE",
    Address: "MARKET SQUARE  ADELAIDE  ADELAIDE",
    Latitude: "-32.7077",
    Longitude: "26.2959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1566",
    WardLookupId: "641",
    VDNumber: "10540011",
    RegisteredPopulation: "1262",
    VotingStationName: "BEZUIDENHOUTVILLE COMMUNITY CENTRE",
    Address: "VILJOEN STREET  BEZUIDENHOUTVILLE  ADELAIDE",
    Latitude: "-32.6862",
    Longitude: "26.299",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1567",
    WardLookupId: "641",
    VDNumber: "10540066",
    RegisteredPopulation: "544",
    VotingStationName: "AMAJINGQI HIGH SCHOOL",
    Address: "BONANI STR  LINGELETHU TOWNSHIP  ADELAIDE",
    Latitude: "-32.6911",
    Longitude: "26.308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1568",
    WardLookupId: "641",
    VDNumber: "10760026",
    RegisteredPopulation: "151",
    VotingStationName: "ELANDSDRIFT PRES PRIMARY SCHOOL",
    Address: "R344 ROAD   ADELAIDE  ADELAIDE",
    Latitude: "-32.6425",
    Longitude: "26.2677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1569",
    WardLookupId: "641",
    VDNumber: "10760093",
    RegisteredPopulation: "1289",
    VotingStationName: "BEZVILLE NEW AREA TENT",
    Address: "SAM BOTHA  ADELAIDE  ADELAIDE",
    Latitude: "-32.6808",
    Longitude: "26.2929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1570",
    WardLookupId: "641",
    VDNumber: "10780017",
    RegisteredPopulation: "137",
    VotingStationName: "EILDON FARM",
    Address: "BEDFORD  EILDON  BEDFORD",
    Latitude: "-32.4152",
    Longitude: "26.078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1571",
    WardLookupId: "641",
    VDNumber: "10780051",
    RegisteredPopulation: "96",
    VotingStationName: "FAIRHOLDT CHURCH",
    Address: "FAIRHOLDT FARM  ADELAIDE  ADELAIDE",
    Latitude: "-32.4908",
    Longitude: "25.9626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1572",
    WardLookupId: "642",
    VDNumber: "10560013",
    RegisteredPopulation: "955",
    VotingStationName: "BEDFORD TOWN HALL",
    Address: "DONKIN STREET  BEDFORD  BEDFORD",
    Latitude: "-32.678",
    Longitude: "26.0872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1573",
    WardLookupId: "642",
    VDNumber: "10560024",
    RegisteredPopulation: "1341",
    VotingStationName: "MSOMBOMVU COMMUNITY HALL",
    Address: "DYASI STREET  BONGWENI  BEDFORD",
    Latitude: "-32.6944",
    Longitude: "26.0998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1574",
    WardLookupId: "642",
    VDNumber: "10560035",
    RegisteredPopulation: "1566",
    VotingStationName: "NYARHA  PRE-SCHOOL",
    Address: "NTLAMA  NONZWAKAZI  BEDFORD",
    Latitude: "-32.6912",
    Longitude: "26.0965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1575",
    WardLookupId: "642",
    VDNumber: "10560046",
    RegisteredPopulation: "792",
    VotingStationName: "ST JOSEPH HALL",
    Address: "GOODWIN PARK  BEDFORD",
    Latitude: "-32.6902",
    Longitude: "26.0879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1576",
    WardLookupId: "642",
    VDNumber: "10560057",
    RegisteredPopulation: "47",
    VotingStationName: "SPRINGGROVE FARM",
    Address: "SPRINGGROVE FARM  SPRINGGROVE   BEDFORD",
    Latitude: "-32.6358",
    Longitude: "26.1363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1577",
    WardLookupId: "643",
    VDNumber: "10980042",
    RegisteredPopulation: "845",
    VotingStationName: "UNITING REFORMED CHURCH",
    Address: "69 SIKHULU STREET  PO LINGELIHLE  CRADOCK",
    Latitude: "-32.1855",
    Longitude: "25.6341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1578",
    WardLookupId: "643",
    VDNumber: "10980075",
    RegisteredPopulation: "576",
    VotingStationName: "A.S. SKWEYIYA MEMORIAL CHURCH",
    Address: "72 ELUXOLWENI STREET  LINGELIHLE LOCATION  CRADOCK",
    Latitude: "-32.1876",
    Longitude: "25.6372",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1579",
    WardLookupId: "643",
    VDNumber: "10980109",
    RegisteredPopulation: "902",
    VotingStationName: "SEVENTH DAY ADVENTIST CHURCH (TENT)",
    Address: "SIKHULU   LINGELIHLE  CRADOCK",
    Latitude: "-32.1902",
    Longitude: "25.6461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1580",
    WardLookupId: "643",
    VDNumber: "10980110",
    RegisteredPopulation: "1162",
    VotingStationName: "KUDE KWALAPHA PRIMARY SCHOOL",
    Address: "SIKHULU STREET  LINGELIHLE  CRADOCK",
    Latitude: "-32.1888",
    Longitude: "25.6424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1581",
    WardLookupId: "644",
    VDNumber: "10980019",
    RegisteredPopulation: "1641",
    VotingStationName: "MATTHEW GONIWE COMPREHENSIVE SCHOOL",
    Address: "NTENETYANA  LINGELIHLE  CRADOCK",
    Latitude: "-32.187",
    Longitude: "25.6416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1582",
    WardLookupId: "644",
    VDNumber: "10980097",
    RegisteredPopulation: "1217",
    VotingStationName: "SONGEZO TENT",
    Address: "SIKHULU STREET  LINGELIHLE  CRADOCK",
    Latitude: "-32.1889",
    Longitude: "25.6504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1583",
    WardLookupId: "645",
    VDNumber: "10980020",
    RegisteredPopulation: "679",
    VotingStationName: "LINGELIHLE COMMUNITY HALL",
    Address: "SIKULU STREET  LINGELIHLE  CRADOCK",
    Latitude: "-32.1838",
    Longitude: "25.6317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1584",
    WardLookupId: "645",
    VDNumber: "10980086",
    RegisteredPopulation: "710",
    VotingStationName: "DISCIPLINE CHURCH IN SOUTH AFRICA",
    Address: "1 LUKHANYO  LINGELIHLE  CRADOCK",
    Latitude: "-32.1788",
    Longitude: "25.6283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1585",
    WardLookupId: "645",
    VDNumber: "10980143",
    RegisteredPopulation: "684",
    VotingStationName: "CRADOCK YOUTH CENTRE",
    Address: "SIKHULU STREET  LINGELIHLE  CRADOCK",
    Latitude: "-32.1762",
    Longitude: "25.6311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1586",
    WardLookupId: "645",
    VDNumber: "10980154",
    RegisteredPopulation: "546",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "NOTWALA STREET  LINGELIHLE  CRADOCK",
    Latitude: "-32.1816",
    Longitude: "25.6354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1587",
    WardLookupId: "645",
    VDNumber: "10980165",
    RegisteredPopulation: "224",
    VotingStationName: "MICHAUSAL SENIOR PRIMARY SCHOOL",
    Address: "VISAGIE  MICHAUSDAL  CRADOCK",
    Latitude: "-32.1748",
    Longitude: "25.6373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1588",
    WardLookupId: "646",
    VDNumber: "10980031",
    RegisteredPopulation: "1149",
    VotingStationName: "MICHAUSDAL SENIOR SECONDARY SCHOOL",
    Address: "BARTISS STREET  MICHAUSDAL  CRADOCK",
    Latitude: "-32.1772",
    Longitude: "25.6468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1589",
    WardLookupId: "646",
    VDNumber: "10980064",
    RegisteredPopulation: "1368",
    VotingStationName: "MICHAUSDAL COMMUNITY HALL",
    Address: "MAIN ROAD  MICHAUSDAL  CRADOCK",
    Latitude: "-32.1753",
    Longitude: "25.6429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1590",
    WardLookupId: "646",
    VDNumber: "10980121",
    RegisteredPopulation: "1087",
    VotingStationName: "MICHAUSDAL CLUB HOUSE",
    Address: "SWIMMING POOL  MICHAUSDAL  CRADOCK",
    Latitude: "-32.1787",
    Longitude: "25.6571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1591",
    WardLookupId: "647",
    VDNumber: "10980053",
    RegisteredPopulation: "3299",
    VotingStationName: "CRADOCK TOWN HALL",
    Address: "MARKET SQUARE  CRADOCK  CRADOCK",
    Latitude: "-32.1703",
    Longitude: "25.6172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1592",
    WardLookupId: "648",
    VDNumber: "10980132",
    RegisteredPopulation: "1676",
    VotingStationName: "NOMSA FRANS COMMUNITY HALL",
    Address: "9 MQHAYI STREET, MICHAUSDAL  MICHAUSDAL  CRADOCK",
    Latitude: "-32.1729",
    Longitude: "25.6505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1593",
    WardLookupId: "648",
    VDNumber: "11090012",
    RegisteredPopulation: "66",
    VotingStationName: "HONEY GROVE FARM HOUSE",
    Address: "HONEY GROOVE  VAALDRAAI  CRADOCK",
    Latitude: "-32.3349",
    Longitude: "25.4808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1594",
    WardLookupId: "648",
    VDNumber: "11090023",
    RegisteredPopulation: "332",
    VotingStationName: "BARODA PRIMARY SCHOOL",
    Address: "BARODA FARM  PO BARODA 5882  CRADOCK",
    Latitude: "-32.0095",
    Longitude: "25.5055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1595",
    WardLookupId: "648",
    VDNumber: "11090034",
    RegisteredPopulation: "347",
    VotingStationName: "FISH RIVER TENNIS CLUB",
    Address: "FISH RIVER  CRADOCK",
    Latitude: "-31.9116",
    Longitude: "25.4184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1596",
    WardLookupId: "648",
    VDNumber: "11090045",
    RegisteredPopulation: "214",
    VotingStationName: "SAMEKOMST HALL",
    Address: "SAMEKOMST  DIST CRADOCK",
    Latitude: "-32.181",
    Longitude: "25.2751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1597",
    WardLookupId: "648",
    VDNumber: "11090056",
    RegisteredPopulation: "133",
    VotingStationName: "TENT AT LOMABARDY FARM",
    Address: "LOMBARD FARM  LOMBARDY  CRADOCK",
    Latitude: "-32.2113",
    Longitude: "25.967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1598",
    WardLookupId: "648",
    VDNumber: "11090067",
    RegisteredPopulation: "410",
    VotingStationName: "VLAKIE FARM",
    Address: "VLAKIE  MORTIMER  CRADOCK",
    Latitude: "-32.3667",
    Longitude: "25.6826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1599",
    WardLookupId: "648",
    VDNumber: "11090078",
    RegisteredPopulation: "85",
    VotingStationName: "BETJIESKRAAL FARM SHED",
    Address: "BETJIESKRAAL  CRADOCK",
    Latitude: "-31.9492",
    Longitude: "25.7037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1600",
    WardLookupId: "648",
    VDNumber: "11090089",
    RegisteredPopulation: "54",
    VotingStationName: "MOUNTAIN ZEBRA PARK COMMUNITY HALL (NAT PARK)",
    Address: "MOUNTAIN ZEBRA PARK  CRADOCK",
    Latitude: "-32.2239",
    Longitude: "25.4746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1601",
    WardLookupId: "649",
    VDNumber: "11010036",
    RegisteredPopulation: "2104",
    VotingStationName: "CIVIC CENTRE",
    Address: "MILES RD  MIDROS  MIDDELBURG CAPE",
    Latitude: "-31.5013",
    Longitude: "25.0275",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1602",
    WardLookupId: "649",
    VDNumber: "11130017",
    RegisteredPopulation: "203",
    VotingStationName: "SCHOOMBEE TRUST SCHOOL",
    Address: "SCHOOMBEE SCHOOL  SCHOOMBEE  MIDDELBURG CAPE",
    Latitude: "-31.4507",
    Longitude: "25.4951",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1603",
    WardLookupId: "649",
    VDNumber: "11130028",
    RegisteredPopulation: "199",
    VotingStationName: "VOLMOED PRIMARY SCHOOL",
    Address: "CONWAY  MIDDELBURG  MIDDELBURG CAPE",
    Latitude: "-31.7358",
    Longitude: "25.3042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1604",
    WardLookupId: "649",
    VDNumber: "11130039",
    RegisteredPopulation: "117",
    VotingStationName: "GROOTVLEI FARM SCHOOL",
    Address: "RICHMOND ROAD  MIDDLEBERG EC  MIDDLEBERG",
    Latitude: "-31.5551",
    Longitude: "24.8057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1605",
    WardLookupId: "649",
    VDNumber: "11130040",
    RegisteredPopulation: "349",
    VotingStationName: "CHRIS HANI DISTRICT COUNCIL OFFICE",
    Address: "MIDDLEBURG  MIDDLEBURG DISTRICT  MIDDLEBURG",
    Latitude: "-31.4839",
    Longitude: "25.0093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1606",
    WardLookupId: "649",
    VDNumber: "11130051",
    RegisteredPopulation: "278",
    VotingStationName: "LINDOKUHLE PRE-SCHOOL",
    Address: "OLD MILITARY UNIT  ROSEMEAD",
    Latitude: "-31.4884",
    Longitude: "25.113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1607",
    WardLookupId: "650",
    VDNumber: "11010025",
    RegisteredPopulation: "2576",
    VotingStationName: "ELLEN OLIVIER PRIMARY SCHOOL",
    Address: "RIVIERWEG  MIDROS  MIDDELBURG EC",
    Latitude: "-31.5052",
    Longitude: "25.0267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1608",
    WardLookupId: "650",
    VDNumber: "11010058",
    RegisteredPopulation: "806",
    VotingStationName: "SIYABULELA COMMUNITY HALL",
    Address: "CETYIWE STREET  KWANONZAME  MIDDELBURG CAPE",
    Latitude: "-31.5076",
    Longitude: "25.0036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1609",
    WardLookupId: "650",
    VDNumber: "11010069",
    RegisteredPopulation: "1174",
    VotingStationName: "MASIGCINANE EDUCARE CENTRE",
    Address: "236 OLD DOCATION  LUSAKA  MIDDLEBURG EC",
    Latitude: "-31.5025",
    Longitude: "25.0122",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1610",
    WardLookupId: "651",
    VDNumber: "11010014",
    RegisteredPopulation: "1501",
    VotingStationName: "MIDDELBURG CIVIC CENTRE",
    Address: "MARKET STREET  MIDDELBURG  MIDDELBURG",
    Latitude: "-31.4958",
    Longitude: "25.0045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1611",
    WardLookupId: "651",
    VDNumber: "11010047",
    RegisteredPopulation: "1883",
    VotingStationName: "AFRICAN UNITED BAPTIST CHURCH",
    Address: "NDAMANE  KWANONZAME  MIDDELBURG - EC",
    Latitude: "-31.5104",
    Longitude: "25.0036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1612",
    WardLookupId: "3001",
    VDNumber: "11080257",
    RegisteredPopulation: "393",
    VotingStationName: "THEMBISILE MARTIN HANI JUNIOR SECONDARY SCHOOL",
    Address: "UPPER SABALELE  COFIMVABA",
    Latitude: "-32.0966",
    Longitude: "27.4581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1613",
    WardLookupId: "3001",
    VDNumber: "11080268",
    RegisteredPopulation: "769",
    VotingStationName: "BANGILIZWE JUNIOR SECONDARY SCHOOL",
    Address: "ZIGUDU A/A  HOITA VILLAGE  COFIMVABA",
    Latitude: "-32.1366",
    Longitude: "27.4849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1614",
    WardLookupId: "3001",
    VDNumber: "11080448",
    RegisteredPopulation: "710",
    VotingStationName: "BANZI TRADITIONAL HALL",
    Address: "HOITA LOCATION  BANZI A/A  COFIMVABA",
    Latitude: "-32.1263",
    Longitude: "27.5199",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1615",
    WardLookupId: "3001",
    VDNumber: "11080482",
    RegisteredPopulation: "490",
    VotingStationName: "SENTILE JUNIOR SECONDARY SCHOOL",
    Address: "SENTILE LOCATION  ZIGUDU A/A  COFIMVABA",
    Latitude: "-32.1795",
    Longitude: "27.5161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1616",
    WardLookupId: "3001",
    VDNumber: "11080493",
    RegisteredPopulation: "261",
    VotingStationName: "ZIGUDU JUNIOR SECONDARY SCHOOL",
    Address: "ZIGUDU MISSION LOCATION  ZIGUDU A/A  COFIMVABA",
    Latitude: "-32.1554",
    Longitude: "27.5291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1617",
    WardLookupId: "3001",
    VDNumber: "11080707",
    RegisteredPopulation: "171",
    VotingStationName: "MTHIMBINI JUNIOR SECONDARY SCHOOL",
    Address: "MTHIMBINI LOCATION  CAMAMA A/A  COFIMVABA",
    Latitude: "-32.0619",
    Longitude: "27.5227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1618",
    WardLookupId: "3001",
    VDNumber: "11080718",
    RegisteredPopulation: "248",
    VotingStationName: "GUDWANA JUNIOR SECONDARY SCHOOL",
    Address: "GUDWANA LOCATION  CAMAMA FOREST A/A  COFIMVABA",
    Latitude: "-32.0651",
    Longitude: "27.4933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1619",
    WardLookupId: "3001",
    VDNumber: "11080842",
    RegisteredPopulation: "432",
    VotingStationName: "GQOBOZA PRIMARY SCHOOL",
    Address: "N/A  KUGQOBHOZA  INTSIKA YETHU [COFIMVABA]",
    Latitude: "-32.1106",
    Longitude: "27.4344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1620",
    WardLookupId: "3001",
    VDNumber: "11080853",
    RegisteredPopulation: "268",
    VotingStationName: "VUKANI SENIOR PRIMARY SCHOOL",
    Address: "ELIXENI LOCATION  BANZI A/A  COFIMVABA",
    Latitude: "-32.115",
    Longitude: "27.5509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1621",
    WardLookupId: "3001",
    VDNumber: "11080864",
    RegisteredPopulation: "191",
    VotingStationName: "ZWELIBANGILE JUNIOR SECONDARY SCHOOL",
    Address: "NGXINGWENI LOCATION  BANZI A/A  COFIMVABA",
    Latitude: "-32.091",
    Longitude: "27.5483",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1622",
    WardLookupId: "3001",
    VDNumber: "11080875",
    RegisteredPopulation: "306",
    VotingStationName: "NDLANGISA SENIOR PRIMARY SCHOOL",
    Address: "NDLANGISA LOCATION  QUTSA A/A  TSOMO",
    Latitude: "-32.0885",
    Longitude: "27.5633",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1623",
    WardLookupId: "3002",
    VDNumber: "11080202",
    RegisteredPopulation: "490",
    VotingStationName: "ST MARKS TRADITIONAL HALL",
    Address: "TYELERA LOCATION  ST MARKS A/A  COFIMVABA",
    Latitude: "-32.0187",
    Longitude: "27.3806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1624",
    WardLookupId: "3002",
    VDNumber: "11080213",
    RegisteredPopulation: "991",
    VotingStationName: "NTSHINGENI JUNIOR SECONDARY SCHOOL",
    Address: "MNANDI LOCATION  NTSHINGENI A/A  COFIMVABA",
    Latitude: "-32.0546",
    Longitude: "27.4302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1625",
    WardLookupId: "3002",
    VDNumber: "11080662",
    RegisteredPopulation: "587",
    VotingStationName: "ANGLICAN CHURCH",
    Address: "LALINI LOCATION  ST MARKS A/A  COFIMVABA",
    Latitude: "-32.0258",
    Longitude: "27.3975",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1626",
    WardLookupId: "3002",
    VDNumber: "11080673",
    RegisteredPopulation: "667",
    VotingStationName: "MBUDLU JUNIOR SECONDARY SCHOOL",
    Address: "MTHONJENI LOCATION  ST MARKS A/A  COFIMVABA",
    Latitude: "-32.0106",
    Longitude: "27.44",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1627",
    WardLookupId: "3002",
    VDNumber: "11080831",
    RegisteredPopulation: "445",
    VotingStationName: "MPOMVANE SENIOR PRIMARY SCHOOL",
    Address: "MPOMVANA LOCATION  NTSHINGENI A/A  COFIMVABA",
    Latitude: "-32.0558",
    Longitude: "27.4065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1628",
    WardLookupId: "3002",
    VDNumber: "11080965",
    RegisteredPopulation: "255",
    VotingStationName: "ST MARKS RURAL HOUSING HALL",
    Address: "BONGOLETHU LOCATION  ST MARKS A/A  COFIMVABA",
    Latitude: "-32.0057",
    Longitude: "27.3579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1629",
    WardLookupId: "3003",
    VDNumber: "10940240",
    RegisteredPopulation: "533",
    VotingStationName: "NKQUBELA JUNIOR SECONDARY SCHOOL",
    Address: "KHUZE VILLAGE  TSOMO  STUTTERHEIM",
    Latitude: "-32.2105",
    Longitude: "27.6171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1630",
    WardLookupId: "3003",
    VDNumber: "10940251",
    RegisteredPopulation: "405",
    VotingStationName: "SONKOSI JUNIOR SECONDARY SCHOOL",
    Address: "XOLOBE  TSOMO",
    Latitude: "-32.1999",
    Longitude: "27.6827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1631",
    WardLookupId: "3003",
    VDNumber: "10940262",
    RegisteredPopulation: "892",
    VotingStationName: "ALBERTINA SISULU JUNIOR SECONDARY SCHOOL",
    Address: "MIDDLE XOLOBE  XOLOBE  TSOMO",
    Latitude: "-32.1193",
    Longitude: "27.6462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1632",
    WardLookupId: "3003",
    VDNumber: "10940273",
    RegisteredPopulation: "256",
    VotingStationName: "MPIKWANA SENIOR PRIMARY SCHOOL",
    Address: "XOLOBE A/A  TSOMO",
    Latitude: "-32.1467",
    Longitude: "27.5929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1633",
    WardLookupId: "3003",
    VDNumber: "10940284",
    RegisteredPopulation: "433",
    VotingStationName: "DUMEZWENI JUNIOR SECONDARY SCHOOL",
    Address: "NTSITO A/A  TSOMO",
    Latitude: "-32.2546",
    Longitude: "27.7029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1634",
    WardLookupId: "3003",
    VDNumber: "10940396",
    RegisteredPopulation: "555",
    VotingStationName: "QWILI-QWILI JUNIOR SECONDARY SCHOOL",
    Address: "QWILI QWILI  TSOMO",
    Latitude: "-32.2875",
    Longitude: "27.7325",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1635",
    WardLookupId: "3003",
    VDNumber: "10940408",
    RegisteredPopulation: "233",
    VotingStationName: "CABA COMMUNITY HALL",
    Address: "CABA  TSOMO NU",
    Latitude: "-32.2412",
    Longitude: "27.6694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1636",
    WardLookupId: "3003",
    VDNumber: "10940419",
    RegisteredPopulation: "231",
    VotingStationName: "BENGU JUNIOR SECONDARY SCHOOL",
    Address: "XOLOBE A/A  TSOMO",
    Latitude: "-32.1443",
    Longitude: "27.6165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1637",
    WardLookupId: "3003",
    VDNumber: "10940565",
    RegisteredPopulation: "280",
    VotingStationName: "NOMZAMO SENIOR PRIMARY SCHOOL",
    Address: "NOMZAMO VILLAGE  TSOMO",
    Latitude: "-32.1252",
    Longitude: "27.6028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1638",
    WardLookupId: "3003",
    VDNumber: "10940576",
    RegisteredPopulation: "200",
    VotingStationName: "MOYENI VILLAGE (TENT)",
    Address: "TSOMO  MOYENI VILLAGE  TSOMO",
    Latitude: "-32.2513",
    Longitude: "27.6553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1639",
    WardLookupId: "3003",
    VDNumber: "10940813",
    RegisteredPopulation: "167",
    VotingStationName: "BLY NGCONGOLO JUNIOR PRIMARY SCHOOL",
    Address: "BENGU VILLAGE  TSOMO  STUTTERHEIM",
    Latitude: "-32.1907",
    Longitude: "27.6226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1640",
    WardLookupId: "3003",
    VDNumber: "10940824",
    RegisteredPopulation: "166",
    VotingStationName: "KHANYISA SENIOR PRIMARY SCHOOL",
    Address: "CABA VILLAGE  TSOMO  STUTTERHEIM",
    Latitude: "-32.2186",
    Longitude: "27.6654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1641",
    WardLookupId: "3004",
    VDNumber: "11070470",
    RegisteredPopulation: "372",
    VotingStationName: "MTHYINTYINI JUNIOR SECONDARY SCHOOL",
    Address: "MTHYINTYINI LOCATION  MBINZANA A/A  COFIMVABA",
    Latitude: "-31.9172",
    Longitude: "27.3974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1642",
    WardLookupId: "3004",
    VDNumber: "11070492",
    RegisteredPopulation: "589",
    VotingStationName: "BILATYE JUNIOR SECONDARY SCHOOL",
    Address: "UPPER BILATYE LOCATION  BILATYE A/A  COFIMVABA",
    Latitude: "-31.9452",
    Longitude: "27.3672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1643",
    WardLookupId: "3004",
    VDNumber: "11071033",
    RegisteredPopulation: "126",
    VotingStationName: "NYONGWANE SENIOR PRIMARY SCHOOL",
    Address: "N/A  BOLOTWA VILLAGE  COFIMVABA",
    Latitude: "-31.9396",
    Longitude: "27.3322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1644",
    WardLookupId: "3004",
    VDNumber: "11071044",
    RegisteredPopulation: "350",
    VotingStationName: "SINGENI SENIOR PRIMARY SCHOOL",
    Address: "RHOYI LOCATION  MBINZANA A/A  COFIMVABA",
    Latitude: "-31.9019",
    Longitude: "27.3121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1645",
    WardLookupId: "3004",
    VDNumber: "11071055",
    RegisteredPopulation: "408",
    VotingStationName: "ST PATRICK/THOBILE DYANTYI JUNIOR SECONDARY SCHOOL",
    Address: "QOLWENI LOCATION  BILATYE A/A  COFIMVABA",
    Latitude: "-31.9733",
    Longitude: "27.3409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1646",
    WardLookupId: "3004",
    VDNumber: "11071729",
    RegisteredPopulation: "342",
    VotingStationName: "MBINZANA JUNIOR SECONDARY SCHOOL",
    Address: "MGAZANA LOCATION  MBINZANA A/A  COFIMVABA",
    Latitude: "-31.9226",
    Longitude: "27.3543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1647",
    WardLookupId: "3004",
    VDNumber: "11080178",
    RegisteredPopulation: "751",
    VotingStationName: "GANDO JUNIOR SECONDARY SCHOOL",
    Address: "MAYA LOCATION  ST MARKS A/A  COFIMVABA/ST MARKS",
    Latitude: "-31.9894",
    Longitude: "27.3965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1648",
    WardLookupId: "3004",
    VDNumber: "11080459",
    RegisteredPopulation: "594",
    VotingStationName: "MHLOBO JUNIOR SECONDARY SCHOOL",
    Address: "NOGATE TOWNSHIP LOCATION  QAMATA A/A  COFIMVABA",
    Latitude: "-31.9569",
    Longitude: "27.4414",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1649",
    WardLookupId: "3004",
    VDNumber: "11080471",
    RegisteredPopulation: "332",
    VotingStationName: "NTLONZE JUNIOR SECONDARY SCHOOL",
    Address: "NTLONZE LOCATION  QAMATA A/A  COFIMVABA",
    Latitude: "-31.9213",
    Longitude: "27.4204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1650",
    WardLookupId: "3004",
    VDNumber: "11080729",
    RegisteredPopulation: "291",
    VotingStationName: "SHASHA SENIOR PRIMARY SCHOOL",
    Address: "ESIHLABENI LOCATION  QAMATA A/A  INTSIKA YETHU",
    Latitude: "-31.9427",
    Longitude: "27.4146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1651",
    WardLookupId: "3005",
    VDNumber: "11080189",
    RegisteredPopulation: "757",
    VotingStationName: "NKQWILISO JUNIOR SECONDARY SCHOOL",
    Address: "N/A  QAMATA BASIN  COFIMVABA",
    Latitude: "-31.9338",
    Longitude: "27.482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1652",
    WardLookupId: "3005",
    VDNumber: "11080325",
    RegisteredPopulation: "459",
    VotingStationName: "MVUZO JUNIOR SECONDARY SCHOOL",
    Address: "DECKETS HILL LOCATION  QAMATA A/A  COFIMVABA",
    Latitude: "-31.9742",
    Longitude: "27.51",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1653",
    WardLookupId: "3005",
    VDNumber: "11080336",
    RegisteredPopulation: "443",
    VotingStationName: "SIDUBI POORT JUNIOR SECONDARY SCHOOL",
    Address: "N/A  JARA VILLAGE  COFIMVABA",
    Latitude: "-32.0273",
    Longitude: "27.4883",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1654",
    WardLookupId: "3005",
    VDNumber: "11080347",
    RegisteredPopulation: "562",
    VotingStationName: "DALIWONGA SENIOR SECONDARY SCHOOL",
    Address: "UPPER WODEHOUSE LOCATION  WODEHOUSE A/A  COFIMVABA",
    Latitude: "-31.9651",
    Longitude: "27.5504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1655",
    WardLookupId: "3005",
    VDNumber: "11080415",
    RegisteredPopulation: "820",
    VotingStationName: "ZAMXOLO JUNIOR SECONDARY SCHOOL",
    Address: "NTLAKWEFOLO LOCATION  QAMATA A/A  COFIMVABA",
    Latitude: "-31.9899",
    Longitude: "27.4366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1656",
    WardLookupId: "3005",
    VDNumber: "11080730",
    RegisteredPopulation: "224",
    VotingStationName: "QAMATA GREAT PLACE",
    Address: "QAMATA  QAMATA A/A  COFIMVABA",
    Latitude: "-31.9584",
    Longitude: "27.4647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1657",
    WardLookupId: "3005",
    VDNumber: "11080820",
    RegisteredPopulation: "482",
    VotingStationName: "JONGULWANDLE JUNIOR PRIMARY SCHOOL",
    Address: "N/A  COFIMVABA  INTSIKA YETHU [COFIMVABA]",
    Latitude: "-31.9588",
    Longitude: "27.5121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1658",
    WardLookupId: "3006",
    VDNumber: "10940048",
    RegisteredPopulation: "340",
    VotingStationName: "XOLOBE COMMUNITY HALL",
    Address: "XOLOBE A/A  TSOMO",
    Latitude: "-32.152",
    Longitude: "27.6997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1659",
    WardLookupId: "3006",
    VDNumber: "10940082",
    RegisteredPopulation: "222",
    VotingStationName: "GCIBALA JUNIOR SECONDARY SCHOOL",
    Address: "MDUNJENI LOCATION  GCIBALA A/A  TSOMO",
    Latitude: "-32.187",
    Longitude: "27.7743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1660",
    WardLookupId: "3006",
    VDNumber: "10940138",
    RegisteredPopulation: "405",
    VotingStationName: "ENTILINI JUNIOR SECONDARY SCHOOL",
    Address: "INTILINI LOCATION  LUTHULI A/A  TSOMO",
    Latitude: "-32.0976",
    Longitude: "27.717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1661",
    WardLookupId: "3006",
    VDNumber: "10940295",
    RegisteredPopulation: "231",
    VotingStationName: "MAXAMA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  SIGUBUDWINI  TSOMO",
    Latitude: "-32.1432",
    Longitude: "27.7432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1662",
    WardLookupId: "3006",
    VDNumber: "10940307",
    RegisteredPopulation: "165",
    VotingStationName: "MBULU JUNIOR SECONDARY SCHOOL",
    Address: "N/A  MBULU A/A  TSOMO",
    Latitude: "-32.2083",
    Longitude: "27.7309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1663",
    WardLookupId: "3006",
    VDNumber: "10940352",
    RegisteredPopulation: "324",
    VotingStationName: "MGWENYANA COMMUNITY HALL",
    Address: "MGWENYANA LOCATION  LUTHULI A/A  TSOMO",
    Latitude: "-32.1314",
    Longitude: "27.7402",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1664",
    WardLookupId: "3006",
    VDNumber: "10940363",
    RegisteredPopulation: "163",
    VotingStationName: "ESIGUBUDWINI JUNIOR SECONDARY SCHOOL",
    Address: "LALINI LOCATION  SIGUBUDWINI A/A  TSOMO",
    Latitude: "-32.1624",
    Longitude: "27.7752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1665",
    WardLookupId: "3006",
    VDNumber: "10940374",
    RegisteredPopulation: "217",
    VotingStationName: "MBULUKWEZA SENIOR PRIMARY SCHOOL",
    Address: "N/A  UPPER MBULUKWEZA  TSOMO",
    Latitude: "-32.2267",
    Longitude: "27.7114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1666",
    WardLookupId: "3006",
    VDNumber: "10940385",
    RegisteredPopulation: "405",
    VotingStationName: "NDEMA SENIOR SECONDARY SCHOOL",
    Address: "MFULA  TSOMO",
    Latitude: "-32.2452",
    Longitude: "27.7513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1667",
    WardLookupId: "3006",
    VDNumber: "10940420",
    RegisteredPopulation: "304",
    VotingStationName: "MTSHANYANE JUNIOR SECONDARY SCHOOL",
    Address: "MBULU HILL LOCATION  MBULU A/A  TSOMO",
    Latitude: "-32.1701",
    Longitude: "27.7165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1668",
    WardLookupId: "3006",
    VDNumber: "10940587",
    RegisteredPopulation: "184",
    VotingStationName: "PRESBYTERIAN CHURCH OF AFRICA",
    Address: "MFULA VILLAGE  TSOMO",
    Latitude: "-32.2337",
    Longitude: "27.7247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1669",
    WardLookupId: "3006",
    VDNumber: "10940677",
    RegisteredPopulation: "153",
    VotingStationName: "LOWER MBULUKWENZA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  LOWER MBULUKHWEZA  INTSIKA YETHU [COFIMVABA]",
    Latitude: "-32.2272",
    Longitude: "27.745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1670",
    WardLookupId: "3006",
    VDNumber: "10940688",
    RegisteredPopulation: "74",
    VotingStationName: "RHABE CHURCH",
    Address: "VAWUKANA LOCATION  SIGUBUDWINI A/A  TSOMO",
    Latitude: "-32.1449",
    Longitude: "27.7825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1671",
    WardLookupId: "3006",
    VDNumber: "10940699",
    RegisteredPopulation: "129",
    VotingStationName: "LAMPTA SENIOR PRIMARY SCHOOL",
    Address: "KHALANA LOCATION  SIGUBUDWINI A/A  TSOMO",
    Latitude: "-32.1549",
    Longitude: "27.8135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1672",
    WardLookupId: "3006",
    VDNumber: "10940857",
    RegisteredPopulation: "251",
    VotingStationName: "JONGINTSIZI SENIOR PRIMARY SCHOOL",
    Address: "KOMKHULU LOCATION  GCIBALA A/A  TSOMO",
    Latitude: "-32.199",
    Longitude: "27.761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1673",
    WardLookupId: "3006",
    VDNumber: "10940868",
    RegisteredPopulation: "247",
    VotingStationName: "KHONCOSHE PRE-SCHOOL",
    Address: "NOMNYONKOLO VILLAGE  MBULU  TSOMO",
    Latitude: "-32.191",
    Longitude: "27.6922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1674",
    WardLookupId: "3007",
    VDNumber: "10940183",
    RegisteredPopulation: "515",
    VotingStationName: "UPPER QUTSA JUNIOR SECONDARY SCHOOL",
    Address: "NEW MINE LOCATION  UPPER QUTSA A/A  TSOMO",
    Latitude: "-32.0664",
    Longitude: "27.6767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1675",
    WardLookupId: "3007",
    VDNumber: "10940239",
    RegisteredPopulation: "378",
    VotingStationName: "ZWELAKHE JUNIOR SECONDARY SCHOOL",
    Address: "TALENI LOCATION  QUTSA A/A  TSOMO",
    Latitude: "-32.0899",
    Longitude: "27.6586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1676",
    WardLookupId: "3007",
    VDNumber: "10940453",
    RegisteredPopulation: "356",
    VotingStationName: "LOWER QUTSA JUNIOR SECONDARY SCHOOL",
    Address: "GUBEVU LOCATION  QUTSA A/A  TSOMO",
    Latitude: "-32.0363",
    Longitude: "27.653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1677",
    WardLookupId: "3007",
    VDNumber: "10940475",
    RegisteredPopulation: "204",
    VotingStationName: "TOM SOPETE JUNIOR SECONDARY SCHOOL",
    Address: "KWA-NTSELA LOCATION  LUTHULI A/A  TSOMO",
    Latitude: "-32.1139",
    Longitude: "27.6911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1678",
    WardLookupId: "3007",
    VDNumber: "10940644",
    RegisteredPopulation: "227",
    VotingStationName: "KUYASA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  KUYASA AA  INTSIKA YETHU [COFIMVABA]",
    Latitude: "-32.0414",
    Longitude: "27.7034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1679",
    WardLookupId: "3007",
    VDNumber: "10940655",
    RegisteredPopulation: "307",
    VotingStationName: "MPOTULO JUNIOR SECONDARY SCHOOL",
    Address: "MANGUNKONE LOCATION  QUTSA A/A  TSOMO",
    Latitude: "-32.0678",
    Longitude: "27.7095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1680",
    WardLookupId: "3007",
    VDNumber: "10940789",
    RegisteredPopulation: "248",
    VotingStationName: "UPPER NQOLOSA JUNIOR SECONDARY SCHOOL",
    Address: "UPPER NQOLOSA VILLAGE  TSOMO",
    Latitude: "-32.1037",
    Longitude: "27.6011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1681",
    WardLookupId: "3007",
    VDNumber: "10940790",
    RegisteredPopulation: "258",
    VotingStationName: "LOWER NQOLOSA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  LOWER NQOLOSA VILLAGE  TSOMO",
    Latitude: "-32.1377",
    Longitude: "27.575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1682",
    WardLookupId: "3007",
    VDNumber: "11080246",
    RegisteredPopulation: "580",
    VotingStationName: "CHESHIRE HOME",
    Address: "CAMAMA A/A  INTSIKA YETHU",
    Latitude: "-32.0426",
    Longitude: "27.5546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1683",
    WardLookupId: "3007",
    VDNumber: "11080426",
    RegisteredPopulation: "279",
    VotingStationName: "ZWELIBANZI JUNIOR SECONDARY SCHOOL",
    Address: "KWA-NYOKA LOCATION  QUTSA A/A  TSOMO",
    Latitude: "-32.0849",
    Longitude: "27.6044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1684",
    WardLookupId: "3007",
    VDNumber: "11080505",
    RegisteredPopulation: "575",
    VotingStationName: "KHANYISA JUNIOR SECONDARY SCHOOL",
    Address: "KWANDUNGWANE LOCATION  QUTSA A/A  COFIMVABA",
    Latitude: "-32.0649",
    Longitude: "27.6289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1685",
    WardLookupId: "3008",
    VDNumber: "10740013",
    RegisteredPopulation: "312",
    VotingStationName: "EAST BANK JUNIOR SECONDARY SCHOOL",
    Address: "N/A  EAST BANK A/A  INTSIKA YETHU",
    Latitude: "-32.0474",
    Longitude: "27.857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1686",
    WardLookupId: "3008",
    VDNumber: "10910179",
    RegisteredPopulation: "325",
    VotingStationName: "MADUMA JUNIOR SECONDARY SCHOOL",
    Address: "TSHETSHENGWANE LOCATION  MADUMA A/A  TSOMO",
    Latitude: "-32.0636",
    Longitude: "27.8692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1687",
    WardLookupId: "3008",
    VDNumber: "10910214",
    RegisteredPopulation: "306",
    VotingStationName: "EZOLO SCHOOL",
    Address: "NTENZA  NTENZA LOCATION  TSOMO",
    Latitude: "-32.0956",
    Longitude: "27.8645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1688",
    WardLookupId: "3008",
    VDNumber: "10910405",
    RegisteredPopulation: "351",
    VotingStationName: "NONKQUBELA JUNIOR SECONDARY SCHOOL",
    Address: "EZOLO  EZOLO A/A  TSOMO",
    Latitude: "-32.1217",
    Longitude: "27.8422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1689",
    WardLookupId: "3008",
    VDNumber: "10910843",
    RegisteredPopulation: "384",
    VotingStationName: "DAZA JUNIOR SECONDARY SCHOOL",
    Address: "SHWEME LOCATION  DAZA A/A  TSOMO",
    Latitude: "-32.0608",
    Longitude: "27.8287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1690",
    WardLookupId: "3008",
    VDNumber: "10940093",
    RegisteredPopulation: "396",
    VotingStationName: "TSOMO HIGH SCHOOL",
    Address: "TSOMO MISSION LOCATION  MKHWINTI A/A  TSOMO",
    Latitude: "-32.0335",
    Longitude: "27.7896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1691",
    WardLookupId: "3008",
    VDNumber: "10940116",
    RegisteredPopulation: "468",
    VotingStationName: "WILSON MAYEKISO SENIOR PRIMARY SCHOOL",
    Address: "MATOLWENI LOCATION  NGCONGCOLORA A/A  TSOMO",
    Latitude: "-32.0722",
    Longitude: "27.7774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1692",
    WardLookupId: "3008",
    VDNumber: "10940464",
    RegisteredPopulation: "291",
    VotingStationName: "NTSUME`S JUNIOR SECONDARY SCHOOL",
    Address: "NGCONGCOLORHA  NGCONGCOLORHA A/A  TSOMO",
    Latitude: "-32.0604",
    Longitude: "27.7506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1693",
    WardLookupId: "3008",
    VDNumber: "10940521",
    RegisteredPopulation: "1191",
    VotingStationName: "TSOMO TOWN HALL",
    Address: "MAIN STREET  TSOMO   TSOMO",
    Latitude: "-32.0377",
    Longitude: "27.8197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1694",
    WardLookupId: "3009",
    VDNumber: "10740024",
    RegisteredPopulation: "479",
    VotingStationName: "GOBINAMBA SENIOR SECONDARY SCHOOL",
    Address: "SIKOLWENI LOCATION  XUME A/A  TSOMO",
    Latitude: "-32.0582",
    Longitude: "27.9018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1695",
    WardLookupId: "3009",
    VDNumber: "10910696",
    RegisteredPopulation: "681",
    VotingStationName: "MABHENTSENI JUNIOR SECONDARY SCHOOL",
    Address: "MABHENTSENI LOCATION  GQOGQORA A/A  TSOMO",
    Latitude: "-32.0235",
    Longitude: "27.9041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1696",
    WardLookupId: "3009",
    VDNumber: "10940026",
    RegisteredPopulation: "447",
    VotingStationName: "GAQELO SENIOR PRIMARY SCHOOL.",
    Address: "JWAYI LOCATION  GQOGQORA A/A  TSOMO",
    Latitude: "-31.9847",
    Longitude: "27.9485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1697",
    WardLookupId: "3009",
    VDNumber: "10940071",
    RegisteredPopulation: "331",
    VotingStationName: "MIDDLE GQOGQORA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  GQOGQORA VILLAGE  TSOMO",
    Latitude: "-31.9994",
    Longitude: "27.9225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1698",
    WardLookupId: "3009",
    VDNumber: "10940554",
    RegisteredPopulation: "459",
    VotingStationName: "UPPER GQOGQORA JUNIOR SECONDARY SCHOOL",
    Address: "JOJWENI LOCATION  GQOGQORA A/A  TSOMO",
    Latitude: "-32.0237",
    Longitude: "27.9539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1699",
    WardLookupId: "3009",
    VDNumber: "10940633",
    RegisteredPopulation: "572",
    VotingStationName: "UPPER XUME SECONDARY SCHOOL",
    Address: "MNYAMANDAWO LOCATION  XUME A/A  TSOMO",
    Latitude: "-32.0712",
    Longitude: "27.9342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1700",
    WardLookupId: "3009",
    VDNumber: "10940756",
    RegisteredPopulation: "364",
    VotingStationName: "LUFUKUFU JUNIOR SECONDARY SCHOOL",
    Address: "GQOGQORHA  LUFUKUFUKU A/A  TSOMO",
    Latitude: "-32.0435",
    Longitude: "27.9459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1701",
    WardLookupId: "3010",
    VDNumber: "10940037",
    RegisteredPopulation: "282",
    VotingStationName: "MMANGUBOMVU JUNIOR SECONDARY SCHOOL",
    Address: "MANGUBOMVU LOCATION  MAHLUBINI A/A  TSOMO",
    Latitude: "-31.9897",
    Longitude: "27.8505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1702",
    WardLookupId: "3010",
    VDNumber: "10940059",
    RegisteredPopulation: "208",
    VotingStationName: "MANGELENGELE JUNIOR SECONDARY SCHOOL",
    Address: "KOMKHULU LOCATION  MAHLUBINI  TSOMO",
    Latitude: "-31.9702",
    Longitude: "27.842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1703",
    WardLookupId: "3010",
    VDNumber: "10940060",
    RegisteredPopulation: "460",
    VotingStationName: "MVELASE JUNIOR SECONDARY SCHOOL",
    Address: "MHLAHLANE  MHLAHLANE A/A  TSOMO",
    Latitude: "-31.927",
    Longitude: "27.8211",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1704",
    WardLookupId: "3010",
    VDNumber: "10940149",
    RegisteredPopulation: "251",
    VotingStationName: "NCWANA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  MHLAHLANE  TSOMO",
    Latitude: "-31.8827",
    Longitude: "27.7943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1705",
    WardLookupId: "3010",
    VDNumber: "10940150",
    RegisteredPopulation: "490",
    VotingStationName: "MTSHABE JUNIOR SECONDARY SCHOOL",
    Address: "NYALASA LOCATION  MTSHABE A/A  TSOMO",
    Latitude: "-31.882",
    Longitude: "27.7853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1706",
    WardLookupId: "3010",
    VDNumber: "10940341",
    RegisteredPopulation: "397",
    VotingStationName: "GCINGCA JUNIOR SECONDARY SCHOOL",
    Address: "BUSINI LOCATION  MKHWINTI A/A  TSOMO",
    Latitude: "-31.9925",
    Longitude: "27.8056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1707",
    WardLookupId: "3010",
    VDNumber: "10940510",
    RegisteredPopulation: "550",
    VotingStationName: "BONUS JUNIOR SECONDARY SCHOOL",
    Address: "NDONDO LOCATION  MAHLUBINI A/A  TSOMO",
    Latitude: "-31.9292",
    Longitude: "27.8384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1708",
    WardLookupId: "3010",
    VDNumber: "10940734",
    RegisteredPopulation: "142",
    VotingStationName: "LOWER GQOGQORA JUNIOR SECONDARY SCHOOL",
    Address: "LOWER GQOGQORA LOCATION  GQOGQORA A/A  TSOMO",
    Latitude: "-31.9831",
    Longitude: "27.8627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1709",
    WardLookupId: "3010",
    VDNumber: "10940802",
    RegisteredPopulation: "228",
    VotingStationName: "JONGIMISHINI SENIOR PRIMARY SCHOOL",
    Address: "FORTY LOCATION  MKWINTI A/A  TSOMO",
    Latitude: "-32.0071",
    Longitude: "27.7736",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1710",
    WardLookupId: "3010",
    VDNumber: "10940846",
    RegisteredPopulation: "261",
    VotingStationName: "LOWER TSOJANA JUNIOR SECONDARY SCHOOL",
    Address: "LOWER TSOJANA LOCATION  TSOJANA A/A  TSOMO",
    Latitude: "-31.944",
    Longitude: "27.7839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1711",
    WardLookupId: "3011",
    VDNumber: "10940543",
    RegisteredPopulation: "383",
    VotingStationName: "NGUBEZULU JUNIOR SECONDARY SCHOOL",
    Address: "SIJONGOLWENI LOCATION  GQOGQORA A/A  TSOMO",
    Latitude: "-31.9819",
    Longitude: "27.9397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1712",
    WardLookupId: "3011",
    VDNumber: "10940767",
    RegisteredPopulation: "246",
    VotingStationName: "GQOGQORA SENIOR PRIMARY SCHOOL",
    Address: "KOMKHULU LOCATION  GQOGQORA A/A  TSOMO",
    Latitude: "-31.9843",
    Longitude: "27.8874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1713",
    WardLookupId: "3011",
    VDNumber: "10940778",
    RegisteredPopulation: "358",
    VotingStationName: "PHILEMON JUNIOR SECONDARY SCHOOL",
    Address: "MDENI LOCATION  GQOGQORA A/A  TSOMO",
    Latitude: "-31.9744",
    Longitude: "27.9083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1714",
    WardLookupId: "3011",
    VDNumber: "11080011",
    RegisteredPopulation: "500",
    VotingStationName: "DALISIZWE JUNIOR SECONDARY SCHOOL",
    Address: "ZINYOKA LOCATION  MAKHWABABA A/A  COFIMVABA",
    Latitude: "-31.9164",
    Longitude: "27.8836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1715",
    WardLookupId: "3011",
    VDNumber: "11080022",
    RegisteredPopulation: "329",
    VotingStationName: "JONGINAMBA JUNIOR SECONDARY SCHOOL",
    Address: "NTABENI LOCATION  QITSI A/A  COFIMVABA",
    Latitude: "-31.9469",
    Longitude: "27.9592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1716",
    WardLookupId: "3011",
    VDNumber: "11080369",
    RegisteredPopulation: "395",
    VotingStationName: "MDIBANISO JUNIOR SECONDARY SCHOOL",
    Address: "MDIBANISO LOCATION  QITSI A/A  COFIMVABA",
    Latitude: "-31.9186",
    Longitude: "27.9287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1717",
    WardLookupId: "3011",
    VDNumber: "11080381",
    RegisteredPopulation: "520",
    VotingStationName: "HLATIKHULU JUNIOR SECONDARY SCHOOL",
    Address: "MTWASHINI LOCATION  QITSI A/A  COFIMVABA",
    Latitude: "-31.9205",
    Longitude: "27.959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1718",
    WardLookupId: "3011",
    VDNumber: "11080404",
    RegisteredPopulation: "587",
    VotingStationName: "NDLUNKULU JUNIOR SECONDARY SCHOOL",
    Address: "NDLUNKULU LOCATION  QITSI A/A  COFIMVABA",
    Latitude: "-31.9611",
    Longitude: "27.9452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1719",
    WardLookupId: "3012",
    VDNumber: "11080033",
    RegisteredPopulation: "980",
    VotingStationName: "SABATA SENIOR SECONDARY SCHOOL",
    Address: "LOWER QITSI LOCATION   QITSI A/A  COFIMVABA",
    Latitude: "-31.8843",
    Longitude: "27.9331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1720",
    WardLookupId: "3012",
    VDNumber: "11080044",
    RegisteredPopulation: "396",
    VotingStationName: "ZWELIXOLILE JUNIOR SECONDARY SCHOOL",
    Address: "N/A  NGQWARU VILLAGE  COFIMVABA",
    Latitude: "-31.8681",
    Longitude: "27.8746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1721",
    WardLookupId: "3012",
    VDNumber: "11080055",
    RegisteredPopulation: "644",
    VotingStationName: "GXOJENI/KAULELE HALL",
    Address: "GXOJENI LOCATION  QUMANCO A/A  COFIMVABA",
    Latitude: "-31.8385",
    Longitude: "27.8695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1722",
    WardLookupId: "3012",
    VDNumber: "11080370",
    RegisteredPopulation: "463",
    VotingStationName: "KWABABA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  NOBHOKWE VILLAGE  COFIMVABA",
    Latitude: "-31.8979",
    Longitude: "27.8649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1723",
    WardLookupId: "3012",
    VDNumber: "11080392",
    RegisteredPopulation: "733",
    VotingStationName: "MAIN JUNIOR SECONDARY SCHOOL",
    Address: "QWEBE-QWEBE LOCATION  QITSI A/A  COFIMVABA",
    Latitude: "-31.8757",
    Longitude: "27.9586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1724",
    WardLookupId: "3012",
    VDNumber: "11080628",
    RegisteredPopulation: "263",
    VotingStationName: "NGOJINI JUNIOR SECONDARY SCHOOL",
    Address: "NGOJINI LOCATION  QUMANCO A/A  COFIMVABA",
    Latitude: "-31.8366",
    Longitude: "27.9088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1725",
    WardLookupId: "3013",
    VDNumber: "10940105",
    RegisteredPopulation: "332",
    VotingStationName: "MKWINTI JUNIOR SECONDARY SCHOOL",
    Address: "MDLETYENI LOCATION  MKWINTI A/A  TSOMO",
    Latitude: "-31.9706",
    Longitude: "27.7815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1726",
    WardLookupId: "3013",
    VDNumber: "10940127",
    RegisteredPopulation: "467",
    VotingStationName: "NGUDLE JUNIOR SECONDARY SCHOOL",
    Address: "TSOJANA LOCATION  NGUDLE A/A  TSOMO",
    Latitude: "-31.9316",
    Longitude: "27.7093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1727",
    WardLookupId: "3013",
    VDNumber: "10940194",
    RegisteredPopulation: "466",
    VotingStationName: "MAWUSHE JUNIOR SECONDARY SCHOOL",
    Address: "N/A  MAWUSHENI VILLAGE  TSOMO",
    Latitude: "-31.9313",
    Longitude: "27.6676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1728",
    WardLookupId: "3013",
    VDNumber: "10940206",
    RegisteredPopulation: "409",
    VotingStationName: "BONGOLETHU JUNIOR SECONDARY SCHOOL",
    Address: "KOMKHULU LOCATION  QOMBOLO A/A  TSOMO",
    Latitude: "-31.9982",
    Longitude: "27.6657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1729",
    WardLookupId: "3013",
    VDNumber: "10940217",
    RegisteredPopulation: "404",
    VotingStationName: "KWEBULANA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  KWEBULANA  TSOMO",
    Latitude: "-32.0076",
    Longitude: "27.6956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1730",
    WardLookupId: "3013",
    VDNumber: "10940228",
    RegisteredPopulation: "270",
    VotingStationName: "PAPAMA JUNIOR SECONDARY SCHOOL",
    Address: "KOMFENI LOCATION  QOMBOLO A/A  TSOMO",
    Latitude: "-31.9704",
    Longitude: "27.6488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1731",
    WardLookupId: "3013",
    VDNumber: "10940329",
    RegisteredPopulation: "619",
    VotingStationName: "SINETHEMBA COMMUNITY HALL",
    Address: "MGXOBHOZWENI LOCATION  NGCEZA A/A  TSOMO",
    Latitude: "-31.9755",
    Longitude: "27.7341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1732",
    WardLookupId: "3013",
    VDNumber: "10940330",
    RegisteredPopulation: "430",
    VotingStationName: "GXWALUBOMVU JUNIOR SECONDARY SCHOOL",
    Address: "GXWALUBOMVU LOCATION  HANGE A/A  TSOMO",
    Latitude: "-32.018",
    Longitude: "27.7477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1733",
    WardLookupId: "3013",
    VDNumber: "10940701",
    RegisteredPopulation: "197",
    VotingStationName: "LUMANI SOCIETY CHURCH",
    Address: "MAZIZINI LOCATION  LUMANI A/A  TSOMO",
    Latitude: "-32.0455",
    Longitude: "27.762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1734",
    WardLookupId: "3013",
    VDNumber: "10940712",
    RegisteredPopulation: "311",
    VotingStationName: "HANGE JUNIOR SECONDARY SCHOOL",
    Address: "N/A  KUHANGE  INTSIKA YETHU [COFIMVABA]",
    Latitude: "-32.005",
    Longitude: "27.7273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1735",
    WardLookupId: "3013",
    VDNumber: "10940723",
    RegisteredPopulation: "292",
    VotingStationName: "PHUMLA JUNIOR SECONDARY SCHOOL",
    Address: "SICUBENI LOCATION  QOMBOLO A/A  TSOMO",
    Latitude: "-31.9708",
    Longitude: "27.6794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1736",
    WardLookupId: "3014",
    VDNumber: "10970018",
    RegisteredPopulation: "1158",
    VotingStationName: "GOOD HOPE COMMUNITY SCHOOL",
    Address: "N/A  ENYANISWENI  INTSIKA YETHU [COFIMVABA]",
    Latitude: "-32.0188",
    Longitude: "27.5783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1737",
    WardLookupId: "3014",
    VDNumber: "10970029",
    RegisteredPopulation: "2397",
    VotingStationName: "COFIMVABA TOWN HALL",
    Address: "COFIMVABA TOWN  COFIMVABA",
    Latitude: "-32.0017",
    Longitude: "27.5795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1738",
    WardLookupId: "3014",
    VDNumber: "11080235",
    RegisteredPopulation: "634",
    VotingStationName: "MAHLUBINI JUNIOR SECONDARY SCHOOL",
    Address: "MAHLUBINI LOCATION  MAHLUBINI A/A  COFIMVABA",
    Latitude: "-32.0226",
    Longitude: "27.6104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1739",
    WardLookupId: "3015",
    VDNumber: "11080190",
    RegisteredPopulation: "636",
    VotingStationName: "LUNGILE JUNIOR SECONDARY SCHOOL",
    Address: "LOWER WODEHOUSE LOCATION  ISIKHOBA A/A  COFIMVABA",
    Latitude: "-31.9562",
    Longitude: "27.5722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1740",
    WardLookupId: "3015",
    VDNumber: "11080224",
    RegisteredPopulation: "533",
    VotingStationName: "MCUMGCO JUNIOR SECONDARY SCHOOL",
    Address: "MCUMNGCO LOCATION  MAGWALA A/A  COFIMVABA",
    Latitude: "-32.0035",
    Longitude: "27.5369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1741",
    WardLookupId: "3015",
    VDNumber: "11080358",
    RegisteredPopulation: "911",
    VotingStationName: "ISIKHOBA JUNIOR SECONDARY SCHOOL",
    Address: "ISIKHOBA LOCATION  ISIKHOBA A/A  COFIMVABA",
    Latitude: "-31.9834",
    Longitude: "27.6114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1742",
    WardLookupId: "3015",
    VDNumber: "11080516",
    RegisteredPopulation: "220",
    VotingStationName: "MATSHONA JUNIOR SECONDARY SCHOOL",
    Address: "MATSHONA LOCATION  MAGWALA A/A  COFIMVABA",
    Latitude: "-31.9923",
    Longitude: "27.522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1743",
    WardLookupId: "3015",
    VDNumber: "11080741",
    RegisteredPopulation: "138",
    VotingStationName: "GILINKUMBI SENIOR PRIMARY SCHOOL",
    Address: "KWA-CUBE  MAGWALA A/A  COFIMVABA",
    Latitude: "-32.0289",
    Longitude: "27.541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1744",
    WardLookupId: "3015",
    VDNumber: "11080886",
    RegisteredPopulation: "235",
    VotingStationName: "MAGWALA STADIUM",
    Address: "NYANZELA LOCATION  MAGWALA A/A  INTSIKA YETHU [COFIMVABA]",
    Latitude: "-31.9839",
    Longitude: "27.558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1745",
    WardLookupId: "3015",
    VDNumber: "11080897",
    RegisteredPopulation: "605",
    VotingStationName: "DULI JUNIOR SECONDARY SCHOOL",
    Address: "LUXHOMO LOCATION  MAGWALA A/A  COFIMVABA",
    Latitude: "-32.0054",
    Longitude: "27.553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1746",
    WardLookupId: "3016",
    VDNumber: "10940161",
    RegisteredPopulation: "440",
    VotingStationName: "NGONYAMA SECONDARY SCHOOL",
    Address: "NGONYAMA LOCATION  NGONYAMA A/A  TSOMO",
    Latitude: "-31.8739",
    Longitude: "27.6939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1747",
    WardLookupId: "3016",
    VDNumber: "10940172",
    RegisteredPopulation: "408",
    VotingStationName: "TSOJANA JUNIOR SECONDARY SCHOOL",
    Address: "TSOJANA LOCATION  NGONYAMA A/A  TSOMO",
    Latitude: "-31.9196",
    Longitude: "27.7527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1748",
    WardLookupId: "3016",
    VDNumber: "10940431",
    RegisteredPopulation: "411",
    VotingStationName: "QWILI JUNIOR SECONDARY SCHOOL",
    Address: "TSOJANA VILLAGE  QWILI A/A  TSOMO",
    Latitude: "-31.8867",
    Longitude: "27.661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1749",
    WardLookupId: "3016",
    VDNumber: "10940442",
    RegisteredPopulation: "364",
    VotingStationName: "BOLANA JUNIOR SECONDARY SCHOOL",
    Address: "BOLANA LOCATION  SIJULA A/A  TSOMO",
    Latitude: "-31.9098",
    Longitude: "27.622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1750",
    WardLookupId: "3016",
    VDNumber: "10940666",
    RegisteredPopulation: "406",
    VotingStationName: "MTYAMDE JUNIOR SECONDARY SCHOOL",
    Address: "MTYAMNDE LOCATION  NGONYAMA A/A  TSOMO",
    Latitude: "-31.8965",
    Longitude: "27.6637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1751",
    WardLookupId: "3016",
    VDNumber: "10940745",
    RegisteredPopulation: "280",
    VotingStationName: "MAHLATHINI JUNIOR SECONDARY SCHOOL",
    Address: "MAHLATHINI LOCATIOM  MAWUSHENI A/A  COFIMVABA",
    Latitude: "-31.9441",
    Longitude: "27.642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1752",
    WardLookupId: "3016",
    VDNumber: "11080145",
    RegisteredPopulation: "913",
    VotingStationName: "NCUNCUZO SENIOR SECONDARY SCHOOL",
    Address: "MANDLANENI LOCATION  LOWER MNCUNCUZO A/A  COFIMVABA",
    Latitude: "-31.8985",
    Longitude: "27.6043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1753",
    WardLookupId: "3017",
    VDNumber: "11080066",
    RegisteredPopulation: "1285",
    VotingStationName: "NCORA FLATS JUNIOR SECONDARY SCHOOL",
    Address: "NCORA FLATS LOCATION  NCORA A/A  COFIMVABA",
    Latitude: "-31.8273",
    Longitude: "27.7532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1754",
    WardLookupId: "3017",
    VDNumber: "11080303",
    RegisteredPopulation: "558",
    VotingStationName: "SIGANGENI JUNIOR SECONDARY SCHOOL",
    Address: "SIGANGENI LOCATION  QUMANCO A/A  COFIMVABA",
    Latitude: "-31.792",
    Longitude: "27.8329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1755",
    WardLookupId: "3017",
    VDNumber: "11080606",
    RegisteredPopulation: "412",
    VotingStationName: "MATAFENI JUNIOR SECONDARY SCHOOL",
    Address: "MATAFENI LOCATION  NCORA A/A  COFIMVABA",
    Latitude: "-31.8294",
    Longitude: "27.7939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1756",
    WardLookupId: "3017",
    VDNumber: "11080617",
    RegisteredPopulation: "367",
    VotingStationName: "NGQWASHU JUNIOR SECONDARY SCHOOL",
    Address: "LUQOLWENI LOCATION  QUMANCO A/A  COFIMVABA",
    Latitude: "-31.8709",
    Longitude: "27.8404",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1757",
    WardLookupId: "3017",
    VDNumber: "11080651",
    RegisteredPopulation: "482",
    VotingStationName: "QUMANCO JUNIOR SECONDARY SCHOOL",
    Address: "QUMANCO  QUMANCO A/A  NCORA",
    Latitude: "-31.8084",
    Longitude: "27.8456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1758",
    WardLookupId: "3017",
    VDNumber: "11080954",
    RegisteredPopulation: "340",
    VotingStationName: "NGQWARHU JUNIOR SECONDARY SCHOOL",
    Address: "NGQWARHU LOCATION  NGQWARHU A/A  COFIMVABA",
    Latitude: "-31.8595",
    Longitude: "27.8013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1759",
    WardLookupId: "3018",
    VDNumber: "11080077",
    RegisteredPopulation: "876",
    VotingStationName: "MBAXA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  FAMENI  COFIMVABA",
    Latitude: "-31.7909",
    Longitude: "27.703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1760",
    WardLookupId: "3018",
    VDNumber: "11080088",
    RegisteredPopulation: "1236",
    VotingStationName: "NOMADAMBA JUNIOR SECONDARY SCHOOL",
    Address: "NOMADAMBA LOCATION  NCORA A/A  COFIMVABA",
    Latitude: "-31.7145",
    Longitude: "27.7674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1761",
    WardLookupId: "3018",
    VDNumber: "11080291",
    RegisteredPopulation: "770",
    VotingStationName: "LOWER NCORA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  NONGQONGQWANA VILLAGE  COFIMVABA",
    Latitude: "-31.7576",
    Longitude: "27.7471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1762",
    WardLookupId: "3018",
    VDNumber: "11080572",
    RegisteredPopulation: "216",
    VotingStationName: "TALENI JUNIOR SECONDARY SCHOOL",
    Address: "N/A  BANTI VILLAGE(NCORA)DAM  COFIMVABA",
    Latitude: "-31.7782",
    Longitude: "27.6702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1763",
    WardLookupId: "3018",
    VDNumber: "11080594",
    RegisteredPopulation: "468",
    VotingStationName: "KULO NGQAYI JUNIOR SECONDARY SCHOOL",
    Address: "KULO-NGQAYI LOCATION  QUMANCO A/A  COFIMVABA",
    Latitude: "-31.773",
    Longitude: "27.8149",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1764",
    WardLookupId: "3019",
    VDNumber: "11080099",
    RegisteredPopulation: "493",
    VotingStationName: "MTINGWEVU CLINIC",
    Address: "KWA-GCINA LOCATION  MTINGWEVU A/A  COFIMVABA",
    Latitude: "-31.766",
    Longitude: "27.5893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1765",
    WardLookupId: "3019",
    VDNumber: "11080112",
    RegisteredPopulation: "716",
    VotingStationName: "MNQANQENI JUNIOR SECONDARY SCHOOL",
    Address: "UPPER NQUQU LOCATION   NQUQU A/A  COFIMVABA",
    Latitude: "-31.7905",
    Longitude: "27.564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1766",
    WardLookupId: "3019",
    VDNumber: "11080280",
    RegisteredPopulation: "534",
    VotingStationName: "MZIMVUBU JUNIOR SECONDARY SCHOOL",
    Address: "NONGQONGQWANA LOCATION  NQUQU A/A  COFIMVABA",
    Latitude: "-31.8177",
    Longitude: "27.6194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1767",
    WardLookupId: "3019",
    VDNumber: "11080437",
    RegisteredPopulation: "449",
    VotingStationName: "HEUKILE JUNIOR SECONDARY SCHOOL",
    Address: "HALA VILLAGE  MTIMGWEVU A/A  COFIMVABA",
    Latitude: "-31.7402",
    Longitude: "27.5967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1768",
    WardLookupId: "3019",
    VDNumber: "11080549",
    RegisteredPopulation: "646",
    VotingStationName: "NQUQU JUNIOR SECONDARY SCHOOL",
    Address: "DIKENI LOCATION  NQUQUA/A  COFIMVABA",
    Latitude: "-31.8072",
    Longitude: "27.5889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1769",
    WardLookupId: "3019",
    VDNumber: "11080561",
    RegisteredPopulation: "197",
    VotingStationName: "MPUNGA JUNIOR SECONDARY SCHOOL",
    Address: "NYAMANKULU LOCATION  NQUQU A/A  COFIMVABA",
    Latitude: "-31.7741",
    Longitude: "27.6233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1770",
    WardLookupId: "3019",
    VDNumber: "11080583",
    RegisteredPopulation: "156",
    VotingStationName: "MAHLUNGULU JUNIOR SECONDARY SCHOOL",
    Address: "MAHLUNGULU LOCATION  MTINGWEVU A/A  COFIMVABA",
    Latitude: "-31.729",
    Longitude: "27.6677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1771",
    WardLookupId: "3019",
    VDNumber: "11080796",
    RegisteredPopulation: "158",
    VotingStationName: "MTINGWEVU JUNIOR SECONDARY SCHOOL",
    Address: "MTINGWEVU MISSION LOCATION  MTINGWEVU A/A  COFIMVABA",
    Latitude: "-31.7469",
    Longitude: "27.6203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1772",
    WardLookupId: "3019",
    VDNumber: "11460080",
    RegisteredPopulation: "356",
    VotingStationName: "ZINGQUTU JUNIOR SECONDARY SCHOOL",
    Address: "NYALASA  NYALASA A/A  COFIMVABA",
    Latitude: "-31.7374",
    Longitude: "27.5173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1773",
    WardLookupId: "3020",
    VDNumber: "11080123",
    RegisteredPopulation: "431",
    VotingStationName: "SOUTHEYVILLE JUNIOR SECONDARY SCHOOL",
    Address: "DAYIMANI LOCATION  LUBISI A/A  COFIMVABA",
    Latitude: "-31.7992",
    Longitude: "27.4625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1774",
    WardLookupId: "3020",
    VDNumber: "11080134",
    RegisteredPopulation: "716",
    VotingStationName: "BANZINDLOVU JUNIOR SECONDARY SCHOOL",
    Address: "EQOLWENI VILLAGE  MCAMBALALENI A/A  COFIMVABA",
    Latitude: "-31.8808",
    Longitude: "27.491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1775",
    WardLookupId: "3020",
    VDNumber: "11080156",
    RegisteredPopulation: "256",
    VotingStationName: "TSAKANA JUNIOR SECONDARY SCHOOL",
    Address: "MANUNENI LOCATION  TSAKANA A/A  COFIMVABA",
    Latitude: "-31.8493",
    Longitude: "27.4668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1776",
    WardLookupId: "3020",
    VDNumber: "11080167",
    RegisteredPopulation: "663",
    VotingStationName: "DALUBUHLE JUNIOR SECONDARY SCHOOL",
    Address: "MTHWASHINI LOCATION  UPPER MNCUNCUZO A/A  COFIMVABA",
    Latitude: "-31.9167",
    Longitude: "27.5252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1777",
    WardLookupId: "3020",
    VDNumber: "11080460",
    RegisteredPopulation: "436",
    VotingStationName: "RWANTSANA JUNIOR SECONDARY SCHOOL",
    Address: "RWANTSANA LOCATION  RWANTSANA A/A  COFIMVABA",
    Latitude: "-31.753",
    Longitude: "27.4449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1778",
    WardLookupId: "3020",
    VDNumber: "11080808",
    RegisteredPopulation: "299",
    VotingStationName: "GUSI JUNIOR SECONDARY SCHOOL",
    Address: "GUSI LOCATION  TSAKANA A/A  COFIMVABA",
    Latitude: "-31.8256",
    Longitude: "27.4695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1779",
    WardLookupId: "3020",
    VDNumber: "11080819",
    RegisteredPopulation: "195",
    VotingStationName: "GCINA SENIOR PRIMARY SCHOOL",
    Address: "KWA-GCINA LOCATION  TSAKANA A/A  COFIMVABA",
    Latitude: "-31.8715",
    Longitude: "27.4489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1780",
    WardLookupId: "3020",
    VDNumber: "11080909",
    RegisteredPopulation: "444",
    VotingStationName: "UPPER NCUNCUZO JUNIOR SECONDARY SCHOOL",
    Address: "UPPER NCUNCUZO A/A  KAYAMNADI LOCATION  COFIMVABA",
    Latitude: "-31.9023",
    Longitude: "27.5479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1781",
    WardLookupId: "3020",
    VDNumber: "11460013",
    RegisteredPopulation: "408",
    VotingStationName: "LOWER SEPLAN JUNIOR SECONDARY SCHOOL",
    Address: "LOWER SEPLAN LOCATION  LOWER SEPLAN A/A  COFIMVABA",
    Latitude: "-31.7122",
    Longitude: "27.4417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1782",
    WardLookupId: "3020",
    VDNumber: "11460541",
    RegisteredPopulation: "258",
    VotingStationName: "LUZUKO JUNIOR SECONDARY SCHOOL",
    Address: "MHLONYANENI LOCATION  LOWER SEPLAN A/A  COFIMVABA",
    Latitude: "-31.7059",
    Longitude: "27.473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1783",
    WardLookupId: "3021",
    VDNumber: "11080101",
    RegisteredPopulation: "467",
    VotingStationName: "NXELESA COMMUNITY HALL",
    Address: "NXELESA LOCATION  MTSHANYANA A/A  COFIMVABA",
    Latitude: "-31.8477",
    Longitude: "27.6618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1784",
    WardLookupId: "3021",
    VDNumber: "11080279",
    RegisteredPopulation: "738",
    VotingStationName: "NGXABANGU JUNIOR SECONDARY SCHOOL",
    Address: "NGXABANGU LOCATION  MTSHANYANA A/A  COFIMVABA",
    Latitude: "-31.8478",
    Longitude: "27.6094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1785",
    WardLookupId: "3021",
    VDNumber: "11080314",
    RegisteredPopulation: "431",
    VotingStationName: "NGCACA JUNIOR SECONDARY SCHOOL",
    Address: "NGCACA LOCATION  NCORA A/A  COFIMVABA",
    Latitude: "-31.8339",
    Longitude: "27.7127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1786",
    WardLookupId: "3021",
    VDNumber: "11080527",
    RegisteredPopulation: "398",
    VotingStationName: "HOYANA JUNIOR SECONDARY SCHOOL",
    Address: "HOYANA LOCATION  MNCUNCUZO A/A  COFIMVABA",
    Latitude: "-31.8723",
    Longitude: "27.5675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1787",
    WardLookupId: "3021",
    VDNumber: "11080538",
    RegisteredPopulation: "547",
    VotingStationName: "MKULULI JUNIOR SECONDARY SCHOOL",
    Address: "CUBE LOCATION  MCAMBALALA A/A   COFIMVABA",
    Latitude: "-31.8574",
    Longitude: "27.5091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1788",
    WardLookupId: "3021",
    VDNumber: "11080550",
    RegisteredPopulation: "518",
    VotingStationName: "NOWETHU JUNIOR SECONDARY SCHOOL",
    Address: "N/A  MTSHANYANE VILLAGE  COFIMVABA",
    Latitude: "-31.8267",
    Longitude: "27.6657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1789",
    WardLookupId: "3021",
    VDNumber: "11080932",
    RegisteredPopulation: "305",
    VotingStationName: "KHULILE JUNIOR SECONDARY SCHOOL",
    Address: "EDIPHINI LOCATION  LOWER NCUNCUZO A/A  COFIMVABA",
    Latitude: "-31.8769",
    Longitude: "27.6132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1790",
    WardLookupId: "3022",
    VDNumber: "11070548",
    RegisteredPopulation: "435",
    VotingStationName: "NQININGANE JUNIOR SECONDARY SCHOOL",
    Address: "NQININGANA  NQININGANA VILL  LADY FRERE",
    Latitude: "-31.9057",
    Longitude: "27.1218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1791",
    WardLookupId: "3022",
    VDNumber: "11070559",
    RegisteredPopulation: "665",
    VotingStationName: "RODANA JUNIOR SECONDARY SCHOOL",
    Address: "RODANA  RODANA VILLAGE  LADY FRERE",
    Latitude: "-31.9266",
    Longitude: "27.2165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1792",
    WardLookupId: "3022",
    VDNumber: "11070560",
    RegisteredPopulation: "684",
    VotingStationName: "FEZEKILE JUNIOR SECONDARY SCHOOL",
    Address: "KUTSHATSHU VILLAGE  LADY FRERE",
    Latitude: "-31.8699",
    Longitude: "27.1954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1793",
    WardLookupId: "3022",
    VDNumber: "11070571",
    RegisteredPopulation: "588",
    VotingStationName: "RWANTSANA JUNIOR SECONDARY SCHOOL",
    Address: "LADY FRERE  RWANTSANA  LADY FRERE",
    Latitude: "-31.8812",
    Longitude: "27.2717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1794",
    WardLookupId: "3022",
    VDNumber: "11070818",
    RegisteredPopulation: "321",
    VotingStationName: "KOLONGA JUNIOR SECONDARY SCHOOL",
    Address: "NKOLONGA  LADY FRERE",
    Latitude: "-31.8323",
    Longitude: "27.2286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1795",
    WardLookupId: "3022",
    VDNumber: "11071011",
    RegisteredPopulation: "303",
    VotingStationName: "MPOTULO JUNIOR SECONDARY SCHOOL",
    Address: "MPOTULO VILLAGE  LADY FRERE",
    Latitude: "-31.9174",
    Longitude: "27.15",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1796",
    WardLookupId: "3022",
    VDNumber: "11071022",
    RegisteredPopulation: "153",
    VotingStationName: "TSOLOKAZI JUNIOR SECONDARY SCHOOL",
    Address: "TSOLOKAZI  TSOLOKAZI VILLAGE  LADY FRERE",
    Latitude: "-31.8981",
    Longitude: "27.1865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1797",
    WardLookupId: "3022",
    VDNumber: "11071066",
    RegisteredPopulation: "186",
    VotingStationName: "HLATIKHULU SENIOR PRIMARY SCHOOL",
    Address: "ESIKHWANKQENI  ESIKHWANKQENI VILLAGE  LADY FRERE",
    Latitude: "-31.8163",
    Longitude: "27.2238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1798",
    WardLookupId: "3022",
    VDNumber: "11071808",
    RegisteredPopulation: "204",
    VotingStationName: "MSOMBOVU PRE-SCHOOL",
    Address: "RWANTSANA VILLAGE  LADY FRERE",
    Latitude: "-31.9062",
    Longitude: "27.2734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1799",
    WardLookupId: "3022",
    VDNumber: "11071819",
    RegisteredPopulation: "229",
    VotingStationName: "AFRICAN MISSION CHURCH",
    Address: "QITHI VILLAGE  NKOLONGA A/A  LADY FRERE",
    Latitude: "-31.8454",
    Longitude: "27.2129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1800",
    WardLookupId: "3023",
    VDNumber: "11070122",
    RegisteredPopulation: "632",
    VotingStationName: "MAQASHU JUNIOR SECONDARY SCHOOL",
    Address: "MAQASHU  MAQASHU VILLAGE  LADY FRERE",
    Latitude: "-31.7403",
    Longitude: "27.3331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1801",
    WardLookupId: "3023",
    VDNumber: "11070447",
    RegisteredPopulation: "525",
    VotingStationName: "QUTHUBENI JUNIOR SECONDARY SCHOOL",
    Address: "BENGU  QUTHUBENI VILLAGE  LADY FRERE",
    Latitude: "-31.787",
    Longitude: "27.3525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1802",
    WardLookupId: "3023",
    VDNumber: "11070458",
    RegisteredPopulation: "334",
    VotingStationName: "THAFENI JUNIOR SECONDARY SCHOOL",
    Address: "THAFENI VILLAGE  BENGU  LADY FRERE",
    Latitude: "-31.8541",
    Longitude: "27.3242",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1803",
    WardLookupId: "3023",
    VDNumber: "11070469",
    RegisteredPopulation: "529",
    VotingStationName: "ST PETERS ON INDWE SCHOOL",
    Address: "EMALAHLENI   LANTI VILLAGE  LADY FRERE",
    Latitude: "-31.8783",
    Longitude: "27.3737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1804",
    WardLookupId: "3023",
    VDNumber: "11070784",
    RegisteredPopulation: "392",
    VotingStationName: "NJOMBELA JUNIOR SECONDARY SCHOOL",
    Address: "NJOMBELA VILLAGE, BENGU A/A  LADY FRERE",
    Latitude: "-31.7719",
    Longitude: "27.3927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1805",
    WardLookupId: "3023",
    VDNumber: "11070953",
    RegisteredPopulation: "322",
    VotingStationName: "LUTUTU JUNIOR SECONDARY SCHOOL",
    Address: "LUTHUTHU VILLAGE, BENGU A/A  LADY FRERE",
    Latitude: "-31.8193",
    Longitude: "27.2829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1806",
    WardLookupId: "3023",
    VDNumber: "11070964",
    RegisteredPopulation: "142",
    VotingStationName: "LUQOLO JUNIOR SECONDARY SCHOOL",
    Address: "LUQOLO VILLAGE  LADY FRERE",
    Latitude: "-31.8454",
    Longitude: "27.4183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1807",
    WardLookupId: "3023",
    VDNumber: "11071257",
    RegisteredPopulation: "272",
    VotingStationName: "NOZUKO PRIMARY SCHOOL",
    Address: "LIGWA VILLAGE / BENGU  LADY FRERE",
    Latitude: "-31.7925",
    Longitude: "27.3378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1808",
    WardLookupId: "3023",
    VDNumber: "11071763",
    RegisteredPopulation: "289",
    VotingStationName: "KWAZOTHE TENT",
    Address: "ESQITINI  ESQITHINI VILLAGE, BENGU  EMALAHLENI [LADY FRERE]",
    Latitude: "-31.8032",
    Longitude: "27.4068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1809",
    WardLookupId: "3024",
    VDNumber: "11070076",
    RegisteredPopulation: "462",
    VotingStationName: "STONICROFT JUNIOR SECONDARY SCHOOL",
    Address: "TRUST VILLAGE, NDONGA A/A  LADY FRERE",
    Latitude: "-31.6831",
    Longitude: "27.3176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1810",
    WardLookupId: "3024",
    VDNumber: "11070144",
    RegisteredPopulation: "564",
    VotingStationName: "LOWER NDONGA SCHOOL",
    Address: "LOWER NDONGA VILLAGE,  LADY FRERE",
    Latitude: "-31.6893",
    Longitude: "27.4204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1811",
    WardLookupId: "3024",
    VDNumber: "11070313",
    RegisteredPopulation: "491",
    VotingStationName: "NTLALONTLE JUNIOR SECONDARY SCHOOL",
    Address: "ENTLALONTLE VILLAGE, NDONGA FARMS  LADY FRERE",
    Latitude: "-31.6551",
    Longitude: "27.3945",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1812",
    WardLookupId: "3024",
    VDNumber: "11070324",
    RegisteredPopulation: "121",
    VotingStationName: "TEMBANI SENIOR PRIMARY SCHOOL",
    Address: "LAMOEN VILLAGE, NDONGA FARMS  LADY FRERE",
    Latitude: "-31.6132",
    Longitude: "27.4212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1813",
    WardLookupId: "3024",
    VDNumber: "11070706",
    RegisteredPopulation: "445",
    VotingStationName: "MAQHUBELA JUNIOR SECONDARY SCHOOL",
    Address: "KUMAQHUBELA VILLAGE, NDONGA FARMS  LADY FRERE",
    Latitude: "-31.6569",
    Longitude: "27.3205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1814",
    WardLookupId: "3024",
    VDNumber: "11070717",
    RegisteredPopulation: "439",
    VotingStationName: "GREYSPAN JUNIOR SECONDARY SCHOOL",
    Address: "GREYSPAN VILLAGE, NDONGA FARMS  LADY FRERE",
    Latitude: "-31.6628",
    Longitude: "27.373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1815",
    WardLookupId: "3024",
    VDNumber: "11070728",
    RegisteredPopulation: "377",
    VotingStationName: "TABATA JUNIOR SECONDARY SCHOOL",
    Address: "HALA 1 VILLAGE, NDONGA FARMS  LADY FRERE",
    Latitude: "-31.6394",
    Longitude: "27.3626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1816",
    WardLookupId: "3024",
    VDNumber: "11070739",
    RegisteredPopulation: "328",
    VotingStationName: "RV MANELI JUNIOR SECONDARY SCHOOL",
    Address: "HALA 2, NDONGA FARMS  LADY FRERE",
    Latitude: "-31.6238",
    Longitude: "27.3867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1817",
    WardLookupId: "3024",
    VDNumber: "11070830",
    RegisteredPopulation: "471",
    VotingStationName: "NOMPUMELELO JUNIOR SECONDARY SCHOOL",
    Address: "PERCY VILLAGE  LADY FRERE",
    Latitude: "-31.6978",
    Longitude: "27.2849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1818",
    WardLookupId: "3024",
    VDNumber: "11071280",
    RegisteredPopulation: "302",
    VotingStationName: "MADWALENI  JUNIOR SECONDARY SCHOOL",
    Address: "MADWALENI/ HALA NO2  LADY FRERE",
    Latitude: "-31.622",
    Longitude: "27.3949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1819",
    WardLookupId: "3025",
    VDNumber: "11000013",
    RegisteredPopulation: "908",
    VotingStationName: "NOMPUCUKO SENIOR PRIMARY SCHOOL",
    Address: "LADY FRERE LOCATION  LADY FRERE TOWNSHIP  LADY FRERE",
    Latitude: "-31.6914",
    Longitude: "27.2399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1820",
    WardLookupId: "3025",
    VDNumber: "11000024",
    RegisteredPopulation: "1294",
    VotingStationName: "LADY FRERE TOWN HALL",
    Address: "55 KUMKANIKAZI  LADY FRERE TOWN  LADY FRERE",
    Latitude: "-31.7029",
    Longitude: "27.2325",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1821",
    WardLookupId: "3025",
    VDNumber: "11070436",
    RegisteredPopulation: "420",
    VotingStationName: "BAKANINI SENIOR PRIMARY SCHOOL",
    Address: "CACADU  LADY FRERE",
    Latitude: "-31.7447",
    Longitude: "27.2109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1822",
    WardLookupId: "3025",
    VDNumber: "11070942",
    RegisteredPopulation: "535",
    VotingStationName: "CACADU SENIOR SECONDARY SCHOOL",
    Address: "NKONDOLO VILL, CACADU  LADY FRERE",
    Latitude: "-31.7278",
    Longitude: "27.1988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1823",
    WardLookupId: "3025",
    VDNumber: "11071213",
    RegisteredPopulation: "508",
    VotingStationName: "LADY FRERE STADIUM.",
    Address: "CACADU EXTENTION  LADY FRERE",
    Latitude: "-31.7064",
    Longitude: "27.2216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1824",
    WardLookupId: "3026",
    VDNumber: "11070177",
    RegisteredPopulation: "389",
    VotingStationName: "EMZI JUNIOR SECONDARY SCHOOL",
    Address: "MCKAYS A/A  CACADU  LADY FRERE",
    Latitude: "-31.7833",
    Longitude: "27.127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1825",
    WardLookupId: "3026",
    VDNumber: "11070403",
    RegisteredPopulation: "610",
    VotingStationName: "NGANGOMHLABA JUNIOR SECONDARY SCHOOL",
    Address: "CUMAKALA VILLAGE  LADY FRERE",
    Latitude: "-31.6644",
    Longitude: "27.2512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1826",
    WardLookupId: "3026",
    VDNumber: "11070616",
    RegisteredPopulation: "603",
    VotingStationName: "NOLUTHANDO SCHOOL",
    Address: "GLEN ADELAIDE  CACADU  LADY FRERE",
    Latitude: "-31.7169",
    Longitude: "27.1399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1827",
    WardLookupId: "3026",
    VDNumber: "11070627",
    RegisteredPopulation: "495",
    VotingStationName: "GCINA TRIBAL AUTHORITY HALL",
    Address: "GLEN ADELAIDE  CACADU  LADY FRERE",
    Latitude: "-31.7346",
    Longitude: "27.1694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1828",
    WardLookupId: "3026",
    VDNumber: "11071099",
    RegisteredPopulation: "381",
    VotingStationName: "MCKAY`S NEK JUNIOR SECONDARY SCHOOL",
    Address: "MCKAY`S NEK VILLAGE  LADY FRERE",
    Latitude: "-31.7654",
    Longitude: "27.1089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1829",
    WardLookupId: "3026",
    VDNumber: "11071190",
    RegisteredPopulation: "375",
    VotingStationName: "THREE CROWNS SENIOR PRIMARY SCHOOL",
    Address: "THREE CROWNS VILLAGE  KUKHAVALA VILLAGE,  LADY FRERE",
    Latitude: "-31.7502",
    Longitude: "27.1118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1830",
    WardLookupId: "3026",
    VDNumber: "11071202",
    RegisteredPopulation: "234",
    VotingStationName: "DE HOOP SCHOOL",
    Address: "MTSHEKO ADMIN AREA  DOPU VILLAGE  LADY FRERE",
    Latitude: "-31.7208",
    Longitude: "27.1673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1831",
    WardLookupId: "3026",
    VDNumber: "11071224",
    RegisteredPopulation: "283",
    VotingStationName: "GLEN ADELAIDE JUNIOR SECONDARY SCHOOL",
    Address: "GLEN ADELAIDE VILLAGE  LADY FRERE",
    Latitude: "-31.7329",
    Longitude: "27.1494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1832",
    WardLookupId: "3026",
    VDNumber: "11071347",
    RegisteredPopulation: "516",
    VotingStationName: "YONNA JUNIOR SECONDARY SCHOOL",
    Address: "JOJWENI VILL, CUMAKALA ADMIN AREA  LADY FRERE",
    Latitude: "-31.6534",
    Longitude: "27.2408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1833",
    WardLookupId: "3026",
    VDNumber: "11071796",
    RegisteredPopulation: "229",
    VotingStationName: "TOPU TENT SITE",
    Address: "TOPU  TOPU VILL  NQANDA A/A",
    Latitude: "-31.6898",
    Longitude: "27.2218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1834",
    WardLookupId: "3027",
    VDNumber: "11070065",
    RegisteredPopulation: "317",
    VotingStationName: "TEMBENI SENIOR PRIMARY SCHOOL",
    Address: "SMALL FARM VILLAGE  LADY FRERE",
    Latitude: "-31.8084",
    Longitude: "26.9894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1835",
    WardLookupId: "3027",
    VDNumber: "11070245",
    RegisteredPopulation: "349",
    VotingStationName: "XONXA JUNIOR SECONDARY SCHOOL",
    Address: "XONXA VILLAGE  LADY FRERE",
    Latitude: "-31.8366",
    Longitude: "27.101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1836",
    WardLookupId: "3027",
    VDNumber: "11070582",
    RegisteredPopulation: "571",
    VotingStationName: "MATYANTYA JUNIOR SECONDARY SCHOOL",
    Address: "MATYANTYA  CACADU  LADY FRERE",
    Latitude: "-31.7836",
    Longitude: "27.0724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1837",
    WardLookupId: "3027",
    VDNumber: "11070593",
    RegisteredPopulation: "307",
    VotingStationName: "VULINDLELA SENIOR SECONDARY SCHOOL",
    Address: "MACKAY`S NEK VILLAGE  LADY FRERE",
    Latitude: "-31.7935",
    Longitude: "27.1076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1838",
    WardLookupId: "3027",
    VDNumber: "11070740",
    RegisteredPopulation: "363",
    VotingStationName: "EMAZIMENI JUNIOR SECONDARY SCHOOL",
    Address: "GQEBENYA  CACADU  LADY FRERE",
    Latitude: "-31.8358",
    Longitude: "27.0282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1839",
    WardLookupId: "3027",
    VDNumber: "11070863",
    RegisteredPopulation: "326",
    VotingStationName: "MTIRARA SENIOR SECONDARY SCHOOL",
    Address: "MXHIKI VILLAGE, MATYANTYA A/A  LADY FRERE",
    Latitude: "-31.7979",
    Longitude: "27.0622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1840",
    WardLookupId: "3027",
    VDNumber: "11070874",
    RegisteredPopulation: "340",
    VotingStationName: "KUNDULU JUNIOR SECONDARY SCHOOL",
    Address: "KUNDULU VILLAGE,  LADY FRERE",
    Latitude: "-31.8516",
    Longitude: "27.0574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1841",
    WardLookupId: "3027",
    VDNumber: "11071077",
    RegisteredPopulation: "659",
    VotingStationName: "GQEBENYA JUNIOR SECONDARY SCHOOL",
    Address: "GQEBENYA  GQEBENYA VILLAGE  LADY FRERE",
    Latitude: "-31.8201",
    Longitude: "27.0076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1842",
    WardLookupId: "3027",
    VDNumber: "11071088",
    RegisteredPopulation: "176",
    VotingStationName: "GANDO SHEARING SHED",
    Address: "GANDO VILLAGE  KHUNDULU A/A  LADY FRERE",
    Latitude: "-31.8648",
    Longitude: "27.0609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1843",
    WardLookupId: "3027",
    VDNumber: "11071178",
    RegisteredPopulation: "234",
    VotingStationName: "NONESI SENIOR PRIMARY SCHOOL",
    Address: "KOROMANA VILLAGE  LADY FRERE",
    Latitude: "-31.7628",
    Longitude: "27.0582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1844",
    WardLookupId: "3027",
    VDNumber: "11071730",
    RegisteredPopulation: "243",
    VotingStationName: "ROMAN CATHOLIC CHURCH HALL",
    Address: "XONXA VILLAGE (ROMA)  EMALAHLENI [LADY FRERE]",
    Latitude: "-31.8487",
    Longitude: "27.0897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1845",
    WardLookupId: "3028",
    VDNumber: "11070199",
    RegisteredPopulation: "614",
    VotingStationName: "MAQWATHINI JUNIOR SECONDARY SCHOOL",
    Address: "NTAKANA VILLAGE, QOQODALA A/A  LADY FRERE",
    Latitude: "-31.716",
    Longitude: "26.9712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1846",
    WardLookupId: "3028",
    VDNumber: "11070302",
    RegisteredPopulation: "309",
    VotingStationName: "MNIKINA JUNIOR SECONDARY SCHOOL",
    Address: "QOQODALA A/A  CACADU  LADY FRERE",
    Latitude: "-31.7135",
    Longitude: "26.9189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1847",
    WardLookupId: "3028",
    VDNumber: "11070605",
    RegisteredPopulation: "261",
    VotingStationName: "QOQODALA JUNIOR SECONDARY SCHOOL",
    Address: "LUXENI VILLAGE  EMALAHLENI [LADY FRERE]",
    Latitude: "-31.752",
    Longitude: "26.965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1848",
    WardLookupId: "3028",
    VDNumber: "11070638",
    RegisteredPopulation: "650",
    VotingStationName: "QOQODALA COMMUNITY HALL",
    Address: "EMAYALULWENI VILLAGE, QOQODALA A/A  LADY FRERE",
    Latitude: "-31.7286",
    Longitude: "26.954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1849",
    WardLookupId: "3028",
    VDNumber: "11070896",
    RegisteredPopulation: "249",
    VotingStationName: "BOWDEN JUNIOR SECONDARY SCHOOL",
    Address: "BOWDEN VILLAGE  LADY FRERE",
    Latitude: "-31.7515",
    Longitude: "26.9378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1850",
    WardLookupId: "3028",
    VDNumber: "11071101",
    RegisteredPopulation: "170",
    VotingStationName: "FUNDA SENIOR PRIMARY SCHOOL",
    Address: "UPPER NGONYAMA VILLAGE  LADY FRERE",
    Latitude: "-31.6971",
    Longitude: "26.926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1851",
    WardLookupId: "3028",
    VDNumber: "11071112",
    RegisteredPopulation: "205",
    VotingStationName: "AFRICAN NATIVE CHURCH",
    Address: "LALINI VIL  EMALAHLENI [LADY FRERE]",
    Latitude: "-31.7219",
    Longitude: "26.9025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1852",
    WardLookupId: "3028",
    VDNumber: "11071123",
    RegisteredPopulation: "216",
    VotingStationName: "PHAKAMISA SENIOR PRIMARY SCHOOL",
    Address: "EMAYIRHENI VILLAGE, QOQODALA A/A  LADY FRERE",
    Latitude: "-31.7421",
    Longitude: "26.9279",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1853",
    WardLookupId: "3028",
    VDNumber: "11071134",
    RegisteredPopulation: "171",
    VotingStationName: "VUYOLWETHU SENIOR PRIMARY SCHOOL",
    Address: "EMAZONGOZINI VILLAGE  LADY FRERE",
    Latitude: "-31.7351",
    Longitude: "26.9926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1854",
    WardLookupId: "3028",
    VDNumber: "11071156",
    RegisteredPopulation: "271",
    VotingStationName: "EDENDALE JUNIOR SECONDARY SCHOOL",
    Address: "LUTUTU VILLAGE, KUDUBENI  LADY FRERE",
    Latitude: "-31.7472",
    Longitude: "27.0176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1855",
    WardLookupId: "3028",
    VDNumber: "11071167",
    RegisteredPopulation: "524",
    VotingStationName: "BOZWANA JUNIOR SECONDARY SCHOOL",
    Address: "KUBOZWANA A/A  LADY FRERE",
    Latitude: "-31.7712",
    Longitude: "27.0324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1856",
    WardLookupId: "3028",
    VDNumber: "11071741",
    RegisteredPopulation: "158",
    VotingStationName: "PHUMELELA SENIOR SECONDARY SCHOOL",
    Address: "QOQODALA A/A  BLANGWE VILLAGE, QOQODALA A/A  LADY FRERE",
    Latitude: "-31.7558",
    Longitude: "26.9947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1857",
    WardLookupId: "3029",
    VDNumber: "11070133",
    RegisteredPopulation: "392",
    VotingStationName: "QHUGQWARHU JUNIOR SECONDARY SCHOOL",
    Address: "QHUGQWARHU VILLAGE, LOWER VAALBANK  LADY FRERE",
    Latitude: "-31.615",
    Longitude: "26.9663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1858",
    WardLookupId: "3029",
    VDNumber: "11070188",
    RegisteredPopulation: "435",
    VotingStationName: "NTSONKOTHA SENIOR SECONDARY SCHOOL",
    Address: "MGQUKWEBE A/A  CACADU  LADY FRERE",
    Latitude: "-31.6966",
    Longitude: "27.0323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1859",
    WardLookupId: "3029",
    VDNumber: "11070212",
    RegisteredPopulation: "330",
    VotingStationName: "BHOQO JUNIOR SECONDARY SCHOOL",
    Address: "BHOQO VILLAGE, LOWER VAALBANK  LADY FRERE",
    Latitude: "-31.6382",
    Longitude: "26.9826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1860",
    WardLookupId: "3029",
    VDNumber: "11070256",
    RegisteredPopulation: "439",
    VotingStationName: "MZAMOMHLE JOJO JUNIOR SECONDARY SCHOOL",
    Address: "JOJWENI VILLAGE  LADY FRERE",
    Latitude: "-31.6492",
    Longitude: "27.0593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1861",
    WardLookupId: "3029",
    VDNumber: "11070649",
    RegisteredPopulation: "460",
    VotingStationName: "UPPER NGONYAMA PRIMARY SCHOOL",
    Address: "NGONYAMA A/A  CACADU  LADY FRERE",
    Latitude: "-31.6745",
    Longitude: "26.934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1862",
    WardLookupId: "3029",
    VDNumber: "11070807",
    RegisteredPopulation: "487",
    VotingStationName: "ETHIOPIAN C.C. OF SOUTH AFRICA",
    Address: "EJEKENI VILLAGE, MGQHUKHWEBE  LADY FRERE",
    Latitude: "-31.6855",
    Longitude: "27.0531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1863",
    WardLookupId: "3029",
    VDNumber: "11071369",
    RegisteredPopulation: "164",
    VotingStationName: "VAALBANK COMMMUNITY HALL",
    Address: "VAALBANK  LADY FRERE",
    Latitude: "-31.6058",
    Longitude: "26.9365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1864",
    WardLookupId: "3029",
    VDNumber: "11071381",
    RegisteredPopulation: "170",
    VotingStationName: "SIYAKONWABA SENIOR PRIMARY SCHOOL",
    Address: "XUSHA VILLAGE, LOWER VAALBANK  LADY FRERE",
    Latitude: "-31.6305",
    Longitude: "26.9372",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1865",
    WardLookupId: "3029",
    VDNumber: "11071392",
    RegisteredPopulation: "198",
    VotingStationName: "IMIZAMOYETHU PRE-SCHOOL",
    Address: "BOQO VILLAGE  LADY FRERE",
    Latitude: "-31.6526",
    Longitude: "26.9639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1866",
    WardLookupId: "3029",
    VDNumber: "11071448",
    RegisteredPopulation: "193",
    VotingStationName: "SINOXOLO SHEARING SHED.",
    Address: "BHOQO  VILLAGE  LADY FRERE",
    Latitude: "-31.6406",
    Longitude: "26.9987",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1867",
    WardLookupId: "3029",
    VDNumber: "11071459",
    RegisteredPopulation: "381",
    VotingStationName: "IKAMVA JUNIOR PRIMARY SCHOOL",
    Address: "AGNES REST  MGQUKHWEBE VILLAGE  LADY FRERE",
    Latitude: "-31.6949",
    Longitude: "27.0132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1868",
    WardLookupId: "3029",
    VDNumber: "11071471",
    RegisteredPopulation: "223",
    VotingStationName: "ZWELETHEMBA PRE-SCHOOL",
    Address: "ZWELETHEMBA VILLAGE, JOJWENI  LADY FRERE",
    Latitude: "-31.6566",
    Longitude: "27.0671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1869",
    WardLookupId: "3030",
    VDNumber: "11070289",
    RegisteredPopulation: "403",
    VotingStationName: "PHUMLANI SENIOR SECONDARY SCHOOL",
    Address: "VAAL BANK  CACADU  LADY FRERE",
    Latitude: "-31.611",
    Longitude: "26.9235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1870",
    WardLookupId: "3030",
    VDNumber: "11070290",
    RegisteredPopulation: "660",
    VotingStationName: "VAAL BANK JUNIOR SECONDARY SCHOOL",
    Address: "EZINGCACENI VILLAGE  LADY FRERE",
    Latitude: "-31.6478",
    Longitude: "26.9096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1871",
    WardLookupId: "3030",
    VDNumber: "11070335",
    RegisteredPopulation: "454",
    VotingStationName: "DUM DUM SENIOR PRIMARY SCHOOL",
    Address: "DUM-DUM VILLAGE, ZWARTWATER A/A  LADY FRERE",
    Latitude: "-31.6768",
    Longitude: "26.8105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1872",
    WardLookupId: "3030",
    VDNumber: "11070908",
    RegisteredPopulation: "351",
    VotingStationName: "NOZALA JUNIOR SECONDARY SCHOOL",
    Address: "NOZALA  TABASA VILLAGE, ZWARTWATER  LADY FRERE",
    Latitude: "-31.646",
    Longitude: "26.8345",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1873",
    WardLookupId: "3030",
    VDNumber: "11071404",
    RegisteredPopulation: "171",
    VotingStationName: "BHADI PRE-SCHOOL",
    Address: "KUBHADI VILLAGE, UPPER NGONYAMA  LADY FRERE",
    Latitude: "-31.6745",
    Longitude: "26.9147",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1874",
    WardLookupId: "3030",
    VDNumber: "11071482",
    RegisteredPopulation: "305",
    VotingStationName: "ESIDWADWENI JUNIOR SECONDARY SCHOOL",
    Address: "ZWARTWATER VILLAGE  LADY FRERE",
    Latitude: "-31.6227",
    Longitude: "26.8442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1875",
    WardLookupId: "3030",
    VDNumber: "11071505",
    RegisteredPopulation: "554",
    VotingStationName: "THOZAMISA HIGH SCHOOL",
    Address: "GCIBHALA VILLAGE, ZWARTWATER VILLAGE  LADY FRERE",
    Latitude: "-31.663",
    Longitude: "26.8323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1876",
    WardLookupId: "3030",
    VDNumber: "11071561",
    RegisteredPopulation: "292",
    VotingStationName: "SIZAMILE SENIOR PRIMARY SCHOOL",
    Address: "KWAGCINA VILLAGE, BUFFELS DORINGS  LADY FRERE",
    Latitude: "-31.596",
    Longitude: "26.9108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1877",
    WardLookupId: "3030",
    VDNumber: "11071583",
    RegisteredPopulation: "274",
    VotingStationName: "DALIBUZWE SENIOR PRIMARY SCHOOL",
    Address: "MANDLANENI VILLAGE  LADY FRERE",
    Latitude: "-31.6144",
    Longitude: "26.9048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1878",
    WardLookupId: "3030",
    VDNumber: "11071594",
    RegisteredPopulation: "130",
    VotingStationName: "LAPHUMILANGA SENIOR PRIMARY SCHOOL",
    Address: "VAALBANK VILLAGE  VILLAGE  LADY FRERE",
    Latitude: "-31.6414",
    Longitude: "26.8895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1879",
    WardLookupId: "3030",
    VDNumber: "11071774",
    RegisteredPopulation: "326",
    VotingStationName: "EMDENIJUNIOR SECONDARY SCHOOL",
    Address: "EMDENI  EMDENI VILLAGE, ZWARTWATER  LADY FRERE",
    Latitude: "-31.6888",
    Longitude: "26.7905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1880",
    WardLookupId: "3031",
    VDNumber: "11070010",
    RegisteredPopulation: "268",
    VotingStationName: "TSEMBEYI JUNIOR SECONDARY SCHOOL",
    Address: "TSEMBEYI ADMIN AREA  LADY FRERE",
    Latitude: "-31.588",
    Longitude: "27.0228",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1881",
    WardLookupId: "3031",
    VDNumber: "11070032",
    RegisteredPopulation: "225",
    VotingStationName: "KOMANAS JUIOR SECONDARY SCHOOL",
    Address: "KOMANAS  TSEMBEYI ADMIN AREA  LADY FRERE",
    Latitude: "-31.5677",
    Longitude: "26.9756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1882",
    WardLookupId: "3031",
    VDNumber: "11070155",
    RegisteredPopulation: "552",
    VotingStationName: "BHANKISI JUNIOR SECONDARY SCHOOL",
    Address: "BHANKISI A/A  LADY FRERE",
    Latitude: "-31.546",
    Longitude: "26.9572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1883",
    WardLookupId: "3031",
    VDNumber: "11070166",
    RegisteredPopulation: "450",
    VotingStationName: "ZAMICEBO JUNIOR PRIMARY SCHOOL",
    Address: "TSEMBEYI A/A  LADY FRERE",
    Latitude: "-31.5826",
    Longitude: "27.0036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1884",
    WardLookupId: "3031",
    VDNumber: "11070852",
    RegisteredPopulation: "372",
    VotingStationName: "MCWANGELE JUNIOR SECONDARY SCHOOL",
    Address: "MCWANGELE  CACADU  LADY FRERE",
    Latitude: "-31.5757",
    Longitude: "26.9177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1885",
    WardLookupId: "3031",
    VDNumber: "11071415",
    RegisteredPopulation: "374",
    VotingStationName: "NDIMANGENI JUNIOR SECONDARY SCHOOL",
    Address: "NDIMANGENI VILLAGE  LADY FRERE",
    Latitude: "-31.6086",
    Longitude: "27.0077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1886",
    WardLookupId: "3031",
    VDNumber: "11071426",
    RegisteredPopulation: "82",
    VotingStationName: "NYINGA TENT",
    Address: "NYINGA VILLAGE  NYINGA  LADY FRERE",
    Latitude: "-31.6332",
    Longitude: "27.013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1887",
    WardLookupId: "3031",
    VDNumber: "11071437",
    RegisteredPopulation: "331",
    VotingStationName: "TSAWULAYO SENIOR PRIMARY SCHOOL",
    Address: "TSEMBEYI VILLAGE  LADY FRERE",
    Latitude: "-31.5741",
    Longitude: "27.0349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1888",
    WardLookupId: "3031",
    VDNumber: "11071549",
    RegisteredPopulation: "330",
    VotingStationName: "ZIZAMELE SENIOR PRIMARY SCHOOL",
    Address: "EZISOYINI VILLAGE  TSEMBEYI ADMIN AREA  EMALAHLENI",
    Latitude: "-31.5854",
    Longitude: "26.9742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1889",
    WardLookupId: "3031",
    VDNumber: "11071550",
    RegisteredPopulation: "247",
    VotingStationName: "HELUSHE JUNIOR PRIMARY SCHOOL",
    Address: "BUFFELSDORINGS A/A  EMALAHLENI [LADY FRERE]",
    Latitude: "-31.5795",
    Longitude: "26.9468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1890",
    WardLookupId: "3031",
    VDNumber: "11450023",
    RegisteredPopulation: "59",
    VotingStationName: "WILLOW PARK FARM BUILDING",
    Address: ".  WILLOWPARK  FARM  DORDRECHT",
    Latitude: "-31.5022",
    Longitude: "26.9448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1891",
    WardLookupId: "3032",
    VDNumber: "11240020",
    RegisteredPopulation: "618",
    VotingStationName: "MIKE HUNA HALL",
    Address: "SINAKO  SINAKHO  DORDRECHT",
    Latitude: "-31.3804",
    Longitude: "27.0357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1892",
    WardLookupId: "3032",
    VDNumber: "11240031",
    RegisteredPopulation: "768",
    VotingStationName: "MUNNIKVILLE HALL",
    Address: "PROTEA STREET  MUNNIKVILLE  DORDRECHT",
    Latitude: "-31.3764",
    Longitude: "27.0322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1893",
    WardLookupId: "3032",
    VDNumber: "11240042",
    RegisteredPopulation: "1089",
    VotingStationName: "HARRY GWALA PRE - SCHOOL",
    Address: ".  DORDRECHT TOWNSHIP  DORDRECHT TOWN",
    Latitude: "-31.3881",
    Longitude: "27.0337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1894",
    WardLookupId: "3032",
    VDNumber: "11240053",
    RegisteredPopulation: "1176",
    VotingStationName: "HARRY GWALA HALL",
    Address: "HARRY GWALA  DORDRECHT",
    Latitude: "-31.389",
    Longitude: "27.0373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1895",
    WardLookupId: "3033",
    VDNumber: "11070201",
    RegisteredPopulation: "395",
    VotingStationName: "MOUNT ARTHUR JUNIOR SECONDARY SCHOOL",
    Address: "BANGINDLALA VILLAGE  LADY FRERE",
    Latitude: "-31.6883",
    Longitude: "27.1494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1896",
    WardLookupId: "3033",
    VDNumber: "11070267",
    RegisteredPopulation: "445",
    VotingStationName: "MKAPUSI SENOIR SECONDARY SCHOOL",
    Address: "QWEMPE VILLAGE  MKAPUSI A/A  LADY FRERE",
    Latitude: "-31.6133",
    Longitude: "27.1431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1897",
    WardLookupId: "3033",
    VDNumber: "11070278",
    RegisteredPopulation: "280",
    VotingStationName: "BOMENI JUNIOR SECONDARY SCHOOL",
    Address: "BOMENI VILLAGE, NGQANDA  LADY FRERE",
    Latitude: "-31.6489",
    Longitude: "27.1838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1898",
    WardLookupId: "3033",
    VDNumber: "11070650",
    RegisteredPopulation: "485",
    VotingStationName: "LUXA JUNIOR SECONDARY SCHOOL",
    Address: "ELUXENI VILLAGE, MKHAPHUSI  LADY FRERE",
    Latitude: "-31.572",
    Longitude: "27.1042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1899",
    WardLookupId: "3033",
    VDNumber: "11070661",
    RegisteredPopulation: "210",
    VotingStationName: "MKAPUSI JUNIOR SECONDARY SCHOOL",
    Address: "MKAPUSI  LADY FRERE  LADY FRERE",
    Latitude: "-31.5953",
    Longitude: "27.1067",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1900",
    WardLookupId: "3033",
    VDNumber: "11070885",
    RegisteredPopulation: "336",
    VotingStationName: "NGQANDA JUNIOR SECONDARY SCHOOL",
    Address: "NGQANDA A/A  LADY FRERE",
    Latitude: "-31.6721",
    Longitude: "27.1991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1901",
    WardLookupId: "3033",
    VDNumber: "11071303",
    RegisteredPopulation: "407",
    VotingStationName: "EMTUNZINI JUNIOR PRIMARY SCHOOL",
    Address: "MTHUNZINI / MKAPUSI VILLAGE  LADY FRERE",
    Latitude: "-31.5962",
    Longitude: "27.0952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1902",
    WardLookupId: "3033",
    VDNumber: "11071314",
    RegisteredPopulation: "137",
    VotingStationName: "NGXINGWENI TENT",
    Address: "NGXINGWENI VILLAGE  NGXINGWENI VILLAGE, MKAPHUSI  LADY FRERE",
    Latitude: "-31.6221",
    Longitude: "27.1314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1903",
    WardLookupId: "3033",
    VDNumber: "11071325",
    RegisteredPopulation: "302",
    VotingStationName: "NCALUKENI JUNIOR PRIMARY SCHOOL",
    Address: "NCALUKENI VILLAGE, NGQANDA  LADY FRERE",
    Latitude: "-31.6558",
    Longitude: "27.1764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1904",
    WardLookupId: "3033",
    VDNumber: "11071336",
    RegisteredPopulation: "166",
    VotingStationName: "LITTLE FIRE PRE-SCHOOL",
    Address: "NGQANDA VILLAGE  LADY FRERE",
    Latitude: "-31.6546",
    Longitude: "27.1982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1905",
    WardLookupId: "3033",
    VDNumber: "11071752",
    RegisteredPopulation: "339",
    VotingStationName: "ZUBAS DALE SENIOR PRIMARY SCHOOL",
    Address: "KWAHALA VILLAGE, KUBANGINDLALA  LADY FRERE",
    Latitude: "-31.6751",
    Longitude: "27.162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1906",
    WardLookupId: "3034",
    VDNumber: "11070234",
    RegisteredPopulation: "284",
    VotingStationName: "PLATKOP SCHOOL",
    Address: "MACHUBENI  CACADU  LADY FRERE",
    Latitude: "-31.5516",
    Longitude: "27.2308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1907",
    WardLookupId: "3034",
    VDNumber: "11070368",
    RegisteredPopulation: "325",
    VotingStationName: "NOKWAZI JUNIOR SECONDARY SCHOOL",
    Address: "QOBOSHANI  QOBOSHANI VILLAGE  LADY FRERE",
    Latitude: "-31.4973",
    Longitude: "27.1853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1908",
    WardLookupId: "3034",
    VDNumber: "11070391",
    RegisteredPopulation: "269",
    VotingStationName: "ST CEPRIANS SCHOOL",
    Address: "MACHUBENI  MACHUBENI VILLAGE  LADY FRERE",
    Latitude: "-31.5654",
    Longitude: "27.17",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1909",
    WardLookupId: "3034",
    VDNumber: "11070672",
    RegisteredPopulation: "279",
    VotingStationName: "DYOBUDAKA JUNIOR SECONDARY SCHOOL",
    Address: "MKAPUSI ( DYOBUDAKA)  MKAPUSI A/A  LADY FRERE",
    Latitude: "-31.6067",
    Longitude: "27.167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1910",
    WardLookupId: "3034",
    VDNumber: "11070694",
    RegisteredPopulation: "371",
    VotingStationName: "NONKQUBELA SENIOR SECONDARY SCHOOL",
    Address: "MACHUBENI  CACADU  LADY FRERE",
    Latitude: "-31.5525",
    Longitude: "27.1888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1911",
    WardLookupId: "3034",
    VDNumber: "11070931",
    RegisteredPopulation: "395",
    VotingStationName: "JAHOS GLEN JUNIOR SECONDARY SCHOOL",
    Address: "MHLANGA A/A  LADY  FRERE",
    Latitude: "-31.516",
    Longitude: "27.1511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1912",
    WardLookupId: "3034",
    VDNumber: "11071640",
    RegisteredPopulation: "293",
    VotingStationName: "BOOMPLAAS COMMUNITY HALL",
    Address: "MACHUBENI / BOOMPLAAS  LADY FRERE",
    Latitude: "-31.5118",
    Longitude: "27.1741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1913",
    WardLookupId: "3034",
    VDNumber: "11071651",
    RegisteredPopulation: "147",
    VotingStationName: "MASIBAMBANE PRE-SCHOOL",
    Address: "MASIBAMBANE VILLAGE  LADY FRERE",
    Latitude: "-31.5903",
    Longitude: "27.1675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1914",
    WardLookupId: "3034",
    VDNumber: "11071662",
    RegisteredPopulation: "259",
    VotingStationName: "JULLIUS MBALO JUNIOR SECONDARY SCHOOL",
    Address: "MACHUBENI ADMIN AREA  XHUMABHOKHWE VILLAGE   LADY FRERE",
    Latitude: "-31.5832",
    Longitude: "27.1944",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1915",
    WardLookupId: "3034",
    VDNumber: "11071684",
    RegisteredPopulation: "279",
    VotingStationName: "MBOLOMPENI TRIBAL HALL",
    Address: "MACHUBENI VILLAGE  LADY FRERE",
    Latitude: "-31.5452",
    Longitude: "27.1768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1916",
    WardLookupId: "3034",
    VDNumber: "11071820",
    RegisteredPopulation: "219",
    VotingStationName: "MZAMOMHLE SCHOOL",
    Address: "LADY FRERE  ",
    Latitude: "-31.4932",
    Longitude: "27.1666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1917",
    WardLookupId: "3035",
    VDNumber: "11070087",
    RegisteredPopulation: "564",
    VotingStationName: "MHLANGA JUNIOR SECONDARY SCHOOL",
    Address: "MHLANGA A/A  CACADU  LADY FRERE",
    Latitude: "-31.4428",
    Longitude: "27.2012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1918",
    WardLookupId: "3035",
    VDNumber: "11070379",
    RegisteredPopulation: "418",
    VotingStationName: "BONISWA JUNIOR SECONDARY SCHOOL",
    Address: "MACHUBENI A/A  LADY FRERE",
    Latitude: "-31.4922",
    Longitude: "27.2298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1919",
    WardLookupId: "3035",
    VDNumber: "11070683",
    RegisteredPopulation: "377",
    VotingStationName: "ST AUGUSTON JUNIOR SECONDARY SCHOOL",
    Address: "MHLANGA VILLAGE  LADY FRERE",
    Latitude: "-31.4633",
    Longitude: "27.16",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1920",
    WardLookupId: "3035",
    VDNumber: "11071639",
    RegisteredPopulation: "207",
    VotingStationName: "UITKYK JUNIOR SECONDARY SCHOOL",
    Address: "UITKYK VILLAGE/ UMHLANGA  LADY FRERE",
    Latitude: "-31.4478",
    Longitude: "27.1241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1921",
    WardLookupId: "3035",
    VDNumber: "11071695",
    RegisteredPopulation: "230",
    VotingStationName: "PHAKAMANI SENIOR PRIMARY SCHOOL",
    Address: "MHLANGA A/A  INDWE",
    Latitude: "-31.4286",
    Longitude: "27.1547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1922",
    WardLookupId: "3035",
    VDNumber: "11071785",
    RegisteredPopulation: "43",
    VotingStationName: "BLAUW KRANTZ FARM",
    Address: "INDWE  LADY FRERE",
    Latitude: "-31.3988",
    Longitude: "27.2377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1923",
    WardLookupId: "3035",
    VDNumber: "11240019",
    RegisteredPopulation: "661",
    VotingStationName: "DORDRECHT TOWN HALL",
    Address: "GREY STREET  TOWN  DORDRECHT",
    Latitude: "-31.3756",
    Longitude: "27.0481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1924",
    WardLookupId: "3035",
    VDNumber: "11240086",
    RegisteredPopulation: "945",
    VotingStationName: "ANAKO SENIOR PRIMARY SCHOOL",
    Address: "1346 ZOLA LOCATION  DORDRECHT",
    Latitude: "-31.3845",
    Longitude: "27.0416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1925",
    WardLookupId: "3036",
    VDNumber: "11071291",
    RegisteredPopulation: "77",
    VotingStationName: "BULELANI SENIOR PRIMARY SCHOOL",
    Address: "CEGCIYANA VILLAGE  LADY FRERE",
    Latitude: "-31.5959",
    Longitude: "27.4301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1926",
    WardLookupId: "3036",
    VDNumber: "11071831",
    RegisteredPopulation: "125",
    VotingStationName: "LUKHANYO SCHOOL",
    Address: "LADY FRERE  ",
    Latitude: "-31.5531",
    Longitude: "27.3754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1927",
    WardLookupId: "3036",
    VDNumber: "11260011",
    RegisteredPopulation: "1037",
    VotingStationName: "LUKHANYO HIGH SCHOOL",
    Address: "MAVUYA TOWN SHIP  INDWE",
    Latitude: "-31.4764",
    Longitude: "27.3552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1928",
    WardLookupId: "3036",
    VDNumber: "11260033",
    RegisteredPopulation: "668",
    VotingStationName: "ESETHU PRIMARY SCHOOL",
    Address: "MAIN ROAD  MAVUYA  INDWE",
    Latitude: "-31.4698",
    Longitude: "27.3492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1929",
    WardLookupId: "3036",
    VDNumber: "11260044",
    RegisteredPopulation: "712",
    VotingStationName: "SIYAKHULA SENIOR PRIMARY SCHOOL",
    Address: "MZAMOMHLE TOWNSHIP  INDWE [EMALAHLENI]",
    Latitude: "-31.4734",
    Longitude: "27.3593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1930",
    WardLookupId: "3036",
    VDNumber: "11400028",
    RegisteredPopulation: "309",
    VotingStationName: "IDA FARM SCHOOL",
    Address: "IDA  INDWE",
    Latitude: "-31.4003",
    Longitude: "27.557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1931",
    WardLookupId: "3036",
    VDNumber: "11400051",
    RegisteredPopulation: "32",
    VotingStationName: "COLD STREAM FARM SCHOOL",
    Address: "INDWE  COLD STREAM FARM  INDWE [EMALAHLENI]",
    Latitude: "-31.4354",
    Longitude: "27.3868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1932",
    WardLookupId: "3036",
    VDNumber: "11400062",
    RegisteredPopulation: "76",
    VotingStationName: "TUNGELA FARM BUILDING",
    Address: "IDA  IDA FARMS  EMALAHLENI [LADY FRERE]",
    Latitude: "-31.3689",
    Longitude: "27.5325",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1933",
    WardLookupId: "3036",
    VDNumber: "11460147",
    RegisteredPopulation: "288",
    VotingStationName: "THEMBELIHLE JUNIOR SECONDARY SCHOOL",
    Address: "LUPAPASI VILLAGE  CALA",
    Latitude: "-31.5544",
    Longitude: "27.4634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1934",
    WardLookupId: "3036",
    VDNumber: "11460271",
    RegisteredPopulation: "275",
    VotingStationName: "LUPAPASI J.S.S.",
    Address: "NTSINGA ADMIN AREA LUPAPASI  LUPAPASI   CALA",
    Latitude: "-31.541",
    Longitude: "27.4343",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1935",
    WardLookupId: "3036",
    VDNumber: "11460282",
    RegisteredPopulation: "318",
    VotingStationName: "MAQWATHINI JUNIOR SECONDARY SCHOOL",
    Address: "LUPAPASI ADMIN AREA  MAQWATHINI VILLAGE  CALA",
    Latitude: "-31.53",
    Longitude: "27.4628",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1936",
    WardLookupId: "3037",
    VDNumber: "11070054",
    RegisteredPopulation: "448",
    VotingStationName: "SIZWE JUNIOR SECONDARY SCHOOL",
    Address: "VUKANI A/A  VUKANI VILLAGE  LADY FRERE",
    Latitude: "-31.5172",
    Longitude: "27.3233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1937",
    WardLookupId: "3037",
    VDNumber: "11070380",
    RegisteredPopulation: "393",
    VotingStationName: "GUBA HOEK SCHOOL",
    Address: "BOOM PLAAS  CACADU  LADY FRERE",
    Latitude: "-31.5219",
    Longitude: "27.2782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1938",
    WardLookupId: "3037",
    VDNumber: "11070919",
    RegisteredPopulation: "339",
    VotingStationName: "KANYA JUNIOR SECONDARY SCHOOL",
    Address: "MACUBENI  CHIBINI VILLAGE  LADY FRERE",
    Latitude: "-31.4907",
    Longitude: "27.2714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1939",
    WardLookupId: "3037",
    VDNumber: "11070920",
    RegisteredPopulation: "266",
    VotingStationName: "ABRAHAM VANQA JUNIOR SECONDARY SCHOOL",
    Address: "MHLANGA  A/A  CACADU  LADY FRERE",
    Latitude: "-31.4834",
    Longitude: "27.305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1940",
    WardLookupId: "3037",
    VDNumber: "11071628",
    RegisteredPopulation: "312",
    VotingStationName: "NGANGAMANZI JUNIOR SECONDARY SCHOOL",
    Address: "MACUBENI  CHIBINI VILLAGE  LADY FRERE",
    Latitude: "-31.5057",
    Longitude: "27.2283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1941",
    WardLookupId: "3037",
    VDNumber: "11260022",
    RegisteredPopulation: "565",
    VotingStationName: "SINETHEMBA - PRE SCHOOL",
    Address: "1514 NCOKO STREET  EKUPHUMLENI TOWNSHIP  INDWE 5445",
    Latitude: "-31.4781",
    Longitude: "27.3547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1942",
    WardLookupId: "3037",
    VDNumber: "11400017",
    RegisteredPopulation: "890",
    VotingStationName: "DORA FOSLO HALL",
    Address: "STASSIE STRAAT  INDWE TOWN  INDWE",
    Latitude: "-31.4665",
    Longitude: "27.3369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1943",
    WardLookupId: "3038",
    VDNumber: "11070414",
    RegisteredPopulation: "499",
    VotingStationName: "NOBUHLE JUNIOR SECONDARY SCHOOL",
    Address: "NGQOKO  CACADU  LADY FRERE",
    Latitude: "-31.7525",
    Longitude: "27.2399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1944",
    WardLookupId: "3038",
    VDNumber: "11070425",
    RegisteredPopulation: "578",
    VotingStationName: "NGCUKA JUNIOR SECONDARY SCHOOL",
    Address: "NGCUKA  CACADU  LADY FRERE",
    Latitude: "-31.7268",
    Longitude: "27.2527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1945",
    WardLookupId: "3038",
    VDNumber: "11070773",
    RegisteredPopulation: "661",
    VotingStationName: "BUYOKOYOKO JUNIOR SECONDARY SCHOOL",
    Address: "ZINGQOLWENI VILLAGE  LADY FRERE",
    Latitude: "-31.7021",
    Longitude: "27.3638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1946",
    WardLookupId: "3038",
    VDNumber: "11070841",
    RegisteredPopulation: "376",
    VotingStationName: "VALELO JUNIOR SECONDARY SCHOOL",
    Address: "VALELO VILLAGE, MAQASHU A/A  LADY FRERE",
    Latitude: "-31.737",
    Longitude: "27.3874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1947",
    WardLookupId: "3038",
    VDNumber: "11071235",
    RegisteredPopulation: "391",
    VotingStationName: "THANDISIZWE SENIOR PRIMARY SCHOOL",
    Address: "NGQOKO VILLAGE  LADY FRERE",
    Latitude: "-31.763",
    Longitude: "27.244",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1948",
    WardLookupId: "3038",
    VDNumber: "11071268",
    RegisteredPopulation: "512",
    VotingStationName: "THE ENDTIME MASSAGE CHURCH",
    Address: "BHAKUBHA  VILLAGE, MAQHASHU A/A  LADY FRERE",
    Latitude: "-31.7414",
    Longitude: "27.3524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1949",
    WardLookupId: "3038",
    VDNumber: "11071718",
    RegisteredPopulation: "224",
    VotingStationName: "BINCA  PRE-SCHOOL",
    Address: "BINCA VILLAGE  LADY FRERE",
    Latitude: "-31.7338",
    Longitude: "27.2579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1950",
    WardLookupId: "3039",
    VDNumber: "11080785",
    RegisteredPopulation: "302",
    VotingStationName: "LUNDA SENIOR PRIMARY SCHOOL",
    Address: "JOJWENI VILLAGE  CABA A/A  NGCOBO",
    Latitude: "-32.0371",
    Longitude: "28.0244",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1951",
    WardLookupId: "3039",
    VDNumber: "11570037",
    RegisteredPopulation: "565",
    VotingStationName: "GANYA SENIOR PRIMARY SCHOOL",
    Address: "MAMFENENI VILLAGE  MQONCI A/A  NGCOBO",
    Latitude: "-31.9345",
    Longitude: "28.0941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1952",
    WardLookupId: "3039",
    VDNumber: "11570543",
    RegisteredPopulation: "909",
    VotingStationName: "NGQUTURA PRIMARY JUNIOR SCHOOL",
    Address: "NGQUTURA VILLAGE  NGQUTURA A/A  NGCOBO",
    Latitude: "-31.9582",
    Longitude: "28.1005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1953",
    WardLookupId: "3039",
    VDNumber: "11570554",
    RegisteredPopulation: "498",
    VotingStationName: "GCUWAPRIMARY JUNIOR SCHOOL",
    Address: "TSHATSHU VILLAGE  CABA A/A  NGCOBO",
    Latitude: "-31.9981",
    Longitude: "28.0669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1954",
    WardLookupId: "3039",
    VDNumber: "11570565",
    RegisteredPopulation: "627",
    VotingStationName: "NXAMAGELE PRIMARY JUNIOR SCHOOL",
    Address: "NGCATARU VILLAGE  NXAMAGELE A/A  NGCOBO",
    Latitude: "-31.9934",
    Longitude: "28.0261",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1955",
    WardLookupId: "3039",
    VDNumber: "11571274",
    RegisteredPopulation: "349",
    VotingStationName: "NGCATARU PRIMARY JUNIOR SCHOOL",
    Address: "NDUNGWANE VILLAGE  NXAMAGELE A/A  NGCOBO",
    Latitude: "-31.981",
    Longitude: "28.0585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1956",
    WardLookupId: "3039",
    VDNumber: "11571409",
    RegisteredPopulation: "178",
    VotingStationName: "CIKICWAYO   SENIOR PRIMARY SCHOOL",
    Address:
      "EMANDLANENI LOCATION  NXAMAGELE ADMINISTRATIVE AREA  NXAMAGELE ADMINISTRATIVE AREA",
    Latitude: "-31.9595",
    Longitude: "28.0135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1957",
    WardLookupId: "3040",
    VDNumber: "11570576",
    RegisteredPopulation: "833",
    VotingStationName: "CWECWENI PRIMARY JUNIOR SCHOOL",
    Address: "CWECWENI VILLAGE  CWECWENI A/A  NGCOBO",
    Latitude: "-31.9357",
    Longitude: "28.0482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1958",
    WardLookupId: "3040",
    VDNumber: "11570587",
    RegisteredPopulation: "659",
    VotingStationName: "TAFA PRIMARY JUNIOR  SCHOOL",
    Address: "ENTLAKWEVENKILE VILLAGE  XONYA A/A  NGCOBO",
    Latitude: "-31.8968",
    Longitude: "28.0643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1959",
    WardLookupId: "3040",
    VDNumber: "11570824",
    RegisteredPopulation: "523",
    VotingStationName: "NTABENI PRIMARY JUNIOR SCHOOL",
    Address: "LUDHIZA  XONYA A/A  NGCOBO",
    Latitude: "-31.8761",
    Longitude: "28.0926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1960",
    WardLookupId: "3040",
    VDNumber: "11570835",
    RegisteredPopulation: "428",
    VotingStationName: "ZWELOXOLO SENIOR PRIMARY SCHOOL",
    Address: "LALINI  VILLAGE  MQONCI A/A  NGCOBO",
    Latitude: "-31.9185",
    Longitude: "28.1293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1961",
    WardLookupId: "3040",
    VDNumber: "11570846",
    RegisteredPopulation: "525",
    VotingStationName: "MQONCI PRIMARY JUNIOR SCHOOL",
    Address: "ENTLAKWESIKOLO VILLAGE  MQONCI A/A  NGCOBO",
    Latitude: "-31.9443",
    Longitude: "28.1378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1962",
    WardLookupId: "3040",
    VDNumber: "11571386",
    RegisteredPopulation: "395",
    VotingStationName: "XONYA SENIOR PRIMARY SCHOOL",
    Address: "SGUBUDWINI VILLAGE  XONYA A/A  NGCOBO",
    Latitude: "-31.9079",
    Longitude: "28.0908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1963",
    WardLookupId: "3041",
    VDNumber: "11570510",
    RegisteredPopulation: "475",
    VotingStationName: "DABULINGWE SENIOR PRIMARY SCHOOL",
    Address: "EMAKHAXENI VILLAGE  TORA A/A  NGCOBO",
    Latitude: "-31.8829",
    Longitude: "28.1943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1964",
    WardLookupId: "3041",
    VDNumber: "11570712",
    RegisteredPopulation: "423",
    VotingStationName: "ZWELIHLE SENIOR SECONDARY SCHOOL",
    Address: "QANGULENI  LOC  TORA  A/A  NGCOBO",
    Latitude: "-31.9008",
    Longitude: "28.1507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1965",
    WardLookupId: "3041",
    VDNumber: "11571050",
    RegisteredPopulation: "452",
    VotingStationName: "LAPETUKA SENIOR PRIMARY SCHOOL",
    Address: "MJANYANA VILLAGE  TORA A/A  NGCOBO",
    Latitude: "-31.8578",
    Longitude: "28.1255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1966",
    WardLookupId: "3041",
    VDNumber: "11571061",
    RegisteredPopulation: "360",
    VotingStationName: "SOBUZA  JUNIOR SECONDARY SCHOOL",
    Address: "MQONCI LOC  TORA A/A  NGCOBO",
    Latitude: "-31.9408",
    Longitude: "28.1735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1967",
    WardLookupId: "3041",
    VDNumber: "11571072",
    RegisteredPopulation: "298",
    VotingStationName: "QENGQELEKA PRIMARY JUNIOR  SCHOOL",
    Address: "MJIKELWENI VILLAGE  TORA A/A  NGCOBO",
    Latitude: "-31.9232",
    Longitude: "28.183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1968",
    WardLookupId: "3041",
    VDNumber: "11571094",
    RegisteredPopulation: "306",
    VotingStationName: "BEKILENI PRIMARY JUNIOR SCHOOL",
    Address: "BEKILENI VILLAGE  TORA A/A  NGCOBO",
    Latitude: "-31.8925",
    Longitude: "28.1779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1969",
    WardLookupId: "3041",
    VDNumber: "11571106",
    RegisteredPopulation: "611",
    VotingStationName: "NTSIMBA PRIMARY JUNIOR SCHOOL",
    Address: "KWADLOMO VILLAGE  TORA A/A  NGCOBO",
    Latitude: "-31.8684",
    Longitude: "28.2191",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1970",
    WardLookupId: "3041",
    VDNumber: "11571353",
    RegisteredPopulation: "204",
    VotingStationName: "KIDSTON PRIMARY JUNIOR SCHOOL",
    Address: "EMAZIZINI VILLAGE  TORA A/A  NGCOBO",
    Latitude: "-31.8714",
    Longitude: "28.1136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1971",
    WardLookupId: "3042",
    VDNumber: "11570442",
    RegisteredPopulation: "640",
    VotingStationName: "NTLALUKANA PRIMARY JUNIOR SCHOOL",
    Address: "NTLALUKANA  LOC  NTLALUKANA  AA  ENGCOBO",
    Latitude: "-31.8093",
    Longitude: "28.2353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1972",
    WardLookupId: "3042",
    VDNumber: "11570497",
    RegisteredPopulation: "376",
    VotingStationName: "MJANYANA SENIOR PRIMARY SCHOOL",
    Address: "SHUSHWANA VILLAGE  MJANYANA  AA  NGCOBO",
    Latitude: "-31.8546",
    Longitude: "28.097",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1973",
    WardLookupId: "3042",
    VDNumber: "11570509",
    RegisteredPopulation: "830",
    VotingStationName: "QULUQU SENIOR PRIMARY SCHOOL",
    Address: "NGQUBUSINI VILLAGE  QULUQU  AA  NGCOBO",
    Latitude: "-31.8334",
    Longitude: "28.1954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1974",
    WardLookupId: "3042",
    VDNumber: "11570701",
    RegisteredPopulation: "893",
    VotingStationName: "COBOSI SENIOR PRIMARY SCHOOL",
    Address: "NDULINI LOC  COBOSI A/A  ENGCOBO",
    Latitude: "-31.7954",
    Longitude: "28.1275",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1975",
    WardLookupId: "3042",
    VDNumber: "11571049",
    RegisteredPopulation: "455",
    VotingStationName: "KHANYOLWETHU SENIOR SECONDARY SCHOOL",
    Address: "NGCELELO LOC  COBOSI A/A  ENGCOBO",
    Latitude: "-31.8013",
    Longitude: "28.1364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1976",
    WardLookupId: "3042",
    VDNumber: "11571083",
    RegisteredPopulation: "484",
    VotingStationName: "BAZINDLOVU SENIOR PRIMARY SCHOOL",
    Address: "CHIBINI VILLAGE  QULUQU A/A  NGCOBO",
    Latitude: "-31.841",
    Longitude: "28.1548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1977",
    WardLookupId: "3043",
    VDNumber: "11570431",
    RegisteredPopulation: "539",
    VotingStationName: "MAQANDA PRIMARY JUNIOR SCHOOL",
    Address: "KWANTONDO VILLAGE  NKWENKWANA A/A  NGCOBO",
    Latitude: "-31.7543",
    Longitude: "28.1782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1978",
    WardLookupId: "3043",
    VDNumber: "11570486",
    RegisteredPopulation: "565",
    VotingStationName: "NGCACU PRIMARY JUNIOR SCHOOL",
    Address: "SIGIDI LOC  NGCACU A/A  NGCOBO",
    Latitude: "-31.7545",
    Longitude: "28.1217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1979",
    WardLookupId: "3043",
    VDNumber: "11570532",
    RegisteredPopulation: "400",
    VotingStationName: "MBILINI PRIMARY JUNIOR SCHOOL",
    Address: "MBILINI VILLAGE  NKWENKWANA A/A  NGCOBO",
    Latitude: "-31.7815",
    Longitude: "28.1838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1980",
    WardLookupId: "3043",
    VDNumber: "11571139",
    RegisteredPopulation: "525",
    VotingStationName: "MTAWELANGA PRIMARY JUNIOR SCHOOL",
    Address: "VETYU VILLAGE  ELUHEWINI A/A  NGCOBO",
    Latitude: "-31.7226",
    Longitude: "28.1446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1981",
    WardLookupId: "3043",
    VDNumber: "11571140",
    RegisteredPopulation: "555",
    VotingStationName: "QOTA JUNIOR SECONDARY SCHOOL",
    Address: "MACWERENI VILLAGE  NKWENKWANA A/A  NGCOBO",
    Latitude: "-31.7342",
    Longitude: "28.1926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1982",
    WardLookupId: "3043",
    VDNumber: "11571296",
    RegisteredPopulation: "575",
    VotingStationName: "NKWENKWANA PRIMARY JUNIOR SCHOOL",
    Address: "NKWENKWANA VILLAGE  NKWENKWANA A/A  NGCOBO",
    Latitude: "-31.7863",
    Longitude: "28.2047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1983",
    WardLookupId: "3044",
    VDNumber: "11570453",
    RegisteredPopulation: "435",
    VotingStationName: "ST ALBANS CHURCH",
    Address: "NGAPHESHEYA VILLAGE  GOSO A/A   NGCOBO",
    Latitude: "-31.8302",
    Longitude: "28.0086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1984",
    WardLookupId: "3044",
    VDNumber: "11570464",
    RegisteredPopulation: "601",
    VotingStationName: "ZILIMBOLA PRIMARY JUNIOR SCHOOL",
    Address: "EMAMFENENI  VILLAGE  DEBERA  A/A  NGCOBO",
    Latitude: "-31.8329",
    Longitude: "28.0581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1985",
    WardLookupId: "3044",
    VDNumber: "11570475",
    RegisteredPopulation: "550",
    VotingStationName: "MBEKENI SENIOR PRIMARY SCHOOL",
    Address: "MBEKENI VILLAGE  MGWALI A/A  NGCOBO",
    Latitude: "-31.7812",
    Longitude: "28.0193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1986",
    WardLookupId: "3044",
    VDNumber: "11570723",
    RegisteredPopulation: "639",
    VotingStationName: "DEBERA SENIOR PRIMARY SCHOOL",
    Address: "KUNENE  VILLAGE  DEBERA  A/A  NGCOBO",
    Latitude: "-31.7939",
    Longitude: "28.0742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1987",
    WardLookupId: "3044",
    VDNumber: "11570969",
    RegisteredPopulation: "355",
    VotingStationName: "PAKAMANI SENIOR SECONDARY SCHOOL",
    Address: "MAGEZA VILLAGE  DEBERA A/A  NGCOBO",
    Latitude: "-31.8111",
    Longitude: "28.0265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1988",
    WardLookupId: "3044",
    VDNumber: "11570970",
    RegisteredPopulation: "378",
    VotingStationName: "ST ALBANS SENIOR PRIMARY SCHOOL",
    Address: "EGOSO VILLAGE  EGOSO A/A  NGCOBO",
    Latitude: "-31.82",
    Longitude: "28.0147",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1989",
    WardLookupId: "3044",
    VDNumber: "11570981",
    RegisteredPopulation: "557",
    VotingStationName: "NGXEBE PRIMARY JUNIOR SCHOOL",
    Address: "NGXEBE VILLAGE  NGXEBE A/A  NGCOBO",
    Latitude: "-31.8388",
    Longitude: "27.9654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1990",
    WardLookupId: "3045",
    VDNumber: "11570116",
    RegisteredPopulation: "371",
    VotingStationName: "EKUPHUMLENI STORE",
    Address: "MAZANGWENI VILLAGE  GUBENXA A/A  NGCOBO",
    Latitude: "-31.7222",
    Longitude: "27.8509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1991",
    WardLookupId: "3045",
    VDNumber: "11570341",
    RegisteredPopulation: "898",
    VotingStationName: "BEYELE SENIOR PRIMARY SCHOOL",
    Address: "GXUBANE VILLAGE  BEYELE  A/A  NGCOBO",
    Latitude: "-31.7588",
    Longitude: "27.9646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1992",
    WardLookupId: "3045",
    VDNumber: "11570385",
    RegisteredPopulation: "743",
    VotingStationName: "NGUBESIZWE SECONDARY SCHOOL",
    Address: "BODINI VILLAGE  QUMANCO A/A  NGCOBO",
    Latitude: "-31.7915",
    Longitude: "27.9124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1993",
    WardLookupId: "3045",
    VDNumber: "11570903",
    RegisteredPopulation: "557",
    VotingStationName: "QOBA SENIOR PRIMARY SCHOOL",
    Address: "QOBA VILLAGE  ELUCWECWE A/A  NGCOBO",
    Latitude: "-31.7469",
    Longitude: "27.9092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1994",
    WardLookupId: "3045",
    VDNumber: "11570947",
    RegisteredPopulation: "332",
    VotingStationName: "ZAMIWONGA SENIOR PRIMARY SCHOOL",
    Address: "EFAMA VILLAGE  QUMANCO A/A  NGCOBO",
    Latitude: "-31.7826",
    Longitude: "27.8838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1995",
    WardLookupId: "3045",
    VDNumber: "11570958",
    RegisteredPopulation: "556",
    VotingStationName: "NOGWAZA SENIOR PRIMARY SCHOOL",
    Address: "BEYELE LOC  BEYELE A/A  ENGCOBO",
    Latitude: "-31.774",
    Longitude: "27.9408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1996",
    WardLookupId: "3045",
    VDNumber: "11571285",
    RegisteredPopulation: "298",
    VotingStationName: "TSHATSHATSHA PRIMARY SCHOOL",
    Address: "TSHATSHATSHA  QUMANCO A/A  NGCOBO",
    Latitude: "-31.8255",
    Longitude: "27.9263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1997",
    WardLookupId: "3045",
    VDNumber: "11571364",
    RegisteredPopulation: "137",
    VotingStationName: "EMTHWAKU SENIOR PRIMARY SCHOOL",
    Address: "EMTHWAKU VILLAGE  MANZANA A/A  NGCOBO",
    Latitude: "-31.7271",
    Longitude: "27.9573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1998",
    WardLookupId: "3046",
    VDNumber: "11570015",
    RegisteredPopulation: "583",
    VotingStationName: "ESIKOBENI SENIOR PRIMARY SCHOOL",
    Address: "EMADLADLENI VILLAGE  BOJANE A/A  NGCOBO",
    Latitude: "-31.7037",
    Longitude: "27.9434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "1999",
    WardLookupId: "3046",
    VDNumber: "11570330",
    RegisteredPopulation: "609",
    VotingStationName: "ELUCWECWE PRIMARY JUNIOR SCHOOL",
    Address: "NONGADLELA VILLAGE  ELUCWECWE A/A  NGCOBO",
    Latitude: "-31.7362",
    Longitude: "27.8783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2000",
    WardLookupId: "3046",
    VDNumber: "11570600",
    RegisteredPopulation: "507",
    VotingStationName: "JONGUBUHLE  SENIOR PRIMARY SCHOOL",
    Address: "VOYIZANA  VILLAGE  MKANZI  A/A  NGCOBO",
    Latitude: "-31.6673",
    Longitude: "27.9418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2001",
    WardLookupId: "3046",
    VDNumber: "11570790",
    RegisteredPopulation: "801",
    VotingStationName: "MGUDLWA SENIOR SECONDARY SCHOOL",
    Address: "MAZANGWENI  VILLAGE  GUBENXA A/A  NGCOBO",
    Latitude: "-31.7146",
    Longitude: "27.8204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2002",
    WardLookupId: "3046",
    VDNumber: "11570868",
    RegisteredPopulation: "389",
    VotingStationName: "HARGREAVES PRIMARY JUNIOR SCHOOL",
    Address: "EMAMPINGENI VILLAGE  LAHLANGUBO  A/A  NGCOBO",
    Latitude: "-31.6902",
    Longitude: "27.8569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2003",
    WardLookupId: "3046",
    VDNumber: "11570891",
    RegisteredPopulation: "472",
    VotingStationName: "PHAKAMISA SENIOR PRIMARY SCHOOL",
    Address: "MISSION  VILLAGE  ELUCWECWE A/A  NGCOBO",
    Latitude: "-31.7082",
    Longitude: "27.8815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2004",
    WardLookupId: "3047",
    VDNumber: "11490038",
    RegisteredPopulation: "798",
    VotingStationName: "K. S .D. TVET COLLEGE",
    Address: "GREEN LAND FARM  NGCOBO  NGCOBO",
    Latitude: "-31.6869",
    Longitude: "27.9897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2005",
    WardLookupId: "3047",
    VDNumber: "11570262",
    RegisteredPopulation: "827",
    VotingStationName: "TSAZO PRIMARY JUNIOR SCHOOL",
    Address: "LALINI VILLAGE  TSAZO A/A  NGCOBO",
    Latitude: "-31.7107",
    Longitude: "27.9762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2006",
    WardLookupId: "3047",
    VDNumber: "11570329",
    RegisteredPopulation: "984",
    VotingStationName: "MANZANA PRIMARY JUNIOR SCHOOL",
    Address: "MISSION  VILLAGE  MANZANA  A/A  NGCOBO",
    Latitude: "-31.7352",
    Longitude: "28.0114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2007",
    WardLookupId: "3047",
    VDNumber: "11570396",
    RegisteredPopulation: "596",
    VotingStationName: "GOBOTI PRIMARY JUNIOR SCHOOL",
    Address: "RHAFU VILLAGE  MANZANA A/A  NGCOBO",
    Latitude: "-31.7462",
    Longitude: "28.052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2008",
    WardLookupId: "3047",
    VDNumber: "11570880",
    RegisteredPopulation: "487",
    VotingStationName: "BOJANE PRIMARY JUNIOR SCHOOL",
    Address: "CHIBINI VILLAGE  BOJANE A/A  NGCOBO",
    Latitude: "-31.6926",
    Longitude: "27.9545",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2009",
    WardLookupId: "3047",
    VDNumber: "11570992",
    RegisteredPopulation: "130",
    VotingStationName: "ST LUKES PARISH",
    Address: "ESIGAGENI VILLAGE  MANZANA A/A  NGCOBO",
    Latitude: "-31.7109",
    Longitude: "28.0217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2010",
    WardLookupId: "3047",
    VDNumber: "11571005",
    RegisteredPopulation: "365",
    VotingStationName: "ENDUKU PRIMARY JUNIOR SCHOOL",
    Address: "ENDUKU VILLAGE  MANZANA A/A  NGCOBO",
    Latitude: "-31.7471",
    Longitude: "28.0227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2011",
    WardLookupId: "3047",
    VDNumber: "11571375",
    RegisteredPopulation: "132",
    VotingStationName: "KUYASA SENIOR PRIMARY SCHOOL",
    Address: "SIKHANTINI VILLAGE  MANZANA A/A  NGCOBO",
    Latitude: "-31.7275",
    Longitude: "28.0309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2012",
    WardLookupId: "3048",
    VDNumber: "11490027",
    RegisteredPopulation: "1394",
    VotingStationName: "MASONWABE SENIOR PRIMARY SCHOOL",
    Address: "MASONWABE TOWNSHIP  NGCOBO VILLAGE  NGCOBO",
    Latitude: "-31.6801",
    Longitude: "28.0087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2013",
    WardLookupId: "3048",
    VDNumber: "11490049",
    RegisteredPopulation: "673",
    VotingStationName: "MASONWABE COMMUNITY HALL",
    Address: "2226 MASONWABE TOWNSHIP  MASONWABE   NGCOBO",
    Latitude: "-31.6757",
    Longitude: "28.0084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2014",
    WardLookupId: "3048",
    VDNumber: "11570239",
    RegisteredPopulation: "611",
    VotingStationName: "ALL SAINTS SENIOR PRIMARY SCHOOL",
    Address: "MISSION  VILLAGE  ALL SAINTS A/A  NGCOBO",
    Latitude: "-31.6575",
    Longitude: "28.0433",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2015",
    WardLookupId: "3048",
    VDNumber: "11570240",
    RegisteredPopulation: "538",
    VotingStationName: "ZOLA SENIOR PRIMARY SCHOOL",
    Address: "ZOLA VILLAGE  ALL SAINTS A/A  NGCOBO",
    Latitude: "-31.6553",
    Longitude: "28.0312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2016",
    WardLookupId: "3048",
    VDNumber: "11570284",
    RegisteredPopulation: "574",
    VotingStationName: "NYANGA SENIOR SECONDARY SCHOOL",
    Address: "GXOJENI  VILLAGE  ALL SAINTS  A/A  NGCOBO",
    Latitude: "-31.6799",
    Longitude: "28.0412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2017",
    WardLookupId: "3048",
    VDNumber: "11571027",
    RegisteredPopulation: "430",
    VotingStationName: "ALL SAINTS HALL",
    Address: "MISSION  VILLAGE  ALL SAINTS A/A  NGCOBO",
    Latitude: "-31.6624",
    Longitude: "28.0505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2018",
    WardLookupId: "3049",
    VDNumber: "11490016",
    RegisteredPopulation: "1566",
    VotingStationName: "NGCOBO TOWN HALL",
    Address: "58 UNION STR.  NGCOBO  NGCOBO",
    Latitude: "-31.6751",
    Longitude: "27.9942",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2019",
    WardLookupId: "3049",
    VDNumber: "11490050",
    RegisteredPopulation: "269",
    VotingStationName: "NGCOBO INDOOR SPORT CENTRE",
    Address: "ERF 261 DAWN VALLEY  NGCOBO   NGCOBO",
    Latitude: "-31.6789",
    Longitude: "27.9932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2020",
    WardLookupId: "3049",
    VDNumber: "11570251",
    RegisteredPopulation: "423",
    VotingStationName: "GQUTYINI SENIOR PRIMARY SCHOOL",
    Address: "MISSION  VILLAGE  ZADUNGENI  A/A  NGCOBO",
    Latitude: "-31.6354",
    Longitude: "27.9324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2021",
    WardLookupId: "3049",
    VDNumber: "11570789",
    RegisteredPopulation: "472",
    VotingStationName: "ZWELIVUMILE SENIOR SECONDARY SCHOOL",
    Address: "NKUNGWINI VILLAGE  ZADUNGENI A/A  NGCOBO",
    Latitude: "-31.5787",
    Longitude: "27.9154",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2022",
    WardLookupId: "3049",
    VDNumber: "11570925",
    RegisteredPopulation: "262",
    VotingStationName: "CEFANE HOEK PRIMARY JUNIOR SCHOOL",
    Address: "CEFANEHOEK VILLAGE  ZADUNGENI A/A  NGCOBO",
    Latitude: "-31.6351",
    Longitude: "27.9604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2023",
    WardLookupId: "3050",
    VDNumber: "11570149",
    RegisteredPopulation: "741",
    VotingStationName: "ZADUNGENI PRIMARY JUNIOR SCHOOL",
    Address: "GXWALIBOMVU  VILLAGE  ZADUNGENI  A/A  NGCOBO",
    Latitude: "-31.5615",
    Longitude: "27.8941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2024",
    WardLookupId: "3050",
    VDNumber: "11570194",
    RegisteredPopulation: "381",
    VotingStationName: "NKWENKWEZI SENIOR PRIMARY SCHOOL",
    Address: "MTHUMENI VILLAGE  NKWENKWEZI A/A  NGCOBO",
    Latitude: "-31.6238",
    Longitude: "27.7932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2025",
    WardLookupId: "3050",
    VDNumber: "11570206",
    RegisteredPopulation: "381",
    VotingStationName: "NDUMNGELA SENIOR PRIMARY SCHOOL",
    Address: "NDUMNGELA  VILLAGE  GQUTYINI  A/A  NGCOBO",
    Latitude: "-31.6445",
    Longitude: "27.8895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2026",
    WardLookupId: "3050",
    VDNumber: "11570228",
    RegisteredPopulation: "538",
    VotingStationName: "MAGOPENI JUNIOR SECONDARY SCHOOL",
    Address: "TSHATSHU VILLAGE  LAHLANGUBO A/A  NGCOBO",
    Latitude: "-31.6631",
    Longitude: "27.8697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2027",
    WardLookupId: "3050",
    VDNumber: "11570857",
    RegisteredPopulation: "376",
    VotingStationName: "MNTUNTLONI JUNIOR PRIMARY SCHOOL",
    Address: "MNTUNTLONI VILLAGE  MNTUNTLONI A/A  NGCOBO",
    Latitude: "-31.5903",
    Longitude: "27.7825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2028",
    WardLookupId: "3050",
    VDNumber: "11570879",
    RegisteredPopulation: "677",
    VotingStationName: "GWARUBANA SENIOR SECONDARY SCHOOL",
    Address: "MKHANZI VILLAGE  GQUTYINI A/A  NGCOBO",
    Latitude: "-31.6612",
    Longitude: "27.9284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2029",
    WardLookupId: "3050",
    VDNumber: "11571397",
    RegisteredPopulation: "402",
    VotingStationName: "TYELINZIMA PRIMARY JUNIOR SCHOOL",
    Address: "TRUSTINI VILLAGE  LAHLANGUBO A/A  NGCOBO",
    Latitude: "-31.6577",
    Longitude: "27.8435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2030",
    WardLookupId: "3051",
    VDNumber: "11570060",
    RegisteredPopulation: "679",
    VotingStationName: "SITOLENI PRIMARY JUNIOR SCHOOL",
    Address: "SUNDWANA  VILLAGE  SITOLENI  A/A  NGCOBO",
    Latitude: "-31.4927",
    Longitude: "28.0803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2031",
    WardLookupId: "3051",
    VDNumber: "11570082",
    RegisteredPopulation: "609",
    VotingStationName: "QEBE JUNIOR SECONDARY SCHOOL",
    Address: "QEBE VILLAGE  QEBE A/A  NGCOBO",
    Latitude: "-31.5122",
    Longitude: "28.0354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2032",
    WardLookupId: "3051",
    VDNumber: "11570127",
    RegisteredPopulation: "653",
    VotingStationName: "QUTUBENI PRIMARY JUNIOR SCHOOL",
    Address: "MAYIRHENI VILLAGE  QUTUBENI A/A  NGCOBO",
    Latitude: "-31.6251",
    Longitude: "28.02",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2033",
    WardLookupId: "3051",
    VDNumber: "11570655",
    RegisteredPopulation: "539",
    VotingStationName: "GULANDODA PRIMARY JUNIOR SCHOOL",
    Address: "LUBISI  VILLAGE  TSALABA   A/A  NGCOBO",
    Latitude: "-31.598",
    Longitude: "28.0346",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2034",
    WardLookupId: "3051",
    VDNumber: "11570914",
    RegisteredPopulation: "603",
    VotingStationName: "SITHONGA JUNIOR SECONDARY SCHOOL",
    Address: "SITHONGA VILLAGE  QUTUBENI A/A  NGCOBO",
    Latitude: "-31.5823",
    Longitude: "27.9886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2035",
    WardLookupId: "3051",
    VDNumber: "11570936",
    RegisteredPopulation: "308",
    VotingStationName: "LOWER QEBE JUNIOR SECONDARY SCHOOL",
    Address: "LOWER QEBE VILLAGE  QEBE A/A  NGCOBO",
    Latitude: "-31.5719",
    Longitude: "28.0041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2036",
    WardLookupId: "3051",
    VDNumber: "11571016",
    RegisteredPopulation: "157",
    VotingStationName: "POSWAYO PRIMARY JUNIOR SCHOOL",
    Address: "NCANABANA VILLAGE  ALL SAINTS A/A  NGCOBO",
    Latitude: "-31.641",
    Longitude: "28.0438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2037",
    WardLookupId: "3051",
    VDNumber: "11571207",
    RegisteredPopulation: "240",
    VotingStationName: "KANYE PRIMARY JUNIOR SCHOOL",
    Address: "ELUQOLWENI VILLAGE  KANYE A/A  NGCOBO",
    Latitude: "-31.6195",
    Longitude: "28.0539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2038",
    WardLookupId: "3052",
    VDNumber: "11570217",
    RegisteredPopulation: "711",
    VotingStationName: "CEFANE PRIMARY JUNIOR SCHOOL",
    Address: "MCOBOLOLO VILLAGE  CEFANE A/A  NGCOBO",
    Latitude: "-31.6833",
    Longitude: "28.192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2039",
    WardLookupId: "3052",
    VDNumber: "11570295",
    RegisteredPopulation: "526",
    VotingStationName: "SILO PRIMARY JUNIOR SCHOOL",
    Address: "MDENI VILLAGE  GCINA A/A  NGCOBO",
    Latitude: "-31.6825",
    Longitude: "28.0818",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2040",
    WardLookupId: "3052",
    VDNumber: "11570307",
    RegisteredPopulation: "584",
    VotingStationName: "ELUHEWINI JUNIOR SECONDARY SCHOOL",
    Address: "LALINI  VILLAGE  ELUHEWINI A/A  NGCOBO",
    Latitude: "-31.7076",
    Longitude: "28.1266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2041",
    WardLookupId: "3052",
    VDNumber: "11570318",
    RegisteredPopulation: "390",
    VotingStationName: "NTLELELENGWANE PRIMARY JUNIOR SCHOOL",
    Address: "UPPER NZOLO  VILLAGE  ELUHEWINI  A/A  NGCOBO",
    Latitude: "-31.7139",
    Longitude: "28.1",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2042",
    WardLookupId: "3052",
    VDNumber: "11571038",
    RegisteredPopulation: "488",
    VotingStationName: "NOXOLO SENIOR PRIMARY SCHOOL",
    Address: "SIXHOLOSINI VILLAGE  ALL SAINTS A/A  NGCOBO",
    Latitude: "-31.686",
    Longitude: "28.068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2043",
    WardLookupId: "3052",
    VDNumber: "11571117",
    RegisteredPopulation: "498",
    VotingStationName: "ZWELIDUMILE SENIOR PRIMARY SCHOOL",
    Address: "KWANZOLO EZANTSI VILLAGE  ELUHEWINI A/A  NGCOBO",
    Latitude: "-31.6938",
    Longitude: "28.1057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2044",
    WardLookupId: "3052",
    VDNumber: "11571128",
    RegisteredPopulation: "267",
    VotingStationName: "KWAXOXO KOMKHULU HOUSE",
    Address: "KWAXOXO VILLAGE  ELUHEWINI A/A  NGCOBO",
    Latitude: "-31.7354",
    Longitude: "28.1028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2045",
    WardLookupId: "3052",
    VDNumber: "11571151",
    RegisteredPopulation: "232",
    VotingStationName: "CACA SENIOR PRIMARY SCHOOL",
    Address: "MHLONTLO VILLAGE  CACA A/A  NGCOBO",
    Latitude: "-31.684",
    Longitude: "28.1603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2046",
    WardLookupId: "3052",
    VDNumber: "11571308",
    RegisteredPopulation: "191",
    VotingStationName: "NDUNGUNYENI HOUSE",
    Address: "NDUNGUNYENI VILLAGE  ELUHEWINI A/A  NGCOBO",
    Latitude: "-31.7002",
    Longitude: "28.0686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2047",
    WardLookupId: "3053",
    VDNumber: "11570093",
    RegisteredPopulation: "720",
    VotingStationName: "SENTUBE PRIMARY JUNIOR SCHOOL",
    Address: "NGXANGXASI VILLAGE  SENTUBE A/A  NGCOBO",
    Latitude: "-31.575",
    Longitude: "28.1692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2048",
    WardLookupId: "3053",
    VDNumber: "11570183",
    RegisteredPopulation: "440",
    VotingStationName: "MSINTSANA JUNIOR SECONDARY SCHOOL",
    Address: "MAKHUMENI  VILLAGE  MSINTSANA  A/A  NGCOBO",
    Latitude: "-31.6368",
    Longitude: "28.1074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2049",
    WardLookupId: "3053",
    VDNumber: "11570363",
    RegisteredPopulation: "581",
    VotingStationName: "MADOTYENI CLINIC",
    Address: "MADOTYENI VILLAGE  UPPER GQOBONCO A/A  NGCOBO",
    Latitude: "-31.6184",
    Longitude: "28.1505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2050",
    WardLookupId: "3053",
    VDNumber: "11570633",
    RegisteredPopulation: "735",
    VotingStationName: "DABULAMANZI PRIMARY JUNIOR SCHOOL",
    Address: "NGXOGI VILLAGE  NGXOGI  A/A  NGCOBO",
    Latitude: "-31.664",
    Longitude: "28.1524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2051",
    WardLookupId: "3053",
    VDNumber: "11571218",
    RegisteredPopulation: "356",
    VotingStationName: "NOKHULULEKILE   STORE",
    Address: "NYOKA VILLAGE  MSINTSANA A/A  NGCOBO",
    Latitude: "-31.6165",
    Longitude: "28.1198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2052",
    WardLookupId: "3053",
    VDNumber: "11571229",
    RegisteredPopulation: "337",
    VotingStationName: "RASMENI PRIMARY JUNIOR SCHOOL",
    Address: "MBENDENI VILLAGE  RASMENI A/A  NGCOBO",
    Latitude: "-31.6276",
    Longitude: "28.1742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2053",
    WardLookupId: "3053",
    VDNumber: "11571230",
    RegisteredPopulation: "625",
    VotingStationName: "ZWELINZIMA JUNIOR SECONDARY SCHOOL",
    Address: "TSHAPILE VILLAGE  TSHAPILE A/A  NGCOBO",
    Latitude: "-31.6506",
    Longitude: "28.185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2054",
    WardLookupId: "3053",
    VDNumber: "11571320",
    RegisteredPopulation: "296",
    VotingStationName: "NDOBE SENIOR PRIMARY SCHOOL",
    Address: "NONYENTU  MSINTSANA A/A  NGCOBO",
    Latitude: "-31.6441",
    Longitude: "28.0801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2055",
    WardLookupId: "3054",
    VDNumber: "11570408",
    RegisteredPopulation: "734",
    VotingStationName: "SITEBE PRIMARY JUNIOR SCHOOL",
    Address: "MANUNENI VILLAGE  SITEBE A/A  NGCOBO",
    Latitude: "-31.7405",
    Longitude: "28.2983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2056",
    WardLookupId: "3054",
    VDNumber: "11570420",
    RegisteredPopulation: "591",
    VotingStationName: "MHLOPEKAZI PRIMARY JUNIOR SCHOOL",
    Address: "TAFENI VILLAGE  MHLOPEKAZI  A/A  NGCOBO",
    Latitude: "-31.7321",
    Longitude: "28.2423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2057",
    WardLookupId: "3054",
    VDNumber: "11570598",
    RegisteredPopulation: "572",
    VotingStationName: "CLARKEBURY PRIMARY JUNIOR SCHOOL",
    Address: "MISSION  VILLAGE  CLARKEBURY  A/A  NGCOBO",
    Latitude: "-31.7848",
    Longitude: "28.2871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2058",
    WardLookupId: "3054",
    VDNumber: "11570688",
    RegisteredPopulation: "635",
    VotingStationName: "MPUHLU STORE",
    Address: "MPOMPENI VILLAGE  MHLOPHEKAZI A/A  NGCOBO",
    Latitude: "-31.7346",
    Longitude: "28.2172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2059",
    WardLookupId: "3054",
    VDNumber: "11570699",
    RegisteredPopulation: "494",
    VotingStationName: "NGUBENGCUKA PRIMARY JUNIOR SCHOOL",
    Address: "TYENI  VILLAGE  CLARKEBURY  AA  NGCOBO",
    Latitude: "-31.8127",
    Longitude: "28.3072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2060",
    WardLookupId: "3054",
    VDNumber: "11571162",
    RegisteredPopulation: "362",
    VotingStationName: "GONGQOZAYO SENIOR PRIMARY SCHOOL",
    Address: "NGQURHU VILLAGE  SITEBE A/A  NGCOBO",
    Latitude: "-31.7756",
    Longitude: "28.326",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2061",
    WardLookupId: "3054",
    VDNumber: "11571319",
    RegisteredPopulation: "332",
    VotingStationName: "MOSHI SENIOR PRIMARY SCHOOL",
    Address: "MBASHE VILLAGE  SITEBE  NGCOBO",
    Latitude: "-31.7291",
    Longitude: "28.3249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2062",
    WardLookupId: "3055",
    VDNumber: "11570150",
    RegisteredPopulation: "602",
    VotingStationName: "BASHEE PRIMARY JUNIOR SCHOOL",
    Address: "NTIBANE VILLAGE  NTIBANE A/A  NGCOBO",
    Latitude: "-31.6539",
    Longitude: "28.2841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2063",
    WardLookupId: "3055",
    VDNumber: "11570161",
    RegisteredPopulation: "950",
    VotingStationName: "AMAQWATI TRIBAL AUTHORITY",
    Address: "THEMBISA VILLAGE  NKONDLO A/A  ENGCOBO",
    Latitude: "-31.6629",
    Longitude: "28.2302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2064",
    WardLookupId: "3055",
    VDNumber: "11570172",
    RegisteredPopulation: "803",
    VotingStationName: "ZWELILUNGILE PRIMARY JUNIOR SCHOOL",
    Address: "XHIBENI  VILLAGE  NKONDLO  A/A  NGCOBO",
    Latitude: "-31.6852",
    Longitude: "28.2515",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2065",
    WardLookupId: "3055",
    VDNumber: "11570419",
    RegisteredPopulation: "493",
    VotingStationName: "DIDI PRIMARY JUNIOR SCHOOL",
    Address: "MTUKUMA VILLAGE  DIDI A/A  NGCOBO",
    Latitude: "-31.7069",
    Longitude: "28.2873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2066",
    WardLookupId: "3055",
    VDNumber: "11571184",
    RegisteredPopulation: "505",
    VotingStationName: "ZANENGQELE PRIMARY JUNIOR SCHOOL",
    Address: "THEMBISA VILLAGE  NKONDLO A/A  NGCOBO",
    Latitude: "-31.6618",
    Longitude: "28.2248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2067",
    WardLookupId: "3055",
    VDNumber: "11571195",
    RegisteredPopulation: "586",
    VotingStationName: "SIVIWE PRIMARY JUNIOR SCHOOL",
    Address: "NDIMA LOC  NTIBANENI A/A  NGCOBO",
    Latitude: "-31.6412",
    Longitude: "28.291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2068",
    WardLookupId: "3056",
    VDNumber: "11570071",
    RegisteredPopulation: "1012",
    VotingStationName: "SITOZA SENIOR SECONDARY SCHOOL",
    Address: "BOKLEIN VILLAGE  UPPER GQOBONCO  NGCOBO",
    Latitude: "-31.5949",
    Longitude: "28.1902",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2069",
    WardLookupId: "3056",
    VDNumber: "11570105",
    RegisteredPopulation: "803",
    VotingStationName: "LOWER GQOBONCO JUNIOR SECONDARY SCHOOL",
    Address: "BLANGWE VILLAGE  LOWER GQOBONCO A/A  NGCOBO",
    Latitude: "-31.6277",
    Longitude: "28.2593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2070",
    WardLookupId: "3056",
    VDNumber: "11570138",
    RegisteredPopulation: "473",
    VotingStationName: "MAJIJA PRIMARY JUNIOR SCHOOL",
    Address: "KWASIBONDA VILLAGE  MAJIJA A/A  NGCOBO",
    Latitude: "-31.6343",
    Longitude: "28.2808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2071",
    WardLookupId: "3056",
    VDNumber: "11570374",
    RegisteredPopulation: "588",
    VotingStationName: "TSHAPILE PRIMARY JUNIOR SCHOOL",
    Address: "TSHAPILE VILLAGE  NTAKA A/A  NGCOBO",
    Latitude: "-31.6321",
    Longitude: "28.2133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2072",
    WardLookupId: "3056",
    VDNumber: "11570622",
    RegisteredPopulation: "727",
    VotingStationName: "SANDILE PRIMARY JUNIOR SCHOOL",
    Address: "KOMKHULU VILLAGE  SANDILE A/A  NGCOBO",
    Latitude: "-31.5904",
    Longitude: "28.2971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2073",
    WardLookupId: "3056",
    VDNumber: "11571173",
    RegisteredPopulation: "378",
    VotingStationName: "YAWA PRIMARY JUNIOR SCHOOL",
    Address: "YAWA VILLAGE  YAWA A/A  NGCOBO",
    Latitude: "-31.594",
    Longitude: "28.2397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2074",
    WardLookupId: "3057",
    VDNumber: "11570026",
    RegisteredPopulation: "279",
    VotingStationName: "UPPER GQAGA PRIMARY JUNIOR SCHOOL",
    Address: "MBABAKAZI LOC  UPPER GQAGA A/A  NGCOBO",
    Latitude: "-31.5197",
    Longitude: "28.2119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2075",
    WardLookupId: "3057",
    VDNumber: "11570048",
    RegisteredPopulation: "282",
    VotingStationName: "MGUDU PRIMARY JUNIOR SCHOOL",
    Address: "MNCWASI LOC  MGUDU A/A  NGCOBO",
    Latitude: "-31.4592",
    Longitude: "28.3268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2076",
    WardLookupId: "3057",
    VDNumber: "11570059",
    RegisteredPopulation: "360",
    VotingStationName: "NJONGOZABANTU PRIMARY JUNIOR SCHOOL",
    Address: "XENTU LOC  SANDILE A/A  NGCOBO",
    Latitude: "-31.5604",
    Longitude: "28.2956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2077",
    WardLookupId: "3057",
    VDNumber: "11570352",
    RegisteredPopulation: "602",
    VotingStationName: "LOWER MNYOLO PRIMARY JUNIOR SCHOOL",
    Address: "NTONDO  LOC  MNYOLO A/A  NGCOBO",
    Latitude: "-31.5673",
    Longitude: "28.2681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2078",
    WardLookupId: "3057",
    VDNumber: "11570677",
    RegisteredPopulation: "532",
    VotingStationName: "ZABASA PRIMARY JUNIOR SCHOOL",
    Address: "ZABASA MISSION  ZABASA AA  NGCOBO",
    Latitude: "-31.448",
    Longitude: "28.2794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2079",
    WardLookupId: "3057",
    VDNumber: "11570745",
    RegisteredPopulation: "538",
    VotingStationName: "LOWER GQAGA SENIOR PRIMARY SCHOOL",
    Address: "SIQIKINI LOCATION  LOWER GQAGA A/A  NGCOBO",
    Latitude: "-31.5633",
    Longitude: "28.2161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2080",
    WardLookupId: "3057",
    VDNumber: "11570756",
    RegisteredPopulation: "191",
    VotingStationName: "JALISA  PRIMARY JUNIOR SCHOOL",
    Address: "MKHONKOTHO LOC  MKHONKOTHO A/A  NGCOBO",
    Latitude: "-31.4944",
    Longitude: "28.2885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2081",
    WardLookupId: "3057",
    VDNumber: "11570767",
    RegisteredPopulation: "494",
    VotingStationName: "DALIGQILI PRIMARY JUNIOR SCHOOL",
    Address: "MGUDU  LOC  MGUDU  A/A  ENGCOBO",
    Latitude: "-31.4796",
    Longitude: "28.3445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2082",
    WardLookupId: "3057",
    VDNumber: "11571331",
    RegisteredPopulation: "335",
    VotingStationName: "NODUMO GENERAL DEALER",
    Address: "LAZU LOC  MNYOLO A/A  NGCOBO",
    Latitude: "-31.5144",
    Longitude: "28.29",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2083",
    WardLookupId: "3057",
    VDNumber: "11571342",
    RegisteredPopulation: "424",
    VotingStationName: "NDLUNKULU PRIMARY JUNIOR SCHOOL",
    Address: "NDLUNKULU LOC  NDLUNKULU A/A  NGCOBO",
    Latitude: "-31.5438",
    Longitude: "28.3376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2084",
    WardLookupId: "3057",
    VDNumber: "11571410",
    RegisteredPopulation: "235",
    VotingStationName: "NTSINGA   SENIOR PRIMARY SCHOOL",
    Address: "NTSINGA LOCATION  ZABASA ADMINISTRATIVE AREA  ZABASA A/A",
    Latitude: "-31.4616",
    Longitude: "28.2512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2085",
    WardLookupId: "3058",
    VDNumber: "11570273",
    RegisteredPopulation: "534",
    VotingStationName: "MANZIMDAKA PRIMARY JUNIOR  SCHOOL",
    Address: "MMANGWENI  VILLAGE  SITOLENI  A/A  NGCOBO",
    Latitude: "-31.4335",
    Longitude: "28.1354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2086",
    WardLookupId: "3058",
    VDNumber: "11570611",
    RegisteredPopulation: "508",
    VotingStationName: "SINQUMENI SENIOR PRIMARY SCHOOL",
    Address: "SINQUMENI  LOC  SINQUMENI  AA  ENGCOBO",
    Latitude: "-31.3953",
    Longitude: "28.2622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2087",
    WardLookupId: "3058",
    VDNumber: "11570666",
    RegisteredPopulation: "520",
    VotingStationName: "MNGQWAZI STORE",
    Address: "LOWER SITOLENI LOC  SITOLENI  A/A  ENGCOBO",
    Latitude: "-31.5293",
    Longitude: "28.1453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2088",
    WardLookupId: "3058",
    VDNumber: "11570734",
    RegisteredPopulation: "538",
    VotingStationName: "GUBENXA JUNIOR SECONDARY SCHOOL",
    Address: "TALENI  LOC  GUBENXA  A/A  ENGCOBO",
    Latitude: "-31.4257",
    Longitude: "28.1753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2089",
    WardLookupId: "3058",
    VDNumber: "11571241",
    RegisteredPopulation: "301",
    VotingStationName: "DALUXOLO SENIOR PRIMARY SCHOOL",
    Address: "NCALUKENI LOC  SINQUMENI A/A  NGCOBO",
    Latitude: "-31.4184",
    Longitude: "28.2624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2090",
    WardLookupId: "3058",
    VDNumber: "11571252",
    RegisteredPopulation: "411",
    VotingStationName: "HLOPHEKAZI PRIMARY JUNIOR SCHOOL",
    Address: "HLOPHEKAZI LOC  UPPER GQAGA A/A  NGCOBO",
    Latitude: "-31.4903",
    Longitude: "28.2009",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2091",
    WardLookupId: "3058",
    VDNumber: "11571263",
    RegisteredPopulation: "362",
    VotingStationName: "MDANJELWA SENIOR PRIMARY SCHOOL",
    Address: "KOMKHULU LOC  UPPER GQAGA A/A  NGCOBO",
    Latitude: "-31.5381",
    Longitude: "28.1898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2092",
    WardLookupId: "652",
    VDNumber: "11250010",
    RegisteredPopulation: "1814",
    VotingStationName: "ELLIOT TOWN HALL",
    Address: "15 MACLEAR ROAD  ELLIOT",
    Latitude: "-31.3337",
    Longitude: "27.8497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2093",
    WardLookupId: "652",
    VDNumber: "11250043",
    RegisteredPopulation: "1079",
    VotingStationName: "COMMUNITY HALL",
    Address: "N/A  OLD LOCATION   ELLIOT",
    Latitude: "-31.3371",
    Longitude: "27.8592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2094",
    WardLookupId: "652",
    VDNumber: "11380014",
    RegisteredPopulation: "216",
    VotingStationName: "ELLIOT COUNTRY CLUB",
    Address: "BARKLEY ROAD  ELLIOT  ELLIOT",
    Latitude: "-31.3276",
    Longitude: "27.8382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2095",
    WardLookupId: "652",
    VDNumber: "11380025",
    RegisteredPopulation: "134",
    VotingStationName: "UMHLWAZI VILLAGE SCHOOL",
    Address: "A/A  MHLWAZI A/A  ELLIOT",
    Latitude: "-31.519",
    Longitude: "27.9807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2096",
    WardLookupId: "652",
    VDNumber: "11380047",
    RegisteredPopulation: "222",
    VotingStationName: "METHODIST CHURCH OF SOUTH GUBENXA",
    Address: "N/A  GUBENXA FARM  ELLIOT",
    Latitude: "-31.342",
    Longitude: "28.1892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2097",
    WardLookupId: "652",
    VDNumber: "11380058",
    RegisteredPopulation: "276",
    VotingStationName: "BOSSIESKLOOF FARM",
    Address: "CENGCU FARM  ELLIOT",
    Latitude: "-31.4728",
    Longitude: "27.9625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2098",
    WardLookupId: "653",
    VDNumber: "11250021",
    RegisteredPopulation: "1951",
    VotingStationName: "SIYAHLANGULA JUNIOR SECONDARY SCHOOL",
    Address: "PHOLA PARK  ELLIOT",
    Latitude: "-31.3224",
    Longitude: "27.822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2099",
    WardLookupId: "653",
    VDNumber: "11250032",
    RegisteredPopulation: "1616",
    VotingStationName: "MASIBAMBANE COMMUNITY HALL",
    Address: "SEJOESENG  VERGENOEG  ELLIOT",
    Latitude: "-31.3304",
    Longitude: "27.8278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2100",
    WardLookupId: "654",
    VDNumber: "11250054",
    RegisteredPopulation: "847",
    VotingStationName: "MASAKHE PRE-SCHOOL",
    Address: "ETANKINI  POLAR PARK  ELLIOT",
    Latitude: "-31.3195",
    Longitude: "27.8182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2101",
    WardLookupId: "654",
    VDNumber: "11460170",
    RegisteredPopulation: "338",
    VotingStationName: "SIFONONDILE JUNIOR SECONDARY SCHOOL",
    Address: "N/A  SIFONONDILE A/A  CALA",
    Latitude: "-31.452",
    Longitude: "27.6231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2102",
    WardLookupId: "654",
    VDNumber: "11460181",
    RegisteredPopulation: "275",
    VotingStationName: "QIBA JUNIOR SECONDARY SCHOOL",
    Address: "QIBA  CALA",
    Latitude: "-31.4319",
    Longitude: "27.686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2103",
    WardLookupId: "654",
    VDNumber: "11460192",
    RegisteredPopulation: "413",
    VotingStationName: "CALA PASS JUNIOR SECONDARY SCHOOL",
    Address: "CALA PASS  CALA",
    Latitude: "-31.4719",
    Longitude: "27.718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2104",
    WardLookupId: "654",
    VDNumber: "11460204",
    RegisteredPopulation: "306",
    VotingStationName: "MGWALANA JUNIOR SECONDARY SCHOOL",
    Address: "QIBA  CALA",
    Latitude: "-31.5228",
    Longitude: "27.7626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2105",
    WardLookupId: "654",
    VDNumber: "11460215",
    RegisteredPopulation: "653",
    VotingStationName: "MTHETHUVUMILE JUNIOR SECONDARY SCHOOL",
    Address: "ZIKHONKWANE A/A  QIBA  CALA",
    Latitude: "-31.4293",
    Longitude: "27.7285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2106",
    WardLookupId: "654",
    VDNumber: "11460226",
    RegisteredPopulation: "133",
    VotingStationName: "MOBILE VOTING STATION (SIPAFENI JSS)",
    Address: "SIPAFENI  ELLIOT  ELLIOT",
    Latitude: "-31.4599",
    Longitude: "27.6734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2107",
    WardLookupId: "654",
    VDNumber: "11460248",
    RegisteredPopulation: "176",
    VotingStationName: "LUSINDISO JUNIOR SECONDARY SCHOOL",
    Address: "LUSINDISO  SIFONONDILE  CALA",
    Latitude: "-31.4928",
    Longitude: "27.6496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2108",
    WardLookupId: "654",
    VDNumber: "11460462",
    RegisteredPopulation: "123",
    VotingStationName: "MLUNGISI JUNIOR SECONDARY SCHOOL",
    Address: "N/A  LAHLANGUBO A/A  CALA",
    Latitude: "-31.433",
    Longitude: "27.6601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2109",
    WardLookupId: "654",
    VDNumber: "11460473",
    RegisteredPopulation: "229",
    VotingStationName: "NOBUNTU JUNIOR SECONDARY SCHOOL",
    Address: "N/A  SIFONONDILE-QITI  CALA",
    Latitude: "-31.4799",
    Longitude: "27.614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2110",
    WardLookupId: "654",
    VDNumber: "11570644",
    RegisteredPopulation: "371",
    VotingStationName: "LINGELETHU JUNIOR SECONDARY SCHOOL",
    Address: "QIBA  CALA",
    Latitude: "-31.5191",
    Longitude: "27.8098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2111",
    WardLookupId: "655",
    VDNumber: "11230018",
    RegisteredPopulation: "1405",
    VotingStationName: "CALA TOWN HALL",
    Address: "458 UMTATA ROAD  CALA  CALA",
    Latitude: "-31.5236",
    Longitude: "27.6953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2112",
    WardLookupId: "655",
    VDNumber: "11230030",
    RegisteredPopulation: "976",
    VotingStationName: "LM SILINGELA SCHOOL",
    Address: "50 ISIDUBU STR  CALA  CALA",
    Latitude: "-31.5251",
    Longitude: "27.6894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2113",
    WardLookupId: "655",
    VDNumber: "11230063",
    RegisteredPopulation: "396",
    VotingStationName: "CALA INDOOR SPORT CENTRE",
    Address: "92 FETCHAN STREET  NDONDO SQUARE  CALA",
    Latitude: "-31.5238",
    Longitude: "27.6978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2114",
    WardLookupId: "656",
    VDNumber: "11230029",
    RegisteredPopulation: "1930",
    VotingStationName: "NDONDO SQUARE COMMUNITY HALL",
    Address: "1615 NDONDO SQUARE  EXTENSION 4  CALA",
    Latitude: "-31.5323",
    Longitude: "27.7038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2115",
    WardLookupId: "656",
    VDNumber: "11230041",
    RegisteredPopulation: "1135",
    VotingStationName: "KANYISA SPECIAL SCHOOL",
    Address: "BO STREET  CALA  CALA",
    Latitude: "-31.5297",
    Longitude: "27.6963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2116",
    WardLookupId: "656",
    VDNumber: "11460136",
    RegisteredPopulation: "798",
    VotingStationName: "ST.JOHNS APOSTOLIC CHURCH",
    Address: "N/A  MANZIMDAKA  CALA",
    Latitude: "-31.5564",
    Longitude: "27.6865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2117",
    WardLookupId: "657",
    VDNumber: "11460114",
    RegisteredPopulation: "498",
    VotingStationName: "TSENGIWE JUNIOR SECONDARY SCHOOL",
    Address: "TSENGIWE A/A  CALA",
    Latitude: "-31.5737",
    Longitude: "27.6577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2118",
    WardLookupId: "657",
    VDNumber: "11460169",
    RegisteredPopulation: "393",
    VotingStationName: "LOWER CALA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  LOWER CALA  A/A  CALA",
    Latitude: "-31.517",
    Longitude: "27.6217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2119",
    WardLookupId: "657",
    VDNumber: "11460259",
    RegisteredPopulation: "319",
    VotingStationName: "MNXE JUNIOR SECONDARY SCHOOL",
    Address: "MNXE  CALA",
    Latitude: "-31.5629",
    Longitude: "27.6247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2120",
    WardLookupId: "657",
    VDNumber: "11460260",
    RegisteredPopulation: "383",
    VotingStationName: "UPPER CALA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  UPPER MNXE A/A  CALA",
    Latitude: "-31.5283",
    Longitude: "27.5613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2121",
    WardLookupId: "657",
    VDNumber: "11460495",
    RegisteredPopulation: "134",
    VotingStationName: "ANGLICAN CHURCH",
    Address: "N/A  TIWANI A/A  CALA",
    Latitude: "-31.5123",
    Longitude: "27.5893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2122",
    WardLookupId: "657",
    VDNumber: "11460507",
    RegisteredPopulation: "277",
    VotingStationName: "LAPESINI METHODIST CHURCH OF S.A",
    Address: "N/A  MNXE - LAPESINI  CALA",
    Latitude: "-31.5578",
    Longitude: "27.6121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2123",
    WardLookupId: "657",
    VDNumber: "11460518",
    RegisteredPopulation: "615",
    VotingStationName: "GUATA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  CALA RESERVE  CALA",
    Latitude: "-31.5286",
    Longitude: "27.6555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2124",
    WardLookupId: "657",
    VDNumber: "11460529",
    RegisteredPopulation: "271",
    VotingStationName: "MANZANA CRECHE",
    Address: "N/A  MANZANA A/A  CALA",
    Latitude: "-31.5511",
    Longitude: "27.6456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2125",
    WardLookupId: "657",
    VDNumber: "11460530",
    RegisteredPopulation: "343",
    VotingStationName: "PHAKAMANI JUNIOR SECONDARY SCHOOL",
    Address: "N/A  TSENGIWE  CALA",
    Latitude: "-31.5853",
    Longitude: "27.6495",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2126",
    WardLookupId: "658",
    VDNumber: "11460057",
    RegisteredPopulation: "463",
    VotingStationName: "INDWANA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  INDWANA A/A  CALA",
    Latitude: "-31.6086",
    Longitude: "27.4987",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2127",
    WardLookupId: "658",
    VDNumber: "11460158",
    RegisteredPopulation: "756",
    VotingStationName: "MCEULA SENIOR SECONDARY SCHOOL",
    Address: "MCEULA VILLAGE, UPPER CALA  CALA",
    Latitude: "-31.5168",
    Longitude: "27.5246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2128",
    WardLookupId: "658",
    VDNumber: "11460293",
    RegisteredPopulation: "225",
    VotingStationName: "NGXUMZA JUNIOR SECONDARY SCHOOL",
    Address: "ASKEATON VILLAGE  CALA",
    Latitude: "-31.6118",
    Longitude: "27.4687",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2129",
    WardLookupId: "658",
    VDNumber: "11460316",
    RegisteredPopulation: "512",
    VotingStationName: "UPPER NDWANA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  UPPER INDWANA VILLAGE  CALA",
    Latitude: "-31.5748",
    Longitude: "27.509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2130",
    WardLookupId: "658",
    VDNumber: "11460552",
    RegisteredPopulation: "435",
    VotingStationName: "RABBELSKLOOF JUNIOR SECONDARY SCHOOL",
    Address: "RABBELSKLOOF VILLAGE, UPPER CALA  CALA",
    Latitude: "-31.4763",
    Longitude: "27.562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2131",
    WardLookupId: "658",
    VDNumber: "11460563",
    RegisteredPopulation: "361",
    VotingStationName: "NONKANYISO JUNIOR SECONDARY SCHOOL",
    Address: "MTYATYA VILLAGE, UPPER CALA  CALA (EMALAHLENI)",
    Latitude: "-31.4844",
    Longitude: "27.501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2132",
    WardLookupId: "658",
    VDNumber: "11460608",
    RegisteredPopulation: "334",
    VotingStationName: "NONTEMBISO JUNIOR SECONDARY SCHOOL",
    Address: "N/A  UPPER INDWANA A/A  CALA",
    Latitude: "-31.5638",
    Longitude: "27.5282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2133",
    WardLookupId: "659",
    VDNumber: "11460024",
    RegisteredPopulation: "645",
    VotingStationName: "SEPLAN MISSION JUNIOR SECONDARY SCHOOL",
    Address: "SEPLAN MISSION  CALA",
    Latitude: "-31.6577",
    Longitude: "27.4557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2134",
    WardLookupId: "659",
    VDNumber: "11460035",
    RegisteredPopulation: "546",
    VotingStationName: "A M ZANTSI SENIOR SECONDARY SCHOOL",
    Address: "N/A  MANZIMAHLE A/A  CALA",
    Latitude: "-31.6251",
    Longitude: "27.5576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2135",
    WardLookupId: "659",
    VDNumber: "11460046",
    RegisteredPopulation: "452",
    VotingStationName: "B.B.MDLEDLE JUNIOR SECONDARY SCHOOL",
    Address: "N/A  ASKEATON A/A  CALA",
    Latitude: "-31.6265",
    Longitude: "27.4605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2136",
    WardLookupId: "659",
    VDNumber: "11460091",
    RegisteredPopulation: "273",
    VotingStationName: "UPPER LUFUTA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  UPPER LUFUTA  CALA",
    Latitude: "-31.6327",
    Longitude: "27.6106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2137",
    WardLookupId: "659",
    VDNumber: "11460305",
    RegisteredPopulation: "543",
    VotingStationName: "UPPER MTHINGWEVU JUNIOR SECONDARY SCHOOL",
    Address: "UPPER MTHINGWEVU A/A  CALA",
    Latitude: "-31.6985",
    Longitude: "27.5738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2138",
    WardLookupId: "659",
    VDNumber: "11460327",
    RegisteredPopulation: "208",
    VotingStationName: "UPPER LANGANCI JUNIOR SECONDARY SCHOOL",
    Address: "UPPER LANGANCI A/A  UPPER LANGANCI  CALA",
    Latitude: "-31.6677",
    Longitude: "27.5892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2139",
    WardLookupId: "659",
    VDNumber: "11460394",
    RegisteredPopulation: "236",
    VotingStationName: "SIGWELA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  MANZIMAHLE A/A  CALA",
    Latitude: "-31.6158",
    Longitude: "27.5829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2140",
    WardLookupId: "659",
    VDNumber: "11460574",
    RegisteredPopulation: "142",
    VotingStationName: "NDYAVU PRE SCHOOL",
    Address: "N/A  NDYAVU  CALA",
    Latitude: "-31.6429",
    Longitude: "27.5856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2141",
    WardLookupId: "660",
    VDNumber: "11460068",
    RegisteredPopulation: "208",
    VotingStationName: "LOWER LANGANCI JUNIOR SECONDARY SCHOOL",
    Address: "LOWER LANGANCI A/A  CALA",
    Latitude: "-31.6955",
    Longitude: "27.6405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2142",
    WardLookupId: "660",
    VDNumber: "11460079",
    RegisteredPopulation: "332",
    VotingStationName: "LOWER LUFUTA JUNIOR SECONDARY SCHOOL",
    Address: "LOWER LUFUTA  CALA",
    Latitude: "-31.6543",
    Longitude: "27.6501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2143",
    WardLookupId: "660",
    VDNumber: "11460103",
    RegisteredPopulation: "263",
    VotingStationName: "HOTA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  HOTA  CALA",
    Latitude: "-31.6464",
    Longitude: "27.6827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2144",
    WardLookupId: "660",
    VDNumber: "11460125",
    RegisteredPopulation: "758",
    VotingStationName: "MBENGE JUNIOR SECONDARY SCHOOL",
    Address: "N/A  MBENGE A/A  CALA",
    Latitude: "-31.6052",
    Longitude: "27.6887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2145",
    WardLookupId: "660",
    VDNumber: "11460338",
    RegisteredPopulation: "295",
    VotingStationName: "NYALASA JUNIOR SECONDARY SCHOOL",
    Address: "NYALASA A/A  CALA",
    Latitude: "-31.6874",
    Longitude: "27.6623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2146",
    WardLookupId: "660",
    VDNumber: "11460349",
    RegisteredPopulation: "275",
    VotingStationName: "MBODLANA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  MBODLANA VILLGE  CALA",
    Latitude: "-31.6388",
    Longitude: "27.6587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2147",
    WardLookupId: "660",
    VDNumber: "11460406",
    RegisteredPopulation: "230",
    VotingStationName: "MASINCEDISE CHRECHE",
    Address: "N/A  LOWER LUFUTA ,ROLLWEST  CALA",
    Latitude: "-31.6665",
    Longitude: "27.6455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2148",
    WardLookupId: "660",
    VDNumber: "11460417",
    RegisteredPopulation: "161",
    VotingStationName: "NOKWAKHA PRE-SCHOOL",
    Address: "N/A  MAHLUNGULU A/A  CALA",
    Latitude: "-31.7032",
    Longitude: "27.6731",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2149",
    WardLookupId: "660",
    VDNumber: "11460428",
    RegisteredPopulation: "197",
    VotingStationName: "PANATYIPHU HOUSE",
    Address: "N/A  PANATYIPHU A/A  CALA",
    Latitude: "-31.6709",
    Longitude: "27.6786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2150",
    WardLookupId: "660",
    VDNumber: "11460439",
    RegisteredPopulation: "154",
    VotingStationName: "MBEULA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  MBEULA A/A  CALA",
    Latitude: "-31.6784",
    Longitude: "27.71",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2151",
    WardLookupId: "660",
    VDNumber: "11460585",
    RegisteredPopulation: "201",
    VotingStationName: "SIGANGA HOUSE",
    Address: "A/A  ESIGANGENI A/A  CALA",
    Latitude: "-31.6578",
    Longitude: "27.7126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2152",
    WardLookupId: "3059",
    VDNumber: "10800010",
    RegisteredPopulation: "388",
    VotingStationName: "HENDERSON FARMERS HALL",
    Address: "HENDERSON VILLAGE  HENDERSON  LUKANJI",
    Latitude: "-32.3156",
    Longitude: "27.3378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2153",
    WardLookupId: "3059",
    VDNumber: "10800065",
    RegisteredPopulation: "81",
    VotingStationName: "COVERSIDE  PRIMARY SCHOOL",
    Address: "COVERSIDE  GWATYU FARMS  QUEENSTOWN DISTRICT",
    Latitude: "-32.2471",
    Longitude: "27.2668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2154",
    WardLookupId: "3059",
    VDNumber: "11070504",
    RegisteredPopulation: "825",
    VotingStationName: "MKONJANA JUNIOR SECONDARY SCHOOL",
    Address: "MKONYANA LOCATION  HALA A/A  BOLOTWA/COFIMVABA",
    Latitude: "-31.9721",
    Longitude: "27.2722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2155",
    WardLookupId: "3059",
    VDNumber: "11070975",
    RegisteredPopulation: "279",
    VotingStationName: "DLAKAVU JUNIOR SECONDARY SCHOOL",
    Address: "DLAKAVU LOCATION  BOLOTHWA A/A  COFIMVABA",
    Latitude: "-31.9744",
    Longitude: "27.2461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2156",
    WardLookupId: "3059",
    VDNumber: "11070986",
    RegisteredPopulation: "697",
    VotingStationName: "BOLOTWA SENIOR PRIMARY SCHOOL",
    Address: "SIQHABHENI LOCATION  BOLOTWA A/A  COFIMVABA",
    Latitude: "-31.9864",
    Longitude: "27.1845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2157",
    WardLookupId: "3059",
    VDNumber: "11080639",
    RegisteredPopulation: "334",
    VotingStationName: "SOBANTU JUNIOR SECONDARY SCHOOL",
    Address: "FORDYCE FARM  QUEENSTOWN",
    Latitude: "-32.0964",
    Longitude: "27.2195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2158",
    WardLookupId: "3059",
    VDNumber: "11080640",
    RegisteredPopulation: "295",
    VotingStationName: "NONIBE JUNIOR SECONDARY SCHOOL",
    Address: "GWATYU FARMS  QUEENSTOWN",
    Latitude: "-32.038",
    Longitude: "27.2948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2159",
    WardLookupId: "3059",
    VDNumber: "11080910",
    RegisteredPopulation: "383",
    VotingStationName: "SIYAVUYA JUNIOR SECONDARY SCHOOL",
    Address: "OATHAY  THEMBANI  QUEENSTOWN",
    Latitude: "-32.0066",
    Longitude: "27.3222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2160",
    WardLookupId: "3059",
    VDNumber: "11160010",
    RegisteredPopulation: "450",
    VotingStationName: "ST BEDS",
    Address: "TYLDEN  QUEENSTOWN  QUEENSTOWN",
    Latitude: "-32.1091",
    Longitude: "27.0732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2161",
    WardLookupId: "3060",
    VDNumber: "11070098",
    RegisteredPopulation: "1001",
    VotingStationName: "NOLUKHANYO JUNIOR PRIMARY SCHOOL",
    Address: "2993 ILINGE  ILINGE LOCATION  QUEENSTOWN DISTRICT",
    Latitude: "-31.9807",
    Longitude: "27.0537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2162",
    WardLookupId: "3060",
    VDNumber: "11070346",
    RegisteredPopulation: "584",
    VotingStationName: "LINGELIHLE SENIOR SECONDARY SCHOOL",
    Address: "ILINGE LOCATION  QUEENSTOWN DISTRICT",
    Latitude: "-31.977",
    Longitude: "27.0462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2163",
    WardLookupId: "3060",
    VDNumber: "11070357",
    RegisteredPopulation: "478",
    VotingStationName: "PHILGET MZAZI JUNIOR SECONDARY SCHOOL",
    Address: "ILINGE LOCATION  QUEENSTOWN DISTRICT",
    Latitude: "-31.9747",
    Longitude: "27.0497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2164",
    WardLookupId: "3060",
    VDNumber: "11071000",
    RegisteredPopulation: "1376",
    VotingStationName: "MASITHEMBE SENIOR PRIMARY SCHOOL",
    Address: "ZWIDE  ILINGE  QUEENSTOWN",
    Latitude: "-31.9807",
    Longitude: "27.0553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2165",
    WardLookupId: "3061",
    VDNumber: "11070021",
    RegisteredPopulation: "864",
    VotingStationName: "MTEBHELE COMBINED SCHOOL",
    Address: "MACIBINI A/A  MACHIBINI  QUEENSTOWN DISTRICT",
    Latitude: "-31.9646",
    Longitude: "27.0744",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2166",
    WardLookupId: "3061",
    VDNumber: "11070515",
    RegisteredPopulation: "358",
    VotingStationName: "MTHWAKU JUNIOR SECONDARY SCHOOL",
    Address: "MTHWAKU LOCATION  BOLOTWA A/A  COFIMVABA",
    Latitude: "-31.95",
    Longitude: "27.1554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2167",
    WardLookupId: "3061",
    VDNumber: "11070526",
    RegisteredPopulation: "539",
    VotingStationName: "MANZEZULU HIGH SCHOOL",
    Address: "MACHIBINI  QUEENSTOWN DISTRICT",
    Latitude: "-31.9207",
    Longitude: "27.029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2168",
    WardLookupId: "3061",
    VDNumber: "11070762",
    RegisteredPopulation: "537",
    VotingStationName: "XHUMABHOKWE JUNIOR SECONDARY SCHOOL",
    Address: "MACHIBINI  QUEENSTOWN DISTRICT",
    Latitude: "-31.896",
    Longitude: "27.0354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2169",
    WardLookupId: "3061",
    VDNumber: "11070829",
    RegisteredPopulation: "299",
    VotingStationName: "NTSHANGA JUNIOR SECONDARY SCHOOL",
    Address: "MACHIBINI A/A  MACHIBINI  QUEENSTOWN",
    Latitude: "-31.9432",
    Longitude: "27.0847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2170",
    WardLookupId: "3061",
    VDNumber: "11070997",
    RegisteredPopulation: "498",
    VotingStationName: "KLEINBOOI JUNIOR SECONDARY SCHOOL",
    Address: "MACHIBINI  QUEENSTOWN",
    Latitude: "-31.9232",
    Longitude: "27.0372",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2171",
    WardLookupId: "3061",
    VDNumber: "11071853",
    RegisteredPopulation: "175",
    VotingStationName: "PETERSDALE S PRIMARY SCHOOL",
    Address: "TYUTYUTYU   TYUTYUTYU VILLAGE  MACHIBINI AA",
    Latitude: "-31.9665",
    Longitude: "27.0978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2172",
    WardLookupId: "3062",
    VDNumber: "11030386",
    RegisteredPopulation: "1424",
    VotingStationName: "BHEKELA COMMUNITY HALL",
    Address: "JAXA  EZIBELENI  KOMANI",
    Latitude: "-31.9217",
    Longitude: "26.9662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2173",
    WardLookupId: "3062",
    VDNumber: "11070100",
    RegisteredPopulation: "779",
    VotingStationName: "ILINGE COMMUNITY HALL",
    Address: "ILINGE  ILINGE TOWNSHIP  QUEENSTOWN DISTRICT",
    Latitude: "-31.9764",
    Longitude: "27.0397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2174",
    WardLookupId: "3062",
    VDNumber: "11070111",
    RegisteredPopulation: "732",
    VotingStationName: "THEMBEKILE LOWER PRIMARY SCHOOL",
    Address: "ILINGE LOCATION  QUEENSTOWN DISTRICT",
    Latitude: "-31.9795",
    Longitude: "27.0377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2175",
    WardLookupId: "3062",
    VDNumber: "11160188",
    RegisteredPopulation: "339",
    VotingStationName: "BIRCH FARMS",
    Address: "BIRCH FARMS  QUEENSTOWN",
    Latitude: "-31.9586",
    Longitude: "26.9763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2176",
    WardLookupId: "3063",
    VDNumber: "11030128",
    RegisteredPopulation: "2035",
    VotingStationName: "IKHALA PUBLIC FET COLLEGE",
    Address: "EZIBELENI LOCATION  EZIBELENI  QUEENSTOWN",
    Latitude: "-31.9013",
    Longitude: "26.9704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2177",
    WardLookupId: "3063",
    VDNumber: "11030140",
    RegisteredPopulation: "1272",
    VotingStationName: "MOROVIAN CHURCH CRECHE",
    Address: "EZIBELENI  QUEENSTOWN",
    Latitude: "-31.9138",
    Longitude: "26.9643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2178",
    WardLookupId: "3064",
    VDNumber: "11030218",
    RegisteredPopulation: "836",
    VotingStationName: "APOSTOLIC FAITH MISSION-EZIBELENI ASSEMBLE",
    Address: "ZONE 3  EZIBELENI  QUEENSTOWN",
    Latitude: "-31.9148",
    Longitude: "26.9703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2179",
    WardLookupId: "3064",
    VDNumber: "11030229",
    RegisteredPopulation: "469",
    VotingStationName: "TENT AT CHRIS HANI SQUATTER CAMP",
    Address: "ZONE 3  EZIBELENI  QUEENSTOWN",
    Latitude: "-31.9122",
    Longitude: "26.976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2180",
    WardLookupId: "3064",
    VDNumber: "11030230",
    RegisteredPopulation: "2003",
    VotingStationName: "UNATHI MKEFA PUBLIC PRIMARY SCHOOL",
    Address: "UNATHI MKEFA  EZIBELENI  QUEENSTOWN",
    Latitude: "-31.9179",
    Longitude: "26.9675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2181",
    WardLookupId: "3064",
    VDNumber: "11070537",
    RegisteredPopulation: "513",
    VotingStationName: "NOMVUYO JUNIOR SECONDARY SCHOOL",
    Address: "MACHIBINI  MACHIBINI VILLAGE  QUEENSTOWN DISTRICT",
    Latitude: "-31.9225",
    Longitude: "27.0081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2182",
    WardLookupId: "3065",
    VDNumber: "11030139",
    RegisteredPopulation: "1120",
    VotingStationName: "EZIBELENI COMMUNITY HALL",
    Address: "ZONE 1  EZIBELENI  EZIBELENI",
    Latitude: "-31.9015",
    Longitude: "26.9579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2183",
    WardLookupId: "3065",
    VDNumber: "11030195",
    RegisteredPopulation: "1137",
    VotingStationName: "ZONE 1TENT",
    Address: "DR XUMA  EZIBELENI  QUEENSTOWN",
    Latitude: "-31.8977",
    Longitude: "26.9517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2184",
    WardLookupId: "3065",
    VDNumber: "11030207",
    RegisteredPopulation: "911",
    VotingStationName: "SIXISHE JUNIOR SECONDARY SCHOOL",
    Address: "1216 MKEFA STREET  ZONE 1  EZIBELENI",
    Latitude: "-31.9081",
    Longitude: "26.9565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2185",
    WardLookupId: "3065",
    VDNumber: "11030397",
    RegisteredPopulation: "338",
    VotingStationName: "LONWABO JUNIOR PRIMARY SCHOOL",
    Address: "EZIBELENI  QUEENSTOWN",
    Latitude: "-31.9038",
    Longitude: "26.9545",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2186",
    WardLookupId: "3066",
    VDNumber: "11030151",
    RegisteredPopulation: "1921",
    VotingStationName: "HOWARD BEN MAZWI JUNIOR SECONDARY SCHOOL",
    Address: "142 ZONE 2  EZIBELENI  QUEENSTOWN",
    Latitude: "-31.9077",
    Longitude: "26.9636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2187",
    WardLookupId: "3066",
    VDNumber: "11030173",
    RegisteredPopulation: "1486",
    VotingStationName: "MINAH T SOGA  SCHOOL",
    Address: "1071 ZONE 2  EZIBELENI  QUEENSTOWN",
    Latitude: "-31.9153",
    Longitude: "26.9544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2188",
    WardLookupId: "3067",
    VDNumber: "11030094",
    RegisteredPopulation: "1087",
    VotingStationName: "TWELVE APOSTLES CHURCH IN CHRIST",
    Address: "QUEENSTOWN  ",
    Latitude: "-31.9011",
    Longitude: "26.8819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2189",
    WardLookupId: "3067",
    VDNumber: "11030285",
    RegisteredPopulation: "1020",
    VotingStationName: "SUNSHINE VILLAGE OLD AGE HOME",
    Address: "LAURIE DASH WOOD  QUEENSTOWN DISTRICT",
    Latitude: "-31.9121",
    Longitude: "26.8935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2190",
    WardLookupId: "3067",
    VDNumber: "11030409",
    RegisteredPopulation: "595",
    VotingStationName: "WSU QUEESTOWN CAMPUS",
    Address: "GREY STREET  QUEENSTOWN CENTRAL  QUEENSTOWN",
    Latitude: "-31.8958",
    Longitude: "26.8833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2191",
    WardLookupId: "3068",
    VDNumber: "11030106",
    RegisteredPopulation: "1110",
    VotingStationName: "JJ SERFONTEIN SCHOOL",
    Address: "WATERBOK ROAD  MADERIA PARK  QUEENSTOWN DISTRICT",
    Latitude: "-31.8905",
    Longitude: "26.8492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2192",
    WardLookupId: "3068",
    VDNumber: "11030117",
    RegisteredPopulation: "2644",
    VotingStationName: "QUEENS COLLEGE JUNIOR SCHOOL HALL",
    Address: "14 HAIG AVENUE  TOP TOWN  QUEENSTOWN DISTRICT",
    Latitude: "-31.885",
    Longitude: "26.8762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2193",
    WardLookupId: "3068",
    VDNumber: "11030184",
    RegisteredPopulation: "684",
    VotingStationName: "IKHALA PUBLIC FET COLLEGE (QUEENSTOWN)",
    Address: "ZEILER STREET  QUEENSTOWN CENTRAL  QUEENSTOWN",
    Latitude: "-31.8932",
    Longitude: "26.8712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2194",
    WardLookupId: "3068",
    VDNumber: "11160144",
    RegisteredPopulation: "223",
    VotingStationName: "IDLEWILD FARM.",
    Address: "R61 RD TO TARKASTAD  QUEENSTOWN WEST  QUEENSTOWN",
    Latitude: "-31.9078",
    Longitude: "26.8183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2195",
    WardLookupId: "3069",
    VDNumber: "11030083",
    RegisteredPopulation: "1335",
    VotingStationName: "INDOOR SPORTS COMPLEX",
    Address: "VICTORIA ROAD  MLUNGISI  QUEENSTOWN",
    Latitude: "-31.904",
    Longitude: "26.8716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2196",
    WardLookupId: "3069",
    VDNumber: "11030308",
    RegisteredPopulation: "825",
    VotingStationName: "AFRICAN GOSPEL CHURCH",
    Address: "312 GANTANA ST.  BEDE LOCATION  QUEENSTOWN DISTRICT",
    Latitude: "-31.9063",
    Longitude: "26.8662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2197",
    WardLookupId: "3069",
    VDNumber: "11030319",
    RegisteredPopulation: "786",
    VotingStationName: "LOUIS REX PRIMARY SCHOOL",
    Address: "JOHN JASON STREET  NEW REST  QUEENSTOWN",
    Latitude: "-31.9096",
    Longitude: "26.8738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2198",
    WardLookupId: "3070",
    VDNumber: "11030320",
    RegisteredPopulation: "1269",
    VotingStationName: "LUKHANJI PUBLIC PRIMARY SCHOOL",
    Address: "PAMBO STREET  QUEENSTOWN  QUEENSTOWN DISTRICT",
    Latitude: "-31.9157",
    Longitude: "26.8649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2199",
    WardLookupId: "3070",
    VDNumber: "11030353",
    RegisteredPopulation: "955",
    VotingStationName: "APOSTOLIC CHURCH",
    Address: "ALOEVALE  QUEENSTOWN",
    Latitude: "-31.9217",
    Longitude: "26.8632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2200",
    WardLookupId: "3070",
    VDNumber: "11030364",
    RegisteredPopulation: "909",
    VotingStationName: "GUMMY BEARS PRE-SCHOOL",
    Address: "ALOEVALE PARK  QUEENSTOWN",
    Latitude: "-31.9192",
    Longitude: "26.8633",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2201",
    WardLookupId: "3071",
    VDNumber: "11030049",
    RegisteredPopulation: "1186",
    VotingStationName: "MARIA LOUW HIGH SCHOOL",
    Address: "AIRFIELD ROAD  VICTORIA PARK  QUEENSTOWN",
    Latitude: "-31.9223",
    Longitude: "26.8728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2202",
    WardLookupId: "3071",
    VDNumber: "11030050",
    RegisteredPopulation: "827",
    VotingStationName: "ASHLEY WYNGAARDT COMMUNITY HALL",
    Address: "MARIGOLD STREET  VICTORIA PARK  QUEENSTOWN",
    Latitude: "-31.9202",
    Longitude: "26.866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2203",
    WardLookupId: "3071",
    VDNumber: "11030162",
    RegisteredPopulation: "1957",
    VotingStationName: "BETHELI METHODIST CHURCH",
    Address: "NEWVALLE  QUEENSTOWN",
    Latitude: "-31.9238",
    Longitude: "26.8588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2204",
    WardLookupId: "3072",
    VDNumber: "11030027",
    RegisteredPopulation: "1944",
    VotingStationName: "APOSTOLIC FAITH REVIVAL   MISSION",
    Address: "HARARI  UNIFOUND  QUEENSTOWN",
    Latitude: "-31.9169",
    Longitude: "26.8559",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2205",
    WardLookupId: "3072",
    VDNumber: "11030263",
    RegisteredPopulation: "1683",
    VotingStationName: "NOMZAMO CRUSADE TENT",
    Address: "MBEKI STREET  NOMZAMO  QUEENSTOWN",
    Latitude: "-31.9198",
    Longitude: "26.8494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2206",
    WardLookupId: "3072",
    VDNumber: "11030331",
    RegisteredPopulation: "671",
    VotingStationName: "SIZIZAMELE EDUCARE CENTRE",
    Address: "UNIFOUND  MLUNGISI  QUEENSTOWN",
    Latitude: "-31.9185",
    Longitude: "26.8531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2207",
    WardLookupId: "3073",
    VDNumber: "11030016",
    RegisteredPopulation: "1342",
    VotingStationName: "EDLELWENI PRIMARY SCHOOL",
    Address: "KHAYELITSHA  QUEENSTOWN",
    Latitude: "-31.9227",
    Longitude: "26.8532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2208",
    WardLookupId: "3073",
    VDNumber: "11030241",
    RegisteredPopulation: "578",
    VotingStationName: "JOHN NOAH PUBLIC SCHOOL",
    Address: "PAMBO STREET  MLUNGISI  QUEENSTOWN",
    Latitude: "-31.924",
    Longitude: "26.8551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2209",
    WardLookupId: "3073",
    VDNumber: "11030252",
    RegisteredPopulation: "1448",
    VotingStationName: "LATHITHA EDUCARE CENTRE",
    Address: "MAHLANGU  NOMZAMO  QUEENSTOWN",
    Latitude: "-31.9215",
    Longitude: "26.8468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2210",
    WardLookupId: "3073",
    VDNumber: "11160021",
    RegisteredPopulation: "201",
    VotingStationName: "KIEBRIDGE FARM SCOOL",
    Address: "SLOVO TRUST  QUEENSTOWN",
    Latitude: "-32.0975",
    Longitude: "26.8508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2211",
    WardLookupId: "3074",
    VDNumber: "11030038",
    RegisteredPopulation: "2089",
    VotingStationName: "MPENDULO PRIMARY SCHOOL",
    Address: "1874 PELEM ROAD  MLUNGISI  QUEENSTOWN",
    Latitude: "-31.9074",
    Longitude: "26.861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2212",
    WardLookupId: "3074",
    VDNumber: "11030274",
    RegisteredPopulation: "603",
    VotingStationName: "WESTBOURNE PLAYGROUNDS  TENT",
    Address: "WESTHOF  WESTBOURNE  QUEENSTOWN",
    Latitude: "-31.8983",
    Longitude: "26.8578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2213",
    WardLookupId: "3074",
    VDNumber: "11030375",
    RegisteredPopulation: "917",
    VotingStationName: "QUEENSTOWN CHILD CARE CENTRE",
    Address: "MLUNGISI LOCATION  QUEENSTOWN",
    Latitude: "-31.9163",
    Longitude: "26.8511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2214",
    WardLookupId: "3075",
    VDNumber: "11030061",
    RegisteredPopulation: "1244",
    VotingStationName: "MLUNGISI COMMUNITY HALL",
    Address: "TOP STREET  MLUNGISI  QUEENSTOWN",
    Latitude: "-31.9112",
    Longitude: "26.8634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2215",
    WardLookupId: "3075",
    VDNumber: "11030072",
    RegisteredPopulation: "1574",
    VotingStationName: "NONESI HIGHER PRIMARY SCHOOL",
    Address: "TOP STREET  MLUNGISI  QUEENSTOWN",
    Latitude: "-31.9111",
    Longitude: "26.8675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2216",
    WardLookupId: "3075",
    VDNumber: "11030296",
    RegisteredPopulation: "1024",
    VotingStationName: "WESTBOURNE OPEN SPACE.",
    Address: "BRIDGE  WESTBOURNE  QUEENSTOWN",
    Latitude: "-31.8968",
    Longitude: "26.8619",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2217",
    WardLookupId: "3076",
    VDNumber: "11060121",
    RegisteredPopulation: "116",
    VotingStationName: "BONGOLO FARM SCHOOL",
    Address: "ALOE GROOVE  QUEENSTOWN  QUEENSTOWN",
    Latitude: "-31.8538",
    Longitude: "26.9238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2218",
    WardLookupId: "3076",
    VDNumber: "11070043",
    RegisteredPopulation: "436",
    VotingStationName: "LAVELILANGA SENIOR SECONDARY SCHOOL",
    Address: "ZINGQUTHU VILLAGE  ZINGQUTHU  QUEENSTOWN DISTRICT",
    Latitude: "-31.752",
    Longitude: "26.8601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2219",
    WardLookupId: "3076",
    VDNumber: "11070751",
    RegisteredPopulation: "307",
    VotingStationName: "NKONKOBE JUNIOR SECONDARY SCHOOL",
    Address: "QOQODALA A/A  QOQODALA  QUEENSTOWN DISRTRICT",
    Latitude: "-31.7293",
    Longitude: "26.8696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2220",
    WardLookupId: "3076",
    VDNumber: "11071842",
    RegisteredPopulation: "353",
    VotingStationName: "GCINA JSSS",
    Address: "GCINA  QUEENSTOWN",
    Latitude: "-31.7676",
    Longitude: "26.8941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2221",
    WardLookupId: "3076",
    VDNumber: "11160100",
    RegisteredPopulation: "944",
    VotingStationName: "TOISEKRAAL PRIMARY SCHOOL",
    Address: "TOISEKRAAL VILLAGE  QUEENSTOWN",
    Latitude: "-31.8297",
    Longitude: "26.7427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2222",
    WardLookupId: "3076",
    VDNumber: "11160111",
    RegisteredPopulation: "1004",
    VotingStationName: "LESSEYTON COMMUNITY HALL",
    Address: "LESSEYTON  QUEENSTOWN",
    Latitude: "-31.8484",
    Longitude: "26.7776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2223",
    WardLookupId: "3076",
    VDNumber: "11160155",
    RegisteredPopulation: "270",
    VotingStationName: "LONWABO EDUCARE CENTRE",
    Address: "TABATA  LESSEYTON  QUEENSTOWN",
    Latitude: "-31.8124",
    Longitude: "26.7347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2224",
    WardLookupId: "3076",
    VDNumber: "11160166",
    RegisteredPopulation: "186",
    VotingStationName: "NDLOVUKAZI HIGH SCHOOL",
    Address: "NDLOVUKAZI  LESSYTON  QUEENSTOWN",
    Latitude: "-31.8437",
    Longitude: "26.782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2225",
    WardLookupId: "3076",
    VDNumber: "11160201",
    RegisteredPopulation: "492",
    VotingStationName: "ZOLA PRE SCHOOL",
    Address: "EZOLA  ZOLA VILLAGE  QUEENSTOWN",
    Latitude: "-31.8468",
    Longitude: "26.7617",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2226",
    WardLookupId: "3077",
    VDNumber: "11160032",
    RegisteredPopulation: "1386",
    VotingStationName: "NKOS`EMNTU PUBLIC SCHOOL",
    Address: "TAMBO  THAMBO VILLAGE  WHITTLESEA",
    Latitude: "-32.1364",
    Longitude: "26.8069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2227",
    WardLookupId: "3077",
    VDNumber: "11160043",
    RegisteredPopulation: "1022",
    VotingStationName: "VUSELELA COMBINED SCHOOL",
    Address: "MC BRIDE VILLAGE  QUEENSTOWN DISTRICT",
    Latitude: "-32.0659",
    Longitude: "26.7788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2228",
    WardLookupId: "3077",
    VDNumber: "11160065",
    RegisteredPopulation: "534",
    VotingStationName: "BRAKKLOOF JUNIOR SECONDARY SCHOOL",
    Address: "BRAKKLOOF VILLAGE  WHITTLESEA",
    Latitude: "-32.1073",
    Longitude: "26.7985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2229",
    WardLookupId: "3077",
    VDNumber: "11160098",
    RegisteredPopulation: "836",
    VotingStationName: "PAKAMANI PRE-SCHOOL",
    Address: "WHO CAN TELL  WHO CAN TELL VILLAGE  QUEENSTOWN DISTRICT",
    Latitude: "-32.0591",
    Longitude: "26.7649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2230",
    WardLookupId: "3077",
    VDNumber: "11160133",
    RegisteredPopulation: "543",
    VotingStationName: "MASOMELELE PRE-SCHOOL",
    Address: "POPULAR GROVE VILLAGE  WHITTLESEA  HEWU DISTRICT",
    Latitude: "-32.1347",
    Longitude: "26.7641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2231",
    WardLookupId: "3077",
    VDNumber: "11160212",
    RegisteredPopulation: "338",
    VotingStationName: "CLAREMONT SENIOR PRIMARY SCHOOL",
    Address: "ENSAM  HEWU AA",
    Latitude: "-32.0889",
    Longitude: "26.7236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2232",
    WardLookupId: "3078",
    VDNumber: "11100025",
    RegisteredPopulation: "443",
    VotingStationName: "KAMASTONE COMMUNITY HALL",
    Address: "KAMASTONE VILLAGE  WHITTLESEA",
    Latitude: "-32.0689",
    Longitude: "26.6457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2233",
    WardLookupId: "3078",
    VDNumber: "11100115",
    RegisteredPopulation: "467",
    VotingStationName: "BULLHOEK JUNIOR SECONDARY SCHOOL",
    Address: "BULLHOEK VILLAGE  BULLHOEK  WHITTLESEA",
    Latitude: "-32.0348",
    Longitude: "26.6336",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2234",
    WardLookupId: "3078",
    VDNumber: "11100137",
    RegisteredPopulation: "420",
    VotingStationName: "ABAMBO HIGH SCHOOL",
    Address: "MCEULA VILLAGE  MCEULA  WHITTLESEA",
    Latitude: "-32.1114",
    Longitude: "26.6856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2235",
    WardLookupId: "3078",
    VDNumber: "11100216",
    RegisteredPopulation: "237",
    VotingStationName: "UPPER ZANGQOKWE PRIMARY SCHOOL",
    Address: "LOWER ZANGQOKWE  WHITTLESEA",
    Latitude: "-32.0716",
    Longitude: "26.5803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2236",
    WardLookupId: "3078",
    VDNumber: "11100227",
    RegisteredPopulation: "566",
    VotingStationName: "VELALANGA SENIOR SECONDARY SCHOOL",
    Address: "DIDIMANA VILLAGE  WHITTLESEA",
    Latitude: "-32.1165",
    Longitude: "26.6063",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2237",
    WardLookupId: "3078",
    VDNumber: "11100340",
    RegisteredPopulation: "119",
    VotingStationName: "SINETHEMBA PRE-SCHOOL",
    Address: "XHALENI  WHITTLESEA",
    Latitude: "-32.1088",
    Longitude: "26.6731",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2238",
    WardLookupId: "3078",
    VDNumber: "11100351",
    RegisteredPopulation: "183",
    VotingStationName: "UPPER DIDIMANA LOWER PRIMARY SCHOOL",
    Address: "UPPER DIDIMANA  WHITTLESEA",
    Latitude: "-32.1483",
    Longitude: "26.5752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2239",
    WardLookupId: "3078",
    VDNumber: "11160054",
    RegisteredPopulation: "139",
    VotingStationName: "HORIZON FARM",
    Address: "WAY TO WHITTLESEA  HORIZON FARM  QUEENSTOWN DISTRICT",
    Latitude: "-31.9593",
    Longitude: "26.7973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2240",
    WardLookupId: "3078",
    VDNumber: "11160087",
    RegisteredPopulation: "512",
    VotingStationName: "MERINO WALK DWELLING",
    Address: "MERINO WALK  QUEENSTOWN DISTRICT",
    Latitude: "-32.0464",
    Longitude: "26.7332",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2241",
    WardLookupId: "3079",
    VDNumber: "11100047",
    RegisteredPopulation: "668",
    VotingStationName: "TEMBISA SCHOOL",
    Address: "MBEKWENI  WHITLLESEA",
    Latitude: "-32.2454",
    Longitude: "26.7286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2242",
    WardLookupId: "3079",
    VDNumber: "11100058",
    RegisteredPopulation: "526",
    VotingStationName: "MORAVIAN PRIMARY SCHOOL",
    Address: "SHILOH  WHITTLESEA",
    Latitude: "-32.2015",
    Longitude: "26.841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2243",
    WardLookupId: "3079",
    VDNumber: "11100069",
    RegisteredPopulation: "339",
    VotingStationName: "MZINGISI EDUCARE CENTRE",
    Address: "NGOJINI  WHITTLESEA",
    Latitude: "-32.2282",
    Longitude: "26.7523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2244",
    WardLookupId: "3079",
    VDNumber: "11100193",
    RegisteredPopulation: "464",
    VotingStationName: "DALUBUZWE LHP SCHOOL",
    Address: "SIBONILE VILLAGE  SIBONILE  WHITTLESEA",
    Latitude: "-32.2955",
    Longitude: "26.7725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2245",
    WardLookupId: "3079",
    VDNumber: "11100250",
    RegisteredPopulation: "621",
    VotingStationName: "KHANYA SENIOR SECONDARY SCHOOL",
    Address: "SADA  WHITTLESEA",
    Latitude: "-32.2005",
    Longitude: "26.8306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2246",
    WardLookupId: "3079",
    VDNumber: "11100283",
    RegisteredPopulation: "487",
    VotingStationName: "SIYABULELA PRIMARY SCHOOL",
    Address: "ZWELEDINGA  WHITTLESEA",
    Latitude: "-32.3216",
    Longitude: "26.691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2247",
    WardLookupId: "3079",
    VDNumber: "11100306",
    RegisteredPopulation: "384",
    VotingStationName: "SOVUKASIKHANYE HIGH SCHOOL",
    Address: "OXTON VILLAGE  WHITTLESEA  QUEENSTOWN",
    Latitude: "-32.2328",
    Longitude: "26.7023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2248",
    WardLookupId: "3079",
    VDNumber: "11100362",
    RegisteredPopulation: "248",
    VotingStationName: "EMFULENI LOWER/HIGHER PRIMARY SCHOOL",
    Address: "0 NO NAME  DIPHALA VILLAGE  WHITTLESEA",
    Latitude: "-32.328",
    Longitude: "26.7894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2249",
    WardLookupId: "3079",
    VDNumber: "11160199",
    RegisteredPopulation: "74",
    VotingStationName: "IMVUMELWANO PRIMARY SCHOOL",
    Address: "GALAWATER  WHITTLESEA",
    Latitude: "-32.2358",
    Longitude: "26.8611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2250",
    WardLookupId: "3080",
    VDNumber: "11100036",
    RegisteredPopulation: "379",
    VotingStationName: "AME CHURCH",
    Address: "HUKUWA  WHITTLESEA",
    Latitude: "-32.1528",
    Longitude: "26.6569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2251",
    WardLookupId: "3080",
    VDNumber: "11100070",
    RegisteredPopulation: "261",
    VotingStationName: "EMJIKELWENI  PRIMARY SCHOOL",
    Address: "ZULUMEMA  WHITTLESEA",
    Latitude: "-32.2183",
    Longitude: "26.6317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2252",
    WardLookupId: "3080",
    VDNumber: "11100081",
    RegisteredPopulation: "564",
    VotingStationName: "YONDA COMBINED PRIMARY SCHOOL",
    Address: "YONDA  YONDA VILLAGE  SADA/ WHITTLESEA",
    Latitude: "-32.2891",
    Longitude: "26.676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2253",
    WardLookupId: "3080",
    VDNumber: "11100159",
    RegisteredPopulation: "296",
    VotingStationName: "MTWAKAZI L/H PRIMARY SCHOOL",
    Address: "MTHWAKAZI VILLAGE  HEWU  WHITTLESEA",
    Latitude: "-32.3807",
    Longitude: "26.6525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2254",
    WardLookupId: "3080",
    VDNumber: "11100171",
    RegisteredPopulation: "455",
    VotingStationName: "HACKNEY CONGREGATIONAL CHURCH",
    Address: "HACKNEY  WHITTLESEA",
    Latitude: "-32.3156",
    Longitude: "26.6491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2255",
    WardLookupId: "3080",
    VDNumber: "11100205",
    RegisteredPopulation: "329",
    VotingStationName: "TSITSIKAMA HIGHER PRIMARY SCHOOL",
    Address: "TSITSIKAMA  WHITTLESEA",
    Latitude: "-32.2223",
    Longitude: "26.5744",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2256",
    WardLookupId: "3080",
    VDNumber: "11100238",
    RegisteredPopulation: "191",
    VotingStationName: "MUSWA PRIMARY SCHOOL",
    Address: "MUSWA  WHITTLESEA",
    Latitude: "-32.3572",
    Longitude: "26.6402",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2257",
    WardLookupId: "3080",
    VDNumber: "11100249",
    RegisteredPopulation: "335",
    VotingStationName: "HEWU SENIOR SECONDARY SCHOOL",
    Address: "SAWUTIYA VILLAGE  LOWER LAHLANGUBO - SAWUTIYA  WHITTLESEA",
    Latitude: "-32.1841",
    Longitude: "26.6844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2258",
    WardLookupId: "3080",
    VDNumber: "11100294",
    RegisteredPopulation: "389",
    VotingStationName: "CIMEZILE  SCHOOL",
    Address: "CIMEZILE  CIMEZILE VILLAGE  WHITTLESEA",
    Latitude: "-32.2679",
    Longitude: "26.6375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2259",
    WardLookupId: "3080",
    VDNumber: "11100317",
    RegisteredPopulation: "223",
    VotingStationName: "EARDLEY JUNIOR AND SENIOR PRIMARY SCHOOL",
    Address: "EARDERLY  EARDLEY  QUEENSTOWN",
    Latitude: "-32.2231",
    Longitude: "26.6912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2260",
    WardLookupId: "3080",
    VDNumber: "11100328",
    RegisteredPopulation: "157",
    VotingStationName: "LOWER HUKUWA COMMUNITY HALL",
    Address: "WHITTLESEA  QUEENSTOWN",
    Latitude: "-32.1366",
    Longitude: "26.6969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2261",
    WardLookupId: "3080",
    VDNumber: "11100339",
    RegisteredPopulation: "197",
    VotingStationName: "ROOMANSLAAGTE JUNIOR SENIOR PRIMARY SCHOOL",
    Address: "ROMMANSLAAGTE  HEWU",
    Latitude: "-32.1598",
    Longitude: "26.5971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2262",
    WardLookupId: "3080",
    VDNumber: "11100384",
    RegisteredPopulation: "170",
    VotingStationName: "NTUNJA JUNIOR SECONDARY SCHOOL",
    Address: "NTUNJA  WHITLLESEA",
    Latitude: "-32.2414",
    Longitude: "26.6426",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2263",
    WardLookupId: "3081",
    VDNumber: "11060019",
    RegisteredPopulation: "2519",
    VotingStationName: "IXHADI LABANTWANA HP SCHOOL",
    Address: "NEW ZONE  EKUPHUMLENI  QUEENSTOWN DISTRICT",
    Latitude: "-32.1832",
    Longitude: "26.793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2264",
    WardLookupId: "3081",
    VDNumber: "11060031",
    RegisteredPopulation: "1399",
    VotingStationName: "EKUPHUMLENI COMMUNITY HALL",
    Address: "EKUPHUMLENI  WHITTLESEA/ SADA",
    Latitude: "-32.1764",
    Longitude: "26.7821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2265",
    WardLookupId: "3082",
    VDNumber: "11060053",
    RegisteredPopulation: "1343",
    VotingStationName: "SADA COMMUNITY HALL",
    Address: "SADA TOWNSHIP  WHITTLESEA",
    Latitude: "-32.2017",
    Longitude: "26.8085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2266",
    WardLookupId: "3082",
    VDNumber: "11060075",
    RegisteredPopulation: "1171",
    VotingStationName: "HOLY CITY CHURCH",
    Address: "EMADEKENI  QUEENSTOWN DISTRICT",
    Latitude: "-32.2011",
    Longitude: "26.8021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2267",
    WardLookupId: "3082",
    VDNumber: "11100104",
    RegisteredPopulation: "652",
    VotingStationName: "NTABELANGA  SENIOR PRIMARY SCHOOL",
    Address: "SADA TOWNSHIP  SADA  WHITTLESEA",
    Latitude: "-32.2072",
    Longitude: "26.8006",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2268",
    WardLookupId: "3082",
    VDNumber: "11100272",
    RegisteredPopulation: "340",
    VotingStationName: "NEW HOPE JUNIOR/SENIOR SCHOOL",
    Address: "MTABAZO VILLAGE  QUEENSTOWN  LUKANJI",
    Latitude: "-32.1873",
    Longitude: "26.7633",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2269",
    WardLookupId: "3083",
    VDNumber: "11060064",
    RegisteredPopulation: "1113",
    VotingStationName: "NOMZAMO JUNIOR   PRIMARY SCHOOL",
    Address: "SADA TOWNSHIP  SADA  WHITTLESEA",
    Latitude: "-32.1988",
    Longitude: "26.8194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2270",
    WardLookupId: "3083",
    VDNumber: "11060086",
    RegisteredPopulation: "1228",
    VotingStationName: "LUVUYO SENIOR PRIMARY SCHOOL",
    Address: "SADA LOCATION  SADA  WHITTLESEA",
    Latitude: "-32.1948",
    Longitude: "26.8155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2271",
    WardLookupId: "3083",
    VDNumber: "11060097",
    RegisteredPopulation: "726",
    VotingStationName: "ZOLANI JUNIOR SECONDARY SCHOOL",
    Address: "SADA TOWNSHIP  WHITTLESEA",
    Latitude: "-32.2024",
    Longitude: "26.8167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2272",
    WardLookupId: "3083",
    VDNumber: "11100014",
    RegisteredPopulation: "536",
    VotingStationName: "SADA SENIOR PRIMARY SCHOOL",
    Address: "SADA TOWNSHIP  WHITTLESEA/ SADA",
    Latitude: "-32.1986",
    Longitude: "26.8088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2273",
    WardLookupId: "3084",
    VDNumber: "11060020",
    RegisteredPopulation: "697",
    VotingStationName: "HEWU HOSPITAL",
    Address: "ZONE 1  EKUPHUMLENI  WHITTLESEA",
    Latitude: "-32.1735",
    Longitude: "26.7838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2274",
    WardLookupId: "3084",
    VDNumber: "11060042",
    RegisteredPopulation: "685",
    VotingStationName: "MAGISTRATE OFFICE",
    Address: "8 CALDERWOOD  WHITTLESEA  WHITTLESEA",
    Latitude: "-32.1746",
    Longitude: "26.8229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2275",
    WardLookupId: "3084",
    VDNumber: "11060109",
    RegisteredPopulation: "1293",
    VotingStationName: "ZAMOKUHLE SENIOR SECONDARY SCHOOL",
    Address: "EXTENSION  4  WHITTLESEA  LUKANJI",
    Latitude: "-32.1706",
    Longitude: "26.8105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2276",
    WardLookupId: "3084",
    VDNumber: "11060110",
    RegisteredPopulation: "1017",
    VotingStationName: "EKUPHUMLENI SENIOR SECONDARY SCHOOL",
    Address: "ZONE  2  WHITTLESEA  LUKANJI",
    Latitude: "-32.1799",
    Longitude: "26.7798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2277",
    WardLookupId: "3084",
    VDNumber: "11100261",
    RegisteredPopulation: "215",
    VotingStationName: "ENQOBOKENI LHP SCHOOL",
    Address: "NQOBOKENI VILLAGE  WHITTLESEA  QUEENSTOWN",
    Latitude: "-32.1632",
    Longitude: "26.7198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2278",
    WardLookupId: "3085",
    VDNumber: "11040017",
    RegisteredPopulation: "1006",
    VotingStationName: "MASAKHE COMMUNITY HALL",
    Address: "177 MASAKHE   MASAKHE TOWNSHIP  STERKSTROOM",
    Latitude: "-31.5537",
    Longitude: "26.5603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2279",
    WardLookupId: "3085",
    VDNumber: "11040028",
    RegisteredPopulation: "502",
    VotingStationName: "STERKSTROOM TENT",
    Address: "VAN HEERDEN  STERKSTROOM  STERKSTROOM",
    Latitude: "-31.5572",
    Longitude: "26.5501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2280",
    WardLookupId: "3085",
    VDNumber: "11040039",
    RegisteredPopulation: "776",
    VotingStationName: "SIYAPHAKAMA HIGH SCHOOL",
    Address: "82 NEW MASAKHE LOCATION  NEW MASAKHE  STERKSTROOM",
    Latitude: "-31.5555",
    Longitude: "26.5681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2281",
    WardLookupId: "3085",
    VDNumber: "11040040",
    RegisteredPopulation: "928",
    VotingStationName: "ELUTHUTHU PRIMARY SCHOOL",
    Address: "STERKSTROOM  MASAKHE TOWNSHIP  STERKSTROOM",
    Latitude: "-31.5594",
    Longitude: "26.5665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2282",
    WardLookupId: "3085",
    VDNumber: "11040051",
    RegisteredPopulation: "739",
    VotingStationName: "AFRICAN METHODIST EPISCOPAL CHURCH",
    Address: "84 OLD MASAKHE  MASAKHE TOWNSHIP  STERKSTROOM",
    Latitude: "-31.5542",
    Longitude: "26.559",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2283",
    WardLookupId: "3085",
    VDNumber: "11170011",
    RegisteredPopulation: "95",
    VotingStationName: "HAZELMERE FARM (MR P MORTLOCK)",
    Address: "HAZELMERE FARM  STERKSTROOM",
    Latitude: "-31.5021",
    Longitude: "26.681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2284",
    WardLookupId: "3086",
    VDNumber: "11020048",
    RegisteredPopulation: "727",
    VotingStationName: "SIYAQAQAMBA CRECHE",
    Address: "14547 CHRIS HANI DRIVE  NKULULEKO PARK  MOLTENO",
    Latitude: "-31.3886",
    Longitude: "26.3558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2285",
    WardLookupId: "3086",
    VDNumber: "11020059",
    RegisteredPopulation: "1271",
    VotingStationName: "NCEBA PUBLIC SCHOOL",
    Address: "426 MBULELO STREET  NOMONDE TOWNSHIP  MOLTENO",
    Latitude: "-31.3799",
    Longitude: "26.3588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2286",
    WardLookupId: "3086",
    VDNumber: "11020105",
    RegisteredPopulation: "302",
    VotingStationName: "DENNEKRUIN HALL",
    Address: "61 GRAHAM STREET  DENNEKRUIN  MOLTENO",
    Latitude: "-31.397",
    Longitude: "26.3685",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2287",
    WardLookupId: "3086",
    VDNumber: "11020116",
    RegisteredPopulation: "88",
    VotingStationName: "METHODIST CHURCH IN AFRICA",
    Address: "178 HAYIYA STREET  OLD LOCATION  MOLTENO",
    Latitude: "-31.3897",
    Longitude: "26.3643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2288",
    WardLookupId: "3086",
    VDNumber: "11020127",
    RegisteredPopulation: "324",
    VotingStationName: "AIRSTRIP TENT",
    Address: "N/A  AIRTRIP LOCATION  MOLTENO",
    Latitude: "-31.3874",
    Longitude: "26.3449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2289",
    WardLookupId: "3086",
    VDNumber: "11140018",
    RegisteredPopulation: "645",
    VotingStationName: "MOLTENO TOWN HALL",
    Address: "226 BROWNLEE STREET  MOLTENO  MOLTENO",
    Latitude: "-31.398",
    Longitude: "26.3647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2290",
    WardLookupId: "3086",
    VDNumber: "11170022",
    RegisteredPopulation: "98",
    VotingStationName: "LANGE WATERVOOR (SONSKYN FARM)",
    Address: "SONSKYN FARM  STERKSTROOM  STERKSTROOM",
    Latitude: "-31.6574",
    Longitude: "26.5527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2291",
    WardLookupId: "3087",
    VDNumber: "11020015",
    RegisteredPopulation: "1232",
    VotingStationName: "NOMONDE COMMUNITY HALL",
    Address: "52 MONDE STREET  NOMONDE  MOLTENO",
    Latitude: "-31.3845",
    Longitude: "26.3598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2292",
    WardLookupId: "3087",
    VDNumber: "11020026",
    RegisteredPopulation: "1160",
    VotingStationName: "AFRICAN METHODIST EPISCOPAL CHURCH",
    Address: "1190 OLD LOCATION  NOMONDE  MOLTENO",
    Latitude: "-31.389",
    Longitude: "26.3643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2293",
    WardLookupId: "3087",
    VDNumber: "11020071",
    RegisteredPopulation: "668",
    VotingStationName: "JOE SLOVO FREEDOM HIGH SCHOOL",
    Address: "111 LONWABO STREET  NOMONDE TOWNSHIP  MOLTENO",
    Latitude: "-31.3835",
    Longitude: "26.3565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2294",
    WardLookupId: "3088",
    VDNumber: "11150019",
    RegisteredPopulation: "932",
    VotingStationName: "MKHITHA PRIMARY SCHOOL",
    Address: "ZOLA VILLAGE  NTABETHEMBA  TSOLWANA",
    Latitude: "-31.9323",
    Longitude: "26.6292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2295",
    WardLookupId: "3088",
    VDNumber: "11150020",
    RegisteredPopulation: "772",
    VotingStationName: "THORNHILL COMMUNITY  HALL",
    Address: "COMMUNITY HALL ( THORNHILL)  THORNHILL  NTABETHEMBA",
    Latitude: "-31.9726",
    Longitude: "26.6065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2296",
    WardLookupId: "3088",
    VDNumber: "11150031",
    RegisteredPopulation: "1144",
    VotingStationName: "HLALETHEMBENI JUNIOR PRIMARY SCHOOL",
    Address: "THORNHILL  THORNHILL VILLAGE  NTABETHEMBA",
    Latitude: "-31.9805",
    Longitude: "26.5906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2297",
    WardLookupId: "3088",
    VDNumber: "11150042",
    RegisteredPopulation: "884",
    VotingStationName: "NYATHI SECONDARY SENIOR SCHOOL",
    Address: "THORNHILL  NTABETHEMBA",
    Latitude: "-31.9863",
    Longitude: "26.6109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2298",
    WardLookupId: "3089",
    VDNumber: "11150053",
    RegisteredPopulation: "906",
    VotingStationName: "CHIEF MALEFANE PRIMARY SCHOOL",
    Address: "MITFORD VILLAGE  NTABETHEMBA",
    Latitude: "-31.9759",
    Longitude: "26.5298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2299",
    WardLookupId: "3089",
    VDNumber: "11150075",
    RegisteredPopulation: "893",
    VotingStationName: "ZANABANTU HIGH SCHOOL",
    Address: "ROCKLANDS  NTABETHEMBA",
    Latitude: "-32.0123",
    Longitude: "26.4815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2300",
    WardLookupId: "3089",
    VDNumber: "11150097",
    RegisteredPopulation: "279",
    VotingStationName: "KHAYALETU LOWER PRIMARY SCHOOL",
    Address: "KHAYALETHU VILLAGE  NTABETHEMBA 5364  TARKASTAD",
    Latitude: "-32.0719",
    Longitude: "26.4674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2301",
    WardLookupId: "3089",
    VDNumber: "11150143",
    RegisteredPopulation: "651",
    VotingStationName: "KOPANO SCHOOL",
    Address: "MITFORD  NTABETHEMBA",
    Latitude: "-31.987",
    Longitude: "26.5299",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2302",
    WardLookupId: "3089",
    VDNumber: "11150154",
    RegisteredPopulation: "615",
    VotingStationName: "MASIZAME JUNIOR SECONDARY SCHOOL",
    Address: "VILLAGE  PHAKAMISA  NTABETHEMBA",
    Latitude: "-31.9297",
    Longitude: "26.5822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2303",
    WardLookupId: "3089",
    VDNumber: "11150165",
    RegisteredPopulation: "112",
    VotingStationName: "GARRICKMOOR FARM",
    Address: "CARRICMOR FARM  TARKASTAD  TARKASTAD",
    Latitude: "-31.9319",
    Longitude: "26.389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2304",
    WardLookupId: "3089",
    VDNumber: "11180012",
    RegisteredPopulation: "154",
    VotingStationName: "IVANLEW COMMUNITY HALL",
    Address: "CATHCART STREET  IVANLEW  TARKASTAD",
    Latitude: "-32.0122",
    Longitude: "26.262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2305",
    WardLookupId: "3090",
    VDNumber: "11150064",
    RegisteredPopulation: "902",
    VotingStationName: "PHUMULANI JUNIOR PRIMARY SCHOOL",
    Address: "IKHWEZI  IKHWEZI VILLAGE  NTABETHEMBA",
    Latitude: "-32.0989",
    Longitude: "26.5581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2306",
    WardLookupId: "3090",
    VDNumber: "11150086",
    RegisteredPopulation: "602",
    VotingStationName: "SIZAMILE HIGHER PRIMARY SCHOOL",
    Address: "BACCLES FARM  NTABETHEMBA",
    Latitude: "-32.0476",
    Longitude: "26.4669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2307",
    WardLookupId: "3090",
    VDNumber: "11150109",
    RegisteredPopulation: "852",
    VotingStationName: "GUGULETHU PRE-SCHOOL",
    Address: "TENTERGATE VILLAGE  NTABETHEMBA 5364  TARKASTAD",
    Latitude: "-32.0866",
    Longitude: "26.4588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:40",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:40",
  },
  {
    VotingStationId: "2308",
    WardLookupId: "3090",
    VDNumber: "11150110",
    RegisteredPopulation: "271",
    VotingStationName: "KWINANA LOWER PRIMARY SCHOOL",
    Address: "SPRINGROOVE  NTABETHEMBA",
    Latitude: "-32.1251",
    Longitude: "26.478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2309",
    WardLookupId: "3090",
    VDNumber: "11150121",
    RegisteredPopulation: "639",
    VotingStationName: "METHODIST CHURCH OF SOUTHERN AFRICA",
    Address: "TENTERGATE  TENTERGATE 1  NTABETHEMBA",
    Latitude: "-32.0809",
    Longitude: "26.4563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2310",
    WardLookupId: "3090",
    VDNumber: "11150132",
    RegisteredPopulation: "734",
    VotingStationName: "NANISO LOWER PRIMARY SCHOOL",
    Address: "SPRINGROVE  NTABETHEMBA",
    Latitude: "-32.1051",
    Longitude: "26.4658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2311",
    WardLookupId: "3091",
    VDNumber: "11050018",
    RegisteredPopulation: "604",
    VotingStationName: "TOWN HALL",
    Address: "12 MURRAY STREET  TARKASTAD",
    Latitude: "-32.0087",
    Longitude: "26.2614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2312",
    WardLookupId: "3091",
    VDNumber: "11050029",
    RegisteredPopulation: "1285",
    VotingStationName: "NKOSIYAKHE HIGHER PRIMARY SCHOOL",
    Address: "1 DUNA STREET  TARKASTAD  TSOLWANA",
    Latitude: "-32.0098",
    Longitude: "26.2415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2313",
    WardLookupId: "3091",
    VDNumber: "11180045",
    RegisteredPopulation: "1226",
    VotingStationName: "RAYMOND MHLABA SENIOR SECONDARY SCHOOL",
    Address: "850 TAKANE STREET  ZOLA TOWNSHIP  TSOLWANA",
    Latitude: "-32.0129",
    Longitude: "26.2495",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2314",
    WardLookupId: "3091",
    VDNumber: "11180056",
    RegisteredPopulation: "607",
    VotingStationName: "TARKASTAD PRIMARY SCHOOL",
    Address: "MANNY PRICE  IVANLEW TOWNSHIP  TARKASTAD",
    Latitude: "-32.0125",
    Longitude: "26.2613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2315",
    WardLookupId: "3092",
    VDNumber: "10990010",
    RegisteredPopulation: "824",
    VotingStationName: "TOWN HALL",
    Address: "MOLTENO STREET  HOFMEYR  HOFMEYR",
    Latitude: "-31.654",
    Longitude: "25.8037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2316",
    WardLookupId: "3092",
    VDNumber: "10990021",
    RegisteredPopulation: "1747",
    VotingStationName: "CHRIS HANI CENTRE",
    Address: "LUXOLWENI  HOFMEYR",
    Latitude: "-31.644",
    Longitude: "25.8059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2317",
    WardLookupId: "3092",
    VDNumber: "11110015",
    RegisteredPopulation: "84",
    VotingStationName: "FONTEINTJIE FARM SHED",
    Address: "FONTEITJIE FARM  HOFMEYR 5930  TARKASTAD",
    Latitude: "-31.7274",
    Longitude: "25.6463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2318",
    WardLookupId: "3092",
    VDNumber: "11110026",
    RegisteredPopulation: "117",
    VotingStationName: "FROTUIN FARM",
    Address: "FROTUIN FARM  HOFMEYR  HOFMEYR",
    Latitude: "-31.5155",
    Longitude: "25.5775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2319",
    WardLookupId: "3092",
    VDNumber: "11110037",
    RegisteredPopulation: "39",
    VotingStationName: "SPITSKOP AGRICULTURAL FARM HALL",
    Address: "HOFMEYR  ",
    Latitude: "-31.5887",
    Longitude: "26.0104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2320",
    WardLookupId: "3092",
    VDNumber: "11110048",
    RegisteredPopulation: "50",
    VotingStationName: "BESTERLEEGTE FARM SHED",
    Address: "BESTERLEEGTE FARM  HOFMEYR 5930  TARKASTAD",
    Latitude: "-31.7831",
    Longitude: "25.8718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2321",
    WardLookupId: "3092",
    VDNumber: "11180023",
    RegisteredPopulation: "138",
    VotingStationName: "HEATHFIELD FARM SHED",
    Address: "HEATHFIELD FARM TARKASTAD R401  TARKASTAD  TARKASTAD",
    Latitude: "-31.8466",
    Longitude: "26.0052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2322",
    WardLookupId: "3092",
    VDNumber: "11180034",
    RegisteredPopulation: "213",
    VotingStationName: "SOSEBENZA COMMUNITY SCHOOL",
    Address: "FAIR VIEW  TARKASTAD",
    Latitude: "-32.1796",
    Longitude: "26.3029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2323",
    WardLookupId: "3093",
    VDNumber: "11420042",
    RegisteredPopulation: "139",
    VotingStationName: "MONTGOMERY JUNIOR SECONDARY SCHOOL",
    Address: "MONTGOMERY FARM  UGIE",
    Latitude: "-31.2876",
    Longitude: "28.2771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2324",
    WardLookupId: "3093",
    VDNumber: "11620280",
    RegisteredPopulation: "580",
    VotingStationName: "ELUNYAWENI JUNIOR SECONDARY SCHOOL",
    Address: "NCEMBU A/A  ELUNYAWENI LOCATION  TSOLO",
    Latitude: "-31.4454",
    Longitude: "28.3844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2325",
    WardLookupId: "3093",
    VDNumber: "11620291",
    RegisteredPopulation: "886",
    VotingStationName: "NCEMBU JUNIOR SECONDARY SCHOOL",
    Address: "NCEMBU A/A  MISSION LOCATION  TSOLO",
    Latitude: "-31.3939",
    Longitude: "28.4033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2326",
    WardLookupId: "3093",
    VDNumber: "11620303",
    RegisteredPopulation: "103",
    VotingStationName: "DALUXOLO JUNIOR SECONDARY SCHOOL",
    Address: "N/A  MAPLOTINI LOCATION  TSOLO",
    Latitude: "-31.35",
    Longitude: "28.3668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2327",
    WardLookupId: "3093",
    VDNumber: "11620606",
    RegisteredPopulation: "233",
    VotingStationName: "WHEATLANDS JUNIOR SECONDARY SCHOOL",
    Address: "NCEMBU A/A  TRUSTINI LOCATION  TSOLO",
    Latitude: "-31.4363",
    Longitude: "28.3684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2328",
    WardLookupId: "3093",
    VDNumber: "11620617",
    RegisteredPopulation: "664",
    VotingStationName: "LUTUTU JUNIOR SECONDARY SCHOOL",
    Address: "NCEMBU A/A  LUTUTU LOCATION  TSOLO",
    Latitude: "-31.3773",
    Longitude: "28.4504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2329",
    WardLookupId: "3093",
    VDNumber: "11620628",
    RegisteredPopulation: "318",
    VotingStationName: "NYIBIBA JUNIOR SECONDARY SCHOOL",
    Address: "NCEMBU A/A  NYIBIBENI LOCATION  TSOLO",
    Latitude: "-31.4189",
    Longitude: "28.4042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2330",
    WardLookupId: "3093",
    VDNumber: "11620639",
    RegisteredPopulation: "402",
    VotingStationName: "ENKALWENI JUNIOR SECONDARY SCHOOL",
    Address: "NGCENGANI A/A  ENKALWENI LOCATION  TSOLO",
    Latitude: "-31.3181",
    Longitude: "28.4193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2331",
    WardLookupId: "3093",
    VDNumber: "11620707",
    RegisteredPopulation: "286",
    VotingStationName: "DALIBANGO JUNIOR SECONDARY SCHOOL",
    Address: "DRAYINI LOCATION  TSOLO",
    Latitude: "-31.2788",
    Longitude: "28.4468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2332",
    WardLookupId: "3093",
    VDNumber: "11620842",
    RegisteredPopulation: "247",
    VotingStationName: "GABULINKUNGU JUNIOR SECONDARY SCHOOL",
    Address: "LALINI LOCATION  TSOLO",
    Latitude: "-31.3372",
    Longitude: "28.3992",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2333",
    WardLookupId: "3094",
    VDNumber: "11330019",
    RegisteredPopulation: "1950",
    VotingStationName: "NTOKOZWENI COMMUNITY HALL",
    Address: "ERF 574  DYOKI  UGIE",
    Latitude: "-31.1945",
    Longitude: "28.2465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2334",
    WardLookupId: "3094",
    VDNumber: "11330042",
    RegisteredPopulation: "1997",
    VotingStationName: "SIBABALE SENIOR SECONDARY SCHOOL",
    Address: "LAND CAMP LOCATION  NEW SETTLEMENT  UGIE",
    Latitude: "-31.2037",
    Longitude: "28.2485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2335",
    WardLookupId: "3095",
    VDNumber: "11290014",
    RegisteredPopulation: "1036",
    VotingStationName: "ROMAN CATHOLIC HALL",
    Address: "PAMBILI STREET  MACLEAR  MACLEAR",
    Latitude: "-31.0731",
    Longitude: "28.3589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2336",
    WardLookupId: "3095",
    VDNumber: "11290025",
    RegisteredPopulation: "1456",
    VotingStationName: "SONWABILE COMMUNITY HALL",
    Address: "SONWABILE LOCATION  MACLEAR  MACLEAR",
    Latitude: "-31.0805",
    Longitude: "28.365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2337",
    WardLookupId: "3095",
    VDNumber: "11290047",
    RegisteredPopulation: "347",
    VotingStationName: "MACLEAR METHODIST PRIMARY SCHOOL",
    Address: "18 UITSIG STREET  (EC141 - ELUNDINI [MOUNT FLETCHER])   MACLEAR",
    Latitude: "-31.073",
    Longitude: "28.3512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2338",
    WardLookupId: "3095",
    VDNumber: "11290058",
    RegisteredPopulation: "528",
    VotingStationName: "UMTHAWELANGA SENIOR SECONDARY SCHOOL",
    Address: "MOKABA  SONWABILE LOC   MACLEAR",
    Latitude: "-31.0754",
    Longitude: "28.3643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2339",
    WardLookupId: "3095",
    VDNumber: "11290069",
    RegisteredPopulation: "442",
    VotingStationName: "SITHOLE  METHODIST CHURCH",
    Address: "N/A  SITHOLE LOCATION  MACLEAR",
    Latitude: "-31.0731",
    Longitude: "28.3678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2340",
    WardLookupId: "3096",
    VDNumber: "11290036",
    RegisteredPopulation: "854",
    VotingStationName: "MACLEAR TOWN HALL",
    Address: "VAN RIEBEECK  MACLEAR  MACLEAR",
    Latitude: "-31.0682",
    Longitude: "28.3479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2341",
    WardLookupId: "3096",
    VDNumber: "11330020",
    RegisteredPopulation: "550",
    VotingStationName: "UGIE  METHODIST  CHURCH",
    Address: "MURRAY STREET  UGIE  UGIE",
    Latitude: "-31.1949",
    Longitude: "28.2335",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2342",
    WardLookupId: "3096",
    VDNumber: "11370068",
    RegisteredPopulation: "103",
    VotingStationName: "ELANDS HEIGHT SCHOOL",
    Address: "DENGXANA FARMS  MACLEAR",
    Latitude: "-30.8038",
    Longitude: "28.2264",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2343",
    WardLookupId: "3096",
    VDNumber: "11420019",
    RegisteredPopulation: "210",
    VotingStationName: "JOELSHOEK FARM SCHOOL",
    Address: "KILLARNEY ESTATE  MACLEAR  MACLEAR",
    Latitude: "-31.0045",
    Longitude: "28.2623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2344",
    WardLookupId: "3096",
    VDNumber: "11800033",
    RegisteredPopulation: "582",
    VotingStationName: "LOWER TSITSANA MISSION",
    Address: "HLANKOMO A/A  LOWER TSITSANA LOCATION  MT FLETCHER",
    Latitude: "-30.8782",
    Longitude: "28.3459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2345",
    WardLookupId: "3096",
    VDNumber: "11800055",
    RegisteredPopulation: "744",
    VotingStationName: "UPPER TSITSANA JUNIOR SECONDARY SCHOOL",
    Address: "HLANKOMO A/A  UPPER TSITSANA LOC  MT FLETCHER",
    Latitude: "-30.8515",
    Longitude: "28.3286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2346",
    WardLookupId: "3096",
    VDNumber: "11800460",
    RegisteredPopulation: "515",
    VotingStationName: "LUNDINI JUNIOR SECONDARY SCHOOL",
    Address: "LUNDINI LOCATION  MT FLETCHER",
    Latitude: "-30.6363",
    Longitude: "28.2265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2347",
    WardLookupId: "3096",
    VDNumber: "11800910",
    RegisteredPopulation: "224",
    VotingStationName: "UPPER MHLONTLO SENIOR PRIMARY SCHOOL",
    Address: "UPPER MHLONTLO SENIOR PRIMARY SCHOOL  HLANKOMO A/A  MT FLETCHER",
    Latitude: "-30.8775",
    Longitude: "28.3983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2348",
    WardLookupId: "3096",
    VDNumber: "11801191",
    RegisteredPopulation: "230",
    VotingStationName: "MDENI SENIOR PRIMARY SCHOOL",
    Address: "MOUNT FLETCHER  HLANKOMO LOCATION  MOUNT FLETCHER",
    Latitude: "-30.9059",
    Longitude: "28.3698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2349",
    WardLookupId: "3097",
    VDNumber: "11611055",
    RegisteredPopulation: "144",
    VotingStationName: "NTUSHUNTUSHU JUNIOR SECONDARY SCHOOL",
    Address: "NTUSHUNTUSHU LOCATION  NGXAZA A/A  TSOLO",
    Latitude: "-31.2427",
    Longitude: "28.5082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2350",
    WardLookupId: "3097",
    VDNumber: "11620022",
    RegisteredPopulation: "456",
    VotingStationName: "MCWANGELE JUNIOR SECONDARY SCHOOL",
    Address: "MCWANGELE  A/A  MCWANGELE LOCATION  TSOLO",
    Latitude: "-31.2124",
    Longitude: "28.4814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2351",
    WardLookupId: "3097",
    VDNumber: "11620369",
    RegisteredPopulation: "388",
    VotingStationName: "INXU JUNIOR SECONDARY SCHOOL",
    Address: "ST. AUGUSTINE LOCATION  INXU A/A  TSOLO",
    Latitude: "-31.2234",
    Longitude: "28.5963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2352",
    WardLookupId: "3097",
    VDNumber: "11620370",
    RegisteredPopulation: "691",
    VotingStationName: "ZWELIDUMILE SENIOR PRIMARY SCHOOL",
    Address: "HOPEDALE  NGCELE  MACLEAR",
    Latitude: "-31.1655",
    Longitude: "28.4929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2353",
    WardLookupId: "3097",
    VDNumber: "11620404",
    RegisteredPopulation: "528",
    VotingStationName: "MBONISWENI JUNIOR SECONDARY SCHOOL",
    Address: "MBONISWENI A/A  MBONISWENI LOCATION  TSOLO",
    Latitude: "-31.2169",
    Longitude: "28.5058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2354",
    WardLookupId: "3097",
    VDNumber: "11620459",
    RegisteredPopulation: "424",
    VotingStationName: "NTABA JUNIOR SECONDARY SCHOOL",
    Address: "NGXAZA A/A  MTSHEZI LOCATION  MACLEAR",
    Latitude: "-31.1943",
    Longitude: "28.5411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2355",
    WardLookupId: "3097",
    VDNumber: "11620695",
    RegisteredPopulation: "488",
    VotingStationName: "NGXAZA JUNIOR SECONDARY SCHOOL",
    Address: "NGXAZA A/A  NGXAZA LOCATION  TSOLO",
    Latitude: "-31.1713",
    Longitude: "28.5085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2356",
    WardLookupId: "3097",
    VDNumber: "11620853",
    RegisteredPopulation: "230",
    VotingStationName: "SITHANA JUNIOR SECONDARY SCHOOL",
    Address: "SITHANA LOCATION  SITHANA A/A  TSOLO",
    Latitude: "-31.1825",
    Longitude: "28.4739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2357",
    WardLookupId: "3097",
    VDNumber: "11620864",
    RegisteredPopulation: "175",
    VotingStationName: "GONIWE SENIOR PRIMARY SCHOOL",
    Address: "NTYWENKA A/A  NTYWENKA LOCATION  TSOLO",
    Latitude: "-31.212",
    Longitude: "28.6218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2358",
    WardLookupId: "3097",
    VDNumber: "11620976",
    RegisteredPopulation: "169",
    VotingStationName: "ZANDISE JUNIOR SECONDARY SCHOOL",
    Address: "MPUNKONE LOCATION  MACLEAR",
    Latitude: "-31.2281",
    Longitude: "28.5681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2359",
    WardLookupId: "3097",
    VDNumber: "11620987",
    RegisteredPopulation: "88",
    VotingStationName: "LOWER NGXAZA SENIOR PRIMARY SCHOOL",
    Address: "LOWER NGXAZA LOCATION  LOWER NGXAZA A/A  TSOLO",
    Latitude: "-31.2262",
    Longitude: "28.5363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2360",
    WardLookupId: "3097",
    VDNumber: "11621001",
    RegisteredPopulation: "307",
    VotingStationName: "MABANDLA SENIOR PRIMARY SCHOOL",
    Address: "NGCELE  TSOLO",
    Latitude: "-31.1599",
    Longitude: "28.5593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2361",
    WardLookupId: "3097",
    VDNumber: "11621067",
    RegisteredPopulation: "131",
    VotingStationName: "NONTATYANA  SCHOOL",
    Address: "MCWANGELE VILLAGE  NGXAZA A.A  MACLEAR",
    Latitude: "-31.2145",
    Longitude: "28.4571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2362",
    WardLookupId: "3098",
    VDNumber: "11620033",
    RegisteredPopulation: "288",
    VotingStationName: "UPPER ESINXAKO JUNIOR SECONDARY SCHOOL",
    Address: "SINXAGO A/A  SINXAKU LOCATION  TSOLO",
    Latitude: "-31.1409",
    Longitude: "28.5965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2363",
    WardLookupId: "3098",
    VDNumber: "11620044",
    RegisteredPopulation: "251",
    VotingStationName: "ESIQHUNGQWINI JUNIOR SECONDARY SCHOOL",
    Address: "ESIQHUNGQWINI A/A  ESIQHUNGQWINI LOCAION  TSOLO",
    Latitude: "-31.1266",
    Longitude: "28.6555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2364",
    WardLookupId: "3098",
    VDNumber: "11620055",
    RegisteredPopulation: "549",
    VotingStationName: "JAMANGILE SENIOR SECONDARY SCHOOL",
    Address: "NGCELE A/A  NGCELE LCOATION  TSOLO",
    Latitude: "-31.162",
    Longitude: "28.5408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2365",
    WardLookupId: "3098",
    VDNumber: "11620066",
    RegisteredPopulation: "188",
    VotingStationName: "SOMMERVILLE JUNIOR SECONDARY SCHOOL",
    Address: "SOMMERVILLE  LOCATION  SOMERVILLE A/A  TSOLO",
    Latitude: "-31.2059",
    Longitude: "28.6395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2366",
    WardLookupId: "3098",
    VDNumber: "11620101",
    RegisteredPopulation: "349",
    VotingStationName: "MQOKOLWENI JUNIOR SECONDARY SCHOOL",
    Address: "MQOKOLWENI A/A  MQOKOLWENI LOCATION  TSOLO",
    Latitude: "-31.1194",
    Longitude: "28.5503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2367",
    WardLookupId: "3098",
    VDNumber: "11620437",
    RegisteredPopulation: "378",
    VotingStationName: "LOWER SINXAKO SCHOOL",
    Address: "SINXAKO A/A  SINXAKOLOCATION  TSOLO",
    Latitude: "-31.1288",
    Longitude: "28.6209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2368",
    WardLookupId: "3098",
    VDNumber: "11620448",
    RegisteredPopulation: "123",
    VotingStationName: "QURANA JUNIOR SECONDARY SCHOOL",
    Address: "QURANA A/A  QURANA LOCATION  TSOLO",
    Latitude: "-31.1531",
    Longitude: "28.632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2369",
    WardLookupId: "3098",
    VDNumber: "11620460",
    RegisteredPopulation: "205",
    VotingStationName: "NTYWENKA JUNIOR SECONDARY SCHOOL",
    Address: "NTYWENKA LOCATION  TSOLO  TSOLO",
    Latitude: "-31.1932",
    Longitude: "28.5936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2370",
    WardLookupId: "3098",
    VDNumber: "11620493",
    RegisteredPopulation: "254",
    VotingStationName: "NGXOTHO JUNIOR SECONDARY SCHOOL",
    Address: "NGCELE A/A  NGXOTHO LOCATION  TSOLO",
    Latitude: "-31.1122",
    Longitude: "28.509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2371",
    WardLookupId: "3098",
    VDNumber: "11620684",
    RegisteredPopulation: "118",
    VotingStationName: "SOBANTU JUNIOR SECONDARY SCHOOL",
    Address: "NKOLOSANE A/A  NKOLOSANE LOATION  TSOLO",
    Latitude: "-31.1283",
    Longitude: "28.4872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2372",
    WardLookupId: "3098",
    VDNumber: "11620998",
    RegisteredPopulation: "60",
    VotingStationName: "NTYWENKA PLANTATION",
    Address: "NTYWENKA PLANTATION LOCATION  NTYWENKA A/A  TSOLO",
    Latitude: "-31.1741",
    Longitude: "28.618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2373",
    WardLookupId: "3098",
    VDNumber: "11621012",
    RegisteredPopulation: "136",
    VotingStationName: "MAYNARD JUNIOR SECONDARY SCHOOL",
    Address: "MAYNARD  ELUNDINI [MOUNT FLETCHER]",
    Latitude: "-31.1118",
    Longitude: "28.6444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2374",
    WardLookupId: "3098",
    VDNumber: "11621056",
    RegisteredPopulation: "134",
    VotingStationName: "TANDISIZWE COMM & TECHNICAL HIGH SCHOOL",
    Address: "ELUNDINI [  ",
    Latitude: "-31.1605",
    Longitude: "28.5221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2375",
    WardLookupId: "3098",
    VDNumber: "11800909",
    RegisteredPopulation: "310",
    VotingStationName: "NGCELE JUNIOR SECONDARY SCHOOL",
    Address: "NGCELE A/A  NGCELE LOCATION  MACLEAR",
    Latitude: "-31.1557",
    Longitude: "28.5259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2376",
    WardLookupId: "3099",
    VDNumber: "11610076",
    RegisteredPopulation: "163",
    VotingStationName: "NGXAXA GORGE JUNIOR SECONDARY SCHOOL",
    Address: "NGQAYI A/A  QUMBU  QUMBU",
    Latitude: "-30.8989",
    Longitude: "28.6357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2377",
    WardLookupId: "3099",
    VDNumber: "11610290",
    RegisteredPopulation: "340",
    VotingStationName: "KHOHLOPONG JUNIOR SECONDARY SCHOOL",
    Address: "NGQAYI A/A  SIKANSIL LOCATION  QUMBU",
    Latitude: "-30.9993",
    Longitude: "28.5068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2378",
    WardLookupId: "3099",
    VDNumber: "11610313",
    RegisteredPopulation: "267",
    VotingStationName: "TSHIKITSHA JUNIOR SECONDARY SCHOOL",
    Address: "NGQAYI A/A  TSHIKITSHA LOCATION  QUMBU",
    Latitude: "-30.9203",
    Longitude: "28.5671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2379",
    WardLookupId: "3099",
    VDNumber: "11610335",
    RegisteredPopulation: "515",
    VotingStationName: "ETYENI JUNIOR SECONDARY SCHOOL",
    Address: "NGQAYI A/A  KATKOP LOCATION  QUMBU",
    Latitude: "-30.9004",
    Longitude: "28.5482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2380",
    WardLookupId: "3099",
    VDNumber: "11610481",
    RegisteredPopulation: "330",
    VotingStationName: "BOTSABELLO JUNIOR SECONDARY SCHOOL",
    Address: "NGQAYI A/A  NGQAYI LOCATION  MACLEAR",
    Latitude: "-30.9613",
    Longitude: "28.555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2381",
    WardLookupId: "3099",
    VDNumber: "11610492",
    RegisteredPopulation: "539",
    VotingStationName: "UPPER NXAXA SCHOOL",
    Address: "UPPER NXAXA A/A  UPPER NXAXA LOCATION  QUMBU",
    Latitude: "-30.8501",
    Longitude: "28.6018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2382",
    WardLookupId: "3099",
    VDNumber: "11610649",
    RegisteredPopulation: "120",
    VotingStationName: "LOWER KHOHLOPONG SENIOR PRIMARY SCHOOL",
    Address: "MFABANTU LOC.  KHOHLOPONG A/A  QUMBU",
    Latitude: "-31.0413",
    Longitude: "28.5018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2383",
    WardLookupId: "3099",
    VDNumber: "11610650",
    RegisteredPopulation: "92",
    VotingStationName: "UPPER GQUKUNQA UPCSA",
    Address: "GQUKUNQA A/A  GAMAKULU LOCATION  QUMBU",
    Latitude: "-30.9921",
    Longitude: "28.5525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2384",
    WardLookupId: "3099",
    VDNumber: "11610717",
    RegisteredPopulation: "82",
    VotingStationName: "MPINDWENI SENIOR PRIMARY SCHOOL",
    Address: "NGQAYI A/A  JOJWENI LOCATION  QUMBU",
    Latitude: "-31.0109",
    Longitude: "28.5487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2385",
    WardLookupId: "3099",
    VDNumber: "11611066",
    RegisteredPopulation: "204",
    VotingStationName: "NGQQAYI JUNIOR SECONDARY SCHOOL",
    Address: "RAMTIYA LOCATION  NGQAYI A/A  QUMBU",
    Latitude: "-30.9618",
    Longitude: "28.5133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2386",
    WardLookupId: "3099",
    VDNumber: "11611088",
    RegisteredPopulation: "201",
    VotingStationName: "NTABODULI JUNIOR SECONDARY SCHOOL",
    Address: "NTABODULI LOCATION  TYENI A/A  QUMBU",
    Latitude: "-30.9182",
    Longitude: "28.5988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2387",
    WardLookupId: "3099",
    VDNumber: "11611134",
    RegisteredPopulation: "83",
    VotingStationName: "NKAHLULO JUNIOR SECONDARY SCHOOL",
    Address: "ZINKUMBINI LOCATION  ELUNDINI [MOUNT FLETCHER]",
    Latitude: "-30.9248",
    Longitude: "28.5366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2388",
    WardLookupId: "3099",
    VDNumber: "11611224",
    RegisteredPopulation: "202",
    VotingStationName: "GOOD SHEPHERD CHURCH",
    Address: "N/A  UPPER NXAXHA  MACLEAR",
    Latitude: "-30.8346",
    Longitude: "28.6005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2389",
    WardLookupId: "3099",
    VDNumber: "11611235",
    RegisteredPopulation: "123",
    VotingStationName: "HLANGALANE JUNIOR PRIMARY SCHOOL",
    Address: "HLANGALANE  NGQAYI A/A  MACLEAR",
    Latitude: "-30.9973",
    Longitude: "28.5348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2390",
    WardLookupId: "3099",
    VDNumber: "11800303",
    RegisteredPopulation: "374",
    VotingStationName: "NKUNYANA JUNIOR SECONDARY SCHOOL",
    Address: "NGQAYI A/A  KATKOP LOCATION  MT FLETCHER",
    Latitude: "-30.8697",
    Longitude: "28.5401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2391",
    WardLookupId: "3100",
    VDNumber: "11800066",
    RegisteredPopulation: "348",
    VotingStationName: "LUZIE POORT JUNIOR SECONDARY SCHOOL",
    Address: "LUZIE POORT A/A  LUZIE POORT LOCATION  MT FLETCHER",
    Latitude: "-30.7707",
    Longitude: "28.4762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2392",
    WardLookupId: "3100",
    VDNumber: "11800325",
    RegisteredPopulation: "640",
    VotingStationName: "UMFANTA JUNIOR SECONDARY SCHOOL",
    Address: "UMFANTA LOCATION  MT FLETCHER",
    Latitude: "-30.724",
    Longitude: "28.5376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2393",
    WardLookupId: "3100",
    VDNumber: "11800448",
    RegisteredPopulation: "197",
    VotingStationName: "MKAKA STORE",
    Address: "N/A  PITSENG LOCATION  MT FLETCHER",
    Latitude: "-30.7501",
    Longitude: "28.444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2394",
    WardLookupId: "3100",
    VDNumber: "11800493",
    RegisteredPopulation: "332",
    VotingStationName: "MOROKA JUNIOR SECONDARY SCHOOL",
    Address: "MOROKA LOCATION  MT  FLETCHER",
    Latitude: "-30.726",
    Longitude: "28.4885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2395",
    WardLookupId: "3100",
    VDNumber: "11800673",
    RegisteredPopulation: "263",
    VotingStationName: "NKAMANE SENIOR PRIMARY SCHOOL",
    Address: "NKAMANE LOCATION  MT FLETCHER",
    Latitude: "-30.744",
    Longitude: "28.5821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2396",
    WardLookupId: "3100",
    VDNumber: "11800684",
    RegisteredPopulation: "39",
    VotingStationName: "NOMZAMO SENIOR PRIMARY SCHOOL",
    Address: "MBIZENI LOCATION  MOUNT FLETCHER",
    Latitude: "-30.7484",
    Longitude: "28.3606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2397",
    WardLookupId: "3100",
    VDNumber: "11800695",
    RegisteredPopulation: "250",
    VotingStationName: "LOWER KETE-KETE JUNIOR SECONDARY SCHOOL",
    Address: "LOWER KETE-KETE LOCATION  MT FLETCHER",
    Latitude: "-30.7742",
    Longitude: "28.5758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2398",
    WardLookupId: "3100",
    VDNumber: "11800897",
    RegisteredPopulation: "396",
    VotingStationName: "LUZIE DRIFT JUNIOR SECONDARY SCHOOL",
    Address: "LUZIE LOCATION  MT. FLETCHER  MT. FLETCHER",
    Latitude: "-30.7725",
    Longitude: "28.5176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2399",
    WardLookupId: "3100",
    VDNumber: "11801089",
    RegisteredPopulation: "159",
    VotingStationName: "LENGOPENG SENIOR PRIMARY SCHOOL",
    Address: "ZWELITSHA LOCATION  [MOUNT FLETCHER]",
    Latitude: "-30.7932",
    Longitude: "28.5458",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2400",
    WardLookupId: "3100",
    VDNumber: "11801113",
    RegisteredPopulation: "496",
    VotingStationName: "BATLOKOA JUNIOR SECONDARY SCHOOL",
    Address: "KETE KETE LOCALITY  KETE-KETE A/A  MOUNT FLETCHER",
    Latitude: "-30.8034",
    Longitude: "28.5681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2401",
    WardLookupId: "3100",
    VDNumber: "11801203",
    RegisteredPopulation: "164",
    VotingStationName: "THEMBENI SENIOR PRIMARY SCHOOL",
    Address: "MAKHATLANENG LOCATION  MAKHKTALNENG LOCATION  MOUNT FLETCHER",
    Latitude: "-30.7456",
    Longitude: "28.5158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2402",
    WardLookupId: "3100",
    VDNumber: "11801214",
    RegisteredPopulation: "241",
    VotingStationName: "NATLANE SEKONYELA SENIOR PRIMARY SCHOOL",
    Address: "(EC141 - ELUNDINI [MOUNT FLETCHER])   ",
    Latitude: "-30.7189",
    Longitude: "28.5223",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2403",
    WardLookupId: "3101",
    VDNumber: "11710011",
    RegisteredPopulation: "1907",
    VotingStationName: "MOUNT FLETCHER TOWN HALL",
    Address: "BACK STREET  MNT FLETCHER  MNT FLETCHER",
    Latitude: "-30.6927",
    Longitude: "28.5042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2404",
    WardLookupId: "3101",
    VDNumber: "11710022",
    RegisteredPopulation: "1058",
    VotingStationName: "LINGELETHU JUNIOR SECONDARY SCHOOL",
    Address: "111 BACK STREET  ILISO LOMZI LOCATION  MNT FLETCHER",
    Latitude: "-30.6955",
    Longitude: "28.4988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2405",
    WardLookupId: "3101",
    VDNumber: "11710055",
    RegisteredPopulation: "689",
    VotingStationName: "BRA T  CAFE TENT",
    Address: "THEMBENI LOC  CASTEL ROCKS A/A  MT FLETCHER",
    Latitude: "-30.7021",
    Longitude: "28.521",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2406",
    WardLookupId: "3101",
    VDNumber: "11710066",
    RegisteredPopulation: "306",
    VotingStationName: "SHOW GROUNDS  TENT",
    Address: "MPHARANE  CASTLE ROCKS  MOUNT FLETCHER",
    Latitude: "-30.6824",
    Longitude: "28.5076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2407",
    WardLookupId: "3102",
    VDNumber: "11800011",
    RegisteredPopulation: "565",
    VotingStationName: "EZINGONYAMEN TRIBAL AUTHORITY",
    Address: "EZINGONYAMENI LOCATION  EZINGONYAMENI  MT FLETCHER",
    Latitude: "-30.6099",
    Longitude: "28.5235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2408",
    WardLookupId: "3102",
    VDNumber: "11800077",
    RegisteredPopulation: "448",
    VotingStationName: "EZINGONYAMENI JUNIOR SECONDARY SCHOOL",
    Address: "EZINGONYAMENI LOCATION  MOUNT FLETCHER",
    Latitude: "-30.6045",
    Longitude: "28.5362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2409",
    WardLookupId: "3102",
    VDNumber: "11800156",
    RegisteredPopulation: "484",
    VotingStationName: "MBAMBANGWE JUNIOR SECONDARY SCHOOL",
    Address: "N/A  MBAMBANGWE LOCATION  MT FLETCHER",
    Latitude: "-30.6349",
    Longitude: "28.5415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2410",
    WardLookupId: "3102",
    VDNumber: "11800167",
    RegisteredPopulation: "688",
    VotingStationName: "ST THOMAS JUNIOR SECONDARY SCHOOL",
    Address: "MPHARANE LOCATION  MT FLETCHER",
    Latitude: "-30.6632",
    Longitude: "28.522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2411",
    WardLookupId: "3102",
    VDNumber: "11800459",
    RegisteredPopulation: "422",
    VotingStationName: "ZAMUXOLO JUNIOR SECONDARY SCHOOL",
    Address: "XAXAZANA LOCATION  MT FLETCHER",
    Latitude: "-30.5821",
    Longitude: "28.5232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2412",
    WardLookupId: "3102",
    VDNumber: "11800482",
    RegisteredPopulation: "848",
    VotingStationName: "TSOLOBENG JUNIOR SECONDARY SCHOOL",
    Address: "TSOLOBENG  TSOLOBENG LOCATION  MT FLETCHER",
    Latitude: "-30.6418",
    Longitude: "28.4726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2413",
    WardLookupId: "3102",
    VDNumber: "11800662",
    RegisteredPopulation: "49",
    VotingStationName: "ESILINDINI JUNIOR SECONDARY SCHOOL",
    Address: "ESILUNDINI  ESILINDINI LOCATION  MT. FLETCHER",
    Latitude: "-30.6596",
    Longitude: "28.5493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2414",
    WardLookupId: "3102",
    VDNumber: "11801124",
    RegisteredPopulation: "528",
    VotingStationName: "MAHEMENG SENIOR PRIMARY SCHOOL",
    Address: "TSOLOBENG LOCATION  ELUNDINI [MOUNT FLETCHER]",
    Latitude: "-30.6443",
    Longitude: "28.458",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2415",
    WardLookupId: "3103",
    VDNumber: "11800189",
    RegisteredPopulation: "231",
    VotingStationName: "TINANA JUNIOR SECONDARY SCHOOL",
    Address: "TINANA LOCATION  MT FLETCHER",
    Latitude: "-30.5516",
    Longitude: "28.4341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2416",
    WardLookupId: "3103",
    VDNumber: "11800190",
    RegisteredPopulation: "361",
    VotingStationName: "DENGWANE JUNIOR SECONDARY SCHOOL",
    Address: "DENGWANE A/A  DENGWANE LOCATION  MT FLETCHER",
    Latitude: "-30.5864",
    Longitude: "28.4923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2417",
    WardLookupId: "3103",
    VDNumber: "11800213",
    RegisteredPopulation: "351",
    VotingStationName: "FLETCHERVILLE JUNIOR SECONDARY SCHOOL",
    Address: "FLETCHERVILLE  FLETCHERVILLE LOCATION  MT FLETCHER",
    Latitude: "-30.6117",
    Longitude: "28.4176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2418",
    WardLookupId: "3103",
    VDNumber: "11800235",
    RegisteredPopulation: "144",
    VotingStationName: "MAFUSINI JUNIOR PRIMARY SCHOOL",
    Address: "TABASE LOCATION  MT FLETCHER",
    Latitude: "-30.5278",
    Longitude: "28.3272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2419",
    WardLookupId: "3103",
    VDNumber: "11800527",
    RegisteredPopulation: "493",
    VotingStationName: "FARVIEW JUNIOR SECONDARY SCHOOL",
    Address: "FARVIEW LOCATION  MT FLETCHER",
    Latitude: "-30.5581",
    Longitude: "28.494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2420",
    WardLookupId: "3103",
    VDNumber: "11800752",
    RegisteredPopulation: "168",
    VotingStationName: "LERATO TENT",
    Address: "FLETCHERVILLE LOCATION  MT FLETCHER",
    Latitude: "-30.602",
    Longitude: "28.4288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2421",
    WardLookupId: "3103",
    VDNumber: "11800774",
    RegisteredPopulation: "198",
    VotingStationName: "NGAQANGANA JUNIOR SECONDARY SCHOOL",
    Address: "NGAQANGANA LOCATION  MT. FLETCHER  MT. FLETCHER",
    Latitude: "-30.588",
    Longitude: "28.4593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2422",
    WardLookupId: "3103",
    VDNumber: "11800785",
    RegisteredPopulation: "627",
    VotingStationName: "LUBISINI JUNIOR SECONDARY SCHOOL",
    Address: "MT. FLETCHER  ",
    Latitude: "-30.5548",
    Longitude: "28.4761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2423",
    WardLookupId: "3103",
    VDNumber: "11800796",
    RegisteredPopulation: "460",
    VotingStationName: "KHULANATHI JUNIOR SECONDARY SCHOOL",
    Address: "(EC141 - ELUNDINI [MOUNT FLETCHER])   ",
    Latitude: "-30.5496",
    Longitude: "28.4263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2424",
    WardLookupId: "3103",
    VDNumber: "11800808",
    RegisteredPopulation: "129",
    VotingStationName: "SIXHOTYENI SENIOR PRIMARY SCHOOL",
    Address: "SIXHOTYENI LOCATION  MT- FLETCHER",
    Latitude: "-30.5295",
    Longitude: "28.3971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2425",
    WardLookupId: "3103",
    VDNumber: "11800831",
    RegisteredPopulation: "190",
    VotingStationName: "MOTSOANE SENIOR PRIMARY SCHOOL",
    Address: "MATSOANE  MT. FLETCHER",
    Latitude: "-30.607",
    Longitude: "28.4506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2426",
    WardLookupId: "3103",
    VDNumber: "11800943",
    RegisteredPopulation: "185",
    VotingStationName: "NOXOLO PRE SCHOOL",
    Address: "N/A  XAXAZANA LOCATION  MT. FLETCHER",
    Latitude: "-30.5704",
    Longitude: "28.5041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2427",
    WardLookupId: "3104",
    VDNumber: "11800291",
    RegisteredPopulation: "384",
    VotingStationName: "SETAKA JUNIOR SECONDARY SCHOOL",
    Address: "THABATLALA LOCATION  MT FLETCHER",
    Latitude: "-30.5123",
    Longitude: "28.527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2428",
    WardLookupId: "3104",
    VDNumber: "11800550",
    RegisteredPopulation: "239",
    VotingStationName: "POPOPO SCHOOL",
    Address: "POPOPO  A/A  POPOPO LOCATION  MT FLETCHER",
    Latitude: "-30.46",
    Longitude: "28.5492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2429",
    WardLookupId: "3104",
    VDNumber: "11800561",
    RegisteredPopulation: "502",
    VotingStationName: "MANGOLOANENG JUNIOR SECONDARY SCHOOL",
    Address: "MANGOLOANENG LOCATION  MT FLETCHER",
    Latitude: "-30.5097",
    Longitude: "28.4679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2430",
    WardLookupId: "3104",
    VDNumber: "11800718",
    RegisteredPopulation: "530",
    VotingStationName: "MORULANE JUNIOR SECONDARY SCHOOL",
    Address: "MAKGADITSENG LOCATION  MT FLETCHER",
    Latitude: "-30.5082",
    Longitude: "28.5624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2431",
    WardLookupId: "3104",
    VDNumber: "11800763",
    RegisteredPopulation: "179",
    VotingStationName: "NTABAYIKHONJWA  SHOP",
    Address: "NTABAYIKHONJWA LOCATION  NGOLILOE /A/A  MT FLETCHER",
    Latitude: "-30.5438",
    Longitude: "28.4573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2432",
    WardLookupId: "3104",
    VDNumber: "11800819",
    RegisteredPopulation: "300",
    VotingStationName: "PHAPHAMA SENIOR PRIMARY SCHOOL",
    Address: "MOLEEKO LOCATION  ELUNDINI MT FLETCHER",
    Latitude: "-30.4882",
    Longitude: "28.5335",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2433",
    WardLookupId: "3104",
    VDNumber: "11800820",
    RegisteredPopulation: "326",
    VotingStationName: "POLOKOE JUNIOR SECONDARY SCHOOL",
    Address: "POLOKOE LOCATION  MT. FLETCHER",
    Latitude: "-30.5276",
    Longitude: "28.5427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2434",
    WardLookupId: "3104",
    VDNumber: "11800842",
    RegisteredPopulation: "423",
    VotingStationName: "NGOLILOE JUNIOR SECONDARY SCHOOL",
    Address: "NGOLILOE LOCATION  MT. FLETCHER",
    Latitude: "-30.5267",
    Longitude: "28.4432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2435",
    WardLookupId: "3104",
    VDNumber: "11800875",
    RegisteredPopulation: "281",
    VotingStationName: "MAHANYANENG JUNIOR SECONDARY SCHOOL",
    Address: "MAHANYANENG LOCATION  MT. FLETCHER",
    Latitude: "-30.489",
    Longitude: "28.4365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2436",
    WardLookupId: "3104",
    VDNumber: "11801258",
    RegisteredPopulation: "225",
    VotingStationName: "LEHLAKANENG PRE SCHOOL",
    Address: "LEHLAKANENG  KINIRA POORT A/A  MOUNT FLETCHER NU",
    Latitude: "-30.4859",
    Longitude: "28.5761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2437",
    WardLookupId: "3105",
    VDNumber: "11800246",
    RegisteredPopulation: "267",
    VotingStationName: "HLANGANISA JUNIOR SECONDARY SCHOOL",
    Address: "NXOTSHANA  NXOTSHANA LOCATION  MT FLETCHER",
    Latitude: "-30.5778",
    Longitude: "28.3404",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2438",
    WardLookupId: "3105",
    VDNumber: "11800268",
    RegisteredPopulation: "455",
    VotingStationName: "SEQHOBONG JUNIOR SECONDARY SCHOOL",
    Address: "SEQHOBONG LOCATION  MT FLETCHER",
    Latitude: "-30.4336",
    Longitude: "28.425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2439",
    WardLookupId: "3105",
    VDNumber: "11800280",
    RegisteredPopulation: "491",
    VotingStationName: "MOHOABATSANE JUNIOR SECONDARY SCHOOL",
    Address: "MOHOABATSANE LOCATION  MT FLETCHER",
    Latitude: "-30.464",
    Longitude: "28.4534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2440",
    WardLookupId: "3105",
    VDNumber: "11800538",
    RegisteredPopulation: "506",
    VotingStationName: "MAKOATLANE JUNIOR SECONDARY SCHOOL",
    Address: "MAKOATLANE LOCATION  MT FLETCHER",
    Latitude: "-30.5831",
    Longitude: "28.3775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2441",
    WardLookupId: "3105",
    VDNumber: "11800549",
    RegisteredPopulation: "441",
    VotingStationName: "KOEBONG JUNIOR SECONDARY SCHOOL",
    Address: "KOEBONG LOCATION  MT FLETCHER",
    Latitude: "-30.414",
    Longitude: "28.3891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2442",
    WardLookupId: "3105",
    VDNumber: "11800628",
    RegisteredPopulation: "290",
    VotingStationName: "MOSANA JUNIOR SECONDARY SCHOOL",
    Address: "SETHATHI LOCATION  MT FLETCHER",
    Latitude: "-30.4637",
    Longitude: "28.4933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2443",
    WardLookupId: "3105",
    VDNumber: "11800853",
    RegisteredPopulation: "269",
    VotingStationName: "NTOKO JUNIOR SECONDARY SCHOOL",
    Address: "NTOKO LOCATION  MT. FLETCHER",
    Latitude: "-30.4467",
    Longitude: "28.4554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2444",
    WardLookupId: "3105",
    VDNumber: "11800886",
    RegisteredPopulation: "483",
    VotingStationName: "LOWER NXOTSHANA JUNIOR SECONDARY SCHOOL",
    Address: "LOWER NXOTSHANA LOCATION  MT. FLETCHER",
    Latitude: "-30.5593",
    Longitude: "28.3491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2445",
    WardLookupId: "3105",
    VDNumber: "11801001",
    RegisteredPopulation: "189",
    VotingStationName: "MASHATA PRESBYTERIAN  CHURCH",
    Address: "MASHATA LOCATION  MASHATA  LOCATION  MOUNT FLETCHER",
    Latitude: "-30.4334",
    Longitude: "28.4785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2446",
    WardLookupId: "3105",
    VDNumber: "11801135",
    RegisteredPopulation: "240",
    VotingStationName: "MALUTI SENIOR PRIMARY SCHOOL",
    Address: "TABASE LOCATION  ELUNDINI [MOUNT FLETCHER]",
    Latitude: "-30.5041",
    Longitude: "28.2742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2447",
    WardLookupId: "3106",
    VDNumber: "11800088",
    RegisteredPopulation: "494",
    VotingStationName: "VUVU JUNIOR SECONDARY SCHOOL",
    Address: "VUVU LOCATION  MT FLETCHER",
    Latitude: "-30.6117",
    Longitude: "28.238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2448",
    WardLookupId: "3106",
    VDNumber: "11800099",
    RegisteredPopulation: "550",
    VotingStationName: "BETHANIA JUNIOR SECONDARY SCHOOL",
    Address: "BETHANIA LOCATION  ELUDINI MT FLETCHER",
    Latitude: "-30.6551",
    Longitude: "28.2985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2449",
    WardLookupId: "3106",
    VDNumber: "11800101",
    RegisteredPopulation: "549",
    VotingStationName: "BETHANIA SENIOR SECONDARY SCHOOL",
    Address: "MAKHWASENG LOCATION  MT FLETCHER",
    Latitude: "-30.6516",
    Longitude: "28.2767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2450",
    WardLookupId: "3106",
    VDNumber: "11800224",
    RegisteredPopulation: "342",
    VotingStationName: "NQALWENI JUNIOR SECONDARY SCHOOL",
    Address: "PHIRINTSU LOCATION  MT FLETCHER",
    Latitude: "-30.5879",
    Longitude: "28.3023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2451",
    WardLookupId: "3106",
    VDNumber: "11800471",
    RegisteredPopulation: "730",
    VotingStationName: "MAMONTOELI JUNIOR SECONDARY SCHOOL",
    Address: "REFELE LOCATION  MAMUNTWELI A/A  MOUNT FLETCHER",
    Latitude: "-30.6435",
    Longitude: "28.36",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2452",
    WardLookupId: "3106",
    VDNumber: "11800640",
    RegisteredPopulation: "182",
    VotingStationName: "UPPER PHIRINTSU JUNIOR SECONDARY SCHOOL",
    Address: "UPPER PHIRINTSU LOCATION  MT FLETCHER",
    Latitude: "-30.586",
    Longitude: "28.2708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2453",
    WardLookupId: "3106",
    VDNumber: "11800741",
    RegisteredPopulation: "386",
    VotingStationName: "ZANYENI JUNIOR SECONDARY SCHOOL",
    Address: "MT. FLETCHER  ",
    Latitude: "-30.5881",
    Longitude: "28.3293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2454",
    WardLookupId: "3106",
    VDNumber: "11800864",
    RegisteredPopulation: "270",
    VotingStationName: "LEHANAS PASS JUNIOR SECONDARY SCHOOL",
    Address: "MAPAUNG LOCATION  BETHANIA A/A  MOUNT FLETCHER",
    Latitude: "-30.6516",
    Longitude: "28.2155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2455",
    WardLookupId: "3106",
    VDNumber: "11801146",
    RegisteredPopulation: "325",
    VotingStationName: "THABAKHUBEDU JUNIOR SECONDARY SCHOOL",
    Address: "THABAKHUBEDU LOCATION  ELUNDINI [MOUNT FLETCHER]",
    Latitude: "-30.646",
    Longitude: "28.2467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2456",
    WardLookupId: "3106",
    VDNumber: "11801292",
    RegisteredPopulation: "175",
    VotingStationName: "UPPER THOKOANA SENIOR PRIMARY SCHOOL",
    Address: "N/A  THOKOANA A/A  MT FLETCHER",
    Latitude: "-30.6505",
    Longitude: "28.3845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2457",
    WardLookupId: "3106",
    VDNumber: "11801304",
    RegisteredPopulation: "71",
    VotingStationName: "PHUTHING SENIOR PRIMARY SCHOOL",
    Address: "N/A  PHIRINTSU A/A  MT FLETCHER",
    Latitude: "-30.5686",
    Longitude: "28.2546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2458",
    WardLookupId: "3107",
    VDNumber: "11710033",
    RegisteredPopulation: "1236",
    VotingStationName: "PUMAPHETHE SPAZA",
    Address: "MANELI STREET  NKULULEKWENI LOCATION  MT FLETCHER",
    Latitude: "-30.6833",
    Longitude: "28.4978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2459",
    WardLookupId: "3107",
    VDNumber: "11710044",
    RegisteredPopulation: "800",
    VotingStationName: "THEMBALETHU CAFE TENT",
    Address: "KUTLAONONG LOCATION  KUTLAONONG  MT FLETCHER",
    Latitude: "-30.6942",
    Longitude: "28.4915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2460",
    WardLookupId: "3107",
    VDNumber: "11800112",
    RegisteredPopulation: "471",
    VotingStationName: "THOKOANA JUNIOR SECONDARY SCHOOL",
    Address: "THOKOANA A/A  THOKOANA LOCATION  MT FLETCHER",
    Latitude: "-30.6606",
    Longitude: "28.4443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2461",
    WardLookupId: "3107",
    VDNumber: "11800134",
    RegisteredPopulation: "297",
    VotingStationName: "KHALATSU SENIOR PRIMARY SCHOOL",
    Address: "KHALATSU LOCATION  MT FLETCHER",
    Latitude: "-30.6911",
    Longitude: "28.4473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2462",
    WardLookupId: "3107",
    VDNumber: "11800505",
    RegisteredPopulation: "494",
    VotingStationName: "HILL GATE TRIBAL AUTHORITY",
    Address: "TSEKONG LOCATION  MT FLETCHER",
    Latitude: "-30.6982",
    Longitude: "28.4841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2463",
    WardLookupId: "3107",
    VDNumber: "11801090",
    RegisteredPopulation: "181",
    VotingStationName: "LIPHAKOENG SENIOR PRIMARY SCHOOL",
    Address: "LIPHAKOENG LOCATION  ELUNDINI [MOUNT FLETCHER]",
    Latitude: "-30.6368",
    Longitude: "28.4307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2464",
    WardLookupId: "3107",
    VDNumber: "11801102",
    RegisteredPopulation: "95",
    VotingStationName: "LIPHOFONG JUNIOR SECONDARY SCHOOL",
    Address: "LIPHOFUNG LOCATION  ELUNDINI [MOUNT FLETCHER]",
    Latitude: "-30.6999",
    Longitude: "28.3271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2465",
    WardLookupId: "3107",
    VDNumber: "11801236",
    RegisteredPopulation: "212",
    VotingStationName: "MAKHULENG SENIOR PRIMARY SCHOOL",
    Address: "MOUNT FLETCHER  ",
    Latitude: "-30.7071",
    Longitude: "28.4656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2466",
    WardLookupId: "3108",
    VDNumber: "11610302",
    RegisteredPopulation: "516",
    VotingStationName: "FRANK MOSHOESHOE JUNIOR SECONDARY SCHOOL",
    Address: "NGQAYI A/A  MDENI LOCATION  MACLEAR",
    Latitude: "-30.9075",
    Longitude: "28.498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2467",
    WardLookupId: "3108",
    VDNumber: "11611077",
    RegisteredPopulation: "201",
    VotingStationName: "TAUNG JUNIOR SECONDARY SCHOOL",
    Address: "TAUNG LOCATION  MT. FLETCHER  MT. FLETCHER",
    Latitude: "-30.9397",
    Longitude: "28.4644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2468",
    WardLookupId: "3108",
    VDNumber: "11611145",
    RegisteredPopulation: "141",
    VotingStationName: "METHODIST CHURCH",
    Address: "ELUNDINI [MOUNT FLETCHER]  ",
    Latitude: "-30.92",
    Longitude: "28.4998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2469",
    WardLookupId: "3108",
    VDNumber: "11800022",
    RegisteredPopulation: "292",
    VotingStationName: "CHEVY-CHASE JUNIOR SECONDARY SCHOOL",
    Address: "CHEVY-CHASE A/A  CHEVY-CHASE LOCATION  MOUNT FLETCHER",
    Latitude: "-30.8347",
    Longitude: "28.5181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2470",
    WardLookupId: "3108",
    VDNumber: "11800044",
    RegisteredPopulation: "384",
    VotingStationName: "MHLONTLO SENIOR SECONDARY SCHOOL",
    Address: "MHLONTLO A/A  HLANKOMO LOCATION  MT FLETCHER",
    Latitude: "-30.9101",
    Longitude: "28.4293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2471",
    WardLookupId: "3108",
    VDNumber: "11800257",
    RegisteredPopulation: "321",
    VotingStationName: "KETE KETE JUNIOR SECONDARY SCHOOL",
    Address: "KETE KETE  LOCALITY  CHEVY CHASE A/A  MOUNT FLETCHER",
    Latitude: "-30.8334",
    Longitude: "28.5403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2472",
    WardLookupId: "3108",
    VDNumber: "11800437",
    RegisteredPopulation: "694",
    VotingStationName: "SOLOMZI JUNIOR SECONDARY SCHOOL",
    Address: "MHLONTLO A/A  MDENI LOCATION  MT FLETCHER",
    Latitude: "-30.92",
    Longitude: "28.471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2473",
    WardLookupId: "3108",
    VDNumber: "11800707",
    RegisteredPopulation: "501",
    VotingStationName: "MAGEDLA JUNIOR SECONDARY SCHOOL",
    Address: "HLANKOMO A/A  MAGEDLA LOCATION  MT FLETCHER",
    Latitude: "-30.8833",
    Longitude: "28.4762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2474",
    WardLookupId: "3108",
    VDNumber: "11800921",
    RegisteredPopulation: "285",
    VotingStationName: "CAMBALALA SENIOR PRIMARY SCHOOL",
    Address: "MCAMBALALA LOCATION  MHLONTLO A/A  MCAMBALALA LOCATION",
    Latitude: "-30.9128",
    Longitude: "28.4064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2475",
    WardLookupId: "3108",
    VDNumber: "11800932",
    RegisteredPopulation: "186",
    VotingStationName: "KOLONI SENIOR PRIMARY SCHOOL",
    Address: "KOLONI LOCATION  MT. FLETCHER  MT. FLETCHER",
    Latitude: "-30.8737",
    Longitude: "28.4472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2476",
    WardLookupId: "3108",
    VDNumber: "11801247",
    RegisteredPopulation: "157",
    VotingStationName: "TLOKOENG SENIOR PRIMARY SCHOOL",
    Address: "MHLONTLO  MHLONTLO LOCATION, MOUNT FLETCHER  MHLONTLO A/A",
    Latitude: "-30.93",
    Longitude: "28.4194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2477",
    WardLookupId: "3108",
    VDNumber: "11801315",
    RegisteredPopulation: "140",
    VotingStationName: "THEMBALETHU  SENIOR  PRIMARY  SCHOOL",
    Address: "MDENI VILLAGE  HLANKOMO A/A  MACLEAR",
    Latitude: "-30.9043",
    Longitude: "28.4747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2478",
    WardLookupId: "3109",
    VDNumber: "11290070",
    RegisteredPopulation: "695",
    VotingStationName: "VINCENT PRE-SCHOOL",
    Address: "(EC141 - ELUNDINI [MOUNT FLETCHER])   ",
    Latitude: "-31.0833",
    Longitude: "28.3743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2479",
    WardLookupId: "3109",
    VDNumber: "11290081",
    RegisteredPopulation: "480",
    VotingStationName: "CHEBENCA PRIMARY SCHOOLL",
    Address: "GREENFIELDS  GREENFIELDS LOCATION  MACLEAR",
    Latitude: "-31.0916",
    Longitude: "28.3759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2480",
    WardLookupId: "3109",
    VDNumber: "11330031",
    RegisteredPopulation: "319",
    VotingStationName: "KRUGER HALL",
    Address: "MUNICIPALITY BUILDING  UGIE  UGIE",
    Latitude: "-31.207",
    Longitude: "28.2307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2481",
    WardLookupId: "3109",
    VDNumber: "11420020",
    RegisteredPopulation: "70",
    VotingStationName: "ROSARY FARM",
    Address: "CHEBENCA FARM  MACLEAR",
    Latitude: "-31.104",
    Longitude: "28.3909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2482",
    WardLookupId: "3109",
    VDNumber: "11420031",
    RegisteredPopulation: "102",
    VotingStationName: "RETREAT FARM TENT",
    Address: "UGIE  RETREAT FARM  UGIE",
    Latitude: "-31.3182",
    Longitude: "28.3188",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2483",
    WardLookupId: "3109",
    VDNumber: "11420053",
    RegisteredPopulation: "41",
    VotingStationName: "VIPAN FARM SCHOOL",
    Address: "MOUNT FLETCHER  ",
    Latitude: "-31.0233",
    Longitude: "28.4347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2484",
    WardLookupId: "3109",
    VDNumber: "11420064",
    RegisteredPopulation: "27",
    VotingStationName: "NCOTHA FARM SCHOOL",
    Address: "MOUNT FLETCHER  ",
    Latitude: "-31.0912",
    Longitude: "28.4434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2485",
    WardLookupId: "3109",
    VDNumber: "11620011",
    RegisteredPopulation: "400",
    VotingStationName: "MBIDLANA JUNIOR SECONDARY SCHOOL",
    Address: "MBIDLANA LOCATION  TSOLO",
    Latitude: "-31.2119",
    Longitude: "28.4202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2486",
    WardLookupId: "3109",
    VDNumber: "11620381",
    RegisteredPopulation: "583",
    VotingStationName: "GQAQHALA ST PATRICK MISSION",
    Address: "GQAQHALA  GQAQHALA LOCATION  TSOLO",
    Latitude: "-31.2819",
    Longitude: "28.3897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2487",
    WardLookupId: "3109",
    VDNumber: "11800730",
    RegisteredPopulation: "912",
    VotingStationName: "UGIE  PARK TENT",
    Address: "UGIE PARK  UGIE  UGIE",
    Latitude: "-31.212",
    Longitude: "28.2379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2488",
    WardLookupId: "3110",
    VDNumber: "11390059",
    RegisteredPopulation: "684",
    VotingStationName: "BIKIZANA JUNIOR SECONDARY SCHOOL",
    Address: "BIKIZANA  STERKSPRUIT",
    Latitude: "-30.345",
    Longitude: "27.45",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2489",
    WardLookupId: "3110",
    VDNumber: "11390071",
    RegisteredPopulation: "942",
    VotingStationName: "MBOBO JUNIOR SECONDARY SCHOOL",
    Address: "MBOBO  STERKSPRUIT",
    Latitude: "-30.35",
    Longitude: "27.4115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2490",
    WardLookupId: "3110",
    VDNumber: "11390093",
    RegisteredPopulation: "578",
    VotingStationName: "WALAZA VILLAGE SCHOOL",
    Address: "WALAZA  STERKSPRUIT",
    Latitude: "-30.4196",
    Longitude: "27.3657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2491",
    WardLookupId: "3110",
    VDNumber: "11390341",
    RegisteredPopulation: "333",
    VotingStationName: "NXASANA JUNIOR SECONDARY SCHOOL",
    Address: "NXASANA  NDOFELA  HERSCHEL",
    Latitude: "-30.3943",
    Longitude: "27.422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2492",
    WardLookupId: "3110",
    VDNumber: "11390666",
    RegisteredPopulation: "441",
    VotingStationName: "NDOFELA JUNIOR SECONDARY SCHOOL",
    Address: "NDOFELA VILLAGE  HERSCHEL  HERSCHEL",
    Latitude: "-30.3768",
    Longitude: "27.4367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2493",
    WardLookupId: "3110",
    VDNumber: "11390677",
    RegisteredPopulation: "641",
    VotingStationName: "GOVERNORS DRIFT JUNIOR SECONDARY SCHOOL",
    Address: "GOVERNORS DRIFT  WALAZA VILLAGE  STERKSPRUIT",
    Latitude: "-30.4065",
    Longitude: "27.3589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2494",
    WardLookupId: "3111",
    VDNumber: "11390037",
    RegisteredPopulation: "562",
    VotingStationName: "QHIMIRHA JUNIOR SECONDARY SCHOOL",
    Address: "QHIMIRHA  STERKSPRUIT",
    Latitude: "-30.3806",
    Longitude: "27.4761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2495",
    WardLookupId: "3111",
    VDNumber: "11390127",
    RegisteredPopulation: "903",
    VotingStationName: "STOROMO JUNIOR SECONDARY SCHOOL",
    Address: "STOROMO VILLAGE  PALMIETFONTEIN  HERSCHEL",
    Latitude: "-30.4147",
    Longitude: "27.5306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2496",
    WardLookupId: "3111",
    VDNumber: "11390206",
    RegisteredPopulation: "928",
    VotingStationName: "NDINGISHE JUNIOR SECONDARY SCHOOL",
    Address: "NDINGISHE VILLAGE  STERKSPRUIT",
    Latitude: "-30.4398",
    Longitude: "27.487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2497",
    WardLookupId: "3111",
    VDNumber: "11390688",
    RegisteredPopulation: "863",
    VotingStationName: "GCINA JUNIOR SECONDARY SCHOOL",
    Address: "GCINA VILLAGE  STERKSPRUIT",
    Latitude: "-30.425",
    Longitude: "27.4842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2498",
    WardLookupId: "3111",
    VDNumber: "11390699",
    RegisteredPopulation: "415",
    VotingStationName: "TELLE JUNCTION JUNIOR SECONDARY SCHOOL",
    Address: "QHOBOSHANE VILLAGE  STERKSPRUIT  QHOBOSHANE",
    Latitude: "-30.435",
    Longitude: "27.5477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2499",
    WardLookupId: "3111",
    VDNumber: "11390756",
    RegisteredPopulation: "372",
    VotingStationName: "APOSTOLIC FAITH MISSION",
    Address: "QOBOSHANE VILLAGE  QOBOSHANE  STERKSPRUIT",
    Latitude: "-30.4158",
    Longitude: "27.5467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2500",
    WardLookupId: "3112",
    VDNumber: "11390161",
    RegisteredPopulation: "698",
    VotingStationName: "CEBANO JUNIOR SECONDARY SCHOOL",
    Address: "MFINCI VILLAGE  MFINCI  STERKSPRUIT",
    Latitude: "-30.5364",
    Longitude: "27.5612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2501",
    WardLookupId: "3112",
    VDNumber: "11390194",
    RegisteredPopulation: "1132",
    VotingStationName: "QOBOSHANE JUNIOR SECONDARY SCHOOL",
    Address: "QHOBOSHANE VILLAGE  QOBOSHANE  STERKSPRUIT",
    Latitude: "-30.4435",
    Longitude: "27.5634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2502",
    WardLookupId: "3112",
    VDNumber: "11390217",
    RegisteredPopulation: "1007",
    VotingStationName: "TLOKWENG SCHOOL",
    Address: "PALMIETFONTEIN VILLAGE  PALMIETFONTEIN  STERKSPRUIT",
    Latitude: "-30.4272",
    Longitude: "27.5055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2503",
    WardLookupId: "3112",
    VDNumber: "11390251",
    RegisteredPopulation: "619",
    VotingStationName: "HOHOBENG JUNIOR SECONDARY SCHOOL",
    Address: "PALMIETFONTEIN VILLAGE  PALMIETFONTEIN  STERKSPRUIT",
    Latitude: "-30.4568",
    Longitude: "27.528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2504",
    WardLookupId: "3112",
    VDNumber: "11390273",
    RegisteredPopulation: "288",
    VotingStationName: "EKRA JUNIOR SECONDARY SCHOOL",
    Address: "MMUSONG VILLAGE  PALMIETFONTEIN  HERSCHEL",
    Latitude: "-30.4888",
    Longitude: "27.5732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2505",
    WardLookupId: "3113",
    VDNumber: "11390048",
    RegisteredPopulation: "820",
    VotingStationName: "MABELE JUNIOR SECONDARY SCHOOL",
    Address: "MABELE VILLGE  MABELE  HERSCHEL",
    Latitude: "-30.6057",
    Longitude: "27.7431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2506",
    WardLookupId: "3113",
    VDNumber: "11390116",
    RegisteredPopulation: "292",
    VotingStationName: "BEBEZA SENIOR PRIMARY SCHOOL",
    Address: "BEBEZA VILLAGE  BEBEZA  HERSCHEL",
    Latitude: "-30.5567",
    Longitude: "27.6695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2507",
    WardLookupId: "3113",
    VDNumber: "11390295",
    RegisteredPopulation: "807",
    VotingStationName: "MKUNYAZO JUNIOR SECONDARY SCHOOL",
    Address: "MKKUNYAZO VILLAGE  MKUNYAZO  HERSCHEL",
    Latitude: "-30.5739",
    Longitude: "27.6157",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2508",
    WardLookupId: "3113",
    VDNumber: "11390598",
    RegisteredPopulation: "748",
    VotingStationName: "KWABO JUNIOR SECONDARY SCHOOL",
    Address: "KWABO VILLAGE  HERSCHEL",
    Latitude: "-30.5289",
    Longitude: "27.661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2509",
    WardLookupId: "3113",
    VDNumber: "11390600",
    RegisteredPopulation: "499",
    VotingStationName: "MAKUMSHA JUNIOR SECONDARY SCHOOL",
    Address: "MAKUMSHA  VILLAGE  STERKSPRUIT",
    Latitude: "-30.5674",
    Longitude: "27.7048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2510",
    WardLookupId: "3113",
    VDNumber: "11390611",
    RegisteredPopulation: "367",
    VotingStationName: "NTLANGANO JUNIOR SECONDARY SCHOOL",
    Address: "NTLANGANO  VILLAGE  HERSCHEL",
    Latitude: "-30.5371",
    Longitude: "27.6023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2511",
    WardLookupId: "3114",
    VDNumber: "11370013",
    RegisteredPopulation: "180",
    VotingStationName: "NEW ENGLAND FARM",
    Address: "N/A  BARKLY EAST  BARKLY EAST",
    Latitude: "-30.862",
    Longitude: "27.4911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2512",
    WardLookupId: "3114",
    VDNumber: "11370024",
    RegisteredPopulation: "137",
    VotingStationName: "CLIFFORD BARN",
    Address: "CLIFFORD BARN FARM  BARKLY EAST  BARKLY EAST",
    Latitude: "-31.0812",
    Longitude: "27.4488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2513",
    WardLookupId: "3114",
    VDNumber: "11390082",
    RegisteredPopulation: "594",
    VotingStationName: "SAKHAKUDE JUNIOR SECONDARY SCHOOL",
    Address: "PELANDABA  STERKSPRUIT",
    Latitude: "-30.6055",
    Longitude: "27.5952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2514",
    WardLookupId: "3114",
    VDNumber: "11390105",
    RegisteredPopulation: "402",
    VotingStationName: "ZINGXENGENE JUNIOR SECONDARY SCHOOL",
    Address: "PELANDABA  STERKSPRUIT",
    Latitude: "-30.6215",
    Longitude: "27.5764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2515",
    WardLookupId: "3114",
    VDNumber: "11390150",
    RegisteredPopulation: "609",
    VotingStationName: "HILLSIDE JUNIOR SECONDARY SCHOOL",
    Address: "HILLSIDE  STERKSPRUIT",
    Latitude: "-30.5628",
    Longitude: "27.5651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2516",
    WardLookupId: "3114",
    VDNumber: "11390239",
    RegisteredPopulation: "424",
    VotingStationName: "PELANDABA JUNIOR SECONDARY SCHOOL",
    Address: "PELANDABA  STERKSPRUIT",
    Latitude: "-30.6422",
    Longitude: "27.5648",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2517",
    WardLookupId: "3114",
    VDNumber: "11390701",
    RegisteredPopulation: "247",
    VotingStationName: "BONGIWE JUNIOR SECONDARY SCHOOL",
    Address: "MKHUNYAZO  STERKSPRUIT  STERKSPRUIT",
    Latitude: "-30.5893",
    Longitude: "27.6195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2518",
    WardLookupId: "3114",
    VDNumber: "11400039",
    RegisteredPopulation: "131",
    VotingStationName: "BERGSTROOM FARM SCHOOL",
    Address: "DODRECHT  ",
    Latitude: "-31.2593",
    Longitude: "27.3085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2519",
    WardLookupId: "3114",
    VDNumber: "11410029",
    RegisteredPopulation: "127",
    VotingStationName: "MOTKOP STATION HALL",
    Address: "MOTKOP  MOTKOP FARM  LADY GREY",
    Latitude: "-30.867",
    Longitude: "27.2932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2520",
    WardLookupId: "3114",
    VDNumber: "11450045",
    RegisteredPopulation: "52",
    VotingStationName: "CLANVILLE SHOP",
    Address: "CLANVILLE  CLANVILLE FARM  DORDRECHT",
    Latitude: "-30.9939",
    Longitude: "27.2233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2521",
    WardLookupId: "3114",
    VDNumber: "11450067",
    RegisteredPopulation: "311",
    VotingStationName: "ROSSOUW SCHOOL",
    Address: "ROSSOUW  WOODEHOUSE",
    Latitude: "-31.1693",
    Longitude: "27.2775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2522",
    WardLookupId: "3115",
    VDNumber: "11390183",
    RegisteredPopulation: "638",
    VotingStationName: "MAJUBA JUNIOR SECONDARY SCHOOL",
    Address: "MAJUBA  STERKSPRUIT",
    Latitude: "-30.5714",
    Longitude: "27.5066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2523",
    WardLookupId: "3115",
    VDNumber: "11390228",
    RegisteredPopulation: "863",
    VotingStationName: "ST MICHAELS JUNIOR SECONDARY SCHOOL",
    Address: "DULCESNEK  STERKSPRUIT",
    Latitude: "-30.4735",
    Longitude: "27.4433",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2524",
    WardLookupId: "3115",
    VDNumber: "11390240",
    RegisteredPopulation: "768",
    VotingStationName: "NDUNGUNYA JUNIOR SECONDARY SCHOOL",
    Address: "HILLSIDE  STERKSPRUIT",
    Latitude: "-30.576",
    Longitude: "27.5546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2525",
    WardLookupId: "3115",
    VDNumber: "11390543",
    RegisteredPopulation: "178",
    VotingStationName: "MALGAS JUNIOR PRIMARY SCHOOL",
    Address: "RIETFONTEIN  STERKSPRUIT",
    Latitude: "-30.6113",
    Longitude: "27.517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2526",
    WardLookupId: "3115",
    VDNumber: "11390554",
    RegisteredPopulation: "331",
    VotingStationName: "BONGOLETHU JUNIOR SECONDARY SCHOOL",
    Address: "RIETFONTEIN  STERKSPRUIT",
    Latitude: "-30.6128",
    Longitude: "27.4891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2527",
    WardLookupId: "3115",
    VDNumber: "11390633",
    RegisteredPopulation: "823",
    VotingStationName: "MMUSONG SCHOOL",
    Address: "MMUSONG VILLAGE  MMUSONG  HERSCHEL",
    Latitude: "-30.4936",
    Longitude: "27.5482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2528",
    WardLookupId: "3116",
    VDNumber: "11390262",
    RegisteredPopulation: "515",
    VotingStationName: "EGUGWINI JUNIOR SECONDARY SCHOOL",
    Address: "MACACUBA  MACACUMA  HERSCHEL",
    Latitude: "-30.4584",
    Longitude: "27.3536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2529",
    WardLookupId: "3116",
    VDNumber: "11390307",
    RegisteredPopulation: "1681",
    VotingStationName: "THABA LESOBA JUNIOR SECONDARY SCHOOL",
    Address: "THABA LESOBA  STERKSPRUIT",
    Latitude: "-30.4818",
    Longitude: "27.3994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2530",
    WardLookupId: "3116",
    VDNumber: "11390464",
    RegisteredPopulation: "747",
    VotingStationName: "BAMBOESSPRUIT JUNIOR SECONDARY SCHOOL",
    Address: "BAMBOESPRUIT  BAMBOESSPRUIT  STERKSPRUIT",
    Latitude: "-30.537",
    Longitude: "27.2939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2531",
    WardLookupId: "3116",
    VDNumber: "11390655",
    RegisteredPopulation: "477",
    VotingStationName: "MBIHLI JUNIOR SECONDARY SCHOOL",
    Address: "MBIHLI  MBIHLE  HERSCHEL",
    Latitude: "-30.4469",
    Longitude: "27.3563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2532",
    WardLookupId: "3116",
    VDNumber: "11390712",
    RegisteredPopulation: "583",
    VotingStationName: "TSEKANE JUNIOR SECONDARY SCHOOL",
    Address: "THABA-LESOBA  THABA-LESOBA VILLAGE  STERKSPRUIT",
    Latitude: "-30.4683",
    Longitude: "27.3842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2533",
    WardLookupId: "3116",
    VDNumber: "11390778",
    RegisteredPopulation: "260",
    VotingStationName: "MBOLEKWA SPS",
    Address: "MACACUMA VILLAGE  MACACUMA  STERKSPRUIT",
    Latitude: "-30.4724",
    Longitude: "27.3532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2534",
    WardLookupId: "3117",
    VDNumber: "11390149",
    RegisteredPopulation: "1326",
    VotingStationName: "MOKHESI METHODIST CHURCH",
    Address: "MOKHESI  STERKSPRUIT",
    Latitude: "-30.5261",
    Longitude: "27.393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2535",
    WardLookupId: "3117",
    VDNumber: "11390352",
    RegisteredPopulation: "1065",
    VotingStationName: "TAPOLENG JUNIOR SECONDARY SCHOOL",
    Address: "TAPOLENG  TAPOLENG VILLAGE  STERKSPRUIT",
    Latitude: "-30.5389",
    Longitude: "27.3637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2536",
    WardLookupId: "3117",
    VDNumber: "11390745",
    RegisteredPopulation: "1063",
    VotingStationName: "MAKHETHA JUNIOR SECONDARY SCHOOL",
    Address: "MAKHETHA  VILLAGE  BARKLY EAST",
    Latitude: "-30.5207",
    Longitude: "27.3872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2537",
    WardLookupId: "3117",
    VDNumber: "11390767",
    RegisteredPopulation: "281",
    VotingStationName: "KHANYA PRE SCHOOL",
    Address: "TIENBANK VILLAGE  STERKSPRUIT  STERKSPRUIT",
    Latitude: "-30.5499",
    Longitude: "27.3901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2538",
    WardLookupId: "3118",
    VDNumber: "11390026",
    RegisteredPopulation: "1262",
    VotingStationName: "SIKHOKHELE PRE SCHOOL",
    Address: "N/A  KROMSPRUIT  STERKSPRUIT",
    Latitude: "-30.5456",
    Longitude: "27.42",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2539",
    WardLookupId: "3118",
    VDNumber: "11390172",
    RegisteredPopulation: "1461",
    VotingStationName: "NGQUBA JUNIOR SECONDARY SCHOOL",
    Address: "NGQUBA  STERKSPRUIT",
    Latitude: "-30.5553",
    Longitude: "27.412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2540",
    WardLookupId: "3118",
    VDNumber: "11390318",
    RegisteredPopulation: "709",
    VotingStationName: "HOITA JUNIOR SECONDARY SCHOOL",
    Address: "HOITA  STERKSPRUIT",
    Latitude: "-30.5498",
    Longitude: "27.4566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2541",
    WardLookupId: "3119",
    VDNumber: "11310017",
    RegisteredPopulation: "1492",
    VotingStationName: "BUNGA HALL",
    Address: "MAIN STREET  STERKSPRUIT  STERKSPRUIT",
    Latitude: "-30.5273",
    Longitude: "27.3699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2542",
    WardLookupId: "3119",
    VDNumber: "11390329",
    RegisteredPopulation: "1111",
    VotingStationName: "TIENBANK SENIOR PRIMARY SCHOOL",
    Address: "TIENBANK  STERKSPRUIT  STERKSPRUIT",
    Latitude: "-30.5346",
    Longitude: "27.3615",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2543",
    WardLookupId: "3119",
    VDNumber: "11390644",
    RegisteredPopulation: "1650",
    VotingStationName: "EKUZOLENI SCHOOL",
    Address: "EKUZOLENI VILLAGE  HERSCHEL  HERSCHEL",
    Latitude: "-30.5123",
    Longitude: "27.3724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2544",
    WardLookupId: "3119",
    VDNumber: "11390734",
    RegisteredPopulation: "445",
    VotingStationName: "EMOYENI TENT",
    Address: "ESILINDINI VILLAGE  STERKSPRUIT  ESILINDINI",
    Latitude: "-30.5416",
    Longitude: "27.338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2545",
    WardLookupId: "3120",
    VDNumber: "11390419",
    RegisteredPopulation: "715",
    VotingStationName: "NTSIMEKWENI JUNIOR SECONDARY SCHOOL",
    Address: "NTSIMEKWENI  HERSCHEL  HERSCHEL",
    Latitude: "-30.6173",
    Longitude: "27.2032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2546",
    WardLookupId: "3120",
    VDNumber: "11390420",
    RegisteredPopulation: "431",
    VotingStationName: "DIBINKONZO JUNIOR SECONDARY SCHOOL",
    Address: "DIBINKONZO  HERSCHEL",
    Latitude: "-30.5894",
    Longitude: "27.1875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2547",
    WardLookupId: "3120",
    VDNumber: "11390431",
    RegisteredPopulation: "834",
    VotingStationName: "MANXEBA JUNIOR SECONDARY SCHOOL",
    Address: "MANXEBA  STERKSPRUIT",
    Latitude: "-30.6029",
    Longitude: "27.2554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2548",
    WardLookupId: "3120",
    VDNumber: "11390442",
    RegisteredPopulation: "606",
    VotingStationName: "SKISAZANA JUNIOR SECONDARY SCHOOL",
    Address: "SKISAZANA  HERSCHEL",
    Latitude: "-30.5222",
    Longitude: "27.2235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2549",
    WardLookupId: "3120",
    VDNumber: "11390453",
    RegisteredPopulation: "810",
    VotingStationName: "MAGUMBU SENIOR PRIMARY SCHOOL",
    Address: "BLUE GUMS  HERSCHEL  HERSCHEL",
    Latitude: "-30.5382",
    Longitude: "27.238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2550",
    WardLookupId: "3120",
    VDNumber: "11390565",
    RegisteredPopulation: "410",
    VotingStationName: "EZINTATYANENI SCHOOL",
    Address: "EZINTATYANENI  HERSCHEL",
    Latitude: "-30.6021",
    Longitude: "27.2104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2551",
    WardLookupId: "3121",
    VDNumber: "11390284",
    RegisteredPopulation: "443",
    VotingStationName: "HLOMENDLINI JUNIOR SECONDARY SCHOOL",
    Address: "HLOMENDLIN  HLOMENDLINI  STERKSPRUIT",
    Latitude: "-30.5835",
    Longitude: "27.2712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2552",
    WardLookupId: "3121",
    VDNumber: "11390330",
    RegisteredPopulation: "252",
    VotingStationName: "MNCUNUBENI JUNIOR SECONDARY SCHOOL",
    Address: "MAGADLA  HERSCHEL",
    Latitude: "-30.6042",
    Longitude: "27.3145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2553",
    WardLookupId: "3121",
    VDNumber: "11390475",
    RegisteredPopulation: "1112",
    VotingStationName: "ESILINDINI JUNIOR SECONDARY SCHOOL",
    Address: "ESILINDINI  STERKSPRUIT",
    Latitude: "-30.5404",
    Longitude: "27.327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2554",
    WardLookupId: "3121",
    VDNumber: "11390486",
    RegisteredPopulation: "709",
    VotingStationName: "NGOGOVILLE JUNIOR SECONDARY SCHOOL",
    Address: "ESILINDINI  STERKSPRUIT",
    Latitude: "-30.5498",
    Longitude: "27.3257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2555",
    WardLookupId: "3121",
    VDNumber: "11390497",
    RegisteredPopulation: "1152",
    VotingStationName: "JOZANASHOEK JUNIOR SECONDARY SCHOOL",
    Address: "JOZANASHOEK  STERKSPRUIT",
    Latitude: "-30.6166",
    Longitude: "27.3677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2556",
    WardLookupId: "3121",
    VDNumber: "11390587",
    RegisteredPopulation: "373",
    VotingStationName: "JOZANASNEK SCHOOL",
    Address: "JOZANASNEK  HERSCHEL  HESRCHEL",
    Latitude: "-30.6204",
    Longitude: "27.3311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2557",
    WardLookupId: "3122",
    VDNumber: "11390363",
    RegisteredPopulation: "179",
    VotingStationName: "MDLOKOVANA JUNIOR SECONDARY SCHOOL",
    Address: "WITTENBEGERN  WITTENBERGEN  STERKSPRUIT",
    Latitude: "-30.5434",
    Longitude: "27.0382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2558",
    WardLookupId: "3122",
    VDNumber: "11390374",
    RegisteredPopulation: "671",
    VotingStationName: "ZAVA JUNIOR SECONDARY SCHOOL",
    Address: "WITTENBERGEN  HERSCHEL",
    Latitude: "-30.5961",
    Longitude: "27.1072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2559",
    WardLookupId: "3122",
    VDNumber: "11390385",
    RegisteredPopulation: "765",
    VotingStationName: "DIPANA SENIOR PRIMARY SCHOOL",
    Address: "WITTENBERGEN  STERKSPRUIT",
    Latitude: "-30.6234",
    Longitude: "27.1206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2560",
    WardLookupId: "3122",
    VDNumber: "11390396",
    RegisteredPopulation: "950",
    VotingStationName: "ST MARYS SENIOR PRIMARY SCHOOL",
    Address: "HERSCHEL  STERKSPRUIT",
    Latitude: "-30.618",
    Longitude: "27.1733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2561",
    WardLookupId: "3122",
    VDNumber: "11390408",
    RegisteredPopulation: "721",
    VotingStationName: "KHIBA JUNIOR SECONDARY SCHOOL",
    Address: "KHIBA LOC  HERSCHEL  HERSCHEL",
    Latitude: "-30.5572",
    Longitude: "27.1523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2562",
    WardLookupId: "3122",
    VDNumber: "11390576",
    RegisteredPopulation: "606",
    VotingStationName: "NKOPANE SCHOOL",
    Address: "WITTENBERGEN  HERSCHEL  HERSCHEL",
    Latitude: "-30.5748",
    Longitude: "27.0848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2563",
    WardLookupId: "3122",
    VDNumber: "11390723",
    RegisteredPopulation: "414",
    VotingStationName: "WITTEBERGEN JUNIOR SECONDARY SCHOOL",
    Address: "WITTENBERGEN  HERSCHELLE  HERSCHELLE",
    Latitude: "-30.6031",
    Longitude: "27.1192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2564",
    WardLookupId: "3123",
    VDNumber: "11280013",
    RegisteredPopulation: "1010",
    VotingStationName: "TOWN HALL",
    Address: "BURNETT STREET  LADY GERY  LADY GREY",
    Latitude: "-30.7113",
    Longitude: "27.2132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2565",
    WardLookupId: "3123",
    VDNumber: "11280024",
    RegisteredPopulation: "1882",
    VotingStationName: "KWEZI NALEDI COMMUNITY HALL",
    Address: "KWEZI NALEDI  LADY GREY  BARKLY EAST",
    Latitude: "-30.7201",
    Longitude: "27.2057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2566",
    WardLookupId: "3123",
    VDNumber: "11280035",
    RegisteredPopulation: "865",
    VotingStationName: "DR PHALO SCHOOL",
    Address: "KWEZI NANEDI LOCATION  BARKLY EAST",
    Latitude: "-30.7167",
    Longitude: "27.206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2567",
    WardLookupId: "3124",
    VDNumber: "11210027",
    RegisteredPopulation: "1597",
    VotingStationName: "NKULULEKO COMMUNITY HALL",
    Address: "NKULULEKO  BARKLY EAST  BARKLY EAST",
    Latitude: "-30.958",
    Longitude: "27.5958",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2568",
    WardLookupId: "3124",
    VDNumber: "11300016",
    RegisteredPopulation: "100",
    VotingStationName: "AGRICULTURAL HALL",
    Address: "RHODES  BARKLY EAST",
    Latitude: "-30.7974",
    Longitude: "27.9681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2569",
    WardLookupId: "3124",
    VDNumber: "11370035",
    RegisteredPopulation: "218",
    VotingStationName: "MOSHESHSFORD COUNTRY CLUB",
    Address: "EAST DISTRICT  BARKLEY EAST  BARKLY EAST",
    Latitude: "-30.8526",
    Longitude: "27.777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2570",
    WardLookupId: "3124",
    VDNumber: "11370046",
    RegisteredPopulation: "117",
    VotingStationName: "PERCIVALE BARN",
    Address: "BARKLY EAST  PERCIVALE FARM  BARKLY EAST",
    Latitude: "-31.0869",
    Longitude: "27.7079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2571",
    WardLookupId: "3124",
    VDNumber: "11370057",
    RegisteredPopulation: "448",
    VotingStationName: "RHODES COMMUNITY HALL",
    Address: "RHODES  BARKLY EAST",
    Latitude: "-30.7957",
    Longitude: "27.9624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2572",
    WardLookupId: "3124",
    VDNumber: "11380036",
    RegisteredPopulation: "103",
    VotingStationName: "TANTALLON BARN",
    Address: "ELLIOT  TANTALLON FARM  ELLIOT",
    Latitude: "-31.1589",
    Longitude: "27.9221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2573",
    WardLookupId: "3124",
    VDNumber: "11390015",
    RegisteredPopulation: "163",
    VotingStationName: "DANGERS HOEK JUNIOR SECONDARY SCHOOL",
    Address: "DANGERSHOEK  HERSCHEL",
    Latitude: "-30.64",
    Longitude: "27.7885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2574",
    WardLookupId: "3124",
    VDNumber: "11390060",
    RegisteredPopulation: "244",
    VotingStationName: "ROCKCLIFF JUNIOR SECONDARY SCHOOL",
    Address: "SIJORA  STERKSPRUIT",
    Latitude: "-30.6244",
    Longitude: "27.6359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2575",
    WardLookupId: "3124",
    VDNumber: "11390622",
    RegisteredPopulation: "107",
    VotingStationName: "NOMLENGANA SCHOOL",
    Address: "NOMLENGANA  HERSCHEL",
    Latitude: "-30.6287",
    Longitude: "27.8278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2576",
    WardLookupId: "3125",
    VDNumber: "11210016",
    RegisteredPopulation: "873",
    VotingStationName: "MUNICIPAL TOWN HALL",
    Address: "MOLTENO  BARKLY EAST  BARKLY EAST",
    Latitude: "-30.968",
    Longitude: "27.5936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2577",
    WardLookupId: "3125",
    VDNumber: "11210038",
    RegisteredPopulation: "835",
    VotingStationName: "MALIKHANYE SENIOR SCHOOL",
    Address: "6 NKULULEKO  BARKLY EAST  BARKLY EAST",
    Latitude: "-30.962",
    Longitude: "27.594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2578",
    WardLookupId: "3125",
    VDNumber: "11210049",
    RegisteredPopulation: "1813",
    VotingStationName: "SIZAMULWAZI PUBLIC SCHOOL",
    Address: "BARKLY EAST  ",
    Latitude: "-30.9663",
    Longitude: "27.5869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2579",
    WardLookupId: "3126",
    VDNumber: "11310028",
    RegisteredPopulation: "572",
    VotingStationName: "TAPOLENG ROMAN CATHOLIC CHURCH",
    Address: "TAPOLENG  STERKSPRUIT",
    Latitude: "-30.5425",
    Longitude: "27.3623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2580",
    WardLookupId: "3126",
    VDNumber: "11390138",
    RegisteredPopulation: "717",
    VotingStationName: "VOYIZANA JUNIOR SECONDARY SCHOOL",
    Address: "VOYIZANA  STERKSPRUIT",
    Latitude: "-30.5676",
    Longitude: "27.424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2581",
    WardLookupId: "3126",
    VDNumber: "11390509",
    RegisteredPopulation: "741",
    VotingStationName: "SUNDUZA JUNIOR SECONDARY SCHOOL",
    Address: "SUNDUZA  STERKSPRUIT",
    Latitude: "-30.6157",
    Longitude: "27.391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2582",
    WardLookupId: "3126",
    VDNumber: "11390510",
    RegisteredPopulation: "345",
    VotingStationName: "BENSONVALE JUNIOR SECONDARY SCHOOL",
    Address: "BENSONVILLE  STERKSPRUIT",
    Latitude: "-30.5793",
    Longitude: "27.36",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2583",
    WardLookupId: "3126",
    VDNumber: "11390521",
    RegisteredPopulation: "640",
    VotingStationName: "JOVELENI SENIOR PRIMARY SCHOOL",
    Address: "JOVELENI  STERKSPRUIT",
    Latitude: "-30.5844",
    Longitude: "27.3971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2584",
    WardLookupId: "3126",
    VDNumber: "11390532",
    RegisteredPopulation: "635",
    VotingStationName: "MAPOLO JUNIOR SECONDARY SCHOOL",
    Address: "VOYIZANA  VOYISANA  STERKSPRUIT",
    Latitude: "-30.5773",
    Longitude: "27.4523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2585",
    WardLookupId: "3127",
    VDNumber: "11340021",
    RegisteredPopulation: "432",
    VotingStationName: "OVISTON TOWN HALL",
    Address: "RECREATION HALL  OVISTON  VENTERSTAD",
    Latitude: "-30.6936",
    Longitude: "25.7662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2586",
    WardLookupId: "3127",
    VDNumber: "11430010",
    RegisteredPopulation: "105",
    VotingStationName: "SUNNYSIDE HALL",
    Address: "SUNNYSIDE FARM  STEYNSBURG",
    Latitude: "-31.0798",
    Longitude: "25.7131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2587",
    WardLookupId: "3127",
    VDNumber: "11430032",
    RegisteredPopulation: "95",
    VotingStationName: "TEEBUS HALL",
    Address: "TEEBUS  STEYNSBURG  GARIEP",
    Latitude: "-31.3674",
    Longitude: "25.6644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2588",
    WardLookupId: "3127",
    VDNumber: "11440011",
    RegisteredPopulation: "2734",
    VotingStationName: "VENTERSTAD TOWN HALL",
    Address: "HOPLEY STREET  VENTERSTAD  VENTERSTAD",
    Latitude: "-30.7757",
    Longitude: "25.7998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2589",
    WardLookupId: "3128",
    VDNumber: "11320018",
    RegisteredPopulation: "1683",
    VotingStationName: "KHAYAMNANDI COMMUNITY HALL",
    Address: "7 PAKA STREET  KHAYAMNANDI  STEYNSBURG",
    Latitude: "-31.2825",
    Longitude: "25.8127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2590",
    WardLookupId: "3128",
    VDNumber: "11320030",
    RegisteredPopulation: "809",
    VotingStationName: "WESTDENE LIBRARY HALL",
    Address: "GARIEP  ",
    Latitude: "-31.2898",
    Longitude: "25.8082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2591",
    WardLookupId: "3128",
    VDNumber: "11320041",
    RegisteredPopulation: "755",
    VotingStationName: "STEYNSBURG INDOOR SPORT CENTRE",
    Address: "HINNIND  STEYNSBURG  STEYNSBURG",
    Latitude: "-31.2914",
    Longitude: "25.8141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2592",
    WardLookupId: "3129",
    VDNumber: "11220028",
    RegisteredPopulation: "1207",
    VotingStationName: "BURGERSDORP TOWN HALL",
    Address: "KERKSTRAAT  BURGERSDORP",
    Latitude: "-30.9942",
    Longitude: "26.3319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2593",
    WardLookupId: "3129",
    VDNumber: "11220062",
    RegisteredPopulation: "440",
    VotingStationName: "NKQUBELA CRECHE",
    Address: "OXFORD  BURGERSDORP  BURGERSDORP",
    Latitude: "-31.0099",
    Longitude: "26.3355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2594",
    WardLookupId: "3129",
    VDNumber: "11270012",
    RegisteredPopulation: "1153",
    VotingStationName: "CHRIS HANI HALL",
    Address: "0 MZINGIZI STREET  MASAKHANE  JAMESTOWN",
    Latitude: "-31.1303",
    Longitude: "26.8033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2595",
    WardLookupId: "3129",
    VDNumber: "11350011",
    RegisteredPopulation: "130",
    VotingStationName: "KROONVLEI HALL",
    Address: "BURGERSDORP  ",
    Latitude: "-31.1848",
    Longitude: "26.5639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2596",
    WardLookupId: "3129",
    VDNumber: "11350022",
    RegisteredPopulation: "116",
    VotingStationName: "KNAPDAAR FARMERS ASSOCIATION BUILDING",
    Address: "KNAPDAAR  BURGERSDORP",
    Latitude: "-30.7169",
    Longitude: "26.1433",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2597",
    WardLookupId: "3130",
    VDNumber: "11220017",
    RegisteredPopulation: "1082",
    VotingStationName: "MZAMOMHLE CLINIC",
    Address: "HIPPO STREET  MZAMOMHLE  BURGERSDORP",
    Latitude: "-31.0115",
    Longitude: "26.3336",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2598",
    WardLookupId: "3130",
    VDNumber: "11220039",
    RegisteredPopulation: "1089",
    VotingStationName: "ST ANDREWS CHURCH",
    Address: "1101 HIPPO STREET  MZAMOMHLE  BURGERSDORP",
    Latitude: "-31.0101",
    Longitude: "26.3296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2599",
    WardLookupId: "3130",
    VDNumber: "11220040",
    RegisteredPopulation: "1162",
    VotingStationName: "EUREKA TOWN HALL",
    Address: "LEEUBEKKIE STREET  EUREKA  BURGERSDORP",
    Latitude: "-31.0065",
    Longitude: "26.3151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2600",
    WardLookupId: "3131",
    VDNumber: "11220051",
    RegisteredPopulation: "2519",
    VotingStationName: "THEMBISA HALL",
    Address: "THEMBISA LOCATION  BURGERSDORP  GARIEP",
    Latitude: "-31.0201",
    Longitude: "26.3222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2601",
    WardLookupId: "3131",
    VDNumber: "11220073",
    RegisteredPopulation: "353",
    VotingStationName: "JOE GQABI ROADS DEPOT BURGERSDORP",
    Address: "DAANTJIE VAN DEN HEEVER  BURGERSDORP  BURGERSDORP",
    Latitude: "-30.9916",
    Longitude: "26.3099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2602",
    WardLookupId: "3131",
    VDNumber: "11320029",
    RegisteredPopulation: "532",
    VotingStationName: "STEYNSBURG TOWN HALL",
    Address: "H POTGIETERSTRAAT  STEYNSBURG",
    Latitude: "-31.2959",
    Longitude: "25.8256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2603",
    WardLookupId: "3132",
    VDNumber: "11200093",
    RegisteredPopulation: "2405",
    VotingStationName: "VUMILE SCHOOL",
    Address: "2900 NTSOENTSANYANA  DUKATHOLE  ALIWAL NORTH",
    Latitude: "-30.6913",
    Longitude: "26.6786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2604",
    WardLookupId: "3132",
    VDNumber: "11200206",
    RegisteredPopulation: "1127",
    VotingStationName: "BL  BENEDICT DASWA",
    Address: "AREA 13  4924 AREA 13  ALIWAL NORTH",
    Latitude: "-30.6768",
    Longitude: "26.6756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2605",
    WardLookupId: "3133",
    VDNumber: "11200048",
    RegisteredPopulation: "979",
    VotingStationName: "GREENSLADE HALL",
    Address: "663 RECREATION STREET  DUKATHOLE  ALIWAL NORTH",
    Latitude: "-30.6925",
    Longitude: "26.6926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2606",
    WardLookupId: "3133",
    VDNumber: "11200071",
    RegisteredPopulation: "601",
    VotingStationName: "GOOD TREE EDUCARE CENTRE",
    Address: "100 BLOCK F BANTU STREET  ZWELITSHA LOCATION  ALIWAL NORTH",
    Latitude: "-30.6912",
    Longitude: "26.6886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2607",
    WardLookupId: "3133",
    VDNumber: "11200127",
    RegisteredPopulation: "464",
    VotingStationName: "ST FRANCIS CHURCH",
    Address: "1513 BANTU STR  DUKATHOLE  ALIWAL NORTH",
    Latitude: "-30.691",
    Longitude: "26.6941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2608",
    WardLookupId: "3133",
    VDNumber: "11200138",
    RegisteredPopulation: "514",
    VotingStationName: "ETHIOPIAN CHURCH.",
    Address: "DUKATHOLE  ALIWAL NORTH",
    Latitude: "-30.6906",
    Longitude: "26.6909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2609",
    WardLookupId: "3133",
    VDNumber: "11200149",
    RegisteredPopulation: "408",
    VotingStationName: "MALCOMESS SECONDARY SCHOOL",
    Address: "1377 MAIN STREET  BADUZA  ALIWAL NORTH",
    Latitude: "-30.6952",
    Longitude: "26.6844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2610",
    WardLookupId: "3134",
    VDNumber: "11200037",
    RegisteredPopulation: "1038",
    VotingStationName: "SACRED HEART CRECHE",
    Address: "1729 BLOCK H  DUKATHOLE  ALIWAL NORTH",
    Latitude: "-30.6935",
    Longitude: "26.6802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2611",
    WardLookupId: "3134",
    VDNumber: "11200105",
    RegisteredPopulation: "621",
    VotingStationName: "JOE SLOVO HALL",
    Address: "4803 NTSOENTSANYANA STREET  DUKATHOLE  ALIWAL NORTH",
    Latitude: "-30.6947",
    Longitude: "26.681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2612",
    WardLookupId: "3134",
    VDNumber: "11200116",
    RegisteredPopulation: "696",
    VotingStationName: "PELOMOSA PRIMARY SCHOOL",
    Address: "873 MKHATHU STREET  DUKATHOLE  ALIWAL NORTH",
    Latitude: "-30.693",
    Longitude: "26.6885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2613",
    WardLookupId: "3134",
    VDNumber: "11200150",
    RegisteredPopulation: "653",
    VotingStationName: "AFRICAN GOSPEL CHURCH",
    Address: "1267 VERGENOEG  DUKATHOLE  ALIWAL NORTH",
    Latitude: "-30.6983",
    Longitude: "26.6827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2614",
    WardLookupId: "3135",
    VDNumber: "11200015",
    RegisteredPopulation: "1304",
    VotingStationName: "HILTON COMMUNITY HALL",
    Address: "MARCOW STREET (CIRCLE)  HILTON  ALIWAL NORTH",
    Latitude: "-30.6987",
    Longitude: "26.6972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2615",
    WardLookupId: "3135",
    VDNumber: "11200026",
    RegisteredPopulation: "1517",
    VotingStationName: "ALIWAL NORTH LIBRARY",
    Address: "00 SOMERSET STREET  ALIWAL NORTH MID TOWN  ALIWAL NORTH",
    Latitude: "-30.6928",
    Longitude: "26.7086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2616",
    WardLookupId: "3136",
    VDNumber: "11200161",
    RegisteredPopulation: "1505",
    VotingStationName: "EGQILI HIGH SCHOOL",
    Address: "3013 MADELIEFE ROAD  HILTON   ALIWAL NORTH",
    Latitude: "-30.701",
    Longitude: "26.6875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2617",
    WardLookupId: "3136",
    VDNumber: "11200194",
    RegisteredPopulation: "2174",
    VotingStationName: "JOE GQABI HALL",
    Address: "0 CNR RAMASHALA & EDIALE STREET  JOE GQABI   ALIWAL NORTH",
    Latitude: "-30.7085",
    Longitude: "26.6966",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2618",
    WardLookupId: "3136",
    VDNumber: "11360012",
    RegisteredPopulation: "592",
    VotingStationName: "MALETSWAI DEVELOPMENT AND SPORT CENTRE",
    Address: "SHOWGROUNDS  ALIWAL NORTH  ALIWAL NORTH",
    Latitude: "-30.7171",
    Longitude: "26.6668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2619",
    WardLookupId: "3137",
    VDNumber: "11200082",
    RegisteredPopulation: "888",
    VotingStationName: "ESPAU BUILDING",
    Address: "SMITH STREET  ALIWAL NORTH  ALIWAL NORTH",
    Latitude: "-30.6881",
    Longitude: "26.7106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2620",
    WardLookupId: "3137",
    VDNumber: "11200183",
    RegisteredPopulation: "688",
    VotingStationName: "BLUE HALL ALIWAL SPA",
    Address: "00 DAN PIENAAR AVENUE  ALIWAL NORTH  ALIWAL NORTH",
    Latitude: "-30.7157",
    Longitude: "26.7143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2621",
    WardLookupId: "3137",
    VDNumber: "11270023",
    RegisteredPopulation: "997",
    VotingStationName: "DUTCH REFORMED CHURCH",
    Address: "0 MZAMO  MASAKHANE JAMESTOWN  ALIWAL NORTH",
    Latitude: "-31.1321",
    Longitude: "26.8063",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2622",
    WardLookupId: "3137",
    VDNumber: "11360023",
    RegisteredPopulation: "476",
    VotingStationName: "COMMUNITY HALL",
    Address: "0 VON HIRSCHBURG STREET  JAMESTOWN  JAMESTOWN",
    Latitude: "-31.121",
    Longitude: "26.8103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2623",
    WardLookupId: "3137",
    VDNumber: "11360034",
    RegisteredPopulation: "79",
    VotingStationName: "FLOUKRAAL COMMUNITY HALL",
    Address: "0 MAIN ROAD  FARM AREA FLOUKRAAL  ALIWAL NORTH",
    Latitude: "-30.996",
    Longitude: "27.0551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2624",
    WardLookupId: "3137",
    VDNumber: "11360045",
    RegisteredPopulation: "27",
    VotingStationName: "BRAAMSPRUIT FARM SCHOOL",
    Address: "BRAAMSPRUIT  ALIWAL NORTH",
    Latitude: "-30.7723",
    Longitude: "26.8344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2625",
    WardLookupId: "3137",
    VDNumber: "11410018",
    RegisteredPopulation: "168",
    VotingStationName: "TOEKOMS FARM",
    Address: "0 TOEKOMS FARM  LADY GREY  LADY GREY",
    Latitude: "-30.7173",
    Longitude: "27.045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2626",
    WardLookupId: "3137",
    VDNumber: "11450012",
    RegisteredPopulation: "88",
    VotingStationName: "VOGELVLEI SCHOOL",
    Address: "0 VOGELVLEI FARM  DORDRECHT FARM AREA  DORDRECHT",
    Latitude: "-31.3833",
    Longitude: "26.8565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2627",
    WardLookupId: "3137",
    VDNumber: "11450034",
    RegisteredPopulation: "165",
    VotingStationName: "BAMBOESHOEK VEILINGSKRALE",
    Address: "0 BAMBOESHOEK VEILINGSKRALE  KEMPERS GROVE  DORDRECHT",
    Latitude: "-31.2603",
    Longitude: "27.0574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2628",
    WardLookupId: "3138",
    VDNumber: "11770051",
    RegisteredPopulation: "1065",
    VotingStationName: "MAKHWALENI JUNIOR SECONDARY SCHOOL",
    Address: "MAKHWALENI LOC.  NTONTELA A/A  LUSIKISIKI",
    Latitude: "-31.3151",
    Longitude: "29.2597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2629",
    WardLookupId: "3138",
    VDNumber: "11771120",
    RegisteredPopulation: "423",
    VotingStationName: "PHANGISA JUNIOR SECONDAY SCHOOL",
    Address: "NTONTELA LOC  NTONTELA A/A  LUSIKISIKI",
    Latitude: "-31.2689",
    Longitude: "29.2768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2630",
    WardLookupId: "3138",
    VDNumber: "11771131",
    RegisteredPopulation: "987",
    VotingStationName: "THEMBUKAZI JUNIOR SECONDARY SCHOOL",
    Address: "THEMBUKAZI LOC  NTONTELA A/A  LUSIKISIKI",
    Latitude: "-31.3619",
    Longitude: "29.2623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2631",
    WardLookupId: "3138",
    VDNumber: "11771850",
    RegisteredPopulation: "935",
    VotingStationName: "JIBA SENIOR SECONDARY SCHOOL",
    Address: "MAKHWALENI LOCATION  MAKHWALENI A/A  MAKHWALENI",
    Latitude: "-31.3036",
    Longitude: "29.2653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2632",
    WardLookupId: "3138",
    VDNumber: "11771883",
    RegisteredPopulation: "387",
    VotingStationName: "MBENYANE ZIONIST CHURCH",
    Address: "MBENYANE LOCATION  MAKHWALENI A/A  LUSIKISIKI",
    Latitude: "-31.3363",
    Longitude: "29.2382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2633",
    WardLookupId: "3139",
    VDNumber: "11770039",
    RegisteredPopulation: "1346",
    VotingStationName: "NTONTELA JUNIOR SECONDARY SCHOOL.",
    Address: "NTONTELA LOCALITY  NTONTELA A/A  LUSIKISIKI",
    Latitude: "-31.2938",
    Longitude: "29.3024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2634",
    WardLookupId: "3139",
    VDNumber: "11770040",
    RegisteredPopulation: "1169",
    VotingStationName: "LUKHANYISWENI JUNIOR SECONDARY SCHOOL",
    Address: "LUKHANYISWENI  LOC  MANTLANENI A/A  LUSIKISIKI",
    Latitude: "-31.2747",
    Longitude: "29.3655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2635",
    WardLookupId: "3139",
    VDNumber: "11771119",
    RegisteredPopulation: "936",
    VotingStationName: "MVUBU  PRIMARY SCHOOL",
    Address: "NGCENGCE LOCATION  MAKHWALENI A/A  MAKHWALENI",
    Latitude: "-31.2562",
    Longitude: "29.3253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2636",
    WardLookupId: "3139",
    VDNumber: "11771872",
    RegisteredPopulation: "553",
    VotingStationName: "ZWELIKHANYILE JUNIOR PRIMARY SCHOOL",
    Address: "NTONTELA LOCALITY  NTONTELA A/A  LUSIKISIKI",
    Latitude: "-31.2917",
    Longitude: "29.3341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2637",
    WardLookupId: "3139",
    VDNumber: "11772019",
    RegisteredPopulation: "261",
    VotingStationName: "SIVUKILE SENIOR PRIMARY SCHOOL",
    Address: "NTSHELENI LOCATION  MAKHWALENI A/A  LUSIKISKI",
    Latitude: "-31.3007",
    Longitude: "29.3416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2638",
    WardLookupId: "3140",
    VDNumber: "11770017",
    RegisteredPopulation: "865",
    VotingStationName: "HERTZORGVILLE JUNIOR SECONDARY SCHOOL",
    Address: "MANTLANENI LOCALITY  MANTLANENI A/AZ  LUSIKISIKI",
    Latitude: "-31.232",
    Longitude: "29.3023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2639",
    WardLookupId: "3140",
    VDNumber: "11770028",
    RegisteredPopulation: "886",
    VotingStationName: "MANTLANENI JUNIOR SECONDARY SCHOOL",
    Address: "MANTLANENI LOC  MANTLANENI A/A  LUSIKISIKI",
    Latitude: "-31.2414",
    Longitude: "29.3364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2640",
    WardLookupId: "3140",
    VDNumber: "11770152",
    RegisteredPopulation: "753",
    VotingStationName: "NORATSHAZA JUNIOR SECONDARY SCHOOL",
    Address: "NORATSHAZA LOC.  MFINIZWENI A/A  LUSIKISIKI",
    Latitude: "-31.2874",
    Longitude: "29.393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2641",
    WardLookupId: "3140",
    VDNumber: "11771614",
    RegisteredPopulation: "633",
    VotingStationName: "UPPER MKHATHA METHODIST CHURCH",
    Address: "NORATSHAZA LOCATION  MFINIZWENI A/A  LUSIKISIKI",
    Latitude: "-31.3025",
    Longitude: "29.3842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2642",
    WardLookupId: "3140",
    VDNumber: "11771636",
    RegisteredPopulation: "481",
    VotingStationName: "MHLOPHEKAZI SENIOR SECONDARY SCHOOL",
    Address: "MMANGWENI LOCATION  MFINIZWENI A/A  LUSIKISIKI",
    Latitude: "-31.2383",
    Longitude: "29.4164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2643",
    WardLookupId: "3140",
    VDNumber: "11771861",
    RegisteredPopulation: "644",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "MANTLANENI LOC  MANTLANENI A/A  QAUKENI",
    Latitude: "-31.2188",
    Longitude: "29.3227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2644",
    WardLookupId: "3140",
    VDNumber: "11772109",
    RegisteredPopulation: "298",
    VotingStationName: "VEGROSS SENIOR PRIMARY SCHOOL",
    Address: "SIKHULU LOCATION  MANTLANENI A/A  LUSIKISIKI",
    Latitude: "-31.2103",
    Longitude: "29.3038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2645",
    WardLookupId: "3140",
    VDNumber: "11772165",
    RegisteredPopulation: "358",
    VotingStationName: "MQHATYENI  SENIOR PRIMARY",
    Address: "MQHATYENI LOCATION  MANTLANENI ADMIN AREA  LUSIKISIKI",
    Latitude: "-31.2479",
    Longitude: "29.3836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2646",
    WardLookupId: "3141",
    VDNumber: "11770129",
    RegisteredPopulation: "519",
    VotingStationName: "MBAYI JUNIOR SECONDARY SCHOOL",
    Address: "MFINIZWENI LOC  MFINIZWENI A/A  LUSIKISIKI",
    Latitude: "-31.2794",
    Longitude: "29.4259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2647",
    WardLookupId: "3141",
    VDNumber: "11770130",
    RegisteredPopulation: "621",
    VotingStationName: "MFINIZWENI DUTCH REFORMED CHURCH",
    Address: "MFINIZWENI LOC  MFINIZWENI A/A  LUSIKISIKI",
    Latitude: "-31.2451",
    Longitude: "29.4493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2648",
    WardLookupId: "3141",
    VDNumber: "11770231",
    RegisteredPopulation: "691",
    VotingStationName: "QEBEDU JUNIOR SECONDARY SCHOOL",
    Address: "QEBEDU LOCATION  XURANA A/A  LUSIKISIKI",
    Latitude: "-31.2622",
    Longitude: "29.4534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2649",
    WardLookupId: "3141",
    VDNumber: "11770961",
    RegisteredPopulation: "456",
    VotingStationName: "NOZAYI JUNIOR SECONDARY SCHOOL",
    Address: "NOZAYI LOCATION  MFINIZWENI A/A  LUSIKISIKI",
    Latitude: "-31.2585",
    Longitude: "29.4232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2650",
    WardLookupId: "3141",
    VDNumber: "11771625",
    RegisteredPopulation: "463",
    VotingStationName: "RED HILL JUNIOR SECONDARY SCHOOL",
    Address: "NKOZO LOCATION  NKOZO A/A  FLAGSTAFF",
    Latitude: "-31.2045",
    Longitude: "29.4467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2651",
    WardLookupId: "3141",
    VDNumber: "11771647",
    RegisteredPopulation: "793",
    VotingStationName: "THAFABANZI METHODIST CHURCH",
    Address: "THAFABANZI LOCALITY  MFINIZWENI A/A  LUSIKISIKI",
    Latitude: "-31.2319",
    Longitude: "29.4454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2652",
    WardLookupId: "3141",
    VDNumber: "11771658",
    RegisteredPopulation: "381",
    VotingStationName: "GXELESHA DUTCH REFORMED CHURCH",
    Address: "GXELESHA LOCALITY  MFINIZWENI A/A  LUSIKISIKI",
    Latitude: "-31.2173",
    Longitude: "29.4289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2653",
    WardLookupId: "3141",
    VDNumber: "11772008",
    RegisteredPopulation: "336",
    VotingStationName: "MANGENA SENIOR PRIMARY SCHOOL",
    Address: "MBHAYI  LOCATION  MFINIZWENI A/A  LUSIKISIKI",
    Latitude: "-31.3079",
    Longitude: "29.4052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2654",
    WardLookupId: "3142",
    VDNumber: "11810461",
    RegisteredPopulation: "778",
    VotingStationName: "MCELU JUNIOR SECONDARY SCHOOL",
    Address: "NKOZO A/A  MCELU LOCATION  FLAGSTAFF",
    Latitude: "-31.1644",
    Longitude: "29.3986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2655",
    WardLookupId: "3142",
    VDNumber: "11810472",
    RegisteredPopulation: "772",
    VotingStationName: "MNEKETSHE JUNIOR SECONDARY SCHOOL",
    Address: "MNEKETSHE LOCATION  MANTLANENI A/A  FLAGSTAFF",
    Latitude: "-31.1771",
    Longitude: "29.3712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2656",
    WardLookupId: "3142",
    VDNumber: "11810483",
    RegisteredPopulation: "629",
    VotingStationName: "BISANA JUNIOR SECONDARY SCHOOL",
    Address: "BISANA LOCATION  NKOZO A/A  FLAGSTAFF",
    Latitude: "-31.2241",
    Longitude: "29.3938",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2657",
    WardLookupId: "3142",
    VDNumber: "11810595",
    RegisteredPopulation: "908",
    VotingStationName: "NKOZO JUNIOR SECONDARY SCHOOL",
    Address: "NKONZO A/A  NKOZO LOCATION  FLAGSTAFF",
    Latitude: "-31.1962",
    Longitude: "29.42",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2658",
    WardLookupId: "3142",
    VDNumber: "11810663",
    RegisteredPopulation: "397",
    VotingStationName: "LUDIWANE JUNIOR SECONDARY SCHOOL",
    Address: "LUDIWANE LOC.  SIPAQENI A/A  FLAGSTAFF",
    Latitude: "-31.1143",
    Longitude: "29.426",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2659",
    WardLookupId: "3142",
    VDNumber: "11810933",
    RegisteredPopulation: "192",
    VotingStationName: "MZONTSUNDU SENIOR PRIMARY SCHOOL",
    Address: "NKOZO LOCATION  NKOZO A/A  FLAGSTAFF",
    Latitude: "-31.16",
    Longitude: "29.4148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2660",
    WardLookupId: "3142",
    VDNumber: "11810944",
    RegisteredPopulation: "455",
    VotingStationName: "MAYIBENYE SENIOR SECONDARY SCHOOL",
    Address: "NKOZO LOCATION  NKOZO A/A  FLAGSTAFF",
    Latitude: "-31.2013",
    Longitude: "29.4327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2661",
    WardLookupId: "3142",
    VDNumber: "11811147",
    RegisteredPopulation: "341",
    VotingStationName: "SKITINI AFM",
    Address: "MPUMAZE LOCATION  NKOZO A/A  FLAGSTAFF",
    Latitude: "-31.1458",
    Longitude: "29.4192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2662",
    WardLookupId: "3143",
    VDNumber: "11680017",
    RegisteredPopulation: "1357",
    VotingStationName: "FLAGSTAFF ANGLICAN CHURCH",
    Address: "MAIN STREET  FLAGSTAFF  FLAGSTAFF",
    Latitude: "-31.0802",
    Longitude: "29.4969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2663",
    WardLookupId: "3143",
    VDNumber: "11680028",
    RegisteredPopulation: "790",
    VotingStationName: "NOMZAMO JUNIOR SECONDARY SCHOOL",
    Address: "ENKULULEKWENI TOWNSHIP  ENKULULEKWENI  FLAGSTAFF",
    Latitude: "-31.0723",
    Longitude: "29.4977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2664",
    WardLookupId: "3143",
    VDNumber: "11680039",
    RegisteredPopulation: "636",
    VotingStationName: "SACRED   HEART JUNIOR SECONDARY SCHOOL",
    Address: "MBEKI PARK  FLAGSTAFF  FLAGSTAFF",
    Latitude: "-31.0887",
    Longitude: "29.4806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2665",
    WardLookupId: "3143",
    VDNumber: "11810382",
    RegisteredPopulation: "1485",
    VotingStationName: "GABAJANA JUNIOR SECONDARY SCHOOL",
    Address: "GABAJANA LOCATION  SIPAQENI A/A  FLAGSTAFF",
    Latitude: "-31.083",
    Longitude: "29.4719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2666",
    WardLookupId: "3143",
    VDNumber: "11810922",
    RegisteredPopulation: "133",
    VotingStationName: "MNGENI SENIOR PRIMARY SCHOOL",
    Address: "MNGENI LOCATION  SIPAQENI A/A  FLAGSTAFF",
    Latitude: "-31.1019",
    Longitude: "29.4552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2667",
    WardLookupId: "3143",
    VDNumber: "11810955",
    RegisteredPopulation: "575",
    VotingStationName: "MTHWAKU JUNIOR SECONDARY SCHOOL",
    Address: "MTHWAKU LOCATION  SIPAQENI A/A  FLAGSTAFF",
    Latitude: "-31.1054",
    Longitude: "29.4769",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2668",
    WardLookupId: "3144",
    VDNumber: "11770219",
    RegisteredPopulation: "872",
    VotingStationName: "QHAMANGWENI JUNIOR SECONDARY SCHOOL",
    Address: "PLANGENI LOCATION  MBADANGO A/A  LUSIKISIKI",
    Latitude: "-31.1544",
    Longitude: "29.4715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2669",
    WardLookupId: "3144",
    VDNumber: "11772020",
    RegisteredPopulation: "227",
    VotingStationName: "MAGWANYA SENIOR PRIMARY SCHOOL",
    Address: "MANYENGELE LOCATION  SIPHAQENI A/A  FLAGSTAFF",
    Latitude: "-31.1429",
    Longitude: "29.5206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2670",
    WardLookupId: "3144",
    VDNumber: "11772031",
    RegisteredPopulation: "358",
    VotingStationName: "KWA GUQA SENIOR PRIMARY SCHOOL",
    Address: "KWA GUQA LOCATION  NKOZO A/A  FLAGSTAFF",
    Latitude: "-31.1669",
    Longitude: "29.4585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2671",
    WardLookupId: "3144",
    VDNumber: "11810371",
    RegisteredPopulation: "468",
    VotingStationName: "FLAGSTAFF COMPREHENSIVE SENIOR SECONDARY SCHOOL",
    Address: "MTHWAKU LOC  SIPHAQENI A/A  FLAGSTAFF",
    Latitude: "-31.1008",
    Longitude: "29.4937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2672",
    WardLookupId: "3144",
    VDNumber: "11810449",
    RegisteredPopulation: "414",
    VotingStationName: "MZAMENI JUNIOR SECONDARY SCHOOL",
    Address: "NYATI A/A  THALENI LOCATION  FLAGSTAFF",
    Latitude: "-31.139",
    Longitude: "29.4535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2673",
    WardLookupId: "3144",
    VDNumber: "11810450",
    RegisteredPopulation: "646",
    VotingStationName: "MABALENGWE JUNIOR SECONDARY SCHOOL",
    Address: "NKOZO A/A  MPHUMAZE LOCATION  FLAGSTAFF",
    Latitude: "-31.1436",
    Longitude: "29.4507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2674",
    WardLookupId: "3144",
    VDNumber: "11810494",
    RegisteredPopulation: "304",
    VotingStationName: "NGQWABENI JUNIOR SECONDARY SCHOOL",
    Address: "MBADANGO A/A  NGQWABENI LOCATION  FLAGSTAFF",
    Latitude: "-31.1323",
    Longitude: "29.5035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2675",
    WardLookupId: "3144",
    VDNumber: "11810506",
    RegisteredPopulation: "303",
    VotingStationName: "MALIWA JUNIOR SECONDARY SCHOOL",
    Address: "MALIWA LOCATION  MSIKABA A/A  FLAGSTAFF",
    Latitude: "-31.1375",
    Longitude: "29.5317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2676",
    WardLookupId: "3144",
    VDNumber: "11810674",
    RegisteredPopulation: "630",
    VotingStationName: "BISI JUNIOR SECONDARY SCHOOL",
    Address: "BISI LOCATION  MANTLANENI A/A  FLAGSTAFF",
    Latitude: "-31.1297",
    Longitude: "29.4789",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2677",
    WardLookupId: "3144",
    VDNumber: "11811013",
    RegisteredPopulation: "442",
    VotingStationName: "BHEKABANTU JUNIOR SECONDARY SCHOOL",
    Address: "NGQWABENI LOCATION  MBADANGO A/A  QAUKENI",
    Latitude: "-31.1399",
    Longitude: "29.4902",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2678",
    WardLookupId: "3144",
    VDNumber: "11811271",
    RegisteredPopulation: "106",
    VotingStationName: "SIGINGQINI PRE SCHOOL",
    Address: "MSIKABA LOCATION  SIPAQENI A/A  FLAGSTAFF",
    Latitude: "-31.1115",
    Longitude: "29.4935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2679",
    WardLookupId: "3145",
    VDNumber: "11810146",
    RegisteredPopulation: "199",
    VotingStationName: "GCINILIFU JUNIOR SECONDARY SCHOOL",
    Address: "MDUDWA LOCATION  XOPHOZO A/A  FLAGSTAFF",
    Latitude: "-31.0226",
    Longitude: "29.5182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2680",
    WardLookupId: "3145",
    VDNumber: "11810179",
    RegisteredPopulation: "722",
    VotingStationName: "KWADIKO JUNIOR SECONDARY SCHOOL",
    Address: "LUTHULINI ADM AREA  MHLANGA LOCATION  FLAGSTAFF",
    Latitude: "-31.0496",
    Longitude: "29.4949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2681",
    WardLookupId: "3145",
    VDNumber: "11810360",
    RegisteredPopulation: "260",
    VotingStationName: "MKHUMBI JUNIOR SECONDARY SCHOOL",
    Address: "LUTHULINI A/A  MKHUMBI LOCATION  FLAGSTAFF",
    Latitude: "-31.0359",
    Longitude: "29.4468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2682",
    WardLookupId: "3145",
    VDNumber: "11810416",
    RegisteredPopulation: "1108",
    VotingStationName: "PHAKAMANI JUNIOR SECONDARY SCHOOL",
    Address: "LUTHULINI A/A  LUTHULINI LOCATION  FLAGSTAFF",
    Latitude: "-31.0047",
    Longitude: "29.4451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2683",
    WardLookupId: "3145",
    VDNumber: "11810977",
    RegisteredPopulation: "425",
    VotingStationName: "NGCUNGENI JUNIOR SECONDARY SCHOOL",
    Address: "NGCUNGENI LOCATION  SIPAQENI A/A  FLAGSTAFF",
    Latitude: "-31.0253",
    Longitude: "29.5372",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2684",
    WardLookupId: "3145",
    VDNumber: "11810988",
    RegisteredPopulation: "339",
    VotingStationName: "ZADUNGENI SENIOR PRIMARY SCHOOL",
    Address: "ZADUNGENI LOCATION  SIPAQENI A/A  FLAGSTAFF",
    Latitude: "-31.0673",
    Longitude: "29.4426",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2685",
    WardLookupId: "3145",
    VDNumber: "11810999",
    RegisteredPopulation: "461",
    VotingStationName: "KRESTU CHURCH OF CHRIST",
    Address: "XOPOZO LOCATION  XOPOZO A/A  FLAGSTAFF",
    Latitude: "-31.0004",
    Longitude: "29.494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2686",
    WardLookupId: "3145",
    VDNumber: "11811158",
    RegisteredPopulation: "337",
    VotingStationName: "MKHUMENI SENIOR PRIMARY SCHOOL",
    Address: "MKHUMENI LOCATION  SIPHAQENI A/A  FLAGSTAFF",
    Latitude: "-31.058",
    Longitude: "29.4896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2687",
    WardLookupId: "3145",
    VDNumber: "11811170",
    RegisteredPopulation: "239",
    VotingStationName: "AMOS KANGO COMMUNITY HALL",
    Address: "MTSHEKELWENI LOCATION  LUTHULINI A/A  FLAGSTAFF",
    Latitude: "-31.0402",
    Longitude: "29.5062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2688",
    WardLookupId: "3146",
    VDNumber: "11810337",
    RegisteredPopulation: "513",
    VotingStationName: "XOLANI PRE-SCHOOL",
    Address: "NGQANDULO LOC  SIPHAQENI A/A  FLAGSTAFF",
    Latitude: "-31.0461",
    Longitude: "29.5364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2689",
    WardLookupId: "3146",
    VDNumber: "11810348",
    RegisteredPopulation: "1295",
    VotingStationName: "DILIZA JUNIOR SECONDARY SCHOOL",
    Address: "SIPAQENI LOCATION  SIPAQENI A/A  FLAGSTAFF",
    Latitude: "-31.0612",
    Longitude: "29.5193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2690",
    WardLookupId: "3146",
    VDNumber: "11810359",
    RegisteredPopulation: "902",
    VotingStationName: "NDABANKULU JUNIOR SECONDARY SCHOOL",
    Address: "BHALASI LOC  SIPHAQENI A/A  FLAGSTAFF",
    Latitude: "-31.0834",
    Longitude: "29.5289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2691",
    WardLookupId: "3146",
    VDNumber: "11810641",
    RegisteredPopulation: "880",
    VotingStationName: "SIGUBUDWINI JUNIOR SECONDARY SCHOOL",
    Address: "SIPAQENI A/A  SIGUBUDWINI LOCATION  FLAGSTAFF",
    Latitude: "-31.0935",
    Longitude: "29.5084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2692",
    WardLookupId: "3146",
    VDNumber: "11811046",
    RegisteredPopulation: "339",
    VotingStationName: "MAKHOSONKE JUNIOR SECONDARY SCHOOL",
    Address: "NDUKUDENI LOCATION  MSIKABA A/A  FLAGSTAFF",
    Latitude: "-31.1092",
    Longitude: "29.5266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2693",
    WardLookupId: "3146",
    VDNumber: "11811282",
    RegisteredPopulation: "487",
    VotingStationName: "NGQAYIMBANA  FULL GOSPEL CHURCH",
    Address: "NGQAYIMBANA LOCATION  SIPAQENI ADMIN AREA  FLAGSTAFF",
    Latitude: "-31.063",
    Longitude: "29.5299",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2694",
    WardLookupId: "3147",
    VDNumber: "11810292",
    RegisteredPopulation: "787",
    VotingStationName: "EMZENGE JUNIOR SECONDARY SCHOOL",
    Address: "EMZENGE LOCATION  NDIMAKUDE A/A  FLAGSTAFF",
    Latitude: "-31.0959",
    Longitude: "29.6142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2695",
    WardLookupId: "3147",
    VDNumber: "11810304",
    RegisteredPopulation: "803",
    VotingStationName: "THWAZI JUNIOR SECONDARY SCHOOL",
    Address: "THWAZI LOCATION  SIPAQENI A/A  FLAGSTAFF",
    Latitude: "-31.0704",
    Longitude: "29.5808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2696",
    WardLookupId: "3147",
    VDNumber: "11810315",
    RegisteredPopulation: "556",
    VotingStationName: "FAMA JUNIOR SENIOR SECONDARY SCHOOL",
    Address: "FAMA LOC  SIPHAQENI A/A  FLAGSTAFF",
    Latitude: "-31.1",
    Longitude: "29.5585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2697",
    WardLookupId: "3147",
    VDNumber: "11810326",
    RegisteredPopulation: "844",
    VotingStationName: "EMANGQUZU JUNIOR SECONDARY SCHOOL",
    Address: "MANGQUZU LOC  SIPHAQENI A/A  FLAGSTAFF",
    Latitude: "-31.105",
    Longitude: "29.5687",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2698",
    WardLookupId: "3147",
    VDNumber: "11811057",
    RegisteredPopulation: "524",
    VotingStationName: "ST PETER JUNIOR SECONDARY SCHOOL",
    Address: "J.B LOCATION  SIPAQENI A/A  FLAGSTAFF",
    Latitude: "-31.0955",
    Longitude: "29.5756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2699",
    WardLookupId: "3147",
    VDNumber: "11811068",
    RegisteredPopulation: "312",
    VotingStationName: "THABAZI JUNIOR SECONDARY SCHOOL",
    Address: "FAMA LOCATION  SIPAQENI A/A  FLAGSTAFF",
    Latitude: "-31.0782",
    Longitude: "29.5697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2700",
    WardLookupId: "3147",
    VDNumber: "11811181",
    RegisteredPopulation: "363",
    VotingStationName: "TUMSE SENIOR PRIMARY SCHOOL",
    Address: "KWAZULU LOCATION  THWAZI A/A  FLAGSTAFF",
    Latitude: "-31.0503",
    Longitude: "29.6095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2701",
    WardLookupId: "3148",
    VDNumber: "11770220",
    RegisteredPopulation: "607",
    VotingStationName: "MXHOKOZWENI JUNIOR SECONDARY SCHOOL",
    Address: "MXOKOZWENI LOCATION  MSIKABA A/A  LUSIKISIKI",
    Latitude: "-31.1592",
    Longitude: "29.548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2702",
    WardLookupId: "3148",
    VDNumber: "11770893",
    RegisteredPopulation: "926",
    VotingStationName: "BODWENI JUNIOR SECONDARY SCHOOL",
    Address: "BODWENI LOCATION  BODWENI A/A  LUSIKISIKI",
    Latitude: "-31.1851",
    Longitude: "29.5498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2703",
    WardLookupId: "3148",
    VDNumber: "11771164",
    RegisteredPopulation: "185",
    VotingStationName: "KOVOTI JUNIOR SECONDARY SCHOOL",
    Address: "KHOVOTHI LOCATION  TSWELENI A/A  LUSIKISIKI",
    Latitude: "-31.1912",
    Longitude: "29.5956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2704",
    WardLookupId: "3148",
    VDNumber: "11771580",
    RegisteredPopulation: "265",
    VotingStationName: "GCINISIZWE SENIOR PRIMARY SCHOOL",
    Address: "MQWANGQWENI LOCATION  MSIKABA A/A  FLAGSTAFF",
    Latitude: "-31.1505",
    Longitude: "29.5407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2705",
    WardLookupId: "3148",
    VDNumber: "11771603",
    RegisteredPopulation: "301",
    VotingStationName: "QHAMANGWENI CHURCH",
    Address: "QHAMANGWENI LOCATION  MSIKABA A/A  FLAGSTAFF",
    Latitude: "-31.2013",
    Longitude: "29.5423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2706",
    WardLookupId: "3148",
    VDNumber: "11772042",
    RegisteredPopulation: "181",
    VotingStationName: "MBHADANGO JUNIOR SECONDARY SCHOOL",
    Address: "MBHADANGO LOCATION  MBHADANGO A/A  LUSIKISIKI",
    Latitude: "-31.1625",
    Longitude: "29.5051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2707",
    WardLookupId: "3148",
    VDNumber: "11810438",
    RegisteredPopulation: "958",
    VotingStationName: "HLWAHLWAZI JUNIOR SECONDARY SCHOOL",
    Address: "HLWAHLWAZI LOCATION  HLWAHLWAZI A/A  FLAGSTAFF",
    Latitude: "-31.1501",
    Longitude: "29.6377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2708",
    WardLookupId: "3148",
    VDNumber: "11810517",
    RegisteredPopulation: "666",
    VotingStationName: "NELSON SIGCAU JUNIOR SENIOR SECONDARY SCHOOL",
    Address: "KHIMBILE LOCATION  MXHOPHO A/A  FLAGSTAFF",
    Latitude: "-31.1337",
    Longitude: "29.5719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2709",
    WardLookupId: "3148",
    VDNumber: "11811136",
    RegisteredPopulation: "463",
    VotingStationName: "MAMPONDO JUNIOR SECONDARY SCHOOL",
    Address: "KHIMBILE LOCATION  MXHOPHO A/A  FLAGSTAFF",
    Latitude: "-31.1247",
    Longitude: "29.5603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2710",
    WardLookupId: "3149",
    VDNumber: "11770208",
    RegisteredPopulation: "661",
    VotingStationName: "BUKAZI JUNIOR SECONDARY SCHOOL",
    Address: "BUKAZI LOCATION  BUKAZI A/A  LUSIKISIKI",
    Latitude: "-31.1788",
    Longitude: "29.4567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2711",
    WardLookupId: "3149",
    VDNumber: "11770477",
    RegisteredPopulation: "807",
    VotingStationName: "NOMATHEMBA JUNIOR SECONDARY SCHOOL",
    Address: "TSHONYA B. A/A  TSHONYA A/A  LUSIKISIKI",
    Latitude: "-31.24",
    Longitude: "29.5585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2712",
    WardLookupId: "3149",
    VDNumber: "11770635",
    RegisteredPopulation: "895",
    VotingStationName: "BUHLANYANGA JUNIOR SECONDARY SCHOOL",
    Address: "BUHLANYANGA LOCATION  TSWELENI A/A  FLAGSTAFF",
    Latitude: "-31.2267",
    Longitude: "29.6035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2713",
    WardLookupId: "3149",
    VDNumber: "11770871",
    RegisteredPopulation: "261",
    VotingStationName: "GINGA`S JUNIOR SECONDARY SCHOOL",
    Address: "SIPEZINI LOCATION  TSHONYA A/A  LUSIKISIKI",
    Latitude: "-31.2302",
    Longitude: "29.5206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2714",
    WardLookupId: "3149",
    VDNumber: "11771142",
    RegisteredPopulation: "286",
    VotingStationName: "VALINGOZI JUNIOR SECONDARY SCHOOL",
    Address: "SIPHEZINI LOC  MHLUMBA A/A  LUSIKISIKI",
    Latitude: "-31.2102",
    Longitude: "29.5377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2715",
    WardLookupId: "3149",
    VDNumber: "11771153",
    RegisteredPopulation: "407",
    VotingStationName: "NZAKA JUNIOR SECONDARY SCHOOL",
    Address: "TSWELENI LOC  TSWELENI A/A  LUSIKISIKI",
    Latitude: "-31.2183",
    Longitude: "29.5736",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2716",
    WardLookupId: "3149",
    VDNumber: "11771298",
    RegisteredPopulation: "295",
    VotingStationName: "LUBALA SENIOR PRIMARY SCHOOL",
    Address: "MHLUMBA LOC  LUBALA A/A  LUSIKISIKI",
    Latitude: "-31.1895",
    Longitude: "29.4981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2717",
    WardLookupId: "3149",
    VDNumber: "11771669",
    RegisteredPopulation: "580",
    VotingStationName: "PHANDAPHANTSI SENIOR PRIMARY SCHOOL",
    Address: "FLAGSTAFF  ",
    Latitude: "-31.1801",
    Longitude: "29.4719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2718",
    WardLookupId: "3149",
    VDNumber: "11771692",
    RegisteredPopulation: "306",
    VotingStationName: "DALIBHUNGA SENIOR PRIMARY SCHOOL",
    Address: "DALIBHUNGA LOCATION  QAUKENI A/A  LUSIKISIKI",
    Latitude: "-31.2463",
    Longitude: "29.5236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2719",
    WardLookupId: "3149",
    VDNumber: "11771737",
    RegisteredPopulation: "360",
    VotingStationName: "THANDIZULU JUNIOR SECONDARY SCHOOL",
    Address: "DIDI LOCATION  TSHONYA A/A  LUSIKISIKI",
    Latitude: "-31.2575",
    Longitude: "29.5569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2720",
    WardLookupId: "3150",
    VDNumber: "11770242",
    RegisteredPopulation: "1001",
    VotingStationName: "BUSHULA`S JUNIOR SECONDARY SCHOOL",
    Address: "SIX TREES LOCALITY  ZALU HEIGHTS A/A  LUSIKISIKI",
    Latitude: "-31.271",
    Longitude: "29.4802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2721",
    WardLookupId: "3150",
    VDNumber: "11770253",
    RegisteredPopulation: "742",
    VotingStationName: "NDIMBANENI SENIOR PRIMARY SCHOOL",
    Address: "ZALU LOCATION  ZALU A/A  LUSIKISIKI",
    Latitude: "-31.3243",
    Longitude: "29.4681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2722",
    WardLookupId: "3150",
    VDNumber: "11770859",
    RegisteredPopulation: "563",
    VotingStationName: "XURANA JUNIOR SECONDARY SCHOOL",
    Address: "LUMAYENI LOC  XURANA  AA  LUSIKISIKI",
    Latitude: "-31.2351",
    Longitude: "29.4956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2723",
    WardLookupId: "3150",
    VDNumber: "11771096",
    RegisteredPopulation: "1207",
    VotingStationName: "MYEZWENI JUNIOR SECONDARY SCHOOL",
    Address: "MYEZWENI LOC  BUSHULA A/A  LUSIKISIKI",
    Latitude: "-31.2888",
    Longitude: "29.5026",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2724",
    WardLookupId: "3150",
    VDNumber: "11771322",
    RegisteredPopulation: "531",
    VotingStationName: "DINGANA SENIOR PRIMARY SCHOOL",
    Address: "XURANA A/A  DINGANA LOCATION  LUSIKISIKI",
    Latitude: "-31.2546",
    Longitude: "29.4867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2725",
    WardLookupId: "3150",
    VDNumber: "11771670",
    RegisteredPopulation: "454",
    VotingStationName: "HILL SIDE JUNIOR SECONDARY SCHOOL",
    Address: "NTSIMBINI LOCATION  ZALU A/A  LUSIKISIKI",
    Latitude: "-31.2919",
    Longitude: "29.4771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2726",
    WardLookupId: "3151",
    VDNumber: "11770411",
    RegisteredPopulation: "968",
    VotingStationName: "LINGELETU JUNIOR SECONDARY SCHOOL",
    Address: "KWANOMPILO LOC.  NGOBOZANA A/A  LUSIKISIKI",
    Latitude: "-31.3431",
    Longitude: "29.5288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2727",
    WardLookupId: "3151",
    VDNumber: "11770422",
    RegisteredPopulation: "1384",
    VotingStationName: "KHOTSO JUNIOR SECONDARY SCHOOL",
    Address: "NGOBOZANA LOC  NGOBOZANA A/A  LUSIKISIKI",
    Latitude: "-31.353",
    Longitude: "29.5507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2728",
    WardLookupId: "3151",
    VDNumber: "11770433",
    RegisteredPopulation: "788",
    VotingStationName: "NGOBOZANA JUNIOR SECONDARY SCHOOL",
    Address: "NGOBOZANA LOCATION  NGOBOZANA A/A  LUSIKISIKI",
    Latitude: "-31.3617",
    Longitude: "29.5292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2729",
    WardLookupId: "3151",
    VDNumber: "11770444",
    RegisteredPopulation: "690",
    VotingStationName: "GUNYENI METHODIST CHURCH OF SA",
    Address: "GUNYENI LOC  NGOBOZANA A/A  LUSIKISIKI",
    Latitude: "-31.3825",
    Longitude: "29.5413",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2730",
    WardLookupId: "3151",
    VDNumber: "11771704",
    RegisteredPopulation: "662",
    VotingStationName: "HOLY APOSTOLIC CHURCH IN CHRIST",
    Address: "MCWABANTSASA LOCATION  MZINTLAVA A/A  LUSIKISIKI",
    Latitude: "-31.385",
    Longitude: "29.5245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2731",
    WardLookupId: "3152",
    VDNumber: "11690018",
    RegisteredPopulation: "1595",
    VotingStationName: "LUSIKISIKI TOWN HALL",
    Address: "MAIN STREET  LUSIKISIKI  LUSIKISIKI",
    Latitude: "-31.3668",
    Longitude: "29.5731",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2732",
    WardLookupId: "3152",
    VDNumber: "11690085",
    RegisteredPopulation: "1191",
    VotingStationName: "SLOVO PARK BRICKS",
    Address: "SLOVO PARK  LUSIKISIKI  ",
    Latitude: "-31.355",
    Longitude: "29.5761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2733",
    WardLookupId: "3152",
    VDNumber: "11690108",
    RegisteredPopulation: "1242",
    VotingStationName: "ASSEMBLIES    OF GOD CHURCH",
    Address: "MALIZOLE LOCATION  HOMBE A/A  LUSIKISIKI",
    Latitude: "-31.3658",
    Longitude: "29.6042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2734",
    WardLookupId: "3152",
    VDNumber: "11770860",
    RegisteredPopulation: "832",
    VotingStationName: "SICHWE JUNIOR SECONDARY SCHOOL",
    Address: "MDIKANA LOC  LUSIKISIKI  LUSIKISIKI",
    Latitude: "-31.3313",
    Longitude: "29.5883",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2735",
    WardLookupId: "3153",
    VDNumber: "11770624",
    RegisteredPopulation: "1387",
    VotingStationName: "MGEZWA JUNIOR SECONDARY SCHOOL",
    Address: "MGEZWA LOCATION  LUSIKISIKI",
    Latitude: "-31.344",
    Longitude: "29.6445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2736",
    WardLookupId: "3153",
    VDNumber: "11770736",
    RegisteredPopulation: "885",
    VotingStationName: "THEMBALETHU SENIOR PRIMARY SCHOOL",
    Address: "MGEZWA LOC.  NKUNZIMBINI A/A  LUSIKISIKI",
    Latitude: "-31.3318",
    Longitude: "29.6662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2737",
    WardLookupId: "3153",
    VDNumber: "11771366",
    RegisteredPopulation: "293",
    VotingStationName: "NQAQHUMBE JUNIOR SECONDARY SCHOOL",
    Address: "NQAQHUMBE LOCATION  LUSIKISIKI",
    Latitude: "-31.3516",
    Longitude: "29.6108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2738",
    WardLookupId: "3153",
    VDNumber: "11771399",
    RegisteredPopulation: "1279",
    VotingStationName: "MALANGENI SENIOR PRIMARY SCHOOL",
    Address: "MALANGENI LOCATION  NKUNZIMBINI A/A  LUSIKISIKI",
    Latitude: "-31.3219",
    Longitude: "29.6974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2739",
    WardLookupId: "3154",
    VDNumber: "11770499",
    RegisteredPopulation: "1047",
    VotingStationName: "KWA-DICK JUNIOR SECONDARY SCHOOL",
    Address: "KWADICK LOCATION  XURA A/A  LUSIKISIKI",
    Latitude: "-31.3129",
    Longitude: "29.5357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2740",
    WardLookupId: "3154",
    VDNumber: "11770501",
    RegisteredPopulation: "709",
    VotingStationName: "CANHAMS JUNIOR SECONDARY SCHOOL",
    Address: "KANANA LOCATION  LUSIKISIKI",
    Latitude: "-31.3357",
    Longitude: "29.5678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2741",
    WardLookupId: "3154",
    VDNumber: "11770938",
    RegisteredPopulation: "427",
    VotingStationName: "JONGIKHAYA JUNIOR SECONDARY SCHOOL",
    Address: "BAZANA LOC  TSHONYA A/A  LUSIKISIKI",
    Latitude: "-31.3011",
    Longitude: "29.5629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2742",
    WardLookupId: "3154",
    VDNumber: "11771041",
    RegisteredPopulation: "790",
    VotingStationName: "LAPHUMILANGA  SENIOR PRIMARY SCHOOL",
    Address: "NTSIMBINI LOCATION  ZALU ADMIN AREA  LUSIKISIKI",
    Latitude: "-31.3133",
    Longitude: "29.4944",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2743",
    WardLookupId: "3154",
    VDNumber: "11771355",
    RegisteredPopulation: "595",
    VotingStationName: "XURA VIEW JUNIOR SECONDARY SCHOOL",
    Address: "TSHANDATSHE LOC  XURA A/A  LUSIKISIKI",
    Latitude: "-31.3326",
    Longitude: "29.532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2744",
    WardLookupId: "3154",
    VDNumber: "11771726",
    RegisteredPopulation: "384",
    VotingStationName: "VUKAPHI STORE",
    Address: "MAKHWETSHUBENI LOCATION  XURA A/A  LUSIKISIKI",
    Latitude: "-31.332",
    Longitude: "29.5741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2745",
    WardLookupId: "3154",
    VDNumber: "11772176",
    RegisteredPopulation: "207",
    VotingStationName: "EVERLASTING  JOY CHURCH",
    Address: "PALMERTON LOCATION  ZALU ADMIN AREA  LUSIKISIKI",
    Latitude: "-31.3363",
    Longitude: "29.4988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2746",
    WardLookupId: "3155",
    VDNumber: "11770488",
    RegisteredPopulation: "372",
    VotingStationName: "MASOBHUZA SENIOR PRIMARY SCHOOL",
    Address: "FAMA LOCATION  QAUKENI A/A  LUSIKISIKI",
    Latitude: "-31.2726",
    Longitude: "29.5893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2747",
    WardLookupId: "3155",
    VDNumber: "11770646",
    RegisteredPopulation: "609",
    VotingStationName: "CETYWAYO JUNIOR SECONDARY SCHOOL",
    Address: "TSHONYA LOCATION  TSHONYA A/A  LUSIKISIKI",
    Latitude: "-31.3088",
    Longitude: "29.6043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2748",
    WardLookupId: "3155",
    VDNumber: "11771029",
    RegisteredPopulation: "619",
    VotingStationName: "MVIMVANE JUNIOR SECONDARY SCHOOL",
    Address: "MVIMVANE LOC.  TSHONYA A/A  LUSIKISIKI",
    Latitude: "-31.2801",
    Longitude: "29.5466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2749",
    WardLookupId: "3155",
    VDNumber: "11771175",
    RegisteredPopulation: "278",
    VotingStationName: "KWA NDUMISO SENIOR PRIMARY SCHOOL",
    Address: "TSHONYA .LOC  TSHONYA A/A  LUSIKISIKI",
    Latitude: "-31.2714",
    Longitude: "29.6489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2750",
    WardLookupId: "3155",
    VDNumber: "11771311",
    RegisteredPopulation: "823",
    VotingStationName: "MDABUKA JUNIOR SECONDARY SCHOOL",
    Address: "PHUMLO LOCALITY  PHUMLO A/A  LUSIKISIKI",
    Latitude: "-31.2758",
    Longitude: "29.6139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2751",
    WardLookupId: "3155",
    VDNumber: "11771333",
    RegisteredPopulation: "561",
    VotingStationName: "NGXAMBANI JUNIOR SECONDARY SCHOOL",
    Address: "NGXAMBANI LOCATION  LUSIKISIKI",
    Latitude: "-31.236",
    Longitude: "29.6257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2752",
    WardLookupId: "3155",
    VDNumber: "11771344",
    RegisteredPopulation: "308",
    VotingStationName: "CHANI JUNIOR SECONDARY SCHOOL",
    Address: "CHANI LOCALITY  PHUMLO A/A  LUSIKISIKI",
    Latitude: "-31.2594",
    Longitude: "29.6321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2753",
    WardLookupId: "3155",
    VDNumber: "11771681",
    RegisteredPopulation: "277",
    VotingStationName: "THABATHA JUNIOR SECONDARY SCHOOL",
    Address: "TABATA LOCATION  TABATA A/A  LUSIKISIKI",
    Latitude: "-31.2807",
    Longitude: "29.5309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2754",
    WardLookupId: "3155",
    VDNumber: "11771894",
    RegisteredPopulation: "507",
    VotingStationName: "BOTHA SIGCAU SENIOR SECONDARY SCHOOL",
    Address: "QAUKENI LOCALITY  QAUKENI A/A  LUSIKISIKI",
    Latitude: "-31.2854",
    Longitude: "29.5755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2755",
    WardLookupId: "3155",
    VDNumber: "11771906",
    RegisteredPopulation: "225",
    VotingStationName: "KWA-NONDUDUMO JUNIOR SECONDARY SCHOOL",
    Address: "NONDUDUMO LOCATION  TSHONYA A/A  LUSIKISIKI",
    Latitude: "-31.2525",
    Longitude: "29.5922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2756",
    WardLookupId: "3156",
    VDNumber: "11690029",
    RegisteredPopulation: "396",
    VotingStationName: "HILLBROW JUNIOR SECONDARY SCHOOL",
    Address: "ARTHUR HOMES  LUSIKISIKI",
    Latitude: "-31.3554",
    Longitude: "29.5699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2757",
    WardLookupId: "3156",
    VDNumber: "11690041",
    RegisteredPopulation: "733",
    VotingStationName: "ISIQALO  PRE-SCHOOL",
    Address: "ARTHUR HOMES LOCATION  NGOBOZANA ADMIN AREA  LUSIKISIKI",
    Latitude: "-31.3755",
    Longitude: "29.574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2758",
    WardLookupId: "3156",
    VDNumber: "11690052",
    RegisteredPopulation: "656",
    VotingStationName: "KHONJWAYO DUTCH REFORMED CHURCH",
    Address: "NYUSWA LOCALITY  KWA KHONWAYO A/A  LUSIKISIKI",
    Latitude: "-31.3744",
    Longitude: "29.5434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2759",
    WardLookupId: "3156",
    VDNumber: "11690063",
    RegisteredPopulation: "485",
    VotingStationName: "LUSIKISIKI COLLEGE OF EDUCATION",
    Address: "NGOBOZANA LOCATION  NGOBOZANA A/A  LUSIKISIKI",
    Latitude: "-31.3548",
    Longitude: "29.5539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2760",
    WardLookupId: "3156",
    VDNumber: "11690096",
    RegisteredPopulation: "982",
    VotingStationName: "UNITY PARK FULL GOSPEL CHURCH",
    Address: "UNITY PARK  NGOBOZANA A/A  LUSIKISIKI",
    Latitude: "-31.3601",
    Longitude: "29.5583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2761",
    WardLookupId: "3156",
    VDNumber: "11690119",
    RegisteredPopulation: "502",
    VotingStationName: "MANZAMNYAMA PRE-SCHOOL",
    Address: "KWA GQATHULA LOCATION  SLOVO TOWNSHIP  LUSIKISIKI",
    Latitude: "-31.3502",
    Longitude: "29.5656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2762",
    WardLookupId: "3156",
    VDNumber: "11770512",
    RegisteredPopulation: "1000",
    VotingStationName: "ST DENNIS JUNIOR SECONDARY SCHOOL",
    Address: "MBUDU LOC  MBUDU A/A  LUSIKISIKI",
    Latitude: "-31.3929",
    Longitude: "29.5566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2763",
    WardLookupId: "3156",
    VDNumber: "11772086",
    RegisteredPopulation: "416",
    VotingStationName: "BEN MALI SENIOR SECONDARY SCHOOL",
    Address: "MBUDU LOCATION  MBUDU A/A  LUSIKISIKI",
    Latitude: "-31.4133",
    Longitude: "29.5386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2764",
    WardLookupId: "3157",
    VDNumber: "11770567",
    RegisteredPopulation: "1509",
    VotingStationName: "BIKITSHA JUNIOR SECONDARY SCHOOL",
    Address: "GOSO LOCATION  DUBANA A/A  LUSIKISIKI",
    Latitude: "-31.3778",
    Longitude: "29.6193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2765",
    WardLookupId: "3157",
    VDNumber: "11770613",
    RegisteredPopulation: "1324",
    VotingStationName: "HOMBE JUNIOR SECONDARY SCHOOL",
    Address: "HOMBE LOCATION  NKUNZIMBINI A/A  LUSIKISIKI",
    Latitude: "-31.3586",
    Longitude: "29.6362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2766",
    WardLookupId: "3157",
    VDNumber: "11771838",
    RegisteredPopulation: "1027",
    VotingStationName: "ZANOKHANYO SENIOR PRIMARY SCHOOL",
    Address: "ZANOKHANYO LOCATION  GOSO A/A  LUSIKISIKI",
    Latitude: "-31.374",
    Longitude: "29.5974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2767",
    WardLookupId: "3157",
    VDNumber: "11771928",
    RegisteredPopulation: "496",
    VotingStationName: "ELUSINDISWENI CHURCH",
    Address: "MTSHAYELO LOCATION  HOMBE A/A  LUSIKISIKI",
    Latitude: "-31.3636",
    Longitude: "29.6316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2768",
    WardLookupId: "3158",
    VDNumber: "11770523",
    RegisteredPopulation: "687",
    VotingStationName: "MAVELA JUNIOR SECONDARY SCHOOL",
    Address: "MAVELA LOC  MAVELA A/A  LUSIKISIKI",
    Latitude: "-31.3904",
    Longitude: "29.5968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2769",
    WardLookupId: "3158",
    VDNumber: "11770534",
    RegisteredPopulation: "1197",
    VotingStationName: "SIWALI JUNIOR SECONDARY SCHOOL",
    Address: "MCOBOTHINI LOCATION  DUBANA A/A  LUSIKISIKI",
    Latitude: "-31.4104",
    Longitude: "29.5972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2770",
    WardLookupId: "3158",
    VDNumber: "11771108",
    RegisteredPopulation: "1012",
    VotingStationName: "QAKATISA JUNIOR SECONDARY SCHOOL",
    Address: "KWANYUSWA LOCATION  MEVANA A/A  LUSIKISIKI",
    Latitude: "-31.3886",
    Longitude: "29.58",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2771",
    WardLookupId: "3158",
    VDNumber: "11771939",
    RegisteredPopulation: "982",
    VotingStationName: "NGQUNGQUSHE TECHNICAL COLLEGE",
    Address: "GOSO LOCATION  GOSO A/A  LUSIKISIKI",
    Latitude: "-31.3758",
    Longitude: "29.5871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2772",
    WardLookupId: "3158",
    VDNumber: "11772097",
    RegisteredPopulation: "565",
    VotingStationName: "QAKATHISA METHODIST CHURCH",
    Address: "MAVELA VILLAGE  LUSIKISIKI  NEVANA A/A",
    Latitude: "-31.3937",
    Longitude: "29.5902",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2773",
    WardLookupId: "3158",
    VDNumber: "11772198",
    RegisteredPopulation: "49",
    VotingStationName: "DAZIBONE SENIOR PRIMARY SCHOOL",
    Address: "GUNYENI LOACATION  GUNYENI ADMIN AREA  LUSIKISKI",
    Latitude: "-31.4165",
    Longitude: "29.5464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2774",
    WardLookupId: "3158",
    VDNumber: "11772200",
    RegisteredPopulation: "231",
    VotingStationName: "NGIBE SENIOR PRIMARY SCHOOL",
    Address: "MCOBOTHINI LOCATION  MCOBOTHINI ADMIN AREA  LUSIKISIKI",
    Latitude: "-31.4164",
    Longitude: "29.5798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2775",
    WardLookupId: "3159",
    VDNumber: "11770545",
    RegisteredPopulation: "342",
    VotingStationName: "GWEXINTABA SENIOR PRIMARY SCHOOL",
    Address: "GOSO FOREST   AA  GOSO FOREST LOCATION  LUSIKISIKI",
    Latitude: "-31.4571",
    Longitude: "29.6346",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2776",
    WardLookupId: "3159",
    VDNumber: "11770578",
    RegisteredPopulation: "1459",
    VotingStationName: "GOSO FOREST JUNIOR SECONDARY SCHOOL",
    Address: "GOSO FOREST A/A  LUSIKISIKI",
    Latitude: "-31.392",
    Longitude: "29.6245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2777",
    WardLookupId: "3159",
    VDNumber: "11770589",
    RegisteredPopulation: "777",
    VotingStationName: "MZIZANGWA JUNIOR SECONDARY SCHOOL",
    Address: "MZIZANGWA LOC  GOSO FOREST A/A  LUSIKISIKI",
    Latitude: "-31.4102",
    Longitude: "29.6439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2778",
    WardLookupId: "3159",
    VDNumber: "11770602",
    RegisteredPopulation: "612",
    VotingStationName: "VELLEM JUNIOR SECONDARY SCHOOL",
    Address: "NKUNZIMBINI LOCATION  NKUNZIMBINI A/A  LUSIKISIKI",
    Latitude: "-31.3541",
    Longitude: "29.6618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2779",
    WardLookupId: "3159",
    VDNumber: "11771197",
    RegisteredPopulation: "402",
    VotingStationName: "MAGWA SENIOR PRIMARY SCHOOL",
    Address: "MAGWA LOCATION  MAGWA A/A  LUSIKISIKI",
    Latitude: "-31.418",
    Longitude: "29.6721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2780",
    WardLookupId: "3160",
    VDNumber: "11770556",
    RegisteredPopulation: "790",
    VotingStationName: "MBOTYI JUNIOR SECONDARY SCHOOL",
    Address: "MBOTYI LOCATION  LUSIKISIKI",
    Latitude: "-31.4666",
    Longitude: "29.7291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2781",
    WardLookupId: "3160",
    VDNumber: "11770590",
    RegisteredPopulation: "490",
    VotingStationName: "MAQULU JUNIOR SECONDARY SCHOOL",
    Address: "LUTWINI LOC  LAMBASI A/A  LUSIKISIKI",
    Latitude: "-31.4193",
    Longitude: "29.755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2782",
    WardLookupId: "3160",
    VDNumber: "11770837",
    RegisteredPopulation: "271",
    VotingStationName: "NDENGANA SENIOR PRIMARY SCHOOL",
    Address: "NDENGANE LOCATION  LAMBASI A/A  LUSIKISIKI",
    Latitude: "-31.3233",
    Longitude: "29.9631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2783",
    WardLookupId: "3160",
    VDNumber: "11771074",
    RegisteredPopulation: "66",
    VotingStationName: "MAZIZI ESTATE",
    Address: "MAZIZI LOC.  LAMBASI A/A  LUSIKISIKI",
    Latitude: "-31.3799",
    Longitude: "29.7305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2784",
    WardLookupId: "3160",
    VDNumber: "11771401",
    RegisteredPopulation: "540",
    VotingStationName: "MXHUME JUNIOR SECONDARY SCHOOL",
    Address: "MXHUME LOCATION  LUSIKISIKI",
    Latitude: "-31.3365",
    Longitude: "29.7618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2785",
    WardLookupId: "3160",
    VDNumber: "11771412",
    RegisteredPopulation: "682",
    VotingStationName: "NDINDINDI JUNIOR SECONDARY SCHOOL",
    Address: "LAMBASI A/A  DIMFI LOCATION  LUSIKISIKI",
    Latitude: "-31.3264",
    Longitude: "29.8421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2786",
    WardLookupId: "3160",
    VDNumber: "11771715",
    RegisteredPopulation: "89",
    VotingStationName: "WEMA TOWNSHIP",
    Address: "MAGWA LOCATION  LAMBASI A/A  LUSIKISIKI",
    Latitude: "-31.468",
    Longitude: "29.6425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2787",
    WardLookupId: "3160",
    VDNumber: "11771782",
    RegisteredPopulation: "538",
    VotingStationName: "KHONJWAYO SENIOR PRIMARY SCHOOL",
    Address: "KHONJWAYO LOCATION  LAMBASI A/A  LUSIKISIKI",
    Latitude: "-31.3276",
    Longitude: "29.7852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2788",
    WardLookupId: "3160",
    VDNumber: "11772064",
    RegisteredPopulation: "289",
    VotingStationName: "KWA RHOLE SENIOR PRIMARY SCHOOL",
    Address: "NDENGANE LOCATION - KWARHOLE  LAMBASI A/A  LUSIKISIKI",
    Latitude: "-31.3648",
    Longitude: "29.8648",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2789",
    WardLookupId: "3161",
    VDNumber: "11770815",
    RegisteredPopulation: "1248",
    VotingStationName: "NJOMBELA JUNIOR SECONDARY SCHOOL",
    Address: "NJOMBELA LOCATION  MSIKABA A/A  LUSIKISIKI",
    Latitude: "-31.2842",
    Longitude: "29.7423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2790",
    WardLookupId: "3161",
    VDNumber: "11771377",
    RegisteredPopulation: "930",
    VotingStationName: "MATHEKO JUNIOR SECONDARY SCHOOL",
    Address: "MATHEKO LOCATION  MATHEKO A/A  LUSIKISIKI",
    Latitude: "-31.3028",
    Longitude: "29.7167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2791",
    WardLookupId: "3161",
    VDNumber: "11771388",
    RegisteredPopulation: "765",
    VotingStationName: "NTLAMVUKAZI SENIOR PRIMARY SCHOOL",
    Address: "NTLAMVUKAZI LOCATION  NTLAMVUKAZI A/A  LUSIKISIKI",
    Latitude: "-31.3392",
    Longitude: "29.7428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2792",
    WardLookupId: "3161",
    VDNumber: "11771805",
    RegisteredPopulation: "843",
    VotingStationName: "TANZI JUNIOR SECONDARY SCHOOL",
    Address: "MGEZWA LOCATION  MGEZWA A/A  LUSIKISIKI",
    Latitude: "-31.3545",
    Longitude: "29.6833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2793",
    WardLookupId: "3162",
    VDNumber: "11770792",
    RegisteredPopulation: "497",
    VotingStationName: "GINYINDLOVU SENIOR PRIMARY SCHOOL",
    Address: "LOWER HLABATHI LOCATION  VLEI A/A  LUSIKISIKI",
    Latitude: "-31.225",
    Longitude: "29.8371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2794",
    WardLookupId: "3162",
    VDNumber: "11770804",
    RegisteredPopulation: "1072",
    VotingStationName: "MKHAMELA JUNIOR SECONDARY SCHOOL",
    Address: "MTENTU LOC  MTENTU A/A  LUSIKISIKI",
    Latitude: "-31.2375",
    Longitude: "29.8033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2795",
    WardLookupId: "3162",
    VDNumber: "11770994",
    RegisteredPopulation: "960",
    VotingStationName: "NGWENYENI JUNIOR SECONDARY SCHOOL",
    Address: "MTENTU LOC  MTENTU A/A  LUSIKISIKI",
    Latitude: "-31.1957",
    Longitude: "29.8492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2796",
    WardLookupId: "3162",
    VDNumber: "11771254",
    RegisteredPopulation: "82",
    VotingStationName: "MKHAMBATI GAME RESERVE",
    Address: "MKHAMBATHI A/A  MKHAMBATI A/A  LUSIKISIKI",
    Latitude: "-31.2834",
    Longitude: "29.9555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2797",
    WardLookupId: "3162",
    VDNumber: "11771760",
    RegisteredPopulation: "447",
    VotingStationName: "LUPHONDO JUNIOR SECONDARY SCHOOL",
    Address: "THAHLE LOCATION  MKHAMELA A/A  FLAGSTAFF",
    Latitude: "-31.2811",
    Longitude: "29.8272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2798",
    WardLookupId: "3162",
    VDNumber: "11771793",
    RegisteredPopulation: "668",
    VotingStationName: "GEBHUZA JUNIOR SECONDARY SCHOOL",
    Address: "MKHAMELA LOCATION  LOWER HLABATHI A/A  FLAGSTAFF",
    Latitude: "-31.2642",
    Longitude: "29.8194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2799",
    WardLookupId: "3162",
    VDNumber: "11772187",
    RegisteredPopulation: "256",
    VotingStationName: "MKHAMBATHI  SPS",
    Address: "DEPO  KHANYAYO  LUSIKISIKI",
    Latitude: "-31.2086",
    Longitude: "29.8812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2800",
    WardLookupId: "3163",
    VDNumber: "11770657",
    RegisteredPopulation: "928",
    VotingStationName: "MHLANGA JUNIOR SECONDARY SCHOOL",
    Address: "MHLANGA LOCATION  KWAMHLANGA A/A  FLAGSTAFF",
    Latitude: "-31.1861",
    Longitude: "29.6943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2801",
    WardLookupId: "3163",
    VDNumber: "11770703",
    RegisteredPopulation: "878",
    VotingStationName: "JIKINDABA SENIOR SECONDARY SCHOOL",
    Address: "MARHAMZINI LOCATION  RHAMZI A/A  FLAGSTAFF",
    Latitude: "-31.1986",
    Longitude: "29.7157",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2802",
    WardLookupId: "3163",
    VDNumber: "11770714",
    RegisteredPopulation: "766",
    VotingStationName: "MTONTSASA JUNIOR SECONDARY SCHOOL",
    Address: "MTSHAYELO LOC.  MTSHAYELO A/A  LUSIKISIKI",
    Latitude: "-31.2117",
    Longitude: "29.7055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2803",
    WardLookupId: "3163",
    VDNumber: "11770725",
    RegisteredPopulation: "680",
    VotingStationName: "BABANE SENIOR PRIMARY SCHOOL",
    Address: "VLEI LOC  VLEI A/A  LUSIKISIKI",
    Latitude: "-31.2425",
    Longitude: "29.6914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2804",
    WardLookupId: "3163",
    VDNumber: "11770770",
    RegisteredPopulation: "896",
    VotingStationName: "MATHAMBO JUNIOR SECONDARY SCHOOL",
    Address: "HLABATHI LOCATION  HLABATHI A/A  FLAGSTAFF",
    Latitude: "-31.2134",
    Longitude: "29.7302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2805",
    WardLookupId: "3164",
    VDNumber: "11770668",
    RegisteredPopulation: "388",
    VotingStationName: "MQHUME SENIOR PRIMARY SCHOOL",
    Address: "VLEI LOC  VLEI A/A  LUSIKISIKI",
    Latitude: "-31.1883",
    Longitude: "29.6515",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2806",
    WardLookupId: "3164",
    VDNumber: "11770679",
    RegisteredPopulation: "1030",
    VotingStationName: "ZIMISELE JUNIOR SECONDARY SCHOOL",
    Address: "VLEI LOC  VLEI A/A  LUSIKISIKI",
    Latitude: "-31.1594",
    Longitude: "29.6871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2807",
    WardLookupId: "3164",
    VDNumber: "11770927",
    RegisteredPopulation: "1187",
    VotingStationName: "NDALISO SENIOR SECONDARY SCHOOL",
    Address: "TAWENI LOCATION  VLEI A/A  LUSIKISIKI",
    Latitude: "-31.1504",
    Longitude: "29.6519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2808",
    WardLookupId: "3164",
    VDNumber: "11771300",
    RegisteredPopulation: "469",
    VotingStationName: "EDOLOPHINI JUNIOR SECONDARY SCHOOL",
    Address: "EDOLOPHINI LOC  VLEI A/A  LUSIKISIKI",
    Latitude: "-31.2135",
    Longitude: "29.6762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2809",
    WardLookupId: "3164",
    VDNumber: "11771748",
    RegisteredPopulation: "321",
    VotingStationName: "HOLY CROSS HALL",
    Address: "HOLY CROSS MISSION  VLEI A/A  LUSIKISIKI",
    Latitude: "-31.1489",
    Longitude: "29.6751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2810",
    WardLookupId: "3164",
    VDNumber: "11810731",
    RegisteredPopulation: "513",
    VotingStationName: "ZWELIVUMILE JUNIOR SECONDARY SCHOOL",
    Address: "MSIKABA LOCATION  MXHOPO A/A  FLAGSTAFF",
    Latitude: "-31.1888",
    Longitude: "29.6229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2811",
    WardLookupId: "3165",
    VDNumber: "11770691",
    RegisteredPopulation: "550",
    VotingStationName: "MBONO JUNIOR SECONDARY SCHOOL",
    Address: "SIXHONTWENI LOCATION  NGQUZA A/A  FLAGSTAFF",
    Latitude: "-31.1575",
    Longitude: "29.7482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2812",
    WardLookupId: "3165",
    VDNumber: "11770916",
    RegisteredPopulation: "703",
    VotingStationName: "KWA-VELILE JUNIOR SECONDARY SCHOOL",
    Address: "COKA LOCATION  VLEI A/A  LUSIKISIKI",
    Latitude: "-31.1615",
    Longitude: "29.7031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2813",
    WardLookupId: "3165",
    VDNumber: "11771849",
    RegisteredPopulation: "353",
    VotingStationName: "AFRICAN REFORMED CHURCH",
    Address: "MDUMAZULU LOCATION  KWACOKA A/A  FLAGSTAFF",
    Latitude: "-31.1334",
    Longitude: "29.7112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2814",
    WardLookupId: "3165",
    VDNumber: "11771917",
    RegisteredPopulation: "998",
    VotingStationName: "MHLANGA FULL GOSPEL CHURCH",
    Address: "MHLANGA LOC  VLEI A/A  FLAGSTAFF",
    Latitude: "-31.1738",
    Longitude: "29.6879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2815",
    WardLookupId: "3165",
    VDNumber: "11810539",
    RegisteredPopulation: "453",
    VotingStationName: "TAWUKA JUNIOR SECONDARY SCHOOL",
    Address: "TAWUKA A/A  FLAGSTAFF  FLAGSTAFF",
    Latitude: "-31.1095",
    Longitude: "29.6872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2816",
    WardLookupId: "3165",
    VDNumber: "11810551",
    RegisteredPopulation: "779",
    VotingStationName: "MKETENGENI JUNIOR SECONDARY SCHOOL",
    Address: "MKETENGENI LOCATION  MKETENGENI A/A  FLAGSTAFF",
    Latitude: "-31.1335",
    Longitude: "29.6974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2817",
    WardLookupId: "3165",
    VDNumber: "11810652",
    RegisteredPopulation: "374",
    VotingStationName: "KHABINGELE SENIOR PRIMARY SCHOOL",
    Address: "KHABINGELE LOCATION  FLAGSTAFF",
    Latitude: "-31.1107",
    Longitude: "29.7135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2818",
    WardLookupId: "3165",
    VDNumber: "11810708",
    RegisteredPopulation: "471",
    VotingStationName: "VUMINDABA SENIOR PRIMARY SCHOOL",
    Address: "MKETENGENI LOCATION  NKANYEZI LOCATION  FLAGSTAFF",
    Latitude: "-31.1386",
    Longitude: "29.6739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2819",
    WardLookupId: "3166",
    VDNumber: "11690030",
    RegisteredPopulation: "477",
    VotingStationName: "MANQILO SENIOR PRIMARY SCHOOL",
    Address: "NDZONDENI LOCATION  MXHOPO A/A  FLAGSTAFF",
    Latitude: "-31.1505",
    Longitude: "29.6043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2820",
    WardLookupId: "3166",
    VDNumber: "11810528",
    RegisteredPopulation: "1039",
    VotingStationName: "GWEBINKUMBI JUNIOR SECONDARY SCHOOL",
    Address: "NZONDENI LOC  MXHOPHO A/A  FLAGSTAFF",
    Latitude: "-31.1241",
    Longitude: "29.6023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2821",
    WardLookupId: "3166",
    VDNumber: "11810540",
    RegisteredPopulation: "687",
    VotingStationName: "GEORGE NTANTA JUNIOR SECONDARY SCHOOL",
    Address: "QHOQHO A/A  FLAGSTAFF  FLAGSTAFF",
    Latitude: "-31.1237",
    Longitude: "29.6524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2822",
    WardLookupId: "3166",
    VDNumber: "11810562",
    RegisteredPopulation: "614",
    VotingStationName: "EKUPUMLENI JUNIOR SECONDARY SCHOOL",
    Address: "EKUPHUMLENI LOCATION  NDIMAKUDE A/A  FLAGSTAFF",
    Latitude: "-31.1122",
    Longitude: "29.6251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2823",
    WardLookupId: "3166",
    VDNumber: "11810696",
    RegisteredPopulation: "469",
    VotingStationName: "LOWER MZENGE JUNIOR SECONDARY SCHOOL",
    Address: "GQWEZA LOCATION  NDIMAKUDE A/A  FLAGSTAFF",
    Latitude: "-31.0761",
    Longitude: "29.6565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2824",
    WardLookupId: "3166",
    VDNumber: "11810742",
    RegisteredPopulation: "153",
    VotingStationName: "LOWER QOQO SENIOR PRIMARY SCHOOL",
    Address: "LUQOQWENI LOC.  NDIMAKUDE A/A  FLAGSTAFF",
    Latitude: "-31.092",
    Longitude: "29.669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2825",
    WardLookupId: "3166",
    VDNumber: "11811079",
    RegisteredPopulation: "468",
    VotingStationName: "MTOMBOLWAZI  JUNIOR SECONDARY SCHOOL",
    Address: "HLWAHLWAZI LOCATION  HLWAHLWAZI A/A  FLAGSTAFF",
    Latitude: "-31.1278",
    Longitude: "29.6383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2826",
    WardLookupId: "3166",
    VDNumber: "11811080",
    RegisteredPopulation: "450",
    VotingStationName: "MPHUMAZE JUNIOR SECONDARY SCHOOL",
    Address: "NDIMAKUDE LOCATION  NDIMAKUDE A/A  FLAGSTAFF",
    Latitude: "-31.1004",
    Longitude: "29.6337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2827",
    WardLookupId: "3166",
    VDNumber: "11811192",
    RegisteredPopulation: "351",
    VotingStationName: "UPPER QHOQHO SENIOR PRIMARY SCHOOL",
    Address: "UPPER QHOQHO LOCATION  LUQHOQHWENI A/A  FLAGSTAFF",
    Latitude: "-31.1299",
    Longitude: "29.642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2828",
    WardLookupId: "3167",
    VDNumber: "11771827",
    RegisteredPopulation: "792",
    VotingStationName: "MEYISI SENIOR SECONDARY SCHOOL",
    Address: "MARASHU LOCATION  BHALA A/A  FLAGSTAFF",
    Latitude: "-30.9898",
    Longitude: "29.5587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2829",
    WardLookupId: "3167",
    VDNumber: "11810191",
    RegisteredPopulation: "832",
    VotingStationName: "NONKONYANA JUNIOR SECONDARY SCHOOL",
    Address: "BHALA A/A  NONKONYANA LOC  FLAGSTAFF",
    Latitude: "-30.9755",
    Longitude: "29.5536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2830",
    WardLookupId: "3167",
    VDNumber: "11810203",
    RegisteredPopulation: "516",
    VotingStationName: "TSHONISWA SENIOR PRIMARY SCHOOL",
    Address: "NJIMBINXENI .LOCATION  FLAGSTAFF  BHALA A/A",
    Latitude: "-31.0028",
    Longitude: "29.5672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2831",
    WardLookupId: "3167",
    VDNumber: "11810214",
    RegisteredPopulation: "300",
    VotingStationName: "MNELISWA SENIOR PRIMARY SCHOOL",
    Address: "JACA LOCATION  BHALA A/A  FLAGSTAFF",
    Latitude: "-30.9656",
    Longitude: "29.5664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2832",
    WardLookupId: "3167",
    VDNumber: "11810269",
    RegisteredPopulation: "381",
    VotingStationName: "LUPHANDLASI JUNIOR SECONDARY SCHOOL",
    Address: "BHALA LOCATION  BHALA A/A  FLAGSTAFF",
    Latitude: "-30.9881",
    Longitude: "29.5816",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2833",
    WardLookupId: "3167",
    VDNumber: "11811002",
    RegisteredPopulation: "389",
    VotingStationName: "MALI JUNIOR SECONDARY SCHOOL",
    Address: "ESIBHIRHOLO LOCATION  BHALA A/A  FLAGSTAFF",
    Latitude: "-31.0195",
    Longitude: "29.5641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2834",
    WardLookupId: "3167",
    VDNumber: "11811024",
    RegisteredPopulation: "551",
    VotingStationName: "FULL GOSPEL CHURCH OF CHRIST",
    Address: "THWAZI LOCATION  SIPAQENI A/A  FLAGSTAFF",
    Latitude: "-31.0651",
    Longitude: "29.5639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2835",
    WardLookupId: "3167",
    VDNumber: "11811035",
    RegisteredPopulation: "296",
    VotingStationName: "ELUJECWENI JUNIOR SECONDARY SCHOOL",
    Address: "LUJECWENI LOCATION  SIPAQENI A/A  FLAGSTAFF",
    Latitude: "-31.0582",
    Longitude: "29.5531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2836",
    WardLookupId: "3168",
    VDNumber: "11690074",
    RegisteredPopulation: "362",
    VotingStationName: "PRESBYTERIAN CHURCH",
    Address: "MATHE LOCATION  BHALA A/A  FLAGSTAFF",
    Latitude: "-30.9657",
    Longitude: "29.5275",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2837",
    WardLookupId: "3168",
    VDNumber: "11810180",
    RegisteredPopulation: "849",
    VotingStationName: "MWELO JUNIOR SECONDARY SCHOOL",
    Address: "MAFADOBO LOCATION  BHALA A/A  FLAGSTAFF",
    Latitude: "-30.9575",
    Longitude: "29.5432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2838",
    WardLookupId: "3168",
    VDNumber: "11810247",
    RegisteredPopulation: "676",
    VotingStationName: "XHOPOZO JUNIOR SECONDARY SCHOOL",
    Address: "XHOPOZO A/A  XOPOZO LOCATION  FLAGSTAFF",
    Latitude: "-30.9978",
    Longitude: "29.4737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2839",
    WardLookupId: "3168",
    VDNumber: "11810630",
    RegisteredPopulation: "158",
    VotingStationName: "GQINA METHODIST CHURCH OF SA",
    Address: "GQINA LOCATION  XOPOZO A/A  FLAGSTAFF",
    Latitude: "-30.9685",
    Longitude: "29.4978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2840",
    WardLookupId: "3168",
    VDNumber: "11810720",
    RegisteredPopulation: "552",
    VotingStationName: "KWABHALA TRIBAL AUTHORITY",
    Address: "KWABHALA A/A  KWABHALA LOCATION  FLAGSTAFF",
    Latitude: "-30.9904",
    Longitude: "29.5365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2841",
    WardLookupId: "3168",
    VDNumber: "11810966",
    RegisteredPopulation: "467",
    VotingStationName: "GCUME SENIOR PRIMARY SCHOOL",
    Address: "LWANDLANA LOCALITY  LWANDLANA A/A  FLAGSTAFF",
    Latitude: "-30.9382",
    Longitude: "29.4962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2842",
    WardLookupId: "3168",
    VDNumber: "11811169",
    RegisteredPopulation: "476",
    VotingStationName: "THOBINTETHO SENIOR PRIMARY SCHOOL",
    Address: "RHWANTSANA LOCATION   XOPOZO A/A  FLAGSTAFF",
    Latitude: "-30.9735",
    Longitude: "29.4616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2843",
    WardLookupId: "3168",
    VDNumber: "11820394",
    RegisteredPopulation: "545",
    VotingStationName: "BHUNGENI JUNIOR SECONDARY SCHOOL",
    Address: "BHUNGENI LOCATION  LWANDLANA A/A  TABANKULU",
    Latitude: "-30.9203",
    Longitude: "29.462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2844",
    WardLookupId: "3169",
    VDNumber: "11770758",
    RegisteredPopulation: "843",
    VotingStationName: "HLABATHI JUNIOR SECONDARY SCHOOL",
    Address: "HLABATHI LOC  VLEI A/A  LUSIKISIKI",
    Latitude: "-31.1968",
    Longitude: "29.7575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2845",
    WardLookupId: "3169",
    VDNumber: "11770769",
    RegisteredPopulation: "1152",
    VotingStationName: "MKANKOMO JUNIOR SECONDARY SCHOOL",
    Address: "MTENTU A/A  KHANYAYO LOCATION  LUSIKISIKI",
    Latitude: "-31.1644",
    Longitude: "29.8349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2846",
    WardLookupId: "3169",
    VDNumber: "11770781",
    RegisteredPopulation: "767",
    VotingStationName: "NTABEZWE JUNIOR SECONDARY SCHOOL",
    Address: "NTABEZWE LOCATION  UPPER HLBATHI A/A  LUSIKISIKI",
    Latitude: "-31.1907",
    Longitude: "29.7862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2847",
    WardLookupId: "3169",
    VDNumber: "11771030",
    RegisteredPopulation: "588",
    VotingStationName: "ZWELIBONGILE SENIOR SECONDARY SCHOOL",
    Address: "MHLWAZINI LOCATION  LOWER HLABATHI A/A  FLAGSTAFF",
    Latitude: "-31.2174",
    Longitude: "29.804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2848",
    WardLookupId: "3169",
    VDNumber: "11771771",
    RegisteredPopulation: "318",
    VotingStationName: "MGWILI SENIOR PRIMARY SCHOOL",
    Address: "NGQUZA LOCATION  NGQUZA A/A  FLAGSTAFF",
    Latitude: "-31.1755",
    Longitude: "29.7576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2849",
    WardLookupId: "3169",
    VDNumber: "11772053",
    RegisteredPopulation: "482",
    VotingStationName: "SINGEMBENI SENIOR PRIMARY SCHOOL",
    Address: "DUKADA LOCATION  UPPER HLABATHI A/A  LUSIKISIKI",
    Latitude: "-31.2195",
    Longitude: "29.7813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2850",
    WardLookupId: "3169",
    VDNumber: "11772075",
    RegisteredPopulation: "295",
    VotingStationName: "MANDLAKAMOYA CLINIC",
    Address: "MAPHELENI LOCATION  NGQUZA A/A  LUSIKISIKI",
    Latitude: "-31.1494",
    Longitude: "29.7642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2851",
    WardLookupId: "3170",
    VDNumber: "11600198",
    RegisteredPopulation: "561",
    VotingStationName: "MAQEBEVU JUNIOR SECONDARY SCHOOL",
    Address: "ZINYOSINI LOCATION  MAQEBEVU A/A  NGQELENI",
    Latitude: "-31.696",
    Longitude: "29.2525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2852",
    WardLookupId: "3170",
    VDNumber: "11640013",
    RegisteredPopulation: "382",
    VotingStationName: "DIKO JUNIOR SECONDARY SCHOOL",
    Address: "BULAWU LOCALITY  MAGINGQI A/A  PORT ST JOHNS",
    Latitude: "-31.7774",
    Longitude: "29.314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2853",
    WardLookupId: "3170",
    VDNumber: "11640024",
    RegisteredPopulation: "605",
    VotingStationName: "GOBINAMBA TECHNICAL SCHOOL",
    Address: "GOBINAMBA LOCATION  QANDU A/A  PORT ST JOHNS",
    Latitude: "-31.7664",
    Longitude: "29.2727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2854",
    WardLookupId: "3170",
    VDNumber: "11640035",
    RegisteredPopulation: "325",
    VotingStationName: "MAGGIE SENIOR PRIMARY SCHOOL",
    Address: "MPOTSHOTSHO LOCALITY  GOMOLO A/A  PORT ST JOHNS",
    Latitude: "-31.7532",
    Longitude: "29.329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2855",
    WardLookupId: "3170",
    VDNumber: "11640204",
    RegisteredPopulation: "1150",
    VotingStationName: "MATANDELA JUNIOR SECONDARY SCHOOL",
    Address: "QANDU LOCATION  QANDU A/A  PORT ST JOHNS",
    Latitude: "-31.7334",
    Longitude: "29.2715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2856",
    WardLookupId: "3170",
    VDNumber: "11640361",
    RegisteredPopulation: "411",
    VotingStationName: "NKANGALA SENIOR SECONDARY SCHOOL",
    Address: "NKANGALA LOCALITY  MAGINGQI A/A  PORT ST JOHNS",
    Latitude: "-31.7785",
    Longitude: "29.3408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2857",
    WardLookupId: "3170",
    VDNumber: "11640372",
    RegisteredPopulation: "387",
    VotingStationName: "LUTATWENI JUNIOR SECONDARY SCHOOL",
    Address: "LUTATWENI LOCALITY  MAGINGQI A/A  PORT ST JOHNS",
    Latitude: "-31.7976",
    Longitude: "29.3307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2858",
    WardLookupId: "3170",
    VDNumber: "11640394",
    RegisteredPopulation: "369",
    VotingStationName: "KHWEZI SENIOR PRIMARY SCHOOL",
    Address: "KWEZI LOCALITY  QANDU A/A  PORT ST JOHNS",
    Latitude: "-31.7516",
    Longitude: "29.3017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2859",
    WardLookupId: "3170",
    VDNumber: "11640642",
    RegisteredPopulation: "132",
    VotingStationName: "TSWELENI COMMUNITY HALL",
    Address: "TSWELENI LOCATION  GOMOLO A/A  PORT ST JOHNS",
    Latitude: "-31.7556",
    Longitude: "29.3606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2860",
    WardLookupId: "3171",
    VDNumber: "11640046",
    RegisteredPopulation: "873",
    VotingStationName: "NDEVU JUNIOR SECONDARY SCHOOL",
    Address: "GOMOLO LOCALITY  GOMOLO A/A  PORT ST JOHNS",
    Latitude: "-31.7127",
    Longitude: "29.3712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2861",
    WardLookupId: "3171",
    VDNumber: "11640068",
    RegisteredPopulation: "309",
    VotingStationName: "LUGASWENI SENIOR PRIMARY SCHOOL",
    Address: "LUGASWENI LOCALITY  GOMOLO A/A  PORT ST JOHNS",
    Latitude: "-31.6898",
    Longitude: "29.3544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2862",
    WardLookupId: "3171",
    VDNumber: "11640080",
    RegisteredPopulation: "795",
    VotingStationName: "TEKWINI JUNIOR SECONDARY SCHOOL",
    Address: "MTALALA LOCALITY  CAGUBA A/A  PORT ST JOHNS",
    Latitude: "-31.6661",
    Longitude: "29.3896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2863",
    WardLookupId: "3171",
    VDNumber: "11640091",
    RegisteredPopulation: "589",
    VotingStationName: "SITHA SENIOR PRIMARY SCHOOL",
    Address: "MASAMENI LOCALITY  GOMOLO A/A  PORT ST JOHNS",
    Latitude: "-31.7058",
    Longitude: "29.3343",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2864",
    WardLookupId: "3171",
    VDNumber: "11640248",
    RegisteredPopulation: "396",
    VotingStationName: "MVELELO JUNIOR SECONDARY SCHOOL",
    Address: "ISILIMELA LOCALITY  GOMOLO A/A  PORT ST JOHNS",
    Latitude: "-31.7327",
    Longitude: "29.3443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2865",
    WardLookupId: "3171",
    VDNumber: "11640383",
    RegisteredPopulation: "250",
    VotingStationName: "LINDUBUHLE SENIOR PRIMARY SCHOOL",
    Address: "LINDUBUHLE LOCATION  QANDU A/A  PORT ST JOHNS",
    Latitude: "-31.7239",
    Longitude: "29.3091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2866",
    WardLookupId: "3171",
    VDNumber: "11640406",
    RegisteredPopulation: "638",
    VotingStationName: "NOKULUNGA SENIOR SECONDARY SCHOOL",
    Address: "MAGCAKINI LOCALITY  GOMOLO A/A  PORT ST JOHNS",
    Latitude: "-31.7125",
    Longitude: "29.3851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2867",
    WardLookupId: "3171",
    VDNumber: "11640417",
    RegisteredPopulation: "350",
    VotingStationName: "ZWELENQABA JUNIOR SECONDARAY SCHOOL",
    Address: "MQALENI LOCALITY  GOMOLO A/A  PORT ST JOHNS",
    Latitude: "-31.6999",
    Longitude: "29.4099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2868",
    WardLookupId: "3171",
    VDNumber: "11640608",
    RegisteredPopulation: "201",
    VotingStationName: "NKWILINI SENIOR PRIMARY SCHOOL",
    Address: "NKWILINI LOCATION  GOMOLO A/A  PORT ST JOHNS",
    Latitude: "-31.6822",
    Longitude: "29.3835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2869",
    WardLookupId: "3171",
    VDNumber: "11640653",
    RegisteredPopulation: "282",
    VotingStationName: "NDLUZULA JUNIOR PRIMARY SCHOOL",
    Address: "NJELA   NDLUZULA   PORT ST JOHNS",
    Latitude: "-31.7325",
    Longitude: "29.3779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2870",
    WardLookupId: "3172",
    VDNumber: "11640057",
    RegisteredPopulation: "420",
    VotingStationName: "NDLUMBINI JUNIOR SECONDARY SCHOOL",
    Address: "KHWANYANA LOCALITY  LUJECWENI A/A  PORT ST JOHNS",
    Latitude: "-31.6763",
    Longitude: "29.2802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2871",
    WardLookupId: "3172",
    VDNumber: "11640114",
    RegisteredPopulation: "772",
    VotingStationName: "MQHAKAMA JUNIOR SECONDORY SCHOOL",
    Address: "NDIMAKUDE LOCALITY  NDIMAKUDE A/A  PORT ST JOHNS",
    Latitude: "-31.6369",
    Longitude: "29.3123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2872",
    WardLookupId: "3172",
    VDNumber: "11640226",
    RegisteredPopulation: "487",
    VotingStationName: "BUZONGOMA JUNIOR SECONDARY SCHOOL",
    Address: "BUZONGOMA LOCALITY  NTSIMBINI A/A  PORT ST JOHNS",
    Latitude: "-31.6307",
    Longitude: "29.3568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2873",
    WardLookupId: "3172",
    VDNumber: "11640259",
    RegisteredPopulation: "251",
    VotingStationName: "DALUBANGO SENIOR PRIMARY SCHOOL",
    Address: "MANCU LOCATION  LUJECWENI A/A  PORT ST JOHNS",
    Latitude: "-31.6816",
    Longitude: "29.2798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2874",
    WardLookupId: "3172",
    VDNumber: "11640350",
    RegisteredPopulation: "181",
    VotingStationName: "QAMBA SENIOR PRIMARY SCHOOL",
    Address: "MAHLATHINI LOCALITY  MAWOTSHENI  A/A  PORT ST JOHNS",
    Latitude: "-31.6692",
    Longitude: "29.3648",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2875",
    WardLookupId: "3172",
    VDNumber: "11640428",
    RegisteredPopulation: "350",
    VotingStationName: "MAPIKI JUNIOR SECONDARY SCHOOL",
    Address: "MFADALALENI LOCALITY  LUJECWENI A/A  PORT ST JOHNS",
    Latitude: "-31.6662",
    Longitude: "29.298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2876",
    WardLookupId: "3172",
    VDNumber: "11640439",
    RegisteredPopulation: "416",
    VotingStationName: "ZAMUKHANYO JUNIOR SECONDARY SCHOOL",
    Address: "LUDALASI LOCALITY  NDIMAKUDE A/A  PORT ST JOHNS",
    Latitude: "-31.6495",
    Longitude: "29.2917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2877",
    WardLookupId: "3172",
    VDNumber: "11640440",
    RegisteredPopulation: "724",
    VotingStationName: "BUTO JUNIOR SECONDARY SCHOOL",
    Address: "BUTO LOCALITY  MAWOTSHENI A/A  PORT ST JOHNS",
    Latitude: "-31.6582",
    Longitude: "29.347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2878",
    WardLookupId: "3172",
    VDNumber: "11640619",
    RegisteredPopulation: "420",
    VotingStationName: "MBENENGENI JUNIOR SECONDARY SCHOOL",
    Address: "MBENENGENI LOCATION  NDIMAKUDE A/A  PORT ST JOHNS",
    Latitude: "-31.647",
    Longitude: "29.3191",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2879",
    WardLookupId: "3173",
    VDNumber: "11640136",
    RegisteredPopulation: "540",
    VotingStationName: "HLAMVANA JUNIOR SECONDARY SCHOOL",
    Address: "HLAMVANA LOCALITY  CAGUBA A/A  PORT ST JOHNS",
    Latitude: "-31.6571",
    Longitude: "29.4206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2880",
    WardLookupId: "3173",
    VDNumber: "11640293",
    RegisteredPopulation: "750",
    VotingStationName: "MTWENI SENIOR SECONDARY SCHOOL",
    Address: "TOMBO LOCATION  TOMBO A/A  PORT ST JOHNS",
    Latitude: "-31.6272",
    Longitude: "29.3908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2881",
    WardLookupId: "3173",
    VDNumber: "11640451",
    RegisteredPopulation: "972",
    VotingStationName: "JOKWANA JUNIOR SECONDARY SCHOOL",
    Address: "MNGAZANA LOCALITY  TOMBO A/A  PORT ST JOHNS",
    Latitude: "-31.6456",
    Longitude: "29.3792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2882",
    WardLookupId: "3173",
    VDNumber: "11640596",
    RegisteredPopulation: "528",
    VotingStationName: "TOMBO MULTIPURPOSE CENTRE COMMUNITY HALL",
    Address: "TOMBO LOCATION  TOMBO A/A  PORT ST JOHNS",
    Latitude: "-31.627",
    Longitude: "29.3836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2883",
    WardLookupId: "3173",
    VDNumber: "11640620",
    RegisteredPopulation: "667",
    VotingStationName: "VULINDLELA JUNIOR SECONDARY SCHOOL",
    Address: "RHELA LOCATION   TOMBO A/A   PORT ST JOHNS",
    Latitude: "-31.6224",
    Longitude: "29.3712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2884",
    WardLookupId: "3174",
    VDNumber: "11640147",
    RegisteredPopulation: "540",
    VotingStationName: "SICAMBENI SECONDARY SCHOOL",
    Address: "SICAMBENI LOCATION  CAGUBA A/A  PORT ST JOHNS",
    Latitude: "-31.6605",
    Longitude: "29.4779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2885",
    WardLookupId: "3174",
    VDNumber: "11640170",
    RegisteredPopulation: "847",
    VotingStationName: "CWEBENI JUNIOR SECONDARY SCHOOL",
    Address: "CWEBENI LOCALITY  CAGUBA A/A  PORT S JOHNS",
    Latitude: "-31.666",
    Longitude: "29.432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2886",
    WardLookupId: "3174",
    VDNumber: "11640181",
    RegisteredPopulation: "327",
    VotingStationName: "VUKANDLULE JUNIOR SECONDARY SCHOOL",
    Address: "VUKANDLULE LOCALITY  CAGUBA A/A  PORT ST JOHNS",
    Latitude: "-31.6633",
    Longitude: "29.4616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2887",
    WardLookupId: "3174",
    VDNumber: "11640215",
    RegisteredPopulation: "1360",
    VotingStationName: "CAGUBA JUNIOR SECONDARY SCHOOL",
    Address: "LUDUME LOCALITY  CAGUBA A/A  PORT ST JOHNS 5120",
    Latitude: "-31.6269",
    Longitude: "29.4623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2888",
    WardLookupId: "3174",
    VDNumber: "11640484",
    RegisteredPopulation: "134",
    VotingStationName: "DUMASI SENIOR PRIMARY SCHOOL",
    Address: "DUMASI LOCALITY  CAGUBA A/A  PORT ST JOHNS",
    Latitude: "-31.6219",
    Longitude: "29.4192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2889",
    WardLookupId: "3174",
    VDNumber: "11640631",
    RegisteredPopulation: "244",
    VotingStationName: "MAHENG CRECHE",
    Address: "CAGUBA LOCATION   CAGUBA A/A  PORT ST JOHNS",
    Latitude: "-31.6248",
    Longitude: "29.4708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2890",
    WardLookupId: "3175",
    VDNumber: "11530011",
    RegisteredPopulation: "2690",
    VotingStationName: "PORT ST JOHNS TOWN HALL",
    Address: "257 MAIN STREET  PORT ST JOHNS  ",
    Latitude: "-31.6225",
    Longitude: "29.5456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2891",
    WardLookupId: "3175",
    VDNumber: "11640552",
    RegisteredPopulation: "967",
    VotingStationName: "MTHUMBANE COMMUNITY HALL",
    Address: "MTHUMBANE LOCATION  PORT ST JOHNS  PORT ST JOHNS",
    Latitude: "-31.6443",
    Longitude: "29.5331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2892",
    WardLookupId: "3175",
    VDNumber: "11771456",
    RegisteredPopulation: "541",
    VotingStationName: "DELARAY MKATSHWA JUNIOR SECONDARY SCHOOL",
    Address: "TIGER FLATS FARMS  MPANTU LOCATION  PORT ST.JOHNS",
    Latitude: "-31.5952",
    Longitude: "29.5216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2893",
    WardLookupId: "3176",
    VDNumber: "11640079",
    RegisteredPopulation: "1381",
    VotingStationName: "NTSIMBINI JUNIOR SECONDARY SCHOOL",
    Address: "NTSIMBINI LOCALITY  NTSIMBINI A/ A  PORT ST JOHNS",
    Latitude: "-31.6017",
    Longitude: "29.3361",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2894",
    WardLookupId: "3176",
    VDNumber: "11640103",
    RegisteredPopulation: "1222",
    VotingStationName: "NOMANDI JUNIOR SECONDARY SCHOOL",
    Address: "MANTUSINI  LOCALITY  MANTUSINI A/A  PORT ST JOHNS",
    Latitude: "-31.576",
    Longitude: "29.3025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2895",
    WardLookupId: "3176",
    VDNumber: "11640125",
    RegisteredPopulation: "827",
    VotingStationName: "MTETELELI JUNIOR SECONDARY SCHOOL",
    Address: "DANGWANA LOCALITY  DANGWANA  A/A  PORT ST JOHNS",
    Latitude: "-31.5931",
    Longitude: "29.2703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2896",
    WardLookupId: "3176",
    VDNumber: "11640462",
    RegisteredPopulation: "733",
    VotingStationName: "BUTULO JUNIOR SECONDARY SCHOOL",
    Address: "BUTULO LOCALITY  DANGWANA A/A  PORT ST JOHNS",
    Latitude: "-31.604",
    Longitude: "29.299",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2897",
    WardLookupId: "3176",
    VDNumber: "11640473",
    RegisteredPopulation: "373",
    VotingStationName: "MVUME SPRINGS SENIOR SECONDARY SCHOOL",
    Address: "MVUME SPRINGS LOCALITY  MANTUSINI A/A  PORT ST JOHNS",
    Latitude: "-31.5567",
    Longitude: "29.3035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2898",
    WardLookupId: "3177",
    VDNumber: "11580858",
    RegisteredPopulation: "543",
    VotingStationName: "MEVANA JUNIOR SECONDARY SCHOOL",
    Address: "RAWUTINI LOCATION  MEVANA A/A  LIBODE",
    Latitude: "-31.5272",
    Longitude: "29.2954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2899",
    WardLookupId: "3177",
    VDNumber: "11640169",
    RegisteredPopulation: "709",
    VotingStationName: "LUTAWENI JUNIOR SECONDARY SCHOOL",
    Address: "MAJOLA LOCALITY  MACIBI A/A  PORT ST JOHNS",
    Latitude: "-31.5326",
    Longitude: "29.337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2900",
    WardLookupId: "3177",
    VDNumber: "11640192",
    RegisteredPopulation: "444",
    VotingStationName: "BUJE JUNIOR SECONDARY SCHOOL",
    Address: "BUJE LOCATION  MAJOLA A/ A  PORT ST JOHNS",
    Latitude: "-31.4597",
    Longitude: "29.3383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2901",
    WardLookupId: "3177",
    VDNumber: "11640338",
    RegisteredPopulation: "518",
    VotingStationName: "GCINIBANDLA SENIOR PRIMARY SCHOOL",
    Address: "LUTATWENI LOCALITY  MACHIBI A/A  PORT ST JOHNS 5120",
    Latitude: "-31.5174",
    Longitude: "29.3184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2902",
    WardLookupId: "3177",
    VDNumber: "11640349",
    RegisteredPopulation: "992",
    VotingStationName: "MAJOLA JUNIOR SECONDARY SCHOOL",
    Address: "MAJOLA LOCATION  MACHIBI A/A  PORT ST JOHNS",
    Latitude: "-31.4902",
    Longitude: "29.3301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2903",
    WardLookupId: "3177",
    VDNumber: "11640530",
    RegisteredPopulation: "262",
    VotingStationName: "NGCOSE HOUSE",
    Address: "NGCOSE LOCATION  MACHIBI   PORT ST JOHNS",
    Latitude: "-31.5029",
    Longitude: "29.3378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2904",
    WardLookupId: "3177",
    VDNumber: "11640585",
    RegisteredPopulation: "262",
    VotingStationName: "GOBIZIZWE SENIOR PRIMARY SCHOOL",
    Address: "SIPHUSIPHU LOCATION  MAJOLA A/A  PORT ST JOHNS",
    Latitude: "-31.463",
    Longitude: "29.3134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2905",
    WardLookupId: "3178",
    VDNumber: "11640237",
    RegisteredPopulation: "853",
    VotingStationName: "MKANZINI JUNIOR SECONDARY SCHOOL",
    Address: "MKANZINI  LOCALITY  SWAZINI A/A  PORT ST JOHNS",
    Latitude: "-31.6009",
    Longitude: "29.4318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2906",
    WardLookupId: "3178",
    VDNumber: "11640260",
    RegisteredPopulation: "401",
    VotingStationName: "BIZANA JUNIOR SECONDARY SCHOOL",
    Address: "BIZANA LOCALITY  SWAZINI A/A  PORT ST JOHNS",
    Latitude: "-31.5941",
    Longitude: "29.3784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2907",
    WardLookupId: "3178",
    VDNumber: "11640271",
    RegisteredPopulation: "1008",
    VotingStationName: "ZINTONGA JUNIOR SECONDARY SCHOOL",
    Address: "SWAZINI LOCALITY  SWAZINI A/A  PORT ST JOHNS",
    Latitude: "-31.5698",
    Longitude: "29.4101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2908",
    WardLookupId: "3178",
    VDNumber: "11640518",
    RegisteredPopulation: "835",
    VotingStationName: "BEKABANTU JUNIOR SECONDARY SCHOOL",
    Address: "QAKA LOCALITY  SWAZINI A/A  PORT ST JOHNS",
    Latitude: "-31.5925",
    Longitude: "29.4134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2909",
    WardLookupId: "3178",
    VDNumber: "11640529",
    RegisteredPopulation: "425",
    VotingStationName: "MAGOMBENI JUNIOR SECONDARY SCHOOL",
    Address: "MBOLENI LOCALITY  SWAZINI A /A  PORT ST JOHNS",
    Latitude: "-31.5953",
    Longitude: "29.4046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2910",
    WardLookupId: "3179",
    VDNumber: "11770332",
    RegisteredPopulation: "717",
    VotingStationName: "NOQHEKWANE SENIOR PRIMARY SCHOOL",
    Address: "NOQHEKWANA LOCALITY  LOWER NTAFUFU A/A  PORT ST JOHNS",
    Latitude: "-31.5982",
    Longitude: "29.5863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2911",
    WardLookupId: "3179",
    VDNumber: "11770343",
    RegisteredPopulation: "836",
    VotingStationName: "MAGOBA JUNIOR SECONDARY SCHOOL",
    Address: "LOWER NTAFUFU LOCALITY  LOWER NTAFUFU A/A  PORT ST JOHNS",
    Latitude: "-31.5415",
    Longitude: "29.545",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2912",
    WardLookupId: "3179",
    VDNumber: "11771085",
    RegisteredPopulation: "957",
    VotingStationName: "NGCAKA JUNIOR SECONDARY SCHOOL",
    Address: "MTAMBALALA LOC  MTAMBALALA A/A  PORT ST JOHNS",
    Latitude: "-31.5482",
    Longitude: "29.5711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2913",
    WardLookupId: "3179",
    VDNumber: "11771210",
    RegisteredPopulation: "469",
    VotingStationName: "MSWAKAZI JUNIOR SECONDARY SCHOOL",
    Address: "MSWAKAZI LOCALITY  LOWER NTAFUFU A/A  PORT ST JOHNS",
    Latitude: "-31.5532",
    Longitude: "29.5267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2914",
    WardLookupId: "3179",
    VDNumber: "11771445",
    RegisteredPopulation: "36",
    VotingStationName: "AGATE TERRACE HOUSE",
    Address: "AGATE TERRACE  PORT ST JOHNS",
    Latitude: "-31.6148",
    Longitude: "29.5691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2915",
    WardLookupId: "3179",
    VDNumber: "11771940",
    RegisteredPopulation: "451",
    VotingStationName: "DEDENI JUNIOR PRIMARY SCHOOL",
    Address: "DEDENI LOCATION  MTHWENI A/A  MTHWENI A/A",
    Latitude: "-31.5735",
    Longitude: "29.5504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2916",
    WardLookupId: "3180",
    VDNumber: "11770354",
    RegisteredPopulation: "406",
    VotingStationName: "NONJONJO JUNIOR SECONDARY SCHOOL",
    Address: "LAMBASI LOCATION  LAMBASI A/A  PORT ST JOHNS",
    Latitude: "-31.5188",
    Longitude: "29.6558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2917",
    WardLookupId: "3180",
    VDNumber: "11770365",
    RegisteredPopulation: "667",
    VotingStationName: "MATHANE JUNIOR SECONDARY SCHOOL",
    Address: "MATHANE LOCALITY  MTHAMBALALA A/A  PORT ST JOHNS",
    Latitude: "-31.4827",
    Longitude: "29.5868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2918",
    WardLookupId: "3180",
    VDNumber: "11770983",
    RegisteredPopulation: "912",
    VotingStationName: "BUCHELE JUNIOR SECONDARY SCHOOL",
    Address: "BUCHELE LOCALITY  MTAMBALALA  A/A  PORT ST JOHNS",
    Latitude: "-31.4931",
    Longitude: "29.6154",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2919",
    WardLookupId: "3180",
    VDNumber: "11771018",
    RegisteredPopulation: "888",
    VotingStationName: "NKODUSWENI JUNIOR SECONDARY SCHOOL",
    Address: "GEMVALE LOC  LOWER NTAFUFU A/A  PORT ST JOHNS",
    Latitude: "-31.5034",
    Longitude: "29.5397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2920",
    WardLookupId: "3180",
    VDNumber: "11771221",
    RegisteredPopulation: "946",
    VotingStationName: "MTHAMBALALA JUNIOR SECONDARY SCHOOL",
    Address: "MTHAMBALALA LOCALITY  LAMBASI A/A  LUSIKISIKI",
    Latitude: "-31.5425",
    Longitude: "29.5865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2921",
    WardLookupId: "3180",
    VDNumber: "11771423",
    RegisteredPopulation: "322",
    VotingStationName: "XHAKA JUNIOR SECONDARY SCHOOL",
    Address: "XHAKA LOCATION  LOWER NTAFUFU A/A  PORT ST JOHNS",
    Latitude: "-31.4574",
    Longitude: "29.5713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2922",
    WardLookupId: "3181",
    VDNumber: "11770095",
    RegisteredPopulation: "1584",
    VotingStationName: "NTAFUFU SENIOR SECONDARY SCHOOL",
    Address: "NTAFUFU LOCALITY  LOWER NTAFUFU A/A  PORT ST JOHNS",
    Latitude: "-31.5",
    Longitude: "29.5101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2923",
    WardLookupId: "3181",
    VDNumber: "11770286",
    RegisteredPopulation: "629",
    VotingStationName: "ZITHOBILE SENIOR PRIMARY SCHOOL",
    Address: "GQUBENI LOC.  GQUBENI A/A  PORT ST JOHNS",
    Latitude: "-31.5106",
    Longitude: "29.4764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2924",
    WardLookupId: "3181",
    VDNumber: "11770309",
    RegisteredPopulation: "633",
    VotingStationName: "NOBUHLALI SIGCAU JUNIOR SECONDARY SCHOOL",
    Address: "NOZOZO LOCATION  LOWER NTAFUFU  PORT ST JOHNS",
    Latitude: "-31.5476",
    Longitude: "29.4611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2925",
    WardLookupId: "3181",
    VDNumber: "11770310",
    RegisteredPopulation: "1367",
    VotingStationName: "NOMVALO JUNIOR SECONDARY SCHOOL",
    Address: "NOMVALO LOCATION  LOWER NTAFUFU A/A  PORT ST JOHNS",
    Latitude: "-31.5204",
    Longitude: "29.5222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2926",
    WardLookupId: "3182",
    VDNumber: "11770275",
    RegisteredPopulation: "567",
    VotingStationName: "KWA-ZWENI SENIOR PRIMARY SCHOOL",
    Address: "GOQWANA LOC.  GOQWANA A/A  PORT ST JOHNS",
    Latitude: "-31.485",
    Longitude: "29.4621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2927",
    WardLookupId: "3182",
    VDNumber: "11770466",
    RegisteredPopulation: "1315",
    VotingStationName: "NYAZI JUNIOR SECONDARY SCHOOL",
    Address: "NYATHI LOC  KWANYATHI A/A  PORT ST JOHNS",
    Latitude: "-31.445",
    Longitude: "29.4346",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2928",
    WardLookupId: "3182",
    VDNumber: "11771052",
    RegisteredPopulation: "499",
    VotingStationName: "STELLA SIGCAU JUNIOR SECONDARY SCHOOL",
    Address: "GQUBENI LOC  GQUBENI A/A  PORT ST JOHNS",
    Latitude: "-31.5168",
    Longitude: "29.4221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2929",
    WardLookupId: "3182",
    VDNumber: "11771973",
    RegisteredPopulation: "195",
    VotingStationName: "NDWALANE JUNIOR SECONDARY SCHOOL",
    Address: "NDWALANE LOCATION  NYAZI  A/A  PORT ST JOHNS",
    Latitude: "-31.4754",
    Longitude: "29.3889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2930",
    WardLookupId: "3182",
    VDNumber: "11772110",
    RegisteredPopulation: "330",
    VotingStationName: "MBOKAZI TENT",
    Address: "MBOKAZI LOCATION   KWA-NYATHI A/A  PORT ST JOHNS",
    Latitude: "-31.4284",
    Longitude: "29.3709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2931",
    WardLookupId: "3182",
    VDNumber: "11772132",
    RegisteredPopulation: "227",
    VotingStationName: "TYONGWANA SENIOR PRIMARY SCHOOL",
    Address: "TYONGWANA   GOQWANA  PORT ST JOHNS",
    Latitude: "-31.4929",
    Longitude: "29.4299",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2932",
    WardLookupId: "3183",
    VDNumber: "11770387",
    RegisteredPopulation: "1058",
    VotingStationName: "SOBABA JUNIOR SECONDARY SCHOOL",
    Address: "SOBABA LOCALITY  UPPER NTAFUFU A/A  PORT ST JOHNS",
    Latitude: "-31.4604",
    Longitude: "29.5501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2933",
    WardLookupId: "3183",
    VDNumber: "11770400",
    RegisteredPopulation: "1012",
    VotingStationName: "MZINTLAVA JUNIOR SECONDARY SCHOOL",
    Address: "MZINTLAVA LOCALITY  MZINTLAVA A/A  PORT ST JOHNS",
    Latitude: "-31.4049",
    Longitude: "29.529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2934",
    WardLookupId: "3183",
    VDNumber: "11771243",
    RegisteredPopulation: "438",
    VotingStationName: "MAPHINDELA JUNIOR SECONDARY SCHOOL",
    Address: "LOWER NTAFUFU  LOWER NTAFUFU A/A  LUSIKISIKI",
    Latitude: "-31.4659",
    Longitude: "29.53",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2935",
    WardLookupId: "3183",
    VDNumber: "11771962",
    RegisteredPopulation: "741",
    VotingStationName: "AFRICAN GOSPEL CHURCH",
    Address: "MZINTLAVA LOCATION  MZINTLAVA A/A  PORT ST JOHNS",
    Latitude: "-31.3921",
    Longitude: "29.5218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2936",
    WardLookupId: "3184",
    VDNumber: "11770321",
    RegisteredPopulation: "1320",
    VotingStationName: "LUQHOQHWENI JUNIOR SECONDARY SCHOOL",
    Address: "LUQHOQHWENI LOCALITY  LOWER NTAFUFU A/A  PORT ST JOHNS",
    Latitude: "-31.4414",
    Longitude: "29.5337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2937",
    WardLookupId: "3184",
    VDNumber: "11770398",
    RegisteredPopulation: "914",
    VotingStationName: "MDLANKALA JUNIOR SECONDARY SCHOOL",
    Address: "SOBABA LOCALITY  UPPER NTAFUFU A/A  PORT ST JOHNS",
    Latitude: "-31.4659",
    Longitude: "29.5122",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2938",
    WardLookupId: "3184",
    VDNumber: "11770950",
    RegisteredPopulation: "1453",
    VotingStationName: "GOQWANA JUNIOR SECONDARY SCHOOL",
    Address: "GOQWANALOC.  GOQWANA A/A  PORT ST JOHNS",
    Latitude: "-31.4463",
    Longitude: "29.4742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2939",
    WardLookupId: "3185",
    VDNumber: "11640158",
    RegisteredPopulation: "365",
    VotingStationName: "TYITYANE SENIOR PRIMARY SCHOOL",
    Address: "TYITYANE LOCALITY  LUTENGELE A/A  PORT ST JOHNS",
    Latitude: "-31.5369",
    Longitude: "29.4376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2940",
    WardLookupId: "3185",
    VDNumber: "11640282",
    RegisteredPopulation: "622",
    VotingStationName: "LUPAPASI JUNIOR SECONDARY SCHOOL",
    Address: "LUPAPASI LOCALITY  SWAZINI A/A  PORT ST JOHNS",
    Latitude: "-31.5563",
    Longitude: "29.3766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2941",
    WardLookupId: "3185",
    VDNumber: "11640305",
    RegisteredPopulation: "619",
    VotingStationName: "NDABANKULU SENIOR PRIMARY SCHOOL",
    Address: "NDABANKULU LOCALITY  MACHIBI  PORT ST JOHNS",
    Latitude: "-31.5454",
    Longitude: "29.3558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2942",
    WardLookupId: "3185",
    VDNumber: "11640316",
    RegisteredPopulation: "747",
    VotingStationName: "MBABALANE JUNIOR SECONDARY SCHOOL",
    Address: "MBABALANE LOCALITY  MACHIBI A/A  PORT ST JOHNS",
    Latitude: "-31.5721",
    Longitude: "29.3399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2943",
    WardLookupId: "3185",
    VDNumber: "11640327",
    RegisteredPopulation: "578",
    VotingStationName: "NGXONGWENI SENIOR SECONDARY SCHOOL",
    Address: "NGXONGWENI LOCALITY  LUTENGELE A/A  PORT ST JOHNS",
    Latitude: "-31.5417",
    Longitude: "29.3755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2944",
    WardLookupId: "3185",
    VDNumber: "11640495",
    RegisteredPopulation: "243",
    VotingStationName: "SELWANE HOUSE",
    Address: "SELWANE LOCALITY  LUTENGELE A/A  PORT ST JOHNS 5120",
    Latitude: "-31.5573",
    Longitude: "29.3587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2945",
    WardLookupId: "3185",
    VDNumber: "11640507",
    RegisteredPopulation: "367",
    VotingStationName: "LANGALITSHONI JUNIOR SECONDARY SCHOOL",
    Address: "VIKANTONGA LOCALITY  LUTENGELE A/A  PORT ST JOHNS",
    Latitude: "-31.5455",
    Longitude: "29.393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2946",
    WardLookupId: "3185",
    VDNumber: "11640541",
    RegisteredPopulation: "189",
    VotingStationName: "QHOBOSHENDLINI JUNIOR SECONDARY SCHOOL",
    Address: "QOBOSHENDLINI LOC.  LUTENGELE A/A  PORT ST JOHNS",
    Latitude: "-31.5262",
    Longitude: "29.3996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2947",
    WardLookupId: "3186",
    VDNumber: "11770118",
    RegisteredPopulation: "1169",
    VotingStationName: "MBOKAZI JUNIOR SECONDARY SCHOOL",
    Address: "MBOKAZI LOC  KWANYATHI A/A  PORT ST JOHNS",
    Latitude: "-31.4125",
    Longitude: "29.388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2948",
    WardLookupId: "3186",
    VDNumber: "11770174",
    RegisteredPopulation: "928",
    VotingStationName: "MKHUMBENI JUNIOR SECONDARY SCHOOL",
    Address: "NYATHI LOCALITY  KWANYATHI A/A  PORT ST JOHNS",
    Latitude: "-31.3713",
    Longitude: "29.407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2949",
    WardLookupId: "3186",
    VDNumber: "11770297",
    RegisteredPopulation: "478",
    VotingStationName: "MAKUKHANYE SENIOR SECONDARY SCHOOL",
    Address: "NYATHI LOC.  NYATHI A/A  PORT ST JOHNS",
    Latitude: "-31.394",
    Longitude: "29.4012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2950",
    WardLookupId: "3186",
    VDNumber: "11771557",
    RegisteredPopulation: "618",
    VotingStationName: "FIHLANI. SENIOR PRIMARY SCHOOL",
    Address: "SIJUNQWINI LOCATION  NYATHI A/A  NYATHI A/A",
    Latitude: "-31.3612",
    Longitude: "29.3654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2951",
    WardLookupId: "3186",
    VDNumber: "11772121",
    RegisteredPopulation: "343",
    VotingStationName: "BAKALENI JUNIOR SECONDARY SCHOOL",
    Address: "BAKALENI LOCATION   BOMVINI A/A  PORT ST JOHNS",
    Latitude: "-31.34",
    Longitude: "29.3829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2952",
    WardLookupId: "3187",
    VDNumber: "11770062",
    RegisteredPopulation: "1154",
    VotingStationName: "LUNGELO JUNIOR SECONDARY SCHOOL",
    Address: "KWAMLAZA LOCATION  LUTSHAYA  PORT ST JOHNS",
    Latitude: "-31.3458",
    Longitude: "29.3578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2953",
    WardLookupId: "3187",
    VDNumber: "11770073",
    RegisteredPopulation: "893",
    VotingStationName: "ENDULINI JUNIOR SECONDARY SCHOOL",
    Address: "NDULINI LOC  KWANGCOYA A/A.  PORT ST JOHNS",
    Latitude: "-31.3948",
    Longitude: "29.3309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2954",
    WardLookupId: "3187",
    VDNumber: "11770084",
    RegisteredPopulation: "340",
    VotingStationName: "TSHANTSHALA SENIOR PRIMARY SCHOOL",
    Address: "NGCOYA LOC  KWANGCOYA A/A.  PORT ST JOHNS",
    Latitude: "-31.4325",
    Longitude: "29.3334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2955",
    WardLookupId: "3187",
    VDNumber: "11770107",
    RegisteredPopulation: "681",
    VotingStationName: "LUVUYO JUNIOR PRIMARY SCHOOL",
    Address: "NGCOYA LOCALITY  NGCOYA A/A  PORT ST JOHNS",
    Latitude: "-31.3811",
    Longitude: "29.3041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2956",
    WardLookupId: "3187",
    VDNumber: "11771995",
    RegisteredPopulation: "1081",
    VotingStationName: "LUTSHAYA SENIOR SECONDARY SCHOOL",
    Address: "LUTSHAYA LOCATION  LUTSHAYA A/A  PORT ST JOHNS",
    Latitude: "-31.3273",
    Longitude: "29.36",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2957",
    WardLookupId: "3188",
    VDNumber: "11770163",
    RegisteredPopulation: "614",
    VotingStationName: "NTLENGA JUNIOR SECONDARY SCHOOL",
    Address: "NYATHI LOC.  NYATHI A/A  P0RT ST JOHNS",
    Latitude: "-31.4078",
    Longitude: "29.4363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2958",
    WardLookupId: "3188",
    VDNumber: "11770264",
    RegisteredPopulation: "1045",
    VotingStationName: "NYOSANA JUNIOR SECONDARY SCHOOL",
    Address: "JAMBENI LOCALITY  NYOSANA A/A  PORT ST JOHNS",
    Latitude: "-31.3846",
    Longitude: "29.5024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2959",
    WardLookupId: "3188",
    VDNumber: "11770972",
    RegisteredPopulation: "148",
    VotingStationName: "MAMVENYANE JUNIOR SECONDARY  SCHOOL",
    Address: "BOMVINI LOC  BOMVINI A/A  PORT ST JOHNS",
    Latitude: "-31.3709",
    Longitude: "29.4785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2960",
    WardLookupId: "3188",
    VDNumber: "11771063",
    RegisteredPopulation: "1051",
    VotingStationName: "MEYIWA JUNIOR SECONDARY SCHOOL",
    Address: "MEYIWA LOCALITY  UPPER NTAFUFU A/A  PORT ST JOHNS",
    Latitude: "-31.4155",
    Longitude: "29.497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2961",
    WardLookupId: "3188",
    VDNumber: "11771951",
    RegisteredPopulation: "830",
    VotingStationName: "ZIZAMELE JUNIOR SECONDARY SCHOOL",
    Address: "ISIHLITHO LOCATION  NYOSANA A/A  PORT ST JOHNS",
    Latitude: "-31.3998",
    Longitude: "29.4671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2962",
    WardLookupId: "3188",
    VDNumber: "11772143",
    RegisteredPopulation: "423",
    VotingStationName: "NQUBELA JUNIOR SECONDARY SCHOOL",
    Address: "MZINTLAVA B SECTION  MZINTLAVA  PORT ST JOHNS",
    Latitude: "-31.4168",
    Longitude: "29.5267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2963",
    WardLookupId: "3189",
    VDNumber: "11770185",
    RegisteredPopulation: "902",
    VotingStationName: "LUZUPHU JUNIOR SECONDARY SCHOOL",
    Address: "LUZUPHU LOCALITY  BOMVINI A/A  PORT ST JOHNS",
    Latitude: "-31.3552",
    Longitude: "29.4341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2964",
    WardLookupId: "3189",
    VDNumber: "11770196",
    RegisteredPopulation: "806",
    VotingStationName: "DUMEZWENI JUNIOR SECONDARY SCHOOL",
    Address: "DUMEZWENI LOC.  ZALU HEIGHTS A/A  PORT ST JOHNS",
    Latitude: "-31.3372",
    Longitude: "29.4635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2965",
    WardLookupId: "3189",
    VDNumber: "11770848",
    RegisteredPopulation: "1380",
    VotingStationName: "MTHIMDE JUNIOR SECONDARY SCHOOL",
    Address: "MTAMBALALA LOC.  MTAMBALALA A/A  PORT ST JOHNS",
    Latitude: "-31.3175",
    Longitude: "29.4262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2966",
    WardLookupId: "3189",
    VDNumber: "11771265",
    RegisteredPopulation: "854",
    VotingStationName: "GULENI JUNIOR SECONDARY SCHOOL",
    Address: "JAMBENI LOCALITY  JAMBENI A/A  PORT ST JOHNS",
    Latitude: "-31.3622",
    Longitude: "29.5053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2967",
    WardLookupId: "3189",
    VDNumber: "11771984",
    RegisteredPopulation: "129",
    VotingStationName: "SITSHAYELO CRECHE",
    Address: "SITSHAYELO LOCATION  ZALU HEIGHTS A/A  PORT ST JOHNS",
    Latitude: "-31.3375",
    Longitude: "29.4979",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2968",
    WardLookupId: "3189",
    VDNumber: "11772154",
    RegisteredPopulation: "281",
    VotingStationName: "SUNRISE SENIOR PRIMARY SCHOOL",
    Address: "SUNRISE  ZALU HEIGHTS   PORT ST JOHNS",
    Latitude: "-31.3456",
    Longitude: "29.45",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2969",
    WardLookupId: "661",
    VDNumber: "11580184",
    RegisteredPopulation: "1699",
    VotingStationName: "GANGATHA JUNIOR SECONDARY SCHOOL.",
    Address: "GANGATHA LOC.  ZANDUKWANA A/A  LIBODE",
    Latitude: "-31.4097",
    Longitude: "28.8806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2970",
    WardLookupId: "661",
    VDNumber: "11580207",
    RegisteredPopulation: "791",
    VotingStationName: "NTSHONGWENI SENIOR PRIMARY SCHOOL",
    Address: "TEMBENI LOC.  ZANDUKWANA A/A  LIBODE",
    Latitude: "-31.3802",
    Longitude: "28.9173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2971",
    WardLookupId: "661",
    VDNumber: "11580218",
    RegisteredPopulation: "451",
    VotingStationName: "CANDULWANDLE JUNIOR SECONDARY SCHOOL",
    Address: "KALANDODA LOC.  NGCOLORA A/A  LIBODE",
    Latitude: "-31.3268",
    Longitude: "28.888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2972",
    WardLookupId: "661",
    VDNumber: "11580421",
    RegisteredPopulation: "604",
    VotingStationName: "MJOBENI JUNIOR SECONDARY SCHOOL",
    Address: "MJOBENI LOC  WEYI A/A  LIBODE",
    Latitude: "-31.3297",
    Longitude: "28.9801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2973",
    WardLookupId: "661",
    VDNumber: "11580803",
    RegisteredPopulation: "222",
    VotingStationName: "TSHAKA JUNIOR SECONDARY SCHOOL",
    Address: "MATOLWENI LOCATION  NGCOLORA A/A  LIBODE",
    Latitude: "-31.3434",
    Longitude: "28.954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2974",
    WardLookupId: "661",
    VDNumber: "11580814",
    RegisteredPopulation: "311",
    VotingStationName: "MDAVUZA SENIOR PRIMARY SCHOOL",
    Address: "KHALANDODA LOCATION  NGCOLORA A/A  LIBODE",
    Latitude: "-31.3114",
    Longitude: "28.9371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2975",
    WardLookupId: "661",
    VDNumber: "11580892",
    RegisteredPopulation: "486",
    VotingStationName: "NONTANGANA JUNIOR SECONDARY SCHOOL",
    Address: "SIQIKINI LOCATION  WEYI A/A  LIBODE",
    Latitude: "-31.3634",
    Longitude: "28.9504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2976",
    WardLookupId: "661",
    VDNumber: "11581017",
    RegisteredPopulation: "346",
    VotingStationName: "DUMILE JUNIOR SECONDARY SCHOOL",
    Address: "MAHOYANA LOC.  NGCOLORHA A/A  LIBODE",
    Latitude: "-31.3262",
    Longitude: "28.9111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2977",
    WardLookupId: "662",
    VDNumber: "11580319",
    RegisteredPopulation: "858",
    VotingStationName: "ZIBUNGU JUNIOR SECONDARY SCHOOL",
    Address: "ZIBUNGU LOC  ZIBUNGU A/A  LIBODE",
    Latitude: "-31.4744",
    Longitude: "29.2157",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2978",
    WardLookupId: "662",
    VDNumber: "11580320",
    RegisteredPopulation: "266",
    VotingStationName: "NGCONGCO JUNIOR SECONDARY SCHOOL",
    Address: "ZIBUNGU LOC  ZIBUNGU A/A  LIBODE",
    Latitude: "-31.5003",
    Longitude: "29.1985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2979",
    WardLookupId: "662",
    VDNumber: "11580331",
    RegisteredPopulation: "178",
    VotingStationName: "GQWEZA JUNIOR PRIMARY SCHOOL",
    Address: "LUKUNI LOC  LUKUNI A/A  LIBODE",
    Latitude: "-31.3985",
    Longitude: "29.2903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2980",
    WardLookupId: "662",
    VDNumber: "11580409",
    RegisteredPopulation: "592",
    VotingStationName: "ZELE JUNIOR SECONDARY SCHOOL",
    Address: "ZELE LOC  MEVANA A/A  LIBODE",
    Latitude: "-31.4873",
    Longitude: "29.2459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2981",
    WardLookupId: "662",
    VDNumber: "11580410",
    RegisteredPopulation: "422",
    VotingStationName: "GUNGUBELE JUNIOR PRIMARY SCHOOL",
    Address: "ZIBUNGU LOC  ZIBUNGU A/A  LIBODE",
    Latitude: "-31.4869",
    Longitude: "29.2123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2982",
    WardLookupId: "662",
    VDNumber: "11580690",
    RegisteredPopulation: "369",
    VotingStationName: "TUNGWINI JUNIOR SECONDARY SCHOOL",
    Address: "TUNGWINI LOCATION  ZIBUNGU A/A  LIBODE",
    Latitude: "-31.5189",
    Longitude: "29.206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2983",
    WardLookupId: "662",
    VDNumber: "11580702",
    RegisteredPopulation: "401",
    VotingStationName: "MADWALENI JUNIOR SECONDARY SCHOOL",
    Address: "TUNGWINI LOCATION  ZIBUNGU A/A  LIBODE",
    Latitude: "-31.4888",
    Longitude: "29.1844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2984",
    WardLookupId: "662",
    VDNumber: "11580768",
    RegisteredPopulation: "209",
    VotingStationName: "LUDEKE SENIOR PRIMARY SCHOOL",
    Address: "KWELIMDAKA LOCATION  LUKUNI A/A  LIBODE",
    Latitude: "-31.4477",
    Longitude: "29.2847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2985",
    WardLookupId: "662",
    VDNumber: "11580779",
    RegisteredPopulation: "193",
    VotingStationName: "MSELENI SENIOR PRIMARY SCHOOL",
    Address: "MSELENI LOCATION  LUKUNI A/A  LIBODE",
    Latitude: "-31.4246",
    Longitude: "29.2601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2986",
    WardLookupId: "662",
    VDNumber: "11581107",
    RegisteredPopulation: "285",
    VotingStationName: "MAHAHANE SENIOR PRIMARY SCHOOL",
    Address: "ZELE LOC  NYANDENI [LIBODE]",
    Latitude: "-31.4848",
    Longitude: "29.2676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2987",
    WardLookupId: "663",
    VDNumber: "11580173",
    RegisteredPopulation: "741",
    VotingStationName: "LUTHUBENI JUNIOR SECONDARY SCHOOL.",
    Address: "LUTHUBENI LOC.  NYANDENI A/A  LIBODE",
    Latitude: "-31.4103",
    Longitude: "28.9364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2988",
    WardLookupId: "663",
    VDNumber: "11580229",
    RegisteredPopulation: "852",
    VotingStationName: "RUZE JUNIOR SECONDARY SCHOOL",
    Address: "MHLANGANISWENI LOC.  MHLANGANISWENI A/A  LIBODE",
    Latitude: "-31.3841",
    Longitude: "28.9932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2989",
    WardLookupId: "663",
    VDNumber: "11580230",
    RegisteredPopulation: "396",
    VotingStationName: "DOKODELA JUNIOR SECONDARY SCHOOL",
    Address: "DOKODELA LOC.  COZA A/A  LIBODE",
    Latitude: "-31.3737",
    Longitude: "29.0529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2990",
    WardLookupId: "663",
    VDNumber: "11580533",
    RegisteredPopulation: "978",
    VotingStationName: "COZA JUNIOR SECONDARY SCHOOL",
    Address: "MLOMO LOC.  COZA A/A  LIBODE",
    Latitude: "-31.3841",
    Longitude: "28.9618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2991",
    WardLookupId: "663",
    VDNumber: "11580667",
    RegisteredPopulation: "559",
    VotingStationName: "MBANANGA JUNIOR SECONDARY SCHOOL",
    Address: "PHEZU KWAMAWA LOC  COZA A/A  LIBODE",
    Latitude: "-31.3678",
    Longitude: "28.989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2992",
    WardLookupId: "663",
    VDNumber: "11580825",
    RegisteredPopulation: "597",
    VotingStationName: "NGAVU-NGAVU JUNIOR SECONDARY SCHOOL",
    Address: "NGAVU-NGAVU LOCATION  MAHLANGANISWE A/A  LIBODE",
    Latitude: "-31.3513",
    Longitude: "29.0239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2993",
    WardLookupId: "663",
    VDNumber: "11581129",
    RegisteredPopulation: "107",
    VotingStationName: "RHUZE SOCIETY CHURCH",
    Address: "GONGO LOC  GONGO A/A  NYANDENI [LIBODE]",
    Latitude: "-31.377",
    Longitude: "29.0746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2994",
    WardLookupId: "664",
    VDNumber: "11580195",
    RegisteredPopulation: "698",
    VotingStationName: "ZINKUMBINI JUNIOR SECONDARY SCHOOL",
    Address: "MAKHOTYANA LOC  NYANDENI [LIBODE]",
    Latitude: "-31.4414",
    Longitude: "29.0129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2995",
    WardLookupId: "664",
    VDNumber: "11580252",
    RegisteredPopulation: "810",
    VotingStationName: "NKQWILISO JUNIOR SECONDARY SCHOOL",
    Address: "GEBANE LOC.  ZINKUMBINI A/A  LIBODE",
    Latitude: "-31.427",
    Longitude: "29.0436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2996",
    WardLookupId: "664",
    VDNumber: "11580263",
    RegisteredPopulation: "1119",
    VotingStationName: "VICTOR POTO SENIOR SECONDARY SCHOOL",
    Address: "MARUBENI LOC.  MARUBENI A/A  LIBODE",
    Latitude: "-31.4465",
    Longitude: "29.0594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2997",
    WardLookupId: "664",
    VDNumber: "11580566",
    RegisteredPopulation: "644",
    VotingStationName: "MBOBELENI JUNIOR SECONDARY SCHOOL",
    Address: "BUNGU LOC.  MBOBELENI A/A  LIBODE",
    Latitude: "-31.4493",
    Longitude: "29.1007",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2998",
    WardLookupId: "664",
    VDNumber: "11580870",
    RegisteredPopulation: "513",
    VotingStationName: "MDINA SENIOR PRIMARY SCHOOL",
    Address: "MDINA LOCATION  MARUBENI A/A  LIBODE",
    Latitude: "-31.4586",
    Longitude: "29.0943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "2999",
    WardLookupId: "664",
    VDNumber: "11581095",
    RegisteredPopulation: "584",
    VotingStationName: "MAKAZIWE JUNIOR SECONDARY SCHOOL",
    Address: "MAKHOTYANA  NYANDENI [LIBODE]",
    Latitude: "-31.4393",
    Longitude: "29.0184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3000",
    WardLookupId: "665",
    VDNumber: "11580241",
    RegisteredPopulation: "834",
    VotingStationName: "DLUKULWANA JUNIOR SECONDARY SCHOOL",
    Address: "GEBANE LOCATION  MARUBENI A/A  LIBODE",
    Latitude: "-31.4142",
    Longitude: "29.0791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3001",
    WardLookupId: "665",
    VDNumber: "11580296",
    RegisteredPopulation: "451",
    VotingStationName: "JONGISIZWE JUNIOR SECONDARY SCHOOL",
    Address: "TAFENI LOC.  MTOMBE A/A  LIBODE",
    Latitude: "-31.3627",
    Longitude: "29.1047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3002",
    WardLookupId: "665",
    VDNumber: "11580308",
    RegisteredPopulation: "712",
    VotingStationName: "QANQISO SENIOR SECONDARY SCHOOL",
    Address: "NOLOKOZA LOC.  MTHOMBE A/A  LIBODE",
    Latitude: "-31.385",
    Longitude: "29.1173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3003",
    WardLookupId: "665",
    VDNumber: "11580364",
    RegisteredPopulation: "362",
    VotingStationName: "NXUKWEBE SENIOR PRIMARY SCHOOL",
    Address: "MTHOMBE TSITSA LOC  MTHOMBE A/A  LIBODE",
    Latitude: "-31.4039",
    Longitude: "29.14",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3004",
    WardLookupId: "665",
    VDNumber: "11580498",
    RegisteredPopulation: "262",
    VotingStationName: "NDZULUKA SENIOR PRIMARY SCHOOL",
    Address: "MANGCWANGULENI LOC  MGAQWENI A/A  LIBODE",
    Latitude: "-31.3697",
    Longitude: "29.1793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3005",
    WardLookupId: "665",
    VDNumber: "11580588",
    RegisteredPopulation: "699",
    VotingStationName: "MJALISWA JUNIOR SECONDARY SCHOOL",
    Address: "MANGCWANGULENI LOC  MGAQWENI A/A  LIBODE",
    Latitude: "-31.3351",
    Longitude: "29.1656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3006",
    WardLookupId: "665",
    VDNumber: "11580599",
    RegisteredPopulation: "283",
    VotingStationName: "SOMPA SENIOR PRIMARY SCHOOL",
    Address: "MGAQWENI A/A  LIBODE",
    Latitude: "-31.3578",
    Longitude: "29.1531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3007",
    WardLookupId: "665",
    VDNumber: "11580836",
    RegisteredPopulation: "297",
    VotingStationName: "DALIBUNGA JUNIOR SECONDARY SCHOOL",
    Address: "NOLOKOZA LOCATION  MTOMBE A/A  LIBODE",
    Latitude: "-31.3556",
    Longitude: "29.1202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3008",
    WardLookupId: "665",
    VDNumber: "11580847",
    RegisteredPopulation: "413",
    VotingStationName: "SAMSON SENIOR PRIMARY SCHOOL",
    Address: "MTOMBE-TSITSA LOCATION  MTOMBE A/A  LIBODE",
    Latitude: "-31.3551",
    Longitude: "29.0948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3009",
    WardLookupId: "665",
    VDNumber: "11580881",
    RegisteredPopulation: "226",
    VotingStationName: "BOMVINI JUNIOR PRIMARY SCHOOL",
    Address: "MANGCWANGULENI LOCATION  MGAQWENI A/A  LIBODE",
    Latitude: "-31.3087",
    Longitude: "29.2005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3010",
    WardLookupId: "666",
    VDNumber: "11580061",
    RegisteredPopulation: "678",
    VotingStationName: "MNGAZI JUNIOR SECONDARY SCHOOL",
    Address: "MASAMENI LOC  MNGAZI A/A  LIBODE",
    Latitude: "-31.5538",
    Longitude: "29.2334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3011",
    WardLookupId: "666",
    VDNumber: "11580375",
    RegisteredPopulation: "571",
    VotingStationName: "MLAMLI JUNIOR PRIMARY SCHOOL",
    Address: "MASAMENI LOC  MGWENYANE A/A  LIBODE",
    Latitude: "-31.5414",
    Longitude: "29.2239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3012",
    WardLookupId: "666",
    VDNumber: "11580386",
    RegisteredPopulation: "485",
    VotingStationName: "NTLAMBELA JUNIOR SECONDARY SCHOOL",
    Address: "NTLAMBELA LOC  MGWENYANA A/A  LIBODE",
    Latitude: "-31.5746",
    Longitude: "29.2522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3013",
    WardLookupId: "666",
    VDNumber: "11580397",
    RegisteredPopulation: "639",
    VotingStationName: "CIBENI JUNIOR SECONDARY SCHOOL",
    Address: "CIBENI LOC  MBALISWENI A/A  LIBODE",
    Latitude: "-31.5274",
    Longitude: "29.2803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3014",
    WardLookupId: "666",
    VDNumber: "11600233",
    RegisteredPopulation: "416",
    VotingStationName: "NGQONGWENI JUNIOR SECONDARY SCHOOL",
    Address: "NGQONGWENI LOC.  NGQONGWENI A/A  NGQELENI",
    Latitude: "-31.6209",
    Longitude: "29.2166",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3015",
    WardLookupId: "666",
    VDNumber: "11600424",
    RegisteredPopulation: "354",
    VotingStationName: "MGUNGUNDLOVU JUNIOR SECONDARY SCHOOL",
    Address: "QITHI LOC.  QITHI A/A  NGQELENI",
    Latitude: "-31.5741",
    Longitude: "29.1949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3016",
    WardLookupId: "666",
    VDNumber: "11600705",
    RegisteredPopulation: "825",
    VotingStationName: "NTSONYINI JUNIOR SECONDARY SCHOOL",
    Address: "NTSONYINI LOC.  NTSONYINI A/A  NGQELENI",
    Latitude: "-31.5973",
    Longitude: "29.2565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3017",
    WardLookupId: "666",
    VDNumber: "11600840",
    RegisteredPopulation: "232",
    VotingStationName: "MCOTHAMA SCHOOL",
    Address: "MGUNGUNDLOVU LOC.  MCOTHAMA A/A  NGQELENI",
    Latitude: "-31.5674",
    Longitude: "29.2337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3018",
    WardLookupId: "666",
    VDNumber: "11601155",
    RegisteredPopulation: "241",
    VotingStationName: "GINYINDABA SENIOR PRIMARY SCHOOL",
    Address: "MPUNTSHANE LOCATION  MGWENYANA A/A  LIBODE",
    Latitude: "-31.5642",
    Longitude: "29.2194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3019",
    WardLookupId: "666",
    VDNumber: "11601245",
    RegisteredPopulation: "396",
    VotingStationName: "JONGUXOLO JUNIOR SECONDARY SCHOOL",
    Address: "NODUSHE  EKULAMBENI",
    Latitude: "-31.6167",
    Longitude: "29.2456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3020",
    WardLookupId: "667",
    VDNumber: "11500018",
    RegisteredPopulation: "1338",
    VotingStationName: "LIBODE MUNICIPAL HALL",
    Address: "STANFORD ROAD  LIBODE VILLAGE  LIBODE",
    Latitude: "-31.5407",
    Longitude: "29.0161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3021",
    WardLookupId: "667",
    VDNumber: "11500029",
    RegisteredPopulation: "1307",
    VotingStationName: "THABO MBEKI PRE-SCHOOL",
    Address: "707 THABO MBEKI STREET  THABO MBEKI TOWNSHIP  LIBODE",
    Latitude: "-31.5368",
    Longitude: "29.0329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3022",
    WardLookupId: "667",
    VDNumber: "11600479",
    RegisteredPopulation: "629",
    VotingStationName: "MAXHAKA JUNIOR SECONDARY SCHOOL",
    Address: "QHANKQU LOCATION  GIBISELA A/A  NGQELENI",
    Latitude: "-31.5552",
    Longitude: "29.0125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3023",
    WardLookupId: "667",
    VDNumber: "11600480",
    RegisteredPopulation: "738",
    VotingStationName: "DINGILIZWE SENIOR PRIMARY SCHOOL",
    Address: "MPINDWENI LOC.  MDLANKOMO A/A  NGQELENI",
    Latitude: "-31.5452",
    Longitude: "28.9774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3024",
    WardLookupId: "667",
    VDNumber: "11601234",
    RegisteredPopulation: "434",
    VotingStationName: "BAJONGE JUNIOR SECONDARY SCHOOL",
    Address: "LIBODE  MARHEWINI A/A  MARHEWINI LOCATION",
    Latitude: "-31.553",
    Longitude: "29.0314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3025",
    WardLookupId: "668",
    VDNumber: "11580139",
    RegisteredPopulation: "796",
    VotingStationName: "ZWELAKHE JUNIOR SECONDARY SCHOOL",
    Address: "ZANDUKWANA LOC.  ZANDUKWANA A/A  LIBODE",
    Latitude: "-31.5373",
    Longitude: "28.9707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3026",
    WardLookupId: "668",
    VDNumber: "11580151",
    RegisteredPopulation: "954",
    VotingStationName: "MAMFENGWINI JUNIOR SECONDARY SCHOOL",
    Address: "MAMFENGWINI LOC.  MDLANKOMO A/A  LIBODE",
    Latitude: "-31.4879",
    Longitude: "28.9698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3027",
    WardLookupId: "668",
    VDNumber: "11580162",
    RegisteredPopulation: "939",
    VotingStationName: "MOYENI JUNIOR SECONDARY SCHOOL",
    Address: "MOYENI LOC.  MARUBENI A/A  LIBODE",
    Latitude: "-31.4936",
    Longitude: "29.0225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3028",
    WardLookupId: "668",
    VDNumber: "11580634",
    RegisteredPopulation: "611",
    VotingStationName: "MAGCAKINI JUNIOR SECONDARY SCHOOL",
    Address: "MDLANKOMO A/A  LIBODE",
    Latitude: "-31.5106",
    Longitude: "28.9437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3029",
    WardLookupId: "668",
    VDNumber: "11580645",
    RegisteredPopulation: "797",
    VotingStationName: "MPANGANA JUNIOR SECONDARY SCHOOL",
    Address: "MARUBENI A/A  LIBODE",
    Latitude: "-31.4918",
    Longitude: "29.0437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3030",
    WardLookupId: "668",
    VDNumber: "11580915",
    RegisteredPopulation: "631",
    VotingStationName: "CHIZELA JUNIOR SECONDARY SCHOOL",
    Address: "TYARA LOCATION  MARUBENI A/A  LIBODE",
    Latitude: "-31.4836",
    Longitude: "28.9936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3031",
    WardLookupId: "669",
    VDNumber: "11580038",
    RegisteredPopulation: "1624",
    VotingStationName: "GXULU JUNIOR SECONDARY SCHOOL",
    Address: "GXULU LOCALITY  ZITATELE A/A  LIBODE",
    Latitude: "-31.5761",
    Longitude: "28.8725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3032",
    WardLookupId: "669",
    VDNumber: "11580106",
    RegisteredPopulation: "969",
    VotingStationName: "NGXANGA JUNIOR SECONDARY SCHOOL",
    Address: "MISTY MOUNT LOC.  CORANA A/A  LIBODE",
    Latitude: "-31.5304",
    Longitude: "28.9251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3033",
    WardLookupId: "669",
    VDNumber: "11580117",
    RegisteredPopulation: "443",
    VotingStationName: "DININKOSI JUNIOR SECONDARY SCHOOL",
    Address: "DININKOSI LOC.  CORANA A/A  LIBODE",
    Latitude: "-31.5039",
    Longitude: "28.9128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3034",
    WardLookupId: "669",
    VDNumber: "11580623",
    RegisteredPopulation: "505",
    VotingStationName: "NGQWANGI JUNIOR SECONDARY SCHOOL",
    Address: "MISTY MOUNT LOC.  CORANA A/A  LIBODE",
    Latitude: "-31.5461",
    Longitude: "28.9224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3035",
    WardLookupId: "669",
    VDNumber: "11580959",
    RegisteredPopulation: "1192",
    VotingStationName: "MZIMKHULU JUNIOR SECONDARY SCHOOL",
    Address: "GXULU LOC.  ZITATELE A/A  LIBODE",
    Latitude: "-31.57",
    Longitude: "28.8954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3036",
    WardLookupId: "669",
    VDNumber: "11580993",
    RegisteredPopulation: "253",
    VotingStationName: "MANGQUKWANA JUNIOR SECONDARY SCHOOL",
    Address: "MHLABENI LOC.  CORANA A/A  LIBODE",
    Latitude: "-31.5309",
    Longitude: "28.9095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3037",
    WardLookupId: "670",
    VDNumber: "11580049",
    RegisteredPopulation: "871",
    VotingStationName: "NTILINI JUNIOR SECONDARY SCHOOL",
    Address: "SBANGWENI LOC.  ZITATELE A/A  LIBODE",
    Latitude: "-31.5324",
    Longitude: "28.8213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3038",
    WardLookupId: "670",
    VDNumber: "11580050",
    RegisteredPopulation: "1306",
    VotingStationName: "NONTSWABU JUNIOR SECONDARY SCHOOL",
    Address: "NONTSWABU LOC.  SIBANGWENI A/A  LIBODE",
    Latitude: "-31.4996",
    Longitude: "28.8606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3039",
    WardLookupId: "670",
    VDNumber: "11580791",
    RegisteredPopulation: "1040",
    VotingStationName: "LUTHOLI JUNIOR SECONDARY SCHOOL",
    Address: "LUTHOLI LOCATION  SIBANGWENI A/A  LIBODE",
    Latitude: "-31.5215",
    Longitude: "28.8284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3040",
    WardLookupId: "670",
    VDNumber: "11580960",
    RegisteredPopulation: "511",
    VotingStationName: "NOKAYA STORE (TENT)",
    Address: "NTILINI LOCATION   NTILINI A/A   LIBODE",
    Latitude: "-31.5247",
    Longitude: "28.8183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3041",
    WardLookupId: "670",
    VDNumber: "11580971",
    RegisteredPopulation: "642",
    VotingStationName: "NKOMAZI STORE.",
    Address: "MAQANYENI  NTILINI LOCATION  LIBODE",
    Latitude: "-31.5178",
    Longitude: "28.8446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3042",
    WardLookupId: "670",
    VDNumber: "11620572",
    RegisteredPopulation: "412",
    VotingStationName: "LURASINI JUNIOR SECONDARY SCHOOL",
    Address: "LURASINI LOC.  SBANGWENI A/A  LIBODE",
    Latitude: "-31.4473",
    Longitude: "28.858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3043",
    WardLookupId: "671",
    VDNumber: "11580016",
    RegisteredPopulation: "1798",
    VotingStationName: "NYANGILIZWE SENIOR SECONDARY SCHOOL",
    Address: "NGOLO LOC.  NYANGILIZWE A/A  LIBODE",
    Latitude: "-31.5521",
    Longitude: "28.83",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3044",
    WardLookupId: "671",
    VDNumber: "11580601",
    RegisteredPopulation: "866",
    VotingStationName: "VULISANGO JUNIOUR SECONDARY SCHOOL",
    Address: "SIBANGWENI A/A  LIBODE",
    Latitude: "-31.5224",
    Longitude: "28.8645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3045",
    WardLookupId: "671",
    VDNumber: "11580982",
    RegisteredPopulation: "727",
    VotingStationName: "NGOLO JUNIOR SECONDARY SCHOOL",
    Address: "NGOLO LOC.  NGOLO A/A  LIBODE",
    Latitude: "-31.5647",
    Longitude: "28.8314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3046",
    WardLookupId: "671",
    VDNumber: "11581084",
    RegisteredPopulation: "1660",
    VotingStationName: "ZIPHUNZANA PRE-SCHOOL",
    Address: "ZIPHUNZANA  NGOLO LOC.  LIBODE",
    Latitude: "-31.5503",
    Longitude: "28.8129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3047",
    WardLookupId: "672",
    VDNumber: "11580027",
    RegisteredPopulation: "1329",
    VotingStationName: "NCIPIZWENI JUNIOR SECONDARY SCHOOL",
    Address: "NCIPIZWENI LOC  ZITATELE A/A  LIBODE",
    Latitude: "-31.5868",
    Longitude: "28.8379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3048",
    WardLookupId: "672",
    VDNumber: "11600648",
    RegisteredPopulation: "1350",
    VotingStationName: "NTAPHANE JUNIOR SECONDARY SCHOOL",
    Address: "CORANA LOC.  LUJECWENI A/A  NGQELENI",
    Latitude: "-31.5943",
    Longitude: "28.8467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3049",
    WardLookupId: "672",
    VDNumber: "11600659",
    RegisteredPopulation: "609",
    VotingStationName: "MAGANISE JUNIOR SECONDARY SCHOOL",
    Address: "NGOBOZI LOC.  LUJECWENI A/A  NGQELENI",
    Latitude: "-31.6017",
    Longitude: "28.8249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3050",
    WardLookupId: "672",
    VDNumber: "11600884",
    RegisteredPopulation: "548",
    VotingStationName: "NTAPHANE PRE-SCHOOL",
    Address: "CORANA  ",
    Latitude: "-31.6003",
    Longitude: "28.8466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3051",
    WardLookupId: "672",
    VDNumber: "11600996",
    RegisteredPopulation: "860",
    VotingStationName: "RIVER SIDE COMPREHENSIVE SCHOOL",
    Address: "CORANA LOC.  CORANA A/A  NGQELENI",
    Latitude: "-31.5885",
    Longitude: "28.8181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3052",
    WardLookupId: "673",
    VDNumber: "11600626",
    RegisteredPopulation: "374",
    VotingStationName: "MANDLOVINI JUNIOR SECONDARY SCHOOL",
    Address: "MANDLOVINI LOC.  MANDLOVINI A/A  NGQELENI",
    Latitude: "-31.6697",
    Longitude: "28.8521",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3053",
    WardLookupId: "673",
    VDNumber: "11600637",
    RegisteredPopulation: "864",
    VotingStationName: "BUNTINGVILLE JUNIOR SECONDARY SCHOOL",
    Address: "BUNTINGVILLE LOC.  BUNTINGVILLE A/A  NGQELENI",
    Latitude: "-31.6367",
    Longitude: "28.877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3054",
    WardLookupId: "673",
    VDNumber: "11600660",
    RegisteredPopulation: "1395",
    VotingStationName: "MHLABUNZIMA JUNIOR SECONDARY SCHOOL",
    Address: "LUJECWENI LOC.  LUJECWENI A/A  NGQELENI",
    Latitude: "-31.6118",
    Longitude: "28.8517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3055",
    WardLookupId: "673",
    VDNumber: "11600952",
    RegisteredPopulation: "289",
    VotingStationName: "TAKATA JUNIOR SECONDARY SCHOOL",
    Address: "NTSAKA LOC.  NTSAKA A/A  NGQELENI",
    Latitude: "-31.6868",
    Longitude: "28.8347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3056",
    WardLookupId: "673",
    VDNumber: "11600963",
    RegisteredPopulation: "460",
    VotingStationName: "NDINDIMENI JUNIOR SECONDARY SCHOOL",
    Address: "NDINDIMENI LOC.  BUNTINGVILLE A/A  LIBODE",
    Latitude: "-31.6428",
    Longitude: "28.8596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3057",
    WardLookupId: "673",
    VDNumber: "11600985",
    RegisteredPopulation: "183",
    VotingStationName: "ZOYISILE JUNIOR SECONDARY SCHOOL",
    Address: "LUJECWENI LOC.  LUJECWENI A/A  NGQELENI",
    Latitude: "-31.6483",
    Longitude: "28.8455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3058",
    WardLookupId: "674",
    VDNumber: "11600547",
    RegisteredPopulation: "799",
    VotingStationName: "DALUKHANYO JUNIOR SECONDARY SCHOOL",
    Address: "MQWANGQWENI NGEMVA  MQWANGQWENI A/A  NGQELENI",
    Latitude: "-31.7224",
    Longitude: "28.9317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3059",
    WardLookupId: "674",
    VDNumber: "11600558",
    RegisteredPopulation: "542",
    VotingStationName: "NQEKETHO JUNIOR SECONDARY SCHOOL",
    Address: "MQWANGWENI LOC.  MQWANGQWENI A/A  NGQELENI",
    Latitude: "-31.7007",
    Longitude: "28.9443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3060",
    WardLookupId: "674",
    VDNumber: "11600570",
    RegisteredPopulation: "755",
    VotingStationName: "MZAMO JUNIOR SECONDARY SCHOOL",
    Address: "MANGWANENI LOC.  MANGWANENI A/A  NGQELENI",
    Latitude: "-31.6793",
    Longitude: "28.9317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3061",
    WardLookupId: "674",
    VDNumber: "11600581",
    RegisteredPopulation: "774",
    VotingStationName: "PONI JUNIOR SECONDARY SCHOOL",
    Address: "NDATYA LOC.  DUMASI A/A  NGQELENI",
    Latitude: "-31.664",
    Longitude: "28.8999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3062",
    WardLookupId: "674",
    VDNumber: "11600918",
    RegisteredPopulation: "749",
    VotingStationName: "MPHATHISWA SENIOR PRIMARY SCHOOL",
    Address: "MAPLOTINI LOC.  MQWANGWENI A/A  NGQELENI",
    Latitude: "-31.717",
    Longitude: "28.9491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3063",
    WardLookupId: "675",
    VDNumber: "11600390",
    RegisteredPopulation: "798",
    VotingStationName: "PONDOMISENI JUNIOR SECONDARY SCHOOL",
    Address: "MAMPONDOMISENI LOC.  MAMPONDOMISENI  NGQELENI",
    Latitude: "-31.6273",
    Longitude: "29.0976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3064",
    WardLookupId: "675",
    VDNumber: "11600446",
    RegisteredPopulation: "1135",
    VotingStationName: "CHIEF HENDRY BOKLEIN SENIOR SECONDARY SCHOOL",
    Address: "NTLAZA LOC.  KULEKA A/A  NGQELENI",
    Latitude: "-31.5643",
    Longitude: "29.1113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3065",
    WardLookupId: "675",
    VDNumber: "11600468",
    RegisteredPopulation: "810",
    VotingStationName: "MTYU JUNIOR SECONDARY SCHOOL",
    Address: "MTYU LOC.  MTYU A/A  NGQELENI",
    Latitude: "-31.5728",
    Longitude: "29.0506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3066",
    WardLookupId: "675",
    VDNumber: "11600671",
    RegisteredPopulation: "933",
    VotingStationName: "ZANOKHANYO JUNIOR SECONDARY SCHOOL",
    Address: "NGXOKWENI LOC.  MAMPONDOMISEIN A/A  NGQELENI",
    Latitude: "-31.5794",
    Longitude: "29.0854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3067",
    WardLookupId: "675",
    VDNumber: "11600839",
    RegisteredPopulation: "496",
    VotingStationName: "DOKODELA JUNIOR SECONDARY SCHOOL",
    Address: "KHULEKA LOC.  NTLAZA A/A  LIBODE",
    Latitude: "-31.5949",
    Longitude: "29.1033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3068",
    WardLookupId: "675",
    VDNumber: "11601144",
    RegisteredPopulation: "519",
    VotingStationName: "GCINIZIZWE SENIOR PRIMARY SCHOOL",
    Address: "NTSHIDI LOCATION  MAMPONDOMISENI A/A  NGQELENI",
    Latitude: "-31.6038",
    Longitude: "29.0825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3069",
    WardLookupId: "676",
    VDNumber: "11580274",
    RegisteredPopulation: "941",
    VotingStationName: "APOSTOLIC FAITH MISSION LIBODE ASSEMBLY",
    Address: "NTENDELE LOC.  MAQHINGENI A/A  LIBODE",
    Latitude: "-31.4963",
    Longitude: "29.1082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3070",
    WardLookupId: "676",
    VDNumber: "11580285",
    RegisteredPopulation: "442",
    VotingStationName: "MSINTSINI JUNIOR SECONDARY SCHOOL",
    Address: "MSINTSINI LOC.  MKANKATO A/A  LIBODE",
    Latitude: "-31.5208",
    Longitude: "29.1478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3071",
    WardLookupId: "676",
    VDNumber: "11580577",
    RegisteredPopulation: "564",
    VotingStationName: "DEEP LEVEL JUNIOR SECONDARY SCHOOL",
    Address: "MAQHINGENI LOC.  MAQHINGENI A/A  LIBODE",
    Latitude: "-31.5015",
    Longitude: "29.1388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3072",
    WardLookupId: "676",
    VDNumber: "11580678",
    RegisteredPopulation: "686",
    VotingStationName: "MKANKATO JUNIOR SECONDARY SCHOOL",
    Address: "TALENI LOCATION  MKAKATO A/A  LIBODE",
    Latitude: "-31.5387",
    Longitude: "29.1495",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3073",
    WardLookupId: "676",
    VDNumber: "11580689",
    RegisteredPopulation: "309",
    VotingStationName: "MFABANE JUNIOR SECONDARY SCHOOL",
    Address: "NTENDELE LOCATION  MAQHINGENI A/A  LIBODE",
    Latitude: "-31.5254",
    Longitude: "29.1023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3074",
    WardLookupId: "676",
    VDNumber: "11600457",
    RegisteredPopulation: "767",
    VotingStationName: "MAFINI JUNIOR SECONDARY SCHOOL",
    Address: "ZINKONZWENI LOC.  MAFINI A/A  NGQELENI",
    Latitude: "-31.5397",
    Longitude: "29.0727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3075",
    WardLookupId: "676",
    VDNumber: "11601256",
    RegisteredPopulation: "485",
    VotingStationName: "MOYAKHE JUNIOR SECONDARY SCHOOL",
    Address: "DIDI LOCATION  DIDI  LIBODE",
    Latitude: "-31.5286",
    Longitude: "29.0878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3076",
    WardLookupId: "677",
    VDNumber: "11600244",
    RegisteredPopulation: "514",
    VotingStationName: "MAGOZENI JUNIOR SECONDARY SCHOOL",
    Address: "MANGWANENI LOC.  ZINDUNENI A/A  NGQELENI",
    Latitude: "-31.651",
    Longitude: "29.1949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3077",
    WardLookupId: "677",
    VDNumber: "11600255",
    RegisteredPopulation: "929",
    VotingStationName: "OLD BUNTING JUNIOR SECONDARY SCHOOL",
    Address: "NQUBA LOC.  KATINI A/A  NGQELENI",
    Latitude: "-31.6757",
    Longitude: "29.1711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3078",
    WardLookupId: "677",
    VDNumber: "11600266",
    RegisteredPopulation: "1042",
    VotingStationName: "MSOLO JUNIOR SECONDARY SCHOOL",
    Address: "MANDILENI LOC.  MANDILENI A/A  NGQELENI",
    Latitude: "-31.6837",
    Longitude: "29.1253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3079",
    WardLookupId: "677",
    VDNumber: "11600378",
    RegisteredPopulation: "354",
    VotingStationName: "NGIDINI JUNIOR SECONDARY SCHOOL",
    Address: "MHLAHLANE LOC.  MAMPONDOMISENI A/A  NGQELENI",
    Latitude: "-31.649",
    Longitude: "29.1052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3080",
    WardLookupId: "677",
    VDNumber: "11600389",
    RegisteredPopulation: "1146",
    VotingStationName: "MDUMAZULU JUNIOR SECONDARY SCHOOL",
    Address: "MAMPONDOMISENI LOC.  PONDOMISENI A/A  NGQELENI",
    Latitude: "-31.628",
    Longitude: "29.1452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3081",
    WardLookupId: "677",
    VDNumber: "11600716",
    RegisteredPopulation: "615",
    VotingStationName: "NOXOVA JUNIOR SECONDARY SCHOOL",
    Address: "NOXOVA LOC.  ZINDUNENI A/A  NGQELENI",
    Latitude: "-31.7116",
    Longitude: "29.1355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3082",
    WardLookupId: "677",
    VDNumber: "11601201",
    RegisteredPopulation: "325",
    VotingStationName: "MPIKELELI JUNIOR SECONDARY SCHOOL",
    Address: "NYANDENI  ",
    Latitude: "-31.65",
    Longitude: "29.2172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3083",
    WardLookupId: "678",
    VDNumber: "11600413",
    RegisteredPopulation: "786",
    VotingStationName: "QITHI JUNIOR SECONDARY SCHOOL",
    Address: "BUKWINI LOCATION  QITHI A/A  NGQELENI",
    Latitude: "-31.5831",
    Longitude: "29.1558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3084",
    WardLookupId: "678",
    VDNumber: "11600435",
    RegisteredPopulation: "866",
    VotingStationName: "MAGADLELA JUNIOR SECONDARY SCHOOL",
    Address: "KWAMBIZA LOC.  TALENI A/A  NGQELENI",
    Latitude: "-31.547",
    Longitude: "29.1425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3085",
    WardLookupId: "678",
    VDNumber: "11601010",
    RegisteredPopulation: "405",
    VotingStationName: "NOMANDLA  SENIOR PRIMARY SCHOOL",
    Address: "MABHELENI LOC.  NTLAZA A/A  NGQELENI",
    Latitude: "-31.5976",
    Longitude: "29.1742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3086",
    WardLookupId: "678",
    VDNumber: "11601021",
    RegisteredPopulation: "718",
    VotingStationName: "NJIVENI JUNIOR SECONDARY SCHOOL",
    Address: "NJIVENI LOC.  QITHI A/A  NGQELENI",
    Latitude: "-31.5746",
    Longitude: "29.1518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3087",
    WardLookupId: "678",
    VDNumber: "11601032",
    RegisteredPopulation: "313",
    VotingStationName: "NOHOKOZA JUNIOR SECONDARY SCHOOL",
    Address: "NOHOKOZA LOC.  NGQONGWENI A/A  LIBODE",
    Latitude: "-31.5933",
    Longitude: "29.2041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3088",
    WardLookupId: "678",
    VDNumber: "11601133",
    RegisteredPopulation: "630",
    VotingStationName: "KHANGISA JUNIOR SECONDARY SCHOOL",
    Address: "NGIDINI LOCATION  NJIVENI A/A  LIBODE",
    Latitude: "-31.5892",
    Longitude: "29.1378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3089",
    WardLookupId: "678",
    VDNumber: "11601166",
    RegisteredPopulation: "395",
    VotingStationName: "SONATHA SENIOR PRIMARY SCHOOL",
    Address: "MLENGANA LOCATION  MKHANKATHO A/A  LIBODE",
    Latitude: "-31.546",
    Longitude: "29.1655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3090",
    WardLookupId: "679",
    VDNumber: "11600200",
    RegisteredPopulation: "459",
    VotingStationName: "EMVILO JUNIOR SECONDARY SCHOOL",
    Address: "MNYAMENI LOC.  ZINDUNENI A/A  NGQELENI",
    Latitude: "-31.7307",
    Longitude: "29.2316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3091",
    WardLookupId: "679",
    VDNumber: "11600211",
    RegisteredPopulation: "402",
    VotingStationName: "TUNGWENI JUNIOR SECONDARY SCHOOL",
    Address: "ZINDUNENI LOC.  ZINDUNENI A/A  NGQELENI",
    Latitude: "-31.7",
    Longitude: "29.21",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3092",
    WardLookupId: "679",
    VDNumber: "11600222",
    RegisteredPopulation: "725",
    VotingStationName: "DUMEZWENI SENIOR SECONDARY SCHOOL",
    Address: "ZINDUNENI LOC.  ZINDUNENI A/A  NGQELENI",
    Latitude: "-31.6828",
    Longitude: "29.1915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3093",
    WardLookupId: "679",
    VDNumber: "11600727",
    RegisteredPopulation: "221",
    VotingStationName: "CWELE MISSION",
    Address: "CWELE LOC.  ZINDUNENI A/A  NGQELENI",
    Latitude: "-31.6722",
    Longitude: "29.243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3094",
    WardLookupId: "679",
    VDNumber: "11600749",
    RegisteredPopulation: "777",
    VotingStationName: "NOMCAMBA JUNIOR SECONDARY SCHOOL",
    Address: "NOMCAMBA LOC.  ZINDUNENI A/A  NGQELENI",
    Latitude: "-31.7108",
    Longitude: "29.1743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3095",
    WardLookupId: "679",
    VDNumber: "11600929",
    RegisteredPopulation: "489",
    VotingStationName: "MAYIBENYE JUNIOR SECONDARY SCHOOL",
    Address: "LUSIZINI LOC.  MAQEBEVU A/A  NGQELENI",
    Latitude: "-31.7083",
    Longitude: "29.2386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3096",
    WardLookupId: "679",
    VDNumber: "11601065",
    RegisteredPopulation: "589",
    VotingStationName: "SIZWE JUNIOR SECONDARY SCHOOL",
    Address: "MNYAMENI LOCATION  ZINDUNENI A/A  NGQELENI",
    Latitude: "-31.715",
    Longitude: "29.2128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3097",
    WardLookupId: "679",
    VDNumber: "11601212",
    RegisteredPopulation: "324",
    VotingStationName: "QANDA JUNIOR SECONDARY SCHOOL",
    Address: "BANDLA  NGQELENI",
    Latitude: "-31.6512",
    Longitude: "29.2493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3098",
    WardLookupId: "679",
    VDNumber: "11601267",
    RegisteredPopulation: "240",
    VotingStationName: "MZONGWANA  SENIOR PRIMARY SCHOOL",
    Address: "GANGENI LOCATION  ZINDUNENI A/A  DANGENI LOCATION",
    Latitude: "-31.6748",
    Longitude: "29.2039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3099",
    WardLookupId: "680",
    VDNumber: "11600053",
    RegisteredPopulation: "1148",
    VotingStationName: "MDIKANE JUNIOR SECONDARY SCHOOL",
    Address: "LUCINGWENI LOC.  HLULEKA  NGQELENI",
    Latitude: "-31.8315",
    Longitude: "29.2742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3100",
    WardLookupId: "680",
    VDNumber: "11600165",
    RegisteredPopulation: "530",
    VotingStationName: "DALISOKA LOWER PRIMARY SCHOOL",
    Address: "GANGENI LOC.  LUCINGWENI A/A  NGQELENI",
    Latitude: "-31.8096",
    Longitude: "29.2428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3101",
    WardLookupId: "680",
    VDNumber: "11600176",
    RegisteredPopulation: "953",
    VotingStationName: "SANDI SENIOR SECONDARY SCHOOL",
    Address: "NTSUNDWANE LOC.  NKANUNU A/A  NGQELENI",
    Latitude: "-31.7611",
    Longitude: "29.2145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3102",
    WardLookupId: "680",
    VDNumber: "11600187",
    RegisteredPopulation: "859",
    VotingStationName: "NOTHINTSILA JUNIOR SECONDARY SCHOOL",
    Address: "NOTHINTSILA LOC.  NKANUNU A/A  NGQELENI",
    Latitude: "-31.7339",
    Longitude: "29.185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3103",
    WardLookupId: "680",
    VDNumber: "11600761",
    RegisteredPopulation: "658",
    VotingStationName: "BUCULA JUNIOR SECONDARY SCHOOL",
    Address: "BUCULA LOC.  ZINDUNENI A/A  NGQELENI",
    Latitude: "-31.7945",
    Longitude: "29.2641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3104",
    WardLookupId: "680",
    VDNumber: "11600794",
    RegisteredPopulation: "685",
    VotingStationName: "VINISH JUNIOR SECONDARY SCHOOL",
    Address: "VINISH LOC.  NKANUNU A/A  NGQELENI",
    Latitude: "-31.8001",
    Longitude: "29.222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3105",
    WardLookupId: "681",
    VDNumber: "11520010",
    RegisteredPopulation: "981",
    VotingStationName: "NGQELENI VILLAGE PRE SCHOOL",
    Address: "EXTENSION 4  NGQELENI VILLAGE  NGQELENI",
    Latitude: "-31.67",
    Longitude: "29.023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3106",
    WardLookupId: "681",
    VDNumber: "11520021",
    RegisteredPopulation: "371",
    VotingStationName: "NGQELENI TOWN HALL",
    Address: "MAIN STR  NGQELENI  NGQELENI",
    Latitude: "-31.6726",
    Longitude: "29.034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3107",
    WardLookupId: "681",
    VDNumber: "11600367",
    RegisteredPopulation: "681",
    VotingStationName: "BANTINI JUNIOR SECONDARY SCHOOL",
    Address: "BANTINI LOC.  MAZIZINI A/A  NGQELENI",
    Latitude: "-31.67",
    Longitude: "29.0694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3108",
    WardLookupId: "681",
    VDNumber: "11600525",
    RegisteredPopulation: "366",
    VotingStationName: "MANZINI JUNIOR SECONDARY SCHOOL",
    Address: "MNGAZANA LOC.  MAQANYENI A/A  NGQELENI",
    Latitude: "-31.6441",
    Longitude: "29.0212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3109",
    WardLookupId: "681",
    VDNumber: "11600738",
    RegisteredPopulation: "629",
    VotingStationName: "UPPER MATHANZIMA JUNIOR SECONDARY SCHOOL",
    Address: "NZWAKAZI LOC.  MAQANYENI A/A  NGQELENI",
    Latitude: "-31.6551",
    Longitude: "28.9636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3110",
    WardLookupId: "681",
    VDNumber: "11601111",
    RegisteredPopulation: "397",
    VotingStationName: "MATANZIMA JUNIOR SECONDARY SCHOOL",
    Address: "MNGAZANA LOCATION  MAQANYENI A/A  NGQELENI",
    Latitude: "-31.65",
    Longitude: "28.9969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3111",
    WardLookupId: "681",
    VDNumber: "11601177",
    RegisteredPopulation: "494",
    VotingStationName: "SB NGCOBO MEMORIAL CHURCH",
    Address: "NZWAKAZI LOCATION     MAQANYENI A/A    NGQELENI",
    Latitude: "-31.6617",
    Longitude: "28.9735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3112",
    WardLookupId: "682",
    VDNumber: "11600110",
    RegisteredPopulation: "851",
    VotingStationName: "WELESE JUNIOR SECONDARY SCHOOL",
    Address: "EMAYALWENI LOC.  BOMVANA A/A  NGQELENI",
    Latitude: "-31.803",
    Longitude: "29.0227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3113",
    WardLookupId: "682",
    VDNumber: "11600277",
    RegisteredPopulation: "943",
    VotingStationName: "MKHUNDLU JUNIOR SECONDARY SCHOOL",
    Address: "CANZIBE LOC.  MAQADINI A/A  NGQELENI",
    Latitude: "-31.8086",
    Longitude: "29.0607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3114",
    WardLookupId: "682",
    VDNumber: "11600312",
    RegisteredPopulation: "578",
    VotingStationName: "NGONYAMA JUNIOR SECONDARY SCHOOL",
    Address: "MTOKWANA LOC.  NQANDA B A/A  NGQELENI",
    Latitude: "-31.8034",
    Longitude: "28.9718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3115",
    WardLookupId: "682",
    VDNumber: "11600323",
    RegisteredPopulation: "765",
    VotingStationName: "LOWER MALAHLE JUNIOR SECONDARY SCHOOL",
    Address: "MTOKWANA LOC.  NQANDA A/A  NGQELENI",
    Latitude: "-31.7836",
    Longitude: "28.9513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3116",
    WardLookupId: "682",
    VDNumber: "11600907",
    RegisteredPopulation: "753",
    VotingStationName: "NKANTSINI JUNIOR SECONDARY SCHOOL",
    Address: "NKANTSINI LOC.  NKANTSINI A/A  NGQELENI",
    Latitude: "-31.7864",
    Longitude: "28.9982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3117",
    WardLookupId: "683",
    VDNumber: "11600097",
    RegisteredPopulation: "877",
    VotingStationName: "MANYOSANA JUNIOR SECONDARY SCHOOL",
    Address: "DETYANA LOC.  DETYANA A/A  NGQELENI",
    Latitude: "-31.828",
    Longitude: "29.0826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3118",
    WardLookupId: "683",
    VDNumber: "11600109",
    RegisteredPopulation: "327",
    VotingStationName: "MNQABE JUNIOR SECONDARY SCHOOL",
    Address: "MNQABE LOC.  THEKWINI A/A  NGQELENI",
    Latitude: "-31.8458",
    Longitude: "29.0257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3119",
    WardLookupId: "683",
    VDNumber: "11600121",
    RegisteredPopulation: "630",
    VotingStationName: "GAVIN JUNIOR SECONDARY SCHOOL",
    Address: "HAMSINI LOC.  HAMSINI A/A  NGQELENI",
    Latitude: "-31.8059",
    Longitude: "29.0791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3120",
    WardLookupId: "683",
    VDNumber: "11600806",
    RegisteredPopulation: "635",
    VotingStationName: "ELUDIKIDI JUNIOR SECONDARY SCHOOL",
    Address: "MNQABE LOC.  TEKWINI A/A  NGQELENI",
    Latitude: "-31.8531",
    Longitude: "29.0263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3121",
    WardLookupId: "683",
    VDNumber: "11600817",
    RegisteredPopulation: "315",
    VotingStationName: "SIZANE JUNIOR SECONDARY SCHOOL",
    Address: "BOMVANA LOC.  NOMADOLO A/A  NGQELENI",
    Latitude: "-31.8227",
    Longitude: "29.0045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3122",
    WardLookupId: "683",
    VDNumber: "11600873",
    RegisteredPopulation: "708",
    VotingStationName: "NOMADOLO JUNIOR SECONDARY SCHOOL",
    Address: "NOMADOLO LOC.  NOMADOLO A/A  NGQELENI",
    Latitude: "-31.8194",
    Longitude: "29.0412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3123",
    WardLookupId: "684",
    VDNumber: "11600345",
    RegisteredPopulation: "731",
    VotingStationName: "PANGALELE SENIOR SECONDARY SCHOOL",
    Address: "MABETSHE LOC.  NKUMANDENI A/A  NGQELENI",
    Latitude: "-31.7375",
    Longitude: "29.0552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3124",
    WardLookupId: "684",
    VDNumber: "11600356",
    RegisteredPopulation: "544",
    VotingStationName: "GODINI JUNIOR SECONDARY SCHOOL",
    Address: "GODINI LOC.  GODINI A/A  NGQELENI",
    Latitude: "-31.6879",
    Longitude: "29.0675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3125",
    WardLookupId: "684",
    VDNumber: "11600569",
    RegisteredPopulation: "496",
    VotingStationName: "MBANGE JUNIOR SECONDARY SCHOOL",
    Address: "MBANGE LOC.  MBANGE A/A  NGQELENI",
    Latitude: "-31.6721",
    Longitude: "28.9803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3126",
    WardLookupId: "684",
    VDNumber: "11600750",
    RegisteredPopulation: "578",
    VotingStationName: "GUQAZA JUNIOR SECONDARY SCHOOL",
    Address: "BUTHONGWENI LOC.  BUTHONGWENI A/A  NGQELENI",
    Latitude: "-31.7034",
    Longitude: "29.0484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3127",
    WardLookupId: "684",
    VDNumber: "11600828",
    RegisteredPopulation: "358",
    VotingStationName: "MALUNGENI CENTRE",
    Address: "MALUNGENI LOC.  MALUNGENI A/A  NGQELENI",
    Latitude: "-31.7004",
    Longitude: "28.9967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3128",
    WardLookupId: "684",
    VDNumber: "11600930",
    RegisteredPopulation: "706",
    VotingStationName: "MABETSHE JUNIOR SECONDARY SCHOOL",
    Address: "MABETSHE LOC.  NKUMANDENI A/A  NGQELENI",
    Latitude: "-31.7279",
    Longitude: "29.0466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3129",
    WardLookupId: "684",
    VDNumber: "11601087",
    RegisteredPopulation: "312",
    VotingStationName: "LOWER GODINI JUNIOR SECONDARY SCHOOL",
    Address: "LOWER GODINI LOCATION  GODINI A/A  NGQELENI",
    Latitude: "-31.7212",
    Longitude: "29.1017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3130",
    WardLookupId: "684",
    VDNumber: "11601223",
    RegisteredPopulation: "158",
    VotingStationName: "NKALWENI SENIOR PRIMARY SCHOOL",
    Address: "NGQELENI  NKALWENI A/A  EKULAMBENI",
    Latitude: "-31.692",
    Longitude: "29.1029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3131",
    WardLookupId: "685",
    VDNumber: "11600020",
    RegisteredPopulation: "1201",
    VotingStationName: "LWANDILE JUNIOR SECONDARY SCHOOL",
    Address: "LWANDILE LOC.  NDUNGUNYENI A/A  NGQELENI",
    Latitude: "-31.8671",
    Longitude: "29.2089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3132",
    WardLookupId: "685",
    VDNumber: "11600031",
    RegisteredPopulation: "759",
    VotingStationName: "MTHAKATYE JUNIOR SECONDARY SCHOOL",
    Address: "EMAMOLWENI LOC.  EMAMOLWENI  NGQELENI",
    Latitude: "-31.8598",
    Longitude: "29.2477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3133",
    WardLookupId: "685",
    VDNumber: "11600132",
    RegisteredPopulation: "785",
    VotingStationName: "MPIMBO JUNIOR SECONDARY SCHOOL",
    Address: "MPIMBO LOC.  MPIMBO A/A  NGQELENI",
    Latitude: "-31.8281",
    Longitude: "29.1861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3134",
    WardLookupId: "685",
    VDNumber: "11600772",
    RegisteredPopulation: "896",
    VotingStationName: "MNGCIBE JUNIOR SENIOR SCHOOL",
    Address: "MNGCIBE LOC.  LWANDILE A/A  NGQELENI",
    Latitude: "-31.9049",
    Longitude: "29.2121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3135",
    WardLookupId: "685",
    VDNumber: "11600851",
    RegisteredPopulation: "445",
    VotingStationName: "NTSHILINI SENIOR SECONDARY SCHOOL",
    Address: "NTSHILINI LOC.  NTSHILINI A/A  NGQELENI",
    Latitude: "-31.8948",
    Longitude: "29.1708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3136",
    WardLookupId: "685",
    VDNumber: "11600895",
    RegisteredPopulation: "466",
    VotingStationName: "MZONYANA PRE SCHOOL",
    Address: "MPIMBO LOC.  MPOZA A/A  MZONYANA",
    Latitude: "-31.8398",
    Longitude: "29.1862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3137",
    WardLookupId: "686",
    VDNumber: "11600019",
    RegisteredPopulation: "1063",
    VotingStationName: "SKHOMA JUNIOR SECONDARY SCHOOL",
    Address: "MANKOSI LOC.  MANKOSI A/A  NGQELENI",
    Latitude: "-31.9297",
    Longitude: "29.1833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3138",
    WardLookupId: "686",
    VDNumber: "11600042",
    RegisteredPopulation: "791",
    VotingStationName: "NTSHILINI JUNIOR SECONDARY SCHOOL",
    Address: "NTSHILINI LOCATION  TSHANI A/A  NGQELENI",
    Latitude: "-31.9056",
    Longitude: "29.1473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3139",
    WardLookupId: "686",
    VDNumber: "11600064",
    RegisteredPopulation: "826",
    VotingStationName: "JONGINTABA JUNIOR SECONDARY SCHOOL",
    Address: "NTSHILINI LOC.  NTSHILINI A/A  NGQELENI",
    Latitude: "-31.8781",
    Longitude: "29.1343",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3140",
    WardLookupId: "686",
    VDNumber: "11600075",
    RegisteredPopulation: "678",
    VotingStationName: "MALIZOLE JUNIOR SECONDARY SCHOOL",
    Address: "NTSIMBINI LOC.  NTSIMBINI A/A  NGQELENI",
    Latitude: "-31.8357",
    Longitude: "29.1068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3141",
    WardLookupId: "686",
    VDNumber: "11600086",
    RegisteredPopulation: "923",
    VotingStationName: "LUTHAMBO JUNIOR SECONDARY SCHOOL",
    Address: "LUTHAMBO LOC.  NTSIMBINI A/A  NGQELENI",
    Latitude: "-31.8647",
    Longitude: "29.1062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3142",
    WardLookupId: "686",
    VDNumber: "11600862",
    RegisteredPopulation: "228",
    VotingStationName: "GOSO SENIOR PRIMARY SCHOOL",
    Address: "GOSO LOC.  BOMVINI A/A  LIBODE",
    Latitude: "-31.892",
    Longitude: "29.0777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3143",
    WardLookupId: "687",
    VDNumber: "11580342",
    RegisteredPopulation: "789",
    VotingStationName: "NKANGA JUNIOR SECONDARY SCHOOL",
    Address: "NKANGA LOCATION  MBOBELENI A/A  LIBODE",
    Latitude: "-31.4586",
    Longitude: "29.1687",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3144",
    WardLookupId: "687",
    VDNumber: "11580353",
    RegisteredPopulation: "894",
    VotingStationName: "BUNGU JUNIOR SECONDARY SCHOOL",
    Address: "BUNGU LOC  MBOBELENI A/A  LIBODE",
    Latitude: "-31.4277",
    Longitude: "29.1428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3145",
    WardLookupId: "687",
    VDNumber: "11580511",
    RegisteredPopulation: "650",
    VotingStationName: "LUKUNI SENIOR PRIMARY SCHOOL",
    Address: "LUKUNI LOC  LUKUNI A/A  LIBODE",
    Latitude: "-31.4428",
    Longitude: "29.2046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3146",
    WardLookupId: "687",
    VDNumber: "11580780",
    RegisteredPopulation: "317",
    VotingStationName: "APOSTOLIC CHURCH OF AFRICA",
    Address: "GQWARU LOCATION  LUKUNI A/A  LIBODE",
    Latitude: "-31.4565",
    Longitude: "29.213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3147",
    WardLookupId: "687",
    VDNumber: "11581028",
    RegisteredPopulation: "601",
    VotingStationName: "DIKELA JUNIOR SECONDARY SCHOOL",
    Address: "DIKELA LOC.  BUNGU A/A  LIBODE",
    Latitude: "-31.4035",
    Longitude: "29.1866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3148",
    WardLookupId: "687",
    VDNumber: "11581118",
    RegisteredPopulation: "421",
    VotingStationName: "NKONKONI SENIOR PRIMARY SCHOOL",
    Address: "NKONKONI LOCATION  MBOBELENI A/A  LIBODE",
    Latitude: "-31.4657",
    Longitude: "29.1393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3149",
    WardLookupId: "687",
    VDNumber: "11581141",
    RegisteredPopulation: "325",
    VotingStationName: "BEDESIDER CHURCH",
    Address: "NKANGA  ",
    Latitude: "-31.4649",
    Longitude: "29.179",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3150",
    WardLookupId: "688",
    VDNumber: "11600143",
    RegisteredPopulation: "1090",
    VotingStationName: "LOWER RAINY JUNIOR SECONDARY SCHOOL",
    Address: "LOWER RAINY  MPOZA A/A  NTIBANE",
    Latitude: "-31.7937",
    Longitude: "29.1586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3151",
    WardLookupId: "688",
    VDNumber: "11600154",
    RegisteredPopulation: "830",
    VotingStationName: "QHOKAMA JUNIOR SECONDARY SCHOOL",
    Address: "QHOKAMA LOC.  MFUNDWENI A/A  NGQELENI",
    Latitude: "-31.7547",
    Longitude: "29.0874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3152",
    WardLookupId: "688",
    VDNumber: "11600783",
    RegisteredPopulation: "634",
    VotingStationName: "SIDANDA  SENIOR PRIMARY SCHOOL",
    Address: "SIDANDA LOCATION  QOKAMA A/A  NGQELENI",
    Latitude: "-31.7513",
    Longitude: "29.138",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3153",
    WardLookupId: "688",
    VDNumber: "11600941",
    RegisteredPopulation: "445",
    VotingStationName: "MBAMBENI SENIOR PRIMARY SCHOOL",
    Address: "NGONYAMENI LOC.  QHOKAMA A/A  NGQELENI",
    Latitude: "-31.7553",
    Longitude: "29.0621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3154",
    WardLookupId: "688",
    VDNumber: "11601043",
    RegisteredPopulation: "434",
    VotingStationName: "MFUNDWENI SENIOR PRIMARY SCHOOL",
    Address: "MFUNDWENI LOCATION  QOKAMA A/A  NGQELENI",
    Latitude: "-31.7721",
    Longitude: "29.1223",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3155",
    WardLookupId: "688",
    VDNumber: "11601076",
    RegisteredPopulation: "294",
    VotingStationName: "THEMBENI SENIOR PRIMARY SCHOOL",
    Address: "TEMBENI LOCATION  QOKAMA A/A  NGQELENI",
    Latitude: "-31.7901",
    Longitude: "29.1155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3156",
    WardLookupId: "689",
    VDNumber: "11600592",
    RegisteredPopulation: "985",
    VotingStationName: "BOLOTWA JUNIOR SECONDARY SCHOOL",
    Address: "BOLOTWA LOC.  BOLOTWA A/A  NGQELENI",
    Latitude: "-31.632",
    Longitude: "28.9487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3157",
    WardLookupId: "689",
    VDNumber: "11600604",
    RegisteredPopulation: "782",
    VotingStationName: "LOWER BOLOTWA JUNIOR SECONDARY SCHOOL",
    Address: "MPENDLE LOC.  L/BOLOTWA A/A  NGQELENI",
    Latitude: "-31.6037",
    Longitude: "28.9112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3158",
    WardLookupId: "689",
    VDNumber: "11600615",
    RegisteredPopulation: "1050",
    VotingStationName: "NKAWUKAZI JUNIOR SECONDARY SCHOOL",
    Address: "NKAWUKAZI LOC.  LANGENI A/A  NGQELENI",
    Latitude: "-31.5882",
    Longitude: "28.8964",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3159",
    WardLookupId: "689",
    VDNumber: "11600693",
    RegisteredPopulation: "1043",
    VotingStationName: "MDENI JUNIOR SECONDARY SCHOOL",
    Address: "MDENI LOC.  ZIXHOLOSINI A/A  NGQELENI",
    Latitude: "-31.6625",
    Longitude: "28.9423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3160",
    WardLookupId: "689",
    VDNumber: "11600974",
    RegisteredPopulation: "407",
    VotingStationName: "ZANOXOLO JUNIOR SECONDARY SCHOOL",
    Address: "DUMASI LOC.  ZIXHOSINI A/A  NGQELENI",
    Latitude: "-31.6569",
    Longitude: "28.9159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3161",
    WardLookupId: "689",
    VDNumber: "11601100",
    RegisteredPopulation: "259",
    VotingStationName: "MTHAWELANGA JUNIOR PRIMARY SCHOOL",
    Address: "MPENDLE LOCATION  LANGENI A/A  NGQELENI",
    Latitude: "-31.5738",
    Longitude: "28.9271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3162",
    WardLookupId: "690",
    VDNumber: "11600491",
    RegisteredPopulation: "592",
    VotingStationName: "LUTSHEKO JUNIOR SECONDARY SCHOOL",
    Address: "LUTSHEKO LOC.  MNGAMNYE A/A  NGQELENI",
    Latitude: "-31.6236",
    Longitude: "29.0443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3163",
    WardLookupId: "690",
    VDNumber: "11600503",
    RegisteredPopulation: "551",
    VotingStationName: "UPPER MNGAMNYE JUNIOR SECONDARY SCHOOL",
    Address: "MNGAMNYE NO.1  U/MNGAMNYE A/A  NGQELENI",
    Latitude: "-31.6293",
    Longitude: "29.0092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3164",
    WardLookupId: "690",
    VDNumber: "11600514",
    RegisteredPopulation: "768",
    VotingStationName: "LOWER MNGAMNYE JUNIOR SECONDARY SCHOOL",
    Address: "MNGAMNYE NO.2  L/MNGAMNYE A/A  NGQELENI",
    Latitude: "-31.5933",
    Longitude: "29.0112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3165",
    WardLookupId: "690",
    VDNumber: "11600682",
    RegisteredPopulation: "833",
    VotingStationName: "MCONCO JUNIOR SECONDARY SCHOOL",
    Address: "MCONCO LOC.  MCOMCO A/A  NGQELENI",
    Latitude: "-31.5641",
    Longitude: "28.9557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3166",
    WardLookupId: "690",
    VDNumber: "11601009",
    RegisteredPopulation: "703",
    VotingStationName: "CHURCH OF GOD",
    Address: "MCHONCO LOC.  MCHONCO A/A  NGQELENI",
    Latitude: "-31.548",
    Longitude: "28.9545",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3167",
    WardLookupId: "690",
    VDNumber: "11601098",
    RegisteredPopulation: "296",
    VotingStationName: "MFABANTU JUNIOR SECONDARY SCHOOL",
    Address: "MFABANTU LOCATION  GIBISELA A/A  NGQELENI",
    Latitude: "-31.5919",
    Longitude: "28.9684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3168",
    WardLookupId: "690",
    VDNumber: "11601122",
    RegisteredPopulation: "373",
    VotingStationName: "LUVELO SENIOR PRIMARY SCHOOL",
    Address: "MASEMENI LOCATION  GIBISELA A/A  NGQELENI",
    Latitude: "-31.5716",
    Longitude: "28.9884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3169",
    WardLookupId: "690",
    VDNumber: "11601188",
    RegisteredPopulation: "500",
    VotingStationName: "FEZEKILE JUNIOR SECONDARY SCHOOL",
    Address: "KWAZULU LOCATION  KWAZULU A/A  EC155 - NYANDENI [LIBODE]",
    Latitude: "-31.6039",
    Longitude: "28.951",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3170",
    WardLookupId: "690",
    VDNumber: "11601199",
    RegisteredPopulation: "290",
    VotingStationName: "DIKELA SPRINGS SENIOR PRIMARY SCHOOL",
    Address: "MNGAMNYE  NGQELENI",
    Latitude: "-31.6274",
    Longitude: "28.9785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3171",
    WardLookupId: "691",
    VDNumber: "11580128",
    RegisteredPopulation: "1022",
    VotingStationName: "RAINY JUNIOR SECONDARY SCHOOL",
    Address: "RAINY LOC.  NYANDENI A/A  LIBODE",
    Latitude: "-31.473",
    Longitude: "28.9097",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3172",
    WardLookupId: "691",
    VDNumber: "11580140",
    RegisteredPopulation: "720",
    VotingStationName: "NONESI JUNIOR SECONDARY SCHOOL",
    Address: "NONESI LOC.  NYANDENI A/A  LIBODE",
    Latitude: "-31.4546",
    Longitude: "28.9698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3173",
    WardLookupId: "691",
    VDNumber: "11580612",
    RegisteredPopulation: "894",
    VotingStationName: "MTOMDE JUNIOR SECONDARY SCHOOL",
    Address: "MTOMDE LOCATION  CORANA A/A  LIBODE",
    Latitude: "-31.4888",
    Longitude: "28.9008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3174",
    WardLookupId: "691",
    VDNumber: "11580869",
    RegisteredPopulation: "301",
    VotingStationName: "GUNYENI JUNIOR SECONDARY SCHOOL",
    Address: "LURASINI LOCATION  ZADUKWANA A/A  LIBODE",
    Latitude: "-31.4384",
    Longitude: "28.8765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3175",
    WardLookupId: "691",
    VDNumber: "11580904",
    RegisteredPopulation: "941",
    VotingStationName: "NYANDENI JUNIOR SECONDARY SCHOOL",
    Address: "NYANDENI LOCATION  NYANDENI A/A  LIBODE",
    Latitude: "-31.4569",
    Longitude: "28.9544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3176",
    WardLookupId: "692",
    VDNumber: "11600288",
    RegisteredPopulation: "762",
    VotingStationName: "NKUNZIMBINI JUNIOR SECONDARY SCHOOL",
    Address: "MGOJWENI LOC.  MGOJWENI A/A  NGQELENI",
    Latitude: "-31.7723",
    Longitude: "29.0316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3177",
    WardLookupId: "692",
    VDNumber: "11600299",
    RegisteredPopulation: "1293",
    VotingStationName: "GXABA SENIOR SECONDARY SCHOOL",
    Address: "LUJIZWENI NO.5 LOC.  LUJIZWENI A/A  NGQELENI",
    Latitude: "-31.7715",
    Longitude: "29.001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3178",
    WardLookupId: "692",
    VDNumber: "11600301",
    RegisteredPopulation: "719",
    VotingStationName: "NQAKAMATYE JUNIOR SECONDARY SCHOOL",
    Address: "LUJIZWENI NO.1  LUJIZWENI A/A  NGQELENI",
    Latitude: "-31.7489",
    Longitude: "29.0001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3179",
    WardLookupId: "692",
    VDNumber: "11600334",
    RegisteredPopulation: "564",
    VotingStationName: "MLATHA JUNIOR SECONDARY SCHOOL",
    Address: "NQANDA LOC.  NQANDA A/A  NGQELENI",
    Latitude: "-31.7777",
    Longitude: "28.9339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3180",
    WardLookupId: "692",
    VDNumber: "11600536",
    RegisteredPopulation: "550",
    VotingStationName: "GQIRHA JUNIOR SECONDARY SCHOOL",
    Address: "MABOMVINI LOC.  MALUNGENI A/A  NGQELENI",
    Latitude: "-31.7324",
    Longitude: "28.9675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3181",
    WardLookupId: "692",
    VDNumber: "11601054",
    RegisteredPopulation: "413",
    VotingStationName: "MALAHLE JUNIOR SECONDARY SCHOOL",
    Address: "MTHOKWANA LOCATION  NQANDA A/A  NGQELENI",
    Latitude: "-31.7655",
    Longitude: "28.9405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3182",
    WardLookupId: "692",
    VDNumber: "11601278",
    RegisteredPopulation: "97",
    VotingStationName: "MAGILA  SENIOR PRIMARY SCHOOL",
    Address: "NDONGA  NDONGA A/A  NDONGA LOCATION",
    Latitude: "-31.8114",
    Longitude: "28.9099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3183",
    WardLookupId: "3190",
    VDNumber: "11620178",
    RegisteredPopulation: "316",
    VotingStationName: "HOBE SENIOR PRIMARY  SCHOOL",
    Address: "QUTHUBENI LOC.  TIKITIKI   TSOLO",
    Latitude: "-31.3209",
    Longitude: "28.5323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3184",
    WardLookupId: "3190",
    VDNumber: "11620314",
    RegisteredPopulation: "361",
    VotingStationName: "MBINJA JUNIOR SECONDARY SCHOOL",
    Address: "MBINJA  LOCATION  MBINJA A/A  TSOLO",
    Latitude: "-31.3617",
    Longitude: "28.4947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3185",
    WardLookupId: "3190",
    VDNumber: "11620325",
    RegisteredPopulation: "496",
    VotingStationName: "BAVUMELENI JUNIOR SECONDARY SCHOOL",
    Address: "LUJECWENI LOC  ELUJECWENI A/A  TSOLO",
    Latitude: "-31.2604",
    Longitude: "28.5896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3186",
    WardLookupId: "3190",
    VDNumber: "11620358",
    RegisteredPopulation: "704",
    VotingStationName: "JENCA  JUNIOR SCONDARY  SCHOOL",
    Address: "JENCA LOCATION   JENCA A/A  TSOLO",
    Latitude: "-31.2955",
    Longitude: "28.5648",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3187",
    WardLookupId: "3190",
    VDNumber: "11620640",
    RegisteredPopulation: "370",
    VotingStationName: "MPOZA JUNIOR SECONDARY SCHOOL",
    Address: "MPOZA A/A  MPOZA LOCATION  TSOLO",
    Latitude: "-31.3108",
    Longitude: "28.5014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3188",
    WardLookupId: "3190",
    VDNumber: "11620662",
    RegisteredPopulation: "332",
    VotingStationName: "RICHARD SAMELA JUNIOR SECONDARY SCHOOL",
    Address: "MNGA LOCATION   MNGA A/A  TSOLO",
    Latitude: "-31.2569",
    Longitude: "28.5027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3189",
    WardLookupId: "3190",
    VDNumber: "11620673",
    RegisteredPopulation: "362",
    VotingStationName: "MADUMELWANO SENIOR PRIMARY SCHOOL",
    Address: "KRATYELA LOCATION   UMNGA A/A  TSOLO",
    Latitude: "-31.2913",
    Longitude: "28.5108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3190",
    WardLookupId: "3190",
    VDNumber: "11620886",
    RegisteredPopulation: "360",
    VotingStationName: "ZANEMVULA JUNIOR SECONDARY SCHOOL",
    Address: "MAJABA LOCATION   TSOLO   JENCA A/A",
    Latitude: "-31.283",
    Longitude: "28.5473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3191",
    WardLookupId: "3190",
    VDNumber: "11620897",
    RegisteredPopulation: "514",
    VotingStationName: "ZWELITSHA JUNIOR SECONDARY SCHOOL",
    Address: "ELUJECWENI LOCATION  ELUJECWENI A/A  TSOLO",
    Latitude: "-31.2553",
    Longitude: "28.5527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3192",
    WardLookupId: "3190",
    VDNumber: "11621023",
    RegisteredPopulation: "175",
    VotingStationName: "W.M.RANUGA SENIOR PRIMARY SCHOOL",
    Address: "FARADALE LOCATION   JENCA A/A  TSOLO",
    Latitude: "-31.3532",
    Longitude: "28.5139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3193",
    WardLookupId: "3191",
    VDNumber: "11620246",
    RegisteredPopulation: "654",
    VotingStationName: "UPPER MJIKA JUNIOR SECONDARY SCHOOL",
    Address: "KAMBI 20 A/A  KAMBI 20  TSOLO",
    Latitude: "-31.4348",
    Longitude: "28.6463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3194",
    WardLookupId: "3191",
    VDNumber: "11620561",
    RegisteredPopulation: "568",
    VotingStationName: "MNQANDANTO SENIOR PRIMARY SCHOOL",
    Address: "XABANE A/A  MQANDANTO LOC  TSOLO",
    Latitude: "-31.3699",
    Longitude: "28.66",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3195",
    WardLookupId: "3191",
    VDNumber: "11620594",
    RegisteredPopulation: "854",
    VotingStationName: "MQOBISI JUNIOR SECONDARY SCHOOL",
    Address: "MANGEZEMENI LOCATION   UPPER MJIKA  TSOLO",
    Latitude: "-31.4011",
    Longitude: "28.628",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3196",
    WardLookupId: "3191",
    VDNumber: "11620752",
    RegisteredPopulation: "818",
    VotingStationName: "LANGELIHLE JUNIOR SECONDARY SCHOOL",
    Address: "HLATINI LOCATION  LANGENI A/A  TSOLO",
    Latitude: "-31.4818",
    Longitude: "28.4859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3197",
    WardLookupId: "3191",
    VDNumber: "11620875",
    RegisteredPopulation: "375",
    VotingStationName: "MTONYAMENI JUNIOR SECONDARY SCHOOL",
    Address: "MTONYAMENI LOCATION  MJIKA A/A  QUMBU",
    Latitude: "-31.407",
    Longitude: "28.667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3198",
    WardLookupId: "3192",
    VDNumber: "11620235",
    RegisteredPopulation: "1216",
    VotingStationName: "BELE ZINCUKA TECHNICAL COLLEGE",
    Address: "BELE ZINGCUKA TECH  ZINGCUKA A/A  QUMBU",
    Latitude: "-31.2875",
    Longitude: "28.6531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3199",
    WardLookupId: "3192",
    VDNumber: "11620336",
    RegisteredPopulation: "886",
    VotingStationName: "ST. CUTHBERTS PARISH HALL",
    Address: "NGCOLOSI  A/A  NGCOLOSI A/A",
    Latitude: "-31.301",
    Longitude: "28.63",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3200",
    WardLookupId: "3192",
    VDNumber: "11620347",
    RegisteredPopulation: "531",
    VotingStationName: "PATISANANI JUNIOR SECONDARY SCHOOL",
    Address: "NOMHALA A/A  NOMHALA LOCATION   TSOLO",
    Latitude: "-31.2582",
    Longitude: "28.6271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3201",
    WardLookupId: "3192",
    VDNumber: "11620549",
    RegisteredPopulation: "233",
    VotingStationName: "ZANOMTHETHO JUNIOR SECONDARY SCHOOL",
    Address: "MANKA A/A  MANKA LOCATION   TSOLO",
    Latitude: "-31.2537",
    Longitude: "28.6575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3202",
    WardLookupId: "3192",
    VDNumber: "11620909",
    RegisteredPopulation: "447",
    VotingStationName: "JONGIKUNDLA JUNIOR SECONDARY SCHOOL",
    Address: "NOMHALA LOCATION   NOMHALA A/A  QUMBU",
    Latitude: "-31.2723",
    Longitude: "28.6074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3203",
    WardLookupId: "3192",
    VDNumber: "11620910",
    RegisteredPopulation: "298",
    VotingStationName: "MASIBAMBISANE JUNIOR SECONDARY SCHOOL",
    Address: "BELE A/A  JOJWENI  LOCATION TSOLO  TSOLO",
    Latitude: "-31.3061",
    Longitude: "28.6423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3204",
    WardLookupId: "3193",
    VDNumber: "11620202",
    RegisteredPopulation: "609",
    VotingStationName: "NQADU JUNIOR SECONDARY SCHOOL",
    Address: "QOLOMBANA LOC  QOLOMBANA  TSOLO",
    Latitude: "-31.4465",
    Longitude: "28.7582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3205",
    WardLookupId: "3193",
    VDNumber: "11620213",
    RegisteredPopulation: "433",
    VotingStationName: "GOTYIBENI SENIOR PRIMARY  SCHOOL",
    Address: "GOTYIBENI LOCATION   GUNGULULU A/A  TSOLO",
    Latitude: "-31.4671",
    Longitude: "28.8403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3206",
    WardLookupId: "3193",
    VDNumber: "11620224",
    RegisteredPopulation: "737",
    VotingStationName: "GUNGULULU SENIOR PRIMARY SCHOOL",
    Address: "GUNGULULU LOCATION  GUNGULULU A/A  TSOLO",
    Latitude: "-31.4668",
    Longitude: "28.8199",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3207",
    WardLookupId: "3193",
    VDNumber: "11620257",
    RegisteredPopulation: "420",
    VotingStationName: "LUCINGWENI JUNIOR SECONDARY SCHOOL",
    Address: "LUCINGWENI LOC.  NCAMBELE A/A  MTHATHA",
    Latitude: "-31.459",
    Longitude: "28.7564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3208",
    WardLookupId: "3193",
    VDNumber: "11620516",
    RegisteredPopulation: "398",
    VotingStationName: "HLANGANI JUNIOR SECONDARY SCHOOL",
    Address: "HLANGANI LOCATION  TSOLO  TSOLO",
    Latitude: "-31.3806",
    Longitude: "28.7579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3209",
    WardLookupId: "3193",
    VDNumber: "11620921",
    RegisteredPopulation: "305",
    VotingStationName: "METHODIST CHURCH OF SOUTHERN AFRICA",
    Address: "NQADU LOC   QOLOMBANA A/A  TSOLO",
    Latitude: "-31.4514",
    Longitude: "28.7769",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3210",
    WardLookupId: "3193",
    VDNumber: "11620943",
    RegisteredPopulation: "384",
    VotingStationName: "ST. PETERS MHLAKULO PARISH",
    Address: "MNGCELENI LOCATION   GUNGULULU A/A  TSOLO",
    Latitude: "-31.4413",
    Longitude: "28.8349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3211",
    WardLookupId: "3193",
    VDNumber: "11621034",
    RegisteredPopulation: "199",
    VotingStationName: "DUBULINGQANGA  SENIOR PRIMARY SCHOOL",
    Address: "GUNGULULU LOCATION   GUNGULULU A/A   TSOLO",
    Latitude: "-31.4419",
    Longitude: "28.8011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3212",
    WardLookupId: "3194",
    VDNumber: "11620123",
    RegisteredPopulation: "444",
    VotingStationName: "TYENI  JUNIOR SENCONDARY SCHOOL",
    Address: "TYENI LOCATION   TYENI A/A  TSOLO",
    Latitude: "-31.3333",
    Longitude: "28.7126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3213",
    WardLookupId: "3194",
    VDNumber: "11620189",
    RegisteredPopulation: "884",
    VotingStationName: "TIKI-TIKI JUNIOR SECONDARY SCHOOL",
    Address: "TIKI-TIKI A/A  TIKI-TIKI  TSOLO",
    Latitude: "-31.3597",
    Longitude: "28.7281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3214",
    WardLookupId: "3194",
    VDNumber: "11620190",
    RegisteredPopulation: "600",
    VotingStationName: "XABANE JUNIOR SECONDARY  SCHOOL",
    Address: "XABANE LOCATION  XABANE A/A  TSOLO",
    Latitude: "-31.39",
    Longitude: "28.6938",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3215",
    WardLookupId: "3194",
    VDNumber: "11620268",
    RegisteredPopulation: "398",
    VotingStationName: "NGQWALA SENIOR PRIMARY SCHOOL",
    Address: "NGQWALA LOC.  MATYEBA A/A  MTHATHA",
    Latitude: "-31.4495",
    Longitude: "28.7476",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3216",
    WardLookupId: "3194",
    VDNumber: "11620279",
    RegisteredPopulation: "454",
    VotingStationName: "GOBINAMBA JUNIOR SECONDARY SCHOOL",
    Address: "LOWER MJIKA LOCATION   KAMBI 20 B A/A  TSOLO",
    Latitude: "-31.4274",
    Longitude: "28.6882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3217",
    WardLookupId: "3194",
    VDNumber: "11620550",
    RegisteredPopulation: "266",
    VotingStationName: "MADWALENI JUNIOR SECONDARY SCHOOL",
    Address: "MADWALENI LOCATION   TIKITIKI A/A  TSOLO",
    Latitude: "-31.3964",
    Longitude: "28.7245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3218",
    WardLookupId: "3194",
    VDNumber: "11620651",
    RegisteredPopulation: "331",
    VotingStationName: "MMANGONKONE JUNIOR SECONDARY SCHOOL",
    Address: "TYENI LOCATION   TYENI A/A  TSOLO",
    Latitude: "-31.3379",
    Longitude: "28.6919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3219",
    WardLookupId: "3194",
    VDNumber: "11620741",
    RegisteredPopulation: "681",
    VotingStationName: "MATYEBA JUNIOR SECONDARY SCHOOL",
    Address: "MATYEBA A/A  TSOLO  TSOLO",
    Latitude: "-31.4499",
    Longitude: "28.7189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3220",
    WardLookupId: "3195",
    VDNumber: "11550013",
    RegisteredPopulation: "1171",
    VotingStationName: "MHLONTLO MUNICIPAL COMMUNITY HALL",
    Address: "128 MTHUTHUZELI MPEHLE  AVENUE  TSOLO  TSOLO",
    Latitude: "-31.3102",
    Longitude: "28.7588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3221",
    WardLookupId: "3195",
    VDNumber: "11620134",
    RegisteredPopulation: "864",
    VotingStationName: "LOWER GOQWANA JUNIOR SECONDARY SCHOOL",
    Address: "LOWERN GOQWANA  LOCATION   GOQWANA A/A  TSOLO",
    Latitude: "-31.3597",
    Longitude: "28.7677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3222",
    WardLookupId: "3195",
    VDNumber: "11620763",
    RegisteredPopulation: "438",
    VotingStationName: "TP FINCA MEMORIAL CHURCH",
    Address: "CUMMING STREET  TSOLO  TSOLO",
    Latitude: "-31.3095",
    Longitude: "28.7513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3223",
    WardLookupId: "3195",
    VDNumber: "11620808",
    RegisteredPopulation: "1471",
    VotingStationName: "TSOLO RESIDENCE JUNIOR SECONDARY SCHOOL",
    Address: "TSOLO VILLAGE  TSOLO  TSOLO",
    Latitude: "-31.304",
    Longitude: "28.7507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3224",
    WardLookupId: "3196",
    VDNumber: "11620112",
    RegisteredPopulation: "856",
    VotingStationName: "NOMBIZO SENIOR PRIMARY SCHOOL",
    Address: "MDIBANISWENI LOCATION   NTIBANE A/A  TSOLO",
    Latitude: "-31.2818",
    Longitude: "28.7166",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3225",
    WardLookupId: "3196",
    VDNumber: "11620167",
    RegisteredPopulation: "563",
    VotingStationName: "GODZI JUNIOR  SECONDARY SCHOOL",
    Address: "GODZI LOCATION  GODZI A/A  TSOLO",
    Latitude: "-31.305",
    Longitude: "28.7042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3226",
    WardLookupId: "3196",
    VDNumber: "11620505",
    RegisteredPopulation: "394",
    VotingStationName: "NOZIYONGWANA JUNIOR SECONDARY SCHOOL",
    Address: "GUNGWANA LOCATION   GUNGQWANA A/A  TSOLO",
    Latitude: "-31.2461",
    Longitude: "28.7021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3227",
    WardLookupId: "3196",
    VDNumber: "11620538",
    RegisteredPopulation: "788",
    VotingStationName: "MANDELA JUNIOR SECONDARY SCHOOL",
    Address: "NTSHIQO LOCATION  NTSHIQO A/A  TSOLO",
    Latitude: "-31.2708",
    Longitude: "28.6823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3228",
    WardLookupId: "3196",
    VDNumber: "11620583",
    RegisteredPopulation: "763",
    VotingStationName: "ZWELONKE JUNIOR SECONDARY SCHOOL",
    Address: "BELE A/A  MAYALULENI LOC  TSOLO",
    Latitude: "-31.3054",
    Longitude: "28.6771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3229",
    WardLookupId: "3196",
    VDNumber: "11620785",
    RegisteredPopulation: "538",
    VotingStationName: "LUTUKA SENIOR SECONDARY SCHOOL",
    Address: "NTSHIQO LOCATION  TSOLO  NTSHIQO LOCATION",
    Latitude: "-31.273",
    Longitude: "28.6981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3230",
    WardLookupId: "3196",
    VDNumber: "11620796",
    RegisteredPopulation: "409",
    VotingStationName: "TSOLO AGRICULTURE & RURAL DEV INSTITUTION",
    Address: "OCONNER STREET  TSOLO COLLEGE  TSOLO",
    Latitude: "-31.2934",
    Longitude: "28.7632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3231",
    WardLookupId: "3196",
    VDNumber: "11621045",
    RegisteredPopulation: "124",
    VotingStationName: "NTABELANGA SENIOR PRIMARY SCHOOL",
    Address: "NTABELANGA LOC.  TYENI A/A  TSOLO",
    Latitude: "-31.3239",
    Longitude: "28.7355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3232",
    WardLookupId: "3197",
    VDNumber: "11611011",
    RegisteredPopulation: "661",
    VotingStationName: "DILIZINTABA SENIOR SECONDARY SCHOOL",
    Address: "MBUTO LOCATION   MBUTO A/A  TSOLO",
    Latitude: "-31.3143",
    Longitude: "28.8199",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3233",
    WardLookupId: "3197",
    VDNumber: "11620145",
    RegisteredPopulation: "371",
    VotingStationName: "MBOKOTWANA PRIMARY SCHOOL",
    Address: "MBOKOTWANA SCHOOL  MBOKOTWANA A/A  TSOLO",
    Latitude: "-31.2579",
    Longitude: "28.8067",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3234",
    WardLookupId: "3197",
    VDNumber: "11620156",
    RegisteredPopulation: "850",
    VotingStationName: "MCWAKUMBANA SENIOR PRIMARY SCHOOL",
    Address: "QANDA  LOCATION   XHOKONXA A/A  TSOLO",
    Latitude: "-31.2844",
    Longitude: "28.804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3235",
    WardLookupId: "3197",
    VDNumber: "11620392",
    RegisteredPopulation: "393",
    VotingStationName: "QEBEYI SENIOR PRIMARY SCHOOL",
    Address: "QEBEYI LOCATION  MHLABATHI A/A  TSOLO",
    Latitude: "-31.2784",
    Longitude: "28.8374",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3236",
    WardLookupId: "3197",
    VDNumber: "11620527",
    RegisteredPopulation: "474",
    VotingStationName: "DALUKHANYO JUNIOR SECONDARY SCHOOL",
    Address: "MFABANTU LOCATION    MFABANTU A/A  TSOLO",
    Latitude: "-31.2517",
    Longitude: "28.7863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3237",
    WardLookupId: "3197",
    VDNumber: "11620820",
    RegisteredPopulation: "436",
    VotingStationName: "MHLABATHI JUNIOR SECONDARY SCHOOL",
    Address: "MHLABATHI  MHLABATHI LOC.  TSOLO",
    Latitude: "-31.2819",
    Longitude: "28.8714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3238",
    WardLookupId: "3197",
    VDNumber: "11620965",
    RegisteredPopulation: "429",
    VotingStationName: "GOMENI PRIMARY SCHOOL",
    Address: "MBOKOTWANA A/A  GOMENI LOCATION  TSOLO",
    Latitude: "-31.237",
    Longitude: "28.8214",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3239",
    WardLookupId: "3198",
    VDNumber: "11610267",
    RegisteredPopulation: "625",
    VotingStationName: "NOTSWELEBA JUNIOR SECONDARY SCHOOL",
    Address: "NOTSWELEBA LOCATION  SHAWBURY A/A  QUMBU",
    Latitude: "-31.2255",
    Longitude: "28.9368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3240",
    WardLookupId: "3198",
    VDNumber: "11610278",
    RegisteredPopulation: "981",
    VotingStationName: "SHAWBURY JUNIOR SECONDARY SCHOOL",
    Address: "SHAWBURY  SHAWBURY A/A  QUMBU",
    Latitude: "-31.2318",
    Longitude: "28.9581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3241",
    WardLookupId: "3198",
    VDNumber: "11610324",
    RegisteredPopulation: "1029",
    VotingStationName: "LOTANA JUNIOR SECONDARY SCHOOL",
    Address: "LOTANA LOCATION  LOTANA A/A  TSOLO",
    Latitude: "-31.2879",
    Longitude: "28.9266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3242",
    WardLookupId: "3198",
    VDNumber: "11610616",
    RegisteredPopulation: "295",
    VotingStationName: "NGWEMNYAMA PRIMARY  SECONDARY SCHOOL",
    Address: "MNCETYANA LOC.  NGWEMNYAMA A/A  QUMBU",
    Latitude: "-31.2412",
    Longitude: "28.9943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3243",
    WardLookupId: "3198",
    VDNumber: "11610627",
    RegisteredPopulation: "604",
    VotingStationName: "UPPER LOTANA JUNIOR SECONDARY SCHOOL",
    Address: "LOTANA LOC.  LOTANA A/A  QUMBU",
    Latitude: "-31.3173",
    Longitude: "28.8833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3244",
    WardLookupId: "3198",
    VDNumber: "11610638",
    RegisteredPopulation: "534",
    VotingStationName: "NGQUBUSINI JUNIOR SECONDARY SCHOOL",
    Address: "NGQUBUSINI LOC.  LOTANA A/A  QUMBU",
    Latitude: "-31.2755",
    Longitude: "28.9554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3245",
    WardLookupId: "3199",
    VDNumber: "11580465",
    RegisteredPopulation: "377",
    VotingStationName: "NKANTI SENIOR PRIMARY SCHOOL",
    Address: "NKANTI LOCATION  KWAM A/A  LIBODE",
    Latitude: "-31.2904",
    Longitude: "29.0683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3246",
    WardLookupId: "3199",
    VDNumber: "11580555",
    RegisteredPopulation: "654",
    VotingStationName: "NQWILISO SENIOR SECONDARY SCHOOL",
    Address: "NCALOSHE A/A  QUMBU VILLAGE  QUMBU",
    Latitude: "-31.2486",
    Longitude: "29.0607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3247",
    WardLookupId: "3199",
    VDNumber: "11580735",
    RegisteredPopulation: "434",
    VotingStationName: "NTSONYANA SENIOR PRIMARY SCHOOL",
    Address: "MDENI LOCATION  QUMBU  KWAM A/A",
    Latitude: "-31.2764",
    Longitude: "29.0443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3248",
    WardLookupId: "3199",
    VDNumber: "11580937",
    RegisteredPopulation: "411",
    VotingStationName: "DUMBA JUNIOR SECONDARY SCHOOL",
    Address: "DUMBA LOC ATION  QUMBU  NCOLOSHE  A/A",
    Latitude: "-31.2101",
    Longitude: "29.0555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3249",
    WardLookupId: "3199",
    VDNumber: "11581073",
    RegisteredPopulation: "337",
    VotingStationName: "MPANTAKA  METHODIST CHURCH OF SOUTHERN AFRICA",
    Address: "MANZANA LOCATION  QUMBU  MBALISWENI A/A",
    Latitude: "-31.2538",
    Longitude: "29.0791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3250",
    WardLookupId: "3199",
    VDNumber: "11581130",
    RegisteredPopulation: "536",
    VotingStationName: "KWAM JUNIOR SECONDARY SCHOOL",
    Address: "KWAM A/A  EMAGCAKINI LOCATION  LIBODE",
    Latitude: "-31.2735",
    Longitude: "29.0732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3251",
    WardLookupId: "3199",
    VDNumber: "11610289",
    RegisteredPopulation: "592",
    VotingStationName: "GANDANA JUNIOR SECONDARY SCHOOL",
    Address: "GANDANA LOCATION   NGWEMNYAMA A/A  QUMBU",
    Latitude: "-31.2585",
    Longitude: "29.0229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3252",
    WardLookupId: "3199",
    VDNumber: "11820057",
    RegisteredPopulation: "529",
    VotingStationName: "MBOMBO SENIOR PRIMARY  SCHOOL",
    Address: "N/A MBOMBO LOCATION   QUMBU   NCUMBE",
    Latitude: "-31.1623",
    Longitude: "29.0771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3253",
    WardLookupId: "3199",
    VDNumber: "11821441",
    RegisteredPopulation: "109",
    VotingStationName: "DUMEZWENI SENIOR PRIMARY SCHOOL",
    Address: "MADIBA LOCATION   TABANKULU  MBOMBO A/A",
    Latitude: "-31.192",
    Longitude: "29.0382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3254",
    WardLookupId: "3200",
    VDNumber: "11580432",
    RegisteredPopulation: "436",
    VotingStationName: "INDWE JUNIOR SECONDARY SCHOOL",
    Address: "INDWE A/A  LIBODE",
    Latitude: "-31.257",
    Longitude: "29.1472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3255",
    WardLookupId: "3200",
    VDNumber: "11580443",
    RegisteredPopulation: "621",
    VotingStationName: "MBONGWENI JUNIOR SECONDARY SCHOOL",
    Address: "NOGAYA  LOC  MBALISWENI A/A  LIBODE",
    Latitude: "-31.2684",
    Longitude: "29.1157",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3256",
    WardLookupId: "3200",
    VDNumber: "11580713",
    RegisteredPopulation: "465",
    VotingStationName: "MAGUMBINI JUNIOR SECONDARY SCHOOL",
    Address: "TALENI LOCATION  NOGAYA A/A  LIBODE",
    Latitude: "-31.2939",
    Longitude: "29.1427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3257",
    WardLookupId: "3200",
    VDNumber: "11580724",
    RegisteredPopulation: "439",
    VotingStationName: "MTIKA SENIOR PRIMARY SCHOOL",
    Address: "MTIKA LOCATION  INDWE A/A  LIBODE",
    Latitude: "-31.267",
    Longitude: "29.138",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3258",
    WardLookupId: "3200",
    VDNumber: "11820541",
    RegisteredPopulation: "966",
    VotingStationName: "HLABATHI PRIMARY SCHOOL",
    Address: "N/A HLABATHI LOCATION   NGCOLO A/A  TABANKULU",
    Latitude: "-31.1748",
    Longitude: "29.1136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3259",
    WardLookupId: "3200",
    VDNumber: "11820642",
    RegisteredPopulation: "482",
    VotingStationName: "MZIWOXOLO SENIOR PRIMARY SCHOOL",
    Address: "N/A N/A  MAKHOTI A/A  TABANKULU",
    Latitude: "-31.1596",
    Longitude: "29.1566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3260",
    WardLookupId: "3200",
    VDNumber: "11821395",
    RegisteredPopulation: "171",
    VotingStationName: "NDAKANA SCHOOL",
    Address: "EC156 - MHLONTLO [QUMBU]  ",
    Latitude: "-31.2029",
    Longitude: "29.1414",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3261",
    WardLookupId: "3201",
    VDNumber: "11820068",
    RegisteredPopulation: "954",
    VotingStationName: "NCUMBE JUNIOR SECONDARY SCHOOL",
    Address: "N/A NTSHELENI LOCATION   NCUMBE A/A  TABANKULU",
    Latitude: "-31.134",
    Longitude: "29.1143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3262",
    WardLookupId: "3201",
    VDNumber: "11820181",
    RegisteredPopulation: "565",
    VotingStationName: "NGQONGO SENIOR PRIMARY  SCHOOL",
    Address: "N/A NGQONGO LOCATION   MDYOBE A/A  TABANKULU",
    Latitude: "-31.106",
    Longitude: "29.0429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3263",
    WardLookupId: "3201",
    VDNumber: "11820664",
    RegisteredPopulation: "571",
    VotingStationName: "NTSHELENI SENIOR PRIMARY SCHOOL",
    Address: "NCUMBE LOCATION   NCUMBE A/A  NTABANKULU",
    Latitude: "-31.1007",
    Longitude: "29.131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3264",
    WardLookupId: "3201",
    VDNumber: "11821126",
    RegisteredPopulation: "304",
    VotingStationName: "ZWELISILE SENIOR PRIMARY SCHOOL",
    Address: "MDYOBE  LOCATION  MDYOBE LA/A  TABANKULU",
    Latitude: "-31.1325",
    Longitude: "29.0504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3265",
    WardLookupId: "3201",
    VDNumber: "11821294",
    RegisteredPopulation: "579",
    VotingStationName: "JONGIKHAYA PRIMARY SCHOOL",
    Address: "MAGXAMFU LOCATION   NCUMBE A/A  TABANKULU",
    Latitude: "-31.1562",
    Longitude: "29.124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3266",
    WardLookupId: "3201",
    VDNumber: "11821306",
    RegisteredPopulation: "479",
    VotingStationName: "METHODIST CHURCH OF SOUTHERN AFRICA DUMSI CIRCUIRT",
    Address: "BETANI LOCATION   NCUMBE A/A  TABANKULU",
    Latitude: "-31.1244",
    Longitude: "29.0878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3267",
    WardLookupId: "3202",
    VDNumber: "11580454",
    RegisteredPopulation: "197",
    VotingStationName: "MZOBOSHE SENIOR PRIMARY SCHOOL",
    Address: "MPINDWENI LOCATION   MBALISWENI A/A  QUMBU",
    Latitude: "-31.1426",
    Longitude: "29.0227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3268",
    WardLookupId: "3202",
    VDNumber: "11580476",
    RegisteredPopulation: "591",
    VotingStationName: "TINA FALLS JUNIOR SECONDARY SCHOOL",
    Address: "TINA LOCATION  MBALISWENI A/A  QUMBU",
    Latitude: "-31.2195",
    Longitude: "29.0203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3269",
    WardLookupId: "3202",
    VDNumber: "11580487",
    RegisteredPopulation: "856",
    VotingStationName: "GABAZI JUNIOR SECONDARY SCHOOL",
    Address: "GABAZI LOCATION  MBALISWENI A/A  QUMBU",
    Latitude: "-31.1369",
    Longitude: "28.974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3270",
    WardLookupId: "3202",
    VDNumber: "11580544",
    RegisteredPopulation: "481",
    VotingStationName: "MKAMBENI SENIOR PRIMARY SCHOOL",
    Address: "MKHAMBENI LOC.  MBALISWENI A/A  LIBODE",
    Latitude: "-31.1687",
    Longitude: "28.9517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3271",
    WardLookupId: "3202",
    VDNumber: "11580746",
    RegisteredPopulation: "680",
    VotingStationName: "TABASE JUNIOR SECONDARY SCHOOL",
    Address: "TABASE LOCATION   BUHLUNGWANA A/A  LIBODE",
    Latitude: "-31.2043",
    Longitude: "28.9986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3272",
    WardLookupId: "3202",
    VDNumber: "11580926",
    RegisteredPopulation: "285",
    VotingStationName: "LUGONQOZA JUNIOR SECONDARY SCHOOL",
    Address: "GODINI LOCATION   MBALISWENI A/A  QUMBU",
    Latitude: "-31.173",
    Longitude: "29.0042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3273",
    WardLookupId: "3202",
    VDNumber: "11581152",
    RegisteredPopulation: "143",
    VotingStationName: "NAZARETH SENIOR PRIMARY  SCHOOL",
    Address: "MPINDO KA DLANGE   MDYOBE   MDYOBE A/A",
    Latitude: "-31.1591",
    Longitude: "29.033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3274",
    WardLookupId: "3202",
    VDNumber: "11610201",
    RegisteredPopulation: "696",
    VotingStationName: "ENDULINI MORIVIAN CHURCH",
    Address: "NGXAKOLO LOCATION   NGXAKOLO A/A  QUMBU",
    Latitude: "-31.1239",
    Longitude: "28.9172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3275",
    WardLookupId: "3202",
    VDNumber: "11610582",
    RegisteredPopulation: "338",
    VotingStationName: "SIKHOBENI SENIOR PRIMARY SCHOOL",
    Address: "SKOBENI LOC  MBALISWENI A/A  QUMBU",
    Latitude: "-31.1272",
    Longitude: "28.9505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3276",
    WardLookupId: "3203",
    VDNumber: "11610245",
    RegisteredPopulation: "1114",
    VotingStationName: "MVUMELWANO JUNIOR SECONDARY SCHOOL",
    Address: "MVUMELWANO A/A  QUMBU",
    Latitude: "-31.1847",
    Longitude: "28.8832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3277",
    WardLookupId: "3203",
    VDNumber: "11610256",
    RegisteredPopulation: "828",
    VotingStationName: "NDASANA JUNIOR SECONDARY SCHOOL",
    Address: "NDASANA A/A  QUMBU",
    Latitude: "-31.1944",
    Longitude: "28.8528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3278",
    WardLookupId: "3203",
    VDNumber: "11610425",
    RegisteredPopulation: "288",
    VotingStationName: "ZWELANDILE PRIMARY SCHOOL",
    Address: "UPPER ROZA LOCATION   UPPER ROZA A/A  QUMBU",
    Latitude: "-31.2124",
    Longitude: "28.9108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3279",
    WardLookupId: "3203",
    VDNumber: "11610436",
    RegisteredPopulation: "418",
    VotingStationName: "LALENI  PRIMARY SCHOOL",
    Address: "LALENI LOCATION   LALENI A/A  QUMBU",
    Latitude: "-31.2508",
    Longitude: "28.912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3280",
    WardLookupId: "3203",
    VDNumber: "11610447",
    RegisteredPopulation: "600",
    VotingStationName: "LOWER KROZA JUNIOR SECONDARY SCHOOL",
    Address: "LOWER ROZA  LOWER ROZA LOCATION  QUMBU",
    Latitude: "-31.2248",
    Longitude: "28.8563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3281",
    WardLookupId: "3203",
    VDNumber: "11610661",
    RegisteredPopulation: "183",
    VotingStationName: "MAHOBE SENIOR PRIMARY SCHOOL",
    Address: "ZIMBILENI LOCATION   LOWER ROZA A/A  QUMBU",
    Latitude: "-31.2416",
    Longitude: "28.8735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3282",
    WardLookupId: "3203",
    VDNumber: "11610751",
    RegisteredPopulation: "605",
    VotingStationName: "NDWANE JUNIOR SECONDARY SCHOOL",
    Address: "NDWANE LOC.  MVUMELWANO A/A  QUMBU",
    Latitude: "-31.2033",
    Longitude: "28.8775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3283",
    WardLookupId: "3204",
    VDNumber: "11540012",
    RegisteredPopulation: "2515",
    VotingStationName: "QUMBU MUNICIPAL HALL",
    Address: "96CHURCH STREET  QUMBU VILLAGE  QUMBU",
    Latitude: "-31.1644",
    Longitude: "28.8699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3284",
    WardLookupId: "3204",
    VDNumber: "11610414",
    RegisteredPopulation: "668",
    VotingStationName: "MARAMBENI JUNIOR SECONDARY SCHOOL",
    Address: "MARAMBENI LOCATION   BALASI A/A  QUMBU",
    Latitude: "-31.1169",
    Longitude: "28.8532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3285",
    WardLookupId: "3204",
    VDNumber: "11610672",
    RegisteredPopulation: "264",
    VotingStationName: "LUQOLWENI JUNIOR SECONDARY SCHOOL",
    Address: "LUQOLWENI LOC.  MPUMAZE A/A  QUMBU",
    Latitude: "-31.1426",
    Longitude: "28.8591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3286",
    WardLookupId: "3204",
    VDNumber: "11611022",
    RegisteredPopulation: "330",
    VotingStationName: "THEMBALETHU JUNIOR SECONDARY SCHOOL",
    Address: "BALASI A/A  SENKUNZI LOC.  QUMBU",
    Latitude: "-31.1301",
    Longitude: "28.8328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3287",
    WardLookupId: "3205",
    VDNumber: "11610199",
    RegisteredPopulation: "699",
    VotingStationName: "MTONINTSHI SENIOR PRIMARY SCHOOL",
    Address: "MATYHAMINI LOCATION   MATYHAMINI A/A  QUMBU",
    Latitude: "-31.1527",
    Longitude: "28.775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3288",
    WardLookupId: "3205",
    VDNumber: "11610234",
    RegisteredPopulation: "561",
    VotingStationName: "EMJIKWENI JUNIOR SECONDARY SCHOOL",
    Address: "MJIKWENI LOCATION   BALASIA/A  QUMBU",
    Latitude: "-31.2008",
    Longitude: "28.7994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3289",
    WardLookupId: "3205",
    VDNumber: "11610526",
    RegisteredPopulation: "338",
    VotingStationName: "LOWER CULUNCA JUNIOR SECONDARY SCHOOL",
    Address: "CULUNCA LOC.  CULUNCA A/A  QUMBU",
    Latitude: "-31.1444",
    Longitude: "28.7324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3290",
    WardLookupId: "3205",
    VDNumber: "11610683",
    RegisteredPopulation: "659",
    VotingStationName: "LOWER TYIRA JUNIOR SECONDARY SCHOOL",
    Address: "LOWER TYIRA LOC.  LOWER TYIRA A/A  QUMBU",
    Latitude: "-31.1802",
    Longitude: "28.807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3291",
    WardLookupId: "3205",
    VDNumber: "11610694",
    RegisteredPopulation: "474",
    VotingStationName: "KONKABI JUNIOR SECONDARY SCHOOL",
    Address: "KONKABI LOC.  LOWER CULUNCA A/A  QUMBU",
    Latitude: "-31.1794",
    Longitude: "28.7509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3292",
    WardLookupId: "3205",
    VDNumber: "11610841",
    RegisteredPopulation: "668",
    VotingStationName: "TINTWA JUNIOR SECONDARY SCHOOL",
    Address: "DEBEZA LOCATION   CULUNCA A/A  QUMBU",
    Latitude: "-31.1177",
    Longitude: "28.7462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3293",
    WardLookupId: "3206",
    VDNumber: "11610166",
    RegisteredPopulation: "190",
    VotingStationName: "GQUNU JUNIOR SECONDARY SCHOOL",
    Address: "GQUNU LOCATION   GQUNU A/A  QUMBU",
    Latitude: "-31.081",
    Longitude: "28.9361",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3294",
    WardLookupId: "3206",
    VDNumber: "11610177",
    RegisteredPopulation: "817",
    VotingStationName: "MAHLUNGLULU JUNIOR SECONDARY SCHOOL",
    Address: "GUGWINI LOCATION  MAHLUNGULU A/A  QUMBU",
    Latitude: "-31.0732",
    Longitude: "28.834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3295",
    WardLookupId: "3206",
    VDNumber: "11610212",
    RegisteredPopulation: "517",
    VotingStationName: "ST JOHNS GQUNU FARMS",
    Address: "MPUMAZE LOCATION   BALASI A/A  QUMBU",
    Latitude: "-31.1279",
    Longitude: "28.8826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3296",
    WardLookupId: "3206",
    VDNumber: "11610391",
    RegisteredPopulation: "211",
    VotingStationName: "MZUZANTO SENIOR PRIMARY SCHOOL",
    Address: "MZUZANTO LOCATION   NGCOLOKENI A/A  QUMBU",
    Latitude: "-31.0686",
    Longitude: "28.8774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3297",
    WardLookupId: "3206",
    VDNumber: "11610403",
    RegisteredPopulation: "283",
    VotingStationName: "NGCOLOKENI  JUNIOR SECONDARY SCHOOL",
    Address: "NGCOLOKINI LOCATION    UPPER NGCOLOKENI A/A  QUMBU",
    Latitude: "-31.1034",
    Longitude: "28.9082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3298",
    WardLookupId: "3206",
    VDNumber: "11610504",
    RegisteredPopulation: "488",
    VotingStationName: "ZILANDANA JUNIOR SECONDARY SCHOOL",
    Address: "ZILANDANA LOCATION  BALASI A/A  QUMBU",
    Latitude: "-31.1035",
    Longitude: "28.834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3299",
    WardLookupId: "3206",
    VDNumber: "11610807",
    RegisteredPopulation: "384",
    VotingStationName: "LOWER NGCOLOKENI SENIOR PRIMARY SCHOOL",
    Address: "LOWER NGCOLOKENI LOC.  NGCOLOKENI A/A  QUMBU",
    Latitude: "-31.0967",
    Longitude: "28.8853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3300",
    WardLookupId: "3206",
    VDNumber: "11611033",
    RegisteredPopulation: "107",
    VotingStationName: "MADUKDUDA SENIOR PRIMARY SCHOOL",
    Address: "MADUKUDA LOCATION   NGXAKOLO A/A  QUMBU",
    Latitude: "-31.0826",
    Longitude: "28.9687",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3301",
    WardLookupId: "3206",
    VDNumber: "11611044",
    RegisteredPopulation: "543",
    VotingStationName: "UPPER TYIRA JUNIOR SECONDARY SCHOOL",
    Address: "UPPER TYIRA A/A  BLACK HILL LOC.  QUMBU",
    Latitude: "-31.0793",
    Longitude: "28.8088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3302",
    WardLookupId: "3206",
    VDNumber: "11760544",
    RegisteredPopulation: "280",
    VotingStationName: "TSIBIYANA SENIOR PRIMARY  SCHOOL",
    Address: "N/A NKANUNU LOCATION   MHLANGALA A/A  MT. FRERE",
    Latitude: "-31.0413",
    Longitude: "29.0143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3303",
    WardLookupId: "3206",
    VDNumber: "11760746",
    RegisteredPopulation: "75",
    VotingStationName: "MHLANGALA SENIOR PRIMARY SCHOOL",
    Address: "MHLANGALA A/A  MHLANFGALA LLOC  MT.FRERE",
    Latitude: "-31.0897",
    Longitude: "28.9882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3304",
    WardLookupId: "3207",
    VDNumber: "11610100",
    RegisteredPopulation: "892",
    VotingStationName: "ST BARTHS HIGH SCHOOL",
    Address: "MDENI A/A  MDENI LOCATION  QUMBU",
    Latitude: "-31.0242",
    Longitude: "28.8051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3305",
    WardLookupId: "3207",
    VDNumber: "11610380",
    RegisteredPopulation: "492",
    VotingStationName: "GURA JUNIOR SECONDARY SCHOOL",
    Address: "GURA LOCATION   MDENI A/A  QUMBU",
    Latitude: "-31.0214",
    Longitude: "28.8387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3306",
    WardLookupId: "3207",
    VDNumber: "11610605",
    RegisteredPopulation: "478",
    VotingStationName: "ZAMUKULUNGISA SENIOR PRIMARY SCHOOL",
    Address: "MAFUSINI LOC.  GURA A/A  QUMBU",
    Latitude: "-31.0071",
    Longitude: "28.8634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3307",
    WardLookupId: "3207",
    VDNumber: "11610818",
    RegisteredPopulation: "238",
    VotingStationName: "KWA-NGXABANE JUNIOR SECONDARY SCHOOL",
    Address: "KWA-NGXABANE LOC.  COKOMFENI A/A  QUMBU",
    Latitude: "-31.0501",
    Longitude: "28.8028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3308",
    WardLookupId: "3207",
    VDNumber: "11610975",
    RegisteredPopulation: "722",
    VotingStationName: "ESITHANGAMENI JUNIOR SECONDARY SCHOOL",
    Address: "MDENI LOCATION  MDENI A/A  QUMBU",
    Latitude: "-31.0352",
    Longitude: "28.8068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3309",
    WardLookupId: "3207",
    VDNumber: "11610997",
    RegisteredPopulation: "393",
    VotingStationName: "NCOTI JUNIOR SECONDARY SCHOOL",
    Address: "NCOTI LOCATION   MDENI A/A  QUMBU",
    Latitude: "-31.0127",
    Longitude: "28.787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3310",
    WardLookupId: "3208",
    VDNumber: "11610054",
    RegisteredPopulation: "417",
    VotingStationName: "NYWARA PRIMARY SCHOOL",
    Address: "(LWANDLANA LOCATION   NOBAMBA A/A  QUMBU",
    Latitude: "-30.991",
    Longitude: "28.7537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3311",
    WardLookupId: "3208",
    VDNumber: "11610065",
    RegisteredPopulation: "300",
    VotingStationName: "NOTHINTSI SENIOR PRIMARY SCHOOL",
    Address: "NYANISWENI LOCATION   NOBAMBA A/A  QUMBU",
    Latitude: "-30.9615",
    Longitude: "28.8156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3312",
    WardLookupId: "3208",
    VDNumber: "11610357",
    RegisteredPopulation: "248",
    VotingStationName: "MTENGWANE SENIOR SECONDARY SCHOOL",
    Address: "QHANQU A/A  QUMBU",
    Latitude: "-30.9151",
    Longitude: "28.738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3313",
    WardLookupId: "3208",
    VDNumber: "11610368",
    RegisteredPopulation: "420",
    VotingStationName: "NONYIKILA JUNIOR SECONDARY SCHOOL",
    Address: "NONYIKILA LOCATION   MAQHUBINI A/A  QUMBU",
    Latitude: "-30.9614",
    Longitude: "28.7779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3314",
    WardLookupId: "3208",
    VDNumber: "11610559",
    RegisteredPopulation: "113",
    VotingStationName: "MANZOTHUKELA JUNIOR PRIMARY  SCHOOL",
    Address: "MBEZA LOC.  MAQUBINI A/A  QUMBU",
    Latitude: "-30.9231",
    Longitude: "28.8085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3315",
    WardLookupId: "3208",
    VDNumber: "11610560",
    RegisteredPopulation: "212",
    VotingStationName: "MDABUKWENI JUNIOR SECONDARY SCHOOL",
    Address: "MDABUKWENI LOC.  MAQHUBINI A/A  QUMBU",
    Latitude: "-30.9107",
    Longitude: "28.7829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3316",
    WardLookupId: "3208",
    VDNumber: "11610773",
    RegisteredPopulation: "305",
    VotingStationName: "NYANISWENI SENIOR PRIMARY  SCHOOL",
    Address: "NYANISWENI LOC.  NOBAMBA A/A  QUMBU",
    Latitude: "-30.9776",
    Longitude: "28.7994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3317",
    WardLookupId: "3208",
    VDNumber: "11610830",
    RegisteredPopulation: "809",
    VotingStationName: "QUMBU TECHNICAL  SCHOOL",
    Address: "TSILITHWA LOCATION  MAQUBINI A/A  QUMBU",
    Latitude: "-30.9794",
    Longitude: "28.7344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3318",
    WardLookupId: "3208",
    VDNumber: "11610920",
    RegisteredPopulation: "93",
    VotingStationName: "MTONDELA SENIOR PRIMARY SCHOOL",
    Address: "MTONDELA LOC  MAQUBINI A/A  QUMBU",
    Latitude: "-30.9661",
    Longitude: "28.7698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3319",
    WardLookupId: "3208",
    VDNumber: "11610964",
    RegisteredPopulation: "439",
    VotingStationName: "ESKWAYINI SENIOR PRIMARY SCHOOL",
    Address: "SKWAYINI LOCATION   NOBAMBA A/A  QUMBU",
    Latitude: "-30.9681",
    Longitude: "28.8336",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3320",
    WardLookupId: "3208",
    VDNumber: "11611190",
    RegisteredPopulation: "256",
    VotingStationName: "TUKELA SENIOR PRIMARY  SCHOOL",
    Address: "LUQOLWENI LOCATION   MAQUBINI A/A  QUMBU",
    Latitude: "-30.9325",
    Longitude: "28.7335",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3321",
    WardLookupId: "3209",
    VDNumber: "11610010",
    RegisteredPopulation: "240",
    VotingStationName: "MILLANI JUNIOR SECONDARY SCHOOL",
    Address: "LOWER NGXAXA A/A  MILLANI LOCATION  MOUNT FLETCHER",
    Latitude: "-30.839",
    Longitude: "28.6601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3322",
    WardLookupId: "3209",
    VDNumber: "11610021",
    RegisteredPopulation: "429",
    VotingStationName: "ETWA JUNIOR SECONDARY SCHOOL",
    Address: "ETWA LOCATION   MAQUBINI A/A  QUMBU",
    Latitude: "-30.8946",
    Longitude: "28.6829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3323",
    WardLookupId: "3209",
    VDNumber: "11610032",
    RegisteredPopulation: "294",
    VotingStationName: "KUBUSIE JUNIOR SECONDARY SCHOOL",
    Address: "KUBUSIE A/A MTONJENI LOC.QUMBU  KUBUSIE A/A  QUMBU",
    Latitude: "-30.8463",
    Longitude: "28.7112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3324",
    WardLookupId: "3209",
    VDNumber: "11610043",
    RegisteredPopulation: "374",
    VotingStationName: "QOTIRHA PRIMARY  SCHOOL",
    Address: "QOTIRA LOCATION   QOTIRHA A/A  QUMBU",
    Latitude: "-30.9308",
    Longitude: "28.7022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3325",
    WardLookupId: "3209",
    VDNumber: "11610346",
    RegisteredPopulation: "319",
    VotingStationName: "GQWESA JUNIOR SECONDARY SCHOOL",
    Address: "GQWESA LOCATION   GQWESA A/A  QUMBU",
    Latitude: "-30.8901",
    Longitude: "28.7448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3326",
    WardLookupId: "3209",
    VDNumber: "11610379",
    RegisteredPopulation: "398",
    VotingStationName: "XABADIYA SENIOR PRIMARY SCHOOL",
    Address: "XHABADIYA LOC  MAQUBINI A/A  QUMBU",
    Latitude: "-30.9407",
    Longitude: "28.7134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3327",
    WardLookupId: "3209",
    VDNumber: "11610515",
    RegisteredPopulation: "265",
    VotingStationName: "MJIKELWENI JUNIOR SECONDARY SCHOOL",
    Address: "GQWESA A/A  QUMBU",
    Latitude: "-30.9047",
    Longitude: "28.7786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3328",
    WardLookupId: "3209",
    VDNumber: "11610537",
    RegisteredPopulation: "241",
    VotingStationName: "NTIBANE JUNIOR SECONDARY SCHOOL",
    Address: "NGXAXA A/A  NTIBANE LOCATION  MOUNT FLETCHER",
    Latitude: "-30.8087",
    Longitude: "28.6431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3329",
    WardLookupId: "3209",
    VDNumber: "11610548",
    RegisteredPopulation: "145",
    VotingStationName: "MALAMLELA SENIOR PRIMARY SCHOOL",
    Address: "LOWER NXAXA A/A  MOUNT FLETCHER",
    Latitude: "-30.8567",
    Longitude: "28.6593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3330",
    WardLookupId: "3209",
    VDNumber: "11611101",
    RegisteredPopulation: "177",
    VotingStationName: "KAMASTONE SENIOR PRIMARY  SCHOOL",
    Address: "KAMASTONE LOCATION   MAQUBINI A/A  QUMBU",
    Latitude: "-30.9673",
    Longitude: "28.7066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3331",
    WardLookupId: "3209",
    VDNumber: "11611202",
    RegisteredPopulation: "116",
    VotingStationName: "POLANDABA SENIOR PRIMARY SCHOOL",
    Address: "POLANDABA LOCATION   QUMBU  KUBUSI A/A",
    Latitude: "-30.8697",
    Longitude: "28.725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3332",
    WardLookupId: "3209",
    VDNumber: "11611213",
    RegisteredPopulation: "169",
    VotingStationName: "EZIMBENGWENI METHODIST CHURCH",
    Address: "EZIMBENGWENI LOCATION   QUMBU  QOTIRHA A/A",
    Latitude: "-30.9141",
    Longitude: "28.7117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3333",
    WardLookupId: "3210",
    VDNumber: "11610087",
    RegisteredPopulation: "1074",
    VotingStationName: "SULENKAMA SENIOR SECONDARY SCHOOL",
    Address: "SULENKAMA LOCATION  SULENKAMA A/A  QUMBU",
    Latitude: "-31.0159",
    Longitude: "28.6847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3334",
    WardLookupId: "3210",
    VDNumber: "11610098",
    RegisteredPopulation: "600",
    VotingStationName: "NTABASIGOGO CHURCH",
    Address: "NTABASIGOGO LOCATION   SULENKAMA A/A  QUMBU",
    Latitude: "-31.0223",
    Longitude: "28.6569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3335",
    WardLookupId: "3210",
    VDNumber: "11610155",
    RegisteredPopulation: "254",
    VotingStationName: "NYANGO JUNIOR SECONDARY SCHOOL",
    Address: "NYANGO A/A  QUMBU  QUMBU",
    Latitude: "-30.9716",
    Longitude: "28.5944",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3336",
    WardLookupId: "3210",
    VDNumber: "11610706",
    RegisteredPopulation: "138",
    VotingStationName: "MALADINI JUNIOR SECONDARY SCHOOL",
    Address: "MALADINI LOCATION  GQUKUNQA A/A  QUMBU",
    Latitude: "-30.9306",
    Longitude: "28.6062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3337",
    WardLookupId: "3210",
    VDNumber: "11610739",
    RegisteredPopulation: "434",
    VotingStationName: "SANGQU SENIOR PRIMARY  SCHOOL",
    Address: "SANGQU LOC.  SULENKAMA A/A  QUMBU",
    Latitude: "-30.9855",
    Longitude: "28.6745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3338",
    WardLookupId: "3210",
    VDNumber: "11610919",
    RegisteredPopulation: "173",
    VotingStationName: "ST JOHNS APOSTLIC CHURCH",
    Address: "HUKWINI LOCATION   GQUKUNQA A/A  QUMBU",
    Latitude: "-30.9961",
    Longitude: "28.6472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3339",
    WardLookupId: "3210",
    VDNumber: "11610986",
    RegisteredPopulation: "410",
    VotingStationName: "DUMANENI JUNIOR SECONDARY SCHOOL",
    Address: "MTOZELA LOCATION  MTHOZELA A/A  QUMBU",
    Latitude: "-31.0345",
    Longitude: "28.7366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3340",
    WardLookupId: "3210",
    VDNumber: "11611099",
    RegisteredPopulation: "212",
    VotingStationName: "EMPHEHLO PRIMARY SCHOOL",
    Address: "MPEHLO LOCATION  GQUKUNQA A/A  QUMBU",
    Latitude: "-30.9664",
    Longitude: "28.6554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3341",
    WardLookupId: "3210",
    VDNumber: "11611178",
    RegisteredPopulation: "409",
    VotingStationName: "GWADANA ANGLICAN CHURCH",
    Address: "GQUKUNQA A/A  QUMBU",
    Latitude: "-30.9412",
    Longitude: "28.6539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3342",
    WardLookupId: "3211",
    VDNumber: "11610122",
    RegisteredPopulation: "404",
    VotingStationName: "SHUKUNXA JUNIOR SECONDARY SCHOOL",
    Address: "SHUKUNXA A/A  QUMBU",
    Latitude: "-31.0857",
    Longitude: "28.5755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3343",
    WardLookupId: "3211",
    VDNumber: "11610133",
    RegisteredPopulation: "368",
    VotingStationName: "DETYANA JUNIOR SECONDARY SCHOOL",
    Address: "CABA AA  CABA A/A  QUMBU",
    Latitude: "-31.0796",
    Longitude: "28.6662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3344",
    WardLookupId: "3211",
    VDNumber: "11610144",
    RegisteredPopulation: "474",
    VotingStationName: "ZWELIVUMILE SENIOR PRIMARY SCHOOL",
    Address: "UPPER CULUNCA AA  UPPER CULUNCA A/A  QUMBU",
    Latitude: "-31.0951",
    Longitude: "28.7098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3345",
    WardLookupId: "3211",
    VDNumber: "11610470",
    RegisteredPopulation: "249",
    VotingStationName: "CABA METHODIST CHURCH OF SOUTHERN AFRICA",
    Address: "CABA A/A  QUMBU",
    Latitude: "-31.083",
    Longitude: "28.6195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3346",
    WardLookupId: "3211",
    VDNumber: "11610740",
    RegisteredPopulation: "328",
    VotingStationName: "GUNGQWANA JUNIOR SECONDARY SCHOOL",
    Address: "GUNGQWANA LOC.  SULENKAMA A/A  QUMBU",
    Latitude: "-31.0382",
    Longitude: "28.6811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3347",
    WardLookupId: "3211",
    VDNumber: "11610762",
    RegisteredPopulation: "206",
    VotingStationName: "KRANCOLO JUNIOR SECONDARY SCHOOL",
    Address: "KRANCOLO LOC.  CABA A/A  QUMBU",
    Latitude: "-31.0717",
    Longitude: "28.6032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3348",
    WardLookupId: "3211",
    VDNumber: "11610829",
    RegisteredPopulation: "193",
    VotingStationName: "GQUKUNQA JUNIOR SECONDARY SCHOOL",
    Address: "GQUKUNQA A/A  QUMBU",
    Latitude: "-31.0189",
    Longitude: "28.6013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3349",
    WardLookupId: "3211",
    VDNumber: "11610852",
    RegisteredPopulation: "336",
    VotingStationName: "NKONKWENI JUNIOR SECONDARY SCHOOL",
    Address: "NKONKWENI LOC.  NKONKWENI A/A  QUMBU",
    Latitude: "-31.1101",
    Longitude: "28.7139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3350",
    WardLookupId: "3211",
    VDNumber: "11610896",
    RegisteredPopulation: "145",
    VotingStationName: "NGQAKAQENI JUNIOR SECONDARY SCHOOL",
    Address: "SHUKUNXA A/A  QUMBU",
    Latitude: "-31.0957",
    Longitude: "28.5322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3351",
    WardLookupId: "3211",
    VDNumber: "11610942",
    RegisteredPopulation: "300",
    VotingStationName: "BUHLEBELIZWE JUNIOR SECONDARY SCHOOL",
    Address: "MACHIBINI LOCATION   UPPER CULUNCA A/A  QUMBU",
    Latitude: "-31.0628",
    Longitude: "28.7133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3352",
    WardLookupId: "3211",
    VDNumber: "11611112",
    RegisteredPopulation: "151",
    VotingStationName: "LUXENI JUNIOR SECONDARY SCHOOL",
    Address: "GQUKUNQA A/A  LUXENI LOCATION  QUMBU",
    Latitude: "-31.0623",
    Longitude: "28.6135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3353",
    WardLookupId: "3211",
    VDNumber: "11611156",
    RegisteredPopulation: "155",
    VotingStationName: "KWANTABANKULU SENIOR PRIMARY SCHOOL",
    Address: "SIBOMVANENI  LOCATION   CABA A/A  QUMBU",
    Latitude: "-31.0913",
    Longitude: "28.6404",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3354",
    WardLookupId: "3211",
    VDNumber: "11611189",
    RegisteredPopulation: "113",
    VotingStationName: "ENGCOTI ANGLICAN CHURCH",
    Address: "GQUKUNQA A/A  QUMBU",
    Latitude: "-30.9998",
    Longitude: "28.5754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3355",
    WardLookupId: "3212",
    VDNumber: "11610111",
    RegisteredPopulation: "787",
    VotingStationName: "COKOMFENI JUNIOR SECONDARY SCHOOL",
    Address: "COKOMFENI LOCATION   UPPER TYIRA A/A  QUMBU",
    Latitude: "-31.0445",
    Longitude: "28.7536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3356",
    WardLookupId: "3212",
    VDNumber: "11610188",
    RegisteredPopulation: "463",
    VotingStationName: "BELEKENCE JUNIOR SECONDARY SCHOOL",
    Address: "BELEKENCE LOCATION   M/TYIRA A/A  QUMBU",
    Latitude: "-31.0971",
    Longitude: "28.7718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3357",
    WardLookupId: "3212",
    VDNumber: "11610223",
    RegisteredPopulation: "592",
    VotingStationName: "NXANXADI JUNIOR SECONDARY SCHOOL",
    Address: "BAJODINI LOCATION   BALASI A/A  QUMBU",
    Latitude: "-31.1309",
    Longitude: "28.8216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3358",
    WardLookupId: "3212",
    VDNumber: "11610728",
    RegisteredPopulation: "436",
    VotingStationName: "MIDDLE TYIRA JUNIOR SECONDARY SCHOOL",
    Address: "NCITSHWENI LOCATION   MIDDLE TYIRA A/A  QUMBU",
    Latitude: "-31.136",
    Longitude: "28.7895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3359",
    WardLookupId: "3212",
    VDNumber: "11610784",
    RegisteredPopulation: "495",
    VotingStationName: "LINGELIHLE JUNIOR SECONDARY SCHOOL",
    Address: "MMANGWENI LOC.  BALASI A/A  QUMBU",
    Latitude: "-31.1521",
    Longitude: "28.8171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3360",
    WardLookupId: "3212",
    VDNumber: "11610795",
    RegisteredPopulation: "828",
    VotingStationName: "BALASI JUNIOR SECONDARY SCHOOL",
    Address: "BALASI LOC.  BALASI A/A  QUMBU",
    Latitude: "-31.1582",
    Longitude: "28.8316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3361",
    WardLookupId: "3212",
    VDNumber: "11611123",
    RegisteredPopulation: "492",
    VotingStationName: "KANYALANGA SENIOR PRIMARY SCHOOL",
    Address: "LOWER CHULUNCA A/A  MBENTSA LOCATION  QUMBU",
    Latitude: "-31.148",
    Longitude: "28.7706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3362",
    WardLookupId: "3213",
    VDNumber: "11610458",
    RegisteredPopulation: "409",
    VotingStationName: "CEKWAYO JUNIOR SECONDARY SCHOOL",
    Address: "CEKWAYO LOCATION   CULUNCA A/A  QUMBU",
    Latitude: "-31.146",
    Longitude: "28.6922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3363",
    WardLookupId: "3213",
    VDNumber: "11610469",
    RegisteredPopulation: "385",
    VotingStationName: "NXOTWE JUNIOR SECONDARY SCHOOL",
    Address: "NONTYANKASHE LOCATION   NXOTWE A/A  QUMBU",
    Latitude: "-31.1693",
    Longitude: "28.7195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3364",
    WardLookupId: "3213",
    VDNumber: "11620077",
    RegisteredPopulation: "750",
    VotingStationName: "UPPER MALEPE-LEPE SCHOOL",
    Address: "U/MALEPE-LEPE JSS  UPPER MALEPE-LEPE  TSOLO",
    Latitude: "-31.2154",
    Longitude: "28.7056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3365",
    WardLookupId: "3213",
    VDNumber: "11620088",
    RegisteredPopulation: "945",
    VotingStationName: "MAGUTYWA JUNIOR SECONDARY SCHOOL",
    Address: "MAGUTYWA LOCATION   MAGUTYWA A/A  TSOLO",
    Latitude: "-31.2271",
    Longitude: "28.6655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3366",
    WardLookupId: "3213",
    VDNumber: "11620099",
    RegisteredPopulation: "727",
    VotingStationName: "LOWER MALE-PELEPE SENIOR PRIMARY SCHOOL",
    Address: "LOWER MALEPE-LEPELOC.  LOWER MALEPE-LEPE  TSOLO",
    Latitude: "-31.2355",
    Longitude: "28.7315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3367",
    WardLookupId: "3213",
    VDNumber: "11620471",
    RegisteredPopulation: "379",
    VotingStationName: "ZANEBANDLA  JUNIOR SECONDSCHOOL",
    Address: "NDZEBE LOCATION  TSOLO  TSOLO",
    Latitude: "-31.1894",
    Longitude: "28.7004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3368",
    WardLookupId: "3214",
    VDNumber: "11761051",
    RegisteredPopulation: "341",
    VotingStationName: "SIZWE SENIOR PRIMARY SCHOOL",
    Address: "STALENI LOCATION  PAPANE A/A  TABANKULU",
    Latitude: "-31.0214",
    Longitude: "29.0144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3369",
    WardLookupId: "3214",
    VDNumber: "11820079",
    RegisteredPopulation: "636",
    VotingStationName: "BUWA SENIOR  SECONDARY SCHOOL",
    Address: "N/A BUWA LOCATION   BUWA A/A  TABANKULU",
    Latitude: "-31.1039",
    Longitude: "29.0776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3370",
    WardLookupId: "3214",
    VDNumber: "11820192",
    RegisteredPopulation: "1197",
    VotingStationName: "TOLWENI SENIOR SECONDARY SCHOOL",
    Address: "N/A TOLENI LOCATION  TOLENIA/A  TABANKULU",
    Latitude: "-31.0522",
    Longitude: "29.0391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3371",
    WardLookupId: "3214",
    VDNumber: "11820226",
    RegisteredPopulation: "410",
    VotingStationName: "KOLISAVILLE JUNIOR SECONDARY SCHOOL",
    Address: "NOTHANAZA LOCATION   PAPANE A/A  TABANKULU",
    Latitude: "-31.0287",
    Longitude: "29.038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3372",
    WardLookupId: "3214",
    VDNumber: "11820574",
    RegisteredPopulation: "497",
    VotingStationName: "SAMARIA JUNIOR SECONDARY SCHOOL",
    Address: "N/A SAMARIAN LOC.  MDYOBE A/A  TABANKULU",
    Latitude: "-31.0912",
    Longitude: "29.0117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3373",
    WardLookupId: "3214",
    VDNumber: "11820596",
    RegisteredPopulation: "469",
    VotingStationName: "NGWAYIBANJWA SENIOR PRIMARY  SCHOOL",
    Address: "TSOLO LOCATION  PAPANE A/A  NTABANKULU",
    Latitude: "-31.0772",
    Longitude: "29.0857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3374",
    WardLookupId: "3214",
    VDNumber: "11821115",
    RegisteredPopulation: "463",
    VotingStationName: "SIYOYO JUNIOR SECONDARY SCHOOL",
    Address: "BHEJA LOCATION   PAPANE A/A  TABANKULU",
    Latitude: "-31.0434",
    Longitude: "29.0573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3375",
    WardLookupId: "3215",
    VDNumber: "11620415",
    RegisteredPopulation: "716",
    VotingStationName: "MHLAKULO JUNIOR SECONDARY SCHOOL",
    Address: "MHLAKULO LOCATION   GUNGULULU A/A  TSOLO",
    Latitude: "-31.4019",
    Longitude: "28.8442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3376",
    WardLookupId: "3215",
    VDNumber: "11620426",
    RegisteredPopulation: "381",
    VotingStationName: "CINGCO PRIMARY SCHOOL",
    Address: "CINGCO LOCATION   SIDWADWENI A/A  TSOLO",
    Latitude: "-31.3349",
    Longitude: "28.8499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3377",
    WardLookupId: "3215",
    VDNumber: "11620718",
    RegisteredPopulation: "584",
    VotingStationName: "ESIDWADWENI  JUNIOR SECONDARY SCHOOL",
    Address: "SIDWADWENI A/A  SIDWADWENI LOCATION   TSOLO",
    Latitude: "-31.365",
    Longitude: "28.8358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3378",
    WardLookupId: "3215",
    VDNumber: "11620729",
    RegisteredPopulation: "533",
    VotingStationName: "MBUTHO( LEPPAN) JUNIOR SECONDARY SCHOOL",
    Address: "MBUTHO(LEPPAN) A/A  MBUTHO A/A  TSOLO",
    Latitude: "-31.3332",
    Longitude: "28.8174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3379",
    WardLookupId: "3215",
    VDNumber: "11620932",
    RegisteredPopulation: "835",
    VotingStationName: "MCHATU SENIOR PRIMARY SCHOOL",
    Address: "ESIDWADWENI A/A  SIDWADWENI LOCATION  TSOLO",
    Latitude: "-31.391",
    Longitude: "28.8356",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3380",
    WardLookupId: "3215",
    VDNumber: "11620954",
    RegisteredPopulation: "256",
    VotingStationName: "VUKANI KUSILE JUNIOR SECONDARY SCHOOL",
    Address: "LOTANA A/A  TSOLO  GWALI LOCATION",
    Latitude: "-31.318",
    Longitude: "28.8776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3381",
    WardLookupId: "693",
    VDNumber: "11560126",
    RegisteredPopulation: "1967",
    VotingStationName: "IKHWEZI COMMUNITY SENIOR PRIMARY SCHOOL",
    Address: "GERALD SPILKIN STR  IKHWEZI TOWNSHIP  MTHATHA",
    Latitude: "-31.6152",
    Longitude: "28.7981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3382",
    WardLookupId: "693",
    VDNumber: "11560171",
    RegisteredPopulation: "1082",
    VotingStationName: "MT ZION BAPTIST CHURCH",
    Address: "TEMBU ROAD  NGANGELIZWE LOC.  MTHATHA",
    Latitude: "-31.6091",
    Longitude: "28.813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3383",
    WardLookupId: "693",
    VDNumber: "11560182",
    RegisteredPopulation: "1228",
    VotingStationName: "MKATINI SENIOR PRIMARY SCHOOL",
    Address: "1366 MATOLENGWE STREET  NGANGELIZWE LOC  MTHATHA",
    Latitude: "-31.6106",
    Longitude: "28.8108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3384",
    WardLookupId: "693",
    VDNumber: "11630258",
    RegisteredPopulation: "743",
    VotingStationName: "NJEMLA JUNIOR SECONDARY SCHOOL",
    Address: "SILVERTON LOCATION  MPEKO A/A  MTHATHA",
    Latitude: "-31.6183",
    Longitude: "28.8164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3385",
    WardLookupId: "693",
    VDNumber: "11630641",
    RegisteredPopulation: "367",
    VotingStationName: "ORANGE GROOVE JUNIOR SECONDARY SCHOOL",
    Address: "ORANGE GROOVE LOC  QOKOLWENI A/A  MTHATHA",
    Latitude: "-31.6457",
    Longitude: "28.8332",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3386",
    WardLookupId: "694",
    VDNumber: "11560137",
    RegisteredPopulation: "1712",
    VotingStationName: "MORAVIAN CHURCH",
    Address: "GOBODO STREET  IKWEZI TOWNSHIP  MTHATHA",
    Latitude: "-31.6092",
    Longitude: "28.8019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3387",
    WardLookupId: "694",
    VDNumber: "11560159",
    RegisteredPopulation: "2733",
    VotingStationName: "ROTARY HALL",
    Address: "702 TEMBU ROAD  NGANGELIZWE LOC.  MTHATHA",
    Latitude: "-31.6048",
    Longitude: "28.8058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3388",
    WardLookupId: "694",
    VDNumber: "11560205",
    RegisteredPopulation: "1134",
    VotingStationName: "ST PAULS FAITH MISSION",
    Address: "1202 MAVUSO STREET  NGANGELIZWE  MTHATHA",
    Latitude: "-31.6099",
    Longitude: "28.8081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3389",
    WardLookupId: "695",
    VDNumber: "11560047",
    RegisteredPopulation: "1250",
    VotingStationName: "MTHATHA FREE CHURCH",
    Address: "BOUNDARY ROAD  IKHWEZI EXTENSION  MTHATHA",
    Latitude: "-31.6029",
    Longitude: "28.7978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3390",
    WardLookupId: "695",
    VDNumber: "11560193",
    RegisteredPopulation: "2650",
    VotingStationName: "WATERFALL PARK JUNIOR PRIMARY SCHOOL",
    Address: "11390 SOTHO ROAD  WATERFALL T/SHIP  MTHATHA",
    Latitude: "-31.6026",
    Longitude: "28.816",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3391",
    WardLookupId: "695",
    VDNumber: "11560227",
    RegisteredPopulation: "1765",
    VotingStationName: "PRESBYTERIAN CHURCH",
    Address: "TEMBU RD  NGANGELIZWE  MTHATHA",
    Latitude: "-31.6027",
    Longitude: "28.8026",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3392",
    WardLookupId: "696",
    VDNumber: "11560148",
    RegisteredPopulation: "1471",
    VotingStationName: "ELI SPILKIN HALL",
    Address: "MTHUNZI NTSHIKA  MBUQE EXTENSION  MTHATHA",
    Latitude: "-31.6165",
    Longitude: "28.7965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3393",
    WardLookupId: "696",
    VDNumber: "11560418",
    RegisteredPopulation: "1718",
    VotingStationName: "BONGWENI COMMUNITY HALL",
    Address: "BONGWENI VILLAGE  ZIMBANE A/A  KING SABATA DALINDYEBO [MTHATHA",
    Latitude: "-31.6292",
    Longitude: "28.7691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3394",
    WardLookupId: "696",
    VDNumber: "11560429",
    RegisteredPopulation: "891",
    VotingStationName: "AFM OF SA  WELSH ASSEMBLY CHURCH HALL",
    Address: "BONGWENI LOCATION ZIMBANE A/A  MTHATHA",
    Latitude: "-31.6264",
    Longitude: "28.7839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3395",
    WardLookupId: "696",
    VDNumber: "11560520",
    RegisteredPopulation: "815",
    VotingStationName: "MBUQE EXTENSION SENIOR PRIMARY SCHOOL",
    Address: "9 MAYA STREET  MBUQE EXTENSION  MTHATHA",
    Latitude: "-31.6205",
    Longitude: "28.7889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3396",
    WardLookupId: "696",
    VDNumber: "11631776",
    RegisteredPopulation: "2080",
    VotingStationName: "ZIMBANE VALLEY SENIOR PRIMARY SCHOOL",
    Address: "MAIN ROAD  ZIMBANE VALLEY  MTHATHA",
    Latitude: "-31.6157",
    Longitude: "28.7736",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3397",
    WardLookupId: "697",
    VDNumber: "11560216",
    RegisteredPopulation: "3615",
    VotingStationName: "WALTER SISULU UNIVERSITY",
    Address: "NELSON MANDELA DRIVE  N2- EAST LONDON ROAD  MTHATHA",
    Latitude: "-31.6034",
    Longitude: "28.7504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3398",
    WardLookupId: "697",
    VDNumber: "11560238",
    RegisteredPopulation: "1733",
    VotingStationName: "S.O.S CHILDRENS VILLAGE",
    Address: "SIDWADWA PARK  SOUTH RIDGE  MTHATHA",
    Latitude: "-31.6221",
    Longitude: "28.7448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3399",
    WardLookupId: "697",
    VDNumber: "11560249",
    RegisteredPopulation: "675",
    VotingStationName: "HOLY CROSS SENIOR SECONDARY SCHOOL",
    Address:
      "CHIEF JOJO DRIVE  SOUTH RIDGE PARK  KING SABATA DALINDYEBO [UMTATA]",
    Latitude: "-31.6102",
    Longitude: "28.7441",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3400",
    WardLookupId: "697",
    VDNumber: "11560508",
    RegisteredPopulation: "973",
    VotingStationName: "TENT AT UPPER CHRIS HANI PLAYGROUND",
    Address: "UPPER CHRIS HANI  MTHATHA",
    Latitude: "-31.5968",
    Longitude: "28.7369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3401",
    WardLookupId: "697",
    VDNumber: "11560597",
    RegisteredPopulation: "174",
    VotingStationName: "SOUTHERNWOOD EXT 46 TENT",
    Address: "SEAGULL STREET  SOUTHERNWOOD  MTHATHA",
    Latitude: "-31.6103",
    Longitude: "28.7635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3402",
    WardLookupId: "698",
    VDNumber: "11560081",
    RegisteredPopulation: "2344",
    VotingStationName: "TENT AT MTHATHA GENERAL HOSPITAL GROUNDS",
    Address: "N2 MANDELA DRIVE  ROOSTE AVENUE  MTHATHA",
    Latitude: "-31.5913",
    Longitude: "28.7663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3403",
    WardLookupId: "698",
    VDNumber: "11560104",
    RegisteredPopulation: "917",
    VotingStationName: "SOUTHERNWOOD JUNIOR SECONDARY SCHOOL",
    Address: "SWALLOW CRESCENT  SOUTHERNWOOD  MTHATHA",
    Latitude: "-31.602",
    Longitude: "28.7632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3404",
    WardLookupId: "698",
    VDNumber: "11560474",
    RegisteredPopulation: "745",
    VotingStationName: "TRANSKEI JUNIOR PRIMARY SCHOOL",
    Address: "VICTORIA STREET  MTHATHA  MTHATHA",
    Latitude: "-31.5921",
    Longitude: "28.7812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3405",
    WardLookupId: "698",
    VDNumber: "11560485",
    RegisteredPopulation: "816",
    VotingStationName: "SOUTHERNWOOD COMMUNITY HALL",
    Address: "FLAMINGO DRIVE    SOUTHERNWOOD  MTHATHA",
    Latitude: "-31.607",
    Longitude: "28.7691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3406",
    WardLookupId: "699",
    VDNumber: "11560014",
    RegisteredPopulation: "3212",
    VotingStationName: "MTHATHA CITY HALL",
    Address: "CNR LEEDS & OWEN ST  MTHATHA  MTHATHA",
    Latitude: "-31.5899",
    Longitude: "28.7878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3407",
    WardLookupId: "699",
    VDNumber: "11560092",
    RegisteredPopulation: "654",
    VotingStationName: "MBUQE JUNIOR SECONDARY SCHOOL",
    Address: "JOZANA STREET  MBUQE PARK  MTHATHA",
    Latitude: "-31.6067",
    Longitude: "28.7909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3408",
    WardLookupId: "700",
    VDNumber: "11560036",
    RegisteredPopulation: "1366",
    VotingStationName: "NORWOOD JUNIOR SECONDARY SCHOOL",
    Address: "61 SECOND AVENUE  NORWOOD  MTHATHA",
    Latitude: "-31.5775",
    Longitude: "28.7897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3409",
    WardLookupId: "700",
    VDNumber: "11560069",
    RegisteredPopulation: "592",
    VotingStationName: "UMTATA COMMUNITY SCHOOL",
    Address: "JEJANE STR.  POLICE CAMP  MTHATHA",
    Latitude: "-31.5806",
    Longitude: "28.7752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3410",
    WardLookupId: "700",
    VDNumber: "11560115",
    RegisteredPopulation: "1066",
    VotingStationName: "NORTHCREST ASSEMBLY OF GOD",
    Address: "JOHN BEER DRIVE  NORTHCREST  MTHATHA",
    Latitude: "-31.5641",
    Longitude: "28.784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3411",
    WardLookupId: "700",
    VDNumber: "11560250",
    RegisteredPopulation: "1076",
    VotingStationName: "POLAR PARK JUNIOR SECONDARY SCHOOL",
    Address: "POLAR PARK  MTHATHA",
    Latitude: "-31.5759",
    Longitude: "28.7614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3412",
    WardLookupId: "700",
    VDNumber: "11560340",
    RegisteredPopulation: "322",
    VotingStationName: "WELLINGTON PRISON",
    Address: "WELLINGTON PRISON HALL  WELLINGTON FARM  MTHATHA",
    Latitude: "-31.5702",
    Longitude: "28.7748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3413",
    WardLookupId: "700",
    VDNumber: "11560407",
    RegisteredPopulation: "500",
    VotingStationName: "NORWOOD  PRE SCHOOL",
    Address: "3RD AVENUE  NORWOOD  MTHATHA",
    Latitude: "-31.5809",
    Longitude: "28.7873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3414",
    WardLookupId: "701",
    VDNumber: "11560025",
    RegisteredPopulation: "964",
    VotingStationName: "ATTWELL MADALA SENIOR SECONDARY SCHOOL",
    Address: "N2 NATIONAL ROAD  HILLCREST  MTHATHA",
    Latitude: "-31.5701",
    Longitude: "28.7956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3415",
    WardLookupId: "701",
    VDNumber: "11560283",
    RegisteredPopulation: "709",
    VotingStationName: "LOVE LIFE CENTRE",
    Address: "WATERFALL ROAD  NGANGELIZWE LOCATION  MTHATHA",
    Latitude: "-31.5993",
    Longitude: "28.8014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3416",
    WardLookupId: "701",
    VDNumber: "11560294",
    RegisteredPopulation: "1094",
    VotingStationName: "ST JOHNS COLLEGE OF EDUCATION",
    Address: "CALLAWAY ST.  MTHATHA  MTHATHA",
    Latitude: "-31.5845",
    Longitude: "28.7943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3417",
    WardLookupId: "701",
    VDNumber: "11560441",
    RegisteredPopulation: "909",
    VotingStationName: "E.W.PEARCE SENIOR PRIMARY SCHOOL",
    Address: "N2 DURBAN ROAD  NCAMBEDLANA  MTHATHA",
    Latitude: "-31.5649",
    Longitude: "28.7969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3418",
    WardLookupId: "701",
    VDNumber: "11560452",
    RegisteredPopulation: "2032",
    VotingStationName: "ESETHU SENIOR PRIMARY SCHOOL",
    Address: "MAYDEN FARM  MTHATHA",
    Latitude: "-31.5788",
    Longitude: "28.8116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3419",
    WardLookupId: "701",
    VDNumber: "11560463",
    RegisteredPopulation: "319",
    VotingStationName: "RIVERSIDE PRIVATE SCHOOL",
    Address: "NEW BRIGHT TOWNSHIP  MTHATHA",
    Latitude: "-31.5932",
    Longitude: "28.7963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3420",
    WardLookupId: "701",
    VDNumber: "11560609",
    RegisteredPopulation: "460",
    VotingStationName: "WATER FALL PHASE FOUR TENT",
    Address: "WATERFALL PARK  MTHATHA",
    Latitude: "-31.5956",
    Longitude: "28.8123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3421",
    WardLookupId: "702",
    VDNumber: "11580094",
    RegisteredPopulation: "581",
    VotingStationName: "GXIDIDI JUNIOR SECONDARY SCHOOL",
    Address: "GXIDIDI LOC  GXIDIDI A/A  UMTATA",
    Latitude: "-31.4923",
    Longitude: "28.799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3422",
    WardLookupId: "702",
    VDNumber: "11580522",
    RegisteredPopulation: "446",
    VotingStationName: "NCAMBELE JUNIOR SECONDARY SCHOOL",
    Address: "NCAMBELE LOC  NCAMBELE A/A  MTHATHA",
    Latitude: "-31.502",
    Longitude: "28.7262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3423",
    WardLookupId: "702",
    VDNumber: "11581051",
    RegisteredPopulation: "600",
    VotingStationName: "KWATHEMBA STORE.",
    Address: "KWADUNGANA/MPINDWENI LOCATION  GXIDIDI A/A  MTHATHA",
    Latitude: "-31.512",
    Longitude: "28.7815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3424",
    WardLookupId: "702",
    VDNumber: "11581062",
    RegisteredPopulation: "309",
    VotingStationName: "AFRICAN GOSPEL CHURCH HALL",
    Address: "GXIDIDI LOC  GXIDIDI A/A  MTHATHA",
    Latitude: "-31.4992",
    Longitude: "28.7855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3425",
    WardLookupId: "702",
    VDNumber: "11620730",
    RegisteredPopulation: "463",
    VotingStationName: "THEMBELANI SENIOR  PRIMARY SCHOOL",
    Address: "NCAMBELE LOCATION  NCANBELE A/A  MTHATHA",
    Latitude: "-31.5056",
    Longitude: "28.7455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3426",
    WardLookupId: "702",
    VDNumber: "11630034",
    RegisteredPopulation: "401",
    VotingStationName: "MATSHONGWE JUNIOR SECONDARY SCHOOL",
    Address: "MATSHONGWE LOC  KAMBI A/A  MTHATHA",
    Latitude: "-31.5126",
    Longitude: "28.6587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3427",
    WardLookupId: "702",
    VDNumber: "11630045",
    RegisteredPopulation: "599",
    VotingStationName: "NCISE JUNIOR SECONDARY SCHOOL",
    Address: "NCISE  LOC.  NCISE A/A  MTHATHA",
    Latitude: "-31.5367",
    Longitude: "28.689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3428",
    WardLookupId: "702",
    VDNumber: "11630056",
    RegisteredPopulation: "836",
    VotingStationName: "NTSHABENI JUNIOR SECONDARY SCHOOL",
    Address: "NTSHABENI LOC.  NCISE A/A  MTHATHA",
    Latitude: "-31.5354",
    Longitude: "28.7111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3429",
    WardLookupId: "702",
    VDNumber: "11630180",
    RegisteredPopulation: "482",
    VotingStationName: "ROSEDALE JUNIOR SECONDARY SCHOOL",
    Address: "ROSEDALE LOC.  MPEKO A/A  MTHATHA",
    Latitude: "-31.5238",
    Longitude: "28.7547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3430",
    WardLookupId: "702",
    VDNumber: "11631541",
    RegisteredPopulation: "278",
    VotingStationName: "GOBIZIZWE SENIOR PRIMARY SCHOOL",
    Address: "NCISE LOCATION  NCISE ADMIN AREA  MTHATHA",
    Latitude: "-31.5341",
    Longitude: "28.6716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3431",
    WardLookupId: "702",
    VDNumber: "11631552",
    RegisteredPopulation: "923",
    VotingStationName: "MZIWODUMO JUNIOR SECONDARY SCHOOL",
    Address: "PHOLAND LOCATION  NCISE A/A  MTHATHA",
    Latitude: "-31.5517",
    Longitude: "28.7248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3432",
    WardLookupId: "702",
    VDNumber: "11631574",
    RegisteredPopulation: "258",
    VotingStationName: "NTSHABENI METHODIST CHURCH",
    Address: "NTSHABENI LOCATION  NCISE A/A  UMTATA",
    Latitude: "-31.5373",
    Longitude: "28.6949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3433",
    WardLookupId: "703",
    VDNumber: "11560317",
    RegisteredPopulation: "1884",
    VotingStationName: "CHRIS HANI PRE-SCHOOL",
    Address: "CHRIS HANI PARK  MTHATHA",
    Latitude: "-31.5771",
    Longitude: "28.7236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3434",
    WardLookupId: "703",
    VDNumber: "11560328",
    RegisteredPopulation: "2067",
    VotingStationName: "LAPHUMIKHWEZI SENIOR SECONDARY SCHOOL",
    Address: "MANDELA PARK  MTHATHA",
    Latitude: "-31.5889",
    Longitude: "28.7408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3435",
    WardLookupId: "703",
    VDNumber: "11560519",
    RegisteredPopulation: "1972",
    VotingStationName: "MASIPHOLENI PRE-PLAY SCHOOL",
    Address: "CNR MGWIGWI & MANDELA PROAD  MANDELA PARK  MTHATHA",
    Latitude: "-31.5737",
    Longitude: "28.7445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3436",
    WardLookupId: "703",
    VDNumber: "11560564",
    RegisteredPopulation: "602",
    VotingStationName: "CHRIS HANI DAY CARE CENTRE",
    Address: "BEDFORD ROAD  CHRIS HANI  MTHATHA",
    Latitude: "-31.5772",
    Longitude: "28.7143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3437",
    WardLookupId: "703",
    VDNumber: "11560575",
    RegisteredPopulation: "319",
    VotingStationName: "CHRIS HANI COMMUNITY CENTRE",
    Address: "ZWELANDILE STREET  CHRIS HANI  MTHATHA",
    Latitude: "-31.5879",
    Longitude: "28.7285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3438",
    WardLookupId: "703",
    VDNumber: "11560586",
    RegisteredPopulation: "348",
    VotingStationName: "MANDELA METHODIST CHURCH",
    Address: "CNR R61 AND ULTRA CITY ROADS  MANDELA PARK  MTHATHA",
    Latitude: "-31.5746",
    Longitude: "28.73",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3439",
    WardLookupId: "704",
    VDNumber: "11560261",
    RegisteredPopulation: "3476",
    VotingStationName: "MANDELA PARK SENIOR PRIMARY SCHOOL (SLOVO HALL)",
    Address: "R61 QUEENSTOWN ROAD  SLOVO PARK  MTHATHA",
    Latitude: "-31.5721",
    Longitude: "28.7431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3440",
    WardLookupId: "704",
    VDNumber: "11560496",
    RegisteredPopulation: "450",
    VotingStationName: "EFATA SCHOOL FOR THE BLIND",
    Address: "R61 QUEENSTOWN ROAD  MTHATHA  UMTATA",
    Latitude: "-31.5696",
    Longitude: "28.7197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3441",
    WardLookupId: "704",
    VDNumber: "11560553",
    RegisteredPopulation: "1009",
    VotingStationName: "BAMBANANI PRIMARY SCHOOL",
    Address: "GEYITHINI SCHOOL  SLOVO PARK  MTHATHA",
    Latitude: "-31.564",
    Longitude: "28.7347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3442",
    WardLookupId: "704",
    VDNumber: "11630247",
    RegisteredPopulation: "1802",
    VotingStationName: "FAIRFIELD JUNIOR SECONDARY SCHOOL",
    Address: "KWADOSI LOC.  MPEKO A/A  MTHATHA",
    Latitude: "-31.5603",
    Longitude: "28.701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3443",
    WardLookupId: "705",
    VDNumber: "11560058",
    RegisteredPopulation: "1515",
    VotingStationName: "JAMES KOBI JUNIOR SECONDARY SCHOOL",
    Address: "18 JOHN BEER DRIVE  MTHATHA  MTHATHA",
    Latitude: "-31.5652",
    Longitude: "28.7915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3444",
    WardLookupId: "705",
    VDNumber: "11560070",
    RegisteredPopulation: "922",
    VotingStationName: "GLADYS KING SKILLS CENTRE",
    Address: "29 SAKWE STR.  MTHATHA",
    Latitude: "-31.5543",
    Longitude: "28.7802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3445",
    WardLookupId: "705",
    VDNumber: "11560373",
    RegisteredPopulation: "722",
    VotingStationName: "COAL & CUBA STREETS (TENT)",
    Address: "NCAMBEDLANA PHASE 1  MTHATHA",
    Latitude: "-31.551",
    Longitude: "28.7853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3446",
    WardLookupId: "705",
    VDNumber: "11580072",
    RegisteredPopulation: "565",
    VotingStationName: "PRESBYTERIAN CHURCH HALL",
    Address: "MPINDWENI LOCATION  NCAMBELE A/A  MTHATHA",
    Latitude: "-31.5334",
    Longitude: "28.7718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3447",
    WardLookupId: "705",
    VDNumber: "11580083",
    RegisteredPopulation: "722",
    VotingStationName: "MPINDWENI JUNIOR SECONDARY SCHOOL",
    Address: "MPINDWENI LOC  NCAMBELE A/A  MTHATHA",
    Latitude: "-31.5205",
    Longitude: "28.7713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3448",
    WardLookupId: "705",
    VDNumber: "11631596",
    RegisteredPopulation: "557",
    VotingStationName: "HIGHBURY JUNIOR SECONDARY SCHOOL",
    Address: "HIGHBURY LOCATION  MTHATHA",
    Latitude: "-31.5438",
    Longitude: "28.7545",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3449",
    WardLookupId: "705",
    VDNumber: "11631934",
    RegisteredPopulation: "175",
    VotingStationName: "HIGHBURY EXTENSION SUBHEADMAN HOUSE",
    Address: "MTHATHA PASSERBY ROAD  HIGHBURY EXTENSION  MTHATHA",
    Latitude: "-31.5592",
    Longitude: "28.7732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3450",
    WardLookupId: "706",
    VDNumber: "11560542",
    RegisteredPopulation: "233",
    VotingStationName: "KWA PAYNE KANYISA JUNIOR PRIMARY SCHOOL",
    Address: "BEHIND ULTRA CITY  PAYNE A/A  MTHATHA",
    Latitude: "-31.6133",
    Longitude: "28.7307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3451",
    WardLookupId: "706",
    VDNumber: "11630157",
    RegisteredPopulation: "464",
    VotingStationName: "ZANGQELE JUNIOR SECONDARY SCHOOL",
    Address: "KAPLAN LOC.  MPEKO A/A  MTHATHA",
    Latitude: "-31.5768",
    Longitude: "28.6868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3452",
    WardLookupId: "706",
    VDNumber: "11630203",
    RegisteredPopulation: "850",
    VotingStationName: "TABASE JUNIOR SECONDARY SCHOOL",
    Address: "TABASE LOC  TABASE A/A  MTHATHA",
    Latitude: "-31.5697",
    Longitude: "28.5734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3453",
    WardLookupId: "706",
    VDNumber: "11630225",
    RegisteredPopulation: "474",
    VotingStationName: "LANDSEND JUNIOR SECONDARY SCHOOL",
    Address: "DLOMO LOCATION  DLOMO A/A  UMTATA",
    Latitude: "-31.5744",
    Longitude: "28.6288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3454",
    WardLookupId: "706",
    VDNumber: "11630786",
    RegisteredPopulation: "491",
    VotingStationName: "ROSS JUNIOR SECONDARY SCHOOL",
    Address: "ROSS MISSION  NCISE A/A  MTHATHA",
    Latitude: "-31.5625",
    Longitude: "28.6126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3455",
    WardLookupId: "706",
    VDNumber: "11630898",
    RegisteredPopulation: "361",
    VotingStationName: "EAGERTON (TSHEMESE) JUNIOR SECONDARY SCHOOL",
    Address: "TSHEMESE LOC.  MPEKO A/A  MTHATHA",
    Latitude: "-31.5961",
    Longitude: "28.6786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3456",
    WardLookupId: "706",
    VDNumber: "11630900",
    RegisteredPopulation: "799",
    VotingStationName: "MPEKO JUNIOR SECONDARY SCHOOL",
    Address: "MPEKO LOC.  MPEKO A/A  UMTATA",
    Latitude: "-31.6212",
    Longitude: "28.6407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3457",
    WardLookupId: "706",
    VDNumber: "11631428",
    RegisteredPopulation: "330",
    VotingStationName: "JONGINGWE JUNIOR SECONDARY SCHOOL",
    Address: "NTABENI LOCATION  MPEKO A/A  MTHATHA",
    Latitude: "-31.6047",
    Longitude: "28.6049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3458",
    WardLookupId: "706",
    VDNumber: "11631495",
    RegisteredPopulation: "800",
    VotingStationName: "NGUBESIZWE SENIOR SECONDARY SCHOOL",
    Address: "LINDILE LOCATION  LINDILE A/A  MTHATHA",
    Latitude: "-31.6068",
    Longitude: "28.7104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3459",
    WardLookupId: "706",
    VDNumber: "11631585",
    RegisteredPopulation: "420",
    VotingStationName: "KAPLAN ANGLICAN CHURCH HALL",
    Address: "KAPLAN LOCATION  MPEKO A/A  UMTATA",
    Latitude: "-31.5726",
    Longitude: "28.6797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3460",
    WardLookupId: "707",
    VDNumber: "11630102",
    RegisteredPopulation: "388",
    VotingStationName: "MPUTI JUNIOR SECONDARY SCHOOL",
    Address: "MPUTI LOC.  MPUTI A/A  MTHATHA",
    Latitude: "-31.6449",
    Longitude: "28.3526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3461",
    WardLookupId: "707",
    VDNumber: "11630113",
    RegisteredPopulation: "809",
    VotingStationName: "SIXUZULU JUNIOR SECONDARY SCHOOL",
    Address: "SIXUZULU LOC.  BAZIYA T/A  MTHATHA",
    Latitude: "-31.6074",
    Longitude: "28.3383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3462",
    WardLookupId: "707",
    VDNumber: "11630124",
    RegisteredPopulation: "599",
    VotingStationName: "MAKAULA JUNIOR SECONDARY SCHOOL",
    Address: "MAKAULA LOC.  BAZIYA A/A  MTHATHA",
    Latitude: "-31.6165",
    Longitude: "28.4359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3463",
    WardLookupId: "707",
    VDNumber: "11630348",
    RegisteredPopulation: "313",
    VotingStationName: "BAZIYA JUNIOR SECONDARY SCHOOL",
    Address: "BAZIYA LOC.  BAZIYA A/A  MTHATHA",
    Latitude: "-31.6125",
    Longitude: "28.3831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3464",
    WardLookupId: "707",
    VDNumber: "11630359",
    RegisteredPopulation: "347",
    VotingStationName: "MBOZWANA JUNIOR SECONDARY SCHOOL",
    Address: "MBOZWANA LOC.  XHONGORA T/A  MTHATHA",
    Latitude: "-31.6511",
    Longitude: "28.4935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3465",
    WardLookupId: "707",
    VDNumber: "11630562",
    RegisteredPopulation: "445",
    VotingStationName: "NTLUKUHLA JUNIOR SECONDARY SCHOOL",
    Address: "BAZIYA LOC.  BAZIYA A/A  MTHATHA",
    Latitude: "-31.5941",
    Longitude: "28.4237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3466",
    WardLookupId: "707",
    VDNumber: "11630607",
    RegisteredPopulation: "545",
    VotingStationName: "GOBINAMBA JUNIOR SECONDARY SCHOOL.",
    Address: "MPUTI LOC.  BAZIYA  A/A  MTHATHA",
    Latitude: "-31.6546",
    Longitude: "28.3451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3467",
    WardLookupId: "707",
    VDNumber: "11630911",
    RegisteredPopulation: "608",
    VotingStationName: "MBOZISA (UMTATA) JUNIOR SECONDARY SCHOOL",
    Address: "MBOZISA LOC.  MBOZISA A/A  MTHATHA",
    Latitude: "-31.5873",
    Longitude: "28.4566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3468",
    WardLookupId: "707",
    VDNumber: "11630933",
    RegisteredPopulation: "228",
    VotingStationName: "KUYASA JUNIOR SECONDARY SCHOOL",
    Address: "EMMANGWENI LOC  BAZIYA A/A  MTHATHA",
    Latitude: "-31.6419",
    Longitude: "28.3807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3469",
    WardLookupId: "707",
    VDNumber: "11631079",
    RegisteredPopulation: "178",
    VotingStationName: "MPETSHENI JUNIOR SECONDARY SCHOOL",
    Address: "MDENI LOCATION  BAZIYA A/A  MTHATHA",
    Latitude: "-31.6909",
    Longitude: "28.4009",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3470",
    WardLookupId: "707",
    VDNumber: "11631372",
    RegisteredPopulation: "560",
    VotingStationName: "SIGUBUDU JUNIOR SECONDARY SCHOOL",
    Address: "SIGUBUDU LOC  BAZIYA  A/A  MTHATHA",
    Latitude: "-31.625",
    Longitude: "28.4878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3471",
    WardLookupId: "708",
    VDNumber: "11630023",
    RegisteredPopulation: "747",
    VotingStationName: "KAMBI JUNIOR SECONDARY SCHOOL",
    Address: "KAMBI LOC.  NCISE A/A  MTHATHA",
    Latitude: "-31.4858",
    Longitude: "28.6574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3472",
    WardLookupId: "708",
    VDNumber: "11630067",
    RegisteredPopulation: "628",
    VotingStationName: "DIKISHE JUNIOR SECONDARY SCHOOL",
    Address: "DIKISHE LOC  NGCENGANE A/A  MTHATHA",
    Latitude: "-31.5197",
    Longitude: "28.6106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3473",
    WardLookupId: "708",
    VDNumber: "11630078",
    RegisteredPopulation: "984",
    VotingStationName: "NGANGENYATHI SENIOR PRIMARY SCHOOL",
    Address: "NGCENGANE LOC.  NCISE T/A  UMTATA",
    Latitude: "-31.5423",
    Longitude: "28.6171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3474",
    WardLookupId: "708",
    VDNumber: "11630887",
    RegisteredPopulation: "473",
    VotingStationName: "MANDLENI JUNIOR SECONDARY SCHOOL",
    Address: "MANDLENI LOC.  TABASE A/A  UMTATA",
    Latitude: "-31.5218",
    Longitude: "28.5552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3475",
    WardLookupId: "708",
    VDNumber: "11630966",
    RegisteredPopulation: "747",
    VotingStationName: "LWANDLANA SENIOR PRIMARY SCHOOL",
    Address: "LWANDLANA LOC  NCISE A/A  MTHATHA",
    Latitude: "-31.5011",
    Longitude: "28.5561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3476",
    WardLookupId: "708",
    VDNumber: "11630977",
    RegisteredPopulation: "328",
    VotingStationName: "GADUKA JUNIOR SECONDARY SCHOOL",
    Address: "PHESHEYA KWETABASE /GADUKA LOC  NCISE A/A  UMTATA",
    Latitude: "-31.4905",
    Longitude: "28.5916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3477",
    WardLookupId: "708",
    VDNumber: "11631002",
    RegisteredPopulation: "397",
    VotingStationName: "LUSAKA METHODIST CHURCH HALL",
    Address: "TAKA LOCATION  NGCINGANE A/A  MTHATHA",
    Latitude: "-31.5395",
    Longitude: "28.6053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3478",
    WardLookupId: "708",
    VDNumber: "11631507",
    RegisteredPopulation: "424",
    VotingStationName: "ASHTON NKALA SENIOR PRIMARY SCHOOL",
    Address: "NONGCWENGANA LOCATION  KHAMBI A/A  MTHATHA",
    Latitude: "-31.4946",
    Longitude: "28.644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3479",
    WardLookupId: "708",
    VDNumber: "11631529",
    RegisteredPopulation: "535",
    VotingStationName: "ZANOKHANYO JUNIOR SECONDARY SCHOOL",
    Address: "KUMATIYANE/KUGCALEKA LOCATION  KHAMBI A/A  MTHATHA",
    Latitude: "-31.4835",
    Longitude: "28.6763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3480",
    WardLookupId: "708",
    VDNumber: "11631530",
    RegisteredPopulation: "460",
    VotingStationName: "CANDILANGA SENIOR PRIMARY SCHOOL",
    Address: "LUXENI LOCATION  NGCISE A/A  MTHATHA",
    Latitude: "-31.4978",
    Longitude: "28.6665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3481",
    WardLookupId: "709",
    VDNumber: "11630135",
    RegisteredPopulation: "371",
    VotingStationName: "KWEZILETHU SENIOR PRIMARY SCHOOL",
    Address: "MATAMBO LOC.  BAZIYA  A/A  MTHATHA",
    Latitude: "-31.6498",
    Longitude: "28.4543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3482",
    WardLookupId: "709",
    VDNumber: "11630416",
    RegisteredPopulation: "512",
    VotingStationName: "XHONGORA TRIBAL AUTHORITY",
    Address: "XHONGORA LOC  XHONGORA TRIBAL AUTHORITY  MTHATHA",
    Latitude: "-31.7187",
    Longitude: "28.44",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3483",
    WardLookupId: "709",
    VDNumber: "11630427",
    RegisteredPopulation: "602",
    VotingStationName: "KONQENI JUNIOR SECONDARY SCHOOL",
    Address: "KONQENI LOC.  XONGORA A/A  MTHATHA",
    Latitude: "-31.7082",
    Longitude: "28.463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3484",
    WardLookupId: "709",
    VDNumber: "11630449",
    RegisteredPopulation: "494",
    VotingStationName: "NGQUQA JUNIOR SECONDARY SCHOOL",
    Address: "NGQUQA LOC.  MQHEKEZWENI A/A  MTHATHA",
    Latitude: "-31.7474",
    Longitude: "28.3907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3485",
    WardLookupId: "709",
    VDNumber: "11630595",
    RegisteredPopulation: "462",
    VotingStationName: "NQWATI JUNIOR SECONDARY SCHOOL",
    Address: "NQWATI LOC  MQHEKEZWENI A/A  MTHATHA",
    Latitude: "-31.7029",
    Longitude: "28.363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3486",
    WardLookupId: "709",
    VDNumber: "11630719",
    RegisteredPopulation: "306",
    VotingStationName: "MBASHE SENIOR PRIMARY SCHOOL",
    Address: "MBASHE LOC  NGQUQHA A/A  MTHATHA",
    Latitude: "-31.758",
    Longitude: "28.3659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3487",
    WardLookupId: "709",
    VDNumber: "11630731",
    RegisteredPopulation: "534",
    VotingStationName: "CIBINI JUNIOR SECONDARY SCHOOL",
    Address: "CIBINI LOC.  NGQUQA A/A  MTHATHA",
    Latitude: "-31.7275",
    Longitude: "28.3764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3488",
    WardLookupId: "709",
    VDNumber: "11630742",
    RegisteredPopulation: "229",
    VotingStationName: "CABA JUNIOR SECONDARY SCHOOL",
    Address: "CABA LOC  NGQUQA A/A  MTHATHA",
    Latitude: "-31.6849",
    Longitude: "28.3414",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3489",
    WardLookupId: "709",
    VDNumber: "11630944",
    RegisteredPopulation: "267",
    VotingStationName: "RAFUZA MNTUYEDWA SENIOR PRIMARY SCHOOL",
    Address: "NTLAKWENDLELA /XHONGORA LOC  XHONGORA A/A  MTHATHA",
    Latitude: "-31.6452",
    Longitude: "28.399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3490",
    WardLookupId: "709",
    VDNumber: "11631068",
    RegisteredPopulation: "494",
    VotingStationName: "NTABELIZA JUNIOR SECONDARY SCHOOL",
    Address: "XONGORA LOC  XHONGORA A/A  MTHATHA",
    Latitude: "-31.6466",
    Longitude: "28.4228",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3491",
    WardLookupId: "709",
    VDNumber: "11631877",
    RegisteredPopulation: "660",
    VotingStationName: "NTILINI JUNIOR SECONDARY SCHOOL",
    Address: "NTILINI LOCATION  XHONGORA A/A  MTHATHA",
    Latitude: "-31.6799",
    Longitude: "28.4066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3492",
    WardLookupId: "709",
    VDNumber: "11631901",
    RegisteredPopulation: "284",
    VotingStationName: "CHOLAPHANTSI JUNIOR SECONDARY SCHOOL",
    Address: "]MAQADINI LOCATION  XHONGORA A/A  MTHATHA",
    Latitude: "-31.7161",
    Longitude: "28.4207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3493",
    WardLookupId: "710",
    VDNumber: "11630337",
    RegisteredPopulation: "873",
    VotingStationName: "MQHEKEZWENI JUNIOR SECONDARY SCHOOL",
    Address: "MQHEKEZWENI LOC.  MQHEKEZWENI A/A  MTHATHA",
    Latitude: "-31.7365",
    Longitude: "28.468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3494",
    WardLookupId: "710",
    VDNumber: "11630450",
    RegisteredPopulation: "354",
    VotingStationName: "MATISO JUNIOR SECONDARY SCHOOL",
    Address: "MSANA LOC.  MQEKEZWENI A/A  MTHATHA",
    Latitude: "-31.8246",
    Longitude: "28.3841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3495",
    WardLookupId: "710",
    VDNumber: "11630461",
    RegisteredPopulation: "523",
    VotingStationName: "JULUKUQU JUNIOR SECONDARY SCHOOL",
    Address: "JULUKUQU LOC.  MQEKEZWENI T/A  MTHATHA",
    Latitude: "-31.7757",
    Longitude: "28.4498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3496",
    WardLookupId: "710",
    VDNumber: "11630472",
    RegisteredPopulation: "213",
    VotingStationName: "MOKOLWENI JUNIOR SECONDARY SCHOOL",
    Address: "MOKOLWENI LOC.  MQHEKEZWENI T/A  MTHATHA",
    Latitude: "-31.8033",
    Longitude: "28.4689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3497",
    WardLookupId: "710",
    VDNumber: "11630539",
    RegisteredPopulation: "703",
    VotingStationName: "MKHWEZO JUNIOR SECONDARY SCHOOL",
    Address: "MKHWEZO LOC.  MQHEKEZWENI A/A  MTHATHA",
    Latitude: "-31.7461",
    Longitude: "28.5293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3498",
    WardLookupId: "710",
    VDNumber: "11630584",
    RegisteredPopulation: "583",
    VotingStationName: "MPIKWANA JUNIOR SECONDARY SCHOOL",
    Address: "MPIKWANA LOC  MQHEKEZWENI T/A  MTHATHA",
    Latitude: "-31.7931",
    Longitude: "28.4053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3499",
    WardLookupId: "710",
    VDNumber: "11630629",
    RegisteredPopulation: "346",
    VotingStationName: "GXWALIBOMVU JUNIOR SECONDARY SCHOOL",
    Address: "GXWALIBOMVU LOC  MQEKEZWENI A/A  MTHATHA",
    Latitude: "-31.8",
    Longitude: "28.3604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3500",
    WardLookupId: "710",
    VDNumber: "11630630",
    RegisteredPopulation: "344",
    VotingStationName: "STETO JUNIOR SECONDARY SCHOOL",
    Address: "RUNE LOC  MQEKEZEKWENI A/A  MTHATHA",
    Latitude: "-31.824",
    Longitude: "28.4093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3501",
    WardLookupId: "710",
    VDNumber: "11630753",
    RegisteredPopulation: "324",
    VotingStationName: "GUNJANA JUNIOR SECONDARY SCHOOL",
    Address: "GUNJANA LOC.  MQEKEZWENI  MTHATHA",
    Latitude: "-31.8076",
    Longitude: "28.4278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3502",
    WardLookupId: "710",
    VDNumber: "11630775",
    RegisteredPopulation: "695",
    VotingStationName: "BITYI JUNIOR SECONDARY SCHOOL",
    Address: "BITYI LOC.  MQEKEZWENI A/A  MTHATHA",
    Latitude: "-31.7885",
    Longitude: "28.5265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3503",
    WardLookupId: "710",
    VDNumber: "11630797",
    RegisteredPopulation: "429",
    VotingStationName: "NDIBELA JUNIOR SECONDARY SCHOOL",
    Address: "NDIBELA LOCATION  NDIBELA A/A  MTHATHA",
    Latitude: "-31.833",
    Longitude: "28.4311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3504",
    WardLookupId: "710",
    VDNumber: "11631091",
    RegisteredPopulation: "268",
    VotingStationName: "NYIBENI CRECHE",
    Address: "NYIBENI LOCATION  MQEKEZWENI A/A  UMTATA",
    Latitude: "-31.7776",
    Longitude: "28.4216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3505",
    WardLookupId: "710",
    VDNumber: "11631181",
    RegisteredPopulation: "231",
    VotingStationName: "DALIBANGO JUNIOR SECONDARY SCHOOL",
    Address: "NDIBELA LOCATION  NDIBELA A/A  MTHATHA",
    Latitude: "-31.8367",
    Longitude: "28.4514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3506",
    WardLookupId: "710",
    VDNumber: "11631204",
    RegisteredPopulation: "221",
    VotingStationName: "NQENCU  JUNIOR SECONDARY SCHOOL",
    Address: "MALINDINI LOC  MTENTU A/A  MTHATHA",
    Latitude: "-31.8155",
    Longitude: "28.4664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3507",
    WardLookupId: "710",
    VDNumber: "11631226",
    RegisteredPopulation: "432",
    VotingStationName: "PHINGILILI JUNIOR SECONDARY SCHOOL",
    Address: "BITYI LOC  BITYI A/A  MTHATHA",
    Latitude: "-31.7998",
    Longitude: "28.506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3508",
    WardLookupId: "710",
    VDNumber: "11631248",
    RegisteredPopulation: "170",
    VotingStationName: "KWANDABA CHURCH HALL",
    Address: "KWANDABA LOCATION  MSANA A/A  MTHATHA",
    Latitude: "-31.8146",
    Longitude: "28.3719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3509",
    WardLookupId: "711",
    VDNumber: "11630506",
    RegisteredPopulation: "837",
    VotingStationName: "EMPA JUNIOR SECONDARY SCHOOL",
    Address: "EMPA LOC.  QUNU A/A  MTHATHA",
    Latitude: "-31.8073",
    Longitude: "28.5723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3510",
    WardLookupId: "711",
    VDNumber: "11630528",
    RegisteredPopulation: "549",
    VotingStationName: "NKALANE JUNIOR SECONDARY SCHOOL",
    Address: "NKALANE LOC.  QUNU A/A  UMTATA",
    Latitude: "-31.8061",
    Longitude: "28.6182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3511",
    WardLookupId: "711",
    VDNumber: "11630663",
    RegisteredPopulation: "468",
    VotingStationName: "SPEFUNDEVU JUNIOR SECONDARY SCHOOL",
    Address: "SPEFUNDEVU LOC.  SITEBE  A/A  MTHATHA",
    Latitude: "-31.8436",
    Longitude: "28.622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3512",
    WardLookupId: "711",
    VDNumber: "11630764",
    RegisteredPopulation: "394",
    VotingStationName: "NOTINARA JUNIOR SECONDARY SCHOOL",
    Address: "SITEBE LOC.  SITEBE A/A  MTHATHA",
    Latitude: "-31.8285",
    Longitude: "28.5588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3513",
    WardLookupId: "711",
    VDNumber: "11630809",
    RegisteredPopulation: "486",
    VotingStationName: "NGCENDESE JUNIOR SECONDARY SCHOOL",
    Address: "NGCENDESE LOC  MTENTU A/A  MTHATHA",
    Latitude: "-31.84",
    Longitude: "28.5271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3514",
    WardLookupId: "711",
    VDNumber: "11630810",
    RegisteredPopulation: "231",
    VotingStationName: "TANTSEKA JUNIOR SECONDARY SCHOOL.",
    Address: "TANTSEKA  LOC.  SITEBE  A/A  MTHATHA",
    Latitude: "-31.8522",
    Longitude: "28.548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3515",
    WardLookupId: "711",
    VDNumber: "11630843",
    RegisteredPopulation: "440",
    VotingStationName: "DUMALISILE JUNIOR SECONDARY SCHOOL",
    Address: "DUMALISILE LOC.  VIEDGESVILLE  KING SABATA DALINDYEBO",
    Latitude: "-31.7921",
    Longitude: "28.6385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3516",
    WardLookupId: "711",
    VDNumber: "11631237",
    RegisteredPopulation: "483",
    VotingStationName: "SIGOYO JUNIOR SECONDARY SCHOOL",
    Address: "MSUKENI LOCATION  MTENTU A/A  MTHATHA",
    Latitude: "-31.8534",
    Longitude: "28.4866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3517",
    WardLookupId: "711",
    VDNumber: "11631642",
    RegisteredPopulation: "154",
    VotingStationName: "DUMALITSHONA SENIOR PRIMARY SCHOOL",
    Address: "BUMBANA LOCATION  SITHEBE A/A  MTHATHA",
    Latitude: "-31.8702",
    Longitude: "28.5113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3518",
    WardLookupId: "711",
    VDNumber: "11631653",
    RegisteredPopulation: "317",
    VotingStationName: "NOBANTU SENIOR PRIMARY SCHOOL",
    Address: "MANTSHAYI LOCATION  SITEBE A/A  MTHATHA",
    Latitude: "-31.8555",
    Longitude: "28.5809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3519",
    WardLookupId: "711",
    VDNumber: "11631709",
    RegisteredPopulation: "591",
    VotingStationName: "MADONISI JUNIOR SECONDARY SCHOOL",
    Address: "TONGA LOCATION  BITYI A/A  MTHATHA",
    Latitude: "-31.789",
    Longitude: "28.5563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3520",
    WardLookupId: "711",
    VDNumber: "11631710",
    RegisteredPopulation: "884",
    VotingStationName: "QUNU JUNIOR SECONDARY SCHOOL",
    Address: "QUNU LOCATION  QUNU A/A  MTHATHA",
    Latitude: "-31.7812",
    Longitude: "28.6136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3521",
    WardLookupId: "711",
    VDNumber: "11631765",
    RegisteredPopulation: "393",
    VotingStationName: "NO-MOSCOW SENIOR PRIMARY SCHOOL",
    Address: "QUNU LOCATION  QUNU A/A  MTHATHA",
    Latitude: "-31.7979",
    Longitude: "28.607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3522",
    WardLookupId: "711",
    VDNumber: "11631888",
    RegisteredPopulation: "273",
    VotingStationName: "JONGIMIZI SENIOR PRIMARY SCHOOL",
    Address: "CEGCWANA LOCATION  NGQUNGE A/A  MTHATHA",
    Latitude: "-31.821",
    Longitude: "28.5213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3523",
    WardLookupId: "712",
    VDNumber: "11590376",
    RegisteredPopulation: "490",
    VotingStationName: "ROZA JUNIOR SECONDARY SCHOOL",
    Address: "ROZA LOC  ROZA A/A  MQANDULI",
    Latitude: "-31.9303",
    Longitude: "28.5789",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3524",
    WardLookupId: "712",
    VDNumber: "11590613",
    RegisteredPopulation: "478",
    VotingStationName: "NCEKANA JUNIOR SECONDARY SCHOOL",
    Address: "KRAKRA A/A  KRAKRA LOCATION  MQANDULI",
    Latitude: "-31.9663",
    Longitude: "28.5649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3525",
    WardLookupId: "712",
    VDNumber: "11591119",
    RegisteredPopulation: "460",
    VotingStationName: "QHIYA SENIOR PRIMARY SCHOOL",
    Address: "MADONDILE LOC  KWENXURA A/A  MQANDULI",
    Latitude: "-31.8997",
    Longitude: "28.616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3526",
    WardLookupId: "712",
    VDNumber: "11630168",
    RegisteredPopulation: "598",
    VotingStationName: "DALINDYEBO SENIOR SECONDARY SCHOOL",
    Address: "MGQUMO LOC.  SITEBE  A/A  MTHATHA",
    Latitude: "-31.8787",
    Longitude: "28.5994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3527",
    WardLookupId: "712",
    VDNumber: "11630179",
    RegisteredPopulation: "351",
    VotingStationName: "TYALARA JUNIOR SECONDARY SCHOOL",
    Address: "TYALARA LOC.  TYALARA A/A  MTHATHA",
    Latitude: "-31.9025",
    Longitude: "28.4942",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3528",
    WardLookupId: "712",
    VDNumber: "11630191",
    RegisteredPopulation: "233",
    VotingStationName: "MVEZO JUNIOR SECONDARY SCHOOL",
    Address: "MVEZO LOC.  SITHEBE T/A  MTHATHA",
    Latitude: "-31.9494",
    Longitude: "28.5179",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3529",
    WardLookupId: "712",
    VDNumber: "11630483",
    RegisteredPopulation: "277",
    VotingStationName: "MTENTU JUNIOR SECONDARY SCHOOL",
    Address: "MTENTU LOC.  SITHEBE T/A  MTHATHA",
    Latitude: "-31.8956",
    Longitude: "28.4644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3530",
    WardLookupId: "712",
    VDNumber: "11630821",
    RegisteredPopulation: "502",
    VotingStationName: "BUWA JUNIOR SECONDARY SCHOOL",
    Address: "SITEBE KOMKHULU  SITHEBE A/A  MTHATHA",
    Latitude: "-31.882",
    Longitude: "28.5816",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3531",
    WardLookupId: "712",
    VDNumber: "11630854",
    RegisteredPopulation: "222",
    VotingStationName: "NOHLUTHA JUNIOR SECONDARY SCHOOL",
    Address: "NOHLUTHA LOC.  SITEBE A/A  MTHATHA",
    Latitude: "-31.9149",
    Longitude: "28.5643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3532",
    WardLookupId: "712",
    VDNumber: "11630876",
    RegisteredPopulation: "367",
    VotingStationName: "ZWELIBANZI PRE-SCHOOL",
    Address: "MATOLWENI LOC.  TYHALARA A/A  MTHATHA",
    Latitude: "-31.9173",
    Longitude: "28.514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3533",
    WardLookupId: "712",
    VDNumber: "11631125",
    RegisteredPopulation: "218",
    VotingStationName: "ZWELINZIMA JUNIOR SECONDARY SCHOOL",
    Address: "MZINYANA LOC  MTENTU A/A  MTHATHA",
    Latitude: "-31.9079",
    Longitude: "28.4465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3534",
    WardLookupId: "712",
    VDNumber: "11631136",
    RegisteredPopulation: "228",
    VotingStationName: "MTENTU PRE-SCHOOL",
    Address: "MMANGWENI LOCATION  MTENTU A/A  UMTATA",
    Latitude: "-31.8816",
    Longitude: "28.4709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3535",
    WardLookupId: "712",
    VDNumber: "11631169",
    RegisteredPopulation: "269",
    VotingStationName: "MLOTSANA SENIOR PRIMARY SCHOOL",
    Address: "KWA - MIYA LOC  MTENTU NDIBELA A/A  MTHATHA",
    Latitude: "-31.8673",
    Longitude: "28.432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3536",
    WardLookupId: "712",
    VDNumber: "11631170",
    RegisteredPopulation: "159",
    VotingStationName: "NCEDULUNTU CAFE KWA-ANTANA SPAZA",
    Address: "NCENJANA LOCATION  MTENTU A/A  UMTATA",
    Latitude: "-31.853",
    Longitude: "28.4586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3537",
    WardLookupId: "712",
    VDNumber: "11631620",
    RegisteredPopulation: "399",
    VotingStationName: "MVEZO TRADITIONAL COUNCIL MULTI PURPOSE CENTRE",
    Address: "MVEZO LOCATION  MVEZO A/A  MTHATHA",
    Latitude: "-31.9624",
    Longitude: "28.4879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3538",
    WardLookupId: "712",
    VDNumber: "11631631",
    RegisteredPopulation: "340",
    VotingStationName: "MANDELA SCHOOL OF SCIENCE & TECHNOLOGY",
    Address: "GWATYU LOCATION  MVEZO A/A  MTHATHA",
    Latitude: "-31.9383",
    Longitude: "28.5542",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3539",
    WardLookupId: "712",
    VDNumber: "11631675",
    RegisteredPopulation: "281",
    VotingStationName: "SIBONDAS HOUSE",
    Address: "KUGANGXO LOCATION  SITEBE A/A  MTHATHA",
    Latitude: "-31.8886",
    Longitude: "28.5685",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3540",
    WardLookupId: "712",
    VDNumber: "11631697",
    RegisteredPopulation: "282",
    VotingStationName: "LUKHANYO PRE-SCHOOL",
    Address: "SITEBE LOCATION  SITEBE A/A  UMTATA",
    Latitude: "-31.8635",
    Longitude: "28.5652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3541",
    WardLookupId: "713",
    VDNumber: "11590231",
    RegisteredPopulation: "454",
    VotingStationName: "FUTYE JUNIOR SECONDARY SCHOOL",
    Address: "FUTYE LOC.  LOWER TYHOLO A/A  MQANDULI",
    Latitude: "-31.9986",
    Longitude: "28.7297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3542",
    WardLookupId: "713",
    VDNumber: "11590332",
    RegisteredPopulation: "827",
    VotingStationName: "DINIZULU JUNIOR SECONDARY SCHOOL",
    Address: "BAKUBA LOC  UPPER TYHOLO A/A  MQANDULI",
    Latitude: "-31.9649",
    Longitude: "28.7447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3543",
    WardLookupId: "713",
    VDNumber: "11590343",
    RegisteredPopulation: "408",
    VotingStationName: "KALALO JUNIOR SECONDARY SCHOOL",
    Address: "KALALO LOC  KWENXURA A/A  MQANDULI",
    Latitude: "-31.9828",
    Longitude: "28.6509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3544",
    WardLookupId: "713",
    VDNumber: "11590354",
    RegisteredPopulation: "592",
    VotingStationName: "BACELA JUNIOR SECONDARY SCHOOL",
    Address: "MAQOMENI LOC.  KWENXURA A/A  MQANDULI",
    Latitude: "-31.9536",
    Longitude: "28.6437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3545",
    WardLookupId: "713",
    VDNumber: "11590365",
    RegisteredPopulation: "312",
    VotingStationName: "ZWELAKHE (KWENXURA) SENIOR PRIMARY SCHOOL",
    Address: "ZWELAKHE LOC.  KWENXURA A/A  MQANDULI",
    Latitude: "-31.9591",
    Longitude: "28.6129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3546",
    WardLookupId: "713",
    VDNumber: "11590398",
    RegisteredPopulation: "403",
    VotingStationName: "MZOMHLE JUNIOR SECONDARY SCHOOL",
    Address: "TUTWINI  LOC  GENGQE A/A  MQANDULI",
    Latitude: "-31.9508",
    Longitude: "28.7122",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3547",
    WardLookupId: "713",
    VDNumber: "11590589",
    RegisteredPopulation: "629",
    VotingStationName: "NZWAKAZI JUNIOR SECONDARY SCHOOL",
    Address: "NZWAKAZI LOC.  L/TYHOLO A/A  MQANDULI",
    Latitude: "-31.9996",
    Longitude: "28.7756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3548",
    WardLookupId: "713",
    VDNumber: "11590859",
    RegisteredPopulation: "320",
    VotingStationName: "LIWALAPAKADE JUNIOR SECONDARY SCHOOL",
    Address: "SKOBENI LOC.  GENGQE A/A  MQANDULI",
    Latitude: "-31.9401",
    Longitude: "28.6862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3549",
    WardLookupId: "713",
    VDNumber: "11590860",
    RegisteredPopulation: "492",
    VotingStationName: "SPENDU JUNIOR SECONDARY SCHOOL",
    Address: "RWELA LOCATION  KWENXUREA A/A  MTHATHA",
    Latitude: "-31.9309",
    Longitude: "28.6288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3550",
    WardLookupId: "713",
    VDNumber: "11590893",
    RegisteredPopulation: "160",
    VotingStationName: "VULINGCOBO JUNIOR SECONDARY SCHOOL",
    Address: "MADINDI LOC.  L/TYHOLO A/A  MQANDULI",
    Latitude: "-32.0431",
    Longitude: "28.7766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3551",
    WardLookupId: "713",
    VDNumber: "11591120",
    RegisteredPopulation: "333",
    VotingStationName: "KALALO PRE- SCHOOL",
    Address: "TOGWANA LOCATION  KALALO A/A  MQANDULI",
    Latitude: "-31.9686",
    Longitude: "28.6576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3552",
    WardLookupId: "713",
    VDNumber: "11591209",
    RegisteredPopulation: "303",
    VotingStationName: "PHAPHAMANI PRE-SCHOOL",
    Address: "DUDANI LOCATION  LOWER TYHOLO A/A  MTHATHA",
    Latitude: "-32",
    Longitude: "28.714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3553",
    WardLookupId: "713",
    VDNumber: "11591210",
    RegisteredPopulation: "241",
    VotingStationName: "KWA WOMA (NGENKALA) HOUSE",
    Address: "LOWER TYHOLO LOCATION  LOWER TYHOLO A/A  MQANDULI",
    Latitude: "-32.0365",
    Longitude: "28.7577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3554",
    WardLookupId: "713",
    VDNumber: "11591221",
    RegisteredPopulation: "95",
    VotingStationName: "TENT AT TAMSANA LOCATION",
    Address: "TAMSANA LOCATION  TUNGWANA A/A  MQANDULI",
    Latitude: "-31.9764",
    Longitude: "28.8224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3555",
    WardLookupId: "713",
    VDNumber: "11591502",
    RegisteredPopulation: "469",
    VotingStationName: "NGONYAMA JUNIOR PRIMARY SCHOOL",
    Address: "XHIBENI LOCATION  ELLIOTDALE",
    Latitude: "-32.0001",
    Longitude: "28.6585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3556",
    WardLookupId: "713",
    VDNumber: "11591513",
    RegisteredPopulation: "220",
    VotingStationName: "HAKO JUNIOR PRIMARY SCHOOL",
    Address: "ZIGENU LOCATION  KWENXURA A/A  MQANDULI",
    Latitude: "-31.9436",
    Longitude: "28.6586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3557",
    WardLookupId: "714",
    VDNumber: "11590196",
    RegisteredPopulation: "455",
    VotingStationName: "KAULA JUNIOR SECONDARY SCHOOL",
    Address: "KAULA LOC.  LUCWECWE A/A  MQANDULI",
    Latitude: "-31.9327",
    Longitude: "28.8535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3558",
    WardLookupId: "714",
    VDNumber: "11590219",
    RegisteredPopulation: "347",
    VotingStationName: "NGUBECHANTI JUNIOR SECONDARY SCHOOL.",
    Address: "QABAKENI LOC.  MAHLUNGULU A/A  MQANDULI",
    Latitude: "-31.9581",
    Longitude: "28.8182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3559",
    WardLookupId: "714",
    VDNumber: "11590220",
    RegisteredPopulation: "345",
    VotingStationName: "TUNGWANA JUNIOR SECONDARY SCHOOL",
    Address: "TUNGWANA LOC.  TUNGWANA A/A  MQANDULI",
    Latitude: "-31.9627",
    Longitude: "28.7889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3560",
    WardLookupId: "714",
    VDNumber: "11590275",
    RegisteredPopulation: "852",
    VotingStationName: "MANCAM JUNIOR SECONDARY SCHOOL",
    Address: "QAUKENI LOC.  XORANA A/A  MQANDULI",
    Latitude: "-31.9051",
    Longitude: "28.7945",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3561",
    WardLookupId: "714",
    VDNumber: "11590297",
    RegisteredPopulation: "583",
    VotingStationName: "LOWER NGQUNGQU JUNIOR SECONDARY SCHOOL",
    Address: "L/NGQUNGQU LOC  LOWER NGQUNGQU A/A  MQANDULI",
    Latitude: "-31.8662",
    Longitude: "28.8165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3562",
    WardLookupId: "714",
    VDNumber: "11590309",
    RegisteredPopulation: "375",
    VotingStationName: "AMAMBALU JUNIOR SECONDARY SCHOOL",
    Address: "AMAMBALU LOC  XORANA A/A  MQANDULI",
    Latitude: "-31.9488",
    Longitude: "28.7661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3563",
    WardLookupId: "714",
    VDNumber: "11590310",
    RegisteredPopulation: "421",
    VotingStationName: "SOMPA JUNIOR PRIMARY SCHOOL",
    Address: "SOMPA KOMKHULU  XHORANA A/A  MQANDULI",
    Latitude: "-31.9246",
    Longitude: "28.7627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3564",
    WardLookupId: "714",
    VDNumber: "11590321",
    RegisteredPopulation: "295",
    VotingStationName: "BIJOLO JUNIOR SECONDARY SCHOOL.",
    Address: "MAQWAMBINI LOCATION  XHORANA A/A  MQANDULI",
    Latitude: "-31.9103",
    Longitude: "28.7446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3565",
    WardLookupId: "714",
    VDNumber: "11590400",
    RegisteredPopulation: "387",
    VotingStationName: "BAVUMELE JUNIOR SECONDARY SCHOOL",
    Address: "XORANA  LOC  XORANA A/A  MQANDULI",
    Latitude: "-31.9407",
    Longitude: "28.7365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3566",
    WardLookupId: "714",
    VDNumber: "11590882",
    RegisteredPopulation: "268",
    VotingStationName: "MHLABUNZIMA JUNIOR SECONDARY SCHOOL",
    Address: "LUNDINI LOC.  LUCWECWE A/A  MQANDULI",
    Latitude: "-31.9112",
    Longitude: "28.8313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3567",
    WardLookupId: "714",
    VDNumber: "11591175",
    RegisteredPopulation: "342",
    VotingStationName: "MZAMOMHLE STORE",
    Address: "MDENI LOCATION  KAULA A/A  MQANDULI",
    Latitude: "-31.9324",
    Longitude: "28.8182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3568",
    WardLookupId: "714",
    VDNumber: "11591232",
    RegisteredPopulation: "589",
    VotingStationName: "BAZINDLOVU SENIOR SECONDARY SCHOOL",
    Address: "ZITHENJINI LOCATION  MANCAM A/A  MQANDULI",
    Latitude: "-31.8883",
    Longitude: "28.8009",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3569",
    WardLookupId: "714",
    VDNumber: "11591298",
    RegisteredPopulation: "170",
    VotingStationName: "SANKOBE JUNIOR SECONDARY SCHOOL",
    Address: "SANKOBE LOCATION  LOWER NGQUNGQU A/A  MQANDULI",
    Latitude: "-31.8407",
    Longitude: "28.792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3570",
    WardLookupId: "714",
    VDNumber: "11591535",
    RegisteredPopulation: "96",
    VotingStationName: "THAMSANA PRE - SCHOOL",
    Address: "THAMSANA LOCATION  THUNGWANA A/A  MQANDULI",
    Latitude: "-31.9793",
    Longitude: "28.7899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3571",
    WardLookupId: "715",
    VDNumber: "11590387",
    RegisteredPopulation: "474",
    VotingStationName: "DARABE JUNIOR SECONDARY SCHOOL",
    Address: "DARABE LOC  DARABE A/A  MQANDULI",
    Latitude: "-31.8958",
    Longitude: "28.6373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3572",
    WardLookupId: "715",
    VDNumber: "11590411",
    RegisteredPopulation: "351",
    VotingStationName: "TSHONTINI SENIOR PRIMARY SCHOOL",
    Address: "TSHONTINI LOC  DARABE A/A  MQANDULI",
    Latitude: "-31.9215",
    Longitude: "28.6582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3573",
    WardLookupId: "715",
    VDNumber: "11590433",
    RegisteredPopulation: "336",
    VotingStationName: "TALENI SENIOR PRIMARY SCHOOL",
    Address: "ZIBODLA LOC.  ZIBODLA A/A  MQANDULI",
    Latitude: "-31.8608",
    Longitude: "28.7095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3574",
    WardLookupId: "715",
    VDNumber: "11590444",
    RegisteredPopulation: "479",
    VotingStationName: "ZIBODLA JUNIOR SECONDARY SCHOOL",
    Address: "ZIBODLA A/A  ZIBODLA LOCATION  MQANDULI",
    Latitude: "-31.8726",
    Longitude: "28.7234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3575",
    WardLookupId: "715",
    VDNumber: "11590455",
    RegisteredPopulation: "609",
    VotingStationName: "UPPER NGQUNGQU JUNIOR SECONDARY SCHOOL",
    Address: "KULOZULU LOC.  MAHLAMVU A/A  MQANDULI",
    Latitude: "-31.8823",
    Longitude: "28.75",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3576",
    WardLookupId: "715",
    VDNumber: "11590466",
    RegisteredPopulation: "351",
    VotingStationName: "MQANDULI JUNIOR SECONDARY SCHOOL",
    Address: "MAKHUMSHENI LOC.  ZIBODLA A/A  MQANDULI",
    Latitude: "-31.8502",
    Longitude: "28.7362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3577",
    WardLookupId: "715",
    VDNumber: "11590477",
    RegisteredPopulation: "547",
    VotingStationName: "NGCANASINI JUNIOR SECONDARY SCHOOL",
    Address: "NGCANASINI LOC.  NGCANASINI A/A  MQANDULI",
    Latitude: "-31.8427",
    Longitude: "28.7777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3578",
    WardLookupId: "715",
    VDNumber: "11590679",
    RegisteredPopulation: "776",
    VotingStationName: "GENGQE JUNIOR SECONDARY SCHOOL",
    Address: "GENGQE A/A  MQANDULI",
    Latitude: "-31.9001",
    Longitude: "28.6981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3579",
    WardLookupId: "715",
    VDNumber: "11591131",
    RegisteredPopulation: "284",
    VotingStationName: "DILIKILE JUNIOR SECONDARY SCHOOL",
    Address: "MBIZA LOCATION  MATSHAMBA A/A  MQANDULI",
    Latitude: "-31.918",
    Longitude: "28.6285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3580",
    WardLookupId: "715",
    VDNumber: "11591142",
    RegisteredPopulation: "332",
    VotingStationName: "MDENI FAITH MISSION CHURCH",
    Address: "MDENI LOCATION  GENGQE A/A  MQANDULI",
    Latitude: "-31.8875",
    Longitude: "28.6509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3581",
    WardLookupId: "715",
    VDNumber: "11591243",
    RegisteredPopulation: "527",
    VotingStationName: "SIKHOBENI METHODIST CHURCH",
    Address: "SIKHOBENI LOCATION  GENGQE A/A  MQANDULI",
    Latitude: "-31.9154",
    Longitude: "28.6978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3582",
    WardLookupId: "715",
    VDNumber: "11591254",
    RegisteredPopulation: "390",
    VotingStationName: "GOTYIBENI FAITH MISSION CHURCH",
    Address: "GOTYIBENI LOCATION  GENGQE A/A  MQANDULI",
    Latitude: "-31.9041",
    Longitude: "28.7171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3583",
    WardLookupId: "715",
    VDNumber: "11591265",
    RegisteredPopulation: "342",
    VotingStationName: "ZILINYAMA SENIOR PRIMARY SCHOOL",
    Address: "MACHIBINI LOCATION  DARABE A/A  MQANDULI",
    Latitude: "-31.899",
    Longitude: "28.6668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3584",
    WardLookupId: "715",
    VDNumber: "11591287",
    RegisteredPopulation: "496",
    VotingStationName: "GOGOZAYO LOCATION",
    Address: "GOGOZAYO LOCATION  UPPER NGQUNGQU A/A  MQANDULI",
    Latitude: "-31.8761",
    Longitude: "28.7557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3585",
    WardLookupId: "715",
    VDNumber: "11591524",
    RegisteredPopulation: "359",
    VotingStationName: "MASAKHE PRE- SCHOOL",
    Address: "LWALWENI LOCATION  CACADU A/A  MQANDULI",
    Latitude: "-31.8691",
    Longitude: "28.6872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3586",
    WardLookupId: "716",
    VDNumber: "11590017",
    RegisteredPopulation: "481",
    VotingStationName: "LOWER MPAKO SENIOR PRIMARY SCHOOL",
    Address: "MTONJANA LOC.  MTONJANA A/A  MQANDULI",
    Latitude: "-32.0176",
    Longitude: "29.0828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3587",
    WardLookupId: "716",
    VDNumber: "11590028",
    RegisteredPopulation: "626",
    VotingStationName: "COFFEE BAY JUNIOR SECONDARY SCHOOL",
    Address: "RINI  LOC  LOWER NENGA A/A  MQANDULI",
    Latitude: "-31.9881",
    Longitude: "29.1445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3588",
    WardLookupId: "716",
    VDNumber: "11590051",
    RegisteredPopulation: "450",
    VotingStationName: "ZINKAWU JUNIOR SECONDARY SCHOOL",
    Address: "ZINKAWU LOC  NZULWINI A/A  MQANDULI",
    Latitude: "-31.9863",
    Longitude: "29.0538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3589",
    WardLookupId: "716",
    VDNumber: "11590062",
    RegisteredPopulation: "387",
    VotingStationName: "MDEDELENI JUNIOR SECONDARY SCHOOL",
    Address: "MDEDELENI LOC.  MPONJANA A/A  MQANDULI",
    Latitude: "-32.0336",
    Longitude: "29.1067",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3590",
    WardLookupId: "716",
    VDNumber: "11590073",
    RegisteredPopulation: "635",
    VotingStationName: "MADAKENI JUNIOR SECONDARY SCHOOL",
    Address: "MADAKENI LOC  LOWER NENGA A/A  MQANDULI",
    Latitude: "-31.9651",
    Longitude: "29.1341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3591",
    WardLookupId: "716",
    VDNumber: "11590107",
    RegisteredPopulation: "333",
    VotingStationName: "MATOKAZINI JUNIOR SECONDARY SCHOOL",
    Address: "COFFEE BAY A/A  COFFEE BAY  MQANDULI",
    Latitude: "-31.9563",
    Longitude: "29.1711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3592",
    WardLookupId: "716",
    VDNumber: "11590635",
    RegisteredPopulation: "849",
    VotingStationName: "MAPUZI TECHNICAL SCHOOL",
    Address: "LOWER NENGA LOC  L. NENGA  A/A  MQANDULI",
    Latitude: "-31.9458",
    Longitude: "29.1304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3593",
    WardLookupId: "716",
    VDNumber: "11590736",
    RegisteredPopulation: "501",
    VotingStationName: "NTEKELELO JUNIOR SECONDARY SCHOOL",
    Address: "BUNENE LOC.  NZULWINI A/A  MQANDULI",
    Latitude: "-32.0032",
    Longitude: "29.0723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3594",
    WardLookupId: "716",
    VDNumber: "11590747",
    RegisteredPopulation: "483",
    VotingStationName: "PATO JUNIOR SECONDARY SCHOOL",
    Address: "KHAM LOC.  NENGA A/A  MQANDULI",
    Latitude: "-32.0073",
    Longitude: "29.1209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3595",
    WardLookupId: "716",
    VDNumber: "11590927",
    RegisteredPopulation: "421",
    VotingStationName: "HLOHLESAKHE STORE TENT",
    Address: "SIZINDENI LOC.  LOWER NENGA A/A  MQANDULI",
    Latitude: "-31.945",
    Longitude: "29.1529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3596",
    WardLookupId: "716",
    VDNumber: "11590938",
    RegisteredPopulation: "591",
    VotingStationName: "BHONGA LOCATION",
    Address: "BHONGA LOCATION  LOWER NENGA A/A  MQANDULI",
    Latitude: "-31.9516",
    Longitude: "29.1161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3597",
    WardLookupId: "716",
    VDNumber: "11590950",
    RegisteredPopulation: "463",
    VotingStationName: "GOBIDOLO SENIOR PRIMARY SCHOOL",
    Address: "GXWALENI LOCATION  LOWER NENGA A/A  MQANDULI",
    Latitude: "-31.9891",
    Longitude: "29.093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3598",
    WardLookupId: "716",
    VDNumber: "11590972",
    RegisteredPopulation: "845",
    VotingStationName: "MTALENI DUTCH REFORMED CHURCH",
    Address: "MTALENI LOCATION  LOWER MPAKO  A/A  MQANDULI",
    Latitude: "-32.0276",
    Longitude: "29.0431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3599",
    WardLookupId: "717",
    VDNumber: "11590039",
    RegisteredPopulation: "713",
    VotingStationName: "MOUNT PARKAD JUNIOR SECONDARY SCHOOL",
    Address: "MOUNT PARKAD LOC  NENGA  A/A  MQANDULI",
    Latitude: "-31.9599",
    Longitude: "29.0916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3600",
    WardLookupId: "717",
    VDNumber: "11590084",
    RegisteredPopulation: "625",
    VotingStationName: "MAQANYENI SENIOR PRIMARY SCHOOL",
    Address: "KWA-TSHALI LOC (MAQANYENI)  UPPER MPAKO A/A  MQANDULI",
    Latitude: "-31.9658",
    Longitude: "29.0155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3601",
    WardLookupId: "717",
    VDNumber: "11590130",
    RegisteredPopulation: "971",
    VotingStationName: "MILTON DALASILE SENIOR SECONDARY SCHOOL",
    Address: "MABEHANA LOC.  MABEHANA A/A  MQANDULI",
    Latitude: "-31.9146",
    Longitude: "29.0712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3602",
    WardLookupId: "717",
    VDNumber: "11590141",
    RegisteredPopulation: "1055",
    VotingStationName: "NGCWANGUBA JUNIOR SECONDARY SCHOOL",
    Address: "NGCWANGUBA LOC.  NGCWANGUBA A/A  MQANDULI",
    Latitude: "-31.9336",
    Longitude: "29.0299",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3603",
    WardLookupId: "717",
    VDNumber: "11590657",
    RegisteredPopulation: "392",
    VotingStationName: "VULINDLELA (THANDANANI) SENIOR PRIMARY SCHOOL",
    Address: "NGOJINI LOC.  UPPER NENGA  A/A  MQANDULI",
    Latitude: "-31.9555",
    Longitude: "29.0296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3604",
    WardLookupId: "717",
    VDNumber: "11590680",
    RegisteredPopulation: "653",
    VotingStationName: "KHULANI PRE- SCHOOL",
    Address: "ZIDINDI LOCATION  NGCWANGUBA A/A  MQANDULI",
    Latitude: "-31.991",
    Longitude: "29.0336",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3605",
    WardLookupId: "717",
    VDNumber: "11590725",
    RegisteredPopulation: "342",
    VotingStationName: "THWALIKHULU JUNIOR SECONDARY SCHOOL",
    Address: "THWALIKHULU LOC.  UPPER MPAKO A/A  MQANDULI",
    Latitude: "-31.9696",
    Longitude: "28.9746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3606",
    WardLookupId: "717",
    VDNumber: "11590961",
    RegisteredPopulation: "245",
    VotingStationName: "MPAKO JUNIOR SECONDARY SCHOOL",
    Address: "THWALIMOFU LOC.  MPAKO A/A  MQANDULI",
    Latitude: "-32.0135",
    Longitude: "29.0143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3607",
    WardLookupId: "717",
    VDNumber: "11591041",
    RegisteredPopulation: "547",
    VotingStationName: "MSUKENI DUTCH REFORMED CHURCH",
    Address: "MALINDINI LOCATION  MABHEHANA AA  MQANDULI",
    Latitude: "-31.9328",
    Longitude: "29.0822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3608",
    WardLookupId: "717",
    VDNumber: "11591074",
    RegisteredPopulation: "108",
    VotingStationName: "MOUNT BLAZES STORE TENT",
    Address: "DINGATA LOC  UPPER MPAKO A/A  MQANDULI",
    Latitude: "-31.9863",
    Longitude: "28.9824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3609",
    WardLookupId: "717",
    VDNumber: "11591085",
    RegisteredPopulation: "322",
    VotingStationName: "ZANOKHANYO SENIOR PRIMARY SCHOOL",
    Address: "NZULWINI LOCATION  UPPER NENGA A/A  MQANDULI",
    Latitude: "-31.9497",
    Longitude: "29.0512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3610",
    WardLookupId: "718",
    VDNumber: "11590264",
    RegisteredPopulation: "475",
    VotingStationName: "NGQINIKO JUNIOR SECONDARY SCHOOL",
    Address: "NGQINIKOLOC.  MBOZISA A/A  MQANDULI",
    Latitude: "-31.8402",
    Longitude: "28.8734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3611",
    WardLookupId: "718",
    VDNumber: "11590286",
    RegisteredPopulation: "812",
    VotingStationName: "ZANCI JUNIOR SECONDARY SCHOOL",
    Address: "ZANCI LOC  ZANCI A/A  MQANDULI",
    Latitude: "-31.8082",
    Longitude: "28.8383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3612",
    WardLookupId: "718",
    VDNumber: "11590512",
    RegisteredPopulation: "212",
    VotingStationName: "MTONJENI JUNIOR SECONDARY SCHOOL",
    Address: "MCOBOTHINI LOC.  QHINGQOLO A/A  MQANDULI",
    Latitude: "-31.784",
    Longitude: "28.8329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3613",
    WardLookupId: "718",
    VDNumber: "11590523",
    RegisteredPopulation: "443",
    VotingStationName: "MADWALWENI JUNIOR SECONDARY SCHOOL",
    Address: "KHENCEZAYO LOC.  NTLANGAZA  A/A  MQANDULI",
    Latitude: "-31.7706",
    Longitude: "28.8053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3614",
    WardLookupId: "718",
    VDNumber: "11590534",
    RegisteredPopulation: "747",
    VotingStationName: "MXAMBULE JUNIOR SECONDARY SCHOOL",
    Address: "MXAMBULE LOC.  NTLANGAZA A/A  MQANDULI",
    Latitude: "-31.8278",
    Longitude: "28.874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3615",
    WardLookupId: "718",
    VDNumber: "11590556",
    RegisteredPopulation: "445",
    VotingStationName: "HLABATSHANE JUNIOR SECONDARY SCHOOL",
    Address: "HLABATSHANE LOC.  HLABATSHANE A/A  MQANDULI",
    Latitude: "-31.7421",
    Longitude: "28.8659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3616",
    WardLookupId: "718",
    VDNumber: "11590602",
    RegisteredPopulation: "275",
    VotingStationName: "NTSHETHU JUNIOR SECONDARY SCHOOL",
    Address: "NTSHETHU LOC.  MAGOMBE A/A  MQANDULI",
    Latitude: "-31.7227",
    Longitude: "28.8556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3617",
    WardLookupId: "718",
    VDNumber: "11590646",
    RegisteredPopulation: "271",
    VotingStationName: "QINGQOLO JUNIOR SECONDARY SCHOOL",
    Address: "MAVUNDLENI LOC.  QINGQOLO A/A  MQANDULI",
    Latitude: "-31.7768",
    Longitude: "28.865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3618",
    WardLookupId: "718",
    VDNumber: "11590691",
    RegisteredPopulation: "362",
    VotingStationName: "DOBE JUNIOR SECONDARY SCHOOL",
    Address: "DOBE  LOC.  HLABATSHANE A/A  MQANDULI",
    Latitude: "-31.7665",
    Longitude: "28.8706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3619",
    WardLookupId: "718",
    VDNumber: "11590826",
    RegisteredPopulation: "323",
    VotingStationName: "UPPER NTLANGAZA JUNIOR SECONDARY SCHOOL",
    Address: "NTLANGAZA LOCALITY  NTLANGAZA A/A  MQANDULI",
    Latitude: "-31.7285",
    Longitude: "28.836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3620",
    WardLookupId: "718",
    VDNumber: "11591300",
    RegisteredPopulation: "127",
    VotingStationName: "SIGINGQINI LOCATION",
    Address: "SIGINGQINI LOCATION  LOWER NGQUNGQU A/A  MQANDULI",
    Latitude: "-31.8658",
    Longitude: "28.8474",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3621",
    WardLookupId: "718",
    VDNumber: "11591322",
    RegisteredPopulation: "184",
    VotingStationName: "MAVUNDLENI LOCATION TENT",
    Address: "KUMPOLOSE MAVUNDLENI LOCATION  QINGQOLO A/A  MQANDULI",
    Latitude: "-31.7914",
    Longitude: "28.8584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3622",
    WardLookupId: "718",
    VDNumber: "11591344",
    RegisteredPopulation: "258",
    VotingStationName: "NGINZA SENIOR PRIMARY SCHOOL",
    Address: "NGINZA  LOCATION  HLABATSHANE A/A  MQANDULI",
    Latitude: "-31.7157",
    Longitude: "28.8976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3623",
    WardLookupId: "718",
    VDNumber: "11591490",
    RegisteredPopulation: "335",
    VotingStationName: "JONGILANGA JUNIOR SECONDARY SCHOOL",
    Address: "PENDU  LOCATION  MAGOMBE A/A  MQANDULI",
    Latitude: "-31.7003",
    Longitude: "28.8691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3624",
    WardLookupId: "719",
    VDNumber: "11590095",
    RegisteredPopulation: "854",
    VotingStationName: "UPPER MPAKO SENIOR SECONDARY SCHOOL",
    Address: "KWAAIMAN LOC  KWAAIMAN A/A  MQANDULI",
    Latitude: "-31.9207",
    Longitude: "28.9761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3625",
    WardLookupId: "719",
    VDNumber: "11590118",
    RegisteredPopulation: "605",
    VotingStationName: "GQUBENI JUNIOR SECONDARY SCHOOL",
    Address: "GQUBENI LOCATION  UPPER NENGA A/A  MQANDULI",
    Latitude: "-31.8908",
    Longitude: "29.0522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3626",
    WardLookupId: "719",
    VDNumber: "11590129",
    RegisteredPopulation: "592",
    VotingStationName: "KWAAIMAN JUNIOR SECONDARY SCHOOL",
    Address: "DINGA LOC  NTSITSHANA A/A  MQANDULI",
    Latitude: "-31.9363",
    Longitude: "28.9596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3627",
    WardLookupId: "719",
    VDNumber: "11590152",
    RegisteredPopulation: "406",
    VotingStationName: "NGCENDUNA JUNIOR SECONDARY SCHOOL",
    Address: "NGCENDUNA LOC  MQANDULI A/A  MQANDULI",
    Latitude: "-31.8897",
    Longitude: "29.0313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3628",
    WardLookupId: "719",
    VDNumber: "11590163",
    RegisteredPopulation: "640",
    VotingStationName: "MNCWASA JUNIOR SECONDARY SCHOOL",
    Address: "KUNENE LOC.  MNCWASA A/A  MQANDULI",
    Latitude: "-31.9067",
    Longitude: "28.921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3629",
    WardLookupId: "719",
    VDNumber: "11590174",
    RegisteredPopulation: "611",
    VotingStationName: "PEZU KWEWILO JUNIOR SECONDARY SCHOOL",
    Address: "WILO A/A  WILO  LOCATION  MQANDULI",
    Latitude: "-31.8686",
    Longitude: "28.9763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3630",
    WardLookupId: "719",
    VDNumber: "11590905",
    RegisteredPopulation: "368",
    VotingStationName:
      "TEMPORARY VOTING STATION (KWAMDEPA - MPINDWENI LOCATION/GQUBENI LOCATION)",
    Address: "MPINDWENI LOC.  MABEHANA A/A  MQANDULI",
    Latitude: "-31.8782",
    Longitude: "29.0705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3631",
    WardLookupId: "719",
    VDNumber: "11591007",
    RegisteredPopulation: "308",
    VotingStationName: "NDLUNKULU JUNIOR SECONDARY SCHOOL  (TALA LOCATION)",
    Address: "TALA LOCATION  TALA LOCATION WILO A/A  MQANDULI",
    Latitude: "-31.8398",
    Longitude: "28.9673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3632",
    WardLookupId: "719",
    VDNumber: "11591018",
    RegisteredPopulation: "303",
    VotingStationName: "HAJI SENIOR PRIMARY SCHOOL",
    Address: "HAJI LOCATION  XORANA  A/A  MQANDULI",
    Latitude: "-31.9407",
    Longitude: "28.8929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3633",
    WardLookupId: "719",
    VDNumber: "11591052",
    RegisteredPopulation: "827",
    VotingStationName: "GONYA JUNIOR SECONDARY SCHOOL",
    Address: "MANANGENI LOCATION  NOTINTWA A/A  MQANDULI",
    Latitude: "-31.9126",
    Longitude: "29.0132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3634",
    WardLookupId: "719",
    VDNumber: "11591063",
    RegisteredPopulation: "381",
    VotingStationName: "MMANGWENI ANGLICAN CHURCH",
    Address: "MMANGWENI /BELUNGWINI LOC  KWAKAKANA/KWATSHOMANE  MQANDULI",
    Latitude: "-31.945",
    Longitude: "28.9776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3635",
    WardLookupId: "720",
    VDNumber: "11590422",
    RegisteredPopulation: "316",
    VotingStationName: "GASA JUNIOR SECONDARY SCHOOL",
    Address: "GASA LOCATION  CACADU A/A  MQANDULI",
    Latitude: "-31.862",
    Longitude: "28.6762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3636",
    WardLookupId: "720",
    VDNumber: "11590488",
    RegisteredPopulation: "689",
    VotingStationName: "MACOSA JUNIOR SECONDARY SCHOOL",
    Address: "MACOSA LOC.  CEZU A/A  MQANDULI",
    Latitude: "-31.8031",
    Longitude: "28.7106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3637",
    WardLookupId: "720",
    VDNumber: "11590499",
    RegisteredPopulation: "311",
    VotingStationName: "GWEBINKUNDLA JUNIOR SECONDARY SCHOOL",
    Address: "CEZU A/A  NGWEVANA LOCATION  MQANDULI",
    Latitude: "-31.8226",
    Longitude: "28.7292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3638",
    WardLookupId: "720",
    VDNumber: "11590501",
    RegisteredPopulation: "390",
    VotingStationName: "CEZU JUNIOR SECONDARY SCHOOL",
    Address: "CEZU LOC.  CEZU A/A  MQANDULI",
    Latitude: "-31.7719",
    Longitude: "28.6971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3639",
    WardLookupId: "720",
    VDNumber: "11590769",
    RegisteredPopulation: "274",
    VotingStationName: "VULINDLELA SENIOR PRIMARY SCHOOL",
    Address: "BLEKANA LOC  CACADU A/A  MQANDULI",
    Latitude: "-31.8463",
    Longitude: "28.6875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3640",
    WardLookupId: "720",
    VDNumber: "11590770",
    RegisteredPopulation: "443",
    VotingStationName: "ZWELAKHE JUNIOR SECONDARY SCHOOL",
    Address: "LUBALEKWENI LOC  CACADU A/A  MQANDULI",
    Latitude: "-31.8326",
    Longitude: "28.7103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3641",
    WardLookupId: "720",
    VDNumber: "11591399",
    RegisteredPopulation: "274",
    VotingStationName: "ASSEMBLIES OF GOD CHURCH HALL",
    Address: "NDAKANA LOCATION  LOWER NGQWARA A/A  MQANDULI",
    Latitude: "-31.7915",
    Longitude: "28.7312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3642",
    WardLookupId: "720",
    VDNumber: "11591401",
    RegisteredPopulation: "321",
    VotingStationName: "MAVUNDLENI LOCATION  (CEZU A/A)",
    Address: "MAVUNDLENI LOCATION  MAVUNDLENI LOCATION CEZU A/A  MQANDULI",
    Latitude: "-31.7829",
    Longitude: "28.705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3643",
    WardLookupId: "720",
    VDNumber: "11591423",
    RegisteredPopulation: "429",
    VotingStationName: "TAFENI METHODIST CHURCH HALL",
    Address: "TAFENI LOCATION  CEZU A/A  MQANDULI",
    Latitude: "-31.7617",
    Longitude: "28.6894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3644",
    WardLookupId: "720",
    VDNumber: "11591467",
    RegisteredPopulation: "263",
    VotingStationName: "ZAWULA METHODIST CHURCH HALL",
    Address: "ZAWULA LOCATION  UPPER NGQWARA A/A  MQANDULI",
    Latitude: "-31.7488",
    Longitude: "28.7049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3645",
    WardLookupId: "720",
    VDNumber: "11630494",
    RegisteredPopulation: "658",
    VotingStationName: "LOWER MATHEKO JUNIOR SECONDARY SCHOOL",
    Address: "MATHEKO LOCATION  MPUNZANA A/A  MTHATHA",
    Latitude: "-31.8014",
    Longitude: "28.6679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3646",
    WardLookupId: "720",
    VDNumber: "11630540",
    RegisteredPopulation: "497",
    VotingStationName: "SAKHELA JUNIOR SECONDARY SCHOOL",
    Address: "SAKHELA LOC.  CACADU A/A  MTHATHA",
    Latitude: "-31.8455",
    Longitude: "28.6391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3647",
    WardLookupId: "720",
    VDNumber: "11630551",
    RegisteredPopulation: "397",
    VotingStationName: "NGWENI JUNIOR SECONDARY SCHOOL",
    Address: "NGWENI LOC.  SITHEBE A/A  MTHATHA",
    Latitude: "-31.8523",
    Longitude: "28.6132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3648",
    WardLookupId: "720",
    VDNumber: "11630674",
    RegisteredPopulation: "553",
    VotingStationName: "KWELERANA JUNIOR SECONDARY SCHOOL",
    Address: "SAKHELA  LOCATION  CACADU A/A  MTHATHA",
    Latitude: "-31.8659",
    Longitude: "28.6186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3649",
    WardLookupId: "720",
    VDNumber: "11630832",
    RegisteredPopulation: "464",
    VotingStationName: "BALIZULU JUNIOR SECONDARY SCHOOL",
    Address: "KOTISHINI  LOC.  QUNU A/A  MTHATHA",
    Latitude: "-31.744",
    Longitude: "28.6839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3650",
    WardLookupId: "721",
    VDNumber: "11510019",
    RegisteredPopulation: "1253",
    VotingStationName: "MQANDULI MAGISTRATE",
    Address: "MAIN STREET  MQANDULI  KING SABATA DALINDYEBO",
    Latitude: "-31.8196",
    Longitude: "28.7627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3651",
    WardLookupId: "721",
    VDNumber: "11590545",
    RegisteredPopulation: "697",
    VotingStationName: "ZWELIVUMILE JUNIOR SECONDARY SCHOOL",
    Address: "NDLUNKULU LOC.  NGQWARA A/A  MQANDULI",
    Latitude: "-31.7421",
    Longitude: "28.7517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3652",
    WardLookupId: "721",
    VDNumber: "11590624",
    RegisteredPopulation: "359",
    VotingStationName: "UPPER NQGWARA JUNIOR SECONDARY SCHOOL",
    Address: "U/NQGWARA LOC.  NGQWARA A/A  MQANDULI",
    Latitude: "-31.7523",
    Longitude: "28.7204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3653",
    WardLookupId: "721",
    VDNumber: "11590703",
    RegisteredPopulation: "518",
    VotingStationName: "LOWER NGQWARA JUNIOR SECONDARY SCHOOL",
    Address: "L/NGWARA LOC.  NGQWARA A/A  MQANDULI",
    Latitude: "-31.7975",
    Longitude: "28.7702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3654",
    WardLookupId: "721",
    VDNumber: "11590781",
    RegisteredPopulation: "678",
    VotingStationName: "NXELE JUNIOR SECONDARY SCHOOL",
    Address: "LUBALEKWENI LOC  CACADU A/A  MQANDULI",
    Latitude: "-31.7625",
    Longitude: "28.7469",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3655",
    WardLookupId: "721",
    VDNumber: "11590804",
    RegisteredPopulation: "355",
    VotingStationName: "BANTWANANA JUNIOR SECONDARY SCHOOL",
    Address: "MAMPINGENI LOC.  NGQWARA A/A  MQANDULI",
    Latitude: "-31.7867",
    Longitude: "28.7408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3656",
    WardLookupId: "721",
    VDNumber: "11591377",
    RegisteredPopulation: "837",
    VotingStationName: "PHANGINDLELA JUNIOR SECONDARY SCHOOL",
    Address: "ZWELITSHA LOCATION  NGCANASINI A/A  MQANDULI",
    Latitude: "-31.8273",
    Longitude: "28.7606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3657",
    WardLookupId: "721",
    VDNumber: "11591388",
    RegisteredPopulation: "327",
    VotingStationName: "ZWELITSHA STORE",
    Address: "ZWELITSHA LOCATION  NGCANASINI A/A  MQANDULI",
    Latitude: "-31.8259",
    Longitude: "28.7465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3658",
    WardLookupId: "721",
    VDNumber: "11591434",
    RegisteredPopulation: "257",
    VotingStationName: "MNGUNI TRADING STORE",
    Address: "BAVUMA LOCATION  UPPER NGQWARA ADMIN AREA  MQANDULI",
    Latitude: "-31.7413",
    Longitude: "28.7013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3659",
    WardLookupId: "721",
    VDNumber: "11591445",
    RegisteredPopulation: "265",
    VotingStationName: "BAMBANANI PRE-SCHOOL",
    Address: "MANDZOTHWENI LOCATION (MACHIBINI )  UPPER NGQWARA A/A  MQANDULI",
    Latitude: "-31.7424",
    Longitude: "28.7327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3660",
    WardLookupId: "721",
    VDNumber: "11591456",
    RegisteredPopulation: "135",
    VotingStationName: "NDLUNKULU JUNIOR SECONDARY SCHOOL",
    Address: "NDLUNKULU LOCATION  UPPER NGQWARA A/A  MQANDULI",
    Latitude: "-31.7617",
    Longitude: "28.7719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3661",
    WardLookupId: "721",
    VDNumber: "11591478",
    RegisteredPopulation: "351",
    VotingStationName: "GCINA MANQABE STORE",
    Address: "LOWER NGQWARA LOC.  KING SABATA DALINDYEBO [UMTATA]",
    Latitude: "-31.8067",
    Longitude: "28.7854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3662",
    WardLookupId: "721",
    VDNumber: "11591489",
    RegisteredPopulation: "387",
    VotingStationName: "MHLAKULO SENIOR PRIMARY SCHOOL",
    Address: "MHLAKULO LOCATION  NTLANGAZA  A/A  MQANDULI",
    Latitude: "-31.7861",
    Longitude: "28.8039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3663",
    WardLookupId: "722",
    VDNumber: "11630236",
    RegisteredPopulation: "900",
    VotingStationName: "LYNDALE JUNIOR SECONDARY SCHOOL",
    Address: "LINDILE LOC  MPEKO A/A  MTHATHA",
    Latitude: "-31.6106",
    Longitude: "28.6997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3664",
    WardLookupId: "722",
    VDNumber: "11630281",
    RegisteredPopulation: "1106",
    VotingStationName: "ZWELI SENIOR PRIMARY SCHOOL",
    Address: "BONGWENI  LOCATION  ZIMBANE A/A  MTHATHA",
    Latitude: "-31.6296",
    Longitude: "28.7512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3665",
    WardLookupId: "722",
    VDNumber: "11630326",
    RegisteredPopulation: "1880",
    VotingStationName: "A.V.PLAATJIE SENIOR SECONDARY SCHOOL",
    Address: "NEW PAYNE LOCATION  LINDILE A/A  MTHATHA",
    Latitude: "-31.6334",
    Longitude: "28.7319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3666",
    WardLookupId: "722",
    VDNumber: "11630696",
    RegisteredPopulation: "1676",
    VotingStationName: "JERSEY FARM JUNIOR SECONDARY SCHOOL",
    Address: "PAYNE LOC.  MPEKO A/A  MTHATHA",
    Latitude: "-31.6333",
    Longitude: "28.7231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3667",
    WardLookupId: "722",
    VDNumber: "11631608",
    RegisteredPopulation: "531",
    VotingStationName: "ST AUGUSTINE CHURCH HALL",
    Address: "OLD PAYNE FARMS  PAYNE A/A  MTHATHA",
    Latitude: "-31.6248",
    Longitude: "28.7179",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3668",
    WardLookupId: "722",
    VDNumber: "11631619",
    RegisteredPopulation: "668",
    VotingStationName: "NEW CHRISTIAN CENTRE INTERNATIONAL",
    Address: "NEW PAYNE LOCATION  LINDILE A/A  MTHATHA",
    Latitude: "-31.6432",
    Longitude: "28.7245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3669",
    WardLookupId: "723",
    VDNumber: "11630382",
    RegisteredPopulation: "520",
    VotingStationName: "NTABENI JUNIOR SECONDARY SCHOOL",
    Address: "LUKHWETHU LOC.  XWILI A/A  MTHATHA",
    Latitude: "-31.711",
    Longitude: "28.589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3670",
    WardLookupId: "723",
    VDNumber: "11630405",
    RegisteredPopulation: "828",
    VotingStationName: "UPPER CENTULI JUNIOR SECONDARY SCHOOL",
    Address: "UPPER CENTULI LOC  MAKAULA A/A  MTHATHA",
    Latitude: "-31.6872",
    Longitude: "28.5682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3671",
    WardLookupId: "723",
    VDNumber: "11630438",
    RegisteredPopulation: "317",
    VotingStationName: "UPPER XHONGORA JUNIOR SECONDARY SCHOOL",
    Address: "XONGORA LOC.  XONGORA T/A  MTHATHA",
    Latitude: "-31.6727",
    Longitude: "28.5171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3672",
    WardLookupId: "723",
    VDNumber: "11630652",
    RegisteredPopulation: "450",
    VotingStationName: "MPANDELA JUNIOR SECONDARY SCHOOL",
    Address: "MPANDELA LOC.  MQEKEZWENI A/A  MTHATHA",
    Latitude: "-31.7066",
    Longitude: "28.5187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3673",
    WardLookupId: "723",
    VDNumber: "11630685",
    RegisteredPopulation: "637",
    VotingStationName: "LUZINI JUNIOR SECONDARY SCHOOL",
    Address: "CHINA LOC  XWILI A/A  MTHATHA",
    Latitude: "-31.7421",
    Longitude: "28.5467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3674",
    WardLookupId: "723",
    VDNumber: "11630720",
    RegisteredPopulation: "330",
    VotingStationName: "ESIKOBENI JUNIOR SECONDARY SCHOOL",
    Address: "SIKHOBENI LOC.  SIKOBENI A/A  MTHATHA",
    Latitude: "-31.6431",
    Longitude: "28.5571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3675",
    WardLookupId: "723",
    VDNumber: "11631282",
    RegisteredPopulation: "261",
    VotingStationName: "MAJOLA SENIOR PRIMARY SCHOOL",
    Address: "KWAMHAGA LOCATION  UPPER XHONGORHA A/A  MTHATHA",
    Latitude: "-31.6863",
    Longitude: "28.5216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3676",
    WardLookupId: "723",
    VDNumber: "11631349",
    RegisteredPopulation: "742",
    VotingStationName: "JONGIBANDLA JUNIOR SECONDARY SCHOOL",
    Address: "CENTULE LOCATION  SIKHOBENI A/A  MTHATHA",
    Latitude: "-31.6657",
    Longitude: "28.6226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3677",
    WardLookupId: "723",
    VDNumber: "11631383",
    RegisteredPopulation: "536",
    VotingStationName: "KHULANATHI SENIOR SECONDARY SCHOOL",
    Address: "SIKHOBENI LOCATION  SIKHOBENI A/A  MTHATHA",
    Latitude: "-31.6551",
    Longitude: "28.5137",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3678",
    WardLookupId: "723",
    VDNumber: "11631417",
    RegisteredPopulation: "273",
    VotingStationName: "LUVUYWENI JUNIOR PRIMARY SCHOOL",
    Address: "EPAKINI LOCATION  MPEKO A/A  MTHATHA",
    Latitude: "-31.622",
    Longitude: "28.5842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3679",
    WardLookupId: "723",
    VDNumber: "11631439",
    RegisteredPopulation: "638",
    VotingStationName: "ASSEMBLIES OF GOD",
    Address: "KWATSHUTSHA LOCATION  MPEKO A/A  MTHATHA",
    Latitude: "-31.6329",
    Longitude: "28.6294",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3680",
    WardLookupId: "724",
    VDNumber: "11630304",
    RegisteredPopulation: "373",
    VotingStationName: "SPRINGVALE JUNIOR SECONDARY SCHOOL",
    Address: "MAQHINEBENI LOC.  ZIMBANE A/A  MTHATHA",
    Latitude: "-31.7065",
    Longitude: "28.7016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3681",
    WardLookupId: "724",
    VDNumber: "11630371",
    RegisteredPopulation: "638",
    VotingStationName: "GWEGWE JUNIOR SECONDARY SCHOOL",
    Address: "GWEGWE LOC.  XWILI T/A  MTHATHA",
    Latitude: "-31.6926",
    Longitude: "28.6336",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3682",
    WardLookupId: "724",
    VDNumber: "11630393",
    RegisteredPopulation: "503",
    VotingStationName: "XWILI PRIMARY JUNIOR SCHOOL",
    Address: "XWILI LOC.  XWILI A/A  MTHATHA",
    Latitude: "-31.745",
    Longitude: "28.5782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3683",
    WardLookupId: "724",
    VDNumber: "11630517",
    RegisteredPopulation: "754",
    VotingStationName: "MABHELENI JUNIOR SECONDARY SCHOOL",
    Address: "MABHELENI LOC.  XWILI A/A  MTHATHA",
    Latitude: "-31.7378",
    Longitude: "28.6667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3684",
    WardLookupId: "724",
    VDNumber: "11630865",
    RegisteredPopulation: "782",
    VotingStationName: "XHUGXWALA JUNIOR SECONDARY SCHOOL",
    Address: "XHUGXWALA LOC.  XWILI  A/A  MTHATHA",
    Latitude: "-31.7086",
    Longitude: "28.6644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3685",
    WardLookupId: "724",
    VDNumber: "11631316",
    RegisteredPopulation: "311",
    VotingStationName: "ZIHASINI METHODIST CHURCH",
    Address: "LUKHWETHU LOCATION  XWILI A/A  MTHATHA",
    Latitude: "-31.7359",
    Longitude: "28.5941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3686",
    WardLookupId: "724",
    VDNumber: "11631327",
    RegisteredPopulation: "543",
    VotingStationName: "MATYENGQINA TRIBAL AUTHORITY",
    Address: "MATYENGQINA LOCATION  XHWILI A/A  UMTATA",
    Latitude: "-31.7476",
    Longitude: "28.6219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3687",
    WardLookupId: "724",
    VDNumber: "11631338",
    RegisteredPopulation: "524",
    VotingStationName: "BAMBILANGA JUNIOR SECONDARY SCHOOL",
    Address: "MATYENGQINA LOCATION  XHWILI A/A  MTHATHA",
    Latitude: "-31.7338",
    Longitude: "28.6131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3688",
    WardLookupId: "724",
    VDNumber: "11631361",
    RegisteredPopulation: "579",
    VotingStationName: "RISE AND SHINE PRE SCHOOL",
    Address: "VIEDGESVILLE LOCATION  QWEQWE A/A  MTHATHA",
    Latitude: "-31.7181",
    Longitude: "28.6927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3689",
    WardLookupId: "724",
    VDNumber: "11631451",
    RegisteredPopulation: "369",
    VotingStationName: "LUTHUTHU JUNIOR SECONDARY SCHOOL",
    Address: "LUTHUTHU LOCATION  MPEKO A/A  MTHATHA",
    Latitude: "-31.6564",
    Longitude: "28.6614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3690",
    WardLookupId: "724",
    VDNumber: "11631743",
    RegisteredPopulation: "355",
    VotingStationName: "NEW TOWN FAITH MISSION CHURCH",
    Address: "NEW BRIGHT LOCATION/KOTISHINI LOCATION  VIEDGESVILLE  UMTATA",
    Latitude: "-31.7487",
    Longitude: "28.662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3691",
    WardLookupId: "724",
    VDNumber: "11631822",
    RegisteredPopulation: "98",
    VotingStationName: "AME CHURCH HALL",
    Address: "MAQINGENI LOCATION  ZIMBANE A/A  MTHATHA",
    Latitude: "-31.7223",
    Longitude: "28.7281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3692",
    WardLookupId: "724",
    VDNumber: "11631956",
    RegisteredPopulation: "138",
    VotingStationName: "TWIN TOWER SHOP",
    Address: "NGQWALA LOCATION  NGQWALA A/A  MTHATHA",
    Latitude: "-31.7004",
    Longitude: "28.7027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3693",
    WardLookupId: "725",
    VDNumber: "11630269",
    RegisteredPopulation: "483",
    VotingStationName: "ZITHULELE SENIOR PRIMARY SCHOOL",
    Address: "MAZIZINI LOC.  SANGONI A/A  MTHATHA",
    Latitude: "-31.6554",
    Longitude: "28.7287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3694",
    WardLookupId: "725",
    VDNumber: "11630292",
    RegisteredPopulation: "749",
    VotingStationName: "EMAWELENI JUNIOR SECONDARY SCHOOL",
    Address: "NTABENI LOC.  QWEQWE A/A  MTHATHA",
    Latitude: "-31.6622",
    Longitude: "28.7167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3695",
    WardLookupId: "725",
    VDNumber: "11630315",
    RegisteredPopulation: "435",
    VotingStationName: "NTSHELE JUNIOR SECONDARY SCHOOL",
    Address: "NTSHELE LOC.  ZIMBANE A/A  MTHATHA",
    Latitude: "-31.6725",
    Longitude: "28.7225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3696",
    WardLookupId: "725",
    VDNumber: "11631462",
    RegisteredPopulation: "436",
    VotingStationName: "JONGISIZWE JUNIOR SECONDARY SCHOOL",
    Address: "UPPER QWEQWE LOCATION  QWEQWE A/A  MTHATHA",
    Latitude: "-31.6846",
    Longitude: "28.69",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3697",
    WardLookupId: "725",
    VDNumber: "11631473",
    RegisteredPopulation: "732",
    VotingStationName: "QWEQWE JUNIOR SECONDARY SCHOOL",
    Address: "QWEQWE LOC  QWEQWE A/A  MTHATHA",
    Latitude: "-31.6538",
    Longitude: "28.7061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3698",
    WardLookupId: "725",
    VDNumber: "11631798",
    RegisteredPopulation: "284",
    VotingStationName: "MBEKWENI SENIOR PRIMARY SCHOOL",
    Address: "MAQHINEBENI LOC  ZIMBANE A/A  MTHATHA",
    Latitude: "-31.6925",
    Longitude: "28.7071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3699",
    WardLookupId: "725",
    VDNumber: "11631855",
    RegisteredPopulation: "1190",
    VotingStationName: "TAMSANQA JUNIOR SECONDARY SCHOOL",
    Address: "MABHELENI LOCATION  ZIMBANE A/A  MTHATHA",
    Latitude: "-31.6513",
    Longitude: "28.7631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3700",
    WardLookupId: "725",
    VDNumber: "11631866",
    RegisteredPopulation: "951",
    VotingStationName: "UPPER ZIMBANE JUNIOR SECONDARY SCHOOL",
    Address: "NTLEKISA LOC  MPEKO A/A  MTHATHA",
    Latitude: "-31.6395",
    Longitude: "28.7423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3701",
    WardLookupId: "725",
    VDNumber: "11631912",
    RegisteredPopulation: "160",
    VotingStationName: "KWANTSHELE LOCATION",
    Address: "KWANTSHELE LOCATION  MTHATHA",
    Latitude: "-31.6721",
    Longitude: "28.734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3702",
    WardLookupId: "726",
    VDNumber: "11630012",
    RegisteredPopulation: "870",
    VotingStationName: "MPAFANE JUNIOR SECONDARY SCHOOL",
    Address: "MPAFANE LOC.  TABASE A/A  MTHATHA",
    Latitude: "-31.5065",
    Longitude: "28.4787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3703",
    WardLookupId: "726",
    VDNumber: "11630090",
    RegisteredPopulation: "420",
    VotingStationName: "NGQUNGE JUNIOR SECONDARY SCHOOL",
    Address: "NGQUNGE LOC.  TABASE T/A  MTHATHA",
    Latitude: "-31.5869",
    Longitude: "28.5225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3704",
    WardLookupId: "726",
    VDNumber: "11630146",
    RegisteredPopulation: "677",
    VotingStationName: "DUMRANA JUNIOR SECONDARY SCHOOL",
    Address: "DUMRANA LOC.  TABASE T/A  MTHATHA",
    Latitude: "-31.604",
    Longitude: "28.5804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3705",
    WardLookupId: "726",
    VDNumber: "11630214",
    RegisteredPopulation: "558",
    VotingStationName: "UPPER TABASE JUNIOR SECONDARY SCHOOL",
    Address: "TABASE LOC.  TABASE A/A  MTHATHA",
    Latitude: "-31.6343",
    Longitude: "28.5201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3706",
    WardLookupId: "726",
    VDNumber: "11630360",
    RegisteredPopulation: "547",
    VotingStationName: "DUKATHOLE JUNIOR SECONDARY SCHOOL",
    Address: "DUKATHOLE LOC.  TABASE A/A  MTHATHA",
    Latitude: "-31.5589",
    Longitude: "28.5172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3707",
    WardLookupId: "726",
    VDNumber: "11630573",
    RegisteredPopulation: "544",
    VotingStationName: "MBOLOMPO COMPREHENSIVE SCHOOL",
    Address: "MBOLOMPO LOC.  TABASE A/A  MTHATHA",
    Latitude: "-31.5237",
    Longitude: "28.5023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3708",
    WardLookupId: "726",
    VDNumber: "11631024",
    RegisteredPopulation: "365",
    VotingStationName: "MANYOSINI SENIOR PRIMARY SCHOOL",
    Address: "MANYOSINI LOC  TABASE A/A  MTHATHA",
    Latitude: "-31.5186",
    Longitude: "28.5245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3709",
    WardLookupId: "726",
    VDNumber: "11631035",
    RegisteredPopulation: "543",
    VotingStationName: "LUGXOGXO JUNIOR SECONDARY SCHOOL",
    Address: "TABASE LOC  TABASE A/A  UMTATA",
    Latitude: "-31.5445",
    Longitude: "28.5309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3710",
    WardLookupId: "726",
    VDNumber: "11631046",
    RegisteredPopulation: "213",
    VotingStationName: "NGQUNGE ROMAN CATHOLIC CHURCH",
    Address: "NGQUNGE LOCATION  TABASE A/A  UMTATA",
    Latitude: "-31.5843",
    Longitude: "28.519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3711",
    WardLookupId: "726",
    VDNumber: "11631114",
    RegisteredPopulation: "606",
    VotingStationName: "KHANYISA JUNIOR SECONDARY SCHOOL",
    Address: "MBOZISA LOCATION  MBOZISA A/A  MTHATHA",
    Latitude: "-31.5714",
    Longitude: "28.4713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3712",
    WardLookupId: "726",
    VDNumber: "11631899",
    RegisteredPopulation: "263",
    VotingStationName: "MDENI SENIOR PRIMARY SCHOOL",
    Address: "TABASE A/A  MTHATHA",
    Latitude: "-31.5656",
    Longitude: "28.5499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3713",
    WardLookupId: "726",
    VDNumber: "11631945",
    RegisteredPopulation: "230",
    VotingStationName: "PHAKAMISA JUNIOR SECONDARY SCHOOL",
    Address: "MAMPINGENI LOCATION  TABASE A/A  MTHATHA",
    Latitude: "-31.5971",
    Longitude: "28.4925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3714",
    WardLookupId: "727",
    VDNumber: "11560531",
    RegisteredPopulation: "395",
    VotingStationName: "TEMBENI SPORTSGROUND TENT",
    Address: "ZIMBANE LOCATION  ZIMBANE A/A  MTHATHA",
    Latitude: "-31.6369",
    Longitude: "28.7903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3715",
    WardLookupId: "727",
    VDNumber: "11590567",
    RegisteredPopulation: "370",
    VotingStationName: "JIXINI SENIOR SECONDARY SCHOOL",
    Address: "JIXINI LOC.  JIXINI A/A  MQANDULI",
    Latitude: "-31.71",
    Longitude: "28.8417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3716",
    WardLookupId: "727",
    VDNumber: "11590578",
    RegisteredPopulation: "931",
    VotingStationName: "QOKOLWENI JUNIOR SECONDARY SCHOOL",
    Address: "QOKOLWENI LOC.  QOKOLWENI A/A  MQANDULI",
    Latitude: "-31.7174",
    Longitude: "28.7811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3717",
    WardLookupId: "727",
    VDNumber: "11590792",
    RegisteredPopulation: "341",
    VotingStationName: "MAWENI JUNIOR SECONDARY SCHOOL",
    Address: "MAWENI LOCATION  QOKOLWENI A/A  MQANDULI",
    Latitude: "-31.7152",
    Longitude: "28.8193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3718",
    WardLookupId: "727",
    VDNumber: "11590837",
    RegisteredPopulation: "841",
    VotingStationName: "NGCWALA  METHODIST CHURCH",
    Address: "NGCWALA LOCALITY  NTLANGAZA A/A  MQANDULI",
    Latitude: "-31.7328",
    Longitude: "28.7803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3719",
    WardLookupId: "727",
    VDNumber: "11630270",
    RegisteredPopulation: "921",
    VotingStationName: "SANGONI JUNIOR SECONDARY SCHOOL",
    Address: "SANGONI LOC.  SANGONI A/A  MTHATHA",
    Latitude: "-31.6923",
    Longitude: "28.771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3720",
    WardLookupId: "727",
    VDNumber: "11630618",
    RegisteredPopulation: "825",
    VotingStationName: "TYUMBU JUNIOR SECONDARY SCHOOL",
    Address: "TYUMBU LOCATION  QOKOLWENI A/A  MTHATHA",
    Latitude: "-31.6489",
    Longitude: "28.7898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3721",
    WardLookupId: "727",
    VDNumber: "11630708",
    RegisteredPopulation: "414",
    VotingStationName: "MAXHWELE COMMUNITY HALL",
    Address: "MAXHWELE LOC.  ZIMBANE A/A  MTHATHA",
    Latitude: "-31.7057",
    Longitude: "28.7456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3722",
    WardLookupId: "727",
    VDNumber: "11631787",
    RegisteredPopulation: "427",
    VotingStationName: "VULINKUNDLA JUNIOR SECONDARY SCHOOL",
    Address: "NDISANE LOC  SANGONI A/A  MTHATHA",
    Latitude: "-31.6793",
    Longitude: "28.7827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3723",
    WardLookupId: "727",
    VDNumber: "11631833",
    RegisteredPopulation: "219",
    VotingStationName: "TYABAKAZI STORE",
    Address: "ZIMBANE LOC  ZIMBANE A/A  MTHATHA",
    Latitude: "-31.6931",
    Longitude: "28.7244",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3724",
    WardLookupId: "727",
    VDNumber: "11631923",
    RegisteredPopulation: "242",
    VotingStationName: "MANDLOVINI FAITH MISSION CHURCH",
    Address: "MANDLOVINI LOCATION  MAXHWELE A/A  MTHATHA",
    Latitude: "-31.7027",
    Longitude: "28.7178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3725",
    WardLookupId: "728",
    VDNumber: "11590185",
    RegisteredPopulation: "755",
    VotingStationName: "LUTHUBENI SENIOR SECONDARY SCHOOL",
    Address: "LUTHUBENI LOC.  LUCWECWE A/A  MQANDULI",
    Latitude: "-31.9247",
    Longitude: "28.8821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3726",
    WardLookupId: "728",
    VDNumber: "11590208",
    RegisteredPopulation: "611",
    VotingStationName: "MANGQOBE JUNIOR SECONDARY SCHOOL",
    Address: "MANGQOBE LOC.  LWANDLANA A/A  MTHATHA",
    Latitude: "-31.9124",
    Longitude: "28.8661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3727",
    WardLookupId: "728",
    VDNumber: "11590242",
    RegisteredPopulation: "611",
    VotingStationName: "MBOZISA (MQANDULI) JUNIOR SECONDARY SCHOOL",
    Address: "MBOZISA LOC.  MBOZISA A/A  MQANDULI",
    Latitude: "-31.8958",
    Longitude: "28.8934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3728",
    WardLookupId: "728",
    VDNumber: "11590253",
    RegisteredPopulation: "479",
    VotingStationName: "ZWELEBANGO JUNIOR SECONDARY SCHOOL",
    Address: "LWANDLANA  LOCATION  LUCWECWE A/A  MQANDULI",
    Latitude: "-31.8818",
    Longitude: "28.8266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3729",
    WardLookupId: "728",
    VDNumber: "11590590",
    RegisteredPopulation: "1267",
    VotingStationName: "WILO JUNIOR SECONDARY SCHOOL",
    Address: "WILO LOC  WILO A/A  MQANDULI",
    Latitude: "-31.8876",
    Longitude: "28.9556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3730",
    WardLookupId: "728",
    VDNumber: "11590668",
    RegisteredPopulation: "394",
    VotingStationName: "CORANA JUNIOR SECONDARY SCHOOL.",
    Address: "CORANA LOC  LOWER NGQUNGQU A/A  MQANDULI",
    Latitude: "-31.8482",
    Longitude: "28.8586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3731",
    WardLookupId: "728",
    VDNumber: "11590815",
    RegisteredPopulation: "450",
    VotingStationName: "PAZIMA JUNIOR SECONDARY SCHOOL",
    Address: "PAZIMA LOC  NTLANGAZA A/A  MQANDULI",
    Latitude: "-31.827",
    Longitude: "28.9119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3732",
    WardLookupId: "728",
    VDNumber: "11590848",
    RegisteredPopulation: "419",
    VotingStationName: "SIDANDA JUNIOR SECONDARY SCHOOL",
    Address: "SIDANDA LOC  MBOZISA A/A  MQANDULI",
    Latitude: "-31.8541",
    Longitude: "28.9032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3733",
    WardLookupId: "728",
    VDNumber: "11590871",
    RegisteredPopulation: "277",
    VotingStationName: "VULINKETHE SENIOR PRIMARY SCHOOL",
    Address: "LUCWECWE LOCALITY  LUTUBENI A/A  MQANDULI",
    Latitude: "-31.9553",
    Longitude: "28.8784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3734",
    WardLookupId: "728",
    VDNumber: "11591311",
    RegisteredPopulation: "176",
    VotingStationName: "THWALUPHAHLA JUNIOR SECONDARY",
    Address: "MPINDWENI LOCATION  NTLANGAZA A/A  MQANDULI",
    Latitude: "-31.811",
    Longitude: "28.9196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3735",
    WardLookupId: "729",
    VDNumber: "11780210",
    RegisteredPopulation: "1491",
    VotingStationName: "ZAMOKUHLE JUNIOR SECONDARY SCHOOL",
    Address: "MALUTI T/SHIP  MALUTI",
    Latitude: "-30.2602",
    Longitude: "28.7823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3736",
    WardLookupId: "729",
    VDNumber: "11780232",
    RegisteredPopulation: "2367",
    VotingStationName: "MALUTI DEVELOPMENT CENTRE",
    Address: "MALUTI TOWNSHIP  MALUTI",
    Latitude: "-30.2613",
    Longitude: "28.7958",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3737",
    WardLookupId: "729",
    VDNumber: "11780760",
    RegisteredPopulation: "972",
    VotingStationName: "MALUTI JUNIOR SECONDARY SCHOOL",
    Address: "MALUTI TOWNSHIP  MALUTI",
    Latitude: "-30.26",
    Longitude: "28.7911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3738",
    WardLookupId: "730",
    VDNumber: "11780209",
    RegisteredPopulation: "841",
    VotingStationName: "MALUBALUBE  TRIBAL AUTHORITY",
    Address: "RAMOHLAKOANA  MALUTI",
    Latitude: "-30.273",
    Longitude: "28.7897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3739",
    WardLookupId: "730",
    VDNumber: "11780265",
    RegisteredPopulation: "462",
    VotingStationName: "MAPHELENG PRE-SCHOOL",
    Address: "HARDENBURG VILLAGE  HARDENBURG  MALUTI",
    Latitude: "-30.2436",
    Longitude: "28.7956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3740",
    WardLookupId: "730",
    VDNumber: "11780748",
    RegisteredPopulation: "773",
    VotingStationName: "RAMOHLAKOANA JUNIOR SECONDARY SCHOOL",
    Address: "RAMOHLAKOANA LOCATION  RAMOHLAKOANA A/A  MALUTI",
    Latitude: "-30.2796",
    Longitude: "28.7929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3741",
    WardLookupId: "730",
    VDNumber: "11780759",
    RegisteredPopulation: "744",
    VotingStationName: "MOLIKO SENIOR PRIMARY SCHOOL",
    Address: "N/A  RAMOHLAKOANA A/A  MALUTI",
    Latitude: "-30.2685",
    Longitude: "28.7809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3742",
    WardLookupId: "730",
    VDNumber: "11780771",
    RegisteredPopulation: "1111",
    VotingStationName: "MALUBALUBE JUNIOR SECONDARY SCHOOL",
    Address: "MALUBALUBE LOCATION  SIBI A/A  MALUTI",
    Latitude: "-30.2778",
    Longitude: "28.8106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3743",
    WardLookupId: "731",
    VDNumber: "11780243",
    RegisteredPopulation: "1230",
    VotingStationName: "MASAKALA JUNIOR SECONDARY SCHOOL",
    Address: "MASAKALA LOCATION  MASAKALA  A/A  MALUTI",
    Latitude: "-30.3022",
    Longitude: "28.8507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3744",
    WardLookupId: "731",
    VDNumber: "11780254",
    RegisteredPopulation: "470",
    VotingStationName: "MANASE JUNIOR SECONDARY SCHOOL",
    Address: "DIKHOTLOANENG  SIBI A/A  MALUTI",
    Latitude: "-30.2735",
    Longitude: "28.8754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3745",
    WardLookupId: "731",
    VDNumber: "11780276",
    RegisteredPopulation: "510",
    VotingStationName: "HEBRON JUNIOR SECONDARY SCHOOL",
    Address: "HEBRON LOCATION  SIBI A/A  MALUTI",
    Latitude: "-30.2536",
    Longitude: "28.84",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3746",
    WardLookupId: "731",
    VDNumber: "11780782",
    RegisteredPopulation: "293",
    VotingStationName: "BAVUMILE SENIOR PRIMARY SCHOOL",
    Address: "KHOHLONG LOCATION  MASAKALA A/A  MALUTI",
    Latitude: "-30.2979",
    Longitude: "28.8667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3747",
    WardLookupId: "731",
    VDNumber: "11780805",
    RegisteredPopulation: "349",
    VotingStationName: "MALIMONG COMMUNITY HALL",
    Address: "HEBRON LOCATION  SISI A/A  MALUTI",
    Latitude: "-30.2502",
    Longitude: "28.8641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3748",
    WardLookupId: "731",
    VDNumber: "11781143",
    RegisteredPopulation: "972",
    VotingStationName: "TSEPISONG JUNIOR SECONDARY SCHOOL",
    Address: "TSEPISONG  MASAKALA A/A  MATATIELE",
    Latitude: "-30.2995",
    Longitude: "28.8229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3749",
    WardLookupId: "732",
    VDNumber: "11780300",
    RegisteredPopulation: "641",
    VotingStationName: "BETHEL JUNIOR SECONDARY SCHOOL",
    Address: "BETHEL LOCATION  SIBI A/A  MALUTI",
    Latitude: "-30.2212",
    Longitude: "28.844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3750",
    WardLookupId: "732",
    VDNumber: "11780311",
    RegisteredPopulation: "491",
    VotingStationName: "MAZIZINI JUNIOR PRIMARY SCHOOL",
    Address: "MAZIZINI  MAZIZINI A/A  MALUTI",
    Latitude: "-30.2111",
    Longitude: "28.8348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3751",
    WardLookupId: "732",
    VDNumber: "11780681",
    RegisteredPopulation: "306",
    VotingStationName: "PHAHAMANG JUNIOR SECONDARY SCHOOL.",
    Address: "MAPHOKONG  SIBI A/A  `MALUTI",
    Latitude: "-30.1921",
    Longitude: "28.8528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3752",
    WardLookupId: "732",
    VDNumber: "11780692",
    RegisteredPopulation: "461",
    VotingStationName: "EDWARDS HOPE JUNIOR SECONDARY SCHOOL",
    Address: "SEHLABENG LOCATION  SIBI A/A  MALUTI",
    Latitude: "-30.1682",
    Longitude: "28.8793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3753",
    WardLookupId: "732",
    VDNumber: "11780816",
    RegisteredPopulation: "800",
    VotingStationName: "PROSPECT JUNIOR SECONDARY SCHOOL",
    Address: "PROSPECT LOCATION  SIBI A/A  MALUTI",
    Latitude: "-30.2365",
    Longitude: "28.8802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3754",
    WardLookupId: "732",
    VDNumber: "11780827",
    RegisteredPopulation: "364",
    VotingStationName: "NKASELE JUNIOR SECONDARY SCHOOL",
    Address: "NKASELA LOCATION  NCHODU A/A  MALUTI",
    Latitude: "-30.2048",
    Longitude: "28.7659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3755",
    WardLookupId: "732",
    VDNumber: "11780849",
    RegisteredPopulation: "260",
    VotingStationName: "TSITSONG JUNIOR SECONDARY SCHOOL",
    Address: "TSITSONG LOCATION  SIBI A/A  MALUTI",
    Latitude: "-30.2127",
    Longitude: "28.7981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3756",
    WardLookupId: "732",
    VDNumber: "11781288",
    RegisteredPopulation: "378",
    VotingStationName: "PHELLANG PRE-SCHOOL",
    Address: "ZIKHALINI  SIBI  SIBI",
    Latitude: "-30.2085",
    Longitude: "28.8486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3757",
    WardLookupId: "733",
    VDNumber: "11780377",
    RegisteredPopulation: "973",
    VotingStationName: "CHIBINI JUNIOR SECONDARY SCHOOL",
    Address: "CHIBINI  MALUTI",
    Latitude: "-30.1646",
    Longitude: "29.0607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3758",
    WardLookupId: "733",
    VDNumber: "11780399",
    RegisteredPopulation: "413",
    VotingStationName: "LUFEFENI JUNIOR SECONDARY  SCHOOL",
    Address: "LUFEFENI LOCATION  MZONGWANA A/A  MALUTI",
    Latitude: "-30.1233",
    Longitude: "29.1049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3759",
    WardLookupId: "733",
    VDNumber: "11780401",
    RegisteredPopulation: "988",
    VotingStationName: "PAMLAVILLE JUNIOR SECONDARY SCHOOL",
    Address: "PAMLAVILLE LOCATION  MZONGWANA A/A  MALUTI",
    Latitude: "-30.1158",
    Longitude: "29.0629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3760",
    WardLookupId: "733",
    VDNumber: "11780614",
    RegisteredPopulation: "287",
    VotingStationName: "MOSTER ROMAN CATHOLIC CHURCH",
    Address: "CHIBINI  MZONGWANA A/A  MALUTI",
    Latitude: "-30.1678",
    Longitude: "29.0809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3761",
    WardLookupId: "733",
    VDNumber: "11780625",
    RegisteredPopulation: "312",
    VotingStationName: "ZWELITSHA JUNIOR SECONDARY SCHOOL",
    Address: "GOXE LOCATION  MZONGWANA A/A  MALUTI",
    Latitude: "-30.1454",
    Longitude: "29.0887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3762",
    WardLookupId: "733",
    VDNumber: "11780726",
    RegisteredPopulation: "593",
    VotingStationName: "LAMEKA JUNIOR SECONDARY SCHOOL",
    Address: "LUBALEKO LOCATION  MAKHOBA A/A  MALUTI",
    Latitude: "-30.1748",
    Longitude: "29.0151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3763",
    WardLookupId: "733",
    VDNumber: "11781299",
    RegisteredPopulation: "56",
    VotingStationName: "SAAMLOOP FARM",
    Address: "SAAMLOOP FARM  MATATIELE",
    Latitude: "-30.3144",
    Longitude: "29.1073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3764",
    WardLookupId: "734",
    VDNumber: "11780142",
    RegisteredPopulation: "598",
    VotingStationName: "MANGUZELA JUNIOR SECONDARY SCHOOL",
    Address: "DENGWANE LOCATION  MAGADLA A/A  MALUTI",
    Latitude: "-30.3132",
    Longitude: "28.7426",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3765",
    WardLookupId: "734",
    VDNumber: "11780153",
    RegisteredPopulation: "1436",
    VotingStationName: "POLOKONG JUNIOR SECONDARY SCHOOL",
    Address: "KHOAPA LOCATION  KHOAPA A/A  MALUTI",
    Latitude: "-30.2903",
    Longitude: "28.769",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3766",
    WardLookupId: "734",
    VDNumber: "11780221",
    RegisteredPopulation: "436",
    VotingStationName: "MALUTI SENIOR SECONDARY SCHOOL",
    Address: "PROTEA  RAMOHLAKOANA A/A  MALUTI",
    Latitude: "-30.2621",
    Longitude: "28.7786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3767",
    WardLookupId: "734",
    VDNumber: "11780883",
    RegisteredPopulation: "766",
    VotingStationName: "HLOMENDLINI JUNIOR SECONDARY SCHOOL",
    Address: "ZWELITSHA LOCATION  MAGDLA A/A  MALUTI",
    Latitude: "-30.3083",
    Longitude: "28.7671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3768",
    WardLookupId: "734",
    VDNumber: "11781165",
    RegisteredPopulation: "900",
    VotingStationName: "RETHABILE PRE-SCHOOL",
    Address: "MAHANGWE LOCATION  KHOAPHA A/A  KHOAPA",
    Latitude: "-30.296",
    Longitude: "28.7561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3769",
    WardLookupId: "734",
    VDNumber: "11781301",
    RegisteredPopulation: "263",
    VotingStationName: "HEAVENS ARE OPEN",
    Address: "MOTSEKUOA  RAMOHLAKOANA  MATATIELE",
    Latitude: "-30.2501",
    Longitude: "28.7819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3770",
    WardLookupId: "735",
    VDNumber: "11780108",
    RegisteredPopulation: "537",
    VotingStationName: "SPRINGSIDE JUNOIR SECONDARY SCHOOL",
    Address: "NKOSANA LOCATION  NKOSANA A/A  MALUTI",
    Latitude: "-30.2321",
    Longitude: "28.6959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3771",
    WardLookupId: "735",
    VDNumber: "11780175",
    RegisteredPopulation: "494",
    VotingStationName: "LE-GRANGE SENIOR SECONDARY SCHOOL",
    Address: "DESCUUR LOCATION  DESCUUR A/A  MALUTI",
    Latitude: "-30.1955",
    Longitude: "28.7072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3772",
    WardLookupId: "735",
    VDNumber: "11780197",
    RegisteredPopulation: "690",
    VotingStationName: "MAFUBE JUNIOR SECONDARY  SCHOOL",
    Address: "MAFUBE LOCATION  MAFUBE A/A  MALUTI",
    Latitude: "-30.2116",
    Longitude: "28.7293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3773",
    WardLookupId: "735",
    VDNumber: "11780412",
    RegisteredPopulation: "414",
    VotingStationName: "ST COLUMBUS SENIOR PRIMARY SCHOOL",
    Address: "NKAULWENI LOCATION  MZONGWANA A/A  MALUTI",
    Latitude: "-30.0662",
    Longitude: "29.0561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3774",
    WardLookupId: "735",
    VDNumber: "11780647",
    RegisteredPopulation: "364",
    VotingStationName: "MNGENI JUNIOR SECONDARY SCHOOL",
    Address: "MNGENI  MZONGWANA A/A  MATATIELE",
    Latitude: "-30.0947",
    Longitude: "29.0902",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3775",
    WardLookupId: "735",
    VDNumber: "11780658",
    RegisteredPopulation: "282",
    VotingStationName: "ESIFOLWENI PRIMARY JUNIOR SCHOOL",
    Address: "SIFOLWENI LOCATION  MZONGWANA A/A  MALUTI",
    Latitude: "-30.0877",
    Longitude: "29.0148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3776",
    WardLookupId: "735",
    VDNumber: "11780669",
    RegisteredPopulation: "281",
    VotingStationName: "NGCWENGANA SENIOR PRIMARY SCHOOL",
    Address: "NGCWENGANA LOCATION  MZONGWANA A/A  MALUTI",
    Latitude: "-30.071",
    Longitude: "28.9916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3777",
    WardLookupId: "735",
    VDNumber: "11780670",
    RegisteredPopulation: "446",
    VotingStationName: "NTABENI PRIMARY SCHOOL",
    Address: "PAMLAVILLE  MZONGWANA A/A  MALUTI",
    Latitude: "-30.0846",
    Longitude: "29.0542",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3778",
    WardLookupId: "735",
    VDNumber: "11780850",
    RegisteredPopulation: "244",
    VotingStationName: "BELFORT SENIOR PRIMARY SCHOOL",
    Address: "BELFORT   SIBI A/A  MALUTI",
    Latitude: "-30.1787",
    Longitude: "28.7197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3779",
    WardLookupId: "736",
    VDNumber: "11780298",
    RegisteredPopulation: "816",
    VotingStationName: "OUTSPAN SENIOR PRIMARY SCHOOL",
    Address: "OUTSPAN LOCATION  SIBI A/A  MALUTI",
    Latitude: "-30.2448",
    Longitude: "28.8404",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3780",
    WardLookupId: "736",
    VDNumber: "11780322",
    RegisteredPopulation: "1183",
    VotingStationName: "MECHAELING JUNIOR SECONDARY SCHOOL",
    Address: "NCHODU  NCHODU LOC  MALUTI",
    Latitude: "-30.2251",
    Longitude: "28.7638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3781",
    WardLookupId: "736",
    VDNumber: "11780793",
    RegisteredPopulation: "1186",
    VotingStationName: "THABANG SENIOR PRIMARY SCHOOL",
    Address: "ZWELITSHA LOCATION  SIBI A/A  MALUTI",
    Latitude: "-30.2425",
    Longitude: "28.8239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3782",
    WardLookupId: "736",
    VDNumber: "11781154",
    RegisteredPopulation: "640",
    VotingStationName: "MECHAELING PRE-SCHOOL",
    Address: "NCHODU  NCHODU A/A  MATATIELE",
    Latitude: "-30.2272",
    Longitude: "28.751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3783",
    WardLookupId: "736",
    VDNumber: "11781312",
    RegisteredPopulation: "276",
    VotingStationName: "MAGEMA SENIOR SECONDARY SCHOOL",
    Address: "MAGEMA  SIBI  MATATIELE",
    Latitude: "-30.2493",
    Longitude: "28.8235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3784",
    WardLookupId: "736",
    VDNumber: "11781356",
    RegisteredPopulation: "170",
    VotingStationName: "ST MARRY`S AGLICAN CHURCH",
    Address: "MAZIZINI  SIBI  MATATIELE",
    Latitude: "-30.2227",
    Longitude: "28.8141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3785",
    WardLookupId: "737",
    VDNumber: "11780287",
    RegisteredPopulation: "1064",
    VotingStationName: "KHASHULE JUNIOR SECONDARY SCHOOL",
    Address: "LEHLONONO  SIBI A/A  MALUTI",
    Latitude: "-30.206",
    Longitude: "28.8834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3786",
    WardLookupId: "737",
    VDNumber: "11780344",
    RegisteredPopulation: "583",
    VotingStationName: "ST MATTHEWS JUNIOR SECONDARY SCHOOL",
    Address: "MANDASTONE  MAKHOBA A/A  MALUTI",
    Latitude: "-30.1812",
    Longitude: "28.9473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3787",
    WardLookupId: "737",
    VDNumber: "11780366",
    RegisteredPopulation: "748",
    VotingStationName: "ROCHDALE JUNIOR PRIMARY SCHOOL",
    Address: "ROCHDALE  MAKHOBA A/A  MALUTI",
    Latitude: "-30.1443",
    Longitude: "28.9245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3788",
    WardLookupId: "737",
    VDNumber: "11780388",
    RegisteredPopulation: "941",
    VotingStationName: "MZONGWANA  JUNIOR SECONDARY SCHOOL",
    Address: "MATIAS LOCA  MZONGWANA A/A  MALUTI",
    Latitude: "-30.1333",
    Longitude: "29.0105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3789",
    WardLookupId: "737",
    VDNumber: "11780715",
    RegisteredPopulation: "580",
    VotingStationName: "MAKHOBA JUNIOR SECONDARY SCHOOL",
    Address: "MAKHOBA LOCATION  MAKHOBA A/A  MALUTI",
    Latitude: "-30.1699",
    Longitude: "28.9793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3790",
    WardLookupId: "737",
    VDNumber: "11780737",
    RegisteredPopulation: "475",
    VotingStationName: "GUGU SENIOR PRIMARY SCHOOL",
    Address: "N/A MPHOTSHONGWENI   MAKHOBA A/A  MALUTI",
    Latitude: "-30.1729",
    Longitude: "28.9206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3791",
    WardLookupId: "738",
    VDNumber: "11780513",
    RegisteredPopulation: "831",
    VotingStationName: "SIJOKA JUNIOR SECONDARY SCHOOL",
    Address: "SIJOKA LOCATION  SIJOKA A/A  MALUTI",
    Latitude: "-30.3362",
    Longitude: "28.6773",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3792",
    WardLookupId: "738",
    VDNumber: "11780861",
    RegisteredPopulation: "79",
    VotingStationName: "NCOME TENT SITE",
    Address: "SILINDINI LOCATION  MAGADLA A/A  MATATIELE",
    Latitude: "-30.348",
    Longitude: "28.7384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3793",
    WardLookupId: "738",
    VDNumber: "11780872",
    RegisteredPopulation: "554",
    VotingStationName: "NTABAZIJONGENE JUNIOR SECONDARY SCHOOL",
    Address: "HLOMENDLINI LOCATION  MAGADLA A/A  MALUTI",
    Latitude: "-30.3264",
    Longitude: "28.7518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3794",
    WardLookupId: "738",
    VDNumber: "11781053",
    RegisteredPopulation: "971",
    VotingStationName: "MAHLUBI JUNIOR SECONDARY SCHOOL",
    Address: "MDENI LOCATION  MAGADLA A/A  MATATIELE",
    Latitude: "-30.3745",
    Longitude: "28.681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3795",
    WardLookupId: "738",
    VDNumber: "11781064",
    RegisteredPopulation: "572",
    VotingStationName: "MAGADLA JUNIOR SECONDARY SCHOOL",
    Address: "MAGONQOLWENI  MAGADLA A/A  MALUTI",
    Latitude: "-30.3526",
    Longitude: "28.6988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3796",
    WardLookupId: "738",
    VDNumber: "11781176",
    RegisteredPopulation: "803",
    VotingStationName: "MAGADLA SENIOR SECONDARY SCHOOL",
    Address: "LUNDA  MAGADLA A/A  MATATIELE",
    Latitude: "-30.3428",
    Longitude: "28.6814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3797",
    WardLookupId: "738",
    VDNumber: "11781200",
    RegisteredPopulation: "437",
    VotingStationName: "NDZONDWENI SENIOR PRIMARY SCHOOL",
    Address: "CABA  MAGADLA  MATATIELE",
    Latitude: "-30.3531",
    Longitude: "28.6695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3798",
    WardLookupId: "739",
    VDNumber: "11780041",
    RegisteredPopulation: "890",
    VotingStationName: "PONTSENG JUNIOR SECONDARY  SCHOOL",
    Address: "PONTSENG LOCATION  DIAU A/A  MALUTI",
    Latitude: "-30.2459",
    Longitude: "28.5702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3799",
    WardLookupId: "739",
    VDNumber: "11780052",
    RegisteredPopulation: "822",
    VotingStationName: "MAPFONTEIN JUNIOR SECONDARY SCHOOL",
    Address: "MAPFONTEIN LOCATION  KLEIN JONAS A/A  MALUTI",
    Latitude: "-30.2456",
    Longitude: "28.6257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3800",
    WardLookupId: "739",
    VDNumber: "11780063",
    RegisteredPopulation: "554",
    VotingStationName: "NKUPULWENI JUNIOR SECONDARY SCHOOL",
    Address: "MAKOMORENG LOCATION  MADLANGALA A/A  MALUTI",
    Latitude: "-30.1864",
    Longitude: "28.5934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3801",
    WardLookupId: "739",
    VDNumber: "11780085",
    RegisteredPopulation: "485",
    VotingStationName: "ST ANDREWS JUNIOR SECONDARY SCHOOL",
    Address: "TSHITA LOCATION  TSHITA  A/A  MALUTI",
    Latitude: "-30.2197",
    Longitude: "28.6505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3802",
    WardLookupId: "739",
    VDNumber: "11780164",
    RegisteredPopulation: "230",
    VotingStationName: "PEHONG JUNIOR SECONDARY SCHOOL",
    Address: "TSHITA LOCATION  TSHITA  A/A  MALUTI",
    Latitude: "-30.2227",
    Longitude: "28.6745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3803",
    WardLookupId: "739",
    VDNumber: "11780906",
    RegisteredPopulation: "485",
    VotingStationName: "THOTANENG JUNIOR SECONDARY SCHOOL",
    Address: "THOTANENG LOCATION  TSITA A/A  MALUTI",
    Latitude: "-30.2008",
    Longitude: "28.5813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3804",
    WardLookupId: "739",
    VDNumber: "11780917",
    RegisteredPopulation: "411",
    VotingStationName: "MABUA AFRICAN GOSPEL CHURCH",
    Address: "MABUA LOCATION  TSITA A/A  MALUTI",
    Latitude: "-30.2054",
    Longitude: "28.6011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3805",
    WardLookupId: "740",
    VDNumber: "11780029",
    RegisteredPopulation: "906",
    VotingStationName: "MOSHESH SENIOR SECONDARY SCHOOL",
    Address: "QUEENSMERCY LOC  TLADI A/A  MALUTI",
    Latitude: "-30.2613",
    Longitude: "28.5451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3806",
    WardLookupId: "740",
    VDNumber: "11780030",
    RegisteredPopulation: "739",
    VotingStationName: "TSIKARONG JUNIOR SECONDARY SCHOOL",
    Address: "NKAU LOCATION  DIAHO A/A  MALUTI",
    Latitude: "-30.2469",
    Longitude: "28.5482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3807",
    WardLookupId: "740",
    VDNumber: "11780894",
    RegisteredPopulation: "576",
    VotingStationName: "NKAU JUNIOR SECONDARY SCHOOL",
    Address: "NKAU LOCATION  NKAU A/A  MALUTI",
    Latitude: "-30.2405",
    Longitude: "28.5269",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3808",
    WardLookupId: "740",
    VDNumber: "11781042",
    RegisteredPopulation: "604",
    VotingStationName: "LERATO JUNIOR SECONDARY SCHOOL",
    Address: "KHUBETSOANA  MPHARANEA A/A  MALUTI",
    Latitude: "-30.2567",
    Longitude: "28.5226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3809",
    WardLookupId: "740",
    VDNumber: "11781132",
    RegisteredPopulation: "280",
    VotingStationName: "TSWELOPELE JUNIOR PRIMARY SCHOOL",
    Address: "TSWELOPELE  NKAU A/A   MATATIELE",
    Latitude: "-30.2206",
    Longitude: "28.5309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3810",
    WardLookupId: "740",
    VDNumber: "11781222",
    RegisteredPopulation: "276",
    VotingStationName: "MT ZION SENIOR PRIMARY SCHOOL",
    Address: "SEKHUTLONG LOCATION  DIAHO A/A  MALUTI",
    Latitude: "-30.2246",
    Longitude: "28.5617",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3811",
    WardLookupId: "741",
    VDNumber: "11780018",
    RegisteredPopulation: "902",
    VotingStationName: "MPHARANE JUNIOR SECONDARY SCHOOL",
    Address: "MPHARANE LOCATION  MPHARANE A/A  MALUTI",
    Latitude: "-30.2634",
    Longitude: "28.4764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3812",
    WardLookupId: "741",
    VDNumber: "11780074",
    RegisteredPopulation: "596",
    VotingStationName: "TSEKONG JUNIOR SECONDARY SCHOOL",
    Address: "MASUPA LOCATION  GEORGE MOSHESH A/A  MALUTI",
    Latitude: "-30.2775",
    Longitude: "28.4301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3813",
    WardLookupId: "741",
    VDNumber: "11781008",
    RegisteredPopulation: "114",
    VotingStationName: "MPHARANE UPCSA CHURCH",
    Address: "MPHARANE  MPHARANE A/A  MALUTI",
    Latitude: "-30.2376",
    Longitude: "28.4383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3814",
    WardLookupId: "741",
    VDNumber: "11781019",
    RegisteredPopulation: "633",
    VotingStationName: "TLHAKANELO JUNIOR SECONDARY SCHOOL",
    Address: "MPHARANE LOCATION  GEORGE MOSHESH A/A  MALUTI",
    Latitude: "-30.2552",
    Longitude: "28.4516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3815",
    WardLookupId: "741",
    VDNumber: "11781020",
    RegisteredPopulation: "473",
    VotingStationName: "LIKAMORENG JUNIOR SECONDARY SCHOOL",
    Address: "LIKAMORENG LOCATION  MPHARANE A/A  MALUTI",
    Latitude: "-30.2755",
    Longitude: "28.4558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3816",
    WardLookupId: "741",
    VDNumber: "11781031",
    RegisteredPopulation: "696",
    VotingStationName: "KUTLOANONG JUNIOR SECONDARY SCHOOL",
    Address: "KUTLOANONG LOCATION  MPHARANE A/A  MALUTI",
    Latitude: "-30.2784",
    Longitude: "28.494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3817",
    WardLookupId: "741",
    VDNumber: "11781323",
    RegisteredPopulation: "379",
    VotingStationName: "KHUBETSOANA COMMUNITY HALL",
    Address: "KHUBETSOANA  GEORGE MOSHESH  MATATIELE",
    Latitude: "-30.2541",
    Longitude: "28.517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3818",
    WardLookupId: "742",
    VDNumber: "11780333",
    RegisteredPopulation: "248",
    VotingStationName: "ONGELUKSNEK SENIOR PRIMARY SCHOOL",
    Address: "DITICHARENG LOC.  DITICHARENG A/A  MALUTI",
    Latitude: "-30.3034",
    Longitude: "28.3851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3819",
    WardLookupId: "742",
    VDNumber: "11780535",
    RegisteredPopulation: "24",
    VotingStationName: "O`CONNERS CAMP",
    Address: "O`CONNERS CAMP FARM  GEORGE MOSHESH  MATATIELE",
    Latitude: "-30.2872",
    Longitude: "28.2836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3820",
    WardLookupId: "742",
    VDNumber: "11800370",
    RegisteredPopulation: "500",
    VotingStationName: "THABACHICHA JUNIOR SECONDARY SCHOOL",
    Address: "THABACHICHA LOC.  THABACHICHA A/A  MALUTI",
    Latitude: "-30.3595",
    Longitude: "28.3963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3821",
    WardLookupId: "742",
    VDNumber: "11800381",
    RegisteredPopulation: "720",
    VotingStationName: "MOALOSI SENIOR PRIMARY SCHOOL",
    Address: "SEQHOBONG  LOC.  SEQHOBONG A/A  EC05B2",
    Latitude: "-30.3967",
    Longitude: "28.3978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3822",
    WardLookupId: "742",
    VDNumber: "11800392",
    RegisteredPopulation: "522",
    VotingStationName: "MAPHEELLE JUNIOR SECONDARY SCHOOL",
    Address: "MOIKETSI RESERVE LOC.  MOIKETSI RESERVEA/A  MALUTI",
    Latitude: "-30.3188",
    Longitude: "28.3918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3823",
    WardLookupId: "742",
    VDNumber: "11800617",
    RegisteredPopulation: "217",
    VotingStationName: "LEKHALONG JUNIOR SECONDARY SCHOOL",
    Address: "LEKHALONG LOC.  LEKHALONG A/A  EC05B2",
    Latitude: "-30.3744",
    Longitude: "28.4571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3824",
    WardLookupId: "742",
    VDNumber: "11800729",
    RegisteredPopulation: "298",
    VotingStationName: "MAFIKALISIU JUNIOR SECONDARY SCHOOL",
    Address: "FATIMA LOC.  THABACHICHA A/A  MT FLETCHER",
    Latitude: "-30.4108",
    Longitude: "28.4209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3825",
    WardLookupId: "742",
    VDNumber: "11800976",
    RegisteredPopulation: "251",
    VotingStationName: "PHALLANG SENIOR PRIMARY SCHOOL",
    Address: "N/A  PHALLANG  MALUTI",
    Latitude: "-30.373",
    Longitude: "28.3485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3826",
    WardLookupId: "742",
    VDNumber: "11800987",
    RegisteredPopulation: "341",
    VotingStationName: "MIKA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  MIKA  MALUTI",
    Latitude: "-30.346",
    Longitude: "28.3713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3827",
    WardLookupId: "742",
    VDNumber: "11801023",
    RegisteredPopulation: "150",
    VotingStationName: "MATELENG JUNIOR SECONDARY SCHOOL",
    Address: "N/A  MATELENG  MALUTI",
    Latitude: "-30.3524",
    Longitude: "28.4308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3828",
    WardLookupId: "743",
    VDNumber: "11780939",
    RegisteredPopulation: "580",
    VotingStationName: "POLILE SENIOR PRIMARY SCHOOL",
    Address: "POLILE LOCATION  NCANYWA A/A  MALUTI",
    Latitude: "-30.4512",
    Longitude: "28.6409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3829",
    WardLookupId: "743",
    VDNumber: "11800123",
    RegisteredPopulation: "518",
    VotingStationName: "QHOBOSHEANENG JUNIOR SECONDARY SCHOOL",
    Address: "QOBOSHEANENG  LOC.  QHOBOSHEANENG A/A  EC5B2",
    Latitude: "-30.412",
    Longitude: "28.5962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3830",
    WardLookupId: "743",
    VDNumber: "11800202",
    RegisteredPopulation: "591",
    VotingStationName: "SEMONKONG SENIOR PRIMARY SCHOOL",
    Address: "SMONKONG LOC.  SEMONKONG A/A  ECO5B2",
    Latitude: "-30.4161",
    Longitude: "28.5446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3831",
    WardLookupId: "743",
    VDNumber: "11800279",
    RegisteredPopulation: "403",
    VotingStationName: "IKAHENG JUNIOR SECONDARY SCHOOL",
    Address: "PONTSENG LOC  IKAHENG A/A  MALUTI",
    Latitude: "-30.4489",
    Longitude: "28.5992",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3832",
    WardLookupId: "743",
    VDNumber: "11800415",
    RegisteredPopulation: "650",
    VotingStationName: "PABALLONG JUNIOR SECONDARY SCHOOL",
    Address: "PABALLONG LOC.  PABALLONG A/A  MALUTI",
    Latitude: "-30.4202",
    Longitude: "28.5039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3833",
    WardLookupId: "743",
    VDNumber: "11800965",
    RegisteredPopulation: "296",
    VotingStationName: "PHARAMAKHULO SENIOR PRIMARY SCHOOL",
    Address: "N/A  PHARAMAKHULO  MALUTI",
    Latitude: "-30.4055",
    Longitude: "28.623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3834",
    WardLookupId: "743",
    VDNumber: "11801012",
    RegisteredPopulation: "267",
    VotingStationName: "LIHASENG JUNIOR SECONDARY SCHOOL",
    Address: "N/A  LIHASENG   MALUTI",
    Latitude: "-30.3998",
    Longitude: "28.4949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3835",
    WardLookupId: "743",
    VDNumber: "11801269",
    RegisteredPopulation: "153",
    VotingStationName: "LEKOENTLANENG SENIOR PRIMARY SCHOOL",
    Address: "LEKHALONG  BAKOENA",
    Latitude: "-30.3788",
    Longitude: "28.4697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3836",
    WardLookupId: "743",
    VDNumber: "11801270",
    RegisteredPopulation: "128",
    VotingStationName: "MASIANOKENG SPS",
    Address: "MAPULENG  QHOBOSHEANEN A/A  BAKOENA",
    Latitude: "-30.3902",
    Longitude: "28.6024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3837",
    WardLookupId: "743",
    VDNumber: "11801281",
    RegisteredPopulation: "115",
    VotingStationName: "FRYSTAT TENT SITE",
    Address: "FRYSTAT LOCATION  PABALLONG A/A  MATATIELE",
    Latitude: "-30.4564",
    Longitude: "28.5249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3838",
    WardLookupId: "744",
    VDNumber: "11780096",
    RegisteredPopulation: "737",
    VotingStationName: "MAKHAULA JUNIOR PRIMARY SCHOOL",
    Address: "MBOBO LOCATION  TSHITA A/A  MALUTI",
    Latitude: "-30.2416",
    Longitude: "28.6754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3839",
    WardLookupId: "744",
    VDNumber: "11781187",
    RegisteredPopulation: "199",
    VotingStationName: "MOTLATSI SENIOR PRIMARY SCHOOL",
    Address: "SPRINGANA   KLEIN JONASE A/A  MATATIELE",
    Latitude: "-30.2563",
    Longitude: "28.6094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3840",
    WardLookupId: "744",
    VDNumber: "11781334",
    RegisteredPopulation: "235",
    VotingStationName: "TLOPO SENIOR PRIMARY SCHOOL",
    Address: "MALOTO  KLEIN JONAS  MATATIELE",
    Latitude: "-30.2543",
    Longitude: "28.6352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3841",
    WardLookupId: "744",
    VDNumber: "11781345",
    RegisteredPopulation: "356",
    VotingStationName: "KHAUOE COMMUNITY HALL",
    Address: "KHAUOE  MATATIELE",
    Latitude: "-30.2554",
    Longitude: "28.6957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3842",
    WardLookupId: "744",
    VDNumber: "11800336",
    RegisteredPopulation: "802",
    VotingStationName: "LIKHETLANE  JUNIOR SECONDARY SCHOOL",
    Address: "LIKHETLANE  LOC.  LIKHETLANE A/A  MATATIELE",
    Latitude: "-30.3563",
    Longitude: "28.5254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3843",
    WardLookupId: "744",
    VDNumber: "11800404",
    RegisteredPopulation: "345",
    VotingStationName: "LEKHATLA PRIMARY SCHOOL",
    Address: "THABACHICHA LOC  LEKHATLA A/A  MALUTI",
    Latitude: "-30.3718",
    Longitude: "28.4956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3844",
    WardLookupId: "744",
    VDNumber: "11800426",
    RegisteredPopulation: "290",
    VotingStationName: "MECHACHANENG SENIOR PRIMARY SCHOOL",
    Address: "MECHACHANENG  PABALLONG  PABALLONG",
    Latitude: "-30.337",
    Longitude: "28.4479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3845",
    WardLookupId: "744",
    VDNumber: "11800583",
    RegisteredPopulation: "662",
    VotingStationName: "SKETLANE JUNIOR SECONDARY SCHOOL",
    Address: "SKETLANE LOC.  SKETLANE A/A  MLUTI T/SHIP",
    Latitude: "-30.3515",
    Longitude: "28.5916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3846",
    WardLookupId: "744",
    VDNumber: "11800954",
    RegisteredPopulation: "708",
    VotingStationName: "MOKHESENG SENIOR SECONDARY SCHOOL",
    Address: "MOKHESENG LOCATION  QHOBOSHEANENG A/A  MOUNT FLETCHER",
    Latitude: "-30.3393",
    Longitude: "28.613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3847",
    WardLookupId: "744",
    VDNumber: "11800998",
    RegisteredPopulation: "221",
    VotingStationName: "KHOARAI JUNIOR SECONDARY SCHOOL",
    Address: "N/A  KHOARAI  MALUTI",
    Latitude: "-30.3404",
    Longitude: "28.4865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3848",
    WardLookupId: "744",
    VDNumber: "11801157",
    RegisteredPopulation: "316",
    VotingStationName: "MOANENG JUNIOR SECONDARY SCHOOL",
    Address: "MOEANENG   MOEANENG A/A  MATATIELE",
    Latitude: "-30.3798",
    Longitude: "28.5366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3849",
    WardLookupId: "745",
    VDNumber: "11780490",
    RegisteredPopulation: "480",
    VotingStationName: "SIBONGILE JUNIOR SECONDARY SCHOOL",
    Address: "LUXENI  LUPINDO A/AI  MALUTI",
    Latitude: "-30.4126",
    Longitude: "28.6887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3850",
    WardLookupId: "745",
    VDNumber: "11780502",
    RegisteredPopulation: "1072",
    VotingStationName: "MANGO JUNIOR SECONDARY SCHOOL",
    Address: "MANGO LOC.  MANGO A/A  MALUTI",
    Latitude: "-30.4572",
    Longitude: "28.67",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3851",
    WardLookupId: "745",
    VDNumber: "11780928",
    RegisteredPopulation: "596",
    VotingStationName: "BAVUMA PRE-SCHOOL",
    Address: "LUGADA  MGUBO",
    Latitude: "-30.4075",
    Longitude: "28.6662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3852",
    WardLookupId: "745",
    VDNumber: "11780940",
    RegisteredPopulation: "379",
    VotingStationName: "MPUTHEKANE JUNIOR SECONDARY SCHOOL",
    Address: "NYANZELA LOCATION  MGUBO A/A  MALUTI",
    Latitude: "-30.4681",
    Longitude: "28.7195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3853",
    WardLookupId: "745",
    VDNumber: "11780951",
    RegisteredPopulation: "281",
    VotingStationName: "EMBIZENI PRIMARY SCHOOL",
    Address: "N/A  MGUBO A/A  MALUTI",
    Latitude: "-30.4411",
    Longitude: "28.7513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3854",
    WardLookupId: "745",
    VDNumber: "11780962",
    RegisteredPopulation: "662",
    VotingStationName: "MPUMALANGA SENIOR PRIMARY SCHOOL",
    Address: "MGUBO LOCATION  MGUBO A/A  MALUTI",
    Latitude: "-30.4282",
    Longitude: "28.7528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3855",
    WardLookupId: "745",
    VDNumber: "11780973",
    RegisteredPopulation: "403",
    VotingStationName: "MOUNT HARGREAVES COMBINED SCHOOL",
    Address: "SIGOGA LOCATION  MGUBO A/A  MALUTI",
    Latitude: "-30.4072",
    Longitude: "28.7075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3856",
    WardLookupId: "745",
    VDNumber: "11780984",
    RegisteredPopulation: "196",
    VotingStationName: "NKALWENI JUNIOR SECONDARY SCHOOL",
    Address: "N/A  MAGADLA A/A  MALUTI",
    Latitude: "-30.441",
    Longitude: "28.712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3857",
    WardLookupId: "746",
    VDNumber: "11780445",
    RegisteredPopulation: "377",
    VotingStationName: "NYANISO SENIOR SECONDARY SCHOOL",
    Address: "QILI  KWAQILI LOC  MALUTI",
    Latitude: "-30.5811",
    Longitude: "28.758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3858",
    WardLookupId: "746",
    VDNumber: "11780524",
    RegisteredPopulation: "584",
    VotingStationName: "DUBA JUNIOR SECONDARY SCHOOL",
    Address: "HILLSIDE LOCATION  LUDIDI A/A  MALUTI",
    Latitude: "-30.5599",
    Longitude: "28.7334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3859",
    WardLookupId: "746",
    VDNumber: "11780995",
    RegisteredPopulation: "289",
    VotingStationName: "TSHISA PRIMARY SCHOOL",
    Address: "TSHISA LOCATION  LUPHINDO A/A  MALUTI",
    Latitude: "-30.4918",
    Longitude: "28.669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3860",
    WardLookupId: "746",
    VDNumber: "11781075",
    RegisteredPopulation: "439",
    VotingStationName: "BUBESI JUNIOR SECONDARY SCHOOL",
    Address: "BUBESI LOCATION  NYANISO A/A  MALUTI",
    Latitude: "-30.5277",
    Longitude: "28.7023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3861",
    WardLookupId: "746",
    VDNumber: "11781086",
    RegisteredPopulation: "244",
    VotingStationName: "ANTIOK CHURCH",
    Address: "N/A N/A  KWA MASHU  MALUTI",
    Latitude: "-30.5137",
    Longitude: "28.7045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3862",
    WardLookupId: "746",
    VDNumber: "11781097",
    RegisteredPopulation: "191",
    VotingStationName: "MRWABO JUNIOR PRIMARY SCHOOL",
    Address: "N/A  NYANISO A/A  MALUTI",
    Latitude: "-30.5592",
    Longitude: "28.7838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3863",
    WardLookupId: "746",
    VDNumber: "11781109",
    RegisteredPopulation: "592",
    VotingStationName: "SIDAKENI JUNIOR SECONDARY SCHOOL",
    Address: "MNYAMANENI LOCATION  LUDIDI A/A  MALUTI",
    Latitude: "-30.5942",
    Longitude: "28.7973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3864",
    WardLookupId: "746",
    VDNumber: "11781110",
    RegisteredPopulation: "223",
    VotingStationName: "SIPHEPHETHO JUNIOR SECONDARY SCHOOL",
    Address: "FIVA LOCATION  ROLWENI A/A  MALUTI",
    Latitude: "-30.5412",
    Longitude: "28.8442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3865",
    WardLookupId: "746",
    VDNumber: "11781211",
    RegisteredPopulation: "226",
    VotingStationName: "MATANDELA JUNIOR SECONDARY SCHOOL",
    Address: "NKUNGWINI LOCATION  NYANISO A/A  MALUTI",
    Latitude: "-30.5448",
    Longitude: "28.7566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3866",
    WardLookupId: "746",
    VDNumber: "11781266",
    RegisteredPopulation: "189",
    VotingStationName: "MOOROSI AGRICULTURAL HIGH SCHOOL",
    Address: "BAMANZI  FIVA  LUDIDI",
    Latitude: "-30.5369",
    Longitude: "28.8286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3867",
    WardLookupId: "747",
    VDNumber: "43920019",
    RegisteredPopulation: "3428",
    VotingStationName: "MATATIELE TOWN HALL",
    Address: "102 HIGH STREET  MATATIELE  MATATIELE",
    Latitude: "-30.3427",
    Longitude: "28.8077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3868",
    WardLookupId: "748",
    VDNumber: "43920031",
    RegisteredPopulation: "3004",
    VotingStationName: "HARRY GWALA PARK COMMUNITY HALL",
    Address: "HARRY GWALA PARK  MATATIELE",
    Latitude: "-30.3306",
    Longitude: "28.7862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3869",
    WardLookupId: "748",
    VDNumber: "43920042",
    RegisteredPopulation: "1383",
    VotingStationName: "NOKHWEZI HALL",
    Address: "ITSOKOLELE  MATATIELE",
    Latitude: "-30.3266",
    Longitude: "28.8069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3870",
    WardLookupId: "749",
    VDNumber: "11760915",
    RegisteredPopulation: "373",
    VotingStationName: "NTLOLA SENIOR PRIMARY SCHOOL",
    Address: "TYIWENI   MVENYANE A/A  CEDARVILLE",
    Latitude: "-30.5895",
    Longitude: "29.086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3871",
    WardLookupId: "749",
    VDNumber: "11780423",
    RegisteredPopulation: "369",
    VotingStationName: "MAGXENI JUNIOR SECONDARY SCHOOL",
    Address: "MAGXENI LOCATION  MVENYANE A/A  MALUTI",
    Latitude: "-30.547",
    Longitude: "29.0595",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3872",
    WardLookupId: "749",
    VDNumber: "11780434",
    RegisteredPopulation: "585",
    VotingStationName: "MVENYANE SCHOOL",
    Address: "MVENYANE  MVENYANE A/A  MALUTI",
    Latitude: "-30.5471",
    Longitude: "29.0201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3873",
    WardLookupId: "749",
    VDNumber: "11780557",
    RegisteredPopulation: "285",
    VotingStationName: "MDENI JUNIOR SECONDARY SCHOOL",
    Address: "MDENI  MVENYANE A/A  CEDARVILLE",
    Latitude: "-30.5759",
    Longitude: "29.0706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3874",
    WardLookupId: "749",
    VDNumber: "11780568",
    RegisteredPopulation: "495",
    VotingStationName: "GWADANA JUNIOR SECONDARY SCHOOL",
    Address: "GWADANA  MVENYANE A/A  CEDARVILLE",
    Latitude: "-30.5732",
    Longitude: "29.021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3875",
    WardLookupId: "749",
    VDNumber: "11780579",
    RegisteredPopulation: "369",
    VotingStationName: "R.V.MANTSHULE JUNIOR SECONDARY SCHOOL",
    Address: "NKAWULWENI LOC.  MVENYANE A/A  MALUTI",
    Latitude: "-30.531",
    Longitude: "29.0214",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3876",
    WardLookupId: "749",
    VDNumber: "11780580",
    RegisteredPopulation: "251",
    VotingStationName: "SITHIYWENI JUNIOR SECONDARY SCHOOL",
    Address: "SITHIYWENI LICATION  MVENYANE A/A  MALUTI",
    Latitude: "-30.5859",
    Longitude: "29.04",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3877",
    WardLookupId: "749",
    VDNumber: "11780591",
    RegisteredPopulation: "522",
    VotingStationName: "UPPER MVENYANE JUNIOR SECONDARY SCHOOL",
    Address: "UPPER MVENYANE  MVENYANE A/A  MATATIELE",
    Latitude: "-30.5529",
    Longitude: "29.0777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3878",
    WardLookupId: "749",
    VDNumber: "11781277",
    RegisteredPopulation: "232",
    VotingStationName: "MABHELENI SENIOR PRIMARY SCHOOL",
    Address: "MABHELENI LOCATION  MVEANYANE A/A  LUDIDI",
    Latitude: "-30.5652",
    Longitude: "29.0814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3879",
    WardLookupId: "750",
    VDNumber: "11780456",
    RegisteredPopulation: "529",
    VotingStationName: "HOTOLO JUNIOR SECONDARY SCHOOL",
    Address: "EPIPHANY  EPIPHANY LOC  MALUTI",
    Latitude: "-30.6478",
    Longitude: "28.9129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3880",
    WardLookupId: "750",
    VDNumber: "11780467",
    RegisteredPopulation: "734",
    VotingStationName: "UPPER MKHEMANE JUNIOR SECONDARY SCHOOL",
    Address: "MKHEMANE  MALUTI",
    Latitude: "-30.5897",
    Longitude: "28.9035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3881",
    WardLookupId: "750",
    VDNumber: "11780478",
    RegisteredPopulation: "827",
    VotingStationName: "ELUKHOLWENI JUNIOR SECONDARY SCHOOL",
    Address: "LUKHOLWENI LOC  LUKHOLWENI A/A  MALUTI",
    Latitude: "-30.6412",
    Longitude: "28.8494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3882",
    WardLookupId: "750",
    VDNumber: "11780489",
    RegisteredPopulation: "317",
    VotingStationName: "ROLWENI MORAVIAN CHURCH",
    Address: "ROLWENI  MALUTI",
    Latitude: "-30.5586",
    Longitude: "28.8089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3883",
    WardLookupId: "750",
    VDNumber: "11780603",
    RegisteredPopulation: "456",
    VotingStationName: "LUHLEKWENI SENIOR PRIMARY SCHOOL",
    Address: "N/A  PALANE  MALUTI",
    Latitude: "-30.6661",
    Longitude: "28.8702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3884",
    WardLookupId: "750",
    VDNumber: "11781121",
    RegisteredPopulation: "349",
    VotingStationName: "MKHUHLANE KOP JUNIOR SECONDARY SCHOOL",
    Address: "FIVA VILLAGE  LUDIDI  MATATIELE RURAL",
    Latitude: "-30.5633",
    Longitude: "28.8576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3885",
    WardLookupId: "751",
    VDNumber: "11780120",
    RegisteredPopulation: "564",
    VotingStationName: "LUPINDO TRIBAL AUTHORITY",
    Address: "LUPHINDO LOC.  LUPHINDO A/A  MALUTI",
    Latitude: "-30.4819",
    Longitude: "28.6436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3886",
    WardLookupId: "751",
    VDNumber: "11800314",
    RegisteredPopulation: "738",
    VotingStationName: "MANGOLONG JUNIOR SECONDARY SCHOOL",
    Address: "MANGOLONG  LOC.  MANGOLONG A/A  MT FLETCHER",
    Latitude: "-30.5116",
    Longitude: "28.5947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3887",
    WardLookupId: "751",
    VDNumber: "11800347",
    RegisteredPopulation: "317",
    VotingStationName: "LOWER EMTUMASE SENIOR PRIMARY SCHOOL",
    Address: "EMTUMASE LOC  EMTUMASE A/A  MALUTI",
    Latitude: "-30.5509",
    Longitude: "28.6362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3888",
    WardLookupId: "751",
    VDNumber: "11800572",
    RegisteredPopulation: "769",
    VotingStationName: "FOBANE JUNIOR SECONDARY SCHOOL",
    Address: "FOBANE LOC.  FOBANE A/A  ECO5B2",
    Latitude: "-30.5311",
    Longitude: "28.6445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3889",
    WardLookupId: "751",
    VDNumber: "11800594",
    RegisteredPopulation: "177",
    VotingStationName: "DAMANE SENIOR PRIMARY SCHOOL",
    Address: "GOOD HOPE LOC.  MTHUMASE  MATATIELE",
    Latitude: "-30.5544",
    Longitude: "28.6786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3890",
    WardLookupId: "751",
    VDNumber: "11800639",
    RegisteredPopulation: "590",
    VotingStationName: "EMITSHATSHANENI JUNIOR SECONDARY SCHOOL",
    Address: "EMITSHATSHANENG LOC.  EMITSHATSHANENI A/A  EC05B2",
    Latitude: "-30.4842",
    Longitude: "28.5986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3891",
    WardLookupId: "751",
    VDNumber: "11801056",
    RegisteredPopulation: "249",
    VotingStationName: "SEKHUTLONG SENIOR PRIMARY SCHOOL",
    Address: "SEKHUTLONG LOCATION  EMITSHATSHANENI A/A  MALUTI",
    Latitude: "-30.5155",
    Longitude: "28.5768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3892",
    WardLookupId: "752",
    VDNumber: "11800178",
    RegisteredPopulation: "539",
    VotingStationName: "MTHUMASI JUNIOR SECONDARY SCHOOL.",
    Address: "RAMAFOLE  LOC.  RAMAFOLE A/A  MT FLETCHER",
    Latitude: "-30.5672",
    Longitude: "28.5461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3893",
    WardLookupId: "752",
    VDNumber: "11800358",
    RegisteredPopulation: "250",
    VotingStationName: "MAHLAKE JUNIOR SECONDARY SCHOOL",
    Address: "MAHLAKE LOCATION  MAHLAKE A/A  MALUTI",
    Latitude: "-30.6282",
    Longitude: "28.6273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3894",
    WardLookupId: "752",
    VDNumber: "11800369",
    RegisteredPopulation: "546",
    VotingStationName: "ESILINDINI ZIBI MEYER SENIOR PRIMARY SCHHOL",
    Address: "MDENI LOCATION  MAHLAKE  MAHLAKE A/A",
    Latitude: "-30.6058",
    Longitude: "28.5877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3895",
    WardLookupId: "752",
    VDNumber: "11800516",
    RegisteredPopulation: "308",
    VotingStationName: "MAQHATSENG JUNIOR SECONDARY SCHOOL",
    Address: "MAQHATSENG LOC.  MAQHATSENG A/A  MALUTI",
    Latitude: "-30.5526",
    Longitude: "28.6002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3896",
    WardLookupId: "752",
    VDNumber: "11800606",
    RegisteredPopulation: "190",
    VotingStationName: "ZIMPOFU JUNIOR SECONDARY SCHOOL",
    Address: "ZIMPOFU  ECO5B2",
    Latitude: "-30.6148",
    Longitude: "28.6652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3897",
    WardLookupId: "752",
    VDNumber: "11801045",
    RegisteredPopulation: "253",
    VotingStationName: "LINOTSING JUNIOR SECONDARY SCHOOL",
    Address: "N/A  LINOTSING  MALUTI",
    Latitude: "-30.5797",
    Longitude: "28.6239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3898",
    WardLookupId: "752",
    VDNumber: "11801067",
    RegisteredPopulation: "188",
    VotingStationName: "PURUTLE SENIOR PRIMARY SCHOOL",
    Address: "PURUTLE  MTHUMASI AA  MT FLETCHER",
    Latitude: "-30.6468",
    Longitude: "28.6245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3899",
    WardLookupId: "752",
    VDNumber: "11801078",
    RegisteredPopulation: "294",
    VotingStationName: "SOLOANA SENIOUR PRIMARY SCHOOL",
    Address: "SOLOANA LOCATION  MAHLAKE A/A  MALUTI",
    Latitude: "-30.6035",
    Longitude: "28.6094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3900",
    WardLookupId: "752",
    VDNumber: "11801168",
    RegisteredPopulation: "351",
    VotingStationName: "RAMAFOLE SENIOR SECONDARY SCHOOL",
    Address: "RAMAFOLE   MT FLETCHER",
    Latitude: "-30.5502",
    Longitude: "28.5264",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3901",
    WardLookupId: "752",
    VDNumber: "11801180",
    RegisteredPopulation: "420",
    VotingStationName: "MORITING JUNIOR SECONDARY SCHOOL",
    Address: "MORITING  MTHUMASI  MATATIELE",
    Latitude: "-30.573",
    Longitude: "28.5756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3902",
    WardLookupId: "753",
    VDNumber: "11780119",
    RegisteredPopulation: "1507",
    VotingStationName: "NALEDI JUNIOR SECONDARY SCHOOL.",
    Address: "AZARIEL LOCATION  KHAUOE A/A  MALUTI",
    Latitude: "-30.2586",
    Longitude: "28.6932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3903",
    WardLookupId: "753",
    VDNumber: "11780131",
    RegisteredPopulation: "992",
    VotingStationName: "LEPHEANA JUNIOR SECONDARY SCHOOL",
    Address: "JABULANI LOCATION  JABULANI A/A  MALUTI",
    Latitude: "-30.2554",
    Longitude: "28.7107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3904",
    WardLookupId: "753",
    VDNumber: "11780186",
    RegisteredPopulation: "600",
    VotingStationName: "PHAMOTSE JUNIOR SECONDARY SCHOOL",
    Address: "ST PAULLOCATION  ST PAUL A/A  MALUTI",
    Latitude: "-30.2508",
    Longitude: "28.7276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3905",
    WardLookupId: "753",
    VDNumber: "11781198",
    RegisteredPopulation: "668",
    VotingStationName: "ST MAGARET SPS",
    Address: "NEWSTANCE  KHAUOE",
    Latitude: "-30.2691",
    Longitude: "28.7117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3906",
    WardLookupId: "753",
    VDNumber: "11781255",
    RegisteredPopulation: "398",
    VotingStationName: "MOHLAKOANA SENIOR PRIMARY SCHOOL",
    Address: "JABAVU  RAMOHLAKOANA",
    Latitude: "-30.2504",
    Longitude: "28.7668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3907",
    WardLookupId: "754",
    VDNumber: "11780355",
    RegisteredPopulation: "375",
    VotingStationName: "THOKOZANI JUNIOR PRIMARY SCHOOL",
    Address: "N/A SHENXA  MAKHOBA A/A  MALUTI",
    Latitude: "-30.2135",
    Longitude: "28.9574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3908",
    WardLookupId: "754",
    VDNumber: "11780704",
    RegisteredPopulation: "229",
    VotingStationName: "BLACK DIAMOND JUNIOR SECONDARY SCHOOL",
    Address: "N/A  MAKHOBA A/A  MALUTI",
    Latitude: "-30.2593",
    Longitude: "28.9537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3909",
    WardLookupId: "754",
    VDNumber: "11781233",
    RegisteredPopulation: "247",
    VotingStationName: "THEMBALITSHA JUNIOR SECONDARY SCHOOL",
    Address: "GOBIZEMBE  MBIZENI  MAKHOBA",
    Latitude: "-30.2305",
    Longitude: "28.9894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3910",
    WardLookupId: "754",
    VDNumber: "43800016",
    RegisteredPopulation: "2581",
    VotingStationName: "CEDARVILLE TOWN HALL",
    Address: "MAIN STREET  CEDARVILLE  CEDARVILLE",
    Latitude: "-30.3879",
    Longitude: "29.0409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3911",
    WardLookupId: "754",
    VDNumber: "43800027",
    RegisteredPopulation: "165",
    VotingStationName: "SANDFONTEIN COMMUNITY HALL",
    Address: "SANDFONTEIN FARM  MATATIELE [MATATIELE]",
    Latitude: "-30.4079",
    Longitude: "29.2161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3912",
    WardLookupId: "754",
    VDNumber: "43800038",
    RegisteredPopulation: "103",
    VotingStationName: "MATSHEMLA PRIMARY SCHOOL",
    Address: "MATSHEMLA  MATSHEMLA FARM  MATATIELE",
    Latitude: "-30.3325",
    Longitude: "28.8918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3913",
    WardLookupId: "754",
    VDNumber: "43800049",
    RegisteredPopulation: "163",
    VotingStationName: "BULFONTEIN SENIOR PRIMARY SCHOOL",
    Address: "BULFONTEIN  BULTFONTEIN  CEDARVILLE",
    Latitude: "-30.2836",
    Longitude: "29.2554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3914",
    WardLookupId: "755",
    VDNumber: "11790222",
    RegisteredPopulation: "1262",
    VotingStationName: "LOWER BROOKSNEK SENIOR PRIMARY SCHOOL",
    Address: "BROOKSNEK  BROOKSNEK A/A  MOUNT AYLIFF",
    Latitude: "-30.6541",
    Longitude: "29.4911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3915",
    WardLookupId: "755",
    VDNumber: "11790288",
    RegisteredPopulation: "820",
    VotingStationName: "UPPER BROOKS NEK JUNIOR SECONDARY SCHOOL",
    Address: "NOLANGENI LOCATION  BROOKSNEK A/A  MOUNT AYLIFF",
    Latitude: "-30.6364",
    Longitude: "29.4825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3916",
    WardLookupId: "755",
    VDNumber: "11790389",
    RegisteredPopulation: "817",
    VotingStationName: "PEPENI SENIOR PRIMARY SCHOOL",
    Address: "PEPENI LOCATION  BROOKSNEK A/A  MOUNT AYLIFF",
    Latitude: "-30.6684",
    Longitude: "29.5252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3917",
    WardLookupId: "755",
    VDNumber: "11790738",
    RegisteredPopulation: "124",
    VotingStationName: "THEMBENI S.P.S",
    Address: "UPPER BROOKSNEK  BROOKS NEK   BROOKSNEK",
    Latitude: "-30.639",
    Longitude: "29.4997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3918",
    WardLookupId: "755",
    VDNumber: "11790749",
    RegisteredPopulation: "960",
    VotingStationName: "GOVALELE NOMAKE SENIOR PRIMARY SCHOOL",
    Address: "GOVALELE  BROOKSNEK A/A  BROOKSNEK A/A",
    Latitude: "-30.6891",
    Longitude: "29.5157",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3919",
    WardLookupId: "756",
    VDNumber: "11790020",
    RegisteredPopulation: "637",
    VotingStationName: "NKANJI JUNIOR SECONDARY SCHOOL",
    Address: "NKANJI LOC  NKANJI A/A  MOUNT AYLIFF",
    Latitude: "-30.6865",
    Longitude: "29.3177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3920",
    WardLookupId: "756",
    VDNumber: "11790121",
    RegisteredPopulation: "916",
    VotingStationName: "NGWEGWENI HALL",
    Address: "NGWEGWENI  MOUNT AYLIFF",
    Latitude: "-30.7324",
    Longitude: "29.3588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3921",
    WardLookupId: "756",
    VDNumber: "11790132",
    RegisteredPopulation: "1007",
    VotingStationName: "LUBALEKO SENIOR PRIMARY SCHOOL",
    Address: "LUBALEKO LOCATION  LUBALEKO A/A  MOUNT AYLIFF",
    Latitude: "-30.6949",
    Longitude: "29.3665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3922",
    WardLookupId: "756",
    VDNumber: "11790143",
    RegisteredPopulation: "629",
    VotingStationName: "SIDAKENI SENIOR PRIMARY SCHOOL",
    Address: "SIDAKENI LOC  SIDAKENI A/A  MOUNT AYLIFF",
    Latitude: "-30.663",
    Longitude: "29.3798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3923",
    WardLookupId: "756",
    VDNumber: "11790154",
    RegisteredPopulation: "666",
    VotingStationName: "SIPOLWENI SENIOR PRIMARY SCHOOL",
    Address: "SIPOLWENI  SIPOLWENI A/A  MOUNT AYLIFF",
    Latitude: "-30.7183",
    Longitude: "29.3833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3924",
    WardLookupId: "756",
    VDNumber: "11790750",
    RegisteredPopulation: "378",
    VotingStationName: "SAHLULO SPS",
    Address: "MNAMBITHI LOC  CABAZANA A/A  CABAZANA A/A",
    Latitude: "-30.6856",
    Longitude: "29.3943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3925",
    WardLookupId: "757",
    VDNumber: "11790019",
    RegisteredPopulation: "996",
    VotingStationName: "GILLESPIE PRE-SCHOOL",
    Address: "DANTI  MOUNT AYLIFF",
    Latitude: "-30.7401",
    Longitude: "29.4331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3926",
    WardLookupId: "757",
    VDNumber: "11790334",
    RegisteredPopulation: "812",
    VotingStationName: "TELA SENIOR PRIMARY SCHOOL",
    Address: "TELA LOCATION  TELA A/A  MOUNT AYLIFF",
    Latitude: "-30.7588",
    Longitude: "29.4565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3927",
    WardLookupId: "757",
    VDNumber: "11790547",
    RegisteredPopulation: "594",
    VotingStationName: "NTLAVINI SENIOR PRIMARY SCHOOL",
    Address: "NTLAVINI LOCATION  NTLAVINI A/A  MT AYLIFF",
    Latitude: "-30.7246",
    Longitude: "29.4005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3928",
    WardLookupId: "757",
    VDNumber: "11790558",
    RegisteredPopulation: "965",
    VotingStationName: "GUGWINI SENIOR PRIMARY SCHOOL",
    Address: "DUNDEE LOCATION  DUNDEE  MT AYLIFF",
    Latitude: "-30.722",
    Longitude: "29.4328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3929",
    WardLookupId: "757",
    VDNumber: "11790828",
    RegisteredPopulation: "356",
    VotingStationName: "LOKHWE SHED",
    Address: "LOKHWE  TELA  MOUNT AYLIFF",
    Latitude: "-30.776",
    Longitude: "29.4549",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3930",
    WardLookupId: "757",
    VDNumber: "11790839",
    RegisteredPopulation: "409",
    VotingStationName: "SIYAMTHEMBA PRE-SCHOOL",
    Address: "MANZANA  MOUNT AYLIFF",
    Latitude: "-30.7349",
    Longitude: "29.4599",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3931",
    WardLookupId: "758",
    VDNumber: "11780636",
    RegisteredPopulation: "388",
    VotingStationName: "MYENGWA SENIOR PRIMARY SCHOOL",
    Address: "MYENGWA LOCATION  SIGIDINI A/A  MT AYLIFF",
    Latitude: "-30.8042",
    Longitude: "29.4236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3932",
    WardLookupId: "758",
    VDNumber: "11780838",
    RegisteredPopulation: "394",
    VotingStationName: "SIGAGANE SENIOR PRIMARY SCHOOL.",
    Address: "ESINGENI LOCATION  SIGIDINI A/A  MT. AYLIFF",
    Latitude: "-30.8031",
    Longitude: "29.3896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3933",
    WardLookupId: "758",
    VDNumber: "11790110",
    RegisteredPopulation: "1190",
    VotingStationName: "BHETSHWANA SENIOR PRIMARY SCHOOL",
    Address: "BETSHWANA  BETSHWANA A/A  MOUNT AYLIFF",
    Latitude: "-30.7715",
    Longitude: "29.3941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3934",
    WardLookupId: "758",
    VDNumber: "11790198",
    RegisteredPopulation: "638",
    VotingStationName: "SIGIDINI SENIOR PRIMARY SCHOOL",
    Address: "SIGIDINI LOCATION  SIGIDINI A/A  MOUNT AYLIFF",
    Latitude: "-30.8056",
    Longitude: "29.4104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3935",
    WardLookupId: "758",
    VDNumber: "11790536",
    RegisteredPopulation: "659",
    VotingStationName: "MNQWANE SENIOR PRIMARY SCHOOL",
    Address: "MNQWANE LOCATION  MNQWANE A/A  MT AYLIFF",
    Latitude: "-30.7626",
    Longitude: "29.417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3936",
    WardLookupId: "758",
    VDNumber: "11790569",
    RegisteredPopulation: "435",
    VotingStationName: "MOMBENI JUNIOR SECONDARY SCHOOL",
    Address: "MOMBENI LOCATION  NGZONGISENI A/A  MOUNT AYLIFF",
    Latitude: "-30.7713",
    Longitude: "29.3556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3937",
    WardLookupId: "758",
    VDNumber: "11790693",
    RegisteredPopulation: "519",
    VotingStationName: "MCUBANA JUNIOR SECONDARY SCHOOL.",
    Address: "NGONYAMENI LOCATION  NGZONGISENI A/A  UMZIMVUBU [MOUNT AYLIFF]",
    Latitude: "-30.7648",
    Longitude: "29.3762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3938",
    WardLookupId: "759",
    VDNumber: "11790109",
    RegisteredPopulation: "815",
    VotingStationName: "FIKENI JUNIOR SECONDARY SCHOOL",
    Address: "NDZONGISENI LOCATION  NDZONGISENI A/A  MOUNT AYLIFF",
    Latitude: "-30.754",
    Longitude: "29.3304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3939",
    WardLookupId: "759",
    VDNumber: "11790312",
    RegisteredPopulation: "460",
    VotingStationName: "SIRHOQOBENI JUNIOR SECONDARY SCHOOL",
    Address: "SIRHOQOBENI LOCATION  NTSIZWA A/A  MOUNT AYLIFF",
    Latitude: "-30.7354",
    Longitude: "29.2533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3940",
    WardLookupId: "759",
    VDNumber: "11790345",
    RegisteredPopulation: "472",
    VotingStationName: "MAPHELENI JUNIOR SECONDARY SCHOOL",
    Address: "MAPHELENI LOCATION  NTSIZWA A/A  MOUNT AYLIFF",
    Latitude: "-30.7749",
    Longitude: "29.2564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3941",
    WardLookupId: "759",
    VDNumber: "11790570",
    RegisteredPopulation: "292",
    VotingStationName: "MWACA SENIOR PRIMARY SCHOOL",
    Address: "DAMBENI  MWACA A/A  MT AYLIFF",
    Latitude: "-30.7048",
    Longitude: "29.2445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3942",
    WardLookupId: "759",
    VDNumber: "11790581",
    RegisteredPopulation: "412",
    VotingStationName: "MARHWAQA JUNIOR SECONDARY SCHOOL",
    Address: "MARHWAQA LOCATION  NDZONGISENI A/A  UMZIMBUVU",
    Latitude: "-30.74",
    Longitude: "29.3337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3943",
    WardLookupId: "759",
    VDNumber: "11790592",
    RegisteredPopulation: "363",
    VotingStationName: "QADU SENIOR PRIMARY SCHOOL",
    Address: "QADU LOCATION  NTSIZWA A/A  MOUNT AYLIFF",
    Latitude: "-30.7255",
    Longitude: "29.2907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3944",
    WardLookupId: "759",
    VDNumber: "11790615",
    RegisteredPopulation: "701",
    VotingStationName: "MJOLI JUNIOR SECONDARY SCHOOL",
    Address: "MJOLI LOCATION  NGZONGISENI A/A  MT AYLIFF",
    Latitude: "-30.7952",
    Longitude: "29.3074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3945",
    WardLookupId: "759",
    VDNumber: "11790840",
    RegisteredPopulation: "94",
    VotingStationName: "NONCEDO STORE",
    Address: "MUNYWINI  MWACA  MOUNT AYLIFF",
    Latitude: "-30.7257",
    Longitude: "29.2324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3946",
    WardLookupId: "759",
    VDNumber: "11790851",
    RegisteredPopulation: "237",
    VotingStationName: "NDZONGISENI S.P.S",
    Address: "NDZONGISENI  MOUNT AYLIFF",
    Latitude: "-30.7757",
    Longitude: "29.3204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3947",
    WardLookupId: "760",
    VDNumber: "11760386",
    RegisteredPopulation: "338",
    VotingStationName: "NDARALA SENIOR PRIMARY  SCHOOL",
    Address: "NDARALA LOC  NDARALA A/A  MOUNT FRERE",
    Latitude: "-30.6554",
    Longitude: "29.187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3948",
    WardLookupId: "760",
    VDNumber: "11760623",
    RegisteredPopulation: "222",
    VotingStationName: "MQHEKEZWENI SENIOR PRIMARY SCHOOL",
    Address: "MQHEKEZWENI LOCATION  MZIMVUBU 40 A/A  MT FRERE",
    Latitude: "-30.721",
    Longitude: "29.2154",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3949",
    WardLookupId: "760",
    VDNumber: "11760690",
    RegisteredPopulation: "164",
    VotingStationName: "SILINDINI JUNIOR SECONDARY SCHOOL",
    Address: "SILINDINI  NTSIZWA A/A  MT FRERE",
    Latitude: "-30.679",
    Longitude: "29.1838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3950",
    WardLookupId: "760",
    VDNumber: "11760780",
    RegisteredPopulation: "218",
    VotingStationName: "NQABENI SENIOR PRIMARY SCHOOL",
    Address: "KWELAKABINI LOCATION  NDARALA A/A  UMZIMVUBU",
    Latitude: "-30.6457",
    Longitude: "29.2201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3951",
    WardLookupId: "760",
    VDNumber: "11760791",
    RegisteredPopulation: "396",
    VotingStationName: "SYLLASVILLE SENIOR PRIMARY SCHOOL",
    Address: "NATALA LOCATION  NTSIZWA A/A  UMZIMVUBU",
    Latitude: "-30.7495",
    Longitude: "29.234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3952",
    WardLookupId: "760",
    VDNumber: "11761062",
    RegisteredPopulation: "153",
    VotingStationName: "MQOKWENI SENIOR PRIMARY SCHOOL.",
    Address: "MQOKWENI LOCATION  NGWEKAZANA A/A  UMZIMVUBU [MOUNT AYLIFF]",
    Latitude: "-30.7167",
    Longitude: "29.1686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3953",
    WardLookupId: "760",
    VDNumber: "11761253",
    RegisteredPopulation: "204",
    VotingStationName: "NATALA NTSIZWA JUNIOR SEC SCHOOL",
    Address: "LUSUTHU LOCATION  NTSIZWA A/A  MOUNT AYLIFF",
    Latitude: "-30.7536",
    Longitude: "29.2187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3954",
    WardLookupId: "760",
    VDNumber: "11790031",
    RegisteredPopulation: "529",
    VotingStationName: "GOGELA SENIOR PRIMARY SCHOOL",
    Address: "GOGELA LOCATION  GOGELA A/A  MOUNT AYLIFF",
    Latitude: "-30.6394",
    Longitude: "29.2354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3955",
    WardLookupId: "760",
    VDNumber: "11790176",
    RegisteredPopulation: "351",
    VotingStationName: "MBUMBAZI COMBINED SECONDARY SCHOOL",
    Address: "MBUMBAZI LOCATION  MWACA A/A  MOUNT AYLIFF",
    Latitude: "-30.6833",
    Longitude: "29.1954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3956",
    WardLookupId: "760",
    VDNumber: "11790367",
    RegisteredPopulation: "259",
    VotingStationName: "NDUM-NDUM JUNIOR SECONDARY SCHOOL",
    Address: "NDUM-NDUM LOC  MWACA A/A  MOUNT FRERE",
    Latitude: "-30.6541",
    Longitude: "29.2054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3957",
    WardLookupId: "760",
    VDNumber: "11790716",
    RegisteredPopulation: "318",
    VotingStationName: "CELINKUNGU SENIOR PRIMARY SCHOOL",
    Address: "CELINKUNGU LOCATION  GOGELA A/A  MOUNT AYLIFF",
    Latitude: "-30.6421",
    Longitude: "29.2715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3958",
    WardLookupId: "761",
    VDNumber: "11700010",
    RegisteredPopulation: "2188",
    VotingStationName: "MOUNT AYLIFF TOWN HALL",
    Address: "68 CHURCH STREET  TOWN  MT AYLIFF",
    Latitude: "-30.8084",
    Longitude: "29.3666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3959",
    WardLookupId: "761",
    VDNumber: "11700021",
    RegisteredPopulation: "453",
    VotingStationName: "LUBALASI SENIOR PRIMARY SCHOOL",
    Address: "LUBALASI LOCATION  NGZONGISENI A/A  MOUNT AYLIFF",
    Latitude: "-30.8029",
    Longitude: "29.3436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3960",
    WardLookupId: "761",
    VDNumber: "11700032",
    RegisteredPopulation: "719",
    VotingStationName: "SANTOMBE SENIOR PRIMARY SCHOOL",
    Address: "SANTOMBE  MT AYLIFF",
    Latitude: "-30.8146",
    Longitude: "29.36",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3961",
    WardLookupId: "761",
    VDNumber: "11700043",
    RegisteredPopulation: "1218",
    VotingStationName: "MT AYLIFF     SENIOR SECONDARY SCHOOL",
    Address: "CHITHWA   MT AYLIFF",
    Latitude: "-30.8085",
    Longitude: "29.3612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3962",
    WardLookupId: "761",
    VDNumber: "11790390",
    RegisteredPopulation: "444",
    VotingStationName: "SKHEMANE JUNIOR SECONDARY SCHOOL",
    Address: "SKHEMANE LOC  SKEMANE LOCATION  MOUNT AYLIFF",
    Latitude: "-30.8433",
    Longitude: "29.3314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3963",
    WardLookupId: "762",
    VDNumber: "11790053",
    RegisteredPopulation: "372",
    VotingStationName: "NDAKENI JUNIOR SECONDARY SCHOOL",
    Address: "NDAKENI LOC  NDAKENI A/A  MOUNT AYLIFF",
    Latitude: "-30.7922",
    Longitude: "29.1584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3964",
    WardLookupId: "762",
    VDNumber: "11790086",
    RegisteredPopulation: "655",
    VotingStationName: "DUTYINI JUNIOR SECONDARY SCHOOL",
    Address: "DUTYINI MISSION  DUTYINI A/A  MOUNT AYLIFF",
    Latitude: "-30.8326",
    Longitude: "29.2681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3965",
    WardLookupId: "762",
    VDNumber: "11790097",
    RegisteredPopulation: "961",
    VotingStationName: "LUGELWENI JUNIOR SECONDARY SCHOOL",
    Address: "LUGELWENI LOCATION  NDZONGISENI A/A  MOUNT AYLIFF",
    Latitude: "-30.8079",
    Longitude: "29.3021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3966",
    WardLookupId: "762",
    VDNumber: "11790323",
    RegisteredPopulation: "486",
    VotingStationName: "NTSIZWA SENIOR PRIMARY SCHOOL",
    Address: "LUSUTHU LOCATION  NTSIZWA A/A  MOUNT AYLIFF",
    Latitude: "-30.7817",
    Longitude: "29.2243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3967",
    WardLookupId: "762",
    VDNumber: "11790705",
    RegisteredPopulation: "607",
    VotingStationName: "NYATHINI SENIOR PRIMARY SCHOOL.",
    Address: "NYATHINI LOCATION  DUTYINI A/A  UMZIMVUBU [MOUNT AYLIFF]",
    Latitude: "-30.8271",
    Longitude: "29.3177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3968",
    WardLookupId: "762",
    VDNumber: "11790761",
    RegisteredPopulation: "179",
    VotingStationName: "LUXWESA COMMUNITY HALL",
    Address: "LUXWESA  MT AYLIFF",
    Latitude: "-30.8164",
    Longitude: "29.2003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3969",
    WardLookupId: "762",
    VDNumber: "11790794",
    RegisteredPopulation: "194",
    VotingStationName: "MADADIYELA SENIOR PRIMARY SCHOOL",
    Address: "MADADIYELA  NGWEKAZANA A/A  MT AYLIFF",
    Latitude: "-30.774",
    Longitude: "29.1392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3970",
    WardLookupId: "762",
    VDNumber: "11821137",
    RegisteredPopulation: "225",
    VotingStationName: "SKHUMBENI SENIOR PRIMARY SCHOOL.",
    Address: "SKHUMBENI LOCATION  DUTYINI A/A  MT AYLIFF",
    Latitude: "-30.8583",
    Longitude: "29.2873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3971",
    WardLookupId: "762",
    VDNumber: "11821430",
    RegisteredPopulation: "5",
    VotingStationName: "MVOMVO LOGDE",
    Address: "DUKATHOLE  SIPHUNDU  MOUNT AYLIFF",
    Latitude: "-30.853",
    Longitude: "29.2703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3972",
    WardLookupId: "763",
    VDNumber: "11790075",
    RegisteredPopulation: "635",
    VotingStationName: "MNIKWA SENIOR PRIMARY SCHOOL",
    Address: "SUGAR BUSH LOCATION  MNCEBA A/A  MOUNT AYLIFF",
    Latitude: "-30.8338",
    Longitude: "29.2317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3973",
    WardLookupId: "763",
    VDNumber: "11790356",
    RegisteredPopulation: "821",
    VotingStationName: "ZWELEDINGA SENIOR PRIMARY SCHOOL",
    Address: "SIPHUNDU LOCATION  MNCEBA A/A  MOUNT AYLIFF",
    Latitude: "-30.8508",
    Longitude: "29.2646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3974",
    WardLookupId: "763",
    VDNumber: "11790604",
    RegisteredPopulation: "700",
    VotingStationName: "MHLUZINI SENIOR PRIMARY SCHOOL",
    Address: "MHLOZINI LOC  SIPUNDU A/A  MT AYLIFF",
    Latitude: "-30.8347",
    Longitude: "29.2525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3975",
    WardLookupId: "763",
    VDNumber: "11790626",
    RegisteredPopulation: "857",
    VotingStationName: "LUXWESA  SENIOR PRIMARY SCHOOL",
    Address: "LUXWESA LOC  SUGARBUSH A/A  MT AYLIFF",
    Latitude: "-30.8298",
    Longitude: "29.2056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3976",
    WardLookupId: "763",
    VDNumber: "11790648",
    RegisteredPopulation: "562",
    VotingStationName: "SAPUKANDUKU SENIOR SECONDARY SCHOOL",
    Address: "SAPUKANDUKU LOC  SAPUKANDUKU A/A  UMZIMVUBU",
    Latitude: "-30.8272",
    Longitude: "29.1803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3977",
    WardLookupId: "764",
    VDNumber: "11760083",
    RegisteredPopulation: "589",
    VotingStationName: "LOWER MVENYANE SENIOR PRIMARY SCHOOL",
    Address: "SIQHINGENI LOC  SIQHINGENI A/A  MOUNT FRERE",
    Latitude: "-30.6928",
    Longitude: "29.1122",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3978",
    WardLookupId: "764",
    VDNumber: "11760230",
    RegisteredPopulation: "258",
    VotingStationName: "DINGEZWENI JUNIOR SECONDARY SCHOOL",
    Address: "NGQUMANE LOCATION  NKUNGWINI A/A  MOUNT FRERE",
    Latitude: "-30.5961",
    Longitude: "28.997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3979",
    WardLookupId: "764",
    VDNumber: "11760375",
    RegisteredPopulation: "495",
    VotingStationName: "COLANA SENIOR PRIMARY SCHOOL",
    Address: "COLANA LOC  COLANA A/A  MOUNT FRERE",
    Latitude: "-30.6404",
    Longitude: "29.0572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3980",
    WardLookupId: "764",
    VDNumber: "11760566",
    RegisteredPopulation: "639",
    VotingStationName: "NOMKHOLOKOTHO SENIOR PRIMARY SCHOOL",
    Address: "N/A NOMKHOLOKOTHO  NOMKOLOKOTO A/A  MOUNT FRERE",
    Latitude: "-30.6368",
    Longitude: "29.1118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3981",
    WardLookupId: "764",
    VDNumber: "11760656",
    RegisteredPopulation: "370",
    VotingStationName: "MTSILA SENIOR PRIMARY SCHOOL",
    Address: "MPOZA LOC  MPOZA A/A  MT FRERE",
    Latitude: "-30.7055",
    Longitude: "29.0618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3982",
    WardLookupId: "764",
    VDNumber: "11760667",
    RegisteredPopulation: "453",
    VotingStationName: "MPAMBA SENIOR PRIMARY SCHOOL",
    Address: "MPAMBA  MPAMBA A/A  MOUNT FRERE",
    Latitude: "-30.6782",
    Longitude: "29.0738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3983",
    WardLookupId: "764",
    VDNumber: "11760892",
    RegisteredPopulation: "412",
    VotingStationName: "MDAKENI SENIOR PRIMARY SCHOOL",
    Address: "MDAKENI LOCATION  MDAKENI A/A  MPOUNT FRERE",
    Latitude: "-30.6131",
    Longitude: "29.0546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3984",
    WardLookupId: "764",
    VDNumber: "11760904",
    RegisteredPopulation: "175",
    VotingStationName: "NCINIBA JUNIOR SECONDARY SCHOOL",
    Address: "NCINIBA LOCATION  NGQUMANE A/A  MOUNT FRERE",
    Latitude: "-30.6305",
    Longitude: "29.0144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3985",
    WardLookupId: "764",
    VDNumber: "11761141",
    RegisteredPopulation: "163",
    VotingStationName: "SIFOLWENI SENIOR PRIMARY SCHOOL",
    Address: "SIQHINGENI A/A  MOUNT FRERE  MT FRERE",
    Latitude: "-30.6687",
    Longitude: "29.1155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3986",
    WardLookupId: "764",
    VDNumber: "11761220",
    RegisteredPopulation: "197",
    VotingStationName: "TEMBENI SENIOR PRIMARY SCHOOL",
    Address: "COLANA A/A  MOUNT FRERE  COLANA A/A",
    Latitude: "-30.6317",
    Longitude: "29.0652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3987",
    WardLookupId: "764",
    VDNumber: "11761309",
    RegisteredPopulation: "69",
    VotingStationName: "GQALA  CHURCH HALL",
    Address: "KU GQALA LOCATION  NCINIBA  MT FRERE",
    Latitude: "-30.6199",
    Longitude: "29.0048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3988",
    WardLookupId: "764",
    VDNumber: "11761376",
    RegisteredPopulation: "74",
    VotingStationName: "MADLANGENI SENIOR PRIMARY SCHOOL",
    Address: "NGQUMANE A/A  MOUNT FRERE",
    Latitude: "-30.5957",
    Longitude: "28.9765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3989",
    WardLookupId: "765",
    VDNumber: "11760229",
    RegisteredPopulation: "434",
    VotingStationName: "ZAKHELE J.S.S",
    Address: "NGWEKAZANA LOC  SITHINTENI A/A  MOUNT FRERE",
    Latitude: "-30.7592",
    Longitude: "29.1377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3990",
    WardLookupId: "765",
    VDNumber: "11760342",
    RegisteredPopulation: "464",
    VotingStationName: "MHLOTSHENI JSS",
    Address: "MOUNT AYLIFF  ",
    Latitude: "-30.7909",
    Longitude: "29.0884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3991",
    WardLookupId: "765",
    VDNumber: "11760487",
    RegisteredPopulation: "560",
    VotingStationName: "MZINTO JUNIOR SECONDARY SCHOOL",
    Address: "MZINTO LOC  MZINTO A/A  MOUNT FRERE",
    Latitude: "-30.8358",
    Longitude: "29.1002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3992",
    WardLookupId: "765",
    VDNumber: "11760814",
    RegisteredPopulation: "214",
    VotingStationName: "LUTSHIKINI J.S.S",
    Address: "MHLOTSHENI LOC  MHLOTSHENI A/A  MOUNT FRERE",
    Latitude: "-30.7656",
    Longitude: "29.0953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3993",
    WardLookupId: "765",
    VDNumber: "11761073",
    RegisteredPopulation: "150",
    VotingStationName: "SITHINTENI JUNIOR SECONDARY SCHOOL.",
    Address: "SITHINTENI LOCATION  NGWEKAZANA A/A  UMZIMVUBU [MOUNT AYLIFF]",
    Latitude: "-30.736",
    Longitude: "29.1268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3994",
    WardLookupId: "765",
    VDNumber: "11761095",
    RegisteredPopulation: "441",
    VotingStationName: "QHANQO JUNIOR SECODARY SCHOOL",
    Address: "QHANQO LOCATION  LUTATENI A/A  MT FRERE",
    Latitude: "-30.78",
    Longitude: "29.0726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3995",
    WardLookupId: "765",
    VDNumber: "11761387",
    RegisteredPopulation: "104",
    VotingStationName: "MKHANGISA S.P.S",
    Address: "MKHANGISA  MPOZA  MOUNT AYLIFF",
    Latitude: "-30.7377",
    Longitude: "29.1019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3996",
    WardLookupId: "765",
    VDNumber: "11790064",
    RegisteredPopulation: "1060",
    VotingStationName: "RODE SENIOR SECONDARY SCHOOL",
    Address: "RODE  LOCATION  RODE A/A  MOUNT AYLIFF",
    Latitude: "-30.8177",
    Longitude: "29.1388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3997",
    WardLookupId: "765",
    VDNumber: "11790637",
    RegisteredPopulation: "443",
    VotingStationName: "NYOSINI JUNIOR SECONDARY SCHOOL",
    Address: "NYOSI LOCATION  NYOSINI A/A  MOUNT FRERE",
    Latitude: "-30.8261",
    Longitude: "29.1053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3998",
    WardLookupId: "765",
    VDNumber: "11790772",
    RegisteredPopulation: "352",
    VotingStationName: "VOVENI SENIOR PRIMARY SCHOOL",
    Address: "VOVENI  RODE A/A  RODE A/A",
    Latitude: "-30.8195",
    Longitude: "29.1591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "3999",
    WardLookupId: "766",
    VDNumber: "11760038",
    RegisteredPopulation: "434",
    VotingStationName: "MGUNGUNDLOVU SENIOR PRIMARY SCHOOL",
    Address: "MGUNGUNDLOVU  MGUNGUNDLOVU A/A  MOUNT FRERE",
    Latitude: "-30.7495",
    Longitude: "29.0127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4000",
    WardLookupId: "766",
    VDNumber: "11760140",
    RegisteredPopulation: "568",
    VotingStationName: "ETYENI SENIOR PRIMARY SCHOOL",
    Address: "ETYENI  ETYENI A/A  MOUNT FRERE",
    Latitude: "-30.6859",
    Longitude: "29.0302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4001",
    WardLookupId: "766",
    VDNumber: "11760353",
    RegisteredPopulation: "565",
    VotingStationName: "MPOZA SENIOR PRIMARY SCHOOL",
    Address: "MHLOTSHENI LOC  MHLOTSHENI A/A  MOUNT FRERE",
    Latitude: "-30.7355",
    Longitude: "29.0604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4002",
    WardLookupId: "766",
    VDNumber: "11760555",
    RegisteredPopulation: "752",
    VotingStationName: "LUTATENI SENIOR PRIMARY SCHOOL",
    Address: "LUTATENI LOC  LUTATENI A/A  KWABHACA",
    Latitude: "-30.746",
    Longitude: "29.0508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4003",
    WardLookupId: "766",
    VDNumber: "11760577",
    RegisteredPopulation: "115",
    VotingStationName: "MANQILWENI JUNIOR SECONDARY SCHOOL",
    Address: "MANQILWENI LOCATION  NKUNGWINI A/A  UMZIMVUBU",
    Latitude: "-30.7303",
    Longitude: "28.9844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4004",
    WardLookupId: "766",
    VDNumber: "11760869",
    RegisteredPopulation: "274",
    VotingStationName: "NTIBANE SENIOR PRIMARY SCHOOL",
    Address: "MPOZA A/A  MPOZA  A/A  MOUNT FRERE",
    Latitude: "-30.7097",
    Longitude: "29.023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4005",
    WardLookupId: "766",
    VDNumber: "11760948",
    RegisteredPopulation: "332",
    VotingStationName: "LOWER MKHEMANE SENIOR PRIMARY SCHOOL",
    Address: "MKEMANE  NKUNGWINI A/A  MOUNT FRERE",
    Latitude: "-30.6699",
    Longitude: "29.0016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4006",
    WardLookupId: "766",
    VDNumber: "11761107",
    RegisteredPopulation: "317",
    VotingStationName: "NGUSE SENIOR PRIMARY SCHOOL",
    Address: "NGUSE LOCATION  MPOZA A/A  UMZIMVUBU [MOUNT FRERE]",
    Latitude: "-30.7",
    Longitude: "29.0408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4007",
    WardLookupId: "766",
    VDNumber: "11761398",
    RegisteredPopulation: "2",
    VotingStationName: "MACHELENI HALL",
    Address: "MACHELENI  LUTATENI  MOUNT AYLIFF",
    Latitude: "-30.7939",
    Longitude: "29.0607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4008",
    WardLookupId: "767",
    VDNumber: "11760094",
    RegisteredPopulation: "462",
    VotingStationName: "NKUNGWINI JUNIOR SECONDARY SCHOOL",
    Address: "N/A NKUNGWINI A/A  MOUNT FRERE",
    Latitude: "-30.6986",
    Longitude: "28.9345",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4009",
    WardLookupId: "767",
    VDNumber: "11760432",
    RegisteredPopulation: "538",
    VotingStationName: "MT WHITE SENIOR PRIMARY SCHOOL",
    Address: "MT WHITE  NTLABENI LOCATION  MOUNT FRERE",
    Latitude: "-30.7361",
    Longitude: "28.9197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4010",
    WardLookupId: "767",
    VDNumber: "11760443",
    RegisteredPopulation: "371",
    VotingStationName: "SIHLAHLENI SENIOR PRIMARY SCHOOL",
    Address: "SIHLAHLENI  SIHLAHLENI LOCATION  MOOUNT FRERE",
    Latitude: "-30.7925",
    Longitude: "28.9811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4011",
    WardLookupId: "767",
    VDNumber: "11760599",
    RegisteredPopulation: "472",
    VotingStationName: "TSEWU SENIOR PRIMARY SCHOOL",
    Address: "MKEMANE NO 11  NKUNGWINI A/A  MT FRERE",
    Latitude: "-30.6499",
    Longitude: "28.9728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4012",
    WardLookupId: "767",
    VDNumber: "11760601",
    RegisteredPopulation: "243",
    VotingStationName: "NYOSINI SENIOR PRIMARY SCHOOL",
    Address: "MKEMANE N0 11  NKUNGWINI A/A  MT FRERE",
    Latitude: "-30.6324",
    Longitude: "28.969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4013",
    WardLookupId: "767",
    VDNumber: "11760847",
    RegisteredPopulation: "204",
    VotingStationName: "NGCOZANA SENIOR PRIMARY SCHOOL",
    Address: "NGCOZANA LOCATIO  SIHLAHLENI  MOUNT FRERE",
    Latitude: "-30.7672",
    Longitude: "28.9731",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4014",
    WardLookupId: "767",
    VDNumber: "11760858",
    RegisteredPopulation: "234",
    VotingStationName: "MGODI SENIOR PRIMARY SCHOOL",
    Address: "MGODI LOCATION  SIHLAHLENI A/A  MOUNT FRERE",
    Latitude: "-30.7894",
    Longitude: "28.9978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4015",
    WardLookupId: "767",
    VDNumber: "11760926",
    RegisteredPopulation: "351",
    VotingStationName: "LOWER MT HOREB SENIOR PRIMARY SCHOOL",
    Address: "MATYENI LOCATIONS  NGWETSHENI A/A  MOUNT FRERE",
    Latitude: "-30.7254",
    Longitude: "28.8774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4016",
    WardLookupId: "767",
    VDNumber: "11760937",
    RegisteredPopulation: "457",
    VotingStationName: "MPUNGUTYANE SENIOR PRIMARY SCHOOL",
    Address: "MPUNGUTYAME  NKUNGWINI A/A  MOUNT FRERE",
    Latitude: "-30.6974",
    Longitude: "28.971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4017",
    WardLookupId: "767",
    VDNumber: "11761275",
    RegisteredPopulation: "86",
    VotingStationName: "BETHANY SENIOR PRIMARY SCHOOL",
    Address: "BETHANI LOCATION  BETHANI A/A  BETHANI",
    Latitude: "-30.7493",
    Longitude: "28.9602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4018",
    WardLookupId: "767",
    VDNumber: "11761286",
    RegisteredPopulation: "144",
    VotingStationName: "SIDIKIDINI CHURCH HALL",
    Address: "SIDIKIDINI   NTLABENI  NTLABENI A/A",
    Latitude: "-30.7136",
    Longitude: "28.9032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4019",
    WardLookupId: "767",
    VDNumber: "11761310",
    RegisteredPopulation: "150",
    VotingStationName: "SINYAQA  SENIOR PRIMARY SCHOOL",
    Address: "SINYAQA LOCATION  SIHLAHLENI A/A  MT FRERE",
    Latitude: "-30.788",
    Longitude: "29.0278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4020",
    WardLookupId: "767",
    VDNumber: "11761400",
    RegisteredPopulation: "54",
    VotingStationName: "LUQOLWENI SENIOR PRIMARY SCHOOL",
    Address: "NTLABENI A/A  MOUNT FRERE",
    Latitude: "-30.7625",
    Longitude: "28.9355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4021",
    WardLookupId: "768",
    VDNumber: "11760061",
    RegisteredPopulation: "723",
    VotingStationName: "HUKU SENIOR SECONDARY SCHOOL",
    Address: "MANDILENI A/A  MOUNT FRERE  UMZIMVUBU",
    Latitude: "-30.7216",
    Longitude: "28.804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4022",
    WardLookupId: "768",
    VDNumber: "11760072",
    RegisteredPopulation: "482",
    VotingStationName: "ANTIOK SENIOR PRIMARYY SCHOOL",
    Address: "NQALWENI LOC  NQALWENI A/A  MOUNT FRERE",
    Latitude: "-30.7007",
    Longitude: "28.8609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4023",
    WardLookupId: "768",
    VDNumber: "11760106",
    RegisteredPopulation: "698",
    VotingStationName: "NGWETSHENI SENIOR PRIMARY /UPPER MANDILENI SCHOOL",
    Address: "NGWETSHENI LOC  MANDILENI A/A  MOUNT FRERE",
    Latitude: "-30.7698",
    Longitude: "28.8524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4024",
    WardLookupId: "768",
    VDNumber: "11760117",
    RegisteredPopulation: "315",
    VotingStationName: "MT HOREB SENIOR PRIMARY SCHOOL",
    Address: "MATYENI LOC  MANDILENI A/A  MOUNT FRERE",
    Latitude: "-30.7527",
    Longitude: "28.8718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4025",
    WardLookupId: "768",
    VDNumber: "11760757",
    RegisteredPopulation: "520",
    VotingStationName: "MNDINI SENIOR PRIMARY SCHOOL",
    Address: "MNDINI  MANDILENI A/A  MOUNT FRERE",
    Latitude: "-30.7096",
    Longitude: "28.7969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4026",
    WardLookupId: "768",
    VDNumber: "11761028",
    RegisteredPopulation: "226",
    VotingStationName: "BETHEL SENIOR PRIMARY SCHOOL",
    Address: "GOXE LOCATION  NGWETSHENI A/A  UMZIMVUBU",
    Latitude: "-30.7782",
    Longitude: "28.8656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4027",
    WardLookupId: "768",
    VDNumber: "11761039",
    RegisteredPopulation: "691",
    VotingStationName: "NIYONA JUNIOR SECONDARY SCHOOL",
    Address: "NIYONA LOCATION  MANDILENI A/A  MOUNT FRERE",
    Latitude: "-30.7478",
    Longitude: "28.7932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4028",
    WardLookupId: "768",
    VDNumber: "11761040",
    RegisteredPopulation: "384",
    VotingStationName: "SAHLULO SENIOR PRIMARY SCHOOL",
    Address: "SAHLULO  MANDILENI A/A  MOUNT FRERE",
    Latitude: "-30.7311",
    Longitude: "28.8445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4029",
    WardLookupId: "769",
    VDNumber: "11760151",
    RegisteredPopulation: "474",
    VotingStationName: "MGANU SENIOR PRIMARY SCHOOL",
    Address: "NTENETYANA LOC  LUGANGENI A/A  MOUNT FRERE",
    Latitude: "-30.786",
    Longitude: "28.934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4030",
    WardLookupId: "769",
    VDNumber: "11760364",
    RegisteredPopulation: "983",
    VotingStationName: "MBONDA SENIOR PRIMARY SCHOOL",
    Address: "LUGANGENI A/A  MOUNT FRERE",
    Latitude: "-30.8317",
    Longitude: "28.9321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4031",
    WardLookupId: "769",
    VDNumber: "11760476",
    RegisteredPopulation: "501",
    VotingStationName: "BETHLEHEM SENIOR PRIMARY SCHOOL",
    Address: "NJINJINI A/A  MOUNT FRERE  MT FRERE",
    Latitude: "-30.8373",
    Longitude: "28.8886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4032",
    WardLookupId: "769",
    VDNumber: "11760779",
    RegisteredPopulation: "159",
    VotingStationName: "GUBUZI JUNIOR SECONDARY SCHOOL",
    Address: "GUBUZI LOCATION  LUGANGENI A/A  N/A",
    Latitude: "-30.8446",
    Longitude: "28.9825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4033",
    WardLookupId: "769",
    VDNumber: "11761006",
    RegisteredPopulation: "669",
    VotingStationName: "MNGCISANE SENIOR PRIMARY SCHOOL",
    Address: "MNGCISANE  MVUZI A/A  MOUNT FRERE",
    Latitude: "-30.8763",
    Longitude: "28.9268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4034",
    WardLookupId: "769",
    VDNumber: "11761084",
    RegisteredPopulation: "314",
    VotingStationName: "MALONGWE SENIOR PRIMARY SCHOOL.",
    Address: "MALONGWE LOCATION  LUGANGENI A/A  UMZIMVUBU [MOUNT FRERE]",
    Latitude: "-30.8091",
    Longitude: "28.9522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4035",
    WardLookupId: "769",
    VDNumber: "11761129",
    RegisteredPopulation: "310",
    VotingStationName: "ZWELITSHA JUNIOR SECONDARY SCHOOL.",
    Address: "ZWELITSHA LOCATION  MVUZI A/A  UMZIMVUBU [MOUNT AYLIFF]",
    Latitude: "-30.8679",
    Longitude: "28.908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4036",
    WardLookupId: "769",
    VDNumber: "11761163",
    RegisteredPopulation: "103",
    VotingStationName: "THWA SENIOR PRIMARY SCHOOL",
    Address: "THWA LOCATION  LUGANGENI A/A  MOUNT FRERE",
    Latitude: "-30.8241",
    Longitude: "28.8961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4037",
    WardLookupId: "769",
    VDNumber: "11761174",
    RegisteredPopulation: "280",
    VotingStationName: "ELUKHANYISWENI SENIOR PRIMARY SCHOOL",
    Address: "LUGANGENI A/A  MOUNT FRERE  UMZIMVUBU",
    Latitude: "-30.8309",
    Longitude: "28.9495",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4038",
    WardLookupId: "770",
    VDNumber: "11720012",
    RegisteredPopulation: "1678",
    VotingStationName: "SILVERCITY CHURCH HALL",
    Address: "MAIN STREET  SILVERCITY  MOUNT FRERE",
    Latitude: "-30.9108",
    Longitude: "28.9905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4039",
    WardLookupId: "770",
    VDNumber: "11720034",
    RegisteredPopulation: "70",
    VotingStationName: "SOPHIA TOWNSHIP",
    Address: "SOPHIA STREET  SOPHIA VILLAGE  MT FRERE",
    Latitude: "-30.9103",
    Longitude: "28.9804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4040",
    WardLookupId: "770",
    VDNumber: "11720067",
    RegisteredPopulation: "604",
    VotingStationName: "EXTENSION 7 HALL",
    Address: "EXTENSION 7  MOUNT FRERE  MOUNT FRERE",
    Latitude: "-30.914",
    Longitude: "28.9916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4041",
    WardLookupId: "770",
    VDNumber: "11760634",
    RegisteredPopulation: "1220",
    VotingStationName: "NKULISA SENIOR PRIMARY SCHOOL",
    Address: "NKULISA LOCATION  LUBACWENI A/A  MT FRERE",
    Latitude: "-30.9152",
    Longitude: "29.0025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4042",
    WardLookupId: "770",
    VDNumber: "11761152",
    RegisteredPopulation: "1183",
    VotingStationName: "ELLIOT-NDABANKULU-MAKAULA SENIOR PRIMARY SCHOOL",
    Address: "MOYENI LOCATION  MVUZI A/A  MOUNT FRERE",
    Latitude: "-30.9216",
    Longitude: "28.9746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4043",
    WardLookupId: "771",
    VDNumber: "11760241",
    RegisteredPopulation: "593",
    VotingStationName: "SIMEKWENI SENIOR PRIMARY SCHOOL",
    Address: "NCUNTENI LOC  LUBACWENI A/A  MOUNT FRERE",
    Latitude: "-30.8819",
    Longitude: "29.015",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4044",
    WardLookupId: "771",
    VDNumber: "11760252",
    RegisteredPopulation: "733",
    VotingStationName: "CWALINKUNGU SENIOR PRIMARY SCHOOL",
    Address: "LUBACWENI A/A  MT FRERE  MT FRERE",
    Latitude: "-30.9003",
    Longitude: "29.0223",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4045",
    WardLookupId: "771",
    VDNumber: "11760263",
    RegisteredPopulation: "1555",
    VotingStationName: "ST GEORGES SENIOR PRIMARY SCHOOL",
    Address: "LUNHACWENI   LUBACWENI LOCATION  MOUNT FRERE",
    Latitude: "-30.902",
    Longitude: "29.0021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4046",
    WardLookupId: "771",
    VDNumber: "11760498",
    RegisteredPopulation: "430",
    VotingStationName: "SOBEDE SENIOR PRIMARY SCHOOL",
    Address: "CHANI A/A  CHANI LOCATION  MT FRERE",
    Latitude: "-30.8636",
    Longitude: "29.0618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4047",
    WardLookupId: "771",
    VDNumber: "11760511",
    RegisteredPopulation: "269",
    VotingStationName: "HLANE SENIOR PRIMARY SCHOOL",
    Address: "HLANE LOCATION  LUBACWENI A/A  MT FRERE",
    Latitude: "-30.9163",
    Longitude: "29.0523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4048",
    WardLookupId: "771",
    VDNumber: "11761321",
    RegisteredPopulation: "532",
    VotingStationName: "MZAMO SENIOR PRIMARY SCHOOL",
    Address: "LUBHACWENI LOC  LUBHACWENI A/A  MT FRERE",
    Latitude: "-30.8911",
    Longitude: "29.0061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4049",
    WardLookupId: "771",
    VDNumber: "11761411",
    RegisteredPopulation: "85",
    VotingStationName: "SIJIKA SENIOR PRIMARY SCHOOL",
    Address: "LUBHACWENI A/A  MOUNT FRERE",
    Latitude: "-30.8867",
    Longitude: "29.0515",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4050",
    WardLookupId: "772",
    VDNumber: "11720023",
    RegisteredPopulation: "1695",
    VotingStationName: "MAGISTRATE OFFICES",
    Address: "184 MAIN STREET  MOUNT FRERE  MOUNT FRERE",
    Latitude: "-30.9021",
    Longitude: "28.9931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4051",
    WardLookupId: "772",
    VDNumber: "11720045",
    RegisteredPopulation: "1853",
    VotingStationName: "MT FRERE TECHNICAL COLLEGE",
    Address: "BADIBANISE LOCATION  BADIBANISE A/A  MOUNT FRERE",
    Latitude: "-30.8916",
    Longitude: "28.9888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4052",
    WardLookupId: "772",
    VDNumber: "11720056",
    RegisteredPopulation: "777",
    VotingStationName: "METHODIST CHURCH HALL",
    Address: "MAIN SREET  MOUNT FRERE  MOUNT FRERE",
    Latitude: "-30.8963",
    Longitude: "28.9965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4053",
    WardLookupId: "772",
    VDNumber: "11760870",
    RegisteredPopulation: "408",
    VotingStationName: "LITHA SENIOR PRIMARY SCHOOL",
    Address: "SOPHIA  SOPHIATOWN TOWNSHIP  UMZIMVUBU",
    Latitude: "-30.9055",
    Longitude: "28.9838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4054",
    WardLookupId: "773",
    VDNumber: "11760207",
    RegisteredPopulation: "1016",
    VotingStationName: "SEMENI SENIOR PRIMARY SCHOOL",
    Address: "SEMENI LOC  SEMENI A/A  MOUNT FRERE",
    Latitude: "-30.9375",
    Longitude: "28.9723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4055",
    WardLookupId: "773",
    VDNumber: "11760274",
    RegisteredPopulation: "364",
    VotingStationName: "QUMRA SENIOR  PRIMARY SCHOOL",
    Address: "QUMRA A/A  MOUNT FRERE",
    Latitude: "-30.9381",
    Longitude: "29.0581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4056",
    WardLookupId: "773",
    VDNumber: "11760320",
    RegisteredPopulation: "639",
    VotingStationName: "MTSHAZI SENIOR PRIMARY SCHOOL",
    Address: "MTSHAZI A/A  MOUNT AYLIFF",
    Latitude: "-30.9391",
    Longitude: "29.0175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4057",
    WardLookupId: "773",
    VDNumber: "11760724",
    RegisteredPopulation: "985",
    VotingStationName: "MNTWANA SENIOR PRIMARY SCHOOL",
    Address: "MTSHAZI A/A  MT FRERE  MT FRERE",
    Latitude: "-30.9516",
    Longitude: "28.9716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4058",
    WardLookupId: "773",
    VDNumber: "11760971",
    RegisteredPopulation: "880",
    VotingStationName: "UPPER DUNGU SENIOR PRIMARY SCHOOL",
    Address: "MVUZI LOCATION  MVUZI A/A  UMZIMVUBU[MOUNT FRERE]",
    Latitude: "-30.942",
    Longitude: "28.9591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4059",
    WardLookupId: "774",
    VDNumber: "11760285",
    RegisteredPopulation: "818",
    VotingStationName: "NGWEKAZI HIGH SCHOOL",
    Address: "NGWEKAZI LOC  NGXABAXA A/A  MOUNT FRERE",
    Latitude: "-31.0116",
    Longitude: "28.944",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4060",
    WardLookupId: "774",
    VDNumber: "11760319",
    RegisteredPopulation: "176",
    VotingStationName: "MPEMBA JSS",
    Address: "MPEMBA  MPEMBA A/A  MT FRERE",
    Latitude: "-31.0153",
    Longitude: "28.9847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4061",
    WardLookupId: "774",
    VDNumber: "11760533",
    RegisteredPopulation: "327",
    VotingStationName: "LWANDLANA SENIOR PRIMARY SCHOOL",
    Address: "LWANDLANA LOCATION  LWANDLANA A/A  MOUNT FRERE",
    Latitude: "-31.0412",
    Longitude: "28.8864",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4062",
    WardLookupId: "774",
    VDNumber: "11760645",
    RegisteredPopulation: "217",
    VotingStationName: "MAJUBA SENIOR PRIMARY SCHOOL",
    Address: "MAJUBA LOCATION  MPEMBA A/A  MT FRERE",
    Latitude: "-31.0383",
    Longitude: "28.968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4063",
    WardLookupId: "774",
    VDNumber: "11760689",
    RegisteredPopulation: "468",
    VotingStationName: "MAHAMANE JUNIOR SECONDARY SCHOOL",
    Address: "MAHAMANE LOCAT.  OSBORN A/A  MT FRERE",
    Latitude: "-30.9755",
    Longitude: "28.9798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4064",
    WardLookupId: "774",
    VDNumber: "11760702",
    RegisteredPopulation: "1175",
    VotingStationName: "NCAPAI SENIOR PRIMARY SCHOOL",
    Address: "DANGWANA A/A  DANGWANA LOCATION  MOUNT FRERE",
    Latitude: "-30.978",
    Longitude: "28.9257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4065",
    WardLookupId: "774",
    VDNumber: "11760735",
    RegisteredPopulation: "289",
    VotingStationName: "CABANE JUNIOR SECONDARY SCHOOL",
    Address: "N/A MPEMBA A/A  MT FRERE  MT FRERE",
    Latitude: "-31.0012",
    Longitude: "28.9724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4066",
    WardLookupId: "774",
    VDNumber: "11760959",
    RegisteredPopulation: "156",
    VotingStationName: "LUCINGWENI SENIOR PRIMARY SCHOOL",
    Address: "LUCINGWENI  LWANDLANA A/A  MOUNT FRERE",
    Latitude: "-31.0662",
    Longitude: "28.9024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4067",
    WardLookupId: "774",
    VDNumber: "11760960",
    RegisteredPopulation: "306",
    VotingStationName: "MKHILWA SENIOR PRIMARY SCHOOL",
    Address: "LWANDLANA LOCATION  LWANDLANA A/A  MOUNT FRERE",
    Latitude: "-31.0477",
    Longitude: "28.9102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4068",
    WardLookupId: "774",
    VDNumber: "11761332",
    RegisteredPopulation: "477",
    VotingStationName: "NGQINIBENI SENIOR PRIMARY SCHOOL",
    Address: "NGQINIBENI   DANGWANA A /A  MT FRERE",
    Latitude: "-30.9678",
    Longitude: "28.9544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4069",
    WardLookupId: "775",
    VDNumber: "11760016",
    RegisteredPopulation: "662",
    VotingStationName: "QAIZANA SENIOR PRIMARY SCHOOL",
    Address: "QAIZANA  QAIZANA A/A  MOUNT FRERE",
    Latitude: "-30.9303",
    Longitude: "28.8636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4070",
    WardLookupId: "775",
    VDNumber: "11760195",
    RegisteredPopulation: "700",
    VotingStationName: "QOQA SENIOR PRIMARY SCHOOL",
    Address: "QOQA LOC  MVUZI A/A  MOUNT FRERE",
    Latitude: "-30.9026",
    Longitude: "28.9155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4071",
    WardLookupId: "775",
    VDNumber: "11760218",
    RegisteredPopulation: "535",
    VotingStationName: "MVUZI SENIOR PRIMARY SCHOOL",
    Address: "MVUZI LOCATION  MOUNT FRERE",
    Latitude: "-30.9353",
    Longitude: "28.9323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4072",
    WardLookupId: "775",
    VDNumber: "11760296",
    RegisteredPopulation: "613",
    VotingStationName: "TOLENI JUNIOR SECONDARY SCHOOL",
    Address: "TOLENI A/A  MOUNT FRERE",
    Latitude: "-30.9504",
    Longitude: "28.8957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4073",
    WardLookupId: "775",
    VDNumber: "11760308",
    RegisteredPopulation: "359",
    VotingStationName: "DANGWANA SENIOR PRIMARY SCHOOL",
    Address: "DANGWANA A/A  MT FRERE",
    Latitude: "-30.9829",
    Longitude: "28.9056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4074",
    WardLookupId: "775",
    VDNumber: "11760713",
    RegisteredPopulation: "336",
    VotingStationName: "ESSECK SENIOR PRIMARY SCHOOL",
    Address: "DANGWANA A/A  MT FRERE",
    Latitude: "-31.006",
    Longitude: "28.9119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4075",
    WardLookupId: "775",
    VDNumber: "11761017",
    RegisteredPopulation: "254",
    VotingStationName: "MPINDWENI SENIOR PRIMARY SCHOOL",
    Address: "MPINDWENI LOCATION  MABOBO A/A  MOUNT FRERE",
    Latitude: "-30.9364",
    Longitude: "28.8361",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4076",
    WardLookupId: "775",
    VDNumber: "11761118",
    RegisteredPopulation: "228",
    VotingStationName: "NTUTA JUNIOR SECONDARY SCHOOL.",
    Address: "MHLANGANISWENI LOCATION  TOLENI A/A  UMZIMVUBU [MOUNT FRERE]",
    Latitude: "-30.9767",
    Longitude: "28.8605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4077",
    WardLookupId: "775",
    VDNumber: "11761231",
    RegisteredPopulation: "515",
    VotingStationName: "MVUMVU CHURCH HALL",
    Address: "TOLENI A/A  MOUNT FRERE  TOLENI A/A",
    Latitude: "-30.9693",
    Longitude: "28.9115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4078",
    WardLookupId: "775",
    VDNumber: "11761365",
    RegisteredPopulation: "71",
    VotingStationName: "MBIZWENI SENIOR PRIMARY SCHOOL",
    Address: "LWANDLANA  LWANDLANA A/A  MOUNT FRERE",
    Latitude: "-31.0122",
    Longitude: "28.8823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4079",
    WardLookupId: "776",
    VDNumber: "11760162",
    RegisteredPopulation: "764",
    VotingStationName: "UPPER BUFALLO JUNIOR SECONDARY SCHOOL",
    Address: "NJIJINI A/A  MOUNT FRERE  MOUNT FRERE",
    Latitude: "-30.8218",
    Longitude: "28.8567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4080",
    WardLookupId: "776",
    VDNumber: "11760173",
    RegisteredPopulation: "340",
    VotingStationName: "SODLADLA SENIOR PRIMARY SCHOOL",
    Address: "MABOBO A/A  MOUNT FRERE  MOUNT FRERE",
    Latitude: "-30.8715",
    Longitude: "28.8047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4081",
    WardLookupId: "776",
    VDNumber: "11760184",
    RegisteredPopulation: "646",
    VotingStationName: "LUSIZINI JUNIOR SECONDARY SCHOOL",
    Address: "LUSIZINI LOC  MABOBO A/A  MOUNT FRERE",
    Latitude: "-30.8669",
    Longitude: "28.8531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4082",
    WardLookupId: "776",
    VDNumber: "11760454",
    RegisteredPopulation: "786",
    VotingStationName: "NJIJINI SENIOR PRIMARY SCHOOL",
    Address: "NJIJINI  NJIJINI LOCATION  MOUNT FRERE",
    Latitude: "-30.8258",
    Longitude: "28.8213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4083",
    WardLookupId: "776",
    VDNumber: "11760465",
    RegisteredPopulation: "477",
    VotingStationName: "MABOBO SENIOR PRIMARY SCHOOL",
    Address: "MBIZENI LOCATION  MABOBO ADMINISTRATIVE AREA  MOUNT FRERE",
    Latitude: "-30.8996",
    Longitude: "28.8443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4084",
    WardLookupId: "776",
    VDNumber: "11761219",
    RegisteredPopulation: "423",
    VotingStationName: "KUYASA SENIOR PRIMARY SCHOOL",
    Address: "MABOBO LOCATION  MABOBO A/A  MOUNT FRERE",
    Latitude: "-30.8441",
    Longitude: "28.8716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4085",
    WardLookupId: "776",
    VDNumber: "11761422",
    RegisteredPopulation: "70",
    VotingStationName: "SANDLULUBE SENIOR PRIMARY SCHOOL",
    Address: "SANDLULUBE LOCATION  MABOBO  MT FRERE",
    Latitude: "-30.9135",
    Longitude: "28.8334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4086",
    WardLookupId: "777",
    VDNumber: "11760050",
    RegisteredPopulation: "431",
    VotingStationName: "QWIDLANA SENIOR PRIMARY SCHOOL",
    Address: "QWIDLANA A/A  MOUNT FRERE",
    Latitude: "-30.8086",
    Longitude: "28.7098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4087",
    WardLookupId: "777",
    VDNumber: "11760128",
    RegisteredPopulation: "641",
    VotingStationName: "PONDOMISE RIDGE SENIOR PRIMARY SCHOOL",
    Address: "PONDOMISE LOC  CANCELE A/A  MOUNT FRERE",
    Latitude: "-30.8357",
    Longitude: "28.7552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4088",
    WardLookupId: "777",
    VDNumber: "11760139",
    RegisteredPopulation: "512",
    VotingStationName: "CHANCELE SENIOR PRIMARY SCHOOL",
    Address: "CANCELE A/A  MOUNT FRERE",
    Latitude: "-30.8472",
    Longitude: "28.7858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4089",
    WardLookupId: "777",
    VDNumber: "11760421",
    RegisteredPopulation: "353",
    VotingStationName: "GCINISIZWE JUNIOR SECONDARY SCHOOL",
    Address: "QWIDLANA A/A  MOUNT FRERE",
    Latitude: "-30.8039",
    Longitude: "28.688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4090",
    WardLookupId: "777",
    VDNumber: "11760612",
    RegisteredPopulation: "590",
    VotingStationName: "MAGXENI SENIOR PRIMARY SCHOOL",
    Address: "CANCELE A/A  MAGXENI LOC  MT FRERE",
    Latitude: "-30.8324",
    Longitude: "28.8054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4091",
    WardLookupId: "777",
    VDNumber: "11760678",
    RegisteredPopulation: "130",
    VotingStationName: "MATYAMHLOPHE SENIOR PRIMARY SCHOOL",
    Address: "MATYAMHLOPHE LOCAT.  QWIDLANA A/A  MT FRERE",
    Latitude: "-30.708",
    Longitude: "28.656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4092",
    WardLookupId: "777",
    VDNumber: "11761130",
    RegisteredPopulation: "329",
    VotingStationName: "QUKANCA JUNIOR SECONDARY SCHOOL.",
    Address: "QUKANCA LOCATION  QWIDLANA A/A  UMZIMVUBU [MOUNT FRERE]",
    Latitude: "-30.8262",
    Longitude: "28.7326",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4093",
    WardLookupId: "777",
    VDNumber: "11761196",
    RegisteredPopulation: "160",
    VotingStationName: "MLENZE SENIOR PRIMARY SCHOOL",
    Address: "MLENZE  MT FRERE  MOUNT FRERE",
    Latitude: "-30.8057",
    Longitude: "28.6671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4094",
    WardLookupId: "777",
    VDNumber: "11761208",
    RegisteredPopulation: "229",
    VotingStationName: "DLABANENI CHURCH",
    Address: "N/A  QWIDLANA A/A  MOUNT FRERE",
    Latitude: "-30.796",
    Longitude: "28.717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4095",
    WardLookupId: "777",
    VDNumber: "11800145",
    RegisteredPopulation: "262",
    VotingStationName: "GXAKU JUNIOR SECONDARY SCHOOL",
    Address: "GXAKU LOC.  GXAKU A/A  EC5B2",
    Latitude: "-30.7034",
    Longitude: "28.6422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4096",
    WardLookupId: "777",
    VDNumber: "11800651",
    RegisteredPopulation: "199",
    VotingStationName: "MVUZI SENIOR PRIMARY SCHOOL",
    Address: "MVUZI LOCATION  MVUZI A/A  MALUTI",
    Latitude: "-30.7288",
    Longitude: "28.6474",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4097",
    WardLookupId: "777",
    VDNumber: "11801034",
    RegisteredPopulation: "103",
    VotingStationName: "GEBANE SENIOR PRIMARY SCHOOL",
    Address: "MAHOBE LOCATION  GXAKU A/A  MALUTI",
    Latitude: "-30.6808",
    Longitude: "28.562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4098",
    WardLookupId: "778",
    VDNumber: "11760027",
    RegisteredPopulation: "915",
    VotingStationName: "ST MARKS JUNIOR SECONDARY SCHOOL",
    Address: "LUYENGWENI A/A  MOUNT FRERE",
    Latitude: "-30.6531",
    Longitude: "28.7626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4099",
    WardLookupId: "778",
    VDNumber: "11760049",
    RegisteredPopulation: "356",
    VotingStationName: "EMAXHEGWENI SENIOR PRIMARY SCHOOL",
    Address: "EMAXHEGWINI  NCOME A/A  MT FRERE",
    Latitude: "-30.6943",
    Longitude: "28.7394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4100",
    WardLookupId: "778",
    VDNumber: "11760397",
    RegisteredPopulation: "492",
    VotingStationName: "UPPER MNYAMANE SENIOR PRIMARY SCHOOL",
    Address: "UPPER MNYAMANA LOC  MNYAMANA A/A  MOUNT FRERE",
    Latitude: "-30.6081",
    Longitude: "28.7294",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4101",
    WardLookupId: "778",
    VDNumber: "11760409",
    RegisteredPopulation: "630",
    VotingStationName: "NCOME SENIOR PRIMARY SCHOOL",
    Address: "NCOME  NCOME A/A  MOUNT FRERE",
    Latitude: "-30.6925",
    Longitude: "28.77",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4102",
    WardLookupId: "778",
    VDNumber: "11760410",
    RegisteredPopulation: "293",
    VotingStationName: "ST METHEWS SENIOR PRIMARY SCHOOL",
    Address: "NCOME A/A  MOUNT FRERE",
    Latitude: "-30.6697",
    Longitude: "28.6611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4103",
    WardLookupId: "778",
    VDNumber: "11760588",
    RegisteredPopulation: "408",
    VotingStationName: "EZIGADINI SENIOR PRIMARY SCHOOL",
    Address: "MANQILWENI LOCAT.  NKUNGWINI A/A  MT FRERE",
    Latitude: "-30.7128",
    Longitude: "28.7367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4104",
    WardLookupId: "778",
    VDNumber: "11760836",
    RegisteredPopulation: "231",
    VotingStationName: "TSHISANE SENIOR PRIMARY SCHOOL",
    Address: "TSHISANE LOCATION  NCOME A/A  UMZIMBUVU",
    Latitude: "-30.6388",
    Longitude: "28.6788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4105",
    WardLookupId: "778",
    VDNumber: "11760881",
    RegisteredPopulation: "224",
    VotingStationName: "CHWEBENI JUNIOR SECONDARY SCHOOL.",
    Address: "LUYENGWENI A/A  MOUNT FRERE",
    Latitude: "-30.6563",
    Longitude: "28.8297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4106",
    WardLookupId: "778",
    VDNumber: "11761242",
    RegisteredPopulation: "330",
    VotingStationName: "LOWER MNYAMANA JUNIOR SECONDARY SCHOOL",
    Address: "LUYENGWENI A/A  MOUNT FRERE  LUYENGWENI J.S.S",
    Latitude: "-30.6345",
    Longitude: "28.7847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4107",
    WardLookupId: "778",
    VDNumber: "11761343",
    RegisteredPopulation: "116",
    VotingStationName: "FAIRVIEW SENIOR PRIMARY SCHOOL",
    Address: "MAGONTSINI LOCATION  NCOME A/A  MT FRERE",
    Latitude: "-30.6916",
    Longitude: "28.8121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4108",
    WardLookupId: "778",
    VDNumber: "11761433",
    RegisteredPopulation: "122",
    VotingStationName: "MVUMELWANO CHURCH HALL",
    Address: "MVUMELWANO LOCATION  NCOME ADMIN AREA  MOUNT FRERE",
    Latitude: "-30.6921",
    Longitude: "28.7235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4109",
    WardLookupId: "779",
    VDNumber: "11760522",
    RegisteredPopulation: "687",
    VotingStationName: "OSBORN MISSION",
    Address: "TSHUNGWANE LOCATION  OSBORN A/A  MT. FRERE",
    Latitude: "-30.9726",
    Longitude: "29.0271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4110",
    WardLookupId: "779",
    VDNumber: "11761264",
    RegisteredPopulation: "405",
    VotingStationName: "BAPHATHE SENIOR.PRIMARY.SCHOOL",
    Address: "OSBORN  TSHUNGWANA  MOUNT FRERE",
    Latitude: "-30.96",
    Longitude: "29.0259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4111",
    WardLookupId: "779",
    VDNumber: "11761354",
    RegisteredPopulation: "112",
    VotingStationName: "MAPHAKAMA JUNIOR SECONDARY  SCHOOL",
    Address: "MGUGA  TSHUNGWANA   MT FRERE",
    Latitude: "-30.9971",
    Longitude: "29.0267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4112",
    WardLookupId: "779",
    VDNumber: "11761444",
    RegisteredPopulation: "162",
    VotingStationName: "VUKUZAKHE SENIOR PRIMARY SCHOOL",
    Address: "TSHUNGWANA A/A  MOUNT FRERE",
    Latitude: "-30.9648",
    Longitude: "29.0075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4113",
    WardLookupId: "779",
    VDNumber: "11820204",
    RegisteredPopulation: "1014",
    VotingStationName: "GALILEE SENIOR PRIMARY SCHOOL",
    Address: "MASOMNTWANA LOC  CHIBINI A/A  NTABANKULU",
    Latitude: "-31.0339",
    Longitude: "29.0701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4114",
    WardLookupId: "779",
    VDNumber: "11820259",
    RegisteredPopulation: "733",
    VotingStationName: "MKHONKQO JUNIOR SECONDARY SCHOOL",
    Address: "MANGQAMZENI LOCATION  CACADU A/A  NTABANKULU",
    Latitude: "-30.9969",
    Longitude: "29.076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4115",
    WardLookupId: "779",
    VDNumber: "11820619",
    RegisteredPopulation: "549",
    VotingStationName: "MNXEKAZI SENIOR PRIMARY SCHOOL",
    Address: "CACADU A/A  MNXEKAZI LOCALITY  TABANKULU",
    Latitude: "-30.9589",
    Longitude: "29.1066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4116",
    WardLookupId: "779",
    VDNumber: "11820888",
    RegisteredPopulation: "133",
    VotingStationName: "MPOLOSA SENIOR PRIMARY SCHOOL",
    Address: "MPOLOSA LOCATION  CACADU A/A  NTABANKULU",
    Latitude: "-31.0056",
    Longitude: "29.1243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4117",
    WardLookupId: "779",
    VDNumber: "11820978",
    RegisteredPopulation: "412",
    VotingStationName: "ZWELIDUMILE SENIOR PRIMARY SCHOOL",
    Address: "NGXOTHO LOCATION  CHIBINI A/A  NTABANKULU",
    Latitude: "-31.0247",
    Longitude: "29.112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4118",
    WardLookupId: "779",
    VDNumber: "11820989",
    RegisteredPopulation: "145",
    VotingStationName: "BATSHANA SENIOR PRIMARY SCHOOL",
    Address: "PAPANE LOCATION  CACADU A/A  NTABANKULU",
    Latitude: "-30.9929",
    Longitude: "29.0598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4119",
    WardLookupId: "780",
    VDNumber: "11760993",
    RegisteredPopulation: "596",
    VotingStationName: "GOBA SENIOR PRIMARY SCHOOL",
    Address: "TRUSTINI LOCATION  CABAZANA A/A  UMZIMVUBU",
    Latitude: "-30.6549",
    Longitude: "29.4692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4120",
    WardLookupId: "780",
    VDNumber: "11790042",
    RegisteredPopulation: "641",
    VotingStationName: "LOWER CABAZANA JUNIOR SECONDARY SCHOOL",
    Address: "L/CABAZANA LOCATION  CABAZANA A/A  MOUNT AYLIFF",
    Latitude: "-30.6905",
    Longitude: "29.4103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4121",
    WardLookupId: "780",
    VDNumber: "11790187",
    RegisteredPopulation: "1014",
    VotingStationName: "MVALWENI JUNIOR SECONDARY SCHOOL",
    Address: "MVALWENI LOCATION  MVALWENI A/A  MOUNT AYLIFF",
    Latitude: "-30.7064",
    Longitude: "29.452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4122",
    WardLookupId: "780",
    VDNumber: "11790301",
    RegisteredPopulation: "565",
    VotingStationName: "UPPER CABAZANA SENIOR PRIMARY SCHOOL",
    Address: "EZINKAWINI LOCATION  CABAZANA A/A  MOUNT AYLIFF",
    Latitude: "-30.6743",
    Longitude: "29.458",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4123",
    WardLookupId: "780",
    VDNumber: "11790525",
    RegisteredPopulation: "541",
    VotingStationName: "TEMBISA SENIOR PRIMARY SCHOOL",
    Address: "PHUKA LOCATION  MVALWENI A/A  MT AYLIFF",
    Latitude: "-30.6866",
    Longitude: "29.4582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4124",
    WardLookupId: "780",
    VDNumber: "11790783",
    RegisteredPopulation: "351",
    VotingStationName: "VALIPHATHWA SENIOR PRIMARY SCHOOL",
    Address: "NYANTUNGO   MVALWENI A/A  MT AYLIFF",
    Latitude: "-30.6988",
    Longitude: "29.4747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4125",
    WardLookupId: "780",
    VDNumber: "11790806",
    RegisteredPopulation: "356",
    VotingStationName: "NONKQUBELA SENIOR PRIMARY SCHOOL",
    Address: "SOLANI  CABAZANA A/A  MT AYLIFF",
    Latitude: "-30.6861",
    Longitude: "29.4265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4126",
    WardLookupId: "781",
    VDNumber: "11760331",
    RegisteredPopulation: "515",
    VotingStationName: "CABAZI SENIOR PRIMARY SCHOOL",
    Address: "CABAZI A/A  MT FRERE  MT FRERE",
    Latitude: "-30.8348",
    Longitude: "29.0062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4127",
    WardLookupId: "781",
    VDNumber: "11760500",
    RegisteredPopulation: "1035",
    VotingStationName: "MBODLENI SENIOR PRIMARY SCHOOL",
    Address: "MBODLENI A/A  MT FRERE  MT FRERE",
    Latitude: "-30.8721",
    Longitude: "28.9892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4128",
    WardLookupId: "781",
    VDNumber: "11760803",
    RegisteredPopulation: "295",
    VotingStationName: "UMZIMVUBU SENIOR PRIMARY SCHOOL",
    Address: "BUTSHENI  NKWAZINI A/A  MOUNT FRERE",
    Latitude: "-30.8396",
    Longitude: "29.054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4129",
    WardLookupId: "781",
    VDNumber: "11760825",
    RegisteredPopulation: "199",
    VotingStationName: "SIBHOZWENI SENIOR PRIMARY SCHOOL",
    Address: "SIBHOZWENI LOC  LUGANGENI A/A  MOUNT FRERE",
    Latitude: "-30.8189",
    Longitude: "29.0404",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4130",
    WardLookupId: "781",
    VDNumber: "11760982",
    RegisteredPopulation: "804",
    VotingStationName: "MTSANA CHURCH HALL",
    Address: "MTSANA LOCATION  MBODLENI A/A  MOUNT FRERE",
    Latitude: "-30.8688",
    Longitude: "29.0113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4131",
    WardLookupId: "781",
    VDNumber: "11761185",
    RegisteredPopulation: "335",
    VotingStationName: "SIQHINGENI SENIOR PRIMARY SCHOOL",
    Address: "SIQHINGENI LOC  CABAZI A/A  MT FRERE",
    Latitude: "-30.8305",
    Longitude: "29.0275",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4132",
    WardLookupId: "781",
    VDNumber: "11761297",
    RegisteredPopulation: "372",
    VotingStationName: "MPENDLA SENIOR PRIMARY SCHOOL",
    Address: "MPENDLA  MBODLENI A/A  MPENDLA",
    Latitude: "-30.8422",
    Longitude: "29.0034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4133",
    WardLookupId: "3216",
    VDNumber: "11670016",
    RegisteredPopulation: "1576",
    VotingStationName: "MAGISTRATE OFFICE",
    Address: "148 MAIN STREET  TOWN  BIZANA",
    Latitude: "-30.8557",
    Longitude: "29.8553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4134",
    WardLookupId: "3216",
    VDNumber: "11670027",
    RegisteredPopulation: "1328",
    VotingStationName: "ST PATRICKS SENIOR PRIMARY SCHOOL",
    Address: "HIGHLAND VIEW  BIZANA  BIZANA",
    Latitude: "-30.8583",
    Longitude: "29.8446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4135",
    WardLookupId: "3216",
    VDNumber: "11670038",
    RegisteredPopulation: "397",
    VotingStationName: "BIZANA VILLAGE PRE-SCHOOL",
    Address: "EXT 3  FURGASON  BIZANA",
    Latitude: "-30.8496",
    Longitude: "29.8643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4136",
    WardLookupId: "3216",
    VDNumber: "11670049",
    RegisteredPopulation: "1181",
    VotingStationName: "BIZANA ROMAN CATHOLIC CHURCH",
    Address: "MAIN STREET  BIZANA  BIZANA",
    Latitude: "-30.8632",
    Longitude: "29.8488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4137",
    WardLookupId: "3216",
    VDNumber: "11751937",
    RegisteredPopulation: "313",
    VotingStationName: "BIZANA BAPTIST CHURCH",
    Address: "EXTENSION 2  EMABHANOYINI LOCATON  BIZANA, 4800",
    Latitude: "-30.8594",
    Longitude: "29.8603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4138",
    WardLookupId: "3217",
    VDNumber: "11750059",
    RegisteredPopulation: "627",
    VotingStationName: "NTAMONDE JUNIOR SECONDARY SCHOOL",
    Address: "NTAMONDE LOCALITY  AMANTSHANGASE A/A  BIZANA",
    Latitude: "-30.7865",
    Longitude: "29.6202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4139",
    WardLookupId: "3217",
    VDNumber: "11751780",
    RegisteredPopulation: "569",
    VotingStationName: "NTLAVUKAZI JUNIOR SECONDARY SCHOOL",
    Address: "MKHANDLWIN  IZILANGWE A/A  BIZANA",
    Latitude: "-30.7486",
    Longitude: "29.6479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4140",
    WardLookupId: "3217",
    VDNumber: "11790255",
    RegisteredPopulation: "346",
    VotingStationName: "GOXE JUNIOR SECONDARY SCHOOL",
    Address: "MBUTHWENI LOCALITY  GOXE A/A  BIZANA",
    Latitude: "-30.7268",
    Longitude: "29.6182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4141",
    WardLookupId: "3217",
    VDNumber: "11790266",
    RegisteredPopulation: "714",
    VotingStationName: "MPENI SENIOR SECONDARY SCHOOL",
    Address: "NQUTHU LOCATION  MTAMVUNA A/A  MOUNT AYLIFF",
    Latitude: "-30.6989",
    Longitude: "29.611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4142",
    WardLookupId: "3217",
    VDNumber: "11790277",
    RegisteredPopulation: "714",
    VotingStationName: "NGELE JUNIOR SECONDARY SCHOOL",
    Address: "GOXE LOCATION  MTAMVUNA A/A  BIZANA",
    Latitude: "-30.7027",
    Longitude: "29.5553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4143",
    WardLookupId: "3217",
    VDNumber: "11790659",
    RegisteredPopulation: "252",
    VotingStationName: "MKAMBATHI SENIOR PRIMARY SCHOOL",
    Address: "MKHAMBATHI LOCATION  INGELE A/A  BIZANA, 4800",
    Latitude: "-30.7409",
    Longitude: "29.5681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4144",
    WardLookupId: "3217",
    VDNumber: "11790660",
    RegisteredPopulation: "215",
    VotingStationName: "MAFADOBO SENIOR PRIMARY SCHOOL",
    Address: "MAFADOBO LOCATION  GOXE A/A  BIZANA, 4800",
    Latitude: "-30.6768",
    Longitude: "29.6712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4145",
    WardLookupId: "3217",
    VDNumber: "11790671",
    RegisteredPopulation: "175",
    VotingStationName: "NOKHATSHILE SENIOR PRIMARY SCHOOL",
    Address: "NOKHATSHILE LOCATION  MPENI A/A  BIZANA",
    Latitude: "-30.7094",
    Longitude: "29.6541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4146",
    WardLookupId: "3217",
    VDNumber: "11790727",
    RegisteredPopulation: "193",
    VotingStationName: "LALENI SENIOR PRIMARY SCHOOL",
    Address: "LALENI LOCATION  GOXE A/A  MT. AYLIFF",
    Latitude: "-30.7443",
    Longitude: "29.6013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4147",
    WardLookupId: "3217",
    VDNumber: "11790817",
    RegisteredPopulation: "168",
    VotingStationName: "ESINYAMENI  PRIMARY SCHOOL",
    Address: "ESINYAMENI LOCATION   QUTHU   MT. AYLIFF",
    Latitude: "-30.7054",
    Longitude: "29.5734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4148",
    WardLookupId: "3218",
    VDNumber: "11750037",
    RegisteredPopulation: "403",
    VotingStationName: "NTOLA JUNIOR SECONDARY SCHOOL",
    Address: "NTOLA  LOC.  AMANTSHANGASE A/A  BIZANA",
    Latitude: "-30.8489",
    Longitude: "29.5895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4149",
    WardLookupId: "3218",
    VDNumber: "11750048",
    RegisteredPopulation: "394",
    VotingStationName: "DUMSI COMPREHESIVE HIGH SCHOOL",
    Address: "PO DUMSI  MANTSHANGASE A/A  BIZANA",
    Latitude: "-30.7709",
    Longitude: "29.568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4150",
    WardLookupId: "3218",
    VDNumber: "11750060",
    RegisteredPopulation: "221",
    VotingStationName: "YANGE SENIOR PRIMARY SCHOOL",
    Address: "YANGE LOC.  MANTSHANGASE A/A  BIZANA",
    Latitude: "-30.788",
    Longitude: "29.5801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4151",
    WardLookupId: "3218",
    VDNumber: "11750149",
    RegisteredPopulation: "516",
    VotingStationName: "NOMATHEBE JUNIOR SECONDARY SCHOOL",
    Address: "MANTSHANGASE A/A  BIZANA",
    Latitude: "-30.8239",
    Longitude: "29.5964",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4152",
    WardLookupId: "3218",
    VDNumber: "11750903",
    RegisteredPopulation: "253",
    VotingStationName: "NTSHANGASE JUNIOR SECONDARY SCHOOL",
    Address: "NTSHANGASE LOCATION  AMANTSHANGASE A/A  BIZANA",
    Latitude: "-30.8218",
    Longitude: "29.5687",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4153",
    WardLookupId: "3218",
    VDNumber: "11751229",
    RegisteredPopulation: "229",
    VotingStationName: "IZIBANZINI JUNIOR SECONDARY SCHOOL",
    Address: "IZIBANZINI LOCATION  AMANTSHANGASE A/A  BIZANA",
    Latitude: "-30.8046",
    Longitude: "29.6257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4154",
    WardLookupId: "3218",
    VDNumber: "11751522",
    RegisteredPopulation: "365",
    VotingStationName: "NTSHANGASE SENIOR PRIMARY SCHOOL",
    Address: "QABANGENI LOCALITY  NTSHANGASE A/A  BIZANA",
    Latitude: "-30.8057",
    Longitude: "29.5822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4155",
    WardLookupId: "3218",
    VDNumber: "11751533",
    RegisteredPopulation: "237",
    VotingStationName: "LONGWENI SENIOR PRIMARY SCHOOL",
    Address: "LONGWENI  MPETSHWA A/A  BIZANA",
    Latitude: "-30.8183",
    Longitude: "29.652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4156",
    WardLookupId: "3218",
    VDNumber: "11751544",
    RegisteredPopulation: "297",
    VotingStationName: "NTSHANGASE KRESTU CHURCH",
    Address: "SITUKUTHEZI LOCALITY  NTSHANGASE A/A  BIZANA",
    Latitude: "-30.8361",
    Longitude: "29.5939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4157",
    WardLookupId: "3218",
    VDNumber: "11751971",
    RegisteredPopulation: "366",
    VotingStationName: "LUKHANYO SENIOR PRIMARY SCHOOL",
    Address: "DUMSI LOCATION  AMANTSHANGASE A/A  BIZANA",
    Latitude: "-30.7714",
    Longitude: "29.56",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4158",
    WardLookupId: "3218",
    VDNumber: "11810629",
    RegisteredPopulation: "391",
    VotingStationName: "MBIBA JUNIOR SECONDARY SCHOOL",
    Address: "MBIBA LOCALITY  MBIBA  A/A  BIZANA",
    Latitude: "-30.8695",
    Longitude: "29.5894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4159",
    WardLookupId: "3219",
    VDNumber: "11750161",
    RegisteredPopulation: "1110",
    VotingStationName: "MDENI JUNIOR SECONDARY SCHOOL",
    Address: "LUDEKE HALT  ISIKELO A/A  BIZANA",
    Latitude: "-30.8482",
    Longitude: "29.7391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4160",
    WardLookupId: "3219",
    VDNumber: "11750914",
    RegisteredPopulation: "748",
    VotingStationName: "LUDEKE JUNIOR SECONDARY SCHOOL",
    Address: "LUDEKE LOCATION  ISIKELO A/A  BIZANA",
    Latitude: "-30.8255",
    Longitude: "29.7186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4161",
    WardLookupId: "3219",
    VDNumber: "11751083",
    RegisteredPopulation: "469",
    VotingStationName: "MDIKISO JUNIOR SECONDARY SCHOOL",
    Address: "MDIKISO LOCATION  NKANTOLO A/A  BIZANA",
    Latitude: "-30.792",
    Longitude: "29.7051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4162",
    WardLookupId: "3219",
    VDNumber: "11751319",
    RegisteredPopulation: "494",
    VotingStationName: "MARELANE SENIOR SECONDARY SCHOOL",
    Address: "LUDEKE LOCATION  ISIKELO A/A  BIZANA",
    Latitude: "-30.809",
    Longitude: "29.7188",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4163",
    WardLookupId: "3219",
    VDNumber: "11751803",
    RegisteredPopulation: "406",
    VotingStationName: "MABUTHO JUNIOR SECONDARY SCHOOL",
    Address: "RURAL  ISIKELO A/A  BIZANA",
    Latitude: "-30.8174",
    Longitude: "29.6818",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4164",
    WardLookupId: "3219",
    VDNumber: "11751870",
    RegisteredPopulation: "433",
    VotingStationName: "EMAZWENI SENIOR PRIMARY SCHOOL",
    Address: "RURAL  ISIKELO A/A  BIZANA",
    Latitude: "-30.8385",
    Longitude: "29.7084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4165",
    WardLookupId: "3219",
    VDNumber: "11752196",
    RegisteredPopulation: "290",
    VotingStationName: "ARISE AND SHINE CHURCH MINISTRIES HALL",
    Address: "LUDEKE PRESIDENT LOCATION  ISIKELO A/A  BIZANA",
    Latitude: "-30.8421",
    Longitude: "29.7296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4166",
    WardLookupId: "3220",
    VDNumber: "11750329",
    RegisteredPopulation: "259",
    VotingStationName: "GWALA JUNIOR SECONDARY SCHOOL",
    Address: "GWALA  LOC.  ISIKELO A/A  BIZANA",
    Latitude: "-30.7756",
    Longitude: "29.8495",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4167",
    WardLookupId: "3220",
    VDNumber: "11750699",
    RegisteredPopulation: "284",
    VotingStationName: "DLUNGWANA JUNIOR SECONDARY SCHOOL",
    Address: "DLUNGWANMA  LOC  ISIKELO A/A  BIZANA",
    Latitude: "-30.7926",
    Longitude: "29.8188",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4168",
    WardLookupId: "3220",
    VDNumber: "11750767",
    RegisteredPopulation: "496",
    VotingStationName: "EMTAMVUNA JUNIOR SECONDARY SCHOOL",
    Address: "MTAMVUNA   LOC  ISIKELO A/A  BIZANA",
    Latitude: "-30.7473",
    Longitude: "29.8237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4169",
    WardLookupId: "3220",
    VDNumber: "11750857",
    RegisteredPopulation: "696",
    VotingStationName: "MMANGWENI JUNIOR SECONDARY SCHOOL",
    Address: "MMANGWENI LOCALITY  ISIKELO A/A  BIZANA",
    Latitude: "-30.7372",
    Longitude: "29.7764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4170",
    WardLookupId: "3220",
    VDNumber: "11751814",
    RegisteredPopulation: "717",
    VotingStationName: "EMBOBENI JUNIOR SECONDARY  SCHOOL",
    Address: "RURAL  ISIKELO A/A  BIZANA",
    Latitude: "-30.7688",
    Longitude: "29.7689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4171",
    WardLookupId: "3220",
    VDNumber: "11751825",
    RegisteredPopulation: "722",
    VotingStationName: "BHEKAMEVA JUNIOR SECONDARY SCHOOL",
    Address: "RURAL  ISIKELO A/A  BIZANA",
    Latitude: "-30.7747",
    Longitude: "29.7883",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4172",
    WardLookupId: "3220",
    VDNumber: "11751881",
    RegisteredPopulation: "101",
    VotingStationName: "LOWER GWALA MOBILE CLINIC",
    Address: "LOWER GWALA  ISIKILO  BIZANA",
    Latitude: "-30.7651",
    Longitude: "29.8643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4173",
    WardLookupId: "3220",
    VDNumber: "11752039",
    RegisteredPopulation: "373",
    VotingStationName: "MMANGWENI METHODIST CHURCH",
    Address: "K. SECTION - MMANGWENI LOCATION  ISIKELO A/A  MBIZANA [BIZANA]",
    Latitude: "-30.7219",
    Longitude: "29.7882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4174",
    WardLookupId: "3221",
    VDNumber: "11750598",
    RegisteredPopulation: "1026",
    VotingStationName: "MJANYELLWA JUNIOR SECONDARY SCHOOL",
    Address: "MJANYELWA  LOC  IMIZIZI  A/A  BIZANA",
    Latitude: "-30.8704",
    Longitude: "29.9142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4175",
    WardLookupId: "3221",
    VDNumber: "11750611",
    RegisteredPopulation: "762",
    VotingStationName: "SONTSELE JUNIOR SECONDARY SCHOOL",
    Address: "SONTSELE  LOC  NTSHAMATHE A/A  BIZANA",
    Latitude: "-30.8352",
    Longitude: "29.8868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4176",
    WardLookupId: "3221",
    VDNumber: "11750655",
    RegisteredPopulation: "1225",
    VotingStationName: "MHLANGA JUNIOR SECONDARY SCHOOL",
    Address: "MHLANGA  LOC  NTSHAMATHE A/A  BIZANA",
    Latitude: "-30.8538",
    Longitude: "29.8869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4177",
    WardLookupId: "3221",
    VDNumber: "11750701",
    RegisteredPopulation: "169",
    VotingStationName: "GUQA SENIOR PRIMARY SCHOOL",
    Address: "GUQA  LOC  NTSHAMATHE A/A  BIZANA",
    Latitude: "-30.799",
    Longitude: "29.8676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4178",
    WardLookupId: "3221",
    VDNumber: "11750712",
    RegisteredPopulation: "439",
    VotingStationName: "TSAWANA JUNIOR SECONDARY SCHOOL",
    Address: "TSAWANA  LOC  NTSHAMATHE A/A  BIZANA",
    Latitude: "-30.8066",
    Longitude: "29.9151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4179",
    WardLookupId: "3221",
    VDNumber: "11751050",
    RegisteredPopulation: "455",
    VotingStationName: "MNDELA JUNIOR SECONDARY SCHOOL",
    Address: "MNDELA LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.8516",
    Longitude: "29.9056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4180",
    WardLookupId: "3221",
    VDNumber: "11751263",
    RegisteredPopulation: "545",
    VotingStationName: "JALI SENIOR PRIMARY SCHOOL",
    Address: "JALI LOCATION  IMIZIZI  A/A  BIZANA",
    Latitude: "-30.8221",
    Longitude: "29.9383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4181",
    WardLookupId: "3221",
    VDNumber: "11752073",
    RegisteredPopulation: "453",
    VotingStationName: "JALI ANGLICAN CHURCH",
    Address: "JALI LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.8062",
    Longitude: "29.896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4182",
    WardLookupId: "3222",
    VDNumber: "11750262",
    RegisteredPopulation: "428",
    VotingStationName: "MAJAZI JUNIOR SECONDARY SCHOOL",
    Address: "MAJAZI  LOC.  ISIKELO  A/A  BIZANA",
    Latitude: "-30.8709",
    Longitude: "29.8054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4183",
    WardLookupId: "3222",
    VDNumber: "11750622",
    RegisteredPopulation: "654",
    VotingStationName: "NTUKAI SENIOR SECONDARY SCHOOL",
    Address: "NTUKAI  LOC  NTSHAMATHE A/A  BIZANA",
    Latitude: "-30.8247",
    Longitude: "29.8641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4184",
    WardLookupId: "3222",
    VDNumber: "11750688",
    RegisteredPopulation: "253",
    VotingStationName: "ZININI JUNIOR SECONDARY SCHOOL",
    Address: "IZININI LOC  NTSHAMATHE A/A  BIZANA",
    Latitude: "-30.8304",
    Longitude: "29.8248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4185",
    WardLookupId: "3222",
    VDNumber: "11751094",
    RegisteredPopulation: "450",
    VotingStationName: "KHALENI SENIOR PRIMARY SCHOOL",
    Address: "KHALENI LOCATION  ISIKELO A/A  BIZANA",
    Latitude: "-30.8226",
    Longitude: "29.8074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4186",
    WardLookupId: "3222",
    VDNumber: "11751768",
    RegisteredPopulation: "510",
    VotingStationName: "VUKUZENZELE SPECIAL SCHOOL",
    Address: "NOMLACU  ISIKELO A/A  BIZANA",
    Latitude: "-30.8483",
    Longitude: "29.7952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4187",
    WardLookupId: "3222",
    VDNumber: "11751869",
    RegisteredPopulation: "628",
    VotingStationName: "MZAMBA COMPREHENSIVE HIGH SCHOOL",
    Address: "RURAL  ISIKELO A/A  BIZANA",
    Latitude: "-30.8461",
    Longitude: "29.7754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4188",
    WardLookupId: "3222",
    VDNumber: "11751892",
    RegisteredPopulation: "436",
    VotingStationName: "GECELO SENIOR PRIMARY SCHOOL",
    Address: "NDUNGE  NTSHAMATHE A/A  BIZANA",
    Latitude: "-30.8221",
    Longitude: "29.8467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4189",
    WardLookupId: "3222",
    VDNumber: "11751904",
    RegisteredPopulation: "166",
    VotingStationName: "NDUNGE JUNIOR SECONDARY SCHOOL",
    Address: "NDUNGE LOCATION  NTSHAMATHE A/A  BIZANA",
    Latitude: "-30.8327",
    Longitude: "29.8686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4190",
    WardLookupId: "3222",
    VDNumber: "11752219",
    RegisteredPopulation: "261",
    VotingStationName: "JERUSALEM AFRICA CHURCH",
    Address: "JERUSALEM LOCATION  AMANGUTYANA A/A  BIZANA",
    Latitude: "-30.861",
    Longitude: "29.8019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4191",
    WardLookupId: "3223",
    VDNumber: "11810102",
    RegisteredPopulation: "444",
    VotingStationName: "GALATYENI JUNIOR SECONDARY SCHOOL",
    Address: "GALATYENI LOCATION  GALATYENI  A/A  BIZANA",
    Latitude: "-30.889",
    Longitude: "29.5829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4192",
    WardLookupId: "3223",
    VDNumber: "11810113",
    RegisteredPopulation: "830",
    VotingStationName: "NCURHA JUNIOR SECONDARY SCHOOL",
    Address: "NDAKENI  LOCATION  DAKENI  A/A  BIZANA",
    Latitude: "-30.9351",
    Longitude: "29.5521",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4193",
    WardLookupId: "3223",
    VDNumber: "11810135",
    RegisteredPopulation: "440",
    VotingStationName: "MAPHAKATHI SENIOR PRIMARY SCHOOL",
    Address: "NTLENZI LOCALITY  NTLENZI  A/A  BIZANA",
    Latitude: "-30.9258",
    Longitude: "29.6099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4194",
    WardLookupId: "3223",
    VDNumber: "11810618",
    RegisteredPopulation: "438",
    VotingStationName: "DUDUMENI COMPREHENSIVE HIGH SCHOOL",
    Address: "DUDUMENI A/A  NTLENZI  A/A  BIZANA",
    Latitude: "-30.9105",
    Longitude: "29.5943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4195",
    WardLookupId: "3223",
    VDNumber: "11810832",
    RegisteredPopulation: "399",
    VotingStationName: "SOMTSEWU SENIOR PRIMARY SCHOOL",
    Address: "NDAKENI  NTLENZI A/A  BIZANA",
    Latitude: "-30.9282",
    Longitude: "29.5305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4196",
    WardLookupId: "3223",
    VDNumber: "11810843",
    RegisteredPopulation: "459",
    VotingStationName: "CAMAGU JUNIOR SECONDARY SCHOOL",
    Address: "NDAKENI  NTLENZI A/A  BIZANA",
    Latitude: "-30.9343",
    Longitude: "29.5635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4197",
    WardLookupId: "3223",
    VDNumber: "11810865",
    RegisteredPopulation: "331",
    VotingStationName: "DUDUMENI NEW LITE CHURCH",
    Address: "DUDUMENI LOCALITY  BUKUVENI A/A  BIZANA",
    Latitude: "-30.9143",
    Longitude: "29.5779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4198",
    WardLookupId: "3223",
    VDNumber: "11810876",
    RegisteredPopulation: "351",
    VotingStationName: "KU-BHA SENIOR PRIMARY SCHOOL",
    Address: "KUBHA LOCALITY  NTLENZI A/A  BIZANA",
    Latitude: "-30.878",
    Longitude: "29.6038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4199",
    WardLookupId: "3223",
    VDNumber: "11811103",
    RegisteredPopulation: "184",
    VotingStationName: "BOKUVENI JUNIOR SECONDARY SCHOOL",
    Address: "BUKUVENI LOCATION  BOKUVENI A/A  BIZANA, 4800",
    Latitude: "-30.9053",
    Longitude: "29.5569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4200",
    WardLookupId: "3223",
    VDNumber: "11811226",
    RegisteredPopulation: "481",
    VotingStationName: "DUMENI PRE-SCHOOL",
    Address: "00 N/A  DUDUMENI LOCATION  FLAGSTAFF",
    Latitude: "-30.9249",
    Longitude: "29.5951",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4201",
    WardLookupId: "3223",
    VDNumber: "11811248",
    RegisteredPopulation: "285",
    VotingStationName: "NDAKENI PRE-SCHOOL",
    Address: "NDAKENI NEW SITES LOCATION  NTLENZI A/A   FLAGSTAFF",
    Latitude: "-30.9257",
    Longitude: "29.5782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4202",
    WardLookupId: "3224",
    VDNumber: "11750307",
    RegisteredPopulation: "691",
    VotingStationName: "MPETSHWA JUNIOR SECONDARY SCHOOL",
    Address: "MPETSHWA LOCALITY  AMANTSHAGASE A/A  BIZANA",
    Latitude: "-30.8648",
    Longitude: "29.6499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4203",
    WardLookupId: "3224",
    VDNumber: "11751555",
    RegisteredPopulation: "515",
    VotingStationName: "BONDA JUNIOR SECONDARY SCHOOL",
    Address: "BONDA LOCALITY  MPETSHWA A/A  BIZANA",
    Latitude: "-30.8401",
    Longitude: "29.6159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4204",
    WardLookupId: "3224",
    VDNumber: "11752309",
    RegisteredPopulation: "146",
    VotingStationName: "MDOZINGANA VILLAGE (TENT)",
    Address: "MDOZINGANA LOCATION   AMANTSHANGASE A/A   BIZANA",
    Latitude: "-30.8461",
    Longitude: "29.6682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4205",
    WardLookupId: "3224",
    VDNumber: "11810089",
    RegisteredPopulation: "923",
    VotingStationName: "SWANE JUNIOR SECONDARY SCHOOL",
    Address: "GWABENI LOC  NTLENZI A/A  BIZANA",
    Latitude: "-30.9148",
    Longitude: "29.6317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4206",
    WardLookupId: "3224",
    VDNumber: "11810090",
    RegisteredPopulation: "222",
    VotingStationName: "NDAYINI SENIOR PRIMARY SCHOOL",
    Address: "NDAYINI LOCALITY  NDAYINI  A/A  BIZANA",
    Latitude: "-30.8917",
    Longitude: "29.6188",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4207",
    WardLookupId: "3224",
    VDNumber: "11810124",
    RegisteredPopulation: "399",
    VotingStationName: "ENQABENI SENIOR SECONDARY SCHOOL",
    Address: "NQABENI LOCATION  NQABENI  A/A  BIZANA",
    Latitude: "-30.8896",
    Longitude: "29.6787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4208",
    WardLookupId: "3224",
    VDNumber: "11810573",
    RegisteredPopulation: "410",
    VotingStationName: "LUPILISWENI JUNIOR SECONDARY SCHOOL",
    Address: "BUKUVENI LOCALITY  BUKUVENI  A/A  BIZANA",
    Latitude: "-30.8696",
    Longitude: "29.6198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4209",
    WardLookupId: "3224",
    VDNumber: "11810887",
    RegisteredPopulation: "404",
    VotingStationName: "NQABENI GOSPEL AFRICAN CHURCH",
    Address: "NQABENI  NQABENI A/A  BIZANA",
    Latitude: "-30.8694",
    Longitude: "29.6523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4210",
    WardLookupId: "3224",
    VDNumber: "11810898",
    RegisteredPopulation: "301",
    VotingStationName: "MATSHEZI SENIOR PRIMARY SCHOOL",
    Address: "TSHUZI LOC.  NQABENI  BIZANA",
    Latitude: "-30.8704",
    Longitude: "29.6301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4211",
    WardLookupId: "3224",
    VDNumber: "11810900",
    RegisteredPopulation: "299",
    VotingStationName: "ZANOKHANYO SENIOR PRIMARY SCHOOL",
    Address: "NQABENI  NQABENI A/A  BIZANA",
    Latitude: "-30.8871",
    Longitude: "29.703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4212",
    WardLookupId: "3224",
    VDNumber: "11810911",
    RegisteredPopulation: "490",
    VotingStationName: "ENVIS METHODIST CHURCH",
    Address: "ENVIS  NQABENI A/A  BIZANA",
    Latitude: "-30.8723",
    Longitude: "29.6741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4213",
    WardLookupId: "3225",
    VDNumber: "11810157",
    RegisteredPopulation: "294",
    VotingStationName: "BATVILLE JUNIOR PRIMARY SCHOOL",
    Address: "BATVILLE LOCATION  NTLENZI A/A  BIZANA",
    Latitude: "-30.9491",
    Longitude: "29.6098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4214",
    WardLookupId: "3225",
    VDNumber: "11810236",
    RegisteredPopulation: "758",
    VotingStationName: "MKHOMANI JUNIOR SECONDARY SCHOOL",
    Address: "MGODINI LOCATION  THEKWINI A/A  BIZANA",
    Latitude: "-31.0189",
    Longitude: "29.5982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4215",
    WardLookupId: "3225",
    VDNumber: "11810258",
    RegisteredPopulation: "662",
    VotingStationName: "EMCETHENI JUNIOR SECONDARY SCHOOL",
    Address: "MCETHENI LOC  NTLENZI A/A  BIZANA",
    Latitude: "-30.9827",
    Longitude: "29.6216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4216",
    WardLookupId: "3225",
    VDNumber: "11810405",
    RegisteredPopulation: "570",
    VotingStationName: "NTLALONTSHA JUNIOR SECONDARY SCHOOL",
    Address: "NTLENZI LOCATION  NTLENZI  A/A  BIZANA",
    Latitude: "-30.9577",
    Longitude: "29.6457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4217",
    WardLookupId: "3225",
    VDNumber: "11810584",
    RegisteredPopulation: "373",
    VotingStationName: "ROCKVILLE JUNIOR SECONDARY SCHOOL",
    Address: "ROCKVILLE LOC  NTLENZI A/A  BIZANA",
    Latitude: "-30.9558",
    Longitude: "29.5802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4218",
    WardLookupId: "3225",
    VDNumber: "11810854",
    RegisteredPopulation: "672",
    VotingStationName: "EMGODINI JUNIOR SECONDARY SCHOOL",
    Address: "MGODINI LOCALITY  BALA A/A  BIZANA",
    Latitude: "-31.0141",
    Longitude: "29.6093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4219",
    WardLookupId: "3225",
    VDNumber: "11811114",
    RegisteredPopulation: "329",
    VotingStationName: "LOWER MKHOMANE PRIMARY SCHOOL",
    Address: "EMCIJWENI  BIZANA",
    Latitude: "-31.0048",
    Longitude: "29.622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4220",
    WardLookupId: "3225",
    VDNumber: "11811204",
    RegisteredPopulation: "225",
    VotingStationName: "NTLENZI STAR PRE-SCHOOL",
    Address: "ROCKVILLE LOCATION  NTLENZI A/A  BIZANA",
    Latitude: "-30.9562",
    Longitude: "29.5867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4221",
    WardLookupId: "3225",
    VDNumber: "11811215",
    RegisteredPopulation: "294",
    VotingStationName: "UPPER MGODINI METHODIST CHURCH",
    Address: "UPPER MGODINI LOCATION  THEKWINI A/A  BIZANA",
    Latitude: "-31.0009",
    Longitude: "29.5916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4222",
    WardLookupId: "3225",
    VDNumber: "11811260",
    RegisteredPopulation: "349",
    VotingStationName: "PHUMELELE MHLANTI COMMUNITY HALL",
    Address: "NEW TOWN  LOCATION  NTLENZI  BIZANA",
    Latitude: "-30.9513",
    Longitude: "29.562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4223",
    WardLookupId: "3226",
    VDNumber: "11810168",
    RegisteredPopulation: "427",
    VotingStationName: "LUCWABA JUNIOR SECONDARY SCHOOL",
    Address: "LUCWABA LOC  NTLENZI A/A  BIZANA",
    Latitude: "-30.9807",
    Longitude: "29.6747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4224",
    WardLookupId: "3226",
    VDNumber: "11810225",
    RegisteredPopulation: "611",
    VotingStationName: "VUKAYIBAMBE SENIOR SECONDARY SCHOOL",
    Address: "MDIKISWENI LOCATION  MDIKISWENI A/A  FLAGSTAFF",
    Latitude: "-31.0103",
    Longitude: "29.6956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4225",
    WardLookupId: "3226",
    VDNumber: "11810270",
    RegisteredPopulation: "728",
    VotingStationName: "ESIGODLWENI JUNIOR SECONDARY SCHOOL",
    Address: "SIGODLWENI LOCALITY  SIGODLWENI  A/A  BIZANA",
    Latitude: "-31.0453",
    Longitude: "29.6955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4226",
    WardLookupId: "3226",
    VDNumber: "11810281",
    RegisteredPopulation: "632",
    VotingStationName: "PELE-PELE SENIOR PRIMARY SCHOOL",
    Address: "QASA LOCATION  QASA  A/A  BIZANA",
    Latitude: "-30.9921",
    Longitude: "29.7179",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4227",
    WardLookupId: "3226",
    VDNumber: "11810427",
    RegisteredPopulation: "570",
    VotingStationName: "MANDLOBE JUNIOR SECONDARY SCHOOL",
    Address: "MANDLOBE LOC  QHASA A/A  FLAGSTAFF, 4800",
    Latitude: "-31.037",
    Longitude: "29.6698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4228",
    WardLookupId: "3226",
    VDNumber: "11810719",
    RegisteredPopulation: "274",
    VotingStationName: "QASA METHODIST CHURCH HALL",
    Address: "QASA  LOCALITY  QASA A/A  BIZANA",
    Latitude: "-31.0463",
    Longitude: "29.7078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4229",
    WardLookupId: "3226",
    VDNumber: "11810764",
    RegisteredPopulation: "254",
    VotingStationName: "CHRISTIAN CATHOLIC CHURCH",
    Address: "QASA A/A  MDIKISWENI LOCATION  FLAGSTAFF",
    Latitude: "-31.0075",
    Longitude: "29.6909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4230",
    WardLookupId: "3226",
    VDNumber: "11811091",
    RegisteredPopulation: "300",
    VotingStationName: "GINISWAYO VILLAGE -TENT",
    Address: "GINISWAYO LOCATION  QASA A/A  FLAGSTAFF",
    Latitude: "-31.002",
    Longitude: "29.6675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4231",
    WardLookupId: "3226",
    VDNumber: "11811125",
    RegisteredPopulation: "313",
    VotingStationName: "SWANE RIDGE SENIOR PRIMARY SCHOOL",
    Address: "SWANE RIDGE  NTLENZI A/A  MBIZANA [BIZANA]",
    Latitude: "-30.9441",
    Longitude: "29.6462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4232",
    WardLookupId: "3227",
    VDNumber: "11750015",
    RegisteredPopulation: "613",
    VotingStationName: "NDLOVU SENIOR SECONDARY SCHOOL",
    Address: "MHLABI LOC  AMANGUTYANA A/A  BIZANA",
    Latitude: "-30.948",
    Longitude: "29.7582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4233",
    WardLookupId: "3227",
    VDNumber: "11750105",
    RegisteredPopulation: "343",
    VotingStationName: "NDINOMNTU SENIOR PRIMARY SCHOOL",
    Address: "MHLABI LOCATION  AMANGUTYANA A/A  BIZANA",
    Latitude: "-30.9708",
    Longitude: "29.7488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4234",
    WardLookupId: "3227",
    VDNumber: "11750420",
    RegisteredPopulation: "474",
    VotingStationName: "MZAMBANA JUNIOR SECONDARY SCHOOL",
    Address: "MZAMBANA  LOCATION  ISIKELO A/A  BIZANA",
    Latitude: "-30.9226",
    Longitude: "29.7746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4235",
    WardLookupId: "3227",
    VDNumber: "11751195",
    RegisteredPopulation: "823",
    VotingStationName: "NKUNDLA JUNIOR SECONDARY SCHOOL",
    Address: "NKUNDLA  LOCATION  AMANDELA A/A  BIZANA",
    Latitude: "-30.9424",
    Longitude: "29.7178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4236",
    WardLookupId: "3227",
    VDNumber: "11751207",
    RegisteredPopulation: "748",
    VotingStationName: "DIAMOND JUNIOR SECONDARY SCHOOL",
    Address: "DIAMOND  LOCATION  AMANGUTYANA A/A  BIZANA",
    Latitude: "-30.9156",
    Longitude: "29.7523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4237",
    WardLookupId: "3227",
    VDNumber: "11810393",
    RegisteredPopulation: "594",
    VotingStationName: "MFUNDAMBINI JUNIOR SECONDARY SCHOOL",
    Address: "NQABENI A/A  NQABENI  A/A  FLAGSSTAFF",
    Latitude: "-30.922",
    Longitude: "29.675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4238",
    WardLookupId: "3227",
    VDNumber: "11810821",
    RegisteredPopulation: "502",
    VotingStationName: "DUMILE SENIOR PRIMARY SCHOOL",
    Address: "RURAL  NDELA A/A  BIZANA",
    Latitude: "-30.9508",
    Longitude: "29.6995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4239",
    WardLookupId: "3227",
    VDNumber: "11811259",
    RegisteredPopulation: "350",
    VotingStationName: "NTLANEZWE SENIOR PRIMARY SCHOOL",
    Address: "NTLANEZWE  NQABENI   NQABENI",
    Latitude: "-30.9175",
    Longitude: "29.6995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4240",
    WardLookupId: "3228",
    VDNumber: "11750127",
    RegisteredPopulation: "500",
    VotingStationName: "MATWEBU JUNIOR SECONDARY SCHOOL",
    Address: "MATWEBU  LOCATION  AMANGUTYANA A/A  BIZANA",
    Latitude: "-30.9017",
    Longitude: "29.8405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4241",
    WardLookupId: "3228",
    VDNumber: "11750150",
    RegisteredPopulation: "665",
    VotingStationName: "NONKQUBELA JUNIOR PRIMARY SCHOOL",
    Address: "NONKQUBELA  LOC  AMANGUTYANA A/A  BIZANA",
    Latitude: "-30.878",
    Longitude: "29.8405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4242",
    WardLookupId: "3228",
    VDNumber: "11750284",
    RegisteredPopulation: "1086",
    VotingStationName: "NGCINGO JUNIOR SECONDARY SCHOOL",
    Address: "GCINGO  LOC.  AMANGUTYANA A/A  BIZANA",
    Latitude: "-30.88",
    Longitude: "29.8606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4243",
    WardLookupId: "3228",
    VDNumber: "11750464",
    RegisteredPopulation: "861",
    VotingStationName: "DIDI HIGHER PRIMARY SCHOOL",
    Address: "DIDI LOCATION  AMANGUTYANA A/A  BIZANA",
    Latitude: "-30.8685",
    Longitude: "29.8505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4244",
    WardLookupId: "3228",
    VDNumber: "11751218",
    RegisteredPopulation: "447",
    VotingStationName: "KHOTSHO JUNIOR SECONDARY SCHOOL",
    Address: "KHOTSHO LOCATION  AMANGUTYANA A/A  BIZANA",
    Latitude: "-30.9193",
    Longitude: "29.8629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4245",
    WardLookupId: "3228",
    VDNumber: "11751746",
    RegisteredPopulation: "637",
    VotingStationName: "ZAMILIZWE SENIOR PRIMARY SCHOOL",
    Address: "NIKWE  IMIZIZI A/A  BIZANA",
    Latitude: "-30.8949",
    Longitude: "29.8713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4246",
    WardLookupId: "3228",
    VDNumber: "11751982",
    RegisteredPopulation: "727",
    VotingStationName: "NIKHWE BAPTIST CHURCH",
    Address: "NIKHWE LOCATION  AMANIKHWE A/A  BIZANA",
    Latitude: "-30.8771",
    Longitude: "29.8674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4247",
    WardLookupId: "3229",
    VDNumber: "11750172",
    RegisteredPopulation: "726",
    VotingStationName: "ELUTHULINI JUNIOR SECONDARY SCHOOL",
    Address: "LUTHULINI LOCATION  AMANGUTYANE A/A  BIZANA",
    Latitude: "-31.0123",
    Longitude: "29.7678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4248",
    WardLookupId: "3229",
    VDNumber: "11750576",
    RegisteredPopulation: "395",
    VotingStationName: "MBHONGWENI JUNIOR SECONDARY SCHOOL",
    Address: "MBONGWENI LOC.  AMANGUTYANA A/A  BIZANA",
    Latitude: "-31.0532",
    Longitude: "29.8646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4249",
    WardLookupId: "3229",
    VDNumber: "11751038",
    RegisteredPopulation: "493",
    VotingStationName: "MEJE JUNIOR SECONDARY SCHOOL",
    Address: "MEJE LOCALITY  AMANGUTYANA A/A  BIZANA",
    Latitude: "-31.0361",
    Longitude: "29.8264",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4250",
    WardLookupId: "3229",
    VDNumber: "11751061",
    RegisteredPopulation: "482",
    VotingStationName: "QADU JUNIOR SECONDARY SCHOOL",
    Address: "QADU LOCATION  BIZANA",
    Latitude: "-31.0138",
    Longitude: "29.8102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4251",
    WardLookupId: "3229",
    VDNumber: "11751184",
    RegisteredPopulation: "264",
    VotingStationName: "NGOJANE JUNIOR SECONDARY SCHOOL",
    Address: "NGOJANE LOCATION  AMANGUTYANA A/A  BIZANA",
    Latitude: "-31.0435",
    Longitude: "29.8027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4252",
    WardLookupId: "3229",
    VDNumber: "11751487",
    RegisteredPopulation: "373",
    VotingStationName: "MBONGWENI FREE METHODIST CHURCH",
    Address: "MBONGWENI LOCALITY  AMANGUTYANA A/A  BIZANA",
    Latitude: "-31.0611",
    Longitude: "29.8573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4253",
    WardLookupId: "3229",
    VDNumber: "11751498",
    RegisteredPopulation: "305",
    VotingStationName: "MBONGWENI TRIBAL AUTHORITY",
    Address: "MBONGWENI LOCALITY  AMANGUTYANA  BIZANA",
    Latitude: "-31.049",
    Longitude: "29.8475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4254",
    WardLookupId: "3229",
    VDNumber: "11751500",
    RegisteredPopulation: "332",
    VotingStationName: "MBUNGWA JUNIOR SECONDARY SCHOOL",
    Address: "MPETSHENI LOCALITY  AMANGUTYANA A/A  BIZANA",
    Latitude: "-30.9895",
    Longitude: "29.7975",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4255",
    WardLookupId: "3229",
    VDNumber: "11751960",
    RegisteredPopulation: "351",
    VotingStationName: "EMKOLWENI TENT.",
    Address: "EMKOLWENI LOCATION  AMANGUTYANA A/A  BIZANA",
    Latitude: "-30.9949",
    Longitude: "29.7777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4256",
    WardLookupId: "3229",
    VDNumber: "11752084",
    RegisteredPopulation: "224",
    VotingStationName: "TEMPORAY VOTING STATION (LUPHONDWENI VILLAGE)",
    Address: "LUPHONDWENI VILLAGE  AMANGUTYANA A/A  BIZANA",
    Latitude: "-31.0273",
    Longitude: "29.7379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4257",
    WardLookupId: "3229",
    VDNumber: "11752095",
    RegisteredPopulation: "152",
    VotingStationName: "MGOMANZI JUNIOR PRIMARY SCHOOL",
    Address: "MGOMANZI LOCATION  AMANGUTYANA A/A  BIZANA",
    Latitude: "-31.0377",
    Longitude: "29.8812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4258",
    WardLookupId: "3229",
    VDNumber: "11752107",
    RegisteredPopulation: "193",
    VotingStationName: "TEMPORARY VOTING STATION (MPETSHENI SPORT GROUND)",
    Address: "MPETSHENI LOCALITY  AMANGUTYANA A/A  BIZANA",
    Latitude: "-31.001",
    Longitude: "29.8294",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4259",
    WardLookupId: "3230",
    VDNumber: "11750194",
    RegisteredPopulation: "531",
    VotingStationName: "LUSINDISWENI JUNIOR SECONDARY SCHOOL",
    Address: "LUSINDISWENI  LOC.  AMANDENGANE A/A  BIZANA",
    Latitude: "-31.0994",
    Longitude: "29.7491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4260",
    WardLookupId: "3230",
    VDNumber: "11750206",
    RegisteredPopulation: "546",
    VotingStationName: "MNCWATHI JUNIOR SECONDARY SCHOOL",
    Address: "MNCWATHI LOCALITY  AMANDENGANE A/A  BIZANA",
    Latitude: "-31.0467",
    Longitude: "29.7624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4261",
    WardLookupId: "3230",
    VDNumber: "11750239",
    RegisteredPopulation: "683",
    VotingStationName: "CRITCHLOW JUNIOR SECONDARY SCHOOL",
    Address: "CRITCHLOW LOCALITY  AMANGUTYANA A/A  BIZANA",
    Latitude: "-31.073",
    Longitude: "29.7936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4262",
    WardLookupId: "3230",
    VDNumber: "11751117",
    RegisteredPopulation: "287",
    VotingStationName: "NONTLANGA JUNIOR SECONDARY SCHOOL",
    Address: "NONTLANGA LOCATION  AMAKHANYAYO A/A  BIZANA",
    Latitude: "-31.1303",
    Longitude: "29.888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4263",
    WardLookupId: "3230",
    VDNumber: "11751308",
    RegisteredPopulation: "196",
    VotingStationName: "EMABHEKUTENI SENIOR PRIMARY SCHOOL",
    Address: "MABHEKUTENI LOC.  AMAKHANYAYO  A/A  BIZANA",
    Latitude: "-31.1105",
    Longitude: "29.8182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4264",
    WardLookupId: "3230",
    VDNumber: "11751410",
    RegisteredPopulation: "229",
    VotingStationName: "OLD POLICE STATION PRE SCHOOL",
    Address: "KWA CHITHWAYO LOCATION  AMANDENGANE A/A   BIZANA",
    Latitude: "-31.0845",
    Longitude: "29.7477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4265",
    WardLookupId: "3230",
    VDNumber: "11751421",
    RegisteredPopulation: "290",
    VotingStationName: "CRITCHLO MISSION HALL",
    Address: "MLOMEKHUBA LOCALITY  AMANGUTYANA A/A  BIZANA",
    Latitude: "-31.078",
    Longitude: "29.8086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4266",
    WardLookupId: "3230",
    VDNumber: "11751432",
    RegisteredPopulation: "439",
    VotingStationName: "KHANYAYO RERORMED CHURCH",
    Address: "NTABEZULU LOCALITY  KHANYAYO A/A  BIZANA",
    Latitude: "-31.0909",
    Longitude: "29.8287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4267",
    WardLookupId: "3230",
    VDNumber: "11751443",
    RegisteredPopulation: "391",
    VotingStationName: "KHANYAYO JUNIOR SECONDARY SCHOOL",
    Address: "AMANDUNU LOCALITY  AMAKHANYAYO A/A  BIZANA",
    Latitude: "-31.1045",
    Longitude: "29.8403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4268",
    WardLookupId: "3230",
    VDNumber: "11751511",
    RegisteredPopulation: "264",
    VotingStationName: "MAKHOSONKE JUNIOR PRIMARY SCHOOL",
    Address: "TAFA LOCALITY  NDENGANE A/A  BIZANA",
    Latitude: "-31.0622",
    Longitude: "29.7686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4269",
    WardLookupId: "3230",
    VDNumber: "11751915",
    RegisteredPopulation: "293",
    VotingStationName: "MSEBENZI  PRE SCHOOL HALL",
    Address: "KWA LABANE LOCATION   AMAKHANYAYO A/A   BIZANA",
    Latitude: "-31.0907",
    Longitude: "29.7954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4270",
    WardLookupId: "3231",
    VDNumber: "11750240",
    RegisteredPopulation: "266",
    VotingStationName: "DLANGEZWA PRIMARY SCHOOL",
    Address: "DLANGEZWA  LOC.  AMADIBA A/A  BIZANA",
    Latitude: "-31.0776",
    Longitude: "29.9223",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4271",
    WardLookupId: "3231",
    VDNumber: "11750251",
    RegisteredPopulation: "839",
    VotingStationName: "MBEKWA JUNIOR SECONDARY SCHOOL",
    Address: "MBEKWA LOCATION  AMAMPISI A/A  BIZANA",
    Latitude: "-31.0672",
    Longitude: "29.8904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4272",
    WardLookupId: "3231",
    VDNumber: "11750385",
    RegisteredPopulation: "517",
    VotingStationName: "DANGENI JUNIOR SECONDARY SCHOOL",
    Address: "MAKHWANTINI LOC  AMADIBA A/A  BIZANA",
    Latitude: "-31.0764",
    Longitude: "29.951",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4273",
    WardLookupId: "3231",
    VDNumber: "11750431",
    RegisteredPopulation: "824",
    VotingStationName: "MTAYISE JUNIOR SECONDARY SCHOOL",
    Address: "MTAYISE  LOCATION  AMADIBA A/A  BIZANA",
    Latitude: "-31.0578",
    Longitude: "29.913",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4274",
    WardLookupId: "3231",
    VDNumber: "11750936",
    RegisteredPopulation: "574",
    VotingStationName: "MLINDAZWE JUNIOR SECONDARY SCHOOL",
    Address: "MPISI LOCATION  AMAMPISI A/A  BIZANA",
    Latitude: "-31.0967",
    Longitude: "29.9014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4275",
    WardLookupId: "3231",
    VDNumber: "11751353",
    RegisteredPopulation: "501",
    VotingStationName: "NDLOVIMILE JUNIOR SECONDARY SCHOOL",
    Address: "RURAL  AMADIBA A/A  BIZANA",
    Latitude: "-31.1157",
    Longitude: "29.9383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4276",
    WardLookupId: "3231",
    VDNumber: "11751454",
    RegisteredPopulation: "515",
    VotingStationName: "AMAMPISI CRECHE HALL",
    Address: "AMAMPISI  AMAMPISI A/A  BIZANA",
    Latitude: "-31.0878",
    Longitude: "29.8854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4277",
    WardLookupId: "3231",
    VDNumber: "11751465",
    RegisteredPopulation: "436",
    VotingStationName: "MTAYISE A.F.M CHURCH",
    Address: "MTAYISE LOCALITY  AMADIBA A/A  BIZANA",
    Latitude: "-31.0547",
    Longitude: "29.8809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4278",
    WardLookupId: "3232",
    VDNumber: "11750217",
    RegisteredPopulation: "796",
    VotingStationName: "QANDASHE JUNIOR SECONDARY SCHOOL",
    Address: "QANDASHE   LOC.  IMIZIZI A/A  BIZANA",
    Latitude: "-30.917",
    Longitude: "29.9168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4279",
    WardLookupId: "3232",
    VDNumber: "11750565",
    RegisteredPopulation: "967",
    VotingStationName: "NIKWE JUNIOR SECONDARY SCHOOL",
    Address: "NIKWE LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.8841",
    Longitude: "29.8784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4280",
    WardLookupId: "3232",
    VDNumber: "11750587",
    RegisteredPopulation: "780",
    VotingStationName: "MBENYA JUNIOR SECONDARY SCHOOL",
    Address: "MBENYA  LOCATION  IMIZIZI  A/A  BIZANA",
    Latitude: "-30.8789",
    Longitude: "29.896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4281",
    WardLookupId: "3232",
    VDNumber: "11750745",
    RegisteredPopulation: "1107",
    VotingStationName: "NTSINGIZI JUNIOR SECONDARY SCHOOL",
    Address: "NTSINGIZI  LOC  IMIZIZI  A/A  BIZANA",
    Latitude: "-30.9018",
    Longitude: "29.8992",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4282",
    WardLookupId: "3232",
    VDNumber: "11750925",
    RegisteredPopulation: "727",
    VotingStationName: "NTLAKWE JUNIOR SECONDARY SCHOOL",
    Address: "NTLAKWE LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9031",
    Longitude: "29.9247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4283",
    WardLookupId: "3232",
    VDNumber: "11751713",
    RegisteredPopulation: "174",
    VotingStationName: "QANDASHE KUSHI A.U. CHURCH",
    Address: "QANDASHE LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9276",
    Longitude: "29.9368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4284",
    WardLookupId: "3232",
    VDNumber: "11751926",
    RegisteredPopulation: "526",
    VotingStationName: "NONKQUBELA PRE-SCHOOL",
    Address: "NIKWE LOCATION  IMIZIZI A/A  BIZANA, 4800",
    Latitude: "-30.8923",
    Longitude: "29.8864",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4285",
    WardLookupId: "3232",
    VDNumber: "11752310",
    RegisteredPopulation: "214",
    VotingStationName: "IMIZAMO YETHU PRE SCHOOL",
    Address: "NTLAKWE LOCATION   IMIZIZI A/A   BIZANA",
    Latitude: "-30.902",
    Longitude: "29.9508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4286",
    WardLookupId: "3233",
    VDNumber: "11750026",
    RegisteredPopulation: "317",
    VotingStationName: "MBANDANE JUNIOR SECONDARY SCHOOL",
    Address: "MBANDANE LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.8764",
    Longitude: "30.0228",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4287",
    WardLookupId: "3233",
    VDNumber: "11750442",
    RegisteredPopulation: "727",
    VotingStationName: "MFUNELI JUNIOR SECONDARY SCHOOL",
    Address: "MFUNELI LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.8971",
    Longitude: "29.9954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4288",
    WardLookupId: "3233",
    VDNumber: "11750509",
    RegisteredPopulation: "266",
    VotingStationName: "NCENJANE JUNIOR SECONDARY SCHOOL",
    Address: "NCENJANE  LOC.  IMIZIZI A/A  BIZANA",
    Latitude: "-30.877",
    Longitude: "30.0398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4289",
    WardLookupId: "3233",
    VDNumber: "11750879",
    RegisteredPopulation: "1007",
    VotingStationName: "LUNA JUNIOR SECONDARY SCHOOL",
    Address: "LUNA LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.8708",
    Longitude: "29.9965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4290",
    WardLookupId: "3233",
    VDNumber: "11750992",
    RegisteredPopulation: "346",
    VotingStationName: "MPUNZI DRIFT JUNIOR SECONDARY SCHOOL",
    Address: "MPUNZI LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.8575",
    Longitude: "30.0601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4291",
    WardLookupId: "3233",
    VDNumber: "11751689",
    RegisteredPopulation: "454",
    VotingStationName: "REDOUBT A.F.M HALL",
    Address: "REDOUBT LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.8943",
    Longitude: "29.9742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4292",
    WardLookupId: "3233",
    VDNumber: "11751724",
    RegisteredPopulation: "316",
    VotingStationName: "MPONDOMBINI SENIOR SECONDARY SCHOOL",
    Address: "NYAKA LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.8564",
    Longitude: "29.9869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4293",
    WardLookupId: "3233",
    VDNumber: "11751735",
    RegisteredPopulation: "452",
    VotingStationName: "SIZOPHILA COMMUNITY & CHILD HELP CENTRE",
    Address: "NYAKA LOCATION  IMIZIZI A/A  MBIZANA",
    Latitude: "-30.884",
    Longitude: "29.9713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4294",
    WardLookupId: "3233",
    VDNumber: "11752130",
    RegisteredPopulation: "405",
    VotingStationName: "THEMBA MZIZE PRE SCHOOL",
    Address: "BIZANA  ",
    Latitude: "-30.8921",
    Longitude: "29.9674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4295",
    WardLookupId: "3233",
    VDNumber: "11752220",
    RegisteredPopulation: "247",
    VotingStationName: "MQONJWANA EKUTHULENI CHURCH",
    Address: "MQONJWANA  MFUNELI  IMIZIZI",
    Latitude: "-30.9119",
    Longitude: "30.0184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4296",
    WardLookupId: "3234",
    VDNumber: "11750093",
    RegisteredPopulation: "743",
    VotingStationName: "MONTI JUNIOR SECONDARY SCHOOL",
    Address: "MONTI  LOC.  AMANGUTYANA A/A  BIZANA",
    Latitude: "-30.9529",
    Longitude: "29.8658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4297",
    WardLookupId: "3234",
    VDNumber: "11750228",
    RegisteredPopulation: "796",
    VotingStationName: "NTLOZELO JUNIOR SECONDARY SCHOOL",
    Address: "NTLOZELO LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9727",
    Longitude: "29.9035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4298",
    WardLookupId: "3234",
    VDNumber: "11751027",
    RegisteredPopulation: "576",
    VotingStationName: "THOKOZANI JUNIOR SECONDARY SCHOOL",
    Address: "THOKOZANI LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9402",
    Longitude: "29.8874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4299",
    WardLookupId: "3234",
    VDNumber: "11751285",
    RegisteredPopulation: "1009",
    VotingStationName: "VUYISILE JUNIOR SECONDARY SCHOOL",
    Address: "VUYISILE  LOC  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9151",
    Longitude: "29.8889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4300",
    WardLookupId: "3234",
    VDNumber: "11751476",
    RegisteredPopulation: "646",
    VotingStationName: "MQENI JUNIOR SECONDARY SCHOOL",
    Address: "MQENI LOCALITY  AMANGUTYANA A/A  BIZANA",
    Latitude: "-30.994",
    Longitude: "29.8755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4301",
    WardLookupId: "3234",
    VDNumber: "11751601",
    RegisteredPopulation: "481",
    VotingStationName: "AME CHURCH",
    Address: "RURAL  NTLOZELO  BIZANA",
    Latitude: "-30.9754",
    Longitude: "29.8793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4302",
    WardLookupId: "3234",
    VDNumber: "11751612",
    RegisteredPopulation: "354",
    VotingStationName: "MBABAZWE SENIOR PRIMARY SCHOOL",
    Address: "RURAL  NTLOZELO  BIZANA",
    Latitude: "-30.9614",
    Longitude: "29.8879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4303",
    WardLookupId: "3234",
    VDNumber: "11751757",
    RegisteredPopulation: "375",
    VotingStationName: "ST PETERS ANGLICAN CHURCH",
    Address: "RURAL  NIKWE A/A  BIZANA",
    Latitude: "-30.8966",
    Longitude: "29.8803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4304",
    WardLookupId: "3235",
    VDNumber: "11750453",
    RegisteredPopulation: "533",
    VotingStationName: "LINDOKUHLE JUNIOR SECONDARY SCHOOL",
    Address: "LINDOKUHLE LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9241",
    Longitude: "29.9868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4305",
    WardLookupId: "3235",
    VDNumber: "11750868",
    RegisteredPopulation: "762",
    VotingStationName: "MADADANA JUNIOR SECONDARY SCHOOL",
    Address: "MADADANA LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9335",
    Longitude: "30.0095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4306",
    WardLookupId: "3235",
    VDNumber: "11750969",
    RegisteredPopulation: "953",
    VotingStationName: "ETHRIDGE JUNIOR SECONDARY SCHOOL",
    Address: "ETHRIDGE LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9129",
    Longitude: "29.9727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4307",
    WardLookupId: "3235",
    VDNumber: "11751656",
    RegisteredPopulation: "523",
    VotingStationName: "CHURCH OF CHRIST MISSION IMIZIZI",
    Address: "MAMCAKWENI VILLAGE  IMIZIZI  BIZANA",
    Latitude: "-30.9279",
    Longitude: "29.972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4308",
    WardLookupId: "3235",
    VDNumber: "11751667",
    RegisteredPopulation: "250",
    VotingStationName: "ST. FRANCIS ANGLICAN CHURCH",
    Address: "RURAL  IMIZIZI  BIZANA",
    Latitude: "-30.9167",
    Longitude: "29.9947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4309",
    WardLookupId: "3235",
    VDNumber: "11751690",
    RegisteredPopulation: "532",
    VotingStationName: "STANFORD JUNIOR SECONDARY SCHOOL",
    Address: "RURAL  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9128",
    Longitude: "29.9832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4310",
    WardLookupId: "3235",
    VDNumber: "11751702",
    RegisteredPopulation: "661",
    VotingStationName: "NONGEKE SENIOR SECONDARY SCHOOL",
    Address: "RURAL  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9058",
    Longitude: "29.9677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4311",
    WardLookupId: "3236",
    VDNumber: "11750510",
    RegisteredPopulation: "1129",
    VotingStationName: "GREENVILLE JUNIOR SECONDARY SCHOOL",
    Address: "GREENVILLE  LOC.  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9187",
    Longitude: "30.0976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4312",
    WardLookupId: "3236",
    VDNumber: "11750543",
    RegisteredPopulation: "691",
    VotingStationName: "NTIKA JUNIOR SECONDARY SCHOOL",
    Address: "NTIKA  LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.897",
    Longitude: "30.0731",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4313",
    WardLookupId: "3236",
    VDNumber: "11751252",
    RegisteredPopulation: "906",
    VotingStationName: "LANGALETHU JUNIOR SECONDARY SCHOOL",
    Address: "LANGALETHU LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9473",
    Longitude: "30.0729",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4314",
    WardLookupId: "3236",
    VDNumber: "11751645",
    RegisteredPopulation: "564",
    VotingStationName: "GREENVILLE HOSPITAL",
    Address: "RURAL  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9301",
    Longitude: "30.1085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4315",
    WardLookupId: "3236",
    VDNumber: "11751678",
    RegisteredPopulation: "261",
    VotingStationName: "NOBAMBA JUNIOR SECONDARY SCHOOL",
    Address: "RURUL  IMIZIZI A/A  BIZANA",
    Latitude: "-30.8993",
    Longitude: "30.0995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4316",
    WardLookupId: "3236",
    VDNumber: "11752242",
    RegisteredPopulation: "400",
    VotingStationName: "GREENVILLE AME CHURCH",
    Address: "GREENVILLE  ETYENI  IMIZIZIS",
    Latitude: "-30.9332",
    Longitude: "30.0968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4317",
    WardLookupId: "3237",
    VDNumber: "11750756",
    RegisteredPopulation: "895",
    VotingStationName: "LUGWIJINI JUNIOR SECONDARY SCHOOL",
    Address: "LUGWIJINI LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9746",
    Longitude: "29.998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4318",
    WardLookupId: "3237",
    VDNumber: "11750789",
    RegisteredPopulation: "1072",
    VotingStationName: "LUKHOLO JUNIOR SECONDARY SCHOOL",
    Address: "LUKHOLO LOC.  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9643",
    Longitude: "29.9708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4319",
    WardLookupId: "3237",
    VDNumber: "11750824",
    RegisteredPopulation: "515",
    VotingStationName: "MDELWA JUNIOR SECONDARY SCHOOL",
    Address: "MDELWA  OLCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9533",
    Longitude: "29.9206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4320",
    WardLookupId: "3237",
    VDNumber: "11751409",
    RegisteredPopulation: "794",
    VotingStationName: "MFOLOZI ROMAN CATHOLIC CHURCH",
    Address: "MFOLOZI LOCALITY  AMADIBA A/A  BIZANA",
    Latitude: "-30.994",
    Longitude: "30.0261",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4321",
    WardLookupId: "3237",
    VDNumber: "11751623",
    RegisteredPopulation: "465",
    VotingStationName: "LUKHOLO REFORMED CHURCH",
    Address: "RURAL  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9487",
    Longitude: "29.9679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4322",
    WardLookupId: "3237",
    VDNumber: "11751634",
    RegisteredPopulation: "662",
    VotingStationName: "LUKHOLO CHRISTIAN APOSTOLIC CHURCH IN ZION",
    Address: "LUKHOLO LOCATION  IMIZIZE A/A  BIZANA",
    Latitude: "-30.9791",
    Longitude: "29.9682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4323",
    WardLookupId: "3237",
    VDNumber: "11752141",
    RegisteredPopulation: "419",
    VotingStationName: "NOMAGQWATHEKAN SENIOR SECONDARY SCHOOL",
    Address: "LUKHOLO LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.968",
    Longitude: "29.9835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4324",
    WardLookupId: "3238",
    VDNumber: "11750970",
    RegisteredPopulation: "1376",
    VotingStationName: "MARINA COMPRIHENSIVE HIGH SCHOOL",
    Address: "MARINA LOCATION  AMADIBA A/A  BIZANA",
    Latitude: "-31.0412",
    Longitude: "30.127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4325",
    WardLookupId: "3238",
    VDNumber: "11751241",
    RegisteredPopulation: "1426",
    VotingStationName: "PLANGWENI JUNIOR SECONDARY SCHOOL",
    Address: "PLANGWENI LOCATION  AMADIBA A/A  BIZANA",
    Latitude: "-31.0571",
    Longitude: "30.1434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4326",
    WardLookupId: "3238",
    VDNumber: "11751364",
    RegisteredPopulation: "620",
    VotingStationName: "VULINDLELA COMPREHENSIVE HIGH SCHOOL",
    Address: "ELUROLWENI LOCATION  AMADIBA A/A  BIZANA",
    Latitude: "-31.0326",
    Longitude: "30.1402",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4327",
    WardLookupId: "3238",
    VDNumber: "11751566",
    RegisteredPopulation: "750",
    VotingStationName: "LINGELETHU SENIOR PRIMARY SCHOOL",
    Address: "EBENEZER LOCATION  AMADIBA A/A  MBIZANA",
    Latitude: "-31.0562",
    Longitude: "30.1263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4328",
    WardLookupId: "3238",
    VDNumber: "11752264",
    RegisteredPopulation: "539",
    VotingStationName: "BENZ SPAZA SHOP (TENT).",
    Address: "UMGUNGUNDLOVU LOCATION  AMADIBA A/A  BIZANA",
    Latitude: "-31.0415",
    Longitude: "30.1046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4329",
    WardLookupId: "3239",
    VDNumber: "11750475",
    RegisteredPopulation: "1816",
    VotingStationName: "EBENEZER COMMUNITY HALL",
    Address: "EBENEZER LOCATION  AMADIBA A/A  BIZANA",
    Latitude: "-31.0684",
    Longitude: "30.1647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4330",
    WardLookupId: "3239",
    VDNumber: "11750497",
    RegisteredPopulation: "515",
    VotingStationName: "MZAMBA MOUTH CHURCH",
    Address: "MZAMBA MOUTH LOCATION  AMADIBA A/A  MBIZANA",
    Latitude: "-31.0753",
    Longitude: "30.1606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4331",
    WardLookupId: "3239",
    VDNumber: "11751386",
    RegisteredPopulation: "739",
    VotingStationName: "AMADIBA CRECHE",
    Address: "GARANE LOCATION  AMADIBA A/A  BIZANA",
    Latitude: "-31.0729",
    Longitude: "30.1781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4332",
    WardLookupId: "3239",
    VDNumber: "11752275",
    RegisteredPopulation: "553",
    VotingStationName: "COVENANT CHURCH OF CHRIST HALL",
    Address: "PLANGENI LOCATION  UMGUNGUNDLOVU  BIZANA",
    Latitude: "-31.0606",
    Longitude: "30.1516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4333",
    WardLookupId: "3239",
    VDNumber: "11752286",
    RegisteredPopulation: "507",
    VotingStationName: "EBENEZER REFORMED CHURCH HALL",
    Address: "MZAMBA LOCATION  UMGUNGUNDLOVU  AMADIBA",
    Latitude: "-31.0695",
    Longitude: "30.1465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4334",
    WardLookupId: "3239",
    VDNumber: "11752297",
    RegisteredPopulation: "536",
    VotingStationName: "POWER OF GOD CHURCH HALL",
    Address: "UMGUNGUNDLOVU  UMGUNGUNDLUVU LOCATION   AMADIBA",
    Latitude: "-31.0669",
    Longitude: "30.1556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4335",
    WardLookupId: "3240",
    VDNumber: "11750295",
    RegisteredPopulation: "662",
    VotingStationName: "BALENI JUNIOR SECONDARY SCHOOL",
    Address: "BALENI  LOCATION  AMADIBA A/A  BIZANA",
    Latitude: "-31.1577",
    Longitude: "29.9705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4336",
    WardLookupId: "3240",
    VDNumber: "11750363",
    RegisteredPopulation: "493",
    VotingStationName: "MAQONGWANE JUNIOR SECONDARY SCHOOL",
    Address: "MAQONGWANE  LOC.  AMADIBA A/A  BIZANA",
    Latitude: "-31.0987",
    Longitude: "29.9784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4337",
    WardLookupId: "3240",
    VDNumber: "11750396",
    RegisteredPopulation: "262",
    VotingStationName: "BEKELA JUNIOR SECONDARY SCHOOL",
    Address: "BEKELA  LOCATION  AMADIBA A/A  BIZANA",
    Latitude: "-31.116",
    Longitude: "30.0811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4338",
    WardLookupId: "3240",
    VDNumber: "11751173",
    RegisteredPopulation: "736",
    VotingStationName: "XOLOBENI JUNIOR SECONDARY SCHOOL",
    Address: "XOLOBENI LOCATION  AMADIBA A/A  BIZANA",
    Latitude: "-31.1705",
    Longitude: "30.0612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4339",
    WardLookupId: "3240",
    VDNumber: "11751230",
    RegisteredPopulation: "467",
    VotingStationName: "MGWEDE JUNIOR SECONDARY SCHOOL",
    Address: "MGWEDE LOCATION  AMADIBA A/A  BIZANA",
    Latitude: "-31.1282",
    Longitude: "30.0361",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4340",
    WardLookupId: "3240",
    VDNumber: "11751331",
    RegisteredPopulation: "457",
    VotingStationName: "KHUMBUZA JUNIOR SECONDARY SCHOOL",
    Address: "RURAL  AMADIBA A/A  BIZANA",
    Latitude: "-31.1777",
    Longitude: "30.016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4341",
    WardLookupId: "3240",
    VDNumber: "11751948",
    RegisteredPopulation: "398",
    VotingStationName: "GCINISIZWE SENIOR PRIMARY SCHOOL",
    Address: "MAKWANTINI LOCATION  AMADIBA A/A  MBIZANA [BIZANA]",
    Latitude: "-31.1392",
    Longitude: "30.0049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4342",
    WardLookupId: "3240",
    VDNumber: "11751959",
    RegisteredPopulation: "410",
    VotingStationName: "JAMA SENIOR PRIMARY SCHOOL",
    Address: "BALENI LOCATION  AMADIBA A/A  MBIZANA [BIZANA]",
    Latitude: "-31.1719",
    Longitude: "29.9497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4343",
    WardLookupId: "3240",
    VDNumber: "11752118",
    RegisteredPopulation: "307",
    VotingStationName: "DUMASI CRECHE",
    Address: "DDUMASI LOCATION  AMADIBA  A/A  BIZANA",
    Latitude: "-31.2263",
    Longitude: "30.0365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4344",
    WardLookupId: "3240",
    VDNumber: "11752129",
    RegisteredPopulation: "214",
    VotingStationName: "PHANDULWAZI JUNIOR PRIMARY SCHOOL",
    Address: "PANDULWAZI LOCATION  AMADIBA A/A  BIZANA",
    Latitude: "-31.1231",
    Longitude: "29.9701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4345",
    WardLookupId: "3240",
    VDNumber: "11752185",
    RegisteredPopulation: "321",
    VotingStationName: "UMGUNGUNDLOVU TRADITIONAL HALL",
    Address: "BALENI  AMADIBA A/A  BIZANA",
    Latitude: "-31.1526",
    Longitude: "30.106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4346",
    WardLookupId: "3241",
    VDNumber: "11750734",
    RegisteredPopulation: "921",
    VotingStationName: "ZAMOKUHLE JUNIOR SECONDARY SCHOOL",
    Address: "ZAMOKUHLE LOCATION  ISIKELO A/A  BIZANA",
    Latitude: "-30.8333",
    Longitude: "29.7787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4347",
    WardLookupId: "3241",
    VDNumber: "11751162",
    RegisteredPopulation: "541",
    VotingStationName: "THANDABANTU SENIOR PRIMARY SCHOOL",
    Address: "THANDABANTU LOCATION  ISIKELO A/A  BIZANA",
    Latitude: "-30.79",
    Longitude: "29.7664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4348",
    WardLookupId: "3241",
    VDNumber: "11751836",
    RegisteredPopulation: "773",
    VotingStationName: "MOUNT. ZION JUNIOR SECONDARY SCHOOL",
    Address: "RURAL  ISIKELO A/A  BIZANA",
    Latitude: "-30.806",
    Longitude: "29.7711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4349",
    WardLookupId: "3241",
    VDNumber: "11751847",
    RegisteredPopulation: "597",
    VotingStationName: "NOMLACU CHRISTIAN APOSTOLIC CHURCH",
    Address: "RURAL  ISIKELO A/A  BIZANA",
    Latitude: "-30.842",
    Longitude: "29.7865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4350",
    WardLookupId: "3241",
    VDNumber: "11751858",
    RegisteredPopulation: "275",
    VotingStationName: "EKUPHUMLENI REFORMED CHURCH",
    Address: "LUDEKE HALT  ISIKELO A/A  BIZANA, 4800",
    Latitude: "-30.8519",
    Longitude: "29.7484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4351",
    WardLookupId: "3241",
    VDNumber: "11752040",
    RegisteredPopulation: "465",
    VotingStationName: "MZOMTSHA METHODIST CHURCH",
    Address: "00 N/A  MT. ZION LOCATION  BIZANA",
    Latitude: "-30.8249",
    Longitude: "29.7723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4352",
    WardLookupId: "3241",
    VDNumber: "11752051",
    RegisteredPopulation: "397",
    VotingStationName: "THANDABANTU METHODIST MEMORIAL SOCIETY",
    Address: "00 NOMLACU LOCATION   ISIKELO A/A   BIZANA",
    Latitude: "-30.8396",
    Longitude: "29.7715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4353",
    WardLookupId: "3242",
    VDNumber: "11750330",
    RegisteredPopulation: "602",
    VotingStationName: "QUNGEBE SENIOR PRIMARY SCHOOL",
    Address: "QUNGEBE LOC.  NKANTOLO A/A  BIZANA",
    Latitude: "-30.784",
    Longitude: "29.6681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4354",
    WardLookupId: "3242",
    VDNumber: "11750486",
    RegisteredPopulation: "474",
    VotingStationName: "CLARKVILLE JUNIOR SECONDARY SCHOOL",
    Address: "CLARKVILLE  LOC.  ISIKELO A/A  BIZANA",
    Latitude: "-30.7981",
    Longitude: "29.6839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4355",
    WardLookupId: "3242",
    VDNumber: "11750723",
    RegisteredPopulation: "600",
    VotingStationName: "NKANTOLO JUNIOR SECONDARY SCHOOL",
    Address: "NKANTOLO  LOC  ISIKELO A/A  BIZANA",
    Latitude: "-30.7621",
    Longitude: "29.7248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4356",
    WardLookupId: "3242",
    VDNumber: "11751296",
    RegisteredPopulation: "768",
    VotingStationName: "QOBO JUNIOR SECONDARY SCHOOL",
    Address: "QOBO LOCATION  IZILANGWE A/A  BIZANA",
    Latitude: "-30.7322",
    Longitude: "29.7209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4357",
    WardLookupId: "3242",
    VDNumber: "11751791",
    RegisteredPopulation: "650",
    VotingStationName: "MLAMBONDABA SENIOR PRIMARY SCHOOL",
    Address: "MLAMBONDABA LOC  IZILANGWE A/A  BIZANA",
    Latitude: "-30.7304",
    Longitude: "29.6801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4358",
    WardLookupId: "3242",
    VDNumber: "11752017",
    RegisteredPopulation: "442",
    VotingStationName: "SILANGWE SENIOR PRIMARY SCHOOL",
    Address: "AFRICA  SILANGWE A/A  MBIZANA [BIZANA]",
    Latitude: "-30.7305",
    Longitude: "29.7379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4359",
    WardLookupId: "3242",
    VDNumber: "11752028",
    RegisteredPopulation: "261",
    VotingStationName: "ETALENI SENIOR PRIMARY SCHOOL",
    Address: "TALENI LOCATION  IZILANGWE A/A  MBIZANA [BIZANA]",
    Latitude: "-30.7057",
    Longitude: "29.686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4360",
    WardLookupId: "3242",
    VDNumber: "11752062",
    RegisteredPopulation: "219",
    VotingStationName: "EKUPHILENI ETHIOPIAN CHURCH IN ZION",
    Address: "SIZINDENI LOCATION   IZILANGWE A/A   BIZANA",
    Latitude: "-30.7225",
    Longitude: "29.701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4361",
    WardLookupId: "3243",
    VDNumber: "11750352",
    RegisteredPopulation: "322",
    VotingStationName: "SIDANGA JUNIOR SECONDARY SCHOOL",
    Address: "SIDANGA  LOCATION  AMADIBA A/A  BIZANA",
    Latitude: "-31.0225",
    Longitude: "29.9466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4362",
    WardLookupId: "3243",
    VDNumber: "11750374",
    RegisteredPopulation: "335",
    VotingStationName: "TOPOZO JUNIOR SECONDARY SCHOOL",
    Address: "TOPOZO LOCATION  AMADIBA A/A  BIZANA",
    Latitude: "-31.0262",
    Longitude: "30.0209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4363",
    WardLookupId: "3243",
    VDNumber: "11750408",
    RegisteredPopulation: "503",
    VotingStationName: "MDATYA SENIOR SECONDARY SCHOOL",
    Address: "MDATYA LOCATION  AMADIBA A/A  BIZANA",
    Latitude: "-31.11",
    Longitude: "30.0979",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4364",
    WardLookupId: "3243",
    VDNumber: "11750419",
    RegisteredPopulation: "376",
    VotingStationName: "MAHAHA JUNIOR SECONDARY SCHOOL",
    Address: "MAHAHA LOCATION  AMADIBA A/A  BIZANA",
    Latitude: "-31.0732",
    Longitude: "30.0718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4365",
    WardLookupId: "3243",
    VDNumber: "11750554",
    RegisteredPopulation: "412",
    VotingStationName: "GUMZANA JUNIOR SECONDARY SCHOOL",
    Address: "GUMZANA LOCALITY  AMANGUTYANA A/A  BIZANA",
    Latitude: "-31.0172",
    Longitude: "29.9804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4366",
    WardLookupId: "3243",
    VDNumber: "11750802",
    RegisteredPopulation: "489",
    VotingStationName: "ELITYENI JUNIOR SECONDARY SCHOOL",
    Address: "ELITYENI LOCATION  AMADIBA A/A  BIZANA",
    Latitude: "-31.0121",
    Longitude: "29.914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4367",
    WardLookupId: "3243",
    VDNumber: "11750958",
    RegisteredPopulation: "336",
    VotingStationName: "SGIDI SENIOR PRIMARY SCHOOL",
    Address: "SGIDI LOCATION  AMADIBA A/A  BIZANA",
    Latitude: "-31.0976",
    Longitude: "30.1461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4368",
    WardLookupId: "3243",
    VDNumber: "11751342",
    RegisteredPopulation: "522",
    VotingStationName: "MNGUNGU JUNIOR SECONDARY SCHOOL",
    Address: "RURAL  AMADIBA A/A  BIZANA",
    Latitude: "-31.0831",
    Longitude: "30.0039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4369",
    WardLookupId: "3243",
    VDNumber: "11752152",
    RegisteredPopulation: "318",
    VotingStationName: "LUNDINI JUNIOR SECONDARY SCHOOL",
    Address: "GUMZANA LOCALITY  AMANGUTYANA A/A  BIZANA",
    Latitude: "-31.0524",
    Longitude: "30.0017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4370",
    WardLookupId: "3243",
    VDNumber: "11752174",
    RegisteredPopulation: "216",
    VotingStationName: "MNGUNGU HLALANATHI CHURCH HALL",
    Address: "MNGUNGU LOCATION  AMADIBA A/A  BIZANA",
    Latitude: "-31.0589",
    Longitude: "30.0379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4371",
    WardLookupId: "3243",
    VDNumber: "11752231",
    RegisteredPopulation: "139",
    VotingStationName: "HOLY SPIRIT IN ZION",
    Address: "BAZANE  DANGENI  AMABIBA",
    Latitude: "-31.0721",
    Longitude: "29.9724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4372",
    WardLookupId: "3243",
    VDNumber: "11752321",
    RegisteredPopulation: "187",
    VotingStationName: "NKQUBELA PRE SCHOO",
    Address: "LUBEKELELE LOCATOIN   AMADIBA A/A   BIZANA",
    Latitude: "-31.0909",
    Longitude: "30.0406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4373",
    WardLookupId: "3244",
    VDNumber: "11750183",
    RegisteredPopulation: "717",
    VotingStationName: "ZWELETHU JUNIOR SECONDARY SCHOOL",
    Address: "ZWELETHU LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9576",
    Longitude: "30.0324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4374",
    WardLookupId: "3244",
    VDNumber: "11750521",
    RegisteredPopulation: "486",
    VotingStationName: "CWAKA JUNIOR SECONDARY SCHOOL",
    Address: "CWAKA LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9179",
    Longitude: "30.0709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4375",
    WardLookupId: "3244",
    VDNumber: "11751005",
    RegisteredPopulation: "648",
    VotingStationName: "PHATHEKILE JUNIOR SECONDARY SCHOOL",
    Address: "PHATHEKILE LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.9",
    Longitude: "30.0486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4376",
    WardLookupId: "3244",
    VDNumber: "11751106",
    RegisteredPopulation: "447",
    VotingStationName: "MFOLOZI JUNIOR SECONDARY SCHOOL",
    Address: "MFOLOZI LOCATION  IMIZIZI A/A  BIZANA",
    Latitude: "-30.987",
    Longitude: "30.0314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4377",
    WardLookupId: "3244",
    VDNumber: "11751397",
    RegisteredPopulation: "794",
    VotingStationName: "LITTLE EDEN PRIVATE SCHOOL",
    Address: "ZIKHUBA LOCALITY  AMADIBA A/A  BIZANA",
    Latitude: "-31.0262",
    Longitude: "30.1171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4378",
    WardLookupId: "3244",
    VDNumber: "11752163",
    RegisteredPopulation: "373",
    VotingStationName: "KWABULALA COMMUNITY HALL",
    Address: "KWABULALA LOCATION  IMIZIZI  BIZANA",
    Latitude: "-31.0019",
    Longitude: "30.0434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4379",
    WardLookupId: "3245",
    VDNumber: "11750071",
    RegisteredPopulation: "430",
    VotingStationName: "DUTYINI JUNIOR SECONDARY SCHOOL",
    Address: "DUTYINI LOCATION  AMANGUTYANA A/A  BIZANA",
    Latitude: "-30.9488",
    Longitude: "29.79",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4380",
    WardLookupId: "3245",
    VDNumber: "11750082",
    RegisteredPopulation: "772",
    VotingStationName: "NYANISWENI SENIOR PRIMARY SCHOOL",
    Address: "NYANISWENI  LOC  AMANGUTYANA A/A  BIZANA",
    Latitude: "-30.897",
    Longitude: "29.7912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4381",
    WardLookupId: "3245",
    VDNumber: "11750138",
    RegisteredPopulation: "455",
    VotingStationName: "VAYI JUNIOR SECONDARY SCHOOL",
    Address: "VAYI LOC.  AMANGUTYANA A/A  BIZANA",
    Latitude: "-30.9697",
    Longitude: "29.7792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4382",
    WardLookupId: "3245",
    VDNumber: "11750891",
    RegisteredPopulation: "412",
    VotingStationName: "NTSIMBINI JUNIOR SECONDARY SCHOOL",
    Address: "NTSIMBINI LOCALITY  NTSIMBINI A/A  BIZANA",
    Latitude: "-30.9807",
    Longitude: "29.8365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4383",
    WardLookupId: "3245",
    VDNumber: "11751274",
    RegisteredPopulation: "409",
    VotingStationName: "ESITOFINI SENIOR PRIMARY SCHOOL",
    Address: "ESITOFINI LOCALITY  AMANGUTYANA A/A  BIZANA",
    Latitude: "-30.9328",
    Longitude: "29.8182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4384",
    WardLookupId: "3245",
    VDNumber: "11751588",
    RegisteredPopulation: "273",
    VotingStationName: "MPENKULU SENIOR PRIMARY SCHOOL",
    Address: "RURAL  AMANGUTYANA A/A  BIZANA",
    Latitude: "-30.9632",
    Longitude: "29.8112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4385",
    WardLookupId: "3245",
    VDNumber: "11751599",
    RegisteredPopulation: "351",
    VotingStationName: "KOPANE JUNIOR PRIMARY SCHOOL",
    Address: "KOPANE LOCATION  AMANGUTYANA A/A  BIZANA",
    Latitude: "-30.9405",
    Longitude: "29.8513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4386",
    WardLookupId: "3245",
    VDNumber: "11751779",
    RegisteredPopulation: "489",
    VotingStationName: "MATHWEBU ST MATHEWS CHURCH",
    Address: "MATHWEBU LOCATION  AMANGUTYANA A/A  BIZANA, 4800",
    Latitude: "-30.8699",
    Longitude: "29.8253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4387",
    WardLookupId: "3245",
    VDNumber: "11751993",
    RegisteredPopulation: "368",
    VotingStationName: "NGQONGWENI JUNIOR PRIMARY SCHOOL",
    Address: "NGQONGWENI LOCATION  AMANGUTYANA A/A  MBIZANA [BIZANA]",
    Latitude: "-30.9123",
    Longitude: "29.8119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4388",
    WardLookupId: "3245",
    VDNumber: "11752006",
    RegisteredPopulation: "476",
    VotingStationName: "MXINGA ROMAN CATHOLIC CHURCH",
    Address: "MXINGA LOCATION  AMANGUTYANA A/A  MBIZANA [BIZANA]",
    Latitude: "-30.8828",
    Longitude: "29.8117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4389",
    WardLookupId: "3246",
    VDNumber: "11750116",
    RegisteredPopulation: "830",
    VotingStationName: "ZIZITYANENI JUNIOR SECONDARY SCHOOL",
    Address: "ZITYANENI  LOC.  ISIKELO  A/A  BIZANA",
    Latitude: "-30.876",
    Longitude: "29.7309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4390",
    WardLookupId: "3246",
    VDNumber: "11750318",
    RegisteredPopulation: "526",
    VotingStationName: "MAJAVU  SECONDARY SCHOOL",
    Address: "MAJAVU LOCATION  ISIKELO A/A  BIZANA",
    Latitude: "-30.8555",
    Longitude: "29.7022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4391",
    WardLookupId: "3246",
    VDNumber: "11750835",
    RegisteredPopulation: "639",
    VotingStationName: "KWANDELA JUNIOR SECONDARY SCHOOL",
    Address: "NDELA  LOCATION  AMANDELA   A/A  BIZANA",
    Latitude: "-30.911",
    Longitude: "29.7289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4392",
    WardLookupId: "3246",
    VDNumber: "11750947",
    RegisteredPopulation: "350",
    VotingStationName: "EBLORWENI SENIOR PRIMARY SCHOOL",
    Address: "BULORWENI LOCATION  ISIKELO A/A  BIZANA",
    Latitude: "-30.8472",
    Longitude: "29.6843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4393",
    WardLookupId: "3246",
    VDNumber: "11751320",
    RegisteredPopulation: "565",
    VotingStationName: "DYIFANI  JUNIOR SECONDARY SCHOOL",
    Address: "DYIFANI   LOC  ISIKELO   A/A  BIZANA",
    Latitude: "-30.8595",
    Longitude: "29.743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4394",
    WardLookupId: "3246",
    VDNumber: "11751577",
    RegisteredPopulation: "446",
    VotingStationName: "ZIZITYANENI  ASSEMBLIES OF GOD CHURCH",
    Address: "ZIZITYANENI  ESIKHUMBENI   ISIKELO",
    Latitude: "-30.8685",
    Longitude: "29.732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4395",
    WardLookupId: "3246",
    VDNumber: "11752208",
    RegisteredPopulation: "407",
    VotingStationName: "EMAZWENI CHIEF MTHENJWA PRE-SCHOOL",
    Address: "EMAZWENI LOCATION  ISIKELO A/A  BIZANA",
    Latitude: "-30.8578",
    Longitude: "29.7262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4396",
    WardLookupId: "3246",
    VDNumber: "11752253",
    RegisteredPopulation: "481",
    VotingStationName: "MZAMBA EXT. ZAMOKUHLE CHURCH",
    Address: "MZAMBA EXTENTION LOCATION  ISIKELO   BIZANA",
    Latitude: "-30.8435",
    Longitude: "29.764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4397",
    WardLookupId: "782",
    VDNumber: "11820013",
    RegisteredPopulation: "591",
    VotingStationName: "NTLANGANO JUNIOR SECONDARY SCHOOL",
    Address: "DUNGU LOCATION  NTLANGANO A/A  NTABANKULU",
    Latitude: "-31.2611",
    Longitude: "29.2153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4398",
    WardLookupId: "782",
    VDNumber: "11820024",
    RegisteredPopulation: "638",
    VotingStationName: "DUNGU JUNIOR SECONDARY SCHOOL",
    Address: "DUNGU AA  NTABANKULU  NTABANKULU",
    Latitude: "-31.2221",
    Longitude: "29.2043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4399",
    WardLookupId: "782",
    VDNumber: "11820035",
    RegisteredPopulation: "333",
    VotingStationName: "MAWONGA HIGHER PRIMARY SCHOOL",
    Address: "N/A NGCOLO A/A, QUMBU  NGCOLO A/A  QUMBU",
    Latitude: "-31.2134",
    Longitude: "29.1819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4400",
    WardLookupId: "782",
    VDNumber: "11820866",
    RegisteredPopulation: "431",
    VotingStationName: "LUTHAMBEKO JUNIOR SECONDARY SCHOOL.",
    Address: "LUTHAMBEKO LOCATION  DUMSI A/A  NTABANKULU",
    Latitude: "-31.1812",
    Longitude: "29.2179",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4401",
    WardLookupId: "782",
    VDNumber: "11820967",
    RegisteredPopulation: "175",
    VotingStationName: "CANDULWANDLE JUNIOR PRIMARY SCHOOL",
    Address: "NYASA LOC  DUMSI A/A  TABANKULU",
    Latitude: "-31.1585",
    Longitude: "29.1711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4402",
    WardLookupId: "782",
    VDNumber: "11821227",
    RegisteredPopulation: "329",
    VotingStationName: "ZAMUKULUNGISA SENIOR PRIMARY SCHOOL",
    Address: "KOLONO LOCATION  DUNGU A/A  TABANKULU",
    Latitude: "-31.1976",
    Longitude: "29.2153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4403",
    WardLookupId: "782",
    VDNumber: "11821373",
    RegisteredPopulation: "359",
    VotingStationName: "MADLALISA SENIOR PRIMARY SCHOOL",
    Address: "MADLALISA LOCATION   BHUNGA A/A  NTABANKULU",
    Latitude: "-31.2296",
    Longitude: "29.2212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4404",
    WardLookupId: "782",
    VDNumber: "11821384",
    RegisteredPopulation: "262",
    VotingStationName: "BHAYI SENIOR PRIMARY SCHOOL",
    Address: "NGQUMANE LOCATION  NGCOLO A/A  NTABANKULU",
    Latitude: "-31.2303",
    Longitude: "29.1872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4405",
    WardLookupId: "782",
    VDNumber: "11821418",
    RegisteredPopulation: "428",
    VotingStationName: "VANE JUNIOR SECONDARY SCHOOL",
    Address: "VANE LOCATION  NGCOLO A/A  TABANKULU",
    Latitude: "-31.1881",
    Longitude: "29.2072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4406",
    WardLookupId: "782",
    VDNumber: "11821452",
    RegisteredPopulation: "117",
    VotingStationName: "NONKCOLOSA PRE - SCHOOL",
    Address: "NTABANKULU  HABHU ADMIN AREA  NTABANKULU",
    Latitude: "-31.159",
    Longitude: "29.25",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4407",
    WardLookupId: "783",
    VDNumber: "11820080",
    RegisteredPopulation: "1339",
    VotingStationName: "DUMSI JUNIOR SECONDARY SCHOOL",
    Address: "DUMSI LOC  SILINDINI A/A  NTABANKULU",
    Latitude: "-31.1461",
    Longitude: "29.1672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4408",
    WardLookupId: "783",
    VDNumber: "11820103",
    RegisteredPopulation: "1193",
    VotingStationName: "XOPHO JUNIOR SECONDARY SCHOOL",
    Address: "IXOPO LOCALITY  NDUNGUNYENI A/A  NTABANKULU",
    Latitude: "-31.1445",
    Longitude: "29.2292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4409",
    WardLookupId: "783",
    VDNumber: "11820170",
    RegisteredPopulation: "878",
    VotingStationName: "MHLELENI JUNIOR SECONDARY SCHOOL",
    Address: "MHLELENI LOCALLITY  MHLELENI A/A  NTABANKULU",
    Latitude: "-31.0911",
    Longitude: "29.2274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4410",
    WardLookupId: "783",
    VDNumber: "11820956",
    RegisteredPopulation: "508",
    VotingStationName: "NGUBELANGA JUNIOR SECONDARY SCHOOL",
    Address: "MANDILIVA LOC  DUMSI A/A  TABANKULU",
    Latitude: "-31.1376",
    Longitude: "29.1729",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4411",
    WardLookupId: "784",
    VDNumber: "11820091",
    RegisteredPopulation: "603",
    VotingStationName: "MATHOLE JUNIOR SECONDARY SCHOOL",
    Address: "SIDAKENI LOC  SILINDINI A/A, NTABANKULU  NTABANKULU",
    Latitude: "-31.1062",
    Longitude: "29.1882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4412",
    WardLookupId: "784",
    VDNumber: "11820125",
    RegisteredPopulation: "865",
    VotingStationName: "ZINYOSINI JUNIOR SECONDARY SCHOOL",
    Address: "ZINYOSINI LOC  SILINDINI A/A  NTABANKULU",
    Latitude: "-31.091",
    Longitude: "29.1338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4413",
    WardLookupId: "784",
    VDNumber: "11820215",
    RegisteredPopulation: "940",
    VotingStationName: "RAVENSCROFT JUNIOR SECONDARY SCHOOL",
    Address: "CIBINI A/A  NTABANKULU",
    Latitude: "-31.0631",
    Longitude: "29.1013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4414",
    WardLookupId: "784",
    VDNumber: "11820877",
    RegisteredPopulation: "309",
    VotingStationName: "MANALENI JUNIOR SECONDARY SCHOOL.",
    Address: "MANALENI LOC  CIBINI A/A  TABANKULU",
    Latitude: "-31.0258",
    Longitude: "29.1485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4415",
    WardLookupId: "784",
    VDNumber: "11820923",
    RegisteredPopulation: "386",
    VotingStationName: "MNGQUMANGO SENIOR PRIMARY SCHOOL",
    Address: "NYABENI LOC  ISILINDINI A/A  TABANKULU",
    Latitude: "-31.0325",
    Longitude: "29.1701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4416",
    WardLookupId: "784",
    VDNumber: "11821250",
    RegisteredPopulation: "357",
    VotingStationName: "ZIMELE JUNIOR SECONDARY SCHOOL",
    Address: "KWAGQINA LOCATION  ISILINDINI A/A  TABANKULU",
    Latitude: "-31.123",
    Longitude: "29.1559",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4417",
    WardLookupId: "785",
    VDNumber: "11820114",
    RegisteredPopulation: "628",
    VotingStationName: "ISILINDINI JUNIOR SECONDARY SCHOOL",
    Address: "ISILINDINI LOCATION  ISILINDINI A/A  TABANKULU",
    Latitude: "-31.0939",
    Longitude: "29.178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4418",
    WardLookupId: "785",
    VDNumber: "11820169",
    RegisteredPopulation: "793",
    VotingStationName: "NOWALALA JUNIOR SECONDARY SCHOOL",
    Address: "NOWALALA LOC  NOWALALA A/A  NTABANKULU",
    Latitude: "-31.0753",
    Longitude: "29.2454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4419",
    WardLookupId: "785",
    VDNumber: "11820822",
    RegisteredPopulation: "452",
    VotingStationName: "KRWANTSANA    JUNIOR   SECONDARY   SCHOOL",
    Address: "KRWANTSANA LOCATION  KRWANTSANA A/A  NTABANKULU",
    Latitude: "-31.0633",
    Longitude: "29.2681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4420",
    WardLookupId: "785",
    VDNumber: "11820833",
    RegisteredPopulation: "248",
    VotingStationName: "DABULAMANZI SENIOR PRIMARY SCHOOL",
    Address: "DABULAMANZI LOCATION  NOWALALA A/A KUKUMBA LOCATION  NTABANKULU",
    Latitude: "-31.0456",
    Longitude: "29.2188",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4421",
    WardLookupId: "785",
    VDNumber: "11820934",
    RegisteredPopulation: "603",
    VotingStationName: "NTSINTSANA JUNIOR SECONDARY SCHOOL.",
    Address: "NTSINTSANA LOCATION  DWAKU A/A  NTABANKULU",
    Latitude: "-31.0683",
    Longitude: "29.1857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4422",
    WardLookupId: "785",
    VDNumber: "11820945",
    RegisteredPopulation: "554",
    VotingStationName: "DUTCH REFORMED CHURCH",
    Address: "SILINDINI LOCATION  SILINDINI A/A  NTABANKULU",
    Latitude: "-31.0886",
    Longitude: "29.1874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4423",
    WardLookupId: "785",
    VDNumber: "11821463",
    RegisteredPopulation: "179",
    VotingStationName: "EKAYENI PRE - SCHOOL",
    Address: "NTABANKULU  NOWALALA ADMIN AREA  NTABANKULU",
    Latitude: "-31.0655",
    Longitude: "29.239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4424",
    WardLookupId: "786",
    VDNumber: "11820237",
    RegisteredPopulation: "309",
    VotingStationName: "MQATYENI JUNIOR SECONDARY SCHOOL",
    Address: "MQATYENI LOCALITY  NGOZI A/A  TABANKULU",
    Latitude: "-31.0504",
    Longitude: "29.1946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4425",
    WardLookupId: "786",
    VDNumber: "11820248",
    RegisteredPopulation: "383",
    VotingStationName: "COLA JUNIOR SECONDARY SCHOOL",
    Address: "COLA LOCATION  COLA A/A  NTABANKULU",
    Latitude: "-31.0068",
    Longitude: "29.2092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4426",
    WardLookupId: "786",
    VDNumber: "11820271",
    RegisteredPopulation: "433",
    VotingStationName: "NGCABHELA JUNIOR SECONDARY SCHOOL",
    Address: "XHUKULA LOC.  NGOZI A/A  NTABANKULU",
    Latitude: "-31.0028",
    Longitude: "29.1582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4427",
    WardLookupId: "786",
    VDNumber: "11820282",
    RegisteredPopulation: "884",
    VotingStationName: "MATSHONA JUNIOR SECONDARY SCHOOL",
    Address: "MATSHONA LOCALITY  MSUKENI A/A  NTABANKULU",
    Latitude: "-30.9949",
    Longitude: "29.2628",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4428",
    WardLookupId: "786",
    VDNumber: "11820608",
    RegisteredPopulation: "394",
    VotingStationName: "BLORWENI JUNIOR SECONDARY SCHOOL",
    Address: "XHIBENI LOCATION  NGOZI A/A  TABANKULU",
    Latitude: "-31.0401",
    Longitude: "29.2398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4429",
    WardLookupId: "786",
    VDNumber: "11820844",
    RegisteredPopulation: "167",
    VotingStationName: "GABHENI SENIOR PRIMARY SCHOOL",
    Address: "GABHENI LOCATION  NGOZI A/A  NTABANKULU",
    Latitude: "-31.0123",
    Longitude: "29.1819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4430",
    WardLookupId: "786",
    VDNumber: "11820899",
    RegisteredPopulation: "482",
    VotingStationName: "MHLONYANENI SENIOR PRIMARY SCHOOL",
    Address: "MHLONYANENI LOC  CABA A/A  TABANKULU",
    Latitude: "-30.9873",
    Longitude: "29.205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4431",
    WardLookupId: "786",
    VDNumber: "11820901",
    RegisteredPopulation: "245",
    VotingStationName: "MAZAKHELE JUNIOR SECONDARY SCHOOL",
    Address: "THALENI LOC  MSUKENI A/A  TABANKULU",
    Latitude: "-31.0071",
    Longitude: "29.2554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4432",
    WardLookupId: "786",
    VDNumber: "11821238",
    RegisteredPopulation: "398",
    VotingStationName: "GCINISIZWE SENIOR PRIMARY SCHOOL",
    Address: "NTSINYANE LOCATION  MSUKENI A/A  TABANKULU",
    Latitude: "-31.0041",
    Longitude: "29.271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4433",
    WardLookupId: "786",
    VDNumber: "11821249",
    RegisteredPopulation: "234",
    VotingStationName: "NQALO SENIOR PRIMARY SCHOOL",
    Address: "NQALO LOCATION  CABA A/A  TABANKULU",
    Latitude: "-30.9729",
    Longitude: "29.1888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4434",
    WardLookupId: "786",
    VDNumber: "11821328",
    RegisteredPopulation: "457",
    VotingStationName: "NTSIKAYEZWE SENIOR SECONDARY SCHOOL",
    Address: "MATSHONA LOCATION  CABA A/A  NTABANKULU",
    Latitude: "-30.9953",
    Longitude: "29.2418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4435",
    WardLookupId: "786",
    VDNumber: "11821340",
    RegisteredPopulation: "296",
    VotingStationName: "NGOZI SENIOR PRIMARY SCHOOL",
    Address: "NGOZI LOCATION  NGOZI A/A  NTABANKULU",
    Latitude: "-31.0192",
    Longitude: "29.197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4436",
    WardLookupId: "787",
    VDNumber: "11820136",
    RegisteredPopulation: "408",
    VotingStationName: "NDILE JUNIOR SECONDARY SCHOOL",
    Address: "NDILE LOCATION  NYOKWENI A/A  NTABANKULU",
    Latitude: "-31.1424",
    Longitude: "29.3162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4437",
    WardLookupId: "787",
    VDNumber: "11820147",
    RegisteredPopulation: "1073",
    VotingStationName: "DINWAYO JUNIOR SECONDARY SCHOOL",
    Address: "DINWAYO LOCATION  NYOKWENI A/A  TABANKULU",
    Latitude: "-31.0853",
    Longitude: "29.3279",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4438",
    WardLookupId: "787",
    VDNumber: "11820158",
    RegisteredPopulation: "933",
    VotingStationName: "XAKANI JUNIOR SECONDARY SCHOOL",
    Address: "XAKANA LOCATION  NYOKWENI A/A  TABANKULU",
    Latitude: "-31.0644",
    Longitude: "29.3458",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4439",
    WardLookupId: "787",
    VDNumber: "11820406",
    RegisteredPopulation: "801",
    VotingStationName: "MTUKUKAZI JUNIOR SECONDARY SCHOOL",
    Address: "MTHUKUKAZI LOCALITY  TABANKULU VILLAGE  TABANKULU",
    Latitude: "-31.1046",
    Longitude: "29.3177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4440",
    WardLookupId: "787",
    VDNumber: "11820855",
    RegisteredPopulation: "500",
    VotingStationName: "KRESTU CHURCH",
    Address: "MADWABA LOCATION  NYOKWENI ADMINISTRATIVE AREA  NTABANKULU",
    Latitude: "-31.1375",
    Longitude: "29.3354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4441",
    WardLookupId: "787",
    VDNumber: "11821216",
    RegisteredPopulation: "459",
    VotingStationName: "NGCWAMANI [ZANOKHANYO] JUNIOR SECONDARY SCHOOL.",
    Address: "NGCWAMANI LOCATION  MTUKUKAZI A/A  TABANKULU",
    Latitude: "-31.0959",
    Longitude: "29.3517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4442",
    WardLookupId: "787",
    VDNumber: "11821317",
    RegisteredPopulation: "357",
    VotingStationName: "DUMAKUDE JUNIOR PRIMARY SCHOOL",
    Address: "MANXUDEBE LOCATION  NYOKWENI A/A  NTABANKULU",
    Latitude: "-31.1505",
    Longitude: "29.2972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4443",
    WardLookupId: "788",
    VDNumber: "11820260",
    RegisteredPopulation: "419",
    VotingStationName: "MZWAKAZI JUNIOR SECONDARY SCHOOL",
    Address: "MZWAKAZI LOCALITY  MZWAKAZI A/A  NTABANKULU",
    Latitude: "-31.0319",
    Longitude: "29.2973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4444",
    WardLookupId: "788",
    VDNumber: "11820305",
    RegisteredPopulation: "340",
    VotingStationName: "LALASHE HIGHER PRIMARY SCHOOL",
    Address: "LALASHE LOCATION  LUDEKE A/A  NTABANKULU",
    Latitude: "-31.1075",
    Longitude: "29.3941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4445",
    WardLookupId: "788",
    VDNumber: "11820350",
    RegisteredPopulation: "637",
    VotingStationName: "LUDEKE JUNIOR SECONDARY SCHOOL",
    Address: "LUDEKE LOCATION  LUDEKE A/A  TABANKULU",
    Latitude: "-31.0574",
    Longitude: "29.3936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4446",
    WardLookupId: "788",
    VDNumber: "11820361",
    RegisteredPopulation: "1162",
    VotingStationName: "BOMVINI JUNIOR SECONDARY SCHOOL",
    Address: "BOMVINI LOCATION  BOMVINI A/A  TABANKULU",
    Latitude: "-31.034",
    Longitude: "29.3663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4447",
    WardLookupId: "788",
    VDNumber: "11820372",
    RegisteredPopulation: "565",
    VotingStationName: "MADWAKAZANE JUNIOR SECONDARY SCHOOL",
    Address: "MADWAKAZANE A/A  TABANKULU",
    Latitude: "-30.994",
    Longitude: "29.3846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4448",
    WardLookupId: "788",
    VDNumber: "11820811",
    RegisteredPopulation: "462",
    VotingStationName: "NYANDA DIKO JUNIOR SECONDARY SCHOOL",
    Address: "BONGA LOCATION  LUDEKE ADMINSTRATION AREA  NTABANKULU",
    Latitude: "-31.0506",
    Longitude: "29.4164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4449",
    WardLookupId: "788",
    VDNumber: "11821171",
    RegisteredPopulation: "304",
    VotingStationName: "MSUKENI SENIOR PRIMARY SCHOOL",
    Address: "STHEBE VILLAGE  MSUKENI A/A  TABANKULU",
    Latitude: "-31.0485",
    Longitude: "29.294",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4450",
    WardLookupId: "788",
    VDNumber: "11821182",
    RegisteredPopulation: "246",
    VotingStationName: "MGENI SENIOR PRIMARY SCHOOL",
    Address: "MZWAKAZI LOCATION  MSUKENI A/A  TABANKULU",
    Latitude: "-31.008",
    Longitude: "29.3164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4451",
    WardLookupId: "788",
    VDNumber: "11821407",
    RegisteredPopulation: "355",
    VotingStationName: "DAMBENI SENIOR PRIMARY SCHOOL",
    Address: "DAMBENI LOCATION  DAMBENI A/A  NTABANKULU",
    Latitude: "-31.0933",
    Longitude: "29.3745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4452",
    WardLookupId: "789",
    VDNumber: "11730013",
    RegisteredPopulation: "1440",
    VotingStationName: "TOWN HALL",
    Address: "85 MAIN STREET  TABANKULU VILLAGE  TABANKULU",
    Latitude: "-30.9599",
    Longitude: "29.3021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4453",
    WardLookupId: "789",
    VDNumber: "11730024",
    RegisteredPopulation: "193",
    VotingStationName: "ZWELITSHA AREA",
    Address: "NTABANKULU  MSUKENI ADMIN AREA  NTABANKULU",
    Latitude: "-30.9936",
    Longitude: "29.3244",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4454",
    WardLookupId: "789",
    VDNumber: "11820293",
    RegisteredPopulation: "1284",
    VotingStationName: "NDAKENI JUNIOR SECONDARY SCHOOL",
    Address: "NDAKENI LOCATION  ZULU ADMIN AREA  NTABANKULU",
    Latitude: "-30.9859",
    Longitude: "29.3008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4455",
    WardLookupId: "789",
    VDNumber: "11820439",
    RegisteredPopulation: "1415",
    VotingStationName: "MAKHOSINI SENIOR PRIMARY SCHOOL",
    Address: "YANDLALA LOCALITY  MBANGWENI A/A  NTABANKULU",
    Latitude: "-30.9475",
    Longitude: "29.3125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4456",
    WardLookupId: "789",
    VDNumber: "11820912",
    RegisteredPopulation: "503",
    VotingStationName: "DALINDYEBO SENIOR PRIMARY SCHOOL",
    Address: "NDAKENI LOC  MSUKENI A/A  TABANKULU",
    Latitude: "-30.9939",
    Longitude: "29.288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4457",
    WardLookupId: "790",
    VDNumber: "11820383",
    RegisteredPopulation: "671",
    VotingStationName: "NDWANE JUNIOR SECONDARY SCHOOL",
    Address: "MBANGWENI A/A  NDWANE A/A  NTABANKULU",
    Latitude: "-30.974",
    Longitude: "29.4067",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4458",
    WardLookupId: "790",
    VDNumber: "11820428",
    RegisteredPopulation: "648",
    VotingStationName: "MBANGWENI JUNIOR SECONDARY SCHOOL",
    Address: "MBANGWENI A/A  MBANGWENI LOCALITY  TABANKULU",
    Latitude: "-30.9412",
    Longitude: "29.3493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4459",
    WardLookupId: "790",
    VDNumber: "11820440",
    RegisteredPopulation: "701",
    VotingStationName: "MABUDU JUNIOR SECONDARY SCHOOL",
    Address: "LUNDINI A/A  MABUDU LOCALITY  TABANKULU",
    Latitude: "-30.8984",
    Longitude: "29.3449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4460",
    WardLookupId: "790",
    VDNumber: "11820462",
    RegisteredPopulation: "650",
    VotingStationName: "DAMBENI JUNIOR SECONDARY SCHOOL",
    Address: "NTLAMVINI A/A  DAMBENI A/A  TABANKULU",
    Latitude: "-30.9101",
    Longitude: "29.3193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4461",
    WardLookupId: "790",
    VDNumber: "11820776",
    RegisteredPopulation: "275",
    VotingStationName: "QIPHU JUNIOR SECONDARY SCHOOL.",
    Address: "NTABENI LOCATION  MBANGWENI  A/A  TABANKULU",
    Latitude: "-30.9576",
    Longitude: "29.3644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4462",
    WardLookupId: "790",
    VDNumber: "11821193",
    RegisteredPopulation: "719",
    VotingStationName: "ZWELABANTU SENIOR PRIMARY SCHOOL",
    Address: "MBANGWENI LOCATION  MBANGWENI A/A  TABANKULU",
    Latitude: "-30.9679",
    Longitude: "29.3355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4463",
    WardLookupId: "790",
    VDNumber: "11821362",
    RegisteredPopulation: "310",
    VotingStationName: "MFAZWE SENIOR SECONDARY SCHOOL",
    Address: "DAMBENI LOCATION  NTLAMVINI A/A  NTABANKULU",
    Latitude: "-30.9158",
    Longitude: "29.3296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4464",
    WardLookupId: "790",
    VDNumber: "11821429",
    RegisteredPopulation: "176",
    VotingStationName: "NDWANE SATELITE SENIOR PRIMARY SCHOOL",
    Address: "SIHLONYANENI LOCATION  MBANGWENI A/A  NTABANKULU",
    Latitude: "-30.955",
    Longitude: "29.4562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4465",
    WardLookupId: "791",
    VDNumber: "11820316",
    RegisteredPopulation: "656",
    VotingStationName: "CABA JUNIOR SECONDARY SCHOOL",
    Address: "CABA LOCATION  CABA A/A  TABANKULU",
    Latitude: "-30.9394",
    Longitude: "29.2153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4466",
    WardLookupId: "791",
    VDNumber: "11820327",
    RegisteredPopulation: "737",
    VotingStationName: "ZOKO JUNIOR SECONDARY SCHOOL",
    Address: "KWAZULU LOCATION  KWAZULU A/A  TABANKULU",
    Latitude: "-30.9471",
    Longitude: "29.2599",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4467",
    WardLookupId: "791",
    VDNumber: "11820349",
    RegisteredPopulation: "243",
    VotingStationName: "LUBALA JUNIOR SECONDARY SCHOOL",
    Address: "LUBALA LOCATION  NGWEMNYAMA A/A, NTABANKULU  TABANKULU",
    Latitude: "-30.9398",
    Longitude: "29.1887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4468",
    WardLookupId: "791",
    VDNumber: "11820484",
    RegisteredPopulation: "789",
    VotingStationName: "KHETHANI JUNIOR SECONDARY SCHOOL",
    Address: "MVENYANE LOC.  MVENYANE A/A  TABANKULU",
    Latitude: "-30.9205",
    Longitude: "29.2358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4469",
    WardLookupId: "791",
    VDNumber: "11820552",
    RegisteredPopulation: "194",
    VotingStationName: "DUMELA JUNIOR SECONDARY SCHOOL",
    Address: "MAQOYINI LOCATION  MAQOYINI A/A  TABANKULU",
    Latitude: "-30.9712",
    Longitude: "29.2543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4470",
    WardLookupId: "791",
    VDNumber: "11821025",
    RegisteredPopulation: "128",
    VotingStationName: "MZIMHLOPHE JUNIOR PRIMARY SCHOOL",
    Address: "NGWEMNYAMA LOC  NGWEMNYAMA A/A  NTABANKULU",
    Latitude: "-30.9353",
    Longitude: "29.1606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4471",
    WardLookupId: "791",
    VDNumber: "11821036",
    RegisteredPopulation: "422",
    VotingStationName: "PHUNGULELWENI SENIOR PRIMARY SCHOOL.",
    Address: "CABA A/A  PHUNGULELWENI LOCATION  NTABANKULU",
    Latitude: "-30.9386",
    Longitude: "29.1999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4472",
    WardLookupId: "791",
    VDNumber: "11821160",
    RegisteredPopulation: "203",
    VotingStationName: "PHUMZILE PRE-SCHOOL",
    Address: "MGQAGQENI LOC  MVENYANE  TABANKULU",
    Latitude: "-30.9276",
    Longitude: "29.2321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4473",
    WardLookupId: "791",
    VDNumber: "11821283",
    RegisteredPopulation: "466",
    VotingStationName: "NDAKENI PRE-SCHOOL",
    Address: "NDAKENI LOCATION  ZULU ADMIN AREA  NTABANKULU",
    Latitude: "-30.9813",
    Longitude: "29.2985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4474",
    WardLookupId: "791",
    VDNumber: "11821339",
    RegisteredPopulation: "74",
    VotingStationName: "BUNTSHENTSHE SENIOR PRIMARY SCHOOL",
    Address: "MTHONJENI LOCATION  MNGWEMNYAMA A/A  NTABANKULU",
    Latitude: "-30.9644",
    Longitude: "29.1556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4475",
    WardLookupId: "792",
    VDNumber: "11820046",
    RegisteredPopulation: "448",
    VotingStationName: "SAPHUKANDUKU JUNIOR SECONDARY SCHOOL",
    Address: "SAPHUKANDUKU LOCATION  SAPHUKANDUKU  TABANKULU",
    Latitude: "-30.841",
    Longitude: "29.17",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4476",
    WardLookupId: "792",
    VDNumber: "11820518",
    RegisteredPopulation: "515",
    VotingStationName: "MJILA JUNIOR SECONDARY SCHOOL",
    Address: "MJILA LOC  MJILA A/A  TABANKULU",
    Latitude: "-30.8501",
    Longitude: "29.1175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4477",
    WardLookupId: "792",
    VDNumber: "11820529",
    RegisteredPopulation: "449",
    VotingStationName: "TLADI JUNIOR SECONDARY SCHOOL",
    Address: "TLADI LOCATION  SAPUKANDUKU A/A  NTABANKULU",
    Latitude: "-30.8396",
    Longitude: "29.1438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4478",
    WardLookupId: "792",
    VDNumber: "11820620",
    RegisteredPopulation: "394",
    VotingStationName: "CACADU JUNIOR SECONDARY SCHOOL",
    Address: "CACADU LOC  CACADU A/A  TABANKULU",
    Latitude: "-30.9347",
    Longitude: "29.1294",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4479",
    WardLookupId: "792",
    VDNumber: "11820631",
    RegisteredPopulation: "358",
    VotingStationName: "CIDERVILLE JUNIOR SECONDARY SCHOOL",
    Address: "CIDERVILLE LOC  CIDERVILLE A/A  TABANKULU",
    Latitude: "-30.8734",
    Longitude: "29.1315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4480",
    WardLookupId: "792",
    VDNumber: "11820765",
    RegisteredPopulation: "97",
    VotingStationName: "VENI SENIOR PRIMARY SCHOOL",
    Address: "VENI LOCATION  MJILA  A/A  NTABANKULU",
    Latitude: "-30.8445",
    Longitude: "29.0756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4481",
    WardLookupId: "792",
    VDNumber: "11820990",
    RegisteredPopulation: "221",
    VotingStationName: "LUGALAKAXA SENIOR PRIMARY SCHOOL",
    Address: "MPEBA LOCATION  NGWENYAMA A/A  NTABANKULU",
    Latitude: "-30.9165",
    Longitude: "29.1",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4482",
    WardLookupId: "792",
    VDNumber: "11821003",
    RegisteredPopulation: "224",
    VotingStationName: "SIYAYA JUNIOR SECONDARY SCHOOL",
    Address: "SIYAYA LOCATION  NGWEMNYAMA A/A  NTABANKULU",
    Latitude: "-30.9012",
    Longitude: "29.131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4483",
    WardLookupId: "792",
    VDNumber: "11821014",
    RegisteredPopulation: "275",
    VotingStationName: "MPINDWENI JUNIOR SECONDARY SCHOOL",
    Address: "SKHULU LOCATION  NGWEMNYAMA A/A  NTABANKULU",
    Latitude: "-30.8908",
    Longitude: "29.1",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4484",
    WardLookupId: "792",
    VDNumber: "11821047",
    RegisteredPopulation: "247",
    VotingStationName: "BAGQOZINI JUNIOR SECONDARY SCHOOL",
    Address: "BAGQOZINI LOCATION  LOWER MNCEBA A/A  NTABANKULU",
    Latitude: "-30.9204",
    Longitude: "29.1579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4485",
    WardLookupId: "792",
    VDNumber: "11821351",
    RegisteredPopulation: "336",
    VotingStationName: "LUFAFA SENIOR PRIMARY SCHOOL",
    Address: "LUFAFA LOCATION  UMZIMVUBU A//A  NTABANKULU",
    Latitude: "-30.8712",
    Longitude: "29.1174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4486",
    WardLookupId: "793",
    VDNumber: "11790446",
    RegisteredPopulation: "617",
    VotingStationName: "METHODIST CHURCH",
    Address: "NDLANTAKA LOCATION  LUNDINI A/A  NTABANKULU",
    Latitude: "-30.8864",
    Longitude: "29.2661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4487",
    WardLookupId: "793",
    VDNumber: "11820338",
    RegisteredPopulation: "439",
    VotingStationName: "LOWER MNCEBA JUNIOR SECONDARY SCHOOL",
    Address: "TSITA LOCATION  LOWER MNCEBA A/A  TABANKULU",
    Latitude: "-30.9246",
    Longitude: "29.1867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4488",
    WardLookupId: "793",
    VDNumber: "11820507",
    RegisteredPopulation: "860",
    VotingStationName: "NDLANTAKA JUNIOR SECONDARY SCHOOL",
    Address: "MVENYANE A/A  MZALANENI LOCATION  TABANKULU",
    Latitude: "-30.8857",
    Longitude: "29.2476",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4489",
    WardLookupId: "793",
    VDNumber: "11820530",
    RegisteredPopulation: "793",
    VotingStationName: "MNCEBA JUNIOR SECONDARY SCHOOL",
    Address: "MNCEBA LOC  MVENYANE A/A  TABANKULU",
    Latitude: "-30.8927",
    Longitude: "29.2097",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4490",
    WardLookupId: "793",
    VDNumber: "11820787",
    RegisteredPopulation: "244",
    VotingStationName: "MJELWENI JUNIOR SECONDARY SCHOOL",
    Address: "MJELWENI LOC  SAPHUKANDUKU A/A  TABANKULU",
    Latitude: "-30.8584",
    Longitude: "29.1901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4491",
    WardLookupId: "793",
    VDNumber: "11820798",
    RegisteredPopulation: "159",
    VotingStationName: "NTSHAMANZI SENIOR PRIMARY SCHOOL",
    Address: "NTSHAMANZI LOCATION  NTLAMVINI A/A  NTABANKULU",
    Latitude: "-30.8833",
    Longitude: "29.17",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4492",
    WardLookupId: "793",
    VDNumber: "11821159",
    RegisteredPopulation: "475",
    VotingStationName: "ZIZAMELE PRE-SCHOOL",
    Address: "MNCEBA LOCATIO  MNCEBA  TABANKULU",
    Latitude: "-30.911",
    Longitude: "29.2145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4493",
    WardLookupId: "794",
    VDNumber: "11820451",
    RegisteredPopulation: "384",
    VotingStationName: "NGQWASHU JUNIOR SECONDARY SCHOOL",
    Address: "LUNDINI A/A  NGQWASHU LOCALITY  TABANKULU",
    Latitude: "-30.8729",
    Longitude: "29.31",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4494",
    WardLookupId: "794",
    VDNumber: "11820473",
    RegisteredPopulation: "769",
    VotingStationName: "BONXA JUNIOR SECONDARY SCHOOL",
    Address: "BONXA LOCATION  BHONXA A/A  TABANKULU",
    Latitude: "-30.9189",
    Longitude: "29.2645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4495",
    WardLookupId: "794",
    VDNumber: "11820495",
    RegisteredPopulation: "578",
    VotingStationName: "BAKUBA JUNIOR SECONDARY SCHOOL",
    Address: "LUNDINI A/A  BAKUBA A/A  TABANKULU",
    Latitude: "-30.8922",
    Longitude: "29.2822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4496",
    WardLookupId: "794",
    VDNumber: "11820710",
    RegisteredPopulation: "729",
    VotingStationName: "LUCINGWENI COMMUNITY HALL",
    Address: "(EC152 - NTABANKULU [NTABANKULU])   ",
    Latitude: "-30.8787",
    Longitude: "29.2869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4497",
    WardLookupId: "794",
    VDNumber: "11820743",
    RegisteredPopulation: "477",
    VotingStationName: "MAFU SENIOR PRIMARY SCHOOL",
    Address: "BONXA LOC  NTLAMVINI A/A  NTABANKULU",
    Latitude: "-30.922",
    Longitude: "29.2859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4498",
    WardLookupId: "794",
    VDNumber: "11820754",
    RegisteredPopulation: "682",
    VotingStationName: "KUYASA PRE-SCHOOL",
    Address: "BONXA LOCATION  LUNDINI A/A  NTABANKULU",
    Latitude: "-30.9282",
    Longitude: "29.2813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4499",
    WardLookupId: "794",
    VDNumber: "11821205",
    RegisteredPopulation: "713",
    VotingStationName: "NTABANKULU JUNIOR SECONDARY SCHOOL",
    Address: "NTABANKULU VILLAGE  NTABANKULU",
    Latitude: "-30.9542",
    Longitude: "29.3068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4500",
    WardLookupId: "794",
    VDNumber: "11821272",
    RegisteredPopulation: "324",
    VotingStationName: "NKALENI (TENT)",
    Address: "BAKUBA LOCATION  LUNDINI ADMINISTRATIVE AREA  NTABANKULU",
    Latitude: "-30.9004",
    Longitude: "29.2828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4501",
    WardLookupId: "794",
    VDNumber: "11821474",
    RegisteredPopulation: "166",
    VotingStationName: "JERUSALEM APOSTOLIC CHURCH",
    Address: "NTABANKULU  NTLAMVINI ADMIN AREA  NTABANKULU",
    Latitude: "-30.9144",
    Longitude: "29.305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4502",
    WardLookupId: "795",
    VDNumber: "11810012",
    RegisteredPopulation: "558",
    VotingStationName: "TONTI JUNIOR SECONDARY SCHOOL",
    Address: "TONTI LOCATION  TONTI A/A  NTABANKULU",
    Latitude: "-30.8673",
    Longitude: "29.439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4503",
    WardLookupId: "795",
    VDNumber: "11820417",
    RegisteredPopulation: "537",
    VotingStationName: "NGQANI JUNIOR SECONDARY SCHOOL",
    Address: "NQANI LOC  LWANDLANA A/A  TABANKULU",
    Latitude: "-30.9068",
    Longitude: "29.4202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4504",
    WardLookupId: "795",
    VDNumber: "11820563",
    RegisteredPopulation: "408",
    VotingStationName: "HLANKOMO JUNIOR SECONDARY SCHOOL",
    Address: "HLANKOMO A/A  TABANKULU",
    Latitude: "-30.909",
    Longitude: "29.362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4505",
    WardLookupId: "795",
    VDNumber: "11820653",
    RegisteredPopulation: "388",
    VotingStationName: "MOWA JUNIOR SECONDARY SCHOOL",
    Address: "MOWA A/A  MOWA LOCALITY  TABANKULU",
    Latitude: "-30.879",
    Longitude: "29.3467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4506",
    WardLookupId: "795",
    VDNumber: "11820721",
    RegisteredPopulation: "293",
    VotingStationName: "NGONYAMENI SENIOR PRIMARY SCHOOL",
    Address: "NGONYAMENI LOCATION  NTLAMVINI A/A  NTABANKULU",
    Latitude: "-30.9266",
    Longitude: "29.3969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4507",
    WardLookupId: "795",
    VDNumber: "11820732",
    RegisteredPopulation: "335",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "NTABENI LOCATION  NTLAMVINI A/A  NTABANKULU",
    Latitude: "-30.935",
    Longitude: "29.4526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4508",
    WardLookupId: "795",
    VDNumber: "11820800",
    RegisteredPopulation: "245",
    VotingStationName: "MZAWUTHETHI SENIOR PRIMARY SCHOOL",
    Address: "NCAMA  LOCATION  MBANGWENI A/A  NTABANKULU",
    Latitude: "-30.9195",
    Longitude: "29.367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4509",
    WardLookupId: "795",
    VDNumber: "11821148",
    RegisteredPopulation: "420",
    VotingStationName: "SOLOMON GAGANE SENIOR PRIMARY SCHOOL",
    Address: "BHUNGENI LOCATION  LWANDLANA A/A  TABANKULU",
    Latitude: "-30.8995",
    Longitude: "29.4454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4510",
    WardLookupId: "795",
    VDNumber: "11821261",
    RegisteredPopulation: "271",
    VotingStationName: "VULINDLELA SENIOR PRIMARY SCHOOL",
    Address: "VULINDLELA LOCATION  MZINTLAVA A/A  NTABANKULU",
    Latitude: "-30.8531",
    Longitude: "29.3626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4511",
    WardLookupId: "796",
    VDNumber: "11810023",
    RegisteredPopulation: "583",
    VotingStationName: "WAYO JUNIOR SECONDARY SCHOOL",
    Address: "WAYO LOCATION  MARAMZENI A/A  TABANKULU",
    Latitude: "-30.8651",
    Longitude: "29.4534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4512",
    WardLookupId: "796",
    VDNumber: "11810045",
    RegisteredPopulation: "609",
    VotingStationName: "CETSHEJUNIOR SECONDARY SCHOOL",
    Address: "CETSHE LOCATION  CETSHE A/A  TABANKULU",
    Latitude: "-30.8644",
    Longitude: "29.536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4513",
    WardLookupId: "796",
    VDNumber: "11810056",
    RegisteredPopulation: "778",
    VotingStationName: "MFUNDISWENI COLLEGE",
    Address: "MFUNDISWENI A/A  NTABANKULU",
    Latitude: "-30.8966",
    Longitude: "29.5271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4514",
    WardLookupId: "796",
    VDNumber: "11810067",
    RegisteredPopulation: "617",
    VotingStationName: "MABOFU JUNIOR SECONDARY SCHOOL",
    Address: "MABOFU LOCATION  MFUNDISWENI A/A  FLAGSTAFF",
    Latitude: "-30.8961",
    Longitude: "29.4658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4515",
    WardLookupId: "796",
    VDNumber: "11810078",
    RegisteredPopulation: "441",
    VotingStationName: "MAZENI JUNIOR SECONDARY SCHOOL",
    Address: "MABOFU A/A  MAZENI LOC.  FLAGSTAFF",
    Latitude: "-30.8778",
    Longitude: "29.4963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4516",
    WardLookupId: "796",
    VDNumber: "11810786",
    RegisteredPopulation: "659",
    VotingStationName: "DAKILE SENIOR PRIMARY SCHOOL",
    Address: "TONTI LOCATION  TONTI A/A  NTABANKULU",
    Latitude: "-30.9084",
    Longitude: "29.5238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4517",
    WardLookupId: "796",
    VDNumber: "11810809",
    RegisteredPopulation: "283",
    VotingStationName: "THEMBILE JUNIOR SECONDARY SCHOOL",
    Address: "MAZENI LOCATION  MAZENI A/A  NTABANKULU",
    Latitude: "-30.8775",
    Longitude: "29.4655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4518",
    WardLookupId: "797",
    VDNumber: "11790165",
    RegisteredPopulation: "447",
    VotingStationName: "MBHONGWENI JUNIOR SECONDARY SCHOOL",
    Address: "MBOMNGENI LOCATION  MBHONGWENI A/A  TABANKULU",
    Latitude: "-30.7959",
    Longitude: "29.486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4519",
    WardLookupId: "797",
    VDNumber: "11790200",
    RegisteredPopulation: "363",
    VotingStationName: "DEDELO JUNIOR SECONDARY SCHOOL",
    Address: "DEDELO A/A  DEDELO LOCATION  MOUNT AYLIFF",
    Latitude: "-30.8184",
    Longitude: "29.4374",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4520",
    WardLookupId: "797",
    VDNumber: "11790457",
    RegisteredPopulation: "322",
    VotingStationName: "KWANTULI ST JOHNS APOSTOLIC CHURCH",
    Address: "NTABANKULU  LOKWE A/A  KWANTULI LOCATION",
    Latitude: "-30.7891",
    Longitude: "29.4562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4521",
    WardLookupId: "797",
    VDNumber: "11790468",
    RegisteredPopulation: "328",
    VotingStationName: "PRESBYTERIAN CHURCH",
    Address: "MBONGWENI LOCATION  MBONGWENI A/A  NTABANKULU",
    Latitude: "-30.8031",
    Longitude: "29.497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4522",
    WardLookupId: "797",
    VDNumber: "11790480",
    RegisteredPopulation: "170",
    VotingStationName: "IBISI PRE-SCHOOL",
    Address: "IBISI PRE- SCHOOL  XAMA A/A  NTABANKULU",
    Latitude: "-30.7667",
    Longitude: "29.5041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4523",
    WardLookupId: "797",
    VDNumber: "11790514",
    RegisteredPopulation: "485",
    VotingStationName: "LUNCEDWENI   JUNIOR   SECONDARY   SCHOOL",
    Address: "LUNCEDWENI LOCATION  DEDELO A/A  NTABANKULU",
    Latitude: "-30.8301",
    Longitude: "29.4311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4524",
    WardLookupId: "797",
    VDNumber: "11790682",
    RegisteredPopulation: "263",
    VotingStationName: "MKHOMANZI JUNIOR PRIMARY SCHOOL",
    Address: "MKHOMANZI LOCATION  NTABANKULU  NTABANKULU",
    Latitude: "-30.7982",
    Longitude: "29.5394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4525",
    WardLookupId: "797",
    VDNumber: "11810034",
    RegisteredPopulation: "501",
    VotingStationName: "LUNZWANA JUNIOR SECONDARY SCHOOL",
    Address: "BIPHA LOCATION  BIPHA A/A  NTABANKULU",
    Latitude: "-30.8413",
    Longitude: "29.5163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4526",
    WardLookupId: "797",
    VDNumber: "11810775",
    RegisteredPopulation: "453",
    VotingStationName: "BUHLAMBO JUNIOR SECONDARY SCHOOL",
    Address: "BUHLAMBO LOCATION  TONTI A/A  NTABANKULU",
    Latitude: "-30.8391",
    Longitude: "29.4808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4527",
    WardLookupId: "797",
    VDNumber: "11811237",
    RegisteredPopulation: "338",
    VotingStationName: "DLEPHU JUNIOR SECONDARY SCHOOL",
    Address: "DLEPHU LOCATION  BIPHA A/A  NTABANKULU",
    Latitude: "-30.8394",
    Longitude: "29.537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4528",
    WardLookupId: "798",
    VDNumber: "11790233",
    RegisteredPopulation: "293",
    VotingStationName: "MANZANA JUNIOR SECONDARY SCHOOL",
    Address: "MANZANA LOC  MANZANA A/A  MOUNT AYLIFF",
    Latitude: "-30.7302",
    Longitude: "29.4655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4529",
    WardLookupId: "798",
    VDNumber: "11790244",
    RegisteredPopulation: "609",
    VotingStationName: "GXWALENI JUNIOR SECONDARY SCHOOL",
    Address: "GXWALENI A/A  TABANKULU",
    Latitude: "-30.7464",
    Longitude: "29.5358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4530",
    WardLookupId: "798",
    VDNumber: "11790299",
    RegisteredPopulation: "672",
    VotingStationName: "SIPETU JUNIOR SECONDARY SCHOOL",
    Address: "SIPETU LOCATION  MBHONGWENI A/A  NTABANKULU",
    Latitude: "-30.78",
    Longitude: "29.5373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4531",
    WardLookupId: "798",
    VDNumber: "11790378",
    RegisteredPopulation: "486",
    VotingStationName: "NTSHENTSHE JUNIOR SECONDARY SCHOOL",
    Address: "NTSHENTSHE A/A  TABANKULU",
    Latitude: "-30.7078",
    Longitude: "29.5314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4532",
    WardLookupId: "798",
    VDNumber: "11790424",
    RegisteredPopulation: "295",
    VotingStationName: "ZANOKHANYO JUNIOR SECONDARY SCHOOL",
    Address: "LOKWE LOCATION  MPELAZWE A/A  TABANKULU",
    Latitude: "-30.7057",
    Longitude: "29.4979",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4533",
    WardLookupId: "798",
    VDNumber: "11790435",
    RegisteredPopulation: "411",
    VotingStationName: "LOKWE JUNIOR SECONDARY SCHOOL",
    Address: "LOKWE LOCATION  NTABANKULU",
    Latitude: "-30.7754",
    Longitude: "29.4605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4534",
    WardLookupId: "798",
    VDNumber: "11790479",
    RegisteredPopulation: "403",
    VotingStationName: "ZOLA SENIOR PRIMARY SCHOOL",
    Address: "MBHONGWENI LOCATION  MBHONGWENI A/A  NTABANKULU",
    Latitude: "-30.762",
    Longitude: "29.5521",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4535",
    WardLookupId: "798",
    VDNumber: "11790491",
    RegisteredPopulation: "385",
    VotingStationName: "JAKUJA JUNIOR SECONDARY SCHOOL",
    Address: "MANXONTSENI LOCATION  XAMA A/A  NTABANKULU",
    Latitude: "-30.7581",
    Longitude: "29.4707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4536",
    WardLookupId: "798",
    VDNumber: "11790503",
    RegisteredPopulation: "175",
    VotingStationName: "MALIWA JUNIOR SECONDARY SCHOOL",
    Address: "MANZANA LOCATION  MANZANA A/A  NTABANKULU",
    Latitude: "-30.7309",
    Longitude: "29.5012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4537",
    WardLookupId: "4349",
    VDNumber: "10250019",
    RegisteredPopulation: "5991",
    VotingStationName: "OCEAN SCIENCE CAMPUS",
    Address: "GOMERY AVENUE  SUMMERSTRAND  PORT ELIZABETH",
    Latitude: "-34.0001",
    Longitude: "25.6665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4538",
    WardLookupId: "4349",
    VDNumber: "10250064",
    RegisteredPopulation: "5031",
    VotingStationName: "MOUNT PLEASANT PRIMARY SCHOOL",
    Address: "40 MILNER STREET  PROVIDENTIA  PORT ELIZABETH",
    Latitude: "-34.0001",
    Longitude: "25.5369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4539",
    WardLookupId: "4349",
    VDNumber: "10460089",
    RegisteredPopulation: "1232",
    VotingStationName: "RIVERSIDE TENT",
    Address: "KRAGGA KAMMA ROAD  COLLEEN GLEN  PORT ELIZABETH",
    Latitude: "-33.9795",
    Longitude: "25.4188",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4540",
    WardLookupId: "4349",
    VDNumber: "10460090",
    RegisteredPopulation: "1618",
    VotingStationName: "ELUKHOLWENI FARM SCHOOL",
    Address: "SEAVIEW ROAD  LOVEMORE PARK AREA  PORT ELIZABETH",
    Latitude: "-34.0101",
    Longitude: "25.5064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4541",
    WardLookupId: "4350",
    VDNumber: "10250020",
    RegisteredPopulation: "4938",
    VotingStationName: "PORTUGUESE CLUB",
    Address: "7TH AVENUE  SUMMERSTRAND  PORT ELIZABETH",
    Latitude: "-33.991",
    Longitude: "25.6618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4542",
    WardLookupId: "4350",
    VDNumber: "10251087",
    RegisteredPopulation: "2272",
    VotingStationName: "KINGS BEACH LIFE SAVERS CLUB",
    Address: "BEACH ROAD  HUMEWOOD  PORT ELIZABETH",
    Latitude: "-33.9727",
    Longitude: "25.6458",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4543",
    WardLookupId: "4350",
    VDNumber: "10251098",
    RegisteredPopulation: "3083",
    VotingStationName: "ACVV CENTRE",
    Address: "1 HILTON CRESCENT  FOREST HILL  PORT ELIZABETH",
    Latitude: "-33.9803",
    Longitude: "25.6317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4544",
    WardLookupId: "4351",
    VDNumber: "10250086",
    RegisteredPopulation: "4673",
    VotingStationName: "BUFFELSFONTEIN OLD AGE HOME",
    Address: "17TH AVENUE  WALMER  PORT ELIZABETH",
    Latitude: "-33.9889",
    Longitude: "25.5541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4545",
    WardLookupId: "4351",
    VDNumber: "10250097",
    RegisteredPopulation: "3702",
    VotingStationName: "WALMER TOWN HALL",
    Address: "MAIN ROAD  WALMER  PORT ELIZABETH",
    Latitude: "-33.9776",
    Longitude: "25.5853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4546",
    WardLookupId: "4351",
    VDNumber: "10251100",
    RegisteredPopulation: "2395",
    VotingStationName: "GREENWOOD PRIMARY SCHOOL",
    Address: "PARK DRIVE  CENTRAL  PORT ELIZABETH",
    Latitude: "-33.9684",
    Longitude: "25.6108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4547",
    WardLookupId: "4351",
    VDNumber: "10251661",
    RegisteredPopulation: "1294",
    VotingStationName: "SETTLERS PARK PRIMARY SCHOOL",
    Address: "4TH AVENUE  WALMER  NELSON MANDELA [PORT ELIZABETH]",
    Latitude: "-33.9746",
    Longitude: "25.6017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4548",
    WardLookupId: "4352",
    VDNumber: "10250031",
    RegisteredPopulation: "4244",
    VotingStationName: "NEW LIFE CHRISTIAN CHURCH",
    Address: "MANKAZANA STREET  WALMER LOCATION  NELSON MANDELA METRO",
    Latitude: "-33.995",
    Longitude: "25.5858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4549",
    WardLookupId: "4352",
    VDNumber: "10250042",
    RegisteredPopulation: "3915",
    VotingStationName: "JOHN MASIZA HIGHER PRIMARY SCHOOL",
    Address: "2 MTYOBO STREET  GQEBERA TOWNSHIP,  PORT ELIZABETH",
    Latitude: "-33.99",
    Longitude: "25.5875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4550",
    WardLookupId: "4352",
    VDNumber: "10250053",
    RegisteredPopulation: "3586",
    VotingStationName: "WALMER LOWER PRIMARY SCHOOL",
    Address: "1 YOYO STREET  GQEBERA TOWNSHIP  PORT ELIZABETH",
    Latitude: "-33.9857",
    Longitude: "25.5926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4551",
    WardLookupId: "4353",
    VDNumber: "10250244",
    RegisteredPopulation: "3323",
    VotingStationName: "LAWSON BROWN HIGH SCHOOL",
    Address: "ILLINGWORTH ST  MILLARD GRANGE  PORT ELIZABETH",
    Latitude: "-33.9487",
    Longitude: "25.5994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4552",
    WardLookupId: "4353",
    VDNumber: "10251111",
    RegisteredPopulation: "2523",
    VotingStationName: "CITY HALL",
    Address: "GOVAN MBEKI AVENUE  CENTRAL  PORT ELIZABETH",
    Latitude: "-33.9626",
    Longitude: "25.6236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4553",
    WardLookupId: "4353",
    VDNumber: "10251122",
    RegisteredPopulation: "4148",
    VotingStationName: "WESTBOURNE OVAL HALL",
    Address: "WESTBOURNE ROAD  CENTRAL  PORT ELIZABETH",
    Latitude: "-33.9611",
    Longitude: "25.609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4554",
    WardLookupId: "4354",
    VDNumber: "10250075",
    RegisteredPopulation: "4114",
    VotingStationName: "CHARLO PRIMARY SCHOOL",
    Address: "MILES RD  CHARLO  PORT ELIZABETH",
    Latitude: "-33.9853",
    Longitude: "25.5386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4555",
    WardLookupId: "4354",
    VDNumber: "10250109",
    RegisteredPopulation: "3783",
    VotingStationName: "MTR SMIT CHILDRENS HOME",
    Address: "CIRCULAR DRIVE  WALMER  PORT ELIZABETH",
    Latitude: "-33.9789",
    Longitude: "25.5509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4556",
    WardLookupId: "4354",
    VDNumber: "10250121",
    RegisteredPopulation: "3214",
    VotingStationName: "LORRAINE METHODIST CHURCH OF AFRICA",
    Address: "LUNEVILLE ROAD  LORRAINE  PORT ELIZABETH",
    Latitude: "-33.9695",
    Longitude: "25.5173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4557",
    WardLookupId: "4354",
    VDNumber: "10251672",
    RegisteredPopulation: "1447",
    VotingStationName: "LONDT PARK CLUB",
    Address: "RALSTEN ROAD  FERNGLEN  NELSON MANDELA [PORT ELIZABETH]",
    Latitude: "-33.9501",
    Longitude: "25.5387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4558",
    WardLookupId: "4355",
    VDNumber: "10250222",
    RegisteredPopulation: "3405",
    VotingStationName: "NEWTON PARK LIBRARY",
    Address: "FOURTH AVENUE  NEWTON PARK  PORT ELIZABETH",
    Latitude: "-33.9497",
    Longitude: "25.5651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4559",
    WardLookupId: "4355",
    VDNumber: "10250233",
    RegisteredPopulation: "3465",
    VotingStationName: "NEWTON TECHNICAL HIGH SCHOOL",
    Address: "92 FIRST AVENUE  NEWTON PARK  PORT ELIZABETH",
    Latitude: "-33.9571",
    Longitude: "25.5728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4560",
    WardLookupId: "4355",
    VDNumber: "10250255",
    RegisteredPopulation: "1391",
    VotingStationName: "ALEXANDER ROAD HIGH SCHOOL",
    Address: "VAN DER VYVER  NEWTON PARK  PORT ELIZABETH",
    Latitude: "-33.9416",
    Longitude: "25.572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4561",
    WardLookupId: "4355",
    VDNumber: "10250277",
    RegisteredPopulation: "1693",
    VotingStationName: "SAM ARENDS HALL",
    Address: "1 GUMPERT STREET  SCHAUDERVILLE  PORT ELIZABETH",
    Latitude: "-33.934",
    Longitude: "25.5708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4562",
    WardLookupId: "4355",
    VDNumber: "10251144",
    RegisteredPopulation: "3103",
    VotingStationName: "MOREWAG PRIMARY SCHOOL",
    Address: "MARY BOYD AVENUE  KENSINGTON  PORT ELIZABETH",
    Latitude: "-33.94",
    Longitude: "25.5884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4563",
    WardLookupId: "4356",
    VDNumber: "10250110",
    RegisteredPopulation: "4876",
    VotingStationName: "P.E RIDING HORSE CLUB",
    Address: "BERGURES  LORRAINE  PORT ELIZABETH",
    Latitude: "-33.9795",
    Longitude: "25.5233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4564",
    WardLookupId: "4356",
    VDNumber: "10250143",
    RegisteredPopulation: "3521",
    VotingStationName: "CHRISTIAN LIFE CENTRE",
    Address: "GLENROY DRIVE  GLENROY PARK  PORT ELIZABETH",
    Latitude: "-33.9555",
    Longitude: "25.5029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4565",
    WardLookupId: "4356",
    VDNumber: "10251683",
    RegisteredPopulation: "1942",
    VotingStationName: "LORRAINE PRIMARY SCHOOL",
    Address: "NELSON MANDELA [PORT ELIZABETH]  ",
    Latitude: "-33.9702",
    Longitude: "25.5107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4566",
    WardLookupId: "4356",
    VDNumber: "10251919",
    RegisteredPopulation: "1894",
    VotingStationName: "EMMANUELLE CHURCH",
    Address: "31 MACON ROAD  LORRAINE  PORT ELIZABETH",
    Latitude: "-33.9721",
    Longitude: "25.507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4567",
    WardLookupId: "4357",
    VDNumber: "10250176",
    RegisteredPopulation: "3934",
    VotingStationName: "SUNRIDGE PARK TENT",
    Address: "TULIP AVENUE,SUNRIDGE PARK  SUNRIDGE PARK  PORT ELIZABETH",
    Latitude: "-33.9521",
    Longitude: "25.5314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4568",
    WardLookupId: "4357",
    VDNumber: "10250198",
    RegisteredPopulation: "4212",
    VotingStationName: "WESTERING SCHOOL",
    Address:
      "PAPENKUIL STREET  NELSON MANDELA [PORT ELIZABETH]  NELSON MANDELA [PORT ELIZABETH]",
    Latitude: "-33.9368",
    Longitude: "25.5246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4569",
    WardLookupId: "4357",
    VDNumber: "10251694",
    RegisteredPopulation: "2607",
    VotingStationName: "VERKENNER PRIMARY SCHOOL",
    Address: "NELSON MANDELA [PORT ELIZABETH]  ",
    Latitude: "-33.9415",
    Longitude: "25.5134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4570",
    WardLookupId: "4358",
    VDNumber: "10250299",
    RegisteredPopulation: "2427",
    VotingStationName: "DAVID LIVINGSTONE SENIOR SECONDARY SCHOOL",
    Address: "KOHLBERG STR  SCHAUDERVILLE  PORT ELIZABETH",
    Latitude: "-33.9298",
    Longitude: "25.5636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4571",
    WardLookupId: "4358",
    VDNumber: "10250301",
    RegisteredPopulation: "3503",
    VotingStationName: "ST MARTINS DE PORRES CATHOLIC CHURCH HALL",
    Address: "KOBUS ROAD  GELVANDALE  PORT ELIZABETH",
    Latitude: "-33.9205",
    Longitude: "25.5573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4572",
    WardLookupId: "4358",
    VDNumber: "10250312",
    RegisteredPopulation: "2513",
    VotingStationName: "GELVANDALE COMMUNITY HALL",
    Address: "LIEBENBERG ROAD  GELVANDALE  PORT ELIZABETH",
    Latitude: "-33.9127",
    Longitude: "25.5583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4573",
    WardLookupId: "4358",
    VDNumber: "10250334",
    RegisteredPopulation: "2997",
    VotingStationName: "MORAVIAN YOUTH CENTRE",
    Address: "SPRINGBOK STREET  GELVANDALE  PORT ELIZABETH",
    Latitude: "-33.9171",
    Longitude: "25.5483",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4574",
    WardLookupId: "4359",
    VDNumber: "10250266",
    RegisteredPopulation: "3194",
    VotingStationName: "JARMAN HALL",
    Address: "HIGHFIELD ROAD  SCHAUDERVILLE  PORT ELIZABETH",
    Latitude: "-33.9336",
    Longitude: "25.5761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4575",
    WardLookupId: "4359",
    VDNumber: "10250288",
    RegisteredPopulation: "1967",
    VotingStationName: "DE VOS MALAN SCHOOL",
    Address: "DINSMORE ROAD  SCHAUDERVILLE  PORT ELIZABETH",
    Latitude: "-33.9295",
    Longitude: "25.5665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4576",
    WardLookupId: "4359",
    VDNumber: "10251177",
    RegisteredPopulation: "1831",
    VotingStationName: "PIET RETIEF PRIMARY SCHOOL",
    Address: "SUTTON ROAD  SIDWELL  PORT ELIZABETH",
    Latitude: "-33.917",
    Longitude: "25.5906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4577",
    WardLookupId: "4359",
    VDNumber: "10251199",
    RegisteredPopulation: "1794",
    VotingStationName: "DIAZ PRIMARY SCHOOL",
    Address: "BANBURY STREET  ALGOA PARK  PORT ELIZABETH",
    Latitude: "-33.9061",
    Longitude: "25.567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4578",
    WardLookupId: "4360",
    VDNumber: "10250187",
    RegisteredPopulation: "4336",
    VotingStationName: "MORNINGSIDE HIGH SCHOOL",
    Address: "TOPAZ ROAD  MORNINGSIDE  PORT ELIZABETH",
    Latitude: "-33.9349",
    Longitude: "25.5045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4579",
    WardLookupId: "4360",
    VDNumber: "10250200",
    RegisteredPopulation: "2188",
    VotingStationName: "MOREGROVE PRIMARY SCHOOL",
    Address: "LOERIE STREET  COTSWOLD EXT  PORT ELIZABETH",
    Latitude: "-33.9394",
    Longitude: "25.5464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4580",
    WardLookupId: "4360",
    VDNumber: "10250323",
    RegisteredPopulation: "2273",
    VotingStationName: "ALPHA PRIMARY SCHOOL",
    Address: "BELL ROAD  GELVANDALE  PORT ELIZABETH",
    Latitude: "-33.9122",
    Longitude: "25.5482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4581",
    WardLookupId: "4360",
    VDNumber: "10250345",
    RegisteredPopulation: "4694",
    VotingStationName: "MALABAR COMMUNITY CENTRE",
    Address: "HAWORTHIA DRIVE  MALABAR  PORT ELIZABETH",
    Latitude: "-33.9213",
    Longitude: "25.5325",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4582",
    WardLookupId: "4361",
    VDNumber: "10250356",
    RegisteredPopulation: "2330",
    VotingStationName: "BAYVIEW PRIMARY SCHOOL",
    Address: "POTT STREET  HELENVALE  PORT ELIZABETH",
    Latitude: "-33.9077",
    Longitude: "25.5523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4583",
    WardLookupId: "4361",
    VDNumber: "10250367",
    RegisteredPopulation: "2424",
    VotingStationName: "HELENVALE PRIMARY SCHOOL",
    Address: "CNRKOBUS STREET& LEITH STREET  HELENVALE  PORT ELIZABETH",
    Latitude: "-33.9069",
    Longitude: "25.5477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4584",
    WardLookupId: "4361",
    VDNumber: "10250378",
    RegisteredPopulation: "2177",
    VotingStationName: "HILLCREST PRIMARY SCHOOL",
    Address: "CNR ETHEL & CHARMOIS ST  HELENVALE  PORT ELIZABETH",
    Latitude: "-33.904",
    Longitude: "25.5452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4585",
    WardLookupId: "4361",
    VDNumber: "10250389",
    RegisteredPopulation: "2510",
    VotingStationName: "WORD OF LIGHT CHRISTIAN WORKS",
    Address: "VIRGO STREET  BARCELONA/ HELENVALE  PORT ELIZABETH",
    Latitude: "-33.909",
    Longitude: "25.5406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4586",
    WardLookupId: "4362",
    VDNumber: "10251201",
    RegisteredPopulation: "1266",
    VotingStationName: "NEW BRIGHTON RENT OFFICE",
    Address: "NTSHEKISA ROAD  NEW BRIGTHON  PORT ELIZABETH",
    Latitude: "-33.9062",
    Longitude: "25.5853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4587",
    WardLookupId: "4362",
    VDNumber: "10251212",
    RegisteredPopulation: "2176",
    VotingStationName: "COWAN HIGH SCHOOL",
    Address: "MADALA STREET  NEW BRIGHTON  PORT ELIZABETH",
    Latitude: "-33.9042",
    Longitude: "25.5922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4588",
    WardLookupId: "4362",
    VDNumber: "10251234",
    RegisteredPopulation: "1923",
    VotingStationName: "PENDLA PRIMARY SCHOOL",
    Address: "NTSHEKISA RD  NEW BRIGHTON  PORT ELIZABETH",
    Latitude: "-33.9008",
    Longitude: "25.5877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4589",
    WardLookupId: "4362",
    VDNumber: "10251245",
    RegisteredPopulation: "2556",
    VotingStationName: "NEWELL HIGH SCHOOL",
    Address: "JOLOBE STREET  NEW BRIGHTON  PORT ELIZABETH",
    Latitude: "-33.8991",
    Longitude: "25.5956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4590",
    WardLookupId: "4362",
    VDNumber: "10251706",
    RegisteredPopulation: "770",
    VotingStationName: "CENTENARY HALL",
    Address: "NTSHEKISA ROAD  NEW BRIGTHON  PORT ELIZABETH",
    Latitude: "-33.8951",
    Longitude: "25.592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4591",
    WardLookupId: "4362",
    VDNumber: "10251717",
    RegisteredPopulation: "1418",
    VotingStationName: "MOLEFE PRIMARY SCHOOL",
    Address: "CONNACHER STREET  NEW BRIGHTON  PORT ELIZABETH",
    Latitude: "-33.9073",
    Longitude: "25.591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4592",
    WardLookupId: "4363",
    VDNumber: "10251155",
    RegisteredPopulation: "3425",
    VotingStationName: "SYDENHAM PRIMARY SCHOOL",
    Address: "PRINCE ALFRED ROAD  SYDENHAM  PORT ELIZABETH",
    Latitude: "-33.9367",
    Longitude: "25.6025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4593",
    WardLookupId: "4363",
    VDNumber: "10251223",
    RegisteredPopulation: "2840",
    VotingStationName: "JARVIS GQAMLANA PRIMARY SCHOOL",
    Address: "SINGAPI STREET  NEW BRIGHTON  PORT ELIZABETH",
    Latitude: "-33.9034",
    Longitude: "25.6035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4594",
    WardLookupId: "4363",
    VDNumber: "10251256",
    RegisteredPopulation: "2277",
    VotingStationName: "EDWARD KOEK METHODIST CHURCH",
    Address: "YAYA  KWAZAKHELE  PORT ELIZABETH",
    Latitude: "-33.898",
    Longitude: "25.6024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4595",
    WardLookupId: "4364",
    VDNumber: "10251267",
    RegisteredPopulation: "1583",
    VotingStationName: "J Y HLISO MEMORIAL CHURCH",
    Address: "COR MHLABA STREET AND AVENUE B  NEW BRIGHTON  PORT ELIZABETH",
    Latitude: "-33.896",
    Longitude: "25.6022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4596",
    WardLookupId: "4364",
    VDNumber: "10251324",
    RegisteredPopulation: "1573",
    VotingStationName: "BEN SINUKA PRIMARY SCHOOL",
    Address: "GUNGULUZA STREET  NEW BRIGHTON  PORT ELIZABETH",
    Latitude: "-33.8922",
    Longitude: "25.6028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4597",
    WardLookupId: "4364",
    VDNumber: "10251346",
    RegisteredPopulation: "1333",
    VotingStationName: "SISONKE COMMUNITY PROJECT",
    Address: "MAHAMBEHLALA  KWAZAKHELE  NELSON MANDELA METRO",
    Latitude: "-33.8861",
    Longitude: "25.6073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4598",
    WardLookupId: "4364",
    VDNumber: "10251492",
    RegisteredPopulation: "1541",
    VotingStationName: "MAHAMBEHLALA TENT , MCADHUKISO STREET",
    Address: "MCADHUKISO STREET  KWAZAKHELE  PORT ELIZABETH",
    Latitude: "-33.8826",
    Longitude: "25.6029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4599",
    WardLookupId: "4364",
    VDNumber: "10251605",
    RegisteredPopulation: "1413",
    VotingStationName: "MASANGWANA DAY CARE CENTRE",
    Address: "MANDELA STREET  NEW BRIGHTON  PORT ELIZABETH",
    Latitude: "-33.8924",
    Longitude: "25.6068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4600",
    WardLookupId: "4364",
    VDNumber: "10251852",
    RegisteredPopulation: "378",
    VotingStationName: "SWARTKOPS PRIMARY SCHOOL",
    Address: "MAXWELL  SWARTKOPS  PORT ELIZABETH",
    Latitude: "-33.8657",
    Longitude: "25.6024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4601",
    WardLookupId: "4365",
    VDNumber: "10251278",
    RegisteredPopulation: "1881",
    VotingStationName: "ELUNDINI  CRECHE",
    Address: "STOKWE  NEW BRIGHTON 2  PORT ELIZABETH",
    Latitude: "-33.8964",
    Longitude: "25.596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4602",
    WardLookupId: "4365",
    VDNumber: "10251289",
    RegisteredPopulation: "2012",
    VotingStationName: "STEPHEN MAZUNGULA PRIMARY SCHOOL",
    Address: "MAGONGO STREET  NEW BRIGHTON  PORT ELIZABETH",
    Latitude: "-33.8915",
    Longitude: "25.5919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4603",
    WardLookupId: "4365",
    VDNumber: "10251290",
    RegisteredPopulation: "1233",
    VotingStationName: "PHILIP NIKIWE SENIOR SECONDARY SCHOOL",
    Address: "DIPPA ROAD  NEW BRIGHTON  PORT ELIZABETH",
    Latitude: "-33.8975",
    Longitude: "25.5875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4604",
    WardLookupId: "4365",
    VDNumber: "10251335",
    RegisteredPopulation: "2077",
    VotingStationName: "THUBELIHLE  SECONDARY SCHOOL",
    Address: "COR SANGOTSA/TSHANGANA STR  NEW BRIGHTON  PORT ELIZABETH",
    Latitude: "-33.8924",
    Longitude: "25.5943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4605",
    WardLookupId: "4366",
    VDNumber: "10251302",
    RegisteredPopulation: "1645",
    VotingStationName: "NGENE  TENT",
    Address: "NGENE ROAD  NEW BRIGHTON  PORT ELIZABETH",
    Latitude: "-33.8905",
    Longitude: "25.5826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4606",
    WardLookupId: "4366",
    VDNumber: "10251313",
    RegisteredPopulation: "2779",
    VotingStationName: "BJ MNYANDA PP SCHOOL",
    Address: "NJOLI ROAD  KWAZAKHELE  PORT ELIZABETH",
    Latitude: "-33.8884",
    Longitude: "25.586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4607",
    WardLookupId: "4366",
    VDNumber: "10251481",
    RegisteredPopulation: "2258",
    VotingStationName: "EBHONGWENI PRIMARY SCHOOL",
    Address: "MADIKANE STREET  KWAZAKHELE  PORT ELIZABETH",
    Latitude: "-33.8869",
    Longitude: "25.5932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4608",
    WardLookupId: "4366",
    VDNumber: "10251616",
    RegisteredPopulation: "1559",
    VotingStationName: "NEW APOSTOLIC CHURCH",
    Address: "61494 MARINA  STRUANDALE  PORT ELIZABETH",
    Latitude: "-33.8905",
    Longitude: "25.5772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4609",
    WardLookupId: "4367",
    VDNumber: "10251357",
    RegisteredPopulation: "2038",
    VotingStationName: "SEYISI PRIMARY SCHOOL",
    Address: "VAKAZA STREET  NEW BRIGHTON  PORT ELIZABETH",
    Latitude: "-33.8864",
    Longitude: "25.6006",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4610",
    WardLookupId: "4367",
    VDNumber: "10251436",
    RegisteredPopulation: "1849",
    VotingStationName: "SKOSANA MEM CONGREGATION CHURCH",
    Address: "CNR KULATI & MEKE STS  KWAZAKHELE  PORT ELIZABETH",
    Latitude: "-33.8797",
    Longitude: "25.5832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4611",
    WardLookupId: "4367",
    VDNumber: "10251469",
    RegisteredPopulation: "1990",
    VotingStationName: "QAPHELANI HIGH SCHOOL",
    Address: "MBILINI ROAD  KWAZAKHELE  PORT ELIZABETH",
    Latitude: "-33.882",
    Longitude: "25.5853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4612",
    WardLookupId: "4367",
    VDNumber: "10251470",
    RegisteredPopulation: "1792",
    VotingStationName: "BEN NYATHI HIGHER PRIMARY SCHOOL",
    Address: "MAQUBELA  ZAKHELE  PORT ELIZABETH",
    Latitude: "-33.8809",
    Longitude: "25.5895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4613",
    WardLookupId: "4367",
    VDNumber: "10251515",
    RegisteredPopulation: "1387",
    VotingStationName: "WOLFSON STADIUM",
    Address: "KWAZAKHELE  PORT ELIZABETH  NELSON MANDELA [PORT ELIZABETH]",
    Latitude: "-33.8842",
    Longitude: "25.5953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4614",
    WardLookupId: "4368",
    VDNumber: "10251368",
    RegisteredPopulation: "3320",
    VotingStationName: "KWAZAKHELE HOSTEL HALL",
    Address: "CNR MEKE AND MAVUSO ROADS  KWAZAKHELE  PORT ELIZABETH",
    Latitude: "-33.8863",
    Longitude: "25.5786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4615",
    WardLookupId: "4368",
    VDNumber: "10251379",
    RegisteredPopulation: "1613",
    VotingStationName: "KWAZAKHELE HIGH SCHOOL",
    Address: "COR. MEKE AND GAIKA STREET  KWAZAKHELE  PORT ELIZABETH",
    Latitude: "-33.8832",
    Longitude: "25.5756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4616",
    WardLookupId: "4368",
    VDNumber: "10251380",
    RegisteredPopulation: "1202",
    VotingStationName: "NKUTHALO PUBLIC PRIMARY SCHOOL",
    Address: "MAHAKANA STREET  ZWIDE  PORT ELIZABETH",
    Latitude: "-33.8781",
    Longitude: "25.565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4617",
    WardLookupId: "4368",
    VDNumber: "10251391",
    RegisteredPopulation: "2800",
    VotingStationName: "J.K. ZONDI PRIMARY SCHOOL",
    Address: "TUBALI STREET  KWAZAKHELE  PORT ELIZABETH",
    Latitude: "-33.8776",
    Longitude: "25.5724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4618",
    WardLookupId: "4369",
    VDNumber: "10251447",
    RegisteredPopulation: "1752",
    VotingStationName: "SIPHO HASHE COMBINED SCHOOL",
    Address: "COR BUDAZA & MBILNI ROADS  KWAZAKHELE  PORT ELIZABETH",
    Latitude: "-33.8801",
    Longitude: "25.5858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4619",
    WardLookupId: "4369",
    VDNumber: "10251504",
    RegisteredPopulation: "1742",
    VotingStationName: "MZONTSUNDU HIGH SCHOOL",
    Address: "MATODLANA STREET  KWAZAKHELE  PORT ELIZABETH",
    Latitude: "-33.8792",
    Longitude: "25.5993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4620",
    WardLookupId: "4369",
    VDNumber: "10251526",
    RegisteredPopulation: "1599",
    VotingStationName: "UNITED APOSTOLIC FAITH CHURCH",
    Address: "BUYAMBO STREET  KWAZAKHELE  PORT ELIZABETH",
    Latitude: "-33.8749",
    Longitude: "25.5894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4621",
    WardLookupId: "4369",
    VDNumber: "10251537",
    RegisteredPopulation: "2567",
    VotingStationName: "KAISER NGXWANA PRIMARY SCHOOL",
    Address: "MAVAVANA STREET  KWAZAKHELE  PORT ELIZABETH",
    Latitude: "-33.8763",
    Longitude: "25.5935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4622",
    WardLookupId: "4370",
    VDNumber: "10251403",
    RegisteredPopulation: "2014",
    VotingStationName: "ETHIOPIAN CHARISMATIC CHURCH",
    Address: "CNR MANGCU & MKYULI STREET  KWAZAKHELE  PORT ELIZABETH",
    Latitude: "-33.8742",
    Longitude: "25.5758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4623",
    WardLookupId: "4370",
    VDNumber: "10251414",
    RegisteredPopulation: "2255",
    VotingStationName: "MASAKANE PRIMARY SCHOOL",
    Address: "MOYAKE STREET  KWAZAKHELE  PORT ELIZABETH",
    Latitude: "-33.8743",
    Longitude: "25.5769",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4624",
    WardLookupId: "4370",
    VDNumber: "10251425",
    RegisteredPopulation: "2104",
    VotingStationName: "WB TSHUME PRIMARY SCHOOL",
    Address: "NGXOKOLO ST  KWAZAKHELE  PORT ELIZABETH",
    Latitude: "-33.8721",
    Longitude: "25.5822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4625",
    WardLookupId: "4370",
    VDNumber: "10251559",
    RegisteredPopulation: "1489",
    VotingStationName: "PHAKAMA PUBLIC PRIMARY SCHOOL",
    Address: "NGQKONDELA STREET  KWAZAKHELE  PORT ELIZABETH",
    Latitude: "-33.8668",
    Longitude: "25.5818",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4626",
    WardLookupId: "4371",
    VDNumber: "10251009",
    RegisteredPopulation: "3018",
    VotingStationName: "DUMANI PRIMARY SCHOOL",
    Address: "KHWALIMANZI STREET  NU 3 MOTHERWELL  PORT ELIZABETH",
    Latitude: "-33.8108",
    Longitude: "25.6034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4627",
    WardLookupId: "4371",
    VDNumber: "10251054",
    RegisteredPopulation: "2051",
    VotingStationName: "ENQILENI PRIMARY SCHOOL",
    Address: "PHALO STREET  MOTHERWELL  NU2  PORT ELIZABETH",
    Latitude: "-33.805",
    Longitude: "25.6058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4628",
    WardLookupId: "4371",
    VDNumber: "10251065",
    RegisteredPopulation: "2464",
    VotingStationName: "MOTHERWELL COMMUNITY HALL",
    Address:
      "CNR LL SEBE & ZAMUKULUNGISA STREET  MOTHERWELL NU2  PORT ELIZABETH",
    Latitude: "-33.8045",
    Longitude: "25.6108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4629",
    WardLookupId: "4371",
    VDNumber: "10251728",
    RegisteredPopulation: "1197",
    VotingStationName: "SIYAPHAMBILI PRIMARY SCHOOL",
    Address: "MATANZIMA  MOTHERWELL  NU 2  NELSON MANDELA [PORT ELIZABETH]",
    Latitude: "-33.8024",
    Longitude: "25.6144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4630",
    WardLookupId: "4372",
    VDNumber: "10251548",
    RegisteredPopulation: "1878",
    VotingStationName: "JC MVUSI METHODIST CHURCH",
    Address: "50219 BOXONGO STREET  KWAZAKHELE  PORT ELIZABETH",
    Latitude: "-33.8715",
    Longitude: "25.5865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4631",
    WardLookupId: "4372",
    VDNumber: "10251560",
    RegisteredPopulation: "2030",
    VotingStationName: "LUVUYO LUTHANDO SPECIAL PRIMARY SCHOOL",
    Address: "MAKHUBALO STREET  ZWIDE  PORT ELIZABETH",
    Latitude: "-33.8643",
    Longitude: "25.5803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4632",
    WardLookupId: "4372",
    VDNumber: "10251571",
    RegisteredPopulation: "1609",
    VotingStationName: "ILITHA PUBLIC PRIMARY SCHOOL",
    Address: "NGWENDU STREET  KWAZAKHELE  PORT ELIZABETH",
    Latitude: "-33.8647",
    Longitude: "25.5861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4633",
    WardLookupId: "4372",
    VDNumber: "10251582",
    RegisteredPopulation: "1080",
    VotingStationName: "SHIVA YO ZION CHURCH",
    Address: "MOUTUMA  KWAZAKHELE  NELSON MANDELA METRO",
    Latitude: "-33.8681",
    Longitude: "25.5917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4634",
    WardLookupId: "4373",
    VDNumber: "10250794",
    RegisteredPopulation: "1998",
    VotingStationName: "GARRET PUBLIC PRIMARY SCHOOL",
    Address: "JOHNSON ROAD  ZWIDE  PORT ELIZABETH",
    Latitude: "-33.8668",
    Longitude: "25.5584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4635",
    WardLookupId: "4373",
    VDNumber: "10250851",
    RegisteredPopulation: "2241",
    VotingStationName: "SITHEMBILE PRIMARY SCHOOL",
    Address: "BOOI STREET  ZWIDE  PORT ELIZABETH",
    Latitude: "-33.873",
    Longitude: "25.5669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4636",
    WardLookupId: "4373",
    VDNumber: "10251188",
    RegisteredPopulation: "4644",
    VotingStationName: "PIETER RADEMEYER HALL",
    Address: "ST LEONARDS ROAD  ALGOA PARK  PORT ELIZABETH",
    Latitude: "-33.9012",
    Longitude: "25.5644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4637",
    WardLookupId: "4373",
    VDNumber: "10251751",
    RegisteredPopulation: "1281",
    VotingStationName: "GARRET METHODIST CHURCH",
    Address: "DUBE STREET  ZWIDE   PORT ELIZABETH",
    Latitude: "-33.8707",
    Longitude: "25.5578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4638",
    WardLookupId: "4374",
    VDNumber: "10250806",
    RegisteredPopulation: "2314",
    VotingStationName: "EMSENGENI PRIMARY SCHOOL",
    Address: "NAKA STREET  ZWIDE  PORT ELIZABETH",
    Latitude: "-33.8655",
    Longitude: "25.5629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4639",
    WardLookupId: "4374",
    VDNumber: "10250817",
    RegisteredPopulation: "2119",
    VotingStationName: "BUCWA STREET TENT",
    Address: "BUCWA STREET  ZWIDE  PORT ELIZABETH",
    Latitude: "-33.8641",
    Longitude: "25.5704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4640",
    WardLookupId: "4374",
    VDNumber: "10250839",
    RegisteredPopulation: "1727",
    VotingStationName: "EMZOMNCANE PRIMARY SCHOOL",
    Address: "JAMES STREET  ZWIDE  PORT ELIZABETH",
    Latitude: "-33.8666",
    Longitude: "25.5781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4641",
    WardLookupId: "4374",
    VDNumber: "10250840",
    RegisteredPopulation: "2335",
    VotingStationName: "ZAMUKUKHANYA SCHOOL",
    Address: "TUNYISWA STREET  ZWIDE  PORT ELIZABETH",
    Latitude: "-33.8691",
    Longitude: "25.5721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4642",
    WardLookupId: "4374",
    VDNumber: "10251739",
    RegisteredPopulation: "639",
    VotingStationName: "DANIELS LOWER AND HIGHER PRIMARY SCHOOL",
    Address: "HAYA  ZWIDE  PORT ELIZABETH",
    Latitude: "-33.8669",
    Longitude: "25.5688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4643",
    WardLookupId: "4375",
    VDNumber: "10250750",
    RegisteredPopulation: "1618",
    VotingStationName: "UKUKHANYA PRE-SCHOOL",
    Address: "MABUDLA STREET  ZWIDE  PORT ELIZABETH",
    Latitude: "-33.8609",
    Longitude: "25.5646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4644",
    WardLookupId: "4375",
    VDNumber: "10250761",
    RegisteredPopulation: "2373",
    VotingStationName: "PHAKAMISA HIGH SCHOOL",
    Address: "KATYU STREET  ZWIDE  PORT ELIZABETH",
    Latitude: "-33.8578",
    Longitude: "25.5621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4645",
    WardLookupId: "4375",
    VDNumber: "10250772",
    RegisteredPopulation: "1120",
    VotingStationName: "JOHNSON ROAD  TENT",
    Address: "JOHNSON ROAD  ZWIDE  PORT ELIZABETH",
    Latitude: "-33.8524",
    Longitude: "25.5681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4646",
    WardLookupId: "4375",
    VDNumber: "10250783",
    RegisteredPopulation: "2054",
    VotingStationName: "SOWETO ON SEA COMMUNITY HALL",
    Address: "JOHNSON RD  SOWETO ON SEA  PORT ELIZABETH",
    Latitude: "-33.8573",
    Longitude: "25.5738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4647",
    WardLookupId: "4375",
    VDNumber: "10250828",
    RegisteredPopulation: "1424",
    VotingStationName: "SOWETO-ON-SEA PRIMARY SCHOOL",
    Address: "JOHNSON ROAD  SOWETO ON SEA  ZWIDE  PORT ELIZABETH",
    Latitude: "-33.8579",
    Longitude: "25.5742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4648",
    WardLookupId: "4376",
    VDNumber: "10250716",
    RegisteredPopulation: "2349",
    VotingStationName: "EMFUNDWENI PRIMARY SCHOOL",
    Address: "DLEPU STREET  ZWIDE  PORT ELIZABETH",
    Latitude: "-33.8585",
    Longitude: "25.5524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4649",
    WardLookupId: "4376",
    VDNumber: "10250727",
    RegisteredPopulation: "2117",
    VotingStationName: "NDZONDELELO HIGH SCHOOL",
    Address: "KOYANA ST  ZWIDE  PORT ELIZABETH",
    Latitude: "-33.8625",
    Longitude: "25.5562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4650",
    WardLookupId: "4376",
    VDNumber: "10250738",
    RegisteredPopulation: "1629",
    VotingStationName: "ELUMANYANWENI PRIMARY SCHOOL",
    Address: "CNR SCHEEPERS & 5TH AVE  ZWIDE  PORT ELIZABETH",
    Latitude: "-33.8652",
    Longitude: "25.5536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4651",
    WardLookupId: "4376",
    VDNumber: "10250749",
    RegisteredPopulation: "2245",
    VotingStationName: "MZIMHLOPE PRIMARY SCHOOL",
    Address: "QOGI STREET  ZWIDE  PORT ELIZABETH",
    Latitude: "-33.8575",
    Longitude: "25.5568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4652",
    WardLookupId: "4377",
    VDNumber: "10250503",
    RegisteredPopulation: "5991",
    VotingStationName: "BOOYSEN PARK HALL",
    Address: "AUBURN STREET  BOOYSEN PARK  PORT ELIZABETH",
    Latitude: "-33.8625",
    Longitude: "25.4614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4653",
    WardLookupId: "4377",
    VDNumber: "10251795",
    RegisteredPopulation: "2557",
    VotingStationName: "SALVATION ARMY CHURCH IN ZION",
    Address: "BAATJIES  NCEBA FAKU VILLAGE  NELSON MANDELA METRO",
    Latitude: "-33.8522",
    Longitude: "25.4639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4654",
    WardLookupId: "4377",
    VDNumber: "10251863",
    RegisteredPopulation: "2015",
    VotingStationName: "ALFONSO ARIES PRIMARY SCHOOL",
    Address: "BOOYSENS PARK  CHATTY EXT.12  NELSON MANDELA METRO",
    Latitude: "-33.8523",
    Longitude: "25.4485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4655",
    WardLookupId: "4377",
    VDNumber: "10251908",
    RegisteredPopulation: "341",
    VotingStationName: "KHAYAMNANDI  EXT  TENT",
    Address: "PETER ROAD  KHAYAMNANDI  EXTENSION  PORT ELIZABETH",
    Latitude: "-33.8278",
    Longitude: "25.4533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4656",
    WardLookupId: "4378",
    VDNumber: "10250682",
    RegisteredPopulation: "1798",
    VotingStationName: "KWAMAGXAKI HIGH SCHOOL",
    Address: "RALO STREET  KWAMAGXAKI  PORT ELIZABETH",
    Latitude: "-33.8477",
    Longitude: "25.5419",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4657",
    WardLookupId: "4378",
    VDNumber: "10250693",
    RegisteredPopulation: "1748",
    VotingStationName: "SAKUBA STREET TENT",
    Address: "SAKUBA STREET  ZWIDE  PORT ELIZABETH",
    Latitude: "-33.8566",
    Longitude: "25.5442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4658",
    WardLookupId: "4378",
    VDNumber: "10250705",
    RegisteredPopulation: "1343",
    VotingStationName: "RABE CHURCH",
    Address: "KANI  VEEPLAS  PORT ELIZABETH",
    Latitude: "-33.8525",
    Longitude: "25.5524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4659",
    WardLookupId: "4378",
    VDNumber: "10250862",
    RegisteredPopulation: "2873",
    VotingStationName: "KWAMAGXAKI COMMUNITY CENTRE",
    Address: "GUNGWANA STREET  KWAMAGXAKI  PORT ELIZABETH",
    Latitude: "-33.8408",
    Longitude: "25.5327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4660",
    WardLookupId: "4378",
    VDNumber: "10251740",
    RegisteredPopulation: "1467",
    VotingStationName: "VEEPLAAS COMMUNITY HALL",
    Address: "KANI STREET  VEEPLAAS  PORT ELIZABETH",
    Latitude: "-33.8533",
    Longitude: "25.5508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4661",
    WardLookupId: "4379",
    VDNumber: "10250648",
    RegisteredPopulation: "4163",
    VotingStationName: "MISSIONVALE CARE CENTRE",
    Address: "TROMP ROAD  MISSIONVALE  PORT ELIZABETH",
    Latitude: "-33.8768",
    Longitude: "25.55",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4662",
    WardLookupId: "4379",
    VDNumber: "10250659",
    RegisteredPopulation: "4340",
    VotingStationName: "MISSIONVALE PRIMARY SCHOOL",
    Address: "COLORADO STREET  MISSIONVALE  PORT ELIZABETH",
    Latitude: "-33.889",
    Longitude: "25.5556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4663",
    WardLookupId: "4380",
    VDNumber: "10250390",
    RegisteredPopulation: "2041",
    VotingStationName: "REPUBLIC PRIMARY SCHOOL",
    Address: "5 5TH AVENUE  WINDVOGEL  PORT ELIZABETH",
    Latitude: "-33.8972",
    Longitude: "25.5396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4664",
    WardLookupId: "4380",
    VDNumber: "10250402",
    RegisteredPopulation: "2092",
    VotingStationName: "DIE HEUWEL PRIMARY SCHOOL",
    Address: "CARELSON STREET  HILLSIDE  PORT ELIZABETH",
    Latitude: "-33.8999",
    Longitude: "25.5326",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4665",
    WardLookupId: "4380",
    VDNumber: "10250413",
    RegisteredPopulation: "2938",
    VotingStationName: "BETHELSDORP SENIOR SECONDARY SCHOOL",
    Address: "FELCASS ROAD  BETHELSDORP  PORT ELIZABETH",
    Latitude: "-33.8932",
    Longitude: "25.5334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4666",
    WardLookupId: "4380",
    VDNumber: "10250435",
    RegisteredPopulation: "2651",
    VotingStationName: "ARCADIA PRIMARY SCHOOL",
    Address: "CLAASEN STREET  ARCADIA  PORT ELIZABETH",
    Latitude: "-33.8814",
    Longitude: "25.5232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4667",
    WardLookupId: "4380",
    VDNumber: "10250479",
    RegisteredPopulation: "2164",
    VotingStationName: "TRIOMPH PRIMARY SCHOOL",
    Address: "1 ADAMS STREET  SALSONEVILLE  PORT ELIZABETH",
    Latitude: "-33.8874",
    Longitude: "25.5096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4668",
    WardLookupId: "4381",
    VDNumber: "10250592",
    RegisteredPopulation: "2590",
    VotingStationName: "KLEINSKOOL COMMUNITY PRIMARY SCHOOL",
    Address: "MAIN ROAD  KLEINSKOOL  PORT ELIZABETH",
    Latitude: "-33.8576",
    Longitude: "25.5159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4669",
    WardLookupId: "4381",
    VDNumber: "10250615",
    RegisteredPopulation: "2913",
    VotingStationName: "GQEBERA SECONDARY SCHOOL",
    Address: "SHOPHE STREET  GOVAN MBEKI TOWNSHIP  PORT ELIZABETH",
    Latitude: "-33.8545",
    Longitude: "25.5346",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4670",
    WardLookupId: "4381",
    VDNumber: "10250626",
    RegisteredPopulation: "2037",
    VotingStationName: "GERTRUDE SHOPE PRIMARY SCHOOL",
    Address: "BAART ROAD  GOVAN MBEKI TOWNSHIP  PORT ELIZABETH",
    Latitude: "-33.8595",
    Longitude: "25.5416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4671",
    WardLookupId: "4381",
    VDNumber: "10250637",
    RegisteredPopulation: "1961",
    VotingStationName: "CEBELIHLE PRIMARY SCHOOL",
    Address: "MBEKI STREET  GOVAN MBEKI TOWNSHIP  PORT ELIZABETH",
    Latitude: "-33.8673",
    Longitude: "25.5484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4672",
    WardLookupId: "4382",
    VDNumber: "10250457",
    RegisteredPopulation: "3714",
    VotingStationName: "WONDERWONINGS COMMUNITY CENTRE",
    Address: "ESTERHUIZEN STREET  ARCADIA  PORT ELIZABETH",
    Latitude: "-33.8729",
    Longitude: "25.5188",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4673",
    WardLookupId: "4382",
    VDNumber: "10250491",
    RegisteredPopulation: "2896",
    VotingStationName: "FRANK MOODIE HALL",
    Address: "26 BRASS STREET  CHATTY  PORT ELIZABETH",
    Latitude: "-33.8742",
    Longitude: "25.5028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4674",
    WardLookupId: "4382",
    VDNumber: "10250536",
    RegisteredPopulation: "3457",
    VotingStationName: "FERNWOOD PRIMARY SCHOOL",
    Address: "CNR SODIN & NICHOLSON RD  SALTVILLE  PORT ELIZABETH",
    Latitude: "-33.8656",
    Longitude: "25.5177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4675",
    WardLookupId: "4382",
    VDNumber: "10251773",
    RegisteredPopulation: "775",
    VotingStationName: "CHETTY SENIOR SECONDARY SCHOOL",
    Address: "BURKE STREET  CHETTY  PORT ELIZABETH",
    Latitude: "-33.8708",
    Longitude: "25.5047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4676",
    WardLookupId: "4383",
    VDNumber: "10250424",
    RegisteredPopulation: "2942",
    VotingStationName: "BETHELSDORP COMMUNITY CENTRE",
    Address: "ST BRIDGET STREET  BETHELSDORP  PORT ELIZABETH",
    Latitude: "-33.8848",
    Longitude: "25.5187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4677",
    WardLookupId: "4383",
    VDNumber: "10250468",
    RegisteredPopulation: "2697",
    VotingStationName: "HEATH PARK COLLEGE",
    Address: "CNR LAWRENCE ERASMUS & STANFORD RD  BETHELSDORP  PORT ELIZABETH",
    Latitude: "-33.878",
    Longitude: "25.4918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4678",
    WardLookupId: "4383",
    VDNumber: "10250480",
    RegisteredPopulation: "2033",
    VotingStationName: "CHATTY HALL",
    Address: "BERTRAM ROAD  CHATTY  PORT ELIZABETH",
    Latitude: "-33.8735",
    Longitude: "25.496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4679",
    WardLookupId: "4383",
    VDNumber: "10250514",
    RegisteredPopulation: "1723",
    VotingStationName: "ASTRA PRIMARY SCHOOL",
    Address: "LAWRENCE ERASMUS DRIVE  BLOEMENDAL  PORT ELIZABETH",
    Latitude: "-33.8683",
    Longitude: "25.4957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4680",
    WardLookupId: "4383",
    VDNumber: "10250525",
    RegisteredPopulation: "2170",
    VotingStationName: "KRONEBERG PRIMARY SCHOOL",
    Address: "KRONEBERG AVENUE  CHATTY EXT 21  PORT ELIZABETH",
    Latitude: "-33.8649",
    Longitude: "25.4943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4681",
    WardLookupId: "4384",
    VDNumber: "10250660",
    RegisteredPopulation: "1681",
    VotingStationName: "EZ KABANE HIGH SCHOOL",
    Address: "MNQAYI  KWADWESI  PORT ELIZABETH",
    Latitude: "-33.8397",
    Longitude: "25.5166",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4682",
    WardLookupId: "4384",
    VDNumber: "10250671",
    RegisteredPopulation: "2234",
    VotingStationName: "HENRY FAZZIE HALL",
    Address: "SIDULU  KWADWESI  PORT ELIZABETH",
    Latitude: "-33.8475",
    Longitude: "25.5197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4683",
    WardLookupId: "4384",
    VDNumber: "10251458",
    RegisteredPopulation: "1923",
    VotingStationName: "ZANOKHANYO EDUCARE CENTRE",
    Address: "BHAMBATHA  DWESI AREA C  PORT ELIZABETH",
    Latitude: "-33.8395",
    Longitude: "25.5058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4684",
    WardLookupId: "4384",
    VDNumber: "10251627",
    RegisteredPopulation: "1502",
    VotingStationName: "ETHIOPIAN ZINCH CHURCH",
    Address: "PORT ELIZABETH  WESTVILLE  PORT ELIZABETH",
    Latitude: "-33.8445",
    Longitude: "25.5032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4685",
    WardLookupId: "4384",
    VDNumber: "10251762",
    RegisteredPopulation: "1238",
    VotingStationName: "ZANDILE PRE-SCHOOL",
    Address: "2 MHLOTHIYANE  KWADWESI  NELSON MANDELA METRO",
    Latitude: "-33.849",
    Longitude: "25.5268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4686",
    WardLookupId: "4384",
    VDNumber: "10251885",
    RegisteredPopulation: "1179",
    VotingStationName: "SANGO METHODIST CHURCH",
    Address: "KWADWESI EXTENSION  EXT.36 KWADWESI  NELSON MANDELA METRO",
    Latitude: "-33.8343",
    Longitude: "25.4993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4687",
    WardLookupId: "4385",
    VDNumber: "10250547",
    RegisteredPopulation: "3607",
    VotingStationName: "TINKERBELL CRECHE",
    Address: "BARBERRY DRIVE  BETHELSDORP  PORT ELIZABETH",
    Latitude: "-33.8618",
    Longitude: "25.5016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4688",
    WardLookupId: "4385",
    VDNumber: "10250558",
    RegisteredPopulation: "2968",
    VotingStationName: "STRELITZIA PRIMARY SCHOOL",
    Address: "BARBERRY DRIVE  BETHELSDORP  PORT ELIZABETH",
    Latitude: "-33.861",
    Longitude: "25.4939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4689",
    WardLookupId: "4385",
    VDNumber: "10250570",
    RegisteredPopulation: "1674",
    VotingStationName: "TAHILA MINISTRIES",
    Address: "22 LANGDON  EXT.36 KLEINSKOOL  NELSON MANDELA METRO",
    Latitude: "-33.851",
    Longitude: "25.4992",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4690",
    WardLookupId: "4385",
    VDNumber: "10250581",
    RegisteredPopulation: "2529",
    VotingStationName: "CHRISTIAN CATHOLIC CHURCH IN ZION",
    Address: "MAIN ROAD  KLEINSKOOL  NELSON MANDELA METRO",
    Latitude: "-33.855",
    Longitude: "25.5091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4691",
    WardLookupId: "4386",
    VDNumber: "10250569",
    RegisteredPopulation: "4117",
    VotingStationName: "GEORGE BOTHA COMMUNITY CENTRE",
    Address: "MPUKO STREET  SALTVILLE (KWANOXOLO)  PORT ELIZABETH",
    Latitude: "-33.8543",
    Longitude: "25.4882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4692",
    WardLookupId: "4386",
    VDNumber: "10251650",
    RegisteredPopulation: "2936",
    VotingStationName: "RTS MINISTRIES",
    Address: "FIJI STREET  JACKSONVILLE  NELSON MANDELA METRO",
    Latitude: "-33.8649",
    Longitude: "25.4809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4693",
    WardLookupId: "4386",
    VDNumber: "10251784",
    RegisteredPopulation: "1406",
    VotingStationName: "UNITED OLD APOSTOLIC CHURCH",
    Address: "19413 LEE SAMUELS  KLEINSKOOL  PORT ELIZABETH",
    Latitude: "-33.8505",
    Longitude: "25.4927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4694",
    WardLookupId: "4386",
    VDNumber: "10251807",
    RegisteredPopulation: "2404",
    VotingStationName: "HARVEST CHURCH",
    Address: "2 MPUKU STREET  JACKSONVILLE  NELSON MANDELA METRO",
    Latitude: "-33.8685",
    Longitude: "25.4764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4695",
    WardLookupId: "4387",
    VDNumber: "10250132",
    RegisteredPopulation: "5822",
    VotingStationName: "NG KERK PE SHERWOOD",
    Address: "AMOS  SHERWOOD  NELSON MANDELA METRO",
    Latitude: "-33.9592",
    Longitude: "25.4801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4696",
    WardLookupId: "4387",
    VDNumber: "10250154",
    RegisteredPopulation: "4119",
    VotingStationName: "ROWALLAN PARK PRIMARY SCHOOL",
    Address: "BRAMPTON AVENUE  ROWALLAN PARK  PORT ELIZABETH",
    Latitude: "-33.9388",
    Longitude: "25.4778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4697",
    WardLookupId: "4387",
    VDNumber: "10250165",
    RegisteredPopulation: "2850",
    VotingStationName: "KABEGA PRIMARY SCHOOL",
    Address: "BRABANT STREET  KABEGA PARK  PORT ELIZABETH",
    Latitude: "-33.9439",
    Longitude: "25.4936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4698",
    WardLookupId: "4388",
    VDNumber: "10360011",
    RegisteredPopulation: "508",
    VotingStationName: "BLUE HORIZON BAY COMMUNITY HALL",
    Address: "BEACH ROAD  BLUE HORIZON BAY  PORT ELIZABETH",
    Latitude: "-33.9767",
    Longitude: "25.2558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4699",
    WardLookupId: "4388",
    VDNumber: "10460012",
    RegisteredPopulation: "1266",
    VotingStationName: "ST ALBANS PRIMARY SCHOOL",
    Address: "MISSION ROAD  ST ALBANS  PORT ELIZABETH",
    Latitude: "-33.914",
    Longitude: "25.35",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4700",
    WardLookupId: "4388",
    VDNumber: "10460023",
    RegisteredPopulation: "653",
    VotingStationName: "VAN STADENS WILDFLOWER RESERVE HALL",
    Address: "VAN STADENS  PORT ELIZABETH",
    Latitude: "-33.914",
    Longitude: "25.2232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4701",
    WardLookupId: "4388",
    VDNumber: "10460034",
    RegisteredPopulation: "1485",
    VotingStationName: "ANKERVAS PRIMARY SCHOOL",
    Address: "UITENHAGE/HUMANSDORP ROAD, ROCKLANDS  ROCKLANDS  ",
    Latitude: "-33.8606",
    Longitude: "25.3171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4702",
    WardLookupId: "4388",
    VDNumber: "10460056",
    RegisteredPopulation: "3749",
    VotingStationName: "KUYGA COMMUNITY CENTRE",
    Address: "OFF OLD CAPE ROAD  GREENBUSHES  PORT ELIZABETH",
    Latitude: "-33.9217",
    Longitude: "25.4454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4703",
    WardLookupId: "4388",
    VDNumber: "10460067",
    RegisteredPopulation: "467",
    VotingStationName: "GREENBUSHES COUNTRY CLUB TENT",
    Address: "CAPE ROAD  GREENBUSHES  PORT ELIZABETH",
    Latitude: "-33.9247",
    Longitude: "25.4023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4704",
    WardLookupId: "4388",
    VDNumber: "10460078",
    RegisteredPopulation: "1659",
    VotingStationName: "ALTONA PRIMARY SCHOOL",
    Address: "ALTONA ROAD  GREENBUSHES  NELSON MANDELA METRO",
    Latitude: "-33.9343",
    Longitude: "25.4142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4705",
    WardLookupId: "4388",
    VDNumber: "10460113",
    RegisteredPopulation: "816",
    VotingStationName: "THE ISLAND FOREST RESERVE SCHOOL",
    Address: "SEAVIEW ROAD  SEAVIEW  PORT ELIZABETH",
    Latitude: "-33.9887",
    Longitude: "25.3692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4706",
    WardLookupId: "4388",
    VDNumber: "10480014",
    RegisteredPopulation: "1853",
    VotingStationName: "SEAVIEW COMMUNITY CENTRE",
    Address: "DA GAMA STREET  SEAVIEW  PORT ELIZABETH",
    Latitude: "-34.0135",
    Longitude: "25.3638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4707",
    WardLookupId: "4389",
    VDNumber: "10080010",
    RegisteredPopulation: "2008",
    VotingStationName: "KHAYAMNANDI COMMUNITY CENTRE",
    Address: "SIBRA MKONTO STREET  DESPATCH  DESPATCH",
    Latitude: "-33.8178",
    Longitude: "25.4568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4708",
    WardLookupId: "4389",
    VDNumber: "10080021",
    RegisteredPopulation: "1807",
    VotingStationName: "DESPATCH PRIMARY SCHOOL",
    Address: "OOIEVAAR CRESCENT  DALEVIEW  DESPATCH",
    Latitude: "-33.8172",
    Longitude: "25.463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4709",
    WardLookupId: "4389",
    VDNumber: "10080076",
    RegisteredPopulation: "983",
    VotingStationName: "DALEVIEW COMMUNITY HALL",
    Address: "HOOG STREET  DALEVIEW  DESPATCH",
    Latitude: "-33.8105",
    Longitude: "25.4686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4710",
    WardLookupId: "4389",
    VDNumber: "10080087",
    RegisteredPopulation: "1748",
    VotingStationName: "ZANOLWAZI HIGH SCHOOL",
    Address: "CHIEF ALBERT LUTHULI STR  DESPATCH  DESPATCH",
    Latitude: "-33.8217",
    Longitude: "25.4598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4711",
    WardLookupId: "4389",
    VDNumber: "10080098",
    RegisteredPopulation: "3501",
    VotingStationName: "JOE SLOVO COMMUNITY HALL",
    Address: "MELUMZI  JOE SLOVO G101  DESPATCH",
    Latitude: "-33.8285",
    Longitude: "25.5112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4712",
    WardLookupId: "4389",
    VDNumber: "10080100",
    RegisteredPopulation: "3111",
    VotingStationName: "JOE SLOVO PRIMARY SCHOOL",
    Address: "MONTLANTHE STREET  JOE SLOVO  PORT ELIZABETH",
    Latitude: "-33.8296",
    Longitude: "25.5026",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4713",
    WardLookupId: "4390",
    VDNumber: "10300015",
    RegisteredPopulation: "2923",
    VotingStationName: "MOLLY BLACKBURN SCHOOL",
    Address: "1ST AVENUE  KWANOBUHLE AREA 8  UITENHAGE",
    Latitude: "-33.8315",
    Longitude: "25.3861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4714",
    WardLookupId: "4390",
    VDNumber: "10300026",
    RegisteredPopulation: "2606",
    VotingStationName: "NOKWEZI SCHOOL",
    Address: "10TH AVENUE  KWANOBUHLE AREA 8  UITENHAGE",
    Latitude: "-33.8288",
    Longitude: "25.3769",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4715",
    WardLookupId: "4390",
    VDNumber: "10300037",
    RegisteredPopulation: "2626",
    VotingStationName: "PHINDUBUYE SCHOOL",
    Address: "1 FREEMANTLE ROAD  KWANOBUHLE  UITENHAGE",
    Latitude: "-33.8305",
    Longitude: "25.398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4716",
    WardLookupId: "4391",
    VDNumber: "10300048",
    RegisteredPopulation: "1731",
    VotingStationName: "MAGQABI SCHOOL",
    Address: "PO BOX 1652  UITENHAGE",
    Latitude: "-33.8256",
    Longitude: "25.3916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4717",
    WardLookupId: "4391",
    VDNumber: "10300059",
    RegisteredPopulation: "2369",
    VotingStationName: "SIKHOTHINA SCHOOL",
    Address: "MBENGO STREET  KWANOBUHLE AREA 6  UITENHAGE",
    Latitude: "-33.8245",
    Longitude: "25.3864",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4718",
    WardLookupId: "4391",
    VDNumber: "10300082",
    RegisteredPopulation: "2302",
    VotingStationName: "UNITED METHODIST CHURCH",
    Address: "126 PITYANA STREET  KWANOBUHLE AREA 7  UITENHAGE",
    Latitude: "-33.8221",
    Longitude: "25.3978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4719",
    WardLookupId: "4391",
    VDNumber: "10300396",
    RegisteredPopulation: "1074",
    VotingStationName: "KINIKINI STREET TENT",
    Address: "KINIKINI STREET  KWANOBUHLE  UITENHAGE",
    Latitude: "-33.825",
    Longitude: "25.3942",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4720",
    WardLookupId: "4392",
    VDNumber: "10300060",
    RegisteredPopulation: "2029",
    VotingStationName: "HOLOMISA APOSTOLIC FAITH MISSION",
    Address:
      "CNR MATOMELA & ZINGELA STREET  KWANOBUHLE UITENHAGE  HOLOMISA AREA KWANOBUHLE",
    Latitude: "-33.8246",
    Longitude: "25.3696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4721",
    WardLookupId: "4392",
    VDNumber: "10300071",
    RegisteredPopulation: "2671",
    VotingStationName: "PHAPHANI SCHOOL",
    Address: "MONDILE STREET  KWANOBUHLE AREA 7  UITENHAGE",
    Latitude: "-33.8217",
    Longitude: "25.4041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4722",
    WardLookupId: "4392",
    VDNumber: "10300093",
    RegisteredPopulation: "2394",
    VotingStationName: "JEFF MASEMOLA HALL",
    Address: "TIZE ROAD  KWANOBUHLE  UITENHAGE",
    Latitude: "-33.819",
    Longitude: "25.3939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4723",
    WardLookupId: "4392",
    VDNumber: "10300105",
    RegisteredPopulation: "1769",
    VotingStationName: "BABS MADLAKANE HALL",
    Address: "MATANZIMA STREET  KWANOBUHLE AREA 1  UITENHAGE",
    Latitude: "-33.8048",
    Longitude: "25.3941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4724",
    WardLookupId: "4393",
    VDNumber: "10300116",
    RegisteredPopulation: "2597",
    VotingStationName: "TANDUXOLO SCHOOL",
    Address: "BANTOM STREET  KWANOBUHLE AREA 9  UITENHAGE",
    Latitude: "-33.8057",
    Longitude: "25.3718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4725",
    WardLookupId: "4393",
    VDNumber: "10300127",
    RegisteredPopulation: "2157",
    VotingStationName: "MJULENI SCHOOL",
    Address: "LIKHONDE STREET  KWANOBUHLE AREA 3  UITENHAGE",
    Latitude: "-33.8127",
    Longitude: "25.376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4726",
    WardLookupId: "4393",
    VDNumber: "10300363",
    RegisteredPopulation: "2028",
    VotingStationName: "LUCKY LUKE CRECHE",
    Address: "BONITO STREET  TIRYVILLE  UITENHAGE",
    Latitude: "-33.7302",
    Longitude: "25.3628",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4727",
    WardLookupId: "4393",
    VDNumber: "10300408",
    RegisteredPopulation: "849",
    VotingStationName: "MABANDLA PRE SCHOOL",
    Address: "GXOWA  NOBUHLE  UITENHAGE",
    Latitude: "-33.8083",
    Longitude: "25.3754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4728",
    WardLookupId: "4393",
    VDNumber: "10300420",
    RegisteredPopulation: "2001",
    VotingStationName: "NTAKOMLILO CHURCH",
    Address: "NTAKOMLILO  KWANOBUHLE  UINTAHAGE",
    Latitude: "-33.8151",
    Longitude: "25.3629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4729",
    WardLookupId: "4393",
    VDNumber: "10510030",
    RegisteredPopulation: "392",
    VotingStationName: "KRUISRIVIER PRIMARY SCHOOL",
    Address: "KRUISRIVIER  UITENHAGE",
    Latitude: "-33.7667",
    Longitude: "25.3485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4730",
    WardLookupId: "4394",
    VDNumber: "10300161",
    RegisteredPopulation: "2646",
    VotingStationName: "AME  CHURCH",
    Address: "HINTSA  KWANOBUHLE  UITENHAGE",
    Latitude: "-33.7993",
    Longitude: "25.3918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4731",
    WardLookupId: "4394",
    VDNumber: "10300172",
    RegisteredPopulation: "2002",
    VotingStationName: "NTLEMEZA PRIMARY SCHOOL",
    Address: "ANTA STREET  KWANOBUHLE AREA 1  UITENHAGE",
    Latitude: "-33.803",
    Longitude: "25.402",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4732",
    WardLookupId: "4394",
    VDNumber: "10300183",
    RegisteredPopulation: "2680",
    VotingStationName: "STEPHEN NKOMO SCHOOL",
    Address: "1 GXIYA STREET  KWANOBUHLE AREA 1  UITENHAGE",
    Latitude: "-33.7975",
    Longitude: "25.397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4733",
    WardLookupId: "4394",
    VDNumber: "10300194",
    RegisteredPopulation: "2494",
    VotingStationName: "INNES PRIMARY SCHOOL",
    Address: "ALGOA  KWANOBUHLE  PORT ELIZABETH",
    Latitude: "-33.7767",
    Longitude: "25.4036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4734",
    WardLookupId: "4395",
    VDNumber: "10300138",
    RegisteredPopulation: "2575",
    VotingStationName: "RH GODLO SCHOOL",
    Address: "RELU STREET  KWANOBUHLE AREA 3  UITENHAGE",
    Latitude: "-33.8041",
    Longitude: "25.3787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4735",
    WardLookupId: "4395",
    VDNumber: "10300149",
    RegisteredPopulation: "1973",
    VotingStationName: "MTHONJENI SCHOOL",
    Address: "JOLOBE AVE  KWANOBUHLE AREA 1  UITENHAGE",
    Latitude: "-33.8035",
    Longitude: "25.3872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4736",
    WardLookupId: "4395",
    VDNumber: "10300150",
    RegisteredPopulation: "2313",
    VotingStationName: "VUBA LP SCHOOL",
    Address: "SIGILA STREET  KWANOBUHLE AREA 2  UITENHAGE",
    Latitude: "-33.7991",
    Longitude: "25.3853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4737",
    WardLookupId: "4395",
    VDNumber: "10300385",
    RegisteredPopulation: "1750",
    VotingStationName: "TULWANA HIGHER PRIMARY SCHOOL",
    Address: "C/O MTIRARA & MENZE ROADS  KWANOBUHLE  UITENHAGE",
    Latitude: "-33.8091",
    Longitude: "25.3826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4738",
    WardLookupId: "4396",
    VDNumber: "10300240",
    RegisteredPopulation: "2433",
    VotingStationName: "WINTERBERG SCHOOL",
    Address: "DURBAN STREET  UITENHAGE  UITENHAGE",
    Latitude: "-33.7698",
    Longitude: "25.3947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4739",
    WardLookupId: "4396",
    VDNumber: "10300251",
    RegisteredPopulation: "3336",
    VotingStationName: "ALLANRIDGE HALL",
    Address: "KAMESH ROAD  KWALANGA  UITENHAGE",
    Latitude: "-33.7512",
    Longitude: "25.386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4740",
    WardLookupId: "4396",
    VDNumber: "10300262",
    RegisteredPopulation: "2181",
    VotingStationName: "DALE STREET CONGREGATION",
    Address: "DOORHOEK LIVINGSTONE CIRCLE  KWALANGA  UITENHAGE",
    Latitude: "-33.7597",
    Longitude: "25.3779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4741",
    WardLookupId: "4396",
    VDNumber: "10300273",
    RegisteredPopulation: "2499",
    VotingStationName: "MCCARTHY SCHOOL",
    Address: "MCCARTHY STREET  KWALANGA  UITENHAGE",
    Latitude: "-33.7501",
    Longitude: "25.3763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4742",
    WardLookupId: "4397",
    VDNumber: "10300318",
    RegisteredPopulation: "2544",
    VotingStationName: "VERITE SCHOOL",
    Address: "KWIKKIE ROAD  KWALANGA  UITENHAGE",
    Latitude: "-33.7384",
    Longitude: "25.3665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4743",
    WardLookupId: "4397",
    VDNumber: "10300330",
    RegisteredPopulation: "2352",
    VotingStationName: "CARITAS SCHOOL",
    Address: "WARBLER ROAD  KWALANGA  UITENHAGE",
    Latitude: "-33.7328",
    Longitude: "25.3722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4744",
    WardLookupId: "4397",
    VDNumber: "10300341",
    RegisteredPopulation: "2458",
    VotingStationName: "JOHN WALTON SCHOOL",
    Address: "ROSEDALE DRIVE  KWALANGA  UITENHAGE",
    Latitude: "-33.7353",
    Longitude: "25.3793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4745",
    WardLookupId: "4397",
    VDNumber: "10300352",
    RegisteredPopulation: "2222",
    VotingStationName: "CW HENDRICKSE SCHOOL",
    Address: "KAMESH STREET  GAMBLEVILLE  UITENHAGE",
    Latitude: "-33.74",
    Longitude: "25.3725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4746",
    WardLookupId: "4398",
    VDNumber: "10300284",
    RegisteredPopulation: "3723",
    VotingStationName: "ILLINGE SCHOOL",
    Address: "TSHININDO  KWALANGA UITENHAGE  UITENHAGE",
    Latitude: "-33.7432",
    Longitude: "25.3874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4747",
    WardLookupId: "4398",
    VDNumber: "10300295",
    RegisteredPopulation: "2512",
    VotingStationName: "JAMES NDULULA SCHOOL",
    Address: "PONGOLO STREET  KWALANGA  UITENHAGE",
    Latitude: "-33.7354",
    Longitude: "25.391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4748",
    WardLookupId: "4398",
    VDNumber: "10300307",
    RegisteredPopulation: "2332",
    VotingStationName: "LIMEKHAYA SCHOOL",
    Address: "MADUNA ROAD  KWANOBUHLE  UITENHAGE",
    Latitude: "-33.7463",
    Longitude: "25.3881",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4749",
    WardLookupId: "4399",
    VDNumber: "10300206",
    RegisteredPopulation: "3644",
    VotingStationName: "AGS LAVENDER",
    Address: "33 LAVENDER CRESCENT  FAIRBRIDGE HEIGHTS  UITENHAGE",
    Latitude: "-33.7626",
    Longitude: "25.4181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4750",
    WardLookupId: "4399",
    VDNumber: "10300217",
    RegisteredPopulation: "2589",
    VotingStationName: "MUIR COLLEGE",
    Address: "SIR THOMAS MUIR DRIVE  UITENHAGE  UITENHAGE",
    Latitude: "-33.7346",
    Longitude: "25.4198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4751",
    WardLookupId: "4399",
    VDNumber: "10300228",
    RegisteredPopulation: "3185",
    VotingStationName: "HANDHAAF SCHOOL",
    Address: "CHANNER STREET  UITENHAGE  UITENHAGE",
    Latitude: "-33.7432",
    Longitude: "25.406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4752",
    WardLookupId: "4399",
    VDNumber: "10300239",
    RegisteredPopulation: "2419",
    VotingStationName: "PARKLANE COLLEGE",
    Address: "PARK LANE AVENUE  CANNON HILL  UITENHAGE",
    Latitude: "-33.7627",
    Longitude: "25.3996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4753",
    WardLookupId: "4400",
    VDNumber: "10080032",
    RegisteredPopulation: "3090",
    VotingStationName: "NG CHURCH EENDRAG HALL",
    Address: "63 BOTHA STREET  CAMPHER PARK  DESPATCH",
    Latitude: "-33.8009",
    Longitude: "25.4471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4754",
    WardLookupId: "4400",
    VDNumber: "10080043",
    RegisteredPopulation: "2711",
    VotingStationName: "DESPATCH TOWN HALL",
    Address: "1 VOORTREKKER STREET  DESPATCH  DESPATCH",
    Latitude: "-33.8011",
    Longitude: "25.4626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4755",
    WardLookupId: "4400",
    VDNumber: "10080054",
    RegisteredPopulation: "2877",
    VotingStationName: "NG CHURCH RETIEF HALL",
    Address: "NORTH PARK STREET  RETIEF  DESPATCH",
    Latitude: "-33.7982",
    Longitude: "25.4789",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4756",
    WardLookupId: "4400",
    VDNumber: "10080065",
    RegisteredPopulation: "2105",
    VotingStationName: "NG CHURCH DESPATCH SOUTH HALL",
    Address: "65 KERK STREET  DESPATCH",
    Latitude: "-33.8034",
    Longitude: "25.4774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4757",
    WardLookupId: "4401",
    VDNumber: "10300329",
    RegisteredPopulation: "1891",
    VotingStationName: "SEAGULL SCHOOL",
    Address: "SEAGULL STREET  KWALANGA  UITENHAGE",
    Latitude: "-33.7372",
    Longitude: "25.3845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4758",
    WardLookupId: "4401",
    VDNumber: "10300431",
    RegisteredPopulation: "2856",
    VotingStationName: "SIYAPHAMBILI PRE-PARATORY SCHOOL",
    Address:
      "MARIGOLD  TAMBOVILLE  UITENHAGE  (NMA - NELSON MANDELA BAY [PORT ELIZABETH])",
    Latitude: "-33.7315",
    Longitude: "25.3921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4759",
    WardLookupId: "4401",
    VDNumber: "10460045",
    RegisteredPopulation: "1154",
    VotingStationName: "COLCHESTER COMMUNITY HALL",
    Address: "COLCHESTER  PORT ELIZABETH",
    Latitude: "-33.6888",
    Longitude: "25.8158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4760",
    WardLookupId: "4401",
    VDNumber: "10460102",
    RegisteredPopulation: "4026",
    VotingStationName: "ETHEMBENI METHODIST CHURCH",
    Address: "GXAKRA STREET  KAMVELIHLE  NELSON MANDELA METRO",
    Latitude: "-33.7975",
    Longitude: "25.6165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4761",
    WardLookupId: "4401",
    VDNumber: "10460124",
    RegisteredPopulation: "57",
    VotingStationName: "THE FARM TANKATARA (MR. LAKE)",
    Address: "OFF COEGA ROAD  COEGA  PORT ELIZABETH",
    Latitude: "-33.6824",
    Longitude: "25.7299",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4762",
    WardLookupId: "4401",
    VDNumber: "10460135",
    RegisteredPopulation: "2213",
    VotingStationName: "WABELUSI APOSTOLIC CHURCH",
    Address:
      "NDLANGISA  KAMVELIHLE MOTHERWELL  NELSON MANDELA METRO MUNICIPALITY",
    Latitude: "-33.7919",
    Longitude: "25.6114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4763",
    WardLookupId: "4401",
    VDNumber: "10510052",
    RegisteredPopulation: "225",
    VotingStationName: "AMANZI SCHOOL",
    Address: "UITENHAGE  ",
    Latitude: "-33.7162",
    Longitude: "25.5093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4764",
    WardLookupId: "4402",
    VDNumber: "10250873",
    RegisteredPopulation: "3179",
    VotingStationName: "PRESBYTERIAN CHURCH OF AFRICA",
    Address: "SADLUNGE STREET  MOTHERWELL NU 11  NELSON MANDELA METRO",
    Latitude: "-33.7825",
    Longitude: "25.5841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4765",
    WardLookupId: "4402",
    VDNumber: "10251166",
    RegisteredPopulation: "2905",
    VotingStationName: "MFESANE HIGH SCHOOL",
    Address: "INQU STREET  MOTHERWELL  NU12  PORT ELIZABETH",
    Latitude: "-33.7774",
    Longitude: "25.5868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4766",
    WardLookupId: "4402",
    VDNumber: "10251593",
    RegisteredPopulation: "2463",
    VotingStationName: "IMBASA PRIMARY SCHOOL",
    Address: "23463 XHAMA  MOTHERWELL NU12  PORT ELIZABETH",
    Latitude: "-33.7724",
    Longitude: "25.5872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4767",
    WardLookupId: "4402",
    VDNumber: "10251841",
    RegisteredPopulation: "3424",
    VotingStationName: "NU 29 METHODIST CHURCH",
    Address: "TJOKSVILLE  NU 29 MOTHERWELL  NELSON MANDELA METRO",
    Latitude: "-33.7751",
    Longitude: "25.5717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4768",
    WardLookupId: "4403",
    VDNumber: "10250884",
    RegisteredPopulation: "2164",
    VotingStationName: "NCEDO HIGH SCHOOL",
    Address: "MNENGA STREET  MOTHERWELL  PORT ELIZABETH",
    Latitude: "-33.7873",
    Longitude: "25.5917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4769",
    WardLookupId: "4403",
    VDNumber: "10250895",
    RegisteredPopulation: "2690",
    VotingStationName: "JERUSALEM CHURCH",
    Address: "DEVOS  MOTHERWELL  PORT ELIZABETH",
    Latitude: "-33.7959",
    Longitude: "25.5798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4770",
    WardLookupId: "4403",
    VDNumber: "10250907",
    RegisteredPopulation: "1852",
    VotingStationName: "MELISIZWE PRIMARY SCHOOL",
    Address: "21 NKUMBA STREET  MOTHERWELL NU 10  PORT ELIZABETH",
    Latitude: "-33.7906",
    Longitude: "25.5917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4771",
    WardLookupId: "4403",
    VDNumber: "10250918",
    RegisteredPopulation: "2619",
    VotingStationName: "ST JOHNS APOSTOLIC CHURCH",
    Address:
      "13 HESHU STREET  STEVE TSHWETE VILLAGE MOTHERWELL  NELSON MANDELA METRO",
    Latitude: "-33.7919",
    Longitude: "25.5833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4772",
    WardLookupId: "4403",
    VDNumber: "10251649",
    RegisteredPopulation: "1887",
    VotingStationName: "OLD BANTU CONGREGATION CHURCH",
    Address:
      "MUKABA                  SHUKUSHUKUMA  MOTHERWELL  NELSON MANDELA METRO MUNICIPALITY",
    Latitude: "-33.7967",
    Longitude: "25.5745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4773",
    WardLookupId: "4404",
    VDNumber: "10251021",
    RegisteredPopulation: "2903",
    VotingStationName: "AME CHURCH",
    Address: "CNR MVUBU & NYATHI  NU 1 MOTHERWELL  NELSON MANDELA BAY",
    Latitude: "-33.8018",
    Longitude: "25.598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4774",
    WardLookupId: "4404",
    VDNumber: "10251032",
    RegisteredPopulation: "1890",
    VotingStationName: "MBONISELO PRIMARY SCHOOL",
    Address: "NDLOVU STREET, NU 1  MOTHERWELL  PORT ELIZABETH",
    Latitude: "-33.7944",
    Longitude: "25.6002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4775",
    WardLookupId: "4404",
    VDNumber: "10251043",
    RegisteredPopulation: "2050",
    VotingStationName: "MASIPATISANE HIGH SCHOOL",
    Address: "71 NDLOVU STREET NU 1  MOTHERWELL  PORT ELIZABETH",
    Latitude: "-33.7969",
    Longitude: "25.603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4776",
    WardLookupId: "4404",
    VDNumber: "10251896",
    RegisteredPopulation: "685",
    VotingStationName: "QONGWE  TENT",
    Address: "QONGWE STREET  MOTHERWELL  PORT ELIZABETH",
    Latitude: "-33.7918",
    Longitude: "25.5928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4777",
    WardLookupId: "4405",
    VDNumber: "10250929",
    RegisteredPopulation: "3006",
    VotingStationName: "MOTHERWELL HIGH SCHOOL",
    Address: "MZWAZWA STREET  MOTHERWELL NU 7  PORT ELIZABETH",
    Latitude: "-33.8151",
    Longitude: "25.5747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4778",
    WardLookupId: "4405",
    VDNumber: "10250930",
    RegisteredPopulation: "2526",
    VotingStationName: "FUMISAUKOMA PRIMARY SCHOOL",
    Address: "NGQOKWENA STREET  MOTHERWELL  PORT ELIZABETH",
    Latitude: "-33.8027",
    Longitude: "25.582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4779",
    WardLookupId: "4405",
    VDNumber: "10250952",
    RegisteredPopulation: "2578",
    VotingStationName: "NXANELWIMFUNDO PRIMARY SCHOOL",
    Address: "BUNYULUZA STREET  MOTHERWELL NU8  PORT ELIZABETH",
    Latitude: "-33.7971",
    Longitude: "25.5883",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4780",
    WardLookupId: "4405",
    VDNumber: "10251829",
    RegisteredPopulation: "911",
    VotingStationName: "ETHIOPIAN ORTHODOX CHURCH",
    Address: "INGWE STREET  MOTHERWELL  NELSON MANDELA METRO",
    Latitude: "-33.7972",
    Longitude: "25.5946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4781",
    WardLookupId: "4406",
    VDNumber: "10250941",
    RegisteredPopulation: "3306",
    VotingStationName: "SOQHAYISA SENIOR SECONDARY SCHOOL",
    Address: "NKOBONGO STREET  MOTHERWELL NU 6  PORT ELIZABETH",
    Latitude: "-33.8046",
    Longitude: "25.5834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4782",
    WardLookupId: "4406",
    VDNumber: "10250996",
    RegisteredPopulation: "2036",
    VotingStationName: "VEZUBUHLE PRIMARY SCHOOL",
    Address: "MDUNDU  MOTHERWELL NU 6  PORT ELIZABETH",
    Latitude: "-33.8179",
    Longitude: "25.5926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4783",
    WardLookupId: "4406",
    VDNumber: "10251010",
    RegisteredPopulation: "2796",
    VotingStationName: "ELUNDINI PRIMARY SCHOOL",
    Address: "BIKANA STREET  MOTHERWELL  PORT ELIZABETH",
    Latitude: "-33.8056",
    Longitude: "25.594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4784",
    WardLookupId: "4406",
    VDNumber: "10251830",
    RegisteredPopulation: "1538",
    VotingStationName: "RAYMOND MHLABA SPORT CENTRE",
    Address: "NDZUNGA  MOTHERWELL  PORT ELIZABETH",
    Latitude: "-33.8136",
    Longitude: "25.5912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4785",
    WardLookupId: "4407",
    VDNumber: "10250963",
    RegisteredPopulation: "3230",
    VotingStationName: "IKHWEZELIHLE PRIMARY SCHOOL",
    Address: "NYARA  MOTHERWELL NU 6  PORT ELIZABETH",
    Latitude: "-33.8179",
    Longitude: "25.5832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4786",
    WardLookupId: "4407",
    VDNumber: "10250974",
    RegisteredPopulation: "2989",
    VotingStationName: "KHULILE PRIMARY SCHOOL",
    Address: "NDAKANA STR  MOTHERWELL NU 7  PORT ELIZABETH",
    Latitude: "-33.8111",
    Longitude: "25.5849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4787",
    WardLookupId: "4407",
    VDNumber: "10250985",
    RegisteredPopulation: "2985",
    VotingStationName: "VULUMZI HIGH SCHOOL",
    Address: "CHALUMNA STREET  MOTHERWELL NU 5  PORT ELIZABETH",
    Latitude: "-33.8279",
    Longitude: "25.5947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4788",
    WardLookupId: "4408",
    VDNumber: "10251076",
    RegisteredPopulation: "3385",
    VotingStationName: "NG KERK SWARTKOPS RIVIER",
    Address: "ETSEBERG  BLUE WATER BAY  PORT ELIZABETH",
    Latitude: "-33.8514",
    Longitude: "25.6245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4789",
    WardLookupId: "4408",
    VDNumber: "10251638",
    RegisteredPopulation: "2794",
    VotingStationName: "COEGA PRI SCHOOL",
    Address: "POLSKA  WELLS ESTATE  PORT ELIZABETH",
    Latitude: "-33.8202",
    Longitude: "25.6348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4790",
    WardLookupId: "4408",
    VDNumber: "10251818",
    RegisteredPopulation: "4615",
    VotingStationName: "WELLS MAIN STREET TENT",
    Address: "WELLS ESTATE MAIN ROAD  WELLS ESTATE MOTHERWELL  PORT ELIZABETH",
    Latitude: "-33.8261",
    Longitude: "25.634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4791",
    WardLookupId: "4408",
    VDNumber: "10251874",
    RegisteredPopulation: "2200",
    VotingStationName: "SANDLWANA   STREET TENT",
    Address: "SANDLWANA  WELLS ESTATE  PORT ELIZABETH",
    Latitude: "-33.8334",
    Longitude: "25.6235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4792",
    WardLookupId: "3357",
    VDNumber: "21940017",
    RegisteredPopulation: "1964",
    VotingStationName: "JOHANNES MOKOPANE HALL",
    Address: "00 JACOB REGOP STREET   RELEBOHILE   LUCKHOFF",
    Latitude: "-29.7438",
    Longitude: "24.7789",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4793",
    WardLookupId: "3357",
    VDNumber: "21950018",
    RegisteredPopulation: "755",
    VotingStationName: "OPPERMANS INTERMEDIATE SCHOOL",
    Address: "64 SCHOOR  DORINGFONTEIN   OPPERMANSGRONDE",
    Latitude: "-29.4121",
    Longitude: "24.7439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4794",
    WardLookupId: "3357",
    VDNumber: "22100083",
    RegisteredPopulation: "191",
    VotingStationName: "ABENDRUHE FARM",
    Address: "S558 ABENDRUHE FARM  LUCKHOFF  LUCKHOFF",
    Latitude: "-29.6049",
    Longitude: "24.4517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4795",
    WardLookupId: "3357",
    VDNumber: "22100139",
    RegisteredPopulation: "102",
    VotingStationName: "WATERPUT (LEMAJALA FARM)",
    Address: "WATERPUT  LUCKHOFF  LUCKHOFF",
    Latitude: "-29.6573",
    Longitude: "24.7125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4796",
    WardLookupId: "3358",
    VDNumber: "21910025",
    RegisteredPopulation: "2370",
    VotingStationName: "IKANYEGENG GEMEENSKAPSKOOL",
    Address: "123 SEDITI STRAAT  RATANANG  JACOBSDAL",
    Latitude: "-29.1168",
    Longitude: "24.7594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4797",
    WardLookupId: "3358",
    VDNumber: "21910036",
    RegisteredPopulation: "1293",
    VotingStationName: "PANORAMA COMBINED SCHOOL",
    Address: "1 SCHOOL STREET   SANDERSHOOGTE  JACOBSDAL",
    Latitude: "-29.1211",
    Longitude: "24.755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4798",
    WardLookupId: "3359",
    VDNumber: "21910014",
    RegisteredPopulation: "716",
    VotingStationName: "JACOBSDAL TOWN HALL",
    Address: "1 ANDRIES PRETORIUS  JACOBSDAL  JACOBSDAL",
    Latitude: "-29.1307",
    Longitude: "24.7738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4799",
    WardLookupId: "3359",
    VDNumber: "21960031",
    RegisteredPopulation: "1547",
    VotingStationName: "IPETLENG SECONDARY SCHOOL",
    Address: "1107 SELEMELA STREET  LETSEMENG  BOLOKANANG PETRUSBURG",
    Latitude: "-29.1264",
    Longitude: "25.426",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4800",
    WardLookupId: "3359",
    VDNumber: "22100016",
    RegisteredPopulation: "129",
    VotingStationName: "RAMDAM FARM SHED",
    Address: "RAMDAM  RANDOM  JACOBSDAL",
    Latitude: "-29.3389",
    Longitude: "24.5979",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4801",
    WardLookupId: "3359",
    VDNumber: "22100027",
    RegisteredPopulation: "514",
    VotingStationName: "PHAMBILI PRIMARY SCHOOL",
    Address: "C/O THOMBSON STREET  RIETRIVER SCHEME  JACOBSDAL",
    Latitude: "-29.1075",
    Longitude: "24.6679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4802",
    WardLookupId: "3359",
    VDNumber: "22100094",
    RegisteredPopulation: "172",
    VotingStationName: "ORANJEPLAAS",
    Address: "S582 ORANJEPLAAS  PETRUSBURG  PETRUSBURG",
    Latitude: "-29.0232",
    Longitude: "25.5765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4803",
    WardLookupId: "3359",
    VDNumber: "22100106",
    RegisteredPopulation: "115",
    VotingStationName: "WITKRAAL [PAUL VORSTER FARM]",
    Address: "WITKRAAL  PETRUSBURG  PETRUSBURG",
    Latitude: "-28.9523",
    Longitude: "25.522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4804",
    WardLookupId: "3359",
    VDNumber: "22100128",
    RegisteredPopulation: "367",
    VotingStationName: "PERDEBERG",
    Address: "PERDEBERG  N8/S288  JACOBSDAL",
    Latitude: "-28.9834",
    Longitude: "25.0765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4805",
    WardLookupId: "3360",
    VDNumber: "21930016",
    RegisteredPopulation: "793",
    VotingStationName: "DIAMANDHOOGTE COMBINED SCHOOL",
    Address: "1 FIRST AVENUE  DIAMANTHOOGTE  KOFFIEFONTEIN",
    Latitude: "-29.3964",
    Longitude: "24.9925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4806",
    WardLookupId: "3360",
    VDNumber: "21930027",
    RegisteredPopulation: "1646",
    VotingStationName: "MULTI PURPOSE COMMUNITY CENTER",
    Address: "KOFFIEFONTEIN  KHAYELITSHA  KOFFIEFONTEIN",
    Latitude: "-29.3899",
    Longitude: "24.9944",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4807",
    WardLookupId: "3360",
    VDNumber: "22100151",
    RegisteredPopulation: "775",
    VotingStationName: "NEW BEGINNINGS CRECHE",
    Address: "SONWABILE  KOFFIEFONTEIN",
    Latitude: "-29.386",
    Longitude: "24.9886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4808",
    WardLookupId: "3361",
    VDNumber: "21930038",
    RegisteredPopulation: "1988",
    VotingStationName: "REIKAELETSE SECONDARY SCHOOL",
    Address: "1888 BERG STREET  DITLAHAKE LOCATION  KOFFIEFONTEIN",
    Latitude: "-29.3999",
    Longitude: "25.0007",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4809",
    WardLookupId: "3361",
    VDNumber: "21930049",
    RegisteredPopulation: "912",
    VotingStationName: "KOFFIEFONTEIN HOERSKOOL",
    Address: "02 HAMPDEN WEG  TOWN  KOFFIEFONTEIN",
    Latitude: "-29.4095",
    Longitude: "25.0039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4810",
    WardLookupId: "3361",
    VDNumber: "22100061",
    RegisteredPopulation: "107",
    VotingStationName: "SMALDEEL FARM",
    Address: "S136 SMALDEEL  PETRUSBURG  PETRUSBURG",
    Latitude: "-29.2778",
    Longitude: "25.3857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4811",
    WardLookupId: "3362",
    VDNumber: "21960019",
    RegisteredPopulation: "1865",
    VotingStationName: "BOLOKANANG COMMUNITY HALL",
    Address: "164 BOIKETLO STREET  BOLOKANANG  PETRUSBURG",
    Latitude: "-29.1277",
    Longitude: "25.4222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4812",
    WardLookupId: "3362",
    VDNumber: "21960020",
    RegisteredPopulation: "576",
    VotingStationName: "AJC JOOSTE HIGH SCHOOL",
    Address: "38 OSSEWA STREET  PETRUSBURG  PETRUSBURG",
    Latitude: "-29.1115",
    Longitude: "25.4167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4813",
    WardLookupId: "3362",
    VDNumber: "21960042",
    RegisteredPopulation: "629",
    VotingStationName: "INOSENG PRIMARY SCHOOL",
    Address: "SCHOOL CLASSROOM  SCHOOL  PETRUSBURG",
    Latitude: "-29.1295",
    Longitude: "25.4222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4814",
    WardLookupId: "911",
    VDNumber: "21980011",
    RegisteredPopulation: "2421",
    VotingStationName: "MATOPORONG COMMUNITY HALL",
    Address: "494 THUSO STREET  MATOPORONG  REDDERSBURG",
    Latitude: "-29.6521",
    Longitude: "26.1582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4815",
    WardLookupId: "911",
    VDNumber: "21980022",
    RegisteredPopulation: "677",
    VotingStationName: "BOSHOFF HALL",
    Address: "SCHEEPERS STREET  REDDERSBURG  REDDERSBURG",
    Latitude: "-29.647",
    Longitude: "26.171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4816",
    WardLookupId: "911",
    VDNumber: "22090058",
    RegisteredPopulation: "99",
    VotingStationName: "BETHANY FARM SHED",
    Address: "S468  BETHANY  EDENBURG",
    Latitude: "-29.5878",
    Longitude: "25.94",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4817",
    WardLookupId: "912",
    VDNumber: "22030018",
    RegisteredPopulation: "927",
    VotingStationName: "TROMPSBURG TOWN HALL",
    Address: "80 VOORTREKKER STREET  TROMPSBURG  TROMPSBURG",
    Latitude: "-30.0351",
    Longitude: "25.7785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4818",
    WardLookupId: "912",
    VDNumber: "22030029",
    RegisteredPopulation: "2083",
    VotingStationName: "MADIKGETLA PRIMARY SCHOOL",
    Address: "392 RAMOSITLE  MADIKGETLA  TROMPSBURG",
    Latitude: "-30.0257",
    Longitude: "25.7793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4819",
    WardLookupId: "912",
    VDNumber: "22090069",
    RegisteredPopulation: "76",
    VotingStationName: "BOOMPLAAS FARM",
    Address: "BOOMPLAAS FARM  TROMPSBURG  TROMPSBURG",
    Latitude: "-29.848",
    Longitude: "25.6482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4820",
    WardLookupId: "912",
    VDNumber: "22090070",
    RegisteredPopulation: "64",
    VotingStationName: "PYPKAN FARM",
    Address: "PYPKAN  EDENBURG  EDENBURG",
    Latitude: "-29.8139",
    Longitude: "25.9929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4821",
    WardLookupId: "912",
    VDNumber: "22090081",
    RegisteredPopulation: "90",
    VotingStationName: "LILYDANE FARM",
    Address: "LILYDANE FARM  TROMPSBURG  TROMPSBURG",
    Latitude: "-30.013",
    Longitude: "25.958",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4822",
    WardLookupId: "912",
    VDNumber: "22090115",
    RegisteredPopulation: "53",
    VotingStationName: "DIEPFONTEIN FARM BUILDING",
    Address: "S340  DIEPFONTEIN  BETHULIE",
    Latitude: "-30.31",
    Longitude: "26.1133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4823",
    WardLookupId: "912",
    VDNumber: "22090126",
    RegisteredPopulation: "77",
    VotingStationName: "RUSTFONTEIN FARMSHED",
    Address: "S535  RUSTFONTEIN  BETHULIE",
    Latitude: "-30.384",
    Longitude: "25.9884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4824",
    WardLookupId: "912",
    VDNumber: "22090137",
    RegisteredPopulation: "78",
    VotingStationName: "MORGENZON FARM SHED",
    Address: "R717  TROMPSBURG  TROMPSBURG",
    Latitude: "-29.908",
    Longitude: "25.8092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4825",
    WardLookupId: "912",
    VDNumber: "22090261",
    RegisteredPopulation: "81",
    VotingStationName: "PLAATJIESFONTEIN",
    Address: "R704  PLAATJIESFONTEIN  TROMPSBURG",
    Latitude: "-29.8916",
    Longitude: "25.6375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4826",
    WardLookupId: "913",
    VDNumber: "21850017",
    RegisteredPopulation: "1391",
    VotingStationName: "BETHULIE TOWN HALL",
    Address: "2 VOORTREKKER  BETHULIE  BETHULIE",
    Latitude: "-30.4998",
    Longitude: "25.975",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4827",
    WardLookupId: "913",
    VDNumber: "21850028",
    RegisteredPopulation: "2351",
    VotingStationName: "WONGALETHU SECONDARY SCHOOL",
    Address: "1047 JIM FOUCHE  LEPHOI  BETHULIE",
    Latitude: "-30.5063",
    Longitude: "25.9777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4828",
    WardLookupId: "914",
    VDNumber: "21970010",
    RegisteredPopulation: "784",
    VotingStationName: "PHILIPPOLIS TOWN HALL",
    Address: "50 KOK STREET  PHILIPPOLIS  PHILIPPOLIS",
    Latitude: "-30.2666",
    Longitude: "25.2739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4829",
    WardLookupId: "914",
    VDNumber: "22050010",
    RegisteredPopulation: "338",
    VotingStationName: "GARIEP DAM TOWN HALL",
    Address: "FAUNA PARK  GARIEP DAM  GARIEP DAM",
    Latitude: "-30.6018",
    Longitude: "25.4959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4830",
    WardLookupId: "914",
    VDNumber: "22050021",
    RegisteredPopulation: "579",
    VotingStationName: "HYDROPARK COMMUNITY HALL",
    Address: "HYDROPARK  KOPANONG [TROMPSBURG]",
    Latitude: "-30.5822",
    Longitude: "25.5065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4831",
    WardLookupId: "914",
    VDNumber: "22090159",
    RegisteredPopulation: "89",
    VotingStationName: "FARAOSFONTEIN FARM",
    Address: "FARAOSFONTEIN FARM  GARIEP DAM  GARIEP DAM",
    Latitude: "-30.4333",
    Longitude: "25.4887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4832",
    WardLookupId: "914",
    VDNumber: "22090294",
    RegisteredPopulation: "1492",
    VotingStationName: "PODING TSE ROLO COMMUNITY HALL",
    Address: "141 MOTSHOANE STREET  PODING TSE ROLO  PHILLIPOLIS",
    Latitude: "-30.2612",
    Longitude: "25.2842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4833",
    WardLookupId: "915",
    VDNumber: "21920048",
    RegisteredPopulation: "30",
    VotingStationName: "GOEDEHOOP FARM",
    Address: "GOEDEHOOP FARM  JAGERSFONTEIN  JAGERSFONTEIN",
    Latitude: "-29.809",
    Longitude: "25.5319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4834",
    WardLookupId: "915",
    VDNumber: "21970021",
    RegisteredPopulation: "34",
    VotingStationName: "CADEUA FARM",
    Address: "CADEAU FARM  PHILIPPOLIS  PHILIPPOLIS",
    Latitude: "-30.1261",
    Longitude: "25.1677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4835",
    WardLookupId: "915",
    VDNumber: "22010016",
    RegisteredPopulation: "686",
    VotingStationName: "SPRINGFONTEIN TOWN HALL",
    Address: "CHRISTIAAN &VAN REEBECK STREET  SPRINGFONTEIN  SPRINGFONTEIN",
    Latitude: "-30.2668",
    Longitude: "25.7048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4836",
    WardLookupId: "915",
    VDNumber: "22010027",
    RegisteredPopulation: "1503",
    VotingStationName: "SPRINGFONTEIN PRIMARY SCHOOL",
    Address: "626 ZOCO  KOPANONG [TROMPSBURG]  SPRINGFONTEIN",
    Latitude: "-30.2608",
    Longitude: "25.7175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4837",
    WardLookupId: "915",
    VDNumber: "22030030",
    RegisteredPopulation: "35",
    VotingStationName: "KRAAIFONTEIN FARM",
    Address: "R717  TROMPSBURG  TROMPSBURG",
    Latitude: "-30.0812",
    Longitude: "25.6656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4838",
    WardLookupId: "915",
    VDNumber: "22090193",
    RegisteredPopulation: "51",
    VotingStationName: "MODDERSFONTEIN FARM",
    Address: "MODDERSFONTEIN FARM  PHILIPPOLIS  PHILIPPOLIS",
    Latitude: "-30.077",
    Longitude: "25.0564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4839",
    WardLookupId: "915",
    VDNumber: "22090216",
    RegisteredPopulation: "88",
    VotingStationName: "GARINGBOOM FARM",
    Address: "R715  GARINGBOOM  SPRINGFONTEIN",
    Latitude: "-30.3734",
    Longitude: "25.8127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4840",
    WardLookupId: "915",
    VDNumber: "22090227",
    RegisteredPopulation: "116",
    VotingStationName: "STERKFONTEIN FARM BUILDING",
    Address: "R701  STERKFONTEIN  BETHULIE",
    Latitude: "-30.5445",
    Longitude: "25.6706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4841",
    WardLookupId: "915",
    VDNumber: "22090306",
    RegisteredPopulation: "50",
    VotingStationName: "PEPERSFONTEIN FARM",
    Address: "R717  TROMPSBURG  TROMPSBURG",
    Latitude: "-30.1574",
    Longitude: "25.5274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4842",
    WardLookupId: "915",
    VDNumber: "22100038",
    RegisteredPopulation: "92",
    VotingStationName: "LUIPERDSRAND SKOOL",
    Address: "S883  LUIPERDSRAND  KOPANONG",
    Latitude: "-29.9191",
    Longitude: "25.1191",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4843",
    WardLookupId: "915",
    VDNumber: "22100140",
    RegisteredPopulation: "73",
    VotingStationName: "HALFWEG FARM",
    Address: "S135  HALFWEG  PHILLIPOLIS",
    Latitude: "-29.9998",
    Longitude: "25.3427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4844",
    WardLookupId: "916",
    VDNumber: "21920015",
    RegisteredPopulation: "2072",
    VotingStationName: "MAYIBUYE COMMUNITY HALL",
    Address: "1292 SEEKOEI STREET  MAYIBUYE  JAGERSFONTEIN",
    Latitude: "-29.774",
    Longitude: "25.4441",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4845",
    WardLookupId: "916",
    VDNumber: "21920026",
    RegisteredPopulation: "1075",
    VotingStationName: "JAGERSFONTEIN TOWN HALL",
    Address: "MARK PLEIN  JAGERSFONTEIN  JAGERSFONTEIN",
    Latitude: "-29.7616",
    Longitude: "25.4252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4846",
    WardLookupId: "917",
    VDNumber: "21900013",
    RegisteredPopulation: "2206",
    VotingStationName: "JACOB ZUMA COMMUNITY HALL",
    Address: "1270 IPOPENG  IPOPENG TOWNSHIP  FAURESMITH",
    Latitude: "-29.7376",
    Longitude: "25.3135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4847",
    WardLookupId: "917",
    VDNumber: "22080169",
    RegisteredPopulation: "143",
    VotingStationName: "TIERPOORT BESPROEIINGSAAL",
    Address: "S426  TIERPOORT  BLOEMFONTEIN",
    Latitude: "-29.4507",
    Longitude: "26.0373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4848",
    WardLookupId: "917",
    VDNumber: "22080170",
    RegisteredPopulation: "111",
    VotingStationName: "HAGESDAM SCHOOL",
    Address: "HAGESDAM  JAGERSFONTEIN  BLOEMFONTEIN",
    Latitude: "-29.3477",
    Longitude: "25.8015",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4849",
    WardLookupId: "917",
    VDNumber: "22080181",
    RegisteredPopulation: "160",
    VotingStationName: "WILLIAMS STRIP FARM SCHOOL",
    Address: "R706  WILLIAMSTRIP  BLOEMFONTEIN",
    Latitude: "-29.3181",
    Longitude: "25.8906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4850",
    WardLookupId: "917",
    VDNumber: "22100049",
    RegisteredPopulation: "100",
    VotingStationName: "NOOITGEDACHT FARM",
    Address: "R706  NOOITGEDACHT  JAGERSFONTEIN",
    Latitude: "-29.6331",
    Longitude: "25.6352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4851",
    WardLookupId: "917",
    VDNumber: "22100050",
    RegisteredPopulation: "148",
    VotingStationName: "SORGVLIET FARM BUILDING",
    Address: "R706  SORVLEIT  JAGERSFONTEIN",
    Latitude: "-29.5004",
    Longitude: "25.5281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4852",
    WardLookupId: "918",
    VDNumber: "21890011",
    RegisteredPopulation: "2202",
    VotingStationName: "BOTLE BA THUTO PRIMARY SCHOOL",
    Address: "1044 VELEKO STREET  HA-RASEBEI  EDENBURG",
    Latitude: "-29.727",
    Longitude: "25.9477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4853",
    WardLookupId: "918",
    VDNumber: "21890022",
    RegisteredPopulation: "1046",
    VotingStationName: "EDENBURG TOWN HALL",
    Address: "CNR PIET RETIEF & KERK STREET  EDENBURG  EDENBURG",
    Latitude: "-29.7364",
    Longitude: "25.935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4854",
    WardLookupId: "919",
    VDNumber: "22070034",
    RegisteredPopulation: "2001",
    VotingStationName: "ZAMA PRIMARY SCHOOL",
    Address: "SITE NO 1933 ITUMELENG LOCATIO  MATLAKENG  ZASTRON",
    Latitude: "-30.2918",
    Longitude: "27.1025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4855",
    WardLookupId: "919",
    VDNumber: "22070056",
    RegisteredPopulation: "1479",
    VotingStationName: "ITUMELENG FOOTBALL GROUND",
    Address: "1295 ITUMELENG LOCATION  MATLAKENG  ZASTRON",
    Latitude: "-30.2859",
    Longitude: "27.1084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4856",
    WardLookupId: "920",
    VDNumber: "21990012",
    RegisteredPopulation: "719",
    VotingStationName: "ROUXVILLE TOWN HALL",
    Address: "PIET RETIEF STREET  ROUXVILLE  ",
    Latitude: "-30.4062",
    Longitude: "26.8285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4857",
    WardLookupId: "920",
    VDNumber: "21990045",
    RegisteredPopulation: "1602",
    VotingStationName: "JB TYU PRIMARY SCHOOL",
    Address: "142 NTOBELA STREET  ROLELATHUNYA  ROUXVILLE",
    Latitude: "-30.4065",
    Longitude: "26.8281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4858",
    WardLookupId: "920",
    VDNumber: "22110028",
    RegisteredPopulation: "77",
    VotingStationName: "RUST EN VREDE",
    Address: "RUST EN VREDE FARM  ROUXVILLE  ROUVILLE",
    Latitude: "-30.5087",
    Longitude: "26.9202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4859",
    WardLookupId: "920",
    VDNumber: "22110084",
    RegisteredPopulation: "34",
    VotingStationName: "WASCHBANK",
    Address: "WASCHBANK FARM, N6 ROAD S397  ROUXVILLE  ROUXVILLE",
    Latitude: "-30.2708",
    Longitude: "26.7656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4860",
    WardLookupId: "920",
    VDNumber: "22110130",
    RegisteredPopulation: "54",
    VotingStationName: "THE WILLOWS",
    Address: "THE WILLOWS FARM,N6 ROAD,T1132  ROUXVILLE  ROUXVILLE",
    Latitude: "-30.6014",
    Longitude: "26.7651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4861",
    WardLookupId: "920",
    VDNumber: "22110141",
    RegisteredPopulation: "84",
    VotingStationName: "BOSCHENDAL",
    Address: "BOSCHEMDAL FARM  ALIWAL NORTH  ALIWAL NORTH",
    Latitude: "-30.6206",
    Longitude: "26.5836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4862",
    WardLookupId: "920",
    VDNumber: "22110163",
    RegisteredPopulation: "43",
    VotingStationName: "BEKKERSKRAAL OUT BUILDINGS",
    Address: "BEKKERSKRAAL N6 ROAD S397  ROUXVILLE  ROUXVILLE",
    Latitude: "-30.4137",
    Longitude: "26.5599",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4863",
    WardLookupId: "920",
    VDNumber: "22110310",
    RegisteredPopulation: "76",
    VotingStationName: "LALAPANSI SCHOOL",
    Address: "LALAPANSI  SMITHFIELD  SMITHFIELD",
    Latitude: "-30.324",
    Longitude: "26.3852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4864",
    WardLookupId: "920",
    VDNumber: "22110455",
    RegisteredPopulation: "373",
    VotingStationName: "GOEDEMOED MEDIUM A SCHOOL",
    Address: "GOEDEMOED   ALIWAL NORTH",
    Latitude: "-30.5629",
    Longitude: "26.3906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4865",
    WardLookupId: "921",
    VDNumber: "22070012",
    RegisteredPopulation: "1547",
    VotingStationName: "ZASTRON PUBLIC SCHOOL",
    Address: "447 SITE OLD LOCATION  MATLAKENG TOWNSHIP  ZASTRON",
    Latitude: "-30.2991",
    Longitude: "27.0941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4866",
    WardLookupId: "921",
    VDNumber: "22070067",
    RegisteredPopulation: "692",
    VotingStationName: "KHANYA TEMPORARY (TENT)",
    Address: "REFENG KGOTSO  ZASTRON  ZASTRON",
    Latitude: "-30.2924",
    Longitude: "27.0941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4867",
    WardLookupId: "921",
    VDNumber: "22110095",
    RegisteredPopulation: "25",
    VotingStationName: "RIETFONTEIN SCHOOL",
    Address: "RIETFONTEIN  ROUXVILLE  ROUXVILLE",
    Latitude: "-30.4587",
    Longitude: "26.985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4868",
    WardLookupId: "921",
    VDNumber: "22110208",
    RegisteredPopulation: "78",
    VotingStationName: "PIETERSRUST FARM",
    Address: "PIETERSRUST FARM BOX 76  ZASTRON  ZASTRON",
    Latitude: "-30.3707",
    Longitude: "27.2835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4869",
    WardLookupId: "921",
    VDNumber: "22110219",
    RegisteredPopulation: "160",
    VotingStationName: "PIETERSFONTEIN WORKSHOP",
    Address: "PIETERSFONTEIN  ZASTRON  ZASTRON DISTRICT",
    Latitude: "-30.083",
    Longitude: "27.2097",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4870",
    WardLookupId: "921",
    VDNumber: "22110354",
    RegisteredPopulation: "68",
    VotingStationName: "MIDDELDAM WORKSHOP",
    Address: "MIDDELDAM  ZASTRON  ZASTRON",
    Latitude: "-30.4419",
    Longitude: "27.1186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4871",
    WardLookupId: "921",
    VDNumber: "22110365",
    RegisteredPopulation: "86",
    VotingStationName: "TRAUTENAU WORKSHOP",
    Address: "TRAUTENAU  ZASTRON  ZASTRON DISTRICT",
    Latitude: "-30.2502",
    Longitude: "27.2789",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4872",
    WardLookupId: "921",
    VDNumber: "22110422",
    RegisteredPopulation: "110",
    VotingStationName: "ABOUKIR",
    Address: "ABOUKIR  ZASTRON  MOHOKARE [ZASTRON]",
    Latitude: "-30.1733",
    Longitude: "27.0926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4873",
    WardLookupId: "922",
    VDNumber: "21990023",
    RegisteredPopulation: "1171",
    VotingStationName: "ROLELATHUNYA COMMUNITY HALL",
    Address: "PHOSHOLI STREET  ROUXVILLE  ROUXVILLE",
    Latitude: "-30.407",
    Longitude: "26.8308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4874",
    WardLookupId: "922",
    VDNumber: "22110129",
    RegisteredPopulation: "239",
    VotingStationName: "BLYDSKAP SCHOOL",
    Address: "WEENKOP  ROUXVILLE",
    Latitude: "-30.1568",
    Longitude: "26.8525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4875",
    WardLookupId: "922",
    VDNumber: "22110152",
    RegisteredPopulation: "49",
    VotingStationName: "AMSTERDAM",
    Address: "AMSTERDAM  SMITHFIELD  SMITHFIELD",
    Latitude: "-30.1668",
    Longitude: "26.7113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4876",
    WardLookupId: "922",
    VDNumber: "22110297",
    RegisteredPopulation: "139",
    VotingStationName: "HEVITIA SCHOOL",
    Address: "FS163 - MOHOKARE [ZASTRON]   ",
    Latitude: "-29.8724",
    Longitude: "26.5521",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4877",
    WardLookupId: "922",
    VDNumber: "22110343",
    RegisteredPopulation: "57",
    VotingStationName: "NOOITGEDAGHT",
    Address: "NOOITGEDAGHT  SMITHFIELD  SMITHFIELD",
    Latitude: "-30.0084",
    Longitude: "26.721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4878",
    WardLookupId: "922",
    VDNumber: "22110411",
    RegisteredPopulation: "66",
    VotingStationName: "KLAWERHOEK FARM",
    Address: "POBOX172  ROUXVILLE  ROUXVILLE",
    Latitude: "-30.2372",
    Longitude: "26.7146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4879",
    WardLookupId: "922",
    VDNumber: "22110466",
    RegisteredPopulation: "682",
    VotingStationName: "UMZIWOXOLO ADULT CENTRE",
    Address: "FS163 - MOHOKARE [ZASTRON]  ",
    Latitude: "-30.4072",
    Longitude: "26.8407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4880",
    WardLookupId: "923",
    VDNumber: "22070023",
    RegisteredPopulation: "1859",
    VotingStationName: "MATLAKENG COMMUNITY HALL",
    Address: "MATLAKENG  ZASTRON",
    Latitude: "-30.2946",
    Longitude: "27.0991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4881",
    WardLookupId: "923",
    VDNumber: "22070045",
    RegisteredPopulation: "1235",
    VotingStationName: "ZASTRON TOWN HALL",
    Address: "1 HOOFD STREET  ZASTRON  ZASTRON",
    Latitude: "-30.3022",
    Longitude: "27.086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4882",
    WardLookupId: "924",
    VDNumber: "22000015",
    RegisteredPopulation: "2133",
    VotingStationName: "GEORGE RANTEE HALL",
    Address: "MOFULATSHEPE  SMITHFIELD  SMITHFIELD",
    Latitude: "-30.2126",
    Longitude: "26.5412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4883",
    WardLookupId: "924",
    VDNumber: "22000026",
    RegisteredPopulation: "944",
    VotingStationName: "SMITHFIELD TOWN HALL",
    Address: "BRAND ST  SMITHFIELD  SMITHFIELD",
    Latitude: "-30.2153",
    Longitude: "26.5316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4884",
    WardLookupId: "924",
    VDNumber: "22090036",
    RegisteredPopulation: "44",
    VotingStationName: "VENTERSKROON",
    Address: "VENTERSKROON FARM  SMITHFIELD  SMITHFIELD",
    Latitude: "-29.8322",
    Longitude: "26.3421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4885",
    WardLookupId: "924",
    VDNumber: "22110039",
    RegisteredPopulation: "97",
    VotingStationName: "BOESMANSBERG",
    Address: "BOESMANSBERG FARM  SMITHFIELD  SMITHFIELD",
    Latitude: "-30.1801",
    Longitude: "26.2629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4886",
    WardLookupId: "924",
    VDNumber: "22110062",
    RegisteredPopulation: "81",
    VotingStationName: "STRUBEN",
    Address: "STRUBEN  STRUBEN FARM  SMITHFIELD",
    Latitude: "-29.9629",
    Longitude: "26.4209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4887",
    WardLookupId: "924",
    VDNumber: "22110196",
    RegisteredPopulation: "140",
    VotingStationName: "ETHELIA SCHOOL",
    Address: "POBOX25 9940  DEWETSDORP  DEWETSDORP",
    Latitude: "-29.6199",
    Longitude: "26.5001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4888",
    WardLookupId: "924",
    VDNumber: "22110332",
    RegisteredPopulation: "59",
    VotingStationName: "KGOTSO PULA PRIMARY SCHOOL",
    Address:
      "BENONI, N6 ROAD S119  FS163 - MOHOKARE [ZASTRON]   FS163 - MOHOKARE [ZASTRON]",
    Latitude: "-30.0908",
    Longitude: "26.3993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4889",
    WardLookupId: "925",
    VDNumber: "22480017",
    RegisteredPopulation: "1954",
    VotingStationName: "MATSHEDISO SECONDARY SCHOOL",
    Address: "P O  BOX  373  MAJWEMASWEU  BRANDFORT",
    Latitude: "-28.6827",
    Longitude: "26.4621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4890",
    WardLookupId: "925",
    VDNumber: "22480040",
    RegisteredPopulation: "1341",
    VotingStationName: "BRANDFORT TOWN HALL",
    Address: "CNR MARKET & BRAND  BRANDFORT  BRANDFORT",
    Latitude: "-28.7022",
    Longitude: "26.458",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4891",
    WardLookupId: "926",
    VDNumber: "22480051",
    RegisteredPopulation: "1556",
    VotingStationName: "KEARABETSWE DAY CARE",
    Address: "MAJWEMASWEU  BRANDFORT  MANDELA VIEW",
    Latitude: "-28.6921",
    Longitude: "26.4518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4892",
    WardLookupId: "926",
    VDNumber: "22480062",
    RegisteredPopulation: "222",
    VotingStationName: "PRESIDENT SWART HOÃ‹RSKOOL",
    Address: "72 VOORTREKKER STREET  BRANDFORT TOWN  BRANDFORT",
    Latitude: "-28.7028",
    Longitude: "26.4569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4893",
    WardLookupId: "926",
    VDNumber: "22630025",
    RegisteredPopulation: "80",
    VotingStationName: "DANIELSRUST FARM",
    Address: "DANIELSRUST  BRANDFORT  FARMER",
    Latitude: "-28.6733",
    Longitude: "26.5856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4894",
    WardLookupId: "926",
    VDNumber: "22630115",
    RegisteredPopulation: "112",
    VotingStationName: "KARROO FARM",
    Address: "P O  BOX  330  KARROO  BRANDFORT",
    Latitude: "-28.6271",
    Longitude: "26.2425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4895",
    WardLookupId: "926",
    VDNumber: "22630126",
    RegisteredPopulation: "27",
    VotingStationName: "KLIPPAN FARM",
    Address: "KLIPPAN  SOUTPAN  FARMER",
    Latitude: "-28.6397",
    Longitude: "26.1378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4896",
    WardLookupId: "926",
    VDNumber: "22630137",
    RegisteredPopulation: "149",
    VotingStationName: "THALIA SCHOOL",
    Address: "47 P O  BOX  THALIA  BRANDFORT",
    Latitude: "-28.472",
    Longitude: "26.4292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4897",
    WardLookupId: "926",
    VDNumber: "22630148",
    RegisteredPopulation: "76",
    VotingStationName: "ZWARTKOP FARM",
    Address: "P O  BOX  362  BRANDFORT  BRANDFORT   9400",
    Latitude: "-28.7985",
    Longitude: "26.4681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4898",
    WardLookupId: "926",
    VDNumber: "22630238",
    RegisteredPopulation: "109",
    VotingStationName: "LOUISDAL FARM",
    Address: "NEXT TO BRANDFORT &BLOEMFONTEIN ROAD  RURAL AREA  BRANDFORT",
    Latitude: "-28.7913",
    Longitude: "26.3805",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4899",
    WardLookupId: "926",
    VDNumber: "22640093",
    RegisteredPopulation: "117",
    VotingStationName: "MAPULE FARM SCHOOL (HALL)",
    Address: "P O  BOX  127  GRASVLEI  THEUNISSEN",
    Latitude: "-28.4626",
    Longitude: "26.5349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4900",
    WardLookupId: "927",
    VDNumber: "22560117",
    RegisteredPopulation: "1223",
    VotingStationName: "HEAVEN FOR ALL PEOPLE MIRACLE CENTRE",
    Address: "239 MASILO  THEUNISSEN  MASILO",
    Latitude: "-28.4223",
    Longitude: "26.7062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4901",
    WardLookupId: "927",
    VDNumber: "22580018",
    RegisteredPopulation: "1484",
    VotingStationName: "ROMAN CATHOLIC",
    Address: "111 WINBURG  WIMBURG",
    Latitude: "-28.8415",
    Longitude: "26.7581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4902",
    WardLookupId: "927",
    VDNumber: "22630272",
    RegisteredPopulation: "115",
    VotingStationName: "LUNA FARM 38",
    Address: "NO STREET NAME  VERKEERDEVLEI  LUNA FARM 38",
    Latitude: "-28.8884",
    Longitude: "26.5183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4903",
    WardLookupId: "927",
    VDNumber: "22640015",
    RegisteredPopulation: "96",
    VotingStationName: "CANDY FARM",
    Address: "CANDY  WINBURG  WINBURG",
    Latitude: "-28.6531",
    Longitude: "26.8506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4904",
    WardLookupId: "927",
    VDNumber: "22640026",
    RegisteredPopulation: "99",
    VotingStationName: "JUNCTIONSPRUIT PRIMARY SCHOOL",
    Address: "JUNCTIONSPRUIT FARM  JUNCTIONSPRUIT  WINBURG",
    Latitude: "-28.7372",
    Longitude: "26.9519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4905",
    WardLookupId: "927",
    VDNumber: "22640082",
    RegisteredPopulation: "53",
    VotingStationName: "TAMA FARM",
    Address: "P O  BOX  85  TAMA  THEUNISSEN",
    Latitude: "-28.4912",
    Longitude: "26.7049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4906",
    WardLookupId: "927",
    VDNumber: "22640307",
    RegisteredPopulation: "43",
    VotingStationName: "SANDVET WATER ASSOCIATION",
    Address: "SANDVET WATER ASSOCIATION  THEUNISSEN  THEUNISSEN",
    Latitude: "-28.5065",
    Longitude: "26.7751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4907",
    WardLookupId: "928",
    VDNumber: "22620024",
    RegisteredPopulation: "1037",
    VotingStationName: "UNITED REFORM CHURCH (URC)",
    Address: "1095 MAKELEKETLA  FORA  WINBURG",
    Latitude: "-28.5389",
    Longitude: "27.0003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4908",
    WardLookupId: "928",
    VDNumber: "22620035",
    RegisteredPopulation: "857",
    VotingStationName: "WINBURG TOWN HALL",
    Address: "26 BRAND STREET  WINBURG  WINBURG",
    Latitude: "-28.519",
    Longitude: "27.013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4909",
    WardLookupId: "928",
    VDNumber: "22620046",
    RegisteredPopulation: "1320",
    VotingStationName: "TLONG-KGANYENG SCHOOL",
    Address: "1094 MAKELEKETLA  WINBURG  WINBURG",
    Latitude: "-28.5305",
    Longitude: "27.0058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4910",
    WardLookupId: "929",
    VDNumber: "22620013",
    RegisteredPopulation: "2888",
    VotingStationName: "MAKELEKETLA COMMUNITY HALL",
    Address: "929 MAKELEKETLA  MAKELEKETLA  WINBURG",
    Latitude: "-28.5319",
    Longitude: "27.002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4911",
    WardLookupId: "929",
    VDNumber: "22620068",
    RegisteredPopulation: "873",
    VotingStationName: "NALEDI YA BOCHABELA HIGH SCHOOL",
    Address: "00 BOITUMELO LOCATION  WINBURG  MAKELEKETLA",
    Latitude: "-28.5351",
    Longitude: "27.0009",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4912",
    WardLookupId: "930",
    VDNumber: "22560049",
    RegisteredPopulation: "1189",
    VotingStationName: "THEUNISSEN TOWN HALL",
    Address: "LE ROUX STREET  55  THEUNISSEN  THEUNISSEN",
    Latitude: "-28.401",
    Longitude: "26.7122",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4913",
    WardLookupId: "930",
    VDNumber: "22560072",
    RegisteredPopulation: "1181",
    VotingStationName: "BEATRIX MINE",
    Address: "P O  BOX  2823 WELKOM  LEEUBULT  THEUNISSEN",
    Latitude: "-28.2612",
    Longitude: "26.782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4914",
    WardLookupId: "930",
    VDNumber: "22560083",
    RegisteredPopulation: "144",
    VotingStationName: "THAKABANNA",
    Address: "PO BOX 161  THAKABANNA  THEUNNISSEN",
    Latitude: "-28.3199",
    Longitude: "26.7634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4915",
    WardLookupId: "930",
    VDNumber: "22560094",
    RegisteredPopulation: "198",
    VotingStationName: "JOEL MINE HOSTEL",
    Address: "LEEUFONTEIN 128  DISTRICT THEUNISSEN  THEUNISSEN",
    Latitude: "-28.2618",
    Longitude: "26.8175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4916",
    WardLookupId: "930",
    VDNumber: "22620057",
    RegisteredPopulation: "68",
    VotingStationName: "WESSELSPUNT FARM",
    Address: "WESSELSPUNT  WINBURG  FARMER",
    Latitude: "-28.4866",
    Longitude: "27.1776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4917",
    WardLookupId: "930",
    VDNumber: "22640116",
    RegisteredPopulation: "111",
    VotingStationName: "AVONDSRUST FARM",
    Address: "P O  BOX  336  AVONDRUST  THEUNISSEN",
    Latitude: "-28.282",
    Longitude: "26.7084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4918",
    WardLookupId: "930",
    VDNumber: "22640318",
    RegisteredPopulation: "136",
    VotingStationName: "LANGVERWACHT FARM",
    Address: "LANGVERWACHT  WINBURG",
    Latitude: "-28.4666",
    Longitude: "26.9018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4919",
    WardLookupId: "931",
    VDNumber: "22560016",
    RegisteredPopulation: "1406",
    VotingStationName: "RETSWELLETSE ABET CENTRE",
    Address: "1155 MASILO  MASILO  THEUNISSEN",
    Latitude: "-28.4056",
    Longitude: "26.7027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4920",
    WardLookupId: "931",
    VDNumber: "22560061",
    RegisteredPopulation: "1437",
    VotingStationName: "TAIWE SECONDARY SCHOOL",
    Address: "3601 BASIL READ MASILO  MASILO  THEUNISSEN",
    Latitude: "-28.4118",
    Longitude: "26.6954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4921",
    WardLookupId: "932",
    VDNumber: "22560038",
    RegisteredPopulation: "1467",
    VotingStationName: "AFRICAN NATIONAL CHURCH",
    Address: "THEUNISSEN  MASILO  CHURCH BOARD",
    Latitude: "-28.4197",
    Longitude: "26.698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4922",
    WardLookupId: "932",
    VDNumber: "22560106",
    RegisteredPopulation: "1567",
    VotingStationName: "MAMELLO DAY CARE",
    Address: "MASILO  THEUNISSEN  MASILONYANA",
    Latitude: "-28.4237",
    Longitude: "26.6928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4923",
    WardLookupId: "933",
    VDNumber: "22560027",
    RegisteredPopulation: "1384",
    VotingStationName: "BOLIBA PRIMARY SCHOOL",
    Address: "674 MASILO  THEUNISSEN",
    Latitude: "-28.4118",
    Longitude: "26.7036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4924",
    WardLookupId: "933",
    VDNumber: "22560050",
    RegisteredPopulation: "1778",
    VotingStationName: "UNITING REFORMED CHURCH",
    Address: "111 ECO VILLAGE  MASILO",
    Latitude: "-28.415",
    Longitude: "26.7121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4925",
    WardLookupId: "934",
    VDNumber: "22480028",
    RegisteredPopulation: "863",
    VotingStationName: "MONAMODI PRIMARY SCHOOL",
    Address: "P O  BOX  20  MAJWEMASWEU  BRANDFORT   9400",
    Latitude: "-28.6883",
    Longitude: "26.4635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4926",
    WardLookupId: "934",
    VDNumber: "22480039",
    RegisteredPopulation: "1937",
    VotingStationName: "TSHWARANANG DAY CARE CENTRE",
    Address: "111 MAJWEMASWEU  MAJWEMASWEU BRANDFORT  MASILONYANA MUNICIPALITY",
    Latitude: "-28.6872",
    Longitude: "26.4565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4927",
    WardLookupId: "3363",
    VDNumber: "22500010",
    RegisteredPopulation: "2528",
    VotingStationName: "TSHWARAGANANG",
    Address: "2000 POTLAKI  DEALESVILLE  DEALESVILLE",
    Latitude: "-28.6592",
    Longitude: "25.7719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4928",
    WardLookupId: "3363",
    VDNumber: "22500032",
    RegisteredPopulation: "666",
    VotingStationName: "KGOLOLOSEGO HIGH SCHOOL",
    Address: "(FS182 - TOKOLOGO [DEALESVILLE])   ",
    Latitude: "-28.674",
    Longitude: "25.7634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4929",
    WardLookupId: "3363",
    VDNumber: "22630081",
    RegisteredPopulation: "96",
    VotingStationName: "SPRINGBOK FARM",
    Address: "SPRINGBOK FARM  DEALESVILLE",
    Latitude: "-28.536",
    Longitude: "25.665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4930",
    WardLookupId: "3363",
    VDNumber: "22630092",
    RegisteredPopulation: "75",
    VotingStationName: "KAREELAAGTE",
    Address: "POSBUS 195  KREELAAGTE  BOSHOF",
    Latitude: "-28.588",
    Longitude: "25.5028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4931",
    WardLookupId: "3363",
    VDNumber: "22630104",
    RegisteredPopulation: "99",
    VotingStationName: "NIELSVIEW FARM (TENT)",
    Address: "NIELSVIEW FARM  DEALESVILLE  DEALESVILLE",
    Latitude: "-28.8882",
    Longitude: "25.6791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4932",
    WardLookupId: "3363",
    VDNumber: "22630227",
    RegisteredPopulation: "81",
    VotingStationName: "BERLYN FARM SCHOOL",
    Address: "DEALESVILLE FARM ROAD  BERLYN  DEALESVILLE",
    Latitude: "-28.5126",
    Longitude: "25.8508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4933",
    WardLookupId: "3363",
    VDNumber: "22630294",
    RegisteredPopulation: "59",
    VotingStationName: "GRASLAAGTE",
    Address: "GRASLAAGTE  HERTZOGVILLE  HERTZOGVILLE",
    Latitude: "-28.3092",
    Longitude: "25.6167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4934",
    WardLookupId: "3364",
    VDNumber: "22460015",
    RegisteredPopulation: "2526",
    VotingStationName: "ARAMELA SCHOOL SERETSE",
    Address: "349 MOSHOLODI STREET  SERETSE  BOSHOF",
    Latitude: "-28.5581",
    Longitude: "25.2532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4935",
    WardLookupId: "3364",
    VDNumber: "22460026",
    RegisteredPopulation: "1541",
    VotingStationName: "KAREEHOF COMMUNITY HALL",
    Address: "ELAND STREET  KAREEHOF  BOSHOF",
    Latitude: "-28.5436",
    Longitude: "25.25",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4936",
    WardLookupId: "3365",
    VDNumber: "22520023",
    RegisteredPopulation: "2456",
    VotingStationName: "SENZILE COMBINED SCHOOL",
    Address: "P.O. BOX 101  HERTZOGVILLE  HERTZOGVILLE",
    Latitude: "-28.1259",
    Longitude: "25.5078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4937",
    WardLookupId: "3365",
    VDNumber: "22630014",
    RegisteredPopulation: "97",
    VotingStationName: "HAMBURG STORE",
    Address: "HAMBURG  BOSHOF  BOSHOF",
    Latitude: "-28.4454",
    Longitude: "24.9723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4938",
    WardLookupId: "3365",
    VDNumber: "22630069",
    RegisteredPopulation: "101",
    VotingStationName: "SAMEKOMST",
    Address: "00 VAN DER MERWE  BOSHOF  BOSHOF",
    Latitude: "-28.8927",
    Longitude: "25.4317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4939",
    WardLookupId: "3365",
    VDNumber: "22630070",
    RegisteredPopulation: "110",
    VotingStationName: "DOORINGLAAGTE (TENT)",
    Address: "8340  BOSHOF  DOORINGLAAGTE",
    Latitude: "-28.6272",
    Longitude: "25.1115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4940",
    WardLookupId: "3365",
    VDNumber: "22630160",
    RegisteredPopulation: "126",
    VotingStationName: "MAMARIKA FARM SCHOOL",
    Address: "BEGINSIL  DEALESVILLE",
    Latitude: "-28.3979",
    Longitude: "25.5375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4941",
    WardLookupId: "3365",
    VDNumber: "22630171",
    RegisteredPopulation: "107",
    VotingStationName: "BIESIESBULT FARM TENT",
    Address: "BIESIESBULT FARM  BOSHOF  BIESIESBULT",
    Latitude: "-28.8234",
    Longitude: "25.2355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4942",
    WardLookupId: "3365",
    VDNumber: "22630216",
    RegisteredPopulation: "116",
    VotingStationName: "HELENA",
    Address: "POSBUS 140  HELENA  HERTZOGVILLE",
    Latitude: "-28.1998",
    Longitude: "25.4596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4943",
    WardLookupId: "3365",
    VDNumber: "22630250",
    RegisteredPopulation: "91",
    VotingStationName: "MIDDENDEEL",
    Address: "00 VAN DER MERWE  BOSHOF  BOSHOF",
    Latitude: "-28.7194",
    Longitude: "25.4141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4944",
    WardLookupId: "3366",
    VDNumber: "22520012",
    RegisteredPopulation: "2136",
    VotingStationName: "METHODIST CHURCH",
    Address: "337 ITIRELENG  HERTZOGVILLE  HERTZOGVILLE",
    Latitude: "-28.1202",
    Longitude: "25.4898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4945",
    WardLookupId: "3366",
    VDNumber: "22630036",
    RegisteredPopulation: "97",
    VotingStationName: "SWEETHOME FARM",
    Address: "HELPMAN-HOUMOED ROAD  HERTZOGVILLE  HERTZOGVILLE",
    Latitude: "-28.0476",
    Longitude: "25.493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4946",
    WardLookupId: "3366",
    VDNumber: "22630058",
    RegisteredPopulation: "59",
    VotingStationName: "DEODAT FARM",
    Address: "DEODAT FARM  HERTZOGVILLE  HERTZOGVILLE",
    Latitude: "-28.1546",
    Longitude: "25.278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4947",
    WardLookupId: "3366",
    VDNumber: "22630193",
    RegisteredPopulation: "111",
    VotingStationName: "ROOIDAM FARM",
    Address: "POSBUS 427  ROOIDAM  HERTZOGVILLE",
    Latitude: "-27.9981",
    Longitude: "25.3907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4948",
    WardLookupId: "3366",
    VDNumber: "22630261",
    RegisteredPopulation: "79",
    VotingStationName: "GELUK FARM",
    Address: "POSBUS 78  GELUK  BOSHOF",
    Latitude: "-28.2879",
    Longitude: "25.2164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4949",
    WardLookupId: "3366",
    VDNumber: "22630283",
    RegisteredPopulation: "74",
    VotingStationName: "LEEUWHEUVEL FARM",
    Address: "LEEUWHEUWEL ROAD  HERTZOGVILLE  TOKOLOGO",
    Latitude: "-28.0731",
    Longitude: "25.1824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4950",
    WardLookupId: "3366",
    VDNumber: "22630306",
    RegisteredPopulation: "40",
    VotingStationName: "CONSTANTIA FARM",
    Address: "121 HERTZOGVILLE  ",
    Latitude: "-28.2236",
    Longitude: "25.0358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4951",
    WardLookupId: "935",
    VDNumber: "22490052",
    RegisteredPopulation: "1707",
    VotingStationName: "RAINBOW HIGH SCHOOL",
    Address: "3590 MOOKODI SECTION  PHAHAMENG LOCATION  BULTFONTEIN",
    Latitude: "-28.3077",
    Longitude: "26.136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4952",
    WardLookupId: "935",
    VDNumber: "22490063",
    RegisteredPopulation: "1100",
    VotingStationName: "THE HOUSE OF PRAISE (FULL GOSPEL CHURCH)",
    Address:
      "2201 KOLOKOME STREET  KOPERASIE SECTION, PHAHAMENG LOCATION  BULTFONTEIN",
    Latitude: "-28.3081",
    Longitude: "26.1448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4953",
    WardLookupId: "936",
    VDNumber: "22490041",
    RegisteredPopulation: "1140",
    VotingStationName: "PHAHAMENG COMMUNITY HALL",
    Address: "1045 CHURCH STREET  PHAHAMENG  BULTFONTEIN",
    Latitude: "-28.3011",
    Longitude: "26.1489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4954",
    WardLookupId: "936",
    VDNumber: "22490074",
    RegisteredPopulation: "984",
    VotingStationName: "ST. PAUL SHINING STAR CHURCH",
    Address: "204 THEMBA STREET  MAOBA PARK, PHAHAMENG LOCATION  BULTFONTEIN",
    Latitude: "-28.304",
    Longitude: "26.1414",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4955",
    WardLookupId: "936",
    VDNumber: "22490096",
    RegisteredPopulation: "806",
    VotingStationName: "MECEDONIAN APOSTOLIC CHURCH IN ZION",
    Address: "402 LEBEKO STREET  KGOTHA SECTION  BULTFONTEIN",
    Latitude: "-28.3003",
    Longitude: "26.1354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4956",
    WardLookupId: "936",
    VDNumber: "22490120",
    RegisteredPopulation: "601",
    VotingStationName: "IKGWANTLELE PRIMARY SCHOOL",
    Address: "121 BUILTFONTEIN  ",
    Latitude: "-28.3015",
    Longitude: "26.1465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4957",
    WardLookupId: "936",
    VDNumber: "22490131",
    RegisteredPopulation: "449",
    VotingStationName: "PORTER`S HOUSE CHURCH",
    Address: "3080 SALIWE STREET  PHAHAMENG LOCATION  BULTFONTEIN",
    Latitude: "-28.2978",
    Longitude: "26.1464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4958",
    WardLookupId: "937",
    VDNumber: "22490030",
    RegisteredPopulation: "1746",
    VotingStationName: "REPHOLOSITSWE HIGH SCHOOL",
    Address: "1566 LUDADA STREET  PHAHAMENG LOCATION  BULTFONTEIN",
    Latitude: "-28.3057",
    Longitude: "26.1488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4959",
    WardLookupId: "937",
    VDNumber: "22490085",
    RegisteredPopulation: "799",
    VotingStationName: "VUKA CRECHE",
    Address:
      "4286 TSHEMOBI STREET  RDP SECTION, PHAHAMENG LOCATION  BULTFONTEIN",
    Latitude: "-28.3127",
    Longitude: "26.1447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4960",
    WardLookupId: "937",
    VDNumber: "22490108",
    RegisteredPopulation: "916",
    VotingStationName: "MAGAKAJANE PRIMARY SCHOOL",
    Address: "1428 BAROLONG STREET  PHAHAMENG LOCATION  BULTFONTEIN",
    Latitude: "-28.3039",
    Longitude: "26.1489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4961",
    WardLookupId: "938",
    VDNumber: "22490029",
    RegisteredPopulation: "1449",
    VotingStationName: "BULTFONTEIN TOWN HALL",
    Address: "01 BOSMAN STREET  BULTFONTEIN  BULTFONTEIN",
    Latitude: "-28.2909",
    Longitude: "26.1534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4962",
    WardLookupId: "938",
    VDNumber: "22490119",
    RegisteredPopulation: "1283",
    VotingStationName: "PHOLA CRECHE",
    Address: "693 CALEB MOTSHABI STREET  PHAHAMENG LOCATION   BULTFONTEIN",
    Latitude: "-28.3039",
    Longitude: "26.13",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4963",
    WardLookupId: "938",
    VDNumber: "22650476",
    RegisteredPopulation: "1028",
    VotingStationName: "APOSTOLIC JERUSALEM CHURCH",
    Address: "6193 EXTENSION 7 & 8  PHAHAMENG LOCATION  BULTFONTEIN",
    Latitude: "-28.3163",
    Longitude: "26.1481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4964",
    WardLookupId: "939",
    VDNumber: "22650016",
    RegisteredPopulation: "75",
    VotingStationName: "EXELSIOR SCHOOL",
    Address: "00 EXELSIOR FARM  BULTFONTEIN  BULTFONTEIN",
    Latitude: "-28.296",
    Longitude: "25.8864",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4965",
    WardLookupId: "939",
    VDNumber: "22650027",
    RegisteredPopulation: "37",
    VotingStationName: "UITKYK FARM",
    Address: "320 UITKYK FARM  BULTFONTEIN  BULTFONTEIN",
    Latitude: "-28.4012",
    Longitude: "26.0568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4966",
    WardLookupId: "939",
    VDNumber: "22650038",
    RegisteredPopulation: "221",
    VotingStationName: "VARKENSPAN FARM",
    Address: "00 VARKENSPAN FARM  BULTFONTEIN",
    Latitude: "-28.3522",
    Longitude: "26.3186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4967",
    WardLookupId: "939",
    VDNumber: "22650049",
    RegisteredPopulation: "39",
    VotingStationName: "SPESBONA FARM",
    Address: "00 NO STREET NAME AVAILABLE  SPESBONA FARM  BULTFONTEIN",
    Latitude: "-28.419",
    Longitude: "26.202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4968",
    WardLookupId: "939",
    VDNumber: "22650050",
    RegisteredPopulation: "220",
    VotingStationName: "DOORNPAN FARM",
    Address: "00 NO STREET NAME AVAILABLE  DOORNPAN FARM  BULTFONTEIN",
    Latitude: "-28.2297",
    Longitude: "26.0033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4969",
    WardLookupId: "939",
    VDNumber: "22650072",
    RegisteredPopulation: "263",
    VotingStationName: "WILDEHONDEPAN FARM",
    Address: "00 NO STREET NAME AVAILABLE  WILDEHONDEPAN FARM  HOOPSTAD",
    Latitude: "-28.0079",
    Longitude: "25.9574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4970",
    WardLookupId: "939",
    VDNumber: "22650083",
    RegisteredPopulation: "132",
    VotingStationName: "BEYERSFONTEIN FARM",
    Address: "301 HOOPSTAD  BEYERSFONTEIN FARM  HOOPSTAD",
    Latitude: "-28.1151",
    Longitude: "25.819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4971",
    WardLookupId: "939",
    VDNumber: "22650106",
    RegisteredPopulation: "93",
    VotingStationName: "LEEUKRAAL FARM",
    Address: "162 HOOPSTAD  ",
    Latitude: "-27.911",
    Longitude: "25.952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4972",
    WardLookupId: "939",
    VDNumber: "22650128",
    RegisteredPopulation: "126",
    VotingStationName: "PLAAS SPES-BONA",
    Address: ". PLAAS SPES-BONA  HOOPSTAD",
    Latitude: "-28.0033",
    Longitude: "25.6124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4973",
    WardLookupId: "939",
    VDNumber: "22650151",
    RegisteredPopulation: "443",
    VotingStationName: "HANOVER FARM SCHOOL",
    Address: "00 NO STREET NAME AVAILABLE  BULTFONTEIN  BULTFONTEIN",
    Latitude: "-28.0683",
    Longitude: "26.0187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4974",
    WardLookupId: "939",
    VDNumber: "22650173",
    RegisteredPopulation: "318",
    VotingStationName: "HAARTEBEESDRAAI FARM SCHOOL",
    Address: "00 ELEKTI FARM  BULTFONTEIN",
    Latitude: "-28.1514",
    Longitude: "26.2655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4975",
    WardLookupId: "939",
    VDNumber: "22650195",
    RegisteredPopulation: "441",
    VotingStationName: "VETRIVIER INTERMEDIATE FARM SCHOOL",
    Address: "380 VALSCHFONTEIN FARM  BULTFONTEIN",
    Latitude: "-28.1639",
    Longitude: "26.4059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4976",
    WardLookupId: "939",
    VDNumber: "22650229",
    RegisteredPopulation: "88",
    VotingStationName: "GROOTHOEK FARM TENT",
    Address: "221 GROOTHOEK FARM  HOOPSTAD  HOOPSTAD",
    Latitude: "-27.879",
    Longitude: "26.0592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4977",
    WardLookupId: "939",
    VDNumber: "22650465",
    RegisteredPopulation: "139",
    VotingStationName: "LETHOLA PRIMARY FARM SCHOOL",
    Address: "410 KLIPPAN FARM  HOOPSTAD",
    Latitude: "-27.8287",
    Longitude: "26.0377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4978",
    WardLookupId: "940",
    VDNumber: "22530024",
    RegisteredPopulation: "1322",
    VotingStationName: "THORISO PRIMARY SCHOOL",
    Address: "1802 MANDELA DRIVE  TIKWANA LOCATION  HOOPSTAD",
    Latitude: "-27.8417",
    Longitude: "25.9263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4979",
    WardLookupId: "940",
    VDNumber: "22530035",
    RegisteredPopulation: "2093",
    VotingStationName: "RELEKILE SENIOR SECONDARY SCHOOL",
    Address: "2150 TSEKI STREET  TIKWANA LOCATION  HOOPSTAD",
    Latitude: "-27.8428",
    Longitude: "25.9309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4980",
    WardLookupId: "941",
    VDNumber: "22530013",
    RegisteredPopulation: "1411",
    VotingStationName: "SOLOMON MAHLANGU COMMUNITY HALL",
    Address: "65 RALEPEDI STREET  TIKWANA LOCATION  HOOPSATD",
    Latitude: "-27.8379",
    Longitude: "25.9154",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4981",
    WardLookupId: "941",
    VDNumber: "22530057",
    RegisteredPopulation: "1322",
    VotingStationName: "TLAMANANG PRIMARY SCHOOL",
    Address: "175 MOFOKENG STREET  TIKWANA LOCATION  HOOPSTAD",
    Latitude: "-27.8381",
    Longitude: "25.9182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4982",
    WardLookupId: "941",
    VDNumber: "22530068",
    RegisteredPopulation: "279",
    VotingStationName: "TIKWANA COMPREHENSIVE SCHOOL",
    Address: "653 RASELLO STREET  TIKWANA LOCATION  HOOPSTAD",
    Latitude: "-27.8407",
    Longitude: "25.9215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4983",
    WardLookupId: "942",
    VDNumber: "22530046",
    RegisteredPopulation: "839",
    VotingStationName: "BURGER SENTRUM/TOWN HALL",
    Address: "00 H/V SKOOL & BUITEN  HOOPSTAD  HOOPSTAD",
    Latitude: "-27.8356",
    Longitude: "25.9086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4984",
    WardLookupId: "942",
    VDNumber: "22650094",
    RegisteredPopulation: "191",
    VotingStationName: "NIEKERKSKUIL FARM",
    Address: "00 NO STREET NAME AVAILABLE  NIEKERKSKUIL FARM  HOOPSTAD",
    Latitude: "-27.8787",
    Longitude: "25.8066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4985",
    WardLookupId: "942",
    VDNumber: "22650117",
    RegisteredPopulation: "145",
    VotingStationName: "PLAAS KAMEEL",
    Address: "00 NO STREET NAME AVAILABLE  PLAAS KAMEEL  BLOEMHOF",
    Latitude: "-27.9239",
    Longitude: "25.5343",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4986",
    WardLookupId: "942",
    VDNumber: "22650139",
    RegisteredPopulation: "117",
    VotingStationName: "NOOITGEDACHT FARM",
    Address: "00 NO STREET NAME AVAILABLE  NOOITGEDACHT FARM  BLOEMHOF",
    Latitude: "-27.7113",
    Longitude: "25.4742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4987",
    WardLookupId: "942",
    VDNumber: "22650140",
    RegisteredPopulation: "121",
    VotingStationName: "PRINETT FARM",
    Address: "PRINETT FARM  HOOPSTAD  HOOPSTAD",
    Latitude: "-27.8049",
    Longitude: "25.8747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4988",
    WardLookupId: "942",
    VDNumber: "22650162",
    RegisteredPopulation: "2064",
    VotingStationName: "GM POLORI PRIMARY SCHOOL",
    Address: "1187 TSEKI STREET  TIKWANA LOCATION  HOOPSTAD",
    Latitude: "-27.8456",
    Longitude: "25.9416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4989",
    WardLookupId: "3367",
    VDNumber: "22570017",
    RegisteredPopulation: "792",
    VotingStationName: "VENTERSBURG TOWN HALL",
    Address: "VOORTREKKER STREET  VENTERSBURG  VENTERSBURG",
    Latitude: "-28.0856",
    Longitude: "27.1374",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4990",
    WardLookupId: "3367",
    VDNumber: "22570028",
    RegisteredPopulation: "2695",
    VotingStationName: "ECCO PUBLIC SCHOOL",
    Address: "STAND 1318  MMAMAHABANE  VENTERSBURG",
    Latitude: "-28.0896",
    Longitude: "27.1504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4991",
    WardLookupId: "3367",
    VDNumber: "22570039",
    RegisteredPopulation: "1136",
    VotingStationName: "KGAUHELO PRIMARY SCHOOL",
    Address: "49 MMAMAHABANE  VENTERSBURG",
    Latitude: "-28.0913",
    Longitude: "27.1441",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4992",
    WardLookupId: "3367",
    VDNumber: "22570040",
    RegisteredPopulation: "1003",
    VotingStationName: "MATSERIPE SECONDARY SCHOOL",
    Address: "1317 MMAMAHABANE  VENTERSBURG",
    Latitude: "-28.0898",
    Longitude: "27.1479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4993",
    WardLookupId: "3367",
    VDNumber: "22570051",
    RegisteredPopulation: "313",
    VotingStationName: "TENT(NEXT TO GROENPUNT)",
    Address: "GROENPUNT FARM  VENTERSBURG  VENTERSBURG",
    Latitude: "-28.0886",
    Longitude: "27.1621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4994",
    WardLookupId: "3367",
    VDNumber: "22640048",
    RegisteredPopulation: "109",
    VotingStationName: "SCHOONGELEGEN FARM",
    Address: "SENEKAL  SCHOONGELEGEN FARM  SENEKAL",
    Latitude: "-28.5142",
    Longitude: "27.2163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4995",
    WardLookupId: "3367",
    VDNumber: "22640060",
    RegisteredPopulation: "218",
    VotingStationName: "ALDAM RESORT",
    Address: "VENTERSBURG  ALDAM ESTATE  VENTER",
    Latitude: "-28.2873",
    Longitude: "27.1605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4996",
    WardLookupId: "3367",
    VDNumber: "22640150",
    RegisteredPopulation: "99",
    VotingStationName: "ETHNAN FARM",
    Address: "ETHNAN  VENTERSBURG",
    Latitude: "-28.1344",
    Longitude: "27.2775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4997",
    WardLookupId: "3367",
    VDNumber: "22640161",
    RegisteredPopulation: "185",
    VotingStationName: "ROODEKOP FARM",
    Address: "ROODEKOP  VENTERSBURG",
    Latitude: "-28.1885",
    Longitude: "27.1198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4998",
    WardLookupId: "3367",
    VDNumber: "22640352",
    RegisteredPopulation: "18",
    VotingStationName: "ROCKLANDS FARM",
    Address: "ROCKLANDS FARM  VENTERSBURG  VENTERSBURG",
    Latitude: "-28.0779",
    Longitude: "27.2064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "4999",
    WardLookupId: "3368",
    VDNumber: "22510011",
    RegisteredPopulation: "1736",
    VotingStationName: "CHRISTIAN REVIVAL CENTRE",
    Address: "3799 EXTENTION 1 PHOMOLONG  HENNENMAN  HENNENMAN",
    Latitude: "-28.0093",
    Longitude: "27.077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5000",
    WardLookupId: "3368",
    VDNumber: "22510044",
    RegisteredPopulation: "2114",
    VotingStationName: "KHELENG SECONDARY SCHOOL",
    Address: "STAND 11451  PHOMOLONG  HENNENMAN",
    Latitude: "-28.0174",
    Longitude: "27.091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5001",
    WardLookupId: "3368",
    VDNumber: "22510055",
    RegisteredPopulation: "1310",
    VotingStationName: "LERATONG DAY CARE",
    Address: "12013 PHO44  PHOMOLONG  HENNENMAN",
    Latitude: "-28.0198",
    Longitude: "27.0904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5002",
    WardLookupId: "3368",
    VDNumber: "22510077",
    RegisteredPopulation: "1201",
    VotingStationName: "KWEETSA PRIMARY SCHOOL",
    Address: "2963 KWEETSA STREET  PHOMOLONG  HENNENMAN",
    Latitude: "-28.0111",
    Longitude: "27.0854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5003",
    WardLookupId: "3369",
    VDNumber: "22510022",
    RegisteredPopulation: "1606",
    VotingStationName: "PHOMOLONG PRIMARY SCHOOL",
    Address: "2037 PHOMOLONG  HENNENMAN",
    Latitude: "-28.0173",
    Longitude: "27.0778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5004",
    WardLookupId: "3369",
    VDNumber: "22510033",
    RegisteredPopulation: "1671",
    VotingStationName: "HENNENMAN PRIMARY SCHOOL",
    Address: "TOTIUS  HENNENMAN  HENNENMAN",
    Latitude: "-27.9801",
    Longitude: "27.0308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5005",
    WardLookupId: "3369",
    VDNumber: "22510066",
    RegisteredPopulation: "769",
    VotingStationName: "HOÃ‹RSKOOL HENNENMAN",
    Address: "SCHLEBUSCH  HENNENMAN  HENNENMAN",
    Latitude: "-27.9686",
    Longitude: "27.0163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5006",
    WardLookupId: "3369",
    VDNumber: "22510088",
    RegisteredPopulation: "1636",
    VotingStationName: "BAHALE SECONDARY SCHOOL",
    Address: "1131 PHOMOLONG  HENNENMAN",
    Latitude: "-28.0182",
    Longitude: "27.0826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5007",
    WardLookupId: "3369",
    VDNumber: "22510099",
    RegisteredPopulation: "670",
    VotingStationName: "PHOMOLONG COMMUNITY HALL",
    Address: "RATHABE  HENNENMAN  PHOMOLONG",
    Latitude: "-28.0184",
    Longitude: "27.0765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5008",
    WardLookupId: "3369",
    VDNumber: "22640138",
    RegisteredPopulation: "82",
    VotingStationName: "PER LOT FARM",
    Address: "POSBUS 621  PER LOT FARM  HENNENMAN",
    Latitude: "-27.985",
    Longitude: "26.9344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5009",
    WardLookupId: "3369",
    VDNumber: "22640240",
    RegisteredPopulation: "68",
    VotingStationName: "ONVERWACHT MEMORIUM FARM",
    Address: "ONVERWACHT  ONVERWACHT FARM  HENNENMAN",
    Latitude: "-28.0465",
    Longitude: "27.0304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5010",
    WardLookupId: "3369",
    VDNumber: "22640262",
    RegisteredPopulation: "120",
    VotingStationName: "BEGINSEL FARM",
    Address: "BEGINSEL  VENTERSBURG",
    Latitude: "-27.983",
    Longitude: "27.1379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5011",
    WardLookupId: "3369",
    VDNumber: "22640295",
    RegisteredPopulation: "228",
    VotingStationName: "ONS ANKER INTERMEDIATE FARM SCHOOL",
    Address: "FARM: ONS ANKER, HENNENMAN  HENNENMAN DISTRICT  HENNENMAN",
    Latitude: "-27.8744",
    Longitude: "26.9531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5012",
    WardLookupId: "3370",
    VDNumber: "22590154",
    RegisteredPopulation: "1052",
    VotingStationName: "REATLEHILE SECONDARY SCHOOL",
    Address: "749 KGOTSONG STR  WELKOM  VIRGINIA",
    Latitude: "-28.1319",
    Longitude: "26.903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5013",
    WardLookupId: "3370",
    VDNumber: "22590176",
    RegisteredPopulation: "1522",
    VotingStationName: "BOASE PRIMARY SCHOOL",
    Address: "2242 SENYANE WAY EUREKA PARK  MELODING  VIRGINIA",
    Latitude: "-28.1357",
    Longitude: "26.9049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5014",
    WardLookupId: "3370",
    VDNumber: "22590187",
    RegisteredPopulation: "1370",
    VotingStationName: "IKAHENG PRIMARY SCHOOL",
    Address: "75 MAKOKO DRIVE  WELKOM  VIRGINIA",
    Latitude: "-28.1288",
    Longitude: "26.9062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5015",
    WardLookupId: "3370",
    VDNumber: "22590200",
    RegisteredPopulation: "1564",
    VotingStationName: "ST MARTIN DE PORRES CRECHE",
    Address: "13390 MELODING  VIRGINIA",
    Latitude: "-28.1407",
    Longitude: "26.9088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5016",
    WardLookupId: "3371",
    VDNumber: "22590132",
    RegisteredPopulation: "1533",
    VotingStationName: "MAMELLO SECONDARY SCHOOL",
    Address: "1391 MUSA STREET  MELODING  VIRGINIA",
    Latitude: "-28.1349",
    Longitude: "26.896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5017",
    WardLookupId: "3371",
    VDNumber: "22590143",
    RegisteredPopulation: "1259",
    VotingStationName: "MELODING COMMUNITY HALL",
    Address: "1148 MELODING STR  MELODING  VIRGINIA",
    Latitude: "-28.1333",
    Longitude: "26.8966",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5018",
    WardLookupId: "3371",
    VDNumber: "22590165",
    RegisteredPopulation: "1270",
    VotingStationName: "TIKWE PRIMARY SCHOOL",
    Address: "369 KOPANONG STREET  VIRGINIA  VIRGINIA",
    Latitude: "-28.1287",
    Longitude: "26.9026",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5019",
    WardLookupId: "3371",
    VDNumber: "22590255",
    RegisteredPopulation: "265",
    VotingStationName: "MERRIESPRUIT 3 COMMUNITY RESIDENTIAL UNIT",
    Address: "MERRIESPRUIT 3  VIRGINIA  VIRGINIA",
    Latitude: "-28.1271",
    Longitude: "26.8873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5020",
    WardLookupId: "3372",
    VDNumber: "22590075",
    RegisteredPopulation: "991",
    VotingStationName: "MELODING SECONDARY SCHOOL",
    Address: "12198 STILTE PARK  MELODING  VIRGINIA",
    Latitude: "-28.1419",
    Longitude: "26.8972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5021",
    WardLookupId: "3372",
    VDNumber: "22590086",
    RegisteredPopulation: "1901",
    VotingStationName: "DIEKETSENG PRIMARY SCHOOL",
    Address: "3518 STILTE PARK   MELODING   VIRGINIA",
    Latitude: "-28.1457",
    Longitude: "26.8978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5022",
    WardLookupId: "3372",
    VDNumber: "22590109",
    RegisteredPopulation: "1445",
    VotingStationName: "MAREMATLOU SECONDARY SCHOOL",
    Address: "4545 MANDELA PARK   MELODING   VIRGINIA",
    Latitude: "-28.1502",
    Longitude: "26.8934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5023",
    WardLookupId: "3372",
    VDNumber: "22590110",
    RegisteredPopulation: "1306",
    VotingStationName: "OR TAMBO CLINIC",
    Address: "12605 PHAHAMENG  MELODING  VIRGINIA",
    Latitude: "-28.1503",
    Longitude: "26.8866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5024",
    WardLookupId: "3373",
    VDNumber: "22590097",
    RegisteredPopulation: "2502",
    VotingStationName: "UMZAMO CRECHE",
    Address: "2875 MOLAOA STREET   MELODING  VIRGINIA",
    Latitude: "-28.1422",
    Longitude: "26.8911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5025",
    WardLookupId: "3373",
    VDNumber: "22590121",
    RegisteredPopulation: "1521",
    VotingStationName: "PHAHAMISANANG PRIMARY SCHOOL",
    Address: "MELODING  VIRGINIA",
    Latitude: "-28.1448",
    Longitude: "26.8886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5026",
    WardLookupId: "3373",
    VDNumber: "22590233",
    RegisteredPopulation: "1120",
    VotingStationName: "REAHOLA COMMUNITY CRECHE",
    Address: "ALBANIA  MELODING  VIRGINIA",
    Latitude: "-28.1515",
    Longitude: "26.8787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5027",
    WardLookupId: "3373",
    VDNumber: "22590244",
    RegisteredPopulation: "776",
    VotingStationName: "KOPANANG CRECHE",
    Address: "5436 MELODING  VIRGINIA  VIRGINIA",
    Latitude: "-28.1494",
    Longitude: "26.8864",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5028",
    WardLookupId: "3374",
    VDNumber: "22590020",
    RegisteredPopulation: "1814",
    VotingStationName: "SAAIPLAAS PRIMARY SCHOOL",
    Address: "NOBEL STREET  SAAIPLAAS  VIRGINIA",
    Latitude: "-28.0595",
    Longitude: "26.8659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5029",
    WardLookupId: "3374",
    VDNumber: "22590031",
    RegisteredPopulation: "1714",
    VotingStationName: "NG KERK IN AFRIKA SAAIPLAAS",
    Address: "MESSINA ROAD  SAAIPLAAS  VIRGINIA",
    Latitude: "-28.0727",
    Longitude: "26.8633",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5030",
    WardLookupId: "3374",
    VDNumber: "22590042",
    RegisteredPopulation: "1525",
    VotingStationName: "HARMONY HIGH SCHOOL",
    Address: "44 VONDELING RD  HARMONY  VIRGINIA",
    Latitude: "-28.079",
    Longitude: "26.8888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5031",
    WardLookupId: "3375",
    VDNumber: "22590019",
    RegisteredPopulation: "1615",
    VotingStationName: "MERRIESPRUIT PRIMARY SCHOOL",
    Address: "MERRIESPRUIT  VIRGINIA",
    Latitude: "-28.1213",
    Longitude: "26.84",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5032",
    WardLookupId: "3375",
    VDNumber: "22590053",
    RegisteredPopulation: "1738",
    VotingStationName: "HARVINIA CLUB HALL",
    Address: "112 HIGHLANDS AVE  CENTRAL  VIRGINIA",
    Latitude: "-28.1004",
    Longitude: "26.8602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5033",
    WardLookupId: "3375",
    VDNumber: "22590064",
    RegisteredPopulation: "1773",
    VotingStationName: "VIRGINIA HIGH SCHOOL",
    Address: "BOOM ROAD  CENTRAL  VIRGINIA",
    Latitude: "-28.1056",
    Longitude: "26.8772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5034",
    WardLookupId: "3375",
    VDNumber: "22590198",
    RegisteredPopulation: "440",
    VotingStationName: "KITTY CLUB",
    Address: "2 ISABEL CRESSANT  KITTY  VIRGINIA",
    Latitude: "-28.1287",
    Longitude: "26.8316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5035",
    WardLookupId: "3375",
    VDNumber: "22590211",
    RegisteredPopulation: "593",
    VotingStationName: "ORYX MYN-SIBANYE GOLD",
    Address: "PALMIETKUIL DISTRICT  THEUNISSEN  THEUNISSEN",
    Latitude: "-28.1796",
    Longitude: "26.7226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5036",
    WardLookupId: "3375",
    VDNumber: "22640194",
    RegisteredPopulation: "145",
    VotingStationName: "HAKKIES FARM",
    Address: "KITTY ROAD  HAKKIES  VIRGINIA",
    Latitude: "-28.121",
    Longitude: "26.8019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5037",
    WardLookupId: "3376",
    VDNumber: "22540171",
    RegisteredPopulation: "2260",
    VotingStationName: "JC MOTUMI SECONDARY SCHOOL",
    Address: "8444 K9 KUTLWANONG   KUTLWANONG   ODENDAALSRUS",
    Latitude: "-27.8462",
    Longitude: "26.7509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5038",
    WardLookupId: "3376",
    VDNumber: "22600314",
    RegisteredPopulation: "1622",
    VotingStationName: "RIEBEECKSTAD SWIMMING POOL HALL",
    Address: "2 CIRCLE ST  RIEBEECKSTAD  WELKOM",
    Latitude: "-27.9241",
    Longitude: "26.8243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5039",
    WardLookupId: "3376",
    VDNumber: "22640273",
    RegisteredPopulation: "156",
    VotingStationName: "PETRA FARM",
    Address: "PETRA FARM  ODENDAALSRUS  ODENDAALSRUS",
    Latitude: "-27.755",
    Longitude: "26.8626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5040",
    WardLookupId: "3376",
    VDNumber: "22640341",
    RegisteredPopulation: "1379",
    VotingStationName: "GENEVA K9 CLINIC",
    Address: "(FS184 - MATJHABENG [WELKOM])   ",
    Latitude: "-27.8508",
    Longitude: "26.748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5041",
    WardLookupId: "3376",
    VDNumber: "22810014",
    RegisteredPopulation: "202",
    VotingStationName: "LANDSKROON FARM",
    Address: "LANDSKROON  LANDSKROON FARM  WELKOM",
    Latitude: "-27.6689",
    Longitude: "26.8642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5042",
    WardLookupId: "3377",
    VDNumber: "22600268",
    RegisteredPopulation: "2145",
    VotingStationName: "BRONVILLE PRIMARY SCHOOL",
    Address: "WATERBOER  BRONVILLE  WELKOM",
    Latitude: "-27.998",
    Longitude: "26.8106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5043",
    WardLookupId: "3377",
    VDNumber: "22600279",
    RegisteredPopulation: "1620",
    VotingStationName: "PEOPLE`S CHOICE DAY CARE",
    Address: "2672 EXTENTION 9  BRONVILLE  WELKOM",
    Latitude: "-27.9932",
    Longitude: "26.8196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5044",
    WardLookupId: "3377",
    VDNumber: "22600684",
    RegisteredPopulation: "1282",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "1241 ELITE AVENUE  BRONVILLE  WELKOM",
    Latitude: "-28.0007",
    Longitude: "26.82",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5045",
    WardLookupId: "3377",
    VDNumber: "22600785",
    RegisteredPopulation: "1533",
    VotingStationName: "LESEDI LA KGANYA DAY CARE",
    Address: "MATJHABENG  ",
    Latitude: "-27.9906",
    Longitude: "26.8248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5046",
    WardLookupId: "3378",
    VDNumber: "22600325",
    RegisteredPopulation: "2440",
    VotingStationName: "MMANTSHEBO PRIMARY SCHOOL",
    Address: "13502 TSIU MATSEPE STREET  THABONG  WELKOM",
    Latitude: "-27.9584",
    Longitude: "26.808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5047",
    WardLookupId: "3378",
    VDNumber: "22600336",
    RegisteredPopulation: "1825",
    VotingStationName: "TSAKANI PRIMARY SCHOOL",
    Address: "11958 MATIMA ROAD  THABONG  WELKOM",
    Latitude: "-27.9679",
    Longitude: "26.8051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5048",
    WardLookupId: "3378",
    VDNumber: "22600741",
    RegisteredPopulation: "529",
    VotingStationName: "SENKHOANE COMMUNITY LEARNING CENTRE",
    Address: "121 OPPENHEIMER PARK  MOTSETHABONG  WELKOM",
    Latitude: "-27.9676",
    Longitude: "26.8071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5049",
    WardLookupId: "3379",
    VDNumber: "22600358",
    RegisteredPopulation: "1241",
    VotingStationName: "RATANANG CRECHE",
    Address: "25239 THABONG  WELKOM",
    Latitude: "-27.9751",
    Longitude: "26.8402",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5050",
    WardLookupId: "3379",
    VDNumber: "22600369",
    RegisteredPopulation: "1021",
    VotingStationName: "TENT(NEXT TO BAHLAPING HIGH MASS LIGHT)",
    Address: "CONSTANTIA ROAD   THABONG 9  WELKOM",
    Latitude: "-27.9739",
    Longitude: "26.8316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5051",
    WardLookupId: "3379",
    VDNumber: "22600370",
    RegisteredPopulation: "1426",
    VotingStationName: "HECTOR PETERSON MUNICIPAL OFFICE",
    Address: "24854 CONSTANTIA ROAD  THABONG   WELKOM",
    Latitude: "-27.9726",
    Longitude: "26.8392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5052",
    WardLookupId: "3379",
    VDNumber: "22600527",
    RegisteredPopulation: "529",
    VotingStationName: "MASIMONG MINE HOSTEL",
    Address: "MASIMOMG MINE  MASIMONG MINE  WELKOM",
    Latitude: "-27.9742",
    Longitude: "26.8716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5053",
    WardLookupId: "3379",
    VDNumber: "22600819",
    RegisteredPopulation: "281",
    VotingStationName: "MASIMONG 4 ESTATE",
    Address: "MASIMONG 4  BRONVILLE  WELKOM",
    Latitude: "-27.9924",
    Longitude: "26.8627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5054",
    WardLookupId: "3380",
    VDNumber: "22600347",
    RegisteredPopulation: "1876",
    VotingStationName: "LEHAKWE PRIMARY SCHOOL",
    Address: "14112 CORNER DULI NTAMBO AND DR JAMES MOROKA  THABONG  WELKOM",
    Latitude: "-27.9734",
    Longitude: "26.8163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5055",
    WardLookupId: "3380",
    VDNumber: "22600381",
    RegisteredPopulation: "2017",
    VotingStationName: "THUSANANG CRECHE",
    Address: "16361 MOSHOESHOE STR  THABONG  WELKOM",
    Latitude: "-27.9704",
    Longitude: "26.8277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5056",
    WardLookupId: "3380",
    VDNumber: "22600651",
    RegisteredPopulation: "1324",
    VotingStationName: "MOKGWABONG PRIMARY SCHOOL",
    Address: "14114 JAMES MOROKA STREET   THABONG   WELKOM",
    Latitude: "-27.9732",
    Longitude: "26.8222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5057",
    WardLookupId: "3380",
    VDNumber: "22600820",
    RegisteredPopulation: "895",
    VotingStationName: "AFRICAN NATIONAL CHURCH",
    Address: "16370 MIKE SELOANE  SUNRISE VIEW  THABONG",
    Latitude: "-27.9698",
    Longitude: "26.8243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5058",
    WardLookupId: "3381",
    VDNumber: "22600392",
    RegisteredPopulation: "2818",
    VotingStationName: "NANABOLELA SECONDARY SCHOOL",
    Address: "18122 KOTELO STREET  THABONG  WELKOM",
    Latitude: "-27.9693",
    Longitude: "26.8345",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5059",
    WardLookupId: "3381",
    VDNumber: "22600404",
    RegisteredPopulation: "1982",
    VotingStationName: "LETSETE SECONDARY SCHOOL",
    Address: "15573 DOLS BRAITZ  THABONG  WELKOM",
    Latitude: "-27.966",
    Longitude: "26.8181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5060",
    WardLookupId: "3382",
    VDNumber: "22600426",
    RegisteredPopulation: "2314",
    VotingStationName: "RCC CHURCH",
    Address: "DAN KHOABONE  THABONG  WELKOM",
    Latitude: "-27.9587",
    Longitude: "26.8258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5061",
    WardLookupId: "3382",
    VDNumber: "22600437",
    RegisteredPopulation: "2649",
    VotingStationName: "SETSHABELO PRIMARY SCHOOL",
    Address: "28588 DR NDAKI ROAD   THABONG  WELKOM",
    Latitude: "-27.9561",
    Longitude: "26.8269",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5062",
    WardLookupId: "3382",
    VDNumber: "22600763",
    RegisteredPopulation: "835",
    VotingStationName: "THABONG SPORTS CENTRE",
    Address: "2 MOTSETHABONG  ",
    Latitude: "-27.9608",
    Longitude: "26.8183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5063",
    WardLookupId: "3383",
    VDNumber: "22600099",
    RegisteredPopulation: "1462",
    VotingStationName: "ADELAIDE TAMBO TECHNICAL SCHOOL",
    Address: "6637 THB 300  WELKOM  THABONG",
    Latitude: "-27.954",
    Longitude: "26.8403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5064",
    WardLookupId: "3383",
    VDNumber: "22600415",
    RegisteredPopulation: "1305",
    VotingStationName: "LEMOTSO PRIMARY SCHOOL",
    Address: "25991 JOE SLOVO STREET  THABONG  WELKOM",
    Latitude: "-27.9613",
    Longitude: "26.8354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5065",
    WardLookupId: "3383",
    VDNumber: "22600673",
    RegisteredPopulation: "1477",
    VotingStationName: "END OF AGE CHURCH",
    Address: "26145 DR NDAKI ROAD  THABONG  WELKOM",
    Latitude: "-27.9584",
    Longitude: "26.8385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5066",
    WardLookupId: "3383",
    VDNumber: "22600718",
    RegisteredPopulation: "1619",
    VotingStationName: "DR. M.G. MNGOMA PRIMARY SCHOOL",
    Address: "27140 NEW STANDS   THABONG   WELKOM",
    Latitude: "-27.9551",
    Longitude: "26.8365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5067",
    WardLookupId: "3383",
    VDNumber: "22600729",
    RegisteredPopulation: "1449",
    VotingStationName: "LIVING HOPE CHURCH",
    Address: "9669 THB 639  WELKOM  THABONG",
    Latitude: "-27.9622",
    Longitude: "26.8438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5068",
    WardLookupId: "3384",
    VDNumber: "22540047",
    RegisteredPopulation: "847",
    VotingStationName: "REARABETSWE SECONDARY SCHOOL",
    Address: "348 BLOCK 2 KUTLWANONG  KUTLWANONG  ODENDAALSRUS",
    Latitude: "-27.8485",
    Longitude: "26.7618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5069",
    WardLookupId: "3384",
    VDNumber: "22540058",
    RegisteredPopulation: "1525",
    VotingStationName: "MOHOBO PRIMARY SCHOOL",
    Address: "2597 K5 KUTLWANONG  ODENDAALSRUS",
    Latitude: "-27.8522",
    Longitude: "26.7755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5070",
    WardLookupId: "3384",
    VDNumber: "22540092",
    RegisteredPopulation: "612",
    VotingStationName: "IMPUCUKO PRIMARY SCHOOL",
    Address: "208 NDIMANDE STR  BLOCK 1 KUTLWANONG  ODENDAALSRUS",
    Latitude: "-27.8487",
    Longitude: "26.757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5071",
    WardLookupId: "3384",
    VDNumber: "22540148",
    RegisteredPopulation: "1801",
    VotingStationName: "MAROBE PRIMARY SCHOOL",
    Address: "433 MENONG STREET  KUTLWANONG  ODENDAALSRUS",
    Latitude: "-27.8551",
    Longitude: "26.7667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5072",
    WardLookupId: "3385",
    VDNumber: "22450014",
    RegisteredPopulation: "1403",
    VotingStationName: "DIHWAI  PRIMARY SCHOOL",
    Address: "2683 NYAKALLONG  ALLANRIDGE",
    Latitude: "-27.7806",
    Longitude: "26.658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5073",
    WardLookupId: "3385",
    VDNumber: "22450036",
    RegisteredPopulation: "1571",
    VotingStationName: "AME CHURCH",
    Address: "530 NYAKALLONG  ALLANRIDGE",
    Latitude: "-27.7718",
    Longitude: "26.6603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5074",
    WardLookupId: "3385",
    VDNumber: "22450058",
    RegisteredPopulation: "1160",
    VotingStationName: "NYAKALLONG COMMUNITY HALL",
    Address: "389 NYAKALLONG  ALLANRIDGE",
    Latitude: "-27.7773",
    Longitude: "26.6583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5075",
    WardLookupId: "3385",
    VDNumber: "22450069",
    RegisteredPopulation: "540",
    VotingStationName: "METHODIST CHURCH",
    Address: "525 NYAKALLONG  ALLANRIDGE",
    Latitude: "-27.7743",
    Longitude: "26.6573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5076",
    WardLookupId: "3385",
    VDNumber: "22450081",
    RegisteredPopulation: "899",
    VotingStationName: "L.A WESI SECONDARY SCHOOL",
    Address: "2564 MOSHANE STREET  NYAKALLONG  ALLANRIDGE",
    Latitude: "-27.7825",
    Longitude: "26.6548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5077",
    WardLookupId: "3385",
    VDNumber: "22450092",
    RegisteredPopulation: "786",
    VotingStationName: "S.A. MOKHOTHU PRIMARY SCHOOL",
    Address: "3073 NYAKALLONG  ALLANRIDGE  NYAKALLONG",
    Latitude: "-27.7804",
    Longitude: "26.6634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5078",
    WardLookupId: "3386",
    VDNumber: "22540070",
    RegisteredPopulation: "990",
    VotingStationName: "FORA-LESOTHO (GEREF KERK)",
    Address: "12 MAROTHODI STREET  KUTLOANONG  ODENDAALSRUS",
    Latitude: "-27.86",
    Longitude: "26.7624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5079",
    WardLookupId: "3386",
    VDNumber: "22540104",
    RegisteredPopulation: "1496",
    VotingStationName: "ICOSENG PRIMARY SCHOOL",
    Address: "405 MORAKE STREET   KUTLWANONG  ODENDAALSRUS",
    Latitude: "-27.8587",
    Longitude: "26.7567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5080",
    WardLookupId: "3386",
    VDNumber: "22540126",
    RegisteredPopulation: "1276",
    VotingStationName: "THUSANONG PRIMARY SCHOOL",
    Address: "405 MAREMA STR  KUTLWANONG  ODENDAALSRUS",
    Latitude: "-27.8591",
    Longitude: "26.759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5081",
    WardLookupId: "3386",
    VDNumber: "22540137",
    RegisteredPopulation: "1240",
    VotingStationName: "KUTLWANONG PUBLIC LIBRARY",
    Address: "91314 MASITSA STREET  KUTLWANONG  ODENDAALSRUS",
    Latitude: "-27.8556",
    Longitude: "26.759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5082",
    WardLookupId: "3387",
    VDNumber: "22540081",
    RegisteredPopulation: "1802",
    VotingStationName: "MALEBALEBA PRIMARY SCHOOL",
    Address: "4989 K6 KUTLWANONG   KUTLWANONG  ODENDAALSRUS",
    Latitude: "-27.8611",
    Longitude: "26.7809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5083",
    WardLookupId: "3387",
    VDNumber: "22540159",
    RegisteredPopulation: "2012",
    VotingStationName: "SEQHOBONG SECONDARY SCHOOL",
    Address: "6273 K6 KUTLWANONG  KUTLWANONG  ODENDAALSRUS",
    Latitude: "-27.868",
    Longitude: "26.7802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5084",
    WardLookupId: "3387",
    VDNumber: "22540193",
    RegisteredPopulation: "971",
    VotingStationName: "PRESBYTERIAN CHURCH",
    Address: "4389 K6  KUTLWANONG  MATJHABENG [WELKOM]",
    Latitude: "-27.8657",
    Longitude: "26.778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5085",
    WardLookupId: "3387",
    VDNumber: "22540238",
    RegisteredPopulation: "1085",
    VotingStationName: "TENT(NEXT TO SEVENTH DAYS ADVENTIST CHURCH)",
    Address: "00 DU PLESSIS ROAD  ODENDAALSRUS  KUTLOANONG",
    Latitude: "-27.8614",
    Longitude: "26.7752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5086",
    WardLookupId: "3388",
    VDNumber: "22540069",
    RegisteredPopulation: "1669",
    VotingStationName: "KUTLWANONG HIGH SCHOOL",
    Address: "2322 K4  KUTLWANONG  ODENDAALSRUS",
    Latitude: "-27.8666",
    Longitude: "26.7599",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5087",
    WardLookupId: "3388",
    VDNumber: "22540160",
    RegisteredPopulation: "1218",
    VotingStationName: "KGOTSONG PRIMARY SCHOOL",
    Address: "111 KUTLOANONG  ODENDAALSRUS",
    Latitude: "-27.8599",
    Longitude: "26.7688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5088",
    WardLookupId: "3388",
    VDNumber: "22540205",
    RegisteredPopulation: "1343",
    VotingStationName: "AFRICAN NATIONAL CHURCH K7",
    Address: "4093 K7  KUTLOANONG  ODENDAALSRUS",
    Latitude: "-27.8674",
    Longitude: "26.7664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5089",
    WardLookupId: "3388",
    VDNumber: "22640330",
    RegisteredPopulation: "2167",
    VotingStationName: "LOVELIFE CENTRE K9",
    Address: "9746 K9 KUTLWANONG  KUTLWANONG   ODENDAALSRUS",
    Latitude: "-27.8568",
    Longitude: "26.7503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5090",
    WardLookupId: "3389",
    VDNumber: "22600549",
    RegisteredPopulation: "1628",
    VotingStationName: "ADULLAME CHURCH OF CHRIST",
    Address: "29772 EXT 18 HANI PARK  THABONG  THABONG",
    Latitude: "-27.9817",
    Longitude: "26.8131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5091",
    WardLookupId: "3389",
    VDNumber: "22600730",
    RegisteredPopulation: "1024",
    VotingStationName: "LIVING IN WORD CHURCH",
    Address: "HANIPARK  BRONVILLE  WELKOM",
    Latitude: "-27.9868",
    Longitude: "26.8157",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5092",
    WardLookupId: "3389",
    VDNumber: "22600796",
    RegisteredPopulation: "2338",
    VotingStationName: "HANIPARK PRIMARY SCHOOL",
    Address: "33235 HANI PARK  BRONVILLE  WELKOM",
    Latitude: "-27.9848",
    Longitude: "26.8242",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5093",
    WardLookupId: "3389",
    VDNumber: "22600808",
    RegisteredPopulation: "1308",
    VotingStationName: "KARABO DAY CARE CENTER",
    Address: "HANI PARK  BRONVILLE  WELKOM",
    Latitude: "-27.982",
    Longitude: "26.8352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5094",
    WardLookupId: "3390",
    VDNumber: "22600280",
    RegisteredPopulation: "1571",
    VotingStationName: "PHYSICALIST MOTHER CHURCH OF CHRIST MISSION",
    Address: "HANIPARK  BRONVILLE  WELKOM",
    Latitude: "-27.9855",
    Longitude: "26.8123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5095",
    WardLookupId: "3390",
    VDNumber: "22600471",
    RegisteredPopulation: "376",
    VotingStationName: "IKEMISETSENG INTERMEDIATE SCHOOL",
    Address: "ST HELENA  WELKOM",
    Latitude: "-28.0144",
    Longitude: "26.7093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5096",
    WardLookupId: "3390",
    VDNumber: "22600482",
    RegisteredPopulation: "309",
    VotingStationName: "PRESIDENT BRAND MINE 2 SCHOOL",
    Address: "GLEN HARMONY  HARMONY PRESIDENT BRAND MINE  WELKOM",
    Latitude: "-28.0263",
    Longitude: "26.7626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5097",
    WardLookupId: "3390",
    VDNumber: "22600493",
    RegisteredPopulation: "589",
    VotingStationName: "BAMBANANI EAST MINE HOSTEL",
    Address: "BAMBANANI EAST MINE  WELKOM",
    Latitude: "-28.0317",
    Longitude: "26.7857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5098",
    WardLookupId: "3390",
    VDNumber: "22600516",
    RegisteredPopulation: "343",
    VotingStationName: "HARMONY PRESIDENT BRAND MINE 5 HOSTEL",
    Address: "GLEN HARMONY  HARMONY PRESIDENT BRAND MINE  VIRGINIA",
    Latitude: "-28.0585",
    Longitude: "26.7676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5099",
    WardLookupId: "3390",
    VDNumber: "22600695",
    RegisteredPopulation: "644",
    VotingStationName: "PRES STEYN-1 HOSTEL",
    Address: "JAN HOFMEYR ROAD  WELKOM INDUSTRIA  PRES STEYN MINE",
    Latitude: "-27.9851",
    Longitude: "26.7873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5100",
    WardLookupId: "3390",
    VDNumber: "22640105",
    RegisteredPopulation: "213",
    VotingStationName: "HOU- AAN FARM",
    Address: "P O  BOX  241  HOU AAN  THEUNISSEN",
    Latitude: "-28.1984",
    Longitude: "26.5266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5101",
    WardLookupId: "3390",
    VDNumber: "22640149",
    RegisteredPopulation: "107",
    VotingStationName: "SANDVET COMMUNITY CHURCH HALL",
    Address: "00 SANDVET WATER USERS  WELKOM  SANDVET WATER USERS",
    Latitude: "-28.1021",
    Longitude: "26.4566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5102",
    WardLookupId: "3390",
    VDNumber: "22640172",
    RegisteredPopulation: "55",
    VotingStationName: "ADAMSONVLEI FARM SCHOOL",
    Address: "ADAMSONVLEI  ADAMSONVLEI FARM  WELKOM",
    Latitude: "-28.0942",
    Longitude: "26.7318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5103",
    WardLookupId: "3390",
    VDNumber: "22640217",
    RegisteredPopulation: "122",
    VotingStationName: "STILTE FARM",
    Address: "STILTE  WELKOM DISTRICT  WELKOM",
    Latitude: "-28.1531",
    Longitude: "26.7129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5104",
    WardLookupId: "3390",
    VDNumber: "22640228",
    RegisteredPopulation: "122",
    VotingStationName: "KAALPAN FARM",
    Address: "65 KAALPAN  KAALPAN SCHOOL  WELKOM",
    Latitude: "-28.0758",
    Longitude: "26.7092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5105",
    WardLookupId: "3390",
    VDNumber: "22640239",
    RegisteredPopulation: "384",
    VotingStationName: "VIERHOEK INTERMEDIATE FARM SCHOOL",
    Address: "P O BOX 1100  VIERHOEK  WELKOM",
    Latitude: "-28.1127",
    Longitude: "26.639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5106",
    WardLookupId: "3391",
    VDNumber: "22600257",
    RegisteredPopulation: "983",
    VotingStationName: "LOVEBLISS CENTRE",
    Address: "NEW STAND  MOTSE-THABONG  WELKOM",
    Latitude: "-27.9486",
    Longitude: "26.8326",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5107",
    WardLookupId: "3391",
    VDNumber: "22600291",
    RegisteredPopulation: "2914",
    VotingStationName: "KOPPIE ALLEEN PRIMARY SCHOOL",
    Address: "10 DEREK STRAAT  RIEBEECKSTAD  WELKOM",
    Latitude: "-27.9368",
    Longitude: "26.8024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5108",
    WardLookupId: "3391",
    VDNumber: "22600303",
    RegisteredPopulation: "1645",
    VotingStationName: "RIEBEECKSTAD PRIMARY SCHOOL",
    Address: "2 ERNEST ST  RIEBEECKSTAD  WELKOM",
    Latitude: "-27.9216",
    Longitude: "26.8108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5109",
    WardLookupId: "3391",
    VDNumber: "22600831",
    RegisteredPopulation: "1766",
    VotingStationName: "GOLDFIELDS TVET COLLEGE - TOSA CAMPUS",
    Address: "NDAKI  WELKOM  WELKOM",
    Latitude: "-27.9523",
    Longitude: "26.8021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5110",
    WardLookupId: "3391",
    VDNumber: "22600875",
    RegisteredPopulation: "960",
    VotingStationName: "VICTORY CHURCH",
    Address: "PHOKENG  THABONG  WELKOM",
    Latitude: "-27.9493",
    Longitude: "26.813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5111",
    WardLookupId: "3392",
    VDNumber: "22600213",
    RegisteredPopulation: "1215",
    VotingStationName: "THOTAGAUTA HIGH SCHOOL",
    Address: "THUHLWANE  THABONG  WELKOM",
    Latitude: "-27.9709",
    Longitude: "26.7965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5112",
    WardLookupId: "3392",
    VDNumber: "22600224",
    RegisteredPopulation: "1343",
    VotingStationName: "CHRISTIAN APOSTOLIC CHURCH",
    Address: "PESA  THABONG  WELKOM",
    Latitude: "-27.954",
    Longitude: "26.7977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5113",
    WardLookupId: "3392",
    VDNumber: "22600235",
    RegisteredPopulation: "1665",
    VotingStationName: "LEPHOLA COMPREHENSIVE SCHOOL",
    Address: "NKOANE RD  THABONG  WELKOM",
    Latitude: "-27.9643",
    Longitude: "26.7985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5114",
    WardLookupId: "3392",
    VDNumber: "22600662",
    RegisteredPopulation: "764",
    VotingStationName: "APOSTOLIC FAITH CHURCH",
    Address: "TSEUTE  WELKOM  THABONG",
    Latitude: "-27.9575",
    Longitude: "26.7944",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5115",
    WardLookupId: "3393",
    VDNumber: "22600077",
    RegisteredPopulation: "1349",
    VotingStationName: "WELKOM VOLKSKOOL",
    Address: "40 TANA STREET  DOORN  WELKOM",
    Latitude: "-27.9824",
    Longitude: "26.7527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5116",
    WardLookupId: "3393",
    VDNumber: "22600088",
    RegisteredPopulation: "2262",
    VotingStationName: "DAGBREEK PRIMARY SCHOOL",
    Address: "62 KING STREET  DAGBREEK  WELKOM",
    Latitude: "-27.9622",
    Longitude: "26.7421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5117",
    WardLookupId: "3393",
    VDNumber: "22600572",
    RegisteredPopulation: "683",
    VotingStationName: "ARM VILLAGE PHAKISA RESIDENCE",
    Address: "PHAKISA RESIDENCE  WELKOM  WELKOM",
    Latitude: "-27.9367",
    Longitude: "26.7595",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5118",
    WardLookupId: "3393",
    VDNumber: "22600752",
    RegisteredPopulation: "699",
    VotingStationName: "DAGBREEK NG KERK WELKOM NOORD",
    Address: "111 AGULHAS  DAGREEK  WELKOM",
    Latitude: "-27.9546",
    Longitude: "26.7463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5119",
    WardLookupId: "3393",
    VDNumber: "22600842",
    RegisteredPopulation: "477",
    VotingStationName: "AURORA PRIMARY SCHOOL",
    Address: "ARRARAT  DAGBREEK  WELKOM",
    Latitude: "-27.9663",
    Longitude: "26.7464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5120",
    WardLookupId: "3393",
    VDNumber: "22600886",
    RegisteredPopulation: "262",
    VotingStationName: "PABALLONG HALL",
    Address: "1 SCHOOL STREET  WELKOM  WELKOM",
    Latitude: "-27.9477",
    Longitude: "26.7619",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5121",
    WardLookupId: "3394",
    VDNumber: "22600112",
    RegisteredPopulation: "1777",
    VotingStationName: "BANTU CHURCH OF CHRIST",
    Address: "CW13 NKASAI STREET  THABONG  WELKOM",
    Latitude: "-27.9571",
    Longitude: "26.784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5122",
    WardLookupId: "3394",
    VDNumber: "22600123",
    RegisteredPopulation: "2440",
    VotingStationName: "THEMBEKILE PRIMARY SCHOOL",
    Address: "8059 DR MNGOMA  THABONG  WELKOM",
    Latitude: "-27.9628",
    Longitude: "26.7825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5123",
    WardLookupId: "3395",
    VDNumber: "22600189",
    RegisteredPopulation: "1088",
    VotingStationName: "FULL GOSPEL CHURCH",
    Address: "8149 MMATSA STR  THABONG  WELKOM",
    Latitude: "-27.9679",
    Longitude: "26.7909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5124",
    WardLookupId: "3395",
    VDNumber: "22600190",
    RegisteredPopulation: "1205",
    VotingStationName: "LEBOGANG SECONDARY SCHOOL",
    Address: "DR MNGOMA  THABONG  WELKOM",
    Latitude: "-27.9631",
    Longitude: "26.7901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5125",
    WardLookupId: "3395",
    VDNumber: "22600202",
    RegisteredPopulation: "1251",
    VotingStationName: "MOREMAPHOFU PRIMARY SCHOOL",
    Address: "8087 PHEPHENGWENI STR  THABONG  WELKOM",
    Latitude: "-27.9561",
    Longitude: "26.791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5126",
    WardLookupId: "3395",
    VDNumber: "22600639",
    RegisteredPopulation: "713",
    VotingStationName: "THE UNITING PRESBYTERIAN CHURCH IN SA",
    Address: "MMATSA  THABONG  WELKOM",
    Latitude: "-27.9666",
    Longitude: "26.7893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5127",
    WardLookupId: "3395",
    VDNumber: "22600774",
    RegisteredPopulation: "655",
    VotingStationName: "THE UNITED METHODIST CHURCH",
    Address: "8099 MPONENG  THABONG  WELKOM",
    Latitude: "-27.9612",
    Longitude: "26.786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5128",
    WardLookupId: "3396",
    VDNumber: "22600134",
    RegisteredPopulation: "1167",
    VotingStationName: "THABONG PRIMARY SCHOOL",
    Address: "8023 MODIKENG RD  THABONG  WELKOM",
    Latitude: "-27.9681",
    Longitude: "26.7828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5129",
    WardLookupId: "3396",
    VDNumber: "22600145",
    RegisteredPopulation: "1287",
    VotingStationName: "SIYAVUYA 2010 COMMUNITY CENTRE",
    Address: "08016 JAMES NGAKE  THABONG  WELKOM",
    Latitude: "-27.9699",
    Longitude: "26.7826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5130",
    WardLookupId: "3396",
    VDNumber: "22600628",
    RegisteredPopulation: "2080",
    VotingStationName: "THABONG CRECHE",
    Address: "8006 THABONG WAY   THABONG  WELKOM",
    Latitude: "-27.9742",
    Longitude: "26.7803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5131",
    WardLookupId: "3397",
    VDNumber: "22600167",
    RegisteredPopulation: "1353",
    VotingStationName: "THABONG COMMUNITY CENTRE",
    Address: "CONSTANTIA RD  THABONG  WELKOM",
    Latitude: "-27.9742",
    Longitude: "26.7905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5132",
    WardLookupId: "3397",
    VDNumber: "22600178",
    RegisteredPopulation: "1279",
    VotingStationName: "HLOLOHELO PRIMARY SCHOOL",
    Address: "8153 MMATSA STREET  THABONG  WELKOM",
    Latitude: "-27.9691",
    Longitude: "26.7908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5133",
    WardLookupId: "3397",
    VDNumber: "22600246",
    RegisteredPopulation: "1667",
    VotingStationName: "TETO HIGH SCHOOL",
    Address: "CONSTANTIA RD  THABONG  WELKOM",
    Latitude: "-27.9744",
    Longitude: "26.8017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5134",
    WardLookupId: "3397",
    VDNumber: "22600617",
    RegisteredPopulation: "611",
    VotingStationName: "DIRISANANG PRIMARY SCHOOL",
    Address: "8155 MMATSA STREET  THABONG  WELKOM",
    Latitude: "-27.9687",
    Longitude: "26.7882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5135",
    WardLookupId: "3398",
    VDNumber: "22600033",
    RegisteredPopulation: "1838",
    VotingStationName: "TORONTO COMMUNITY CENTRE",
    Address: "45 LANGENHOVEN STREET  JAN CELLIERS PARK  WELKOM",
    Latitude: "-27.9923",
    Longitude: "26.7327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5136",
    WardLookupId: "3398",
    VDNumber: "22600055",
    RegisteredPopulation: "1776",
    VotingStationName: "ORION SPECIAL SCHOOL",
    Address: "46 ORIBI STREET DOORN  DOORN  WELKOM",
    Latitude: "-27.9893",
    Longitude: "26.7511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5137",
    WardLookupId: "3398",
    VDNumber: "22600101",
    RegisteredPopulation: "1947",
    VotingStationName: "REITZPARK PRIMARY SCHOOL",
    Address: "33 GELDENHUYS STREET  REITZPARK  WELKOM",
    Latitude: "-27.9716",
    Longitude: "26.7713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5138",
    WardLookupId: "3398",
    VDNumber: "22600853",
    RegisteredPopulation: "822",
    VotingStationName: "GOUDVELD SECONDARY SCHOOL",
    Address: "8245 LONG ROAD  DOORN  WELKOM",
    Latitude: "-27.988",
    Longitude: "26.7397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5139",
    WardLookupId: "3399",
    VDNumber: "22600011",
    RegisteredPopulation: "2732",
    VotingStationName: "BEDELIA PRIMARY SCHOOL",
    Address: "36 OBERON  BEDELIA  WELKOM",
    Latitude: "-27.9785",
    Longitude: "26.7108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5140",
    WardLookupId: "3399",
    VDNumber: "22600022",
    RegisteredPopulation: "2408",
    VotingStationName: "ST HELENA PRIMARY SCHOOL",
    Address: "14 UNICORN ROAD  ST HELENA  WELKOM",
    Latitude: "-27.9902",
    Longitude: "26.7175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5141",
    WardLookupId: "3399",
    VDNumber: "22600864",
    RegisteredPopulation: "796",
    VotingStationName: "NAUDEVILLE PRIMARY SCHOOL",
    Address: "EBELEEN  NAUDEVILLE  WELKKM",
    Latitude: "-27.9953",
    Longitude: "26.7283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5142",
    WardLookupId: "3400",
    VDNumber: "22600044",
    RegisteredPopulation: "1582",
    VotingStationName: "WELKOM HIGH SCHOOL",
    Address: "FALSTAFF STR 62  BEDELIA  WELKOM",
    Latitude: "-27.9799",
    Longitude: "26.7268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5143",
    WardLookupId: "3400",
    VDNumber: "22600066",
    RegisteredPopulation: "1890",
    VotingStationName: "WELKOM - GIMNASIUM SCHOOL",
    Address: "1 GIMNASIUM STREET  JIM FOUCHE PARK  WELKOM",
    Latitude: "-27.9727",
    Longitude: "26.7286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5144",
    WardLookupId: "3400",
    VDNumber: "22600448",
    RegisteredPopulation: "1783",
    VotingStationName: "FLAMINGO PARK COMMUNITY CENTRE",
    Address: "882 BUREN STREET  FLAMINGO PARK  WELKOM",
    Latitude: "-27.9537",
    Longitude: "26.7265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5145",
    WardLookupId: "3400",
    VDNumber: "22600583",
    RegisteredPopulation: "1191",
    VotingStationName: "RHEEDERPARK COMBINED SCHOOL",
    Address: "LINSAY ROAD  RHEEDERPARK  WELKOM",
    Latitude: "-27.9532",
    Longitude: "26.7207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5146",
    WardLookupId: "3401",
    VDNumber: "22540014",
    RegisteredPopulation: "1521",
    VotingStationName: "T.S MATLALETSA PRIMARY SCHOOL",
    Address: "C/N ALTHEA AND GEDER  WELKOM  ODENDAALSRUS",
    Latitude: "-27.8723",
    Longitude: "26.702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5147",
    WardLookupId: "3401",
    VDNumber: "22540025",
    RegisteredPopulation: "1291",
    VotingStationName: "ODENSIA PRIMÃŠRE SKOOL",
    Address: "DE KOCK LAAN  ROSS KENT EAST  ODENDAALSRUS",
    Latitude: "-27.8761",
    Longitude: "26.6941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5148",
    WardLookupId: "3401",
    VDNumber: "22540182",
    RegisteredPopulation: "546",
    VotingStationName: "TSHEPONG MINE HOSTEL",
    Address: "TSHEPONG MINE  ODENDAALSRUS  ODENDAALSRUS",
    Latitude: "-27.9023",
    Longitude: "26.6777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5149",
    WardLookupId: "3401",
    VDNumber: "22600460",
    RegisteredPopulation: "271",
    VotingStationName: "JABULANI VILLAGE",
    Address: "JABULANI HALL  WELKOM  WELKOM",
    Latitude: "-27.9404",
    Longitude: "26.6997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5150",
    WardLookupId: "3401",
    VDNumber: "22600606",
    RegisteredPopulation: "1448",
    VotingStationName: "WESTERN HOLDINGS MINE PRIMARY SCHOOL",
    Address: "PHOMOLONG VILLAGE  WELKOM  WELKOM",
    Latitude: "-27.946",
    Longitude: "26.7101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5151",
    WardLookupId: "3401",
    VDNumber: "22600707",
    RegisteredPopulation: "647",
    VotingStationName: "REAHOLA HOUSING COMPLEX",
    Address: "BEDELIA  WELKOM",
    Latitude: "-27.9694",
    Longitude: "26.7029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5152",
    WardLookupId: "3401",
    VDNumber: "22640251",
    RegisteredPopulation: "39",
    VotingStationName: "MERINO",
    Address: "01 POINT  ODENDAALSRUS  ODENDAALSRUS",
    Latitude: "-27.9085",
    Longitude: "26.5954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5153",
    WardLookupId: "3402",
    VDNumber: "22450025",
    RegisteredPopulation: "1206",
    VotingStationName: "TSHIRELETSO PRIMARY SCHOOL",
    Address: "378 REUBEN MOKGOSI STREET  NYAKALLONG  ALLANRIDGE",
    Latitude: "-27.7757",
    Longitude: "26.6544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5154",
    WardLookupId: "3402",
    VDNumber: "22450047",
    RegisteredPopulation: "1175",
    VotingStationName: "ALLANRIDGE TLC",
    Address: "ALLANRIDGE TLC  ALLANRIDGE  ALLANRIDGE",
    Latitude: "-27.7495",
    Longitude: "26.6446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5155",
    WardLookupId: "3402",
    VDNumber: "22450070",
    RegisteredPopulation: "791",
    VotingStationName: "KATLEHONG PRE-SCHOOL",
    Address: "NYAKALLONG  ALLANRIDGE  NYAKALLONG",
    Latitude: "-27.7884",
    Longitude: "26.6513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5156",
    WardLookupId: "3402",
    VDNumber: "22540036",
    RegisteredPopulation: "2024",
    VotingStationName: "ODENDAALSRUS TOWN HALL",
    Address: "00 ODENDAAL  ODENDAALSRUS CENTRAL  ODENDAALSRUS",
    Latitude: "-27.8743",
    Longitude: "26.6852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5157",
    WardLookupId: "3402",
    VDNumber: "22640037",
    RegisteredPopulation: "183",
    VotingStationName: "ONRUST FARMSTORE",
    Address: "ONRUST FARM  ALLANRIDGE  ALLANRIDGE",
    Latitude: "-27.7522",
    Longitude: "26.5886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5158",
    WardLookupId: "3403",
    VDNumber: "22610034",
    RegisteredPopulation: "1390",
    VotingStationName: "TATAISO PRIMARY SCHOOL",
    Address: "3814 MOEDING STREET  MONYAKENG  WESSELSBRON",
    Latitude: "-27.8281",
    Longitude: "26.3676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5159",
    WardLookupId: "3403",
    VDNumber: "22610045",
    RegisteredPopulation: "1113",
    VotingStationName: "CHRIST FOR AFRICA CHURCH",
    Address: "2673 STICKS SIDE  WESSELSBRON   MONYAKENG",
    Latitude: "-27.8242",
    Longitude: "26.3719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5160",
    WardLookupId: "3403",
    VDNumber: "22610089",
    RegisteredPopulation: "867",
    VotingStationName: "ALFRED NZO HALL",
    Address: "CHURCH STREET  MONYAKENG LOCATION  ",
    Latitude: "-27.8315",
    Longitude: "26.3738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5161",
    WardLookupId: "3404",
    VDNumber: "22610012",
    RegisteredPopulation: "1318",
    VotingStationName: "NG KERK",
    Address: "ERWEE  WESSELSBRON  WESSELSBRON",
    Latitude: "-27.8542",
    Longitude: "26.3663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5162",
    WardLookupId: "3404",
    VDNumber: "22610067",
    RegisteredPopulation: "1539",
    VotingStationName: "LETSIBOLO PRIMARY SCHOOL",
    Address: "444 KGANG STREET  MAHLAKENG SECTION  WESSELSBRON",
    Latitude: "-27.8322",
    Longitude: "26.3783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5163",
    WardLookupId: "3404",
    VDNumber: "22610090",
    RegisteredPopulation: "599",
    VotingStationName: "BAPTIST - CHURCH",
    Address: "00 BOTHAVILLE  ",
    Latitude: "-27.8358",
    Longitude: "26.372",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5164",
    WardLookupId: "3404",
    VDNumber: "22650263",
    RegisteredPopulation: "60",
    VotingStationName: "VOLGESTRUISPAN FARM",
    Address: "WESSELSBRON   VOLGESTRUISPAN",
    Latitude: "-27.7162",
    Longitude: "26.3498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5165",
    WardLookupId: "3405",
    VDNumber: "22610056",
    RegisteredPopulation: "1607",
    VotingStationName: "KATOLOSO PUBLIC SCHOOL",
    Address: "1169 SEBOKOLODI STREET   MONYAKENG  WESSELSBRON",
    Latitude: "-27.826",
    Longitude: "26.3775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5166",
    WardLookupId: "3405",
    VDNumber: "22610102",
    RegisteredPopulation: "1184",
    VotingStationName: "UNITING REFORMED CHURCH IN SOUTH AFRCA-TENT",
    Address: "MOTLOENYA STREET  MONYAKENG  WESSELSBRON",
    Latitude: "-27.8291",
    Longitude: "26.3788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5167",
    WardLookupId: "3405",
    VDNumber: "22610113",
    RegisteredPopulation: "415",
    VotingStationName: "ITHABELENG SECONDARY SCHOOL",
    Address: "1299 BOIKHUTSONG STREET  MONYAKENG  WESSELSBRON",
    Latitude: "-27.8288",
    Longitude: "26.3767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5168",
    WardLookupId: "3406",
    VDNumber: "22610078",
    RegisteredPopulation: "1539",
    VotingStationName: "MONYAKENG HALL",
    Address: "4318 MATAMONG  MONYAKENG  BOTHAVILLE",
    Latitude: "-27.8233",
    Longitude: "26.3646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5169",
    WardLookupId: "3406",
    VDNumber: "22610124",
    RegisteredPopulation: "1481",
    VotingStationName: "MONYAKENG SECONDARY SCHOOL",
    Address: "9062/9 MONYAKENG   WESSELSBRON",
    Latitude: "-27.8201",
    Longitude: "26.377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5170",
    WardLookupId: "3407",
    VDNumber: "22470106",
    RegisteredPopulation: "1489",
    VotingStationName: "TSHEDISEHANG  PRIMARY SCHOOL",
    Address: "974/5 MOHLABANE STREET  KGOTSONG  BOTHAVILLE",
    Latitude: "-27.3878",
    Longitude: "26.6502",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5171",
    WardLookupId: "3407",
    VDNumber: "22470140",
    RegisteredPopulation: "1116",
    VotingStationName: "LUTHERAN CHURCH",
    Address: "3060 C/O LEDIGA & MONNAMONCHO STR  BOTHAVILLE  KGOTSONG",
    Latitude: "-27.3904",
    Longitude: "26.6534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5172",
    WardLookupId: "3407",
    VDNumber: "22470151",
    RegisteredPopulation: "640",
    VotingStationName: "MOPHATE SECONDARY SCHOOL",
    Address: "1355 MAJA STREET  KGOTSONG  BOTHAVILLE",
    Latitude: "-27.3848",
    Longitude: "26.6446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5173",
    WardLookupId: "3408",
    VDNumber: "22470016",
    RegisteredPopulation: "1063",
    VotingStationName: "BOTHAVILLE PRIMARY SCHOOL",
    Address: "634 MOKGAGE STREET  KGOTSONG  BOTHAVILLE",
    Latitude: "-27.3818",
    Longitude: "26.6507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5174",
    WardLookupId: "3408",
    VDNumber: "22470027",
    RegisteredPopulation: "1184",
    VotingStationName: "DIPHETOHO SECONDARY SCHOOL",
    Address: "6708 MPUMALANGA SECTION  KGOTSONG  BOTHAVILLE",
    Latitude: "-27.3796",
    Longitude: "26.6557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5175",
    WardLookupId: "3408",
    VDNumber: "22470083",
    RegisteredPopulation: "961",
    VotingStationName: "MOTHOOSELE CRECHE",
    Address: "2117 TSOAI STREET  KGOTSONG  BOTHAVILLE",
    Latitude: "-27.3872",
    Longitude: "26.6543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5176",
    WardLookupId: "3409",
    VDNumber: "22470038",
    RegisteredPopulation: "1472",
    VotingStationName: "OZIEL SELELE COMPREHENSIVE SECONDARY SCHOOL",
    Address: "2360 MPITSI STREET  KGOTSONG  BOTHAVILLE",
    Latitude: "-27.3916",
    Longitude: "26.6589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5177",
    WardLookupId: "3409",
    VDNumber: "22470184",
    RegisteredPopulation: "1120",
    VotingStationName: "BOIKUTLO PRIMARY SCHOOL",
    Address: "6149 KGOTSONG TOWNSHIP  KGOTSONG  BOTHAVILLE",
    Latitude: "-27.3822",
    Longitude: "26.6581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5178",
    WardLookupId: "3410",
    VDNumber: "22470050",
    RegisteredPopulation: "2501",
    VotingStationName: "MAMELLANG - THUTO SECONDARY SCHOOL",
    Address: "5233 MPUMALANGA SECTION  KGOTSONG  BOTHAVILLE",
    Latitude: "-27.3811",
    Longitude: "26.6633",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5179",
    WardLookupId: "3410",
    VDNumber: "22650421",
    RegisteredPopulation: "210",
    VotingStationName: "MORESTER",
    Address: "MORESTER  BOTHAVILLE  BOTHAVILLE",
    Latitude: "-27.2864",
    Longitude: "26.5463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5180",
    WardLookupId: "3410",
    VDNumber: "22650432",
    RegisteredPopulation: "117",
    VotingStationName: "NAMPO AGRICULTURAL SECONDARY SCHOOL",
    Address: "NAMPO  RUSTIG FARM  BOTHAVILLE",
    Latitude: "-27.2515",
    Longitude: "26.6569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5181",
    WardLookupId: "3411",
    VDNumber: "22470117",
    RegisteredPopulation: "1661",
    VotingStationName: "LESEDI CRECHE",
    Address: "8422 DITSHEHLONG  BOTHAVILLE  KGOTSONG",
    Latitude: "-27.3689",
    Longitude: "26.6507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5182",
    WardLookupId: "3411",
    VDNumber: "22470162",
    RegisteredPopulation: "1985",
    VotingStationName: "LETLOTLO NALEDI PRIMARY SCHOOL",
    Address: "9114 NALEDI SECTION   KGOTSONG  BOTHAVILLE",
    Latitude: "-27.3629",
    Longitude: "26.6494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5183",
    WardLookupId: "3412",
    VDNumber: "22470207",
    RegisteredPopulation: "186",
    VotingStationName: "TENT AT GELUKVLAKTE FARM",
    Address: "GELUKVLAKTE  BOTHAVILLE  BOTHAVILLE",
    Latitude: "-27.447",
    Longitude: "26.584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5184",
    WardLookupId: "3412",
    VDNumber: "22650184",
    RegisteredPopulation: "163",
    VotingStationName: "UNION FARM",
    Address: "UNION FARM   WESSELSBRON   UNION FARM",
    Latitude: "-27.7089",
    Longitude: "26.1748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5185",
    WardLookupId: "3412",
    VDNumber: "22650241",
    RegisteredPopulation: "263",
    VotingStationName: "RAPARDVLAKTE",
    Address: "00 BOTHAVILLE  ",
    Latitude: "-27.619",
    Longitude: "26.2316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5186",
    WardLookupId: "3412",
    VDNumber: "22650274",
    RegisteredPopulation: "333",
    VotingStationName: "BESEMPPAN FARM",
    Address: "00 BOTHAVILLE  ",
    Latitude: "-27.9522",
    Longitude: "26.3256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5187",
    WardLookupId: "3412",
    VDNumber: "22650308",
    RegisteredPopulation: "380",
    VotingStationName: "ITOKISETSENG COMBINED FARM SCHOOL",
    Address: "SANNIESRUS FARM  WESSELSBRON",
    Latitude: "-27.6795",
    Longitude: "26.46",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5188",
    WardLookupId: "3412",
    VDNumber: "22650319",
    RegisteredPopulation: "117",
    VotingStationName: "MIDDLESPRUIT FARM",
    Address: "MIDDLESPRUIT FARM   BOTHAVILLE  MIDDLESPRUIT FARM",
    Latitude: "-27.5887",
    Longitude: "26.4095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5189",
    WardLookupId: "3412",
    VDNumber: "22650331",
    RegisteredPopulation: "394",
    VotingStationName: "BOVAAL INTERMEDIARY SCHOOL",
    Address: "P.O BOX 1059  SPESBONA  BOTHAVILLE",
    Latitude: "-27.5069",
    Longitude: "26.42",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5190",
    WardLookupId: "3412",
    VDNumber: "22650342",
    RegisteredPopulation: "178",
    VotingStationName: "VAN ABOS VILLA SCHOOL",
    Address: "VAN ABOS VILLA FARM  BOTHAVILLE",
    Latitude: "-27.625",
    Longitude: "26.6876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5191",
    WardLookupId: "3412",
    VDNumber: "22650353",
    RegisteredPopulation: "430",
    VotingStationName: "GOLDEN FLEECE FARM",
    Address: "GOLDEN FLEECE FARM  BOTHAVILLE",
    Latitude: "-27.5657",
    Longitude: "26.6437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5192",
    WardLookupId: "3413",
    VDNumber: "22470072",
    RegisteredPopulation: "2544",
    VotingStationName: "HLABOLOHA PRIMARY SCHOOL",
    Address: "6972 MOKGAKGE STREET   KGOTSONG  BOTHAVILLE",
    Latitude: "-27.3751",
    Longitude: "26.6512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5193",
    WardLookupId: "3413",
    VDNumber: "22470195",
    RegisteredPopulation: "1087",
    VotingStationName: "ST MARYS CATHOLIC PRE-SCHOOL",
    Address: "10073 NALEDI SECTION   BOTHAVILLE   KGOTSONG",
    Latitude: "-27.3672",
    Longitude: "26.6465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5194",
    WardLookupId: "3414",
    VDNumber: "22470049",
    RegisteredPopulation: "2361",
    VotingStationName: "BOTHAVILLE TOWN HALL",
    Address: "13 PRELLER STREET  BOTHAVILLE  NALA",
    Latitude: "-27.3934",
    Longitude: "26.6186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5195",
    WardLookupId: "3414",
    VDNumber: "22470061",
    RegisteredPopulation: "1485",
    VotingStationName: "NEW CHURCH OF SOUTHERN AFRICA",
    Address: "1931 MOSOLODI STREET  KGOTSONG  BOTHAVILLE",
    Latitude: "-27.3788",
    Longitude: "26.6443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5196",
    WardLookupId: "3414",
    VDNumber: "22470173",
    RegisteredPopulation: "702",
    VotingStationName: "PEACE MAKERS MINISTRY",
    Address: "NALA  ",
    Latitude: "-27.3838",
    Longitude: "26.6383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5197",
    WardLookupId: "3415",
    VDNumber: "22260024",
    RegisteredPopulation: "2058",
    VotingStationName: "TENT AT MOEMANENG COMMUNITY HALL",
    Address: "845 MOEMANENG  MARQUARD  MARQUARD",
    Latitude: "-28.6744",
    Longitude: "27.4136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5198",
    WardLookupId: "3415",
    VDNumber: "22260046",
    RegisteredPopulation: "842",
    VotingStationName: "MARQUARD TOWN HALL",
    Address: "LOUIS TRICHARDT SQUARE  MARQUARD  MARQUARD",
    Latitude: "-28.6662",
    Longitude: "27.4272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5199",
    WardLookupId: "3415",
    VDNumber: "22410201",
    RegisteredPopulation: "49",
    VotingStationName: "POLMIETKUIL FARM",
    Address: "MARQUARD  ",
    Latitude: "-28.7052",
    Longitude: "27.6274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5200",
    WardLookupId: "3415",
    VDNumber: "22410212",
    RegisteredPopulation: "38",
    VotingStationName: "RIETFONTEIN FARM",
    Address: "SENEKAL  REITFONTEIN FARM  SENEKAL",
    Latitude: "-28.625",
    Longitude: "27.4827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5201",
    WardLookupId: "3416",
    VDNumber: "22260013",
    RegisteredPopulation: "959",
    VotingStationName: "ST COLUMBAS CHURCH",
    Address: "MOEMANENG  MARQUARD",
    Latitude: "-28.6763",
    Longitude: "27.4189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5202",
    WardLookupId: "3416",
    VDNumber: "22260035",
    RegisteredPopulation: "1589",
    VotingStationName: "MOEMANENG PRIMARY SCHOOL",
    Address: "407 JACOB MAKOELLE DRIVE  MOEMANENG  MARQUARD",
    Latitude: "-28.6793",
    Longitude: "27.4171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5203",
    WardLookupId: "3416",
    VDNumber: "22260057",
    RegisteredPopulation: "1181",
    VotingStationName: "LIFE GIVER MINISTRIES CHURCH",
    Address: "MARQUARD  MOEMANENG  MARQUARD",
    Latitude: "-28.682",
    Longitude: "27.4216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5204",
    WardLookupId: "3417",
    VDNumber: "22260068",
    RegisteredPopulation: "888",
    VotingStationName: "BOKAMOSO EARLY CHILD DEVELOPMENT CENTRE",
    Address: "3434 MOEMANENG  MARQUARD",
    Latitude: "-28.6877",
    Longitude: "27.4265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5205",
    WardLookupId: "3417",
    VDNumber: "22400031",
    RegisteredPopulation: "145",
    VotingStationName: "THE HEIGHTS FARM",
    Address: "SENEKAL  ",
    Latitude: "-28.4082",
    Longitude: "27.3977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5206",
    WardLookupId: "3417",
    VDNumber: "22400042",
    RegisteredPopulation: "206",
    VotingStationName: "MONTE VIDEO FARM",
    Address: "SENEKAL  ",
    Latitude: "-28.4708",
    Longitude: "27.5259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5207",
    WardLookupId: "3417",
    VDNumber: "22400053",
    RegisteredPopulation: "119",
    VotingStationName: "DELARINA FARM",
    Address: "SENEKAL  DELARINA FARM  SENEKAL",
    Latitude: "-28.0553",
    Longitude: "27.6718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5208",
    WardLookupId: "3417",
    VDNumber: "22400097",
    RegisteredPopulation: "423",
    VotingStationName: "LIBERTAS COMBINED FARM SCHOOL",
    Address: "SENEKAL  LIBERTAS FARM  SENEKAL",
    Latitude: "-28.1784",
    Longitude: "27.734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5209",
    WardLookupId: "3417",
    VDNumber: "22400390",
    RegisteredPopulation: "71",
    VotingStationName: "UITSIG FARM",
    Address: "UITSIG FARM  SENEKAL  SENEKAL",
    Latitude: "-28.289",
    Longitude: "27.5531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5210",
    WardLookupId: "3417",
    VDNumber: "22400402",
    RegisteredPopulation: "79",
    VotingStationName: "TENT NEXT TO LIBERIA FARM",
    Address: "LIBERIA FARM  SENEKAL  SENEKAL",
    Latitude: "-28.3777",
    Longitude: "27.7977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5211",
    WardLookupId: "3417",
    VDNumber: "22400413",
    RegisteredPopulation: "65",
    VotingStationName: "HARBIN STOOR FARM",
    Address: "SENEKAL  ",
    Latitude: "-28.3854",
    Longitude: "27.5901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5212",
    WardLookupId: "3417",
    VDNumber: "22400424",
    RegisteredPopulation: "59",
    VotingStationName: "GOEDEMOED FARM",
    Address: "SENEKAL  SETSOTO",
    Latitude: "-28.5366",
    Longitude: "27.7783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5213",
    WardLookupId: "3417",
    VDNumber: "22400479",
    RegisteredPopulation: "146",
    VotingStationName: "RIETPAN FARM",
    Address: "SENEKAL  ",
    Latitude: "-28.5006",
    Longitude: "27.6949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5214",
    WardLookupId: "3417",
    VDNumber: "22410223",
    RegisteredPopulation: "158",
    VotingStationName: "CYPRESS STRUCTURE",
    Address: "MARQUARD  CYPRESS FARM  MARQUARD",
    Latitude: "-28.5646",
    Longitude: "27.4028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5215",
    WardLookupId: "3417",
    VDNumber: "22410234",
    RegisteredPopulation: "100",
    VotingStationName: "WILDEBEESTLAAGTE HALL",
    Address: "MARQUARD  ",
    Latitude: "-28.5051",
    Longitude: "27.3562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5216",
    WardLookupId: "3417",
    VDNumber: "22410256",
    RegisteredPopulation: "105",
    VotingStationName: "POT THE RED",
    Address: "POT THE RED  MARQUARD  SETSOTO",
    Latitude: "-28.7415",
    Longitude: "27.2339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5217",
    WardLookupId: "3417",
    VDNumber: "22410267",
    RegisteredPopulation: "205",
    VotingStationName: "KOHLER LAMBERTINA SCHOOL",
    Address: "CLOCOLAN  ",
    Latitude: "-28.8197",
    Longitude: "27.5878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5218",
    WardLookupId: "3417",
    VDNumber: "22410458",
    RegisteredPopulation: "54",
    VotingStationName: "TROMMEL FARM",
    Address: "01 TROMMEL  MARQUARD  MARQUARD",
    Latitude: "-28.7214",
    Longitude: "27.4131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5219",
    WardLookupId: "3418",
    VDNumber: "22340012",
    RegisteredPopulation: "941",
    VotingStationName: "RORISANG PRIMARY SCHOOL",
    Address: "456 MATWABENG  SENEKAL  SENEKAL",
    Latitude: "-28.3467",
    Longitude: "27.6254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5220",
    WardLookupId: "3418",
    VDNumber: "22340045",
    RegisteredPopulation: "1430",
    VotingStationName: "KHOPOTSO APOSTOLIC FAITH MISSION",
    Address: "2595 ZONE 3  SENEKAL  MATWABENG",
    Latitude: "-28.3392",
    Longitude: "27.6253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5221",
    WardLookupId: "3418",
    VDNumber: "22340067",
    RegisteredPopulation: "963",
    VotingStationName: "SENEKAL TOWN HALL",
    Address: "10 VAN RIEBEECK STR  TOWN HALL  SENEKAL",
    Latitude: "-28.3201",
    Longitude: "27.6215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5222",
    WardLookupId: "3419",
    VDNumber: "22340056",
    RegisteredPopulation: "849",
    VotingStationName: "HOUSE OF HOPE COMMUNITY CHURCH",
    Address: "SENEKAL  MATWABENG  SENEKAL",
    Latitude: "-28.3387",
    Longitude: "27.6409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5223",
    WardLookupId: "3419",
    VDNumber: "22340089",
    RegisteredPopulation: "725",
    VotingStationName: "SEDI LA THUTO DAY CARE",
    Address: "4661 TAMBO  MOEMANENG  SENEKAL",
    Latitude: "-28.3375",
    Longitude: "27.6428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5224",
    WardLookupId: "3419",
    VDNumber: "22340090",
    RegisteredPopulation: "1132",
    VotingStationName: "EE MONESE HIGH SCHOOL",
    Address: "00 SENEKAL  SENEAKAL",
    Latitude: "-28.3443",
    Longitude: "27.6364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5225",
    WardLookupId: "3419",
    VDNumber: "22340102",
    RegisteredPopulation: "625",
    VotingStationName: "IKHETHENG CRECHE",
    Address: "3191 TAMBO  MATWABENG  SENEKAL",
    Latitude: "-28.3393",
    Longitude: "27.639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5226",
    WardLookupId: "3420",
    VDNumber: "22340078",
    RegisteredPopulation: "1460",
    VotingStationName: "REARABETSWE DAY CARE CENTRE",
    Address: "4602 TAMBO  MATWABENG  SENEKAL",
    Latitude: "-28.3365",
    Longitude: "27.6471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5227",
    WardLookupId: "3420",
    VDNumber: "22340113",
    RegisteredPopulation: "1299",
    VotingStationName: "GODS POWER BAPTIST CHURCH",
    Address: "5531 EXTENSION 5  MATWABENG  SENEKAL",
    Latitude: "-28.3338",
    Longitude: "27.6371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5228",
    WardLookupId: "3420",
    VDNumber: "22340124",
    RegisteredPopulation: "629",
    VotingStationName: "PAUL ERASMUS HIGH SCHOOL",
    Address: "01 VAN NIEKERK STREET  SENEKAL  SENEKAL",
    Latitude: "-28.312",
    Longitude: "27.6237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5229",
    WardLookupId: "3420",
    VDNumber: "22340135",
    RegisteredPopulation: "879",
    VotingStationName: "KATLEHONG DC",
    Address: "6252 TAMBO  MATWABENG  SENEKAL",
    Latitude: "-28.3387",
    Longitude: "27.6517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5230",
    WardLookupId: "3421",
    VDNumber: "22340023",
    RegisteredPopulation: "1372",
    VotingStationName: "TUTUBOLOHA INTERMEDIATE SCHOOL",
    Address: "893 JOHNSTONE STREET  MATWABENG  SENEKAL",
    Latitude: "-28.3452",
    Longitude: "27.6288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5231",
    WardLookupId: "3421",
    VDNumber: "22340034",
    RegisteredPopulation: "1370",
    VotingStationName: "REHOTSE INTERMEDIATE SCHOOL",
    Address: "2076 JOHNSTONE STREET  MATWABENG  SENEKAL",
    Latitude: "-28.3507",
    Longitude: "27.6287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5232",
    WardLookupId: "3422",
    VDNumber: "22170057",
    RegisteredPopulation: "1620",
    VotingStationName: "MPOKOANE CRECHE",
    Address: "4666 EXT 6 HLOHLOLWANE  CLOCOLAN",
    Latitude: "-28.8989",
    Longitude: "27.5632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5233",
    WardLookupId: "3422",
    VDNumber: "22170068",
    RegisteredPopulation: "1120",
    VotingStationName: "RUANG TSEBO PRIMARY SCHOOL",
    Address: "3333 EXTENSION 6  CLOCOLAN  HLOHLOLWANE",
    Latitude: "-28.896",
    Longitude: "27.5598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5234",
    WardLookupId: "3422",
    VDNumber: "22170079",
    RegisteredPopulation: "789",
    VotingStationName: "CALVARY CHURCH",
    Address: "3388 EXTENSION 6  HLOHLOLWANE  CLOCOLAN",
    Latitude: "-28.8951",
    Longitude: "27.5635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5235",
    WardLookupId: "3422",
    VDNumber: "22170080",
    RegisteredPopulation: "119",
    VotingStationName: "TENT NEXT TO SHELL GARAGE",
    Address: "111 CLOCOLAN  ",
    Latitude: "-28.9085",
    Longitude: "27.5717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5236",
    WardLookupId: "3423",
    VDNumber: "22170046",
    RegisteredPopulation: "1018",
    VotingStationName: "CLOCOLAN TOWN HALL",
    Address: "619 ANDRIES PRETORIUS STREET  CLOCOLAN  CLOCOLAN",
    Latitude: "-28.9153",
    Longitude: "27.5686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5237",
    WardLookupId: "3423",
    VDNumber: "22410289",
    RegisteredPopulation: "1400",
    VotingStationName: "TENT NEXT TO HLOHLOLWANE STADIUM",
    Address: "MANDELA PARK  HLOHLOLWANE  CLOCOLAN",
    Latitude: "-28.9111",
    Longitude: "27.5553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5238",
    WardLookupId: "3423",
    VDNumber: "22410290",
    RegisteredPopulation: "252",
    VotingStationName: "WALLOP INTERMEDIATE SCHOOL",
    Address: "CLOCLAN  CLOCOLAN  CLOCOLAN",
    Latitude: "-29.0026",
    Longitude: "27.6071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5239",
    WardLookupId: "3424",
    VDNumber: "22200028",
    RegisteredPopulation: "1656",
    VotingStationName: "MEQHELENG PUBLIC SCHOOL",
    Address: "MEQHELENG  FICKSBURG",
    Latitude: "-28.8897",
    Longitude: "27.88",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5240",
    WardLookupId: "3424",
    VDNumber: "22200107",
    RegisteredPopulation: "1172",
    VotingStationName: "HORTICULTURAL HALL",
    Address: "TOORN STREET  FICKSBURG  FICKSBURG",
    Latitude: "-28.873",
    Longitude: "27.8749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5241",
    WardLookupId: "3424",
    VDNumber: "22200174",
    RegisteredPopulation: "1134",
    VotingStationName: "CALEDON PARK HALL",
    Address: "CALEDON STREET  FICKSBURG  FICKSBURG",
    Latitude: "-28.8836",
    Longitude: "27.8829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5242",
    WardLookupId: "3425",
    VDNumber: "22170013",
    RegisteredPopulation: "641",
    VotingStationName: "HLOHLOLWANE PRIMARY SCHOOL",
    Address: "736 THETHE  HLOHLOLWANE  CLOCOLAN",
    Latitude: "-28.9123",
    Longitude: "27.5519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5243",
    WardLookupId: "3425",
    VDNumber: "22170024",
    RegisteredPopulation: "1070",
    VotingStationName: "KGUTLISO PRIMARY SCHOOL",
    Address: "454 HLOHLOLWANE  CLOCOLAN",
    Latitude: "-28.9073",
    Longitude: "27.5547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5244",
    WardLookupId: "3425",
    VDNumber: "22170035",
    RegisteredPopulation: "1281",
    VotingStationName: "HLOHLOLWANE CRECHE",
    Address: "2241 HLOHLOLWANE  SUNFLOWER PARK  CLOCOLAN",
    Latitude: "-28.9073",
    Longitude: "27.5588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5245",
    WardLookupId: "3426",
    VDNumber: "22200073",
    RegisteredPopulation: "1750",
    VotingStationName: "QHOWANENG PUBLIC SCHOOL",
    Address: "3079 ZONE 6 MEQHELENG  MEQHELENG  FICKSBURG",
    Latitude: "-28.9073",
    Longitude: "27.8833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5246",
    WardLookupId: "3426",
    VDNumber: "22200129",
    RegisteredPopulation: "1286",
    VotingStationName: "BOITUMELO SECONDARY SCHOOL",
    Address: "2443 ZONE 4 MEQHELENG  MEQHELENG  FICSKBURG",
    Latitude: "-28.9025",
    Longitude: "27.8819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5247",
    WardLookupId: "3427",
    VDNumber: "22200095",
    RegisteredPopulation: "975",
    VotingStationName: "ITUMELENG CRECHE",
    Address: "4567 ZONE 7  MEQHELENG  FICKSBURG",
    Latitude: "-28.9013",
    Longitude: "27.8765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5248",
    WardLookupId: "3427",
    VDNumber: "22200130",
    RegisteredPopulation: "1363",
    VotingStationName: "TENT NEXT TO THE BRIDGE",
    Address: "MEQHELENG  FICKSBURG",
    Latitude: "-28.9038",
    Longitude: "27.8684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5249",
    WardLookupId: "3427",
    VDNumber: "22200141",
    RegisteredPopulation: "799",
    VotingStationName: "TENT NEXT TO QHOWANENG PRIMARY SCHOOL",
    Address: "ZONE 07  MEQHELENG  FICKSBURG",
    Latitude: "-28.9066",
    Longitude: "27.8806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5250",
    WardLookupId: "3427",
    VDNumber: "22200185",
    RegisteredPopulation: "978",
    VotingStationName: "MASAKENG OFFICE",
    Address: "4915 ZONE 7  MEQHELENG   FICKSBURG",
    Latitude: "-28.9042",
    Longitude: "27.8749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5251",
    WardLookupId: "3428",
    VDNumber: "22200039",
    RegisteredPopulation: "1633",
    VotingStationName: "MARALLANENG HIGH SCHOOL",
    Address: "FICKSBURG  MEQHELENG  FICKSBURG",
    Latitude: "-28.8975",
    Longitude: "27.8791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5252",
    WardLookupId: "3428",
    VDNumber: "22200040",
    RegisteredPopulation: "1124",
    VotingStationName: "ST. JOSEPHS PRIMARY SCHOOL",
    Address: "1609 ZONE 2  MEQHELENG  FICKSBURG",
    Latitude: "-28.8965",
    Longitude: "27.8845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5253",
    WardLookupId: "3428",
    VDNumber: "22200196",
    RegisteredPopulation: "500",
    VotingStationName: "MEQHELENG SPORTS HALL",
    Address: "12 MEQHELENG  ",
    Latitude: "-28.9029",
    Longitude: "27.8806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5254",
    WardLookupId: "3429",
    VDNumber: "22200017",
    RegisteredPopulation: "1149",
    VotingStationName: "FICKSBURG PRIMARY SCHOOL",
    Address: "IMPERANI  FICKSBURG  FICKSBURG",
    Latitude: "-28.8678",
    Longitude: "27.8796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5255",
    WardLookupId: "3429",
    VDNumber: "22200163",
    RegisteredPopulation: "1254",
    VotingStationName: "KATLEHONG DAY CARE CENTER",
    Address: "8912 ZONE 8  MEQHELENG  FICKSBURG",
    Latitude: "-28.8957",
    Longitude: "27.8648",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5256",
    WardLookupId: "3429",
    VDNumber: "22410032",
    RegisteredPopulation: "353",
    VotingStationName: "RAMAFUTSANA INTERMEDIATE FARM SCHOOL",
    Address: "00 DEEMSTAR FARM  ",
    Latitude: "-28.8803",
    Longitude: "27.7371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5257",
    WardLookupId: "3429",
    VDNumber: "22410076",
    RegisteredPopulation: "226",
    VotingStationName: "SEFOMELA PRIMARY FARM SCHOOL",
    Address: "BERLIN FARM  FICKSBURG  FICKSBURG",
    Latitude: "-28.7557",
    Longitude: "27.9653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5258",
    WardLookupId: "3429",
    VDNumber: "22410087",
    RegisteredPopulation: "89",
    VotingStationName: "TENT (AT DANKBAAR FARM)",
    Address: "FICKSBURG  DANKBAAR FARM  FICKSBURG",
    Latitude: "-28.5897",
    Longitude: "27.8785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5259",
    WardLookupId: "3429",
    VDNumber: "22410100",
    RegisteredPopulation: "171",
    VotingStationName: "BIESSIEPAN STOOR FARM",
    Address: "FICKSBURG  ",
    Latitude: "-28.6449",
    Longitude: "27.8497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5260",
    WardLookupId: "3429",
    VDNumber: "22410111",
    RegisteredPopulation: "93",
    VotingStationName: "MOOLMANSHOEK FARM",
    Address: "FICKSBURG  MOOLMANSHOEK FARM  FICKSBURG",
    Latitude: "-28.6261",
    Longitude: "27.9924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5261",
    WardLookupId: "3429",
    VDNumber: "22410133",
    RegisteredPopulation: "341",
    VotingStationName: "FRAAIUITZUIT FARM",
    Address: "FICKSBURG  ",
    Latitude: "-28.7954",
    Longitude: "28.0295",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5262",
    WardLookupId: "3429",
    VDNumber: "22410144",
    RegisteredPopulation: "185",
    VotingStationName: "MISSIO EDEI FARM SCHOOL",
    Address: "EENSVOGELAAGTE FARM  FICKSBURG  FICKSBURG",
    Latitude: "-28.6558",
    Longitude: "27.792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5263",
    WardLookupId: "3429",
    VDNumber: "22410188",
    RegisteredPopulation: "63",
    VotingStationName: "GOEWERNEURSKOP FARM",
    Address: "GOEWERNEURSKOP  FICKSBURG  FICKSBURG",
    Latitude: "-28.7764",
    Longitude: "27.6452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5264",
    WardLookupId: "3429",
    VDNumber: "22410425",
    RegisteredPopulation: "349",
    VotingStationName: "HAMMONIA INTERMEDIATE FARM SCHOOL",
    Address: "FICKSBURG  HAMMONIA  FICKSBURG",
    Latitude: "-28.723",
    Longitude: "27.8166",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5265",
    WardLookupId: "3430",
    VDNumber: "22200051",
    RegisteredPopulation: "997",
    VotingStationName: "TENT AT MAPENYADIRA COMMUNITY HALL",
    Address: "MEQHELENG  FICKSBURG  FICKSBURG",
    Latitude: "-28.8962",
    Longitude: "27.8788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5266",
    WardLookupId: "3430",
    VDNumber: "22200062",
    RegisteredPopulation: "2644",
    VotingStationName: "TLOTLISONG SECONDARY SCHOOL",
    Address: "1731 CROSS ROAD  MEQHELENG  FICKSBURG",
    Latitude: "-28.8937",
    Longitude: "27.8753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5267",
    WardLookupId: "3431",
    VDNumber: "22200118",
    RegisteredPopulation: "3032",
    VotingStationName: "MEHOPUNG PRIMARY SCHOOL",
    Address: "00 FICKSBURG  ",
    Latitude: "-28.8992",
    Longitude: "27.8669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5268",
    WardLookupId: "943",
    VDNumber: "22150088",
    RegisteredPopulation: "1189",
    VotingStationName: "BODIKELA HIGHER PRIMARY SCHOOL",
    Address: "2306 MOKGELE STREET  BOHLOKONG  BETHLEHEM",
    Latitude: "-28.2079",
    Longitude: "28.3254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5269",
    WardLookupId: "943",
    VDNumber: "22150123",
    RegisteredPopulation: "1951",
    VotingStationName: "MATSWATHAKA LOWER PRIMARY SCHOOL",
    Address: "2812 SHUPINYANENG STREET  BOHLOKONG  BETHLEHEM",
    Latitude: "-28.2047",
    Longitude: "28.3266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5270",
    WardLookupId: "944",
    VDNumber: "22150101",
    RegisteredPopulation: "1721",
    VotingStationName: "THABANG LOWER PRIMARY SCHOOL",
    Address: "BB STREET  BOHLOKONG  BETHLEHEM",
    Latitude: "-28.212",
    Longitude: "28.3221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5271",
    WardLookupId: "944",
    VDNumber: "22150112",
    RegisteredPopulation: "1304",
    VotingStationName: "IMPUMELELO LOWER PRIMARY SCHOOL",
    Address: "3306 NHLABATH STREET  BOHLOKONG  BETHLEHEM",
    Latitude: "-28.207",
    Longitude: "28.332",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5272",
    WardLookupId: "945",
    VDNumber: "22150066",
    RegisteredPopulation: "1794",
    VotingStationName: "BETHLEHEM COMBINED SCHOOL",
    Address: "186 POPULIER STREET  BETHLEHEM  BETHLEHEM",
    Latitude: "-28.2183",
    Longitude: "28.3321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5273",
    WardLookupId: "945",
    VDNumber: "22150077",
    RegisteredPopulation: "1451",
    VotingStationName: "COMPREHENSIVE SCHOOL",
    Address: "3539 NTSINQILA STREET  BOHLOKONG  BETHLEHEM",
    Latitude: "-28.2027",
    Longitude: "28.3344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5274",
    WardLookupId: "945",
    VDNumber: "22150303",
    RegisteredPopulation: "1075",
    VotingStationName: "TENT NEXT TO AFRIKAANSE PROTESTANTE KERK",
    Address: "MUNICIPAL OPEN SPACE  PANORAMA  BETHLEHEM",
    Latitude: "-28.2277",
    Longitude: "28.3355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5275",
    WardLookupId: "946",
    VDNumber: "22150033",
    RegisteredPopulation: "1743",
    VotingStationName: "NTHUTE PUBLIC SCHOOL",
    Address: "6832 BOHLOKONG  BETHLEHEM",
    Latitude: "-28.1928",
    Longitude: "28.3367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5276",
    WardLookupId: "946",
    VDNumber: "22150202",
    RegisteredPopulation: "1941",
    VotingStationName: "AFRICAN GOSPEL CHURCH",
    Address: "EXT 5 CIRCLE 5   BOHLOKONG  (FS192 - DIHLABENG [BETHLEHEM])",
    Latitude: "-28.1966",
    Longitude: "28.3362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5277",
    WardLookupId: "947",
    VDNumber: "22150178",
    RegisteredPopulation: "2239",
    VotingStationName: "APOSTOLIC SALVATION GOSPEL CHURCH",
    Address: "5314 EXTENSION 2 BOHLOKONG  BETHLEHEM  BOHLOKONG  BETHLEHEM",
    Latitude: "-28.1954",
    Longitude: "28.3319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5278",
    WardLookupId: "948",
    VDNumber: "22150213",
    RegisteredPopulation: "1464",
    VotingStationName: "ST JOHN APOSTOLIC CHURCH",
    Address: "PHASE 7  BOHLOKONG  BETHLEHEM",
    Latitude: "-28.192",
    Longitude: "28.3207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5279",
    WardLookupId: "948",
    VDNumber: "22150235",
    RegisteredPopulation: "1533",
    VotingStationName: "TENT NEXT TO THE ROCK MINISTRIES",
    Address: "PHASE 7  BOHLOKONG  PHASE 7 BOHLOKONG",
    Latitude: "-28.1878",
    Longitude: "28.3203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5280",
    WardLookupId: "948",
    VDNumber: "22150279",
    RegisteredPopulation: "1003",
    VotingStationName: "MOTSEKUWA SECTION TENT (NEXT TO ZCC CHURCH)",
    Address: "NULL  BOHLOKONG  BETHLEHEM",
    Latitude: "-28.1972",
    Longitude: "28.3206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5281",
    WardLookupId: "949",
    VDNumber: "22150167",
    RegisteredPopulation: "1733",
    VotingStationName: "KINGDOM CHURCH",
    Address: "881 EXT 1  BOHLOKONG  BETHLEHEM",
    Latitude: "-28.1981",
    Longitude: "28.3259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5282",
    WardLookupId: "949",
    VDNumber: "22150246",
    RegisteredPopulation: "1842",
    VotingStationName: "NEW LIFE CHURCH",
    Address: "8922 EXT 7 BOHLOKONG  BOHLOKONG  BETHLEHEM",
    Latitude: "-28.1879",
    Longitude: "28.3252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5283",
    WardLookupId: "950",
    VDNumber: "22150099",
    RegisteredPopulation: "1142",
    VotingStationName: "IMPUCUKO HIGHER PRIMARY SCHOOL",
    Address: "1548 A MABIZELA STREET  BOHLOKONG  BETHLEHEM",
    Latitude: "-28.216",
    Longitude: "28.3234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5284",
    WardLookupId: "950",
    VDNumber: "22150224",
    RegisteredPopulation: "1415",
    VotingStationName: "CHRISTIAN ASSEMBLIES CHURCH",
    Address: "714 C/O KOITING STREET  BOHLOKONG  BETHLEHEM",
    Latitude: "-28.2156",
    Longitude: "28.3178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5285",
    WardLookupId: "950",
    VDNumber: "22150358",
    RegisteredPopulation: "450",
    VotingStationName: "THABO THOKOZA SECONDARY SCHOOL",
    Address: "02 TSHABALALA STREET  BOHLOKONG  BETHLEHEM",
    Latitude: "-28.2176",
    Longitude: "28.3226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5286",
    WardLookupId: "951",
    VDNumber: "22150022",
    RegisteredPopulation: "1713",
    VotingStationName: "MALUTI HOOGLAND SKOOL",
    Address: "28 UNIONSTRAAT  MORELIG  BETHLEHEM",
    Latitude: "-28.2344",
    Longitude: "28.3026",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5287",
    WardLookupId: "951",
    VDNumber: "22150134",
    RegisteredPopulation: "822",
    VotingStationName: "WEST STREET TENT ( NEXT TO GOLF GROUND)",
    Address: "WEST STREET  DIHLABENG   DIHLABENG",
    Latitude: "-28.2201",
    Longitude: "28.3144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5288",
    WardLookupId: "951",
    VDNumber: "22150190",
    RegisteredPopulation: "836",
    VotingStationName: "TEMPORARY VOTING STATION (BOHLOKONG POWER STATION)",
    Address: "EXT 1  BOHLOKONG  BETHLEHEM",
    Latitude: "-28.1989",
    Longitude: "28.323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5289",
    WardLookupId: "951",
    VDNumber: "22150257",
    RegisteredPopulation: "553",
    VotingStationName: "VUKA TENT (NEXT TO DR. MOETI`S SURGERY)",
    Address: "DEVELLIERS  VUKA  BETHLEHEM",
    Latitude: "-28.213",
    Longitude: "28.3179",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5290",
    WardLookupId: "952",
    VDNumber: "22150044",
    RegisteredPopulation: "1370",
    VotingStationName: "PANORAMA TENT (NEXT TO ESKOM POWER STATION)",
    Address: "30 DE KOK  PANORAM  BETHLEHEM",
    Latitude: "-28.2335",
    Longitude: "28.3262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5291",
    WardLookupId: "952",
    VDNumber: "22150325",
    RegisteredPopulation: "1484",
    VotingStationName: "WITTERBERG HOÃ‹RSKOOL",
    Address: "(FS192 - DIHLABENG [BETHLEHEM])   ",
    Latitude: "-28.2375",
    Longitude: "28.3171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5292",
    WardLookupId: "952",
    VDNumber: "22150370",
    RegisteredPopulation: "185",
    VotingStationName: "TENT AT LOCH ATHLONE",
    Address: "LOCH ATHLONE  BOHLOKONG  BETHLEHEM",
    Latitude: "-28.2529",
    Longitude: "28.3058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5293",
    WardLookupId: "952",
    VDNumber: "22400558",
    RegisteredPopulation: "431",
    VotingStationName: "TENT NEXT TO GLEN ASH SCHOOL",
    Address: "BETHLEHEN  BETHLEHEM  BETHLEHEM",
    Latitude: "-28.2609",
    Longitude: "28.3405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5294",
    WardLookupId: "953",
    VDNumber: "22150189",
    RegisteredPopulation: "751",
    VotingStationName: "BOIKWETLISONG CRECHE",
    Address: "SELAHLIWE  BOHLOKONG  BOHLOKONG",
    Latitude: "-28.1995",
    Longitude: "28.325",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5295",
    WardLookupId: "953",
    VDNumber: "22150369",
    RegisteredPopulation: "587",
    VotingStationName: "TENT AT EXTENSION 7",
    Address: "EXTENSION 7  BOHLOKONG  BETHLEHEM",
    Latitude: "-28.1923",
    Longitude: "28.3411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5296",
    WardLookupId: "953",
    VDNumber: "22150381",
    RegisteredPopulation: "957",
    VotingStationName: "TENT AT VOGELFONTEIN",
    Address: "VOGELFONTEIN  BETHLEHEM",
    Latitude: "-28.202",
    Longitude: "28.3467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5297",
    WardLookupId: "953",
    VDNumber: "22400176",
    RegisteredPopulation: "378",
    VotingStationName: "RABOHLALE PRIMARY SCHOOL",
    Address: "BOX 535,BHM  UITVLUG FARM  BETHLEHEM",
    Latitude: "-28.3132",
    Longitude: "28.5025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5298",
    WardLookupId: "953",
    VDNumber: "22400198",
    RegisteredPopulation: "206",
    VotingStationName: "MAMOJALEFA PRIMARY SCHOOL",
    Address: "EXCELSIOR FARM  (FS192 - DIHLABENG [BETHLEHEM])   BETHLEHEM",
    Latitude: "-28.0794",
    Longitude: "28.4317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5299",
    WardLookupId: "953",
    VDNumber: "22400200",
    RegisteredPopulation: "123",
    VotingStationName: "ABAFELDING TENT (NEXT TO THABA BOSIU  SCHOOL)",
    Address: "AFRIKASKOP  KESTEL",
    Latitude: "-28.1454",
    Longitude: "28.7322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5300",
    WardLookupId: "953",
    VDNumber: "22400211",
    RegisteredPopulation: "114",
    VotingStationName: "MOLEBOHE PRIMARY SCHOOL",
    Address:
      "(FS192 - DIHLABENG [BETHLEHEM])   SUSANANA SKOP FARM   (FS192 - DIHLABENG [BETHLEHEM])",
    Latitude: "-28.189",
    Longitude: "28.5039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5301",
    WardLookupId: "953",
    VDNumber: "22400222",
    RegisteredPopulation: "194",
    VotingStationName: "SOBA PRIMARY SCHOOL",
    Address: "FARM    BOHLOKONG  BETHLEHEM",
    Latitude: "-28.2103",
    Longitude: "28.6933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5302",
    WardLookupId: "953",
    VDNumber: "22400446",
    RegisteredPopulation: "481",
    VotingStationName: "KRANSFONTEIN PRIMARY SCHOOL",
    Address: "KRANSFONTEIN ESTATES  KRANSFONTEIN  BETHLEHEM",
    Latitude: "-28.1334",
    Longitude: "28.5549",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5303",
    WardLookupId: "953",
    VDNumber: "22400457",
    RegisteredPopulation: "306",
    VotingStationName: "LERALLA INTERMEDIATE SCHOOL",
    Address: "MIEMIESRUST FARM  CLARENS  CLARENS",
    Latitude: "-28.4559",
    Longitude: "28.3976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5304",
    WardLookupId: "954",
    VDNumber: "22270058",
    RegisteredPopulation: "1765",
    VotingStationName: "MASHAENG COMMUNITY HALL",
    Address: "1901 MASHAENG  DIHLABENG",
    Latitude: "-28.6148",
    Longitude: "28.199",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5305",
    WardLookupId: "954",
    VDNumber: "22270069",
    RegisteredPopulation: "1509",
    VotingStationName: "TSEPANO 111 PUBLIC SCHOOL",
    Address: "BETHLEHEM  ",
    Latitude: "-28.6088",
    Longitude: "28.1962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5306",
    WardLookupId: "954",
    VDNumber: "22410054",
    RegisteredPopulation: "176",
    VotingStationName: "WELVANPAS PRIMARY SCHOOL",
    Address: "(FS192 - DIHLABENG [BETHLEHEM])   ",
    Latitude: "-28.5893",
    Longitude: "28.1641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5307",
    WardLookupId: "954",
    VDNumber: "22410098",
    RegisteredPopulation: "200",
    VotingStationName: "MEYNELL PRIMARY FARM SCHOOL",
    Address: "BELLEVUE FARM  FOURIESBURG  FOURIESBURG",
    Latitude: "-28.6566",
    Longitude: "28.0852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5308",
    WardLookupId: "954",
    VDNumber: "22410278",
    RegisteredPopulation: "82",
    VotingStationName: "CAROLINE PRIMARY SCHOOL",
    Address: "FOURIESBURG  CAROLINE FARM  FOURIESBURG",
    Latitude: "-28.6567",
    Longitude: "28.2325",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5309",
    WardLookupId: "954",
    VDNumber: "22410469",
    RegisteredPopulation: "134",
    VotingStationName: "MASHAYENG PRIMARY SCHOOL",
    Address: "LOSKOP FARM  FOURIESBURG  FORIESBURG",
    Latitude: "-28.639",
    Longitude: "28.0669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5310",
    WardLookupId: "955",
    VDNumber: "22270014",
    RegisteredPopulation: "2016",
    VotingStationName: "PHAPAMA PRIMARY SCHOOL",
    Address: "NO 118  MASHAENG  FOURIESBURG",
    Latitude: "-28.6221",
    Longitude: "28.1918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5311",
    WardLookupId: "955",
    VDNumber: "22270025",
    RegisteredPopulation: "929",
    VotingStationName: "ST FELICITAS ANGLICAN CHURCH",
    Address: "NO 566  MASHAENG  FOURIESBURG",
    Latitude: "-28.6243",
    Longitude: "28.1912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5312",
    WardLookupId: "956",
    VDNumber: "22270036",
    RegisteredPopulation: "953",
    VotingStationName: "FOURIESBURG TOWN HALL",
    Address: "C/O HEYNS STR - MARTIN STR  FOURIESBURG",
    Latitude: "-28.6194",
    Longitude: "28.2088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5313",
    WardLookupId: "956",
    VDNumber: "22270047",
    RegisteredPopulation: "1883",
    VotingStationName: "IPOKELLENG SECONDARY SCHOOL",
    Address: "MASHAENG  FOURIESBURG",
    Latitude: "-28.6194",
    Longitude: "28.1962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5314",
    WardLookupId: "956",
    VDNumber: "22400468",
    RegisteredPopulation: "233",
    VotingStationName: "HIGHLAND PRIMARY SCHOOL",
    Address: "HIGHLAND FARM  HIGHLANDS FARM  BETHLEHEM",
    Latitude: "-28.3938",
    Longitude: "28.2737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5315",
    WardLookupId: "956",
    VDNumber: "22400604",
    RegisteredPopulation: "172",
    VotingStationName: "DALJOSSAFAT PRIMARY SCHOOL",
    Address: "BETHLEHEM  ",
    Latitude: "-28.348",
    Longitude: "28.3862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5316",
    WardLookupId: "956",
    VDNumber: "22410065",
    RegisteredPopulation: "441",
    VotingStationName: "TOKOLOHO PRIMARY SCHOOL",
    Address: "SHERIDAN  FOURIESBURG",
    Latitude: "-28.53",
    Longitude: "28.1183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5317",
    WardLookupId: "956",
    VDNumber: "22410357",
    RegisteredPopulation: "244",
    VotingStationName: "KWALENT PRIMARYSCHOOL",
    Address: "SLABBERTS FARM  FOURIESBURG  FOURIESBURG",
    Latitude: "-28.493",
    Longitude: "28.2308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5318",
    WardLookupId: "957",
    VDNumber: "22280015",
    RegisteredPopulation: "2349",
    VotingStationName: "TAUNG SECONDARY SCHOOL",
    Address: "117  MAUTSE   ROSENDAL  ROSENDAL",
    Latitude: "-28.5097",
    Longitude: "27.9228",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5319",
    WardLookupId: "957",
    VDNumber: "22300029",
    RegisteredPopulation: "457",
    VotingStationName: "TENT NEXT TO PAUL ROUX CLINIC (VOORTREKKER STREET)",
    Address: "PAUL ROUX  ",
    Latitude: "-28.2957",
    Longitude: "27.9551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5320",
    WardLookupId: "957",
    VDNumber: "22410043",
    RegisteredPopulation: "651",
    VotingStationName: "HELENA PRIMARY SCHOOL",
    Address: "DIHLABENG   ",
    Latitude: "-28.4384",
    Longitude: "28.1936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5321",
    WardLookupId: "957",
    VDNumber: "22410177",
    RegisteredPopulation: "304",
    VotingStationName: "MAUTSE CULTURAL VILLAGE",
    Address: "ROSENDAL  ",
    Latitude: "-28.5068",
    Longitude: "27.9259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5322",
    WardLookupId: "957",
    VDNumber: "22410447",
    RegisteredPopulation: "87",
    VotingStationName: "DEECHO PRIMARY SCHOOL",
    Address: "PAUL ROUX  ERICA FARM  PAUL ROUX",
    Latitude: "-28.3744",
    Longitude: "27.8875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5323",
    WardLookupId: "958",
    VDNumber: "22150055",
    RegisteredPopulation: "1334",
    VotingStationName: "TRUIDA KESTELL SCHOOL",
    Address: "14 WESSELS STR  BETHLEHEM CENTRAL  BETHLEHEM",
    Latitude: "-28.2379",
    Longitude: "28.3094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5324",
    WardLookupId: "958",
    VDNumber: "22150145",
    RegisteredPopulation: "1574",
    VotingStationName: "KHANYENG HIGHER PRIMARY SCHOOL",
    Address: "1093 MANYE STREET  BOHLOKONG  BETHLEHEM",
    Latitude: "-28.2207",
    Longitude: "28.3218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5325",
    WardLookupId: "958",
    VDNumber: "22150268",
    RegisteredPopulation: "202",
    VotingStationName: "KGALAPA TRAINING CENTRE",
    Address: "11 NAUDE STREET  BETHLEHEM  BETHLEHEM",
    Latitude: "-28.2301",
    Longitude: "28.3096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5326",
    WardLookupId: "958",
    VDNumber: "22150314",
    RegisteredPopulation: "1110",
    VotingStationName: "MOTSHEPUWA PRIMARY SCHOOL",
    Address: "111 BETHLEHEM  ",
    Latitude: "-28.2225",
    Longitude: "28.3171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5327",
    WardLookupId: "959",
    VDNumber: "22300018",
    RegisteredPopulation: "2041",
    VotingStationName: "REKGOTSOFETSI SECONDARY SCHOOL",
    Address: "FATENG TSE NTSHO  PAUL ROUX",
    Latitude: "-28.2998",
    Longitude: "27.967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5328",
    WardLookupId: "959",
    VDNumber: "22300030",
    RegisteredPopulation: "1364",
    VotingStationName: "SEKOKO PRIMARY SCHOOL",
    Address: "FATENG TSE NTSHO  PAUL ROUX",
    Latitude: "-28.298",
    Longitude: "27.966",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5329",
    WardLookupId: "959",
    VDNumber: "22400154",
    RegisteredPopulation: "207",
    VotingStationName: "MATOPPO PRIMARY SCHOOL",
    Address: "MATOPPO FARM  PAUL ROUX  PAUL ROUX",
    Latitude: "-28.3265",
    Longitude: "28.0625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5330",
    WardLookupId: "959",
    VDNumber: "22400581",
    RegisteredPopulation: "192",
    VotingStationName: "DUIKFONTEIN PRIMARY SCHOOL",
    Address: "N5 ROAD  DIHLABENG  DIHLABENG",
    Latitude: "-28.2674",
    Longitude: "28.0878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5331",
    WardLookupId: "960",
    VDNumber: "22150011",
    RegisteredPopulation: "1540",
    VotingStationName: "NTSU S SCHOOL",
    Address: "111 BETHLEHEM  ",
    Latitude: "-28.2202",
    Longitude: "28.2896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5332",
    WardLookupId: "960",
    VDNumber: "22150336",
    RegisteredPopulation: "511",
    VotingStationName: "MORELIG TENT (NEXT TO NEDERDUITS KERK)",
    Address: "111 BETHLEHEM  BOHLOKONG  BOHLOKONG",
    Latitude: "-28.2267",
    Longitude: "28.2957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5333",
    WardLookupId: "960",
    VDNumber: "22150347",
    RegisteredPopulation: "776",
    VotingStationName: "MORELIG TENT( NEXT TO NG KERK)",
    Address: "111 BETHLEHEM  ",
    Latitude: "-28.2142",
    Longitude: "28.2915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5334",
    WardLookupId: "960",
    VDNumber: "22400143",
    RegisteredPopulation: "296",
    VotingStationName: "MONT - REVE PRIMARY SCHOOL",
    Address:
      "(FS192 - DIHLABENG [BETHLEHEM])   MONT REVE   (FS192 - DIHLABENG [BETHLEHEM])",
    Latitude: "-28.2966",
    Longitude: "28.2165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5335",
    WardLookupId: "960",
    VDNumber: "22400233",
    RegisteredPopulation: "398",
    VotingStationName: "MEETS FARM SCHOOL",
    Address: "RETREAT  MEETS FARM  BETHLEHEM",
    Latitude: "-28.1985",
    Longitude: "28.22",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5336",
    WardLookupId: "960",
    VDNumber: "22400592",
    RegisteredPopulation: "262",
    VotingStationName: "JOHANNA FOURIE PF/SCHOOL",
    Address:
      "(FS192 - DIHLABENG [BETHLEHEM])   VALS RIVER FARM   (FS192 - DIHLABENG [BETHLEHEM])",
    Latitude: "-28.1578",
    Longitude: "28.1128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5337",
    WardLookupId: "961",
    VDNumber: "22150156",
    RegisteredPopulation: "1769",
    VotingStationName: "TENT NEXT TO NANDOS TUCK SHOP",
    Address: "OPEN SPACE  BOHLOKONG  BETHLEHEM",
    Latitude: "-28.1911",
    Longitude: "28.3303",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5338",
    WardLookupId: "961",
    VDNumber: "22150280",
    RegisteredPopulation: "1313",
    VotingStationName: "LOCK LOMOND PF/SCHOOL",
    Address: "(FS192 - DIHLABENG [BETHLEHEM])   ",
    Latitude: "-28.1822",
    Longitude: "28.3213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5339",
    WardLookupId: "961",
    VDNumber: "22150291",
    RegisteredPopulation: "1110",
    VotingStationName: "REHOPOTSWE INTERMEDIATE SCHOOL",
    Address: "9255 PHASE 7   BETHLEHEM  BOHLOKONG",
    Latitude: "-28.1849",
    Longitude: "28.3301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5340",
    WardLookupId: "962",
    VDNumber: "22160012",
    RegisteredPopulation: "2202",
    VotingStationName: "MORITING WA THUTO SECONDARY SCHOOL",
    Address: "058 2561778  CLARENS  CLARENS",
    Latitude: "-28.5212",
    Longitude: "28.4207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5341",
    WardLookupId: "962",
    VDNumber: "22160023",
    RegisteredPopulation: "1844",
    VotingStationName: "THUSANANG HALL",
    Address: "DIHLABENG [BETHLEHEM]  ",
    Latitude: "-28.5238",
    Longitude: "28.4257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5342",
    WardLookupId: "962",
    VDNumber: "22400503",
    RegisteredPopulation: "143",
    VotingStationName: "MOJALEFA PRIMARY SCHOOL",
    Address: "TRIPOLI FARM  KOEBERG  GOLDEN GATE",
    Latitude: "-28.5063",
    Longitude: "28.5255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5343",
    WardLookupId: "962",
    VDNumber: "22400525",
    RegisteredPopulation: "173",
    VotingStationName: "GOLDEN GATE PARK",
    Address: "GOLDEN GATE NATIONAL PARK  CLARENS  CLARENS",
    Latitude: "-28.507",
    Longitude: "28.5844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5344",
    WardLookupId: "963",
    VDNumber: "22310019",
    RegisteredPopulation: "1123",
    VotingStationName: "MAMAFUBEDU COMMUNITY HALL",
    Address: "MALINDI  MAMAFUBEDU  PETRUS STEYN",
    Latitude: "-27.6647",
    Longitude: "28.128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5345",
    WardLookupId: "963",
    VDNumber: "22310020",
    RegisteredPopulation: "1918",
    VotingStationName: "IKAHENG - ZAKHENI SECONDARY SCHOOL",
    Address: "MAMAFUBEDU  PETRUSSTEYN  NKETOANA",
    Latitude: "-27.6689",
    Longitude: "28.1268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5346",
    WardLookupId: "963",
    VDNumber: "22310064",
    RegisteredPopulation: "148",
    VotingStationName: "KOPANO ITHUTENG INTERMEDIATE INDEPENDENT SCHOOL",
    Address: "54 REITZ   PETRUS STEYN  PETRUS STEYN",
    Latitude: "-27.6488",
    Longitude: "28.1261",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5347",
    WardLookupId: "963",
    VDNumber: "22430236",
    RegisteredPopulation: "134",
    VotingStationName: "DAMPLAAS",
    Address: "VOORREG FARM  PETRUS STEYN",
    Latitude: "-27.7586",
    Longitude: "28.1612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5348",
    WardLookupId: "963",
    VDNumber: "22430247",
    RegisteredPopulation: "130",
    VotingStationName: "ZWARTFONTEIN FARM SCHOOL",
    Address: "ZWARTFONTEIN FARM  PETRUS STEYN  PETRUS STEYN",
    Latitude: "-27.756",
    Longitude: "28.0625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5349",
    WardLookupId: "963",
    VDNumber: "22430326",
    RegisteredPopulation: "102",
    VotingStationName: "SEMOMOTELA SCHOOL",
    Address: "PETRUS STEYN  NKETOANA",
    Latitude: "-27.6394",
    Longitude: "28.1949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5350",
    WardLookupId: "964",
    VDNumber: "22310031",
    RegisteredPopulation: "1366",
    VotingStationName: "MAMAFUBEDU PRIMARY SCHOOL",
    Address: "704 MAMAFUBEDU  PETRUS STEYN  PETRUS STEYN",
    Latitude: "-27.6599",
    Longitude: "28.1274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5351",
    WardLookupId: "964",
    VDNumber: "22310042",
    RegisteredPopulation: "554",
    VotingStationName: "KWETLISONG SECONDARY SCHOOL",
    Address: "59 NAUDE STREET  NKETOANA  PETRUS STEYN",
    Latitude: "-27.6474",
    Longitude: "28.133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5352",
    WardLookupId: "964",
    VDNumber: "22310053",
    RegisteredPopulation: "1810",
    VotingStationName: "TSWELOPELE EDUCARE",
    Address: "(FS193 - NKETOANA [REITZ])   ",
    Latitude: "-27.6631",
    Longitude: "28.1322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5353",
    WardLookupId: "965",
    VDNumber: "22250012",
    RegisteredPopulation: "1393",
    VotingStationName: "FATENG SATHUTO PRIMARY SCHOOL",
    Address: "830 MONYOBA   NTHA   LINDLEY",
    Latitude: "-27.8761",
    Longitude: "27.9051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5354",
    WardLookupId: "965",
    VDNumber: "22250023",
    RegisteredPopulation: "986",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "199 WEETO STREET  LINDLEY  LINDLEY",
    Latitude: "-27.8827",
    Longitude: "27.9019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5355",
    WardLookupId: "965",
    VDNumber: "22250056",
    RegisteredPopulation: "992",
    VotingStationName: "JERUSALEM CHURCH OF GOD",
    Address: "NTHA  NTHA COMMUNITY  LINDLEY",
    Latitude: "-27.889",
    Longitude: "27.8989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5356",
    WardLookupId: "965",
    VDNumber: "22430270",
    RegisteredPopulation: "89",
    VotingStationName: "BLOEMHOEK PLAAS",
    Address: "BLOEMHOEK FARM  LINDLEY  LINDLEY",
    Latitude: "-27.7876",
    Longitude: "27.959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5357",
    WardLookupId: "965",
    VDNumber: "22430371",
    RegisteredPopulation: "66",
    VotingStationName: "PHELLAPEDI FARM PRIMARY SCHOOL",
    Address: "SAAMELOOP  LINDLEY  LINDLEY",
    Latitude: "-27.8118",
    Longitude: "27.9119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5358",
    WardLookupId: "966",
    VDNumber: "22250034",
    RegisteredPopulation: "500",
    VotingStationName: "JJ GROENEWOUD",
    Address: "REITZ  NKETOANA",
    Latitude: "-27.8787",
    Longitude: "27.9238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5359",
    WardLookupId: "966",
    VDNumber: "22250045",
    RegisteredPopulation: "1337",
    VotingStationName: "THUTO TSEBO PRIMARY SCHOOL",
    Address: "1466 MAREMATLOU  NTHA  LINDLEY",
    Latitude: "-27.8851",
    Longitude: "27.9091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5360",
    WardLookupId: "966",
    VDNumber: "22250067",
    RegisteredPopulation: "1057",
    VotingStationName: "SHADOW OF HIS WINGS APOSTOLIC CENTRE",
    Address: "2463 PHOLA PARK  NTHA   LINDLEY",
    Latitude: "-27.9068",
    Longitude: "27.8892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5361",
    WardLookupId: "966",
    VDNumber: "22430012",
    RegisteredPopulation: "76",
    VotingStationName: "TENT AT PAUCI FLORA FARM",
    Address: "PAUCI FLORA  LINDLEY  LINDLEY",
    Latitude: "-27.9561",
    Longitude: "27.9787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5362",
    WardLookupId: "966",
    VDNumber: "22430225",
    RegisteredPopulation: "179",
    VotingStationName: "SKERPPUNT INTERMEDIATE FARM SCHOOL",
    Address: "SKERPPUNT FARM  LINDLEY  LINDLEY",
    Latitude: "-27.9339",
    Longitude: "28.1563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5363",
    WardLookupId: "967",
    VDNumber: "22140010",
    RegisteredPopulation: "1458",
    VotingStationName: "LERATSWANA COMMUNITY HALL",
    Address: "LERATSWANA TOWNSHIP  LERATSWANA  ARLINGTON",
    Latitude: "-28.0278",
    Longitude: "27.8442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5364",
    WardLookupId: "967",
    VDNumber: "22140021",
    RegisteredPopulation: "754",
    VotingStationName: "APOSTOLIC FAITH MISSION CHURCH",
    Address: "12 LERATSWANA  ",
    Latitude: "-28.0223",
    Longitude: "27.8414",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5365",
    WardLookupId: "967",
    VDNumber: "22140032",
    RegisteredPopulation: "93",
    VotingStationName: "ARLINGTON HALL",
    Address: "ARLINTON  ARLINGTON  ARLINGTON",
    Latitude: "-28.0275",
    Longitude: "27.8497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5366",
    WardLookupId: "967",
    VDNumber: "22400086",
    RegisteredPopulation: "38",
    VotingStationName: "SCHLESWIG HOLSTEIN",
    Address: "LINDLEY  NKETONA",
    Latitude: "-28.185",
    Longitude: "27.881",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5367",
    WardLookupId: "967",
    VDNumber: "22400110",
    RegisteredPopulation: "243",
    VotingStationName: "MISPA FARM SCHOOL 232",
    Address: "MISPA FARM  BETHLEHEM  BETHLEHEM",
    Latitude: "-28.037",
    Longitude: "28.2348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5368",
    WardLookupId: "967",
    VDNumber: "22400121",
    RegisteredPopulation: "221",
    VotingStationName: "EMDEN FARM SCHOOL",
    Address: "REITZ  ",
    Latitude: "-28.1357",
    Longitude: "28.0423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5369",
    WardLookupId: "967",
    VDNumber: "22430258",
    RegisteredPopulation: "110",
    VotingStationName: "DIMO PF SCHOOL",
    Address: "FAIRFIELD  ARLINGTON  ARLINGTON",
    Latitude: "-28.0365",
    Longitude: "27.7904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5370",
    WardLookupId: "967",
    VDNumber: "22430269",
    RegisteredPopulation: "56",
    VotingStationName: "ANGORA FARM BARN",
    Address: "ANGORA-PLAAS  ARLINGTON",
    Latitude: "-28.0542",
    Longitude: "27.9286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5371",
    WardLookupId: "968",
    VDNumber: "22330044",
    RegisteredPopulation: "1636",
    VotingStationName: "REITZ TOWN HALL",
    Address: "CNA CHURCH STREET  REITZ  REITZ",
    Latitude: "-27.7999",
    Longitude: "28.4302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5372",
    WardLookupId: "968",
    VDNumber: "22330077",
    RegisteredPopulation: "704",
    VotingStationName: "THUSANANG DAYCARE CENTRE",
    Address: "PETSANA  NKETOANA [REITZ]",
    Latitude: "-27.7951",
    Longitude: "28.4461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5373",
    WardLookupId: "968",
    VDNumber: "22330123",
    RegisteredPopulation: "705",
    VotingStationName: "RETLAPHELA DAY-CARE CENTRE",
    Address: "4746 EXT 7 MATLARANTLENG   PETSANA  REITZ",
    Latitude: "-27.7899",
    Longitude: "28.4577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5374",
    WardLookupId: "968",
    VDNumber: "22430157",
    RegisteredPopulation: "36",
    VotingStationName: "PAARDEHOEK FARM",
    Address: "FAJ MARAIS SNR  STEYNSTRAAT 12  REITZ",
    Latitude: "-27.6058",
    Longitude: "28.5141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5375",
    WardLookupId: "968",
    VDNumber: "22430304",
    RegisteredPopulation: "85",
    VotingStationName: "BRAHEM HALL",
    Address: "REITZ  NKETOANA",
    Latitude: "-27.7268",
    Longitude: "28.3579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5376",
    WardLookupId: "968",
    VDNumber: "22430315",
    RegisteredPopulation: "178",
    VotingStationName: "SUSANNASKOP FARM",
    Address: "214 SUSANNASKOP  FARM  REITZ",
    Latitude: "-27.7093",
    Longitude: "28.653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5377",
    WardLookupId: "969",
    VDNumber: "22330033",
    RegisteredPopulation: "975",
    VotingStationName: "PETSANA COMMUNITY HALL",
    Address: "PETSANA  PETSANA 150  REITZ",
    Latitude: "-27.8037",
    Longitude: "28.4398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5378",
    WardLookupId: "969",
    VDNumber: "22330088",
    RegisteredPopulation: "247",
    VotingStationName: "NKETOANA COMMUNITY SERVICES BUILDING",
    Address: "UNIFEES  REITZ  REITZ",
    Latitude: "-27.81",
    Longitude: "28.4269",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5379",
    WardLookupId: "969",
    VDNumber: "22330099",
    RegisteredPopulation: "579",
    VotingStationName: "LEIFO-IZIKO COMBINED SCHOOL",
    Address: "62 PETSANA  REITZ",
    Latitude: "-27.8096",
    Longitude: "28.445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5380",
    WardLookupId: "969",
    VDNumber: "22400187",
    RegisteredPopulation: "279",
    VotingStationName: "DANIELSRUS POSKANTOOR",
    Address: "DANIELSRUS FARM  ",
    Latitude: "-28.019",
    Longitude: "28.387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5381",
    WardLookupId: "969",
    VDNumber: "22430191",
    RegisteredPopulation: "197",
    VotingStationName: "BEYERSGIFT FARM SCHOOL",
    Address: "FARM  REITZ  NKETOANA",
    Latitude: "-27.8025",
    Longitude: "28.3862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5382",
    WardLookupId: "969",
    VDNumber: "22430203",
    RegisteredPopulation: "191",
    VotingStationName: "KLEINWATER INTERMEDIATE FARM SCHOOL",
    Address: "FARM   REITZ  REITZ",
    Latitude: "-27.9385",
    Longitude: "28.3836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5383",
    WardLookupId: "969",
    VDNumber: "22430214",
    RegisteredPopulation: "179",
    VotingStationName: "BLYDSCHAP (BULTHOEK) HALL",
    Address: "BULTHOEK  REITZ",
    Latitude: "-27.8666",
    Longitude: "28.2815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5384",
    WardLookupId: "970",
    VDNumber: "22330022",
    RegisteredPopulation: "2023",
    VotingStationName: "PHINDUZAME INTERMEDIATE SCHOOL",
    Address: "1596 SCHOOL STREET  REITZ  REITZ",
    Latitude: "-27.8018",
    Longitude: "28.4493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5385",
    WardLookupId: "970",
    VDNumber: "22330066",
    RegisteredPopulation: "627",
    VotingStationName: "MAXIMA BIBLE CHURCH",
    Address: "MZEET STREET  PETSANA  REITZ",
    Latitude: "-27.7985",
    Longitude: "28.4476",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5386",
    WardLookupId: "971",
    VDNumber: "22330011",
    RegisteredPopulation: "1929",
    VotingStationName: "EAGLES OF LIVING",
    Address: "3640 PETSANA  NKETOANA [REITZ]",
    Latitude: "-27.7986",
    Longitude: "28.4568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5387",
    WardLookupId: "971",
    VDNumber: "22330055",
    RegisteredPopulation: "1124",
    VotingStationName: "REATILE INTERMEDIATE SCHOOL",
    Address: "2370 PETSANA TOWNSHIP  PETSANA  NKETOANA [REITZ]",
    Latitude: "-27.8071",
    Longitude: "28.452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5388",
    WardLookupId: "971",
    VDNumber: "22330112",
    RegisteredPopulation: "717",
    VotingStationName: "KGOTSO UXOLO SECONDARY SCHOOL",
    Address: "PETSANA  REITZ",
    Latitude: "-27.8012",
    Longitude: "28.4604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5389",
    WardLookupId: "971",
    VDNumber: "22430124",
    RegisteredPopulation: "191",
    VotingStationName: "EMAMMOHO PRIMARY SCHOOL",
    Address: "EMDEN FARM  REITZ  REITZ",
    Latitude: "-27.9086",
    Longitude: "28.6326",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5390",
    WardLookupId: "971",
    VDNumber: "22430135",
    RegisteredPopulation: "214",
    VotingStationName: "SEPHESHA PRIMARY FARM SCHOOL",
    Address: "VANROOYENSWON  PETSANA  REITZ",
    Latitude: "-27.8965",
    Longitude: "28.5383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5391",
    WardLookupId: "971",
    VDNumber: "22430146",
    RegisteredPopulation: "89",
    VotingStationName: "SILO FARM",
    Address: "SILO  REITZ  REITZ",
    Latitude: "-27.8139",
    Longitude: "28.526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5392",
    WardLookupId: "972",
    VDNumber: "22210085",
    RegisteredPopulation: "1853",
    VotingStationName: "SEDIBA HALL",
    Address: "SEDIBA  TSHIAME B  HARRISMITH",
    Latitude: "-28.3192",
    Longitude: "28.9932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5393",
    WardLookupId: "972",
    VDNumber: "22210096",
    RegisteredPopulation: "1707",
    VotingStationName: "TSEBONG OLWAZINI PRIMARY SCHOOL",
    Address: "KHALANYONI  HARRISMITH  TSHIAME C",
    Latitude: "-28.326",
    Longitude: "28.993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5394",
    WardLookupId: "972",
    VDNumber: "22210197",
    RegisteredPopulation: "496",
    VotingStationName: "REAHOLA CRECHE",
    Address: "KHALANYONI   HARRISMITH  TSHIAME",
    Latitude: "-28.3312",
    Longitude: "28.9862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5395",
    WardLookupId: "972",
    VDNumber: "22400244",
    RegisteredPopulation: "297",
    VotingStationName: "THOLI THEMBA CRECHE",
    Address: "SMALDEEL FARM  HARRISMITH",
    Latitude: "-28.129",
    Longitude: "29.0366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5396",
    WardLookupId: "972",
    VDNumber: "22400570",
    RegisteredPopulation: "1359",
    VotingStationName: "KGOSATSANA - MAPULE CRECHE",
    Address: "MAKGOLOKWENG VILLAGE  KGUTLONG SA MAKGOLOKOENG  HARRISMITH",
    Latitude: "-28.3069",
    Longitude: "28.9717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5397",
    WardLookupId: "972",
    VDNumber: "22400637",
    RegisteredPopulation: "242",
    VotingStationName: "EERAM INTERMEDIATE FARM SCHOOL",
    Address: "111 N3 JOBURG & DURBAN  EERAM FARM  HARRISMITH",
    Latitude: "-28.1013",
    Longitude: "29.0382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5398",
    WardLookupId: "972",
    VDNumber: "22400659",
    RegisteredPopulation: "390",
    VotingStationName: "BONUKUHLE EDUCARE CENTRE",
    Address: "MAKGOLOKWENG  HARRISMITH",
    Latitude: "-28.2889",
    Longitude: "28.9856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5399",
    WardLookupId: "973",
    VDNumber: "22320098",
    RegisteredPopulation: "1041",
    VotingStationName: "UNIVERSITY OF FREE STATE",
    Address: "MAMPOI ROAD  PHUTHADITJHABA  PHUTHADITJHABA",
    Latitude: "-28.4685",
    Longitude: "28.8231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5400",
    WardLookupId: "973",
    VDNumber: "22320155",
    RegisteredPopulation: "1074",
    VotingStationName: "CLUBVIEW INTERMEDIATE SCHOOL",
    Address: "01 CLUBVIEW STRE  CLUBVIEW  THABO MOFUTSANYANA",
    Latitude: "-28.4987",
    Longitude: "28.8377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5401",
    WardLookupId: "973",
    VDNumber: "22420011",
    RegisteredPopulation: "1108",
    VotingStationName: "MATSIKENG PRIMARY SCHOOL",
    Address: "MATSIKENG  MALUTI A PHOFUNG",
    Latitude: "-28.5022",
    Longitude: "28.8482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5402",
    WardLookupId: "973",
    VDNumber: "22420123",
    RegisteredPopulation: "1611",
    VotingStationName: "MPHATLALATSANE INTERMEDIATE SCHOOL",
    Address: "15380 WETSIESHOEK  MPHATLALATSANE  QWAQWA",
    Latitude: "-28.5074",
    Longitude: "28.8364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5403",
    WardLookupId: "973",
    VDNumber: "22421472",
    RegisteredPopulation: "445",
    VotingStationName: "BAPHUTHING TUCK SHOP",
    Address: "MATSIKENG VILLAGE  QWAQWA  WITSIESHOEK",
    Latitude: "-28.5042",
    Longitude: "28.8538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5404",
    WardLookupId: "973",
    VDNumber: "22421483",
    RegisteredPopulation: "517",
    VotingStationName: "THE FACE OF JESUS CHRIST INTERATIONAL MINISTRIES",
    Address: "MPHATLALATSANE VILLAGE  WITSIESHOEK  MATSIKENG",
    Latitude: "-28.5089",
    Longitude: "28.8391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5405",
    WardLookupId: "974",
    VDNumber: "22230010",
    RegisteredPopulation: "1198",
    VotingStationName: "NKARABENG SECONDARY SCHOOL",
    Address: "TLHOLONG  KESTELL  KESTELL",
    Latitude: "-28.332",
    Longitude: "28.6993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5406",
    WardLookupId: "974",
    VDNumber: "22230021",
    RegisteredPopulation: "973",
    VotingStationName: "KESTELL CITY HALL",
    Address: "39 BOTHA STR  MALUTI A PHOFUNG  KESTELL",
    Latitude: "-28.3139",
    Longitude: "28.7038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5407",
    WardLookupId: "974",
    VDNumber: "22230032",
    RegisteredPopulation: "534",
    VotingStationName: "TLHOLONG DAY CARE",
    Address: "BOIPATONG SECTION  TLHOLONG LOCATION  KESTELL",
    Latitude: "-28.3325",
    Longitude: "28.695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5408",
    WardLookupId: "974",
    VDNumber: "22230043",
    RegisteredPopulation: "1098",
    VotingStationName: "KHULISA CRECHE",
    Address: "EXTENSION 5  DIPELANENG  KESTELL",
    Latitude: "-28.3301",
    Longitude: "28.6913",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5409",
    WardLookupId: "974",
    VDNumber: "22230054",
    RegisteredPopulation: "859",
    VotingStationName: "HOLY DESTINATION CHURCH",
    Address: "PASTENG  DIPELANENG  KESTELL",
    Latitude: "-28.3275",
    Longitude: "28.6986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5410",
    WardLookupId: "974",
    VDNumber: "22400075",
    RegisteredPopulation: "79",
    VotingStationName: "TENT AT GEDULD FARM",
    Address: "KESTELL HARRISMITH ROAD N5  GEDULD FARM  KESTELL",
    Latitude: "-28.2904",
    Longitude: "28.811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5411",
    WardLookupId: "974",
    VDNumber: "22400367",
    RegisteredPopulation: "173",
    VotingStationName: "ABERFELDY BOEREVEREENIGING",
    Address: "58 ABERFELDY KO-OP  ABERFELDY  HARRISMITH",
    Latitude: "-28.216",
    Longitude: "28.834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5412",
    WardLookupId: "974",
    VDNumber: "22400491",
    RegisteredPopulation: "103",
    VotingStationName: "ABERFELDY PRIMARY FARM SCHOOL",
    Address: "DEVARON FARM  ABERFELDY  KESTELL",
    Latitude: "-28.1901",
    Longitude: "28.7813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5413",
    WardLookupId: "974",
    VDNumber: "22400536",
    RegisteredPopulation: "79",
    VotingStationName: "TENT AT EERSTEGELUK FARM",
    Address: "EERSTEGELUK FARM  SAN PARKS EERSTEGELUK PARK  KESTELL",
    Latitude: "-28.4282",
    Longitude: "28.6941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5414",
    WardLookupId: "974",
    VDNumber: "22400569",
    RegisteredPopulation: "60",
    VotingStationName: "TENT AT HA-TSHOHANYANE",
    Address: "HA-TSHOHANYANE VILLAGE  KESTELL  MABOLELA",
    Latitude: "-28.4749",
    Longitude: "28.7232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5415",
    WardLookupId: "974",
    VDNumber: "22400626",
    RegisteredPopulation: "194",
    VotingStationName: "MAJOENG FARM SCHOOL",
    Address: "MAJOENG  KESTELL",
    Latitude: "-28.3108",
    Longitude: "28.8849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5416",
    WardLookupId: "975",
    VDNumber: "22210063",
    RegisteredPopulation: "2001",
    VotingStationName: "INTABAZWE INTERMEDIATE SCHOOL",
    Address: "C/O NDABA-NGUBENI STR  HARRISMITH  INTABAZWE",
    Latitude: "-28.2485",
    Longitude: "29.1059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5417",
    WardLookupId: "975",
    VDNumber: "22210074",
    RegisteredPopulation: "1458",
    VotingStationName: "TENT AT SELAHLIWE NEXT TO AIRPORT",
    Address: "SELAHLIWE  INTABAZWE  HARRISMITH",
    Latitude: "-28.2443",
    Longitude: "29.1095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5418",
    WardLookupId: "975",
    VDNumber: "22210142",
    RegisteredPopulation: "174",
    VotingStationName: "TENT AT LOTUS - VILLE",
    Address: "LOTUS VILLE  MALUTI A PHOFUNG  HARRISMITH",
    Latitude: "-28.2509",
    Longitude: "29.118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5419",
    WardLookupId: "975",
    VDNumber: "22210175",
    RegisteredPopulation: "672",
    VotingStationName: "INHLAKANIPHO INTERMEDIATE SCHOOL",
    Address: "111 VILAKAZI  HARRIESMITH  INTABAZWE",
    Latitude: "-28.2475",
    Longitude: "29.1103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5420",
    WardLookupId: "975",
    VDNumber: "22210186",
    RegisteredPopulation: "490",
    VotingStationName: "VULINDLELA PRIMARY SCHOOL",
    Address: "405 TWALA STREET  INTABAZWE  HARRISMITH",
    Latitude: "-28.2529",
    Longitude: "29.1056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5421",
    WardLookupId: "975",
    VDNumber: "22210221",
    RegisteredPopulation: "588",
    VotingStationName: "NKOSINATHI EDUCARE",
    Address: "INTABAZWE  HARRISMITH  HARRISMITH",
    Latitude: "-28.2458",
    Longitude: "29.1134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5422",
    WardLookupId: "975",
    VDNumber: "22210232",
    RegisteredPopulation: "177",
    VotingStationName: "TENT AT MOYENI FARM",
    Address: "MOYENI FARM  MOYENI   HARRISMITH",
    Latitude: "-28.2327",
    Longitude: "29.1202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5423",
    WardLookupId: "976",
    VDNumber: "22210030",
    RegisteredPopulation: "1047",
    VotingStationName: "METHODIST CHURCH",
    Address: "C/O MOLOI-NYEMBEZI STR  INTABAZWE  HARRISMITH",
    Latitude: "-28.2496",
    Longitude: "29.0954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5424",
    WardLookupId: "976",
    VDNumber: "22210041",
    RegisteredPopulation: "1046",
    VotingStationName: "ITLHAHANELENG PRIMARY SCHOOL",
    Address: "ZWANE STR  HARRISMITH  INTABAZWE",
    Latitude: "-28.2458",
    Longitude: "29.1031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5425",
    WardLookupId: "976",
    VDNumber: "22210120",
    RegisteredPopulation: "871",
    VotingStationName: "LERATO UTHANDO COMPREHENSIVE SCHOOL",
    Address: "INTABAZWE  HARRISMITH",
    Latitude: "-28.2452",
    Longitude: "29.094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5426",
    WardLookupId: "976",
    VDNumber: "22210131",
    RegisteredPopulation: "1231",
    VotingStationName: "TENT AT INTABAZWE",
    Address: "MOHLAKANENG LOCATION  INTABAZWE  HARRISMITH",
    Latitude: "-28.2376",
    Longitude: "29.0957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5427",
    WardLookupId: "976",
    VDNumber: "22210164",
    RegisteredPopulation: "676",
    VotingStationName: "MPHO CRECHE",
    Address: "MOHLAKENG   HARRISMITH  HARRISMITH",
    Latitude: "-28.2355",
    Longitude: "29.0968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5428",
    WardLookupId: "976",
    VDNumber: "22210243",
    RegisteredPopulation: "529",
    VotingStationName: "THEMBELENI DAY CARE",
    Address: "PHOLANI EXTENTION   HARRISMITH  HARRISMITH",
    Latitude: "-28.2363",
    Longitude: "29.0935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5429",
    WardLookupId: "976",
    VDNumber: "22210254",
    RegisteredPopulation: "366",
    VotingStationName: "ENDUMISWENI CHURCH",
    Address: "INTABAZWE  HARRISMITH",
    Latitude: "-28.2438",
    Longitude: "29.1006",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5430",
    WardLookupId: "977",
    VDNumber: "22210018",
    RegisteredPopulation: "664",
    VotingStationName: "COMMANDO",
    Address: "BERGSIG  HARRISMITH",
    Latitude: "-28.2668",
    Longitude: "29.1351",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5431",
    WardLookupId: "977",
    VDNumber: "22210029",
    RegisteredPopulation: "2205",
    VotingStationName: "HARRISMITH TOWN HALL",
    Address: "HARRISMITH  MALUTI A PHOFUNG  HARRISMITH",
    Latitude: "-28.2701",
    Longitude: "29.1246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5432",
    WardLookupId: "977",
    VDNumber: "22210119",
    RegisteredPopulation: "1109",
    VotingStationName: "NEXUS PRIMARY SCHOOL",
    Address: "2581C TSHIAME  TSHIAME C  HARRISMITH",
    Latitude: "-28.3352",
    Longitude: "28.9837",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5433",
    WardLookupId: "977",
    VDNumber: "22210153",
    RegisteredPopulation: "663",
    VotingStationName: "KGETHATSEBO-KHETHULWAZI SECONDARY SCHOOL",
    Address: "111 KHALANYONI  HARRISMITH  TSHIAME B",
    Latitude: "-28.3329",
    Longitude: "28.9914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5434",
    WardLookupId: "977",
    VDNumber: "22210209",
    RegisteredPopulation: "51",
    VotingStationName: "TENT  NEXT TO STILLERUST OLD FARM SCHOOL",
    Address: "STILLERUST FARM  HARRISMITH  HARRISMITH",
    Latitude: "-28.3616",
    Longitude: "29.0478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5435",
    WardLookupId: "977",
    VDNumber: "22210265",
    RegisteredPopulation: "611",
    VotingStationName: "TENT AT SKOOMPLAAS",
    Address: "TSHIAME  HARRISMITH  HARRISMITH",
    Latitude: "-28.2909",
    Longitude: "29.1253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5436",
    WardLookupId: "977",
    VDNumber: "22400323",
    RegisteredPopulation: "359",
    VotingStationName: "MAGUBANE CRECHE",
    Address: "MAGUBANE  LANGSPRUIT  HARRISMITH",
    Latitude: "-28.2273",
    Longitude: "29.4048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5437",
    WardLookupId: "977",
    VDNumber: "22400334",
    RegisteredPopulation: "202",
    VotingStationName: "MPHOPHOMO COMBINED SCHOOL",
    Address: "MPHOPHOMO  HARRISMITH  WATERVAL",
    Latitude: "-28.2951",
    Longitude: "29.3903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5438",
    WardLookupId: "977",
    VDNumber: "22400356",
    RegisteredPopulation: "122",
    VotingStationName: "TENT AT ARENDSKOP BOERDERY MOUNTAINVIEW PREFERT",
    Address: "VERKYKERSKOP FARM  MAALUTI A PHOFUNG  HARRISMITH",
    Latitude: "-28.1423",
    Longitude: "29.1759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5439",
    WardLookupId: "977",
    VDNumber: "22400378",
    RegisteredPopulation: "434",
    VotingStationName: "TENT AT SWINBURNE FARM",
    Address: "HARRISMITH  SWINBURNE FARM",
    Latitude: "-28.351",
    Longitude: "29.2783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5440",
    WardLookupId: "977",
    VDNumber: "22400389",
    RegisteredPopulation: "126",
    VotingStationName: "TENT NEXT TO SUMMERSLIE OLD FARM SCHOOL",
    Address: "SUMMERSLIE FARM  HARRISMITH  HARRSIMITH",
    Latitude: "-28.4511",
    Longitude: "29.1074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5441",
    WardLookupId: "977",
    VDNumber: "22400615",
    RegisteredPopulation: "75",
    VotingStationName: "BEULAH FARM HOUSE",
    Address: "BEULAH FARM  MALUTI A PHOFUNG   PHUTHADITJHABA",
    Latitude: "-28.4085",
    Longitude: "28.9222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5442",
    WardLookupId: "977",
    VDNumber: "22400648",
    RegisteredPopulation: "685",
    VotingStationName: "TENT AT MATODING",
    Address: "TSHIAME -  C VILLAGE  TSHIAME  HARRISMITH",
    Latitude: "-28.3354",
    Longitude: "28.98",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5443",
    WardLookupId: "978",
    VDNumber: "22320122",
    RegisteredPopulation: "645",
    VotingStationName: "MOTHEO WA THUTO PREPARATORY AND COLLEGE",
    Address: "PHUTHADITJHABA  DE BULT   DE BULT",
    Latitude: "-28.5379",
    Longitude: "28.7975",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5444",
    WardLookupId: "978",
    VDNumber: "22421089",
    RegisteredPopulation: "1358",
    VotingStationName: "MAJARA INTERMEDIATE SCHOOL",
    Address: "13492 MAJARA SCHOOL  MAKGALWANENG  QWAQWA",
    Latitude: "-28.5521",
    Longitude: "28.7913",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5445",
    WardLookupId: "978",
    VDNumber: "22421113",
    RegisteredPopulation: "1819",
    VotingStationName: "TABOLA PRIMARY SCHOOL",
    Address: "13121 TABOLA  DITHOTANENG  QWAQWA",
    Latitude: "-28.5606",
    Longitude: "28.7829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5446",
    WardLookupId: "978",
    VDNumber: "22421494",
    RegisteredPopulation: "364",
    VotingStationName: "OLD APOSTOLIC FAITH MISSION",
    Address: "MAKHALOANENG VILLAGE  MANGANENG  WITSIESHOEK",
    Latitude: "-28.5577",
    Longitude: "28.7924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5447",
    WardLookupId: "978",
    VDNumber: "22421506",
    RegisteredPopulation: "606",
    VotingStationName: "KOALI SECONDARY SCHOOL",
    Address: "MAKHALOANENG VILLAGE  PHUTHADITJHABA  NAMAHALI",
    Latitude: "-28.5445",
    Longitude: "28.7965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5448",
    WardLookupId: "979",
    VDNumber: "22420954",
    RegisteredPopulation: "1178",
    VotingStationName: "LEBOHANG PRIMARY SCHOOL",
    Address: "00 DITHOTANENG ROUTE  DITHOTANENG (MATEBELENG)  BOLATA",
    Latitude: "-28.5465",
    Longitude: "28.779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5449",
    WardLookupId: "979",
    VDNumber: "22421135",
    RegisteredPopulation: "877",
    VotingStationName: "MAKHALOANENG PRIMARY SCHOOL",
    Address: "WITSIESHOEK  MAKHALOANENG VILLAGE  THABO MOFUTSANYANA",
    Latitude: "-28.5413",
    Longitude: "28.7885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5450",
    WardLookupId: "979",
    VDNumber: "22421281",
    RegisteredPopulation: "824",
    VotingStationName: "LEKGULO SECONDARY SCHOOL",
    Address: "WITSIESHOEK  DITHOTANENG VILLAGE  TSEKI",
    Latitude: "-28.5529",
    Longitude: "28.7782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5451",
    WardLookupId: "979",
    VDNumber: "22421416",
    RegisteredPopulation: "261",
    VotingStationName: "TENT AT MABOPANE",
    Address: "WITSIESHOEK  MATEBELENG  LERIBE",
    Latitude: "-28.5501",
    Longitude: "28.7702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5452",
    WardLookupId: "979",
    VDNumber: "22421517",
    RegisteredPopulation: "396",
    VotingStationName: "TSHWARANANG CRECHE",
    Address: "MAKGALANENG VILLAGE  QWAQWA  WITSIESHOEK",
    Latitude: "-28.5416",
    Longitude: "28.7845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5453",
    WardLookupId: "979",
    VDNumber: "22421528",
    RegisteredPopulation: "417",
    VotingStationName: "TEMPLE OF GOD REVIVAL",
    Address: "MATEBELENG  WITSIESHOEK  WITSIEHOEK",
    Latitude: "-28.5386",
    Longitude: "28.7821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5454",
    WardLookupId: "979",
    VDNumber: "22421539",
    RegisteredPopulation: "245",
    VotingStationName: "TIISETSANG CRECHE",
    Address: "LERIBE VILLAGE  QWAQWA  WITSIESHOEK",
    Latitude: "-28.5461",
    Longitude: "28.7721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5455",
    WardLookupId: "980",
    VDNumber: "22420156",
    RegisteredPopulation: "650",
    VotingStationName: "KGOTSONG PRIMARY SCHOOL",
    Address: "KUDUMANE VILLAGE  WITSIESHOEK  BAKWENA",
    Latitude: "-28.5362",
    Longitude: "28.7704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5456",
    WardLookupId: "980",
    VDNumber: "22420437",
    RegisteredPopulation: "1419",
    VotingStationName: "PABALLONG PRIMARY SCHOOL",
    Address: "13812 PABALLONG PRIMARY  PABALLONG  QWAQWA",
    Latitude: "-28.5525",
    Longitude: "28.7654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5457",
    WardLookupId: "980",
    VDNumber: "22420897",
    RegisteredPopulation: "887",
    VotingStationName: "TENT NEXT TO SEDI LA THUTO CRECHE",
    Address: "PABALLONG   WITSIESHOEK  BAKGATLA",
    Latitude: "-28.5663",
    Longitude: "28.7673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5458",
    WardLookupId: "980",
    VDNumber: "22420909",
    RegisteredPopulation: "886",
    VotingStationName: "NAKA PRIMARY SCHOOL",
    Address: "NAKA  LERIBE  QWAQWA",
    Latitude: "-28.5395",
    Longitude: "28.7737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5459",
    WardLookupId: "980",
    VDNumber: "22421540",
    RegisteredPopulation: "375",
    VotingStationName: "TENT NEXT TO OLD HA JOHN",
    Address: "LERIBE  WITSIESHOEK  WITSIESHOEK",
    Latitude: "-28.5578",
    Longitude: "28.774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5460",
    WardLookupId: "980",
    VDNumber: "22421551",
    RegisteredPopulation: "344",
    VotingStationName: "LENICE DAY CARE",
    Address: "PABALLONG  WITSIESHOEK  WITSIESHOEK",
    Latitude: "-28.563",
    Longitude: "28.7693",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5461",
    WardLookupId: "981",
    VDNumber: "22420853",
    RegisteredPopulation: "1030",
    VotingStationName: "LIBE PRIMARY SCHOOL",
    Address: "331 LIBE  TSEKI  QWAQWA",
    Latitude: "-28.5715",
    Longitude: "28.7773",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5462",
    WardLookupId: "981",
    VDNumber: "22420921",
    RegisteredPopulation: "988",
    VotingStationName: "MAFIKADITSHIU PRIMARY SCHOOL",
    Address: "MALUTI A PHOFUNG  SEKGUTLONG (TSEKI)  QWAQWA",
    Latitude: "-28.5883",
    Longitude: "28.7705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5463",
    WardLookupId: "981",
    VDNumber: "22421157",
    RegisteredPopulation: "2121",
    VotingStationName: "ITLOTLISENG PRIMARY SCHOOL",
    Address: "WITSIESHOEK  DUMELANG SHOPPING CENTRE  TSEKI",
    Latitude: "-28.5786",
    Longitude: "28.7725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5464",
    WardLookupId: "981",
    VDNumber: "22421562",
    RegisteredPopulation: "442",
    VotingStationName: "TENT AT MPHATLALATSANE",
    Address: "WITSIESHOEK  MPHATLALATSANE VILLAGE  TSEKI",
    Latitude: "-28.5828",
    Longitude: "28.7758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5465",
    WardLookupId: "981",
    VDNumber: "22421573",
    RegisteredPopulation: "490",
    VotingStationName: "MMAPHEPO DAY CARE",
    Address: "SEKGUTLONG  TSEKI  WITSIESHOEK",
    Latitude: "-28.5873",
    Longitude: "28.7666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5466",
    WardLookupId: "982",
    VDNumber: "22420022",
    RegisteredPopulation: "734",
    VotingStationName: "MONONTSHA INTERMEDIATE SCHOOL",
    Address: "WITSIESHOEK  MONONTSHA VILLAGE  MANTSUBISE",
    Latitude: "-28.5665",
    Longitude: "28.7445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5467",
    WardLookupId: "982",
    VDNumber: "22420055",
    RegisteredPopulation: "266",
    VotingStationName: "TENT NEXT TO MALESAOANA CLINIC",
    Address: "WITSIESHOEK  PABALLONG VILLAGE  TSEKI",
    Latitude: "-28.5601",
    Longitude: "28.7215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5468",
    WardLookupId: "982",
    VDNumber: "22420718",
    RegisteredPopulation: "459",
    VotingStationName: "MAKGABANE SECONDARY SCHOOL",
    Address: "TSESENG VILLAGE  QWAQWA  SEHLAJANENG",
    Latitude: "-28.5675",
    Longitude: "28.7231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5469",
    WardLookupId: "982",
    VDNumber: "22421056",
    RegisteredPopulation: "1019",
    VotingStationName: "THEBE YA KGOMO PRIMARY SCHOOL",
    Address: "MONONTSHA VILLAGE,WITSIESHOEK  MONONTSHA  QWAQWA",
    Latitude: "-28.5683",
    Longitude: "28.7373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5470",
    WardLookupId: "982",
    VDNumber: "22421191",
    RegisteredPopulation: "345",
    VotingStationName: "HLATSENG INTERMEDIATE SCHOOL",
    Address: "WITSIESHOEK  HLATSENG VILLAGE  TSEKI",
    Latitude: "-28.5522",
    Longitude: "28.7174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5471",
    WardLookupId: "982",
    VDNumber: "22421214",
    RegisteredPopulation: "1135",
    VotingStationName: "MOHATO SECONDARY SCHOOL",
    Address: "WITSIESHOEK  NAMOHA  MONONTSHA",
    Latitude: "-28.5617",
    Longitude: "28.7585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5472",
    WardLookupId: "982",
    VDNumber: "22421247",
    RegisteredPopulation: "142",
    VotingStationName: "TSEBELA PRIMARY SCHOOL",
    Address: "MAKHABANE VILLAGE  WITSIESHOEK  SEHLAJANENG",
    Latitude: "-28.5716",
    Longitude: "28.7088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5473",
    WardLookupId: "982",
    VDNumber: "22421584",
    RegisteredPopulation: "380",
    VotingStationName: "BATAUNG GENERAL DEALER",
    Address: "SEHLAJANENG VILLAGE  QWAQWA  MONONTSHA",
    Latitude: "-28.5603",
    Longitude: "28.7302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5474",
    WardLookupId: "982",
    VDNumber: "22421595",
    RegisteredPopulation: "347",
    VotingStationName: "NAMOHA PRIMARY SCHOOL",
    Address: "MONONTSHA VILLAGE  QWAQWA  MONONTSHA",
    Latitude: "-28.569",
    Longitude: "28.7581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5475",
    WardLookupId: "983",
    VDNumber: "22420583",
    RegisteredPopulation: "1167",
    VotingStationName: "NEO E-DU-CARE CENTRE",
    Address: "729 NALEDI VILLAGE  NALEDI  WITSIESHOEK",
    Latitude: "-28.5855",
    Longitude: "28.807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5476",
    WardLookupId: "983",
    VDNumber: "22420808",
    RegisteredPopulation: "780",
    VotingStationName: "LEPANYA PRIMARY SCHOOL",
    Address: "LEPANYA ROUTE  SEMPHURWANENG  TSEKI BOLATA",
    Latitude: "-28.5845",
    Longitude: "28.7942",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5477",
    WardLookupId: "983",
    VDNumber: "22420910",
    RegisteredPopulation: "750",
    VotingStationName: "MAKHALANYANE INTERMEDIATE SCHOOL",
    Address: "HANCHABENG VILLAGE  WITSIESHOEK  BAKWENA",
    Latitude: "-28.5779",
    Longitude: "28.7962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5478",
    WardLookupId: "983",
    VDNumber: "22420943",
    RegisteredPopulation: "687",
    VotingStationName: "PHAHAMENG INTERMEDIATE SCHOOL",
    Address: "PHAHAMENG  MASALENG  QWAQWA",
    Latitude: "-28.5938",
    Longitude: "28.7826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5479",
    WardLookupId: "983",
    VDNumber: "22421427",
    RegisteredPopulation: "524",
    VotingStationName: "JABULA STORE",
    Address: "PHOMOLONG VILLAGE  TSEKI  HA NCHABENG",
    Latitude: "-28.5884",
    Longitude: "28.7847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5480",
    WardLookupId: "983",
    VDNumber: "22421438",
    RegisteredPopulation: "267",
    VotingStationName: "MATSIENG PRIMARY SCHOOL",
    Address: "111 E- ROSS ROUTE  MAOKONG VILLAGE  NAMAHALI",
    Latitude: "-28.5895",
    Longitude: "28.8065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5481",
    WardLookupId: "983",
    VDNumber: "22421607",
    RegisteredPopulation: "517",
    VotingStationName: "MATLEPE GENERAL DEALER",
    Address: "NALEDI ROUTE  NAMAHALI  MAKONG",
    Latitude: "-28.5857",
    Longitude: "28.8016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5482",
    WardLookupId: "983",
    VDNumber: "22421629",
    RegisteredPopulation: "532",
    VotingStationName: "BATAUNG SHOP",
    Address: "MASALENG VILLAGE  QWAQWA  QWAQWA",
    Latitude: "-28.5952",
    Longitude: "28.7763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5483",
    WardLookupId: "983",
    VDNumber: "22421719",
    RegisteredPopulation: "230",
    VotingStationName: "TSHABALALA GARAGE",
    Address: "NALEDI VILLAGE  NALEDI  QWAQWA",
    Latitude: "-28.5935",
    Longitude: "28.8067",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5484",
    WardLookupId: "984",
    VDNumber: "22420819",
    RegisteredPopulation: "743",
    VotingStationName: "KGOPTJANE PRIMARY SCHOOL",
    Address: "BOLATA  TSEKI  KGOPTJANE",
    Latitude: "-28.576",
    Longitude: "28.8021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5485",
    WardLookupId: "984",
    VDNumber: "22420820",
    RegisteredPopulation: "927",
    VotingStationName: "SELEMELA PRIMARY SCHOOL",
    Address: "SELEMELA  ",
    Latitude: "-28.5639",
    Longitude: "28.794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5486",
    WardLookupId: "984",
    VDNumber: "22420831",
    RegisteredPopulation: "1028",
    VotingStationName: "THAHAMESO SECONDARY SCHOOL",
    Address: "849 THAHAMESO  BOLATA  QWAQWA",
    Latitude: "-28.5747",
    Longitude: "28.7875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5487",
    WardLookupId: "984",
    VDNumber: "22420842",
    RegisteredPopulation: "867",
    VotingStationName: "MOSIUOA LEKOTA SENIOR SECONDARY",
    Address: "BOLATA  TSEKI BOLATA  TSEKI",
    Latitude: "-28.5676",
    Longitude: "28.7937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5488",
    WardLookupId: "984",
    VDNumber: "22421630",
    RegisteredPopulation: "549",
    VotingStationName: "BETHEL CAFE",
    Address: "BOLATA ROUTE  BOLATA  TSEKI BOLATA",
    Latitude: "-28.5694",
    Longitude: "28.798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5489",
    WardLookupId: "984",
    VDNumber: "22421641",
    RegisteredPopulation: "426",
    VotingStationName: "CHURCH OF THE  LORD JESUS CHRIST",
    Address: "MACHABAKUNG VILLAGE  BOLATA  QWAQWA",
    Latitude: "-28.5627",
    Longitude: "28.8046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5490",
    WardLookupId: "984",
    VDNumber: "22421652",
    RegisteredPopulation: "89",
    VotingStationName: "TSEKI SENIOR SECONDARY SCHOOL",
    Address: "TSEKI  WITSIESHOEK  TSEKI",
    Latitude: "-28.5725",
    Longitude: "28.7782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5491",
    WardLookupId: "985",
    VDNumber: "22320201",
    RegisteredPopulation: "704",
    VotingStationName: "TENT AT BOIKETLO",
    Address: "BOIKETLO VILLAGE  NAMAHALI  WITSIESHOEK",
    Latitude: "-28.548",
    Longitude: "28.8194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5492",
    WardLookupId: "985",
    VDNumber: "22420314",
    RegisteredPopulation: "673",
    VotingStationName: "DIKWENA SENIOR SECONDARY SCHOOL",
    Address: "NAMAHADI  QWAQWA",
    Latitude: "-28.572",
    Longitude: "28.8311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5493",
    WardLookupId: "985",
    VDNumber: "22420774",
    RegisteredPopulation: "1735",
    VotingStationName: "MADIBOHO INTERMEDIATE SCHOOL",
    Address: "PHAMONG  PHUTHADITJHABA5",
    Latitude: "-28.5604",
    Longitude: "28.8107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5494",
    WardLookupId: "985",
    VDNumber: "22421269",
    RegisteredPopulation: "846",
    VotingStationName: "ITEMOHELENG EDUCARE CENTRE",
    Address: "THEOSANE VILLAGE  WITSIESHOEK  NAMAHALI",
    Latitude: "-28.5722",
    Longitude: "28.8071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5495",
    WardLookupId: "985",
    VDNumber: "22421663",
    RegisteredPopulation: "299",
    VotingStationName: "TENT AT NAMAHALI",
    Address: "NAMAHALI VILLAGE  NAMAHALI  WITSIESHOEK",
    Latitude: "-28.5662",
    Longitude: "28.8268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5496",
    WardLookupId: "985",
    VDNumber: "22421674",
    RegisteredPopulation: "370",
    VotingStationName: "TENT AT THEOSANE",
    Address: "PHAMONG VILLAGE  PHUTHADITJHABA  NAMAHALI",
    Latitude: "-28.5547",
    Longitude: "28.8153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5497",
    WardLookupId: "986",
    VDNumber: "22420066",
    RegisteredPopulation: "399",
    VotingStationName: "TENT AT HA-PEETE",
    Address: "THELLABOY  QWAQWA  SEKHUTLONG",
    Latitude: "-28.6216",
    Longitude: "28.7922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5498",
    WardLookupId: "986",
    VDNumber: "22420235",
    RegisteredPopulation: "696",
    VotingStationName: "TSHWARA-THEBE INTERMEDIATE SCHOOL",
    Address: "TSHIRELLA VILLAGE  WITSIESHOEK  MAKENENG",
    Latitude: "-28.6318",
    Longitude: "28.8003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5499",
    WardLookupId: "986",
    VDNumber: "22421045",
    RegisteredPopulation: "1063",
    VotingStationName: "TSHOLO SECONDARY SCHOOL",
    Address: "TSHOLO SECONDARY SCHOOL  MOKODUMELA  QWAQWA",
    Latitude: "-28.6223",
    Longitude: "28.8031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5500",
    WardLookupId: "986",
    VDNumber: "22421203",
    RegisteredPopulation: "842",
    VotingStationName: "SEKGOTHADI PRIMARY SCHOOL",
    Address: "THABANATSOANA VILLAGE  WITSIESHOEK  SEKHUTLONG",
    Latitude: "-28.625",
    Longitude: "28.8119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5501",
    WardLookupId: "986",
    VDNumber: "22421225",
    RegisteredPopulation: "937",
    VotingStationName: "SELEBALO PRIMARY SCHOOL",
    Address: "SELEBALO  THABONG  SEKHUTLONG",
    Latitude: "-28.6128",
    Longitude: "28.8145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5502",
    WardLookupId: "986",
    VDNumber: "22421696",
    RegisteredPopulation: "698",
    VotingStationName: "PICK YOUR CHOICE SUPERMARKET",
    Address: "THABONG VILLAGE  WITSIESHOEK  MAKENENG",
    Latitude: "-28.6107",
    Longitude: "28.8098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5503",
    WardLookupId: "987",
    VDNumber: "22420325",
    RegisteredPopulation: "831",
    VotingStationName: "ITEBOHELENG PRIMARY SCHOOL",
    Address: "MAKENENG VILLAGE  WITSIESHOEK  MOKODUMELA",
    Latitude: "-28.5906",
    Longitude: "28.8109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5504",
    WardLookupId: "987",
    VDNumber: "22420460",
    RegisteredPopulation: "1150",
    VotingStationName: "MAKENENG PRIMARY SCHOOL",
    Address: "MAKENENG VILLAGE  WITSIESHOEK  MAKENENG",
    Latitude: "-28.6118",
    Longitude: "28.8219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5505",
    WardLookupId: "987",
    VDNumber: "22420796",
    RegisteredPopulation: "516",
    VotingStationName: "THUSADITJHABA CRECHE",
    Address: "875 THABANG VILLAGE  WITSIESHOEK  MAKENENG",
    Latitude: "-28.6026",
    Longitude: "28.8279",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5506",
    WardLookupId: "987",
    VDNumber: "22420886",
    RegisteredPopulation: "1309",
    VotingStationName: "THABANG COMMUNITY HALL",
    Address: "MAKENENG VILLAGE  WITSIESHOEK  MAKENENG",
    Latitude: "-28.6021",
    Longitude: "28.8321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5507",
    WardLookupId: "987",
    VDNumber: "22421168",
    RegisteredPopulation: "878",
    VotingStationName: "MAKONG INTERMEDIATE SCHOOL",
    Address: "10107 MAKONG VILLAGE  WITSIESHOEK  NAMAHALI",
    Latitude: "-28.5992",
    Longitude: "28.8107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5508",
    WardLookupId: "987",
    VDNumber: "22421179",
    RegisteredPopulation: "217",
    VotingStationName: "TENT NEXT TO MATSIENG CLINIC",
    Address: "MATSIENG VILLAGE  WITSIESHOEK  NAMAHALI",
    Latitude: "-28.601",
    Longitude: "28.8024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5509",
    WardLookupId: "987",
    VDNumber: "22421720",
    RegisteredPopulation: "278",
    VotingStationName: "TENT NEXT TO THABANG TUCK SHOP",
    Address: "MAKENENG VILLAGE  WITSIESHOEK  BATLOKWA",
    Latitude: "-28.6122",
    Longitude: "28.8277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5510",
    WardLookupId: "988",
    VDNumber: "22420550",
    RegisteredPopulation: "1698",
    VotingStationName: "MOJATSOHLE PRIMARY SCHOOL",
    Address: "MANGAUNG VILLAGE  QWAQWA  NAMAHALI",
    Latitude: "-28.5765",
    Longitude: "28.8225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5511",
    WardLookupId: "988",
    VDNumber: "22420572",
    RegisteredPopulation: "960",
    VotingStationName: "NEO PRIMARY SCHOOL",
    Address: "SEBOKENG VILLAGE  WITSIESHOEK  NAMAHALI",
    Latitude: "-28.5844",
    Longitude: "28.8283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5512",
    WardLookupId: "988",
    VDNumber: "22421067",
    RegisteredPopulation: "1247",
    VotingStationName: "DIPHAKWENG PRIMARY SCHOOL",
    Address: "MANGAUNG VILLAGE  WITSIESHOEK  NAMAHALI",
    Latitude: "-28.588",
    Longitude: "28.8215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5513",
    WardLookupId: "988",
    VDNumber: "22421618",
    RegisteredPopulation: "648",
    VotingStationName: "MABOSHWANE",
    Address: "BOPHELONG (MANGAUNG)  WITSIESHOEK  WITSIESHOEK",
    Latitude: "-28.5786",
    Longitude: "28.8171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5514",
    WardLookupId: "988",
    VDNumber: "22421731",
    RegisteredPopulation: "609",
    VotingStationName: "MANGAUNG INTERMEDIATE  SCHOOL",
    Address: "MANGAUNG VILLAGE  WITSIESHOEK  NAMAHALI",
    Latitude: "-28.5811",
    Longitude: "28.8252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5515",
    WardLookupId: "988",
    VDNumber: "22421742",
    RegisteredPopulation: "471",
    VotingStationName: "TENT AT SEBOKENG",
    Address: "SEBOKENG   WITSIESHOEK  NAMAHADI",
    Latitude: "-28.5935",
    Longitude: "28.8236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5516",
    WardLookupId: "989",
    VDNumber: "22420257",
    RegisteredPopulation: "1014",
    VotingStationName: "TEBANG INTERMEDIATE SCHOOL",
    Address: "11544 TEBANG VILLAGE  WITSIESHOEK  NAMAHALI",
    Latitude: "-28.5809",
    Longitude: "28.8395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5517",
    WardLookupId: "989",
    VDNumber: "22420291",
    RegisteredPopulation: "1527",
    VotingStationName: "LETSHA-LE-MADUKE PRIMARY SCHOOL",
    Address: "MANGAUNG VILLAGE  WITSIESHOEK  NAMAHALI",
    Latitude: "-28.5588",
    Longitude: "28.8383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5518",
    WardLookupId: "989",
    VDNumber: "22420303",
    RegisteredPopulation: "824",
    VotingStationName: "NAMAHALI TRIBAL COUNCIL",
    Address: "NAMAHALI VILLAGE  WITSIESHOEK  NAMAHALI",
    Latitude: "-28.562",
    Longitude: "28.8325",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5519",
    WardLookupId: "989",
    VDNumber: "22420651",
    RegisteredPopulation: "1174",
    VotingStationName: "MANTSHATLALA INTERMEDIATE SCHOOL",
    Address: "MANGAUNG VILLAGE  WITSIESHOEK  NAMAHADI",
    Latitude: "-28.5699",
    Longitude: "28.836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5520",
    WardLookupId: "989",
    VDNumber: "22421753",
    RegisteredPopulation: "693",
    VotingStationName: "HA SHAI GARAGE",
    Address: "HARANKOPANE VILLAGE  WITSIESHOEK  NAMAHALI",
    Latitude: "-28.5485",
    Longitude: "28.8365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5521",
    WardLookupId: "989",
    VDNumber: "22421764",
    RegisteredPopulation: "395",
    VotingStationName: "MAMPOI HIGH SCHOOL",
    Address: "MANGAUNG VILLAGE  WITSIESHOEK  NAMAHALI",
    Latitude: "-28.5829",
    Longitude: "28.8305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5522",
    WardLookupId: "990",
    VDNumber: "22420279",
    RegisteredPopulation: "1243",
    VotingStationName: "QIBI PRIMARY SCHOOL",
    Address: "HA SETHUNYA  NAMAHALI  HA SETHUNYA",
    Latitude: "-28.574",
    Longitude: "28.8455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5523",
    WardLookupId: "990",
    VDNumber: "22420987",
    RegisteredPopulation: "595",
    VotingStationName: "MIRI PRIMARY SCHOOL",
    Address: "THABABOSIU VILLAGE  PHUTHADITJHABA  QOQOLOSING",
    Latitude: "-28.5988",
    Longitude: "28.8983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5524",
    WardLookupId: "990",
    VDNumber: "22421012",
    RegisteredPopulation: "1239",
    VotingStationName: "THABA BOSIU INTERMEDIATE SCHOOL",
    Address: "THABA BOSIU VILLAGE  WITSIESHOEK  TSESENG",
    Latitude: "-28.5977",
    Longitude: "28.8794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5525",
    WardLookupId: "990",
    VDNumber: "22421348",
    RegisteredPopulation: "504",
    VotingStationName: "TINKA STORE",
    Address: "THABA BOSIU VILLAGE  WITSIESHOEK  HA SETHUNYA",
    Latitude: "-28.5715",
    Longitude: "28.8542",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5526",
    WardLookupId: "990",
    VDNumber: "22421359",
    RegisteredPopulation: "236",
    VotingStationName: "THEMBALETHU CRECHE",
    Address: "THABA BOSIU VILLAGE  WITSIESHOEK  JWALA BOHOLO",
    Latitude: "-28.6025",
    Longitude: "28.9093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5527",
    WardLookupId: "990",
    VDNumber: "22421393",
    RegisteredPopulation: "213",
    VotingStationName: "JWALA BOHOLO PRIMARY SCHOOL",
    Address: "THABA BOSIU VILLAGE  WITSIESHOEK  QOQOLOSING",
    Latitude: "-28.6084",
    Longitude: "28.9223",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5528",
    WardLookupId: "990",
    VDNumber: "22421775",
    RegisteredPopulation: "692",
    VotingStationName: "MOFUMAHADI DAY CARE",
    Address: "THABA BOSIU VILLAGE  WITSIESHOEK  QOQOLOSING",
    Latitude: "-28.5859",
    Longitude: "28.8681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5529",
    WardLookupId: "990",
    VDNumber: "22421786",
    RegisteredPopulation: "462",
    VotingStationName: "GREEN SHACK",
    Address: "METSIMATSHO VILLAGE  QWAQWA  QWAQWA",
    Latitude: "-28.5553",
    Longitude: "28.8842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5530",
    WardLookupId: "991",
    VDNumber: "22420167",
    RegisteredPopulation: "1131",
    VotingStationName: "THIBELLA INTERMEDIATE SCHOOL",
    Address: "THIBELLA VILLAGE  WITSIESHOEK  TSESENG",
    Latitude: "-28.6442",
    Longitude: "28.8562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5531",
    WardLookupId: "991",
    VDNumber: "22420426",
    RegisteredPopulation: "249",
    VotingStationName: "THABA TSHEU PRIMARY SCHOOL",
    Address: "LEJOANENG VILLAGE  WITSIESHOEK  TSESENG",
    Latitude: "-28.6314",
    Longitude: "28.8406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5532",
    WardLookupId: "991",
    VDNumber: "22420527",
    RegisteredPopulation: "830",
    VotingStationName: "MOHALE INTERMEDIATE SCHOOL",
    Address: "13873 WITSIESHOEK  MAKENENG  QWAQWA",
    Latitude: "-28.6206",
    Longitude: "28.8229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5533",
    WardLookupId: "991",
    VDNumber: "22420785",
    RegisteredPopulation: "842",
    VotingStationName: "SEKGOMPEPE PRIMARY SCHOOL",
    Address: "THIBELLA VILLAGE  WITSIESHOEK  TSESENG",
    Latitude: "-28.6378",
    Longitude: "28.8528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5534",
    WardLookupId: "991",
    VDNumber: "22421236",
    RegisteredPopulation: "804",
    VotingStationName: "MAKGETHENG PRIMARY SCHOOL",
    Address: "LEJWANENG VILLAGE  WITSIESHOEK  TSESENG",
    Latitude: "-28.6457",
    Longitude: "28.8398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5535",
    WardLookupId: "991",
    VDNumber: "22421797",
    RegisteredPopulation: "174",
    VotingStationName: "TENT AT KGILIBITHI",
    Address: "LEJWANENG VILLAGE  QWAQWA  TSESENG",
    Latitude: "-28.6529",
    Longitude: "28.861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5536",
    WardLookupId: "991",
    VDNumber: "22421809",
    RegisteredPopulation: "165",
    VotingStationName: "BASIA CAFE",
    Address: "KGILIBITHI VILLAGE  WITSIESHOEK  TSESENG",
    Latitude: "-28.6352",
    Longitude: "28.8562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5537",
    WardLookupId: "991",
    VDNumber: "22421810",
    RegisteredPopulation: "309",
    VotingStationName: "PHOFUNG SECONDARY SCHOOL",
    Address: "TSHESENG VILLAGE  QWAQWA  MASIANOKENG",
    Latitude: "-28.6358",
    Longitude: "28.8666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5538",
    WardLookupId: "992",
    VDNumber: "22420033",
    RegisteredPopulation: "633",
    VotingStationName: "TSESENG INTERMEDIATE SCHOOL",
    Address: "PHOMOLONG  QWAQWA",
    Latitude: "-28.6351",
    Longitude: "28.8746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5539",
    WardLookupId: "992",
    VDNumber: "22420190",
    RegisteredPopulation: "879",
    VotingStationName: "KOOS MOTA INTERMEDIATE SCHOOL",
    Address: "2636 PHOMOLONG VILLAGE  WITSIESHOEK  TSESENG",
    Latitude: "-28.6345",
    Longitude: "28.8691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5540",
    WardLookupId: "992",
    VDNumber: "22420673",
    RegisteredPopulation: "363",
    VotingStationName: "OLD SOUTH CIRCUIT OFFICE",
    Address: "MOEDING VILLAGE  WITSIESHOEK  TSESENG",
    Latitude: "-28.6199",
    Longitude: "28.8546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5541",
    WardLookupId: "992",
    VDNumber: "22420684",
    RegisteredPopulation: "549",
    VotingStationName: "MATSWAKENG INTERMEDIATE SCHOOL",
    Address: "MATSWAKENG VILLAGE  WITSIESHOEK  TSESENG",
    Latitude: "-28.6436",
    Longitude: "28.8833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5542",
    WardLookupId: "992",
    VDNumber: "22420965",
    RegisteredPopulation: "806",
    VotingStationName: "PHETA INTERMEDIATE SCHOOL",
    Address: "MOEDING VILLAGE  WITSIESHOEK  TSESENG",
    Latitude: "-28.6079",
    Longitude: "28.8524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5543",
    WardLookupId: "992",
    VDNumber: "22421090",
    RegisteredPopulation: "393",
    VotingStationName: "MOHLAKANENG INTERMEDIATE SCHOOL",
    Address: "10811 DINKOENG VILLAGE  WITSIESHOEK  TSESENG",
    Latitude: "-28.6326",
    Longitude: "28.8988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5544",
    WardLookupId: "992",
    VDNumber: "22421102",
    RegisteredPopulation: "681",
    VotingStationName: "SEDIBENG PRIMARY SCHOOL",
    Address: "MATSWAKENG VILLAGE  WITSIESHOEK  TSESENG",
    Latitude: "-28.6262",
    Longitude: "28.8576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5545",
    WardLookupId: "992",
    VDNumber: "22421821",
    RegisteredPopulation: "206",
    VotingStationName: "TENT AT MAFIKENG",
    Address: "TSESENG VILLAGE  QWAQWA  MOEDING",
    Latitude: "-28.6122",
    Longitude: "28.8615",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5546",
    WardLookupId: "992",
    VDNumber: "22421832",
    RegisteredPopulation: "96",
    VotingStationName: "TENT AT PITSENG",
    Address: "SEFIKENG VILLAGE  QWAQWA  DINKOENG",
    Latitude: "-28.6443",
    Longitude: "28.8993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5547",
    WardLookupId: "993",
    VDNumber: "22210052",
    RegisteredPopulation: "1650",
    VotingStationName: "NALEDI HALL",
    Address: "NTSHINGILA STR  HARRISMITH  INTABAZWE",
    Latitude: "-28.2515",
    Longitude: "29.0993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5548",
    WardLookupId: "993",
    VDNumber: "22210108",
    RegisteredPopulation: "1592",
    VotingStationName: "SASAMALA SENIOR SECONDARY SCHOOL",
    Address: "280 TSHIAME A  HARRISMITH",
    Latitude: "-28.2978",
    Longitude: "29.0174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5549",
    WardLookupId: "993",
    VDNumber: "22210276",
    RegisteredPopulation: "617",
    VotingStationName: "PLATBERG STADIUM",
    Address: "HARRISMITH  ",
    Latitude: "-28.2744",
    Longitude: "29.1366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5550",
    WardLookupId: "994",
    VDNumber: "22420370",
    RegisteredPopulation: "1201",
    VotingStationName: "MOHLAPHUDI SHOP",
    Address: "16705 WITSIESHOEK  MARAKONG  MALUTI A PHOFUNG",
    Latitude: "-28.5624",
    Longitude: "28.8522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5551",
    WardLookupId: "994",
    VDNumber: "22420695",
    RegisteredPopulation: "1443",
    VotingStationName: "MOLIBELI PRIMARY SCHOOL",
    Address: "KATLEHONG  MALUTI A PHOFUNG",
    Latitude: "-28.5435",
    Longitude: "28.8357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5552",
    WardLookupId: "994",
    VDNumber: "22421270",
    RegisteredPopulation: "1251",
    VotingStationName: "REALEBOHA CRECHE",
    Address: "18460 HA MAFOSE ROUTE  NAMAHALI  LETSHA LE MADUKE",
    Latitude: "-28.5521",
    Longitude: "28.8421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5553",
    WardLookupId: "994",
    VDNumber: "22421843",
    RegisteredPopulation: "232",
    VotingStationName: "TENT NEXT TO ROMAN CATHOLIC CHURCH",
    Address: "KATLEHONG VILLAGE  PHUTHADITJHABA  NAMAHALI",
    Latitude: "-28.5472",
    Longitude: "28.841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5554",
    WardLookupId: "994",
    VDNumber: "22421854",
    RegisteredPopulation: "1078",
    VotingStationName: "BOITUMELO CRECHE",
    Address: "MARAKONG VILLAGE  WITSIESHOEK  NAMAHALI",
    Latitude: "-28.5629",
    Longitude: "28.8449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5555",
    WardLookupId: "995",
    VDNumber: "22420404",
    RegisteredPopulation: "832",
    VotingStationName: "MOLAPO SECONDARY SCHOOL",
    Address: "01 MAKWANE  MATSHEKGENG  THABO MOFUTSANYANA",
    Latitude: "-28.5468",
    Longitude: "28.8636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5556",
    WardLookupId: "995",
    VDNumber: "22421180",
    RegisteredPopulation: "1997",
    VotingStationName: "QHOLAQHWE INTERMEDIATE SCHOOL",
    Address: "QHOLAQHWE  WITSIESHOEK",
    Latitude: "-28.5221",
    Longitude: "28.8525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5557",
    WardLookupId: "995",
    VDNumber: "22421292",
    RegisteredPopulation: "1143",
    VotingStationName: "LUSAKA CHRISTIAN FELLOWSHIP",
    Address: "LUSAKA  MALUTI A PHOFUNG [QWA-QWA]",
    Latitude: "-28.5327",
    Longitude: "28.8609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5558",
    WardLookupId: "995",
    VDNumber: "22421360",
    RegisteredPopulation: "573",
    VotingStationName: "TENT NEXT TO MAJWENG CHURCH",
    Address: "LUSAKA VILLAGE  LUSAKA  MAKOANE",
    Latitude: "-28.5379",
    Longitude: "28.8645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5559",
    WardLookupId: "995",
    VDNumber: "22421405",
    RegisteredPopulation: "867",
    VotingStationName: "TENT NEXT TO MARUMO TUCKSHOP",
    Address: "MAKWANE  WITSIESHOEK  WITSIESHOEK",
    Latitude: "-28.5222",
    Longitude: "28.8607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5560",
    WardLookupId: "995",
    VDNumber: "22421944",
    RegisteredPopulation: "177",
    VotingStationName: "MARUMO COMMUNITY CENTRE",
    Address: "MARUMO VILLAGE  MAKOANE  WETSIESHOEK",
    Latitude: "-28.5054",
    Longitude: "28.8461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5561",
    WardLookupId: "996",
    VDNumber: "22320133",
    RegisteredPopulation: "756",
    VotingStationName: "KWETLISONG TECHNICAL COLLEGE",
    Address: "BOLATA  THABO MOFUTSANYANA  RIVERSIDE",
    Latitude: "-28.5435",
    Longitude: "28.8155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5562",
    WardLookupId: "996",
    VDNumber: "22320144",
    RegisteredPopulation: "1788",
    VotingStationName: "LETSIBOLO INTERMEDIATE SCHOOL",
    Address: "802 RIVERSIDE  PHUTHADITJHABA  PHUTHADITJHABA",
    Latitude: "-28.551",
    Longitude: "28.8112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5563",
    WardLookupId: "996",
    VDNumber: "22420730",
    RegisteredPopulation: "1738",
    VotingStationName: "PHAMONG PRIMARY SCHOOL",
    Address: "5535 PHAMONG  QWAQWA",
    Latitude: "-28.5554",
    Longitude: "28.8047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5564",
    WardLookupId: "997",
    VDNumber: "22420178",
    RegisteredPopulation: "1005",
    VotingStationName: "BOIKETLONG PRIMARY SCHOOL",
    Address: "BOIKETLO VILLAGE  WITSIESHOEK  NAMAHALI",
    Latitude: "-28.5512",
    Longitude: "28.8265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5565",
    WardLookupId: "997",
    VDNumber: "22420202",
    RegisteredPopulation: "1181",
    VotingStationName: "MABEWANA PRIMARY SCHOOL",
    Address: "SENYAMO VILLAGE  PHUTHADITJHABA  NAMAHALI",
    Latitude: "-28.5621",
    Longitude: "28.8217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5566",
    WardLookupId: "997",
    VDNumber: "22420213",
    RegisteredPopulation: "901",
    VotingStationName: "MABELA INTERMEDIATE SCHOOL",
    Address: "BOIKETLO VILLAGE  PHUTHADITJHABA  NAMAHALI",
    Latitude: "-28.5476",
    Longitude: "28.8225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5567",
    WardLookupId: "997",
    VDNumber: "22420707",
    RegisteredPopulation: "755",
    VotingStationName: "QWABI PRIMARY SCHOOL",
    Address: "5115 QWABI  HARANKOPANE  QWAQWA",
    Latitude: "-28.5455",
    Longitude: "28.8341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5568",
    WardLookupId: "997",
    VDNumber: "22421304",
    RegisteredPopulation: "343",
    VotingStationName: "HA MAPITSO",
    Address: "BOIKETLO VILLAGE  WITSIESHOEK  NAMAHALI",
    Latitude: "-28.5609",
    Longitude: "28.827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5569",
    WardLookupId: "997",
    VDNumber: "22421449",
    RegisteredPopulation: "315",
    VotingStationName: "ITSOSENG CRECHE",
    Address: "BOIKETLO VILLAGE  WITSIEHSOEK  NAMAHALI",
    Latitude: "-28.5554",
    Longitude: "28.8265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5570",
    WardLookupId: "997",
    VDNumber: "22421876",
    RegisteredPopulation: "364",
    VotingStationName: "TSEBO SECONDARY SCHOOL",
    Address: "BOIKETLO VILLAGE  WITSIESHOEK  NAMAHALI TRIBAL",
    Latitude: "-28.547",
    Longitude: "28.8241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5571",
    WardLookupId: "998",
    VDNumber: "22320010",
    RegisteredPopulation: "1439",
    VotingStationName: "AKOFANG INTERMEDIATE SCHOOL",
    Address: "3653 NTEO STREET  PHUTHADITJHABA  PHUTHADITJHABA",
    Latitude: "-28.5182",
    Longitude: "28.8214",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5572",
    WardLookupId: "998",
    VDNumber: "22320021",
    RegisteredPopulation: "1906",
    VotingStationName: "BONAMELO CAMPUS",
    Address: "MAFUBE STREET  PHUTHADITJHABA  PHUTHADITJHABA",
    Latitude: "-28.5267",
    Longitude: "28.8181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5573",
    WardLookupId: "998",
    VDNumber: "22320054",
    RegisteredPopulation: "1281",
    VotingStationName: "LERATONG CHILDRENS HOME",
    Address: "THEKOLOHELONG STREET  PHUTHADITJHABA  PHUTHADITJHABA",
    Latitude: "-28.5316",
    Longitude: "28.811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5574",
    WardLookupId: "998",
    VDNumber: "22320100",
    RegisteredPopulation: "894",
    VotingStationName: "METHODIST CHURCH",
    Address: "MOEKO STR  PHUTHADITJHABA  MALUTI-A-PHOFUNG",
    Latitude: "-28.5204",
    Longitude: "28.8243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5575",
    WardLookupId: "999",
    VDNumber: "22320166",
    RegisteredPopulation: "2230",
    VotingStationName: "THE BEACON SECONDARY SCHOOL",
    Address: "13761 MABOLELA ROAD  WITSIESHOEK  MABOLELA",
    Latitude: "-28.5251",
    Longitude: "28.7826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5576",
    WardLookupId: "999",
    VDNumber: "22320177",
    RegisteredPopulation: "929",
    VotingStationName: "TSHIYA RESOURCE CENTRE",
    Address: "MOSESE STREET  PHUTHADITJHABA  NABOLELA",
    Latitude: "-28.5297",
    Longitude: "28.7971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5577",
    WardLookupId: "999",
    VDNumber: "22420077",
    RegisteredPopulation: "1273",
    VotingStationName: "MABOLELA TRIBAL COUNCIL",
    Address: "MA-HEIK  PHUTHADITJHABA  MABOLELA",
    Latitude: "-28.5201",
    Longitude: "28.8016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5578",
    WardLookupId: "999",
    VDNumber: "22420088",
    RegisteredPopulation: "1151",
    VotingStationName: "LETOTOLO PRIMARY SCHOOL",
    Address: "18130 MABOLELA VILLAGE  WITSIESHOEK  MABOLELA",
    Latitude: "-28.5216",
    Longitude: "28.7891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5579",
    WardLookupId: "999",
    VDNumber: "22421887",
    RegisteredPopulation: "269",
    VotingStationName: "THUSANG GENERAL DEALER",
    Address: "MABOLELA  WITSIESHOEK  WITSIESHOEK",
    Latitude: "-28.5178",
    Longitude: "28.7962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5580",
    WardLookupId: "999",
    VDNumber: "22421898",
    RegisteredPopulation: "214",
    VotingStationName: "TENT AT MABOLELA",
    Address: "MABOLELA  WITSIESHOEK  MABOLELA",
    Latitude: "-28.5077",
    Longitude: "28.8139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5581",
    WardLookupId: "1000",
    VDNumber: "22320032",
    RegisteredPopulation: "1602",
    VotingStationName: "PHUTHADITJHABA INTERMEDIATE SCHOOL",
    Address: "5045 MAMPOI STREET  PHUTHADITJHABA  MALUTI-A-PHOFUNG",
    Latitude: "-28.5201",
    Longitude: "28.8175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5582",
    WardLookupId: "1000",
    VDNumber: "22320043",
    RegisteredPopulation: "1713",
    VotingStationName: "BOITJHORISONG FET COLLEGE",
    Address: "MAMPOI STREET  PHUTHADITJHABA  MALUTI-A-PHOFUNG",
    Latitude: "-28.5283",
    Longitude: "28.8076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5583",
    WardLookupId: "1000",
    VDNumber: "22320212",
    RegisteredPopulation: "584",
    VotingStationName: "KARABO CHURCH",
    Address: "SHELL STREET  PHUTHADITJHABA  MALUTI-A-PHOFUNG",
    Latitude: "-28.5113",
    Longitude: "28.8219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5584",
    WardLookupId: "1000",
    VDNumber: "22420101",
    RegisteredPopulation: "1242",
    VotingStationName: "SEPHOKONG INTERMEDIATE SCHOOL",
    Address: "5410 BOTJHABELA VILLAGE  PHUTHADITJHABA  MABOLELA",
    Latitude: "-28.5086",
    Longitude: "28.8189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5585",
    WardLookupId: "1001",
    VDNumber: "22420268",
    RegisteredPopulation: "1234",
    VotingStationName: "TENT AT KGOTSONG",
    Address: "KGOTSONG VILLAGE  LUSAKA  WITSIESHOEK",
    Latitude: "-28.5336",
    Longitude: "28.8505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5586",
    WardLookupId: "1001",
    VDNumber: "22421078",
    RegisteredPopulation: "2081",
    VotingStationName: "Z.R.MAHABANE PRIMARY SCHOOL",
    Address: "LUSAKA  LUSAKA VILLAGE  MAKOANE",
    Latitude: "-28.5359",
    Longitude: "28.858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5587",
    WardLookupId: "1001",
    VDNumber: "22421461",
    RegisteredPopulation: "666",
    VotingStationName: "LEHLASEDI DAYCARE",
    Address: "KGOTSONG VILLAGE  MAKOANE  WITSIESHOEK",
    Latitude: "-28.5358",
    Longitude: "28.8523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5588",
    WardLookupId: "1001",
    VDNumber: "22421900",
    RegisteredPopulation: "598",
    VotingStationName: "LUSAKA PHOLOSO CHURCH",
    Address: "TEBANG  MAKOANE  WITSIESHOEK",
    Latitude: "-28.5284",
    Longitude: "28.8567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5589",
    WardLookupId: "1002",
    VDNumber: "22420145",
    RegisteredPopulation: "800",
    VotingStationName: "DIQHOBONG PRIMARY SCHOOL",
    Address: "5350 MAKWANE  METSIMATSHO  THABO MOFUTSANYANA",
    Latitude: "-28.5611",
    Longitude: "28.8851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5590",
    WardLookupId: "1002",
    VDNumber: "22420415",
    RegisteredPopulation: "2159",
    VotingStationName: "MOHALADITWE SECONDARY SCHOOL",
    Address: "5524 MOHALADITWE  MMAKWANE  QWAQWA",
    Latitude: "-28.5491",
    Longitude: "28.8855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5591",
    WardLookupId: "1002",
    VDNumber: "22420448",
    RegisteredPopulation: "726",
    VotingStationName: "RANTSANE SENIOR SECONDARY SCHOOL",
    Address: "5649 COMET  MMAKWANE  PHAHAMENG",
    Latitude: "-28.539",
    Longitude: "28.8872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5592",
    WardLookupId: "1002",
    VDNumber: "22420459",
    RegisteredPopulation: "970",
    VotingStationName: "MACHAEA PRIMARY SCHOOL",
    Address: "14999 METSI-MATSHO  COMET  WITSIESHOEK",
    Latitude: "-28.5571",
    Longitude: "28.8771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5593",
    WardLookupId: "1002",
    VDNumber: "22420482",
    RegisteredPopulation: "768",
    VotingStationName: "TSWELANGPELE INTERMEDIATE SCHOOL",
    Address: "5574 TSWELANGPELE  COMET  THABO MOFUTSANYANA",
    Latitude: "-28.5529",
    Longitude: "28.8669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5594",
    WardLookupId: "1002",
    VDNumber: "22421450",
    RegisteredPopulation: "259",
    VotingStationName: "SHOESHOE PRIMARY SCHOOL",
    Address: "111 PHAHAMENG  MAKOANE  QWAQWA",
    Latitude: "-28.5424",
    Longitude: "28.8873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5595",
    WardLookupId: "1003",
    VDNumber: "22320188",
    RegisteredPopulation: "2629",
    VotingStationName: "THAROLLO SECONDARY SCHOOL",
    Address: "MPHEFELA  PHUTHADITJHABA  MALUTI A PHOFUNG",
    Latitude: "-28.4725",
    Longitude: "28.8581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5596",
    WardLookupId: "1003",
    VDNumber: "22320223",
    RegisteredPopulation: "47",
    VotingStationName: "TENT AT QWANTANI",
    Address: "PATRYSKAMP   MALUTI A PHOFUNG  HARRISMITH",
    Latitude: "-28.4379",
    Longitude: "28.9624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5597",
    WardLookupId: "1003",
    VDNumber: "22320256",
    RegisteredPopulation: "598",
    VotingStationName: "KANANELO EDUCARE CENTRE",
    Address: "KGABISI EXTENSION 17  MAKOANE  QWAQWA",
    Latitude: "-28.5111",
    Longitude: "28.875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5598",
    WardLookupId: "1003",
    VDNumber: "22420606",
    RegisteredPopulation: "1635",
    VotingStationName: "KGOLEDI YA MANKA PRIMARY SCHOOL",
    Address: "TEBANG  MAKOANE  COMET",
    Latitude: "-28.5195",
    Longitude: "28.8584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5599",
    WardLookupId: "1003",
    VDNumber: "22421337",
    RegisteredPopulation: "844",
    VotingStationName: "TUMISI STORE",
    Address: "TEBANG VILLAGE   NAMAHALI  WITSIESHOEK",
    Latitude: "-28.5213",
    Longitude: "28.8717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5600",
    WardLookupId: "1003",
    VDNumber: "22421382",
    RegisteredPopulation: "1050",
    VotingStationName: "TENT AT KGABISI",
    Address: "MARUMO VILLAGE  TEBANG  MAKOANE",
    Latitude: "-28.52",
    Longitude: "28.8751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5601",
    WardLookupId: "1004",
    VDNumber: "22320076",
    RegisteredPopulation: "530",
    VotingStationName: "THIBOLOHA SCHOOL FOR THE DEAF & BLIND",
    Address: "RIVERSIDE LOCATION  MALUTI A PHOFUNG  PHUTHADITJHABA",
    Latitude: "-28.5383",
    Longitude: "28.8158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5602",
    WardLookupId: "1004",
    VDNumber: "22320087",
    RegisteredPopulation: "2144",
    VotingStationName: "LEBOHANG DIMPHO CRECHE",
    Address: "MANDELA PARK  PHUTHADITJHABA  NAMAHALI",
    Latitude: "-28.5359",
    Longitude: "28.827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5603",
    WardLookupId: "1004",
    VDNumber: "22320111",
    RegisteredPopulation: "912",
    VotingStationName: "TENT AT SECTION 4 MANDELA PARK",
    Address: "MANDELA PARK  PHUTHADITJHABA  NAMAHALI",
    Latitude: "-28.5244",
    Longitude: "28.8306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5604",
    WardLookupId: "1004",
    VDNumber: "22320234",
    RegisteredPopulation: "974",
    VotingStationName: "REHLOKOMETSWE DAY CARE",
    Address: "MANDELA PARK  WITSIESHOEK  MANDELA PARK",
    Latitude: "-28.5399",
    Longitude: "28.8245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5605",
    WardLookupId: "1004",
    VDNumber: "22320267",
    RegisteredPopulation: "732",
    VotingStationName: "DR NKOSAZANA DLAMINI ZUMA DROP IN CENTRE",
    Address: "SLOVO PARK  PHUTHADITJHABA  NAMAHALI",
    Latitude: "-28.5366",
    Longitude: "28.8424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5606",
    WardLookupId: "1005",
    VDNumber: "22320065",
    RegisteredPopulation: "3493",
    VotingStationName: "BLUEGUMBOSCH SECONDARY SCHOOL",
    Address: "SEOTLONG ROUTE  THABO MOFUTSANYANA  BLUEGUM-BOSCH",
    Latitude: "-28.4801",
    Longitude: "28.846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5607",
    WardLookupId: "1005",
    VDNumber: "22320199",
    RegisteredPopulation: "1264",
    VotingStationName: "BLUEGUMBOSCH STADIUM",
    Address: "BLUEGUMBOSCH  PHUTHADITJHABA  PHUTHADITJHABA",
    Latitude: "-28.4828",
    Longitude: "28.8533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5608",
    WardLookupId: "1005",
    VDNumber: "22320245",
    RegisteredPopulation: "1172",
    VotingStationName: "PORTESHOUSE REVIVAL CHURCH",
    Address: "BLUEGUMBOSCH  PHUTHADITJHABA  PHUTHADITJHABA",
    Latitude: "-28.4747",
    Longitude: "28.8468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5609",
    WardLookupId: "1006",
    VDNumber: "22420369",
    RegisteredPopulation: "1127",
    VotingStationName: "TEBOHO PRIMARY SCHOOL",
    Address: "PHAHAMENG  MAKOANE  WITSIESHOEK",
    Latitude: "-28.5344",
    Longitude: "28.8812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5610",
    WardLookupId: "1006",
    VDNumber: "22420392",
    RegisteredPopulation: "610",
    VotingStationName: "TENT NEXT TO MAKOANE STADIUM",
    Address: "SEKHUTLONG VILLAGE  MAKOANE  WITSIESHOEK",
    Latitude: "-28.5487",
    Longitude: "28.8992",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5611",
    WardLookupId: "1006",
    VDNumber: "22420594",
    RegisteredPopulation: "782",
    VotingStationName: "TENT NEXT TO LEBOHANG CRECHE",
    Address: "PERENG VILLAGE  MAKWANE  MAKWANE",
    Latitude: "-28.5198",
    Longitude: "28.881",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5612",
    WardLookupId: "1006",
    VDNumber: "22420628",
    RegisteredPopulation: "434",
    VotingStationName: "NG KERK",
    Address: "22497 CHURCH STREET  MAKWANE  MALUTI A PHOFUNG",
    Latitude: "-28.5261",
    Longitude: "28.8711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5613",
    WardLookupId: "1006",
    VDNumber: "22421315",
    RegisteredPopulation: "301",
    VotingStationName: "DIKWENA STORE",
    Address: "MAKOANE VILLAGE  SEKHUTLONG   MAKOANE",
    Latitude: "-28.5376",
    Longitude: "28.8891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5614",
    WardLookupId: "1006",
    VDNumber: "22421326",
    RegisteredPopulation: "775",
    VotingStationName: "BAFODISI CHURCH",
    Address: "MAKWANE  WITSIESHOEK  WITSIESHOEK",
    Latitude: "-28.5286",
    Longitude: "28.8761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5615",
    WardLookupId: "1006",
    VDNumber: "22421371",
    RegisteredPopulation: "492",
    VotingStationName: "EVANGELICAL CHURCH",
    Address: "PHAHAMENG VILLAGE  MAKOANE  WETSIESHOEK",
    Latitude: "-28.5436",
    Longitude: "28.8961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5616",
    WardLookupId: "1006",
    VDNumber: "22421865",
    RegisteredPopulation: "193",
    VotingStationName: "JUNIOR CRECHE",
    Address: "TEBANG  MAKWANE  WITSIESHOEK",
    Latitude: "-28.528",
    Longitude: "28.8671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5617",
    WardLookupId: "1006",
    VDNumber: "22421911",
    RegisteredPopulation: "335",
    VotingStationName: "TENT NEXT TO SENTEBALE SHOP",
    Address: "MAKOANE  WITSIESHOEK  BATLOKOA",
    Latitude: "-28.5228",
    Longitude: "28.8751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5618",
    WardLookupId: "1006",
    VDNumber: "22421922",
    RegisteredPopulation: "289",
    VotingStationName: "SEKGUTLONG PHOLOSO CHURCH",
    Address: "SEKGUTLONG  MAKOANE  WITSIESHOEK",
    Latitude: "-28.5314",
    Longitude: "28.8836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5619",
    WardLookupId: "1006",
    VDNumber: "22421933",
    RegisteredPopulation: "116",
    VotingStationName: "MAKWANE CHURCH",
    Address: "DIPOLATENG VILLAGE  MAKOANE   WITSIESHOEK",
    Latitude: "-28.5457",
    Longitude: "28.8944",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5620",
    WardLookupId: "1007",
    VDNumber: "22290016",
    RegisteredPopulation: "1423",
    VotingStationName: "MEMEL PUBLIC SCHOOL",
    Address: "ZAMANI OLD LOCATION  ZAMANI  MEMEL",
    Latitude: "-27.6687",
    Longitude: "29.5556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5621",
    WardLookupId: "1007",
    VDNumber: "22290027",
    RegisteredPopulation: "1064",
    VotingStationName: "MEMEL  PRIMARY SCHOOL",
    Address: "WHIPP STREET  MEMEL  MEMEL",
    Latitude: "-27.684",
    Longitude: "29.5637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5622",
    WardLookupId: "1007",
    VDNumber: "22290049",
    RegisteredPopulation: "504",
    VotingStationName: "ZAMANI FULL GOSPEL",
    Address: "VREDE  ",
    Latitude: "-27.6714",
    Longitude: "29.5562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5623",
    WardLookupId: "1008",
    VDNumber: "22380027",
    RegisteredPopulation: "1057",
    VotingStationName: "THEMBIMFUNDO SCHOOL",
    Address: "1927 TSHABANGU STREET  THEMBALIHLE  VREDE",
    Latitude: "-27.4189",
    Longitude: "29.1793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5624",
    WardLookupId: "1008",
    VDNumber: "22380050",
    RegisteredPopulation: "1824",
    VotingStationName: "ASSEMBLIES CHURCH",
    Address: "3440 TAMBO  THEMBALIHLE  PHUMELELA [VREDE]",
    Latitude: "-27.424",
    Longitude: "29.1795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5625",
    WardLookupId: "1008",
    VDNumber: "22380083",
    RegisteredPopulation: "673",
    VotingStationName: "THE APOSTOLIC CHURCH OF ZION IN JERUSALEM",
    Address: "THEMBALIHLE  VREDE",
    Latitude: "-27.4297",
    Longitude: "29.1827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5626",
    WardLookupId: "1008",
    VDNumber: "22430067",
    RegisteredPopulation: "67",
    VotingStationName: "TENT AT KOEFONTEIN",
    Address: "KOEFONTEIN  VREDE  VREDE",
    Latitude: "-27.4853",
    Longitude: "29.1847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5627",
    WardLookupId: "1009",
    VDNumber: "22380049",
    RegisteredPopulation: "1614",
    VotingStationName: "VREDE TOWN HALL",
    Address: "KUHN STREET  VREDE  VREDE",
    Latitude: "-27.4298",
    Longitude: "29.163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5628",
    WardLookupId: "1009",
    VDNumber: "22380061",
    RegisteredPopulation: "1321",
    VotingStationName: "THEMBALIHLE MUNICIPAL OFFICES",
    Address: "(FS195 - PHUMELELA [VREDE])   ",
    Latitude: "-27.4212",
    Longitude: "29.1679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5629",
    WardLookupId: "1009",
    VDNumber: "22380072",
    RegisteredPopulation: "220",
    VotingStationName: "GLODEL INTERMEDIATE SCHOOL",
    Address: "KUHN STREET  VREDE  VREDE",
    Latitude: "-27.4328",
    Longitude: "29.1569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5630",
    WardLookupId: "1009",
    VDNumber: "22430382",
    RegisteredPopulation: "647",
    VotingStationName: "TENT AT EXT4",
    Address: "EXTENTION 4  THEMBALIHLE  VREDE",
    Latitude: "-27.4211",
    Longitude: "29.1528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5631",
    WardLookupId: "1010",
    VDNumber: "22380016",
    RegisteredPopulation: "1797",
    VotingStationName: "EVUNGWINI SECONDARY  SCHOOL",
    Address: "MAKOKO  THEMBALIHLE  VREDE",
    Latitude: "-27.4159",
    Longitude: "29.1789",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5632",
    WardLookupId: "1010",
    VDNumber: "22380038",
    RegisteredPopulation: "1310",
    VotingStationName: "MHLABUNZIMA COMMUNITY HALL",
    Address: "MAKOKO   VREDE  THEMBALIHLE",
    Latitude: "-27.4177",
    Longitude: "29.1733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5633",
    WardLookupId: "1011",
    VDNumber: "22390062",
    RegisteredPopulation: "517",
    VotingStationName: "TSWELOPELO PRE-SCHOOL",
    Address: "EZENZELENI  WARDEN",
    Latitude: "-27.8375",
    Longitude: "28.9791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5634",
    WardLookupId: "1011",
    VDNumber: "22400109",
    RegisteredPopulation: "210",
    VotingStationName: "VAALKOP PRIMARY SCHOOL",
    Address: "VAALKOP FARM  WARDEN  WARDEN",
    Latitude: "-27.9177",
    Longitude: "29.0412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5635",
    WardLookupId: "1011",
    VDNumber: "22400266",
    RegisteredPopulation: "248",
    VotingStationName: "TENT AT GEORGESKRAAL FARM",
    Address: "GEORGESKRAAL  WARDEN",
    Latitude: "-27.7779",
    Longitude: "29.0831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5636",
    WardLookupId: "1011",
    VDNumber: "22400277",
    RegisteredPopulation: "377",
    VotingStationName: "OLD POST OFFICE",
    Address: "VERKYKERSKOP  ",
    Latitude: "-27.9205",
    Longitude: "29.2802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5637",
    WardLookupId: "1011",
    VDNumber: "22400299",
    RegisteredPopulation: "223",
    VotingStationName: "SOLFERINO PRIMARY  SCHOOL",
    Address: "SOLFERINA FARM  WARDEN  WARDEN",
    Latitude: "-28.0042",
    Longitude: "28.8106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5638",
    WardLookupId: "1011",
    VDNumber: "22400301",
    RegisteredPopulation: "340",
    VotingStationName: "ALMA PRIMARY SCHOOL",
    Address: "ALMA FARM  WARDEN FARM  WARDEN",
    Latitude: "-27.9895",
    Longitude: "28.9819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5639",
    WardLookupId: "1011",
    VDNumber: "22400312",
    RegisteredPopulation: "89",
    VotingStationName: "LINCOLNSHIRE FARM SCHOOL",
    Address: "LINCOLNSHIRE FARM  MEMEL",
    Latitude: "-27.8893",
    Longitude: "29.623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5640",
    WardLookupId: "1011",
    VDNumber: "22400345",
    RegisteredPopulation: "198",
    VotingStationName: "TENT AT MERINODAL",
    Address: "MERINODAL FARM  MEMEL",
    Latitude: "-27.8433",
    Longitude: "29.3646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5641",
    WardLookupId: "1011",
    VDNumber: "22400480",
    RegisteredPopulation: "383",
    VotingStationName: "HLOMISA SCHOOL",
    Address: "RIETVLEI  HARRISMITH",
    Latitude: "-28.0426",
    Longitude: "29.4471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5642",
    WardLookupId: "1011",
    VDNumber: "22430089",
    RegisteredPopulation: "123",
    VotingStationName: "TENT AT PRESENTSKRAAL",
    Address: "VREDE  PRESENTSKRAAL FARM  VREDE",
    Latitude: "-27.7036",
    Longitude: "29.1712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5643",
    WardLookupId: "1011",
    VDNumber: "22430090",
    RegisteredPopulation: "224",
    VotingStationName: "TENT AT COUNTY CLAIRE",
    Address: "COUNTY CLAIRE FARM  VREDE",
    Latitude: "-27.6197",
    Longitude: "29.3471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5644",
    WardLookupId: "1011",
    VDNumber: "22430360",
    RegisteredPopulation: "79",
    VotingStationName: "DINASDEEL FARM",
    Address: "DINASDEEL  VREDE",
    Latitude: "-27.6303",
    Longitude: "29.1183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5645",
    WardLookupId: "1012",
    VDNumber: "22390028",
    RegisteredPopulation: "1076",
    VotingStationName: "IPHONDLE HIGH SCHOOL",
    Address: "TSHONGWE  STREET  EZENZELENI  WARDEN",
    Latitude: "-27.8366",
    Longitude: "28.969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5646",
    WardLookupId: "1012",
    VDNumber: "22390040",
    RegisteredPopulation: "677",
    VotingStationName: "SDUDU COMMUNITY HALL",
    Address: "SDUDU SECTION  EZENZELELNI  WARDEN",
    Latitude: "-27.8382",
    Longitude: "28.9817",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5647",
    WardLookupId: "1012",
    VDNumber: "22390051",
    RegisteredPopulation: "1184",
    VotingStationName: "REFENG KGOTSO PRE SCHOOL",
    Address: "EZENZELENI  WARDEN  WARDEN",
    Latitude: "-27.8416",
    Longitude: "28.9757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5648",
    WardLookupId: "1013",
    VDNumber: "22390017",
    RegisteredPopulation: "1109",
    VotingStationName: "WARDEN TOWN HALL",
    Address: "PIET RETIEF STREET  WARDEN",
    Latitude: "-27.8554",
    Longitude: "28.9643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5649",
    WardLookupId: "1013",
    VDNumber: "22390039",
    RegisteredPopulation: "1661",
    VotingStationName: "EZENZELENI COMMUNITY HALL",
    Address: "C/O TSHONGWE & MABANGA  EZENZELENI  WARDEN",
    Latitude: "-27.8356",
    Longitude: "28.9754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5650",
    WardLookupId: "1013",
    VDNumber: "22400165",
    RegisteredPopulation: "210",
    VotingStationName: "PAARDEPLAAS",
    Address: "PAARDEPLAAS  WARDEN",
    Latitude: "-27.7051",
    Longitude: "28.8785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5651",
    WardLookupId: "1013",
    VDNumber: "22430102",
    RegisteredPopulation: "170",
    VotingStationName: "BOOMSTRAAT FARM",
    Address: "BOOMSTRAAT  FARM  VREDE",
    Latitude: "-27.5246",
    Longitude: "28.9963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5652",
    WardLookupId: "1013",
    VDNumber: "22430113",
    RegisteredPopulation: "187",
    VotingStationName: "OUDEWONING PRIMARY SCHOOL",
    Address: "OUDEWONING  WARDEN  WARDEN",
    Latitude: "-27.6044",
    Longitude: "28.8023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5653",
    WardLookupId: "1014",
    VDNumber: "22290038",
    RegisteredPopulation: "1032",
    VotingStationName: "ESIZIBENI SECONDARY  SCHOOL",
    Address: "ZAMANI  MEMEL",
    Latitude: "-27.6644",
    Longitude: "29.5562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5654",
    WardLookupId: "1014",
    VDNumber: "22290050",
    RegisteredPopulation: "478",
    VotingStationName: "ST STEPHEN GENERAL APOSTOLIC CHURCH",
    Address: "FORSTER  ZAMANI  MEMEL",
    Latitude: "-27.6751",
    Longitude: "29.5502",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5655",
    WardLookupId: "1014",
    VDNumber: "22430034",
    RegisteredPopulation: "60",
    VotingStationName: "TENT AT BOOMPIE-ALLEAN",
    Address: "BOOMPIE ALLEAN  VREDE  VREDE",
    Latitude: "-27.305",
    Longitude: "28.9691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5656",
    WardLookupId: "1014",
    VDNumber: "22430045",
    RegisteredPopulation: "201",
    VotingStationName: "AANVANG FARM",
    Address: "AANVANG FARM  VREDE  VREDE",
    Latitude: "-27.3316",
    Longitude: "29.1791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5657",
    WardLookupId: "1014",
    VDNumber: "22430056",
    RegisteredPopulation: "242",
    VotingStationName: "TENT AT TWEEDEGELUK FARM",
    Address: "VREDE  TWEEDEGELUK FARM  VREDE",
    Latitude: "-27.4278",
    Longitude: "29.3836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5658",
    WardLookupId: "1014",
    VDNumber: "22430078",
    RegisteredPopulation: "57",
    VotingStationName: "SEEKOEVLEIPOORT GAME RESERVE",
    Address: "MEMEL  ",
    Latitude: "-27.6048",
    Longitude: "29.6077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5659",
    WardLookupId: "1014",
    VDNumber: "22430281",
    RegisteredPopulation: "225",
    VotingStationName: "UITZIEN INTERMEDIATE FARM SCHOOL",
    Address: "WELSTAND  PHUMELELA  VREDE",
    Latitude: "-27.2237",
    Longitude: "29.021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5660",
    WardLookupId: "1014",
    VDNumber: "22430348",
    RegisteredPopulation: "108",
    VotingStationName: "TENT AT ROBERSDRIFT FARM",
    Address: "ROBERSDRIFT  CORNELIA  CORNELIA",
    Latitude: "-27.0209",
    Longitude: "29.024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5661",
    WardLookupId: "3432",
    VDNumber: "22360014",
    RegisteredPopulation: "663",
    VotingStationName: "PAUL BERGMAN GEMEENSKAPSAAL",
    Address: "177 HOBHOUSEWEG  THABA PATCHOA  THABA PATCHOA",
    Latitude: "-29.3137",
    Longitude: "27.124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5662",
    WardLookupId: "3432",
    VDNumber: "22370015",
    RegisteredPopulation: "2076",
    VotingStationName: "TWEESPRUIT COMMUNITY HALL",
    Address: "00 TWEESPRUIT  LADYBRAND",
    Latitude: "-29.1943",
    Longitude: "27.0318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5663",
    WardLookupId: "3432",
    VDNumber: "22370026",
    RegisteredPopulation: "952",
    VotingStationName: "DAWIESVILLE HALL",
    Address: "NO STREET NAME  TWEESPRUIT  TWEESPRUIT",
    Latitude: "-29.1896",
    Longitude: "27.0401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5664",
    WardLookupId: "3433",
    VDNumber: "22220019",
    RegisteredPopulation: "2161",
    VotingStationName: "DIPELANENG COMMUNITY HALL",
    Address: "00 HOBHOUSE  LADYBRAND  HOBHOUSE",
    Latitude: "-29.5109",
    Longitude: "27.1518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5665",
    WardLookupId: "3433",
    VDNumber: "22410166",
    RegisteredPopulation: "132",
    VotingStationName: "BODIBENG BA THUTO SCHOOL",
    Address: "P.O. BOX 128  TWEESPRUIT  LADYBRAND",
    Latitude: "-29.3095",
    Longitude: "27.1396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5666",
    WardLookupId: "3433",
    VDNumber: "22410346",
    RegisteredPopulation: "179",
    VotingStationName: "MIDDELPUNT FARM",
    Address: "MIDDELPUNT FARM  HOBHOUSE  HOBHOUSE",
    Latitude: "-29.4016",
    Longitude: "27.2526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5667",
    WardLookupId: "3433",
    VDNumber: "22410368",
    RegisteredPopulation: "127",
    VotingStationName: "LANGLEY PARK FARM",
    Address: "LANGLEY PARK FARM  HOBHOUSE  MANTSOPA",
    Latitude: "-29.4202",
    Longitude: "27.1294",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5668",
    WardLookupId: "3433",
    VDNumber: "22410403",
    RegisteredPopulation: "321",
    VotingStationName: "NEW VALE SCHOOL",
    Address: "111 LADYBRAND   VENIES  LADYBRAND",
    Latitude: "-29.2511",
    Longitude: "27.3771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5669",
    WardLookupId: "3434",
    VDNumber: "22240022",
    RegisteredPopulation: "1284",
    VotingStationName: "MANYATSENG PRIMARY SCHOOL",
    Address: "00 LADYBRAND  ",
    Latitude: "-29.1696",
    Longitude: "27.4605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5670",
    WardLookupId: "3434",
    VDNumber: "22240077",
    RegisteredPopulation: "999",
    VotingStationName: "VUMANI OUTREACH CENTRE",
    Address: "2103 ARTHUR PITSO   MANYATSENG  LADYBRAND",
    Latitude: "-29.1771",
    Longitude: "27.4677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5671",
    WardLookupId: "3434",
    VDNumber: "22410155",
    RegisteredPopulation: "210",
    VotingStationName: "COENRAAD SNYMAN PRIMARY SCHOOL",
    Address: "MODDERPOORT  LADYBRAND",
    Latitude: "-29.1059",
    Longitude: "27.45",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5672",
    WardLookupId: "3434",
    VDNumber: "22410302",
    RegisteredPopulation: "159",
    VotingStationName: "SPITSKOP FARM SCHOOL",
    Address: "BOX 220 LADYBRAND  SPITSKOP FARM  LADYBRAND",
    Latitude: "-29.1028",
    Longitude: "27.5732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5673",
    WardLookupId: "3434",
    VDNumber: "22410414",
    RegisteredPopulation: "156",
    VotingStationName: "PHEDISANO FARM SCHOOL",
    Address: "LADYBRAND  VILJOEN FARM  LADYBRAND",
    Latitude: "-29.2648",
    Longitude: "27.4445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5674",
    WardLookupId: "3435",
    VDNumber: "22240033",
    RegisteredPopulation: "1288",
    VotingStationName: "UNITED CONGREGATIONAL CHURCH",
    Address: "1396 MUELLI STREET  MANYATSENG  LADYBRAND",
    Latitude: "-29.1699",
    Longitude: "27.4575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5675",
    WardLookupId: "3435",
    VDNumber: "22240088",
    RegisteredPopulation: "1003",
    VotingStationName: "CHANGING LIVES MINISTRIES",
    Address: "LUSAKA  MANYATSENG  LADYBRAND",
    Latitude: "-29.1676",
    Longitude: "27.4539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5676",
    WardLookupId: "3435",
    VDNumber: "22410481",
    RegisteredPopulation: "1253",
    VotingStationName: "TWELVE APOSTLES CHURCH",
    Address: "00 THABONG  LADYBRAND  MANYATSENG",
    Latitude: "-29.1577",
    Longitude: "27.4621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5677",
    WardLookupId: "3436",
    VDNumber: "22240055",
    RegisteredPopulation: "1932",
    VotingStationName: "IKETSETSENG CRECHE",
    Address: "MANYATSENG  LADYBRAND  MANYATSENG",
    Latitude: "-29.1689",
    Longitude: "27.4679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5678",
    WardLookupId: "3436",
    VDNumber: "22240101",
    RegisteredPopulation: "640",
    VotingStationName: "MANDELA PARK (TENT)",
    Address: "MANDELA PARK  MANYATSENG  LADYBRAND",
    Latitude: "-29.1663",
    Longitude: "27.4682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5679",
    WardLookupId: "3437",
    VDNumber: "22240044",
    RegisteredPopulation: "2154",
    VotingStationName: "TENT (NEXT TO SPORTS GROUND)",
    Address: "M1319 NEXT N.C.F.S SPORTS GROUND  MANYATSENG  LADYBRAND",
    Latitude: "-29.1651",
    Longitude: "27.4643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5680",
    WardLookupId: "3437",
    VDNumber: "22240112",
    RegisteredPopulation: "730",
    VotingStationName: "LUSAKA TENT NEXT TO MOHAU",
    Address: "2973 MANYATSENG  LUSAKA  LADYBRAND",
    Latitude: "-29.1608",
    Longitude: "27.4627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5681",
    WardLookupId: "3437",
    VDNumber: "22240123",
    RegisteredPopulation: "270",
    VotingStationName: "BETHELE CHURCH",
    Address: "MANYATSENG  LADYBRAND  MANYATSENG",
    Latitude: "-29.1654",
    Longitude: "27.4618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5682",
    WardLookupId: "3438",
    VDNumber: "22240011",
    RegisteredPopulation: "1660",
    VotingStationName: "YESHUA COLLEGE",
    Address: "00 LADYBRAND  ",
    Latitude: "-29.1863",
    Longitude: "27.4625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5683",
    WardLookupId: "3438",
    VDNumber: "22240066",
    RegisteredPopulation: "1213",
    VotingStationName: "HERMANA PRIMARY SCHOOL",
    Address: "2 OLIENHOUT STRAAT  MAUERSNEK  LADYBRAND",
    Latitude: "-29.1898",
    Longitude: "27.4397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5684",
    WardLookupId: "3438",
    VDNumber: "22240099",
    RegisteredPopulation: "371",
    VotingStationName: "LADYBRAND PRIMARY SCHOOL",
    Address: "COLLINS STREET  LADYBRAND  LADYBRAND",
    Latitude: "-29.2023",
    Longitude: "27.4552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5685",
    WardLookupId: "3438",
    VDNumber: "22240134",
    RegisteredPopulation: "980",
    VotingStationName: "LADYBRAND TOWN HALL",
    Address: "38 JOUBERT STREET  LADYBRAND  LADYBRAND",
    Latitude: "-29.1937",
    Longitude: "27.4587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5686",
    WardLookupId: "3439",
    VDNumber: "22190026",
    RegisteredPopulation: "735",
    VotingStationName: "EXCELSIOR TOWN HALL",
    Address: "COMMISSION STREET  LADYBRAND  EXCELSIOR",
    Latitude: "-28.9407",
    Longitude: "27.0615",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5687",
    WardLookupId: "3439",
    VDNumber: "22190037",
    RegisteredPopulation: "850",
    VotingStationName: "MARANATHA COMMUNITY CHURCH",
    Address: "1532 KOMA VILLAGE   MAHLATSWETSA  EXCELSIOR",
    Latitude: "-28.932",
    Longitude: "27.0522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5688",
    WardLookupId: "3439",
    VDNumber: "22410380",
    RegisteredPopulation: "414",
    VotingStationName: "WESTMINESTER SPORTS CLUB",
    Address: "HOBHOUSE / TWEESPRUIT  WESTMINISTER  WESTMINISTER",
    Latitude: "-29.1776",
    Longitude: "27.1457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5689",
    WardLookupId: "3439",
    VDNumber: "22410391",
    RegisteredPopulation: "365",
    VotingStationName: "MARSEILLES FARM HALL",
    Address: "LADYBRAND  ",
    Latitude: "-29.1875",
    Longitude: "27.2867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5690",
    WardLookupId: "3439",
    VDNumber: "22410436",
    RegisteredPopulation: "113",
    VotingStationName: "MAJWANA MABEDI SCHOOL",
    Address: "SAMEKOMS  EXCELSIOR  EXCELSIOR",
    Latitude: "-29.0309",
    Longitude: "27.0965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5691",
    WardLookupId: "3439",
    VDNumber: "22410470",
    RegisteredPopulation: "216",
    VotingStationName: "NG CHURCH",
    Address: "CHURCH STREET  TWEESPRUIT  TWEESPRUIT",
    Latitude: "-29.184",
    Longitude: "27.0296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5692",
    WardLookupId: "3440",
    VDNumber: "22190015",
    RegisteredPopulation: "994",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "MAHLATSWETSA  EXCELSIOR",
    Latitude: "-28.9327",
    Longitude: "27.0421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5693",
    WardLookupId: "3440",
    VDNumber: "22190048",
    RegisteredPopulation: "997",
    VotingStationName: "MAHLATSWETSA COMMUNITY HALL",
    Address: "MAHLATSWETA COMMUNITY HALL  MAHLATSWETSA  EXCELSIOR",
    Latitude: "-28.9344",
    Longitude: "27.0437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5694",
    WardLookupId: "3440",
    VDNumber: "22410313",
    RegisteredPopulation: "147",
    VotingStationName: "RUSTIG SCHOOL",
    Address: "CLOCOLAN  ",
    Latitude: "-29.0226",
    Longitude: "27.4726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5695",
    WardLookupId: "3440",
    VDNumber: "22410324",
    RegisteredPopulation: "154",
    VotingStationName: "REAMOHETSWE PRIMARY SCHOOL",
    Address: "CLOCOLAN  ",
    Latitude: "-28.9135",
    Longitude: "27.4557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5696",
    WardLookupId: "3440",
    VDNumber: "22410335",
    RegisteredPopulation: "164",
    VotingStationName: "MOOIMEISIESHOEK",
    Address: "MOOIMEISIESHOEK  EXCELSIOR   EXCELSIOR",
    Latitude: "-28.8279",
    Longitude: "27.2062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5697",
    WardLookupId: "3440",
    VDNumber: "22410379",
    RegisteredPopulation: "123",
    VotingStationName: "SANDSPRUIT SCHOOL",
    Address: "P.O. BOX 155  CLOCOLAN  CLOCOLAN",
    Latitude: "-29.0233",
    Longitude: "27.2551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5698",
    WardLookupId: "3441",
    VDNumber: "22350024",
    RegisteredPopulation: "1871",
    VotingStationName: "THAROLLO PRIMARY SCHOOL",
    Address: "94 MATLWANGTLWANG  MOQHAKA [KROONSTAD]",
    Latitude: "-27.939",
    Longitude: "27.5496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5699",
    WardLookupId: "3441",
    VDNumber: "22350046",
    RegisteredPopulation: "1459",
    VotingStationName: "TENT AT MATLWANGTLWANG COMMUNITY HALL",
    Address: "MATLWANNGTLWANG  STEYSRUS  STEYNSRUS",
    Latitude: "-27.9443",
    Longitude: "27.5525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5700",
    WardLookupId: "3441",
    VDNumber: "22350057",
    RegisteredPopulation: "649",
    VotingStationName: "MATLWANGLTLWANG CRECHE",
    Address: "1698 MPHEFELA  MATLWANGTLWANG  STEYNSRUS",
    Latitude: "-27.9457",
    Longitude: "27.544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5701",
    WardLookupId: "3442",
    VDNumber: "22350035",
    RegisteredPopulation: "577",
    VotingStationName: "STEYNSRUS TOWN HALL",
    Address: "13 VAN RIEBECK  STEYNSRUS  STEYNSRUS",
    Latitude: "-27.9527",
    Longitude: "27.564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5702",
    WardLookupId: "3442",
    VDNumber: "22400019",
    RegisteredPopulation: "246",
    VotingStationName: "MAKAWAANSBANK FARM SCHOOL",
    Address: "4 MAKAWAANSBANK FARM  STEYNSRUS",
    Latitude: "-28.1008",
    Longitude: "27.557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5703",
    WardLookupId: "3442",
    VDNumber: "22400020",
    RegisteredPopulation: "113",
    VotingStationName: "LELIEFONTEIN FARM",
    Address: "LELIEFONTEIN  SENEKAL  STEYNSRUS",
    Latitude: "-28.2376",
    Longitude: "27.4653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5704",
    WardLookupId: "3442",
    VDNumber: "22430168",
    RegisteredPopulation: "196",
    VotingStationName: "STEYNSRUST BOEREVEREENIGING",
    Address: "NORTH ON VAN RIEBECK STREET  STEYNSRUS  STEYNSRUS",
    Latitude: "-27.9339",
    Longitude: "27.579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5705",
    WardLookupId: "3442",
    VDNumber: "22810036",
    RegisteredPopulation: "252",
    VotingStationName: "PLAATBERG PRIMARY SCHOOL",
    Address: "69 S699  KROONSTAD  KROONSTAD",
    Latitude: "-27.777",
    Longitude: "27.1155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5706",
    WardLookupId: "3442",
    VDNumber: "22810058",
    RegisteredPopulation: "121",
    VotingStationName: "IS TEVREDE FARM SCHOOL",
    Address: "IRENE FARM  KROONSTAD  KROONSTAD",
    Latitude: "-27.8264",
    Longitude: "27.1862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5707",
    WardLookupId: "3442",
    VDNumber: "22810070",
    RegisteredPopulation: "201",
    VotingStationName: "MAKGETHA FARM SCHOOL",
    Address: "WELGENHOF  UITZOEK FARM  KROONSTAD",
    Latitude: "-27.7035",
    Longitude: "27.4571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5708",
    WardLookupId: "3442",
    VDNumber: "22810081",
    RegisteredPopulation: "113",
    VotingStationName: "WONDERKOP FARM SCHOOL",
    Address: "WONDERKOP FARM  KROONSTAD  KROONSTAD",
    Latitude: "-27.8586",
    Longitude: "27.4091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5709",
    WardLookupId: "3442",
    VDNumber: "22810160",
    RegisteredPopulation: "187",
    VotingStationName: "TENT NEXT MOOIWATER FARM OPEN SPACE",
    Address: "MOOIWATER FARM  KROONSTAD   KROONSTAD RURAL",
    Latitude: "-27.7416",
    Longitude: "27.0909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5710",
    WardLookupId: "3442",
    VDNumber: "22810171",
    RegisteredPopulation: "222",
    VotingStationName: "TENT AT LABRI FARM",
    Address: "R82 LABRI FARM  KROONSTAD  KROONSTAD",
    Latitude: "-27.5465",
    Longitude: "27.3468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5711",
    WardLookupId: "3442",
    VDNumber: "22810182",
    RegisteredPopulation: "93",
    VotingStationName: "TENT NEXT BERLIN FARM",
    Address: "CNR S165 AND S167  KROONSTAD  KROONSTAD",
    Latitude: "-27.9113",
    Longitude: "27.3472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5712",
    WardLookupId: "3442",
    VDNumber: "22810193",
    RegisteredPopulation: "323",
    VotingStationName: "RIVERSIDE FARM SHED",
    Address: "PLOT 1 RIVERSIDE FARM  KROONSTAD  KROONSTAD",
    Latitude: "-27.6607",
    Longitude: "27.2729",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5713",
    WardLookupId: "3442",
    VDNumber: "22810227",
    RegisteredPopulation: "91",
    VotingStationName: "WONDERKOP FARM SHED",
    Address: "WONDERKOP  WONDERKOP FARM  KROONSTAD",
    Latitude: "-27.8531",
    Longitude: "27.4222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5714",
    WardLookupId: "3443",
    VDNumber: "22720047",
    RegisteredPopulation: "1644",
    VotingStationName: "VOORWAARTS PRIMARY SCHOOL",
    Address: "HARTLEY STREET  MOREWAG  KROONSTAD",
    Latitude: "-27.6344",
    Longitude: "27.2246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5715",
    WardLookupId: "3443",
    VDNumber: "22720250",
    RegisteredPopulation: "724",
    VotingStationName: "HOÃ‹R LANDBOUSKOOL",
    Address: "NOORDWEG ILLANDIA  MOREWAG  KROONSTAD",
    Latitude: "-27.623",
    Longitude: "27.2323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5716",
    WardLookupId: "3443",
    VDNumber: "22720384",
    RegisteredPopulation: "1039",
    VotingStationName: "AFRIKAANS HOÃ‹RSKOOL",
    Address: "111 KROONSTAD  ",
    Latitude: "-27.637",
    Longitude: "27.2322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5717",
    WardLookupId: "3443",
    VDNumber: "22720395",
    RegisteredPopulation: "369",
    VotingStationName: "TENT AT BEN MERVIS STREET",
    Address: "MAOKENG  KROONSTAD",
    Latitude: "-27.6404",
    Longitude: "27.2245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5718",
    WardLookupId: "3444",
    VDNumber: "22720261",
    RegisteredPopulation: "1604",
    VotingStationName: "NTUTUBOLLE CRECHE",
    Address: "15452/7 SNAKE PARK  KROONSTAD  KROONSTAD",
    Latitude: "-27.6291",
    Longitude: "27.1922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5719",
    WardLookupId: "3444",
    VDNumber: "22720351",
    RegisteredPopulation: "1706",
    VotingStationName: "TENT AT KOEKOE VILLAGE",
    Address: "(FS201 - MOQHAKA [KROONSTAD])   ",
    Latitude: "-27.6317",
    Longitude: "27.1988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5720",
    WardLookupId: "3445",
    VDNumber: "22720238",
    RegisteredPopulation: "1517",
    VotingStationName: "CATHOLIC APOSTOLIC JERUSALEM CHURCH IN ZION",
    Address: "13825 SNAKE PARK  DINOHENG  KROONSTAD",
    Latitude: "-27.6287",
    Longitude: "27.1831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5721",
    WardLookupId: "3445",
    VDNumber: "22720283",
    RegisteredPopulation: "1134",
    VotingStationName: "IBANDLA LE NKOSI CHURCH",
    Address: "13329 KOEKOE  MOQHAKA [KROONSTAD]",
    Latitude: "-27.6294",
    Longitude: "27.1801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5722",
    WardLookupId: "3445",
    VDNumber: "22720407",
    RegisteredPopulation: "601",
    VotingStationName: "SOUL WINNING OUTREACH MINISTRIES CHURCH",
    Address: "14128 DINOHENG  KROONSTAD",
    Latitude: "-27.6246",
    Longitude: "27.1852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5723",
    WardLookupId: "3445",
    VDNumber: "22720418",
    RegisteredPopulation: "492",
    VotingStationName: "KGOTSONG CRECHE",
    Address: "13843 SNAKE PARK  DINOHENG  KROONSTAD",
    Latitude: "-27.6302",
    Longitude: "27.187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5724",
    WardLookupId: "3446",
    VDNumber: "22720036",
    RegisteredPopulation: "1927",
    VotingStationName: "MAOKENG PRIMARY SCHOOL",
    Address: "10372 STEENWERP  KOEKOE VILLAGE  MAOKENG KROONSTAD",
    Latitude: "-27.6357",
    Longitude: "27.1891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5725",
    WardLookupId: "3446",
    VDNumber: "22720328",
    RegisteredPopulation: "1393",
    VotingStationName: "PHEPHETSO SECONDARY SCHOOL",
    Address: "10765 KOEKOE  MOQHAKA [KROONSTAD]  MOQHAKA [KROONSTAD]",
    Latitude: "-27.634",
    Longitude: "27.1856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5726",
    WardLookupId: "3446",
    VDNumber: "22720429",
    RegisteredPopulation: "648",
    VotingStationName: "TENT AT SNAKE PARK",
    Address: "DINOHENG  SNAKE APRK  KROONSTAD",
    Latitude: "-27.6352",
    Longitude: "27.1929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5727",
    WardLookupId: "3447",
    VDNumber: "22720216",
    RegisteredPopulation: "1419",
    VotingStationName: "DR R CINGO SECONDARY SCHOOL",
    Address: "635 EDGAR PHAKOE STREET  GELUKWAARTS  MAOKENG KROONSTAD",
    Latitude: "-27.6372",
    Longitude: "27.1758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5728",
    WardLookupId: "3447",
    VDNumber: "22720339",
    RegisteredPopulation: "996",
    VotingStationName: "IKEMELENG CRECHE",
    Address: "13687 SNAKE PARK  MAOKENG  KOEKOE VILLAGE",
    Latitude: "-27.6317",
    Longitude: "27.1775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5729",
    WardLookupId: "3447",
    VDNumber: "22720430",
    RegisteredPopulation: "700",
    VotingStationName: "NYAKALLONG CONFERENCE CENTRE",
    Address: "BESTER  GELUKWAARTS  KROONSTAD",
    Latitude: "-27.637",
    Longitude: "27.1696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5730",
    WardLookupId: "3447",
    VDNumber: "22810159",
    RegisteredPopulation: "151",
    VotingStationName: "DOORNSPRUIT SCHOOL",
    Address: "DOORNSPRUIT FARM  KROONSTAD  KROONSTAD",
    Latitude: "-27.4978",
    Longitude: "27.1401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5731",
    WardLookupId: "3447",
    VDNumber: "22810205",
    RegisteredPopulation: "279",
    VotingStationName: "LOVEDALE FARM SCHOOL",
    Address: "LOVEDALE  LOVEDALE FARM  KROONSTAD",
    Latitude: "-27.6246",
    Longitude: "27.1646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5732",
    WardLookupId: "3448",
    VDNumber: "22720159",
    RegisteredPopulation: "819",
    VotingStationName: "BOIKEMISETSO PRIMARY SCHOOL",
    Address: "7399 PITSO  CONSTANTIA  MAOKENG KROONSTAD",
    Latitude: "-27.6405",
    Longitude: "27.1799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5733",
    WardLookupId: "3448",
    VDNumber: "22720160",
    RegisteredPopulation: "1045",
    VotingStationName: "CONSTANTIA HALL",
    Address: "NEXT TO BP GARAGE  CONSTANTIA  MAOKENG KROONSTAD",
    Latitude: "-27.6442",
    Longitude: "27.1827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5734",
    WardLookupId: "3448",
    VDNumber: "22720340",
    RegisteredPopulation: "962",
    VotingStationName: "HOLY PENTECOST APOSTOLIC CHURCH",
    Address: "862 MOGOJE  KROONSTAD  CONSTANTIA PARK",
    Latitude: "-27.6429",
    Longitude: "27.1792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5735",
    WardLookupId: "3449",
    VDNumber: "22720126",
    RegisteredPopulation: "1815",
    VotingStationName: "MPHOHADI FET COLLEGE",
    Address: "TANG STREET  GELUKWAARTS  KROONSTAD",
    Latitude: "-27.6439",
    Longitude: "27.1905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5736",
    WardLookupId: "3449",
    VDNumber: "22720137",
    RegisteredPopulation: "1177",
    VotingStationName: "REAITUMELA PRIMARY SCHOOL",
    Address: "6147 TEMPI  CONSTANTIA  MAOKENG KROONSTAD",
    Latitude: "-27.6442",
    Longitude: "27.1866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5737",
    WardLookupId: "3450",
    VDNumber: "22720148",
    RegisteredPopulation: "2063",
    VotingStationName: "RELEBOHILE PRIMARY SCHOOL",
    Address: "5209 KHIBA  MAOKENG(LTA)  KROONSTAD",
    Latitude: "-27.6508",
    Longitude: "27.1826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5738",
    WardLookupId: "3450",
    VDNumber: "22720362",
    RegisteredPopulation: "2081",
    VotingStationName: "MAXIMA BIBLE CHURCH",
    Address: "RELEBOHILE  KROONSTAD",
    Latitude: "-27.6547",
    Longitude: "27.1878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5739",
    WardLookupId: "3451",
    VDNumber: "22720081",
    RegisteredPopulation: "1108",
    VotingStationName: "MOEPENG INTERMEDIATE SCHOOL",
    Address:
      "1504 ROBERT SELLO STREET MAOKENG  (FS201 - MOQHAKA [KROONSTAD])   (FS201 - KROONSTAD",
    Latitude: "-27.6489",
    Longitude: "27.2036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5740",
    WardLookupId: "3451",
    VDNumber: "22720092",
    RegisteredPopulation: "814",
    VotingStationName: "FAITH MISSION CHURCH",
    Address: "1528 SESELE STREET  PHOMOLONG LOCATION  KROONSTAD",
    Latitude: "-27.6506",
    Longitude: "27.2012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5741",
    WardLookupId: "3451",
    VDNumber: "22720193",
    RegisteredPopulation: "1593",
    VotingStationName: "BODIBENG SECONDARY SCHOOL",
    Address: "16744 MARABASTAD LOCATION  SEEISOVILLE  MAOKENG KROONSTAD",
    Latitude: "-27.6453",
    Longitude: "27.2127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5742",
    WardLookupId: "3451",
    VDNumber: "22720441",
    RegisteredPopulation: "367",
    VotingStationName: "KANANELO SENIOR SECONDARY SCHOOL",
    Address: "1563 ROBERT SELLO STREET  PHOMOLONG  KROONSTAD",
    Latitude: "-27.6547",
    Longitude: "27.1984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5743",
    WardLookupId: "3452",
    VDNumber: "22720104",
    RegisteredPopulation: "1713",
    VotingStationName: "PHOMOLONG PRIMARY SCHOOL",
    Address: "SEWENDE LAAN  PHOMOLONG  MAOKENG KROONSTAD",
    Latitude: "-27.6519",
    Longitude: "27.195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5744",
    WardLookupId: "3452",
    VDNumber: "22720115",
    RegisteredPopulation: "1175",
    VotingStationName: "NTHA PRIMARY SCHOOL",
    Address: "3631 MONGANE STREET  CONSTANTIA PARK  MAOKENG KROONSTAD",
    Latitude: "-27.6478",
    Longitude: "27.1892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5745",
    WardLookupId: "3453",
    VDNumber: "22720182",
    RegisteredPopulation: "2012",
    VotingStationName: "BRENTPARK HIGH SCHOOL",
    Address: "BRIERLEY STREET  BRENTPARK  KROONSTAD",
    Latitude: "-27.666",
    Longitude: "27.1874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5746",
    WardLookupId: "3453",
    VDNumber: "22720294",
    RegisteredPopulation: "1165",
    VotingStationName: "SEEISIVILLE COMMUNITY HALL",
    Address: "SEEISOVILLE  MOQHAKA [KROONSTAD]",
    Latitude: "-27.6526",
    Longitude: "27.2105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5747",
    WardLookupId: "3454",
    VDNumber: "22720171",
    RegisteredPopulation: "1342",
    VotingStationName: "APOSTOLIC CHURCH",
    Address: "1935 GELUKWAARTS  MOQHAKA [KROONSTAD]",
    Latitude: "-27.6458",
    Longitude: "27.1684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5748",
    WardLookupId: "3454",
    VDNumber: "22720317",
    RegisteredPopulation: "1498",
    VotingStationName: "MOTSWELA SECONDARY SCHOOL",
    Address: "11481 CASWELL KOEKOE  GELUKWAARTS  MOQHAKA [KROONSTAD]",
    Latitude: "-27.6446",
    Longitude: "27.1726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5749",
    WardLookupId: "3455",
    VDNumber: "22720070",
    RegisteredPopulation: "1655",
    VotingStationName: "SEEISOVILLE PRIMARY SCHOOL",
    Address: "1242 MAHABANE  SEEISOVILLE  MAOKENG KROONSTAD",
    Latitude: "-27.6545",
    Longitude: "27.2043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5750",
    WardLookupId: "3455",
    VDNumber: "22720306",
    RegisteredPopulation: "841",
    VotingStationName: "PHULENG  PRIMARY SCHOOL",
    Address: "1500 THULO  SEEISOVILLE  MOQHAKA [KROONSTAD]",
    Latitude: "-27.648",
    Longitude: "27.2119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5751",
    WardLookupId: "3455",
    VDNumber: "22720485",
    RegisteredPopulation: "368",
    VotingStationName: "ZENZELE YWCA PRE-SCHOOL",
    Address: "HELEPI STREET  SEEISOVILLE  KROONSTAD",
    Latitude: "-27.6524",
    Longitude: "27.2022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5752",
    WardLookupId: "3456",
    VDNumber: "22720014",
    RegisteredPopulation: "1820",
    VotingStationName: "NAUDE SAAL",
    Address: "TURNER STREET  KROONHEUWEL  KROONSTAD",
    Latitude: "-27.6481",
    Longitude: "27.2346",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5753",
    WardLookupId: "3456",
    VDNumber: "22720373",
    RegisteredPopulation: "1252",
    VotingStationName: "FLAVIUS MAREKA FET COLLEGE",
    Address: "BEUKES STREET  KROONSTAD   KROONSTAD",
    Latitude: "-27.6594",
    Longitude: "27.2446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5754",
    WardLookupId: "3457",
    VDNumber: "22720025",
    RegisteredPopulation: "1529",
    VotingStationName: "KROON PRIMARY SCHOOL HALL",
    Address: "BOOYSEN STREET  SUIDRAND  KROONSTAD",
    Latitude: "-27.6818",
    Longitude: "27.2328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5755",
    WardLookupId: "3457",
    VDNumber: "22720058",
    RegisteredPopulation: "1631",
    VotingStationName: "CONVENT NOTRE DAME",
    Address: "KAREE STRAAT  JORDANIA  KROONSTAD",
    Latitude: "-27.6582",
    Longitude: "27.2599",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5756",
    WardLookupId: "3457",
    VDNumber: "22720463",
    RegisteredPopulation: "822",
    VotingStationName: "TENT AT ROBB STREET",
    Address: "ROBB STREET  WILGENHOH  KROONSTAD",
    Latitude: "-27.6765",
    Longitude: "27.2452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5757",
    WardLookupId: "3458",
    VDNumber: "22650409",
    RegisteredPopulation: "153",
    VotingStationName: "KOEBERG FARM SCHOOL",
    Address: "FARM  KOEBERG  BOTHAVILLE",
    Latitude: "-27.3025",
    Longitude: "26.7884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5758",
    WardLookupId: "3458",
    VDNumber: "22770019",
    RegisteredPopulation: "1084",
    VotingStationName: "NTSOANATSATSI MIDDLE SCHOOL",
    Address: "220 NTLABA STR  RAMMULOTSI VILJOENSKROON  KROONSTAD",
    Latitude: "-27.1956",
    Longitude: "26.952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5759",
    WardLookupId: "3458",
    VDNumber: "22770053",
    RegisteredPopulation: "1367",
    VotingStationName: "REHAUHETSWE HIGH SCHOOL",
    Address: "2658 RAMMULOTSI  VILJOENSKROON  KROONSTAD",
    Latitude: "-27.1842",
    Longitude: "26.9465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5760",
    WardLookupId: "3458",
    VDNumber: "22770097",
    RegisteredPopulation: "508",
    VotingStationName: "ADELINE MEJE PRIMARY SCHOOL",
    Address: "MOLETSANE STREET  RAMMULOTSI  VILJOENSKROON",
    Latitude: "-27.1873",
    Longitude: "26.9476",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5761",
    WardLookupId: "3458",
    VDNumber: "22800035",
    RegisteredPopulation: "198",
    VotingStationName: "HUNTERSVLEI PRIMARY SCHOOL",
    Address: "R59 ROAD  FARM  VILJOENSKROON",
    Latitude: "-27.2125",
    Longitude: "26.8968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5762",
    WardLookupId: "3458",
    VDNumber: "22800079",
    RegisteredPopulation: "139",
    VotingStationName: "KRUISPAN SCHOOL",
    Address: "KRUISPAN  VILJOENSKROON",
    Latitude: "-27.14",
    Longitude: "26.8775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5763",
    WardLookupId: "3458",
    VDNumber: "22800204",
    RegisteredPopulation: "144",
    VotingStationName: "GEMEENSKAPSAAL",
    Address: "GELUKSDAM  VILJOENSKROON",
    Latitude: "-27.2546",
    Longitude: "26.8817",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5764",
    WardLookupId: "3459",
    VDNumber: "22770020",
    RegisteredPopulation: "1318",
    VotingStationName: "RENYAKALLETSE MIDDLE SCHOOL",
    Address: "1735 RAMMULOTSI  VILJOENSKROON  KROONSTAD",
    Latitude: "-27.1874",
    Longitude: "26.9508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5765",
    WardLookupId: "3459",
    VDNumber: "22770075",
    RegisteredPopulation: "1834",
    VotingStationName: "KGOLAGANO SECONDARY SCHOOL",
    Address: "4166 NEW LOCATION  RAMMULOTSI  VILJOENSKROON",
    Latitude: "-27.1785",
    Longitude: "26.9544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5766",
    WardLookupId: "3459",
    VDNumber: "22770109",
    RegisteredPopulation: "474",
    VotingStationName: "TENT AT MK SECTION",
    Address: "MOQHAKA   RAMMULOTSI  VILJOENSKROON",
    Latitude: "-27.181",
    Longitude: "26.9499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5767",
    WardLookupId: "3460",
    VDNumber: "22770086",
    RegisteredPopulation: "1657",
    VotingStationName: "KGABARENG SECONDARY SCHOOL",
    Address: "4916 KHAYA SECTION  RAMMULOTSI  VILJOENSKROON",
    Latitude: "-27.1945",
    Longitude: "26.9592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5768",
    WardLookupId: "3460",
    VDNumber: "22770110",
    RegisteredPopulation: "925",
    VotingStationName: "THE PRAYER HOUSE",
    Address: "RAMMULOTSI  VILJOENSKROON",
    Latitude: "-27.1987",
    Longitude: "26.9547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5769",
    WardLookupId: "3461",
    VDNumber: "22770031",
    RegisteredPopulation: "1824",
    VotingStationName: "THABANG HIGH SCHOOL",
    Address: "420 N/A  RAMMULOTSI  VILJOENSKROON",
    Latitude: "-27.1854",
    Longitude: "26.956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5770",
    WardLookupId: "3461",
    VDNumber: "22770042",
    RegisteredPopulation: "1253",
    VotingStationName: "TSHEPAHALO COMMUNITY HALL",
    Address: "931 RAMANAMANE  RAMMULOTSI  VILJOENSKROON",
    Latitude: "-27.1907",
    Longitude: "26.9543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5771",
    WardLookupId: "3461",
    VDNumber: "22800013",
    RegisteredPopulation: "96",
    VotingStationName: "SKAAP PLAAS",
    Address: "S699  VREDEFORT  VREDEFORT",
    Latitude: "-27.0871",
    Longitude: "27.2116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5772",
    WardLookupId: "3461",
    VDNumber: "22800024",
    RegisteredPopulation: "53",
    VotingStationName: "RAATHSKOPPIE",
    Address: "672  VREDEFORT  VREDEFORT",
    Latitude: "-26.9982",
    Longitude: "27.2486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5773",
    WardLookupId: "3461",
    VDNumber: "22800080",
    RegisteredPopulation: "142",
    VotingStationName: "VAN DER WALTSRUS FARM",
    Address: "1621 VREDEFORT  VREDEFORT  VREDEFORT",
    Latitude: "-26.9541",
    Longitude: "27.1079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5774",
    WardLookupId: "3461",
    VDNumber: "22800091",
    RegisteredPopulation: "103",
    VotingStationName: "MAKVOEL FARM SCHOOL",
    Address: "MAKSTRUISVOGEL  FARM  VILJOENSKROON",
    Latitude: "-26.9588",
    Longitude: "26.9485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5775",
    WardLookupId: "3461",
    VDNumber: "22800136",
    RegisteredPopulation: "96",
    VotingStationName: "SEOTLO PRIMARY SCHOOL",
    Address: "R 59  VREDEFORT",
    Latitude: "-27.0964",
    Longitude: "27.3153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5776",
    WardLookupId: "3461",
    VDNumber: "22800147",
    RegisteredPopulation: "65",
    VotingStationName: "TURFHOEK FARM",
    Address: "VREDEFORT  ",
    Latitude: "-27.2383",
    Longitude: "27.3082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5777",
    WardLookupId: "3461",
    VDNumber: "22800169",
    RegisteredPopulation: "88",
    VotingStationName: "SANDFONTEIN SCHOOL",
    Address: "SANDFONTEIN  MOQHAKA",
    Latitude: "-27.1003",
    Longitude: "26.9297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5778",
    WardLookupId: "3462",
    VDNumber: "22650443",
    RegisteredPopulation: "302",
    VotingStationName: "WINSTON FARM SCHOOL",
    Address: "946 WINSTON  VILJOENSKROON  VILJOENSKROON",
    Latitude: "-27.1368",
    Longitude: "26.7046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5779",
    WardLookupId: "3462",
    VDNumber: "22800046",
    RegisteredPopulation: "980",
    VotingStationName: "VIERFONTEIN COMMUNITY HALL",
    Address: "CIVIC CENTRE  VIERFONTEIN",
    Latitude: "-27.0972",
    Longitude: "26.7758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5780",
    WardLookupId: "3462",
    VDNumber: "22800068",
    RegisteredPopulation: "217",
    VotingStationName: "BOTSHONG SKOOL",
    Address: "SWARTFONTEIN PLAAS  VILJOENSKROON",
    Latitude: "-27.1434",
    Longitude: "26.7486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5781",
    WardLookupId: "3462",
    VDNumber: "22800215",
    RegisteredPopulation: "1036",
    VotingStationName: "KOPANANG MINE",
    Address: "VAAL REEFS  ORKNEY",
    Latitude: "-26.976",
    Longitude: "26.7451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5782",
    WardLookupId: "3462",
    VDNumber: "22800248",
    RegisteredPopulation: "729",
    VotingStationName: "GREAT NOLIGWA MINE HALL",
    Address: "VAAL REEFS SHAFT 08  ORKNEY  ORKNEY",
    Latitude: "-26.9568",
    Longitude: "26.7846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5783",
    WardLookupId: "3463",
    VDNumber: "22650386",
    RegisteredPopulation: "171",
    VotingStationName: "WELMANSRUS PLAASSAAL",
    Address: "BOTHAVILLE  KROONSTAD",
    Latitude: "-27.4108",
    Longitude: "26.7796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5784",
    WardLookupId: "3463",
    VDNumber: "22770064",
    RegisteredPopulation: "1662",
    VotingStationName: "VILJOENSKROON MUNICIPALITY TOWN HALL",
    Address: "27 DENYSSEN STR  VILJOENSKROON  KROONSTAD",
    Latitude: "-27.209",
    Longitude: "26.9462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5785",
    WardLookupId: "3463",
    VDNumber: "22800057",
    RegisteredPopulation: "1623",
    VotingStationName: "NORTHLEY COMMUNITY HALL",
    Address: "NORTHLEY PLAAS  VILJOENSKROON",
    Latitude: "-27.2038",
    Longitude: "26.9647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5786",
    WardLookupId: "3463",
    VDNumber: "22800158",
    RegisteredPopulation: "94",
    VotingStationName: "ESPERANZA FARM SCHOOL",
    Address: "947 ESPERANZA FARM  VILJOENSKROON  VILJOENSKROON",
    Latitude: "-27.1539",
    Longitude: "27.0948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5787",
    WardLookupId: "3463",
    VDNumber: "22800170",
    RegisteredPopulation: "135",
    VotingStationName: "NUGGET SKOOL",
    Address: "NUGGET FARM  VILJOENSKROON",
    Latitude: "-27.3276",
    Longitude: "27.0249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5788",
    WardLookupId: "3463",
    VDNumber: "22800181",
    RegisteredPopulation: "182",
    VotingStationName: "SWAARTPAN PRE-SCHOOL",
    Address: "730 SWAARTPAN  VILJOENSKROON  VILJOENSKROON",
    Latitude: "-27.3682",
    Longitude: "27.0859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5789",
    WardLookupId: "3463",
    VDNumber: "22800192",
    RegisteredPopulation: "185",
    VotingStationName: "GRASSMERE SKOOL",
    Address: "GRASSMERE  VILJOENSKROON",
    Latitude: "-27.3491",
    Longitude: "26.9697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5790",
    WardLookupId: "3463",
    VDNumber: "22800259",
    RegisteredPopulation: "427",
    VotingStationName: "DINALEDI CRECHE",
    Address: "6825 RAMULOTSI  VILJOENSKROON",
    Latitude: "-27.1943",
    Longitude: "26.9696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5791",
    WardLookupId: "3463",
    VDNumber: "22810025",
    RegisteredPopulation: "69",
    VotingStationName: "TENT AT SPYTFONTEIN FARM",
    Address: "639 SPYTFONTEIN  SPYTFONTEIN FARM  KROONSTAD",
    Latitude: "-27.5358",
    Longitude: "26.9753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5792",
    WardLookupId: "1015",
    VDNumber: "22700067",
    RegisteredPopulation: "1365",
    VotingStationName: "SANDERSVILLE HALL",
    Address: "VAN STADEN STR  SANDERSVILLE  HEILBRON",
    Latitude: "-27.2756",
    Longitude: "27.9647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5793",
    WardLookupId: "1015",
    VDNumber: "22700124",
    RegisteredPopulation: "494",
    VotingStationName: "GEREFORMEERDE KERK PHIRITONA",
    Address: "DI-ESENG PHIRITONA  PHIRITONA  HEILBRON",
    Latitude: "-27.2637",
    Longitude: "27.9652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5794",
    WardLookupId: "1015",
    VDNumber: "22810126",
    RegisteredPopulation: "124",
    VotingStationName: "HONINGKLOOF FARM",
    Address: "HONING KLOOF FARM  GREENLANES   KOPPIES",
    Latitude: "-27.1304",
    Longitude: "27.7066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5795",
    WardLookupId: "1015",
    VDNumber: "22810137",
    RegisteredPopulation: "140",
    VotingStationName: "KOPPIES FARMERS WINKEL",
    Address: "KOPPIES DAM  KOPPIES   KOPPIES",
    Latitude: "-27.2534",
    Longitude: "27.6621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5796",
    WardLookupId: "1015",
    VDNumber: "22820093",
    RegisteredPopulation: "131",
    VotingStationName: "TENT NEXT VYANDSVLEI FARM",
    Address: "HEILBRON FARM  HEILBRON",
    Latitude: "-27.038",
    Longitude: "27.9706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5797",
    WardLookupId: "1015",
    VDNumber: "22820105",
    RegisteredPopulation: "148",
    VotingStationName: "OORSPRONG-OOS FARM",
    Address: "FARM  HEILBRON  HEILBRON",
    Latitude: "-27.1437",
    Longitude: "27.9525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5798",
    WardLookupId: "1015",
    VDNumber: "22820116",
    RegisteredPopulation: "199",
    VotingStationName: "PALMIETKUIL FARM SHED",
    Address: "PALMIETKUIL FARM  HEILBRON  HEILBRON",
    Latitude: "-27.2754",
    Longitude: "27.8588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5799",
    WardLookupId: "1015",
    VDNumber: "22820172",
    RegisteredPopulation: "125",
    VotingStationName: "BLOEMHOF FARM SCHOOL",
    Address: "BLOEMHOF FARM  HEILBRON  HEILBRON",
    Latitude: "-27.3144",
    Longitude: "28.0682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5800",
    WardLookupId: "1015",
    VDNumber: "22820183",
    RegisteredPopulation: "128",
    VotingStationName: "THABANTWA FARM SCHOOL",
    Address: "HERTZOG FARM  HEILBRON  HEILBRON",
    Latitude: "-27.4699",
    Longitude: "27.909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5801",
    WardLookupId: "1015",
    VDNumber: "22820194",
    RegisteredPopulation: "215",
    VotingStationName: "DIPKRAAL FARM SCHOOL",
    Address: "MOEDHOU  HEILBRON  HEILBRON",
    Latitude: "-27.2144",
    Longitude: "28.2289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5802",
    WardLookupId: "1015",
    VDNumber: "22820206",
    RegisteredPopulation: "158",
    VotingStationName: "STRATFORD SCHOOL",
    Address: "TOT HIER TOE  HEILBRON  HEILBRON",
    Latitude: "-27.361",
    Longitude: "28.0004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5803",
    WardLookupId: "1015",
    VDNumber: "22820217",
    RegisteredPopulation: "127",
    VotingStationName: "ARCADIA FARM",
    Address: "ARCADIA  HEILBRON  HEILBRON",
    Latitude: "-27.479",
    Longitude: "28.2107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5804",
    WardLookupId: "1015",
    VDNumber: "22820273",
    RegisteredPopulation: "42",
    VotingStationName: "HLWEKO FARM",
    Address: "KAALFONTEIN  HEILBRON  NGWATHE",
    Latitude: "-27.5395",
    Longitude: "27.9812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5805",
    WardLookupId: "1016",
    VDNumber: "22700023",
    RegisteredPopulation: "1466",
    VotingStationName: "HEILBRON HOÃ‹RSKOOL",
    Address: "HEILBRON  ",
    Latitude: "-27.2943",
    Longitude: "27.9671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5806",
    WardLookupId: "1016",
    VDNumber: "22700034",
    RegisteredPopulation: "1090",
    VotingStationName: "PHIRITONA TOWN HALL",
    Address: "DIPHARE STREET  PHIRITONA  HEILBRON",
    Latitude: "-27.2785",
    Longitude: "27.9736",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5807",
    WardLookupId: "1016",
    VDNumber: "22700113",
    RegisteredPopulation: "759",
    VotingStationName: "PHIRIHADI PRIMARY SCHOOL",
    Address: "1002 LIEIEE STREET  PHIRITONA  HEILBRON",
    Latitude: "-27.2689",
    Longitude: "27.9747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5808",
    WardLookupId: "1017",
    VDNumber: "22700056",
    RegisteredPopulation: "1514",
    VotingStationName: "KEARABETSWE PRIMARY SCHOOL",
    Address: "2240 LENNON STREET  PHIRITONA  HEILBRON",
    Latitude: "-27.2644",
    Longitude: "27.9753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5809",
    WardLookupId: "1017",
    VDNumber: "22700090",
    RegisteredPopulation: "894",
    VotingStationName: "CHRIST THE ROCK COMMUNITY CHURCH",
    Address: "PHAHAMENG SECTION  PHIRITONA  HEILBRON",
    Latitude: "-27.2575",
    Longitude: "27.9707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5810",
    WardLookupId: "1018",
    VDNumber: "22700012",
    RegisteredPopulation: "1164",
    VotingStationName: "PHIRITONA PUBLIC LIBRARY",
    Address: "VAN DER LINDE STR  PHIRITONA  HEILBRON",
    Latitude: "-27.2729",
    Longitude: "27.9776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5811",
    WardLookupId: "1018",
    VDNumber: "22700089",
    RegisteredPopulation: "951",
    VotingStationName: "TENT MOUNTAIN-VIEW GROUND TEMPORARY",
    Address: "PHIRITONA  HEILBRON",
    Latitude: "-27.2661",
    Longitude: "27.9825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5812",
    WardLookupId: "1018",
    VDNumber: "22700102",
    RegisteredPopulation: "884",
    VotingStationName: "UNITING REFORMED CHURCH",
    Address: "2941 PHIRITONA  MAPETLA SECTION  HEILBRON",
    Latitude: "-27.2734",
    Longitude: "27.9831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5813",
    WardLookupId: "1019",
    VDNumber: "22700045",
    RegisteredPopulation: "1422",
    VotingStationName: "SEDIBA THUTO HIGH SCHOOL",
    Address: "KUAPE STREET  PHIRITONA  HEILBRON",
    Latitude: "-27.265",
    Longitude: "27.9807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5814",
    WardLookupId: "1019",
    VDNumber: "22820295",
    RegisteredPopulation: "1261",
    VotingStationName: "APOSTOLI TWELVE CHURCH",
    Address: "AIRPORT  PHIRITONA  HEILBRON",
    Latitude: "-27.2714",
    Longitude: "27.9895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5815",
    WardLookupId: "1019",
    VDNumber: "22820307",
    RegisteredPopulation: "1490",
    VotingStationName: "NALEDI EDUCARE",
    Address: "PHIRITONA  HEILBRON",
    Latitude: "-27.2567",
    Longitude: "27.9756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5816",
    WardLookupId: "1020",
    VDNumber: "22740050",
    RegisteredPopulation: "1473",
    VotingStationName: "BOITLAMO SECONDARY SCHOOL",
    Address: "STAND 8912 MANDELA  TUMAHOLE  PARYS",
    Latitude: "-26.9219",
    Longitude: "27.4958",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5817",
    WardLookupId: "1020",
    VDNumber: "22740061",
    RegisteredPopulation: "2330",
    VotingStationName: "MASTER NAKEDI SPORT COMPLEX",
    Address: "MANDELA SECTION  PARYS  TUMAHOLE",
    Latitude: "-26.9187",
    Longitude: "27.49",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5818",
    WardLookupId: "1020",
    VDNumber: "22740218",
    RegisteredPopulation: "252",
    VotingStationName: "GOSPEL MINISTRIES TUMAHOLE",
    Address: "LUSAKA  TUMAHOLE  PARYS",
    Latitude: "-26.9233",
    Longitude: "27.4856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5819",
    WardLookupId: "1021",
    VDNumber: "22740049",
    RegisteredPopulation: "901",
    VotingStationName: "FREE ETHIOPIAN CHURCH OF SOUTH AFRICA",
    Address: "10312 TOKOLOHO SECTION  TUMAHOLE  PARYS",
    Latitude: "-26.9135",
    Longitude: "27.4844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5820",
    WardLookupId: "1021",
    VDNumber: "22740151",
    RegisteredPopulation: "2294",
    VotingStationName: "INTERNATIONAL ASSEMBLES OF GOD",
    Address: "109351 TAMBO SECTION  TUMAHOLE  PARYS",
    Latitude: "-26.9159",
    Longitude: "27.4938",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5821",
    WardLookupId: "1021",
    VDNumber: "22740173",
    RegisteredPopulation: "827",
    VotingStationName: "EMMANUEL FELLOWSHIP CENTRE",
    Address: "1 ZUMA  TUMAHOLE  PARYS",
    Latitude: "-26.9101",
    Longitude: "27.4853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5822",
    WardLookupId: "1021",
    VDNumber: "22740195",
    RegisteredPopulation: "317",
    VotingStationName: "TENT NEXT TO PARYS HOSPITAL",
    Address: "1596 HOSPITALWEG  PARYS  PARYS",
    Latitude: "-26.8963",
    Longitude: "27.4726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5823",
    WardLookupId: "1021",
    VDNumber: "22800103",
    RegisteredPopulation: "168",
    VotingStationName: "MOOIHOEK FARM",
    Address: "MOOIHOEK  PARYS  PARYS",
    Latitude: "-26.8543",
    Longitude: "27.5988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5824",
    WardLookupId: "1021",
    VDNumber: "22800114",
    RegisteredPopulation: "165",
    VotingStationName: "WEIVELD  AGRICULTURE AND HOTEL SCHOOL",
    Address: "GANSKUIL FARM  FARM  PARYS",
    Latitude: "-26.9732",
    Longitude: "27.6168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5825",
    WardLookupId: "1021",
    VDNumber: "22800125",
    RegisteredPopulation: "82",
    VotingStationName: "BOHOLONG FARM  SCHOOL",
    Address: "GROOTFONTEIN  FARM  PARYS",
    Latitude: "-26.9101",
    Longitude: "27.6511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5826",
    WardLookupId: "1022",
    VDNumber: "22790022",
    RegisteredPopulation: "1433",
    VotingStationName: "VREDEFORT TOWN HALL",
    Address: "C/O VRY & KERK STREET  VREDEFORT  VREDEFORT",
    Latitude: "-27.0073",
    Longitude: "27.3675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5827",
    WardLookupId: "1022",
    VDNumber: "22800237",
    RegisteredPopulation: "98",
    VotingStationName: "INLANDSEE FARM",
    Address: "INLANDSEE FARM  VREDEFORT  VREDEFORT",
    Latitude: "-27.0511",
    Longitude: "27.5073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5828",
    WardLookupId: "1022",
    VDNumber: "22810047",
    RegisteredPopulation: "113",
    VotingStationName: "SERFONTEIN FARM PRIMARY SCHOOL",
    Address: "SERFONTEIN SAAILINGE  KOPPIES  KOPPIES",
    Latitude: "-27.3838",
    Longitude: "27.4593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5829",
    WardLookupId: "1022",
    VDNumber: "22810069",
    RegisteredPopulation: "110",
    VotingStationName: "OUERSGIFT FARM",
    Address: "OUERSGIFT FARM  EDENVILLE  EDENVILLE",
    Latitude: "-27.5634",
    Longitude: "27.6092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5830",
    WardLookupId: "1022",
    VDNumber: "22810092",
    RegisteredPopulation: "185",
    VotingStationName: "SEIPONE FARM",
    Address: "WELVAART FARM  KROONSTAD  KROONSTAD",
    Latitude: "-27.3881",
    Longitude: "27.2161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5831",
    WardLookupId: "1022",
    VDNumber: "22810104",
    RegisteredPopulation: "259",
    VotingStationName: "ROOIWAL FARM",
    Address: "ROOIWAL  FARM  KOPPIES",
    Latitude: "-27.2995",
    Longitude: "27.523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5832",
    WardLookupId: "1022",
    VDNumber: "22810115",
    RegisteredPopulation: "161",
    VotingStationName: "ROOIPORTDAM  FARM",
    Address: "ROOIPORTDAM  FARM  KOPPIES",
    Latitude: "-27.2145",
    Longitude: "27.5203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5833",
    WardLookupId: "1022",
    VDNumber: "22810148",
    RegisteredPopulation: "138",
    VotingStationName: "TABOR FARM PRIMARY SCHOOL",
    Address: "TABOR FARM  FARM  KOPIES",
    Latitude: "-27.3399",
    Longitude: "27.657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5834",
    WardLookupId: "1022",
    VDNumber: "22810216",
    RegisteredPopulation: "106",
    VotingStationName: "NOKANAPEDI FARM SCHOOL",
    Address: "PARDEKRAAL FARM  FARM  EDENVILLE",
    Latitude: "-27.5093",
    Longitude: "27.6839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5835",
    WardLookupId: "1023",
    VDNumber: "22740139",
    RegisteredPopulation: "1599",
    VotingStationName: "BARNARD MOLOKOANE SECONDARY SCHOOL",
    Address: "3442 SEKOTIPHOLA SECTION  TUMAHOLE  PARYS",
    Latitude: "-26.9162",
    Longitude: "27.4858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5836",
    WardLookupId: "1023",
    VDNumber: "22740140",
    RegisteredPopulation: "1856",
    VotingStationName: "NTSHWEPHEPA PRIMÃŠRE SKOOL",
    Address: "8097 LUSAKA SECTION  TUMAHOLE  PARYS",
    Latitude: "-26.9279",
    Longitude: "27.4844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5837",
    WardLookupId: "1024",
    VDNumber: "22740038",
    RegisteredPopulation: "1051",
    VotingStationName: "MOSEPIDI COMMUNITY HALL",
    Address: "STAND 1098  TUMAHOLE  PARYS",
    Latitude: "-26.9214",
    Longitude: "27.4734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5838",
    WardLookupId: "1024",
    VDNumber: "22740072",
    RegisteredPopulation: "1513",
    VotingStationName: "TSWELOPELE PRE-SCHOOL",
    Address: "3137 SKOTIPHOLA SECTION  TUMAHOLE  PARYS",
    Latitude: "-26.9159",
    Longitude: "27.4806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5839",
    WardLookupId: "1024",
    VDNumber: "22740128",
    RegisteredPopulation: "1389",
    VotingStationName: "PHEHELLANG SECONDARY SCHOOL",
    Address: "1054 BROWN STREET  TUMAHOLE  PARYS",
    Latitude: "-26.9195",
    Longitude: "27.482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5840",
    WardLookupId: "1025",
    VDNumber: "22740083",
    RegisteredPopulation: "1375",
    VotingStationName: "AHA-SETJHABA PRIMARY SCHOOL",
    Address: "4955 SISULU SECTION  TUMAHOLE  PARYS",
    Latitude: "-26.9287",
    Longitude: "27.4736",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5841",
    WardLookupId: "1025",
    VDNumber: "22740094",
    RegisteredPopulation: "1916",
    VotingStationName: "BOTJHABATSATSI PRIMARY SCHOOL",
    Address: "1500 DABI STREET  TUMAHOLE  PARYS",
    Latitude: "-26.9252",
    Longitude: "27.4771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5842",
    WardLookupId: "1026",
    VDNumber: "22740027",
    RegisteredPopulation: "1716",
    VotingStationName: "PARYS LIBRARY",
    Address: "886 SCHILBACH/ PHILLIP STR  PARYS  PARYS",
    Latitude: "-26.9033",
    Longitude: "27.4623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5843",
    WardLookupId: "1026",
    VDNumber: "22740117",
    RegisteredPopulation: "1687",
    VotingStationName: "PARYS TOWN HALL",
    Address: "LIEBENBERGTREK  PARYS  PARYS",
    Latitude: "-26.8995",
    Longitude: "27.4569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5844",
    WardLookupId: "1026",
    VDNumber: "22740184",
    RegisteredPopulation: "859",
    VotingStationName: "AME ST LEORNARD CHURCH",
    Address: "64 MABONA ST  TUMAHOLE  PARYS",
    Latitude: "-26.9186",
    Longitude: "27.4699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5845",
    WardLookupId: "1026",
    VDNumber: "22740229",
    RegisteredPopulation: "392",
    VotingStationName: "NED.GEREF KERK PARYS SUID",
    Address: "DELVER  VAALPARYS  PARYS",
    Latitude: "-26.9142",
    Longitude: "27.4548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5846",
    WardLookupId: "1027",
    VDNumber: "22740106",
    RegisteredPopulation: "1713",
    VotingStationName: "SCHONKENVILLE HALL",
    Address: "1 SESDESTRAAT  SCHONKENVILLE  PARYS",
    Latitude: "-26.9326",
    Longitude: "27.4645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5847",
    WardLookupId: "1027",
    VDNumber: "22740162",
    RegisteredPopulation: "1313",
    VotingStationName: "THE NEW KHOPOTSO CHURCH OF GOD IN SA",
    Address: "7212 SIZULU  TUMAHOLE  PARYS",
    Latitude: "-26.9296",
    Longitude: "27.471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5848",
    WardLookupId: "1027",
    VDNumber: "22740230",
    RegisteredPopulation: "134",
    VotingStationName: "BASA FACTORY",
    Address: "CORNER CARL PRELLER AND THOMPS  NGWATHE MUNICPALITY  PARYS",
    Latitude: "-26.9222",
    Longitude: "27.4586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5849",
    WardLookupId: "1028",
    VDNumber: "22740207",
    RegisteredPopulation: "567",
    VotingStationName: "ROTARY CLUB",
    Address: "2 ROTARY STREET  PARYS  PARYS",
    Latitude: "-26.9074",
    Longitude: "27.4377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5850",
    WardLookupId: "1028",
    VDNumber: "22790033",
    RegisteredPopulation: "1791",
    VotingStationName: "TATAISO PRIMARY SCHOOL",
    Address: "792 MANDELA SECTION  MOKWALLO  VREDEFORT",
    Latitude: "-27.0004",
    Longitude: "27.3899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5851",
    WardLookupId: "1028",
    VDNumber: "22790044",
    RegisteredPopulation: "1209",
    VotingStationName: "AFM CHURCH",
    Address: "357 MOKUBETSANE STREET  MOKWALLO  VREDEFORT",
    Latitude: "-26.9992",
    Longitude: "27.3854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5852",
    WardLookupId: "1029",
    VDNumber: "22740016",
    RegisteredPopulation: "1897",
    VotingStationName: "NG KERK WES SAAL",
    Address: "14 KORTSTRAAT  PARYS  PARYS",
    Latitude: "-26.9113",
    Longitude: "27.4471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5853",
    WardLookupId: "1029",
    VDNumber: "22790011",
    RegisteredPopulation: "1478",
    VotingStationName: "MOKWALLO PUBLIC PRIMARY SCHOOL",
    Address:
      "220 BASENG STREET  MOKWALLO                               VREDEFORT",
    Latitude: "-27.0028",
    Longitude: "27.3825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5854",
    WardLookupId: "1029",
    VDNumber: "22790123",
    RegisteredPopulation: "2255",
    VotingStationName: "S. S. PAKI SECONDARY SCHOOL",
    Address: "2562 TAMBO SECTION  MOKWALLO  VREDEFORT",
    Latitude: "-27.0103",
    Longitude: "27.3815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5855",
    WardLookupId: "1030",
    VDNumber: "22710024",
    RegisteredPopulation: "1869",
    VotingStationName: "IPATLELENG PRIMARY SCHOOL",
    Address: "3576 NEW LOCATION  KWAKWATSI  KOPPIES",
    Latitude: "-27.2301",
    Longitude: "27.5883",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5856",
    WardLookupId: "1030",
    VDNumber: "22710046",
    RegisteredPopulation: "1625",
    VotingStationName: "REBATLA THUTO SECONDARY SCHOOL",
    Address: "3821 SETLABOTJHA SECTION  KWAKWATSI  KOPPIES",
    Latitude: "-27.2247",
    Longitude: "27.5903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5857",
    WardLookupId: "1031",
    VDNumber: "22710013",
    RegisteredPopulation: "985",
    VotingStationName: "SAREL CILLIERS COMBINED SCHOOL",
    Address: "3 DIRKIEUYS   KWAKWATSI  KOPPIES",
    Latitude: "-27.2416",
    Longitude: "27.571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5858",
    WardLookupId: "1031",
    VDNumber: "22710035",
    RegisteredPopulation: "1633",
    VotingStationName: "KWAKWATSI COMMUNITY HALL",
    Address: "803 MASOOANE  KWAKWATSI  KOPPIES",
    Latitude: "-27.2301",
    Longitude: "27.5974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5859",
    WardLookupId: "1031",
    VDNumber: "22710057",
    RegisteredPopulation: "1580",
    VotingStationName: "NG KERK IN AFRIKA",
    Address: "740/1 CHURCH STREET  KWAKWATSI  KOPPIES",
    Latitude: "-27.2329",
    Longitude: "27.5896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5860",
    WardLookupId: "1032",
    VDNumber: "22430292",
    RegisteredPopulation: "91",
    VotingStationName: "SERNICK FARM PRIMARY SCHOOL",
    Address: "SERNICK  FARM  SERNICK FARM  EDENVILLE",
    Latitude: "-27.6167",
    Longitude: "27.7252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5861",
    WardLookupId: "1032",
    VDNumber: "22680019",
    RegisteredPopulation: "1954",
    VotingStationName: "NGWATHE SECONDARY SCHOOL",
    Address: "MOKONE STREET  EDENVILLE  EDENVILLE",
    Latitude: "-27.5486",
    Longitude: "27.6581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5862",
    WardLookupId: "1032",
    VDNumber: "22680020",
    RegisteredPopulation: "406",
    VotingStationName: "EDENVILLE HIGH  HALL",
    Address: "22 JORDAAN STREET  EDENVILLE  EDENVILLE",
    Latitude: "-27.5544",
    Longitude: "27.6755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5863",
    WardLookupId: "1032",
    VDNumber: "22680031",
    RegisteredPopulation: "1100",
    VotingStationName: "EDENVILLE LIBRARY",
    Address: "588 MOSHOESHOE STREET  NGWATHE  EDENVILLE",
    Latitude: "-27.553",
    Longitude: "27.6574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5864",
    WardLookupId: "1033",
    VDNumber: "22750422",
    RegisteredPopulation: "2454",
    VotingStationName: "FISHERS OF MEN CHURCH",
    Address: "21064 AMELIA SECTION  ZAMDELA AMELIA  SASOLBURG",
    Latitude: "-26.8609",
    Longitude: "27.8988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5865",
    WardLookupId: "1033",
    VDNumber: "22820059",
    RegisteredPopulation: "169",
    VotingStationName: "THABANG FARM SCHOOL",
    Address: "LISSAGALLY FARM   KRAGBRON  SASOLBURG",
    Latitude: "-26.9949",
    Longitude: "27.848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5866",
    WardLookupId: "1033",
    VDNumber: "22820330",
    RegisteredPopulation: "3304",
    VotingStationName: "KOPANELANG THUTO PRIMARY SCHOOL",
    Address: "1739 WALTER SISULU  ZAMDELA   SASOLBURG",
    Latitude: "-26.8804",
    Longitude: "27.875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5867",
    WardLookupId: "1033",
    VDNumber: "22820352",
    RegisteredPopulation: "574",
    VotingStationName: "HO FEDILE HO PHETHEHILE GOLGOTHA WORSHIP",
    Address: "16741 IRAQ PHASE 2  SASOLBURG  ZAMDELA",
    Latitude: "-26.8703",
    Longitude: "27.8655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5868",
    WardLookupId: "1034",
    VDNumber: "22750107",
    RegisteredPopulation: "1559",
    VotingStationName: "KATLEHO MPUMELELO SEC SCHOOL",
    Address: "SNAKE PARK  SASOLBURG  ZAMDELA",
    Latitude: "-26.8581",
    Longitude: "27.8578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5869",
    WardLookupId: "1034",
    VDNumber: "22750185",
    RegisteredPopulation: "1342",
    VotingStationName: "HEAVEN APOSTOLIC CHURCH",
    Address: "SOMERSTPOST SECTION  ZAMDELA  SASOLBURG",
    Latitude: "-26.8643",
    Longitude: "27.8531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5870",
    WardLookupId: "1034",
    VDNumber: "22750231",
    RegisteredPopulation: "807",
    VotingStationName: "ETHOPIAN CHURCH OF SOUTH AFRICA",
    Address: "9317 SOMERSTPOST  ZAMDELA  SASOLBURG",
    Latitude: "-26.8575",
    Longitude: "27.8528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5871",
    WardLookupId: "1034",
    VDNumber: "22750332",
    RegisteredPopulation: "386",
    VotingStationName: "REAL WORSHIP CHRISTIAN CENTRE CHURCH",
    Address: "9214 SOMERSTPOST  ZAMDELA  SASOLBURG",
    Latitude: "-26.8613",
    Longitude: "27.8529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5872",
    WardLookupId: "1035",
    VDNumber: "22670029",
    RegisteredPopulation: "1831",
    VotingStationName: "REFENGKGOTSO COMMUNITY HALL",
    Address: "907 MAIN STREET  REFENGKGOTSO  DENEYSVILLE",
    Latitude: "-26.8959",
    Longitude: "28.072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5873",
    WardLookupId: "1035",
    VDNumber: "22670041",
    RegisteredPopulation: "1950",
    VotingStationName: "LE NOTSI SECONDARY SCHOOL",
    Address: "1471 MAIN STREET  REFENGKGOTSO  DENEYSVILLE",
    Latitude: "-26.8981",
    Longitude: "28.0691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5874",
    WardLookupId: "1036",
    VDNumber: "22670018",
    RegisteredPopulation: "1657",
    VotingStationName: "ST JOHN  CHURCH",
    Address: "585 REFENGKGOTSO LETAMONG SECTION  REFENGKGOTSO  DENEYSVILLE",
    Latitude: "-26.8922",
    Longitude: "28.0778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5875",
    WardLookupId: "1036",
    VDNumber: "22670030",
    RegisteredPopulation: "1381",
    VotingStationName: "ATLEHANG EDUCARE CENTRE",
    Address: "2272 PHOMOLONG   REFENGKGOTSO  DENEYSVILLE",
    Latitude: "-26.8984",
    Longitude: "28.0776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5876",
    WardLookupId: "1037",
    VDNumber: "22730015",
    RegisteredPopulation: "1475",
    VotingStationName: "METSIMAHOLO COMMUNITY HALL",
    Address: "933 POTOLOHA STREET  METSIMAHOLO  ORANJEVILLE",
    Latitude: "-26.9796",
    Longitude: "28.2117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5877",
    WardLookupId: "1037",
    VDNumber: "22730037",
    RegisteredPopulation: "667",
    VotingStationName: "ST CLEMENTE ANUARITE CATHOLIC CHURCH",
    Address: "805 POTOLOHA STREET  METSIMAHOLO  ORANJEVILLE",
    Latitude: "-26.9764",
    Longitude: "28.2037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5878",
    WardLookupId: "1037",
    VDNumber: "22820060",
    RegisteredPopulation: "156",
    VotingStationName: "FARM STALL SCHOOL",
    Address: "MOOIKLIP   HEILBRON  ORANGEVILLE",
    Latitude: "-26.9866",
    Longitude: "28.1042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5879",
    WardLookupId: "1037",
    VDNumber: "22820149",
    RegisteredPopulation: "1144",
    VotingStationName: "ORANJEVILLE PRIMARY SCHOOL",
    Address: "46 SCOTT STREET  ORANGEVILLE  ORANJEVILLE",
    Latitude: "-26.9926",
    Longitude: "28.2058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5880",
    WardLookupId: "1038",
    VDNumber: "22750163",
    RegisteredPopulation: "2431",
    VotingStationName: "NELSON MANDELA PRIMARY SCHOOL",
    Address: "11397 RUTH FIRST STREET  ZAMDELA  SASOLBURG",
    Latitude: "-26.8512",
    Longitude: "27.8765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5881",
    WardLookupId: "1038",
    VDNumber: "22750321",
    RegisteredPopulation: "892",
    VotingStationName: "AFRICAN PRESBETERIAN BAFOLISI CHURCH",
    Address: "11628 HARRY GWALA   ZAMDELA  SASOLBURG",
    Latitude: "-26.8518",
    Longitude: "27.8695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5882",
    WardLookupId: "1039",
    VDNumber: "22750130",
    RegisteredPopulation: "3089",
    VotingStationName: "APOSTOLIC FAITH CHURCH",
    Address: "8758 SNAKE PARK   ZAMDELA  SASOLBURG",
    Latitude: "-26.8545",
    Longitude: "27.8623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5883",
    WardLookupId: "1039",
    VDNumber: "22750343",
    RegisteredPopulation: "185",
    VotingStationName: "TENT NEXT TO (TRANSNET OPEN SPACE)",
    Address: "11573 SPOORNET SITE  ZAMDELA  SASOLBURG",
    Latitude: "-26.8457",
    Longitude: "27.8708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5884",
    WardLookupId: "1039",
    VDNumber: "22750433",
    RegisteredPopulation: "774",
    VotingStationName: "FREE BAPTIST CHURCH OF SA",
    Address: "1623 IRAQ PHASE 2  ZAMDELA",
    Latitude: "-26.8652",
    Longitude: "27.8664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5885",
    WardLookupId: "1040",
    VDNumber: "22750152",
    RegisteredPopulation: "2012",
    VotingStationName: "ISAAC MHLAMBI PRIMARY SCHOOL",
    Address: "6207 CHRIS HANI SECTION  ZAMDELA  SASOLBURG",
    Latitude: "-26.8464",
    Longitude: "27.8624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5886",
    WardLookupId: "1040",
    VDNumber: "22750264",
    RegisteredPopulation: "1220",
    VotingStationName: "CEDAR SECONDARY SCHOOL",
    Address: "6288 CHRIS HANI   ZAMDELA  SASOLBURG",
    Latitude: "-26.8435",
    Longitude: "27.8611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5887",
    WardLookupId: "1040",
    VDNumber: "22750354",
    RegisteredPopulation: "699",
    VotingStationName: "ETHIOPIAN CHURCH OF SOUTH AFRICA",
    Address: "4051 EXT 3  SASOLBURG  ZAMDELA",
    Latitude: "-26.8494",
    Longitude: "27.8612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5888",
    WardLookupId: "1041",
    VDNumber: "22750141",
    RegisteredPopulation: "2250",
    VotingStationName: "THEMBALETHU HALL",
    Address: "2301 KATLEHO SECTION  ZAMDELA  SASOLBURG",
    Latitude: "-26.8385",
    Longitude: "27.857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5889",
    WardLookupId: "1041",
    VDNumber: "22750365",
    RegisteredPopulation: "677",
    VotingStationName: "SPIRIT LIFE MINISTRIES CHURCH",
    Address: "2304/16/17 THUBELITJHA  SASOLBURG  ZAMDELA",
    Latitude: "-26.8383",
    Longitude: "27.854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5890",
    WardLookupId: "1042",
    VDNumber: "22750073",
    RegisteredPopulation: "2124",
    VotingStationName: "IKETSETSENG COMPREHENSIVE SECONDARY SCHOOL",
    Address: "3998 TAYLORPARK   ZAMDELA  SASOLBURG",
    Latitude: "-26.8458",
    Longitude: "27.8553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5891",
    WardLookupId: "1042",
    VDNumber: "22750275",
    RegisteredPopulation: "1680",
    VotingStationName: "TENT (SARATOGA OPEN SPACE)",
    Address: "4255 EXTENSION 3  ZAMDELA  SASOLBURG",
    Latitude: "-26.8497",
    Longitude: "27.8554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5892",
    WardLookupId: "1043",
    VDNumber: "22750129",
    RegisteredPopulation: "1751",
    VotingStationName: "NKGOPOLENG SECONDARY SCHOOL",
    Address: "667 TUBATSI STREET  ZAMDELA  SASOLBURG",
    Latitude: "-26.844",
    Longitude: "27.8456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5893",
    WardLookupId: "1043",
    VDNumber: "22750219",
    RegisteredPopulation: "1233",
    VotingStationName: "TSATSI PRIMARY SCHOOL",
    Address: "1126 LEOKA  STREET  ZAMDELA  SASOLBURG",
    Latitude: "-26.8406",
    Longitude: "27.8422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5894",
    WardLookupId: "1043",
    VDNumber: "22750376",
    RegisteredPopulation: "363",
    VotingStationName: "BOFULATSHEPE PRIMARY SCHOOL HALL",
    Address: "3244 TAYLOR PARK  ZAMDELA  SASOLBURG",
    Latitude: "-26.842",
    Longitude: "27.8508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5895",
    WardLookupId: "1044",
    VDNumber: "22750118",
    RegisteredPopulation: "2014",
    VotingStationName: "BOIKETLONG SASOL HALL",
    Address: "13158 ERIC LOUW ROAD  ZAMDELA  SASOLBURG",
    Latitude: "-26.8363",
    Longitude: "27.8461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5896",
    WardLookupId: "1044",
    VDNumber: "22750220",
    RegisteredPopulation: "1100",
    VotingStationName: "METHODIST CHURCH OF SA",
    Address: "645 PROTERM  ZAMDELA  SASOLBURG",
    Latitude: "-26.8395",
    Longitude: "27.8485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5897",
    WardLookupId: "1045",
    VDNumber: "22750297",
    RegisteredPopulation: "1863",
    VotingStationName: "NEW LIFE IN JESUS MINISTRIES INTERNATIONAL CHURCH",
    Address:
      "12595 HARRY GWALA SECTION  (FS204 - METSIMAHOLO [SASOLBURG])    ZAMDELA",
    Latitude: "-26.8596",
    Longitude: "27.881",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5898",
    WardLookupId: "1045",
    VDNumber: "22820284",
    RegisteredPopulation: "2589",
    VotingStationName: "SAKUBUSHA SECONDARY SCHOOL",
    Address: "14882 WALTER SISULU  SASOLBURG  ZAMDELA",
    Latitude: "-26.8637",
    Longitude: "27.8726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5899",
    WardLookupId: "1046",
    VDNumber: "22750286",
    RegisteredPopulation: "1973",
    VotingStationName: "LUMIERE PRIMARY SCHOOL",
    Address: "COR WOLWEKOP & ELANDSBERG STR  VAALPARK  SASOLBURG",
    Latitude: "-26.7777",
    Longitude: "27.8465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5900",
    WardLookupId: "1046",
    VDNumber: "22750309",
    RegisteredPopulation: "1283",
    VotingStationName: "LEEUWSPRUIT PRIMARY SCHOOL",
    Address: "6 BEN BOUWER STREET   [SASOLBURG])    SASOLBURG",
    Latitude: "-26.8167",
    Longitude: "27.8022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5901",
    WardLookupId: "1046",
    VDNumber: "22750398",
    RegisteredPopulation: "1571",
    VotingStationName: "VAALPARK PRIMARY SCHOOL",
    Address: "28 BAVIAANSKLOOF STREET  VAALPARK  SASOLBURG",
    Latitude: "-26.7647",
    Longitude: "27.8401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5902",
    WardLookupId: "1046",
    VDNumber: "22820037",
    RegisteredPopulation: "349",
    VotingStationName: "LEDIBOHONG PRIMARY SCHOOL",
    Address: "BARRAGE FARM  SASOLBURG  BARRAGE FARM",
    Latitude: "-26.7805",
    Longitude: "27.7001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5903",
    WardLookupId: "1046",
    VDNumber: "22820329",
    RegisteredPopulation: "135",
    VotingStationName: "KRUITFONTEIN PSPP FARM SCHOOL",
    Address: "1024 KRUITFNTEIN   KRUITFONTEIN FARM  SASOLBURG",
    Latitude: "-26.8619",
    Longitude: "27.7604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5904",
    WardLookupId: "1047",
    VDNumber: "22750062",
    RegisteredPopulation: "2795",
    VotingStationName: "FAKKEL  HIGH SCHOOL",
    Address: "18 JAN SMUTS STREET  SASOLBURG  SASOLBURG",
    Latitude: "-26.8134",
    Longitude: "27.8141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5905",
    WardLookupId: "1047",
    VDNumber: "22750400",
    RegisteredPopulation: "813",
    VotingStationName: "TENT NEXT TO KLASSIE HAVENGA ROAD OPEN SPACE",
    Address: "1711 PARK OF KLASIE HAVENGA ROAD  SASOLBURG  SASOLBURG",
    Latitude: "-26.8222",
    Longitude: "27.8103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5906",
    WardLookupId: "1048",
    VDNumber: "22750084",
    RegisteredPopulation: "1995",
    VotingStationName: "EDUCATION DEPT DISTRICT OFFICES",
    Address: "23 TOTIUS STREET  SASOLBURG  SASOLBURG",
    Latitude: "-26.7977",
    Longitude: "27.8167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5907",
    WardLookupId: "1048",
    VDNumber: "22750095",
    RegisteredPopulation: "1653",
    VotingStationName: "NORTH PRIMARY SCHOOL",
    Address: "VANDERBIJL STREET  SASOLBURG  SASOLBURG",
    Latitude: "-26.8091",
    Longitude: "27.8334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5908",
    WardLookupId: "1048",
    VDNumber: "22750411",
    RegisteredPopulation: "642",
    VotingStationName: "DUTCH REFORMED CHURCH OF SOUTH AFRICA CENTRAL CHUR",
    Address: "COR ROTHMANN & KERK STREET  SASOLBURG  SASOLBURG",
    Latitude: "-26.8144",
    Longitude: "27.8175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5909",
    WardLookupId: "1049",
    VDNumber: "22750040",
    RegisteredPopulation: "1882",
    VotingStationName: "A.J.JACOBS PRIMARY SCHOOL",
    Address: "WEPENER STREET  SASOLBURG  SASOLBURG",
    Latitude: "-26.823",
    Longitude: "27.8301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5910",
    WardLookupId: "1049",
    VDNumber: "22750242",
    RegisteredPopulation: "2048",
    VotingStationName: "SASOLBURG PUBLIC LIBRARY",
    Address: "JONH VOSTER ROAD  SASOLBURG  SASOLBURG",
    Latitude: "-26.8227",
    Longitude: "27.8181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5911",
    WardLookupId: "1049",
    VDNumber: "22750310",
    RegisteredPopulation: "1194",
    VotingStationName: "FLAVIUS MAREKA FET COLLEGE",
    Address: "7 HARRY SMITH STREET  SASOLBURG    SASOLBURG",
    Latitude: "-26.8176",
    Longitude: "27.8366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5912",
    WardLookupId: "1050",
    VDNumber: "22750017",
    RegisteredPopulation: "1590",
    VotingStationName: "TENT NEXT TO GRACE BIBLE CHURCH (TENT)",
    Address: "28 KOEBERG  SASOLBURG  SASOLBURG",
    Latitude: "-26.7771",
    Longitude: "27.8539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5913",
    WardLookupId: "1050",
    VDNumber: "22750028",
    RegisteredPopulation: "1664",
    VotingStationName: "VAALPARK ARTICON SCHOOL",
    Address: "35 COR LANGEBERG  ZOUTPANBESTREET  VAALPARK  SASOLBURG",
    Latitude: "-26.7666",
    Longitude: "27.8547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5914",
    WardLookupId: "1051",
    VDNumber: "22750196",
    RegisteredPopulation: "2803",
    VotingStationName: "BEKEZELA PRIMARY SCHOOL",
    Address: "19321 EXTENSION 16 AMELIA SETTLEMENT  ZAMDELA AMELIA  SASOLBURG",
    Latitude: "-26.8585",
    Longitude: "27.8943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5915",
    WardLookupId: "1051",
    VDNumber: "22820026",
    RegisteredPopulation: "1048",
    VotingStationName: "VAAL CHRISTIAN SCHOOL",
    Address:
      "ON ROUTE R716 BETWEEN VILJOENSDRIFT & DENEYSVILLE  VILJOENSDRIFT  DENEYSVILLE",
    Latitude: "-26.763",
    Longitude: "27.9494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5916",
    WardLookupId: "1051",
    VDNumber: "22820048",
    RegisteredPopulation: "473",
    VotingStationName: "WILLOWS CAFE (TENT)",
    Address: "29 PLOT  DENEYSVILLE  DENEYSVILLE",
    Latitude: "-26.804",
    Longitude: "28.0115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5917",
    WardLookupId: "1052",
    VDNumber: "22670052",
    RegisteredPopulation: "922",
    VotingStationName: "DENEYSVILLE  PRIMARY SCHOOL",
    Address: "5 TANK STREET  DENEYSVILLE  DENEYSVILLE",
    Latitude: "-26.8939",
    Longitude: "28.092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5918",
    WardLookupId: "1052",
    VDNumber: "22670063",
    RegisteredPopulation: "1333",
    VotingStationName: "J.J. KUBHEKA PRIMARY SCHOOL",
    Address: "3374 REFENGKGOTSO   DENEYSVILLE",
    Latitude: "-26.8978",
    Longitude: "28.0859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5919",
    WardLookupId: "1052",
    VDNumber: "22670074",
    RegisteredPopulation: "622",
    VotingStationName: "DENEYSVILLE LIBRARY AUDITORIUM",
    Address: "CNR MAIN & ISLAND STREET  DENEYSVILLE  DENEYSVILLE",
    Latitude: "-26.8988",
    Longitude: "28.1048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5920",
    WardLookupId: "1052",
    VDNumber: "22750039",
    RegisteredPopulation: "677",
    VotingStationName: "TAAIBOS COMBINED SCHOOL",
    Address: "1 SCHOOL STREET   KRAGBRON  SASOLBURG",
    Latitude: "-26.9065",
    Longitude: "27.8826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5921",
    WardLookupId: "1052",
    VDNumber: "22820071",
    RegisteredPopulation: "1789",
    VotingStationName: "MAMA`S BABY CRECH",
    Address: "4848 THEMBA KHUBEKA  DENEYSVILLE  SASOLBURG",
    Latitude: "-26.9015",
    Longitude: "28.0585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5922",
    WardLookupId: "1053",
    VDNumber: "22750208",
    RegisteredPopulation: "1541",
    VotingStationName: "MULTI PURPOSE CENTRE",
    Address: "10174 HARRY GWALA  ZAMDELA  SASOLBURG",
    Latitude: "-26.8548",
    Longitude: "27.8824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5923",
    WardLookupId: "1053",
    VDNumber: "22750253",
    RegisteredPopulation: "1226",
    VotingStationName: "ST FRANCIS CATHOLIC CHURCH",
    Address: "1092 HARRY GWALA   ZAMDELA  SASOLBURG",
    Latitude: "-26.8498",
    Longitude: "27.8843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5924",
    WardLookupId: "1054",
    VDNumber: "22180014",
    RegisteredPopulation: "841",
    VotingStationName: "CORNELIA TOWN HALL",
    Address: "367 RITCHER STREET  CORNELIA  CORNELIA",
    Latitude: "-27.2359",
    Longitude: "28.8537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5925",
    WardLookupId: "1054",
    VDNumber: "22180025",
    RegisteredPopulation: "842",
    VotingStationName: "EKUKHANYENI COMMUNITY HALL",
    Address: "ROMAN STREET  NTSWANATSATSI  CORNELIA",
    Latitude: "-27.2285",
    Longitude: "28.8551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5926",
    WardLookupId: "1054",
    VDNumber: "22430180",
    RegisteredPopulation: "74",
    VotingStationName: "ARUNDEL",
    Address: "ARUNDEL FARM  VILLAGE  DISTRICT",
    Latitude: "-27.3914",
    Longitude: "28.8237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5927",
    WardLookupId: "1054",
    VDNumber: "22430359",
    RegisteredPopulation: "44",
    VotingStationName: "GRENFELL PRIMARY SCHOOL",
    Address: "GRENFELL FARM  CORNELIA  GRENFALL FARM",
    Latitude: "-27.4939",
    Longitude: "28.717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5928",
    WardLookupId: "1054",
    VDNumber: "22820082",
    RegisteredPopulation: "70",
    VotingStationName: "BETHANIE FARM",
    Address: "BETHANIE  VILLIERS  VILLIERS",
    Latitude: "-27.1653",
    Longitude: "28.606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5929",
    WardLookupId: "1054",
    VDNumber: "22820150",
    RegisteredPopulation: "1021",
    VotingStationName: "TSWELOPELE EDUCARE CENTRE",
    Address: "MAGASHULE   CORNELIA  NTSWANATSATSI",
    Latitude: "-27.2254",
    Longitude: "28.8491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5930",
    WardLookupId: "1054",
    VDNumber: "22820318",
    RegisteredPopulation: "70",
    VotingStationName: "RIVER RANCH",
    Address: "FARM  RIVER RANCH  CORNELIA",
    Latitude: "-27.0815",
    Longitude: "28.9341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5931",
    WardLookupId: "1055",
    VDNumber: "22690065",
    RegisteredPopulation: "2267",
    VotingStationName: "MFUNDU THUTO SECONDARY SCHOOL",
    Address: "4768  NAMAHADI  FRANKFORT",
    Latitude: "-27.2516",
    Longitude: "28.5173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5932",
    WardLookupId: "1055",
    VDNumber: "22690087",
    RegisteredPopulation: "731",
    VotingStationName: "DOMINION CHRISTIAN CHURCH",
    Address: "2962 VERGENOEGE  NAMAHADI  FRANKFORT",
    Latitude: "-27.2573",
    Longitude: "28.5164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5933",
    WardLookupId: "1055",
    VDNumber: "22690098",
    RegisteredPopulation: "755",
    VotingStationName: "APOSTOLIC CHRISTIAN BROTHER CHURCH",
    Address: "1478 MAMELLO  FRANKFORT",
    Latitude: "-27.2547",
    Longitude: "28.5224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5934",
    WardLookupId: "1056",
    VDNumber: "22780021",
    RegisteredPopulation: "2099",
    VotingStationName: "MOHLAKENG PRIMARY SCHOOL",
    Address: "1369 SEGASA STREET  QALABOTJHA  VILLIERS",
    Latitude: "-27.017",
    Longitude: "28.6275",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5935",
    WardLookupId: "1056",
    VDNumber: "22780054",
    RegisteredPopulation: "951",
    VotingStationName: "RETSHIDISITSWE SECONDARY SCHOOL",
    Address: "1538 EXTENSION 3  QALABOTJHA  VILLIERS",
    Latitude: "-27.0218",
    Longitude: "28.6283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5936",
    WardLookupId: "1057",
    VDNumber: "22780032",
    RegisteredPopulation: "1772",
    VotingStationName: "MADIBA COMMUNITY HALL",
    Address: "729 GAMEDE STREET  QALABOTJHA  VILLIERS",
    Latitude: "-27.0186",
    Longitude: "28.6174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5937",
    WardLookupId: "1057",
    VDNumber: "22780043",
    RegisteredPopulation: "849",
    VotingStationName: "VILLIERS TOWN HALL",
    Address: "150 PEARSONS STREET  VILLIERS  VILLIERS",
    Latitude: "-27.0296",
    Longitude: "28.599",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5938",
    WardLookupId: "1057",
    VDNumber: "22780076",
    RegisteredPopulation: "576",
    VotingStationName: "HLALEFANG CRECHE",
    Address: "EXTATION 8  QALABOTJHA   VILLIERS",
    Latitude: "-27.0331",
    Longitude: "28.6251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5939",
    WardLookupId: "1057",
    VDNumber: "22820138",
    RegisteredPopulation: "204",
    VotingStationName: "OPLOOP PRIMARY SCHOOL",
    Address: "OPLOOP FARM  FRANKFORT  OPLOOP FARM",
    Latitude: "-27.1697",
    Longitude: "28.3133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5940",
    WardLookupId: "1057",
    VDNumber: "22820240",
    RegisteredPopulation: "56",
    VotingStationName: "FOREST-CREEK FARM",
    Address: "FPREST-CREEK   VILLIERS  VILLIERS",
    Latitude: "-27.0706",
    Longitude: "28.4444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5941",
    WardLookupId: "1058",
    VDNumber: "22690010",
    RegisteredPopulation: "1689",
    VotingStationName: "FRANKFORT TOWN HALL",
    Address: "64 JJ RADEBE STREET  FRANKFORT  FRANKFORT",
    Latitude: "-27.2774",
    Longitude: "28.4928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5942",
    WardLookupId: "1058",
    VDNumber: "22690021",
    RegisteredPopulation: "913",
    VotingStationName: "MEDUWANENG PRIMARY SCHOOL",
    Address: "1210  NAMAHADI  FRANKFORT",
    Latitude: "-27.2674",
    Longitude: "28.5017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5943",
    WardLookupId: "1058",
    VDNumber: "22690076",
    RegisteredPopulation: "1036",
    VotingStationName: "SAVE GOSPEL CHURCH",
    Address: "1202 MAMELLO  NAMAHADI   FRANKFORT",
    Latitude: "-27.2517",
    Longitude: "28.5252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5944",
    WardLookupId: "1059",
    VDNumber: "22690032",
    RegisteredPopulation: "1314",
    VotingStationName: "THUTO KE TSELA PRIMARY SCHOOL",
    Address: "2333 SUNRISE  FRANKFORT",
    Latitude: "-27.2587",
    Longitude: "28.5112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5945",
    WardLookupId: "1059",
    VDNumber: "22690054",
    RegisteredPopulation: "1696",
    VotingStationName: "GUGULETHU PRIMARY SCHOOL",
    Address: "1214 MAPHOSHE STREET  NAMAHADI  FRANKFORT",
    Latitude: "-27.2666",
    Longitude: "28.5068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5946",
    WardLookupId: "1060",
    VDNumber: "22690043",
    RegisteredPopulation: "3587",
    VotingStationName: "POELANO PRIMARY SCHOOL",
    Address: "3825  NAMAHADI  FRANKFORT",
    Latitude: "-27.2541",
    Longitude: "28.5066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5947",
    WardLookupId: "1060",
    VDNumber: "22820239",
    RegisteredPopulation: "103",
    VotingStationName: "TEBELLO TSHEPO SCHOOL",
    Address: "GOEDEHOOP  FRANKFORT  FRANKFORT",
    Latitude: "-27.3172",
    Longitude: "28.4454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5948",
    WardLookupId: "1060",
    VDNumber: "22820262",
    RegisteredPopulation: "75",
    VotingStationName: "TENT NEXT TO BLOUKRAANS FARM",
    Address: "FARM   FRANKFORT  FRANKFORT",
    Latitude: "-27.3318",
    Longitude: "28.6281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5949",
    WardLookupId: "1060",
    VDNumber: "22820341",
    RegisteredPopulation: "453",
    VotingStationName: "MMABANA CRECHE",
    Address: "PHOMOLONG  FRANKFORT  FRANKFORT",
    Latitude: "-27.2413",
    Longitude: "28.509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5950",
    WardLookupId: "1061",
    VDNumber: "22760018",
    RegisteredPopulation: "1829",
    VotingStationName: "MAFAHLANENG COMMUNITY HALL",
    Address: "538 MOKOENA STREET  MAFAHLANENG  TWEELING",
    Latitude: "-27.5456",
    Longitude: "28.5182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5951",
    WardLookupId: "1061",
    VDNumber: "22760029",
    RegisteredPopulation: "1646",
    VotingStationName: "TWEELING TOWN HALL",
    Address: "08 HANS DONS DE LANGE  TWEELING  TWEELING",
    Latitude: "-27.555",
    Longitude: "28.5146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5952",
    WardLookupId: "1061",
    VDNumber: "22820228",
    RegisteredPopulation: "99",
    VotingStationName: "KROONTJIE PRIMARY SCHOOL",
    Address: "BEGINSEL  TWEELING  TWEELING",
    Latitude: "-27.4654",
    Longitude: "28.4656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5953",
    WardLookupId: "1061",
    VDNumber: "22820251",
    RegisteredPopulation: "67",
    VotingStationName: "MORSALA PRIMARY SCHOOL",
    Address: "ROSEDALE  TWEELING  TWEELING",
    Latitude: "-27.4891",
    Longitude: "28.548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5954",
    WardLookupId: "1062",
    VDNumber: "22780010",
    RegisteredPopulation: "1852",
    VotingStationName: "NTATAISE EDUCARE",
    Address: "2383 EXTENSION 4  QALABOTJHA  MAFUBE",
    Latitude: "-27.0263",
    Longitude: "28.6296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5955",
    WardLookupId: "1062",
    VDNumber: "22780065",
    RegisteredPopulation: "1088",
    VotingStationName: "OLD APOSTOLIC FAITH MISSION CHURCH",
    Address: "2070 EXTENTION 03 QALABOTJHA   VILLIERS  QALABOTJHA",
    Latitude: "-27.0282",
    Longitude: "28.6235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5956",
    WardLookupId: "4076",
    VDNumber: "21860120",
    RegisteredPopulation: "1925",
    VotingStationName: "KATLEHO CENTER",
    Address: "LOVEDALE ROAD  BATHO LOCATION  BLOEMFONTEIN",
    Latitude: "-29.13",
    Longitude: "26.2318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5957",
    WardLookupId: "4076",
    VDNumber: "21860254",
    RegisteredPopulation: "977",
    VotingStationName: "MARANG INTERMEDIATE SCHOOL",
    Address: "C/O COOPER AVENUE & MKHUHLANE STREET  BOCHABELA  BLOEMFONTEIN",
    Latitude: "-29.1361",
    Longitude: "26.2395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5958",
    WardLookupId: "4076",
    VDNumber: "21860546",
    RegisteredPopulation: "1562",
    VotingStationName: "BATHO FULL GOSPEL CHURCH",
    Address: "804 SESING STREET  BATHO LOCATION   MANGAUNG",
    Latitude: "-29.1379",
    Longitude: "26.2293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5959",
    WardLookupId: "4076",
    VDNumber: "21861086",
    RegisteredPopulation: "919",
    VotingStationName: "SUSANNA OLLEMANS CRECHE",
    Address: "1 DISPENSARY STREET  BATHO LOCATION  MANGAUNG [BLOEMFONTEIN]",
    Latitude: "-29.1313",
    Longitude: "26.227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5960",
    WardLookupId: "4076",
    VDNumber: "21861200",
    RegisteredPopulation: "406",
    VotingStationName: "TENT  ( AT BUITESIG)",
    Address: "BUITESIG  BLOEMFONTEIN",
    Latitude: "-29.1215",
    Longitude: "26.2292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5961",
    WardLookupId: "4076",
    VDNumber: "21861389",
    RegisteredPopulation: "963",
    VotingStationName: "AL-IMDAAD FOUNDATION",
    Address: "55584 C/O COOK AVENUE & SHUPING  BATHO LOCATION  BLOEMFONTEIN",
    Latitude: "-29.1359",
    Longitude: "26.2294",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5962",
    WardLookupId: "4076",
    VDNumber: "21861884",
    RegisteredPopulation: "435",
    VotingStationName: "ST JOHN METHODIST CHURCH",
    Address: "54989 NGYAYIYA STREET  BATHO LOCATION  BLOEMFONTEIN",
    Latitude: "-29.1334",
    Longitude: "26.2282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5963",
    WardLookupId: "4077",
    VDNumber: "21860557",
    RegisteredPopulation: "1965",
    VotingStationName: "KALOSONG DAY CARE",
    Address: "1449 MAKGOTHI STREET  BATHO LOCATION  BLOEMFONTEIN",
    Latitude: "-29.1376",
    Longitude: "26.2238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5964",
    WardLookupId: "4077",
    VDNumber: "21860568",
    RegisteredPopulation: "2224",
    VotingStationName: "PARADISE HALL",
    Address: "1075 MKUHLANE STREET  BOCHABELA  BLOEMFONTEIN",
    Latitude: "-29.1407",
    Longitude: "26.2381",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5965",
    WardLookupId: "4077",
    VDNumber: "21860579",
    RegisteredPopulation: "715",
    VotingStationName: "MORAFE PRIMARY SCHOOL",
    Address: "30316 ABDURAMAN  BOCHABELA  BLOEMFONTEIN",
    Latitude: "-29.1428",
    Longitude: "26.2311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5966",
    WardLookupId: "4077",
    VDNumber: "21860603",
    RegisteredPopulation: "1293",
    VotingStationName: "BOIKHUCO OLD AGE HOME",
    Address: "55291 MALELEKA STREET  BOCHABELA  BLOEMFONTEIN",
    Latitude: "-29.1454",
    Longitude: "26.2324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5967",
    WardLookupId: "4077",
    VDNumber: "21861064",
    RegisteredPopulation: "1042",
    VotingStationName: "SEHUNELO SECONDARY SCHOOL",
    Address: "22607 HAMILTON ROAD  BATHO  BLOEMFONTEIN",
    Latitude: "-29.1365",
    Longitude: "26.2222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5968",
    WardLookupId: "4077",
    VDNumber: "21861390",
    RegisteredPopulation: "407",
    VotingStationName: "UNITING REFORMED CHURCH",
    Address: "736 MKHUHLANE  BOCHABELA  BLOEMFONTEIN",
    Latitude: "-29.1381",
    Longitude: "26.2376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5969",
    WardLookupId: "4077",
    VDNumber: "21861907",
    RegisteredPopulation: "532",
    VotingStationName: "TENT NEXT TO FORT HARE ROAD",
    Address: "FORT HARE   BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.1446",
    Longitude: "26.226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5970",
    WardLookupId: "4078",
    VDNumber: "21860513",
    RegisteredPopulation: "2470",
    VotingStationName: "MABEOANA PRIMARY SCHOOL",
    Address: "6591 JONGA STREET  PHAHAMENG  BLOEMFONTEIN",
    Latitude: "-29.1525",
    Longitude: "26.2384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5971",
    WardLookupId: "4078",
    VDNumber: "21860580",
    RegisteredPopulation: "1885",
    VotingStationName: "LEGAE PRIMARY SCHOOL",
    Address: "6619 MTJOMBILE STREET  BOCHABELA  BLOEMFONTEIN",
    Latitude: "-29.1489",
    Longitude: "26.2298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5972",
    WardLookupId: "4078",
    VDNumber: "21860591",
    RegisteredPopulation: "1438",
    VotingStationName: "BOCHABELA PRIMARY SCHOOL",
    Address: "4515 MOIKANGOA STREET  BOCHABELA  BLOEMFONTEIN",
    Latitude: "-29.1487",
    Longitude: "26.2415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5973",
    WardLookupId: "4078",
    VDNumber: "21861110",
    RegisteredPopulation: "694",
    VotingStationName: "NEDERDUITSCH GEREFORMEERDE KERK",
    Address: "6576 MNTWANA STREET  PHAHAMENG  MANGAUNG [BLOEMFONTEIN]",
    Latitude: "-29.155",
    Longitude: "26.2362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5974",
    WardLookupId: "4078",
    VDNumber: "21861402",
    RegisteredPopulation: "445",
    VotingStationName: "ST PETER METHODIST CHURCH",
    Address: "4456 TSOAI  BOCHABELA  BLOEMFONTEIN",
    Latitude: "-29.1495",
    Longitude: "26.2394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5975",
    WardLookupId: "4079",
    VDNumber: "21860401",
    RegisteredPopulation: "1723",
    VotingStationName: "LESEDI PRIMARY SCHOOL",
    Address: "326 MATHAMBO STREET  BOCHABELA  BLOEMFONTEIN",
    Latitude: "-29.1468",
    Longitude: "26.2466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5976",
    WardLookupId: "4079",
    VDNumber: "21860614",
    RegisteredPopulation: "2154",
    VotingStationName: "GONYANE PRIMARY SCHOOL",
    Address: "6593 DR LEBONA   PHAHAMENG  BLOEMFONTEIN",
    Latitude: "-29.1558",
    Longitude: "26.2469",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5977",
    WardLookupId: "4079",
    VDNumber: "21861020",
    RegisteredPopulation: "1445",
    VotingStationName: "TSHEPO DAY CARE",
    Address: "MANGAUNG  NAMIBIA  BLOEMFONTEIN",
    Latitude: "-29.1625",
    Longitude: "26.2525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5978",
    WardLookupId: "4079",
    VDNumber: "21861198",
    RegisteredPopulation: "1349",
    VotingStationName: "L.A KAMANDA THABEDI",
    Address: "27968 NAMIBIA  NAMIBIA SQUARE  BLOEMFONTEIN",
    Latitude: "-29.1659",
    Longitude: "26.248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5979",
    WardLookupId: "4080",
    VDNumber: "21860490",
    RegisteredPopulation: "1590",
    VotingStationName: "NOZALA PRIMARY SCHOOL",
    Address: "6592 C/O MAPHISA & MOSHOESHOE ROAD  ROCKLANDS  BLOEMFONTEIN",
    Latitude: "-29.1609",
    Longitude: "26.2386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5980",
    WardLookupId: "4080",
    VDNumber: "21860636",
    RegisteredPopulation: "1420",
    VotingStationName: "TENT ( AT BOBO STREET)",
    Address: "BOBO STREET  ROCKLANDS  BLOEMFONTEIN",
    Latitude: "-29.1718",
    Longitude: "26.2387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5981",
    WardLookupId: "4080",
    VDNumber: "21860647",
    RegisteredPopulation: "1411",
    VotingStationName: "VULAMASANGO SECONDARY SCHOOL",
    Address: "1026 ZIM   ROCKLANDS  BLOEMFONTEIN",
    Latitude: "-29.1659",
    Longitude: "26.2357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5982",
    WardLookupId: "4080",
    VDNumber: "21861288",
    RegisteredPopulation: "830",
    VotingStationName: "DALUXOLO PRIMARY SCHOOL",
    Address: "1183 JINGOSE  ROCKLANDS  MANGAUNG",
    Latitude: "-29.1654",
    Longitude: "26.2384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5983",
    WardLookupId: "4080",
    VDNumber: "21861413",
    RegisteredPopulation: "729",
    VotingStationName: "MARANATHA NG KERK",
    Address: "26579 MOPHETHE  ROCKLANDS  MANGAUNG",
    Latitude: "-29.1589",
    Longitude: "26.2437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5984",
    WardLookupId: "4080",
    VDNumber: "21861424",
    RegisteredPopulation: "666",
    VotingStationName: "TENT NEXT TO ZCC CHURCH",
    Address: "MOHAPI STREET   MANGAUNG  BLOEMFONTEIN",
    Latitude: "-29.1615",
    Longitude: "26.2448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5985",
    WardLookupId: "4080",
    VDNumber: "21861435",
    RegisteredPopulation: "428",
    VotingStationName: "TENT ( AT CRAWFORD THOKA STREET)",
    Address: "CRAWFORD THOKA STEET  ROCKLANDS   BLOEMFONTEIN",
    Latitude: "-29.1709",
    Longitude: "26.2367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5986",
    WardLookupId: "4080",
    VDNumber: "21861446",
    RegisteredPopulation: "392",
    VotingStationName: "HODISA TECHNICAL SCHOOL",
    Address: "420 NTLATI STREET  ROCKLANDS  BLOEMFONTEIN",
    Latitude: "-29.1712",
    Longitude: "26.2341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5987",
    WardLookupId: "4081",
    VDNumber: "21860704",
    RegisteredPopulation: "1892",
    VotingStationName: "TENT AT WHITE HOUSE",
    Address: "MANGAUNG  FREEDOM SQUARE  BLOEMFONTEIN",
    Latitude: "-29.1728",
    Longitude: "26.2524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5988",
    WardLookupId: "4081",
    VDNumber: "21860737",
    RegisteredPopulation: "1318",
    VotingStationName: "TENT  ( AT ERF 36788 FREEDOM SQ)",
    Address: "ERF 36788  FREEDOM SQUARE  BLOEMFONTEIN",
    Latitude: "-29.1666",
    Longitude: "26.2529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5989",
    WardLookupId: "4081",
    VDNumber: "21860748",
    RegisteredPopulation: "768",
    VotingStationName: "METHODIST CHURCH FREEDOM SQUARE",
    Address: "FREEDOM SQUARE  MANGAUNG  BLOEMFONTEIN",
    Latitude: "-29.171",
    Longitude: "26.256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5990",
    WardLookupId: "4081",
    VDNumber: "21861019",
    RegisteredPopulation: "906",
    VotingStationName: "COMMERCIAL TECHNICAL SCHOOL",
    Address: "10792 SINGONZO STREET (22883)  PHELINDABA  BLOEMFONTEIN",
    Latitude: "-29.1776",
    Longitude: "26.2428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5991",
    WardLookupId: "4081",
    VDNumber: "21861299",
    RegisteredPopulation: "845",
    VotingStationName: "ATANG PRIMARY SCHOOL",
    Address: "8152 NAMIBIA  MANGAUNG",
    Latitude: "-29.1693",
    Longitude: "26.249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5992",
    WardLookupId: "4081",
    VDNumber: "21861457",
    RegisteredPopulation: "682",
    VotingStationName: "BT DAY CARE",
    Address: "FREEDOM SQUARE  MANGAUNG  BLOEMFONTEIN",
    Latitude: "-29.1678",
    Longitude: "26.257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5993",
    WardLookupId: "4081",
    VDNumber: "21861468",
    RegisteredPopulation: "351",
    VotingStationName: "LEFIKA REFORMED CHURCH",
    Address: "36287 FREEDOM SQUARE  MANGAUNG  BLOEMFONTEIN",
    Latitude: "-29.1739",
    Longitude: "26.2601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5994",
    WardLookupId: "4081",
    VDNumber: "21861479",
    RegisteredPopulation: "924",
    VotingStationName: "POLOKEHONG PRIMARY SCHOOL",
    Address: "38017 FREEDOM SQUARE  BLOEMFONTEIN",
    Latitude: "-29.1777",
    Longitude: "26.2487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5995",
    WardLookupId: "4081",
    VDNumber: "21861480",
    RegisteredPopulation: "416",
    VotingStationName: "MOHAU CRECHE",
    Address: "3427 FREEDOM SQUARE EXTENTION  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.1742",
    Longitude: "26.2613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5996",
    WardLookupId: "4082",
    VDNumber: "21860692",
    RegisteredPopulation: "1254",
    VotingStationName: "TENT AT 39158 FREEDOM SQUARE",
    Address: "39158 FREEDOM SQUARE  BLOEMFONTEIN",
    Latitude: "-29.1836",
    Longitude: "26.2486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5997",
    WardLookupId: "4082",
    VDNumber: "21860715",
    RegisteredPopulation: "1225",
    VotingStationName: "THUSANO DAY CARE",
    Address: "35774 FREEDOM SQUARE  MANGAUNG  BLOEMFONTEIN",
    Latitude: "-29.1787",
    Longitude: "26.253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5998",
    WardLookupId: "4082",
    VDNumber: "21860850",
    RegisteredPopulation: "1687",
    VotingStationName: "MEDET OFFICE",
    Address: "18806 TAU STREET  ROCKLANDS  MANGAUNG",
    Latitude: "-29.1908",
    Longitude: "26.2476",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "5999",
    WardLookupId: "4082",
    VDNumber: "21860872",
    RegisteredPopulation: "1063",
    VotingStationName: "PRESBYTARIAN CHURCH",
    Address: "TURFLAAGTE  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.1917",
    Longitude: "26.2521",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6000",
    WardLookupId: "4082",
    VDNumber: "21860894",
    RegisteredPopulation: "860",
    VotingStationName: "TENT (AT THABA YA SIONE)",
    Address: "39014 TURFLAAGTE  BLOEMFONTEIN",
    Latitude: "-29.1817",
    Longitude: "26.2467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6001",
    WardLookupId: "4082",
    VDNumber: "21861301",
    RegisteredPopulation: "581",
    VotingStationName: "TENT (AT BOPHELONG OPEN SPACE)",
    Address: "111 BOPHELONG  MANGAUNG  BLOEMFONTEIN",
    Latitude: "-29.1847",
    Longitude: "26.2457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6002",
    WardLookupId: "4083",
    VDNumber: "21860782",
    RegisteredPopulation: "3026",
    VotingStationName: "KAMOHELO PRIMARY SCHOOL",
    Address: "8806 BLOEMSIDE PHASE 3  BLOEMFONTEIN",
    Latitude: "-29.1583",
    Longitude: "26.2717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6003",
    WardLookupId: "4083",
    VDNumber: "21861154",
    RegisteredPopulation: "1696",
    VotingStationName: "MATSHEPO CHILD AND DAY CARE CENTRE",
    Address: "5200 PETER SWAARTZ  MANGAUNG  BLOEMFONTEIN",
    Latitude: "-29.1614",
    Longitude: "26.2614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6004",
    WardLookupId: "4083",
    VDNumber: "21861222",
    RegisteredPopulation: "3379",
    VotingStationName: "GRASSLAND PRIMARY SCHOOL",
    Address: "GRASSLAND PHASE 2  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.1522",
    Longitude: "26.2853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6005",
    WardLookupId: "4083",
    VDNumber: "21861491",
    RegisteredPopulation: "895",
    VotingStationName: "GMG CHURCH",
    Address: "9424 PETER SWARTZ  MANGAUNG  BLOEMFONTEIN",
    Latitude: "-29.1614",
    Longitude: "26.2689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6006",
    WardLookupId: "4084",
    VDNumber: "21860759",
    RegisteredPopulation: "1139",
    VotingStationName: "TENT NEXT TO ISMAEL DITALANE CRES",
    Address: "ISMAEL DITALANE CRESCENT  BLOEMANDA, PHASE 2  BLOEMFONTEIN",
    Latitude: "-29.2029",
    Longitude: "26.2219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6007",
    WardLookupId: "4084",
    VDNumber: "21860760",
    RegisteredPopulation: "2441",
    VotingStationName: "MOTHEO DAY CARE",
    Address: "17875 SEROTHO   PHASE 2  BLOEMFONTEIN",
    Latitude: "-29.1977",
    Longitude: "26.2301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6008",
    WardLookupId: "4084",
    VDNumber: "21860771",
    RegisteredPopulation: "2024",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "2625 PHASE 2  HILLSIDE VIEW  MANGAUNG",
    Latitude: "-29.1955",
    Longitude: "26.2257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6009",
    WardLookupId: "4084",
    VDNumber: "21861109",
    RegisteredPopulation: "1338",
    VotingStationName: "UNITY PRIMARY SCHOOL",
    Address: "18460 MOREENG MOKGELE   PHASE 2  BLOEMFONTEIN",
    Latitude: "-29.2019",
    Longitude: "26.2265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6010",
    WardLookupId: "4084",
    VDNumber: "21861503",
    RegisteredPopulation: "949",
    VotingStationName: "NG KERK IN AFRICA",
    Address: "15705 ANDREW NCHECHE  MAFORA  BLOEMFONTEIN",
    Latitude: "-29.1995",
    Longitude: "26.2202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6011",
    WardLookupId: "4085",
    VDNumber: "21860917",
    RegisteredPopulation: "1332",
    VotingStationName: "TENT  (AT GLADYS MTHEMBU STREET)",
    Address: "MAFORA  MANGAUNG  BLOEMFONTEIN",
    Latitude: "-29.2004",
    Longitude: "26.234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6012",
    WardLookupId: "4085",
    VDNumber: "21861132",
    RegisteredPopulation: "1615",
    VotingStationName: "GALALETSANG DAY CARE",
    Address: "120 CALEB MOTSHABI   BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.2112",
    Longitude: "26.2283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6013",
    WardLookupId: "4085",
    VDNumber: "21861165",
    RegisteredPopulation: "874",
    VotingStationName: "AMOHELANG DAY CARE",
    Address: "57429 BLOCK 1  MAFORA PHASE 2  BLOEMFONTEIN",
    Latitude: "-29.2061",
    Longitude: "26.2328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6014",
    WardLookupId: "4085",
    VDNumber: "21861211",
    RegisteredPopulation: "1692",
    VotingStationName: "NEDEDUITCH GEREFOMEERDE KERK IN AFRIKA",
    Address: "MAFORA MANGAUNG  J.B MAFORA MANGAUNG  J.B MAFORA MANGAUNG",
    Latitude: "-29.2011",
    Longitude: "26.2471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6015",
    WardLookupId: "4085",
    VDNumber: "21861514",
    RegisteredPopulation: "393",
    VotingStationName: "RORISANG OPEN SPACE",
    Address: "MAFORA  MANGAUNG   BLOEMFONTEIN",
    Latitude: "-29.2041",
    Longitude: "26.2367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6016",
    WardLookupId: "4085",
    VDNumber: "21861525",
    RegisteredPopulation: "725",
    VotingStationName: "IKAHENG DAY CARE",
    Address: "57726 BLOCK 1 JB MAFORA  JB MAFORA  MANGAUNG",
    Latitude: "-29.2082",
    Longitude: "26.2328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6017",
    WardLookupId: "4085",
    VDNumber: "21861536",
    RegisteredPopulation: "721",
    VotingStationName: "THEMBINKOSI DAY CARE CENTRE",
    Address: "697 BLOCK 5 MAFORA  J.B MAFORA   BLOEMFONTEIN",
    Latitude: "-29.2022",
    Longitude: "26.239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6018",
    WardLookupId: "4085",
    VDNumber: "21861615",
    RegisteredPopulation: "872",
    VotingStationName: "REITUMETSE DAY CARE",
    Address: "955 CALEB MOTSHABI, ROCKLANDS  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.2137",
    Longitude: "26.2379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6019",
    WardLookupId: "4085",
    VDNumber: "21861626",
    RegisteredPopulation: "703",
    VotingStationName: "LESEDI LA BOPHELO DAY CARE",
    Address: "3270 JB MAFORA  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.217",
    Longitude: "26.2436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6020",
    WardLookupId: "4085",
    VDNumber: "21861637",
    RegisteredPopulation: "1006",
    VotingStationName: "KGOTSOFALANG DAY CARE",
    Address: "MOTSHABI SQUARE  BLOEMFONTEIN   MONGAUNG",
    Latitude: "-29.2195",
    Longitude: "26.2479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6021",
    WardLookupId: "4085",
    VDNumber: "21861918",
    RegisteredPopulation: "674",
    VotingStationName: "MIGHTY WARRIORS CHRISTIAN CHURCH",
    Address: "MAFORA   BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.2077",
    Longitude: "26.2424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6022",
    WardLookupId: "4085",
    VDNumber: "21861929",
    RegisteredPopulation: "420",
    VotingStationName: "ELETSANANG EDUCARE",
    Address: "MOTSHBI SQUARE  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.2088",
    Longitude: "26.2387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6023",
    WardLookupId: "4085",
    VDNumber: "21861930",
    RegisteredPopulation: "844",
    VotingStationName: "MARIA`S DAY CARE",
    Address: "620 MOTSHABI SQUARE  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.2175",
    Longitude: "26.2362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6024",
    WardLookupId: "4086",
    VDNumber: "21860726",
    RegisteredPopulation: "1308",
    VotingStationName: "TOKA PRIMARY SCHOOL",
    Address: "ERF 36022  FREEDOM SQUARE  BLOEMFONTEIN",
    Latitude: "-29.1773",
    Longitude: "26.2572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6025",
    WardLookupId: "4086",
    VDNumber: "21860861",
    RegisteredPopulation: "2226",
    VotingStationName: "KOPANONG SECONDARY SCHOOL",
    Address: "33039 TURFLAAGTE  BLOEMFONTEIN",
    Latitude: "-29.1844",
    Longitude: "26.2554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6026",
    WardLookupId: "4086",
    VDNumber: "21860883",
    RegisteredPopulation: "1795",
    VotingStationName: "REHAUHETSWE CRECHE",
    Address: "CHRIS HANI  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.1931",
    Longitude: "26.2604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6027",
    WardLookupId: "4086",
    VDNumber: "21860962",
    RegisteredPopulation: "1696",
    VotingStationName: "MAMOHAU PRE-SCHOOL",
    Address: "28936 CHRIS HANI  BLOEMFONTEIN",
    Latitude: "-29.1803",
    Longitude: "26.2653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6028",
    WardLookupId: "4086",
    VDNumber: "21861121",
    RegisteredPopulation: "1034",
    VotingStationName: "SEDI LA DITJHABA CATHOLIC CHURCH",
    Address: "CHRIS HANI PARK  CHRIS HANI  MANGAUNG [BLOEMFONTEIN]",
    Latitude: "-29.1897",
    Longitude: "26.2644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6029",
    WardLookupId: "4086",
    VDNumber: "21861547",
    RegisteredPopulation: "798",
    VotingStationName: "BOTLEHADI PRIMARY SCHOOL",
    Address: "CHRIS HANI  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.1916",
    Longitude: "26.2676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6030",
    WardLookupId: "4086",
    VDNumber: "21861558",
    RegisteredPopulation: "649",
    VotingStationName: "MINA`S DAY CARE",
    Address: "CHRIS HANI  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.1978",
    Longitude: "26.2582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6031",
    WardLookupId: "4087",
    VDNumber: "21860906",
    RegisteredPopulation: "1690",
    VotingStationName: "ATLEHANG SECONDARY SCHOOL",
    Address: "449 RAMALALA STR  KAGISANONG  BLOEMFONTEIN",
    Latitude: "-29.1964",
    Longitude: "26.2423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6032",
    WardLookupId: "4087",
    VDNumber: "21860939",
    RegisteredPopulation: "2356",
    VotingStationName: "CUSIAN CHURCH (TOPIA)",
    Address: "PHASE 3  MANGAUNG PHASE 3  PHASE3 MANGAUNG",
    Latitude: "-29.2037",
    Longitude: "26.2622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6033",
    WardLookupId: "4087",
    VDNumber: "21861143",
    RegisteredPopulation: "1932",
    VotingStationName: "KOPANANG DAY CARE CENTRE",
    Address: "MANGAUNG  CALEB MOTSHABI  BLOEMFONTEIN",
    Latitude: "-29.2066",
    Longitude: "26.2603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6034",
    WardLookupId: "4087",
    VDNumber: "21861569",
    RegisteredPopulation: "1784",
    VotingStationName: "ATTA EL ROOI CHURCH",
    Address: "TURFLAAGTE 2 IPOPENG  MANGAUNG  MANGAUNG",
    Latitude: "-29.1956",
    Longitude: "26.252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6035",
    WardLookupId: "4087",
    VDNumber: "21861570",
    RegisteredPopulation: "942",
    VotingStationName: "EVANGELICAL CHRISTIAN CENTRE",
    Address: "PHASE 3  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.2016",
    Longitude: "26.2663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6036",
    WardLookupId: "4087",
    VDNumber: "21861941",
    RegisteredPopulation: "1451",
    VotingStationName: "TENT AT THUTO KE THEBE PRIMARY SCHOOL",
    Address: "KGOTSONG SQUARE  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.2145",
    Longitude: "26.2525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6037",
    WardLookupId: "4088",
    VDNumber: "21860489",
    RegisteredPopulation: "1072",
    VotingStationName: "ROCKLANDS COMMUNITY HALL",
    Address: "MOSHOESHOE RD  ROCKLANDS  BLOEMFONTEIN",
    Latitude: "-29.1837",
    Longitude: "26.2353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6038",
    WardLookupId: "4088",
    VDNumber: "21860524",
    RegisteredPopulation: "1576",
    VotingStationName: "KGABANE PRIMARY SCHOOL",
    Address: "11874 MALEFANE STREET  ROCKLANDS  BLOEMFONTEIN",
    Latitude: "-29.1769",
    Longitude: "26.2362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6039",
    WardLookupId: "4088",
    VDNumber: "21860669",
    RegisteredPopulation: "1169",
    VotingStationName: "MOTHUSI COMBINED SCHOOL",
    Address: "16943 TSEKELETSA STREET  ROCKLANDS  BLOEMFONTEIN",
    Latitude: "-29.1737",
    Longitude: "26.2311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6040",
    WardLookupId: "4088",
    VDNumber: "21860670",
    RegisteredPopulation: "1241",
    VotingStationName: "LEREKO SECONDARY SCHOOL",
    Address: "7855 MONAHENG STR  KAGISANONG  BLOEMFONTEIN",
    Latitude: "-29.1839",
    Longitude: "26.2377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6041",
    WardLookupId: "4088",
    VDNumber: "21860681",
    RegisteredPopulation: "1965",
    VotingStationName: "NZAME PRIMARY SCHOOL",
    Address: "10792 MAKOANE STR  PHELINDABA  BLOEMFONTEIN",
    Latitude: "-29.1836",
    Longitude: "26.2434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6042",
    WardLookupId: "4089",
    VDNumber: "21860793",
    RegisteredPopulation: "2059",
    VotingStationName: "TSOLETSANG PRIMARY SCHOOL",
    Address: "6184 LOBERE STREET  KAGISANONG  BLOEMFONTEIN",
    Latitude: "-29.1931",
    Longitude: "26.2304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6043",
    WardLookupId: "4089",
    VDNumber: "21860827",
    RegisteredPopulation: "1384",
    VotingStationName: "RUTANANG INTERMEDIATE SCHOOL",
    Address: "6269 TLHAPANE STREET  ROCKLANDS  BLOEMFONTEIN",
    Latitude: "-29.1884",
    Longitude: "26.2321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6044",
    WardLookupId: "4089",
    VDNumber: "21860838",
    RegisteredPopulation: "1157",
    VotingStationName: "TEBELELO PRIMARY SCHOOL",
    Address: "14618 MOIPOLAI STREET  KAGISNONG  BLOEMFONTEIN",
    Latitude: "-29.1919",
    Longitude: "26.2388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6045",
    WardLookupId: "4089",
    VDNumber: "21860849",
    RegisteredPopulation: "1731",
    VotingStationName: "IKAELELO HIGH SCHOOL",
    Address: "3051 MOIPOLAI STREET  KAGISANONG  BLOEMFONTEIN",
    Latitude: "-29.191",
    Longitude: "26.2409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6046",
    WardLookupId: "4089",
    VDNumber: "21861008",
    RegisteredPopulation: "2350",
    VotingStationName: "TSHOLOFELO PRIMARY SCHOOL",
    Address: "21751 TAU   KAGISANONG  BLOEMFONTEIN",
    Latitude: "-29.1985",
    Longitude: "26.2381",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6047",
    WardLookupId: "4089",
    VDNumber: "21861581",
    RegisteredPopulation: "451",
    VotingStationName: "LEKHULONG SECONDARY SCHOOL",
    Address: "5081 MOJATAU STREET  ROCKLANDS  BLOEMFONTEIN",
    Latitude: "-29.1921",
    Longitude: "26.235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6048",
    WardLookupId: "4090",
    VDNumber: "21860478",
    RegisteredPopulation: "1848",
    VotingStationName: "KARABELO PRIMARY SCHOOL",
    Address: "7797 MOLATOLE STREET  ROCKLANDS  BLOEMFONTEIN",
    Latitude: "-29.1768",
    Longitude: "26.231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6049",
    WardLookupId: "4090",
    VDNumber: "21860658",
    RegisteredPopulation: "1961",
    VotingStationName: "UNITING SOUTH REFORMED CHURCH",
    Address: "BLOEMANDA  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.1782",
    Longitude: "26.2251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6050",
    WardLookupId: "4090",
    VDNumber: "21860805",
    RegisteredPopulation: "1255",
    VotingStationName: "MOTHEO TVET (HILLSIDE VIEW CAMPUS)",
    Address: "KHOMO STREET  BLOMANDA  BLOEMFONTEIN",
    Latitude: "-29.188",
    Longitude: "26.2209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6051",
    WardLookupId: "4090",
    VDNumber: "21860816",
    RegisteredPopulation: "787",
    VotingStationName: "MOEMEDI SECONDARY SCHOOL",
    Address: "17104 MOKHUTLE STREET  KAGISANONG  BLOEMFONTEIN",
    Latitude: "-29.1818",
    Longitude: "26.2321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6052",
    WardLookupId: "4090",
    VDNumber: "21861097",
    RegisteredPopulation: "783",
    VotingStationName: "TSOSELETSO SECONDARY SCHOOL",
    Address: "11936 DM SELEMELA ROAD  ROCKLANDS  MANGAUNG [BLOEMFONTEIN]",
    Latitude: "-29.1845",
    Longitude: "26.2216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6053",
    WardLookupId: "4090",
    VDNumber: "21861592",
    RegisteredPopulation: "894",
    VotingStationName: "LECHABILE DAY CARE",
    Address: "DITIRA STREET  BLOEMANDA  BLOEMFONTEIN",
    Latitude: "-29.1868",
    Longitude: "26.2259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6054",
    WardLookupId: "4091",
    VDNumber: "21860399",
    RegisteredPopulation: "1253",
    VotingStationName: "TENT AT JOE SOLOMON PARK",
    Address: "PARISH AVENUE  HEIDEDAL  BLOEMFONTEIN",
    Latitude: "-29.1329",
    Longitude: "26.2407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6055",
    WardLookupId: "4091",
    VDNumber: "21860423",
    RegisteredPopulation: "1293",
    VotingStationName: "NORMAN DOUBELL HALL",
    Address: "REMBRANDT CRES  HEIDEDAL  BLOEMFONTEIN",
    Latitude: "-29.145",
    Longitude: "26.2509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6056",
    WardLookupId: "4091",
    VDNumber: "21860434",
    RegisteredPopulation: "2289",
    VotingStationName: "CREDENCE PRIMARY SCHOOL",
    Address: "PIETERSEN CRESCENT  HEIDEDAL  BLOEMFONTEIN",
    Latitude: "-29.1504",
    Longitude: "26.2558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6057",
    WardLookupId: "4091",
    VDNumber: "21860445",
    RegisteredPopulation: "1479",
    VotingStationName: "OLYMPIA PRIMARY SCHOOL (HEIDEDAL)",
    Address: "TITUS LOUW STR  HEIDEDAL  BLOEMFONTEIN",
    Latitude: "-29.1458",
    Longitude: "26.2561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6058",
    WardLookupId: "4091",
    VDNumber: "21860535",
    RegisteredPopulation: "1771",
    VotingStationName: "DR BLOK SECONDARY SCHOOL",
    Address: "ABDURAMAN STREET  HEIDEDAL  BLOEMFONTEIN",
    Latitude: "-29.1366",
    Longitude: "26.246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6059",
    WardLookupId: "4092",
    VDNumber: "21860265",
    RegisteredPopulation: "597",
    VotingStationName: "ST PATRICS SCHOOL",
    Address: "CIPRUS STREET  LYNCHFIELD  BLOEMFONTEIN",
    Latitude: "-29.1569",
    Longitude: "26.3638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6060",
    WardLookupId: "4092",
    VDNumber: "21860412",
    RegisteredPopulation: "1249",
    VotingStationName: "BLOEMFONTEIN EAST INTERMEDIATE SCHOOL",
    Address: "100 EEUFEES ROAD  BLOEMSPRUIT  BLOEMFONTEIN",
    Latitude: "-29.1582",
    Longitude: "26.3163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6061",
    WardLookupId: "4092",
    VDNumber: "21861277",
    RegisteredPopulation: "2300",
    VotingStationName: "TENT AT BERGMAN EXTENTION 4",
    Address: "GRASSLAND  BLOEMFONTEIN",
    Latitude: "-29.163",
    Longitude: "26.2905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6062",
    WardLookupId: "4092",
    VDNumber: "21861738",
    RegisteredPopulation: "1143",
    VotingStationName: "TENT (NEXT TO SAREL CELLIER ROAD)",
    Address: "SAREL CELLIER ROAD  GRASSLAND EX 4  BLOEMFONTEIN",
    Latitude: "-29.1682",
    Longitude: "26.2961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6063",
    WardLookupId: "4092",
    VDNumber: "21861749",
    RegisteredPopulation: "1450",
    VotingStationName: "REV. NYONGWANE`S RESIDENCE",
    Address: "KHAYELITSHA  MANGAUNG BLOEMFONTEIN",
    Latitude: "-29.1552",
    Longitude: "26.2995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6064",
    WardLookupId: "4092",
    VDNumber: "21861750",
    RegisteredPopulation: "1149",
    VotingStationName: "HIS PRESENCE MINISTRY",
    Address: "BERGMAN SQUARE  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.1581",
    Longitude: "26.2862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6065",
    WardLookupId: "4092",
    VDNumber: "22080080",
    RegisteredPopulation: "300",
    VotingStationName: "UITKOMS SCHOOL",
    Address: "BERGKRAAL FARM  DISTRICT OF BLOEMFONTEIN  BLOEMFONTEIN",
    Latitude: "-29.0417",
    Longitude: "26.4559",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6066",
    WardLookupId: "4092",
    VDNumber: "22080248",
    RegisteredPopulation: "797",
    VotingStationName: "TENT AT MANDELA VIEW",
    Address: "MMAKATHALE STREET  MANDELA VIEW  BLOEMFONTEIN",
    Latitude: "-29.1307",
    Longitude: "26.3825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6067",
    WardLookupId: "4093",
    VDNumber: "21860131",
    RegisteredPopulation: "3055",
    VotingStationName: "FAUNA PRIMARY SCHOOL",
    Address: "SPRINGBOKWEG  FAUNA  BLOEMFONTEIN",
    Latitude: "-29.169",
    Longitude: "26.1909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6068",
    WardLookupId: "4093",
    VDNumber: "21860355",
    RegisteredPopulation: "795",
    VotingStationName: "PHUTANANG PRIMARY SCHOOL (FERREIRA)",
    Address: "DS KOK STREET  FERREIRA  BLOEMFONTEIN",
    Latitude: "-29.2073",
    Longitude: "26.1777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6069",
    WardLookupId: "4093",
    VDNumber: "21860940",
    RegisteredPopulation: "1476",
    VotingStationName: "EERSTELING FARM SCHOOL",
    Address: "OLD PETRUSBURG ROAD, KWAGGAFONTEIN  BAINSVLEI  BLOEMFONTEIN",
    Latitude: "-29.1431",
    Longitude: "26.1086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6070",
    WardLookupId: "4093",
    VDNumber: "21861042",
    RegisteredPopulation: "1930",
    VotingStationName: "LOURIER PARK PRIMARY SCHOOL",
    Address: "63 KOKKERBOOM STREET  BLOEMFONTEIN  BLOEMFONTEIN",
    Latitude: "-29.1803",
    Longitude: "26.1775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6071",
    WardLookupId: "4093",
    VDNumber: "21861312",
    RegisteredPopulation: "710",
    VotingStationName: "ONSE RUST PRIMARY SCHOOL",
    Address: "14 GENL HATTING STRAAT  BLOEMFONTEIN  BLOEMFONTEIN",
    Latitude: "-29.1603",
    Longitude: "26.1988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6072",
    WardLookupId: "4093",
    VDNumber: "22080103",
    RegisteredPopulation: "333",
    VotingStationName: "GROOTVLEI SPORTS CLUB",
    Address: "DEWETSDORP RD  GROOTVLEI  BLOEMFONTEIN",
    Latitude: "-29.2285",
    Longitude: "26.2948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6073",
    WardLookupId: "4093",
    VDNumber: "22080125",
    RegisteredPopulation: "290",
    VotingStationName: "SHOOTING RANGE HALL",
    Address: "REDDERSBURG ROAD  FARM PARADYS  BLOEMFONTEIN",
    Latitude: "-29.2576",
    Longitude: "26.2154",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6074",
    WardLookupId: "4093",
    VDNumber: "22080158",
    RegisteredPopulation: "175",
    VotingStationName: "KGOTSOFALO FARM SCHOOL",
    Address: "TIERPOORT  TIERPOORT JERUSALEM  BLOEMFONTEIN",
    Latitude: "-29.4403",
    Longitude: "26.0912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6075",
    WardLookupId: "4093",
    VDNumber: "22080204",
    RegisteredPopulation: "271",
    VotingStationName: "WILLOWS PRIMARY SCHOOL",
    Address: "WILLOWS  TEMPE  BLOEMFONTEIN",
    Latitude: "-29.2555",
    Longitude: "26.0569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6076",
    WardLookupId: "4094",
    VDNumber: "21860018",
    RegisteredPopulation: "827",
    VotingStationName: "APLHA EARLY CHILD DEVELOPMENT CENTRE",
    Address: "WATKEY  ORANJESIG  BLOEMFONTEIN",
    Latitude: "-29.1309",
    Longitude: "26.2195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6077",
    WardLookupId: "4094",
    VDNumber: "21860029",
    RegisteredPopulation: "1871",
    VotingStationName: "EHRLICH PARK FIRE STATION",
    Address: "BLACKWOOD ROAD ERF 15921  ORANJESIG  BLOEMFONTEIN",
    Latitude: "-29.1529",
    Longitude: "26.217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6078",
    WardLookupId: "4094",
    VDNumber: "21860142",
    RegisteredPopulation: "1477",
    VotingStationName: "FREE STATE TENNIS STADIUM",
    Address: "ATT HORAK AVE  WILLOWS  BLOEMFONTEIN",
    Latitude: "-29.1169",
    Longitude: "26.2035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6079",
    WardLookupId: "4094",
    VDNumber: "21860153",
    RegisteredPopulation: "2820",
    VotingStationName: "TECHNICAL HIGH SCHOOL LOUIS BOTHA",
    Address: "23 VICTORIA RD  WILLOWS  BLOEMFONTEIN",
    Latitude: "-29.1242",
    Longitude: "26.2083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6080",
    WardLookupId: "4094",
    VDNumber: "21860210",
    RegisteredPopulation: "1133",
    VotingStationName: "BLOEMFONTEIN COLLEGE",
    Address: "GODDARD STREET  C.B.D.  BLOEMFONTEIN",
    Latitude: "-29.1207",
    Longitude: "26.2215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6081",
    WardLookupId: "4094",
    VDNumber: "21860243",
    RegisteredPopulation: "995",
    VotingStationName: "MUNICIPAL BOWLS CLUB",
    Address: "CNR ZASTRON AND DAN PIENAAR  WESTDENE  BLOEMFONTEIN",
    Latitude: "-29.1065",
    Longitude: "26.2061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6082",
    WardLookupId: "4094",
    VDNumber: "21861244",
    RegisteredPopulation: "719",
    VotingStationName: "MANGAUNG TRAFFIC DEPARTMENT",
    Address: "BLOEMFONTEIN  ",
    Latitude: "-29.1576",
    Longitude: "26.2176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6083",
    WardLookupId: "4095",
    VDNumber: "21860287",
    RegisteredPopulation: "2568",
    VotingStationName: "ST MICHAELS SCHOOL",
    Address: "111 KLERCK AVENUE  BRANDWAG  BLOEMFONTEIN",
    Latitude: "-29.1037",
    Longitude: "26.201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6084",
    WardLookupId: "4095",
    VDNumber: "21860298",
    RegisteredPopulation: "2472",
    VotingStationName: "SENTRAAL SECONDARY SCHOOL",
    Address: "GENL CRONJE STREET  DAN PIENAAR  BLOEMFONTEIN",
    Latitude: "-29.0871",
    Longitude: "26.2103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6085",
    WardLookupId: "4095",
    VDNumber: "21860322",
    RegisteredPopulation: "2004",
    VotingStationName: "NG GEMEENTE HEUWELKRUIN",
    Address: "HEUWILSIG  BLOEMFONTEIN  HEUWELSIG",
    Latitude: "-29.0794",
    Longitude: "26.1973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6086",
    WardLookupId: "4095",
    VDNumber: "21861356",
    RegisteredPopulation: "1123",
    VotingStationName: "BREBNER PRIMARY SCHOOL",
    Address: "31 DEALE ROAD  BAYSWATER  BLOEMFONTEIN",
    Latitude: "-29.0842",
    Longitude: "26.224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6087",
    WardLookupId: "4095",
    VDNumber: "21861367",
    RegisteredPopulation: "1469",
    VotingStationName: "TENT (AT PENTAGON)",
    Address: "1111 PENTAGON  PENTAGON PARK  BLOEMFONTEIN",
    Latitude: "-29.0757",
    Longitude: "26.2175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6088",
    WardLookupId: "4095",
    VDNumber: "21861648",
    RegisteredPopulation: "468",
    VotingStationName: "TENT (AT HEUWELSIG RESERVE PARK)",
    Address: "HEUWELSIG  BLOEMFONTEIN",
    Latitude: "-29.0883",
    Longitude: "26.1997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6089",
    WardLookupId: "4095",
    VDNumber: "21861985",
    RegisteredPopulation: "275",
    VotingStationName: "BLOEMHEUWEL NG KERK",
    Address: "HARRBURGER CRES  WESTDENE  BLOEMFONTEIN",
    Latitude: "-29.1022",
    Longitude: "26.2076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6090",
    WardLookupId: "4096",
    VDNumber: "21860221",
    RegisteredPopulation: "2363",
    VotingStationName: "BRAM FISHER BUILDING",
    Address: "5 C/O NELSON MANDELA DRIVE  C.B.D  BLOEMFONTEIN",
    Latitude: "-29.1144",
    Longitude: "26.2168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6091",
    WardLookupId: "4096",
    VDNumber: "21860232",
    RegisteredPopulation: "2423",
    VotingStationName: "SENTRAAL PRIMARY SCHOOL",
    Address: "32 RAYMOND MHLABA STREET  NAVALSIG  BLOEMFONTEIN",
    Latitude: "-29.1083",
    Longitude: "26.2313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6092",
    WardLookupId: "4096",
    VDNumber: "21860300",
    RegisteredPopulation: "2633",
    VotingStationName: "ORANJE MEISIESKOOL",
    Address: "3 HARRY SMITH STREET  ARBORETUM  BLOEMFONTEIN",
    Latitude: "-29.1013",
    Longitude: "26.2198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6093",
    WardLookupId: "4096",
    VDNumber: "21861659",
    RegisteredPopulation: "732",
    VotingStationName: "ST JOSEPH CHILDCARE",
    Address: "93 CNR GRUIS & EXTON  HILTON  BLOEMFONTEIN",
    Latitude: "-29.1034",
    Longitude: "26.2404",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6094",
    WardLookupId: "4096",
    VDNumber: "21861963",
    RegisteredPopulation: "419",
    VotingStationName: "DR.BETHUEL SETAI INTERMEDIATE SCHOOL",
    Address: "BLOEMFONTEIN  ",
    Latitude: "-29.0677",
    Longitude: "26.2756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6095",
    WardLookupId: "4096",
    VDNumber: "21861974",
    RegisteredPopulation: "427",
    VotingStationName: "TENT AT RODERICK CRESCENT",
    Address: "RODERICK   BLOEMFONTEIN  NOORTHOEK",
    Latitude: "-29.0855",
    Longitude: "26.2568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6096",
    WardLookupId: "4097",
    VDNumber: "21860366",
    RegisteredPopulation: "3295",
    VotingStationName: "TENT  (NP V WYK LOUW STREET)",
    Address: "NP VAN WYK LOUW STR  LANGENHOVEN PARK  BLOEMFONTEIN",
    Latitude: "-29.0888",
    Longitude: "26.157",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6097",
    WardLookupId: "4097",
    VDNumber: "21860377",
    RegisteredPopulation: "2965",
    VotingStationName: "BAINSVLEI LIBRARY",
    Address: "8 BOERNEEF  BAINSVLEI  BLOEMFONTEIN",
    Latitude: "-29.0982",
    Longitude: "26.1563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6098",
    WardLookupId: "4097",
    VDNumber: "21861671",
    RegisteredPopulation: "900",
    VotingStationName: "TENT (AT CHRIS BARNARD)",
    Address: "JAN MARX  LANGENHOVEN PARK  BLOEMFONTEIN",
    Latitude: "-29.1035",
    Longitude: "26.1521",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6099",
    WardLookupId: "4097",
    VDNumber: "21861682",
    RegisteredPopulation: "1100",
    VotingStationName: "TENT (AT ANNA M LOUW)",
    Address: "ANNA M LOUW  LANGENHOVEN PARK  BLOEMFONTEIN",
    Latitude: "-29.1035",
    Longitude: "26.1618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6100",
    WardLookupId: "4097",
    VDNumber: "21861862",
    RegisteredPopulation: "850",
    VotingStationName: "NG KERK LANGENHOVENPARK",
    Address: "SAREL PRETORIUS STREET   LANGENHOVEN PARK   BLOEMFONTEIN",
    Latitude: "-29.0941",
    Longitude: "26.1537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6101",
    WardLookupId: "4098",
    VDNumber: "21860164",
    RegisteredPopulation: "1745",
    VotingStationName: "DR BÃ–HMER SECONDARY SCHOOL",
    Address: "WALTER SISULU ROAD  UNIVERSITAS  BLOEMFONTEIN",
    Latitude: "-29.1249",
    Longitude: "26.1911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6102",
    WardLookupId: "4098",
    VDNumber: "21860186",
    RegisteredPopulation: "1384",
    VotingStationName: "EUNICE SECONDARY SCHOOL (CLUB HOUSE)",
    Address: "3 JOCK MEIRING STREET  PARK WEST  BLOEMFONTEIN",
    Latitude: "-29.1163",
    Longitude: "26.197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6103",
    WardLookupId: "4098",
    VDNumber: "21860209",
    RegisteredPopulation: "1504",
    VotingStationName: "NG KERK UNIVERSITAS PARKSIDE",
    Address: "HENDRIK KOTZE  UNIVERSITAS RIDGE  BLOEMFONTEIN",
    Latitude: "-29.0982",
    Longitude: "26.1682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6104",
    WardLookupId: "4098",
    VDNumber: "21861323",
    RegisteredPopulation: "3838",
    VotingStationName: "TENT NEXT TO PROVINCIAL ARCHIVING",
    Address: "BLOEMFONTEIN  ",
    Latitude: "-29.1131",
    Longitude: "26.1835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6105",
    WardLookupId: "4098",
    VDNumber: "21861660",
    RegisteredPopulation: "815",
    VotingStationName: "TENT (AT WYNAND MOUTON)",
    Address: "5 WYNAND MOUTON  PARK WEST  BLOEMFONTEIN",
    Latitude: "-29.1144",
    Longitude: "26.1792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6106",
    WardLookupId: "4099",
    VDNumber: "21860030",
    RegisteredPopulation: "2140",
    VotingStationName: "SAND DU PLESSIS PRIMARY SCHOOL",
    Address: "HOSPITAL PARK  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.1385",
    Longitude: "26.1987",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6107",
    WardLookupId: "4099",
    VDNumber: "21860052",
    RegisteredPopulation: "1071",
    VotingStationName: "TENT AT DE WET PARK",
    Address: "BLOEMFONTEIN  ",
    Latitude: "-29.1408",
    Longitude: "26.2021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6108",
    WardLookupId: "4099",
    VDNumber: "21860063",
    RegisteredPopulation: "2001",
    VotingStationName: "FICHARDTPARK SECONDARY SCHOOL",
    Address: "24 HUDSONRYLAAN  FICHARDT PARK  BLOEMFONTEIN",
    Latitude: "-29.15",
    Longitude: "26.1847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6109",
    WardLookupId: "4099",
    VDNumber: "21861233",
    RegisteredPopulation: "2357",
    VotingStationName: "TENT (NEXT TO FLEURDAL SHOPPING CENTRE)",
    Address: "126 BARRY RICHTER  FLEURDAL  FEURDAL",
    Latitude: "-29.153",
    Longitude: "26.1944",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6110",
    WardLookupId: "4099",
    VDNumber: "21861378",
    RegisteredPopulation: "1003",
    VotingStationName: "BLOEMFONTEIN HIGH SOUTH SCHOOL",
    Address: "2 MAGERSFONTEIN  GENERAAL DE WET  BLOEMFONTEIN",
    Latitude: "-29.1477",
    Longitude: "26.1964",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6111",
    WardLookupId: "4099",
    VDNumber: "21861705",
    RegisteredPopulation: "446",
    VotingStationName: "DOXA DEO MINISTRIES",
    Address: "FICHARDT PARK  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.1511",
    Longitude: "26.1894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6112",
    WardLookupId: "4100",
    VDNumber: "21860085",
    RegisteredPopulation: "2304",
    VotingStationName: "FICHARDTPARK PRIMARY SCHOOL",
    Address: "WELTHAGEN STREET  FICHARDT PARK  BLOEMFONTEIN",
    Latitude: "-29.1396",
    Longitude: "26.1796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6113",
    WardLookupId: "4100",
    VDNumber: "21860096",
    RegisteredPopulation: "1662",
    VotingStationName: "PRESIDENT BRAND PRIMARY SCHOOL",
    Address: "WAG N BIETJIEBOSLAAN  PELLISSIER  BLOEMFONTEIN",
    Latitude: "-29.1473",
    Longitude: "26.168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6114",
    WardLookupId: "4100",
    VDNumber: "21860108",
    RegisteredPopulation: "1213",
    VotingStationName: "TENT (AT JAN ENSLIN AVENUE)",
    Address: "JAN ENSLIN AVE  FICHARDT PARK  BLOEMFONTEIN",
    Latitude: "-29.1578",
    Longitude: "26.1803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6115",
    WardLookupId: "4100",
    VDNumber: "21860973",
    RegisteredPopulation: "2546",
    VotingStationName: "TENT (AT PELLISSIER DRIVE)",
    Address: "PELLISSIER DRIVE  PELLISSIER  BLOEMFONTEIN",
    Latitude: "-29.16",
    Longitude: "26.1704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6116",
    WardLookupId: "4100",
    VDNumber: "21861334",
    RegisteredPopulation: "446",
    VotingStationName: "ROSENHOF HIGH SCHOOL",
    Address: "GUSTAV  FICHARDT PARK  BLOEMFONTEIN",
    Latitude: "-29.1485",
    Longitude: "26.1766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6117",
    WardLookupId: "4100",
    VDNumber: "21861716",
    RegisteredPopulation: "434",
    VotingStationName: "BOBBIES PARK",
    Address: "2 PASTEUR  HOSPITAL PARK  BLOEMFONTEIN",
    Latitude: "-29.1354",
    Longitude: "26.1893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6118",
    WardLookupId: "4100",
    VDNumber: "21861727",
    RegisteredPopulation: "707",
    VotingStationName: "TENT (AT JAN FISKAAL AVENUE)",
    Address: "LIEFSTE MARYN  PELLISSIER  BLOEMFONTEIN",
    Latitude: "-29.1644",
    Longitude: "26.1781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6119",
    WardLookupId: "4101",
    VDNumber: "21860074",
    RegisteredPopulation: "2551",
    VotingStationName: "WILGEHOF PRIMARY SCHOOL",
    Address: "ROSE AVENUE  WILGEHOF  BLOEMFONTEIN",
    Latitude: "-29.1297",
    Longitude: "26.1945",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6120",
    WardLookupId: "4101",
    VDNumber: "21860175",
    RegisteredPopulation: "1600",
    VotingStationName: "JIM FOUCHE SECONDARY SCHOOL",
    Address: "1 WIDEALSLAAN  GARDENIASPARK  BLOEMFONTEIN",
    Latitude: "-29.1283",
    Longitude: "26.1757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6121",
    WardLookupId: "4101",
    VDNumber: "21860197",
    RegisteredPopulation: "3713",
    VotingStationName: "UNIVERSITAS PRIMARY SCHOOL",
    Address: "ROSS STREET  UNIVERSITAS  BLOEMFONTEIN",
    Latitude: "-29.12",
    Longitude: "26.1788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6122",
    WardLookupId: "4101",
    VDNumber: "21861693",
    RegisteredPopulation: "651",
    VotingStationName: "WILGEHOF MUNICIPAL CLINIC",
    Address: "LINARIA STREET  WILGEHOF  BLOEMFONTEIN",
    Latitude: "-29.1286",
    Longitude: "26.1832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6123",
    WardLookupId: "4102",
    VDNumber: "21870187",
    RegisteredPopulation: "2539",
    VotingStationName: "ST PETER AND PAUL ROMAN CATHOLIC",
    Address: "260 SECTION F  BOTSHABELO WEST",
    Latitude: "-29.2201",
    Longitude: "26.6753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6124",
    WardLookupId: "4102",
    VDNumber: "21870457",
    RegisteredPopulation: "2129",
    VotingStationName: "PONTSHENG PRIMARY SCHOOL",
    Address: "1840 SECTION F  BOTSHABELO WEST",
    Latitude: "-29.2151",
    Longitude: "26.6694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6125",
    WardLookupId: "4102",
    VDNumber: "21870480",
    RegisteredPopulation: "550",
    VotingStationName: "KATLEHO DAY CARE CENTRE",
    Address: "3110 NEW STANDS SECTION F  F SECTION  BOTSHABELO",
    Latitude: "-29.2248",
    Longitude: "26.6714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6126",
    WardLookupId: "4102",
    VDNumber: "21870491",
    RegisteredPopulation: "1166",
    VotingStationName: "JESUS CHRIST PRAYER MINISTRIES",
    Address: "3983 F SECTION  BOTSHABELO  MANGAUNG [BLOEMFONTEIN]",
    Latitude: "-29.2113",
    Longitude: "26.6658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6127",
    WardLookupId: "4102",
    VDNumber: "21870514",
    RegisteredPopulation: "435",
    VotingStationName: "IKOPANYENG DAY CARE",
    Address: "SECTION F EXT  BOTSHABELO WEST  BOTSHABELO WEST",
    Latitude: "-29.2108",
    Longitude: "26.6532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6128",
    WardLookupId: "4102",
    VDNumber: "21870525",
    RegisteredPopulation: "508",
    VotingStationName: "MAROTHODI DAY CARE",
    Address: "2459 SECTION F EXT 1  BOTSHABELO  BOTSHABELO WEST",
    Latitude: "-29.2059",
    Longitude: "26.6593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6129",
    WardLookupId: "4102",
    VDNumber: "21870536",
    RegisteredPopulation: "392",
    VotingStationName: "LEMOHANG PRE-SCHOOL",
    Address: "795 SECTION F EXT1  BOTSHABELO   BOTSHABELO WEST",
    Latitude: "-29.2078",
    Longitude: "26.6554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6130",
    WardLookupId: "4102",
    VDNumber: "21870659",
    RegisteredPopulation: "566",
    VotingStationName: "LERATO PRE-SCHOOL",
    Address: "3559 NEW STANDS SECTION F  SECTION F  BOTSHABELO",
    Latitude: "-29.2292",
    Longitude: "26.6772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6131",
    WardLookupId: "4102",
    VDNumber: "22080237",
    RegisteredPopulation: "59",
    VotingStationName: "RESFONTEIN DAM",
    Address: "BOTSHABELO ROAD  MANGAUNG  ",
    Latitude: "-29.2714",
    Longitude: "26.617",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6132",
    WardLookupId: "4103",
    VDNumber: "21870075",
    RegisteredPopulation: "1193",
    VotingStationName: "LETHABO CRECHE",
    Address: "3380 SECTION K  K SECTION  BOTSHABELO",
    Latitude: "-29.212",
    Longitude: "26.7002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6133",
    WardLookupId: "4103",
    VDNumber: "21870299",
    RegisteredPopulation: "1278",
    VotingStationName: "NTHAPELENG PRIMARY SCHOOL",
    Address: "NO 1691  SECTION K  BOTSHABELO",
    Latitude: "-29.2078",
    Longitude: "26.6932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6134",
    WardLookupId: "4103",
    VDNumber: "21870334",
    RegisteredPopulation: "1415",
    VotingStationName: "SEEMAHALE HIGH SCHOOL",
    Address: "2633 SECTION J  BOTSHABELO",
    Latitude: "-29.2087",
    Longitude: "26.675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6135",
    WardLookupId: "4103",
    VDNumber: "21870446",
    RegisteredPopulation: "1293",
    VotingStationName: "DIBENG SA TSEBO PRIMARY SCHOOL",
    Address: "253 SECTION K  BOTSHABELO",
    Latitude: "-29.2071",
    Longitude: "26.6695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6136",
    WardLookupId: "4103",
    VDNumber: "21870547",
    RegisteredPopulation: "439",
    VotingStationName: "NTEDISENG PRIMARY SCHOOL",
    Address: "SECTION K  BOTSHABELO K",
    Latitude: "-29.2067",
    Longitude: "26.6763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6137",
    WardLookupId: "4103",
    VDNumber: "21870558",
    RegisteredPopulation: "436",
    VotingStationName: "MPULELE MENYAKO CRECHE",
    Address: "SECTION K EXT  BOTSHABELO WEST",
    Latitude: "-29.1989",
    Longitude: "26.6621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6138",
    WardLookupId: "4103",
    VDNumber: "21870569",
    RegisteredPopulation: "475",
    VotingStationName: "DITHOLWANA PRE SCHOOL",
    Address: "1401 SECTION K EXT 2  BOTSHABELO WEST",
    Latitude: "-29.2025",
    Longitude: "26.6627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6139",
    WardLookupId: "4103",
    VDNumber: "21870570",
    RegisteredPopulation: "828",
    VotingStationName: "TLHOLO PRIMARY SCHOOL",
    Address: "1429 EXTENTION 1 F SECTION  MANGAUNG  BOTSHABELO",
    Latitude: "-29.2024",
    Longitude: "26.6556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6140",
    WardLookupId: "4103",
    VDNumber: "21870592",
    RegisteredPopulation: "770",
    VotingStationName: "THABO  PRIMARY SCHOOL",
    Address: "1391 SECTION F  BOTSHABELO",
    Latitude: "-29.211",
    Longitude: "26.6778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6141",
    WardLookupId: "4104",
    VDNumber: "21870064",
    RegisteredPopulation: "1221",
    VotingStationName: "SELOKISA PRIMARY SCHOOL",
    Address: "2606 SECTION J  BOTSHABELO",
    Latitude: "-29.2223",
    Longitude: "26.7033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6142",
    WardLookupId: "4104",
    VDNumber: "21870086",
    RegisteredPopulation: "1699",
    VotingStationName: "MAKGULO PRIMARY SCHOOL",
    Address: "1359 SECTION J  BOTSHABELO",
    Latitude: "-29.2227",
    Longitude: "26.6873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6143",
    WardLookupId: "4104",
    VDNumber: "21870097",
    RegisteredPopulation: "2194",
    VotingStationName: "RETSAMAILE PRIMARY SCHOOL",
    Address: "153 SECTION J  BOTSHABELO",
    Latitude: "-29.2171",
    Longitude: "26.6858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6144",
    WardLookupId: "4104",
    VDNumber: "21870413",
    RegisteredPopulation: "1658",
    VotingStationName: "BOLOKEHANG PRIMARY SCHOOL",
    Address: "2710 SECTION J  BOTSHABELO  MANGAUNG",
    Latitude: "-29.2247",
    Longitude: "26.6991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6145",
    WardLookupId: "4104",
    VDNumber: "21870468",
    RegisteredPopulation: "1906",
    VotingStationName: "REENTSENG PRIMARY SCHOOL",
    Address: "2406 SECTION K  BOTSHABELO",
    Latitude: "-29.2113",
    Longitude: "26.6924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6146",
    WardLookupId: "4105",
    VDNumber: "21870019",
    RegisteredPopulation: "2035",
    VotingStationName: "SECHABA SE MAKETSE PRIMARY SCHOOL",
    Address: "5 SECTION H  BOTSHABELO",
    Latitude: "-29.2056",
    Longitude: "26.7142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6147",
    WardLookupId: "4105",
    VDNumber: "21870031",
    RegisteredPopulation: "2884",
    VotingStationName: "SEITHATI PRIMARY SCHOOL",
    Address: "2121 SECTION H3  BOTSHABELO",
    Latitude: "-29.209",
    Longitude: "26.7251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6148",
    WardLookupId: "4105",
    VDNumber: "21870053",
    RegisteredPopulation: "1605",
    VotingStationName: "LENYORA LA THUTO SCHOOL",
    Address: "3224 SECTION H  BOTSHABELO",
    Latitude: "-29.2118",
    Longitude: "26.708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6149",
    WardLookupId: "4105",
    VDNumber: "21870479",
    RegisteredPopulation: "1118",
    VotingStationName: "ADONAI DAY CARE",
    Address: "1011 G SECTION BOTSHABELO  MANGAUNG   G SECTION BOTSHABELO",
    Latitude: "-29.2188",
    Longitude: "26.7235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6150",
    WardLookupId: "4106",
    VDNumber: "21870042",
    RegisteredPopulation: "1664",
    VotingStationName: "HOHLE PRIMARY SCHOOL",
    Address: "03 SECTION G  BOTSHABELO",
    Latitude: "-29.2221",
    Longitude: "26.7171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6151",
    WardLookupId: "4106",
    VDNumber: "21870109",
    RegisteredPopulation: "1394",
    VotingStationName: "TLOTLISANG PRIMARY SCHOOL",
    Address: "1957 SECTION C  BOTSHABELO",
    Latitude: "-29.242",
    Longitude: "26.7159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6152",
    WardLookupId: "4106",
    VDNumber: "21870132",
    RegisteredPopulation: "1679",
    VotingStationName: "PHALLANG PRIMARY SCHOOL",
    Address: "246 SECTION C  BOTSHABELO",
    Latitude: "-29.2474",
    Longitude: "26.7187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6153",
    WardLookupId: "4106",
    VDNumber: "21870154",
    RegisteredPopulation: "1400",
    VotingStationName: "REFIHLILE PRIMARY SCHOOL",
    Address: "1359 SECTION C  BOTSHABELO",
    Latitude: "-29.2423",
    Longitude: "26.7222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6154",
    WardLookupId: "4106",
    VDNumber: "21870604",
    RegisteredPopulation: "569",
    VotingStationName: "NTATELENG PRIMARY SCHOOL",
    Address: "1136 SECTION G  BOTSHABELO",
    Latitude: "-29.2238",
    Longitude: "26.7093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6155",
    WardLookupId: "4107",
    VDNumber: "21870110",
    RegisteredPopulation: "1941",
    VotingStationName: "ITEKENG PRE-SCHOOL",
    Address: "2691 SECTION C  BOTSHABELO",
    Latitude: "-29.2449",
    Longitude: "26.7092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6156",
    WardLookupId: "4107",
    VDNumber: "21870121",
    RegisteredPopulation: "1617",
    VotingStationName: "PHANO PRIMARY SCHOOL",
    Address: "34 SECTION E  BOTSHABELO",
    Latitude: "-29.2558",
    Longitude: "26.7153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6157",
    WardLookupId: "4107",
    VDNumber: "21870200",
    RegisteredPopulation: "1234",
    VotingStationName: "LEROLE PRIMARY SCHOOL",
    Address: "1339 SECTION E  BOTSHABELO",
    Latitude: "-29.2641",
    Longitude: "26.7259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6158",
    WardLookupId: "4107",
    VDNumber: "21870222",
    RegisteredPopulation: "1400",
    VotingStationName: "MAHLOHONOLO INTERMEDIATE SCHOOL",
    Address: "815 SECTION E  BOTSHABELO",
    Latitude: "-29.2596",
    Longitude: "26.7217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6159",
    WardLookupId: "4107",
    VDNumber: "21870615",
    RegisteredPopulation: "484",
    VotingStationName: "RAOHANG PRIMARY SCHOOL",
    Address: "1673 SECTION E  BOTSHABELO",
    Latitude: "-29.2608",
    Longitude: "26.7252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6160",
    WardLookupId: "4108",
    VDNumber: "21870143",
    RegisteredPopulation: "1890",
    VotingStationName: "THATOHATSI PRIMARY SCHOOL",
    Address: "464 SECTION B  BOTSHABELO",
    Latitude: "-29.2509",
    Longitude: "26.7328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6161",
    WardLookupId: "4108",
    VDNumber: "21870165",
    RegisteredPopulation: "1814",
    VotingStationName: "BOTHOBAPELO PRIMARY SCHOOL",
    Address: "431 SECTION A  BOTSHABELO",
    Latitude: "-29.2561",
    Longitude: "26.7399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6162",
    WardLookupId: "4108",
    VDNumber: "21870176",
    RegisteredPopulation: "2023",
    VotingStationName: "NTHABELENG PRIMARY SCHOOL",
    Address: "193 SECTION A  BOTSHABELO",
    Latitude: "-29.2593",
    Longitude: "26.7324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6163",
    WardLookupId: "4108",
    VDNumber: "21870626",
    RegisteredPopulation: "1111",
    VotingStationName: "POPANO SECONDARY SCHOOL",
    Address: "190 SECTION A  BOTSHABELO",
    Latitude: "-29.2595",
    Longitude: "26.7374",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6164",
    WardLookupId: "4109",
    VDNumber: "21870211",
    RegisteredPopulation: "2070",
    VotingStationName: "BATJHA PRIMARY SCHOOL",
    Address: "856 SECTION T  BOTSHABELO",
    Latitude: "-29.2678",
    Longitude: "26.7133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6165",
    WardLookupId: "4109",
    VDNumber: "21870233",
    RegisteredPopulation: "1053",
    VotingStationName: "HAUHELANG DISABLED AND ECD",
    Address: "1701 SECTION T  BOTSHABELO",
    Latitude: "-29.2597",
    Longitude: "26.7052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6166",
    WardLookupId: "4109",
    VDNumber: "21870345",
    RegisteredPopulation: "2287",
    VotingStationName: "NKGOTHATSENG PRIMARY SCHOOL",
    Address: "1646 SECTION N  BOTSHABELO",
    Latitude: "-29.2839",
    Longitude: "26.7123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6167",
    WardLookupId: "4109",
    VDNumber: "21870637",
    RegisteredPopulation: "638",
    VotingStationName: "SEMOMOTELA PRIMARY SCHOOL",
    Address: "2848 SECTION N  BOTSHABELO",
    Latitude: "-29.2961",
    Longitude: "26.7081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6168",
    WardLookupId: "4109",
    VDNumber: "21870660",
    RegisteredPopulation: "1017",
    VotingStationName: "KHOTHATSO INTERMEDIATE SCHOOL",
    Address: "1253 T SECTION  BOTSHABELO",
    Latitude: "-29.2647",
    Longitude: "26.7052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6169",
    WardLookupId: "4110",
    VDNumber: "21870198",
    RegisteredPopulation: "1788",
    VotingStationName: "PRESBYTERIAN CHURCH",
    Address: "480 SECTION M  BOTSHABELO",
    Latitude: "-29.2707",
    Longitude: "26.7316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6170",
    WardLookupId: "4110",
    VDNumber: "21870244",
    RegisteredPopulation: "1995",
    VotingStationName: "THARI YA TSHEPE SCHOOL",
    Address: "NO 1704  SECTION L  BOTSHABELO",
    Latitude: "-29.2709",
    Longitude: "26.7209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6171",
    WardLookupId: "4110",
    VDNumber: "21870277",
    RegisteredPopulation: "1336",
    VotingStationName: "SEBABATSO PRIMARY SCHOOL",
    Address: "2028 M SECTION  SECTION M  BOTSHABELO",
    Latitude: "-29.2845",
    Longitude: "26.7188",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6172",
    WardLookupId: "4110",
    VDNumber: "21870301",
    RegisteredPopulation: "1601",
    VotingStationName: "SEROKI PRIMARY SCHOOL",
    Address: "802 SECTION M  BOTSHABELO",
    Latitude: "-29.2852",
    Longitude: "26.724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6173",
    WardLookupId: "4110",
    VDNumber: "21870671",
    RegisteredPopulation: "1181",
    VotingStationName: "THE CHRISTIAN CATHOLIC IN ZION CHURCH",
    Address: "2022 SECTION L  L SECTION   BOTSHABELO",
    Latitude: "-29.2752",
    Longitude: "26.72",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6174",
    WardLookupId: "4111",
    VDNumber: "21870367",
    RegisteredPopulation: "1601",
    VotingStationName: "MMULAKGORO INTERMEDIATE SCHOOL",
    Address: "844 SECTION M  BOTSHABELO",
    Latitude: "-29.2488",
    Longitude: "26.6817",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6175",
    WardLookupId: "4111",
    VDNumber: "21870378",
    RegisteredPopulation: "1378",
    VotingStationName: "LEFIKENG SCHOOL",
    Address: "1190 SECTION U  BOTSHABELO",
    Latitude: "-29.2575",
    Longitude: "26.6876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6176",
    WardLookupId: "4111",
    VDNumber: "21870389",
    RegisteredPopulation: "1862",
    VotingStationName: "NTEBALENG PRIMARY SCHOOL",
    Address: "2434 SECTION W  BOTSHABELO",
    Latitude: "-29.2554",
    Longitude: "26.6751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6177",
    WardLookupId: "4111",
    VDNumber: "21870402",
    RegisteredPopulation: "1899",
    VotingStationName: "MABELA PRIMARY SCHOOL",
    Address: "514 SECTION U  BOTSHABELO",
    Latitude: "-29.2544",
    Longitude: "26.6942",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6178",
    WardLookupId: "4111",
    VDNumber: "21870693",
    RegisteredPopulation: "695",
    VotingStationName: "REDUMETSE CRECHE",
    Address: "SECTION W  BOTSHABELO MANGAUNG",
    Latitude: "-29.2492",
    Longitude: "26.6711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6179",
    WardLookupId: "4112",
    VDNumber: "21870356",
    RegisteredPopulation: "1381",
    VotingStationName: "KANANELO CRECHE",
    Address: "3062 SECTION W  BOTSHABELO",
    Latitude: "-29.2548",
    Longitude: "26.6702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6180",
    WardLookupId: "4112",
    VDNumber: "21870390",
    RegisteredPopulation: "2397",
    VotingStationName: "KATAMELO PRIMARY SCHOOL",
    Address: "3284 SECTION U  BOTSHABELO",
    Latitude: "-29.2648",
    Longitude: "26.6916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6181",
    WardLookupId: "4112",
    VDNumber: "21870424",
    RegisteredPopulation: "1284",
    VotingStationName: "THABO EARLY LEARNING CRECHE",
    Address: "709 V SECTION  SECTION V  BOTSHABELO",
    Latitude: "-29.2659",
    Longitude: "26.6759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6182",
    WardLookupId: "4112",
    VDNumber: "21870435",
    RegisteredPopulation: "2048",
    VotingStationName: "NTEBOHENG PRIMARY SCHOOL",
    Address: "917 SECTION S  BOTSHABELO",
    Latitude: "-29.2753",
    Longitude: "26.6894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6183",
    WardLookupId: "4112",
    VDNumber: "21870705",
    RegisteredPopulation: "797",
    VotingStationName: "BONAMELO DAY CARE",
    Address: "SECTION V  BOTSHABELO",
    Latitude: "-29.2595",
    Longitude: "26.6786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6184",
    WardLookupId: "4113",
    VDNumber: "21870255",
    RegisteredPopulation: "1687",
    VotingStationName: "THATO PRIMARY SCHOOL",
    Address: "2192 SECTION D  BOTSHABELO",
    Latitude: "-29.2828",
    Longitude: "26.7328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6185",
    WardLookupId: "4113",
    VDNumber: "21870288",
    RegisteredPopulation: "1900",
    VotingStationName: "DITHOLWANA PRIMARY SCHOOL",
    Address: "440 SECTION D  BOTSHABELO",
    Latitude: "-29.2716",
    Longitude: "26.7386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6186",
    WardLookupId: "4113",
    VDNumber: "21870312",
    RegisteredPopulation: "461",
    VotingStationName: "BOTSHABELO ROMAN CATHOLIC CHURCH",
    Address: "2106 SECTION M  BOTSHABELO",
    Latitude: "-29.2876",
    Longitude: "26.7178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6187",
    WardLookupId: "4113",
    VDNumber: "21870323",
    RegisteredPopulation: "1395",
    VotingStationName: "NTUMEDISENG HIGH SCHOOL",
    Address: "2061 SECTION N  BOTSHABELO  MANGAUNG",
    Latitude: "-29.2906",
    Longitude: "26.7123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6188",
    WardLookupId: "4113",
    VDNumber: "21870648",
    RegisteredPopulation: "625",
    VotingStationName: "WESLYAN CHURCH SECTION M",
    Address: "2913  SECTION M  BOTSHABELO  SECTION M BOTHSHABELO",
    Latitude: "-29.2931",
    Longitude: "26.7238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6189",
    WardLookupId: "4113",
    VDNumber: "21870682",
    RegisteredPopulation: "315",
    VotingStationName: "KGORATHUTO SECONDARY SCHOOL",
    Address: "1426 SECTION D  BOTSHABELO",
    Latitude: "-29.2761",
    Longitude: "26.7319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6190",
    WardLookupId: "4113",
    VDNumber: "21870716",
    RegisteredPopulation: "922",
    VotingStationName: "LESEDI DAY CARE",
    Address: "1358 SECTION M  M SECTION  BOTSHABELO",
    Latitude: "-29.2863",
    Longitude: "26.7295",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6191",
    WardLookupId: "4114",
    VDNumber: "22020084",
    RegisteredPopulation: "773",
    VotingStationName: "TLOTLANANG PRIMARY SCHOOL",
    Address: "8152 MOKWENA  THABA NCHU",
    Latitude: "-29.2175",
    Longitude: "26.8518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6192",
    WardLookupId: "4114",
    VDNumber: "22020095",
    RegisteredPopulation: "920",
    VotingStationName: "ERESKULD PRIMARY SCHOOL",
    Address: "GARAPULANA  GARAPULANA LOCATION  THABA NCHU",
    Latitude: "-29.204",
    Longitude: "26.8434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6193",
    WardLookupId: "4114",
    VDNumber: "22020163",
    RegisteredPopulation: "1525",
    VotingStationName: "RUTEGANG FET",
    Address: "111 THABA-NCHU  ",
    Latitude: "-29.2116",
    Longitude: "26.8347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6194",
    WardLookupId: "4114",
    VDNumber: "22020174",
    RegisteredPopulation: "1871",
    VotingStationName: "BAROLONG HALL",
    Address: "GARAPULANA  THABA NCHU",
    Latitude: "-29.222",
    Longitude: "26.8392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6195",
    WardLookupId: "4114",
    VDNumber: "22020185",
    RegisteredPopulation: "1308",
    VotingStationName: "ITIRELENG TSOELELOPELE CRECHE",
    Address: "1646 RATAU  THABA NCHU",
    Latitude: "-29.2353",
    Longitude: "26.8305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6196",
    WardLookupId: "4114",
    VDNumber: "22020219",
    RegisteredPopulation: "790",
    VotingStationName: "MOTLATLA PRIMARY SCHOOL",
    Address: "MOTLATLA  MOTLATLA TRUST  THABA NCHU",
    Latitude: "-29.1997",
    Longitude: "26.8677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6197",
    WardLookupId: "4114",
    VDNumber: "22020253",
    RegisteredPopulation: "557",
    VotingStationName: "ALPHA CHILD CARE(NEW)",
    Address: "THABA NCHU  ",
    Latitude: "-29.2176",
    Longitude: "26.8398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6198",
    WardLookupId: "4114",
    VDNumber: "22020264",
    RegisteredPopulation: "1149",
    VotingStationName: "MAMAS TOUCH DAY CARE",
    Address: "927 RATAU  THABA-NCHU",
    Latitude: "-29.2332",
    Longitude: "26.8228",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6199",
    WardLookupId: "4114",
    VDNumber: "22020275",
    RegisteredPopulation: "976",
    VotingStationName: "EBENEZER EDUCARE CENTRE",
    Address: "MOROLONG  THABA NCHU",
    Latitude: "-29.2207",
    Longitude: "26.8273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6200",
    WardLookupId: "4114",
    VDNumber: "22020286",
    RegisteredPopulation: "502",
    VotingStationName: "SHAMMAH DAY CARE CENTRE",
    Address: "5294 RAMAKGARI  THABA NCHU",
    Latitude: "-29.2325",
    Longitude: "26.8417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6201",
    WardLookupId: "4115",
    VDNumber: "22020017",
    RegisteredPopulation: "2290",
    VotingStationName: "GORONYANE SECONDARY  SCHOOL",
    Address: "3152 UNIT 1  SELOSESHA  THABA NCHU",
    Latitude: "-29.1955",
    Longitude: "26.8086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6202",
    WardLookupId: "4115",
    VDNumber: "22020028",
    RegisteredPopulation: "930",
    VotingStationName: "TAWANA PRIMARY SCHOOL",
    Address: "RATLOU VILLAGE  RATLOU  THABA NCHU",
    Latitude: "-29.1987",
    Longitude: "26.8251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6203",
    WardLookupId: "4115",
    VDNumber: "22020040",
    RegisteredPopulation: "1773",
    VotingStationName: "SELOSESHA PRIMARY SCHOOL",
    Address: "3050 MOLOSIOA STREET  SELOSESHA UNIT 1  THABA NCHU",
    Latitude: "-29.2013",
    Longitude: "26.8165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6204",
    WardLookupId: "4115",
    VDNumber: "22020196",
    RegisteredPopulation: "1772",
    VotingStationName: "MOIPONE PRIMARY SCHOOL",
    Address: "3044 CHIEF MOROKA  GARAPULANA  THABA NCHU",
    Latitude: "-29.2069",
    Longitude: "26.8229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6205",
    WardLookupId: "4115",
    VDNumber: "22020242",
    RegisteredPopulation: "1096",
    VotingStationName: "THUTO KE LESEDI DAY CARE CENTRE",
    Address: "SELOSESHA   THABA NCHU  5172  SELOSESHA EXTENTION 3",
    Latitude: "-29.1821",
    Longitude: "26.8072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6206",
    WardLookupId: "4115",
    VDNumber: "22020297",
    RegisteredPopulation: "487",
    VotingStationName: "BOITUMELONG SPECIAL SCHOOL",
    Address: "4700 RATLOU  THABA NCHU",
    Latitude: "-29.2117",
    Longitude: "26.8195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6207",
    WardLookupId: "4115",
    VDNumber: "22020310",
    RegisteredPopulation: "289",
    VotingStationName: "KGODISHO PRE SCHOOL",
    Address: "ROOIFONTEIN  ROOIFONTEIN LOCATION  THABA NCHU",
    Latitude: "-29.1963",
    Longitude: "26.826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6208",
    WardLookupId: "4116",
    VDNumber: "22020051",
    RegisteredPopulation: "2213",
    VotingStationName: "SEIPHEMO PRIMARY SCHOOL",
    Address: "1 ZONE 1  GARAPULANA  THABA NCHU",
    Latitude: "-29.1723",
    Longitude: "26.7812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6209",
    WardLookupId: "4116",
    VDNumber: "22020062",
    RegisteredPopulation: "891",
    VotingStationName: "MOKAE PRIMARY SCHOOL",
    Address: "12121 SEROALO VILLAGE  SELOSESHA 10  THABA NCHU",
    Latitude: "-29.2066",
    Longitude: "26.7903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6210",
    WardLookupId: "4116",
    VDNumber: "22020073",
    RegisteredPopulation: "845",
    VotingStationName: "GOOD HOPE DAY CARE CENTRE",
    Address: "ZONE 1  THABA NCHU  ZONE 1",
    Latitude: "-29.1701",
    Longitude: "26.7915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6211",
    WardLookupId: "4116",
    VDNumber: "22120018",
    RegisteredPopulation: "190",
    VotingStationName: "MODISA O TSILE PRIMARY SCHOOL",
    Address: "80 RAKHOI VILLAGE  THABA NCHU",
    Latitude: "-29.047",
    Longitude: "26.9888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6212",
    WardLookupId: "4116",
    VDNumber: "22120029",
    RegisteredPopulation: "317",
    VotingStationName: "SETLOGELO PRIMARY SCHOOL",
    Address: "12 MOROTO VILLAGE  THABA-NCHU",
    Latitude: "-29.0999",
    Longitude: "26.9847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6213",
    WardLookupId: "4116",
    VDNumber: "22120052",
    RegisteredPopulation: "216",
    VotingStationName: "MOTIYANE PRIMARY SCHOOL",
    Address: "SPITSKOP VILLAGE  THABA-NCHU",
    Latitude: "-29.0327",
    Longitude: "26.7761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6214",
    WardLookupId: "4116",
    VDNumber: "22120063",
    RegisteredPopulation: "272",
    VotingStationName: "MODUTUNG INTERMEDIATE SCHOOL",
    Address: "MODUTUNG VILLAGE  MODUTUNG TRUST  THABA NCHU",
    Latitude: "-28.9879",
    Longitude: "26.8056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6215",
    WardLookupId: "4116",
    VDNumber: "22120074",
    RegisteredPopulation: "239",
    VotingStationName: "BOTSIME PRIMARY SCHOOL",
    Address: "P.O.BOX 67  SELOSESHA  THABA NCHU",
    Latitude: "-28.9555",
    Longitude: "26.8206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6216",
    WardLookupId: "4116",
    VDNumber: "22120085",
    RegisteredPopulation: "210",
    VotingStationName: "ROOIBULT PRIMARY SCHOOL",
    Address: "ROOIBULT  ROOIBULT TRUST  THABA NCHU",
    Latitude: "-28.9507",
    Longitude: "26.8985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6217",
    WardLookupId: "4116",
    VDNumber: "22120096",
    RegisteredPopulation: "333",
    VotingStationName: "TALA PRIMARY SCHOOL",
    Address: "TALA VILLAGE  THABA-NCHU",
    Latitude: "-28.9806",
    Longitude: "26.9032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6218",
    WardLookupId: "4116",
    VDNumber: "22120108",
    RegisteredPopulation: "220",
    VotingStationName: "MOKOTO PRIMARY SCHOOL",
    Address: "MOKOTO VILLAGE  THABA NCHU",
    Latitude: "-29.1209",
    Longitude: "26.788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6219",
    WardLookupId: "4116",
    VDNumber: "22120119",
    RegisteredPopulation: "223",
    VotingStationName: "TSIMA TSIMA PRIMARY SCHOOL",
    Address: "53 MIDDELDEEL  THABA NCHU",
    Latitude: "-29.0681",
    Longitude: "26.8411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6220",
    WardLookupId: "4116",
    VDNumber: "22120120",
    RegisteredPopulation: "248",
    VotingStationName: "MOIPOLAI PRIMARY SCHOOL",
    Address: "49 POTSANE VILLAGE  THABA NCHU",
    Latitude: "-29.1326",
    Longitude: "26.7576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6221",
    WardLookupId: "4116",
    VDNumber: "22120186",
    RegisteredPopulation: "141",
    VotingStationName: "RATABANE COMMUNITY HALL",
    Address: "RATABANE  RATABANE VILLAGE  THABA NCHU",
    Latitude: "-29.0858",
    Longitude: "26.8064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6222",
    WardLookupId: "4116",
    VDNumber: "22120197",
    RegisteredPopulation: "253",
    VotingStationName: "FENYANG PRIMARY SCHOOL",
    Address: "PARADYS VILLAGE  THABA NCHU",
    Latitude: "-29.0985",
    Longitude: "26.8455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6223",
    WardLookupId: "4116",
    VDNumber: "22120209",
    RegisteredPopulation: "213",
    VotingStationName: "TOBA PRIMARY SCHOOL",
    Address: "MERINO LOCATION  THABA NCHU",
    Latitude: "-29.0854",
    Longitude: "26.7671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6224",
    WardLookupId: "4116",
    VDNumber: "22120210",
    RegisteredPopulation: "254",
    VotingStationName: "MORAGO PRIMARY SCHOOL",
    Address: "MORAGO LOCATION  THABA NCHU",
    Latitude: "-29.0158",
    Longitude: "26.851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6225",
    WardLookupId: "4116",
    VDNumber: "22120221",
    RegisteredPopulation: "383",
    VotingStationName: "TLHABAKI INTERMEDIATE SCHOOL",
    Address: "197 SEDIBA VILLAGE  SELOSESHA  THABA NCHU",
    Latitude: "-29.0259",
    Longitude: "26.9453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6226",
    WardLookupId: "4116",
    VDNumber: "22120232",
    RegisteredPopulation: "139",
    VotingStationName: "BOFULO PRIMARY SCHOOL",
    Address: "BOFULO VILLAGE  THABA NCHU",
    Latitude: "-28.9443",
    Longitude: "26.8676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6227",
    WardLookupId: "4116",
    VDNumber: "22120311",
    RegisteredPopulation: "120",
    VotingStationName: "ARCHIEBULT METHODIST CHURCH",
    Address: "HOUTNEK TRUST  THABA NCHU  HOUTNEK TRUST",
    Latitude: "-29.0268",
    Longitude: "26.8042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6228",
    WardLookupId: "4116",
    VDNumber: "22120322",
    RegisteredPopulation: "112",
    VotingStationName: "RAMAHUTSE PRIMARY SCHOOL",
    Address: "15044 THUBISI VILLAGE  THABA NCHU",
    Latitude: "-29.1584",
    Longitude: "26.7609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6229",
    WardLookupId: "4116",
    VDNumber: "22120333",
    RegisteredPopulation: "252",
    VotingStationName: "NGAKANTSI`S POORT PRIMARY SCHOOL",
    Address: "FELOANE  GARAPULANA  THABA NCHU",
    Latitude: "-29.118",
    Longitude: "26.7373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6230",
    WardLookupId: "4117",
    VDNumber: "22020039",
    RegisteredPopulation: "609",
    VotingStationName: "KHUNE PRIMARY SCHOOL",
    Address: "158 ROOIFONTEIN  GARAPULANA  THABA NCHU",
    Latitude: "-29.1719",
    Longitude: "26.864",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6231",
    WardLookupId: "4117",
    VDNumber: "22020118",
    RegisteredPopulation: "1154",
    VotingStationName: "ROMAN CATHOLIC CHURCH (THABA NCHU)",
    Address: "STAND 5  ZONE II  THABA NCHU",
    Latitude: "-29.1675",
    Longitude: "26.8012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6232",
    WardLookupId: "4117",
    VDNumber: "22020130",
    RegisteredPopulation: "2100",
    VotingStationName: "THABA NCHU COMMUNITY HALL",
    Address: "BULTFONTEIN 2  THABA NCHU  THABA NCHU",
    Latitude: "-29.1602",
    Longitude: "26.8098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6233",
    WardLookupId: "4117",
    VDNumber: "22020231",
    RegisteredPopulation: "1710",
    VotingStationName: "BOIPELO DAY CARE",
    Address: "THABA NCHU  ",
    Latitude: "-29.1635",
    Longitude: "26.8169",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6234",
    WardLookupId: "4117",
    VDNumber: "22020309",
    RegisteredPopulation: "584",
    VotingStationName: "ST LAWRENCE CHURCH",
    Address: "ZONE 2  THABA NCHU",
    Latitude: "-29.1688",
    Longitude: "26.8056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6235",
    WardLookupId: "4118",
    VDNumber: "21880010",
    RegisteredPopulation: "794",
    VotingStationName: "METSIMAPHODI SENIOR SECONDARY SCHOOL",
    Address: "1274 RAMANAMANE ST  MOROJANENG  DEWETSDORP",
    Latitude: "-29.5854",
    Longitude: "26.6779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6236",
    WardLookupId: "4118",
    VDNumber: "21880021",
    RegisteredPopulation: "1527",
    VotingStationName: "KATISO PRIMARY SCHOOL",
    Address: "1539 REVONIA  DEWETSDORP  MOROJANENG",
    Latitude: "-29.5777",
    Longitude: "26.6831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6237",
    WardLookupId: "4118",
    VDNumber: "21880032",
    RegisteredPopulation: "770",
    VotingStationName: "DEWETSDORP TOWN HALL",
    Address: "13 VON MALTITZ STR  DEWETSDORP  DEWETSDORP",
    Latitude: "-29.5838",
    Longitude: "26.6641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6238",
    WardLookupId: "4118",
    VDNumber: "21880054",
    RegisteredPopulation: "815",
    VotingStationName: "NKGODISE PUBLIC SCHOOL",
    Address:
      "554 OLD LOCATION MOROJANENG   (FS171 - NALEDI [DEWETSDORP])   (FS171 - NALEDI [DEWETSDORP])",
    Latitude: "-29.5838",
    Longitude: "26.6756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6239",
    WardLookupId: "4118",
    VDNumber: "22110040",
    RegisteredPopulation: "78",
    VotingStationName: "HONEYTON BUILDING",
    Address: "27 HONEYTON FARM  DEWEETSDORP",
    Latitude: "-29.5454",
    Longitude: "26.7995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6240",
    WardLookupId: "4118",
    VDNumber: "22110107",
    RegisteredPopulation: "45",
    VotingStationName: "DAMFONTEIN",
    Address: "290 DAMFONTEIN   KLIPPOORTJIE AREA   DEWETSDORP",
    Latitude: "-29.4102",
    Longitude: "26.5926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6241",
    WardLookupId: "4118",
    VDNumber: "22110118",
    RegisteredPopulation: "95",
    VotingStationName: "DEELPAN",
    Address: "S736 DRIE HOEK  HA MOLEBEDI  DEWETSDORP",
    Latitude: "-29.5682",
    Longitude: "26.8776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6242",
    WardLookupId: "4118",
    VDNumber: "22110185",
    RegisteredPopulation: "189",
    VotingStationName: "ROODEPOORT BUILDING",
    Address: "S737 ROODEPOORT  JIMMY ROOS   DEWETSDORP",
    Latitude: "-29.5616",
    Longitude: "26.5696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6243",
    WardLookupId: "4118",
    VDNumber: "22110286",
    RegisteredPopulation: "121",
    VotingStationName: "NIEKERKSVLEI PRIMARY SCHOOL",
    Address: "116 NIEKERKSVLEI  NIEKERSVLEI  DEWETSDORP",
    Latitude: "-29.4905",
    Longitude: "26.4216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6244",
    WardLookupId: "4118",
    VDNumber: "22110376",
    RegisteredPopulation: "120",
    VotingStationName: "DASPOORT BUILDING",
    Address: "71 DASPOORT FARM  DEWETSDORP  DEWETSDORP",
    Latitude: "-29.6349",
    Longitude: "26.8106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6245",
    WardLookupId: "4118",
    VDNumber: "22120030",
    RegisteredPopulation: "119",
    VotingStationName: "KEIKELAME PRIMARY SCHOOL",
    Address: "00 GROOTDAM  THABA NCHU  THABA NCHU",
    Latitude: "-29.3918",
    Longitude: "26.9216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6246",
    WardLookupId: "4118",
    VDNumber: "22120041",
    RegisteredPopulation: "220",
    VotingStationName: "NKHABELE PRIMARY SCHOOL",
    Address: "THABA NCHU  RIETFONTEIN   THABA NCHU",
    Latitude: "-29.4213",
    Longitude: "26.9389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6247",
    WardLookupId: "4118",
    VDNumber: "22120131",
    RegisteredPopulation: "154",
    VotingStationName: "KLIPFONTEIN PRIMARY SCHOOL",
    Address: "1 KLIPFONTEIN  THABA NCHU",
    Latitude: "-29.5203",
    Longitude: "26.9798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6248",
    WardLookupId: "4118",
    VDNumber: "22120153",
    RegisteredPopulation: "214",
    VotingStationName: "MATSITSILELE PRIMARY SCHOOL",
    Address: "KOMMISIEDRIFT  THABA NCHU",
    Latitude: "-29.3141",
    Longitude: "26.7633",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6249",
    WardLookupId: "4118",
    VDNumber: "22120164",
    RegisteredPopulation: "226",
    VotingStationName: "RAMOSHOANE PRIMARY SCHOOL",
    Address: "00 YORKSFORD  THABA NCHU",
    Latitude: "-29.417",
    Longitude: "26.8477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6250",
    WardLookupId: "4118",
    VDNumber: "22120243",
    RegisteredPopulation: "156",
    VotingStationName: "MOTSUMI PRIMARY SCHOOL",
    Address: "BALACLAVA  THABA NCHU",
    Latitude: "-29.3945",
    Longitude: "26.9832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6251",
    WardLookupId: "4118",
    VDNumber: "22120254",
    RegisteredPopulation: "124",
    VotingStationName: "MEFANE PRIMARY SCHOOL",
    Address: "00 VICTORIA NECK   THABA NCHU",
    Latitude: "-29.2531",
    Longitude: "26.7884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6252",
    WardLookupId: "4118",
    VDNumber: "22120265",
    RegisteredPopulation: "349",
    VotingStationName: "MASERONA PRIMARY SCHOOL",
    Address: "GLADSTONE  THABA-NCHU",
    Latitude: "-29.3605",
    Longitude: "26.839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6253",
    WardLookupId: "4118",
    VDNumber: "22120276",
    RegisteredPopulation: "143",
    VotingStationName: "KEOAGILE DAY CARE",
    Address: "NOGASPOST  THABA NCHU",
    Latitude: "-29.3518",
    Longitude: "26.7885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6254",
    WardLookupId: "4118",
    VDNumber: "22120287",
    RegisteredPopulation: "86",
    VotingStationName: "SEROPE PRIMARY SCHOOL",
    Address: "01 WOODBRIDGE   WOODBRIDGE 01  THABA NCHU",
    Latitude: "-29.4284",
    Longitude: "26.813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6255",
    WardLookupId: "4118",
    VDNumber: "22120298",
    RegisteredPopulation: "165",
    VotingStationName: "PRESBYTERIAN CHURCH",
    Address: "02 WOODBRIDGE  WOODBRIDGE 2  THABA NCHU",
    Latitude: "-29.4031",
    Longitude: "26.8203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6256",
    WardLookupId: "4118",
    VDNumber: "22120300",
    RegisteredPopulation: "168",
    VotingStationName: "POONYANE PRIMARY SCHOOL",
    Address: "TWEEFONTEIN  THABA NCHU",
    Latitude: "-29.3725",
    Longitude: "26.9334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6257",
    WardLookupId: "4119",
    VDNumber: "21860311",
    RegisteredPopulation: "1322",
    VotingStationName: "KRUITBERG PRIMARY SCHOOL",
    Address: "132A ANDRIES PRETORIUS STREET  NOORDHOEK  BLOEMFONTEIN",
    Latitude: "-29.0932",
    Longitude: "26.2477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6258",
    WardLookupId: "4119",
    VDNumber: "21860344",
    RegisteredPopulation: "3092",
    VotingStationName: "BLOEMFONTEIN HIGH NORTH SCHOOL",
    Address: "59 GLADSTONE ROAD  BAYSWATER  BLOEMFONTEIN",
    Latitude: "-29.0846",
    Longitude: "26.2452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6259",
    WardLookupId: "4119",
    VDNumber: "21860928",
    RegisteredPopulation: "2651",
    VotingStationName: "BREBNER HIGH SCHOOL",
    Address: "LIMOUSIN STREET  HELICON HEIGHTS  BLOEMFONTEIN",
    Latitude: "-29.0767",
    Longitude: "26.2327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6260",
    WardLookupId: "4119",
    VDNumber: "22080013",
    RegisteredPopulation: "245",
    VotingStationName: "VERGEZOCHT SCHOOL",
    Address: "P.O.BOX 17082  BAINSVLEI  BLOEMFONTEIN",
    Latitude: "-28.9652",
    Longitude: "25.9682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6261",
    WardLookupId: "4119",
    VDNumber: "22080046",
    RegisteredPopulation: "236",
    VotingStationName: "FLORADALE FARM SCHOOL",
    Address: "BULTFONTEIN RD  FLORADALE FARM  BLOEMFONTEIN",
    Latitude: "-28.9613",
    Longitude: "26.2306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6262",
    WardLookupId: "4119",
    VDNumber: "22080057",
    RegisteredPopulation: "613",
    VotingStationName: "KOLISANG KHAKHAU SCHOOL",
    Address: "9 TOGEKREGGEN ROAD  GLEN  BLOEMFONTEIN",
    Latitude: "-28.941",
    Longitude: "26.3364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6263",
    WardLookupId: "4119",
    VDNumber: "22550015",
    RegisteredPopulation: "159",
    VotingStationName: "SOUTPAN SIÃ‹STA HALL",
    Address: "P O  BOX  22  SOUTPAN  SOUTPAN",
    Latitude: "-28.7134",
    Longitude: "26.0696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6264",
    WardLookupId: "4119",
    VDNumber: "22550026",
    RegisteredPopulation: "1792",
    VotingStationName: "KAGISANO COMBINED SCHOOL",
    Address: "NTHUNYA STREET   IKGOMOTSENG   SOUTPAN",
    Latitude: "-28.7346",
    Longitude: "26.1101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6265",
    WardLookupId: "4119",
    VDNumber: "22630182",
    RegisteredPopulation: "57",
    VotingStationName: "SA SOUTKOOPERASIE",
    Address: "1 LONG STREET  SOUTPAN  SOUTPAN",
    Latitude: "-28.757",
    Longitude: "26.0394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6266",
    WardLookupId: "4120",
    VDNumber: "21860995",
    RegisteredPopulation: "3733",
    VotingStationName: "REKGONNE PRIMARY SCHOOL",
    Address: "128 VOORTREKKERLAAN  WIEGANDIA  BLOEMFONTEIN",
    Latitude: "-29.1667",
    Longitude: "26.2805",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6267",
    WardLookupId: "4120",
    VDNumber: "21861604",
    RegisteredPopulation: "1149",
    VotingStationName: "TENT AT GRASSLAND SECONDARY SCHOOL",
    Address: "BERGMAN SQUARE  BLOEMFONTEIN  MANGAUANG",
    Latitude: "-29.1586",
    Longitude: "26.281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6268",
    WardLookupId: "4120",
    VDNumber: "21861761",
    RegisteredPopulation: "1476",
    VotingStationName: "RELEBELETSE PRIMARY SCHOOL",
    Address: "7482 PHASE 4, BLOEMSIDE  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.1744",
    Longitude: "26.2728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6269",
    WardLookupId: "4120",
    VDNumber: "21861772",
    RegisteredPopulation: "1021",
    VotingStationName: "YOUNG DAY CARE CENTRE",
    Address: "BLOEMFONTEIN  PHASE 4  BLOEMFONTEIN",
    Latitude: "-29.1683",
    Longitude: "26.2644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6270",
    WardLookupId: "4120",
    VDNumber: "21861783",
    RegisteredPopulation: "650",
    VotingStationName: "ITUMELENG DAY CARE",
    Address: "PHASE 9  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.1642",
    Longitude: "26.2727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6271",
    WardLookupId: "4121",
    VDNumber: "21861176",
    RegisteredPopulation: "1837",
    VotingStationName: "TENT (SEROPE SA BENYA)",
    Address: "BLOEMFONTEIN  ",
    Latitude: "-29.1779",
    Longitude: "26.2784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6272",
    WardLookupId: "4121",
    VDNumber: "21861187",
    RegisteredPopulation: "2188",
    VotingStationName: "TJHEBELO PELE PRIMARY SCHOOL",
    Address: "PHASE 5  MANGAUNG [BLOEMFONTEIN]",
    Latitude: "-29.1915",
    Longitude: "26.2809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6273",
    WardLookupId: "4121",
    VDNumber: "21861266",
    RegisteredPopulation: "1343",
    VotingStationName: "EAGLE CHRISTIAN CENTRE",
    Address: "PHASE 10  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.2082",
    Longitude: "26.2829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6274",
    WardLookupId: "4121",
    VDNumber: "21861806",
    RegisteredPopulation: "1269",
    VotingStationName: "BOLOKEHANG DAY CARE",
    Address: "PHASE 5  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.1817",
    Longitude: "26.2752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6275",
    WardLookupId: "4121",
    VDNumber: "21861817",
    RegisteredPopulation: "790",
    VotingStationName: "TSHEPA THAPELO DAY CARE",
    Address: "PHASE 9  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.1742",
    Longitude: "26.2894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6276",
    WardLookupId: "4121",
    VDNumber: "21861828",
    RegisteredPopulation: "1318",
    VotingStationName: "MATLA PRIMARY SCHOOL",
    Address: "PHASE 6  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.186",
    Longitude: "26.2902",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6277",
    WardLookupId: "4121",
    VDNumber: "21861839",
    RegisteredPopulation: "803",
    VotingStationName: "INTOKOZO DAY CARE",
    Address: "PHASE 6  BLOEMFONTEI  MANGAUNG",
    Latitude: "-29.1816",
    Longitude: "26.2808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6278",
    WardLookupId: "4121",
    VDNumber: "21861840",
    RegisteredPopulation: "1872",
    VotingStationName: "BOKAMOSO DAY CARE",
    Address: "PHASE 6  BLOEMFONTEIN  MANGAUNG",
    Latitude: "-29.1773",
    Longitude: "26.2932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6279",
    WardLookupId: "4122",
    VDNumber: "21860276",
    RegisteredPopulation: "1557",
    VotingStationName: "NG KERK BLOEMVALLEI",
    Address: "PLOT 90 DREYERLAAN  ROODEWAL  BLOEMFONTEIN",
    Latitude: "-29.0995",
    Longitude: "26.3376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6280",
    WardLookupId: "4122",
    VDNumber: "21860388",
    RegisteredPopulation: "1257",
    VotingStationName: "TENT 2 AT PINE HEAVEN",
    Address: "PINEHAVEN BLOEMFONTEIN  MANGAUNG   PINE HAVEN BLOEMSITE",
    Latitude: "-29.1422",
    Longitude: "26.2792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6281",
    WardLookupId: "4122",
    VDNumber: "21860456",
    RegisteredPopulation: "1748",
    VotingStationName: "HEATHERDALE SECONDARY SCHOOL",
    Address: "HEATHERDALE RD  HEIDEDAL  BLOEMFONTEIN",
    Latitude: "-29.146",
    Longitude: "26.2601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6282",
    WardLookupId: "4122",
    VDNumber: "21860467",
    RegisteredPopulation: "2096",
    VotingStationName: "MAHLWEMPU CHILD CARE CENTRE",
    Address: "4067 GEELVIS LAAN  BLOEMSIDE  BLOEMFONTEIN",
    Latitude: "-29.1568",
    Longitude: "26.2579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6283",
    WardLookupId: "4122",
    VDNumber: "21860984",
    RegisteredPopulation: "1384",
    VotingStationName: "HEIDE PRIMARY SCHOOL",
    Address: "92A PAPER STREET  HEIDEDAL  BLOEMFONTEIN",
    Latitude: "-29.1324",
    Longitude: "26.2504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6284",
    WardLookupId: "4122",
    VDNumber: "21861075",
    RegisteredPopulation: "2084",
    VotingStationName: "EDMARITA PRE-PRIMARY SCHOOL",
    Address: "GRASSLAND  BLOEMFONTEIN",
    Latitude: "-29.1471",
    Longitude: "26.268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6285",
    WardLookupId: "4122",
    VDNumber: "21861345",
    RegisteredPopulation: "552",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "170 GEELVIS  BLOEMSIDE   BLOEMFONTEIN",
    Latitude: "-29.1514",
    Longitude: "26.2645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6286",
    WardLookupId: "4122",
    VDNumber: "21861895",
    RegisteredPopulation: "474",
    VotingStationName: "TENT AT THE RACEWAY  COURSE",
    Address: "RACEWAY COURSE  MANGAUNG  BLOEMFONTEIN",
    Latitude: "-29.1143",
    Longitude: "26.2848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6287",
    WardLookupId: "4122",
    VDNumber: "21861952",
    RegisteredPopulation: "243",
    VotingStationName: "KOOTNIEMAN PRIMARY SCHOOL",
    Address: "KOOT NIEMAN  BLOEMFONTEIN   KOOT NIEMAN",
    Latitude: "-29.1393",
    Longitude: "26.2981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6288",
    WardLookupId: "4122",
    VDNumber: "21861996",
    RegisteredPopulation: "196",
    VotingStationName: "DR CF VISSER PRIMARY SCHOOL",
    Address: "TIBBIE VISSER STREET  ESTOIRE  BLOEMFONTEIN",
    Latitude: "-29.0908",
    Longitude: "26.274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6289",
    WardLookupId: "4123",
    VDNumber: "21860333",
    RegisteredPopulation: "1382",
    VotingStationName: "TENT AT FLOCKEMANN STREET",
    Address: "5-11 FLOCKEMANN STREET  HEUWELSIG  BLOEMFONTEIN",
    Latitude: "-29.077",
    Longitude: "26.2093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6290",
    WardLookupId: "4123",
    VDNumber: "21860502",
    RegisteredPopulation: "1334",
    VotingStationName: "TEMPE CHURCH HALL",
    Address: "FURSTENBURG ST  TEMPE MILITARY BASE  BLOEMFONTEIN",
    Latitude: "-29.0966",
    Longitude: "26.194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6291",
    WardLookupId: "4123",
    VDNumber: "21860951",
    RegisteredPopulation: "1701",
    VotingStationName: "HIGH SCHOOL PRESIDENT STEYN",
    Address: "PIET ODENDAAL ROAD  BAINSVLEI  BLOEMFONTEIN",
    Latitude: "-29.0131",
    Longitude: "26.063",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6292",
    WardLookupId: "4123",
    VDNumber: "21861031",
    RegisteredPopulation: "2054",
    VotingStationName: "BAINSVLEI MUNICIPAL CLINIC",
    Address: "ABRAHAMSKRAAL ROAD  BAINSVLEI  BLOEMFONTEIN",
    Latitude: "-29.0646",
    Longitude: "26.1294",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6293",
    WardLookupId: "4123",
    VDNumber: "21861851",
    RegisteredPopulation: "369",
    VotingStationName: "TENT (AT NICOLAI PARK)",
    Address: "NICOLAI STREET   HEUWELSIG   BLOEMFONTEIN",
    Latitude: "-29.0712",
    Longitude: "26.2128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6294",
    WardLookupId: "4123",
    VDNumber: "21861873",
    RegisteredPopulation: "745",
    VotingStationName: "BAINSVLEI COMBINED SCHOOL",
    Address: "16 LANGEBERG AVENUE  BAINSVLEI   BLOEMFONTEIN",
    Latitude: "-29.0714",
    Longitude: "26.1522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6295",
    WardLookupId: "4123",
    VDNumber: "22080147",
    RegisteredPopulation: "375",
    VotingStationName: "LEEUWVLEI CHURCH HALL",
    Address: "PLOT 90 DREYER AVENUE  ROODEWAAL  BLOEMFONTEIN",
    Latitude: "-29.0098",
    Longitude: "25.8991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6296",
    WardLookupId: "4123",
    VDNumber: "22080259",
    RegisteredPopulation: "807",
    VotingStationName: "TENT NEXT TO LILLY VALE",
    Address: "LILLY VALE  BLOEMFONTEIN",
    Latitude: "-29.0635",
    Longitude: "26.2034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6297",
    WardLookupId: "4123",
    VDNumber: "22080260",
    RegisteredPopulation: "1798",
    VotingStationName: "TENT NEXT TO WOODLANDS HILLS",
    Address: "BLOEMFONTEI  BLOEMFONTEIN  WOODLANDS HILLS",
    Latitude: "-29.0494",
    Longitude: "26.1808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6298",
    WardLookupId: "4123",
    VDNumber: "22080271",
    RegisteredPopulation: "321",
    VotingStationName: "CRC NORTH",
    Address: "20 JAN MARX STREET  PENTAGON PARK  BLOEMFONTEIN",
    Latitude: "-29.0705",
    Longitude: "26.2264",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6299",
    WardLookupId: "4124",
    VDNumber: "22020107",
    RegisteredPopulation: "1436",
    VotingStationName: "THUBISI PRIMARY SCHOOL",
    Address: "GA-RAPULANA  GA-RAPULANA LOCATION  THABA NCHU",
    Latitude: "-29.1765",
    Longitude: "26.8082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6300",
    WardLookupId: "4124",
    VDNumber: "22020129",
    RegisteredPopulation: "1760",
    VotingStationName: "RT MOKGOPA HIGH SCHOOL",
    Address: "3 BULTFONTEIN  THABA NCHU",
    Latitude: "-29.1735",
    Longitude: "26.8212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6301",
    WardLookupId: "4124",
    VDNumber: "22020141",
    RegisteredPopulation: "2633",
    VotingStationName: "REFENTSE PRIMARY SCHOOL",
    Address: "8883 SELOSESHA  THBA NCHU",
    Latitude: "-29.1784",
    Longitude: "26.8308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6302",
    WardLookupId: "4124",
    VDNumber: "22020208",
    RegisteredPopulation: "1655",
    VotingStationName: "METHODIST CHURCH OF SOUTHERN AFRICA",
    Address: "97 MOKOENA  MOKOENA LOCATION  THABA NCHU",
    Latitude: "-29.191",
    Longitude: "26.8359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6303",
    WardLookupId: "4124",
    VDNumber: "22020220",
    RegisteredPopulation: "2213",
    VotingStationName: "PHETOGANE PRIMARY SCHOOL",
    Address: "MOKOENA  MOKOENA LOCATION  THABA NCHU",
    Latitude: "-29.1919",
    Longitude: "26.8485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6304",
    WardLookupId: "4125",
    VDNumber: "21880043",
    RegisteredPopulation: "1178",
    VotingStationName: "NTATAISE DAY CARE",
    Address: "121 MOROJANENG  DEWETSDORP",
    Latitude: "-29.5927",
    Longitude: "26.6794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6305",
    WardLookupId: "4125",
    VDNumber: "21880065",
    RegisteredPopulation: "496",
    VotingStationName: "JERUSALEMA CHURCH",
    Address: "NEXT TO HA KHOASE  DEWETSDORP  MOROJANENG",
    Latitude: "-29.5879",
    Longitude: "26.6809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6306",
    WardLookupId: "4125",
    VDNumber: "22040019",
    RegisteredPopulation: "1131",
    VotingStationName: "THAPELONG COMMUNITY HALL",
    Address: "506 THAPELONG  HALL   VANTSAD   VANSTADENSRUS",
    Latitude: "-29.985",
    Longitude: "27.0054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6307",
    WardLookupId: "4125",
    VDNumber: "22060011",
    RegisteredPopulation: "895",
    VotingStationName: "WEPENER TOWN HALL",
    Address: "178 DE BEER STREET  WEPENER   WEPENER",
    Latitude: "-29.7297",
    Longitude: "27.039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6308",
    WardLookupId: "4125",
    VDNumber: "22060022",
    RegisteredPopulation: "587",
    VotingStationName: "EBENHAESERHOOGTE PRIMARY SCHOOL",
    Address: "4 PETUNIA STREET  HOOGTE  WEPENER",
    Latitude: "-29.7219",
    Longitude: "27.0126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6309",
    WardLookupId: "4125",
    VDNumber: "22060033",
    RegisteredPopulation: "1430",
    VotingStationName: "QIBING COMMUNITY HALL",
    Address: "KOKOROPO STR  QIBING  WEPENER",
    Latitude: "-29.7199",
    Longitude: "27.0271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6310",
    WardLookupId: "4125",
    VDNumber: "22060044",
    RegisteredPopulation: "937",
    VotingStationName: "IKAHENG CRECHE",
    Address: "3147 KANANA C  WEPENER",
    Latitude: "-29.7162",
    Longitude: "27.0113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6311",
    WardLookupId: "4125",
    VDNumber: "22060055",
    RegisteredPopulation: "893",
    VotingStationName: "ROSE DAY CARE CENTRE",
    Address: "2510 KANANA   QIBING  WEPENER",
    Latitude: "-29.7203",
    Longitude: "27.0198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6312",
    WardLookupId: "4125",
    VDNumber: "22060066",
    RegisteredPopulation: "620",
    VotingStationName: "MPHATLALATSANE CRECHE",
    Address: "1724 LUSAKA  QIBING WEPENER  WEPENER",
    Latitude: "-29.7229",
    Longitude: "27.0315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6313",
    WardLookupId: "4125",
    VDNumber: "22110073",
    RegisteredPopulation: "52",
    VotingStationName: "KNELPOORT BUILDING",
    Address: "S32 KNELPOORT  KNELPOORT DAM  WEPENER",
    Latitude: "-29.7811",
    Longitude: "26.9027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6314",
    WardLookupId: "4125",
    VDNumber: "22110174",
    RegisteredPopulation: "55",
    VotingStationName: "BIESIESDAL BUILDING",
    Address: "P.O BOX 112  WEPENER  WEPENER",
    Latitude: "-29.6336",
    Longitude: "26.9963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6315",
    WardLookupId: "4125",
    VDNumber: "22110231",
    RegisteredPopulation: "101",
    VotingStationName: "BALMACAR PRIMARY SCHOOL",
    Address: "000 DEWETSDORP  ",
    Latitude: "-29.9277",
    Longitude: "27.1496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6316",
    WardLookupId: "4125",
    VDNumber: "22110253",
    RegisteredPopulation: "62",
    VotingStationName: "NOOITGEDACHT BUILDING",
    Address: "S411 NOOITGEDACHT FARM BUILDING   SAVILE AREA    WEPENER",
    Latitude: "-29.851",
    Longitude: "27.0919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6317",
    WardLookupId: "4125",
    VDNumber: "22110264",
    RegisteredPopulation: "120",
    VotingStationName: "GELUKWAARTS PRIMARY SCHOOL",
    Address: "GELUKWAARTS  VANSTAD  VANSTADENSRUS",
    Latitude: "-30.0188",
    Longitude: "26.878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6318",
    WardLookupId: "4125",
    VDNumber: "22110275",
    RegisteredPopulation: "142",
    VotingStationName: "KOREA FARM SCHOOL",
    Address: "493 BON HILL  BON HILL FARM  VANSTADENSRUS",
    Latitude: "-29.9261",
    Longitude: "27.0057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6319",
    WardLookupId: "4125",
    VDNumber: "22110309",
    RegisteredPopulation: "113",
    VotingStationName: "WELBEDACHT DAM",
    Address: "S32 WELBEDACHT DAM  WEPENER",
    Latitude: "-29.9211",
    Longitude: "26.8474",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6320",
    WardLookupId: "4125",
    VDNumber: "22110477",
    RegisteredPopulation: "38",
    VotingStationName: "BRAKVLY STORE",
    Address: "458 BRAKVLY  DEWETSDORP",
    Latitude: "-29.6553",
    Longitude: "26.7457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6321",
    WardLookupId: "799",
    VDNumber: "32910230",
    RegisteredPopulation: "2763",
    VotingStationName: "OLIFANTSFONTEIN COMMUNITY HALL",
    Address: "PEARS STREET  OLIFANTSFONTEIN  OLIFANTSFONTEIN",
    Latitude: "-25.9558",
    Longitude: "28.2288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6322",
    WardLookupId: "799",
    VDNumber: "32910331",
    RegisteredPopulation: "4105",
    VotingStationName: "MIDSTREAM COLLEGE",
    Address: "01 ASHFORD  MISTREAM ESTATES  MIDSTREAM",
    Latitude: "-25.9159",
    Longitude: "28.1973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6323",
    WardLookupId: "799",
    VDNumber: "32910375",
    RegisteredPopulation: "2395",
    VotingStationName: "WORLD WORSHIP CENTRE",
    Address:
      "00 CNR PORCELAIN AND ALLUMINIUM   CLAYVILLE EXT 29  OLIFANTSFONTEIN",
    Latitude: "-25.9508",
    Longitude: "28.2048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6324",
    WardLookupId: "799",
    VDNumber: "32910410",
    RegisteredPopulation: "1412",
    VotingStationName: "RAINBOW DAYCARE CENTRE",
    Address: "2773 COBALT STREET CLAYVILLE  CLAYVILLE  TEMBISA",
    Latitude: "-25.9527",
    Longitude: "28.2112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6325",
    WardLookupId: "799",
    VDNumber: "32910421",
    RegisteredPopulation: "1137",
    VotingStationName: "THE APOSTOLIC FAITH MISSION LOGOS ASSEMBLY",
    Address: "5005 EXTENSION 8   TSWELOPELE  TEMBISA",
    Latitude: "-25.9703",
    Longitude: "28.1989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6326",
    WardLookupId: "799",
    VDNumber: "32910432",
    RegisteredPopulation: "1340",
    VotingStationName: "BETHLEM REVIVAL CHURCH",
    Address: "1617 EXTENSION 8   TSWELOPELE  TEMBISA",
    Latitude: "-25.9679",
    Longitude: "28.1996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6327",
    WardLookupId: "799",
    VDNumber: "32910465",
    RegisteredPopulation: "4627",
    VotingStationName: "RETIREMENT AT MISTREAM",
    Address: "00 BRAKFONTEIN  MIDSTREAM ESTATE  MIDSTREAM",
    Latitude: "-25.9223",
    Longitude: "28.1908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6328",
    WardLookupId: "799",
    VDNumber: "32910522",
    RegisteredPopulation: "1642",
    VotingStationName: "CURRO ACADEMY CLAYVILLE",
    Address:
      "01 VALUMAX RESIDENTIAL ESTATE,CLA  CLAYVILLE EXT 45  OLIFANTSFONTEIN",
    Latitude: "-25.9828",
    Longitude: "28.19",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6329",
    WardLookupId: "799",
    VDNumber: "32910533",
    RegisteredPopulation: "1867",
    VotingStationName: "MIDSTREAM RIDGE PRIMARY SCHOOL",
    Address: "1 RIDGEWAY AVENUE  MIDSTREAM RIDGE  MIDSTREAM ESTATE",
    Latitude: "-25.9195",
    Longitude: "28.2096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6330",
    WardLookupId: "800",
    VDNumber: "32890080",
    RegisteredPopulation: "1833",
    VotingStationName: "TENT (WINNIE MANDELA ZONE 11 GROUNDS)",
    Address: "4237 RAMAPHOSA STREET  WINNIE MANDELA SECTION  TEMBISA. 1632",
    Latitude: "-25.9847",
    Longitude: "28.2139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6331",
    WardLookupId: "800",
    VDNumber: "32890103",
    RegisteredPopulation: "3369",
    VotingStationName: "ENTSHONALANGA PRIMARY SCHOOL",
    Address: "816 SKYLAB STREET  MAOKENG EXT. 1  TEMBISA. 1632",
    Latitude: "-25.9922",
    Longitude: "28.2197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6332",
    WardLookupId: "800",
    VDNumber: "32890114",
    RegisteredPopulation: "2398",
    VotingStationName: "TLAMATLAMA PRIMARY SCHOOL",
    Address: "124 SAGITTARIUS STREET  TLAMATLAMA SECTION  TEMBISA. 1632",
    Latitude: "-25.998",
    Longitude: "28.2261",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6333",
    WardLookupId: "800",
    VDNumber: "32890125",
    RegisteredPopulation: "2813",
    VotingStationName: "MUNICIPAL BUILDING MOSES MOLELEKWA ARTS CENTRE",
    Address: "ANDREW MAPHETO & STAR STR  TSEPO  TEMBISA 1632",
    Latitude: "-26.0005",
    Longitude: "28.229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6334",
    WardLookupId: "800",
    VDNumber: "32890136",
    RegisteredPopulation: "2360",
    VotingStationName: "BOKAMOSO SECONDARY SCHOOL",
    Address: "583 STAR STREET  MAKHULONG SECTION  TEMBISA. 1632",
    Latitude: "-25.9948",
    Longitude: "28.2309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6335",
    WardLookupId: "800",
    VDNumber: "32890765",
    RegisteredPopulation: "1480",
    VotingStationName: "NEW LIFE PRAISE CHURCH",
    Address: "926 ZONE 4   WINNIE MANDELA  TEMBISA 1632",
    Latitude: "-25.9874",
    Longitude: "28.2212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6336",
    WardLookupId: "801",
    VDNumber: "32890068",
    RegisteredPopulation: "5279",
    VotingStationName: "WINNIE MANDELA SECONDARY SCHOOL.",
    Address: "01 MADIBA DRIVE  ZONE 6  WINNIE MANDELA  TEMBISA",
    Latitude: "-25.9803",
    Longitude: "28.2224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6337",
    WardLookupId: "801",
    VDNumber: "32890079",
    RegisteredPopulation: "3786",
    VotingStationName: "WINNIE MANDELA PRIMARY SCHOOL",
    Address: "CNR SOLOM MAHLANGU & MADIBA DR  WINNIE MANDELA   TEMBISA",
    Latitude: "-25.9813",
    Longitude: "28.2255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6338",
    WardLookupId: "801",
    VDNumber: "32890091",
    RegisteredPopulation: "4668",
    VotingStationName: "REAGILE PRIMARY SCHOOL",
    Address: "2820 ZONE 10  WINNIE MANDELA SECTION  TEMBISA. 1632",
    Latitude: "-25.9755",
    Longitude: "28.2265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6339",
    WardLookupId: "801",
    VDNumber: "32890800",
    RegisteredPopulation: "1498",
    VotingStationName: "KGANYA CRECHE & PRE-SCHOOL",
    Address: "9514 WINNIE MANDELA  WINNIE MANDELA ZONE 4  TEMBISA",
    Latitude: "-25.9848",
    Longitude: "28.221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6340",
    WardLookupId: "802",
    VDNumber: "32890192",
    RegisteredPopulation: "2259",
    VotingStationName: "MARHULANA PRIMARY SCHOOL",
    Address: "365 NDLOPFU STREET  XUBENI SECTION  TEMBISA. 1632",
    Latitude: "-26.0062",
    Longitude: "28.2442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6341",
    WardLookupId: "802",
    VDNumber: "32890204",
    RegisteredPopulation: "2486",
    VotingStationName: "SHUKUMANI PRIMARY SCHOOL",
    Address: "488 NKANYAMBA STREET  ECALENI SECTION  TEMBISA",
    Latitude: "-26.0116",
    Longitude: "28.245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6342",
    WardLookupId: "802",
    VDNumber: "32890215",
    RegisteredPopulation: "1753",
    VotingStationName: "ECALENI PRIMARY SCHOOL",
    Address: "706 MATHOLE STREET  ECALENI SECTION  TEMBISA",
    Latitude: "-26.0144",
    Longitude: "28.2455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6343",
    WardLookupId: "802",
    VDNumber: "32890237",
    RegisteredPopulation: "2816",
    VotingStationName: "IKUSASA COMPREHENSIVE SCHOOL",
    Address: "98 THAMI MNYELE STREET  ISITHEBE SECTION  TEMBISA",
    Latitude: "-26.0087",
    Longitude: "28.2401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6344",
    WardLookupId: "802",
    VDNumber: "32890248",
    RegisteredPopulation: "2247",
    VotingStationName: "KHULASIZWE PRIMARY SCHOOL",
    Address: "125 PHOFO STREET  MQANTSA SECTION  TEMBISA. 1632",
    Latitude: "-26.0098",
    Longitude: "28.2352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6345",
    WardLookupId: "802",
    VDNumber: "32890945",
    RegisteredPopulation: "522",
    VotingStationName: "MUNICIPAL BUILDING MADLALANE HALL",
    Address: "01 CNR INDAMA AND BUSHBUCHRIDGE  EXT 7  TEMBISA",
    Latitude: "-26.0097",
    Longitude: "28.247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6346",
    WardLookupId: "803",
    VDNumber: "32890158",
    RegisteredPopulation: "2440",
    VotingStationName: "BOITUMELONG SENIOR SECONDARY  SCHOOL",
    Address: "216 MIMAS STREET  SEDIBENG SECTION  TEMBISA. 1632",
    Latitude: "-26.0027",
    Longitude: "28.2386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6347",
    WardLookupId: "803",
    VDNumber: "32890169",
    RegisteredPopulation: "3224",
    VotingStationName: "THLAKANANG PRIMARY SCHOOL",
    Address: "428 LARISSA STREET  KOPANONG SECTION  TEMBISA. 1632",
    Latitude: "-26.002",
    Longitude: "28.2296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6348",
    WardLookupId: "803",
    VDNumber: "32890259",
    RegisteredPopulation: "1924",
    VotingStationName: "SEDIBENG PRIMARY SCHOOL",
    Address: "2 HALLEY STREET  SEDIBENG SECTION  TEMBISA. 1632",
    Latitude: "-26.0075",
    Longitude: "28.2337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6349",
    WardLookupId: "803",
    VDNumber: "32890282",
    RegisteredPopulation: "3012",
    VotingStationName: "ISEKELO PRIMARY SCHOOL",
    Address: "52 MAMPURU STREET  ISITHAME SECTION  TEMBISA. 1632",
    Latitude: "-26.0073",
    Longitude: "28.227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6350",
    WardLookupId: "803",
    VDNumber: "32890710",
    RegisteredPopulation: "1171",
    VotingStationName: "MASHEMONG PRIMARY SCHOOL",
    Address: "850 JANUS STREET  MASHEMONG SECTION  TEMBISA. 1632",
    Latitude: "-25.9999",
    Longitude: "28.2366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6351",
    WardLookupId: "803",
    VDNumber: "32890833",
    RegisteredPopulation: "891",
    VotingStationName: "EMMANUEL WESLEYAN CHRISTIAN CHURCH",
    Address: "22 MAPHANGA STREET  EMOYENI SECTION  TEMBISA",
    Latitude: "-26.0132",
    Longitude: "28.2333",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6352",
    WardLookupId: "804",
    VDNumber: "32890293",
    RegisteredPopulation: "2700",
    VotingStationName: "MVELAPHANDA PRIMARY SCHOOL",
    Address: "772 SEAPARANKWE STREET  ISITHAME SECTION  TEMBISA. 1632",
    Latitude: "-26.0049",
    Longitude: "28.2216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6353",
    WardLookupId: "804",
    VDNumber: "32890338",
    RegisteredPopulation: "1367",
    VotingStationName: "TEMBISA HIGH SCHOOL",
    Address: "1 ANDREW MAPHETHO DRIVE  IBAZELO SECTION  TEMBISA",
    Latitude: "-26.0077",
    Longitude: "28.2177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6354",
    WardLookupId: "804",
    VDNumber: "32890349",
    RegisteredPopulation: "1831",
    VotingStationName: "CHILD ACADEMY EDUCARE CENTRE",
    Address: "235 SHEBA STREET  IGQAGQA SECTION  TEMBISA",
    Latitude: "-26.0084",
    Longitude: "28.2219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6355",
    WardLookupId: "804",
    VDNumber: "32890350",
    RegisteredPopulation: "1733",
    VotingStationName: "ENDULWENI PRIMARY SCHOOL",
    Address: "797 HADEBE STREET  ENDULWINI SECTION  TEMBISA",
    Latitude: "-26.0115",
    Longitude: "28.2192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6356",
    WardLookupId: "804",
    VDNumber: "32890462",
    RegisteredPopulation: "1991",
    VotingStationName: "ETHIOPIAN CHURCH OF S.A.",
    Address: "295 ZIKALALA STREET  ENDULWENI SECTION  TEMBISA",
    Latitude: "-26.0147",
    Longitude: "28.2223",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6357",
    WardLookupId: "804",
    VDNumber: "32890631",
    RegisteredPopulation: "2627",
    VotingStationName: "UNITING REFORMED CHURCH OF SOUTH AFRICA",
    Address: "127 YENDE & MAOKA  EMOYENI SECTION  TEMBISA. 1632",
    Latitude: "-26.0149",
    Longitude: "28.2312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6358",
    WardLookupId: "805",
    VDNumber: "32890305",
    RegisteredPopulation: "2033",
    VotingStationName: "THUTO KE MATLA COMPREHENSIVE SCHOOL",
    Address: "267 KILIFI STREET  MAOKENG SECTION  TEMBISA. 1632",
    Latitude: "-25.9972",
    Longitude: "28.2227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6359",
    WardLookupId: "805",
    VDNumber: "32890316",
    RegisteredPopulation: "2961",
    VotingStationName: "TRANSVAAL BASOTHO LUTHERN CHURCH",
    Address: "215 GORONGOZA STREET  LEBOENG SECTION  TEMBISA",
    Latitude: "-25.9989",
    Longitude: "28.216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6360",
    WardLookupId: "805",
    VDNumber: "32890327",
    RegisteredPopulation: "3350",
    VotingStationName: "BERTHARRY ENGLISH PRIVATE SCHOOL",
    Address: "256 CNR. MANDARA & MAPUNGUBWE STREET  TEMONG SECTION  TEMBISA",
    Latitude: "-26.0026",
    Longitude: "28.2133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6361",
    WardLookupId: "805",
    VDNumber: "32890787",
    RegisteredPopulation: "1697",
    VotingStationName: "KINGDOM REIGN CHRISTIAN CHURCH",
    Address: "61 KAKONGO & DONGOLA STS  TEANONG SECTION  TEMBISA",
    Latitude: "-26.0052",
    Longitude: "28.2081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6362",
    WardLookupId: "805",
    VDNumber: "32890811",
    RegisteredPopulation: "1211",
    VotingStationName: "DOMINION LIFE CATHEDRAL CHURCH TEMBISA",
    Address: "00 MANDARA STREET  LEBOENG  TEMBISA",
    Latitude: "-26",
    Longitude: "28.2121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6363",
    WardLookupId: "805",
    VDNumber: "32890822",
    RegisteredPopulation: "1458",
    VotingStationName: "INGQAYIZIVELE HIGH SCHOOL",
    Address: "LUANGWA STREET  TSENELONG SECTION  TEMBISA",
    Latitude: "-26.0007",
    Longitude: "28.2192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6364",
    WardLookupId: "806",
    VDNumber: "32890260",
    RegisteredPopulation: "3317",
    VotingStationName: "EMANGWENI PRIMARY SCHOOL",
    Address: "320 MAPHANGA STREET  EMANGWENI SECTION  TEMBISA",
    Latitude: "-26.0186",
    Longitude: "28.2356",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6365",
    WardLookupId: "806",
    VDNumber: "32890271",
    RegisteredPopulation: "3094",
    VotingStationName: "UNITED CONGREGATIONAL CHURCH OF S.A.",
    Address: "1 SAM MOLELE DRIVE  ISEKELO SECTION  TEMBISA",
    Latitude: "-26.0172",
    Longitude: "28.2249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6366",
    WardLookupId: "806",
    VDNumber: "32890709",
    RegisteredPopulation: "4468",
    VotingStationName: "AGCI CHURCH",
    Address: "361 ESSELLEN PARK  TEMBISA",
    Latitude: "-26.0237",
    Longitude: "28.2396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6367",
    WardLookupId: "806",
    VDNumber: "32890844",
    RegisteredPopulation: "1254",
    VotingStationName: "THE HOLY CATHOLIC APOSTOLIC IN ZION OF SA",
    Address: "SAM MOLELE STREET  EMANGWENI  TEMBISA",
    Latitude: "-26.0183",
    Longitude: "28.2309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6368",
    WardLookupId: "806",
    VDNumber: "32890855",
    RegisteredPopulation: "1415",
    VotingStationName: "OLD APOSTOLIC CHURCH",
    Address: "01 CNR MONTJANE STREET ILILIBA   ILILIBA SECTION  TEMBISA",
    Latitude: "-26.0217",
    Longitude: "28.2317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6369",
    WardLookupId: "806",
    VDNumber: "32890899",
    RegisteredPopulation: "2380",
    VotingStationName: "SMART ANGELS DAY CARE",
    Address:
      "2472/28 BLOCK Q ESSELLEN PARK EXT 3  ESSELLEN PARK EXT 3  TEMBISA",
    Latitude: "-26.0324",
    Longitude: "28.2203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6370",
    WardLookupId: "807",
    VDNumber: "32890361",
    RegisteredPopulation: "3919",
    VotingStationName: "SEOTLOANA PRIMARY SCHOOL",
    Address: "645 SPARROW STR.  DIFATENG SECTION  TEMBISA. 1632",
    Latitude: "-26.0125",
    Longitude: "28.2088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6371",
    WardLookupId: "807",
    VDNumber: "32890372",
    RegisteredPopulation: "2952",
    VotingStationName: "BULAMEHLO CRECHE",
    Address: "208 SECRETARYBIRD STREET  DIFATENG SECTION  TEMBISA",
    Latitude: "-26.0101",
    Longitude: "28.2111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6372",
    WardLookupId: "807",
    VDNumber: "32890383",
    RegisteredPopulation: "1718",
    VotingStationName: "GEREFORMEERDE KERK TEMBISA WEST",
    Address: "21 NKORO STREET  MOTHEONG SECTION  TEMBISA. 1632",
    Latitude: "-26.0142",
    Longitude: "28.2054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6373",
    WardLookupId: "807",
    VDNumber: "32890620",
    RegisteredPopulation: "3056",
    VotingStationName: "MUNICIPAL BUILDING MOTSU PARK",
    Address: "01 PEACOCK STREET  MOTSU SECTION  TEMBISA",
    Latitude: "-26.0059",
    Longitude: "28.2033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6374",
    WardLookupId: "808",
    VDNumber: "32890394",
    RegisteredPopulation: "3545",
    VotingStationName: "BOJELONG PRIMARY SCHOOL",
    Address: "1 ROBIN STREET  MPHO SECTION  TEMBISA. 1632",
    Latitude: "-26.0177",
    Longitude: "28.207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6375",
    WardLookupId: "808",
    VDNumber: "32890417",
    RegisteredPopulation: "1801",
    VotingStationName: "WELAMLAMBO PRIMARY SCHOOL",
    Address: "473 UGANDA STREET  WELAMLAMBO SECTION  TEMBISA. 1632",
    Latitude: "-26.0235",
    Longitude: "28.2094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6376",
    WardLookupId: "808",
    VDNumber: "32900150",
    RegisteredPopulation: "4099",
    VotingStationName: "KHATLAMPING PRIMARY SCHOOL",
    Address: "178 CNR MORULA & MOROPA STS  KHATLAMPING SECTION  TEMBISA",
    Latitude: "-26.0127",
    Longitude: "28.198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6377",
    WardLookupId: "808",
    VDNumber: "32900206",
    RegisteredPopulation: "2709",
    VotingStationName: "SIPHIWE PRIMARY SCHOOL",
    Address: "205 UMFOLOZI STREET  ESANGWENI SECTION  TEMBISA. 1632",
    Latitude: "-26.0237",
    Longitude: "28.2013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6378",
    WardLookupId: "809",
    VDNumber: "32900161",
    RegisteredPopulation: "3228",
    VotingStationName: "IPONTSHE PRIMARY SCHOOL",
    Address: "455 CNR HIMALAYA & IPONTSHE STS  UMNONJANENI SECTION  TEMBISA",
    Latitude: "-26.0166",
    Longitude: "28.1933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6379",
    WardLookupId: "809",
    VDNumber: "32900172",
    RegisteredPopulation: "2611",
    VotingStationName: "METHODIST CHURCH OF SA",
    Address: "182 MAKHADO STREET  UMFUYANENI SECTION  TEMBISA",
    Latitude: "-26.022",
    Longitude: "28.1931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6380",
    WardLookupId: "809",
    VDNumber: "32900183",
    RegisteredPopulation: "4068",
    VotingStationName: "PHUTHUMANI PRIMARY SCHOOL",
    Address: "07 CNR HIMALAYA & MANANA STS  ESIQONGWENI SECTION  TEMBISA",
    Latitude: "-26.0229",
    Longitude: "28.1879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6381",
    WardLookupId: "809",
    VDNumber: "32900194",
    RegisteredPopulation: "3640",
    VotingStationName: "UMTHAMBEKA PRIMARY SCHOOL",
    Address: "1019 SLOVO STREET  UMTHAMBEKA SECTION  TEMBISA. 1632",
    Latitude: "-26.0279",
    Longitude: "28.1925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6382",
    WardLookupId: "809",
    VDNumber: "32900251",
    RegisteredPopulation: "1349",
    VotingStationName: "MODUOPO PRIMARY SCHOOL",
    Address:
      "180 GAOLATLHE MOLEFE STREET  MFUYANENG SECTION. TEMBISA  EAST RAND - EKURHULENI [EAST RAND]",
    Latitude: "-26.0199",
    Longitude: "28.1938",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6383",
    WardLookupId: "810",
    VDNumber: "32900116",
    RegisteredPopulation: "5171",
    VotingStationName: "OR TAMBO PRIMARY SCHOOL",
    Address: "731 NTOMBELA   PHOMOLONG  KEMPTON PARK",
    Latitude: "-26.0367",
    Longitude: "28.18",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6384",
    WardLookupId: "810",
    VDNumber: "32900127",
    RegisteredPopulation: "5204",
    VotingStationName: "PHOMOLONG PRIMARY SCHOOL",
    Address: "3816/7 MAHLANGU STREET  PHOMOLONG  TEMBISA. 1625",
    Latitude: "-26.0411",
    Longitude: "28.1827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6385",
    WardLookupId: "810",
    VDNumber: "32900149",
    RegisteredPopulation: "4694",
    VotingStationName: "PETER ZONGWANE PRIMARY SCHOOL",
    Address: "2914 FREEDOM DRIVE  PHOMOLONG  EDENVALE",
    Latitude: "-26.0487",
    Longitude: "28.1787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6386",
    WardLookupId: "810",
    VDNumber: "32900262",
    RegisteredPopulation: "477",
    VotingStationName: "CHLOORKOP PRIMARY SCHOOL",
    Address: "1 BUFFALO STREET  CHLOORKOP  KEMPTON PARK",
    Latitude: "-26.0563",
    Longitude: "28.1717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6387",
    WardLookupId: "811",
    VDNumber: "32890529",
    RegisteredPopulation: "4351",
    VotingStationName: "BIRCH ACRES PRIMARY SCHOOL",
    Address: "2 SNEEUBAL STREET  BIRCH ACRES EXT 5  KEMPTON PARK",
    Latitude: "-26.0618",
    Longitude: "28.2012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6388",
    WardLookupId: "811",
    VDNumber: "32890697",
    RegisteredPopulation: "4114",
    VotingStationName: "DOMINION LIFE CATHEDRAL CHURCH ( BIRCH ACRES)",
    Address: "CNR MOGORO & MOBOLA STREET  BIRCH ACRES  KEMPTON PARK",
    Latitude: "-26.0409",
    Longitude: "28.2004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6389",
    WardLookupId: "811",
    VDNumber: "32900138",
    RegisteredPopulation: "5710",
    VotingStationName: "PHOMOLONG SECONDARY SCHOOL",
    Address: "145 CNR MADUNA DR & S.M SONGO ROAD  PHOMOLONG  EDENVALE",
    Latitude: "-26.0501",
    Longitude: "28.1823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6390",
    WardLookupId: "812",
    VDNumber: "32890428",
    RegisteredPopulation: "3213",
    VotingStationName: "NYIKO PRIMARY SCHOOL",
    Address: "288 ZEPHANIA MSEBENZI STRRET  ISAVANA SECTION  TEMBISA. 1632",
    Latitude: "-26.0212",
    Longitude: "28.2127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6391",
    WardLookupId: "812",
    VDNumber: "32890439",
    RegisteredPopulation: "3544",
    VotingStationName: "MASISEBENZE COMPREHENSIVE SCHOOL",
    Address: "6 UGANDA STREET  JIYANE SECTION  TEMBISA. 1632",
    Latitude: "-26.026",
    Longitude: "28.2156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6392",
    WardLookupId: "812",
    VDNumber: "32890440",
    RegisteredPopulation: "2641",
    VotingStationName: "MASIQHAKAZE SECONDARY SCHOOL",
    Address: "549 MOLEBANE STREET  EMFIHLWENI SECTION  TEMBISA. 1632",
    Latitude: "-26.025",
    Longitude: "28.2245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6393",
    WardLookupId: "812",
    VDNumber: "32890451",
    RegisteredPopulation: "2960",
    VotingStationName: "INXIWENI PRIMARY SCHOOL",
    Address: "3 CNR NGEMA & JACKIE NCUBE STREET  ISIVANA  TEMBISA 1632",
    Latitude: "-26.0201",
    Longitude: "28.2185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6394",
    WardLookupId: "812",
    VDNumber: "32890686",
    RegisteredPopulation: "1909",
    VotingStationName: "ROYAL CHRISTIAN ACADEMY",
    Address: "601 CONAKRY STREET  ESIPHETHWENI SECTION  TEMBISA",
    Latitude: "-26.0292",
    Longitude: "28.2197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6395",
    WardLookupId: "812",
    VDNumber: "32890934",
    RegisteredPopulation: "536",
    VotingStationName: "APOSTOLIC CHURCH OF SWAZILAND IN SA",
    Address: "NHLAPO AND ZIKALALA  TEMBISA  TEMBISA",
    Latitude: "-26.0189",
    Longitude: "28.2221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6396",
    WardLookupId: "813",
    VDNumber: "32890013",
    RegisteredPopulation: "6023",
    VotingStationName: "LAERSKOOL KRUINSIG",
    Address: "14 FISKAAL STREET  GLEN MARAIS  KEMPTON PARK",
    Latitude: "-26.0732",
    Longitude: "28.2448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6397",
    WardLookupId: "813",
    VDNumber: "32890507",
    RegisteredPopulation: "4259",
    VotingStationName: "WYNAND MARAIS HALL",
    Address: "OLIENHOUT STREET  BIRCHLEIGH  KEMPTON PARK",
    Latitude: "-26.0672",
    Longitude: "28.2286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6398",
    WardLookupId: "813",
    VDNumber: "32890552",
    RegisteredPopulation: "4206",
    VotingStationName: "HOÃ‹RSKOOL JEUGLAND",
    Address: "CHRISTOFFEL STREET  VAN RIEBEECK PARK EXT 4  KEMPTON PARK",
    Latitude: "-26.0757",
    Longitude: "28.2128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6399",
    WardLookupId: "813",
    VDNumber: "32890912",
    RegisteredPopulation: "1883",
    VotingStationName: "BIRCHLEIGH LAERSKOOL",
    Address: "177 OLIENHOUT AVENUE  BIRCHLEIGH  KEMPTON PARK",
    Latitude: "-26.0611",
    Longitude: "28.2308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6400",
    WardLookupId: "814",
    VDNumber: "32890024",
    RegisteredPopulation: "2770",
    VotingStationName: "ASTON MANOR PRIMARY SCHOOL",
    Address: "ELANDWEG  NIMROD PARK  KEMPTON PARK",
    Latitude: "-26.0875",
    Longitude: "28.2412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6401",
    WardLookupId: "814",
    VDNumber: "32890046",
    RegisteredPopulation: "5411",
    VotingStationName: "THE OLD APOSTOLIC CHURCH",
    Address: "19 ILALA  STREET  GLEN MARAIS  KEMPTON PARK",
    Latitude: "-26.0767",
    Longitude: "28.2588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6402",
    WardLookupId: "814",
    VDNumber: "32890585",
    RegisteredPopulation: "3756",
    VotingStationName: "CIVIC CENTRE KEMPTON PARK",
    Address:
      "C/O C R SWART DRIVE AND PRETORIA ROAD  KEMPTON CENTRAL  KEMPTON PARK",
    Latitude: "-26.102",
    Longitude: "28.2267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6403",
    WardLookupId: "814",
    VDNumber: "32890596",
    RegisteredPopulation: "3834",
    VotingStationName: "LAERSKOOL IMPALA",
    Address: "VAN RIEBEECK ROAD  KEMPTON PARK  KEMPTON PARK",
    Latitude: "-26.0863",
    Longitude: "28.2309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6404",
    WardLookupId: "814",
    VDNumber: "32890923",
    RegisteredPopulation: "893",
    VotingStationName: "LAERSKOOL KREFT",
    Address: "KEMPTON PARK  ",
    Latitude: "-26.1001",
    Longitude: "28.237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6405",
    WardLookupId: "815",
    VDNumber: "32890035",
    RegisteredPopulation: "3487",
    VotingStationName: "LAERSKOOL KEMPTON PARK",
    Address: "KITTYHAWK STREET  RHODESFIELD  KEMPTON PARK",
    Latitude: "-26.1162",
    Longitude: "28.2353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6406",
    WardLookupId: "815",
    VDNumber: "32890608",
    RegisteredPopulation: "4219",
    VotingStationName: "CRESSLAWN PRIMARY SCHOOL",
    Address: "37 RIGGER ROAD  CRESSLAWN  KEMPTON PARK",
    Latitude: "-26.1145",
    Longitude: "28.2061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6407",
    WardLookupId: "815",
    VDNumber: "33020480",
    RegisteredPopulation: "5160",
    VotingStationName: "SUMMERFIELDS PRIMARY SCHOOL",
    Address: "PIAGIO STREET  IMPALA PARK  BOKSBURG",
    Latitude: "-26.1648",
    Longitude: "28.276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6408",
    WardLookupId: "815",
    VDNumber: "33020592",
    RegisteredPopulation: "2671",
    VotingStationName: "AUTOTEAM",
    Address: "154 NORTH RAND ROAD  BARDENE  BOKSBURG",
    Latitude: "-26.1735",
    Longitude: "28.2721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6409",
    WardLookupId: "816",
    VDNumber: "32900037",
    RegisteredPopulation: "2646",
    VotingStationName: "TRINITY PRESBYTERIAN CHURCH",
    Address: "CNR REMBRANDT & ST DAVID RD  ELMA PARK  EDENVALE",
    Latitude: "-26.1542",
    Longitude: "28.1594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6410",
    WardLookupId: "816",
    VDNumber: "32900048",
    RegisteredPopulation: "3234",
    VotingStationName: "EDENVALE COMMUNITY CENTRE",
    Address: "VAN RIEBEEK STREET  EDENVALE  EDENVALE",
    Latitude: "-26.1468",
    Longitude: "28.1566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6411",
    WardLookupId: "816",
    VDNumber: "32900082",
    RegisteredPopulation: "6717",
    VotingStationName: "EDENGLEN PRIMARY SCHOOL",
    Address: "WAGENAAR RD  EDEN GLEN  EDENVALE",
    Latitude: "-26.1324",
    Longitude: "28.1772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6412",
    WardLookupId: "816",
    VDNumber: "32900239",
    RegisteredPopulation: "3492",
    VotingStationName: "AGS  CHURCH EDENVALE",
    Address: "45 PRICE ROAD  ILLIONDALE  EDENVALE",
    Latitude: "-26.1169",
    Longitude: "28.1658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6413",
    WardLookupId: "817",
    VDNumber: "32900015",
    RegisteredPopulation: "3270",
    VotingStationName: "DUNVEGAN PRIMARY SCHOOL",
    Address: "SHEILA STREET  DUNVEGAN  EDENVALE",
    Latitude: "-26.1553",
    Longitude: "28.1518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6414",
    WardLookupId: "817",
    VDNumber: "32900026",
    RegisteredPopulation: "2591",
    VotingStationName: "EDENVALE HIGH SCHOOL",
    Address: "LINKSFIELD RD  DOWERGLEN  EDENVALE",
    Latitude: "-26.1488",
    Longitude: "28.1471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6415",
    WardLookupId: "817",
    VDNumber: "32900059",
    RegisteredPopulation: "2066",
    VotingStationName: "NEWDAY  CHURCH",
    Address: "129 12TH AVENUE  EDENVALE  EDENVALE",
    Latitude: "-26.1322",
    Longitude: "28.1509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6416",
    WardLookupId: "817",
    VDNumber: "32900060",
    RegisteredPopulation: "3017",
    VotingStationName: "EDENVALE BOWLING CLUB",
    Address: "SIXTH AVE  EDENVALE",
    Latitude: "-26.1284",
    Longitude: "28.1423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6417",
    WardLookupId: "817",
    VDNumber: "32900071",
    RegisteredPopulation: "3294",
    VotingStationName: "EASTLEIGH PRIMARY SCHOOL",
    Address: "1 CENTRAL AVENUE  EASTLEIGH  EDENVALE",
    Latitude: "-26.1348",
    Longitude: "28.1563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6418",
    WardLookupId: "818",
    VDNumber: "33060800",
    RegisteredPopulation: "1857",
    VotingStationName: "BEDFORDVIEW HIGH SCHOOL",
    Address: "HEALY STREET  MALVERN EAST  GERMISTON",
    Latitude: "-26.1958",
    Longitude: "28.1279",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6419",
    WardLookupId: "818",
    VDNumber: "33060811",
    RegisteredPopulation: "4643",
    VotingStationName: "BEDFORDVIEW CITY HALL",
    Address: "HAWLEY RD  BEDFORDVIEW  BEDFORDVIEW",
    Latitude: "-26.1793",
    Longitude: "28.1358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6420",
    WardLookupId: "818",
    VDNumber: "33060822",
    RegisteredPopulation: "2390",
    VotingStationName: "CORNERSTONE CHURCH",
    Address: "43 BERNARD     ROAD  MORNING HILL  GERMISTON",
    Latitude: "-26.1715",
    Longitude: "28.1257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6421",
    WardLookupId: "818",
    VDNumber: "33060833",
    RegisteredPopulation: "3529",
    VotingStationName: "SAHETI SCHOOL",
    Address: "CIVEN DRIVE  BEDFORDVIEW  BEDFORDVIEW",
    Latitude: "-26.1519",
    Longitude: "28.1259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6422",
    WardLookupId: "818",
    VDNumber: "33060844",
    RegisteredPopulation: "3531",
    VotingStationName: "BEDFORDVIEW SCOUT HALL",
    Address: "FLORENCE & DIZA RD  BEDFORDVIEW  GERMISTON",
    Latitude: "-26.1699",
    Longitude: "28.1412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6423",
    WardLookupId: "819",
    VDNumber: "33020514",
    RegisteredPopulation: "3851",
    VotingStationName: "THEMBELIHLE DAY CARE CENTRE",
    Address: "36 BUYAFUTHI STREET  DELMORE  BOKSBURG",
    Latitude: "-26.203",
    Longitude: "28.1968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6424",
    WardLookupId: "819",
    VDNumber: "33020716",
    RegisteredPopulation: "2690",
    VotingStationName: "JERUSALEM CHURCH",
    Address: "MAIN REEF & WIT DEEP ROAD  KANANA INFORMAL SETTLEMENT  BOKSBURG",
    Latitude: "-26.198",
    Longitude: "28.2038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6425",
    WardLookupId: "819",
    VDNumber: "33020727",
    RegisteredPopulation: "1279",
    VotingStationName: "WORD OF LIFE DAY CARE CENTRE",
    Address: "ANGELO SECTION F  CHRIS HANI INFORMAL SETTLEMENT  BOKSBURG",
    Latitude: "-26.2032",
    Longitude: "28.2167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6426",
    WardLookupId: "819",
    VDNumber: "33060776",
    RegisteredPopulation: "5712",
    VotingStationName: "FIRE OF GOD MINISTRIES",
    Address: "PRETORIA ROAD  PRIMROSE  GERMISTON",
    Latitude: "-26.1927",
    Longitude: "28.1636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6427",
    WardLookupId: "819",
    VDNumber: "33061081",
    RegisteredPopulation: "1197",
    VotingStationName: "ULANDA DAY CARE CENTRE",
    Address: "WITDEEP  WITFIELD  BOKSBURG",
    Latitude: "-26.204",
    Longitude: "28.1935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6428",
    WardLookupId: "820",
    VDNumber: "33020536",
    RegisteredPopulation: "4738",
    VotingStationName: "LAERSKOOL CONCORDIA",
    Address: "RIETFONTEIN RD  DRIEFONTEIN  BOKSBURG",
    Latitude: "-26.1891",
    Longitude: "28.2389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6429",
    WardLookupId: "820",
    VDNumber: "33020569",
    RegisteredPopulation: "4090",
    VotingStationName: "LAERSKOOL GOUDRAND",
    Address: "1141 CHARL CILLIER ST  BOKSBURG NORTH  BOKSBURG",
    Latitude: "-26.2037",
    Longitude: "28.2488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6430",
    WardLookupId: "820",
    VDNumber: "33020570",
    RegisteredPopulation: "2481",
    VotingStationName: "MC MOO`S PARTY FARMYARD",
    Address: "575 TRICHARDT ROAD  RAVENSWOOD  BOKSBURG",
    Latitude: "-26.1983",
    Longitude: "28.2529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6431",
    WardLookupId: "820",
    VDNumber: "33020581",
    RegisteredPopulation: "4288",
    VotingStationName: "LAERSKOOL WESTWOOD",
    Address: "PHILLIPS RD  WESTWOOD HOLDINGS  BOKSBURG",
    Latitude: "-26.1845",
    Longitude: "28.2774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6432",
    WardLookupId: "821",
    VDNumber: "32890057",
    RegisteredPopulation: "4838",
    VotingStationName: "BONAERO PARK PRIMARY SCHOOL",
    Address: "C/O ALDERGROVE AND ARLANDA  BONAERO PARK  KEMPTON PARK",
    Latitude: "-26.1161",
    Longitude: "28.255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6433",
    WardLookupId: "821",
    VDNumber: "32890866",
    RegisteredPopulation: "1087",
    VotingStationName: "EVANGELICAL LUTHERAN CONGREGATION  - KEMPTON PARK",
    Address: "GELDENHUYS  BONAERO PARK  KEMPTON PARK",
    Latitude: "-26.1274",
    Longitude: "28.2666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6434",
    WardLookupId: "821",
    VDNumber: "33010625",
    RegisteredPopulation: "3859",
    VotingStationName: "LADY FATIMA CHURCH",
    Address: "25 VAN WYK STR  BRENTWOOD PARK  BENONI",
    Latitude: "-26.1291",
    Longitude: "28.2876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6435",
    WardLookupId: "821",
    VDNumber: "33020446",
    RegisteredPopulation: "4193",
    VotingStationName: "ATLASVILLE TENNIS CLUB",
    Address: "674 FINCH  ATLASVILLE  BOKSBURG",
    Latitude: "-26.1591",
    Longitude: "28.2799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6436",
    WardLookupId: "821",
    VDNumber: "33020749",
    RegisteredPopulation: "840",
    VotingStationName: "CLEARWATER ESTATE CLUB HOUSE",
    Address: "ATLAS ROAD  ATLASVILLE  KEMPTON PARK",
    Latitude: "-26.1463",
    Longitude: "28.2737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6437",
    WardLookupId: "822",
    VDNumber: "33010580",
    RegisteredPopulation: "1586",
    VotingStationName: "MUSIC ACADEMY OF GAUTENG",
    Address: "OUTTFONTEIN RD  CLOVERDENE  BENONI",
    Latitude: "-26.1504",
    Longitude: "28.3809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6438",
    WardLookupId: "822",
    VDNumber: "33010591",
    RegisteredPopulation: "2971",
    VotingStationName: "CRYSTAL PARK HEALTH CLINIC",
    Address: "STRAND STREET  CRYSTAL PARK  BENONI",
    Latitude: "-26.137",
    Longitude: "28.3708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6439",
    WardLookupId: "822",
    VDNumber: "33010614",
    RegisteredPopulation: "3143",
    VotingStationName: "STARSUN COMPLEX",
    Address: "28 C/O BENONI ROAD AND ESTATE ROA  SMALL FARM  BENONI",
    Latitude: "-26.1212",
    Longitude: "28.3179",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6440",
    WardLookupId: "822",
    VDNumber: "33010636",
    RegisteredPopulation: "4498",
    VotingStationName: "BENONI COUNCIL FOR THE CARE OF THE AGED",
    Address: "PARKER ST.  RYNFIELD  BENONI",
    Latitude: "-26.1351",
    Longitude: "28.3427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6441",
    WardLookupId: "822",
    VDNumber: "33010861",
    RegisteredPopulation: "3951",
    VotingStationName: "CRYSTAL PARK PRIMARY SCHOOL",
    Address: "CNR CLYDESDALE & HORNBILL STR.  CRYSTAL PARK  BENONI",
    Latitude: "-26.1251",
    Longitude: "28.3648",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6442",
    WardLookupId: "823",
    VDNumber: "32890226",
    RegisteredPopulation: "4273",
    VotingStationName: "BREDEL LAERSKOOL",
    Address: "3RD AVENUE  BREDEL  KEMPTON PARK",
    Latitude: "-26.0839",
    Longitude: "28.2928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6443",
    WardLookupId: "823",
    VDNumber: "32890877",
    RegisteredPopulation: "3274",
    VotingStationName: "HERVORMDE KERK",
    Address: "174 WEST ROAD  BRENTWOOD PARK AH  BENONI",
    Latitude: "-26.1117",
    Longitude: "28.2751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6444",
    WardLookupId: "823",
    VDNumber: "33010568",
    RegisteredPopulation: "2384",
    VotingStationName: "PUTFONTEIN PRIMARY SCHOOL",
    Address: "CNR DU RANDT & KERK ST.  PUTFONTEIN  BENONI",
    Latitude: "-26.1275",
    Longitude: "28.3916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6445",
    WardLookupId: "823",
    VDNumber: "33010603",
    RegisteredPopulation: "2798",
    VotingStationName: "PETIT HIGH SCHOOL",
    Address: "59 ZESFONTEIN  PUTFONTEIN  BENONI",
    Latitude: "-26.0969",
    Longitude: "28.3719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6446",
    WardLookupId: "823",
    VDNumber: "33110288",
    RegisteredPopulation: "1330",
    VotingStationName: "BAPSFONTEIN HOTEL ( TWEEFONTEIN PORTION 10)",
    Address: "10 WOLMERANZ STREET  BAPSFONTEIN  BENONI",
    Latitude: "-26.0036",
    Longitude: "28.4247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6447",
    WardLookupId: "823",
    VDNumber: "33110299",
    RegisteredPopulation: "1451",
    VotingStationName: "CURRO SERENGETI ACADEMY",
    Address:
      "SERENGETI STREET  SERENGETI GOLF AND WILDLIFE ESTATE  KEMPTON PARK",
    Latitude: "-26.0566",
    Longitude: "28.2944",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6448",
    WardLookupId: "823",
    VDNumber: "33110312",
    RegisteredPopulation: "929",
    VotingStationName: "BEKEKAYO PRIMARY SCHOOL",
    Address: "DELMAS   BAPSFONTEIN  EKURHULENI RURAL",
    Latitude: "-26.0225",
    Longitude: "28.41",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6449",
    WardLookupId: "823",
    VDNumber: "33110435",
    RegisteredPopulation: "360",
    VotingStationName: "EASTLANDS LIFESTYLE",
    Address: "OFF 9TH ROAD  BREDELL  KEMPTON PARK",
    Latitude: "-26.0584",
    Longitude: "28.3324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6450",
    WardLookupId: "824",
    VDNumber: "33010142",
    RegisteredPopulation: "3068",
    VotingStationName: "BARCELONA SHOW HOUSES(WATER TANK)",
    Address: "PORTION 1 KNOPPIESFONTEIN   ETWATWA EXT 23  BENONI",
    Latitude: "-26.1002",
    Longitude: "28.4498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6451",
    WardLookupId: "824",
    VDNumber: "33010153",
    RegisteredPopulation: "2040",
    VotingStationName: "ETWATWA EXT 23 NEW SCHOOL",
    Address: "ETWATWA EXT 23  BARCELORNA  ETWATWA",
    Latitude: "-26.1048",
    Longitude: "28.4543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6452",
    WardLookupId: "824",
    VDNumber: "33010164",
    RegisteredPopulation: "2973",
    VotingStationName: "ELOHIM COMMUNITY CHURCH",
    Address: "33521 ETWATWA EXT 34  ETWATWA  BENONI",
    Latitude: "-26.1004",
    Longitude: "28.4447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6453",
    WardLookupId: "824",
    VDNumber: "33010175",
    RegisteredPopulation: "2590",
    VotingStationName: "BARCELONA CLINIC",
    Address: "BARCELONA  ETHWATHWA  DAVEYTON",
    Latitude: "-26.1074",
    Longitude: "28.4519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6454",
    WardLookupId: "824",
    VDNumber: "33110378",
    RegisteredPopulation: "496",
    VotingStationName: "UMNYEZANE PRIMARY SCHOOL",
    Address: "PORTION 5  NEAR PUTFONTEIN POLICE STATION  BENONI",
    Latitude: "-26.0972",
    Longitude: "28.4304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6455",
    WardLookupId: "824",
    VDNumber: "33110389",
    RegisteredPopulation: "4480",
    VotingStationName: "THUSANANG DAY CARE AND PRE-SCHOOL",
    Address: "2361 PHIRI STREET  MOGOBA VILLAGE  BENONI",
    Latitude: "-26.0922",
    Longitude: "28.4397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6456",
    WardLookupId: "825",
    VDNumber: "33010647",
    RegisteredPopulation: "4007",
    VotingStationName: "EAST RAND SPEEDBOAT CLUB",
    Address: "SAREL CILLIERS STR  RYNFIELD  BENONI",
    Latitude: "-26.1568",
    Longitude: "28.354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6457",
    WardLookupId: "825",
    VDNumber: "33010658",
    RegisteredPopulation: "3882",
    VotingStationName: "ST ANDREWS COMMUNITY CHURCH",
    Address: "MILES SHARP ST  RYNFIELD  BENONI",
    Latitude: "-26.1563",
    Longitude: "28.3285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6458",
    WardLookupId: "825",
    VDNumber: "33010669",
    RegisteredPopulation: "4424",
    VotingStationName: "JOHN BARRABLE HALL",
    Address: "OREILLY MERRY ST.  NORTHMEAD  BENONI",
    Latitude: "-26.1532",
    Longitude: "28.3156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6459",
    WardLookupId: "825",
    VDNumber: "33010670",
    RegisteredPopulation: "4041",
    VotingStationName: "NORTHFIELD METHODIST CHURCH",
    Address: "CNR AERODROME & WEBB DRIVE.  NORTHMEAD  BENONI",
    Latitude: "-26.1656",
    Longitude: "28.3129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6460",
    WardLookupId: "826",
    VDNumber: "33010681",
    RegisteredPopulation: "4964",
    VotingStationName: "BELVEDERE SCHOOL",
    Address: "142 KLEINFONTEIN AH.  FARRARMERE  BENONI",
    Latitude: "-26.1627",
    Longitude: "28.3003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6461",
    WardLookupId: "826",
    VDNumber: "33010715",
    RegisteredPopulation: "3951",
    VotingStationName: "BENONI BAPTIST CHURCH",
    Address: "33 WORDSWORTH  FARRARMERE  BENONI",
    Latitude: "-26.1732",
    Longitude: "28.3057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6462",
    WardLookupId: "826",
    VDNumber: "33010726",
    RegisteredPopulation: "3762",
    VotingStationName: "GAUTENG EASTERN TENNIS ASSOCIATION",
    Address: "PORTION 175 OF KLEINFONTEIN 76 IR  LAKEFIELD  BENONI",
    Latitude: "-26.1834",
    Longitude: "28.303",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6463",
    WardLookupId: "827",
    VDNumber: "33010760",
    RegisteredPopulation: "3227",
    VotingStationName: "SIYENZIWE DAY CARE",
    Address: "410 EMANDLENI   APEX  BRAKPAN",
    Latitude: "-26.2163",
    Longitude: "28.3195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6464",
    WardLookupId: "827",
    VDNumber: "33010771",
    RegisteredPopulation: "1487",
    VotingStationName: "ST JOSEPHS CATHOLIC CHURCH HALL",
    Address: "CNR BIRMINGHAM & LIVERPOOL RD  ACTONVILLE  BENONI",
    Latitude: "-26.2089",
    Longitude: "28.3069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6465",
    WardLookupId: "827",
    VDNumber: "33010782",
    RegisteredPopulation: "3150",
    VotingStationName: "ACTONVILLE COMMUNITY HALL",
    Address: "KHAN CRESCENT  ACTONVILLE  BENONI",
    Latitude: "-26.214",
    Longitude: "28.3015",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6466",
    WardLookupId: "827",
    VDNumber: "33010805",
    RegisteredPopulation: "2383",
    VotingStationName: "ACTONVILLE PRIMARY SCHOOL",
    Address: "1272 KAROLIA STREET  ACTONVILLE  BENONI",
    Latitude: "-26.2167",
    Longitude: "28.3008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6467",
    WardLookupId: "827",
    VDNumber: "33010872",
    RegisteredPopulation: "2560",
    VotingStationName: "DAVEY SOCIAL CENTRE",
    Address: "26 READING ROAD  ACTONVILLE  BENONI",
    Latitude: "-26.2108",
    Longitude: "28.2998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6468",
    WardLookupId: "828",
    VDNumber: "33010793",
    RegisteredPopulation: "2106",
    VotingStationName: "SOLOMON MOTLANA PRIMARY SCHOOL",
    Address: "383 MHLANZA AVE.  WATTVILLE  BENONI",
    Latitude: "-26.2161",
    Longitude: "28.2915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6469",
    WardLookupId: "828",
    VDNumber: "33010816",
    RegisteredPopulation: "2053",
    VotingStationName: "ISAAC MAKAU PRIMARY SCHOOL",
    Address: "1798 GODLO ST.  WATTVILLE  BENONI",
    Latitude: "-26.2196",
    Longitude: "28.2969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6470",
    WardLookupId: "828",
    VDNumber: "33010827",
    RegisteredPopulation: "2849",
    VotingStationName: "WATTVILLE DAYCARE CENTRE",
    Address: "DUBE STR  WATTVILLE  BENONI",
    Latitude: "-26.2223",
    Longitude: "28.2977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6471",
    WardLookupId: "828",
    VDNumber: "33010838",
    RegisteredPopulation: "2323",
    VotingStationName: "PARISH CHURCH OF RESURRECTION",
    Address: "2455 DUBE/MOKONE  WATTVILLE  BENONI",
    Latitude: "-26.2231",
    Longitude: "28.3074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6472",
    WardLookupId: "828",
    VDNumber: "33010849",
    RegisteredPopulation: "1627",
    VotingStationName: "SWEDISH CHURCH OF SA",
    Address: "CNR XUMA  WATTVILLE  EKURHULENI",
    Latitude: "-26.2227",
    Longitude: "28.3027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6473",
    WardLookupId: "828",
    VDNumber: "33010906",
    RegisteredPopulation: "1333",
    VotingStationName: "HARRY GWALA HALL",
    Address: "OPPOSITE HOUSE NO 452  WATTVILLE  BENONI",
    Latitude: "-26.2211",
    Longitude: "28.3121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6474",
    WardLookupId: "829",
    VDNumber: "33010209",
    RegisteredPopulation: "2906",
    VotingStationName: "EKUKHANYENI INTERMEDIATE SCHOOL",
    Address: "2875 MATHIBEDI STR  WATTVILLE  BENONI",
    Latitude: "-26.2276",
    Longitude: "28.3062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6475",
    WardLookupId: "829",
    VDNumber: "33010850",
    RegisteredPopulation: "2984",
    VotingStationName: "WATTVILLE YOUTH CENTRE",
    Address: "JANGU STR  WATTVILLE  BENONI",
    Latitude: "-26.2278",
    Longitude: "28.3016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6476",
    WardLookupId: "829",
    VDNumber: "33020345",
    RegisteredPopulation: "4589",
    VotingStationName: "VAN DYK PRIMARY SCHOOL",
    Address: "1061 KEURBOOM STREET  VAN DYK PARK  BOKSBURG",
    Latitude: "-26.2629",
    Longitude: "28.2908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6477",
    WardLookupId: "829",
    VDNumber: "33030403",
    RegisteredPopulation: "3023",
    VotingStationName: "DALPARK PRIVATE SCHOOL",
    Address: "34 UMKOMAAS RD  DALPARK EXT 6  BRAKPAN",
    Latitude: "-26.2404",
    Longitude: "28.3118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6478",
    WardLookupId: "829",
    VDNumber: "33030458",
    RegisteredPopulation: "2190",
    VotingStationName: "LEACHVILLE FULL GOSPEL CHURCH OF GOD",
    Address: "CNR KAAPMUIDEN & PRIVET  LEACHVILLE  BRAKPAN",
    Latitude: "-26.2288",
    Longitude: "28.323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6479",
    WardLookupId: "829",
    VDNumber: "33030470",
    RegisteredPopulation: "860",
    VotingStationName: "GAUTENG EAST EDUCATION DEVELOPMENT CENTRE",
    Address: "18  ESSENHOUT STR  DALPARK EXT 5  BRAKPAN",
    Latitude: "-26.2538",
    Longitude: "28.3292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6480",
    WardLookupId: "830",
    VDNumber: "33020356",
    RegisteredPopulation: "2086",
    VotingStationName: "PARKRAND PRIMARY SCHOOL",
    Address: "WASSENAAR STR  PARKRAND  BOKSBURG",
    Latitude: "-26.2492",
    Longitude: "28.277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6481",
    WardLookupId: "830",
    VDNumber: "33020367",
    RegisteredPopulation: "2830",
    VotingStationName: "BAANBREKER LAERSKOOL",
    Address: "1231 LANGENHOVEN STREET  PARKRAND  BOKSBURG",
    Latitude: "-26.2429",
    Longitude: "28.2644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6482",
    WardLookupId: "830",
    VDNumber: "33020378",
    RegisteredPopulation: "3487",
    VotingStationName: "PARKDENE PRIMARY SCHOOL",
    Address: "14 MC GAGHEY STREET  PARKDENE  BOKSBURG",
    Latitude: "-26.2306",
    Longitude: "28.2535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6483",
    WardLookupId: "830",
    VDNumber: "33020435",
    RegisteredPopulation: "2882",
    VotingStationName: "VOORTREKKER SECONDARY SCHOOL",
    Address: "1692 VOORTREKKER ST  BOKSBURG  BOKSBURG",
    Latitude: "-26.2202",
    Longitude: "28.2585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6484",
    WardLookupId: "830",
    VDNumber: "33020468",
    RegisteredPopulation: "1861",
    VotingStationName: "BOKSBURG HIGH SCHOOL",
    Address: "CNR LEEUPOORT&ALBU ROAD  BOKSBURG SOUTH  BOKSBURG",
    Latitude: "-26.2245",
    Longitude: "28.2543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6485",
    WardLookupId: "830",
    VDNumber: "33020660",
    RegisteredPopulation: "1014",
    VotingStationName: "BOKSBURG CITY STADIUM",
    Address: "CNR JUBILEE AND DUDLEY ROAD   BOKSBURG   BOKSBURG NORTH",
    Latitude: "-26.2355",
    Longitude: "28.2771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6486",
    WardLookupId: "831",
    VDNumber: "33020479",
    RegisteredPopulation: "1665",
    VotingStationName: "GEPPETTO KIDS PRE-SCHOOL AND CARE CENTRE",
    Address: "16 KEARSNEY AVENUE  DELMORE PARK  BOKSBURG",
    Latitude: "-26.2083",
    Longitude: "28.1989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6487",
    WardLookupId: "831",
    VDNumber: "33020503",
    RegisteredPopulation: "3766",
    VotingStationName: "LIGHT OF THE WORLD MINISTRIES",
    Address: "CNR DRIEFONTEIN:MAIN REEF/ANGELO R  ANGELO  BOKSBURG",
    Latitude: "-26.1986",
    Longitude: "28.2161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6488",
    WardLookupId: "831",
    VDNumber: "33020525",
    RegisteredPopulation: "2814",
    VotingStationName: "LAERSKOOL WITFIELD.",
    Address: "WITFIELD  BOKSBURG",
    Latitude: "-26.1868",
    Longitude: "28.2076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6489",
    WardLookupId: "831",
    VDNumber: "33020547",
    RegisteredPopulation: "2450",
    VotingStationName: "WIT DEEP PRIMARY SCHOOL",
    Address: "FIELD RD  WITFIELD  BOKSBURG",
    Latitude: "-26.187",
    Longitude: "28.2176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6490",
    WardLookupId: "831",
    VDNumber: "33020558",
    RegisteredPopulation: "2116",
    VotingStationName: "A.F.M OF S.A",
    Address: "75 MAIN  STREET  WITFIELD  BOKSBURG",
    Latitude: "-26.1825",
    Longitude: "28.2073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6491",
    WardLookupId: "831",
    VDNumber: "33020637",
    RegisteredPopulation: "1192",
    VotingStationName: "COMET HALL",
    Address: "CNR MAIN REEF & MINING BELT  COMET  BOKSBURG",
    Latitude: "-26.2115",
    Longitude: "28.2344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6492",
    WardLookupId: "831",
    VDNumber: "33020806",
    RegisteredPopulation: "792",
    VotingStationName: "OLD APOSTOLIC CHURCH",
    Address: "CRN WIT DEEP AND PRINCESS AVE  DELMORE PARK  BOKSBURG",
    Latitude: "-26.2126",
    Longitude: "28.2074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6493",
    WardLookupId: "832",
    VDNumber: "33020390",
    RegisteredPopulation: "2951",
    VotingStationName: "DROMMEDARIS PRIMARY SCHOOL",
    Address: "CLARENCE SEPTEMBER  REIGER PARK  BOKSBURG",
    Latitude: "-26.2348",
    Longitude: "28.2246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6494",
    WardLookupId: "832",
    VDNumber: "33020402",
    RegisteredPopulation: "1326",
    VotingStationName: "OOSRAND SEKONDÃŠRE SKOOL",
    Address: "GOEDEHOOP STR  REIGERPARK X1  BOKSBURG",
    Latitude: "-26.2299",
    Longitude: "28.2229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6495",
    WardLookupId: "832",
    VDNumber: "33020413",
    RegisteredPopulation: "3088",
    VotingStationName: "CHRISTIAN REFORMED - CHURCH",
    Address: "274 ANGELIER STREET  REIGER PARK  BOKSBURG",
    Latitude: "-26.2336",
    Longitude: "28.2164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6496",
    WardLookupId: "832",
    VDNumber: "33020424",
    RegisteredPopulation: "2695",
    VotingStationName: "REIGER PARK CIVIC CENTRE",
    Address: "LEON FERREIRA DRIVE  REIGER PARK  BOKSBURG",
    Latitude: "-26.2288",
    Longitude: "28.2274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6497",
    WardLookupId: "832",
    VDNumber: "33020457",
    RegisteredPopulation: "1765",
    VotingStationName: "EVANGELICAL ASSEMBLIES OF GOD.",
    Address:
      "68 ST ANTHONY`S   JOE SLOVO INFORMAL SETTLEMENT, REIGER PARK  BOKSBURG",
    Latitude: "-26.2251",
    Longitude: "28.2254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6498",
    WardLookupId: "833",
    VDNumber: "33060721",
    RegisteredPopulation: "3715",
    VotingStationName: "GALWAY PRIMARY SCHOOL",
    Address: "8 BAPAUME STREET  DELVILLE  GERMISTON",
    Latitude: "-26.2252",
    Longitude: "28.1723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6499",
    WardLookupId: "833",
    VDNumber: "33060732",
    RegisteredPopulation: "6160",
    VotingStationName: "EKURHULENI PRIMARY SCHOOL",
    Address: "59 PETERSON STREET  DUKATHOLE EXT 8  GERMISTON",
    Latitude: "-26.2245",
    Longitude: "28.1903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6500",
    WardLookupId: "833",
    VDNumber: "33061069",
    RegisteredPopulation: "4113",
    VotingStationName: "CITY HALL GERMISTON",
    Address: "CNR PRESIDENT AND SPILSBURY  GERMISTON SOUTH  GERMISTON",
    Latitude: "-26.2157",
    Longitude: "28.1638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6501",
    WardLookupId: "833",
    VDNumber: "33061238",
    RegisteredPopulation: "1255",
    VotingStationName: "LAERSKOOL GERMISTON",
    Address: "HIGH ROAD  GEORGE TOWN  GERMISTON",
    Latitude: "-26.2173",
    Longitude: "28.1718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6502",
    WardLookupId: "834",
    VDNumber: "33060710",
    RegisteredPopulation: "1727",
    VotingStationName: "GERMISTON HIGH SCHOOL",
    Address: "AIRPORT ROADCNR FIRST & THIRD AVE  GERMISTON  GERMISTON",
    Latitude: "-26.2366",
    Longitude: "28.1572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6503",
    WardLookupId: "834",
    VDNumber: "33060787",
    RegisteredPopulation: "2730",
    VotingStationName: "WYCHWOOD PRIMARY SCHOOL",
    Address: "CR SENATOR & HOME ROAD  WYCHWOOD  GERMISTON",
    Latitude: "-26.1998",
    Longitude: "28.1278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6504",
    WardLookupId: "834",
    VDNumber: "33060798",
    RegisteredPopulation: "4061",
    VotingStationName: "PRIMROSE PRIMARY SCHOOL",
    Address: "CNR BEACONSFIELD & THISTLE ROAD  PRIMROSE  GERMISTON",
    Latitude: "-26.1873",
    Longitude: "28.1603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6505",
    WardLookupId: "834",
    VDNumber: "33060855",
    RegisteredPopulation: "2642",
    VotingStationName: "DIE PRESIDENT LAERSKOOL",
    Address: "ASTRONOMY RD  PRIMROSE  GERMISTON",
    Latitude: "-26.1802",
    Longitude: "28.1653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6506",
    WardLookupId: "834",
    VDNumber: "33060866",
    RegisteredPopulation: "3221",
    VotingStationName: "SUNNYRIDGE PRIMARY SCHOOL",
    Address: "PITTS AVENUE  SUNNYRIDGE  GERMISTON",
    Latitude: "-26.1727",
    Longitude: "28.1717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6507",
    WardLookupId: "834",
    VDNumber: "33061115",
    RegisteredPopulation: "1369",
    VotingStationName: "PRIMROSE HILL PRIMARY",
    Address: "CNR KERRIA & CYDONIA STREETS   PRIMROSE  PRIMROSE",
    Latitude: "-26.1918",
    Longitude: "28.1444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6508",
    WardLookupId: "835",
    VDNumber: "33000275",
    RegisteredPopulation: "3746",
    VotingStationName: "ALBERTON RUGBY CLUB",
    Address: "BRAUN ROAD  VERWOERD PARK  ALBERTON",
    Latitude: "-26.2769",
    Longitude: "28.1327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6509",
    WardLookupId: "835",
    VDNumber: "33000309",
    RegisteredPopulation: "5022",
    VotingStationName: "LAERSKOOL PRESIDENT STEYN",
    Address: "CNR BRINK AND BRAUN ROAD  VERWOERDPARK  ALBERTON",
    Latitude: "-26.2696",
    Longitude: "28.1386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6510",
    WardLookupId: "835",
    VDNumber: "33000321",
    RegisteredPopulation: "3553",
    VotingStationName: "ALBERTON TEACHER`S CENTRE",
    Address: "49 1ST STREET ALBERTON NORTH  ALBERTON NORTH  ALBERTON",
    Latitude: "-26.2588",
    Longitude: "28.1298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6511",
    WardLookupId: "835",
    VDNumber: "33060686",
    RegisteredPopulation: "3663",
    VotingStationName: "LAERSKOOL ELANDIA",
    Address: "58 LENNOX ROAD  ALBEMARLE  GERMISTON",
    Latitude: "-26.2589",
    Longitude: "28.1573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6512",
    WardLookupId: "836",
    VDNumber: "33000196",
    RegisteredPopulation: "3910",
    VotingStationName: "EKURHULENI WEST COLLEGE",
    Address: "25 LAKE ATHUR STREET  BRACKENDOWNS  ALBERTON",
    Latitude: "-26.3456",
    Longitude: "28.0986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6513",
    WardLookupId: "836",
    VDNumber: "33000208",
    RegisteredPopulation: "3379",
    VotingStationName: "GLENBRACK JUNIOR HIGH SCHOOL",
    Address: "44 CNR SELIGNA & KAMFERBOS STREET  BRACKENDOWNS  ALBERTON",
    Latitude: "-26.3346",
    Longitude: "28.098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6514",
    WardLookupId: "836",
    VDNumber: "33000219",
    RegisteredPopulation: "1887",
    VotingStationName: "LAERSKOOL ORION",
    Address: "6 TOINETTE STREET  BRACKENHURST  ALBERTON",
    Latitude: "-26.3344",
    Longitude: "28.1056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6515",
    WardLookupId: "836",
    VDNumber: "33000231",
    RegisteredPopulation: "2805",
    VotingStationName: "GLENVIEW PRIMARY SCHOOL",
    Address: "11 APPELGREIN STREET  BRACKENHURST  ALBERTON",
    Latitude: "-26.3182",
    Longitude: "28.1059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6516",
    WardLookupId: "836",
    VDNumber: "33000242",
    RegisteredPopulation: "4319",
    VotingStationName: "HOÃ‹RSKOOL DINAMIKA",
    Address: "55 ROY CAMPBELL STREET  BRACKENHURST  ALBERTON",
    Latitude: "-26.3131",
    Longitude: "28.1007",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6517",
    WardLookupId: "837",
    VDNumber: "33060013",
    RegisteredPopulation: "2448",
    VotingStationName: "BOYS SCOUTS HALL",
    Address: "00 ANNA STREET  LAMBTON  GERMISTON",
    Latitude: "-26.2488",
    Longitude: "28.1829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6518",
    WardLookupId: "837",
    VDNumber: "33060664",
    RegisteredPopulation: "3048",
    VotingStationName: "DINWIDDIE HIGH SCHOOL",
    Address: "BALDOCK AVE  DINWIDDIE  GERMISTON",
    Latitude: "-26.2667",
    Longitude: "28.1631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6519",
    WardLookupId: "837",
    VDNumber: "33060675",
    RegisteredPopulation: "4224",
    VotingStationName: "ELSBURG HALL",
    Address: "PARK LANE  ELSBURG  GERMISTON",
    Latitude: "-26.2448",
    Longitude: "28.2001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6520",
    WardLookupId: "837",
    VDNumber: "33060697",
    RegisteredPopulation: "1933",
    VotingStationName: "LUTHERAN CHURCH PARKHILL",
    Address: "75 PIERCY AVENUE  PARKHILL GARDENS  GERMISTON",
    Latitude: "-26.2407",
    Longitude: "28.1896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6521",
    WardLookupId: "837",
    VDNumber: "33060709",
    RegisteredPopulation: "3262",
    VotingStationName: "LAERSKOOL DELVILLE",
    Address: "CNR FIRST & THIRD AVE  LAMBTON  GERMISTON",
    Latitude: "-26.2426",
    Longitude: "28.1738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6522",
    WardLookupId: "837",
    VDNumber: "33061227",
    RegisteredPopulation: "873",
    VotingStationName: "TENT - OPEN SPACE SEKEL STREET",
    Address: "SEKEL STREET- DELVILLE   DELVILLE  GERMISTON",
    Latitude: "-26.2353",
    Longitude: "28.1884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6523",
    WardLookupId: "838",
    VDNumber: "33060529",
    RegisteredPopulation: "2853",
    VotingStationName: "AME CHURCH",
    Address: "ERF 2527, CNR MOTHEO &MASAKHANE ST  TSHONGWENI  KATLEHONG",
    Latitude: "-26.3111",
    Longitude: "28.1569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6524",
    WardLookupId: "838",
    VDNumber: "33060631",
    RegisteredPopulation: "3393",
    VotingStationName: "LEONDALE HALL",
    Address: "ERF 1167 NERINE AVE  LEONDALE  GERMISTON",
    Latitude: "-26.3022",
    Longitude: "28.1817",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6525",
    WardLookupId: "838",
    VDNumber: "33061036",
    RegisteredPopulation: "4534",
    VotingStationName: "BUHLE PARK SECONDARY SCHOOL",
    Address: "EKURHULENI  ",
    Latitude: "-26.2915",
    Longitude: "28.1981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6526",
    WardLookupId: "838",
    VDNumber: "33061126",
    RegisteredPopulation: "785",
    VotingStationName: "LEONDALE PRIMARY SCHOOL",
    Address: "00 CAMELTHORN  LEONDALE  LEONDALE",
    Latitude: "-26.3071",
    Longitude: "28.1859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6527",
    WardLookupId: "838",
    VDNumber: "33061137",
    RegisteredPopulation: "1040",
    VotingStationName: "BUHLE PARK PRIMARY SCHOOL.",
    Address: "93 DRAGON STREET  BUHLE PARK  GERMISTON",
    Latitude: "-26.2949",
    Longitude: "28.204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6528",
    WardLookupId: "838",
    VDNumber: "33061272",
    RegisteredPopulation: "132",
    VotingStationName: "TENT - ROODEKOP EXT 25",
    Address: "ROODEKOP EXT 5  ROODEKOP  GERMISTON",
    Latitude: "-26.2929",
    Longitude: "28.1577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6529",
    WardLookupId: "839",
    VDNumber: "33060642",
    RegisteredPopulation: "3361",
    VotingStationName: "RONDEBULT EXT2 SEC SCHOOL",
    Address: "ROODEKOP  GERMISTON  GERMISTON",
    Latitude: "-26.3114",
    Longitude: "28.2186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6530",
    WardLookupId: "839",
    VDNumber: "33060990",
    RegisteredPopulation: "4486",
    VotingStationName: "PHUMULA GARDENS PRIMARY SCHOOL",
    Address: "4413 LUVUYO STREET  PHUMULA EXT. 21  GERMISTON",
    Latitude: "-26.3208",
    Longitude: "28.1966",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6531",
    WardLookupId: "839",
    VDNumber: "33061058",
    RegisteredPopulation: "5838",
    VotingStationName: "FAITH CHURCH INTERNATIONAL",
    Address: "1897 GARLIC STREET, RONDEBULT EXT 2  RONDEBULT EXT 2  GERMISTON",
    Latitude: "-26.3126",
    Longitude: "28.2141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6532",
    WardLookupId: "839",
    VDNumber: "33061148",
    RegisteredPopulation: "2574",
    VotingStationName: "ROODEKOP SECONDARY SCHOOL",
    Address: "CAMELTHORN  ROODEKOP  ROODEKOP",
    Latitude: "-26.3132",
    Longitude: "28.1871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6533",
    WardLookupId: "840",
    VDNumber: "33020389",
    RegisteredPopulation: "6512",
    VotingStationName: "CONQUERORS THROUGH CHRIST MINISTRIES",
    Address: "1400 FISH EAGLE  REIGERPARK EXT5  BOKSBURG",
    Latitude: "-26.2321",
    Longitude: "28.2078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6534",
    WardLookupId: "840",
    VDNumber: "33020738",
    RegisteredPopulation: "1992",
    VotingStationName: "NONCEDO DAY CARE CENTRE",
    Address: "1411 FISH EAGLE   RAMAPHOSA EXT5  BOKSBURG REIGERPARK",
    Latitude: "-26.2366",
    Longitude: "28.2071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6535",
    WardLookupId: "840",
    VDNumber: "33060653",
    RegisteredPopulation: "5630",
    VotingStationName: "SCHOOL OF ACHIEVEMENT",
    Address: "CNR HEIDELBURG&BLOEMHOF ROAD  ELSPARK  GERMISTON",
    Latitude: "-26.2604",
    Longitude: "28.2189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6536",
    WardLookupId: "840",
    VDNumber: "33061182",
    RegisteredPopulation: "1272",
    VotingStationName: "TENT VACANT SITE CNRS VINK, GANS & QUAIL",
    Address: "CNRS VINK, GANS & QUAIL  TEDSTON VILLE  GERMISTON",
    Latitude: "-26.2375",
    Longitude: "28.1975",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6537",
    WardLookupId: "840",
    VDNumber: "33061193",
    RegisteredPopulation: "2146",
    VotingStationName: "TENT ON THE CNR NITROGEN & OSBORN STR",
    Address: "CNR NITROGEN & OSBORN STREET  KLIPPOORTJIE AH  GERMISTON",
    Latitude: "-26.2863",
    Longitude: "28.2071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6538",
    WardLookupId: "841",
    VDNumber: "33020299",
    RegisteredPopulation: "3654",
    VotingStationName: "FALCON EDUCATIONAL SCHOOL",
    Address: "191 WEST CENTRAL  BOKSBURG  DAWN PARK",
    Latitude: "-26.307",
    Longitude: "28.2381",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6539",
    WardLookupId: "841",
    VDNumber: "33020312",
    RegisteredPopulation: "2344",
    VotingStationName: "SUNWARD PARK HIGH SCHOOL",
    Address: "3219 CRESTA RAOD  SUNWARD PARK  BOKSBURG",
    Latitude: "-26.2651",
    Longitude: "28.2465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6540",
    WardLookupId: "841",
    VDNumber: "33020323",
    RegisteredPopulation: "2851",
    VotingStationName: "FREEWAY PARK PRIMARY SCHOOL",
    Address: "876 ELSENBURG ROAD  FREEWAY PARK  BOKSBURG",
    Latitude: "-26.2578",
    Longitude: "28.2371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6541",
    WardLookupId: "841",
    VDNumber: "33020334",
    RegisteredPopulation: "3033",
    VotingStationName: "RECREATION CENTRE SUNWARD PARK",
    Address: "KINGFISHER AVENUE  SUNWARD PARK X14  BOKSBURG",
    Latitude: "-26.2588",
    Longitude: "28.2591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:41",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:41",
  },
  {
    VotingStationId: "6542",
    WardLookupId: "841",
    VDNumber: "33020648",
    RegisteredPopulation: "2399",
    VotingStationName: "GOD WITH US MINISTRIES CHURCH",
    Address: "5149 CNR DE WAAL & HEWITT  WINDMILL PARK EXT8  WINDMILL PARK",
    Latitude: "-26.2846",
    Longitude: "28.2557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6543",
    WardLookupId: "841",
    VDNumber: "33020659",
    RegisteredPopulation: "2180",
    VotingStationName: "KINGDOM WEALTH PROPERTIES",
    Address: "48 AGULHAS  KLIPPOORTJIE  BOKSBURG",
    Latitude: "-26.2813",
    Longitude: "28.2382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6544",
    WardLookupId: "841",
    VDNumber: "33020794",
    RegisteredPopulation: "1274",
    VotingStationName: "TENT ON CNR DR INJANKOMO & ILONGWE STR",
    Address: "CNR INJANKOMO&ILONGWE STREETS  WINDMILL PARK  BOKSBURG",
    Latitude: "-26.2913",
    Longitude: "28.2573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6545",
    WardLookupId: "842",
    VDNumber: "33020042",
    RegisteredPopulation: "2035",
    VotingStationName: "KUTLOANONG PRIMARY SCHOOL",
    Address: "2560 MOKHEHLE RD  VOSLOORUS X1  VOSLOORUS",
    Latitude: "-26.3533",
    Longitude: "28.1899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6546",
    WardLookupId: "842",
    VDNumber: "33020053",
    RegisteredPopulation: "2616",
    VotingStationName: "ILLINGE HIGH SCHOOL",
    Address: "1039 MOHLALA STREET  VOSLOORUS  BOKSBURG",
    Latitude: "-26.3534",
    Longitude: "28.1946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6547",
    WardLookupId: "842",
    VDNumber: "33020064",
    RegisteredPopulation: "2998",
    VotingStationName: "KHAYELIHLE PRIMARY SCHOOL",
    Address: "215 LEGWABE STREET  VOSLOORUS  BOKSBURG",
    Latitude: "-26.359",
    Longitude: "28.1885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6548",
    WardLookupId: "842",
    VDNumber: "33020109",
    RegisteredPopulation: "3308",
    VotingStationName: "VOSLOORUS CIVIC CENTRE",
    Address: "MC BOTHA DRIVE  VOSLOORUS EXT1  VOSLOORUS",
    Latitude: "-26.3498",
    Longitude: "28.1995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6549",
    WardLookupId: "842",
    VDNumber: "33020121",
    RegisteredPopulation: "2773",
    VotingStationName: "FORTUNE KUNENE PRIMARY SCHOOL",
    Address: "547 GAMPU RD  VOSLOORUS  BOKSBURG",
    Latitude: "-26.3504",
    Longitude: "28.2046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6550",
    WardLookupId: "842",
    VDNumber: "33020143",
    RegisteredPopulation: "1777",
    VotingStationName: "NGUNI HALL",
    Address: "1912 MAKGOKE STREET  VOSLOORUS  BOKSBURG",
    Latitude: "-26.3558",
    Longitude: "28.2046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6551",
    WardLookupId: "843",
    VDNumber: "33020222",
    RegisteredPopulation: "2890",
    VotingStationName: "MASITHWALISANE SECONDARY SCHOOL",
    Address: "17370 UMZUKUZU RD  VOSLOORUS X25  BOKSBURG",
    Latitude: "-26.3622",
    Longitude: "28.2292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6552",
    WardLookupId: "843",
    VDNumber: "33020244",
    RegisteredPopulation: "2781",
    VotingStationName: "BOPANG KGOTSO SCHOOL",
    Address: "6767 KOPAOPIE RD  VOSLOORUS X9  BOKSBURG",
    Latitude: "-26.3485",
    Longitude: "28.2215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6553",
    WardLookupId: "843",
    VDNumber: "33020255",
    RegisteredPopulation: "2349",
    VotingStationName: "TENT (TONYS MINI MARKET)",
    Address: "OLD JHB-HEIDELBERG RD  VLAKPLAATS  BOKSBURG",
    Latitude: "-26.3507",
    Longitude: "28.2394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6554",
    WardLookupId: "843",
    VDNumber: "33020266",
    RegisteredPopulation: "1513",
    VotingStationName: "TENT (SOMHLOLO TAXI RANK)",
    Address: "18383 BARRY MARAIS RD  VOSLOORUS X9  BOKSBURG",
    Latitude: "-26.3426",
    Longitude: "28.217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6555",
    WardLookupId: "843",
    VDNumber: "33020277",
    RegisteredPopulation: "2110",
    VotingStationName: "DAWN PARK PRIMARY SCHOOL",
    Address: "GALAHAD STREET  DAWN PARK  BOKSBURG",
    Latitude: "-26.3106",
    Longitude: "28.254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6556",
    WardLookupId: "843",
    VDNumber: "33020288",
    RegisteredPopulation: "2517",
    VotingStationName: "VILLA LIZA PRIMARY SCHOOL",
    Address: "RHINOCEROS STREET  VILLA LIZA  BOKSBURG",
    Latitude: "-26.3226",
    Longitude: "28.2493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6557",
    WardLookupId: "843",
    VDNumber: "33020783",
    RegisteredPopulation: "841",
    VotingStationName: "DAWN PARK SPORTS CLUB",
    Address: "PERCEVAL ROAD  DAWN PARK  BOKSBURG",
    Latitude: "-26.3058",
    Longitude: "28.2546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6558",
    WardLookupId: "844",
    VDNumber: "33020110",
    RegisteredPopulation: "2548",
    VotingStationName: "ORHOVELANI EDUCATION CENTRE",
    Address: "2 KGOSANA STREET  VOSLOORUS  BOKSBURG",
    Latitude: "-26.3439",
    Longitude: "28.2128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6559",
    WardLookupId: "844",
    VDNumber: "33020132",
    RegisteredPopulation: "2704",
    VotingStationName: "MTHIMKHULU PRIMARY SCHOOL",
    Address: "108 KHOZA STREET  VOSLOORUS  BOKSBURG",
    Latitude: "-26.3481",
    Longitude: "28.2102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6560",
    WardLookupId: "844",
    VDNumber: "33020187",
    RegisteredPopulation: "3242",
    VotingStationName: "J.D. DUMANE COMMUNITY CENTRE",
    Address: "1370 ROETS DRIVE  VOSLOORUS  BOKSBURG",
    Latitude: "-26.367",
    Longitude: "28.2183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6561",
    WardLookupId: "844",
    VDNumber: "33020198",
    RegisteredPopulation: "2496",
    VotingStationName: "NDLELENHLE PRIMARY SCHOOL",
    Address: "3945 DOUGLAS MONTHENG ROAD  VOSLOORUS  BOKSBURG",
    Latitude: "-26.358",
    Longitude: "28.21",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6562",
    WardLookupId: "844",
    VDNumber: "33020233",
    RegisteredPopulation: "2613",
    VotingStationName: "ABINALA PRIMARY SCHOOL",
    Address: "16416 KHOKHONOKA RD  VOSLOORUS X4  BOKSBURG",
    Latitude: "-26.3534",
    Longitude: "28.2104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6563",
    WardLookupId: "845",
    VDNumber: "33020075",
    RegisteredPopulation: "2563",
    VotingStationName: "METHODIST CHURCH",
    Address: "1014 MAKURUNYANE STR  EXT3  VOSLOORUS",
    Latitude: "-26.3633",
    Longitude: "28.1865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6564",
    WardLookupId: "845",
    VDNumber: "33020086",
    RegisteredPopulation: "3621",
    VotingStationName: "THABANG PRIMARY SCHOOL",
    Address: "INYONI STREET  VOSLOORUS X3  BOKSBURG",
    Latitude: "-26.372",
    Longitude: "28.1919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6565",
    WardLookupId: "845",
    VDNumber: "33020097",
    RegisteredPopulation: "2982",
    VotingStationName: "ITUMELENG NURSERY AND PRE-SCHOOL",
    Address: "581 ULONOLOZO ROAD  EXT 7  BOKSBURG",
    Latitude: "-26.3622",
    Longitude: "28.1943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6566",
    WardLookupId: "845",
    VDNumber: "33020154",
    RegisteredPopulation: "2507",
    VotingStationName: "JONGIMFUNDO PRIMARY SCHOOL",
    Address: "2739  MASEKO STREET  VOSLOORUS  BOKSBURG",
    Latitude: "-26.359",
    Longitude: "28.2048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6567",
    WardLookupId: "845",
    VDNumber: "33020165",
    RegisteredPopulation: "3001",
    VotingStationName: "THUTO-LESEDI SECONDARY SCHOOL",
    Address: "3944 NYASHENGO STREET  VOSLOORUS  BOKSBURG",
    Latitude: "-26.3657",
    Longitude: "28.2061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6568",
    WardLookupId: "846",
    VDNumber: "33060282",
    RegisteredPopulation: "2367",
    VotingStationName: "SONQOBA SCHOOL",
    Address: "ERF 254, CNR LUKHELE & SEKWANE ST  RAMOKONOPI EAST  KATLEHONG",
    Latitude: "-26.3561",
    Longitude: "28.1662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6569",
    WardLookupId: "846",
    VDNumber: "33060293",
    RegisteredPopulation: "2521",
    VotingStationName: "PHUMULANE SCHOOL",
    Address: "ERF 461, SETOKA STREET  MOKOENA  GERMISTON",
    Latitude: "-26.3535",
    Longitude: "28.1585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6570",
    WardLookupId: "846",
    VDNumber: "33060327",
    RegisteredPopulation: "2110",
    VotingStationName: "MPONTSHENG HIGH SCHOOL",
    Address: "ERF 289, CNR PASELA & MOSEU STREETS  NDLAZI SECTION  KATLEHONG",
    Latitude: "-26.3491",
    Longitude: "28.1551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6571",
    WardLookupId: "846",
    VDNumber: "33060338",
    RegisteredPopulation: "2476",
    VotingStationName: "KABELO PRIMARY SCHOOL",
    Address: "640 THAPELO STREET  MOSELEKI KATLEHONG  GERMISTON",
    Latitude: "-26.3487",
    Longitude: "28.1598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6572",
    WardLookupId: "846",
    VDNumber: "33060349",
    RegisteredPopulation: "2407",
    VotingStationName: "KWA DUKATHOLE HIGH SCHOOL",
    Address: "ERF 1, CNR SEGWANE & SONTONGA STREET  KATLEHONG  GERMISTON",
    Latitude: "-26.3472",
    Longitude: "28.163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6573",
    WardLookupId: "847",
    VDNumber: "33060372",
    RegisteredPopulation: "2442",
    VotingStationName: "MATSHEDISO SCHOOL",
    Address: "99 TSHOLO SECTION  KATLEHONG  GERMISTON",
    Latitude: "-26.3417",
    Longitude: "28.1588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6574",
    WardLookupId: "847",
    VDNumber: "33060383",
    RegisteredPopulation: "2435",
    VotingStationName: "EWC KATHORUS COLLEGE",
    Address: "ERF 782, POOLE STREET  KATLEHONG  GERMISTON",
    Latitude: "-26.3432",
    Longitude: "28.1641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6575",
    WardLookupId: "847",
    VDNumber: "33060394",
    RegisteredPopulation: "2488",
    VotingStationName: "KEKETSO PRIMARY SCHOOL",
    Address: "ERF 204, CNR TSAMELA & MOTSEPE & SONTONGA STR  PHOOKO  KATLEHONG",
    Latitude: "-26.3351",
    Longitude: "28.1629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6576",
    WardLookupId: "847",
    VDNumber: "33060406",
    RegisteredPopulation: "2005",
    VotingStationName: "MATJIBULO HIGHER PRIMARY SCHOOL",
    Address: "ERF 474, KANYANA & TLAPI STREETS  HLAHATSI  KATLEHONG",
    Latitude: "-26.3381",
    Longitude: "28.1569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6577",
    WardLookupId: "847",
    VDNumber: "33060451",
    RegisteredPopulation: "2329",
    VotingStationName: "AARON MOETI ADULT EDUCATION CENTRE MAIN",
    Address: "ERF 2, MOSEU STREET  MOPELI  KATLEHONG",
    Latitude: "-26.3286",
    Longitude: "28.1553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6578",
    WardLookupId: "847",
    VDNumber: "33060967",
    RegisteredPopulation: "1749",
    VotingStationName: "AARON MOETI ADULT EDUCATION CENTRE SATELLITE",
    Address: "ERF 262, MOAGI STREET  MOTSAMAI  KATLEHONG",
    Latitude: "-26.3247",
    Longitude: "28.1617",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6579",
    WardLookupId: "847",
    VDNumber: "33061160",
    RegisteredPopulation: "1038",
    VotingStationName: "TSHABALALA PRIMARY SCHOOL",
    Address: "CNR PHAKOE & BAKOENA  KATLEHONG  KATLEHONG (PHAKE)",
    Latitude: "-26.3313",
    Longitude: "28.1592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6580",
    WardLookupId: "848",
    VDNumber: "33060439",
    RegisteredPopulation: "2647",
    VotingStationName: "TENT @ FREEDOM PARK SQUARE",
    Address: "ERF.40 GINYA STREET  TWALA SECTION  KATLEHONG",
    Latitude: "-26.3312",
    Longitude: "28.1489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6581",
    WardLookupId: "848",
    VDNumber: "33060440",
    RegisteredPopulation: "1463",
    VotingStationName: "PHUMELELA COMBINED SCHOOL",
    Address: "269 MADONSELA  MAKULA SECTION - KATLEHONG  GERMISTON",
    Latitude: "-26.3251",
    Longitude: "28.1558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6582",
    WardLookupId: "848",
    VDNumber: "33060462",
    RegisteredPopulation: "1181",
    VotingStationName: "DE BRUYN PRIMARY SCHOOL",
    Address: "257 MOERANE  KATLEHONG  GERMISTON",
    Latitude: "-26.3224",
    Longitude: "28.1593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6583",
    WardLookupId: "848",
    VDNumber: "33060473",
    RegisteredPopulation: "2887",
    VotingStationName: "THULISA LOWER PRIMARY SCHOOL",
    Address: "498 CNR VILLE &  VILAKAZI STREET  SKOSANA  KATLEHONG",
    Latitude: "-26.326",
    Longitude: "28.1486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6584",
    WardLookupId: "848",
    VDNumber: "33060495",
    RegisteredPopulation: "1838",
    VotingStationName: "DUKATHOLE PRIMARY SCHOOL",
    Address: "ERF 276, CNR DUKATOLE & TSOMANE STREETS  MONAHENG  KATLEHONG",
    Latitude: "-26.3221",
    Longitude: "28.1541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6585",
    WardLookupId: "848",
    VDNumber: "33060507",
    RegisteredPopulation: "1676",
    VotingStationName: "THOKOZA LOWER PRIMARY SCHOOL",
    Address: "ERF 322, CNR SEOUE & MOGOJO & MZIZI STREETS  CREDI  KATLEHONG",
    Latitude: "-26.3206",
    Longitude: "28.1528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6586",
    WardLookupId: "848",
    VDNumber: "33060541",
    RegisteredPopulation: "1088",
    VotingStationName: "D.H. WILLIAMS HALL",
    Address: "ADMIN TRIANGLE  KATLEHONG  GERMISTON",
    Latitude: "-26.315",
    Longitude: "28.1541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6587",
    WardLookupId: "849",
    VDNumber: "33060563",
    RegisteredPopulation: "2815",
    VotingStationName: "TAMAHO CHILD CARE CENTRE",
    Address: "MOTSAMAI  KATLEHONG  KATLEHONG",
    Latitude: "-26.3079",
    Longitude: "28.1445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6588",
    WardLookupId: "849",
    VDNumber: "33060574",
    RegisteredPopulation: "2786",
    VotingStationName: "TAMAHO PRIMARY SCHOOL",
    Address: "139 PORCIMI STREET  HLAHANE  KATLEHONG",
    Latitude: "-26.3044",
    Longitude: "28.1472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6589",
    WardLookupId: "849",
    VDNumber: "33060585",
    RegisteredPopulation: "2214",
    VotingStationName: "WORD OF LIFE VICTORY CENTRE",
    Address: "1-4 MAHLA  NHLAPHO SECTION -KATLEHONG  GERMISTON",
    Latitude: "-26.3079",
    Longitude: "28.1493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6590",
    WardLookupId: "849",
    VDNumber: "33060596",
    RegisteredPopulation: "1180",
    VotingStationName: "REAHILE PRIMARY SCHOOL",
    Address: "1962 ZWANE STREET  TSHONGWENI SECTION-KATLEHONG  GERMISTON",
    Latitude: "-26.3036",
    Longitude: "28.1571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6591",
    WardLookupId: "849",
    VDNumber: "33060608",
    RegisteredPopulation: "2481",
    VotingStationName: "ALAFANG HIGH SCHOOL",
    Address: "1190 MAKUBA STREET  TSHONGWENI SECTION-KATLEHONG  GERMISTON",
    Latitude: "-26.3066",
    Longitude: "28.1544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6592",
    WardLookupId: "849",
    VDNumber: "33060956",
    RegisteredPopulation: "2500",
    VotingStationName: "MOGOBENG PRIMARY SCHOOL",
    Address: "131 SODHANGISA STREET  NHLAPO SECTION-KATLEHONG  GERMISTON",
    Latitude: "-26.3095",
    Longitude: "28.151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6593",
    WardLookupId: "850",
    VDNumber: "33000185",
    RegisteredPopulation: "5403",
    VotingStationName: "THOKOZA AUDITORIUM HALL",
    Address: "KHUMALO (STAND 8016)  THOKOZA  ALBERTON",
    Latitude: "-26.3311",
    Longitude: "28.1429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6594",
    WardLookupId: "850",
    VDNumber: "33000343",
    RegisteredPopulation: "1487",
    VotingStationName: "BAMBANANI DAYCARE CENTRE",
    Address: "8021 MAZIBUKO  STREET  THOKOZA  ALBERTON",
    Latitude: "-26.3339",
    Longitude: "28.1435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6595",
    WardLookupId: "850",
    VDNumber: "33060484",
    RegisteredPopulation: "2213",
    VotingStationName: "SAMSON PRIMARY SCHOOL",
    Address: "ERF 236, MHLAMBI STREET  MAPHANGA  KATLEHONG",
    Latitude: "-26.3201",
    Longitude: "28.1474",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6596",
    WardLookupId: "850",
    VDNumber: "33060530",
    RegisteredPopulation: "1716",
    VotingStationName: "FUMANA COMPREHENSIVE SCHOOL",
    Address: "49 TSABALALA ST  MAPHANGA SECTION KATLEHONG  ",
    Latitude: "-26.3161",
    Longitude: "28.1497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6597",
    WardLookupId: "850",
    VDNumber: "33060552",
    RegisteredPopulation: "4133",
    VotingStationName: "TENT AT MANDELA SECTION",
    Address: "CNR LESOTHO & TSHABALALA  KATLEHONG  KATLEHONG",
    Latitude: "-26.3144",
    Longitude: "28.1456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6598",
    WardLookupId: "851",
    VDNumber: "33000062",
    RegisteredPopulation: "3989",
    VotingStationName: "PHOLA PARK HALL",
    Address: "12613 YENDE STREET  PHOLA PARK  ALBERTON",
    Latitude: "-26.3666",
    Longitude: "28.127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6599",
    WardLookupId: "851",
    VDNumber: "33000073",
    RegisteredPopulation: "2744",
    VotingStationName: "TIISETSONG HIGH SCHOOL",
    Address: "1335 SHLAKWE  THOKOZA EXTENSION 1  ALBERTON",
    Latitude: "-26.3606",
    Longitude: "28.1313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6600",
    WardLookupId: "851",
    VDNumber: "33000398",
    RegisteredPopulation: "1054",
    VotingStationName: "TENT AT THINASONKE EXT.3",
    Address: "(EKU - EKURHULENI [EAST RAND])   ",
    Latitude: "-26.3637",
    Longitude: "28.1024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6601",
    WardLookupId: "851",
    VDNumber: "33000400",
    RegisteredPopulation: "864",
    VotingStationName: "GOD`S POWER HOUSE WORSHIP CENTRE",
    Address: "UMGENI STREET  THINASONKE EXT.3  ALBERTON",
    Latitude: "-26.3703",
    Longitude: "28.1075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6602",
    WardLookupId: "851",
    VDNumber: "33000411",
    RegisteredPopulation: "880",
    VotingStationName: "CARAVAN PARK (TENT)",
    Address: "PHUMELELA STREET  THINASONKE EXT.4  ALBERTON",
    Latitude: "-26.3655",
    Longitude: "28.1134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6603",
    WardLookupId: "851",
    VDNumber: "33000422",
    RegisteredPopulation: "1337",
    VotingStationName: "ENCOCHOYINI PRIMARY SCHOOL",
    Address: "12520 K. SECTION   PHOLA PARK -THOKOZA  ALBERTON",
    Latitude: "-26.3608",
    Longitude: "28.121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6604",
    WardLookupId: "851",
    VDNumber: "33000444",
    RegisteredPopulation: "3472",
    VotingStationName: "EDENRIDGE PRIMARY SCHOOL",
    Address: "4087 ACHMAT DONGOR STREET  EDENPARK  ALBERTON",
    Latitude: "-26.3819",
    Longitude: "28.1172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6605",
    WardLookupId: "851",
    VDNumber: "33000466",
    RegisteredPopulation: "1596",
    VotingStationName: "TENT PHASE ONE EDENPARK",
    Address: "ADAM SMALL  EDENPARK  ALBERTON",
    Latitude: "-26.373",
    Longitude: "28.1182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6606",
    WardLookupId: "851",
    VDNumber: "33000488",
    RegisteredPopulation: "677",
    VotingStationName: "ROYAL SCHOOL@ SKY CITY",
    Address: "26 R550 GARTHDALE AH  SKY CITY  ALBERTON",
    Latitude: "-26.4169",
    Longitude: "28.1108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6607",
    WardLookupId: "852",
    VDNumber: "33000130",
    RegisteredPopulation: "2017",
    VotingStationName: "UNITING REFORMED CHURCH",
    Address: "180 MABUYA STREET (STAND 1809)  THOKOZA  ALBERTON",
    Latitude: "-26.3503",
    Longitude: "28.1376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6608",
    WardLookupId: "852",
    VDNumber: "33000141",
    RegisteredPopulation: "2012",
    VotingStationName: "LUTHERAN CHURCH",
    Address: "6797 MPYE  THOKOZA  ALBERTON",
    Latitude: "-26.3443",
    Longitude: "28.1329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6609",
    WardLookupId: "852",
    VDNumber: "33000152",
    RegisteredPopulation: "2661",
    VotingStationName: "REFORM APOSTOLIC CHURCH OF SA (THINTWA VILLAGE)",
    Address: "155 PULEDI STREET  TOKOZA  ALBERTON",
    Latitude: "-26.3428",
    Longitude: "28.1336",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6610",
    WardLookupId: "852",
    VDNumber: "33000163",
    RegisteredPopulation: "2235",
    VotingStationName: "UMKHATHIZWE PRIMARY SCHOOL",
    Address: "1090 MJIVANE STREET  THOKOZA  ALBERTON",
    Latitude: "-26.3474",
    Longitude: "28.1424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6611",
    WardLookupId: "852",
    VDNumber: "33000174",
    RegisteredPopulation: "2827",
    VotingStationName: "R.P MAPHANZELA PRIMARY SCHOOL.",
    Address: "CNR BUTHELEZI & TSHABALALA  KATLEHONG  KATLEHONG",
    Latitude: "-26.3437",
    Longitude: "28.1422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6612",
    WardLookupId: "852",
    VDNumber: "33000365",
    RegisteredPopulation: "2212",
    VotingStationName: "TSHWARAGANO PRIMARY SCHOOL",
    Address: "6027 MPHASANE STREET  THOKOZA  ALBERTON",
    Latitude: "-26.3411",
    Longitude: "28.1363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6613",
    WardLookupId: "853",
    VDNumber: "33060260",
    RegisteredPopulation: "1613",
    VotingStationName: "NOKULUNGO PRIMARY SCHOOL",
    Address: "ERF 1, TWILI STREET  SALI SECTION  KATLEHONG",
    Latitude: "-26.3526",
    Longitude: "28.1517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6614",
    WardLookupId: "853",
    VDNumber: "33060305",
    RegisteredPopulation: "1768",
    VotingStationName: "THEMBALETHU PRIMARY SCHOOL",
    Address: "756 MNDEBELE STREET  MAVIMBELA SECTION  KATLEHONG",
    Latitude: "-26.3503",
    Longitude: "28.1474",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6615",
    WardLookupId: "853",
    VDNumber: "33060316",
    RegisteredPopulation: "1826",
    VotingStationName: "KATLEHONG TECHNICAL CENTRE",
    Address: "KHUMALO CIRCLE (CLOSE)  KATLEHONG  KATLEHONG",
    Latitude: "-26.3438",
    Longitude: "28.15",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6616",
    WardLookupId: "853",
    VDNumber: "33060350",
    RegisteredPopulation: "1655",
    VotingStationName: "MUTINGATI COMMUNITY SCHOOL",
    Address: "ERF 329, CNR MBOOI & MOTSHABI ST  MAVIMBELA SECTION  KATLEHONG",
    Latitude: "-26.3462",
    Longitude: "28.1464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6617",
    WardLookupId: "853",
    VDNumber: "33060361",
    RegisteredPopulation: "2820",
    VotingStationName: "INTOKOZO  PRIMARY SCHOOL",
    Address: "ERF 950, CNR GANYA & SIMELANI STR  NCALA  KATLEHONG",
    Latitude: "-26.3402",
    Longitude: "28.1467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6618",
    WardLookupId: "853",
    VDNumber: "33060417",
    RegisteredPopulation: "1926",
    VotingStationName: "KUMALO PRIMARY SCHOOL",
    Address:
      "ERF 397, CNR NDLOVU & MATHIMUNYE & HLATHI STREETS  NGEMA  KATLEHONG",
    Latitude: "-26.3371",
    Longitude: "28.1512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6619",
    WardLookupId: "853",
    VDNumber: "33060428",
    RegisteredPopulation: "1479",
    VotingStationName: "UKHANYISO HIGHER PRIMARY SCHOOL",
    Address: "ERF 924, CNR GANYA & SIMELANI STREETS  TWALA  KATLEHONG",
    Latitude: "-26.3365",
    Longitude: "28.1456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6620",
    WardLookupId: "854",
    VDNumber: "33000084",
    RegisteredPopulation: "1930",
    VotingStationName: "UMZAMO PRIMARY SCHOOL",
    Address: "1429 GAGADU STREET  THOKOZA  ALBERTON",
    Latitude: "-26.3514",
    Longitude: "28.1422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6621",
    WardLookupId: "854",
    VDNumber: "33000095",
    RegisteredPopulation: "2315",
    VotingStationName: "NEW BRETHERN APOSTOLIC CHURCH",
    Address: "3227 YENDE STREET  THOKOZA EXT.1  ALBERTON",
    Latitude: "-26.3587",
    Longitude: "28.1391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6622",
    WardLookupId: "854",
    VDNumber: "33000107",
    RegisteredPopulation: "2835",
    VotingStationName: "SAM NTULI SPORTS CENTRE",
    Address: "MATSEMELA  TOKOZA  ALBERTON",
    Latitude: "-26.3528",
    Longitude: "28.1306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6623",
    WardLookupId: "854",
    VDNumber: "33000118",
    RegisteredPopulation: "2292",
    VotingStationName: "MOHAUNG PRIMARY SCHOOL",
    Address: "7271 RAMONOTSI  THOKOZA  ALBERTON",
    Latitude: "-26.3556",
    Longitude: "28.1358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6624",
    WardLookupId: "854",
    VDNumber: "33000129",
    RegisteredPopulation: "2079",
    VotingStationName: "THOKO-THABA HIGH SCHOOL",
    Address: "MOEPSHE STREET (STAND 3519)  THOKOZA  ALBERTON",
    Latitude: "-26.3519",
    Longitude: "28.1379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6625",
    WardLookupId: "854",
    VDNumber: "33060259",
    RegisteredPopulation: "2006",
    VotingStationName: "TENT @ RADEBE OPEN SPACE",
    Address: "732 CNR MOYA & MAHLAHLO  KATLEHONG  RADEBE SECTION",
    Latitude: "-26.3526",
    Longitude: "28.1477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6626",
    WardLookupId: "855",
    VDNumber: "33000017",
    RegisteredPopulation: "3266",
    VotingStationName: "J.D THOMAS HALL",
    Address: "FERRARI STREET  EDEN PARK  ALBERTON",
    Latitude: "-26.3769",
    Longitude: "28.1258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6627",
    WardLookupId: "855",
    VDNumber: "33000028",
    RegisteredPopulation: "3139",
    VotingStationName: "EDENPARK HIGH SCHOOL",
    Address: "14 FERRARI STREET  EDEN PARK  ALBERTON",
    Latitude: "-26.3755",
    Longitude: "28.13",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6628",
    WardLookupId: "855",
    VDNumber: "33000040",
    RegisteredPopulation: "3920",
    VotingStationName: "MOHLODI PRIMARY SCHOOL",
    Address: "10051 EXT.5 ESHOWE STREET  TOKOZA  ALBERTON",
    Latitude: "-26.3704",
    Longitude: "28.1338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6629",
    WardLookupId: "855",
    VDNumber: "33000051",
    RegisteredPopulation: "2725",
    VotingStationName: "BUHLEBUZILE SECONDARY SCHOOL",
    Address: "10767 NONG STREET  THOKOZA X 2  ALBERTON",
    Latitude: "-26.3657",
    Longitude: "28.1334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6630",
    WardLookupId: "855",
    VDNumber: "33000433",
    RegisteredPopulation: "1039",
    VotingStationName: "SIMUNYE PRIMARY SCHOOL",
    Address: "BHEJANE STREET  THOKOZA  ALBERTON",
    Latitude: "-26.3607",
    Longitude: "28.1402",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6631",
    WardLookupId: "856",
    VDNumber: "33000332",
    RegisteredPopulation: "5504",
    VotingStationName: "GREENFIELDS COMMUNITY HALL",
    Address: "UMBRELLA STREET  GREENFIELDS  ALBERTON",
    Latitude: "-26.3891",
    Longitude: "28.1273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6632",
    WardLookupId: "856",
    VDNumber: "33061025",
    RegisteredPopulation: "4178",
    VotingStationName: "REALEBOHA PRIMARY SCHOOL",
    Address: "2699 MKHATHIZWE STREET  PALMRIDGE EXT.2  PALMRIDGE",
    Latitude: "-26.4",
    Longitude: "28.1477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6633",
    WardLookupId: "856",
    VDNumber: "33061047",
    RegisteredPopulation: "2703",
    VotingStationName: "INTERNATIONAL PENTECOSTAL HOLINESS CHURCH",
    Address: "CELTIS & UMQADANE STREET  PALMRIDGE  GERMISTON",
    Latitude: "-26.4016",
    Longitude: "28.1375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6634",
    WardLookupId: "856",
    VDNumber: "33061283",
    RegisteredPopulation: "878",
    VotingStationName: "PALMRIDGE EXT.9 PHASE 1 (TENT)",
    Address: "N/A  PALMRIDGE  GERMISTON",
    Latitude: "-26.4049",
    Longitude: "28.136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6635",
    WardLookupId: "857",
    VDNumber: "33060237",
    RegisteredPopulation: "3070",
    VotingStationName: "PONEGO SCHOOL",
    Address: "ERF 816, MOSEU STREET  RAMAKONOPI  KATLEHONG",
    Latitude: "-26.363",
    Longitude: "28.1581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6636",
    WardLookupId: "857",
    VDNumber: "33060248",
    RegisteredPopulation: "2146",
    VotingStationName: "HLESIPHI SCHOOL",
    Address: "ERF 151, MOPHOLI STQ  MNGADI SECTION  KATLEHONG",
    Latitude: "-26.3606",
    Longitude: "28.1488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6637",
    WardLookupId: "857",
    VDNumber: "33060271",
    RegisteredPopulation: "2776",
    VotingStationName: "KATHA DAY CARE CENTRE",
    Address:
      "677 MAILI STREET MONISE SECTION  MONISE SECTION  KATLEHONG , GERMISTON",
    Latitude: "-26.3554",
    Longitude: "28.1584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6638",
    WardLookupId: "857",
    VDNumber: "33061003",
    RegisteredPopulation: "3242",
    VotingStationName: "HOSTEL OFFICES",
    Address: "KWESINE  KATLEHONG",
    Latitude: "-26.3647",
    Longitude: "28.1472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6639",
    WardLookupId: "857",
    VDNumber: "33061171",
    RegisteredPopulation: "1582",
    VotingStationName: "GOD`S WILL MISSION MINISTRIES",
    Address: "RAMOKONOPI EAST  KATLEHONG  GERMISTON",
    Latitude: "-26.3701",
    Longitude: "28.1565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6640",
    WardLookupId: "858",
    VDNumber: "33060114",
    RegisteredPopulation: "3575",
    VotingStationName: "ABRAM HLOPE PRIMARY SCHOOL",
    Address: "7334 MALEFETSANE STR  MOLELEKI EXT 2  GERMISTON",
    Latitude: "-26.3807",
    Longitude: "28.1757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6641",
    WardLookupId: "858",
    VDNumber: "33060125",
    RegisteredPopulation: "2110",
    VotingStationName: "TENT - MOLELEKI EXT 1",
    Address: "ERF 3347 MOLELEKI  MOLELEKI EXT. 1  GERMISTON",
    Latitude: "-26.3664",
    Longitude: "28.1673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6642",
    WardLookupId: "858",
    VDNumber: "33060215",
    RegisteredPopulation: "3181",
    VotingStationName: "THE TRUE VINE WORSHIP CENTRE",
    Address: "KATLEHONG  GERMISTON",
    Latitude: "-26.368",
    Longitude: "28.1645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6643",
    WardLookupId: "858",
    VDNumber: "33060226",
    RegisteredPopulation: "2157",
    VotingStationName: "SIJABULILE SCHOOL",
    Address: "ERF 99, SONTONGA STREET  MOLELEKI  KATLEHONG",
    Latitude: "-26.3675",
    Longitude: "28.1633",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6644",
    WardLookupId: "858",
    VDNumber: "33061014",
    RegisteredPopulation: "1788",
    VotingStationName: "KEDITSELANA CULTURAL VILLAGE",
    Address: "10263 VENUS ROAD  VOSLOORUS EXT20  KATLEHONG",
    Latitude: "-26.3751",
    Longitude: "28.1772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6645",
    WardLookupId: "859",
    VDNumber: "33000376",
    RegisteredPopulation: "2969",
    VotingStationName: "THABOTONA PRIMARY SCHOOL 34",
    Address:
      "KLIPRIVIER & NIGEL ROAD  PALMRIDGE  EAST RAND - EKURHULENI [EAST RAND]",
    Latitude: "-26.4219",
    Longitude: "28.1609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6646",
    WardLookupId: "859",
    VDNumber: "33000477",
    RegisteredPopulation: "1982",
    VotingStationName: "PHEASANT FOLLY PRIMARY SCHOOL",
    Address: "N/A  PALMRIDGE EXT.9  GERMISTON",
    Latitude: "-26.4079",
    Longitude: "28.1396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6647",
    WardLookupId: "859",
    VDNumber: "33060024",
    RegisteredPopulation: "2529",
    VotingStationName: "APOSTOLIC GOSPEL CHURCH INTERNATIONAL",
    Address: "2989 FIRST STREET ZONE 1   ZONKIZIZWE  GERMISTON",
    Latitude: "-26.4186",
    Longitude: "28.1737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6648",
    WardLookupId: "859",
    VDNumber: "33060035",
    RegisteredPopulation: "2821",
    VotingStationName: "ZONKIZIZWE PRIMARY SCHOOL",
    Address: "ERF 1086, CNR 6TH & 18TH AVE  ZONKIZIZWE  KATLEHONG",
    Latitude: "-26.4158",
    Longitude: "28.1786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6649",
    WardLookupId: "859",
    VDNumber: "33060046",
    RegisteredPopulation: "3275",
    VotingStationName: "ZONKIZIZWE COMMUNITY HALL",
    Address: "ERF 1141, CNR 8TH & 14TH AVE  ZONKEZIZWE  KATLEHONG",
    Latitude: "-26.41",
    Longitude: "28.1786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6650",
    WardLookupId: "859",
    VDNumber: "33061070",
    RegisteredPopulation: "3688",
    VotingStationName: "TENT  AT PHASE 4 SPORTS GROUND (EXT.8)",
    Address: "NULL  PALMRIDGE  EAST RAND - EKURHULENI [EAST RAND]",
    Latitude: "-26.4112",
    Longitude: "28.1582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6651",
    WardLookupId: "860",
    VDNumber: "33020761",
    RegisteredPopulation: "209",
    VotingStationName: "TENT THULASIZWE GROUND",
    Address: "VREDEPLAAS  TAMBOEKIESFONTEIN  BOKSBURG",
    Latitude: "-26.4088",
    Longitude: "28.2186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6652",
    WardLookupId: "860",
    VDNumber: "33060057",
    RegisteredPopulation: "2057",
    VotingStationName: "FATHER MASANGO`S ST JOHN`S APOSTOLIC FAITH MISSION",
    Address: "5712 4TH AND 14 STR.ZONE 5   ZONKIZIZWE  GERMISTON",
    Latitude: "-26.4082",
    Longitude: "28.1897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6653",
    WardLookupId: "860",
    VDNumber: "33060068",
    RegisteredPopulation: "4104",
    VotingStationName: "MANZINI PRIMARY SCHOOL",
    Address: "ZONKIZISWE  KATLEHONG  GERMISTON",
    Latitude: "-26.3921",
    Longitude: "28.1934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6654",
    WardLookupId: "860",
    VDNumber: "33060923",
    RegisteredPopulation: "2123",
    VotingStationName: "MAGAGULA PRIMARY SCHOOL",
    Address: "606 KOPOANE STREET  MAGAGULA HEIGHTS  GERMISTON",
    Latitude: "-26.4208",
    Longitude: "28.1967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6655",
    WardLookupId: "860",
    VDNumber: "33060978",
    RegisteredPopulation: "2895",
    VotingStationName: "ZONKIZIZWE SECONDARY SCHOOL",
    Address: "UNKNOWN  ZONKIZIZWE  ZONKIZIZWE",
    Latitude: "-26.4052",
    Longitude: "28.1875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6656",
    WardLookupId: "860",
    VDNumber: "33061216",
    RegisteredPopulation: "453",
    VotingStationName: "TENT - CNR KGOTSO & MERCURY",
    Address: "CNR KGOTSO & MERCURY)  SUNRISE  GERMISTON",
    Latitude: "-26.3855",
    Longitude: "28.1839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6657",
    WardLookupId: "860",
    VDNumber: "33061249",
    RegisteredPopulation: "607",
    VotingStationName: "TENT - OPEN SPACE KAUNDA STREET (ZONKE)",
    Address:
      "KAUNDA STREET, ZONKIZIZWE EXT   KATLEHONG - ZONKIZIZWE  GERMISTON",
    Latitude: "-26.3934",
    Longitude: "28.1803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6658",
    WardLookupId: "861",
    VDNumber: "33060170",
    RegisteredPopulation: "2387",
    VotingStationName: "BEULAH CHRISTIANS FELLOWSHIP",
    Address: "1216 SIBUSISO STREET, SILUMA VIEW  SILUMA VIEW  GERMISTON",
    Latitude: "-26.3787",
    Longitude: "28.1536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6659",
    WardLookupId: "861",
    VDNumber: "33060181",
    RegisteredPopulation: "1991",
    VotingStationName: "AMAKHANDONIYA CHURCH OF GOD",
    Address: "ERF 1145, TSOTETSI ST  KHUMALO  KATLEHONG",
    Latitude: "-26.3777",
    Longitude: "28.1661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6660",
    WardLookupId: "861",
    VDNumber: "33060192",
    RegisteredPopulation: "2714",
    VotingStationName: "THUTOPELE SECONDARY SCHOOL.",
    Address: "1150 HLONGWANE ST  KHUMALO  KATLEHONG",
    Latitude: "-26.3772",
    Longitude: "28.1648",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6661",
    WardLookupId: "861",
    VDNumber: "33060204",
    RegisteredPopulation: "2843",
    VotingStationName: "LUNGISANE PRIMARY SCHOOL",
    Address: "ERF 1872, PHENDUKA STR  LIKOLE  KATLEHONG",
    Latitude: "-26.3731",
    Longitude: "28.1587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6662",
    WardLookupId: "861",
    VDNumber: "33060989",
    RegisteredPopulation: "2294",
    VotingStationName: "ST CANAAN APOSTOLIC FAITH MISSION CHURCH",
    Address: "909 LIKOLE SECTION KATLEHONG  KATLEHONG 2  GERMISTON",
    Latitude: "-26.3703",
    Longitude: "28.1602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6663",
    WardLookupId: "862",
    VDNumber: "33020176",
    RegisteredPopulation: "3574",
    VotingStationName: "KHULISA COMMUNITY HALL",
    Address: "112-113 PHASE 4  VOSLOORUS EXTENTION 28  BOKSBURG",
    Latitude: "-26.3776",
    Longitude: "28.2032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6664",
    WardLookupId: "862",
    VDNumber: "33020200",
    RegisteredPopulation: "3970",
    VotingStationName: "ERASMUS MONARENG SECONDARY SCHOOL",
    Address: "11801 UMKOMISO RD  VOSLOORUS X14  BOKSBURG",
    Latitude: "-26.3697",
    Longitude: "28.2193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6665",
    WardLookupId: "862",
    VDNumber: "33020211",
    RegisteredPopulation: "2314",
    VotingStationName: "DEVINE CHRISTIAN CENTRE CHURCH",
    Address: "17889 UMZUKUZA  VOSLOORUS EXT 25  BOKSBURG",
    Latitude: "-26.3652",
    Longitude: "28.2289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6666",
    WardLookupId: "862",
    VDNumber: "33020626",
    RegisteredPopulation: "3624",
    VotingStationName: "TENT (PHASE 1 EXT 28 153 EPUKIRO STREET)",
    Address:
      "CNR MOSLA/LUANDA STR.  VOSLOORUS  EAST RAND - EKURHULENI [EAST RAND]",
    Latitude: "-26.382",
    Longitude: "28.2062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6667",
    WardLookupId: "862",
    VDNumber: "33020705",
    RegisteredPopulation: "2161",
    VotingStationName: "TENT (PETER MOKABA GROUND)",
    Address: "MAHOODISA RD  VOSLOORUS   VOSLOORUS EXT28",
    Latitude: "-26.3839",
    Longitude: "28.2002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6668",
    WardLookupId: "863",
    VDNumber: "33010018",
    RegisteredPopulation: "4477",
    VotingStationName: "THEMBELIHLE PRIMARY SCHOOL",
    Address: "26252 CHRIS HANI DRIVE  EMAPHUPHENI  ETWATWA DAVEYTON",
    Latitude: "-26.106",
    Longitude: "28.487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6669",
    WardLookupId: "863",
    VDNumber: "33010029",
    RegisteredPopulation: "2524",
    VotingStationName: "TENT (EMAPHUPHENI WATER TANK)",
    Address: "PORTION OF BRESWOL AH  ETWATWA X19  ETWATWA",
    Latitude: "-26.1106",
    Longitude: "28.4866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6670",
    WardLookupId: "863",
    VDNumber: "33010030",
    RegisteredPopulation: "2873",
    VotingStationName: "PHANDIMFUNDO SECONDARY SCHOOL",
    Address: "CHRIS HANI DRIVE  ETWATWA EXT 10  ETWATWA",
    Latitude: "-26.1076",
    Longitude: "28.4799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6671",
    WardLookupId: "863",
    VDNumber: "33010052",
    RegisteredPopulation: "4119",
    VotingStationName: "TSHIPI NOTO PRIMARY SCHOOL",
    Address: "SITHOLE DRIVE  ETWATWA EXT 10  DAVEYTON",
    Latitude: "-26.1078",
    Longitude: "28.4756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6672",
    WardLookupId: "864",
    VDNumber: "33010041",
    RegisteredPopulation: "2812",
    VotingStationName: "KGOLAGANO PRIMARY SCHOOL",
    Address: "1308 ETWATWA  ETWATWA EXT 9  ETWATWA",
    Latitude: "-26.1141",
    Longitude: "28.4812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6673",
    WardLookupId: "864",
    VDNumber: "33010063",
    RegisteredPopulation: "2556",
    VotingStationName: "ROLIHLAHLA PRIMARY SCHOOL",
    Address: "STAND 9107 THULARE ST.  EMAPHUPHENI  ETWATWA",
    Latitude: "-26.1129",
    Longitude: "28.4728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6674",
    WardLookupId: "864",
    VDNumber: "33010074",
    RegisteredPopulation: "2023",
    VotingStationName: "TENT AT PHILLIP MOYO MEMORIAL HEALTH CLINIC",
    Address: "EISELEN STR  ETWATWA X9  ETWATWA",
    Latitude: "-26.1198",
    Longitude: "28.4848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6675",
    WardLookupId: "864",
    VDNumber: "33010096",
    RegisteredPopulation: "2669",
    VotingStationName: "DR. HARRY GWALA COMPREHENSIVE SCHOOL",
    Address: "BEE-EATER CRESCENT  ETWATWA EXT 9  ",
    Latitude: "-26.1168",
    Longitude: "28.4793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6676",
    WardLookupId: "864",
    VDNumber: "33010119",
    RegisteredPopulation: "2254",
    VotingStationName: "ZAMUKHANYO PRIMARY SCHOOL",
    Address: "NOBELA CRESCENT  ETWATWA  DAVEYTON",
    Latitude: "-26.123",
    Longitude: "28.487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6677",
    WardLookupId: "865",
    VDNumber: "33010085",
    RegisteredPopulation: "2826",
    VotingStationName: "CAIPHUS NYOKA HIGH SCHOOL",
    Address: "(EKU - EKURHULENI [EAST RAND])   ",
    Latitude: "-26.1244",
    Longitude: "28.4848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6678",
    WardLookupId: "865",
    VDNumber: "33010108",
    RegisteredPopulation: "2576",
    VotingStationName: "TSHEPO THEMBA",
    Address: "3812 ETWATWA EAST  DAVEYTON  BENONI",
    Latitude: "-26.1226",
    Longitude: "28.4741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6679",
    WardLookupId: "865",
    VDNumber: "33010120",
    RegisteredPopulation: "2055",
    VotingStationName: "EMMANUEL BAPTIST CHURCH",
    Address: "MNCWANE   ETWATWA EXT 8  ETWATWA",
    Latitude: "-26.1292",
    Longitude: "28.4828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6680",
    WardLookupId: "865",
    VDNumber: "33010131",
    RegisteredPopulation: "2619",
    VotingStationName: "GEORGE MBILASE SCHOOL",
    Address: "ERF 3566  ETWATWA X13  ",
    Latitude: "-26.129",
    Longitude: "28.4727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6681",
    WardLookupId: "865",
    VDNumber: "33010243",
    RegisteredPopulation: "1809",
    VotingStationName: "JS MALAZA SCHOOL",
    Address: "ETWATWA WEST  ",
    Latitude: "-26.1293",
    Longitude: "28.4675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6682",
    WardLookupId: "865",
    VDNumber: "33010254",
    RegisteredPopulation: "2416",
    VotingStationName: "BB MYATAZA SECONDARY SCHOOL",
    Address: "1354 NTSOANE STREET  ETWATWA EXT 3  DAVEYTON",
    Latitude: "-26.1248",
    Longitude: "28.467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6683",
    WardLookupId: "866",
    VDNumber: "33010265",
    RegisteredPopulation: "2329",
    VotingStationName: "MBIKWA CINDI HALL",
    Address: "CHILOANE STR  DAVEYTON  BENONI",
    Latitude: "-26.1291",
    Longitude: "28.4453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6684",
    WardLookupId: "866",
    VDNumber: "33010276",
    RegisteredPopulation: "1930",
    VotingStationName: "AFRICAN CATHOLIC CHURCH",
    Address: "13535 SEKHUKHUNI STR  DAVEYTON  ",
    Latitude: "-26.1311",
    Longitude: "28.4454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6685",
    WardLookupId: "866",
    VDNumber: "33010287",
    RegisteredPopulation: "1996",
    VotingStationName: "SIPHETHU PRIMARY SCHOOL",
    Address: "BHEKUZULU ST.  DAVEYTON  BENONI",
    Latitude: "-26.1271",
    Longitude: "28.4387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6686",
    WardLookupId: "866",
    VDNumber: "33010300",
    RegisteredPopulation: "2810",
    VotingStationName: "KATLEGO INTERMEDIATE SCHOOL",
    Address: "RATALE ST.  PUTFONTEIN  BENONI",
    Latitude: "-26.133",
    Longitude: "28.4359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6687",
    WardLookupId: "866",
    VDNumber: "33010928",
    RegisteredPopulation: "1922",
    VotingStationName: "KIDO KIDS DAY CARE",
    Address: "CRN MOCKEY AND SABATH STREET  DAVEYTON  BENONI",
    Latitude: "-26.1374",
    Longitude: "28.4368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6688",
    WardLookupId: "866",
    VDNumber: "33090465",
    RegisteredPopulation: "2734",
    VotingStationName: "GABON (TENT)",
    Address: "C187 MOCKEY STREET  GABON - MODDER EAST  SPRINGS",
    Latitude: "-26.1413",
    Longitude: "28.4431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6689",
    WardLookupId: "867",
    VDNumber: "33010322",
    RegisteredPopulation: "970",
    VotingStationName: "DAVEY SENIOR SECONDARY SCHOOL",
    Address: "MABASO STR  DAVEYTON  BENONI",
    Latitude: "-26.1376",
    Longitude: "28.4293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6690",
    WardLookupId: "867",
    VDNumber: "33010333",
    RegisteredPopulation: "1155",
    VotingStationName: "HB NYATHI SECONDARY SCHOOL",
    Address: "PAI STREET  DAVEYTON  ",
    Latitude: "-26.1379",
    Longitude: "28.4334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6691",
    WardLookupId: "867",
    VDNumber: "33010344",
    RegisteredPopulation: "2698",
    VotingStationName: "BHEKIMFUNDO PRIMARY SCHOOL",
    Address: "HELENE STR  DAVEYTON  BENONI",
    Latitude: "-26.1342",
    Longitude: "28.4298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6692",
    WardLookupId: "867",
    VDNumber: "33010366",
    RegisteredPopulation: "2850",
    VotingStationName: "KUZIMISELA PRIMARY SCHOOL",
    Address: "LANGENI STR  DAVEYTON  BENONI",
    Latitude: "-26.1402",
    Longitude: "28.4183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6693",
    WardLookupId: "867",
    VDNumber: "33010377",
    RegisteredPopulation: "1815",
    VotingStationName: "INKATHA-KA-ZULU PRIMARY SCHOOL",
    Address: "6141 MABASO STREET  DAVEYTON  BENONI",
    Latitude: "-26.1398",
    Longitude: "28.4262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6694",
    WardLookupId: "867",
    VDNumber: "33010388",
    RegisteredPopulation: "2608",
    VotingStationName: "LERUTLE PRIMARY SCHOOL",
    Address: "12533 MOTSHA STR  DAVEYTON  BENONI",
    Latitude: "-26.142",
    Longitude: "28.4315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6695",
    WardLookupId: "867",
    VDNumber: "33010399",
    RegisteredPopulation: "1558",
    VotingStationName: "SINABA STADIUM",
    Address: "EISELEN  DAVEYTON  BENONI",
    Latitude: "-26.1436",
    Longitude: "28.4238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6696",
    WardLookupId: "867",
    VDNumber: "33010401",
    RegisteredPopulation: "1472",
    VotingStationName: "ROSE MAY DABULA HOME",
    Address: "TSWANA STREET  DAVEYTON  BENONI",
    Latitude: "-26.1463",
    Longitude: "28.4275",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6697",
    WardLookupId: "868",
    VDNumber: "33010412",
    RegisteredPopulation: "2118",
    VotingStationName: "RIVONI SECONDARY SCHOOL",
    Address: "11652 PULANA STR  DAVEYTON  BENONI",
    Latitude: "-26.1507",
    Longitude: "28.4277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6698",
    WardLookupId: "868",
    VDNumber: "33010423",
    RegisteredPopulation: "1871",
    VotingStationName: "KGALEMA PRIMARY SCHOOL",
    Address: "BARWA STREET  DAVEYTON  BENONI",
    Latitude: "-26.1484",
    Longitude: "28.4194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6699",
    WardLookupId: "868",
    VDNumber: "33010434",
    RegisteredPopulation: "2329",
    VotingStationName: "BAIKAGETSE INTERMEDIATE SCHOOL",
    Address: "NARENG STR  DAVEYTON  BENONI",
    Latitude: "-26.1508",
    Longitude: "28.4234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6700",
    WardLookupId: "868",
    VDNumber: "33010456",
    RegisteredPopulation: "1747",
    VotingStationName: "THABONG PRE-PRIMARY SCHOOL",
    Address: "MAHLOBO STR  DAVEYTON  BENONI",
    Latitude: "-26.1435",
    Longitude: "28.4086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6701",
    WardLookupId: "868",
    VDNumber: "33010467",
    RegisteredPopulation: "1619",
    VotingStationName: "GUGULESIZWE PRIMARY SCHOOL",
    Address: "MPONDOMISE ST.  DAVEYTON  BENONI",
    Latitude: "-26.1433",
    Longitude: "28.4146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6702",
    WardLookupId: "868",
    VDNumber: "33010478",
    RegisteredPopulation: "1561",
    VotingStationName: "SIPHUMELELE PRIMARY SCHOOL",
    Address: "BHENGU STR  DAVEYTON  BENONI",
    Latitude: "-26.1461",
    Longitude: "28.4168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6703",
    WardLookupId: "868",
    VDNumber: "33010490",
    RegisteredPopulation: "1729",
    VotingStationName: "NTSIKANA PRIMARY SCHOOL",
    Address: "KHESWA ST.  DAVEYTON  ",
    Latitude: "-26.1474",
    Longitude: "28.4131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6704",
    WardLookupId: "868",
    VDNumber: "33010951",
    RegisteredPopulation: "579",
    VotingStationName: "LETSHA PRIMARY SCHOOL",
    Address: "CNR FOKENG & MASHENE  DAVEYTON  BENONI",
    Latitude: "-26.1458",
    Longitude: "28.4242",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6705",
    WardLookupId: "869",
    VDNumber: "33010445",
    RegisteredPopulation: "2516",
    VotingStationName: "VICTOR NDLAZILWANE HALL",
    Address: "EISELEN STR  DAVEYTON  BENONI",
    Latitude: "-26.1534",
    Longitude: "28.4179",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6706",
    WardLookupId: "869",
    VDNumber: "33010535",
    RegisteredPopulation: "1617",
    VotingStationName: "TIMKEN YOUTH CENTRE",
    Address: "MARIVATE STR  DAVEYTON  BENONI",
    Latitude: "-26.1555",
    Longitude: "28.4074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6707",
    WardLookupId: "869",
    VDNumber: "33010546",
    RegisteredPopulation: "2478",
    VotingStationName: "HULWAZI SCHOOL",
    Address: "DUMERI STR  DAVEYTON  BENONI",
    Latitude: "-26.1566",
    Longitude: "28.4137",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6708",
    WardLookupId: "869",
    VDNumber: "33010557",
    RegisteredPopulation: "1234",
    VotingStationName: "NQUBELA PRIMARY SCHOOL",
    Address: "ROKA STR  DAVEYTON  BENONI",
    Latitude: "-26.1586",
    Longitude: "28.4078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6709",
    WardLookupId: "869",
    VDNumber: "33010579",
    RegisteredPopulation: "4518",
    VotingStationName: "LINDELANI HALL",
    Address: "(EKU - EKURHULENI [EAST RAND])   ",
    Latitude: "-26.1868",
    Longitude: "28.4",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6710",
    WardLookupId: "869",
    VDNumber: "33010894",
    RegisteredPopulation: "1322",
    VotingStationName: "DAVEYTON ASSOCIATION FOR PHYSICALLY DISABLED",
    Address: "20067/8 HEALD  DAVEYTON  BENONI",
    Latitude: "-26.1623",
    Longitude: "28.4051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6711",
    WardLookupId: "869",
    VDNumber: "33010973",
    RegisteredPopulation: "488",
    VotingStationName: "MODDERBEE NEW DEVELOPMENT",
    Address: "CORNER CAMEROUN AND CHAPPAL ST  MODDERBEE  BENONI",
    Latitude: "-26.1772",
    Longitude: "28.4123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6712",
    WardLookupId: "870",
    VDNumber: "33090106",
    RegisteredPopulation: "2738",
    VotingStationName: "MORESTER PRIMARY SCHOOL",
    Address: "CNR HEXRIVIER AND ANYS STREET  EASTVALE  SPRINGS",
    Latitude: "-26.1799",
    Longitude: "28.4281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6713",
    WardLookupId: "870",
    VDNumber: "33090397",
    RegisteredPopulation: "1705",
    VotingStationName: "DR WK DU PLESSIS 2 (KLOP KLOPPIE)",
    Address: "1 NEWHOUSE  ROWHILL  SPRINGS",
    Latitude: "-26.2259",
    Longitude: "28.4287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6714",
    WardLookupId: "870",
    VDNumber: "33090409",
    RegisteredPopulation: "1647",
    VotingStationName: "JAMESON GIRLS HIGH SCHOOL",
    Address: "1 MAIN REEF ROAD  DERSLEY PARK  SPRINGS",
    Latitude: "-26.2038",
    Longitude: "28.4093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6715",
    WardLookupId: "870",
    VDNumber: "33090410",
    RegisteredPopulation: "3080",
    VotingStationName: "DIVINE PRAISE AND GRACE MINISTRIES",
    Address: "217 ENSTRA ROAD  PAYNEVILLE  SPRINGS",
    Latitude: "-26.229",
    Longitude: "28.4578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6716",
    WardLookupId: "870",
    VDNumber: "33090432",
    RegisteredPopulation: "3386",
    VotingStationName: "BAKERTON COMMUNITY HALL",
    Address: "FIRST AVENUE  BAKETON  SPRINGS",
    Latitude: "-26.2271",
    Longitude: "28.4704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6717",
    WardLookupId: "870",
    VDNumber: "33090500",
    RegisteredPopulation: "1534",
    VotingStationName: "GRACE GOSPEL CHURCH IN CHRIST",
    Address: "G1235 DINGIZULU STREET  GUGULETHU  SPRINGS",
    Latitude: "-26.2174",
    Longitude: "28.4656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6718",
    WardLookupId: "871",
    VDNumber: "33010704",
    RegisteredPopulation: "1725",
    VotingStationName: "RON HOWIE HALL",
    Address: "2 UNITY STREET  NEW MODDER  BENONI",
    Latitude: "-26.1845",
    Longitude: "28.3585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6719",
    WardLookupId: "871",
    VDNumber: "33010737",
    RegisteredPopulation: "1747",
    VotingStationName: "WILLOW MOORE HIGH SCHOOL",
    Address: "01 CECIL JACKSON ROAD  BENONI CENTRAL  BENONI",
    Latitude: "-26.1996",
    Longitude: "28.3181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6720",
    WardLookupId: "871",
    VDNumber: "33010748",
    RegisteredPopulation: "3570",
    VotingStationName: "BENONI CITY HALL",
    Address: "PRINCES AVE  BENONI",
    Latitude: "-26.1938",
    Longitude: "28.3105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6721",
    WardLookupId: "871",
    VDNumber: "33010759",
    RegisteredPopulation: "2245",
    VotingStationName: "BENONI WEST SCHOOL",
    Address: "22 RAILWAY AVE  BENONI WEST  BENONI WEST",
    Latitude: "-26.1881",
    Longitude: "28.302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6722",
    WardLookupId: "871",
    VDNumber: "33010883",
    RegisteredPopulation: "5697",
    VotingStationName: "KINGSWAY COMBINED SCHOOL",
    Address: "1079 KINGSWAY  BENONI",
    Latitude: "-26.1887",
    Longitude: "28.394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6723",
    WardLookupId: "871",
    VDNumber: "33010940",
    RegisteredPopulation: "1216",
    VotingStationName: "NEW LIFE CHRISTIAN CHURCH",
    Address: "CNR DEKAR & BUSH BUCK STR  MACKENZIE  BENONI",
    Latitude: "-26.2046",
    Longitude: "28.3334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6724",
    WardLookupId: "871",
    VDNumber: "33010984",
    RegisteredPopulation: "520",
    VotingStationName: "EMSHOLOZI BIG TREE TENT",
    Address:
      "MAIN REEFRD OPP HOUSE 0160029   EMSHOLOZI INFORMAL SETTLEMENT  BENONI",
    Latitude: "-26.2018",
    Longitude: "28.3475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6725",
    WardLookupId: "872",
    VDNumber: "33030469",
    RegisteredPopulation: "1272",
    VotingStationName: "GOSPEL TRUTH CHURCH",
    Address:
      "3RD STREET OPPOSITE HOUSE B517  PLOT 92 WITPOORT,KWA MKHANCWA  BRAKPAN",
    Latitude: "-26.2735",
    Longitude: "28.379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6726",
    WardLookupId: "872",
    VDNumber: "33090195",
    RegisteredPopulation: "2471",
    VotingStationName: "MARGARET MALTMAN CRECHE",
    Address: "2756 MORAPEDI STREET  KWA-THEMA  SPRINGS",
    Latitude: "-26.2897",
    Longitude: "28.3918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6727",
    WardLookupId: "872",
    VDNumber: "33090207",
    RegisteredPopulation: "2502",
    VotingStationName: "THEO TWALA PRIMARY SCHOOL",
    Address: "HABEDI STREET  KWA-THEMA  SPRINGS",
    Latitude: "-26.2798",
    Longitude: "28.3968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6728",
    WardLookupId: "872",
    VDNumber: "33090263",
    RegisteredPopulation: "2402",
    VotingStationName: "JEHOVAH JIREH MINISTRY",
    Address: "01 MEYER STR  WRIGHT PARK  SPRINGS",
    Latitude: "-26.2647",
    Longitude: "28.4102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6729",
    WardLookupId: "872",
    VDNumber: "33090274",
    RegisteredPopulation: "2008",
    VotingStationName: "KWA - THEMA SKILLS SCHOOL",
    Address: "1487 SAM NGEMA DRIVE  KWA THEMA  SPRINGS",
    Latitude: "-26.2901",
    Longitude: "28.4003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6730",
    WardLookupId: "872",
    VDNumber: "33090353",
    RegisteredPopulation: "1587",
    VotingStationName: "SPRINGS CIVIC CENTRE",
    Address: "SOUTH MAIN REEF & PLANTATION ROADS  SPRINGS  SPRINGS",
    Latitude: "-26.2554",
    Longitude: "28.4323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6731",
    WardLookupId: "872",
    VDNumber: "33090375",
    RegisteredPopulation: "1797",
    VotingStationName: "LAERSKOOL P.A.M BRINK",
    Address: "CNR FOURTH STREET AND SIXTH AVENUE  GEDULD  SPRINGS",
    Latitude: "-26.2482",
    Longitude: "28.4339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6732",
    WardLookupId: "872",
    VDNumber: "33090487",
    RegisteredPopulation: "1469",
    VotingStationName: "HOME-FELLOWSHIP CHURCH",
    Address: "01 CUNBERRA LANE  REEDVILLE  SPRINGS",
    Latitude: "-26.2706",
    Longitude: "28.3918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6733",
    WardLookupId: "873",
    VDNumber: "33090117",
    RegisteredPopulation: "2125",
    VotingStationName: "WELGEDAG PRIMARY SCHOOL",
    Address: "FOURTH STREET  WELGEDACHT  SPRINGS",
    Latitude: "-26.2088",
    Longitude: "28.4986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6734",
    WardLookupId: "873",
    VDNumber: "33090218",
    RegisteredPopulation: "2744",
    VotingStationName: "VUKUCINGE PRIMARY SCHOOL",
    Address: "WELGEDACHT ROAD  SLOVO PARK  SPRINGS",
    Latitude: "-26.21",
    Longitude: "28.4842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6735",
    WardLookupId: "873",
    VDNumber: "33090342",
    RegisteredPopulation: "1664",
    VotingStationName: "LAERSKOOL WERDA",
    Address: "WITWEG  LODEYKO  SPRINGS",
    Latitude: "-26.2575",
    Longitude: "28.4494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6736",
    WardLookupId: "873",
    VDNumber: "33090364",
    RegisteredPopulation: "2025",
    VotingStationName: "OLYMPIA - PARK SCHOOL",
    Address: "PLANTATION  GEDULD  SPRINGS",
    Latitude: "-26.2422",
    Longitude: "28.4327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6737",
    WardLookupId: "873",
    VDNumber: "33090386",
    RegisteredPopulation: "1917",
    VotingStationName: "DR WK DU PLESSIS",
    Address: "26 EAST GEDULD  SPRINGS",
    Latitude: "-26.2304",
    Longitude: "28.4416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6738",
    WardLookupId: "873",
    VDNumber: "33090443",
    RegisteredPopulation: "3333",
    VotingStationName: "STRUBENVALE PRIMARY SCHOOL",
    Address: "58 ATHLONE AVENUE  STRUBENVALE  SPRINGS",
    Latitude: "-26.2558",
    Longitude: "28.4709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6739",
    WardLookupId: "873",
    VDNumber: "33090498",
    RegisteredPopulation: "1327",
    VotingStationName: "PAYNEVILLE PRIMARY SCHOOL",
    Address: "1832 CNR SHAKA & GROOTVLEI STR  PAYNEVILLE  SPRINGS",
    Latitude: "-26.2348",
    Longitude: "28.4559",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6740",
    WardLookupId: "873",
    VDNumber: "33090533",
    RegisteredPopulation: "1131",
    VotingStationName: "SLOVO PARK CLINIC",
    Address: "1932 DURBAN DRIVE  SLOVO PARK PHASE 2  SPRINGS",
    Latitude: "-26.2141",
    Longitude: "28.4892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6741",
    WardLookupId: "874",
    VDNumber: "33090285",
    RegisteredPopulation: "2268",
    VotingStationName: "SPRINGS GIRLS HIGH SCHOOL",
    Address: "MOLYNEUX STREET  SELCOURT  SPRINGS",
    Latitude: "-26.3013",
    Longitude: "28.4434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6742",
    WardLookupId: "874",
    VDNumber: "33090296",
    RegisteredPopulation: "1947",
    VotingStationName: "TENT AT ST CATHRINE`S CATHOLIC CHURCH",
    Address: "CNR WANKIE & WAIHA STR  SELCOURT  SPRINGS",
    Latitude: "-26.3102",
    Longitude: "28.4512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6743",
    WardLookupId: "874",
    VDNumber: "33090308",
    RegisteredPopulation: "2934",
    VotingStationName: "LAERSKOOL SELECTION PARK",
    Address: "ALLEN ROAD  SELECTION PARK  SPRINGS",
    Latitude: "-26.2804",
    Longitude: "28.4473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6744",
    WardLookupId: "874",
    VDNumber: "33090319",
    RegisteredPopulation: "1137",
    VotingStationName: "EBM PROJECTS",
    Address: "CNR PLOVER & VOGELSTRUISBULT ROAD  STRUISBULT  SPRINGS",
    Latitude: "-26.3127",
    Longitude: "28.4717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6745",
    WardLookupId: "874",
    VDNumber: "33090320",
    RegisteredPopulation: "3637",
    VotingStationName: "EPILEPSY SOUTH AFRICA.",
    Address: "1 PETREL  DAGGFONTEIN  SPRINGS",
    Latitude: "-26.293",
    Longitude: "28.4756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6746",
    WardLookupId: "874",
    VDNumber: "33090331",
    RegisteredPopulation: "2763",
    VotingStationName: "LAERSKOOL CHRISTIAAN BEYERS",
    Address: "19 MENTZ STREET  CASSELDALE  SPRINGS",
    Latitude: "-26.2609",
    Longitude: "28.463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6747",
    WardLookupId: "875",
    VDNumber: "33090027",
    RegisteredPopulation: "2335",
    VotingStationName: "KWATHEMA PRIMARY SCHOOL",
    Address: "MARULE/SEPTEMBER  KWATHEMA  SPRINGS",
    Latitude: "-26.3121",
    Longitude: "28.4131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6748",
    WardLookupId: "875",
    VDNumber: "33090050",
    RegisteredPopulation: "1898",
    VotingStationName: "NKABINDE PRIMARY SCHOOL",
    Address: "CNR KGASWANE AND MARULE STREET  KWA-THEMA  SPRINGS",
    Latitude: "-26.3035",
    Longitude: "28.4031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6749",
    WardLookupId: "875",
    VDNumber: "33090173",
    RegisteredPopulation: "2464",
    VotingStationName: "PHULONG SECONDARY SCHOOL",
    Address: "KWA-THEMA  SPRINGS",
    Latitude: "-26.3015",
    Longitude: "28.4096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6750",
    WardLookupId: "875",
    VDNumber: "33090230",
    RegisteredPopulation: "1442",
    VotingStationName: "LABAN MOTLHABI COMPREHENSIVE SCHOOL",
    Address: "JADULA STREET  KWA THEMA  SPRINGS",
    Latitude: "-26.294",
    Longitude: "28.4003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6751",
    WardLookupId: "875",
    VDNumber: "33090241",
    RegisteredPopulation: "2603",
    VotingStationName: "EAST RAND COLLEGE",
    Address: "SAM NGEMA DRIVE  KWA-THEMA  SPRINGS",
    Latitude: "-26.2887",
    Longitude: "28.4095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6752",
    WardLookupId: "875",
    VDNumber: "33090522",
    RegisteredPopulation: "1968",
    VotingStationName: "GOD`S PLAN MINISTRIES",
    Address: "24976 THABAHADI STREET  KWA-THEMA X3  SPRINGS",
    Latitude: "-26.3105",
    Longitude: "28.4209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6753",
    WardLookupId: "876",
    VDNumber: "33090128",
    RegisteredPopulation: "2246",
    VotingStationName: "MASIMINI PRIMARY SCHOOL",
    Address: "CNR NKOSI AND MBIDLI STREET  KWA-THEMA  SPRINGS",
    Latitude: "-26.2957",
    Longitude: "28.3884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6754",
    WardLookupId: "876",
    VDNumber: "33090139",
    RegisteredPopulation: "1893",
    VotingStationName: "ITHEMBALIKAZULU SENIOR PRIMARY SCHOOL",
    Address: "KATAZA STREET  KWA-THEMA  SPRINGS",
    Latitude: "-26.2985",
    Longitude: "28.3911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6755",
    WardLookupId: "876",
    VDNumber: "33090151",
    RegisteredPopulation: "2235",
    VotingStationName: "JOB MASEKO PRIMARY SCHOOL",
    Address: "TSUPA STREET  KWA-THEMA  SPRINGS",
    Latitude: "-26.3039",
    Longitude: "28.3988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6756",
    WardLookupId: "876",
    VDNumber: "33090162",
    RegisteredPopulation: "1850",
    VotingStationName: "SAKHELWE PRIMARY SCHOOL",
    Address: "KHUMBUZA STREET  KWA-THEMA  SPRINGS",
    Latitude: "-26.297",
    Longitude: "28.3962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6757",
    WardLookupId: "876",
    VDNumber: "33090184",
    RegisteredPopulation: "1092",
    VotingStationName: "FRED HABEDI PRIMARY SCHOOL",
    Address: "1 NGWENYA STREET  KWA-THEMA  SPRINGS",
    Latitude: "-26.2919",
    Longitude: "28.3892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6758",
    WardLookupId: "876",
    VDNumber: "33090229",
    RegisteredPopulation: "2312",
    VotingStationName: "KRISTO NKOSI CATHOLIC CHURCH",
    Address: "RAPODILE STREET  KWA-THEMA  SPRINGS",
    Latitude: "-26.2907",
    Longitude: "28.3953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6759",
    WardLookupId: "877",
    VDNumber: "33030111",
    RegisteredPopulation: "2928",
    VotingStationName: "LED OFFICE (NEXT TO SANCOPAYPOINT)",
    Address: "CNR FREE STATE & EKUPEPENI ROAD  LANGAVILLE  BRAKPAN",
    Latitude: "-26.3078",
    Longitude: "28.3838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6760",
    WardLookupId: "877",
    VDNumber: "33030122",
    RegisteredPopulation: "2489",
    VotingStationName: "ITHOPIA CHURCH",
    Address: "6132 NCANE STREET  KWA_THEMA EXT 7  SPRINGS",
    Latitude: "-26.3156",
    Longitude: "28.3867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6761",
    WardLookupId: "877",
    VDNumber: "33030133",
    RegisteredPopulation: "2541",
    VotingStationName: "LANGAVILLE SECONDARY SCHOOL",
    Address: "195 NOHASHE STREET  LANGAVILLE EXT 7  BRAKPAN",
    Latitude: "-26.3145",
    Longitude: "28.3903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6762",
    WardLookupId: "877",
    VDNumber: "33090083",
    RegisteredPopulation: "2231",
    VotingStationName: "NTOKOZWENI PRIMARY SCHOOL",
    Address: "MAETANE STREET  KWA-THEMA  SPRINGS",
    Latitude: "-26.3049",
    Longitude: "28.3868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6763",
    WardLookupId: "877",
    VDNumber: "33090094",
    RegisteredPopulation: "2355",
    VotingStationName: "LUTHERAN CHURCH",
    Address: "6015 MOTSUGI STREET  KWA-THEMA  SPRINGS",
    Latitude: "-26.3027",
    Longitude: "28.3893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6764",
    WardLookupId: "877",
    VDNumber: "33090140",
    RegisteredPopulation: "985",
    VotingStationName: "KHANGEZILE PRIMARY SCHOOL",
    Address: "18450 THEMA ROAD  KWA-THEMA  SPRINGS",
    Latitude: "-26.2946",
    Longitude: "28.3839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6765",
    WardLookupId: "878",
    VDNumber: "33090016",
    RegisteredPopulation: "2741",
    VotingStationName: "EBENNEZER DAYCARE AND CRECHE",
    Address: "2186 RABORIFI STREET  KWA-THEMA EXT 2  SPRINGS",
    Latitude: "-26.3212",
    Longitude: "28.4065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6766",
    WardLookupId: "878",
    VDNumber: "33090038",
    RegisteredPopulation: "2487",
    VotingStationName: "MUZOMSHA PRIMARY SCHOOL",
    Address: "2743 RAMPELA STREET  KWA-THEMA  SPRINGS",
    Latitude: "-26.312",
    Longitude: "28.4035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6767",
    WardLookupId: "878",
    VDNumber: "33090049",
    RegisteredPopulation: "1024",
    VotingStationName: "ST MATTHEWS METHODIST CHURCH",
    Address: "27208 KOTANE STR  KWA-THEMA (REST IN PEACE)  SPRINGS",
    Latitude: "-26.3062",
    Longitude: "28.401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6768",
    WardLookupId: "878",
    VDNumber: "33090061",
    RegisteredPopulation: "2996",
    VotingStationName: "QEDUSIZI PRIMARY SCHOOL",
    Address: "CNR MAJOLA AND THEMA ROAD  KWA-THEMA  SPRINGS",
    Latitude: "-26.3087",
    Longitude: "28.3921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6769",
    WardLookupId: "878",
    VDNumber: "33090072",
    RegisteredPopulation: "2131",
    VotingStationName: "SECHABA PRIMARY SCHOOL",
    Address: "CNR TWALA AND RAKOENA STREET  KWA-THEMA  SPRINGS",
    Latitude: "-26.3056",
    Longitude: "28.3958",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6770",
    WardLookupId: "879",
    VDNumber: "33030144",
    RegisteredPopulation: "2685",
    VotingStationName: "MENZI PRIMARY SCHOOL",
    Address: "44444 4 TONGATI STREET,LANGAVILLE  LANGAVILLE EXT 4  TSAKANE",
    Latitude: "-26.3284",
    Longitude: "28.3956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6771",
    WardLookupId: "879",
    VDNumber: "33030155",
    RegisteredPopulation: "1982",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "CNR IMIBALA STR  LANGAVILLE  BRAKPAN",
    Latitude: "-26.3245",
    Longitude: "28.3809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6772",
    WardLookupId: "879",
    VDNumber: "33030166",
    RegisteredPopulation: "2180",
    VotingStationName: "NEW AFRICAN GAZA CHURCH",
    Address: "7341 MBEKI STR,EXT 5LANGAVILLE  TSAKANE  BRAKPAN",
    Latitude: "-26.3275",
    Longitude: "28.4034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6773",
    WardLookupId: "879",
    VDNumber: "33030177",
    RegisteredPopulation: "2158",
    VotingStationName: "LANGAVILLE LIBRARY",
    Address: "CNR MPANDE AVENUE  LANGAVILLE EXT 2  BRAKPAN",
    Latitude: "-26.3276",
    Longitude: "28.381",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6774",
    WardLookupId: "879",
    VDNumber: "33030188",
    RegisteredPopulation: "2791",
    VotingStationName: "LANGAVILLE PRIMARY SCHOOL",
    Address: "01  LANGAVILLE EXT 5  TSAKANE  BRAKPAN",
    Latitude: "-26.334",
    Longitude: "28.3922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6775",
    WardLookupId: "879",
    VDNumber: "33030447",
    RegisteredPopulation: "4048",
    VotingStationName: "GOSPEL IN ACTION MINISTRY",
    Address: "EXT 6 LANGAVILLE  TSAKANE  BRAKPAN",
    Latitude: "-26.3433",
    Longitude: "28.3977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6776",
    WardLookupId: "880",
    VDNumber: "33030199",
    RegisteredPopulation: "2460",
    VotingStationName: "J E MALEPE SECONDARY SCHOOL",
    Address: "1059 MODISA STR  TSAKANE  BRAKPAN",
    Latitude: "-26.3419",
    Longitude: "28.3799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6777",
    WardLookupId: "880",
    VDNumber: "33030201",
    RegisteredPopulation: "2490",
    VotingStationName: "MICHAEL ZULU SCHOOL",
    Address: "2522 SHANDU/MAGWAZA ST  TSAKANE  BRAKPAN",
    Latitude: "-26.345",
    Longitude: "28.3879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6778",
    WardLookupId: "880",
    VDNumber: "33030212",
    RegisteredPopulation: "1454",
    VotingStationName: "AGC CHURCH",
    Address: "NGOTSHA ST.  TSAKANE  BRAKPAN",
    Latitude: "-26.3483",
    Longitude: "28.3805",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6779",
    WardLookupId: "880",
    VDNumber: "33030357",
    RegisteredPopulation: "2103",
    VotingStationName: "GELUKSDAL OLD AGE HOME",
    Address: "UITTOG AVENUE  GELUKSDAL  BRAKPAN",
    Latitude: "-26.3425",
    Longitude: "28.3623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6780",
    WardLookupId: "880",
    VDNumber: "33030368",
    RegisteredPopulation: "1996",
    VotingStationName: "GELUKSDAL PRIMARY SCHOOL",
    Address: "326 HEUMANELA LAAN  GELUKSDAL  BRAKPAN",
    Latitude: "-26.3406",
    Longitude: "28.3565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6781",
    WardLookupId: "880",
    VDNumber: "33030481",
    RegisteredPopulation: "1593",
    VotingStationName: "FUNUKUKHANYA PRIMARY SCHOOL",
    Address: "01 BANANA STREET  TSAKANE  BRAKPAN",
    Latitude: "-26.3515",
    Longitude: "28.3638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6782",
    WardLookupId: "880",
    VDNumber: "33030492",
    RegisteredPopulation: "1374",
    VotingStationName: "SALVATION OF GOD FAMILY FELLOW CHURCH",
    Address: "2273 APRICOT STREET  GELUKSDAL EXT 3  BRAKPAN",
    Latitude: "-26.3236",
    Longitude: "28.3696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6783",
    WardLookupId: "881",
    VDNumber: "33030223",
    RegisteredPopulation: "2600",
    VotingStationName: "THOLULWAZI SCHOOL",
    Address: "11414 TUKETANE STREET  TSAKANE  ",
    Latitude: "-26.3492",
    Longitude: "28.392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6784",
    WardLookupId: "881",
    VDNumber: "33030234",
    RegisteredPopulation: "2035",
    VotingStationName: "SHADRACK MBAMBO PRIMARY SCHOOL",
    Address: "4139 MATHABA/NGOTSHA ST  TSAKANE  BRAKPAN",
    Latitude: "-26.3489",
    Longitude: "28.3803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6785",
    WardLookupId: "881",
    VDNumber: "33030245",
    RegisteredPopulation: "2038",
    VotingStationName: "MANGOSUTHU SCHOOL",
    Address: "871 NDAWANDWE  TSAKANE  BRAKPAN",
    Latitude: "-26.3523",
    Longitude: "28.3854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6786",
    WardLookupId: "881",
    VDNumber: "33030256",
    RegisteredPopulation: "2139",
    VotingStationName: "EMANUEL WORSHIP CENTER",
    Address: "CNR ZWANE  BRAKPAN  TSAKANE",
    Latitude: "-26.3551",
    Longitude: "28.3891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6787",
    WardLookupId: "881",
    VDNumber: "33030267",
    RegisteredPopulation: "1866",
    VotingStationName: "TSAKANE PRIMARY SCHOOL",
    Address: "1981 FINGO STREET  TSAKANE  BRAKPAN",
    Latitude: "-26.3562",
    Longitude: "28.3825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6788",
    WardLookupId: "881",
    VDNumber: "33030289",
    RegisteredPopulation: "1905",
    VotingStationName: "VUYANI SCHOOL",
    Address: "1353 TSHAMANE STR  TSAKANE  BRAKPAN",
    Latitude: "-26.3596",
    Longitude: "28.3858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6789",
    WardLookupId: "881",
    VDNumber: "33030560",
    RegisteredPopulation: "2646",
    VotingStationName: "KINGS AND PRIEST COMMUNITY CHURCH",
    Address: "42163 CNR NDLANYA AND SIPHUMELELE ST  TSAKANE XET 19  BRAKPAN",
    Latitude: "-26.3634",
    Longitude: "28.3939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6790",
    WardLookupId: "882",
    VDNumber: "33030324",
    RegisteredPopulation: "3453",
    VotingStationName: "RESHOGOFADITSWE SECONDARY SCHOOL",
    Address: "31450 LANGA STREET  TSAKANE EXTENSION 9  BRAKPAN",
    Latitude: "-26.3758",
    Longitude: "28.3808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6791",
    WardLookupId: "882",
    VDNumber: "33030335",
    RegisteredPopulation: "3342",
    VotingStationName: "TSAKANE EXT 17 TENT",
    Address: "ERF 36742  TSAKANE EXT 17  BRAKPAN",
    Latitude: "-26.3664",
    Longitude: "28.3843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6792",
    WardLookupId: "882",
    VDNumber: "33030346",
    RegisteredPopulation: "1825",
    VotingStationName: "EKUPHILENI CRECHE",
    Address: "9113 TAWANA/KGARE STREETS  TSAKANE  BRAKPAN",
    Latitude: "-26.3645",
    Longitude: "28.3773",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6793",
    WardLookupId: "882",
    VDNumber: "33030436",
    RegisteredPopulation: "1510",
    VotingStationName: "UPPER GLORY MINISTRIES",
    Address: "CNR LANGA & PHAKAMA STR  TSAKANE  BRAKPAN",
    Latitude: "-26.3715",
    Longitude: "28.3824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6794",
    WardLookupId: "882",
    VDNumber: "33030515",
    RegisteredPopulation: "1806",
    VotingStationName: "MOUNTAIN OF FREEDOM CHURCH EXT 10",
    Address: "14827 OBOSE STREET  BRAKPAN  TSAKANE",
    Latitude: "-26.3825",
    Longitude: "28.3734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6795",
    WardLookupId: "882",
    VDNumber: "33030526",
    RegisteredPopulation: "1683",
    VotingStationName: "TSAKANE EXT 10 CLINIC",
    Address: "1551/2/3 CNR NTULI & SIMELANE STREET  BRAKPAN  TSAKANE",
    Latitude: "-26.3845",
    Longitude: "28.3788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6796",
    WardLookupId: "883",
    VDNumber: "33030010",
    RegisteredPopulation: "2276",
    VotingStationName: "HOLINESS UNION CHURCH",
    Address: "CNR LEROKA STR  TSAKANE  BRAKPAN",
    Latitude: "-26.3694",
    Longitude: "28.3651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6797",
    WardLookupId: "883",
    VDNumber: "33030278",
    RegisteredPopulation: "2035",
    VotingStationName: "LETSIE PRIMARY SCHOOL",
    Address: "8856 KGAODI STREET  TSAKANE  BRAKPAN",
    Latitude: "-26.3602",
    Longitude: "28.3738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6798",
    WardLookupId: "883",
    VDNumber: "33030302",
    RegisteredPopulation: "2244",
    VotingStationName: "NCHABELENG PRIMARY SCHOOL",
    Address: "7326 MOSHOESHOE STR  TSAKANE  BRAKPAN",
    Latitude: "-26.3594",
    Longitude: "28.3779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6799",
    WardLookupId: "883",
    VDNumber: "33030313",
    RegisteredPopulation: "1488",
    VotingStationName: "TSAKANE PROGRESS CRECHE",
    Address: "8084 TSHWENI/KONI ST  TSAKANE  BRAKPAN",
    Latitude: "-26.3574",
    Longitude: "28.3736",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6800",
    WardLookupId: "883",
    VDNumber: "33030379",
    RegisteredPopulation: "3437",
    VotingStationName: "ASSEMBLIES OF GOD",
    Address: "5991 JOE SLOVO,EXT 5  TSAKANE  BRAKPAN",
    Latitude: "-26.3599",
    Longitude: "28.3637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6801",
    WardLookupId: "884",
    VDNumber: "33030537",
    RegisteredPopulation: "1660",
    VotingStationName: "TENT AT OPEN SPACE NXT TO HOUSE NO 176 (EXT 17)",
    Address: "OPEN SPACE NXT TO HOUSE NO 176  TSAKANE EXT 17   BRAKPAN",
    Latitude: "-26.3735",
    Longitude: "28.3869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6802",
    WardLookupId: "884",
    VDNumber: "33030582",
    RegisteredPopulation: "935",
    VotingStationName: "RIVER OF LIFE CHURCH",
    Address: "43070 CNR MPHO AND SIPHUMELELE  TSAKANE  BRAKPAN",
    Latitude: "-26.3686",
    Longitude: "28.3947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6803",
    WardLookupId: "884",
    VDNumber: "33080127",
    RegisteredPopulation: "3522",
    VotingStationName: "ASSER MALOKA HIGH SCHOOL",
    Address: "2544 MANDELA DRIVE,BLUEGUM VIEW   DUDUZA  NIGEL",
    Latitude: "-26.382",
    Longitude: "28.389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6804",
    WardLookupId: "884",
    VDNumber: "33080161",
    RegisteredPopulation: "1898",
    VotingStationName: "SIBONISIWE PRIMARY SCHOOL",
    Address: "5051 MOTSIELOE  DUDUZA  NIGEL",
    Latitude: "-26.3747",
    Longitude: "28.3991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6805",
    WardLookupId: "884",
    VDNumber: "33080183",
    RegisteredPopulation: "1512",
    VotingStationName: "ZAKHENI PRIMARY SCHOOL",
    Address: "1525 KHUMALO STR  DUDUZA  NIGEL",
    Latitude: "-26.3738",
    Longitude: "28.4069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6806",
    WardLookupId: "884",
    VDNumber: "33080194",
    RegisteredPopulation: "1987",
    VotingStationName: "ESIBONELWESIHLE HIGH SCHOOL",
    Address: "1689 JACOBS  DUDUZA  NIGEL",
    Latitude: "-26.371",
    Longitude: "28.4045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6807",
    WardLookupId: "884",
    VDNumber: "33080217",
    RegisteredPopulation: "1529",
    VotingStationName: "EMZIMKHULU PRIMARY SCHOOL",
    Address: "442 KHUZWAYO/MOTSHWENI  DUDUZA  NIGEL",
    Latitude: "-26.3675",
    Longitude: "28.4082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6808",
    WardLookupId: "885",
    VDNumber: "33080060",
    RegisteredPopulation: "1997",
    VotingStationName: "MASECHABAVIEW (SEAD)CLINIC",
    Address: "1742 OLEANDER  MASECHABA VIEW, DUDUZA  NIGEL",
    Latitude: "-26.3933",
    Longitude: "28.3959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6809",
    WardLookupId: "885",
    VDNumber: "33080071",
    RegisteredPopulation: "3741",
    VotingStationName: "ALL NATION BAPTIST CHURCH",
    Address: "1791 HARRY GWALA STREET,MASECHABA  DUDUZA  NIGEL",
    Latitude: "-26.3904",
    Longitude: "28.3904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6810",
    WardLookupId: "885",
    VDNumber: "33080093",
    RegisteredPopulation: "1433",
    VotingStationName: "DUDUZA MULTIPURPOSE CENTRE",
    Address: "3500 LEKOPE STREET  DUDUZA  NIGEL",
    Latitude: "-26.3878",
    Longitude: "28.4024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6811",
    WardLookupId: "885",
    VDNumber: "33080116",
    RegisteredPopulation: "2365",
    VotingStationName: "JAMES NKOSI PRIMARY SCHOOL",
    Address: "974 SISULU STREET,BLUEGUM VIEW  DUDUZA  NIGEL",
    Latitude: "-26.3816",
    Longitude: "28.3983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6812",
    WardLookupId: "885",
    VDNumber: "33080150",
    RegisteredPopulation: "2329",
    VotingStationName: "MMUSO PRIMARY SCHOOL",
    Address: "3898 LEKOPE  DUDUZA  NIGEL",
    Latitude: "-26.3808",
    Longitude: "28.4066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6813",
    WardLookupId: "885",
    VDNumber: "33080273",
    RegisteredPopulation: "1275",
    VotingStationName: "MARY-JANE CRECHE",
    Address: "1612 MAYEKISO STREET,MASECHABAVIEW  MASECHABA,DUDUZA  NEGEL",
    Latitude: "-26.3925",
    Longitude: "28.3922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6814",
    WardLookupId: "886",
    VDNumber: "33080015",
    RegisteredPopulation: "2573",
    VotingStationName: "MACKENZIEVILLE COMMUNITY HALL",
    Address: "9046/1 AHZED DRIVE  MACKENZIEVILLE  NIGEL",
    Latitude: "-26.445",
    Longitude: "28.5153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6815",
    WardLookupId: "886",
    VDNumber: "33080026",
    RegisteredPopulation: "2688",
    VotingStationName: "ALRAPARK COMMUNITY HALL",
    Address: "906 GAZEL DRIVE  ALRA PARK  NIGEL",
    Latitude: "-26.436",
    Longitude: "28.5112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6816",
    WardLookupId: "886",
    VDNumber: "33080037",
    RegisteredPopulation: "2836",
    VotingStationName: "NIGEL HIGH SCHOOL",
    Address: "YORK STREET  FERRYVALE  NIGEL",
    Latitude: "-26.4274",
    Longitude: "28.4877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6817",
    WardLookupId: "886",
    VDNumber: "33080048",
    RegisteredPopulation: "2806",
    VotingStationName: "NIGEL LIBRARY",
    Address: "HENDRIK VERWOERD STR  NIGEL  NIGEL",
    Latitude: "-26.4204",
    Longitude: "28.4747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6818",
    WardLookupId: "886",
    VDNumber: "33080059",
    RegisteredPopulation: "1507",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "26 RAMSAY STREET  NOYCEDALE  NIGEL",
    Latitude: "-26.4221",
    Longitude: "28.462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6819",
    WardLookupId: "886",
    VDNumber: "33080082",
    RegisteredPopulation: "1619",
    VotingStationName: "SHARON PARK SHOPPING COMPLEX",
    Address: "CNR  NIGEL ROAD AND MAITLAND AVENUE  SHARON PARK  NIGEL",
    Latitude: "-26.3261",
    Longitude: "28.445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6820",
    WardLookupId: "886",
    VDNumber: "33080251",
    RegisteredPopulation: "478",
    VotingStationName: "WRS MARIEVALE  TENT",
    Address: "MARIEVALE  NIGEL",
    Latitude: "-26.3473",
    Longitude: "28.4888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6821",
    WardLookupId: "886",
    VDNumber: "33100063",
    RegisteredPopulation: "1808",
    VotingStationName: "NIGEL SECONDARY SCHOOL",
    Address: "60 AHZED ST  MACKENZIEVILLE  NIGEL",
    Latitude: "-26.4464",
    Longitude: "28.5155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6822",
    WardLookupId: "887",
    VDNumber: "32890406",
    RegisteredPopulation: "2434",
    VotingStationName: "TERSIA KING LEARNING ACADEMY",
    Address: "SAMORA MACHEL STR.  HOSPITAL VIEW SECTION  TEMBISA",
    Latitude: "-25.9878",
    Longitude: "28.2398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6823",
    WardLookupId: "887",
    VDNumber: "32890619",
    RegisteredPopulation: "2615",
    VotingStationName: "AFRICAN COPTIC ORTHODOX CHURCH",
    Address: "396 TSIETSI MASHININI STREET HOSPI  HOSPITAL VIEW  TEMBISA",
    Latitude: "-25.9892",
    Longitude: "28.2433",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6824",
    WardLookupId: "887",
    VDNumber: "32890653",
    RegisteredPopulation: "2774",
    VotingStationName: "THEMBINKOSI SCHOOL FOR LSEN",
    Address: "2551 ZONE 6  WINNIE MANDELA  TEMBISA. 1632",
    Latitude: "-25.9909",
    Longitude: "28.2316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6825",
    WardLookupId: "887",
    VDNumber: "32890798",
    RegisteredPopulation: "2761",
    VotingStationName: "CARTONIA PRE SCHOOL",
    Address: "6933 BUZARD STREET  ZONE 2 WINNIE MANDELA  TEMBISA",
    Latitude: "-25.9869",
    Longitude: "28.2302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6826",
    WardLookupId: "887",
    VDNumber: "32910296",
    RegisteredPopulation: "1337",
    VotingStationName: "TENT NEXT TO PASTOR MAHLANGU CHURCH",
    Address: "01 STEENBOK AND MAJOR ROAD  CLAYVILLE EAST  OLIFANTSFONTEIN",
    Latitude: "-25.9605",
    Longitude: "28.2368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6827",
    WardLookupId: "887",
    VDNumber: "33110301",
    RegisteredPopulation: "638",
    VotingStationName: "BUSY BEE  PRIMARY SCHOOL",
    Address: "PLOT 23 KAALFONTEIN  ELANDSFONTEIN  KEMPTON PARK",
    Latitude: "-26.0047",
    Longitude: "28.3067",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6828",
    WardLookupId: "887",
    VDNumber: "33110334",
    RegisteredPopulation: "1296",
    VotingStationName: "MADIBATLOU MIDDLE SCHOOL",
    Address: "01 APOLLO ROAD  OLIFANTSFONTEIN  TEMBISA",
    Latitude: "-25.9352",
    Longitude: "28.253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6829",
    WardLookupId: "888",
    VDNumber: "32890473",
    RegisteredPopulation: "3340",
    VotingStationName: "ARMOUR FOUNDATION PRIVATE SCHOOL",
    Address: "303 CASTLE BLOCK  VUSIMUSI SECTION  TEMBISA",
    Latitude: "-26.0302",
    Longitude: "28.2093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6830",
    WardLookupId: "888",
    VDNumber: "32890642",
    RegisteredPopulation: "2560",
    VotingStationName: "MUNICIPAL BUILDING EHLANZENI HOSTEL",
    Address: "NAKURU STREET  EHLANZENI SECTION  TEMBISA 1632",
    Latitude: "-26.0294",
    Longitude: "28.1984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6831",
    WardLookupId: "888",
    VDNumber: "32890675",
    RegisteredPopulation: "3517",
    VotingStationName: "NCP HALL",
    Address: "120 LUSAKA STREET  EHLANZENI SECTION  TEMBISA",
    Latitude: "-26.0278",
    Longitude: "28.2023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6832",
    WardLookupId: "888",
    VDNumber: "32890721",
    RegisteredPopulation: "2665",
    VotingStationName: "LETHABONG CHRECHE",
    Address: "831 IGQILI STREET  VUSIMUZI SECTION  TEMBISA",
    Latitude: "-26.0338",
    Longitude: "28.211",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6833",
    WardLookupId: "888",
    VDNumber: "32890888",
    RegisteredPopulation: "1592",
    VotingStationName: "SAKISIZWE CRECH",
    Address: "01 DUMPING SITE  VUSIMUZI  TEMBISA",
    Latitude: "-26.036",
    Longitude: "28.21",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6834",
    WardLookupId: "888",
    VDNumber: "32890901",
    RegisteredPopulation: "1168",
    VotingStationName: "ROCK OF SALVATION MINISTRY",
    Address: "01 LINDELANI INFORMAL SETTLEMENT  ENHLANZENI  TEMBISA",
    Latitude: "-26.0329",
    Longitude: "28.1994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6835",
    WardLookupId: "889",
    VDNumber: "32890484",
    RegisteredPopulation: "5081",
    VotingStationName: "COEN SCHOLTZ COMMUNITY CENTRE HALL",
    Address: "MOOIFONTEIN ROAD  BIRCHLEIGH NORTH EXT 2  KEMPTON PARK",
    Latitude: "-26.052",
    Longitude: "28.2308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6836",
    WardLookupId: "889",
    VDNumber: "32890495",
    RegisteredPopulation: "3867",
    VotingStationName: "JEUGPARK LAERSKOOL",
    Address: "10 KOSI RIVER STREET  NORKEM PARK EXT 3  KEMPTON PARK",
    Latitude: "-26.0483",
    Longitude: "28.2182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6837",
    WardLookupId: "889",
    VDNumber: "32890518",
    RegisteredPopulation: "2682",
    VotingStationName: "NORKEM PARK PRIMARY SCHOOL",
    Address:
      "CNR.CABANE STREET & JAMES WRIGHT AVENUE  NORKEM PARK  KEMPTON PARK",
    Latitude: "-26.061",
    Longitude: "28.2221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6838",
    WardLookupId: "889",
    VDNumber: "32890732",
    RegisteredPopulation: "2209",
    VotingStationName: "TENT AT WEST GATE GLEN EAGLE ESTATE",
    Address: "C/O MONUMENT ROAD  GLEN MARAIS  KEMPTON PARK",
    Latitude: "-26.0735",
    Longitude: "28.2617",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6839",
    WardLookupId: "890",
    VDNumber: "33060877",
    RegisteredPopulation: "2158",
    VotingStationName: "ITHEMBELIHLE LSEN SCHOOL",
    Address: "CNR CYPRESS& ELM ROADS  PRIMROSE EAST  GERMISTON",
    Latitude: "-26.1835",
    Longitude: "28.1854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6840",
    WardLookupId: "890",
    VDNumber: "33060888",
    RegisteredPopulation: "3618",
    VotingStationName: "GOUDRIF HIGH SCHOOL",
    Address: "CNR BARBARA/KRAFT RD  MARLANDS  GERMISTON",
    Latitude: "-26.1773",
    Longitude: "28.1934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6841",
    WardLookupId: "890",
    VDNumber: "33060899",
    RegisteredPopulation: "2421",
    VotingStationName: "LAERSKOOL OOSTERKRUIN",
    Address: "492 BATAVIA STRAAT  GERDVIEW  GERMISTON",
    Latitude: "-26.1728",
    Longitude: "28.1823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6842",
    WardLookupId: "890",
    VDNumber: "33060901",
    RegisteredPopulation: "2808",
    VotingStationName: "HIGHWAY GARDENS HALL",
    Address: "PATRIDGE AVENUE  HIGHWAY GARDENS  GERMISTON",
    Latitude: "-26.1446",
    Longitude: "28.1795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6843",
    WardLookupId: "890",
    VDNumber: "33060912",
    RegisteredPopulation: "2802",
    VotingStationName: "DRIESKLOPPERS HALL",
    Address: "36 KRUIN STREET  KLOPPERPARK  GERMISTON",
    Latitude: "-26.1472",
    Longitude: "28.1975",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6844",
    WardLookupId: "890",
    VDNumber: "33061294",
    RegisteredPopulation: "1865",
    VotingStationName: "SIZWE HIGH SCHOOL",
    Address: "HATTINGH & KENNIS  ELANDSFONTEIN  KEMPTON PARK",
    Latitude: "-26.157",
    Longitude: "28.206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6845",
    WardLookupId: "891",
    VDNumber: "33020491",
    RegisteredPopulation: "3413",
    VotingStationName: "LIVING WATERS ASSEMBLY",
    Address: "CNR FISH EAGLE & BLUE CRANE ST  REIGERPARK  BOKSBURG",
    Latitude: "-26.2253",
    Longitude: "28.2119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6846",
    WardLookupId: "891",
    VDNumber: "33060754",
    RegisteredPopulation: "2740",
    VotingStationName: "GOOD HOPE PRE-SCHOOL",
    Address:
      "GOOD HOPE INFORMAL SETTLEMENT  GOOD HOPE INFORMAL SETTLEMENT-GERMISTON  GERMISTON",
    Latitude: "-26.2118",
    Longitude: "28.1864",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6847",
    WardLookupId: "891",
    VDNumber: "33060765",
    RegisteredPopulation: "4187",
    VotingStationName: "VUKUKHANYE PRE-SCHOOL",
    Address: "01 JOHN RISSIK ROAD  DELPORT  GERMISTON",
    Latitude: "-26.2003",
    Longitude: "28.16",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6848",
    WardLookupId: "891",
    VDNumber: "33060945",
    RegisteredPopulation: "4619",
    VotingStationName: "TENT - MFULA BOULEVARD (GERMISTON X9)",
    Address: "ERF 3032 MFULA BOULEVARD  GERMISTON SOUTH X9  GERMISTON",
    Latitude: "-26.2159",
    Longitude: "28.1915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6849",
    WardLookupId: "892",
    VDNumber: "33000220",
    RegisteredPopulation: "4297",
    VotingStationName: "BRACKEN PARK COMMUNITY HALL",
    Address: "HENNIE ALBERTS STREET  MAYBERRY PARK  ALBERTON",
    Latitude: "-26.3408",
    Longitude: "28.1155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6850",
    WardLookupId: "892",
    VDNumber: "33000264",
    RegisteredPopulation: "4811",
    VotingStationName: "ALBERTON FIRE STATION",
    Address: "SWARTKOPPIES RD  GENERAL ALBERTSPARK  ALBERTON",
    Latitude: "-26.2893",
    Longitude: "28.1209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6851",
    WardLookupId: "892",
    VDNumber: "33000387",
    RegisteredPopulation: "1654",
    VotingStationName: "ST FRANCIS CHURCH",
    Address: "85 HART AVENUE  MEYERSDAL  ALBERTON",
    Latitude: "-26.2993",
    Longitude: "28.097",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6852",
    WardLookupId: "892",
    VDNumber: "33000455",
    RegisteredPopulation: "2258",
    VotingStationName: "THE DAWN DAY CARE CETRE",
    Address: "3 BLOUKRANS   ALBERTSDAL  ALBERTON",
    Latitude: "-26.3497",
    Longitude: "28.1108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6853",
    WardLookupId: "892",
    VDNumber: "33061092",
    RegisteredPopulation: "1556",
    VotingStationName: "EDEN GARDEN PRE SCHOOL AND CRECHE",
    Address: "7604 EKUTHULENI  ROODEKOP EXT 31  GERMISTON",
    Latitude: "-26.2976",
    Longitude: "28.1558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6854",
    WardLookupId: "893",
    VDNumber: "33020019",
    RegisteredPopulation: "4085",
    VotingStationName: "LETHULWAZI SECONDARY SCHOOL",
    Address: "13691 IGOLOGOLO STREET   VOSLOORUS EXT6   BOKSBURG",
    Latitude: "-26.3407",
    Longitude: "28.1999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6855",
    WardLookupId: "893",
    VDNumber: "33020020",
    RegisteredPopulation: "3478",
    VotingStationName: "MAMPUDI PRIMARY SCHOOL",
    Address: "407 LESIKA STREET  EXT 5  VOSLOORUS",
    Latitude: "-26.3269",
    Longitude: "28.1945",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6856",
    WardLookupId: "893",
    VDNumber: "33020615",
    RegisteredPopulation: "4067",
    VotingStationName: "METHODIST CHURCH MAPLETON BRANCH",
    Address: "LUVUYO, LUTHANDO&FIG TREE  MAPLETON EXT10  BOKSBURG",
    Latitude: "-26.3216",
    Longitude: "28.2073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6857",
    WardLookupId: "893",
    VDNumber: "33020682",
    RegisteredPopulation: "2612",
    VotingStationName: "TENT (VACANT SITE-VOSLOO EXT6)",
    Address: "KUKUKU   VOSLOORUS EXT6   VOSLOORUS",
    Latitude: "-26.3334",
    Longitude: "28.2053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6858",
    WardLookupId: "893",
    VDNumber: "33020772",
    RegisteredPopulation: "751",
    VotingStationName: "DIVINE CHANGE MINISTRIES.",
    Address: "22 PEPPERBAK  MAPLETON EXT12  BOKSBURG",
    Latitude: "-26.3296",
    Longitude: "28.2086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6859",
    WardLookupId: "893",
    VDNumber: "33061159",
    RegisteredPopulation: "2141",
    VotingStationName: "PHUMULA GARDENS SECONDARY SCHOOL",
    Address: "UNKNOWN  ROODEKOP  ROODEKOP",
    Latitude: "-26.3182",
    Longitude: "28.2066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6860",
    WardLookupId: "894",
    VDNumber: "33010298",
    RegisteredPopulation: "3239",
    VotingStationName: "PUTFONTEIN PORTION 100 (TENT)",
    Address: "5096 POTION 100 (OPPOSITE BZ EXT 6)  PUTFONTEIN  BENONI",
    Latitude: "-26.1239",
    Longitude: "28.4354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6861",
    WardLookupId: "894",
    VDNumber: "33010311",
    RegisteredPopulation: "3103",
    VotingStationName: "PORTION 37 OF PUTFONTEIN LAPA HALL",
    Address: "MALHERBE STREET  PUTFONTEIN  BENONI",
    Latitude: "-26.131",
    Longitude: "28.4125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6862",
    WardLookupId: "894",
    VDNumber: "33010355",
    RegisteredPopulation: "4155",
    VotingStationName: "AFRICAN CHURCH",
    Address: "PTN 162 MIELIE STR  PUTFONTEIN  BENONI",
    Latitude: "-26.1259",
    Longitude: "28.4213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6863",
    WardLookupId: "894",
    VDNumber: "33010917",
    RegisteredPopulation: "3615",
    VotingStationName: "MINA NAWE OFFICE - MAYFIELD",
    Address: "PLOT 100 MIELIE/KORING  PUTFONTEIN  BENONI",
    Latitude: "-26.1236",
    Longitude: "28.4241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6864",
    WardLookupId: "895",
    VDNumber: "33030021",
    RegisteredPopulation: "2400",
    VotingStationName: "INDOOR SPORTS CENTRE",
    Address: "(EKU - EKURHULENI [EAST RAND])   ",
    Latitude: "-26.2317",
    Longitude: "28.3659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6865",
    WardLookupId: "895",
    VDNumber: "33030032",
    RegisteredPopulation: "4101",
    VotingStationName: "BRAKPAN TOWN HALL",
    Address: "KINGSWAY AVE  BRAKPAN  BRAKPAN",
    Latitude: "-26.2372",
    Longitude: "28.3659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6866",
    WardLookupId: "895",
    VDNumber: "33030043",
    RegisteredPopulation: "3273",
    VotingStationName: "HOÃ‹RSKOOL DIE ANKER",
    Address: "TWEEDY STREET  BRENTHURST  BRAKPAN",
    Latitude: "-26.2578",
    Longitude: "28.3835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6867",
    WardLookupId: "895",
    VDNumber: "33030391",
    RegisteredPopulation: "4483",
    VotingStationName: "TENT (SHERWOOD SHOPPING CENTRE)",
    Address: "CRAVENSTRAAT  BRAKPAN NOORD X3  BRAKPAN",
    Latitude: "-26.2256",
    Longitude: "28.3863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6868",
    WardLookupId: "895",
    VDNumber: "33030638",
    RegisteredPopulation: "441",
    VotingStationName: "TENT OPPOSITE JARMAN SHOP",
    Address: "LOCATION ROAD  BRAKPAN  BRAKPAN OLD LOCATION,EXT13",
    Latitude: "-26.2373",
    Longitude: "28.3923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6869",
    WardLookupId: "895",
    VDNumber: "33090511",
    RegisteredPopulation: "897",
    VotingStationName: "HUIS VAN VREDE",
    Address: "01 MARIE STREET  NEW ESTATE,FAR EAST  SPRINGS",
    Latitude: "-26.2416",
    Longitude: "28.4028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6870",
    WardLookupId: "896",
    VDNumber: "33080138",
    RegisteredPopulation: "2862",
    VotingStationName: "DAN RADEBE PRIMARY SCHOOL",
    Address: "4164 LETSAPA STREET,DUDUZA  DUDUZA  NIGEL",
    Latitude: "-26.3817",
    Longitude: "28.4145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6871",
    WardLookupId: "896",
    VDNumber: "33080149",
    RegisteredPopulation: "1976",
    VotingStationName: "NN NDEBELE PRIMARY SCHOOL",
    Address: "6970 NALA STREET  DUDUZA  NIGEL",
    Latitude: "-26.3766",
    Longitude: "28.4172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6872",
    WardLookupId: "896",
    VDNumber: "33080172",
    RegisteredPopulation: "1976",
    VotingStationName: "IPHAHAMISENG PRIMARY SCHOOL",
    Address: "4214 MAKOA STR  DUDUZA  NIGEL",
    Latitude: "-26.3776",
    Longitude: "28.4078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6873",
    WardLookupId: "896",
    VDNumber: "33080206",
    RegisteredPopulation: "1973",
    VotingStationName: "DUDUZA PRIMARY SCHOOL",
    Address: "638 MAYABA  DUDUZA  NIGEL",
    Latitude: "-26.3704",
    Longitude: "28.4103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6874",
    WardLookupId: "896",
    VDNumber: "33080240",
    RegisteredPopulation: "2456",
    VotingStationName: "TANDI ELEANOR SIBEKO SECONDARY SCHOOL",
    Address: "3252 LEKOPE STREET   MASECHABA - DUDUZA   NIGEL",
    Latitude: "-26.3931",
    Longitude: "28.3994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6875",
    WardLookupId: "896",
    VDNumber: "33080262",
    RegisteredPopulation: "768",
    VotingStationName: "UNITED APOSTOLIC FAITH CHURCH",
    Address: "5208 MOLOISE STREET  DUDUZA  SPRINGS",
    Latitude: "-26.3834",
    Longitude: "28.4183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6876",
    WardLookupId: "897",
    VDNumber: "33020301",
    RegisteredPopulation: "5816",
    VotingStationName: "WINDMILL PARK PRIMARY SCHOOL",
    Address: "752 EAST CENTRAL AVE  WINDMILL PARK  BOKSBURG",
    Latitude: "-26.2889",
    Longitude: "28.2694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6877",
    WardLookupId: "897",
    VDNumber: "33020604",
    RegisteredPopulation: "4644",
    VotingStationName: "CORNER STONE MINISTRIES CHURCH",
    Address: "DIXIE AVE/DANDELION STREETS  EXT2 VILLA LIZA  BOKSBURG",
    Latitude: "-26.3279",
    Longitude: "28.2598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6878",
    WardLookupId: "897",
    VDNumber: "33020693",
    RegisteredPopulation: "2057",
    VotingStationName: "THULASIZWE PRIMARY SCHO0L",
    Address: "752 STRANDLOPER  WINDMILL PARK  EXT 8  BOKSBURG",
    Latitude: "-26.2953",
    Longitude: "28.2573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6879",
    WardLookupId: "897",
    VDNumber: "33020817",
    RegisteredPopulation: "865",
    VotingStationName: "WINDMILL PARK ESTATE PARK",
    Address: "CNRS THONTHO AND MOSU ROADS  WINDMILL PARK  BOKSBURG",
    Latitude: "-26.3002",
    Longitude: "28.2596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6880",
    WardLookupId: "897",
    VDNumber: "33030098",
    RegisteredPopulation: "2234",
    VotingStationName: "FREEDOM IN CHRIST MINISTRIES",
    Address: "CNR RUTH FIRST & MASHONISA STR  GELUKSDAL  BRAKPAN",
    Latitude: "-26.3493",
    Longitude: "28.3579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6881",
    WardLookupId: "897",
    VDNumber: "33030571",
    RegisteredPopulation: "1922",
    VotingStationName: "TENT AT MPHO ST-METHODIST CHURCH",
    Address: "1 MPHO  VILLA LISA PHASE 1  BOKSBURG",
    Latitude: "-26.3308",
    Longitude: "28.2654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6882",
    WardLookupId: "897",
    VDNumber: "33030627",
    RegisteredPopulation: "1197",
    VotingStationName: "TENT ( TSAKANE EXT 22 )",
    Address: "TSAKANE,HEIDELBERG&KWATHEMA  TSAKANE EXT 22  BRAKPAN",
    Latitude: "-26.3819",
    Longitude: "28.3548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6883",
    WardLookupId: "898",
    VDNumber: "32890147",
    RegisteredPopulation: "2896",
    VotingStationName: "MUNICIPAL BUILDING MORITING PARK",
    Address: "00 CNR EVATON & REVONIA STREETS  MORITING  TEMBISA",
    Latitude: "-25.9954",
    Longitude: "28.2409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6884",
    WardLookupId: "898",
    VDNumber: "32890170",
    RegisteredPopulation: "4178",
    VotingStationName: "MUNICIPAL BUILDING SETHOKGA HOSTEL MAIN HALL",
    Address: "SETHOKGA HOSTEL  SETHOKGA SECTION  TEMBISA 1632",
    Latitude: "-26.004",
    Longitude: "28.2441",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6885",
    WardLookupId: "898",
    VDNumber: "32890181",
    RegisteredPopulation: "3400",
    VotingStationName: "MUNICIPAL BUILDING SETHOKGA HOSTEL PARK",
    Address: "NYARHI STREET  SETHOKGA SECTION  TEMBISA 1632",
    Latitude: "-25.9986",
    Longitude: "28.2436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6886",
    WardLookupId: "898",
    VDNumber: "32890664",
    RegisteredPopulation: "2976",
    VotingStationName: "TENT- BEHIND BLOCK 18 & 21 HOSTEL UNIT",
    Address: "IZIMBONGI STREET  EXTENSION 7 (SECTION)  TEMBISA",
    Latitude: "-26.0008",
    Longitude: "28.2466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6887",
    WardLookupId: "898",
    VDNumber: "32890776",
    RegisteredPopulation: "845",
    VotingStationName: "TEMBISA PEMS CHURCH",
    Address: "CNR 21ST MARCH & DECEMBER 26 STREE  MORITING SECTION  TEMBISA",
    Latitude: "-26.0009",
    Longitude: "28.2409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6888",
    WardLookupId: "899",
    VDNumber: "33060136",
    RegisteredPopulation: "2813",
    VotingStationName: "BAPTIST COMMUNITY  CHURCH",
    Address: "6049 CELTIS  SONWABA  EXT.5  PALMRIDGE  GERMISTON",
    Latitude: "-26.3946",
    Longitude: "28.1552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6889",
    WardLookupId: "899",
    VDNumber: "33060147",
    RegisteredPopulation: "1720",
    VotingStationName: "OLD APOSTOLIC CHURCH",
    Address: "2424   SILUMAVIEW EXT.1  GERMISTON",
    Latitude: "-26.3855",
    Longitude: "28.1666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6890",
    WardLookupId: "899",
    VDNumber: "33060169",
    RegisteredPopulation: "3408",
    VotingStationName: "CHIVIRIKANI PRIMARY SCHOOL",
    Address: "ERF 491, SONTONGA ST  SILUMA VIEW  KATLEHONG",
    Latitude: "-26.3887",
    Longitude: "28.1607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6891",
    WardLookupId: "899",
    VDNumber: "33060934",
    RegisteredPopulation: "3185",
    VotingStationName: "LIGHT OF GLORY -MINISTRY",
    Address: "11693 UMPHEFUMULO  EXT.7  GERMISTON  PALMRIDGE",
    Latitude: "-26.4006",
    Longitude: "28.1705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6892",
    WardLookupId: "899",
    VDNumber: "33061261",
    RegisteredPopulation: "2363",
    VotingStationName: "PHASE ONE PALM RIDGE (TENT)",
    Address: "N/A  PALM RIDGE PHASE ONE  GERMISTON",
    Latitude: "-26.4014",
    Longitude: "28.164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6893",
    WardLookupId: "900",
    VDNumber: "32890754",
    RegisteredPopulation: "3971",
    VotingStationName: "A MAPHETHO PRIMARY SCHOOL",
    Address: "1767 ZONE 9 WINNIE MANDELA  TEMBISA",
    Latitude: "-25.9744",
    Longitude: "28.215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6894",
    WardLookupId: "900",
    VDNumber: "32910072",
    RegisteredPopulation: "2480",
    VotingStationName: "APOSTOLIC SAVES GOSPEL CHURCH",
    Address: "ZONE 7  WINNIE MANDELA  TEMBISA",
    Latitude: "-25.9816",
    Longitude: "28.2167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6895",
    WardLookupId: "900",
    VDNumber: "32910263",
    RegisteredPopulation: "4904",
    VotingStationName: "MPUMELELO PRIMARY SCHOOL",
    Address:
      "1 AND 2 CNR NYALA AND NDLOVU STREET  TSWELOPELE  EXT  TEMBISA 1632",
    Latitude: "-25.9688",
    Longitude: "28.2103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6896",
    WardLookupId: "900",
    VDNumber: "32910319",
    RegisteredPopulation: "2468",
    VotingStationName: "MVELAPHANDA CRECHE",
    Address: "00 MVELAPHANDA STREET  TSWELOPELE  TEMBISA",
    Latitude: "-25.9715",
    Longitude: "28.2077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6897",
    WardLookupId: "900",
    VDNumber: "32910409",
    RegisteredPopulation: "2905",
    VotingStationName: "TSWELOPELE SECONDARY SCHOOL",
    Address: "3915 ALGERIA ROAD  TSELOPELE EXT  TEMBISA",
    Latitude: "-25.9657",
    Longitude: "28.2105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6898",
    WardLookupId: "900",
    VDNumber: "32910544",
    RegisteredPopulation: "1294",
    VotingStationName: "CHURCH OF JESUS CHRIST",
    Address: "2106 BUFFALO STREET TSWELOPELE  TSWELOPELE  TEMBISA",
    Latitude: "-25.9728",
    Longitude: "28.2106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6899",
    WardLookupId: "901",
    VDNumber: "33000039",
    RegisteredPopulation: "3664",
    VotingStationName: "NQUBELA PRIMARY SCHOOL",
    Address: "MOROBISI (STAND 11954)  THOKOZA X2  ALBERTON",
    Latitude: "-26.3674",
    Longitude: "28.1345",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6900",
    WardLookupId: "901",
    VDNumber: "33060158",
    RegisteredPopulation: "5507",
    VotingStationName: "PALM RIDGE COMMUNITY HALL",
    Address: "39 PALMRIDGE ROAD  PALM RIDGE  PALM RIDGE",
    Latitude: "-26.3877",
    Longitude: "28.1437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6901",
    WardLookupId: "901",
    VDNumber: "33061104",
    RegisteredPopulation: "2690",
    VotingStationName: "KWENELE REGIONAL PARK",
    Address: "PALMRIDGE ROAD  KWENELE  GERMISTON",
    Latitude: "-26.3787",
    Longitude: "28.1481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6902",
    WardLookupId: "901",
    VDNumber: "33061250",
    RegisteredPopulation: "2837",
    VotingStationName: "D SPORTS GROUND EXT.6 (TENT)",
    Address: "N/A  PALMRIDGE EXT.6  GERMISTON",
    Latitude: "-26.3972",
    Longitude: "28.1523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6903",
    WardLookupId: "902",
    VDNumber: "32890530",
    RegisteredPopulation: "4356",
    VotingStationName: "MOOIFONTEIN LAERSKOOL",
    Address: "KWIKSTERT ROAD  BIRCH ACRES  KEMPTON PARK",
    Latitude: "-26.0638",
    Longitude: "28.2082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6904",
    WardLookupId: "902",
    VDNumber: "32890541",
    RegisteredPopulation: "3908",
    VotingStationName: "WESTSIDE PRIMARY SCHOOL",
    Address: "CNR KEIR /HEUWEL STREETS  KEMPTON PARK WEST  KEMPTON PARK",
    Latitude: "-26.0842",
    Longitude: "28.1945",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6905",
    WardLookupId: "902",
    VDNumber: "32890563",
    RegisteredPopulation: "3015",
    VotingStationName: "EDLEEN PRIMARY SCHOOL",
    Address: "KLIPSPRINGER STREET  ESTHER PARK  KEMPTON PARK",
    Latitude: "-26.0958",
    Longitude: "28.1968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6906",
    WardLookupId: "902",
    VDNumber: "32890574",
    RegisteredPopulation: "4102",
    VotingStationName: "LAERSKOOL EDLEEN",
    Address: "ILEX ROAD  EDLEEN  KEMPTON PARK",
    Latitude: "-26.0933",
    Longitude: "28.2064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6907",
    WardLookupId: "902",
    VDNumber: "32890743",
    RegisteredPopulation: "795",
    VotingStationName: "NG HOOGLAND KEMPTON PARK",
    Address: "CNR ELGIN & DE WIEKUS  VAN RIEBEECKPARK  KEMPTON PARK",
    Latitude: "-26.0722",
    Longitude: "28.2079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6908",
    WardLookupId: "903",
    VDNumber: "33030054",
    RegisteredPopulation: "880",
    VotingStationName: "HOUSE OF REVIVAL CHURCH",
    Address: "85 SPRINGS ROAD  BRAKPAN  BRAKPAN",
    Latitude: "-26.2649",
    Longitude: "28.3686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6909",
    WardLookupId: "903",
    VDNumber: "33030076",
    RegisteredPopulation: "1506",
    VotingStationName: "DIE AREND PRIMARY SCHOOL",
    Address: "17 END STREET  DALVIEW  BRAKPAN",
    Latitude: "-26.2452",
    Longitude: "28.3561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6910",
    WardLookupId: "903",
    VDNumber: "33030087",
    RegisteredPopulation: "3735",
    VotingStationName: "DALVIEW PRIMARY SCHOOL",
    Address: "17 VERSTER STREET  DALVIEW  BRAKPAN",
    Latitude: "-26.2499",
    Longitude: "28.3489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6911",
    WardLookupId: "903",
    VDNumber: "33030100",
    RegisteredPopulation: "2952",
    VotingStationName: "FRONT LINE PEOPLE CHURCH",
    Address: "ERIC HOLDTMAN STR  MINNEBRON  BRAKPAN",
    Latitude: "-26.2801",
    Longitude: "28.3462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6912",
    WardLookupId: "903",
    VDNumber: "33030414",
    RegisteredPopulation: "1706",
    VotingStationName: "THANDIMFUNDO ACADEMY",
    Address: "79 FARQUHARSON STREET  SUNAIR PARK  BRAKPAN",
    Latitude: "-26.2686",
    Longitude: "28.3414",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6913",
    WardLookupId: "903",
    VDNumber: "33030504",
    RegisteredPopulation: "1784",
    VotingStationName: "TENT AT DALCREST INDOOR SPORTS CENTRE",
    Address: "ANKER STREET  DALPARK EXTENTION 1  BRAKPAN",
    Latitude: "-26.2606",
    Longitude: "28.3202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6914",
    WardLookupId: "904",
    VDNumber: "33000253",
    RegisteredPopulation: "6617",
    VotingStationName: "TENT (INFO KIOSK)",
    Address: "1 IZAK DE VILLIERS AVENUE  MEYERSDAL  ALBERTON",
    Latitude: "-26.2894",
    Longitude: "28.0955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6915",
    WardLookupId: "904",
    VDNumber: "33000286",
    RegisteredPopulation: "3721",
    VotingStationName: "ALBERTON HIGH SCHOOL",
    Address: "35 PHANTOM  RANDHART  ALBERTON",
    Latitude: "-26.2817",
    Longitude: "28.1169",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6916",
    WardLookupId: "904",
    VDNumber: "33000297",
    RegisteredPopulation: "3960",
    VotingStationName: "ALBERTON CIVIC CENTRE",
    Address: "ALWYN TALJAARD STREET  NEW REDRUTH  ALBERTON",
    Latitude: "-26.2649",
    Longitude: "28.1234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6917",
    WardLookupId: "904",
    VDNumber: "33000310",
    RegisteredPopulation: "2024",
    VotingStationName: "NG KERK SOUTHCREST",
    Address: "66 JAN MEYER STREET  SOUTHCREST  ALBERTON",
    Latitude: "-26.2545",
    Longitude: "28.103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6918",
    WardLookupId: "905",
    VDNumber: "33020031",
    RegisteredPopulation: "2336",
    VotingStationName: "NAGENG PRIMARY SCHOOL",
    Address: "3586 SEBATI STREET  VOSLOORUS  BOKSBURG",
    Latitude: "-26.3476",
    Longitude: "28.1898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6919",
    WardLookupId: "905",
    VDNumber: "33020671",
    RegisteredPopulation: "1709",
    VotingStationName: "NEW LIFE BIBLE CHRISTIAN CHURCH",
    Address: "15800 IGWALAGWALA STREET  VOSLOORUS EXT31  BOKSBURG",
    Latitude: "-26.3397",
    Longitude: "28.185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6920",
    WardLookupId: "905",
    VDNumber: "33020750",
    RegisteredPopulation: "1282",
    VotingStationName: "TSWELOPELE CLINIC",
    Address: "CNR LESEDING AND NOMBELA  PONONG  BOKSBURG",
    Latitude: "-26.327",
    Longitude: "28.187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6921",
    WardLookupId: "905",
    VDNumber: "33060518",
    RegisteredPopulation: "2330",
    VotingStationName: "SEKGUTLONG PRIMARY SCHOOL",
    Address: "ERF 9605, CR NTSOSO & NTSANE STREETS  MOTLOUNG  KATLEHONG",
    Latitude: "-26.316",
    Longitude: "28.1619",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6922",
    WardLookupId: "905",
    VDNumber: "33060619",
    RegisteredPopulation: "3020",
    VotingStationName: "SPRUITVIEW LIBRARY",
    Address: "ERF 1442, DR MATSIPA AVE  SPRUITVIEW X1  KATLEHONG",
    Latitude: "-26.3235",
    Longitude: "28.1797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6923",
    WardLookupId: "905",
    VDNumber: "33060620",
    RegisteredPopulation: "3245",
    VotingStationName: "IGAGASE PRIMARY SCHOOL",
    Address: "1 SEKETE RD  SPRUITVIEW  KATLEHONG",
    Latitude: "-26.3176",
    Longitude: "28.1786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6924",
    WardLookupId: "906",
    VDNumber: "33060079",
    RegisteredPopulation: "2585",
    VotingStationName: "KATLEHONG SOUTH SECONDARY SCHOOL",
    Address: "SA GAMA AND A THULO  KATLEHONG SOUTH  KATLEHONG",
    Latitude: "-26.3872",
    Longitude: "28.1899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6925",
    WardLookupId: "906",
    VDNumber: "33060080",
    RegisteredPopulation: "4403",
    VotingStationName: "KATLEHONG SOUTH PRIMARY SCHOOL",
    Address: "3277 CNR J NHLEKO AND NYANGA STR  KATLEHONG SOUTH  KATLEHONG",
    Latitude: "-26.3771",
    Longitude: "28.1842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6926",
    WardLookupId: "906",
    VDNumber: "33060091",
    RegisteredPopulation: "2060",
    VotingStationName: "ETHOPIA INTERNATIONAL CHURCH OF SOUTH AFRICA",
    Address:
      "2304 NEXT TO MALEFETSANE STREET  KATLEHONG SOUTH EXT20  GERMISTON",
    Latitude: "-26.3796",
    Longitude: "28.1814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6927",
    WardLookupId: "906",
    VDNumber: "33060103",
    RegisteredPopulation: "3351",
    VotingStationName: "TENT ON CNR THABILE AND MARUMO STREET",
    Address: "ERF 9298 CNR THABILE 8  MOLELEKI EXT 8  GERMISTON",
    Latitude: "-26.3857",
    Longitude: "28.1764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6928",
    WardLookupId: "906",
    VDNumber: "33061306",
    RegisteredPopulation: "846",
    VotingStationName: "HOPE CHRISTIAN CHURCH",
    Address: "8721 KGOTSO STREET  MOLELEKI EXT2  GERMISTON",
    Latitude: "-26.385",
    Longitude: "28.1803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6929",
    WardLookupId: "907",
    VDNumber: "33010186",
    RegisteredPopulation: "2443",
    VotingStationName: "WJ MPENGESI PRIMARY SCHOOL",
    Address: "IKAGENG CRESCENT  ETWATWA EXT 2  ",
    Latitude: "-26.1161",
    Longitude: "28.4617",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6930",
    WardLookupId: "907",
    VDNumber: "33010197",
    RegisteredPopulation: "1542",
    VotingStationName: "LEKAMOSO SECONDARY SCHOOL",
    Address: "GIDEON NKOMO  DAVEYTON  BENONI",
    Latitude: "-26.1126",
    Longitude: "28.4617",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6931",
    WardLookupId: "907",
    VDNumber: "33010210",
    RegisteredPopulation: "1632",
    VotingStationName: "QUANTUM SECONDARY SCHOOL",
    Address: "5733 TEBOGILE STREET  ETWATWA  BENONI",
    Latitude: "-26.1173",
    Longitude: "28.4516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6932",
    WardLookupId: "907",
    VDNumber: "33010221",
    RegisteredPopulation: "3014",
    VotingStationName: "BARCELONA PRIMARY SCHOOL",
    Address: "NGUNGUNYA ST.  ETWATWA EXT 32  BENONI",
    Latitude: "-26.1086",
    Longitude: "28.4572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6933",
    WardLookupId: "907",
    VDNumber: "33010232",
    RegisteredPopulation: "3787",
    VotingStationName: "SAZAKHELA  NEW SCHOOL",
    Address: "LONDON CITY AVE.  ETWATWA EXT 32  NEAR VERMELEUN STREET",
    Latitude: "-26.1127",
    Longitude: "28.4464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6934",
    WardLookupId: "907",
    VDNumber: "33010692",
    RegisteredPopulation: "2425",
    VotingStationName: "ACRES OF GLORY MINISTRIES",
    Address: "2562 LETSOALO STREET RAILWAY HOUSE  ETWATWA  BENONI",
    Latitude: "-26.1314",
    Longitude: "28.4553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6935",
    WardLookupId: "908",
    VDNumber: "33010489",
    RegisteredPopulation: "2100",
    VotingStationName: "BAFO CHICO PRIMARY SCHOOL",
    Address: "1465 KHAKHU  STR  DAVEYTON  BENONI",
    Latitude: "-26.1502",
    Longitude: "28.41",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6936",
    WardLookupId: "908",
    VDNumber: "33010502",
    RegisteredPopulation: "1542",
    VotingStationName: "SOZIZWE PRIMARY SCHOOL",
    Address: "PTN OF FARM DAVEYTON 73 IR  DAVEYTON  BENONI",
    Latitude: "-26.1454",
    Longitude: "28.4086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6937",
    WardLookupId: "908",
    VDNumber: "33010513",
    RegisteredPopulation: "2842",
    VotingStationName: "UNITY SECONDARY SCHOOL",
    Address: "CNR MATTEWSON & BRITZ ST.  DAVEYTON EXT 2  BENONI",
    Latitude: "-26.1498",
    Longitude: "28.3984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6938",
    WardLookupId: "908",
    VDNumber: "33010524",
    RegisteredPopulation: "2224",
    VotingStationName: "ALLIANCE CHURCH IN SA.",
    Address: "30O1 ZIBISIMI STR  DAVEYTON  DAVEYTON",
    Latitude: "-26.1496",
    Longitude: "28.4024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6939",
    WardLookupId: "908",
    VDNumber: "33010939",
    RegisteredPopulation: "5333",
    VotingStationName: "CHIEF LUTHULI HIGH SCHOOL",
    Address: "EQUATOR  CLOVERDENE  BENONI",
    Latitude: "-26.1604",
    Longitude: "28.3791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6940",
    WardLookupId: "908",
    VDNumber: "33010962",
    RegisteredPopulation: "3844",
    VotingStationName: "CHIEF LUTHULI PRIMARY SCHOOL",
    Address: "00 CLOVERDENE  BENONI",
    Latitude: "-26.1591",
    Longitude: "28.3908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6941",
    WardLookupId: "909",
    VDNumber: "33030559",
    RegisteredPopulation: "1130",
    VotingStationName: "THE METHODIST CHURCH OF SOUTHERN AFRICA",
    Address: "7698 CORNWELL STR  LANGAVILLE  TSAKANE",
    Latitude: "-26.3289",
    Longitude: "28.3982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6942",
    WardLookupId: "909",
    VDNumber: "33030616",
    RegisteredPopulation: "1244",
    VotingStationName: "HOUSE OF RESTORATION CHURCH",
    Address: "TSAKANE  BRAKPAN",
    Latitude: "-26.3544",
    Longitude: "28.3985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6943",
    WardLookupId: "909",
    VDNumber: "33080228",
    RegisteredPopulation: "3294",
    VotingStationName: "DUNNOTTAR PRIMARY SCHOOL",
    Address: "CHRISTOPHERSON  DUNNOTTAR  NIGEL",
    Latitude: "-26.3468",
    Longitude: "28.4316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6944",
    WardLookupId: "909",
    VDNumber: "33080284",
    RegisteredPopulation: "781",
    VotingStationName: "TENT AT ERF 1429 DOVE STREET",
    Address: "ERF1429 DOVE STREET  SHARON PARK EXT 2 (HLANGANANI)  NIGEL",
    Latitude: "-26.3171",
    Longitude: "28.4353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6945",
    WardLookupId: "909",
    VDNumber: "33080295",
    RegisteredPopulation: "1707",
    VotingStationName: "TENT - JOHN DUBE VILLAGE",
    Address: "OPPOSITE HOUSE 214  DUDUZA  NIGEL",
    Latitude: "-26.362",
    Longitude: "28.4232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6946",
    WardLookupId: "909",
    VDNumber: "33090252",
    RegisteredPopulation: "3152",
    VotingStationName: "SHALOM CHRISTIAN FELLOWSHIP",
    Address: "25683 CRN PHASHA & BARWA  KWA-THEMA   SPRINGS",
    Latitude: "-26.3174",
    Longitude: "28.4163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6947",
    WardLookupId: "909",
    VDNumber: "33090476",
    RegisteredPopulation: "1995",
    VotingStationName: "POWER AND GLORY OF GOD MINISTRIES",
    Address:
      "26449 NDLELA STREET CNR MASEHLA STRE  KWA-THEMA EXTENSION 3  SPRINGS",
    Latitude: "-26.3234",
    Longitude: "28.4131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6948",
    WardLookupId: "910",
    VDNumber: "33030065",
    RegisteredPopulation: "2991",
    VotingStationName: "TWELVE APOSTLE CHURCH OF AFRICA",
    Address: "768 MABUNDA STR,EXT 8  EXT 8  TSAKANE",
    Latitude: "-26.3701",
    Longitude: "28.3584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6949",
    WardLookupId: "910",
    VDNumber: "33030290",
    RegisteredPopulation: "1806",
    VotingStationName: "PHUMLANI PRIMARY SCHOOL",
    Address: "10149 RATLADI STRET  TSAKANE  BRAKPAN",
    Latitude: "-26.3735",
    Longitude: "28.3677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6950",
    WardLookupId: "910",
    VDNumber: "33030380",
    RegisteredPopulation: "2869",
    VotingStationName: "BUHLEBEMFUNDO SECONDARY SCHOOL",
    Address: "21249 TSAKANE ROAD  TSAKANE EXT 11  BRAKPAN",
    Latitude: "-26.3569",
    Longitude: "28.3569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6951",
    WardLookupId: "910",
    VDNumber: "33030425",
    RegisteredPopulation: "2438",
    VotingStationName: "EXT 8 TSAKANE PRIMARY SCHOOL",
    Address: "1 MABUNDZA STR  TSAKANE  BRAKPAN",
    Latitude: "-26.373",
    Longitude: "28.3529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6952",
    WardLookupId: "910",
    VDNumber: "33030548",
    RegisteredPopulation: "1549",
    VotingStationName: "TABERNACLE COMMUNITY CHRISTIAN CHURCH",
    Address: "CNR TSAKANE ROAD  TSAKANE  BRAKPAN",
    Latitude: "-26.3738",
    Longitude: "28.3547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6953",
    WardLookupId: "910",
    VDNumber: "33030593",
    RegisteredPopulation: "745",
    VotingStationName: "ETSHANINI PUBLIC PLACE",
    Address: "676 MOHLWARE STR,EXT8,TSAKANE  TSAKANE  BRAKPAN",
    Latitude: "-26.3765",
    Longitude: "28.3508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6954",
    WardLookupId: "910",
    VDNumber: "33030605",
    RegisteredPopulation: "1165",
    VotingStationName: "THUTHUKANISIZWE PRIMARY SCHOOL",
    Address: "CNR MOGAPE STR  TSAKANE  BRAKPAN",
    Latitude: "-26.3601",
    Longitude: "28.357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6955",
    WardLookupId: "3464",
    VDNumber: "32930063",
    RegisteredPopulation: "3906",
    VotingStationName: "HOÃ‹RSKOOL DRIE RIVIERE",
    Address: "C/O SABIE AND UMGENI STREETS  THREE RIVERS  VEREENIGING",
    Latitude: "-26.6581",
    Longitude: "27.9741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6956",
    WardLookupId: "3464",
    VDNumber: "32930614",
    RegisteredPopulation: "3741",
    VotingStationName: "RIVERSIDE HIGH SCHOOL",
    Address: "HAWTHORN AVENUE  THREE RIVERS EAST  VEREENIGING",
    Latitude: "-26.6573",
    Longitude: "27.9979",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6957",
    WardLookupId: "3464",
    VDNumber: "32931020",
    RegisteredPopulation: "1025",
    VotingStationName: "LAERSKOOL SUIKERBOS",
    Address: "FALCON  THREE RIVERS EAST  VEREENIGING",
    Latitude: "-26.6603",
    Longitude: "28.0064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6958",
    WardLookupId: "3465",
    VDNumber: "32940211",
    RegisteredPopulation: "1228",
    VotingStationName: "PHEHELLO PRIMARY SCHOOL",
    Address: "20455 ZONE 14  SEBOKENG, ZONE 14  SEBOKENG",
    Latitude: "-26.5919",
    Longitude: "27.8393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6959",
    WardLookupId: "3465",
    VDNumber: "32940255",
    RegisteredPopulation: "3299",
    VotingStationName: "MOSHATE SCHOOL",
    Address: "62079 ZONE 17  SEBOKENG, ZONE 17  SEBOKENG",
    Latitude: "-26.5968",
    Longitude: "27.8349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6960",
    WardLookupId: "3465",
    VDNumber: "32940682",
    RegisteredPopulation: "1650",
    VotingStationName: "LIVING  DISCIPLES OF CHRIST CHURCH",
    Address: "NONE  SEBOKENG ZONE 20  SEBOKENG",
    Latitude: "-26.593",
    Longitude: "27.8243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6961",
    WardLookupId: "3465",
    VDNumber: "32940828",
    RegisteredPopulation: "2569",
    VotingStationName: "ZWELITSHA HALL",
    Address: "HOSTEL 3 BLOCK F  MOSHOESHOE STREET  SEBOKENG",
    Latitude: "-26.5985",
    Longitude: "27.8427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6962",
    WardLookupId: "3466",
    VDNumber: "32940345",
    RegisteredPopulation: "2631",
    VotingStationName: "APOSTOLIC FAITH MISSION BOIPATONG",
    Address: "1976 MALEBOGO STR  BOIPATONG  BOIPATONG",
    Latitude: "-26.6749",
    Longitude: "27.8465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6963",
    WardLookupId: "3466",
    VDNumber: "32940356",
    RegisteredPopulation: "2299",
    VotingStationName: "PHUTHULLA PRIMARY SCHOOL",
    Address: "1988 MAMELODI STR  BOIPATONG  BOIPATONG",
    Latitude: "-26.6734",
    Longitude: "27.849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6964",
    WardLookupId: "3466",
    VDNumber: "32940378",
    RegisteredPopulation: "3466",
    VotingStationName: "LAERSKOOL OOSPARK",
    Address: "CUBITT-BENZ STR  CE 3  VANDERBIJLPARK",
    Latitude: "-26.6908",
    Longitude: "27.8442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6965",
    WardLookupId: "3467",
    VDNumber: "32930131",
    RegisteredPopulation: "2982",
    VotingStationName: "AGS NUWE BEGIN BEDWORTHPARK",
    Address: "CENTAUR ROAD & FORTUNA AVENUE  BEDWORTHPARK  BEDWORTHPARK",
    Latitude: "-26.7079",
    Longitude: "27.8804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6966",
    WardLookupId: "3467",
    VDNumber: "32940479",
    RegisteredPopulation: "3168",
    VotingStationName: "LAERSKOOL EMFULENIPARK",
    Address: "FUR & OLIENHOUT ST  SE3  VANDERBIJLPARK",
    Latitude: "-26.736",
    Longitude: "27.8487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6967",
    WardLookupId: "3467",
    VDNumber: "32940895",
    RegisteredPopulation: "2171",
    VotingStationName: "PARK RIDGE PRIMARY SCHOOL",
    Address:
      "CORNER OF BEZUIDENHOUT AND WOLHUTER STREETS  VANDERBIJLPARK   SE 6",
    Latitude: "-26.7213",
    Longitude: "27.8582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6968",
    WardLookupId: "3467",
    VDNumber: "32940941",
    RegisteredPopulation: "1179",
    VotingStationName: "CURRO VANDERBIJLPARK",
    Address: "1174 HENDRIK VAN ECK BOULEVARD  VANDERBIJLPARK  VANDERBIJLPARK",
    Latitude: "-26.7208",
    Longitude: "27.8827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6969",
    WardLookupId: "3468",
    VDNumber: "32940435",
    RegisteredPopulation: "2664",
    VotingStationName: "HOÃ‹RSKOOL DRIEHOEK",
    Address: "RAMSBOTTEM STR  SE2  VANDERBIJLPARK",
    Latitude: "-26.7236",
    Longitude: "27.8455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6970",
    WardLookupId: "3468",
    VDNumber: "32940457",
    RegisteredPopulation: "2787",
    VotingStationName: "DF MALHERBE LAERSKOOL",
    Address: "SULLIVAN & DEVORAK  SW5  VANDERBIJLPARK",
    Latitude: "-26.73",
    Longitude: "27.8338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6971",
    WardLookupId: "3468",
    VDNumber: "32940503",
    RegisteredPopulation: "1516",
    VotingStationName: "VANDERBIJLPARK POSDUIFKLUB",
    Address: "65 VAAL DRIVE  VANDERBIJLPARK  MANTEVREDE",
    Latitude: "-26.7367",
    Longitude: "27.819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6972",
    WardLookupId: "3468",
    VDNumber: "32940907",
    RegisteredPopulation: "1607",
    VotingStationName: "EMFULENI CONFERENCE AND SPORT CENTRE",
    Address: "EMFULENI DRIVE  SW 5  VANDERBIJLPARK",
    Latitude: "-26.7407",
    Longitude: "27.8428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6973",
    WardLookupId: "3469",
    VDNumber: "32940716",
    RegisteredPopulation: "3464",
    VotingStationName: "IKOKOBETSENG PRIMARY SCHOOL",
    Address: "3274 EXT 6  BOPHELONG  VANDERBIJLPARK",
    Latitude: "-26.6873",
    Longitude: "27.7896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6974",
    WardLookupId: "3469",
    VDNumber: "32940727",
    RegisteredPopulation: "3656",
    VotingStationName: "BOPHELONG NEW SECONDARY SCHOOL",
    Address: "4893 THOMAS NKOBI STREET  BOPHELONG  VANDERBIJLPARK",
    Latitude: "-26.6834",
    Longitude: "27.7866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6975",
    WardLookupId: "3469",
    VDNumber: "32940952",
    RegisteredPopulation: "1832",
    VotingStationName: "BOPHELONG BAPTIST CHURCH",
    Address: "EXT 13  BOPHELONG  BOPHELONG",
    Latitude: "-26.6843",
    Longitude: "27.7819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6976",
    WardLookupId: "3470",
    VDNumber: "32940491",
    RegisteredPopulation: "1794",
    VotingStationName: "LAERSKOOL VAALRIVIER",
    Address: "NANCSCOL 85  ",
    Latitude: "-26.7193",
    Longitude: "27.7533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6977",
    WardLookupId: "3470",
    VDNumber: "32940525",
    RegisteredPopulation: "1808",
    VotingStationName: "MQINISWA PRIMARY SCHOOL",
    Address: "777 XUMA STREET  BOPHELONG  VANDERBIJLPARK",
    Latitude: "-26.694",
    Longitude: "27.7926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6978",
    WardLookupId: "3470",
    VDNumber: "32940536",
    RegisteredPopulation: "1336",
    VotingStationName: "SEEISO PRIMARY SCHOOL",
    Address: "114 MOFOLO STREET  BOPHELONG  VANDERBIJLPARK",
    Latitude: "-26.6973",
    Longitude: "27.7962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6979",
    WardLookupId: "3470",
    VDNumber: "32940705",
    RegisteredPopulation: "4017",
    VotingStationName: "SAPPHIRE SECONDARY SCHOOL",
    Address: "2071 EXT 4  BOPHELONG  VANDERBIJLPARK",
    Latitude: "-26.6954",
    Longitude: "27.7862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6980",
    WardLookupId: "3470",
    VDNumber: "32940974",
    RegisteredPopulation: "103",
    VotingStationName: "DINOKENG PRIMARY SCHOOL",
    Address: "581 MARLBANK ROAD  DRIEFONTEIN  VANDERBIJLPARK",
    Latitude: "-26.7716",
    Longitude: "27.7606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6981",
    WardLookupId: "3471",
    VDNumber: "32940323",
    RegisteredPopulation: "2838",
    VotingStationName: "JET NTEO HIGH SCHOOL",
    Address: "3001 UMZIMVUMBU STREET  BOIPATONG  BOIPATONG",
    Latitude: "-26.6634",
    Longitude: "27.8459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6982",
    WardLookupId: "3471",
    VDNumber: "32940334",
    RegisteredPopulation: "2227",
    VotingStationName: "MAKAPANE PRIMARY SCHOOL",
    Address: "1232 MOSHOESHOE STREET  BOIPATONG  BOIPATONG",
    Latitude: "-26.6695",
    Longitude: "27.8519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6983",
    WardLookupId: "3471",
    VDNumber: "32940367",
    RegisteredPopulation: "2407",
    VotingStationName: "NOORDHOEK LAERSKOOL",
    Address: "EC JANSEN STREET  CW4  VANDERBIJLPARK",
    Latitude: "-26.6802",
    Longitude: "27.8312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6984",
    WardLookupId: "3472",
    VDNumber: "32940424",
    RegisteredPopulation: "2379",
    VotingStationName: "PRESTIGIOUS AUREATE HIGH SCHOOL",
    Address: "7 MERRIMAN STR  SE1  VANDERBIJLPARK",
    Latitude: "-26.7164",
    Longitude: "27.8399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6985",
    WardLookupId: "3472",
    VDNumber: "32940446",
    RegisteredPopulation: "3606",
    VotingStationName: "LAERSKOOL KOLLEGEPARK",
    Address: "77 GENL FRONEMAN  SE7  VANDERBIJLPARK",
    Latitude: "-26.7163",
    Longitude: "27.8531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6986",
    WardLookupId: "3472",
    VDNumber: "32940514",
    RegisteredPopulation: "3013",
    VotingStationName: "LAERSKOOL ELIGWA",
    Address: "C/O LION CASHET & CONAN DOYLE  SW1  VANDERBIJLPARK",
    Latitude: "-26.7145",
    Longitude: "27.8282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6987",
    WardLookupId: "3473",
    VDNumber: "32940389",
    RegisteredPopulation: "3670",
    VotingStationName: "TECHNICAL HIGH SCHOOL CAREL DE WET",
    Address: "46 JAN VAN RIEBEECK BOULEVARD  CE5  VANDERBIJLPARK",
    Latitude: "-26.7042",
    Longitude: "27.8407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6988",
    WardLookupId: "3473",
    VDNumber: "32940390",
    RegisteredPopulation: "2297",
    VotingStationName: "OLIVER LODGE PRIMARY SCHOOL",
    Address: "LODGE STR  CW2  VANDERBIJLPARK",
    Latitude: "-26.6941",
    Longitude: "27.8284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6989",
    WardLookupId: "3473",
    VDNumber: "32940413",
    RegisteredPopulation: "3046",
    VotingStationName: "LAERSKOOL TOTIUS",
    Address: "CULCREST STR  CW6  ",
    Latitude: "-26.6955",
    Longitude: "27.8166",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6990",
    WardLookupId: "3474",
    VDNumber: "32930074",
    RegisteredPopulation: "1687",
    VotingStationName: "ISIZWE SETJHABA SECONDARY SCHOOL",
    Address: "ANTON STEGMAN STREET  ROODSTUINE  VEREENIGING",
    Latitude: "-26.652",
    Longitude: "27.8914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6991",
    WardLookupId: "3474",
    VDNumber: "32940604",
    RegisteredPopulation: "4090",
    VotingStationName: "IKEMELENG ELDERLY SERVICE CENTRE",
    Address: "CNR MAIN ROAD PHASE 3  TSHEPISO  2632",
    Latitude: "-26.6672",
    Longitude: "27.8671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6992",
    WardLookupId: "3474",
    VDNumber: "32940918",
    RegisteredPopulation: "1828",
    VotingStationName: "MAHARENG SECONDARY SCHOOL",
    Address: "BOY LOUW ROAD  TSHEPONG  TSHEPONG",
    Latitude: "-26.6519",
    Longitude: "27.8763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6993",
    WardLookupId: "3474",
    VDNumber: "32940963",
    RegisteredPopulation: "1305",
    VotingStationName: "TENT TSHEPISO EXT 4 & 5 OFF HOUTKOP ROAD",
    Address: "OFF HOUTKOP ROAD  TSHEPISO EXT 4  VEREENIGING",
    Latitude: "-26.6431",
    Longitude: "27.8689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6994",
    WardLookupId: "3475",
    VDNumber: "32930625",
    RegisteredPopulation: "2016",
    VotingStationName: "EMMANUEL PRIMARY SCHOOL",
    Address: "6280A RAMOKHOASE STREET  SHARPEVILLE  SHARPVILLE",
    Latitude: "-26.6759",
    Longitude: "27.8834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6995",
    WardLookupId: "3475",
    VDNumber: "32930692",
    RegisteredPopulation: "1411",
    VotingStationName: "LEBOWA PRIMARY SCHOOL",
    Address: "8869 HULWANA STREET  SHARPEVILLE  ",
    Latitude: "-26.6884",
    Longitude: "27.8801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6996",
    WardLookupId: "3475",
    VDNumber: "32930715",
    RegisteredPopulation: "1991",
    VotingStationName: "SELIBA PRIMARY SCHOOL",
    Address: "1997B SEEISO STREET  SHARPEVILLE  ",
    Latitude: "-26.6831",
    Longitude: "27.8796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6997",
    WardLookupId: "3475",
    VDNumber: "32930726",
    RegisteredPopulation: "1151",
    VotingStationName: "CHURCH OF ETHOPIAN",
    Address: "SHARPEVILLE  SHARPVILLE  SHARPEVILLE",
    Latitude: "-26.6759",
    Longitude: "27.8878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6998",
    WardLookupId: "3475",
    VDNumber: "32931097",
    RegisteredPopulation: "264",
    VotingStationName: "BETHSIDA WORD CENTRE",
    Address: "KOPANO STREET  POWERVILLE  VEREENIGING",
    Latitude: "-26.6983",
    Longitude: "27.8782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "6999",
    WardLookupId: "3476",
    VDNumber: "32930580",
    RegisteredPopulation: "1305",
    VotingStationName: "ASSEMBLIES OF GOD CHURCH",
    Address: "5241 PUMULO SECTION  SHARPEVILLE  SHARPVILLE",
    Latitude: "-26.6779",
    Longitude: "27.8801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7000",
    WardLookupId: "3476",
    VDNumber: "32930670",
    RegisteredPopulation: "1282",
    VotingStationName: "MOHLOLI SECONDARY SCHOOL",
    Address: "8000 ZWANE STEET  SHARPEVILLE  ",
    Latitude: "-26.6915",
    Longitude: "27.8784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7001",
    WardLookupId: "3476",
    VDNumber: "32930681",
    RegisteredPopulation: "1457",
    VotingStationName: "METHODIST CHURCH IN AFRICA",
    Address: "7065 KHABASHEANE STREET  SHARPEVILLE  ",
    Latitude: "-26.6869",
    Longitude: "27.8758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7002",
    WardLookupId: "3476",
    VDNumber: "32930704",
    RegisteredPopulation: "1828",
    VotingStationName: "KGOMOCO SCHOOL",
    Address: "4400 MAREKA STREET  SHARPEVILLE  SHARPVILLE",
    Latitude: "-26.685",
    Longitude: "27.8674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7003",
    WardLookupId: "3476",
    VDNumber: "32930928",
    RegisteredPopulation: "1110",
    VotingStationName: "MATSIE STEYN PRIMARY SCHOOL",
    Address: "SEEISO STREET  SHARPEVILLE  VANDERBIJLPARK",
    Latitude: "-26.6824",
    Longitude: "27.8782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7004",
    WardLookupId: "3477",
    VDNumber: "32930636",
    RegisteredPopulation: "1876",
    VotingStationName: "LEKOA SHANDU HIGH SCHOOL",
    Address: "E13 DUBULA DRIVE  SHARPEVILLE  SHARPVILLE",
    Latitude: "-26.6928",
    Longitude: "27.8676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7005",
    WardLookupId: "3477",
    VDNumber: "32930647",
    RegisteredPopulation: "1425",
    VotingStationName: "LEHLASEDI PRIMARY SCHOOL",
    Address: "1711 LEHOKO STREET  SHARPEVILLE  ",
    Latitude: "-26.6966",
    Longitude: "27.8637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7006",
    WardLookupId: "3477",
    VDNumber: "32930658",
    RegisteredPopulation: "1533",
    VotingStationName: "VUKUZAKHE PRIMARY SCHOOL",
    Address: "1721 BEN PITSI DRIVE  SHARPEVILLE  ",
    Latitude: "-26.6958",
    Longitude: "27.868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7007",
    WardLookupId: "3477",
    VDNumber: "32930669",
    RegisteredPopulation: "1849",
    VotingStationName: "VUYO INTERMEDIATE SCHOOL",
    Address: "E25 DUBULA DRIVE  SHARPEVILLE  SHARPVILLE",
    Latitude: "-26.6973",
    Longitude: "27.8748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7008",
    WardLookupId: "3478",
    VDNumber: "32930030",
    RegisteredPopulation: "2938",
    VotingStationName: "VEREENIGING GIMNASIUM",
    Address: "DE VILLIERS LAAN  VEREENIGING  VEREENIGING",
    Latitude: "-26.665",
    Longitude: "27.924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7009",
    WardLookupId: "3478",
    VDNumber: "32930052",
    RegisteredPopulation: "4155",
    VotingStationName: "VEREENIGING TOWN HALL",
    Address: "JOUBERT  VEREENIGING CENTRAL  VEREENIGING",
    Latitude: "-26.6751",
    Longitude: "27.928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7010",
    WardLookupId: "3478",
    VDNumber: "32930119",
    RegisteredPopulation: "1514",
    VotingStationName: "MBALI KA MUZI (PEACEHAVEN)",
    Address: "DEE STR  VEREENIGING  VEREENIGING",
    Latitude: "-26.6633",
    Longitude: "27.9482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7011",
    WardLookupId: "3479",
    VDNumber: "32930018",
    RegisteredPopulation: "4538",
    VotingStationName: "ARCONPARK PRIMARY SCHOOL",
    Address: "C/O MINT & LEE AVENUE  ARCON PARK  VEREENIGING",
    Latitude: "-26.6307",
    Longitude: "27.9427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7012",
    WardLookupId: "3479",
    VDNumber: "32930085",
    RegisteredPopulation: "3406",
    VotingStationName: "RUST TER VAAL COMMUNITY CENTRE",
    Address: "19 KIEPERSOL STREET  RUST TER VAAL CIVIC CENTRE  VEREENIGING",
    Latitude: "-26.574",
    Longitude: "27.9489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7013",
    WardLookupId: "3480",
    VDNumber: "32940277",
    RegisteredPopulation: "3053",
    VotingStationName: "RECREATION HALL (SEBOKENG HOSTEL 2)",
    Address: "SEBOKENG HOSTEL 2  BLOCK 2 MOSHOESHOE STR  SEBOKENG",
    Latitude: "-26.601",
    Longitude: "27.8397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7014",
    WardLookupId: "3480",
    VDNumber: "32940693",
    RegisteredPopulation: "1375",
    VotingStationName: "KWA MASIZA",
    Address: "SEBOKENG HOSTELS  ",
    Latitude: "-26.5982",
    Longitude: "27.827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7015",
    WardLookupId: "3480",
    VDNumber: "32940783",
    RegisteredPopulation: "738",
    VotingStationName: "MASOHENG/PEACE HEIGHTS",
    Address: "SEBOKENG  MASOHENG",
    Latitude: "-26.5938",
    Longitude: "27.8463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7016",
    WardLookupId: "3480",
    VDNumber: "32940817",
    RegisteredPopulation: "887",
    VotingStationName: "TENT ZONE 20 EXT 2 OPEN FIELD",
    Address: "NONE  VANDERBIJLPARK  VANDERBIJLPARK",
    Latitude: "-26.6004",
    Longitude: "27.8204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7017",
    WardLookupId: "3480",
    VDNumber: "33330057",
    RegisteredPopulation: "2282",
    VotingStationName: "KINGDOM RESTORATION  CENTRE",
    Address: "MPASHE  GOLDEN GARDENS  SEBOKENG",
    Latitude: "-26.614",
    Longitude: "27.8086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7018",
    WardLookupId: "3481",
    VDNumber: "32930388",
    RegisteredPopulation: "2157",
    VotingStationName: "MOJALATHUTO SCHOOL",
    Address: "448-3 CNR SELBOURNE & HEATH ROAD  SMALL FARMS  EVATON",
    Latitude: "-26.5457",
    Longitude: "27.8418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7019",
    WardLookupId: "3481",
    VDNumber: "32930850",
    RegisteredPopulation: "828",
    VotingStationName: "ST JOHN CHURCH EVATON",
    Address: "10-61 SMALL FARM  EVATON  ",
    Latitude: "-26.5559",
    Longitude: "27.8426",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7020",
    WardLookupId: "3481",
    VDNumber: "32940288",
    RegisteredPopulation: "2351",
    VotingStationName: "TSWELOPELE DAY CARE CENTRE",
    Address: "NONE  EXT. 2  KANANA",
    Latitude: "-26.5529",
    Longitude: "27.834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7021",
    WardLookupId: "3481",
    VDNumber: "32940851",
    RegisteredPopulation: "2290",
    VotingStationName: "TSWELOPELE CRECHE",
    Address: "RABOROKO  KANANA  KANANA",
    Latitude: "-26.5592",
    Longitude: "27.8345",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7022",
    WardLookupId: "3482",
    VDNumber: "32930197",
    RegisteredPopulation: "3353",
    VotingStationName: "QEDILIZWE SECONDARY SCHOOL",
    Address: "2250 EVATON NORTH  EVATON  ",
    Latitude: "-26.5133",
    Longitude: "27.8591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7023",
    WardLookupId: "3482",
    VDNumber: "32930209",
    RegisteredPopulation: "1954",
    VotingStationName: "PHEPANE INTERMEDIATE SCHOOL",
    Address: "1070/1/2 CRADOCK ROAD  EVATON  ",
    Latitude: "-26.5183",
    Longitude: "27.8498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7024",
    WardLookupId: "3482",
    VDNumber: "32930243",
    RegisteredPopulation: "1212",
    VotingStationName: "COUSIN SENATLE CONVENTION CENTRE",
    Address: "197 CORNER BODEA&BUFFALO ROAD  EVATON  EVATON",
    Latitude: "-26.5229",
    Longitude: "27.8612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7025",
    WardLookupId: "3482",
    VDNumber: "32931019",
    RegisteredPopulation: "1002",
    VotingStationName: "RUTASETJHABA  SCHOOL",
    Address: "0 MOOI  EVATON NORTH  EVATON",
    Latitude: "-26.5128",
    Longitude: "27.8512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7026",
    WardLookupId: "3483",
    VDNumber: "32930153",
    RegisteredPopulation: "2413",
    VotingStationName: "THARABOLLO SCHOOL",
    Address: "2065 PALM STREET  EVATON  VEREENIGING",
    Latitude: "-26.4973",
    Longitude: "27.8408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7027",
    WardLookupId: "3483",
    VDNumber: "32930164",
    RegisteredPopulation: "1850",
    VotingStationName: "MOLOANTOA PRIMARY SCHOOL BRANCH",
    Address: "PALM SPRINGS  VEREENIGING",
    Latitude: "-26.4983",
    Longitude: "27.8342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7028",
    WardLookupId: "3483",
    VDNumber: "32930175",
    RegisteredPopulation: "2885",
    VotingStationName: "DMT (BAFOKENG) SCHOOL",
    Address: "599 SECTION A  PALMSPRINGS  VEREENIGING",
    Latitude: "-26.5017",
    Longitude: "27.8483",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7029",
    WardLookupId: "3484",
    VDNumber: "32930041",
    RegisteredPopulation: "3176",
    VotingStationName: "ROSHNEE CIVIC CENTRE",
    Address: "17 BILAL DRIVE  ROSHNEE  VEREENIGING",
    Latitude: "-26.5646",
    Longitude: "27.9418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7030",
    WardLookupId: "3484",
    VDNumber: "32930108",
    RegisteredPopulation: "3173",
    VotingStationName: "TENT MILLENIUM SQUARE - ST PATRICK ROAD",
    Address: "525 ST PATRICK  DEBONAIR PARK  VEREENIGING",
    Latitude: "-26.524",
    Longitude: "27.8967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7031",
    WardLookupId: "3484",
    VDNumber: "32930995",
    RegisteredPopulation: "3927",
    VotingStationName: "TSHEPONG EXT 1 PRIMARY SCHOOL",
    Address: "PHASE 1  TSHEPONG  VEREENIGING",
    Latitude: "-26.5448",
    Longitude: "27.8982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7032",
    WardLookupId: "3485",
    VDNumber: "32940581",
    RegisteredPopulation: "2634",
    VotingStationName: "TSHEPISO CLINIC PHASE 2",
    Address: "STAND 1986  EXT 2  TSHEPISO",
    Latitude: "-26.6741",
    Longitude: "27.8682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7033",
    WardLookupId: "3485",
    VDNumber: "32940592",
    RegisteredPopulation: "2198",
    VotingStationName: "TSOARANANG PRIMARY SCHOOL",
    Address: "2043 PHASE 2  TSHEPISO  TSHEPISO",
    Latitude: "-26.6726",
    Longitude: "27.8751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7034",
    WardLookupId: "3485",
    VDNumber: "32940772",
    RegisteredPopulation: "3575",
    VotingStationName: "TENT TSHEPISO (NEXT TO TAXI RANK)",
    Address: "BAHLOKI  TSHEPISO  TSHEPISO",
    Latitude: "-26.6747",
    Longitude: "27.8599",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7035",
    WardLookupId: "3486",
    VDNumber: "32940019",
    RegisteredPopulation: "1565",
    VotingStationName: "BOPHELONG COMMUNITY HALL",
    Address: "STAND 1158  BOPHELONG PROPER  BOPHELONG",
    Latitude: "-26.6939",
    Longitude: "27.7984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7036",
    WardLookupId: "3486",
    VDNumber: "32940266",
    RegisteredPopulation: "1798",
    VotingStationName: "BOPHELONG PRIMARY SCHOOL",
    Address: "1065 MOSAKA STREET  BOPHELONG  VANDERBIJLPARK",
    Latitude: "-26.693",
    Longitude: "27.802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7037",
    WardLookupId: "3486",
    VDNumber: "32940402",
    RegisteredPopulation: "2591",
    VotingStationName: "FRIKKIE MEYER LAERSKOOL",
    Address: "C /O HUXLEY & EUCLID STR  CW1  VANDERBIJLPARK",
    Latitude: "-26.6999",
    Longitude: "27.8267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7038",
    WardLookupId: "3486",
    VDNumber: "32940806",
    RegisteredPopulation: "2590",
    VotingStationName: "EMFULENI PRIMARY SCHOOL",
    Address: "CILLIERS  VANDERBIJLPARK  BONANE",
    Latitude: "-26.7012",
    Longitude: "27.7987",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7039",
    WardLookupId: "3486",
    VDNumber: "32940929",
    RegisteredPopulation: "694",
    VotingStationName: "TENT FLORA GARDENS (ACTION CRICKET PRECINCT)",
    Address: "ANEMONE  VANDERBIJLPARK  VANDERBIJLPARK",
    Latitude: "-26.719",
    Longitude: "27.8167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7040",
    WardLookupId: "3487",
    VDNumber: "32930782",
    RegisteredPopulation: "4671",
    VotingStationName: "EVATON PRIMARY SCHOOL",
    Address: "10353 KING MOSHOESHOE EXT. 11  EVATON WEST  EVATON",
    Latitude: "-26.5232",
    Longitude: "27.818",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7041",
    WardLookupId: "3487",
    VDNumber: "32930940",
    RegisteredPopulation: "1877",
    VotingStationName: "CHIEF BAMBATHA SCHOOL",
    Address: "CORNER OF MALUTI AND LEBOMBO   EVATON WEST  EVATON WEST",
    Latitude: "-26.5131",
    Longitude: "27.821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7042",
    WardLookupId: "3488",
    VDNumber: "32940299",
    RegisteredPopulation: "4303",
    VotingStationName: "CRYSTAL SPRINGS COMBINED SCHOOL",
    Address: "1446 POLOKONG/JOHANDEO  VANDERBIJLPARK",
    Latitude: "-26.5991",
    Longitude: "27.8116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7043",
    WardLookupId: "3488",
    VDNumber: "32940468",
    RegisteredPopulation: "1531",
    VotingStationName: "NOORDVAAL COMMUNITY HALL",
    Address: "89 STAND  MULLERSTUINE  EMFULENI",
    Latitude: "-26.7029",
    Longitude: "27.7579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7044",
    WardLookupId: "3488",
    VDNumber: "32940480",
    RegisteredPopulation: "1584",
    VotingStationName: "TENT AT LOCH VAAL HOTEL (LOCH VAAL HOTEL PRECINCT)",
    Address: "VILLAGE MANOR  LOCH VAAL  VILLAGE MANOR HOTEL",
    Latitude: "-26.7376",
    Longitude: "27.7087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7045",
    WardLookupId: "3488",
    VDNumber: "33330013",
    RegisteredPopulation: "331",
    VotingStationName: "BALEMI BA LEKOA (KLIPKOP KAALFONTEIN)",
    Address: "NONE  KLIPKOP  VANDERBIJLPARK",
    Latitude: "-26.5681",
    Longitude: "27.7154",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7046",
    WardLookupId: "3488",
    VDNumber: "33330024",
    RegisteredPopulation: "671",
    VotingStationName: "VAALRIVER RAID CENTRE",
    Address: "PLOT 84  LOUISRUS  VAALRIVER",
    Latitude: "-26.6495",
    Longitude: "27.7609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7047",
    WardLookupId: "3488",
    VDNumber: "33330035",
    RegisteredPopulation: "762",
    VotingStationName: "VAALOEWER RAID CENTRE",
    Address: "277 VAALOEWER AVE  VAALOEWER GATE  VANDERBIJLPARK",
    Latitude: "-26.7265",
    Longitude: "27.5896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7048",
    WardLookupId: "3488",
    VDNumber: "33330046",
    RegisteredPopulation: "297",
    VotingStationName: "RAMOSUKULA SCHOOL",
    Address:
      "3235 P O BOX 3235 VANDERBIJLPARK  1474TAHITTI ESTATES FARM  VAALRIVIER VANDERBILJPARK",
    Latitude: "-26.7282",
    Longitude: "27.6457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7049",
    WardLookupId: "3489",
    VDNumber: "32930748",
    RegisteredPopulation: "2768",
    VotingStationName: "REACHOUT COMMUNITY CHURCH",
    Address: "EXTENSION 4  EVATON WEST   EVATON WEST",
    Latitude: "-26.5046",
    Longitude: "27.8192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7050",
    WardLookupId: "3489",
    VDNumber: "32930883",
    RegisteredPopulation: "2054",
    VotingStationName: "EVATON WEST EXT.3 HALL",
    Address: "NONE  EVATON WEST EXT.3  EVATON WEST",
    Latitude: "-26.5133",
    Longitude: "27.8145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7051",
    WardLookupId: "3489",
    VDNumber: "32930939",
    RegisteredPopulation: "1897",
    VotingStationName: "LIVINGSTONE WORSHIP CENTRE",
    Address: "N/A  EVATON WEST EXT. 5   EVATON WEST",
    Latitude: "-26.5006",
    Longitude: "27.8252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7052",
    WardLookupId: "3489",
    VDNumber: "32930951",
    RegisteredPopulation: "1123",
    VotingStationName: "TRINITY CHURCH OF GOD EVATON WEST EXT. 7A",
    Address: "EXT 7  EVATON WEST  EVATON WEST",
    Latitude: "-26.5087",
    Longitude: "27.8202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7053",
    WardLookupId: "3489",
    VDNumber: "32931086",
    RegisteredPopulation: "884",
    VotingStationName: "NATIONAL ETHOPIAN CHRISTIAN FELLOWSHIP",
    Address: "N/A  EVATON WEST EXT 7B (HLALA KWA BAFILEYO)  VANDERBIJLPARK",
    Latitude: "-26.5063",
    Longitude: "27.8272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7054",
    WardLookupId: "3490",
    VDNumber: "32940648",
    RegisteredPopulation: "3885",
    VotingStationName: "LETSEMENG PRIMARY SCHOOL",
    Address: "2004 MANDELA DRIVE  BOITUMELO  ",
    Latitude: "-26.5626",
    Longitude: "27.8233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7055",
    WardLookupId: "3490",
    VDNumber: "32940839",
    RegisteredPopulation: "1729",
    VotingStationName: "LIGHT OF GOD WORSHIP CENTRE",
    Address: "CARLIFORNIA  SONDERWATER  SEBOKENG",
    Latitude: "-26.5425",
    Longitude: "27.8131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7056",
    WardLookupId: "3490",
    VDNumber: "32940873",
    RegisteredPopulation: "3418",
    VotingStationName: "BOITUMELO SECONDARY SCHOOL",
    Address: "TOKYO SEXWALE  BOITUMELO  SEBOKENG",
    Latitude: "-26.5586",
    Longitude: "27.8239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7057",
    WardLookupId: "3491",
    VDNumber: "32930298",
    RegisteredPopulation: "3069",
    VotingStationName: "BEVERLY HILLS HIGH SCHOOL",
    Address: "2854 FLORIDA STREET  BEVERLY HILLS  EVATON",
    Latitude: "-26.5369",
    Longitude: "27.8252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7058",
    WardLookupId: "3491",
    VDNumber: "32930973",
    RegisteredPopulation: "2255",
    VotingStationName: "WATER IN THE WILDERNESS MINISTRIES",
    Address: "CNR. CHIEF BAMBATHA AND MOSHOE  EVATON WEST  EVATON WEST",
    Latitude: "-26.5239",
    Longitude: "27.817",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7059",
    WardLookupId: "3491",
    VDNumber: "32931075",
    RegisteredPopulation: "1025",
    VotingStationName: "TENT BEVERLY HILLS KINGSTON ROAD",
    Address: "KINGSTON ROAD  BEVERLY HILLS  EVATON WEST",
    Latitude: "-26.5321",
    Longitude: "27.8221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7060",
    WardLookupId: "3491",
    VDNumber: "32940884",
    RegisteredPopulation: "1584",
    VotingStationName: "EMTHONJENI SATELLITE 2",
    Address: "N/A  SONDERWATER  SEBOKENG",
    Latitude: "-26.5464",
    Longitude: "27.822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7061",
    WardLookupId: "3492",
    VDNumber: "32930401",
    RegisteredPopulation: "982",
    VotingStationName: "TOKELO SCHOOL",
    Address: "21 SELBORNE ROAD  SMALL FARMS  EVATON",
    Latitude: "-26.5466",
    Longitude: "27.8475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7062",
    WardLookupId: "3492",
    VDNumber: "32930467",
    RegisteredPopulation: "2023",
    VotingStationName: "MOSIOA SCHOOL",
    Address: "45 SELBORNE ROAD  SMALL FARMS  EVATON",
    Latitude: "-26.5485",
    Longitude: "27.8509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7063",
    WardLookupId: "3492",
    VDNumber: "32930849",
    RegisteredPopulation: "1728",
    VotingStationName: "ROMAN CATHOLIC CHURCH AT SMALL FARM",
    Address: "NO STREET NAME AVAILABLE  SMALL FARMS  X",
    Latitude: "-26.5505",
    Longitude: "27.8589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7064",
    WardLookupId: "3492",
    VDNumber: "32930861",
    RegisteredPopulation: "1094",
    VotingStationName: "JABULANI-THABANG SCHOOL",
    Address: "67/10 11TH AVENUE  SMALL FARMS  EVATON",
    Latitude: "-26.5567",
    Longitude: "27.8502",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7065",
    WardLookupId: "3492",
    VDNumber: "32930894",
    RegisteredPopulation: "1189",
    VotingStationName: "ESOKWAZI SECONDARY SCHOOL",
    Address: "151 ZONE 8  SEBOKENG  VEREENIGING",
    Latitude: "-26.5494",
    Longitude: "27.8627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7066",
    WardLookupId: "3493",
    VDNumber: "32940222",
    RegisteredPopulation: "1950",
    VotingStationName: "TSHITSO INTERMEDIATE SCHOOL",
    Address: "17878 THUTO STREET  ZONE 14  SEBOKENG",
    Latitude: "-26.5858",
    Longitude: "27.8341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7067",
    WardLookupId: "3493",
    VDNumber: "32940233",
    RegisteredPopulation: "2546",
    VotingStationName: "SETJHABA SOHLE SCHOOL",
    Address: "18648 ZONE 14  SEBOKENG  SEBOKENG",
    Latitude: "-26.5909",
    Longitude: "27.8313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7068",
    WardLookupId: "3493",
    VDNumber: "32940244",
    RegisteredPopulation: "2622",
    VotingStationName: "AFRICAN PRESBYTERIAN CHURCH",
    Address: "17968 ZONE14  SEBOKENG",
    Latitude: "-26.5887",
    Longitude: "27.8266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7069",
    WardLookupId: "3493",
    VDNumber: "32940671",
    RegisteredPopulation: "1241",
    VotingStationName: "DINOKANENG SECONDARY SCHOOL",
    Address: "15851 zone 21  sebokeng  SEBOKENG",
    Latitude: "-26.5856",
    Longitude: "27.8158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7070",
    WardLookupId: "3494",
    VDNumber: "32940110",
    RegisteredPopulation: "4085",
    VotingStationName: "SIVIWE PRIMARY SCHOOL",
    Address: "ZONE 10  SEBOKENG",
    Latitude: "-26.5843",
    Longitude: "27.8482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7071",
    WardLookupId: "3494",
    VDNumber: "32940200",
    RegisteredPopulation: "2915",
    VotingStationName: "SOL TSOTETSI SPORTS CENTRE",
    Address: "ZONE 14  SEBOKENG",
    Latitude: "-26.5884",
    Longitude: "27.8406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7072",
    WardLookupId: "3494",
    VDNumber: "32940930",
    RegisteredPopulation: "746",
    VotingStationName: "ZIPHAKAMISE NURSERY AND PRE-SCHOOL",
    Address: "NONE  SEBOKENG ZONE 10  SEBOKENG",
    Latitude: "-26.5902",
    Longitude: "27.8481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7073",
    WardLookupId: "3495",
    VDNumber: "32940154",
    RegisteredPopulation: "1534",
    VotingStationName: "SEBOKENG TECHNICAL HIGH SCHOOL",
    Address: "MOHALE  ZONE 13  SEBOKENG",
    Latitude: "-26.5749",
    Longitude: "27.8384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7074",
    WardLookupId: "3495",
    VDNumber: "32940165",
    RegisteredPopulation: "2147",
    VotingStationName: "SIZANANI THUSANANG COMPREHENSIVE SCHOOL",
    Address: "1258 PHUTHATSWANA STR  ZONE 13  SEBOKENG",
    Latitude: "-26.575",
    Longitude: "27.8347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7075",
    WardLookupId: "3495",
    VDNumber: "32940187",
    RegisteredPopulation: "1703",
    VotingStationName: "MODISHI-LEKASHU PRIMARY SCHOOL",
    Address: "ZONE 13  SEBEKENG  ",
    Latitude: "-26.5809",
    Longitude: "27.8379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7076",
    WardLookupId: "3495",
    VDNumber: "32940198",
    RegisteredPopulation: "2679",
    VotingStationName: "JENGRAC TECHNICAL COLLEGE (EX-LESABASABA SCHOOL)",
    Address: "3641 LEWETSE STR  ZONE 13  SEBOKENG",
    Latitude: "-26.5812",
    Longitude: "27.8294",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7077",
    WardLookupId: "3496",
    VDNumber: "32940132",
    RegisteredPopulation: "2283",
    VotingStationName: "NTABANKULU MULTI - PURPOSE CENTRE",
    Address: "4243 MPANGEVA STREET  ZONE 12  SEBOKENG",
    Latitude: "-26.5659",
    Longitude: "27.8405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7078",
    WardLookupId: "3496",
    VDNumber: "32940143",
    RegisteredPopulation: "2093",
    VotingStationName: "TSHEMEDI SCHOOL",
    Address: "336 SEKGAPANE STR  ZONE 13  SEBOKENG",
    Latitude: "-26.5666",
    Longitude: "27.8336",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7079",
    WardLookupId: "3496",
    VDNumber: "32940312",
    RegisteredPopulation: "2230",
    VotingStationName: "QWELANG PRIMARY SCHOOL",
    Address: "6986 ZONE 12 EXTENSION  SEBOKENG  SEBOKENG",
    Latitude: "-26.5604",
    Longitude: "27.8396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7080",
    WardLookupId: "3496",
    VDNumber: "32940840",
    RegisteredPopulation: "1275",
    VotingStationName: "PHUTHADITJHABA OLD AGE CENTRE (BEHIND MANDELA SQUA",
    Address: "71697 N/A  KANANA EXT 2  VANDERBIJLPARK",
    Latitude: "-26.5615",
    Longitude: "27.8312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7081",
    WardLookupId: "3497",
    VDNumber: "32940075",
    RegisteredPopulation: "2603",
    VotingStationName: "SEBOKENG ADULT CENTRE",
    Address: "14237 MOFOLO STREET  ZONE 11  SEBOKENG",
    Latitude: "-26.5692",
    Longitude: "27.8488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7082",
    WardLookupId: "3497",
    VDNumber: "32940086",
    RegisteredPopulation: "2250",
    VotingStationName: "THUTHUKANI PRIMARY SCHOOL",
    Address: "5506 MBALI STREET  ZONE 12  SEBOKENG",
    Latitude: "-26.5666",
    Longitude: "27.8466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7083",
    WardLookupId: "3497",
    VDNumber: "32940097",
    RegisteredPopulation: "1965",
    VotingStationName: "TJHABATSATSI PRIMARY SCHOOL",
    Address: "13566 ZONE 11  SEBOKENG  SEBOKENG",
    Latitude: "-26.5761",
    Longitude: "27.8462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7084",
    WardLookupId: "3498",
    VDNumber: "32940109",
    RegisteredPopulation: "1964",
    VotingStationName: "MOGOGODI PRIMARY SCHOOL",
    Address: "12689 BOLEU STREET  ZONE 11  SEBOKENG",
    Latitude: "-26.5728",
    Longitude: "27.8423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7085",
    WardLookupId: "3498",
    VDNumber: "32940121",
    RegisteredPopulation: "1958",
    VotingStationName: "MTHOMBOLWAZI PRIMARY SCHOOL",
    Address: "4988 MFOLOZI STREET  ZONE 12  SEBOKENG",
    Latitude: "-26.5701",
    Longitude: "27.8405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7086",
    WardLookupId: "3498",
    VDNumber: "32940176",
    RegisteredPopulation: "1691",
    VotingStationName: "HERVORMDE KERK (MARANATHA)",
    Address: "3031 RATHEBE  ZONE 13  SEBOKENG",
    Latitude: "-26.5823",
    Longitude: "27.843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7087",
    WardLookupId: "3498",
    VDNumber: "32940862",
    RegisteredPopulation: "1015",
    VotingStationName: "CHRIST THE KING REACHOUT MINISTRY",
    Address: "CORNER MOSHOESHOE  SEBOKENG ZONE 10  SEBOKENG",
    Latitude: "-26.5801",
    Longitude: "27.8433",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7088",
    WardLookupId: "3499",
    VDNumber: "32930838",
    RegisteredPopulation: "3448",
    VotingStationName: "BOIKETLONG PRIMARY SCHOOL",
    Address: "VLAKFONTEIN  BOIKETLONG / VLAKFONTEIN  VLAKFONTEIN",
    Latitude: "-26.6036",
    Longitude: "27.858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7089",
    WardLookupId: "3499",
    VDNumber: "32930984",
    RegisteredPopulation: "415",
    VotingStationName: "WATERDAL GENERAL DEALER",
    Address: "MAIN ROAD  WATERDAL  WATERDAL",
    Latitude: "-26.571",
    Longitude: "27.871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7090",
    WardLookupId: "3499",
    VDNumber: "32940020",
    RegisteredPopulation: "2894",
    VotingStationName: "MOHALADITOE SECONDARY SCHOOL",
    Address: "920 ZONE 10 EXT 3  SEBOKENG  SEBOKENG",
    Latitude: "-26.5666",
    Longitude: "27.8548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7091",
    WardLookupId: "3499",
    VDNumber: "32940031",
    RegisteredPopulation: "1857",
    VotingStationName: "SEHOPOTSO SECONDARY SCHOOL",
    Address: "6462 ZONE 12  SEBOKENG  SEBOKENG",
    Latitude: "-26.5603",
    Longitude: "27.8506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7092",
    WardLookupId: "3500",
    VDNumber: "32930513",
    RegisteredPopulation: "2834",
    VotingStationName: "KGOKARE SECONDARY SCHOOL",
    Address: "12205 MOPELI STREET  ZONE 7A  SEBOKENG",
    Latitude: "-26.5534",
    Longitude: "27.865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7093",
    WardLookupId: "3500",
    VDNumber: "32930524",
    RegisteredPopulation: "2732",
    VotingStationName: "LEFIKA PRIMARY SCHOOL",
    Address: "10313 ITSOSE SREET ZONE 7A  SEBOKENG  VEREENIGING",
    Latitude: "-26.556",
    Longitude: "27.8703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7094",
    WardLookupId: "3500",
    VDNumber: "32930546",
    RegisteredPopulation: "1506",
    VotingStationName: "APOSTOLIC GOSPEL CHURCH",
    Address: "8302 SEKWATI STREET  ZONE 7 A  SEBOKENG",
    Latitude: "-26.5589",
    Longitude: "27.8748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7095",
    WardLookupId: "3501",
    VDNumber: "32930434",
    RegisteredPopulation: "1785",
    VotingStationName: "JORDAN HIGH SCHOOL",
    Address: "2060/9 CNR DONNA & GLASGOW ROAD  EVATON  VEREENIGING",
    Latitude: "-26.5373",
    Longitude: "27.873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7096",
    WardLookupId: "3501",
    VDNumber: "32930445",
    RegisteredPopulation: "1732",
    VotingStationName: "CHURCH OF ASCENSION",
    Address: "ZONE 6 EXT 1  SEBOKENG  VEREENIGING",
    Latitude: "-26.5404",
    Longitude: "27.8757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7097",
    WardLookupId: "3501",
    VDNumber: "32930456",
    RegisteredPopulation: "2625",
    VotingStationName: "THANDOKWAZI SECONDARY SCHOOL",
    Address: "ZONE 6  SEBOKENG  SEBOKENG",
    Latitude: "-26.5458",
    Longitude: "27.8826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7098",
    WardLookupId: "3501",
    VDNumber: "32931031",
    RegisteredPopulation: "1238",
    VotingStationName: "RESIDENTIA COMMUNITY LIBRARY",
    Address: "0 MBALI STREET  UNIT 6  SEBOKENG",
    Latitude: "-26.548",
    Longitude: "27.8743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7099",
    WardLookupId: "3502",
    VDNumber: "32930557",
    RegisteredPopulation: "2649",
    VotingStationName: "SIVUSE PRIMARY SCHOOL",
    Address: "9471 MOPEDI STREET  ZONE 7  SEBOKENG",
    Latitude: "-26.5511",
    Longitude: "27.8795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7100",
    WardLookupId: "3502",
    VDNumber: "32930568",
    RegisteredPopulation: "4078",
    VotingStationName: "SETLABOTJHA PRIMARY SCHOOL - EATONSIDE",
    Address: "2496 CNR KNOX & CINDERELLA  EATONSIDE  RESIDENSIA",
    Latitude: "-26.5583",
    Longitude: "27.8848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7101",
    WardLookupId: "3503",
    VDNumber: "32930254",
    RegisteredPopulation: "2151",
    VotingStationName: "CALVARY TEMPLE",
    Address: "383 SEBENZILE STREET  ZONE 6 EXT 5  SEBOKENG",
    Latitude: "-26.5343",
    Longitude: "27.8857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7102",
    WardLookupId: "3503",
    VDNumber: "32930276",
    RegisteredPopulation: "2168",
    VotingStationName: "ITSEBENG PRIMARY SCHOOL",
    Address: "588001 ZONE 3  SEBOKENG  ",
    Latitude: "-26.5269",
    Longitude: "27.8763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7103",
    WardLookupId: "3503",
    VDNumber: "32930300",
    RegisteredPopulation: "2785",
    VotingStationName: "KGUTLO-THARO SECONDARY SCHOOL",
    Address: "573008 ZONE 3  SEBOKENG  SEBOKENG",
    Latitude: "-26.5325",
    Longitude: "27.8748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7104",
    WardLookupId: "3504",
    VDNumber: "32930265",
    RegisteredPopulation: "1562",
    VotingStationName: "EKUJULENI SCHOOL",
    Address: "538012 ZONE 3  SEBOKENG  ",
    Latitude: "-26.523",
    Longitude: "27.8653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7105",
    WardLookupId: "3504",
    VDNumber: "32930287",
    RegisteredPopulation: "2309",
    VotingStationName: "EMANZINI SCHOOL",
    Address: "553095 ZONE 3  SEBOKENG  ",
    Latitude: "-26.5258",
    Longitude: "27.8691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7106",
    WardLookupId: "3504",
    VDNumber: "32930805",
    RegisteredPopulation: "879",
    VotingStationName: "ST PAUL APOSTOLIC FAITH (RAMOIPONE CHURCH)",
    Address: "N/A  EVATON  ",
    Latitude: "-26.5353",
    Longitude: "27.8688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7107",
    WardLookupId: "3504",
    VDNumber: "32930872",
    RegisteredPopulation: "2643",
    VotingStationName: "MOPHOLOSI SECONDARY SCHOOL",
    Address: "PO BOX 1160 LAKESIDE 1984  LAKESIDE  LAKESIDE",
    Latitude: "-26.5198",
    Longitude: "27.8718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7108",
    WardLookupId: "3505",
    VDNumber: "32930412",
    RegisteredPopulation: "2051",
    VotingStationName: "BOTLE HADI PRIMARY SCHOOL",
    Address: "1461 WARD ROAD  EVATON  ",
    Latitude: "-26.5333",
    Longitude: "27.8631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7109",
    WardLookupId: "3505",
    VDNumber: "32930423",
    RegisteredPopulation: "1396",
    VotingStationName: "PASSOVER EVANGELISM CHURCH",
    Address: "2793 DONA ROAD  EASTERNVILLE  EVATON",
    Latitude: "-26.5358",
    Longitude: "27.866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7110",
    WardLookupId: "3505",
    VDNumber: "32930490",
    RegisteredPopulation: "2289",
    VotingStationName: "LEEMA-MOKOTULI PRIMARY SCHOOL",
    Address: "14965 ZONE 7B  SEBOKENG  VEREENIGING",
    Latitude: "-26.5478",
    Longitude: "27.8655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7111",
    WardLookupId: "3505",
    VDNumber: "32930906",
    RegisteredPopulation: "1667",
    VotingStationName: "UNITING REFORMED CHURCH IN SOUTHERN AFRICA",
    Address: "44/20 MILNER ROAD  SMALL FARMS  EVATON",
    Latitude: "-26.5386",
    Longitude: "27.85",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7112",
    WardLookupId: "3506",
    VDNumber: "32930186",
    RegisteredPopulation: "2189",
    VotingStationName: "LAKESIDE SECONDARY SCHOOL",
    Address: "4201 SEBILOANE  PALM SPRINGS  ORANGE FARM",
    Latitude: "-26.505",
    Longitude: "27.8382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7113",
    WardLookupId: "3506",
    VDNumber: "32930210",
    RegisteredPopulation: "1069",
    VotingStationName: "THABENG PRIMARY SCHOOL",
    Address: "EVATON  EVATON CENTRAL  EVATON",
    Latitude: "-26.5178",
    Longitude: "27.8318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7114",
    WardLookupId: "3506",
    VDNumber: "32930232",
    RegisteredPopulation: "1525",
    VotingStationName: "MAXEKE SCHOOL",
    Address: "63 LIND ROAD  EVATON  EVATON",
    Latitude: "-26.524",
    Longitude: "27.8311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7115",
    WardLookupId: "3506",
    VDNumber: "32930355",
    RegisteredPopulation: "2022",
    VotingStationName: "LETSHEGO SCHOOL",
    Address: "197/199 WARD ROAD  EVATON  ",
    Latitude: "-26.5385",
    Longitude: "27.8341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7116",
    WardLookupId: "3506",
    VDNumber: "32930377",
    RegisteredPopulation: "743",
    VotingStationName: "MOTSEWAPELE PRIMARY SCHOOL",
    Address: "755 NEWMAN ROAD  EVATON  ",
    Latitude: "-26.5204",
    Longitude: "27.8411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7117",
    WardLookupId: "3507",
    VDNumber: "32930221",
    RegisteredPopulation: "1066",
    VotingStationName: "MOFOLO SCHOOL",
    Address: "864/7 HAMILTON ROAD  EVATON  ",
    Latitude: "-26.5215",
    Longitude: "27.8436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7118",
    WardLookupId: "3507",
    VDNumber: "32930311",
    RegisteredPopulation: "1621",
    VotingStationName: "TSOKOLIBANE PRIMARY SCHOOL",
    Address: "1262 STERLING ROAD  EVATON  EVATON",
    Latitude: "-26.527",
    Longitude: "27.8563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7119",
    WardLookupId: "3507",
    VDNumber: "32930322",
    RegisteredPopulation: "1738",
    VotingStationName: "LINDISA SCHOOL",
    Address: "535 AVONDALE ROAD  176-4  EVATON",
    Latitude: "-26.5291",
    Longitude: "27.8384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7120",
    WardLookupId: "3507",
    VDNumber: "32930344",
    RegisteredPopulation: "1230",
    VotingStationName: "AME TRINITY CHURCH",
    Address: "1041 CRADOCK ROAD  EVATON  VEREENIGING",
    Latitude: "-26.5286",
    Longitude: "27.8513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7121",
    WardLookupId: "3507",
    VDNumber: "32930366",
    RegisteredPopulation: "1702",
    VotingStationName: "BOIKAGO PRIMARY SCHOOL",
    Address: "687 ADAMS ROAD  EVATON  VEREENIGING",
    Latitude: "-26.5352",
    Longitude: "27.8421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7122",
    WardLookupId: "3508",
    VDNumber: "32930029",
    RegisteredPopulation: "1793",
    VotingStationName: "LAERSKOOL UNITASPARK",
    Address: "33 JAPIE KRIGE STREET  UNITASPARK  VEREENIGING",
    Latitude: "-26.6349",
    Longitude: "27.9199",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7123",
    WardLookupId: "3508",
    VDNumber: "32930096",
    RegisteredPopulation: "2947",
    VotingStationName: "SONLANDPARK LAERSKOOL",
    Address: "18 ABRAHAM RAS STREET  SONLANDPARK  VEREENIGING",
    Latitude: "-26.6137",
    Longitude: "27.9051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7124",
    WardLookupId: "3508",
    VDNumber: "32930142",
    RegisteredPopulation: "2358",
    VotingStationName: "HOÃ‹RSKOOL OVERVAAL",
    Address: "7 REYNOLDS STREET  FALCON RIDGE  VEREENIGING",
    Latitude: "-26.6036",
    Longitude: "27.9119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7125",
    WardLookupId: "3508",
    VDNumber: "32930579",
    RegisteredPopulation: "1655",
    VotingStationName: "LAERSKOOL VRYHEIDSMONUMENT",
    Address: "HOUTKOP  DUNCANVILLE  VEREENIGING",
    Latitude: "-26.6456",
    Longitude: "27.9219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7126",
    WardLookupId: "3508",
    VDNumber: "32931109",
    RegisteredPopulation: "483",
    VotingStationName: "TENT UNITASPARK EXT",
    Address: "GERRIE BRAND  UNITASPARK  VEREENIGING",
    Latitude: "-26.6463",
    Longitude: "27.8967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7127",
    WardLookupId: "3509",
    VDNumber: "33200053",
    RegisteredPopulation: "585",
    VotingStationName: "BANTU-BONKE COMMUNITY HALL.",
    Address: "BANTU-BONKE  MEYERTON",
    Latitude: "-26.7199",
    Longitude: "28.0166",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7128",
    WardLookupId: "3509",
    VDNumber: "33200075",
    RegisteredPopulation: "309",
    VotingStationName: "BLOEKOMSPOORT",
    Address: "R54 MAIN ROAD  VLAKFONTEIN  MEYERTON",
    Latitude: "-26.752",
    Longitude: "28.1023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7129",
    WardLookupId: "3509",
    VDNumber: "33200109",
    RegisteredPopulation: "90",
    VotingStationName: "KUDUNG MIDDLE SCHOOL (NOOITGEDACHT)",
    Address: "NOOITGEDACHT FARM  BOSCHKOP-RIETSPRUIT  HEIDELBERG DISTRICT",
    Latitude: "-26.6844",
    Longitude: "28.328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7130",
    WardLookupId: "3509",
    VDNumber: "33200110",
    RegisteredPopulation: "124",
    VotingStationName: "PLATKOPPIE",
    Address: "420 I.R PORTION 10  HEIDELBERG  ",
    Latitude: "-26.6453",
    Longitude: "28.2349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7131",
    WardLookupId: "3509",
    VDNumber: "33200198",
    RegisteredPopulation: "182",
    VotingStationName: "TIKELO COMMUNITY HALL",
    Address: "EUREKA FARM  WITLEIFONTEIN  HEIDELBERG",
    Latitude: "-26.7844",
    Longitude: "28.3188",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7132",
    WardLookupId: "3509",
    VDNumber: "33200200",
    RegisteredPopulation: "1109",
    VotingStationName: "JAKKALSDRAAI HALL",
    Address: "70 FIFTH STREET  MOOILANDE AH  MEYERTON",
    Latitude: "-26.6419",
    Longitude: "28.0494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7133",
    WardLookupId: "3509",
    VDNumber: "33210010",
    RegisteredPopulation: "1289",
    VotingStationName: "VAAL MARINA COMMUNITY HALL",
    Address:
      "OFF VEREENIGING-VILLIERS ROAD, ANCHOVY ROAD  VAAL MARINA  VEREENIGING",
    Latitude: "-26.8741",
    Longitude: "28.2313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7134",
    WardLookupId: "3510",
    VDNumber: "32930737",
    RegisteredPopulation: "3267",
    VotingStationName: "RISIVILLE LAERSKOOL",
    Address: "CNR MARIE & AIDA STREETS  RISIVILLE  VEREENIGING",
    Latitude: "-26.6453",
    Longitude: "27.9824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7135",
    WardLookupId: "3511",
    VDNumber: "32930603",
    RegisteredPopulation: "3293",
    VotingStationName: "ROTHDENE TOWN HALL",
    Address: "CORNER RABIE AND NORTH STREETS  ROTHDENE  MEYERTON",
    Latitude: "-26.6004",
    Longitude: "27.9914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7136",
    WardLookupId: "3511",
    VDNumber: "32931110",
    RegisteredPopulation: "390",
    VotingStationName: "MEYERTON HIGH SCHOOL",
    Address: "48 ANDREW MURRAY  KOOKRUS  MEYERTON",
    Latitude: "-26.586",
    Longitude: "28.0028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7137",
    WardLookupId: "3512",
    VDNumber: "33180049",
    RegisteredPopulation: "1278",
    VotingStationName: "DALESIDE LIBRARY",
    Address: "01 HOUTKAPPER STREET  MEYERTON  DALESIDE",
    Latitude: "-26.4998",
    Longitude: "28.0668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7138",
    WardLookupId: "3512",
    VDNumber: "33200019",
    RegisteredPopulation: "455",
    VotingStationName: "ABET BOITUMELO SCHOOL",
    Address: "EATON  BOITUMELO  MEYERTON",
    Latitude: "-26.4614",
    Longitude: "28.0933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7139",
    WardLookupId: "3512",
    VDNumber: "33200020",
    RegisteredPopulation: "385",
    VotingStationName: "BOSCHOEK WAREHOUSE",
    Address: "R557  BOSCHOEK  MEYERTON",
    Latitude: "-26.5154",
    Longitude: "28.1399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7140",
    WardLookupId: "3512",
    VDNumber: "33200132",
    RegisteredPopulation: "1612",
    VotingStationName: "KERK TUSSEN DIE MIELIELANDE",
    Address: "LOWA PRAIRIE  BUYSCELIA  MEYERTON",
    Latitude: "-26.6472",
    Longitude: "28.013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7141",
    WardLookupId: "3512",
    VDNumber: "33200143",
    RegisteredPopulation: "334",
    VotingStationName: "TENT PONTY VALLEY",
    Address: "6 STERKFONTEIN  MIDVAAL  MEYERTON",
    Latitude: "-26.5868",
    Longitude: "28.1807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7142",
    WardLookupId: "3513",
    VDNumber: "33120032",
    RegisteredPopulation: "1426",
    VotingStationName: "MICHAEL RUA SCHOOL",
    Address:
      "I77 NOOITGEDACHT,ON R155 RANDVAAL ROAD  WALKERVILLE/RANDVAAL  MEYERTON",
    Latitude: "-26.4686",
    Longitude: "28.0022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7143",
    WardLookupId: "3513",
    VDNumber: "33180027",
    RegisteredPopulation: "1760",
    VotingStationName: "RANDVAAL MUNICIPAL OFFICES",
    Address: "56 ROOIBOK STREET  HIGHBURY  RANDVAAL",
    Latitude: "-26.5147",
    Longitude: "28.044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7144",
    WardLookupId: "3514",
    VDNumber: "33120054",
    RegisteredPopulation: "4633",
    VotingStationName: "LAKESIDE COMMUNITY-  HALL",
    Address: "ST ANDREW  DE DEUR  MEYWRTON",
    Latitude: "-26.5168",
    Longitude: "27.8904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7145",
    WardLookupId: "3515",
    VDNumber: "33140023",
    RegisteredPopulation: "2797",
    VotingStationName: "ALOE RIDGE PRIMARY SCHOOL",
    Address:
      "OFF OLD JOHANNESBURG-VEREENIGING ROAD, SCHOOL ROAD  HARTZENBERGFONTEIN  WALKERVILLE",
    Latitude: "-26.3791",
    Longitude: "27.9583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7146",
    WardLookupId: "3515",
    VDNumber: "33140034",
    RegisteredPopulation: "1237",
    VotingStationName: "PENBRITTE EQUESTRIAN CENTRE",
    Address: "83 PLOT  EIKENHOF  MEYERTON",
    Latitude: "-26.3651",
    Longitude: "28.0327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7147",
    WardLookupId: "3516",
    VDNumber: "32930816",
    RegisteredPopulation: "2984",
    VotingStationName: "SICELO EARLY LEARNING CENTRE",
    Address: "BELL  MEYERTON  MEYERTON",
    Latitude: "-26.5436",
    Longitude: "28.0031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7148",
    WardLookupId: "3516",
    VDNumber: "32931053",
    RegisteredPopulation: "1212",
    VotingStationName: "TENT SICELO (WHITE HOUSE)",
    Address: "BELL  SICELO  MEYERTON",
    Latitude: "-26.5463",
    Longitude: "28.0032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7149",
    WardLookupId: "3517",
    VDNumber: "32930591",
    RegisteredPopulation: "1953",
    VotingStationName: "MEYERTON TOWN HALL",
    Address: "CNR JUNIUS & MITCHELL  MEYERTON  MEYERTON",
    Latitude: "-26.5574",
    Longitude: "28.0158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7150",
    WardLookupId: "3517",
    VDNumber: "32931008",
    RegisteredPopulation: "519",
    VotingStationName: "RAINBOW FAMILY CHURCH",
    Address: "C/O MORRIS AND JOHAN LE ROUX  MEYERTON  MEYERTON",
    Latitude: "-26.5608",
    Longitude: "28.0004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7151",
    WardLookupId: "3517",
    VDNumber: "32931042",
    RegisteredPopulation: "355",
    VotingStationName: "BROADLANDS PRIVATE SCHOOL",
    Address: "MORRIS  MEYERTON  MEYERTON FARMS",
    Latitude: "-26.5473",
    Longitude: "28.0098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7152",
    WardLookupId: "3518",
    VDNumber: "32930827",
    RegisteredPopulation: "4089",
    VotingStationName: "SICELO - STADIUM",
    Address: "WISCONSIN  MEYERTON  MEYERTON",
    Latitude: "-26.5533",
    Longitude: "27.9862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7153",
    WardLookupId: "3519",
    VDNumber: "33120021",
    RegisteredPopulation: "2065",
    VotingStationName: "DE DEUR PRIMARY SCHOOL",
    Address: "OLD VEREENIGING-JOHANNESBURG ROAD  DE DEUR  MEYERTON",
    Latitude: "-26.5127",
    Longitude: "27.9272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7154",
    WardLookupId: "3519",
    VDNumber: "33120043",
    RegisteredPopulation: "1064",
    VotingStationName: "CURRO ACADEMY SAVANNA CITY",
    Address: "CENTRAL BOULEVARD  MEYERTON  MEYERTON",
    Latitude: "-26.4659",
    Longitude: "27.9135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7155",
    WardLookupId: "3519",
    VDNumber: "33120065",
    RegisteredPopulation: "1265",
    VotingStationName: "ADONAI FELLOWSHIP CHURCH",
    Address: "110 FOURTH STREET  WALKERVILLE  MEYERTON",
    Latitude: "-26.4454",
    Longitude: "27.9513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7156",
    WardLookupId: "3519",
    VDNumber: "33120076",
    RegisteredPopulation: "1168",
    VotingStationName: "TENT SAVANNAH RDP",
    Address: "SAMI  MEYERTON  SAVANNAH CITY",
    Latitude: "-26.5088",
    Longitude: "27.9036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7157",
    WardLookupId: "3520",
    VDNumber: "33140012",
    RegisteredPopulation: "615",
    VotingStationName: "RAND WATER ZWARTKOPPIES PUMP STATION",
    Address: "OFF JOHANNESBURG-BRACKENHURST ROAD  BRACKENHURST  ALBERTON",
    Latitude: "-26.3451",
    Longitude: "28.0661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7158",
    WardLookupId: "3520",
    VDNumber: "33180038",
    RegisteredPopulation: "1844",
    VotingStationName: "SIBONILE SCHOOL FOR THE BLIND",
    Address: "33 PLOT GARTHVIEW ROAD  KLIPRIVIER  MEYERTON",
    Latitude: "-26.4222",
    Longitude: "28.1081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7159",
    WardLookupId: "3520",
    VDNumber: "33180061",
    RegisteredPopulation: "840",
    VotingStationName: "PILLIES FARM C0MMUNITY HALL",
    Address: "R55  PILLIES FARM  MEYERTON",
    Latitude: "-26.4411",
    Longitude: "28.0664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7160",
    WardLookupId: "3520",
    VDNumber: "33200211",
    RegisteredPopulation: "190",
    VotingStationName: "TENT R550 RIETSPRUIT SHOP",
    Address: "R550 RIETSPRUIT SHOP  R550 RIETSPRUIT  MEYERTON",
    Latitude: "-26.4245",
    Longitude: "28.143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7161",
    WardLookupId: "3521",
    VDNumber: "33200121",
    RegisteredPopulation: "3654",
    VotingStationName: "VOORWAARTS LAERSKOOL",
    Address: "CNR MIMOSA & JAN NEETHLING  CHRISSIESFONTEIN  MEYERTON EAST",
    Latitude: "-26.6019",
    Longitude: "28.0467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7162",
    WardLookupId: "3522",
    VDNumber: "32930793",
    RegisteredPopulation: "2263",
    VotingStationName: "DESTINATA SCHOOL",
    Address: "MIMOSA STREET  GOLFPARK  MEYERTON",
    Latitude: "-26.5555",
    Longitude: "28.0304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7163",
    WardLookupId: "3522",
    VDNumber: "32930962",
    RegisteredPopulation: "1619",
    VotingStationName: "DR MALAN HIGH SCHOOL",
    Address: "C/O HOLLIDAY & SHIPPARD STREETS  MEYERTON  MEYERTON",
    Latitude: "-26.5647",
    Longitude: "28.0188",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7164",
    WardLookupId: "3523",
    VDNumber: "33180016",
    RegisteredPopulation: "3746",
    VotingStationName: "HOPE FAMILY CHURCH",
    Address: "78 SHIPLAKE  HENLEY ON KLIP  MEYERTON",
    Latitude: "-26.5402",
    Longitude: "28.0459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7165",
    WardLookupId: "1063",
    VDNumber: "33070104",
    RegisteredPopulation: "1403",
    VotingStationName: "SITHOKOMELE PRIMARY SCHOOL",
    Address: "0 MAZIBUKO  HEIDELBERG  RATANDA PROPER",
    Latitude: "-26.5522",
    Longitude: "28.3321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7166",
    WardLookupId: "1063",
    VDNumber: "33070115",
    RegisteredPopulation: "983",
    VotingStationName: "RATANDA OLD AGE HOME",
    Address: "2886 NKITSING  RATANDA  HEIDELBERG - GP",
    Latitude: "-26.5474",
    Longitude: "28.328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7167",
    WardLookupId: "1063",
    VDNumber: "33200086",
    RegisteredPopulation: "111",
    VotingStationName: "CAMP ZENITH",
    Address: "PO 1814 HEIDELBERG  ELDORADO PARK  HEIDELBERG",
    Latitude: "-26.5715",
    Longitude: "28.255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7168",
    WardLookupId: "1063",
    VDNumber: "33200176",
    RegisteredPopulation: "490",
    VotingStationName: "SIPHILISIWE DAY CARE",
    Address: "H56 SIPHIWE VILLAGE  SEDAVEN  HEIDELBERG",
    Latitude: "-26.5244",
    Longitude: "28.311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7169",
    WardLookupId: "1063",
    VDNumber: "33200233",
    RegisteredPopulation: "1513",
    VotingStationName: "HONEYDEW DAYCARE CENTRE",
    Address: "RATANDA  HEIDELBERG",
    Latitude: "-26.5419",
    Longitude: "28.3398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7170",
    WardLookupId: "1064",
    VDNumber: "33070047",
    RegisteredPopulation: "2029",
    VotingStationName: "FAITH MISSION OUTREACH CHURCH",
    Address: "THABO MBEKI  RATANDA  HEIDELBERG - GP",
    Latitude: "-26.5584",
    Longitude: "28.3159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7171",
    WardLookupId: "1064",
    VDNumber: "33070069",
    RegisteredPopulation: "1343",
    VotingStationName: "LEHAE LA BANA DAYCARE",
    Address: "THABO MBEKI  EXT 6  RATANDA",
    Latitude: "-26.554",
    Longitude: "28.316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7172",
    WardLookupId: "1065",
    VDNumber: "33070081",
    RegisteredPopulation: "1902",
    VotingStationName: "FOUNTAIN FIVE PRIMARY SCHOOL",
    Address: "MOLOI STREET  LESEDI   RATANDA",
    Latitude: "-26.5566",
    Longitude: "28.3277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7173",
    WardLookupId: "1065",
    VDNumber: "33070182",
    RegisteredPopulation: "1411",
    VotingStationName: "HARVEST OUTREACH CENTRE",
    Address: "621 MOOKO STREET  RATANDA  HEIDELBERG - GP",
    Latitude: "-26.553",
    Longitude: "28.3341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7174",
    WardLookupId: "1065",
    VDNumber: "33070238",
    RegisteredPopulation: "728",
    VotingStationName: "RATANDA SECONDARY SCHOOL.",
    Address: "NDLOVU  RATANDA  HEIDELBERG - GP",
    Latitude: "-26.5558",
    Longitude: "28.3322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7175",
    WardLookupId: "1066",
    VDNumber: "33070058",
    RegisteredPopulation: "1539",
    VotingStationName: "OLD COMMUNITY HALL RATANDA",
    Address: "C/O HEIDELBERG & BLESBOK ST  RATANDA  HEIDELBERG",
    Latitude: "-26.5529",
    Longitude: "28.3283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7176",
    WardLookupId: "1066",
    VDNumber: "33070159",
    RegisteredPopulation: "1225",
    VotingStationName: "MULTIPURPOSE HALL",
    Address: "HEIDELBERG RD  RATANDA  HEIDELBERG",
    Latitude: "-26.5558",
    Longitude: "28.3247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7177",
    WardLookupId: "1066",
    VDNumber: "33070216",
    RegisteredPopulation: "701",
    VotingStationName: "QHAQHOLLA PRIMARY SCHOOL",
    Address: "2356 MAHOMO  HEIDELBERG  RATANDA PROPER",
    Latitude: "-26.5505",
    Longitude: "28.3255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7178",
    WardLookupId: "1067",
    VDNumber: "33070092",
    RegisteredPopulation: "2307",
    VotingStationName: "MULTI-PURPOSE CENTRE (EXT 7 RATANDA)",
    Address: "STAND 6224  RATANDA  EXT 7",
    Latitude: "-26.5693",
    Longitude: "28.3241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7179",
    WardLookupId: "1067",
    VDNumber: "33200222",
    RegisteredPopulation: "859",
    VotingStationName: "THUTO KE LEFA DAYCARE",
    Address: "8952 MATHE STREET EXT 8  RATANDA  HEIDELBERG",
    Latitude: "-26.5651",
    Longitude: "28.3216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7180",
    WardLookupId: "1068",
    VDNumber: "33070137",
    RegisteredPopulation: "2103",
    VotingStationName: "RATANDA PRIMARY SCHOOL",
    Address: "5773 OSCAR MPHETA DR  RATANDA EXT. 7  HEIDELBERG",
    Latitude: "-26.571",
    Longitude: "28.3237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7181",
    WardLookupId: "1068",
    VDNumber: "33100029",
    RegisteredPopulation: "296",
    VotingStationName: "KOBUS STANDER FARM",
    Address: "10 UITKYK ROAD  LESEDI  RIETKUIL",
    Latitude: "-26.4482",
    Longitude: "28.576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7182",
    WardLookupId: "1068",
    VDNumber: "33200064",
    RegisteredPopulation: "229",
    VotingStationName: "MASIZAKHE PRIMARY SCHOOL",
    Address: "LAGERSPOORT FARM  HEIDELBERG  HEIDELBERG",
    Latitude: "-26.591",
    Longitude: "28.4368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7183",
    WardLookupId: "1068",
    VDNumber: "33200097",
    RegisteredPopulation: "249",
    VotingStationName: "MOFOKENG FARM",
    Address: "CNR ELANDSFONTEIN AND VAALDAM  LESEDI  HEIDELBERG",
    Latitude: "-26.6101",
    Longitude: "28.3218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7184",
    WardLookupId: "1069",
    VDNumber: "33070148",
    RegisteredPopulation: "2004",
    VotingStationName: "KGORO YA THUTO",
    Address: "52712 MOKHOMONG  RATANDA  HEIDELBERG GP",
    Latitude: "-26.5357",
    Longitude: "28.3561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7185",
    WardLookupId: "1069",
    VDNumber: "33070171",
    RegisteredPopulation: "1867",
    VotingStationName: "BERTHA GXOWA PRIMARY SCHOOL",
    Address: "3849 BAMBISA DRIVE  RATANDA  HEIDELBERG",
    Latitude: "-26.5312",
    Longitude: "28.3573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7186",
    WardLookupId: "1070",
    VDNumber: "33070025",
    RegisteredPopulation: "1868",
    VotingStationName: "SHALIMAR RIDGE COMMUNITY HALL",
    Address: "1 KISMET ST  SHALIMAR RIDGE  HEIDELBERG",
    Latitude: "-26.5145",
    Longitude: "28.3551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7187",
    WardLookupId: "1070",
    VDNumber: "33070036",
    RegisteredPopulation: "2796",
    VotingStationName: "A.G. VISSER LAER SKOOL",
    Address: "RENSBURG  HEIDELBERG - GP",
    Latitude: "-26.5274",
    Longitude: "28.3646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7188",
    WardLookupId: "1071",
    VDNumber: "33070014",
    RegisteredPopulation: "3182",
    VotingStationName: "HEIDELBERG TOWN HALL",
    Address: "C/O HF VERWOERD & VOORTREKKER  HEIDELBERG  HEIDELBERG",
    Latitude: "-26.4997",
    Longitude: "28.3568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7189",
    WardLookupId: "1071",
    VDNumber: "33070160",
    RegisteredPopulation: "1096",
    VotingStationName: "HEIDELBERG PUBLIC SCHOOL CNR GEMSBOK AND DUIKER",
    Address: "GEMSBOK & DUIKER  JORDAN PARK  LESEDI [HEIDELBERG]",
    Latitude: "-26.5025",
    Longitude: "28.3414",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7190",
    WardLookupId: "1072",
    VDNumber: "33070227",
    RegisteredPopulation: "1192",
    VotingStationName: "MOUNTAIN VIEW HIGH SCHOOL",
    Address: "GLADIOLA & VARING  BERGSIG  HEIDELBERG - GP",
    Latitude: "-26.4899",
    Longitude: "28.3777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7191",
    WardLookupId: "1072",
    VDNumber: "33080239",
    RegisteredPopulation: "1441",
    VotingStationName: "JAMESON PARK COMMUNITY HALL",
    Address: "855 C/O LARK AVE & BERYL  JAMESON PARK  NIGEL",
    Latitude: "-26.4511",
    Longitude: "28.4178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7192",
    WardLookupId: "1072",
    VDNumber: "33100030",
    RegisteredPopulation: "569",
    VotingStationName: "TENT J&G FARM STALL (R23 BENONI/HEIDELBERG RD)",
    Address: "R23 BENONI/HEIDELBERG RD  SPAARWATER AGRIC. HOLDINGS  HEIDELBERG",
    Latitude: "-26.4182",
    Longitude: "28.3473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7193",
    WardLookupId: "1072",
    VDNumber: "33200031",
    RegisteredPopulation: "917",
    VotingStationName: "FOURIE-VILLE GARAGE (150 BENDOR)",
    Address: "150 BENDOR  HEIDELBERG  OVERKRUIN",
    Latitude: "-26.4797",
    Longitude: "28.3488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7194",
    WardLookupId: "1073",
    VDNumber: "33070193",
    RegisteredPopulation: "1522",
    VotingStationName: "EXT 23 MULTIPURPOSE HALL",
    Address: "DANNIE AVENUE  HEIDELBERG  RATANDA EXT 23",
    Latitude: "-26.5389",
    Longitude: "28.3609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7195",
    WardLookupId: "1073",
    VDNumber: "33070205",
    RegisteredPopulation: "1136",
    VotingStationName: "ACTS GOSPEL HEALING MINISTRIES",
    Address: "KHUHLUZA STREET  EX 26  RATANDA",
    Latitude: "-26.5404",
    Longitude: "28.3642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7196",
    WardLookupId: "1073",
    VDNumber: "33200165",
    RegisteredPopulation: "217",
    VotingStationName: "POORTJIE MINE",
    Address: "POORTJIE ROAD  HEIDELBERG  HEIDELBERG",
    Latitude: "-26.5099",
    Longitude: "28.4352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7197",
    WardLookupId: "1074",
    VDNumber: "33100018",
    RegisteredPopulation: "176",
    VotingStationName: "BOCHABELO PRIMARY SCHOOL",
    Address: "LANGZEKOEGAT  HEIDELBERG",
    Latitude: "-26.4242",
    Longitude: "28.6603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7198",
    WardLookupId: "1074",
    VDNumber: "33100041",
    RegisteredPopulation: "203",
    VotingStationName: "UMBILA FARM SCHOOL",
    Address: "1 UMBILA FARM ROAD  UMBILA ESTATES  SPRINGS FARMS",
    Latitude: "-26.2516",
    Longitude: "28.5624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7199",
    WardLookupId: "1074",
    VDNumber: "33100052",
    RegisteredPopulation: "580",
    VotingStationName: "NIGEL AUCTION CENTRE",
    Address: "R152 DEVON ROAD  NIGEL  NIGEL",
    Latitude: "-26.4312",
    Longitude: "28.5442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7200",
    WardLookupId: "1074",
    VDNumber: "33220011",
    RegisteredPopulation: "1002",
    VotingStationName: "VISCHKUIL COMMUNITY HALL",
    Address: "MELMAN (CNR DORIS)  ENDICOTT  SPRINGS",
    Latitude: "-26.2992",
    Longitude: "28.5852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7201",
    WardLookupId: "1074",
    VDNumber: "33220022",
    RegisteredPopulation: "1348",
    VotingStationName: "TENT AT KWAZENZELE SPORTS FIELD CENTRE",
    Address: "C/O ETHEL ROAD & R29  KWAZENZELE  VISCHKUIL",
    Latitude: "-26.2881",
    Longitude: "28.5708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7202",
    WardLookupId: "1075",
    VDNumber: "33130011",
    RegisteredPopulation: "1916",
    VotingStationName: "IMPUMELELO COMMUNITY HALL",
    Address: "IMPUMELELO  DEVON",
    Latitude: "-26.3487",
    Longitude: "28.7693",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7203",
    WardLookupId: "1075",
    VDNumber: "33130022",
    RegisteredPopulation: "2504",
    VotingStationName: "ZIKHETHELE SECONDARY SCHOOL",
    Address: "148 IMPUMELELO DRIVE  DEVON  LESEDI [HEIDELBERG]",
    Latitude: "-26.3443",
    Longitude: "28.7674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7204",
    WardLookupId: "1075",
    VDNumber: "33130033",
    RegisteredPopulation: "226",
    VotingStationName: "SITHEMBISO PRIMARY SCHOOL.",
    Address: "416 BARNET STREET  LESEDI  DEVON",
    Latitude: "-26.3619",
    Longitude: "28.784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7205",
    WardLookupId: "1076",
    VDNumber: "33250508",
    RegisteredPopulation: "3626",
    VotingStationName: "LODIRILE SECONDARY SCHOOL",
    Address: "1593 SANDPIPER STREET  RIETVALLEI  MOGALE CITY",
    Latitude: "-26.1927",
    Longitude: "27.7673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7206",
    WardLookupId: "1076",
    VDNumber: "33250902",
    RegisteredPopulation: "460",
    VotingStationName: "SWANNEVILLE BLOCK 9 TENT",
    Address: "YELLOWSTONE  SWANNEVILLE  MOGALE CITY",
    Latitude: "-26.1963",
    Longitude: "27.7595",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7207",
    WardLookupId: "1077",
    VDNumber: "33250485",
    RegisteredPopulation: "2558",
    VotingStationName: "COMMUNITY HALL RIETVALLEI EXT 1",
    Address: "2880 COLOMBIA STREET  RIETVALLEI EXT 1  MOGALE",
    Latitude: "-26.2007",
    Longitude: "27.7672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7208",
    WardLookupId: "1077",
    VDNumber: "33250698",
    RegisteredPopulation: "2118",
    VotingStationName: "THUTHUZEKANI PRIMARY SCHOOL",
    Address: "1861 DARLING STREET  RIETVALLEI EXT 1  MOGALE CITY",
    Latitude: "-26.2002",
    Longitude: "27.7584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7209",
    WardLookupId: "1078",
    VDNumber: "33250429",
    RegisteredPopulation: "1875",
    VotingStationName: "AZAADVILLE COMMUNITY HALL",
    Address: "551 TAJMAHAL STREET  AZAADVILLE  KRUGERSDORP",
    Latitude: "-26.171",
    Longitude: "27.7583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7210",
    WardLookupId: "1078",
    VDNumber: "33250463",
    RegisteredPopulation: "1686",
    VotingStationName: "RIETVALLEI EXT 2 COMMUNITY HALL",
    Address: "TEMP STREET  RIETVALLEI EXT 2  MOGALE",
    Latitude: "-26.1812",
    Longitude: "27.7568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7211",
    WardLookupId: "1078",
    VDNumber: "33250519",
    RegisteredPopulation: "1274",
    VotingStationName: "AZAADVILLE NURSERY SCHOOL",
    Address: "MEDINA STREET  AZAADVILLE  MOGALE CITY",
    Latitude: "-26.1711",
    Longitude: "27.7498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7212",
    WardLookupId: "1079",
    VDNumber: "33250441",
    RegisteredPopulation: "3382",
    VotingStationName: "KID MAPONYA  PRIMARY SCHOOL",
    Address: "15873 JOSEPH MOLATLHOA  KAGISO EXT 12  MOGALE CITY",
    Latitude: "-26.1749",
    Longitude: "27.7906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7213",
    WardLookupId: "1079",
    VDNumber: "33250632",
    RegisteredPopulation: "1233",
    VotingStationName: "DIVINE CHRISTIAN CHURCH",
    Address: "16343/0 CORNER OF R41 AND GEBA STREETS  KAGISO  KRUGERSDORP",
    Latitude: "-26.1722",
    Longitude: "27.7927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7214",
    WardLookupId: "1080",
    VDNumber: "33250430",
    RegisteredPopulation: "2479",
    VotingStationName: "KAGISO EXTENTION 12 GROUNDS (TENT)",
    Address: "ELESENBURG   MOGALE CITY  KRUGERSDORP",
    Latitude: "-26.1783",
    Longitude: "27.7924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7215",
    WardLookupId: "1080",
    VDNumber: "33250575",
    RegisteredPopulation: "1865",
    VotingStationName: "COMMUNITY CENTRE EXT 12",
    Address: "GEBA STREET  KAGISO EXT 12  MAOGALE",
    Latitude: "-26.1794",
    Longitude: "27.7868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7216",
    WardLookupId: "1080",
    VDNumber: "33250878",
    RegisteredPopulation: "1002",
    VotingStationName: "TENT NEAR EXTENSION 13 RESEVOIR",
    Address: "KAGISO EXTENSION 13  KAGISO  KRUGERSDORP",
    Latitude: "-26.1751",
    Longitude: "27.7981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7217",
    WardLookupId: "1081",
    VDNumber: "33250384",
    RegisteredPopulation: "4306",
    VotingStationName: "WD OLIPHANT PRIMARY SCHOOL",
    Address: "5116 THEMBA STREET  KAGISO  KRUGERSDORP",
    Latitude: "-26.1574",
    Longitude: "27.769",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7218",
    WardLookupId: "1082",
    VDNumber: "33250272",
    RegisteredPopulation: "2636",
    VotingStationName: "THUSONG PRIMARY SCHOOL",
    Address: "11796 SPARROW CRESCENT  KAGISO EXT II  KRUGERSDORP",
    Latitude: "-26.1687",
    Longitude: "27.7991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7219",
    WardLookupId: "1082",
    VDNumber: "33250306",
    RegisteredPopulation: "2843",
    VotingStationName: "MANDISA SHICEKA HIGH SCHOOL",
    Address: "11347 UTLHANONG STREET/HINZA STREET  KAGISO EXT 6  KRUGERSDORP",
    Latitude: "-26.1649",
    Longitude: "27.7841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7220",
    WardLookupId: "1083",
    VDNumber: "33250283",
    RegisteredPopulation: "2657",
    VotingStationName: "EVANGELICAL LUTHERAN CHURCH IN SOUTH AFRICA",
    Address: "7272 MZINTO DRIVE  TSAKANE  MOGALE CITY",
    Latitude: "-26.1618",
    Longitude: "27.7989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7221",
    WardLookupId: "1083",
    VDNumber: "33250700",
    RegisteredPopulation: "1772",
    VotingStationName: "TENT NEXT TO GREEN HOSTEL",
    Address: "CNR THEMBA DR & EMPANGENI STR  KAGISO  MOGALE CITY",
    Latitude: "-26.164",
    Longitude: "27.7959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7222",
    WardLookupId: "1084",
    VDNumber: "33250227",
    RegisteredPopulation: "2657",
    VotingStationName: "HOÃ‹RSKOOL BASTION",
    Address: "TREZONALAAN  MINDALORE  KRUGERSDORP",
    Latitude: "-26.1489",
    Longitude: "27.8227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7223",
    WardLookupId: "1084",
    VDNumber: "33250261",
    RegisteredPopulation: "1655",
    VotingStationName: "KAGISO HALL",
    Address: "8757 MMUSONG STREET  KAGISO  KRUGERSDORP",
    Latitude: "-26.1521",
    Longitude: "27.788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7224",
    WardLookupId: "1084",
    VDNumber: "33250294",
    RegisteredPopulation: "2032",
    VotingStationName: "KHASELIHLE PRIMARY SCHOOL",
    Address: "6447 HADEBE STREET  KAGISO  KRUGERSDORP",
    Latitude: "-26.1594",
    Longitude: "27.7915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7225",
    WardLookupId: "1085",
    VDNumber: "33250317",
    RegisteredPopulation: "1819",
    VotingStationName: "SANDILE PRIMARY SCHOOL",
    Address: "9893 THEMBA STREET  KAGISO  KRUGERSDORP",
    Latitude: "-26.162",
    Longitude: "27.7849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7226",
    WardLookupId: "1085",
    VDNumber: "33250328",
    RegisteredPopulation: "2917",
    VotingStationName: "THEMBILE PRIMARY SCHOOL",
    Address: "2699 SENZANGOKHONA STREET  KAGISO  KRUGERSDORP",
    Latitude: "-26.1576",
    Longitude: "27.7867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7227",
    WardLookupId: "1086",
    VDNumber: "33250373",
    RegisteredPopulation: "1992",
    VotingStationName: "FULL GOSPEL CHURCH OF GOD",
    Address: "11174 UMFOLOZI CRESCENT EXTENTION 6  MOGALE CITY  KAGISO",
    Latitude: "-26.1641",
    Longitude: "27.7754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7228",
    WardLookupId: "1086",
    VDNumber: "33250395",
    RegisteredPopulation: "2142",
    VotingStationName: "LUTHERAN CHURCH MAHURA STREET",
    Address: "1956 MAHURA STREET  KAGISO  KRUGERSDORP",
    Latitude: "-26.161",
    Longitude: "27.7795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7229",
    WardLookupId: "1086",
    VDNumber: "33250889",
    RegisteredPopulation: "1804",
    VotingStationName: "CHIEF MOGALE HALL",
    Address: "GAMOGALE  KAGISO  MOGALE CITY",
    Latitude: "-26.1681",
    Longitude: "27.7775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7230",
    WardLookupId: "1087",
    VDNumber: "33250340",
    RegisteredPopulation: "2161",
    VotingStationName: "BOSELE INTERMEDIATE SCHOOL",
    Address: "1676 THEMBA STREET  KAGISO  KRUGERSDORP",
    Latitude: "-26.1598",
    Longitude: "27.7796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7231",
    WardLookupId: "1087",
    VDNumber: "33250418",
    RegisteredPopulation: "2018",
    VotingStationName: "BOIPELO SECONDARY SCHOOL",
    Address: "9688 THEMBA STREET  KAGISO  KRUGERSDORP",
    Latitude: "-26.1582",
    Longitude: "27.7748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7232",
    WardLookupId: "1088",
    VDNumber: "33250351",
    RegisteredPopulation: "1724",
    VotingStationName: "MOSUPATSELA SENIOR SECONDARY SCHOOL",
    Address: "9080 SEBENZISA STREET  KAGISO  KRUGERSDORP",
    Latitude: "-26.1551",
    Longitude: "27.7771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7233",
    WardLookupId: "1088",
    VDNumber: "33250362",
    RegisteredPopulation: "2231",
    VotingStationName: "SG MAFAESA SENIOR SECONDARY SCHOOL",
    Address: "5921 MOROSI STREET  KAGISO  KRUGERSDORP",
    Latitude: "-26.1529",
    Longitude: "27.7681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7234",
    WardLookupId: "1088",
    VDNumber: "33250733",
    RegisteredPopulation: "1922",
    VotingStationName: "EVANGELICAL REFORMED CHURCH",
    Address: "KAGISO EXT 8  KAGISO  MOGALE CITY",
    Latitude: "-26.1478",
    Longitude: "27.7719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7235",
    WardLookupId: "1089",
    VDNumber: "33250542",
    RegisteredPopulation: "3484",
    VotingStationName: "SINQOBILE CRECHE",
    Address: "TEMP STREET  SINQOBILE  MOGALE",
    Latitude: "-26.1442",
    Longitude: "27.7852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7236",
    WardLookupId: "1089",
    VDNumber: "33250687",
    RegisteredPopulation: "440",
    VotingStationName: "LANWEN HALL",
    Address: "MAIN REEF  LANWEN  MOGALE CITY",
    Latitude: "-26.118",
    Longitude: "27.7919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7237",
    WardLookupId: "1089",
    VDNumber: "33250744",
    RegisteredPopulation: "1139",
    VotingStationName: "SINQOBILE PHASE 3 (TENT)",
    Address: "PHASE 3  KAGISO  MOGALE CITY",
    Latitude: "-26.1381",
    Longitude: "27.7898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7238",
    WardLookupId: "1090",
    VDNumber: "33250238",
    RegisteredPopulation: "1738",
    VotingStationName: "JOHN-MARTIN CATHOLIC SCHOOL",
    Address: "462 ESIKOLWENE STREET  KAGISO  KRUGERSDORP",
    Latitude: "-26.142",
    Longitude: "27.8004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7239",
    WardLookupId: "1090",
    VDNumber: "33250250",
    RegisteredPopulation: "1695",
    VotingStationName: "TSHOLOTSEGA PRIMARY SCHOOL",
    Address: "KRUGERSDORP  ",
    Latitude: "-26.1425",
    Longitude: "27.794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7240",
    WardLookupId: "1090",
    VDNumber: "33250812",
    RegisteredPopulation: "1217",
    VotingStationName: "KAGISO 1 HOSTEL OFFICES",
    Address: "KAGISO AVENUE  MOGALE CITY  KAGISO",
    Latitude: "-26.1391",
    Longitude: "27.7931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7241",
    WardLookupId: "1091",
    VDNumber: "33250081",
    RegisteredPopulation: "2437",
    VotingStationName: "JUBILEE HALL",
    Address: "CNR HARVEY & VICTOR  LEWISHAM  KRUGERSDORP",
    Latitude: "-26.1223",
    Longitude: "27.8134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7242",
    WardLookupId: "1091",
    VDNumber: "33250553",
    RegisteredPopulation: "2589",
    VotingStationName: "KAGISO LIVING WATERS BILBLE CHURCH",
    Address: "TUDOR ROAD  SOUL CITY  MOGALE",
    Latitude: "-26.1343",
    Longitude: "27.8014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7243",
    WardLookupId: "1091",
    VDNumber: "33250935",
    RegisteredPopulation: "365",
    VotingStationName: "VINE CHRISTIAN SCHOOL",
    Address: "28 EXCHANGE ROAD  MINDALORE  KRUGERSDORP",
    Latitude: "-26.1287",
    Longitude: "27.8292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7244",
    WardLookupId: "1092",
    VDNumber: "33250148",
    RegisteredPopulation: "2492",
    VotingStationName: "RANT EN DAL KLINIEKSKOOL",
    Address: "94 STERKFONTEINWEG  KRUGERSDORP NORTH  KRUGERSDORP",
    Latitude: "-26.083",
    Longitude: "27.7675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7245",
    WardLookupId: "1092",
    VDNumber: "33250159",
    RegisteredPopulation: "2435",
    VotingStationName: "TOWN VIEW HIGH SCHOOL",
    Address: "SCHOEMAN DRIVE  QUELLERIE PARK  KRUGERSDORP",
    Latitude: "-26.0885",
    Longitude: "27.7589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7246",
    WardLookupId: "1093",
    VDNumber: "33250069",
    RegisteredPopulation: "2757",
    VotingStationName: "LAERSKOOL KENMARE",
    Address: "ENDANWEG  KENMARE  KRUGERSDORP",
    Latitude: "-26.1094",
    Longitude: "27.8099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7247",
    WardLookupId: "1093",
    VDNumber: "33250070",
    RegisteredPopulation: "2847",
    VotingStationName: "SILVERFIELDS PRIMARY SCHOOL",
    Address: "GARRICK STREET  KENMARE  KRUGERSDORP",
    Latitude: "-26.1146",
    Longitude: "27.8182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7248",
    WardLookupId: "1093",
    VDNumber: "33250946",
    RegisteredPopulation: "387",
    VotingStationName: "TENT IN THE OPEN SPACE NEAR BREAUNANDA STREAM",
    Address: "AFRICANA DRIVE  BREAUNANDA  KRUGERSDORP",
    Latitude: "-26.1223",
    Longitude: "27.8345",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7249",
    WardLookupId: "1094",
    VDNumber: "33250339",
    RegisteredPopulation: "2792",
    VotingStationName: "MATLHASEDI PRIMARY SCHOOL",
    Address: "1422 PHILLIP RAMAKOBWA STREET  KAGISO  KRUGERSDORP",
    Latitude: "-26.1555",
    Longitude: "27.7809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7250",
    WardLookupId: "1094",
    VDNumber: "33250531",
    RegisteredPopulation: "618",
    VotingStationName: "KAGISO SPORTS COMPLEX",
    Address: "KAGISO DRIVE  KAGISO  MOGALE CITY",
    Latitude: "-26.1471",
    Longitude: "27.7878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7251",
    WardLookupId: "1094",
    VDNumber: "33250755",
    RegisteredPopulation: "615",
    VotingStationName: "KAGISO PRE SCHOOL",
    Address: "KAGISO  MOGALE CITY  WESTRAND",
    Latitude: "-26.1507",
    Longitude: "27.7867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7252",
    WardLookupId: "1095",
    VDNumber: "33250205",
    RegisteredPopulation: "2259",
    VotingStationName: "HOÃ‹RSKOOL JAN DE KLERK",
    Address: "KERK STRAAT  KRUGERSDORP CENTRAL  KRUGERSDORP",
    Latitude: "-26.0979",
    Longitude: "27.7653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7253",
    WardLookupId: "1095",
    VDNumber: "33250766",
    RegisteredPopulation: "1043",
    VotingStationName: "HOÃ‹RSKOOL MONUMENT",
    Address: "KRUGERSDORP NORTH  KRUGERSDORP  MOGALE CITY",
    Latitude: "-26.0935",
    Longitude: "27.7746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7254",
    WardLookupId: "1095",
    VDNumber: "33250777",
    RegisteredPopulation: "1401",
    VotingStationName: "WESTCOL KRUGERSDORP COLLEGE",
    Address: "KRUGERSDORP CENTRAL  KRUGERSDORP  MOGALE CITY",
    Latitude: "-26.1053",
    Longitude: "27.773",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7255",
    WardLookupId: "1096",
    VDNumber: "33250058",
    RegisteredPopulation: "3720",
    VotingStationName: "HOÃ‹RSKOOL NOORDHEUWEL",
    Address: "BELL STREET  NOORDHEUWEL  KRUGERSDORP",
    Latitude: "-26.0966",
    Longitude: "27.8075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7256",
    WardLookupId: "1096",
    VDNumber: "33250788",
    RegisteredPopulation: "1148",
    VotingStationName: "MOUNT FAITH PRESBYTERIAN CHURCH",
    Address: "19 HANEKON ROAD  NOORHEUWEL  MOGALE CITY",
    Latitude: "-26.0889",
    Longitude: "27.8162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7257",
    WardLookupId: "1096",
    VDNumber: "33250823",
    RegisteredPopulation: "637",
    VotingStationName: "TENT AT PANORAMA",
    Address: "ROBERT BROOM  NOORDHEUWEL  NOORDHEUWEL",
    Latitude: "-26.0937",
    Longitude: "27.8259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7258",
    WardLookupId: "1097",
    VDNumber: "33250047",
    RegisteredPopulation: "2449",
    VotingStationName: "CHURCH OF ASCENSION",
    Address: "KRUGERSDORP  ",
    Latitude: "-26.0976",
    Longitude: "27.835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7259",
    WardLookupId: "1097",
    VDNumber: "33250092",
    RegisteredPopulation: "3120",
    VotingStationName: "NOORDHEUWEL COUNTRY CLUB",
    Address: "C/O PAARDEKRAAL/SNEEUBERGWEG  NOORDHEUWEL  KRUGERSDORP",
    Latitude: "-26.0803",
    Longitude: "27.7911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7260",
    WardLookupId: "1098",
    VDNumber: "33250025",
    RegisteredPopulation: "2053",
    VotingStationName: "THUSANANG PRE-SCHOOL",
    Address: "CLINIC ROAD  MULDERSDRIFT  WESTRAND",
    Latitude: "-26.0144",
    Longitude: "27.8573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7261",
    WardLookupId: "1098",
    VDNumber: "33250610",
    RegisteredPopulation: "1898",
    VotingStationName: "RIETFONTEIN MPCC",
    Address: "141/139 BAYERS NAUDE DRIVE  RIETFONTEIN  MOGALE CITY",
    Latitude: "-26.0147",
    Longitude: "27.8788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7262",
    WardLookupId: "1098",
    VDNumber: "33250621",
    RegisteredPopulation: "1014",
    VotingStationName: "TENT IN THE OPEN SPACE NEAR FOODZONE MULDERSDRIFT",
    Address: "MULDERSDRIFT SPAR ROAD  MULDERSDRIFT  MOGALE CITY - KRUGERSDORP",
    Latitude: "-26.0392",
    Longitude: "27.8448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7263",
    WardLookupId: "1098",
    VDNumber: "33250799",
    RegisteredPopulation: "920",
    VotingStationName: "RIETFONTEIN SUPERMARKET (TENT)",
    Address: "RIETFONTEIN  MULDERSDRIFT  MOGALE CITY",
    Latitude: "-26.032",
    Longitude: "27.8763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7264",
    WardLookupId: "1099",
    VDNumber: "33250126",
    RegisteredPopulation: "1709",
    VotingStationName: "APOSTOLIC CHURCH",
    Address: "1365 SKOSANA STREET  MUNSIEVILLE  MOGALE CITY",
    Latitude: "-26.0764",
    Longitude: "27.7565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7265",
    WardLookupId: "1099",
    VDNumber: "33250137",
    RegisteredPopulation: "1298",
    VotingStationName: "MUNSIEVILLE CLINIC",
    Address: "CORANE STREET  MUNSIEVILLE EXT 2  KRUGERSDORP",
    Latitude: "-26.0745",
    Longitude: "27.7527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7266",
    WardLookupId: "1099",
    VDNumber: "33250586",
    RegisteredPopulation: "2438",
    VotingStationName: "PHATHUDI PRIMARY SCHOOL",
    Address: "MATLABA  MUNSIENVILLE  MOGALE CITY",
    Latitude: "-26.0788",
    Longitude: "27.755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7267",
    WardLookupId: "1100",
    VDNumber: "33250115",
    RegisteredPopulation: "1428",
    VotingStationName: "IKAGENG DAYCARE CENTRE",
    Address: "42 BASHEE STREET  MUNSIEVILLE  MOGALE CITY",
    Latitude: "-26.0732",
    Longitude: "27.7554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7268",
    WardLookupId: "1100",
    VDNumber: "33250520",
    RegisteredPopulation: "1465",
    VotingStationName: "THUTO LEFA SECONDARY SCHOOL",
    Address: "1744 MAFAESA STREET  MUNSIEVILLE  KRUGERSDORP",
    Latitude: "-26.0713",
    Longitude: "27.7595",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7269",
    WardLookupId: "1100",
    VDNumber: "33250801",
    RegisteredPopulation: "1838",
    VotingStationName: "MSHENGUVILLE (TENT)",
    Address: "MSHENGU  MUNSIELVILL  MOGALECITY",
    Latitude: "-26.072",
    Longitude: "27.7526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7270",
    WardLookupId: "1101",
    VDNumber: "33250182",
    RegisteredPopulation: "2233",
    VotingStationName: "PRO-PRACTICUM SKOOL",
    Address: "273 LUIPAARDSTRAAT  BOLTONIA  KRUGERSDORP",
    Latitude: "-26.1022",
    Longitude: "27.7478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7271",
    WardLookupId: "1101",
    VDNumber: "33250249",
    RegisteredPopulation: "2122",
    VotingStationName: "WEST VILLAGE TENT",
    Address: "WEST VILLAGE   WESTRAND  MOGALE CITY",
    Latitude: "-26.1187",
    Longitude: "27.7535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7272",
    WardLookupId: "1101",
    VDNumber: "33310189",
    RegisteredPopulation: "430",
    VotingStationName: "EKUPHILENI - BOPHELONG LUNCHEON CLUB",
    Address: "7 WATERVAL ROAD  FLACKDRIFT  MOGALE CITY",
    Latitude: "-26.0897",
    Longitude: "27.6824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7273",
    WardLookupId: "1102",
    VDNumber: "33250564",
    RegisteredPopulation: "1933",
    VotingStationName: "RENEWAL MINISTRIES FELLOWSHIP CHURCH",
    Address: "2357 JOE SLOVO DRIVE  MUNSIEVILLE  KRUGERSDORP",
    Latitude: "-26.0774",
    Longitude: "27.7499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7274",
    WardLookupId: "1102",
    VDNumber: "33250711",
    RegisteredPopulation: "2725",
    VotingStationName: "MUNSIEVILLE PRIMARY SCHOOL",
    Address: "KAMOGELO  MOGALE CITY  MUNSIEVILLE",
    Latitude: "-26.0668",
    Longitude: "27.7512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7275",
    WardLookupId: "1103",
    VDNumber: "33250014",
    RegisteredPopulation: "4170",
    VotingStationName: "LAERSKOOL MULDERSDRIFT",
    Address: "66 HENDRIK POTGIETER  MULDERSDRIFT  KRUGERSDORP",
    Latitude: "-26.0635",
    Longitude: "27.8333",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7276",
    WardLookupId: "1103",
    VDNumber: "33250867",
    RegisteredPopulation: "1000",
    VotingStationName: "RUIMSIG (TENT)",
    Address: "RUIMSIG  MOGALE CITY",
    Latitude: "-26.0709",
    Longitude: "27.8545",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7277",
    WardLookupId: "1104",
    VDNumber: "33250104",
    RegisteredPopulation: "3064",
    VotingStationName: "KOEDOE PARK (TENT)",
    Address: "KOEDOE  RANT EN DAL  KRUGERSDORP",
    Latitude: "-26.0787",
    Longitude: "27.7697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7278",
    WardLookupId: "1104",
    VDNumber: "33250924",
    RegisteredPopulation: "1048",
    VotingStationName: "OUTBOUND MISSION",
    Address: "27 CLIFFORD ROAD  MOGALE CITY  MOGALE CITY",
    Latitude: "-26.0676",
    Longitude: "27.7953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7279",
    WardLookupId: "1105",
    VDNumber: "33310022",
    RegisteredPopulation: "1862",
    VotingStationName: "NELSON MANDELA HALL",
    Address: "STREET  TARLTON  MOGALE",
    Latitude: "-26.0912",
    Longitude: "27.6682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7280",
    WardLookupId: "1105",
    VDNumber: "33310167",
    RegisteredPopulation: "278",
    VotingStationName: "KWAGGAFONTEIN PRIMARY SCHOOL",
    Address: "STREET  KWAGGAFONTEIN  MOGALE",
    Latitude: "-26.0429",
    Longitude: "27.631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7281",
    WardLookupId: "1105",
    VDNumber: "33310224",
    RegisteredPopulation: "2716",
    VotingStationName: "JESUS IS MY VICTORY GOSPEL MINISTRY",
    Address: "NEXT TO R24 ROAD (RUSTENBURG R  TARLTON  KRUGERSDORP",
    Latitude: "-26.0763",
    Longitude: "27.6547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7282",
    WardLookupId: "1105",
    VDNumber: "33310246",
    RegisteredPopulation: "1066",
    VotingStationName: "SMOKE DOWN (TENT)",
    Address: "PLOT 151 SMOKE DOWN  TARLTON  MOGALE CITY",
    Latitude: "-26.0683",
    Longitude: "27.6262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7283",
    WardLookupId: "1105",
    VDNumber: "33310268",
    RegisteredPopulation: "142",
    VotingStationName: "FLACKDRIFT SHACK HALL",
    Address: "INFORMAL SETTLEMENT  MOGALE CITY  FLACKDRIFT",
    Latitude: "-26.0454",
    Longitude: "27.6762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7284",
    WardLookupId: "1106",
    VDNumber: "33300010",
    RegisteredPopulation: "1962",
    VotingStationName: "MAGALIESBURG COMMUNITY CENTRE",
    Address: "KOSTER/RUSTENBURG ROAD  MAGALIESBURG  MAGALIESBURG",
    Latitude: "-25.9984",
    Longitude: "27.5447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7285",
    WardLookupId: "1106",
    VDNumber: "33310066",
    RegisteredPopulation: "2836",
    VotingStationName: "MPHE THUTO PRIMARY SCHOOL",
    Address: "KOSTER ROAD  MOGALE CITY  MAGALIESBURG",
    Latitude: "-25.9966",
    Longitude: "27.5403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7286",
    WardLookupId: "1106",
    VDNumber: "33310178",
    RegisteredPopulation: "865",
    VotingStationName: "ORIENT HILLS DAY CARE",
    Address: "ORIENT HILLS  TARLTON  MOGALE CITY",
    Latitude: "-26.031",
    Longitude: "27.6047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7287",
    WardLookupId: "1107",
    VDNumber: "33300021",
    RegisteredPopulation: "185",
    VotingStationName: "BOYS/GIRLS TOWN SCHOOL",
    Address: "KRUITFONTEIN FARM  MAGALIESBURG  MOGALE CITY",
    Latitude: "-25.9844",
    Longitude: "27.5448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7288",
    WardLookupId: "1107",
    VDNumber: "33310011",
    RegisteredPopulation: "667",
    VotingStationName: "HOÃ‹RSKOOL BEKKER",
    Address: "ROAD 96 ZEEKOEHOEK  BEKKER  MAGALIESBURG",
    Latitude: "-25.9503",
    Longitude: "27.557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7289",
    WardLookupId: "1107",
    VDNumber: "33310033",
    RegisteredPopulation: "2269",
    VotingStationName: "HEKPOORT RAID CENTRE",
    Address: "P123-1 HEKPOORT  MAGALIESBURG  MAGALIESBURG",
    Latitude: "-25.8963",
    Longitude: "27.6014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7290",
    WardLookupId: "1107",
    VDNumber: "33310190",
    RegisteredPopulation: "405",
    VotingStationName: "RAND GOLD PRIMARY SCHOOL",
    Address: "77/7 RUSTENBURG ROAD  MAGALIESBURG  MOGALE CITY",
    Latitude: "-25.9412",
    Longitude: "27.4912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7291",
    WardLookupId: "1107",
    VDNumber: "33310213",
    RegisteredPopulation: "226",
    VotingStationName: "MAROPENG EXHIBITION CENTRE",
    Address: "(GT481 - MOGALE CITY [KRUGERSDORP])   ",
    Latitude: "-25.9635",
    Longitude: "27.662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7292",
    WardLookupId: "1107",
    VDNumber: "33310235",
    RegisteredPopulation: "354",
    VotingStationName: "DIE POORT PRIMARY SCHOOL",
    Address: "390 HERKPOORT  472JQ HARTEBEESFONTEIN  MOGALE CITY",
    Latitude: "-25.8725",
    Longitude: "27.6492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7293",
    WardLookupId: "1108",
    VDNumber: "33250676",
    RegisteredPopulation: "364",
    VotingStationName: "SWARTKOP VALLEY PRIMARY SCHOOL",
    Address: "SWARTKOP  MOGALE CITY",
    Latitude: "-25.9891",
    Longitude: "27.8467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7294",
    WardLookupId: "1108",
    VDNumber: "33280073",
    RegisteredPopulation: "1312",
    VotingStationName: "TENT AT RHENOSTERSPRUIT COMPLEX",
    Address: "R512  RHENOSTERSPRUIT  STERKFONTEIN",
    Latitude: "-25.8947",
    Longitude: "27.8819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7295",
    WardLookupId: "1108",
    VDNumber: "33310044",
    RegisteredPopulation: "1430",
    VotingStationName: "LAERSKOOL NOOITGEDACHT",
    Address: "P158-2 NOOITGEDACHT  NOOITGEDACHT  KRUGERSDORP",
    Latitude: "-26.0017",
    Longitude: "27.9217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7296",
    WardLookupId: "1108",
    VDNumber: "33310156",
    RegisteredPopulation: "644",
    VotingStationName: "ST ANSGARS SCHOOL",
    Address: "ROAD  LANSERIA  MOGALE",
    Latitude: "-25.9329",
    Longitude: "27.9013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7297",
    WardLookupId: "1108",
    VDNumber: "33310202",
    RegisteredPopulation: "548",
    VotingStationName: "LANSERIA SHOPPING CENTRE",
    Address: "R512 PELINDABA RD  NOOITGEDACHT  MOGALE CITY",
    Latitude: "-25.975",
    Longitude: "27.9231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7298",
    WardLookupId: "1108",
    VDNumber: "33310257",
    RegisteredPopulation: "408",
    VotingStationName: "LAMMERMOOR DWELLINGS (TENT)",
    Address: "PLOT 83 CNR SHERWOOD DRIVE & MOUNTAIN   SWAARTKOP  MOGALE CITY",
    Latitude: "-25.9768",
    Longitude: "27.8752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7299",
    WardLookupId: "1109",
    VDNumber: "33250474",
    RegisteredPopulation: "2272",
    VotingStationName: "RIETVALLEI EXTENSION 3 PRIMARY SCHOOL",
    Address: "6060 TENTH STREET  KAGISO EXT 3  KRUGERSDORP",
    Latitude: "-26.1971",
    Longitude: "27.7444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7300",
    WardLookupId: "1109",
    VDNumber: "33250654",
    RegisteredPopulation: "3339",
    VotingStationName: "PATRICK MASHEGO  PRIMARY SCHOOL",
    Address: "3722 EIGHT STREET  RIETVALLEI EXT 2  MOGALE CITY [KRUGERSDORP]",
    Latitude: "-26.1881",
    Longitude: "27.7487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7301",
    WardLookupId: "1110",
    VDNumber: "33250496",
    RegisteredPopulation: "2631",
    VotingStationName: "KHULULEKANI PRIMARY SCHOOL",
    Address: "ST LAWRENCE STREET  RIETVALLEI  KRUGERSDORP",
    Latitude: "-26.1959",
    Longitude: "27.7687",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7302",
    WardLookupId: "1110",
    VDNumber: "33250722",
    RegisteredPopulation: "2403",
    VotingStationName: "RIETVALLEI EXTENSION 5 SECONDARY SCHOOL",
    Address: "6491 KINGFISHER  KAGISO EXT 5  KRUGERSDORP",
    Latitude: "-26.2044",
    Longitude: "27.7735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7303",
    WardLookupId: "1111",
    VDNumber: "33250597",
    RegisteredPopulation: "963",
    VotingStationName: "KAGISO IAG",
    Address: "19320 PAKISA STREET  KAGISO EXT 9  MOGALE CITY",
    Latitude: "-26.1716",
    Longitude: "27.7798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7304",
    WardLookupId: "1111",
    VDNumber: "33250643",
    RegisteredPopulation: "1987",
    VotingStationName: "THUTO-PELE SECONDARY SCHOOL",
    Address: "18800 REBECCA TLHARIPE & LETLHABILE  KAGISO EXT 14  MOGALE CITY",
    Latitude: "-26.1735",
    Longitude: "27.7838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7305",
    WardLookupId: "1111",
    VDNumber: "33250913",
    RegisteredPopulation: "1303",
    VotingStationName: "ASSEMBLY CHRISTIAN CENTRE",
    Address: "FOURTH STREET  RIETVALLEI  MOGALE CITY",
    Latitude: "-26.1846",
    Longitude: "27.7589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7306",
    WardLookupId: "1112",
    VDNumber: "33250171",
    RegisteredPopulation: "2724",
    VotingStationName: "MONUMENT PRIMARY SCHOOL",
    Address: "PIET JOUBERT STREET  MONUMENT  KRUGERSDORP",
    Latitude: "-26.0955",
    Longitude: "27.7891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7307",
    WardLookupId: "1112",
    VDNumber: "33250216",
    RegisteredPopulation: "2325",
    VotingStationName: "WESRAND SKOOL",
    Address: "JOHAN JONKERRYLAAN  WENTWORTH PARK  KRUGERSDORP",
    Latitude: "-26.1082",
    Longitude: "27.79",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7308",
    WardLookupId: "1113",
    VDNumber: "33250160",
    RegisteredPopulation: "1928",
    VotingStationName: "TECHNICAL COLLEGE, WEST CAMPUS",
    Address: "FIGULLUS STREET  KRUGERSDORP WEST  KRUGERSDORP",
    Latitude: "-26.0912",
    Longitude: "27.7487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7309",
    WardLookupId: "1113",
    VDNumber: "33250193",
    RegisteredPopulation: "1997",
    VotingStationName: "BURGERSHOOP COMMUNITY CENTRE",
    Address: "LAGOI STREET  BURGERSHOOP  KRUGERSDORP",
    Latitude: "-26.0988",
    Longitude: "27.7557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7310",
    WardLookupId: "1113",
    VDNumber: "33250845",
    RegisteredPopulation: "995",
    VotingStationName: "LAERSKOOL EBENHAESER",
    Address: "7 DELPORT AVE  KRUGERSDORP WEST  KRUGERSDORP",
    Latitude: "-26.0984",
    Longitude: "27.7426",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7311",
    WardLookupId: "1114",
    VDNumber: "33250036",
    RegisteredPopulation: "2487",
    VotingStationName: "PINEHAVEN WEIGH BRIDGE TENT",
    Address: "14 CNR STEYN ROAD AND N14  PINEHAVEN  KRUGERSDORP",
    Latitude: "-26.0531",
    Longitude: "27.8143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7312",
    WardLookupId: "1114",
    VDNumber: "33250609",
    RegisteredPopulation: "525",
    VotingStationName: "LESEGO PRIMARY SCHOOL",
    Address: "DRIFONTEIN ROAD  MULDERSDRIFT  MOGALE",
    Latitude: "-26.0321",
    Longitude: "27.8403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7313",
    WardLookupId: "1114",
    VDNumber: "33250834",
    RegisteredPopulation: "482",
    VotingStationName: "LAERSKOOL PROTEARIF",
    Address: "STERKFONTEINWEG  KRUGERSDORP  MOGALE CITY",
    Latitude: "-26.0591",
    Longitude: "27.7572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7314",
    WardLookupId: "1114",
    VDNumber: "33250856",
    RegisteredPopulation: "439",
    VotingStationName: "IMAGES OF AFRICA (TENT)",
    Address: "PLOT 26 N14  KROMDRAAI  PROTEA RIFF",
    Latitude: "-26.035",
    Longitude: "27.7584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7315",
    WardLookupId: "1114",
    VDNumber: "33250890",
    RegisteredPopulation: "581",
    VotingStationName: "CRADLE FOOD MARKET",
    Address: "PLOT 68 R563 HERKPOORT ROAD  STERKFONTEIN   KRUGERSDORP",
    Latitude: "-26.0252",
    Longitude: "27.7252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7316",
    WardLookupId: "1114",
    VDNumber: "33310055",
    RegisteredPopulation: "1099",
    VotingStationName: "MATLA COMBINED SCHOOL",
    Address: "R540 TURN OFF KROMDRAAI  KRUGERSDORP  KRUGERSDORP",
    Latitude: "-25.9918",
    Longitude: "27.7864",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7317",
    WardLookupId: "1115",
    VDNumber: "33240350",
    RegisteredPopulation: "2103",
    VotingStationName: "RELEBOGILE SECONDARY",
    Address: "KHOTSONG   KHUTSONG SOUTH  MERAFONG",
    Latitude: "-26.3473",
    Longitude: "27.3307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7318",
    WardLookupId: "1115",
    VDNumber: "33290018",
    RegisteredPopulation: "516",
    VotingStationName: "DIN-GLO SCHOOL",
    Address: "FARM DE PAN  CARLETONVILLE  CARLETONVILLE",
    Latitude: "-26.2288",
    Longitude: "27.4268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7319",
    WardLookupId: "1115",
    VDNumber: "33290029",
    RegisteredPopulation: "691",
    VotingStationName: "ROOIPOORT FARM SCHOOL.",
    Address: "R500 ROOIPOORT  CARLETONVILLE",
    Latitude: "-26.3124",
    Longitude: "27.3795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7320",
    WardLookupId: "1115",
    VDNumber: "33290108",
    RegisteredPopulation: "1880",
    VotingStationName: "KHUTSONG EXT5 LIBRARY",
    Address: "KHUTSONG EXT 5  KHUTSONG SOUTH EXT5  CARLETONVILLE",
    Latitude: "-26.3581",
    Longitude: "27.344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7321",
    WardLookupId: "1116",
    VDNumber: "33240338",
    RegisteredPopulation: "3175",
    VotingStationName: "KHUTSONG SOUTH PRIMARY SCHOOL",
    Address: "NALEDI STREET  KHUTSONG SOUTH EXT 4  CARLETONVILLE",
    Latitude: "-26.353",
    Longitude: "27.322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7322",
    WardLookupId: "1116",
    VDNumber: "33240439",
    RegisteredPopulation: "1662",
    VotingStationName: "KHUTSONG S SPORTS GROUNDS (TENT)",
    Address: "KHUTSONG SOUTH EXT 4  CARLETONVILLE",
    Latitude: "-26.3564",
    Longitude: "27.3279",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7323",
    WardLookupId: "1117",
    VDNumber: "33240158",
    RegisteredPopulation: "1244",
    VotingStationName: "TRINITY PRE-SCHOOL",
    Address: "6314 MOLEFE STREET  KHUTSONG EXT.3  CARLETONVILLE",
    Latitude: "-26.3361",
    Longitude: "27.3088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7324",
    WardLookupId: "1117",
    VDNumber: "33240204",
    RegisteredPopulation: "1447",
    VotingStationName: "RIVONIA & T SECTION (TENT)",
    Address: "RIVONIA INFORMAL SETTLEMENT  KHUTSONG  CARLETONVILLE",
    Latitude: "-26.3307",
    Longitude: "27.3107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7325",
    WardLookupId: "1118",
    VDNumber: "33240169",
    RegisteredPopulation: "1339",
    VotingStationName: "JOE SLOVO (TENT)",
    Address: "JOE SLOVO INFORMAL AREA  KHUTSONG  CARLETONVILLE",
    Latitude: "-26.3382",
    Longitude: "27.302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7326",
    WardLookupId: "1118",
    VDNumber: "33240170",
    RegisteredPopulation: "1091",
    VotingStationName: "CHRIS HANI (TENT)",
    Address: "CHRIS HANI INFORMAL AREA  KHUTSONG  CARLETONVILLE",
    Latitude: "-26.3335",
    Longitude: "27.3045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7327",
    WardLookupId: "1118",
    VDNumber: "33240383",
    RegisteredPopulation: "895",
    VotingStationName: "MUNIC CLINIC",
    Address: "",
    Latitude: "-26.3417",
    Longitude: "27.302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7328",
    WardLookupId: "1119",
    VDNumber: "33240226",
    RegisteredPopulation: "571",
    VotingStationName: "WEST DRIE CHURCH",
    Address: "NO 2 HOSTEL  WEST DRIEFONTEIN  CARLETONVILLE",
    Latitude: "-26.3928",
    Longitude: "27.4267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7329",
    WardLookupId: "1119",
    VDNumber: "33240394",
    RegisteredPopulation: "1637",
    VotingStationName: "BLYVOOR LAERSKOOL",
    Address: "ROUX STREET  BLYVOORUITSIG  CARLETONVILLE",
    Latitude: "-26.3993",
    Longitude: "27.3818",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7330",
    WardLookupId: "1119",
    VDNumber: "33240428",
    RegisteredPopulation: "256",
    VotingStationName: "DOORNFONTEIN PIT STOP GOLF CLUB",
    Address: "DOORNFONTEIN R501  DOORNFONTEIN  CARLETONVILLE",
    Latitude: "-26.3937",
    Longitude: "27.3245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7331",
    WardLookupId: "1120",
    VDNumber: "33240192",
    RegisteredPopulation: "1310",
    VotingStationName: "TSWASONGO HIGH SCHOOL",
    Address: "5007 RAPHALI STREET  KHUTSONG  CARLETONVILLE",
    Latitude: "-26.3374",
    Longitude: "27.3206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7332",
    WardLookupId: "1120",
    VDNumber: "33240215",
    RegisteredPopulation: "1853",
    VotingStationName: "ABC CHURCH  (TENT)",
    Address: "ERF 7399  HKUTSONG  CARLETONVILLE",
    Latitude: "-26.3289",
    Longitude: "27.3158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7333",
    WardLookupId: "1121",
    VDNumber: "33240080",
    RegisteredPopulation: "2156",
    VotingStationName: "MBULELO PRIMARY SCHOOL",
    Address: "4699 MPHATA STREET  KHUTSONG  CARLETONVILLE",
    Latitude: "-26.3335",
    Longitude: "27.3235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7334",
    WardLookupId: "1121",
    VDNumber: "33240181",
    RegisteredPopulation: "1343",
    VotingStationName: "HLANGABEZA PRIMARY SCHOOL",
    Address: "NXUMALO STREET  KHUTSONG  CARLETONVILLE",
    Latitude: "-26.3269",
    Longitude: "27.3239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7335",
    WardLookupId: "1122",
    VDNumber: "33240068",
    RegisteredPopulation: "1004",
    VotingStationName: "SONDERWATER CEMETRY (TENT)",
    Address: "SONDERWATER CEMETRY  KHUTSONG  CARLETONVILLE",
    Latitude: "-26.3244",
    Longitude: "27.3141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7336",
    WardLookupId: "1122",
    VDNumber: "33240103",
    RegisteredPopulation: "1314",
    VotingStationName: "CUSHION CHURCH",
    Address: "4507 KHUTSONG  CARLETONVILLE",
    Latitude: "-26.3262",
    Longitude: "27.3213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7337",
    WardLookupId: "1122",
    VDNumber: "33240406",
    RegisteredPopulation: "572",
    VotingStationName: "ZCC CHURCH",
    Address: "KHUTSONG  CARLETONVILLE",
    Latitude: "-26.3274",
    Longitude: "27.3172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7338",
    WardLookupId: "1123",
    VDNumber: "33240147",
    RegisteredPopulation: "1552",
    VotingStationName: "REFORMED CHURCH",
    Address: "258 KHUTSONG  CARLETONVILLE",
    Latitude: "-26.3192",
    Longitude: "27.3207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7339",
    WardLookupId: "1123",
    VDNumber: "33240237",
    RegisteredPopulation: "875",
    VotingStationName: "CHIAWELO (TENT)",
    Address: "CHIAWELO INFORMAL AREA  KHUTSONG  CARLETONVILLE",
    Latitude: "-26.3162",
    Longitude: "27.3207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7340",
    WardLookupId: "1124",
    VDNumber: "33240125",
    RegisteredPopulation: "1798",
    VotingStationName: "ITUMELENG EDUCARE CENTRE",
    Address: "2136 KHUTSONG  CARLETONVILLE",
    Latitude: "-26.3211",
    Longitude: "27.3247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7341",
    WardLookupId: "1124",
    VDNumber: "33240248",
    RegisteredPopulation: "823",
    VotingStationName: "KAMOHELO PRIMARY SCHOOL",
    Address: "4502 KHUTSONG  CARLETONVILLE",
    Latitude: "-26.3191",
    Longitude: "27.3266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7342",
    WardLookupId: "1124",
    VDNumber: "33240507",
    RegisteredPopulation: "619",
    VotingStationName: "ANGLICAN CHURCH",
    Address: "BATSWANENG  KHUTSONG LOCATION  CARLETONVILLE",
    Latitude: "-26.3163",
    Longitude: "27.3232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7343",
    WardLookupId: "1125",
    VDNumber: "33240259",
    RegisteredPopulation: "1825",
    VotingStationName: "ELANDSRAND VILLAGE COMMUNITY HALL",
    Address: "SILKAATS DRIVE  ELANDSRAND  ",
    Latitude: "-26.4457",
    Longitude: "27.3708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7344",
    WardLookupId: "1125",
    VDNumber: "86950042",
    RegisteredPopulation: "1074",
    VotingStationName: "WEDELA PRIMARY SCHOOL",
    Address: "507 THIRD AVENUE  WEDELA  CARLETONVILLE",
    Latitude: "-26.46",
    Longitude: "27.3844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7345",
    WardLookupId: "1126",
    VDNumber: "33240114",
    RegisteredPopulation: "1412",
    VotingStationName: "DE BEER PRIMARY SCHOOL",
    Address: "C/O 16DE STRAAT & ROSELAAN  WELVERDIEND  CARLETONVILLE",
    Latitude: "-26.3746",
    Longitude: "27.2824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7346",
    WardLookupId: "1126",
    VDNumber: "33240327",
    RegisteredPopulation: "702",
    VotingStationName: "DEELKRAAL VILLAGE RECREATION CLUB",
    Address: "RECREATION CLUB  DEELKRAAL VILLAGE  ",
    Latitude: "-26.4587",
    Longitude: "27.32",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7347",
    WardLookupId: "1126",
    VDNumber: "33240518",
    RegisteredPopulation: "1173",
    VotingStationName: "EXT 5 WARD 12 (TENT)",
    Address: "KHUTSONG SOUTH EXT5  CARLETONVILLE",
    Latitude: "-26.3615",
    Longitude: "27.3341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7348",
    WardLookupId: "1126",
    VDNumber: "33240530",
    RegisteredPopulation: "1037",
    VotingStationName: "TENT AT KHUTSONG EXT 5 SECTION 70 HOME BASED CARE",
    Address: "SECTION 70  KHUTSONG EXT 5  CARLETONVILLE",
    Latitude: "-26.3752",
    Longitude: "27.3049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7349",
    WardLookupId: "1126",
    VDNumber: "33240541",
    RegisteredPopulation: "374",
    VotingStationName: "TENT AT KHUTSONG EXT 5 SECTION 60",
    Address: "SECTION 60  KHUTSONG EXT 5  CARLETONVILLE",
    Latitude: "-26.3663",
    Longitude: "27.3212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7350",
    WardLookupId: "1127",
    VDNumber: "33240305",
    RegisteredPopulation: "1291",
    VotingStationName: "LETSATSING INTERMEDIATE SCHOOL",
    Address: "1 LETSATSI DRIVE  LETSATSING VILLAGE  CARLETONVILLE",
    Latitude: "-26.3711",
    Longitude: "27.459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7351",
    WardLookupId: "1127",
    VDNumber: "33240361",
    RegisteredPopulation: "2332",
    VotingStationName: "BLYBANK LIBRARY (TENT)",
    Address: "DOVE STREET  BLYBANK   CARLETONVILLE",
    Latitude: "-26.3404",
    Longitude: "27.4732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7352",
    WardLookupId: "1128",
    VDNumber: "33240473",
    RegisteredPopulation: "242",
    VotingStationName: "WESTWITS RECREATION CENTRE",
    Address: "SAVUKA  CARLETONVILLE",
    Latitude: "-26.4257",
    Longitude: "27.4117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7353",
    WardLookupId: "1128",
    VDNumber: "86820723",
    RegisteredPopulation: "1212",
    VotingStationName: "SOUTH MINE HOSTEL WESTERN DEEP LEVELS",
    Address: "SOUTH MINE  WESTERN DEEP LEVELS MINE  FOCHVILLE",
    Latitude: "-26.4406",
    Longitude: "27.4267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7354",
    WardLookupId: "1128",
    VDNumber: "86821229",
    RegisteredPopulation: "304",
    VotingStationName: "TENT AT MOHALES HOEK",
    Address: "R 500 ROAD  MOHALES HOEK INFORMAL SETTLEMENT  CARLETONVILLE",
    Latitude: "-26.4175",
    Longitude: "27.437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7355",
    WardLookupId: "1128",
    VDNumber: "86850029",
    RegisteredPopulation: "2301",
    VotingStationName: "FOCHVILLE CIVIC CENTRE",
    Address: "FRONEMAN STR  FOCHVILLE  FOCHVILLE",
    Latitude: "-26.4769",
    Longitude: "27.4958",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7356",
    WardLookupId: "1129",
    VDNumber: "33240079",
    RegisteredPopulation: "2295",
    VotingStationName: "EAST DRIE PRIMARY SCHOOL",
    Address: "EAST DRIE  EAST DRIEFONTEIN VILLAGE  CARLETONVILLE",
    Latitude: "-26.3872",
    Longitude: "27.5144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7357",
    WardLookupId: "1129",
    VDNumber: "86821162",
    RegisteredPopulation: "877",
    VotingStationName: "EAST DRIE VILLAGE 1 SHOP",
    Address: "EASTDRIEFONTEIN  DRIEFONTEIN  CARLETONVILLE",
    Latitude: "-26.4002",
    Longitude: "27.5103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7358",
    WardLookupId: "1129",
    VDNumber: "86821173",
    RegisteredPopulation: "366",
    VotingStationName: "EAST DRIE VILLAGE 2 MOOLMAN HOUSE",
    Address: "EAST DRIEFONTEIN  DRIEFONTEIN  CARLETONVILLE",
    Latitude: "-26.4095",
    Longitude: "27.5033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7359",
    WardLookupId: "1130",
    VDNumber: "33240046",
    RegisteredPopulation: "2528",
    VotingStationName: "CARLETONVILLE HOÃ‹RSKOOL",
    Address: "63-121 DOLOMITE STR  CARLETONVILLE",
    Latitude: "-26.3602",
    Longitude: "27.3916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7360",
    WardLookupId: "1130",
    VDNumber: "33240440",
    RegisteredPopulation: "1046",
    VotingStationName: "CARLTON JONES HIGH SCHOOL",
    Address: "CARLETONVILLE  ",
    Latitude: "-26.3678",
    Longitude: "27.4037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7361",
    WardLookupId: "1131",
    VDNumber: "33240013",
    RegisteredPopulation: "3333",
    VotingStationName: "MAGISTRATE COURT",
    Address: "VAN ZYL SMIT STR  OBERHOLZER  CARLETONVILLE",
    Latitude: "-26.3487",
    Longitude: "27.3901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7362",
    WardLookupId: "1132",
    VDNumber: "33240057",
    RegisteredPopulation: "2571",
    VotingStationName: "CARLETONVILLE CIVIC CENTRE",
    Address: "1 CELESTINE STR  CARLETONVILLE",
    Latitude: "-26.3566",
    Longitude: "27.4013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7363",
    WardLookupId: "1132",
    VDNumber: "33240484",
    RegisteredPopulation: "1039",
    VotingStationName: "DANIE THERON LAERSKOOL",
    Address: "LIGNITE  CARLETONVILLE CENTRAL  CARLETONVILLE",
    Latitude: "-26.3602",
    Longitude: "27.4048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7364",
    WardLookupId: "1133",
    VDNumber: "33240316",
    RegisteredPopulation: "1815",
    VotingStationName: "EAST DRIEFONTEIN HOSTEL",
    Address: "HOSTEL COMPLEX  EAST DRIEFONTEIN  CARLETONVILLE",
    Latitude: "-26.389",
    Longitude: "27.4965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7365",
    WardLookupId: "1133",
    VDNumber: "33240529",
    RegisteredPopulation: "294",
    VotingStationName: "EAST DRIE SKOMPLAAS CHURCH HALL",
    Address: "EAST DRIE SKOMPLAAS  CARLETONVILLE  EASTDRIEFONTEIN MINE",
    Latitude: "-26.3856",
    Longitude: "27.4962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7366",
    WardLookupId: "1134",
    VDNumber: "86950031",
    RegisteredPopulation: "1851",
    VotingStationName: "WEDELA TECHNICAL HIGH SCHOOL",
    Address: "2604 FOURTH AVENUE  WEDELA  WEDELA",
    Latitude: "-26.4644",
    Longitude: "27.3805",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7367",
    WardLookupId: "1134",
    VDNumber: "86950053",
    RegisteredPopulation: "2216",
    VotingStationName: "EXT3 WEDELA LIBRARY",
    Address: "ALFRED KOBI STREET  WEDELA  CARLETONVILLE",
    Latitude: "-26.4689",
    Longitude: "27.3787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7368",
    WardLookupId: "1135",
    VDNumber: "86820576",
    RegisteredPopulation: "167",
    VotingStationName: "LABOUR RATE CENTRE",
    Address: "4 CORNER LEEUWPOORT AND KLOOF  WELTEVREDEN   FOCHVILLE",
    Latitude: "-26.4915",
    Longitude: "27.5409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7369",
    WardLookupId: "1135",
    VDNumber: "86850018",
    RegisteredPopulation: "2820",
    VotingStationName: "LAERSKOOL FOCHVILLE",
    Address: "33 SEVENTH STREET  FOCHVILLE  FOCHVILLE",
    Latitude: "-26.4842",
    Longitude: "27.4933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7370",
    WardLookupId: "1135",
    VDNumber: "86850096",
    RegisteredPopulation: "1509",
    VotingStationName: "GREENSPARK PRIMARY SCHOOL",
    Address: "GREENSPARK   CARLETONVILLE",
    Latitude: "-26.4927",
    Longitude: "27.5128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7371",
    WardLookupId: "1136",
    VDNumber: "86820532",
    RegisteredPopulation: "284",
    VotingStationName: "TVS (KAALPLATS)",
    Address: "KAALPLAATS  MERAFONG CITY  FOCHVILLE",
    Latitude: "-26.5448",
    Longitude: "27.3351",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7372",
    WardLookupId: "1136",
    VDNumber: "86820565",
    RegisteredPopulation: "174",
    VotingStationName: "EIGENAARSFONTEIN HALL",
    Address: "FARM  GOEDGEDACHT 408IQ  FOCHVILLE",
    Latitude: "-26.6074",
    Longitude: "27.4014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7373",
    WardLookupId: "1136",
    VDNumber: "86821252",
    RegisteredPopulation: "521",
    VotingStationName: "TENT AT KOKOSI EXT.6 GADAFFI STREET",
    Address: "GADAFFI STREET  KOKOSI  FOCHVILLE",
    Latitude: "-26.5161",
    Longitude: "27.4694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7374",
    WardLookupId: "1136",
    VDNumber: "86850052",
    RegisteredPopulation: "2651",
    VotingStationName: "ASSEMBLIES OF GOD CHURCH",
    Address: "MOONO STREET  KOKOSI  FOCHVILLE",
    Latitude: "-26.5097",
    Longitude: "27.4593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7375",
    WardLookupId: "1136",
    VDNumber: "86850119",
    RegisteredPopulation: "492",
    VotingStationName: "TENT AT KOKOSI 99 SECTION",
    Address: "MOKOENA  KOKOSI  FOCHVILLE",
    Latitude: "-26.5133",
    Longitude: "27.4644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7376",
    WardLookupId: "1137",
    VDNumber: "86950019",
    RegisteredPopulation: "2660",
    VotingStationName: "XHOBANI PRIMARY SCHOOL",
    Address: "1367/8 HLOBO STR  WEDELA  WEDELA",
    Latitude: "-26.4624",
    Longitude: "27.3875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7377",
    WardLookupId: "1137",
    VDNumber: "86950020",
    RegisteredPopulation: "1022",
    VotingStationName: "WEDELA COMMUNITY HALL",
    Address: "1340 FIRST AVENUE  WEDELA  WEDELA",
    Latitude: "-26.4619",
    Longitude: "27.3851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7378",
    WardLookupId: "1138",
    VDNumber: "86850063",
    RegisteredPopulation: "1809",
    VotingStationName: "KOKOSI COMMUNITY HALL",
    Address: "BEN SHIBURI DRV  KOKOSI  FOCHVILLE",
    Latitude: "-26.4933",
    Longitude: "27.4683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7379",
    WardLookupId: "1138",
    VDNumber: "86850085",
    RegisteredPopulation: "1313",
    VotingStationName: "LOSBERG PRIMARY SCHOOL",
    Address: "33 BOSMAN STR  FOCHVILLE  FOCHVILLE",
    Latitude: "-26.4975",
    Longitude: "27.4916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7380",
    WardLookupId: "1139",
    VDNumber: "86850041",
    RegisteredPopulation: "1388",
    VotingStationName: "REAKGONA PRIMARY SCHOOL",
    Address: "763 MOLUBI STR  KOKSI EXT 1  FOCHVILLE",
    Latitude: "-26.5041",
    Longitude: "27.461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7381",
    WardLookupId: "1139",
    VDNumber: "86850074",
    RegisteredPopulation: "1722",
    VotingStationName: "RETLILE PRIMARY SCHOOL",
    Address: "2401 MBEKI STREET  KOKOSI  FOCHVILLE",
    Latitude: "-26.5063",
    Longitude: "27.4693",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7382",
    WardLookupId: "1140",
    VDNumber: "86850030",
    RegisteredPopulation: "3761",
    VotingStationName: "IMFUNDU SCHOOL",
    Address: "ERF 3982  KOKOSI  FOCHVILLE",
    Latitude: "-26.5103",
    Longitude: "27.4697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7383",
    WardLookupId: "1141",
    VDNumber: "33240271",
    RegisteredPopulation: "1215",
    VotingStationName: "ROCKLANDS SCHOOL",
    Address: "C/O TOWER & 7 TH AVENUE  BLYVOORUITZICHT  CARLETONVILLE",
    Latitude: "-26.403",
    Longitude: "27.3823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7384",
    WardLookupId: "1141",
    VDNumber: "33240293",
    RegisteredPopulation: "1151",
    VotingStationName: "NO 9 HOSTEL HALL",
    Address: "9 HOSTEL  WESTERN DEEP MINES  CARLETONVILLE",
    Latitude: "-26.4122",
    Longitude: "27.4162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7385",
    WardLookupId: "1141",
    VDNumber: "33240349",
    RegisteredPopulation: "512",
    VotingStationName: "SAVUKA HOSTEL WESTERN DEEP LEVELS",
    Address: "NO 2 HOSTEL  WESTERN DEEP LEVELS  CARLETONVILLE",
    Latitude: "-26.4245",
    Longitude: "27.4028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7386",
    WardLookupId: "1142",
    VDNumber: "33240035",
    RegisteredPopulation: "1791",
    VotingStationName: "WONDERFONTEIN HOÃ‹RSKOOL",
    Address: "13-19 VEGKOP STR  CARLETONVILLE  CARLETONVILLE",
    Latitude: "-26.3571",
    Longitude: "27.3719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7387",
    WardLookupId: "1142",
    VDNumber: "33240372",
    RegisteredPopulation: "1336",
    VotingStationName: "JONGSPAN SCHOOL",
    Address: "",
    Latitude: "-26.3671",
    Longitude: "27.3835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7388",
    WardLookupId: "1142",
    VDNumber: "33240451",
    RegisteredPopulation: "1020",
    VotingStationName: "OBERHOLZER PARK (TENT)",
    Address: "MERAFONG CITY  OBERHOLZER  CARLETONVILLE",
    Latitude: "-26.342",
    Longitude: "27.3851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7389",
    WardLookupId: "3524",
    VDNumber: "33290030",
    RegisteredPopulation: "3318",
    VotingStationName: "ITHUTENG SECONDARY SCHOOL",
    Address: "16 PORTION ,N14 VENTERSDORP ROAD  BRANDVLEI  FARM BRANDVLEI",
    Latitude: "-26.1386",
    Longitude: "27.5866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7390",
    WardLookupId: "3524",
    VDNumber: "33290052",
    RegisteredPopulation: "554",
    VotingStationName: "THUTOBOKAMOSO PRIMARY SCHOOL",
    Address: "15 RODORA ROAD  DOORNFONTEIN  RANDFONTEIN",
    Latitude: "-26.2169",
    Longitude: "27.5047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7391",
    WardLookupId: "3525",
    VDNumber: "33260228",
    RegisteredPopulation: "3804",
    VotingStationName: "AVANTE LAERSKOOL",
    Address:
      "CNR STEYN& VAN DER BERG STREET  KOCKSOORD  (GT482 - RANDFONTEIN [RANDFONTEIN])",
    Latitude: "-26.2186",
    Longitude: "27.6478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7392",
    WardLookupId: "3525",
    VDNumber: "33260408",
    RegisteredPopulation: "1562",
    VotingStationName: "METHODIST CHURCH OF SOUTHERN AFRICA",
    Address: "DRAKENSBERG  FINSBURY  RANDFONTEIN",
    Latitude: "-26.2068",
    Longitude: "27.6537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7393",
    WardLookupId: "3526",
    VDNumber: "33260116",
    RegisteredPopulation: "2067",
    VotingStationName: "SETHOLELA PRIMARY SCHOOL",
    Address: "SESDE STRAAT  WHEATLANDS  RANDFONTEIN",
    Latitude: "-26.1822",
    Longitude: "27.6301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7394",
    WardLookupId: "3526",
    VDNumber: "33260284",
    RegisteredPopulation: "1520",
    VotingStationName: "RANDPOORT RENAISSANCE HALL",
    Address: "BAILEY ST  RANDPOORT  RANDFONTEIN",
    Latitude: "-26.1705",
    Longitude: "27.6781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7395",
    WardLookupId: "3526",
    VDNumber: "33260431",
    RegisteredPopulation: "258",
    VotingStationName: "JABULANI PRE SCHOOL",
    Address:
      "CNR LAZAAR AVENUE & ROAD 5 R41  RANDFONTEIN  BOTHA PLOTS RANDGATE",
    Latitude: "-26.1711",
    Longitude: "27.6663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7396",
    WardLookupId: "3527",
    VDNumber: "33260127",
    RegisteredPopulation: "3986",
    VotingStationName: "RANDGATE RECREATION HALL",
    Address: "H/V SAUER & TUDHOPE  RANDGATE  RANDFONTEIN",
    Latitude: "-26.171",
    Longitude: "27.687",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7397",
    WardLookupId: "3528",
    VDNumber: "33260172",
    RegisteredPopulation: "2663",
    VotingStationName: "OLD TOWN HALL",
    Address: "POLLOCK STREET  MEO OFFICE  RANDFONTEIN",
    Latitude: "-26.1815",
    Longitude: "27.7001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7398",
    WardLookupId: "3528",
    VDNumber: "33260307",
    RegisteredPopulation: "1089",
    VotingStationName: "BANTSHO OFFICE PARK",
    Address: "92 247 IQ MAUGHAM ROAD  RANDFONTEIN CENTRAL  RANDFONTEIN",
    Latitude: "-26.1782",
    Longitude: "27.7061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7399",
    WardLookupId: "3529",
    VDNumber: "33260149",
    RegisteredPopulation: "2255",
    VotingStationName: "SHOW HALL",
    Address: "NORTH ROAD  GREENHILLS  RANDFONTEIN",
    Latitude: "-26.163",
    Longitude: "27.7053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7400",
    WardLookupId: "3529",
    VDNumber: "33260161",
    RegisteredPopulation: "1942",
    VotingStationName: "RANDFONTEIN LAERSKOOL",
    Address: "1 FREDERICK STREET  HOMELAKE  RANDFONTEIN",
    Latitude: "-26.1749",
    Longitude: "27.6996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7401",
    WardLookupId: "3530",
    VDNumber: "33260330",
    RegisteredPopulation: "2335",
    VotingStationName: "WESTCOL RANDFONTEIN HALL",
    Address: "1 KIEWIETS STREET  HELIKON PARK  RANDFONTEIN",
    Latitude: "-26.1866",
    Longitude: "27.6776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7402",
    WardLookupId: "3530",
    VDNumber: "33260363",
    RegisteredPopulation: "2113",
    VotingStationName: "RIEBEECK RAND HOÃ‹RSKOOL",
    Address: "CNR VAN RIEBEECK & ANGELIER ST  CULEMBORG PARK  RANDFONTEIN",
    Latitude: "-26.1791",
    Longitude: "27.6975",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7403",
    WardLookupId: "3531",
    VDNumber: "33260183",
    RegisteredPopulation: "1958",
    VotingStationName: "TOEKOMSRUS COMMUNITY HALL",
    Address: "1512 GOUD STREET  TOEKOMSRUS  RANDFONTEIN",
    Latitude: "-26.2051",
    Longitude: "27.7052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7404",
    WardLookupId: "3531",
    VDNumber: "33260239",
    RegisteredPopulation: "945",
    VotingStationName: "F.J.L WELLS PRIMARY SCHOOL",
    Address: "02 NEW OXFORD STREET  BHONGWENI VILLAGE  RANDFONTEIN",
    Latitude: "-26.1663",
    Longitude: "27.7277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7405",
    WardLookupId: "3531",
    VDNumber: "33260352",
    RegisteredPopulation: "960",
    VotingStationName: "HOMELAKE WESTCOL COLLEGE",
    Address: "42  JOHN STONE  HOMELAKE  RANDFONTEIN",
    Latitude: "-26.1711",
    Longitude: "27.7098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7406",
    WardLookupId: "3531",
    VDNumber: "33260442",
    RegisteredPopulation: "196",
    VotingStationName: "AZAADVILLE GARDEN E&R SHOW HOUSE",
    Address: "90 GARDENIA STREET  RANDFONTEIN  AZAADVILLE",
    Latitude: "-26.1652",
    Longitude: "27.7479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7407",
    WardLookupId: "3532",
    VDNumber: "33260138",
    RegisteredPopulation: "2201",
    VotingStationName: "RANDFONTEIN PRIMARY SCHOOL",
    Address: "BOERPERD STREET  GREENHILLS  RANDFONTEIN",
    Latitude: "-26.1529",
    Longitude: "27.6947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7408",
    WardLookupId: "3532",
    VDNumber: "33260329",
    RegisteredPopulation: "1473",
    VotingStationName: "RANDFONTEIN HIGH SCHOOL",
    Address: "01 ALOE ROAD  GREENHILLS  RANDFONTEIN",
    Latitude: "-26.1586",
    Longitude: "27.7049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7409",
    WardLookupId: "3532",
    VDNumber: "33290074",
    RegisteredPopulation: "854",
    VotingStationName: "MABLOMONG INTERMEDIATE SCHOOL",
    Address: "35 PLOT KLEIN ELANDSVLEI  BRANDVLEI  RANDFONTEIN",
    Latitude: "-26.1334",
    Longitude: "27.6441",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7410",
    WardLookupId: "3533",
    VDNumber: "33260206",
    RegisteredPopulation: "3362",
    VotingStationName: "HARTZSTRAAT PRIMÃŠRE SKOOL",
    Address: "93 HARTZ STREET  TOEKOMSRUS  (GT482 - RANDFONTEIN [RANDFONTEIN])",
    Latitude: "-26.2054",
    Longitude: "27.7175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7411",
    WardLookupId: "3534",
    VDNumber: "33260015",
    RegisteredPopulation: "2241",
    VotingStationName: "MOHLAKANO PRIMARY SCHOOL",
    Address: "274 NGQONYELA STREET  MOHLAKENG  RANDFONTEIN",
    Latitude: "-26.2219",
    Longitude: "27.6936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7412",
    WardLookupId: "3534",
    VDNumber: "33260341",
    RegisteredPopulation: "2113",
    VotingStationName: "MOHLAKENG EXT 11 (TENT)",
    Address: "MAIN REEF ROAD  MOHLAKENG  RANDFONTEIN",
    Latitude: "-26.2286",
    Longitude: "27.6819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7413",
    WardLookupId: "3535",
    VDNumber: "33260251",
    RegisteredPopulation: "1651",
    VotingStationName: "TENT (ZENZELE ZCC)",
    Address: "MAIN STREET  RANDFONTEIN  ZENZELE 1",
    Latitude: "-26.2552",
    Longitude: "27.6936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7414",
    WardLookupId: "3535",
    VDNumber: "33260262",
    RegisteredPopulation: "1482",
    VotingStationName: "TENT (ZENZELE 2)",
    Address: "INFORMAL SETTLEMENT  ZENZELE  RANDFONTEIN",
    Latitude: "-26.2602",
    Longitude: "27.6948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7415",
    WardLookupId: "3535",
    VDNumber: "33260273",
    RegisteredPopulation: "549",
    VotingStationName: "EBENEZER BIBLE CHURCH",
    Address: "32 PLOT  RANDFONTEIN  RANDFONTEIN",
    Latitude: "-26.2445",
    Longitude: "27.6838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7416",
    WardLookupId: "3536",
    VDNumber: "33260295",
    RegisteredPopulation: "2540",
    VotingStationName: "MOHLAKENG ARTS AND CULTURE",
    Address: "RALERATA  MOHLAKENG  RANDFONTEIN",
    Latitude: "-26.221",
    Longitude: "27.6957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7417",
    WardLookupId: "3536",
    VDNumber: "33260374",
    RegisteredPopulation: "376",
    VotingStationName: "ACTS OF SPIRIT BIBLE CHURCH",
    Address: "BUNDU INN INFORMAL SETTLEMENT  RANDFONTEIN  RANDFONTEIN",
    Latitude: "-26.2076",
    Longitude: "27.6909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7418",
    WardLookupId: "3536",
    VDNumber: "33260419",
    RegisteredPopulation: "927",
    VotingStationName: "CRN SEME & MALEKE (TENT)",
    Address: "SEME & MALEKE  MOHLAKENG  RANDFONTEIN",
    Latitude: "-26.2185",
    Longitude: "27.703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7419",
    WardLookupId: "3537",
    VDNumber: "33290063",
    RegisteredPopulation: "3468",
    VotingStationName: "MOHLAKENG EXT 7 (TENT)",
    Address: "RANDFONTEIN  EXT 7 MOHLAKENG  RANDFONTEIN",
    Latitude: "-26.2281",
    Longitude: "27.709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7420",
    WardLookupId: "3538",
    VDNumber: "33260240",
    RegisteredPopulation: "1290",
    VotingStationName: "THUTO-LEHAKWE SECONDARY SCHOOL",
    Address: "6579 KENT MASIRE STREET  MOHLAKENG  RANDFONTEIN",
    Latitude: "-26.2368",
    Longitude: "27.6946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7421",
    WardLookupId: "3538",
    VDNumber: "33260318",
    RegisteredPopulation: "2034",
    VotingStationName: "TENT (MOHLAKENG EXT 4 KHOSA STREET)",
    Address: "6282 KHOSA STREET  MOHLAKENG  RANDFONTEIN [RANDFONTEIN]",
    Latitude: "-26.2446",
    Longitude: "27.697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7422",
    WardLookupId: "3538",
    VDNumber: "33260385",
    RegisteredPopulation: "1344",
    VotingStationName: "JEHOVAH RAFAH MINISTRIES",
    Address: "7933 KENT MASIRE STREET  MOHLAKENG  MOHLAKENG EXT 5",
    Latitude: "-26.2414",
    Longitude: "27.6938",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7423",
    WardLookupId: "3539",
    VDNumber: "33260059",
    RegisteredPopulation: "1719",
    VotingStationName: "SEDIMOSANG PRIMARY SCHOOL",
    Address: "3385 NTULI STREET  MOHLAKENG  RANDFONTEIN",
    Latitude: "-26.2317",
    Longitude: "27.6887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7424",
    WardLookupId: "3539",
    VDNumber: "33260105",
    RegisteredPopulation: "1087",
    VotingStationName: "TSWELELO PUBLIC SCHOOL",
    Address: "2188 LEBUSA STREET  MOHLAKENG  RANDFONTEIN",
    Latitude: "-26.2314",
    Longitude: "27.6983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7425",
    WardLookupId: "3539",
    VDNumber: "33260396",
    RegisteredPopulation: "1503",
    VotingStationName: "MANDELA PARK (TENT)",
    Address: "RANDFONTEIN  ",
    Latitude: "-26.237",
    Longitude: "27.6897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7426",
    WardLookupId: "3540",
    VDNumber: "33270184",
    RegisteredPopulation: "2662",
    VotingStationName: "ZUURBEKOM INTERMEDIATE SCHOOL",
    Address: "389 NOLA STREET   ZUURBEKOM   WESTONARIA",
    Latitude: "-26.304",
    Longitude: "27.7539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7427",
    WardLookupId: "3540",
    VDNumber: "33270308",
    RegisteredPopulation: "1076",
    VotingStationName: "PHILANI COMM DEVELOPMENT CENTRE",
    Address: "WATERWORKS INFORMAL SETTLEMENT  WESTONARIA  WATERWORKS",
    Latitude: "-26.2952",
    Longitude: "27.8115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7428",
    WardLookupId: "3541",
    VDNumber: "33260037",
    RegisteredPopulation: "2237",
    VotingStationName: "MATLAPANENG PUBLIC SCHOOL",
    Address: "982 SELOPE THEMA STREET  MOHLAKENG  RANDFONTEIN",
    Latitude: "-26.2247",
    Longitude: "27.6983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7429",
    WardLookupId: "3541",
    VDNumber: "33260048",
    RegisteredPopulation: "1587",
    VotingStationName: "PHANDULWAZI PRIMARY SCHOOL",
    Address: "3366 VILAKAZI STREET  MOHLAKENG  RANDFONTEIN",
    Latitude: "-26.2274",
    Longitude: "27.6915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7430",
    WardLookupId: "3542",
    VDNumber: "33260026",
    RegisteredPopulation: "1834",
    VotingStationName: "MALERATO PUBLIC SCHOOL",
    Address: "1528 RAKALE STREET  MOHLAKENG  RANDFONTEIN",
    Latitude: "-26.2241",
    Longitude: "27.7019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7431",
    WardLookupId: "3542",
    VDNumber: "33290085",
    RegisteredPopulation: "1302",
    VotingStationName: "LUKHANYO HIGH SCHOOL.",
    Address: "11266 NGQONYELA STREET,MOHLAKENG EXT  MOHLAKENG  RANDFONTEIN",
    Latitude: "-26.2238",
    Longitude: "27.7089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7432",
    WardLookupId: "3543",
    VDNumber: "33260194",
    RegisteredPopulation: "1784",
    VotingStationName: "PENTECOSTAL CHURCH",
    Address: "4 STORMSRIVIERSTRAAT  TOEKOMSRUS  RANDFONTEIN",
    Latitude: "-26.2108",
    Longitude: "27.7105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7433",
    WardLookupId: "3543",
    VDNumber: "33260217",
    RegisteredPopulation: "2084",
    VotingStationName: "TOEKOMSRUS PRIMARY SCHOOL",
    Address: "1 STRAWBERRY STREET  TOEKOMSRUS  RANDFONTEIN",
    Latitude: "-26.205",
    Longitude: "27.709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7434",
    WardLookupId: "3544",
    VDNumber: "33260060",
    RegisteredPopulation: "1822",
    VotingStationName: "METHODIST CHURCH OF SOUTH AFRICA",
    Address: "2772 KEPADISA STREET  MOHLAKENG  RANDFONTEIN",
    Latitude: "-26.2293",
    Longitude: "27.6925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7435",
    WardLookupId: "3544",
    VDNumber: "33260071",
    RegisteredPopulation: "1390",
    VotingStationName: "RAMOSA HALL",
    Address: "2550 CNR THEBENARE &  MOROKA  MOHLAKENG  RANDFONTEIN",
    Latitude: "-26.2312",
    Longitude: "27.6969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7436",
    WardLookupId: "3545",
    VDNumber: "33260093",
    RegisteredPopulation: "2613",
    VotingStationName: "ELIZABETH SOKHELA CRECHE",
    Address: "1158 MAMPONDO STR  MOHLAKENG  RANDFONTEIN",
    Latitude: "-26.228",
    Longitude: "27.6994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7437",
    WardLookupId: "3545",
    VDNumber: "33260420",
    RegisteredPopulation: "547",
    VotingStationName: "APOSTOLIC FAITH MISSION",
    Address: "GT485  RANDFONTEIN",
    Latitude: "-26.2305",
    Longitude: "27.6999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7438",
    WardLookupId: "3546",
    VDNumber: "33270049",
    RegisteredPopulation: "2833",
    VotingStationName: "KLOOF COUNTRY CLUB",
    Address: "WATERKLOOF STREET  GLENHARVIE  ",
    Latitude: "-26.3857",
    Longitude: "27.6229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7439",
    WardLookupId: "3546",
    VDNumber: "33270241",
    RegisteredPopulation: "1903",
    VotingStationName: "WELFARE ROOM (NO 1 HOSTEL)",
    Address: "KLOOF HOSTEL  KLOOF GOLD MINING CO LTD  WESTONARIA",
    Latitude: "-26.4081",
    Longitude: "27.5937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7440",
    WardLookupId: "3547",
    VDNumber: "33270038",
    RegisteredPopulation: "1617",
    VotingStationName: "ITUMELENG LSEN SCHOOL",
    Address: "C/O DAVIES HENRY STREETS  WESTONARIA  WESTONARIA",
    Latitude: "-26.3195",
    Longitude: "27.6412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7441",
    WardLookupId: "3547",
    VDNumber: "33270128",
    RegisteredPopulation: "2247",
    VotingStationName: "CHRISTIAN COMMUNITY CHURCH",
    Address: "PAKEMAN STREET  WESTONARIA  WESTONARIA",
    Latitude: "-26.3186",
    Longitude: "27.6492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7442",
    WardLookupId: "3548",
    VDNumber: "33270016",
    RegisteredPopulation: "978",
    VotingStationName: "LAERSKOOL VENTERSPOST",
    Address: "12 SKOOL STREET  VENTERSPOST  WESTONARIA",
    Latitude: "-26.2696",
    Longitude: "27.6282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7443",
    WardLookupId: "3548",
    VDNumber: "33270218",
    RegisteredPopulation: "727",
    VotingStationName: "LEEUDOORN MINE HOSTEL COMPLEX",
    Address: "LEEUDOORN HOSTEL  LEEUDOORN GLENHARVIE  WESTONARIA",
    Latitude: "-26.4474",
    Longitude: "27.5728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7444",
    WardLookupId: "3548",
    VDNumber: "33270229",
    RegisteredPopulation: "2005",
    VotingStationName: "LIBANON HALL",
    Address: "LIBANON MINE  LIBANON  SIBANYE GOLD MINE",
    Latitude: "-26.3468",
    Longitude: "27.6063",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7445",
    WardLookupId: "3548",
    VDNumber: "33270386",
    RegisteredPopulation: "65",
    VotingStationName: "TENT AT MOUNTAIN VIEW ESTATE",
    Address: "DD  ",
    Latitude: "-26.358",
    Longitude: "27.6304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7446",
    WardLookupId: "3548",
    VDNumber: "33290041",
    RegisteredPopulation: "954",
    VotingStationName: "VLEIKOP RDP ROADHOUSE",
    Address: "140 EERSTE STRAAT  RANDFONTEIN  VLEIKOP",
    Latitude: "-26.256",
    Longitude: "27.587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7447",
    WardLookupId: "3549",
    VDNumber: "33270027",
    RegisteredPopulation: "2383",
    VotingStationName: "WESTONARIA PRIMARY SCHOOL",
    Address: "2 MERCURIUS STREET  WESTONARIA  WESTONARIA",
    Latitude: "-26.3294",
    Longitude: "27.6575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7448",
    WardLookupId: "3549",
    VDNumber: "33270050",
    RegisteredPopulation: "641",
    VotingStationName: "WESTERN AREAS PRIMARY MINE SCHOOL",
    Address: "06 MHINGA STREET  WESTONARIA  THABONG VILLAGE",
    Latitude: "-26.3652",
    Longitude: "27.7088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7449",
    WardLookupId: "3549",
    VDNumber: "33270285",
    RegisteredPopulation: "911",
    VotingStationName: "MODDERFONTEIN PRIMARY SCHOOL",
    Address:
      "JASPI STREET, HILLSHAVEN  (GT483 - WESTONARIA [WESTONARIA])   (GT483 - WESTONARIA [WESTONARIA])",
    Latitude: "-26.3688",
    Longitude: "27.6589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7450",
    WardLookupId: "3549",
    VDNumber: "33270364",
    RegisteredPopulation: "2412",
    VotingStationName: "MUNICIPAL BANQUET HALL",
    Address: "2 NEPTUNE  WESTONARIA  WESTONARIA",
    Latitude: "-26.3301",
    Longitude: "27.6528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7451",
    WardLookupId: "3550",
    VDNumber: "33270252",
    RegisteredPopulation: "3434",
    VotingStationName: "SIMUNYE MULTIPURPOSE DEVELOPMENT CENTRE",
    Address: "555 INKULULEKO/REAIKAGA ROAD  SIMUNYE  WESTONARIA",
    Latitude: "-26.3227",
    Longitude: "27.6835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7452",
    WardLookupId: "3550",
    VDNumber: "33270319",
    RegisteredPopulation: "854",
    VotingStationName: "TENT AT SIMUNYE LIBRARY",
    Address: "3409 KGOTLELELA DRIVE  SIMUNYE  WESTONARIA",
    Latitude: "-26.3168",
    Longitude: "27.692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7453",
    WardLookupId: "3551",
    VDNumber: "33270263",
    RegisteredPopulation: "1662",
    VotingStationName: "SIMUNYE 2&2 SOCCERFIELd (TENT)",
    Address: "12 MAIN ROAD  WESTONARIA  WESTONARIA",
    Latitude: "-26.3176",
    Longitude: "27.694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7454",
    WardLookupId: "3551",
    VDNumber: "33270320",
    RegisteredPopulation: "1894",
    VotingStationName: "KAMOGELO PRIMARY SCHOOL",
    Address: "4494 CNR DIRISANANG AND DIRELAGO ST  WESTONARIA  WESTONARIA",
    Latitude: "-26.3297",
    Longitude: "27.7002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7455",
    WardLookupId: "3551",
    VDNumber: "33270331",
    RegisteredPopulation: "1226",
    VotingStationName: "SIMUNYE RAILWAY LINE (TENT)",
    Address: "KOPANANG  WESTONARIA  WESTONARIA",
    Latitude: "-26.3126",
    Longitude: "27.6906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7456",
    WardLookupId: "3552",
    VDNumber: "33270139",
    RegisteredPopulation: "1096",
    VotingStationName: "VEZINYAWO INFORMAL SETTLEMENT (TENT)",
    Address: "VEZINYAWO INFORMAL SETTLEMENT  BEKKERSDAL  WESTONARIA",
    Latitude: "-26.2755",
    Longitude: "27.6994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7457",
    WardLookupId: "3552",
    VDNumber: "33270162",
    RegisteredPopulation: "1629",
    VotingStationName: "HOLOMISA NEXT TO SEWERAGE (TENT)",
    Address: "NEXT TO SEWRAGE  BEKKERSDAL  WESTONARIA",
    Latitude: "-26.279",
    Longitude: "27.692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7458",
    WardLookupId: "3553",
    VDNumber: "33270195",
    RegisteredPopulation: "1151",
    VotingStationName: "MIM HALL (OLD ENDULENI KITCHEN)",
    Address: "OLD VEREENIGING ROAD  SOUTH DEEP  WESTONARIA",
    Latitude: "-26.4057",
    Longitude: "27.6841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7459",
    WardLookupId: "3553",
    VDNumber: "33270296",
    RegisteredPopulation: "901",
    VotingStationName: "THUSANANG VILLAGE CENTRE",
    Address: "R500  WESTONARIA  WESTONARIA",
    Latitude: "-26.4148",
    Longitude: "27.7014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7460",
    WardLookupId: "3553",
    VDNumber: "33270353",
    RegisteredPopulation: "75",
    VotingStationName: "TENT AT KALABASFONTEIN",
    Address: "PLOT 365 KALABASFONTEIN  WESTONARIA  CARDOVILLE",
    Latitude: "-26.454",
    Longitude: "27.6499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7461",
    WardLookupId: "3553",
    VDNumber: "33270375",
    RegisteredPopulation: "97",
    VotingStationName: "ETHEMBENI VILLAGE (TENT)",
    Address: "ETHEMBENI  WESTONARIA  WESTONARIA",
    Latitude: "-26.4197",
    Longitude: "27.5953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7462",
    WardLookupId: "3554",
    VDNumber: "33270140",
    RegisteredPopulation: "2468",
    VotingStationName: "TENT AT BEKKERSDAL DEVELOPMENT HUB",
    Address: "KGOMO YA TLHABA  BEKKERSDAL  WESTONARIA",
    Latitude: "-26.2829",
    Longitude: "27.7022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7463",
    WardLookupId: "3554",
    VDNumber: "33270342",
    RegisteredPopulation: "1094",
    VotingStationName: "SPOOK TOWN (TENT)",
    Address: "SPOOKTOWN INFORMAL SETTLEMENT  BEKKERSDAL  BEKKERSDAL",
    Latitude: "-26.278",
    Longitude: "27.7015",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7464",
    WardLookupId: "3555",
    VDNumber: "33270083",
    RegisteredPopulation: "2710",
    VotingStationName: "KGOTHALANG HIGH SCHOOL",
    Address: "831 MHELO MAKULU AVENUE  BEKKERSDAL  WESTNONARIA",
    Latitude: "-26.2856",
    Longitude: "27.7154",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7465",
    WardLookupId: "3555",
    VDNumber: "33270094",
    RegisteredPopulation: "1675",
    VotingStationName: "MAPUTLE PRIMARY SCHOOL",
    Address: "833 SCHOOL AVENUE  BEKKERSDAL  WESTONARIA",
    Latitude: "-26.2851",
    Longitude: "27.705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7466",
    WardLookupId: "3556",
    VDNumber: "33270061",
    RegisteredPopulation: "2037",
    VotingStationName: "UNITING REFORMED CHURCH",
    Address: "78 DLOMO STREET  WESTONARIA  BEKKERSDAL",
    Latitude: "-26.2817",
    Longitude: "27.7045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7467",
    WardLookupId: "3556",
    VDNumber: "33270072",
    RegisteredPopulation: "1449",
    VotingStationName: "BOITUMELO EDU-CARE CENTRE",
    Address: "2747 THETHELETSA STREET  WESTONARIA  BEKKERSDAL",
    Latitude: "-26.282",
    Longitude: "27.707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7468",
    WardLookupId: "3557",
    VDNumber: "33270151",
    RegisteredPopulation: "1565",
    VotingStationName: "THAMBO BASE SECTION (TENT)",
    Address: "AKK98 THAMBO SECTION  BEKKERSDAL  WESTONARIA",
    Latitude: "-26.2863",
    Longitude: "27.6909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7469",
    WardLookupId: "3557",
    VDNumber: "33270274",
    RegisteredPopulation: "1183",
    VotingStationName: "TENT WINNIE SECTION (TENT)",
    Address: "AKK  BEKKERSDAL  WESTONARIA",
    Latitude: "-26.2831",
    Longitude: "27.6938",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7470",
    WardLookupId: "3558",
    VDNumber: "33270106",
    RegisteredPopulation: "1625",
    VotingStationName: "IPELENG PRIMARY SCHOOL",
    Address: "1372 KRAAI STREET  BEKKERSDAL  WESTONARIA",
    Latitude: "-26.285",
    Longitude: "27.7021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7471",
    WardLookupId: "3558",
    VDNumber: "33270173",
    RegisteredPopulation: "1297",
    VotingStationName: "THAMBO SOCCER FIELD (TENT)",
    Address: "1 THAMBO SOCCERFIELD  BEKKERSDAL  WESTONARIA",
    Latitude: "-26.2891",
    Longitude: "27.6834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7472",
    WardLookupId: "1143",
    VDNumber: "32860728",
    RegisteredPopulation: "3882",
    VotingStationName: "THE VICTORIOUS RESTORATION CHURCH INTERNATIONAL",
    Address: "12856 STRETFORD EXT 7A  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4895",
    Longitude: "27.8503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7473",
    WardLookupId: "1143",
    VDNumber: "32860896",
    RegisteredPopulation: "1536",
    VotingStationName: "TSHEPANA PRIMARY SCHOOL",
    Address: "12655 STRETFORD EXT 7A  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4847",
    Longitude: "27.8485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7474",
    WardLookupId: "1143",
    VDNumber: "32860919",
    RegisteredPopulation: "2474",
    VotingStationName: "THAMSANQA SECONDARY SCHOOL",
    Address: "12248 STRETFORD EXT 7  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4806",
    Longitude: "27.8465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7475",
    WardLookupId: "1143",
    VDNumber: "32861022",
    RegisteredPopulation: "2157",
    VotingStationName: "SEIPONE PRIMARY SCHOOL",
    Address: "STRETFORD EXT 6A  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4808",
    Longitude: "27.8539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7476",
    WardLookupId: "1143",
    VDNumber: "32862944",
    RegisteredPopulation: "2799",
    VotingStationName: "EMMANUEL DAYCARE CENTRE",
    Address: "16653  STRETFORD EXT 9  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4909",
    Longitude: "27.8463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7477",
    WardLookupId: "1143",
    VDNumber: "32862955",
    RegisteredPopulation: "2553",
    VotingStationName: "REFALLETSE PRIMARY SCHOOL",
    Address: "DRIEZIEK EXT 9  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4966",
    Longitude: "27.8524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7478",
    WardLookupId: "1144",
    VDNumber: "32861011",
    RegisteredPopulation: "2080",
    VotingStationName: "QOQA SECONDARY SCHOOL",
    Address: "3712 ORANGE FARM EXT 1  ORANGE FARM  ORANGE FARM",
    Latitude: "-26.4981",
    Longitude: "27.8782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7479",
    WardLookupId: "1144",
    VDNumber: "32861033",
    RegisteredPopulation: "1477",
    VotingStationName: "TENT ORANGE FARM EXT 1 NEXT TO NAMBITHA",
    Address: "1433 ORANGE FARM EXT.1  ORANGE FARM   JOHANNESBURG",
    Latitude: "-26.4951",
    Longitude: "27.8817",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7480",
    WardLookupId: "1144",
    VDNumber: "32862359",
    RegisteredPopulation: "2868",
    VotingStationName: "LANGALIBALELE PRIMARY SCHOOL",
    Address: "6400 LAKESIDE EXT 2  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.5048",
    Longitude: "27.8689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7481",
    WardLookupId: "1144",
    VDNumber: "32862966",
    RegisteredPopulation: "3592",
    VotingStationName: "UNITING REFORMED CHURCH IN S.A",
    Address: "2471 LAKESIDE EXT 1  LAKESIDE ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.5111",
    Longitude: "27.8787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7482",
    WardLookupId: "1144",
    VDNumber: "32863226",
    RegisteredPopulation: "1046",
    VotingStationName: "QHAKAZANI PRIMARY SCHOOL",
    Address: "1843 PALM ROAD  LAKESIDE EXT 1  JOHANNESBURG",
    Latitude: "-26.5065",
    Longitude: "27.8772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7483",
    WardLookupId: "1144",
    VDNumber: "32863350",
    RegisteredPopulation: "1141",
    VotingStationName: "ZONKIZIZWE PRIMARY SCHOOL",
    Address: "1510 MSHENGU DRIVE  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.5014",
    Longitude: "27.8826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7484",
    WardLookupId: "1144",
    VDNumber: "32863361",
    RegisteredPopulation: "1605",
    VotingStationName: "EE MAHABANE METHODIST CHURCH",
    Address: "01 MPUMALANGA  LAKESIDE EXT 2  JOHANNESBURG",
    Latitude: "-26.5109",
    Longitude: "27.8662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7485",
    WardLookupId: "1144",
    VDNumber: "32863372",
    RegisteredPopulation: "1325",
    VotingStationName: "TENT LAKESIDE EXT1  - NEXT TO DISANTENG HARDWARE",
    Address: "LAKESIDE  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.518",
    Longitude: "27.878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7486",
    WardLookupId: "1145",
    VDNumber: "32860874",
    RegisteredPopulation: "2738",
    VotingStationName: "LESHATA SECONDARY SCHOOL",
    Address: "9039 ORANGE FARM EXT 6B  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4828",
    Longitude: "27.8631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7487",
    WardLookupId: "1145",
    VDNumber: "32860908",
    RegisteredPopulation: "2681",
    VotingStationName: "RADIPABI PRIMARY SCHOOL",
    Address: "12830 ORANGE FARM EXT 7B  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4734",
    Longitude: "27.8606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7488",
    WardLookupId: "1145",
    VDNumber: "32860920",
    RegisteredPopulation: "1512",
    VotingStationName: "LAUS DEO PRIMARY SCHOOL",
    Address: "8395 ORANGE FARM EXT 6A  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.478",
    Longitude: "27.8528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7489",
    WardLookupId: "1145",
    VDNumber: "32860931",
    RegisteredPopulation: "1927",
    VotingStationName: "AHA-THUTO SECONDARY SCHOOL",
    Address: "12211 ORANGE FARM EXT 7B  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4783",
    Longitude: "27.8602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7490",
    WardLookupId: "1145",
    VDNumber: "32860986",
    RegisteredPopulation: "2715",
    VotingStationName: "ORANGE FARM PRIMARY SCHOOL",
    Address: "983 ORANGE FARM EXT1  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4864",
    Longitude: "27.8726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7491",
    WardLookupId: "1145",
    VDNumber: "32861099",
    RegisteredPopulation: "1439",
    VotingStationName: "MATIWANE PRIMARY SCHOOL",
    Address: "ORANGE FARM EXT 7  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4751",
    Longitude: "27.8549",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7492",
    WardLookupId: "1145",
    VDNumber: "32863383",
    RegisteredPopulation: "1133",
    VotingStationName: "MOYISELA PRIMARY SCHOOL",
    Address: "11823 MODISELLE STREET  ORANGE FARM EXT 7B  JOHANNESBURG",
    Latitude: "-26.472",
    Longitude: "27.8668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7493",
    WardLookupId: "1146",
    VDNumber: "32860852",
    RegisteredPopulation: "3571",
    VotingStationName: "INTLONIPHO PRIMARY SCHOOL",
    Address: "6712 ORANGE FARM EXT1  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4609",
    Longitude: "27.8679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7494",
    WardLookupId: "1146",
    VDNumber: "32860863",
    RegisteredPopulation: "2166",
    VotingStationName: "VULANINDLELA SECONDARY SCHOOL",
    Address: "4896 EXT 2 ORANGE FARM  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4566",
    Longitude: "27.8625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7495",
    WardLookupId: "1146",
    VDNumber: "32860885",
    RegisteredPopulation: "596",
    VotingStationName: "REITUMETSE PRIMARY SCHOOL",
    Address: "32 PLOT 1 GOLDENHIGHWAY  ORANGE FARM EXT2  JOHANNESBURG",
    Latitude: "-26.4511",
    Longitude: "27.8677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7496",
    WardLookupId: "1146",
    VDNumber: "32861088",
    RegisteredPopulation: "1686",
    VotingStationName: "REAMOHETSOE PRIMARY SCHOOL",
    Address: "3459 POPPY STREET  DRIEZIEK EXT1  JOHANNESBURG",
    Latitude: "-26.4712",
    Longitude: "27.8517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7497",
    WardLookupId: "1146",
    VDNumber: "32861101",
    RegisteredPopulation: "3715",
    VotingStationName: "REKGUTLILE PRIMARY SCHOOL",
    Address: "14501 EXT 8 ORANGE FARM  ORANGE FARM 8  JOHANNESBURG",
    Latitude: "-26.4666",
    Longitude: "27.8534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7498",
    WardLookupId: "1146",
    VDNumber: "32861112",
    RegisteredPopulation: "4733",
    VotingStationName: "ITEMOHENG PRIMARY SCHOOL",
    Address: "1190 DRIEZIEK 4  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4592",
    Longitude: "27.8442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7499",
    WardLookupId: "1146",
    VDNumber: "32863394",
    RegisteredPopulation: "1996",
    VotingStationName: "MPHETHI MAHLATSI SEC SCHOOL",
    Address: "13745 VINCENT STREET  ORANGE FARM EXT 8  JOHANNESBURG",
    Latitude: "-26.4613",
    Longitude: "27.8573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7500",
    WardLookupId: "1147",
    VDNumber: "32860717",
    RegisteredPopulation: "4287",
    VotingStationName: "GOVAN MBEKI PRIMARY SCHOOL",
    Address: "6964 DRIEZIEK EXT 3  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4715",
    Longitude: "27.8427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7501",
    WardLookupId: "1147",
    VDNumber: "32860942",
    RegisteredPopulation: "2291",
    VotingStationName: "ORANGE FARM CATHOLIC CHURCH",
    Address: "STRETFORD EXT 8  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.476",
    Longitude: "27.8473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7502",
    WardLookupId: "1147",
    VDNumber: "32860997",
    RegisteredPopulation: "1032",
    VotingStationName: "IMFUNDU THUTO DAYCARE",
    Address: "13705 STRETFORD 8A  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4772",
    Longitude: "27.8451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7503",
    WardLookupId: "1147",
    VDNumber: "32861123",
    RegisteredPopulation: "2857",
    VotingStationName: "AHANANG PRIMARY SCHOOL",
    Address: "943 POORTJIE  JOHANNESBURG",
    Latitude: "-26.4553",
    Longitude: "27.7705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7504",
    WardLookupId: "1147",
    VDNumber: "32861134",
    RegisteredPopulation: "1903",
    VotingStationName: "MFUNDO-MTOTI PRIMARY SCHOOL",
    Address: "1366 POORTJIE  JOHANNESBURG",
    Latitude: "-26.4587",
    Longitude: "27.7645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7505",
    WardLookupId: "1147",
    VDNumber: "32862832",
    RegisteredPopulation: "413",
    VotingStationName: "TENT KAPOK",
    Address: "01 TOWN ROAD  ENENERDALE KAPOK INFORMAL  JOHANNESBURG",
    Latitude: "-26.4242",
    Longitude: "27.8281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7506",
    WardLookupId: "1147",
    VDNumber: "32863035",
    RegisteredPopulation: "747",
    VotingStationName: "TENT DRIEZIEK 6",
    Address: "DRIEZIEK 6  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4758",
    Longitude: "27.8411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7507",
    WardLookupId: "1147",
    VDNumber: "32863158",
    RegisteredPopulation: "3123",
    VotingStationName: "SOLWAZI PRIMARY  SCHOOL",
    Address: "9060 DRIEZIEK 5  DRIEZIEK  JOHANNESBURG",
    Latitude: "-26.4773",
    Longitude: "27.8291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7508",
    WardLookupId: "1148",
    VDNumber: "32862371",
    RegisteredPopulation: "3519",
    VotingStationName: "THUTHUKANI-TSWELOPELE PRIMARY SCHOOL",
    Address: "1042 TWEEDE STREET  FINETOWN  JOHANNESBURG",
    Latitude: "-26.4244",
    Longitude: "27.8683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7509",
    WardLookupId: "1148",
    VDNumber: "32862382",
    RegisteredPopulation: "4343",
    VotingStationName: "SAKHISIZWE SECONDARY SCHOOL",
    Address: "E1 WEILERS FARM  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.4126",
    Longitude: "27.9029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7510",
    WardLookupId: "1148",
    VDNumber: "32862438",
    RegisteredPopulation: "2617",
    VotingStationName: "SIBEKIWE PRIMARY SCHOOL",
    Address: "340 ENNERDALE SOUTH  GRASMERE  JOHANNESBURG",
    Latitude: "-26.4327",
    Longitude: "27.8637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7511",
    WardLookupId: "1148",
    VDNumber: "32862449",
    RegisteredPopulation: "243",
    VotingStationName: "JOHWETO COMBINED SCHOOL",
    Address:
      "C62/3 DOORNKUIL&GOLDEN HIGHWAY  SWEET WATER  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.4376",
    Longitude: "27.8835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7512",
    WardLookupId: "1148",
    VDNumber: "32862843",
    RegisteredPopulation: "1282",
    VotingStationName: "THULAMTWANA COMBINED SCHOOL",
    Address: "256-263 277-284 THULAMTWANA  JOHANNESBURG",
    Latitude: "-26.4339",
    Longitude: "27.8967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7513",
    WardLookupId: "1148",
    VDNumber: "32863237",
    RegisteredPopulation: "2255",
    VotingStationName: "PHAKAMANI EDUCATIONAL CENTRE",
    Address: "1365 KANANA PARK  WEILERS FARM  JOHANNESBURG",
    Latitude: "-26.4194",
    Longitude: "27.8993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7514",
    WardLookupId: "1148",
    VDNumber: "32863406",
    RegisteredPopulation: "2350",
    VotingStationName: "KANANA PARK PRIMARY SCHOOL",
    Address: "KANANA PARK EXT 3  KANANA PARK  CITY OF JOJANNESBURG",
    Latitude: "-26.4228",
    Longitude: "27.8964",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7515",
    WardLookupId: "1148",
    VDNumber: "32863529",
    RegisteredPopulation: "706",
    VotingStationName: "DUSENENDLELA PRIMARY SCHOOL",
    Address: "10 GOLDEN HIGHWAY  FINETOWN  JOHANNESBURG",
    Latitude: "-26.4404",
    Longitude: "27.8721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7516",
    WardLookupId: "1149",
    VDNumber: "32861044",
    RegisteredPopulation: "2706",
    VotingStationName: "ENNERDALE EVANGELIC CHURCH",
    Address: "CNR MICA & MINNETTE  ENNERDALE EXT 10  JOHANNESBURG",
    Latitude: "-26.4209",
    Longitude: "27.844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7517",
    WardLookupId: "1149",
    VDNumber: "32861055",
    RegisteredPopulation: "3876",
    VotingStationName: "MID-ENNERDALE PRIMARY SCHOOL",
    Address: "335 CHARLES  MID-ENNERDALE  JOHANNESBURG",
    Latitude: "-26.4178",
    Longitude: "27.8364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7518",
    WardLookupId: "1149",
    VDNumber: "32861066",
    RegisteredPopulation: "3187",
    VotingStationName: "FRED NORMAN SECONDARY SCHOOL",
    Address: "CNR NEPTUNE & POSEIDON  ENNERDALE EXT 1  JOHANNESBURG",
    Latitude: "-26.4048",
    Longitude: "27.8363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7519",
    WardLookupId: "1149",
    VDNumber: "32861077",
    RegisteredPopulation: "3457",
    VotingStationName: "FINETOWN PRIMARY SCHOOL",
    Address: "2598 FINE STR  GRASMERE  JOHANNESBURG",
    Latitude: "-26.4143",
    Longitude: "27.8585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7520",
    WardLookupId: "1149",
    VDNumber: "32862360",
    RegisteredPopulation: "1842",
    VotingStationName: "BUYANI PRIMARY SCHOOL",
    Address:
      "2022 CENTRAL STREET  FINETOWN  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.4203",
    Longitude: "27.8609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7521",
    WardLookupId: "1149",
    VDNumber: "32862854",
    RegisteredPopulation: "1400",
    VotingStationName: "TENT (FINETOWN NEAR STAND NO. 3104)",
    Address: "STAND NO. 3104  FINETOWN INFORMAL SETTLEMENT  JOHANNESBURG",
    Latitude: "-26.4193",
    Longitude: "27.872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7522",
    WardLookupId: "1149",
    VDNumber: "32862865",
    RegisteredPopulation: "1865",
    VotingStationName: "TENT AT FINETOWN EAST OPEN FIELD",
    Address: "FINETOWN EAST INFORMAL SETTLEMENT  FINETOWN  JOHANNESBURG",
    Latitude: "-26.4104",
    Longitude: "27.8715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7523",
    WardLookupId: "1149",
    VDNumber: "32863417",
    RegisteredPopulation: "1086",
    VotingStationName: "ENNERDALE COMMUNITY HALL",
    Address: "1376 ACHILLES CRESCENT  ENNERDALE EXT 1  JOHANNESBURG",
    Latitude: "-26.4083",
    Longitude: "27.8457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7524",
    WardLookupId: "1150",
    VDNumber: "32860672",
    RegisteredPopulation: "2266",
    VotingStationName: "M.H.JOOSUB TECHNICAL TRAINING SCHOOL",
    Address: "4 BAMBOO STREET  LENASIA EXT 4  JOHANNESBURG",
    Latitude: "-26.3282",
    Longitude: "27.8288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7525",
    WardLookupId: "1150",
    VDNumber: "32860694",
    RegisteredPopulation: "2103",
    VotingStationName: "APEX PRIMARY SCHOOL",
    Address: "245 VOLTA STREET  LENASIA EXT 10  JOHANNESBURG",
    Latitude: "-26.3404",
    Longitude: "27.871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7526",
    WardLookupId: "1150",
    VDNumber: "32860706",
    RegisteredPopulation: "1907",
    VotingStationName: "TENT LEHAE PHASE 3 EXT",
    Address: "DINGO STREET  LEHAE PHASE 3  JOHANNESBURG",
    Latitude: "-26.3455",
    Longitude: "27.8738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7527",
    WardLookupId: "1150",
    VDNumber: "32862483",
    RegisteredPopulation: "2834",
    VotingStationName: "LAWLEY COMMUNITY CRECHE",
    Address: "314 LAWLEY STATION  JOHANNESBURG",
    Latitude: "-26.3546",
    Longitude: "27.8326",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7528",
    WardLookupId: "1150",
    VDNumber: "32862641",
    RegisteredPopulation: "3307",
    VotingStationName: "THEMBELIHLE COMMUNITY CENTRE",
    Address:
      "VOLTA STREE, THEMBELIHLE INFORMAL SETTLEMENT  THEMBALIHLE  JOHANNESBURG",
    Latitude: "-26.3368",
    Longitude: "27.8684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7529",
    WardLookupId: "1150",
    VDNumber: "32862652",
    RegisteredPopulation: "1730",
    VotingStationName: "SHARICREST PRIMARY SCHOOL",
    Address:
      "SHARI & VOLTA STREET  LENASIA EXT10  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.3431",
    Longitude: "27.862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7530",
    WardLookupId: "1150",
    VDNumber: "32862663",
    RegisteredPopulation: "2742",
    VotingStationName: "ZODIAK PRIMARY SCHOOL",
    Address: "7944 SCORPION DRIVE, LENZ EXT9  LENASIA  JOHANNESBURG",
    Latitude: "-26.3323",
    Longitude: "27.8741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7531",
    WardLookupId: "1150",
    VDNumber: "32863125",
    RegisteredPopulation: "413",
    VotingStationName: "JOHANNESBURG COUNCIL FOR THE DISABLED",
    Address: "K-43  LENASIA  JOHANNESBURG",
    Latitude: "-26.3365",
    Longitude: "27.8536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7532",
    WardLookupId: "1150",
    VDNumber: "32863428",
    RegisteredPopulation: "409",
    VotingStationName: "LENZ PUBLIC SEC SCHOOL",
    Address: "763 HYDERABAD  LENASIA EXT 6  JOHANNESBURG",
    Latitude: "-26.337",
    Longitude: "27.8422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7533",
    WardLookupId: "1151",
    VDNumber: "32860616",
    RegisteredPopulation: "1804",
    VotingStationName: "PENTAROSA PRIMARY SCHOOL",
    Address: "CNR MARMER & TURKOOISE  LENASIA  JOHANNEBURG",
    Latitude: "-26.3208",
    Longitude: "27.8504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7534",
    WardLookupId: "1151",
    VDNumber: "32860638",
    RegisteredPopulation: "3330",
    VotingStationName: "LENASIA CIVIC CENTRE",
    Address: "CNR ROSE & ELAND STS  LENASIA  JOHANNESBURG",
    Latitude: "-26.3206",
    Longitude: "27.8248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7535",
    WardLookupId: "1151",
    VDNumber: "32860649",
    RegisteredPopulation: "2527",
    VotingStationName: "PARK PRIMARY SCHOOL",
    Address: "2290 CONDOR AVE, FALCON  LENASIA EXT 1  JOHANNESBURG",
    Latitude: "-26.318",
    Longitude: "27.8333",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7536",
    WardLookupId: "1151",
    VDNumber: "32860661",
    RegisteredPopulation: "3005",
    VotingStationName: "PROTEA RECREATION CENTRE",
    Address: "7326 PROTEA AVE  LENASIA EXT 7  JOHANNESBURG",
    Latitude: "-26.3275",
    Longitude: "27.8522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7537",
    WardLookupId: "1151",
    VDNumber: "32860683",
    RegisteredPopulation: "2494",
    VotingStationName: "NIRVANA SECONDARY SCHOOL",
    Address: "CNR WILLOW & IVY STS  LENASIA EXT 3  JOHANNESBURG",
    Latitude: "-26.3264",
    Longitude: "27.8423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7538",
    WardLookupId: "1151",
    VDNumber: "32862416",
    RegisteredPopulation: "3351",
    VotingStationName: "JISWA SERVICE CENTRE FOR THE AGED",
    Address: "108 NIRVANA DRIVE, EXT.13  LENASIA  JOHANNESBURG",
    Latitude: "-26.3132",
    Longitude: "27.848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7539",
    WardLookupId: "1152",
    VDNumber: "32860021",
    RegisteredPopulation: "2745",
    VotingStationName: "TENT PROTEA SOUTH (NEAR HOUSE NO. 3940)",
    Address: "3940 PROTEA SOUTH  SOWETO  JOHANNESBURG",
    Latitude: "-26.291",
    Longitude: "27.8406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7540",
    WardLookupId: "1152",
    VDNumber: "32860065",
    RegisteredPopulation: "3049",
    VotingStationName: "ST MARY MAGDALINE ANGLICAN CHURCH",
    Address:
      "2576 SURREY STR  PROTEA SOUTH  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.2867",
    Longitude: "27.841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7541",
    WardLookupId: "1152",
    VDNumber: "32860087",
    RegisteredPopulation: "2549",
    VotingStationName: "ALTMONT TECHNICAL HIGH SCHOOL",
    Address: "CNR STANTON & ALEKHINE STR.  PROTEA SOUTH  JOHANNESBURG",
    Latitude: "-26.2814",
    Longitude: "27.8425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7542",
    WardLookupId: "1152",
    VDNumber: "32860414",
    RegisteredPopulation: "2881",
    VotingStationName: "LENASIA EXT 1 SECONDARY SCHOOL",
    Address: "CNR CRANE & PENGUIN ST  LENASIA  JOHANNESBURG",
    Latitude: "-26.3117",
    Longitude: "27.8293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7543",
    WardLookupId: "1152",
    VDNumber: "32860627",
    RegisteredPopulation: "1310",
    VotingStationName: "LENZ STADIUM CLUB HOUSE",
    Address: "HUMMINGBIRD AVE  LENASIA  JOHANNESBURG",
    Latitude: "-26.3159",
    Longitude: "27.8307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7544",
    WardLookupId: "1152",
    VDNumber: "32860650",
    RegisteredPopulation: "1525",
    VotingStationName: "TRINITY SECONDARY SCHOOL",
    Address: "GAZELLE AVENUE  LENASIA EXT 5  JOHANNESBURG",
    Latitude: "-26.314",
    Longitude: "27.8415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7545",
    WardLookupId: "1152",
    VDNumber: "32863473",
    RegisteredPopulation: "414",
    VotingStationName: "MPUDULE EARLEY LEARNING CENTRE",
    Address: "02 MAKHAYASTREET  PROTEA NORTH  JOHANNESBURG",
    Latitude: "-26.278",
    Longitude: "27.836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7546",
    WardLookupId: "1153",
    VDNumber: "32860593",
    RegisteredPopulation: "2113",
    VotingStationName: "FIRETHORN PRIMARY SCHOOL",
    Address: "551 FREEZIA DRIVE  KLIPSPRUIT WEST  KLIPTOWN",
    Latitude: "-26.2862",
    Longitude: "27.8798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7547",
    WardLookupId: "1153",
    VDNumber: "32860605",
    RegisteredPopulation: "1980",
    VotingStationName: "ST IVES PRIMARY SCHOOL",
    Address: "4 ST IVES STREET  KLIPSPRUIT WEST EXT 1  KLIPTOWN",
    Latitude: "-26.2948",
    Longitude: "27.873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7548",
    WardLookupId: "1153",
    VDNumber: "32862203",
    RegisteredPopulation: "2158",
    VotingStationName: "UNITING PRESBYTERIAN CHURCH",
    Address: "4273 VIKE STREET  CHIAWELO 2  SOWETO",
    Latitude: "-26.2868",
    Longitude: "27.868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7549",
    WardLookupId: "1153",
    VDNumber: "32862214",
    RegisteredPopulation: "2023",
    VotingStationName: "NHLUVUKO PRIMARY SCHOOL",
    Address: "769 NETSHITANGENI STREET  TSHIAWELO  SOWETO",
    Latitude: "-26.2818",
    Longitude: "27.8746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7550",
    WardLookupId: "1153",
    VDNumber: "32862225",
    RegisteredPopulation: "3487",
    VotingStationName: "TSHILITZI PRIMARY SCHOOL",
    Address: "1212 MBAHE STREET  CHIAWELO  JOHANNESBURG",
    Latitude: "-26.2815",
    Longitude: "27.8678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7551",
    WardLookupId: "1153",
    VDNumber: "32862236",
    RegisteredPopulation: "2754",
    VotingStationName: "TIAKENI PRIMARY SCHOOL",
    Address: "209 TAKALANI STREET  TSHIAWELO  SOWETO",
    Latitude: "-26.2783",
    Longitude: "27.8691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7552",
    WardLookupId: "1153",
    VDNumber: "32862258",
    RegisteredPopulation: "1973",
    VotingStationName: "HITEKANI PRIMARY SCHOOL",
    Address: "4131 MASUNGWINI STREET  CHIAWELO  SOWETO",
    Latitude: "-26.2859",
    Longitude: "27.8663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7553",
    WardLookupId: "1153",
    VDNumber: "32862562",
    RegisteredPopulation: "1301",
    VotingStationName: "HOME OF THE PASEO BAPTIST CHURCH",
    Address:
      "1431 CRN SAINT FRANCIS & SAINT CHRISTOPHER STREET  KLIPSPRUIT WEST EXT.2  KLIPTOWN",
    Latitude: "-26.2994",
    Longitude: "27.8766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7554",
    WardLookupId: "1154",
    VDNumber: "32861189",
    RegisteredPopulation: "1926",
    VotingStationName: "ELSIE NGIDI PRIMARY SCHOOL",
    Address: "432 TSITHUTHUNI  CHIAWELO EXT 3  SOWETO",
    Latitude: "-26.2815",
    Longitude: "27.8513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7555",
    WardLookupId: "1154",
    VDNumber: "32862247",
    RegisteredPopulation: "1984",
    VotingStationName: "GAZANKULU SCHOOL",
    Address: "2710 MHLABA DRIVE  TSHIAWELO  SOWETO",
    Latitude: "-26.286",
    Longitude: "27.8642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7556",
    WardLookupId: "1154",
    VDNumber: "32862269",
    RegisteredPopulation: "1994",
    VotingStationName: "PUTALUSHAKA PRIMARY SCHOOL",
    Address: "MOBANE STREET  CHIAWELO  SOWETO",
    Latitude: "-26.2815",
    Longitude: "27.8612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7557",
    WardLookupId: "1154",
    VDNumber: "32862270",
    RegisteredPopulation: "4228",
    VotingStationName: "VUWANI SECONDARY SCHOOL",
    Address: "FUNDUDZI STREET  CHIAWELO EXT 2  SOWETO/JHB",
    Latitude: "-26.2856",
    Longitude: "27.8556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7558",
    WardLookupId: "1154",
    VDNumber: "32862281",
    RegisteredPopulation: "3432",
    VotingStationName: "BASANI PRIMARY SCHOOL",
    Address: "1781 MHINGA STREET  CHIAWELO EXT 2  SOWETO/JHB",
    Latitude: "-26.2788",
    Longitude: "27.8548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7559",
    WardLookupId: "1154",
    VDNumber: "32862999",
    RegisteredPopulation: "1738",
    VotingStationName: "DIKGABANE SCHOOL",
    Address: "TSHITHUTHUNE ST.  CHIAWELO  JOHANNESBURG",
    Latitude: "-26.2791",
    Longitude: "27.8473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7560",
    WardLookupId: "1154",
    VDNumber: "32863305",
    RegisteredPopulation: "1050",
    VotingStationName: "MAMBO PRIMARY SCHOOL",
    Address: "SHAGA  CHIAWELO EXT 3  CHIAWELO",
    Latitude: "-26.2803",
    Longitude: "27.8536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7561",
    WardLookupId: "1155",
    VDNumber: "32861246",
    RegisteredPopulation: "4092",
    VotingStationName: "TENT AT NEAR PROTEA GLEN POLICE STATION)",
    Address: "PROTEA GLEN EXT 2  PROTEA GLEN  JOHANNESBURG",
    Latitude: "-26.2774",
    Longitude: "27.8174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7562",
    WardLookupId: "1155",
    VDNumber: "32861459",
    RegisteredPopulation: "3411",
    VotingStationName: "THOLIMFUNDO PRIMARY SCHOOL",
    Address:
      "3942 REDCURRENT STREET  PROTEA GLEN EXT 3  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.281",
    Longitude: "27.8219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7563",
    WardLookupId: "1155",
    VDNumber: "32861831",
    RegisteredPopulation: "2388",
    VotingStationName: "BLESSED ISIDOREBAKANJA CATHOLIC CHURCH",
    Address: "2423 BUSH WILLOW  PROTEA GLEN EXT 2  SOWETO",
    Latitude: "-26.2716",
    Longitude: "27.8159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7564",
    WardLookupId: "1155",
    VDNumber: "32862315",
    RegisteredPopulation: "2857",
    VotingStationName: "ITEMOGELE PRIMARY SCHOOL",
    Address: "590 QUININE RD  PROTEA GLEN  JOHANNESBURG",
    Latitude: "-26.2655",
    Longitude: "27.8113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7565",
    WardLookupId: "1155",
    VDNumber: "32863293",
    RegisteredPopulation: "1401",
    VotingStationName: "BASA HIGH SCHOOL",
    Address: "56 INCUNCU STREET  PROTEA GLEN EXT 15  SOWETO",
    Latitude: "-26.2877",
    Longitude: "27.8182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7566",
    WardLookupId: "1155",
    VDNumber: "32870987",
    RegisteredPopulation: "1905",
    VotingStationName: "PROTEA CITY HALL",
    Address: "37/38 TSHUKUDU STREET  PROTEA CITY  JOHANNESBURG",
    Latitude: "-26.2576",
    Longitude: "27.8081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7567",
    WardLookupId: "1156",
    VDNumber: "32860762",
    RegisteredPopulation: "2981",
    VotingStationName: "TENT (OPPOSITE HOUSE NO. 5255)",
    Address: "MATOOBANE STREET  PROTEA GLEN EXT 4  SOWETO",
    Latitude: "-26.2825",
    Longitude: "27.8286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7568",
    WardLookupId: "1156",
    VDNumber: "32862180",
    RegisteredPopulation: "1899",
    VotingStationName: "REBONE  PRIMARY SCHOOL",
    Address: "50 LETSATSI AND RADEBE STREET  NALEDI EXT 2  SOWETO/JHB",
    Latitude: "-26.2561",
    Longitude: "27.8165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7569",
    WardLookupId: "1156",
    VDNumber: "32862191",
    RegisteredPopulation: "1385",
    VotingStationName: "MONICA LOLWANE EDUCARE CENTRE",
    Address: "1731 CNR MAHLANGU & MAZIBUKO STREET  PROTEA NORTH  JOHANNESBURG",
    Latitude: "-26.2681",
    Longitude: "27.8301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7570",
    WardLookupId: "1156",
    VDNumber: "32862292",
    RegisteredPopulation: "2594",
    VotingStationName: "TETELO SECONDARY SCHOOL",
    Address: "LETSATSI RADEBE STREET  PROTEA NORTH  JOHHANESBURG",
    Latitude: "-26.2688",
    Longitude: "27.8291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7571",
    WardLookupId: "1156",
    VDNumber: "32862304",
    RegisteredPopulation: "3721",
    VotingStationName: "KHUTHALA COMBINED SCHOOL",
    Address: "KGALADI STR  PROTEA NORTH  SOWETO",
    Latitude: "-26.2708",
    Longitude: "27.8338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7572",
    WardLookupId: "1156",
    VDNumber: "32862911",
    RegisteredPopulation: "2210",
    VotingStationName: "NALEDI EXT2 SPORT CENTRE.",
    Address: "LETSATSI  NALEDI  SOWETO",
    Latitude: "-26.2599",
    Longitude: "27.82",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7573",
    WardLookupId: "1157",
    VDNumber: "32861549",
    RegisteredPopulation: "2749",
    VotingStationName: "PHIRI SCHOOL",
    Address: "905 CNR MVUNDLA & MABALANE STREET  SENAOANE  SOWETO  JHB",
    Latitude: "-26.2722",
    Longitude: "27.8564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7574",
    WardLookupId: "1157",
    VDNumber: "32861606",
    RegisteredPopulation: "1573",
    VotingStationName: "BAFIKILE PRIMARY SCHOOL",
    Address: "863 SHEZI STREET  SENAOANE  SOWETO",
    Latitude: "-26.2712",
    Longitude: "27.8682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7575",
    WardLookupId: "1157",
    VDNumber: "32861640",
    RegisteredPopulation: "2116",
    VotingStationName: "KGOTSO PRIMARY SCHOOL",
    Address: "639 LEANE STREET  PHIRI  JOHANNESBURG",
    Latitude: "-26.2711",
    Longitude: "27.8516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7576",
    WardLookupId: "1157",
    VDNumber: "32861684",
    RegisteredPopulation: "2984",
    VotingStationName: "ENKANYEZINI SCHOOL",
    Address: "157 RALEBATHA STREET  PHIRI  JOHANNESBURG",
    Latitude: "-26.268",
    Longitude: "27.8567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7577",
    WardLookupId: "1157",
    VDNumber: "32861707",
    RegisteredPopulation: "2235",
    VotingStationName: "EMAWENI PRIMARY SCHOOL",
    Address: "360 MLAZI STR  SENAOANE  JOHANNESBURG",
    Latitude: "-26.2715",
    Longitude: "27.8625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7578",
    WardLookupId: "1157",
    VDNumber: "32861718",
    RegisteredPopulation: "2280",
    VotingStationName: "SIBONGILE PRIMARY SCHOOL",
    Address: "1604 MSOLWA STREET  SENAOANE  ",
    Latitude: "-26.2748",
    Longitude: "27.8625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7579",
    WardLookupId: "1157",
    VDNumber: "32861796",
    RegisteredPopulation: "1560",
    VotingStationName: "SENAOANE SENIOR SCHOOL",
    Address: "1103 UMKONDO ST.  SENAOANE  JOHANNESBURG",
    Latitude: "-26.2746",
    Longitude: "27.8667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7580",
    WardLookupId: "1158",
    VDNumber: "32861651",
    RegisteredPopulation: "1192",
    VotingStationName: "MEGATONG PRIMARY SCHOOL",
    Address: "2984 SEKWATI STREET  MAPETLA EXT  JOHANNESBURG",
    Latitude: "-26.2758",
    Longitude: "27.8499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7581",
    WardLookupId: "1158",
    VDNumber: "32861662",
    RegisteredPopulation: "1941",
    VotingStationName: "MOTSANENG SCHOOL",
    Address: "1350 LEGWALE STR  JOHANNESBURG",
    Latitude: "-26.2683",
    Longitude: "27.8487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7582",
    WardLookupId: "1158",
    VDNumber: "32861673",
    RegisteredPopulation: "1985",
    VotingStationName: "DIODI PRIMARY SCHOOL",
    Address: "886 RALEKGETO STREET  MAPETLA  JOHANNESBURG",
    Latitude: "-26.2653",
    Longitude: "27.8517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7583",
    WardLookupId: "1158",
    VDNumber: "32861741",
    RegisteredPopulation: "1471",
    VotingStationName: "THE AFRICAN PRESBYTERIAN BAFOLISI CHURCH",
    Address: "2973 SKOATI STREET  MAPETLA EXT.  JOHANNESBURG",
    Latitude: "-26.2753",
    Longitude: "27.849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7584",
    WardLookupId: "1158",
    VDNumber: "32861752",
    RegisteredPopulation: "480",
    VotingStationName: "SEANAMARENA HIGH SCHOOL",
    Address: "CNR KOMA & PILANE STREETS  MAPETLA  JOHANNESBURG",
    Latitude: "-26.2646",
    Longitude: "27.8551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7585",
    WardLookupId: "1158",
    VDNumber: "32861763",
    RegisteredPopulation: "2837",
    VotingStationName: "SEDIBATHUTO SCHOOL",
    Address: "2184 PHAALA  MAPETLA  SOJOHANNESBURG",
    Latitude: "-26.2724",
    Longitude: "27.845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7586",
    WardLookupId: "1158",
    VDNumber: "32861774",
    RegisteredPopulation: "1579",
    VotingStationName: "LO-ITEKILO SCHOOL",
    Address: "470 MLOTO STR  MAPETLA EAST  JOHANNESBURG",
    Latitude: "-26.2618",
    Longitude: "27.855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7587",
    WardLookupId: "1158",
    VDNumber: "32861785",
    RegisteredPopulation: "3010",
    VotingStationName: "TENT MAPETLA HOSTEL",
    Address: "MAPETLA  ",
    Latitude: "-26.2647",
    Longitude: "27.8469",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7588",
    WardLookupId: "1159",
    VDNumber: "32841479",
    RegisteredPopulation: "1334",
    VotingStationName: "TENT-OPP HOUSES 84 & 85 ZONE 9 PIMVILLE RDP HOUSES",
    Address: "ZONE 9  SOWETO  PIMVILLE",
    Latitude: "-26.2733",
    Longitude: "27.8887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7589",
    WardLookupId: "1159",
    VDNumber: "32860515",
    RegisteredPopulation: "2062",
    VotingStationName: "ELDORADO PARK PRIMARY SCHOOL",
    Address: "1107 FONTEIN ST  ELDORADO PARK  JOHANNESBURG",
    Latitude: "-26.2954",
    Longitude: "27.8975",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7590",
    WardLookupId: "1159",
    VDNumber: "32860548",
    RegisteredPopulation: "2354",
    VotingStationName: "KLIPTOWN EBENEZER CONGREGATIONAL CHURCH",
    Address: "0001 EAST ROAD  EXT.8  ELDORADO PARK",
    Latitude: "-26.2893",
    Longitude: "27.891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7591",
    WardLookupId: "1159",
    VDNumber: "32860559",
    RegisteredPopulation: "2842",
    VotingStationName: "PARKDALE PRIMARY SCHOOL",
    Address: "C/O KORUND & CUMMING  ELDORADO PARK  JOHANNESBURG",
    Latitude: "-26.2973",
    Longitude: "27.8949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7592",
    WardLookupId: "1159",
    VDNumber: "32860560",
    RegisteredPopulation: "2666",
    VotingStationName: "DELRADO PRIMARY SCHOOL",
    Address: "918 LEIKLIP STREET  ELDORADO PARK  JOHANNESBURG",
    Latitude: "-26.3036",
    Longitude: "27.8949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7593",
    WardLookupId: "1159",
    VDNumber: "32860582",
    RegisteredPopulation: "2554",
    VotingStationName: "KLIPTOWN SECONDARY SCHOOL",
    Address: "1ST ST. JOAN AVE EXT 9  ELDORADO PARK  CITY OF JOHANNESBURG",
    Latitude: "-26.2977",
    Longitude: "27.8858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7594",
    WardLookupId: "1159",
    VDNumber: "32862539",
    RegisteredPopulation: "2459",
    VotingStationName: "ELOHIM COMMUNITY CHURCH",
    Address: "108 GAMSU STREET  EXT.10  KLIPTOWN",
    Latitude: "-26.2851",
    Longitude: "27.8874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7595",
    WardLookupId: "1159",
    VDNumber: "32862551",
    RegisteredPopulation: "2175",
    VotingStationName: "KHAYALETHU CARE CENTRE",
    Address: "58 BEACON RD  KLIPTOWN  JOHANNESBURG",
    Latitude: "-26.2815",
    Longitude: "27.8899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7596",
    WardLookupId: "1160",
    VDNumber: "32860391",
    RegisteredPopulation: "2839",
    VotingStationName: "JD OPPERMAN RECREATION CENTRE",
    Address: "SNEEUBERG ST SOUTH  ELDORADO PARK EXT 2  JOHANNESBURG",
    Latitude: "-26.294",
    Longitude: "27.9204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7597",
    WardLookupId: "1160",
    VDNumber: "32860470",
    RegisteredPopulation: "3150",
    VotingStationName: "BOEKENHOUT PRIMARY SCHOOL",
    Address: "BOEKENHOUT CRESCENT  ELDORADO PARK EXT 3  JOHANNESBURG",
    Latitude: "-26.2987",
    Longitude: "27.9097",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7598",
    WardLookupId: "1160",
    VDNumber: "32860481",
    RegisteredPopulation: "3451",
    VotingStationName: "LANCEAVALE SECONDARY SCHOOL",
    Address: "MILNERTON STREET  ELDORADO PARK EXT 4  JOHANNESBURG",
    Latitude: "-26.2874",
    Longitude: "27.9134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7599",
    WardLookupId: "1160",
    VDNumber: "32860492",
    RegisteredPopulation: "1549",
    VotingStationName: "ELDRIDGE PRIMARY SCHOOL",
    Address: "CITY OF JOHANNESBURG   ",
    Latitude: "-26.2973",
    Longitude: "27.9204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7600",
    WardLookupId: "1160",
    VDNumber: "32860504",
    RegisteredPopulation: "1559",
    VotingStationName: "ELDOMAINE PRIMARY SCHOOL",
    Address: "86 MIRAGE STR  ELDORADO PARK EXT 1  JOHANNESBURG",
    Latitude: "-26.2956",
    Longitude: "27.9074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7601",
    WardLookupId: "1160",
    VDNumber: "32860526",
    RegisteredPopulation: "2564",
    VotingStationName: "CAVENDISH PRIMARY SCHOOL",
    Address: "CAVENDISH STREET  EXT 6 ELDORADO PARK  JOHANNESBURG",
    Latitude: "-26.2852",
    Longitude: "27.9047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7602",
    WardLookupId: "1160",
    VDNumber: "32860537",
    RegisteredPopulation: "2523",
    VotingStationName: "FLORIDA PRIMARY SCHOOL",
    Address: "04 FLORIDA STREET  ELDORADO PARK EXT 7  JOHANNESBURG",
    Latitude: "-26.2819",
    Longitude: "27.8941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7603",
    WardLookupId: "1160",
    VDNumber: "32863248",
    RegisteredPopulation: "869",
    VotingStationName: "ST EUGENE DE MAZENOD CATHOLIC CHURCH",
    Address: "46 HEERENGRACHT STREET EXT 4  ELDORADO PARK  JHB",
    Latitude: "-26.292",
    Longitude: "27.9161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7604",
    WardLookupId: "1161",
    VDNumber: "32860447",
    RegisteredPopulation: "1188",
    VotingStationName: "TENT (FREEMDOM CHARTER SQUARE)",
    Address: "RIVERSIDE SOUTH  FREEDOM CHARTER SQUARE  JOHANNESBURG",
    Latitude: "-26.2786",
    Longitude: "27.8841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7605",
    WardLookupId: "1161",
    VDNumber: "32861482",
    RegisteredPopulation: "1346",
    VotingStationName: "LAKEVIEW PRIMARY SCHOOL",
    Address: "2214 DHLAMINI EXT 2  DHLAMINI  SOWETO",
    Latitude: "-26.2643",
    Longitude: "27.8866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7606",
    WardLookupId: "1161",
    VDNumber: "32861594",
    RegisteredPopulation: "2001",
    VotingStationName: "ISIPHO PRIMARY SCHOOL",
    Address: "144 CHAKA/DINGISWAYO  DHLAMINI  SOWETO",
    Latitude: "-26.2711",
    Longitude: "27.872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7607",
    WardLookupId: "1161",
    VDNumber: "32861617",
    RegisteredPopulation: "2074",
    VotingStationName: "EMADLELWENI SCHOOL",
    Address: "994 FUNEKILE STREET  DHLAMINI 1  JOHANNESBURG",
    Latitude: "-26.2768",
    Longitude: "27.8753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7608",
    WardLookupId: "1161",
    VDNumber: "32861628",
    RegisteredPopulation: "2517",
    VotingStationName: "LILLYDALE PRIMARY SCHOOL",
    Address: "1301 SENDAWANE STREET  DHLAMINI EXT 1  SOWETO JHB",
    Latitude: "-26.2758",
    Longitude: "27.8787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7609",
    WardLookupId: "1161",
    VDNumber: "32861639",
    RegisteredPopulation: "2264",
    VotingStationName: "HLAKANIPHANI SCHOOL",
    Address: "CNR SARILI & JANTJIE STREET  DHLAMINI  SOWETO JHB",
    Latitude: "-26.2723",
    Longitude: "27.879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7610",
    WardLookupId: "1161",
    VDNumber: "32862528",
    RegisteredPopulation: "764",
    VotingStationName: "TENT (OPEN FIELD NEAR PATS)",
    Address:
      "CHRIS HANI CHICKEN FARM INFORMAL SETTLEMENT  NEAR PATS BUILDING MATERIAL  DLAMINI",
    Latitude: "-26.268",
    Longitude: "27.8904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7611",
    WardLookupId: "1161",
    VDNumber: "32862540",
    RegisteredPopulation: "1759",
    VotingStationName: "KLIPTOWN YOUTH PROGRAM",
    Address: "25 FREEDOM CHARTER SQUARE INFORMAL SETTLEMENT  KLIPTOWN  SOWETO",
    Latitude: "-26.2758",
    Longitude: "27.8861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7612",
    WardLookupId: "1161",
    VDNumber: "32863002",
    RegisteredPopulation: "428",
    VotingStationName: "TENT (KLIPTOWN STATION)",
    Address: "NEAR KLIPTOWN STATION  CHICKEN FARM  JOHANNESBURG",
    Latitude: "-26.2704",
    Longitude: "27.8879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7613",
    WardLookupId: "1162",
    VDNumber: "32861932",
    RegisteredPopulation: "2789",
    VotingStationName: "KARABO SCHOOL",
    Address: "SEBOKA STREET  NALEDI  SOWETO JHB",
    Latitude: "-26.2568",
    Longitude: "27.8337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7614",
    WardLookupId: "1162",
    VDNumber: "32862089",
    RegisteredPopulation: "2374",
    VotingStationName: "MARA PRIMARY SCHOOL",
    Address: "NAPE STREET  NALEDI  SOWETO JHB",
    Latitude: "-26.2505",
    Longitude: "27.8286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7615",
    WardLookupId: "1162",
    VDNumber: "32862135",
    RegisteredPopulation: "2226",
    VotingStationName: "IKEMELENG JUNIOR PRIMARY",
    Address: "958 MELATON STREET  NALEDI  SOWETO JHB",
    Latitude: "-26.2519",
    Longitude: "27.8337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7616",
    WardLookupId: "1162",
    VDNumber: "32862157",
    RegisteredPopulation: "2619",
    VotingStationName: "THABA-TSHEHLO PRIMARY SCHOOL",
    Address: "1515A THONG & MONTSHIOA  NALEDI  SOWETO  JHB",
    Latitude: "-26.2542",
    Longitude: "27.8261",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7617",
    WardLookupId: "1162",
    VDNumber: "32862168",
    RegisteredPopulation: "1908",
    VotingStationName: "THOMAS MOFOLO HIGH SCHOOL",
    Address: "2331 LEKARA ST  NALEDI EXT  SOWETO",
    Latitude: "-26.2595",
    Longitude: "27.8285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7618",
    WardLookupId: "1162",
    VDNumber: "32862179",
    RegisteredPopulation: "2757",
    VotingStationName: "KGATELOPELE PRIMARY SCHOOL",
    Address: "2766 MODUTLWA STREET  NALEDI EXTENTION   SOWETO",
    Latitude: "-26.2623",
    Longitude: "27.8323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7619",
    WardLookupId: "1163",
    VDNumber: "32861178",
    RegisteredPopulation: "2185",
    VotingStationName: "MOCHOCHONONO SCHOOL",
    Address: "CNR SEDIBE & PANGWANA ST.  MOLETSANE  SOWETO JHB",
    Latitude: "-26.2558",
    Longitude: "27.8529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7620",
    WardLookupId: "1163",
    VDNumber: "32861875",
    RegisteredPopulation: "1859",
    VotingStationName: "DIKWANKWETLA PRIMARY SCHOOL",
    Address: "MATLAKALA/MOETI  MOLETSANE  SOWETO JHB",
    Latitude: "-26.2505",
    Longitude: "27.8518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7621",
    WardLookupId: "1163",
    VDNumber: "32861886",
    RegisteredPopulation: "1954",
    VotingStationName: "TIHOLOHELO SCHOOL",
    Address: "MOKHOBO & MAKHABO  MOLETSANE  SOWETO JHB",
    Latitude: "-26.2505",
    Longitude: "27.8485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7622",
    WardLookupId: "1163",
    VDNumber: "32861897",
    RegisteredPopulation: "2210",
    VotingStationName: "MATUBENG SCHOOL",
    Address: "TLALE  MOLETSANE  ",
    Latitude: "-26.2579",
    Longitude: "27.8478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7623",
    WardLookupId: "1163",
    VDNumber: "32861909",
    RegisteredPopulation: "2021",
    VotingStationName: "LEITSIBOLO PRIMARY SCHOOL",
    Address: "LEGWALE STR  TLADI  SOWETO",
    Latitude: "-26.2526",
    Longitude: "27.8424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7624",
    WardLookupId: "1163",
    VDNumber: "32861910",
    RegisteredPopulation: "1671",
    VotingStationName: "SETLAKALANA MOLEPO ADULT EDUCATION CENTRE",
    Address: "730 KOMAKO STREET  TLADI  SOWETO",
    Latitude: "-26.2516",
    Longitude: "27.8403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7625",
    WardLookupId: "1163",
    VDNumber: "32861921",
    RegisteredPopulation: "2724",
    VotingStationName: "MERAFE PRIMARY SCHOOL",
    Address: "JOHANNESBURG  ",
    Latitude: "-26.258",
    Longitude: "27.8407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7626",
    WardLookupId: "1163",
    VDNumber: "32862922",
    RegisteredPopulation: "1068",
    VotingStationName: "TLADI ADMINISTRATIVE OFFICE",
    Address: "2071 NTSUNYANA  SOWETO  JOHANNESBURG",
    Latitude: "-26.2613",
    Longitude: "27.8451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7627",
    WardLookupId: "1164",
    VDNumber: "32841086",
    RegisteredPopulation: "3546",
    VotingStationName: "LEIHLO PRIMARY SCHOOL",
    Address: "3436 MBHONGISA STREET ZONE 3  PIMVILLE  SOWETO",
    Latitude: "-26.2645",
    Longitude: "27.8983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7628",
    WardLookupId: "1164",
    VDNumber: "32841097",
    RegisteredPopulation: "2764",
    VotingStationName: "TSHEBEDISANO PRIMARY SCHOOL",
    Address: "2650 ZONE 2  PIMVILLE  JHB",
    Latitude: "-26.2647",
    Longitude: "27.9036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7629",
    WardLookupId: "1164",
    VDNumber: "32841109",
    RegisteredPopulation: "3070",
    VotingStationName: "ST PETER CLAVER SCHOOL",
    Address: "457 MOROBADILEPE STREET ZONE 7  PIMVILLE  SOWETO",
    Latitude: "-26.2722",
    Longitude: "27.901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7630",
    WardLookupId: "1164",
    VDNumber: "32841121",
    RegisteredPopulation: "2288",
    VotingStationName: "WISANI PRIMARY SCHOOL",
    Address: "3897 ZONE 4  PIMVILLE  JHB",
    Latitude: "-26.2737",
    Longitude: "27.8966",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7631",
    WardLookupId: "1164",
    VDNumber: "32841132",
    RegisteredPopulation: "3171",
    VotingStationName: "FARESANI PRIMARY SCHOOL",
    Address: "3779A ZONE 4  PIMVILLE  JHB",
    Latitude: "-26.2706",
    Longitude: "27.8926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7632",
    WardLookupId: "1164",
    VDNumber: "32841143",
    RegisteredPopulation: "2346",
    VotingStationName: "PIMVILLE PRIMARY SCHOOL",
    Address: "137 THAKADU STREET  ZONE 7 PIMVILLE  JOHANNESBURG",
    Latitude: "-26.2698",
    Longitude: "27.9076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7633",
    WardLookupId: "1164",
    VDNumber: "32841480",
    RegisteredPopulation: "1310",
    VotingStationName: "TENT -PIMVILLE GOLF COURSE FLATS",
    Address: "UNION & KLIPSPRUITVALLEY RD  PIMVILLE ZONE 5  SOWETO",
    Latitude: "-26.2761",
    Longitude: "27.8955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7634",
    WardLookupId: "1165",
    VDNumber: "32860290",
    RegisteredPopulation: "4626",
    VotingStationName: "GLENANDA PRIMARY SCHOOL",
    Address: "GLEN AVENUE  GLENANDA  JOHANNESBURG",
    Latitude: "-26.2738",
    Longitude: "28.0394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7635",
    WardLookupId: "1165",
    VDNumber: "32860302",
    RegisteredPopulation: "2745",
    VotingStationName: "BASELINE FAMILY CHURCH BASSONIA AFM",
    Address: "25 PIETER ACROYD AVE  BASSONIA  JOHANNESBURG",
    Latitude: "-26.2707",
    Longitude: "28.0547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7636",
    WardLookupId: "1165",
    VDNumber: "32860368",
    RegisteredPopulation: "2439",
    VotingStationName: "COMARO VALLEY NURSERY SCHOOL",
    Address: "47 MOGG STREET  GLENVISTA EXT 4  JOHANNESBURG",
    Latitude: "-26.299",
    Longitude: "28.0729",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7637",
    WardLookupId: "1165",
    VDNumber: "32860571",
    RegisteredPopulation: "5152",
    VotingStationName: "GLENVISTA HIGH SCHOOL",
    Address: "THE BROADS STREET  GLENVISTA  JOHANNESBURG",
    Latitude: "-26.2921",
    Longitude: "28.0516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7638",
    WardLookupId: "1165",
    VDNumber: "32863013",
    RegisteredPopulation: "3207",
    VotingStationName: "MARONITE CATHOLIC SCHOOL",
    Address: "49 BLOUKOMP CRESCENT  JOHANNESBURG  LIEFDE & VREDE",
    Latitude: "-26.3109",
    Longitude: "28.0609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7639",
    WardLookupId: "1165",
    VDNumber: "32863316",
    RegisteredPopulation: "1058",
    VotingStationName: "MAYFIELD PARK FIRE STATION",
    Address: "IMPALA ROAD  KIBLER PARK  JOHANNESBURG SOUTH",
    Latitude: "-26.3075",
    Longitude: "28.023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7640",
    WardLookupId: "1166",
    VDNumber: "32840894",
    RegisteredPopulation: "2564",
    VotingStationName: "DIEPKLOOF HOSTEL HALL",
    Address: "DIEPKLOOF HOSTEL  ZONE 6 DIEPKLOOF  JOHANNESBURG",
    Latitude: "-26.2596",
    Longitude: "27.9547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7641",
    WardLookupId: "1166",
    VDNumber: "32862573",
    RegisteredPopulation: "1540",
    VotingStationName: "SOMELULWAZI PRIMARY SCHOOL",
    Address: "10322 IQ MISGUND  JOHANNESBURG  FREEDOM PARK INFORMAL SETTLEMENT",
    Latitude: "-26.285",
    Longitude: "27.9308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7642",
    WardLookupId: "1166",
    VDNumber: "32862584",
    RegisteredPopulation: "3123",
    VotingStationName: "TENT (BETWEEN CAR WASH & MALULEKE TUCKSHOP)",
    Address: "PROCESS STR.  FREEDOM PARK   CITY OF JOHANNESBURG",
    Latitude: "-26.2919",
    Longitude: "27.928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7643",
    WardLookupId: "1166",
    VDNumber: "32862595",
    RegisteredPopulation: "2859",
    VotingStationName: "FREEDOM PARK NOAHS ARK CENTRE",
    Address: "6091 EXT. 33  FREEDOM PARK  DEVLAND",
    Latitude: "-26.2877",
    Longitude: "27.9428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7644",
    WardLookupId: "1166",
    VDNumber: "32862618",
    RegisteredPopulation: "3165",
    VotingStationName: "RADIANCE OF GLORIOUS LIVING GOD CHRISTIAN CHURCH",
    Address: "OIL AVENUE & JAN DE NECKER ROA  MAHALA PARK   FREEDOM PARK,JHB",
    Latitude: "-26.2857",
    Longitude: "27.941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7645",
    WardLookupId: "1166",
    VDNumber: "32862629",
    RegisteredPopulation: "1494",
    VotingStationName: "OLD APOSTOLIC CHURCH",
    Address: "100 NO NAME STREET  FREEDOM PARK  DEVLAND",
    Latitude: "-26.2874",
    Longitude: "27.9336",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7646",
    WardLookupId: "1166",
    VDNumber: "32862719",
    RegisteredPopulation: "3797",
    VotingStationName: "TENT-OPP. HOUSE 468   MOTSWALEDI INFORMAL SETTLE",
    Address: "MOTSWALEDI INFORMAL SETTLEMENT   JOHANNESBURG",
    Latitude: "-26.2642",
    Longitude: "27.934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7647",
    WardLookupId: "1166",
    VDNumber: "32862720",
    RegisteredPopulation: "382",
    VotingStationName: "TENT (SAPS GARAGE MARRIED QUARTERS)",
    Address: "NO NAME STREET  DIEPKLOOF  SOWETO",
    Latitude: "-26.2664",
    Longitude: "27.9485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7648",
    WardLookupId: "1166",
    VDNumber: "32863530",
    RegisteredPopulation: "606",
    VotingStationName: "TENT-BETWEEN SHACK 503 &  MOTSWALEDI SOCCER FIELD",
    Address: "DEVLAND  MOTWALEDI INFORMAL SETTLEMENT  JHB",
    Latitude: "-26.2653",
    Longitude: "27.9283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7649",
    WardLookupId: "1167",
    VDNumber: "32841042",
    RegisteredPopulation: "1832",
    VotingStationName: "KLIPSPRUIT HOSTEL",
    Address: "1 MOFOKENG STREET  KLIPSPRUIT  JHB",
    Latitude: "-26.2507",
    Longitude: "27.909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7650",
    WardLookupId: "1167",
    VDNumber: "32841053",
    RegisteredPopulation: "3052",
    VotingStationName: "EMSHUKANTAMBO SECONDARY SCHOOL",
    Address: "1574 MTHANGA STREET  PIMVILLE  JOHANNESBURG",
    Latitude: "-26.2636",
    Longitude: "27.9116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7651",
    WardLookupId: "1167",
    VDNumber: "32841064",
    RegisteredPopulation: "1365",
    VotingStationName: "THE METHODIST CHURCH OF SOUTHERN AFRICA",
    Address: "2273 MOTHLATSOA STREET  PIMVILLE ZONE 2  SOWETO",
    Latitude: "-26.2629",
    Longitude: "27.9049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7652",
    WardLookupId: "1167",
    VDNumber: "32841075",
    RegisteredPopulation: "2025",
    VotingStationName: "ZOE BIBLE CHURCH",
    Address: "11764 NONQAWE STREET   ZONE 6 PIMVILLE  SOWETO",
    Latitude: "-26.2656",
    Longitude: "27.9162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7653",
    WardLookupId: "1167",
    VDNumber: "32841110",
    RegisteredPopulation: "2126",
    VotingStationName: "BATSOGILE PRIMARY SCHOOL",
    Address: "277 MUSI STREET  KLIPSPRUIT  JHB",
    Latitude: "-26.259",
    Longitude: "27.9094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7654",
    WardLookupId: "1167",
    VDNumber: "32841154",
    RegisteredPopulation: "2365",
    VotingStationName: "THEMBU PRIMARY SCHOOL",
    Address: "8325 SIKLELE STREET ZONE 6  PIMVILLE  GAUTENG",
    Latitude: "-26.2734",
    Longitude: "27.912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7655",
    WardLookupId: "1167",
    VDNumber: "32841165",
    RegisteredPopulation: "3766",
    VotingStationName: "WELIZIBUKO PRIMARY SCHOOL",
    Address: "611 KLIPSPRUIT, CNR MOFOKENG & MAKHUBU STR  KLIPSRUIT  JHB",
    Latitude: "-26.2555",
    Longitude: "27.9111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7656",
    WardLookupId: "1167",
    VDNumber: "32863080",
    RegisteredPopulation: "644",
    VotingStationName: "ORLANDO EAST EKHAYA RESIDENCE COMMUNITY HALL",
    Address: "KINGSLEY SITHOLE STREET  ORLANDO EAST  SOWETO",
    Latitude: "-26.2516",
    Longitude: "27.9224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7657",
    WardLookupId: "1167",
    VDNumber: "32863451",
    RegisteredPopulation: "609",
    VotingStationName: "UNIVERSITY OF JHB (SOWETO CAMPUS-IMBIZO HALL)",
    Address: "OLD POTCHEFSTROOM ROAD  PIMVILLE  SOWETO",
    Latitude: "-26.2606",
    Longitude: "27.9238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7658",
    WardLookupId: "1168",
    VDNumber: "32840625",
    RegisteredPopulation: "1399",
    VotingStationName: "KHOMANANI SCHOOL",
    Address: "2062 B ZONE 2  DIEPKLOOF  JOHANNESBURG",
    Latitude: "-26.2501",
    Longitude: "27.9432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7659",
    WardLookupId: "1168",
    VDNumber: "32840838",
    RegisteredPopulation: "1741",
    VotingStationName: "ELITHENI SCHOOL",
    Address: "1729 THAFA STREET  ZONE 1  DIEPKLOOF",
    Latitude: "-26.2455",
    Longitude: "27.9471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7660",
    WardLookupId: "1168",
    VDNumber: "32840872",
    RegisteredPopulation: "2154",
    VotingStationName: "GIYANI PRIMARY SCHOOL",
    Address: "8077 ZONE 6  DIEPKLOOF  JHB",
    Latitude: "-26.2583",
    Longitude: "27.9402",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7661",
    WardLookupId: "1168",
    VDNumber: "32840906",
    RegisteredPopulation: "2393",
    VotingStationName: "JS MPANZA PRIMARY SCHOOL",
    Address: "8295A ZONE 6  DIEPKLOOF  JOHANNESBURG",
    Latitude: "-26.2574",
    Longitude: "27.9459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7662",
    WardLookupId: "1168",
    VDNumber: "32840940",
    RegisteredPopulation: "2417",
    VotingStationName: "EKUTHULENI JUNIOR PRIMARY SCHOOL",
    Address: "5110 HLABISA STR  DIEPKLOOF ZONE 5  JOHANNESBURG",
    Latitude: "-26.253",
    Longitude: "27.9498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7663",
    WardLookupId: "1168",
    VDNumber: "32840951",
    RegisteredPopulation: "2068",
    VotingStationName: "VULAMAZIBUKO SCHOOL",
    Address: "2062 TSHUKUDU  DIEPKLOOF  CITYOFJOHANNEBURG",
    Latitude: "-26.2486",
    Longitude: "27.9442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7664",
    WardLookupId: "1168",
    VDNumber: "32841435",
    RegisteredPopulation: "878",
    VotingStationName: "RENA LE LONA CREATIVE CENTRE FOR CHILDREN",
    Address: "JOHANNESBURG  ",
    Latitude: "-26.2583",
    Longitude: "27.949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7665",
    WardLookupId: "1168",
    VDNumber: "32841446",
    RegisteredPopulation: "1635",
    VotingStationName: "DIEPKLOOF LIBRARY",
    Address: "5244 BEN NAUDE DRIVE  DIEPKLOOL ZONE 5 1864  SOWETO",
    Latitude: "-26.2496",
    Longitude: "27.9514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7666",
    WardLookupId: "1169",
    VDNumber: "32840917",
    RegisteredPopulation: "1608",
    VotingStationName: "THABISILE SCHOOL",
    Address: "5793 NONGOMA ST  DIEPKLOOF  GAUTENG",
    Latitude: "-26.2464",
    Longitude: "27.9525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7667",
    WardLookupId: "1169",
    VDNumber: "32840939",
    RegisteredPopulation: "1561",
    VotingStationName: "FIDELITAS COMPREHENSIVE SCHOOL",
    Address: "5792 ZONE 5  DIEPKLOOF  GAUTENG",
    Latitude: "-26.2457",
    Longitude: "27.9517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7668",
    WardLookupId: "1169",
    VDNumber: "32840962",
    RegisteredPopulation: "2268",
    VotingStationName: "FONS LUMINIS SECONDARY SCHOOL",
    Address: "834 DIEPKLOOF  PHASE 3  JOHANNESBURG",
    Latitude: "-26.2513",
    Longitude: "27.9566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7669",
    WardLookupId: "1169",
    VDNumber: "32840973",
    RegisteredPopulation: "2030",
    VotingStationName: "CLUB HOUSE PHASE 1",
    Address: "ZONE 5  DIEPKLOOF  ",
    Latitude: "-26.2468",
    Longitude: "27.9576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7670",
    WardLookupId: "1169",
    VDNumber: "32840984",
    RegisteredPopulation: "2165",
    VotingStationName: "BOPANANG PRIMARY SCHOOL",
    Address: "6939 TAUNG STREET  DIEPKLOOF  JHB",
    Latitude: "-26.2423",
    Longitude: "27.9586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7671",
    WardLookupId: "1169",
    VDNumber: "32840995",
    RegisteredPopulation: "2396",
    VotingStationName: "EKHAYA CENTRE",
    Address: "628 RUSTENBURG ROAD   ZONE 4 DIEPKLOOF  SOWETO",
    Latitude: "-26.24",
    Longitude: "27.9579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7672",
    WardLookupId: "1169",
    VDNumber: "32841008",
    RegisteredPopulation: "2225",
    VotingStationName: "IKANENG PRIMARY SCHOOL",
    Address: "6469 LENONG STREET ZONE 4, LENONG STR  DIEPKLOOF  JHB",
    Latitude: "-26.2378",
    Longitude: "27.954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7673",
    WardLookupId: "1169",
    VDNumber: "32841019",
    RegisteredPopulation: "2675",
    VotingStationName: "UNITING REFORMED CHURCH IN SA",
    Address: "4689 RED SHOW STREET  ZONE 4 DIEPKLOOF  SOWETO",
    Latitude: "-26.2404",
    Longitude: "27.9528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7674",
    WardLookupId: "1169",
    VDNumber: "32863114",
    RegisteredPopulation: "1024",
    VotingStationName: "TENT-OPP. HOUSE 4948  MAKHURA STREET MOGASEVIEW",
    Address: "4948 MAKHURA STREET  MOGASE VIEW, DIEPKLOOF EXT 10  SOWETO",
    Latitude: "-26.2338",
    Longitude: "27.9543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7675",
    WardLookupId: "1170",
    VDNumber: "32840636",
    RegisteredPopulation: "1212",
    VotingStationName: "IMMANUEL EVANGELICAL LUTHERAN CHURCH",
    Address: "3002 LEHLOHONOLO STREET ZONE 2   DIEPKLOOF  SOWETO",
    Latitude: "-26.2441",
    Longitude: "27.9378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7676",
    WardLookupId: "1170",
    VDNumber: "32840782",
    RegisteredPopulation: "1763",
    VotingStationName: "JOB RATHEBE JUNIOR SECONDARY SCHOOL",
    Address: "1724 THIBEDI  ORLANDO EAST  JHB",
    Latitude: "-26.2388",
    Longitude: "27.9338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7677",
    WardLookupId: "1170",
    VDNumber: "32840793",
    RegisteredPopulation: "1962",
    VotingStationName: "QHOBOSHEANE PRIMARY SCHOOL",
    Address: "JOHANNESBURG  ",
    Latitude: "-26.2412",
    Longitude: "27.939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7678",
    WardLookupId: "1170",
    VDNumber: "32840827",
    RegisteredPopulation: "1559",
    VotingStationName: "DIEPKLOOF ADULT EDUCATION CENTRE",
    Address:
      "722 CNR NJAMBIYI AND SHIRULUNI STREET  DIEPKLOOF ZONE 1  JOHANNESBURG",
    Latitude: "-26.2409",
    Longitude: "27.9432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7679",
    WardLookupId: "1170",
    VDNumber: "32840849",
    RegisteredPopulation: "2080",
    VotingStationName: "DUMEZWENI JUNIOR PRIMARY SCHOOL",
    Address: "1536 PHUMULO STREET  ZONE 1  DIEPKLOOF",
    Latitude: "-26.2449",
    Longitude: "27.9448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7680",
    WardLookupId: "1170",
    VDNumber: "32840850",
    RegisteredPopulation: "2987",
    VotingStationName: "TIYANE JUNIOR SECONDARY SCHOOL",
    Address: "JOHANNESBURG  ",
    Latitude: "-26.2474",
    Longitude: "27.9383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7681",
    WardLookupId: "1170",
    VDNumber: "32841031",
    RegisteredPopulation: "2668",
    VotingStationName: "DIEPDALE SECONDARY SCHOOL",
    Address: "3318B DIPPENAAR STREET  ZONE 2  DIEPKLOOF",
    Latitude: "-26.248",
    Longitude: "27.9376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7682",
    WardLookupId: "1171",
    VDNumber: "32840300",
    RegisteredPopulation: "3353",
    VotingStationName: "NOORDGESIG PRIMARY SCHOOL",
    Address: "800 BERGROOS STR  NOORDGESIG  JOHANNESBURG",
    Latitude: "-26.2311",
    Longitude: "27.9377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7683",
    WardLookupId: "1171",
    VDNumber: "32840647",
    RegisteredPopulation: "2154",
    VotingStationName: "LOFENTSE SECONDARY SCHOOL",
    Address: "6442 MOOKI  ORLANDO EAST  ",
    Latitude: "-26.2274",
    Longitude: "27.9279",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7684",
    WardLookupId: "1171",
    VDNumber: "32840805",
    RegisteredPopulation: "2508",
    VotingStationName: "GOOD NEW BIBLE CHURCH",
    Address: "MPANZA STREET  ORLANDO EAST  SOWETO",
    Latitude: "-26.2318",
    Longitude: "27.9366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7685",
    WardLookupId: "1171",
    VDNumber: "32840861",
    RegisteredPopulation: "1717",
    VotingStationName: "IPOLOKENG PRIMARY SCHOOL",
    Address: "3707 MOTAUNG STREET ZONE 3  DIEPKLOOF  SOWETO",
    Latitude: "-26.2367",
    Longitude: "27.9457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7686",
    WardLookupId: "1171",
    VDNumber: "32840883",
    RegisteredPopulation: "1686",
    VotingStationName: "NAMEDI SECONDARY SCHOOL",
    Address: "4140C TSOTETSI STREET ZONE 3  DIEPKLOOF  JHB",
    Latitude: "-26.2375",
    Longitude: "27.9482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7687",
    WardLookupId: "1171",
    VDNumber: "32840928",
    RegisteredPopulation: "870",
    VotingStationName: "DIEPKLOOF MULTIPURPOSE HALL",
    Address: "5985 EBEN CUYLER DRIVE  ZONE 3 DIEPKLOOF  SOWETO",
    Latitude: "-26.2419",
    Longitude: "27.948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7688",
    WardLookupId: "1171",
    VDNumber: "32841020",
    RegisteredPopulation: "2228",
    VotingStationName: "THE REFORMED PRESBYTERIAN CHURCH IN SOUTHERN AFRICA",
    Address: "4267 ZONE 3  DIEPKLOOF  SOWETO",
    Latitude: "-26.2344",
    Longitude: "27.9492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7689",
    WardLookupId: "1171",
    VDNumber: "32841615",
    RegisteredPopulation: "1035",
    VotingStationName: "TENT (PENNYVILLE PHASE 3)",
    Address: "GEMSBOK (BTW NO 96 & 97)  PENNYVILLE PHASE 3  JOHANNESBURG",
    Latitude: "-26.2198",
    Longitude: "27.9367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7690",
    WardLookupId: "1171",
    VDNumber: "32841727",
    RegisteredPopulation: "1080",
    VotingStationName: "ST. ANDREWS ROMAN CATHOLIC CHURCH",
    Address: "50 PARK ROAD  NOORDGESIG  CITY OF JHB",
    Latitude: "-26.2253",
    Longitude: "27.9333",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7691",
    WardLookupId: "1172",
    VDNumber: "32840658",
    RegisteredPopulation: "1175",
    VotingStationName: "LUKHOLWENI PRIMARY SCHOOL",
    Address: "6604A MADLALA  ORLANDO EAST  JOHANNESBURG",
    Latitude: "-26.249",
    Longitude: "27.9224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7692",
    WardLookupId: "1172",
    VDNumber: "32840670",
    RegisteredPopulation: "2321",
    VotingStationName: "ASSEMBLIES OF GOD",
    Address: "5720 MOSAKA STREET  ORLANDO EAST  JOHANNESBURG",
    Latitude: "-26.2482",
    Longitude: "27.9319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7693",
    WardLookupId: "1172",
    VDNumber: "32840692",
    RegisteredPopulation: "2097",
    VotingStationName: "ST JOHN BERCHMAN SCHOOL",
    Address: "5333 KHUZWAYO  ORLANDO EAST  GAUTENG",
    Latitude: "-26.2456",
    Longitude: "27.9276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7694",
    WardLookupId: "1172",
    VDNumber: "32840715",
    RegisteredPopulation: "2412",
    VotingStationName: "ZAKHENI PRIMARY SCHOOL",
    Address: "6600 MADLALA STREET  ORLANDO EAST  SOWETO",
    Latitude: "-26.2455",
    Longitude: "27.9149",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7695",
    WardLookupId: "1172",
    VDNumber: "32840726",
    RegisteredPopulation: "2337",
    VotingStationName:
      "SOWETO RETIRED PROFESSIONAL SOCIETY T/A FOOTPRINTS HOSPICE",
    Address: "785 LETSATSI STREET  ORLANDO EAST  SOWETO",
    Latitude: "-26.2402",
    Longitude: "27.9229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7696",
    WardLookupId: "1172",
    VDNumber: "32840737",
    RegisteredPopulation: "1693",
    VotingStationName: "HERBERT MDINGI ADULT CENTRE",
    Address: "6303 MADLALA STREET  ORLANDO EAST  JOHANNESBURG",
    Latitude: "-26.2487",
    Longitude: "27.922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7697",
    WardLookupId: "1172",
    VDNumber: "32840748",
    RegisteredPopulation: "2034",
    VotingStationName: "UNITED CONGREGATIONAL CHURCH",
    Address: "4077 MACFAYDEN STREET  ORLANDO EAST  JOHANNESBURG",
    Latitude: "-26.2412",
    Longitude: "27.9183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7698",
    WardLookupId: "1172",
    VDNumber: "32841345",
    RegisteredPopulation: "1630",
    VotingStationName: "EVANGELICAL LUTHERAN CHURCH ORLANDO EAST PARISH",
    Address: "5408 TEMA STREET  ORLANDO EAST  SOWETO",
    Latitude: "-26.2484",
    Longitude: "27.9265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7699",
    WardLookupId: "1172",
    VDNumber: "32841806",
    RegisteredPopulation: "1371",
    VotingStationName: "NOMZAMO COMMUNITY CLUB",
    Address: "285 NOMZAMO PARK  ORLANDO EAST   SOWETO",
    Latitude: "-26.2479",
    Longitude: "27.9144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7700",
    WardLookupId: "1173",
    VDNumber: "32840681",
    RegisteredPopulation: "2444",
    VotingStationName: "AFRICAN CONGREGATIONAL CHURCH",
    Address: "2402 MASUPHA ST  ORLANDO EAST  JOHANNESBURG",
    Latitude: "-26.243",
    Longitude: "27.9293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7701",
    WardLookupId: "1173",
    VDNumber: "32840704",
    RegisteredPopulation: "1466",
    VotingStationName: "AME CHURCH",
    Address: "1723 MSIMANGO STREET  ORLANDO EAST  JOHANNESBURG",
    Latitude: "-26.2424",
    Longitude: "27.926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7702",
    WardLookupId: "1173",
    VDNumber: "32840759",
    RegisteredPopulation: "1335",
    VotingStationName: "THEMBALIHLE SCHOOL",
    Address: "2227 NAKENE  ORLANDO EAST  JOHANNESBURG",
    Latitude: "-26.2389",
    Longitude: "27.9322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7703",
    WardLookupId: "1173",
    VDNumber: "32840760",
    RegisteredPopulation: "1644",
    VotingStationName: "LERESCHE PRIMARY SCHOOL",
    Address: "6501 ADAMS STREET  ORLANDO EAST  JOHANNESBURG",
    Latitude: "-26.2302",
    Longitude: "27.9275",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7704",
    WardLookupId: "1173",
    VDNumber: "32840771",
    RegisteredPopulation: "2564",
    VotingStationName: "MUNICIPAL MULTIPURPOSE COMMUNITY CENTRE",
    Address: "1424 SOFASONKE STR  ORLANDO EAST  SOWETO",
    Latitude: "-26.2375",
    Longitude: "27.9282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7705",
    WardLookupId: "1173",
    VDNumber: "32840816",
    RegisteredPopulation: "2333",
    VotingStationName: "ORLANDO EAST COMMUNAL HALL",
    Address: "129 ADAM STREET  ORLANDO EAST  SOWETO",
    Latitude: "-26.2353",
    Longitude: "27.9233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7706",
    WardLookupId: "1173",
    VDNumber: "32841356",
    RegisteredPopulation: "1850",
    VotingStationName: "TENT (CNR NKOMO -YA-HLABE & MPANE)",
    Address: "CNR NKOMO - YA HLABE & MPANE  ORLANDO EAST  SOWETO",
    Latitude: "-26.2344",
    Longitude: "27.9329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7707",
    WardLookupId: "1174",
    VDNumber: "32850727",
    RegisteredPopulation: "5391",
    VotingStationName: "BUCCLEUCH PRIMARY SCHOOL",
    Address: "2 BEATTY STREET  BUCCLEUCH  JOHANNESBURG",
    Latitude: "-26.0539",
    Longitude: "28.1008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7708",
    WardLookupId: "1174",
    VDNumber: "32850930",
    RegisteredPopulation: "795",
    VotingStationName: "KINGS SCHOOL - LINBRO PARK",
    Address: "134 RONALD RD CNR 1ST  LINBRO PARK  JOHANNESBURG",
    Latitude: "-26.098",
    Longitude: "28.1268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7709",
    WardLookupId: "1174",
    VDNumber: "32900105",
    RegisteredPopulation: "2283",
    VotingStationName: "HERITAGE CHRISTIAN COLLEGE",
    Address: "BOND STREET,OFF CASINO ROAD  MODDERFONTEIN  JOHANNESBURG",
    Latitude: "-26.0986",
    Longitude: "28.1658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7710",
    WardLookupId: "1174",
    VDNumber: "32900240",
    RegisteredPopulation: "5119",
    VotingStationName: "KLIPFONTEIN VIEW MULTI-PURPOSE CENTRE",
    Address: "MODIMONTHUSE STREET  KLIPFONTEIN VIEW EXT 1  JOHANNESBURG",
    Latitude: "-26.0504",
    Longitude: "28.1603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7711",
    WardLookupId: "1174",
    VDNumber: "32900284",
    RegisteredPopulation: "6165",
    VotingStationName: "GREEN VALLEY SHOPPING CENTRE",
    Address: "31 CONER GREENSTONE & STONERIDGE   GREENHILLS  JOHANNESBURG",
    Latitude: "-26.1179",
    Longitude: "28.156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7712",
    WardLookupId: "1174",
    VDNumber: "32910476",
    RegisteredPopulation: "1379",
    VotingStationName: "JUKSKEI VIEW TENT",
    Address: "ALLANDALE & MASTIFF  MIDRAND - JUKSKEI VIEW  JOHANNESBURG",
    Latitude: "-26.042",
    Longitude: "28.144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7713",
    WardLookupId: "1175",
    VDNumber: "32861392",
    RegisteredPopulation: "2041",
    VotingStationName: "JOHANNESBURG CENTRAL TEACHER DEVELOPMENT CENTRE",
    Address: "2035 VUNDLA DRIVE  MOROKA  SOWETO JHB",
    Latitude: "-26.2659",
    Longitude: "27.8746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7714",
    WardLookupId: "1175",
    VDNumber: "32861448",
    RegisteredPopulation: "910",
    VotingStationName: "ELKA SPORTS CLUB",
    Address: "VUNDLA STREET  MOROKA SOWETO  JOHANNESBURG",
    Latitude: "-26.2632",
    Longitude: "27.8709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7715",
    WardLookupId: "1175",
    VDNumber: "32861460",
    RegisteredPopulation: "1912",
    VotingStationName: "MOLALATLADI SCHOOL",
    Address: "LEFATOLA STREET  MOROKA  SOWETO JHB",
    Latitude: "-26.2635",
    Longitude: "27.8672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7716",
    WardLookupId: "1175",
    VDNumber: "32861471",
    RegisteredPopulation: "2122",
    VotingStationName: "SEFIKA PRIMARY SCHOOL",
    Address: "1074 GOYAPELE STREET  MOLAPO  SOWETO JHB",
    Latitude: "-26.2608",
    Longitude: "27.8619",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7717",
    WardLookupId: "1175",
    VDNumber: "32861493",
    RegisteredPopulation: "3205",
    VotingStationName: "HOERNLE PRIMARY SCHOOL",
    Address: "KUNENE & MKHIZE  MOROKA  SOWETO JHB",
    Latitude: "-26.2599",
    Longitude: "27.879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7718",
    WardLookupId: "1175",
    VDNumber: "32861695",
    RegisteredPopulation: "1817",
    VotingStationName: "SOUTH WEST GAUTENG COLLEGE",
    Address: "KOMA & MOLELE STRS  MOLAPO  SOWETO JHB",
    Latitude: "-26.2649",
    Longitude: "27.8607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7719",
    WardLookupId: "1175",
    VDNumber: "32861729",
    RegisteredPopulation: "1979",
    VotingStationName: "REUTLWILE JUNIOR SECONDARY SCHOOL",
    Address: "708 THEBE STR  MOLAPO  JOHANNESBURG",
    Latitude: "-26.2587",
    Longitude: "27.8592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7720",
    WardLookupId: "1176",
    VDNumber: "32860739",
    RegisteredPopulation: "3480",
    VotingStationName: "TENT- JABULANI HOSTEL",
    Address: "BOLANI  STREET  JABULANI  SOWETO",
    Latitude: "-26.2443",
    Longitude: "27.8647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7721",
    WardLookupId: "1176",
    VDNumber: "32861437",
    RegisteredPopulation: "2383",
    VotingStationName: "ISAACSON PRIMARY SCHOOL",
    Address: "220 MAPHETO STREET  MOROKA  SOWETO JHB",
    Latitude: "-26.2577",
    Longitude: "27.8695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7722",
    WardLookupId: "1176",
    VDNumber: "32861505",
    RegisteredPopulation: "1966",
    VotingStationName: "THESELE SECONDARY SCHOOL.",
    Address: "437 DOKANE DR  WHITE CITY  SOWETO",
    Latitude: "-26.2513",
    Longitude: "27.8703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7723",
    WardLookupId: "1176",
    VDNumber: "32861527",
    RegisteredPopulation: "2036",
    VotingStationName: "MXOLISI SCHOOL",
    Address: "475 DLAMINI STREET  JABULANE  JOHANNESBURG",
    Latitude: "-26.2398",
    Longitude: "27.8618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7724",
    WardLookupId: "1176",
    VDNumber: "32861730",
    RegisteredPopulation: "2806",
    VotingStationName: "M MPHAHLELE SCHOOL",
    Address: "MASIANE STR  MOLAPO  SOWETO",
    Latitude: "-26.2556",
    Longitude: "27.863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7725",
    WardLookupId: "1176",
    VDNumber: "32862506",
    RegisteredPopulation: "1841",
    VotingStationName: "JABULANI CIVIC CENTRE",
    Address: "1 KOMA ROAD  JABULANI  SOWETO",
    Latitude: "-26.2508",
    Longitude: "27.8546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7726",
    WardLookupId: "1177",
    VDNumber: "32861370",
    RegisteredPopulation: "1509",
    VotingStationName: "RUTEGANG PRIMARY SCHOOL",
    Address: "3115 B NTSOKO STREET  WHITE CITY JABAVU  JHB",
    Latitude: "-26.2424",
    Longitude: "27.8795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7727",
    WardLookupId: "1177",
    VDNumber: "32861404",
    RegisteredPopulation: "1721",
    VotingStationName: "MAKOLA PRIMARY SCHOOL",
    Address: "345 EMHLANGENI  CENTRAL WESTERN JABAVU  CENTRAL WESTERN JABAVU",
    Latitude: "-26.2412",
    Longitude: "27.8737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7728",
    WardLookupId: "1177",
    VDNumber: "32861516",
    RegisteredPopulation: "3416",
    VotingStationName: "MORRIS ISAACSON HIGH SCHOOL",
    Address: "MPHUTI & LIMAKATSO  JABAVU  JOHANNESBURG",
    Latitude: "-26.2458",
    Longitude: "27.8721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7729",
    WardLookupId: "1177",
    VDNumber: "32861538",
    RegisteredPopulation: "1157",
    VotingStationName: "SYDNEY MASEKO ADULT LEARNING CENTRE",
    Address: "3134 DIOKANE STREET  CENTRAL WESTERNJABAVU  JOHANNESBURG",
    Latitude: "-26.2442",
    Longitude: "27.8769",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7730",
    WardLookupId: "1177",
    VDNumber: "32861550",
    RegisteredPopulation: "1812",
    VotingStationName: "MOLAETSA PRIMARY SCHOOL",
    Address: "3130 DIOKANE DRIVE  WHITE CITY JABAVU  SOWETO",
    Latitude: "-26.2512",
    Longitude: "27.8729",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7731",
    WardLookupId: "1177",
    VDNumber: "32861572",
    RegisteredPopulation: "2882",
    VotingStationName: "ITEKENG SCHOOL",
    Address: "PHERA & MJIKELANE  JABAVU  ",
    Latitude: "-26.2515",
    Longitude: "27.8796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7732",
    WardLookupId: "1177",
    VDNumber: "32863259",
    RegisteredPopulation: "688",
    VotingStationName: "SALVATION ARMY JABAVU BRIDGMAN CRECHE",
    Address: "883B CORNER NDALA & MANNE STREET  WHITECITY  SOWETO",
    Latitude: "-26.2473",
    Longitude: "27.8794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7733",
    WardLookupId: "1178",
    VDNumber: "32861279",
    RegisteredPopulation: "2137",
    VotingStationName: "VUKUZENZELE PRIMARY SCHOOL",
    Address: "999 999 SIGASA STR  MOFOLO NORTH  MOFOLO NORTH",
    Latitude: "-26.2348",
    Longitude: "27.8778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7734",
    WardLookupId: "1178",
    VDNumber: "32861280",
    RegisteredPopulation: "2239",
    VotingStationName: "MOKOROTLO PRIMARY SCHOOL",
    Address: "540 LEGOAMOLLE MOLAPO DRIVE  MOFOLO VILLAGE  SOWETO",
    Latitude: "-26.2325",
    Longitude: "27.8852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7735",
    WardLookupId: "1178",
    VDNumber: "32861291",
    RegisteredPopulation: "1811",
    VotingStationName: "TSHEDIMOSETSO MEHLALENG PRIMARY SCHOOL",
    Address: "NKOSI  MOFOLO CENTRAL  SOWETO",
    Latitude: "-26.2496",
    Longitude: "27.8874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7736",
    WardLookupId: "1178",
    VDNumber: "32861347",
    RegisteredPopulation: "1554",
    VotingStationName: "EMISEBENI PRIMARY SCHOOL",
    Address: "148 SKOSANA STREET  MOFOLO VILLAGE  SOWETO",
    Latitude: "-26.2372",
    Longitude: "27.8816",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7737",
    WardLookupId: "1178",
    VDNumber: "32861358",
    RegisteredPopulation: "2106",
    VotingStationName: "IKWEZI PRIMARY SCHOOL",
    Address: "1671 MAHLOBO STREET  MOFOLO NORTH  JHB",
    Latitude: "-26.2285",
    Longitude: "27.8831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7738",
    WardLookupId: "1178",
    VDNumber: "32861369",
    RegisteredPopulation: "1365",
    VotingStationName: "MOFOLO ART CENTRE",
    Address: "1209 MOFOLO PARK  MOFOLO  SOWETO",
    Latitude: "-26.2433",
    Longitude: "27.8872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7739",
    WardLookupId: "1178",
    VDNumber: "32862933",
    RegisteredPopulation: "783",
    VotingStationName: "ST. PIUS X CATHOLIC CHURCH",
    Address: "MSHENGUVILLE INFORMAL SETTLEME  SOWETO  JOHANNESBURG",
    Latitude: "-26.2419",
    Longitude: "27.8819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7740",
    WardLookupId: "1178",
    VDNumber: "32863091",
    RegisteredPopulation: "1075",
    VotingStationName: "ST JOSEPH ANGLICAN CHURCH",
    Address: "ITSHENGE & SKOSANA STREETS  CENTRAL WESTERN JABAVU  SOWETO",
    Latitude: "-26.2351",
    Longitude: "27.876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7741",
    WardLookupId: "1178",
    VDNumber: "32863260",
    RegisteredPopulation: "929",
    VotingStationName: "TENT (OPPOSITE HOUSE 506C WHITE CITY JABAVU)",
    Address: "LEPELLE STREET  WHITE CITY JABAVU  SOWETO",
    Latitude: "-26.2494",
    Longitude: "27.8824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7742",
    WardLookupId: "1179",
    VDNumber: "32841211",
    RegisteredPopulation: "2406",
    VotingStationName: "TENT NANCEFIELD HOSTEL LIFATEN",
    Address: "1 MOFOKENG STR  KLIPSPRUIT  SOWETO",
    Latitude: "-26.2537",
    Longitude: "27.8989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7743",
    WardLookupId: "1179",
    VDNumber: "32861257",
    RegisteredPopulation: "3107",
    VotingStationName: "IGUGU PRIMARY SCHOOL",
    Address: "732 ELIAS MOTSOALEDI ROAD  MOFOLO SOUTH  SOWTO",
    Latitude: "-26.256",
    Longitude: "27.8863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7744",
    WardLookupId: "1179",
    VDNumber: "32861268",
    RegisteredPopulation: "1602",
    VotingStationName: "SUNSHINE CENTRE",
    Address: "CRUTSE & RAMAITE  JABAVU  SOWETO",
    Latitude: "-26.2574",
    Longitude: "27.879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7745",
    WardLookupId: "1179",
    VDNumber: "32861426",
    RegisteredPopulation: "1948",
    VotingStationName: "THANDANANI DAY CARE CENTRE",
    Address: "FRED CLARK INFORMAL SETTLEMENT  FRED CLARK - PIMVILLE  SOWETO",
    Latitude: "-26.2611",
    Longitude: "27.8918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7746",
    WardLookupId: "1179",
    VDNumber: "32861561",
    RegisteredPopulation: "2941",
    VotingStationName: "DONALDSON PRIMARY SCHOOL",
    Address: "1965 TSHABANGU DRIVE  JABAVU  SOWETO",
    Latitude: "-26.2538",
    Longitude: "27.8741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7747",
    WardLookupId: "1179",
    VDNumber: "32861583",
    RegisteredPopulation: "1474",
    VotingStationName: "THUBELIHLE INTERMEDIARY SCHOOL",
    Address: "1436 NJHKELANE STREET  JABAVU  SOWETO",
    Latitude: "-26.2539",
    Longitude: "27.879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7748",
    WardLookupId: "1179",
    VDNumber: "32862494",
    RegisteredPopulation: "2931",
    VotingStationName: "BOXING ACADEMY",
    Address: "CNR KLIPSPRUIT & MOROKO RD  MOFOLO  SOWETO",
    Latitude: "-26.2525",
    Longitude: "27.9006",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7749",
    WardLookupId: "1180",
    VDNumber: "32861145",
    RegisteredPopulation: "2081",
    VotingStationName: "SYDNEY MASEKO ADULT LEARNING CENTRE",
    Address: "2219 CHALKER AVENUE  DUBE  JHB",
    Latitude: "-26.241",
    Longitude: "27.8981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7750",
    WardLookupId: "1180",
    VDNumber: "32861190",
    RegisteredPopulation: "1782",
    VotingStationName: "NKA-THUTO PRIMARY SCHOOL",
    Address: "1228 NCUBE & MZILIKAZI  DUBE  JHB",
    Latitude: "-26.2455",
    Longitude: "27.8927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7751",
    WardLookupId: "1180",
    VDNumber: "32861202",
    RegisteredPopulation: "1331",
    VotingStationName: "DR BEYERS NAUDE SECONDARY SCHOOL",
    Address: "1380 PIONEER  DUBE  SOWETO",
    Latitude: "-26.2424",
    Longitude: "27.8957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7752",
    WardLookupId: "1180",
    VDNumber: "32861213",
    RegisteredPopulation: "2172",
    VotingStationName: "DALIWONGA SECONDARY SCHOOL",
    Address: "201 KOMA  DUBE  DUBE",
    Latitude: "-26.232",
    Longitude: "27.8885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7753",
    WardLookupId: "1180",
    VDNumber: "32861224",
    RegisteredPopulation: "2521",
    VotingStationName: "THABISANG PRIMARY SCHOOL",
    Address: "11227 RAMARULA  ORLANDO WEST EXT.  ORLANDO WEST EXT.",
    Latitude: "-26.2424",
    Longitude: "27.9027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7754",
    WardLookupId: "1180",
    VDNumber: "32863271",
    RegisteredPopulation: "700",
    VotingStationName: "SIZANANI PRIMARY SCHOOL",
    Address: "599 NCWANA STREET  DUBE VILLAGE  SOWETO",
    Latitude: "-26.2372",
    Longitude: "27.8897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7755",
    WardLookupId: "1180",
    VDNumber: "32871078",
    RegisteredPopulation: "3628",
    VotingStationName: "DUBE HOSTEL HALL",
    Address: "2333 DUBE HOSTEL  DUBE HOSTEL  CITY OF JOHANNESBURG",
    Latitude: "-26.2344",
    Longitude: "27.8973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7756",
    WardLookupId: "1181",
    VDNumber: "32861235",
    RegisteredPopulation: "1484",
    VotingStationName: "ORLANDO WEST SECONDARY SCHOOL",
    Address: "7339 VILAKAZI STREET  ORLANDO WEST  JOHANNESBURG",
    Latitude: "-26.2388",
    Longitude: "27.9065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7757",
    WardLookupId: "1181",
    VDNumber: "32861303",
    RegisteredPopulation: "2717",
    VotingStationName: "SAPEBUSO PRIMARY SCHOOL",
    Address: "9392 MOPHIRING STREET  ORLANDO WEST  JHB",
    Latitude: "-26.2264",
    Longitude: "27.9174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7758",
    WardLookupId: "1181",
    VDNumber: "32861314",
    RegisteredPopulation: "2128",
    VotingStationName: "ORLANDO WEST WOMENS HOSTEL HALL",
    Address: "11020 MOKHELE & NKUNGU  STREETS  ORLANDO WEST 2  JOHANNESBURG",
    Latitude: "-26.2246",
    Longitude: "27.9246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7759",
    WardLookupId: "1181",
    VDNumber: "32861325",
    RegisteredPopulation: "2622",
    VotingStationName: "MZAMO PRIMARY SCHOOL",
    Address: "8300 SISULU STR  ORLANDO WEST  ORLANDO WEST",
    Latitude: "-26.2333",
    Longitude: "27.9072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7760",
    WardLookupId: "1181",
    VDNumber: "32861336",
    RegisteredPopulation: "2140",
    VotingStationName: "KWA-NTSIKANA JUNIOR SECONDARY SCHOOL",
    Address: "11901 ARMITAGE STREET  ORLANDO WEST  JOHANNESBURG",
    Latitude: "-26.2385",
    Longitude: "27.9129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7761",
    WardLookupId: "1181",
    VDNumber: "32863327",
    RegisteredPopulation: "602",
    VotingStationName: "EHLATHINI (ASHA) PRE SCHOOL",
    Address: "11744 MOEKETSI STREET  ORLANDO WEST EXT  SOWETO",
    Latitude: "-26.2418",
    Longitude: "27.9069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7762",
    WardLookupId: "1181",
    VDNumber: "32870853",
    RegisteredPopulation: "2579",
    VotingStationName: "ANGLICAN CHURCH",
    Address: "10484 CNR QUPE STREET / NOBANDA  ORLANDO WEST 2  SOWETO",
    Latitude: "-26.2218",
    Longitude: "27.9189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7763",
    WardLookupId: "1182",
    VDNumber: "32870729",
    RegisteredPopulation: "2045",
    VotingStationName: "TSHIRELETSO LOWER PRIMARY SCHOOL",
    Address: "1113 BAKWENA STREET ZONE 1  MEADOWLANDS  JHB",
    Latitude: "-26.2146",
    Longitude: "27.9076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7764",
    WardLookupId: "1182",
    VDNumber: "32870730",
    RegisteredPopulation: "3511",
    VotingStationName: "TENT (NEXT TO HOUSE NO 379 A)",
    Address: "MEADOWLANDS HOSTEL  MEADOWLANDS  SOWETO",
    Latitude: "-26.2168",
    Longitude: "27.9094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7765",
    WardLookupId: "1182",
    VDNumber: "32870842",
    RegisteredPopulation: "2458",
    VotingStationName: "THABANENG PRIMARY SCHOOL",
    Address: "8885 MPITSO STR  ORLANDO WEST 2  GAUTENG",
    Latitude: "-26.2232",
    Longitude: "27.9097",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7766",
    WardLookupId: "1182",
    VDNumber: "32870864",
    RegisteredPopulation: "1677",
    VotingStationName: "VEZUKHULE PRIMARY SCHOOL",
    Address: "10878 NEKU STREET  ORLANDO WEST 2  JHB",
    Latitude: "-26.2223",
    Longitude: "27.9149",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7767",
    WardLookupId: "1182",
    VDNumber: "32870875",
    RegisteredPopulation: "2026",
    VotingStationName: "EKUTHULENI RECREATION CENTRE",
    Address: "657 VINCENT ROAD  ZONE 1 MEADOWLANDS  SOWETO",
    Latitude: "-26.2186",
    Longitude: "27.9155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7768",
    WardLookupId: "1182",
    VDNumber: "32870886",
    RegisteredPopulation: "2528",
    VotingStationName: "121 B MEADOWLANDS HOSTEL BUNGALOW",
    Address: "121B BUNGALOW MEADOWLANDS HOSTEL  MEADOWLANDS",
    Latitude: "-26.2171",
    Longitude: "27.9174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7769",
    WardLookupId: "1182",
    VDNumber: "32871258",
    RegisteredPopulation: "869",
    VotingStationName: "UNITING REFORMED CHURCH IN SOUTHERN AFRICA",
    Address: "12612 SEGAPO STREET  ORLANDO WEST  SOWETO",
    Latitude: "-26.2304",
    Longitude: "27.9051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7770",
    WardLookupId: "1182",
    VDNumber: "32871269",
    RegisteredPopulation: "728",
    VotingStationName: "ANCHOR COMPREHENSIVE HIGH SCHOOL",
    Address:
      "10894 MABASOTHO  ORLANDO WEST  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.2196",
    Longitude: "27.9141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7771",
    WardLookupId: "1183",
    VDNumber: "32870707",
    RegisteredPopulation: "2162",
    VotingStationName: "THUTOLORE HIGH SCHOOL",
    Address: "734 MADUBANE STREET ZONE1  MEADOWLANDS  JHB",
    Latitude: "-26.2139",
    Longitude: "27.9044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7772",
    WardLookupId: "1183",
    VDNumber: "32870741",
    RegisteredPopulation: "1344",
    VotingStationName: "EVANGELICAL LUTHERAN CHURCH",
    Address: "510 CNR BOIKHUTSO ST / MPOLOKENG  ZONE 3  MEADOWLANDS",
    Latitude: "-26.2191",
    Longitude: "27.9062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7773",
    WardLookupId: "1183",
    VDNumber: "32870752",
    RegisteredPopulation: "1802",
    VotingStationName: "MASEKHENE PRIMARY SCHOOL",
    Address:
      "218 CNR MASILELA & MORUTLHARE STREET  ZONE 3 MEADOWLANDS  MEADOWLANDS",
    Latitude: "-26.2175",
    Longitude: "27.9018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7774",
    WardLookupId: "1183",
    VDNumber: "32870763",
    RegisteredPopulation: "1899",
    VotingStationName: "TUMELO PRIMARY SCHOOL",
    Address: "JOHANNESBURG  ",
    Latitude: "-26.2205",
    Longitude: "27.9032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7775",
    WardLookupId: "1183",
    VDNumber: "32870796",
    RegisteredPopulation: "2999",
    VotingStationName: "LAMULA JUBILEE SECONDARY SCHOOL",
    Address: "685 SCHRADER ROAD  ZONE 5  MEADOWLANDS",
    Latitude: "-26.2294",
    Longitude: "27.8985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7776",
    WardLookupId: "1183",
    VDNumber: "32870808",
    RegisteredPopulation: "2484",
    VotingStationName: "DZATA PRIMARY SCHOOL",
    Address: "789A SIBASA STREET  ZONE 5 MEADOWLANDS  SOWETO",
    Latitude: "-26.2318",
    Longitude: "27.9003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7777",
    WardLookupId: "1183",
    VDNumber: "32870819",
    RegisteredPopulation: "2661",
    VotingStationName: "EMZIMVUBU PRIMARY SCHOOL",
    Address: "128 CNR MNGUNI / EMOLETSHANE  ZONE 5  MEADOWLANDS",
    Latitude: "-26.2267",
    Longitude: "27.8999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7778",
    WardLookupId: "1183",
    VDNumber: "32870820",
    RegisteredPopulation: "1350",
    VotingStationName: "NEW PHEFENI RECREATION CENTRE",
    Address: "8480 XABA  &  funani streets  ORLANDO WEST  JOHANNESBURG",
    Latitude: "-26.2326",
    Longitude: "27.9046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7779",
    WardLookupId: "1183",
    VDNumber: "32870831",
    RegisteredPopulation: "1260",
    VotingStationName: "TSOGANG PRIMARY SCHOOL",
    Address: "BOIKUTSO STREET ZONE 3  MEADOWLANDS  JOHANNESBURG",
    Latitude: "-26.222",
    Longitude: "27.9062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7780",
    WardLookupId: "1184",
    VDNumber: "32870606",
    RegisteredPopulation: "1154",
    VotingStationName: "SIVELILE COMBINED SCHOOL",
    Address: "760/61 NYENGA STREET ZONE 6  MEADOWLANDS  JHB",
    Latitude: "-26.2198",
    Longitude: "27.8863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7781",
    WardLookupId: "1184",
    VDNumber: "32870617",
    RegisteredPopulation: "2783",
    VotingStationName: "EMPUMALANGA PRIMARY SCHOOL",
    Address: "45 SOMKHELE STREET  ZONE 4  MEADOWLANDS",
    Latitude: "-26.2253",
    Longitude: "27.894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7782",
    WardLookupId: "1184",
    VDNumber: "32870628",
    RegisteredPopulation: "1577",
    VotingStationName: "TLHOKOMELO PRIMARY SCHOOL",
    Address: "424 MODJADJI STREET ZONE 2  MEADOWLANDS  JHB",
    Latitude: "-26.2205",
    Longitude: "27.8953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7783",
    WardLookupId: "1184",
    VDNumber: "32870639",
    RegisteredPopulation: "2442",
    VotingStationName: "INKONJANE JUNIOR SECONDARY SCHOOL",
    Address: "362 IVUKULU STREET   ZONE 6 MEADOWLANDS  SOWETO",
    Latitude: "-26.2212",
    Longitude: "27.8905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7784",
    WardLookupId: "1184",
    VDNumber: "32870640",
    RegisteredPopulation: "1798",
    VotingStationName: "THOBEKA PRIMARY SCHOOL",
    Address: "653 VAN ONSELEN RD ZONE 6  MEADOWLANDS  JHB",
    Latitude: "-26.2158",
    Longitude: "27.8894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7785",
    WardLookupId: "1184",
    VDNumber: "32870785",
    RegisteredPopulation: "2697",
    VotingStationName: "RISHILE COMBINED SCHOOL",
    Address: "337 PHIRIPHIRI STREET  ZONE 4  MEADOWLANDS",
    Latitude: "-26.2266",
    Longitude: "27.8925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7786",
    WardLookupId: "1184",
    VDNumber: "32871102",
    RegisteredPopulation: "1305",
    VotingStationName: "PRESBYTERIAN CHURCH",
    Address: "490 VAN ONSELEN ROAD ZONE 2  MEADOWLANDS  JOHANNESBURG",
    Latitude: "-26.2171",
    Longitude: "27.8964",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7787",
    WardLookupId: "1185",
    VDNumber: "32870651",
    RegisteredPopulation: "2558",
    VotingStationName: "MEADOWLANDS PRIMARY SCHOOL",
    Address: "386 INGUNGULU STR  ZONE 7, MEADOWLANDS  MEADOWLANDS",
    Latitude: "-26.2106",
    Longitude: "27.893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7788",
    WardLookupId: "1185",
    VDNumber: "32870662",
    RegisteredPopulation: "2051",
    VotingStationName: "PARISH OF MEADOWLANDS",
    Address: "450 MASERU STREET ZONE 7  MEADOWLANDS  JOHANNESBURG",
    Latitude: "-26.2094",
    Longitude: "27.8927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7789",
    WardLookupId: "1185",
    VDNumber: "32870673",
    RegisteredPopulation: "2341",
    VotingStationName: "LIVHUWANI PRIMARY SCHOOL",
    Address: "354 SEKHWIRI STREET ZONE 8  ZONE 8 MEADOWLANDS  MEADOWLANDS",
    Latitude: "-26.2086",
    Longitude: "27.8857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7790",
    WardLookupId: "1185",
    VDNumber: "32870684",
    RegisteredPopulation: "2881",
    VotingStationName: "MORUTATHUTO PRIMARY SCHOOL",
    Address: "620 A PHUDUFUDU STREET ZONE 1  MEADOWLANDS  JHB",
    Latitude: "-26.2118",
    Longitude: "27.896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7791",
    WardLookupId: "1185",
    VDNumber: "32870695",
    RegisteredPopulation: "1845",
    VotingStationName: "TOTOMENG PRIMARY SCHOOL",
    Address: "691 LENONG  STR  MEADOWLANDS ZONE8  MEADOWLANDS",
    Latitude: "-26.2069",
    Longitude: "27.8895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7792",
    WardLookupId: "1185",
    VDNumber: "32870718",
    RegisteredPopulation: "2007",
    VotingStationName: "MEADOWLANDS HIGH SCHOOL",
    Address: "55A VAN ONSELEN / MODJADJI  ZONE 2  MEADOWLANDS",
    Latitude: "-26.2157",
    Longitude: "27.8952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7793",
    WardLookupId: "1185",
    VDNumber: "32870774",
    RegisteredPopulation: "1498",
    VotingStationName: "EBENEZER PARISH",
    Address: "174 MODJADJI STREET  ZONE 2  MEADOWLANDS",
    Latitude: "-26.215",
    Longitude: "27.8983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7794",
    WardLookupId: "1186",
    VDNumber: "32870369",
    RegisteredPopulation: "2050",
    VotingStationName: "APOSTOLIC FAITH MISSION OF SA.",
    Address: "30005 FORBES ROAD  MMESI PARK  ROODEPOORT",
    Latitude: "-26.2033",
    Longitude: "27.8785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7795",
    WardLookupId: "1186",
    VDNumber: "32871124",
    RegisteredPopulation: "6140",
    VotingStationName: "MOSES KOTANE PRIMARY SCHOOL",
    Address:
      "5633 CNR UNITY BOULEVARD&FREEDOM DRIVE  BRAAMFISCHERVILLE  ROODEPOORT",
    Latitude: "-26.2016",
    Longitude: "27.868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7796",
    WardLookupId: "1186",
    VDNumber: "32871168",
    RegisteredPopulation: "5287",
    VotingStationName: "SIYABONGA SECONDARY SCHOOL",
    Address: "10216 PHAES 2 BRAAMFISCHER  BRAAMFISCHER  ROODEPOORT",
    Latitude: "-26.1901",
    Longitude: "27.8676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7797",
    WardLookupId: "1186",
    VDNumber: "32871382",
    RegisteredPopulation: "935",
    VotingStationName: "FORTE HIGH SCHOOL.",
    Address: "01 JONAS MOABI DRIVE  DOBSONVILLE  ROODEPOORT",
    Latitude: "-26.2146",
    Longitude: "27.8672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7798",
    WardLookupId: "1186",
    VDNumber: "32871393",
    RegisteredPopulation: "3205",
    VotingStationName: "KGATELOPELE SECONDARY SCHOOL",
    Address:
      "1062 PHASE 1 CNR BOTSWANA & NIGER ROAD  BRAAMFISCHERVILLE  ROODEPOORT",
    Latitude: "-26.2109",
    Longitude: "27.8594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7799",
    WardLookupId: "1186",
    VDNumber: "32871405",
    RegisteredPopulation: "2688",
    VotingStationName: "JULIUS SEBOLAI PRIMARY SCHOOL",
    Address: "13257 CNR SAGEWOOD & WILLOW ST  BRAAMFISCHERVILLE  ROODEPOORT",
    Latitude: "-26.1935",
    Longitude: "27.8726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7800",
    WardLookupId: "1187",
    VDNumber: "32870415",
    RegisteredPopulation: "2031",
    VotingStationName: "SENYAMO PRIMARY SCHOOL",
    Address: "3670 VUZANE  STREET  DOBSONVILLE  SOWETO",
    Latitude: "-26.2192",
    Longitude: "27.8735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7801",
    WardLookupId: "1187",
    VDNumber: "32870549",
    RegisteredPopulation: "2791",
    VotingStationName: "MEADOWLANDS WELFARE CENTRE",
    Address: "10623 MASERU STR ZONE 9  MEADOWLANDS  MEADOWLANDS",
    Latitude: "-26.2066",
    Longitude: "27.8794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7802",
    WardLookupId: "1187",
    VDNumber: "32870550",
    RegisteredPopulation: "2093",
    VotingStationName: "PALESA COMBINED SCHOOL",
    Address: "1650 ZONE 9  MEADOWLANDS  SOWETO",
    Latitude: "-26.208",
    Longitude: "27.8786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7803",
    WardLookupId: "1187",
    VDNumber: "32870561",
    RegisteredPopulation: "1935",
    VotingStationName: "KELOKITSO SENIOR SECONDARY SCHOOL",
    Address: "2031 ZONE 9  MEADOWLANDS  MEADOWLANDS",
    Latitude: "-26.2113",
    Longitude: "27.8811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7804",
    WardLookupId: "1187",
    VDNumber: "32870572",
    RegisteredPopulation: "2101",
    VotingStationName: "SWISS MISSION EVANGELICAL CHURCH",
    Address: "JOHANNESBURG  ",
    Latitude: "-26.2167",
    Longitude: "27.8772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7805",
    WardLookupId: "1187",
    VDNumber: "32870583",
    RegisteredPopulation: "2341",
    VotingStationName: "INDONI JUNIOR SECONDARY SCHOOL",
    Address: "2918 ZONE 10  MEADOWLANDS  SOWETO",
    Latitude: "-26.2184",
    Longitude: "27.8799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7806",
    WardLookupId: "1187",
    VDNumber: "32870594",
    RegisteredPopulation: "2329",
    VotingStationName: "THEMBALETHU PRIMARY SCHOOL",
    Address: "3555 ZONE 10  MEADOWLANDS  MEADOWLANDS",
    Latitude: "-26.2169",
    Longitude: "27.8822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7807",
    WardLookupId: "1187",
    VDNumber: "32871270",
    RegisteredPopulation: "1034",
    VotingStationName: "KHINDLIMUKANI JUNIOR SECONDARY  SCHOOL",
    Address: "3244 ZONE 10  MEADOWLANDS  SOWETO",
    Latitude: "-26.2231",
    Longitude: "27.8836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7808",
    WardLookupId: "1188",
    VDNumber: "32861167",
    RegisteredPopulation: "2949",
    VotingStationName: "THULANI COMBINED PRIMARY SCHOOL",
    Address: "1586 MDLALOSE STREET  ZOLA  JOHANNESBURG",
    Latitude: "-26.2457",
    Longitude: "27.8485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7809",
    WardLookupId: "1188",
    VDNumber: "32861808",
    RegisteredPopulation: "1710",
    VotingStationName: "USINDISO HIGHER PRIMARY SCHOOL",
    Address: "277 LUMKWANA  STREET  ZONDI 2  SOWETO",
    Latitude: "-26.23",
    Longitude: "27.8676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7810",
    WardLookupId: "1188",
    VDNumber: "32861819",
    RegisteredPopulation: "1898",
    VotingStationName: "LETARE SCHOOL",
    Address: "821 GAWE STREET  JABULANI  SOWETO",
    Latitude: "-26.2466",
    Longitude: "27.8532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7811",
    WardLookupId: "1188",
    VDNumber: "32861820",
    RegisteredPopulation: "1688",
    VotingStationName: "KHOLWANI PRIMARY SCHOOL",
    Address: "1431 KEY DRIVE  JABULANI  SOWETO",
    Latitude: "-26.2427",
    Longitude: "27.8519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7812",
    WardLookupId: "1188",
    VDNumber: "32861842",
    RegisteredPopulation: "2117",
    VotingStationName: "SIYAVUMA PRIMARY SCHOOL",
    Address: "1010 MTHETHWA STREET  JABULANI  SOWETO",
    Latitude: "-26.2387",
    Longitude: "27.8536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7813",
    WardLookupId: "1188",
    VDNumber: "32861864",
    RegisteredPopulation: "2467",
    VotingStationName: "ENTANDWENI PRIMARY SCHOOL",
    Address: "672 MASINGAFI STREET  ZONDI 2  SOWETO",
    Latitude: "-26.2324",
    Longitude: "27.8602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7814",
    WardLookupId: "1188",
    VDNumber: "32861943",
    RegisteredPopulation: "1225",
    VotingStationName: "EKUPHUMELELENI HIGHER PRIMARY SCHOOL",
    Address: "197 MANGALI  JABULANI  SOWETO",
    Latitude: "-26.2366",
    Longitude: "27.8594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7815",
    WardLookupId: "1188",
    VDNumber: "32861954",
    RegisteredPopulation: "2855",
    VotingStationName: "EMATHAFENI PRIMARY SCHOOL",
    Address: "1172 TWALA STR  ZONDI 1  SOWETO",
    Latitude: "-26.2359",
    Longitude: "27.8657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7816",
    WardLookupId: "1189",
    VDNumber: "32861381",
    RegisteredPopulation: "1572",
    VotingStationName: "NAZARENE CHURCH",
    Address: "JOHANNESBURG  ",
    Latitude: "-26.2291",
    Longitude: "27.8759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7817",
    WardLookupId: "1189",
    VDNumber: "32861415",
    RegisteredPopulation: "2441",
    VotingStationName: "ESIYALWINI PRIMARY SCHOOL",
    Address: "461 GUMEDE STREET  MOFOLO NORTH  JHB",
    Latitude: "-26.2257",
    Longitude: "27.8748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7818",
    WardLookupId: "1189",
    VDNumber: "32870381",
    RegisteredPopulation: "2777",
    VotingStationName: "ST ANGELAS CATHOLIC CHURCH",
    Address: "18 MOKHESI STREET  DOBSONVILLE  JOHANNESBURG",
    Latitude: "-26.2208",
    Longitude: "27.8664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7819",
    WardLookupId: "1189",
    VDNumber: "32870392",
    RegisteredPopulation: "1705",
    VotingStationName: "KOPANONG COMMUNITY CENTRE",
    Address: "LUTHULI STREET 2332  DOBSONVILLE  SOWETO JHB",
    Latitude: "-26.2235",
    Longitude: "27.8637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7820",
    WardLookupId: "1189",
    VDNumber: "32870426",
    RegisteredPopulation: "1932",
    VotingStationName: "SAMUEL MANGALA PRIMARY SCHOOL",
    Address: "3670 SIBIGI STREET  DOBSONVILLE  JOHANNESBURG",
    Latitude: "-26.2194",
    Longitude: "27.8701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7821",
    WardLookupId: "1189",
    VDNumber: "32870437",
    RegisteredPopulation: "2239",
    VotingStationName: "MARGARET GWELE PRIMARY SCHOOL",
    Address: "14 MATOMELA STREET  DOBSONVILLE  JOHANNESBURG",
    Latitude: "-26.2257",
    Longitude: "27.8712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7822",
    WardLookupId: "1189",
    VDNumber: "32870448",
    RegisteredPopulation: "1092",
    VotingStationName: "ITHEMBALIHLE PRIMARY SCHOOL",
    Address: "STAND 3289  DOBSONVILLE  JOHANNESBURG",
    Latitude: "-26.2279",
    Longitude: "27.8674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7823",
    WardLookupId: "1189",
    VDNumber: "32870471",
    RegisteredPopulation: "2353",
    VotingStationName: "THABANG PRIMARY SCHOOL",
    Address: "2761 MASHAO STREET  DOBSONVILLE  JOHANNEBSURG",
    Latitude: "-26.2187",
    Longitude: "27.8602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7824",
    WardLookupId: "1190",
    VDNumber: "32870459",
    RegisteredPopulation: "1066",
    VotingStationName: "ENKOLWENI PRIMARY SCHOOL",
    Address: "27 KHOZA STREET  DOBSONVILLE  JOHANNESBURG",
    Latitude: "-26.2266",
    Longitude: "27.8673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7825",
    WardLookupId: "1190",
    VDNumber: "32870460",
    RegisteredPopulation: "2976",
    VotingStationName: "THATHEZAKHO PRIMARY SCHOOL",
    Address: "3023 SIMELANE  DOBSONVILLE  JOHANNESBURG",
    Latitude: "-26.2271",
    Longitude: "27.8611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7826",
    WardLookupId: "1190",
    VDNumber: "32870482",
    RegisteredPopulation: "2679",
    VotingStationName: "DSJ PRIMARY SCHOOL",
    Address: "4057 MMUTLE STREET  DOBSONVILLE  JOHANNESBURG",
    Latitude: "-26.222",
    Longitude: "27.8563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7827",
    WardLookupId: "1190",
    VDNumber: "32870493",
    RegisteredPopulation: "1271",
    VotingStationName: "PRESBYTERIAN CHURCH PREMISES",
    Address:
      "4058 MASOBELA STREET (PRESBYTERIAN CHURCH PREMISES)  DOBSONVILLE  JOHANNESBURG",
    Latitude: "-26.2249",
    Longitude: "27.8547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7828",
    WardLookupId: "1190",
    VDNumber: "32870505",
    RegisteredPopulation: "2393",
    VotingStationName: "EBENEZER CHURCH",
    Address: "7717 SETSILE STREET  DOBSONVILLE  JOHANNESBURG",
    Latitude: "-26.2271",
    Longitude: "27.8486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7829",
    WardLookupId: "1190",
    VDNumber: "32870516",
    RegisteredPopulation: "2451",
    VotingStationName: "NOKUPHILA RECREATION CENTRE",
    Address: "8405 SETOE STREET  DOBSONVILLE EXT 2  JOHANNESBURG",
    Latitude: "-26.2307",
    Longitude: "27.8507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7830",
    WardLookupId: "1190",
    VDNumber: "32870527",
    RegisteredPopulation: "3139",
    VotingStationName: "UMTHOMBOLWAZI MULTIPURPOSE CENTRE",
    Address: "6851 JACA DRIVE  DOBSONVILLE  JOHANNESBURG",
    Latitude: "-26.2314",
    Longitude: "27.8343",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7831",
    WardLookupId: "1191",
    VDNumber: "32871113",
    RegisteredPopulation: "2914",
    VotingStationName: "BRAAM FISCHERVILLE CRICKET STADIUM",
    Address: "PHASE 2  BRAAMFISCHERVILLE  ROODEPOORT",
    Latitude: "-26.213",
    Longitude: "27.8535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7832",
    WardLookupId: "1191",
    VDNumber: "32871180",
    RegisteredPopulation: "4742",
    VotingStationName: "BRAAM FISCHER PRIMARY SCHOOL",
    Address: "2513 NCUBE DRIVE 1785  BRAAMFISCHERVILLE  ROODEPOORT",
    Latitude: "-26.198",
    Longitude: "27.8533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7833",
    WardLookupId: "1191",
    VDNumber: "32871236",
    RegisteredPopulation: "3374",
    VotingStationName: "NKONE MARUPING PRIMARY SCHOOL",
    Address: "11062 MINE ROAD (EXT 6)  BRAAMFISCHERVILLE  ROODEPOORT",
    Latitude: "-26.1876",
    Longitude: "27.8428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7834",
    WardLookupId: "1191",
    VDNumber: "32871292",
    RegisteredPopulation: "4049",
    VotingStationName: "IKUSASALETHU SECONDARY SCHOOL",
    Address:
      "17626 FUTURE STREET EXT 13 PHASE 4  BRAAMFISCHERVILLE  ROODEPOORT",
    Latitude: "-26.193",
    Longitude: "27.8265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7835",
    WardLookupId: "1191",
    VDNumber: "32871348",
    RegisteredPopulation: "2396",
    VotingStationName: "BRAAMFISCHER MULTIPURPOSE CENTRE",
    Address: "6732 LOERBLAAR AVENUE  BRAAMFISCHERVILLE  ROODEPOORT",
    Latitude: "-26.2036",
    Longitude: "27.854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7836",
    WardLookupId: "1191",
    VDNumber: "32871427",
    RegisteredPopulation: "2374",
    VotingStationName: "KHULANOLWAZI PRIMARY SCHOOL",
    Address: "7558 ABACUS DRIVE PHASE 4  BRAAM FISCHERVILLE  ROODEPOORT",
    Latitude: "-26.1972",
    Longitude: "27.8218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7837",
    WardLookupId: "1191",
    VDNumber: "32871506",
    RegisteredPopulation: "736",
    VotingStationName: "NOMZAMO MADIKIZELA MANDELA PRIMARY SCHOOL",
    Address: "BRAM FISCHERVILLE  SOWETO  BRAM FISCHERVILLE",
    Latitude: "-26.186",
    Longitude: "27.8311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7838",
    WardLookupId: "1192",
    VDNumber: "32870404",
    RegisteredPopulation: "841",
    VotingStationName: "TENT (EXT A&B FOOTBALL GROUND)",
    Address: "BLOCK 12 EXT A&B FOOTBALL GRD  DOORNKOP  SOWETO",
    Latitude: "-26.2279",
    Longitude: "27.8067",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7839",
    WardLookupId: "1192",
    VDNumber: "32870943",
    RegisteredPopulation: "2149",
    VotingStationName: "TENT (EXT 4 TAXI RANK)",
    Address: "TAXI RANK  DOORNKOP X4  JOHANNESBURG",
    Latitude: "-26.2238",
    Longitude: "27.8078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7840",
    WardLookupId: "1192",
    VDNumber: "32870965",
    RegisteredPopulation: "4448",
    VotingStationName: "MAYIBUYE PRIMARY SCHOOL",
    Address: "BLOCK 9, SITE 1304  DOORNKOP  JOHANNESBURG",
    Latitude: "-26.2209",
    Longitude: "27.819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7841",
    WardLookupId: "1192",
    VDNumber: "32870998",
    RegisteredPopulation: "3347",
    VotingStationName: "THULANI SECONDARY SCHOOL.",
    Address: "358  DOORNKOP  DOORNKOP BLOCK 5  SOWETO",
    Latitude: "-26.2168",
    Longitude: "27.8276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7842",
    WardLookupId: "1192",
    VDNumber: "32871001",
    RegisteredPopulation: "1142",
    VotingStationName: "WELL OF DIVINE WISDOM FELLOWSHIP",
    Address: "45508 EXT.4  DOORNKOP  JOHANNESBURG",
    Latitude: "-26.2189",
    Longitude: "27.8086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7843",
    WardLookupId: "1192",
    VDNumber: "32871157",
    RegisteredPopulation: "2363",
    VotingStationName: "TENT (DOORNKOP PARK)",
    Address: "OPPOSITE SHACK 1771  DOORNKOP  JOHANNESBURG",
    Latitude: "-26.2297",
    Longitude: "27.818",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7844",
    WardLookupId: "1193",
    VDNumber: "32861156",
    RegisteredPopulation: "2014",
    VotingStationName: "ESITHEBENI PRIMARY SCHOOL",
    Address: "2446 TSHAWESTREET  ZOLA SOTH  JOHANNESBURG",
    Latitude: "-26.2467",
    Longitude: "27.8421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7845",
    WardLookupId: "1193",
    VDNumber: "32861853",
    RegisteredPopulation: "2379",
    VotingStationName: "INDYEBO HIGHER PRIMARY SCHOOL",
    Address: "1586 B  MDLALOSE  ZOLA NORTH  SOWETO",
    Latitude: "-26.2388",
    Longitude: "27.8435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7846",
    WardLookupId: "1193",
    VDNumber: "32861965",
    RegisteredPopulation: "1998",
    VotingStationName: "BUSISIWE PRIMARY SCHOOL",
    Address: "1854 ZOLA 2  ZOLA SOUTH  JOHANNEBSURG",
    Latitude: "-26.2455",
    Longitude: "27.8331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7847",
    WardLookupId: "1193",
    VDNumber: "32861976",
    RegisteredPopulation: "1525",
    VotingStationName: "ZOLA PARK DAY CARE CENTRE",
    Address: "17 MBHELE  ZOLA NORTH  SOWETO",
    Latitude: "-26.2365",
    Longitude: "27.8391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7848",
    WardLookupId: "1193",
    VDNumber: "32861987",
    RegisteredPopulation: "2182",
    VotingStationName: "SIVULELENI PRIMARY SCHOOL",
    Address: "1240 THULASIZWI STREET  ZOLA NORTH  SOWETO",
    Latitude: "-26.2353",
    Longitude: "27.8431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7849",
    WardLookupId: "1193",
    VDNumber: "32862012",
    RegisteredPopulation: "2070",
    VotingStationName: "VUSISIZWE JUNIOR PRIMARY SCHOOL",
    Address: "778 MASEKO STREET  ZOLA NORTH  JOHANNESBURG",
    Latitude: "-26.241",
    Longitude: "27.8347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7850",
    WardLookupId: "1193",
    VDNumber: "32862023",
    RegisteredPopulation: "1227",
    VotingStationName: "ZOLA ADMINISTRATION OFFICES",
    Address: "3700 BUTHELEZI  ZOLA  JOHANNESBURG",
    Latitude: "-26.2401",
    Longitude: "27.8381",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7851",
    WardLookupId: "1193",
    VDNumber: "32862124",
    RegisteredPopulation: "2020",
    VotingStationName: "EVANGELICAL LUTHERAN CHURCH IN SA",
    Address: "2054 SEHOOLE STR  ZOLA 2  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.2448",
    Longitude: "27.8378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7852",
    WardLookupId: "1194",
    VDNumber: "32861998",
    RegisteredPopulation: "2560",
    VotingStationName: "DR BW VILAKAZI SECONDARY SCHOOL",
    Address: "277 MATHEBULA  ZOLA 3  JOHANNESBURG",
    Latitude: "-26.2385",
    Longitude: "27.8309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7853",
    WardLookupId: "1194",
    VDNumber: "32862001",
    RegisteredPopulation: "1503",
    VotingStationName: "ASSEMBLY OF GOD",
    Address: "369 MTHONJENI ST.  ZOLA 3  JOHANNESBURG",
    Latitude: "-26.2384",
    Longitude: "27.8362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7854",
    WardLookupId: "1194",
    VDNumber: "32862045",
    RegisteredPopulation: "1677",
    VotingStationName: "ERNEST BUTI CRECHE",
    Address: "718 B XHUMA STREET  EMDENI EXT 2  SOWETO",
    Latitude: "-26.2405",
    Longitude: "27.8175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7855",
    WardLookupId: "1194",
    VDNumber: "32862056",
    RegisteredPopulation: "2783",
    VotingStationName: "KHULANI HIGHER PRIMARY SCHOOL",
    Address: "388B BIYELA STREET  MNDENI NORTH  SOWETO",
    Latitude: "-26.241",
    Longitude: "27.824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7856",
    WardLookupId: "1194",
    VDNumber: "32862090",
    RegisteredPopulation: "1861",
    VotingStationName: "MATLA CRECHE",
    Address: "924 KHISA STREET  EMDENI SOUTH  JOHANNESBURG",
    Latitude: "-26.2474",
    Longitude: "27.8302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7857",
    WardLookupId: "1194",
    VDNumber: "32862102",
    RegisteredPopulation: "2348",
    VotingStationName: "LUYOLO SENIOR PRIMARY SCHOOL",
    Address: "1026 THEMBALIHLE ST.  EMDENI SOUTH  JOHANNESBURG",
    Latitude: "-26.246",
    Longitude: "27.8252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7858",
    WardLookupId: "1194",
    VDNumber: "32863024",
    RegisteredPopulation: "1110",
    VotingStationName: "ISU LIHLE HIGHER PRIMARY SCHOOL",
    Address: "CNR ZAMAKULUNGISA & STHEMBISO STREETS  ZOLA NORTH  JOHANNESBURG",
    Latitude: "-26.2431",
    Longitude: "27.8323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7859",
    WardLookupId: "1195",
    VDNumber: "32870370",
    RegisteredPopulation: "4122",
    VotingStationName: "SLOVOVILLE HALL",
    Address: "OPPOSITE SHOPS OF CONTRACTORS  SLOVOVILLE  JOHANNESBURG",
    Latitude: "-26.2151",
    Longitude: "27.7799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7860",
    WardLookupId: "1195",
    VDNumber: "32870909",
    RegisteredPopulation: "3474",
    VotingStationName: "METHODIST CHURCH OF SOUTHERN AFRICA",
    Address: "627 DOBSONVILLE GARDENS  DOBSONVILLE DARDENS  SOWETO",
    Latitude: "-26.2318",
    Longitude: "27.8106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7861",
    WardLookupId: "1195",
    VDNumber: "32870954",
    RegisteredPopulation: "4824",
    VotingStationName: "KLIPVALLEY PRIMARY SCHOOL",
    Address: "2011 CORNER RAFELLE  DOORNKOP EXT 1  SOWETO",
    Latitude: "-26.2414",
    Longitude: "27.8087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7862",
    WardLookupId: "1195",
    VDNumber: "32871359",
    RegisteredPopulation: "1912",
    VotingStationName: "LUFHERENG MOVABLE CONTAINER",
    Address: "708 CNR MORUMOSETLHA & MOLOPA STR  LUFHERENG  SOWETO",
    Latitude: "-26.2402",
    Longitude: "27.8004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7863",
    WardLookupId: "1195",
    VDNumber: "32871450",
    RegisteredPopulation: "1830",
    VotingStationName: "LUFHERENG PRIMARY SCHOOL",
    Address: "1857 MULEMBU STREET  LUFHERENG  SOWETO",
    Latitude: "-26.232",
    Longitude: "27.7989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7864",
    WardLookupId: "1195",
    VDNumber: "32871517",
    RegisteredPopulation: "176",
    VotingStationName: "DOORNKOP HOSTEL HALL",
    Address: "DOORNKOP HOSTEL  SOWETO  DOORNKOP",
    Latitude: "-26.2203",
    Longitude: "27.7935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7865",
    WardLookupId: "1196",
    VDNumber: "32860144",
    RegisteredPopulation: "6030",
    VotingStationName: "PRESIDENT HIGH SCHOOL",
    Address: "RIFLE RANGE ROAD  RIDGEWAY  JOHANNESBURG",
    Latitude: "-26.2576",
    Longitude: "28",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7866",
    WardLookupId: "1196",
    VDNumber: "32860166",
    RegisteredPopulation: "4183",
    VotingStationName: "UNISA CONFERENCE CENTRE",
    Address: "2 VINTON RD  ORMONDE X1  JOHANNESBURG",
    Latitude: "-26.2431",
    Longitude: "27.996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7867",
    WardLookupId: "1196",
    VDNumber: "32860335",
    RegisteredPopulation: "4570",
    VotingStationName: "MONDEOR RECREATION CENTRE",
    Address: "CNR COLUMBINE & DAYLESFORD RDS.  MONDEOR  JOHANNESBURG",
    Latitude: "-26.2781",
    Longitude: "28.0073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7868",
    WardLookupId: "1196",
    VDNumber: "32860357",
    RegisteredPopulation: "2521",
    VotingStationName: "RANDEOR SPECIAL SCHOOL",
    Address: "CNR HANTAM & KOUGA  WINCHESTER HILLS EXT 1  JOHANNESBURG",
    Latitude: "-26.2777",
    Longitude: "28.0206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7869",
    WardLookupId: "1196",
    VDNumber: "32863282",
    RegisteredPopulation: "2460",
    VotingStationName: "MONDEOR HIGH SCHOOL",
    Address: "214 BEAUVAL  MONDEOR  JOHANNESBURG",
    Latitude: "-26.2704",
    Longitude: "28.0058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7870",
    WardLookupId: "1197",
    VDNumber: "32860155",
    RegisteredPopulation: "2852",
    VotingStationName: "WINCHESTER RIDGE PRIMARY SCHOOL",
    Address: "ESTELLE AVE  ROBERTSHAM  JOHANNESBURG",
    Latitude: "-26.2542",
    Longitude: "28.0112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7871",
    WardLookupId: "1197",
    VDNumber: "32860188",
    RegisteredPopulation: "4609",
    VotingStationName: "TURFFONTEIN PRIMARY SCHOOL",
    Address: "C/O PRESIDENT & BERTHA STR  TURFFONTEIN  JOHANNESBURG",
    Latitude: "-26.2411",
    Longitude: "28.0338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7872",
    WardLookupId: "1197",
    VDNumber: "32860313",
    RegisteredPopulation: "3718",
    VotingStationName: "FAKKEL HIGH SCHOOL",
    Address: "CARTER & RIFLE RANGE  FOREST HILL  JOHANNESBURG",
    Latitude: "-26.2577",
    Longitude: "28.0386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7873",
    WardLookupId: "1197",
    VDNumber: "32860324",
    RegisteredPopulation: "2807",
    VotingStationName: "FOREST HILL PRIMARY SCHOOL",
    Address: "CNR SIDE ROAD & RIFLE RANGE  CHRISVILLE  JOHANNESBURG",
    Latitude: "-26.2612",
    Longitude: "28.0278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7874",
    WardLookupId: "1197",
    VDNumber: "32863507",
    RegisteredPopulation: "850",
    VotingStationName: "ROBERTSHAM PRIMARY SCHOOL",
    Address: "2 IRMA STREET Z  ROBERTSHAM  JOHANNESBURG SOUTH",
    Latitude: "-26.2446",
    Longitude: "28.0161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7875",
    WardLookupId: "1198",
    VDNumber: "32860212",
    RegisteredPopulation: "3406",
    VotingStationName: "TOWNSVIEW PRIMARY SCHOOL",
    Address: "VALDA STREET  TOWNSVIEW  JOHANNESBURG",
    Latitude: "-26.2591",
    Longitude: "28.0521",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7876",
    WardLookupId: "1198",
    VDNumber: "32860223",
    RegisteredPopulation: "3304",
    VotingStationName: "HIGHVELD PRIMARY SCHOOL",
    Address: "9 RAINIER ROAD  THE HILL  JOHANNESBURG",
    Latitude: "-26.2547",
    Longitude: "28.0651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7877",
    WardLookupId: "1198",
    VDNumber: "32860234",
    RegisteredPopulation: "2484",
    VotingStationName: "ROSETTENVILLE TENNIS COURT",
    Address: "CNR DAISY & MABEL STREET   ROSSETTENVILLE  JOHANNESBURG",
    Latitude: "-26.2498",
    Longitude: "28.0546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7878",
    WardLookupId: "1198",
    VDNumber: "32860245",
    RegisteredPopulation: "2884",
    VotingStationName: "MARIST BROTHERS LINMEYER",
    Address: "RETHA STREET  LINMEYER  JOHANNESBURG",
    Latitude: "-26.2659",
    Longitude: "28.0657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7879",
    WardLookupId: "1198",
    VDNumber: "32860751",
    RegisteredPopulation: "3432",
    VotingStationName: "ROSETTENVILLE CENTRAL PRIMARY SCHOOL",
    Address: "MABEL STREET  ROSETTENVILLE  JOHANNESBURG",
    Latitude: "-26.2448",
    Longitude: "28.0532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7880",
    WardLookupId: "1198",
    VDNumber: "32863541",
    RegisteredPopulation: "456",
    VotingStationName: "FRANCES VORWERG SCHOOL",
    Address: "245 RIFLE RANGE  HADDON  JOHANNESBURG",
    Latitude: "-26.2615",
    Longitude: "28.0323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7881",
    WardLookupId: "1199",
    VDNumber: "32860111",
    RegisteredPopulation: "1437",
    VotingStationName: "CITY DEEP ADULT SCHOOL",
    Address: "HEIDELBERG RD  CITY DEEP  CITY DEEP",
    Latitude: "-26.2255",
    Longitude: "28.0714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7882",
    WardLookupId: "1199",
    VDNumber: "32860256",
    RegisteredPopulation: "3931",
    VotingStationName: "SOUTH RAND RECREATION CENTRE",
    Address: "CNR GENEVA & ESTENGA  SOUTH HILLS  JOHANNESBURG",
    Latitude: "-26.252",
    Longitude: "28.088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7883",
    WardLookupId: "1199",
    VDNumber: "32860267",
    RegisteredPopulation: "4177",
    VotingStationName: "REWLATCH PRIMARY SCHOOL",
    Address: "VICTORIA RD  REWLATCH  JOHANNESBURG",
    Latitude: "-26.2408",
    Longitude: "28.0762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7884",
    WardLookupId: "1199",
    VDNumber: "32860278",
    RegisteredPopulation: "3003",
    VotingStationName: "SUIDHEUWELS PRIMARY SCHOOL",
    Address: "45 QUANTOCK ROAD  SUIDHEUWELS  JOHANNESBURG",
    Latitude: "-26.2453",
    Longitude: "28.0922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7885",
    WardLookupId: "1199",
    VDNumber: "32860289",
    RegisteredPopulation: "602",
    VotingStationName: "QUANTUM LIFE COLLEGE",
    Address: "LOWER GERMISTON RD  CLEVELAND  ",
    Latitude: "-26.2269",
    Longitude: "28.1171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7886",
    WardLookupId: "1199",
    VDNumber: "32862775",
    RegisteredPopulation: "1413",
    VotingStationName: "SOCIAL SERVICES SKILLS CENTRE",
    Address: "2 11TH STREET LA-ROCHELLE  LA-ROCHELLE  JOHANNESBURG SOUTH",
    Latitude: "-26.2359",
    Longitude: "28.0545",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7887",
    WardLookupId: "1199",
    VDNumber: "32862786",
    RegisteredPopulation: "563",
    VotingStationName: "KASERNE HOSTEL COMMUNITY HALL",
    Address: "KASERNE HOSTEL  CITY DEEP  JOHANNEBSURG",
    Latitude: "-26.221",
    Longitude: "28.0657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7888",
    WardLookupId: "1199",
    VDNumber: "32862797",
    RegisteredPopulation: "2504",
    VotingStationName: "CUDDLE ME CRECHE",
    Address: "84 SANGIRO AVENUE  ELANDSPARK  JOHANNESBURG",
    Latitude: "-26.2388",
    Longitude: "28.1074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7889",
    WardLookupId: "1199",
    VDNumber: "32863192",
    RegisteredPopulation: "1027",
    VotingStationName: "TENT (ELANDSROCK)",
    Address: "JOHANNESBURG  ",
    Latitude: "-26.2503",
    Longitude: "28.1136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7890",
    WardLookupId: "1200",
    VDNumber: "32840197",
    RegisteredPopulation: "3183",
    VotingStationName: "UJ METROPOLITAN ACADEMY",
    Address: "CNR HEKPOORT & FOYLE  CROSBY  JOHANNESBURG",
    Latitude: "-26.1926",
    Longitude: "27.9872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7891",
    WardLookupId: "1200",
    VDNumber: "32840221",
    RegisteredPopulation: "3435",
    VotingStationName: "CROSBY ACADEMIC PRIMARY SCHOOL",
    Address: "33 ST JEROME  CROSBY  JOHANNESBURG",
    Latitude: "-26.1987",
    Longitude: "27.9926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7892",
    WardLookupId: "1200",
    VDNumber: "32840232",
    RegisteredPopulation: "2481",
    VotingStationName: "FORDSBURG PRIMARY SCHOOL",
    Address: "PARK DRIVE & LANGERMAN STREET  FORDSBURG  JOHANNESBURG",
    Latitude: "-26.203",
    Longitude: "28.0195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7893",
    WardLookupId: "1200",
    VDNumber: "32840243",
    RegisteredPopulation: "3922",
    VotingStationName: "JAN HOFMEYR RECREATION CENTRE",
    Address: "19 FIRST STREET  JAN HOFMEYR  JOHANNESBURG",
    Latitude: "-26.192",
    Longitude: "28.0131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7894",
    WardLookupId: "1200",
    VDNumber: "32840254",
    RegisteredPopulation: "2048",
    VotingStationName: "BOPHELO IMPILO PRIVATE SCHOOL",
    Address: "26 11TH AVENUE  MAYFAIR  JOHANNESBURG",
    Latitude: "-26.2076",
    Longitude: "28.0119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7895",
    WardLookupId: "1200",
    VDNumber: "32841244",
    RegisteredPopulation: "1721",
    VotingStationName: "CROWN REEF PRIMARY SCHOOL",
    Address: "CNR HANOVER AND CROWN REEF  MAYFAIR  JOHANNESBURG",
    Latitude: "-26.2095",
    Longitude: "28.017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7896",
    WardLookupId: "1200",
    VDNumber: "32841761",
    RegisteredPopulation: "1157",
    VotingStationName: "TECHNICAL HIGH SCHOOL LANGLAAGTE",
    Address: "CNR KRUGER AND DU TOIT  LANGLAAGTE  JOHANNESBURG",
    Latitude: "-26.2033",
    Longitude: "27.9914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7897",
    WardLookupId: "1201",
    VDNumber: "32860436",
    RegisteredPopulation: "8839",
    VotingStationName: "GREENHOUSE PEOPLE ENVIRONMENTAL CENTRE (TENT)",
    Address: "CITY OF JOHANNESBURG   ",
    Latitude: "-26.195",
    Longitude: "28.0467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7898",
    WardLookupId: "1201",
    VDNumber: "32860458",
    RegisteredPopulation: "3610",
    VotingStationName: "DRILL HALL",
    Address: "TWIST & PLEIN  JOHANNESBURG CENTRAL  JOHANNESBURG",
    Latitude: "-26.1988",
    Longitude: "28.0484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7899",
    WardLookupId: "1202",
    VDNumber: "32860032",
    RegisteredPopulation: "1875",
    VotingStationName: "JOHANNESBURG MUSLIM SCHOOL",
    Address:
      "CNR BREE & BURGHERSDORP STREETS  FORDSBURG  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.2011",
    Longitude: "28.0222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7900",
    WardLookupId: "1202",
    VDNumber: "32860043",
    RegisteredPopulation: "3799",
    VotingStationName: "PEOPLES CENTRE",
    Address: "80 LOVEDAY STREET  CBD  JOHANNESBURG",
    Latitude: "-26.2004",
    Longitude: "28.0409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7901",
    WardLookupId: "1202",
    VDNumber: "32860054",
    RegisteredPopulation: "2980",
    VotingStationName: "METROPOLITAN CENTRE",
    Address: "158 LOVEDAY ST  BRAAMFONTEIN  JOHANNESBURG",
    Latitude: "-26.1909",
    Longitude: "28.0397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7902",
    WardLookupId: "1202",
    VDNumber: "32860122",
    RegisteredPopulation: "4413",
    VotingStationName: "CENTRAL METHODIST CHURCH.",
    Address: "CNR SMALL AND PRITCHARD  JOHANNESBURG CBD  JOHANNESBURG",
    Latitude: "-26.2022",
    Longitude: "28.0464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7903",
    WardLookupId: "1202",
    VDNumber: "32863204",
    RegisteredPopulation: "594",
    VotingStationName: "JOHN ORR TECHNICAL HIGH SCHOOL",
    Address: "OWL STREET  MILPARK  JOHANNESBURG",
    Latitude: "-26.1865",
    Longitude: "28.0222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7904",
    WardLookupId: "1202",
    VDNumber: "32863338",
    RegisteredPopulation: "2846",
    VotingStationName: "WITS OLD MUTUAL HALL",
    Address: "JORISSEN  BRAAMFONTEIN  JOHANNESBURG",
    Latitude: "-26.1927",
    Longitude: "28.0282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7905",
    WardLookupId: "1203",
    VDNumber: "32850356",
    RegisteredPopulation: "3324",
    VotingStationName: "BERTHA SOLOMON RECREATION CENTRE",
    Address: "368 MARSHALL STREET  JEPPESTOWN  JOHANNESBURG",
    Latitude: "-26.2049",
    Longitude: "28.0677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7906",
    WardLookupId: "1203",
    VDNumber: "32850435",
    RegisteredPopulation: "2349",
    VotingStationName: "JULES HIGH SCHOOL",
    Address: "CNR MARSHALL&BROWNING STREETS  JEPPESTOWN  JOHANNESBURG",
    Latitude: "-26.2052",
    Longitude: "28.0693",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7907",
    WardLookupId: "1203",
    VDNumber: "32862685",
    RegisteredPopulation: "3612",
    VotingStationName: "TENT (PUBLIC PARK JEPPESTOWN)",
    Address: "CNR JULES & JANIE STREETS  JEPPESTOWN  JOHANNESBURG",
    Latitude: "-26.2065",
    Longitude: "28.0648",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7908",
    WardLookupId: "1203",
    VDNumber: "32862696",
    RegisteredPopulation: "5268",
    VotingStationName: "TENT (JEPPE HOSTEL)",
    Address: "WOLHUTER STREET  JEPPESTOWN  JOHANNESBURG",
    Latitude: "-26.2093",
    Longitude: "28.068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7909",
    WardLookupId: "1203",
    VDNumber: "32862887",
    RegisteredPopulation: "1681",
    VotingStationName: "MAI-MAI BAZAAR HALL",
    Address: "ALBERT STREET  CITY&SUBURBAN  JOHANNESBURG",
    Latitude: "-26.2074",
    Longitude: "28.0595",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7910",
    WardLookupId: "1204",
    VDNumber: "32850446",
    RegisteredPopulation: "3258",
    VotingStationName: "TEMPLE ISRAEL",
    Address: "PAUL NEL ST  HILLBROW  JOHANNESBURG",
    Latitude: "-26.1847",
    Longitude: "28.0481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7911",
    WardLookupId: "1204",
    VDNumber: "32850457",
    RegisteredPopulation: "5483",
    VotingStationName: "CHRIST CHURCH",
    Address: "3 CAROLINE STREET  HILLBROW  JOHANNESBURG",
    Latitude: "-26.1863",
    Longitude: "28.0465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7912",
    WardLookupId: "1204",
    VDNumber: "32850468",
    RegisteredPopulation: "4119",
    VotingStationName: "HILLBROW RECREATION CENTRE",
    Address: "CLARENDON PLACE  HILLBROW  JOHANNESBURG",
    Latitude: "-26.1889",
    Longitude: "28.0447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7913",
    WardLookupId: "1205",
    VDNumber: "32850413",
    RegisteredPopulation: "3701",
    VotingStationName: "BRETHREN IN CHRIST CHURCH",
    Address: "47 BANKET  HILLBROW  JOHANNESBURG",
    Latitude: "-26.1913",
    Longitude: "28.0501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7914",
    WardLookupId: "1205",
    VDNumber: "32850479",
    RegisteredPopulation: "4513",
    VotingStationName: "EVANGELICAL LUTHERAN CONGREGATION",
    Address: "30 EDITH CAVELL STREET  HILLBROW  JOHANNESBURG",
    Latitude: "-26.1925",
    Longitude: "28.0471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7915",
    WardLookupId: "1205",
    VDNumber: "32850985",
    RegisteredPopulation: "2798",
    VotingStationName: "AFM SHEKINAH INTERNATIONAL CHURCH",
    Address: "06 ESSELEN  HILLBROW  JOHANNESBURG",
    Latitude: "-26.19",
    Longitude: "28.0498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7916",
    WardLookupId: "1205",
    VDNumber: "32851223",
    RegisteredPopulation: "2005",
    VotingStationName: "BEREA SPORTS AND RECREATION OFFICE",
    Address: "15 SOPER ROAD  BEREA  CITY OF JOHANNESBURG",
    Latitude: "-26.1888",
    Longitude: "28.0543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7917",
    WardLookupId: "1206",
    VDNumber: "32850211",
    RegisteredPopulation: "3892",
    VotingStationName: "BARNATO PARK HIGH SCHOOL",
    Address: "BEATRICE LANE  BEREA  JOHANNESBURG",
    Latitude: "-26.1851",
    Longitude: "28.0514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7918",
    WardLookupId: "1206",
    VDNumber: "32850244",
    RegisteredPopulation: "2234",
    VotingStationName: "TENT (YEOVILLE)",
    Address: "CNR HENDON STR & JOE SLOVO ROAD  YEOVILLE  JOHANNESBURG",
    Latitude: "-26.1873",
    Longitude: "28.0581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7919",
    WardLookupId: "1206",
    VDNumber: "32850402",
    RegisteredPopulation: "4078",
    VotingStationName: "TENT (PUBLIC PARK LILY AVENUE BEREA)",
    Address: "LILY AVE  BEREA  JOHANNESBURG",
    Latitude: "-26.1887",
    Longitude: "28.0571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7920",
    WardLookupId: "1206",
    VDNumber: "32851212",
    RegisteredPopulation: "1853",
    VotingStationName: "HILLBROW LIBRARY",
    Address: "3 OLIVIA ROAD  BEREA  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.1879",
    Longitude: "28.0511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7921",
    WardLookupId: "1206",
    VDNumber: "32851234",
    RegisteredPopulation: "1888",
    VotingStationName: "JOHANNESBURG GIRLS PREPATORY SCHOOL",
    Address: "FIFE AVENUE  BEREA  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.1837",
    Longitude: "28.051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7922",
    WardLookupId: "1206",
    VDNumber: "32851245",
    RegisteredPopulation: "1767",
    VotingStationName: "TENT AT TERRACE ROAD BERTRAMS",
    Address: "TERRACE ROAD  BERTRAMS  CITY OF JOHANNESBURG",
    Latitude: "-26.1909",
    Longitude: "28.0625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7923",
    WardLookupId: "1207",
    VDNumber: "32850367",
    RegisteredPopulation: "5409",
    VotingStationName: "MALVERN PRIMARY SCHOOL",
    Address: "FRERE ST  MALVERN  JOHANNESBURG",
    Latitude: "-26.2012",
    Longitude: "28.1073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7924",
    WardLookupId: "1207",
    VDNumber: "32851087",
    RegisteredPopulation: "3068",
    VotingStationName: "ABELBAILE PARK HALL",
    Address: "CNR MARSHALL AND MCDONALD  MALVERN  JOHANNESBURG",
    Latitude: "-26.2032",
    Longitude: "28.081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7925",
    WardLookupId: "1207",
    VDNumber: "32851403",
    RegisteredPopulation: "521",
    VotingStationName: "DENVER SECONDARY SCHOOL",
    Address: "01 TUCKER   MALVERN  JOHANNESBURG",
    Latitude: "-26.2057",
    Longitude: "28.0903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7926",
    WardLookupId: "1207",
    VDNumber: "32860098",
    RegisteredPopulation: "6741",
    VotingStationName: "BAMBANANI PRE SCHOOL",
    Address: "BENROSE  JOHANNESBURG",
    Latitude: "-26.2148",
    Longitude: "28.0828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7927",
    WardLookupId: "1207",
    VDNumber: "32862764",
    RegisteredPopulation: "1798",
    VotingStationName: "EKUKHANYENI MISSION SCHOOL",
    Address: "18 MAIN REEF ROAD CNR KERK STREET  DENVER  JOHANNESBURG",
    Latitude: "-26.2047",
    Longitude: "28.1041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7928",
    WardLookupId: "1208",
    VDNumber: "32850299",
    RegisteredPopulation: "3421",
    VotingStationName: "SIR EDMUND HILLARY PRIMARY SCHOOL",
    Address: "23 CUMBERLAND ROAD  KENSINGTON  JOHANNESBURG",
    Latitude: "-26.1911",
    Longitude: "28.0911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7929",
    WardLookupId: "1208",
    VDNumber: "32850301",
    RegisteredPopulation: "4041",
    VotingStationName: "HOFLAND PARK RECREATION CENTRE",
    Address: "BETWEEN 4TH ST & 3RD AVE  BEZUIDENHOUT VALLEY  JOHANNESBURG",
    Latitude: "-26.1886",
    Longitude: "28.0825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7930",
    WardLookupId: "1208",
    VDNumber: "32850312",
    RegisteredPopulation: "2000",
    VotingStationName: "DOUG WHITEHEAD SCHOOL",
    Address: "24 CRESSY STREET  KENSINGTON  JOHANNESBURG",
    Latitude: "-26.1954",
    Longitude: "28.077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7931",
    WardLookupId: "1208",
    VDNumber: "32850996",
    RegisteredPopulation: "2474",
    VotingStationName: "YEOVILLE COMMUNITY SCHOOL",
    Address:
      "CNR MULLER STREET & BEDFORD ROAD  YEOVILLE  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.1788",
    Longitude: "28.0654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7932",
    WardLookupId: "1208",
    VDNumber: "32851100",
    RegisteredPopulation: "897",
    VotingStationName: "OBSERVATORY EAST PRIMARY SCHOOL",
    Address:
      "SHORT STREET  OBSERVATORY EXTENSION  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.1814",
    Longitude: "28.0857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7933",
    WardLookupId: "1208",
    VDNumber: "32851290",
    RegisteredPopulation: "775",
    VotingStationName: "THE KENSINGTON CLUB",
    Address: "8 IVANHOE  KENSINGTON  JOHANNESBURG",
    Latitude: "-26.1974",
    Longitude: "28.087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7934",
    WardLookupId: "1208",
    VDNumber: "32851302",
    RegisteredPopulation: "914",
    VotingStationName: "JEPPE GIRLS HIGH SCHOOL",
    Address: "JEPPE  JEPPES TOWN  JOHANNESBURG",
    Latitude: "-26.1972",
    Longitude: "28.091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7935",
    WardLookupId: "1208",
    VDNumber: "32851414",
    RegisteredPopulation: "496",
    VotingStationName: "MAURICE FREEMAN RECREATION CENTRE",
    Address: "19 FULLER AND THAMES  BERTRAMS  JOHANNESBURG",
    Latitude: "-26.1945",
    Longitude: "28.0674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7936",
    WardLookupId: "1209",
    VDNumber: "32850176",
    RegisteredPopulation: "1075",
    VotingStationName: "KING EDWARD VII SCHOOL",
    Address: "44 ST PATRICK ROAD  HOUGHTON  JOHANNESBURG",
    Latitude: "-26.1739",
    Longitude: "28.0626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7937",
    WardLookupId: "1209",
    VDNumber: "32850200",
    RegisteredPopulation: "7891",
    VotingStationName: "YEOVILLE RECREATION CENTRE",
    Address: "36 RALEIGH STREET  YEOVILLE  JOHANNESBURG",
    Latitude: "-26.1817",
    Longitude: "28.0622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7938",
    WardLookupId: "1209",
    VDNumber: "32850222",
    RegisteredPopulation: "1802",
    VotingStationName: "YEOVILLE BOYS PRIMARY SCHOOL",
    Address: "MULLER  STREET  YEOVILLE  JOHANNESBURG",
    Latitude: "-26.1796",
    Longitude: "28.0635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7939",
    WardLookupId: "1209",
    VDNumber: "32850929",
    RegisteredPopulation: "2891",
    VotingStationName: "UNIVERSITY OF THE WITWATERSRAND EDUCATION CAMPUS",
    Address: "ST ANDREWS ROAD  PARKTOWN  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.1805",
    Longitude: "28.043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7940",
    WardLookupId: "1210",
    VDNumber: "32840265",
    RegisteredPopulation: "1034",
    VotingStationName: "ANTEA HOSTEL",
    Address: "CNR KELVIN & MARISBURG ROAD  INDUSTRIA  JOHANNESBURG",
    Latitude: "-26.1992",
    Longitude: "27.9723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7941",
    WardLookupId: "1210",
    VDNumber: "32840287",
    RegisteredPopulation: "3553",
    VotingStationName: "RIVERLEA RECREATION CENTRE",
    Address: "CNR COLORADO DR & AVON STREET  RIVERLEA  JOHANNESBURG",
    Latitude: "-26.2128",
    Longitude: "27.9757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7942",
    WardLookupId: "1210",
    VDNumber: "32840344",
    RegisteredPopulation: "2617",
    VotingStationName: "WILHELMINA HOSKINS PRIMARY SCHOOL",
    Address: "CNR TARKA & KARIEGA  RIVERLEA EXT 1  JOHANNESBURG",
    Latitude: "-26.2173",
    Longitude: "27.9743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7943",
    WardLookupId: "1210",
    VDNumber: "32840669",
    RegisteredPopulation: "3626",
    VotingStationName: "PENNYVILLE CRECHE",
    Address: "1265 RIETBOK ROAD  PENNYVILLE  JOHANNESBURG",
    Latitude: "-26.2138",
    Longitude: "27.9491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7944",
    WardLookupId: "1210",
    VDNumber: "32841233",
    RegisteredPopulation: "2109",
    VotingStationName: "LITTLE PEOPLE PRE-SCHOOL",
    Address: "AALWYN STR (PLOT 1231)  RIVERLEA EXT 2  JOHANNESBURG",
    Latitude: "-26.2057",
    Longitude: "27.961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7945",
    WardLookupId: "1210",
    VDNumber: "32841626",
    RegisteredPopulation: "1293",
    VotingStationName: "TENT (PENNYVILLE PHASE 4)",
    Address: "CRN ELAND & SPRINGBOK  PENNYVILLE PHASE 4  JOHANNESBURG",
    Latitude: "-26.2168",
    Longitude: "27.9392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7946",
    WardLookupId: "1210",
    VDNumber: "32841637",
    RegisteredPopulation: "1306",
    VotingStationName: "HORSESHOE MUSLIM & ISLAMIC CENTRE",
    Address: "CNR MAIN & AALWYN STREET  RIVERLEA EXT 2  JOHANNESBURG",
    Latitude: "-26.2055",
    Longitude: "27.9653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7947",
    WardLookupId: "1210",
    VDNumber: "32841738",
    RegisteredPopulation: "942",
    VotingStationName: "RIVERLEA EMPOWERMENT CENTRE",
    Address: "1686 NASREC AND SANDPIPER DRIVE  RIVERLEA EXT 5  JOHANNESBURG",
    Latitude: "-26.2162",
    Longitude: "27.9855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7948",
    WardLookupId: "1211",
    VDNumber: "32840186",
    RegisteredPopulation: "3388",
    VotingStationName: "WESTDENE RECREATION CENTRE",
    Address: "CNR 4TH & PARKLANE NORTH  WESTDENE  JOHANNESBURG",
    Latitude: "-26.1768",
    Longitude: "27.988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7949",
    WardLookupId: "1211",
    VDNumber: "32840210",
    RegisteredPopulation: "1705",
    VotingStationName: "CORONATIONVILLE RECREATION CENTRE",
    Address: "159 CALEDON STR  CORONATIONVILLE  JOHANNESBURG",
    Latitude: "-26.1892",
    Longitude: "27.9765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7950",
    WardLookupId: "1211",
    VDNumber: "32840322",
    RegisteredPopulation: "1485",
    VotingStationName: "NG GEMEENTE WESTDENE",
    Address: "13 4TH AVENUE  WESTDENE  JOHANNESBURG",
    Latitude: "-26.1825",
    Longitude: "27.9826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7951",
    WardLookupId: "1211",
    VDNumber: "32841301",
    RegisteredPopulation: "2100",
    VotingStationName: "CORONATIONVILLE SECONDARY SCHOOL",
    Address:
      "555 CNR HARMONY & RIVERSDAL STREET  CORONATIONVILLE  JOHANNESBURG",
    Latitude: "-26.1935",
    Longitude: "27.9765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7952",
    WardLookupId: "1211",
    VDNumber: "32841378",
    RegisteredPopulation: "2908",
    VotingStationName: "WESTBURY RECREATION CENTRE",
    Address: "CNR ROBERTS AVE & DU PLESSIS  WESTBURY  JOHANNESBURG",
    Latitude: "-26.1826",
    Longitude: "27.975",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7953",
    WardLookupId: "1211",
    VDNumber: "32841648",
    RegisteredPopulation: "2611",
    VotingStationName: "UNIVERSITY OF JOHANNESBURG AUCKLAND PARK CAMPUS",
    Address: "PLUNKETT  WESTDENE  JOHANNESBURG",
    Latitude: "-26.1835",
    Longitude: "27.9965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7954",
    WardLookupId: "1211",
    VDNumber: "32841659",
    RegisteredPopulation: "2058",
    VotingStationName: "BRIXTON RECREATION CENTRE",
    Address: "67 CAROLINE  BRIXTON  JOHANNESBURG",
    Latitude: "-26.1914",
    Longitude: "27.9967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7955",
    WardLookupId: "1211",
    VDNumber: "32841828",
    RegisteredPopulation: "233",
    VotingStationName: "VORENTOE HIGH SCHOOL",
    Address: "STUDENT AVENUE  ROSSMORE - AUCKLAND PARK  JOHANNESBURG",
    Latitude: "-26.1867",
    Longitude: "27.993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7956",
    WardLookupId: "1212",
    VDNumber: "32870189",
    RegisteredPopulation: "3832",
    VotingStationName: "MOTHS HALL",
    Address: "CNR 4TH & GREEN STREET  FLORIDA  ROODEPOORT",
    Latitude: "-26.1782",
    Longitude: "27.9099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7957",
    WardLookupId: "1212",
    VDNumber: "32870190",
    RegisteredPopulation: "2848",
    VotingStationName: "FLORIDA PRIMARY SCHOOL",
    Address: "28 ROSE STREET  FLORIDA  ROODEPOORT",
    Latitude: "-26.1757",
    Longitude: "27.9168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7958",
    WardLookupId: "1212",
    VDNumber: "32870202",
    RegisteredPopulation: "2688",
    VotingStationName: "FLORIDA PARK HIGH SCHOOL",
    Address: "PETUNIA STR  FLORIDA PARK  ROODEPOORT",
    Latitude: "-26.1662",
    Longitude: "27.9108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7959",
    WardLookupId: "1212",
    VDNumber: "32870213",
    RegisteredPopulation: "3670",
    VotingStationName: "HOÃ‹RSKOOL FLORIDA",
    Address: "LOUIS BOTHA DRIVE  FLORIDA PARK  ROODEPOORT",
    Latitude: "-26.1656",
    Longitude: "27.9208",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7960",
    WardLookupId: "1212",
    VDNumber: "32870224",
    RegisteredPopulation: "2116",
    VotingStationName: "DONOVAN MCDONALD CENTRE",
    Address: "CNR MAUDE & DU PLESSIS AVE  FLORIDA  EXT 2  ROODEPOORT",
    Latitude: "-26.1786",
    Longitude: "27.9341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7961",
    WardLookupId: "1212",
    VDNumber: "32870235",
    RegisteredPopulation: "2783",
    VotingStationName: "FLEURHOF COMMUNITY HALL",
    Address: "MALICITE AVE  FLEURHOF  ROODEPOORT",
    Latitude: "-26.2011",
    Longitude: "27.9127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7962",
    WardLookupId: "1212",
    VDNumber: "32871461",
    RegisteredPopulation: "3107",
    VotingStationName: "FLEURHOF PRIMARY SCHOOL",
    Address: "HELPMEKAAR DRIVE  FLEUFHOF  FLORIDA",
    Latitude: "-26.1964",
    Longitude: "27.9103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7963",
    WardLookupId: "1212",
    VDNumber: "32871528",
    RegisteredPopulation: "469",
    VotingStationName: "FUTURE NATION SCHOOL",
    Address: "X  ",
    Latitude: "-26.2075",
    Longitude: "27.9148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7964",
    WardLookupId: "1213",
    VDNumber: "32870022",
    RegisteredPopulation: "4778",
    VotingStationName: "ADELAAR HOÃ‹RSKOOL",
    Address: "CNR SOUTH ROAD & VERMOOTEN STREET  PRINCESS  ROODEPOORT",
    Latitude: "-26.1397",
    Longitude: "27.8414",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7965",
    WardLookupId: "1213",
    VDNumber: "32870033",
    RegisteredPopulation: "3634",
    VotingStationName: "LAERSKOOL ROODEBEECK",
    Address: "CNR HUGENOOT & DROMEDARIS  WITPOORTJIE  ROODEPOORT",
    Latitude: "-26.1546",
    Longitude: "27.8283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7966",
    WardLookupId: "1213",
    VDNumber: "32870044",
    RegisteredPopulation: "3056",
    VotingStationName: "LAERSKOOL CULEMBEECK",
    Address: "PROOT STREET  WITPOORTJIE  ROODEPOORT",
    Latitude: "-26.1615",
    Longitude: "27.821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7967",
    WardLookupId: "1213",
    VDNumber: "32870055",
    RegisteredPopulation: "2870",
    VotingStationName: "DAVIDSONVILLE COMMUNITY CENTRE",
    Address: "MINNAAR STREET  DAVIDSONVILLE X2  ROODEPOORT",
    Latitude: "-26.1545",
    Longitude: "27.8486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7968",
    WardLookupId: "1213",
    VDNumber: "32870077",
    RegisteredPopulation: "2998",
    VotingStationName: "LANTERN SCHOOL",
    Address: "ALBERTINA SISULU & SOUTH ROAD  LINDHAVEN  ROODEPOORT",
    Latitude: "-26.1422",
    Longitude: "27.8572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7969",
    WardLookupId: "1214",
    VDNumber: "32850086",
    RegisteredPopulation: "3267",
    VotingStationName: "GLENHAZEL PRIMARY SCHOOL",
    Address: "48 LONG AVENUE  GLENHAZEL  JOHANNESBURG",
    Latitude: "-26.1336",
    Longitude: "28.104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7970",
    WardLookupId: "1214",
    VDNumber: "32850109",
    RegisteredPopulation: "3516",
    VotingStationName: "JABULA RECREATION CENTRE",
    Address: "CNR ANNE ST & ATHLONE  SANDRINGHAM  JOHANNESBURG",
    Latitude: "-26.1462",
    Longitude: "28.1073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7971",
    WardLookupId: "1214",
    VDNumber: "32850110",
    RegisteredPopulation: "4179",
    VotingStationName: "ORANGE GROVE PRIMARY SCHOOL",
    Address: "PEMBROKE STREET  SYDENHAM  JOHANNESBURG",
    Latitude: "-26.1565",
    Longitude: "28.0885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7972",
    WardLookupId: "1214",
    VDNumber: "32850277",
    RegisteredPopulation: "2338",
    VotingStationName: "LINKSFIELD PRIMARY SCHOOL",
    Address: "CNR CLUB & KLOOF STREET  LINKSFIELD  JOHANNESBURG",
    Latitude: "-26.1652",
    Longitude: "28.0911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7973",
    WardLookupId: "1214",
    VDNumber: "32851188",
    RegisteredPopulation: "1986",
    VotingStationName: "SUMMERWOOD PRIMARY SCHOOL",
    Address:
      "CNR WARNE AND MURIEL ST  FAIRMOUNT  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.1446",
    Longitude: "28.0957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7974",
    WardLookupId: "1215",
    VDNumber: "32850132",
    RegisteredPopulation: "4324",
    VotingStationName: "NORWOOD PRIMARY SCHOOL",
    Address: "SCOLTZ AVENUE  NORWOOD  JOHANNESBURG",
    Latitude: "-26.1573",
    Longitude: "28.0722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7975",
    WardLookupId: "1215",
    VDNumber: "32850143",
    RegisteredPopulation: "3977",
    VotingStationName: "PATERSON PARK RECREATION CENTRE",
    Address: "7TH STREET  ORANGE GROVE  JOHANNESBURG",
    Latitude: "-26.1628",
    Longitude: "28.0784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7976",
    WardLookupId: "1215",
    VDNumber: "32850154",
    RegisteredPopulation: "3189",
    VotingStationName: "HOUGHTON PRIMARY SCHOOL",
    Address: "1ST AVENUE  LOWER HOUGHTON  JOHANNESBURG",
    Latitude: "-26.1685",
    Longitude: "28.0662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7977",
    WardLookupId: "1215",
    VDNumber: "32850198",
    RegisteredPopulation: "4329",
    VotingStationName: "KILLARNEY COUNTRY CLUB",
    Address: "60 5TH STREET  LOWER HOUGHTON  JOHANNESBURG",
    Latitude: "-26.1495",
    Longitude: "28.0543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7978",
    WardLookupId: "1216",
    VDNumber: "32850075",
    RegisteredPopulation: "3136",
    VotingStationName: "BRAMLEY PRIMARY SCHOOL",
    Address: "59 EDEN ROAD  BRAMLEY  JOHANNESBURG",
    Latitude: "-26.124",
    Longitude: "28.0791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7979",
    WardLookupId: "1216",
    VDNumber: "32850121",
    RegisteredPopulation: "2142",
    VotingStationName: "ORCHARDS PRIMARY SCHOOL",
    Address: "ORANGE ROAD  ORCHARDS  JOHANNESBURG",
    Latitude: "-26.1524",
    Longitude: "28.0783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7980",
    WardLookupId: "1216",
    VDNumber: "32850480",
    RegisteredPopulation: "5501",
    VotingStationName: "H A JACK PRIMARY SCHOOL",
    Address: "JAUNCY & PRESTON STR  HIGHLANDS NORTH  JOHANNESBURG",
    Latitude: "-26.1418",
    Longitude: "28.073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7981",
    WardLookupId: "1216",
    VDNumber: "32851313",
    RegisteredPopulation: "1850",
    VotingStationName: "FAIRWAY PRIMARY SCHOOL",
    Address: "CORLETT DRIVE AND IRENE RD  FAIRWAYS  JOHANNESBURG",
    Latitude: "-26.1315",
    Longitude: "28.061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7982",
    WardLookupId: "1216",
    VDNumber: "32851324",
    RegisteredPopulation: "1419",
    VotingStationName: "ST VINCENT SCHOOL FOR THE DEAF",
    Address: "CNR JELICOE&CECIL AVE  MELROSE  JOHANNESBURG",
    Latitude: "-26.1415",
    Longitude: "28.0456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7983",
    WardLookupId: "1216",
    VDNumber: "32851335",
    RegisteredPopulation: "1838",
    VotingStationName: "GRESSWOLD SENIOR SCHOOL",
    Address: "PORTLOCK AND HATHAWAY RD  GRESSWOLD  GRESSWORD",
    Latitude: "-26.1343",
    Longitude: "28.0891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7984",
    WardLookupId: "1217",
    VDNumber: "32850907",
    RegisteredPopulation: "1779",
    VotingStationName: "EKUKHANYISWENI LOWER PRIMARY SCHOOL",
    Address: "100 6TH AVENUE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1091",
    Longitude: "28.0939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7985",
    WardLookupId: "1217",
    VDNumber: "32850952",
    RegisteredPopulation: "5595",
    VotingStationName: "ALEXANDRA LIBRARY (NEW LIBRARY)",
    Address: "126 3RD AVE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1124",
    Longitude: "28.0918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7986",
    WardLookupId: "1217",
    VDNumber: "32851009",
    RegisteredPopulation: "2874",
    VotingStationName: "WOMEN FOR PEACE",
    Address: "99 8 TH AVENUE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1112",
    Longitude: "28.0966",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7987",
    WardLookupId: "1217",
    VDNumber: "32851010",
    RegisteredPopulation: "2160",
    VotingStationName: "JESUS IS THE ANSWER MINISTRIES",
    Address: "129 11TH AVENUE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1112",
    Longitude: "28.0985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7988",
    WardLookupId: "1217",
    VDNumber: "32851177",
    RegisteredPopulation: "2892",
    VotingStationName: "KHUMALOS CHURCH",
    Address: "150 3RD AVENUE  ALEXANDRA  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.1148",
    Longitude: "28.0925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7989",
    WardLookupId: "1217",
    VDNumber: "32851380",
    RegisteredPopulation: "1247",
    VotingStationName: "NKANYEZI PRIMARY SCHOOL",
    Address: "166 ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1144",
    Longitude: "28.0958",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7990",
    WardLookupId: "1218",
    VDNumber: "32850266",
    RegisteredPopulation: "2524",
    VotingStationName: "THUSONG YOUTH CENTRE",
    Address: "ENTOKOZWENI WELFARE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1112",
    Longitude: "28.0998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7991",
    WardLookupId: "1218",
    VDNumber: "32850503",
    RegisteredPopulation: "2829",
    VotingStationName: "ZENZELENI PRIMARY SCHOOL",
    Address: "CNR 19TH & LONDON  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1109",
    Longitude: "28.1083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7992",
    WardLookupId: "1218",
    VDNumber: "32850974",
    RegisteredPopulation: "2706",
    VotingStationName: "3 SQUARE GROUNDS",
    Address: "13 TH AVENUE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1109",
    Longitude: "28.1012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7993",
    WardLookupId: "1218",
    VDNumber: "32851098",
    RegisteredPopulation: "3850",
    VotingStationName: "TENT (RUTH AND 16TH AVENUE)",
    Address: "CNR OF RUTH & 16TH AVENUE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1094",
    Longitude: "28.1043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7994",
    WardLookupId: "1218",
    VDNumber: "32851111",
    RegisteredPopulation: "1001",
    VotingStationName: "EMADLANGENI RESIDENCE",
    Address: "153 9TH AVENUE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1134",
    Longitude: "28.0982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7995",
    WardLookupId: "1218",
    VDNumber: "32851122",
    RegisteredPopulation: "1905",
    VotingStationName: "TENT AT JUSKEI PARK",
    Address: "CNR ROOTH & 21ST AVENUE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1079",
    Longitude: "28.1095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7996",
    WardLookupId: "1219",
    VDNumber: "32910094",
    RegisteredPopulation: "4757",
    VotingStationName: "IVORY PARK SECONDARY SCHOOL",
    Address: "15260 CNR RIVERSIDE & MALATJI ST  IVORY PARK EXT 9  MIDRAND",
    Latitude: "-25.9805",
    Longitude: "28.2012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7997",
    WardLookupId: "1219",
    VDNumber: "32910252",
    RegisteredPopulation: "3511",
    VotingStationName: "MIKATEKA PRIMARY SCHOOL",
    Address: "8551 NGOBESE STREET  IVORY PARK EXT 9  MIDRAND",
    Latitude: "-25.9885",
    Longitude: "28.2093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7998",
    WardLookupId: "1219",
    VDNumber: "32910274",
    RegisteredPopulation: "3765",
    VotingStationName: "EBOMINI SCHOOL",
    Address: "13117 MAKHUNA ST  IVORY PARK EXT 12  MIDRAND",
    Latitude: "-25.9837",
    Longitude: "28.2021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "7999",
    WardLookupId: "1219",
    VDNumber: "32910285",
    RegisteredPopulation: "4586",
    VotingStationName: "TENT (MPUMELELO CLINIC)",
    Address: "13985 NKOSI STREET  IVORY PARK EXT 12  MIDRAND",
    Latitude: "-25.9859",
    Longitude: "28.2088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8000",
    WardLookupId: "1220",
    VDNumber: "32910151",
    RegisteredPopulation: "5570",
    VotingStationName: "REBONWE PRIMARY SCHOOL",
    Address: "8826 NGONYAMA  MIDRAND - IVORY PARK  JOHANNESBURG",
    Latitude: "-26.0011",
    Longitude: "28.202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8001",
    WardLookupId: "1220",
    VDNumber: "32910184",
    RegisteredPopulation: "5183",
    VotingStationName: "IVORY PARK COMMUNITY HALL 5",
    Address: "4325 MAKHAYA   IVORY PARK  MIDRAND",
    Latitude: "-26.0088",
    Longitude: "28.1898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8002",
    WardLookupId: "1220",
    VDNumber: "32910229",
    RegisteredPopulation: "4205",
    VotingStationName: "MYNHARDT HALL",
    Address: "2717 MATHABATHE ST  IVORY PARK EXT 3  MIDRAND",
    Latitude: "-26.0116",
    Longitude: "28.1864",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8003",
    WardLookupId: "1220",
    VDNumber: "32910386",
    RegisteredPopulation: "1890",
    VotingStationName: "GOSPEL CHURCH",
    Address: "FREEDOM DRIVE  IVORY PARK  IVORY PARK",
    Latitude: "-26.0086",
    Longitude: "28.1893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8004",
    WardLookupId: "1221",
    VDNumber: "32910173",
    RegisteredPopulation: "4052",
    VotingStationName: "EQINISWENI SECONDARY SCHOOL",
    Address: "5230 MASAKHANE DRIVE  IVORY PARK EXT 7  MIDRAND",
    Latitude: "-26.0047",
    Longitude: "28.1845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8005",
    WardLookupId: "1221",
    VDNumber: "32910195",
    RegisteredPopulation: "4283",
    VotingStationName: "IVORY PARK PRIMARY SCHOOL",
    Address: "587 IVORY PARK EXT 2  IVORY PARK  MIDRAND",
    Latitude: "-26.0088",
    Longitude: "28.1735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8006",
    WardLookupId: "1221",
    VDNumber: "32910207",
    RegisteredPopulation: "3719",
    VotingStationName: "IKAGENG EDUCARE CENTRE",
    Address: "1319 IVORY PARK EXT 2  IVORY PARK  MIDRAND",
    Latitude: "-26.0076",
    Longitude: "28.1795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8007",
    WardLookupId: "1221",
    VDNumber: "32910218",
    RegisteredPopulation: "4833",
    VotingStationName: "VUKUZENZELE CRECHE",
    Address: "1942 SITHOLE ST  IVORY PARK EXT 2  MIDRAND",
    Latitude: "-26.0121",
    Longitude: "28.1815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8008",
    WardLookupId: "1222",
    VDNumber: "32900217",
    RegisteredPopulation: "4864",
    VotingStationName: "KANANA COMMUNITY HALL",
    Address: "2990 RABIE RIDGE EXT 4  RABIE RIDGE  MIDRAND",
    Latitude: "-26.0276",
    Longitude: "28.1803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8009",
    WardLookupId: "1222",
    VDNumber: "32900228",
    RegisteredPopulation: "5543",
    VotingStationName: "KANANA PRIMARY SCHOOL",
    Address: "2357 RABIE RIDGE EXT 5  RABIE RIDGE  MIDRAND",
    Latitude: "-26.0322",
    Longitude: "28.1835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8010",
    WardLookupId: "1222",
    VDNumber: "32900295",
    RegisteredPopulation: "1918",
    VotingStationName: "TENT AT KANANA",
    Address: "PARLIAMENT  KANANA MIDRAND  JOHANNESBURG",
    Latitude: "-26.0264",
    Longitude: "28.1771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8011",
    WardLookupId: "1222",
    VDNumber: "32910443",
    RegisteredPopulation: "1234",
    VotingStationName: "AFM OF SA. EMMAUEL REVIVAL CENTRE",
    Address: "731 RIETDUIKER ST,  MIDRAND  RABIE RIDGE",
    Latitude: "-26.0207",
    Longitude: "28.1752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8012",
    WardLookupId: "1222",
    VDNumber: "32910500",
    RegisteredPopulation: "416",
    VotingStationName: "RABIE RIDGE COMMUNITY HALL",
    Address: "3 KORHAAN CNR KRAAI ST  RABIE RIDGE  MIDRAND",
    Latitude: "-26.0199",
    Longitude: "28.1718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8013",
    WardLookupId: "1223",
    VDNumber: "32850064",
    RegisteredPopulation: "3415",
    VotingStationName: "LYNDHURST PRIMARY SCHOOL",
    Address: "CNR JOHANNESBURG ROAD & KERNIC  LYNDHURST  JOHANNESBURG",
    Latitude: "-26.1282",
    Longitude: "28.1023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8014",
    WardLookupId: "1223",
    VDNumber: "32850097",
    RegisteredPopulation: "3817",
    VotingStationName: "REMBRANDT PARK PRIMARY SCHOOL",
    Address: "MARLBOROUGH ROAD  LOMBARDY EAST  JOHANNESBURG",
    Latitude: "-26.1218",
    Longitude: "28.1277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8015",
    WardLookupId: "1223",
    VDNumber: "32850165",
    RegisteredPopulation: "4801",
    VotingStationName: "OLD APOSTOLIC CHURCH.",
    Address: "VAN GELDER  BRAMLEY VIEW  JOHANNESBURG",
    Latitude: "-26.118",
    Longitude: "28.1004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8016",
    WardLookupId: "1223",
    VDNumber: "32850491",
    RegisteredPopulation: "1015",
    VotingStationName: "TARENTAAL RETIREMENT VILLAGE",
    Address: "200 MODDERFONTEIN ROAD  LYNDHURST  JOHANNESBURG",
    Latitude: "-26.129",
    Longitude: "28.1241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8017",
    WardLookupId: "1223",
    VDNumber: "32851043",
    RegisteredPopulation: "3802",
    VotingStationName: "TENT (RIVER PARK)",
    Address: "MARULA CRESCENT  RIVER PARK , ALEX  JOHANNESBURG",
    Latitude: "-26.1079",
    Longitude: "28.1221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8018",
    WardLookupId: "1223",
    VDNumber: "32851155",
    RegisteredPopulation: "1492",
    VotingStationName: "LYNDHURST BAPTIST CHURCH",
    Address: "161 MORKEL ROAD  LYNDHURST  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.1272",
    Longitude: "28.1093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8019",
    WardLookupId: "1224",
    VDNumber: "32840029",
    RegisteredPopulation: "1919",
    VotingStationName: "CLAREMONT PRIMARY SCHOOL",
    Address: "C/N ACKERMAN AND ROSE STREET  CLAREMONT  JOHANNESBURG",
    Latitude: "-26.1823",
    Longitude: "27.9602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8020",
    WardLookupId: "1224",
    VDNumber: "32840030",
    RegisteredPopulation: "3963",
    VotingStationName: "DANIE VAN ZYL RECREATION CENTRE",
    Address: "CNR PLANTATION & REX  CLAREMONT  JOHANNESBURG",
    Latitude: "-26.1772",
    Longitude: "27.9601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8021",
    WardLookupId: "1224",
    VDNumber: "32840052",
    RegisteredPopulation: "2673",
    VotingStationName: "DOWLING AVENUE PRIMARY SCHOOL",
    Address: "96 DOWLING AVE  NEWCLARE  JOHANNESBURG",
    Latitude: "-26.1816",
    Longitude: "27.9703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8022",
    WardLookupId: "1224",
    VDNumber: "32840276",
    RegisteredPopulation: "3258",
    VotingStationName: "BOSMONT RECREATION CENTRE",
    Address: "MARAISBURG ROAD  BOSMONT  JOHANNESBURG [REG.B]",
    Latitude: "-26.1891",
    Longitude: "27.9578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8023",
    WardLookupId: "1224",
    VDNumber: "32840298",
    RegisteredPopulation: "1595",
    VotingStationName: "GEREFORMEERDE KERK - NEWCLARE",
    Address: "264 PRICE STREET  INDUSTRIA WEST  JOHANNESBURG",
    Latitude: "-26.188",
    Longitude: "27.9661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8024",
    WardLookupId: "1224",
    VDNumber: "32840333",
    RegisteredPopulation: "2040",
    VotingStationName: "NEWCLARE PRIMARY SCHOOL",
    Address: "STEYTLER & POLLACK AVENUE  NEWCLARE  JOHANNESBURG [REG.B]",
    Latitude: "-26.1852",
    Longitude: "27.9697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8025",
    WardLookupId: "1224",
    VDNumber: "32841299",
    RegisteredPopulation: "2551",
    VotingStationName: "NEWCLARE MASJID CRECHE",
    Address: "CNR STAR & BEVAN  NEWCLARE  JOHANNESBURG",
    Latitude: "-26.1852",
    Longitude: "27.9643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8026",
    WardLookupId: "1225",
    VDNumber: "32870066",
    RegisteredPopulation: "2900",
    VotingStationName: "WESTRAND LUTHERAN CHURCH",
    Address: "CNR CR SWART ROAD & NABOOM  WILROPARK  ROODEPOORT",
    Latitude: "-26.1234",
    Longitude: "27.8498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8027",
    WardLookupId: "1225",
    VDNumber: "32870268",
    RegisteredPopulation: "3647",
    VotingStationName: "HELDERKRUIN LAERSKOOL",
    Address: "CNR BANKET & RUHAMA  HELDERKRUIN  ROODEPOORT",
    Latitude: "-26.1238",
    Longitude: "27.8645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8028",
    WardLookupId: "1225",
    VDNumber: "32870279",
    RegisteredPopulation: "1994",
    VotingStationName: "NG KERK ROODEKRANS",
    Address: "CNR MOEPEL & AZALEA  ROODEKRANS  ROODEPOORT",
    Latitude: "-26.1112",
    Longitude: "27.8406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8029",
    WardLookupId: "1225",
    VDNumber: "32870280",
    RegisteredPopulation: "3864",
    VotingStationName: "LAERSKOOL ROODEKRANS",
    Address: "CNR WILDE AMANDEL & GARDENIA  ROODEKRANS  ROODEPOORT",
    Latitude: "-26.1071",
    Longitude: "27.8443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8030",
    WardLookupId: "1225",
    VDNumber: "32870291",
    RegisteredPopulation: "3766",
    VotingStationName: "KLEIN HELDERKRUIN CENTRE",
    Address: "CNR OUKLIP & GRAPHITE  WILRO PARK  ROODEPOORT",
    Latitude: "-26.113",
    Longitude: "27.8609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8031",
    WardLookupId: "1226",
    VDNumber: "32870088",
    RegisteredPopulation: "1732",
    VotingStationName: "SOUTH WEST GAUTENG COLLEGE",
    Address: "WEBBER AVENUE  HORISON VIEW  ROODEPOORT",
    Latitude: "-26.1407",
    Longitude: "27.867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8032",
    WardLookupId: "1226",
    VDNumber: "32870099",
    RegisteredPopulation: "4207",
    VotingStationName: "ROODEPOORT CITY HALL",
    Address: "BERLANDINA STREET  ROODEPOORT  ROODEPOORT",
    Latitude: "-26.1593",
    Longitude: "27.8657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8033",
    WardLookupId: "1226",
    VDNumber: "32870123",
    RegisteredPopulation: "2642",
    VotingStationName: "ROODEPOORT HOÃ‹RSKOOL",
    Address: "ADDERLEY & KILBURNSTR  HORISON  ROODEPOORT",
    Latitude: "-26.1541",
    Longitude: "27.8735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8034",
    WardLookupId: "1226",
    VDNumber: "32870156",
    RegisteredPopulation: "4052",
    VotingStationName: "DISCOVERY COMMUNITY CENTRE",
    Address: "522 ANSTRUTHER STR AND WALKER AVE  DISCOVERY  ROODEPOORT",
    Latitude: "-26.16",
    Longitude: "27.8932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8035",
    WardLookupId: "1226",
    VDNumber: "32871034",
    RegisteredPopulation: "2128",
    VotingStationName: "ROODEPARK SCHOOL",
    Address: "CLARENDON STREET  DISCOVERY  ROODEPOORT",
    Latitude: "-26.1668",
    Longitude: "27.894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8036",
    WardLookupId: "1226",
    VDNumber: "32871089",
    RegisteredPopulation: "3107",
    VotingStationName: "RUITER AFRIKAANS PRIMARY SCHOOL",
    Address: "HAMBERG ROAD  GEORGINIA  ROODEPOORT",
    Latitude: "-26.1703",
    Longitude: "27.8838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8037",
    WardLookupId: "1226",
    VDNumber: "32871472",
    RegisteredPopulation: "1017",
    VotingStationName: "JOZUA NAUDE LAERSKOOL",
    Address: "2 PAUL KRUGER & RAATH STR  HORISON  ROODEPOORT",
    Latitude: "-26.1563",
    Longitude: "27.8765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8038",
    WardLookupId: "1227",
    VDNumber: "32870112",
    RegisteredPopulation: "2925",
    VotingStationName: "LAERSKOOL HORISON",
    Address: "WEAVER STREET  HORISON  ROODEPOORT",
    Latitude: "-26.1414",
    Longitude: "27.8807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8039",
    WardLookupId: "1227",
    VDNumber: "32870167",
    RegisteredPopulation: "1601",
    VotingStationName: "GEREFORMEERDE KERK",
    Address: "THEATRE STREET  FLORIDA PARK  ROODEPOORT",
    Latitude: "-26.1554",
    Longitude: "27.8995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8040",
    WardLookupId: "1227",
    VDNumber: "32870178",
    RegisteredPopulation: "2338",
    VotingStationName: "BIOKINETICS CENTRE",
    Address: "PHEASANT STREET  HORISON PARK  ROODEPOORT",
    Latitude: "-26.1369",
    Longitude: "27.8693",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8041",
    WardLookupId: "1227",
    VDNumber: "32870303",
    RegisteredPopulation: "1516",
    VotingStationName: "KLOOFENDAL ECOLOGICAL CENTRE",
    Address: "AMFITEATER STREET  KLOOFENDAL  ROODEPOORT",
    Latitude: "-26.1307",
    Longitude: "27.8806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8042",
    WardLookupId: "1227",
    VDNumber: "32871012",
    RegisteredPopulation: "3685",
    VotingStationName: "LEBOGANG CRÃˆCHE PLOT 61",
    Address: "VAN DE LINDA STREET  PRINCESS  ROODEPOORT",
    Latitude: "-26.1372",
    Longitude: "27.8524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8043",
    WardLookupId: "1227",
    VDNumber: "32871023",
    RegisteredPopulation: "5472",
    VotingStationName: "STRUBENSVALLEY LIBRARY",
    Address: "FREDENHARRY STREET  STRUBENSVALLEY  ROODEPOORT",
    Latitude: "-26.1227",
    Longitude: "27.9005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8044",
    WardLookupId: "1228",
    VDNumber: "32840041",
    RegisteredPopulation: "3054",
    VotingStationName: "LAERSKOOL ESPERANZA",
    Address: "62 STONEWALL ST  NEWLANDS  JOHANNESBURG",
    Latitude: "-26.1704",
    Longitude: "27.9683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8045",
    WardLookupId: "1228",
    VDNumber: "32840063",
    RegisteredPopulation: "2935",
    VotingStationName: "GENL CHRISTIAN DE WET PRIMARY SCHOOL",
    Address: "15 VICTORIA RD  SOPHIATOWN  JOHANNESBURG",
    Latitude: "-26.1747",
    Longitude: "27.9753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8046",
    WardLookupId: "1228",
    VDNumber: "32840119",
    RegisteredPopulation: "3878",
    VotingStationName: "GREYMONT NG KERK (ALBERTS FARM)",
    Address: "24 2ND ROAD  GREYMONT  JOHANNESBURG",
    Latitude: "-26.1637",
    Longitude: "27.9681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8047",
    WardLookupId: "1228",
    VDNumber: "32870246",
    RegisteredPopulation: "2864",
    VotingStationName: "ARTHUR MATTHEWS PRIMARY SCHOOL",
    Address: "CNR ONTDEKKERS & DIE OU PAD  MARAISBURG  JOHANNESBURG [REG.B]",
    Latitude: "-26.1726",
    Longitude: "27.9379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8048",
    WardLookupId: "1228",
    VDNumber: "32870257",
    RegisteredPopulation: "3784",
    VotingStationName: "HOÃ‹RSKOOL DIE BURGER",
    Address: "23 EXHIBITION ROAD  WHITE RIDGE  ROODEPOORT",
    Latitude: "-26.1707",
    Longitude: "27.9513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8049",
    WardLookupId: "1228",
    VDNumber: "32871067",
    RegisteredPopulation: "3044",
    VotingStationName: "NG KERK QUELLERINA.",
    Address: "SNEEU STREET  QUELLERINA  JOHANNESBURG",
    Latitude: "-26.1607",
    Longitude: "27.9401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8050",
    WardLookupId: "1229",
    VDNumber: "32840164",
    RegisteredPopulation: "4172",
    VotingStationName: "GREENSIDE PRIMARY SCHOOL",
    Address: "VARDON ROAD  GREENSIDE  JOHANNESBURG",
    Latitude: "-26.1526",
    Longitude: "28.013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8051",
    WardLookupId: "1229",
    VDNumber: "32840209",
    RegisteredPopulation: "3530",
    VotingStationName: "HOPE CHURCH AUCKLAND PARK",
    Address: "45 DITTON  AUCKLAND PARK  JOHANNESBURG",
    Latitude: "-26.1866",
    Longitude: "28.0044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8052",
    WardLookupId: "1229",
    VDNumber: "32840311",
    RegisteredPopulation: "4127",
    VotingStationName: "THE RIDGE SCHOOL",
    Address: "26 WOOLSTON ROAD  WESTCLIFF  JOHANNESBURG",
    Latitude: "-26.1724",
    Longitude: "28.0272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8053",
    WardLookupId: "1229",
    VDNumber: "32841312",
    RegisteredPopulation: "2975",
    VotingStationName: "MELPARK PRIMARY SCHOOL",
    Address: "63 2ND AVE  MELVILLE  JOHANNESBURG",
    Latitude: "-26.1763",
    Longitude: "28.0054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8054",
    WardLookupId: "1229",
    VDNumber: "32850187",
    RegisteredPopulation: "1700",
    VotingStationName: "HOLY FAMILY COLLEGE",
    Address: "40 OXFORD ROAD  PARKTOWN  JOHANNESBURG",
    Latitude: "-26.168",
    Longitude: "28.0461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8055",
    WardLookupId: "1230",
    VDNumber: "32840074",
    RegisteredPopulation: "4578",
    VotingStationName: "FRANKLIN D ROOSEVELT PRIMARY SCHOOL",
    Address: "MENDELSSOHN ROAD  ROOSEVELT PARK  JOHANNESBURG",
    Latitude: "-26.1461",
    Longitude: "27.9879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8056",
    WardLookupId: "1230",
    VDNumber: "32840108",
    RegisteredPopulation: "4040",
    VotingStationName: "BERARIO COMMUNITY CENTRE",
    Address: "C/O DELORES & HOOVERSTR  BERARIO  ",
    Latitude: "-26.1356",
    Longitude: "27.9527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8057",
    WardLookupId: "1230",
    VDNumber: "32840120",
    RegisteredPopulation: "2489",
    VotingStationName: "NORTHCLIFF HIGH SCHOOL",
    Address: "MOUNTAINVIEW DRIVE  BLACKHEATH EXT 3  JOHANNESBURG",
    Latitude: "-26.1298",
    Longitude: "27.9646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8058",
    WardLookupId: "1230",
    VDNumber: "32840175",
    RegisteredPopulation: "3543",
    VotingStationName: "EMMARENTIA PRIMARY SCHOOL",
    Address: "CNR HILL & UMGENI ROADS  EMMARENTIA  JOHANNESBURG",
    Latitude: "-26.1649",
    Longitude: "28.011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8059",
    WardLookupId: "1230",
    VDNumber: "32841660",
    RegisteredPopulation: "1201",
    VotingStationName: "ROOSEVELT PARK BOWLING CLUB",
    Address: "MILNER AVENUE  FRANKLIN ROOSEVELT PARK  JOHANNESBURG",
    Latitude: "-26.1519",
    Longitude: "27.9813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8060",
    WardLookupId: "1231",
    VDNumber: "32840085",
    RegisteredPopulation: "3275",
    VotingStationName: "LAERSKOOL FAIRLAND",
    Address: "145 MARKETSTRAAT  FAIRLAND  JOHANNESBURG",
    Latitude: "-26.1348",
    Longitude: "27.9391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8061",
    WardLookupId: "1231",
    VDNumber: "32841671",
    RegisteredPopulation: "2556",
    VotingStationName: "CLIFFVIEW  PRIMARY SCHOOL",
    Address: "29 NORMAN DRIVE  NORTHCLIFF  JOHANNESBURG",
    Latitude: "-26.1478",
    Longitude: "27.9461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8062",
    WardLookupId: "1231",
    VDNumber: "32870325",
    RegisteredPopulation: "5612",
    VotingStationName: "PANORAMA PRIMARY SCHOOL",
    Address: "ALBERT STR  WELTEVREDEN PARK  ROODEPOORT",
    Latitude: "-26.1343",
    Longitude: "27.9234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8063",
    WardLookupId: "1231",
    VDNumber: "32870336",
    RegisteredPopulation: "4455",
    VotingStationName: "CONSTANTIA KLOOF PRIMARY SCHOOL",
    Address: "CNR YOLANDE STR & LIEBENBERG  CONSTANTIA KLOOF  ROODEPOORT",
    Latitude: "-26.1449",
    Longitude: "27.906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8064",
    WardLookupId: "1231",
    VDNumber: "32871045",
    RegisteredPopulation: "2467",
    VotingStationName: "CCH PRE SCHOOL",
    Address: "2 KAPOK STREET & CONSTATIA DRIVE  WELTEVREDEN PARK  ROODEPOORT",
    Latitude: "-26.1435",
    Longitude: "27.9182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8065",
    WardLookupId: "1232",
    VDNumber: "32850042",
    RegisteredPopulation: "2624",
    VotingStationName: "DUNKELD BOWLS CLUB",
    Address: "15 HUME ROAD  DUNKELD WEST  JOHANNESBURG",
    Latitude: "-26.134",
    Longitude: "28.0342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8066",
    WardLookupId: "1232",
    VDNumber: "32850547",
    RegisteredPopulation: "4263",
    VotingStationName: "HYDE PARK HIGH SCHOOL",
    Address: "107 3RD STREET  HYDE PARK  JOHANNESBURG",
    Latitude: "-26.1239",
    Longitude: "28.0408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8067",
    WardLookupId: "1232",
    VDNumber: "32850615",
    RegisteredPopulation: "4387",
    VotingStationName: "GEORGE LEA SPORTS CLUB",
    Address: "CNR HOLT & MARIE ST  PARKMORE  JOHANNESBURG",
    Latitude: "-26.0983",
    Longitude: "28.0356",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8068",
    WardLookupId: "1232",
    VDNumber: "32850637",
    RegisteredPopulation: "5037",
    VotingStationName: "CRAIGHALL PRIMARY SCHOOL",
    Address: "ROTHESAY AVENUE  CRAIGHALL PARK  JOHANNESBURG",
    Latitude: "-26.1228",
    Longitude: "28.0248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8069",
    WardLookupId: "1233",
    VDNumber: "32850558",
    RegisteredPopulation: "5549",
    VotingStationName: "ST DAVIDS MARIST INANDA",
    Address: "36 REVONIA ROAD  SANDTON  JOHANNESBURG",
    Latitude: "-26.1208",
    Longitude: "28.0482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8070",
    WardLookupId: "1233",
    VDNumber: "32850569",
    RegisteredPopulation: "3968",
    VotingStationName: "ALEXANDRA HIGH SCHOOL",
    Address: "26 2ND ST  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1041",
    Longitude: "28.0879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8071",
    WardLookupId: "1233",
    VDNumber: "32850581",
    RegisteredPopulation: "3477",
    VotingStationName: "SANDTON FIRE STATION",
    Address: "CNR GRAYSTON AND LINDEN ROAD  SANDOWN  SANDTON",
    Latitude: "-26.1027",
    Longitude: "28.0687",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8072",
    WardLookupId: "1233",
    VDNumber: "32851021",
    RegisteredPopulation: "616",
    VotingStationName: "SANDTONVIEW SCHOOL",
    Address: "MAREE STREET  BRAMLEY PARK  SANDTON",
    Latitude: "-26.1114",
    Longitude: "28.0772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8073",
    WardLookupId: "1233",
    VDNumber: "32851267",
    RegisteredPopulation: "2318",
    VotingStationName: "ALEX ADULT EDUCATION CENTRE",
    Address:
      "CNR SELBORN AND SECOND  CITY OF JOHANNESBURG [JOHANNESBURG]  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.1091",
    Longitude: "28.089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8074",
    WardLookupId: "1234",
    VDNumber: "32910016",
    RegisteredPopulation: "3334",
    VotingStationName: "RANDJESFONTEIN HOMESTEAD",
    Address: "KING WILLOW CRESCENT  RANDJESFONTEIN  MIDRAND",
    Latitude: "-25.9478",
    Longitude: "28.1464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8075",
    WardLookupId: "1234",
    VDNumber: "32910308",
    RegisteredPopulation: "7136",
    VotingStationName: "SEDI-LAKA PRIMARY SCHOOL",
    Address:
      "3857 PILCHARD ST. CNR ARCHER FISH  KAALFONTEIN  CITY OF JOHANNESBURG",
    Latitude: "-25.9902",
    Longitude: "28.1751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8076",
    WardLookupId: "1234",
    VDNumber: "32910342",
    RegisteredPopulation: "4981",
    VotingStationName: "DRAKE KOKA PRIMARY SCHOOL",
    Address: "EXT22. KAALFONTEIN  KAALFONTEIN  MIDRAND",
    Latitude: "-25.986",
    Longitude: "28.182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8077",
    WardLookupId: "1235",
    VDNumber: "32841772",
    RegisteredPopulation: "555",
    VotingStationName: "TENT - KINGFISCHER PARK",
    Address: "ALEXANDER  FOURWAYS  RANDBURG",
    Latitude: "-26.0295",
    Longitude: "28.0008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8078",
    WardLookupId: "1235",
    VDNumber: "32850738",
    RegisteredPopulation: "5108",
    VotingStationName: "ST. PETER`S COLLEGE",
    Address: "COLLEGE LANE CNR MAXWELL  SUNNINGHILL  RANDBURG",
    Latitude: "-26.0327",
    Longitude: "28.0857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8079",
    WardLookupId: "1235",
    VDNumber: "32850761",
    RegisteredPopulation: "3366",
    VotingStationName: "RIVONIA SPORTS CLUB",
    Address: "ACHTER  PAULSHOF  JOBURG",
    Latitude: "-26.0303",
    Longitude: "28.0581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8080",
    WardLookupId: "1235",
    VDNumber: "32850918",
    RegisteredPopulation: "6375",
    VotingStationName: "ST PETERS PREP BOYS COLLEGE",
    Address: "105 WITKOPPEN RD  SANDTON  PAULSHOF",
    Latitude: "-26.031",
    Longitude: "28.033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8081",
    WardLookupId: "1235",
    VDNumber: "32851289",
    RegisteredPopulation: "3791",
    VotingStationName: "TENT (THE SQUARE SHOPPING CENTRE)",
    Address: "NAVAISHA CNR LEEUWKOP STREET  SUNNINGHILL  JOHANNESBURG",
    Latitude: "-26.0316",
    Longitude: "28.0654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8082",
    WardLookupId: "1236",
    VDNumber: "32840018",
    RegisteredPopulation: "996",
    VotingStationName: "SUMMIT COLLEGE",
    Address: "PLOT 154  DIEPSLOOT  MIDRAND",
    Latitude: "-25.9705",
    Longitude: "28.0302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8083",
    WardLookupId: "1236",
    VDNumber: "32840580",
    RegisteredPopulation: "3654",
    VotingStationName: "WITKOPPEN COMBINED SCHOOL",
    Address: "110 WILLIAM NICOL DRIVE  WITKOPPEN  RANDBURG",
    Latitude: "-26.0112",
    Longitude: "28.0103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8084",
    WardLookupId: "1236",
    VDNumber: "32850536",
    RegisteredPopulation: "8386",
    VotingStationName: "CRAWFORD COLLEGE",
    Address: "17 LONEHILL BOULEVARD  LONEHILL  JOHANNESBURG",
    Latitude: "-26.0145",
    Longitude: "28.0299",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8085",
    WardLookupId: "1236",
    VDNumber: "32910061",
    RegisteredPopulation: "3133",
    VotingStationName: "BEAULIEU PRE-PARATORY SCHOOL",
    Address: "PERCHERON ROAD  BEAULIEU  MIDRAND",
    Latitude: "-25.98",
    Longitude: "28.0753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8086",
    WardLookupId: "1236",
    VDNumber: "32910083",
    RegisteredPopulation: "1229",
    VotingStationName: "CLUNNY FARM",
    Address: "67 ROSE ST  WITPOORT  MIDRAND",
    Latitude: "-25.9559",
    Longitude: "28.0656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8087",
    WardLookupId: "1237",
    VDNumber: "32841176",
    RegisteredPopulation: "5642",
    VotingStationName: "DIEPSLOOT YOUTH CENTRE",
    Address: "443 DIEPSLOOT  MIDRAND",
    Latitude: "-25.9196",
    Longitude: "28.0182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8088",
    WardLookupId: "1237",
    VDNumber: "32841187",
    RegisteredPopulation: "4967",
    VotingStationName: "MUZOMUHLE PRIMARY SCHOOL",
    Address: "1071 DIEPSLOOT EXT 2,  DIEPSLOOT  MIDRAND",
    Latitude: "-25.9233",
    Longitude: "28.0183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8089",
    WardLookupId: "1237",
    VDNumber: "32841389",
    RegisteredPopulation: "4890",
    VotingStationName: "NEIGHBOURHOOD CENTRE",
    Address: "571 DIEPSLOOT EXT 1  DIEPSLOOT  MIDRAND",
    Latitude: "-25.933",
    Longitude: "28.0132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8090",
    WardLookupId: "1237",
    VDNumber: "32841682",
    RegisteredPopulation: "2966",
    VotingStationName: "TENT AT TIN HALL",
    Address: "CNR MANALA & BHEKILANGA ST  DIEPSLOOT  CITY OF JOBURG",
    Latitude: "-25.928",
    Longitude: "28.0193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8091",
    WardLookupId: "1238",
    VDNumber: "32841255",
    RegisteredPopulation: "1423",
    VotingStationName: "TENT AFRICA ABLAZE MINISTRIES",
    Address:
      "3 RIETVALLEI  RIETVALLEI 538-JQ, MILLGATE  CITY OF JOHANNESBURG RURAL",
    Latitude: "-25.9907",
    Longitude: "27.9501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8092",
    WardLookupId: "1238",
    VDNumber: "32841288",
    RegisteredPopulation: "3031",
    VotingStationName: "PARADISE BEND SCHOOL",
    Address: "NORTHERN FARM  DIEPSLOOT 3  RANDBURG",
    Latitude: "-25.9363",
    Longitude: "27.9858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8093",
    WardLookupId: "1238",
    VDNumber: "32841323",
    RegisteredPopulation: "1206",
    VotingStationName: "KWENA MOLAPO SCHOOL",
    Address: "6 LANSERIA GATE, BUILTFONTEIN  BUILTFONTEIN  MIDRAND",
    Latitude: "-25.9664",
    Longitude: "27.9303",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8094",
    WardLookupId: "1238",
    VDNumber: "32841390",
    RegisteredPopulation: "2115",
    VotingStationName: "TENT RIVERSIDE VIEW",
    Address: "ROSE STREET   ZEVENFONTEIN 407-JR  RANDBURG",
    Latitude: "-25.963",
    Longitude: "28.0138",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8095",
    WardLookupId: "1238",
    VDNumber: "32841413",
    RegisteredPopulation: "3068",
    VotingStationName: "KYA SANDS (TENT)",
    Address: "KYA SANDS  RANDBURG",
    Latitude: "-26.0244",
    Longitude: "27.9499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8096",
    WardLookupId: "1238",
    VDNumber: "32841468",
    RegisteredPopulation: "2221",
    VotingStationName: "LION PARK PRIMARY SCHOOL",
    Address: "114 THABO MBEKI  LION PARK  RANDBURG",
    Latitude: "-25.9904",
    Longitude: "27.9267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8097",
    WardLookupId: "1238",
    VDNumber: "32841592",
    RegisteredPopulation: "1792",
    VotingStationName: "NGIZWENI INITIATIVE PROGRAM",
    Address: "213/400 M272 LANSERIA  NPO MALATJI  LANSERIA",
    Latitude: "-25.929",
    Longitude: "27.922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8098",
    WardLookupId: "1238",
    VDNumber: "32841604",
    RegisteredPopulation: "1057",
    VotingStationName: "(TENT) ZEWENFONTEIN",
    Address: "CEDAR ROAD  DAINFERN  CITY OF JOBURG",
    Latitude: "-25.9708",
    Longitude: "27.9848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8099",
    WardLookupId: "1238",
    VDNumber: "32841716",
    RegisteredPopulation: "6261",
    VotingStationName: "DAINFERN - COLLEGE",
    Address: "BROADACRES  DAINFERN  SANDTON",
    Latitude: "-25.9902",
    Longitude: "28.0154",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8100",
    WardLookupId: "1238",
    VDNumber: "32841783",
    RegisteredPopulation: "1101",
    VotingStationName: "TENT - MOSTTYN PARK EXT",
    Address: "CNR DAWN & BERTA   MOSTYN PARK  JOHANNESBURG",
    Latitude: "-26.0155",
    Longitude: "27.9431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8101",
    WardLookupId: "1239",
    VDNumber: "32870314",
    RegisteredPopulation: "3762",
    VotingStationName: "RUIMSIG SPORT COMPLEX / STADIUM",
    Address: "HANDICAP RD  RUIMSIG  ROODEPOORT",
    Latitude: "-26.0718",
    Longitude: "27.8659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8102",
    WardLookupId: "1239",
    VDNumber: "32870358",
    RegisteredPopulation: "7465",
    VotingStationName: "PANORAMA SPORT CLUB",
    Address: "PAUL KRUGER  & JOHN VOSTER STR  WILGEHEUWEL  ROODEPOORT",
    Latitude: "-26.1018",
    Longitude: "27.9161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8103",
    WardLookupId: "1239",
    VDNumber: "32871225",
    RegisteredPopulation: "7044",
    VotingStationName: "CHARTER HOUSE PRE-PREPARATORY SCHOOL",
    Address: "10 ERASMUS ROAD  RADIOKOP  ROODEPOORT",
    Latitude: "-26.1106",
    Longitude: "27.9176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8104",
    WardLookupId: "1239",
    VDNumber: "32871371",
    RegisteredPopulation: "3031",
    VotingStationName: "TENT AT LITTLE FALLS NATURE RESERVE",
    Address: "FALLS  LITTLE FALLS  ROODEPOORT",
    Latitude: "-26.1139",
    Longitude: "27.8803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8105",
    WardLookupId: "1239",
    VDNumber: "32871494",
    RegisteredPopulation: "548",
    VotingStationName: "STEVE JOBS SCHOOL",
    Address: "81 PIERRE ROAD  RUIMSIG  ROODEPOORT",
    Latitude: "-26.0705",
    Longitude: "27.8993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8106",
    WardLookupId: "1240",
    VDNumber: "32840096",
    RegisteredPopulation: "5715",
    VotingStationName: "NORTHCLIFF PRIMARY SCHOOL",
    Address: "176 WELTEVREDEN RD  NORTHCLIFF  JOHANNESBURG",
    Latitude: "-26.1377",
    Longitude: "27.9602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8107",
    WardLookupId: "1240",
    VDNumber: "32840366",
    RegisteredPopulation: "3073",
    VotingStationName: "WINDSOR EAST RECREATION CENTRE",
    Address: "VICOUNT & GEORGE  WINDSOR EAST  JOHANNESBURG",
    Latitude: "-26.1232",
    Longitude: "27.9791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8108",
    WardLookupId: "1240",
    VDNumber: "32840388",
    RegisteredPopulation: "3554",
    VotingStationName: "ROBIN HILLS PRIMARY SCHOOL",
    Address: "KALK AVE  RANDPARK EXT 2  RANDBURG",
    Latitude: "-26.1131",
    Longitude: "27.9746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8109",
    WardLookupId: "1240",
    VDNumber: "32840399",
    RegisteredPopulation: "3299",
    VotingStationName: "THREE IN ONE SPIRITUAL CENTRE",
    Address: "8 VISCOUNTS AVENUE  WINDSOR WEST  RANDBURG",
    Latitude: "-26.1248",
    Longitude: "27.9663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8110",
    WardLookupId: "1240",
    VDNumber: "32840524",
    RegisteredPopulation: "2167",
    VotingStationName: "AVENIR PRIVATE SCHOOL",
    Address: "72 MIMOSA ROAD  RANDPARK RIDGE EXT41  JOHANNESBURG REG4",
    Latitude: "-26.1075",
    Longitude: "27.9496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8111",
    WardLookupId: "1241",
    VDNumber: "32840131",
    RegisteredPopulation: "3198",
    VotingStationName: "LAERSKOOL LOUW GELDENHUYS",
    Address: "H/V DERDE LAAN & SEWENDE STRAAT  LINDEN  JOHANNESBURG",
    Latitude: "-26.1384",
    Longitude: "27.9951",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8112",
    WardLookupId: "1241",
    VDNumber: "32840142",
    RegisteredPopulation: "3596",
    VotingStationName: "LINDEN HOÃ‹RSKOOL",
    Address: "CNR FIFTH STREET & 3RD AVE  LINDEN  RANDBURG",
    Latitude: "-26.1348",
    Longitude: "27.9939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8113",
    WardLookupId: "1241",
    VDNumber: "32840355",
    RegisteredPopulation: "4011",
    VotingStationName: "LAERSKOOL UNIKA",
    Address: "CNR OAK & ABBOTWEG  ROBINDALE  RANDBURG",
    Latitude: "-26.1137",
    Longitude: "27.9975",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8114",
    WardLookupId: "1241",
    VDNumber: "32840377",
    RegisteredPopulation: "3360",
    VotingStationName: "IR GRIFFITH PRIMARY SCHOOL",
    Address: "EQUITY DRIVE  BLAIRGOWRIE  RANDBURG",
    Latitude: "-26.1096",
    Longitude: "28.0049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8115",
    WardLookupId: "1241",
    VDNumber: "32841693",
    RegisteredPopulation: "1471",
    VotingStationName: "FONTAINEBLEAU PRE - PRIMARY SCHOOL",
    Address: "11 BELLAIRS ST  ROBINDALE  RANDBURG",
    Latitude: "-26.1173",
    Longitude: "27.9883",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8116",
    WardLookupId: "1242",
    VDNumber: "32841200",
    RegisteredPopulation: "3690",
    VotingStationName: "COSMO CITY MULTIPUREPOSE CENTRE",
    Address: "CNR SOUTH AFRICA AND ANGOLA ST  COSMO CITY  NORTHRIDING",
    Latitude: "-26.0202",
    Longitude: "27.9298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8117",
    WardLookupId: "1242",
    VDNumber: "32841334",
    RegisteredPopulation: "4511",
    VotingStationName: "SGODIPHOLA SECONDARY SCHOOL",
    Address:
      "7356 YUSANDA CRESCENT & CENTRAL AFRICAN REPUBLIC STREET  RANDBURG  NORTHRIDING",
    Latitude: "-26.0274",
    Longitude: "27.9306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8118",
    WardLookupId: "1242",
    VDNumber: "32841424",
    RegisteredPopulation: "2513",
    VotingStationName: "ITSOSENG DEVELOPMENT TRUST INFORMAL SETTLEMENT",
    Address: "",
    Latitude: "-26.0048",
    Longitude: "27.9216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8119",
    WardLookupId: "1242",
    VDNumber: "32841525",
    RegisteredPopulation: "1728",
    VotingStationName: "COSMO CITY CHRISTIAN CENTRE",
    Address: "814 GEORGIA CRESCENT  COSMO CITY  RANDBURG",
    Latitude: "-26.0198",
    Longitude: "27.9348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8120",
    WardLookupId: "1242",
    VDNumber: "32841536",
    RegisteredPopulation: "1528",
    VotingStationName: "GRACE FAMILY CHURCH",
    Address: "CNR ANTIGUA AND BELIZE  COSMO CITY EXT 5  RANDBURG",
    Latitude: "-26.027",
    Longitude: "27.9365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8121",
    WardLookupId: "1242",
    VDNumber: "32841558",
    RegisteredPopulation: "2448",
    VotingStationName: "TIRISANO MMOGO PRIMARY  SCHOOL",
    Address: "3759 CNR.BRAZIL & EQUADOR STREET  COSMO CITY  NORTHRIDING",
    Latitude: "-26.0292",
    Longitude: "27.9216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8122",
    WardLookupId: "1242",
    VDNumber: "32841569",
    RegisteredPopulation: "1868",
    VotingStationName: "COSMO CITY EXT 3 CHURCH",
    Address: "3358 SLOVANIA STREET  COSMO CITY EXT 3 33  RANDBURG",
    Latitude: "-26.049",
    Longitude: "27.9249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8123",
    WardLookupId: "1242",
    VDNumber: "32841749",
    RegisteredPopulation: "1894",
    VotingStationName: "FAR NORTH SECONDARY SCHOOL",
    Address: "8150 CNR UKRAINE AND SOUTH AFRICA  COSMO CITY  HONEYDEW",
    Latitude: "-26.0413",
    Longitude: "27.9149",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8124",
    WardLookupId: "1242",
    VDNumber: "32841750",
    RegisteredPopulation: "1705",
    VotingStationName: "COSMO CITY FIRE STATION",
    Address: "SOUTH AFRICA ROAD  COSMO CITY EXT 8  COSMO CITY",
    Latitude: "-26.037",
    Longitude: "27.9211",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8125",
    WardLookupId: "1243",
    VDNumber: "32840535",
    RegisteredPopulation: "6420",
    VotingStationName: "RANDPARK RIDGE UNITED CHURCH",
    Address: "46 TIN ROAD  BROMHOF  RANDBURG",
    Latitude: "-26.0841",
    Longitude: "27.9632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8126",
    WardLookupId: "1243",
    VDNumber: "32840546",
    RegisteredPopulation: "3661",
    VotingStationName: "NORTHERN MLC CLUB",
    Address: "HOLDING 6, HUNTERS RD  GOLDEN HARVEST  RANDBURG",
    Latitude: "-26.0679",
    Longitude: "27.9533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8127",
    WardLookupId: "1243",
    VDNumber: "32840568",
    RegisteredPopulation: "6029",
    VotingStationName: "SHARONLEA PRIMARY SCHOOL",
    Address: "CNR MAHOGANY & CAMPHER STR  SHARONLEA  RANDBURG",
    Latitude: "-26.0615",
    Longitude: "27.9614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8128",
    WardLookupId: "1243",
    VDNumber: "32841570",
    RegisteredPopulation: "5704",
    VotingStationName: "NORTH RIDING SECONDARY SCHOOL",
    Address: "CNR BELLAIR AND HYPERION DRIVE  NORTH RIDING  RANDBURG",
    Latitude: "-26.0442",
    Longitude: "27.9694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8129",
    WardLookupId: "1244",
    VDNumber: "32840423",
    RegisteredPopulation: "3079",
    VotingStationName: "LAERSKOOL FONTAINEBLEAU",
    Address: "H/V RABIE & CHARLIE STR  FONTAINEBLEAU  RANDBURG",
    Latitude: "-26.1062",
    Longitude: "27.984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8130",
    WardLookupId: "1244",
    VDNumber: "32840456",
    RegisteredPopulation: "4746",
    VotingStationName: "BORDEAUX PRIMARY SCHOOL",
    Address: "CNR MAIN & DARNOC ST  BORDEAUX  RANDBURG",
    Latitude: "-26.0938",
    Longitude: "28.0115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8131",
    WardLookupId: "1244",
    VDNumber: "32840467",
    RegisteredPopulation: "3801",
    VotingStationName: "BLAIRGOWRIE COMMUNITY CENTRE",
    Address: "PARKLANE AVE  BLAIRGOWRIE  JHB",
    Latitude: "-26.1155",
    Longitude: "28.0132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8132",
    WardLookupId: "1244",
    VDNumber: "32840478",
    RegisteredPopulation: "2520",
    VotingStationName: "ST STITHIANS GIRLS COLLEGE",
    Address: "CRN BRAM FISHER & MILNER ROAD  RANDBURG  JOHNNESBURG",
    Latitude: "-26.0829",
    Longitude: "28.0144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8133",
    WardLookupId: "1244",
    VDNumber: "32850783",
    RegisteredPopulation: "4528",
    VotingStationName: "BRYANSTON HIGH SCHOOL",
    Address: "TRAMORE ROAD  BRYANSTON  SANDOTN",
    Latitude: "-26.0732",
    Longitude: "28.0257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8134",
    WardLookupId: "1245",
    VDNumber: "32850604",
    RegisteredPopulation: "1748",
    VotingStationName: "SANDTON ART GALLERY",
    Address: "CNR STELLA AND WEST STR  SANDOWN  JOHHANESBURG",
    Latitude: "-26.1081",
    Longitude: "28.0551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8135",
    WardLookupId: "1245",
    VDNumber: "32850626",
    RegisteredPopulation: "3054",
    VotingStationName: "FIELD AND STUDY CENTRE",
    Address: "LOUISE AVENUE  PARKMORE  JOHANNESBURG",
    Latitude: "-26.0841",
    Longitude: "28.0298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8136",
    WardLookupId: "1245",
    VDNumber: "32850648",
    RegisteredPopulation: "3987",
    VotingStationName: "LYCEE FRANCAIS JULES VERNE",
    Address: "CNR BAUHINIA & CESTRUM RD  MORNINGSIDE  JOHANNESBURG",
    Latitude: "-26.0792",
    Longitude: "28.0472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8137",
    WardLookupId: "1245",
    VDNumber: "32850659",
    RegisteredPopulation: "5535",
    VotingStationName: "BET DAVID",
    Address: "3 MIDDLE STREET AND SOUTH ROAD  MORNINGSIDE  SANDTON",
    Latitude: "-26.0897",
    Longitude: "28.0641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8138",
    WardLookupId: "1245",
    VDNumber: "32850750",
    RegisteredPopulation: "5317",
    VotingStationName: "BRYNEVEN PRIMARY SCHOOL",
    Address: "CNR BALLYCLARE & BALLINA  BRYANSTON  SANDTON",
    Latitude: "-26.0735",
    Longitude: "28.0334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8139",
    WardLookupId: "1246",
    VDNumber: "32840412",
    RegisteredPopulation: "4105",
    VotingStationName: "NG KERK FONTAINEBLEAU",
    Address: "4TH AVENUE AND RABIE STREET  FONTAINEBLEAU  RANDBURG",
    Latitude: "-26.1041",
    Longitude: "27.9784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8140",
    WardLookupId: "1246",
    VDNumber: "32840434",
    RegisteredPopulation: "3336",
    VotingStationName: "CITY POWER RANDBURG",
    Address: "CNR HANS STRIJDOM & HANS SCHOEMAN AVE  RANDBURG  RANDBURG",
    Latitude: "-26.093",
    Longitude: "27.9829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8141",
    WardLookupId: "1246",
    VDNumber: "32840445",
    RegisteredPopulation: "2713",
    VotingStationName: "FERNDALE HIGH SCHOOL",
    Address: "53 MILNER RD  KENSINGTON B  RANDBURG",
    Latitude: "-26.0816",
    Longitude: "28.0021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8142",
    WardLookupId: "1246",
    VDNumber: "32840489",
    RegisteredPopulation: "2877",
    VotingStationName: "OPEN PARK (TENT)",
    Address:
      "CNR WESTVIEW DR. & ROUX AVE.  BRYANSTO MANOR EXT. 5  JOHANNESBURG",
    Latitude: "-26.073",
    Longitude: "27.994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8143",
    WardLookupId: "1246",
    VDNumber: "32850840",
    RegisteredPopulation: "4560",
    VotingStationName: "BRYANDALE PRIMARY SCHOOL",
    Address: "CNR CUMBERLAND AVENUE & STERLING ROAD  BRYANSTON  JOHANNESBURG",
    Latitude: "-26.0503",
    Longitude: "28.0061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8144",
    WardLookupId: "1247",
    VDNumber: "32850334",
    RegisteredPopulation: "2225",
    VotingStationName: "ATREC SPORTS COMPLEX",
    Address: "410 EASTBANK AVE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.0998",
    Longitude: "28.1095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8145",
    WardLookupId: "1247",
    VDNumber: "32850884",
    RegisteredPopulation: "1923",
    VotingStationName: "EAST BANK CLINIC",
    Address: "CNR SPRINGBOK CRES & IMPALA RD  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1069",
    Longitude: "28.1109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8146",
    WardLookupId: "1247",
    VDNumber: "32851032",
    RegisteredPopulation: "4907",
    VotingStationName: "SKEEN PRIMARY SCHOOL",
    Address:
      "CNR SOUTH AFRICA & CORT STREET  TSUTSUMANI VILL., ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.0998",
    Longitude: "28.1185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8147",
    WardLookupId: "1247",
    VDNumber: "32851133",
    RegisteredPopulation: "2116",
    VotingStationName: "THANDEKA PRE-SCHOOL",
    Address: "91 18TH AVENUE  ALEXANDRA  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.1055",
    Longitude: "28.1053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8148",
    WardLookupId: "1247",
    VDNumber: "32851201",
    RegisteredPopulation: "3780",
    VotingStationName: "BHEKILANGA HIGH SCHOOL",
    Address:
      "336 HOFMEYER STREET EASTBANK  ALEXANDRA  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.0987",
    Longitude: "28.1129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8149",
    WardLookupId: "1247",
    VDNumber: "32851278",
    RegisteredPopulation: "3736",
    VotingStationName: "TENT (TSUTSUMANE, EXT 7 )",
    Address: "CNR LADUMA AND FOOTBALL STREET  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.0846",
    Longitude: "28.1098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8150",
    WardLookupId: "1248",
    VDNumber: "32840490",
    RegisteredPopulation: "7168",
    VotingStationName: "NORSCOT MANOR RECREATION CENTRE",
    Address: "PENQUIN DRIVE  FOURWAYS  JHB",
    Latitude: "-26.0356",
    Longitude: "28.0074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8151",
    WardLookupId: "1248",
    VDNumber: "32850682",
    RegisteredPopulation: "2389",
    VotingStationName: "CEDARWOODS OF SANDTON",
    Address: "120 WESTERN SERVICE ROAD  THE WOODLANDS  JOHANNESBURG",
    Latitude: "-26.0677",
    Longitude: "28.0892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8152",
    WardLookupId: "1248",
    VDNumber: "32850749",
    RegisteredPopulation: "6100",
    VotingStationName: "RIVONIA VILLAGE HALL",
    Address: "CNR RIVONIA & 10TH AVENUE  RIVONIA  JOHANNESBURG",
    Latitude: "-26.054",
    Longitude: "28.0597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8153",
    WardLookupId: "1248",
    VDNumber: "32850772",
    RegisteredPopulation: "4292",
    VotingStationName: "BRYANSTON PARALLEL MEDIUM SCHOOL",
    Address: "CNR SLOAN & MAIN STREET  BRYANSTON  JOHANNESBURG",
    Latitude: "-26.0407",
    Longitude: "28.0262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8154",
    WardLookupId: "1249",
    VDNumber: "32850794",
    RegisteredPopulation: "3713",
    VotingStationName: "CARTER PRIMARY SCHOOL",
    Address: "40 4TH AVENUE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1048",
    Longitude: "28.0903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8155",
    WardLookupId: "1249",
    VDNumber: "32850806",
    RegisteredPopulation: "2685",
    VotingStationName: "ST MICHAELS CHURCH",
    Address: "45 8TH AVENUE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1045",
    Longitude: "28.0937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8156",
    WardLookupId: "1249",
    VDNumber: "32850828",
    RegisteredPopulation: "3502",
    VotingStationName: "REALOGILE HIGH SCHOOL",
    Address: "CNR SELBORNE & 15TH AVE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1048",
    Longitude: "28.1009",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8157",
    WardLookupId: "1249",
    VDNumber: "32850862",
    RegisteredPopulation: "4134",
    VotingStationName: "DR KNAK COMMUNITY SCHOOL",
    Address: "64 CNR SELBOURNE & 10TH AVENUE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1066",
    Longitude: "28.0973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8158",
    WardLookupId: "1249",
    VDNumber: "32851166",
    RegisteredPopulation: "1077",
    VotingStationName: "7TH AVENUE TENT",
    Address: "7TH AVENUE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1058",
    Longitude: "28.0934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8159",
    WardLookupId: "1249",
    VDNumber: "32851199",
    RegisteredPopulation: "1363",
    VotingStationName: "7TH DAY ADVENTIST CHURCH",
    Address: "65 6TH AVENUE  ALEXANDRA  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.1073",
    Longitude: "28.0927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8160",
    WardLookupId: "1250",
    VDNumber: "32850693",
    RegisteredPopulation: "1489",
    VotingStationName: "METHODIST CHURCH OF SOUTH AFRICA",
    Address: "12 2ND AVE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1019",
    Longitude: "28.0871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8161",
    WardLookupId: "1250",
    VDNumber: "32850817",
    RegisteredPopulation: "3439",
    VotingStationName: "AME CHURCH",
    Address: "46 10TH AVENUE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1037",
    Longitude: "28.096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8162",
    WardLookupId: "1250",
    VDNumber: "32851065",
    RegisteredPopulation: "4743",
    VotingStationName: "TENT (VASCO AND 9TH AVENUE)",
    Address: "1 9 TH AVENUE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.099",
    Longitude: "28.0931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8163",
    WardLookupId: "1250",
    VDNumber: "32851076",
    RegisteredPopulation: "4192",
    VotingStationName: "TENT (JOE NHLANHLA AND 5TH AVENUE)",
    Address: "26 5 TH AVENUE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1028",
    Longitude: "28.0905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8164",
    WardLookupId: "1250",
    VDNumber: "32851346",
    RegisteredPopulation: "976",
    VotingStationName: "12TH AVENUE CHURCH",
    Address: "27 12TH AVENUE   ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1013",
    Longitude: "28.0969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8165",
    WardLookupId: "1251",
    VDNumber: "32850424",
    RegisteredPopulation: "4005",
    VotingStationName: "HLAYISANANI DAY CARE CENTRE",
    Address: "376 CEMETORY CENTRE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.0938",
    Longitude: "28.1049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8166",
    WardLookupId: "1251",
    VDNumber: "32850592",
    RegisteredPopulation: "2911",
    VotingStationName: "ERNEST ULLMAN RECREATION CENTRE",
    Address: "1 MINTO-ALMO ROAD  MORNINGSIDE  JOHANNESBURG",
    Latitude: "-26.08",
    Longitude: "28.0738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8167",
    WardLookupId: "1251",
    VDNumber: "32850671",
    RegisteredPopulation: "4302",
    VotingStationName: "WENDYWOOD HIGH SCHOOL",
    Address: "43 LOTUS AVENUE  WENDYWOOD  JOHANNESBURG",
    Latitude: "-26.0691",
    Longitude: "28.0842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8168",
    WardLookupId: "1251",
    VDNumber: "32850705",
    RegisteredPopulation: "2979",
    VotingStationName: "MARLBORO GARDENS COMBINED SCHOOL",
    Address: "622 ZINNIA DRIVE  MARLBORO  JOHANNESBURG",
    Latitude: "-26.0935",
    Longitude: "28.0971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8169",
    WardLookupId: "1251",
    VDNumber: "32850716",
    RegisteredPopulation: "4316",
    VotingStationName: "NOKUTHULA CENTRE",
    Address: "82 3RD STREET  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.0962",
    Longitude: "28.0986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8170",
    WardLookupId: "1251",
    VDNumber: "32851425",
    RegisteredPopulation: "749",
    VotingStationName: "ST JOHANNES LUTHERAN CHURCH",
    Address: "6-8 FAIR WAY STREET  KELVIN  JOHANNESBURG",
    Latitude: "-26.076",
    Longitude: "28.0917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8171",
    WardLookupId: "1252",
    VDNumber: "32900093",
    RegisteredPopulation: "5059",
    VotingStationName: "MAYBUYE PRIMARY SCHOOL",
    Address: "MAYIBUYE  CITY OF JOHANNESBURG",
    Latitude: "-26.0334",
    Longitude: "28.1717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8172",
    WardLookupId: "1252",
    VDNumber: "32900273",
    RegisteredPopulation: "3567",
    VotingStationName: "MAPHUTHA SECONDARY SCHOOL",
    Address:
      "CONER TERESSA AND VIRGINIA MOL  MAYIBUYE  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.0338",
    Longitude: "28.1636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8173",
    WardLookupId: "1252",
    VDNumber: "32910038",
    RegisteredPopulation: "3243",
    VotingStationName: "GALLAGHER COMBINED SCHOOL",
    Address: "537 LUPTON DRIVE  MIDRAND  MIDRAND",
    Latitude: "-25.9979",
    Longitude: "28.1309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8174",
    WardLookupId: "1252",
    VDNumber: "32910162",
    RegisteredPopulation: "4326",
    VotingStationName: "RABIE RIDGE OLD APOSTOLIC CHURCH",
    Address: "FALCON  RABIE RIDGE  MIDRAND",
    Latitude: "-26.0181",
    Longitude: "28.1746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8175",
    WardLookupId: "1253",
    VDNumber: "32910049",
    RegisteredPopulation: "8361",
    VotingStationName: "EBONY PARK PRIMARY SCHOOL",
    Address: "477 BOUGAINVILLE RD  EBONY PARK  MIDRAND",
    Latitude: "-26.0008",
    Longitude: "28.1752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8176",
    WardLookupId: "1253",
    VDNumber: "32910106",
    RegisteredPopulation: "3952",
    VotingStationName: "UMQHELE SECONDARY SCHOOL",
    Address: "12017 21ST AUGUST DRIVE  IVORY PARK EXT 10  MIDRAND",
    Latitude: "-25.994",
    Longitude: "28.1985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8177",
    WardLookupId: "1253",
    VDNumber: "32910320",
    RegisteredPopulation: "4258",
    VotingStationName: "PONELOPELE SECONDARY SCHOOL",
    Address:
      "ARCHERFISH STREET  KAALFONTEIN]  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-25.9945",
    Longitude: "28.179",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8178",
    WardLookupId: "1254",
    VDNumber: "32910140",
    RegisteredPopulation: "4795",
    VotingStationName: "MIDRAND PRIMARY SCHOOL",
    Address: "135 POPLAR STREET  NOORDWYK  MIDRAND",
    Latitude: "-25.9556",
    Longitude: "28.1163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8179",
    WardLookupId: "1254",
    VDNumber: "32910364",
    RegisteredPopulation: "4403",
    VotingStationName: "MIDRAND HIGH SCHOOL",
    Address: "CNR FIRST &THIRD AVENUE  JOHANNESBURG  JOHANNESBURG",
    Latitude: "-25.9889",
    Longitude: "28.1168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8180",
    WardLookupId: "1254",
    VDNumber: "32910397",
    RegisteredPopulation: "1879",
    VotingStationName: "TENT COUNTRYVIEW ENGEN GARAGE",
    Address: "LEVER ROAD CNR AZALEA  COUNTRYVIEW  MIDRAND",
    Latitude: "-25.9475",
    Longitude: "28.1251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8181",
    WardLookupId: "1254",
    VDNumber: "32910487",
    RegisteredPopulation: "2653",
    VotingStationName: "OPTI BABY CRECHE",
    Address: "TAMBOTI  SUMMERSET  MIDRAND",
    Latitude: "-25.9657",
    Longitude: "28.1015",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8182",
    WardLookupId: "1254",
    VDNumber: "32910498",
    RegisteredPopulation: "4293",
    VotingStationName: "BROOKFIELD ACADEMY",
    Address: "CNR FREDERICK DRIVE & 14TH STREET  NOORDWYK  MIDRAND",
    Latitude: "-25.9575",
    Longitude: "28.1299",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8183",
    WardLookupId: "1254",
    VDNumber: "32910511",
    RegisteredPopulation: "1540",
    VotingStationName: "NOORDWYK SECONDARY SCHOOL",
    Address: "CRN GARDEN & MIMOSA  NOORDWYK   JOHANNEBSURG",
    Latitude: "-25.9536",
    Longitude: "28.1018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8184",
    WardLookupId: "1255",
    VDNumber: "32841198",
    RegisteredPopulation: "8520",
    VotingStationName: "DIEPSLOOT COMBINED SCHOOL",
    Address: "3292 UBUNTU CNR LAPENG  DIEPSLOOT  MIDRAND",
    Latitude: "-25.9403",
    Longitude: "28.0129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8185",
    WardLookupId: "1255",
    VDNumber: "32841402",
    RegisteredPopulation: "8235",
    VotingStationName: "NEIGHBOURHOOD COMMUNITY HALL",
    Address: "226 DIEPSLOOT EXT 8  DIEPSLOOT  CITY OF JOHANNESBURG",
    Latitude: "-25.9312",
    Longitude: "28.0048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8186",
    WardLookupId: "1255",
    VDNumber: "32841457",
    RegisteredPopulation: "2773",
    VotingStationName: "DIEPSLOOT OLD APOSTOLIC CHURCH",
    Address: "MAIN  DIEPSLOOT 3  RANDBURG",
    Latitude: "-25.9356",
    Longitude: "28.0198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8187",
    WardLookupId: "1255",
    VDNumber: "32841839",
    RegisteredPopulation: "697",
    VotingStationName: "HOUSE OF CHILDREN DAY CARE",
    Address: "1738 NTSHABELENG STREET  DIEPSLOOT WEST  RANDBURG",
    Latitude: "-25.9263",
    Longitude: "28.0083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8188",
    WardLookupId: "1256",
    VDNumber: "32840614",
    RegisteredPopulation: "5433",
    VotingStationName: "MASAKHANE TSWELELOPELE PRIMARY SCHOOL",
    Address: "PLOT 74 MARINA DRIVE  ZANDSPRUIT  HONEYDWE",
    Latitude: "-26.0588",
    Longitude: "27.9041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8189",
    WardLookupId: "1256",
    VDNumber: "32841277",
    RegisteredPopulation: "3158",
    VotingStationName: "ZANDSPRUIT COMMUNITY CHURCH",
    Address: "N/A  HONEYDEW  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.0535",
    Longitude: "27.9055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8190",
    WardLookupId: "1256",
    VDNumber: "32841491",
    RegisteredPopulation: "2004",
    VotingStationName: "TENT (BOUNDARY ROAD PARK)",
    Address: "140/191 BOUNDARY ROAD  HONEYDEW  ROODEPOORT",
    Latitude: "-26.0651",
    Longitude: "27.9248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8191",
    WardLookupId: "1256",
    VDNumber: "32841503",
    RegisteredPopulation: "3219",
    VotingStationName: "HAPPY KIDZ PLAY SCHOOL",
    Address: "PLOT 58  EXT 15 BAYERS NAUDE DRIVE  ZANSPRUIT  HONEYDEW",
    Latitude: "-26.0637",
    Longitude: "27.9079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8192",
    WardLookupId: "1256",
    VDNumber: "32841794",
    RegisteredPopulation: "1165",
    VotingStationName: "ZANDSPRUIT PRIMARY SCHOOL",
    Address: "PLOT 52 EXT 10  ZANDSPRUIT  HONEYDEW",
    Latitude: "-26.052",
    Longitude: "27.91",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8193",
    WardLookupId: "1257",
    VDNumber: "32840579",
    RegisteredPopulation: "5827",
    VotingStationName: "MAGIC BEINGS NURSERY SCHOOL",
    Address: "247 CHURCH STREET  JOHANNESBURG NORTH  RANDBURG",
    Latitude: "-26.0349",
    Longitude: "27.9752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8194",
    WardLookupId: "1257",
    VDNumber: "32840591",
    RegisteredPopulation: "3360",
    VotingStationName: "TENT AT NAUTILUS PARK",
    Address: "CNR NAUTILUS STREET AND ROOIPEER   RANDBURG  JOHANNESBURG",
    Latitude: "-26.0261",
    Longitude: "27.9689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8195",
    WardLookupId: "1257",
    VDNumber: "32840603",
    RegisteredPopulation: "4343",
    VotingStationName: "FOURWAYS HIGH SCHOOL",
    Address: "KINGFISHER DRIVE  FOURWAYS  SANDTON RANDBURG",
    Latitude: "-26.027",
    Longitude: "28.0056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8196",
    WardLookupId: "1257",
    VDNumber: "32841581",
    RegisteredPopulation: "6587",
    VotingStationName: "CRAWFORD PRE-PREPARATORY SCHOOL",
    Address: "CAMPBELL STREET  MIDRAND / RANDBURG  FOURWAYS",
    Latitude: "-26.0175",
    Longitude: "27.9946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8197",
    WardLookupId: "1257",
    VDNumber: "32841817",
    RegisteredPopulation: "886",
    VotingStationName: "TREE LIFE COMMUNITY PROJECTS",
    Address: "52 AGNES AVENUE  KYA SAND  RANDBURG",
    Latitude: "-26.0221",
    Longitude: "27.9655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8198",
    WardLookupId: "1258",
    VDNumber: "32850839",
    RegisteredPopulation: "2861",
    VotingStationName: "ZION APOSTOLIC CHURCH",
    Address: "39 17TH AVENUE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.101",
    Longitude: "28.1024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8199",
    WardLookupId: "1258",
    VDNumber: "32850873",
    RegisteredPopulation: "3643",
    VotingStationName: "UNITED CHURCH OF CHRIST",
    Address: "36 13TH AVENUE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1018",
    Longitude: "28.0986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8200",
    WardLookupId: "1258",
    VDNumber: "32850895",
    RegisteredPopulation: "4563",
    VotingStationName: "SWISS MISSION/EVANGELICAL PRESBYTERIAN CHURCH",
    Address: "53 16TH AVENUE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.1031",
    Longitude: "28.1018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8201",
    WardLookupId: "1258",
    VDNumber: "32851054",
    RegisteredPopulation: "2048",
    VotingStationName: "FAITH DAYCARE",
    Address: "15 14 TH AVENUE  ALEXANDRA  JOHANNESBURG",
    Latitude: "-26.0991",
    Longitude: "28.0983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8202",
    WardLookupId: "1258",
    VDNumber: "32851144",
    RegisteredPopulation: "1362",
    VotingStationName: "BANA BA KANANA APOSTOLIC CHURCH",
    Address: "35 15TH AVENUE  ALEXANDRA  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.1007",
    Longitude: "28.1003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8203",
    WardLookupId: "1259",
    VDNumber: "32840153",
    RegisteredPopulation: "3129",
    VotingStationName: "BLAIRGOWRIE PRIMARY SCHOOL",
    Address: "GAVIN AVENUE  BLAIRGOWRIE  RANDBURG JOHANNESBURG",
    Latitude: "-26.1256",
    Longitude: "28.0019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8204",
    WardLookupId: "1259",
    VDNumber: "32850019",
    RegisteredPopulation: "4662",
    VotingStationName: "PARKHURST PRIMARY SCHOOL",
    Address: "102 15TH STREET  PARKHURST  JOHANNESBURG",
    Latitude: "-26.1377",
    Longitude: "28.0206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8205",
    WardLookupId: "1259",
    VDNumber: "32850020",
    RegisteredPopulation: "4012",
    VotingStationName: "SAXONWOLD PRIMARY SCHOOL",
    Address: "ALDSWOLD ROAD  SAXONWOLD  JOHANNESBURG",
    Latitude: "-26.162",
    Longitude: "28.0455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8206",
    WardLookupId: "1259",
    VDNumber: "32851368",
    RegisteredPopulation: "2631",
    VotingStationName: "ROSEBANK PRIMARY SCHOOL",
    Address: "JELLICOE  ROSEBANK  SANDTON",
    Latitude: "-26.1425",
    Longitude: "28.0392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8207",
    WardLookupId: "1260",
    VDNumber: "32850288",
    RegisteredPopulation: "4220",
    VotingStationName: "ATHLONE GIRLS HIGH SCHOOL",
    Address: "FRANCISKA STREET  OBSERVATORY  JOHANNESBURG",
    Latitude: "-26.1704",
    Longitude: "28.0909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8208",
    WardLookupId: "1260",
    VDNumber: "32850378",
    RegisteredPopulation: "2553",
    VotingStationName: "EASTGATE PRIMARY SCHOOL",
    Address: "15 YORK RD  KENSINGTON  JOHANNESBURG",
    Latitude: "-26.1866",
    Longitude: "28.1052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8209",
    WardLookupId: "1260",
    VDNumber: "32850389",
    RegisteredPopulation: "2126",
    VotingStationName: "KENSINGTON RIDGE PRIMARY SCHOOL",
    Address: "75 OXFORD RD  KENSINGTON  JOHANNESBURG",
    Latitude: "-26.195",
    Longitude: "28.1147",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8210",
    WardLookupId: "1260",
    VDNumber: "32851256",
    RegisteredPopulation: "4035",
    VotingStationName: "TENT  MALVERN PANDORA STREET",
    Address: "CNR HEREFORD AND PANDORA   MALVERN  JOHANNESBURG",
    Latitude: "-26.1981",
    Longitude: "28.1165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8211",
    WardLookupId: "1260",
    VDNumber: "32862742",
    RegisteredPopulation: "5985",
    VotingStationName: "SOCIAL SERVICES  CONTAINER",
    Address: "PLANTASIE STREET  DENVER  JOHANNESBURG",
    Latitude: "-26.2063",
    Longitude: "28.1055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8212",
    WardLookupId: "1261",
    VDNumber: "32860133",
    RegisteredPopulation: "3247",
    VotingStationName: "NATURENA PRIMARY SCHOOL",
    Address: "C/O NATUUR ROAD/MALTA  NATURENA  JOHANNESBURG",
    Latitude: "-26.2941",
    Longitude: "27.9565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8213",
    WardLookupId: "1261",
    VDNumber: "32860403",
    RegisteredPopulation: "835",
    VotingStationName: "TENT (OPP HOUSE NO 217 STERRE ROAD)",
    Address: "STERRE ROAD   BUSHKOPPIES  JOHANNESBURG",
    Latitude: "-26.3088",
    Longitude: "27.9143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8214",
    WardLookupId: "1261",
    VDNumber: "32862607",
    RegisteredPopulation: "4715",
    VotingStationName: "FREEDOM  PRIMARY SCHOOL",
    Address: "261 JAN DE NECKER STREET  FREEDOM PARK  CITY OF JOHANNESBURG",
    Latitude: "-26.293",
    Longitude: "27.9324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8215",
    WardLookupId: "1261",
    VDNumber: "32862630",
    RegisteredPopulation: "3087",
    VotingStationName: "BANNER OF TRUTH MINISTRIES",
    Address:
      "1123/1 JAN DE NECKER&MPUMELELOSTRS  FREEDOM PARK INFORMAL SETTLEMENT  JOHANNESBURG",
    Latitude: "-26.2962",
    Longitude: "27.9251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8216",
    WardLookupId: "1261",
    VDNumber: "32862674",
    RegisteredPopulation: "2934",
    VotingStationName: "CONTAINER -OPP HOUSE 723 WINNIE CAMP SLOVOPARK",
    Address: "MARSHALL STREET  WINNIE CAMP SLOVOPARK  NANCEFIELD",
    Latitude: "-26.3049",
    Longitude: "27.9014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8217",
    WardLookupId: "1261",
    VDNumber: "32863046",
    RegisteredPopulation: "311",
    VotingStationName: "BUSHKOPPIES HOSTEL COMMUNITY HALL",
    Address: "CITY OF JOHANNESBURG [JOHANNESBURG]  ",
    Latitude: "-26.3058",
    Longitude: "27.9251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8218",
    WardLookupId: "1261",
    VDNumber: "32863079",
    RegisteredPopulation: "2548",
    VotingStationName: "NEW NATURENA PRIMARY SCHOOL",
    Address: "CULLINAN ROAD  NATURENA  JHB",
    Latitude: "-26.297",
    Longitude: "27.9475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8219",
    WardLookupId: "1261",
    VDNumber: "32863495",
    RegisteredPopulation: "2479",
    VotingStationName: "TENT-OPP.HOUSE 3105 LETSATSI STREE -TAXI RANK",
    Address: "3105 LETSATSI STREET   SIYAYA SECTION    FREEDOM PARK",
    Latitude: "-26.3008",
    Longitude: "27.9418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8220",
    WardLookupId: "1262",
    VDNumber: "32860773",
    RegisteredPopulation: "1929",
    VotingStationName: "SOUTH VIEW HIGH SCHOOL",
    Address: "C/R COSMOS & VIOLET ST  LENASIA SOUTH  JOHANNESBURG",
    Latitude: "-26.3791",
    Longitude: "27.8496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8221",
    WardLookupId: "1262",
    VDNumber: "32860807",
    RegisteredPopulation: "3279",
    VotingStationName: "KIASHA PARK PRIMARY SCHOOL",
    Address: "5794 DRAKENSBERG ST.  LENASIA SOUTH  JOHANNESBURG",
    Latitude: "-26.3966",
    Longitude: "27.8696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8222",
    WardLookupId: "1262",
    VDNumber: "32860818",
    RegisteredPopulation: "2756",
    VotingStationName: "SIERRA NEVADA PRIMARY SCHOOL",
    Address:
      "CNR SIERRA NEVADA & STELLENBERG ST.  LENASIA SOUTH EXT 4  JOHANNESBURG",
    Latitude: "-26.3977",
    Longitude: "27.8602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8223",
    WardLookupId: "1262",
    VDNumber: "32860829",
    RegisteredPopulation: "1966",
    VotingStationName: "MADIBA PRIMARY SCHOOL",
    Address: "2637 HIBISCUS CRESCENT  LENASIA SOUTH EXT 2  JOHANNESBURG",
    Latitude: "-26.3945",
    Longitude: "27.844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8224",
    WardLookupId: "1262",
    VDNumber: "32862337",
    RegisteredPopulation: "2038",
    VotingStationName: "LANCASTER PRIMARY SCHOOL",
    Address: "LANCASTER  LENASIA SOUTH  JOHANNESBURG",
    Latitude: "-26.3866",
    Longitude: "27.8569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8225",
    WardLookupId: "1262",
    VDNumber: "32862427",
    RegisteredPopulation: "1047",
    VotingStationName: "GODS PLAN CHURCH",
    Address: "BARIUM  MAGSON MANOR  JOHANNESBURG",
    Latitude: "-26.4158",
    Longitude: "27.8768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8226",
    WardLookupId: "1262",
    VDNumber: "32862450",
    RegisteredPopulation: "3072",
    VotingStationName: "QALABOTJHA SECONDARY SCHOOL",
    Address: "3041 LONGCLAW ROAD  VLAKFONTEIN EXT 3  JOHANNESBURG",
    Latitude: "-26.3883",
    Longitude: "27.8871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8227",
    WardLookupId: "1262",
    VDNumber: "32862810",
    RegisteredPopulation: "2775",
    VotingStationName: "TOUCH LIFE MINISTRIES",
    Address: "5 TO 5 HOSPITAL HILL  LEHOSPITAL HILL  JOHANNESBURG",
    Latitude: "-26.3824",
    Longitude: "27.8605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8228",
    WardLookupId: "1262",
    VDNumber: "32863552",
    RegisteredPopulation: "2530",
    VotingStationName: "NEW GENERATION COMBINED SCHOOL",
    Address: "71 PLOT 71 UNAVILLE  VLAKFONTEIN  JOHANNESBURG",
    Latitude: "-26.4016",
    Longitude: "27.882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8229",
    WardLookupId: "1263",
    VDNumber: "32860784",
    RegisteredPopulation: "4288",
    VotingStationName: "ENNERDALE SECONDARY SCHOOL",
    Address:
      "3622 AGAAT STREET  ENNERDALE  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.4146",
    Longitude: "27.8234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8230",
    WardLookupId: "1263",
    VDNumber: "32860795",
    RegisteredPopulation: "1580",
    VotingStationName: "ENNERDALE CIVIC CENTRE",
    Address: "KATZ STREET  ENNERDALE EXT. 9  JOHANNESBURG",
    Latitude: "-26.4036",
    Longitude: "27.8311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8231",
    WardLookupId: "1263",
    VDNumber: "32860830",
    RegisteredPopulation: "3551",
    VotingStationName: "MOTHEO PRIMARY SCHOOL",
    Address:
      "88 BARACUDA ROAD  LAWLEY EXT1  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.3893",
    Longitude: "27.8117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8232",
    WardLookupId: "1263",
    VDNumber: "32862326",
    RegisteredPopulation: "4026",
    VotingStationName: "LAWLEY EXT8 (TENT)",
    Address: "SIMELANE AND TSAKANE STR  LAWLEY  JOHANNESBURG",
    Latitude: "-26.38",
    Longitude: "27.7964",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8233",
    WardLookupId: "1263",
    VDNumber: "32862461",
    RegisteredPopulation: "4591",
    VotingStationName: "LAWLEY PRIMARY SCHOOL",
    Address: "461 THIRD AVENUE  LAWLEY  JOHANNESBURG",
    Latitude: "-26.3846",
    Longitude: "27.8104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8234",
    WardLookupId: "1263",
    VDNumber: "32862821",
    RegisteredPopulation: "463",
    VotingStationName: "HOPEFIELD COMMUNITY HALL",
    Address: "HOPEFIELD INFORMAL SETTLEMENT  HOPEFIELD  JOHANNESBURG",
    Latitude: "-26.4235",
    Longitude: "27.8129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8235",
    WardLookupId: "1263",
    VDNumber: "32862900",
    RegisteredPopulation: "756",
    VotingStationName: "TENT (ENNERDALE EXT.2)",
    Address: "ENNERDALE EXT2  ENNERDALE  JOHANNESBURG",
    Latitude: "-26.3959",
    Longitude: "27.8385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8236",
    WardLookupId: "1263",
    VDNumber: "32863484",
    RegisteredPopulation: "1497",
    VotingStationName: "THUTO-LEFA SECONDARY SCHOOL",
    Address: "LOUIS AND THIRD AVENUE  LAWLEY EXT2  JOHANNESBURG",
    Latitude: "-26.3805",
    Longitude: "27.809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8237",
    WardLookupId: "1263",
    VDNumber: "32863563",
    RegisteredPopulation: "1423",
    VotingStationName: "TENT LAWLEY EXTENTION 2",
    Address: "SEVENTH AVENUE  LAWLEY  JOHANNEBSURG",
    Latitude: "-26.3709",
    Longitude: "27.8023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8238",
    WardLookupId: "1264",
    VDNumber: "32860380",
    RegisteredPopulation: "597",
    VotingStationName: "ELETHU THEMBA SCHOOL",
    Address: "KLIPRIVIER RD, PLOT 51  EIKENHOF  JOHANNESBURG",
    Latitude: "-26.3333",
    Longitude: "27.9872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8239",
    WardLookupId: "1264",
    VDNumber: "32860469",
    RegisteredPopulation: "2625",
    VotingStationName: "ZAKARIA PARK COMMUNITY HALL",
    Address: "249 CLOVE & TUMERIC STREETS  ZAKARIA PARK  JOHANNESBURG",
    Latitude: "-26.369",
    Longitude: "27.8962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8240",
    WardLookupId: "1264",
    VDNumber: "32860740",
    RegisteredPopulation: "111",
    VotingStationName: "OLIFANTSVLEI PRIMARY SCHOOL",
    Address: "LENASIA/EIKENHOF RD  IQ 316 PORTIONS 15&27  JOHANNESBURG",
    Latitude: "-26.3412",
    Longitude: "27.9096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8241",
    WardLookupId: "1264",
    VDNumber: "32862809",
    RegisteredPopulation: "1623",
    VotingStationName: "COMMUNITY CRECHE",
    Address: "JACKSON PARK  EIKENHOF  JOHANNESBURG",
    Latitude: "-26.3322",
    Longitude: "27.9766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8242",
    WardLookupId: "1264",
    VDNumber: "32862876",
    RegisteredPopulation: "3084",
    VotingStationName: "INKULULEKO YESIZWE PRIMARY SCHOOL",
    Address:
      "5608 SCORPION ROAD  OLD VLAKFONTEIN  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.3774",
    Longitude: "27.8761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8243",
    WardLookupId: "1264",
    VDNumber: "32862977",
    RegisteredPopulation: "3876",
    VotingStationName: "IGUGULETHU PRIMARY SCHOOL",
    Address: "VLAKFONTEIN  JOHANNESBURG",
    Latitude: "-26.3774",
    Longitude: "27.8897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8244",
    WardLookupId: "1264",
    VDNumber: "32863136",
    RegisteredPopulation: "2256",
    VotingStationName: "LEHAE PRIMARY SCHOOL",
    Address: "2503 IMPATIENS STREET  LEHAE  JOHANNESBURG",
    Latitude: "-26.3519",
    Longitude: "27.8929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8245",
    WardLookupId: "1264",
    VDNumber: "32863147",
    RegisteredPopulation: "3321",
    VotingStationName: "BAZOKHULA DAYCARE",
    Address: "01 DAISY STR  LEHAE  JOHANNESBURG",
    Latitude: "-26.343",
    Longitude: "27.8922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8246",
    WardLookupId: "1264",
    VDNumber: "32863439",
    RegisteredPopulation: "1833",
    VotingStationName: "BETHEL MINISTRIES",
    Address: "2037 ELEPHANT STREET  LEHAE  JOHANNESBURG",
    Latitude: "-26.3461",
    Longitude: "27.8839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8247",
    WardLookupId: "1264",
    VDNumber: "32863518",
    RegisteredPopulation: "1045",
    VotingStationName: "TSHEPO YA RONA SEC SCHOOL",
    Address: "01 FALCON  VLAKFONTEIN  JOHANNESBURG",
    Latitude: "-26.3839",
    Longitude: "27.8863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8248",
    WardLookupId: "1265",
    VDNumber: "32850255",
    RegisteredPopulation: "1338",
    VotingStationName: "CATHEDRAL OF CHRIST THE KING",
    Address: "3 SARATOGA AVENUE  BEREA  CITY OF JOHANNESBURG",
    Latitude: "-26.1934",
    Longitude: "28.052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8249",
    WardLookupId: "1265",
    VDNumber: "32850323",
    RegisteredPopulation: "2909",
    VotingStationName: "IH HARRIS PRIMARY SCHOOL",
    Address: "CNR BEIT & DAVIES STS  DOORNFONTEIN  JOHANNESBURG",
    Latitude: "-26.1965",
    Longitude: "28.0528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8250",
    WardLookupId: "1265",
    VDNumber: "32850345",
    RegisteredPopulation: "3361",
    VotingStationName: "TVS UJ DFC - TENT",
    Address: "CNR NIND AND PEARSE   DOORNFONTEIN  JOHANNESBURG",
    Latitude: "-26.1943",
    Longitude: "28.0561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8251",
    WardLookupId: "1265",
    VDNumber: "32850390",
    RegisteredPopulation: "3686",
    VotingStationName: "CENTRAL COMMUNITY FELLOWSHIP",
    Address: "49 LEYDS STREET  JOUBERTPARK  JOHANNESBURG",
    Latitude: "-26.1947",
    Longitude: "28.0492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8252",
    WardLookupId: "1265",
    VDNumber: "32850514",
    RegisteredPopulation: "4430",
    VotingStationName: "ST ENDAS HIGH SCHOOL",
    Address: "12-18 PIETERSEN ST  JOUBERT PARK  JOHANNESBURG",
    Latitude: "-26.1934",
    Longitude: "28.0474",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8253",
    WardLookupId: "1265",
    VDNumber: "32851357",
    RegisteredPopulation: "782",
    VotingStationName: "PULLINGERKOP PARK (TENT)",
    Address: "PRIMROSE  BEREA  JOHANNESBURG",
    Latitude: "-26.1913",
    Longitude: "28.053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8254",
    WardLookupId: "1266",
    VDNumber: "32860010",
    RegisteredPopulation: "4952",
    VotingStationName: "JOHANNESBURG CITY HALL",
    Address: "PRESIDENT ST  JOHANNESBURG  JOHANNESBURG",
    Latitude: "-26.204",
    Longitude: "28.0398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8255",
    WardLookupId: "1266",
    VDNumber: "32860100",
    RegisteredPopulation: "1218",
    VotingStationName: "SELBY CENTRAL OFFICE",
    Address: "1 WEST ST  SELBY X3  JOHANNESBURG",
    Latitude: "-26.2114",
    Longitude: "28.0356",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8256",
    WardLookupId: "1266",
    VDNumber: "32860177",
    RegisteredPopulation: "1157",
    VotingStationName: "PIONEER ADMINISTRATION HALL",
    Address: "CNR GLENROY & LA ROCHELLE  PIONEER  JOHANNESBURG",
    Latitude: "-26.2316",
    Longitude: "28.0466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8257",
    WardLookupId: "1266",
    VDNumber: "32860199",
    RegisteredPopulation: "3327",
    VotingStationName: "SOUTHERN SUBURBS BOWLING CLUB",
    Address: "FRAZER & DE VILLIERS  KENILWORTH  JOHANNESBURG",
    Latitude: "-26.2451",
    Longitude: "28.0489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8258",
    WardLookupId: "1266",
    VDNumber: "32860201",
    RegisteredPopulation: "3959",
    VotingStationName: "FOREST HIGH SCHOOL",
    Address: "FOREST STREET  FOREST HILL  JOHANNESBURG",
    Latitude: "-26.2517",
    Longitude: "28.0429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8259",
    WardLookupId: "1266",
    VDNumber: "32862405",
    RegisteredPopulation: "2376",
    VotingStationName: "REUVEN SENIOR COMPLEX",
    Address: "31 WEST TURFFONTEIN ROAD  REUVEN  JOHANNESBURG",
    Latitude: "-26.2374",
    Longitude: "28.0286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8260",
    WardLookupId: "1266",
    VDNumber: "32862731",
    RegisteredPopulation: "703",
    VotingStationName: "TENDER ADVICE CENTRE CITY POWER",
    Address: "40 HERONMERE ROAD  REUVEN  JOHANNESBURG",
    Latitude: "-26.2319",
    Longitude: "28.03",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8261",
    WardLookupId: "1267",
    VDNumber: "32860346",
    RegisteredPopulation: "2685",
    VotingStationName: "MEREDALE PRIMARY SCHOOL",
    Address: "40 40 MAUREEN/SHEILA STS  MEREDALE  JOHANNESBURG",
    Latitude: "-26.2709",
    Longitude: "27.9787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8262",
    WardLookupId: "1267",
    VDNumber: "32860379",
    RegisteredPopulation: "3725",
    VotingStationName: "KLIPRIVIER CENTRE",
    Address: "PEGGY VERA RD  KIBLER PARK  JOHANNESBURG",
    Latitude: "-26.3109",
    Longitude: "28.0092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8263",
    WardLookupId: "1267",
    VDNumber: "32863103",
    RegisteredPopulation: "1647",
    VotingStationName: "ORMONDE AFM EBENEZER CHURCH",
    Address: "STIRLING STREET  ORMONDE VIEW  JOHANNESBURG SOUTH",
    Latitude: "-26.2498",
    Longitude: "27.9792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8264",
    WardLookupId: "1267",
    VDNumber: "32863169",
    RegisteredPopulation: "597",
    VotingStationName: "CONFIRMED WORD FAITH CHURCH",
    Address: "JOHANNESBURG  ",
    Latitude: "-26.2781",
    Longitude: "27.9685",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8265",
    WardLookupId: "1267",
    VDNumber: "32863170",
    RegisteredPopulation: "2676",
    VotingStationName: "DANIE THERON PRIMARY SCHOOL",
    Address: "JOHANNESBURG  ",
    Latitude: "-26.3165",
    Longitude: "28.0044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8266",
    WardLookupId: "1267",
    VDNumber: "32863181",
    RegisteredPopulation: "2340",
    VotingStationName: "ALPHA & OMEGA CHRISTIAN ACADEMY",
    Address: "CNR LARK & SWALLOW AVENUE  JOHANNESBURG  MEREDALE EXT 2",
    Latitude: "-26.2712",
    Longitude: "27.9878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8267",
    WardLookupId: "1267",
    VDNumber: "32863215",
    RegisteredPopulation: "2560",
    VotingStationName: "NEXT TO JHB CORRECTIONAL SERVICS - SUN CITY (TENT)",
    Address: "MAIN & INO ROADS  MEREDALE  JOHANNESBURG SOUTH",
    Latitude: "-26.2757",
    Longitude: "27.9602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8268",
    WardLookupId: "1268",
    VDNumber: "32840502",
    RegisteredPopulation: "6773",
    VotingStationName: "BOSKOP PRIMARY",
    Address: "DF MALAN & BLUEBERRY  HONEYDEW  RANDBURG",
    Latitude: "-26.0802",
    Longitude: "27.9285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8269",
    WardLookupId: "1268",
    VDNumber: "32870011",
    RegisteredPopulation: "5612",
    VotingStationName: "ALLEN GLEN HIGH SCHOOL",
    Address: "PIET RETIEF STR  ALLENS NEK X9  ROODEPOORT",
    Latitude: "-26.1207",
    Longitude: "27.9198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8270",
    WardLookupId: "1268",
    VDNumber: "32870347",
    RegisteredPopulation: "6866",
    VotingStationName: "WELTEVREDEN PARK PRIMARY SCHOOL",
    Address: "C/O KRINKHOUT & COCKSPUR ROAD  WELTEVREDEN PARK  ROODEPOORT",
    Latitude: "-26.115",
    Longitude: "27.9371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8271",
    WardLookupId: "1269",
    VDNumber: "32870134",
    RegisteredPopulation: "1460",
    VotingStationName: "DURBAN DEEP PRIMARY SCHOOL",
    Address: "12 ENOCH SONTONGA STREET  DURBAN DEEP MINE  ROODEPOORT",
    Latitude: "-26.1718",
    Longitude: "27.8558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8272",
    WardLookupId: "1269",
    VDNumber: "32871056",
    RegisteredPopulation: "4392",
    VotingStationName: "SOL PLAATJIE COMMUNITY HALL",
    Address: "1960 MOTLAKA SREET  SOLPLAATJIE ROODEPOORT  ROODEPOORT",
    Latitude: "-26.1827",
    Longitude: "27.8508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8273",
    WardLookupId: "1269",
    VDNumber: "32871090",
    RegisteredPopulation: "4483",
    VotingStationName: "GOUDRAND HOSTEL HALL",
    Address: "MAIN REEF ROAD  ROODEPOORT  ROODEPOORT",
    Latitude: "-26.1702",
    Longitude: "27.852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8274",
    WardLookupId: "1269",
    VDNumber: "32871281",
    RegisteredPopulation: "2269",
    VotingStationName: "KAGISO CHRISTIAN CENTRE",
    Address: "4838 HECTOR PIETERSON STR PHASE  7  TSHEPISONG  ROODEPOORT",
    Latitude: "-26.1916",
    Longitude: "27.8122",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8275",
    WardLookupId: "1269",
    VDNumber: "32871337",
    RegisteredPopulation: "1121",
    VotingStationName: "NEW FOUNDATION SKILL CENTRE",
    Address: "480 PLOT 8  LINDHAVEN  ROODEPOORT",
    Latitude: "-26.1527",
    Longitude: "27.8375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8276",
    WardLookupId: "1269",
    VDNumber: "33250452",
    RegisteredPopulation: "2878",
    VotingStationName: "LERATONG  SKILL CENTRE",
    Address: "LERATONG INTERSECTION  LERATONG  GREATER JOHANNESBURG",
    Latitude: "-26.1789",
    Longitude: "27.8138",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8277",
    WardLookupId: "1270",
    VDNumber: "32870145",
    RegisteredPopulation: "3844",
    VotingStationName: "TSHEPISONG PRIMARY SCHOOL",
    Address: "3244 RUTHFIRST STREET PHASE 3  TSHEPISONG  JOHANNESBURG",
    Latitude: "-26.193",
    Longitude: "27.8059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8278",
    WardLookupId: "1270",
    VDNumber: "32871203",
    RegisteredPopulation: "4773",
    VotingStationName: "ONKGOPOTSE TIRO PRIMARY",
    Address: "10613 DULCIE SEPTEMBER STREET  TSHEPISONG WEST  ROODEPOORT",
    Latitude: "-26.1939",
    Longitude: "27.7851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8279",
    WardLookupId: "1270",
    VDNumber: "32871214",
    RegisteredPopulation: "5763",
    VotingStationName: "HARRY GWALA PRIMARY SCHOOL",
    Address: "334 ALBERT LUTHULI  TSHEPISONG  ROODEPOORT",
    Latitude: "-26.1841",
    Longitude: "27.7997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8280",
    WardLookupId: "1270",
    VDNumber: "32871304",
    RegisteredPopulation: "2078",
    VotingStationName: "RAYMOND MHLABA SECONDARY SCHOOL",
    Address:
      "4237  CNR PAT MOLAWA & BUZZARD STR  TSHEPISONG PHASE 1  ROODEPOORT",
    Latitude: "-26.1972",
    Longitude: "27.8027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8281",
    WardLookupId: "1270",
    VDNumber: "32871483",
    RegisteredPopulation: "1735",
    VotingStationName: "BOITSHOKO HALL",
    Address: "HOUSE 17 PLOT 21  TSHEPISONG WEST  ROODEPOORT",
    Latitude: "-26.1852",
    Longitude: "27.7871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8282",
    WardLookupId: "1271",
    VDNumber: "32870538",
    RegisteredPopulation: "2317",
    VotingStationName: "HECTOR PETERSON PRIMARY SCHOOL",
    Address: "8853 MBALO DRIVE  DOBSONVILLE  JOHANNESBURG",
    Latitude: "-26.234",
    Longitude: "27.822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8283",
    WardLookupId: "1271",
    VDNumber: "32870910",
    RegisteredPopulation: "3522",
    VotingStationName: "OBED MOSIANE PRIMARY SCHOOL",
    Address:
      "5329 ROAD 1101 BLOCK 1  DOORNKOP  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.2173",
    Longitude: "27.8364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8284",
    WardLookupId: "1271",
    VDNumber: "32870921",
    RegisteredPopulation: "2734",
    VotingStationName: "SEBETSA OTHOLE-MOPUTSO",
    Address:
      "485 RING ROAD  DOORNKOP BLOCK 8  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.2248",
    Longitude: "27.829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8285",
    WardLookupId: "1271",
    VDNumber: "32870932",
    RegisteredPopulation: "2888",
    VotingStationName: "DOORNKOP COMMUNITY HALL",
    Address: "BLOCK  4  DOORNKOP  JOHANNESBURG",
    Latitude: "-26.2228",
    Longitude: "27.8334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8286",
    WardLookupId: "1271",
    VDNumber: "32871135",
    RegisteredPopulation: "2546",
    VotingStationName: "BONAMELO PRIMARY SCHOOL",
    Address: "BLOCK 4  DOORNKOP  JOHANNESBURG",
    Latitude: "-26.2213",
    Longitude: "27.8283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8287",
    WardLookupId: "1271",
    VDNumber: "32871146",
    RegisteredPopulation: "1645",
    VotingStationName: "HOUSE OF VICTORY KINGDOM LEADERSHIP FAMILY CHURCH",
    Address: "DOORNKOP RIVERSIDE  DOORNKOP   SOWETO",
    Latitude: "-26.2302",
    Longitude: "27.8205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8288",
    WardLookupId: "1272",
    VDNumber: "32862034",
    RegisteredPopulation: "2425",
    VotingStationName: "EMDENI SENIOR SECONDARY SCHOOL",
    Address: "1817 ZAMUKULUNGISA  EMDENI EXT  JOHANNESBURG",
    Latitude: "-26.247",
    Longitude: "27.8136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8289",
    WardLookupId: "1272",
    VDNumber: "32862067",
    RegisteredPopulation: "3112",
    VotingStationName: "JOSIAH KHUMALO ADULT EDUCATION CENTRE",
    Address: "NKOSINYANA & NTSHENGU  EMDENI  JOHANNESBURG",
    Latitude: "-26.2461",
    Longitude: "27.8211",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8290",
    WardLookupId: "1272",
    VDNumber: "32862078",
    RegisteredPopulation: "2129",
    VotingStationName: "EMSENI PRIMARY SCHOOL",
    Address: "1816 ZAMAKULUNGISA ST.  EMDENI EXT.  SOWETO",
    Latitude: "-26.2482",
    Longitude: "27.8148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8291",
    WardLookupId: "1272",
    VDNumber: "32862113",
    RegisteredPopulation: "1776",
    VotingStationName: "ATAMELANG PRIMARY SCHOOL",
    Address: "48A KGARIBE  STR  NALEDI  SOWETO JHB",
    Latitude: "-26.2506",
    Longitude: "27.8229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8292",
    WardLookupId: "1272",
    VDNumber: "32862146",
    RegisteredPopulation: "1592",
    VotingStationName: "NALEDI METHODIST CHURCH",
    Address: "566 CRN MOJALEFA & MOSIMANE STREET  NALEDI  SOWETO JOHANNESBURG",
    Latitude: "-26.252",
    Longitude: "27.8274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8293",
    WardLookupId: "1272",
    VDNumber: "32862393",
    RegisteredPopulation: "1875",
    VotingStationName: "EMNDENI CATHOLIC CHURCH.",
    Address: "1590 DITSHEGO STREET  EMNDENI  SOWETO",
    Latitude: "-26.2524",
    Longitude: "27.8198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8294",
    WardLookupId: "1272",
    VDNumber: "32863440",
    RegisteredPopulation: "532",
    VotingStationName: "TENT- ASH MOUNTAIN OPP. HOUSE NO. 382",
    Address: "SEEISO RD  NALEDI EXT 2  SOWETO",
    Latitude: "-26.2516",
    Longitude: "27.8136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8295",
    WardLookupId: "1273",
    VDNumber: "32860841",
    RegisteredPopulation: "3192",
    VotingStationName: "MADUME PRIMARY SCHOOL",
    Address: "6274 STRETFORD. EXT 4  ORANGE FARM  ",
    Latitude: "-26.4895",
    Longitude: "27.8608",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8296",
    WardLookupId: "1273",
    VDNumber: "32860953",
    RegisteredPopulation: "3062",
    VotingStationName: "RAPHELA SECONDARY SCHOOL",
    Address: "5450 STRETFORD EXT 2  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4951",
    Longitude: "27.8676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8297",
    WardLookupId: "1273",
    VDNumber: "32860964",
    RegisteredPopulation: "1909",
    VotingStationName: "ORANGE FARM EXT 1 HALL",
    Address: "2896 ORANGE FARM EXT 1  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4941",
    Longitude: "27.8769",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8298",
    WardLookupId: "1273",
    VDNumber: "32861000",
    RegisteredPopulation: "2855",
    VotingStationName: "THULI HOME",
    Address: "8604 STRETFORD EXT. 3  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.5019",
    Longitude: "27.8642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8299",
    WardLookupId: "1273",
    VDNumber: "32862348",
    RegisteredPopulation: "962",
    VotingStationName: "MARANATHA REVIVAL CHURCH OF ORANGE FARM",
    Address: "1618 ORANGE FARM EXT 1  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.4877",
    Longitude: "27.8768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8300",
    WardLookupId: "1273",
    VDNumber: "32862472",
    RegisteredPopulation: "1296",
    VotingStationName: "IMBALI PRIMARY SCHOOL",
    Address: "2156 ORANGE FARM EXT 1  ORANGE FARM   JOHANNESBURG",
    Latitude: "-26.4917",
    Longitude: "27.8772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8301",
    WardLookupId: "1273",
    VDNumber: "32863349",
    RegisteredPopulation: "1167",
    VotingStationName: "NOMINI PRIMARY SCHOOL",
    Address: "5786 EXT 3 ORANGE FARM  ORANGE FARM  JOHANNESBURG",
    Latitude: "-26.498",
    Longitude: "27.8687",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8302",
    WardLookupId: "1274",
    VDNumber: "32850053",
    RegisteredPopulation: "1849",
    VotingStationName: "LEEUWKOP PRISON",
    Address: "M71 MAIN ROAD  LEEUWKOP  RANDBURG",
    Latitude: "-26.0078",
    Longitude: "28.0462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8303",
    WardLookupId: "1274",
    VDNumber: "32851379",
    RegisteredPopulation: "4023",
    VotingStationName: "REDDAM COLLEGE",
    Address: "WATERFALL COUNTRY ESTATE  MIDRAND  WATERFALL ESTATE",
    Latitude: "-26.0266",
    Longitude: "28.0921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8304",
    WardLookupId: "1274",
    VDNumber: "32910027",
    RegisteredPopulation: "5490",
    VotingStationName: "FUTURE STARS PRE-SCHOOL",
    Address: "01 ANDRE BRINK ST. CNR ALBERTYN   VORNA VALLEY  MIDRAND",
    Latitude: "-26",
    Longitude: "28.1012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8305",
    WardLookupId: "1274",
    VDNumber: "32910050",
    RegisteredPopulation: "5829",
    VotingStationName: "HALFWAY HOUSE PRIMARY SCHOOL",
    Address: "291 CNR VAN HEERDEN RD  HALFWAY GARDENS  JOHANNESBURG",
    Latitude: "-25.9925",
    Longitude: "28.1143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8306",
    WardLookupId: "1274",
    VDNumber: "32910353",
    RegisteredPopulation: "1441",
    VotingStationName: "COMMUNITAS CHURCH",
    Address: "18 JUPITER AVENUE  CRAWTHORN  MIDRAND",
    Latitude: "-25.9718",
    Longitude: "28.0894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8307",
    WardLookupId: "1274",
    VDNumber: "32910555",
    RegisteredPopulation: "2853",
    VotingStationName: "KYALAMI PREPARATORY SCHOOL",
    Address: "CNR MAPLE & LYNDORE  MIDRAND, KYALAMI ESTATE  JOBURG",
    Latitude: "-25.9989",
    Longitude: "28.0822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8308",
    WardLookupId: "1275",
    VDNumber: "32910117",
    RegisteredPopulation: "3567",
    VotingStationName: "IVORY PARK NORTH COMMUNITY HALL",
    Address: "21ST AUGUST DRIVE  IVORY PARK EXT 9  MIDRAND",
    Latitude: "-25.9903",
    Longitude: "28.2013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8309",
    WardLookupId: "1275",
    VDNumber: "32910128",
    RegisteredPopulation: "4699",
    VotingStationName: "JB MATABANE SECONDARY SCHOOL",
    Address: "MIDRAND  JOHANNESBURG",
    Latitude: "-25.996",
    Longitude: "28.209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8310",
    WardLookupId: "1275",
    VDNumber: "32910139",
    RegisteredPopulation: "4733",
    VotingStationName: "COME ONE COME ALL",
    Address: "6694 DLAMINI DRIVE  IVORY PARK  MIDRAND",
    Latitude: "-25.9982",
    Longitude: "28.2022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8311",
    WardLookupId: "1275",
    VDNumber: "32910241",
    RegisteredPopulation: "4302",
    VotingStationName: "FULL GOSPEL CHURCH",
    Address: "10936 IVORY PARK EXT 10  IVORY PARK  MIDRAND",
    Latitude: "-25.9886",
    Longitude: "28.1977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8312",
    WardLookupId: "1276",
    VDNumber: "32840401",
    RegisteredPopulation: "3872",
    VotingStationName: "RANDPARK PRIMARY SCHOOL",
    Address: "CNR RANDPARK DRIVE & SEDER STR  RANDPARK RIDGE EXT 1  RANDBURG",
    Latitude: "-26.099",
    Longitude: "27.955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8313",
    WardLookupId: "1276",
    VDNumber: "32840513",
    RegisteredPopulation: "6190",
    VotingStationName: "BOSKRUIN COMMUNITY CENTRE",
    Address: "KELLY ROAD  BOSKRUIN  RANDBURG",
    Latitude: "-26.0876",
    Longitude: "27.9455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8314",
    WardLookupId: "1276",
    VDNumber: "32840557",
    RegisteredPopulation: "3541",
    VotingStationName: "CABANGA CONFERENCE CENTRE",
    Address: "252A MONTROSE AVENUE  NORTHRIDING  NORTHRIDING",
    Latitude: "-26.0608",
    Longitude: "27.9496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8315",
    WardLookupId: "1276",
    VDNumber: "32841266",
    RegisteredPopulation: "1971",
    VotingStationName: "ACACIA PRIVATE SCHOOL",
    Address: "200 BLANDFORD ROAD  NORTHRIDING  NORTHRIDING",
    Latitude: "-26.0547",
    Longitude: "27.9625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8316",
    WardLookupId: "1276",
    VDNumber: "32841705",
    RegisteredPopulation: "366",
    VotingStationName: "GIRL GUIDE SA (CONFERENCE CENTRE)",
    Address: "374 BOUNDARY ROAD   NORTH RIDING AH  HONEYDEW",
    Latitude: "-26.0497",
    Longitude: "27.9302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8317",
    WardLookupId: "1277",
    VDNumber: "32870897",
    RegisteredPopulation: "5783",
    VotingStationName: "FARANANI PRIMARY SCHOOL",
    Address: "8996/186 BOULVARD ROAD  PROTEA GLEN EXT 11  SOWETO",
    Latitude: "-26.2757",
    Longitude: "27.8065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8318",
    WardLookupId: "1277",
    VDNumber: "32871191",
    RegisteredPopulation: "3469",
    VotingStationName: "MZIWANDILEMSELANTO EDUCARE CENTRE",
    Address:
      "7310 7310 KUKAME STREET  PROTEA GLEN/SOWETO  CITY OF JOHANNESBURG",
    Latitude: "-26.2711",
    Longitude: "27.8035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8319",
    WardLookupId: "1277",
    VDNumber: "32871247",
    RegisteredPopulation: "2739",
    VotingStationName: "LITTLE ANGELS DAYCARE & PRE-SCHOOL",
    Address:
      "9634 TSHIPO STR  PROTEA GLEN EXT12  CITY OF JOHANNESBURG [JOHANNESBURG]",
    Latitude: "-26.2811",
    Longitude: "27.804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8320",
    WardLookupId: "1277",
    VDNumber: "32871315",
    RegisteredPopulation: "3306",
    VotingStationName: "PROTEA GLEN SECONDARY NO.2.",
    Address: "15704 CORNER SUNSET & ALI STREET  PROTEA GLEN EXT 16  SOWETO",
    Latitude: "-26.27",
    Longitude: "27.7894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8321",
    WardLookupId: "1277",
    VDNumber: "32871326",
    RegisteredPopulation: "2209",
    VotingStationName: "GLEN RIDGE PRIMARY SCHOOL",
    Address: "ALFONSO STREET  GLENRIDGE  SOWETO",
    Latitude: "-26.2738",
    Longitude: "27.7893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8322",
    WardLookupId: "1277",
    VDNumber: "32871438",
    RegisteredPopulation: "489",
    VotingStationName: "HAPPY FEET NURSERY & DAY CARE CENTRE",
    Address: "20852 ORANGE STREET  PROTEA GLEN EXT 29  SOWETO",
    Latitude: "-26.2666",
    Longitude: "27.7714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8323",
    WardLookupId: "1277",
    VDNumber: "32871449",
    RegisteredPopulation: "3540",
    VotingStationName: "PRESTIGE COLLEGE",
    Address: "KEI STREET  PROTEA GLEN EXT 28  SOWETO",
    Latitude: "-26.2711",
    Longitude: "27.7706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8324",
    WardLookupId: "1277",
    VDNumber: "32871539",
    RegisteredPopulation: "585",
    VotingStationName: "PROTEA GLEN PRIMARY SCHOOL NO.2",
    Address: "9 CURRANT STREET  PROTEA GLEN EXT.28  SOWETO",
    Latitude: "-26.2835",
    Longitude: "27.7705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8325",
    WardLookupId: "2564",
    VDNumber: "32950021",
    RegisteredPopulation: "3198",
    VotingStationName: "LAERSKOOL GENL NICOLAAS SMIT",
    Address: "945 CNR BREMER AND PRETORIA STREET  CLAREMONT  PRETORIA",
    Latitude: "-25.716",
    Longitude: "28.1374",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8326",
    WardLookupId: "2564",
    VDNumber: "32950032",
    RegisteredPopulation: "4370",
    VotingStationName: "HOÃ‹RSKOOL HERCULES",
    Address: "CNR RIBBENS & JENNINGS STRAAT  HERCULES  PRETORIA",
    Latitude: "-25.7158",
    Longitude: "28.1456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8327",
    WardLookupId: "2564",
    VDNumber: "32950065",
    RegisteredPopulation: "3570",
    VotingStationName: "SAAMSPAN LAERSKOOL",
    Address: "CNR DENYSSEN AND DANIEL STREETS  MOUNTAIN VIEW  PRETORIA",
    Latitude: "-25.6977",
    Longitude: "28.1569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8328",
    WardLookupId: "2564",
    VDNumber: "32950098",
    RegisteredPopulation: "2876",
    VotingStationName: "NG KERK PRETORIA TUINE",
    Address: "567 SCHURMANN LAAN AVENUE  PRETORIA TUINE  PRETORIA",
    Latitude: "-25.7243",
    Longitude: "28.1519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8329",
    WardLookupId: "2564",
    VDNumber: "32952348",
    RegisteredPopulation: "1689",
    VotingStationName: "CAPITAL PARK PRIMARY SCHOOL",
    Address: "290 PAUL KRUGER  CAPITAL PARK  PRETORIA",
    Latitude: "-25.7278",
    Longitude: "28.1874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8330",
    WardLookupId: "2565",
    VDNumber: "32951640",
    RegisteredPopulation: "3404",
    VotingStationName: "LAERSKOOL RACHEL DE BEER",
    Address: "766 WONDERBOOM STREET  PRETORIA NORTH  PRETORIA",
    Latitude: "-25.6618",
    Longitude: "28.1569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8331",
    WardLookupId: "2565",
    VDNumber: "32951651",
    RegisteredPopulation: "2840",
    VotingStationName: "LAERSKOOL VOORTREKKER EEUFEES",
    Address: "538 CNR PRESIDENT STEYN STREET   PRETORIA NORTH  PRETORIA",
    Latitude: "-25.6656",
    Longitude: "28.171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8332",
    WardLookupId: "2565",
    VDNumber: "32951662",
    RegisteredPopulation: "4260",
    VotingStationName: "PRETORIA-NOORD STADSAAL",
    Address: "VADER KESTEL PARK, BURGER STREET  PRETORIA NOORD  PRETORIA",
    Latitude: "-25.6806",
    Longitude: "28.1723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8333",
    WardLookupId: "2565",
    VDNumber: "32951831",
    RegisteredPopulation: "1864",
    VotingStationName: "PRETORIA NORTH LIBRARY",
    Address: "BURGER STREET  PRETORIA NOORD  TSHWANE",
    Latitude: "-25.6737",
    Longitude: "28.1714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8334",
    WardLookupId: "2565",
    VDNumber: "32952236",
    RegisteredPopulation: "1956",
    VotingStationName: "FLORAUNA KLEUTERKAMPUS",
    Address:
      "706 BERGLAAN, PRETORIA-NOORD  PRETORIA-NOORD  TSHWANE METRO [PRETORIA]",
    Latitude: "-25.6845",
    Longitude: "28.158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8335",
    WardLookupId: "2565",
    VDNumber: "32952359",
    RegisteredPopulation: "654",
    VotingStationName: "LAERSKOOL DANIE MALAN",
    Address: "279 KOOS DE LA RAY STREET  PRETORIA-NORTH  TSHWANE",
    Latitude: "-25.6765",
    Longitude: "28.1794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8336",
    WardLookupId: "2566",
    VDNumber: "32950122",
    RegisteredPopulation: "4599",
    VotingStationName: "GENERAAL BEYERS LAERSKOOL",
    Address: "C/O DU PLESSISSTRAAT & DELANEY STR  DANVILLE  PRETORIA",
    Latitude: "-25.7435",
    Longitude: "28.1266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8337",
    WardLookupId: "2566",
    VDNumber: "32950155",
    RegisteredPopulation: "2442",
    VotingStationName: "TENT - PARK WESTVIEW",
    Address: "VROLIKE LIED/LIBBIS PLACE  ELANDSPOORT  PRETORIA",
    Latitude: "-25.7406",
    Longitude: "28.1078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8338",
    WardLookupId: "2566",
    VDNumber: "32950166",
    RegisteredPopulation: "2631",
    VotingStationName: "LAERSKOOL WESPARK",
    Address: "30 BOSBOKSTRAAT (ENTRANCE BREEZE STREET)  WESPARK  PRETORIA",
    Latitude: "-25.7586",
    Longitude: "28.1228",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8339",
    WardLookupId: "2566",
    VDNumber: "32950177",
    RegisteredPopulation: "1850",
    VotingStationName: "TSHWANE SOUTH COLLEGE FOR FET (PRETORIA WEST CAMPUS)",
    Address: "150 INDUSTRIAL ROAD  PRETORIA-WEST  PRETORIA",
    Latitude: "-25.7611",
    Longitude: "28.1364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8340",
    WardLookupId: "2566",
    VDNumber: "32950188",
    RegisteredPopulation: "2742",
    VotingStationName: "KWAGGASRAND LAERSKOOL",
    Address: "1 LYSTER STREET  KWAGGASRAND  PRETORIA",
    Latitude: "-25.7595",
    Longitude: "28.1125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8341",
    WardLookupId: "2566",
    VDNumber: "32952090",
    RegisteredPopulation: "1869",
    VotingStationName: "TSHWANE SOUTH TVET COLLEGE",
    Address: "5 KHOZA STREET  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.7672",
    Longitude: "28.0895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8342",
    WardLookupId: "2567",
    VDNumber: "32960527",
    RegisteredPopulation: "5101",
    VotingStationName: "AKASIA MUNICIPAL OFFICE",
    Address: "16 DALE AVENUE  KAREN PARK  TSHWANE",
    Latitude: "-25.6668",
    Longitude: "28.1065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8343",
    WardLookupId: "2567",
    VDNumber: "32960538",
    RegisteredPopulation: "5392",
    VotingStationName: "THE ORCHARDS PRIMARY SCHOOL",
    Address: "FAIRWOOD AVE  ORCHARDS  TSHWANE",
    Latitude: "-25.6482",
    Longitude: "28.0967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8344",
    WardLookupId: "2567",
    VDNumber: "32960875",
    RegisteredPopulation: "2718",
    VotingStationName: "AMANDASIG SECONDARY SCHOOL",
    Address: "7473 RACHEL DE BEER STREET  AMANDASIG  PRETORIA",
    Latitude: "-25.6725",
    Longitude: "28.0977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8345",
    WardLookupId: "2567",
    VDNumber: "33280017",
    RegisteredPopulation: "1145",
    VotingStationName: "VISSERSHOEK PRIMARY SCHOOL",
    Address: "FARM VISSERSHOEK 435 IQ  VISSERSHOEK  PRETORIA",
    Latitude: "-25.6574",
    Longitude: "27.955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8346",
    WardLookupId: "2567",
    VDNumber: "33280107",
    RegisteredPopulation: "1255",
    VotingStationName: "XIHLAMARISO DAY CARE",
    Address: "4385 SLIPPIE KIRSTEL STREET   ORCHARDS EXTENTION  PRETORIA",
    Latitude: "-25.6416",
    Longitude: "28.0905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8347",
    WardLookupId: "2567",
    VDNumber: "33280118",
    RegisteredPopulation: "1705",
    VotingStationName: "FOUNTAIN OF LIFE WORSHIP CENTER",
    Address: "53 DEELEFS  EXTENTION 47  ORCHARDS",
    Latitude: "-25.6376",
    Longitude: "28.0845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8348",
    WardLookupId: "2568",
    VDNumber: "32951617",
    RegisteredPopulation: "4217",
    VotingStationName: "MAGALIESKRUIN LAERSKOOL",
    Address: "CNR VERONICA AND STEPHAN ROAD  MAGALIESKRUIN  PRETORIA",
    Latitude: "-25.6841",
    Longitude: "28.2381",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8349",
    WardLookupId: "2568",
    VDNumber: "32951628",
    RegisteredPopulation: "6644",
    VotingStationName: "MONTANA HOÃ‹RSKOOL",
    Address: "CNR 6TH AVENUE AND SWANEPOEL  MONTANA   PRETORIA",
    Latitude: "-25.6738",
    Longitude: "28.243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8350",
    WardLookupId: "2568",
    VDNumber: "32951718",
    RegisteredPopulation: "2624",
    VotingStationName: "TENT AT MUNICIPAL PARK",
    Address: "370 MOONFLOWER STREET  SINOVILLE, EXT. 2  PRETORIA",
    Latitude: "-25.6789",
    Longitude: "28.2302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8351",
    WardLookupId: "2568",
    VDNumber: "32952630",
    RegisteredPopulation: "1626",
    VotingStationName: "BRABBEL BEKKIES KLEUTERSKOOL",
    Address: "893 VEDA AVENUE  MONTANA PARK  PRETORIA",
    Latitude: "-25.6835",
    Longitude: "28.2635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8352",
    WardLookupId: "2568",
    VDNumber: "32952641",
    RegisteredPopulation: "1758",
    VotingStationName: "MONTANA FITNESS CENTRE",
    Address: "650 VERONICA ROAD  MONTANA  PRETORIA",
    Latitude: "-25.6648",
    Longitude: "28.2383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8353",
    WardLookupId: "2568",
    VDNumber: "32953226",
    RegisteredPopulation: "827",
    VotingStationName: "HIS SANCTUARY MINISTRIES",
    Address: "38  DR VAN DER MERWE  MONTANA  PRETORIA",
    Latitude: "-25.6695",
    Longitude: "28.25",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8354",
    WardLookupId: "2569",
    VDNumber: "32951202",
    RegisteredPopulation: "1775",
    VotingStationName: "F.F. RIBEIRO PRIMARY SCHOOL",
    Address: "26585 SHABANGU STREET  MAMELODI WEST  PRETORIA",
    Latitude: "-25.7144",
    Longitude: "28.3441",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8355",
    WardLookupId: "2569",
    VDNumber: "32951369",
    RegisteredPopulation: "1538",
    VotingStationName: "EVANGELICAL CHURCH",
    Address: "19247 SECTION M 19247(D1)  MAMELODI WEST  TSHWANE",
    Latitude: "-25.7069",
    Longitude: "28.3567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8356",
    WardLookupId: "2569",
    VDNumber: "32951392",
    RegisteredPopulation: "2164",
    VotingStationName: "NDIMA PRIMARY SCHOOL",
    Address: "2947 2947 SIBANDE STR SECTION J  MAMELODI WEST 0122  TSHWANE",
    Latitude: "-25.7043",
    Longitude: "28.3489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8357",
    WardLookupId: "2569",
    VDNumber: "32951404",
    RegisteredPopulation: "1368",
    VotingStationName: "LUTHERAN CHURCH",
    Address: "3407 3407 MASETHE STREET BLOCK K  MAMELODI WEST 0122  TSHWANE",
    Latitude: "-25.7055",
    Longitude: "28.3449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8358",
    WardLookupId: "2569",
    VDNumber: "32951437",
    RegisteredPopulation: "1923",
    VotingStationName: "J KEKANA SECONDARY SCHOOL",
    Address: "6607 6607 SEBOPA STR  MAMELODI WEST  TSHWANE",
    Latitude: "-25.7078",
    Longitude: "28.3518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8359",
    WardLookupId: "2569",
    VDNumber: "32951448",
    RegisteredPopulation: "2395",
    VotingStationName: "PHELADI-NAKENE PRIMARY SCHOOL",
    Address: "6094 6094 SECTION Q, MOFOKENG STR  MAMELODI WEST 0122  TSHWANE",
    Latitude: "-25.7115",
    Longitude: "28.3555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8360",
    WardLookupId: "2569",
    VDNumber: "32952652",
    RegisteredPopulation: "743",
    VotingStationName: "MAMELODI WEST COMMUNITY HALL",
    Address: "KUBONE AND MPHAKI  MAMELODI WEST  PRETORIA",
    Latitude: "-25.7112",
    Longitude: "28.3475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8361",
    WardLookupId: "2570",
    VDNumber: "32950133",
    RegisteredPopulation: "4100",
    VotingStationName: "LOTUS GARDENS SECONDARY SCHOOL",
    Address: "809 CYME CRESCENT  LOTUS GARDENS  PRETORIA",
    Latitude: "-25.7479",
    Longitude: "28.0897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8362",
    WardLookupId: "2570",
    VDNumber: "32952225",
    RegisteredPopulation: "4980",
    VotingStationName: "FUSION SECONDARY SCHOOL",
    Address: "5898 CHRIS HANI STREET  LOTUS GARDENS EXT. 2  PRETORIA",
    Latitude: "-25.7505",
    Longitude: "28.0774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8363",
    WardLookupId: "2570",
    VDNumber: "32952663",
    RegisteredPopulation: "3079",
    VotingStationName: "BAGALE PRIMARY SCHOOL",
    Address: "3839 CNR ROLIHLAHLA & OLIVER STREET  LOTUS GARDENS  PRETORIA",
    Latitude: "-25.7529",
    Longitude: "28.0668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8364",
    WardLookupId: "2570",
    VDNumber: "32952674",
    RegisteredPopulation: "1854",
    VotingStationName: "TENT - JALAPENO STREET",
    Address: "00 JALAPENO STREET  LOTUS GARDENS  PRETORIA",
    Latitude: "-25.7476",
    Longitude: "28.0965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8365",
    WardLookupId: "2570",
    VDNumber: "33280040",
    RegisteredPopulation: "619",
    VotingStationName: "LAERSKOOL BROEDERSTROOM",
    Address: "33 PRIMULA STR  FLORA PARK  IFAFI",
    Latitude: "-25.7681",
    Longitude: "27.9592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8366",
    WardLookupId: "2570",
    VDNumber: "33280130",
    RegisteredPopulation: "642",
    VotingStationName: "TENT- OPEN SPACE OPPOSITE FAMILY SUPERMARKET EXT19",
    Address: "STAND 564 EXTENTION 19  ATTERIDGEVILLE WEST  PRETORIA",
    Latitude: "-25.775",
    Longitude: "28.0329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8367",
    WardLookupId: "2571",
    VDNumber: "86661030",
    RegisteredPopulation: "1437",
    VotingStationName: "DILOPYE PRIMARY SCHOOL",
    Address: "STAND 146 DILOPYE VILLAGE OPP COMMUNITY CLINIC  DILOPYE  DILOPYE",
    Latitude: "-25.383",
    Longitude: "28.1932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8368",
    WardLookupId: "2571",
    VDNumber: "86661041",
    RegisteredPopulation: "1220",
    VotingStationName: "PHALESANE PRIMARY SCHOOL",
    Address: "SUURMAN  ",
    Latitude: "-25.3784",
    Longitude: "28.2084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8369",
    WardLookupId: "2571",
    VDNumber: "86661074",
    RegisteredPopulation: "802",
    VotingStationName: "TIRANI BASADI CENTER",
    Address: "235 SECTION 3 SUURMAN  TEMBA  TSHWANE",
    Latitude: "-25.3917",
    Longitude: "28.2082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8370",
    WardLookupId: "2571",
    VDNumber: "86661085",
    RegisteredPopulation: "1433",
    VotingStationName: "MODITELA MIDDLE SCHOOL",
    Address: "1205 LEPHENGVILLE MAJANENG  MAJANENG  TEMBA",
    Latitude: "-25.3714",
    Longitude: "28.2222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8371",
    WardLookupId: "2571",
    VDNumber: "86661108",
    RegisteredPopulation: "1972",
    VotingStationName: "SUURMAN  COMMUNITY HALL",
    Address: "1705 SUURMAN  TEMBA",
    Latitude: "-25.3876",
    Longitude: "28.2258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8372",
    WardLookupId: "2571",
    VDNumber: "86663537",
    RegisteredPopulation: "234",
    VotingStationName: "THUTO KE LESEDI DAY CARE",
    Address: "525 SUURMAN VILLAGE  TEMBA  SUURMAN",
    Latitude: "-25.3998",
    Longitude: "28.2245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8373",
    WardLookupId: "2571",
    VDNumber: "86720058",
    RegisteredPopulation: "1618",
    VotingStationName: "SEKAMPANENG PRIMARY SCHOOL",
    Address: "SEKAMPANENG VILLAGE  TEMBA  TEMBA",
    Latitude: "-25.3976",
    Longitude: "28.2342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8374",
    WardLookupId: "2571",
    VDNumber: "86720069",
    RegisteredPopulation: "3188",
    VotingStationName: "MASAKHANE PRIMARY SCHOOL",
    Address: "SEKAMPANENG VILLAGE  SEKAMPANENG  TEMBA",
    Latitude: "-25.3869",
    Longitude: "28.2375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8375",
    WardLookupId: "2571",
    VDNumber: "86720160",
    RegisteredPopulation: "1040",
    VotingStationName: "SEKAMPANENG HALL",
    Address: "873401 BLOCK B  SEKAMPANENG  TEMBA",
    Latitude: "-25.3909",
    Longitude: "28.2332",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8376",
    WardLookupId: "2571",
    VDNumber: "86720182",
    RegisteredPopulation: "271",
    VotingStationName: "TENT- OPEN SPACE EXT1 OPP TRAFFIC DEPT.",
    Address: "1 EXTENTION 1  TEMBA EXT 1  TEMBA",
    Latitude: "-25.3884",
    Longitude: "28.2518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8377",
    WardLookupId: "2572",
    VDNumber: "86730026",
    RegisteredPopulation: "2032",
    VotingStationName: "REFALOTSE PRIMARY SCHOOL",
    Address: "P.B 532 WINTERVELD  TSHWANE",
    Latitude: "-25.4862",
    Longitude: "28.0742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8378",
    WardLookupId: "2572",
    VDNumber: "86730048",
    RegisteredPopulation: "2670",
    VotingStationName: "TENT-CITY ROCK",
    Address: "PLOT 1559 MABENA STAND  WINTERVELDT  WINTERVELT",
    Latitude: "-25.4799",
    Longitude: "28.0699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8379",
    WardLookupId: "2572",
    VDNumber: "86730071",
    RegisteredPopulation: "1977",
    VotingStationName: "GALEBOE MIDDLE SCHOOL",
    Address: "MOTSHOANE STAND  WINTERVELDT  TSHWANE",
    Latitude: "-25.4662",
    Longitude: "28.0641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8380",
    WardLookupId: "2572",
    VDNumber: "86730116",
    RegisteredPopulation: "2177",
    VotingStationName: "MONTSHO PRIMARY SCHOOL",
    Address: "PLOT NO 1572  WINTERVELDT  WINTERVELDT",
    Latitude: "-25.4694",
    Longitude: "28.0717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8381",
    WardLookupId: "2572",
    VDNumber: "86730127",
    RegisteredPopulation: "1344",
    VotingStationName: "REV MAKUNYANE CHURCH",
    Address: "1618 WINTERVELDT  WINTERVELD",
    Latitude: "-25.4899",
    Longitude: "28.0725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8382",
    WardLookupId: "2572",
    VDNumber: "86730161",
    RegisteredPopulation: "1570",
    VotingStationName: "JESUS CHRIST OUR REST MINISTRIES",
    Address: "1624 KHOZA STAND  WINTERVELDT  PRETORIA",
    Latitude: "-25.4857",
    Longitude: "28.069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8383",
    WardLookupId: "2572",
    VDNumber: "86730240",
    RegisteredPopulation: "1422",
    VotingStationName: "OLD ASSEMBLIES OF GOD OF AFRICA",
    Address: "1426 NCUBE STAND  WINTERVELDT  WINTERVELDT",
    Latitude: "-25.4607",
    Longitude: "28.0773",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8384",
    WardLookupId: "2573",
    VDNumber: "32951729",
    RegisteredPopulation: "3120",
    VotingStationName: "THE GRACE OF THE LIVING GOD MINISTRIES",
    Address: "120 MNISI  MAMELODI EAST  PRETORIA",
    Latitude: "-25.7317",
    Longitude: "28.4359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8385",
    WardLookupId: "2573",
    VDNumber: "32952102",
    RegisteredPopulation: "2551",
    VotingStationName: "SEDIBA SA THUTO PRIMARY SCHOOL",
    Address: "CNR JUDA AND THAMSAQA STREET  MAMELODI EAST, RDP  PRETORIA",
    Latitude: "-25.7276",
    Longitude: "28.4339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8386",
    WardLookupId: "2573",
    VDNumber: "32952113",
    RegisteredPopulation: "3165",
    VotingStationName: "REPHAFUGILE SECONDARY SCHOOL",
    Address: "100 MILENYANE TSELE  LUSAKA MAMELODI EAST  TSHWANE",
    Latitude: "-25.7208",
    Longitude: "28.4342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8387",
    WardLookupId: "2573",
    VDNumber: "32952371",
    RegisteredPopulation: "1725",
    VotingStationName: "VIVA VILLAGE COMMUNITY CENTRE",
    Address:
      "371 MOSHUMI STREET, LUSAKA VILLAGE  MAMELODI EAST, EXT 22   PRETORIA",
    Latitude: "-25.7322",
    Longitude: "28.4448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8388",
    WardLookupId: "2573",
    VDNumber: "32952685",
    RegisteredPopulation: "1694",
    VotingStationName: "TENT AT DIAMOND CASH AND CARRY",
    Address: "145 KGAGARA AVENUE  MAMELODI EAST EXT 11  PRETORIA",
    Latitude: "-25.7255",
    Longitude: "28.4269",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8389",
    WardLookupId: "2573",
    VDNumber: "32952696",
    RegisteredPopulation: "1932",
    VotingStationName: "CALVARY CHRIST MISSION - CHURCH",
    Address: "04 RAMPSA STREET  MEMELODI EAST -LUSAKA EXT 22  PRETORIA",
    Latitude: "-25.7179",
    Longitude: "28.4354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8390",
    WardLookupId: "2573",
    VDNumber: "32952708",
    RegisteredPopulation: "720",
    VotingStationName: "GREAT AND MIGHTY GOD CHURCH",
    Address: "19 CNR MANDLA MLANDU AVENUE  MAMELODI EAST EXT 18  PRETORIA",
    Latitude: "-25.7307",
    Longitude: "28.4407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8391",
    WardLookupId: "2573",
    VDNumber: "32953158",
    RegisteredPopulation: "917",
    VotingStationName: "THE CHURCH OF JESUS CHRIST",
    Address: "253 KGAGARA  MAMELODI EAST, RDP  PRETORIA",
    Latitude: "-25.7338",
    Longitude: "28.4273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8392",
    WardLookupId: "2573",
    VDNumber: "32953237",
    RegisteredPopulation: "620",
    VotingStationName: "TENT- RDP CHISANYAMA",
    Address: "CNR MNISI AND RAMUTLOA AVENUE  MAMELODI EAST EXT 18  PRETORIA",
    Latitude: "-25.7317",
    Longitude: "28.4314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8393",
    WardLookupId: "2573",
    VDNumber: "32953248",
    RegisteredPopulation: "504",
    VotingStationName: "IMPENDULO PRIMARY SCHOOL",
    Address: "185 LETSOGO STREET  MAMELODI EAST EXT 22  PRETORIA",
    Latitude: "-25.7238",
    Longitude: "28.4378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8394",
    WardLookupId: "2574",
    VDNumber: "32960011",
    RegisteredPopulation: "2295",
    VotingStationName: "AFRICAN GOSPEL CHURCH",
    Address: "NDLOZI PP2  SOSHANGUVE BLOCK PP2",
    Latitude: "-25.4475",
    Longitude: "28.0941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8395",
    WardLookupId: "2574",
    VDNumber: "32960280",
    RegisteredPopulation: "3206",
    VotingStationName: "PHAKAMONOLA PRIMARY SCHOOL",
    Address: "194 BLOCK LL  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4651",
    Longitude: "28.0842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8396",
    WardLookupId: "2574",
    VDNumber: "32960336",
    RegisteredPopulation: "2129",
    VotingStationName: "RIVONINGO PRIMARY SCHOOL",
    Address: "971 BLOCK JJ  SOSHANGUVE  ",
    Latitude: "-25.4569",
    Longitude: "28.0828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8397",
    WardLookupId: "2574",
    VDNumber: "32960369",
    RegisteredPopulation: "1784",
    VotingStationName: "MAKHOSINI SECONDARY SCHOOL",
    Address: "523 BLOCK HH  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4487",
    Longitude: "28.09",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8398",
    WardLookupId: "2574",
    VDNumber: "32960370",
    RegisteredPopulation: "2638",
    VotingStationName: "MAFUMBUKA PRIMARY SCHOOL",
    Address: "21 BLOCK HH  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4493",
    Longitude: "28.0849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8399",
    WardLookupId: "2574",
    VDNumber: "32960561",
    RegisteredPopulation: "1625",
    VotingStationName: "MORORISENG PRE-SCHOOL",
    Address: "507 PP1 SECTION   APLA SECTION   SOSHANGUVE",
    Latitude: "-25.4413",
    Longitude: "28.0787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8400",
    WardLookupId: "2574",
    VDNumber: "32961180",
    RegisteredPopulation: "459",
    VotingStationName: "KINGDOM AMBASSADOR MINISTRIES",
    Address: "755 BLOCK PP2   BLOCK PP2   SOSHANGUVE",
    Latitude: "-25.4417",
    Longitude: "28.0905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8401",
    WardLookupId: "2575",
    VDNumber: "86700090",
    RegisteredPopulation: "1766",
    VotingStationName: "MABOPANE HIGH SCHOOL",
    Address: "UNIT A  MABOPANE  TSHWANE",
    Latitude: "-25.5012",
    Longitude: "28.0608",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8402",
    WardLookupId: "2575",
    VDNumber: "86700168",
    RegisteredPopulation: "1912",
    VotingStationName: "LETLOTLO MIDDLE SCHOOL",
    Address: "BLOCK B  MABOPANE  TSHWANE",
    Latitude: "-25.5028",
    Longitude: "28.0732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8403",
    WardLookupId: "2575",
    VDNumber: "86700270",
    RegisteredPopulation: "2101",
    VotingStationName: "SHOSHANGAAN PRIMARY SCHOOL",
    Address: "BLOCK B  SHOSHANGAAN  TSHWANE",
    Latitude: "-25.5007",
    Longitude: "28.0682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8404",
    WardLookupId: "2575",
    VDNumber: "86730037",
    RegisteredPopulation: "1798",
    VotingStationName: "MANAMELONG PRIMARY SCHOOL",
    Address: "P.B508 WINTERVELDT  TSHWANE",
    Latitude: "-25.4912",
    Longitude: "28.0717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8405",
    WardLookupId: "2575",
    VDNumber: "86730217",
    RegisteredPopulation: "2729",
    VotingStationName: "NGAKA MASEKO HIGH SCHOOL",
    Address: "966 PHASE 1  LEBANON  WINTERVELDT",
    Latitude: "-25.4902",
    Longitude: "28.0503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8406",
    WardLookupId: "2575",
    VDNumber: "86730273",
    RegisteredPopulation: "1161",
    VotingStationName: "EDWIN MOALUSI PRIMARY SCHOOL",
    Address: "1602 BEIRUT  MABOPANE  TSHWANE METRO [PRETORIA]",
    Latitude: "-25.4921",
    Longitude: "28.0593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8407",
    WardLookupId: "2576",
    VDNumber: "32960662",
    RegisteredPopulation: "1111",
    VotingStationName: "OFFICE OPPOSITE TOM`S PLACE",
    Address: "353 SOUTPAN   SOUTPAN , SOSHANGUVE NORTH  TEMBA",
    Latitude: "-25.4103",
    Longitude: "28.1044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8408",
    WardLookupId: "2576",
    VDNumber: "32960954",
    RegisteredPopulation: "1210",
    VotingStationName: "REVIVAL CHURCH",
    Address: "1102 EXTENTION V  TEMBA  EXTENTION V",
    Latitude: "-25.4359",
    Longitude: "28.1153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8409",
    WardLookupId: "2576",
    VDNumber: "32961102",
    RegisteredPopulation: "379",
    VotingStationName: "ST ATHANUSIUS SCHOOL",
    Address: "1104 MARIKANA VILLAGE  SOUTPAN , MARIKANA  TEMBA",
    Latitude: "-25.4263",
    Longitude: "28.1178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8410",
    WardLookupId: "2576",
    VDNumber: "32961113",
    RegisteredPopulation: "265",
    VotingStationName: "KEITUMETSE PRE SCHOOL",
    Address: "153 MARIKANA  M3  MARIKANA VILLAGE   TEMBA",
    Latitude: "-25.4252",
    Longitude: "28.1114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8411",
    WardLookupId: "2576",
    VDNumber: "32961124",
    RegisteredPopulation: "222",
    VotingStationName: "KOPANO NKOKETSE DAY CARE",
    Address: "MARIKANA VILLAGE   TEMBA   SOUTPAN EXT",
    Latitude: "-25.428",
    Longitude: "28.105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8412",
    WardLookupId: "2576",
    VDNumber: "32961135",
    RegisteredPopulation: "492",
    VotingStationName: "FEEDING SCHEME.CENTER",
    Address: "MARIKANA  TEMBA  MARIKANA  SOUTPAN",
    Latitude: "-25.421",
    Longitude: "28.1091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8413",
    WardLookupId: "2576",
    VDNumber: "32961146",
    RegisteredPopulation: "323",
    VotingStationName: "ANNOINTING APOSTOLIC CHURCH",
    Address: "1618 MARIKANA M2  MARIKANA VILLAGE   TEMBA",
    Latitude: "-25.4168",
    Longitude: "28.1087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8414",
    WardLookupId: "2576",
    VDNumber: "32961157",
    RegisteredPopulation: "517",
    VotingStationName: "NOAH`S ARK",
    Address: "18 MARIKANA M2  MARIKANA VILLAGE   TEMBA",
    Latitude: "-25.4122",
    Longitude: "28.1143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8415",
    WardLookupId: "2576",
    VDNumber: "86660905",
    RegisteredPopulation: "866",
    VotingStationName: "MODILATI SECONDARY SCHOOL",
    Address: "5509 REFENTSE   REFENTSE BLOCK  TEMBA",
    Latitude: "-25.3953",
    Longitude: "28.1556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8416",
    WardLookupId: "2576",
    VDNumber: "86660916",
    RegisteredPopulation: "1445",
    VotingStationName: "NAMO PRIMARY SCHOOL",
    Address: "2531 TRUSTFARM , REFENTSE  STINKWATER  TEMBA",
    Latitude: "-25.3902",
    Longitude: "28.1624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8417",
    WardLookupId: "2576",
    VDNumber: "86660983",
    RegisteredPopulation: "1818",
    VotingStationName: "SUN RISE DAY CARE",
    Address: "4559 REFENTSE BLOCK  REFENTSE   TEMBA",
    Latitude: "-25.3951",
    Longitude: "28.1766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8418",
    WardLookupId: "2576",
    VDNumber: "86661018",
    RegisteredPopulation: "1978",
    VotingStationName: "FATLHOGANG PRIMARY SCHOOL",
    Address: "4832 REFENTSE   STINKWATER  TEMBA",
    Latitude: "-25.3959",
    Longitude: "28.1697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8419",
    WardLookupId: "2576",
    VDNumber: "86662390",
    RegisteredPopulation: "1716",
    VotingStationName: "MOTJIBOSANE PRIMARY SCHOOL",
    Address: "2095 REFENTSE EXT  STINKWATER  TEMBA",
    Latitude: "-25.391",
    Longitude: "28.1777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8420",
    WardLookupId: "2576",
    VDNumber: "86663201",
    RegisteredPopulation: "1071",
    VotingStationName: "REFENTSE P.SCHOOL",
    Address: "NO1 REFENTSE EXT  STINKWATER  TEMBA",
    Latitude: "-25.3796",
    Longitude: "28.1717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8421",
    WardLookupId: "2576",
    VDNumber: "86663515",
    RegisteredPopulation: "123",
    VotingStationName: "NTSWANE SECONDARY SCHOOL",
    Address: "495 DILOPYE VILLAGE  DILOPYE  TEMBA",
    Latitude: "-25.3922",
    Longitude: "28.1886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8422",
    WardLookupId: "2577",
    VDNumber: "86660804",
    RegisteredPopulation: "1623",
    VotingStationName: "MOLONDOLODZI PRE-SCHOOL",
    Address: "778 BLOCK F2  NEW EERSTERUS  TEMBA",
    Latitude: "-25.4148",
    Longitude: "28.125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8423",
    WardLookupId: "2577",
    VDNumber: "86660815",
    RegisteredPopulation: "1716",
    VotingStationName: "RAPELEGO PRIMARY SCHOOL",
    Address: "726 BLOCK F1   EERSTERUS  TEMBA",
    Latitude: "-25.4111",
    Longitude: "28.1189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8424",
    WardLookupId: "2577",
    VDNumber: "86660826",
    RegisteredPopulation: "1530",
    VotingStationName: "MATHIBE STORE",
    Address: "2029 BLOCK C  EERSTERUST  TEMBA",
    Latitude: "-25.4041",
    Longitude: "28.134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8425",
    WardLookupId: "2577",
    VDNumber: "86660837",
    RegisteredPopulation: "1775",
    VotingStationName: "MMATSO PRIMARY SCHOOL",
    Address: "2168 NEW EERSTERUS  EERSTERUST  TEMBA",
    Latitude: "-25.4086",
    Longitude: "28.1315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8426",
    WardLookupId: "2577",
    VDNumber: "86660859",
    RegisteredPopulation: "2406",
    VotingStationName: "RAMABELE JUNIOR SECONDARY SCHOOL",
    Address: "2287 BLOCK C  EERSTERUS  TEMBA",
    Latitude: "-25.4143",
    Longitude: "28.1389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8427",
    WardLookupId: "2577",
    VDNumber: "86660860",
    RegisteredPopulation: "824",
    VotingStationName: "KGOMBA PRIMARY SCHOOL",
    Address: "2623 BLOCK D  NEW EERSTERUS  TEMBA",
    Latitude: "-25.4103",
    Longitude: "28.1338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8428",
    WardLookupId: "2577",
    VDNumber: "86660871",
    RegisteredPopulation: "1035",
    VotingStationName: "TENT - OPPOSITE OLD CLINIC",
    Address: "2889 EERSTERUS   TEMBA  TEMBA",
    Latitude: "-25.405",
    Longitude: "28.1405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8429",
    WardLookupId: "2577",
    VDNumber: "86660882",
    RegisteredPopulation: "473",
    VotingStationName: "PALE BRICK WORKS",
    Address: "2960 BLOCK A  EERSTERUS  TEMBA",
    Latitude: "-25.3977",
    Longitude: "28.1489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8430",
    WardLookupId: "2577",
    VDNumber: "86662884",
    RegisteredPopulation: "1863",
    VotingStationName: "KERUSSO BIBLE MISSION",
    Address: "6147 F4 NEW EERTERUS  EERSTERUS  TEMBA",
    Latitude: "-25.4319",
    Longitude: "28.1273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8431",
    WardLookupId: "2578",
    VDNumber: "32951505",
    RegisteredPopulation: "3049",
    VotingStationName: "N WA - VANGANI PRIMARY SCHOOL",
    Address: "17986 MOTIMALENYORA STREET  MAMELODI EAST  PRETORIA",
    Latitude: "-25.723",
    Longitude: "28.3866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8432",
    WardLookupId: "2578",
    VDNumber: "32951516",
    RegisteredPopulation: "2706",
    VotingStationName: "IKAGENG COMMUNITY CENTRE",
    Address: "IKAGENG, EXTENTION 3, MAMELODI EAST  PRETORIA",
    Latitude: "-25.726",
    Longitude: "28.3976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8433",
    WardLookupId: "2578",
    VDNumber: "32951527",
    RegisteredPopulation: "2049",
    VotingStationName: "ST JOHN APOSTOLIC FAITH MISSION",
    Address: "ERF 17979  MAMELODI EAST  PRETORIA",
    Latitude: "-25.7259",
    Longitude: "28.3904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8434",
    WardLookupId: "2578",
    VDNumber: "32952337",
    RegisteredPopulation: "3702",
    VotingStationName: "NELLMAPIUS PRIMARY SCHOOL EXT 6",
    Address: "119 KOPORO CRESCENT  NELLMAPIUS EXT 6  PRETORIA",
    Latitude: "-25.7332",
    Longitude: "28.3768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8435",
    WardLookupId: "2578",
    VDNumber: "32952449",
    RegisteredPopulation: "820",
    VotingStationName: "TSHEGOFATSONG SPECIAL SCHOOL",
    Address:
      "20853 NETSHIBUPFE NKUNA STREET  MAMELODI EAST, BUFFERZONE  PRETORIA",
    Latitude: "-25.726",
    Longitude: "28.3862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8436",
    WardLookupId: "2578",
    VDNumber: "32952810",
    RegisteredPopulation: "3183",
    VotingStationName: "THUTO BOHLALE SECONDARY SCHOOL",
    Address: "7945 LEHLWA STREET  NELLMAPIUS EXT 7  PRETORIA",
    Latitude: "-25.7336",
    Longitude: "28.3834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8437",
    WardLookupId: "2578",
    VDNumber: "32952821",
    RegisteredPopulation: "2130",
    VotingStationName: "TENT-NELLMAPIUS EXT 21 SPORTS GROUND",
    Address: "EERSTEFABRIEK STATION  NELLMAPIUS EXT 21  PRETORIA",
    Latitude: "-25.724",
    Longitude: "28.3627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8438",
    WardLookupId: "2578",
    VDNumber: "32953259",
    RegisteredPopulation: "290",
    VotingStationName: "TENT- HEATHERLEY VILLAGE",
    Address: "HEATHERLEY VILLAGE  NELLMAPIUS EXT 22  PRETORIA",
    Latitude: "-25.7448",
    Longitude: "28.4056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8439",
    WardLookupId: "2578",
    VDNumber: "32953260",
    RegisteredPopulation: "622",
    VotingStationName: "TENT- NELLMAPIUS EXT 22 FORMAL HOUSES",
    Address: "HEATHERLEY DISTRICT  NELLMAPIUS EXT 22  PRETORIA",
    Latitude: "-25.7416",
    Longitude: "28.3929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8440",
    WardLookupId: "2578",
    VDNumber: "32953271",
    RegisteredPopulation: "647",
    VotingStationName: "FIRE REVIVAL COMMUNITY CHURCH",
    Address: "UMANGO AND INTSHATSHELI STREET  NELLMAPIUS EXT 24  PRETORIA",
    Latitude: "-25.7316",
    Longitude: "28.395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8441",
    WardLookupId: "2578",
    VDNumber: "32953282",
    RegisteredPopulation: "734",
    VotingStationName: "TENT- NELLMAPIUS EXT 24 OPEN SPACE SHOW HOUSE",
    Address: "MARIKANA VILLAGE  NELLMAPIUS EXT 24  PRETORIA",
    Latitude: "-25.7292",
    Longitude: "28.3792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8442",
    WardLookupId: "2579",
    VDNumber: "32951246",
    RegisteredPopulation: "2014",
    VotingStationName: "GATANG SCHOOL",
    Address: "23423 HINTERLAND STREET  MAMELODI GARDENS  PRETORIA",
    Latitude: "-25.7229",
    Longitude: "28.3957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8443",
    WardLookupId: "2579",
    VDNumber: "32951257",
    RegisteredPopulation: "2292",
    VotingStationName: "LEHLABILE SCHOOL",
    Address: "136 BROADWAY STREET  MAMELODI GARDENS EXT2  PRETORIA",
    Latitude: "-25.7127",
    Longitude: "28.3899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8444",
    WardLookupId: "2579",
    VDNumber: "32951730",
    RegisteredPopulation: "2152",
    VotingStationName: "BAJABULILE PRIMARY SCHOOL",
    Address: "22892 MOTSUMI STREET  MAMELODI EAST  PRETORIA",
    Latitude: "-25.7154",
    Longitude: "28.3929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8445",
    WardLookupId: "2579",
    VDNumber: "32951741",
    RegisteredPopulation: "2197",
    VotingStationName: "STANZA BOPAPE COMMUNITY CENTRE",
    Address: "27401 RAMMAPUDU STREET  MAMELODI EAST  PRETORIA",
    Latitude: "-25.706",
    Longitude: "28.401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8446",
    WardLookupId: "2579",
    VDNumber: "32952135",
    RegisteredPopulation: "2406",
    VotingStationName: "RAMAHLALE PRIMARY SCHOOL",
    Address: "28790 BUTHELEZI STREET  MAMELODI EAST, EXT 5  PRETORIA",
    Latitude: "-25.7112",
    Longitude: "28.4041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8447",
    WardLookupId: "2579",
    VDNumber: "32952719",
    RegisteredPopulation: "994",
    VotingStationName: "EXT 5 COMMUNITY LIBRARY",
    Address: "109 MARISHANE STREET  MAMELODI EAST  PRETORIA",
    Latitude: "-25.7138",
    Longitude: "28.4009",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8448",
    WardLookupId: "2579",
    VDNumber: "32952720",
    RegisteredPopulation: "1621",
    VotingStationName: "BULADIKGORO PRIMARY SCHOOL",
    Address: "27400 RAMMAPUDU STR  MAMELODI EAST, EXT 5  PRETORIA",
    Latitude: "-25.7042",
    Longitude: "28.3984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8449",
    WardLookupId: "2580",
    VDNumber: "32952001",
    RegisteredPopulation: "2916",
    VotingStationName: "UNITING REFORMED CHURCH",
    Address: "30911 ISIDINGO ROAD  MAMELODI EAST, PHASE 5  PRETORIA",
    Latitude: "-25.7039",
    Longitude: "28.407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8450",
    WardLookupId: "2580",
    VDNumber: "32952012",
    RegisteredPopulation: "3553",
    VotingStationName: "BONALESEDI SCHOOL",
    Address: "415 LEKGANYANE ST  MAHUBE VILLAGE MAMELODI WEST  TSHWANE",
    Latitude: "-25.703",
    Longitude: "28.4125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8451",
    WardLookupId: "2580",
    VDNumber: "32952168",
    RegisteredPopulation: "2993",
    VotingStationName: "EMASANGWENI PRIMARY SCHOOL.",
    Address: "256 STANZA BOPAPE  MAMELODI EAST  PRETORIA",
    Latitude: "-25.7083",
    Longitude: "28.4104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8452",
    WardLookupId: "2580",
    VDNumber: "32952416",
    RegisteredPopulation: "1337",
    VotingStationName: "AFRICAN FAITH MISSION STOFFEL PARK",
    Address: "STOFFEL PARK  MAMELODI EAST, SOFFEL PARK  PRETORIA",
    Latitude: "-25.7047",
    Longitude: "28.4319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8453",
    WardLookupId: "2580",
    VDNumber: "32952731",
    RegisteredPopulation: "1105",
    VotingStationName: "FEEDER MISSION CHURCH",
    Address: "133 SS MOKONE  MAHUBE VALLEY  PRETORIA",
    Latitude: "-25.7003",
    Longitude: "28.4167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8454",
    WardLookupId: "2580",
    VDNumber: "32952742",
    RegisteredPopulation: "801",
    VotingStationName: "KIDS KINGDOM DAY CARE",
    Address:
      "09 LP BAMBO STREET  MAMELODI EAST, MAHUBE VALLEY, EXT 2  PRETORIA",
    Latitude: "-25.7033",
    Longitude: "28.4145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8455",
    WardLookupId: "2580",
    VDNumber: "32952753",
    RegisteredPopulation: "971",
    VotingStationName: "MOUNTAIN OF GLORY CHURCH",
    Address: "STOFFEL PARK  MAMELODI EAST  PRETORIA",
    Latitude: "-25.6992",
    Longitude: "28.4273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8456",
    WardLookupId: "2581",
    VDNumber: "32951190",
    RegisteredPopulation: "1529",
    VotingStationName: "KOOS MATLI SCHOOL",
    Address: "15671 MALINDI STREET  MAMELODI EAST  PRETORIA",
    Latitude: "-25.7077",
    Longitude: "28.3914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8457",
    WardLookupId: "2581",
    VDNumber: "32951493",
    RegisteredPopulation: "1612",
    VotingStationName: "PHATENG COMPREHENSIVE SCHOOL",
    Address: "20202 SERAPENG AVE  MAMELODI EAST, BUFFER ZONE  PRETORIA",
    Latitude: "-25.7213",
    Longitude: "28.3734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8458",
    WardLookupId: "2581",
    VDNumber: "32951538",
    RegisteredPopulation: "1368",
    VotingStationName: "RIBANE LAKA SECONDARY SCHOOL",
    Address: "19558 MASEKO STREET  MAMELODI EAST 0122  PRETORIA",
    Latitude: "-25.7175",
    Longitude: "28.3854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8459",
    WardLookupId: "2581",
    VDNumber: "32951549",
    RegisteredPopulation: "1182",
    VotingStationName: "SIKHANYISELE PRIMARY SCHOOL",
    Address: "LETSWALO & MPUNZI STREET  MAMELODI EAST 0122  TSHWANE",
    Latitude: "-25.7145",
    Longitude: "28.3871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8460",
    WardLookupId: "2581",
    VDNumber: "32951550",
    RegisteredPopulation: "2185",
    VotingStationName: "CHARITY AND FAITH MISSION",
    Address: "19537 19537 KHUTSONG EXT  MAMELODI EAST 0122  TSHWANE",
    Latitude: "-25.7164",
    Longitude: "28.3791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8461",
    WardLookupId: "2581",
    VDNumber: "32951561",
    RegisteredPopulation: "1953",
    VotingStationName: "SOLOMON MAHLANGU FREEDOM SCHOOL",
    Address: "63 MATHIBE LEDWABA  MAMELODI EAST, SECTION 16  PRETORIA",
    Latitude: "-25.7196",
    Longitude: "28.385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8462",
    WardLookupId: "2581",
    VDNumber: "32951998",
    RegisteredPopulation: "2291",
    VotingStationName: "UOANE PRIMARY SCHOOL",
    Address: "14848 RATSOMA STREET  MAMELODI EAST  PRETORIA",
    Latitude: "-25.7098",
    Longitude: "28.3861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8463",
    WardLookupId: "2581",
    VDNumber: "32952764",
    RegisteredPopulation: "685",
    VotingStationName: "THE METHODIST CHURCH OF SOUTH AFRICA",
    Address: "41 M MTHETWA & IKONJANE STREET  MAMELODI GARDENS  PRETORIA",
    Latitude: "-25.7193",
    Longitude: "28.3893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8464",
    WardLookupId: "2582",
    VDNumber: "86730059",
    RegisteredPopulation: "3499",
    VotingStationName: "ABEL MOTSHWANE HIGH SCHOOL",
    Address: "2788 ROLIHLAHLA AVE  SLOVOVILLE  WINTERVELDT",
    Latitude: "-25.4807",
    Longitude: "28.0439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8465",
    WardLookupId: "2582",
    VDNumber: "86730082",
    RegisteredPopulation: "2027",
    VotingStationName: "TLHABOLOGO AET CENTRE",
    Address: "PLOT 1480 MAKHIBIDU STAND  WINTERVELD  TSHWANE",
    Latitude: "-25.4711",
    Longitude: "28.0582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8466",
    WardLookupId: "2582",
    VDNumber: "86730093",
    RegisteredPopulation: "1284",
    VotingStationName: "TENT- NOBELA BUSINESS COMPLEX",
    Address: "1514  W/VELD  WINTERVELDT",
    Latitude: "-25.4791",
    Longitude: "28.0567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8467",
    WardLookupId: "2582",
    VDNumber: "86730150",
    RegisteredPopulation: "1427",
    VotingStationName: "ST PETER CATHOLIC CHURCH",
    Address: "WINTERVELDT  ",
    Latitude: "-25.4792",
    Longitude: "28.0637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8468",
    WardLookupId: "2582",
    VDNumber: "86730172",
    RegisteredPopulation: "2016",
    VotingStationName: "TENT- KHOZA STAND",
    Address: "1520 KHOZA STAND  WINTERVELD  WINTERVELDT",
    Latitude: "-25.4862",
    Longitude: "28.0607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8469",
    WardLookupId: "2582",
    VDNumber: "86730228",
    RegisteredPopulation: "2443",
    VotingStationName: "TIDIMALONG PRIMARY SCHOOL",
    Address: "1656 WINTERVELDT  MABOPANE  W/VELD",
    Latitude: "-25.479",
    Longitude: "28.0503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8470",
    WardLookupId: "2582",
    VDNumber: "86730284",
    RegisteredPopulation: "842",
    VotingStationName: "IKELENG PRIMARY SCHOOL",
    Address: "138 LEBANON  MABOPANE  TSHWANE METRO [PRETORIA]",
    Latitude: "-25.4857",
    Longitude: "28.052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8471",
    WardLookupId: "2582",
    VDNumber: "86730295",
    RegisteredPopulation: "1273",
    VotingStationName: "FR SMANGALISO MKHASHWA HIGH SCHOOL",
    Address: "2660 PHASE 4,NTSAKO ROAD  SLOVOVILLE,MABOPANE  PRETORIA",
    Latitude: "-25.4743",
    Longitude: "28.0386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8472",
    WardLookupId: "2583",
    VDNumber: "86700012",
    RegisteredPopulation: "2837",
    VotingStationName: "TENT-MMAPHUTHI",
    Address: "2299 PHASE 1  ITSOSENG  MABOPANE",
    Latitude: "-25.5471",
    Longitude: "28.0631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8473",
    WardLookupId: "2583",
    VDNumber: "86700067",
    RegisteredPopulation: "2897",
    VotingStationName: "DR MOREOSELE HIGH SCHOOL",
    Address: "UNIT U  MABOPANE  MABOPANE",
    Latitude: "-25.507",
    Longitude: "28.0385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8474",
    WardLookupId: "2583",
    VDNumber: "86700113",
    RegisteredPopulation: "1500",
    VotingStationName: "PELOTONA SECONDARY SCHOOL",
    Address: "BLOCK C  MABOPANE  MABOPANE",
    Latitude: "-25.5168",
    Longitude: "28.0413",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8475",
    WardLookupId: "2583",
    VDNumber: "86700124",
    RegisteredPopulation: "2359",
    VotingStationName: "NONG PRIMARY SCHOOL",
    Address: "BLOCK C  MABOPANE  MABOPANE",
    Latitude: "-25.519",
    Longitude: "28.0561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8476",
    WardLookupId: "2583",
    VDNumber: "86700203",
    RegisteredPopulation: "1749",
    VotingStationName: "MATSHEDISO ABET CENTRE",
    Address: "46 UNIT D  MABOPANE  TSHWANE",
    Latitude: "-25.522",
    Longitude: "28.0663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8477",
    WardLookupId: "2583",
    VDNumber: "86700214",
    RegisteredPopulation: "2061",
    VotingStationName: "SUN VALLEY PRIMARY SCHOOL",
    Address: "UNIT S  MABOPANE  TSHWANE",
    Latitude: "-25.524",
    Longitude: "28.0796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8478",
    WardLookupId: "2583",
    VDNumber: "86700304",
    RegisteredPopulation: "831",
    VotingStationName: "TENT - ITSOSENG NEXT TO WHITE SHOP",
    Address: "ITSOSENG  MABOPANE  PRETORIA",
    Latitude: "-25.5432",
    Longitude: "28.0576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8479",
    WardLookupId: "2583",
    VDNumber: "86700315",
    RegisteredPopulation: "706",
    VotingStationName: "TENT- NEXT TO MKHONTO BRICKS.",
    Address: "ITSOSENG  MABOPANE  PRETORIA",
    Latitude: "-25.5413",
    Longitude: "28.0703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8480",
    WardLookupId: "2584",
    VDNumber: "86700023",
    RegisteredPopulation: "2302",
    VotingStationName: "BAFETI MIDDLE SCHOOL",
    Address: "2299 BLOCK B  MABOPANE  TSHWANE",
    Latitude: "-25.5122",
    Longitude: "28.0674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8481",
    WardLookupId: "2584",
    VDNumber: "86700034",
    RegisteredPopulation: "2355",
    VotingStationName: "SETUMO KHIBA SECONDARY SCHOOL",
    Address: "UNIT B  MABOPANE  TSHWANE",
    Latitude: "-25.5126",
    Longitude: "28.0753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8482",
    WardLookupId: "2584",
    VDNumber: "86700135",
    RegisteredPopulation: "2988",
    VotingStationName: "UNIT X REVENUE OFFICES",
    Address: "BLOCK X  MABOPANE  PRETORIA",
    Latitude: "-25.5137",
    Longitude: "28.0469",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8483",
    WardLookupId: "2584",
    VDNumber: "86700146",
    RegisteredPopulation: "2628",
    VotingStationName: "REINOTSWE SPECIAL SCHOOL",
    Address: "4444 UNIT B  MABOPANE  TSHWANE",
    Latitude: "-25.5097",
    Longitude: "28.0543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8484",
    WardLookupId: "2584",
    VDNumber: "86700247",
    RegisteredPopulation: "1772",
    VotingStationName: "MODISABANA PRE- SCHOOL",
    Address: "BLOCK X EXTENTION  MABOPANE  ",
    Latitude: "-25.5058",
    Longitude: "28.0472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8485",
    WardLookupId: "2584",
    VDNumber: "86700281",
    RegisteredPopulation: "1121",
    VotingStationName: "KOPADILALELO PRIMARY SCHOOL",
    Address: "2735 BLOCK B  MABOPANE  TSHWANE METRO [PRETORIA]",
    Latitude: "-25.5154",
    Longitude: "28.0687",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8486",
    WardLookupId: "2584",
    VDNumber: "86700292",
    RegisteredPopulation: "1066",
    VotingStationName: "MATSEPA TEACHERS CENTRE",
    Address: "MABOPANE  TSHWANE",
    Latitude: "-25.5132",
    Longitude: "28.0596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8487",
    WardLookupId: "2585",
    VDNumber: "86661580",
    RegisteredPopulation: "2238",
    VotingStationName: "MABOPANE ISLAMIC CENTRE",
    Address: "TSEBE VILLAGE  TSEBE  TSHWANE",
    Latitude: "-25.5072",
    Longitude: "28.012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8488",
    WardLookupId: "2585",
    VDNumber: "86700045",
    RegisteredPopulation: "3788",
    VotingStationName: "MORULA VIEW PRIMARY SCHOOL",
    Address: "UNIT M  MABOPANE  TSHWANE",
    Latitude: "-25.5254",
    Longitude: "28.0449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8489",
    WardLookupId: "2585",
    VDNumber: "86700056",
    RegisteredPopulation: "2823",
    VotingStationName: "TSHWANE WEST DISTRICT",
    Address: "TSEBE  MABOPANE  TSHWANE",
    Latitude: "-25.5147",
    Longitude: "28.0225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8490",
    WardLookupId: "2585",
    VDNumber: "86700102",
    RegisteredPopulation: "2255",
    VotingStationName: "TSIBOGO MIDDLE SCHOOL",
    Address: "748 UNIT U  MABOPANE  TSHWANE",
    Latitude: "-25.4978",
    Longitude: "28.0369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8491",
    WardLookupId: "2585",
    VDNumber: "86700258",
    RegisteredPopulation: "2054",
    VotingStationName: "REATLEGILE PRIMARY SCHOOL",
    Address: "3291 BLOCK U EXTENSION  MABOPANE  PRETORIA",
    Latitude: "-25.5099",
    Longitude: "28.0261",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8492",
    WardLookupId: "2586",
    VDNumber: "32951268",
    RegisteredPopulation: "2001",
    VotingStationName: "RETHABILE HALL",
    Address: "SOMO STREET  10935 MAMELODI EAST  PRETORIA",
    Latitude: "-25.7053",
    Longitude: "28.3754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8493",
    WardLookupId: "2586",
    VDNumber: "32951471",
    RegisteredPopulation: "1713",
    VotingStationName: "MVELEDZO PRIMARY SCHOOL",
    Address: "12507 TSAMAYA ROAD  MAMELODI EAST  PRETORIA",
    Latitude: "-25.7162",
    Longitude: "28.3694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8494",
    WardLookupId: "2586",
    VDNumber: "32951583",
    RegisteredPopulation: "1760",
    VotingStationName: "RENEILWE ADULT CENTRE",
    Address: "STAND 10954  MAMELODI EAST  PRETORIA",
    Latitude: "-25.7033",
    Longitude: "28.3713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8495",
    WardLookupId: "2586",
    VDNumber: "32951594",
    RegisteredPopulation: "2277",
    VotingStationName: "BALEBOGENG PRIMARY SCHOOL",
    Address: "13159 TSAMAYA ROAD  MAMELODI EAST  TSHWANE",
    Latitude: "-25.7065",
    Longitude: "28.3862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8496",
    WardLookupId: "2586",
    VDNumber: "32951606",
    RegisteredPopulation: "2304",
    VotingStationName: "SINDAWONYE PRIMARY SCHOOL",
    Address: "331 TSOMO STREET  MAMELODI EAST  PRETORIA",
    Latitude: "-25.7109",
    Longitude: "28.3698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8497",
    WardLookupId: "2586",
    VDNumber: "32952786",
    RegisteredPopulation: "1019",
    VotingStationName: "ZAKHELE PRIMARY SCHOOL",
    Address: "MAKGATHOLELA & MABILO  MAMELODI - S&S  PRETORIA",
    Latitude: "-25.7061",
    Longitude: "28.3786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8498",
    WardLookupId: "2587",
    VDNumber: "86730015",
    RegisteredPopulation: "1211",
    VotingStationName: "SISTERS OF MERCY SCHOOL",
    Address: "1080 WINTERVELD  TSHWANE",
    Latitude: "-25.452",
    Longitude: "28.0241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8499",
    WardLookupId: "2587",
    VDNumber: "86730060",
    RegisteredPopulation: "2254",
    VotingStationName: "THULAGANYO MIDDLE SCHOOL",
    Address: "P.B131 WINTERVELDT  TSHWANE",
    Latitude: "-25.4502",
    Longitude: "28.0549",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8500",
    WardLookupId: "2587",
    VDNumber: "86730138",
    RegisteredPopulation: "3682",
    VotingStationName: "KULANI PRIMARY SCHOOL",
    Address: "1378 ANGLICAN STAND  WINTERVELDT  TSHWANE",
    Latitude: "-25.4459",
    Longitude: "28.065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8501",
    WardLookupId: "2587",
    VDNumber: "86730183",
    RegisteredPopulation: "954",
    VotingStationName: "EMMANUEL CATHOLIC CHURCH",
    Address: "190 GA- MOTSEPE STAND  FIVE MORGAN  WINTERVELDT",
    Latitude: "-25.4095",
    Longitude: "28.0094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8502",
    WardLookupId: "2587",
    VDNumber: "86730194",
    RegisteredPopulation: "808",
    VotingStationName: "BAPTIST CHURCH",
    Address: "PLOT 932  10 MORGAN  WINTERVELDT",
    Latitude: "-25.4106",
    Longitude: "27.9888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8503",
    WardLookupId: "2587",
    VDNumber: "86730206",
    RegisteredPopulation: "1965",
    VotingStationName: "WATER PROJECT",
    Address: "STAND 1655  WINTERVELDT  ",
    Latitude: "-25.4451",
    Longitude: "28.0512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8504",
    WardLookupId: "2587",
    VDNumber: "86730239",
    RegisteredPopulation: "1771",
    VotingStationName: "MERAFENG PRIMARY SCHOOL",
    Address: "PLOT 134  WINTERVELDT  PRETORIA",
    Latitude: "-25.4048",
    Longitude: "28.0216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8505",
    WardLookupId: "2587",
    VDNumber: "86730251",
    RegisteredPopulation: "1342",
    VotingStationName: "NDABA HALL",
    Address: "285 NDABA STAND  WINTERVELDT  TSHWANE",
    Latitude: "-25.4167",
    Longitude: "28.0488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8506",
    WardLookupId: "2587",
    VDNumber: "86730307",
    RegisteredPopulation: "1127",
    VotingStationName: "GOITSEONE EARLY LEARNING CENTRE",
    Address: "76/186 SLOVO GARDENS  WINTERVELDT  PRETORIA",
    Latitude: "-25.466",
    Longitude: "28.033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8507",
    WardLookupId: "2588",
    VDNumber: "32960314",
    RegisteredPopulation: "3141",
    VotingStationName: "DIMAKATSO PRIMARY SCHOOL",
    Address: "202 BLOCK W  SOSHANGUVE  PRETORIA",
    Latitude: "-25.4504",
    Longitude: "28.1213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8508",
    WardLookupId: "2588",
    VDNumber: "32960392",
    RegisteredPopulation: "3068",
    VotingStationName: "KGOTLELELANG PRIMARY SCHOOL",
    Address: "351 BLOCK T  SOSHANGUVE  PRETORIA",
    Latitude: "-25.4396",
    Longitude: "28.1096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8509",
    WardLookupId: "2588",
    VDNumber: "32960404",
    RegisteredPopulation: "3176",
    VotingStationName: "VUKOSI PRIMARY SCHOOL",
    Address: "BLOCK 132  132 BLOCK VSOSHANGUVE  PRETORIA",
    Latitude: "-25.438",
    Longitude: "28.119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8510",
    WardLookupId: "2588",
    VDNumber: "32960651",
    RegisteredPopulation: "1750",
    VotingStationName: "BOIKHUTSONG CENTRE",
    Address: "1266 BLOCK T  SOSHANGUVE  PRETORIA",
    Latitude: "-25.4485",
    Longitude: "28.1126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8511",
    WardLookupId: "2588",
    VDNumber: "32960886",
    RegisteredPopulation: "844",
    VotingStationName: "FUNEKILE SECONDARY SCHOOL",
    Address: "963 BLOCK V  SOSHANGUVE V  SOSHANGUVE",
    Latitude: "-25.4418",
    Longitude: "28.121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8512",
    WardLookupId: "2589",
    VDNumber: "32960033",
    RegisteredPopulation: "1959",
    VotingStationName: "UTHANDO PRIMARY SCHOOL",
    Address: "1623 BLOCK R  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4751",
    Longitude: "28.1227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8513",
    WardLookupId: "2589",
    VDNumber: "32960178",
    RegisteredPopulation: "2362",
    VotingStationName: "SOSHANGUVE HIGH SCHOOL",
    Address: "1025 BLOCK FF  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4794",
    Longitude: "28.1011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8514",
    WardLookupId: "2589",
    VDNumber: "32960279",
    RegisteredPopulation: "1849",
    VotingStationName: "SEETSA SAKGWEDI PRIMARY SCHOOL",
    Address: "431 BLOCK R  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4668",
    Longitude: "28.1189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8515",
    WardLookupId: "2589",
    VDNumber: "32960415",
    RegisteredPopulation: "1790",
    VotingStationName: "KONDELELANI PRIMARY SCHOOL",
    Address: "314 BLOCK S  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4593",
    Longitude: "28.1187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8516",
    WardLookupId: "2589",
    VDNumber: "32960718",
    RegisteredPopulation: "3022",
    VotingStationName: "CHURCH OF CHRIST - SOSHANGUVE",
    Address: "48 BLOCK KK  SOSHANGUVE - KK  PRETORIA",
    Latitude: "-25.4747",
    Longitude: "28.1115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8517",
    WardLookupId: "2589",
    VDNumber: "32960965",
    RegisteredPopulation: "537",
    VotingStationName: "BABINAPHUTI SECONDARY SCHOOL",
    Address: "BLACK FF  SOSHANGUVE - FF  PRETORIA",
    Latitude: "-25.488",
    Longitude: "28.1033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8518",
    WardLookupId: "2589",
    VDNumber: "32960976",
    RegisteredPopulation: "1033",
    VotingStationName: "MILLENIUM EARLY LEARNING CENTRE",
    Address: "BLACK RR   SOSHANGUVE - RR NORTH  PRETORIA",
    Latitude: "-25.4671",
    Longitude: "28.1136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8519",
    WardLookupId: "2590",
    VDNumber: "32960189",
    RegisteredPopulation: "1338",
    VotingStationName: "SENTHIBELE SENIOR SECONDARY SCHOOL",
    Address: "1556 BLOCK R  SOSHANGUVE  TSHWANE",
    Latitude: "-25.475",
    Longitude: "28.1259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8520",
    WardLookupId: "2590",
    VDNumber: "32960303",
    RegisteredPopulation: "1854",
    VotingStationName: "NCHUNCHEKO PRIMARY SCHOOL",
    Address: "871 BLOCK R  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4661",
    Longitude: "28.1254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8521",
    WardLookupId: "2590",
    VDNumber: "32960325",
    RegisteredPopulation: "3300",
    VotingStationName: "M J MGIDI",
    Address: "1683 BLOCK X  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4709",
    Longitude: "28.1337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8522",
    WardLookupId: "2590",
    VDNumber: "32960381",
    RegisteredPopulation: "2517",
    VotingStationName: "PULAMADIBOGO PRIMARY SCHOOL",
    Address: "264 BLOCK Y  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4868",
    Longitude: "28.1353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8523",
    WardLookupId: "2590",
    VDNumber: "32960426",
    RegisteredPopulation: "2550",
    VotingStationName: "MMABANA PRIMARY SCHOOL",
    Address: "622 BLOCK X  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4613",
    Longitude: "28.1316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8524",
    WardLookupId: "2590",
    VDNumber: "32960987",
    RegisteredPopulation: "489",
    VotingStationName: "SOSHANGUVE LIBRARY BLOCK X",
    Address: "CAPITAL B STREET BLOCK X  SOSHANGUVE - X  PRETORIA",
    Latitude: "-25.4677",
    Longitude: "28.1306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8525",
    WardLookupId: "2591",
    VDNumber: "32951280",
    RegisteredPopulation: "1288",
    VotingStationName: "TSHIMOLLO PRIMARY SCHOOL",
    Address: "7265 SECTION U  MAMELODI WEST  PRETORIA",
    Latitude: "-25.7157",
    Longitude: "28.3485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8526",
    WardLookupId: "2591",
    VDNumber: "32951291",
    RegisteredPopulation: "1826",
    VotingStationName: "AGNES CHIDI PRIMARY SCHOOL",
    Address: "7402 TJALE STREET, SECTION Y  MAMELODI WEST 0122  TSHWANE",
    Latitude: "-25.7154",
    Longitude: "28.3521",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8527",
    WardLookupId: "2591",
    VDNumber: "32951459",
    RegisteredPopulation: "2085",
    VotingStationName: "MORETELE PRIMARY SCHOOL",
    Address: "5458 BLOCK P DIKGAKGATHA STREET  MAMELODI WEST 0122  TSHWANE",
    Latitude: "-25.7143",
    Longitude: "28.3587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8528",
    WardLookupId: "2591",
    VDNumber: "32951460",
    RegisteredPopulation: "1554",
    VotingStationName: "LUTHERAN CHURCH (MODISA O MOLEMO)",
    Address: "4957 SECTION O  MAMELODI WEST 0122  TSHWANE",
    Latitude: "-25.7151",
    Longitude: "28.3615",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8529",
    WardLookupId: "2591",
    VDNumber: "32951482",
    RegisteredPopulation: "1692",
    VotingStationName: "HOPE DAYCARE CENTRE",
    Address: "20218 TSAMAYA ROAD, TSAKANE  MAMELODI EAST  PRETORIA",
    Latitude: "-25.7194",
    Longitude: "28.3622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8530",
    WardLookupId: "2591",
    VDNumber: "32951572",
    RegisteredPopulation: "2064",
    VotingStationName: "MAMELODI HIGH SCHOOL",
    Address: "9996 9996 TSOMO DRIVE  MAMELODI WEST 0122  TSHWANE",
    Latitude: "-25.7102",
    Longitude: "28.3638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8531",
    WardLookupId: "2591",
    VDNumber: "32952775",
    RegisteredPopulation: "1059",
    VotingStationName: "TATENI HOME BASE CARE",
    Address: "227 VAMSANDA  MAMELODI WEST, MORETELE VIEW  PRETORIA",
    Latitude: "-25.7195",
    Longitude: "28.3518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8532",
    WardLookupId: "2592",
    VDNumber: "32960190",
    RegisteredPopulation: "1744",
    VotingStationName: "PUTCO SOSHANGUVE",
    Address: "BLOCK EE1,BUITEKANT STREET  SOSHANGUVE  PRETORIA",
    Latitude: "-25.4846",
    Longitude: "28.0939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8533",
    WardLookupId: "2592",
    VDNumber: "32960257",
    RegisteredPopulation: "1967",
    VotingStationName: "MEMEZELO SENIOR SECONDARY SCHOOL",
    Address: "279 BLOCK AA  SOSHANGUVE  TSHWANE",
    Latitude: "-25.5026",
    Longitude: "28.0943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8534",
    WardLookupId: "2592",
    VDNumber: "32960729",
    RegisteredPopulation: "2146",
    VotingStationName: "CENTRAL VIEW FIRE STATION",
    Address: "CENTRAL VIEW  MABOPANE  TSHWANE",
    Latitude: "-25.4927",
    Longitude: "28.0872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8535",
    WardLookupId: "2592",
    VDNumber: "32960730",
    RegisteredPopulation: "3008",
    VotingStationName: "HARVEST TIME CHRISTIAN CENTRE",
    Address: "1172 BLOCK NN  SOSHANGUVE  PRETORIA",
    Latitude: "-25.474",
    Longitude: "28.0814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8536",
    WardLookupId: "2592",
    VDNumber: "32960897",
    RegisteredPopulation: "565",
    VotingStationName: "IKHAYA LAMI LETHEMBA PROJECT",
    Address: "1719 BLOCK NN  SOSHANGUVE  PRETORIA",
    Latitude: "-25.4791",
    Longitude: "28.0824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8537",
    WardLookupId: "2592",
    VDNumber: "86700179",
    RegisteredPopulation: "2717",
    VotingStationName: "PATRICK MOLOTO PRIMARY SCHOOL",
    Address: "UNIT E  MABOPANE  TSHWANE",
    Latitude: "-25.4995",
    Longitude: "28.0777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8538",
    WardLookupId: "2592",
    VDNumber: "86730262",
    RegisteredPopulation: "1583",
    VotingStationName: "GRACE & TRUTH MISSION CHURCH",
    Address: "1648 VILAKAZI MOLEMA STAND  WINTERVELDT  PRETORIA",
    Latitude: "-25.4898",
    Longitude: "28.079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8539",
    WardLookupId: "2593",
    VDNumber: "86690065",
    RegisteredPopulation: "2001",
    VotingStationName: "TENT - NEXT TO HOUSE NUMBER 9259",
    Address: "GA-RANKUWA  GA-RANKUWA UNIT 17  TSHWANE",
    Latitude: "-25.57",
    Longitude: "27.9923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8540",
    WardLookupId: "2593",
    VDNumber: "86690098",
    RegisteredPopulation: "3344",
    VotingStationName: "MAROPENG PRIMARY SCHOOL",
    Address: "811 ZONE 16 GA-RANKUWA  GA-RANKUWA  GA-RANKUWA",
    Latitude: "-25.595",
    Longitude: "27.9761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8541",
    WardLookupId: "2593",
    VDNumber: "86690122",
    RegisteredPopulation: "1909",
    VotingStationName: "MAPENANE MIDDLE SCHOOL",
    Address: "193 SENNE STREET  ZONE 16  GA-RANKUWA",
    Latitude: "-25.5886",
    Longitude: "27.9835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8542",
    WardLookupId: "2593",
    VDNumber: "86690144",
    RegisteredPopulation: "2004",
    VotingStationName: "RANTAILANE HIGH SCHOOL",
    Address: "5799 ZONE 4  GA-RANKUWA  PRETORIA",
    Latitude: "-25.5951",
    Longitude: "27.9854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8543",
    WardLookupId: "2593",
    VDNumber: "86690166",
    RegisteredPopulation: "3118",
    VotingStationName: "BACHANA MOKWENA PRIMARY SCHOOL",
    Address: "GA- RANKUWA VIEW  GA- RANKUWA  TSHWANE METRO",
    Latitude: "-25.5499",
    Longitude: "27.9853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8544",
    WardLookupId: "2593",
    VDNumber: "86690177",
    RegisteredPopulation: "2114",
    VotingStationName: "ADONIA CHRISTIAN CHURCH",
    Address: "1243 EXT 23 GA-RANKUWA VIEW  GA-RANKUWA UNIT 23  GA-RANKUWA",
    Latitude: "-25.5717",
    Longitude: "27.9821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8545",
    WardLookupId: "2593",
    VDNumber: "86690201",
    RegisteredPopulation: "243",
    VotingStationName: "GILGAL MINISTRIES",
    Address: "EXT 1  GA-RANKUWA  GA-RANKUWA VIEW",
    Latitude: "-25.5555",
    Longitude: "28.001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8546",
    WardLookupId: "2593",
    VDNumber: "86692652",
    RegisteredPopulation: "1162",
    VotingStationName: "TENT - EXT 24, PARK NEXT TO HOUSE 854",
    Address: "EXTENSION 24  GA-RANKUWA VIEW,GA-RANKUWA  PRETORIA",
    Latitude: "-25.5665",
    Longitude: "27.9809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8547",
    WardLookupId: "2594",
    VDNumber: "86690010",
    RegisteredPopulation: "1899",
    VotingStationName: "TSELA-TSHWEU MOEPATHUTSE ABET CENTRE",
    Address: "297 MOLOTO  ZONE 1  GA-RANKUWA",
    Latitude: "-25.6124",
    Longitude: "27.9867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8548",
    WardLookupId: "2594",
    VDNumber: "86690021",
    RegisteredPopulation: "1675",
    VotingStationName: "TOKYO SEXWALE PRIMARY SCHOOL",
    Address: "1949 MAMOGALE  ZONE 1  GA-RANKUWA",
    Latitude: "-25.61",
    Longitude: "27.995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8549",
    WardLookupId: "2594",
    VDNumber: "86690032",
    RegisteredPopulation: "2966",
    VotingStationName: "BOLOKANANG MOEPATHUTSE ABET CENTRE",
    Address: "2831 MATSHEGO  ZONE 2  GA-RANKUWA",
    Latitude: "-25.6098",
    Longitude: "28.0001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8550",
    WardLookupId: "2594",
    VDNumber: "86690076",
    RegisteredPopulation: "2252",
    VotingStationName: "METHODIST CHURCH",
    Address: "ZONE 1  GA- RANKUWA  TSHWANE METRO [PRETORIA]",
    Latitude: "-25.6112",
    Longitude: "27.9814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8551",
    WardLookupId: "2594",
    VDNumber: "86690087",
    RegisteredPopulation: "1769",
    VotingStationName: "SETLALENTOA HIGH SCHOOL",
    Address: "ZONE 5  GA-RANKUWA  GA-RANKUWA",
    Latitude: "-25.5955",
    Longitude: "27.9946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8552",
    WardLookupId: "2594",
    VDNumber: "86690111",
    RegisteredPopulation: "1845",
    VotingStationName: "CATHOLIC CHURCH",
    Address: "8669 NKARENG  ZONE 4  GA-RANKUWA",
    Latitude: "-25.5998",
    Longitude: "27.9785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8553",
    WardLookupId: "2594",
    VDNumber: "86690155",
    RegisteredPopulation: "1067",
    VotingStationName: "ITIRELENG WORKSHOP FOR THE BLIND",
    Address: "3152 SEKWATI STR  ZONE 2  GARANKUWA",
    Latitude: "-25.613",
    Longitude: "28.0103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8554",
    WardLookupId: "2595",
    VDNumber: "32960707",
    RegisteredPopulation: "1991",
    VotingStationName: "SEFAKO MAKGATHO HEALTH SCIENCES UNIVERSITY",
    Address: "237 OUDEKRAAL  GA-RANKUWA  TSHWANE",
    Latitude: "-25.6151",
    Longitude: "28.0166",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8555",
    WardLookupId: "2595",
    VDNumber: "32960998",
    RegisteredPopulation: "1235",
    VotingStationName: "ROSSLYN PRIMARY SCHOOL",
    Address: "667 INHLAVA STREET  ROSSLYN  PRETORIA",
    Latitude: "-25.6258",
    Longitude: "28.0555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8556",
    WardLookupId: "2595",
    VDNumber: "86690043",
    RegisteredPopulation: "2197",
    VotingStationName: "TIM MODISE PRIMARY SCHOOL",
    Address: "7384 UNIT 6  GA-RANKUWA  GA-RANKUWA",
    Latitude: "-25.6076",
    Longitude: "28.0095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8557",
    WardLookupId: "2595",
    VDNumber: "86690054",
    RegisteredPopulation: "2325",
    VotingStationName: "RAKALE-THABONG PRIMARY SCHOOL",
    Address: "4222 KHONOU STREET  GA-RANKUWA  TSHWANE",
    Latitude: "-25.5948",
    Longitude: "28.0093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8558",
    WardLookupId: "2595",
    VDNumber: "86690100",
    RegisteredPopulation: "2733",
    VotingStationName: "BETHESDA DAYCARE CENTRE",
    Address: "2885 MONNAKGOTLA  ZONE 8  GA-RANKUWA",
    Latitude: "-25.5723",
    Longitude: "28.0152",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8559",
    WardLookupId: "2595",
    VDNumber: "86690133",
    RegisteredPopulation: "2720",
    VotingStationName: "MODISELLE PRIMARY SCHOOL",
    Address: "1735 MALEBYE STREET ZONE 7  ZONE 7  GA-RANKUWA",
    Latitude: "-25.5803",
    Longitude: "28.018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8560",
    WardLookupId: "2595",
    VDNumber: "86690188",
    RegisteredPopulation: "1321",
    VotingStationName: "DINALETSANA EARLY CHILDWOOD DEVELOPMENT CENTRE",
    Address: "820 EXT 21  GA-RANKUWA  UNIT 7  PRETORIA",
    Latitude: "-25.5612",
    Longitude: "28.012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8561",
    WardLookupId: "2595",
    VDNumber: "86690199",
    RegisteredPopulation: "1031",
    VotingStationName: "TEBOGWANA COMPREHENSIVE HIGH SCHOOL",
    Address: "1644 ZONE 7.EP THEMA STREET  GA-RANKUWA  PRETORIA",
    Latitude: "-25.5761",
    Longitude: "28.0173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8562",
    WardLookupId: "2595",
    VDNumber: "86690212",
    RegisteredPopulation: "856",
    VotingStationName: "DIMPHO DAY CARE CENTRE",
    Address: "1654 B ZONE 14  GA-RANKUWA  GA-RANKUWA",
    Latitude: "-25.5766",
    Longitude: "28.0305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8563",
    WardLookupId: "2595",
    VDNumber: "86690223",
    RegisteredPopulation: "396",
    VotingStationName: "MMATHELO DAY CARE",
    Address: "1094 BRINGAVILLE  GA-RANKUWA  TOWN",
    Latitude: "-25.5547",
    Longitude: "28.0098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8564",
    WardLookupId: "2596",
    VDNumber: "32960044",
    RegisteredPopulation: "1675",
    VotingStationName: "BOEPATHUTSE JUNIOR SECONDARY SCHOOL",
    Address: "1415 BLOCK F  SOSHANGUVE  TSHWANE",
    Latitude: "-25.5112",
    Longitude: "28.1041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8565",
    WardLookupId: "2596",
    VDNumber: "32960055",
    RegisteredPopulation: "2400",
    VotingStationName: "LETHABONG SECONDARY SCHOOL",
    Address: "382 BLOCK F  SOSHANGUVE  TSHWANE",
    Latitude: "-25.5062",
    Longitude: "28.1053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8566",
    WardLookupId: "2596",
    VDNumber: "32960066",
    RegisteredPopulation: "1702",
    VotingStationName: "HLANGANANI HIGH SCHOOL",
    Address: "971 BLOCK G  SOSHANGUVE  TSHWANE",
    Latitude: "-25.5093",
    Longitude: "28.1183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8567",
    WardLookupId: "2596",
    VDNumber: "32960235",
    RegisteredPopulation: "2340",
    VotingStationName: "TSAROGA PHOKA PRIMARY SCHOOL",
    Address: "825 BLOCK BB  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4911",
    Longitude: "28.1032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8568",
    WardLookupId: "2596",
    VDNumber: "32960246",
    RegisteredPopulation: "2060",
    VotingStationName: "SIYOKHELA PRIMARY SCHOOL",
    Address: "22 BLOCK AA  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4982",
    Longitude: "28.0981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8569",
    WardLookupId: "2596",
    VDNumber: "32960268",
    RegisteredPopulation: "2492",
    VotingStationName: "ENTOKOZWENI PRIMARY SCHOOL",
    Address: "1316 BLOCK G  SOSHANGUVE  TSHWANE",
    Latitude: "-25.5106",
    Longitude: "28.1143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8570",
    WardLookupId: "2596",
    VDNumber: "32961001",
    RegisteredPopulation: "286",
    VotingStationName: "NTSHA PEU PRIMARY SCHOOL",
    Address: "294 BOREKO STREET BLOCK F EST   SOSHANGUVE - F EST   PRETORIA",
    Latitude: "-25.5079",
    Longitude: "28.1031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8571",
    WardLookupId: "2597",
    VDNumber: "32960077",
    RegisteredPopulation: "2308",
    VotingStationName: "RHULANI COMBINED SCHOOL",
    Address: "240 BLOCK H  SOSHANGUVE  TSHWANE",
    Latitude: "-25.5166",
    Longitude: "28.1099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8572",
    WardLookupId: "2597",
    VDNumber: "32960088",
    RegisteredPopulation: "2214",
    VotingStationName: "PHUMZILE PRIMARY SCHOOL",
    Address: "704 BLOCK H  SOSHANGUVE  TSHWANE",
    Latitude: "-25.5179",
    Longitude: "28.1115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8573",
    WardLookupId: "2597",
    VDNumber: "32960099",
    RegisteredPopulation: "3301",
    VotingStationName: "KHENSANI PRIMARY SCHOOL",
    Address: "1813 BLOCK H  SOSHANGUVE  PRETORIA",
    Latitude: "-25.5236",
    Longitude: "28.1055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8574",
    WardLookupId: "2597",
    VDNumber: "32960101",
    RegisteredPopulation: "1642",
    VotingStationName: "DITHABANENG PRIMARY SCHOOL",
    Address: "1988 BLOCK F  SOSHANGUVE  TSHWANE",
    Latitude: "-25.5129",
    Longitude: "28.1027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8575",
    WardLookupId: "2597",
    VDNumber: "32960123",
    RegisteredPopulation: "1801",
    VotingStationName: "REDIBONE PRIMARY SCHOOL",
    Address: "880 F WEST  SOSHANGUVE  TSHWANE",
    Latitude: "-25.5151",
    Longitude: "28.0951",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8576",
    WardLookupId: "2597",
    VDNumber: "32960583",
    RegisteredPopulation: "1966",
    VotingStationName: "SOSHANGUVE MAGISTRATE OFFICE",
    Address: "SOSHANGUVE HIGH WAY-BLOCK H  SOSHANGUVE  TSHWANE",
    Latitude: "-25.5292",
    Longitude: "28.1141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8577",
    WardLookupId: "2598",
    VDNumber: "32960134",
    RegisteredPopulation: "922",
    VotingStationName: "TSHWANE NORTH COLLEGE",
    Address: "COLLEGE ROAD  3164 BLOCK LSOSHANGUVE  PRETORIA",
    Latitude: "-25.5451",
    Longitude: "28.0921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8578",
    WardLookupId: "2598",
    VDNumber: "32960145",
    RegisteredPopulation: "2252",
    VotingStationName: "BETHSAIDA SKILLS CENTRE",
    Address: "429 BLOCK K  SOSHANGUVE  PRETORIA",
    Latitude: "-25.5248",
    Longitude: "28.0994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8579",
    WardLookupId: "2598",
    VDNumber: "32960471",
    RegisteredPopulation: "1552",
    VotingStationName: "SINQOBILE PRIMARY SCHOOL",
    Address: "BLOCK M EXT  SOSHANGUVE  PRETORIA",
    Latitude: "-25.5499",
    Longitude: "28.087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8580",
    WardLookupId: "2598",
    VDNumber: "32960549",
    RegisteredPopulation: "1235",
    VotingStationName: "WORD OF LIFE CHRISTIAN CHURCH (SOSHANGUVE)",
    Address: "374 MOTLHATWA AND UMKHANGELE   BLOCK WW  SOSHANGUVE",
    Latitude: "-25.5544",
    Longitude: "28.0832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8581",
    WardLookupId: "2598",
    VDNumber: "32960572",
    RegisteredPopulation: "3243",
    VotingStationName: "TIPFUXENI PRIMARY SCHOOL",
    Address: "285 BLOCK M  SOSHANGUVE  TSHWANE",
    Latitude: "-25.5292",
    Longitude: "28.0889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8582",
    WardLookupId: "2598",
    VDNumber: "32960617",
    RegisteredPopulation: "2404",
    VotingStationName: "PHUTHANANG PRIMARY SCHOOL",
    Address: "862 BLOCK K  SOSHANGUVE  TSHWANE",
    Latitude: "-25.531",
    Longitude: "28.1002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8583",
    WardLookupId: "2598",
    VDNumber: "32960932",
    RegisteredPopulation: "447",
    VotingStationName: "CHRIST REACHES THE WORLD MINISTRY",
    Address: "2175 M EXTENTION   M EXTENTION/LKK  SOSHANGUVE SOUTH",
    Latitude: "-25.5489",
    Longitude: "28.0756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8584",
    WardLookupId: "2599",
    VDNumber: "32960156",
    RegisteredPopulation: "2069",
    VotingStationName: "A RETHABENG PRIMARY SCHOOL",
    Address: "1670 BLOCK L  SOSHANGUVE  PRETORIA",
    Latitude: "-25.5426",
    Longitude: "28.106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8585",
    WardLookupId: "2599",
    VDNumber: "32960167",
    RegisteredPopulation: "1766",
    VotingStationName: "RETHOMILE JUNIOR SECONDARY SCHOOL",
    Address: "2116 BLOCK L  SOSHANGUVE  PRETORIA",
    Latitude: "-25.536",
    Longitude: "28.1033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8586",
    WardLookupId: "2599",
    VDNumber: "32960628",
    RegisteredPopulation: "2791",
    VotingStationName: "KUTUNG PRIMARY SCHOOL",
    Address: "318 BLOCK L  SOSHANGUVE  TSHWANE",
    Latitude: "-25.5341",
    Longitude: "28.1091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8587",
    WardLookupId: "2599",
    VDNumber: "32960639",
    RegisteredPopulation: "3194",
    VotingStationName: "MEETSENG PRIMARY SCHOOL",
    Address: "1487 BLOCK L  SOSHANGUVE  TSHWANE",
    Latitude: "-25.5389",
    Longitude: "28.0999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8588",
    WardLookupId: "2599",
    VDNumber: "32960741",
    RegisteredPopulation: "1616",
    VotingStationName: "KHUTSONG DAY CARE CENTRE",
    Address: "3081 M EXT 1  (LVV)  BLOCK LVV-M EXT 1  SOSHSNGUVE",
    Latitude: "-25.552",
    Longitude: "28.0999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8589",
    WardLookupId: "2599",
    VDNumber: "32960752",
    RegisteredPopulation: "2408",
    VotingStationName: "MATHAGA PRIMARY SCHOOL",
    Address: "2116 BLOCK L,  SOSHANGUVE -BLOCK  L   PRETORIA",
    Latitude: "-25.5477",
    Longitude: "28.1038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8590",
    WardLookupId: "2600",
    VDNumber: "32960684",
    RegisteredPopulation: "4952",
    VotingStationName: "BOKAMOSO PRIMARY SCHOOL",
    Address: "6113 EXTESION 4  SOSHANGUVE  TSHWANE",
    Latitude: "-25.5849",
    Longitude: "28.0826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8591",
    WardLookupId: "2600",
    VDNumber: "32960796",
    RegisteredPopulation: "4819",
    VotingStationName: "KGETHEGO EARLY LEARNING CENTRE",
    Address: "19978 EXT 10   PRETORIA   SOSHANGUVE SOUTH",
    Latitude: "-25.5897",
    Longitude: "28.0608",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8592",
    WardLookupId: "2600",
    VDNumber: "32960820",
    RegisteredPopulation: "3608",
    VotingStationName: "COMMANDMENT OF GOD MINISTRY (CGM)",
    Address: "15339 NONE   EXTENTION 10   SOSHANGUVE",
    Latitude: "-25.5861",
    Longitude: "28.0497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8593",
    WardLookupId: "2600",
    VDNumber: "32960910",
    RegisteredPopulation: "2235",
    VotingStationName: "TABLE OF HIS GRACE K/C",
    Address: "4659 EXTENSION 4   PRETORIA   SOSHANGUVE",
    Latitude: "-25.5787",
    Longitude: "28.0831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8594",
    WardLookupId: "2600",
    VDNumber: "32960921",
    RegisteredPopulation: "1779",
    VotingStationName: "REVIVAL FOR ALL NATIONSâ€™ MINISTRIES",
    Address: "3501 EXTENTION 04  EXTENTION 4  SOSHANGUVE SOUTH",
    Latitude: "-25.5781",
    Longitude: "28.0679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8595",
    WardLookupId: "2600",
    VDNumber: "32961168",
    RegisteredPopulation: "754",
    VotingStationName: "SIHLE-BUHLE",
    Address: "2661 IKHAMABI AND MORNING STAR STR  PRETORIA   ROSSLYN GARDENS",
    Latitude: "-25.6188",
    Longitude: "28.0645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8596",
    WardLookupId: "2600",
    VDNumber: "32961191",
    RegisteredPopulation: "591",
    VotingStationName: "KAMOGELO DAY CARE CENTER",
    Address: "00 M17  SOSHANGUVE EXT 11  PRETORIA",
    Latitude: "-25.5858",
    Longitude: "28.0451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8597",
    WardLookupId: "2600",
    VDNumber: "32961203",
    RegisteredPopulation: "619",
    VotingStationName: "CHARGING SPOT DAY CARE  (M20)",
    Address: "CHANGING SPOT   PRETORIA   SOSHANGUVE SOUTH",
    Latitude: "-25.5979",
    Longitude: "28.0777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8598",
    WardLookupId: "2601",
    VDNumber: "32951213",
    RegisteredPopulation: "2265",
    VotingStationName: "SHIRINDA PRIMARY SCHOOL",
    Address: "1692 GOLELE STREET  MAMELODI WEST  PRETORIA",
    Latitude: "-25.7054",
    Longitude: "28.3308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8599",
    WardLookupId: "2601",
    VDNumber: "32951303",
    RegisteredPopulation: "370",
    VotingStationName: "MINI MUNITORIA",
    Address: "19268 MAKHUBELA & LETWABA STREET  MAMELODI WEST  PRETORIA",
    Latitude: "-25.7161",
    Longitude: "28.3365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8600",
    WardLookupId: "2601",
    VDNumber: "32951336",
    RegisteredPopulation: "1615",
    VotingStationName: "MAMELODI HERITAGE FORUM",
    Address: "610 SHABANGU STREET  MAMELODI WEST  PRETORIA",
    Latitude: "-25.7141",
    Longitude: "28.3373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8601",
    WardLookupId: "2601",
    VDNumber: "32951347",
    RegisteredPopulation: "2471",
    VotingStationName: "KINGSLEY HOSTEL,HALL NO.2, BLOCK 4",
    Address: "LETWABA STREET  MAMELODI WEST  PRETORIA",
    Latitude: "-25.7156",
    Longitude: "28.3393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8602",
    WardLookupId: "2601",
    VDNumber: "32951358",
    RegisteredPopulation: "1545",
    VotingStationName: "UNITING REFORMED CHURCH-",
    Address: "10 LEBOTSE STREET  MAMELODI WEST SEC V  PRETORIA",
    Latitude: "-25.715",
    Longitude: "28.344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8603",
    WardLookupId: "2601",
    VDNumber: "32951426",
    RegisteredPopulation: "1824",
    VotingStationName: "BOHLABATSATSI PRIMARY SCHOOL",
    Address: "653 SECTION B, MAKHUSELA STREET  MAMELODI WEST  PRETORIA",
    Latitude: "-25.7118",
    Longitude: "28.3396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8604",
    WardLookupId: "2601",
    VDNumber: "32952797",
    RegisteredPopulation: "1362",
    VotingStationName: "TENT AT SHABANGU STREET",
    Address: "SHABANGU & NTSIENG  MAMELODI WEST  PRETORIA",
    Latitude: "-25.7108",
    Longitude: "28.3312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8605",
    WardLookupId: "2601",
    VDNumber: "32952809",
    RegisteredPopulation: "657",
    VotingStationName: "TENT AT BOUNDARY STREET",
    Address: "BOUNDARY & CHARLES LEYDS  MAMELODI SUN VALLEY  PRETORIA",
    Latitude: "-25.7154",
    Longitude: "28.3305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8606",
    WardLookupId: "2602",
    VDNumber: "32960594",
    RegisteredPopulation: "3991",
    VotingStationName: "REABETSWE PRIMARY SCHOOL",
    Address: "3105 EXTENSION  1  SOSHANGUVE  TSHWANE",
    Latitude: "-25.5555",
    Longitude: "28.0541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8607",
    WardLookupId: "2602",
    VDNumber: "32960606",
    RegisteredPopulation: "2827",
    VotingStationName: "SEAGENG SECONDARY SCHOOL",
    Address: "101 EXTENSION 11  SOSHANGUVE EXT 11  TSHWANE",
    Latitude: "-25.5688",
    Longitude: "28.0446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8608",
    WardLookupId: "2602",
    VDNumber: "32960695",
    RegisteredPopulation: "2566",
    VotingStationName: "AYANDA PRIMARY SCHOOL",
    Address: "172 KLIPFONTEIN  TSHWANE",
    Latitude: "-25.5751",
    Longitude: "28.0611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8609",
    WardLookupId: "2602",
    VDNumber: "32960808",
    RegisteredPopulation: "2581",
    VotingStationName: "ITUMELENG MADIBA PRIMARY SCHOOL",
    Address: "8194 EXT 2  SOSHANGUVE  TSHWANE METRO [PRETORIA]",
    Latitude: "-25.5666",
    Longitude: "28.0635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8610",
    WardLookupId: "2602",
    VDNumber: "32961012",
    RegisteredPopulation: "520",
    VotingStationName: "ALMIGHTY DAY CARE CENTRE",
    Address: "BLOCK TT  SOSHANGUVE SOUTH  PRETORIA",
    Latitude: "-25.5807",
    Longitude: "28.0681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8611",
    WardLookupId: "2602",
    VDNumber: "32961023",
    RegisteredPopulation: "1068",
    VotingStationName: "MOTHEO PRE-SCHOOL",
    Address: "1836 EXTENTION 1,    SOSHANGUVE SOUTH  PRETORIA",
    Latitude: "-25.5529",
    Longitude: "28.0613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8612",
    WardLookupId: "2602",
    VDNumber: "32961214",
    RegisteredPopulation: "332",
    VotingStationName: "SJAMBOK COMMUNITY CENTRE",
    Address: "ERASMUS AREA  SHOSHANGUVE SOUTH  PRETORIA",
    Latitude: "-25.5649",
    Longitude: "28.0415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8613",
    WardLookupId: "2603",
    VDNumber: "32950830",
    RegisteredPopulation: "4023",
    VotingStationName: "MAHLASEDI MASANA SCHOOL",
    Address: "256 CNR. LEGADIMA & BENJAMIN SEEMA  MAMELODI  PRETORIA",
    Latitude: "-25.715",
    Longitude: "28.4184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8614",
    WardLookupId: "2603",
    VDNumber: "32952056",
    RegisteredPopulation: "5550",
    VotingStationName: "PFUNZO NDI TSHEDZA SCHOOL",
    Address: "35194 MORETLWA & MMUPU STR  EXT 6 MAMELODI EAST  PRETORIA",
    Latitude: "-25.7214",
    Longitude: "28.4044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8615",
    WardLookupId: "2603",
    VDNumber: "32952405",
    RegisteredPopulation: "2863",
    VotingStationName: "FUTURE GLORY MINISTRIES PHOMOLONG",
    Address: "PHOMOLONG BLOCK A  MAMELODI EAST PHOMOLONG  PRETORIA",
    Latitude: "-25.7286",
    Longitude: "28.4098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8616",
    WardLookupId: "2603",
    VDNumber: "32952438",
    RegisteredPopulation: "2698",
    VotingStationName: "CHARITY AND FAITH PHOMOLONG",
    Address: "MOHWELERE ROAD  MAMELODI EAST  PHOMOLONG EXT 6",
    Latitude: "-25.7241",
    Longitude: "28.4047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8617",
    WardLookupId: "2603",
    VDNumber: "32953293",
    RegisteredPopulation: "571",
    VotingStationName: "LIGHT AND LOVE CENTRE",
    Address: "PHOMOLONG BLOCK C  MAMELODI EAST  PRETORIA",
    Latitude: "-25.7209",
    Longitude: "28.4123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8618",
    WardLookupId: "2604",
    VDNumber: "32950278",
    RegisteredPopulation: "2616",
    VotingStationName: "LAERSKOOL MEYERSPARK",
    Address: "211 MANSER STREET  MEYERSPARK  PRETORIA",
    Latitude: "-25.7403",
    Longitude: "28.315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8619",
    WardLookupId: "2604",
    VDNumber: "32950425",
    RegisteredPopulation: "4023",
    VotingStationName: "LAERSKOOL SKUILKRANS",
    Address: "36 GRACE AVENUE  MURRAYFIELD  PRETORIA",
    Latitude: "-25.7489",
    Longitude: "28.3036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8620",
    WardLookupId: "2604",
    VDNumber: "32950964",
    RegisteredPopulation: "2945",
    VotingStationName: "LAERSKOOL SILVERTON",
    Address: "DEBOULEVARD STREET  SILVERTON  PRETORIA",
    Latitude: "-25.7323",
    Longitude: "28.3017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8621",
    WardLookupId: "2604",
    VDNumber: "32952450",
    RegisteredPopulation: "1814",
    VotingStationName: "GERMAN HIGH SCHOOL",
    Address: "SIMON VERMOETEN ROAD  THE WILLOWS  PRETORIA",
    Latitude: "-25.7491",
    Longitude: "28.3292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8622",
    WardLookupId: "2604",
    VDNumber: "32952461",
    RegisteredPopulation: "1278",
    VotingStationName: "SILVERTON HOÃ‹RSKOOL",
    Address: "122 DE BOULEVARD ROAD  PRETORIA  TSHWANE",
    Latitude: "-25.7365",
    Longitude: "28.3016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8623",
    WardLookupId: "2604",
    VDNumber: "32952472",
    RegisteredPopulation: "1753",
    VotingStationName: "LA MONTAGNE PRIMARY SCHOOL",
    Address: "PRETORIA  TSHWANE",
    Latitude: "-25.7452",
    Longitude: "28.3238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8624",
    WardLookupId: "2605",
    VDNumber: "32950841",
    RegisteredPopulation: "3673",
    VotingStationName: "MONUMENT PARK LAERSKOOL",
    Address: "20 OKAPI ROAD  MONUMENT PARK X7  PRETORIA",
    Latitude: "-25.8009",
    Longitude: "28.2302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8625",
    WardLookupId: "2605",
    VDNumber: "32950852",
    RegisteredPopulation: "3043",
    VotingStationName: "TENT (OPEN SPACE WATERKLOOF HEIGHTS)",
    Address: "MATROOSBERG & GLASTONBURG STREET  WATERKLOOF HEIGHTS   TSHAWNE",
    Latitude: "-25.7949",
    Longitude: "28.2574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8626",
    WardLookupId: "2605",
    VDNumber: "32951022",
    RegisteredPopulation: "3354",
    VotingStationName: "WATERKLOOF PRIMARY SCHOOL",
    Address: "306 MILNER STREET  WATERKLOOF  PRETORIA",
    Latitude: "-25.7782",
    Longitude: "28.24",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8627",
    WardLookupId: "2605",
    VDNumber: "32952292",
    RegisteredPopulation: "1361",
    VotingStationName: "TENT - WATERKLOOF RAND PARK",
    Address: "CNR RIGEL AND SCHOONGEZIGHT STREET  WATERKLOOF  PRETORIA",
    Latitude: "-25.809",
    Longitude: "28.2539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8628",
    WardLookupId: "2605",
    VDNumber: "32952573",
    RegisteredPopulation: "968",
    VotingStationName: "TENT - HEDGEROWS PRE-SCHOOL",
    Address: "434 ALBERT & HELOMA STREET  WATERKLOOF  TSHWANE",
    Latitude: "-25.7781",
    Longitude: "28.2514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8629",
    WardLookupId: "2605",
    VDNumber: "32952876",
    RegisteredPopulation: "1572",
    VotingStationName: "NG KERK MONUMENT PARKWES",
    Address: "36 KALKOEN STREET  MONUMENT PARK  PRETORIA",
    Latitude: "-25.8057",
    Longitude: "28.2225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8630",
    WardLookupId: "2606",
    VDNumber: "32950919",
    RegisteredPopulation: "2858",
    VotingStationName: "NORRIDGE PARK PRIMARY SCHOOL",
    Address: "P.S. FOURIE  EESTERUST  TSHWANE",
    Latitude: "-25.7072",
    Longitude: "28.3149",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8631",
    WardLookupId: "2606",
    VDNumber: "32950920",
    RegisteredPopulation: "2846",
    VotingStationName: "P.S. FOURIE PRIMARY SCHOOL",
    Address: "290 ST JOSEPH AVE  EERSTERUST  PRETORIA",
    Latitude: "-25.715",
    Longitude: "28.3119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8632",
    WardLookupId: "2606",
    VDNumber: "32950931",
    RegisteredPopulation: "2906",
    VotingStationName: "TENT AT LABOUR & PEGGY BARNES",
    Address: "OPEN SPACE C/O LABOUR & PEGGY BARNES STR  EERSTERUST  PRETORIA",
    Latitude: "-25.7015",
    Longitude: "28.3185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8633",
    WardLookupId: "2606",
    VDNumber: "32950942",
    RegisteredPopulation: "2562",
    VotingStationName: "NANTES LAERSKOOL",
    Address: "500 SIONSBERG ROAD  EERSTERUST  PRETORIA",
    Latitude: "-25.7112",
    Longitude: "28.3218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8634",
    WardLookupId: "2606",
    VDNumber: "32950975",
    RegisteredPopulation: "1572",
    VotingStationName: "TENT AT CNR FISKAAL & BERGHAAN ROAD",
    Address: "CNR FISKAAL & BERGHAAN ROAD  WATLOO  PRETORIA",
    Latitude: "-25.7314",
    Longitude: "28.3205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8635",
    WardLookupId: "2606",
    VDNumber: "32951897",
    RegisteredPopulation: "2464",
    VotingStationName: "PROSPERITAS SECONDARY SCHOOL",
    Address: "SOUTRIVIER AVENUE  EERSTERUST  PRETORIA",
    Latitude: "-25.7023",
    Longitude: "28.3041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8636",
    WardLookupId: "2607",
    VDNumber: "32951033",
    RegisteredPopulation: "2741",
    VotingStationName: "NG KERK VALLEISIG",
    Address: "773 TIPPERARY WAY  FAERIE GLEN  PRETORIA",
    Latitude: "-25.7932",
    Longitude: "28.3167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8637",
    WardLookupId: "2607",
    VDNumber: "32951932",
    RegisteredPopulation: "2707",
    VotingStationName: "NEDERDUITSCH GEREFORMEERDE KERK (FAERIE GLEN)",
    Address: "429 GLENWOOD ROAD  FAERIE GLEN  PRETORIA",
    Latitude: "-25.7825",
    Longitude: "28.2997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8638",
    WardLookupId: "2607",
    VDNumber: "32952078",
    RegisteredPopulation: "2942",
    VotingStationName: "TOMORROWS PEOPLE COLLEGE",
    Address: "381 SELIKATS CAUSE WAY  FAERIE GLEN  PRETORIA",
    Latitude: "-25.781",
    Longitude: "28.3106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8639",
    WardLookupId: "2607",
    VDNumber: "32952494",
    RegisteredPopulation: "1215",
    VotingStationName: "LUX MUNDI GEMEENTE",
    Address: "685 FLORIS STREET  GARSFONTEIN  PRETORIA",
    Latitude: "-25.7934",
    Longitude: "28.3092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8640",
    WardLookupId: "2607",
    VDNumber: "32952832",
    RegisteredPopulation: "1376",
    VotingStationName: "TENT - TWEEFONTEIN",
    Address: "SELIKATS CAUSE (NEXT TO ENGEN)  FAERIE GLEN  PRETORIA",
    Latitude: "-25.7841",
    Longitude: "28.3079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8641",
    WardLookupId: "2607",
    VDNumber: "32953013",
    RegisteredPopulation: "2486",
    VotingStationName: "BRIGHT BEGINNING NURSERY SCHOOL",
    Address: "LERATO & OLD FARM ROAD  OLYMPUS  PRETORIA",
    Latitude: "-25.7896",
    Longitude: "28.3221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8642",
    WardLookupId: "2607",
    VDNumber: "32953169",
    RegisteredPopulation: "545",
    VotingStationName: "EMMANUEL PRESBYTERIAN KERK",
    Address: "HAYMEADOW  BOARDWALK  TSHWANE",
    Latitude: "-25.7977",
    Longitude: "28.3207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8643",
    WardLookupId: "2608",
    VDNumber: "32950818",
    RegisteredPopulation: "1723",
    VotingStationName: "PRETORIUS PARK ELECTRICITY DEPOT",
    Address: "927 BUGLE STREET  PRETORIUS PARK  PRETORIA",
    Latitude: "-25.8114",
    Longitude: "28.3047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8644",
    WardLookupId: "2608",
    VDNumber: "32951077",
    RegisteredPopulation: "3897",
    VotingStationName: "GARSFONTEIN HOÃ‹RSKOOL",
    Address: "ROLINA STREET  GARSFONTEIN  PRETORIA",
    Latitude: "-25.7978",
    Longitude: "28.3056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8645",
    WardLookupId: "2608",
    VDNumber: "32951088",
    RegisteredPopulation: "2219",
    VotingStationName: "GARSFONTEIN LAERSKOOL",
    Address:
      "RINA VAN ZYL STREET( ENTRANCE ZITA STR.)  GARSFONTEIN X8  PRETORIA",
    Latitude: "-25.7924",
    Longitude: "28.2934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8646",
    WardLookupId: "2608",
    VDNumber: "32951819",
    RegisteredPopulation: "2787",
    VotingStationName: "NED GEREF KERK GARSFONTEIN",
    Address: "CNR ALEX MILLER & EMMIE HARTMN  GARSFONTEIN  PRETORIA",
    Latitude: "-25.7917",
    Longitude: "28.2886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8647",
    WardLookupId: "2608",
    VDNumber: "32952584",
    RegisteredPopulation: "1064",
    VotingStationName: "TENT AT RUBENSTEIN & PRIMULA CENTRE",
    Address: "RUBENSTEIN ROAD  MORELETA PARK  MORELETA PARK",
    Latitude: "-25.8114",
    Longitude: "28.2963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8648",
    WardLookupId: "2608",
    VDNumber: "32952887",
    RegisteredPopulation: "646",
    VotingStationName: "TENT - ROCCO DE VILLIERS STREET",
    Address: "DVORAK @ ROCCO DE VILLIERS STR  CONSTANTIA PARK  PRETORIA",
    Latitude: "-25.8032",
    Longitude: "28.2901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8649",
    WardLookupId: "2608",
    VDNumber: "32953080",
    RegisteredPopulation: "1047",
    VotingStationName: "BETHEL CHAPEL",
    Address: "611 BEAGLE ROAD  PRETORIUS PARK  PRETORIA",
    Latitude: "-25.8093",
    Longitude: "28.3137",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8650",
    WardLookupId: "2608",
    VDNumber: "32953170",
    RegisteredPopulation: "586",
    VotingStationName: "WOODHILL ESTATE CLUB  HOUSE",
    Address: "2024 ST BERNARD DRIVE   WOODHILL  TSHWANE",
    Latitude: "-25.8207",
    Longitude: "28.3123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8651",
    WardLookupId: "2608",
    VDNumber: "32953181",
    RegisteredPopulation: "600",
    VotingStationName: "EAST SIDE COMMUNITY CHURCH HALL",
    Address: "850 PRIMULA DRIVE  GARSFONTEIN  TSHWANE",
    Latitude: "-25.8125",
    Longitude: "28.3019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8652",
    WardLookupId: "2609",
    VDNumber: "32950874",
    RegisteredPopulation: "3498",
    VotingStationName: "LYNNWOOD RIDGE PRIMARY SCHOOL",
    Address: "HALEPENSIS STREET  LYNNWOOD RIDGE  PRETORIA",
    Latitude: "-25.761",
    Longitude: "28.2983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8653",
    WardLookupId: "2609",
    VDNumber: "32951044",
    RegisteredPopulation: "2839",
    VotingStationName: "ST ALBANS COLLEGE",
    Address: "110 CLEARWATER ROAD  LYNNWOOD GLEN  PRETORIA",
    Latitude: "-25.7737",
    Longitude: "28.2842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8654",
    WardLookupId: "2609",
    VDNumber: "32951055",
    RegisteredPopulation: "1938",
    VotingStationName: "ALKANTRANT LIBRARY",
    Address: "C/O LYNBURN AND DAVENTRY  LYNNWOOD MANOR  PRETORIA",
    Latitude: "-25.7635",
    Longitude: "28.2793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8655",
    WardLookupId: "2609",
    VDNumber: "32951099",
    RegisteredPopulation: "1589",
    VotingStationName: "NG KERK PRETORIA OOSTERLIG",
    Address: "469 C/O JUDITH & LEA STREET  WATERKLOOF GLEN  PRETORIA",
    Latitude: "-25.8044",
    Longitude: "28.2796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8656",
    WardLookupId: "2609",
    VDNumber: "32952203",
    RegisteredPopulation: "2187",
    VotingStationName: "TENT - CNR CEDAR & BEECH STREET",
    Address: "CNR CEDAR & BEECH STREET   LYNNWOOD RIDGE  TSHWANE",
    Latitude: "-25.7619",
    Longitude: "28.2939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8657",
    WardLookupId: "2609",
    VDNumber: "32953192",
    RegisteredPopulation: "934",
    VotingStationName: "THE GLEN HIGH SCHOOL",
    Address: "GRASFONTEIN RD & COROBAY AVENU  WATERKLOOF GLEN  TSHWANE",
    Latitude: "-25.79",
    Longitude: "28.2791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8658",
    WardLookupId: "2610",
    VDNumber: "32950885",
    RegisteredPopulation: "2878",
    VotingStationName: "LAERSKOOL ELARDUSPARK",
    Address: "548 ALBERTON STREET  ELARDUSPARK  PRETORIA",
    Latitude: "-25.8292",
    Longitude: "28.2606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8659",
    WardLookupId: "2610",
    VDNumber: "32950997",
    RegisteredPopulation: "4361",
    VotingStationName: "CHOOSE LIFE CHURCH",
    Address: "589 CNR RUBENSTEIN & NORVAL STREET  MORELETA PARK  PRETORIA",
    Latitude: "-25.8197",
    Longitude: "28.2842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8660",
    WardLookupId: "2610",
    VDNumber: "32951000",
    RegisteredPopulation: "3436",
    VotingStationName: "KLEUTERGENOT PRE PRIMARY SCHOOL",
    Address: "JOCHEM & PETRUS  ELARDUS PARK  PRETORIA",
    Latitude: "-25.8483",
    Longitude: "28.2624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8661",
    WardLookupId: "2610",
    VDNumber: "32951066",
    RegisteredPopulation: "2492",
    VotingStationName: "PRETORIA CHINESE SCHOOL",
    Address: "894 HALSE STREET   WINGATE PARK  TSHWANE",
    Latitude: "-25.8281",
    Longitude: "28.2672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8662",
    WardLookupId: "2610",
    VDNumber: "32953305",
    RegisteredPopulation: "932",
    VotingStationName: "TENT AT PIXIE LAND FARM SCHOOL",
    Address: "731 PETRUS STREET   RIETVALLEIRAND   PRETORIA",
    Latitude: "-25.84",
    Longitude: "28.2589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8663",
    WardLookupId: "2610",
    VDNumber: "32953316",
    RegisteredPopulation: "1126",
    VotingStationName: "NH GEMEENTE RIETVALLEI",
    Address: "679 CNR PIROKSEEN & SERPENTINE STR  ELARDUSPARK  PRETORIA",
    Latitude: "-25.8412",
    Longitude: "28.2656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8664",
    WardLookupId: "2611",
    VDNumber: "32980237",
    RegisteredPopulation: "6203",
    VotingStationName: "HEUWELOORD COMMUNITY TEST CENTRE",
    Address: "C/O KOORSBOOM AND WILDEPERSKE  HEUWELOORD  CENTURION",
    Latitude: "-25.8829",
    Longitude: "28.109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8665",
    WardLookupId: "2611",
    VDNumber: "32980293",
    RegisteredPopulation: "1784",
    VotingStationName: "TENT AT MNANDI SPAR",
    Address: "0 TULIP ROAD  CENTURION  MNANDI",
    Latitude: "-25.882",
    Longitude: "28.0852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8666",
    WardLookupId: "2611",
    VDNumber: "32980327",
    RegisteredPopulation: "806",
    VotingStationName: "FERN GULLY NURSERY SCHOOL",
    Address: "3RD STREET  CENTURION  GERHARDSVILLE",
    Latitude: "-25.8489",
    Longitude: "28.0297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8667",
    WardLookupId: "2611",
    VDNumber: "32980338",
    RegisteredPopulation: "2886",
    VotingStationName: "TSHWARANANG DAY CARE",
    Address: "MOOIPLAAS ENTRANCE  CENTURION  MOOIPLAAS",
    Latitude: "-25.848",
    Longitude: "28.0956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8668",
    WardLookupId: "2611",
    VDNumber: "32980349",
    RegisteredPopulation: "1123",
    VotingStationName: "TENT - 114 CRECH MISSION",
    Address: "114 LLOYS ELLIS AVE  CENTURION  MOOIPLAATS FARM, KNOPPIESLAAGTE",
    Latitude: "-25.8749",
    Longitude: "28.0745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8669",
    WardLookupId: "2611",
    VDNumber: "32980439",
    RegisteredPopulation: "5575",
    VotingStationName: "TENT - CNR LENCHEN & CAPENSIS",
    Address: "OPEN SPACE CNR LENCHEN & CAPEN  AMBERFIELD HEIGHTS  CENTURION",
    Latitude: "-25.8816",
    Longitude: "28.1371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8670",
    WardLookupId: "2611",
    VDNumber: "33280028",
    RegisteredPopulation: "1018",
    VotingStationName: "LAEZONIA LIGHTHOUSE CHURCH",
    Address: "LAEZONIA PLOT 157  CENTURION  LAEZONIA PLOT 157",
    Latitude: "-25.9074",
    Longitude: "28.029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8671",
    WardLookupId: "2611",
    VDNumber: "33280039",
    RegisteredPopulation: "532",
    VotingStationName: "HENNOPSRIVIER PRIMARY SCHOOL",
    Address: "PLOT 489  HENNOPSRIVIER  PRETORIA",
    Latitude: "-25.8266",
    Longitude: "27.9739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8672",
    WardLookupId: "2611",
    VDNumber: "33280141",
    RegisteredPopulation: "346",
    VotingStationName: "MONAGHAN FARM CURRO SCHOOL",
    Address: "MONAGHAN FARM  CENTURION  MONAGHAN FARM",
    Latitude: "-25.9162",
    Longitude: "27.925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8673",
    WardLookupId: "2612",
    VDNumber: "33160014",
    RegisteredPopulation: "1873",
    VotingStationName: "MOKONYAMA PRIMARY SCHOOL",
    Address: "934 BLOCK E  MANDELA-VILLAGE  HAMMANSKRAAL",
    Latitude: "-25.3951",
    Longitude: "28.2954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8674",
    WardLookupId: "2612",
    VDNumber: "33160025",
    RegisteredPopulation: "2099",
    VotingStationName: "HAMMANSKRAAL SECONDARY SCHOOL",
    Address: "354 LOVERS LANE   HAMMANSKRAAL  MANDELA VILLAGE",
    Latitude: "-25.403",
    Longitude: "28.2874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8675",
    WardLookupId: "2612",
    VDNumber: "33170060",
    RegisteredPopulation: "1124",
    VotingStationName: "NG KERK PETRONELLA",
    Address: "120 MAVOUREEN ROAD  WATERVAL  PRETORIA",
    Latitude: "-25.5495",
    Longitude: "28.2459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8676",
    WardLookupId: "2612",
    VDNumber: "33170071",
    RegisteredPopulation: "496",
    VotingStationName: "TENT-WALMANSTHALL",
    Address: "WALMANSTHALL  NOKENG",
    Latitude: "-25.5329",
    Longitude: "28.3537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8677",
    WardLookupId: "2612",
    VDNumber: "33170105",
    RegisteredPopulation: "3316",
    VotingStationName: "VARSFONTEIN COMMUNITY TRANSFORMATION",
    Address: "2  PLOT JUBILEE ROAD LEHWELERENG  KANANA  TEMBA",
    Latitude: "-25.415",
    Longitude: "28.2616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8678",
    WardLookupId: "2612",
    VDNumber: "33170138",
    RegisteredPopulation: "881",
    VotingStationName: "WALMASTHAL DAY CARE CENTER",
    Address: "HOUSE NO2 WALMANTHAL   HAMMANSKRAAL  WALMANSTHAL",
    Latitude: "-25.5783",
    Longitude: "28.291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8679",
    WardLookupId: "2612",
    VDNumber: "33170172",
    RegisteredPopulation: "2486",
    VotingStationName: "LEHWELERENG HIGH SCHOOL",
    Address: "3685 EXT 2 KANANA VILLAGE  KANANA  HAMMANSKRAAL",
    Latitude: "-25.4102",
    Longitude: "28.2546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8680",
    WardLookupId: "2612",
    VDNumber: "33170183",
    RegisteredPopulation: "1269",
    VotingStationName: "KANANA P.SCHOOL",
    Address: "1090 WEST PROPER  KANANA   TEMBA",
    Latitude: "-25.4204",
    Longitude: "28.2652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8681",
    WardLookupId: "2612",
    VDNumber: "33170194",
    RegisteredPopulation: "758",
    VotingStationName: "NEW SPROUT CHURCH",
    Address: "1124 KANANA VILLABE   HAMMANSKRAAL  HAMANSKRAAL WEST",
    Latitude: "-25.4127",
    Longitude: "28.2471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8682",
    WardLookupId: "2612",
    VDNumber: "33170206",
    RegisteredPopulation: "236",
    VotingStationName: "TENT- ADAM MASEBE SPORTS GROUND",
    Address: "OPEN SPACE  HAMMANSKRAAL  GREENFIELD EXT 4",
    Latitude: "-25.404",
    Longitude: "28.2315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8683",
    WardLookupId: "2612",
    VDNumber: "86661232",
    RegisteredPopulation: "1668",
    VotingStationName: "COMMUNITY OFFICE",
    Address: "122 MAROKOLONG  HAMMANSKRAAL",
    Latitude: "-25.3899",
    Longitude: "28.2898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8684",
    WardLookupId: "2613",
    VDNumber: "32951639",
    RegisteredPopulation: "2230",
    VotingStationName: "NORTHRIDGE PRIMARY SCHOOL",
    Address: "BRAAM PRETORIUS STREET  WONDERBOOM  PRETORIA",
    Latitude: "-25.6854",
    Longitude: "28.2181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8685",
    WardLookupId: "2613",
    VDNumber: "32951684",
    RegisteredPopulation: "3728",
    VotingStationName: "HOÃ‹RSKOOL OVERKRUIN",
    Address: "CNR ALDO AND BRAAM PRETORIUS STREETS  WONDERBOOM  PRETORIA",
    Latitude: "-25.6853",
    Longitude: "28.2105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8686",
    WardLookupId: "2613",
    VDNumber: "32951842",
    RegisteredPopulation: "4057",
    VotingStationName: "WONDERBOOM LAERSKOOL",
    Address:
      "CNR BRAAM PRETORIUS AND JAN BOOYSEN STREETS  WONDERBOOM  PRETORIA",
    Latitude: "-25.6828",
    Longitude: "28.1998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8687",
    WardLookupId: "2613",
    VDNumber: "32951853",
    RegisteredPopulation: "4736",
    VotingStationName: "STEPHANUS ROOS LAERSKOOL",
    Address: "273 ANTUN STREET  SINOVILLE  PRETORIA",
    Latitude: "-25.6798",
    Longitude: "28.222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8688",
    WardLookupId: "2614",
    VDNumber: "32950739",
    RegisteredPopulation: "2327",
    VotingStationName: "SEAPARANKWE PRIMARY SCHOOL",
    Address: "CNR KHUDU AND MOKGATLE STREET  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.7773",
    Longitude: "28.0789",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8689",
    WardLookupId: "2614",
    VDNumber: "32950740",
    RegisteredPopulation: "2093",
    VotingStationName: "ZODWA SPECIAL SCHOOL",
    Address: "467 MAUNDE STREET  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.7758",
    Longitude: "28.0891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8690",
    WardLookupId: "2614",
    VDNumber: "32950751",
    RegisteredPopulation: "2459",
    VotingStationName: "ISAAC MORE PRIMARY SCHOOL",
    Address: "SEHULARO STREET  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.7736",
    Longitude: "28.081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8691",
    WardLookupId: "2614",
    VDNumber: "32950795",
    RegisteredPopulation: "3100",
    VotingStationName: "BOKGONI TECHNICAL SCHOOL",
    Address: "51 SEKHUKHUNI STREET  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.7648",
    Longitude: "28.0859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8692",
    WardLookupId: "2614",
    VDNumber: "32950807",
    RegisteredPopulation: "1742",
    VotingStationName: "JJ DE JONG PRIMARY SCHOOL",
    Address: "CNR MOTE AND TLALE STREET  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.763",
    Longitude: "28.0785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8693",
    WardLookupId: "2615",
    VDNumber: "32950357",
    RegisteredPopulation: "2929",
    VotingStationName: "HUIS VERGENOEG",
    Address: "830 CUNNINGHAM AVE.(CORNER 33RD AVE.)  VILLIERIA  PRETORIA",
    Latitude: "-25.7",
    Longitude: "28.241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8694",
    WardLookupId: "2615",
    VDNumber: "32950368",
    RegisteredPopulation: "3440",
    VotingStationName: "LAERSKOOL VILLIERIA",
    Address: "1032 BEN SWART STREET    VILLIERIA  PRETORIA",
    Latitude: "-25.7121",
    Longitude: "28.2356",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8695",
    WardLookupId: "2615",
    VDNumber: "32950391",
    RegisteredPopulation: "3024",
    VotingStationName: "TOTIUSDAL LAERSKOOL",
    Address: "1211 CUNNINGHAM AVENUE  WAVERLEY  PRETORIA",
    Latitude: "-25.7025",
    Longitude: "28.246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8696",
    WardLookupId: "2615",
    VDNumber: "32951101",
    RegisteredPopulation: "3550",
    VotingStationName: "LAERSKOOL VOORPOS",
    Address: "1354 CUNNINGHAM AVENUE  WAVERLEY  PRETORIA",
    Latitude: "-25.7023",
    Longitude: "28.2591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8697",
    WardLookupId: "2615",
    VDNumber: "32951875",
    RegisteredPopulation: "1091",
    VotingStationName: "TENT OPPOSITE VRYE GEREFORMEERDE KERK MARANATA",
    Address: "KOEKOEK ROAD  EAST LYNNE  PRETORIA",
    Latitude: "-25.7008",
    Longitude: "28.2724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8698",
    WardLookupId: "2616",
    VDNumber: "32950100",
    RegisteredPopulation: "1535",
    VotingStationName: "HILLVIEW HIGH SCHOOL",
    Address: "71 FRANZINA STREET  ROSEVILLE  PRETORIA",
    Latitude: "-25.7125",
    Longitude: "28.1761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8699",
    WardLookupId: "2616",
    VDNumber: "32950111",
    RegisteredPopulation: "1716",
    VotingStationName: "SKEMERVREUGDE DIENSSENTRUM (SILWERKROON)",
    Address: "325 BOOYSEN STEET  ELOFFSDAL  PRETORIA",
    Latitude: "-25.7109",
    Longitude: "28.1925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8700",
    WardLookupId: "2616",
    VDNumber: "32950256",
    RegisteredPopulation: "2506",
    VotingStationName: "HOÃ‹RSKOOL HENDRIK VERWOERD",
    Address: "C/O 18TH AVE. & PIERNEEF STREET  DEERNESS  PRETORIA",
    Latitude: "-25.724",
    Longitude: "28.218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8701",
    WardLookupId: "2616",
    VDNumber: "32950335",
    RegisteredPopulation: "1974",
    VotingStationName: "KORFBALVELD",
    Address: "C/O TENTH & FREDRIKA STR  GEZINA  PRETORIA",
    Latitude: "-25.7176",
    Longitude: "28.2061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8702",
    WardLookupId: "2616",
    VDNumber: "32950379",
    RegisteredPopulation: "2429",
    VotingStationName: "TOUTREK PARK",
    Address: "C/O FREDERIKA STR AND 14TH AVE  RIETFONTEIN  PRETORIA",
    Latitude: "-25.7179",
    Longitude: "28.2122",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8703",
    WardLookupId: "2616",
    VDNumber: "32951909",
    RegisteredPopulation: "2165",
    VotingStationName: "GENERAAL JACQUES PIENAAR LAERSKOOL",
    Address: "CNR 6TH AVENUE AND VAN HEERDEN STREET  CAPITAL PARK  PRETORIA",
    Latitude: "-25.7257",
    Longitude: "28.196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8704",
    WardLookupId: "2616",
    VDNumber: "32952900",
    RegisteredPopulation: "1320",
    VotingStationName: "NG GEMEENTE - VILLIERA",
    Address: "571 24TH STREET & BEN SWART  VILLIERIA  PRETORIA",
    Latitude: "-25.7129",
    Longitude: "28.2267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8705",
    WardLookupId: "2616",
    VDNumber: "32953114",
    RegisteredPopulation: "1100",
    VotingStationName: "DUTCH REFORMED CHURCH RIVIERA JACARANDA",
    Address: "125 BLAKE & ROSE STREET  RIETONDALE / RIVIERA  TSHWANE",
    Latitude: "-25.7313",
    Longitude: "28.2125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8706",
    WardLookupId: "2617",
    VDNumber: "32950076",
    RegisteredPopulation: "3104",
    VotingStationName: "BERGSIG LAERSKOOL",
    Address: "336 IRVINE AVENUE  MOUNTAIN VIEW  PRETORIA",
    Latitude: "-25.7001",
    Longitude: "28.1665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8707",
    WardLookupId: "2617",
    VDNumber: "32950302",
    RegisteredPopulation: "3193",
    VotingStationName: "MAYVILLE LAERSKOOL",
    Address: "159 LOUIS TRICHARDT STREET  MAYVILLE  PRETORIA",
    Latitude: "-25.7052",
    Longitude: "28.183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8708",
    WardLookupId: "2617",
    VDNumber: "32950313",
    RegisteredPopulation: "3992",
    VotingStationName: "LAERSKOOL WONDERBOOM SUID",
    Address: "785 TWAALDE LAAN  WONDERBOOM SUID  PRETORIA",
    Latitude: "-25.7056",
    Longitude: "28.2052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8709",
    WardLookupId: "2617",
    VDNumber: "32950324",
    RegisteredPopulation: "3222",
    VotingStationName: "MAGALIES SCHOOL (SONNESTRAAL)",
    Address: "717 TWENTY FIRST AVENUE  RIETFONTEIN  PRETORIA",
    Latitude: "-25.7071",
    Longitude: "28.2203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8710",
    WardLookupId: "2617",
    VDNumber: "32952528",
    RegisteredPopulation: "1857",
    VotingStationName: "BOEREFORT PRIMARY SCHOOL",
    Address: "WONDERBOOM  TSHWANE",
    Latitude: "-25.7003",
    Longitude: "28.1927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8711",
    WardLookupId: "2618",
    VDNumber: "32950043",
    RegisteredPopulation: "3103",
    VotingStationName: "TUINE HOÃ‹RSKOOL",
    Address: "TUNIE AND LOUISE STREET  HERCULES  PRETORIA",
    Latitude: "-25.7255",
    Longitude: "28.1425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8712",
    WardLookupId: "2618",
    VDNumber: "32950087",
    RegisteredPopulation: "4751",
    VotingStationName: "LAERSKOOL BOOYSENS",
    Address: "CNR ATTIE AND WILHELM STREETS  BOOYSENS  PRETORIA",
    Latitude: "-25.7083",
    Longitude: "28.1258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8713",
    WardLookupId: "2618",
    VDNumber: "32950144",
    RegisteredPopulation: "2688",
    VotingStationName: "WESTERLIG LAERSKOOL",
    Address: "215 MARSBURGLAAN  DANVILLE  PRETORIA",
    Latitude: "-25.7387",
    Longitude: "28.133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8714",
    WardLookupId: "2618",
    VDNumber: "32952539",
    RegisteredPopulation: "2985",
    VotingStationName: "HOÃ‹R TEGNIESE SKOOL PRETORIA - TUINE",
    Address: "1025 COMMERCIAL STREET   HERCULES  PRETORIA",
    Latitude: "-25.719",
    Longitude: "28.1319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8715",
    WardLookupId: "2618",
    VDNumber: "32952595",
    RegisteredPopulation: "1676",
    VotingStationName: "SUIDERBERG SECONDARY SCHOOL",
    Address: "DENYSSEN  SUIDERBERG  PRETORIA",
    Latitude: "-25.6973",
    Longitude: "28.1429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8716",
    WardLookupId: "2618",
    VDNumber: "32952629",
    RegisteredPopulation: "1158",
    VotingStationName: "AFM DANVILLE CHURCH",
    Address: "CNR VAN JAARSVELD & SHERIFF ST  DANVILLE  PRETORIA",
    Latitude: "-25.7365",
    Longitude: "28.1203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8717",
    WardLookupId: "2618",
    VDNumber: "33280051",
    RegisteredPopulation: "3331",
    VotingStationName: "KAMEELDRIFT PRIMARY SCHOOL",
    Address: "GEDEELTE 313, KAMEELDRIFT  KAMEELDRIFT  PRETORIA",
    Latitude: "-25.7119",
    Longitude: "28.0068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8718",
    WardLookupId: "2618",
    VDNumber: "33280152",
    RegisteredPopulation: "331",
    VotingStationName: "4 ETERNITY EVENT VENUE",
    Address: "33 POPLAR STREET  SWACINA PARK  PRETORIA",
    Latitude: "-25.7066",
    Longitude: "28.0518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8719",
    WardLookupId: "2619",
    VDNumber: "32950504",
    RegisteredPopulation: "2629",
    VotingStationName: "PRETORIA-OOS LAERSKOOL",
    Address: "209 ROPER STREET  BROOKLYN  PRETORIA",
    Latitude: "-25.7587",
    Longitude: "28.2301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8720",
    WardLookupId: "2619",
    VDNumber: "32950548",
    RegisteredPopulation: "1861",
    VotingStationName: "ANTON VAN WOUW LAERSKOOL",
    Address: "C/O LANGE & DEY STR  NIEUW MUCKLENEUK  PRETORIA",
    Latitude: "-25.7744",
    Longitude: "28.2322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8721",
    WardLookupId: "2619",
    VDNumber: "32950593",
    RegisteredPopulation: "3313",
    VotingStationName: "TENT - BELGRAVE SQUARE PARK",
    Address: "JAN SHOBA & PROSPECT ROAD  HATFIELD  PRETORIA",
    Latitude: "-25.7517",
    Longitude: "28.2396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8722",
    WardLookupId: "2619",
    VDNumber: "32952618",
    RegisteredPopulation: "2825",
    VotingStationName: "MUNICIPAL SWEMBAD",
    Address: "JAN SHOBA & DUXBURY HATFIELD  HATFIELD  TSHWANE",
    Latitude: "-25.7564",
    Longitude: "28.239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8723",
    WardLookupId: "2619",
    VDNumber: "32952911",
    RegisteredPopulation: "1164",
    VotingStationName: "BROOKLYN PRIMARY SCHOOL",
    Address: "279 MURRAY STREET  BROOKLYN  PRETORIA",
    Latitude: "-25.7654",
    Longitude: "28.243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8724",
    WardLookupId: "2620",
    VDNumber: "32980057",
    RegisteredPopulation: "5048",
    VotingStationName: "LAERSKOOL LOUIS LEIPOLDT",
    Address: "MONUMENT AVE  LYTTELTON  CENTURION",
    Latitude: "-25.8349",
    Longitude: "28.2041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8725",
    WardLookupId: "2620",
    VDNumber: "32980068",
    RegisteredPopulation: "3114",
    VotingStationName: "LYTTELTON PRIMARY SCHOOL",
    Address: "C/O MONUMENT & POTGIETER STREETS  LYTTELTON  CENTURION",
    Latitude: "-25.8267",
    Longitude: "28.2002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8726",
    WardLookupId: "2620",
    VDNumber: "32980125",
    RegisteredPopulation: "5426",
    VotingStationName: "MUNICIPAL BANQUET HALL",
    Address: "C/O RABIE STREET & BASDEN AVE  DIE HOEWES  CENTURION",
    Latitude: "-25.8342",
    Longitude: "28.1959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8727",
    WardLookupId: "2621",
    VDNumber: "32950054",
    RegisteredPopulation: "4662",
    VotingStationName: "PRETORIA-WES HOÃ‹RSKOOL",
    Address: "NIC V VUUREN OORD  PRETORIA WEST  PRETORIA",
    Latitude: "-25.7406",
    Longitude: "28.1576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8728",
    WardLookupId: "2621",
    VDNumber: "32950199",
    RegisteredPopulation: "1040",
    VotingStationName: "MC MOTOR CORP",
    Address: "204 VOM HAGEN STREET  PRETORIA WEST X1  PRETORIA",
    Latitude: "-25.7434",
    Longitude: "28.1668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8729",
    WardLookupId: "2621",
    VDNumber: "32950346",
    RegisteredPopulation: "2204",
    VotingStationName: "RIVIERA LAERSKOOL",
    Address: "6 ROSE STREET  RIVIERA  PRETORIA",
    Latitude: "-25.7303",
    Longitude: "28.2063",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8730",
    WardLookupId: "2621",
    VDNumber: "32950380",
    RegisteredPopulation: "3451",
    VotingStationName: "LAERSKOOL EENDRACHT",
    Address: "89 JOHANNES RAMOKHOASE  PRETORIA CENTRAL  PRETORIA",
    Latitude: "-25.7435",
    Longitude: "28.1849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8731",
    WardLookupId: "2621",
    VDNumber: "32952382",
    RegisteredPopulation: "1473",
    VotingStationName: "PHILIP NEL FIRE STATION",
    Address: "MORKEL STREET  PHILLIP NEL  TSHWANE",
    Latitude: "-25.7408",
    Longitude: "28.1371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8732",
    WardLookupId: "2621",
    VDNumber: "32952393",
    RegisteredPopulation: "481",
    VotingStationName: "PRINSHOF SKOOL",
    Address: "19 PRINSHOF STREET  PRETORIA CENTRAL  TSHWANE",
    Latitude: "-25.7377",
    Longitude: "28.1957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8733",
    WardLookupId: "2621",
    VDNumber: "32952933",
    RegisteredPopulation: "1031",
    VotingStationName: "CAPTURED BY GRACE CHRISTIAN CENTRE",
    Address: "13 PRETORIUS STREET  PRINCESS PARK  PRETORIA",
    Latitude: "-25.7492",
    Longitude: "28.179",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8734",
    WardLookupId: "2622",
    VDNumber: "32950289",
    RegisteredPopulation: "2442",
    VotingStationName: "LAERSKOOL PARATUS",
    Address: "HENRY WILLIAMS STREET  THABA TSWANE  PRETORIA",
    Latitude: "-25.7932",
    Longitude: "28.1376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8735",
    WardLookupId: "2622",
    VDNumber: "32950481",
    RegisteredPopulation: "2719",
    VotingStationName: "TENT (GEORGE BRINK SPORTS CLUB)",
    Address: "JORRISEN  SUNNYSIDE  PRETORIA",
    Latitude: "-25.7565",
    Longitude: "28.2136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8736",
    WardLookupId: "2622",
    VDNumber: "32950492",
    RegisteredPopulation: "2959",
    VotingStationName: "METHODIST CHURCH",
    Address: "CNR BOURKE AND JORISSEN STREETS  SUNNYSIDE  PRETORIA",
    Latitude: "-25.7562",
    Longitude: "28.208",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8737",
    WardLookupId: "2622",
    VDNumber: "32950526",
    RegisteredPopulation: "2773",
    VotingStationName: "TENT  AT MUCKLENEUK PARK",
    Address: "H/V LOVEDAY & WALKER  SUNNYSIDE  PRETORIA",
    Latitude: "-25.7597",
    Longitude: "28.2102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8738",
    WardLookupId: "2622",
    VDNumber: "32950537",
    RegisteredPopulation: "2771",
    VotingStationName: "DEUTSCHES ALTERSHEIM SCHOOL",
    Address: "73 OATES STREET  GROENKLOOF  PRETORIA",
    Latitude: "-25.7721",
    Longitude: "28.2198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8739",
    WardLookupId: "2623",
    VDNumber: "32950223",
    RegisteredPopulation: "5747",
    VotingStationName: "CITY HALL",
    Address: "CNR VISAGIE & PAUL KRUGER   PRETORIA CENTRAL  TSHWANE",
    Latitude: "-25.7528",
    Longitude: "28.1871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8740",
    WardLookupId: "2623",
    VDNumber: "32951156",
    RegisteredPopulation: "3149",
    VotingStationName: "SIMON BEKKER LAERSKOOL",
    Address: "H/V SOUTER & REBECCA  PRETORIA WEST  PRETORIA",
    Latitude: "-25.7535",
    Longitude: "28.1533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8741",
    WardLookupId: "2623",
    VDNumber: "32951910",
    RegisteredPopulation: "2025",
    VotingStationName: "JOPIE FOURIE LAERSKOOL",
    Address: "173 5TH AVENUE  SALVOKOP  PRETORIA",
    Latitude: "-25.7631",
    Longitude: "28.1849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8742",
    WardLookupId: "2623",
    VDNumber: "32952922",
    RegisteredPopulation: "1077",
    VotingStationName: "TENT - LIEVAART STREET",
    Address: "LIEVAART STREET  PRETORIA WEST  PRETORIA",
    Latitude: "-25.7481",
    Longitude: "28.1383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8743",
    WardLookupId: "2624",
    VDNumber: "32980248",
    RegisteredPopulation: "2902",
    VotingStationName: "LAERSKOOL ERASMIA",
    Address: "C/O ELI & BODENSTEIN STREETS  ERASMIA  CENTURION",
    Latitude: "-25.8134",
    Longitude: "28.0956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8744",
    WardLookupId: "2624",
    VDNumber: "32980259",
    RegisteredPopulation: "1974",
    VotingStationName: "JACARANDA PRIMARY SCHOOL",
    Address: "330 LILAC STREET  LAUDIUM  CENTURION",
    Latitude: "-25.7941",
    Longitude: "28.1007",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8745",
    WardLookupId: "2624",
    VDNumber: "32980260",
    RegisteredPopulation: "1771",
    VotingStationName: "LAUDIUM COMMUNITY HALL",
    Address: "C/O SIXTH & FOURTH AVES  LAUDIUM  CENTURION",
    Latitude: "-25.7873",
    Longitude: "28.1067",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8746",
    WardLookupId: "2624",
    VDNumber: "32980271",
    RegisteredPopulation: "1791",
    VotingStationName: "LAUDIUM PRIMARY SCHOOL",
    Address: "C/O  15 TH STREET & CARMINE AVE  LAUDIUM  CENTURION",
    Latitude: "-25.787",
    Longitude: "28.1006",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8747",
    WardLookupId: "2624",
    VDNumber: "32980282",
    RegisteredPopulation: "1515",
    VotingStationName: "ROSINA SEDIBANE SCHOOL OF SPORTS",
    Address: "12 43 RD AVE  LAUDIUM  CENTURION",
    Latitude: "-25.7821",
    Longitude: "28.1094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8748",
    WardLookupId: "2624",
    VDNumber: "32980316",
    RegisteredPopulation: "2042",
    VotingStationName: "HIMALAYA SECONDARY SCHOOL",
    Address: "C/O BENGAL STREET & 37 TH AVE  LAUDIUM  CENTURION",
    Latitude: "-25.7829",
    Longitude: "28.1001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8749",
    WardLookupId: "2624",
    VDNumber: "32980350",
    RegisteredPopulation: "3996",
    VotingStationName: "TENT - IRELENG INFORMAL SETTLEMENT",
    Address: "REMAINDER OF PORTION 18  FARM MOOIPLAATS 355 JR  TSHWANE",
    Latitude: "-25.7868",
    Longitude: "28.0792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8750",
    WardLookupId: "2625",
    VDNumber: "32950683",
    RegisteredPopulation: "1053",
    VotingStationName: "KGABO PRIMARY SCHOOL",
    Address: "6 MAMPANE  STREET  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.7734",
    Longitude: "28.0672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8751",
    WardLookupId: "2625",
    VDNumber: "32950717",
    RegisteredPopulation: "2409",
    VotingStationName: "PATOGENG PRIMARY SCHOOL",
    Address: "30A KHUDU STR  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.7788",
    Longitude: "28.0699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8752",
    WardLookupId: "2625",
    VDNumber: "32950728",
    RegisteredPopulation: "2021",
    VotingStationName: "MABAFENG PRIMARY SCHOOL",
    Address: "CNR RAMATSUI AND TSHITSHIDI STREETS  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.7772",
    Longitude: "28.065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8753",
    WardLookupId: "2625",
    VDNumber: "32950762",
    RegisteredPopulation: "2298",
    VotingStationName: "MATSEKE PRIMARY SCHOOL",
    Address: "THINDISA AND GOMBA STREETS  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.7736",
    Longitude: "28.075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8754",
    WardLookupId: "2625",
    VDNumber: "32950773",
    RegisteredPopulation: "2503",
    VotingStationName: "HOFMEYER HIGH SCHOOL",
    Address: "CNR. MAFOLE & MNGADI STREET  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.7693",
    Longitude: "28.073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8755",
    WardLookupId: "2625",
    VDNumber: "32950784",
    RegisteredPopulation: "2125",
    VotingStationName: "GAEGOLELWE ADULT CENTRE",
    Address: "1A MOTSEPE STREET  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.772",
    Longitude: "28.0709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8756",
    WardLookupId: "2626",
    VDNumber: "32950649",
    RegisteredPopulation: "2103",
    VotingStationName: "THOHO YANDOU PRIMARY SCHOOL",
    Address: "CNR LETSATSI AND KGAKA STREETS  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.7708",
    Longitude: "28.0507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8757",
    WardLookupId: "2626",
    VDNumber: "32950650",
    RegisteredPopulation: "1373",
    VotingStationName: "ESIKHISINI COMBINED SCHOOL",
    Address: "2 LEBOA STREET  SAULSVILLE  PRETORIA",
    Latitude: "-25.7682",
    Longitude: "28.0527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8758",
    WardLookupId: "2626",
    VDNumber: "32951965",
    RegisteredPopulation: "2857",
    VotingStationName: "SAULRIDGE HIGH SCHOOL",
    Address: "156 SEKHU STREET  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.7665",
    Longitude: "28.0653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8759",
    WardLookupId: "2626",
    VDNumber: "32952270",
    RegisteredPopulation: "1493",
    VotingStationName: "BUD MBELLE PRIMARY SCHOOL",
    Address:
      "CNR ZITHA & SEHLOHO STREET  ATTERIDGEVILLE  TSHWANE METRO [PRETORIA]",
    Latitude: "-25.7679",
    Longitude: "28.0659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8760",
    WardLookupId: "2626",
    VDNumber: "32952281",
    RegisteredPopulation: "3768",
    VotingStationName: "SAULSVILLE HOSTEL",
    Address:
      "CNR. MTHIMKHULU AND MAMOGOALE STREET  SAULSVILLE, ATTERIDGEVILLE  TSHWANE METRO [PRETORIA]",
    Latitude: "-25.7681",
    Longitude: "28.0602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8761",
    WardLookupId: "2627",
    VDNumber: "32980091",
    RegisteredPopulation: "4341",
    VotingStationName: "LAERSKOOL ROOIHUISKRAAL",
    Address: "C/O PANORAMA & MARABOE AVES  ROOIHUISKRAAL  CENTURION",
    Latitude: "-25.8894",
    Longitude: "28.1526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8762",
    WardLookupId: "2627",
    VDNumber: "32980103",
    RegisteredPopulation: "3305",
    VotingStationName: "AFM ROOIHUISKRAAL COMMUNITY CHURCH",
    Address: "CNR KESTRAL & KRANSVOEL  ROOIHUISKRAAL  CENTURION",
    Latitude: "-25.8968",
    Longitude: "28.1489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8763",
    WardLookupId: "2627",
    VDNumber: "32980170",
    RegisteredPopulation: "3694",
    VotingStationName: "LAERSKOOL UITSIG",
    Address: "46 YELANDA STREET  THE REEDS  CENTURION",
    Latitude: "-25.8994",
    Longitude: "28.131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8764",
    WardLookupId: "2627",
    VDNumber: "32980204",
    RegisteredPopulation: "3210",
    VotingStationName: "HOÃ‹RSKOOL UITSIG",
    Address: "47 MARQUARD STREET  THE REEDS  CENTURION",
    Latitude: "-25.8978",
    Longitude: "28.1386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8765",
    WardLookupId: "2628",
    VDNumber: "32980035",
    RegisteredPopulation: "5692",
    VotingStationName: "IRENE PRIMARY SCHOOL",
    Address: "BRUCE RD  IRENE  CENTURION",
    Latitude: "-25.872",
    Longitude: "28.2218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8766",
    WardLookupId: "2628",
    VDNumber: "32980079",
    RegisteredPopulation: "5735",
    VotingStationName: "LAERSKOOL DORINGKLOOF",
    Address: "164 CECILE STREET  DORINGKLOOF  CENTURION",
    Latitude: "-25.8589",
    Longitude: "28.2051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8767",
    WardLookupId: "2628",
    VDNumber: "32980383",
    RegisteredPopulation: "5514",
    VotingStationName: "TENT - CENTURION TESTING GROUND",
    Address: "0 NELLMAPIUS ROAD  HIGHVELD TECHNO PARK  CENTURION",
    Latitude: "-25.8911",
    Longitude: "28.1824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8768",
    WardLookupId: "2628",
    VDNumber: "32980440",
    RegisteredPopulation: "1050",
    VotingStationName: "IRENE MIDDLE SCHOOL",
    Address: "CNR NELMAPIUS & JAN SMUTS AVE  CENTURION  IRENE",
    Latitude: "-25.8777",
    Longitude: "28.228",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8769",
    WardLookupId: "2628",
    VDNumber: "33110132",
    RegisteredPopulation: "973",
    VotingStationName: "ST GEORGES HOTEL",
    Address: "58 PTA/KMPTONPARK ROAD  DOORNKLOOF  KUNGWINI",
    Latitude: "-25.8928",
    Longitude: "28.253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8770",
    WardLookupId: "2629",
    VDNumber: "32950267",
    RegisteredPopulation: "4079",
    VotingStationName: "VHALLIESPARK PRIMARY SCHOOL",
    Address: "72 BOTHMA WEG  VALHALLA  PRETORIA",
    Latitude: "-25.8053",
    Longitude: "28.1529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8771",
    WardLookupId: "2629",
    VDNumber: "32951167",
    RegisteredPopulation: "2950",
    VotingStationName: "DEFENCE SPORTS CLUB",
    Address: "HENDRIK ALBERTS ROAD  VOORTREKKERHOOGTE  PRETORIA",
    Latitude: "-25.7872",
    Longitude: "28.1367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8772",
    WardLookupId: "2629",
    VDNumber: "32951943",
    RegisteredPopulation: "3315",
    VotingStationName: "LAERSKOOL SWARTKOP",
    Address: "C/O CAMBELL RD & VIKING RD  VALHALLA  PRETORIA",
    Latitude: "-25.8201",
    Longitude: "28.1464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8773",
    WardLookupId: "2629",
    VDNumber: "32980147",
    RegisteredPopulation: "4132",
    VotingStationName: "ALPHA CENTURION RUNNER CLUB",
    Address: "EDINBURGH AVE  CLUBVIEW  CENTURION",
    Latitude: "-25.837",
    Longitude: "28.1756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8774",
    WardLookupId: "2629",
    VDNumber: "32980518",
    RegisteredPopulation: "281",
    VotingStationName: "TENT - OPEN SPACE NEXT TO AIRSCHOOL SPORTS GROUND",
    Address: "68 SNAKE VALLEY ROAD  LYTTELTON  CENTURION",
    Latitude: "-25.8173",
    Longitude: "28.19",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8775",
    WardLookupId: "2630",
    VDNumber: "32951224",
    RegisteredPopulation: "2106",
    VotingStationName: "TENT AT SUNVALLEY OPEN SPACE OPPOSITE HOUSE NO 315",
    Address: "MAMELODI  TSHWANE",
    Latitude: "-25.7105",
    Longitude: "28.3284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8776",
    WardLookupId: "2630",
    VDNumber: "32951314",
    RegisteredPopulation: "1424",
    VotingStationName: "12 APOSTOLIC CHURCH",
    Address: "2004 CHAKA STREET  MAMELODI WEST  PRETORIA",
    Latitude: "-25.6985",
    Longitude: "28.3288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8777",
    WardLookupId: "2630",
    VDNumber: "32951325",
    RegisteredPopulation: "2050",
    VotingStationName: "EMTHUNZINI PRIMARY SCHOOL",
    Address: "1485 KUBONE STREET SECTION D  MAMELODI WEST  PRETORIA",
    Latitude: "-25.7061",
    Longitude: "28.3359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8778",
    WardLookupId: "2630",
    VDNumber: "32951370",
    RegisteredPopulation: "1455",
    VotingStationName: "SALVATION ARMY CHURCH",
    Address: "1477 BLOCK D, SIBANDE AVENUE  MAMELODI WEST  PRETORIA",
    Latitude: "-25.7031",
    Longitude: "28.3347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8779",
    WardLookupId: "2630",
    VDNumber: "32951381",
    RegisteredPopulation: "1935",
    VotingStationName: "ZAMINTUTHUKO PRIMARY SCHOOL",
    Address: "2329 SIBANDE STR  MAMELODI WEST, SECTION G  PRETORIA",
    Latitude: "-25.7031",
    Longitude: "28.3396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8780",
    WardLookupId: "2630",
    VDNumber: "32952944",
    RegisteredPopulation: "630",
    VotingStationName: "TENT AT SUNVALLEY PARK",
    Address: "MPHOKENG  MAMELODI SUN VALLEY  PRETORIA",
    Latitude: "-25.7086",
    Longitude: "28.3267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8781",
    WardLookupId: "2630",
    VDNumber: "32952955",
    RegisteredPopulation: "948",
    VotingStationName: "UMTHOMBO PRIMARY SCHOOL",
    Address: "MSAMANGA  MAMELODI WEST A1  PRETORIA",
    Latitude: "-25.7106",
    Longitude: "28.3353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8782",
    WardLookupId: "2631",
    VDNumber: "32950627",
    RegisteredPopulation: "2813",
    VotingStationName: "MASIZANE PRIMARY SCHOOL",
    Address: "CNR. MAKURUNTSI & MADISHA  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.7746",
    Longitude: "28.0497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8783",
    WardLookupId: "2631",
    VDNumber: "32950638",
    RegisteredPopulation: "3992",
    VotingStationName: "EDWARD PHATUDI HIGH SCHOOL",
    Address: "CNR. MAKHAZA & MAKURUNTSU STR  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.7775",
    Longitude: "28.0503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8784",
    WardLookupId: "2631",
    VDNumber: "32950661",
    RegisteredPopulation: "1382",
    VotingStationName: "KINGDOM LIFE CHILDREN CENTRE",
    Address: "64 MASUPA STREET  SAULSVILLE  TSHWANE",
    Latitude: "-25.7726",
    Longitude: "28.0527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8785",
    WardLookupId: "2631",
    VDNumber: "32950672",
    RegisteredPopulation: "2412",
    VotingStationName: "MAKGATHO PRIMARY SCHOOL",
    Address: "CNR SAKWENG & MOFOKENG  SAULSVILLE  PRETORIA",
    Latitude: "-25.7745",
    Longitude: "28.062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8786",
    WardLookupId: "2631",
    VDNumber: "32951954",
    RegisteredPopulation: "2001",
    VotingStationName: "MAHLAHLE PRIMARY SCHOOL",
    Address: "MOLAMBO STR  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.7789",
    Longitude: "28.0538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8787",
    WardLookupId: "2631",
    VDNumber: "32952540",
    RegisteredPopulation: "1323",
    VotingStationName: "PHUTHADITJHABA PRIMARY SCHOOL",
    Address:
      "CNR 4TH & 8TH AVENUE  EXTENTION 7, ATTERIDGEVILLE WEST  SAULSVILLE",
    Latitude: "-25.7824",
    Longitude: "28.0465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8788",
    WardLookupId: "2631",
    VDNumber: "32952551",
    RegisteredPopulation: "861",
    VotingStationName: "MAHLALA EGHODWENI DISTRIBUTION",
    Address: "CNR. MOGOTLHORI & VERGENOEG  ATTERIDGEVILLE  ATTERIDGEVILLE WEST",
    Latitude: "-25.7829",
    Longitude: "28.0361",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8789",
    WardLookupId: "2632",
    VDNumber: "32980169",
    RegisteredPopulation: "3980",
    VotingStationName: "TENT AT CENTURION WRESTLING HALL",
    Address: "CNR EDWARD AND IRELAND   ELDORAIGNE  CENTURION",
    Latitude: "-25.8486",
    Longitude: "28.1543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8790",
    WardLookupId: "2632",
    VDNumber: "32980181",
    RegisteredPopulation: "4309",
    VotingStationName: "NG WIERDA PARK",
    Address: "CNR KORT & PIET HUGO  ELDORAIGNE/WIERDA PARK  PRETORIA",
    Latitude: "-25.8555",
    Longitude: "28.1514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8791",
    WardLookupId: "2632",
    VDNumber: "32980215",
    RegisteredPopulation: "6014",
    VotingStationName: "LAERSKOOL BAKENKOP",
    Address: "5 UNDERBERG STREET  WIERDA PARK  CENTURION",
    Latitude: "-25.8692",
    Longitude: "28.1533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8792",
    WardLookupId: "2633",
    VDNumber: "32980192",
    RegisteredPopulation: "4961",
    VotingStationName: "LAERSKOOL WIERDAPARK",
    Address: "256 WILLEM BOTHA STREET  WIERDA PARK  CENTURION",
    Latitude: "-25.8542",
    Longitude: "28.1423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8793",
    WardLookupId: "2633",
    VDNumber: "32980226",
    RegisteredPopulation: "3312",
    VotingStationName: "TENT - ZWARTKOP LAPA",
    Address: "PORTION 307 (ZWARTKOP LAPA)  FARM ZWARTKOP 356 JR  CENTURION",
    Latitude: "-25.8362",
    Longitude: "28.1235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8794",
    WardLookupId: "2633",
    VDNumber: "32980361",
    RegisteredPopulation: "3085",
    VotingStationName: "SYTZE WIERDA SPORTS AND CULTURAL CLUB",
    Address: "284 FRIEDERICHE STREET  WIERDA PARK  CENTURION",
    Latitude: "-25.8581",
    Longitude: "28.1477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8795",
    WardLookupId: "2633",
    VDNumber: "32980406",
    RegisteredPopulation: "2580",
    VotingStationName: "SUTHERLAND HIGH SCHOOL",
    Address: "00 WILLEM BOTHA  ELDORAIGNE  CENTURION",
    Latitude: "-25.8413",
    Longitude: "28.1437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8796",
    WardLookupId: "2633",
    VDNumber: "32980495",
    RegisteredPopulation: "2166",
    VotingStationName: "WIERDA INDEPENDENT SCHOOL",
    Address: "474 THEUNS VAN NIEKERK   ROIHUISKRAL NORTH  WIERDA PARK SOUTH",
    Latitude: "-25.8766",
    Longitude: "28.1512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8797",
    WardLookupId: "2634",
    VDNumber: "32951774",
    RegisteredPopulation: "2425",
    VotingStationName: "MODIRWADI DAY CARE",
    Address: "ATTRIDGEVILLE  PRETORIA  TSHWANE",
    Latitude: "-25.7853",
    Longitude: "28.0498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8798",
    WardLookupId: "2634",
    VDNumber: "32951785",
    RegisteredPopulation: "4415",
    VotingStationName: "TENT - PRIVATE OPEN STAND 124",
    Address: "PHOMOLONG  ATTRIDGEVILLE  TSHWANE",
    Latitude: "-25.7845",
    Longitude: "28.0466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8799",
    WardLookupId: "2634",
    VDNumber: "32951796",
    RegisteredPopulation: "5349",
    VotingStationName: "LEDWABA FUNERAL UNDERTAKERS HALL (PHOMOLONG)",
    Address: "MAHLOGONOLO LEDWABA CENTRE  SAULSVILLE  PRETORIA",
    Latitude: "-25.7859",
    Longitude: "28.0429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8800",
    WardLookupId: "2635",
    VDNumber: "32950694",
    RegisteredPopulation: "2212",
    VotingStationName: "NWA MHINGA PRIMARY SCHOOL",
    Address: "NTSU STREET  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.7794",
    Longitude: "28.0597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8801",
    WardLookupId: "2635",
    VDNumber: "32950706",
    RegisteredPopulation: "2374",
    VotingStationName: "MAKGWARANENG PRIMARY SCHOOL",
    Address: "CNR. TSITOE STREET AND TSHEPE STREET  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.7808",
    Longitude: "28.0657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8802",
    WardLookupId: "2635",
    VDNumber: "32951752",
    RegisteredPopulation: "4177",
    VotingStationName: "MOTSWEDING PRIMARY SCHOOL",
    Address: "00 NTSU AND PHATLALATSANE  ATTERIDGEVILLE  ATTERIDGEVILLE",
    Latitude: "-25.7826",
    Longitude: "28.0604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8803",
    WardLookupId: "2635",
    VDNumber: "32951763",
    RegisteredPopulation: "2861",
    VotingStationName: "TENT - OPEN-SPACE NEXT TO DR MABE",
    Address: "MAUNDE  ATTRIDGEVILLE  TSHWANE",
    Latitude: "-25.7822",
    Longitude: "28.0532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8804",
    WardLookupId: "2636",
    VDNumber: "33170048",
    RegisteredPopulation: "3035",
    VotingStationName: "STEVE BIKO MULTIPURPOSE CENTRE",
    Address: "RUST DE WINTER ROAD  STEVE BIKO  KEKANA",
    Latitude: "-25.3788",
    Longitude: "28.3077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8805",
    WardLookupId: "2636",
    VDNumber: "33170127",
    RegisteredPopulation: "1248",
    VotingStationName: "ST CAMILLIS SKOOL",
    Address: "PLOT 92 KLIPDRIFT 121JR  KLIPDRIFT  PRETORIA",
    Latitude: "-25.3904",
    Longitude: "28.301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8806",
    WardLookupId: "2636",
    VDNumber: "33170150",
    RegisteredPopulation: "2006",
    VotingStationName: "DITEBOGO DAY CARE CENTER",
    Address: "1338 BLOCK L  KEKANA GARDEN  TEMBA",
    Latitude: "-25.3702",
    Longitude: "28.3016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8807",
    WardLookupId: "2636",
    VDNumber: "33170161",
    RegisteredPopulation: "1292",
    VotingStationName: "HOLINESS CHURCH",
    Address: "NO1 BLOCK L  KEKANA GARDEN  HAMMANSKRAAL",
    Latitude: "-25.3688",
    Longitude: "28.3123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8808",
    WardLookupId: "2636",
    VDNumber: "86661153",
    RegisteredPopulation: "1176",
    VotingStationName: "MOKONYAMA PRIMARY SCHOOL",
    Address: "1900B RAMOTSE  HAMMANSKRAAL",
    Latitude: "-25.3435",
    Longitude: "28.2914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8809",
    WardLookupId: "2636",
    VDNumber: "86661164",
    RegisteredPopulation: "1356",
    VotingStationName: "BAPTIST CHURCH",
    Address: "1801 STAND NO 72   RAMOTSE  HAMMANSKRAAL",
    Latitude: "-25.3458",
    Longitude: "28.2866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8810",
    WardLookupId: "2636",
    VDNumber: "86661186",
    RegisteredPopulation: "1472",
    VotingStationName: "LEBELO PRIMARY SCHOOL",
    Address: "1212 RAMOTSE   RAMOTSE VILLAGE  TEMBA",
    Latitude: "-25.357",
    Longitude: "28.2949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8811",
    WardLookupId: "2636",
    VDNumber: "86661197",
    RegisteredPopulation: "1285",
    VotingStationName: "LETHAMAGE MIDDLE SCHOOL",
    Address: "1436 RAMOTSE  TEMBA",
    Latitude: "-25.3741",
    Longitude: "28.298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8812",
    WardLookupId: "2636",
    VDNumber: "86661221",
    RegisteredPopulation: "1201",
    VotingStationName: "HOSIYA KEKANA HIGH SCHOOL",
    Address: "1968 RAMOTSE VILLAGE   RAMOTSE  HAMMANSKRAAL",
    Latitude: "-25.3563",
    Longitude: "28.2866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8813",
    WardLookupId: "2636",
    VDNumber: "86661243",
    RegisteredPopulation: "1949",
    VotingStationName: "BOITSHEPO MIDDLE SCHOOL",
    Address: "1468 BABELEGI STREET   MAROKOLONG  HAMANSKRAAL",
    Latitude: "-25.3712",
    Longitude: "28.2873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8814",
    WardLookupId: "2637",
    VDNumber: "86661210",
    RegisteredPopulation: "1892",
    VotingStationName: "MAROKOLONG PRIMARY SCHOOL",
    Address: "338 MAROKOLONG  HAMANSKRAAL",
    Latitude: "-25.3845",
    Longitude: "28.2887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8815",
    WardLookupId: "2637",
    VDNumber: "86720025",
    RegisteredPopulation: "1229",
    VotingStationName: "TENT-OPEN AREA NEXT TO GOLD STAR",
    Address: "PORTION B  MAROKOLONG  HAMMANSKRAAL",
    Latitude: "-25.4017",
    Longitude: "28.2813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8816",
    WardLookupId: "2637",
    VDNumber: "86720081",
    RegisteredPopulation: "1937",
    VotingStationName: "RENEILWE PRIMARY SCHOOL",
    Address: "2472 UNIT 2  TEMBA  ",
    Latitude: "-25.3749",
    Longitude: "28.2632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8817",
    WardLookupId: "2637",
    VDNumber: "86720092",
    RegisteredPopulation: "2356",
    VotingStationName: "MAKGETSI HIGH SCHOOL",
    Address: "4070 UNIT 1  TEMBA  TEMBA",
    Latitude: "-25.3889",
    Longitude: "28.2675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8818",
    WardLookupId: "2637",
    VDNumber: "86720104",
    RegisteredPopulation: "1909",
    VotingStationName: "MOSALEDI PRIMARY SCHOOL",
    Address: "OLD TEMBA   TEMBA   TEMBA",
    Latitude: "-25.3842",
    Longitude: "28.2623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8819",
    WardLookupId: "2637",
    VDNumber: "86720115",
    RegisteredPopulation: "800",
    VotingStationName: "ST ANGLICAN CHURCH",
    Address: "1052 UNIT 2 TEMBA 0407  TEMBA   TEMBA",
    Latitude: "-25.392",
    Longitude: "28.2652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8820",
    WardLookupId: "2637",
    VDNumber: "86720126",
    RegisteredPopulation: "1667",
    VotingStationName: "MAKGAKE PRIMARY SCHOOL",
    Address: "9A  PORTION 9  MAROKOLONG  HAMMANSKRAAL",
    Latitude: "-25.3794",
    Longitude: "28.2815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8821",
    WardLookupId: "2637",
    VDNumber: "86720159",
    RegisteredPopulation: "711",
    VotingStationName: "KUDUBE PRIMARY SCHOOL",
    Address: "362 UNIT 1  TEMBA  TEMBA",
    Latitude: "-25.3951",
    Longitude: "28.2736",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8822",
    WardLookupId: "2638",
    VDNumber: "86720014",
    RegisteredPopulation: "2086",
    VotingStationName: "TENT-CHRIS HANI SPORTS GROUND",
    Address: "CHRIS HANI  CHRIS HANI INFORMAL SETTLEMENT  TEMBA",
    Latitude: "-25.3696",
    Longitude: "28.2539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8823",
    WardLookupId: "2638",
    VDNumber: "86720036",
    RegisteredPopulation: "1318",
    VotingStationName: "LEBONENG PRIMARY SCHOOL",
    Address: "3249 UNIT 3  TEMBA  TEMBA",
    Latitude: "-25.3661",
    Longitude: "28.265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8824",
    WardLookupId: "2638",
    VDNumber: "86720047",
    RegisteredPopulation: "2061",
    VotingStationName: "PHL MORAKA HIGH SCHOOL",
    Address: "53 UNIT D  TEMBA  TEMBA",
    Latitude: "-25.3732",
    Longitude: "28.2476",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8825",
    WardLookupId: "2638",
    VDNumber: "86720070",
    RegisteredPopulation: "1676",
    VotingStationName: "RATSHEPO SCHOOL",
    Address: "2026 TEMBA 0407  TEMBA   TEMBA",
    Latitude: "-25.3789",
    Longitude: "28.2547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8826",
    WardLookupId: "2638",
    VDNumber: "86720137",
    RegisteredPopulation: "3140",
    VotingStationName: "SIKHULULEKILE HIGH SCHOOL",
    Address: "4113 UNIT D EXT 6N  TEMBA  TEMBA",
    Latitude: "-25.3805",
    Longitude: "28.2488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8827",
    WardLookupId: "2638",
    VDNumber: "86720148",
    RegisteredPopulation: "2265",
    VotingStationName: "REFILWE DAY CARE CENTER",
    Address: "5648 UNIT 7A SECTION   TEMBA   TEMBA",
    Latitude: "-25.3751",
    Longitude: "28.2383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8828",
    WardLookupId: "2638",
    VDNumber: "86720171",
    RegisteredPopulation: "379",
    VotingStationName: "TSEBENTLHA MIDDLE SCHOOL",
    Address: "TEMBA   ",
    Latitude: "-25.3725",
    Longitude: "28.2543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8829",
    WardLookupId: "2638",
    VDNumber: "86720193",
    RegisteredPopulation: "1009",
    VotingStationName: "MMANKONE DAY CARE",
    Address: "961 HANS KEKANA VIEW  TEMBA  TEMBA",
    Latitude: "-25.3716",
    Longitude: "28.2396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8830",
    WardLookupId: "2638",
    VDNumber: "86720205",
    RegisteredPopulation: "244",
    VotingStationName: "TENT- OPEN SPACE UNIT 10 OPPOSITE OFFICE",
    Address: "CHRIS HANI UNIT 10  TEMBA  TEMBA",
    Latitude: "-25.3635",
    Longitude: "28.2519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8831",
    WardLookupId: "2639",
    VDNumber: "86661052",
    RegisteredPopulation: "1970",
    VotingStationName: "MABUATLOU PRIMARY SCHOOL",
    Address: "168 LEPHENGVILLE  MAJANENG  TEMBA",
    Latitude: "-25.3678",
    Longitude: "28.2224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8832",
    WardLookupId: "2639",
    VDNumber: "86661119",
    RegisteredPopulation: "2060",
    VotingStationName: "SPORTS GROUND MIKIMIKI",
    Address: "SECTION5 MASHEMONG MJANENG  MASHEMONG SECTION  MAJANENG",
    Latitude: "-25.3627",
    Longitude: "28.2141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8833",
    WardLookupId: "2639",
    VDNumber: "86661704",
    RegisteredPopulation: "1124",
    VotingStationName: "KEKANA PRIMARY SCHOOL",
    Address: "4609 PARKTOWN   MAJANENG  TEMBA",
    Latitude: "-25.3552",
    Longitude: "28.2484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8834",
    WardLookupId: "2639",
    VDNumber: "86662211",
    RegisteredPopulation: "703",
    VotingStationName: "SEROTE MOSETLHE PRIMARY SCHOOL",
    Address: "4751 MOSHATE SECTION  MAJANENG  TEMBA",
    Latitude: "-25.3578",
    Longitude: "28.251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8835",
    WardLookupId: "2639",
    VDNumber: "86662222",
    RegisteredPopulation: "2307",
    VotingStationName: "HANS KEKANA SCHOOL",
    Address: "4660 MAJANENG  TEMBA",
    Latitude: "-25.3633",
    Longitude: "28.236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8836",
    WardLookupId: "2639",
    VDNumber: "86662671",
    RegisteredPopulation: "1456",
    VotingStationName: "SECTION 3 WOLF CLUB",
    Address: "MAJANENG SECTION   TEMBA   TEMBA",
    Latitude: "-25.3607",
    Longitude: "28.2259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8837",
    WardLookupId: "2639",
    VDNumber: "86662873",
    RegisteredPopulation: "1451",
    VotingStationName: "TENT-SELOSESHA SPORTS GROUND",
    Address: "MAJANENG  TEMBA",
    Latitude: "-25.3531",
    Longitude: "28.2598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8838",
    WardLookupId: "2639",
    VDNumber: "86663212",
    RegisteredPopulation: "653",
    VotingStationName: "TENT - TREGINA",
    Address:
      "BLOCK 21 MASHIMONG SECTION 4  TEMBA  BLOCK 21 MASHIMONG SECTION 4",
    Latitude: "-25.3507",
    Longitude: "28.2218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8839",
    WardLookupId: "2639",
    VDNumber: "86663223",
    RegisteredPopulation: "753",
    VotingStationName: "TENT-PHOPHOLA SECTION 5 MASHIMONG",
    Address: "MASHIMONG  TEMBA  MASHIMONG SECTION 5",
    Latitude: "-25.3639",
    Longitude: "28.2057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8840",
    WardLookupId: "2639",
    VDNumber: "86663234",
    RegisteredPopulation: "89",
    VotingStationName: "TVS - BOSPLAAS GA MATHABE TVS",
    Address: "MASHEMONG  TEMBA   MASHEMONG",
    Latitude: "-25.3347",
    Longitude: "28.2006",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8841",
    WardLookupId: "2640",
    VDNumber: "32980114",
    RegisteredPopulation: "6658",
    VotingStationName: "OREFILE PRIMARY SCHOOL",
    Address: "EXT. 19  OLIEVENHOUTBOSCH  PRETORIA",
    Latitude: "-25.9122",
    Longitude: "28.1113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8842",
    WardLookupId: "2640",
    VDNumber: "32980428",
    RegisteredPopulation: "2817",
    VotingStationName: "TENT - CNR. ROOIHUISKRAAL & RIETSPRUIT ROAD",
    Address: "ROOIHUISKRAAL & RIETSPRUIT ROA  CENTURION  BLUE VALLEY",
    Latitude: "-25.9321",
    Longitude: "28.1314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8843",
    WardLookupId: "2640",
    VDNumber: "32980473",
    RegisteredPopulation: "3376",
    VotingStationName: "TENT - CNR. BRAKFONTEIN & DRAKENSBURG ROAD",
    Address: "CNR. OF BRAKFONTEIN & DRAKENSB  THATCHFIELD  PRETORIA",
    Latitude: "-25.9028",
    Longitude: "28.1078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8844",
    WardLookupId: "2640",
    VDNumber: "32980484",
    RegisteredPopulation: "1545",
    VotingStationName: "TENT-KOLGANS PARK",
    Address: "CRN KOLGANS AND ROOIHUISKRAAL   CENTURION  THE REEDS",
    Latitude: "-25.9067",
    Longitude: "28.1438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8845",
    WardLookupId: "2640",
    VDNumber: "32980507",
    RegisteredPopulation: "3082",
    VotingStationName: "TENT NEXT TO ARUNDO ESTATE",
    Address: "CENTURION  THE REEDS  THE REEDS",
    Latitude: "-25.9121",
    Longitude: "28.1348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8846",
    WardLookupId: "2641",
    VDNumber: "32980080",
    RegisteredPopulation: "4464",
    VotingStationName: "TENT - NEXT TO KERK SONDER MURE",
    Address: "C/O KORT & HENDRIK VERWOERD ROAD  CENTURION  PRETORIA",
    Latitude: "-25.8607",
    Longitude: "28.1772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8847",
    WardLookupId: "2641",
    VDNumber: "32980136",
    RegisteredPopulation: "3037",
    VotingStationName: "TENT (OPEN SPACE C/O LENCHEN AVE & JOHN VORSTER)",
    Address: "C/O LENCHEN AVE & JOHN VORSTER  CENTURION  CENTURION",
    Latitude: "-25.8614",
    Longitude: "28.1863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8848",
    WardLookupId: "2641",
    VDNumber: "32980158",
    RegisteredPopulation: "4452",
    VotingStationName: "HOÃ‹RSKOOL ZWARTKOP",
    Address: "C/O MOPANI AVE & OLD JHB RD  HENNOPSPARK  CENTURION",
    Latitude: "-25.8498",
    Longitude: "28.166",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8849",
    WardLookupId: "2642",
    VDNumber: "32952854",
    RegisteredPopulation: "1609",
    VotingStationName: "NG KERK WATERKLOOFRIF",
    Address: "RIDGEVIEW KIERIEKLAPER STREET  WATERKLOOF  PRETORIA",
    Latitude: "-25.8143",
    Longitude: "28.2432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8850",
    WardLookupId: "2642",
    VDNumber: "32952898",
    RegisteredPopulation: "1155",
    VotingStationName: "TENT - PEFFER & VAN POMMEREN STREET",
    Address: "PEFFER & VAN POMMEREN  ELARDUSPARK  PRETORIA",
    Latitude: "-25.8302",
    Longitude: "28.2493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8851",
    WardLookupId: "2642",
    VDNumber: "32952966",
    RegisteredPopulation: "1956",
    VotingStationName: "OPEN SPACE CNR. BOEING & PIERING (TENT)",
    Address: "CNR. BOEING & PIERING  ELARDUS PARK  PRETORIA",
    Latitude: "-25.8356",
    Longitude: "28.2509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8852",
    WardLookupId: "2642",
    VDNumber: "32980013",
    RegisteredPopulation: "4097",
    VotingStationName: "N.G CHURCH PIERRE VAN RYNEVELD",
    Address: "14 BALTIMORE  PIERRE VAN RYNEVELD  CENTURION",
    Latitude: "-25.8379",
    Longitude: "28.2427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8853",
    WardLookupId: "2642",
    VDNumber: "32980024",
    RegisteredPopulation: "4631",
    VotingStationName: "PIERRE VAN RYNEVELD COMMUNITY CENTRE",
    Address: "FOUCHE STREET  PIERRE VAN RYNEVELD  CENTURION",
    Latitude: "-25.8429",
    Longitude: "28.2437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8854",
    WardLookupId: "2642",
    VDNumber: "32980046",
    RegisteredPopulation: "3756",
    VotingStationName: "FLEUR LAERSKOOL",
    Address: "236 HANS STRIJDOM STREET  LYTTELTON  CENTURION",
    Latitude: "-25.8202",
    Longitude: "28.21",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8855",
    WardLookupId: "2643",
    VDNumber: "32950458",
    RegisteredPopulation: "3865",
    VotingStationName: "LAERSKOOL OOST EIND",
    Address: "179 GERHARD MOERDYK STREET  PRETORIA  PRETORIA",
    Latitude: "-25.7568",
    Longitude: "28.1979",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8856",
    WardLookupId: "2643",
    VDNumber: "32951145",
    RegisteredPopulation: "7020",
    VotingStationName: "HAMILTON PRIMARY SCHOOL",
    Address: "VISAGIE STREET  PRETORIA CENTRAL  PRETORIA",
    Latitude: "-25.7516",
    Longitude: "28.1947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8857",
    WardLookupId: "2643",
    VDNumber: "32953327",
    RegisteredPopulation: "947",
    VotingStationName: "METHODIST CITY MISSION",
    Address: "258 BURGER PARK LANE  PRETORIA CENTRAL  PRETORIA",
    Latitude: "-25.7525",
    Longitude: "28.1915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8858",
    WardLookupId: "2644",
    VDNumber: "32950447",
    RegisteredPopulation: "2209",
    VotingStationName: "TENT AT CALEDONIAN SPORTS GROUNDS",
    Address: "PRETORIUS & NELSON MANDELA DRIVE  SUNNYSIDE  PRETORIA",
    Latitude: "-25.7482",
    Longitude: "28.1991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8859",
    WardLookupId: "2644",
    VDNumber: "32950470",
    RegisteredPopulation: "7837",
    VotingStationName: "TENT AT JUBILEE SQUARE PARK",
    Address: "CNR JORISSEN AND TROYE STREET  SUNNYSIDE  TSHWANE",
    Latitude: "-25.7545",
    Longitude: "28.2042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8860",
    WardLookupId: "2644",
    VDNumber: "32952977",
    RegisteredPopulation: "1822",
    VotingStationName: "HARMONIE HOF HOME OF THE ELDERLY",
    Address: "JOUBERT STREET  SUNNYSIDE  PRETORIA",
    Latitude: "-25.7537",
    Longitude: "28.2008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8861",
    WardLookupId: "2644",
    VDNumber: "32953338",
    RegisteredPopulation: "336",
    VotingStationName: "ST MICHAEL AND ALL ANGELS ANGLICAN CHURCH",
    Address: "280 KOZTE STREET  PRETORIA  SUNNYSIDE",
    Latitude: "-25.7543",
    Longitude: "28.2066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8862",
    WardLookupId: "2645",
    VDNumber: "32950605",
    RegisteredPopulation: "2970",
    VotingStationName: "LAERSKOOL LYNNWOOD",
    Address: "450 RODERICKS STREET  LYNNWOOD  PRETORIA",
    Latitude: "-25.761",
    Longitude: "28.2684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8863",
    WardLookupId: "2645",
    VDNumber: "32950616",
    RegisteredPopulation: "4669",
    VotingStationName: "LAERSKOOL MENLOPARK",
    Address: "10TH STREET  MENLO PARK  PRETORIA",
    Latitude: "-25.7678",
    Longitude: "28.2551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8864",
    WardLookupId: "2645",
    VDNumber: "32950863",
    RegisteredPopulation: "2939",
    VotingStationName: "LYNNWOOD CATHOLIC CHURCH",
    Address: "321 BORDER ROAD EAST  LYNNWOOD  PRETORIA",
    Latitude: "-25.7674",
    Longitude: "28.2681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8865",
    WardLookupId: "2645",
    VDNumber: "32951178",
    RegisteredPopulation: "2079",
    VotingStationName: "PRO- ARTE SCHOOL",
    Address:
      "C/O ROELINE & SELATI ST (ENTRANCE SELATI STREET)  ALPHEN PARK  PRETORIA",
    Latitude: "-25.7816",
    Longitude: "28.2647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8866",
    WardLookupId: "2646",
    VDNumber: "32950212",
    RegisteredPopulation: "2560",
    VotingStationName: "WATERKLOOF HOÃ‹RSKOOL",
    Address: "C/O HANS STRYDOM DRIVE & BOEING STREET  ERASMUSKLOOF  PRETORIA",
    Latitude: "-25.8197",
    Longitude: "28.2562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8867",
    WardLookupId: "2646",
    VDNumber: "32950829",
    RegisteredPopulation: "2939",
    VotingStationName: "SCOUT CENTRE",
    Address:
      "C/O ANDRIES STRYDOM & WILLIAM NICOL STREETS  CONSTANTIA PARK  PRETORIA",
    Latitude: "-25.8109",
    Longitude: "28.2765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8868",
    WardLookupId: "2646",
    VDNumber: "32950896",
    RegisteredPopulation: "3594",
    VotingStationName: "RADEMEYERS VENUE CENTRE",
    Address: "1 HELIOS  MORELETA PARK  PRETORIA",
    Latitude: "-25.8154",
    Longitude: "28.2909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8869",
    WardLookupId: "2646",
    VDNumber: "32950908",
    RegisteredPopulation: "1965",
    VotingStationName: "CONSTANTIA PARK LAERSKOOL",
    Address: "ROVER STREET  CONSTANTIA PARK  PRETORIA",
    Latitude: "-25.8074",
    Longitude: "28.2831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8870",
    WardLookupId: "2647",
    VDNumber: "32950403",
    RegisteredPopulation: "2766",
    VotingStationName: "LAERSKOOL PIERNEEF",
    Address: "940 PIERNEEFSTRAAT  VILLIERIA  PRETORIA",
    Latitude: "-25.7216",
    Longitude: "28.2292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8871",
    WardLookupId: "2647",
    VDNumber: "32950414",
    RegisteredPopulation: "2289",
    VotingStationName: "QUEENSWOOD LAERSKOOL",
    Address: "1251 KIRKBY STREET  QUEENSWOOD  PRETORIA",
    Latitude: "-25.7322",
    Longitude: "28.2544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8872",
    WardLookupId: "2647",
    VDNumber: "32950436",
    RegisteredPopulation: "2107",
    VotingStationName: "TENT RIETONDALE PARK",
    Address: "NORTH STREET.PRETORIA NORTH.PR  RIETODALE  PRETORIA",
    Latitude: "-25.7317",
    Longitude: "28.2232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8873",
    WardLookupId: "2647",
    VDNumber: "32951011",
    RegisteredPopulation: "2637",
    VotingStationName: "HOÃ‹RSKOOL STAATSPRESIDENT CR SWART",
    Address: "1349 COLLINS AVENUE  WAVERLEY  PRETORIA",
    Latitude: "-25.7091",
    Longitude: "28.2575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8874",
    WardLookupId: "2647",
    VDNumber: "32951123",
    RegisteredPopulation: "2512",
    VotingStationName: "NELLIE SWART LAERSKOOL",
    Address: "1240 JANSEN STRAAT  QUEENSWOOD UITBR 2  PRETORIA",
    Latitude: "-25.7193",
    Longitude: "28.2551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8875",
    WardLookupId: "2647",
    VDNumber: "32952517",
    RegisteredPopulation: "808",
    VotingStationName: "CLAPHAM HIGH SCHOOL",
    Address: "SOUTPANSBERG ROAD  QUEENSWOOD  PRETORIA",
    Latitude: "-25.7311",
    Longitude: "28.2436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8876",
    WardLookupId: "2647",
    VDNumber: "32952988",
    RegisteredPopulation: "1564",
    VotingStationName: "TENT ON CNR DORMER & WEBB STREET",
    Address: "DORMER & WEBB  QUEENSWOOD  PRETORIA",
    Latitude: "-25.7275",
    Longitude: "28.2548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8877",
    WardLookupId: "2647",
    VDNumber: "32952999",
    RegisteredPopulation: "1006",
    VotingStationName: "OPEN SPACE ON CNR RAYNER & BELLIS (TENT)",
    Address: "CNR RAYNER & BELLIS  QUEENSWOOD  PRETORIA",
    Latitude: "-25.7271",
    Longitude: "28.263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8878",
    WardLookupId: "2648",
    VDNumber: "32950245",
    RegisteredPopulation: "3156",
    VotingStationName: "TENT AT CNR VAALKOP & TZANEEN STREET",
    Address: "CNR OF VAALKOP & TZANEEN STREET  FAERIE GLEN  PRETORIA",
    Latitude: "-25.7904",
    Longitude: "28.3315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8879",
    WardLookupId: "2648",
    VDNumber: "32950290",
    RegisteredPopulation: "3803",
    VotingStationName: "WILLOWRIDGE HIGH SCHOOL",
    Address: "518 VERKENNER AVE  DIE WILGERS  PRETORIA",
    Latitude: "-25.7588",
    Longitude: "28.3151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8880",
    WardLookupId: "2648",
    VDNumber: "32950571",
    RegisteredPopulation: "3050",
    VotingStationName: "WILLOWS METHODIST CHURCH",
    Address: "224 MEERLUST STREET  WILLOW GLEN A.H.  PRETORIA",
    Latitude: "-25.7719",
    Longitude: "28.3392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8881",
    WardLookupId: "2648",
    VDNumber: "32952304",
    RegisteredPopulation: "3143",
    VotingStationName: "STELLENBERG DEPOT",
    Address: "150 STELLENBERG ROAD  EQUASTRIA  TSHWANE METRO [PRETORIA]",
    Latitude: "-25.7644",
    Longitude: "28.3468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8882",
    WardLookupId: "2648",
    VDNumber: "32953002",
    RegisteredPopulation: "2007",
    VotingStationName: "STAR COLLEGE",
    Address: "CNR OF LYNNWOOD AND MEADOW AVE  WILLOW GLEN  TSWANE",
    Latitude: "-25.7688",
    Longitude: "28.3336",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8883",
    WardLookupId: "2648",
    VDNumber: "32953204",
    RegisteredPopulation: "742",
    VotingStationName: "TENT - MICA DISSELBOOM",
    Address: "DISSELBOOM & BUILGARD STREET  DISSELBOOM  TSHWANE",
    Latitude: "-25.7829",
    Longitude: "28.3438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8884",
    WardLookupId: "2648",
    VDNumber: "32953215",
    RegisteredPopulation: "1191",
    VotingStationName: "LASPOSSIES KLEUETERSKOOL.",
    Address: "907 PIVOTE PLATE  WAPADRAND SECURITY VILLAGE  TSHWANE",
    Latitude: "-25.7801",
    Longitude: "28.3399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8885",
    WardLookupId: "2648",
    VDNumber: "32953383",
    RegisteredPopulation: "1119",
    VotingStationName: "TENT ST ANNES ANGLICAN CHURCH",
    Address: "STELLENBERG ROAD  EQUESTRIA  PRETORIA",
    Latitude: "-25.7551",
    Longitude: "28.3347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8886",
    WardLookupId: "2649",
    VDNumber: "32950201",
    RegisteredPopulation: "4880",
    VotingStationName: "NELLMAPIUS PRIMARY SCHOOL",
    Address: "664 LOERIESFONTEIN CRESCENT  NELLMAPIUS  PRETORIA",
    Latitude: "-25.7371",
    Longitude: "28.3515",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8887",
    WardLookupId: "2649",
    VDNumber: "32952089",
    RegisteredPopulation: "3347",
    VotingStationName: "VUKAUZENZELE PRIMARY SCHOOL",
    Address:
      "1617 CNR ALWYN & MOROKA SWALLOWS STREET  NELLMAPIUS EXT 3  PRETORIA",
    Latitude: "-25.7404",
    Longitude: "28.3738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8888",
    WardLookupId: "2649",
    VDNumber: "32952269",
    RegisteredPopulation: "3368",
    VotingStationName: "JAN KOTLOLO PRIMARY SCHOOL",
    Address:
      "2228 HALELLUJAH STREET  NELLMAPIUS EXT 4  TSHWANE METRO [PRETORIA]",
    Latitude: "-25.73",
    Longitude: "28.3617",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8889",
    WardLookupId: "2649",
    VDNumber: "32952607",
    RegisteredPopulation: "488",
    VotingStationName: "CHILDRENS PARADISE PRE-SCHOOL",
    Address: "154 BRAE AVENUE WILLOW BRAE  WILLOW PARK   PRETORIA",
    Latitude: "-25.7473",
    Longitude: "28.3421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8890",
    WardLookupId: "2649",
    VDNumber: "32953046",
    RegisteredPopulation: "3216",
    VotingStationName: "NELLMAPIUS COMMUNITY HALL AND LIBRARY",
    Address: "CNR OF MORERI AND LESEDI  NELLMAPIUS   TSWANE",
    Latitude: "-25.7395",
    Longitude: "28.3623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8891",
    WardLookupId: "2649",
    VDNumber: "32953057",
    RegisteredPopulation: "971",
    VotingStationName: "TENT - EXT 13 NELLMAPIUS",
    Address: "CNR OF ALWYN AND IGWALI STR  NELLMAPIUS  PRETORIA",
    Latitude: "-25.7324",
    Longitude: "28.3501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8892",
    WardLookupId: "2649",
    VDNumber: "32953068",
    RegisteredPopulation: "1160",
    VotingStationName: "WILLOW MANNER CHRISTIAN CHURCH",
    Address: "605 EERSTE FABRIEK CRESCENT  NELLMAPIUS  PRETORIA",
    Latitude: "-25.7397",
    Longitude: "28.3461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8893",
    WardLookupId: "2650",
    VDNumber: "32950986",
    RegisteredPopulation: "1998",
    VotingStationName: "N.G KERK SILVERTON KRUIN",
    Address: "39B C/O JOSEPH BOSMAN & BLOM STR  SILVERTON  PRETORIA",
    Latitude: "-25.7356",
    Longitude: "28.2887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8894",
    WardLookupId: "2650",
    VDNumber: "32951112",
    RegisteredPopulation: "2740",
    VotingStationName: "LINDOPARK LAERSKOOL",
    Address: "24 PRIVET STRAAT  LINDOPARK  PRETORIA",
    Latitude: "-25.7119",
    Longitude: "28.2719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8895",
    WardLookupId: "2650",
    VDNumber: "32951864",
    RegisteredPopulation: "677",
    VotingStationName: "DE VRIES AMBULANS AKADEMIE",
    Address: "169 PLOT  SAKABUKA AVE  DERDEPOORT  TSHWANE",
    Latitude: "-25.6653",
    Longitude: "28.2768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8896",
    WardLookupId: "2650",
    VDNumber: "32951886",
    RegisteredPopulation: "3056",
    VotingStationName: "DERDEPOORT LAERSKOOL",
    Address: "C/R JAN COETZEE & UILSTREET  JAN NIEMANDPARK  PRETORIA",
    Latitude: "-25.7025",
    Longitude: "28.2873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8897",
    WardLookupId: "2650",
    VDNumber: "32952191",
    RegisteredPopulation: "988",
    VotingStationName: "KILNERTON TRAINING AND CONFERENCE CENTRE",
    Address: "PITTS AVENUE   TSHWANE  TSHWANE",
    Latitude: "-25.7339",
    Longitude: "28.2682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8898",
    WardLookupId: "2650",
    VDNumber: "32952360",
    RegisteredPopulation: "1838",
    VotingStationName: "TENT ON CNR PARK BRAAM PRETORIUS & FLUFF TAIL STR",
    Address: "CNR FLUFF TAIL & BRAAM PRETORI  MONTANA  TSHWANE",
    Latitude: "-25.6866",
    Longitude: "28.2736",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8899",
    WardLookupId: "2650",
    VDNumber: "32953079",
    RegisteredPopulation: "1262",
    VotingStationName: "TENT - PICNIC SPORT CNR SWAN & JAN COETZEE",
    Address: "CNR SWAN & JAN COETZEE  EAST LYNNE  TSHWANE",
    Latitude: "-25.7077",
    Longitude: "28.2889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8900",
    WardLookupId: "2650",
    VDNumber: "33190017",
    RegisteredPopulation: "2517",
    VotingStationName: "ROODEPLAAT COMMUNITY HALL",
    Address: "64 MOLOTO ROAD  ROODEPLAAT  TSHWANE",
    Latitude: "-25.6502",
    Longitude: "28.3072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8901",
    WardLookupId: "2650",
    VDNumber: "33190073",
    RegisteredPopulation: "875",
    VotingStationName: "TENT - VORTECH TRADING",
    Address: "MOLOTO ROAD  KAMEELDRIFT  CULLINAN",
    Latitude: "-25.6344",
    Longitude: "28.3208",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8902",
    WardLookupId: "2651",
    VDNumber: "32960437",
    RegisteredPopulation: "2525",
    VotingStationName: "ST FRANCIS PRIMARY SCHOOL",
    Address: "2287 BLOCK P  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4876",
    Longitude: "28.1239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8903",
    WardLookupId: "2651",
    VDNumber: "32960448",
    RegisteredPopulation: "2369",
    VotingStationName: "KGOMOTSO HIGH SCHOOL",
    Address: "271 BLOCK DD  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4876",
    Longitude: "28.1116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8904",
    WardLookupId: "2651",
    VDNumber: "32960459",
    RegisteredPopulation: "3022",
    VotingStationName: "THAKGALANG PRIMARY SCHOOL",
    Address: "SOSHANGUVE  SOSHANGUVE 1088 BLOCK P  PRETORIA",
    Latitude: "-25.4849",
    Longitude: "28.1311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8905",
    WardLookupId: "2651",
    VDNumber: "32960460",
    RegisteredPopulation: "1282",
    VotingStationName: "ANOINTED GOSPEL CHURCH",
    Address: "MATSANE STREET   BLOCK SS EXT 1  SOSHANGUVE",
    Latitude: "-25.4829",
    Longitude: "28.1187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8906",
    WardLookupId: "2651",
    VDNumber: "32960550",
    RegisteredPopulation: "1458",
    VotingStationName: "SEDIBA-SA-TSEBO PRIMARY SCHOOL",
    Address: "838 BLOCK DD  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4935",
    Longitude: "28.1111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8907",
    WardLookupId: "2651",
    VDNumber: "32961034",
    RegisteredPopulation: "879",
    VotingStationName: "KHULA-NGO`LWAZI PRE-SCHOOL",
    Address: "779 BLOCK SS EXTETION 5   SOSHANGUVE   PRETORIA",
    Latitude: "-25.4924",
    Longitude: "28.1206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8908",
    WardLookupId: "2651",
    VDNumber: "32961045",
    RegisteredPopulation: "715",
    VotingStationName: "AMOGELANG SECONDARY SCHOOL",
    Address: "287 FIDEL CASTRO STREET   SOSHANGUVE - BLOCK P  PRETORIA",
    Latitude: "-25.4823",
    Longitude: "28.1232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8909",
    WardLookupId: "2652",
    VDNumber: "32960022",
    RegisteredPopulation: "2390",
    VotingStationName: "SHALOM PRIMARY SCHOOL",
    Address: "215 BLOCK UU  SOSHANGUVE  PRETORIA",
    Latitude: "-25.5549",
    Longitude: "28.0787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8910",
    WardLookupId: "2652",
    VDNumber: "32960505",
    RegisteredPopulation: "2078",
    VotingStationName: "RODNEY MOKOENA PRIMARY SCHOOL",
    Address: "27 BLOCK TT  SOSHANGUVE  PRETORIA",
    Latitude: "-25.5526",
    Longitude: "28.068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8911",
    WardLookupId: "2652",
    VDNumber: "32960640",
    RegisteredPopulation: "4058",
    VotingStationName: "REFIHLILE-PELE PRIMARY SCHOOL",
    Address: "1417 BLOCK TT  SOSHANGUVE-BLOCK TT  TSHWANE",
    Latitude: "-25.5598",
    Longitude: "28.0699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8912",
    WardLookupId: "2652",
    VDNumber: "32960785",
    RegisteredPopulation: "1744",
    VotingStationName: "CENTRAL SECONDARY SCHOOL",
    Address: "768 BLOCK WW  SOSHANGUVE  TSHWANE",
    Latitude: "-25.5604",
    Longitude: "28.0802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8913",
    WardLookupId: "2652",
    VDNumber: "32960909",
    RegisteredPopulation: "1972",
    VotingStationName: "GONTSE PRIMARY SCHOOL",
    Address: "6668 MKHUNDULALE STREET   SOSHANGUVE SOUTH-EXT 3   PRETORIA",
    Latitude: "-25.5706",
    Longitude: "28.0705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8914",
    WardLookupId: "2652",
    VDNumber: "32961056",
    RegisteredPopulation: "1057",
    VotingStationName: "KIDZ AND KIDS DAY CARE CENTRE",
    Address: "7347/51 NONE   EXTENTION 3  SOSHANGUVE",
    Latitude: "-25.5572",
    Longitude: "28.0854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8915",
    WardLookupId: "2653",
    VDNumber: "32960516",
    RegisteredPopulation: "3936",
    VotingStationName: "LESEDI PRIMARY SCHOOL",
    Address: "891 BLOCK XX  SOSHANGUVE  TSHWANE",
    Latitude: "-25.5891",
    Longitude: "28.1059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8916",
    WardLookupId: "2653",
    VDNumber: "32960673",
    RegisteredPopulation: "3045",
    VotingStationName: "SOSHANGUVE EAST SECONDARY",
    Address: "1138 BLOCK XX  PRETORIA   SOSHANGUVE  BLOCK XX",
    Latitude: "-25.5898",
    Longitude: "28.0998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8917",
    WardLookupId: "2653",
    VDNumber: "32960763",
    RegisteredPopulation: "3149",
    VotingStationName: "PELE PRIMARY SCHOOL",
    Address: "CNR PELE AND ZOLA BUDD  SOSHANGUVE-A  BLOCK XX",
    Latitude: "-25.6014",
    Longitude: "28.1057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8918",
    WardLookupId: "2653",
    VDNumber: "32960831",
    RegisteredPopulation: "3378",
    VotingStationName: "APOSTOLIC FAITH MISSION CHURCH",
    Address: "10908 EXT 5  SOSHANGUVE  TSHWANE METRO [PRETORIA]",
    Latitude: "-25.5913",
    Longitude: "28.082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8919",
    WardLookupId: "2653",
    VDNumber: "32960853",
    RegisteredPopulation: "3138",
    VotingStationName: "PRESTIGE COLLEGE THORNVIEW",
    Address: "MOFIFI STREET  THORNVIEW BLOCK VV  SOSHANGUVE",
    Latitude: "-25.5704",
    Longitude: "28.098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8920",
    WardLookupId: "2653",
    VDNumber: "32961067",
    RegisteredPopulation: "1094",
    VotingStationName: "HOLLY-CROSS CRECHE",
    Address: "2-677 BAFANA BAFANA  BLOCK XX  SOSHANGUVE",
    Latitude: "-25.5964",
    Longitude: "28.1068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8921",
    WardLookupId: "2653",
    VDNumber: "32961179",
    RegisteredPopulation: "2059",
    VotingStationName: "KHANYISILE LETHABO CARE",
    Address: "UCWETHU STREET   PRETORIA   SOSHANGUVE SOUTH EXT 7",
    Latitude: "-25.599",
    Longitude: "28.0922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8922",
    WardLookupId: "2654",
    VDNumber: "32952315",
    RegisteredPopulation: "7209",
    VotingStationName: "TENT - WEKKER & MARY ETHEL",
    Address: "CORNER OF WEKKER AND MARY ETHEL STREET  MORELETA PARK  TSHWANE",
    Latitude: "-25.8321",
    Longitude: "28.298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8923",
    WardLookupId: "2654",
    VDNumber: "32952506",
    RegisteredPopulation: "3464",
    VotingStationName: "TENT - OPEN SPACE OPP THE WILDS ESTATE",
    Address: "DIEVILLEBOIS OPP THE WILDS EST  WOODLANDS GARSFONTEIN  PRETORIA",
    Latitude: "-25.8157",
    Longitude: "28.3253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8924",
    WardLookupId: "2654",
    VDNumber: "32953091",
    RegisteredPopulation: "1919",
    VotingStationName: "NODDYLAND NURSERY SCHOOL",
    Address: "686 GREAT DANE STR  GARSFONTEIN  PRETORIA",
    Latitude: "-25.8056",
    Longitude: "28.3196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8925",
    WardLookupId: "2654",
    VDNumber: "32953349",
    RegisteredPopulation: "671",
    VotingStationName: "TENT JOZINI & WEKKER",
    Address: "WEKKER & JOZINI  MORELETA PARK  PRETORIA",
    Latitude: "-25.8424",
    Longitude: "28.3032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8926",
    WardLookupId: "2654",
    VDNumber: "32953350",
    RegisteredPopulation: "728",
    VotingStationName: "TENT LUCKYBEAN NEXT TO VISTARIA COMPLEX",
    Address: "LUCKY BEAN & WEKKER  MORELETA PARK  PRETORIA",
    Latitude: "-25.8442",
    Longitude: "28.2791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8927",
    WardLookupId: "2654",
    VDNumber: "33110266",
    RegisteredPopulation: "1795",
    VotingStationName: "KUNGWINI WELFARE ORGANISATION",
    Address: "PLOT 214 ZWAVELPOORT  KUNGWINI",
    Latitude: "-25.8113",
    Longitude: "28.3709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8928",
    WardLookupId: "2654",
    VDNumber: "33110277",
    RegisteredPopulation: "4573",
    VotingStationName: "RAFTERS PUB B/HOUSE BUILDING",
    Address:
      "81 BLESBOK STREET MOOIKLOOF  KUNGWINI [BRONKHORSTSPRUIT]  KUNGWINI [BRONKHORSTSPRUIT]",
    Latitude: "-25.8515",
    Longitude: "28.3298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8929",
    WardLookupId: "2654",
    VDNumber: "33110413",
    RegisteredPopulation: "2013",
    VotingStationName: "KAYA BEACH RESTAURANT",
    Address: "GARSFONTEIN RD  GROOTFONTEIN  PRETORIA",
    Latitude: "-25.8797",
    Longitude: "28.3689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8930",
    WardLookupId: "2655",
    VDNumber: "32950469",
    RegisteredPopulation: "2535",
    VotingStationName: "PRETORIA TECHNICAL HIGH SCHOOL",
    Address: "649 PARK STREET  ARCADIA  PRETORIA",
    Latitude: "-25.7496",
    Longitude: "28.2119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8931",
    WardLookupId: "2655",
    VDNumber: "32950559",
    RegisteredPopulation: "3102",
    VotingStationName: "HOTEL 224",
    Address: "CNR FRANCIS BAARD AND LEDYS   ARCADIA   PRETORIA",
    Latitude: "-25.7472",
    Longitude: "28.2089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8932",
    WardLookupId: "2655",
    VDNumber: "32950560",
    RegisteredPopulation: "3478",
    VotingStationName: "UNION BUILDING OPEN SPACE (TENT)",
    Address: "CRN LEDYS AND MADIBA   ARCADIA  PRETORIA",
    Latitude: "-25.7442",
    Longitude: "28.2095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8933",
    WardLookupId: "2655",
    VDNumber: "32950582",
    RegisteredPopulation: "2260",
    VotingStationName: "ARCADIA PRIMARY SCHOOL",
    Address: "FARENDEN STREET  ARCADIA  PRETORIA",
    Latitude: "-25.7466",
    Longitude: "28.22",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8934",
    WardLookupId: "2655",
    VDNumber: "32952214",
    RegisteredPopulation: "1515",
    VotingStationName: "TENT  AT ALLCOCK & GLEN STREET",
    Address: "CNR ALLCOCK & GLEN STREET  HATFIELD  TSHWANE",
    Latitude: "-25.7431",
    Longitude: "28.2405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8935",
    WardLookupId: "2655",
    VDNumber: "32953103",
    RegisteredPopulation: "676",
    VotingStationName: "HATFIELD EMERGENCY SERVICES",
    Address: "1024 BURNETT STREET  HATFIELD  PRETORIA",
    Latitude: "-25.7502",
    Longitude: "28.2291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8936",
    WardLookupId: "2656",
    VDNumber: "32951189",
    RegisteredPopulation: "1729",
    VotingStationName: "STANZA BOPAPE HIGH SCHOOL",
    Address: "13413 MAHLARE STREET  MAMELODI EAST  PRETORIA",
    Latitude: "-25.703",
    Longitude: "28.389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8937",
    WardLookupId: "2656",
    VDNumber: "32951415",
    RegisteredPopulation: "1954",
    VotingStationName: "REFENTSE PRIMARY SCHOOL",
    Address: "4500 4500 DR RIBANE  MAMELODI WEST 0122  TSHWANE",
    Latitude: "-25.7059",
    Longitude: "28.3601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8938",
    WardLookupId: "2656",
    VDNumber: "32951820",
    RegisteredPopulation: "2559",
    VotingStationName: "NEW DAY MINISTRIES CHURCH",
    Address: "2434 ROMEO STREET  MAMELODI EAST, PHASE 1  MAMELODI",
    Latitude: "-25.6983",
    Longitude: "28.3953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8939",
    WardLookupId: "2656",
    VDNumber: "32952124",
    RegisteredPopulation: "1742",
    VotingStationName: "REFORMED APOSTOLIC CHURCH PHASE 1",
    Address: "81 MONARENG STREET  MAMELODI EAST  PRETORIA",
    Latitude: "-25.7035",
    Longitude: "28.391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8940",
    WardLookupId: "2656",
    VDNumber: "32952179",
    RegisteredPopulation: "1483",
    VotingStationName: "UNION APOSTILIC FAITH MISSION CHURCH",
    Address: "192 NTOBE AVE  MAMELODI EAST, PHASE 2  PRETORIA",
    Latitude: "-25.6976",
    Longitude: "28.3982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8941",
    WardLookupId: "2656",
    VDNumber: "32952427",
    RegisteredPopulation: "2144",
    VotingStationName: "LIFE GIVING MINISTRIES",
    Address: "33573 STEVE BIKO STREET  MAMELODI EAST, EXT 17  PRETORIA",
    Latitude: "-25.697",
    Longitude: "28.4143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8942",
    WardLookupId: "2656",
    VDNumber: "32952562",
    RegisteredPopulation: "388",
    VotingStationName: "MATHITHI TUCK SHOP",
    Address: "2860 LEBOGANG STREET  MAMELODI EAST PHASE 1   PRETORIA",
    Latitude: "-25.6984",
    Longitude: "28.3974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8943",
    WardLookupId: "2656",
    VDNumber: "32953361",
    RegisteredPopulation: "247",
    VotingStationName: "POWERHOUSE BAPTIST CHURCH",
    Address: "OLIVER TAMBO  MAHUBE VALLEY EXT 1  PRETORIA",
    Latitude: "-25.6914",
    Longitude: "28.4116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8944",
    WardLookupId: "2656",
    VDNumber: "32953372",
    RegisteredPopulation: "444",
    VotingStationName: "IKETLENG EARLY LEARNING CENTRE",
    Address: "LETOABA AVENUE  MAMELODI EAST EXT 10  PRETORIA",
    Latitude: "-25.6938",
    Longitude: "28.4044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8945",
    WardLookupId: "2657",
    VDNumber: "32960202",
    RegisteredPopulation: "1694",
    VotingStationName: "TIAMOKO PRIMARY SCHOOL",
    Address: "546 BLOCK FF  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4748",
    Longitude: "28.1017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8946",
    WardLookupId: "2657",
    VDNumber: "32960213",
    RegisteredPopulation: "2350",
    VotingStationName: "BAXOXELA PRIMARY SCHOOL",
    Address: "1187 BLOCK GG  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4679",
    Longitude: "28.0902",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8947",
    WardLookupId: "2657",
    VDNumber: "32960224",
    RegisteredPopulation: "1632",
    VotingStationName: "PADISAGO PRIMARY SCHOOL",
    Address: "343 BLOCK BB  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4891",
    Longitude: "28.0978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8948",
    WardLookupId: "2657",
    VDNumber: "32960291",
    RegisteredPopulation: "1892",
    VotingStationName: "LINDELANI PRIMARY SCHOOL",
    Address: "620 BLOCK GG  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4642",
    Longitude: "28.0909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8949",
    WardLookupId: "2657",
    VDNumber: "32960347",
    RegisteredPopulation: "2376",
    VotingStationName: "ECHIBINI SECONDARY SCHOOL",
    Address: "2062 BLOCK GG  SOSHANGUVE  TSHWANE",
    Latitude: "-25.463",
    Longitude: "28.0972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8950",
    WardLookupId: "2657",
    VDNumber: "32960358",
    RegisteredPopulation: "1834",
    VotingStationName: "SEMPHATO PRIMARY SCHOOL",
    Address: "977 BLOCK HH  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4559",
    Longitude: "28.0893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8951",
    WardLookupId: "2657",
    VDNumber: "32961078",
    RegisteredPopulation: "692",
    VotingStationName: "NTSAKO SECONDARY SCHOOL",
    Address: "70 MOEDING TREET   SOSHANGUVE - FF  PRETORIA",
    Latitude: "-25.4772",
    Longitude: "28.0974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8952",
    WardLookupId: "2658",
    VDNumber: "33170082",
    RegisteredPopulation: "1744",
    VotingStationName: "PB ALGEMENE HANDELAARS",
    Address: "PLOT 133  VASTFONTEIN  PRETORIA",
    Latitude: "-25.503",
    Longitude: "28.2313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8953",
    WardLookupId: "2658",
    VDNumber: "86660848",
    RegisteredPopulation: "2238",
    VotingStationName: "ALFA HEALTH CENTRE",
    Address: "5244 BLOCK F4  EERSTERUS  TEMBA",
    Latitude: "-25.429",
    Longitude: "28.1368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8954",
    WardLookupId: "2658",
    VDNumber: "86660927",
    RegisteredPopulation: "932",
    VotingStationName: "TENT - NO PROBLEM STORE",
    Address: "1770 RUTH BLOCK STINKWATER   TEMBA  STINKWATER",
    Latitude: "-25.4068",
    Longitude: "28.1508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8955",
    WardLookupId: "2658",
    VDNumber: "86660938",
    RegisteredPopulation: "1708",
    VotingStationName: "MAROTOLA PRIMARY SCHOOL",
    Address: "7841 BLOCK MOKONE  STINKWATER  TEMBA",
    Latitude: "-25.4112",
    Longitude: "28.1559",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8956",
    WardLookupId: "2658",
    VDNumber: "86660949",
    RegisteredPopulation: "1564",
    VotingStationName: "LERATONG PRIMARY SCHOOL",
    Address: "2210 MTHETHWA BLOCK  STINKWATER  TEMBA",
    Latitude: "-25.4066",
    Longitude: "28.167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8957",
    WardLookupId: "2658",
    VDNumber: "86660950",
    RegisteredPopulation: "1287",
    VotingStationName: "MOTSHEGOFADIWA PRIMARY SCHOOL",
    Address: "3420 BLOCK NOKEDI  STINKWATER  STINKWATER",
    Latitude: "-25.4082",
    Longitude: "28.1753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8958",
    WardLookupId: "2658",
    VDNumber: "86660961",
    RegisteredPopulation: "1334",
    VotingStationName: "BRENDA TUCKSHOP",
    Address: "1724 MOKONE BLOCK  STINKWATER  TEMBA",
    Latitude: "-25.41",
    Longitude: "28.1839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8959",
    WardLookupId: "2658",
    VDNumber: "86660972",
    RegisteredPopulation: "885",
    VotingStationName: "ABC CHURCH",
    Address: "2893 ZONE9 STINKWATER  STINKWATER  STINKWATER",
    Latitude: "-25.4036",
    Longitude: "28.1723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8960",
    WardLookupId: "2658",
    VDNumber: "86660994",
    RegisteredPopulation: "989",
    VotingStationName: "APOSTOLIC CHURCH IN JERUSALEM",
    Address: "3420 BLOCK NOKEDI   STINKWATER  TEMBA",
    Latitude: "-25.4085",
    Longitude: "28.163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8961",
    WardLookupId: "2659",
    VDNumber: "32951695",
    RegisteredPopulation: "1480",
    VotingStationName: "WONDERBOOM FIRE STATION",
    Address: "C/O BORAGE & LAVENDER ROAD  ANNLIN  PRETORIA",
    Latitude: "-25.669",
    Longitude: "28.1917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8962",
    WardLookupId: "2659",
    VDNumber: "32951707",
    RegisteredPopulation: "4470",
    VotingStationName: "NG KERK DOORNPOORT",
    Address: "841 BAOBAB STREET  DOORNPOORT  PRETORIA",
    Latitude: "-25.6467",
    Longitude: "28.2411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8963",
    WardLookupId: "2659",
    VDNumber: "32952067",
    RegisteredPopulation: "4168",
    VotingStationName: "KAREL WYE VOETE KLEUTERSKOOL",
    Address: "710 CREVILLEA STREET  DOORNPOORT  PRETORIA",
    Latitude: "-25.6558",
    Longitude: "28.2519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8964",
    WardLookupId: "2659",
    VDNumber: "32960112",
    RegisteredPopulation: "2187",
    VotingStationName: "EBENEZER BEAUTIFUL CHURCH",
    Address: "970 BLOCK MM  SOSHANGUVE  TSHWANE",
    Latitude: "-25.4932",
    Longitude: "28.1295",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8965",
    WardLookupId: "2659",
    VDNumber: "33170015",
    RegisteredPopulation: "1381",
    VotingStationName: "HAAKDOORN PRIMARY SCHOOL",
    Address: "PLOT 247 SWEELTJIE STREET  HAAKDOORNBOOM  PRETORIA",
    Latitude: "-25.5998",
    Longitude: "28.1348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8966",
    WardLookupId: "2659",
    VDNumber: "33170059",
    RegisteredPopulation: "1382",
    VotingStationName: "KUDUMELA PRIMARY SCHOOL",
    Address: "11 PLOT 20821 NERINA STREET  HONINGNESTKRANS STREET  PRETORIA",
    Latitude: "-25.6025",
    Longitude: "28.2071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8967",
    WardLookupId: "2659",
    VDNumber: "33170116",
    RegisteredPopulation: "1023",
    VotingStationName: "ROOIWAL CLUB HALL & RECREATION CENTRE",
    Address: "4TH STREET ROOIWAL  ROOIWAL DORP  PRETORIA",
    Latitude: "-25.5614",
    Longitude: "28.2474",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8968",
    WardLookupId: "2660",
    VDNumber: "32952023",
    RegisteredPopulation: "3478",
    VotingStationName: "MEETSE A BOPHELO PRIMARY",
    Address: "27273 EXTENTION  MAMELODI EAST 8 EXTENTION 8  TSHWANE",
    Latitude: "-25.722",
    Longitude: "28.4196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8969",
    WardLookupId: "2660",
    VDNumber: "32952045",
    RegisteredPopulation: "2670",
    VotingStationName: "BARAK EDUCATION FOUNDATION",
    Address: "36372 LEHONG STREET  MAMELODI EAST, LUSAKA, EXT 22  PRETORIA",
    Latitude: "-25.7149",
    Longitude: "28.434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8970",
    WardLookupId: "2660",
    VDNumber: "32952157",
    RegisteredPopulation: "2933",
    VotingStationName: "ASSEMBLIES OF GOD-STANZA BOPAPE",
    Address: "04 JIYANE STREET  MAMELODI EAST, EXT 11  PRETORIA",
    Latitude: "-25.7189",
    Longitude: "28.424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8971",
    WardLookupId: "2660",
    VDNumber: "32952247",
    RegisteredPopulation: "1694",
    VotingStationName: "LEGORA PRIMARY SCHOOL",
    Address:
      "32429EXT12 THSUKUDU STR MAMELODI EAST 0122  TSHWANE METRO [PRETORIA]  TSHWANE METRO [PRETORIA]",
    Latitude: "-25.7283",
    Longitude: "28.4198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8972",
    WardLookupId: "2660",
    VDNumber: "32953125",
    RegisteredPopulation: "1226",
    VotingStationName: "ANGLICAN CHURCH-ST. AGNES",
    Address: "2614 MONHLA CRES  MAHUBE VLALLEY EXT 24  PRETORIA",
    Latitude: "-25.7141",
    Longitude: "28.423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8973",
    WardLookupId: "2660",
    VDNumber: "32953136",
    RegisteredPopulation: "1100",
    VotingStationName: "STOFFEL PARK PRESBETERIAN CHURCH",
    Address: "STOFFEL PARK  MAMELODI EAST  PRETORIA",
    Latitude: "-25.7081",
    Longitude: "28.4366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8974",
    WardLookupId: "2661",
    VDNumber: "32951673",
    RegisteredPopulation: "2171",
    VotingStationName: "HOÃ‹RSKOOL GERRIT MARITZ",
    Address: "DAAN DE WET NEL DRIVE  PRETORIA NORTH  PRETORIA",
    Latitude: "-25.665",
    Longitude: "28.1542",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8975",
    WardLookupId: "2661",
    VDNumber: "32960482",
    RegisteredPopulation: "3499",
    VotingStationName: "THERESA PARK PRIMARY SCHOOL",
    Address: "CO WATERBOKSTRAAT AND LEGUAN  THERESA PARK  PRETORIA",
    Latitude: "-25.6674",
    Longitude: "28.1414",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8976",
    WardLookupId: "2661",
    VDNumber: "32960493",
    RegisteredPopulation: "2691",
    VotingStationName: "LAERSKOOL AKASIA",
    Address: "HV DAAN DE WET NEL RYLAAN & RENE  CLARINA  AKASIA",
    Latitude: "-25.6492",
    Longitude: "28.1252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8977",
    WardLookupId: "2661",
    VDNumber: "32960819",
    RegisteredPopulation: "2078",
    VotingStationName: "SANCA PRETORIA",
    Address: "WATERBOK AND BRITS ROAD   NINAPARK  PRETORIA",
    Latitude: "-25.6783",
    Longitude: "28.1428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8978",
    WardLookupId: "2661",
    VDNumber: "32960842",
    RegisteredPopulation: "1718",
    VotingStationName: "TINKTINKIE KLEUTERSKOOL",
    Address: "111 SPRINGHAASSTRAAT  THERESAPARK  TSHWANE",
    Latitude: "-25.6766",
    Longitude: "28.1437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8979",
    WardLookupId: "2661",
    VDNumber: "32960864",
    RegisteredPopulation: "1343",
    VotingStationName: "SMILEY KIDS ORCHARDS",
    Address: "41 DORFING STREET   THE ORCHARDS   ORCHARDS",
    Latitude: "-25.6536",
    Longitude: "28.1056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8980",
    WardLookupId: "2661",
    VDNumber: "32960943",
    RegisteredPopulation: "1746",
    VotingStationName: "WONDERPARK NG CHURCH",
    Address: "BERG AVENUE   AMANDASIG   PRETORIA",
    Latitude: "-25.6799",
    Longitude: "28.1071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8981",
    WardLookupId: "2661",
    VDNumber: "32961089",
    RegisteredPopulation: "1234",
    VotingStationName: "HOÃ‹RSKOOL AKASIA",
    Address: "MAIN STREET   AKASIA   PRETORIA",
    Latitude: "-25.6698",
    Longitude: "28.1301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8982",
    WardLookupId: "2661",
    VDNumber: "32961090",
    RegisteredPopulation: "828",
    VotingStationName: "DIE RONDAWEL",
    Address: "101 ROOIKAT   AKASIA   PRETORIA",
    Latitude: "-25.6543",
    Longitude: "28.1364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8983",
    WardLookupId: "2662",
    VDNumber: "32952326",
    RegisteredPopulation: "1392",
    VotingStationName: "POWER TUBBIES LEARNING CENTRE",
    Address: "2595 OLIVER TAMBO STREET  MAMELODI EAST  MAHUBE VALLEY  EXT 1",
    Latitude: "-25.6901",
    Longitude: "28.4158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8984",
    WardLookupId: "2662",
    VDNumber: "33050012",
    RegisteredPopulation: "1792",
    VotingStationName: "REFILWE MUNICIPAL OFFICES",
    Address: "2645 2645 RUMO DRIVE  REFILWE PROPER  CULLINAN",
    Latitude: "-25.6387",
    Longitude: "28.5347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8985",
    WardLookupId: "2662",
    VDNumber: "33050034",
    RegisteredPopulation: "2511",
    VotingStationName: "REFILWE LIBRARY",
    Address: "RUME DRIVE  REFILWE EXT 2  REFILWE",
    Latitude: "-25.6359",
    Longitude: "28.5276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8986",
    WardLookupId: "2662",
    VDNumber: "33050102",
    RegisteredPopulation: "1481",
    VotingStationName: "END TIME HARVEST CHURCH",
    Address: "5100 RUMO DRIVE  REFILWE  TSHWANE",
    Latitude: "-25.634",
    Longitude: "28.5239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8987",
    WardLookupId: "2662",
    VDNumber: "33150013",
    RegisteredPopulation: "1112",
    VotingStationName: "WAGENDRIFT PRIMARY FARM SCHOOL",
    Address: "77 PLOT MOLOTO ROAD   DEWAGENSDRIFT  CULLINAN",
    Latitude: "-25.514",
    Longitude: "28.5273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8988",
    WardLookupId: "2662",
    VDNumber: "33150046",
    RegisteredPopulation: "446",
    VotingStationName: "TWEEFONTEIN SHOPPING CENTRE",
    Address: "76 MAMELODI/MOLOTO RD (PLOT 76)  TWEEFONTEIN  CULLINAN",
    Latitude: "-25.6051",
    Longitude: "28.5008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8989",
    WardLookupId: "2662",
    VDNumber: "33150103",
    RegisteredPopulation: "69",
    VotingStationName: "ROOIKOP PRE-SCHOOL",
    Address:
      "181JR CNR SIYABUSWA ROAD AND BROILER CHICKEN FARMS  RUST DER VENTER  TSHWANE",
    Latitude: "-25.1795",
    Longitude: "28.6632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8990",
    WardLookupId: "2662",
    VDNumber: "33150114",
    RegisteredPopulation: "35",
    VotingStationName: "LEEUFONTEIN NATURE RESERVE",
    Address:
      "212JR REST DE VENTER LEEUFONTEIN ROAD  LEEUFONTEIN  TSHWANE [CULLINAN]",
    Latitude: "-25.4014",
    Longitude: "28.6174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8991",
    WardLookupId: "2662",
    VDNumber: "33150147",
    RegisteredPopulation: "70",
    VotingStationName: "FOXTROT PRIMARY SCHOOL",
    Address: "253 MOLOTO  R573 KWA MHLANGA ROAD  JAKKALASDANS  CULLINAN",
    Latitude: "-25.4786",
    Longitude: "28.6033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8992",
    WardLookupId: "2662",
    VDNumber: "33150158",
    RegisteredPopulation: "930",
    VotingStationName: "TENT-GEM VALLEY EXT1 (MAHUBE STREET)",
    Address: "LETOABA & MAHUBE STREET  MAMELODI  TSHWANE",
    Latitude: "-25.6878",
    Longitude: "28.4082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8993",
    WardLookupId: "2662",
    VDNumber: "33150169",
    RegisteredPopulation: "1110",
    VotingStationName: "CHRIST FELLOWSHIP MINISTRY",
    Address: "275 CNR VHUMBONI & MOTHOKGO   REFILWE EXT 09  CULLINAN",
    Latitude: "-25.6294",
    Longitude: "28.5256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8994",
    WardLookupId: "2662",
    VDNumber: "33150215",
    RegisteredPopulation: "621",
    VotingStationName: "TENT- AMANDA PARK",
    Address: "MOUNTIN VIEW  MAHUBE VALLEY  MAMELODI EAST",
    Latitude: "-25.6898",
    Longitude: "28.3894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8995",
    WardLookupId: "2662",
    VDNumber: "33150237",
    RegisteredPopulation: "304",
    VotingStationName: "THE HIDING PLACE FIRE MINISTRY",
    Address: "EXT 10  REFILWE  CULLINAN",
    Latitude: "-25.6257",
    Longitude: "28.5241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8996",
    WardLookupId: "2662",
    VDNumber: "33170037",
    RegisteredPopulation: "587",
    VotingStationName: "TENT - SPAR SHOPPING SENTRUM",
    Address: "147 MOLOTO ROAD (PLOT)  KAMEELDRIFT  TSHWANE",
    Latitude: "-25.6349",
    Longitude: "28.3233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8997",
    WardLookupId: "2662",
    VDNumber: "33170149",
    RegisteredPopulation: "716",
    VotingStationName: "K9 POLICE DOG TRAINING SCHOOL",
    Address: "293 JR MOLOTO  KWA MHLANGA ROAD  ROODEPLAAT  TSHWANE / CULLINAN",
    Latitude: "-25.6073",
    Longitude: "28.3695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8998",
    WardLookupId: "2662",
    VDNumber: "33190039",
    RegisteredPopulation: "1661",
    VotingStationName: "LAERSKOOL LEEUFONTEIN",
    Address: "63 PLOT LEEUWFONTEIN ROAD   LEEUFONTEIN/KAMEELDRIFT   PRETORIA",
    Latitude: "-25.6555",
    Longitude: "28.3759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "8999",
    WardLookupId: "2662",
    VDNumber: "33190062",
    RegisteredPopulation: "1736",
    VotingStationName: "DERDEPARK WINKEL SENTRUM (ZOZO HOUSE)",
    Address: "190 ZAMBEZI LAAN (PLOT)  DERDEPARK   TSHWANE",
    Latitude: "-25.6833",
    Longitude: "28.3044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9000",
    WardLookupId: "2662",
    VDNumber: "33190084",
    RegisteredPopulation: "1725",
    VotingStationName: "LAERSKOOL DIE POORT",
    Address: "236 JR KAMEELDRIFT ROAD  DERDEPOORT  TSHWANE",
    Latitude: "-25.6745",
    Longitude: "28.3015",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9001",
    WardLookupId: "2662",
    VDNumber: "33190095",
    RegisteredPopulation: "426",
    VotingStationName: "BAVIAANSPOORT CENTRE RESIDENTS",
    Address: "330 OLD CULLINAN ROAD  KAMEELDRIFT  PRETORIA",
    Latitude: "-25.6719",
    Longitude: "28.3541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9002",
    WardLookupId: "2662",
    VDNumber: "33190107",
    RegisteredPopulation: "614",
    VotingStationName: "BETHEL CHRISTIAN CHURCH",
    Address: "KAMEELDRIFT   LYNN EAST  PLOT 124",
    Latitude: "-25.6845",
    Longitude: "28.3827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9003",
    WardLookupId: "2663",
    VDNumber: "33050045",
    RegisteredPopulation: "1889",
    VotingStationName: "CULLINAN COMBINE SCHOOL",
    Address: "CRESENT STREET  TSHWANE  CULLINAN",
    Latitude: "-25.6781",
    Longitude: "28.5233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9004",
    WardLookupId: "2663",
    VDNumber: "33050056",
    RegisteredPopulation: "2159",
    VotingStationName: "RAYTON COMMUNITY HALL",
    Address: "204 CNR OAKELY & MONTROSE STREET  RAYTON  CULLINAN",
    Latitude: "-25.7395",
    Longitude: "28.5308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9005",
    WardLookupId: "2663",
    VDNumber: "33050067",
    RegisteredPopulation: "342",
    VotingStationName: "CHRIS HANI FLATS",
    Address: "480 ELANDSFONTEIN JR  CHRIS HANI FLATS  CULLINAN",
    Latitude: "-25.6776",
    Longitude: "28.4987",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9006",
    WardLookupId: "2663",
    VDNumber: "33050078",
    RegisteredPopulation: "583",
    VotingStationName: "ZONDERWATER BLOUSAAL HALL (IN PRISON)",
    Address: "07 CNR HARDEKOOL AND KIAART  CULLINAN ZONDERWATER  TSHWANE",
    Latitude: "-25.6863",
    Longitude: "28.5476",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9007",
    WardLookupId: "2663",
    VDNumber: "33050089",
    RegisteredPopulation: "1752",
    VotingStationName: "CHOKOE PRIMARY SCHOOL",
    Address: "3643 TSWELOPELE DRIVE REFILWE  REFILWE LOCATION  CULLINAN",
    Latitude: "-25.6472",
    Longitude: "28.5298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9008",
    WardLookupId: "2663",
    VDNumber: "33050090",
    RegisteredPopulation: "1357",
    VotingStationName: "REFILWE COMMUNITY HALL",
    Address: "1168 CNR RUMO & MASINA DRIVE  REFILWE  CULLINAN",
    Latitude: "-25.6375",
    Longitude: "28.5307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9009",
    WardLookupId: "2663",
    VDNumber: "33050113",
    RegisteredPopulation: "578",
    VotingStationName: "DEPOT WATER AND SANITATION",
    Address: "137 PLOT  RAYTON  RAYTON  CULLINAN",
    Latitude: "-25.7395",
    Longitude: "28.5231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9010",
    WardLookupId: "2663",
    VDNumber: "33150057",
    RegisteredPopulation: "799",
    VotingStationName: "THUSONG SERVICE CENTRE",
    Address: "424JR 123 THABO MBEKI STREET  ONVERWACHT  CULLINAN",
    Latitude: "-25.602",
    Longitude: "28.598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9011",
    WardLookupId: "2663",
    VDNumber: "33150068",
    RegisteredPopulation: "298",
    VotingStationName: "IDENDALE INDEPENDENT SCHOOL",
    Address: "13 JR  SEFAKO MAKGATHO DR   NOOIGEDRAGHT FARM  CULLINAN",
    Latitude: "-25.6887",
    Longitude: "28.4475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9012",
    WardLookupId: "2663",
    VDNumber: "33150079",
    RegisteredPopulation: "514",
    VotingStationName: "CULLINAN LANDBOU-UNIE SAAL",
    Address: "475 JR PORTION 70 EKANGALA ROAD  CULLINAN  CULLINAN",
    Latitude: "-25.664",
    Longitude: "28.5612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9013",
    WardLookupId: "2663",
    VDNumber: "33150080",
    RegisteredPopulation: "530",
    VotingStationName: "LESEDI SECONDARY SCHOOL",
    Address: "82 OLD PRETORIA ROAD (PLOT)  DONKERHOEK  TSHWANE",
    Latitude: "-25.7732",
    Longitude: "28.4472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9014",
    WardLookupId: "2663",
    VDNumber: "33150125",
    RegisteredPopulation: "1554",
    VotingStationName: "KUNGWINI EARLY LEARNING CENTRE",
    Address: "3/4 (PLOT) LETHABONG DONKERHOEK  DONKERHOEK  TSHWANE",
    Latitude: "-25.7577",
    Longitude: "28.4291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9015",
    WardLookupId: "2663",
    VDNumber: "33150136",
    RegisteredPopulation: "644",
    VotingStationName: "TENT -  NEXT TO JALA CAFE",
    Address: "OLD PRETORIA ROAD NEXT TO N4   RAYTON  TSHWANE REGION 05",
    Latitude: "-25.7929",
    Longitude: "28.5178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9016",
    WardLookupId: "2663",
    VDNumber: "33150170",
    RegisteredPopulation: "915",
    VotingStationName: "DIVINE MINISTRY CHURCH",
    Address: "KOPANONG  PIENAARSPOORT   PRETORIA",
    Latitude: "-25.7461",
    Longitude: "28.4178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9017",
    WardLookupId: "2663",
    VDNumber: "33150181",
    RegisteredPopulation: "817",
    VotingStationName: "TENT-MOUNTAIN VIEW SPORTS GROUND",
    Address: "MOUNTAIN VIEW  PIENAARSPOORT  CULLINAN",
    Latitude: "-25.7433",
    Longitude: "28.4365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9018",
    WardLookupId: "2663",
    VDNumber: "33150192",
    RegisteredPopulation: "1252",
    VotingStationName: "KOPANONG SPORTS GROUND -TENT",
    Address: "KOPANONG  PIENAARSPOORT  CULLINAN",
    Latitude: "-25.7353",
    Longitude: "28.4249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9019",
    WardLookupId: "2663",
    VDNumber: "33150204",
    RegisteredPopulation: "1137",
    VotingStationName: "(TENT) KOPANONG PIENAARSPOORT",
    Address: "PIENAARSPOORT  DONKERHOEK  CULLINAN",
    Latitude: "-25.7454",
    Longitude: "28.4254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9020",
    WardLookupId: "2663",
    VDNumber: "33150226",
    RegisteredPopulation: "263",
    VotingStationName: "LIGHT OF GOD GOSPEL MINISTRY",
    Address: "KOPANONG   PIENAARSPOORT  CULLINAN",
    Latitude: "-25.7418",
    Longitude: "28.4284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9021",
    WardLookupId: "2664",
    VDNumber: "33110110",
    RegisteredPopulation: "307",
    VotingStationName: "LA RACHELLE",
    Address: "38 PLOT  YSTERVARKFONTEIN  YSTERVARKONTEIN",
    Latitude: "-26.0221",
    Longitude: "28.5729",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9022",
    WardLookupId: "2664",
    VDNumber: "33110143",
    RegisteredPopulation: "1105",
    VotingStationName: "WOZANIBONE SECONDARY SCHOOL",
    Address: "PLOT  KLEINSONDERHOUT  519JR",
    Latitude: "-25.9052",
    Longitude: "28.5303",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9023",
    WardLookupId: "2664",
    VDNumber: "33110154",
    RegisteredPopulation: "257",
    VotingStationName: "LAERSKOOL WITPOORT",
    Address: "551 GARSFONTEIN ROAD  WELBEKEND  BHS",
    Latitude: "-25.9602",
    Longitude: "28.5197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9024",
    WardLookupId: "2664",
    VDNumber: "33110176",
    RegisteredPopulation: "1025",
    VotingStationName: "BOSCHKOP PRIM SCHOOL",
    Address: "49 BOSCHKOP  KUNGWINI",
    Latitude: "-25.8567",
    Longitude: "28.4987",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9025",
    WardLookupId: "2664",
    VDNumber: "33110198",
    RegisteredPopulation: "399",
    VotingStationName: "TENT -R25 SQUARE SHOPPING CENTRE",
    Address: "398 JR ONBEKEND  ONBEKEND  KUNGWINI",
    Latitude: "-25.9518",
    Longitude: "28.4741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9026",
    WardLookupId: "2664",
    VDNumber: "33110211",
    RegisteredPopulation: "374",
    VotingStationName: "LAKELANDS AARBEIPLAAS",
    Address: "PLOT  TIEGERPOORT  PRETORIA",
    Latitude: "-25.8711",
    Longitude: "28.4445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9027",
    WardLookupId: "2664",
    VDNumber: "33110222",
    RegisteredPopulation: "795",
    VotingStationName: "PAT PROJECTS",
    Address: "PLOT 165  BASHEWA  KUNGWINI",
    Latitude: "-25.9223",
    Longitude: "28.4296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9028",
    WardLookupId: "2664",
    VDNumber: "33110233",
    RegisteredPopulation: "1176",
    VotingStationName: "LERIBISI LODGE",
    Address: "82 PLOT  TIERPOORT  PRETORIA",
    Latitude: "-25.8722",
    Longitude: "28.3742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9029",
    WardLookupId: "2664",
    VDNumber: "33110244",
    RegisteredPopulation: "3454",
    VotingStationName: "SILVERLAKES CLUB HOUSE",
    Address: "LAQUINTA STREET  SILVERLAKES  KUNGWINI",
    Latitude: "-25.7722",
    Longitude: "28.3701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9030",
    WardLookupId: "2664",
    VDNumber: "33110255",
    RegisteredPopulation: "3033",
    VotingStationName: "LAERSKOOL TYGERPOORT",
    Address: "HENDRYLAAN 41  TYGER VALLEY  KU7NGWINI",
    Latitude: "-25.7967",
    Longitude: "28.3543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9031",
    WardLookupId: "2664",
    VDNumber: "33110345",
    RegisteredPopulation: "352",
    VotingStationName: "EMATSHENI PRIMARY SCHOOL",
    Address: "21R DELMAS ROAD  RIETFONTEIN  BAPSFONTEIN",
    Latitude: "-26.042",
    Longitude: "28.4826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9032",
    WardLookupId: "2664",
    VDNumber: "33110390",
    RegisteredPopulation: "2090",
    VotingStationName: "CRC PRETORIA EAST",
    Address: "SOLOMON MAHLANGU RD  PRETORIA EAST  PRETORIA",
    Latitude: "-25.7797",
    Longitude: "28.3477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9033",
    WardLookupId: "2664",
    VDNumber: "33110402",
    RegisteredPopulation: "4516",
    VotingStationName: "DOXA DEO EAST CAMPUS",
    Address: "OLYMPUS RD  OLYMPUS  PRETORIA",
    Latitude: "-25.8064",
    Longitude: "28.3281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9034",
    WardLookupId: "2664",
    VDNumber: "33150091",
    RegisteredPopulation: "836",
    VotingStationName: "ROSEMARYHILL",
    Address: "257 BOSCHKOP ROAD  MOOIPLAATS  KUNGWINI",
    Latitude: "-25.7915",
    Longitude: "28.4312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9035",
    WardLookupId: "2665",
    VDNumber: "33040033",
    RegisteredPopulation: "3090",
    VotingStationName: "MSHULUZANE MAISELA PRIMARY SCHOOL",
    Address: "2535 MOTHIBE DRIVE  ZITHOBEN EXT 2  KUNGWINI [BRONKHORSTSPRUIT]",
    Latitude: "-25.775",
    Longitude: "28.7189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9036",
    WardLookupId: "2665",
    VDNumber: "33040044",
    RegisteredPopulation: "2189",
    VotingStationName: "R.J SEKGOLELA CENTRE",
    Address: "4104 EXT 4  ZITHOBENI  KUNGWINI",
    Latitude: "-25.7827",
    Longitude: "28.7238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9037",
    WardLookupId: "2665",
    VDNumber: "33040055",
    RegisteredPopulation: "2010",
    VotingStationName: "KGORO PRIMARY SCHOOL",
    Address: "KABINI STR  ZITHOBENI PROPER  BRONKHORSTSPRUIT",
    Latitude: "-25.7878",
    Longitude: "28.7195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9038",
    WardLookupId: "2665",
    VDNumber: "33040066",
    RegisteredPopulation: "1657",
    VotingStationName: "BRONKHORSTSPRUIT PRIMARY SCHOOL",
    Address: "C0 MADELIEFIE & VERBENA STR  BRONKHORSTSPRUIT  KUNGWINI",
    Latitude: "-25.8088",
    Longitude: "28.7107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9039",
    WardLookupId: "2665",
    VDNumber: "33040088",
    RegisteredPopulation: "815",
    VotingStationName: "KAIA MANZI LODGE",
    Address: "541 KUNGWINI DAM  KUNGWINI",
    Latitude: "-25.8925",
    Longitude: "28.6885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9040",
    WardLookupId: "2665",
    VDNumber: "33040099",
    RegisteredPopulation: "723",
    VotingStationName: "BRONKHORSTSPRUIT SPORT COMPLEX",
    Address: "CORNER CHURCH & CATHY STR  BESTER PARK  KUNGWINI",
    Latitude: "-25.807",
    Longitude: "28.7318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9041",
    WardLookupId: "2665",
    VDNumber: "33040112",
    RegisteredPopulation: "1414",
    VotingStationName: "SAMKELENI DAY CARE",
    Address: "EXT 10  BRONKHORSTSPRUIT  ZITHOBENI",
    Latitude: "-25.7919",
    Longitude: "28.7318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9042",
    WardLookupId: "2665",
    VDNumber: "33040123",
    RegisteredPopulation: "1279",
    VotingStationName: "ZITHOBENI COMMUNITY HALL",
    Address: "KABINI STREET  ZITHOBENI  KUNGWINI",
    Latitude: "-25.7836",
    Longitude: "28.7194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9043",
    WardLookupId: "2665",
    VDNumber: "33040145",
    RegisteredPopulation: "896",
    VotingStationName: "ZITHOBENI SECONDARY SCHOOL",
    Address: "ZITHOBENI  BRONKHORSTSPRUIT",
    Latitude: "-25.7763",
    Longitude: "28.7295",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9044",
    WardLookupId: "2665",
    VDNumber: "33110109",
    RegisteredPopulation: "414",
    VotingStationName: "DANAS AGRICULTURAL CENTRE",
    Address: "509 ONVERWACHT  BRONKHORSTSPRUIT  KUNGWINI",
    Latitude: "-25.8036",
    Longitude: "28.6499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9045",
    WardLookupId: "2665",
    VDNumber: "33110121",
    RegisteredPopulation: "636",
    VotingStationName: "QINANI DAY CARE CENTRE",
    Address: "13 BRONKHORSTSPRUIT ROAD  VALTAKI AH  BRONKHORSTSPRUIT",
    Latitude: "-25.7805",
    Longitude: "28.5982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9046",
    WardLookupId: "2665",
    VDNumber: "33110187",
    RegisteredPopulation: "355",
    VotingStationName: "KHONZINKOSI PRIMARY SCHOOL",
    Address: "523 PLOT  VLAKFONTEIN  VLAKFONTEIN  BRONKHORSTSPRUIT",
    Latitude: "-25.8614",
    Longitude: "28.6919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9047",
    WardLookupId: "2666",
    VDNumber: "33040011",
    RegisteredPopulation: "1119",
    VotingStationName: "CHRISTIAN CATHOLIC CHURCH",
    Address: "248 MATUKANE  KUNGWINI  RETHABISENG",
    Latitude: "-25.7181",
    Longitude: "28.7108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9048",
    WardLookupId: "2666",
    VDNumber: "33040022",
    RegisteredPopulation: "1806",
    VotingStationName: "COMMUNITY HALL RETHABISENG",
    Address: "RETHABISENG  RETHABISENG X3  BRONKHORSTSPRUIT",
    Latitude: "-25.72",
    Longitude: "28.7172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9049",
    WardLookupId: "2666",
    VDNumber: "33040101",
    RegisteredPopulation: "1717",
    VotingStationName: "RETHABISENG PRIMARY SCHOOL",
    Address: "521 RETHABISENG  KUNGWINI",
    Latitude: "-25.7249",
    Longitude: "28.7163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9050",
    WardLookupId: "2666",
    VDNumber: "33040156",
    RegisteredPopulation: "424",
    VotingStationName: "MPUMELELO SECONDARY SCHOOL",
    Address: "874 RETHABISENG  BRONKHORSTSPRUIT",
    Latitude: "-25.7207",
    Longitude: "28.7146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9051",
    WardLookupId: "2666",
    VDNumber: "33040178",
    RegisteredPopulation: "11",
    VotingStationName: "JABULANE PRIMARY SCHOOL",
    Address: "1A ARGENTUM  EKANDUSTRIA  BRONKHORSTSPRUIT",
    Latitude: "-25.6846",
    Longitude: "28.7115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9052",
    WardLookupId: "2666",
    VDNumber: "54510027",
    RegisteredPopulation: "2768",
    VotingStationName: "SITJHEJIWE SECONDARY SCHOOL",
    Address: "4996 PROPER SECION  EKANGALA  KUNGWINI",
    Latitude: "-25.6832",
    Longitude: "28.7411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9053",
    WardLookupId: "2666",
    VDNumber: "54510038",
    RegisteredPopulation: "1692",
    VotingStationName: "BAWEZE PRIMARY SCHOOL",
    Address: "310 SECTION A  EKANGALA  KUNGWINI",
    Latitude: "-25.6934",
    Longitude: "28.7429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9054",
    WardLookupId: "2666",
    VDNumber: "54510094",
    RegisteredPopulation: "1728",
    VotingStationName: "HLOLISISA COMBINED SCHOOL",
    Address: "542 SECTIONB,  EKANGALA  KUNGWINI [BRONKHORSTSPRUIT]",
    Latitude: "-25.6887",
    Longitude: "28.7455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9055",
    WardLookupId: "2666",
    VDNumber: "54510128",
    RegisteredPopulation: "741",
    VotingStationName: "RAYS CHURCH",
    Address: "PROPER SECTION  EKANGALA  KUNGWINI",
    Latitude: "-25.6857",
    Longitude: "28.7352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9056",
    WardLookupId: "2666",
    VDNumber: "54510151",
    RegisteredPopulation: "668",
    VotingStationName: "ASSEMBLIES OF GOD",
    Address: "SECTION A  EKANGALA  BRONKHORSTSPRUIT",
    Latitude: "-25.6992",
    Longitude: "28.7429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9057",
    WardLookupId: "2667",
    VDNumber: "54510016",
    RegisteredPopulation: "1335",
    VotingStationName: "ZIVUSENI COMBINED SCHOOL",
    Address: "EXTENSION 1  EKANGALA EXT 1  EKANGALA",
    Latitude: "-25.6762",
    Longitude: "28.7251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9058",
    WardLookupId: "2667",
    VDNumber: "54510061",
    RegisteredPopulation: "1441",
    VotingStationName: "MANDLOMSOBO COMBINED SCHOOL",
    Address: "520 DARK CITY  EKANGALA  KUNGWINI",
    Latitude: "-25.6682",
    Longitude: "28.7263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9059",
    WardLookupId: "2667",
    VDNumber: "54510072",
    RegisteredPopulation: "2751",
    VotingStationName: "MASAKHANE COMMUNITY CENTRE",
    Address: "EKANGALA  KUNGWINI",
    Latitude: "-25.6625",
    Longitude: "28.7281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9060",
    WardLookupId: "2667",
    VDNumber: "54510083",
    RegisteredPopulation: "1732",
    VotingStationName: "DEVINE CHURCH",
    Address: "EKANGALA  BHS",
    Latitude: "-25.6573",
    Longitude: "28.7345",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9061",
    WardLookupId: "2667",
    VDNumber: "54510106",
    RegisteredPopulation: "1359",
    VotingStationName: "SIHLUZIWE PRIMARY SCHOOL",
    Address: "1105 SECTION F  EKANGALA  KUNGWINI",
    Latitude: "-25.6581",
    Longitude: "28.7274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9062",
    WardLookupId: "2667",
    VDNumber: "54510117",
    RegisteredPopulation: "669",
    VotingStationName: "LINGITJHUDU SECONDARY SCHOOL",
    Address: "N  EKANGALA  KUNGWINI",
    Latitude: "-25.6772",
    Longitude: "28.7276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9063",
    WardLookupId: "2667",
    VDNumber: "54510139",
    RegisteredPopulation: "1705",
    VotingStationName: "THUTOPELE DAY CARE",
    Address: "3657 SECTION I  EKANGALA SEC I  EKANGALA",
    Latitude: "-25.66",
    Longitude: "28.7424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9064",
    WardLookupId: "2667",
    VDNumber: "54540345",
    RegisteredPopulation: "1460",
    VotingStationName: "SIKHULISILE PRIMARY SCHOOL",
    Address: "1934 EKANGALA SECTION H  EKANGALA  BRONKHORSTSPRUIT",
    Latitude: "-25.6628",
    Longitude: "28.7372",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9065",
    WardLookupId: "2668",
    VDNumber: "33040077",
    RegisteredPopulation: "2078",
    VotingStationName: "LAERSKOOL DU PREEZ VAN WYK",
    Address: "GENERAL LOUIS BOTHA  BRONKHORSTSPRUIT  BRONKHORSTSPRUIT",
    Latitude: "-25.8091",
    Longitude: "28.7383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9066",
    WardLookupId: "2668",
    VDNumber: "33040134",
    RegisteredPopulation: "710",
    VotingStationName: "CULTURA HIGH SCHOOL",
    Address: "(GT462 - KUNGWINI [BRONKHORSTSPRUIT])   ",
    Latitude: "-25.8249",
    Longitude: "28.7386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9067",
    WardLookupId: "2668",
    VDNumber: "33040167",
    RegisteredPopulation: "579",
    VotingStationName: "HOÃ‹RSKOOL ERASMUS",
    Address: "6 CATHIESTREET  BRONKHORSTSPRUIT  BRONKHORSTSPRUIT",
    Latitude: "-25.8125",
    Longitude: "28.7333",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9068",
    WardLookupId: "2668",
    VDNumber: "33110019",
    RegisteredPopulation: "202",
    VotingStationName: "ODEZWAAN PRIMARY SCHOOL",
    Address: "542 ODEZWAAN SKRAAL  BRONKHORSTSPEUIT  KWUNGWINI",
    Latitude: "-25.9105",
    Longitude: "28.6369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9069",
    WardLookupId: "2668",
    VDNumber: "33110020",
    RegisteredPopulation: "211",
    VotingStationName: "REFANO PRIMARY SCHOOL",
    Address: "GROBBLERSDAL ROAD  BHS  KUNGWINI",
    Latitude: "-25.657",
    Longitude: "28.8848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9070",
    WardLookupId: "2668",
    VDNumber: "33110031",
    RegisteredPopulation: "204",
    VotingStationName: "MEULSTROOM LODGE",
    Address: "16 PLOT  ZUSTERSTROOM  ZUSTERSTROOM",
    Latitude: "-25.6112",
    Longitude: "29.0051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9071",
    WardLookupId: "2668",
    VDNumber: "33110042",
    RegisteredPopulation: "658",
    VotingStationName: "SIZANANI VILLAGE",
    Address: "GROBLERSDAL ROAD  BHS  KUNGWINI",
    Latitude: "-25.7745",
    Longitude: "28.7693",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9072",
    WardLookupId: "2668",
    VDNumber: "33110053",
    RegisteredPopulation: "154",
    VotingStationName: "TENT -EZEMVELO NATURE RESERVE",
    Address: "493JR ELANDSFONTEIN  ELANDSFONTEIN 493JR  BRONKHORSTSPRUIT",
    Latitude: "-25.7084",
    Longitude: "28.93",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9073",
    WardLookupId: "2668",
    VDNumber: "33110064",
    RegisteredPopulation: "306",
    VotingStationName: "JAN LOOTS EDUCATIONAL CENTRE",
    Address: "108 NOOITGEDACHT  BRONKHORSTSPRUIT",
    Latitude: "-25.8438",
    Longitude: "28.7698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9074",
    WardLookupId: "2668",
    VDNumber: "33110075",
    RegisteredPopulation: "255",
    VotingStationName: "THERESO PRIMARY SCHOOL",
    Address: "PLOT 19 WITKLIP  WITKLIP  KUNGWINI",
    Latitude: "-25.9114",
    Longitude: "28.789",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9075",
    WardLookupId: "2668",
    VDNumber: "33110086",
    RegisteredPopulation: "285",
    VotingStationName: "TENT - SPORTSGROUND ARBOR ROAD",
    Address: "563 WITPOORT 563  WITPOORT  KUNGWINI",
    Latitude: "-25.952",
    Longitude: "28.8176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9076",
    WardLookupId: "2668",
    VDNumber: "33110323",
    RegisteredPopulation: "977",
    VotingStationName: "MASADA LIGKRUIS GEMEENTE",
    Address: "NOOITGEDACHT STREET  MASADA  BRONKHORSTSPRUIT",
    Latitude: "-25.8144",
    Longitude: "28.7529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9077",
    WardLookupId: "2668",
    VDNumber: "33110356",
    RegisteredPopulation: "86",
    VotingStationName: "LUCKY PRIMARY SCHOOL",
    Address: "40 SPITSKOP  BHS",
    Latitude: "-25.7797",
    Longitude: "28.8196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9078",
    WardLookupId: "2668",
    VDNumber: "33110367",
    RegisteredPopulation: "315",
    VotingStationName: "KWENA MARIBANE ACCOMODATION",
    Address: "151 BALMORAL ROAD  WACHTENBIETJIESKOP  BRONKHORSTSPRUIT",
    Latitude: "-25.8206",
    Longitude: "28.8776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9079",
    WardLookupId: "2668",
    VDNumber: "33110424",
    RegisteredPopulation: "105",
    VotingStationName: "PHUMULA OLD AGE VILLAGE",
    Address: "MODDERFONTEIN  BRONKHORSTSPRUIT  MODDERFONTEIN",
    Latitude: "-25.7235",
    Longitude: "28.7941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9080",
    WardLookupId: "2668",
    VDNumber: "54510049",
    RegisteredPopulation: "1169",
    VotingStationName: "EKANGALA COMPREHENSIVE SCHOOL",
    Address: "EKANGALA  KUNGWINI",
    Latitude: "-25.6976",
    Longitude: "28.7471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9081",
    WardLookupId: "2668",
    VDNumber: "54510050",
    RegisteredPopulation: "2739",
    VotingStationName: "EKANGALA COMMUNITY HALL",
    Address: "EKANGALA  KUNGWINI",
    Latitude: "-25.6976",
    Longitude: "28.7517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9082",
    WardLookupId: "2668",
    VDNumber: "54510140",
    RegisteredPopulation: "662",
    VotingStationName: "APOSTOLIC FAITH MISSION",
    Address: "GOCHVILLE  EKANGALA SEC D  EKANGALA",
    Latitude: "-25.7013",
    Longitude: "28.75",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9083",
    WardLookupId: "2668",
    VDNumber: "54540031",
    RegisteredPopulation: "1622",
    VotingStationName: "MAHLENGA HIGH SCHOOL",
    Address: "1545 SOKHULUMI  SOKHULUMI VILLAGE  NKANGALA",
    Latitude: "-25.6015",
    Longitude: "28.8943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9084",
    WardLookupId: "2669",
    VDNumber: "32980305",
    RegisteredPopulation: "886",
    VotingStationName: "TENT - NEXT TO OLD PHILENA MIDDLE SCHOOL",
    Address: "173 PLOT  CENTURION  KNOPPIESLAAGTE",
    Latitude: "-25.9299",
    Longitude: "28.053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9085",
    WardLookupId: "2669",
    VDNumber: "32980372",
    RegisteredPopulation: "7125",
    VotingStationName: "OLIEVENHOUTBOSCH COMMUNITY HALL",
    Address: "RETHABELE AND LEGONG STR  OLIEVENHOUTBOSCH X 13  CENTURION",
    Latitude: "-25.9114",
    Longitude: "28.0935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9086",
    WardLookupId: "2669",
    VDNumber: "32980394",
    RegisteredPopulation: "6853",
    VotingStationName: "SESHEGONG SECONDARY SCHOOL",
    Address: "LETLOTLO  CENTURION  OLIEVENHOUTBOSCH EXT 36",
    Latitude: "-25.9005",
    Longitude: "28.099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9087",
    WardLookupId: "2669",
    VDNumber: "32980417",
    RegisteredPopulation: "4856",
    VotingStationName: "OLIEVENHOUTBOSCH CHRISTIAN-SCHOOL",
    Address: "37 EXT 37  OLIEVENTBOSCH  OLIEVENHOUTBOSCH",
    Latitude: "-25.9216",
    Longitude: "28.091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9088",
    WardLookupId: "2670",
    VDNumber: "32951808",
    RegisteredPopulation: "4342",
    VotingStationName: "TENT (VUKU UZAME BRICK YARD)",
    Address: "BLOCK DD14 VERGENOEG  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.7891",
    Longitude: "28.0362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9089",
    WardLookupId: "2670",
    VDNumber: "33280084",
    RegisteredPopulation: "6174",
    VotingStationName: "TENT AT BRAZAVILLE SPORTS GROUND",
    Address: "VERGENOEG INFORMAL SETTLEMENT  SAULSVILLE  ATTREDGEVILLE",
    Latitude: "-25.788",
    Longitude: "28.029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9090",
    WardLookupId: "2670",
    VDNumber: "33280095",
    RegisteredPopulation: "2234",
    VotingStationName: "OASIS DAY CARE AND COMMUNITY CRECHE",
    Address: "AD 71 SIYAHLALA BRAZAVILLE  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.795",
    Longitude: "28.0242",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9091",
    WardLookupId: "2670",
    VDNumber: "33280129",
    RegisteredPopulation: "1758",
    VotingStationName: "TENT - MORETOLOGA EXT. 17",
    Address: "MORETOLOGA EXT 17  ATTERIDGEVILLE  PRETORIA",
    Latitude: "-25.7843",
    Longitude: "28.032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9092",
    WardLookupId: "3247",
    VDNumber: "43400179",
    RegisteredPopulation: "2504",
    VotingStationName: "NOMFIHLELA PRIMARY SCHOOL",
    Address: "D1028 MZOMUNYE ROAD  KWAXIMBA, CATO RIDGE 3680  ETHEKWINI",
    Latitude: "-29.669",
    Longitude: "30.6264",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9093",
    WardLookupId: "3247",
    VDNumber: "43400180",
    RegisteredPopulation: "1541",
    VotingStationName: "OTHWEBA PRIMARY SCHOOL",
    Address:
      "NTABENI RD VIA ASSMANG, OTHWEB  OTHWEBA, NEAR MBOVANA STORE  KWAXIMBA",
    Latitude: "-29.6951",
    Longitude: "30.5924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9094",
    WardLookupId: "3247",
    VDNumber: "43400191",
    RegisteredPopulation: "906",
    VotingStationName: "CATO RIDGE LIBRARY ACTIVITIES ROOM",
    Address: "OLD MAIN ROAD  CATO RIDGE  ETHEKWINI",
    Latitude: "-29.7367",
    Longitude: "30.5788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9095",
    WardLookupId: "3247",
    VDNumber: "43400528",
    RegisteredPopulation: "1890",
    VotingStationName: "NGIDI PRIMARY SCHOOL",
    Address:
      "PHUMEKHAYA ROAD, BHOBHONONO  KWA XIMBA AREA, CATO RIDGE  ETHEKWINI",
    Latitude: "-29.6539",
    Longitude: "30.66",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9096",
    WardLookupId: "3247",
    VDNumber: "43400539",
    RegisteredPopulation: "1674",
    VotingStationName: "MVINI PRIMARY SCHOOL",
    Address:
      "MUTHI ROAD, KWAXIMBA TRIBAL   MVINI AREA, CATO RIDGE 3680  KWAXIMBA",
    Latitude: "-29.6369",
    Longitude: "30.6716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9097",
    WardLookupId: "3247",
    VDNumber: "43400595",
    RegisteredPopulation: "2176",
    VotingStationName: "XIMBA COMMUNITY HALL",
    Address: "9 MAGAYISA ROAD, KWAXIMBA AREA  CATO RIDGE 3680  ETHEKWINI",
    Latitude: "-29.6656",
    Longitude: "30.6366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9098",
    WardLookupId: "3247",
    VDNumber: "43400607",
    RegisteredPopulation: "1109",
    VotingStationName: "KWADENGE COMMUNITY HALL",
    Address: "MAGAYISA ROAD  CATO RIDGE  KWAXIMBA",
    Latitude: "-29.6846",
    Longitude: "30.6355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9099",
    WardLookupId: "3247",
    VDNumber: "43400618",
    RegisteredPopulation: "2078",
    VotingStationName: "NTUKUSO PRIMARY SCHOOL",
    Address:
      "D1022 SIHONQANA ROAD, NTUKUSO AREA  KWAXIMBA, CATO RIDGE, 3680   ETHEKWINI",
    Latitude: "-29.6738",
    Longitude: "30.5885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9100",
    WardLookupId: "3247",
    VDNumber: "43400696",
    RegisteredPopulation: "1822",
    VotingStationName: "INGCINDEZI PRIMARY SCHOOL",
    Address: "ALICE GOSEWELL ROUD  CATO RIDGE  ETHEKWINI",
    Latitude: "-29.7052",
    Longitude: "30.5774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9101",
    WardLookupId: "3247",
    VDNumber: "43400708",
    RegisteredPopulation: "1197",
    VotingStationName: "INSIMANGWE PRIMARY SCHOOL",
    Address:
      "MAQATHA ROAD, ESIWENI AREA   KWAXIMBA, CATO RIDGE 3680  KWAXIMBA TC",
    Latitude: "-29.686",
    Longitude: "30.6159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9102",
    WardLookupId: "3247",
    VDNumber: "43400719",
    RegisteredPopulation: "815",
    VotingStationName: "INDUNAKAZI PRIMARY SCHOOL",
    Address: "D1004 PHUMEKHAYA ROAD  CATO RIDGE  KWAXIMBA",
    Latitude: "-29.6779",
    Longitude: "30.7034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9103",
    WardLookupId: "3247",
    VDNumber: "43400887",
    RegisteredPopulation: "912",
    VotingStationName: "EMKHATHINI PRIMARY SCHOOL",
    Address: "PHUMEKHAYA ROAD  CATO RIDGE  KWAXIMBA TRIBAL COURT",
    Latitude: "-29.6635",
    Longitude: "30.6554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9104",
    WardLookupId: "3248",
    VDNumber: "43580662",
    RegisteredPopulation: "1565",
    VotingStationName: "SIYAJABULA HIGH SCHOOL",
    Address: "ZULU RD  KWANYUSWA  ETHEKWINI",
    Latitude: "-29.695",
    Longitude: "30.7551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9105",
    WardLookupId: "3248",
    VDNumber: "43580673",
    RegisteredPopulation: "2082",
    VotingStationName: "QINISELANI TRIBAL COURT",
    Address: "ZULU RESERVE ROAD  KWANYUSWA AREA , BOTHAS HILL 3660  ETHEKWINI",
    Latitude: "-29.6823",
    Longitude: "30.7596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9106",
    WardLookupId: "3248",
    VDNumber: "43580763",
    RegisteredPopulation: "1290",
    VotingStationName: "UMPHUMELA PRIMARY SCHOOL",
    Address:
      "1120203 ZULU RESERVE ROAD  BOTHA`S HILL, KWANYUSWA (MLAMBO)  QINISELANI MANYUSWA TC",
    Latitude: "-29.6704",
    Longitude: "30.7639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9107",
    WardLookupId: "3248",
    VDNumber: "43580774",
    RegisteredPopulation: "662",
    VotingStationName: "KHAMANGWA SECONDARY SCHOOL",
    Address: "MAPHEPHETHENI TRIBAL AUTHORITY  GCALIPHIWE  ETHEKWINI",
    Latitude: "-29.6587",
    Longitude: "30.8417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9108",
    WardLookupId: "3248",
    VDNumber: "43580785",
    RegisteredPopulation: "1422",
    VotingStationName: "BONGUMUSA PRIMARY SCHOOL",
    Address: "MGANGENI AREA  MAPHEPHETHENI  ETHEKWINI",
    Latitude: "-29.6607",
    Longitude: "30.8616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9109",
    WardLookupId: "3248",
    VDNumber: "43581315",
    RegisteredPopulation: "1010",
    VotingStationName: "MAPHEPHETHE TRIDITONAL COUNCIL",
    Address:
      "050249 MAPHEPHETHA MAIN ROAD  MBOZAMO AREA, LOWER MAPHEPHETHE, INANDA 4309  ETHEKWINI",
    Latitude: "-29.6453",
    Longitude: "30.7926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9110",
    WardLookupId: "3248",
    VDNumber: "43581326",
    RegisteredPopulation: "610",
    VotingStationName: "UPHISO PRIMARY SCHOOL",
    Address: "UPPER MAPHEPHETHE, AMATATA ARE  INANDA, 4310  ETHEKWINI",
    Latitude: "-29.6171",
    Longitude: "30.8054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9111",
    WardLookupId: "3248",
    VDNumber: "43581359",
    RegisteredPopulation: "436",
    VotingStationName: "NKANGALA TEMP VS (TENT)",
    Address: "UPPER-MAPHEPHETHE  NKANGALA AREA  MAPHEPHETHE TRIBAL AUTHORITY",
    Latitude: "-29.5819",
    Longitude: "30.765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9112",
    WardLookupId: "3248",
    VDNumber: "43581988",
    RegisteredPopulation: "496",
    VotingStationName: "LETHOKUHLE COMBINED PRIMARY SCHOOL SCHOOL",
    Address: "OFF NGOZA RD UMQEKU  MAPHEPHETHWENI  EHTEKWINI",
    Latitude: "-29.5986",
    Longitude: "30.7342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9113",
    WardLookupId: "3248",
    VDNumber: "43582046",
    RegisteredPopulation: "636",
    VotingStationName: "KWANONGQANGA PRIMARY SCHOOL",
    Address:
      "AMATATA ROUTE,   NEAR UMQEKU SCHOOL, UPPER-MAPHEPHETHE, INANDA 4310  ETHEKWINI",
    Latitude: "-29.6001",
    Longitude: "30.7726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9114",
    WardLookupId: "3248",
    VDNumber: "43582855",
    RegisteredPopulation: "532",
    VotingStationName: "AMAQADI COMBINED PRIMARY SCHOOL",
    Address: "D 1506 NTONONO ROAD  AMABEDLANE, BOTHAS HILL 3660  ETHEKWINI",
    Latitude: "-29.6471",
    Longitude: "30.7538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9115",
    WardLookupId: "3248",
    VDNumber: "43582866",
    RegisteredPopulation: "738",
    VotingStationName: "MATHABETHU PRIMARY SCHOOL",
    Address:
      "NGODE ROAD, MAZANGANE  MATHEBETHU AREA, BOTHA`S HILL 3660  ETHEKWINI",
    Latitude: "-29.6819",
    Longitude: "30.7361",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9116",
    WardLookupId: "3248",
    VDNumber: "43583014",
    RegisteredPopulation: "1274",
    VotingStationName: "INGCUKWINI PRIMARY SCHOOL",
    Address:
      "MBEJE MAIN ROAD  INGCUKWINI AREA, LOWER MAPHEPHETHE, INANDA 4310   ETHEKWINI",
    Latitude: "-29.671",
    Longitude: "30.8254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9117",
    WardLookupId: "3248",
    VDNumber: "43583755",
    RegisteredPopulation: "263",
    VotingStationName: "MBANANA ZENEX PRIMARY SCHOOL",
    Address: "SHELELA  KWANYUSWA RESERVE, BOTHAS HILL  ETHEKWINI",
    Latitude: "-29.6482",
    Longitude: "30.7839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9118",
    WardLookupId: "3248",
    VDNumber: "43584554",
    RegisteredPopulation: "649",
    VotingStationName: "KHULANI JUNIOR PRIMARY SCHOOL",
    Address:
      "MBEJE ROAD, KWAVUTHA  LOWER MAPHEPHETHE, INANDA 4310  MAPHEPHETHE TRIBAL COURT",
    Latitude: "-29.6483",
    Longitude: "30.8137",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9119",
    WardLookupId: "3248",
    VDNumber: "43584565",
    RegisteredPopulation: "433",
    VotingStationName: "SILINDELE LP SCHOOL",
    Address: "ZULU RESERVE ROAD  BOTHA`S HILL  KWANYUSWA AREA",
    Latitude: "-29.6878",
    Longitude: "30.7565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9120",
    WardLookupId: "3248",
    VDNumber: "43772249",
    RegisteredPopulation: "1126",
    VotingStationName: "NOGUJWA HIGH SCHOOL",
    Address: "NAGLE DAM ROAD, KWAKHIZWANE   CATO RIDGE, 3680  ETHEKWINI",
    Latitude: "-29.6558",
    Longitude: "30.6869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9121",
    WardLookupId: "3248",
    VDNumber: "43772250",
    RegisteredPopulation: "809",
    VotingStationName: "INGOMEZULU PRIMARY SCHOOL",
    Address:
      "KWA MKHIZWANE AREA VIA NO 9,POLICE STATION ACROSS UMNGENI BGIDGE, CATO RIDGE 3680  ETHEKWINI",
    Latitude: "-29.6112",
    Longitude: "30.6939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9122",
    WardLookupId: "3248",
    VDNumber: "43775488",
    RegisteredPopulation: "104",
    VotingStationName: "MKHIZWANA JP SCHOOL",
    Address: "NKANGALA  CATO RIDGE  MKHIZWANA RESERVE",
    Latitude: "-29.637",
    Longitude: "30.7128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9123",
    WardLookupId: "3249",
    VDNumber: "43580684",
    RegisteredPopulation: "2120",
    VotingStationName: "KWAGENCE SCHOOL",
    Address: "KWAGENCE AREA  MZINYATHI  ETHEKWINI",
    Latitude: "-29.68",
    Longitude: "30.8897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9124",
    WardLookupId: "3249",
    VDNumber: "43580796",
    RegisteredPopulation: "644",
    VotingStationName: "MAGQIBAGQIBA SECONDARY SCHOOL",
    Address: "EKUMANAZENI AREA  INANDA  ETHEKWINI",
    Latitude: "-29.654",
    Longitude: "30.9152",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9125",
    WardLookupId: "3249",
    VDNumber: "43582013",
    RegisteredPopulation: "933",
    VotingStationName: "SILWANE SCHOOL",
    Address: "EKUKHANYENI MISSION  VERULAM  ETHEKWINI",
    Latitude: "-29.6142",
    Longitude: "30.9133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9126",
    WardLookupId: "3249",
    VDNumber: "43582024",
    RegisteredPopulation: "1019",
    VotingStationName: "ENGONWENI HIGHER PRIMARY SCHOOL",
    Address: "ENGONWENI AREA  NDWEDWE  ETHEKWINI",
    Latitude: "-29.5999",
    Longitude: "30.8921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9127",
    WardLookupId: "3249",
    VDNumber: "43582967",
    RegisteredPopulation: "637",
    VotingStationName: "NYONI PRIMARY SCHOOL",
    Address: "UMLINYATHI AREA  INANDA  ETHEKWINI",
    Latitude: "-29.6793",
    Longitude: "30.8744",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9128",
    WardLookupId: "3249",
    VDNumber: "43582978",
    RegisteredPopulation: "2524",
    VotingStationName: "DABEKA HIGH SCHOOL",
    Address: "NEXT TO UMZINYATHI POWER STATION  UMZINYATHI  ETHEKWINI",
    Latitude: "-29.6542",
    Longitude: "30.892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9129",
    WardLookupId: "3249",
    VDNumber: "43582989",
    RegisteredPopulation: "1091",
    VotingStationName: "NEW RIVER PRIMARY SCHOOL",
    Address: "EMANYASENI AREA  KHUSANE  ETHEKWINI",
    Latitude: "-29.6267",
    Longitude: "30.8899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9130",
    WardLookupId: "3249",
    VDNumber: "43583003",
    RegisteredPopulation: "975",
    VotingStationName: "IMBEKA HIGH SCHOOL",
    Address: "109 NDWEDWE  INANDA  ETHEKWINI",
    Latitude: "-29.6357",
    Longitude: "30.8437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9131",
    WardLookupId: "3249",
    VDNumber: "43583047",
    RegisteredPopulation: "2110",
    VotingStationName: "QADI TRIBAL COURT",
    Address: "155 NDWEDWE  INANDA  ETHEKWINI",
    Latitude: "-29.6679",
    Longitude: "30.9015",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9132",
    WardLookupId: "3249",
    VDNumber: "43583665",
    RegisteredPopulation: "1295",
    VotingStationName: "MAKHAPHA COMBINED SCHOOL",
    Address: "EGUGWINI  INANDA  ETHEKWINI",
    Latitude: "-29.6587",
    Longitude: "30.8813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9133",
    WardLookupId: "3249",
    VDNumber: "43583979",
    RegisteredPopulation: "1276",
    VotingStationName: "MATABETULU PRIMARY SCHOOL",
    Address: "EBUHLENI VILLAGE  INANDA  ETHEKWINI",
    Latitude: "-29.7016",
    Longitude: "30.8918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9134",
    WardLookupId: "3249",
    VDNumber: "43584352",
    RegisteredPopulation: "462",
    VotingStationName: "NDABENHLE PRIMARY SCHOOL",
    Address: "AMATATA MAIN ROAD, MZINYATHI  INANDA  ETHEKWINI",
    Latitude: "-29.6361",
    Longitude: "30.8658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9135",
    WardLookupId: "3249",
    VDNumber: "43584532",
    RegisteredPopulation: "911",
    VotingStationName: "SENZOKUHLE L P SCHOOL",
    Address: "INANDA ROAD  UMZINYATHI  QADI T/A",
    Latitude: "-29.6565",
    Longitude: "30.9024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9136",
    WardLookupId: "3249",
    VDNumber: "43584633",
    RegisteredPopulation: "440",
    VotingStationName: "MANDOSI COMBINED SCHOOL",
    Address: "MANYASENI ROAD  INANDA  INANDA",
    Latitude: "-29.6346",
    Longitude: "30.9016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9137",
    WardLookupId: "3250",
    VDNumber: "43400124",
    RegisteredPopulation: "1890",
    VotingStationName: "ESIKHELEKEHLENI COMMUNITY HALL",
    Address:
      "D1004 ESIKHELEHLENI ROAD  LOWER INCHANGA, ESIKHELEKEHLENI  ETHEKWINI",
    Latitude: "-29.7266",
    Longitude: "30.675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9138",
    WardLookupId: "3250",
    VDNumber: "43400225",
    RegisteredPopulation: "3894",
    VotingStationName: "SINENZUZO PRIMARY SCHOOL",
    Address: "HAMMARSDALE EXTENTION  HAMMARSDALE  ETHEKWINI",
    Latitude: "-29.7898",
    Longitude: "30.6386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9139",
    WardLookupId: "3250",
    VDNumber: "43400562",
    RegisteredPopulation: "1718",
    VotingStationName: "XIMBA PRIMARY SCHOOL",
    Address: "FREDVILLE MAIN ROAD  INCHANGA  ETHEKWINI",
    Latitude: "-29.6842",
    Longitude: "30.6853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9140",
    WardLookupId: "3250",
    VDNumber: "43400573",
    RegisteredPopulation: "3337",
    VotingStationName: "MICHAEL GWALA COMMUNITY HALL",
    Address: "FREDVILLE MAIN ROAD  FREDVILLE  ETHEKWINI",
    Latitude: "-29.7016",
    Longitude: "30.6745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9141",
    WardLookupId: "3250",
    VDNumber: "43400584",
    RegisteredPopulation: "3318",
    VotingStationName: "RIETVALLEI SECONDARY SCHOOL",
    Address: "TIN TOWN AREA; INCHANGA 3670  INCHANGA  INCHANGA",
    Latitude: "-29.7185",
    Longitude: "30.6509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9142",
    WardLookupId: "3250",
    VDNumber: "43400630",
    RegisteredPopulation: "529",
    VotingStationName: "OLD HAMMARSDALE LIBRARY",
    Address: "ANDERSON ROAD  MPUMALANGA T/SHIP  HAMMARSDALE",
    Latitude: "-29.7915",
    Longitude: "30.6624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9143",
    WardLookupId: "3250",
    VDNumber: "43400641",
    RegisteredPopulation: "1105",
    VotingStationName: "AMAZONDI STORE",
    Address: "25 INCHANGA DRIVE  INCHANGA  ETHEKWINI",
    Latitude: "-29.7325",
    Longitude: "30.663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9144",
    WardLookupId: "3250",
    VDNumber: "43400720",
    RegisteredPopulation: "905",
    VotingStationName: "INTONGELA COMBINED PRIMARY SCHOOL",
    Address: "ESIKHELEKEHLEMI  KWAXIMBA  ETHEKWINI",
    Latitude: "-29.7065",
    Longitude: "30.6989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9145",
    WardLookupId: "3250",
    VDNumber: "43400731",
    RegisteredPopulation: "674",
    VotingStationName: "EMNGCWINI PRIMARY SCHOOL",
    Address: "EMNGCWINI  KWAXIMBA  ETHEKWINI",
    Latitude: "-29.7063",
    Longitude: "30.6416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9146",
    WardLookupId: "3250",
    VDNumber: "43400821",
    RegisteredPopulation: "2647",
    VotingStationName: "MBALIYEZWE LOWER PRIMARY SCHOOL",
    Address: "NEAR FREDVILLE CLINIC  INCHANGA  ETHEKWINI [DURBAN METRO]",
    Latitude: "-29.7082",
    Longitude: "30.6687",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9147",
    WardLookupId: "3251",
    VDNumber: "43400236",
    RegisteredPopulation: "2668",
    VotingStationName: "GEORGEDALE COMBINED PRIMARY SCHOOL",
    Address: "GEORGEDALE MISSION GAB STREET  ETHEKWINI",
    Latitude: "-29.7938",
    Longitude: "30.6094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9148",
    WardLookupId: "3251",
    VDNumber: "43400247",
    RegisteredPopulation: "3839",
    VotingStationName: "AMATSHEZULU HIGH SCHOOL",
    Address: "GAB STREET  GEORGEDALE  GEORGEDALE",
    Latitude: "-29.7923",
    Longitude: "30.6097",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9149",
    WardLookupId: "3251",
    VDNumber: "43400258",
    RegisteredPopulation: "1914",
    VotingStationName: "PEACEVILLE COMBINED PRIMARY SCHOOL",
    Address: "KWAMOYA MISSION  GEORGEDALE /MPUMALANGA 3700  ETHEKWINI",
    Latitude: "-29.7955",
    Longitude: "30.6234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9150",
    WardLookupId: "3251",
    VDNumber: "43400269",
    RegisteredPopulation: "4044",
    VotingStationName: "SANKONTSHE PRIMARY SCHOOL",
    Address: "M430 SANKONTSHE MAIN ROAD  SANKONTSHE  HAMMARSDALE",
    Latitude: "-29.8015",
    Longitude: "30.5939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9151",
    WardLookupId: "3251",
    VDNumber: "43400270",
    RegisteredPopulation: "2921",
    VotingStationName: "MOPHELA PRIMARY SCHOOL",
    Address: "MAIN ROAD, MOPHELA  HAMMARSDALE  ETHEKWINI",
    Latitude: "-29.8194",
    Longitude: "30.5866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9152",
    WardLookupId: "3252",
    VDNumber: "43400157",
    RegisteredPopulation: "2418",
    VotingStationName: "ELANGABINI LOWER PRIMARY SCHOOL",
    Address: "C 329 MPUMALANGA  HAMMARSDALE  ETHEKWINI",
    Latitude: "-29.8184",
    Longitude: "30.6385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9153",
    WardLookupId: "3252",
    VDNumber: "43400203",
    RegisteredPopulation: "2950",
    VotingStationName: "INGEDE SENIOR PRIMARY SCHOOL",
    Address: "C 523 THULU RD  MPUMALANGA  ETHEKWINI",
    Latitude: "-29.8205",
    Longitude: "30.6344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9154",
    WardLookupId: "3252",
    VDNumber: "43400214",
    RegisteredPopulation: "2602",
    VotingStationName: "EKWANDENI PRIMARY SCHOOL",
    Address: "H926 SENGWAYO ROAD  MPUMALANGA T/SHIP  EMBO / ILANGA",
    Latitude: "-29.8279",
    Longitude: "30.6479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9155",
    WardLookupId: "3252",
    VDNumber: "43400292",
    RegisteredPopulation: "1268",
    VotingStationName: "NCINCI LOWER PRIMARY SCHOOL",
    Address: "D293 SAGANGA ROAD  MPUMALANGA TOWNSHIP, HAMMARSDALE  ETHEKWINI",
    Latitude: "-29.8236",
    Longitude: "30.626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9156",
    WardLookupId: "3252",
    VDNumber: "43400371",
    RegisteredPopulation: "3588",
    VotingStationName: "KHALAWEMUKE  PRIMARY SCHOOL",
    Address: "D1303 DUBANDLELA RD  MPUMALANGA TOWNSHIP, HAMMARSDALE  ETHEKWINI",
    Latitude: "-29.8305",
    Longitude: "30.6265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9157",
    WardLookupId: "3252",
    VDNumber: "43400551",
    RegisteredPopulation: "752",
    VotingStationName: "INDLULAMITHI LOWER PRIMARY SCHOOL",
    Address: "C1403 MPUMALANGA TOWNSHIP  HAMMARSDALE  ETHEKWINI",
    Latitude: "-29.8181",
    Longitude: "30.6264",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9158",
    WardLookupId: "3252",
    VDNumber: "43400629",
    RegisteredPopulation: "1306",
    VotingStationName: "EKWENAMENI LOWER PRIMARY SCHOOL",
    Address: "C1401 NKULULEKO RD  MPUMALANGA  ETHEKWINI",
    Latitude: "-29.817",
    Longitude: "30.6271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9159",
    WardLookupId: "3252",
    VDNumber: "43400685",
    RegisteredPopulation: "3619",
    VotingStationName: "EMAXULWINI PRIMARY SCHOOL",
    Address: "UNIT 4 SAGANGA ROAD  MPUMALANGA  ETHEKWINI",
    Latitude: "-29.8252",
    Longitude: "30.6209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9160",
    WardLookupId: "3253",
    VDNumber: "43400113",
    RegisteredPopulation: "2022",
    VotingStationName: "CHARLES MEMORIAL C.P SCHOOL",
    Address: "D999 MKHIZE ROAD  NTSHONGWENI  PINETOWN",
    Latitude: "-29.8524",
    Longitude: "30.6889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9161",
    WardLookupId: "3253",
    VDNumber: "43400146",
    RegisteredPopulation: "3321",
    VotingStationName: "KWAMYEZA PRIMARY SCHOOL",
    Address: "H464 SIBISI ROAD, MPUMALANGA T/SHIP  HAMMARSDALE 3700  ETHEKWINI",
    Latitude: "-29.8227",
    Longitude: "30.6551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9162",
    WardLookupId: "3253",
    VDNumber: "43400450",
    RegisteredPopulation: "2452",
    VotingStationName: "HILLCREST LIBRARY ACTIVITIES ROOM",
    Address: "DELAMORE ROAD  HILLCREST  ETHEKWINI",
    Latitude: "-29.7838",
    Longitude: "30.762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9163",
    WardLookupId: "3253",
    VDNumber: "43400461",
    RegisteredPopulation: "4064",
    VotingStationName: "EDAMINI PRIMARY SCHOOL",
    Address: "DAMINI ROAD  SHONGWENI RESOURCE RESERVE  EDAMINI AREA",
    Latitude: "-29.8556",
    Longitude: "30.7374",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9164",
    WardLookupId: "3253",
    VDNumber: "43400775",
    RegisteredPopulation: "3244",
    VotingStationName: "NTSHONGWENI PRIMARY SCHOOL",
    Address: "D 210 EMAROMENI  SHONGWENI",
    Latitude: "-29.8369",
    Longitude: "30.6868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9165",
    WardLookupId: "3253",
    VDNumber: "43400786",
    RegisteredPopulation: "1230",
    VotingStationName: "LALELANI PRIMARY SCHOOL",
    Address: "ZAKHIWENI RD  NTSHONGWENI  ETHEKWINI",
    Latitude: "-29.8511",
    Longitude: "30.6721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9166",
    WardLookupId: "3253",
    VDNumber: "43400944",
    RegisteredPopulation: "364",
    VotingStationName: "EMAFUTHENI REST ROOM",
    Address: "EMAFUTHENI  MPUMALANGA  HAMMARSDALE",
    Latitude: "-29.8088",
    Longitude: "30.6581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9167",
    WardLookupId: "3254",
    VDNumber: "43400078",
    RegisteredPopulation: "6193",
    VotingStationName: "HILLCREST HIGH SCHOOL",
    Address: "40 RIDGE ROAD  HILLCREST  ETHEKWINI",
    Latitude: "-29.7775",
    Longitude: "30.7771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9168",
    WardLookupId: "3254",
    VDNumber: "43580695",
    RegisteredPopulation: "1709",
    VotingStationName: "HLAHLINDLELA HIGH SCHOOL",
    Address: "KWANGCOLOSI MAIN ROAD  KWANGCOLOSI  ETHEKWINI",
    Latitude: "-29.714",
    Longitude: "30.8197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9169",
    WardLookupId: "3254",
    VDNumber: "43580730",
    RegisteredPopulation: "1619",
    VotingStationName: "NDLOKOLO PRIMARY SCHOOL",
    Address: "NGCOLOSI MAIN ROAD  NGCOLOSI  ETHEKWINI",
    Latitude: "-29.6948",
    Longitude: "30.8112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9170",
    WardLookupId: "3254",
    VDNumber: "43580752",
    RegisteredPopulation: "2004",
    VotingStationName: "EMSENI  METHODIST CHURCH",
    Address: "120246 TWINS ROAD  HILLCREST  KWAGCWENSA AREA RIVER VIEW",
    Latitude: "-29.7323",
    Longitude: "30.79",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9171",
    WardLookupId: "3254",
    VDNumber: "43582899",
    RegisteredPopulation: "948",
    VotingStationName: "INHLANGANO SENIOR PRIMARY SCHOOL",
    Address: "TURN OFF FISCHER ROAD  HILLCREST  ETHEKWINI",
    Latitude: "-29.7405",
    Longitude: "30.783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9172",
    WardLookupId: "3254",
    VDNumber: "43582901",
    RegisteredPopulation: "1283",
    VotingStationName: "KHANYAKHWEZI PRIMARY SCHOOL",
    Address: "INANDA ROAD,EMTHOMBENI BUS STOP  NQETHO AREA  ETHEKWINI",
    Latitude: "-29.7326",
    Longitude: "30.8098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9173",
    WardLookupId: "3254",
    VDNumber: "43582912",
    RegisteredPopulation: "814",
    VotingStationName: "UMNGENI HIGHER PRIMARY SCHOOL",
    Address: "NGCOLOSI ROAD  NGCOLOSI  ETHEKWINI",
    Latitude: "-29.6874",
    Longitude: "30.85",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9174",
    WardLookupId: "3254",
    VDNumber: "43582934",
    RegisteredPopulation: "2884",
    VotingStationName: "KHABAZELA SECONDARY SCHOOL",
    Address: "BEHIND MISSION RD  EMBO  ETHEKWINI",
    Latitude: "-29.7524",
    Longitude: "30.7661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9175",
    WardLookupId: "3254",
    VDNumber: "43582945",
    RegisteredPopulation: "433",
    VotingStationName: "NGCOLOSI TRIBAL COURT",
    Address:
      "VIKA ROAD, KWANGCOLOSI TRIBAL   MSHAZI AREA, KWANGCOLOSI, HILLCREST 3652  EHTEKWINI",
    Latitude: "-29.6805",
    Longitude: "30.8036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9176",
    WardLookupId: "3254",
    VDNumber: "43582956",
    RegisteredPopulation: "343",
    VotingStationName: "PHOSHANE PRIMARY SCHOOL",
    Address: "EMBO MAIN ROAD  EMBO  ETHEKWINI",
    Latitude: "-29.7221",
    Longitude: "30.7753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9177",
    WardLookupId: "3254",
    VDNumber: "43583621",
    RegisteredPopulation: "1997",
    VotingStationName: "UMZAMO PRIMARY SCHOOL",
    Address: "ISIDANGA PLACE  NYUSWA RESERVED, BOTHA`S HILL 3660  ETHEKWINI",
    Latitude: "-29.7071",
    Longitude: "30.7546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9178",
    WardLookupId: "3254",
    VDNumber: "43583766",
    RegisteredPopulation: "834",
    VotingStationName: "MNYAMENI ZENEX SECONDARY SCHOOL",
    Address: "EZITHOLENI AREA  KWANYUSWA, BOTHA`S HILL 3660  ETHEKWINI",
    Latitude: "-29.6823",
    Longitude: "30.7742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9179",
    WardLookupId: "3254",
    VDNumber: "43584239",
    RegisteredPopulation: "618",
    VotingStationName: "EMBO CHRISTIAN FELLOWSHIP CHURCH",
    Address: "EMBO AREA  HILLCREST  ETHEKWINI [DURBAN METRO]",
    Latitude: "-29.7456",
    Longitude: "30.7665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9180",
    WardLookupId: "3254",
    VDNumber: "43584712",
    RegisteredPopulation: "397",
    VotingStationName: "SENZOKUHLE CRECHE",
    Address: "00 MR17  MKHELEKEHLE  KWANGCOLOSI",
    Latitude: "-29.7097",
    Longitude: "30.8084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9181",
    WardLookupId: "3255",
    VDNumber: "43400393",
    RegisteredPopulation: "2640",
    VotingStationName: "FOREST VIEW PRIMARY SCHOOL",
    Address: "RIDGE ROAD  FOREST HILLS  ETHEKWINI",
    Latitude: "-29.7562",
    Longitude: "30.8311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9182",
    WardLookupId: "3255",
    VDNumber: "43400405",
    RegisteredPopulation: "2843",
    VotingStationName: "KWADINABAKUBO SECONDARY SCHOOL",
    Address: "INANDA ROAD  MOLWENI  ETHEKWINI",
    Latitude: "-29.7387",
    Longitude: "30.8707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9183",
    WardLookupId: "3255",
    VDNumber: "43400797",
    RegisteredPopulation: "6652",
    VotingStationName: "WATERFALL LIBRARY",
    Address: "LINK ROAD  WATERFALL  ETHEKWINI",
    Latitude: "-29.7489",
    Longitude: "30.8182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9184",
    WardLookupId: "3255",
    VDNumber: "43400810",
    RegisteredPopulation: "1357",
    VotingStationName: "INGQUNGQULU HIGH SCHOOL",
    Address: "INANDA ROAD  MOLWENI  KWAMAGEZA AREA",
    Latitude: "-29.7368",
    Longitude: "30.8862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9185",
    WardLookupId: "3255",
    VDNumber: "43400854",
    RegisteredPopulation: "1257",
    VotingStationName: "LOWER MOLWENI MULTIPURPOSE CENTRE",
    Address: "LOWER MOLWENI  INANDA  ETHEKWINI",
    Latitude: "-29.7557",
    Longitude: "30.8802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9186",
    WardLookupId: "3255",
    VDNumber: "43400865",
    RegisteredPopulation: "2541",
    VotingStationName: "MOLWENI ZIMELE CENTRE",
    Address: "IKHWEZI ROAD  MOLWENI   ETHEKWINI",
    Latitude: "-29.7365",
    Longitude: "30.872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9187",
    WardLookupId: "3255",
    VDNumber: "43400933",
    RegisteredPopulation: "903",
    VotingStationName: "ST LEO PRIMARY SCHOOL",
    Address: "0  ROMAN ROAD  HILLCREST  MOLWENI EMAROMENI AREA",
    Latitude: "-29.7253",
    Longitude: "30.891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9188",
    WardLookupId: "3255",
    VDNumber: "43583171",
    RegisteredPopulation: "1253",
    VotingStationName: "LAMULA PRIMARY SCHOOL",
    Address: "UMGABABA RESERVE  MOLWENI  ETHEKWINI",
    Latitude: "-29.7437",
    Longitude: "30.8953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9189",
    WardLookupId: "3255",
    VDNumber: "43583777",
    RegisteredPopulation: "1570",
    VotingStationName: "WUSHWINI COMMUNITY HALL",
    Address:
      "M259 TRK 6804 MSHAZI ROAD  WUSHINI HILLCREST  NGCOLOSI TRIBAL AUTHORITY",
    Latitude: "-29.706",
    Longitude: "30.8503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9190",
    WardLookupId: "3255",
    VDNumber: "43584543",
    RegisteredPopulation: "693",
    VotingStationName: "INKANYEZI CRECHE",
    Address: "050121 SIKHWAMA ROAD  HILLCREST  MADIMENI AREA",
    Latitude: "-29.7163",
    Longitude: "30.8507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9191",
    WardLookupId: "3255",
    VDNumber: "43584587",
    RegisteredPopulation: "187",
    VotingStationName: "APOSTOLIC CHURCH",
    Address: "149 FITCHER ROAD  HILLCREST  ETHEKWINI",
    Latitude: "-29.7386",
    Longitude: "30.7992",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9192",
    WardLookupId: "3256",
    VDNumber: "43400045",
    RegisteredPopulation: "3328",
    VotingStationName: "KLOOF TOWN HALL",
    Address: "OLD MAIN ROAD  KLOOF  ETHEKWINI",
    Latitude: "-29.7911",
    Longitude: "30.8323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9193",
    WardLookupId: "3256",
    VDNumber: "43400067",
    RegisteredPopulation: "3654",
    VotingStationName: "GILLITS COMMUNITY HALL",
    Address: "OLD MAIN ROAD  GILLITTS  ETHEKWINI",
    Latitude: "-29.7965",
    Longitude: "30.7936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9194",
    WardLookupId: "3256",
    VDNumber: "43400438",
    RegisteredPopulation: "2809",
    VotingStationName: "KLOOF HIGH SCHOOL",
    Address: "34 EMOLWENI  KLOOF  3610",
    Latitude: "-29.7835",
    Longitude: "30.8373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9195",
    WardLookupId: "3256",
    VDNumber: "43400809",
    RegisteredPopulation: "1040",
    VotingStationName: "KLOOF TENNIS CLUB",
    Address: "KLOOFLANDS RD  KLOOF  ETHEKWINI",
    Latitude: "-29.7772",
    Longitude: "30.8438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9196",
    WardLookupId: "3256",
    VDNumber: "43400843",
    RegisteredPopulation: "2908",
    VotingStationName: "VISION CHURCH",
    Address: "8 SHIRLEY AVENUE  GILLITTS  ETHEKWINI [DURBAN METRO]",
    Latitude: "-29.7793",
    Longitude: "30.7991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9197",
    WardLookupId: "3256",
    VDNumber: "43400900",
    RegisteredPopulation: "1811",
    VotingStationName: "WINSTON PARK PRIMARY SCHOOL",
    Address: "JAN SMUTS  WINSTON PARK  WINSTON PARK",
    Latitude: "-29.7973",
    Longitude: "30.7815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9198",
    WardLookupId: "3256",
    VDNumber: "43400911",
    RegisteredPopulation: "1078",
    VotingStationName: "KLOOF METHODIST CHURCH",
    Address: "4 PARK CLOSE  KLOOF  PINETOWN",
    Latitude: "-29.7957",
    Longitude: "30.8358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9199",
    WardLookupId: "3257",
    VDNumber: "43360523",
    RegisteredPopulation: "1825",
    VotingStationName: "BRIARDALE PRIMARY SCHOOL",
    Address: "12 PALMDALE PLACE  NEWLANDS WEST  ETHEKWINI",
    Latitude: "-29.7801",
    Longitude: "30.9647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9200",
    WardLookupId: "3257",
    VDNumber: "43360578",
    RegisteredPopulation: "3338",
    VotingStationName: "PARKVALE PRIMARY SCHOOL",
    Address:
      "27 WANDALE PLACE NORDALE ROAD  CASTLEHILL, NEWLANDS WEST  ETHEKWINI",
    Latitude: "-29.7693",
    Longitude: "30.9602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9201",
    WardLookupId: "3257",
    VDNumber: "43360589",
    RegisteredPopulation: "2771",
    VotingStationName: "FERNDALE PRIMARY SCHOOL",
    Address: "9 GALJOEN ROAD  NEWLANDS EAST  ETHEKWINI",
    Latitude: "-29.7781",
    Longitude: "30.9779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9202",
    WardLookupId: "3257",
    VDNumber: "43360590",
    RegisteredPopulation: "1452",
    VotingStationName: "HILLVIEW SENIOR SECONDARY SCHOOL",
    Address: "2 SEAMASS ROAD  NEWLANDS EAST  ETHEKWINI",
    Latitude: "-29.7713",
    Longitude: "30.9822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9203",
    WardLookupId: "3257",
    VDNumber: "43360602",
    RegisteredPopulation: "2581",
    VotingStationName: "ROSELAND PRIMARY SCHOOL",
    Address: "2 GARRUPA CRESCENT  NEWLANDS EAST  ETHEKWINI",
    Latitude: "-29.763",
    Longitude: "30.9702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9204",
    WardLookupId: "3257",
    VDNumber: "43360613",
    RegisteredPopulation: "2208",
    VotingStationName: "BARRACUDA PRIMARY SCHOOL",
    Address: "3 BARRACUDA ROAD  NEWLANDS EAST  ETHEKWINI",
    Latitude: "-29.7705",
    Longitude: "30.9862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9205",
    WardLookupId: "3257",
    VDNumber: "43362042",
    RegisteredPopulation: "4275",
    VotingStationName: "QUARRY HEIGHTS PRIMARY SCHOOL",
    Address: "11 ISILO ROAD  QUARRY HEIGHTS  NEWLANDS EAST",
    Latitude: "-29.7659",
    Longitude: "30.9918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9206",
    WardLookupId: "3258",
    VDNumber: "43390100",
    RegisteredPopulation: "1465",
    VotingStationName: "BHONGO PRIMARY SCHOOL",
    Address: "699 THENDELE ROAD  PITOLI SECTION, KWANDENGEZI   ETHEKWINI",
    Latitude: "-29.847",
    Longitude: "30.7731",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9207",
    WardLookupId: "3258",
    VDNumber: "43390223",
    RegisteredPopulation: "2950",
    VotingStationName: "NDENGEZI INTERMEDIATE SCHOOL",
    Address: "MLUNGISI ROAD  KWANDENGEZI  ETHEKWINI",
    Latitude: "-29.8647",
    Longitude: "30.7623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9208",
    WardLookupId: "3258",
    VDNumber: "43390391",
    RegisteredPopulation: "4647",
    VotingStationName: "BOTATE PRIMARY SCHOOL",
    Address: "5647 TOM-TOM RD  KWANDENGEZI  ETHEKWINI",
    Latitude: "-29.8563",
    Longitude: "30.7705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9209",
    WardLookupId: "3258",
    VDNumber: "43390414",
    RegisteredPopulation: "2259",
    VotingStationName: "MBALENHLE HALL",
    Address: "1 THUKANI STREET  COFFEE FARM  ETHEKWINI",
    Latitude: "-29.8633",
    Longitude: "30.7821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9210",
    WardLookupId: "3258",
    VDNumber: "43390728",
    RegisteredPopulation: "1734",
    VotingStationName: "NKOSENYE INTERMEDIATE SCHOOL",
    Address: "121 MZWILILI CRESCENT  EPITOLI SECTION, KWANDENGEZI  ETHEKWINI",
    Latitude: "-29.8419",
    Longitude: "30.7722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9211",
    WardLookupId: "3258",
    VDNumber: "43390830",
    RegisteredPopulation: "1717",
    VotingStationName: "KWALINDA COMMUNITY HALL",
    Address: "921 MR518, KWANDENGEZI   KWANDENGEZI  PINETOWN",
    Latitude: "-29.8408",
    Longitude: "30.7689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9212",
    WardLookupId: "3258",
    VDNumber: "43391033",
    RegisteredPopulation: "2484",
    VotingStationName: "IMBALIYEZWE CRECHE",
    Address: "TOM-TOM RF/MTOLO RD  KWA NDENGEZI  ETHEKWINI [DURBAN METRO]",
    Latitude: "-29.8549",
    Longitude: "30.766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9213",
    WardLookupId: "3259",
    VDNumber: "43390380",
    RegisteredPopulation: "1835",
    VotingStationName: "BIRCHWOOD PARK COMMUNITY HALL",
    Address: "39 SALVIA PLACE  NAGINA  ETHEKWINI",
    Latitude: "-29.8772",
    Longitude: "30.7915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9214",
    WardLookupId: "3259",
    VDNumber: "43390548",
    RegisteredPopulation: "3944",
    VotingStationName: "THEMBALIHLE PRIMARY SCHOOL",
    Address: "5  ZWELETHU ROAD  LUGANDA  PINETOWN",
    Latitude: "-29.8877",
    Longitude: "30.8053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9215",
    WardLookupId: "3259",
    VDNumber: "43390593",
    RegisteredPopulation: "1438",
    VotingStationName: "MARIANRIDGE COMMUNITY HALL",
    Address: "CORNER CENTRAL DRIVE & HILES P  MARIANRIDGE  ETHEKWINI",
    Latitude: "-29.8597",
    Longitude: "30.8247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9216",
    WardLookupId: "3259",
    VDNumber: "43390650",
    RegisteredPopulation: "4951",
    VotingStationName: "DASSENHOEK COMMUNITY HALL",
    Address: "8 RECREATION ROAD  DASSENHOEK  ETHEKWINI",
    Latitude: "-29.8734",
    Longitude: "30.8034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9217",
    WardLookupId: "3259",
    VDNumber: "43390931",
    RegisteredPopulation: "2069",
    VotingStationName: "MARIANNRIDGE PRIMARY SCHOOL",
    Address: "389 MERCURY CRESCENT  MARIANNRIDGE  ETHEKWINI",
    Latitude: "-29.8662",
    Longitude: "30.8225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9218",
    WardLookupId: "3259",
    VDNumber: "43390986",
    RegisteredPopulation: "1623",
    VotingStationName: "MARIANNHILL PRIMARY",
    Address: "1 MAMAYA ROAD  MARIANNHILL  PINETOWN",
    Latitude: "-29.8737",
    Longitude: "30.8165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9219",
    WardLookupId: "3260",
    VDNumber: "43390436",
    RegisteredPopulation: "3003",
    VotingStationName: "DASSENHOEK SECONDARY SCHOOL",
    Address: "DASSENHOEK STATION ROAD  DASSENHOEK  ETHEKWINI",
    Latitude: "-29.8436",
    Longitude: "30.7881",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9220",
    WardLookupId: "3260",
    VDNumber: "43390470",
    RegisteredPopulation: "2155",
    VotingStationName: "MANSENSENI COMMUNITY HALL",
    Address: "DASSENHOEK BLOCK A  DASSENHOEK  ETHEKWINI",
    Latitude: "-29.8398",
    Longitude: "30.7828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9221",
    WardLookupId: "3260",
    VDNumber: "43390481",
    RegisteredPopulation: "3142",
    VotingStationName: "BHEKOKUHLE PRIMARY SCHOOL",
    Address: "SHONGWENI ROAD  DASSENHOEK  ETHEKWINI",
    Latitude: "-29.8533",
    Longitude: "30.7977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9222",
    WardLookupId: "3260",
    VDNumber: "43390683",
    RegisteredPopulation: "2967",
    VotingStationName: "SINQOBILE RESOURCE CENTRE",
    Address: "13875 OAKLANDS ROAD  DASSENHOEK,PINETOWN  ETHEKWINI",
    Latitude: "-29.867",
    Longitude: "30.7909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9223",
    WardLookupId: "3260",
    VDNumber: "43390694",
    RegisteredPopulation: "2590",
    VotingStationName: "THORNWOOD COMMUNITY HALL",
    Address: "113 THORNWOOD ROAD  NAGINA  ETHEKWINI",
    Latitude: "-29.8565",
    Longitude: "30.8039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9224",
    WardLookupId: "3260",
    VDNumber: "43390706",
    RegisteredPopulation: "2717",
    VotingStationName: "SITHUNDU HILLS  COMMUNITY HALL",
    Address: "SITHUNDU HILLS ROAD  NAGINA  ETHEKWINI",
    Latitude: "-29.8571",
    Longitude: "30.7925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9225",
    WardLookupId: "3260",
    VDNumber: "43391099",
    RegisteredPopulation: "1929",
    VotingStationName: "MARIANNPARK PRIMARY SCHOOL",
    Address: "01 THORNWOOD ROAD  MARIANNHILL  PINETOWN",
    Latitude: "-29.8685",
    Longitude: "30.8013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9226",
    WardLookupId: "3260",
    VDNumber: "43391101",
    RegisteredPopulation: "1064",
    VotingStationName: "DELANI PRIMARY SCHOOL",
    Address: "7TH STREET THORNWOOD  PINETOWN",
    Latitude: "-29.8621",
    Longitude: "30.8055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9227",
    WardLookupId: "3261",
    VDNumber: "43390469",
    RegisteredPopulation: "3410",
    VotingStationName: "KWACUTSHWAYO PRIMARY SCHOOL",
    Address: "SHONGWENI ROAD  DASSENHOEK  ETHEKWINI",
    Latitude: "-29.8413",
    Longitude: "30.7952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9228",
    WardLookupId: "3261",
    VDNumber: "43390537",
    RegisteredPopulation: "4403",
    VotingStationName: "TSHELI MNYAMA COMMUNITY HALL",
    Address: "118 UMHLATHUZANA ROAD  TSHELIMNYAMA  PINETOWN",
    Latitude: "-29.8381",
    Longitude: "30.815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9229",
    WardLookupId: "3261",
    VDNumber: "43390672",
    RegisteredPopulation: "5294",
    VotingStationName: "MPOLA COMMUNITY HALL",
    Address: "MPOLA ROAD  PINETOWN  ETHEKWINI",
    Latitude: "-29.8537",
    Longitude: "30.8095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9230",
    WardLookupId: "3261",
    VDNumber: "43390739",
    RegisteredPopulation: "408",
    VotingStationName: "JUBILEE SENIOR PRIMARY SCHOOL",
    Address: "98 ABBOT FRANCIS ROAD  MARIANHILL  ETHEKWNI",
    Latitude: "-29.8468",
    Longitude: "30.826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9231",
    WardLookupId: "3261",
    VDNumber: "43390920",
    RegisteredPopulation: "1720",
    VotingStationName: "MOTALA HEIGHTS LIBRARY",
    Address: "LOT88 MOTALA ROAD  PINETOWN  ETHEKWINI",
    Latitude: "-29.811",
    Longitude: "30.8319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9232",
    WardLookupId: "3261",
    VDNumber: "43390975",
    RegisteredPopulation: "3503",
    VotingStationName: "TSHELIMNYAMA PRIMARY SCHOOL",
    Address: "MHLATHUZANA ROAD  TESHELIMNYAMA  PINETOWN",
    Latitude: "-29.8363",
    Longitude: "30.8129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9233",
    WardLookupId: "3261",
    VDNumber: "43391022",
    RegisteredPopulation: "1116",
    VotingStationName: "APOSTOLIC CHURCH",
    Address: "E 21990 ROAD NO A 47629  TSHELIMYAMA  ETHEKWINI",
    Latitude: "-29.8459",
    Longitude: "30.8022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9234",
    WardLookupId: "3262",
    VDNumber: "43390560",
    RegisteredPopulation: "2896",
    VotingStationName: "GELOFTE HIGH SCHOOL",
    Address: "2 CAVERSHAM ROAD  PINETOWN  ETHEKWINI",
    Latitude: "-29.8216",
    Longitude: "30.8576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9235",
    WardLookupId: "3262",
    VDNumber: "43390605",
    RegisteredPopulation: "3607",
    VotingStationName: "NAZARETH PRIMARY",
    Address: "735 DLAMINI ROAD  PINETOWN  UMHLATUZANA",
    Latitude: "-29.8474",
    Longitude: "30.8556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9236",
    WardLookupId: "3262",
    VDNumber: "43390717",
    RegisteredPopulation: "2864",
    VotingStationName: "NONOPHA SENIOR PRIMARY SCHOOL",
    Address: "397 ST WENDOLINS ROAD  ST WENDOLINS  ETHEKWINI",
    Latitude: "-29.868",
    Longitude: "30.8584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9237",
    WardLookupId: "3262",
    VDNumber: "43390795",
    RegisteredPopulation: "3649",
    VotingStationName: "ASHLEY PRIMARY SCHOOL",
    Address: "08 05TH AVENUE ASHLEY  PINETOWN  ETHEKWINI",
    Latitude: "-29.8273",
    Longitude: "30.8455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9238",
    WardLookupId: "3262",
    VDNumber: "43390942",
    RegisteredPopulation: "3854",
    VotingStationName: "INQALABUTHO COMMUNITY HALL",
    Address: "ST WENDOLINS ROAD, ST WENDOLINS RIDGE  ST WENDOLINS  ETHEKWINI",
    Latitude: "-29.8714",
    Longitude: "30.8462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9239",
    WardLookupId: "3262",
    VDNumber: "43390964",
    RegisteredPopulation: "687",
    VotingStationName: "PINELANDS TENNIS CLUB",
    Address: "COLLINS ROAD  PINELANDS  PINETOWN",
    Latitude: "-29.8316",
    Longitude: "30.8543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9240",
    WardLookupId: "3262",
    VDNumber: "43391077",
    RegisteredPopulation: "2305",
    VotingStationName: "WESTMEAD SEC SCHOOL",
    Address: "274 RUDOLF ROAD  SOUTHCLIFF  PINETOWN",
    Latitude: "-29.858",
    Longitude: "30.8497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9241",
    WardLookupId: "3263",
    VDNumber: "43370456",
    RegisteredPopulation: "1738",
    VotingStationName: "CROSSMEAD PRIMARY SCHOOL",
    Address: "GOLDEN POPPY CRESCENT  CROSSMOOR, CHATSWORTH  ETHEKWINI",
    Latitude: "-29.8935",
    Longitude: "30.8581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9242",
    WardLookupId: "3263",
    VDNumber: "43390616",
    RegisteredPopulation: "3196",
    VotingStationName: "GLENRIDGE PRIMARY SCHOOL",
    Address: "1 WINGEN WALK  SHALLCROSS  ETHEKWINI",
    Latitude: "-29.8804",
    Longitude: "30.8661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9243",
    WardLookupId: "3263",
    VDNumber: "43390627",
    RegisteredPopulation: "3327",
    VotingStationName: "MZAMO CLINIC",
    Address: "10859 ST. WENDOLINS RIDGE STREET  PINETOWN  ETHEKWINI",
    Latitude: "-29.8724",
    Longitude: "30.8296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9244",
    WardLookupId: "3263",
    VDNumber: "43390649",
    RegisteredPopulation: "4142",
    VotingStationName: "KLAARWATER COMMUNITY HALL",
    Address: "CNR MFEKA & NDWANDWE ROADS  KLAARWATER  ETHEKWINI",
    Latitude: "-29.8828",
    Longitude: "30.8298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9245",
    WardLookupId: "3263",
    VDNumber: "43390751",
    RegisteredPopulation: "2684",
    VotingStationName: "SAVANNAH DAYCARE CENTRE",
    Address: "269 MPANZA  ROAD  SAVANNAH PARK  ETHEKWINI",
    Latitude: "-29.8786",
    Longitude: "30.8466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9246",
    WardLookupId: "3263",
    VDNumber: "43390762",
    RegisteredPopulation: "2840",
    VotingStationName: "SAVANNAH PARK SECONDARY SCHOOL",
    Address: "PEBBLE DRIVE  SAVANNAH PARK  ETHEKWINI",
    Latitude: "-29.8828",
    Longitude: "30.8432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9247",
    WardLookupId: "3263",
    VDNumber: "43391000",
    RegisteredPopulation: "649",
    VotingStationName: "SHALLCROSS SECONDARY SCHOOL",
    Address: "16 ALPINE DRIVE  SHALLCROSS  ETHEKWINI",
    Latitude: "-29.8824",
    Longitude: "30.8768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9248",
    WardLookupId: "3264",
    VDNumber: "43390212",
    RegisteredPopulation: "2408",
    VotingStationName: "ATHOLL HEIGHTS PRIMARY SCHOOL",
    Address: "52 METHVEN ROAD  WESTVILLE NORTH  ETHEKWINI",
    Latitude: "-29.8165",
    Longitude: "30.9022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9249",
    WardLookupId: "3264",
    VDNumber: "43390515",
    RegisteredPopulation: "6117",
    VotingStationName: "PINETOWN CIVIC CENTRE MAIN HALL",
    Address: "60 KINGS ROAD  PINETOWN  ETHEKWINI",
    Latitude: "-29.8144",
    Longitude: "30.8629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9250",
    WardLookupId: "3264",
    VDNumber: "43390526",
    RegisteredPopulation: "4381",
    VotingStationName: "COWIES HILL COMMUNITY HALL",
    Address: "5 WOODSIDE AVENUE  COWIES  HILL  ETHEKWINI",
    Latitude: "-29.8276",
    Longitude: "30.8952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9251",
    WardLookupId: "3264",
    VDNumber: "43390571",
    RegisteredPopulation: "4694",
    VotingStationName: "BENJAMIN PINE PRIMARY SCHOOL",
    Address: "11 BLENHEIM ROAD  PINETOWN  PINETOWN",
    Latitude: "-29.8334",
    Longitude: "30.8603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9252",
    WardLookupId: "3264",
    VDNumber: "43390784",
    RegisteredPopulation: "1966",
    VotingStationName: "AVON JUNIOR PRIMARY SCHOOL",
    Address: "5 AVON DRIVE  WESTVILLE, 3630  ETHEKWINI",
    Latitude: "-29.8242",
    Longitude: "30.9137",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9253",
    WardLookupId: "3265",
    VDNumber: "43390403",
    RegisteredPopulation: "4225",
    VotingStationName: "KWADABEKA SUB 5 HALL",
    Address: "SUB 5 COMMUNITY HALL LOT T1001, KWA DABEKA  KWADABEKA  ETHEKWINI",
    Latitude: "-29.7769",
    Longitude: "30.895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9254",
    WardLookupId: "3265",
    VDNumber: "43390447",
    RegisteredPopulation: "2354",
    VotingStationName: "SIPHUMELELE CRECHE",
    Address: "R 321  KWADABEKA  ETHEKWINI",
    Latitude: "-29.7734",
    Longitude: "30.8918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9255",
    WardLookupId: "3265",
    VDNumber: "43390908",
    RegisteredPopulation: "1844",
    VotingStationName: "HAPPY KHUMALO COMMUNITY HALL",
    Address: "H33 SUB 5  KWADABEKA  PINETOWN",
    Latitude: "-29.7679",
    Longitude: "30.9018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9256",
    WardLookupId: "3265",
    VDNumber: "43390919",
    RegisteredPopulation: "1258",
    VotingStationName: "KRANSKLOOF NO. 2 PRIMARY SCHOOL (DABEKA NO.7)",
    Address: "J98 KWADABEKA  ETHEKWINI",
    Latitude: "-29.7699",
    Longitude: "30.9093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9257",
    WardLookupId: "3265",
    VDNumber: "43391088",
    RegisteredPopulation: "1989",
    VotingStationName: "MZWAMANDLA KHANYILE PRIMARY SCHOOL",
    Address: "689-690 SIZWE MDLALOSE ROAD  CLERMONT  KWADABEKA",
    Latitude: "-29.7594",
    Longitude: "30.904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9258",
    WardLookupId: "3265",
    VDNumber: "43400034",
    RegisteredPopulation: "4981",
    VotingStationName: "WYEBANK SECONDARY SCHOOL",
    Address: "158 WYEBANK ROAD  WYEBANK  ETHEKWINI",
    Latitude: "-29.7712",
    Longitude: "30.8716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9259",
    WardLookupId: "3265",
    VDNumber: "43400832",
    RegisteredPopulation: "3023",
    VotingStationName: "WYEBANK EDUCARE CENTRE",
    Address: "2 HILLTOP ROAD  WYEBANK  ETHEKWINI",
    Latitude: "-29.7695",
    Longitude: "30.8829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9260",
    WardLookupId: "3265",
    VDNumber: "43400922",
    RegisteredPopulation: "723",
    VotingStationName: "WYEBANK COMMUNITY HALL",
    Address: "9 SAILOR ROAD  WYEBANK  PINETOWN",
    Latitude: "-29.7832",
    Longitude: "30.8553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9261",
    WardLookupId: "3266",
    VDNumber: "43390245",
    RegisteredPopulation: "3098",
    VotingStationName: "MCOPHELELI HIGHER PRIMARY SCHOOL",
    Address: "A449 KWADABEKA TOWNSHIP  KWADABEKA  ETHEKWINI",
    Latitude: "-29.7766",
    Longitude: "30.9062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9262",
    WardLookupId: "3266",
    VDNumber: "43390425",
    RegisteredPopulation: "1749",
    VotingStationName: "SITHOKOZILE SECONDARY SCHOOL",
    Address: "UNIT C CLERNAVILLE  KWADABEKA  ETHEKWINI",
    Latitude: "-29.7714",
    Longitude: "30.9095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9263",
    WardLookupId: "3266",
    VDNumber: "43390458",
    RegisteredPopulation: "3664",
    VotingStationName: "BUHLEBEMFUNDO SECONDARY SCHOOL",
    Address: "E1015 KWADABEKA  ETHEKWINI",
    Latitude: "-29.7614",
    Longitude: "30.9233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9264",
    WardLookupId: "3266",
    VDNumber: "43390807",
    RegisteredPopulation: "3056",
    VotingStationName: "K K COMMUNITY HALL D SECTION",
    Address: "D SECTION KWA DABEKA  KRANSKLOOF  ETHEKWINI",
    Latitude: "-29.7808",
    Longitude: "30.9017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9265",
    WardLookupId: "3266",
    VDNumber: "43390818",
    RegisteredPopulation: "2919",
    VotingStationName: "KWADABEKA LIBRARY FUNCTIONS ROOM",
    Address:
      "NEXT TO K K HOSTEL J SECTION OPP 2 ROOM HOUSES  KWADABEKA  ETHEKWINI",
    Latitude: "-29.78",
    Longitude: "30.8989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9266",
    WardLookupId: "3266",
    VDNumber: "43391011",
    RegisteredPopulation: "1151",
    VotingStationName: "KWA DABEKA CLINIC",
    Address: "KWA DABEKA CLINIC  KWA DABEKA  ETHEKWINI",
    Latitude: "-29.7772",
    Longitude: "30.8977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9267",
    WardLookupId: "3266",
    VDNumber: "43391123",
    RegisteredPopulation: "954",
    VotingStationName: "LETHUKUKHANYA CRISIS AND DEVELOPMENT CENTRE",
    Address: "UMNGENI WATER SEWERAGE  KWADABEKA  PINETOWN",
    Latitude: "-29.7644",
    Longitude: "30.9282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9268",
    WardLookupId: "3267",
    VDNumber: "43390289",
    RegisteredPopulation: "2580",
    VotingStationName: "CATHOLIC CHURCH HALL",
    Address: "892 17TH AVENUE,CLERMONT(ST CLEMENT PARISH)  CLERMONT  ETHEKWINI",
    Latitude: "-29.7915",
    Longitude: "30.8904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9269",
    WardLookupId: "3267",
    VDNumber: "43390290",
    RegisteredPopulation: "3417",
    VotingStationName: "CLERMONT COMMUNITY HALL",
    Address: "1106 12TH STREET  CLERMONT  ETHEKWINI",
    Latitude: "-29.7957",
    Longitude: "30.8935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9270",
    WardLookupId: "3267",
    VDNumber: "43390302",
    RegisteredPopulation: "2284",
    VotingStationName: "METHODIST CHURCH J C MVUSI HALL",
    Address: "1167 11TH STREET  CLERMONT  ETHEKWINI",
    Latitude: "-29.7905",
    Longitude: "30.8932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9271",
    WardLookupId: "3267",
    VDNumber: "43390492",
    RegisteredPopulation: "2871",
    VotingStationName: "LYNDHURST PRIMARY SCHOOL",
    Address: "2 THOLE STREET  NEW GERMANY  ETHEKWINI",
    Latitude: "-29.782",
    Longitude: "30.8746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9272",
    WardLookupId: "3267",
    VDNumber: "43390504",
    RegisteredPopulation: "4210",
    VotingStationName: "NEW GERMANY TOWN HALL",
    Address: "HARVEY ROAD  NEW GERMANY  ETHEKWINI",
    Latitude: "-29.7998",
    Longitude: "30.8701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9273",
    WardLookupId: "3267",
    VDNumber: "43390997",
    RegisteredPopulation: "2662",
    VotingStationName: "SOLOMON MAHLANGU  HALL",
    Address: "NEW GERMANY EXTENSION(BUFFER ZONE)  NEW GERMANY  ETHEKWINI",
    Latitude: "-29.7916",
    Longitude: "30.8851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9274",
    WardLookupId: "3268",
    VDNumber: "43390234",
    RegisteredPopulation: "1614",
    VotingStationName: "EKUJABULENI PRIMARY SCHOOL",
    Address: "A1039 KWADABEKA  ETHEKWINI",
    Latitude: "-29.7831",
    Longitude: "30.9066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9275",
    WardLookupId: "3268",
    VDNumber: "43390256",
    RegisteredPopulation: "3326",
    VotingStationName: "SITHENGILE SECONDARY SCHOOL",
    Address: "868 4TH AVENUE  CLERMONT  ETHEKWINI",
    Latitude: "-29.7863",
    Longitude: "30.8956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9276",
    WardLookupId: "3268",
    VDNumber: "43390267",
    RegisteredPopulation: "4913",
    VotingStationName: "SHEMBE COMMUNITY HALL",
    Address: "2788 18TH AVENUE  CLERNAVILLE  ETHEKWINI",
    Latitude: "-29.7883",
    Longitude: "30.9041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9277",
    WardLookupId: "3268",
    VDNumber: "43390278",
    RegisteredPopulation: "2693",
    VotingStationName: "UMVUZANE NO 7 HALL",
    Address: "ST AVENUE  CLERMONT  ETHEKWINI",
    Latitude: "-29.7781",
    Longitude: "30.8914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9278",
    WardLookupId: "3268",
    VDNumber: "43390313",
    RegisteredPopulation: "2288",
    VotingStationName: "FENNIN COMMUNITY HALL",
    Address: "12 THIRD FIFTY AVENUE  CLERMONT  ETHEKWINI",
    Latitude: "-29.7928",
    Longitude: "30.9042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9279",
    WardLookupId: "3268",
    VDNumber: "43390874",
    RegisteredPopulation: "3402",
    VotingStationName: "ZIPHATHELE HIGH SCHOOL",
    Address: "3017 KINGS ROAD  CLERMONT  ETHEKWINI",
    Latitude: "-29.7895",
    Longitude: "30.8996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9280",
    WardLookupId: "3269",
    VDNumber: "43360354",
    RegisteredPopulation: "1322",
    VotingStationName: "CLARE HILLS BASKET BALL  (TENT)",
    Address: "131 SPENCER RD  CLARE ESTATE  ETHEKWINI",
    Latitude: "-29.8124",
    Longitude: "30.9649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9281",
    WardLookupId: "3269",
    VDNumber: "43360365",
    RegisteredPopulation: "1886",
    VotingStationName: "PALMIET PRIMARY SCHOOL",
    Address: "725 CLARE ESTATE ROAD  CLARE ESTATE  ETHEKWINI",
    Latitude: "-29.8076",
    Longitude: "30.9723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9282",
    WardLookupId: "3269",
    VDNumber: "43362031",
    RegisteredPopulation: "2831",
    VotingStationName: "TEMPORARY VOTING STATION (BOLTON ROAD PARK) TENT",
    Address: "BOLTON RD. PARK  BOLTON  ETHEKWINI",
    Latitude: "-29.8183",
    Longitude: "30.9674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9283",
    WardLookupId: "3269",
    VDNumber: "43390010",
    RegisteredPopulation: "2629",
    VotingStationName: "DR A D LAZARUS SECONDARY SCHOOL",
    Address: "01 NEW GERMANY ROAD  RESERVOIR HILLS  ETHEKWINI",
    Latitude: "-29.7997",
    Longitude: "30.9631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9284",
    WardLookupId: "3269",
    VDNumber: "43390021",
    RegisteredPopulation: "2341",
    VotingStationName: "RESMONT PRIMARY SCHOOL",
    Address: "2 MAGDELAN AVENUE  RESERVOIR HILLS  ETHEKWINI",
    Latitude: "-29.8038",
    Longitude: "30.953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9285",
    WardLookupId: "3269",
    VDNumber: "43390032",
    RegisteredPopulation: "4117",
    VotingStationName: "HILLVIEW PRIMARY SCHOOL",
    Address: "271 VARSITY DRIVE  RESERVOIR HILLS  ETHEKWINI",
    Latitude: "-29.8132",
    Longitude: "30.9535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9286",
    WardLookupId: "3269",
    VDNumber: "43390043",
    RegisteredPopulation: "3458",
    VotingStationName: "RESERVOIR HILLS SECONDARY SCHOOL",
    Address: "25 SHANNON DRIVE  RESERVOIR HILLS  ETHEKWINI",
    Latitude: "-29.796",
    Longitude: "30.9385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9287",
    WardLookupId: "3269",
    VDNumber: "43390054",
    RegisteredPopulation: "2087",
    VotingStationName: "PEMARY RIDGE PRIMARY SCHOOL",
    Address: "4 PEMARY RIDGE ROAD  RESERVOIR HILLS  ETHEKWINI",
    Latitude: "-29.7892",
    Longitude: "30.9395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9288",
    WardLookupId: "3270",
    VDNumber: "43370388",
    RegisteredPopulation: "4225",
    VotingStationName: "BAPTIST CHURCH HALL",
    Address: "75 MAHLATHI ROAD  CHESTERVILLE  DURBAN",
    Latitude: "-29.8496",
    Longitude: "30.9434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9289",
    WardLookupId: "3270",
    VDNumber: "43370502",
    RegisteredPopulation: "3238",
    VotingStationName: "CHESTERVILLE COMMUNITY HALL",
    Address: "423 CNR RD.  CHESTERVILLE  ETHEKWINI",
    Latitude: "-29.8498",
    Longitude: "30.9462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9290",
    WardLookupId: "3270",
    VDNumber: "43371806",
    RegisteredPopulation: "3247",
    VotingStationName: "H P NGWENYA PRE - PRIMARY SCHOOL",
    Address: "62 DENIS SHEPSTONE ROAD  CHESTERVILLE EXT1, DURBAN  ETHEKWINI",
    Latitude: "-29.8609",
    Longitude: "30.9483",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9291",
    WardLookupId: "3270",
    VDNumber: "43372100",
    RegisteredPopulation: "1063",
    VotingStationName: "CHESTERVILLE EXTENSION HALL",
    Address: "HARRY GWALA  CHESTERVILLE  ETHEKWINI",
    Latitude: "-29.859",
    Longitude: "30.9413",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9292",
    WardLookupId: "3270",
    VDNumber: "43390087",
    RegisteredPopulation: "4647",
    VotingStationName: "WESTVILLE TOWN HALL",
    Address: "1 WILLIAM LESTER DRIVE  WESTVILLE  ETHEKWINI",
    Latitude: "-29.8369",
    Longitude: "30.9226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9293",
    WardLookupId: "3270",
    VDNumber: "43390201",
    RegisteredPopulation: "3140",
    VotingStationName: "WESTVILLE ATHLETICS CLUB",
    Address:
      "MARYVALE ROAD (CNR MARYVALE AND SYRINGA ROAD)  WESTVILLE  ETHEKWINI",
    Latitude: "-29.8544",
    Longitude: "30.9105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9294",
    WardLookupId: "3271",
    VDNumber: "43360219",
    RegisteredPopulation: "2091",
    VotingStationName: "THEKWINI COLLEGE - CENTEC CAMPUS",
    Address: "75 EARL HAIG ROAD  ROSEGLEN  ETHEKWINI",
    Latitude: "-29.824",
    Longitude: "31.0047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9295",
    WardLookupId: "3271",
    VDNumber: "43360220",
    RegisteredPopulation: "4087",
    VotingStationName: "SPRINGFIELD HINDU SCHOOL",
    Address: "11 ALPINE ROAD  SPRINGFIELD  ETHEKWINI",
    Latitude: "-29.8147",
    Longitude: "31.0012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9296",
    WardLookupId: "3271",
    VDNumber: "43360231",
    RegisteredPopulation: "1801",
    VotingStationName: "NAGARI PRACHARNI SABHA HALL",
    Address: "30 MUSCARI ROAD  SPRINGFIELD  ETHEKWINI",
    Latitude: "-29.819",
    Longitude: "30.9963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9297",
    WardLookupId: "3271",
    VDNumber: "43360242",
    RegisteredPopulation: "2560",
    VotingStationName: "SRS PRIMARY SCHOOL",
    Address: "330 WEST ROAD  OVERPORT  ETHEKWINI",
    Latitude: "-29.8285",
    Longitude: "30.9987",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9298",
    WardLookupId: "3271",
    VDNumber: "43360286",
    RegisteredPopulation: "1151",
    VotingStationName: "COLLEGEVALE PRIMARY SCHOOL",
    Address: "14 COLLEGE ROAD  OVERPORT  ETHEKWINI",
    Latitude: "-29.8333",
    Longitude: "30.99",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9299",
    WardLookupId: "3271",
    VDNumber: "43360321",
    RegisteredPopulation: "3283",
    VotingStationName: "SYDENHAM PRIMARY SCHOOL",
    Address: "58 CLAIRE ROAD  SYDENHAM  ETHEKWINI",
    Latitude: "-29.8246",
    Longitude: "30.9815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9300",
    WardLookupId: "3271",
    VDNumber: "43360332",
    RegisteredPopulation: "3147",
    VotingStationName: "THEKWINI COLLEGE - SPRINGFIELD CAMPUS",
    Address: "CORNER BISASAR AND UMGENI ROAD  CLARE ESTATE  ETHEKWINI",
    Latitude: "-29.8096",
    Longitude: "30.983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9301",
    WardLookupId: "3271",
    VDNumber: "43360343",
    RegisteredPopulation: "1194",
    VotingStationName: "S M JHAVARY PRIMARY SCHOOL",
    Address: "533 CLARE ROAD  CLARE ESTATE  ETHEKWINI",
    Latitude: "-29.8132",
    Longitude: "30.9703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9302",
    WardLookupId: "3271",
    VDNumber: "43362020",
    RegisteredPopulation: "1748",
    VotingStationName: "CLAREVILLE PRIMARY SCHOOL",
    Address: "133 HARTLEY ROAD  OVERPORT  ETHEKWINI",
    Latitude: "-29.8197",
    Longitude: "30.9705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9303",
    WardLookupId: "3272",
    VDNumber: "43360017",
    RegisteredPopulation: "3299",
    VotingStationName: "ADDINGTON PRIMARY SCHOOL",
    Address: "43 BELL STREET  POINT  ETHEKWINI",
    Latitude: "-29.8681",
    Longitude: "31.0433",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9304",
    WardLookupId: "3272",
    VDNumber: "43360028",
    RegisteredPopulation: "3267",
    VotingStationName: "ADDINGTON HOSPITAL",
    Address: "HOSPITAL RD.  SOUTH BEACH  ETHEKWINI",
    Latitude: "-29.8628",
    Longitude: "31.0418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9305",
    WardLookupId: "3272",
    VDNumber: "43360039",
    RegisteredPopulation: "1594",
    VotingStationName: "75 WINDER STREET GROUND FLOOR",
    Address: "75 WINDER STREET  DURBAN  ETHEKWINI",
    Latitude: "-29.8604",
    Longitude: "31.0341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9306",
    WardLookupId: "3272",
    VDNumber: "43360040",
    RegisteredPopulation: "3354",
    VotingStationName: "TENT AT VICTORA PARK (NORTH BEACH)",
    Address: "BAUMANN ROAD  NORTH BEACH  DURBAN CENTRAL",
    Latitude: "-29.851",
    Longitude: "31.0358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9307",
    WardLookupId: "3272",
    VDNumber: "43360062",
    RegisteredPopulation: "3589",
    VotingStationName: "GEORGE CAMPBELL TECHNICAL HIGH SCHOOL",
    Address: "261 BRICKHILL ROAD  NORTH BEACH  ETHEKWINI",
    Latitude: "-29.8421",
    Longitude: "31.031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9308",
    WardLookupId: "3273",
    VDNumber: "43360130",
    RegisteredPopulation: "2090",
    VotingStationName: "CLARENCE ROAD PRIMARY SCHOOL",
    Address: "20 MITCHELL CRESCENT  GREYVILLE  ETHEKWINI",
    Latitude: "-29.839",
    Longitude: "31.0168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9309",
    WardLookupId: "3273",
    VDNumber: "43360141",
    RegisteredPopulation: "2407",
    VotingStationName: "RANDALL HALL",
    Address: "CNR MILNER AND LINDEN RD  MUSGRAVE  ETHEKWINI",
    Latitude: "-29.8406",
    Longitude: "31.0084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9310",
    WardLookupId: "3273",
    VDNumber: "43360163",
    RegisteredPopulation: "2069",
    VotingStationName: "GREYVILLE TENNIS CLUB",
    Address: "CORNER OF ARGYLE & MONTPELIER ROAD  MORNINGSIDE  ETHEKWINI",
    Latitude: "-29.8341",
    Longitude: "31.0153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9311",
    WardLookupId: "3273",
    VDNumber: "43360174",
    RegisteredPopulation: "3515",
    VotingStationName: "LIVINGSTONE PRIMARY SCHOOL",
    Address: "74 LIVINGSTONE ROAD  MORNINGSIDE  ETHEKWINI",
    Latitude: "-29.8245",
    Longitude: "31.0232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9312",
    WardLookupId: "3273",
    VDNumber: "43360185",
    RegisteredPopulation: "2166",
    VotingStationName: "GIRL GUIDES HALL",
    Address: "180 NORTH RIDGE ROAD  MORNINGSIDE  ETHEKWINI",
    Latitude: "-29.822",
    Longitude: "31.0105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9313",
    WardLookupId: "3273",
    VDNumber: "43360196",
    RegisteredPopulation: "424",
    VotingStationName: "KINGS PARK ATHLETIC STADIUM",
    Address: "WALTER -GILBERT ROAD  STAMFORD HILL  ETHEKWINI",
    Latitude: "-29.8267",
    Longitude: "31.0319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9314",
    WardLookupId: "3273",
    VDNumber: "43360208",
    RegisteredPopulation: "2019",
    VotingStationName: "MORNINGSIDE SPORTS CLUB",
    Address: "138 HOLLANDER CRESCENT  MORNINGSIDE  ETHEKWINI",
    Latitude: "-29.8205",
    Longitude: "31.0153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9315",
    WardLookupId: "3274",
    VDNumber: "43360073",
    RegisteredPopulation: "2322",
    VotingStationName: "DURBAN GIRLS SECONDARY SCHOOL",
    Address: "3331 DARTNELL CRESCENT  GREYVILLE  ETHEKWINI",
    Latitude: "-29.8498",
    Longitude: "31.0154",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9316",
    WardLookupId: "3274",
    VDNumber: "43360084",
    RegisteredPopulation: "3836",
    VotingStationName: "INSTITUTE OF TECHNOLOGY (STEVE BIKO CAMPUS)",
    Address: "MANSFIELD ROAD  BEREA  ETHEKWINI",
    Latitude: "-29.8538",
    Longitude: "31.0072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9317",
    WardLookupId: "3274",
    VDNumber: "43371255",
    RegisteredPopulation: "6116",
    VotingStationName: "DURBAN CITY HALL",
    Address: "152 WEST STREET  DURBAN  ETHEKWINI",
    Latitude: "-29.8585",
    Longitude: "31.0262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9318",
    WardLookupId: "3274",
    VDNumber: "43371288",
    RegisteredPopulation: "1888",
    VotingStationName: "DENNIS HURLEY CENTRE",
    Address: "62-64 DENIS HURLEY STREET  DURBAN  ETHEKWINI",
    Latitude: "-29.8565",
    Longitude: "31.016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9319",
    WardLookupId: "3274",
    VDNumber: "43371299",
    RegisteredPopulation: "1900",
    VotingStationName: "INSTITUTE OF TECHNOLOGY (CITY CAMPUS)",
    Address: "SMITH STREET  DURBAN  ETHEKWINI",
    Latitude: "-29.8609",
    Longitude: "31.0106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9320",
    WardLookupId: "3275",
    VDNumber: "43371480",
    RegisteredPopulation: "3147",
    VotingStationName: "WIGGINS SPORTS FIELD (TENT)",
    Address: "CNR OF INANDA/DUNBAR RD SCHOOL AVENUE  CHESTERVILLE  ETHEKWINI",
    Latitude: "-29.8524",
    Longitude: "30.9595",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9321",
    WardLookupId: "3275",
    VDNumber: "43371491",
    RegisteredPopulation: "1386",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "1 MAHLATHI ROAD (WIGGINS ROAD)  CHESTERVILLE  ETHEKWINI",
    Latitude: "-29.849",
    Longitude: "30.9522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9322",
    WardLookupId: "3275",
    VDNumber: "43371817",
    RegisteredPopulation: "2413",
    VotingStationName: "UMKHUMBAAN RIDGE VIEW COMMUNITY HALL",
    Address: "BOOTH RD.  CATO MANOR  ETHEKWINI",
    Latitude: "-29.8657",
    Longitude: "30.9618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9323",
    WardLookupId: "3275",
    VDNumber: "43371828",
    RegisteredPopulation: "8248",
    VotingStationName: "UMKHUMBANE WIGGINS COMMUNITY HALL",
    Address: "DUNBAR ROAD  CATO MANOR  ETHEKWINI",
    Latitude: "-29.8548",
    Longitude: "30.9656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9324",
    WardLookupId: "3275",
    VDNumber: "43371839",
    RegisteredPopulation: "3551",
    VotingStationName: "NSIMBINI PRIMARY SCHOOL",
    Address: "BOROUGH ROAD  CATO MANOR  ETHEKWINI",
    Latitude: "-29.8567",
    Longitude: "30.9572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9325",
    WardLookupId: "3276",
    VDNumber: "43360310",
    RegisteredPopulation: "2713",
    VotingStationName: "SHERWOOD HALL",
    Address: "102 HARRIS CRESCENT  SHERWOOD  ETHEKWINI",
    Latitude: "-29.8327",
    Longitude: "30.9728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9326",
    WardLookupId: "3276",
    VDNumber: "43362255",
    RegisteredPopulation: "899",
    VotingStationName: "SITE OFFICE HABITAT FOR HUMANITY (TENT)",
    Address: "144 45TH AVENUE   SHERWOOD   DURBAN",
    Latitude: "-29.8359",
    Longitude: "30.9626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9327",
    WardLookupId: "3276",
    VDNumber: "43371413",
    RegisteredPopulation: "5218",
    VotingStationName: "EKUPHILENI CLINIC",
    Address: "BELLAIR ROAD  CATO MANOR  ETHEKWINI",
    Latitude: "-29.8526",
    Longitude: "30.9707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9328",
    WardLookupId: "3276",
    VDNumber: "43371424",
    RegisteredPopulation: "2989",
    VotingStationName: "BONELA SECONDARY SCHOOL",
    Address: "88 BONELA ROAD  MAYVILLE  ETHEKWINI",
    Latitude: "-29.8438",
    Longitude: "30.9666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9329",
    WardLookupId: "3276",
    VDNumber: "43371435",
    RegisteredPopulation: "2505",
    VotingStationName: "BONELA PRIMARY SCHOOL",
    Address: "100 CANDELLA ROAD  MAYVILLE  ETHEKWINI",
    Latitude: "-29.842",
    Longitude: "30.9624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9330",
    WardLookupId: "3276",
    VDNumber: "43371840",
    RegisteredPopulation: "1265",
    VotingStationName: "MAYVILLE BRISTOW ROAD (TENT)",
    Address: "BRISTOW CRESCENT  BONELLA MAYVILLE  DURBAN",
    Latitude: "-29.8442",
    Longitude: "30.9803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9331",
    WardLookupId: "3276",
    VDNumber: "43390076",
    RegisteredPopulation: "2706",
    VotingStationName: "BEREA WEST SENIOR PRIMARY SCHOOL",
    Address: "7 TRENT RD  WESTVILLE  DBN",
    Latitude: "-29.8372",
    Longitude: "30.9419",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9332",
    WardLookupId: "3276",
    VDNumber: "43390098",
    RegisteredPopulation: "2426",
    VotingStationName: "TRURO HALL",
    Address: "2 MISSION PLACE  WESTVILLE  ETHEKWINI",
    Latitude: "-29.828",
    Longitude: "30.942",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9333",
    WardLookupId: "3277",
    VDNumber: "43360095",
    RegisteredPopulation: "2104",
    VotingStationName: "BEREA PRIMARY SCHOOL",
    Address: "20 BELLEVUE ROAD  BEREA  ETHEKWINI",
    Latitude: "-29.8508",
    Longitude: "30.9955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9334",
    WardLookupId: "3277",
    VDNumber: "43360107",
    RegisteredPopulation: "2957",
    VotingStationName: "NORWEGIAN HALL",
    Address: "214 ST THOMAS RD  BEREA  ETHEKWINI",
    Latitude: "-29.8449",
    Longitude: "31.0004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9335",
    WardLookupId: "3277",
    VDNumber: "43360118",
    RegisteredPopulation: "1666",
    VotingStationName: "RIDGE PARK COLLEGE (TENT)",
    Address: "399 PETER MOKABA ROAD  OVERPORT  ETHEKWINI",
    Latitude: "-29.8375",
    Longitude: "30.9966",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9336",
    WardLookupId: "3277",
    VDNumber: "43360152",
    RegisteredPopulation: "2549",
    VotingStationName: "HARTLEY ROAD PRIMARY SCHOOL",
    Address: "133 HARTLEY ROAD  OVERPORT  ETHEKWINI",
    Latitude: "-29.8348",
    Longitude: "30.997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9337",
    WardLookupId: "3277",
    VDNumber: "43360264",
    RegisteredPopulation: "2616",
    VotingStationName: "CLAYTON PRIMARY SCHOOL",
    Address: "64 CLAYTON ROAD  OVERPORT  ETHEKWINI",
    Latitude: "-29.8339",
    Longitude: "30.9893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9338",
    WardLookupId: "3277",
    VDNumber: "43360275",
    RegisteredPopulation: "2119",
    VotingStationName: "SPRINGFIELD MODEL PRIMARY SCHOOL",
    Address: "52 DAINTREE AVENUE  ASHERVILLE  ETHEKWINI",
    Latitude: "-29.8254",
    Longitude: "30.9882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9339",
    WardLookupId: "3277",
    VDNumber: "43360297",
    RegisteredPopulation: "3225",
    VotingStationName: "SPEARMAN ROAD PRIMARY SCHOOL",
    Address: "138 SPEARMAN ROAD  SYDENHAM  ETHEKWINI",
    Latitude: "-29.8316",
    Longitude: "30.9804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9340",
    WardLookupId: "3277",
    VDNumber: "43360309",
    RegisteredPopulation: "1792",
    VotingStationName: "SPARKS ESTATE SECONDARY SCHOOL",
    Address: "67 BARON GROVE  MAYVILLE  ETHEWINI",
    Latitude: "-29.8412",
    Longitude: "30.9869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9341",
    WardLookupId: "3278",
    VDNumber: "43370197",
    RegisteredPopulation: "2826",
    VotingStationName: "CLAIRWOOD BOYS PRIMARY SCHOOL",
    Address: "43 DONE ROAD  CLAIRWOOD  ETHEKWINI",
    Latitude: "-29.9164",
    Longitude: "30.9787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9342",
    WardLookupId: "3278",
    VDNumber: "43371244",
    RegisteredPopulation: "6384",
    VotingStationName: "DURBAN YMCA-HALL",
    Address: "82 DIAKONIA AVENUE  DURBAN CENTRAL  ETHEKWINI",
    Latitude: "-29.8632",
    Longitude: "31.0148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9343",
    WardLookupId: "3278",
    VDNumber: "43371301",
    RegisteredPopulation: "2672",
    VotingStationName: "THEKWINI COLLEGE - MELBOURNE CAMPUS",
    Address: "47 MELBOURNE ROAD  DALBRIDGE  ETHEKWINI",
    Latitude: "-29.8691",
    Longitude: "30.9968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9344",
    WardLookupId: "3278",
    VDNumber: "43371974",
    RegisteredPopulation: "1272",
    VotingStationName: "BAXTER PARK (TENT)",
    Address: "CNR CLARKE RD & CAMPBELL RD  GLENWOOD  DURBAN",
    Latitude: "-29.8615",
    Longitude: "31.0042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9345",
    WardLookupId: "3278",
    VDNumber: "43371985",
    RegisteredPopulation: "4259",
    VotingStationName: "DURBAN MUSIC SCHOOL",
    Address: "21 DIAKONIA AVENUE  DURBAN CENTRAL  DURBAN",
    Latitude: "-29.8633",
    Longitude: "31.0175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9346",
    WardLookupId: "3279",
    VDNumber: "43371200",
    RegisteredPopulation: "1428",
    VotingStationName: "BRETTONWOOD SCHOOL",
    Address: "399 OLIVER LEA DRIVE  UMBILO  ETHEKWINI",
    Latitude: "-29.893",
    Longitude: "30.9723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9347",
    WardLookupId: "3279",
    VDNumber: "43371211",
    RegisteredPopulation: "1311",
    VotingStationName: "CARRINGTON SENIOR PRIMARY SCHOOL",
    Address: "66 STELLAWOOD ROAD  UMBILO  ETHEKWINI",
    Latitude: "-29.8853",
    Longitude: "30.9789",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9348",
    WardLookupId: "3279",
    VDNumber: "43371222",
    RegisteredPopulation: "1465",
    VotingStationName: "NEDERDUITSCH HERVORMDE KERK",
    Address: "20 MERINO STREET  CONGELLA  ETHEKWINI",
    Latitude: "-29.8761",
    Longitude: "30.9942",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9349",
    WardLookupId: "3279",
    VDNumber: "43371233",
    RegisteredPopulation: "4901",
    VotingStationName: "PORT NATAL SCHOOL",
    Address: "14 DIRK UYS STREET  UMBILO  ETHEKWINI",
    Latitude: "-29.8823",
    Longitude: "30.9807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9350",
    WardLookupId: "3279",
    VDNumber: "43371323",
    RegisteredPopulation: "2188",
    VotingStationName: "GLENWOOD PRE-PARATORY SCHOOL",
    Address: "BATH ROAD  GLENWOOD  ETHEKWINI",
    Latitude: "-29.8612",
    Longitude: "30.9945",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9351",
    WardLookupId: "3279",
    VDNumber: "43371345",
    RegisteredPopulation: "2693",
    VotingStationName: "CAMPBELL HALL",
    Address: "23 JAMES HANDERSON CRESCENT  GLENWOOD  ETHEKWINI",
    Latitude: "-29.8643",
    Longitude: "30.9914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9352",
    WardLookupId: "3279",
    VDNumber: "43371356",
    RegisteredPopulation: "2039",
    VotingStationName: "STELLA SPORTS CLUB",
    Address: "CORNER OF DAVENPORT & BULWER ROAD  BEREA  ETHEKWINI",
    Latitude: "-29.8584",
    Longitude: "30.9979",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9353",
    WardLookupId: "3279",
    VDNumber: "43371367",
    RegisteredPopulation: "2169",
    VotingStationName: "HUNT ROAD SECONDARY SCHOOL",
    Address: "415 BEREA ROAD  BEREA  ETHEKWINI",
    Latitude: "-29.8534",
    Longitude: "30.998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9354",
    WardLookupId: "3280",
    VDNumber: "43360398",
    RegisteredPopulation: "3117",
    VotingStationName: "GREENWOOD PARK PRIMARY SCHOOL",
    Address: "126 PARK STATION ROAD  GREENWOOD PARK  ETHEKWINI",
    Latitude: "-29.7836",
    Longitude: "31.0201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9355",
    WardLookupId: "3280",
    VDNumber: "43360455",
    RegisteredPopulation: "2480",
    VotingStationName: "ST MICHAELS PRIMARY SCHOOL",
    Address: "123 HYACINTH ROAD  REDHILL  ETHEKWINI",
    Latitude: "-29.7707",
    Longitude: "31.0217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9356",
    WardLookupId: "3280",
    VDNumber: "43360466",
    RegisteredPopulation: "1901",
    VotingStationName: "EFFINGHMAN SECONDARY SCHOOL",
    Address: "1 DEVSHI ROAD  EFFINGHAM HEIGHTS  ETHEKWINI",
    Latitude: "-29.7692",
    Longitude: "31.0118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9357",
    WardLookupId: "3280",
    VDNumber: "43360477",
    RegisteredPopulation: "3733",
    VotingStationName: "EFFINGHAM HEIGHTS PRIMARY SCHOOL",
    Address: "1 MANJEE PLACE  EFFINGHAM HEIGHTS  ETHEKWINI",
    Latitude: "-29.7772",
    Longitude: "31.0104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9358",
    WardLookupId: "3280",
    VDNumber: "43360488",
    RegisteredPopulation: "1698",
    VotingStationName: "KENVILLE PRIMARY SCHOOL",
    Address: "SMITHFIELD ROAD  KENVILLE  ETHEKWINI",
    Latitude: "-29.7953",
    Longitude: "31.0057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9359",
    WardLookupId: "3280",
    VDNumber: "43360499",
    RegisteredPopulation: "3894",
    VotingStationName: "SEA COW LAKE SECONDARY SCHOOL",
    Address: "1 GUM TREE ROAD  KENVILLE  ETHEKWINI",
    Latitude: "-29.7973",
    Longitude: "31.0045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9360",
    WardLookupId: "3280",
    VDNumber: "43360512",
    RegisteredPopulation: "1795",
    VotingStationName: "LAKEHAVEN SECONDARY SCHOOL",
    Address: "170 HIPPO ROAD  SEA COW LAKE  ETHEKWINI",
    Latitude: "-29.7898",
    Longitude: "30.984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9361",
    WardLookupId: "3281",
    VDNumber: "43350027",
    RegisteredPopulation: "2847",
    VotingStationName: "TRINITY METHODIST CHURCH",
    Address: "4 DAVENTRY PLACE  LA LUCIA  ETHEKWINI",
    Latitude: "-29.7524",
    Longitude: "31.0574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9362",
    WardLookupId: "3281",
    VDNumber: "43350038",
    RegisteredPopulation: "4558",
    VotingStationName: "ATHOLTON PRIMARY SCHOOL",
    Address: "KINGSTON DRIVE  UMHLANGA  ETHEKWINI",
    Latitude: "-29.7169",
    Longitude: "31.0857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9363",
    WardLookupId: "3281",
    VDNumber: "43350050",
    RegisteredPopulation: "4999",
    VotingStationName: "NG KERK UMHLANGA",
    Address: "34 HARRWOOD DRIVE  UMHLANGA  ETHEKWINI",
    Latitude: "-29.7162",
    Longitude: "31.0797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9364",
    WardLookupId: "3281",
    VDNumber: "43350465",
    RegisteredPopulation: "3538",
    VotingStationName: "ITHUBA COMMUNITY CENTRE",
    Address: "SHAPPAS CITY  MOUNT EDGECOMBE  BLACKBURN INFORMAL SETTLEMENTS",
    Latitude: "-29.6931",
    Longitude: "31.0711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9365",
    WardLookupId: "3281",
    VDNumber: "43350500",
    RegisteredPopulation: "1895",
    VotingStationName: "VICTORY CHRISTIAN ACADEMY",
    Address: "34 RONAN ROAD  LA LUCIA  ETHEKWINI",
    Latitude: "-29.7444",
    Longitude: "31.0576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9366",
    WardLookupId: "3281",
    VDNumber: "43362323",
    RegisteredPopulation: "2437",
    VotingStationName: "GLENASHLEY JP SCHOOL",
    Address: "48 ADELAIDE DRIVE  GLENASHLEY  ETHEKWINI",
    Latitude: "-29.7576",
    Longitude: "31.0537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9367",
    WardLookupId: "3282",
    VDNumber: "43360376",
    RegisteredPopulation: "4238",
    VotingStationName: "ROSEHILL PRIMARY SCHOOL",
    Address: "225 BUTTERY ROAD  DURBAN NORTH  ETHEKWINI",
    Latitude: "-29.7996",
    Longitude: "31.0188",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9368",
    WardLookupId: "3282",
    VDNumber: "43360387",
    RegisteredPopulation: "2191",
    VotingStationName: "NORTH CREST PRIMARY SCHOOL",
    Address: "6 BENBOW ROAD  REDHILL  ETHEKWINI",
    Latitude: "-29.7814",
    Longitude: "31.0256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9369",
    WardLookupId: "3282",
    VDNumber: "43360400",
    RegisteredPopulation: "3218",
    VotingStationName: "DURBAN NORTH COLLEGE",
    Address: "25 PROSPECT HALL ROAD  DURBAN   DURBAN NORTH",
    Latitude: "-29.8042",
    Longitude: "31.0359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9370",
    WardLookupId: "3282",
    VDNumber: "43360411",
    RegisteredPopulation: "4629",
    VotingStationName: "CHELSEA PRE - PARATORY SCHOOL",
    Address: "30 CHELSEA DRIVE  DURBAN NORTH  ETHEKWINI",
    Latitude: "-29.7881",
    Longitude: "31.0364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9371",
    WardLookupId: "3282",
    VDNumber: "43360433",
    RegisteredPopulation: "4112",
    VotingStationName: "NORTHWOOD BOYS HIGH SCHOOL",
    Address: "140 KENSINGTON DRIVE  DURBAN NORTH  ETHEKWINI",
    Latitude: "-29.7743",
    Longitude: "31.0452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9372",
    WardLookupId: "3283",
    VDNumber: "43360501",
    RegisteredPopulation: "2591",
    VotingStationName: "DR. MACKAN MISTRY PRIMARY SCHOOL",
    Address: "87 MACKAN MISTRY ROAD  SEA COW LAKE  ETHEKWINI",
    Latitude: "-29.7974",
    Longitude: "30.9856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9373",
    WardLookupId: "3283",
    VDNumber: "43360534",
    RegisteredPopulation: "3251",
    VotingStationName: "HILLGROVE SECONDARY SCHOOL",
    Address: "77 HILLGROVE DRIVE  NEWLANDS WEST  ETHEKWINI",
    Latitude: "-29.7854",
    Longitude: "30.9532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9374",
    WardLookupId: "3283",
    VDNumber: "43360545",
    RegisteredPopulation: "2529",
    VotingStationName: "PAUL SYKES PRIMARY SCHOOL",
    Address: "LOWFIELD GROVE, EARLSFIELD  NEWLANDS WEST  ETHEKWINI",
    Latitude: "-29.775",
    Longitude: "30.9423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9375",
    WardLookupId: "3283",
    VDNumber: "43360556",
    RegisteredPopulation: "4123",
    VotingStationName: "RIVERDENE SECONDARY SCHOOL",
    Address: "317 RIVERDENE DRIVE  NEWLANDS WEST  ETHEKWINI",
    Latitude: "-29.7744",
    Longitude: "30.9345",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9376",
    WardLookupId: "3283",
    VDNumber: "43360567",
    RegisteredPopulation: "2419",
    VotingStationName: "NEW WEST SECONDARY SCHOOL",
    Address: "VELDCASTLE DRIVE  NEWLANDS WEST  ETHEKWINI",
    Latitude: "-29.7722",
    Longitude: "30.9567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9377",
    WardLookupId: "3283",
    VDNumber: "43362053",
    RegisteredPopulation: "4149",
    VotingStationName: "TENT AT WESTRICH GROUNDS",
    Address: "END OF ROAD 108202  WESTRICH  ETHEKWINI",
    Latitude: "-29.7721",
    Longitude: "30.9416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9378",
    WardLookupId: "3283",
    VDNumber: "43362312",
    RegisteredPopulation: "575",
    VotingStationName: "SAVERITE CENTRE",
    Address: "NEWLANDS WEST DRIVE  NEWLANDS WEST  NEWLANDS WEST DURBAN",
    Latitude: "-29.7802",
    Longitude: "30.9555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9379",
    WardLookupId: "3284",
    VDNumber: "43360804",
    RegisteredPopulation: "3132",
    VotingStationName: "THOBILE SCHOOL",
    Address: "B2105 MPISI ROAD  RICHMOND FARM  ETHEKWINI",
    Latitude: "-29.7608",
    Longitude: "30.9475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9380",
    WardLookupId: "3284",
    VDNumber: "43360860",
    RegisteredPopulation: "2514",
    VotingStationName: "PHIKISWAYO SENIOR PRIMARY SCHOOL",
    Address: "BOX 27064  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7558",
    Longitude: "30.9413",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9381",
    WardLookupId: "3284",
    VDNumber: "43360871",
    RegisteredPopulation: "2814",
    VotingStationName: "BHEKIMPILO COMMUNITY HALL",
    Address: "B1444 MHLOPHE RD  RICHMOND FARM  ETHEKWINI",
    Latitude: "-29.7504",
    Longitude: "30.9483",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9382",
    WardLookupId: "3284",
    VDNumber: "43360882",
    RegisteredPopulation: "1924",
    VotingStationName: "SANDASONKE  PRIMARY SCHOOL",
    Address: "A571 KHANGELA ROAD  NTUZUMA  ETHEKWINI",
    Latitude: "-29.7601",
    Longitude: "30.9414",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9383",
    WardLookupId: "3284",
    VDNumber: "43360893",
    RegisteredPopulation: "1622",
    VotingStationName: "NDABOMUHLE JUNIOR PRIMARY SCHOOL",
    Address: "A1350 MZUNGULU RD  NTUZUMA  ETHEKWINI",
    Latitude: "-29.7532",
    Longitude: "30.9342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9384",
    WardLookupId: "3284",
    VDNumber: "43360905",
    RegisteredPopulation: "2073",
    VotingStationName: "SICELULWAZI PRIMARY SCHOOL",
    Address: "A2051 MDUBU ROAD, LINDELANI  NTUZUMA  ETHEKWINI",
    Latitude: "-29.7496",
    Longitude: "30.93",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9385",
    WardLookupId: "3284",
    VDNumber: "43361063",
    RegisteredPopulation: "1513",
    VotingStationName: "UMTAPO HIGH SCHOOL",
    Address: "D SECTION  LINDELANI  ETHEKWENI",
    Latitude: "-29.7422",
    Longitude: "30.9251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9386",
    WardLookupId: "3284",
    VDNumber: "43362334",
    RegisteredPopulation: "1517",
    VotingStationName: "NTUZUMA A COMMUNITY HALL",
    Address: "MDUBU ROAD  NTUZUMA  KWA MASHU",
    Latitude: "-29.7492",
    Longitude: "30.9338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9387",
    WardLookupId: "3285",
    VDNumber: "43360635",
    RegisteredPopulation: "1018",
    VotingStationName: "DUFFS ROAD PRIMARY SCHOOL",
    Address: "2 ROBIN ROAD  DUFFS ROAD  ETHEKWINI",
    Latitude: "-29.7445",
    Longitude: "31.006",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9388",
    WardLookupId: "3285",
    VDNumber: "43360646",
    RegisteredPopulation: "4777",
    VotingStationName: "MENS HOSTEL AT ROTARY STADIUM (TENT)",
    Address: "E709 MALANDELA RD  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7444",
    Longitude: "31.0016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9389",
    WardLookupId: "3285",
    VDNumber: "43360668",
    RegisteredPopulation: "3223",
    VotingStationName: "ISULABASHA HOSTEL HALL",
    Address: "JABULA ROAD  KWAMASHU  ETHEKWINI",
    Latitude: "-29.75",
    Longitude: "30.9983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9390",
    WardLookupId: "3285",
    VDNumber: "43360680",
    RegisteredPopulation: "5098",
    VotingStationName: "BLOCK 224/228 PARKING LOT (TENT)",
    Address: "KHOLWA ROAD, SECTION A  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7443",
    Longitude: "30.9971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9391",
    WardLookupId: "3285",
    VDNumber: "43360691",
    RegisteredPopulation: "4817",
    VotingStationName: "SITE B PARKING LOT (TENT)",
    Address: "NTOKOZO ROAD,  A SECTION   KWAMASHU  ETHEKWINI",
    Latitude: "-29.7403",
    Longitude: "31.0014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9392",
    WardLookupId: "3286",
    VDNumber: "43360725",
    RegisteredPopulation: "2498",
    VotingStationName: "DR JOHN LANGALIBALELE DUBE HIGH SCHOOL",
    Address: "D1144 NONTOBE ROAD  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7587",
    Longitude: "30.9866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9393",
    WardLookupId: "3286",
    VDNumber: "43360736",
    RegisteredPopulation: "2300",
    VotingStationName: "INHLAKANIPHO SECONDARY SCHOOL",
    Address: "1418 NTUNJAMBILI ROAD  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7571",
    Longitude: "30.9813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9394",
    WardLookupId: "3286",
    VDNumber: "43360747",
    RegisteredPopulation: "2600",
    VotingStationName: "ZAMOKUHLE JUNIOR PRIMARY SCHOOL",
    Address: "D312 MANDAWE ROAD  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7551",
    Longitude: "30.986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9395",
    WardLookupId: "3286",
    VDNumber: "43360758",
    RegisteredPopulation: "2457",
    VotingStationName: "EKUSIZANENI SENIOR PRIMARY SCHOOL",
    Address: "C336 MLALAZI ROAD  KWA - MASHU  ETHEKWINI",
    Latitude: "-29.7523",
    Longitude: "30.9825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9396",
    WardLookupId: "3286",
    VDNumber: "43360769",
    RegisteredPopulation: "2701",
    VotingStationName: "PHAKAMA HIGHER PRIMARY SCHOOL",
    Address: "C 1519 UMFOLOZI ROAD  KWAMASHU  DURBAN",
    Latitude: "-29.7552",
    Longitude: "30.9786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9397",
    WardLookupId: "3286",
    VDNumber: "43360770",
    RegisteredPopulation: "2763",
    VotingStationName: "KHUPHUKANI JUNIOR PRIMARY SCHOOL",
    Address: "C1178 UMVOTI ROAD  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7553",
    Longitude: "30.9749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9398",
    WardLookupId: "3286",
    VDNumber: "43362211",
    RegisteredPopulation: "3303",
    VotingStationName: "ESIBAYENI OFFICE 1 (TENT)",
    Address: "121239 ST  SIYANDA  KWAMASHU",
    Latitude: "-29.7589",
    Longitude: "30.972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9399",
    WardLookupId: "3287",
    VDNumber: "43360781",
    RegisteredPopulation: "3331",
    VotingStationName: "SIYANDAMAZULU PRIMARY SCHOOL",
    Address: "B382 SIYANDA  NEWLANDS EAST  ETHEKWINI",
    Latitude: "-29.7592",
    Longitude: "30.9667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9400",
    WardLookupId: "3287",
    VDNumber: "43360815",
    RegisteredPopulation: "5170",
    VotingStationName: "THOLAMANDLA SENIOR PRIMARY SCHOOL",
    Address: "K646 SAQWACA ROAD  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7547",
    Longitude: "30.9607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9401",
    WardLookupId: "3287",
    VDNumber: "43360826",
    RegisteredPopulation: "2079",
    VotingStationName: "ZEPH DHLOMO HIGH SCHOOL",
    Address: "K1938 MPANGELE ROAD  KWA - MASHU  ETHEKWINI",
    Latitude: "-29.7525",
    Longitude: "30.9663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9402",
    WardLookupId: "3287",
    VDNumber: "43360837",
    RegisteredPopulation: "2412",
    VotingStationName: "SIBONISE JUNIOR PRIMARY SCHOOL",
    Address: "J1107 MNCAKA ROAD  KWAMASHU  ETHEKWINI",
    Latitude: "-29.75",
    Longitude: "30.9632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9403",
    WardLookupId: "3287",
    VDNumber: "43362086",
    RegisteredPopulation: "2021",
    VotingStationName: "KWANKALANKALA TRANSIT CAMP TENT",
    Address: "B 1519 SAMBANE ROAD  NTUZUMA B  NTUZUMA",
    Latitude: "-29.7486",
    Longitude: "30.9561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9404",
    WardLookupId: "3288",
    VDNumber: "43361120",
    RegisteredPopulation: "2445",
    VotingStationName: "DUMEHLEZI HIGH SCHOOL",
    Address: "G376 SOKESIMBONE G - SECTION  NTUZUMA  ETHEKWINI",
    Latitude: "-29.721",
    Longitude: "30.9445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9405",
    WardLookupId: "3288",
    VDNumber: "43361322",
    RegisteredPopulation: "3358",
    VotingStationName: "SIYAKHULA CRECHE",
    Address: "D64 PIESANG  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7185",
    Longitude: "30.9349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9406",
    WardLookupId: "3288",
    VDNumber: "43361333",
    RegisteredPopulation: "1772",
    VotingStationName: "DALMENY LOWER PRIMARY SCHOOL",
    Address: "G990 ZIPHETHE STR.  NTUZUMA  ETHEKWINI",
    Latitude: "-29.721",
    Longitude: "30.9519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9407",
    WardLookupId: "3288",
    VDNumber: "43361377",
    RegisteredPopulation: "1110",
    VotingStationName: "INANDA TOWNSHIP MANAGERS OFFICE",
    Address: "A2794 KING BUTHELEZI DRIVE  INANDA NEWTOWN  ETHEKWINI",
    Latitude: "-29.7158",
    Longitude: "30.9357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9408",
    WardLookupId: "3288",
    VDNumber: "43361399",
    RegisteredPopulation: "2262",
    VotingStationName: "HLANGANANI EDUCARE CENTRE",
    Address: "A2854 HLANGANANI ROAD  INANDA  ETHEKWINI",
    Latitude: "-29.7158",
    Longitude: "30.952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9409",
    WardLookupId: "3288",
    VDNumber: "43362064",
    RegisteredPopulation: "2710",
    VotingStationName: "MUZIWABANTU JUNIOR PRIMARY SCHOOL",
    Address: "F729 HLOMUKA STREET  NTUZUMA  ETHEKWINI",
    Latitude: "-29.7284",
    Longitude: "30.9534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9410",
    WardLookupId: "3288",
    VDNumber: "43362345",
    RegisteredPopulation: "1063",
    VotingStationName: "INTUZUMA JP SCHOOL",
    Address: "EZIMANGWENI  NEW TOWN C  INANDA",
    Latitude: "-29.7245",
    Longitude: "30.9418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9411",
    WardLookupId: "3289",
    VDNumber: "43361085",
    RegisteredPopulation: "4050",
    VotingStationName: "SITHEMBILE PRIMARY SCHOOL",
    Address: "D SECTION, THANDABANTU  LINDELANI  ETHEKWINI",
    Latitude: "-29.7355",
    Longitude: "30.923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9412",
    WardLookupId: "3289",
    VDNumber: "43361096",
    RegisteredPopulation: "2396",
    VotingStationName: "BHEKISISA HIGH SCHOOL",
    Address: "1490 ITHENDELE ROAD  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7243",
    Longitude: "30.9241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9413",
    WardLookupId: "3289",
    VDNumber: "43361108",
    RegisteredPopulation: "2373",
    VotingStationName: "HALALISA JUNIOR PRIMARY SCHOOL",
    Address: "E824 SAGWACA RD  NTUZUMA  ETHEKWINI",
    Latitude: "-29.7253",
    Longitude: "30.9327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9414",
    WardLookupId: "3289",
    VDNumber: "43361119",
    RegisteredPopulation: "1927",
    VotingStationName: "IGUGULABASHA HIGH SCHOOL",
    Address: "F 2020 NTUZUMA T/SHIP  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7336",
    Longitude: "30.9361",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9415",
    WardLookupId: "3289",
    VDNumber: "43362075",
    RegisteredPopulation: "1273",
    VotingStationName: "PHELELANI SENIOR PRIMARY SCHOOL",
    Address: "F938 NTUZUMA  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7329",
    Longitude: "30.9446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9416",
    WardLookupId: "3289",
    VDNumber: "43362266",
    RegisteredPopulation: "928",
    VotingStationName: "SONDELANI PRIMARY SCHOOL",
    Address: "E32 IDADA CLOSE   NTUZUMA   (ETH - ETHEKWINI [DURBAN METRO])",
    Latitude: "-29.7227",
    Longitude: "30.9345",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9417",
    WardLookupId: "3289",
    VDNumber: "43362277",
    RegisteredPopulation: "2347",
    VotingStationName: "PHASE 1 LINDELANI OPEN SITE (TENT)",
    Address:
      "NEW ROAD   MAIN ROAD FROM NTUZUMA  (ETH - ETHEKWINI [DURBAN METRO])",
    Latitude: "-29.739",
    Longitude: "30.9274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9418",
    WardLookupId: "3290",
    VDNumber: "43361298",
    RegisteredPopulation: "3198",
    VotingStationName: "SBUSISIWE CRECHE AND PRE-SCHOOL",
    Address: "PHASE 3  INANDA GLEBE  INANDA",
    Latitude: "-29.7141",
    Longitude: "30.9188",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9419",
    WardLookupId: "3290",
    VDNumber: "43361300",
    RegisteredPopulation: "2215",
    VotingStationName: "ZUZOKUHLE COMBINED PRIMARY SCHOOL (INANDA GLEB NO. 17)",
    Address: "LOT 552  INANDA GLEBE  ETHEKWINI",
    Latitude: "-29.7119",
    Longitude: "30.9288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9420",
    WardLookupId: "3290",
    VDNumber: "43361311",
    RegisteredPopulation: "2478",
    VotingStationName: "AKHANANI DAY CARE",
    Address: "1088 EMAPULAZINI  INANDA  ETHEKWINI",
    Latitude: "-29.7151",
    Longitude: "30.9261",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9421",
    WardLookupId: "3290",
    VDNumber: "43583058",
    RegisteredPopulation: "3661",
    VotingStationName: "MBASELA PRIMARY SCHOOL",
    Address: "MACHOBENI MAIN RD  INANDA  ETHEKWINI",
    Latitude: "-29.7342",
    Longitude: "30.9173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9422",
    WardLookupId: "3290",
    VDNumber: "43583070",
    RegisteredPopulation: "2282",
    VotingStationName: "INANDA DAY HIGHER PRIMARY SCHOOL",
    Address: "INANDA MISSION  INANDA  INANDA MISSION",
    Latitude: "-29.7021",
    Longitude: "30.9238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9423",
    WardLookupId: "3290",
    VDNumber: "43583531",
    RegisteredPopulation: "1935",
    VotingStationName: "VUSUKUKHANYA SCHOOL",
    Address: "INANDA MISSION STATION  INANDA  ETHEKWINI",
    Latitude: "-29.6939",
    Longitude: "30.9167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9424",
    WardLookupId: "3290",
    VDNumber: "43584598",
    RegisteredPopulation: "373",
    VotingStationName: "LITTLE WORLD DAY CARE CENTRE",
    Address: "EMACHOBENI  INANDA",
    Latitude: "-29.7527",
    Longitude: "30.9205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9425",
    WardLookupId: "3291",
    VDNumber: "43360916",
    RegisteredPopulation: "3682",
    VotingStationName: "THANDOLWESIZWE PRIMARY SCHOOL",
    Address: "SECTION C, LINDELANI  NTUZUMA  ETHEKWINI",
    Latitude: "-29.743",
    Longitude: "30.939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9426",
    WardLookupId: "3291",
    VDNumber: "43361029",
    RegisteredPopulation: "2668",
    VotingStationName: "NKULISABANTU PRIMARY SCHOOL",
    Address: "L591 SIZENZE ROAD  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7358",
    Longitude: "30.972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9427",
    WardLookupId: "3291",
    VDNumber: "43361041",
    RegisteredPopulation: "1979",
    VotingStationName: "GOBHOGOBHO JUNIOR PRIMARY SCHOOL",
    Address: "F165 HAWU RD  NTUZUMA  ETHEKWINI",
    Latitude: "-29.735",
    Longitude: "30.961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9428",
    WardLookupId: "3291",
    VDNumber: "43361052",
    RegisteredPopulation: "1433",
    VotingStationName: "J E NDLOVU HIGH SCHOOL",
    Address: "N332 IBOHLOLOLO DRIVE  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7415",
    Longitude: "30.9625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9429",
    WardLookupId: "3291",
    VDNumber: "43362097",
    RegisteredPopulation: "2750",
    VotingStationName: "NTUZUMA B HALL",
    Address: "OFF MNGAKA ROAD  B - SECTION NTUZUMA  ETHEKWINI",
    Latitude: "-29.7401",
    Longitude: "30.9572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9430",
    WardLookupId: "3291",
    VDNumber: "43362299",
    RegisteredPopulation: "1583",
    VotingStationName: "NTUZUMA C (MADAMFANA TRANSIT CAMP)",
    Address: "1944 BHUCU ROAD  C SECTION  NTUZUMA",
    Latitude: "-29.7411",
    Longitude: "30.948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9431",
    WardLookupId: "3291",
    VDNumber: "43362301",
    RegisteredPopulation: "1215",
    VotingStationName: "VELEMSENI CRECHE",
    Address: "62563 INKOMFE AVENUE  NTUZUMA  ETHEKWINI",
    Latitude: "-29.7362",
    Longitude: "30.9464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9432",
    WardLookupId: "3292",
    VDNumber: "43360657",
    RegisteredPopulation: "3551",
    VotingStationName: "BHEKUZULU COMMUNITY HALL",
    Address: "MUSA ROAD  KWA - MASHU  ETHEKWINI",
    Latitude: "-29.7415",
    Longitude: "30.9961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9433",
    WardLookupId: "3292",
    VDNumber: "43360679",
    RegisteredPopulation: "2631",
    VotingStationName: "CHURCH SITE MUSA ROAD (TENT)",
    Address: "MUSA ROAD, B SECTION  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7423",
    Longitude: "30.9949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9434",
    WardLookupId: "3292",
    VDNumber: "43360949",
    RegisteredPopulation: "2520",
    VotingStationName: "DUKEMINI JUNIOR PRIMARY SCHOOL",
    Address: "E576 MZILIKAZI ROAD  KWA - MASHU  ETHEKWINI",
    Latitude: "-29.7373",
    Longitude: "30.9934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9435",
    WardLookupId: "3292",
    VDNumber: "43360950",
    RegisteredPopulation: "2665",
    VotingStationName: "M A MAZIBUKO SCOUT HALL",
    Address: "E246 MNYAMANA ROAD  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7328",
    Longitude: "30.9938",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9436",
    WardLookupId: "3292",
    VDNumber: "43360961",
    RegisteredPopulation: "992",
    VotingStationName: "SHAYAMOYA PRIMARY SCHOOL",
    Address: "F 866 THABEKHULU ROAD  KWA - MASHU  ETHEKWINI",
    Latitude: "-29.7408",
    Longitude: "30.9847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9437",
    WardLookupId: "3292",
    VDNumber: "43361984",
    RegisteredPopulation: "864",
    VotingStationName: "SIVANANDA TECHNICAL HIGH SCHOOL",
    Address: "F5 MALANDELA ROAD  KWA - MASHU  ETHEKWINI",
    Latitude: "-29.7424",
    Longitude: "30.9899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9438",
    WardLookupId: "3293",
    VDNumber: "43360972",
    RegisteredPopulation: "2317",
    VotingStationName: "INKANYISWENI HALL",
    Address: "F1518 MGOBHOZI RD  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7375",
    Longitude: "30.9869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9439",
    WardLookupId: "3293",
    VDNumber: "43361007",
    RegisteredPopulation: "2096",
    VotingStationName: "KWESETHU HIGH SCHOOL",
    Address: "F1207 ZULU ROAD  KWA - MASHU  ETHEKWINI",
    Latitude: "-29.7359",
    Longitude: "30.9823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9440",
    WardLookupId: "3293",
    VDNumber: "43361018",
    RegisteredPopulation: "1988",
    VotingStationName: "ZAKHE HIGH SCHOOL",
    Address: "L1110 MPISELO ROAD  KWA - MASHU  ETHEKWINI",
    Latitude: "-29.7306",
    Longitude: "30.9785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9441",
    WardLookupId: "3293",
    VDNumber: "43361131",
    RegisteredPopulation: "2469",
    VotingStationName: "BAMBISANDLA LOWER PRIMARY SCHOOL",
    Address: "M1238 NTUMUSHE ROAD  KWA  MASHU  ETHEKWINI",
    Latitude: "-29.7306",
    Longitude: "30.9682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9442",
    WardLookupId: "3293",
    VDNumber: "43361153",
    RegisteredPopulation: "2873",
    VotingStationName: "BHEKILANGA JUNIOR PRIMARY SCHOOL",
    Address: "M804 MUHLWA ROAD  KWA - MASHU  ETHEKWINI",
    Latitude: "-29.7285",
    Longitude: "30.9757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9443",
    WardLookupId: "3293",
    VDNumber: "43362109",
    RegisteredPopulation: "2483",
    VotingStationName: "EKUTHULENI PRE-SCHOOL (MADLAMINI CRECHE)",
    Address: "2303 LOT AREA 10  BESTER  ETHEKWINI",
    Latitude: "-29.7255",
    Longitude: "30.9746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9444",
    WardLookupId: "3293",
    VDNumber: "43362233",
    RegisteredPopulation: "700",
    VotingStationName: "MTHOLAMPILO CLINIC",
    Address: "1138 108548 ST  BESTERS  ETHEKWINI",
    Latitude: "-29.7275",
    Longitude: "30.985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9445",
    WardLookupId: "3294",
    VDNumber: "43361210",
    RegisteredPopulation: "2381",
    VotingStationName: "GREENBURY COMMUNITY HALL",
    Address: "202 GREENBURY DRIVE  PHOENIX  ETHEKWINI",
    Latitude: "-29.7238",
    Longitude: "31.0318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9446",
    WardLookupId: "3294",
    VDNumber: "43361221",
    RegisteredPopulation: "1795",
    VotingStationName: "PHOENIX TECHNICAL SECONDARY SCHOOL",
    Address: "CNR OLD NORTH COAST ROAD AND PHOENIX HIGHWAY  PHOENIX  ETHEKWINI",
    Latitude: "-29.7184",
    Longitude: "31.0359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9447",
    WardLookupId: "3294",
    VDNumber: "43361232",
    RegisteredPopulation: "2251",
    VotingStationName: "ROCKFORD PRIMARY SCHOOL",
    Address: "65 RUSTON PLACE, ROCKFORD  PHOENIX  ETHEKWINI",
    Latitude: "-29.7124",
    Longitude: "31.0286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9448",
    WardLookupId: "3294",
    VDNumber: "43361243",
    RegisteredPopulation: "1735",
    VotingStationName: "HOPEVILLE PRIMARY SCHOOL",
    Address: "10 RAINHAM ROAD  PHOENIX  ETHEKWINI",
    Latitude: "-29.723",
    Longitude: "31.0213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9449",
    WardLookupId: "3294",
    VDNumber: "43361254",
    RegisteredPopulation: "3577",
    VotingStationName: "STONEBRIDGE COMMUNITY HALL",
    Address: "5 ARCH AND STONEBRIDGE DRIVE  PHOENIX  ETHEKWINI",
    Latitude: "-29.7211",
    Longitude: "31.0169",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9450",
    WardLookupId: "3294",
    VDNumber: "43361265",
    RegisteredPopulation: "2072",
    VotingStationName: "CLAYHAVEN PRIMARY SCHOOL",
    Address: "501 ROCKFORD DRIVE  PHOENIX  ETHEKWINI",
    Latitude: "-29.7153",
    Longitude: "31.0243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9451",
    WardLookupId: "3294",
    VDNumber: "43361276",
    RegisteredPopulation: "2556",
    VotingStationName: "CLAYRIDGE PRIMARY SCHOOL",
    Address: "1 CALSHOT CRESCENT,CLAYFIELD  PHOENIX  ETHEKWINI",
    Latitude: "-29.7148",
    Longitude: "31.0158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9452",
    WardLookupId: "3294",
    VDNumber: "43362110",
    RegisteredPopulation: "2141",
    VotingStationName: "DALE VIEW SECONDARY SCHOOL",
    Address: "UNIT 2 DALEVIEW ROAD  PHOENIX  ETHEKWINI",
    Latitude: "-29.7262",
    Longitude: "31.0256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9453",
    WardLookupId: "3295",
    VDNumber: "43361546",
    RegisteredPopulation: "3514",
    VotingStationName: "SWANVALE PRIMARY SCHOOL",
    Address: "96 SOUTHVALE AVENUE  RYDALVALE  ETHEKWINI",
    Latitude: "-29.7068",
    Longitude: "30.9984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9454",
    WardLookupId: "3295",
    VDNumber: "43361568",
    RegisteredPopulation: "2384",
    VotingStationName: "LONGCROFT PRIMARY SCHOOL",
    Address: "50 WREXCROFT DRIVE  PHOENIX  ETHEKWINI",
    Latitude: "-29.7061",
    Longitude: "31.0086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9455",
    WardLookupId: "3295",
    VDNumber: "43361579",
    RegisteredPopulation: "2266",
    VotingStationName: "EARLINGTON SECONDARY SCHOOL",
    Address: "30 EARLCROFT CLOSE  PHOENIX  ETHEKWINI",
    Latitude: "-29.7056",
    Longitude: "31.0139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9456",
    WardLookupId: "3295",
    VDNumber: "43361580",
    RegisteredPopulation: "1997",
    VotingStationName: "EASTVIEW PRIMARY SCHOOL",
    Address: "122/124 NORTHBURY AVENUE  PHOENIX  ETHEKWINI",
    Latitude: "-29.7027",
    Longitude: "31.0183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9457",
    WardLookupId: "3295",
    VDNumber: "43361591",
    RegisteredPopulation: "2491",
    VotingStationName: "MILLVIEW PRIMARY SCHOOL",
    Address: "48 SOUTHBURY AVENUE  EASTBURY, PHOENIX  ETHEKWINI",
    Latitude: "-29.7057",
    Longitude: "31.022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9458",
    WardLookupId: "3295",
    VDNumber: "43361816",
    RegisteredPopulation: "2076",
    VotingStationName: "SUNFORD PRIMARY SCHOOL",
    Address: "INNERFORD ROAD  PHOENIX  ETHEKWINI",
    Latitude: "-29.6974",
    Longitude: "31.0044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9459",
    WardLookupId: "3295",
    VDNumber: "43361827",
    RegisteredPopulation: "1891",
    VotingStationName: "BRAILSFORD PRIMARY SCHOOL",
    Address: "57 BRAILSFORD AVENUE  SUNFORD, PHOENIX  ETHEKWINI",
    Latitude: "-29.6947",
    Longitude: "31.0027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9460",
    WardLookupId: "3295",
    VDNumber: "43361838",
    RegisteredPopulation: "2192",
    VotingStationName: "NORTHLEN PRIMARY SCHOOL",
    Address: "17 AVALEN AVENUE, NORTHWOOD  PHOENIX  ETHEKWINI",
    Latitude: "-29.7",
    Longitude: "30.9961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9461",
    WardLookupId: "3295",
    VDNumber: "43362121",
    RegisteredPopulation: "844",
    VotingStationName: "S DASS SCHOOL",
    Address: "45 WHITFORD ROAD  PHOENIX  ETHEKWINI",
    Latitude: "-29.6943",
    Longitude: "31.0089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9462",
    WardLookupId: "3296",
    VDNumber: "43361603",
    RegisteredPopulation: "1688",
    VotingStationName: "GRANDMORE PRIMARY SCHOOL",
    Address: "GRANDMORE DRIVE  GROVE END,PHOENIX  ETHEKWINI",
    Latitude: "-29.6973",
    Longitude: "31.0289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9463",
    WardLookupId: "3296",
    VDNumber: "43361614",
    RegisteredPopulation: "2243",
    VotingStationName: "MAHATHMA PRIMARY SCHOOL",
    Address: "UNIT 17 GROVE END DRIVE,  STANMORE, PHOENIX  ETHEKWINI",
    Latitude: "-29.6985",
    Longitude: "31.0213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9464",
    WardLookupId: "3296",
    VDNumber: "43361625",
    RegisteredPopulation: "1649",
    VotingStationName: "STANMORE PRIMARY SCHOOL",
    Address: "62 DODMORE ROAD  STANMORE, PHOENIX  ETHEKWINI",
    Latitude: "-29.6961",
    Longitude: "31.0196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9465",
    WardLookupId: "3296",
    VDNumber: "43361692",
    RegisteredPopulation: "1634",
    VotingStationName: "WOODVIEW PRIMARY SCHOOL",
    Address: "77 CHERRYWOOD AVE  PHOENIX  ETHEKWINI",
    Latitude: "-29.6853",
    Longitude: "31.0239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9466",
    WardLookupId: "3296",
    VDNumber: "43361704",
    RegisteredPopulation: "2242",
    VotingStationName: "WOODVIEW SECONDARY SCHOOL",
    Address: "320 VIEWHAVEN DRIVE  PHOENIX  ETHEKWINI",
    Latitude: "-29.6785",
    Longitude: "31.0266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9467",
    WardLookupId: "3296",
    VDNumber: "43361748",
    RegisteredPopulation: "2232",
    VotingStationName: "SKYLARK PRIMARY SCHOOL",
    Address: "241 CANEHAVEN DRIVE  FORESTHAVEN,PHOENIX  ETHEKWINI",
    Latitude: "-29.685",
    Longitude: "31.0172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9468",
    WardLookupId: "3296",
    VDNumber: "43361759",
    RegisteredPopulation: "1979",
    VotingStationName: "FORESTHAVEN SECONDARY SCHOOL",
    Address: "CANEHAVEN DRIVE  FORESTHAVEN PHOENIX  ETHEKWINI",
    Latitude: "-29.6878",
    Longitude: "31.016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9469",
    WardLookupId: "3296",
    VDNumber: "43361782",
    RegisteredPopulation: "1983",
    VotingStationName: "HAVENPARK SECONDARY SCHOOL",
    Address: "2 DIPSIDE ROAD  PHOENIX  ETHEKWINI",
    Latitude: "-29.6887",
    Longitude: "31.0109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9470",
    WardLookupId: "3296",
    VDNumber: "43362132",
    RegisteredPopulation: "1945",
    VotingStationName: "STANMORE SECONDARY SCHOOL",
    Address: "213 CORNER OF GROVEND AND STANDGROVE DRIVE  PHOENIX  ETHEKWINI",
    Latitude: "-29.6921",
    Longitude: "31.0246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9471",
    WardLookupId: "3297",
    VDNumber: "43361715",
    RegisteredPopulation: "3422",
    VotingStationName: "PALMVIEW PRIMARY SCHOOL",
    Address: "CNR OF PALMVIEW DRIVE & TRENANCE PARK DRIVE  PHOENIX  ETHEKWINI",
    Latitude: "-29.6739",
    Longitude: "31.0229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9472",
    WardLookupId: "3297",
    VDNumber: "43361726",
    RegisteredPopulation: "2576",
    VotingStationName: "SASTRI PARK SECONDARY SCHOOL",
    Address: "TRENANCE PARK DRIVE  SASTRI PARK  ETHEKWINI",
    Latitude: "-29.6725",
    Longitude: "31.0142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9473",
    WardLookupId: "3297",
    VDNumber: "43361737",
    RegisteredPopulation: "1845",
    VotingStationName: "OLYMPIA PRIMARY SCHOOL",
    Address: "266 PALMVIEW DRIVE, SHASTRI PARK  PHOENIX  ETHEKWINI",
    Latitude: "-29.6802",
    Longitude: "31.0192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9474",
    WardLookupId: "3297",
    VDNumber: "43361771",
    RegisteredPopulation: "2169",
    VotingStationName: "RUSTIC MANOR PRIMARY SCHOOL",
    Address: "32/34 RUSTIC MANOR ROAD  PHOENIX  ETHEKWINI",
    Latitude: "-29.6801",
    Longitude: "31.0094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9475",
    WardLookupId: "3297",
    VDNumber: "43361793",
    RegisteredPopulation: "1822",
    VotingStationName: "CLAYHEIGHTS PRIMARY SCHOOL",
    Address: "CLAYSIDE CRESCENT, CANESIDE  PHOENIX  ETHEKWINI",
    Latitude: "-29.6844",
    Longitude: "31.008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9476",
    WardLookupId: "3297",
    VDNumber: "43361805",
    RegisteredPopulation: "1977",
    VotingStationName: "SOLVISTA SECONDARY SCHOOL",
    Address: "278 SUNFORD DRIVE,UNIT20,  PHOENIX  ETHEKWINI",
    Latitude: "-29.69",
    Longitude: "31.0035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9477",
    WardLookupId: "3297",
    VDNumber: "43361850",
    RegisteredPopulation: "2528",
    VotingStationName: "ESSELEN HEIGHTS PRIMARY SCHOOL",
    Address: "349 ESSELEN CRESCENT  LENHAM  ETHEKWINI",
    Latitude: "-29.6939",
    Longitude: "30.9953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9478",
    WardLookupId: "3297",
    VDNumber: "43361883",
    RegisteredPopulation: "1119",
    VotingStationName: "BROOKDALE PRIMARY SCHOOL",
    Address: "18/20 WHEATBROOK RD  PHOENIX  ETHEKWINI",
    Latitude: "-29.6872",
    Longitude: "30.9905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9479",
    WardLookupId: "3297",
    VDNumber: "43361894",
    RegisteredPopulation: "2397",
    VotingStationName: "BROOKDALE SECONDARY SCHOOL",
    Address: "126 CARDHAM DRIVE, BROOKDALE  BROOKDALE, PHOENIX  ETHEKWINI",
    Latitude: "-29.6891",
    Longitude: "30.9936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9480",
    WardLookupId: "3298",
    VDNumber: "43361513",
    RegisteredPopulation: "1764",
    VotingStationName: "REDFERN PRIMARY SCHOOL",
    Address: "185 FERNHAM DRIVE  PHOENIX  ETHEKWINI",
    Latitude: "-29.7099",
    Longitude: "30.982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9481",
    WardLookupId: "3298",
    VDNumber: "43361524",
    RegisteredPopulation: "2103",
    VotingStationName: "WHETSTONE PRIMARY SCHOOL",
    Address: "31 WHETSTONE DRIVE  PHOENIX  ETHEKWINI",
    Latitude: "-29.7074",
    Longitude: "30.991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9482",
    WardLookupId: "3298",
    VDNumber: "43361535",
    RegisteredPopulation: "2169",
    VotingStationName: "HIGHSTONE PRIMARY SCHOOL",
    Address: "CNR HIGHSTONE RD & STONEHAM AVE  PHOENIX  ETHEKWINI",
    Latitude: "-29.704",
    Longitude: "30.9836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9483",
    WardLookupId: "3298",
    VDNumber: "43361557",
    RegisteredPopulation: "3988",
    VotingStationName: "INANDA NEWTOWN COMPREHENSIVE SECONDARY SCHOOL",
    Address: "INANDA HIGHWAY  INANDA  ETHEKWINI",
    Latitude: "-29.7097",
    Longitude: "30.9773",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9484",
    WardLookupId: "3298",
    VDNumber: "43361849",
    RegisteredPopulation: "1535",
    VotingStationName: "CRYSTAL POINT SECONDARY SCHOOL",
    Address: "NEDLEN ROAD  PHOENIX  ETHEKWINI",
    Latitude: "-29.6987",
    Longitude: "30.9895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9485",
    WardLookupId: "3298",
    VDNumber: "43361861",
    RegisteredPopulation: "2497",
    VotingStationName: "ALLINGHAM PRIMARY SCHOOL",
    Address: "312 FERNHAM DRIVE  WESTHAM,PHOENIX  ETHEKWINI",
    Latitude: "-29.6995",
    Longitude: "30.9849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9486",
    WardLookupId: "3298",
    VDNumber: "43361872",
    RegisteredPopulation: "3630",
    VotingStationName: "PHOENIX HEIGHTS PRIMARY SCHOOL",
    Address: "HEXAM ROAD  PHOENIX  ETHEKWINI",
    Latitude: "-29.6928",
    Longitude: "30.9823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9487",
    WardLookupId: "3298",
    VDNumber: "43361951",
    RegisteredPopulation: "1684",
    VotingStationName: "AMANDLETHU SECONDARY SCHOOL",
    Address: "AMAOTI AREA  INANDA  ETHEKWINI",
    Latitude: "-29.6864",
    Longitude: "30.9802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9488",
    WardLookupId: "3299",
    VDNumber: "43360129",
    RegisteredPopulation: "4388",
    VotingStationName: "KHANYISANI PRE-SCHOOL",
    Address: "LUSAKA AREA  AMAOTI  INANDA",
    Latitude: "-29.6796",
    Longitude: "30.9907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9489",
    WardLookupId: "3299",
    VDNumber: "43360253",
    RegisteredPopulation: "1837",
    VotingStationName: "AMAOTI HIGHER PRIMARY SCHOOL",
    Address: "RD D403  INANDA  ETHEKWINI",
    Latitude: "-29.6779",
    Longitude: "30.9852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9490",
    WardLookupId: "3299",
    VDNumber: "43360927",
    RegisteredPopulation: "2450",
    VotingStationName: "SUSAN NGCOBO PRIMARY SCHOOL",
    Address: "OFF AMAOTI MAIN ROAD  AMAOTI  INANDA",
    Latitude: "-29.6816",
    Longitude: "30.9797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9491",
    WardLookupId: "3299",
    VDNumber: "43361287",
    RegisteredPopulation: "7282",
    VotingStationName: "AMAOTI COMMUNITY HALL",
    Address: "AMAOTI MAIN ROAD OPP, AMAOTI POLICE STATION  INANDA  ETHEKWINI",
    Latitude: "-29.6751",
    Longitude: "31.0002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9492",
    WardLookupId: "3299",
    VDNumber: "43361760",
    RegisteredPopulation: "1795",
    VotingStationName: "TRENANCE MANOR SECONDARY SCHOOL",
    Address: "40 STONEMANOR RD, TRENANCE MANOR  PHOENIX  ETHEKWINI",
    Latitude: "-29.6763",
    Longitude: "31.0063",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9493",
    WardLookupId: "3300",
    VDNumber: "43361164",
    RegisteredPopulation: "1757",
    VotingStationName: "BESTERS OLD CLINIC",
    Address: "MZOMUSHA AREA  INANDA  ETHEKWINI",
    Latitude: "-29.721",
    Longitude: "30.9781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9494",
    WardLookupId: "3300",
    VDNumber: "43361423",
    RegisteredPopulation: "3586",
    VotingStationName: "NHLUNGWANE COMMUNITY HALL",
    Address: "448 108514  NTUZUMA  ETHEKWINI",
    Latitude: "-29.7192",
    Longitude: "30.974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9495",
    WardLookupId: "3300",
    VDNumber: "43361434",
    RegisteredPopulation: "2525",
    VotingStationName: "BESTERS CAMP COMMUNITY HALL",
    Address: "NTUZUMA ACCESS ROAD  NTUZUMA  ETHEKWINI",
    Latitude: "-29.7234",
    Longitude: "30.9829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9496",
    WardLookupId: "3300",
    VDNumber: "43361456",
    RegisteredPopulation: "3043",
    VotingStationName: "IMBALIYAMAZULU PRIMARY SCHOOL",
    Address: "216 NEWTOWN C  INANDA  ETHEKWINI",
    Latitude: "-29.7122",
    Longitude: "30.9733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9497",
    WardLookupId: "3300",
    VDNumber: "43361502",
    RegisteredPopulation: "1804",
    VotingStationName: "FERNDALE COMBINED SCHOOL",
    Address: "FERNHAM DRIVE  PHOENIX  ETHEKWINI",
    Latitude: "-29.7149",
    Longitude: "30.9899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9498",
    WardLookupId: "3300",
    VDNumber: "43362198",
    RegisteredPopulation: "1736",
    VotingStationName: "MZOMUSHA COMMUNITY HALL",
    Address: "MZOMUSHA  INANDA BESTERS  ETHEKWINI",
    Latitude: "-29.7199",
    Longitude: "30.9759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9499",
    WardLookupId: "3301",
    VDNumber: "43360792",
    RegisteredPopulation: "1045",
    VotingStationName: "PHAKAMANI CRECHE",
    Address: "OFF MR 93  OHLANGE , INANDA  ETHEKWINI",
    Latitude: "-29.699",
    Longitude: "30.9531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9500",
    WardLookupId: "3301",
    VDNumber: "43361366",
    RegisteredPopulation: "1381",
    VotingStationName: "ESIKHULULIWE PRIMARY SCHOOL",
    Address: "109 INANDA NEWTOWN B  INANDA  ETHEKWINI",
    Latitude: "-29.7112",
    Longitude: "30.9561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9501",
    WardLookupId: "3301",
    VDNumber: "43361401",
    RegisteredPopulation: "2283",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "659 NEWTOWN A  INANDA  ETHEKWINI",
    Latitude: "-29.7139",
    Longitude: "30.9464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9502",
    WardLookupId: "3301",
    VDNumber: "43361489",
    RegisteredPopulation: "2596",
    VotingStationName: "SHEMBE MEMORIAL SCHOOL",
    Address: "43244 MAWULA OFF INANDA OLD MAIN RD  INANDA  ETHEKWINI",
    Latitude: "-29.7078",
    Longitude: "30.9648",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9503",
    WardLookupId: "3301",
    VDNumber: "43361647",
    RegisteredPopulation: "2573",
    VotingStationName: "NEW CONGO OFFICE",
    Address: "2080 AREA 2  INANDA  ETHEKWINI",
    Latitude: "-29.6927",
    Longitude: "30.9496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9504",
    WardLookupId: "3301",
    VDNumber: "43361669",
    RegisteredPopulation: "2417",
    VotingStationName: "GUGULETHU CRECHE (OHLANGE PHASE 3B SCHOOL SITE)",
    Address: "93 INANDA MAIN ROAD  INANDA  ETHEKWINI",
    Latitude: "-29.6988",
    Longitude: "30.9488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9505",
    WardLookupId: "3301",
    VDNumber: "43362008",
    RegisteredPopulation: "2773",
    VotingStationName: "THEMBALETHU PRIMARY SCHOOL",
    Address: "416 MSHAYAZAFE  INANDA  ETHEKWINI",
    Latitude: "-29.7072",
    Longitude: "30.9536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9506",
    WardLookupId: "3301",
    VDNumber: "43362154",
    RegisteredPopulation: "2205",
    VotingStationName: "INANDA HOUSING SUPPORT CENTRE",
    Address: "INANDA HOUSING SUPPORT CENTRE, OHLANGE  INANDA  ETHEKWINI",
    Latitude: "-29.7031",
    Longitude: "30.9678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9507",
    WardLookupId: "3302",
    VDNumber: "43361490",
    RegisteredPopulation: "1344",
    VotingStationName: "NTOBEKO CRECHE",
    Address: "2 GOQOKAZI  PHASE  INANDA  ETHEKWINI",
    Latitude: "-29.6826",
    Longitude: "30.9613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9508",
    WardLookupId: "3302",
    VDNumber: "43361636",
    RegisteredPopulation: "3866",
    VotingStationName: "NGOQOKAZI COMMUNITY HALL",
    Address: "C117 LINDIWE MCSHA ROAD  INANDA  AMATIKWE",
    Latitude: "-29.6876",
    Longitude: "30.9347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9509",
    WardLookupId: "3302",
    VDNumber: "43361906",
    RegisteredPopulation: "909",
    VotingStationName: "SIYABONGA CRECHE EBHODIN",
    Address: "A101 NGOQOKAZI  INANDA  ETHEKWINI",
    Latitude: "-29.673",
    Longitude: "30.9468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9510",
    WardLookupId: "3302",
    VDNumber: "43361917",
    RegisteredPopulation: "2060",
    VotingStationName: "SITHABILE SECONDARY SCHOOL",
    Address: "AREA 8 AMATIKWE, GOQOKAZI  INANDA  ETHEKWINI",
    Latitude: "-29.6855",
    Longitude: "30.9498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9511",
    WardLookupId: "3302",
    VDNumber: "43361928",
    RegisteredPopulation: "2024",
    VotingStationName: "EQINISWENI COMBINED PRIMARY SCHOOL",
    Address: "M27 INANDA MAIN RD  INANDA  ETHEKWINI",
    Latitude: "-29.6829",
    Longitude: "30.9393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9512",
    WardLookupId: "3302",
    VDNumber: "43361939",
    RegisteredPopulation: "1838",
    VotingStationName: "SINENHLANHLA PRIMARY SCHOOL",
    Address: "ETAFULENI MAIN ROAD  INANDA  ETHEKWINI",
    Latitude: "-29.669",
    Longitude: "30.9459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9513",
    WardLookupId: "3302",
    VDNumber: "43362019",
    RegisteredPopulation: "303",
    VotingStationName: "TEA ESTATE HALL",
    Address: "D827 D827  VERULAM  ETHEKWINI",
    Latitude: "-29.6531",
    Longitude: "30.9574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9514",
    WardLookupId: "3302",
    VDNumber: "43582473",
    RegisteredPopulation: "2482",
    VotingStationName: "MATIKWE COMMUNITY HALL",
    Address: "112 NDWEDWE ROAD  AMATIKWE  ETHEKWINI",
    Latitude: "-29.6776",
    Longitude: "30.9212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9515",
    WardLookupId: "3302",
    VDNumber: "43584240",
    RegisteredPopulation: "907",
    VotingStationName: "MVABA HIGH SCHOOL",
    Address: "742 AMATIKWE PHASE 1  INANDA  ETHEKWINI",
    Latitude: "-29.6732",
    Longitude: "30.9333",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9516",
    WardLookupId: "3303",
    VDNumber: "43361670",
    RegisteredPopulation: "1642",
    VotingStationName: "OHLANGE HIGH SCHOOL",
    Address: "OFF OLD INANDA MAIN ROAD  INANDA  ETHEKWINI",
    Latitude: "-29.7002",
    Longitude: "30.9585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9517",
    WardLookupId: "3303",
    VDNumber: "43361681",
    RegisteredPopulation: "4390",
    VotingStationName: "OKUHLE CRECHE",
    Address: "C345 AMAOTI MAIN ROAD  INANDA  ETHEKWINI",
    Latitude: "-29.6892",
    Longitude: "30.9715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9518",
    WardLookupId: "3303",
    VDNumber: "43361940",
    RegisteredPopulation: "1614",
    VotingStationName: "THELOKUHLE ECD CENTRE",
    Address: "AMAOTI  MOZAMBIQUE AREA  INANDA",
    Latitude: "-29.6832",
    Longitude: "30.9739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9519",
    WardLookupId: "3303",
    VDNumber: "43362165",
    RegisteredPopulation: "3708",
    VotingStationName: "BHAMBAY DEVELOPMENT CENTRE",
    Address: "6 ROAD  NEWTOWN  ETHEKWINI",
    Latitude: "-29.7037",
    Longitude: "30.9758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9520",
    WardLookupId: "3303",
    VDNumber: "43362176",
    RegisteredPopulation: "2834",
    VotingStationName: "WHITE CITY CRECHE",
    Address: "963 WHITE CITY CRECHE  INANDA  ETHEKWINI",
    Latitude: "-29.6961",
    Longitude: "30.9741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9521",
    WardLookupId: "3303",
    VDNumber: "43362187",
    RegisteredPopulation: "1830",
    VotingStationName: "NHLAKANIPHO ECD CENTRE",
    Address: "504068 TAMBO PLAZA  INANDA  ETHEKWINI",
    Latitude: "-29.6922",
    Longitude: "30.9659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9522",
    WardLookupId: "3303",
    VDNumber: "43362244",
    RegisteredPopulation: "1927",
    VotingStationName: "NHLONIPHO PRIMARY SCHOOL",
    Address: "LOT1284 OHLANGE TOWNSHIP  INANDA  ETHEKWINI",
    Latitude: "-29.698",
    Longitude: "30.9671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9523",
    WardLookupId: "3304",
    VDNumber: "43350061",
    RegisteredPopulation: "965",
    VotingStationName: "LA MERCY CHRISTIAN FELLOWSHIP CHURCH",
    Address: "22 AHMEDY STREET  LA MERCY  ETHEKWINI",
    Latitude: "-29.6388",
    Longitude: "31.1253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9524",
    WardLookupId: "3304",
    VDNumber: "43350207",
    RegisteredPopulation: "1306",
    VotingStationName: "FLAMBEL PRIMARY SCHOOL",
    Address: "124 SASTRI CIRCLE  BELVEDERE  ETHEKWINI",
    Latitude: "-29.5817",
    Longitude: "31.0888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9525",
    WardLookupId: "3304",
    VDNumber: "43350252",
    RegisteredPopulation: "2162",
    VotingStationName: "TONGAAT BEACH LIBRARY",
    Address: "DOLPHIN AVENUE  TONGAAT  ETHEKWINI",
    Latitude: "-29.609",
    Longitude: "31.151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9526",
    WardLookupId: "3304",
    VDNumber: "43350331",
    RegisteredPopulation: "2661",
    VotingStationName: "CANELANDS PUBLIC LIBRARY",
    Address: "ALEC BEAN ROAD  CANELANDS  ETHEKWINI",
    Latitude: "-29.6319",
    Longitude: "31.0574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9527",
    WardLookupId: "3304",
    VDNumber: "43350342",
    RegisteredPopulation: "353",
    VotingStationName: "MT MORELAND CONSEVATORY GAZEBO",
    Address: "CORNER CHARLES & WILLIAM STR  MT MORELAND, VERULAM  ETHEKWINI",
    Latitude: "-29.6418",
    Longitude: "31.0924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9528",
    WardLookupId: "3304",
    VDNumber: "43350364",
    RegisteredPopulation: "1693",
    VotingStationName: "UMHLOTI CLINIC",
    Address: "MAIN ROAD  UMHLOTI BEACH  ETHEKWINI",
    Latitude: "-29.6701",
    Longitude: "31.1163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9529",
    WardLookupId: "3304",
    VDNumber: "43350410",
    RegisteredPopulation: "2322",
    VotingStationName: "HAMMONDS FARM TENT",
    Address: "JABU NDLOVU STREET  HAMMONDS FARM  VERULAM",
    Latitude: "-29.657",
    Longitude: "31.0651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9530",
    WardLookupId: "3304",
    VDNumber: "43350421",
    RegisteredPopulation: "1464",
    VotingStationName: "LA MERCY COMMUNITY HALL",
    Address: "4 EVEREST DRIVE  LA MERCY  TONGAAT",
    Latitude: "-29.6359",
    Longitude: "31.1323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9531",
    WardLookupId: "3304",
    VDNumber: "43350432",
    RegisteredPopulation: "1140",
    VotingStationName: "WESTBROOK RETIREMENT VILLAGE",
    Address: "WESTBROOK RETIREMENT VILLAGE HALL  TONGAAT  ETHEKWINI",
    Latitude: "-29.5919",
    Longitude: "31.1642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9532",
    WardLookupId: "3304",
    VDNumber: "43350522",
    RegisteredPopulation: "1016",
    VotingStationName: "VICTORIA PRIMARY SCHOOL",
    Address: "2 HIGH STREET  VICTORIA  TONGAAT",
    Latitude: "-29.5767",
    Longitude: "31.1163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9533",
    WardLookupId: "3304",
    VDNumber: "43350544",
    RegisteredPopulation: "2222",
    VotingStationName: "WORD OF HOPE CHURCH CRECHE",
    Address: "OFFF GREYLAND STREET  MAGWAVENI AREA  TONGAAT",
    Latitude: "-29.5532",
    Longitude: "31.1419",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9534",
    WardLookupId: "3304",
    VDNumber: "43400876",
    RegisteredPopulation: "1612",
    VotingStationName: "WATERLOO PHASE 6 CRECHE",
    Address: "7959 UMKHONTO WESIZWE ROAD  WATERLOO  VERULAM",
    Latitude: "-29.6606",
    Longitude: "31.072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9535",
    WardLookupId: "3305",
    VDNumber: "43350094",
    RegisteredPopulation: "4299",
    VotingStationName: "SIPHESIHLE SECONDARY SCHOOL",
    Address: "191 COTTONWOOD DRIVE  TRENANCE PARK  VERULAM",
    Latitude: "-29.6494",
    Longitude: "30.9996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9536",
    WardLookupId: "3305",
    VDNumber: "43350106",
    RegisteredPopulation: "2562",
    VotingStationName: "TENT AT BUFFELSDRAAI CLINIC",
    Address: "BUFFELSDRAAI ROAD, REDCLIFFE  BUFFELSDRAAI  ETHEKWINI",
    Latitude: "-29.6408",
    Longitude: "30.9896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9537",
    WardLookupId: "3305",
    VDNumber: "43350128",
    RegisteredPopulation: "1119",
    VotingStationName: "DLAMINI CHURCH",
    Address: "THANDANANI PHASE 2B  VERULAM  ETHEKWINI",
    Latitude: "-29.6527",
    Longitude: "31.0101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9538",
    WardLookupId: "3305",
    VDNumber: "43360051",
    RegisteredPopulation: "3403",
    VotingStationName: "KWAGINGA PRIMARY SCHOOL",
    Address: "AMOUTANA  VERULAM  ETHEKWINI",
    Latitude: "-29.6659",
    Longitude: "30.9958",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9539",
    WardLookupId: "3305",
    VDNumber: "43580819",
    RegisteredPopulation: "516",
    VotingStationName: "OAKFORD CATHOLIC MISSION",
    Address: "660 LOWER TUGELA  OAKFORD  VERULAM",
    Latitude: "-29.6049",
    Longitude: "31.0178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9540",
    WardLookupId: "3305",
    VDNumber: "43580831",
    RegisteredPopulation: "2423",
    VotingStationName: "OSINDISWENI HOSPITAL",
    Address: "OAKFORD ROAD  VERULAM  ETHEKWINI",
    Latitude: "-29.6079",
    Longitude: "30.9826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9541",
    WardLookupId: "3305",
    VDNumber: "43582596",
    RegisteredPopulation: "1078",
    VotingStationName: "OGUNJINI PRIMARY SCHOOL",
    Address: "NEAR OGUNJINI SHOPPING CENTRE  NDWEDWE  VERULAM",
    Latitude: "-29.5888",
    Longitude: "30.9831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9542",
    WardLookupId: "3305",
    VDNumber: "43582608",
    RegisteredPopulation: "880",
    VotingStationName: "LOCKHAT HIGH SCHOOL",
    Address: "132 LOCKHAT SCHOOL  MJOJI AREA  VERULAM",
    Latitude: "-29.6014",
    Longitude: "30.9606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9543",
    WardLookupId: "3305",
    VDNumber: "43584015",
    RegisteredPopulation: "365",
    VotingStationName: "THUMBELA PRIMARY SCHOOL",
    Address: "EKWAZINI AREA, VERULAM  EKWAZINI, VERULAM  ETHEKWINI",
    Latitude: "-29.6064",
    Longitude: "30.9338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9544",
    WardLookupId: "3305",
    VDNumber: "43584026",
    RegisteredPopulation: "670",
    VotingStationName: "BAPTIST CHURCH",
    Address: "15 OAKFORD RD  GLASGOW AREA , VERULAM  NDWEDWE",
    Latitude: "-29.5811",
    Longitude: "31.0093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9545",
    WardLookupId: "3306",
    VDNumber: "43350173",
    RegisteredPopulation: "5278",
    VotingStationName: "EVEREST HEIGHTS PRIMARY SCHOOL",
    Address: "AUSTEN AVENUE  EVEREST HEIGHTS, VERULAM  ETHEKWINI",
    Latitude: "-29.6412",
    Longitude: "31.0382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9546",
    WardLookupId: "3306",
    VDNumber: "43350184",
    RegisteredPopulation: "3323",
    VotingStationName: "DANIELS HOUSE REDCLIFFE INFORMAL SETTLEMENT",
    Address: "58 REDCLIFFE  ETHEKWINI",
    Latitude: "-29.6242",
    Longitude: "31.0296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9547",
    WardLookupId: "3306",
    VDNumber: "43350397",
    RegisteredPopulation: "3936",
    VotingStationName: "REDCLIFFE PRIMARY SCHOOL",
    Address: "REDCLIFFE DRIVE  REDCLIFFE, VERULAM  ETHEKWINI",
    Latitude: "-29.6407",
    Longitude: "31.0217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9548",
    WardLookupId: "3306",
    VDNumber: "43580820",
    RegisteredPopulation: "4586",
    VotingStationName: "COTTONLANDS PRIMARY SCHOOL",
    Address: "NDWEDWE MAIN RD  VERULAM  ETHEKWINI",
    Latitude: "-29.5875",
    Longitude: "31.0582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9549",
    WardLookupId: "3306",
    VDNumber: "43584037",
    RegisteredPopulation: "1950",
    VotingStationName: "HAZELMERE COMMUNITY HALL",
    Address: "HAZELMERE ROAD  VERULAM  ETHEKWINI",
    Latitude: "-29.6095",
    Longitude: "31.0443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9550",
    WardLookupId: "3307",
    VDNumber: "43350117",
    RegisteredPopulation: "2047",
    VotingStationName: "NEWTOWN HALL",
    Address: "1 THEMBHELIHLE ROAD, SANDFIELDS  TONGAAT  ETHEKWINI",
    Latitude: "-29.5386",
    Longitude: "31.1363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9551",
    WardLookupId: "3307",
    VDNumber: "43350218",
    RegisteredPopulation: "3110",
    VotingStationName: "BELVEDERE COMMUNITY HALL",
    Address: "CNR SAUNDERS CIRCLE  BELVEDERE DRIVE  ETHEKWINI",
    Latitude: "-29.5801",
    Longitude: "31.0834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9552",
    WardLookupId: "3307",
    VDNumber: "43350229",
    RegisteredPopulation: "1479",
    VotingStationName: "SHRI MATHI ANANDBEN PRE-PRIMARY SCHOOL",
    Address: "87 CANARY  CRESCENT,FLAMINGO HEIGHTS  TONGAAT  ETHEKWINI",
    Latitude: "-29.5817",
    Longitude: "31.094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9553",
    WardLookupId: "3307",
    VDNumber: "43350230",
    RegisteredPopulation: "2661",
    VotingStationName: "V MOONSAMY HALL",
    Address: "CORONATION ROAD  BUFFELSDALE,TONGAAT  ETHEKWINI",
    Latitude: "-29.5839",
    Longitude: "31.0981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9554",
    WardLookupId: "3307",
    VDNumber: "43350241",
    RegisteredPopulation: "1683",
    VotingStationName: "MITCHFORD PRIMARY SCHOOL",
    Address: "36 DAFFODIL DRIVE  MITHANAGAR TONGAAT  ETHEKWINI",
    Latitude: "-29.5784",
    Longitude: "31.1066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9555",
    WardLookupId: "3307",
    VDNumber: "43350320",
    RegisteredPopulation: "1243",
    VotingStationName: "GWALA FARM CHURCH",
    Address: "LOT1061 GWALA FARM  TONGAAT  ETHEKWINI",
    Latitude: "-29.5783",
    Longitude: "31.0733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9556",
    WardLookupId: "3307",
    VDNumber: "43350409",
    RegisteredPopulation: "2097",
    VotingStationName: "OLIVER REGINALD TAMBO HALL",
    Address: "EDMUNDSABERRY DRIVE  UMBHAYI  ETHEKWINI",
    Latitude: "-29.5734",
    Longitude: "31.0889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9557",
    WardLookupId: "3307",
    VDNumber: "43350533",
    RegisteredPopulation: "882",
    VotingStationName: "TRUBEL PRIMARY SCHOOL",
    Address: "207 207 SAUNDERS CIRCLE, BELVEDERE  BELVEDERE  TONGAAT",
    Latitude: "-29.5771",
    Longitude: "31.0843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9558",
    WardLookupId: "3307",
    VDNumber: "43582776",
    RegisteredPopulation: "2017",
    VotingStationName: "ZAMOKUHLE CRECHE",
    Address: "LOT81 NQABENI NO2 AREA  TONGAAT  ETHEKWINI",
    Latitude: "-29.5555",
    Longitude: "31.096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9559",
    WardLookupId: "3307",
    VDNumber: "43584048",
    RegisteredPopulation: "143",
    VotingStationName: "FROSTERLY COMPOUND CHURCH HALL",
    Address: "0593 FROSTERLY FARM  FROSTALY FARM  TONGAAT",
    Latitude: "-29.5668",
    Longitude: "31.0674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9560",
    WardLookupId: "3308",
    VDNumber: "43350263",
    RegisteredPopulation: "2491",
    VotingStationName: "TONGAAT TOWN HALL",
    Address: "VICTORIA AVENUE  TONGAAT  ETHEKWINI",
    Latitude: "-29.5705",
    Longitude: "31.1209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9561",
    WardLookupId: "3308",
    VDNumber: "43350285",
    RegisteredPopulation: "1382",
    VotingStationName: "TONGAAT CHILD WELFARE",
    Address: "12 TESCO DRIVE  POTGIETERS HILL, TONGAAT  ETHEKWINI",
    Latitude: "-29.5657",
    Longitude: "31.1182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9562",
    WardLookupId: "3308",
    VDNumber: "43350296",
    RegisteredPopulation: "3439",
    VotingStationName: "HAMBANATI RESOURCES CENTRE",
    Address: "264 JIYANE ROAD  HAMBANATI  ETHEKWINI",
    Latitude: "-29.5517",
    Longitude: "31.1227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9563",
    WardLookupId: "3308",
    VDNumber: "43350308",
    RegisteredPopulation: "2142",
    VotingStationName: "FAIRBREEZE MADRESSA HALL",
    Address: "3 GREYLANDS ROAD  MAIDSTONE  ETHEKWINI",
    Latitude: "-29.5473",
    Longitude: "31.1372",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9564",
    WardLookupId: "3308",
    VDNumber: "43350443",
    RegisteredPopulation: "2091",
    VotingStationName: "HAMBANATHI LIBRARY",
    Address: "CHILI ROAD  HAMBANATI  TONGAAT",
    Latitude: "-29.5571",
    Longitude: "31.1198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9565",
    WardLookupId: "3308",
    VDNumber: "43350454",
    RegisteredPopulation: "2911",
    VotingStationName: "QOQISIZWE-MAGWAVENI CRECHE",
    Address: "OFF GREYLAND ROAD  A88 MAGWAVENI   MAIDESTONE, TONGAAT",
    Latitude: "-29.5501",
    Longitude: "31.1437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9566",
    WardLookupId: "3308",
    VDNumber: "43350498",
    RegisteredPopulation: "970",
    VotingStationName: "BURBREEZE TENT (COMMERCIAL & IMPUMELELO)",
    Address: "1 CORNER COMMERCIAL & IMPUMELELO  BURBREEZE  ETHEKWINI",
    Latitude: "-29.5374",
    Longitude: "31.1416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9567",
    WardLookupId: "3308",
    VDNumber: "43582800",
    RegisteredPopulation: "1636",
    VotingStationName: "SARASVATI PRIMARY SCHOOL",
    Address: "19 SCHOOL ROAD  FRASERS  ETHEKWINI",
    Latitude: "-29.5325",
    Longitude: "31.171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9568",
    WardLookupId: "3309",
    VDNumber: "43390111",
    RegisteredPopulation: "3053",
    VotingStationName: "QUEENSBURGH GIRLS HIGH SCHOOL",
    Address: "97 MAIN ROAD  MALVERN  ETHEKWINI",
    Latitude: "-29.8796",
    Longitude: "30.9223",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9569",
    WardLookupId: "3309",
    VDNumber: "43390122",
    RegisteredPopulation: "3367",
    VotingStationName: "QUEENSBURGH TOWN HALL",
    Address: "CNR ST. AUGUSTINE ROAD AND MAIN ROAD  QUEENSBURGH  ETHEKWINI",
    Latitude: "-29.8717",
    Longitude: "30.8994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9570",
    WardLookupId: "3309",
    VDNumber: "43390155",
    RegisteredPopulation: "3558",
    VotingStationName: "QUEENSBURGH HIGH SCHOOL",
    Address: "102 DIPDALE ROAD  ESCOMBE, QUEENSBURGH  ETHEKWINI",
    Latitude: "-29.8801",
    Longitude: "30.9062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9571",
    WardLookupId: "3309",
    VDNumber: "43390188",
    RegisteredPopulation: "3310",
    VotingStationName: "GIRL GUIDES TRAINING CENTRE",
    Address: "622 MAIN ROAD  NORTHDENE  ETHEKWINI",
    Latitude: "-29.8675",
    Longitude: "30.8854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9572",
    WardLookupId: "3309",
    VDNumber: "43390199",
    RegisteredPopulation: "2817",
    VotingStationName: "FIRNWOOD SPORTS CLUB",
    Address: "CNR JAN SMUTS & ALEXANDER RD  ETHEKWINI  NORTHDENE",
    Latitude: "-29.8556",
    Longitude: "30.8897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9573",
    WardLookupId: "3309",
    VDNumber: "43390582",
    RegisteredPopulation: "2005",
    VotingStationName: "NORTHDENE PRIMARY SCHOOL",
    Address: "63 SCHOOLDALE ROAD  NORTHDENE  ETHEKWINI",
    Latitude: "-29.8641",
    Longitude: "30.8771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9574",
    WardLookupId: "3309",
    VDNumber: "43391066",
    RegisteredPopulation: "1221",
    VotingStationName: "EMRS QUEENSBURGH",
    Address: "2 ELLIS ROAD  ETHEKWINI  QUEENSBURGH",
    Latitude: "-29.8773",
    Longitude: "30.9251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9575",
    WardLookupId: "3310",
    VDNumber: "43371132",
    RegisteredPopulation: "1608",
    VotingStationName: "ST RAPHAELS SPECIAL SCHOOL",
    Address: "18 COULTER ROAD  WOODLANDS  ETHEKWINI",
    Latitude: "-29.9172",
    Longitude: "30.9503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9576",
    WardLookupId: "3310",
    VDNumber: "43371143",
    RegisteredPopulation: "4602",
    VotingStationName: "YELLOWWOOD PARK CIVIC CENTRE VS",
    Address: "1  PELICAN DRIVE  YELLOW WOOD PARK  DURBAN",
    Latitude: "-29.918",
    Longitude: "30.9427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9577",
    WardLookupId: "3310",
    VDNumber: "43371154",
    RegisteredPopulation: "4936",
    VotingStationName: "MONTCLAIR COMMUNITY HALL",
    Address: "191 WOOD ROAD  MONTCLAIR  ETHEKWINI",
    Latitude: "-29.9231",
    Longitude: "30.9672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9578",
    WardLookupId: "3310",
    VDNumber: "43371165",
    RegisteredPopulation: "2622",
    VotingStationName: "MONTCLAIR SENIOR PRIMARY SCHOOL",
    Address: "35 BANGAY ROAD  MONTCLAIR  ETHEKWINI",
    Latitude: "-29.9178",
    Longitude: "30.9656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9579",
    WardLookupId: "3310",
    VDNumber: "43371176",
    RegisteredPopulation: "2115",
    VotingStationName: "PHAMBILI HIGH SCHOOL",
    Address: "244 SARNIA ROAD  ROSSBURGH  ETHEKWINI",
    Latitude: "-29.9027",
    Longitude: "30.9748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9580",
    WardLookupId: "3310",
    VDNumber: "43372010",
    RegisteredPopulation: "1002",
    VotingStationName: "YELLOWWOOD PARK PRIMARY SCHOOL",
    Address: "25 FLAMINGO GROVE  YELLOWWOOD PARK  ETHEKWINI",
    Latitude: "-29.9224",
    Longitude: "30.9395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9581",
    WardLookupId: "3310",
    VDNumber: "43372021",
    RegisteredPopulation: "1639",
    VotingStationName: "NEW FOREST HIGH SCHOOL",
    Address: "ALAMEIN AVENUE  WOODLANDS  ETHEKWINI",
    Latitude: "-29.9242",
    Longitude: "30.9566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9582",
    WardLookupId: "3310",
    VDNumber: "43372098",
    RegisteredPopulation: "789",
    VotingStationName: "WILLOW PARK PRIMARY SCHOOL",
    Address: "6 39TH AVENUE , UMHLATUZANA  CHATSWORTH  DURBAN",
    Latitude: "-29.9111",
    Longitude: "30.9242",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9583",
    WardLookupId: "3311",
    VDNumber: "43371187",
    RegisteredPopulation: "2377",
    VotingStationName: "SEAVIEW PRIMARY SCHOOL",
    Address: "129 SEAVIEW  ROAD  SEAVIEW  ETHEKWINI",
    Latitude: "-29.8998",
    Longitude: "30.9636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9584",
    WardLookupId: "3311",
    VDNumber: "43371514",
    RegisteredPopulation: "3519",
    VotingStationName: "BELLAIR PRIMARY SCHOOL",
    Address: "1191 SARNIA ROAD  BELLAIR  ETHEKWINI",
    Latitude: "-29.8895",
    Longitude: "30.949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9585",
    WardLookupId: "3311",
    VDNumber: "43371547",
    RegisteredPopulation: "2559",
    VotingStationName: "UMHLATUZANA PRIMARY SCHOOL",
    Address: "30-28TH AVENUE  UMHLATUZANA  ETHEKWINI",
    Latitude: "-29.8979",
    Longitude: "30.9206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9586",
    WardLookupId: "3311",
    VDNumber: "43371558",
    RegisteredPopulation: "2392",
    VotingStationName: "ERICA PRIMARY SCHOOL",
    Address: "40 WREN STREET  KHARWASTAN  ETHEKWINI",
    Latitude: "-29.9043",
    Longitude: "30.9114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9587",
    WardLookupId: "3311",
    VDNumber: "43371727",
    RegisteredPopulation: "4102",
    VotingStationName: "HILLARY SENIOR PRIMARY SCHOOL",
    Address: "CNR WAVERLY & STELLA ROAD  HILLARY  ETHEKWINI",
    Latitude: "-29.8876",
    Longitude: "30.9362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9588",
    WardLookupId: "3311",
    VDNumber: "43390133",
    RegisteredPopulation: "1962",
    VotingStationName: "WEST PARK SCHOOL",
    Address: "382 STELLA ROAD  MALVERN  ETHEKWINI",
    Latitude: "-29.8898",
    Longitude: "30.9236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9589",
    WardLookupId: "3311",
    VDNumber: "43390144",
    RegisteredPopulation: "3811",
    VotingStationName: "BURLINGTON HALT STATION HALL",
    Address: "3681 BURLINGTON STATION ROAD  SHALLCROSS  ETHEKWINI",
    Latitude: "-29.886",
    Longitude: "30.8989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9590",
    WardLookupId: "3312",
    VDNumber: "43370030",
    RegisteredPopulation: "967",
    VotingStationName: "TRANSHAVEN SEASIDE FUND",
    Address: "51 TREASURE BEACH ROAD  TREASURE BEACH  ETHEKWINI",
    Latitude: "-29.9471",
    Longitude: "30.9959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9591",
    WardLookupId: "3312",
    VDNumber: "43370131",
    RegisteredPopulation: "2381",
    VotingStationName: "GLENARDLE JUNIOR PRIMARY SCHOOL",
    Address: "186 GLENARDLE ROAD  BLUFF  ETHEKWINI",
    Latitude: "-29.9363",
    Longitude: "31.0018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9592",
    WardLookupId: "3312",
    VDNumber: "43370142",
    RegisteredPopulation: "2779",
    VotingStationName: "DIRKIE UYS PRIMARY SCHOOL",
    Address: "100 HOLLESLEY ROAD  BLUFF  ETHEKWINI",
    Latitude: "-29.9306",
    Longitude: "30.996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9593",
    WardLookupId: "3312",
    VDNumber: "43370153",
    RegisteredPopulation: "2691",
    VotingStationName: "ST GERARDS DAYCARE",
    Address: "43 SORMANY  BLUFF  DURBAN",
    Latitude: "-29.9186",
    Longitude: "31.0207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9594",
    WardLookupId: "3312",
    VDNumber: "43370164",
    RegisteredPopulation: "2432",
    VotingStationName: "BUSHLANDS PRIMARY SCHOOL",
    Address: "51 GRAFTON STREET  BLUFF  ETHEKWINI",
    Latitude: "-29.903",
    Longitude: "31.0347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9595",
    WardLookupId: "3312",
    VDNumber: "43370175",
    RegisteredPopulation: "4012",
    VotingStationName: "DURBAN ACADEMY",
    Address: "CORNER OF BLUFF & SHIPMAN PLACE  BLUFF  ETHEKWINI",
    Latitude: "-29.8995",
    Longitude: "31.0272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9596",
    WardLookupId: "3312",
    VDNumber: "43370186",
    RegisteredPopulation: "3183",
    VotingStationName: "GROSVENOR GIRLS HIGH SCHOOL",
    Address: "20 BIDEFORD ROAD  BLUFF  DURBAN",
    Latitude: "-29.9209",
    Longitude: "31.0044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9597",
    WardLookupId: "3313",
    VDNumber: "43580370",
    RegisteredPopulation: "2264",
    VotingStationName: "PHINDELA SENIOR PRIMARY SCHOOL",
    Address: "GOLOKODO  FOLWENI  ETHEKWINI",
    Latitude: "-30.0027",
    Longitude: "30.8424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9598",
    WardLookupId: "3313",
    VDNumber: "43580381",
    RegisteredPopulation: "2894",
    VotingStationName: "ZAMANI CRECHE",
    Address: "550 UMBUMBULU  ETHEKWINI",
    Latitude: "-30.0369",
    Longitude: "30.8382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9599",
    WardLookupId: "3313",
    VDNumber: "43580392",
    RegisteredPopulation: "3223",
    VotingStationName: "ADAMS COMMUNITY HALL",
    Address: "540 ADAMS  ADAMS MISSION,UMBUMBULU  ETHEKWINI",
    Latitude: "-30.0276",
    Longitude: "30.8227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9600",
    WardLookupId: "3313",
    VDNumber: "43580404",
    RegisteredPopulation: "2123",
    VotingStationName: "IGUGULABANGUNI PRIMARY SCHOOL",
    Address: "ADAMS MISSION  ADAMS   AMANZIMTOTI 4126  ETHEKWINI",
    Latitude: "-30.0069",
    Longitude: "30.8042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9601",
    WardLookupId: "3313",
    VDNumber: "43583205",
    RegisteredPopulation: "1245",
    VotingStationName: "SIBAMBANEZULU HIGH SCHOOL",
    Address: "UMKHAZINI AREA  ISIPINGO 4110  ETHEKWINI",
    Latitude: "-30.0115",
    Longitude: "30.842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9602",
    WardLookupId: "3313",
    VDNumber: "43583463",
    RegisteredPopulation: "1699",
    VotingStationName: "SOPHIE PHEWA SCHOOL",
    Address: "ZWLITSHA RESERVE  UMBUMBULU  ETHEKWINI",
    Latitude: "-30.015",
    Longitude: "30.8319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9603",
    WardLookupId: "3313",
    VDNumber: "43583597",
    RegisteredPopulation: "2015",
    VotingStationName: "MTHOMBENI CRECHE",
    Address: "811 MANGAMANZI  UMBUMBULU  ETHEKWINI",
    Latitude: "-29.9939",
    Longitude: "30.8505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9604",
    WardLookupId: "3313",
    VDNumber: "43584576",
    RegisteredPopulation: "571",
    VotingStationName: "ADAMS COLLEGE",
    Address: "ADAMS ROAD  ADAMS  ADAMS",
    Latitude: "-30.0312",
    Longitude: "30.8177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9605",
    WardLookupId: "3313",
    VDNumber: "43584600",
    RegisteredPopulation: "546",
    VotingStationName: "UKUKHANYA KOMKHAZI CRECHE",
    Address: "UMKHAZINI AREA  ADAMS  SOBONAKHONA",
    Latitude: "-30.024",
    Longitude: "30.8435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9606",
    WardLookupId: "3314",
    VDNumber: "43370018",
    RegisteredPopulation: "2059",
    VotingStationName: "NIZAM ROAD PRIMARY SCHOOL",
    Address: "NIZAM ROAD  MEREBANK  ETHEKWINI",
    Latitude: "-29.9614",
    Longitude: "30.9787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9607",
    WardLookupId: "3314",
    VDNumber: "43370029",
    RegisteredPopulation: "2331",
    VotingStationName: "SETTLERS PRIMARY SCHOOL",
    Address: "98 LAKHIMPUR  MEREBANK  ETHEKWINI",
    Latitude: "-29.9582",
    Longitude: "30.9789",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9608",
    WardLookupId: "3314",
    VDNumber: "43370041",
    RegisteredPopulation: "2056",
    VotingStationName: "M L SULTAN ST MARYS PRIMARY SCHOOL",
    Address: "12 JUGGERNAUT ROAD  MEREBANK  ETHEKWINI",
    Latitude: "-29.9569",
    Longitude: "30.9679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9609",
    WardLookupId: "3314",
    VDNumber: "43370052",
    RegisteredPopulation: "1863",
    VotingStationName: "JUNAGARTH ROAD PRIMARY SCHOOL",
    Address: "42 JUNAGARTH ROAD  MEREBANK  ETHEKWINI",
    Latitude: "-29.9503",
    Longitude: "30.9671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9610",
    WardLookupId: "3314",
    VDNumber: "43370085",
    RegisteredPopulation: "2319",
    VotingStationName: "WENTWORTH SECONDARY SCHOOL",
    Address: "94 OLIVE GROVE  MEREWENT  ETHEKWINI",
    Latitude: "-29.9493",
    Longitude: "30.9741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9611",
    WardLookupId: "3314",
    VDNumber: "43370096",
    RegisteredPopulation: "1658",
    VotingStationName: "GARDENIA PRIMARY SCHOOL",
    Address: "18 GARDENIA ROAD  AUSTERVILLE  ETHEKWINI",
    Latitude: "-29.9462",
    Longitude: "30.9723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9612",
    WardLookupId: "3314",
    VDNumber: "43370108",
    RegisteredPopulation: "2019",
    VotingStationName: "WENTWORTH PRIMARY SCHOOL",
    Address: "12 TIMMERMAN ROAD  WENTWORTH  ETHEKWINI",
    Latitude: "-29.9428",
    Longitude: "30.98",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9613",
    WardLookupId: "3314",
    VDNumber: "43370119",
    RegisteredPopulation: "1458",
    VotingStationName: "FAIRVALE SECONDARY SCHOOL",
    Address: "400 TARA ROAD  WENTWORTH  ETHEKWINI",
    Latitude: "-29.9433",
    Longitude: "30.9842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9614",
    WardLookupId: "3314",
    VDNumber: "43370120",
    RegisteredPopulation: "3287",
    VotingStationName: "AUSTERVILLE COMMUNITY HALL",
    Address: "2 PERCY JOHNSON DRIVE  WENTWORTH  ETHEKWINI",
    Latitude: "-29.9381",
    Longitude: "30.983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9615",
    WardLookupId: "3315",
    VDNumber: "43371053",
    RegisteredPopulation: "2028",
    VotingStationName: "MASAKHANE CRECHE",
    Address: "2422 NYATHI PLACE  LAMONTVILLE  ETHEKWINI",
    Latitude: "-29.9373",
    Longitude: "30.9347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9616",
    WardLookupId: "3315",
    VDNumber: "43371109",
    RegisteredPopulation: "1808",
    VotingStationName: "ZOE BIBLE CHURCH",
    Address: "KHALIPHILE WAY  LAMONTVILLE  DURBAN",
    Latitude: "-29.9372",
    Longitude: "30.9419",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9617",
    WardLookupId: "3315",
    VDNumber: "43371110",
    RegisteredPopulation: "2242",
    VotingStationName: "EXCELSIOR PRIMARY SCHOOL",
    Address: "JUPITER SQUARE  MOBENI HEIGHTS  ETHEKWINI",
    Latitude: "-29.9332",
    Longitude: "30.9449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9618",
    WardLookupId: "3315",
    VDNumber: "43371581",
    RegisteredPopulation: "2989",
    VotingStationName: "EVERGREEN PRIMARY SCHOOL",
    Address: "153 HAVENSIDE DRIVE  CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9281",
    Longitude: "30.929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9619",
    WardLookupId: "3315",
    VDNumber: "43371592",
    RegisteredPopulation: "1586",
    VotingStationName: "OCEANVIEW PRIMARY SCHOOL",
    Address: "11 RUSHLY STREET  CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9216",
    Longitude: "30.92",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9620",
    WardLookupId: "3315",
    VDNumber: "43371604",
    RegisteredPopulation: "2053",
    VotingStationName: "TRURO PRIMARY SCHOOL",
    Address: "FELICITY STREET  BAYVIEW  ETHEKWINI",
    Latitude: "-29.9183",
    Longitude: "30.914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9621",
    WardLookupId: "3315",
    VDNumber: "43371626",
    RegisteredPopulation: "1673",
    VotingStationName: "PROTEA SECONDARY SCHOOL",
    Address: "ROAD 257, BAYVIEW  CHATSWORTH  ETHEKWINI",
    Latitude: "-29.924",
    Longitude: "30.9193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9622",
    WardLookupId: "3315",
    VDNumber: "43371637",
    RegisteredPopulation: "3168",
    VotingStationName: "SUMMERFIELD PRIMARY SCHOOL",
    Address: "240 SUMMERFIELD  ROAD  BAYVIEW, CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9316",
    Longitude: "30.914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9623",
    WardLookupId: "3316",
    VDNumber: "43371569",
    RegisteredPopulation: "1093",
    VotingStationName: "PARKVIEW PRIMARY SCHOOL",
    Address: "STAR STREET  CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9116",
    Longitude: "30.9157",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9624",
    WardLookupId: "3316",
    VDNumber: "43371570",
    RegisteredPopulation: "2518",
    VotingStationName: "WOODHURST SECONDARY SCHOOL",
    Address: "WOODHURST DRIVE  CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9089",
    Longitude: "30.9096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9625",
    WardLookupId: "3316",
    VDNumber: "43371615",
    RegisteredPopulation: "1304",
    VotingStationName: "MERRY HILL PRIMARY SCHOOL",
    Address: "210 PELICAN DRIVE  CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9253",
    Longitude: "30.9136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9626",
    WardLookupId: "3316",
    VDNumber: "43371648",
    RegisteredPopulation: "1759",
    VotingStationName: "DEPOT ROAD MEMORIAL PRIMARY SCHOOL",
    Address: "369 PELICAN DRIVE  CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9176",
    Longitude: "30.912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9627",
    WardLookupId: "3316",
    VDNumber: "43371659",
    RegisteredPopulation: "2146",
    VotingStationName: "SUMMIT PRIMARY SCHOOL",
    Address: "POWERLINE STREET  WESTCLIFF, CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9122",
    Longitude: "30.9086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9628",
    WardLookupId: "3316",
    VDNumber: "43371660",
    RegisteredPopulation: "2068",
    VotingStationName: "CAVENDISH PRIMARY SCHOOL",
    Address: "PIRRIP STREET  WESTCLIFF, CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9113",
    Longitude: "30.9011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9629",
    WardLookupId: "3316",
    VDNumber: "43371671",
    RegisteredPopulation: "1270",
    VotingStationName: "GLEN HEIGHTS PRIMARY SCHOOL",
    Address: "18 GREENFIELDS ROAD  SILVERGLEN  CHATSWORTH",
    Latitude: "-29.9187",
    Longitude: "30.9093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9630",
    WardLookupId: "3316",
    VDNumber: "43371682",
    RegisteredPopulation: "1318",
    VotingStationName: "SILVERGLEN PRIMARY SCHOOL",
    Address: "7 MOUNTAIN VIEW ROAD  SILVERGLEN, CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9265",
    Longitude: "30.8964",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9631",
    WardLookupId: "3316",
    VDNumber: "43371705",
    RegisteredPopulation: "2384",
    VotingStationName: "GLENVIEW PRIMARY SCHOOL",
    Address: "GLENOVER ROAD  CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9146",
    Longitude: "30.897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9632",
    WardLookupId: "3316",
    VDNumber: "43371851",
    RegisteredPopulation: "878",
    VotingStationName: "GITANJALI PRIMARY SCHOOL",
    Address: "DAWNIEW ROAD  SILVERGLEN, CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9288",
    Longitude: "30.9028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9633",
    WardLookupId: "3316",
    VDNumber: "43371873",
    RegisteredPopulation: "1264",
    VotingStationName: "WESTCLIFF SECONDARY SCHOOL",
    Address: "FLORENCE NIGHTINGALE DRIVE  WESTCLIFF  CHATSWORTH",
    Latitude: "-29.9093",
    Longitude: "30.8888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9634",
    WardLookupId: "3316",
    VDNumber: "43372076",
    RegisteredPopulation: "1032",
    VotingStationName: "ALENCON PRIMARY SCHOOL",
    Address: "ASTRAL DRIVE  WOODHURST  CHATSWORTH",
    Latitude: "-29.9044",
    Longitude: "30.8994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9635",
    WardLookupId: "3317",
    VDNumber: "43370513",
    RegisteredPopulation: "6893",
    VotingStationName: "BROOKLYN HEIGHTS PRIMARY SCHOOL",
    Address: "64 CROSSMOOR DRIVE  CROSSMOOR  CHATSWORTH",
    Latitude: "-29.8982",
    Longitude: "30.8701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9636",
    WardLookupId: "3317",
    VDNumber: "43371794",
    RegisteredPopulation: "3214",
    VotingStationName: "M. PADAVATAN PRIMARY SCHOOL",
    Address: "1101 CROSSMORE ROAD  CROSSMOOR, CHATSWORTH  ETHEKWINI",
    Latitude: "-29.8924",
    Longitude: "30.8628",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9637",
    WardLookupId: "3317",
    VDNumber: "43371862",
    RegisteredPopulation: "3159",
    VotingStationName: "MOORTON COMMUNITY HALL",
    Address: "1 HARP PLACE  CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9047",
    Longitude: "30.8665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9638",
    WardLookupId: "3317",
    VDNumber: "43371963",
    RegisteredPopulation: "1373",
    VotingStationName: "ELORA PRIMARY SCHOOL",
    Address: "100 RAINSTORM ROAD  MOORTON, CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9",
    Longitude: "30.8605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9639",
    WardLookupId: "3317",
    VDNumber: "43390166",
    RegisteredPopulation: "2310",
    VotingStationName: "MALVERN PRIMARY SCHOOL",
    Address: "18 ANDES STREET  SHALLCROSS  ETHEKWINI",
    Latitude: "-29.8915",
    Longitude: "30.8722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9640",
    WardLookupId: "3317",
    VDNumber: "43390177",
    RegisteredPopulation: "3948",
    VotingStationName: "SHALLCROSS COMMUNITY HALL",
    Address: "CNR SHALLCROSS RD & EVEREST STR  SHALLCROSS  ETHEKWINI",
    Latitude: "-29.8885",
    Longitude: "30.8767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9641",
    WardLookupId: "3318",
    VDNumber: "43370928",
    RegisteredPopulation: "4856",
    VotingStationName: "DAWNRIDGE PRIMARY SCHOOL",
    Address: "751 ROAD MOORTON  CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9083",
    Longitude: "30.8611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9642",
    WardLookupId: "3318",
    VDNumber: "43390638",
    RegisteredPopulation: "1037",
    VotingStationName: "PHAKATHI HIGH SCHOOL",
    Address: "615 PHAKATHI /MOSEI STREET  KLAARWATER  KLAARWATER",
    Latitude: "-29.8882",
    Longitude: "30.8328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9643",
    WardLookupId: "3318",
    VDNumber: "43390661",
    RegisteredPopulation: "5956",
    VotingStationName: "SHALLCROSS PRIMARY SCHOOL",
    Address: "DEMAT ROAD  SHALLCROSS  CHATSWORTH",
    Latitude: "-29.8954",
    Longitude: "30.8413",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9644",
    WardLookupId: "3318",
    VDNumber: "43391044",
    RegisteredPopulation: "2993",
    VotingStationName: "IMBALIYETHU PRIMARY SCHOOL",
    Address:
      "HSE 3611 & 3615 OFF DEMAT ROAD  WELBEDACHT WEST, VILLAGE  ETHEKWINI",
    Latitude: "-29.9212",
    Longitude: "30.8304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9645",
    WardLookupId: "3318",
    VDNumber: "43391134",
    RegisteredPopulation: "2441",
    VotingStationName: "DUMISANE MAKHAYE HIGH SCHOOL",
    Address: "2353 DEMAT ROAD  WELBEDACHT WEST  CHATSWORTH",
    Latitude: "-29.9187",
    Longitude: "30.8391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9646",
    WardLookupId: "3318",
    VDNumber: "43580550",
    RegisteredPopulation: "895",
    VotingStationName: "NKONSELENI PRIMARY SCHOOL",
    Address: "KWANTETHE  UMBUMBULU 770  ETHEKWINI",
    Latitude: "-29.9121",
    Longitude: "30.8288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9647",
    WardLookupId: "3319",
    VDNumber: "43370209",
    RegisteredPopulation: "2315",
    VotingStationName: "ARENA PARK SECONDARY SCHOOL",
    Address: "118 ROSE HEIGHTS ROAD  ARENA PARK  CHATSWORTH",
    Latitude: "-29.9142",
    Longitude: "30.8765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9648",
    WardLookupId: "3319",
    VDNumber: "43370254",
    RegisteredPopulation: "1639",
    VotingStationName: "SUNBEAM PRIMARY SCHOOL",
    Address: "84 NATUREVIEW STREET  CROFTDENE, CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9246",
    Longitude: "30.8946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9649",
    WardLookupId: "3319",
    VDNumber: "43370467",
    RegisteredPopulation: "1517",
    VotingStationName: "MEADOWLANDS SECONDARY SCHOOL",
    Address: "81 ROAD 734  CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9197",
    Longitude: "30.8741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9650",
    WardLookupId: "3319",
    VDNumber: "43370603",
    RegisteredPopulation: "1732",
    VotingStationName: "SUNNYVALE PRIMARY SCHOOL",
    Address: "211 ARENA PARK DRIVE , ARENA PARK  CHATSWORTH  DURBAN",
    Latitude: "-29.9205",
    Longitude: "30.88",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9651",
    WardLookupId: "3319",
    VDNumber: "43370715",
    RegisteredPopulation: "2223",
    VotingStationName: "BELVEDERE PRIMARY SCHOOL",
    Address: "158 EQUALITY AVENUE  CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9222",
    Longitude: "30.8848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9652",
    WardLookupId: "3319",
    VDNumber: "43371121",
    RegisteredPopulation: "1575",
    VotingStationName: "WITTEKLIP SECONDARY SCHOOL",
    Address: "CORNER OF ROAD 501/519  CHATSWORTH  ETHEKWINI",
    Latitude: "-29.922",
    Longitude: "30.8896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9653",
    WardLookupId: "3319",
    VDNumber: "43371312",
    RegisteredPopulation: "2088",
    VotingStationName: "RISECLIFF SECONDARY SCHOOL",
    Address: "33 BLUE JILL CRESCENT  RISECLIFF  CHATSWORTH",
    Latitude: "-29.9144",
    Longitude: "30.8657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9654",
    WardLookupId: "3319",
    VDNumber: "43371389",
    RegisteredPopulation: "1874",
    VotingStationName: "CRESTVIEW PRIMARY SCHOOL",
    Address: "180 LEMURIA GROVE, ARENA PARK  CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9087",
    Longitude: "30.8708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9655",
    WardLookupId: "3319",
    VDNumber: "43371536",
    RegisteredPopulation: "1617",
    VotingStationName: "AYS MEMORIAL PRIMARY SCHOOL",
    Address: "RD 706 MONTFORD  CHATSWORTH  ETHEKWINI",
    Latitude: "-29.921",
    Longitude: "30.8723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9656",
    WardLookupId: "3319",
    VDNumber: "43371693",
    RegisteredPopulation: "2191",
    VotingStationName: "ASTRA PRIMARY SCHOOL",
    Address: "DUNVERIA CRESCENT  CROFTDENE, CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9164",
    Longitude: "30.8884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9657",
    WardLookupId: "3320",
    VDNumber: "43370489",
    RegisteredPopulation: "3601",
    VotingStationName: "TRANSNET TEHUIS",
    Address: "75 MANGOSUTHU HIGHWAY  UMLAZI  ETHEKWINI",
    Latitude: "-29.9548",
    Longitude: "30.9382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9658",
    WardLookupId: "3320",
    VDNumber: "43371019",
    RegisteredPopulation: "2443",
    VotingStationName: "ZWELETHU HIGH SCHOOL",
    Address: "SECTION V1131  UMLAZI  ETHEKWINI",
    Latitude: "-29.9565",
    Longitude: "30.9284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9659",
    WardLookupId: "3320",
    VDNumber: "43371042",
    RegisteredPopulation: "3030",
    VotingStationName: "GIJIMA PRIMARY SCHOOL",
    Address: "HADEBE ST  LAMONTVILLE  ETHEKWINI",
    Latitude: "-29.9401",
    Longitude: "30.9331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9660",
    WardLookupId: "3320",
    VDNumber: "43371064",
    RegisteredPopulation: "2012",
    VotingStationName: "BANTUVUKANI HIGHER PRIMARY SCHOOL",
    Address: "900 NTULI STREET  LAMONTVILLE  ETHEKWINI",
    Latitude: "-29.9447",
    Longitude: "30.9405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9661",
    WardLookupId: "3320",
    VDNumber: "43371097",
    RegisteredPopulation: "2698",
    VotingStationName: "ST JAMES CATHOLIC CHURCH",
    Address: "4332 MHLONGO ROAD  LAMONTVILLE  ETHEKWINI",
    Latitude: "-29.9417",
    Longitude: "30.939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9662",
    WardLookupId: "3320",
    VDNumber: "43371884",
    RegisteredPopulation: "1615",
    VotingStationName: "OLD ROMAN CATHOLIC CHURCH (TENT)",
    Address: "CNR. MAKHATHINI AVENUE & LUSHOZI RD.  LAMONTVILLE  ETHEKWINI",
    Latitude: "-29.9465",
    Longitude: "30.9305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9663",
    WardLookupId: "3321",
    VDNumber: "43370063",
    RegisteredPopulation: "2541",
    VotingStationName: "GANGES SECONDARY SCHOOL",
    Address: "61 JAMMU ROAD  MEREBANK  ETHEKWINI",
    Latitude: "-29.9486",
    Longitude: "30.9636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9664",
    WardLookupId: "3321",
    VDNumber: "43370074",
    RegisteredPopulation: "1667",
    VotingStationName: "JACOBS HOSTEL",
    Address: "1 MILNER STREET  JACOBS  ETHEKWINI",
    Latitude: "-29.9279",
    Longitude: "30.9792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9665",
    WardLookupId: "3321",
    VDNumber: "43371031",
    RegisteredPopulation: "4212",
    VotingStationName: "LAMONTVILLE COMMUNITY OFFICE",
    Address: "1 HULL ROAD  LAMONTVILLE  ETHEKWINI",
    Latitude: "-29.9429",
    Longitude: "30.95",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9666",
    WardLookupId: "3321",
    VDNumber: "43371075",
    RegisteredPopulation: "3208",
    VotingStationName: "ENTUTHUKWENI PRE - PRIMARY SCHOOL",
    Address: "CNR OF KULU ROAD & HALL DRIVE  LAMONTVILLE  ETHEKWINI",
    Latitude: "-29.9489",
    Longitude: "30.943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9667",
    WardLookupId: "3321",
    VDNumber: "43371402",
    RegisteredPopulation: "4292",
    VotingStationName: "S J SMITH HOSTEL (HALL)",
    Address: "20 SWINTON ROAD  MEREBANK  ETHEKWINI",
    Latitude: "-29.95",
    Longitude: "30.9497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9668",
    WardLookupId: "3322",
    VDNumber: "43370995",
    RegisteredPopulation: "2038",
    VotingStationName: "UMLAZI COMMERCIAL HIGH SCHOOL",
    Address: "V MANGOSUTHU HIGHWAY  UMLAZI  ETHEKWINI",
    Latitude: "-29.9635",
    Longitude: "30.9229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9669",
    WardLookupId: "3322",
    VDNumber: "43371020",
    RegisteredPopulation: "7668",
    VotingStationName: "GLEBE HOSTEL COMMUNITY HALL",
    Address: "NEXT TO HOSTEL SHOPS (GLEBE)  UMLAZI  ETHEKWINI",
    Latitude: "-29.9624",
    Longitude: "30.9368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9670",
    WardLookupId: "3322",
    VDNumber: "43371749",
    RegisteredPopulation: "1729",
    VotingStationName: "ENALENI HIGH SCHOOL",
    Address: "V SECTION  UMLAZI  ETHEKWINI",
    Latitude: "-29.9572",
    Longitude: "30.9301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9671",
    WardLookupId: "3322",
    VDNumber: "43372009",
    RegisteredPopulation: "1898",
    VotingStationName: "MASON LINCOLN SPECIAL SCHOOL",
    Address: "V SECTION  UMLAZI  ETHEKWINI",
    Latitude: "-29.9626",
    Longitude: "30.9324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9672",
    WardLookupId: "3322",
    VDNumber: "43380356",
    RegisteredPopulation: "944",
    VotingStationName: "REUNION SPECIAL SCHOOL",
    Address: "REUNION  DURBAN  REUNION",
    Latitude: "-29.9685",
    Longitude: "30.9378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9673",
    WardLookupId: "3323",
    VDNumber: "43370366",
    RegisteredPopulation: "1472",
    VotingStationName: "SEVEN HILLS PRIMARY SCHOOL",
    Address: "ROAD 706  CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9266",
    Longitude: "30.8755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9674",
    WardLookupId: "3323",
    VDNumber: "43370434",
    RegisteredPopulation: "2516",
    VotingStationName: "MONTFORD PRIMARY SCHOOL",
    Address: "719 MONTFORD ROAD  CHATSWORTH  ETHEKWINI",
    Latitude: "-29.923",
    Longitude: "30.8715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9675",
    WardLookupId: "3323",
    VDNumber: "43371446",
    RegisteredPopulation: "2861",
    VotingStationName: "SARVA DHARMA ASHRAM",
    Address: "10 MAIN ROAD  WELBEDACHT EAST  DURBAN",
    Latitude: "-29.9253",
    Longitude: "30.8598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9676",
    WardLookupId: "3323",
    VDNumber: "43371479",
    RegisteredPopulation: "1413",
    VotingStationName: "WELBEDENE SECONDARY SCHOOL",
    Address: "ROAD 749  CHATSWORTH  ETHEKWINI",
    Latitude: "-29.914",
    Longitude: "30.8596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9677",
    WardLookupId: "3323",
    VDNumber: "43371525",
    RegisteredPopulation: "2417",
    VotingStationName: "TYBURN PRIMARY SCHOOL",
    Address: "721 ROAD 721, MONTFORD  CHATSWORTH  ETHEKWINI",
    Latitude: "-29.9187",
    Longitude: "30.8619",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9678",
    WardLookupId: "3323",
    VDNumber: "43372043",
    RegisteredPopulation: "3762",
    VotingStationName: "DORCAS CHRISTIAN CENTER",
    Address: "12 GRACELAND DRIVE  WELBEDACHT EAST  CHATSWORTH",
    Latitude: "-29.9374",
    Longitude: "30.8509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9679",
    WardLookupId: "3323",
    VDNumber: "43372054",
    RegisteredPopulation: "2480",
    VotingStationName: "NOMZAMO MANDELA PRIMARY SCHOOL",
    Address: "1000 WELBEDACHT ROAD  WELBEDACHT EAST  CHATSWORTH",
    Latitude: "-29.9323",
    Longitude: "30.8563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9680",
    WardLookupId: "3324",
    VDNumber: "43370377",
    RegisteredPopulation: "2580",
    VotingStationName: "EMBONINI JUNIOR PRIMARY SCHOOL",
    Address: "1281 UNIT K  UMLAZI  ETHEKWINI",
    Latitude: "-29.9667",
    Longitude: "30.8582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9681",
    WardLookupId: "3324",
    VDNumber: "43370401",
    RegisteredPopulation: "2647",
    VotingStationName: "THOLISU SCHOOL",
    Address: "J1212 UMLAZI  ETHEKWINI",
    Latitude: "-29.9594",
    Longitude: "30.8562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9682",
    WardLookupId: "3324",
    VDNumber: "43370412",
    RegisteredPopulation: "2624",
    VotingStationName: "SAWELA HIGHER PRIMARY SCHOOL",
    Address: "1349 UNIT J  UMLAZI  ETHEKWINI",
    Latitude: "-29.9589",
    Longitude: "30.861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9683",
    WardLookupId: "3324",
    VDNumber: "43370445",
    RegisteredPopulation: "3926",
    VotingStationName: "ZWELIBANZI HIGH SCHOOL",
    Address: "876 J  UMLAZI  ETHEKWINI",
    Latitude: "-29.9511",
    Longitude: "30.8626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9684",
    WardLookupId: "3324",
    VDNumber: "43370614",
    RegisteredPopulation: "2579",
    VotingStationName: "DLOKO HIGH SCHOOL",
    Address: "403 UNIT J  UMLAZI  ETHEKWINI",
    Latitude: "-29.9478",
    Longitude: "30.858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9685",
    WardLookupId: "3324",
    VDNumber: "43370625",
    RegisteredPopulation: "2156",
    VotingStationName: "IMISEBE COMBINED SCHOOL",
    Address: "J49 UMLAZI  ETHEKWINI",
    Latitude: "-29.9421",
    Longitude: "30.8609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9686",
    WardLookupId: "3324",
    VDNumber: "43371941",
    RegisteredPopulation: "2208",
    VotingStationName: "KWAMGAGA HIGH SCHOOL",
    Address: "428 K SECTION  UMLAZI  ETHEKWINI",
    Latitude: "-29.9623",
    Longitude: "30.8531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9687",
    WardLookupId: "3325",
    VDNumber: "43370636",
    RegisteredPopulation: "5832",
    VotingStationName: "INSELELE JUNIOR PRIMARY SCHOOL",
    Address: "1077 UNIT H  UMLAZI  ETHEKWINI",
    Latitude: "-29.95",
    Longitude: "30.8685",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9688",
    WardLookupId: "3325",
    VDNumber: "43370647",
    RegisteredPopulation: "2859",
    VotingStationName: "INKONGOZELO PRIMARY SCHOOL",
    Address: "G1078 UMLAZI  ETHEKWINI",
    Latitude: "-29.9467",
    Longitude: "30.8801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9689",
    WardLookupId: "3325",
    VDNumber: "43370658",
    RegisteredPopulation: "1828",
    VotingStationName: "G-SECTION PARK (TENT)",
    Address: "UNIT G, ROAD 722 (NEAR KWANTSHEBE TUCK SHOP)  UMLAZI  ETHEKWINI",
    Latitude: "-29.9438",
    Longitude: "30.8837",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9690",
    WardLookupId: "3325",
    VDNumber: "43370670",
    RegisteredPopulation: "4257",
    VotingStationName: "NTWELA JUNIOR PRIMARY SCHOOL",
    Address: "405 UNIT F  UMLAZI  ETHEKWINI",
    Latitude: "-29.9478",
    Longitude: "30.889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9691",
    WardLookupId: "3325",
    VDNumber: "43370681",
    RegisteredPopulation: "3775",
    VotingStationName: "EMBIZWENI HIGH SCHOOL",
    Address: "689 UNIT F  UMLAZI  ETHEKWINI",
    Latitude: "-29.9458",
    Longitude: "30.895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9692",
    WardLookupId: "3325",
    VDNumber: "43371952",
    RegisteredPopulation: "1738",
    VotingStationName: "OPEN SPACE  (TENT)",
    Address: "H SECTION  UMLAZI  ETHEKWINI",
    Latitude: "-29.9462",
    Longitude: "30.8743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9693",
    WardLookupId: "3326",
    VDNumber: "43370906",
    RegisteredPopulation: "3333",
    VotingStationName: "KWASHAKA HIGH SCHOOL",
    Address: "80 UNIT E  UMLAZI  ETHEKWINI",
    Latitude: "-29.9466",
    Longitude: "30.9151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9694",
    WardLookupId: "3326",
    VDNumber: "43370939",
    RegisteredPopulation: "1333",
    VotingStationName: "NEXT TO UMLAZI RIVER (TENT)",
    Address: "UNIT E, ROAD 515, (NEXT TO UMLAZI  RIVER)  UMLAZI  ETHEKWINI",
    Latitude: "-29.9396",
    Longitude: "30.917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9695",
    WardLookupId: "3326",
    VDNumber: "43370940",
    RegisteredPopulation: "3059",
    VotingStationName: "NYANISWENI JUNIOR PRIMARY SCHOOL",
    Address: "1129 E  UMLAZI  ETHEKWINI",
    Latitude: "-29.9442",
    Longitude: "30.9197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9696",
    WardLookupId: "3326",
    VDNumber: "43370951",
    RegisteredPopulation: "3241",
    VotingStationName: "MBALASI COMBINED PRIMARY SCHOOL",
    Address: "UNIT A 977  UMLAZI  ETHEKWINI",
    Latitude: "-29.9575",
    Longitude: "30.915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9697",
    WardLookupId: "3326",
    VDNumber: "43371008",
    RegisteredPopulation: "2691",
    VotingStationName: "SANDAKAHLE COMBINED PRIMARY SCHOOL",
    Address: "227 V  UMLAZI TOWNSHIP  ETHEKWINI",
    Latitude: "-29.954",
    Longitude: "30.9252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9698",
    WardLookupId: "3326",
    VDNumber: "43371907",
    RegisteredPopulation: "2096",
    VotingStationName: "B SECTION OPEN SPACE  (TENT)",
    Address: "B SECTION  UMLAZI TOWNSHIP  ETHEKWINI",
    Latitude: "-29.9578",
    Longitude: "30.9091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9699",
    WardLookupId: "3327",
    VDNumber: "43370490",
    RegisteredPopulation: "1900",
    VotingStationName: "MAFUKUZELA JUNIOR PRIMARY SCHOOL",
    Address: "460 B  UMLAZI  ETHEKWINI",
    Latitude: "-29.9549",
    Longitude: "30.9064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9700",
    WardLookupId: "3327",
    VDNumber: "43370692",
    RegisteredPopulation: "1471",
    VotingStationName: "MANYUSWA SENIOR PRIMARY SCHOOL",
    Address: "347 UNIT F  UMLAZI  ETHEKWINI",
    Latitude: "-29.9508",
    Longitude: "30.891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9701",
    WardLookupId: "3327",
    VDNumber: "43370704",
    RegisteredPopulation: "1683",
    VotingStationName: "PHILA COMBINED PRIMARY SCHOOL",
    Address: "290 F SECTION  UMLAZI  ETHEKWINI",
    Latitude: "-29.9536",
    Longitude: "30.8927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9702",
    WardLookupId: "3327",
    VDNumber: "43370726",
    RegisteredPopulation: "3374",
    VotingStationName: "ISIKHUMBUZO JUNIOR PRIMARY SCHOOL",
    Address: "918 C UNIT  UMLAZI  ETHEKWINI",
    Latitude: "-29.9489",
    Longitude: "30.9037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9703",
    WardLookupId: "3327",
    VDNumber: "43370737",
    RegisteredPopulation: "1996",
    VotingStationName: "MAFUMBUKA HIGH SCHOOL",
    Address: "259 C  UMLAZI  ETHEKWIMI",
    Latitude: "-29.9558",
    Longitude: "30.8985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9704",
    WardLookupId: "3327",
    VDNumber: "43370872",
    RegisteredPopulation: "1170",
    VotingStationName: "LINDELANI SENIOR PRIMARY SCHOOL",
    Address: "636 B  UMLAZI  ETHEKWINI",
    Latitude: "-29.9588",
    Longitude: "30.9039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9705",
    WardLookupId: "3327",
    VDNumber: "43370883",
    RegisteredPopulation: "907",
    VotingStationName: "UNIT B ZONE OFFICE",
    Address: "229 UNIT C  UMLAZI  ETHEKWINI",
    Latitude: "-29.9582",
    Longitude: "30.9006",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9706",
    WardLookupId: "3327",
    VDNumber: "43370894",
    RegisteredPopulation: "2589",
    VotingStationName: "BUYANI SENIOR PRIMARY SCHOOL",
    Address: "99 E SECTION  UMLAZI  ETHEKWINI",
    Latitude: "-29.9484",
    Longitude: "30.9075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9707",
    WardLookupId: "3328",
    VDNumber: "43370579",
    RegisteredPopulation: "2528",
    VotingStationName: "ZAMOKUHLE COMMUNITY HALL",
    Address: "137 SECTION N  UMLAZI  ETHEKWINI",
    Latitude: "-29.9582",
    Longitude: "30.8787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9708",
    WardLookupId: "3328",
    VDNumber: "43370580",
    RegisteredPopulation: "3216",
    VotingStationName: "DUMIESWABAHLE PTY LTD",
    Address: "26 W  UMLAZI TOWNSHIP  ETHEKWINI",
    Latitude: "-29.956",
    Longitude: "30.8839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9709",
    WardLookupId: "3328",
    VDNumber: "43370669",
    RegisteredPopulation: "2816",
    VotingStationName: "ISIKHWELO SENIOR PRIMARY SCHOOL",
    Address: "407 UNIT G  UMLAZI  ETHEKWINI",
    Latitude: "-29.9537",
    Longitude: "30.8811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9710",
    WardLookupId: "3328",
    VDNumber: "43370748",
    RegisteredPopulation: "3191",
    VotingStationName: "UMLAZI LIBRARY",
    Address: "W SECTION  UMLAZI  ETHEKWINI",
    Latitude: "-29.9599",
    Longitude: "30.898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9711",
    WardLookupId: "3328",
    VDNumber: "43370759",
    RegisteredPopulation: "2415",
    VotingStationName: "NDUKWENHLE HIGH SCHOOL",
    Address: "27 R SECTION  UMLAZI TOWNSHIP  ETHEKWINI",
    Latitude: "-29.9655",
    Longitude: "30.892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9712",
    WardLookupId: "3328",
    VDNumber: "43371716",
    RegisteredPopulation: "2237",
    VotingStationName: "VELABAHLEKE HIGH SCHOOL",
    Address: "UNIT H8  UMLAZI  ETHEKWINI",
    Latitude: "-29.9544",
    Longitude: "30.8772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9713",
    WardLookupId: "3328",
    VDNumber: "43371918",
    RegisteredPopulation: "1581",
    VotingStationName: "CRECHE SITE  (TENT)",
    Address: "H SECTION  UMLAZI  ETHEKWINI",
    Latitude: "-29.9494",
    Longitude: "30.8756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9714",
    WardLookupId: "3328",
    VDNumber: "43371929",
    RegisteredPopulation: "947",
    VotingStationName: "UMLAZI BAPTIST CHURCH",
    Address: "466 N  UMLAZI  ETHEKWINI",
    Latitude: "-29.964",
    Longitude: "30.8812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9715",
    WardLookupId: "3329",
    VDNumber: "43370399",
    RegisteredPopulation: "2526",
    VotingStationName: "KHUTHALA JUNIOR PRIMARY SCHOOL",
    Address: "447 UNIT L  UMLAZI  ETHEKWINI",
    Latitude: "-29.9692",
    Longitude: "30.8649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9716",
    WardLookupId: "3329",
    VDNumber: "43370423",
    RegisteredPopulation: "1673",
    VotingStationName: "NDONGENI SENIOR PRIMARY SCHOOL",
    Address: "1171 M  UMLAZI  ETHEKWINI",
    Latitude: "-29.9611",
    Longitude: "30.8699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9717",
    WardLookupId: "3329",
    VDNumber: "43370524",
    RegisteredPopulation: "3462",
    VotingStationName: "SUKUMA PRIMARY SCHOOL",
    Address: "35 SECTION M  UMLAZI  ETHEKWINI",
    Latitude: "-29.9728",
    Longitude: "30.8706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9718",
    WardLookupId: "3329",
    VDNumber: "43370535",
    RegisteredPopulation: "1465",
    VotingStationName: "ESIPHETWINI JUNIOR PRIMARY SCHOOL",
    Address: "517 UNIT M, NEAR JOES INTERSECTION  UMLAZI  ETHEKWINI",
    Latitude: "-29.9711",
    Longitude: "30.8751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9719",
    WardLookupId: "3329",
    VDNumber: "43370557",
    RegisteredPopulation: "2421",
    VotingStationName: "NGILOSI JUNIOR PRIMARY SCHOOL",
    Address: "936 M SECTION  UMLAZI TOWNSHIP  ETHEKWINI",
    Latitude: "-29.965",
    Longitude: "30.8699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9720",
    WardLookupId: "3329",
    VDNumber: "43370568",
    RegisteredPopulation: "1162",
    VotingStationName: "MZIWAMANDLA HIGH SCHOOL",
    Address: "660 M  UMLAZI  ETHEKWINI",
    Latitude: "-29.9701",
    Longitude: "30.8711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9721",
    WardLookupId: "3329",
    VDNumber: "43370591",
    RegisteredPopulation: "1852",
    VotingStationName: "ZANDILE JUNIOR PRIMARY SCHOOL",
    Address: "865 UNIT H  UMLAZI  ETHEKWINI",
    Latitude: "-29.9568",
    Longitude: "30.8685",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9722",
    WardLookupId: "3329",
    VDNumber: "43371503",
    RegisteredPopulation: "3375",
    VotingStationName: "CWEBEZELA SENIOR PRIMARY SCHOOL",
    Address: "UNIT L 625  UMLAZI  ETHEKWINI",
    Latitude: "-29.9666",
    Longitude: "30.8636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9723",
    WardLookupId: "3330",
    VDNumber: "43370298",
    RegisteredPopulation: "1635",
    VotingStationName: "BHEKITHEMBA CHURCH OF ENGLAND",
    Address: "126 UNIT BB  UMLAZI  ETHEKWINI",
    Latitude: "-29.9795",
    Longitude: "30.8636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9724",
    WardLookupId: "3330",
    VDNumber: "43370300",
    RegisteredPopulation: "3201",
    VotingStationName: "STHANDIWE JUNIOR PRIMARY SCHOOL",
    Address: "CORNER BHAMBATHA & CETSHWAYO RD.  UMLAZI BB  ETHEKWINI",
    Latitude: "-29.9815",
    Longitude: "30.8559",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9725",
    WardLookupId: "3330",
    VDNumber: "43370311",
    RegisteredPopulation: "1123",
    VotingStationName: "BHEKITHEMBA COMBINED PRIMARY SCHOOL",
    Address: "203 AA  UMLAZI TOWNSHIP  ETHEKWINI",
    Latitude: "-29.982",
    Longitude: "30.8712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9726",
    WardLookupId: "3330",
    VDNumber: "43370322",
    RegisteredPopulation: "2364",
    VotingStationName: "MANDLAKAZULU COMMUNITY HALL",
    Address: "UNIT AA  UMLAZI  ETHEKWINI",
    Latitude: "-29.9787",
    Longitude: "30.866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9727",
    WardLookupId: "3330",
    VDNumber: "43370333",
    RegisteredPopulation: "2562",
    VotingStationName: "CC COMMUNITY HALL",
    Address: "CC SECTION  UMLAZI  ETHEKWINI",
    Latitude: "-29.9679",
    Longitude: "30.8428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9728",
    WardLookupId: "3330",
    VDNumber: "43370355",
    RegisteredPopulation: "2223",
    VotingStationName: "KWAMGAGA HALL",
    Address: "138 SECTION  K  UMLAZI  ETHEKWINI",
    Latitude: "-29.9646",
    Longitude: "30.8504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9729",
    WardLookupId: "3330",
    VDNumber: "43371895",
    RegisteredPopulation: "2216",
    VotingStationName: "VUMOKUHLE JUNIOR PRIMARY SCHOOL",
    Address: "K874 NYATHI ROAD  UMLAZI  ETHEKWINI",
    Latitude: "-29.9627",
    Longitude: "30.8471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9730",
    WardLookupId: "3330",
    VDNumber: "43372087",
    RegisteredPopulation: "1456",
    VotingStationName: "APOSTOLIC FAITH MISSION PARADISE ASSEMBLY",
    Address: "63 CHICAGO CRESCENT  UMLAZI  UMLAZI",
    Latitude: "-29.9724",
    Longitude: "30.8544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9731",
    WardLookupId: "3330",
    VDNumber: "43583609",
    RegisteredPopulation: "1735",
    VotingStationName: "INGILOSI CHURCH",
    Address: "NGONYAMENI  ETHEKWINI",
    Latitude: "-29.9578",
    Longitude: "30.8379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9732",
    WardLookupId: "3331",
    VDNumber: "43370232",
    RegisteredPopulation: "2576",
    VotingStationName: "EMAFEZINI JUNIOR PRIMARY SCHOOL",
    Address: "2088 Z PHASE 7  UMLAZI  ETHEKWINI",
    Latitude: "-29.9889",
    Longitude: "30.872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9733",
    WardLookupId: "3331",
    VDNumber: "43370243",
    RegisteredPopulation: "2629",
    VotingStationName: "COLA LOWER PRIMARY SCHOOL",
    Address: "271 UNIT Z  UMLAZI  ETHEKWINI",
    Latitude: "-29.9862",
    Longitude: "30.8811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9734",
    WardLookupId: "3331",
    VDNumber: "43370287",
    RegisteredPopulation: "1816",
    VotingStationName: "SISHOSONKE HIGH SCHOOL",
    Address: "38 UNIT AA KEKANA RD.  UMLAZI  ETHEKWINI",
    Latitude: "-29.9812",
    Longitude: "30.8789",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9735",
    WardLookupId: "3331",
    VDNumber: "43370760",
    RegisteredPopulation: "1637",
    VotingStationName: "FUNDAKAHLE PRIMARY SCHOOL",
    Address: "R682 1429 ROAD  UMLAZI  ETHEKWINI",
    Latitude: "-29.968",
    Longitude: "30.8833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9736",
    WardLookupId: "3331",
    VDNumber: "43370771",
    RegisteredPopulation: "2596",
    VotingStationName: "EMTHETHWENI SENIOR PRIMARY SCHOOL",
    Address: "UNIT P  UMLAZI  ETHEKWINI",
    Latitude: "-29.9724",
    Longitude: "30.884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9737",
    WardLookupId: "3331",
    VDNumber: "43370793",
    RegisteredPopulation: "2684",
    VotingStationName: "OGWINI COMPREHENSIVE HIGH SCHOOL",
    Address: "899 UNIT P  UMLAZI  ETHEKWINI",
    Latitude: "-29.9761",
    Longitude: "30.8857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9738",
    WardLookupId: "3331",
    VDNumber: "43371761",
    RegisteredPopulation: "2599",
    VotingStationName: "SEKELANI SENIOR PRIMARY SCHOOL",
    Address: "842 N  UMLAZI  ETHEKWINI",
    Latitude: "-29.9682",
    Longitude: "30.8755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9739",
    WardLookupId: "3332",
    VDNumber: "43370210",
    RegisteredPopulation: "3706",
    VotingStationName: "MALUKAZI OFFICE",
    Address: "267 RD1 MAKHEHLENI ROAD  MALUKAZI  ETHEKWINI",
    Latitude: "-29.997",
    Longitude: "30.8913",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9740",
    WardLookupId: "3332",
    VDNumber: "43370221",
    RegisteredPopulation: "2250",
    VotingStationName: "OKUMHLOPHE SENIOR SECONDARY SCHOOL",
    Address: "2027 UNIT Z  UMLAZI  ETHEKWINI",
    Latitude: "-29.9996",
    Longitude: "30.8781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9741",
    WardLookupId: "3332",
    VDNumber: "43370265",
    RegisteredPopulation: "3826",
    VotingStationName: "U COMMUNITY HALL",
    Address: "U SECTION  U SECTION, UMLAZI  ETHEKWINI",
    Latitude: "-29.9883",
    Longitude: "30.8926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9742",
    WardLookupId: "3332",
    VDNumber: "43370478",
    RegisteredPopulation: "2436",
    VotingStationName: "EMBOKODWENI PRIMARY SCHOOL",
    Address: "PHILANI VALLEY  UMLAZI Y  UMLAZI",
    Latitude: "-30.0068",
    Longitude: "30.8844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9743",
    WardLookupId: "3332",
    VDNumber: "43371772",
    RegisteredPopulation: "2013",
    VotingStationName: "ZWELIHLE SECONDARY SCHOOL",
    Address: "U772 UNIT U  UMLAZI  ETHEKWINI",
    Latitude: "-29.9945",
    Longitude: "30.8935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9744",
    WardLookupId: "3332",
    VDNumber: "43372065",
    RegisteredPopulation: "1953",
    VotingStationName: "APOSTOLIC FAITH MISSION",
    Address: "117 ECHWEBENI AVENUE  UMLAZI Y  UMLAZI",
    Latitude: "-30.0043",
    Longitude: "30.8791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9745",
    WardLookupId: "3333",
    VDNumber: "43370276",
    RegisteredPopulation: "1988",
    VotingStationName: "BADELILE LOWER PRIMARY SCHOOL",
    Address: "848 Q  UMLAZI  ETHEKWINI",
    Latitude: "-29.9845",
    Longitude: "30.895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9746",
    WardLookupId: "3333",
    VDNumber: "43370782",
    RegisteredPopulation: "2370",
    VotingStationName: "SIZWAKELE JUNIOR PRIMARY SCHOOL",
    Address: "89 UNIT P  UMLAZI  ETHEKWINI",
    Latitude: "-29.9706",
    Longitude: "30.892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9747",
    WardLookupId: "3333",
    VDNumber: "43370805",
    RegisteredPopulation: "3341",
    VotingStationName: "UNIT Q OFFICE",
    Address: "Q1028 P.O UMLAZI  UMLAZI  ETHEKWINI",
    Latitude: "-29.9805",
    Longitude: "30.891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9748",
    WardLookupId: "3333",
    VDNumber: "43370816",
    RegisteredPopulation: "4140",
    VotingStationName: "UMBELEBELE HIGH SCHOOL",
    Address: "32 Q  UMLAZI  ETHEKWINI",
    Latitude: "-29.9768",
    Longitude: "30.8984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9749",
    WardLookupId: "3333",
    VDNumber: "43370849",
    RegisteredPopulation: "3302",
    VotingStationName: "UMLAZI INDOOR SPORTS CENTRE",
    Address: "CNR OF MANGOSUTHU & SECTQ/P MAIN ROAD  UMLAZI  ETHEKWINI",
    Latitude: "-29.9702",
    Longitude: "30.8999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9750",
    WardLookupId: "3334",
    VDNumber: "43370838",
    RegisteredPopulation: "2447",
    VotingStationName: "ZIMISELE SENIOR PRIMARY SCHOOL",
    Address: "1256 UNIT D  UMLAZI  ETHEKWINI",
    Latitude: "-29.9672",
    Longitude: "30.8967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9751",
    WardLookupId: "3334",
    VDNumber: "43370850",
    RegisteredPopulation: "1569",
    VotingStationName: "ZWELESITHEMBISO SENIOR PRIMARY SCHOOL",
    Address: "562 D  UMLAZI  ETHEKWINI",
    Latitude: "-29.9659",
    Longitude: "30.9078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9752",
    WardLookupId: "3334",
    VDNumber: "43370861",
    RegisteredPopulation: "2346",
    VotingStationName: "MUZOMUHLE PRIMARY SCHOOL",
    Address: "939 B SECTION  UMLAZI  ETHEKWINI",
    Latitude: "-29.9606",
    Longitude: "30.9026",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9753",
    WardLookupId: "3334",
    VDNumber: "43370962",
    RegisteredPopulation: "3937",
    VotingStationName: "INKONKONI HIGHER PRIMARY SCHOOL",
    Address: "329 UNIT V  UMLAZI  ETHEKWINI",
    Latitude: "-29.9592",
    Longitude: "30.922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9754",
    WardLookupId: "3334",
    VDNumber: "43370973",
    RegisteredPopulation: "2288",
    VotingStationName: "UMLAZI COMTECH HIGH SCHOOL",
    Address: "UNIT S  UMLAZI  ETHEKWINI",
    Latitude: "-29.9694",
    Longitude: "30.9199",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9755",
    WardLookupId: "3334",
    VDNumber: "43370984",
    RegisteredPopulation: "3416",
    VotingStationName: "MASIBAMBANENI DAY CARE",
    Address: "18 SPIRAL ROAD GRIFFITS MXENGE  UMLAZI TOWNSHIP  UMLAZI",
    Latitude: "-29.9652",
    Longitude: "30.9138",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9756",
    WardLookupId: "3334",
    VDNumber: "43371930",
    RegisteredPopulation: "1422",
    VotingStationName: "NGQAYIZVELE LOWER PRIMARY SCHOOL",
    Address: "151 D  UMLAZI  ETHEKWINI",
    Latitude: "-29.9621",
    Longitude: "30.9115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9757",
    WardLookupId: "3335",
    VDNumber: "43370827",
    RegisteredPopulation: "4422",
    VotingStationName: "UNIT T OFFICE",
    Address: "UNIT T.OFFICE  UMLAZI TOWNSHIP  ETHEKWINI",
    Latitude: "-29.9786",
    Longitude: "30.9062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9758",
    WardLookupId: "3335",
    VDNumber: "43371457",
    RegisteredPopulation: "2330",
    VotingStationName: "UNIT T COMMUNITY HALL",
    Address: "ROAD 1703  UMLAZI T  ETHEKWINI",
    Latitude: "-29.9749",
    Longitude: "30.9071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9759",
    WardLookupId: "3335",
    VDNumber: "43371996",
    RegisteredPopulation: "3132",
    VotingStationName: "OPEN SPACE SECTION T (TENT)",
    Address: "T SECTION  UMLAZI  ETHEKWINI [DURBAN METRO]",
    Latitude: "-29.9806",
    Longitude: "30.9074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9760",
    WardLookupId: "3335",
    VDNumber: "43380233",
    RegisteredPopulation: "5852",
    VotingStationName: "WINDY HEIGHTS PRIMARY SCHOOL",
    Address: "132 POSTUM ROAD  ISIPINGO  ETHEKWINI",
    Latitude: "-29.9964",
    Longitude: "30.8989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9761",
    WardLookupId: "3335",
    VDNumber: "43380367",
    RegisteredPopulation: "1037",
    VotingStationName: "SIFUNULWAZI COMMUNITY HALL",
    Address: "UMSHINI PLACE  ZAMANI  UMLAZI",
    Latitude: "-29.9909",
    Longitude: "30.9004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9762",
    WardLookupId: "3336",
    VDNumber: "43380031",
    RegisteredPopulation: "1657",
    VotingStationName: "STRELITZIA SECONDARY SCHOOL",
    Address: "15 TEAL PLACE  ISIPINGO  ETHEKWINI",
    Latitude: "-30.0047",
    Longitude: "30.9121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9763",
    WardLookupId: "3336",
    VDNumber: "43380211",
    RegisteredPopulation: "1456",
    VotingStationName: "ISIPINGO BEACH INTERMEDIATE SCHOOL",
    Address: "31 BELLEVUE ROAD  ISIPINGO BEACH  ETHEKWINI",
    Latitude: "-29.9979",
    Longitude: "30.9436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9764",
    WardLookupId: "3336",
    VDNumber: "43380222",
    RegisteredPopulation: "1598",
    VotingStationName: "ISIPINGO CIVIC HALL",
    Address: "18 DELTA ROAD  ISIPINGO BEACH  ETHEKWINI",
    Latitude: "-29.9967",
    Longitude: "30.9464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9765",
    WardLookupId: "3336",
    VDNumber: "43380244",
    RegisteredPopulation: "2954",
    VotingStationName: "LOTUS PARK CIVIC CENTRE",
    Address: "4 ARAUCURIA PLACE  LOTUS PARK  ETHEKWINI",
    Latitude: "-29.9972",
    Longitude: "30.9107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9766",
    WardLookupId: "3336",
    VDNumber: "43380255",
    RegisteredPopulation: "3332",
    VotingStationName: "ORIENT HILL PRIMARY SCHOOL",
    Address: "90 CITRUS GROVE  ISIPINGO  ETHEKWINI",
    Latitude: "-30.0045",
    Longitude: "30.9177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9767",
    WardLookupId: "3336",
    VDNumber: "43380266",
    RegisteredPopulation: "2057",
    VotingStationName: "PRIMROSE PRIMARY SCHOOL",
    Address: "1 SAROJINE CRESCENT  ISIPINGO HILLS  ETHEKWINI",
    Latitude: "-29.987",
    Longitude: "30.9222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9768",
    WardLookupId: "3336",
    VDNumber: "43380277",
    RegisteredPopulation: "3009",
    VotingStationName: "ISIPINGO PRIMARY SCHOOL",
    Address: "CORNER OF LOTUS AND PARDY ROAD  ISIPINGO  ETHEKWINI",
    Latitude: "-29.9808",
    Longitude: "30.9218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9769",
    WardLookupId: "3336",
    VDNumber: "43380288",
    RegisteredPopulation: "2618",
    VotingStationName: "GOKUL PRIMARY SCHOOL",
    Address: "25A SULAGENI ROAD  ISIPINGO  ETHEKWINI",
    Latitude: "-29.9764",
    Longitude: "30.9255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9770",
    WardLookupId: "3337",
    VDNumber: "43400304",
    RegisteredPopulation: "1357",
    VotingStationName: "UXOLOPHAMBILI HIGH SCHOOL",
    Address:
      "A 1818 (UNIT 1 NORTH)WOZANAZO RD  MPUMALANGA T/SHIP HAMMARSDALE 3700  ETHEKWINI",
    Latitude: "-29.8109",
    Longitude: "30.6129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9771",
    WardLookupId: "3337",
    VDNumber: "43400315",
    RegisteredPopulation: "1649",
    VotingStationName: "ISIQALO LOWER PRIMARY SCHOOL",
    Address: "B 366 MTHIMKHULU ROAD  MPUMALANGA  ETHEKWINI",
    Latitude: "-29.806",
    Longitude: "30.6147",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9772",
    WardLookupId: "3337",
    VDNumber: "43400326",
    RegisteredPopulation: "2372",
    VotingStationName: "INTAKEMAZOLO PRIMARY SCHOOL",
    Address: "B880 NDABEZITHA ROAD,UNIT 2 EXTENSION  MPUMALANGA  ETHEKWINI",
    Latitude: "-29.8055",
    Longitude: "30.6266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9773",
    WardLookupId: "3337",
    VDNumber: "43400337",
    RegisteredPopulation: "2328",
    VotingStationName: "ZAMINHLANHLA SENIOR PRIMARY SCHOOL",
    Address: "A1315 MAGWAZA ROAD, UNIT 1 SOUTH,  MPUMALANGA  ETHEKWINI",
    Latitude: "-29.8136",
    Longitude: "30.6159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9774",
    WardLookupId: "3337",
    VDNumber: "43400348",
    RegisteredPopulation: "2053",
    VotingStationName: "OKHOZINI HIGHER PRIMARY SCHOOL",
    Address: "B891 UNIT 2, MKHALIPHI RD  MPUMALANGA  ETHEKWINI",
    Latitude: "-29.8066",
    Longitude: "30.6181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9775",
    WardLookupId: "3337",
    VDNumber: "43400540",
    RegisteredPopulation: "2137",
    VotingStationName: "DEPARTMENT OF WORKS",
    Address: "NEXT TO MPUMALAGA BUS DEPOT  MPUMALANGA  ETHEKWINI",
    Latitude: "-29.8089",
    Longitude: "30.6493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9776",
    WardLookupId: "3337",
    VDNumber: "43400674",
    RegisteredPopulation: "674",
    VotingStationName: "UKUSA SENIOR SECONDARY SCHOOL",
    Address:
      "A1817 1817 MAVOVO ROAD  MPUMALANGA TOWNSHIP, HAMMARSDALE  ETHEKWINI",
    Latitude: "-29.8097",
    Longitude: "30.616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9777",
    WardLookupId: "3337",
    VDNumber: "43400753",
    RegisteredPopulation: "1405",
    VotingStationName: "MPUMALANGA COMMUNITY HALL",
    Address: "SHEZI MAIN ROAD  MPUMALANGA  ETHEKWINI",
    Latitude: "-29.8123",
    Longitude: "30.6367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9778",
    WardLookupId: "3337",
    VDNumber: "43400898",
    RegisteredPopulation: "1319",
    VotingStationName: "ESIHONQENI JP SCHOOL",
    Address: "A 1825 MNGADI ROAD  HAMMARSDALE  UNIT 1 NORTH",
    Latitude: "-29.8105",
    Longitude: "30.6038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9779",
    WardLookupId: "3338",
    VDNumber: "43390065",
    RegisteredPopulation: "3023",
    VotingStationName: "PITLOCHRY PRIMARY SCHOOL",
    Address: "49 PITLOCHRY ROAD  WESTVILLE  ETHEKWINI",
    Latitude: "-29.8161",
    Longitude: "30.9268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9780",
    WardLookupId: "3338",
    VDNumber: "43390324",
    RegisteredPopulation: "1599",
    VotingStationName: "CHRISTIANENBURG HIGH PRIMARY SCHOOL",
    Address: "214 SCUN STREET  CLERMONT  ETHEKWINI",
    Latitude: "-29.7977",
    Longitude: "30.8965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9781",
    WardLookupId: "3338",
    VDNumber: "43390335",
    RegisteredPopulation: "3101",
    VotingStationName: "INDUNDUMA COMMUNITY HALL",
    Address: "33RD AVENUE  INDUNDUMA,CLERNAVILLE  ETHEKWINI",
    Latitude: "-29.8013",
    Longitude: "30.9058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9782",
    WardLookupId: "3338",
    VDNumber: "43390357",
    RegisteredPopulation: "3768",
    VotingStationName: "UMQHELE SECONDARY SCHOOL",
    Address: "2535 31ST AVENUE  CLERNAVILLE  ETHEKWINI",
    Latitude: "-29.804",
    Longitude: "30.8991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9783",
    WardLookupId: "3338",
    VDNumber: "43390368",
    RegisteredPopulation: "2836",
    VotingStationName: "38TH AVENUE COMMUNITY HALL",
    Address: "38TH AVENUE  CLERMONT  ETHEKWINI",
    Latitude: "-29.7931",
    Longitude: "30.9136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9784",
    WardLookupId: "3338",
    VDNumber: "43390379",
    RegisteredPopulation: "2297",
    VotingStationName: "VEZAMAFA PRIMARY SCHOOL",
    Address: "CLERMONT ROAD  CLERMONT  LOT 1",
    Latitude: "-29.7836",
    Longitude: "30.9166",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9785",
    WardLookupId: "3338",
    VDNumber: "43390885",
    RegisteredPopulation: "2507",
    VotingStationName: "RINGINI COMMUNITY HALL",
    Address: "2090 MAIN ROAD  CLERMONT  ETHEKWINI",
    Latitude: "-29.7968",
    Longitude: "30.9012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9786",
    WardLookupId: "3339",
    VDNumber: "43380086",
    RegisteredPopulation: "757",
    VotingStationName: "UMBOGINTWINI PRIMARY SCHOOL",
    Address: "9 PRINCE STREET  UMBOGINTWINI  ETHEKWINI",
    Latitude: "-30.019",
    Longitude: "30.9148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9787",
    WardLookupId: "3339",
    VDNumber: "43380097",
    RegisteredPopulation: "1963",
    VotingStationName: "KUSWAG PRIMARY SCHOOL",
    Address: "5 ASHGATE AMANZIMTOTI  AMANZIMTOTI  ETHEKWINI",
    Latitude: "-30.0419",
    Longitude: "30.8912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9788",
    WardLookupId: "3339",
    VDNumber: "43380154",
    RegisteredPopulation: "4748",
    VotingStationName: "EZIMBOKODWENI COMMUNITY HALL",
    Address: "M37 ALONG WANDA CELE ROAD   KWAMAKHUTHA  ETHEKWINI",
    Latitude: "-30.0157",
    Longitude: "30.887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9789",
    WardLookupId: "3339",
    VDNumber: "43380198",
    RegisteredPopulation: "3691",
    VotingStationName: "KUSAKUSA JUNIOR PRIMARY SCHOOL",
    Address: "5 CORNER OF GATE E  UMBUMBULU  ETHEKWINI",
    Latitude: "-30.0139",
    Longitude: "30.8943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9790",
    WardLookupId: "3339",
    VDNumber: "43380200",
    RegisteredPopulation: "2286",
    VotingStationName: "ATHLONE PARK LIBRARY",
    Address: "4 WARNER ROAD  AMANZIMTOTI  ETHEKWINI",
    Latitude: "-30.0178",
    Longitude: "30.9195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9791",
    WardLookupId: "3339",
    VDNumber: "43583575",
    RegisteredPopulation: "1604",
    VotingStationName: "SEWULA PRIMARY SCHOOL",
    Address: "SEWULA ROAD  UMBUMBULU  ETHEKWINI",
    Latitude: "-30.0399",
    Longitude: "30.8677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9792",
    WardLookupId: "3339",
    VDNumber: "43584093",
    RegisteredPopulation: "2261",
    VotingStationName: "ZIKO COMMUNITY HALL",
    Address: "OFF M3ZIKO ROAD  KWAMAKHUTHA TOWNSHIP   ETHEKWINI",
    Latitude: "-30.0339",
    Longitude: "30.877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9793",
    WardLookupId: "3340",
    VDNumber: "43380109",
    RegisteredPopulation: "3190",
    VotingStationName: "YIBONI JUNIOR PRIMARY SCHOOL",
    Address: "2424 KWAMAKHUTHU T/SHOP  AMANZIMTOTI  ETHEKWINI",
    Latitude: "-30.0306",
    Longitude: "30.8532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9794",
    WardLookupId: "3340",
    VDNumber: "43380110",
    RegisteredPopulation: "2516",
    VotingStationName: "EGUGWINI HIGHER PRIMARY SCHOOL",
    Address: "50028 KWA MAKHUTHA TOWNSHIP  AMANZIMTOTI  ETHEKWINI",
    Latitude: "-30.03",
    Longitude: "30.8639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9795",
    WardLookupId: "3340",
    VDNumber: "43380121",
    RegisteredPopulation: "1994",
    VotingStationName: "HABIYANA JUNIOR PRIMARY SCHOOL",
    Address: "2380 KWAMAKHUTHA  AMANZIMTOTI 4126  ETHEKWINI",
    Latitude: "-30.0262",
    Longitude: "30.8684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9796",
    WardLookupId: "3340",
    VDNumber: "43380132",
    RegisteredPopulation: "3101",
    VotingStationName: "KWAMAKHUTHA COMPREHENSIVE HIGH SCHOOL",
    Address: "2369 KWAMAKHUTHA LOCATION  AMANZIMTOTI  ETHEKWINI",
    Latitude: "-30.0209",
    Longitude: "30.8667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9797",
    WardLookupId: "3340",
    VDNumber: "43580369",
    RegisteredPopulation: "1128",
    VotingStationName: "SOMPUKANE SECONDARY SCHOOL",
    Address: "KWANKONKA  UMBUMBULU  ETHEKWINI",
    Latitude: "-30.0037",
    Longitude: "30.8571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9798",
    WardLookupId: "3340",
    VDNumber: "43580482",
    RegisteredPopulation: "2679",
    VotingStationName: "KUHLEKWETHU SCHOOL",
    Address: "56 ENSIMBINI  ISIPINGO  ETHEKWINI",
    Latitude: "-30.001",
    Longitude: "30.859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9799",
    WardLookupId: "3340",
    VDNumber: "43580493",
    RegisteredPopulation: "656",
    VotingStationName: "EMASOMINI COMMUNITY HALL",
    Address: "830 EMKHAZINI  UMBUMBULU  ETHEKWINI",
    Latitude: "-30.0126",
    Longitude: "30.8646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9800",
    WardLookupId: "3340",
    VDNumber: "43582002",
    RegisteredPopulation: "1792",
    VotingStationName: "JAJA SCHOOL",
    Address: "EMKHAZIMI  KWAMAKHUTHA  ETHEKWINI",
    Latitude: "-30.0245",
    Longitude: "30.8588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9801",
    WardLookupId: "3340",
    VDNumber: "43584611",
    RegisteredPopulation: "1103",
    VotingStationName: "FREE CHRISTIAN CHURCH",
    Address: "PHOLAZI  FOLWENI  SOBONAKHONA",
    Latitude: "-30.0048",
    Longitude: "30.8696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9802",
    WardLookupId: "3341",
    VDNumber: "43380143",
    RegisteredPopulation: "3374",
    VotingStationName: "NTWENHLE SENIOR SECONDARY SCHOOL",
    Address: "C1723 GOLOKODO AREA  FOLWENI TOWNSHIP  ETHEKWINI",
    Latitude: "-30.001",
    Longitude: "30.8365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9803",
    WardLookupId: "3341",
    VDNumber: "43380165",
    RegisteredPopulation: "2527",
    VotingStationName: "FOLWENI COMMUNITY HALL",
    Address: "NEAR POLICE STATION  FOLWENI  ETHEKWINI",
    Latitude: "-29.9918",
    Longitude: "30.8127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9804",
    WardLookupId: "3341",
    VDNumber: "43380176",
    RegisteredPopulation: "4475",
    VotingStationName: "MKLOMELO JUNIOR PRIMARY SCHOOL",
    Address: "613 B  FOLWENI TOWNSHIP  ETHEKWINI",
    Latitude: "-29.9917",
    Longitude: "30.8225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9805",
    WardLookupId: "3341",
    VDNumber: "43380299",
    RegisteredPopulation: "2422",
    VotingStationName: "SIPHEPHELO JUNIOR SECONDARY SCHOOL",
    Address: "A SECTION  FOLWENI  ETHEKWINI",
    Latitude: "-29.9923",
    Longitude: "30.8167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9806",
    WardLookupId: "3341",
    VDNumber: "43380334",
    RegisteredPopulation: "3430",
    VotingStationName: "RED CROSS COMMUNITY HALL",
    Address: "UNIT B LULU ROAD  FOLWENI  ETHEKWINI",
    Latitude: "-29.9954",
    Longitude: "30.8293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9807",
    WardLookupId: "3341",
    VDNumber: "43380378",
    RegisteredPopulation: "792",
    VotingStationName: "METHODIST CHURCH (KWADODOZA)",
    Address: "KWADODOZA  FOLWENI  SOBONAKHONA",
    Latitude: "-29.9902",
    Longitude: "30.8337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9808",
    WardLookupId: "3341",
    VDNumber: "43580347",
    RegisteredPopulation: "3193",
    VotingStationName: "QHOSHEYIPHETHE PRIMARY SCHOOL",
    Address: "800 UMBUMBULU  SUNDWINI  ETHEKWINI",
    Latitude: "-29.9817",
    Longitude: "30.8421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9809",
    WardLookupId: "3342",
    VDNumber: "43580437",
    RegisteredPopulation: "1316",
    VotingStationName: "EMANGADINI COMBINED PRIMARY SCHOOL",
    Address: "D991 EZINYATHINI  UMBUMBULU  ETHEKWINI",
    Latitude: "-29.9973",
    Longitude: "30.7371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9810",
    WardLookupId: "3342",
    VDNumber: "43580448",
    RegisteredPopulation: "1125",
    VotingStationName: "BANGUBUKHOSI SCHOOL",
    Address: "UMVUZANE RESERVE  UMBUMBULU  ETHEKWINI",
    Latitude: "-29.9986",
    Longitude: "30.7014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9811",
    WardLookupId: "3342",
    VDNumber: "43580459",
    RegisteredPopulation: "662",
    VotingStationName: "UMBUMBULU HALL",
    Address: "20421 ROAD P729  UMBUMBULU  ETHEKWINI",
    Latitude: "-29.9779",
    Longitude: "30.6976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9812",
    WardLookupId: "3342",
    VDNumber: "43580471",
    RegisteredPopulation: "1030",
    VotingStationName: "LUGOBE HIGH SCHOOL",
    Address: "INTIYANE RESERVE  UMBUMBULU  ETHEKWINI",
    Latitude: "-29.9842",
    Longitude: "30.7447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9813",
    WardLookupId: "3342",
    VDNumber: "43580516",
    RegisteredPopulation: "2337",
    VotingStationName: "EMPUSHENI COMMUNITY HALL",
    Address: "MPHUSHENI  UMBUMBULU 590  ETHEKWINI",
    Latitude: "-29.9869",
    Longitude: "30.7993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9814",
    WardLookupId: "3342",
    VDNumber: "43580527",
    RegisteredPopulation: "1631",
    VotingStationName: "MASUKU PRIMARY SCHOOL",
    Address: "M35 FOLWENI AREA  UMBUMBULU 580  ETHEKWINI",
    Latitude: "-29.9855",
    Longitude: "30.8224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9815",
    WardLookupId: "3342",
    VDNumber: "43580606",
    RegisteredPopulation: "783",
    VotingStationName: "TOBI SENIOR PRIMARY SCHOOL",
    Address: "890 UMBUMBULU  ETHEKWINI",
    Latitude: "-29.9913",
    Longitude: "30.7173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9816",
    WardLookupId: "3342",
    VDNumber: "43583081",
    RegisteredPopulation: "1098",
    VotingStationName: "SOBONAKHONA TRIBAL COURT",
    Address: "SOBONAKHONA TRIBAL COURT  UMBUMBULU  ETHEKWINI",
    Latitude: "-29.9873",
    Longitude: "30.7035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9817",
    WardLookupId: "3342",
    VDNumber: "43583137",
    RegisteredPopulation: "841",
    VotingStationName: "KHAYELIHLE HIGH SCHOOL",
    Address: "N/A MBUMBULU MAIN RD  UMBUMBULU ( NUNGWANE )  OGAGWINI",
    Latitude: "-29.9902",
    Longitude: "30.6575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9818",
    WardLookupId: "3342",
    VDNumber: "43583216",
    RegisteredPopulation: "971",
    VotingStationName: "SAWPITS PRIMARY SCHOOL",
    Address: "D994 SWAPITS ROAD  UMBUMBULU 4105  ETHEKWINI",
    Latitude: "-29.9996",
    Longitude: "30.7859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9819",
    WardLookupId: "3342",
    VDNumber: "43583272",
    RegisteredPopulation: "1573",
    VotingStationName: "ZAMAKAHLE HIGH SCHOOL",
    Address: "NKANYISWENI  UMBUMBULU 520  ETHEKWINI",
    Latitude: "-30.0223",
    Longitude: "30.79",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9820",
    WardLookupId: "3342",
    VDNumber: "43583654",
    RegisteredPopulation: "564",
    VotingStationName: "NGONGOMISA SCHOOL",
    Address: "970 NGONGOMISA  UMBUMBULU  ETHEKWINI",
    Latitude: "-30.0121",
    Longitude: "30.7136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9821",
    WardLookupId: "3342",
    VDNumber: "43584059",
    RegisteredPopulation: "579",
    VotingStationName: "MBOVU PRIMARY SCHOOL",
    Address: "UMBUMBULU  ETHEKWINI",
    Latitude: "-30.0092",
    Longitude: "30.6785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9822",
    WardLookupId: "3342",
    VDNumber: "43584060",
    RegisteredPopulation: "1666",
    VotingStationName: "AFRICAN GOSPEL CHURCH",
    Address: "ADAMS ROAD  ADAMS  ETHEKWINI",
    Latitude: "-30.0435",
    Longitude: "30.8047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9823",
    WardLookupId: "3342",
    VDNumber: "43584071",
    RegisteredPopulation: "1605",
    VotingStationName: "ISOLEMPILO CLINIC",
    Address: "MBUMBULU  UMBUMBULU  ETHEKWINI",
    Latitude: "-30.0477",
    Longitude: "30.8193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9824",
    WardLookupId: "3342",
    VDNumber: "43584082",
    RegisteredPopulation: "949",
    VotingStationName: "ST MARYS ANGLICAN CHURCH",
    Address: "UMBUMBULU  ETHEKWINI",
    Latitude: "-29.9889",
    Longitude: "30.7657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9825",
    WardLookupId: "3342",
    VDNumber: "43584655",
    RegisteredPopulation: "199",
    VotingStationName: "FELOKWAKHE MKHIZE(EZIGENI)",
    Address: "EZIGENI  UMBUMBULU  ESIMAHLENI",
    Latitude: "-30.0181",
    Longitude: "30.6456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9826",
    WardLookupId: "3342",
    VDNumber: "43584666",
    RegisteredPopulation: "476",
    VotingStationName: "UCC MPUMALANGA CHURCH",
    Address: "ADAMS EDIPHINI  UMBUMBULU  SOBONAKHONA",
    Latitude: "-30.0059",
    Longitude: "30.8005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9827",
    WardLookupId: "3343",
    VDNumber: "43380020",
    RegisteredPopulation: "4170",
    VotingStationName: "WARNER BEACH JUNIOR PRIMARY SCHOOL",
    Address: "13 RADBOURNE RD  WARNER BEACH  ETHEKWINI",
    Latitude: "-30.0851",
    Longitude: "30.8616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9828",
    WardLookupId: "3343",
    VDNumber: "43380042",
    RegisteredPopulation: "2903",
    VotingStationName: "AMANZIMTOTI LIONS CLUB",
    Address: "4 HULL ROAD  WARNER BEACH  AMANZIMTOTI",
    Latitude: "-30.0831",
    Longitude: "30.8666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9829",
    WardLookupId: "3343",
    VDNumber: "43380053",
    RegisteredPopulation: "2597",
    VotingStationName: "DOON HEIGHTS PRIMARY SCHOOL",
    Address: "66 LONGACRES RD  KINGSBURGH  ETHEKWINI",
    Latitude: "-30.0672",
    Longitude: "30.8605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9830",
    WardLookupId: "3343",
    VDNumber: "43380064",
    RegisteredPopulation: "6269",
    VotingStationName: "AMANZIMTOTI CIVIC-CENTRE",
    Address: "HUTCHINSON ROAD  AMANZIMTOTI  ETHEKWINI",
    Latitude: "-30.0585",
    Longitude: "30.8756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9831",
    WardLookupId: "3344",
    VDNumber: "43580167",
    RegisteredPopulation: "3339",
    VotingStationName: "UNITED CONGREGATIONAL CHURCH",
    Address: "ILFRACOMBE AREA   UMGABABA  DURBAN",
    Latitude: "-30.1819",
    Longitude: "30.8082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9832",
    WardLookupId: "3344",
    VDNumber: "43580178",
    RegisteredPopulation: "1763",
    VotingStationName: "SIDIYA PRIMARY SCHOOL",
    Address: "EHLANZENI AREA   KWALUTHULI TC   ETHEKWINI",
    Latitude: "-30.1358",
    Longitude: "30.7927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9833",
    WardLookupId: "3344",
    VDNumber: "43580189",
    RegisteredPopulation: "2722",
    VotingStationName: "THEMBENI CRECHE",
    Address: "AREA 7 UMGABABA  UMGABABA AREA   ETHEKWINI",
    Latitude: "-30.1479",
    Longitude: "30.8315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9834",
    WardLookupId: "3344",
    VDNumber: "43580235",
    RegisteredPopulation: "1445",
    VotingStationName: "MASHIWASE SCHOOL",
    Address: "MASHIWASE AREA   KWAHLENGWA TC   UMBUMBULU",
    Latitude: "-30.1045",
    Longitude: "30.7875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9835",
    WardLookupId: "3344",
    VDNumber: "43580291",
    RegisteredPopulation: "2749",
    VotingStationName: "AMAGCINO PRIMARY SCHOOL",
    Address: "43 ILLOVO BEACH  ILLOVO  ETHEKWINI",
    Latitude: "-30.1365",
    Longitude: "30.8213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9836",
    WardLookupId: "3344",
    VDNumber: "43580314",
    RegisteredPopulation: "1465",
    VotingStationName: "UMGABABA PRIMARY SCHOOL",
    Address: "SACCO RD. NEXT TO P/STATION  UMGABABA  ETHEKWINI",
    Latitude: "-30.1522",
    Longitude: "30.8146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9837",
    WardLookupId: "3344",
    VDNumber: "43583283",
    RegisteredPopulation: "2137",
    VotingStationName: "MNGANIWAKHE HIGH SCHOOL",
    Address: "AREA 24 SAICO  UMGABABA  ETHEKWINI",
    Latitude: "-30.141",
    Longitude: "30.8193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9838",
    WardLookupId: "3344",
    VDNumber: "43583294",
    RegisteredPopulation: "1627",
    VotingStationName: "EHLANZENI HALL",
    Address: "OPP MAGABENI LOCATION, UMKOMAA  EHLANZENI  ETHEKWINI",
    Latitude: "-30.1689",
    Longitude: "30.7889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9839",
    WardLookupId: "3344",
    VDNumber: "43583317",
    RegisteredPopulation: "730",
    VotingStationName: "KWASONI PRIMARY SCHOOL",
    Address: "NKWAI   ESIQHINGINI   UMBUMBULU",
    Latitude: "-30.0859",
    Longitude: "30.8218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9840",
    WardLookupId: "3345",
    VDNumber: "43380312",
    RegisteredPopulation: "3092",
    VotingStationName: "MAGABHENI COMMUNITY HALL",
    Address: "1059 MAGABHENI  UMKOMAAS  ETHEKWINI",
    Latitude: "-30.1685",
    Longitude: "30.7657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9841",
    WardLookupId: "3345",
    VDNumber: "43380389",
    RegisteredPopulation: "238",
    VotingStationName: "NKANGALA TRIBAL AUTHORITY HALL",
    Address: "NKANGALA AREA   UMKOMAAS   THOYANA TC",
    Latitude: "-30.1575",
    Longitude: "30.7528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9842",
    WardLookupId: "3345",
    VDNumber: "43580808",
    RegisteredPopulation: "1157",
    VotingStationName: "DLAMBULA PRIMARY SCHOOL",
    Address: "OFF N2 ON MAGABHENI RAOD   EMAGABHENI   ETHEKWINI",
    Latitude: "-30.1819",
    Longitude: "30.7843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9843",
    WardLookupId: "3345",
    VDNumber: "43583328",
    RegisteredPopulation: "1224",
    VotingStationName: "OPHAPHENI PRIMARY SCHOOL",
    Address: "VIA KWAMEHLO TEA ROOM.  KWAMABUZA EMAGABHENI   ETHEKWINI",
    Latitude: "-30.1653",
    Longitude: "30.7747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9844",
    WardLookupId: "3345",
    VDNumber: "43990454",
    RegisteredPopulation: "274",
    VotingStationName: "CROWDER WATER OFFICE",
    Address: "CROWDER AREA  UMKOMAAS   KWAQADI TC",
    Latitude: "-30.1943",
    Longitude: "30.7096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9845",
    WardLookupId: "3345",
    VDNumber: "44070038",
    RegisteredPopulation: "3964",
    VotingStationName: "NAIDOO MEMORIAL PRIMARY SCHOOL",
    Address: "OLD MAIN ROAD, ROSENEATH   UMKOMAAS   CRAIGIEBURN",
    Latitude: "-30.2016",
    Longitude: "30.75",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9846",
    WardLookupId: "3345",
    VDNumber: "44070049",
    RegisteredPopulation: "1236",
    VotingStationName: "AFRICAN PENTECOSTAL CHURCH HALL",
    Address: "AMAHLONGWA AREA   UMKOMAAS   KWAMQADI TRIBAL AUTHORITY",
    Latitude: "-30.2197",
    Longitude: "30.7247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9847",
    WardLookupId: "3345",
    VDNumber: "44070050",
    RegisteredPopulation: "1981",
    VotingStationName: "UMKOMAAS TOWN HALL",
    Address: "BARROW STREET  UMKOMAAS  ETHEKWINI",
    Latitude: "-30.2055",
    Longitude: "30.798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9848",
    WardLookupId: "3345",
    VDNumber: "44070061",
    RegisteredPopulation: "2019",
    VotingStationName: "NAIDOOVILLE PRIMARY SCHOOL",
    Address: "1 CRAIGIEBURN DRIVE  CRAIGIEBURN  ETHEKWINI",
    Latitude: "-30.2073",
    Longitude: "30.7698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9849",
    WardLookupId: "3345",
    VDNumber: "44070072",
    RegisteredPopulation: "763",
    VotingStationName: "EBENHEZER",
    Address:
      "OPPOSITE ENGEN GARAGE  UMKOMAAS   (ETH - ETHEKWINI [DURBAN METRO])",
    Latitude: "-30.1932",
    Longitude: "30.7477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9850",
    WardLookupId: "3346",
    VDNumber: "43580460",
    RegisteredPopulation: "507",
    VotingStationName: "PUTELLOS PRIMARY SCHOOL",
    Address: "603 EMPENDWINI RD  UMBUMBULU  ETHEKWINI",
    Latitude: "-29.9825",
    Longitude: "30.6576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9851",
    WardLookupId: "3346",
    VDNumber: "43580538",
    RegisteredPopulation: "867",
    VotingStationName: "NOMAVIMBELA HIGH SCHOOL",
    Address: "ENGONYAMENI  RUREAL AREA  ETHEKWINI",
    Latitude: "-29.9526",
    Longitude: "30.7669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9852",
    WardLookupId: "3346",
    VDNumber: "43580549",
    RegisteredPopulation: "915",
    VotingStationName: "SIKHWAMA HIGH SCHOOL",
    Address: "ISIKHWAMA HIGH SCHOOL  ENGONYAMENI (RURAL AREA)  ETHEKWINI",
    Latitude: "-29.9484",
    Longitude: "30.8022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9853",
    WardLookupId: "3346",
    VDNumber: "43580561",
    RegisteredPopulation: "763",
    VotingStationName: "TAFELKOP PRIMARY SCHOOL",
    Address: "VUMENGAZI AUTHORITY  UMBUMBULU  ETHEKWINI",
    Latitude: "-29.9252",
    Longitude: "30.7781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9854",
    WardLookupId: "3346",
    VDNumber: "43580572",
    RegisteredPopulation: "1629",
    VotingStationName: "GEZANGANI COMBINED PRIMARY SCHOOL",
    Address: "UMBUMBULU 740  ETHEKWINI",
    Latitude: "-29.9031",
    Longitude: "30.8005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9855",
    WardLookupId: "3346",
    VDNumber: "43580583",
    RegisteredPopulation: "2207",
    VotingStationName: "WILIWILI PRIMARY SCHOOL",
    Address: "BEHIND NDULIS STORE  ZWELIBOMVU  ETHEKWINI",
    Latitude: "-29.8787",
    Longitude: "30.7448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9856",
    WardLookupId: "3346",
    VDNumber: "43580594",
    RegisteredPopulation: "965",
    VotingStationName: "ZWELIBOMVU SCHOOL",
    Address: "680 UMBUMBULU OLD RICHMOND RD  ZWELIBOMVU,DASSENHOEK  ETHEKWINI",
    Latitude: "-29.8701",
    Longitude: "30.7745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9857",
    WardLookupId: "3346",
    VDNumber: "43580617",
    RegisteredPopulation: "2260",
    VotingStationName: "OLWAMBENI PRIMARY SCHOOL",
    Address: "ZWELIBOMVU MAIN ROAD  ZWELIBOMVU AREA  VUMAZONKE TC",
    Latitude: "-29.8836",
    Longitude: "30.7103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9858",
    WardLookupId: "3346",
    VDNumber: "43583092",
    RegisteredPopulation: "531",
    VotingStationName: "NDLANDLAMA PRIMARY SCHOOL",
    Address: "INDLANDLAMU  UMBUMBULU 670  ETHEKWINI",
    Latitude: "-29.939",
    Longitude: "30.7413",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9859",
    WardLookupId: "3346",
    VDNumber: "43583115",
    RegisteredPopulation: "1318",
    VotingStationName: "MBUYAZI PRIMARY SCHOOL",
    Address: "ZWELIBOMVU  PINETOWN  VUMAZONKE TRIBAL COURT",
    Latitude: "-29.9035",
    Longitude: "30.7513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9860",
    WardLookupId: "3346",
    VDNumber: "43583126",
    RegisteredPopulation: "771",
    VotingStationName: "NQOBANE PRIMARY SCHOOL",
    Address:
      "NKOSENYE ROAD,EMANGANGENI AREA,NEAR VUMAZONKE TRIB  PINETOWN  ETHEKWINI",
    Latitude: "-29.8721",
    Longitude: "30.7792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9861",
    WardLookupId: "3346",
    VDNumber: "43583148",
    RegisteredPopulation: "366",
    VotingStationName: "BRIGDEMAN PRIMARY SCHOOL",
    Address: "BRIGDEMAN AREA  UMBUMBULU 4105  EMBOTHIMUNI",
    Latitude: "-29.9495",
    Longitude: "30.6978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9862",
    WardLookupId: "3346",
    VDNumber: "43583159",
    RegisteredPopulation: "328",
    VotingStationName: "ISISUSA SECONDARY SCHOOL",
    Address: "NKOMOKAZI RESERVE  UMBUMBULU  ETHEKWINI",
    Latitude: "-29.9399",
    Longitude: "30.6951",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9863",
    WardLookupId: "3346",
    VDNumber: "43583160",
    RegisteredPopulation: "640",
    VotingStationName: "VUMELANI CRECHE",
    Address: "EMANGANGENI AREA(ADJ TO ZWELIBOMVU AREA)  DASSENHOEK  ETHEKWINI",
    Latitude: "-29.8809",
    Longitude: "30.7678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9864",
    WardLookupId: "3346",
    VDNumber: "43583182",
    RegisteredPopulation: "718",
    VotingStationName: "DUZE PRIMARY SCHOOL",
    Address: "ETSHENI VILLAGE  UMBUMBULU 4105  ETHEKWINI",
    Latitude: "-29.9739",
    Longitude: "30.7226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9865",
    WardLookupId: "3346",
    VDNumber: "43583227",
    RegisteredPopulation: "612",
    VotingStationName: "ISINKONTSHE COMBINED PRIMARY SCHOOL",
    Address: "EHLOUZENI  UMLAZI  ETHEKWINI",
    Latitude: "-29.9334",
    Longitude: "30.8392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9866",
    WardLookupId: "3346",
    VDNumber: "43583610",
    RegisteredPopulation: "1244",
    VotingStationName: "VUMENGAZI TRIBAL COURT",
    Address: "VUMENGAZI TRIBAL COURT  UMBUMBULU 760  ETHEKWINI",
    Latitude: "-29.9391",
    Longitude: "30.802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9867",
    WardLookupId: "3346",
    VDNumber: "43583676",
    RegisteredPopulation: "255",
    VotingStationName: "MNTSHINGWANA COMBINED PRIMARY SCHOOL",
    Address: "TAFELKOP  UMBUMBULU  ETHEKWINI",
    Latitude: "-29.9132",
    Longitude: "30.741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9868",
    WardLookupId: "3346",
    VDNumber: "43583698",
    RegisteredPopulation: "2006",
    VotingStationName: "MATHOLE COMMUNITY HALL",
    Address: "VUMENGAZI,NGONYAMENI  UMLAZI  ETHEKWINI",
    Latitude: "-29.9465",
    Longitude: "30.8293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9869",
    WardLookupId: "3346",
    VDNumber: "43583823",
    RegisteredPopulation: "754",
    VotingStationName: "SIBUKEYANA PRIMARY SCHOOL",
    Address: "MADUNDUBU AREA  UMBUMBULU  ETHEKWINI",
    Latitude: "-29.9622",
    Longitude: "30.7471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9870",
    WardLookupId: "3346",
    VDNumber: "43772597",
    RegisteredPopulation: "1013",
    VotingStationName: "CLIFTON HEIGHTS STORE",
    Address: "TONIE ROAD  ZWELIBOMVU  ETHEKWINI",
    Latitude: "-29.8957",
    Longitude: "30.6843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9871",
    WardLookupId: "3347",
    VDNumber: "43371378",
    RegisteredPopulation: "3610",
    VotingStationName: "WESTRIDGE PARK TENNIS STADIUM",
    Address: "100 JAN SMUTS HIGHWAY  MAYVILLE  ETHEKWINI",
    Latitude: "-29.8497",
    Longitude: "30.9862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9872",
    WardLookupId: "3347",
    VDNumber: "43371390",
    RegisteredPopulation: "6016",
    VotingStationName: "MAYVILLE SECONDARY SCHOOL",
    Address: "19 GRAMMAR STREET  MAYVILLE  DURBAN",
    Latitude: "-29.8555",
    Longitude: "30.9737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9873",
    WardLookupId: "3347",
    VDNumber: "43371468",
    RegisteredPopulation: "2659",
    VotingStationName: "UNIVERSITY OF NATAL SPORTS FIELD",
    Address: "OFF FRANCOIS ROAD  DURBAN  ETHEKWINI",
    Latitude: "-29.8664",
    Longitude: "30.9738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9874",
    WardLookupId: "3347",
    VDNumber: "43371738",
    RegisteredPopulation: "1447",
    VotingStationName: "CARRINGTON HEIGHTS PRIMARY SCHOOL",
    Address: "52 MOUNTAIN RISE  CARRINGTON HEIGHTS  ETHEKWINI",
    Latitude: "-29.8823",
    Longitude: "30.9673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9875",
    WardLookupId: "3347",
    VDNumber: "43372032",
    RegisteredPopulation: "2417",
    VotingStationName: "WESTRIDGE HIGH SCHOOL",
    Address: "189 JAN SMUTS AVE.  MAYVILLE  ETHEKWINI",
    Latitude: "-29.8503",
    Longitude: "30.9834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9876",
    WardLookupId: "3348",
    VDNumber: "43350049",
    RegisteredPopulation: "3624",
    VotingStationName: "SHREE EMPERUMAL TEMPLE SOCIETY",
    Address: "197 FLANDERS DRIVE  MOUNT EDGECOMBE  ETHEKWINI",
    Latitude: "-29.7068",
    Longitude: "31.0381",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9877",
    WardLookupId: "3348",
    VDNumber: "43350072",
    RegisteredPopulation: "1926",
    VotingStationName: "TRENANCE PARK SECONDARY SCHOOL",
    Address: "1 TRENANCE DRIVE  TRENANCE PARK, VERULAM  ETHEKWINI",
    Latitude: "-29.6555",
    Longitude: "31.0085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9878",
    WardLookupId: "3348",
    VDNumber: "43350083",
    RegisteredPopulation: "5662",
    VotingStationName: "ZWELITSHA COMMUNITY HALL",
    Address: "LINK ROAD TRENANCE PARK  VERULAM  ETHEKWINI",
    Latitude: "-29.6673",
    Longitude: "31.012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9879",
    WardLookupId: "3348",
    VDNumber: "43350386",
    RegisteredPopulation: "1871",
    VotingStationName: "OTTAWA CIVIC CENTRE",
    Address: "44 MAHARAJ ROAD  OTTAWA  ETHEKWINI",
    Latitude: "-29.6705",
    Longitude: "31.033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9880",
    WardLookupId: "3348",
    VDNumber: "43350476",
    RegisteredPopulation: "3358",
    VotingStationName: "PARKGATE PRIMARY SCHOOL",
    Address: "24 SCHOOL ROAD  PARKGATE  VERULAM",
    Latitude: "-29.6608",
    Longitude: "31.0333",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9881",
    WardLookupId: "3348",
    VDNumber: "43350487",
    RegisteredPopulation: "701",
    VotingStationName: "AT THE CROSS MINISTRIES",
    Address: "15 RIET ROAD  RIET RIVER, VERULAM  ETHEKWINI",
    Latitude: "-29.6639",
    Longitude: "31.0243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9882",
    WardLookupId: "3348",
    VDNumber: "43350555",
    RegisteredPopulation: "2802",
    VotingStationName: "SOLOMON MAHLANGU PRIMARY SCHOOL",
    Address: "CORNUBIA  OTTAWA SOUTH  PHOENIX",
    Latitude: "-29.6882",
    Longitude: "31.0459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9883",
    WardLookupId: "3349",
    VDNumber: "43400102",
    RegisteredPopulation: "2404",
    VotingStationName: "BOTHAS HILL TOURISM OFFICE",
    Address: "1 OLD MAIN ROAD  BOTHAS HILL  ETHEKWINI",
    Latitude: "-29.752",
    Longitude: "30.7406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9884",
    WardLookupId: "3349",
    VDNumber: "43400472",
    RegisteredPopulation: "3108",
    VotingStationName: "TEMPORARY VOTING STATION (POLO PONY FIELDS)",
    Address: "ASSAGAY ROAD  ASSAGAY  ETHEKWINI",
    Latitude: "-29.7957",
    Longitude: "30.7424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9885",
    WardLookupId: "3349",
    VDNumber: "43400494",
    RegisteredPopulation: "778",
    VotingStationName: "THOUSAND HILLS HOTEL",
    Address: "OLD MAIN ROAD, CATO RIDGE 3680  DRUMMOND  DRUMMOND",
    Latitude: "-29.7478",
    Longitude: "30.7",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9886",
    WardLookupId: "3349",
    VDNumber: "43400506",
    RegisteredPopulation: "1630",
    VotingStationName: "EMPILWENI PRIMARY SCHOOL",
    Address: "ZULU RESERVE ROAD  KWANYUSWA BOTHA`S HILL  QADI TRIBAL AUTHORITY",
    Latitude: "-29.7377",
    Longitude: "30.7367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9887",
    WardLookupId: "3349",
    VDNumber: "43400652",
    RegisteredPopulation: "1524",
    VotingStationName: "UMTHOMBOMUHLE PRIMARY SCHOOL",
    Address: "116 UMTHOMBOMUHLE ROAD; CLIFFDALE   CLIFFDALE  HAMMARSDALE",
    Latitude: "-29.7878",
    Longitude: "30.6824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9888",
    WardLookupId: "3349",
    VDNumber: "43400663",
    RegisteredPopulation: "1044",
    VotingStationName: "HOUSE OF PRAYER",
    Address: "BOX 1670  HILLCREST  ETHEKWINI",
    Latitude: "-29.8132",
    Longitude: "30.6921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9889",
    WardLookupId: "3349",
    VDNumber: "43580651",
    RegisteredPopulation: "733",
    VotingStationName: "GWADU-ZENEX PRIMARY SCHOOL",
    Address: "BERNARES RD  EMBO  ETHEKWINI",
    Latitude: "-29.7425",
    Longitude: "30.7623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9890",
    WardLookupId: "3349",
    VDNumber: "43582877",
    RegisteredPopulation: "1497",
    VotingStationName: "MNAMATHA SCHOOL",
    Address: "MNAMATHA ROAD  NYUSWA RESERVE  ETHEKWINI",
    Latitude: "-29.7161",
    Longitude: "30.7203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9891",
    WardLookupId: "3349",
    VDNumber: "43582888",
    RegisteredPopulation: "1945",
    VotingStationName: "BOTHAS HILL SCHOOL",
    Address: "ZULU RESERVE ROAD  BOTHA`S HILL  KWANYUSWA",
    Latitude: "-29.7194",
    Longitude: "30.7387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9892",
    WardLookupId: "3349",
    VDNumber: "43583744",
    RegisteredPopulation: "2035",
    VotingStationName: "ST LAWRENCE PRIMARY SCHOOL",
    Address: "NEAR VULINDLELA STORE  NYUSWA  ETHEKWINI",
    Latitude: "-29.7034",
    Longitude: "30.742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9893",
    WardLookupId: "3349",
    VDNumber: "43583980",
    RegisteredPopulation: "1055",
    VotingStationName: "ELANGENI FET (QADI CAMPUS)",
    Address:
      "ZULU RESERVE ROAD  KWANYUSWA, NEAR QADI COMMUNITY HALL  DADI TRIBAL AUTHORITY",
    Latitude: "-29.7329",
    Longitude: "30.7354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9894",
    WardLookupId: "3350",
    VDNumber: "43360703",
    RegisteredPopulation: "4635",
    VotingStationName: "ETHEKWINI JUNIOR PRIMARY SCHOOL",
    Address: "B212 SIKHINDI ROAD  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7491",
    Longitude: "30.9918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9895",
    WardLookupId: "3350",
    VDNumber: "43360848",
    RegisteredPopulation: "1690",
    VotingStationName: "THANDOKUHLE PRIMARY SCHOOL",
    Address: "J680 KLOLO ROAD  KWA - MASHU  ETHEKWINI",
    Latitude: "-29.7463",
    Longitude: "30.9648",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9896",
    WardLookupId: "3350",
    VDNumber: "43360859",
    RegisteredPopulation: "2073",
    VotingStationName: "NQABAKAZULU HIGH SCHOOL",
    Address: "P55 MKHIWANE ROAD  KWA - MASHU  ETHEKWINI",
    Latitude: "-29.7482",
    Longitude: "30.9667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9897",
    WardLookupId: "3350",
    VDNumber: "43360938",
    RegisteredPopulation: "2327",
    VotingStationName: "PHUTHUMANI JUNIOR PRIMARY SCHOOL",
    Address: "H591 BHEJANE ROAD  KWA MASHU  ETHEKWINI",
    Latitude: "-29.7418",
    Longitude: "30.9767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9898",
    WardLookupId: "3350",
    VDNumber: "43360983",
    RegisteredPopulation: "1877",
    VotingStationName: "UBUHLE SENIOR PRIMARY SCHOOL",
    Address: "G559 NDLONDLO ROAD  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7434",
    Longitude: "30.983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9899",
    WardLookupId: "3350",
    VDNumber: "43360994",
    RegisteredPopulation: "2300",
    VotingStationName: "DUMANI JUNIOR PRIMARY SCHOOL",
    Address: "G961 NDLONDLO  KWAMASHU  ETHEKWINI",
    Latitude: "-29.7438",
    Longitude: "30.9808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9900",
    WardLookupId: "3350",
    VDNumber: "43361995",
    RegisteredPopulation: "546",
    VotingStationName: "PHAPHAMANI JUNIOR PRIMARY SCHOOL",
    Address: "G366 DLOKWE ROAD  KWA - MASHU  ETHEKWINI",
    Latitude: "-29.7467",
    Longitude: "30.9835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9901",
    WardLookupId: "3351",
    VDNumber: "43580112",
    RegisteredPopulation: "328",
    VotingStationName: "ISULETHU SCHOOL",
    Address: "KWANDAYA, EMBO  UMBUMBULU",
    Latitude: "-30.1302",
    Longitude: "30.6415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9902",
    WardLookupId: "3351",
    VDNumber: "43580123",
    RegisteredPopulation: "207",
    VotingStationName: "THOKOZA GENERAL DEALER",
    Address: "KWANDAYA, EMBO,  UMBUMBULU",
    Latitude: "-30.1274",
    Longitude: "30.6699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9903",
    WardLookupId: "3351",
    VDNumber: "43580134",
    RegisteredPopulation: "349",
    VotingStationName: "NDAYA SCHOOL",
    Address: "KWANDAYA, EMBO  VULAMEHLO",
    Latitude: "-30.1093",
    Longitude: "30.6597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9904",
    WardLookupId: "3351",
    VDNumber: "43580145",
    RegisteredPopulation: "543",
    VotingStationName: "KWA-QUMBU SCHOOL",
    Address: "TOYANA, UMBUMBULU  UMBUMBULU",
    Latitude: "-30.0707",
    Longitude: "30.6755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9905",
    WardLookupId: "3351",
    VDNumber: "43580156",
    RegisteredPopulation: "582",
    VotingStationName: "MADUNDUBALA COMBINED PRIMARY SCHOOL",
    Address: "MGANGENI, EMBO  UMBUMBULU",
    Latitude: "-30.1441",
    Longitude: "30.709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9906",
    WardLookupId: "3351",
    VDNumber: "43580246",
    RegisteredPopulation: "965",
    VotingStationName: "MGENDWA COMMUNITY HALL",
    Address: "FAKAZI ROAD  KWAMGENDWA, MAPHUMULO T/A  VULAMEHLO",
    Latitude: "-30.0684",
    Longitude: "30.804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9907",
    WardLookupId: "3351",
    VDNumber: "43580257",
    RegisteredPopulation: "255",
    VotingStationName: "KWAJAKOBE SCHOOL",
    Address: "NTUKWINI, KWATHOYANA  UMBUMBULU",
    Latitude: "-30.0845",
    Longitude: "30.7181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9908",
    WardLookupId: "3351",
    VDNumber: "43580268",
    RegisteredPopulation: "718",
    VotingStationName: "ODIDINI SCHOOL",
    Address: "ODIDINI MAIN RD.  ODIDINI, THOYANA T/A  UMBUMBULU",
    Latitude: "-30.0325",
    Longitude: "30.6813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9909",
    WardLookupId: "3351",
    VDNumber: "43580280",
    RegisteredPopulation: "898",
    VotingStationName: "KWAPHIKAZIWE SCHOOL",
    Address: "MDUMEZULU  UMBUMBULU",
    Latitude: "-30.033",
    Longitude: "30.7374",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9910",
    WardLookupId: "3351",
    VDNumber: "43580415",
    RegisteredPopulation: "453",
    VotingStationName: "MAPHUMULO TRIBAL COURT",
    Address: "MDUMEZULU, MAPHUMULO T/A  UMBUMBULU",
    Latitude: "-30.0361",
    Longitude: "30.7702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9911",
    WardLookupId: "3351",
    VDNumber: "43580426",
    RegisteredPopulation: "368",
    VotingStationName: "ST TERESSA HALL",
    Address: "MAPHUMULO  UGU  MAPHUMULO TRIBAL AUTHORITY",
    Latitude: "-30.0408",
    Longitude: "30.7943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9912",
    WardLookupId: "3351",
    VDNumber: "43583306",
    RegisteredPopulation: "882",
    VotingStationName: "FAKAZI SCHOOL",
    Address: "FAKAZI, THOYANA T/A  UMBUMBULU",
    Latitude: "-30.0844",
    Longitude: "30.8048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9913",
    WardLookupId: "3351",
    VDNumber: "43583339",
    RegisteredPopulation: "1536",
    VotingStationName: "INGQALABUTHO SCHOOL",
    Address: "IMFUME MISSION  UMBUMBULU",
    Latitude: "-30.1216",
    Longitude: "30.7462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9914",
    WardLookupId: "3351",
    VDNumber: "43583351",
    RegisteredPopulation: "323",
    VotingStationName: "EGOLI SCHOOL",
    Address: "IMFUME, KWATHOYANA T/A  UMBUMBULU",
    Latitude: "-30.157",
    Longitude: "30.7388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9915",
    WardLookupId: "3351",
    VDNumber: "43583362",
    RegisteredPopulation: "512",
    VotingStationName: "EWUBWINI SCHOOL",
    Address: "EWUBWINI  EWUBWINI, THOYANA T/A  VULAMEHLO",
    Latitude: "-30.1155",
    Longitude: "30.7167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9916",
    WardLookupId: "3351",
    VDNumber: "43583384",
    RegisteredPopulation: "618",
    VotingStationName: "MPENDU SCHOOL",
    Address: "ECHOBENI  EMBO TRIBAL AREA  UMBUMBULU",
    Latitude: "-30.0956",
    Longitude: "30.6361",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9917",
    WardLookupId: "3351",
    VDNumber: "43583395",
    RegisteredPopulation: "1385",
    VotingStationName: "POWERS COURT PRIMARY SCHOOL",
    Address: "EZIMWENI (MAHLEKA) NEAR GCEWU   MBUMBULU  OGAGWINI",
    Latitude: "-30.0339",
    Longitude: "30.631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9918",
    WardLookupId: "3351",
    VDNumber: "43583485",
    RegisteredPopulation: "840",
    VotingStationName: "ITHENJANA SCHOOL",
    Address: "ITHENJANE, EMGANGENI  VULAMEHLO",
    Latitude: "-30.1165",
    Longitude: "30.6871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9919",
    WardLookupId: "3351",
    VDNumber: "43583496",
    RegisteredPopulation: "323",
    VotingStationName: "ITSHEHLOPE SCHOOL",
    Address: "ITSHEHLOPHE, MDUMEZULU, MAPUMULO T/A  UMBUMBULU",
    Latitude: "-30.0661",
    Longitude: "30.7722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9920",
    WardLookupId: "3351",
    VDNumber: "43583508",
    RegisteredPopulation: "740",
    VotingStationName: "INYONEMHLOPHE SCHOOL",
    Address: "MDUMEZULU  UMBUMBULU",
    Latitude: "-30.0537",
    Longitude: "30.7554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9921",
    WardLookupId: "3351",
    VDNumber: "43583564",
    RegisteredPopulation: "1558",
    VotingStationName: "ESIKHUKHUKHU COMBINED PRIMARY SCHOOL",
    Address: "N/A NEAR OGAGWINI TRIBAL AUTHORITY  UMBUMBULU 111  OGAGWINI",
    Latitude: "-30.0453",
    Longitude: "30.6625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9922",
    WardLookupId: "3351",
    VDNumber: "43583845",
    RegisteredPopulation: "465",
    VotingStationName: "NGOLELA COMMUNITY HALL",
    Address: "ECHOBENI, EMBO  VULAMEHLO",
    Latitude: "-30.1057",
    Longitude: "30.6142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9923",
    WardLookupId: "3351",
    VDNumber: "43584105",
    RegisteredPopulation: "311",
    VotingStationName: "AMANDLAKHE COMBINED PRIMARY SCHOOL",
    Address: "N/A EIPHAMBATHINI ( NUNGWANE )  MID-ILLOVO  OGAGWINI",
    Latitude: "-30.0878",
    Longitude: "30.6689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9924",
    WardLookupId: "3351",
    VDNumber: "43584116",
    RegisteredPopulation: "690",
    VotingStationName: "MASHASHA STORE",
    Address: "EGUDWINI, IMFUME  VULAMEHLO",
    Latitude: "-30.1144",
    Longitude: "30.7708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9925",
    WardLookupId: "3351",
    VDNumber: "43584138",
    RegisteredPopulation: "338",
    VotingStationName: "MAJOLA PRIMARY  SCHOOL",
    Address: "D982  HLUHLUWE, IMFUME  VULAMEHLO",
    Latitude: "-30.0992",
    Longitude: "30.7507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9926",
    WardLookupId: "3351",
    VDNumber: "43584183",
    RegisteredPopulation: "231",
    VotingStationName: "NDABAS  TENT",
    Address: "MGANGENI   VULAMEHLO RURAL",
    Latitude: "-30.1455",
    Longitude: "30.6931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9927",
    WardLookupId: "3351",
    VDNumber: "43584194",
    RegisteredPopulation: "315",
    VotingStationName: "MGOQOZI COMBINED PRIMARY SCHOOL",
    Address: "MGOQOZI, THOYANA  VULAMEHLO",
    Latitude: "-30.0514",
    Longitude: "30.7233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9928",
    WardLookupId: "3351",
    VDNumber: "43584251",
    RegisteredPopulation: "318",
    VotingStationName: "EMALEBUKENI SCHOOL",
    Address: "EMATSHEKETSHENI, IMFUME  VULAMEHLO",
    Latitude: "-30.1372",
    Longitude: "30.7358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9929",
    WardLookupId: "3352",
    VDNumber: "43350139",
    RegisteredPopulation: "1826",
    VotingStationName: "MOUNTHAVEN PRIMARY SCHOOL",
    Address: "2 MAGPIE ROAD, MOUNTVIEW  VERULAM  ETHEKWINI",
    Latitude: "-29.655",
    Longitude: "31.0367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9930",
    WardLookupId: "3352",
    VDNumber: "43350140",
    RegisteredPopulation: "1737",
    VotingStationName: "DAWNCREST PRIMARY SCHOOL",
    Address: "42 ROBIN STREET, DAWNCREST  VERULAM  ETHEKWINI",
    Latitude: "-29.6479",
    Longitude: "31.0211",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9931",
    WardLookupId: "3352",
    VDNumber: "43350151",
    RegisteredPopulation: "2806",
    VotingStationName: "TEMPLE VALLEY SECONDARY SCHOOL",
    Address: "PAKCO STREET  VERULAM  ETHEKWINI",
    Latitude: "-29.653",
    Longitude: "31.0508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9932",
    WardLookupId: "3352",
    VDNumber: "43350162",
    RegisteredPopulation: "1917",
    VotingStationName: "DIANTHUS PRIMARY SCHOOL",
    Address: "12 DIANTHUS ROAD, BRINDHAVEN  VERULAM  ETHEKWINI",
    Latitude: "-29.646",
    Longitude: "31.0371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9933",
    WardLookupId: "3352",
    VDNumber: "43350195",
    RegisteredPopulation: "378",
    VotingStationName: "UMHLOTI PRIMARY SCHOOL",
    Address: "BARRS FLAT ROAD  UMHLOTI  ETHEKWINI",
    Latitude: "-29.6443",
    Longitude: "31.0577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9934",
    WardLookupId: "3352",
    VDNumber: "43350353",
    RegisteredPopulation: "3739",
    VotingStationName: "WATERLOO PRIMARY SCHOOL",
    Address: "1 PYTHON ROAD, WATERLOO  VERULAM  ETHEKWINI",
    Latitude: "-29.6648",
    Longitude: "31.0566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9935",
    WardLookupId: "3352",
    VDNumber: "43350511",
    RegisteredPopulation: "1644",
    VotingStationName: "WATERLOO SECONDARY SCHOOL",
    Address: "118 PYTHON ROAD  WATERLOO  VERULAM",
    Latitude: "-29.666",
    Longitude: "31.0505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9936",
    WardLookupId: "3352",
    VDNumber: "43400764",
    RegisteredPopulation: "3008",
    VotingStationName: "WATERLOO PHASE 5 CRECHE",
    Address: "JABU NGCOBO  WATERLOO  ETHEKWINI",
    Latitude: "-29.666",
    Longitude: "31.067",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9937",
    WardLookupId: "3353",
    VDNumber: "43361186",
    RegisteredPopulation: "3426",
    VotingStationName: "NTUZUMA H COMMUNITY HALL",
    Address: "H2592 DALMENY MAIN ROAD  NTUZUMA  ETHEKWINI",
    Latitude: "-29.7258",
    Longitude: "30.9608",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9938",
    WardLookupId: "3353",
    VDNumber: "43361197",
    RegisteredPopulation: "3272",
    VotingStationName: "SENZOKWETHU SECONDARY SCHOOL",
    Address: "H1732 NTUZUMA  ETHEKWINI",
    Latitude: "-29.7231",
    Longitude: "30.9639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9939",
    WardLookupId: "3353",
    VDNumber: "43361344",
    RegisteredPopulation: "1838",
    VotingStationName: "NHLANHLAYETHU SECONDARY SCHOOL",
    Address: "B 382  INANDA NEWTOWN  ETHEKWINI",
    Latitude: "-29.7167",
    Longitude: "30.9605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9940",
    WardLookupId: "3353",
    VDNumber: "43361355",
    RegisteredPopulation: "1486",
    VotingStationName: "SIFUNIMFUNDO PRIMARY SCHOOL",
    Address: "C1027 NEWTOWN C  INANDA  ETHEKWINI",
    Latitude: "-29.7196",
    Longitude: "30.9622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9941",
    WardLookupId: "3353",
    VDNumber: "43361467",
    RegisteredPopulation: "5713",
    VotingStationName: "SIPHUMELELE CP SCHOOL",
    Address: "1447 EZIMANGWENI  INANDA  ETHEKWINI",
    Latitude: "-29.7143",
    Longitude: "30.9687",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9942",
    WardLookupId: "3354",
    VDNumber: "43361388",
    RegisteredPopulation: "2133",
    VotingStationName: "MZOMUSHA PRIMARY SCHOOL",
    Address: "1818 NEWTOWN A  INANDA  ETHEKWINI",
    Latitude: "-29.7129",
    Longitude: "30.9384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9943",
    WardLookupId: "3354",
    VDNumber: "43361412",
    RegisteredPopulation: "2225",
    VotingStationName: "PHUMUZUZULU PRIMARY SCHOOL",
    Address: "NEWTOWN  INANDA  ETHEKWINI",
    Latitude: "-29.7051",
    Longitude: "30.9432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9944",
    WardLookupId: "3354",
    VDNumber: "43361658",
    RegisteredPopulation: "2304",
    VotingStationName: "INANDA FELLOWSHIP CENTRE",
    Address: "STOP 8  INANDA  INANDA",
    Latitude: "-29.7022",
    Longitude: "30.9386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9945",
    WardLookupId: "3354",
    VDNumber: "43362143",
    RegisteredPopulation: "1449",
    VotingStationName: "BANTU APOSTOLIC CHURCH",
    Address: "PHOLA-MLOSHI  INANDA  ETHEKWINI",
    Latitude: "-29.697",
    Longitude: "30.9426",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9946",
    WardLookupId: "3354",
    VDNumber: "43362367",
    RegisteredPopulation: "2101",
    VotingStationName: "SEKUSILE PRIMARY SCHOOL",
    Address: "INANDA ROAD  NEW CONGO   INANDA",
    Latitude: "-29.6947",
    Longitude: "30.936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9947",
    WardLookupId: "3354",
    VDNumber: "43583069",
    RegisteredPopulation: "2752",
    VotingStationName: "HLENGIMPILO PRIMARY SCHOOL",
    Address: "SEME STORE MZINYATHI  INANDA  ETHEKWINI",
    Latitude: "-29.6903",
    Longitude: "30.9029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9948",
    WardLookupId: "3354",
    VDNumber: "43583991",
    RegisteredPopulation: "1837",
    VotingStationName: "PHOLA HALL",
    Address: "UMZINYATHI ROAD,  INANDA  ETHEKWINI",
    Latitude: "-29.6896",
    Longitude: "30.9183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9949",
    WardLookupId: "3354",
    VDNumber: "43584004",
    RegisteredPopulation: "1077",
    VotingStationName: "ZULU CONGREGATIONAL CHURCH",
    Address: "UMZINYATHI  INANDA  ETHEKWINI",
    Latitude: "-29.6923",
    Longitude: "30.927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9950",
    WardLookupId: "3354",
    VDNumber: "43584644",
    RegisteredPopulation: "651",
    VotingStationName: "KHULAKAHLE CRECHE",
    Address: "19568 UMZINYATHI ROAD  INANDA  UMZINYATHI",
    Latitude: "-29.6765",
    Longitude: "30.9087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9951",
    WardLookupId: "3355",
    VDNumber: "43380301",
    RegisteredPopulation: "859",
    VotingStationName: "ILLOVO PRIMARY SCHOOL",
    Address: "ILLOVU  AMANZIMTOTI  ETHEKWINI",
    Latitude: "-30.0946",
    Longitude: "30.8234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9952",
    WardLookupId: "3355",
    VDNumber: "43380323",
    RegisteredPopulation: "4868",
    VotingStationName: "ILLOVU COMMUNITY HALL(C SECTION)",
    Address: "2270 LOVU T/SHIP,WINKELSPRUIT  ILLOVU  ETHEKWINI",
    Latitude: "-30.0687",
    Longitude: "30.8363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9953",
    WardLookupId: "3355",
    VDNumber: "43380345",
    RegisteredPopulation: "6492",
    VotingStationName: "ILLOVU COMMUNITY HALL( B SECTION)",
    Address: "ILLOVU TOWNSHIP  ILLOVU   ETHEKWINI",
    Latitude: "-30.0622",
    Longitude: "30.826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9954",
    WardLookupId: "3355",
    VDNumber: "43580279",
    RegisteredPopulation: "1517",
    VotingStationName: "SIYATHOKOZA SCHOOL",
    Address: "AMANZIMTOTI  UMBUMBULU  ETHEKWINI",
    Latitude: "-30.0381",
    Longitude: "30.865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9955",
    WardLookupId: "3355",
    VDNumber: "43580303",
    RegisteredPopulation: "1284",
    VotingStationName: "MTHOMBENI SCHOOL",
    Address: "BAPHEHLI  AMANZIMTOTI  ETHEKWINI",
    Latitude: "-30.0539",
    Longitude: "30.8334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9956",
    WardLookupId: "3355",
    VDNumber: "43581685",
    RegisteredPopulation: "1719",
    VotingStationName: "BHEKULWANDLE COMMUNITY HALL",
    Address: "950 NOBAMBA  BHEKULWANDLE  ETHEKWINI",
    Latitude: "-30.056",
    Longitude: "30.8566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9957",
    WardLookupId: "3355",
    VDNumber: "43583452",
    RegisteredPopulation: "2215",
    VotingStationName: "BAPHEHLI COMMUNITY HALL",
    Address: "930 UMBUMBULU,BHEKULWANDLE  UMBUMBULU  ETHEKWINI",
    Latitude: "-30.0555",
    Longitude: "30.8482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9958",
    WardLookupId: "3355",
    VDNumber: "43583586",
    RegisteredPopulation: "1019",
    VotingStationName: "BHEKULWANDLE PRIMARY SCHOOL",
    Address: "D1058 BHEKULWANDLE AREA  AMANZIMTOTI ,4126  BHEKULWANDLE",
    Latitude: "-30.0495",
    Longitude: "30.8444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9959",
    WardLookupId: "3356",
    VDNumber: "43350016",
    RegisteredPopulation: "4722",
    VotingStationName: "SUNNINGDALE PRE-PRIMARY (TENT)",
    Address: "VILLAGE WAY AND SPURWING DRIVE  SUNNINGDALE  DURBAN",
    Latitude: "-29.7458",
    Longitude: "31.0434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9960",
    WardLookupId: "3356",
    VDNumber: "43360444",
    RegisteredPopulation: "3078",
    VotingStationName: "DUBAN NORTH ACTION CRICKET CLUB",
    Address: "80 RINALDO ROAD  GLENHILLS  ETHEKWINI",
    Latitude: "-29.7598",
    Longitude: "31.0259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9961",
    WardLookupId: "3356",
    VDNumber: "43360624",
    RegisteredPopulation: "4157",
    VotingStationName: "COROVOCA PRIMARY SCHOOL",
    Address: "5 POMEGRANATE ROAD  AVOCA HILLS  ETHEKWINI",
    Latitude: "-29.7594",
    Longitude: "31.0025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9962",
    WardLookupId: "3356",
    VDNumber: "43362222",
    RegisteredPopulation: "4851",
    VotingStationName: "MOUNT MORIAH JUNIOR PRIMARY SCHOOL",
    Address: "P2484 DOLOFIYA ROAD  MOUNT MORIAH  PHOENIX",
    Latitude: "-29.7433",
    Longitude: "31.0197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9963",
    WardLookupId: "3356",
    VDNumber: "43362288",
    RegisteredPopulation: "2593",
    VotingStationName: "MT ROYAL PRIMARY SCHOOL",
    Address:
      "208 HAYFIELD ROAD - MT. ROYAL  PHOENIX   (ETH - ETHEKWINI [DURBAN METRO])",
    Latitude: "-29.7362",
    Longitude: "31.012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9964",
    WardLookupId: "1278",
    VDNumber: "43990061",
    RegisteredPopulation: "380",
    VotingStationName: "SIVELILE JUNIOR SECONDARY SCHOOL",
    Address: "UMKHUNYA,  DUMISA  VULAMEHLO, UMZINTO",
    Latitude: "-30.1465",
    Longitude: "30.4516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9965",
    WardLookupId: "1278",
    VDNumber: "43990072",
    RegisteredPopulation: "402",
    VotingStationName: "INKANINI COMBINED PRIMARY SCHOOL",
    Address: "ENSEPHENI, DUMISA  VULAMEHLO",
    Latitude: "-30.1309",
    Longitude: "30.4748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9966",
    WardLookupId: "1278",
    VDNumber: "43990083",
    RegisteredPopulation: "408",
    VotingStationName: "AMANDLAKAPHELI COMBINED PRIMARY SCHOOL",
    Address: "NTSHASENI, KWALEMBE T/A  VULAMEHLO",
    Latitude: "-30.105",
    Longitude: "30.4634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9967",
    WardLookupId: "1278",
    VDNumber: "43990117",
    RegisteredPopulation: "342",
    VotingStationName: "MNCINDO HIGH SCHOOL",
    Address: "0  KWALEMBE  VULAMEHLO",
    Latitude: "-30.0967",
    Longitude: "30.5163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9968",
    WardLookupId: "1278",
    VDNumber: "43990128",
    RegisteredPopulation: "503",
    VotingStationName: "MTHOLI COMBINED PRIMARY SCHOOL",
    Address: "UMKHUNYA ROAD  UMKHUNYA  VULAMEHLO, UMZINTO 4200",
    Latitude: "-30.147",
    Longitude: "30.4653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9969",
    WardLookupId: "1278",
    VDNumber: "43990139",
    RegisteredPopulation: "248",
    VotingStationName: "COMPOSTELLA CHURCH",
    Address: "MZIMLILO, DUMISA  VULAMEHLO KZ211",
    Latitude: "-30.1885",
    Longitude: "30.4449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9970",
    WardLookupId: "1278",
    VDNumber: "43990140",
    RegisteredPopulation: "187",
    VotingStationName: "MPHAMBANYONI COMMUNITY HALL",
    Address: "MPHAMBANYONI  UMZINTO  KWALEMBE",
    Latitude: "-30.1908",
    Longitude: "30.4321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9971",
    WardLookupId: "1278",
    VDNumber: "43990274",
    RegisteredPopulation: "478",
    VotingStationName: "SIYATHUTHUKA CRECHE",
    Address: "OKHALWENI, DUMISA  UMZINTO",
    Latitude: "-30.1739",
    Longitude: "30.5031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9972",
    WardLookupId: "1278",
    VDNumber: "43993299",
    RegisteredPopulation: "468",
    VotingStationName: "KWA MISO COMBINED PRIMARY SCHOOL",
    Address: "UMKHUNYA, DUMISA  VULAMEHLO",
    Latitude: "-30.1625",
    Longitude: "30.4787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9973",
    WardLookupId: "1278",
    VDNumber: "43993581",
    RegisteredPopulation: "572",
    VotingStationName: "DUMAYO COMBINED PRIMARY SCHOOL",
    Address: "UMKHUNYA, KWADUMISA  VULAMEHLO",
    Latitude: "-30.1299",
    Longitude: "30.4444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9974",
    WardLookupId: "1278",
    VDNumber: "43994267",
    RegisteredPopulation: "338",
    VotingStationName: "KWALEMBE COMMUNITY HALL",
    Address: "P739  KWALEMBE  VULAMEHLO",
    Latitude: "-30.1072",
    Longitude: "30.5384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9975",
    WardLookupId: "1279",
    VDNumber: "43990094",
    RegisteredPopulation: "172",
    VotingStationName: "BHEWULA CRECHE",
    Address: "SCOTTBURGH 4180  BHEWULA AREA DUDUDU  ZEMBENI TRIBAL AUTHORITY",
    Latitude: "-30.1825",
    Longitude: "30.542",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9976",
    WardLookupId: "1279",
    VDNumber: "43990106",
    RegisteredPopulation: "253",
    VotingStationName: "SHUKUMISA HIGHER PRIMARY SCHOOL",
    Address: "NTSHENKOMBE, KWAQIKO  VULAMEHLO",
    Latitude: "-30.1427",
    Longitude: "30.5626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9977",
    WardLookupId: "1279",
    VDNumber: "43992928",
    RegisteredPopulation: "436",
    VotingStationName: "KHAKHAMA COMMUNITY HALL",
    Address: "KHAKHAMA  UMZINTO 4200  VULAMEHLO",
    Latitude: "-30.2355",
    Longitude: "30.5878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9978",
    WardLookupId: "1279",
    VDNumber: "43992939",
    RegisteredPopulation: "826",
    VotingStationName: "MCELENI COMBINED PRIMARY SCHOOL",
    Address: "KWA-JONI, DUMISA  VULAMEHLO, UMZINTO",
    Latitude: "-30.2014",
    Longitude: "30.5115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9979",
    WardLookupId: "1279",
    VDNumber: "43993288",
    RegisteredPopulation: "251",
    VotingStationName: "NDUNDUMA COMBINED PRIMARY SCHOOL",
    Address: "UMKHUNYA ROAD  BHEWULA  VULAMEHLO",
    Latitude: "-30.1811",
    Longitude: "30.5256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9980",
    WardLookupId: "1279",
    VDNumber: "43993592",
    RegisteredPopulation: "373",
    VotingStationName: "AMANDLALATHI LOWER PRIMARY SCHOOL",
    Address: "0  AMANDLALATHI, KWAQIKO  VULAMEHLO",
    Latitude: "-30.1335",
    Longitude: "30.5292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9981",
    WardLookupId: "1279",
    VDNumber: "43993705",
    RegisteredPopulation: "502",
    VotingStationName: "ISANQULA COMBINED PRIMARY SCHOOL",
    Address: "UMKHUNYA MAIN ROAD  AMANDLALATHI  VULAMEHLO",
    Latitude: "-30.1612",
    Longitude: "30.5161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9982",
    WardLookupId: "1279",
    VDNumber: "43994357",
    RegisteredPopulation: "357",
    VotingStationName: "SEWING CENTRE",
    Address: "00 NONE  OPHONDWENI, KWADUMISA  UMZINTO",
    Latitude: "-30.2127",
    Longitude: "30.5194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9983",
    WardLookupId: "1280",
    VDNumber: "43990331",
    RegisteredPopulation: "200",
    VotingStationName: "QIKO TRIBAL COURT",
    Address: "ESKEBHENI, KWAQIKO  VULAMEHLO, UMZINTO",
    Latitude: "-30.1245",
    Longitude: "30.5906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9984",
    WardLookupId: "1280",
    VDNumber: "43992838",
    RegisteredPopulation: "1957",
    VotingStationName: "ZEMBENI SENIOR PRIMARY SCHOOL",
    Address: "MAHWAQA  UMZINTO  ZEMBENI",
    Latitude: "-30.1945",
    Longitude: "30.5952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9985",
    WardLookupId: "1280",
    VDNumber: "43992849",
    RegisteredPopulation: "607",
    VotingStationName: "NHLAYENZA LOWER PRIMARY SCHOOL",
    Address: "0  EZEMBENIT/A  DUDUDU",
    Latitude: "-30.1688",
    Longitude: "30.5853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9986",
    WardLookupId: "1280",
    VDNumber: "43994504",
    RegisteredPopulation: "985",
    VotingStationName: "NKAMPULA CRECHE",
    Address: "MAHWAQA   DUDUDU  ZEMBENI TRIBAL AUTHORITY",
    Latitude: "-30.1842",
    Longitude: "30.5868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9987",
    WardLookupId: "1280",
    VDNumber: "43994515",
    RegisteredPopulation: "261",
    VotingStationName: "BHEWULA COMMUNITY HALL",
    Address: "BHEWULA AREA  UMZINTO  ZEMBENI TRIBAL AUTHORITY",
    Latitude: "-30.1675",
    Longitude: "30.568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9988",
    WardLookupId: "1281",
    VDNumber: "43990173",
    RegisteredPopulation: "787",
    VotingStationName: "INQANNULA COMBINED PRIMARY SCHOOL",
    Address: "KWABHADANE  DUMISA TRIBAL AREA  VULAMEHLO",
    Latitude: "-30.2348",
    Longitude: "30.4441",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9989",
    WardLookupId: "1281",
    VDNumber: "43990229",
    RegisteredPopulation: "657",
    VotingStationName: "MQANGQALA CRECHE",
    Address: "MQANGQALA  EMANDLENI T/A  VULAMEHLO, UMZINTO",
    Latitude: "-30.2299",
    Longitude: "30.4108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9990",
    WardLookupId: "1281",
    VDNumber: "43990241",
    RegisteredPopulation: "974",
    VotingStationName: "KENTERTON COMBINED PRIMARY SCHOOL",
    Address: "KENTERTON  VULAMEHLO",
    Latitude: "-30.2452",
    Longitude: "30.3958",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9991",
    WardLookupId: "1281",
    VDNumber: "43990296",
    RegisteredPopulation: "408",
    VotingStationName: "QUEMBE COMBINED PRIMARY SCHOOL",
    Address: "EHLANZENI, AMANYUSWA T/A  UMZINTO",
    Latitude: "-30.2107",
    Longitude: "30.4669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9992",
    WardLookupId: "1281",
    VDNumber: "43990308",
    RegisteredPopulation: "108",
    VotingStationName: "POOVANS STORE",
    Address: "OPHONDWENI, DUMISA TRIBAL AREA  VULAMEHLO, UMZINTO",
    Latitude: "-30.2126",
    Longitude: "30.5039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9993",
    WardLookupId: "1281",
    VDNumber: "43993828",
    RegisteredPopulation: "360",
    VotingStationName: "KWANYUSWA SKILLS CENTRE",
    Address: "MGANGENI  UMZINTO  AMANYUSWA TRIBAL AUTHORITY",
    Latitude: "-30.2355",
    Longitude: "30.382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9994",
    WardLookupId: "1281",
    VDNumber: "43994368",
    RegisteredPopulation: "337",
    VotingStationName: "TWO-STICK COMMUNITY HALL",
    Address: "TWO-STICK  UMZINTO",
    Latitude: "-30.1924",
    Longitude: "30.4794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9995",
    WardLookupId: "1282",
    VDNumber: "43990184",
    RegisteredPopulation: "863",
    VotingStationName: "ZAMANI LOWER PRIMARY SCHOOL",
    Address: "MBULULA  KWADUMISA  VULAMEHLO",
    Latitude: "-30.276",
    Longitude: "30.4464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9996",
    WardLookupId: "1282",
    VDNumber: "43990207",
    RegisteredPopulation: "193",
    VotingStationName: "HIMMELBERG SCHOOL",
    Address: "HIMMELBERG MISSION, SAWOTI  VULAMEHLO",
    Latitude: "-30.2692",
    Longitude: "30.4969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9997",
    WardLookupId: "1282",
    VDNumber: "43990218",
    RegisteredPopulation: "1069",
    VotingStationName: "SIZOPHUMELELA HIGH SCHOOL",
    Address: "KWADUMISA  VULAMEHLO",
    Latitude: "-30.2625",
    Longitude: "30.4109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9998",
    WardLookupId: "1282",
    VDNumber: "43990678",
    RegisteredPopulation: "549",
    VotingStationName: "MAYFIELD CRECHE",
    Address: "MAYFIELD AREA  VULAMEHLO, UMZINTO",
    Latitude: "-30.2628",
    Longitude: "30.5056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "9999",
    WardLookupId: "1282",
    VDNumber: "43993109",
    RegisteredPopulation: "1389",
    VotingStationName: "VUKAPHI COMBINED PRIMARY SCHOOL",
    Address: "0  KWA-DUMISA , IZMPETHU ZENDLOVU T/A  UMZINTO",
    Latitude: "-30.2675",
    Longitude: "30.4258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10000",
    WardLookupId: "1283",
    VDNumber: "43990285",
    RegisteredPopulation: "756",
    VotingStationName: "MAHLATHINI MPCC",
    Address: "MAHLATHINI ROAD  MAHLATHINI, BRAEMAR  VULAMEHLO",
    Latitude: "-30.2974",
    Longitude: "30.5347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10001",
    WardLookupId: "1283",
    VDNumber: "43990320",
    RegisteredPopulation: "230",
    VotingStationName: "VELANAWE COMBINED PRIMARY SCHOOL",
    Address: "MAIZELAND  UMZINTO  VULAMEHLO KZ211",
    Latitude: "-30.2593",
    Longitude: "30.598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10002",
    WardLookupId: "1283",
    VDNumber: "43992771",
    RegisteredPopulation: "873",
    VotingStationName: "BRAEMAR PRIMARY SCHOOL",
    Address: "SCHOOL ROAD  BRAEMAR  UMZINTO",
    Latitude: "-30.3121",
    Longitude: "30.5657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10003",
    WardLookupId: "1283",
    VDNumber: "43993165",
    RegisteredPopulation: "1236",
    VotingStationName: "ISULABASHA COMBINED PRIMARY SCHOOL",
    Address: "MISTAKE FARM  UMZINTO",
    Latitude: "-30.2645",
    Longitude: "30.6238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10004",
    WardLookupId: "1283",
    VDNumber: "43993817",
    RegisteredPopulation: "307",
    VotingStationName: "ST ODILO PRIMARY SCHOOL",
    Address: "HARRINGWORTH  UMZINTO",
    Latitude: "-30.3565",
    Longitude: "30.5432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10005",
    WardLookupId: "1283",
    VDNumber: "43993840",
    RegisteredPopulation: "526",
    VotingStationName: "PHUNGULA PRIMARY SCHOOL",
    Address: "KWAMBUMNGULU, DUMISA  UMZINTO",
    Latitude: "-30.2583",
    Longitude: "30.5496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10006",
    WardLookupId: "1283",
    VDNumber: "43994166",
    RegisteredPopulation: "346",
    VotingStationName: "MBILI FARM HALL",
    Address: "INVERUGIE  UMZINTO  OFAFA AREA, UMZINTO",
    Latitude: "-30.3338",
    Longitude: "30.5736",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10007",
    WardLookupId: "1284",
    VDNumber: "43990915",
    RegisteredPopulation: "689",
    VotingStationName: "IFAFA LIBRARY",
    Address: "KIRKMAN DRIVE  IFAFA BEACH  IFAFA BEACH",
    Latitude: "-30.4634",
    Longitude: "30.6508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10008",
    WardLookupId: "1284",
    VDNumber: "43993277",
    RegisteredPopulation: "2035",
    VotingStationName: "KHUPHUKA COMBINED PRIMARY SCHOOL",
    Address: "MALANGENI  UMZINTO",
    Latitude: "-30.4036",
    Longitude: "30.6457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10009",
    WardLookupId: "1284",
    VDNumber: "44010010",
    RegisteredPopulation: "459",
    VotingStationName: "LOT 112 COVEWAY",
    Address: "112 COVEWAY  MTWALUME  HIBBERDENE",
    Latitude: "-30.4907",
    Longitude: "30.6323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10010",
    WardLookupId: "1284",
    VDNumber: "44040024",
    RegisteredPopulation: "1053",
    VotingStationName: "SEZELA PUBLIC LIBRARY",
    Address: "SCHOOL ROAD  SEZELA  SCOTTBURGH",
    Latitude: "-30.4061",
    Longitude: "30.678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10011",
    WardLookupId: "1285",
    VDNumber: "43990599",
    RegisteredPopulation: "1842",
    VotingStationName: "SIHLE HIGH SCHOOL",
    Address: "MALANGENI  UMZINTO",
    Latitude: "-30.3719",
    Longitude: "30.639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10012",
    WardLookupId: "1285",
    VDNumber: "43990601",
    RegisteredPopulation: "1411",
    VotingStationName: "BONGUMBHELE SENIOR PRIMARY SCHOOL",
    Address: "ESPARANZA RD  MALANGENI  UMZINTO",
    Latitude: "-30.3799",
    Longitude: "30.6451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10013",
    WardLookupId: "1285",
    VDNumber: "43990612",
    RegisteredPopulation: "835",
    VotingStationName: "JABULASAGA CRECHE",
    Address: "ENKOMBO VILLAGE  UMZINTO  MALANGENI TRIBAL AUTHORITY",
    Latitude: "-30.3707",
    Longitude: "30.6615",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10014",
    WardLookupId: "1285",
    VDNumber: "43990634",
    RegisteredPopulation: "1629",
    VotingStationName: "VUSISIZWE HIGHER PRIMARY SCHOOL",
    Address: "D 13  MALANGENI  UMZINTO",
    Latitude: "-30.3509",
    Longitude: "30.6517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10015",
    WardLookupId: "1286",
    VDNumber: "43990588",
    RegisteredPopulation: "653",
    VotingStationName: "SKHUMBUZO GUMEDE COMMUNITY HALL",
    Address: "MAFITHINI WARD 9 ESIDABULWENI  UMZINTO  MALANGENI",
    Latitude: "-30.387",
    Longitude: "30.627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10016",
    WardLookupId: "1286",
    VDNumber: "43994188",
    RegisteredPopulation: "1520",
    VotingStationName: "IFAFA UCCSA",
    Address: "0 BEACON HILL  MALANGENI  UMZINTO",
    Latitude: "-30.3796",
    Longitude: "30.6381",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10017",
    WardLookupId: "1287",
    VDNumber: "44040013",
    RegisteredPopulation: "2792",
    VotingStationName: "PENNINGTON LIBRARY ACTIVITIES HALL",
    Address: "42 DOLPHIN DRIVE  PENNINGTON  SCOTTBURGH",
    Latitude: "-30.3709",
    Longitude: "30.7034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10018",
    WardLookupId: "1288",
    VDNumber: "43990623",
    RegisteredPopulation: "933",
    VotingStationName: "ZAMANI CRECHE",
    Address: "OPPOSITE DRIFT STORE  MALANGENI  UMZINTO",
    Latitude: "-30.342",
    Longitude: "30.6465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10019",
    WardLookupId: "1288",
    VDNumber: "44060037",
    RegisteredPopulation: "1575",
    VotingStationName: "UMZINTO MUNICIPAL BOARDROOM",
    Address: "MAIN ROAD  PROTECTION SERVICES DEPT  UMZINTO",
    Latitude: "-30.3189",
    Longitude: "30.6594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10020",
    WardLookupId: "1288",
    VDNumber: "44060060",
    RegisteredPopulation: "614",
    VotingStationName: "SHAYAMOYA LIBRARY",
    Address: "152 PHAKATHI STREET  SHAYAMOYA  UMZINTO",
    Latitude: "-30.3301",
    Longitude: "30.6608",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10021",
    WardLookupId: "1288",
    VDNumber: "44060082",
    RegisteredPopulation: "186",
    VotingStationName: "ELLINGHAM HALL-STRATHEARME HOSTEL",
    Address:
      "R612 1 OLD MAIN ROAD   SMITH COMPOUND  ELLINGHAM ESTATE, PARK RYNIE",
    Latitude: "-30.3322",
    Longitude: "30.706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10022",
    WardLookupId: "1289",
    VDNumber: "43994830",
    RegisteredPopulation: "760",
    VotingStationName: "ROSEVILLE SECONDARY SCHOOL",
    Address: "427 LILY  ROSEVILLE HEIGHTS  UMZINTO",
    Latitude: "-30.3055",
    Longitude: "30.6613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10023",
    WardLookupId: "1289",
    VDNumber: "44060059",
    RegisteredPopulation: "2435",
    VotingStationName: "GHANDINAGAR EDU-CARE CENTRE",
    Address: "1518 EGRET LANE  GHANDHINAGAR  UMZINTO",
    Latitude: "-30.2856",
    Longitude: "30.6786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10024",
    WardLookupId: "1289",
    VDNumber: "44060071",
    RegisteredPopulation: "594",
    VotingStationName: "UMZINTO MAGISTRATE COURT",
    Address: "COURT ROAD  UMZINTO  UMDONI",
    Latitude: "-30.3088",
    Longitude: "30.6723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10025",
    WardLookupId: "1290",
    VDNumber: "44060048",
    RegisteredPopulation: "3315",
    VotingStationName: "UMZINTO DINING HALL",
    Address: "MAIN ROAD  UMZINTO  UMZINTO",
    Latitude: "-30.3024",
    Longitude: "30.6669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10026",
    WardLookupId: "1291",
    VDNumber: "43990511",
    RegisteredPopulation: "1539",
    VotingStationName: "VG NYAWOSE HALL",
    Address: "SHAYAMOYA   AMANDAWE  SCOTTBURGH",
    Latitude: "-30.261",
    Longitude: "30.7355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10027",
    WardLookupId: "1291",
    VDNumber: "43990667",
    RegisteredPopulation: "1625",
    VotingStationName: "GUGULESIZWE HIGH SCHOOL",
    Address: "GUGULESIZWE ROAD  WARD 4, AMANDAWE MISSION  AMANDAWE",
    Latitude: "-30.2623",
    Longitude: "30.7121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10028",
    WardLookupId: "1291",
    VDNumber: "43994638",
    RegisteredPopulation: "975",
    VotingStationName: "MAHLASHANA MEMORIAL SCHOOL",
    Address: "AMANDAWE MISSION  SCOTTBURGH  KWACELE TRIBAL AUTHORITY",
    Latitude: "-30.2607",
    Longitude: "30.7262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10029",
    WardLookupId: "1292",
    VDNumber: "44060015",
    RegisteredPopulation: "2692",
    VotingStationName: "ST FRANCIS PRIMARY SCHOOL",
    Address: "CONVENT ROAD  PARK RYNIE  PARK RYNIE",
    Latitude: "-30.3145",
    Longitude: "30.7403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10030",
    WardLookupId: "1292",
    VDNumber: "44060026",
    RegisteredPopulation: "2409",
    VotingStationName: "SCOTTBURGH TOWN HALL",
    Address: "BRAM FISCHER ROAD  SCOTTBURGH CENTRAL  SCOTTBURGH",
    Latitude: "-30.2858",
    Longitude: "30.7545",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10031",
    WardLookupId: "1293",
    VDNumber: "43990522",
    RegisteredPopulation: "1445",
    VotingStationName: "AMAHLONGWA METHODIST CHURCH",
    Address: "OGQOLWENI, AMAHLONGWA  UMKOMAAS  ZEMBENI",
    Latitude: "-30.2404",
    Longitude: "30.7201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10032",
    WardLookupId: "1293",
    VDNumber: "43990533",
    RegisteredPopulation: "969",
    VotingStationName: "KWAHLUZINGQONDO HIGH SCHOOL",
    Address: "AMAHLONGWA  SCOTTBURGH",
    Latitude: "-30.2359",
    Longitude: "30.7308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10033",
    WardLookupId: "1293",
    VDNumber: "43994649",
    RegisteredPopulation: "779",
    VotingStationName: "ZAMA STORE",
    Address: "AMANDAWE MISSION  SCOTTBURGH  KWACELE TRIBAL AUTHORITY",
    Latitude: "-30.2573",
    Longitude: "30.7215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10034",
    WardLookupId: "1294",
    VDNumber: "43990421",
    RegisteredPopulation: "1759",
    VotingStationName: "SHONKWENI COMBINED PRIMARY SCHOOL",
    Address: "DUDUDU MAIN ROAD  KWABHAKAJANA  KWA-CELE T/A",
    Latitude: "-30.2562",
    Longitude: "30.6921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10035",
    WardLookupId: "1294",
    VDNumber: "43994177",
    RegisteredPopulation: "1718",
    VotingStationName: "ACCV CHURCH - AMANDAWE BRANCH",
    Address: "ESILENGENI ROAD  AMANDAWE MISSION  KWACELE",
    Latitude: "-30.2514",
    Longitude: "30.7104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10036",
    WardLookupId: "1295",
    VDNumber: "43990443",
    RegisteredPopulation: "522",
    VotingStationName: "OLWASINI JUNIOR PRIMARY SCHOOL",
    Address: "OLWASINI  AMAHLONGWA  SCOTTBURGH",
    Latitude: "-30.2097",
    Longitude: "30.692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10037",
    WardLookupId: "1295",
    VDNumber: "43990465",
    RegisteredPopulation: "530",
    VotingStationName: "SANDANOLWAZI COMBINED PRIMARY SCHOOL",
    Address: "00 VULAMEHLO  KWA-DLANGEZWA, ZEMBENI T/A  UMKOMAAS",
    Latitude: "-30.183",
    Longitude: "30.6755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10038",
    WardLookupId: "1295",
    VDNumber: "43990476",
    RegisteredPopulation: "477",
    VotingStationName: "THANDOKWETHU LOWER PRIMARY SCHOOL",
    Address: "MJUNUNDWINI AREA  UMKOMAAS  ZEMBENI TRIBAL AUTHORITY",
    Latitude: "-30.1775",
    Longitude: "30.6468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10039",
    WardLookupId: "1295",
    VDNumber: "43990487",
    RegisteredPopulation: "328",
    VotingStationName: "EMHLONHLWENI CRECHE",
    Address: "VULINDLELA  KWAQIKO  VULAMEHLO",
    Latitude: "-30.1517",
    Longitude: "30.6417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10040",
    WardLookupId: "1295",
    VDNumber: "43990555",
    RegisteredPopulation: "1324",
    VotingStationName: "AMAHLONGWA COMMUNITY HALL",
    Address: "0 AMAHLONGWA  UMKOMAAS  EZEMBENI",
    Latitude: "-30.2264",
    Longitude: "30.7251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10041",
    WardLookupId: "1295",
    VDNumber: "43992940",
    RegisteredPopulation: "101",
    VotingStationName: "SIZOMUNYE PRIMARY SCHOOL",
    Address: "OLWASINI  UMKOMAAS  KWACELE TRIBAL AUTHORITY",
    Latitude: "-30.1946",
    Longitude: "30.6668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10042",
    WardLookupId: "1295",
    VDNumber: "43994627",
    RegisteredPopulation: "396",
    VotingStationName: "MMOYENI PRIMARY SCHOOL",
    Address: "AMAHLONGWA MISSION  UMKOMAAS  ZEMBENI",
    Latitude: "-30.2267",
    Longitude: "30.7124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10043",
    WardLookupId: "1296",
    VDNumber: "43990432",
    RegisteredPopulation: "1273",
    VotingStationName: "CELOKUHLE HIGHER PRIMARY SCHOOL",
    Address: "DUDUDU  KWA-CELE  AMANDAWE",
    Latitude: "-30.2363",
    Longitude: "30.6759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10044",
    WardLookupId: "1296",
    VDNumber: "43992760",
    RegisteredPopulation: "1133",
    VotingStationName: "UCC DUDUDU CHURCH HALL",
    Address: "DUDUDU MAIN RD.  DUDUDU, EZEMBENI TR/A  UMZINTO",
    Latitude: "-30.1893",
    Longitude: "30.6073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10045",
    WardLookupId: "1296",
    VDNumber: "43993176",
    RegisteredPopulation: "208",
    VotingStationName: "NTONTONTO COMBINED PRIMARY SCHOOL",
    Address: "NTONTONTO, CELE T/A  UMZINTO",
    Latitude: "-30.2407",
    Longitude: "30.6145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10046",
    WardLookupId: "1296",
    VDNumber: "43994021",
    RegisteredPopulation: "716",
    VotingStationName: "SWELIHLE CRECHE",
    Address: "MJUNUNDWINI, DUDUDU  UMZINTO",
    Latitude: "-30.1852",
    Longitude: "30.6239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10047",
    WardLookupId: "1296",
    VDNumber: "43994199",
    RegisteredPopulation: "83",
    VotingStationName: "MAHLABATHINI CRECHE",
    Address: "MAHLABATHINI, CELE T/A  VULAMEHLO",
    Latitude: "-30.2396",
    Longitude: "30.6314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10048",
    WardLookupId: "1296",
    VDNumber: "43994818",
    RegisteredPopulation: "469",
    VotingStationName: "ASSEMBLES OF GOD FELLOWSHIP",
    Address: "MISTAKE FARM  UMZINTO  KWACELE",
    Latitude: "-30.269",
    Longitude: "30.6375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10049",
    WardLookupId: "1296",
    VDNumber: "43994829",
    RegisteredPopulation: "238",
    VotingStationName: "MACEBO JUNIOR PRIMARY SCHOOL",
    Address: "MGWEMPISI AREA  UMZINTO  KWACELE",
    Latitude: "-30.2177",
    Longitude: "30.667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10050",
    WardLookupId: "3559",
    VDNumber: "43991488",
    RegisteredPopulation: "1076",
    VotingStationName: "DWESHULA PRIMARY SCHOOL",
    Address: "ST FAITHS ROAD  DWESHULA  CELE K",
    Latitude: "-30.5554",
    Longitude: "30.2672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10051",
    WardLookupId: "3559",
    VDNumber: "43991501",
    RegisteredPopulation: "532",
    VotingStationName: "EMSENI PRIMARY SCHOOL",
    Address: "MVOZANA  PORT SHEPSTONE  CELE K",
    Latitude: "-30.571",
    Longitude: "30.314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10052",
    WardLookupId: "3559",
    VDNumber: "43991657",
    RegisteredPopulation: "303",
    VotingStationName: "NGALEKA COMBINED PRIMARY SCHOOL",
    Address: "PORT SHEPSTONE  DWESHULA  UMZUMBE",
    Latitude: "-30.6175",
    Longitude: "30.2838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10053",
    WardLookupId: "3559",
    VDNumber: "43991668",
    RegisteredPopulation: "161",
    VotingStationName: "INDIKINI PRIMARY SCHOOL",
    Address: "PORT SHESPTONE  DWESHULA  UMZUMBE",
    Latitude: "-30.6099",
    Longitude: "30.2097",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10054",
    WardLookupId: "3559",
    VDNumber: "43993479",
    RegisteredPopulation: "608",
    VotingStationName: "SIZABANTU",
    Address: "KHAKHAMELA  UGU-KZN213  CELE K",
    Latitude: "-30.5442",
    Longitude: "30.2383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10055",
    WardLookupId: "3559",
    VDNumber: "43993918",
    RegisteredPopulation: "452",
    VotingStationName: "SUNDUZA PRIMARY SCHOOL",
    Address: "DWESHULA  CELE T/A  PORT SHEPSTONE",
    Latitude: "-30.5591",
    Longitude: "30.2339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10056",
    WardLookupId: "3559",
    VDNumber: "43994290",
    RegisteredPopulation: "374",
    VotingStationName: "KHANYILE HALL",
    Address: "DWESHULA  UMZUMBE [UMZUMBE]",
    Latitude: "-30.5526",
    Longitude: "30.2851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10057",
    WardLookupId: "3560",
    VDNumber: "43991444",
    RegisteredPopulation: "1173",
    VotingStationName: "ESIWOYENI  SCHOOL",
    Address: "HIGHFLATS  DWESHULA  UMZUMBE",
    Latitude: "-30.5264",
    Longitude: "30.2609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10058",
    WardLookupId: "3560",
    VDNumber: "43991477",
    RegisteredPopulation: "886",
    VotingStationName: "ST FAITHS HALL",
    Address: "ST FAITHS ROAD  EMAHLUBINI  HLUBI TRIBAL AUTHORITY",
    Latitude: "-30.5287",
    Longitude: "30.199",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10059",
    WardLookupId: "3560",
    VDNumber: "43991499",
    RegisteredPopulation: "358",
    VotingStationName: "BUMBENI JUNIOR PRIMARY SCHOOL",
    Address: "DWESHULA  HIGHFLATS  UMZUMBE",
    Latitude: "-30.5282",
    Longitude: "30.293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10060",
    WardLookupId: "3560",
    VDNumber: "43991679",
    RegisteredPopulation: "300",
    VotingStationName: "ST THOMAS PRIMARY SCHOOL",
    Address: "PO BOX 42777  ST. FAITHS  UMZUMBE",
    Latitude: "-30.5825",
    Longitude: "30.1959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10061",
    WardLookupId: "3560",
    VDNumber: "43993693",
    RegisteredPopulation: "879",
    VotingStationName: "INDLELENHLE S SCHOOL",
    Address: "ST FAITHS   ENKULU  HLUBI",
    Latitude: "-30.5335",
    Longitude: "30.215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10062",
    WardLookupId: "3560",
    VDNumber: "43993930",
    RegisteredPopulation: "197",
    VotingStationName: "SIZWILE SECONDARY SCHOOL",
    Address: "ST FAITHS  GUBHUZA  MABHELENI",
    Latitude: "-30.4835",
    Longitude: "30.2686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10063",
    WardLookupId: "3561",
    VDNumber: "43991466",
    RegisteredPopulation: "354",
    VotingStationName: "HLWATHIKA PRIMARY SCHOOL",
    Address: "THALENI TRUSTLAND  ST FAITHS  UMZUMBE",
    Latitude: "-30.5296",
    Longitude: "30.1678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10064",
    WardLookupId: "3561",
    VDNumber: "43992254",
    RegisteredPopulation: "437",
    VotingStationName: "MAKHOWANE PRIMARY SCHOOL",
    Address: "HIGHFLATS  UMZUMBE",
    Latitude: "-30.4791",
    Longitude: "30.1527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10065",
    WardLookupId: "3561",
    VDNumber: "43992344",
    RegisteredPopulation: "550",
    VotingStationName: "OXOLWENI PRIMARY SCHOOL",
    Address: "ST FAITHS MAIN ROAD  NHLANGWINI  UMZUMBE",
    Latitude: "-30.4664",
    Longitude: "30.1525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10066",
    WardLookupId: "3561",
    VDNumber: "43992355",
    RegisteredPopulation: "827",
    VotingStationName: "MQHAKAMA HIGH SCHOOL",
    Address: "HIGHFLATS  UMZUMBE",
    Latitude: "-30.4857",
    Longitude: "30.1659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10067",
    WardLookupId: "3561",
    VDNumber: "43992704",
    RegisteredPopulation: "292",
    VotingStationName: "SIYAKHONA PRIMARY SCHOOL",
    Address: "HIGHFLATS  UMZUMBE",
    Latitude: "-30.5303",
    Longitude: "30.1302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10068",
    WardLookupId: "3561",
    VDNumber: "43992883",
    RegisteredPopulation: "465",
    VotingStationName: "FINGQINDLELA HIGH SCHOOL",
    Address: "HIGHFLATS  UMZUMBE",
    Latitude: "-30.5088",
    Longitude: "30.132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10069",
    WardLookupId: "3561",
    VDNumber: "43992906",
    RegisteredPopulation: "654",
    VotingStationName: "MARIA TROST MISSION",
    Address: "HIGHFLATS  UMZUMBE",
    Latitude: "-30.502",
    Longitude: "30.2159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10070",
    WardLookupId: "3561",
    VDNumber: "43993390",
    RegisteredPopulation: "635",
    VotingStationName: "KWASANTI PRIMARY SCHOOL",
    Address: "HIGHFLATS  PHUNGASHE  UMZUMBE",
    Latitude: "-30.4907",
    Longitude: "30.1657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10071",
    WardLookupId: "3562",
    VDNumber: "43992142",
    RegisteredPopulation: "278",
    VotingStationName: "DUBANDLELA HIGH SCHOOL",
    Address: "HIGHFLATS  UMZUMBE",
    Latitude: "-30.454",
    Longitude: "30.0919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10072",
    WardLookupId: "3562",
    VDNumber: "43992153",
    RegisteredPopulation: "365",
    VotingStationName: "NOMAGEJE PRIMARY SCHOOL",
    Address: "ST. FAITHS ROAD  HIGHFLATS  BHEKANI TRIBAL AUTHORITY",
    Latitude: "-30.4421",
    Longitude: "30.1156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10073",
    WardLookupId: "3562",
    VDNumber: "43992164",
    RegisteredPopulation: "561",
    VotingStationName: "NHLALWANE  SCHOOL",
    Address: "NHLALWANE  HIGHFLATS  MFANGISALE TRIBAL AUTHORITY",
    Latitude: "-30.4567",
    Longitude: "30.0801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10074",
    WardLookupId: "3562",
    VDNumber: "43992243",
    RegisteredPopulation: "968",
    VotingStationName: "PHUNGASHE STORE",
    Address: "HIGHFLATS  UMZUMBE",
    Latitude: "-30.4238",
    Longitude: "30.152",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10075",
    WardLookupId: "3562",
    VDNumber: "43992333",
    RegisteredPopulation: "820",
    VotingStationName: "BHEKAMEVA HIGH SCHOOL",
    Address: "HIGHFLATS  UMZUMBE",
    Latitude: "-30.4192",
    Longitude: "30.1439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10076",
    WardLookupId: "3562",
    VDNumber: "43992861",
    RegisteredPopulation: "693",
    VotingStationName: "ISINAMUVA HIGH SCHOOL",
    Address: "HIGHFLATS  UMZUMBE",
    Latitude: "-30.418",
    Longitude: "30.1505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10077",
    WardLookupId: "3562",
    VDNumber: "43994694",
    RegisteredPopulation: "476",
    VotingStationName: "MAGUGU P. SCHOOL",
    Address: "HIGHFLATS  OLUPHEPHENI  NHLANGWINI",
    Latitude: "-30.4389",
    Longitude: "30.1472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10078",
    WardLookupId: "3563",
    VDNumber: "43991556",
    RegisteredPopulation: "477",
    VotingStationName: "GWALAGWALA CRECHE",
    Address: "UMZUMBE  GWALAGWALA  QWABE P TRIBAL AUTHORITY",
    Latitude: "-30.5833",
    Longitude: "30.4213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10079",
    WardLookupId: "3563",
    VDNumber: "43991567",
    RegisteredPopulation: "343",
    VotingStationName: "MAGEZA CRECHE",
    Address: "MAKHEHLENI  UMZUMBE  UMZUMBE",
    Latitude: "-30.5902",
    Longitude: "30.3982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10080",
    WardLookupId: "3563",
    VDNumber: "43991578",
    RegisteredPopulation: "917",
    VotingStationName: "UMALUSI PRIMARY SCHOOL",
    Address: "MARABA  PORT SHEPSTONE  QWABE P",
    Latitude: "-30.6217",
    Longitude: "30.3775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10081",
    WardLookupId: "3563",
    VDNumber: "43991635",
    RegisteredPopulation: "932",
    VotingStationName: "MEHLOMNYAMA PRIMARY SCHOOL",
    Address: "OLD ST FAITHS ROAD  MEHLOMNYAMA  UMZUMBE",
    Latitude: "-30.5992",
    Longitude: "30.3342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10082",
    WardLookupId: "3563",
    VDNumber: "43994302",
    RegisteredPopulation: "862",
    VotingStationName: "QWABE HALL",
    Address: "MQOKWENI  PORT SHEPSTONE  UMZUMBE [UMZUMBE]",
    Latitude: "-30.6118",
    Longitude: "30.4003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10083",
    WardLookupId: "3563",
    VDNumber: "43994728",
    RegisteredPopulation: "681",
    VotingStationName: "FRANKLAND COMMUNITY HALL",
    Address: "FRANKLAND  FRANKLAND LOCATION  QWABE P",
    Latitude: "-30.6235",
    Longitude: "30.3897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10084",
    WardLookupId: "3563",
    VDNumber: "43994739",
    RegisteredPopulation: "324",
    VotingStationName: "FRANKLAND P. SCHOOL",
    Address: "MQHOKWENI  ENKULU  QWABE P",
    Latitude: "-30.6029",
    Longitude: "30.4069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10085",
    WardLookupId: "3564",
    VDNumber: "43992265",
    RegisteredPopulation: "428",
    VotingStationName: "GIDELA JUNIOR SECONDARY SCHOOL",
    Address: "HIGHFLATS  UMZUMBE",
    Latitude: "-30.3848",
    Longitude: "30.2615",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10086",
    WardLookupId: "3564",
    VDNumber: "43992366",
    RegisteredPopulation: "365",
    VotingStationName: "SOVIYO LOWER PRIMARY SCHOOL",
    Address: "HLOKOZI ROAD, OETTING MISSION  HIGHFLATS  UMZUMBE",
    Latitude: "-30.4175",
    Longitude: "30.2523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10087",
    WardLookupId: "3564",
    VDNumber: "43992423",
    RegisteredPopulation: "495",
    VotingStationName: "MABHELENI COMMUNITY HALL",
    Address: "PO HIBBERDENE  ST PATRICKS MISSION  UMZUMBE",
    Latitude: "-30.4421",
    Longitude: "30.3208",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10088",
    WardLookupId: "3564",
    VDNumber: "43992445",
    RegisteredPopulation: "421",
    VotingStationName: "KWATATE COMMUNITY HALL",
    Address: "HIGHFLATS  UMZUMBE",
    Latitude: "-30.4303",
    Longitude: "30.3004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10089",
    WardLookupId: "3564",
    VDNumber: "43992793",
    RegisteredPopulation: "242",
    VotingStationName: "OETTING MISSION",
    Address: "HIGHFLATS  UMZUMBE",
    Latitude: "-30.3578",
    Longitude: "30.305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10090",
    WardLookupId: "3564",
    VDNumber: "43992917",
    RegisteredPopulation: "966",
    VotingStationName: "PHEMBUKUKHANYA P SCHOOL",
    Address: "GOBHAMEHLO NYAVINI  NYAVINI  NYAVINI",
    Latitude: "-30.385",
    Longitude: "30.3225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10091",
    WardLookupId: "3564",
    VDNumber: "43994740",
    RegisteredPopulation: "342",
    VotingStationName: "NO. 7 CRECHE",
    Address: "NYAVINI  NO.7  NYAVINI",
    Latitude: "-30.3682",
    Longitude: "30.3501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10092",
    WardLookupId: "3565",
    VDNumber: "43990690",
    RegisteredPopulation: "169",
    VotingStationName: "SANGQU COMBINED PRIMARY SCHOOL",
    Address: "UMZINTO  UMZUMBE",
    Latitude: "-30.4317",
    Longitude: "30.4206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10093",
    WardLookupId: "3565",
    VDNumber: "43990702",
    RegisteredPopulation: "346",
    VotingStationName: "NYAVINI PRIMARY SCHOOL",
    Address: "UMZINTO  UMZUMBE",
    Latitude: "-30.4045",
    Longitude: "30.3838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10094",
    WardLookupId: "3565",
    VDNumber: "43990735",
    RegisteredPopulation: "613",
    VotingStationName: "AMAHLAYA PRIMARY SCHOOL",
    Address: "AMAHLAYA RIDGE FARM  UMZINTO  UMZUMBE",
    Latitude: "-30.3508",
    Longitude: "30.4129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10095",
    WardLookupId: "3565",
    VDNumber: "43990993",
    RegisteredPopulation: "501",
    VotingStationName: "GOBUME HIGH SCHOOL",
    Address: "UMZINTO  UMZUMBE",
    Latitude: "-30.3705",
    Longitude: "30.4434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10096",
    WardLookupId: "3565",
    VDNumber: "43991028",
    RegisteredPopulation: "457",
    VotingStationName: "NHLASANE PRIMARY SCHOOL",
    Address: "PO BOX 28101  UMZINTO  UMZUMBE",
    Latitude: "-30.3908",
    Longitude: "30.3425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10097",
    WardLookupId: "3565",
    VDNumber: "43992276",
    RegisteredPopulation: "817",
    VotingStationName: "NONGWINYA JUNIOR PRIMARY SCHOOL",
    Address: "UMZINTO  UMZUMBE",
    Latitude: "-30.3536",
    Longitude: "30.3339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10098",
    WardLookupId: "3565",
    VDNumber: "43993097",
    RegisteredPopulation: "421",
    VotingStationName: "ZISUKUMELE PRIMARY SCHOOL",
    Address: "UMZINTO  UMZUMBE",
    Latitude: "-30.3587",
    Longitude: "30.3697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10099",
    WardLookupId: "3565",
    VDNumber: "43993637",
    RegisteredPopulation: "614",
    VotingStationName: "MNGOMENI HIGH SCHOOL",
    Address: "UMZINTO  ",
    Latitude: "-30.3858",
    Longitude: "30.3666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10100",
    WardLookupId: "3565",
    VDNumber: "43994751",
    RegisteredPopulation: "379",
    VotingStationName: "MAQHIKIZANA H SCHOOL",
    Address: "UMZINTO  NYAVINI  NYAVINI",
    Latitude: "-30.346",
    Longitude: "30.3588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10101",
    WardLookupId: "3566",
    VDNumber: "43990746",
    RegisteredPopulation: "774",
    VotingStationName: "SHEEP WALK COMMUNITY CENTRE",
    Address: "UMZINTO  UMZUMBE",
    Latitude: "-30.3399",
    Longitude: "30.4614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10102",
    WardLookupId: "3566",
    VDNumber: "43993110",
    RegisteredPopulation: "716",
    VotingStationName: "NOBUZWE SCHOOL",
    Address: "MBEYANE  UMZINTO  ZIMPETHUZENDLOVU",
    Latitude: "-30.3503",
    Longitude: "30.488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10103",
    WardLookupId: "3566",
    VDNumber: "43993266",
    RegisteredPopulation: "1126",
    VotingStationName: "MGAYI PRIMARY SCHOOL",
    Address: "UMZINTO  UMZUMBE",
    Latitude: "-30.3851",
    Longitude: "30.4755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10104",
    WardLookupId: "3566",
    VDNumber: "43993558",
    RegisteredPopulation: "663",
    VotingStationName: "AMEN CRECHE",
    Address: "UMZINTO  UMZUMBE",
    Latitude: "-30.3765",
    Longitude: "30.471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10105",
    WardLookupId: "3566",
    VDNumber: "43994289",
    RegisteredPopulation: "728",
    VotingStationName: "NOGUDUKA COMMUNITY HALL",
    Address: "UMZINTO ROAD  UMZINTO  UMZUMBE [UMZUMBE]",
    Latitude: "-30.3419",
    Longitude: "30.4713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10106",
    WardLookupId: "3567",
    VDNumber: "43990724",
    RegisteredPopulation: "202",
    VotingStationName: "UMSIKAZI PRIMARY SCHOOL",
    Address: "UMZINTO  UMZUMBE",
    Latitude: "-30.4395",
    Longitude: "30.4696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10107",
    WardLookupId: "3567",
    VDNumber: "43990870",
    RegisteredPopulation: "548",
    VotingStationName: "WILDER JUNIOR PRIMARY SCHOOL",
    Address: "MTWALUME  UMZUMBE",
    Latitude: "-30.4644",
    Longitude: "30.5352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10108",
    WardLookupId: "3567",
    VDNumber: "43990892",
    RegisteredPopulation: "770",
    VotingStationName: "MTWALUME HIGH SCHOOL",
    Address: "UMZINTO  UMZUMBE",
    Latitude: "-30.4498",
    Longitude: "30.535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10109",
    WardLookupId: "3567",
    VDNumber: "43990904",
    RegisteredPopulation: "1135",
    VotingStationName: "KWA-BHAVU HIGH SCHOOL",
    Address: "UMZINTO  UMZUMBE",
    Latitude: "-30.4209",
    Longitude: "30.5476",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10110",
    WardLookupId: "3567",
    VDNumber: "43992669",
    RegisteredPopulation: "397",
    VotingStationName: "QOLOQOLO TRIBAL COURT",
    Address: "UMZINTO  QOLOQOLO  QOLOQOLO TRIBAL AUTHORITY",
    Latitude: "-30.4438",
    Longitude: "30.5406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10111",
    WardLookupId: "3567",
    VDNumber: "43992827",
    RegisteredPopulation: "851",
    VotingStationName: "FUNDEDUZE COMBINED PRIMARY SCHOOL",
    Address: "UMZINTO  UMZUMBE",
    Latitude: "-30.4187",
    Longitude: "30.5739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10112",
    WardLookupId: "3568",
    VDNumber: "43990836",
    RegisteredPopulation: "774",
    VotingStationName: "BANTU CONGEGATIONAL CHURCH",
    Address: "HIBBERDENE  TURTON  MATHULINI TRIBAL",
    Latitude: "-30.5051",
    Longitude: "30.5955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10113",
    WardLookupId: "3568",
    VDNumber: "43990960",
    RegisteredPopulation: "3229",
    VotingStationName: "ESIBANINI COMMUNITY HALL",
    Address: "ISIPOFU  NYANGWINI  THULINI",
    Latitude: "-30.4985",
    Longitude: "30.5775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10114",
    WardLookupId: "3569",
    VDNumber: "43990948",
    RegisteredPopulation: "465",
    VotingStationName: "BHEKISIZWE CRECHE",
    Address: "MAHWAQA  HIBBERDENE  THULINI TRIBAL AUTHORITY",
    Latitude: "-30.5277",
    Longitude: "30.5415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10115",
    WardLookupId: "3569",
    VDNumber: "43990959",
    RegisteredPopulation: "1130",
    VotingStationName: "BONGUZWANE HIGH SCHOOL",
    Address: "NYANGWINI  MTWALUME  THULINI",
    Latitude: "-30.5064",
    Longitude: "30.5453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10116",
    WardLookupId: "3569",
    VDNumber: "43993941",
    RegisteredPopulation: "796",
    VotingStationName: "NHLABAMKHOSI TRAINING CENTRE",
    Address: "ISIPHOFU  UMTWALUME  THULINI",
    Latitude: "-30.5042",
    Longitude: "30.5522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10117",
    WardLookupId: "3569",
    VDNumber: "43993974",
    RegisteredPopulation: "655",
    VotingStationName: "AFRICA CHURCH",
    Address: "NYANGWINI  HIBBERDENE  THULINI",
    Latitude: "-30.5136",
    Longitude: "30.5499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10118",
    WardLookupId: "3569",
    VDNumber: "43994773",
    RegisteredPopulation: "679",
    VotingStationName: "ESIQUNGENI GARAGE HALL",
    Address: "NYANGWINI  ESIQUNGENI  THULINI TRIBAL AUTHORITY",
    Latitude: "-30.5027",
    Longitude: "30.5656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10119",
    WardLookupId: "3570",
    VDNumber: "43990689",
    RegisteredPopulation: "688",
    VotingStationName: "MFIMFITHA MPCC",
    Address: "THALENI  HIBBERDENE  MABHELENI",
    Latitude: "-30.4934",
    Longitude: "30.3256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10120",
    WardLookupId: "3570",
    VDNumber: "43990937",
    RegisteredPopulation: "499",
    VotingStationName: "ENKUNGWINI COMBINED PRIMARY SCHOOL",
    Address: "MNGENI  HIBBERDENE  QWABE N",
    Latitude: "-30.4823",
    Longitude: "30.4886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10121",
    WardLookupId: "3570",
    VDNumber: "43991017",
    RegisteredPopulation: "695",
    VotingStationName: "INQOLOBANE JUNIOR SECONDARY SCHOOL",
    Address: "UMZUMBE  HIBBERDENE  MABHELENI",
    Latitude: "-30.4771",
    Longitude: "30.3693",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10122",
    WardLookupId: "3570",
    VDNumber: "43992434",
    RegisteredPopulation: "247",
    VotingStationName: "GUBHUZA PRIMARY SCHOOL",
    Address: "GUBHUZA  PORT SHEPSTONE  MABHELENI",
    Latitude: "-30.4805",
    Longitude: "30.2661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10123",
    WardLookupId: "3570",
    VDNumber: "43993378",
    RegisteredPopulation: "430",
    VotingStationName: "SBONGUJEKE HIGH SCHOOL",
    Address: "THOFETHI  HIBBERDENE  MABHELENI",
    Latitude: "-30.4501",
    Longitude: "30.3862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10124",
    WardLookupId: "3570",
    VDNumber: "43993626",
    RegisteredPopulation: "726",
    VotingStationName: "BHEKAMANDELU HIGH SCHOOL",
    Address: "FOKSENI  HIBBERDENE  MABHELENI",
    Latitude: "-30.4625",
    Longitude: "30.4177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10125",
    WardLookupId: "3570",
    VDNumber: "43993929",
    RegisteredPopulation: "49",
    VotingStationName: "THUTHUKANIMABHELE SCHOOL",
    Address: "UMZUMBE  HIBBERDENE  MABHELENI",
    Latitude: "-30.4568",
    Longitude: "30.2891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10126",
    WardLookupId: "3570",
    VDNumber: "43994403",
    RegisteredPopulation: "433",
    VotingStationName: "SBONGUMFEKA HIGH SCHOOL",
    Address: "HIGHFLATS  MABHELENI  MABHELENI TRIBAL AUTHORITY",
    Latitude: "-30.4552",
    Longitude: "30.3404",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10127",
    WardLookupId: "3571",
    VDNumber: "43991039",
    RegisteredPopulation: "424",
    VotingStationName: "ODEKE COMBINED PRIMARY SCHOOL",
    Address: "UMZUMBE  MORRISONS POST  NDELU",
    Latitude: "-30.5315",
    Longitude: "30.3868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10128",
    WardLookupId: "3571",
    VDNumber: "43991073",
    RegisteredPopulation: "1033",
    VotingStationName: "MVUTHULUKA HIGH SCHOOL",
    Address: "UMZUMBE  MORRISONS POST  NDELU",
    Latitude: "-30.5536",
    Longitude: "30.3895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10129",
    WardLookupId: "3571",
    VDNumber: "43991455",
    RegisteredPopulation: "333",
    VotingStationName: "KWAMQADI LOWER PRIMARY SCHOOL",
    Address: "UMZUMBE  MORRISON POST  NDELU",
    Latitude: "-30.5261",
    Longitude: "30.3504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10130",
    WardLookupId: "3571",
    VDNumber: "43992715",
    RegisteredPopulation: "882",
    VotingStationName: "BUHLEBETHU HIGH SCHOOL",
    Address: "MORRISON  UMZUMBE  NDELU",
    Latitude: "-30.5654",
    Longitude: "30.419",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10131",
    WardLookupId: "3571",
    VDNumber: "43993536",
    RegisteredPopulation: "538",
    VotingStationName: "MTUMASELI HIGH SCHOOL",
    Address: "MORRISON  UMZUMBE  KWAHLONGWA",
    Latitude: "-30.5609",
    Longitude: "30.4651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10132",
    WardLookupId: "3571",
    VDNumber: "43993659",
    RegisteredPopulation: "418",
    VotingStationName: "BAPHUMILE COMBINED PRIMARY SCHOOL",
    Address: "UMZUMBE  MORRISONS POST  NDELU",
    Latitude: "-30.5533",
    Longitude: "30.3546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10133",
    WardLookupId: "3572",
    VDNumber: "43991040",
    RegisteredPopulation: "640",
    VotingStationName: "MABUTHELA HIGH SCHOOL",
    Address: "UMZUMBE  MORRISONS  NDELU",
    Latitude: "-30.515",
    Longitude: "30.3965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10134",
    WardLookupId: "3572",
    VDNumber: "43991051",
    RegisteredPopulation: "630",
    VotingStationName: "KHATHI HIGH SCHOOL",
    Address: "NDUNGE  HIBBERDENE  QWABE N",
    Latitude: "-30.5072",
    Longitude: "30.4394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10135",
    WardLookupId: "3572",
    VDNumber: "43991062",
    RegisteredPopulation: "430",
    VotingStationName: "SHINGA TRIBAL COURT",
    Address: "UMZUMBE  MORRISON  NDELU",
    Latitude: "-30.5161",
    Longitude: "30.3839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10136",
    WardLookupId: "3572",
    VDNumber: "43991095",
    RegisteredPopulation: "512",
    VotingStationName: "KWA-ZAMOKUHLE COMBINED PRIMARY SCHOOL",
    Address: "UMZUMBE  MORRISON POST  KWAHLONGWA",
    Latitude: "-30.5526",
    Longitude: "30.4463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10137",
    WardLookupId: "3572",
    VDNumber: "43991107",
    RegisteredPopulation: "1000",
    VotingStationName: "ROSETTENVILLE COMBINED PRIMARY SCHOOL",
    Address: "ICABHANE  HIBBERDENE  THULINI",
    Latitude: "-30.5421",
    Longitude: "30.4737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10138",
    WardLookupId: "3572",
    VDNumber: "43993648",
    RegisteredPopulation: "535",
    VotingStationName: "ETSHENI COMBINED PRIMARY SCHOOL",
    Address: "UMZUMBE  MORRISONS POST  NDELU",
    Latitude: "-30.5324",
    Longitude: "30.4235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10139",
    WardLookupId: "3573",
    VDNumber: "43990847",
    RegisteredPopulation: "971",
    VotingStationName: "DINGIMBIZA LOWER PRIMARY SCHOOL",
    Address: "UMZUMBE  HIBBERDENE  THULINI",
    Latitude: "-30.4857",
    Longitude: "30.5567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10140",
    WardLookupId: "3573",
    VDNumber: "43990858",
    RegisteredPopulation: "1456",
    VotingStationName: "NOMAKHANZANE COMBINED PRIMARY SCHOOL",
    Address: "NOMAKHANZANE AREA  HIBBERDENE  THULINI",
    Latitude: "-30.4759",
    Longitude: "30.5842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10141",
    WardLookupId: "3573",
    VDNumber: "43990869",
    RegisteredPopulation: "460",
    VotingStationName: "UCC CHURCH",
    Address: "UMZUMBE  HIBBERDENE  QOLOQOLO",
    Latitude: "-30.4747",
    Longitude: "30.5435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10142",
    WardLookupId: "3573",
    VDNumber: "43992805",
    RegisteredPopulation: "671",
    VotingStationName: "KHANYA HIGH SCHOOL",
    Address: "QOLO QOLO TRIBAL AUTHORITY  OTHANDWENI  UMZUMBE",
    Latitude: "-30.4482",
    Longitude: "30.5704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10143",
    WardLookupId: "3573",
    VDNumber: "43992816",
    RegisteredPopulation: "652",
    VotingStationName: "OTHANDWENI CHURCH HALL",
    Address: "UMZUMBE  UMZINTO  QOLOQOLO",
    Latitude: "-30.4425",
    Longitude: "30.5759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10144",
    WardLookupId: "3573",
    VDNumber: "43994784",
    RegisteredPopulation: "441",
    VotingStationName: "SBONGUJEZA LP SCHOOL",
    Address: "UMZINTO  QOLOQOLO  QOLOQOLO",
    Latitude: "-30.4583",
    Longitude: "30.5734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10145",
    WardLookupId: "3574",
    VDNumber: "43990926",
    RegisteredPopulation: "182",
    VotingStationName: "NTENGO HIGHER PRIMARY SCHOOL",
    Address: "SIPOFU ROAD  UMZUMBE  UMZUMBE",
    Latitude: "-30.4718",
    Longitude: "30.4519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10146",
    WardLookupId: "3574",
    VDNumber: "43990971",
    RegisteredPopulation: "110",
    VotingStationName: "SIZANAYO HIGH SCHOOL",
    Address: "MTWALUME  UMZUMBE",
    Latitude: "-30.4828",
    Longitude: "30.4464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10147",
    WardLookupId: "3574",
    VDNumber: "43991006",
    RegisteredPopulation: "609",
    VotingStationName: "SOSUKWANA PRIMARY SCHOOL",
    Address: "HIBBERDENE  UMZUMBE",
    Latitude: "-30.4942",
    Longitude: "30.4236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10148",
    WardLookupId: "3574",
    VDNumber: "43993255",
    RegisteredPopulation: "618",
    VotingStationName: "ICABHANE COMBINED PRIMARY SCHOOL",
    Address: "ICABHANE  HIBBERDENE  THULINI",
    Latitude: "-30.5336",
    Longitude: "30.504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10149",
    WardLookupId: "3574",
    VDNumber: "43993525",
    RegisteredPopulation: "967",
    VotingStationName: "VELIMEMEZE PRIMARY SCHOOL",
    Address: "HIBBERDENE  UMZUMBE",
    Latitude: "-30.5593",
    Longitude: "30.5031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10150",
    WardLookupId: "3574",
    VDNumber: "43993547",
    RegisteredPopulation: "710",
    VotingStationName: "MALUKHAKHA COMBINED PRIMARY SCHOOL",
    Address: "UMGUBO  PORT SHEPSTONE  KWAHLONGWA",
    Latitude: "-30.5769",
    Longitude: "30.4785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10151",
    WardLookupId: "3574",
    VDNumber: "43993615",
    RegisteredPopulation: "606",
    VotingStationName: "NDUNGE HIGHER PRIMARY SCHOOL",
    Address: "1 SIPOFU ROAD  QWABE LOCATION, MTWALUME  UMZUMBE",
    Latitude: "-30.5035",
    Longitude: "30.4661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10152",
    WardLookupId: "3574",
    VDNumber: "43993952",
    RegisteredPopulation: "319",
    VotingStationName: "VUSISIZWE CRECHE",
    Address: "KWANDELU LOC.  KWANDELU  UMZUMBE",
    Latitude: "-30.4907",
    Longitude: "30.4189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10153",
    WardLookupId: "3575",
    VDNumber: "43990757",
    RegisteredPopulation: "1153",
    VotingStationName: "SIBHEKULWANDLE PRIMARY SCHOOL",
    Address: "HIBBERDENE  UMZUMBE",
    Latitude: "-30.534",
    Longitude: "30.6024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10154",
    WardLookupId: "3575",
    VDNumber: "43990779",
    RegisteredPopulation: "1383",
    VotingStationName: "GOBHELA CP SCHOOL",
    Address: "ISIPOFU ROAD  MATHULINI  UMZUMBE",
    Latitude: "-30.5404",
    Longitude: "30.5861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10155",
    WardLookupId: "3575",
    VDNumber: "43994795",
    RegisteredPopulation: "956",
    VotingStationName: "POWER OF FAITH",
    Address: "HIBBERDENE  MFAZAZANE  THULINI",
    Latitude: "-30.5287",
    Longitude: "30.5955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10156",
    WardLookupId: "3576",
    VDNumber: "43990713",
    RegisteredPopulation: "521",
    VotingStationName: "KWAFICA COMMUNITY HALL",
    Address: "HIBBERDENE  MATHULINI  THULINI",
    Latitude: "-30.531",
    Longitude: "30.5836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10157",
    WardLookupId: "3576",
    VDNumber: "43993963",
    RegisteredPopulation: "1206",
    VotingStationName: "MATHULINI CRECHE",
    Address: "MTHWALUME  THULINI  UMZUMBE",
    Latitude: "-30.5379",
    Longitude: "30.5812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10158",
    WardLookupId: "3576",
    VDNumber: "43994391",
    RegisteredPopulation: "1424",
    VotingStationName: "MFULI CHURCH",
    Address: "UMZUMBE  HIBBERDENE  THULINI",
    Latitude: "-30.5487",
    Longitude: "30.5866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10159",
    WardLookupId: "3576",
    VDNumber: "43994414",
    RegisteredPopulation: "720",
    VotingStationName: "MGANGENI CRECHE HALL",
    Address: "HIBBERDENE  MGANGENI  MATHULINI TRIBAL AUTHORITY",
    Latitude: "-30.5361",
    Longitude: "30.5581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10160",
    WardLookupId: "3577",
    VDNumber: "43990803",
    RegisteredPopulation: "1283",
    VotingStationName: "BONGINDUNA PRIMARY SCHOOL",
    Address: "MTWALUME  UMZUMBE",
    Latitude: "-30.511",
    Longitude: "30.6173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10161",
    WardLookupId: "3577",
    VDNumber: "43990814",
    RegisteredPopulation: "1523",
    VotingStationName: "MNAFU HIGH SCHOOL",
    Address: "HIBBERDENE  UMZUMBE",
    Latitude: "-30.5061",
    Longitude: "30.6007",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10162",
    WardLookupId: "3577",
    VDNumber: "43990825",
    RegisteredPopulation: "1070",
    VotingStationName: "FREE CHRISTIAN BROTHERS CHURCH",
    Address: "MTWALUME  UMZUMBE",
    Latitude: "-30.5006",
    Longitude: "30.6236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10163",
    WardLookupId: "3578",
    VDNumber: "43990768",
    RegisteredPopulation: "1087",
    VotingStationName: "COTHOZA LUTHULI CRECHE",
    Address: "MATHULINI TRIBAL AUTHORITY  MATHULINI  UMZUMBE",
    Latitude: "-30.5199",
    Longitude: "30.5964",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10164",
    WardLookupId: "3578",
    VDNumber: "43990780",
    RegisteredPopulation: "793",
    VotingStationName: "BANGIBIZO JUNIOR PRIMARY SCHOOL",
    Address: "HIBBERDENE  UMZUMBE",
    Latitude: "-30.5262",
    Longitude: "30.5835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10165",
    WardLookupId: "3578",
    VDNumber: "43994278",
    RegisteredPopulation: "653",
    VotingStationName: "KHULAMNTWANA CRECHE",
    Address: "TURTON ROAD  HIBBERDENE  UMZUMBE [UMZUMBE]",
    Latitude: "-30.5156",
    Longitude: "30.604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10166",
    WardLookupId: "3578",
    VDNumber: "43994762",
    RegisteredPopulation: "685",
    VotingStationName: "EKUPHILENI ASSEMBLIES OF GOD",
    Address: "HIBBERDENE  MAKHOSO  THULINI",
    Latitude: "-30.5151",
    Longitude: "30.5882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10167",
    WardLookupId: "3578",
    VDNumber: "43994807",
    RegisteredPopulation: "714",
    VotingStationName: "NYIDE CHURCH",
    Address: "HIBBERDENE  EBHUNWINI  THULINI",
    Latitude: "-30.5134",
    Longitude: "30.6071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10168",
    WardLookupId: "3579",
    VDNumber: "43991310",
    RegisteredPopulation: "318",
    VotingStationName: "MZOMUHLE CRECHE",
    Address: "BOZANA WARD 01  BOZANA WARD1. KWA-DUMISA  HARDING",
    Latitude: "-30.5545",
    Longitude: "30.0332",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10169",
    WardLookupId: "3579",
    VDNumber: "43991321",
    RegisteredPopulation: "302",
    VotingStationName: "EKHUZA PRIMARY SCHOOL",
    Address: "XANBU  UGU  THOKOZANI MADUMISA TRIBAL AUTHORITY",
    Latitude: "-30.56",
    Longitude: "30.0106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10170",
    WardLookupId: "3579",
    VDNumber: "43992412",
    RegisteredPopulation: "928",
    VotingStationName: "UMZOKHANYAYO PRIMARY SCHOOL",
    Address: "121 SIHOQO WARD 01  FODO TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.483",
    Longitude: "30.021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10171",
    WardLookupId: "3579",
    VDNumber: "43992467",
    RegisteredPopulation: "934",
    VotingStationName: "MBAMBUYE PRIMARY SCHOOL",
    Address: "121 NKONENI WARD 01  FODO TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.5235",
    Longitude: "30.0559",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10172",
    WardLookupId: "3579",
    VDNumber: "43993086",
    RegisteredPopulation: "469",
    VotingStationName: "MAHELANE HIGH SCHOOL",
    Address: "MAMBOTHO WARD 01  MAMBOTHO TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.5791",
    Longitude: "30.0458",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10173",
    WardLookupId: "3579",
    VDNumber: "43993491",
    RegisteredPopulation: "746",
    VotingStationName: "SAKHAYEDWA PRIMARY SCHOOL",
    Address:
      "121 GUDLUCINGO WARD 01  THOKOZANI MADUMISA TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.5314",
    Longitude: "30.0022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10174",
    WardLookupId: "3579",
    VDNumber: "43994650",
    RegisteredPopulation: "528",
    VotingStationName: "ECEKEZA HIGH SCHOOL",
    Address: "121 FODO  UGU  FODO TRIBAL AUTHORITY",
    Latitude: "-30.5308",
    Longitude: "30.0247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10175",
    WardLookupId: "3580",
    VDNumber: "43991332",
    RegisteredPopulation: "635",
    VotingStationName: "MARSH MOUNT PRIMARY SCHOOL",
    Address: "250 JIJINTABA WARD 02  MAMBOTHO TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.5981",
    Longitude: "30.0087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10176",
    WardLookupId: "3580",
    VDNumber: "43991343",
    RegisteredPopulation: "657",
    VotingStationName: "LENKASI SECONDARY SCHOOL",
    Address: "165 MAMBOTHO  JABULANI BESHWAYO TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.6173",
    Longitude: "30.0231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10177",
    WardLookupId: "3580",
    VDNumber: "43991354",
    RegisteredPopulation: "591",
    VotingStationName: "GUGULETHU CRECHE.",
    Address: "ESIGANSENI  UGU  MAMBOTHO TRIBAL AOUTHORITY",
    Latitude: "-30.6397",
    Longitude: "30.0315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10178",
    WardLookupId: "3580",
    VDNumber: "43991859",
    RegisteredPopulation: "205",
    VotingStationName: "SIYEPHU PRIMARY SCHOOL",
    Address: "250 NDLOVINI WARD 02  MAMBUTHO TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.5866",
    Longitude: "30.0624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10179",
    WardLookupId: "3580",
    VDNumber: "43991860",
    RegisteredPopulation: "180",
    VotingStationName: "EKUZAMENI PRIMARY SCHOOL",
    Address: "MPESHU WARD 02  MAMBOTHO TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.6306",
    Longitude: "30.0642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10180",
    WardLookupId: "3580",
    VDNumber: "43992489",
    RegisteredPopulation: "348",
    VotingStationName: "UMZIMKULU PRIMARY SCHOOL",
    Address: "ISABELO WARD 02  MAMBOTHO TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.6181",
    Longitude: "30.1186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10181",
    WardLookupId: "3580",
    VDNumber: "43992490",
    RegisteredPopulation: "947",
    VotingStationName: "JAMANGWENI PRIMARY SCHOOL",
    Address: "MNKANGALA WARD 01  KWAFODO TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.5694",
    Longitude: "30.082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10182",
    WardLookupId: "3580",
    VDNumber: "43992513",
    RegisteredPopulation: "954",
    VotingStationName: "NCIYA JUNIOR SECONDARY SCHOOL",
    Address: "TRUST FARMS  MAMBOTHO TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.6222",
    Longitude: "30.0428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10183",
    WardLookupId: "3580",
    VDNumber: "43993480",
    RegisteredPopulation: "876",
    VotingStationName: "MOUNT NEBO CHURCH",
    Address: "SIKHULU WARD 03  MAMBOTHO TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.6006",
    Longitude: "29.9882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10184",
    WardLookupId: "3580",
    VDNumber: "43993806",
    RegisteredPopulation: "166",
    VotingStationName: "MAGEBA PRIMARY SCHOOL",
    Address: "165 NDLOVINI  MAMBOTHO TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.6058",
    Longitude: "30.0887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10185",
    WardLookupId: "3581",
    VDNumber: "44000019",
    RegisteredPopulation: "2191",
    VotingStationName: "HARDING TOWN COMMUNITY HALL",
    Address: "MURCHISON ST  UGU  HARDING",
    Latitude: "-30.5767",
    Longitude: "29.8793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10186",
    WardLookupId: "3581",
    VDNumber: "44000020",
    RegisteredPopulation: "2549",
    VotingStationName: "SUTTON PRIMARY SCHOOL",
    Address: "SHEPSTONE STREET  UGU  HARDING",
    Latitude: "-30.5827",
    Longitude: "29.8947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10187",
    WardLookupId: "3581",
    VDNumber: "44000031",
    RegisteredPopulation: "1032",
    VotingStationName: "ST ANDREWS MISSION",
    Address: "BALLANCE STREET  UGU  HARDING",
    Latitude: "-30.5749",
    Longitude: "29.8905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10188",
    WardLookupId: "3582",
    VDNumber: "43991231",
    RegisteredPopulation: "1314",
    VotingStationName: "MACHI HIGHER PRIMARY SCHOOL",
    Address: "ELANGENI WARD  KWA MACHI T/A  HARDING",
    Latitude: "-30.685",
    Longitude: "29.8805",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10189",
    WardLookupId: "3582",
    VDNumber: "43991242",
    RegisteredPopulation: "683",
    VotingStationName: "UNITED CONGREGATIONAL CHURCH",
    Address: "KWA-GQIGQA PLACE  OCINGWENI WARD 4  HARDING",
    Latitude: "-30.6487",
    Longitude: "29.9043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10190",
    WardLookupId: "3582",
    VDNumber: "43991253",
    RegisteredPopulation: "1204",
    VotingStationName: "EMJALISWENI PRIMARY SCHOOL",
    Address: "MJALISWENI MAIN ROAD  KWA MACHI T/A  HARDING",
    Latitude: "-30.6385",
    Longitude: "29.8836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10191",
    WardLookupId: "3582",
    VDNumber: "43993794",
    RegisteredPopulation: "687",
    VotingStationName: "SALEM PRIMARY SCHOOL",
    Address: "OCINGWENI WARD 4  KWA MACHI T/A  HARDING/UMUZIWABANTU",
    Latitude: "-30.6411",
    Longitude: "29.8996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10192",
    WardLookupId: "3583",
    VDNumber: "43991174",
    RegisteredPopulation: "932",
    VotingStationName: "MVUYO PRIMARY SCHOOL",
    Address: "PHILLIPINE ROAD  NTONGA WARD5 K/MACHI LOC.  HARDING",
    Latitude: "-30.7813",
    Longitude: "29.9936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10193",
    WardLookupId: "3583",
    VDNumber: "43991185",
    RegisteredPopulation: "1448",
    VotingStationName: "MBONWA HIGH SCHOOL",
    Address: "PHILLIPINE ROAD/MBANGWENI  IZIBONDA TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.74",
    Longitude: "30.0001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10194",
    WardLookupId: "3583",
    VDNumber: "43993851",
    RegisteredPopulation: "325",
    VotingStationName: "A U N BAPTIST CHURCH",
    Address: "KWA SHALI ROAD  NGUNJINI WARD 5 K/MACHI T/A  HARDING",
    Latitude: "-30.7622",
    Longitude: "30.0085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10195",
    WardLookupId: "3583",
    VDNumber: "43994470",
    RegisteredPopulation: "1102",
    VotingStationName: "MBELENI PRIMARY SCHOOL",
    Address: "NGUNJINI  HARDING  NGUNJINI",
    Latitude: "-30.7276",
    Longitude: "29.994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10196",
    WardLookupId: "3583",
    VDNumber: "43994481",
    RegisteredPopulation: "452",
    VotingStationName: "MZUKELA PRIMARY SCHOOL",
    Address: "KWAMACHI  HARDING  IZIBONDA TRIBAL AUTHORITY",
    Latitude: "-30.7548",
    Longitude: "30.0061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10197",
    WardLookupId: "3583",
    VDNumber: "43994661",
    RegisteredPopulation: "515",
    VotingStationName: "SUNRISE COMMUNITY HALL",
    Address: "INGUNJINI  UGU  IZIBONDA TRIBAL AUTHORITY",
    Latitude: "-30.762",
    Longitude: "29.99",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10198",
    WardLookupId: "3584",
    VDNumber: "43991196",
    RegisteredPopulation: "2045",
    VotingStationName: "INKANYEZI YAMACHI SENIOR PRIMARY SCHOOL",
    Address: "WELA  IZIBONDA TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.7095",
    Longitude: "29.9046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10199",
    WardLookupId: "3584",
    VDNumber: "43991219",
    RegisteredPopulation: "788",
    VotingStationName: "MJIKA PRIMARY SCHOOL",
    Address: "IZIBONDA TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.7297",
    Longitude: "29.8721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10200",
    WardLookupId: "3584",
    VDNumber: "43992614",
    RegisteredPopulation: "1241",
    VotingStationName: "MDLANGATHI HIGH SCHOOL",
    Address: "MTHENTHU  IZIBONDA TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.7318",
    Longitude: "29.9215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10201",
    WardLookupId: "3585",
    VDNumber: "43991141",
    RegisteredPopulation: "354",
    VotingStationName: "THUTHUKA CRECHE",
    Address: "UNGQOLO   NHLANGANO TRIBAL AUTHORITY, WARD 7  HARDING",
    Latitude: "-30.6413",
    Longitude: "29.7064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10202",
    WardLookupId: "3585",
    VDNumber: "43991163",
    RegisteredPopulation: "380",
    VotingStationName: "GIVE HOPE PUBLIC SCHOOL",
    Address: "WEZA  IZIBONDA TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.5951",
    Longitude: "29.7496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10203",
    WardLookupId: "3585",
    VDNumber: "43991309",
    RegisteredPopulation: "1832",
    VotingStationName: "NTABA HIGHER PRIMARY SCHOOL",
    Address: "KWA JALI MAIN ROAD  KWA JALI  UMUZIWABANTU",
    Latitude: "-30.6398",
    Longitude: "29.7414",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10204",
    WardLookupId: "3585",
    VDNumber: "43992995",
    RegisteredPopulation: "1033",
    VotingStationName: "MKHOBA PRIMARY SCHOOL",
    Address: "MKHOBA   IZIBONDA TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.6279",
    Longitude: "29.7794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10205",
    WardLookupId: "3585",
    VDNumber: "43994672",
    RegisteredPopulation: "86",
    VotingStationName: "NURSERY VILLAGE (TENT)",
    Address: "WEZA  UGU  IZIBONDA",
    Latitude: "-30.5951",
    Longitude: "29.6892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10206",
    WardLookupId: "3585",
    VDNumber: "43994683",
    RegisteredPopulation: "504",
    VotingStationName: "KHANYANJALO CRECHE",
    Address: "MAWANE  UGU  IZIBONDA TRIBAL AUTHORITY",
    Latitude: "-30.6574",
    Longitude: "29.7635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10207",
    WardLookupId: "3585",
    VDNumber: "43994919",
    RegisteredPopulation: "313",
    VotingStationName: "APOSTOLIC FAITH MISSIOM",
    Address: "NGQOLO  UGU  NHLANGANO TRIBAL AUTHORITY",
    Latitude: "-30.629",
    Longitude: "29.7362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10208",
    WardLookupId: "3586",
    VDNumber: "43991118",
    RegisteredPopulation: "547",
    VotingStationName: "GAYIGA PRIMARY SCHOOL",
    Address: "NDAKENI   IZIBONDA TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.6468",
    Longitude: "29.8257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10209",
    WardLookupId: "3586",
    VDNumber: "43991286",
    RegisteredPopulation: "1804",
    VotingStationName: "IKWEZI LAMACHI PRIMARY SCHOOL",
    Address:
      "KWAMBONWA MAIN ROAD  IKHWEZI WARD 8, IZIBONDA TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.6239",
    Longitude: "29.8055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10210",
    WardLookupId: "3586",
    VDNumber: "43992984",
    RegisteredPopulation: "895",
    VotingStationName: "JOLWAYO HIGH SCHOOL",
    Address: "KWA MBONWA MAIN ROAD  IZIBONDA TRIBAL AUTHORITY, WARD 8  HARDING",
    Latitude: "-30.6395",
    Longitude: "29.7859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10211",
    WardLookupId: "3586",
    VDNumber: "43993503",
    RegisteredPopulation: "697",
    VotingStationName: "GAMA CAR WASH",
    Address: "MACHI  UGU  IZIBONDA TA",
    Latitude: "-30.6205",
    Longitude: "29.8156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10212",
    WardLookupId: "3586",
    VDNumber: "43994920",
    RegisteredPopulation: "492",
    VotingStationName: "IKHWEZI MISSION",
    Address: "EKHWEZI  UGU  IZIBONDA TRIBAL AUTHORITY",
    Latitude: "-30.62",
    Longitude: "29.799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10213",
    WardLookupId: "3587",
    VDNumber: "43991129",
    RegisteredPopulation: "885",
    VotingStationName: "MPHIKWA PRIMARY SCHOOL",
    Address: "ESIKHULU   NHLANGANO TRIBAL AUTHORITY, WARD 9  HARDING",
    Latitude: "-30.6857",
    Longitude: "29.7819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10214",
    WardLookupId: "3587",
    VDNumber: "43991130",
    RegisteredPopulation: "993",
    VotingStationName: "ESIVIVANENI PRIMARY SCHOOL",
    Address: "T 914  NHLANGANO TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.6626",
    Longitude: "29.7094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10215",
    WardLookupId: "3587",
    VDNumber: "43991264",
    RegisteredPopulation: "605",
    VotingStationName: "INCABHELA PRIMARY SCHOOL",
    Address: "MKHOBA   IZIBONDA TRIBAL AUTHORITY, WARD 9  HARDING",
    Latitude: "-30.6666",
    Longitude: "29.8095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10216",
    WardLookupId: "3587",
    VDNumber: "43991275",
    RegisteredPopulation: "1125",
    VotingStationName: "ST TERESA PRIMARY SCHOOL",
    Address: "ISITEZI MAIN ROAD  IZIBONDA TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.6793",
    Longitude: "29.8241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10217",
    WardLookupId: "3587",
    VDNumber: "43991297",
    RegisteredPopulation: "1029",
    VotingStationName: "KWA JALI COMMUNITY HALL",
    Address: "KWA JALI  NHLANGANO TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.665",
    Longitude: "29.7418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10218",
    WardLookupId: "3587",
    VDNumber: "43993312",
    RegisteredPopulation: "667",
    VotingStationName: "KUBHUDLU PRIMARY SCHOOL",
    Address: "NKUNGWINI   NHLANGANO TRIBAL AUTHORITY, WARD 9  HARDING",
    Latitude: "-30.7021",
    Longitude: "29.7358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10219",
    WardLookupId: "3588",
    VDNumber: "43991400",
    RegisteredPopulation: "581",
    VotingStationName: "ENYANDENI PRIMARY SCHOOL",
    Address: "NQABENI  IZIBONDA TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.7518",
    Longitude: "29.9398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10220",
    WardLookupId: "3588",
    VDNumber: "43991411",
    RegisteredPopulation: "633",
    VotingStationName: "EMZINHLANGA PRIMARY SCHOOL",
    Address: "NQABENI  IZIBONDA TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.7489",
    Longitude: "29.9611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10221",
    WardLookupId: "3588",
    VDNumber: "43991422",
    RegisteredPopulation: "568",
    VotingStationName: "MAQAKALA HIGHER PRIMARY SCHOOL",
    Address: "IZIBONDA TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.7423",
    Longitude: "29.9081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10222",
    WardLookupId: "3588",
    VDNumber: "43993053",
    RegisteredPopulation: "1402",
    VotingStationName: "UKUKHANYA KUFIKILE CRECHE",
    Address: "IZIBONDA TRIBAL AUTHORITY  HARDING",
    Latitude: "-30.7237",
    Longitude: "29.9551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10223",
    WardLookupId: "3588",
    VDNumber: "43993727",
    RegisteredPopulation: "383",
    VotingStationName: "MBANGWENI PRIMARY SCHOOL",
    Address: "MBANGWENI   IZIBONDA TRIBAL AUTHORITY, WARD 6  HARDING",
    Latitude: "-30.7015",
    Longitude: "29.9527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10224",
    WardLookupId: "3588",
    VDNumber: "43994447",
    RegisteredPopulation: "43",
    VotingStationName: "THEMBALETHU CHILDRENS HOME",
    Address: "HLUKU  UGU  HARDING",
    Latitude: "-30.6252",
    Longitude: "29.9263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10225",
    WardLookupId: "3588",
    VDNumber: "43994458",
    RegisteredPopulation: "119",
    VotingStationName: "NQABENI PRIMARY SCHOOL",
    Address: "NQABENI  IZIBONDA TRIBAL AUTHORITY   HARDING",
    Latitude: "-30.698",
    Longitude: "30.0437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10226",
    WardLookupId: "1297",
    VDNumber: "43992041",
    RegisteredPopulation: "511",
    VotingStationName: "WATERIDGE COLLEGE",
    Address: "CREDAL FARM IZINGOLWENI ROAD  PORT EDWARD  PORT EDWARD",
    Latitude: "-31.0376",
    Longitude: "30.184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10227",
    WardLookupId: "1297",
    VDNumber: "43992131",
    RegisteredPopulation: "1218",
    VotingStationName: "NOMBUSO HIGH SCHOOL",
    Address: "KWA-XOLO TRIBAL AUTHORITY  MUNSTER  MUNSTER",
    Latitude: "-30.9935",
    Longitude: "30.2392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10228",
    WardLookupId: "1297",
    VDNumber: "43992524",
    RegisteredPopulation: "935",
    VotingStationName: "ITHONGASI HIGHER PRIMARY SCHOOL",
    Address: "WARD 1 NZIMAKWE LOCATION  UGU - KWANZIMAKWE TA  MUNSTER",
    Latitude: "-31.0024",
    Longitude: "30.2435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10229",
    WardLookupId: "1297",
    VDNumber: "43994380",
    RegisteredPopulation: "1020",
    VotingStationName: "KWALATSHODA COMMUNITY HALL",
    Address: "KWALATSHODA LOW COST HOUSES  UGU  PORT EDWARD",
    Latitude: "-31.0086",
    Longitude: "30.2286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10230",
    WardLookupId: "1297",
    VDNumber: "44080017",
    RegisteredPopulation: "2204",
    VotingStationName: "PORT EDWARD TOWN HALL",
    Address: "354 DOVER ROAD  PORT EDWARD  PORT EDWARD",
    Latitude: "-31.0519",
    Longitude: "30.2256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10231",
    WardLookupId: "1298",
    VDNumber: "44030067",
    RegisteredPopulation: "2948",
    VotingStationName: "RHEMA SOUTH COAST FAMILY CHURCH",
    Address: "CORNER OF BOND AND SHORT STREE  UGU  RAMSGATE",
    Latitude: "-30.8807",
    Longitude: "30.3522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10232",
    WardLookupId: "1298",
    VDNumber: "44030102",
    RegisteredPopulation: "823",
    VotingStationName: "ASSEMBLE OF GOD",
    Address: "JOHN CANE ROAD - RAMSGATE  UGU  MARGATE",
    Latitude: "-30.8976",
    Longitude: "30.3291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10233",
    WardLookupId: "1299",
    VDNumber: "43991680",
    RegisteredPopulation: "797",
    VotingStationName: "IZOTSHA PRIMARY SCHOOL",
    Address: "CNR IZOTSHA AND GAMALAKHE RD  IZOTSHA  IZOTSHA",
    Latitude: "-30.783",
    Longitude: "30.3907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10234",
    WardLookupId: "1299",
    VDNumber: "44030023",
    RegisteredPopulation: "2070",
    VotingStationName: "SHELLY BEACH CIVIC OFFICES",
    Address: "SHEPSTONE DRIVE  SHELLY BEACH  MARGATE",
    Latitude: "-30.7948",
    Longitude: "30.419",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10235",
    WardLookupId: "1299",
    VDNumber: "44030089",
    RegisteredPopulation: "2000",
    VotingStationName: "MASINENGE CRECHE",
    Address:
      "MASINENGE INFORMAL SETTLEMENT  UGU  MASINENGE INFORMAL SETTLEMENT",
    Latitude: "-30.8381",
    Longitude: "30.3706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10236",
    WardLookupId: "1300",
    VDNumber: "43991589",
    RegisteredPopulation: "1762",
    VotingStationName: "COPHELA JUNIOR PRIMARY SCHOOL",
    Address: "ESITHEBENI WARD  LUSHABA TRIBAL AUTHORITY  PORT SHEPSTONE",
    Latitude: "-30.6393",
    Longitude: "30.3658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10237",
    WardLookupId: "1300",
    VDNumber: "43991613",
    RegisteredPopulation: "758",
    VotingStationName: "LUSHABA TRIBAL COURT",
    Address: "OSHABENI AREA  LUSHABA TRIBAL AUTHORITY  PORT SHEPSTONE",
    Latitude: "-30.6251",
    Longitude: "30.3627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10238",
    WardLookupId: "1300",
    VDNumber: "43991624",
    RegisteredPopulation: "1448",
    VotingStationName: "ASSISSI MISSION CONVENT",
    Address: "ENKULU LOCATIONWARD 4 OSHABENI  LUSHABA T/A  PORT SHEPSTONE",
    Latitude: "-30.6076",
    Longitude: "30.3543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10239",
    WardLookupId: "1300",
    VDNumber: "43993468",
    RegisteredPopulation: "1241",
    VotingStationName: "AFRICAN GOSPEL CHURCH",
    Address: "NKULU WARD  LUSHABA TRIBAL AUTHORITY  PORT SHEPSTONE",
    Latitude: "-30.6224",
    Longitude: "30.356",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10240",
    WardLookupId: "1301",
    VDNumber: "44030034",
    RegisteredPopulation: "2045",
    VotingStationName: "SETHEMBINKOSI PRIMARY SCHOOL",
    Address: "622 CHRIS HANI ROAD GAMALAKHE  GAMALAKHE  PORT SHEPSTONE",
    Latitude: "-30.799",
    Longitude: "30.3344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10241",
    WardLookupId: "1301",
    VDNumber: "44030045",
    RegisteredPopulation: "1803",
    VotingStationName: "ZIBAMBELENI OLD AGE DAY CARE CENTRE",
    Address: "481 GAMALAKHE NEXT TO CLINIC  GAMALAKHE  MARGATE",
    Latitude: "-30.8098",
    Longitude: "30.3311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10242",
    WardLookupId: "1301",
    VDNumber: "44030113",
    RegisteredPopulation: "1277",
    VotingStationName: "NSIMBINI PRIMARY SCHOOL",
    Address: "GAMALAKHE TOWNSHIP  MARGATE  MAVUNDLA",
    Latitude: "-30.7956",
    Longitude: "30.3297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10243",
    WardLookupId: "1302",
    VDNumber: "44030078",
    RegisteredPopulation: "3102",
    VotingStationName: "MARGATE RETIREMENT VILLAGE",
    Address: "01 NORTH DRIVE MARGATE  MARGATE  MARGATE",
    Latitude: "-30.8559",
    Longitude: "30.362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10244",
    WardLookupId: "1302",
    VDNumber: "44030090",
    RegisteredPopulation: "2082",
    VotingStationName: "MARGATE MIDDLE SCHOOL",
    Address:
      "288 HUMPREY ROAD -EXT 3 MARGATE  KZN216 - HIBISCUS COAST [PORT SHEPSTONE]",
    Latitude: "-30.8506",
    Longitude: "30.3548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10245",
    WardLookupId: "1303",
    VDNumber: "43992052",
    RegisteredPopulation: "614",
    VotingStationName: "PARADISE HOLIDAY RESORT",
    Address: "R61 BETWEEN SOUTHBROOM AND MARINA   MARINA BEACH  SOUTHBROOM",
    Latitude: "-30.9215",
    Longitude: "30.2952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10246",
    WardLookupId: "1303",
    VDNumber: "43992636",
    RegisteredPopulation: "1880",
    VotingStationName: "MBAMBI HIGH SCHOOL",
    Address: "KWA-XOLO TRIBAL AUTHORITY  MARGATE  MARGATE",
    Latitude: "-30.9144",
    Longitude: "30.2684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10247",
    WardLookupId: "1303",
    VDNumber: "43992748",
    RegisteredPopulation: "2481",
    VotingStationName: "PHATHWA HIGH SCHOOL",
    Address: "KWA-XOLO TRIBAL AUTHORITY  MARGATE  MARGATE",
    Latitude: "-30.9085",
    Longitude: "30.2468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10248",
    WardLookupId: "1303",
    VDNumber: "43993211",
    RegisteredPopulation: "507",
    VotingStationName: "NONTSHUNTSHA COMBINED PRIMARY SCHOOL",
    Address: "KWA-XOLO TRIBAL AUTHORITY  MARGATE  MARGATE",
    Latitude: "-30.8887",
    Longitude: "30.2531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10249",
    WardLookupId: "1303",
    VDNumber: "43994155",
    RegisteredPopulation: "313",
    VotingStationName: "CHURCH OF CHRIST",
    Address: "KWA XOLO  RAMSGATE  RAMSGATE",
    Latitude: "-30.8798",
    Longitude: "30.2735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10250",
    WardLookupId: "1304",
    VDNumber: "43992030",
    RegisteredPopulation: "1427",
    VotingStationName: "NKOTHANENI SEWING CENTRE",
    Address: "NKOTHANENI WARD  KWAXOLO T/A  PORT SHEPSTONE",
    Latitude: "-30.8908",
    Longitude: "30.2213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10251",
    WardLookupId: "1304",
    VDNumber: "43992119",
    RegisteredPopulation: "1069",
    VotingStationName: "KWAMPHELELEWA COMBINED PRIMARY SCHOOL",
    Address: "KWA-XOLO TRIBAL AUTHORITY  IZINGOLWENI  IZINGOLWENI",
    Latitude: "-30.9172",
    Longitude: "30.1853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10252",
    WardLookupId: "1304",
    VDNumber: "43992120",
    RegisteredPopulation: "478",
    VotingStationName: "DUMEZULU COMMUNITY HALL",
    Address: "KWAXOLO  KWAXOLO AREA  KWAXOLO",
    Latitude: "-30.8868",
    Longitude: "30.1849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10253",
    WardLookupId: "1304",
    VDNumber: "43992759",
    RegisteredPopulation: "818",
    VotingStationName: "KWAXOLO MPCC",
    Address: "WARD 8 KWAXOLO LOCATION  MARGATE  KWAXOLO",
    Latitude: "-30.8597",
    Longitude: "30.1797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10254",
    WardLookupId: "1304",
    VDNumber: "43994346",
    RegisteredPopulation: "356",
    VotingStationName: "ENYANISWENI PRIMARY SCHOOL",
    Address: "MANZAMHLOPHE  UGU  KWAXOLO",
    Latitude: "-30.8793",
    Longitude: "30.1952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10255",
    WardLookupId: "1305",
    VDNumber: "43992074",
    RegisteredPopulation: "2334",
    VotingStationName: "DLUKULWANE PRIMARY SCHOOL",
    Address: "KWA-XOLO TRIBAL AUTHORITY  MARGATE  MARGATE",
    Latitude: "-30.9304",
    Longitude: "30.2607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10256",
    WardLookupId: "1305",
    VDNumber: "43992647",
    RegisteredPopulation: "1381",
    VotingStationName: "MHLABULUNGILE COMBINED PRIMARY SCHOOL",
    Address: "BHOKODISA WARD  KWAXOLO TRIBAL AUTHORITY  IZINGOLWENI",
    Latitude: "-30.9383",
    Longitude: "30.2601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10257",
    WardLookupId: "1305",
    VDNumber: "43993682",
    RegisteredPopulation: "1642",
    VotingStationName: "DELIHLAZO PRIMARY SCHOOL",
    Address: "KWA-XOLO TRIBAL AUTHORITY  IZINGOLWENI",
    Latitude: "-30.9311",
    Longitude: "30.2322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10258",
    WardLookupId: "1305",
    VDNumber: "44020011",
    RegisteredPopulation: "880",
    VotingStationName: "MARINA BEACH COUNCIL CHAMBERS",
    Address: "13 MARINE DRIVE  MARINA BEACH  SOUTHBROOM/IMPENJATI",
    Latitude: "-30.9355",
    Longitude: "30.298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10259",
    WardLookupId: "1306",
    VDNumber: "43992063",
    RegisteredPopulation: "931",
    VotingStationName: "XHONYWA PRIMARY SCHOOL",
    Address: "WOZA WARD  NZIMAKWE TRIBAL AUTHORITY  MARGATE",
    Latitude: "-30.9465",
    Longitude: "30.2134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10260",
    WardLookupId: "1306",
    VDNumber: "43992085",
    RegisteredPopulation: "1505",
    VotingStationName: "KHABA SECONDARY SCHOOL",
    Address: "NZIMAKWE  KWANZIMAKWE",
    Latitude: "-30.9577",
    Longitude: "30.2411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10261",
    WardLookupId: "1306",
    VDNumber: "43992096",
    RegisteredPopulation: "681",
    VotingStationName: "ENTABENI COMBINED PRIMARY SCHOOL",
    Address: "NZIMAKWE TRIBAL AUTHORITY  MUNSTER  MUNSTER",
    Latitude: "-30.9712",
    Longitude: "30.2243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10262",
    WardLookupId: "1306",
    VDNumber: "43992108",
    RegisteredPopulation: "998",
    VotingStationName: "ENKONENI COMBINED PRIMARY SCHOOL",
    Address: "NZIMAKWE TRIBAL AUTHORITY  MARGATE  MARGATE",
    Latitude: "-30.9621",
    Longitude: "30.1947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10263",
    WardLookupId: "1306",
    VDNumber: "43993222",
    RegisteredPopulation: "1897",
    VotingStationName: "KWAMASOSHA HIGH SCHOOL",
    Address: "NZIMAKWE TRIBAL AUTHORITY  MUNSTER  MUNSTER",
    Latitude: "-30.9728",
    Longitude: "30.2359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10264",
    WardLookupId: "1307",
    VDNumber: "43993402",
    RegisteredPopulation: "1275",
    VotingStationName: "BREAMER COMMUNITY HALL",
    Address: "NZIMAKWE  KWANZIMAKWE  NZIMAKWE",
    Latitude: "-30.9667",
    Longitude: "30.1916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10265",
    WardLookupId: "1307",
    VDNumber: "43993716",
    RegisteredPopulation: "433",
    VotingStationName: "MUNSTER  SHOPING CENTRE",
    Address: "MUNSTER  UGU  MUNSTER",
    Latitude: "-30.9982",
    Longitude: "30.248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10266",
    WardLookupId: "1307",
    VDNumber: "43994065",
    RegisteredPopulation: "1413",
    VotingStationName: "KHANDANDLOVU BAPTIST CHURCH",
    Address: "NZIMAKWE LOCATION  NZIMAKWE T/A  MUNSTER",
    Latitude: "-30.9853",
    Longitude: "30.2318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10267",
    WardLookupId: "1307",
    VDNumber: "44080028",
    RegisteredPopulation: "717",
    VotingStationName: "MUNSTER SPORTS CLUB",
    Address: "258 VON BAUMBACH ROAD  MUNSTER  PORT EDWARD",
    Latitude: "-31.0066",
    Longitude: "30.2589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10268",
    WardLookupId: "1308",
    VDNumber: "44050014",
    RegisteredPopulation: "2510",
    VotingStationName: "PORT SHEPSTONE SECONDARY SCHOOL",
    Address: "103 ALBERSVILLE ROAD  ALBERSVILLE  PORT SHEPSTONE",
    Latitude: "-30.7256",
    Longitude: "30.4393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10269",
    WardLookupId: "1308",
    VDNumber: "44050058",
    RegisteredPopulation: "2105",
    VotingStationName: "UMTENTWENI TOWN HALL",
    Address: "281 ABLESIDE  UMTENTWENI  PORT SHEPSTONE",
    Latitude: "-30.7199",
    Longitude: "30.4732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10270",
    WardLookupId: "1309",
    VDNumber: "43991084",
    RegisteredPopulation: "586",
    VotingStationName: "ALLERTON STORE",
    Address: "DAVID  ROAD  HIBBERDENE  PORT SHEPSTONE",
    Latitude: "-30.5604",
    Longitude: "30.5525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10271",
    WardLookupId: "1309",
    VDNumber: "44010032",
    RegisteredPopulation: "1494",
    VotingStationName: "HIBBERDENE TOWN HALL",
    Address: "MAIN ROAD  HIIBERDENE  PORT SHEPSTONE",
    Latitude: "-30.5773",
    Longitude: "30.5712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10272",
    WardLookupId: "1309",
    VDNumber: "44010043",
    RegisteredPopulation: "928",
    VotingStationName: "ANGLICAN CHURCH UMZUMBE HALL",
    Address: "STATION ROAD  UMZUMBE  PORT SHEPSTONE",
    Latitude: "-30.6189",
    Longitude: "30.5434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10273",
    WardLookupId: "1309",
    VDNumber: "44010054",
    RegisteredPopulation: "277",
    VotingStationName: "LITTLE ANGEL PRE-SCHOOL",
    Address: "STICK FARM  HIBBEDENE",
    Latitude: "-30.5852",
    Longitude: "30.5429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10274",
    WardLookupId: "1309",
    VDNumber: "44010065",
    RegisteredPopulation: "576",
    VotingStationName: "INALA CP SCHOOL",
    Address: "FAIRVIEW  FAIRVIEW MISSION  MADLALA",
    Latitude: "-30.612",
    Longitude: "30.5189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10275",
    WardLookupId: "1310",
    VDNumber: "43991534",
    RegisteredPopulation: "1558",
    VotingStationName: "DENVER ZOAR HIGHER PRIMARY SCHOOL",
    Address: "CABHANE WARD  PORT SHEPSTONE  MADLALA TRIBAL AUTHORITY",
    Latitude: "-30.6167",
    Longitude: "30.4502",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10276",
    WardLookupId: "1310",
    VDNumber: "43991545",
    RegisteredPopulation: "2123",
    VotingStationName: "SOZABE HIGH SCHOOL",
    Address: "SENTOMBI WARD  MADLALA TRIBAL AUTHORITY  PORT SHEPSTONE",
    Latitude: "-30.6326",
    Longitude: "30.4322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10277",
    WardLookupId: "1310",
    VDNumber: "43991837",
    RegisteredPopulation: "389",
    VotingStationName: "MARIS STELLA SCHOOL",
    Address: "MARIS STELLA MISSION  MARIS STELLA  PORT SHEPSTONE",
    Latitude: "-30.659",
    Longitude: "30.3985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10278",
    WardLookupId: "1310",
    VDNumber: "43993660",
    RegisteredPopulation: "635",
    VotingStationName: "MADLALA TRIBAL COURT",
    Address: "CABHANE WARD  MADLALA TRIBAL AUTHORITY  PORT SHEPSTONE",
    Latitude: "-30.6175",
    Longitude: "30.4343",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10279",
    WardLookupId: "1310",
    VDNumber: "43994087",
    RegisteredPopulation: "507",
    VotingStationName: "MASHABA JUNIOR PRIMARY SCHOOL",
    Address: "MADLALA LOCATION  KWAMADLA T/A  UMZUMBE",
    Latitude: "-30.6049",
    Longitude: "30.4518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10280",
    WardLookupId: "1310",
    VDNumber: "43994492",
    RegisteredPopulation: "491",
    VotingStationName: "AFRICAN APOSTOLIC MISSION CHURCH",
    Address: "GREEN SIDE  HIBISCUS COAST  OSHABENI",
    Latitude: "-30.6379",
    Longitude: "30.3722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10281",
    WardLookupId: "1311",
    VDNumber: "43991512",
    RegisteredPopulation: "770",
    VotingStationName: "CHIBINI COMMUNITY HALL",
    Address: "WARD 15 MGOGO AREA KWAMADLALA  UGU -PORT SHEPSTONE  MADLALA",
    Latitude: "-30.6006",
    Longitude: "30.4891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10282",
    WardLookupId: "1311",
    VDNumber: "43991523",
    RegisteredPopulation: "500",
    VotingStationName: "MNTWANUNGAMZIZWE HIGH SCHOOL",
    Address:
      "MAGOG WARD AREA KWAMADLALA TA  - UGU-MADLALA TRIBAL AUTHORITY  PORT SHEPSTONE",
    Latitude: "-30.6026",
    Longitude: "30.4705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10283",
    WardLookupId: "1311",
    VDNumber: "43992210",
    RegisteredPopulation: "2124",
    VotingStationName: "LOUISIANA SCHOOL",
    Address: "P198 OLD MAIN ROAD  LOUISIANA  PORT SHEPSTONE",
    Latitude: "-30.6931",
    Longitude: "30.4466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10284",
    WardLookupId: "1311",
    VDNumber: "43992221",
    RegisteredPopulation: "377",
    VotingStationName: "INTSHAMBILI STORE",
    Address: "MELBORNE FARM -UMZUMBE  UGU  MELBORNE FARM",
    Latitude: "-30.6264",
    Longitude: "30.5182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10285",
    WardLookupId: "1311",
    VDNumber: "43994076",
    RegisteredPopulation: "381",
    VotingStationName: "KWASLWANE COMMUNITY HALL",
    Address: "OAK FARM  UGU-RAY NKONYENI MUNICIPALITY  MADLALA",
    Latitude: "-30.594",
    Longitude: "30.4651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10286",
    WardLookupId: "1311",
    VDNumber: "43994379",
    RegisteredPopulation: "345",
    VotingStationName: "ZIMISELE CRECHE",
    Address:
      "INTSHAMBILI FARM - MADLALA  UGU - HIBISCUS COAST [PORT SHEPSTONE]",
    Latitude: "-30.6919",
    Longitude: "30.4768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10287",
    WardLookupId: "1312",
    VDNumber: "44010021",
    RegisteredPopulation: "943",
    VotingStationName: "CAPUCHIN COVE",
    Address: "110 CAPUCHIN WAY  UGU  PUMULA",
    Latitude: "-30.6486",
    Longitude: "30.5225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10288",
    WardLookupId: "1312",
    VDNumber: "44050047",
    RegisteredPopulation: "2229",
    VotingStationName: "ROMAN CATHOLIC CHURCH HALL",
    Address: "SEA PARK  HIBISCUS COAST   SEA PRK",
    Latitude: "-30.6985",
    Longitude: "30.4836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10289",
    WardLookupId: "1312",
    VDNumber: "44050070",
    RegisteredPopulation: "1727",
    VotingStationName: "ANERLEY YOUTH CAMP",
    Address: "2 GOODINDS ROAD   UGU - ANERLEY  ANERLEY",
    Latitude: "-30.674",
    Longitude: "30.5082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10290",
    WardLookupId: "1313",
    VDNumber: "43994245",
    RegisteredPopulation: "914",
    VotingStationName: "MERLEWOOD PRIMARY  SCHOOL",
    Address:
      "01 PINE LANE MERLEWOOD  UGU -PORT SHEPSTONE  HIBISCUS COAST [PORT SHEPSTONE]",
    Latitude: "-30.7488",
    Longitude: "30.4106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10291",
    WardLookupId: "1313",
    VDNumber: "43994841",
    RegisteredPopulation: "574",
    VotingStationName: "HLANGANANI PRIMARY SCHOOL",
    Address: "MERELEWOOD (MKHOLOMBE)  UGU -PORT SHEPSTONE  MERELEWOOD",
    Latitude: "-30.744",
    Longitude: "30.4013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10292",
    WardLookupId: "1313",
    VDNumber: "44050036",
    RegisteredPopulation: "2558",
    VotingStationName: "MARBURG PRIMARY SCHOOL",
    Address: "17 WIMPEY CRESCENT  MARBURG  PORT SHEPSTONE",
    Latitude: "-30.7465",
    Longitude: "30.4209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10293",
    WardLookupId: "1313",
    VDNumber: "44050081",
    RegisteredPopulation: "712",
    VotingStationName: "MARBURG SECONDARY SCHOOL",
    Address: "MARBURG  UGU  PORT SHEPSTONE",
    Latitude: "-30.7504",
    Longitude: "30.4225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10294",
    WardLookupId: "1314",
    VDNumber: "44050025",
    RegisteredPopulation: "2702",
    VotingStationName: "PORT SHEPSTONE -CIVIC HALL",
    Address: "10 CONNOR STREET  PORT SHEPSTONE  PORT SHEPSTONE",
    Latitude: "-30.7402",
    Longitude: "30.4497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10295",
    WardLookupId: "1314",
    VDNumber: "44050069",
    RegisteredPopulation: "1510",
    VotingStationName: "SUID NATAL PRIMARY SCHOOL",
    Address:
      "PRINCE OLAF STREET -OSLO BEACH  OSLO BEACH - PORT SHEPSTONE  HIBISCUS COAST [PORT SHEPSTONE]",
    Latitude: "-30.7564",
    Longitude: "30.4396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10296",
    WardLookupId: "1315",
    VDNumber: "44030012",
    RegisteredPopulation: "4646",
    VotingStationName: "UVONGO TOWN HALL SUPPER ROOM",
    Address: "CRESCENT ROAD  UVONGO  MARGATE",
    Latitude: "-30.8314",
    Longitude: "30.3927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10297",
    WardLookupId: "1316",
    VDNumber: "43991747",
    RegisteredPopulation: "616",
    VotingStationName: "KWANDABEZINHLE PRIMARY SCHOOL",
    Address: "MBOTSHA WARD  NDWALANE TRIBAL AUTHORITY  PORT SHEPSTONE",
    Latitude: "-30.7374",
    Longitude: "30.3695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10298",
    WardLookupId: "1316",
    VDNumber: "43991758",
    RegisteredPopulation: "856",
    VotingStationName: "MGANKA PRIMARY SCHOOL",
    Address: "MGANKA WARD  NDWALANE TRIBAL AUTHORITY  PORT SHEPSTONE",
    Latitude: "-30.741",
    Longitude: "30.384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10299",
    WardLookupId: "1316",
    VDNumber: "43994098",
    RegisteredPopulation: "2738",
    VotingStationName: "MERLEWOOD SECONDARY SCHOOL",
    Address: "COLOMBO AREA,MAIN HARDING ROAD  MERLEWOOD  PORT SHEPSTONE",
    Latitude: "-30.749",
    Longitude: "30.4021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10300",
    WardLookupId: "1316",
    VDNumber: "43994122",
    RegisteredPopulation: "1165",
    VotingStationName: "SAE MISSION",
    Address: "BHOBHOYI LOCATION  NDWALANE T/A  PORT SHEPSTONE",
    Latitude: "-30.7345",
    Longitude: "30.396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10301",
    WardLookupId: "1316",
    VDNumber: "43994526",
    RegisteredPopulation: "975",
    VotingStationName: "ST FRANCIS ROMAN CHURCH",
    Address: "MURCHISON  UGU  NDWALANE",
    Latitude: "-30.733",
    Longitude: "30.3625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10302",
    WardLookupId: "1316",
    VDNumber: "43994537",
    RegisteredPopulation: "602",
    VotingStationName: "NGWABE COMMUNITY HALL",
    Address: "BHOBHOYI  UGU  NDWALANE",
    Latitude: "-30.7363",
    Longitude: "30.3988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10303",
    WardLookupId: "1316",
    VDNumber: "43994863",
    RegisteredPopulation: "240",
    VotingStationName: "OSLO BEACH TVET COLLEGE",
    Address: "58 OSLO BEACH  UGU  OLSO BEACH",
    Latitude: "-30.7753",
    Longitude: "30.4296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10304",
    WardLookupId: "1317",
    VDNumber: "43991792",
    RegisteredPopulation: "1556",
    VotingStationName: "MBHAYIMBHAYI COMMUNITY HALL",
    Address: "MBHAYIMBHAYI   UGU  NSIMBHINI",
    Latitude: "-30.725",
    Longitude: "30.3621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10305",
    WardLookupId: "1317",
    VDNumber: "43991804",
    RegisteredPopulation: "785",
    VotingStationName: "MTENGWANE COMMUNITY HALL",
    Address:
      "MTENGWANE AREA- PORT SHEPSTONE  UGU - PORT SHEPSTONE  NDWALANE T/A-PORT SHEPSTONE",
    Latitude: "-30.7187",
    Longitude: "30.369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10306",
    WardLookupId: "1317",
    VDNumber: "43994100",
    RegisteredPopulation: "1212",
    VotingStationName: "MDLAZI PRIMARY SCHOOL",
    Address: "MDLAZI WARD  NDWALANE T/A  PORT SHEPSTONE",
    Latitude: "-30.7228",
    Longitude: "30.381",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10307",
    WardLookupId: "1317",
    VDNumber: "43994111",
    RegisteredPopulation: "560",
    VotingStationName: "CHRISTIAN APOSTOLIC FAITH MISSION",
    Address: "BHOBHOYI LOCATION  NDWALANE T/A  PORT SHEPSTONE",
    Latitude: "-30.732",
    Longitude: "30.3818",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10308",
    WardLookupId: "1317",
    VDNumber: "43994548",
    RegisteredPopulation: "675",
    VotingStationName: "POWER OF GOD ASSEMBLY CHURCH",
    Address: "MURCHISON  UGU  NDWALANE",
    Latitude: "-30.7281",
    Longitude: "30.3684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10309",
    WardLookupId: "1318",
    VDNumber: "43991714",
    RegisteredPopulation: "1714",
    VotingStationName: "MAVESHE COMMUNITY HALL",
    Address:
      "R102  OPPOSITE MURCHISON HOSPI  PORT SHEPSTONE -UGU  NDWALANE T/AUTHORITY",
    Latitude: "-30.7305",
    Longitude: "30.3477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10310",
    WardLookupId: "1318",
    VDNumber: "43991725",
    RegisteredPopulation: "884",
    VotingStationName: "MADAKANE HALL",
    Address:
      "WARD 22 MADAKANE AREA MURCHISI  UGU - PORT SHEPSTONE  PORT SHEPSTONE",
    Latitude: "-30.7374",
    Longitude: "30.3403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10311",
    WardLookupId: "1318",
    VDNumber: "43991769",
    RegisteredPopulation: "601",
    VotingStationName: "NKONKA HIGH SCHOOL",
    Address: "MBAYIMBAYI WARD  NDWALANE T/A  PORT SHEPSTONE",
    Latitude: "-30.7315",
    Longitude: "30.3586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10312",
    WardLookupId: "1318",
    VDNumber: "43992692",
    RegisteredPopulation: "1126",
    VotingStationName: "KWALUHLAZA LOWER PRIMARY SCHOOL",
    Address: "NEWTON/ MTENGWANE  NDWALANE TRIBAL AUTHORITY  PORT SHEPSTONE",
    Latitude: "-30.7205",
    Longitude: "30.354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10313",
    WardLookupId: "1318",
    VDNumber: "43994559",
    RegisteredPopulation: "359",
    VotingStationName: "MADAKANE PRIMARY SCHOOL",
    Address: "MADAKANE  UGU  DWALANE",
    Latitude: "-30.7393",
    Longitude: "30.3508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10314",
    WardLookupId: "1319",
    VDNumber: "43991781",
    RegisteredPopulation: "1394",
    VotingStationName: "SIDUMILE COMBINED PRIMARY SCHOOL",
    Address: "BHOBOYI LOCATION  NDWALANE T/A  PORT SHEPSTONE",
    Latitude: "-30.7362",
    Longitude: "30.4028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10315",
    WardLookupId: "1319",
    VDNumber: "43993424",
    RegisteredPopulation: "1814",
    VotingStationName: "SIPHAKAMILE PRIMARY SCHOOL",
    Address: "BHOBHOYI WARD  NDWALANE T/A  PORT SHEPSTONE",
    Latitude: "-30.729",
    Longitude: "30.388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10316",
    WardLookupId: "1319",
    VDNumber: "43994560",
    RegisteredPopulation: "936",
    VotingStationName: "AFRICAN FAITH MISSION CHURCH",
    Address: "JESUS LOCATION  UGU  NDWALANE",
    Latitude: "-30.7327",
    Longitude: "30.402",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10317",
    WardLookupId: "1319",
    VDNumber: "44050092",
    RegisteredPopulation: "218",
    VotingStationName: "LIONS GROOVE STORE",
    Address: "MAPHEK`UPHUTHU  PORT SHEPSTONE  MARBURG",
    Latitude: "-30.7336",
    Longitude: "30.4112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10318",
    WardLookupId: "1320",
    VDNumber: "43991691",
    RegisteredPopulation: "1938",
    VotingStationName: "NYANDE ZULU LOWER PRIMARY SCHOOL",
    Address: "NYANDEZULU AREA  NDWALANE T/A  PORT SHEPSTONE",
    Latitude: "-30.7574",
    Longitude: "30.3259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10319",
    WardLookupId: "1320",
    VDNumber: "43991736",
    RegisteredPopulation: "337",
    VotingStationName: "NDWALANE FACILITY",
    Address: "BETHANIA AREA  NDWALANE T/A  PORT SHEPSTONE",
    Latitude: "-30.7587",
    Longitude: "30.3708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10320",
    WardLookupId: "1320",
    VDNumber: "43993435",
    RegisteredPopulation: "904",
    VotingStationName: "ZWELIHLE COMBINED PRIMARY SCHOOL",
    Address: "BETHANIA AREA  NDWALANE T/A  PORT SHEPSTONE",
    Latitude: "-30.7684",
    Longitude: "30.3654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10321",
    WardLookupId: "1320",
    VDNumber: "43993446",
    RegisteredPopulation: "621",
    VotingStationName: "NSINGIZI PRIMARY SCHOOL",
    Address: "BOMELA WARD  NDWALANE  PORT SHEPSTONE",
    Latitude: "-30.7638",
    Longitude: "30.3491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10322",
    WardLookupId: "1320",
    VDNumber: "43993457",
    RegisteredPopulation: "1275",
    VotingStationName: "MAKHANDA HIGH SCHOOL",
    Address: "NDWALANE  NYANDEZULU  NDWALANE",
    Latitude: "-30.7621",
    Longitude: "30.3431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10323",
    WardLookupId: "1320",
    VDNumber: "43994144",
    RegisteredPopulation: "478",
    VotingStationName: "BOMVINI CRECHE",
    Address: "BOVINI  AREA NDWALANE TA  UGU PORT SHEPSTONE  PORT SHEPSTONE",
    Latitude: "-30.7574",
    Longitude: "30.356",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10324",
    WardLookupId: "1321",
    VDNumber: "43992018",
    RegisteredPopulation: "1055",
    VotingStationName: "MAVUNDLA TRIBAL COURT",
    Address: "NKULU WARD  MAVUNDLA T/A  PORT SHEPSTONE",
    Latitude: "-30.7957",
    Longitude: "30.3237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10325",
    WardLookupId: "1321",
    VDNumber: "43993031",
    RegisteredPopulation: "1787",
    VotingStationName: "MAVUNDLA PRIMARY SCHOOL",
    Address: "MSIKABA WARD  MAVUNDLA T/A  PORT SHEPSTONE",
    Latitude: "-30.777",
    Longitude: "30.3588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10326",
    WardLookupId: "1321",
    VDNumber: "43993042",
    RegisteredPopulation: "765",
    VotingStationName: "AMERICAN CHURCH",
    Address: "NSANGWINI WARD  KWAMAVUNDLA TRIBAL AUTHORITY  PORT SHEPSTONE",
    Latitude: "-30.7709",
    Longitude: "30.337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10327",
    WardLookupId: "1321",
    VDNumber: "43993143",
    RegisteredPopulation: "1187",
    VotingStationName: "ENKULU COMMUNITY HALL",
    Address: "WARD 25 NKULU AREA-MAVUNDLENI  UGU - PORT SHEPSTONE  MAVUNDLA",
    Latitude: "-30.7715",
    Longitude: "30.3214",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10328",
    WardLookupId: "1321",
    VDNumber: "43994852",
    RegisteredPopulation: "309",
    VotingStationName: "BHOMELA ASSEMBLY OF GOD",
    Address: "NSANGWINI  PORT SHEPSTONE  NDWALANE",
    Latitude: "-30.7674",
    Longitude: "30.3479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10329",
    WardLookupId: "1322",
    VDNumber: "43993750",
    RegisteredPopulation: "2222",
    VotingStationName: "OHLANGENI PRIMARY SCHOOL",
    Address: "THAFENI WARD  MAVUNDLA TRIBAL AUTHORITY  PORT SHEPSTONE",
    Latitude: "-30.7927",
    Longitude: "30.3423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10330",
    WardLookupId: "1322",
    VDNumber: "44030124",
    RegisteredPopulation: "1286",
    VotingStationName: "AFRICAN GOSPEL CHURCH",
    Address: "MTHINOMILE  MTHINOMILE AREA- UGU  PORT SHEPSTONE - MAVUNDLA",
    Latitude: "-30.8032",
    Longitude: "30.3479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10331",
    WardLookupId: "1323",
    VDNumber: "43991983",
    RegisteredPopulation: "3746",
    VotingStationName: "GAMALAKHE  TVET  COLLEGE",
    Address: "GAMALAKHE COLLAGE  UGU  MAVUNDLA",
    Latitude: "-30.8155",
    Longitude: "30.3189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10332",
    WardLookupId: "1323",
    VDNumber: "43991994",
    RegisteredPopulation: "1541",
    VotingStationName: "NOSITHA PRIMARY SCHOOL",
    Address: "NOSITHA WARD  MAVUNDLA TRIBAL AUTHORITY  PORT SHEPSTONE",
    Latitude: "-30.829",
    Longitude: "30.3284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10333",
    WardLookupId: "1324",
    VDNumber: "43994571",
    RegisteredPopulation: "1063",
    VotingStationName: "SIZANOKUHLE CARE CENTRE",
    Address: "SIGEDLENI AREA GAMALAKHE  UGU - PORT SHEPSTONE  MAVUNDLA",
    Latitude: "-30.8183",
    Longitude: "30.3384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10334",
    WardLookupId: "1324",
    VDNumber: "44030056",
    RegisteredPopulation: "2310",
    VotingStationName: "GAMALAKHE CIVIC HALL",
    Address: "ROAD 1 RING ROAD  GAMALAKHE  MARGATE",
    Latitude: "-30.8075",
    Longitude: "30.3438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10335",
    WardLookupId: "1324",
    VDNumber: "44030135",
    RegisteredPopulation: "755",
    VotingStationName: "AFRICAN CONGREGATIONAL CHURCH",
    Address: "130 SIGILA MZOTHO ROAD GAMALAKHE  UGU - PORT SHEPSTONE  MAVUNDLA",
    Latitude: "-30.814",
    Longitude: "30.3387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10336",
    WardLookupId: "1324",
    VDNumber: "44030146",
    RegisteredPopulation: "374",
    VotingStationName: "INTERNATIONAL ASSEMBLES OF GOD",
    Address:
      "GAMALAKHE NEXT TO GAMAKAHE CLI  PORT SHEPSTONE  GAMALAKHE TOWNSIHP",
    Latitude: "-30.808",
    Longitude: "30.3359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10337",
    WardLookupId: "1325",
    VDNumber: "43992670",
    RegisteredPopulation: "428",
    VotingStationName: "MAMBHONGWENI SCHOOL",
    Address: "KWA-XOLO TRIBAL AUTHORITY  PADDOCK  PADDOCK",
    Latitude: "-30.842",
    Longitude: "30.2375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10338",
    WardLookupId: "1325",
    VDNumber: "43992681",
    RegisteredPopulation: "1589",
    VotingStationName: "MLONDE SECONDARY SCHOOL",
    Address: "KWA-XOLO TRIBAL AUTHORITY  RAMSGATE  RAMSGATE",
    Latitude: "-30.8608",
    Longitude: "30.3022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10339",
    WardLookupId: "1325",
    VDNumber: "43992850",
    RegisteredPopulation: "1186",
    VotingStationName: "MTHOMBOTHI LOWER PRIMARY SCHOOL",
    Address:
      "KWA XOLO TRIBAL AUTHORITY  MARGATE  KWAXOLO TRADITIONAL AUTHORUTY WARD 29-MARGATE",
    Latitude: "-30.8667",
    Longitude: "30.2822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10340",
    WardLookupId: "1325",
    VDNumber: "43993008",
    RegisteredPopulation: "295",
    VotingStationName: "DINGETON SCHOOL",
    Address: "KWA-XOLO TRIBAL AUTHORITY  PADDOCK  PADDOCK",
    Latitude: "-30.8451",
    Longitude: "30.2607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10341",
    WardLookupId: "1325",
    VDNumber: "43993749",
    RegisteredPopulation: "1262",
    VotingStationName: "BASHISE HIGHER PRIMARY SCHOOL",
    Address: "KWA-XOLO TRIBAL AUTHORITY  MARGATE  MARGATE",
    Latitude: "-30.8723",
    Longitude: "30.2973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10342",
    WardLookupId: "1325",
    VDNumber: "43994032",
    RegisteredPopulation: "385",
    VotingStationName: "SUNDUZA COMMUNITY HALL",
    Address: "SUNDUZA WARD  KWAXOLO T/A  PORT SHEPSTONE",
    Latitude: "-30.8625",
    Longitude: "30.2698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10343",
    WardLookupId: "1326",
    VDNumber: "43992568",
    RegisteredPopulation: "680",
    VotingStationName: "ETSHENI LIKA SHOBA PRIMARY SCHOOL",
    Address: "ETSHENILKASHOBA AREA  KWASHOBA WARD  IZINGOLWENI",
    Latitude: "-30.837",
    Longitude: "30.1022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10344",
    WardLookupId: "1326",
    VDNumber: "43992579",
    RegisteredPopulation: "408",
    VotingStationName: "KHUMBUZA PRIMARY SCHOOL",
    Address: "00 MDLAZI AREA  MDLAZI RD  IZINGOLWENI",
    Latitude: "-30.8671",
    Longitude: "30.1054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10345",
    WardLookupId: "1326",
    VDNumber: "43992591",
    RegisteredPopulation: "1089",
    VotingStationName: "QHINQA HIGH SCHOOL",
    Address: "ENGCAWUSHENI AREA  VUKUZITHATHE T/A  IZINGOLWENI",
    Latitude: "-30.8554",
    Longitude: "30.1409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10346",
    WardLookupId: "1326",
    VDNumber: "43993761",
    RegisteredPopulation: "423",
    VotingStationName: "ZAKHENI STORE",
    Address: "00 BIZANA RD  SHOBA WARD  IZINGOLWENI",
    Latitude: "-30.8409",
    Longitude: "30.0935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10347",
    WardLookupId: "1326",
    VDNumber: "43993884",
    RegisteredPopulation: "238",
    VotingStationName: "GOQOZI COMMUNITY HALL",
    Address: "GOQOZI  UGU - IZINGOLWENI  KWAXOLO",
    Latitude: "-30.9",
    Longitude: "30.1277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10348",
    WardLookupId: "1326",
    VDNumber: "43994436",
    RegisteredPopulation: "783",
    VotingStationName: "NATIONAL CHRISTIAN ASSEMBLY OF GOD.",
    Address: "BANDLANE  EZINQOLENI  BANDLANE",
    Latitude: "-30.8441",
    Longitude: "30.1461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10349",
    WardLookupId: "1326",
    VDNumber: "43994616",
    RegisteredPopulation: "457",
    VotingStationName: "BANDLANE CRECHE",
    Address: "BANDLANE  UGU  VUKUZITHATHE TRADITIONAL AUTHORITY",
    Latitude: "-30.8596",
    Longitude: "30.1506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10350",
    WardLookupId: "1326",
    VDNumber: "43994908",
    RegisteredPopulation: "174",
    VotingStationName: "MPUNZI DRIFT COMMUNITY HALL",
    Address: "KWASHOBA  UGU - KZN 216  VUKUZITHATHE",
    Latitude: "-30.8527",
    Longitude: "30.0741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10351",
    WardLookupId: "1327",
    VDNumber: "43991893",
    RegisteredPopulation: "966",
    VotingStationName: "APOSTOLIC CHURCH OF CHRIST",
    Address: "BANDLANE  UGU - IZINGOLWENI  VUKUZITHATHE TRADITIONAL AUTHORITY",
    Latitude: "-30.8233",
    Longitude: "30.1409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10352",
    WardLookupId: "1327",
    VDNumber: "43991905",
    RegisteredPopulation: "1366",
    VotingStationName: "MSHWESHWE HIGH SCHOOL",
    Address: "1 PORT EDWARD RD  DLOVINGA  IZINGOLWENI",
    Latitude: "-30.797",
    Longitude: "30.1429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10353",
    WardLookupId: "1327",
    VDNumber: "43991927",
    RegisteredPopulation: "829",
    VotingStationName: "IZINGOLWENI COMBINED PRIMARY SCHOOL",
    Address: "00 MAIN HARDING RD  IZINGOLWENI  IZINGOLWENI",
    Latitude: "-30.7865",
    Longitude: "30.1387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10354",
    WardLookupId: "1327",
    VDNumber: "43991949",
    RegisteredPopulation: "1559",
    VotingStationName: "MBENI COMMUNITY HALL",
    Address: "MBENI  UGU - IZINGOLWENI  IZINGOLWENI",
    Latitude: "-30.7855",
    Longitude: "30.1673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10355",
    WardLookupId: "1328",
    VDNumber: "43991848",
    RegisteredPopulation: "664",
    VotingStationName: "PADDOCK CHURCH HALL",
    Address: "QINISELANIMANYUSWA T/A  PORT SHEPSTONE",
    Latitude: "-30.7628",
    Longitude: "30.2453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10356",
    WardLookupId: "1328",
    VDNumber: "43991916",
    RegisteredPopulation: "670",
    VotingStationName: "SHIBASE PRIMARY SCHOOL",
    Address: "KWA-XOLO TRIBAL AUTHORITY  IZINGOLWENI  IZINGOLWENI",
    Latitude: "-30.8289",
    Longitude: "30.1485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10357",
    WardLookupId: "1328",
    VDNumber: "43991950",
    RegisteredPopulation: "948",
    VotingStationName: "EMTHINI COMBINED PRIMARY SCHOOL",
    Address: "KWA-XOLO TRIBAL AUTHORITY  IZINGOLWENI  IZINGOLWENI",
    Latitude: "-30.8527",
    Longitude: "30.1529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10358",
    WardLookupId: "1328",
    VDNumber: "43991961",
    RegisteredPopulation: "1267",
    VotingStationName: "DUNYWA COMBINED PRIMARY SCHOOL",
    Address: "XOLO TRIBAL AUTHORITY  IZINGOLWENI",
    Latitude: "-30.7979",
    Longitude: "30.1832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10359",
    WardLookupId: "1328",
    VDNumber: "43992658",
    RegisteredPopulation: "769",
    VotingStationName: "IKHWEZILOKUSA PRIMARY SCHOOL",
    Address: "KWAXOLO  UGU  KWAXOLO",
    Latitude: "-30.8395",
    Longitude: "30.1719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10360",
    WardLookupId: "1328",
    VDNumber: "43993413",
    RegisteredPopulation: "455",
    VotingStationName: "MGAWULANA COMBINED SCHOOL",
    Address: "KWA-XOLO TRIBAL AUTHORITY  KWA-XOLO  IZINGOLWENI",
    Latitude: "-30.814",
    Longitude: "30.1537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10361",
    WardLookupId: "1329",
    VDNumber: "43992535",
    RegisteredPopulation: "651",
    VotingStationName: "MAGAYE COMMUNITY HALL",
    Address: "KWASHOBASHOBANE  EZINQOLENI  VUKUZITHAHTE TA",
    Latitude: "-30.7934",
    Longitude: "30.1191",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10362",
    WardLookupId: "1329",
    VDNumber: "43993862",
    RegisteredPopulation: "763",
    VotingStationName: "ZWELETHU METHODIST CHUCH",
    Address:
      "SHONGWE- NEAR IZINGOLWENI POLI  SHONGWE AREA  VUKUZITHATHE TA- UGU",
    Latitude: "-30.7843",
    Longitude: "30.1273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10363",
    WardLookupId: "1329",
    VDNumber: "43993873",
    RegisteredPopulation: "487",
    VotingStationName: "KHANDALESIZWE  SECONDARY SCHOOL",
    Address: "VUKUZITHATHE T/A  EZINGOLWENI",
    Latitude: "-30.823",
    Longitude: "30.1163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10364",
    WardLookupId: "1329",
    VDNumber: "43993895",
    RegisteredPopulation: "1083",
    VotingStationName: "MUNGA PRIMARY SCHOOL",
    Address: "00 MAIN HARDING RD  MBENI WARD  EZINGOLENI",
    Latitude: "-30.7765",
    Longitude: "30.1467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10365",
    WardLookupId: "1329",
    VDNumber: "43993907",
    RegisteredPopulation: "1179",
    VotingStationName: "VUKUZITHATHE TRADITIONAL COURT",
    Address: "NKULU WARD  VUKUZITHATHE  T/C  IZINGOLWENI",
    Latitude: "-30.7835",
    Longitude: "30.135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10366",
    WardLookupId: "1330",
    VDNumber: "43991365",
    RegisteredPopulation: "1210",
    VotingStationName: "KWANYUSWA COMMUNITY HALL",
    Address:
      "ENKULU  QINISELANIMANYUSWA  QINISELANIMANYUSWA TRADITIONAL AUTHORITY",
    Latitude: "-30.6535",
    Longitude: "30.0411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10367",
    WardLookupId: "1330",
    VDNumber: "43991871",
    RegisteredPopulation: "132",
    VotingStationName: "NKUNSWANA PRIMARY SCHOOL",
    Address: "ETHONJENI AREA  QINISELANIMANYUSWA T/A  QINISELANIMANYUSWA T/A",
    Latitude: "-30.636",
    Longitude: "30.1796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10368",
    WardLookupId: "1330",
    VDNumber: "43991882",
    RegisteredPopulation: "402",
    VotingStationName: "SINEKE PRIMARY SCHOOL",
    Address: "KWABLOSE AREA  KWANYUSWA  QINISELANIMANYUSWA T/A",
    Latitude: "-30.6939",
    Longitude: "30.1293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10369",
    WardLookupId: "1330",
    VDNumber: "43992502",
    RegisteredPopulation: "188",
    VotingStationName: "ITHONJENI PRIMARY SCHOOL",
    Address: "00 ETHONJENI AREA  ETHONJENI  QINISELANIMANYUSWA T/A",
    Latitude: "-30.6456",
    Longitude: "30.122",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10370",
    WardLookupId: "1330",
    VDNumber: "43993187",
    RegisteredPopulation: "263",
    VotingStationName: "GIBRALTOR STORE",
    Address: "0 ORIBI GORGE RD  ORIBI FARMS  IZINGOLWENI",
    Latitude: "-30.6623",
    Longitude: "30.3101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10371",
    WardLookupId: "1330",
    VDNumber: "43993323",
    RegisteredPopulation: "659",
    VotingStationName: "QINISELANIMANYUSWA HALL",
    Address: "KWAWOSIYANE AREA  UGU  QINISELANIMANYUSWA",
    Latitude: "-30.6568",
    Longitude: "30.0807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10372",
    WardLookupId: "1330",
    VDNumber: "43993738",
    RegisteredPopulation: "1065",
    VotingStationName: "MAHLABATHINI COMMUNITY HALL",
    Address: "EKHUKHU  UGU - IZINGOLWENI  VUKUZITHATHE TRADITIONAL AUTHORITY",
    Latitude: "-30.6768",
    Longitude: "30.1617",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10373",
    WardLookupId: "1330",
    VDNumber: "43994133",
    RegisteredPopulation: "325",
    VotingStationName: "NSIMBINI FARM",
    Address: "PADDOCK  UGU  NDWALANE",
    Latitude: "-30.7297",
    Longitude: "30.2971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10374",
    WardLookupId: "1331",
    VDNumber: "43991220",
    RegisteredPopulation: "456",
    VotingStationName: "ST LUKES MISSION",
    Address: "NQABENI WARD 04  NQABENI FARMERS  HARDING",
    Latitude: "-30.7236",
    Longitude: "30.0504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10375",
    WardLookupId: "1331",
    VDNumber: "43991387",
    RegisteredPopulation: "1013",
    VotingStationName: "SIKANISWENI HIGH SCHOOL",
    Address: "KWANDUNU AREA  NQABENI  NQABENI",
    Latitude: "-30.7455",
    Longitude: "30.0362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10376",
    WardLookupId: "1331",
    VDNumber: "43991398",
    RegisteredPopulation: "1059",
    VotingStationName: "MVOLOZI PRIMARY SCHOOL",
    Address: "HLOMENDLINI AREA  MTHIMUDE T/A  NQABENI",
    Latitude: "-30.7826",
    Longitude: "30.0208",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10377",
    WardLookupId: "1331",
    VDNumber: "43991433",
    RegisteredPopulation: "728",
    VotingStationName: "BOMVINI PRIMARY SCHOOL",
    Address: "KWAGODLOZA AREA  MTHIMUDE TRIBAL COURT  IZINGOLWENI",
    Latitude: "-30.7722",
    Longitude: "30.0696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10378",
    WardLookupId: "1331",
    VDNumber: "43991826",
    RegisteredPopulation: "486",
    VotingStationName: "SIBHANGWANA PRIMARY SCHOOL",
    Address: "THUVUKEZI AREA-QINISELANI TA  UGU- IZINGOLWENI  IZINGOLWENI",
    Latitude: "-30.7219",
    Longitude: "30.1101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10379",
    WardLookupId: "1331",
    VDNumber: "43994593",
    RegisteredPopulation: "710",
    VotingStationName: "SHIBE COMMUNITY HALL",
    Address: "KWASHIBE  UGU  MTHIMUDE TRADITIONAL AUTHORITY",
    Latitude: "-30.7695",
    Longitude: "30.0182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10380",
    WardLookupId: "1331",
    VDNumber: "43994605",
    RegisteredPopulation: "568",
    VotingStationName: "ST JOHN`S CHURCH",
    Address: "EKHUKHU  UGU - IZINGOLWENI  MTHIMUDE",
    Latitude: "-30.7677",
    Longitude: "30.0597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10381",
    WardLookupId: "1332",
    VDNumber: "43991376",
    RegisteredPopulation: "734",
    VotingStationName: "ECHIBINI PRIMARY SCHOOL",
    Address: "MAHLUBINI AREA  MTHIMUDE T/A  KWAMTHIMUDE T/A",
    Latitude: "-30.8156",
    Longitude: "30.0557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10382",
    WardLookupId: "1332",
    VDNumber: "43991938",
    RegisteredPopulation: "1248",
    VotingStationName: "MANSFIELD COMBINED PRIMARY SCHOOL",
    Address: "INKULU  IZINGOLWENI",
    Latitude: "-30.7695",
    Longitude: "30.1138",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10383",
    WardLookupId: "1332",
    VDNumber: "43992546",
    RegisteredPopulation: "316",
    VotingStationName: "ZAMAKUZAKHA PRIMARY SCHOOL",
    Address: "MLOZANA AREA  MTHIMUDE  T/A  IZINGOLWENI",
    Latitude: "-30.7928",
    Longitude: "30.0954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10384",
    WardLookupId: "1332",
    VDNumber: "43992557",
    RegisteredPopulation: "239",
    VotingStationName: "IMFUNDWENHLE COMBINED PRIMARY SCHOOL",
    Address: "00 NONE  KWAMTHIMUDE  IZINGOLWENI",
    Latitude: "-30.8396",
    Longitude: "30.0597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10385",
    WardLookupId: "1332",
    VDNumber: "43992603",
    RegisteredPopulation: "1105",
    VotingStationName: "MTHIMUDE TRADITIONAL AUTHORITY",
    Address: "00 MAIN RD  MLOZANA  IZINGOLWENI",
    Latitude: "-30.7687",
    Longitude: "30.0926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10386",
    WardLookupId: "1332",
    VDNumber: "43992625",
    RegisteredPopulation: "584",
    VotingStationName: "BHOSIKI PRIMARY SCHOOL",
    Address: "0 KWANIKWE AREA  KWANIKWE  NQABENI",
    Latitude: "-30.8041",
    Longitude: "29.9954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10387",
    WardLookupId: "1332",
    VDNumber: "43994582",
    RegisteredPopulation: "409",
    VotingStationName: "ZAMANI CRECHE",
    Address: "NIKELA AREA  UGU  VUKUZITHTHE T/A",
    Latitude: "-30.7706",
    Longitude: "30.1071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10388",
    WardLookupId: "3589",
    VDNumber: "43773295",
    RegisteredPopulation: "900",
    VotingStationName: "ALBERT FALLS PRIMARY SCHOOL",
    Address: "50 MAIN ROAD CRAMOND  CRAMOND  WARTBURG",
    Latitude: "-29.4187",
    Longitude: "30.4286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10389",
    WardLookupId: "3589",
    VDNumber: "43773307",
    RegisteredPopulation: "297",
    VotingStationName: "MELVILLE PRIMARY SCHOOL",
    Address: "REITVEL / YORK  NEW HANOVER  WARTBURG",
    Latitude: "-29.2962",
    Longitude: "30.4511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10390",
    WardLookupId: "3589",
    VDNumber: "43773374",
    RegisteredPopulation: "415",
    VotingStationName: "THEMBANI - OTTOS BLUFF STORE",
    Address: "CNR OF OTTOS BLUFF AND D495 RD  OTTOS BLUFF  OTTOS BLUFF",
    Latitude: "-29.4766",
    Longitude: "30.3542",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10391",
    WardLookupId: "3589",
    VDNumber: "43773868",
    RegisteredPopulation: "177",
    VotingStationName: "OKHETHENI (TENT)",
    Address: "EZINKETHENI  KARKLOOF  FORREST FARM",
    Latitude: "-29.3098",
    Longitude: "30.336",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10392",
    WardLookupId: "3589",
    VDNumber: "43774555",
    RegisteredPopulation: "1565",
    VotingStationName: "THOKOZANI COMMUNITY HALL",
    Address: "THOKOZANI  CRAMOND  UMSHWATHI",
    Latitude: "-29.4319",
    Longitude: "30.4383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10393",
    WardLookupId: "3589",
    VDNumber: "43775141",
    RegisteredPopulation: "855",
    VotingStationName: "THOKOZANI CRECHE",
    Address: "THOKOZANI  ALBERT FALLS  CRAMOND",
    Latitude: "-29.4251",
    Longitude: "30.4342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10394",
    WardLookupId: "3589",
    VDNumber: "43775152",
    RegisteredPopulation: "498",
    VotingStationName: "NKAYISHANA PRIMARY SCHOOL",
    Address: "ALBERT FALLS  CRAMOND   CRAMOND",
    Latitude: "-29.3719",
    Longitude: "30.4053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10395",
    WardLookupId: "3590",
    VDNumber: "43772519",
    RegisteredPopulation: "402",
    VotingStationName: "FORTMANSPRUIT PRIMARY SCHOOL",
    Address: "DALTON ROAD  NEW HANOVER   NEW HANOVER",
    Latitude: "-29.3623",
    Longitude: "30.5787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10396",
    WardLookupId: "3590",
    VDNumber: "43830019",
    RegisteredPopulation: "1281",
    VotingStationName: "DALTON TLC",
    Address: "KLIPP AVENUE  DALTON  DALTON",
    Latitude: "-29.3437",
    Longitude: "30.6396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10397",
    WardLookupId: "3590",
    VDNumber: "43940011",
    RegisteredPopulation: "2156",
    VotingStationName: "NEW HANOVER COMMUNITY HALL",
    Address: "CNR KEIR & DALE STR  NEW HANOVER  NEW HANOVER",
    Latitude: "-29.3562",
    Longitude: "30.5291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10398",
    WardLookupId: "3591",
    VDNumber: "43770595",
    RegisteredPopulation: "725",
    VotingStationName: "INADI SUB CLAN TRIBAL COURT",
    Address: "NDUNDWINI   DALTON  INADI SUB CLAN",
    Latitude: "-29.2203",
    Longitude: "30.7348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10399",
    WardLookupId: "3591",
    VDNumber: "43770797",
    RegisteredPopulation: "694",
    VotingStationName: "MUZIKAWUTHANDWA HIGH SCHOOL",
    Address: "D40 MTULWA  DALTON  MASIBAMBISANE T/A",
    Latitude: "-29.231",
    Longitude: "30.7651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10400",
    WardLookupId: "3591",
    VDNumber: "43773587",
    RegisteredPopulation: "2364",
    VotingStationName: "EFAYE PRIMARY SCHOOL",
    Address: "D438  EFAYE  NGUBANE T/A",
    Latitude: "-29.2847",
    Longitude: "30.801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10401",
    WardLookupId: "3591",
    VDNumber: "43774139",
    RegisteredPopulation: "857",
    VotingStationName: "EKHAMANZI PRIMARY SCHOOL",
    Address: "0 EKHAMANZI   SEVEN OAKS  EKHAMANZI MISSION",
    Latitude: "-29.2498",
    Longitude: "30.7037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10402",
    WardLookupId: "3591",
    VDNumber: "43775590",
    RegisteredPopulation: "540",
    VotingStationName: "EMAKENI PRIMARY SCHOOL",
    Address: "MT ELLIAS  EFAYE    MT ELLIAS D",
    Latitude: "-29.2549",
    Longitude: "30.7985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10403",
    WardLookupId: "3592",
    VDNumber: "43770584",
    RegisteredPopulation: "397",
    VotingStationName: "UMBIKO PRIMARY SCHOOL",
    Address: "P128  UMHLANGANDLOVU   NTANZI UMHLANGANDLOVU T/A",
    Latitude: "-29.2512",
    Longitude: "30.884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10404",
    WardLookupId: "3592",
    VDNumber: "43770708",
    RegisteredPopulation: "1128",
    VotingStationName: "KWELIFUPHI  PRIMARY SCHOOL",
    Address: "MBALENHLE  OZWATHINI  MTHULI TRIBAL AUTHORITY",
    Latitude: "-29.3703",
    Longitude: "30.8705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10405",
    WardLookupId: "3592",
    VDNumber: "43770719",
    RegisteredPopulation: "85",
    VotingStationName: "ECHAMENI PRIMARY SCHOOL",
    Address: "P82 MTHOMBENI/ KWAKLIBHU  MTHOMBENI/ GLENSIDE  DALTON",
    Latitude: "-29.316",
    Longitude: "30.8491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10406",
    WardLookupId: "3592",
    VDNumber: "43770720",
    RegisteredPopulation: "653",
    VotingStationName: "GAJU SECONDARY SCHOOL",
    Address: "D384  EFAYE  BAMBANANI",
    Latitude: "-29.2696",
    Longitude: "30.8463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10407",
    WardLookupId: "3592",
    VDNumber: "43772238",
    RegisteredPopulation: "1928",
    VotingStationName: "MJELE HIGH SCHOOL",
    Address: "DOBOTI / SWIDI  OZWATHINI  OZWATHINI",
    Latitude: "-29.3822",
    Longitude: "30.8446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10408",
    WardLookupId: "3592",
    VDNumber: "43775163",
    RegisteredPopulation: "158",
    VotingStationName: "NENES FARM (TENT)",
    Address: "N/A  OZWATHINI  APPLESBOCH",
    Latitude: "-29.3837",
    Longitude: "30.8283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10409",
    WardLookupId: "3593",
    VDNumber: "43772193",
    RegisteredPopulation: "895",
    VotingStationName: "BULAWAYO PRIMARY SCHOOL",
    Address: "D 1620 HLATHIKHULU  OZWATHINI  WARTBURG",
    Latitude: "-29.4009",
    Longitude: "30.8947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10410",
    WardLookupId: "3593",
    VDNumber: "43772205",
    RegisteredPopulation: "859",
    VotingStationName: "SIBONGILE PRIMARY SCHOOL",
    Address: "D 2165 AMATHULI AREA  OZWATHINI  WARTBURG",
    Latitude: "-29.3854",
    Longitude: "30.8947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10411",
    WardLookupId: "3593",
    VDNumber: "43772216",
    RegisteredPopulation: "1362",
    VotingStationName: "AMATHULI PRIMARY SCHOOL",
    Address: "MTHULI  APPLESBOCH / OZWATHINI  OZWATHINI /MUTHULI T/A",
    Latitude: "-29.3867",
    Longitude: "30.8654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10412",
    WardLookupId: "3593",
    VDNumber: "43775174",
    RegisteredPopulation: "544",
    VotingStationName: "MTHULI SECONDARY SCHOOL",
    Address: "MATHULINI  OZWATHINI  MTHULI TRADITIONAL ADMINISTRATION",
    Latitude: "-29.3998",
    Longitude: "30.854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10413",
    WardLookupId: "3594",
    VDNumber: "43772182",
    RegisteredPopulation: "956",
    VotingStationName: "KWAMAPHUMULO PRIMARY SCHOOL",
    Address:
      "SWAYIMANA, KWAMAPHUMULO ESTEZI  ESITEZI   GCUMISA TRIBAL AUTHORITY",
    Latitude: "-29.527",
    Longitude: "30.5934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10414",
    WardLookupId: "3594",
    VDNumber: "43772272",
    RegisteredPopulation: "985",
    VotingStationName: "MASJABULE HIGH SCHOOL",
    Address: "EMBHAVA / CUPHULAKA  SWAYIMANI  GCUMISA T/A",
    Latitude: "-29.5755",
    Longitude: "30.6781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10415",
    WardLookupId: "3594",
    VDNumber: "43772294",
    RegisteredPopulation: "892",
    VotingStationName: "NGABAYENA PRIMARY SCHOOL",
    Address: "MBHAVA AREA  SWAYIMANA  GCUMISA T/A",
    Latitude: "-29.5901",
    Longitude: "30.6612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10416",
    WardLookupId: "3594",
    VDNumber: "43772384",
    RegisteredPopulation: "947",
    VotingStationName: "NTINTISA PRIMARY SCHOOL",
    Address: "CUPHULAKA  SWAYIMANA  GCUMISA T/A",
    Latitude: "-29.5423",
    Longitude: "30.6822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10417",
    WardLookupId: "3594",
    VDNumber: "43772395",
    RegisteredPopulation: "182",
    VotingStationName: "KHULANJALO PRIMARY SCHOOL",
    Address: "ZULU ROAD / EMPETHU  SWAYIMANI  GCVUMISA T/A",
    Latitude: "-29.5776",
    Longitude: "30.6482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10418",
    WardLookupId: "3594",
    VDNumber: "43775185",
    RegisteredPopulation: "178",
    VotingStationName: "MAYIZEKANYE SECONDARY SCHOOL",
    Address:
      "SWAYIMANA, KWAMAPHUMULO ESTEZI  WARTBURG  GCUMISA TRIBAL AUTHORITY",
    Latitude: "-29.5321",
    Longitude: "30.6248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10419",
    WardLookupId: "3595",
    VDNumber: "43774128",
    RegisteredPopulation: "416",
    VotingStationName: "BRUYNSHILL PRIMARY SCHOOL",
    Address: "LOT 14A ST PUALS ETATE BRUYNSHILL  BRUYNSHILL  WARTBURG",
    Latitude: "-29.4476",
    Longitude: "30.6548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10420",
    WardLookupId: "3595",
    VDNumber: "43810017",
    RegisteredPopulation: "1909",
    VotingStationName: "COOL AIR TOWN HALL",
    Address: "BLUE BELL CRESCENT  COOL AIR  DALTON",
    Latitude: "-29.3636",
    Longitude: "30.63",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10421",
    WardLookupId: "3595",
    VDNumber: "43980015",
    RegisteredPopulation: "1522",
    VotingStationName: "GOERGENAU COMBINED SCHOOL",
    Address: "21 CNR HIGH AND RIDGE RDS  WARTBURG  WARTBURG",
    Latitude: "-29.4317",
    Longitude: "30.5807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10422",
    WardLookupId: "3596",
    VDNumber: "43772283",
    RegisteredPopulation: "521",
    VotingStationName: "OQAQENI HALL",
    Address: "OQAQENI ROAD  SWAYIMANI  GCUMISA T/A",
    Latitude: "-29.5544",
    Longitude: "30.6984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10423",
    WardLookupId: "3596",
    VDNumber: "43772306",
    RegisteredPopulation: "1954",
    VotingStationName: "SWAYIMANA HIGH SCHOOL",
    Address: "SWAYIMANA MAIN ROAD  SWAYIMANA  GCUMISA T/A",
    Latitude: "-29.5187",
    Longitude: "30.6934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10424",
    WardLookupId: "3596",
    VDNumber: "43773532",
    RegisteredPopulation: "480",
    VotingStationName: "MBEKA PRIMARY SCHOOL",
    Address: "ESGQUMENI  SWAYIMANI  GCUMISA T/A",
    Latitude: "-29.5831",
    Longitude: "30.7012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10425",
    WardLookupId: "3596",
    VDNumber: "43774588",
    RegisteredPopulation: "1018",
    VotingStationName: "UMSILILI CRECHE",
    Address: "D1017  SWAYIMANE  GCUMISA T/A",
    Latitude: "-29.5145",
    Longitude: "30.7072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10426",
    WardLookupId: "3597",
    VDNumber: "43773688",
    RegisteredPopulation: "3146",
    VotingStationName: "MARTIN NXUMALO HIGH SCHOOL",
    Address: "MAIN ROAD  WARTBURG   TRUSTFEED",
    Latitude: "-29.4043",
    Longitude: "30.5416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10427",
    WardLookupId: "3597",
    VDNumber: "43774577",
    RegisteredPopulation: "1321",
    VotingStationName: "MVUNDLWENI CRECHE",
    Address: "L 2475  MPOLWENI MISSION  MPOLWENI MISSION",
    Latitude: "-29.4039",
    Longitude: "30.4807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10428",
    WardLookupId: "3598",
    VDNumber: "43773497",
    RegisteredPopulation: "2641",
    VotingStationName: "MPOLWENI COMMUNITY HALL",
    Address: "D708 MAIN ROAD  NEW HANOVER   MPOLWENI MISSION",
    Latitude: "-29.4288",
    Longitude: "30.483",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10429",
    WardLookupId: "3598",
    VDNumber: "43774207",
    RegisteredPopulation: "186",
    VotingStationName: "F10 WHISPERS TENT",
    Address: "TABLE MOUNTAIN ROAD  WARTBURG  PIETERMARIZBURG",
    Latitude: "-29.5606",
    Longitude: "30.4627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10430",
    WardLookupId: "3598",
    VDNumber: "43774566",
    RegisteredPopulation: "288",
    VotingStationName: "GHOUSIA MANZIL CLARIDGE",
    Address: "4 HARDINGSDALE ROAD; FUN FARM   CLARIDGE  PITERMARITZBURG",
    Latitude: "-29.54",
    Longitude: "30.374",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10431",
    WardLookupId: "3598",
    VDNumber: "43774656",
    RegisteredPopulation: "480",
    VotingStationName: "TENT AT CROSSING FARMSTALL",
    Address: "R33 GREYTON ROAD  CRAMOND  CRAMOND",
    Latitude: "-29.4543",
    Longitude: "30.4367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10432",
    WardLookupId: "3598",
    VDNumber: "43774667",
    RegisteredPopulation: "1022",
    VotingStationName: "MANTSHALINI COMMUNITY HALL",
    Address: "D708 MATSHALINI VILLAGE  MPOLWENI   MPOLWENI",
    Latitude: "-29.4439",
    Longitude: "30.4912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10433",
    WardLookupId: "3598",
    VDNumber: "43775602",
    RegisteredPopulation: "158",
    VotingStationName: "HARDINGSDALE FARM OPEN SPACE",
    Address: "R33 GREYTOWN ROAD  NEW HANOVER  HARDINGSDALE FARM",
    Latitude: "-29.5149",
    Longitude: "30.4074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10434",
    WardLookupId: "3599",
    VDNumber: "43772317",
    RegisteredPopulation: "1143",
    VotingStationName: "MASIBAMBISANE HALL",
    Address: "EMABHELENI  WARTBURGCUMISA TRIBAL AUTHORITY; SWAYIMANA  WARTBURG",
    Latitude: "-29.4875",
    Longitude: "30.7276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10435",
    WardLookupId: "3599",
    VDNumber: "43772328",
    RegisteredPopulation: "455",
    VotingStationName: "GQUGQUMA PRIMARY SCHOOL",
    Address: "GQUGQUMA VILLAGE  SWAYIMANA  HARBURG",
    Latitude: "-29.461",
    Longitude: "30.7296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10436",
    WardLookupId: "3599",
    VDNumber: "43772339",
    RegisteredPopulation: "1406",
    VotingStationName: "MEHLWENKOSI COMBINED PRIMARY SCHOOL",
    Address: "EKUPHOLENI  GCUMISA T/A SWAYIMANA  WARTBURG",
    Latitude: "-29.4736",
    Longitude: "30.6899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10437",
    WardLookupId: "3599",
    VDNumber: "43775196",
    RegisteredPopulation: "600",
    VotingStationName: "ENGOLELENI PRIMARY SCHOOL",
    Address:
      "ENGOLELENI  GCUMISA TRADITIONAL ADMINITRATION ,SWAYIMANA  WARTBURG",
    Latitude: "-29.4877",
    Longitude: "30.6929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10438",
    WardLookupId: "3599",
    VDNumber: "43775208",
    RegisteredPopulation: "673",
    VotingStationName: "EKUPHOLENI HIGH SCHOOL",
    Address: "EKUPHOLENI  SWAYIMANA  WARTBURG",
    Latitude: "-29.4918",
    Longitude: "30.6804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10439",
    WardLookupId: "3600",
    VDNumber: "43772351",
    RegisteredPopulation: "1292",
    VotingStationName: "INKULULEKO HIGHER PRIMARY SCHOOL",
    Address: "INKULULEKO , NEAR JABULA STORE  SWAYIMANA  WARTBURG",
    Latitude: "-29.4918",
    Longitude: "30.6486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10440",
    WardLookupId: "3600",
    VDNumber: "43772362",
    RegisteredPopulation: "981",
    VotingStationName: "GOBIZEMBE HIGH SCHOOL",
    Address: "MASELEKWINI AREA  SWAYIMANA  WARTBURG",
    Latitude: "-29.4905",
    Longitude: "30.6369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10441",
    WardLookupId: "3600",
    VDNumber: "43773475",
    RegisteredPopulation: "284",
    VotingStationName: "PELLA PRIMARY SCHOOL",
    Address: "WINDY HILL - PELLA  WINDY HILL  WARTBURG",
    Latitude: "-29.453",
    Longitude: "30.5884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10442",
    WardLookupId: "3600",
    VDNumber: "43775219",
    RegisteredPopulation: "666",
    VotingStationName: "BAXOLELENI PRIMARY SCHOOL",
    Address: "MASELEKWINI AREA  GCUMISA T/A SWAYIMANA  WARTBURG",
    Latitude: "-29.4981",
    Longitude: "30.6265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10443",
    WardLookupId: "3600",
    VDNumber: "43775545",
    RegisteredPopulation: "649",
    VotingStationName: "INKULULEKO COMMUNITY HALL",
    Address: "INKULULEKO , KWADLEBEZEMBUZI  SWAYIMANA  WARTBURG",
    Latitude: "-29.4763",
    Longitude: "30.6527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10444",
    WardLookupId: "3601",
    VDNumber: "43772340",
    RegisteredPopulation: "1158",
    VotingStationName: "ODLAMENI PRIMARY SCHOOL",
    Address: "ODLAMENI AREA  SWAYIMANA  WARTBURG",
    Latitude: "-29.5309",
    Longitude: "30.6588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10445",
    WardLookupId: "3601",
    VDNumber: "43772373",
    RegisteredPopulation: "1030",
    VotingStationName: "ASAMKELE JUNIOR PRIMARY SCHOOL",
    Address: "40977 EMAMBEDWINI  SWAYIMANE  WARTBURG",
    Latitude: "-29.4991",
    Longitude: "30.6531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10446",
    WardLookupId: "3601",
    VDNumber: "43775220",
    RegisteredPopulation: "672",
    VotingStationName: "INYANINGA PRIMARY SCHOOL",
    Address: "SWAYIMANA  GCUMISA TRIBAL AUTHORITY, SWAYIMANA  WARTBURG",
    Latitude: "-29.5148",
    Longitude: "30.6433",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10447",
    WardLookupId: "3601",
    VDNumber: "43775556",
    RegisteredPopulation: "755",
    VotingStationName: "NDLAVELENI ROMAN CATHOLIC CHURCH",
    Address: "NDLAVELENI AREA  SWAYIMANA  WARTBURG",
    Latitude: "-29.5071",
    Longitude: "30.6712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10448",
    WardLookupId: "3602",
    VDNumber: "43772227",
    RegisteredPopulation: "1433",
    VotingStationName: "INQOLAYOLWAZI PRIMARY SCHOOL",
    Address: "SWIDI LOCATION  OZWATHINI  WARTBURG",
    Latitude: "-29.3937",
    Longitude: "30.8486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10449",
    WardLookupId: "3602",
    VDNumber: "43773408",
    RegisteredPopulation: "541",
    VotingStationName: "FORBESDALE PRIMARY SCHOOL",
    Address: "ARUM VALLEY FARM  GLENSIDE  HARBURG",
    Latitude: "-29.4025",
    Longitude: "30.7736",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10450",
    WardLookupId: "3602",
    VDNumber: "43773419",
    RegisteredPopulation: "550",
    VotingStationName: "MATHAFENI/FAWN LEASE (TENT)",
    Address: "FAWN LEASE  DALTON",
    Latitude: "-29.3538",
    Longitude: "30.7222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10451",
    WardLookupId: "3602",
    VDNumber: "43773509",
    RegisteredPopulation: "275",
    VotingStationName: "OEBSISFELDE PRIMARY SCHOOL",
    Address: "GREEN GABLES   FO KLIPP FARM  DALTON",
    Latitude: "-29.3082",
    Longitude: "30.6744",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10452",
    WardLookupId: "1333",
    VDNumber: "43870024",
    RegisteredPopulation: "3072",
    VotingStationName: "HOWICK WEST COMMUNITY HALL",
    Address: "ALPHINE ROAD  HOWICK WEST  HOWICK",
    Latitude: "-29.5055",
    Longitude: "30.2293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10453",
    WardLookupId: "1333",
    VDNumber: "43870169",
    RegisteredPopulation: "746",
    VotingStationName: "HOWICK INDOOR CENTRE",
    Address: "MAIN ROAD  OPPOSITE UMNGENI HOSPITAL, HOWICK WEST  HOWICK",
    Latitude: "-29.5004",
    Longitude: "30.2305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10454",
    WardLookupId: "1334",
    VDNumber: "43870013",
    RegisteredPopulation: "1811",
    VotingStationName: "HOWICK PREP SCHOOL",
    Address: "22 HARVARD  HOWICK  HOWICK",
    Latitude: "-29.4891",
    Longitude: "30.2243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10455",
    WardLookupId: "1334",
    VDNumber: "43870114",
    RegisteredPopulation: "1809",
    VotingStationName: "HOWICK HIGH SCHOOL",
    Address: "01 AMBER AVENUE  GREENDALE PARK  HOWICK",
    Latitude: "-29.4793",
    Longitude: "30.2099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10456",
    WardLookupId: "1335",
    VDNumber: "43773161",
    RegisteredPopulation: "621",
    VotingStationName: "DARGLE PRIMARY SCHOOL",
    Address: "IMPENDLE RD  DARGLE  HOWICK",
    Latitude: "-29.502",
    Longitude: "30.0159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10457",
    WardLookupId: "1335",
    VDNumber: "43773172",
    RegisteredPopulation: "382",
    VotingStationName: "SILINDELE COMBINED PRIMARY SCHOOL",
    Address: "FORT NOTTINGHAM RD  LIONS BUSH FARM  FORT NOTTINGHAM",
    Latitude: "-29.4121",
    Longitude: "29.938",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10458",
    WardLookupId: "1335",
    VDNumber: "43773183",
    RegisteredPopulation: "223",
    VotingStationName: "ISIPHETHU SEMFUNDO PRIMARY SCHOOL",
    Address: "361 STEYNBRAES FARM  NOTINGHAM ROAD  NOTINGHAM ROAD",
    Latitude: "-29.3785",
    Longitude: "29.8835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10459",
    WardLookupId: "1335",
    VDNumber: "43773666",
    RegisteredPopulation: "675",
    VotingStationName: "ZENZANE COMMUNITY HALL",
    Address: "180 LOT  ZENZANE VILLAGE  BALGOWAN",
    Latitude: "-29.3795",
    Longitude: "30.0302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10460",
    WardLookupId: "1335",
    VDNumber: "43773677",
    RegisteredPopulation: "1242",
    VotingStationName: "NOTTINGHAM RD PRIMARY SCHOOL",
    Address: "0 OLD MAIN RD  NOTTINGHAM RD  NOTTINGHAM RD",
    Latitude: "-29.3552",
    Longitude: "29.9944",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10461",
    WardLookupId: "1335",
    VDNumber: "43774421",
    RegisteredPopulation: "392",
    VotingStationName: "ESIPHETHWINI SENDIZA PRIMARY SCHOOL",
    Address: "FORT NOTTINGHAM RD  NOTTINGHAM RD  NOTTINGHAM RD",
    Latitude: "-29.319",
    Longitude: "30.0472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10462",
    WardLookupId: "1336",
    VDNumber: "43773251",
    RegisteredPopulation: "2571",
    VotingStationName: "JABULA COMBINED SCHOOL",
    Address: "CNR MARKET & PINE  LIDGETTON WEST  LIDGETTON",
    Latitude: "-29.4405",
    Longitude: "30.1002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10463",
    WardLookupId: "1336",
    VDNumber: "43773329",
    RegisteredPopulation: "781",
    VotingStationName: "CURRYS POST PRIMARY SCHOOL",
    Address: "CURRY POST ROAD  CURRYS POST  HOWICK",
    Latitude: "-29.358",
    Longitude: "30.131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10464",
    WardLookupId: "1336",
    VDNumber: "43774432",
    RegisteredPopulation: "354",
    VotingStationName: "LIONS RIVER CLUB",
    Address: "DARGLE / IMPENDLE RD  LIONS RIVER  LIONS RIVER",
    Latitude: "-29.4687",
    Longitude: "30.1243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10465",
    WardLookupId: "1337",
    VDNumber: "43773262",
    RegisteredPopulation: "531",
    VotingStationName: "TRIANDRA FARM SCHOOL",
    Address: "D293 PROV ROAD  KARKLOOF  HOWICK",
    Latitude: "-29.3488",
    Longitude: "30.2306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10466",
    WardLookupId: "1337",
    VDNumber: "43774443",
    RegisteredPopulation: "508",
    VotingStationName: "YARROW PRIMARY SCHOOL",
    Address: "KARKLOOF RD  YARROW FARM  HOWICK",
    Latitude: "-29.3444",
    Longitude: "30.312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10467",
    WardLookupId: "1337",
    VDNumber: "43870091",
    RegisteredPopulation: "2561",
    VotingStationName: "THEMBELIHLE PRIMARY SCHOOL",
    Address: "90 MAIN STREET  HOWICK  HOWICK",
    Latitude: "-29.4789",
    Longitude: "30.2255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10468",
    WardLookupId: "1338",
    VDNumber: "43773273",
    RegisteredPopulation: "1963",
    VotingStationName: "HAWKSTONE PRIMARY SCHOOL",
    Address: "CRAMMOND RD  MCKENZIE FARM  HAWKSTONE",
    Latitude: "-29.3674",
    Longitude: "30.3137",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10469",
    WardLookupId: "1338",
    VDNumber: "43773363",
    RegisteredPopulation: "2528",
    VotingStationName: "HILTON TOWN HALL",
    Address: "HILTON ROAD  HILTON  HILTON",
    Latitude: "-29.5498",
    Longitude: "30.3012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10470",
    WardLookupId: "1338",
    VDNumber: "43774140",
    RegisteredPopulation: "391",
    VotingStationName: "HILTON COLLEGE",
    Address: "D494 HILTON COLLEGE RD  HILTON COLLEGE  HILTON",
    Latitude: "-29.4939",
    Longitude: "30.3002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10471",
    WardLookupId: "1338",
    VDNumber: "43775039",
    RegisteredPopulation: "263",
    VotingStationName: "SIBONGUMBOVU COMBINED SCHOOL",
    Address: "R103  EMANDLENI LOCATION, NEXT TO ROMAN CATHOLIC CHURCH  CEDARA",
    Latitude: "-29.527",
    Longitude: "30.267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10472",
    WardLookupId: "1339",
    VDNumber: "43773756",
    RegisteredPopulation: "846",
    VotingStationName: "MERRIVALE HEIGHTS (TENT)",
    Address: "CORNER WILSON & BULWER ROAD  MERRIVALE HEIGHTS  MERRIVALE",
    Latitude: "-29.5266",
    Longitude: "30.2446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10473",
    WardLookupId: "1339",
    VDNumber: "43850011",
    RegisteredPopulation: "2930",
    VotingStationName: "CHRIST CHURCH",
    Address: "03 BERG VIEW ROAD  HILTON  HILTON",
    Latitude: "-29.5555",
    Longitude: "30.2893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10474",
    WardLookupId: "1339",
    VDNumber: "43850022",
    RegisteredPopulation: "816",
    VotingStationName: "CEDARA PRIMARY SCHOOL",
    Address:
      "KHANYA VILLAGE  WITHIN CEDARA AGRICULTURAL COLLEGE PREMISES  CEDARA",
    Latitude: "-29.553",
    Longitude: "30.2707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10475",
    WardLookupId: "1340",
    VDNumber: "43770966",
    RegisteredPopulation: "476",
    VotingStationName: "NXAMALALA ISLAMIC SCHOOL",
    Address:
      "0 MASHINGENI  NEXT TO MPOPHOMENI CEMETERY, LOWER MASHINGENI  NXAMALALA TRIBAL AREA",
    Latitude: "-29.5861",
    Longitude: "30.2071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10476",
    WardLookupId: "1340",
    VDNumber: "43770977",
    RegisteredPopulation: "1476",
    VotingStationName: "JULUKANDODA PRIMARY SCHOOL",
    Address:
      "950132 KWADULELA MAIN ROAD   KWADULELA, NEXT TO MCONJWANA HIGH SCHOOL  NXAMALALA TRIBAL AUTHORITY",
    Latitude: "-29.5971",
    Longitude: "30.1954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10477",
    WardLookupId: "1340",
    VDNumber: "43870068",
    RegisteredPopulation: "4128",
    VotingStationName: "ISIBONGO PRIMARY SCHOOL",
    Address: "0 ZOTHI  MPOPHOMENI TOWNSHIP; NXT TO EBUMNANDINI  MERRIVALE",
    Latitude: "-29.5731",
    Longitude: "30.1899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10478",
    WardLookupId: "1340",
    VDNumber: "43870181",
    RegisteredPopulation: "588",
    VotingStationName: "JABULANI TUCKSHOP",
    Address: "MAIN ROAD  MPOPHOMENI  MERRIVALE",
    Latitude: "-29.5729",
    Longitude: "30.1797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10479",
    WardLookupId: "1341",
    VDNumber: "43773149",
    RegisteredPopulation: "69",
    VotingStationName: "ASHLEY FARM TENT",
    Address: "0 PETRUS STROOM  HENBURY FARM - KWAGQISHI  HOWICK",
    Latitude: "-29.5605",
    Longitude: "30.1153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10480",
    WardLookupId: "1341",
    VDNumber: "43773150",
    RegisteredPopulation: "531",
    VotingStationName: "CORRIE-LYNN PRIMARY SCHOOL",
    Address: "25  PETRUS STROOM CORRIE LYNN   CORRIE LYNN   CORRIE LYNN",
    Latitude: "-29.5128",
    Longitude: "30.0995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10481",
    WardLookupId: "1341",
    VDNumber: "43773723",
    RegisteredPopulation: "1122",
    VotingStationName: "NXAMALALA COMMUNITY HALL",
    Address:
      "KWACHIEF MAIN RD  KWACHIEF LOCATION,   NXAMALALA TRIBAL AUTHORITY",
    Latitude: "-29.5797",
    Longitude: "30.1773",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10482",
    WardLookupId: "1341",
    VDNumber: "43773745",
    RegisteredPopulation: "1491",
    VotingStationName: "HAZA PRIMARY SCHOOL",
    Address:
      "0 HAZA LOCATION  OPPOSITE HAZA TAXI RANK  NXAMALALA TRIBAL AUTHORITY",
    Latitude: "-29.595",
    Longitude: "30.1782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10483",
    WardLookupId: "1341",
    VDNumber: "43775040",
    RegisteredPopulation: "812",
    VotingStationName: "THOKANS STORE",
    Address: "R103  LIONS RIVER  LIONS RIVER",
    Latitude: "-29.4664",
    Longitude: "30.1553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10484",
    WardLookupId: "1341",
    VDNumber: "43870158",
    RegisteredPopulation: "1211",
    VotingStationName: "UMTHUMBOWOLWAZI SECONDARY SCHOOL",
    Address: "01 INGULULE   MPOPHOMENI  MERRIVALE",
    Latitude: "-29.5572",
    Longitude: "30.1771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10485",
    WardLookupId: "1342",
    VDNumber: "43870079",
    RegisteredPopulation: "1867",
    VotingStationName: "MPOPHOMENI COMMUNITY HALL",
    Address: "N.MANDELA H/WAY  MPOPHOMENI  HOWICK",
    Latitude: "-29.566",
    Longitude: "30.1856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10486",
    WardLookupId: "1342",
    VDNumber: "43870147",
    RegisteredPopulation: "1745",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "01 CHRIS HANI  MPOPHOMENI  MERRIVALE",
    Latitude: "-29.5746",
    Longitude: "30.1835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10487",
    WardLookupId: "1343",
    VDNumber: "43870057",
    RegisteredPopulation: "2662",
    VotingStationName: "PRESBYTERIAN CHURCH",
    Address: "UBHEJANE ROAD  MPOPHOMENI  MERRIVALE",
    Latitude: "-29.5655",
    Longitude: "30.1765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10488",
    WardLookupId: "1343",
    VDNumber: "43870080",
    RegisteredPopulation: "968",
    VotingStationName: "SIFISESIHLE LOWER PRIMARY SCHOOL",
    Address: "01 BOXER AVENUE  MPOPHOMENI  MERRIVALE",
    Latitude: "-29.5688",
    Longitude: "30.1795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10489",
    WardLookupId: "1343",
    VDNumber: "43870170",
    RegisteredPopulation: "727",
    VotingStationName: "ZAMUTHULE PRIMARY SCHOOL",
    Address: "CHRIS HANI RD  MPOPHOMENI  HOWICK",
    Latitude: "-29.566",
    Longitude: "30.181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10490",
    WardLookupId: "1344",
    VDNumber: "43870035",
    RegisteredPopulation: "1381",
    VotingStationName: "NG CHURCH",
    Address: "HOLIDAY ROAD  MERRIEVALE  HOWICK",
    Latitude: "-29.5155",
    Longitude: "30.2434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10491",
    WardLookupId: "1344",
    VDNumber: "43870125",
    RegisteredPopulation: "1353",
    VotingStationName: "NOGQAZA PRIMARY SCHOOL",
    Address: "01 KHWELA  KWAMEVANA  HOWICK",
    Latitude: "-29.492",
    Longitude: "30.2296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10492",
    WardLookupId: "1344",
    VDNumber: "43870136",
    RegisteredPopulation: "2792",
    VotingStationName: "COSMO PRIMARY SCHOOL",
    Address: "MAPHOLOBA STREET  TUMBLEWEED  HOWICK SOUTH",
    Latitude: "-29.5125",
    Longitude: "30.2191",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10493",
    WardLookupId: "1345",
    VDNumber: "43773240",
    RegisteredPopulation: "157",
    VotingStationName: "OAKLAND FARM TENT",
    Address: "OAKLAND FARM  MOOI RIVER  MOOI RIVER",
    Latitude: "-29.1151",
    Longitude: "30.0124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10494",
    WardLookupId: "1345",
    VDNumber: "43773341",
    RegisteredPopulation: "215",
    VotingStationName: "KWA CUBELA COMBINED SCHOOL",
    Address: "1ST MIDDLERUS ROAD  SHACKLETON FARM, MOOI RIVER  MOOI RIVER",
    Latitude: "-29.1578",
    Longitude: "30.0369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10495",
    WardLookupId: "1345",
    VDNumber: "43773352",
    RegisteredPopulation: "333",
    VotingStationName: "SCOTTSFONTEIN PRIMARY SCHOOL",
    Address: "1ST MIDDLERUS ROAD  SCOTSFONTEIN FARM  MOOI RIVER",
    Latitude: "-29.066",
    Longitude: "30.1183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10496",
    WardLookupId: "1345",
    VDNumber: "43930021",
    RegisteredPopulation: "2162",
    VotingStationName: "MOOI RIVER TOWN HALL",
    Address: "CHURCH ROAD  MOOI RIVER  MOOI RIVER",
    Latitude: "-29.2058",
    Longitude: "29.9958",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10497",
    WardLookupId: "1346",
    VDNumber: "43773217",
    RegisteredPopulation: "376",
    VotingStationName: "DABULAMANZI COMBINED SCHOOL",
    Address: "KAMBERG ROAD  ROSETTA  ROSETTA",
    Latitude: "-29.3281",
    Longitude: "29.8435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10498",
    WardLookupId: "1346",
    VDNumber: "43773228",
    RegisteredPopulation: "269",
    VotingStationName: "CARSHALTON FARM TENT",
    Address: "HLATHIKHULU ROAD  CARSHALTON FARM  MOOI RIVER",
    Latitude: "-29.2279",
    Longitude: "29.8755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10499",
    WardLookupId: "1346",
    VDNumber: "43773239",
    RegisteredPopulation: "216",
    VotingStationName: "MIDDLEFIELD PRIMARY SCHOOL",
    Address: "INTERSECTION D172 & D167  HIDCOTE  MOOI RIVER",
    Latitude: "-29.1567",
    Longitude: "29.9563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10500",
    WardLookupId: "1346",
    VDNumber: "43773655",
    RegisteredPopulation: "140",
    VotingStationName: "SIBONOKUHLE PRIMARY SCHOOL",
    Address: "ROSETTA INTERSECTION  ROSETTA  ROSETTA",
    Latitude: "-29.2997",
    Longitude: "29.9572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10501",
    WardLookupId: "1346",
    VDNumber: "43774094",
    RegisteredPopulation: "777",
    VotingStationName: "TENDELA COMBINED SCHOOL",
    Address: "KAMBERG ROAD  KAMBERG  ROSETTA",
    Latitude: "-29.3749",
    Longitude: "29.6812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10502",
    WardLookupId: "1346",
    VDNumber: "43774926",
    RegisteredPopulation: "175",
    VotingStationName: "ROBIN PRIMARY SCHOOL",
    Address: "KWASOGUBU  RONDEBOSCH FARM  MOOIRIVER",
    Latitude: "-29.2687",
    Longitude: "30.0193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10503",
    WardLookupId: "1346",
    VDNumber: "43930032",
    RegisteredPopulation: "691",
    VotingStationName: "ROSETTA COMMUNITY HALL",
    Address: "KAMBERG ROAD  ROSETTA  ROSETTA",
    Latitude: "-29.3043",
    Longitude: "29.9746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10504",
    WardLookupId: "1347",
    VDNumber: "43930010",
    RegisteredPopulation: "1485",
    VotingStationName: "BRUNTVILLE COMMUNITY HALL",
    Address: "MAIN ROAD  BRUNTVILLE  MOOI RIVER",
    Latitude: "-29.2216",
    Longitude: "30.0112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10505",
    WardLookupId: "1347",
    VDNumber: "43930054",
    RegisteredPopulation: "1144",
    VotingStationName: "EMINYEZANENI HIGH SCHOOL",
    Address: "MABELE STREET  BRUNTVILLE TOWNSHIP  MOOI MPOFANA",
    Latitude: "-29.2193",
    Longitude: "30.0147",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10506",
    WardLookupId: "1347",
    VDNumber: "43930076",
    RegisteredPopulation: "819",
    VotingStationName: "APOSTOLIC CHURCH",
    Address: "MAIN ROAD, BRUNTVILLE  WHITE CITY SECTION  MOOI RIVER",
    Latitude: "-29.2253",
    Longitude: "30.0083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10507",
    WardLookupId: "1348",
    VDNumber: "43773420",
    RegisteredPopulation: "419",
    VotingStationName: "MIDDLERUS FARMERS COMMUNITY HALL DOORNKLOOF",
    Address: "MIDDLEREST ROAD  MIDDLEREST  MOOI RIVER",
    Latitude: "-29.03",
    Longitude: "30.2418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10508",
    WardLookupId: "1348",
    VDNumber: "43773431",
    RegisteredPopulation: "505",
    VotingStationName: "DOORNKLOOF COMBINED SCHOOL",
    Address: "P174 MIDDELRUS ROAD  MIDDELRUS VRYSTAT FARM  MOOI RIVER",
    Latitude: "-29.0919",
    Longitude: "30.2369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10509",
    WardLookupId: "1348",
    VDNumber: "43773442",
    RegisteredPopulation: "393",
    VotingStationName: "BARKSTON ASH HIGHER PRIMARY SCHOOL",
    Address: "R622 GREYTOWN ROAD  KWAMQENULA  MOOIRIVER",
    Latitude: "-29.2029",
    Longitude: "30.2091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10510",
    WardLookupId: "1348",
    VDNumber: "43774117",
    RegisteredPopulation: "1006",
    VotingStationName: "TAURICUS SCHOOL",
    Address: "GREYTOWN ROAD  RIETVLEI  MOOI RIVER",
    Latitude: "-29.1835",
    Longitude: "30.2853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10511",
    WardLookupId: "1348",
    VDNumber: "43774487",
    RegisteredPopulation: "376",
    VotingStationName: "ONVERWAG COMBINED SCHOOL",
    Address: "R622 GREYTOWN ROAD  RIETVLEI CRAIGBURN  MOOI RIVER",
    Latitude: "-29.193",
    Longitude: "30.2698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10512",
    WardLookupId: "1348",
    VDNumber: "43774993",
    RegisteredPopulation: "367",
    VotingStationName: "ROCKY`S DRIFT PRIMARY SCHOOL",
    Address: "GRAVEL ROAD  KWAMPHUMELA  EMACHUNWININI",
    Latitude: "-29.0206",
    Longitude: "30.2983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10513",
    WardLookupId: "1348",
    VDNumber: "43775006",
    RegisteredPopulation: "259",
    VotingStationName: "BIRDSPRUIT PRIMARY SCHOOL",
    Address: "P172 MIDDELRUS ROAD  BIRDSPRUIT  EPHOFINI  MOOI RIVER",
    Latitude: "-29.0037",
    Longitude: "30.1866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10514",
    WardLookupId: "1348",
    VDNumber: "43775613",
    RegisteredPopulation: "121",
    VotingStationName: "THOKOZA CHONCO BUILDING",
    Address: "R74  THOKOZA AREA  MOOI RIVER",
    Latitude: "-28.9368",
    Longitude: "30.2623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10515",
    WardLookupId: "1348",
    VDNumber: "43775624",
    RegisteredPopulation: "141",
    VotingStationName: "HLAZENI PRIMARY SCHOOL",
    Address: "D508  MDUBUZWENI  MOOI RIVER",
    Latitude: "-29.1393",
    Longitude: "30.2182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10516",
    WardLookupId: "1349",
    VDNumber: "43773138",
    RegisteredPopulation: "345",
    VotingStationName: "THE GROVE COMBINED SCHOOL",
    Address: "R622 GREYTOWN ROAD  RIVERSIDE FARM  MOOIRIVER",
    Latitude: "-29.1771",
    Longitude: "30.1137",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10517",
    WardLookupId: "1349",
    VDNumber: "43774151",
    RegisteredPopulation: "241",
    VotingStationName: "WESTON COLLEGE PAVILION",
    Address: "R622 GREYTOWN ROAD  WESTON FARM  MOOI RIVER",
    Latitude: "-29.213",
    Longitude: "30.0357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10518",
    WardLookupId: "1349",
    VDNumber: "43930043",
    RegisteredPopulation: "2690",
    VotingStationName: "TOWNVIEW TEMPORARY STATION (TENT)",
    Address:
      "MAIN ROAD BRUNTVILLE  TOWNVIEW OPPOSITE BRUNTVILLE CLINIC  MOOI RIVER",
    Latitude: "-29.2146",
    Longitude: "30.0102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10519",
    WardLookupId: "1349",
    VDNumber: "43930065",
    RegisteredPopulation: "874",
    VotingStationName: "PHUMLAS (TENT)",
    Address: "MAIN ROAD BRUNTVILLE  PHUMLAS  MOOI RIVER",
    Latitude: "-29.2204",
    Longitude: "30.0195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10520",
    WardLookupId: "1350",
    VDNumber: "43772890",
    RegisteredPopulation: "184",
    VotingStationName: "SIYAMUKELA EDU CARE CENTRE",
    Address: "LOWER LOTHENI  LOTHENI  NXAMALALA",
    Latitude: "-29.4924",
    Longitude: "29.5701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10521",
    WardLookupId: "1350",
    VDNumber: "43772902",
    RegisteredPopulation: "505",
    VotingStationName: "MAHLUTSHINI PRIMARY SCHOOL",
    Address: "LOTHENI ROAD  MAHLUTSHINI  IMPENDLE",
    Latitude: "-29.5509",
    Longitude: "29.5989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10522",
    WardLookupId: "1350",
    VDNumber: "43773071",
    RegisteredPopulation: "368",
    VotingStationName: "CELANI PRIMARY SCHOOL",
    Address: "STOFFELTON MAIN ROAD  STOFFELTON  IMPENDLE",
    Latitude: "-29.6306",
    Longitude: "29.7054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10523",
    WardLookupId: "1350",
    VDNumber: "43773082",
    RegisteredPopulation: "780",
    VotingStationName: "HOLOMA PRIMARY SCHOOL",
    Address: "INZINGA EMZUMBE  INZINGA  IMPENDLE",
    Latitude: "-29.5931",
    Longitude: "29.7512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10524",
    WardLookupId: "1350",
    VDNumber: "43773093",
    RegisteredPopulation: "587",
    VotingStationName: "STOFFELTON PRIMARY SCHOOL",
    Address: "STOFFELTON MAIN ROAD  STOFFELTON  IMPENDLE",
    Latitude: "-29.6227",
    Longitude: "29.6882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10525",
    WardLookupId: "1350",
    VDNumber: "43773598",
    RegisteredPopulation: "751",
    VotingStationName: "LUGAJU JUNIOR SECONDARY SCHOOL",
    Address: "INZINGA MAIN ROAD  INZINGA LOCATION  IMPENDLE",
    Latitude: "-29.6025",
    Longitude: "29.7725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10526",
    WardLookupId: "1350",
    VDNumber: "43774050",
    RegisteredPopulation: "371",
    VotingStationName: "MALUNGA PRIMARY SCHOOL",
    Address: "LOTHENI ROAD  LOTHENI  IMPENDLE",
    Latitude: "-29.5083",
    Longitude: "29.5991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10527",
    WardLookupId: "1350",
    VDNumber: "43774296",
    RegisteredPopulation: "130",
    VotingStationName: "THANDOLWABASHA PRIMARY SCHOOL",
    Address: "STOFFELTON ROAD  LOWER MAKHUZENI  IMPENDLE",
    Latitude: "-29.6335",
    Longitude: "29.7413",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10528",
    WardLookupId: "1350",
    VDNumber: "43774544",
    RegisteredPopulation: "275",
    VotingStationName: "KHETHUKUTHULA PRIMARY SCHOOL",
    Address: "ENKANGALA ROAD  ENKANGALA CLEN LOCATION  IMPENDLE",
    Latitude: "-29.5744",
    Longitude: "29.6375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10529",
    WardLookupId: "1351",
    VDNumber: "43773004",
    RegisteredPopulation: "871",
    VotingStationName: "NXAMALALA SEC SCHOOL",
    Address: "P127 ROAD  MACSEM  IMPENDLE",
    Latitude: "-29.6393",
    Longitude: "29.8363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10530",
    WardLookupId: "1351",
    VDNumber: "43773026",
    RegisteredPopulation: "626",
    VotingStationName: "ENGUKA COMBINED PRIMARY SCHOOL",
    Address: "INGUGA MAIN ROAD  ENGUGA  LOCATION  IMPENDLE",
    Latitude: "-29.5888",
    Longitude: "29.8045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10531",
    WardLookupId: "1351",
    VDNumber: "43773037",
    RegisteredPopulation: "690",
    VotingStationName: "KHAZIMULA PRIMARY SCHOOL",
    Address: "QUTSHINI MAIN ROAD  QUTSHINI LOCATION  IMPENDLE",
    Latitude: "-29.5735",
    Longitude: "29.7866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10532",
    WardLookupId: "1351",
    VDNumber: "43773048",
    RegisteredPopulation: "430",
    VotingStationName: "NTSHIYABANTU PRIMARY SCHOOL",
    Address: "NTSHIYABANTU MAIN ROAD  NTSHIYABANTU  LOCATION  IMPENDLE",
    Latitude: "-29.5962",
    Longitude: "29.7984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10533",
    WardLookupId: "1351",
    VDNumber: "43773059",
    RegisteredPopulation: "601",
    VotingStationName: "NOGASELA PRIMARY SCHOOL",
    Address: "P127 MBALIYEZWE  MBALIYEZWE  LOCATION  IMPENDLE",
    Latitude: "-29.621",
    Longitude: "29.7972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10534",
    WardLookupId: "1351",
    VDNumber: "43773891",
    RegisteredPopulation: "173",
    VotingStationName: "UKUKHANYA PRIMARY SCHOOL",
    Address: "L746 EKUKHANYENI MAIN ROAD  QWEBEDE LOCATION  IMPENDLE",
    Latitude: "-29.6518",
    Longitude: "29.8036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10535",
    WardLookupId: "1351",
    VDNumber: "43774195",
    RegisteredPopulation: "1002",
    VotingStationName: "CHIBELIHLE COMBINED SCHOOL",
    Address: "SWAMPU MAIN ROAD  SWAMP LOCATION  IMPENDLE",
    Latitude: "-29.6353",
    Longitude: "29.7893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10536",
    WardLookupId: "1352",
    VDNumber: "43772935",
    RegisteredPopulation: "694",
    VotingStationName: "NOVUKA PRIMARY SCHOOL",
    Address: "NOVUKA MAIN ROAD  NOVUKA  IMPENDLE",
    Latitude: "-29.5728",
    Longitude: "29.8463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10537",
    WardLookupId: "1352",
    VDNumber: "43772980",
    RegisteredPopulation: "610",
    VotingStationName: "ZIMISELE CRECHE",
    Address: "PHINDANGENE ROAD  PHINDANGENE  IMPENDLE",
    Latitude: "-29.643",
    Longitude: "29.8579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10538",
    WardLookupId: "1352",
    VDNumber: "43772991",
    RegisteredPopulation: "816",
    VotingStationName: "KWAKHETHA PRIMARY SCHOOL",
    Address: "P127 KWAKHETHA  KWAKHETHA LOCATION  IMPENDLE",
    Latitude: "-29.6178",
    Longitude: "29.8453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10539",
    WardLookupId: "1352",
    VDNumber: "43773015",
    RegisteredPopulation: "762",
    VotingStationName: "BONELOKUHLE PRIMARY SCHOOL",
    Address: "SMILO BAR ROAD  SMILO BAR  IMPENDLE",
    Latitude: "-29.657",
    Longitude: "29.8588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10540",
    WardLookupId: "1352",
    VDNumber: "43774533",
    RegisteredPopulation: "247",
    VotingStationName: "WOZOBONA PRIMARY SCHOOL",
    Address: "COME AND SEE MAIN ROAD  COME AND  SEE  LOCATION  IMPENDLE",
    Latitude: "-29.6565",
    Longitude: "29.8289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10541",
    WardLookupId: "1352",
    VDNumber: "43774982",
    RegisteredPopulation: "482",
    VotingStationName: "THANDANANI CRECHE",
    Address: "P127 NTOKOZWEN  NTOKOZWENI AREA  IMPENDLE",
    Latitude: "-29.6195",
    Longitude: "29.8601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10542",
    WardLookupId: "1352",
    VDNumber: "43775635",
    RegisteredPopulation: "78",
    VotingStationName: "CAMDEN CRECHE",
    Address: "MAIN ROAD   CAMDEN  IMPENDLE",
    Latitude: "-29.7388",
    Longitude: "29.8933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10543",
    WardLookupId: "1352",
    VDNumber: "43880014",
    RegisteredPopulation: "481",
    VotingStationName: "IMPENDLE COMMUNITY HALL",
    Address: "165 IKHWEZI ROAD  IMPENDLE VILLAGE  IMPENDLE VILLAGE",
    Latitude: "-29.6024",
    Longitude: "29.866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10544",
    WardLookupId: "1353",
    VDNumber: "43772070",
    RegisteredPopulation: "399",
    VotingStationName: "NDABISEKHAYA HIGH SCHOOL",
    Address: "D1212 NONGUQA MAIN ROAD  ZASHUKE AREA  IMPENDLE",
    Latitude: "-29.7808",
    Longitude: "29.9179",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10545",
    WardLookupId: "1353",
    VDNumber: "43772913",
    RegisteredPopulation: "120",
    VotingStationName: "IVANHOE PRIMARY SCHOOL",
    Address: "DANGLE ROAD  KWAMAGUSHA FARM  IMPENDLE",
    Latitude: "-29.5467",
    Longitude: "29.9001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10546",
    WardLookupId: "1353",
    VDNumber: "43772924",
    RegisteredPopulation: "451",
    VotingStationName: "BOSTON COUNTRY CLUB",
    Address: "R617 BOSTON  IMPENDLE",
    Latitude: "-29.6829",
    Longitude: "30.0274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10547",
    WardLookupId: "1353",
    VDNumber: "43772946",
    RegisteredPopulation: "601",
    VotingStationName: "NHLABAMKHOSI PRIMARY SCHOOL",
    Address: "NHLABAMKHOSI ROAD  NHLABAMKHOSI AREA  IMPENDLE",
    Latitude: "-29.5955",
    Longitude: "29.8873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10548",
    WardLookupId: "1353",
    VDNumber: "43772957",
    RegisteredPopulation: "1233",
    VotingStationName: "UBUNYE PRIMARY SCHOOL",
    Address: "GOMANE  H MAIN ROAD  GOMANE LOCATION  IMPENDLE",
    Latitude: "-29.6197",
    Longitude: "29.9038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10549",
    WardLookupId: "1353",
    VDNumber: "43772968",
    RegisteredPopulation: "665",
    VotingStationName: "HLELOLUSHA PRIMARY SCHOOL",
    Address: "GOMANE ESLAHENI ROAD  GOMANE ESLAHENI  IMPENDLE",
    Latitude: "-29.6165",
    Longitude: "29.8837",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10550",
    WardLookupId: "1353",
    VDNumber: "43772979",
    RegisteredPopulation: "808",
    VotingStationName: "GOMANE  HALL",
    Address: "P127 GOMANE CROSSING  GOMANE  IMPENDLE",
    Latitude: "-29.6113",
    Longitude: "29.8753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10551",
    WardLookupId: "1353",
    VDNumber: "43775499",
    RegisteredPopulation: "487",
    VotingStationName: "SIYAZAMA SECONDARY SCHOOL",
    Address: "IMPENDLE MAIN ROAD  GOMANE  IMPENDLE",
    Latitude: "-29.6247",
    Longitude: "29.8896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10552",
    WardLookupId: "1354",
    VDNumber: "43770832",
    RegisteredPopulation: "1022",
    VotingStationName: "TETELEGU PRIMARY SCHOOL",
    Address: "CALUZA ROAD  MPUMUZA  MPUMUZA TRADITIONAL COUNCIL",
    Latitude: "-29.6099",
    Longitude: "30.3008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10553",
    WardLookupId: "1354",
    VDNumber: "43770854",
    RegisteredPopulation: "1419",
    VotingStationName: "PHAYIPHINI HIGH SCHOOL",
    Address:
      "00 PHAYIPHINI ROAD   SIXTIES, BEFORE TAXI RANK FROM ASHDOWN  P.M.BURG",
    Latitude: "-29.628",
    Longitude: "30.3234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10554",
    WardLookupId: "1354",
    VDNumber: "43770865",
    RegisteredPopulation: "976",
    VotingStationName: "ENTOKOZWENI SPECIAL SCHOOL",
    Address: "SWEETWATERS MAIN ROAD   SWEETWATER - MPUMUZA  PIETERMARITZBURG",
    Latitude: "-29.6017",
    Longitude: "30.2861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10555",
    WardLookupId: "1354",
    VDNumber: "43772575",
    RegisteredPopulation: "1531",
    VotingStationName: "VEZOKUHLE PRIMARY SCHOOL",
    Address: "D1135  PHAYIPHINI - MPUMUZA  P.M.BURG",
    Latitude: "-29.6295",
    Longitude: "30.3187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10556",
    WardLookupId: "1354",
    VDNumber: "43773622",
    RegisteredPopulation: "1593",
    VotingStationName: "LADUMA HIGH SCHOOL",
    Address: "OFF CALUZA ROAD    MPUMUZA   PIETERMARITZBURG",
    Latitude: "-29.6222",
    Longitude: "30.2979",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10557",
    WardLookupId: "1354",
    VDNumber: "43774645",
    RegisteredPopulation: "1296",
    VotingStationName: "MPUMUZA CRECHE",
    Address: "SHAYAMOYA MAIN ROAD   SHAYAMOYA - MPUMUZA   PIETERMARITZBURG",
    Latitude: "-29.6286",
    Longitude: "30.3353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10558",
    WardLookupId: "1354",
    VDNumber: "43774746",
    RegisteredPopulation: "678",
    VotingStationName: "METHODIST CHURCH",
    Address:
      "OFF ZAYEKA MAIN ROAD   ZAYEKA - MPUMUZA  PIETERMARITZBURG / MPUMUZA TRADITIONAL AUTHORITY",
    Latitude: "-29.6065",
    Longitude: "30.2782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10559",
    WardLookupId: "1354",
    VDNumber: "43775275",
    RegisteredPopulation: "1027",
    VotingStationName: "FREE AFRICAN CONGREGATIONAL CHURCH (FACC)",
    Address:
      "PHAYIPHINI ROAD,    PHYIPHINI NEXT TO XABA TUCK SHOP   PIETERMARITZBURG",
    Latitude: "-29.6287",
    Longitude: "30.3121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10560",
    WardLookupId: "1354",
    VDNumber: "43775286",
    RegisteredPopulation: "827",
    VotingStationName: "AFRICAN CHURCH (KWANZIMANDE)",
    Address: "CALUZA ROAD  MPUMUZA  MPUMUZA TRADITIONAL COUNCIL",
    Latitude: "-29.6322",
    Longitude: "30.3031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10561",
    WardLookupId: "1354",
    VDNumber: "43775297",
    RegisteredPopulation: "614",
    VotingStationName: "THULA CRECHE",
    Address:
      "MKANGALA ROAD  MKANGALA LOCATION  MPUMUZA TRADITIONAL ADMINISTRATION COUNCIL",
    Latitude: "-29.5963",
    Longitude: "30.2836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10562",
    WardLookupId: "1355",
    VDNumber: "43770843",
    RegisteredPopulation: "1205",
    VotingStationName: "SIYANDA HIGH SCHOOL",
    Address: "MPUSHINI RD  SWEETWATERS  PIETERMARITZBURG",
    Latitude: "-29.6196",
    Longitude: "30.269",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10563",
    WardLookupId: "1355",
    VDNumber: "43770876",
    RegisteredPopulation: "1148",
    VotingStationName: "NOBANDA LOWER PRIMARY SCHOOL",
    Address: "MABANE GRAVEL ROAD, MABANE P.M.BURG MPUMUZA TRADITIONAL COUNCIL",
    Latitude: "-29.6015",
    Longitude: "30.274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10564",
    WardLookupId: "1355",
    VDNumber: "43770887",
    RegisteredPopulation: "405",
    VotingStationName: "SHAYABANTU HIGH SCHOOL",
    Address:
      "EGCEBENI MAIN ROAD  EGCEBENI[INADI]  P.M.BURG / INADI TRADITIONAL AUTHORITY",
    Latitude: "-29.6098",
    Longitude: "30.2481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10565",
    WardLookupId: "1355",
    VDNumber: "43770911",
    RegisteredPopulation: "458",
    VotingStationName: "IMBUBU  PRIMARY SCHOOL",
    Address:
      "SWEETWATERS MAIN ROAD  IMBUBU  PIETERMARITZBURG/INADI TRIBAL AUTHORITY",
    Latitude: "-29.6127",
    Longitude: "30.2483",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10566",
    WardLookupId: "1355",
    VDNumber: "43770922",
    RegisteredPopulation: "1100",
    VotingStationName: "SWEETWATERS PRIMARY SCHOOL",
    Address: "1132  MHLELENBANA ROAD  SWEETWATERS  PIETERMARITZBURG",
    Latitude: "-29.6111",
    Longitude: "30.2699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10567",
    WardLookupId: "1355",
    VDNumber: "43771226",
    RegisteredPopulation: "938",
    VotingStationName: "MBUBU GOSPEL CHURCH",
    Address:
      "00 SWEETWATERS MAIN RD  IMBUBU   PIETERMARITZBURG/INADI TRADITIONAL AUTHORITY",
    Latitude: "-29.6097",
    Longitude: "30.2578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10568",
    WardLookupId: "1355",
    VDNumber: "43771237",
    RegisteredPopulation: "931",
    VotingStationName: "THEMBENI GOSPEL CHURCH",
    Address:
      "00 ZAYEKA MAIN ROAD  ZAYEKA - MPUMUZA  P.M.BURG / MPUMUZA TRADITIONAL AUTHORITY",
    Latitude: "-29.6109",
    Longitude: "30.2846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10569",
    WardLookupId: "1355",
    VDNumber: "43773600",
    RegisteredPopulation: "1024",
    VotingStationName: "MASHAKA PRIMARY SCHOOL",
    Address: "MBUBU RD  SWEETWATERS  PIETERMARITZBURG",
    Latitude: "-29.5936",
    Longitude: "30.2683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10570",
    WardLookupId: "1355",
    VDNumber: "43773712",
    RegisteredPopulation: "1503",
    VotingStationName: "BHEKIZIZWE PRIMARY SCHOOL",
    Address: "ZAYEKA RD  ZAYEKA - MPUMUZA  P.M.BURG",
    Latitude: "-29.6187",
    Longitude: "30.2842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10571",
    WardLookupId: "1355",
    VDNumber: "43775309",
    RegisteredPopulation: "366",
    VotingStationName: "AFRICA CHURCH",
    Address:
      "0 MTHALANE ROAD   MKANGALA, PASS MTHALANE SHOP BEFORE STADIUM  PIETERMARITZBURG",
    Latitude: "-29.5954",
    Longitude: "30.2799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10572",
    WardLookupId: "1355",
    VDNumber: "43775310",
    RegisteredPopulation: "576",
    VotingStationName: "MOUNTAIN HOME SCHOOL",
    Address:
      "MBUTSHANE ROAD   MBUTSHANA, RIGHT TO CELL C PASS ASSEMBLY CHURCH OF  MPUMUZA TRADITIONAL COUNCIL",
    Latitude: "-29.5861",
    Longitude: "30.2651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10573",
    WardLookupId: "1355",
    VDNumber: "43775321",
    RegisteredPopulation: "536",
    VotingStationName: "KWANOMUSA PRE-SCHOOL",
    Address:
      "0 OFF SWEETWATERS ROAD   SWEETWATERS - MPUMUZA  PIETERMARITZBURG/MPUMUZA TRADITIONAL AUTHORITY",
    Latitude: "-29.6055",
    Longitude: "30.2706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10574",
    WardLookupId: "1356",
    VDNumber: "43770955",
    RegisteredPopulation: "2164",
    VotingStationName: "NXAMALALA HALL",
    Address:
      "P399   NXAMALALA   PIETERMARITZBURG/NXAMALALA TRADITIONAL AUTHORITY",
    Latitude: "-29.621",
    Longitude: "30.2382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10575",
    WardLookupId: "1356",
    VDNumber: "43770988",
    RegisteredPopulation: "1061",
    VotingStationName: "UMTHOQOTHO HIGH SCHOOL",
    Address:
      "D1130 BEKEZELA ROAD  KWANXAMALALA TRIBAL AUTHORITY   PIETERMARITZBURG",
    Latitude: "-29.6108",
    Longitude: "30.224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10576",
    WardLookupId: "1356",
    VDNumber: "43771248",
    RegisteredPopulation: "1118",
    VotingStationName: "MARIA MEMORIAL PRIMARY SCHOOL",
    Address:
      "P399 MPANDE ROAD   KWAMPANDE   PIETERMARITZBURG/INADI TRIBAL AUTHORITY",
    Latitude: "-29.6376",
    Longitude: "30.2014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10577",
    WardLookupId: "1356",
    VDNumber: "43771259",
    RegisteredPopulation: "460",
    VotingStationName: "AFRICAN GOSPEL CHURCH",
    Address:
      "P399 SWEETWATERS ROAD  NXAMALALA  P.M.BURG / NXAMALALA TRIBAL AUTHORITY",
    Latitude: "-29.6202",
    Longitude: "30.2187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10578",
    WardLookupId: "1356",
    VDNumber: "43771260",
    RegisteredPopulation: "793",
    VotingStationName: "ZUZULWAZI JUNIOR SECONDARY SCHOOL",
    Address:
      "P399 MPADE ROAD  KWAMPANDE  PIETERMARITZBURG/INADI TRIBAL AUTHORITY",
    Latitude: "-29.6438",
    Longitude: "30.1944",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10579",
    WardLookupId: "1356",
    VDNumber: "43771305",
    RegisteredPopulation: "1466",
    VotingStationName: "MQHATHI  PRIMARY SCHOOL",
    Address:
      "P399 MPANDE ROAD  MSUNDUZI AREA  PIETERMARITZBURG/INADI TRIBAL AUTHORITY",
    Latitude: "-29.6586",
    Longitude: "30.1803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10580",
    WardLookupId: "1356",
    VDNumber: "43774308",
    RegisteredPopulation: "1195",
    VotingStationName: "JUBULANI PRIMARY SCHOOL",
    Address:
      "D1130 BEKEZELA ROAD   NXAMALALA   PIETERMARITZBURG/NXAMALALA TIBAL AUTHORITY",
    Latitude: "-29.6212",
    Longitude: "30.1962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10581",
    WardLookupId: "1356",
    VDNumber: "43774735",
    RegisteredPopulation: "690",
    VotingStationName: "EMVELWENI LUTHERAN CHURCH",
    Address:
      "P399 - MPANDE ROAD   EMVELWENI  PIETERMARITZBURG / INADI TRIBAL AUTHORITY",
    Latitude: "-29.6277",
    Longitude: "30.2142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10582",
    WardLookupId: "1356",
    VDNumber: "43775332",
    RegisteredPopulation: "514",
    VotingStationName: "SHAYAMOYA MBUBU CRECHE",
    Address:
      "MBULUKWANE GRAVEL RD  MBULUKWANE NEXT TO MAPHUMULO STORE   PIETERMARITZBURG",
    Latitude: "-29.6082",
    Longitude: "30.2402",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10583",
    WardLookupId: "1356",
    VDNumber: "43775343",
    RegisteredPopulation: "658",
    VotingStationName: "SIWELILE PRIMARY SCHOOL",
    Address:
      "P399 BEKEZELA ROAD   KWACHAGWE   PIETERMARITZBURG - NXAMALALA T/AUTHORITY",
    Latitude: "-29.6137",
    Longitude: "30.2092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10584",
    WardLookupId: "1357",
    VDNumber: "43770898",
    RegisteredPopulation: "1232",
    VotingStationName: "MBANJWA PRIMARY SCHOOL",
    Address:
      "P399 OFF SWEETWATERS MAIN ROAD  IMBUBU  P.M.BURG/INADI TRIBAL AUTHORITY",
    Latitude: "-29.6172",
    Longitude: "30.2446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10585",
    WardLookupId: "1357",
    VDNumber: "43770933",
    RegisteredPopulation: "653",
    VotingStationName: "MGODINI CRECHE",
    Address:
      "MGODINI MAIN ROAD  MGODINI   PIETERMARITZBURG/ INADI TRIBAL AUTHORITY",
    Latitude: "-29.6406",
    Longitude: "30.2642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10586",
    WardLookupId: "1357",
    VDNumber: "43770944",
    RegisteredPopulation: "713",
    VotingStationName: "EZIBOVINI COMMUNITY HALL",
    Address: "412 P412  EZIBOVINI  PIETERMARITZBURG/INADI TRIBAL AUTHORITY",
    Latitude: "-29.6313",
    Longitude: "30.2349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10587",
    WardLookupId: "1357",
    VDNumber: "43771024",
    RegisteredPopulation: "1468",
    VotingStationName: "KWASHANGE PRIMARY SCHOOL",
    Address:
      "KWASHANGE MAIN ROAD  KWASHANGE  PIETERMARITZBURG/INADI TRIBAL AUTHORITY",
    Latitude: "-29.6501",
    Longitude: "30.2208",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10588",
    WardLookupId: "1357",
    VDNumber: "43771046",
    RegisteredPopulation: "1573",
    VotingStationName: "DLOKWAKHE JUNIOR PRIMARY SCHOOL",
    Address:
      "OFF BULWER ROAD   MVUNDLWENI   PIETERMARITZBURG/INADI TRIBAL AUTHORITY",
    Latitude: "-29.6645",
    Longitude: "30.2342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10589",
    WardLookupId: "1357",
    VDNumber: "43771316",
    RegisteredPopulation: "1512",
    VotingStationName: "NDELESHANE PRIMARY SCHOOL",
    Address:
      "OFF BULWER ROAD   GEZUBUSO   PIETERMARITZBURG/INADI TRIBAL AUTHORITY",
    Latitude: "-29.6661",
    Longitude: "30.2083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10590",
    WardLookupId: "1357",
    VDNumber: "43773611",
    RegisteredPopulation: "1545",
    VotingStationName: "HENLEY PRIMARY SCHOOL",
    Address: "KWASHANGE MAIN ROAD  KWASHANGE   P.M.BURG/INADI TRIBAL SCHOOL",
    Latitude: "-29.6402",
    Longitude: "30.2347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10591",
    WardLookupId: "1357",
    VDNumber: "43774274",
    RegisteredPopulation: "720",
    VotingStationName: "QOQISIZWE HIGH SCHOOL",
    Address:
      "OFF BULWER ROAD  ESHOWE LOCATION  P M BURG/INADI TRIBAL AUTHORITY",
    Latitude: "-29.6802",
    Longitude: "30.19",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10592",
    WardLookupId: "1357",
    VDNumber: "43775534",
    RegisteredPopulation: "589",
    VotingStationName: "HENLEY DAM UMNGENI WATER",
    Address: "MAIN STREET  HENLEY DAM EXTENTION  PIETERMARITZBURG",
    Latitude: "-29.6255",
    Longitude: "30.2504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10593",
    WardLookupId: "1358",
    VDNumber: "43771035",
    RegisteredPopulation: "1387",
    VotingStationName: "LUTHERAN CHURCH",
    Address:
      "OFF BULWER ROAD   KWAMNYANDU   PIETERMARITZBURG/INADI TRIBAL AUTHORITY",
    Latitude: "-29.6546",
    Longitude: "30.2614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10594",
    WardLookupId: "1358",
    VDNumber: "43771057",
    RegisteredPopulation: "1587",
    VotingStationName: "KWAMNYANDU PRIMARY SCHOOL",
    Address: "OFF BULWER ROAD  MVUNDLWENI   P.M.BURG/INADI TRIBAL AUTHORITY",
    Latitude: "-29.6561",
    Longitude: "30.25",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10595",
    WardLookupId: "1358",
    VDNumber: "43771068",
    RegisteredPopulation: "837",
    VotingStationName: "ZANINI CHURCH",
    Address:
      "TAFULENI ROAD   KWANGUBENI  PIETERMARITZBURG/INADI TRIBAL AUTHORITY",
    Latitude: "-29.69",
    Longitude: "30.2229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10596",
    WardLookupId: "1358",
    VDNumber: "43771079",
    RegisteredPopulation: "1171",
    VotingStationName: "INGQWANGELE HIGH SCHOOL",
    Address:
      "OFF SNATHING ROAD  KWANGUBENI  PIETERMARITZBURG/INADI TRIBAL AUTHORITY",
    Latitude: "-29.6804",
    Longitude: "30.2668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10597",
    WardLookupId: "1358",
    VDNumber: "43771080",
    RegisteredPopulation: "547",
    VotingStationName: "KWANOSHEZI COMMUNITY HALL",
    Address: "MAIN ROAD   KWANOSHEZI   INADI",
    Latitude: "-29.6891",
    Longitude: "30.2646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10598",
    WardLookupId: "1358",
    VDNumber: "43771091",
    RegisteredPopulation: "1402",
    VotingStationName: "KWANGUBENI PRIMARY SCHOOL",
    Address:
      "TAFULENI ROAD  KWANGUBENI   PIETERMARITZBURG/INADI TRIBAL AUTHORITY",
    Latitude: "-29.7",
    Longitude: "30.2413",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10599",
    WardLookupId: "1358",
    VDNumber: "43771103",
    RegisteredPopulation: "957",
    VotingStationName: "XIMBA TRIBAL AUTHORITY",
    Address: "TAFULENI ROAD  NTEMBENI  P.M.BURG/XIMBA TRIBAL COURT",
    Latitude: "-29.7147",
    Longitude: "30.2493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10600",
    WardLookupId: "1358",
    VDNumber: "43771271",
    RegisteredPopulation: "1039",
    VotingStationName: "ZAMELISIZWE PRIMARY SCHOOL",
    Address: "BULWER ROAD   GEZUBUSO  PIETERMARITZBURG/INADI TRIBAL AUTHORITY",
    Latitude: "-29.6714",
    Longitude: "30.2203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10601",
    WardLookupId: "1358",
    VDNumber: "43775354",
    RegisteredPopulation: "814",
    VotingStationName: "FREE CHURCH",
    Address: "BULWER ROAD   MVUNDLWENI   MVUNDLWENI - INADI TRIBAL AUTHORITY",
    Latitude: "-29.67",
    Longitude: "30.2308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10602",
    WardLookupId: "1359",
    VDNumber: "43770900",
    RegisteredPopulation: "951",
    VotingStationName: "IWAHLANGA PRIMARY SCHOOL",
    Address: "D1126  TAYLORS HALT   PIETERMARITZBURG/INADI TRIBAL AUTHORITY",
    Latitude: "-29.6907",
    Longitude: "30.1786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10603",
    WardLookupId: "1359",
    VDNumber: "43771114",
    RegisteredPopulation: "933",
    VotingStationName: "KWAMSINGA PRIMARY SCHOOL",
    Address:
      "OFF BAYNESFIELD ROAD  NTEMBENI   PIETERMARITZBURG/XIMBA TRIBAL AUTHORITY",
    Latitude: "-29.7263",
    Longitude: "30.238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10604",
    WardLookupId: "1359",
    VDNumber: "43771158",
    RegisteredPopulation: "1100",
    VotingStationName: "INHLANGENI PRIMARY SCHOOL",
    Address: "D1126   QANDA  PIETERMARITZBURG/MAFUNZE TRIBAL AUTHORITY",
    Latitude: "-29.7128",
    Longitude: "30.2129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10605",
    WardLookupId: "1359",
    VDNumber: "43771181",
    RegisteredPopulation: "1351",
    VotingStationName: "QANDA PRIMARY SCHOOL",
    Address: "DEDA ROAD  QANDA  PIETERMARITZBURG",
    Latitude: "-29.7011",
    Longitude: "30.1971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10606",
    WardLookupId: "1359",
    VDNumber: "43771192",
    RegisteredPopulation: "928",
    VotingStationName: "ZULU CONGREGATIONAL CHURCH",
    Address: "DEDA ROAD  DEDA  PIETERMARITZBURG/MAFUNZE TRIBAL AUTHORITY",
    Latitude: "-29.7186",
    Longitude: "30.196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10607",
    WardLookupId: "1359",
    VDNumber: "43771204",
    RegisteredPopulation: "1157",
    VotingStationName: "MVUNULO SENIOR SECONDARY SCHOOL",
    Address:
      "BULWER ROAD  TAYLORS HALT  PIETERMARITZBURG/MAFUNZE TRIBAL AUTHORITY",
    Latitude: "-29.685",
    Longitude: "30.1871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10608",
    WardLookupId: "1359",
    VDNumber: "43771327",
    RegisteredPopulation: "994",
    VotingStationName: "MLUNGISI HIGH SCHOOL",
    Address:
      "D1126  DINDI/TAYLORS HALT  PIETERMARITZBURG/MAFUNZE TRIBAL AUTHORITY",
    Latitude: "-29.7074",
    Longitude: "30.1882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10609",
    WardLookupId: "1359",
    VDNumber: "43771338",
    RegisteredPopulation: "559",
    VotingStationName: "DINDI PRIMARY SCHOOL",
    Address:
      "D1126  DINDI / TAYLORS HALT  PIETERMARITZBURG/MAFUNZE TRIBAL AUTHORITY",
    Latitude: "-29.7038",
    Longitude: "30.1813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10610",
    WardLookupId: "1359",
    VDNumber: "43775365",
    RegisteredPopulation: "545",
    VotingStationName: "CHICKEN PROJECT HALL",
    Address: "OFF QANDA ROAD  QANDA  PMBURG / MAFUNZE TRADITIONAL AUTHORITY",
    Latitude: "-29.693",
    Longitude: "30.1946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10611",
    WardLookupId: "1360",
    VDNumber: "43771169",
    RegisteredPopulation: "1290",
    VotingStationName: "KWAMNCANE HIGH SCHOOL",
    Address:
      "OFF BULWER ROAD  KWAMNCANE - ELANDSKOP  PIETERMARITZBURG/MPUMUZA TRIBAL AUTHORITY",
    Latitude: "-29.6975",
    Longitude: "30.1168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10612",
    WardLookupId: "1360",
    VDNumber: "43771215",
    RegisteredPopulation: "1359",
    VotingStationName: "MBABANE CRECHE",
    Address: "BULWER ROAD  MBABANE  PIETERMARITZBURG/MAFUNZE TRIBAL AUTHORITY",
    Latitude: "-29.6962",
    Longitude: "30.1354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10613",
    WardLookupId: "1360",
    VDNumber: "43771349",
    RegisteredPopulation: "1352",
    VotingStationName: "APOSTOLIC FAITH MISSION",
    Address:
      "D1124 GIBSON GATE ROAD  NOMA - MAFUNZE  PIETERMARITZBURG/MAFUNZE TRIBAL AUTHORITY",
    Latitude: "-29.7241",
    Longitude: "30.1297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10614",
    WardLookupId: "1360",
    VDNumber: "43771350",
    RegisteredPopulation: "1276",
    VotingStationName: "MAGWENYANE BRANCH OFFICE",
    Address:
      "BULWER ROAD  MUNYWINI MAGWENYANE  PIETERMARITZBURG/MAFUNZE TRIBAL AUTHORITY",
    Latitude: "-29.6937",
    Longitude: "30.1583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10615",
    WardLookupId: "1360",
    VDNumber: "43771361",
    RegisteredPopulation: "450",
    VotingStationName: "NKABINI CHURCH",
    Address:
      "OFF GIBSON GATE ROAD  NKABINI - MAFUNZE  PIETERMARITZBURG/MAFUNZE TRIBAL AUTHORITY",
    Latitude: "-29.7114",
    Longitude: "30.136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10616",
    WardLookupId: "1360",
    VDNumber: "43771372",
    RegisteredPopulation: "961",
    VotingStationName: "MAFUNZE TRADITIONAL COURT",
    Address:
      "SEVONTEIN PRISON ROAD,MAFUNZE  MAFUNZE, CROSS RAILWAY LINE FIRST RIGHT TURN   MAFUNZE",
    Latitude: "-29.7184",
    Longitude: "30.1221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10617",
    WardLookupId: "1360",
    VDNumber: "43775376",
    RegisteredPopulation: "575",
    VotingStationName: "VDA OFFICE - KARAWONDI",
    Address:
      "SEVONTEIN PRISON MAIN ROAD   MAFUNZE/SONGONZIMA   MAFUNZE TRADITIONAL AUTHORITY",
    Latitude: "-29.7035",
    Longitude: "30.1225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10618",
    WardLookupId: "1360",
    VDNumber: "43775387",
    RegisteredPopulation: "411",
    VotingStationName: "SIYAKHANYA CRECHE",
    Address: "KWANOMO ROAD  KWANOMO  PMB/MAFUNZE TRADITIONAL COUNCIL",
    Latitude: "-29.722",
    Longitude: "30.1405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10619",
    WardLookupId: "1360",
    VDNumber: "43775398",
    RegisteredPopulation: "363",
    VotingStationName: "VULINDLELA NORTH COMMUNITY HALL",
    Address: "BULWER ROAD  MAFUNZE   PMB/MAFUNZE TRADITIONAL COUNCIL",
    Latitude: "-29.6983",
    Longitude: "30.12",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10620",
    WardLookupId: "1361",
    VDNumber: "43771406",
    RegisteredPopulation: "1572",
    VotingStationName: "SIKHULULIWE HIGH SCHOOL",
    Address:
      "OFF BULWER ROAD  ZONDI STORE/ELANDSKOP  PIETERMARITZBURG/MPUMUZA TRIBAL AUTHORITY",
    Latitude: "-29.6787",
    Longitude: "30.1203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10621",
    WardLookupId: "1361",
    VDNumber: "43771417",
    RegisteredPopulation: "1481",
    VotingStationName: "ZWELIYAZUZA PRIMARY SCHOOL",
    Address:
      "OFF BULWER ROAD   ZONDI STORE - ELANDSKOP  PIETERMARITZBURG/MPUMUZA TRIBAL AUTHORITY",
    Latitude: "-29.6835",
    Longitude: "30.1068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10622",
    WardLookupId: "1361",
    VDNumber: "43771428",
    RegisteredPopulation: "1392",
    VotingStationName: "KUHLEKONKE HIGH SCHOOL",
    Address:
      "OFF BULWER ROAD   MASWAZINI - ELANDSKOP  PIETERMARITZBURG/MPUMUZA TRIBAL AUTHORITY",
    Latitude: "-29.6658",
    Longitude: "30.0865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10623",
    WardLookupId: "1361",
    VDNumber: "43771439",
    RegisteredPopulation: "1216",
    VotingStationName: "EMASWAZINI  PRIMARY SCHOOL",
    Address:
      "OFF BULWER ROAD  MASWAZINI - ELANDSKOP  PIETERMARITZBURG/MPUMUZA TRIBAL AUTHORITY",
    Latitude: "-29.667",
    Longitude: "30.0908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10624",
    WardLookupId: "1361",
    VDNumber: "43771462",
    RegisteredPopulation: "411",
    VotingStationName: "MAGWENYANE CRECHE",
    Address:
      "BULWER ROAD  MAGWENYANE / ELANDSKOP  PIETERMARITZBURG/MPUMUZA TRIBAL AUTHORITY",
    Latitude: "-29.6893",
    Longitude: "30.1593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10625",
    WardLookupId: "1361",
    VDNumber: "43774285",
    RegisteredPopulation: "418",
    VotingStationName: "IMBALENHLE CRECHE",
    Address:
      "OFF BULWER ROAD   NDEBEQHEKE - ELANDSKOP  P M BURG/MPUMUZA TRIBAL AUTHORITY",
    Latitude: "-29.6919",
    Longitude: "30.144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10626",
    WardLookupId: "1361",
    VDNumber: "43774320",
    RegisteredPopulation: "696",
    VotingStationName: "KWAMADLALA HALL",
    Address:
      "OFF BULWER ROAD   KWAMADLALA LOCATION  PIETERMARITZBURG/MPUMUZA TRIBAL AUTHORITY",
    Latitude: "-29.6898",
    Longitude: "30.1327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10627",
    WardLookupId: "1361",
    VDNumber: "43774454",
    RegisteredPopulation: "593",
    VotingStationName: "SIYATHUTHUKA CRECHE",
    Address:
      "OFF ZONDI SORE ROAD  KHETHI LOCATION   PIETERMARITZBURG/MPUMUZA TRIBAL AUTHORITY",
    Latitude: "-29.655",
    Longitude: "30.1211",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10628",
    WardLookupId: "1362",
    VDNumber: "43770999",
    RegisteredPopulation: "928",
    VotingStationName: "SIBANESIHLE HIGH SCHOOL",
    Address:
      "HAZA ROAD  KWAMGWAGWA LOCATION  PIETERMARITZBURG / INADI TRIBAL AUTHORITY",
    Latitude: "-29.6209",
    Longitude: "30.1699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10629",
    WardLookupId: "1362",
    VDNumber: "43771002",
    RegisteredPopulation: "1533",
    VotingStationName: "EMAFAKATHINI PRIMARY SCHOOL",
    Address:
      "OFF R617 UNDERBERG ROAD  MAFAKATINI   PIETERMARITZBURG / INADI TRIBAL AUTHORITY",
    Latitude: "-29.6416",
    Longitude: "30.1279",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10630",
    WardLookupId: "1362",
    VDNumber: "43771013",
    RegisteredPopulation: "1000",
    VotingStationName: "CAPRISA HALL",
    Address:
      "OFF R617 UNDERBERG ROAD  MAFAKATINI   PIETERMARITZBURG /INADI TRIBAL AUTHORITY",
    Latitude: "-29.6435",
    Longitude: "30.1471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10631",
    WardLookupId: "1362",
    VDNumber: "43771282",
    RegisteredPopulation: "660",
    VotingStationName: "SILINDOKUHLE PRIMARY SCHOOL",
    Address:
      "2229 HAZA ROAD - MGWAGWA  MGWAGWA  PIETERMARITZBURG-INADI TRIBAL AUTHORITY",
    Latitude: "-29.6316",
    Longitude: "30.1706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10632",
    WardLookupId: "1362",
    VDNumber: "43771293",
    RegisteredPopulation: "529",
    VotingStationName: "SANELISIWE PRIMARY SCHOOL",
    Address:
      "OFF BULWER ROAD  KHOBONGWANENI  PIETERMARITZBURG/MPUMUZA TRIBAL AUTHORITY",
    Latitude: "-29.6757",
    Longitude: "30.1657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10633",
    WardLookupId: "1362",
    VDNumber: "43771440",
    RegisteredPopulation: "1349",
    VotingStationName: "NGCENDOMHLOPHE HIGH SCHOOL",
    Address:
      "OFF R617 UNDERBERG ROAD  MAFAKATINI  PIETERMARITZBURG/MPUMUZATRIBAL AUTHORITY",
    Latitude: "-29.6508",
    Longitude: "30.1296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10634",
    WardLookupId: "1362",
    VDNumber: "43771451",
    RegisteredPopulation: "822",
    VotingStationName: "KHOBOGWANE PRIMARY SCHOOL",
    Address: "OFF MAFAKATINI ROAD  KHOBONGWANE  PIETERMARITZBURG",
    Latitude: "-29.6675",
    Longitude: "30.1358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10635",
    WardLookupId: "1362",
    VDNumber: "43775400",
    RegisteredPopulation: "1019",
    VotingStationName: "REFORMED CHURCH",
    Address:
      "MAFAKATINI MAIN ROAD  MAFAKATINI  PMB/MPUMUZA TRADITIONAL AUTHORITY",
    Latitude: "-29.6502",
    Longitude: "30.1131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10636",
    WardLookupId: "1362",
    VDNumber: "43775411",
    RegisteredPopulation: "963",
    VotingStationName: "NGCEDOMHLOPHE HALL",
    Address:
      "MAFAKATINI MAIN ROAD   MAFAKATINI  PMB/MPUMUZA TRADITIONAL AUTHORITY",
    Latitude: "-29.649",
    Longitude: "30.1285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10637",
    WardLookupId: "1363",
    VDNumber: "43950641",
    RegisteredPopulation: "1758",
    VotingStationName: "AZALEA CHURCH",
    Address: "MAIN RAOD  AZALEA  PIETERMARITZBURG/MSUNDUZI",
    Latitude: "-29.6859",
    Longitude: "30.3185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10638",
    WardLookupId: "1363",
    VDNumber: "43950922",
    RegisteredPopulation: "1806",
    VotingStationName: "KWAPATA HALL(UNIT S)",
    Address: "AZALEA ROAD  KWAPATA  PIETERMARITZBURG",
    Latitude: "-29.6772",
    Longitude: "30.3197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10639",
    WardLookupId: "1363",
    VDNumber: "43950933",
    RegisteredPopulation: "674",
    VotingStationName: "KWAPATA MUSJID & ISLAMIC COMMUNITY CENTRE",
    Address: "MOUNT PARTRIDGE ROAD  KWAPATA  PIETERMARITZBURG",
    Latitude: "-29.673",
    Longitude: "30.3262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10640",
    WardLookupId: "1363",
    VDNumber: "43950944",
    RegisteredPopulation: "1913",
    VotingStationName: "THANDOKUHLE CRECHE AZALEA",
    Address: "MOUNT PATRIDGE ROAD  AZALEA  PIETERMARIZTBURG",
    Latitude: "-29.6906",
    Longitude: "30.3155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10641",
    WardLookupId: "1363",
    VDNumber: "43951091",
    RegisteredPopulation: "864",
    VotingStationName: "VUBAMASI TRANSIST CAMP",
    Address:
      "BHOKIDE ROAD, KWAPATA VUBAMASI  KWATAPA VUBAMASI NEXT TO MBATHA STORE  PIETERMARITZBURG",
    Latitude: "-29.6721",
    Longitude: "30.3164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10642",
    WardLookupId: "1363",
    VDNumber: "43951215",
    RegisteredPopulation: "715",
    VotingStationName: "KHETHINDLELENHLE PRIMARY SCHOOL",
    Address:
      "589 UNIT S AZALEA  EMAFLATIN, PASS PATA HALL FIRST RIGHT TURN TO SCH  PIETERMARITZBURG",
    Latitude: "-29.6805",
    Longitude: "30.3155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10643",
    WardLookupId: "1364",
    VDNumber: "43950663",
    RegisteredPopulation: "2624",
    VotingStationName: "SNATHING COMMUNITY HALL",
    Address: "SNATHING MAIN ROAD   SNATHING   SNATHING",
    Latitude: "-29.6787",
    Longitude: "30.2879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10644",
    WardLookupId: "1364",
    VDNumber: "43950674",
    RegisteredPopulation: "1834",
    VotingStationName: "JALIS PLACE",
    Address: "0 GOGE ROAD  GG - SNATHING  PIETERMARITZBURG",
    Latitude: "-29.6829",
    Longitude: "30.2992",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10645",
    WardLookupId: "1364",
    VDNumber: "43950775",
    RegisteredPopulation: "2068",
    VotingStationName: "NHLAZATSHE COMMUNITY HALL",
    Address: "NHLAZATSHE MAIN   NHLAZATSHE  PIETERMARITZBURG",
    Latitude: "-29.6596",
    Longitude: "30.291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10646",
    WardLookupId: "1364",
    VDNumber: "43950887",
    RegisteredPopulation: "1767",
    VotingStationName: "EDENDALE ASSEMBLY OF GOD",
    Address: "0 DAMBUZA MAIN ROAD  DAMBUZA - EDENDALE  PIETERMARITZBURG",
    Latitude: "-29.6551",
    Longitude: "30.3021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10647",
    WardLookupId: "1364",
    VDNumber: "43951147",
    RegisteredPopulation: "617",
    VotingStationName: "UNIT H CHURCH",
    Address: "SNATHING ROAD   SNATHING / EDENDALE  PIETERMARITZBURG",
    Latitude: "-29.6658",
    Longitude: "30.2962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10648",
    WardLookupId: "1364",
    VDNumber: "43951226",
    RegisteredPopulation: "769",
    VotingStationName: "MAGABA METHODIST CHURCH",
    Address: "MAIN ROAD  SNATHING  EDENDALE",
    Latitude: "-29.6713",
    Longitude: "30.2938",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10649",
    WardLookupId: "1365",
    VDNumber: "43950742",
    RegisteredPopulation: "1144",
    VotingStationName: "ST MARTINS CHURCH",
    Address: "EDENDALE ROAD  EDENDALE  PIETERMARITZBURG",
    Latitude: "-29.6479",
    Longitude: "30.3164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10650",
    WardLookupId: "1365",
    VDNumber: "43950753",
    RegisteredPopulation: "1268",
    VotingStationName: "YMCA HALL",
    Address: "WATERWORKS RD  GEORGETOWN  EDENDALE",
    Latitude: "-29.6477",
    Longitude: "30.2939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10651",
    WardLookupId: "1365",
    VDNumber: "43950786",
    RegisteredPopulation: "1046",
    VotingStationName: "GEORGETOWN HIGH SCHOOL",
    Address: "EDENDALE ROAD  GEORGETOWN  PIETERMARITZBURG",
    Latitude: "-29.653",
    Longitude: "30.2963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10652",
    WardLookupId: "1365",
    VDNumber: "43950898",
    RegisteredPopulation: "1155",
    VotingStationName: "EDENDALE TECHNICAL HIGH SCHOOL",
    Address: "01 WATERFALL ROAD   EDENDALE  PIETERMARITZBURG",
    Latitude: "-29.6495",
    Longitude: "30.2864",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10653",
    WardLookupId: "1365",
    VDNumber: "43950988",
    RegisteredPopulation: "2606",
    VotingStationName: "ESIGODINI PRIMARY SCHOOL",
    Address: "214 WATERFALL ROAD   ESIGODINI, EDENDALE  PIETERMARITZBURG",
    Latitude: "-29.648",
    Longitude: "30.2743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10654",
    WardLookupId: "1366",
    VDNumber: "43950865",
    RegisteredPopulation: "834",
    VotingStationName: "NJABULO`S STORE",
    Address: "MAIN ROAD   SLANGPRUIT   SLANGSPRUIT",
    Latitude: "-29.6548",
    Longitude: "30.3638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10655",
    WardLookupId: "1366",
    VDNumber: "43951002",
    RegisteredPopulation: "1261",
    VotingStationName: "HLELINGOMUSO PRIMARY SCHOOL",
    Address: "6 CEDAR  UNIT 15 - IMBALI TOWNSHIP  PIETERMARITZBURG",
    Latitude: "-29.6682",
    Longitude: "30.3584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10656",
    WardLookupId: "1366",
    VDNumber: "43951046",
    RegisteredPopulation: "2881",
    VotingStationName: "FRANCE BIG HOUSE",
    Address:
      "68 OFF RICHMOND ROAD  PHASE 1 FRANCE  MSUNDUZI [PIETERMARITZBURG]",
    Latitude: "-29.6757",
    Longitude: "30.3778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10657",
    WardLookupId: "1366",
    VDNumber: "43951079",
    RegisteredPopulation: "1583",
    VotingStationName: "BUFFER STRIP HALL",
    Address: "MBOZISA STREET  BUFFER STRIP  MSUNDUZI [PIETERMARITZBURG]",
    Latitude: "-29.6548",
    Longitude: "30.3686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:42",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:42",
  },
  {
    VotingStationId: "10658",
    WardLookupId: "1366",
    VDNumber: "43951169",
    RegisteredPopulation: "2952",
    VotingStationName: "SLANGSPRUIT PRIMARY SCHOOL",
    Address:
      "SLANGSPRUIT GRAVEL ROAD,   SLANGSPRUIT AREA, BETWEEN IMBALI 2 SY SUPERMARKET",
    Latitude: "-29.6601",
    Longitude: "30.3642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10659",
    WardLookupId: "1366",
    VDNumber: "43951237",
    RegisteredPopulation: "1841",
    VotingStationName: "UMVUZO HIGH SCHOOL",
    Address: "FRANCE MAIN ROAD   PIETERMARITZBURG   PIETERMARITZBURG",
    Latitude: "-29.6729",
    Longitude: "30.3706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10660",
    WardLookupId: "1366",
    VDNumber: "43951428",
    RegisteredPopulation: "774",
    VotingStationName: "GRANGE ASSEMBLY OF GOD",
    Address: "JEFFRIES ROAD   WESTGATE  PIETERMARITZBURG",
    Latitude: "-29.657",
    Longitude: "30.3752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10661",
    WardLookupId: "1367",
    VDNumber: "43950955",
    RegisteredPopulation: "1818",
    VotingStationName: "THANDOKHULE PRE-SCHOOL",
    Address: "WILLOWFOUNTAIN ROAD  WILLOWFOUNTAIN  PIETERMARITZBURG",
    Latitude: "-29.7118",
    Longitude: "30.3162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10662",
    WardLookupId: "1367",
    VDNumber: "43950999",
    RegisteredPopulation: "2223",
    VotingStationName: "WILLOWFONTEIN INTERMEDIATE SCHOOL",
    Address:
      "WILLOWFONTAIN MAIN ROAD   WILLOWFONTAIN, PASS UNIT BB AND TAXI RANK   MSUNDUZI [PIETERMARITZBURG]",
    Latitude: "-29.6918",
    Longitude: "30.3324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10663",
    WardLookupId: "1367",
    VDNumber: "43951035",
    RegisteredPopulation: "2199",
    VotingStationName: "KWABHAKABHA HALL",
    Address:
      "WILLOWFONTEIN MAIN ROAD   WILLOWFONTEIN, KWABHAKABHA, OPPOSITE CLINIC  PIETERMARITZBURG",
    Latitude: "-29.7053",
    Longitude: "30.3296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10664",
    WardLookupId: "1367",
    VDNumber: "43951248",
    RegisteredPopulation: "1849",
    VotingStationName: "SILWANETSHE PRIMARY SCHOOL",
    Address:
      "KWAPHUPHA ROAD     WILLOWFONTEIN, KWAPHUPHA NEXT TO EKOYI/LANGA SHOP  PIETERMARITZBURG/MSUNDUZI",
    Latitude: "-29.7023",
    Longitude: "30.3349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10665",
    WardLookupId: "1368",
    VDNumber: "43950540",
    RegisteredPopulation: "2911",
    VotingStationName: "MEHLOKAZULU HIGH SCHOOL",
    Address: "FJ SITHOLE ROAD  UNIT 2  PIETERMARITZBURG",
    Latitude: "-29.6571",
    Longitude: "30.3457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10666",
    WardLookupId: "1368",
    VDNumber: "43950562",
    RegisteredPopulation: "1938",
    VotingStationName: "ZAMAZULU HIGH SCHOOL",
    Address: "7899 MPALA ROAD   IMBALI STAGE 2  PIETERMARITZBURG/MSUNDUZI",
    Latitude: "-29.6649",
    Longitude: "30.3493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10667",
    WardLookupId: "1368",
    VDNumber: "43950584",
    RegisteredPopulation: "1999",
    VotingStationName: "FEZOKHULE PRIMARY SCHOOL",
    Address: "WILLOWFONTAIN RD  IMBALI  PIETERMARITZBURG/MSUNDUZI",
    Latitude: "-29.672",
    Longitude: "30.3305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10668",
    WardLookupId: "1368",
    VDNumber: "43951103",
    RegisteredPopulation: "1900",
    VotingStationName: "FEDSEM HOUSE OF PRAYER",
    Address: "FEDSEM  UNIT J  IMBALI  MSUNDUZI",
    Latitude: "-29.6614",
    Longitude: "30.3387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10669",
    WardLookupId: "1368",
    VDNumber: "43951259",
    RegisteredPopulation: "1873",
    VotingStationName: "UNIT 18 COMMUNITY HALL",
    Address: "UNIT 18 MAIN ROAD   UNIT 18 IMBALI   PIETERMARITZBURG",
    Latitude: "-29.6791",
    Longitude: "30.3294",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10670",
    WardLookupId: "1369",
    VDNumber: "43950573",
    RegisteredPopulation: "2271",
    VotingStationName: "UNIT J HALL",
    Address: "SITE 968  UNIT J IMBALI  PIETERMARITZBURG",
    Latitude: "-29.6632",
    Longitude: "30.3316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10671",
    WardLookupId: "1369",
    VDNumber: "43950821",
    RegisteredPopulation: "2291",
    VotingStationName: "PATA SECONDARY SCHOOL",
    Address: "PATA ROAD  KWA PATA  PIETERMARITZBURG",
    Latitude: "-29.663",
    Longitude: "30.327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10672",
    WardLookupId: "1369",
    VDNumber: "43951158",
    RegisteredPopulation: "1610",
    VotingStationName: "BONGUDUNGA HIGH SCHOOL",
    Address:
      "53 BONGUDUNGA ROAD   DAMBUZA ENTABENI   PIETERMARITZBURG/MSUNDUZI",
    Latitude: "-29.662",
    Longitude: "30.3201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10673",
    WardLookupId: "1369",
    VDNumber: "43951260",
    RegisteredPopulation: "1116",
    VotingStationName: "ZENZELENI PRE-SCHOOL",
    Address: "J 15 ROAD  IMBALI UNIT J  PIETERMARITZBURG/MSUNDUZI",
    Latitude: "-29.6594",
    Longitude: "30.3346",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10674",
    WardLookupId: "1369",
    VDNumber: "43951271",
    RegisteredPopulation: "1021",
    VotingStationName: "VULAMEHLO COMMUNITY CRECHE",
    Address: "1964 BISLEY ROAD   KWAPATA  PIETERMARITZBURG",
    Latitude: "-29.6654",
    Longitude: "30.3191",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10675",
    WardLookupId: "1370",
    VDNumber: "43950595",
    RegisteredPopulation: "2621",
    VotingStationName: "SIYANDA CRECHE",
    Address: "UNIT BB MAIN ROAD  UNIT BB - IMBALI  PIETERMARITZBURG",
    Latitude: "-29.6843",
    Longitude: "30.3382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10676",
    WardLookupId: "1370",
    VDNumber: "43950607",
    RegisteredPopulation: "2706",
    VotingStationName: "UNIT N COMMUNITY HALL",
    Address: "PHEZULU ROAD  UNIT 13  ",
    Latitude: "-29.6751",
    Longitude: "30.3463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10677",
    WardLookupId: "1370",
    VDNumber: "43950685",
    RegisteredPopulation: "1992",
    VotingStationName: "PHILANI PRIMARY SCHOOL",
    Address: "477 SAMELA DR  IMBALI UNIT 13  PIETERMARITZBURG",
    Latitude: "-29.6703",
    Longitude: "30.352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10678",
    WardLookupId: "1370",
    VDNumber: "43951114",
    RegisteredPopulation: "1611",
    VotingStationName: "IKUSASELIHLE HIGH SCHOOL",
    Address: "UNIT AA MAIN ROAD, IMBALI   IMBALI UNIT AA  MSUNDUZI",
    Latitude: "-29.695",
    Longitude: "30.3409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10679",
    WardLookupId: "1370",
    VDNumber: "43951282",
    RegisteredPopulation: "1420",
    VotingStationName: "IZWI LESIZWE SCHOOL",
    Address: "0 UNIT AA MAIN ROAD IMBALI  IMBALI  PIETERMARITZBURG",
    Latitude: "-29.689",
    Longitude: "30.3381",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10680",
    WardLookupId: "1371",
    VDNumber: "43950977",
    RegisteredPopulation: "2977",
    VotingStationName: "MPUMELELO PRIMARY SCHOOL",
    Address:
      "OFF RICHMOND MAIN ROAD  MPUMELELO AREA NEXT TO FRANCE  PIETERMARITZBURG/MSUNDUZI",
    Latitude: "-29.68",
    Longitude: "30.3757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10681",
    WardLookupId: "1371",
    VDNumber: "43951013",
    RegisteredPopulation: "3441",
    VotingStationName: "UNIT 14 COMMUNITY HALL",
    Address: "MAIN ROAD   UNIT 14 IMBALI   PIETERMARITZBURG",
    Latitude: "-29.6783",
    Longitude: "30.3554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10682",
    WardLookupId: "1371",
    VDNumber: "43951125",
    RegisteredPopulation: "1395",
    VotingStationName: "EZIZAMELE PRE-SCHOOL",
    Address: "UNIT 14  IMBALI UNIT 14  PIETERMARITZBURG",
    Latitude: "-29.6828",
    Longitude: "30.3605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10683",
    WardLookupId: "1371",
    VDNumber: "43951204",
    RegisteredPopulation: "1832",
    VotingStationName: "ONE LIFE CHURCH - FRANCE",
    Address: "MAIN ROAD   MPUMELELO   PIETERMARITZBURG",
    Latitude: "-29.6872",
    Longitude: "30.3704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10684",
    WardLookupId: "1371",
    VDNumber: "43951439",
    RegisteredPopulation: "592",
    VotingStationName: "THORNVILLE CHURCH",
    Address: "R56 ROAD   THORNVILLE   PIETERMARITZBURG",
    Latitude: "-29.7188",
    Longitude: "30.3715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10685",
    WardLookupId: "1372",
    VDNumber: "43950506",
    RegisteredPopulation: "1036",
    VotingStationName: "SIZAMOKUHLE CRECHE",
    Address:
      "KHALIMELE RAOD   IMBALI STAGE 2, BEHIND SUPERMARKET  PIETERMARITZBURG",
    Latitude: "-29.6567",
    Longitude: "30.3567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10686",
    WardLookupId: "1372",
    VDNumber: "43950517",
    RegisteredPopulation: "1089",
    VotingStationName: "IMBALI LODGE (TEHUIS)",
    Address: "FJ SITHOLE RD  UNITS 3 J&CC  PIETERMARITZBURG",
    Latitude: "-29.653",
    Longitude: "30.3466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10687",
    WardLookupId: "1372",
    VDNumber: "43950539",
    RegisteredPopulation: "1846",
    VotingStationName: "MFUNDWENHLE PRIMARY SCHOOL",
    Address: "MNQAYI ROAD  IMBALI  PIETERMARITZBURG",
    Latitude: "-29.6553",
    Longitude: "30.3485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10688",
    WardLookupId: "1372",
    VDNumber: "43950696",
    RegisteredPopulation: "1800",
    VotingStationName: "NDABENHLE PRIMARY SCHOOL",
    Address: "SAKABULI ROAD  UNIT 2 - IMBALI TOWNSHIP  PIETERMARITZBURG",
    Latitude: "-29.6602",
    Longitude: "30.3567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10689",
    WardLookupId: "1372",
    VDNumber: "43950843",
    RegisteredPopulation: "1763",
    VotingStationName: "FUNULWAZI SCHOOL",
    Address: "MTHOMBOTHI ROAD  IMBALI  PIETERMARITZBURG",
    Latitude: "-29.6519",
    Longitude: "30.3569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10690",
    WardLookupId: "1372",
    VDNumber: "43950854",
    RegisteredPopulation: "1157",
    VotingStationName: "IMBALI HALL STAGE 1",
    Address:
      "MTHOMBOTHI ROAD   IMBALI STAGE 1, NEXT TO SHOPPING CENTRE  PIETERMARITZBURG/MSUNDUZI",
    Latitude: "-29.6532",
    Longitude: "30.35",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10691",
    WardLookupId: "1372",
    VDNumber: "43951417",
    RegisteredPopulation: "637",
    VotingStationName: "OLD HOSTEL YOUTH CENTRE",
    Address: "MBABAZANE ROAD   IMBALI STAGE 2  PIETERMARITZBURG",
    Latitude: "-29.6515",
    Longitude: "30.3598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10692",
    WardLookupId: "1373",
    VDNumber: "43950652",
    RegisteredPopulation: "2328",
    VotingStationName: "MIRIAM ZULU PRE-PRIMARY SCHOOL",
    Address: "WATERWORKS ROAD  SMEROE  EDENDALE",
    Latitude: "-29.6427",
    Longitude: "30.2846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10693",
    WardLookupId: "1373",
    VDNumber: "43950719",
    RegisteredPopulation: "1312",
    VotingStationName: "CALUZA SPORTS FIELDS",
    Address:
      "0 CALUZA MAIN ROAD   CALUZA - ACCROSS CALUZA CLINIC    PIETERMARITZBURG/MSUNDUZI",
    Latitude: "-29.6448",
    Longitude: "30.3095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10694",
    WardLookupId: "1373",
    VDNumber: "43950720",
    RegisteredPopulation: "2634",
    VotingStationName: "CALUZA PRIMARY SCHOOL",
    Address: "CALUZA ROAD  CALUZA  PIETERMARITZBURG",
    Latitude: "-29.6378",
    Longitude: "30.3055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10695",
    WardLookupId: "1373",
    VDNumber: "43950731",
    RegisteredPopulation: "1577",
    VotingStationName: "MNTUNGWA SHOP",
    Address: "MAIN ROAD  HAREWOOD - EDENDALE  PIETERMARITZBURG",
    Latitude: "-29.6406",
    Longitude: "30.3143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10696",
    WardLookupId: "1374",
    VDNumber: "43950797",
    RegisteredPopulation: "2116",
    VotingStationName: "SANZWILE PRIMARY SCHOOL",
    Address: "DAMBUZA ROAD  DAMBUZA  EDENDALE",
    Latitude: "-29.6546",
    Longitude: "30.3089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10697",
    WardLookupId: "1374",
    VDNumber: "43950809",
    RegisteredPopulation: "2326",
    VotingStationName: "DUMBUZA HALL",
    Address:
      "OFF DAMBUZA MAIN ROAD  DAMBUZA NEXT TO AME CHURCH  PIETERMARITZBURG",
    Latitude: "-29.654",
    Longitude: "30.315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10698",
    WardLookupId: "1374",
    VDNumber: "43950810",
    RegisteredPopulation: "1133",
    VotingStationName: "MTHUTHUZELI CRECHE",
    Address: "OFF EDENDALE ROAD  MACHIBISA - EDENDALE  PIETERMARITZBURG",
    Latitude: "-29.6481",
    Longitude: "30.3202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10699",
    WardLookupId: "1374",
    VDNumber: "43951057",
    RegisteredPopulation: "860",
    VotingStationName: "DAMBUZA COMMUNITY DEVELOPMENT TRUST (DAMCOM)",
    Address: "DAMBUZA MAIN ROAD   DAMBUZA  PIETERMARITZBURG",
    Latitude: "-29.6559",
    Longitude: "30.316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10700",
    WardLookupId: "1374",
    VDNumber: "43951136",
    RegisteredPopulation: "1553",
    VotingStationName: "GUGULETHU SUPERMARKET",
    Address: "DAMBUZA ROAD  DAMBUZA  PIETERMARITZBURG",
    Latitude: "-29.6562",
    Longitude: "30.3211",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10701",
    WardLookupId: "1375",
    VDNumber: "43950528",
    RegisteredPopulation: "2198",
    VotingStationName: "SINAMUVA PRIMARY SCHOOL",
    Address: "1970 NQAYI RD  UNITS 3 - IMBALI  PIETERMARITZBURG/MSUNDUZI",
    Latitude: "-29.6577",
    Longitude: "30.3409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10702",
    WardLookupId: "1375",
    VDNumber: "43950764",
    RegisteredPopulation: "1731",
    VotingStationName: "PLESSISLAER COMMUNITY HALL",
    Address: "OLD EDENDALE ROAD  EDENDALE  EDENDALE",
    Latitude: "-29.645",
    Longitude: "30.3312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10703",
    WardLookupId: "1375",
    VDNumber: "43950832",
    RegisteredPopulation: "2295",
    VotingStationName: "MTHETHOMUSHA PRIMARY SCHOOL",
    Address: "0 HANTSA ROAD  MACHIBISA - EDENDALE  PIETERMARITZBURG",
    Latitude: "-29.6516",
    Longitude: "30.3314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10704",
    WardLookupId: "1375",
    VDNumber: "43951068",
    RegisteredPopulation: "1143",
    VotingStationName: "HENRYVILLE PRIMARY SCHOOL",
    Address: "MACHIBISA MAIN ROAD  MACHIBISA - EDENDALE  PIETERMARITZBURG",
    Latitude: "-29.6555",
    Longitude: "30.3247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10705",
    WardLookupId: "1375",
    VDNumber: "43951293",
    RegisteredPopulation: "1453",
    VotingStationName: "UNITING PRESBYTERIAN CHURCH",
    Address: "0 MNGOMEZULU  UNIT 3  PIETERMARITZBURG",
    Latitude: "-29.6513",
    Longitude: "30.3377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10706",
    WardLookupId: "1376",
    VDNumber: "43950494",
    RegisteredPopulation: "1173",
    VotingStationName: "ONE LIFE CHURCH IMBALI",
    Address: "SUTHERLAND ROAD   IMBALI   PIETERMARITZBURG",
    Latitude: "-29.6458",
    Longitude: "30.3566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10707",
    WardLookupId: "1376",
    VDNumber: "43950618",
    RegisteredPopulation: "5214",
    VotingStationName: "ASHDOWN PRIMARY SCHOOL",
    Address: "VILAKAZI ROAD  ASHDOWN  EDENDALE",
    Latitude: "-29.6373",
    Longitude: "30.3296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10708",
    WardLookupId: "1376",
    VDNumber: "43950630",
    RegisteredPopulation: "2035",
    VotingStationName: "SUTHERLANDS CRECHE",
    Address: "HERCHISON ROAD   PEACEVALLEY 2   PIETERMARITZBURG",
    Latitude: "-29.6398",
    Longitude: "30.3465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10709",
    WardLookupId: "1377",
    VDNumber: "43950461",
    RegisteredPopulation: "2291",
    VotingStationName: "NATAL RALLY CLUB",
    Address: "1 ORIBI ROAD  ORIBI  PIETERMARITZBURG",
    Latitude: "-29.6384",
    Longitude: "30.3944",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10710",
    WardLookupId: "1377",
    VDNumber: "43950472",
    RegisteredPopulation: "3357",
    VotingStationName: "THE GRANGE PRIMARY SCHOOL",
    Address: "WHITE ROAD  GRANGE  PIETERMARITZBURG",
    Latitude: "-29.6492",
    Longitude: "30.3755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10711",
    WardLookupId: "1377",
    VDNumber: "43950483",
    RegisteredPopulation: "1871",
    VotingStationName: "ONE LIFE CHURCH BISLEY",
    Address: "462 ALEXANDRA EXT RD  BISLEY  PIETERMARITZBURG",
    Latitude: "-29.6466",
    Longitude: "30.3835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10712",
    WardLookupId: "1377",
    VDNumber: "43951305",
    RegisteredPopulation: "651",
    VotingStationName: "CALVARY FELLOWSHIP",
    Address: "108 TRELAWNEY ROAD   FAIRMEADE  PIETERMARITZBURG",
    Latitude: "-29.6362",
    Longitude: "30.3756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10713",
    WardLookupId: "1377",
    VDNumber: "43951316",
    RegisteredPopulation: "1167",
    VotingStationName: "BISLEY HEIGHTS TEMPORAL VOTING STATION (TENT)",
    Address: "CNR ORIBI & STUBBS ROAD   BISLEY HEIGHTS  PIETERMARITZBURG",
    Latitude: "-29.6482",
    Longitude: "30.3924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10714",
    WardLookupId: "1378",
    VDNumber: "43950281",
    RegisteredPopulation: "2573",
    VotingStationName: "FULL GOSPEL CHURCH",
    Address: "40 CHASEDENE ROAD  CHASE VALLEY  PIETERMARITZBURG",
    Latitude: "-29.5759",
    Longitude: "30.3736",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10715",
    WardLookupId: "1378",
    VDNumber: "43950292",
    RegisteredPopulation: "3541",
    VotingStationName: "ALL SAINTS UNITED CHURCH",
    Address: "73 MONTGOMERY DRIVE  MONTROSE/NORTHERN PARK  PIETERMARITZBURG",
    Latitude: "-29.5783",
    Longitude: "30.3608",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10716",
    WardLookupId: "1378",
    VDNumber: "43950304",
    RegisteredPopulation: "1891",
    VotingStationName: "CARTER HIGH SCHOOL",
    Address: "22 CARTER DRIVE  WEMBLY  PIETERMARITZBURG",
    Latitude: "-29.5835",
    Longitude: "30.3602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10717",
    WardLookupId: "1378",
    VDNumber: "43951440",
    RegisteredPopulation: "177",
    VotingStationName: "HOUSE OF PRAYER",
    Address: "OTTO`S BLUFF  WOODLANDS EXTENSION   PIETERMARITZBURG",
    Latitude: "-29.5707",
    Longitude: "30.3829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10718",
    WardLookupId: "1379",
    VDNumber: "43950315",
    RegisteredPopulation: "2334",
    VotingStationName: "CLARENDON PRIMARY SCHOOL",
    Address: "ROBERTS ROAD  CLARENDON  PIETERMARITZBURG",
    Latitude: "-29.6007",
    Longitude: "30.3579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10719",
    WardLookupId: "1379",
    VDNumber: "43950326",
    RegisteredPopulation: "2597",
    VotingStationName: "PRESTBURY PRIMARY SCHOOL",
    Address: "STOTT ROAD  PRESTBURY  PIETERMARITZBURG",
    Latitude: "-29.6115",
    Longitude: "30.3358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10720",
    WardLookupId: "1379",
    VDNumber: "43950337",
    RegisteredPopulation: "1446",
    VotingStationName: "PIET RETIEF PRIMARY SCHOOL",
    Address: "HORTENSIA ROAD  NAPIERVILLE  PIETERMARITZBURG",
    Latitude: "-29.6172",
    Longitude: "30.3573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10721",
    WardLookupId: "1379",
    VDNumber: "43951170",
    RegisteredPopulation: "413",
    VotingStationName: "PEACEVALLEY 3 OPEN SPACE (TENT)",
    Address: "OFF SHAYAMOYA ROAD  PEACEVALLEY 3 NEXT TO MPUMUZA SHAYAMOYA AREA",
    Latitude: "-29.6275",
    Longitude: "30.3406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10722",
    WardLookupId: "1379",
    VDNumber: "43951181",
    RegisteredPopulation: "1271",
    VotingStationName: "LINPARK HIGH SCHOOL",
    Address:
      "2 CLAUDE FORSYTH ROAD  BLACKRIDGE  KZN225 - MSUNDUZI [PIETERMARITZBURG]",
    Latitude: "-29.6015",
    Longitude: "30.3158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10723",
    WardLookupId: "1379",
    VDNumber: "43951327",
    RegisteredPopulation: "1022",
    VotingStationName: "CHURCH IN PIETERMARITZBURG",
    Address: "MORCOM ROAD   PRESTBURY  PIETERMARITZBURG",
    Latitude: "-29.6083",
    Longitude: "30.3451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10724",
    WardLookupId: "1380",
    VDNumber: "43950360",
    RegisteredPopulation: "2185",
    VotingStationName: "DEPARTMENT OF EDUCATION OFFICES - BOOM STREET",
    Address: "BOOM STREET   PIETERMARITZBURG  PIETERMARITZBURG",
    Latitude: "-29.6027",
    Longitude: "30.3706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10725",
    WardLookupId: "1380",
    VDNumber: "43950393",
    RegisteredPopulation: "1808",
    VotingStationName: "JAN RICHTER CENTRE",
    Address: "40 NEW SCOTLAND ROAD  PELHAM  PIETERMARITZBURG",
    Latitude: "-29.624",
    Longitude: "30.3814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10726",
    WardLookupId: "1380",
    VDNumber: "43950708",
    RegisteredPopulation: "1744",
    VotingStationName: "DEPARTMENT OF TRANSPORT LICENSING SERVICES",
    Address: "230 PRINCE ALFRED  PIETERMARITZBURG CENTRAL  PIETERMARITZBURG",
    Latitude: "-29.6084",
    Longitude: "30.3842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10727",
    WardLookupId: "1380",
    VDNumber: "43951338",
    RegisteredPopulation: "1284",
    VotingStationName: "F E T COLLEGE MIDLANDS CAMPUS",
    Address: "0 PINE STREET  PIETERMARITZBURG  PIETERMARITZBURG",
    Latitude: "-29.6073",
    Longitude: "30.3662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10728",
    WardLookupId: "1381",
    VDNumber: "43950056",
    RegisteredPopulation: "2496",
    VotingStationName: "KHARINA SECONDARY SCHOOL",
    Address: "390 REGINA RD  BOMBAY HEIGHTS  PIETERMARITZBURG",
    Latitude: "-29.5415",
    Longitude: "30.397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10729",
    WardLookupId: "1381",
    VDNumber: "43950067",
    RegisteredPopulation: "1316",
    VotingStationName: "SUNCREST PRIMARY SCHOOL",
    Address: "30  TAURUS RD  BOMBAY HEIGHTS  PIETERMARITZBURG",
    Latitude: "-29.5494",
    Longitude: "30.3931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10730",
    WardLookupId: "1381",
    VDNumber: "43950078",
    RegisteredPopulation: "1202",
    VotingStationName: "SILVER HEIGHTS SECONDARY SCHOOL",
    Address: "110 SILVER ROAD  NORTHDALE  PIETERMARITZBURG",
    Latitude: "-29.5527",
    Longitude: "30.4002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10731",
    WardLookupId: "1381",
    VDNumber: "43950089",
    RegisteredPopulation: "1744",
    VotingStationName: "RIDGE VIEW PRIMARY SCHOOL",
    Address: "20 BERTHA RD  NORTHDALE  PIETERMARITZBURG",
    Latitude: "-29.5569",
    Longitude: "30.3858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10732",
    WardLookupId: "1381",
    VDNumber: "43950090",
    RegisteredPopulation: "2411",
    VotingStationName: "HEATHER SECONDARY SCHOOL",
    Address: "27 HEATHER CRESCENT  NEW HOLMES  PIETERMARITZBURG",
    Latitude: "-29.554",
    Longitude: "30.3913",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10733",
    WardLookupId: "1382",
    VDNumber: "43950012",
    RegisteredPopulation: "2397",
    VotingStationName: "COPESVILLE SECONDARY SCHOOL",
    Address: "ALBASTER ROAD  COPESVILLE  PIETERMARITZBURG",
    Latitude: "-29.5535",
    Longitude: "30.4275",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10734",
    WardLookupId: "1382",
    VDNumber: "43950023",
    RegisteredPopulation: "2513",
    VotingStationName: "COPESVILLE PRIMARY SCHOOL",
    Address: "1 WARTBURG ROAD  HANIVILLE  PIETERMARITZBURG",
    Latitude: "-29.5544",
    Longitude: "30.4443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10735",
    WardLookupId: "1382",
    VDNumber: "43951080",
    RegisteredPopulation: "2001",
    VotingStationName: "COPESVILLE SPORTS GROUND HALL",
    Address: "LAZARUS ROAD  COPESVILLE  PIETERMARITZBURG",
    Latitude: "-29.5546",
    Longitude: "30.4373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10736",
    WardLookupId: "1382",
    VDNumber: "43951350",
    RegisteredPopulation: "1671",
    VotingStationName: "HANNIVILLE COMMUNITY HALL",
    Address: "HANNIVILLE MAIN ROAD  HANNIVILLE  PIETERMARITZBURG",
    Latitude: "-29.5494",
    Longitude: "30.4373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10737",
    WardLookupId: "1382",
    VDNumber: "43951361",
    RegisteredPopulation: "1379",
    VotingStationName: "SWAPO B OPEN FIELD (TENT)",
    Address:
      "COPESVILLE MAIN ROAD  SWAPO  - OPPOSITE OLD SATELITE POLISE STATION  PIETERMARITZBURG/MSUNDUZI",
    Latitude: "-29.5504",
    Longitude: "30.4258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10738",
    WardLookupId: "1383",
    VDNumber: "43950034",
    RegisteredPopulation: "1189",
    VotingStationName: "ALLANDALE STEAK DEN",
    Address: "67 MANUEL ROAD  ALLANDALE  PIETERMARITZBURG",
    Latitude: "-29.5631",
    Longitude: "30.415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10739",
    WardLookupId: "1383",
    VDNumber: "43950045",
    RegisteredPopulation: "2158",
    VotingStationName: "SPRINGHAVEN PRIMARY SCHOOL",
    Address: "167 SPRING VALE ROAD  BOMBAY HEIGHTS  PIETERMARITZBURG",
    Latitude: "-29.544",
    Longitude: "30.4104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10740",
    WardLookupId: "1383",
    VDNumber: "43950102",
    RegisteredPopulation: "2438",
    VotingStationName: "ORIENT HEIGHTS PRIMARY SCHOOL",
    Address: "55 BAYAT ROAD  ORIENT HEIGHTS  PIETERMARITZBURG",
    Latitude: "-29.56",
    Longitude: "30.4159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10741",
    WardLookupId: "1383",
    VDNumber: "43950113",
    RegisteredPopulation: "2382",
    VotingStationName: "ALLANDALE PRIMARY SCHOOL",
    Address: "64 KHAN ROAD  ALLANDALE  PIETERMARITZBURG",
    Latitude: "-29.5659",
    Longitude: "30.4109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10742",
    WardLookupId: "1383",
    VDNumber: "43951192",
    RegisteredPopulation: "2624",
    VotingStationName: "UMSILINGA PRIMARY SCHOOL",
    Address: "OFF BHAMBATHA ROAD  UMSILINGA - COPESVILLE  PIETERMARITZBURG",
    Latitude: "-29.5389",
    Longitude: "30.4223",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10743",
    WardLookupId: "1384",
    VDNumber: "43950124",
    RegisteredPopulation: "2092",
    VotingStationName: "NORTHDALE TECH",
    Address: "BALHAMBRA WAY   NORTHDALE  PIETERMARITZBURG",
    Latitude: "-29.5621",
    Longitude: "30.4016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10744",
    WardLookupId: "1384",
    VDNumber: "43950135",
    RegisteredPopulation: "1477",
    VotingStationName: "ROSEFERN PRIMARY SCHOOL",
    Address: "40 MUNIREDY ROAD  NORTHDALE  PIETERMARITZBURG",
    Latitude: "-29.5607",
    Longitude: "30.3897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10745",
    WardLookupId: "1384",
    VDNumber: "43950146",
    RegisteredPopulation: "2977",
    VotingStationName: "BOMBAY CIVIC CENTRE",
    Address: "C/O BOMBAY AND BANGALORE ROADS  NORTHDALE  PIETERMARITZBURG",
    Latitude: "-29.5679",
    Longitude: "30.4016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10746",
    WardLookupId: "1384",
    VDNumber: "43950157",
    RegisteredPopulation: "1228",
    VotingStationName: "NORTHLANDS PRIMARY SCHOOL",
    Address: "2  GAYSINGH ROAD  NORTHDALE  PIETERMARITZBURG",
    Latitude: "-29.5659",
    Longitude: "30.3907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10747",
    WardLookupId: "1384",
    VDNumber: "43951349",
    RegisteredPopulation: "1078",
    VotingStationName: "CHRISTIAN CENTRE",
    Address: "30 OLYMPIAWAY RAOD  NORTHDALE  PIETERMARITZBURG",
    Latitude: "-29.5584",
    Longitude: "30.3996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10748",
    WardLookupId: "1385",
    VDNumber: "43950168",
    RegisteredPopulation: "1670",
    VotingStationName: "EPS SECONDARY SCHOOL",
    Address: "211 BOMBAY ROAD  NORTHDALE  PIETERMARITZBURG",
    Latitude: "-29.5691",
    Longitude: "30.3895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10749",
    WardLookupId: "1385",
    VDNumber: "43950179",
    RegisteredPopulation: "2815",
    VotingStationName: "WOODLANDS COMMUNITY HALL",
    Address: "COMMUNITY ROAD  WOODLANDS  PIETERMARITZBURG",
    Latitude: "-29.5802",
    Longitude: "30.3888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10750",
    WardLookupId: "1385",
    VDNumber: "43950270",
    RegisteredPopulation: "1554",
    VotingStationName: "BERG STREET PRIMARY SCHOOL",
    Address: "509 BERG STREET  CITY  PIETERMARTIZBURG",
    Latitude: "-29.5913",
    Longitude: "30.3854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10751",
    WardLookupId: "1385",
    VDNumber: "43950359",
    RegisteredPopulation: "2820",
    VotingStationName: "AMY BIEL CENTRE",
    Address: "PEACH RD  WOODLANDS  MSUNDUZI [PIETERMARITZBURG]",
    Latitude: "-29.5737",
    Longitude: "30.391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10752",
    WardLookupId: "1385",
    VDNumber: "43951372",
    RegisteredPopulation: "757",
    VotingStationName: "RAMATHA ROAD PRIMARY SCHOOL",
    Address: "RAMATHA ROAD  NORTHDALE  PIETERMARITZBURG",
    Latitude: "-29.5704",
    Longitude: "30.3958",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10753",
    WardLookupId: "1386",
    VDNumber: "43950348",
    RegisteredPopulation: "3672",
    VotingStationName: "SHRI VISHNU PRIMARY SCHOOL",
    Address: "558 LONGMARKET STREET  CITY  PIETERMARITZBURG",
    Latitude: "-29.5934",
    Longitude: "30.3917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10754",
    WardLookupId: "1386",
    VDNumber: "43950371",
    RegisteredPopulation: "1733",
    VotingStationName: "RUBY ALLISON HOMES",
    Address: "488 BURGER STREET  PIETERMARITZBURG CENTRAL  PIETERMARITZBURG",
    Latitude: "-29.5974",
    Longitude: "30.3921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10755",
    WardLookupId: "1386",
    VDNumber: "43950427",
    RegisteredPopulation: "3292",
    VotingStationName: "SCOTTSVILLE PRIMARY SCHOOL",
    Address: "1 KING EDWARD AVE  SCOTTSVILLE  PIETERMARITZBURG",
    Latitude: "-29.6151",
    Longitude: "30.4",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10756",
    WardLookupId: "1386",
    VDNumber: "43951383",
    RegisteredPopulation: "681",
    VotingStationName: "DUT RIVERSIDE",
    Address: "19 ABERFLDY  SCOTTSVILLE  PIETERMARITZBURG",
    Latitude: "-29.606",
    Longitude: "30.3959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10757",
    WardLookupId: "1387",
    VDNumber: "43950180",
    RegisteredPopulation: "2082",
    VotingStationName: "EASTWOOD COMMUNITY HALL",
    Address: "C/O STARLING AND WEAVER ROADS  EASTWOOD  PIETERMARITZBURG",
    Latitude: "-29.5864",
    Longitude: "30.4251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10758",
    WardLookupId: "1387",
    VDNumber: "43950191",
    RegisteredPopulation: "2021",
    VotingStationName: "PANORAMA PRIMARY SCHOOL",
    Address: "1 NAVAN BOULEVARD  PANORAMA GARDENS  PIETERMARITZBURG",
    Latitude: "-29.5741",
    Longitude: "30.4237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10759",
    WardLookupId: "1387",
    VDNumber: "43950203",
    RegisteredPopulation: "1426",
    VotingStationName: "EASTWOOD PRIMARY SCHOOL",
    Address: "25 KINGFISHER ROAD  EASTWOOD  PIETERMARITZBURG",
    Latitude: "-29.5838",
    Longitude: "30.4248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10760",
    WardLookupId: "1387",
    VDNumber: "43951394",
    RegisteredPopulation: "1852",
    VotingStationName: "CINDERELLA DROP - IN CENTRE",
    Address: "STALLION  EASTWOOD  PIETERMARITZBURG",
    Latitude: "-29.5865",
    Longitude: "30.4174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10761",
    WardLookupId: "1388",
    VDNumber: "43950247",
    RegisteredPopulation: "3310",
    VotingStationName: "SOBANTU HALL",
    Address: "SIKHOSANA ROAD  SOBANTU  PIETERMARITZBURG",
    Latitude: "-29.5974",
    Longitude: "30.4201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10762",
    WardLookupId: "1388",
    VDNumber: "43950258",
    RegisteredPopulation: "1582",
    VotingStationName: "SOBANTU HIGH SCHOOL",
    Address: "SIKHOSANA ROAD  SOBANTU  PIETERMARITZBURG",
    Latitude: "-29.5955",
    Longitude: "30.4237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10763",
    WardLookupId: "1388",
    VDNumber: "43950269",
    RegisteredPopulation: "1937",
    VotingStationName: "ARTHUR BLAXALL SCHOOL",
    Address: "87 ROYSTON ROAD  MOUNTAIN RISE  PIETERMARITZBURG",
    Latitude: "-29.5858",
    Longitude: "30.4049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10764",
    WardLookupId: "1388",
    VDNumber: "43950438",
    RegisteredPopulation: "2696",
    VotingStationName: "LINCOLN MEADE OPEN SPACE (TENT)",
    Address:
      "GRIMTHORPE ROAD  LINCOLN MEADE NEXT TO LINCOLN STORE  PIETERMARITZBURG",
    Latitude: "-29.6193",
    Longitude: "30.4377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10765",
    WardLookupId: "1389",
    VDNumber: "43950405",
    RegisteredPopulation: "2147",
    VotingStationName: "WINSTON CHURCHILL THEATRE",
    Address: "1 LENSTER ROAD  SCOTTSVILLE  PIETERMARITZBURG",
    Latitude: "-29.6147",
    Longitude: "30.3918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10766",
    WardLookupId: "1389",
    VDNumber: "43950416",
    RegisteredPopulation: "3143",
    VotingStationName: "PELHAM PRIMARY SCHOOL",
    Address: "BROMHEAD ROAD   PELHAM   PIETERMARITZBURG",
    Latitude: "-29.6337",
    Longitude: "30.3929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10767",
    WardLookupId: "1389",
    VDNumber: "43950966",
    RegisteredPopulation: "1817",
    VotingStationName: "CLELAND COMMUNITY HALL",
    Address: "20 CNR PETREA AVENUE AND ABELIA R  CLELAND  PIETERMARITZBURG",
    Latitude: "-29.6468",
    Longitude: "30.421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10768",
    WardLookupId: "1389",
    VDNumber: "43951406",
    RegisteredPopulation: "748",
    VotingStationName: "CEBULA HOUSE FORMATION",
    Address: "0 IVY ROAD  BELLEVUE  PIETERMARITZBURG",
    Latitude: "-29.6428",
    Longitude: "30.428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10769",
    WardLookupId: "1390",
    VDNumber: "43780013",
    RegisteredPopulation: "1988",
    VotingStationName: "ASHBURTON HALL",
    Address: "1 WALLY HAYWARD DR  ASHBURTON  PIETERMARITZBURG",
    Latitude: "-29.6681",
    Longitude: "30.4477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10770",
    WardLookupId: "1390",
    VDNumber: "43780024",
    RegisteredPopulation: "1392",
    VotingStationName: "SAKHA SPORTS FIELD (TENT)",
    Address: "GRAVEL ROAD   MKHONDENI  PIETERMARITZBURG",
    Latitude: "-29.6689",
    Longitude: "30.4177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10771",
    WardLookupId: "1390",
    VDNumber: "43950449",
    RegisteredPopulation: "1412",
    VotingStationName: "DUTCH REFORMED CHURCH HAYFIELDS",
    Address: "15 DRYDEN ROAD  HAYFIELDS  PIETERMARITZBURG",
    Latitude: "-29.6306",
    Longitude: "30.4202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10772",
    WardLookupId: "1390",
    VDNumber: "43950450",
    RegisteredPopulation: "3141",
    VotingStationName: "WESLEY METHODIST CHURCH",
    Address: "69 CLELAND ROAD  HAYFIELDS  PIETERMARITZBURG",
    Latitude: "-29.6303",
    Longitude: "30.413",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10773",
    WardLookupId: "1391",
    VDNumber: "43773701",
    RegisteredPopulation: "483",
    VotingStationName: "BISHOPSTOWE COMMUNUTY HALL",
    Address: "0 HARIET COLENSO ROAD  BISHOPSTOWE  PIETERMARITZBURG",
    Latitude: "-29.6003",
    Longitude: "30.4614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10774",
    WardLookupId: "1391",
    VDNumber: "43780035",
    RegisteredPopulation: "111",
    VotingStationName: "ASHBURTON TRAINING CENTRE (CLUB HOUSE)",
    Address: "POPE ELLIS  ASHBURTON   PIETERMARITZBURG",
    Latitude: "-29.6416",
    Longitude: "30.4681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10775",
    WardLookupId: "1391",
    VDNumber: "43950214",
    RegisteredPopulation: "1307",
    VotingStationName: "PENTECOSTAL GOSPEL MISSION",
    Address: "1 CALVARY ROAD  GLENWOOD  PIETERMARITZBURG",
    Latitude: "-29.5898",
    Longitude: "30.4361",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10776",
    WardLookupId: "1391",
    VDNumber: "43950225",
    RegisteredPopulation: "2868",
    VotingStationName: "MCCARTHY PRIMARY SCHOOL",
    Address: "0 G605  THAMBOVILLE  PIETERMARITZBURG/MSUNDUZI",
    Latitude: "-29.5868",
    Longitude: "30.4357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10777",
    WardLookupId: "1391",
    VDNumber: "43950236",
    RegisteredPopulation: "2885",
    VotingStationName: "THEMBALIHLE MASAKHANE CRECHE",
    Address: "THEMBALIHLE MAIN ROAD   THEMBALIHLE   PIETERMARITZBURG",
    Latitude: "-29.583",
    Longitude: "30.4339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10778",
    WardLookupId: "1392",
    VDNumber: "43771125",
    RegisteredPopulation: "678",
    VotingStationName: "MBUMBANE AFRICAN CHURCH",
    Address:
      "MAFUNZE COURT ROAD   MBUMBANE  PIETERMARITZBURG/MAFUNZE TRIBAL AUTHORITY",
    Latitude: "-29.7339",
    Longitude: "30.1062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10779",
    WardLookupId: "1392",
    VDNumber: "43771136",
    RegisteredPopulation: "901",
    VotingStationName: "HEMU-HEMU PRIMARY SCHOOL",
    Address:
      "OFF GIBSON GATE ROAD   MAFUNZE  PITERMARITZBURG/MAFUNZE TRIBAL AUTHORITY",
    Latitude: "-29.7306",
    Longitude: "30.1123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10780",
    WardLookupId: "1392",
    VDNumber: "43771147",
    RegisteredPopulation: "392",
    VotingStationName: "KWAMBHELE CHURCH",
    Address: "MKHESHEKWENI  MAFUNZE TRADITIONAL COUNCIL",
    Latitude: "-29.7292",
    Longitude: "30.1036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10781",
    WardLookupId: "1392",
    VDNumber: "43771383",
    RegisteredPopulation: "1824",
    VotingStationName: "SONGOZIMA  PRIMARY SCHOOL",
    Address:
      "SONGONZIMA ROAD  SONGONZIMA  PIETERMARITZBURG/MAFUNZE TRIBAL AUTHORITY",
    Latitude: "-29.7088",
    Longitude: "30.1128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10782",
    WardLookupId: "1392",
    VDNumber: "43771394",
    RegisteredPopulation: "1521",
    VotingStationName: "EKHOKWANE  PRIMARY SCHOOL",
    Address:
      "OFF BULWER ROAD  EKHOKHWANE-ELANDSKOP  PIETERMARITZBURG/MPUMUZA TRIBAL AUTHORITY",
    Latitude: "-29.7039",
    Longitude: "30.096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10783",
    WardLookupId: "1392",
    VDNumber: "43772148",
    RegisteredPopulation: "413",
    VotingStationName: "MDUTSHINI  PRIMARY SCHOOL",
    Address:
      "MDUTSHINI GRAVEL ROAD, NCWADI  CROSS THE RIVER OFF TO RIGH TURN, NCWADI   AMAQADI",
    Latitude: "-29.7957",
    Longitude: "29.9803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10784",
    WardLookupId: "1392",
    VDNumber: "43772159",
    RegisteredPopulation: "775",
    VotingStationName: "NTABENDE HIGH SCHOOL",
    Address:
      "NTABENDE RAOD, NCWADI   BEFORE AMAQADI COURT, NCWADI   AMAQADI TRADITIONAL ADMINISTRATION",
    Latitude: "-29.779",
    Longitude: "29.9848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10785",
    WardLookupId: "1392",
    VDNumber: "43772160",
    RegisteredPopulation: "550",
    VotingStationName: "GUDLINTABA  PRIMARY SCHOOL",
    Address:
      "NTABENDE ROAD, GUDLINTABA  NEXT TO NCWADI TRIBAL COURT, NCWADI  ELANDSKOP",
    Latitude: "-29.7808",
    Longitude: "30.012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10786",
    WardLookupId: "1392",
    VDNumber: "43772171",
    RegisteredPopulation: "376",
    VotingStationName: "JUBANE  PRIMARY SCHOOL",
    Address:
      "NTABENDE ROAD, NCWADI   PASS TRIBAL COURT THE SCHOOL IS ON UP HILL, NCWADI  AMAQADI",
    Latitude: "-29.7401",
    Longitude: "30.003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10787",
    WardLookupId: "1393",
    VDNumber: "43772463",
    RegisteredPopulation: "1131",
    VotingStationName: "QALAKAHLE COMMUNITY HALL",
    Address: "110101 GCINA  MANYAVU  P M BURG/ KWANYAVU T/A",
    Latitude: "-29.6003",
    Longitude: "30.5888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10788",
    WardLookupId: "1393",
    VDNumber: "43772485",
    RegisteredPopulation: "1898",
    VotingStationName: "VILLA MARIA COMBINED PRIMARY SCHOOL",
    Address: "NEXT TOMAQONGQO SPORT GROUND  CAMPERDOWN  KWAMAPHUMULO",
    Latitude: "-29.5793",
    Longitude: "30.5697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10789",
    WardLookupId: "1393",
    VDNumber: "43773699",
    RegisteredPopulation: "1611",
    VotingStationName: "STINGINI COMMUNITY HALL",
    Address:
      "D2170 NEAR MUNICIPALITY CRECHE  CAMPERDOWN  MAPHUMULO TRIBAL AUTHORITY",
    Latitude: "-29.5863",
    Longitude: "30.5338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10790",
    WardLookupId: "1393",
    VDNumber: "43774690",
    RegisteredPopulation: "1419",
    VotingStationName: "MAQONGQO PRIMARY SCHOOL",
    Address: "NONZILA(D1026 MBAMBANGALO SCH  MAQONGQO LOCATION  MAPHUMULO",
    Latitude: "-29.5732",
    Longitude: "30.5625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10791",
    WardLookupId: "1394",
    VDNumber: "43771529",
    RegisteredPopulation: "647",
    VotingStationName: "MPHAYA LOWER PRIMARY SCHOOL",
    Address: "5 NAGLE DAM  OPHOKWENI  MKHAMBATHINI / AMANYAVU",
    Latitude: "-29.631",
    Longitude: "30.6574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10792",
    WardLookupId: "1394",
    VDNumber: "43772407",
    RegisteredPopulation: "496",
    VotingStationName: "INTOBEKO CRECHE",
    Address: "1 NO. 1 (NEAR NAGLE DAM)  MANZAMNYAMA  KWANYAVU T/A",
    Latitude: "-29.5945",
    Longitude: "30.6169",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10793",
    WardLookupId: "1394",
    VDNumber: "43772429",
    RegisteredPopulation: "1000",
    VotingStationName: "INHLANHLAYABEBHUZA HIGH SCHOOL",
    Address: "N0.3 NAGLE DAM ROAD( NEAR MCOSELI )  MANYAVU  AMANYAVU",
    Latitude: "-29.6122",
    Longitude: "30.6384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10794",
    WardLookupId: "1394",
    VDNumber: "43772441",
    RegisteredPopulation: "1505",
    VotingStationName: "SANSIKANE COMBINED PRIMARY SCHOOL",
    Address:
      "NO.8 NAGLE DAM ROAD(NEAR THUTHUKA S  OPHOKWENI  CAMPERDOWN ( AMANYAVU)",
    Latitude: "-29.649",
    Longitude: "30.6425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10795",
    WardLookupId: "1394",
    VDNumber: "43775512",
    RegisteredPopulation: "226",
    VotingStationName: "NONZILA PRIMARY",
    Address: "D1026 NONZILA OPP MSINSI GAME RESERV  CAMPERDOWN  MAPHUMULO T/A",
    Latitude: "-29.5774",
    Longitude: "30.5929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10796",
    WardLookupId: "1395",
    VDNumber: "43772474",
    RegisteredPopulation: "493",
    VotingStationName: "IMBOYI COMBINED PRIMARY SCHOOL",
    Address: "104 DISTRICT ROAD  IMBOYI  PM BURG",
    Latitude: "-29.6107",
    Longitude: "30.5637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10797",
    WardLookupId: "1395",
    VDNumber: "43772520",
    RegisteredPopulation: "1119",
    VotingStationName: "VANS HOTEL",
    Address: "UMLAAS ROAD  CAMPERDOWN  K Z NATAL",
    Latitude: "-29.7238",
    Longitude: "30.4996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10798",
    WardLookupId: "1395",
    VDNumber: "43774162",
    RegisteredPopulation: "402",
    VotingStationName: "LION PARK HALL",
    Address: "LION PARK  CAMPERDOWN  MAJOZI",
    Latitude: "-29.6524",
    Longitude: "30.5232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10799",
    WardLookupId: "1395",
    VDNumber: "43774689",
    RegisteredPopulation: "375",
    VotingStationName: "THORNER PRIMARY SCHOOL",
    Address:
      "C/O THONER ESTATE(NEXT VENKER STOR  MANDESTONE  MKHAMBATHINI [CAMPERDOWN]",
    Latitude: "-29.7485",
    Longitude: "30.4507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10800",
    WardLookupId: "1395",
    VDNumber: "43775028",
    RegisteredPopulation: "555",
    VotingStationName: "SETHABE PRIMARY SCHOOL",
    Address: "N/A COCORICO FARM(NEXT TO NATIONAL  MLAAS ROAD  CAMPERDOWN",
    Latitude: "-29.7352",
    Longitude: "30.4819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10801",
    WardLookupId: "1395",
    VDNumber: "43775523",
    RegisteredPopulation: "586",
    VotingStationName: "NOBHALA SECONDARY SCHOOL",
    Address: "NKANYEZINI  CAMPERDOWN  KWANYAVU",
    Latitude: "-29.6316",
    Longitude: "30.5719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10802",
    WardLookupId: "1395",
    VDNumber: "43790014",
    RegisteredPopulation: "1529",
    VotingStationName: "CAMPERDOWN PRIMARY SCHOOL",
    Address:
      "32 VICTORIA STREET  CAMPERDOWN( NEXT TO RAILWAY LINE)  CAMPERDOWN",
    Latitude: "-29.7268",
    Longitude: "30.5358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10803",
    WardLookupId: "1396",
    VDNumber: "43772553",
    RegisteredPopulation: "1152",
    VotingStationName: "FAIRLEIGH PRIMARY SCHOOL",
    Address: "ESTON CJ DUKES FARM  CAMPERDOWN  ESTON",
    Latitude: "-29.8662",
    Longitude: "30.5282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10804",
    WardLookupId: "1396",
    VDNumber: "43772643",
    RegisteredPopulation: "538",
    VotingStationName: "MABOMVINI HIGH SCHOOL",
    Address: "STONEYRIDGE ROAD  REDLANDS FARM  P M BURG",
    Latitude: "-29.8546",
    Longitude: "30.497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10805",
    WardLookupId: "1396",
    VDNumber: "43773767",
    RegisteredPopulation: "349",
    VotingStationName: "DESDALE PRIMARY SCHOOL",
    Address: "N/A MID ILLOVO ROAD(WESHORPHE FARM  MID ILLOVO  CAMPERDOWN",
    Latitude: "-29.9258",
    Longitude: "30.5485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10806",
    WardLookupId: "1396",
    VDNumber: "43773789",
    RegisteredPopulation: "481",
    VotingStationName: "MBUTHO COMBINED PRIMARY SCHOOL",
    Address: "70 DISTRICT ROAD  NJOBOKAZI  CAMPERDOWN",
    Latitude: "-29.8615",
    Longitude: "30.5905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10807",
    WardLookupId: "1396",
    VDNumber: "43774061",
    RegisteredPopulation: "1042",
    VotingStationName: "COSMOORE PRIMARY SCHOOL",
    Address: "N/A NGOMANKULU(EMABHODINI)  UMLAAS RD  CAMPERDOWN",
    Latitude: "-29.775",
    Longitude: "30.4961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10808",
    WardLookupId: "1397",
    VDNumber: "43772418",
    RegisteredPopulation: "950",
    VotingStationName: "NGANGEZWE COMMUNITY HALL",
    Address: "N/A MZIZIMA (NEAR NGANGEZWE SCHOOL  OQWEQWENI WARD  AMANYAVU",
    Latitude: "-29.6576",
    Longitude: "30.6155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10809",
    WardLookupId: "1397",
    VDNumber: "43772430",
    RegisteredPopulation: "1277",
    VotingStationName: "TABLE MOUNTAIN COMBINED PRIMARY SCHOOL",
    Address: "N/A OQAQENI ( ESIDADENI )  CAMPERDOWN  AMANYAVU",
    Latitude: "-29.6447",
    Longitude: "30.6161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10810",
    WardLookupId: "1397",
    VDNumber: "43772452",
    RegisteredPopulation: "1943",
    VotingStationName: "INKANYEZINI LOWER PRIMARY SCHOOL",
    Address: "N/A INKANYEZINI(NEAR TAXI RANK)  CAMPERDOWN(ESKHALENI)  KWANYAVU",
    Latitude: "-29.6222",
    Longitude: "30.5756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10811",
    WardLookupId: "1398",
    VDNumber: "43580088",
    RegisteredPopulation: "815",
    VotingStationName: "MID - ILLOVO PRIMARY SCHOOL",
    Address: "D410 KWASDINGANE NEXT TO ERENKI  UMBUMBULU 090  EMBOTHIMUNI",
    Latitude: "-30.0361",
    Longitude: "30.5509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10812",
    WardLookupId: "1398",
    VDNumber: "43582114",
    RegisteredPopulation: "564",
    VotingStationName: "MNTONJANI SECONDARY SCHOOL",
    Address: "D158 MPANGISA  MID-ILLOVO  EMBOTHIMUNI",
    Latitude: "-30.0159",
    Longitude: "30.5053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10813",
    WardLookupId: "1398",
    VDNumber: "43583193",
    RegisteredPopulation: "455",
    VotingStationName: "ONDINI PRIMARY SCHOOL",
    Address: "D2058 ESMONTI NEAR NGCONGO STORE  UMBUMBULU 100  EMBOTHIMUNI",
    Latitude: "-30.041",
    Longitude: "30.5856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10814",
    WardLookupId: "1398",
    VDNumber: "43583250",
    RegisteredPopulation: "653",
    VotingStationName: "SENZAKAHLE SECONDARY SCHOOL",
    Address: "D2169 KWENZOKUHLE NEAR EGALAJI SCHOO  MID-ILLOVO  VUMUKWENZA",
    Latitude: "-30.0335",
    Longitude: "30.4629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10815",
    WardLookupId: "1398",
    VDNumber: "43772508",
    RegisteredPopulation: "1478",
    VotingStationName: "MPULULE PRIMARY SCHOOL",
    Address: "545 DISTRICT ROAD NO  MAKHOLWENI  IMBUMBULU",
    Latitude: "-29.9592",
    Longitude: "30.6471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10816",
    WardLookupId: "1398",
    VDNumber: "43772632",
    RegisteredPopulation: "751",
    VotingStationName: "MID ILLOVO PENSION POINT (TENT)",
    Address: "ISMONT ROAD  MKHAMBATHINI  MID-ILLOVO",
    Latitude: "-29.9684",
    Longitude: "30.5134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10817",
    WardLookupId: "1399",
    VDNumber: "43580055",
    RegisteredPopulation: "622",
    VotingStationName: "GULUBE PRIMARY SCHOOL",
    Address: "T728 GULUBE  MID-ILLOVO  EMBO-THIMUNI",
    Latitude: "-30.0412",
    Longitude: "30.5242",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10818",
    WardLookupId: "1399",
    VDNumber: "43580066",
    RegisteredPopulation: "494",
    VotingStationName: "THEMBALETHU COMBINED PRIMARY SCHOOL",
    Address: "T728 MGWENYA NEAR MKHOMAZI RIVER  UMBUMBULU 130  EMBOTHIMUNI",
    Latitude: "-30.0898",
    Longitude: "30.5239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10819",
    WardLookupId: "1399",
    VDNumber: "43580077",
    RegisteredPopulation: "908",
    VotingStationName: "BANIYENA COMBINED PRIMARY SCHOOL",
    Address: "T728 GILAFOHLE NEXT EMBO CLINIC  UMBUMBULU 140  EMBOTHIMUNI",
    Latitude: "-30.1074",
    Longitude: "30.5883",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10820",
    WardLookupId: "1399",
    VDNumber: "43580099",
    RegisteredPopulation: "959",
    VotingStationName: "INSIKAKAZI HIGH SCHOOL",
    Address: "D1148 NGILANYONI NEXT TO EMBO CLINIC  UMBUMBULU 120  EMBOTHIMUNI",
    Latitude: "-30.077",
    Longitude: "30.5535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10821",
    WardLookupId: "1399",
    VDNumber: "43583700",
    RegisteredPopulation: "378",
    VotingStationName: "THIMUNI LOWER PRIMARY SCHOOL",
    Address: "L1633 NTSONGENI  UMBUMBULU 141  EMBOTHIMUNI",
    Latitude: "-30.1124",
    Longitude: "30.5682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10822",
    WardLookupId: "1400",
    VDNumber: "43770045",
    RegisteredPopulation: "612",
    VotingStationName: "ILLOVO NEK PRIMARY SCHOOL",
    Address: "5 BYRNE FARM  RICHMOND  RICHMOND",
    Latitude: "-29.8465",
    Longitude: "30.1779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10823",
    WardLookupId: "1400",
    VDNumber: "43960024",
    RegisteredPopulation: "1963",
    VotingStationName: "AGRICULTURAL HALL",
    Address: "NELSON ST  RICHMOND VILLAGE  RICHMOND",
    Latitude: "-29.8681",
    Longitude: "30.2742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10824",
    WardLookupId: "1400",
    VDNumber: "43960080",
    RegisteredPopulation: "413",
    VotingStationName: "SOBANTU COMMUNITY CHURCH IN AFRICA",
    Address: "KWAMAGODA  LUANDA  RICHMOND",
    Latitude: "-29.9079",
    Longitude: "30.2587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10825",
    WardLookupId: "1400",
    VDNumber: "43960103",
    RegisteredPopulation: "642",
    VotingStationName: "BERNARD  MIZEKI PRIMARY SCHOOL",
    Address: "70 ALBERT STREET  RICHMOND [RICHMOND]  RICHMOND [RICHMOND]",
    Latitude: "-29.873",
    Longitude: "30.2711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10826",
    WardLookupId: "1400",
    VDNumber: "43960136",
    RegisteredPopulation: "503",
    VotingStationName: "MAGODA COMMUNITY HALL",
    Address: "MAGODA ROAD  MAGODA  RICHMOND",
    Latitude: "-29.9022",
    Longitude: "30.252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10827",
    WardLookupId: "1401",
    VDNumber: "43960046",
    RegisteredPopulation: "1833",
    VotingStationName: "MZWANDILE MBONGWE HIGH SCHOOL",
    Address: "SLAHLA   NEXT TO MJINTINI  RICHMOND",
    Latitude: "-29.8837",
    Longitude: "30.2488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10828",
    WardLookupId: "1401",
    VDNumber: "43960079",
    RegisteredPopulation: "1047",
    VotingStationName: "HUBA HIGH SCHOOL",
    Address: "MAGODA   ESIGCAKINI AREA  RICHMOND",
    Latitude: "-29.8954",
    Longitude: "30.2525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10829",
    WardLookupId: "1401",
    VDNumber: "43960091",
    RegisteredPopulation: "1157",
    VotingStationName: "LUSAKA COMMUNITY HALL",
    Address: "INDALENI  SLAHLA LOCATION NEXT TO THE SPORTSFIELD  RICHMOND",
    Latitude: "-29.8793",
    Longitude: "30.2635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10830",
    WardLookupId: "1401",
    VDNumber: "43960114",
    RegisteredPopulation: "1205",
    VotingStationName: "SIYATHUTHUKA COMMUNITY HALL",
    Address: "SIYATHUTHUKA AREA  EMXHASWENI   RICHMOND",
    Latitude: "-29.8735",
    Longitude: "30.2591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10831",
    WardLookupId: "1402",
    VDNumber: "43772542",
    RegisteredPopulation: "509",
    VotingStationName: "BAYNESFIELD & DISTRICT RECREATIONAL CLUB",
    Address: "BAYNESFIELD   BAYNESFIELD ESTATE  RICHMOND",
    Latitude: "-29.7589",
    Longitude: "30.3492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10832",
    WardLookupId: "1402",
    VDNumber: "43773510",
    RegisteredPopulation: "1935",
    VotingStationName: "HOPEWELL PRIMARY SCHOOL",
    Address: "NEAR MCHUNUS GENERAL DEALER  HOPEWELL  THORNVILLE",
    Latitude: "-29.7933",
    Longitude: "30.3926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10833",
    WardLookupId: "1402",
    VDNumber: "43773521",
    RegisteredPopulation: "787",
    VotingStationName: "UNITING PRESBYTERIAN CHURCH",
    Address: "HOPEWELL MAIN ROAD  RICHMOND  RICHMOND",
    Latitude: "-29.7891",
    Longitude: "30.3981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10834",
    WardLookupId: "1402",
    VDNumber: "43774599",
    RegisteredPopulation: "655",
    VotingStationName: "THORNVILLE PRIMARY SCHOOL",
    Address: "NEXT TO CALTEX GARAGE  THORNVILLE AREA   RICHMOND",
    Latitude: "-29.7359",
    Longitude: "30.386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10835",
    WardLookupId: "1403",
    VDNumber: "43772531",
    RegisteredPopulation: "2852",
    VotingStationName: "ARGOSY COMMUNITY HALL",
    Address: "HOPEWELL ROAD  ARGOSY FARM  RICHMOND",
    Latitude: "-29.7952",
    Longitude: "30.4046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10836",
    WardLookupId: "1403",
    VDNumber: "43772654",
    RegisteredPopulation: "664",
    VotingStationName: "EINSIEDELN PRIMARY SCHOOL",
    Address: "ESTON ROAD  ESTON  RICHMOND",
    Latitude: "-29.9392",
    Longitude: "30.3644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10837",
    WardLookupId: "1403",
    VDNumber: "43772665",
    RegisteredPopulation: "453",
    VotingStationName: "GABANGOLWAZI COMBINED SCHOOL",
    Address: "DISTRICT RD (D35)  INHLAZUKA  RICHMOND",
    Latitude: "-30.0169",
    Longitude: "30.4316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10838",
    WardLookupId: "1403",
    VDNumber: "43772687",
    RegisteredPopulation: "284",
    VotingStationName: "PACKHOUSE (TENT)",
    Address: "RICHMOND VALLEY ROAD  RICHMOND  KWAZULU NATAL",
    Latitude: "-29.8703",
    Longitude: "30.3196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10839",
    WardLookupId: "1403",
    VDNumber: "43774702",
    RegisteredPopulation: "257",
    VotingStationName: "ARDEN FARM PRIMARY SCHOOL",
    Address: "MID-ILLOVO THE VALLEYVIEW FARM  RICHMOND  MID-ILLOVO",
    Latitude: "-29.977",
    Longitude: "30.4775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10840",
    WardLookupId: "1404",
    VDNumber: "43580011",
    RegisteredPopulation: "896",
    VotingStationName: "MQOLOMBENI COMBINED PRIMARY SCHOOL",
    Address: "MBUMBULU MAIN ROAD  UMBUMBULU 010  RICHMOND",
    Latitude: "-30.0568",
    Longitude: "30.3925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10841",
    WardLookupId: "1404",
    VDNumber: "43580022",
    RegisteredPopulation: "559",
    VotingStationName: "MBUTHISWENI PRIMARY SCHOOL",
    Address: "MBUMBULU MAIN ROARD   MID-ILLOVO  VUMUKWENZA TRIBAL AUTHORITY",
    Latitude: "-30.0607",
    Longitude: "30.4597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10842",
    WardLookupId: "1404",
    VDNumber: "43580033",
    RegisteredPopulation: "632",
    VotingStationName: "MPOFANA PRIMARY SCHOOL",
    Address: "L675  MPOFANA AREA  RICHMOND",
    Latitude: "-30.0866",
    Longitude: "30.4956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10843",
    WardLookupId: "1404",
    VDNumber: "43580044",
    RegisteredPopulation: "499",
    VotingStationName: "MALIZAYO PRIMARY SCHOOL",
    Address: "D158 PHUTHAZA ROAD  MALIZAYO AREA  VUMUKWENZA TRIBAL AUTHORITY",
    Latitude: "-30.0385",
    Longitude: "30.5023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10844",
    WardLookupId: "1404",
    VDNumber: "43583249",
    RegisteredPopulation: "453",
    VotingStationName: "MKHONTO GARAGE",
    Address: "D158  KWAMBILA  VUMUKWENZA TRIBAL AUTHORITY",
    Latitude: "-30.0571",
    Longitude: "30.4709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10845",
    WardLookupId: "1404",
    VDNumber: "43583261",
    RegisteredPopulation: "277",
    VotingStationName: "MTHOMBOWOLWAZI PRIMARY SCHOOL",
    Address: "MBUMBULU MAIN ROAD   ISIPINGO  VUMUKWENZA",
    Latitude: "-30.0698",
    Longitude: "30.44",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10846",
    WardLookupId: "1404",
    VDNumber: "43584206",
    RegisteredPopulation: "153",
    VotingStationName: "EZULWINI COMBINED SCHOOL",
    Address: "EMNSINSINI EMBUTHWENI   RICHMOND   VUMUKWENZA",
    Latitude: "-30.0911",
    Longitude: "30.4007",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10847",
    WardLookupId: "1404",
    VDNumber: "43584217",
    RegisteredPopulation: "275",
    VotingStationName: "MANTSHOLWANE PRIMARY SCHOOL",
    Address: "L674  MSHIBANE  VUMUKWENZA TRIBAL AUTHORITY",
    Latitude: "-30.0711",
    Longitude: "30.4867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10848",
    WardLookupId: "1404",
    VDNumber: "43584228",
    RegisteredPopulation: "367",
    VotingStationName: "MALIPHUME COMBINED PRIMARY SCHOOL",
    Address: "D1065  MAHLAMVINI AREA  VUMUKWENZA TRIBAL AUTHORITY",
    Latitude: "-30.0508",
    Longitude: "30.5147",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10849",
    WardLookupId: "1404",
    VDNumber: "43584318",
    RegisteredPopulation: "287",
    VotingStationName: "SHIYAMPAHLA PRIMARY SCHOOL",
    Address: "L1116  KA SHIYAMPAHLA   VUMUKWENZA TRIBAL AUTHORITY",
    Latitude: "-30.0946",
    Longitude: "30.47",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10850",
    WardLookupId: "1404",
    VDNumber: "43774409",
    RegisteredPopulation: "542",
    VotingStationName: "THUTHUKISA SECONDARY SCHOOL",
    Address: "INHLAZUKA  ST. BERNARD`S MISSION  VUMUKWENZA TRIBAL AUTHORITY",
    Latitude: "-30.0367",
    Longitude: "30.425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10851",
    WardLookupId: "1405",
    VDNumber: "43771945",
    RegisteredPopulation: "114",
    VotingStationName: "MASANGAZANA PRIMARY SCHOOL",
    Address: "NCWADI ROAD  MASANGAZANA  RICHMOND",
    Latitude: "-29.8189",
    Longitude: "30.0599",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10852",
    WardLookupId: "1405",
    VDNumber: "43772609",
    RegisteredPopulation: "416",
    VotingStationName: "KWAGENGESHE COMMUNITY HALL",
    Address: "NEXT TO KWAGENGESHE CRECHE  KWAGENGESHE  RICHMOND",
    Latitude: "-29.849",
    Longitude: "30.1187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10853",
    WardLookupId: "1405",
    VDNumber: "43772676",
    RegisteredPopulation: "1178",
    VotingStationName: "PHATHENI PRIMARY SCHOOL",
    Address: "PHATHENI MAIN ROAD  RICHMOND  ESIPHAHLENI TRIBAL AUTHORITY",
    Latitude: "-29.9617",
    Longitude: "30.2128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10854",
    WardLookupId: "1405",
    VDNumber: "43772698",
    RegisteredPopulation: "250",
    VotingStationName: "ROSETTENSTEIN PRIMARY SCHOOL",
    Address: "ROAD D60  WARDHILL FARM  RICHMOND",
    Latitude: "-29.9608",
    Longitude: "30.3251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10855",
    WardLookupId: "1405",
    VDNumber: "43774397",
    RegisteredPopulation: "455",
    VotingStationName: "LINWOOD PRIMARY SCHOOL",
    Address: "HELLA HELLA ROAD  EASTWOLDS NICHOLSONS FARM  RICHMOND [RICHMOND]",
    Latitude: "-29.8955",
    Longitude: "30.1338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10856",
    WardLookupId: "1405",
    VDNumber: "43774601",
    RegisteredPopulation: "206",
    VotingStationName: "SAPEKOE PRIMARY SCHOOL",
    Address: "HELLA HELLA ROAD  EMOYENI NEXT CAMELLIA TEA ESTATE  RICHMOND",
    Latitude: "-29.9574",
    Longitude: "30.1299",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10857",
    WardLookupId: "1405",
    VDNumber: "43775017",
    RegisteredPopulation: "428",
    VotingStationName: "MKHOBENI HIGH SCHOOL",
    Address:
      "PHATHENI LOCATION  MKHOBENI NEXT TO PHATHENI CLINIC   ESIPHAHLENI TRIBAL AUTHORITY",
    Latitude: "-29.949",
    Longitude: "30.2136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10858",
    WardLookupId: "1405",
    VDNumber: "43960035",
    RegisteredPopulation: "1304",
    VotingStationName: "ESIMOZOMENI COMMUNITY HALL",
    Address: "ESIMOZOMENI LOCATION  NEXT TO NDALA BOTTLE STORE  RICHMOND",
    Latitude: "-29.9117",
    Longitude: "30.2202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10859",
    WardLookupId: "1406",
    VDNumber: "43960013",
    RegisteredPopulation: "2083",
    VotingStationName: "MENZIWA PRIMARY SCHOOL",
    Address: "EMASWAZINI AREA   INDALENI MISSION  RICHMOND",
    Latitude: "-29.8904",
    Longitude: "30.2349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10860",
    WardLookupId: "1406",
    VDNumber: "43960057",
    RegisteredPopulation: "1505",
    VotingStationName: "MAGODA PRIMARY SCHOOL",
    Address: "MAGODA ROAD  MAGODA LOCATION  RICHMOND",
    Latitude: "-29.9114",
    Longitude: "30.2528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10861",
    WardLookupId: "1406",
    VDNumber: "43960068",
    RegisteredPopulation: "1180",
    VotingStationName: "NDABIKHONA PRIMARY SCHOOL",
    Address: "NDALENI ROAD  INDALENI  RICHMOND",
    Latitude: "-29.8979",
    Longitude: "30.2443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10862",
    WardLookupId: "1406",
    VDNumber: "43960125",
    RegisteredPopulation: "940",
    VotingStationName: "INDALENI SCHOOL FOR THE DEAF",
    Address: "INDALENI MISSION  NEXT TO INDALENI PRIMARY SCHOOL  RICHMOND",
    Latitude: "-29.8916",
    Longitude: "30.2398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10863",
    WardLookupId: "1407",
    VDNumber: "43691115",
    RegisteredPopulation: "2663",
    VotingStationName: "CELIMFUNDO PRIMARY",
    Address: "KHETHANI LOCATION  BERGVILLE  OKHAHLAMBA /WINTERTON",
    Latitude: "-28.8168",
    Longitude: "29.5263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10864",
    WardLookupId: "1407",
    VDNumber: "43693139",
    RegisteredPopulation: "1148",
    VotingStationName: "ALL SAINT  ANGLICAN CHURCH HALL",
    Address: "68 R74 MAIN ROAD CEMENTERY   WINTERTON  WINTERTON",
    Latitude: "-28.8099",
    Longitude: "29.5413",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10865",
    WardLookupId: "1407",
    VDNumber: "43693476",
    RegisteredPopulation: "157",
    VotingStationName: "BERSIG FARM SCHOOL",
    Address: "EMSELENI BERSIG FARM   WINTERTON  OKHAHLAMBA /WINTERTON",
    Latitude: "-28.793",
    Longitude: "29.6204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10866",
    WardLookupId: "1408",
    VDNumber: "43691698",
    RegisteredPopulation: "1374",
    VotingStationName: "EMMAUS COMBINED PRIMARY SCHOOL",
    Address: "EMMAUS MISSION  EMMAUS AREA  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.8578",
    Longitude: "29.3818",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10867",
    WardLookupId: "1408",
    VDNumber: "43691700",
    RegisteredPopulation: "1263",
    VotingStationName: "MAVUMBUKA HIGH SCHOOL",
    Address: "EMAMFEMFETHENI  EMMAUS  AMANGWANE OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.8545",
    Longitude: "29.336",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10868",
    WardLookupId: "1408",
    VDNumber: "43691722",
    RegisteredPopulation: "863",
    VotingStationName: "EZINYONYANA COMBINED PRIMARY SCHOOL",
    Address: "EZINYONYANA  EMMAUS   BERGVILLE  AMANGWANE",
    Latitude: "-28.8608",
    Longitude: "29.4023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10869",
    WardLookupId: "1408",
    VDNumber: "43691777",
    RegisteredPopulation: "653",
    VotingStationName: "SIFISOKUHLE HIGHER PRIMARY SCHOOL",
    Address: "ETHUNZINI  EMMAUS  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.8724",
    Longitude: "29.3584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10870",
    WardLookupId: "1408",
    VDNumber: "43693184",
    RegisteredPopulation: "371",
    VotingStationName: "APOSTOLIC CHURCH OF SOUTH AFRICA",
    Address: "ETHUNZINI  AMANGWANE  TRIBAL AUTHORITY",
    Latitude: "-28.8854",
    Longitude: "29.3468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10871",
    WardLookupId: "1409",
    VDNumber: "43691676",
    RegisteredPopulation: "334",
    VotingStationName: "NQOBILE COMBINED PRIMARY SCHOOL",
    Address: "KWANOBADINA  ENGOBA  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.8384",
    Longitude: "29.2588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10872",
    WardLookupId: "1409",
    VDNumber: "43691711",
    RegisteredPopulation: "782",
    VotingStationName: "NGWADI LOWER PRIMARY SCHOOL",
    Address: "ENGOBA  NGOBA   OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.8715",
    Longitude: "29.2999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10873",
    WardLookupId: "1409",
    VDNumber: "43691766",
    RegisteredPopulation: "511",
    VotingStationName: "SOKESIMBONE HIGH SCHOOL",
    Address: "ESIHLANJENI  BERGVILLE  AMANGWANE TRIBAL",
    Latitude: "-28.8835",
    Longitude: "29.285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10874",
    WardLookupId: "1409",
    VDNumber: "43691788",
    RegisteredPopulation: "273",
    VotingStationName: "KHOLOKAZANA COMBINED PRIMARY SCHOOL",
    Address: "EMAMFEMFETHENI  EMMAUS  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.8897",
    Longitude: "29.3187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10875",
    WardLookupId: "1409",
    VDNumber: "43691812",
    RegisteredPopulation: "394",
    VotingStationName: "MAMPONJWANA COMBINED PRIMARY SCHOOL",
    Address: "EMAMPONJWANA   MAMPONJWANA  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.9093",
    Longitude: "29.2943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10876",
    WardLookupId: "1409",
    VDNumber: "43691823",
    RegisteredPopulation: "1145",
    VotingStationName: "IDIDIMA COMBINED PRIMARY SCHOOL",
    Address: "EMHLWAZINI  AMANGWANE OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.9264",
    Longitude: "29.2713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10877",
    WardLookupId: "1409",
    VDNumber: "43692587",
    RegisteredPopulation: "579",
    VotingStationName: "SENZOKWETHU PRIMARY SCHOOL",
    Address: "INGOBA SETTLEMENT  BERGIVILLE  AMANGWANE",
    Latitude: "-28.8976",
    Longitude: "29.2642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10878",
    WardLookupId: "1410",
    VDNumber: "43691519",
    RegisteredPopulation: "929",
    VotingStationName: "UKHAHLAMBA HIGH SCHOOL",
    Address: "UKHAHLAMBA  DUKUZA  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.7582",
    Longitude: "29.2077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10879",
    WardLookupId: "1410",
    VDNumber: "43691542",
    RegisteredPopulation: "949",
    VotingStationName: "TABHANE HIGH SCHOOL",
    Address: "KWAMAYE  HOFFENTAL  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.7744",
    Longitude: "29.2373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10880",
    WardLookupId: "1410",
    VDNumber: "43691564",
    RegisteredPopulation: "645",
    VotingStationName: "NSEBENZO ENHLE LOWER PRIMARY SCHOOL",
    Address: "MKHOMAZANE  AMANGWANE OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.7773",
    Longitude: "29.199",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10881",
    WardLookupId: "1410",
    VDNumber: "43691597",
    RegisteredPopulation: "585",
    VotingStationName: "HOFFENTHAL COMBINED PRIMARY SCHOOL",
    Address: "EMAKHOSANENI  HOFFENTAL  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.7965",
    Longitude: "29.2441",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10882",
    WardLookupId: "1410",
    VDNumber: "43691609",
    RegisteredPopulation: "548",
    VotingStationName: "SOMSEWU LOWER PRIMARY SCHOOL",
    Address: "EMASWAZINI  ESANDLWANA  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.8089",
    Longitude: "29.2018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10883",
    WardLookupId: "1410",
    VDNumber: "43693005",
    RegisteredPopulation: "477",
    VotingStationName: "MHLOSHANA PRIMARY",
    Address: "MHLOSHANA  BERGVILLE  BERGVILLE / AMANGWANE T/A",
    Latitude: "-28.8432",
    Longitude: "29.2328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10884",
    WardLookupId: "1410",
    VDNumber: "43693421",
    RegisteredPopulation: "441",
    VotingStationName: "NGUNJINI SATELITE PRIMARY SCHOOL",
    Address: "KWANKOSANA  BERGVILLE / AMANGWANE",
    Latitude: "-28.7933",
    Longitude: "29.1695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10885",
    WardLookupId: "1411",
    VDNumber: "43691520",
    RegisteredPopulation: "519",
    VotingStationName: "DUKUZA PRIMARY SCHOOL",
    Address: "EZIBOMVU  DUKUZA  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.7582",
    Longitude: "29.196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10886",
    WardLookupId: "1411",
    VDNumber: "43691553",
    RegisteredPopulation: "441",
    VotingStationName: "MONDISA COMBINED PRIMARY SCHOOL",
    Address: "EMANZANA  MANZAMA  BERGVILLE/AMANGWANE",
    Latitude: "-28.7955",
    Longitude: "29.1136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10887",
    WardLookupId: "1411",
    VDNumber: "43691575",
    RegisteredPopulation: "778",
    VotingStationName: "NTONYELANA JUNIOR PRIMARY SCHOOL",
    Address: "ENTONYELANE  ESANDLWANA  OKHAHLAMBA BERGVILLE DISTRICT/AMANGWANE",
    Latitude: "-28.824",
    Longitude: "29.1025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10888",
    WardLookupId: "1411",
    VDNumber: "43691586",
    RegisteredPopulation: "665",
    VotingStationName: "NGUNJINI LOWER PRIMARY SCHOOL",
    Address: "EZIBOMVU  DUKUZA  OKHAHLAMBA BERGVILLE DISTRICT/ AMANGWANE",
    Latitude: "-28.7734",
    Longitude: "29.1793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10889",
    WardLookupId: "1411",
    VDNumber: "43691643",
    RegisteredPopulation: "887",
    VotingStationName: "GLOECKNER COMBINED PRIMARY SCHOOL",
    Address: "QGOMU  ESANDLWANA  OKHAHLAMBA BERGVILLE DISTRICT/AMANGWANE B",
    Latitude: "-28.8148",
    Longitude: "29.1359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10890",
    WardLookupId: "1411",
    VDNumber: "43691654",
    RegisteredPopulation: "630",
    VotingStationName: "UKHALI JUNIOR SCHOOL",
    Address: "KHOKHWANA  ESANDLWANA  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.8109",
    Longitude: "29.1791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10891",
    WardLookupId: "1411",
    VDNumber: "43693432",
    RegisteredPopulation: "221",
    VotingStationName: "NSETHENI PRIMARY SCHOOL",
    Address: "KHOKHWANE/EMABHULESINI  EMABHULESINI  AMANGWANE/BERGVILLE",
    Latitude: "-28.8046",
    Longitude: "29.0451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10892",
    WardLookupId: "1412",
    VDNumber: "43691193",
    RegisteredPopulation: "703",
    VotingStationName: "HLELOKUHLE CRECHE (NEW STAND)",
    Address: "MAZIZINI (NEW STAND)  EMAZIZINI  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.681",
    Longitude: "29.0304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10893",
    WardLookupId: "1412",
    VDNumber: "43691250",
    RegisteredPopulation: "931",
    VotingStationName: "BANGANI HIGH SCHOOL",
    Address: "OBONJANENI  OKHAHLAMBA/ BERGVILLE DISTRICT",
    Latitude: "-28.6825",
    Longitude: "28.9985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10894",
    WardLookupId: "1412",
    VDNumber: "43691306",
    RegisteredPopulation: "510",
    VotingStationName: "EBUSINGATHA COMBINED PRIMARY SCHOOL",
    Address: "EBUSINGATHA  OKHAHLAMBA DISTRICT/AMAZIZI",
    Latitude: "-28.716",
    Longitude: "28.9982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10895",
    WardLookupId: "1412",
    VDNumber: "43691317",
    RegisteredPopulation: "1251",
    VotingStationName: "OBONJANENI COMBINED PRIMARY SCHOOL",
    Address: "OBONJANENI  OKHAHLAMBA BERGVILLE DISTRICT/AMAZIZI",
    Latitude: "-28.6876",
    Longitude: "28.9813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10896",
    WardLookupId: "1412",
    VDNumber: "43691429",
    RegisteredPopulation: "602",
    VotingStationName: "FAITH MISSION CHURCH EBUSINGATHA",
    Address: "EBUSINGATHA  BERGVILLE  EBUSINGATHA",
    Latitude: "-28.731",
    Longitude: "28.9968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10897",
    WardLookupId: "1412",
    VDNumber: "43693195",
    RegisteredPopulation: "326",
    VotingStationName: "MYENDANE PRIMARY SCHOOL",
    Address: "KWAMAGABA  AMAZIZI TRIBAL AUTHORITY",
    Latitude: "-28.6889",
    Longitude: "29.0112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10898",
    WardLookupId: "1413",
    VDNumber: "43691159",
    RegisteredPopulation: "429",
    VotingStationName: "SIVULELENI PRE-PRIMARY SCHOOL",
    Address: "EMAPHOPHOMANENI  EMAZIZINI  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.6776",
    Longitude: "29.0359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10899",
    WardLookupId: "1413",
    VDNumber: "43691171",
    RegisteredPopulation: "735",
    VotingStationName: "ENHLANHLENI COMMUNITY HALL",
    Address: "ZWELISHA   BERGVILLE  AMANGWANE TRIBAL AUTHORITY",
    Latitude: "-28.6708",
    Longitude: "29.0841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10900",
    WardLookupId: "1413",
    VDNumber: "43691238",
    RegisteredPopulation: "880",
    VotingStationName: "AMAZIZI HIGH SCHOOL",
    Address: "AMAZIZI  BERGVILLE   OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.6814",
    Longitude: "29.0594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10901",
    WardLookupId: "1413",
    VDNumber: "43691283",
    RegisteredPopulation: "739",
    VotingStationName: "INSUKANGIHLALE COMBINED SCHOOL",
    Address: "ENSUKANGIHLALE  MAZIZINI  OKHAHLAMBA",
    Latitude: "-28.6816",
    Longitude: "29.0464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10902",
    WardLookupId: "1413",
    VDNumber: "43691328",
    RegisteredPopulation: "548",
    VotingStationName: "OKHOMBE PRIMARY SCHOOL",
    Address: "OKHOMBE  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.7071",
    Longitude: "29.0784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10903",
    WardLookupId: "1413",
    VDNumber: "43691395",
    RegisteredPopulation: "793",
    VotingStationName: "ZAMOKUHLE CRECHE",
    Address: "BERGVILLE  AMAZIZI",
    Latitude: "-28.7271",
    Longitude: "29.0722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10904",
    WardLookupId: "1414",
    VDNumber: "43691272",
    RegisteredPopulation: "723",
    VotingStationName: "MAFU HIGH SCHOOL",
    Address: "OLIVIERSHOEK  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.7022",
    Longitude: "29.1485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10905",
    WardLookupId: "1414",
    VDNumber: "43691340",
    RegisteredPopulation: "316",
    VotingStationName: "GUGULETHU COMMUNITY CENTRE",
    Address: "BEFORE JOLLY BAR  EMOYENI  OKHAHLAMBA",
    Latitude: "-28.7091",
    Longitude: "29.1278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10906",
    WardLookupId: "1414",
    VDNumber: "43691418",
    RegisteredPopulation: "823",
    VotingStationName: "VULAMEHLO COMBINED PRIMARY SCHOOL",
    Address: "MOYENI AREA  EMOYENI  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.7214",
    Longitude: "29.1544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10907",
    WardLookupId: "1414",
    VDNumber: "43691430",
    RegisteredPopulation: "620",
    VotingStationName: "UBULINGA HIGH SCHOOL",
    Address: "OKHAHLAMBA/BERGVILLE EMOYENI  BERGVILLE  AMANGWANE",
    Latitude: "-28.7205",
    Longitude: "29.1731",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10908",
    WardLookupId: "1414",
    VDNumber: "43691474",
    RegisteredPopulation: "1126",
    VotingStationName: "NTUMBANE COMBINED PRIMARY SCHOOL",
    Address: "ENTUMBANE  BHALEKISI  BERGVILLE DISTRICT",
    Latitude: "-28.739",
    Longitude: "29.1704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10909",
    WardLookupId: "1414",
    VDNumber: "43691485",
    RegisteredPopulation: "592",
    VotingStationName: "OGADE COMBINED PRIMARY SCHOOL",
    Address: "OGADE  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.7295",
    Longitude: "29.1214",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10910",
    WardLookupId: "1415",
    VDNumber: "43691058",
    RegisteredPopulation: "1307",
    VotingStationName: "MASUMPA PRIMARY SCHOOL",
    Address: "ROOKDALE ENTSHA  ZWELISHA  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.6731",
    Longitude: "29.0956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10911",
    WardLookupId: "1415",
    VDNumber: "43691104",
    RegisteredPopulation: "924",
    VotingStationName: "AMANGWANE HIGH SCHOOL",
    Address: "ZWELISHA AREA  ZWELISHA  BERGVILLE",
    Latitude: "-28.6774",
    Longitude: "29.1229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10912",
    WardLookupId: "1415",
    VDNumber: "43691126",
    RegisteredPopulation: "627",
    VotingStationName: "NOGAGA COMBINED PRIMARY SCHOOL",
    Address: "ZWELISHA AREA  ZWELISHA  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.6761",
    Longitude: "29.11",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10913",
    WardLookupId: "1415",
    VDNumber: "43691148",
    RegisteredPopulation: "701",
    VotingStationName: "BERGVILLE BEING CENTRE",
    Address: "KWATHUSI  OKHAHLAMBA/BERGVILLE  AMANGWANE ZWELISHA KWATHUSI",
    Latitude: "-28.6778",
    Longitude: "29.1055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10914",
    WardLookupId: "1415",
    VDNumber: "43691249",
    RegisteredPopulation: "624",
    VotingStationName: "BAMBANANI PRIMARY",
    Address: "ZWELISHA   BERGVILLE  AMANGWANE",
    Latitude: "-28.688",
    Longitude: "29.1325",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10915",
    WardLookupId: "1416",
    VDNumber: "43690776",
    RegisteredPopulation: "264",
    VotingStationName: "ROAD RESERVED 3 RESEVOIR TENT",
    Address: "R74  BERGVILLE   BERGVILLE",
    Latitude: "-28.6363",
    Longitude: "29.1428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10916",
    WardLookupId: "1416",
    VDNumber: "43690800",
    RegisteredPopulation: "758",
    VotingStationName: "AMAPHISI SECONDARY SCHOOL",
    Address: "LANGKLOOF AREA  LANGKLOOF  BERGVILLE DISTRICT",
    Latitude: "-28.6248",
    Longitude: "29.0841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10917",
    WardLookupId: "1416",
    VDNumber: "43691182",
    RegisteredPopulation: "593",
    VotingStationName: "CROWFIELD LOWER PRIMARY SCHOOL",
    Address: "ENDANYANA  ROOKDALE  BERGVILLE DISTRICT",
    Latitude: "-28.7214",
    Longitude: "29.2474",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10918",
    WardLookupId: "1416",
    VDNumber: "43691373",
    RegisteredPopulation: "946",
    VotingStationName: "ROOKDALE PRIMARY SCHOOL",
    Address: "ROOKDALE AREA  ROOKDALE  BERGVILLE",
    Latitude: "-28.7109",
    Longitude: "29.2339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10919",
    WardLookupId: "1416",
    VDNumber: "43691441",
    RegisteredPopulation: "802",
    VotingStationName: "MQENDANDABA JUNIOR SCHOOL",
    Address: "EMAROMENI  ROOKDALE  BERGVILLE DISTRICT",
    Latitude: "-28.7162",
    Longitude: "29.2329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10920",
    WardLookupId: "1416",
    VDNumber: "43692891",
    RegisteredPopulation: "679",
    VotingStationName: "ROOKDALE MAHLATHINI",
    Address: "ROOKDALE MAHLATHINI  ROOKDALE  OKHAHLAMBA",
    Latitude: "-28.7105",
    Longitude: "29.2164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10921",
    WardLookupId: "1417",
    VDNumber: "43690642",
    RegisteredPopulation: "312",
    VotingStationName: "EKUKHANYENI PRIMARY SCHOOL",
    Address: "ROSELEIGH FARM  NTABAMNYAMA  KLIPRIVER DISTRICT",
    Latitude: "-28.6126",
    Longitude: "29.5481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10922",
    WardLookupId: "1417",
    VDNumber: "43690710",
    RegisteredPopulation: "1204",
    VotingStationName: "KWANTATSHANA SECONDARY SCHOOL",
    Address: "ACTON HOMES  BERGVILLE DISTRICT",
    Latitude: "-28.6456",
    Longitude: "29.4073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10923",
    WardLookupId: "1417",
    VDNumber: "43690989",
    RegisteredPopulation: "1257",
    VotingStationName: "NOBOMI HIGH SCHOOL",
    Address: "BERGVILLE DISTRICT  ",
    Latitude: "-28.632",
    Longitude: "29.4375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10924",
    WardLookupId: "1417",
    VDNumber: "43700015",
    RegisteredPopulation: "903",
    VotingStationName: "ST ANGLICAN CHURCH HALL",
    Address: "SOUTH STREET  BERGVILLE  BERGVILLE",
    Latitude: "-28.7328",
    Longitude: "29.3547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10925",
    WardLookupId: "1418",
    VDNumber: "43691216",
    RegisteredPopulation: "710",
    VotingStationName: "WOODFORD METHODIST CHURCH",
    Address: "EMAVILENI  WOODFORD  BERGVILLE DISTRICT",
    Latitude: "-28.6897",
    Longitude: "29.3003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10926",
    WardLookupId: "1418",
    VDNumber: "43691227",
    RegisteredPopulation: "297",
    VotingStationName: "VICKLANDS PRIMARY SCHOOL",
    Address: "BERWIN  BERGVILLE  BERGVILLE",
    Latitude: "-28.7589",
    Longitude: "29.4389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10927",
    WardLookupId: "1418",
    VDNumber: "43691339",
    RegisteredPopulation: "813",
    VotingStationName: "APOSTOLIC DIVINE FELLOWSHIP CHURCH",
    Address: "WOODFORD  BERGVILLE   WOODFORD AREA",
    Latitude: "-28.6867",
    Longitude: "29.2936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10928",
    WardLookupId: "1418",
    VDNumber: "43691351",
    RegisteredPopulation: "631",
    VotingStationName: "WOODFORD COMBINED PRIMARY SCHOOL",
    Address: "WOODFORD AREA  WOODFORD  BERGVILLE DISTRICT",
    Latitude: "-28.6965",
    Longitude: "29.2861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10929",
    WardLookupId: "1418",
    VDNumber: "43691621",
    RegisteredPopulation: "898",
    VotingStationName: "ZWELETHU LOWER PRIMARY SCHOOL",
    Address: "KWAKHWELA  EPOTSHINI  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.8206",
    Longitude: "29.3685",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10930",
    WardLookupId: "1418",
    VDNumber: "43691687",
    RegisteredPopulation: "542",
    VotingStationName: "NOKOPELA COMBINED PRIMARY SCHOOL",
    Address: "KWANOKOPELA  EMMAUS  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.8276",
    Longitude: "29.4021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10931",
    WardLookupId: "1418",
    VDNumber: "43693375",
    RegisteredPopulation: "404",
    VotingStationName: "PRESBYTERIAN APOSTOLIC CHURCH",
    Address: "EMMAUSE  BERGVILLE  AMANGWANE TRIBAL",
    Latitude: "-28.8513",
    Longitude: "29.3757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10932",
    WardLookupId: "1419",
    VDNumber: "43690305",
    RegisteredPopulation: "189",
    VotingStationName: "SANDRIVER VALLEY PRIMARY SCHOOL",
    Address: "EBHOWENI  BERGVILLE / VAN RENEEN  BERGVILLE",
    Latitude: "-28.4566",
    Longitude: "29.4894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10933",
    WardLookupId: "1419",
    VDNumber: "43690417",
    RegisteredPopulation: "714",
    VotingStationName: "RHEIBOKSPRUIT COMBINED PRIMARY SCHOOL",
    Address: "RHEIBOKSPUIT  RHEIBOKSPRUIT  BERGVILLE DISTRICT",
    Latitude: "-28.4724",
    Longitude: "29.2635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10934",
    WardLookupId: "1419",
    VDNumber: "43690787",
    RegisteredPopulation: "1099",
    VotingStationName: "GREENPOINT HALL",
    Address: "GREENPOINT AREA  BERGVILLE  BERGVILLE DISTRICT",
    Latitude: "-28.5663",
    Longitude: "29.3988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10935",
    WardLookupId: "1419",
    VDNumber: "43690798",
    RegisteredPopulation: "771",
    VotingStationName: "MALOTTASKRAAL COMBINED PRIMARY SCHOOL",
    Address: "BERGVILLE  MALOTTASKRAAL  BERGVILLE DISTRICT",
    Latitude: "-28.5639",
    Longitude: "29.3757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10936",
    WardLookupId: "1419",
    VDNumber: "43690822",
    RegisteredPopulation: "253",
    VotingStationName: "OPPERMANSKRAAL PRIMARY SCHOOL",
    Address: "BERGVILLE  ",
    Latitude: "-28.5678",
    Longitude: "29.3218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10937",
    WardLookupId: "1419",
    VDNumber: "43692532",
    RegisteredPopulation: "259",
    VotingStationName: "MASWAZI PRIMARY SCHOOL",
    Address: "GELUKSBURG AREA  GELUKSBURG  LADYSMITH",
    Latitude: "-28.5237",
    Longitude: "29.3577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10938",
    WardLookupId: "1419",
    VDNumber: "43692903",
    RegisteredPopulation: "254",
    VotingStationName: "EGQUMAWENI PRIMARY SCHOOL",
    Address:
      "EGQUMAWENI  OKHAHLAMBA/BERGVILLE  EGQUMAWENI OKHAHLAMBA /BERGVILLE",
    Latitude: "-28.4904",
    Longitude: "29.3806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10939",
    WardLookupId: "1419",
    VDNumber: "43693050",
    RegisteredPopulation: "125",
    VotingStationName: "MPANDWENI PRIMARY SCHOOL",
    Address:
      "(KZN235 - OKHAHLAMBA [BERGVILLE])   R74 NONDELA ESTATE MPANDWENI OKHAHLAMBA/BERGVILLE",
    Latitude: "-28.5968",
    Longitude: "29.2091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10940",
    WardLookupId: "1420",
    VDNumber: "43691610",
    RegisteredPopulation: "901",
    VotingStationName: "AMASWAZI TRIBAL COURT",
    Address:
      "(KZN235 - OKHAHLAMBA [BERGVILLE])   KWASIMAHLA EMASWAZINI OKHAHLAMBA/BERGVILLE-KZN-235",
    Latitude: "-28.9285",
    Longitude: "29.4454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10941",
    WardLookupId: "1420",
    VDNumber: "43691799",
    RegisteredPopulation: "935",
    VotingStationName: "NOMTSHILO COMBINED PRIMARY SCHOOL",
    Address: "ESTULWANE  EMMAUSE  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.9",
    Longitude: "29.362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10942",
    WardLookupId: "1420",
    VDNumber: "43691801",
    RegisteredPopulation: "703",
    VotingStationName: "ISUBUKO HIGH SCHOOL",
    Address: "EMAGANGANGOZI  EMMAUS  OKHAHLAMBA BERGVILLE DISTRICT",
    Latitude: "-28.8981",
    Longitude: "29.3101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10943",
    WardLookupId: "1420",
    VDNumber: "43692576",
    RegisteredPopulation: "664",
    VotingStationName: "VIMBUKHALO PRIMARY SCHOOL",
    Address: "BERGVILLE  AMANGWANE  T/C",
    Latitude: "-28.8912",
    Longitude: "29.4038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10944",
    WardLookupId: "1420",
    VDNumber: "43692644",
    RegisteredPopulation: "309",
    VotingStationName: "IMPIMBO/MTHUKWANA PRIMARY SCHOOL",
    Address: "BRANDKRAAL FARM  IMPIMBO,BRANDKRAAL FARM  BERGVILLE",
    Latitude: "-28.8711",
    Longitude: "29.4589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10945",
    WardLookupId: "1420",
    VDNumber: "43710016",
    RegisteredPopulation: "712",
    VotingStationName: "CATHKIN PARK TLC OFFICES",
    Address: "R600  CATHKIN PARK  BERGVILLE",
    Latitude: "-28.9899",
    Longitude: "29.4745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10946",
    WardLookupId: "1421",
    VDNumber: "43691137",
    RegisteredPopulation: "1402",
    VotingStationName: "JABULANI PRIMARY SCHOOL",
    Address: "BETHANI AREA  EBHETHANI  BERGVILLE DISTRICT",
    Latitude: "-28.6793",
    Longitude: "29.3457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10947",
    WardLookupId: "1421",
    VDNumber: "43691160",
    RegisteredPopulation: "742",
    VotingStationName: "BHETHANI COMMUNITY CARE CENTRE",
    Address: "BHETHANI NEXT TO MOONLITE   BHETHANI  BERGVILLE",
    Latitude: "-28.6772",
    Longitude: "29.34",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10948",
    WardLookupId: "1421",
    VDNumber: "43691205",
    RegisteredPopulation: "732",
    VotingStationName: "EBHETHANI LOWER PRIMARY SCHOOL",
    Address: "BERGVILLE  EBHETHANI  BERGVILLE DISTRICT",
    Latitude: "-28.6817",
    Longitude: "29.3203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10949",
    WardLookupId: "1421",
    VDNumber: "43691261",
    RegisteredPopulation: "575",
    VotingStationName: "THOKOZA SECONDARY SCHOOL",
    Address: "KWAGUDLINTABA  EBHETANI  BERGVILLE DISTRICT",
    Latitude: "-28.6928",
    Longitude: "29.3232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10950",
    WardLookupId: "3603",
    VDNumber: "43692026",
    RegisteredPopulation: "535",
    VotingStationName: "EMADOLOBHENI PRIMARY SCHOOL",
    Address: "EMADOLOBHENI LOCATION  LOSKOP  ESTCOURT",
    Latitude: "-29.0335",
    Longitude: "29.5199",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10951",
    WardLookupId: "3603",
    VDNumber: "43692048",
    RegisteredPopulation: "537",
    VotingStationName: "MPUMUZA COMBINED PRIMARY SCHOOL",
    Address: "EMNYANGWENI LOCATION  LOSKOP  ESTCOURT",
    Latitude: "-29.0362",
    Longitude: "29.5743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10952",
    WardLookupId: "3603",
    VDNumber: "43692059",
    RegisteredPopulation: "750",
    VotingStationName: "ITHUBELISHA HIGH SCHOOL",
    Address: "ENGODINI LOCATION  LOSKOP  ESTCOURT",
    Latitude: "-29.0521",
    Longitude: "29.5337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10953",
    WardLookupId: "3603",
    VDNumber: "43692060",
    RegisteredPopulation: "580",
    VotingStationName: "SINGAYO IMFUNDO PRIMARY SCHOOL",
    Address: "KWASLIMANGAMEHLO LOCATION  LOSKOP  ESTCOURT",
    Latitude: "-29.0624",
    Longitude: "29.5128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10954",
    WardLookupId: "3603",
    VDNumber: "43692105",
    RegisteredPopulation: "738",
    VotingStationName: "EQHWENI COMBINED PRIMARY SCHOOL",
    Address: "ENGODINI LOCATION  LOSKOP  ESTCOURT",
    Latitude: "-29.0696",
    Longitude: "29.5172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10955",
    WardLookupId: "3603",
    VDNumber: "43693342",
    RegisteredPopulation: "635",
    VotingStationName: "MOUNTAINVIEW PRIMARY SCHOOL",
    Address: "KWAVALA  EMANGDABENI  AMANGWE TRIBAL AUTHORITY",
    Latitude: "-29.0144",
    Longitude: "29.5236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10956",
    WardLookupId: "3604",
    VDNumber: "43691935",
    RegisteredPopulation: "1804",
    VotingStationName: "BONOKUHLE HIGH SCHOOL",
    Address: "EMOYENI LOCATION  LOSKOP  ESTCOURT",
    Latitude: "-28.9426",
    Longitude: "29.5596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10957",
    WardLookupId: "3604",
    VDNumber: "43691979",
    RegisteredPopulation: "962",
    VotingStationName: "SIZATHINA HIGH SCHOOL",
    Address: "EMANDABENI LOCATION  LOSKOP  ETSCOURT",
    Latitude: "-28.9874",
    Longitude: "29.5472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10958",
    WardLookupId: "3604",
    VDNumber: "43692004",
    RegisteredPopulation: "458",
    VotingStationName: "ZUZIMFUNDO COMBINED PRIMARY SCHOOL",
    Address: "KWAVALA LOCATION  LOSKOP  ESTCOURT",
    Latitude: "-29.0047",
    Longitude: "29.537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10959",
    WardLookupId: "3604",
    VDNumber: "43692846",
    RegisteredPopulation: "1011",
    VotingStationName: "CHOTHWANE PRIMARY SCHOOL",
    Address: "EMOYENI LOCATION  LOSKOP  ESTCOURT",
    Latitude: "-28.9574",
    Longitude: "29.5459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10960",
    WardLookupId: "3605",
    VDNumber: "43691924",
    RegisteredPopulation: "1061",
    VotingStationName: "AMANGWE TRADITIONAL COUNCIL",
    Address: "LOSKOP  NKOMOKAZINI AREA  AMANGWE TAC",
    Latitude: "-28.9393",
    Longitude: "29.583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10961",
    WardLookupId: "3605",
    VDNumber: "43691957",
    RegisteredPopulation: "704",
    VotingStationName: "USIZOLWETHU COMBINED PRIMARY SCHOOL",
    Address: "NKOMOKAZINI LOCATION  LOSKOP  ESTCOURT",
    Latitude: "-28.9717",
    Longitude: "29.5886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10962",
    WardLookupId: "3605",
    VDNumber: "43691980",
    RegisteredPopulation: "995",
    VotingStationName: "LUKHAZI PRIMARY SCHOOL",
    Address: "ENYEZANE LOCATION  LOSKOP  ESTCOURT",
    Latitude: "-28.9974",
    Longitude: "29.5786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10963",
    WardLookupId: "3605",
    VDNumber: "43692015",
    RegisteredPopulation: "652",
    VotingStationName: "NJEZA HIGH SCHOOL",
    Address: "EMAKEKENI LOCATION  LOSKOP  ESTCOURT",
    Latitude: "-29.0196",
    Longitude: "29.5603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10964",
    WardLookupId: "3605",
    VDNumber: "43693331",
    RegisteredPopulation: "445",
    VotingStationName: "ENGONYAMENI TENT",
    Address: "ENGONYAMENI LOCATION  LOSKOP  AMANGWE TRIBAL AUTHORITY",
    Latitude: "-28.9637",
    Longitude: "29.6136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10965",
    WardLookupId: "3606",
    VDNumber: "43691834",
    RegisteredPopulation: "1518",
    VotingStationName: "GOURTON BRIDGE COMBINED PRIMARY SCHOOL",
    Address: "ENKOMOKAZINI LOCATION  LOSKOP  ESTCOURT",
    Latitude: "-28.926",
    Longitude: "29.5922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10966",
    WardLookupId: "3606",
    VDNumber: "43691856",
    RegisteredPopulation: "1492",
    VotingStationName: "PHASIWE COMBINED PRIMARY SCHOOL",
    Address: "MQEDANDABA LOCATION  LOSKOP  ESTCOURT",
    Latitude: "-28.9259",
    Longitude: "29.6106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10967",
    WardLookupId: "3606",
    VDNumber: "43691867",
    RegisteredPopulation: "1165",
    VotingStationName: "NGIBONGELENI HIGH SCHOOL",
    Address: "MQEDANDABA LOCATION  LOSKOP  ESTCOURT",
    Latitude: "-28.9291",
    Longitude: "29.6212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10968",
    WardLookupId: "3607",
    VDNumber: "43691878",
    RegisteredPopulation: "1969",
    VotingStationName: "GEZA PRIMARY SCHOOL",
    Address: "ETATANE LOCATION  LOSKOP  ESTCOURT",
    Latitude: "-28.9411",
    Longitude: "29.6416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10969",
    WardLookupId: "3607",
    VDNumber: "43691946",
    RegisteredPopulation: "1581",
    VotingStationName: "PHUMALANGA PRIMARY SCHOOL",
    Address: "ENGONYAMENI LOCATION  LOSKOP  ESTCOURT",
    Latitude: "-28.9546",
    Longitude: "29.6213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10970",
    WardLookupId: "3607",
    VDNumber: "43693072",
    RegisteredPopulation: "394",
    VotingStationName: "MAHLUBIMANGWE PRIMARY SCHOOL",
    Address: "EKUPHUMULENI LOCATION  LOSKOP  ESTCOURT",
    Latitude: "-28.8787",
    Longitude: "29.6347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10971",
    WardLookupId: "3607",
    VDNumber: "43693386",
    RegisteredPopulation: "215",
    VotingStationName: "MPUMELELO HIGH SCHOOL",
    Address: "ENGONYAMENI  LOSKOP  ESTCOURT",
    Latitude: "-28.9575",
    Longitude: "29.6294",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10972",
    WardLookupId: "3608",
    VDNumber: "43691889",
    RegisteredPopulation: "1939",
    VotingStationName: "BHEKUZULU PRIMARY SCHOOL",
    Address: "BHEKUZULU LOCATION  DRYCOTT  ESTCOURT",
    Latitude: "-28.9856",
    Longitude: "29.6846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10973",
    WardLookupId: "3608",
    VDNumber: "43691968",
    RegisteredPopulation: "911",
    VotingStationName: "KWALOMODE HALL",
    Address: "KWALOMODE LOCATION  KWALOMODE, BHEKUZULU  ESTCOURT",
    Latitude: "-28.9921",
    Longitude: "29.6704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10974",
    WardLookupId: "3608",
    VDNumber: "43692857",
    RegisteredPopulation: "1255",
    VotingStationName: "PHUMELELANI PRIMARY SCHOOL",
    Address: "ROSEDALE LOCATION  ROSEDALE, LOSKOP  ESTCOURT",
    Latitude: "-28.9623",
    Longitude: "29.6609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10975",
    WardLookupId: "3608",
    VDNumber: "43693162",
    RegisteredPopulation: "943",
    VotingStationName: "EPHANGWENI COMMUNITY HALL",
    Address: "ESAYINI AREA  EPHANGWENI LOCATION  ESTCOURT",
    Latitude: "-28.9686",
    Longitude: "29.7117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10976",
    WardLookupId: "3608",
    VDNumber: "43693397",
    RegisteredPopulation: "403",
    VotingStationName: "TATAZELA HIGH SCHOOL",
    Address: "BHEKUZULU LOCATION  DRYCOTT  ESTCOURT",
    Latitude: "-28.9775",
    Longitude: "29.692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10977",
    WardLookupId: "3609",
    VDNumber: "43691991",
    RegisteredPopulation: "157",
    VotingStationName: "ZIMELE PRIMARY SCHOOL",
    Address: "ENHLALAKAHLE, KWAMSHINI   NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.1051",
    Longitude: "29.6056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10978",
    WardLookupId: "3609",
    VDNumber: "43692149",
    RegisteredPopulation: "1516",
    VotingStationName: "EDILINI COMBINED PRIMARY SCHOOL",
    Address: "GOODHOME LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.0787",
    Longitude: "29.6689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10979",
    WardLookupId: "3609",
    VDNumber: "43692161",
    RegisteredPopulation: "1151",
    VotingStationName: "AFRICAN GOSPEL CHURCH GOODHOME",
    Address: "GOODHOME LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.0952",
    Longitude: "29.6685",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10980",
    WardLookupId: "3609",
    VDNumber: "43692183",
    RegisteredPopulation: "846",
    VotingStationName: "ESTCOURT SENIOR SECONDARY SCHOOL",
    Address: "DUTCH LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.1037",
    Longitude: "29.6682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10981",
    WardLookupId: "3609",
    VDNumber: "43692206",
    RegisteredPopulation: "556",
    VotingStationName: "BAMBE COMBINED PRIMARY SCHOOL",
    Address: "DUTCH LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.1035",
    Longitude: "29.6586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10982",
    WardLookupId: "3609",
    VDNumber: "43692789",
    RegisteredPopulation: "177",
    VotingStationName: "EPHAWA TENT",
    Address: "EPHAWA LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.149",
    Longitude: "29.6122",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10983",
    WardLookupId: "3610",
    VDNumber: "43730029",
    RegisteredPopulation: "1733",
    VotingStationName: "ZAMUKUTHULA PRIMARY SCHOOL",
    Address: "SECTION C  WEMBEZI  SECTION C",
    Latitude: "-29.0393",
    Longitude: "29.771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10984",
    WardLookupId: "3610",
    VDNumber: "43730085",
    RegisteredPopulation: "805",
    VotingStationName: "ZOLA PRIMARY SCHOOL",
    Address: "B 392 LONGHOMES  WEMBEZI  ESTCOURT",
    Latitude: "-29.0396",
    Longitude: "29.7888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10985",
    WardLookupId: "3610",
    VDNumber: "43730142",
    RegisteredPopulation: "713",
    VotingStationName: "D SECTION CRECHE",
    Address: "D SECTION  D SECTION, WEMBEZI TOWNSHIP  ESTCOURT",
    Latitude: "-29.0286",
    Longitude: "29.7722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10986",
    WardLookupId: "3610",
    VDNumber: "43730186",
    RegisteredPopulation: "1111",
    VotingStationName: "ZION CHURCH C SECTION",
    Address: "C SECTION  C SECTION WEMBEZI   ESTCOURT",
    Latitude: "-29.0438",
    Longitude: "29.7754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10987",
    WardLookupId: "3611",
    VDNumber: "43730030",
    RegisteredPopulation: "2002",
    VotingStationName: "WEMBEZI SECONDARY SCHOOL",
    Address: "A-SECTION  WEMBEZI  ESTCOURT",
    Latitude: "-29.0495",
    Longitude: "29.7862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10988",
    WardLookupId: "3611",
    VDNumber: "43730108",
    RegisteredPopulation: "1310",
    VotingStationName: "BHEKUNDI PRIMARY SCHOOL",
    Address: "NKWEZELA  WEMBEZI TOWN SHIP  UMTSHEZI",
    Latitude: "-29.0554",
    Longitude: "29.78",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10989",
    WardLookupId: "3611",
    VDNumber: "43730131",
    RegisteredPopulation: "1212",
    VotingStationName: "INKANYISO PRIMARY SCHOOL",
    Address: "VQ SECTION - WEMBEZI  WEMBEZI  ESTCOURT",
    Latitude: "-29.0542",
    Longitude: "29.7878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10990",
    WardLookupId: "3612",
    VDNumber: "43730052",
    RegisteredPopulation: "2081",
    VotingStationName: "FORDERVILLE COMMUNITY HALL",
    Address: "CANNA AVENUE  FORDERVILLE  ESTCOURT",
    Latitude: "-28.9953",
    Longitude: "29.87",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10991",
    WardLookupId: "3612",
    VDNumber: "43730063",
    RegisteredPopulation: "1274",
    VotingStationName: "ESTCOURT TOWN HALL",
    Address: "CORNER VICTORIA-HARDING STREET  ESTCOURT  ESTCOURT",
    Latitude: "-29.0088",
    Longitude: "29.8738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10992",
    WardLookupId: "3612",
    VDNumber: "43730175",
    RegisteredPopulation: "1161",
    VotingStationName: "COLITA HALL",
    Address: "COLITA MAIN ROAD  COLITA  ESTCOURT",
    Latitude: "-28.9929",
    Longitude: "29.8745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10993",
    WardLookupId: "3613",
    VDNumber: "43692037",
    RegisteredPopulation: "132",
    VotingStationName: "VULEKENI PRIMARY SCHOOL",
    Address: "KWASHALI  LOWLANDS  ESTCOURT",
    Latitude: "-29.1351",
    Longitude: "29.7922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10994",
    WardLookupId: "3613",
    VDNumber: "43692127",
    RegisteredPopulation: "361",
    VotingStationName: "INTSONGE PRIMARY SCHOOL",
    Address: "INSONGE  HLATHIKHULU  ESTCOURT",
    Latitude: "-29.2519",
    Longitude: "29.6694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10995",
    WardLookupId: "3613",
    VDNumber: "43692273",
    RegisteredPopulation: "1657",
    VotingStationName: "THIBANI LOWER PRIMARY SCHOOL",
    Address: "KWAMKHIZE LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.1972",
    Longitude: "29.6671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10996",
    WardLookupId: "3613",
    VDNumber: "43692284",
    RegisteredPopulation: "404",
    VotingStationName: "HLATHIKHULU HIGH SCHOOL",
    Address: "HLATHIKHULU LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.1929",
    Longitude: "29.647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10997",
    WardLookupId: "3613",
    VDNumber: "43692307",
    RegisteredPopulation: "1297",
    VotingStationName: "MANKONJANE PRIMARY SCHOOL",
    Address: "KWAMKHIZE  HLATHIKHULU  NTABAMHLOPHE/ESTCOURT",
    Latitude: "-29.211",
    Longitude: "29.6802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10998",
    WardLookupId: "3613",
    VDNumber: "43692329",
    RegisteredPopulation: "372",
    VotingStationName: "ZAMUKWAZI COMBINED PRIMARY SCHOOL",
    Address: "KWAMKHIZE  NTABAMHLOPHE  ESTCOURT/NTABAMHLOPHE",
    Latitude: "-29.2387",
    Longitude: "29.6467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "10999",
    WardLookupId: "3613",
    VDNumber: "43692835",
    RegisteredPopulation: "224",
    VotingStationName: "DALTON BRIDGE PRIMARY SCHOOL",
    Address: "DALTON LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.0905",
    Longitude: "29.8033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11000",
    WardLookupId: "3613",
    VDNumber: "43692958",
    RegisteredPopulation: "108",
    VotingStationName: "BEACON HILL TENT",
    Address: "NEXT TO LOWLANDS MAIN ROAD  LOWLANDS  ESTCOURT",
    Latitude: "-29.0618",
    Longitude: "29.8806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11001",
    WardLookupId: "3613",
    VDNumber: "43692970",
    RegisteredPopulation: "101",
    VotingStationName: "STOCKTON PRIMARY SCHOOL",
    Address: "LOWLANDS  ESTCOURT",
    Latitude: "-29.108",
    Longitude: "29.8981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11002",
    WardLookupId: "3614",
    VDNumber: "43692228",
    RegisteredPopulation: "464",
    VotingStationName: "EMANJOKWENI PRIMARY SCHOOL",
    Address: "EMANJOKWENI LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.1518",
    Longitude: "29.593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11003",
    WardLookupId: "3614",
    VDNumber: "43692240",
    RegisteredPopulation: "941",
    VotingStationName: "NTABAMHLOPHE COMBINED PRIMARY SCHOOL",
    Address: "KWADLAMINI LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.1597",
    Longitude: "29.6486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11004",
    WardLookupId: "3614",
    VDNumber: "43692262",
    RegisteredPopulation: "912",
    VotingStationName: "ENHLANGANISWENI COMBINED PRIMARY SCHOOL",
    Address: "EMAHLUTSHINI LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.1978",
    Longitude: "29.6076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11005",
    WardLookupId: "3614",
    VDNumber: "43692295",
    RegisteredPopulation: "615",
    VotingStationName: "BHUNGANE HIGH SCHOOL",
    Address: "EMAHLUTSHINI LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.1985",
    Longitude: "29.622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11006",
    WardLookupId: "3614",
    VDNumber: "43692318",
    RegisteredPopulation: "295",
    VotingStationName: "NCIBIDWANE HIGHER PRIMARY SCHOOL",
    Address: "EMAHENDENI  HLATHIKHULU  NTABAMHLOPHE/ESTCOURT",
    Latitude: "-29.2325",
    Longitude: "29.6288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11007",
    WardLookupId: "3614",
    VDNumber: "43692790",
    RegisteredPopulation: "743",
    VotingStationName: "AME CHURCH KWADLAMINI",
    Address: "KWADLAMINI LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.1662",
    Longitude: "29.6378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11008",
    WardLookupId: "3615",
    VDNumber: "43692194",
    RegisteredPopulation: "1835",
    VotingStationName: "EMTSHEZI HIGH SCHOOL",
    Address: "ENKUNZINI LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.1123",
    Longitude: "29.6896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11009",
    WardLookupId: "3615",
    VDNumber: "43692217",
    RegisteredPopulation: "1115",
    VotingStationName: "PISGA COMBINED PRIMARY SCHOOL",
    Address: "EZINYOSINI LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.1314",
    Longitude: "29.6812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11010",
    WardLookupId: "3615",
    VDNumber: "43692239",
    RegisteredPopulation: "882",
    VotingStationName: "MHLUNGWINI PRIMARY SCHOOL",
    Address: "KWANDABA LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.1582",
    Longitude: "29.6974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11011",
    WardLookupId: "3615",
    VDNumber: "43692420",
    RegisteredPopulation: "666",
    VotingStationName: "BHEKATHINA HIGH SCHOOL",
    Address: "EMVUNDLWENI LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.1406",
    Longitude: "29.7001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11012",
    WardLookupId: "3616",
    VDNumber: "43692071",
    RegisteredPopulation: "1164",
    VotingStationName: "THEMBA PRIMARY SCHOOL",
    Address: "SOBABILI LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.0786",
    Longitude: "29.6916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11013",
    WardLookupId: "3616",
    VDNumber: "43692116",
    RegisteredPopulation: "1298",
    VotingStationName: "MATHAMO COMBINED PRIMARY SCHOOL",
    Address: "EMDWEBU LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.0916",
    Longitude: "29.7114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11014",
    WardLookupId: "3616",
    VDNumber: "43692802",
    RegisteredPopulation: "596",
    VotingStationName: "SQALOKUSHA PRIMARY SCHOOL",
    Address: "PHESHEYAKWAMATHAMO, SOBABILI   NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.059",
    Longitude: "29.6921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11015",
    WardLookupId: "3616",
    VDNumber: "43693364",
    RegisteredPopulation: "314",
    VotingStationName: "GOODHOME TENT",
    Address: "GOODHOME  MHLUNGWINI TRIBAL AUTHORITY",
    Latitude: "-29.0779",
    Longitude: "29.6746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11016",
    WardLookupId: "3617",
    VDNumber: "43692082",
    RegisteredPopulation: "564",
    VotingStationName: "KHANDIMFUNDO HIGH SCHOOL",
    Address: "NEWLAND LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.0716",
    Longitude: "29.7571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11017",
    WardLookupId: "3617",
    VDNumber: "43692093",
    RegisteredPopulation: "1726",
    VotingStationName: "EMTHONJENIWOLWAZI HIGH SCHOOL",
    Address: "ZWELISHA LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.0529",
    Longitude: "29.7357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11018",
    WardLookupId: "3617",
    VDNumber: "43692655",
    RegisteredPopulation: "902",
    VotingStationName: "SIZAMELENI PRIMARY SCHOOL",
    Address: "LOCHSLOY LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.0669",
    Longitude: "29.7633",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11019",
    WardLookupId: "3617",
    VDNumber: "43692813",
    RegisteredPopulation: "828",
    VotingStationName: "ZWELISHA ANGLICAN CHURCH",
    Address: "ZWELISHA LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.0592",
    Longitude: "29.7402",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11020",
    WardLookupId: "3618",
    VDNumber: "43692138",
    RegisteredPopulation: "1024",
    VotingStationName: "EMUHUBHENI HIGH SCHOOL",
    Address: "EMHUBHENI LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.0832",
    Longitude: "29.7916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11021",
    WardLookupId: "3618",
    VDNumber: "43692150",
    RegisteredPopulation: "1379",
    VotingStationName: "MABASO LOWER PRIMARY SCHOOL",
    Address: "EBHOSHI LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.0988",
    Longitude: "29.736",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11022",
    WardLookupId: "3618",
    VDNumber: "43692172",
    RegisteredPopulation: "1048",
    VotingStationName: "SAKHILE LOWER PRIMARY SCHOOL",
    Address: "SHAYAMOYA LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.1186",
    Longitude: "29.6993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11023",
    WardLookupId: "3618",
    VDNumber: "43692824",
    RegisteredPopulation: "926",
    VotingStationName: "SHAYAMOYA PRIMARY SCHOOL",
    Address: "SHAYAMOYA LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.1026",
    Longitude: "29.7173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11024",
    WardLookupId: "3618",
    VDNumber: "43693151",
    RegisteredPopulation: "206",
    VotingStationName: "EZINDIKINI CRECHE",
    Address: "EZINDIKINI LOCATION  NTABAMHLOPHE  ESTCOURT",
    Latitude: "-29.0955",
    Longitude: "29.7756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11025",
    WardLookupId: "3619",
    VDNumber: "43730018",
    RegisteredPopulation: "2004",
    VotingStationName: "MAHASHINI PRIMARY SCHOOL",
    Address: "MAHHASHINI  WEMBEZI  ESTCOURT",
    Latitude: "-29.0485",
    Longitude: "29.7925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11026",
    WardLookupId: "3619",
    VDNumber: "43730041",
    RegisteredPopulation: "1926",
    VotingStationName: "ESTCOURT HIGH SCHOOL",
    Address: "CNR MARTIN AND HELLET ROADS  ESTCOURT  ESTCOURT",
    Latitude: "-29.007",
    Longitude: "29.8656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11027",
    WardLookupId: "3620",
    VDNumber: "43691665",
    RegisteredPopulation: "1506",
    VotingStationName: "RIVERDALE PRIMARY SCHOOL",
    Address: "ESIGODLWENI  RENSBURG  ESTCOURT",
    Latitude: "-28.9793",
    Longitude: "29.8934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11028",
    WardLookupId: "3620",
    VDNumber: "43691890",
    RegisteredPopulation: "512",
    VotingStationName: "AMAHLUBI HIGH SCHOOL",
    Address: "EPHANGWENI LOCATION  EPHANGWENI/ LOSKOP  ESTCOURT",
    Latitude: "-28.9786",
    Longitude: "29.7116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11029",
    WardLookupId: "3620",
    VDNumber: "43692554",
    RegisteredPopulation: "268",
    VotingStationName: "ENNERSDALE SHOP",
    Address: "ENNERSDALE   ENNERSDALE, LOSKOP  ESTCOURT",
    Latitude: "-28.9859",
    Longitude: "29.7772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11030",
    WardLookupId: "3620",
    VDNumber: "43692947",
    RegisteredPopulation: "702",
    VotingStationName: "FREERE COMMUNITY HALL",
    Address: "R103 TO COLENSO  FREERE  ESTCOURT",
    Latitude: "-28.8914",
    Longitude: "29.7655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11031",
    WardLookupId: "3620",
    VDNumber: "43692981",
    RegisteredPopulation: "200",
    VotingStationName: "EMADELENI TENT",
    Address: "JACKIES ABBATOIR  EMADELENI  ESTCOURT",
    Latitude: "-28.9566",
    Longitude: "29.8573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11032",
    WardLookupId: "3620",
    VDNumber: "43693409",
    RegisteredPopulation: "239",
    VotingStationName: "IKHAYA LOMKHULEKO CHURCH",
    Address: "ESIGODLWENI  ESTCOURT",
    Latitude: "-28.9577",
    Longitude: "29.8782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11033",
    WardLookupId: "3620",
    VDNumber: "43730120",
    RegisteredPopulation: "1139",
    VotingStationName: "PAAPKUILSFONTEIN COMMUNITY HALL",
    Address: "BALSAM ROAD  PAPKUILSFONTEIN  ESTCOURT",
    Latitude: "-28.9816",
    Longitude: "29.8613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11034",
    WardLookupId: "3621",
    VDNumber: "43691362",
    RegisteredPopulation: "288",
    VotingStationName: "CHIVELEY CRECHE",
    Address: "CHIEVELY  COLENSO  ESTCOURT",
    Latitude: "-28.8324",
    Longitude: "29.8074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11035",
    WardLookupId: "3621",
    VDNumber: "43691733",
    RegisteredPopulation: "856",
    VotingStationName: "VUKASEKUSILE HIGH SCHOOL",
    Address: "CORNFIELDS  ESTCOURT",
    Latitude: "-28.8694",
    Longitude: "29.8661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11036",
    WardLookupId: "3621",
    VDNumber: "43691755",
    RegisteredPopulation: "861",
    VotingStationName: "THEMBALIHLE PRIMARY SCHOOL",
    Address: "R103 WEENEN  THEMBALIHLE  ESTCOURT",
    Latitude: "-28.8816",
    Longitude: "29.9159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11037",
    WardLookupId: "3621",
    VDNumber: "43692374",
    RegisteredPopulation: "372",
    VotingStationName: "INDULWANA PRIMARY SCHOOL",
    Address: "EKUTHULENI  COLENSO",
    Latitude: "-28.7742",
    Longitude: "29.8471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11038",
    WardLookupId: "3621",
    VDNumber: "43692767",
    RegisteredPopulation: "1204",
    VotingStationName: "CORNFIELD PRIMARY SCHOOL",
    Address: "SHIYABAZALI  CORNFIELDS  ESTCOURT",
    Latitude: "-28.8545",
    Longitude: "29.8616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11039",
    WardLookupId: "3621",
    VDNumber: "43693061",
    RegisteredPopulation: "153",
    VotingStationName: "TEMPORARY VOTING STATION (CLOUSTON FARM KRAAL)TENT",
    Address:
      "KZN234 - UMTSHEZI [ESTCOURT]  COLENSO  KZN234 - UMTSHEZI [ESTCOURT]",
    Latitude: "-28.7506",
    Longitude: "29.8162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11040",
    WardLookupId: "3622",
    VDNumber: "43691036",
    RegisteredPopulation: "640",
    VotingStationName: "GANNAHOEK PRIMARY SCHOOL",
    Address: "KWASHAMENI  GANNAHOEK  COLENSO",
    Latitude: "-28.7218",
    Longitude: "29.9664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11041",
    WardLookupId: "3622",
    VDNumber: "43691531",
    RegisteredPopulation: "264",
    VotingStationName: "MVUBU AIDED PRIMARY SCHOOL",
    Address: "R74 WEENEN  MVUBU  WEENEN",
    Latitude: "-28.7956",
    Longitude: "29.9527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11042",
    WardLookupId: "3622",
    VDNumber: "43692756",
    RegisteredPopulation: "411",
    VotingStationName: "KOPLEEGTE PRIMARY SCHOOL",
    Address: "MILTON FARM  THEMBALIHLE - FRANCE  ESTCOURT",
    Latitude: "-28.8706",
    Longitude: "29.9474",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11043",
    WardLookupId: "3622",
    VDNumber: "43692936",
    RegisteredPopulation: "860",
    VotingStationName: "THANDANANI PRIMARY SCHOOL",
    Address: "NGODINI  ENGODINI  WEENEN",
    Latitude: "-28.8177",
    Longitude: "30.0373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11044",
    WardLookupId: "3622",
    VDNumber: "43693094",
    RegisteredPopulation: "505",
    VotingStationName: "MORNING STAR PRIMARY SCHOOL",
    Address: "R74 COLENSO  EKUTHULENI  COLENSO",
    Latitude: "-28.7786",
    Longitude: "29.8849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11045",
    WardLookupId: "3622",
    VDNumber: "43750010",
    RegisteredPopulation: "654",
    VotingStationName: "WEENEN TOWN HALL",
    Address: "ANDRIES PRETORIUS STREET  WEENEN  WEENEN",
    Latitude: "-28.8542",
    Longitude: "30.0729",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11046",
    WardLookupId: "3622",
    VDNumber: "43750032",
    RegisteredPopulation: "628",
    VotingStationName: "WEENEN COMBINED SCHOOL",
    Address: "EZITENDENI  WEENEN",
    Latitude: "-28.8464",
    Longitude: "30.0981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11047",
    WardLookupId: "3623",
    VDNumber: "43691744",
    RegisteredPopulation: "1188",
    VotingStationName: "MTHANIYA COMBINED SCHOOL",
    Address: "MNGWENYA  MTHANIYA  WEENEN",
    Latitude: "-28.911",
    Longitude: "30.0823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11048",
    WardLookupId: "3623",
    VDNumber: "43691913",
    RegisteredPopulation: "226",
    VotingStationName: "MNGWENYA PRIMARY SCHOOL",
    Address: "MNGWENYA AREA NEXT KWASITHOLE  MNGWENYA  ESTCOURT",
    Latitude: "-28.98",
    Longitude: "30.0791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11049",
    WardLookupId: "3623",
    VDNumber: "43692701",
    RegisteredPopulation: "226",
    VotingStationName: "SELBOURNE PRIMARY SCHOOL",
    Address: "SELBOURNE  WEENEN",
    Latitude: "-28.9352",
    Longitude: "30.0499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11050",
    WardLookupId: "3623",
    VDNumber: "43693241",
    RegisteredPopulation: "230",
    VotingStationName: "MKHOLOMBE PRIMARY SCHOOL",
    Address: "MKHOLOMBE  WEENEN",
    Latitude: "-28.8963",
    Longitude: "30.1085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11051",
    WardLookupId: "3623",
    VDNumber: "43750021",
    RegisteredPopulation: "1721",
    VotingStationName: "EZITENDENI COMMUNITY HALL",
    Address: "EZITENDENI  WEENEN",
    Latitude: "-28.8416",
    Longitude: "30.1108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11052",
    WardLookupId: "3623",
    VDNumber: "43750043",
    RegisteredPopulation: "341",
    VotingStationName: "FERDINAND PRIMARY SCHOOL",
    Address: "NEXT TO MUDEN ROAD  NHLIWE  WEENEN",
    Latitude: "-28.8622",
    Longitude: "30.0853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11053",
    WardLookupId: "3624",
    VDNumber: "43691463",
    RegisteredPopulation: "604",
    VotingStationName: "NHLAWE PRIMARY SCHOOL",
    Address: "NHLAWE  WEENEN",
    Latitude: "-28.7815",
    Longitude: "30.0502",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11054",
    WardLookupId: "3624",
    VDNumber: "43691496",
    RegisteredPopulation: "538",
    VotingStationName: "NKASENI PRIMARY SCHOOL",
    Address: "NKASENI  WEENEN",
    Latitude: "-28.7655",
    Longitude: "30.1898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11055",
    WardLookupId: "3624",
    VDNumber: "43691632",
    RegisteredPopulation: "430",
    VotingStationName: "KWENZOKUHLE PRIMARY SCHOOL",
    Address: "NEXT TO THE ROAD TO MUDEN  EMHLUMBA  WEENEN",
    Latitude: "-28.9254",
    Longitude: "30.2458",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11056",
    WardLookupId: "3624",
    VDNumber: "43691845",
    RegisteredPopulation: "514",
    VotingStationName: "FLORENCE BOOTH PRIMARY SCHOOL",
    Address: "EMNGWENYA  WEENEN",
    Latitude: "-28.9563",
    Longitude: "30.1344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11057",
    WardLookupId: "3624",
    VDNumber: "43692699",
    RegisteredPopulation: "363",
    VotingStationName: "NCUNJANA PRIMARY SCHOOL",
    Address: "NCUNJANA  WEENEN",
    Latitude: "-28.786",
    Longitude: "30.2567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11058",
    WardLookupId: "3624",
    VDNumber: "43692778",
    RegisteredPopulation: "691",
    VotingStationName: "CECELIA PRIMARY SCHOOL",
    Address: "BUSANI AREA  WEENEN  ESTCOURT",
    Latitude: "-28.9044",
    Longitude: "30.1706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11059",
    WardLookupId: "3624",
    VDNumber: "43692925",
    RegisteredPopulation: "474",
    VotingStationName: "HORSE SHOE PRIMARY SCHOOL",
    Address: "R74 WEENEN - GREYTOWN  WEENEN  WEENEN",
    Latitude: "-28.8585",
    Longitude: "30.1455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11060",
    WardLookupId: "3624",
    VDNumber: "43693083",
    RegisteredPopulation: "126",
    VotingStationName: "ENHLANGWINI COMBINED PRIMARY SCHOOL",
    Address: "ENHLANGWINI AREA  WEENEN   WEENEN",
    Latitude: "-28.966",
    Longitude: "30.2219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11061",
    WardLookupId: "3624",
    VDNumber: "43693106",
    RegisteredPopulation: "245",
    VotingStationName: "KWAMAKHONYA",
    Address: "NCUNJANA  WEENEN",
    Latitude: "-28.7348",
    Longitude: "30.2569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11062",
    WardLookupId: "3624",
    VDNumber: "43693353",
    RegisteredPopulation: "363",
    VotingStationName: "SIYAPHAKAMA PRIMARY SCHOOL",
    Address: "SUNNYVALE  SUNNYVALE, WEENEN  ESTCOURT",
    Latitude: "-28.7874",
    Longitude: "30.1016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11063",
    WardLookupId: "3624",
    VDNumber: "43693410",
    RegisteredPopulation: "266",
    VotingStationName: "EMADULANENI CRECHE",
    Address: "EMADULANENI AREA  WEENEN",
    Latitude: "-28.8661",
    Longitude: "30.2016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11064",
    WardLookupId: "3625",
    VDNumber: "43692543",
    RegisteredPopulation: "269",
    VotingStationName: "ISLAMIC CENTRE",
    Address: "MTSHEZANA  KWAMATSHESI  ESTCOURT",
    Latitude: "-28.9799",
    Longitude: "29.9369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11065",
    WardLookupId: "3625",
    VDNumber: "43692712",
    RegisteredPopulation: "181",
    VotingStationName: "BRYNBELLA PRIMARY SCHOOL",
    Address: "BRYNBELLA  LOWLANDS  ESTCOURT",
    Latitude: "-29.1026",
    Longitude: "29.9444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11066",
    WardLookupId: "3625",
    VDNumber: "43692969",
    RegisteredPopulation: "241",
    VotingStationName: "NTUNDA CRECHE",
    Address: "NTUNDA  WILLOGRANGE  ESTCOURT",
    Latitude: "-29.045",
    Longitude: "29.977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11067",
    WardLookupId: "3625",
    VDNumber: "43730119",
    RegisteredPopulation: "1468",
    VotingStationName: "LYNDHURST PRIMARY SCHOOL",
    Address: "KEMPS ROAD  MSHAYAZAFE  ESTCOURT",
    Latitude: "-29.0154",
    Longitude: "29.9052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11068",
    WardLookupId: "3625",
    VDNumber: "43730164",
    RegisteredPopulation: "2151",
    VotingStationName: "CELUKUPHIWA COMBINED SCHOOL",
    Address: "EMABHALONINI  MIMOSADALE  ESTCOURT",
    Latitude: "-29.0386",
    Longitude: "29.9131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11069",
    WardLookupId: "3625",
    VDNumber: "43730197",
    RegisteredPopulation: "410",
    VotingStationName: "NEW MIMOSADALE COMMUNITY HALL",
    Address: "EMABHALONINI LOCATION  ESTCOURT",
    Latitude: "-29.0382",
    Longitude: "29.9173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11070",
    WardLookupId: "1422",
    VDNumber: "43740187",
    RegisteredPopulation: "2710",
    VotingStationName: "FALETHU LOWER PRIMARY SCHOOL",
    Address: "C1966 EZAKHENI  EZAKHENI  LADYSMITH",
    Latitude: "-28.6244",
    Longitude: "29.9215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11071",
    WardLookupId: "1422",
    VDNumber: "43740211",
    RegisteredPopulation: "1139",
    VotingStationName: "METHODIST CHURCH",
    Address: "D206  EMBONGOLWENI, EZAKHENI  LADYSMITH",
    Latitude: "-28.6191",
    Longitude: "29.9295",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11072",
    WardLookupId: "1422",
    VDNumber: "43740288",
    RegisteredPopulation: "555",
    VotingStationName: "SAMUKELWE CRECHE",
    Address: "D1934  EZAKHENI  LADYSMITH",
    Latitude: "-28.6093",
    Longitude: "29.9287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11073",
    WardLookupId: "1423",
    VDNumber: "43740222",
    RegisteredPopulation: "2179",
    VotingStationName: "GQAMA SENIOR PRIMARY SCHOOL",
    Address: "A1114-NEXT TO EZAKHENI LIBRAR  EZAKHENI A-SECTION   LADYSMITH",
    Latitude: "-28.5993",
    Longitude: "29.9295",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11074",
    WardLookupId: "1423",
    VDNumber: "43740233",
    RegisteredPopulation: "2132",
    VotingStationName: "EZAKHENI HIGH SCHOOL",
    Address: "A551- A -SECTION   EZAKHENI  LADYSMITH",
    Latitude: "-28.6005",
    Longitude: "29.9253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11075",
    WardLookupId: "1424",
    VDNumber: "43690899",
    RegisteredPopulation: "434",
    VotingStationName: "THEMBUMZAMO SENIOR PRIMARY SCHOOL",
    Address: "ST`CHADS NEXT TO ST JOHNS  KWAMAZABILAYO  LADYSMITH",
    Latitude: "-28.6088",
    Longitude: "29.9076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11076",
    WardLookupId: "1424",
    VDNumber: "43740042",
    RegisteredPopulation: "1299",
    VotingStationName: "MCITSHENI JUNIOR PRIMARY SCHOOL",
    Address: "B1716  EZAKHENI, B- SECTION   LADYSMITH",
    Latitude: "-28.6084",
    Longitude: "29.9245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11077",
    WardLookupId: "1424",
    VDNumber: "43740198",
    RegisteredPopulation: "2449",
    VotingStationName: "EKUKHULENI HIGH SCHOOL",
    Address: "B2221  EZAKHENI  EZAKHENI",
    Latitude: "-28.6104",
    Longitude: "29.92",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11078",
    WardLookupId: "1424",
    VDNumber: "43740244",
    RegisteredPopulation: "724",
    VotingStationName: "COMMUNITY HALL EZAKHENI C SECTION",
    Address: "C734 UMVAVA ROAD  EZAKHENI C SECTION  LADYSMITH",
    Latitude: "-28.6181",
    Longitude: "29.9168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11079",
    WardLookupId: "1425",
    VDNumber: "43691003",
    RegisteredPopulation: "486",
    VotingStationName: "QINISA PRIMARY SCHOOL",
    Address: "ESIDAKENI AREA  LADYSMITH",
    Latitude: "-28.6643",
    Longitude: "29.9012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11080",
    WardLookupId: "1425",
    VDNumber: "43740200",
    RegisteredPopulation: "3097",
    VotingStationName: "GOODHOPE SENIOR PRIMARY SCHOOL",
    Address: "D602  EZAKHENI, D -SECTION   LADYSMITH",
    Latitude: "-28.6252",
    Longitude: "29.932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11081",
    WardLookupId: "1426",
    VDNumber: "43740165",
    RegisteredPopulation: "1271",
    VotingStationName: "METHODIST CHURCH",
    Address: "C5086 NSIZWA STREET, C-SECTION  EZAKHENI C -SECTION  LADYSMITH",
    Latitude: "-28.6212",
    Longitude: "29.9143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11082",
    WardLookupId: "1426",
    VDNumber: "43740176",
    RegisteredPopulation: "2596",
    VotingStationName: "ENDAKANE HIGH SCHOOL",
    Address: "C1 3992 MVELASE STREET  EZAKHENI  LADYSMITH",
    Latitude: "-28.6318",
    Longitude: "29.9078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11083",
    WardLookupId: "1426",
    VDNumber: "43740356",
    RegisteredPopulation: "499",
    VotingStationName: "EKUPHUMULENI PRIMARY SCHOOL",
    Address: "EKUPHUMULENI STREET   EZAKHENI   EZAKHENI",
    Latitude: "-28.6316",
    Longitude: "29.9152",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11084",
    WardLookupId: "1427",
    VDNumber: "43740109",
    RegisteredPopulation: "1801",
    VotingStationName: "METHODIST CHURCH",
    Address: "E2947  E- SECTION , EZAKHENI   LADYSMITH",
    Latitude: "-28.6267",
    Longitude: "29.886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11085",
    WardLookupId: "1427",
    VDNumber: "43740154",
    RegisteredPopulation: "1810",
    VotingStationName: "SESIKHONA PRIMARY SCHOOL",
    Address: "E286 MATHEBENI STREET  EZAKHENI  LADYSMITH",
    Latitude: "-28.634",
    Longitude: "29.8919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11086",
    WardLookupId: "1427",
    VDNumber: "43740301",
    RegisteredPopulation: "647",
    VotingStationName: "SAKHELWE HIGH SCHOOL",
    Address: "E 1874 NDLULAMITHI STREET  EZAKHENI E  LADYSMITH",
    Latitude: "-28.6328",
    Longitude: "29.8854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11087",
    WardLookupId: "1427",
    VDNumber: "43740367",
    RegisteredPopulation: "353",
    VotingStationName: "TVET COLLEGE -E SECTION",
    Address: "MANQWASHI  EZAKHENI E  EZAKHENI",
    Latitude: "-28.6294",
    Longitude: "29.8929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11088",
    WardLookupId: "1428",
    VDNumber: "43690721",
    RegisteredPopulation: "935",
    VotingStationName: "DUMISA PUBLIC SCHOOL",
    Address: "EMADAZANE  EMADAZANE OR SGODIPHOLA  AMANGWE TRIBAL AUTHORITY",
    Latitude: "-28.5576",
    Longitude: "30.0144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11089",
    WardLookupId: "1428",
    VDNumber: "43690833",
    RegisteredPopulation: "1789",
    VotingStationName: "MABHUMANE PRIMARY SCHOOL",
    Address: "ST CHADS HELPMEKAAR  ST CHADS AREA  LADYSMITH",
    Latitude: "-28.5693",
    Longitude: "29.9076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11090",
    WardLookupId: "1428",
    VDNumber: "43690912",
    RegisteredPopulation: "1461",
    VotingStationName: "NGCONGWANE COMBINED PRIMARY SCHOOL",
    Address:
      "EMCITSHENI AREA  EMCITSHENI- 3KM FROM HELPMEKAAR RD  EMNAMBITHI/LADYSMITH",
    Latitude: "-28.615",
    Longitude: "29.9554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11091",
    WardLookupId: "1428",
    VDNumber: "43693218",
    RegisteredPopulation: "712",
    VotingStationName: "KHULANSEVU CRECHE",
    Address: "ST CHADS NEXT TO ST CHADS HOSP  EZWELISHA  EZWELISHA",
    Latitude: "-28.5755",
    Longitude: "29.926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11092",
    WardLookupId: "1428",
    VDNumber: "43693443",
    RegisteredPopulation: "445",
    VotingStationName: "LOKOTHWAYO COMBINED SCHOOL",
    Address: "EMACHIBINI AREA   343 HELPMEKAAR ROAD",
    Latitude: "-28.5577",
    Longitude: "29.9793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11093",
    WardLookupId: "1429",
    VDNumber: "43691014",
    RegisteredPopulation: "965",
    VotingStationName: "UMNDENI WOXOLO PRIMARY SCHOOL",
    Address: "BRAKFONTEIN FARM,EZINTABENI  ALONG PIETERS MAIN ROAD  LADYSMITH",
    Latitude: "-28.6692",
    Longitude: "29.8596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11094",
    WardLookupId: "1429",
    VDNumber: "43740110",
    RegisteredPopulation: "2647",
    VotingStationName: "NCWABA PRIMARY SCHOOL",
    Address: "E1926  EZAKHENI  LADYSMITH",
    Latitude: "-28.636",
    Longitude: "29.8869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11095",
    WardLookupId: "1430",
    VDNumber: "43740121",
    RegisteredPopulation: "3247",
    VotingStationName: "SILINDOKUHLE HIGH SCHOOL",
    Address: "PIETERS ROAD  TSAKANE  LADYSMITH",
    Latitude: "-28.5723",
    Longitude: "29.832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11096",
    WardLookupId: "1430",
    VDNumber: "43740277",
    RegisteredPopulation: "1180",
    VotingStationName: "AFRICAN METHODIST EPISCOPAL CHURCH",
    Address: "MBULWANE AREA  LADYSMITH",
    Latitude: "-28.5773",
    Longitude: "29.8194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11097",
    WardLookupId: "1430",
    VDNumber: "43740312",
    RegisteredPopulation: "660",
    VotingStationName: "SIDINGULWAZI PRIMARY SCHOOL",
    Address: "TSAKANE, ALONG PIETERS MAIN RD  TSAKANE  LADYSMITH",
    Latitude: "-28.5865",
    Longitude: "29.833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11098",
    WardLookupId: "1431",
    VDNumber: "43740064",
    RegisteredPopulation: "1660",
    VotingStationName: "ML SULTAN PRIMARY SCHOOL",
    Address: "318 KANDAHAR AVE  LEONARDSVILLE  LADYSMITH",
    Latitude: "-28.5709",
    Longitude: "29.7846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11099",
    WardLookupId: "1431",
    VDNumber: "43740097",
    RegisteredPopulation: "2493",
    VotingStationName: "ANGLICAN CHURCH CEMETERY ROAD",
    Address: "CEMETERY ROAD  STEADVILLE  LADYSMITH",
    Latitude: "-28.565",
    Longitude: "29.8161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11100",
    WardLookupId: "1432",
    VDNumber: "43690877",
    RegisteredPopulation: "2374",
    VotingStationName: "ENJABULWENI PRIMARY SCHOOL",
    Address: "PHASE 1   ROOSBOM AREA   EMNAMBITHI/LADYSMITH",
    Latitude: "-28.6345",
    Longitude: "29.7011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11101",
    WardLookupId: "1432",
    VDNumber: "43692745",
    RegisteredPopulation: "1136",
    VotingStationName: "SIZAKAHLE HIGH SCHOOL",
    Address: "GOODHOPE ROAD  ROOSBOOM   ROOSBOOM",
    Latitude: "-28.6705",
    Longitude: "29.7163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11102",
    WardLookupId: "1432",
    VDNumber: "43740019",
    RegisteredPopulation: "1738",
    VotingStationName: "DR. JOSEPH SHABALALA SECONDARY SCHOOL",
    Address: "91 FARQUAR RD  HOSPITAL PARK  LADYSMITH",
    Latitude: "-28.5522",
    Longitude: "29.7665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11103",
    WardLookupId: "1433",
    VDNumber: "43740020",
    RegisteredPopulation: "1559",
    VotingStationName: "TOWN HALL",
    Address: "CNR MURCHISON & QUEEN ST  CITY CENTRE  LADYSMITH",
    Latitude: "-28.5591",
    Longitude: "29.7803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11104",
    WardLookupId: "1433",
    VDNumber: "43740132",
    RegisteredPopulation: "1743",
    VotingStationName: "EGERTON SCHOOL",
    Address: "13 SHEARER RD  EGERTON  LADYSMITH",
    Latitude: "-28.5487",
    Longitude: "29.7804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11105",
    WardLookupId: "1433",
    VDNumber: "43740143",
    RegisteredPopulation: "1973",
    VotingStationName: "MONUMENT PRIMARY SCHOOL",
    Address: "DE WET ST  61 DE WET ST LADYSMITH 3370  LADYSMITH",
    Latitude: "-28.5354",
    Longitude: "29.7732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11106",
    WardLookupId: "1434",
    VDNumber: "43690956",
    RegisteredPopulation: "2688",
    VotingStationName: "BUHLEBEZWE PRIMARY SCHOOL",
    Address: "RIETKIL AREA  ROOSBOOM PHASE 1  LADYSMITH",
    Latitude: "-28.6404",
    Longitude: "29.7207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11107",
    WardLookupId: "1434",
    VDNumber: "43740031",
    RegisteredPopulation: "1448",
    VotingStationName: "LADYSMITH SECONDARY SCHOOL",
    Address: "DRIVIDIAN RD  ALOE PARK  LADYSMITH",
    Latitude: "-28.5825",
    Longitude: "29.7875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11108",
    WardLookupId: "1435",
    VDNumber: "43690147",
    RegisteredPopulation: "593",
    VotingStationName: "HURSLEY PRIMARY SCHOOL",
    Address: "D343 ROAD   KWAMANZINI AREA   LADYSMITH",
    Latitude: "-28.4865",
    Longitude: "29.7701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11109",
    WardLookupId: "1435",
    VDNumber: "43690383",
    RegisteredPopulation: "843",
    VotingStationName: "BURFORD HIGHER PRIMARY SCHOOL",
    Address: "BURFORD OPPOSIT SPORTS GROUND  BURFORD AREA  LADYSMITH",
    Latitude: "-28.4266",
    Longitude: "29.7803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11110",
    WardLookupId: "1435",
    VDNumber: "43690473",
    RegisteredPopulation: "2157",
    VotingStationName: "NSUNGULU LOWER PRIMARY SCHOOL",
    Address: "BURFORD   DRIEFONTEIN  LADYSMITH",
    Latitude: "-28.4321",
    Longitude: "29.7872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11111",
    WardLookupId: "1435",
    VDNumber: "43693049",
    RegisteredPopulation: "512",
    VotingStationName: "MHLANGANYELWA PRIMARY SCHOOL",
    Address:
      "EXTENSION OF HYDES ROAD,   HOPESLAND AREA    EMNAMBITHI/LADYSMITH",
    Latitude: "-28.4627",
    Longitude: "29.7751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11112",
    WardLookupId: "1435",
    VDNumber: "43693128",
    RegisteredPopulation: "443",
    VotingStationName: "PEPWORTH PRIMARY SCHOOL",
    Address: "D45  PEPWORTH  LADYSMITH",
    Latitude: "-28.5002",
    Longitude: "29.88",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11113",
    WardLookupId: "1436",
    VDNumber: "43690440",
    RegisteredPopulation: "1155",
    VotingStationName: "SICELOKUHLE HIGH SCHOOL",
    Address: "PEACETOWN  PEACETOWN AREA L1245 ROAD  LADYSMITH",
    Latitude: "-28.44",
    Longitude: "29.741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11114",
    WardLookupId: "1436",
    VDNumber: "43690462",
    RegisteredPopulation: "906",
    VotingStationName: "KIRKINTULLOCH COMBINED PRIMARY SCHOOL",
    Address: "PEACETOWN   PEACETOWN AREA ALONG L1245 ROAD  LADYSMITH",
    Latitude: "-28.4369",
    Longitude: "29.7233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11115",
    WardLookupId: "1436",
    VDNumber: "43690518",
    RegisteredPopulation: "1259",
    VotingStationName: "PEACETOWN EAST TENT",
    Address: "PEACETOWN  PEACETOWN ALONG THE MAIN ROAD  PEACETOWN",
    Latitude: "-28.4504",
    Longitude: "29.745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11116",
    WardLookupId: "1436",
    VDNumber: "43690529",
    RegisteredPopulation: "973",
    VotingStationName: "PEACETOWN COMBINED PRIMARY SCHOOL",
    Address: "PEACETOWN AREA  LADYSMITH",
    Latitude: "-28.4511",
    Longitude: "29.7362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11117",
    WardLookupId: "1437",
    VDNumber: "43690349",
    RegisteredPopulation: "1292",
    VotingStationName: "APOSTOLIC FAITH MISSION (MGUNJANE)",
    Address: "DRIEFONTEIN ALONG MAIN ROAD  DRIEFONTEIN AREA  LADYSMITH",
    Latitude: "-28.4091",
    Longitude: "29.7467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11118",
    WardLookupId: "1437",
    VDNumber: "43690361",
    RegisteredPopulation: "1146",
    VotingStationName: "GCIZELA COMBINED PRIMARY SCHOOL",
    Address: "WATERSMEET TURN LEFT AT RANK   DRIEFONTEIN AREA  LADYSMITH",
    Latitude: "-28.4205",
    Longitude: "29.7359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11119",
    WardLookupId: "1437",
    VDNumber: "43690406",
    RegisteredPopulation: "630",
    VotingStationName: "SINOTHANDO CRECHE",
    Address: "L1245 ROAD WATERSMEET  WATERSMEET  LADYMITH",
    Latitude: "-28.4292",
    Longitude: "29.7213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11120",
    WardLookupId: "1437",
    VDNumber: "43693230",
    RegisteredPopulation: "1030",
    VotingStationName: "THOLULWAZI PRIMARY SCHOOL",
    Address: "WATERSMEET NEXT TO TAXI RANK  WATERSMEET  LADYSMITH",
    Latitude: "-28.4206",
    Longitude: "29.7503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11121",
    WardLookupId: "1438",
    VDNumber: "43690080",
    RegisteredPopulation: "840",
    VotingStationName: "MADILIKA COMBINED PRIMARY SCHOOL",
    Address: "EMTATENI TAKE AT DEPO  DRIEFONTEIN  LADYSMITH",
    Latitude: "-28.3382",
    Longitude: "29.7807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11122",
    WardLookupId: "1438",
    VDNumber: "43690114",
    RegisteredPopulation: "938",
    VotingStationName: "AMAHUKU COMBINED PRIMARY SCHOOL",
    Address: "AMAHOEK ALONG THE GRAVEL ROAD  DRIEFONTEIN  LADYSMITH",
    Latitude: "-28.3329",
    Longitude: "29.7442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11123",
    WardLookupId: "1438",
    VDNumber: "43690136",
    RegisteredPopulation: "855",
    VotingStationName: "INYAMAZWE PRIMARY SCHOOL",
    Address: "DRIEFONTEIN  ABANTUNGWAKHOLWA TRIBAL AUTHORITY",
    Latitude: "-28.3527",
    Longitude: "29.7341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11124",
    WardLookupId: "1438",
    VDNumber: "43690226",
    RegisteredPopulation: "1555",
    VotingStationName: "SINENHLANHLA PRIMARY SCHOOL",
    Address: "MATHONDWANE ALONG MAIN ROAD  DRIEFONTEIN  LADYSMITH",
    Latitude: "-28.3627",
    Longitude: "29.7492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11125",
    WardLookupId: "1439",
    VDNumber: "43690181",
    RegisteredPopulation: "519",
    VotingStationName: "ENTUTHWINI (TENT)",
    Address: "ALONG ROAD AT SPORTS GROUND  DRIEFONTAIN  LADYSMITH",
    Latitude: "-28.3538",
    Longitude: "29.7114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11126",
    WardLookupId: "1439",
    VDNumber: "43690248",
    RegisteredPopulation: "453",
    VotingStationName: "MASHESHISA  PRIMARY SCHOOL",
    Address: "KWAGODI ALONG MAIN ROAD  DRIEFONTEIN  EMNAMBITHI DISTRICT",
    Latitude: "-28.4074",
    Longitude: "29.784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11127",
    WardLookupId: "1439",
    VDNumber: "43690271",
    RegisteredPopulation: "1087",
    VotingStationName: "WATERSMEET (TENT)",
    Address: "ERINGINI  ALONG NGULA ROAD  DRIEFONTEIN  LADYSMITH",
    Latitude: "-28.4066",
    Longitude: "29.7179",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11128",
    WardLookupId: "1439",
    VDNumber: "43690282",
    RegisteredPopulation: "469",
    VotingStationName: "KWATIMOTHY COMBINED PRIMARY SCHOOL",
    Address: "FROM ERINGINI PROCEEDWITH ROAD  DRIEFONTEIN  LADYSMITH",
    Latitude: "-28.3799",
    Longitude: "29.7241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11129",
    WardLookupId: "1439",
    VDNumber: "43690293",
    RegisteredPopulation: "415",
    VotingStationName: "KWAPHAYIKENI HIGH SCHOOL",
    Address: "NEXT TO WATERSMEET CLINIC  DRIEFONTEIN  LADYSMITH",
    Latitude: "-28.393",
    Longitude: "29.7311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11130",
    WardLookupId: "1439",
    VDNumber: "43690327",
    RegisteredPopulation: "1597",
    VotingStationName: "INGULA HIGH SCHOOL",
    Address: "WATERSMEET NEXT TO CLINIC  DRIEFONTEIN  LADYSMITH",
    Latitude: "-28.3997",
    Longitude: "29.7333",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11131",
    WardLookupId: "1440",
    VDNumber: "43690068",
    RegisteredPopulation: "532",
    VotingStationName: "WATERSHED COMBINED PRIMARY SCHOOL",
    Address: "WATERSHED , TAKE WATERSHED RD   DRIEFONTEIN  LADYSMITH",
    Latitude: "-28.3131",
    Longitude: "29.6953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11132",
    WardLookupId: "1440",
    VDNumber: "43690091",
    RegisteredPopulation: "954",
    VotingStationName: "ENTONJENI HIGH SCHOOL",
    Address:
      "KLEINFONTEIN AREA  DRIEFONTEIN, TAKE GRAVEL RD 2 KM AWAY FROM TC  LADYSMITH",
    Latitude: "-28.3391",
    Longitude: "29.6489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11133",
    WardLookupId: "1440",
    VDNumber: "43690125",
    RegisteredPopulation: "590",
    VotingStationName: "MANGQASHA COMBINED PRIMARY SCHOOL",
    Address: "ROODEPOORT AREA   DRIEFONTEIN  LADYSMITH",
    Latitude: "-28.3357",
    Longitude: "29.6812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11134",
    WardLookupId: "1440",
    VDNumber: "43690158",
    RegisteredPopulation: "887",
    VotingStationName: "EMFULAMTHATHU PRIMARY SCHOOL",
    Address:
      "DRIEFONTEIN   LADYSMITH, TAKE LEFT TOWARDS MHLWANENI SCHOOL   NKOSI KHUMALO TRIBAL AUTHORITY",
    Latitude: "-28.3447",
    Longitude: "29.6954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11135",
    WardLookupId: "1440",
    VDNumber: "43690215",
    RegisteredPopulation: "1463",
    VotingStationName: "INKUTHU COMBINED PRIMARY SCHOOL",
    Address:
      "KLEINFONTEIN AREA   DRIEFONTEIN AREA, PASS NTONJENI HIGH SCHOOL  ,   LADYSMITH",
    Latitude: "-28.3628",
    Longitude: "29.6512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11136",
    WardLookupId: "1441",
    VDNumber: "43693117",
    RegisteredPopulation: "951",
    VotingStationName: "MTHANDI PRIMARY SCHOOL",
    Address:
      "PIETERS ROAD  TAKE RIGHT AT TAXI RANK,THEN LEFT AFTER THE BRIDGE  LADYSMITH",
    Latitude: "-28.6273",
    Longitude: "29.8377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11137",
    WardLookupId: "1441",
    VDNumber: "43740075",
    RegisteredPopulation: "3445",
    VotingStationName: "ACACIAVALE PRIMARY SCHOOL",
    Address: "6/28 FOXGLOVE AVE  ACACIAVALE  LADYSMITH",
    Latitude: "-28.5948",
    Longitude: "29.8028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11138",
    WardLookupId: "1441",
    VDNumber: "43740299",
    RegisteredPopulation: "883",
    VotingStationName: "ACACIAVALE TENT AT CLINIC",
    Address: "92 CENTENARY ROAD   ACACIAVALE  LADYSMITH",
    Latitude: "-28.587",
    Longitude: "29.7962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11139",
    WardLookupId: "1442",
    VDNumber: "43740086",
    RegisteredPopulation: "2074",
    VotingStationName: "STEADVILLE COMMUNITY HALL",
    Address: "539 CEMETERY RD  STEADVILLE, ON MAIN ROAD   LADYSMITH",
    Latitude: "-28.5605",
    Longitude: "29.8144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11140",
    WardLookupId: "1442",
    VDNumber: "43740266",
    RegisteredPopulation: "2291",
    VotingStationName: "STEADVILLE AREA J -COMMUNITY HALL",
    Address: "AREA J - STEADVILLE  STEADVILLE   LADYSMITH",
    Latitude: "-28.5562",
    Longitude: "29.8245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11141",
    WardLookupId: "1443",
    VDNumber: "43693229",
    RegisteredPopulation: "419",
    VotingStationName: "ENHLALAKAHLE (TENT)",
    Address:
      "HELPMEKAAR ROAD  ST. CHADS AREA , OPP THEMBISIZWE SCHOOL   ST. CHADS AREA(OPP. THEMBISIZWE SCHOOL)",
    Latitude: "-28.5641",
    Longitude: "29.8586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11142",
    WardLookupId: "1443",
    VDNumber: "43740053",
    RegisteredPopulation: "1952",
    VotingStationName: "LIMIT HILL COMMUNITY HALL",
    Address: "15 SECOND AVENUE  LIMITHILL AREA   LADYSMITH",
    Latitude: "-28.5367",
    Longitude: "29.8053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11143",
    WardLookupId: "1443",
    VDNumber: "43740334",
    RegisteredPopulation: "1047",
    VotingStationName: "MKHAMBA GARDENS (TENT)",
    Address: "PARK LANE   MKHAMBA   LADYSMITH",
    Latitude: "-28.5349",
    Longitude: "29.7875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11144",
    WardLookupId: "1444",
    VDNumber: "43690170",
    RegisteredPopulation: "840",
    VotingStationName: "CWEMBE COMBINED PRIMARY SCHOOL",
    Address:
      "MATIWANESKOP   MATIWANESKOP AREA, TAKE RIGHT ON GRAVEL ROAD,  LADYSMITH",
    Latitude: "-28.3497",
    Longitude: "29.9125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11145",
    WardLookupId: "1444",
    VDNumber: "43690192",
    RegisteredPopulation: "905",
    VotingStationName: "MANGCENGEZA HIGH SCHOOL",
    Address: "MATIWANESKOP   NEXT TO MATIWANESKOP CLINIC   LADYSMITH",
    Latitude: "-28.3464",
    Longitude: "29.9002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11146",
    WardLookupId: "1444",
    VDNumber: "43690204",
    RegisteredPopulation: "948",
    VotingStationName: "MATIWANESKOP COMBINED PRIMARY SCHOOL",
    Address: "MATIWANESKOP NEXT TO  CEMETERY  MATIWANESKOP  LADYSMITH",
    Latitude: "-28.3578",
    Longitude: "29.874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11147",
    WardLookupId: "1444",
    VDNumber: "43690237",
    RegisteredPopulation: "667",
    VotingStationName: "THEMBELA HIGH SCHOOL",
    Address: "NEXT TO MOUNTAIN  MATIWANESKOP AREA   LADYSMITH",
    Latitude: "-28.3719",
    Longitude: "29.8866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11148",
    WardLookupId: "1444",
    VDNumber: "43690260",
    RegisteredPopulation: "944",
    VotingStationName: "JONONOSKOP PRIMARY SCHOOL",
    Address: "NEXT TO MCHUNU SHOP   JONONOSKOP  LADYSMITH",
    Latitude: "-28.3855",
    Longitude: "29.9012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11149",
    WardLookupId: "1445",
    VDNumber: "43690013",
    RegisteredPopulation: "589",
    VotingStationName: "LUSITANIA SENIOR PRIMARY SCHOOL",
    Address: "TAKE N11, COLLING PASS  LUSITANIA AREA , NEXT TO DAM  LADYSMITH",
    Latitude: "-28.2052",
    Longitude: "29.7357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11150",
    WardLookupId: "1445",
    VDNumber: "43690035",
    RegisteredPopulation: "273",
    VotingStationName: "KWAKALI COMMUNITY HALL",
    Address: "DRONGWAL AREA   DRONGWAL   KWAKALI/DRONGWAL AREA",
    Latitude: "-28.2765",
    Longitude: "29.9118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11151",
    WardLookupId: "1445",
    VDNumber: "43690057",
    RegisteredPopulation: "984",
    VotingStationName: "ENKUNZI COMMUNITY HALL",
    Address: "ENKUNZI ALONG DUNDEE ROAD  WASBANK  LADYSMITH",
    Latitude: "-28.3267",
    Longitude: "30.0537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11152",
    WardLookupId: "1445",
    VDNumber: "43690079",
    RegisteredPopulation: "869",
    VotingStationName: "BALDASKRAAL COMBINED PRIMARY SCHOOL",
    Address: "INKUTHU NEAR BUS STOP  DRIEFONTEIN AREA   LADYSMITH",
    Latitude: "-28.3005",
    Longitude: "29.7145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11153",
    WardLookupId: "1445",
    VDNumber: "43690103",
    RegisteredPopulation: "1219",
    VotingStationName: "VEYISI COMBINED PRIMARY SCHOOL",
    Address: "ENDINGENI AREA  MATIWANESKOP  LADYSMITH",
    Latitude: "-28.3369",
    Longitude: "29.8707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11154",
    WardLookupId: "1445",
    VDNumber: "43690169",
    RegisteredPopulation: "220",
    VotingStationName: "BONGELA PRIMARY SCHOOL",
    Address: "CREMIN, TAKE D45 ROAD  CREAMIN AREA, ELANDLAAGTE  LADYSMITH",
    Latitude: "-28.4501",
    Longitude: "29.943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11155",
    WardLookupId: "1445",
    VDNumber: "43692677",
    RegisteredPopulation: "209",
    VotingStationName: "VUKUFUNDE PRIMARY  SCHOOL",
    Address: "NKOSI SHABALALAS AREA  NKUNZI AREA, HALI AREA  LADYSMITH",
    Latitude: "-28.3689",
    Longitude: "29.9592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11156",
    WardLookupId: "1445",
    VDNumber: "43692914",
    RegisteredPopulation: "220",
    VotingStationName: "WESSELSNEK COMMUNITY HALL",
    Address:
      "WESSELSNEK/  EKUVUKENI  WESSELSNEK/ EKUVUKENI   MACHUNWINI TRIBAL AUTHORITY",
    Latitude: "-28.3841",
    Longitude: "30.0792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11157",
    WardLookupId: "1446",
    VDNumber: "43692992",
    RegisteredPopulation: "727",
    VotingStationName: "MIMTAS AIDED PRIMARY SCHOOL",
    Address: "RIETKIL AREA ,  ROOSBOOM, 500M FROM R103 RD  LADYSMITH",
    Latitude: "-28.6124",
    Longitude: "29.7285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11158",
    WardLookupId: "1446",
    VDNumber: "43693454",
    RegisteredPopulation: "253",
    VotingStationName: "ETHULENI PRIMARY SCHOOL",
    Address: "R103 ROAD   ROOSBOOM   LADYSMITH",
    Latitude: "-28.6735",
    Longitude: "29.7404",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11159",
    WardLookupId: "1446",
    VDNumber: "43720017",
    RegisteredPopulation: "1469",
    VotingStationName: "COLENSO PRIMARY SCHOOL",
    Address: "LANE STREET   LADYSMITH   COLENSO",
    Latitude: "-28.7421",
    Longitude: "29.8248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11160",
    WardLookupId: "1446",
    VDNumber: "43720028",
    RegisteredPopulation: "1571",
    VotingStationName: "COLENSO COMBINED SCHOOL",
    Address: "COLENSO  INKANYEZI TOWNSHIP, NEXT TO THE COMMUNITY HALL",
    Latitude: "-28.7355",
    Longitude: "29.8373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11161",
    WardLookupId: "1447",
    VDNumber: "43690046",
    RegisteredPopulation: "240",
    VotingStationName: "BRAKVAL COMBINED SCHOOL",
    Address:
      "BESTERS AREA, GRAVEL ROAD  BESTERS,12 KM FROM RAILWAY/STOP SIGNS, GRAVEL ROAD  LADYSMITH",
    Latitude: "-28.3772",
    Longitude: "29.4848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11162",
    WardLookupId: "1447",
    VDNumber: "43690259",
    RegisteredPopulation: "618",
    VotingStationName: "VAN REENEN PRIMARY SCHOOL",
    Address:
      "8 WRAGG STREET  VAN REENEN TOWN , BEHIND PETROL STATION    LADYSMITH",
    Latitude: "-28.3755",
    Longitude: "29.3819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11163",
    WardLookupId: "1447",
    VDNumber: "43690316",
    RegisteredPopulation: "226",
    VotingStationName: "BROOKFIELD PRIMARY SCHOOL",
    Address: "TAKE HARRISSMITH ROAD   BROOKFIELD AREA   LADYSMITH",
    Latitude: "-28.4898",
    Longitude: "29.6565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11164",
    WardLookupId: "1447",
    VDNumber: "43690541",
    RegisteredPopulation: "542",
    VotingStationName: "BLAAUBANK PRIMARY SCHOOL",
    Address:
      "1308 BLUE BANK AREA  20KM FROM TRUCK STOP,NOOITEGEDACHT FARM   EMNAMBITHI/LADYSMITH",
    Latitude: "-28.5201",
    Longitude: "29.5645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11165",
    WardLookupId: "1447",
    VDNumber: "43692688",
    RegisteredPopulation: "266",
    VotingStationName: "ZAAIFONTEIN PRIMARY SCHOOL",
    Address: "KHUMALO TRIBAL AUTHORITY  DRIEFONTEIN  LADYSMITH",
    Latitude: "-28.2884",
    Longitude: "29.6296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11166",
    WardLookupId: "1447",
    VDNumber: "43692734",
    RegisteredPopulation: "384",
    VotingStationName: "UXOLO NOKUZWANA HIGH SCHOOL",
    Address: "ST. JOSEPH MISSION   BESTERS AREA  LADYSMITH",
    Latitude: "-28.4828",
    Longitude: "29.5641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11167",
    WardLookupId: "1447",
    VDNumber: "43693140",
    RegisteredPopulation: "388",
    VotingStationName: "IMVULO PRIMARY SCHOOL",
    Address:
      "BESTERS AREA   HARRISMITH ROAD, CROSS RAILWAY,NEAR POLICE STATION  LADYSMITH",
    Latitude: "-28.4339",
    Longitude: "29.6318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11168",
    WardLookupId: "1447",
    VDNumber: "43740323",
    RegisteredPopulation: "313",
    VotingStationName: "HOSPITAL PARK RECREATIONAL AREA",
    Address:
      "PRESIDENT STREET  HOSPITAL PARK , NEXT TO PENTECOSTAL CHURCH   LADYSMITH",
    Latitude: "-28.5516",
    Longitude: "29.7623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11169",
    WardLookupId: "1448",
    VDNumber: "43690855",
    RegisteredPopulation: "1581",
    VotingStationName: "ST CHADS MISSION PRIMARY SCHOOL",
    Address:
      "1 KM FROM HELPMEKAAR ROAD   ST CHADS MISSION AREA- NEXT TO ANGLICAN CHURCH  LADYSMITH",
    Latitude: "-28.5809",
    Longitude: "29.8842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11170",
    WardLookupId: "1448",
    VDNumber: "43690888",
    RegisteredPopulation: "2156",
    VotingStationName: "AMANCANAKAZANA PRIMARY SCHOOL",
    Address: "EZAKHENI ROAD  ST CHADS AREA- 700M FROM EZAKHENI RD  LADYSMITH",
    Latitude: "-28.5913",
    Longitude: "29.9132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11171",
    WardLookupId: "1448",
    VDNumber: "43693016",
    RegisteredPopulation: "331",
    VotingStationName: "THEMBISIZWE PRIMARY SCHOOL",
    Address:
      "HELPMEKAAR RD,1ST SPEED HUMPS  ST. CHADS AREA-OPP. ROMAN CATHOLIC CHURCH   EMNAMBITHI/LADYSMITH",
    Latitude: "-28.5657",
    Longitude: "29.8584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11172",
    WardLookupId: "1448",
    VDNumber: "43693465",
    RegisteredPopulation: "707",
    VotingStationName: "THUSONG -CENTRE-ST.CHADS",
    Address: "HELPMEKAAR ROAD   ST. CHADS MISSION   ST. CHADS MISSION",
    Latitude: "-28.5805",
    Longitude: "29.9005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11173",
    WardLookupId: "1448",
    VDNumber: "43740255",
    RegisteredPopulation: "588",
    VotingStationName: "PIETERS &EZAKHENI ROAD (TENT)",
    Address: "PIETERS MAIN ROAD  SHAYAMOYA  EMNAMBITHI/LADYSMITH",
    Latitude: "-28.5663",
    Longitude: "29.8358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11174",
    WardLookupId: "1448",
    VDNumber: "43740345",
    RegisteredPopulation: "472",
    VotingStationName: "INKANYEZI  SPECIAL SCHOOL",
    Address: "OLIVA TAMBO RD  STEADVILLE TOWNSHIP  3324",
    Latitude: "-28.5613",
    Longitude: "29.8181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11175",
    WardLookupId: "1449",
    VDNumber: "43690811",
    RegisteredPopulation: "737",
    VotingStationName: "MNTUNGWA PRIMARY SCHOOL",
    Address: "MADAZANE  FITTY PARK NEXT TO OLD ZG   AMAGWE TRIBAL AUTHORITY",
    Latitude: "-28.5667",
    Longitude: "30.0713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11176",
    WardLookupId: "1449",
    VDNumber: "43690844",
    RegisteredPopulation: "1146",
    VotingStationName: "NSIKAYEZWE HIGHER PRIMARY SCHOOL",
    Address:
      "ENGEDLENGEDLENI  NGEDLENGEDLENI NEXT TO INDAKA RIVER  SITHOLE TRIBAL AUTHORITY",
    Latitude: "-28.5862",
    Longitude: "30.1228",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11177",
    WardLookupId: "1449",
    VDNumber: "43690901",
    RegisteredPopulation: "1105",
    VotingStationName: "WITTEKLEINFONTEIN COMBINED PRIMARY SCHOOL",
    Address:
      "EMAHLABATHINI  KWASHUZI NEXT TO TRIBAL COURT  MABASO TRIBAL AUTHORITY",
    Latitude: "-28.6066",
    Longitude: "30.0637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11178",
    WardLookupId: "1449",
    VDNumber: "43690923",
    RegisteredPopulation: "640",
    VotingStationName: "MANKAYANA HIGHER PRIMARY SCHOOL",
    Address:
      "MKHUMBANE  EMACHIBINI NEXT TO SHABALALA STORE  ABATHEMBU TRIBAL AUTHORITY",
    Latitude: "-28.6158",
    Longitude: "30.0269",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11179",
    WardLookupId: "1449",
    VDNumber: "43690978",
    RegisteredPopulation: "1003",
    VotingStationName: "CENGEZI COMBINED PRIMARY SCHOOL",
    Address: "KWAMTEYI  KWAMTEYI NEXT TO CLINIC  AMNGWE TRIBAL AUTHORITY",
    Latitude: "-28.632",
    Longitude: "30.07",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11180",
    WardLookupId: "1449",
    VDNumber: "43690990",
    RegisteredPopulation: "521",
    VotingStationName: "MZIYONKE HIGHER PRIMARY SCHOOL",
    Address:
      "EMZIYONKE  MZIYONKE NEXT TO COMMUNITY HALL  ABATHEMBU TRIBAL AUTHORITY",
    Latitude: "-28.6497",
    Longitude: "30.0337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11181",
    WardLookupId: "1449",
    VDNumber: "43691092",
    RegisteredPopulation: "289",
    VotingStationName: "MANKANDANE COMMUNITY HALL",
    Address: "MANKANDANE  MANKANDE   ABATHEMBU TRIBAL AUTHORITY",
    Latitude: "-28.6705",
    Longitude: "30.0802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11182",
    WardLookupId: "1450",
    VDNumber: "43690967",
    RegisteredPopulation: "955",
    VotingStationName: "MGABO PRIMARY SCHOOL",
    Address:
      "MJINDINI  MJINDINI NEXT TO COMMUNITY HALL  SITHOLE TRIBAL AUTHORITY",
    Latitude: "-28.6565",
    Longitude: "30.1115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11183",
    WardLookupId: "1450",
    VDNumber: "43691069",
    RegisteredPopulation: "746",
    VotingStationName: "MHLUMAYO PRIMARY SCHOOL",
    Address: "MHLUMAYO  GCINALISHONE NEXT CLINIC  SITHOLE PRIMARY SCHOOL",
    Latitude: "-28.6857",
    Longitude: "30.1333",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11184",
    WardLookupId: "1450",
    VDNumber: "43691294",
    RegisteredPopulation: "368",
    VotingStationName: "ISIBANKWA LOWER PRIMARY SCHOOL",
    Address: "EHLONYANA  EHLONYANE NEXT TO MAIN ROAD  SITHOLE TRIBAL AUTHORITY",
    Latitude: "-28.6962",
    Longitude: "30.1552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11185",
    WardLookupId: "1450",
    VDNumber: "43691407",
    RegisteredPopulation: "1709",
    VotingStationName: "SAHLUMBE SENIOR SECONDARY SCHOOL",
    Address: "EMANSELENI  SAHLUMBE NEXT TO CLINIC   ABATHEMBU TRIBAL AUTHORITY",
    Latitude: "-28.7392",
    Longitude: "30.1905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11186",
    WardLookupId: "1450",
    VDNumber: "43691452",
    RegisteredPopulation: "649",
    VotingStationName: "FUNDANI HIGHER PRIMARY SCHOOL",
    Address: "MTEBHELU  KWAMADONDO AREA   ABATHEMBU TRIBAL AUTHORITY",
    Latitude: "-28.7377",
    Longitude: "30.153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11187",
    WardLookupId: "1450",
    VDNumber: "43691508",
    RegisteredPopulation: "560",
    VotingStationName: "MTHEMBU TRIBAL COURT",
    Address: "SAHLUMBE  EBULINGE   ABATHEMBU TRIBAL COURT",
    Latitude: "-28.7512",
    Longitude: "30.1527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11188",
    WardLookupId: "1451",
    VDNumber: "43690765",
    RegisteredPopulation: "343",
    VotingStationName: "MBELEBELE COMBINED PRIMARY SCHOOL",
    Address:
      "SOMSHOEK  HWEBEDENI NEXT TO COMMUNITY HALL  SITHOLE TRIBAL AUTHORITY",
    Latitude: "-28.5448",
    Longitude: "30.1833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11189",
    WardLookupId: "1451",
    VDNumber: "43690866",
    RegisteredPopulation: "746",
    VotingStationName: "JOBE HIGHER PRIMARY SCHOOL",
    Address: "KWAGIBA  KWAGIBA AT ILENGE MOUNTAIN  SITHOLE TRIBAL AUTHORITY",
    Latitude: "-28.5933",
    Longitude: "30.2062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11190",
    WardLookupId: "1451",
    VDNumber: "43690934",
    RegisteredPopulation: "479",
    VotingStationName: "EMLILWENI COMBINED PRIMARY SCHOOL",
    Address:
      "MLILWENI  MAHLABATHINI NEXT TO TRIBAL COURT  EMANGWENI TRIBAL AUTHIRITY",
    Latitude: "-28.6403",
    Longitude: "30.1776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11191",
    WardLookupId: "1451",
    VDNumber: "43690945",
    RegisteredPopulation: "365",
    VotingStationName: "MNYANDA LOWER PRIMARY SCHOOL",
    Address: "KWANOGEJANE  MNYANDA  AMANGWE TRIBAL AUTHORITY",
    Latitude: "-28.6383",
    Longitude: "30.2359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11192",
    WardLookupId: "1451",
    VDNumber: "43691047",
    RegisteredPopulation: "743",
    VotingStationName: "BHEKABATHEMBU HIGH SCHOOL",
    Address: "EGOQWENI AREA  LADYSSMITH  SITHOLE TRIBAL AUTHORITY",
    Latitude: "-28.6597",
    Longitude: "30.2006",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11193",
    WardLookupId: "1451",
    VDNumber: "43691070",
    RegisteredPopulation: "469",
    VotingStationName: "OQUNGWENI HIGHER PRIMARY SCHOOL",
    Address: "OQUNGWENI  OQUNGWINI NEXT TO MAIN ROAD  SITHOLE TRIBAL AUTHORITY",
    Latitude: "-28.6757",
    Longitude: "30.1778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11194",
    WardLookupId: "1451",
    VDNumber: "43691081",
    RegisteredPopulation: "489",
    VotingStationName: "LUDIMBI COMBINED PRIMARY SCHOOL",
    Address:
      "ELUDIMBI  KWALUDIMBI NEXT TO INDAKA RIVER  ABATHEMBU TRIBAL AUTHORITY",
    Latitude: "-28.6858",
    Longitude: "30.2636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11195",
    WardLookupId: "1451",
    VDNumber: "43691384",
    RegisteredPopulation: "1203",
    VotingStationName: "THUTHUKANI PRIMARY SCHOOL",
    Address: "SAHLUMBE  KWANOMOYA AREA   ABATHEMBU TRIBAL AUTHORITY",
    Latitude: "-28.7383",
    Longitude: "30.2325",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11196",
    WardLookupId: "1451",
    VDNumber: "43692565",
    RegisteredPopulation: "196",
    VotingStationName: "MHLONGAMVULA  TEMPORAL VOTING STATION(TENT)",
    Address: "UMHLWAZI  EMHLWAZI AFTER TRIBAL COURT  AMANGWE TRIBAL AUTHORITY",
    Latitude: "-28.6263",
    Longitude: "30.1688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11197",
    WardLookupId: "1451",
    VDNumber: "43693320",
    RegisteredPopulation: "228",
    VotingStationName: "THEMBOKUHLE PRIMARY SCHOOL",
    Address: "MHLWAZI  ELENGE AREA  AMANGWE TRIBAL AUTHORITY",
    Latitude: "-28.5931",
    Longitude: "30.1623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11198",
    WardLookupId: "1452",
    VDNumber: "43690428",
    RegisteredPopulation: "540",
    VotingStationName: "MABHEKAZI HIGH SCHOOL",
    Address: "EMABHEKAZI ,NEXT TO RESEVIOR  INDAKA AREA  LADYSMITH",
    Latitude: "-28.4772",
    Longitude: "30.0965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11199",
    WardLookupId: "1452",
    VDNumber: "43690451",
    RegisteredPopulation: "628",
    VotingStationName: "NTABEBOMVU COMMUNITY HALL",
    Address:
      "JWILI AREA  KWAJWILI NEXT TO EMATHUNENI  ABATHEMBU TRAIBA AUTHORITY",
    Latitude: "-28.4628",
    Longitude: "30.132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11200",
    WardLookupId: "1452",
    VDNumber: "43690574",
    RegisteredPopulation: "880",
    VotingStationName: "ZIMISELE PRIMARY SCHOOL",
    Address:
      "WAAIHOEK  WAAIHOEK NEXT TO COMMUNITY HALL  ABATHEMBU TRIBAL AUTHIRITY",
    Latitude: "-28.5171",
    Longitude: "30.0786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11201",
    WardLookupId: "1452",
    VDNumber: "43690686",
    RegisteredPopulation: "286",
    VotingStationName: "DALKOSI TEMPORAL VOTING STATION(TENT)",
    Address: "DALKOSI  DALKOSINI   SITHOLE TRIBAL AUTHORITY",
    Latitude: "-28.5378",
    Longitude: "30.1636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11202",
    WardLookupId: "1452",
    VDNumber: "43690697",
    RegisteredPopulation: "171",
    VotingStationName: "NODADA PRIMARY SCHOOL",
    Address:
      "KOMANI AREA  NODADA NEXT NSOYI MOUNTAIN  ABATHEMBU TRIBAL AUTHORITY",
    Latitude: "-28.5165",
    Longitude: "30.1284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11203",
    WardLookupId: "1452",
    VDNumber: "43690743",
    RegisteredPopulation: "1500",
    VotingStationName: "NKANYEZI PRIMARY SCHOOL",
    Address:
      "SOMSHOEK  EMBANGENI NEXT TO SCELUKUKHANYA  AMANGWE TRIBAL AUTHORITY",
    Latitude: "-28.5167",
    Longitude: "30.2124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11204",
    WardLookupId: "1452",
    VDNumber: "43690754",
    RegisteredPopulation: "272",
    VotingStationName: "VIKINDUKU COMBINED PRIMARY SCHOOL",
    Address: "MBONDWANE  ABATHEMBU TRIBAL AUTHORITY",
    Latitude: "-28.53",
    Longitude: "30.1049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11205",
    WardLookupId: "1452",
    VDNumber: "43692666",
    RegisteredPopulation: "265",
    VotingStationName: "OLD BETHLEHEM ZION CHURCH",
    Address: "KOMANE AREA  LADYSMITH  ABATHEMBU TRIBAL AUTHORITY",
    Latitude: "-28.5043",
    Longitude: "30.1187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11206",
    WardLookupId: "1452",
    VDNumber: "43692723",
    RegisteredPopulation: "466",
    VotingStationName: "NTSHELE PRIMARY SCHOOL",
    Address: "INTSHELE  NTSHELE AREA  ABATHEMBU TRADITIONAL AUTHORITY",
    Latitude: "-28.5114",
    Longitude: "30.0436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11207",
    WardLookupId: "1453",
    VDNumber: "43690495",
    RegisteredPopulation: "1523",
    VotingStationName: "MADLALA FULL SERVICE SCHOOL",
    Address:
      "VAALKOP AREA  KWAMAQANDA NEXT COMMUNITY HALL  NXUMALO TRIBAL AUTHORITY",
    Latitude: "-28.4624",
    Longitude: "30.1904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11208",
    WardLookupId: "1453",
    VDNumber: "43690552",
    RegisteredPopulation: "698",
    VotingStationName: "SIYAPHAMBILI PRIMARY SCHOOL",
    Address:
      "EMAKHASI  EMAKHASI AREA OPPOSIT MAKHASI MISSION  EMACHUNWINI TRIBAL AUTHORITY",
    Latitude: "-28.4606",
    Longitude: "30.2201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11209",
    WardLookupId: "1453",
    VDNumber: "43690631",
    RegisteredPopulation: "449",
    VotingStationName: "THOBISIZWE PRIMARY SCHOOL",
    Address: "SPANDIKROON  ESIKOKO  NXUMALO TRIBAL AUTHORITY",
    Latitude: "-28.4919",
    Longitude: "30.1786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11210",
    WardLookupId: "1453",
    VDNumber: "43690664",
    RegisteredPopulation: "820",
    VotingStationName: "NAMAKAZI PRIMARY SCHOOL",
    Address: "NAZARETH   NAMAKAZI   NXUMALO TRIBAL AUTHORITY",
    Latitude: "-28.4973",
    Longitude: "30.2112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11211",
    WardLookupId: "1453",
    VDNumber: "43693285",
    RegisteredPopulation: "882",
    VotingStationName: "NAZARETH COMMUNITY HALL",
    Address: "NAZARETH AREA  NAZARETH NEXT TO CLINIC  NXUMALO TRIBAL AUTHORITY",
    Latitude: "-28.5008",
    Longitude: "30.2072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11212",
    WardLookupId: "1453",
    VDNumber: "43693296",
    RegisteredPopulation: "514",
    VotingStationName: "SIKHONA CRECHE",
    Address: "UITVAAL   MAKHASI AREA   WASBANK",
    Latitude: "-28.4658",
    Longitude: "30.2185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11213",
    WardLookupId: "1454",
    VDNumber: "43690585",
    RegisteredPopulation: "1014",
    VotingStationName: "SONDODA JUNIOR PRIMARY SCHOOL",
    Address:
      "EKUVUKENI SECTION D   EKUVUKENI SECTION D NEXT TO CLINIC   WASBANK",
    Latitude: "-28.4669",
    Longitude: "30.1587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11214",
    WardLookupId: "1454",
    VDNumber: "43690596",
    RegisteredPopulation: "799",
    VotingStationName: "INKUNZI SENIOR PRIMARY SCHOOL",
    Address: "EKUVUKENI  SECTION F    NEXT TO MBIZENI HIGH SCHOOL  EKUVUKENI",
    Latitude: "-28.4626",
    Longitude: "30.1609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11215",
    WardLookupId: "1454",
    VDNumber: "43690608",
    RegisteredPopulation: "779",
    VotingStationName: "EMALAHLENI JUNIOR PRIMARY SCHOOL",
    Address: "EKUVUKENI  SECTION E NEXT TO SPORT GROUND  EKUVUKENI",
    Latitude: "-28.4668",
    Longitude: "30.1502",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11216",
    WardLookupId: "1454",
    VDNumber: "43690620",
    RegisteredPopulation: "950",
    VotingStationName: "IMFANELO HIGHER PRIMARY SCHOOL",
    Address: "EKUVUKENI  EMNAMSECTION B NEXT TO SOLOM STORE  EKUVUKENI",
    Latitude: "-28.4694",
    Longitude: "30.1499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11217",
    WardLookupId: "1454",
    VDNumber: "43693038",
    RegisteredPopulation: "1300",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "EKUVUKENI  SECTION E ON MAIN ROAD  EKUVUKENI",
    Latitude: "-28.4683",
    Longitude: "30.1646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11218",
    WardLookupId: "1454",
    VDNumber: "43693319",
    RegisteredPopulation: "574",
    VotingStationName: "CHURCH OF ENGLAND",
    Address:
      "EKUVUKENI TOWN SHIP  SECTION E NEXT TO TOP SHAYELA STORE  EKUVUKENI",
    Latitude: "-28.47",
    Longitude: "30.1628",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11219",
    WardLookupId: "1455",
    VDNumber: "43690338",
    RegisteredPopulation: "930",
    VotingStationName: "THOLENI LOWER PRIMARY SCHOOL",
    Address:
      "ETHOLENI  THOLENI NEXT DUNDEE HELPMEKAAR ROAD  EMACHUNWINI TRIBAL AUTHORITY",
    Latitude: "-28.4232",
    Longitude: "30.2227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11220",
    WardLookupId: "1455",
    VDNumber: "43690350",
    RegisteredPopulation: "757",
    VotingStationName: "PRESBETARIAN CHURCH",
    Address:
      "THOLENI AREA  ESIDAKANENI NEXT TO KHUMALO TAXI RANK  MCHUNU TRIBAL AUTHORITY",
    Latitude: "-28.4181",
    Longitude: "30.2369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11221",
    WardLookupId: "1455",
    VDNumber: "43690372",
    RegisteredPopulation: "693",
    VotingStationName: "IMPOLWANE HIGH SCHOOL",
    Address:
      "ETHOLENI  GIKISHOBA NEXT TO COMMUNITY HALL  MCHUNU TRIBAL AUTHORITY",
    Latitude: "-28.4238",
    Longitude: "30.2159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11222",
    WardLookupId: "1455",
    VDNumber: "43690394",
    RegisteredPopulation: "1000",
    VotingStationName: "EMAFUSINI SENIOR PRIMARY SCHOOL",
    Address:
      "LIMEHILL  OLD LIMEHILL OPPOSIT TRIBAL COURT  EMACHUNWINI TRIBAL AUTHORITY",
    Latitude: "-28.4389",
    Longitude: "30.2447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11223",
    WardLookupId: "1455",
    VDNumber: "43690484",
    RegisteredPopulation: "650",
    VotingStationName: "SIPHENDUKILE COMBINED PRIMARY SCHOOL",
    Address: "STANFORD  KLIPOORT  EMACHUNWINI TRIBAL AUTHORITY",
    Latitude: "-28.4449",
    Longitude: "30.2234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11224",
    WardLookupId: "1455",
    VDNumber: "43693274",
    RegisteredPopulation: "416",
    VotingStationName: "NCEDE PRIMARY SCHOOL",
    Address:
      "LIME HILL AREA   KWANCEDE NEXT TO SOCIAL WAKER   EMACHUNWINI TRIBAL AUTHORITY",
    Latitude: "-28.4377",
    Longitude: "30.2482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11225",
    WardLookupId: "1456",
    VDNumber: "43690439",
    RegisteredPopulation: "1185",
    VotingStationName: "LIMEHILL HIGH SCHOOL",
    Address:
      "LIMEHILL  LIMEHIL NEXT TO NEDIPOLA CENTER  MCHUNU TRIBAL AUTHORITY",
    Latitude: "-28.4507",
    Longitude: "30.2509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11226",
    WardLookupId: "1456",
    VDNumber: "43690507",
    RegisteredPopulation: "736",
    VotingStationName: "ENGQONDWENI HIGH SCHOOL",
    Address: "EDIPHINI  DIVAL AREA  MBENSE TRIBAL AUTHORITY",
    Latitude: "-28.4445",
    Longitude: "30.2748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11227",
    WardLookupId: "1456",
    VDNumber: "43690530",
    RegisteredPopulation: "795",
    VotingStationName: "EMAHLEKWANE COMBINED PRIMARY SCHOOL",
    Address:
      "UITVAL  UITVAL SECTION B NEXT TO TRADITIONAL COURT  MBENSE TRIBAL AUTHORITY",
    Latitude: "-28.4656",
    Longitude: "30.2586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11228",
    WardLookupId: "1456",
    VDNumber: "43690563",
    RegisteredPopulation: "1077",
    VotingStationName: "NTANDOYESIZWE SECONDARY SCHOOL",
    Address:
      "UITVAL  UITVAAL SECTION E HELPMEKAAR ROAD  MBENSE TRIBAL AUTHORITY",
    Latitude: "-28.4706",
    Longitude: "30.2428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11229",
    WardLookupId: "1456",
    VDNumber: "43693027",
    RegisteredPopulation: "546",
    VotingStationName: "MAKHASI COMMUNITY HALL",
    Address:
      "MAKHASI AREA  (KZN233 - INDAKA [WAAIHOEK]) SECTION H EHLAHLENI  MBENSE TRIBAL COURT",
    Latitude: "-28.4698",
    Longitude: "30.2286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11230",
    WardLookupId: "1456",
    VDNumber: "43693252",
    RegisteredPopulation: "676",
    VotingStationName: "GABANGEMFUNDO PRIMARY SCHOOL",
    Address:
      "UITVAAL AREA  SECTION F OPPOSIT THEMBALIHLE   MBENSE TRIBAL COURT",
    Latitude: "-28.4695",
    Longitude: "30.2391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11231",
    WardLookupId: "1456",
    VDNumber: "43693263",
    RegisteredPopulation: "563",
    VotingStationName: "UITVAAL SOCIATE",
    Address: "UITVAAL AREA  SECTION C NEXT TO MNCASIMBANA HARDWARE  MBENSE TC",
    Latitude: "-28.4746",
    Longitude: "30.2563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11232",
    WardLookupId: "1457",
    VDNumber: "43690619",
    RegisteredPopulation: "819",
    VotingStationName: "MNGAYI PRIMARY SCHOOL",
    Address:
      "KWAHLATHI   KWAHLATHI PASS CLINIC & TRIBAL CRT 1KM TURN RIGHT  WASBANK/SIGWEJE TRIBAL AUTHORITY",
    Latitude: "-28.4804",
    Longitude: "30.2853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11233",
    WardLookupId: "1457",
    VDNumber: "43690653",
    RegisteredPopulation: "662",
    VotingStationName: "SIGWEJE HIGH SCHOOL",
    Address: "KWAHLATHI WARD 2B  INDAKA  KUNENE TRIBAL AUTHORITY",
    Latitude: "-28.4773",
    Longitude: "30.2603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11234",
    WardLookupId: "1457",
    VDNumber: "43690675",
    RegisteredPopulation: "688",
    VotingStationName: "MKHULUNYELWA COMBINED PRIMARY SCHOOL",
    Address: "KWAHLATHI NO 1  INDAKA   KUNENE TRIBAL AUTHORITY",
    Latitude: "-28.4911",
    Longitude: "30.2658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11235",
    WardLookupId: "1457",
    VDNumber: "43690709",
    RegisteredPopulation: "810",
    VotingStationName: "MNDENI SENIOR PRIMARY SCHOOL",
    Address:
      "KWAHLATHI  EZIHLABATHINI NEXT TO COMMUNITY HALL  KUNENE TRIBAL AUTHORITY",
    Latitude: "-28.5185",
    Longitude: "30.2903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11236",
    WardLookupId: "1457",
    VDNumber: "43692330",
    RegisteredPopulation: "1677",
    VotingStationName: "HLATHI COMMUNITY HALL",
    Address: "KWAHLATHI  KWAHLATHI WARD 2B   KUNENE TRIBAL AUTHORITY",
    Latitude: "-28.4841",
    Longitude: "30.2511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11237",
    WardLookupId: "1458",
    VDNumber: "43621545",
    RegisteredPopulation: "476",
    VotingStationName: "NOORDENVELD INTERMEDIATE SCHOOL",
    Address: "NQUTU ROAD  FANKOMO  DUNDEE",
    Latitude: "-28.1972",
    Longitude: "30.4444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11238",
    WardLookupId: "1458",
    VDNumber: "43622007",
    RegisteredPopulation: "428",
    VotingStationName: "MTSHILO PRIMARY SCHOOL",
    Address: "KWAMTSHILO  WASBANK",
    Latitude: "-28.3573",
    Longitude: "30.1923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11239",
    WardLookupId: "1458",
    VDNumber: "43622849",
    RegisteredPopulation: "1004",
    VotingStationName: "WASBANK PRIMARY SCHOOL",
    Address: "LOT 101/102 LANDMAN ROAD  WASBANK  WASBANK",
    Latitude: "-28.3118",
    Longitude: "30.1097",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11240",
    WardLookupId: "1458",
    VDNumber: "43625314",
    RegisteredPopulation: "392",
    VotingStationName: "ETHIOPIAN CHURCH",
    Address: "ENDUMENI FARM  ENDUMENI  DUNDEE",
    Latitude: "-28.2161",
    Longitude: "30.2389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11241",
    WardLookupId: "1458",
    VDNumber: "43625909",
    RegisteredPopulation: "1568",
    VotingStationName: "STRATHFORD COMMUNITY HALL",
    Address: "STRATHFORD   KWANKOSI SITHOLE  KWANKOSI SITHOLE",
    Latitude: "-28.2066",
    Longitude: "30.2748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11242",
    WardLookupId: "1458",
    VDNumber: "43625987",
    RegisteredPopulation: "953",
    VotingStationName: "EBUSI PRIMARY SCHOOL",
    Address: "WASBANK  EBUSI  WASBANK",
    Latitude: "-28.3094",
    Longitude: "30.091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11243",
    WardLookupId: "1459",
    VDNumber: "43640052",
    RegisteredPopulation: "2162",
    VotingStationName: "DUNDEE JUNIOR SCHOOL",
    Address: "EXCELSIOR LANE  DUNDEE CENTRAL  DUNDEE",
    Latitude: "-28.1585",
    Longitude: "30.2287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11244",
    WardLookupId: "1459",
    VDNumber: "43640074",
    RegisteredPopulation: "1955",
    VotingStationName: "THE HORSE RIDING CLUB",
    Address:
      "WASBANK/ORANGE GROVE ROAD  ENDUMENI SPORT AND RECREATION GROUNDS  DUNDEE",
    Latitude: "-28.1805",
    Longitude: "30.2187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11245",
    WardLookupId: "1460",
    VDNumber: "43650020",
    RegisteredPopulation: "2893",
    VotingStationName: "AMAKHEMFUNDO PRIMARY SCHOOL",
    Address: "NDUMENI  SITHEMBILE  GLENCOE",
    Latitude: "-28.1945",
    Longitude: "30.1574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11246",
    WardLookupId: "1460",
    VDNumber: "43650042",
    RegisteredPopulation: "1860",
    VotingStationName: "EMPISINI PRIMARY SCHOOL",
    Address: "935 MZUVELE  SITHEMBILE  GLENCOE",
    Latitude: "-28.2031",
    Longitude: "30.1654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11247",
    WardLookupId: "1461",
    VDNumber: "43640029",
    RegisteredPopulation: "1533",
    VotingStationName: "ETHANGENI SECONDARY SCHOOL",
    Address: "44 CORNHILL STREET  DUNDEE CENTRAL  DUNDEE",
    Latitude: "-28.1669",
    Longitude: "30.2336",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11248",
    WardLookupId: "1461",
    VDNumber: "43640030",
    RegisteredPopulation: "2541",
    VotingStationName: "SIBONGILE ADMINISTRATION OFFICE",
    Address: "ZIGA SITHOLE STREET  SIBONGILE  DUNDEE",
    Latitude: "-28.1805",
    Longitude: "30.2397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11249",
    WardLookupId: "1462",
    VDNumber: "43622120",
    RegisteredPopulation: "361",
    VotingStationName: "ENYANYENI SCHOOL",
    Address: "NQUTU ROAD, MALONGENI FARM  DUNDEE  ENDUMENI",
    Latitude: "-28.143",
    Longitude: "30.352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11250",
    WardLookupId: "1462",
    VDNumber: "43640041",
    RegisteredPopulation: "1555",
    VotingStationName: "SIBONGILE COMMUNITY HALL",
    Address: "SIBISI STREET  SIBONGILE TOWNSHIP  DUNDEE",
    Latitude: "-28.1844",
    Longitude: "30.2369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11251",
    WardLookupId: "1462",
    VDNumber: "43640063",
    RegisteredPopulation: "2489",
    VotingStationName: "NEW TOWN HALL",
    Address: "BHAMBATHA  SIBONGILE  DUNDEE",
    Latitude: "-28.193",
    Longitude: "30.2343",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11252",
    WardLookupId: "1463",
    VDNumber: "43621994",
    RegisteredPopulation: "428",
    VotingStationName: "VEGKOP PRIMARY SCHOOL",
    Address: "NTIBANE FARM  KINGSLEY  DUNDEE",
    Latitude: "-28.113",
    Longitude: "30.5271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11253",
    WardLookupId: "1463",
    VDNumber: "43622119",
    RegisteredPopulation: "115",
    VotingStationName: "IKHWEZI PRIMARY SCHOOL",
    Address: "NYANYADU ROAD  MORRISTON FARM  DUNDEE",
    Latitude: "-28.1151",
    Longitude: "30.2513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11254",
    WardLookupId: "1463",
    VDNumber: "43622131",
    RegisteredPopulation: "239",
    VotingStationName: "ZISIZE PRIMARY SCHOOL",
    Address: "TAVETE FARM  DEJAGERS DRIFT  DUNDEE",
    Latitude: "-27.9863",
    Longitude: "30.3829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11255",
    WardLookupId: "1463",
    VDNumber: "43625325",
    RegisteredPopulation: "315",
    VotingStationName: "TAYSIDE SCHOOL",
    Address: "MENTIETH FARM  ENDUMENI",
    Latitude: "-28.0626",
    Longitude: "30.3695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11256",
    WardLookupId: "1463",
    VDNumber: "43640018",
    RegisteredPopulation: "2717",
    VotingStationName: "DUNDEE SECONDARY SCHOOL",
    Address: "17 BROWNING STREET  PEACEVALE  DUNDEE",
    Latitude: "-28.1584",
    Longitude: "30.2439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11257",
    WardLookupId: "1463",
    VDNumber: "43640085",
    RegisteredPopulation: "606",
    VotingStationName: "TALANA COMMUNITY HALL",
    Address: "EDISON STREET  FORESTDALE  DUNDEE",
    Latitude: "-28.1666",
    Longitude: "30.2522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11258",
    WardLookupId: "1464",
    VDNumber: "43621859",
    RegisteredPopulation: "222",
    VotingStationName: "THELAPHI CRECHE",
    Address: "LADYSMITH ROAD  THELAPHI  ENDUMENI",
    Latitude: "-28.237",
    Longitude: "30.0027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11259",
    WardLookupId: "1464",
    VDNumber: "43650019",
    RegisteredPopulation: "2003",
    VotingStationName: "GLENCOE TOWN HALL",
    Address: "CNR KARELLANDMAN- & BIGGAR STREET  GLENCOE",
    Latitude: "-28.178",
    Longitude: "30.1532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11260",
    WardLookupId: "1464",
    VDNumber: "43650031",
    RegisteredPopulation: "1289",
    VotingStationName: "GLENRIDGE HALL",
    Address: "28 SCHROEDERS  GLENRIDGE  GLENCOE",
    Latitude: "-28.1839",
    Longitude: "30.1481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11261",
    WardLookupId: "3626",
    VDNumber: "43620858",
    RegisteredPopulation: "942",
    VotingStationName: "KWANGQULU PRIMARY SCHOOL",
    Address:
      "00 KWANQULU AREA  NQUTU - QHUDENI  VULINDLELA TRADITIONAL AUTHORITY",
    Latitude: "-28.5916",
    Longitude: "30.6667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11262",
    WardLookupId: "3626",
    VDNumber: "43620869",
    RegisteredPopulation: "414",
    VotingStationName: "MATSHANA PRIMARY SCHOOL",
    Address:
      "00 MATSHANA AREA  NQUTU - QHUDENI  VULINDLELA TRADITIONAL AUTHORITY",
    Latitude: "-28.6089",
    Longitude: "30.7622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11263",
    WardLookupId: "3626",
    VDNumber: "43621006",
    RegisteredPopulation: "708",
    VotingStationName: "VULINDLELA TRIBAL COURT",
    Address: "00 BENGWANE  QHUDENI - NQUTU  VULINDLELA TRADITIONAL AUTHORITY",
    Latitude: "-28.5608",
    Longitude: "30.7894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11264",
    WardLookupId: "3626",
    VDNumber: "43621017",
    RegisteredPopulation: "623",
    VotingStationName: "NTABASIBAHLE PRIMARY SCHOOL",
    Address:
      "OKHALWENI AREA  QHUDENI - NQUTU  VULINDLELA TRADITIONAL AUTHORITY",
    Latitude: "-28.5881",
    Longitude: "30.7337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11265",
    WardLookupId: "3626",
    VDNumber: "43621073",
    RegisteredPopulation: "655",
    VotingStationName: "MANGENI HIGH SCHOOL",
    Address: "00 MANGENI AREA  MANGENI VILLAGE  NQUTU DISTRICT",
    Latitude: "-28.4688",
    Longitude: "30.7722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11266",
    WardLookupId: "3626",
    VDNumber: "43621084",
    RegisteredPopulation: "823",
    VotingStationName: "HLALELE COMBINED PRIMARY SCHOOL",
    Address: "00 MANXILI AREA  HLAZAKAZI-NQUTU  MANDLENI TRADITIONAL AUTHORITY",
    Latitude: "-28.5114",
    Longitude: "30.7553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11267",
    WardLookupId: "3626",
    VDNumber: "43624885",
    RegisteredPopulation: "789",
    VotingStationName: "GWIJA PRIMARY SCHOOL",
    Address: "QHUDENI  VULINDLELA  NQUTU",
    Latitude: "-28.6343",
    Longitude: "30.7209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11268",
    WardLookupId: "3627",
    VDNumber: "43621051",
    RegisteredPopulation: "375",
    VotingStationName: "ZAMOKWAKHE HIGH SCHOOL",
    Address: "HLAZAKAZI  NQUTU  MANDLENI TRADITIONAL AUTHORITY",
    Latitude: "-28.3987",
    Longitude: "30.7099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11269",
    WardLookupId: "3627",
    VDNumber: "43621062",
    RegisteredPopulation: "759",
    VotingStationName: "MBOKODWEBOMVU TRIBAL AUTHORITY",
    Address:
      "00 ISILUTSHANA AREA  ISILUTSHANA-NQUTU  MBOKODWEBOMVU TRIBAL AUTHORITY",
    Latitude: "-28.4128",
    Longitude: "30.7798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11270",
    WardLookupId: "3627",
    VDNumber: "43621141",
    RegisteredPopulation: "643",
    VotingStationName: "MFEKA PRIMARY SCHOOL",
    Address: "00 MFEKA AREA  HLAZAKAZI-NQUTU  MFEKA AREA",
    Latitude: "-28.4446",
    Longitude: "30.7045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11271",
    WardLookupId: "3627",
    VDNumber: "43621185",
    RegisteredPopulation: "571",
    VotingStationName: "ISILUTSHANA PRIMARY SCHOOL",
    Address:
      "00 ISILUTSHANA AREA  ISILUTSHANA- NQUTU  MBOKODWEBOMVU TRIBAL AUTHORITY",
    Latitude: "-28.3853",
    Longitude: "30.7768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11272",
    WardLookupId: "3627",
    VDNumber: "43622692",
    RegisteredPopulation: "749",
    VotingStationName: "NKUNYANA PRIMARY SCHOOL",
    Address: "00 NKUNYANA AREA  NKUNYANA VILLAGE  NQUTU DISTRICT",
    Latitude: "-28.4329",
    Longitude: "30.6421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11273",
    WardLookupId: "3627",
    VDNumber: "43622872",
    RegisteredPopulation: "388",
    VotingStationName: "KHULAKANCANE CRECHE`",
    Address: "MNGXANGALA VILLAGE  NQUTHU  MBOKODWEBOMVU TRADITIONAL AUTHORITY",
    Latitude: "-28.3793",
    Longitude: "30.7504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11274",
    WardLookupId: "3627",
    VDNumber: "43622883",
    RegisteredPopulation: "636",
    VotingStationName: "MAGOGO PRIMARY SCHOOL",
    Address: "00 MAGOGO AREA  MAGOGO VILLAGE  NQUTU DISTRICT",
    Latitude: "-28.4567",
    Longitude: "30.8042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11275",
    WardLookupId: "3627",
    VDNumber: "43625213",
    RegisteredPopulation: "413",
    VotingStationName: "KWANDWALANE SCHOOL PRIMARY",
    Address: "NDWALLNE AREA  NDWALANE-NQUTU  MBOKODWEBOMVU TRIBAL AUTHORITY",
    Latitude: "-28.3927",
    Longitude: "30.8044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11276",
    WardLookupId: "3627",
    VDNumber: "43625392",
    RegisteredPopulation: "204",
    VotingStationName: "SHESHANI PRIMARY SCHOOL",
    Address:
      "NO HLAZAKAZI AREA  HLAZAKAZI -NQUTU  EMANDLENI TRADITIONA AUTHORITY",
    Latitude: "-28.4319",
    Longitude: "30.734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11277",
    WardLookupId: "3627",
    VDNumber: "43625448",
    RegisteredPopulation: "241",
    VotingStationName: "REFORMED CHURCH",
    Address: "NO KWAQWABE AREA  NQUTU  MBOKODWEBOMVU TRIBAL AUTHORITY",
    Latitude: "-28.3706",
    Longitude: "30.73",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11278",
    WardLookupId: "3627",
    VDNumber: "43625752",
    RegisteredPopulation: "305",
    VotingStationName: "KLWAYISI PRIMARY SCHOOL",
    Address: "KWAHLAZAKAZI AREA  NQUTHU  NQUTHU",
    Latitude: "-28.4119",
    Longitude: "30.6688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11279",
    WardLookupId: "3628",
    VDNumber: "43501070",
    RegisteredPopulation: "1262",
    VotingStationName: "SICELIMFUNDO COMBINED PRIMARY SCHOOL",
    Address: "HLABAMKHOSI AREA  NQABENI - NQUTU  JAMA TRADITIONAL AUTHORITY",
    Latitude: "-28.3793",
    Longitude: "30.9052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11280",
    WardLookupId: "3628",
    VDNumber: "43503577",
    RegisteredPopulation: "348",
    VotingStationName: "SHEKELELA PRIMARY SCHOOL",
    Address:
      "ON THE MAIN ROAD TO NQUTHU FROM BABANANGO SIDE  BABANANGO AREA, ON THE MAIN ROAD TO NQUTHU  ULUNDI",
    Latitude: "-28.3737",
    Longitude: "30.8242",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11281",
    WardLookupId: "3628",
    VDNumber: "43503588",
    RegisteredPopulation: "679",
    VotingStationName: "KLWANA PRIMARY SCHOOL",
    Address: "MAIN ROAD TO NQUTHU FROM BABANANGO SIDE  BABANANGO AREA  ULUNDI",
    Latitude: "-28.4093",
    Longitude: "30.879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11282",
    WardLookupId: "3628",
    VDNumber: "43504196",
    RegisteredPopulation: "320",
    VotingStationName: "NKOSI JIYANE PRIMARY SCHOOL",
    Address: "MNGXANGALA VILLAGE  NQUTHU  JAMA TRADITIONAL AUTHORITY",
    Latitude: "-28.4349",
    Longitude: "30.8344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11283",
    WardLookupId: "3628",
    VDNumber: "43621231",
    RegisteredPopulation: "485",
    VotingStationName: "ZONDI TRIBAL COURT",
    Address: "00 KWANYEZI AREA  KWANYEZI NQUTU  ZONDI TRADITIONAL AUTHORITY",
    Latitude: "-28.3469",
    Longitude: "30.7543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11284",
    WardLookupId: "3628",
    VDNumber: "43621242",
    RegisteredPopulation: "344",
    VotingStationName: "NOMDUMO HIGH SCHOOL",
    Address: "00 KWANYEZI AREA  KWANYEZI VILLAGE  ZONDI TRADITIONAL COURT",
    Latitude: "-28.3349",
    Longitude: "30.7542",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11285",
    WardLookupId: "3628",
    VDNumber: "43624920",
    RegisteredPopulation: "606",
    VotingStationName: "NHLENGILE PRIMARY SCHOOL",
    Address: "BABANANGO AREA, ON THE MAIN ROAD TO NQUTHU  NQUTHU",
    Latitude: "-28.3846",
    Longitude: "30.9537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11286",
    WardLookupId: "3628",
    VDNumber: "43625415",
    RegisteredPopulation: "317",
    VotingStationName: "FAHLAZA PRIMARY SCHOOL",
    Address: "FAHLAZA  ISILUTSHANA  NQUTU",
    Latitude: "-28.359",
    Longitude: "30.7745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11287",
    WardLookupId: "3629",
    VDNumber: "43500080",
    RegisteredPopulation: "940",
    VotingStationName: "MACHITSHANA PRIMARY SCHOOL",
    Address: "MACHITSHANA ROAD  MACHITSHANA AREA  JAMA TRIBAL AUTHORITY",
    Latitude: "-28.2167",
    Longitude: "30.99",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11288",
    WardLookupId: "3629",
    VDNumber: "43501069",
    RegisteredPopulation: "309",
    VotingStationName: "NHLOPHENI HIGH SCHOOL",
    Address: "MHLOPHENI MAIN ROAD  ZWELISHA RESERVE  ULUNDI",
    Latitude: "-28.1989",
    Longitude: "31.0175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11289",
    WardLookupId: "3629",
    VDNumber: "43621163",
    RegisteredPopulation: "1245",
    VotingStationName: "JAMA TRIBAL COURT",
    Address: "ABATHWA LP  BABANANGO AREA  NEXT TO NTININI STORE",
    Latitude: "-28.2687",
    Longitude: "30.8977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11290",
    WardLookupId: "3629",
    VDNumber: "43621523",
    RegisteredPopulation: "1113",
    VotingStationName: "NHLABAMKHOSI PRIMARY SCHOOL",
    Address: "00 SEVEN AREA  NTININI VILLAGE  NQUTU DISTRICT",
    Latitude: "-28.3272",
    Longitude: "30.9039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11291",
    WardLookupId: "3629",
    VDNumber: "43624784",
    RegisteredPopulation: "338",
    VotingStationName: "ZWELISHA PRIMARY SCHOOL",
    Address: "ZWELISHA MAIN ROAD  ZWELISHA VILLAGE  JAMA TRIBAL AUTHORITY",
    Latitude: "-28.168",
    Longitude: "30.9999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11292",
    WardLookupId: "3629",
    VDNumber: "43625651",
    RegisteredPopulation: "462",
    VotingStationName: "BATHWA PRIMARY SCHOOL",
    Address: "OGAZINI AREA  NQUTHU  JAMA TRADITIONAL AUTHORITY",
    Latitude: "-28.2565",
    Longitude: "30.9467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11293",
    WardLookupId: "3630",
    VDNumber: "43621174",
    RegisteredPopulation: "915",
    VotingStationName: "HLINZEKA HIGHER PRIMARY SCHOOL",
    Address: "VUMANKALA  VUMANKALA AREA  JAMA TRIBAL AUTHORITY",
    Latitude: "-28.3157",
    Longitude: "30.8369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11294",
    WardLookupId: "3630",
    VDNumber: "43621309",
    RegisteredPopulation: "598",
    VotingStationName: "HWANQANA INTERMEDIATE COMBINED SCHOOL",
    Address: "HWANQANA AREA  ULUNDI",
    Latitude: "-28.2",
    Longitude: "30.9051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11295",
    WardLookupId: "3630",
    VDNumber: "43622850",
    RegisteredPopulation: "913",
    VotingStationName: "NYAKAZA PRIMARY SCHOOL",
    Address: "00 DUDUDZA AREA  DUDUZA VILLAGE  NQUTU DISTRICT",
    Latitude: "-28.2479",
    Longitude: "30.8438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11296",
    WardLookupId: "3630",
    VDNumber: "43624795",
    RegisteredPopulation: "531",
    VotingStationName: "SIYATHUTHUKA SEWING ROOM",
    Address: "FIVE AREA  NONDWENI-NQUTU  SIZAMILE TRIBAL AUTHORITY",
    Latitude: "-28.1837",
    Longitude: "30.8328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11297",
    WardLookupId: "3630",
    VDNumber: "43625279",
    RegisteredPopulation: "691",
    VotingStationName: "EKUKHANYENI PRIMARY SCHOOL",
    Address: "NONDWENI  FIVE SECTION  [NQUTU]",
    Latitude: "-28.1791",
    Longitude: "30.8428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11298",
    WardLookupId: "3630",
    VDNumber: "43625471",
    RegisteredPopulation: "300",
    VotingStationName: "NOMASHAKA PRIMARY SCHOOL",
    Address: "BABANANGO TO VRYHEID ROAD  BARKLESIDE AREA  NQUTU",
    Latitude: "-28.1635",
    Longitude: "30.871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11299",
    WardLookupId: "3630",
    VDNumber: "43625921",
    RegisteredPopulation: "324",
    VotingStationName: "BUHLEBUYEZA  PRIMARY SCHOOL",
    Address: "NONDWENI ROAD  NONDWENI-NQUTHU  NHLAMBAMASOKA TRIBAL AUTHORITY",
    Latitude: "-28.1871",
    Longitude: "30.8632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11300",
    WardLookupId: "3631",
    VDNumber: "43621512",
    RegisteredPopulation: "962",
    VotingStationName: "SIZAMILE TRIBAL COURT",
    Address:
      "00 NONDWENI TOWNSHIP  NONDWENI VILLAGE  HLATSHWAYO TRIBAL AUTHORITY",
    Latitude: "-28.1852",
    Longitude: "30.8041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11301",
    WardLookupId: "3631",
    VDNumber: "43621534",
    RegisteredPopulation: "722",
    VotingStationName: "SIYABUSWA PRIMARY SCHOOL",
    Address:
      "00 MAHLUNGULU AREA  MAHLUNGULU VILLAGE  HLATSHWAYO TRADITIONAL AUTHORITY",
    Latitude: "-28.2093",
    Longitude: "30.8066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11302",
    WardLookupId: "3631",
    VDNumber: "43624919",
    RegisteredPopulation: "1794",
    VotingStationName: "LANGAZELA HIGH SCHOOL",
    Address: "NONDWENI  NONDWENI VILLAGE  HLATSHWAYO TRADITIONAL AUTHORITY",
    Latitude: "-28.1927",
    Longitude: "30.816",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11303",
    WardLookupId: "3631",
    VDNumber: "43625268",
    RegisteredPopulation: "874",
    VotingStationName: "SIYACATHULA SCHOOL",
    Address: "NO TINTOWN AREA  NONDWENI-NQUTU  SIZAMILE TRIBAL AUTHORITY",
    Latitude: "-28.1936",
    Longitude: "30.8218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11304",
    WardLookupId: "3631",
    VDNumber: "43625730",
    RegisteredPopulation: "415",
    VotingStationName: "BAMBUHLANGA CRECHE",
    Address:
      "EMAHLUNGULU AREA  EMAHLUNGULU -NQUTU  NHLAMBAMASOKA TRIBAL AUTHORITY",
    Latitude: "-28.2104",
    Longitude: "30.8288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11305",
    WardLookupId: "3632",
    VDNumber: "43621422",
    RegisteredPopulation: "914",
    VotingStationName: "MHLUNGWANA HALL",
    Address:
      "NO  MHLUNGWANA AREA  NQUTU-MHLUNGWANA  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.1433",
    Longitude: "30.8638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11306",
    WardLookupId: "3632",
    VDNumber: "43621466",
    RegisteredPopulation: "635",
    VotingStationName: "HLOMISA PRIMARY SCHOOL",
    Address: "00 MAGABENG AREA  MAGABENG - NQUTU  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.1511",
    Longitude: "30.7123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11307",
    WardLookupId: "3632",
    VDNumber: "43621488",
    RegisteredPopulation: "1089",
    VotingStationName: "MADULADULA PRIMARY SCHOOL",
    Address:
      "00 MADULADULA AREA  MADULADULA- NQUTU  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.1994",
    Longitude: "30.7526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11308",
    WardLookupId: "3632",
    VDNumber: "43621501",
    RegisteredPopulation: "538",
    VotingStationName: "MPHONDI LOWER PRIMARY SCHOOL",
    Address: "00 MPHONDI AREA  MPHONDI - NQUTU  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.1552",
    Longitude: "30.77",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11309",
    WardLookupId: "3632",
    VDNumber: "43625617",
    RegisteredPopulation: "525",
    VotingStationName: "MANDLENYOSI PRIMARY SCHOOL",
    Address: "DUDELA AREA  DUDULA - NQUTU  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.1739",
    Longitude: "30.8168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11310",
    WardLookupId: "3632",
    VDNumber: "43625741",
    RegisteredPopulation: "193",
    VotingStationName: "THUTHUKANI CRECHE",
    Address: "MAPAYIPINI AREA  MPHONDI - NQUTU  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.1359",
    Longitude: "30.8023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11311",
    WardLookupId: "3632",
    VDNumber: "43625763",
    RegisteredPopulation: "264",
    VotingStationName: "NSEKWINI CRECHE",
    Address: "ENSEKWINI AREA  NDINDINDI AREA  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.16",
    Longitude: "30.6715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11312",
    WardLookupId: "3633",
    VDNumber: "43621219",
    RegisteredPopulation: "418",
    VotingStationName: "MABULULWANA COMBINED PRIMARY SCHOOL",
    Address: "00 MABULULWANE  MABULULWANA VILLAGE  ZONDI TRADITIONAL AUTHORITY",
    Latitude: "-28.2977",
    Longitude: "30.7731",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11313",
    WardLookupId: "3633",
    VDNumber: "43621310",
    RegisteredPopulation: "716",
    VotingStationName: "PATSOANA PRIMARY SCHOOL",
    Address: "00 PATSOANA  PATSOANA VILLAGE  NQUTHU VILLAGE",
    Latitude: "-28.245",
    Longitude: "30.7806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11314",
    WardLookupId: "3633",
    VDNumber: "43621332",
    RegisteredPopulation: "271",
    VotingStationName: "MAHOLELA COMBINED PRIMARY SCHOOL",
    Address: "00 KWAVUNA AREA  KWAVUNA VILLAGE  KHIPHINKUZI TRIBAL AUTHORITY",
    Latitude: "-28.2872",
    Longitude: "30.7621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11315",
    WardLookupId: "3633",
    VDNumber: "43621365",
    RegisteredPopulation: "915",
    VotingStationName: "VEZINHLANHLA PRIMARY SCHOOL",
    Address:
      "00 MAGONGOLOZA AREA  MAGONGOLOZA VILLAGE  KHIPHINKUNZI TRIBAL AUTHORITY",
    Latitude: "-28.1992",
    Longitude: "30.7039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11316",
    WardLookupId: "3633",
    VDNumber: "43621376",
    RegisteredPopulation: "690",
    VotingStationName: "MBEWUNYE PRIMARY SCHOOL",
    Address: "00 MBEWUNYE AREA  MBEWUNYE VILLAGE  NQUTU DISTRICT",
    Latitude: "-28.236",
    Longitude: "30.728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11317",
    WardLookupId: "3633",
    VDNumber: "43621499",
    RegisteredPopulation: "785",
    VotingStationName: "KHIPHINKUNZI TRIBAL COURT",
    Address:
      "00 MBEWUNYE AREA  KHIPHINKUNZI VILLAGE  KHIPHINKUNZI TRIBAL AUTHORITY",
    Latitude: "-28.2052",
    Longitude: "30.7236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11318",
    WardLookupId: "3633",
    VDNumber: "43626045",
    RegisteredPopulation: "288",
    VotingStationName: "LESEDI CRECHE",
    Address: "KWAZONDI AREA  NQUTHU TOWN  ZONDI TRIBAL AUTHORITY",
    Latitude: "-28.2821",
    Longitude: "30.7904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11319",
    WardLookupId: "3633",
    VDNumber: "43626056",
    RegisteredPopulation: "443",
    VotingStationName: "CASSINO PRIMARY SCHOOL",
    Address: "CASSINO  NQUTU  NQUTHU MUNICIPAL COUNCIL",
    Latitude: "-28.2059",
    Longitude: "30.6806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11320",
    WardLookupId: "3634",
    VDNumber: "43621253",
    RegisteredPopulation: "688",
    VotingStationName: "BUHLEBAMANGWE PRIMARY SCHOOL",
    Address:
      "00 KHEYI SECTION  ISANDLWANA-NQUTU  MANGWEBUTHANANI TRADITIONAL AUTHORITY",
    Latitude: "-28.331",
    Longitude: "30.7054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11321",
    WardLookupId: "3634",
    VDNumber: "43621264",
    RegisteredPopulation: "539",
    VotingStationName: "GADELENI HIGH SCHOOL",
    Address:
      "00 MISSION AREA  ISANDLWANA-NQUTU  MANGWEBUTHANANI TRADITIONAL AUTHORITY",
    Latitude: "-28.3433",
    Longitude: "30.6627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11322",
    WardLookupId: "3634",
    VDNumber: "43621444",
    RegisteredPopulation: "707",
    VotingStationName: "PHAKATHWAYO HIGH SCHOOL",
    Address:
      "00 NGWEBINI AREA  NQUTU NGWEBINI  KHIPHINKUNZI TRADITIONAL AUTHORITY",
    Latitude: "-28.3066",
    Longitude: "30.7104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11323",
    WardLookupId: "3634",
    VDNumber: "43622579",
    RegisteredPopulation: "1154",
    VotingStationName: "NTANYANDLOVU PRIMARY SCHOOL",
    Address:
      "BABANANGO ROAD (SUNRISE AREA)  NQUTU-SUNRISE  KHIPHINKUNZI TRADITIONAL AUTHORITY",
    Latitude: "-28.2685",
    Longitude: "30.7021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11324",
    WardLookupId: "3634",
    VDNumber: "43625235",
    RegisteredPopulation: "553",
    VotingStationName: "MANGWEBUTHANANI TRADITIONAL COURT",
    Address: "ISANDLWANA  MAGAGA  MAZIBUKO TRADITIONAL AUTHORITY",
    Latitude: "-28.327",
    Longitude: "30.6638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11325",
    WardLookupId: "3634",
    VDNumber: "43625404",
    RegisteredPopulation: "693",
    VotingStationName: "DUTCH REFORMED CHURCH",
    Address:
      "SHAYAMOYA AREA  SHAYAMOYA NQUTU  KHIPHINKUNZI TRADITIONAL AUTHORITY",
    Latitude: "-28.2702",
    Longitude: "30.6947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11326",
    WardLookupId: "3634",
    VDNumber: "43625460",
    RegisteredPopulation: "405",
    VotingStationName: "CEBELIHLE PRIMARY SCHOOL",
    Address: "ESIGQUMENI AREA  NQUTU  KHIPHINKUNZI TRADITIONAL AUTHORITY",
    Latitude: "-28.3231",
    Longitude: "30.7299",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11327",
    WardLookupId: "3635",
    VDNumber: "43621107",
    RegisteredPopulation: "373",
    VotingStationName: "NHLOYA PRIMARY SCHOOL",
    Address: "NHLOYA  NHLOYA AREA  MANGWEBUTHANANI TRIBAL AUTHORITY",
    Latitude: "-28.3741",
    Longitude: "30.65",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11328",
    WardLookupId: "3635",
    VDNumber: "43621118",
    RegisteredPopulation: "1269",
    VotingStationName: "BUZUBONA PRIMARY SCHOOL",
    Address: "00 NGONINI  NGONINI VILLAGE  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.3253",
    Longitude: "30.5512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11329",
    WardLookupId: "3635",
    VDNumber: "43621196",
    RegisteredPopulation: "715",
    VotingStationName: "MASOTSHENI PRIMARY SCHOOL",
    Address: "MASOTSHENI AREA  MASOTSHENI  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.2852",
    Longitude: "30.5774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11330",
    WardLookupId: "3635",
    VDNumber: "43621275",
    RegisteredPopulation: "837",
    VotingStationName: "NCEPHENI HIGHER PRIMARY SCHOOL",
    Address:
      "00 NCEPHENI AREA  NCEPHENI VILLAGE  KHIPHINKUNZI TRADITIONAL AUTHORITY",
    Latitude: "-28.3408",
    Longitude: "30.6118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11331",
    WardLookupId: "3635",
    VDNumber: "43621433",
    RegisteredPopulation: "814",
    VotingStationName: "NGEDLA HIGHER PRIMARY SCHOOL",
    Address: "00 KWANGEDLA  NGEDLA VILLAGE  KHIPHINKUNZI TRADITIONAL AUTHORITY",
    Latitude: "-28.2872",
    Longitude: "30.6209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11332",
    WardLookupId: "3635",
    VDNumber: "43625202",
    RegisteredPopulation: "892",
    VotingStationName: "MHLAZANE PRIMARY SCHOOL",
    Address: "ESIGUBUDU  [NQUTU]",
    Latitude: "-28.3549",
    Longitude: "30.6091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11333",
    WardLookupId: "3635",
    VDNumber: "43625437",
    RegisteredPopulation: "326",
    VotingStationName: "MATHUTSHANA PRIMARY SCHOOL",
    Address: "MATHUTSHANA ROAD  MATHUTSHANA  MANGWEBUTHANANI TRIBAL AUTHORITY",
    Latitude: "-28.3604",
    Longitude: "30.6793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11334",
    WardLookupId: "3636",
    VDNumber: "43621343",
    RegisteredPopulation: "615",
    VotingStationName: "ETHEMBENI STORE",
    Address:
      "NTANYANDLOVU AREA  NTANYANDLOVU NQUTU  KHIPHINKUNZI TRADITIONAL AUTHORITY",
    Latitude: "-28.286",
    Longitude: "30.6726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11335",
    WardLookupId: "3636",
    VDNumber: "43621400",
    RegisteredPopulation: "1494",
    VotingStationName: "THULASIZWE COMBINED PRIMARY SCHOOL",
    Address: "00 GUBAZI AREA  GUBAZI VILLAGE  NQUTHU DISTRICT",
    Latitude: "-28.2312",
    Longitude: "30.6474",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11336",
    WardLookupId: "3636",
    VDNumber: "43622580",
    RegisteredPopulation: "286",
    VotingStationName: "BATSHE SCHOOL",
    Address: "00 BATSHE AREA  BATSHE VILLAGE  NQUTHU DISTRICT",
    Latitude: "-28.2659",
    Longitude: "30.6387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11337",
    WardLookupId: "3636",
    VDNumber: "43625066",
    RegisteredPopulation: "820",
    VotingStationName: "NTSHISEKELO",
    Address: "MTSHONGWENI   NQUTU  KHIPHINKUNZI",
    Latitude: "-28.2484",
    Longitude: "30.6329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11338",
    WardLookupId: "3636",
    VDNumber: "43625246",
    RegisteredPopulation: "926",
    VotingStationName: "GOODSHEPERD CHURCH",
    Address: "NKALANKALA AREA  NQUTU  NQUTU NU",
    Latitude: "-28.2544",
    Longitude: "30.6916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11339",
    WardLookupId: "3636",
    VDNumber: "43625459",
    RegisteredPopulation: "867",
    VotingStationName: "MEHLOKAZULU PRE-SCHOOL",
    Address: "MFONGOMFONGO  MFONGOMFONGO AREA  NQUTU",
    Latitude: "-28.2415",
    Longitude: "30.6848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11340",
    WardLookupId: "3636",
    VDNumber: "43626168",
    RegisteredPopulation: "520",
    VotingStationName: "ZAKHENI CRECHE",
    Address: "VULAMEHLO  NQUTU  KHIPHINKUNZI",
    Latitude: "-28.2224",
    Longitude: "30.6578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11341",
    WardLookupId: "3637",
    VDNumber: "43621208",
    RegisteredPopulation: "967",
    VotingStationName: "NCOME PRIMARY SCHOOL",
    Address: "00 HLATI DAM  HLATI DAM VILLAGE  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.234",
    Longitude: "30.5223",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11342",
    WardLookupId: "3637",
    VDNumber: "43621321",
    RegisteredPopulation: "1717",
    VotingStationName: "SPRINGLAKE HIGH SCHOOL",
    Address:
      "00 HLATHI DAM AREA  HLATI DAM VILLAGE  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.2344",
    Longitude: "30.5541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11343",
    WardLookupId: "3637",
    VDNumber: "43621398",
    RegisteredPopulation: "591",
    VotingStationName: "PHUMLANI HIGH SCHOOL",
    Address: "MASHESHELENI AREA  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.2351",
    Longitude: "30.6102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11344",
    WardLookupId: "3637",
    VDNumber: "43622524",
    RegisteredPopulation: "691",
    VotingStationName: "JABAVU PRIMARY SCHOOL",
    Address: "00 JABAVU AREA  JABAVU VILLAGE  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.271",
    Longitude: "30.5363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11345",
    WardLookupId: "3637",
    VDNumber: "43625190",
    RegisteredPopulation: "665",
    VotingStationName: "PHILANI SCHOOL",
    Address: "MAFIHLENG TO MASOTSHENI ROAD  MQHEDLANA AREA  [NQUTU]",
    Latitude: "-28.263",
    Longitude: "30.5895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11346",
    WardLookupId: "3637",
    VDNumber: "43625628",
    RegisteredPopulation: "179",
    VotingStationName: "DALALA PRIMARY SCHOOL",
    Address: "HLATHI  HLATHI DAM  MOLEFE TRADITIONAL COUNCIL",
    Latitude: "-28.2372",
    Longitude: "30.5711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11347",
    WardLookupId: "3637",
    VDNumber: "43626179",
    RegisteredPopulation: "282",
    VotingStationName: "MZIMELA PRIMARY SCHOOL",
    Address: "EZINTABENI EZIBOMVU  NQUTU  MOLEFE",
    Latitude: "-28.2464",
    Longitude: "30.5447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11348",
    WardLookupId: "3638",
    VDNumber: "43621354",
    RegisteredPopulation: "1092",
    VotingStationName: "QEDUSIZI SENIOR PRIMARY SCHOOL",
    Address:
      "00 NDINDINDI AREA  NDINDINDI NQUTU  KHIPHINKUNZI TRADITIONAL AUTHORITY",
    Latitude: "-28.1848",
    Longitude: "30.66",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11349",
    WardLookupId: "3638",
    VDNumber: "43621455",
    RegisteredPopulation: "885",
    VotingStationName: "NHLALAKAHLE SECONDARY SCHOOL",
    Address: "00 NDINDINDI  NDINDINDI VILLAGE  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.1708",
    Longitude: "30.6552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11350",
    WardLookupId: "3638",
    VDNumber: "43621477",
    RegisteredPopulation: "1274",
    VotingStationName: "ZICOLE SENIOR SECONDARY SCHOOL",
    Address: "00 MKHONJANE ROAD  ZICOLE VILLAGE  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.1817",
    Longitude: "30.6274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11351",
    WardLookupId: "3638",
    VDNumber: "43625178",
    RegisteredPopulation: "538",
    VotingStationName: "MOLEFE TRADITIONAL COURT",
    Address: "MKHONJANE  MKHONJANE AREA  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.183",
    Longitude: "30.6038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11352",
    WardLookupId: "3638",
    VDNumber: "43625639",
    RegisteredPopulation: "765",
    VotingStationName: "QEDIPHIKA PRIMARY SCHOOL",
    Address: "KWABIYA  NQUTU  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.1574",
    Longitude: "30.6426",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11353",
    WardLookupId: "3638",
    VDNumber: "43625640",
    RegisteredPopulation: "567",
    VotingStationName: "MAFIHLENG SCHOOL",
    Address: "MAFIHLENG   NQUTU  MOLEFE TA",
    Latitude: "-28.2101",
    Longitude: "30.6204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11354",
    WardLookupId: "3639",
    VDNumber: "43621387",
    RegisteredPopulation: "1208",
    VotingStationName: "LUVISI SCHOOL",
    Address: "LUVISI  NQUTU  NQUTU",
    Latitude: "-28.2079",
    Longitude: "30.6586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11355",
    WardLookupId: "3639",
    VDNumber: "43624908",
    RegisteredPopulation: "1296",
    VotingStationName: "MAGISTRATE COURT",
    Address: "BABANANGO ROAD  NQUTU  NQUTHU MUNICIPAL COUNCIL",
    Latitude: "-28.2139",
    Longitude: "30.6779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11356",
    WardLookupId: "3639",
    VDNumber: "43625189",
    RegisteredPopulation: "1036",
    VotingStationName: "ZION CHURCH",
    Address: "LUVISI TO ZICOLE ROAD  LUVISI AREA  NQUTHU MUNICIPAL COUNCIL",
    Latitude: "-28.2018",
    Longitude: "30.6552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11357",
    WardLookupId: "3639",
    VDNumber: "43625257",
    RegisteredPopulation: "1681",
    VotingStationName: "MGAZI PRIMARY SCHOOL",
    Address: "LOT 1557 MANGOSUTHU DRIVE   NQUTHU TOWN, 3135  [NQUTU]",
    Latitude: "-28.2234",
    Longitude: "30.6708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11358",
    WardLookupId: "3640",
    VDNumber: "43621411",
    RegisteredPopulation: "1115",
    VotingStationName: "ISIBUKOSABASHA COMBINED PRIMARY SCHOOL",
    Address: "00 EZIQHAZENI  KWAMACEBA VILLAGE  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.2202",
    Longitude: "30.5757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11359",
    WardLookupId: "3640",
    VDNumber: "43621624",
    RegisteredPopulation: "774",
    VotingStationName: "NQUTU LUTHERAN COMBINED SCHOOL",
    Address:
      "00 THELEZINI RESERVE  THELEZINI VILLAGE  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.1227",
    Longitude: "30.6301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11360",
    WardLookupId: "3640",
    VDNumber: "43621635",
    RegisteredPopulation: "215",
    VotingStationName: "MKHONJANE COMBINED PRIMARY SCHOOL",
    Address:
      "00 ST JAMES MISSION  MKHONJANE VILLAGE  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.1721",
    Longitude: "30.5538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11361",
    WardLookupId: "3640",
    VDNumber: "43621646",
    RegisteredPopulation: "1157",
    VotingStationName: "BUHLEBUZOVANA PRIMARY SCHOOL",
    Address:
      "00 MKHONJANE RESERVE  MKHONJANE VILLAGE  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.1509",
    Longitude: "30.5763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11362",
    WardLookupId: "3640",
    VDNumber: "43622591",
    RegisteredPopulation: "300",
    VotingStationName: "BAYABONGA PRIMARY SCHOOL",
    Address: "ST. JOHN MISSION THELEZINI  NQUTU  MOLEFE",
    Latitude: "-28.1148",
    Longitude: "30.6023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11363",
    WardLookupId: "3640",
    VDNumber: "43625527",
    RegisteredPopulation: "539",
    VotingStationName: "LINDOKUHLE PRIMARY SCHOOL",
    Address: "NQUTU TO NCOME MUSEUM ROAD  MATHAMBO AREA  NQUTU",
    Latitude: "-28.0959",
    Longitude: "30.5699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11364",
    WardLookupId: "3640",
    VDNumber: "43625785",
    RegisteredPopulation: "238",
    VotingStationName: "SIYABONGA PRIMARY SCHOOL",
    Address: "MACEBA AREA  NQUTHU  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.2174",
    Longitude: "30.5546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11365",
    WardLookupId: "3640",
    VDNumber: "43625796",
    RegisteredPopulation: "260",
    VotingStationName: "MUNYWANA PRIMARY SCHOOL",
    Address: "MUNYWANA AREA  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.1519",
    Longitude: "30.6143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11366",
    WardLookupId: "3640",
    VDNumber: "43626067",
    RegisteredPopulation: "470",
    VotingStationName: "KWADOPHI SCHOOL",
    Address: "KWADOPHI AREA  NQUTHU TOWN  MOLEFE TRIBAL AUTHORITY",
    Latitude: "-28.1677",
    Longitude: "30.5306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11367",
    WardLookupId: "3641",
    VDNumber: "43621567",
    RegisteredPopulation: "661",
    VotingStationName: "CELUMUSA SENIOR SECONDARY SCHOOL",
    Address: "00 HADULA  HALADU VILLAGE  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.0282",
    Longitude: "30.6165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11368",
    WardLookupId: "3641",
    VDNumber: "43621602",
    RegisteredPopulation: "601",
    VotingStationName: "NDATSHANA PRIMARY SCHOOL",
    Address:
      "00 NDATSHANE RESERVE  NDATSHANA VILLAGE  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.0928",
    Longitude: "30.5947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11369",
    WardLookupId: "3641",
    VDNumber: "43621613",
    RegisteredPopulation: "455",
    VotingStationName: "MPHAZIMA PRIMARY SCHOOL",
    Address:
      "00 MPHAZIMA RESERVE  NKANDE VILLAGE  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.0666",
    Longitude: "30.6309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11370",
    WardLookupId: "3641",
    VDNumber: "43621770",
    RegisteredPopulation: "1026",
    VotingStationName: "LENEHA TUMISA SENIOR SECONDARY SCHOOL",
    Address: "00 NKANDE RESERVE  NKANDE VILLAGE  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-27.9985",
    Longitude: "30.6224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11371",
    WardLookupId: "3641",
    VDNumber: "43625426",
    RegisteredPopulation: "609",
    VotingStationName: "THEMBEKILE PRIMARY SCHOOL",
    Address: "MHLANGENI  MHLANGENI AREA  NQUTU",
    Latitude: "-28.0903",
    Longitude: "30.6334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11372",
    WardLookupId: "3641",
    VDNumber: "43625774",
    RegisteredPopulation: "350",
    VotingStationName: "SIYAMTHANDA PRIMARY SCHOOL",
    Address: "THELEZINI AREA  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-28.1079",
    Longitude: "30.6415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11373",
    WardLookupId: "3641",
    VDNumber: "43626078",
    RegisteredPopulation: "395",
    VotingStationName: "EKWANDENI REFORMED CHURCH",
    Address: "CELUMUSA AREA  NQUTHU TOWN  MOLEFE TRIBAL COURT",
    Latitude: "-28.0297",
    Longitude: "30.5877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11374",
    WardLookupId: "3642",
    VDNumber: "43621589",
    RegisteredPopulation: "936",
    VotingStationName: "NTSHANGASE PRIMARY SCHOOL",
    Address: "00 NKANDE AREA  NKANDE NQUTU  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-27.9654",
    Longitude: "30.6164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11375",
    WardLookupId: "3642",
    VDNumber: "43621590",
    RegisteredPopulation: "1569",
    VotingStationName: "NDLANGAMANDLA JUNIOR SECONDARY SCHOOL",
    Address: "00 NKANDE AREA  NKANDE NQUTU  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-27.9493",
    Longitude: "30.6112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11376",
    WardLookupId: "3642",
    VDNumber: "43622603",
    RegisteredPopulation: "410",
    VotingStationName: "NHLOKOMO PRIMARY SCHOOL",
    Address: "NTSHENDLOVU AREA  NQUTU  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-27.981",
    Longitude: "30.6696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11377",
    WardLookupId: "3642",
    VDNumber: "43625224",
    RegisteredPopulation: "966",
    VotingStationName: "NKANDE PRIMARY SCHOOL",
    Address: "NKANDE AREA  NKANDE NQUTU  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-27.9532",
    Longitude: "30.6313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11378",
    WardLookupId: "3642",
    VDNumber: "43625516",
    RegisteredPopulation: "489",
    VotingStationName: "TLOKOENG LOWER PRIMARY SCHOOL",
    Address: "NKANDE AREA  NKANDE NQUTU  MOLEFE TRADITIONAL AUTHORITY",
    Latitude: "-27.9903",
    Longitude: "30.6298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11379",
    WardLookupId: "3643",
    VDNumber: "43620735",
    RegisteredPopulation: "481",
    VotingStationName: "ITHUBALETHU PRIMARY SCHOOL",
    Address: "ELENGE  ENZIMANE  QAMU TRIBAL",
    Latitude: "-28.5749",
    Longitude: "30.2478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11380",
    WardLookupId: "3643",
    VDNumber: "43620814",
    RegisteredPopulation: "728",
    VotingStationName: "MPOMPOLWANE PRIMARY SCHOOL",
    Address: "OBANJENI  TUGELA FERRY  QAMU TRIBAL",
    Latitude: "-28.6223",
    Longitude: "30.3442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11381",
    WardLookupId: "3643",
    VDNumber: "43620825",
    RegisteredPopulation: "413",
    VotingStationName: "OBISINI PRIMARY SCHOOL",
    Address: "MPONDWENI  TUGELA FERRY  QAMU TRIBAL",
    Latitude: "-28.5829",
    Longitude: "30.2992",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11382",
    WardLookupId: "3643",
    VDNumber: "43620881",
    RegisteredPopulation: "481",
    VotingStationName: "BUHLEBEMFUNDO PRIMARY SCHOOL",
    Address: "EDEBEDEBENI  POMEROY  QAMU TRIBAL",
    Latitude: "-28.5666",
    Longitude: "30.2707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11383",
    WardLookupId: "3643",
    VDNumber: "43620892",
    RegisteredPopulation: "727",
    VotingStationName: "DOUGLAS COMBINED PRIMARY SCHOOL",
    Address: "DOUGLAS  MSINGA",
    Latitude: "-28.5272",
    Longitude: "30.2795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11384",
    WardLookupId: "3643",
    VDNumber: "43620948",
    RegisteredPopulation: "288",
    VotingStationName: "QAMU TRIBAL COURT",
    Address: "EMZWENI ROAD  MAJOZI  MSINGA",
    Latitude: "-28.5556",
    Longitude: "30.3867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11385",
    WardLookupId: "3643",
    VDNumber: "43620960",
    RegisteredPopulation: "448",
    VotingStationName: "SALVATION (LEWIS PRIMARY SCHOOL)",
    Address: "EMKHUZENI  POMEROY  QAMU TRIBAL",
    Latitude: "-28.5479",
    Longitude: "30.2598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11386",
    WardLookupId: "3643",
    VDNumber: "43620993",
    RegisteredPopulation: "1147",
    VotingStationName: "MUMBE PRIMARY SCHOOL",
    Address: "ENHLANHLENI  POMEROY  MSINGA",
    Latitude: "-28.5702",
    Longitude: "30.3844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11387",
    WardLookupId: "3643",
    VDNumber: "43624942",
    RegisteredPopulation: "299",
    VotingStationName: "GEDE PRIMARY SCHOOL",
    Address: "EZIMBUBENI  POMEROY  QAMU",
    Latitude: "-28.5301",
    Longitude: "30.3397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11388",
    WardLookupId: "3643",
    VDNumber: "43626157",
    RegisteredPopulation: "249",
    VotingStationName: "HOLLYWOOD PRIMARY SCHOOL",
    Address: "EMKHUZENI  POMEROY  QAMU TRIBAL",
    Latitude: "-28.5205",
    Longitude: "30.2511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11389",
    WardLookupId: "3644",
    VDNumber: "43620702",
    RegisteredPopulation: "625",
    VotingStationName: "EMZWENI PRIMARY SCHOOL",
    Address: "BHAZA AREA  POMEROY  MSINGA",
    Latitude: "-28.6399",
    Longitude: "30.2656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11390",
    WardLookupId: "3644",
    VDNumber: "43620746",
    RegisteredPopulation: "762",
    VotingStationName: "NKAMBA PRIMARY SCHOOL",
    Address: "NKAMBA  POMEROY  QAMU TRIBAL",
    Latitude: "-28.6358",
    Longitude: "30.4382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11391",
    WardLookupId: "3644",
    VDNumber: "43620757",
    RegisteredPopulation: "592",
    VotingStationName: "CELIMFUNDO  PRIMARY SCHOOL",
    Address: "GUNJANE   POMEROY  QAMU TRIBAL",
    Latitude: "-28.6343",
    Longitude: "30.3328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11392",
    WardLookupId: "3644",
    VDNumber: "43620768",
    RegisteredPopulation: "871",
    VotingStationName: "INDLOZANA HIGH SCHOOL",
    Address: "MZWENI  POMEROY  QAMU TRIBAL",
    Latitude: "-28.6317",
    Longitude: "30.3038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11393",
    WardLookupId: "3644",
    VDNumber: "43620836",
    RegisteredPopulation: "726",
    VotingStationName: "MHLABANGULE PRIMARY SCHOOL",
    Address: "NGULULE  POMEROY  QAMU TRIBAL",
    Latitude: "-28.6436",
    Longitude: "30.3636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11394",
    WardLookupId: "3644",
    VDNumber: "43620870",
    RegisteredPopulation: "498",
    VotingStationName: "MADUDULA HIGH SCHOOL",
    Address: "NGABAYENA AREA  POMEROY  QAMU TRIBAL",
    Latitude: "-28.5839",
    Longitude: "30.4167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11395",
    WardLookupId: "3644",
    VDNumber: "43620937",
    RegisteredPopulation: "585",
    VotingStationName: "ENTOKOZWENI PRIMARY SCHOOL",
    Address: "NGABAYENA  POMEROY  QAMU TRIBAL",
    Latitude: "-28.6106",
    Longitude: "30.4064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11396",
    WardLookupId: "3644",
    VDNumber: "43624931",
    RegisteredPopulation: "386",
    VotingStationName: "GUBUZELA PRIMARY SCHOOL",
    Address: "EMZWENI  POMEROY  QAMU TRBAL",
    Latitude: "-28.6208",
    Longitude: "30.2498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11397",
    WardLookupId: "3644",
    VDNumber: "43626180",
    RegisteredPopulation: "176",
    VotingStationName: "FASILE CRECHE",
    Address: "NKAMBA  TUGELA FERRY  MABASO TRIBAL AUTHORITY",
    Latitude: "-28.6518",
    Longitude: "30.4068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11398",
    WardLookupId: "3645",
    VDNumber: "43620577",
    RegisteredPopulation: "468",
    VotingStationName: "SWEBANE PRIMARY SCHOOL",
    Address: "SWEBANE  TUGELA FERRY  MTHEMBU TRIBAL",
    Latitude: "-28.6835",
    Longitude: "30.3033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11399",
    WardLookupId: "3645",
    VDNumber: "43620645",
    RegisteredPopulation: "805",
    VotingStationName: "THEMANE PRIMARY SCHOOL",
    Address: "ENGCENGENI  TUGELA FERRY  MTHEMBU TRIBAL",
    Latitude: "-28.7021",
    Longitude: "30.411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11400",
    WardLookupId: "3645",
    VDNumber: "43620667",
    RegisteredPopulation: "760",
    VotingStationName: "MANDLENI PRIMARY SCHOOL",
    Address: "KWANGUBO  TUGELA FERRY  MTHEMBU TRIBAL",
    Latitude: "-28.73",
    Longitude: "30.366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11401",
    WardLookupId: "3645",
    VDNumber: "43620713",
    RegisteredPopulation: "712",
    VotingStationName: "DLENYANE PRIMARY SCHOOL",
    Address: "ENGCENGENI  TUGELA FERRY  MTHEMBU TRIBAL",
    Latitude: "-28.6641",
    Longitude: "30.4005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11402",
    WardLookupId: "3645",
    VDNumber: "43620724",
    RegisteredPopulation: "454",
    VotingStationName: "EMKHAMO PRIMARY SCHOOL",
    Address: "KWAPHALAFINI  TUGELA FERRY  MTHEMBU TRIBAL",
    Latitude: "-28.6798",
    Longitude: "30.3209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11403",
    WardLookupId: "3645",
    VDNumber: "43625291",
    RegisteredPopulation: "872",
    VotingStationName: "ZAMOKUHLE PRIMARY SCHOOL",
    Address: "PHALAFINI AREA   TUGELA FERRY   MTHEMBU TRIBAL",
    Latitude: "-28.7152",
    Longitude: "30.3444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11404",
    WardLookupId: "3645",
    VDNumber: "43625695",
    RegisteredPopulation: "797",
    VotingStationName: "LANDULWAZI COMBINED PRIMARY SCHOOL",
    Address: "ESAMPOFU   TUGELA FERRY  MTHEMBU TRIBAL",
    Latitude: "-28.736",
    Longitude: "30.3922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11405",
    WardLookupId: "3646",
    VDNumber: "43620500",
    RegisteredPopulation: "790",
    VotingStationName: "MSINGA HIGH SCHOOL",
    Address: "EMKHUPHULANGWENYA  TUGELA FERRY  MABASO TRIBAL",
    Latitude: "-28.7498",
    Longitude: "30.4158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11406",
    WardLookupId: "3646",
    VDNumber: "43620601",
    RegisteredPopulation: "987",
    VotingStationName: "MBONDWENI PRIMARY SCHOOL",
    Address: "ESIDAKENI  TUGELA FERRY  MABASO TRIBAL",
    Latitude: "-28.723",
    Longitude: "30.4488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11407",
    WardLookupId: "3646",
    VDNumber: "43620612",
    RegisteredPopulation: "807",
    VotingStationName: "SAKHISENI HIGH SCHOOL",
    Address: "EBHUBESINI  TUGELA FERRY  MABASO TRIBAL",
    Latitude: "-28.6861",
    Longitude: "30.452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11408",
    WardLookupId: "3646",
    VDNumber: "43620803",
    RegisteredPopulation: "491",
    VotingStationName: "SAMPOFU PRIMARY SCHOOL",
    Address: "ESAMPOFU  TUGELA FERRY  MABASO TRIBAL",
    Latitude: "-28.6798",
    Longitude: "30.4169",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11409",
    WardLookupId: "3646",
    VDNumber: "43622490",
    RegisteredPopulation: "780",
    VotingStationName: "NYANDU PRIMARY SCHOOL",
    Address: "NYANDU  TUGELA FEERY  MABASO TRIBAL",
    Latitude: "-28.6573",
    Longitude: "30.4633",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11410",
    WardLookupId: "3646",
    VDNumber: "43622502",
    RegisteredPopulation: "925",
    VotingStationName: "MAGISTRATE COURT TUGELA FERRY",
    Address: "R33 MAIN ROAD TUGELA FERRY  TUGELA FERRY  MABASO TRIBAL",
    Latitude: "-28.7487",
    Longitude: "30.443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11411",
    WardLookupId: "3646",
    VDNumber: "43625864",
    RegisteredPopulation: "320",
    VotingStationName: "EZIBOMVINI PRIMARY SCHOOL",
    Address: "ESIDAKENI  TUGELA FERRY  BASO TRIBAL AUTHORITY",
    Latitude: "-28.7182",
    Longitude: "30.4233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11412",
    WardLookupId: "3646",
    VDNumber: "43625932",
    RegisteredPopulation: "204",
    VotingStationName: "SAMPOFU STORE",
    Address: "SAMPOFU  TUGELA FERRY  MABASO TRIBAL",
    Latitude: "-28.7003",
    Longitude: "30.4217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11413",
    WardLookupId: "3647",
    VDNumber: "43620397",
    RegisteredPopulation: "1002",
    VotingStationName: "MTHEMBU TRIBAL COURT",
    Address: "MAIN GREYTOWN ROAD  TUGELA FERRY  MTHEMBU TRIBAL COURT",
    Latitude: "-28.7632",
    Longitude: "30.445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11414",
    WardLookupId: "3647",
    VDNumber: "43620409",
    RegisteredPopulation: "1169",
    VotingStationName: "CABANGOKUHLE HIGH SCHOOL",
    Address: "MHLAKOTHI ROAD  TUGELA FERRY  MSINGA",
    Latitude: "-28.763",
    Longitude: "30.4345",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11415",
    WardLookupId: "3647",
    VDNumber: "43620454",
    RegisteredPopulation: "720",
    VotingStationName: "MZOMUSHA PRIMARY SCHOOL",
    Address: "MBABANE ROAD  TUGELA FERRY  MSINGA",
    Latitude: "-28.7661",
    Longitude: "30.4083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11416",
    WardLookupId: "3647",
    VDNumber: "43620678",
    RegisteredPopulation: "949",
    VotingStationName: "MHLAKOTHI HIGH SCHOOL",
    Address: "MBABANE ROAD  MBABANE  MSINGA",
    Latitude: "-28.7538",
    Longitude: "30.3789",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11417",
    WardLookupId: "3647",
    VDNumber: "43624986",
    RegisteredPopulation: "354",
    VotingStationName: "ZIZI HIGHER PRIMARY SCHOOL",
    Address: "MAHLABATHINI AREA  TUGELA FERRY  MTHEMBU TRIBBAL",
    Latitude: "-28.755",
    Longitude: "30.4709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11418",
    WardLookupId: "3647",
    VDNumber: "43625088",
    RegisteredPopulation: "784",
    VotingStationName: "ESINQUMENI PRIMARY SCHOOL",
    Address: "GREYTOWN ROAD R33  TUGELA FERRY  MSINGA",
    Latitude: "-28.7792",
    Longitude: "30.4469",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11419",
    WardLookupId: "3647",
    VDNumber: "43626191",
    RegisteredPopulation: "196",
    VotingStationName: "MBUBU PRIMARY SCHOOL",
    Address: "MAHLABATHINI  TUGELA FERRY  MTHEMBU TRIBAL AUTHORITY",
    Latitude: "-28.7814",
    Longitude: "30.4697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11420",
    WardLookupId: "3648",
    VDNumber: "43620375",
    RegisteredPopulation: "742",
    VotingStationName: "NGONGOLO COMBINED PRIMARY SCHOOL",
    Address: "MABABANE ROAD  MCHUNU AREA  TUGELA FERRY",
    Latitude: "-28.7564",
    Longitude: "30.3002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11421",
    WardLookupId: "3648",
    VDNumber: "43620410",
    RegisteredPopulation: "817",
    VotingStationName: "ST BERNARDS JOLWAYO COMBINED SCHOOL",
    Address: "MBABANE ROAD  TUGELA FERRY  MSINGA",
    Latitude: "-28.7465",
    Longitude: "30.3401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11422",
    WardLookupId: "3648",
    VDNumber: "43620498",
    RegisteredPopulation: "807",
    VotingStationName: "MATHINTA PRIMARY SCHOOL",
    Address: "KWA GUQA  MSINGA",
    Latitude: "-28.7941",
    Longitude: "30.3029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11423",
    WardLookupId: "3648",
    VDNumber: "43620555",
    RegisteredPopulation: "1223",
    VotingStationName: "MABIZELA HIGH SCHOOL",
    Address: "MASHUNKA  MTHEMBU TRIBAL  TUGELA FERRY",
    Latitude: "-28.7384",
    Longitude: "30.3106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11424",
    WardLookupId: "3648",
    VDNumber: "43620623",
    RegisteredPopulation: "612",
    VotingStationName: "DLABESUTHU HIGH SCHOOL",
    Address: "MCHUNU ROAD  GUQA  MSINGA",
    Latitude: "-28.8156",
    Longitude: "30.3085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11425",
    WardLookupId: "3648",
    VDNumber: "43625011",
    RegisteredPopulation: "271",
    VotingStationName: "KOSBIYA (TENT)",
    Address: "MATHINTA ROAD  FERRY  MSINGA",
    Latitude: "-28.7945",
    Longitude: "30.3253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11426",
    WardLookupId: "3648",
    VDNumber: "43625707",
    RegisteredPopulation: "473",
    VotingStationName: "BAMBANANI LOWER PRIMARY SCHOOL",
    Address: "TUGELA FERRY  MASHUNKA AREA MTHEMBU TA",
    Latitude: "-28.7309",
    Longitude: "30.2796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11427",
    WardLookupId: "3649",
    VDNumber: "43620432",
    RegisteredPopulation: "1544",
    VotingStationName: "ULWAZI COMBINED PRIMARY SCHOOL",
    Address: "MBANGWENI  MBHANGWENI AREA  TUGELA FERRY",
    Latitude: "-28.8204",
    Longitude: "30.3987",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11428",
    WardLookupId: "3649",
    VDNumber: "43620511",
    RegisteredPopulation: "613",
    VotingStationName: "MCHUNU TRIBAL COURT",
    Address: "MCHUNU T/C ROAD  GUJINI  MCHUNU T/C",
    Latitude: "-28.8476",
    Longitude: "30.3061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11429",
    WardLookupId: "3649",
    VDNumber: "43620566",
    RegisteredPopulation: "703",
    VotingStationName: "NOMAHAYE PRIMARY SCHOOL",
    Address: "MCHUNU ROAD  GUJINI  MSINGA",
    Latitude: "-28.8304",
    Longitude: "30.3014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11430",
    WardLookupId: "3649",
    VDNumber: "43620588",
    RegisteredPopulation: "925",
    VotingStationName: "BETHULO PRIMARY  SCHOOL",
    Address: "MCHUNU  EMBANGWENI  TUGELA FERRY",
    Latitude: "-28.8273",
    Longitude: "30.3937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11431",
    WardLookupId: "3649",
    VDNumber: "43620634",
    RegisteredPopulation: "523",
    VotingStationName: "EMHLANGANE COMBINED PRIMARY",
    Address: "MHLANGANO ROAD  GUJINI  MSINGA",
    Latitude: "-28.8304",
    Longitude: "30.3164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11432",
    WardLookupId: "3649",
    VDNumber: "43625853",
    RegisteredPopulation: "347",
    VotingStationName: "JOJINGWENYA PRIMARY SCHOOL",
    Address: "EMBANGWENI  MSINGA  MCHUNU TRIBAL AUTHORITY",
    Latitude: "-28.8084",
    Longitude: "30.4305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11433",
    WardLookupId: "3649",
    VDNumber: "43625886",
    RegisteredPopulation: "398",
    VotingStationName: "EZIHLABENI PRIMARY SCHOOL",
    Address: "GUJINI  MSINGA  MCHUNU TRIBAL AUTHORITY",
    Latitude: "-28.8456",
    Longitude: "30.2885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11434",
    WardLookupId: "3649",
    VDNumber: "43626214",
    RegisteredPopulation: "46",
    VotingStationName: "OSUTHU PRIMARY SCHOOL",
    Address: "OSUTHU  TUGELA FERRY  MCHUNU TRIBAL AUTHORITY",
    Latitude: "-28.7952",
    Longitude: "30.4305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11435",
    WardLookupId: "3650",
    VDNumber: "43620195",
    RegisteredPopulation: "539",
    VotingStationName: "DUMAKUDE PRIMARY SCHOOL",
    Address: "FABENI MUDEN ROAD  GALIBASI  MSINGA",
    Latitude: "-28.896",
    Longitude: "30.4347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11436",
    WardLookupId: "3650",
    VDNumber: "43620421",
    RegisteredPopulation: "1354",
    VotingStationName: "NOGIDA HIGH SCHOOL",
    Address: "MUDEN ROAD  EMACHUNWINI  MSINGA",
    Latitude: "-28.9016",
    Longitude: "30.391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11437",
    WardLookupId: "3650",
    VDNumber: "43620465",
    RegisteredPopulation: "767",
    VotingStationName: "MHLANGEZULU JUNIOR SECONDARY SCHOOL",
    Address: "MUDEN ROAD  EMADULANENI  TUGELA FERRY",
    Latitude: "-28.8707",
    Longitude: "30.4461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11438",
    WardLookupId: "3650",
    VDNumber: "43625280",
    RegisteredPopulation: "605",
    VotingStationName: "KHULANI PRIMARY SCHOOL",
    Address: "NXAMALALA ROAD  FERRY  MSINGA",
    Latitude: "-28.8315",
    Longitude: "30.4348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11439",
    WardLookupId: "3650",
    VDNumber: "43625729",
    RegisteredPopulation: "484",
    VotingStationName: "DUMABEMSOLA PRIMARY SCHOOL",
    Address: "NOGIDA ROAD   EMADULANENI AREA  MCHUNU T/C",
    Latitude: "-28.8619",
    Longitude: "30.4135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11440",
    WardLookupId: "3650",
    VDNumber: "43626089",
    RegisteredPopulation: "512",
    VotingStationName: "NTANYANA PRIMARY SCHOOL",
    Address: "EKUVUKENI  MCHUNU TRIBAL  MCHUNUT/C TUGELA FERRY",
    Latitude: "-28.891",
    Longitude: "30.3792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11441",
    WardLookupId: "3650",
    VDNumber: "43626225",
    RegisteredPopulation: "261",
    VotingStationName: "ZIMNGAYE COMBINED PRIMARY",
    Address: "NXAMALALA  TUGELA FERRY  MCCHUNU TRIBAL AUTHORITY",
    Latitude: "-28.8445",
    Longitude: "30.4292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11442",
    WardLookupId: "3650",
    VDNumber: "43626236",
    RegisteredPopulation: "291",
    VotingStationName: "NTENESHANA PRIMARY SCHOOL",
    Address: "EKUVUKENI  TUGELA FERRY  MCHUNU TRIBAL AUTHORITY",
    Latitude: "-28.9127",
    Longitude: "30.415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11443",
    WardLookupId: "3651",
    VDNumber: "43620364",
    RegisteredPopulation: "667",
    VotingStationName: "HOLLINESS UNION CHURCH",
    Address: "MBANWENI ROAD TUGELA FERRY  MCHUNU AREA  TUGELA FERRY MCHUNU T/C",
    Latitude: "-28.8945",
    Longitude: "30.3079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11444",
    WardLookupId: "3651",
    VDNumber: "43620386",
    RegisteredPopulation: "430",
    VotingStationName: "OKHULANE LOWER PRIMARY SCHOOL",
    Address: "EMACHWINI ROAD  EKUVUKENI AREA  TUGELA FERRY",
    Latitude: "-28.908",
    Longitude: "30.3585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11445",
    WardLookupId: "3651",
    VDNumber: "43620476",
    RegisteredPopulation: "518",
    VotingStationName: "MHLUMBA COMBINED PRIMARY SCHOOL",
    Address: "MHLUMBA ROAD OR MUDEN ROAD  MCHUNU AREA  TUGELA FERRY",
    Latitude: "-28.8781",
    Longitude: "30.3204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11446",
    WardLookupId: "3651",
    VDNumber: "43620487",
    RegisteredPopulation: "655",
    VotingStationName: "GUJINI COMMUNITY HALL",
    Address: "MACHUNWININI ROAD  GIJINI AREA  MCHUNU TRIBAL COURT",
    Latitude: "-28.8689",
    Longitude: "30.2918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11447",
    WardLookupId: "3651",
    VDNumber: "43620522",
    RegisteredPopulation: "523",
    VotingStationName: "KWA VULAMEHLO PRIMARY SCHOOL",
    Address: "MHLUMBA ROAD  TUGELA FERRY  MSINGA",
    Latitude: "-28.8546",
    Longitude: "30.3472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11448",
    WardLookupId: "3651",
    VDNumber: "43620533",
    RegisteredPopulation: "166",
    VotingStationName: "ST PETERS PARISH",
    Address: "MHLANGANO ROAD  TUGELA FERRY  MSINGA",
    Latitude: "-28.8465",
    Longitude: "30.3239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11449",
    WardLookupId: "3651",
    VDNumber: "43620544",
    RegisteredPopulation: "729",
    VotingStationName: "MAHLAHLAMELA LOWER PRIMARY SCHOOL",
    Address: "MCHUNU ROAD  MCHUNU  MSINGA",
    Latitude: "-28.8862",
    Longitude: "30.2923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11450",
    WardLookupId: "3651",
    VDNumber: "43626090",
    RegisteredPopulation: "415",
    VotingStationName: "KWENZOKUHLE CRECHE",
    Address: "EMHLANGANE ROAD  MCHUNU TRIBAL  TUGELA FERRY",
    Latitude: "-28.8986",
    Longitude: "30.2874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11451",
    WardLookupId: "3652",
    VDNumber: "43620106",
    RegisteredPopulation: "825",
    VotingStationName: "KHULANI CRECHE",
    Address: "MAIN ROAD KEATES DRIFT  TUGELA FERRY  MSINGA",
    Latitude: "-28.8578",
    Longitude: "30.4957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11452",
    WardLookupId: "3652",
    VDNumber: "43620207",
    RegisteredPopulation: "880",
    VotingStationName: "FABENI PRIMARY SCHOOL",
    Address: "R33 ROAD  FABENI  MSINGA",
    Latitude: "-28.8248",
    Longitude: "30.4894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11453",
    WardLookupId: "3652",
    VDNumber: "43620218",
    RegisteredPopulation: "817",
    VotingStationName: "FUNDOKUHLE HIGH SCHOOL",
    Address: "DUNGAMANZI  TUGELA FERRY",
    Latitude: "-28.81",
    Longitude: "30.4686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11454",
    WardLookupId: "3652",
    VDNumber: "43620229",
    RegisteredPopulation: "934",
    VotingStationName: "MERTON COMBINED PRIMARY SCHOOL",
    Address: "R33  KWA KOPI  TUGELA FERRY",
    Latitude: "-28.7979",
    Longitude: "30.4764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11455",
    WardLookupId: "3652",
    VDNumber: "43620353",
    RegisteredPopulation: "1126",
    VotingStationName: "BUHLALU PRIMARY SCHOOL",
    Address: "NXAMALALA AREA  KWA NXAMALALA  MSINGA",
    Latitude: "-28.8523",
    Longitude: "30.4657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11456",
    WardLookupId: "3652",
    VDNumber: "43624997",
    RegisteredPopulation: "330",
    VotingStationName: "NOGAWU PRIMARY SCHOOL",
    Address: "NHLESI ROAD  FERRY  MSINGA",
    Latitude: "-28.7684",
    Longitude: "30.4903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11457",
    WardLookupId: "3652",
    VDNumber: "43626102",
    RegisteredPopulation: "432",
    VotingStationName: "NOMFOMELA SCHOOL",
    Address: "OTHULINI  MCHUNU TRIBAL  TUGELA FERRY",
    Latitude: "-28.8563",
    Longitude: "30.5032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11458",
    WardLookupId: "3653",
    VDNumber: "43620016",
    RegisteredPopulation: "685",
    VotingStationName: "LATHA PRIMARY  SCHOOL",
    Address: "D1268 MKHUPHULA ROAD  LATHA  GREYTOWN",
    Latitude: "-28.856",
    Longitude: "30.5512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11459",
    WardLookupId: "3653",
    VDNumber: "43620027",
    RegisteredPopulation: "621",
    VotingStationName: "UMBONJE SCHOOL",
    Address: "LATHA ROAD  MSINGA TOP  TUGELA FERRY",
    Latitude: "-28.8577",
    Longitude: "30.5402",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11460",
    WardLookupId: "3653",
    VDNumber: "43620049",
    RegisteredPopulation: "898",
    VotingStationName: "JANGENI JUNIOR PRIMARY SCHOOL",
    Address: "MAIN ROAD GREYTOWN  MAWOZINI  GREYTOWN",
    Latitude: "-28.8717",
    Longitude: "30.5357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11461",
    WardLookupId: "3653",
    VDNumber: "43620117",
    RegisteredPopulation: "1307",
    VotingStationName: "KEATES DRIFT PRIMARY SCHOOL",
    Address: "R33 OTHULINI LWEZULU  KEATESDRRIFT AREA  GREYTOWN",
    Latitude: "-28.8643",
    Longitude: "30.486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11462",
    WardLookupId: "3653",
    VDNumber: "43620342",
    RegisteredPopulation: "445",
    VotingStationName: "SINAMVA CRECHE",
    Address: "EMVUNDLENI ROAD  KEATESDRIFT  MSINGA / MCHUNU",
    Latitude: "-28.838",
    Longitude: "30.5192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11463",
    WardLookupId: "3653",
    VDNumber: "43625684",
    RegisteredPopulation: "783",
    VotingStationName: "NTABENDE COMBINED PRIMARY SCHOOL",
    Address: "NYONINI OTHULINI LWEZULU  TUGELA FERRY  MCHUNU TA",
    Latitude: "-28.8817",
    Longitude: "30.5035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11464",
    WardLookupId: "3654",
    VDNumber: "43620904",
    RegisteredPopulation: "670",
    VotingStationName: "ELANDSBERG COMBINED PRIMARY SCHOOL",
    Address: "ENDANYANA  POMEROY  QAMU TRIBAL",
    Latitude: "-28.4826",
    Longitude: "30.5087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11465",
    WardLookupId: "3654",
    VDNumber: "43620959",
    RegisteredPopulation: "1034",
    VotingStationName: "NYONYANA PRIMARY SCHOOL",
    Address: "NYONYANA ON UITVAL ROAD  POMEROY  QAMU TRIBAL",
    Latitude: "-28.4863",
    Longitude: "30.3534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11466",
    WardLookupId: "3654",
    VDNumber: "43621028",
    RegisteredPopulation: "968",
    VotingStationName: "SHIYANE HIGH SCHOOL",
    Address: "ROAKES DRIFT  POMEROY  QAMU TRIBAL",
    Latitude: "-28.3608",
    Longitude: "30.536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11467",
    WardLookupId: "3654",
    VDNumber: "43621040",
    RegisteredPopulation: "507",
    VotingStationName: "MAMBENI PRIMARY SCHOOL",
    Address: "KWAZENZELE  POMEROY  QAMU TRIBAL",
    Latitude: "-28.5328",
    Longitude: "30.3825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11468",
    WardLookupId: "3654",
    VDNumber: "43625022",
    RegisteredPopulation: "74",
    VotingStationName: "BLESBOK TENT",
    Address: "BLESBOK NEXT TO KWALEBI STORE  POMEROY  QAMU TRIBAL",
    Latitude: "-28.4133",
    Longitude: "30.2745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11469",
    WardLookupId: "3654",
    VDNumber: "43625033",
    RegisteredPopulation: "258",
    VotingStationName: "GIBA PRIMARY SCHOOL",
    Address: "HELPMEKAAR ON R33 DUNDEE ROAD  HELPMEKAAR  QAMU TRIBAL",
    Latitude: "-28.387",
    Longitude: "30.3802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11470",
    WardLookupId: "3654",
    VDNumber: "43625099",
    RegisteredPopulation: "459",
    VotingStationName: "ALVA PRIMARY SCHOOL",
    Address: "KWAZENZELE  POMEROY  QAMU TRIBAL",
    Latitude: "-28.5028",
    Longitude: "30.4035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11471",
    WardLookupId: "3654",
    VDNumber: "43626124",
    RegisteredPopulation: "255",
    VotingStationName: "BUYAFUTHI",
    Address: "BUYAFUTHI  QAMU TRIBAL  POMEROY",
    Latitude: "-28.5217",
    Longitude: "30.4552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11472",
    WardLookupId: "3654",
    VDNumber: "43626247",
    RegisteredPopulation: "197",
    VotingStationName: "MSITHA PRIMARY",
    Address: "HLANGANANI  POMEROY  QAMU TRIBAL AUTHORITY",
    Latitude: "-28.5524",
    Longitude: "30.4112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11473",
    WardLookupId: "3654",
    VDNumber: "43626258",
    RegisteredPopulation: "153",
    VotingStationName: "MAGOSO HALL",
    Address: "KWAMAGOSO  POMEROY  QAMU TRIBAL AUTHORITY",
    Latitude: "-28.4899",
    Longitude: "30.4973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11474",
    WardLookupId: "3655",
    VDNumber: "43620083",
    RegisteredPopulation: "234",
    VotingStationName: "EMKHUPHULA PRIMARY SCHOOL",
    Address: "D1268 MAWOZINI-EMKHUPHULA  EMKHUPHULA  GREYTOWN",
    Latitude: "-28.7932",
    Longitude: "30.5687",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11475",
    WardLookupId: "3655",
    VDNumber: "43620162",
    RegisteredPopulation: "273",
    VotingStationName: "ESIMANYAMA PRIMARY SCHOOL",
    Address: "NGUBEVU ROAD  LATHANTSHAWU MSINGA  GREYTOWN",
    Latitude: "-28.7633",
    Longitude: "30.6604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11476",
    WardLookupId: "3655",
    VDNumber: "43620184",
    RegisteredPopulation: "428",
    VotingStationName: "MFENEBUDE COMBINED PRIMARY SCHOOL",
    Address: "MFENEBUDE ROAD D 17  KWANDAYA  GREYTOWN",
    Latitude: "-28.7971",
    Longitude: "30.6312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11477",
    WardLookupId: "3655",
    VDNumber: "43620230",
    RegisteredPopulation: "391",
    VotingStationName: "NYONIYEZWE HIGH SCHOOL",
    Address: "MFENEBUDE ROAD  KWANDAYA KEATES DRIFT  MSINGA",
    Latitude: "-28.7994",
    Longitude: "30.6238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11478",
    WardLookupId: "3655",
    VDNumber: "43620274",
    RegisteredPopulation: "753",
    VotingStationName: "MAKHANDANA COMBINED PRIMARY SCHOOL",
    Address: "D 17 NHLONGA ROAD  TUGELA FEERY  MSINGA",
    Latitude: "-28.7416",
    Longitude: "30.6187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11479",
    WardLookupId: "3655",
    VDNumber: "43622636",
    RegisteredPopulation: "513",
    VotingStationName: "MFUNZI SCHOOL",
    Address: "1268 MKHUPHULA ROARD  TUGELA FERRY  MSINGA",
    Latitude: "-28.7898",
    Longitude: "30.5349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11480",
    WardLookupId: "3655",
    VDNumber: "43622669",
    RegisteredPopulation: "211",
    VotingStationName: "KWA NDUNGE HIGH SCHOOL",
    Address: "MKHUPHULA ROAD  UMKHUPHULA AREA  UMSINGA",
    Latitude: "-28.8029",
    Longitude: "30.572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11481",
    WardLookupId: "3655",
    VDNumber: "43624964",
    RegisteredPopulation: "418",
    VotingStationName: "NKAYISHANA SCHOOL",
    Address: "MFENEBUDE ROAD  MKHUPHULA  MSINGA",
    Latitude: "-28.8422",
    Longitude: "30.575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11482",
    WardLookupId: "3655",
    VDNumber: "43625662",
    RegisteredPopulation: "85",
    VotingStationName: "NGCUBA PRIMARY SCHOOL",
    Address: "ENGCUBA, MABOMVINI  TUGELA FERRY  MBOMVU TA",
    Latitude: "-28.7402",
    Longitude: "30.6903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11483",
    WardLookupId: "3655",
    VDNumber: "43625673",
    RegisteredPopulation: "562",
    VotingStationName: "KUBUYAKWEZWE PRIMARY SCHOOL",
    Address: "NHLUNGWANE AREA  TUGELA FERRY  MBOMVU TA",
    Latitude: "-28.7752",
    Longitude: "30.5803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11484",
    WardLookupId: "3655",
    VDNumber: "43625820",
    RegisteredPopulation: "349",
    VotingStationName: "HOLISIZWE SCHOOL",
    Address: "KWANDAYA  MSINGA  MBOMVU T/A",
    Latitude: "-28.7845",
    Longitude: "30.6026",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11485",
    WardLookupId: "3656",
    VDNumber: "43620252",
    RegisteredPopulation: "628",
    VotingStationName: "EMSIZINI PRIMARY SCHOOL",
    Address: "TUGELA FERRY  MBHONO AREA  MBOMVU TRIBAL",
    Latitude: "-28.7586",
    Longitude: "30.5224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11486",
    WardLookupId: "3656",
    VDNumber: "43620263",
    RegisteredPopulation: "439",
    VotingStationName: "DAYISWAYO PRIMARY SCHOOL",
    Address: "TUGELA FERRY  OTHAME AREA  MBOMVU TRIBAL",
    Latitude: "-28.7366",
    Longitude: "30.6032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11487",
    WardLookupId: "3656",
    VDNumber: "43620285",
    RegisteredPopulation: "611",
    VotingStationName: "MPIKAYIZEKANYE HIGH SCHOOL",
    Address: "MBHONO AREA  TUGELA FERRY  MBOMVU TRIBAL",
    Latitude: "-28.7531",
    Longitude: "30.5473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11488",
    WardLookupId: "3656",
    VDNumber: "43620319",
    RegisteredPopulation: "341",
    VotingStationName: "BARNHILL PRIMARY SCHOOL",
    Address: "TUGELA FERRY  MBHONO AREA(MSINGA TOP)  MBOMVU TRIBAL",
    Latitude: "-28.7136",
    Longitude: "30.5181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11489",
    WardLookupId: "3656",
    VDNumber: "43620331",
    RegisteredPopulation: "695",
    VotingStationName: "USIZO HIGH SCHOOL",
    Address: "TUGELA FERRY  GXOBANYAWO (MSINGA TOP)  MBOMVU TRIBAL",
    Latitude: "-28.6814",
    Longitude: "30.5238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11490",
    WardLookupId: "3656",
    VDNumber: "43620689",
    RegisteredPopulation: "523",
    VotingStationName: "PHENDUKA PRIMARY SCHOOL",
    Address: "TUGELA FERRY  NKADLA AREA (MSINGA TOP)  MBOMVU TRIBAL",
    Latitude: "-28.6749",
    Longitude: "30.5057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11491",
    WardLookupId: "3656",
    VDNumber: "43622478",
    RegisteredPopulation: "1061",
    VotingStationName: "NTSHISHILI PRIMARY SCHOOL",
    Address: "TUGELA FERRY  EZINGULUBENI AREA  MBOMVU TRIBAL",
    Latitude: "-28.7476",
    Longitude: "30.4827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11492",
    WardLookupId: "3656",
    VDNumber: "43625000",
    RegisteredPopulation: "497",
    VotingStationName: "COSH PRIMARY SCHOOL",
    Address: "MAKHUNGUBHEDE AREA  TUGELA FERRY  MABASO TRIBAL",
    Latitude: "-28.7434",
    Longitude: "30.4703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11493",
    WardLookupId: "3656",
    VDNumber: "43625875",
    RegisteredPopulation: "283",
    VotingStationName: "MPOPHOMA PRIMARY SCHOOL",
    Address: "NKANDLA  TUGELA FERRY  MABASO TRIBAL",
    Latitude: "-28.6966",
    Longitude: "30.4924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11494",
    WardLookupId: "3656",
    VDNumber: "43626203",
    RegisteredPopulation: "314",
    VotingStationName: "COSH HOSPITAL",
    Address: "TUGELA FERRY  MTHEMBU TRIBAL AUTHORITY",
    Latitude: "-28.7461",
    Longitude: "30.4496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11495",
    WardLookupId: "3657",
    VDNumber: "43620296",
    RegisteredPopulation: "532",
    VotingStationName: "OVERTOUN PRIMARY SCHOOL",
    Address: "TUGELA FERRY  OTHAME AREA  MBOMVU TRIBAL",
    Latitude: "-28.7136",
    Longitude: "30.5641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11496",
    WardLookupId: "3657",
    VDNumber: "43620656",
    RegisteredPopulation: "448",
    VotingStationName: "HOLWANE PRIMARY SCHOOL",
    Address: "TUGELA FERRY  HOLWANE AREA  MBOMVU TRIBAL",
    Latitude: "-28.6721",
    Longitude: "30.5794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11497",
    WardLookupId: "3657",
    VDNumber: "43620690",
    RegisteredPopulation: "631",
    VotingStationName: "MAWELE SECONDARY SCHOOL",
    Address: "TUGELA FERRY  OTHAME AREA  MBOMVU TRIABL",
    Latitude: "-28.6956",
    Longitude: "30.5488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11498",
    WardLookupId: "3657",
    VDNumber: "43620791",
    RegisteredPopulation: "640",
    VotingStationName: "ZWELINJANI HIGH SCHOOL",
    Address: "TUGELA FERYY  NOCOMBOSHE AREA  MBOMVU TRIBAL",
    Latitude: "-28.6697",
    Longitude: "30.6238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11499",
    WardLookupId: "3657",
    VDNumber: "43620847",
    RegisteredPopulation: "449",
    VotingStationName: "NOCOMBOSHE PRIMARY SCHOOL",
    Address: "TUGELA FERRY  NOCOMBOSHE AREA  MBOVU TRIBAL",
    Latitude: "-28.6685",
    Longitude: "30.6262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11500",
    WardLookupId: "3657",
    VDNumber: "43625077",
    RegisteredPopulation: "525",
    VotingStationName: "ZOMBANE HIGH SCHOOL",
    Address: "NGUBEVU AREA (MSINGA TOP)  TUGELA FERRY  MBOMVU TRIBAL",
    Latitude: "-28.6745",
    Longitude: "30.5716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11501",
    WardLookupId: "3657",
    VDNumber: "43625303",
    RegisteredPopulation: "448",
    VotingStationName: "PANO HIGH SCHOOL",
    Address: "TUGELA FERRY  NGUBEVU AREA (MSINGA TOP)  MBVOMVU TRIBAL",
    Latitude: "-28.6756",
    Longitude: "30.6028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11502",
    WardLookupId: "3657",
    VDNumber: "43625808",
    RegisteredPopulation: "347",
    VotingStationName: "EMBUSWENI PRIMARY SCHOOL",
    Address: "TUGELA FERRY  ENQABENI AREA (MSIGA TOP)  MBOMVU TRIBAL",
    Latitude: "-28.6558",
    Longitude: "30.6288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11503",
    WardLookupId: "3658",
    VDNumber: "43620599",
    RegisteredPopulation: "765",
    VotingStationName: "COLLESSIE PRIMARY SCHOOL",
    Address: "POMEROY  KOGQOHI AREA  QAMU TRIBAL",
    Latitude: "-28.6682",
    Longitude: "30.5306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11504",
    WardLookupId: "3658",
    VDNumber: "43620780",
    RegisteredPopulation: "1079",
    VotingStationName: "BUNYEBETHU HIGH SCHOOL",
    Address: "POMEROY  GXUSHANENI AREA  QAMU TRIBAL",
    Latitude: "-28.6002",
    Longitude: "30.4997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11505",
    WardLookupId: "3658",
    VDNumber: "43622715",
    RegisteredPopulation: "350",
    VotingStationName: "MAHEDENI PRIMARY  SCHOOL",
    Address: "POMEROY  MAHEDENI AREA  QAMU TRIBAL",
    Latitude: "-28.6428",
    Longitude: "30.5219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11506",
    WardLookupId: "3658",
    VDNumber: "43622726",
    RegisteredPopulation: "662",
    VotingStationName: "SIFULENI PRIMARY SCHOOL",
    Address: "POMEROY  ESIFULENI AREA  QAMU TRIBAL",
    Latitude: "-28.5683",
    Longitude: "30.5106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11507",
    WardLookupId: "3658",
    VDNumber: "43622748",
    RegisteredPopulation: "709",
    VotingStationName: "GAYISANI PRIMARY SCHOOL",
    Address: "POMEROY  EMNGENI AREA  QAMU TRIBAL",
    Latitude: "-28.6108",
    Longitude: "30.5428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11508",
    WardLookupId: "3658",
    VDNumber: "43622759",
    RegisteredPopulation: "307",
    VotingStationName: "MABEDLANE PRIMARY SCHOOL",
    Address: "POEMEROY  ESIGUBUDU AREA  QAMU TRIBAL",
    Latitude: "-28.585",
    Longitude: "30.567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11509",
    WardLookupId: "3658",
    VDNumber: "43625819",
    RegisteredPopulation: "286",
    VotingStationName: "NGUBUKAZI PRIMARY SCHOOL",
    Address: "POMEROY  KWANGUBUKAZI AREA  QAMU TRIBAL",
    Latitude: "-28.635",
    Longitude: "30.4994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11510",
    WardLookupId: "3659",
    VDNumber: "43620779",
    RegisteredPopulation: "939",
    VotingStationName: "NINI LOWER PRIMARY SCHOOL",
    Address: "MBINDOLO  POMEROY  MSINGA",
    Latitude: "-28.5839",
    Longitude: "30.4651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11511",
    WardLookupId: "3659",
    VDNumber: "43620915",
    RegisteredPopulation: "829",
    VotingStationName: "MAKHANKANE PRIMARY SCHOOL",
    Address: "MATSHEMATSHE  POMEROY  QAMU TRIBAL",
    Latitude: "-28.6054",
    Longitude: "30.4623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11512",
    WardLookupId: "3659",
    VDNumber: "43620926",
    RegisteredPopulation: "796",
    VotingStationName: "GORDON HIGH SCHOOL",
    Address: "GORDON MISSION  POMEROY  QAMU TRIBAL",
    Latitude: "-28.5514",
    Longitude: "30.4587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11513",
    WardLookupId: "3659",
    VDNumber: "43622489",
    RegisteredPopulation: "370",
    VotingStationName: "KUKHANYAKWEZWE PRIMARY SCHOOL",
    Address: "KWANGUBUKAZI  POMEROY  QAMU TRIBAL",
    Latitude: "-28.646",
    Longitude: "30.4688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11514",
    WardLookupId: "3659",
    VDNumber: "43622827",
    RegisteredPopulation: "1326",
    VotingStationName: "C J MTHETHWA COMMUNITY HALL",
    Address: "POMEROY  QAMU TRIBAL",
    Latitude: "-28.5587",
    Longitude: "30.4291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11515",
    WardLookupId: "3659",
    VDNumber: "43625718",
    RegisteredPopulation: "906",
    VotingStationName: "POMEROY COMBINED PRIMARY SCHOOL",
    Address: "LOT FOUR AREA  POMEROY  QAMU TRIBAL",
    Latitude: "-28.573",
    Longitude: "30.4399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11516",
    WardLookupId: "3660",
    VDNumber: "43620971",
    RegisteredPopulation: "699",
    VotingStationName: "MAHLABA PRIMARY SCHOOL",
    Address: "MAHLABA  ROAD  MAZABEKO  QAMU TRIBAL",
    Latitude: "-28.5297",
    Longitude: "30.5917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11517",
    WardLookupId: "3660",
    VDNumber: "43620982",
    RegisteredPopulation: "422",
    VotingStationName: "PHATHIZWE HIGH SCHOOL",
    Address: "KWAWOZA  POMEROY  QAMU TRIBAL",
    Latitude: "-28.5029",
    Longitude: "30.5701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11518",
    WardLookupId: "3660",
    VDNumber: "43621039",
    RegisteredPopulation: "172",
    VotingStationName: "MBIZIMBELWE PRIMARY SCHOOL",
    Address: "ELANDSKRAAL ROAD  POMEROY  QAMU TRIBAL",
    Latitude: "-28.4891",
    Longitude: "30.5711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11519",
    WardLookupId: "3660",
    VDNumber: "43622254",
    RegisteredPopulation: "789",
    VotingStationName: "QINISO PRIMARY SCHOOL",
    Address: "MTHALENI  POMEROY  QAMU TRIBAL",
    Latitude: "-28.538",
    Longitude: "30.5002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11520",
    WardLookupId: "3660",
    VDNumber: "43622760",
    RegisteredPopulation: "1163",
    VotingStationName: "GANGEYANA PRIMARY SCHOOL",
    Address: "MAHLABA  POMEROY  QAMU TRIBAL",
    Latitude: "-28.5349",
    Longitude: "30.623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11521",
    WardLookupId: "3660",
    VDNumber: "43624762",
    RegisteredPopulation: "660",
    VotingStationName: "QINELANI PRIMARY SCHOOL",
    Address: "MTHALENI AREA  POMEROY  QAMU TRIBAL",
    Latitude: "-28.5397",
    Longitude: "30.5254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11522",
    WardLookupId: "3660",
    VDNumber: "43626113",
    RegisteredPopulation: "304",
    VotingStationName: "LELELE PRIMARY SCHOOL",
    Address: "EZIBHIDLINI  POMEROY  QAMU TRIBAL",
    Latitude: "-28.5251",
    Longitude: "30.5476",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11523",
    WardLookupId: "3661",
    VDNumber: "43770461",
    RegisteredPopulation: "952",
    VotingStationName: "VIKINDLALA SCHOOL",
    Address: "1626 MBULWANE ROAD  CELE TA MBULWANE RESERVE  GREYTOWN",
    Latitude: "-29.1719",
    Longitude: "30.87",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11524",
    WardLookupId: "3661",
    VDNumber: "43770483",
    RegisteredPopulation: "651",
    VotingStationName: "UMVOTI HIGH SCHOOL",
    Address: "MATIMATOLO AREA  MBULWANE STORE  GREYTOWN",
    Latitude: "-29.1781",
    Longitude: "30.8821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11525",
    WardLookupId: "3661",
    VDNumber: "43770506",
    RegisteredPopulation: "437",
    VotingStationName: "MGWEMPISI PRIMARY SCHOOL",
    Address: "MASHAYINYONI AREA VIA AHRENS  MBULWANE MASHAYINYONI  GREYTOWN",
    Latitude: "-29.1908",
    Longitude: "30.9159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11526",
    WardLookupId: "3661",
    VDNumber: "43770517",
    RegisteredPopulation: "359",
    VotingStationName: "DOMREMY SCHOOL",
    Address: "MAKHABELA TRIBAL AUTHORITY ROAD D1525  MFUYAMUNI  GREYTOWN",
    Latitude: "-29.2051",
    Longitude: "30.9398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11527",
    WardLookupId: "3661",
    VDNumber: "43770562",
    RegisteredPopulation: "502",
    VotingStationName: "NCULWANE L P SCHOOL",
    Address: "MFUYAMUNI  GREYTOWN  MAKHABELA TC",
    Latitude: "-29.2381",
    Longitude: "30.9147",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11528",
    WardLookupId: "3661",
    VDNumber: "43770573",
    RegisteredPopulation: "400",
    VotingStationName: "MASHINGIZELA HIGH SCHOOL",
    Address:
      "BHEKIZWE TRIBAL AUTHORITY  MAKHABELENI TRIBAL AUTHORITY  GREYTOWN",
    Latitude: "-29.2265",
    Longitude: "30.8869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11529",
    WardLookupId: "3661",
    VDNumber: "43770629",
    RegisteredPopulation: "389",
    VotingStationName: "NJENGABANTHU SCHOOL",
    Address: "DALTON ROAD  DALTON  BOMVU B",
    Latitude: "-29.218",
    Longitude: "30.8164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11530",
    WardLookupId: "3661",
    VDNumber: "43770630",
    RegisteredPopulation: "202",
    VotingStationName: "MAKHOSONKE SCHOOL",
    Address: "BOMVU TRIBAL AUTHORITY  NJENJABANTU  UMVOTI",
    Latitude: "-29.2538",
    Longitude: "30.8052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11531",
    WardLookupId: "3661",
    VDNumber: "43774780",
    RegisteredPopulation: "274",
    VotingStationName: "BUBU SCHOOL",
    Address: "MAIN ROAD TO MAPHUMALO  MNSUDE AREA  MAKHABELA / BHEKIWE TC",
    Latitude: "-29.2471",
    Longitude: "30.9546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11532",
    WardLookupId: "3661",
    VDNumber: "43775422",
    RegisteredPopulation: "280",
    VotingStationName: "MBOPHENI L. P. SCHOOL",
    Address: "EMAKHABELENI  VUKAPHANSI  MAKHABELENI TRIBAL AUTHORITY",
    Latitude: "-29.1817",
    Longitude: "30.8689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11533",
    WardLookupId: "3661",
    VDNumber: "43775433",
    RegisteredPopulation: "169",
    VotingStationName: "MBALANE P. SCHOOL",
    Address: "D1626  MBULWANE  BOMVU B",
    Latitude: "-29.2151",
    Longitude: "30.8635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11534",
    WardLookupId: "3662",
    VDNumber: "43770449",
    RegisteredPopulation: "1431",
    VotingStationName: "BUSANA HIGH SCHOOL",
    Address:
      "MATIMATOLO MAIN ROAD  MATIMATOLO CELE TRIBAL AUTHORITY   GREYTOWN",
    Latitude: "-29.13",
    Longitude: "30.8196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11535",
    WardLookupId: "3662",
    VDNumber: "43770450",
    RegisteredPopulation: "943",
    VotingStationName: "CELE TRIBAL COURT",
    Address: "MATIMATOLO MAIN ROAD  MATIMATOLO  CELE TRIBAL AUTHORITY",
    Latitude: "-29.1363",
    Longitude: "30.8243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11536",
    WardLookupId: "3662",
    VDNumber: "43774623",
    RegisteredPopulation: "1483",
    VotingStationName: "MATIMATOLO PRIMARY SCHOOL",
    Address: "MATIMATOLO AREA  CELE TRIBAL AUTHORITY  UMVOTI",
    Latitude: "-29.1429",
    Longitude: "30.8368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11537",
    WardLookupId: "3663",
    VDNumber: "43770405",
    RegisteredPopulation: "1338",
    VotingStationName: "CANDABUTHULE SECONDARY SCHOOL",
    Address: "NTEMBISWENI ROAD  NTEMBISWENI RESERVE  GREYTOWN",
    Latitude: "-29.1623",
    Longitude: "30.7583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11538",
    WardLookupId: "3663",
    VDNumber: "43770416",
    RegisteredPopulation: "424",
    VotingStationName: "MZONIWE HIGH SCHOOL",
    Address: "EMDLELANTO UMVOTI KLOOF  AMABOMVU TRIBAL AREA  GREYTOWN",
    Latitude: "-29.2081",
    Longitude: "30.7348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11539",
    WardLookupId: "3663",
    VDNumber: "43770607",
    RegisteredPopulation: "343",
    VotingStationName: "MGAGA SCHOOL",
    Address: "NTEMBISWENI ROAD  NTEMBISWENI  GREYTOWN",
    Latitude: "-29.1757",
    Longitude: "30.7689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11540",
    WardLookupId: "3663",
    VDNumber: "43774892",
    RegisteredPopulation: "681",
    VotingStationName: "ENTEMBISWENI PRIMARY SCHOOL",
    Address: "ENTEMBISWENI  BOMVU B TRADITIONAL COUNCIL",
    Latitude: "-29.1565",
    Longitude: "30.7536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11541",
    WardLookupId: "3663",
    VDNumber: "43774904",
    RegisteredPopulation: "474",
    VotingStationName: "NTANDOYETHU PRIMARY SCHOOL",
    Address: "ENTEMBISWENI  BOMVU B TRADITIONAL COUNCIL",
    Latitude: "-29.1669",
    Longitude: "30.7485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11542",
    WardLookupId: "3663",
    VDNumber: "43775455",
    RegisteredPopulation: "374",
    VotingStationName: "ENDUBAZI P. SCHOOL",
    Address: "ARHENS ROAD  ENTEMBISWENI  BOMVU B",
    Latitude: "-29.1617",
    Longitude: "30.7526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11543",
    WardLookupId: "3664",
    VDNumber: "43770258",
    RegisteredPopulation: "684",
    VotingStationName: "HERMANSBURG COMBINED SCHOOL",
    Address: "KRANSKOP ROAD  HERMANSBURG  GREYTOWN",
    Latitude: "-29.0482",
    Longitude: "30.7863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11544",
    WardLookupId: "3664",
    VDNumber: "43770359",
    RegisteredPopulation: "298",
    VotingStationName: "LILANI PRIMARY SCHOOL",
    Address:
      "LILANI AREA VIA AHRENS  LILANI MTHEMBU SITHOLE TRIBAL AUTHORITY  GREYTOWN",
    Latitude: "-29.1183",
    Longitude: "30.8546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11545",
    WardLookupId: "3664",
    VDNumber: "43770360",
    RegisteredPopulation: "1177",
    VotingStationName: "MAQHINGA HIGH SCHOOL",
    Address:
      "VIA AHRENS D48 D1407 D1 D17  ESHANE MTHEMBU SITHOLE TRIBAL AUTHORITY  GREYTOWN",
    Latitude: "-29.1028",
    Longitude: "30.8351",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11546",
    WardLookupId: "3664",
    VDNumber: "43773992",
    RegisteredPopulation: "556",
    VotingStationName: "SHEBE PRIMARY SCHOOL",
    Address: "THULINI AREA  THULINI MTHEMBU SITHOLE TRIBAL AUTHORITY  KRANSKOP",
    Latitude: "-29.0843",
    Longitude: "30.8836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11547",
    WardLookupId: "3664",
    VDNumber: "43775466",
    RegisteredPopulation: "1141",
    VotingStationName: "ESHANE COMMUNITY HALL",
    Address: "ESHANE ROAD  ESHANE  MTEMBU SITHOLE",
    Latitude: "-29.1014",
    Longitude: "30.8307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11548",
    WardLookupId: "3665",
    VDNumber: "43770135",
    RegisteredPopulation: "224",
    VotingStationName: "ESINYAMBOTHI PRIMARY SCHOOL",
    Address: "KWAHLONGWA AREA  ESINYAMBOTHI  UMVOTI",
    Latitude: "-29.037",
    Longitude: "30.985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11549",
    WardLookupId: "3665",
    VDNumber: "43770203",
    RegisteredPopulation: "306",
    VotingStationName: "DULUMBE P. SCHOOL",
    Address: "DULUMBE AREA  KRANSKOP  NGCOLOSI TRIBAL",
    Latitude: "-28.9569",
    Longitude: "30.9183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11550",
    WardLookupId: "3665",
    VDNumber: "43770214",
    RegisteredPopulation: "242",
    VotingStationName: "POTSPRUIT PRIMARY SCHOOL",
    Address: "KRANSKOP ROAD  ESIGEDLANE POTSPRUIT FARM  KRANSKOP",
    Latitude: "-28.9768",
    Longitude: "30.8031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11551",
    WardLookupId: "3665",
    VDNumber: "43770225",
    RegisteredPopulation: "158",
    VotingStationName: "SISISIWE PRIMARY SCHOOL",
    Address: "MAPHUMULO RD DIEKOP KRANSKOP  SALEM ESTATE  UMVOTI",
    Latitude: "-29.019",
    Longitude: "30.9157",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11552",
    WardLookupId: "3665",
    VDNumber: "43770247",
    RegisteredPopulation: "153",
    VotingStationName: "HARMONY RETREAT",
    Address: "KRANSKOP ROAD  GREYTOWN  GREYTOWN",
    Latitude: "-29.0637",
    Longitude: "30.7042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11553",
    WardLookupId: "3665",
    VDNumber: "43774342",
    RegisteredPopulation: "938",
    VotingStationName: "DOMINO SERVITE C PRIVATE SCHOOL",
    Address: "KWASIZABANTU MISSION  SILVERSTREAM FARM  UMVOTI",
    Latitude: "-29.0801",
    Longitude: "30.9612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11554",
    WardLookupId: "3665",
    VDNumber: "43774870",
    RegisteredPopulation: "1469",
    VotingStationName: "ENSELENI COMMUNITY CRECHE",
    Address: "HERMANSBURG  MTHEMBU SITHOLE TRADITIONAL COUNCIL",
    Latitude: "-29.0366",
    Longitude: "30.7659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11555",
    WardLookupId: "3665",
    VDNumber: "43774915",
    RegisteredPopulation: "264",
    VotingStationName: "ENKAWINI COMMUNITY HALL",
    Address: "ENKAWINI AREA  KRANSKOP  AMAKHABELA TRADITIONAL COUNCIL",
    Latitude: "-28.9081",
    Longitude: "30.861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11556",
    WardLookupId: "3665",
    VDNumber: "43774960",
    RegisteredPopulation: "137",
    VotingStationName: "MFUSHANE SCHOOL",
    Address: "MFUSHANE  GREYTOWN  MABOMVINI TC",
    Latitude: "-28.9838",
    Longitude: "30.9657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11557",
    WardLookupId: "3665",
    VDNumber: "43910018",
    RegisteredPopulation: "830",
    VotingStationName: "KRANSKOP FARMERS HALL",
    Address: "23 MAIN ROAD  KRANSKOP  ",
    Latitude: "-28.9692",
    Longitude: "30.8601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11558",
    WardLookupId: "3665",
    VDNumber: "43910029",
    RegisteredPopulation: "228",
    VotingStationName: "KRANSKOP PRIMARY SCHOOL",
    Address: "55 MAIN STREET  KRANSKOP  KRANSKOP",
    Latitude: "-28.9641",
    Longitude: "30.8662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11559",
    WardLookupId: "3666",
    VDNumber: "43410934",
    RegisteredPopulation: "396",
    VotingStationName: "EMADEKENI PRIMARY SCHOOL",
    Address: "D215 INADI ROAD KWASENGE AREA  EMADEKENI  GREYTOWN",
    Latitude: "-28.7562",
    Longitude: "30.7912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11560",
    WardLookupId: "3666",
    VDNumber: "43410967",
    RegisteredPopulation: "1014",
    VotingStationName: "GCOTHOYI HIGH SCHOOL",
    Address: "GCOTHOYI AREA  KRANSKOP  AMAKHABELA",
    Latitude: "-28.8528",
    Longitude: "30.8619",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11561",
    WardLookupId: "3666",
    VDNumber: "43410978",
    RegisteredPopulation: "365",
    VotingStationName: "OPHOFINI SCHOOL",
    Address: "OPHOFINI AREA  OPHOFINI  KRANSKOP",
    Latitude: "-28.8786",
    Longitude: "30.9614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11562",
    WardLookupId: "3666",
    VDNumber: "43410989",
    RegisteredPopulation: "545",
    VotingStationName: "MAGOBHE PRIMARY SCHOOL",
    Address: "MAGOBHE AREA  KRANSKOP  AMAKHABELA TC",
    Latitude: "-28.8579",
    Longitude: "30.9168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11563",
    WardLookupId: "3666",
    VDNumber: "43415906",
    RegisteredPopulation: "504",
    VotingStationName: "VUKAPHANI PRIMARY SCHOOL",
    Address: "VUKAPHANSI AREA  KRANSKOP  AMAKHABELA",
    Latitude: "-28.8149",
    Longitude: "30.8935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11564",
    WardLookupId: "3666",
    VDNumber: "43416222",
    RegisteredPopulation: "289",
    VotingStationName: "MAWALANE SCHOOL",
    Address: "D215 MHLABATHINI AREA  AMAKHABELA TRIBAL AUTHORITY  UMVOTI",
    Latitude: "-28.8299",
    Longitude: "30.7917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11565",
    WardLookupId: "3666",
    VDNumber: "43417313",
    RegisteredPopulation: "279",
    VotingStationName: "KHUMBULANI PRIMARY SCHOOL",
    Address: "EMAKHABELENI VIA KRANSKOP INADI ROAD  MHLABATHINI AREA  UMVOTI",
    Latitude: "-28.8109",
    Longitude: "30.8311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11566",
    WardLookupId: "3666",
    VDNumber: "43417324",
    RegisteredPopulation: "202",
    VotingStationName: "ENHLOSWENI PRIMARY SCHOOL",
    Address: "VUKAPHANSI LOCATION AMAKHABELA TA  ENDUNDUMENI AREA  UMVOTI",
    Latitude: "-28.8174",
    Longitude: "30.9368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11567",
    WardLookupId: "3666",
    VDNumber: "43417863",
    RegisteredPopulation: "248",
    VotingStationName: "ENDUNDUMENI PRIMARY SCHOOL",
    Address: "AMAKHABELA  AMAKHABELA TRADITIONAL COUNCIL",
    Latitude: "-28.8551",
    Longitude: "30.9748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11568",
    WardLookupId: "3666",
    VDNumber: "43417997",
    RegisteredPopulation: "280",
    VotingStationName: "ETONGWE PRIMARY SCHOOL",
    Address: "AMAKHABELA  GREYTOWN  AMAKHABELA TA",
    Latitude: "-28.7857",
    Longitude: "30.9041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11569",
    WardLookupId: "3666",
    VDNumber: "43418774",
    RegisteredPopulation: "244",
    VotingStationName: "OSIZWENI PRIMARY SCHOOL",
    Address: "DIMANE AREA  KRANSKOP  AMAKHABELA TC",
    Latitude: "-28.8317",
    Longitude: "30.8421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11570",
    WardLookupId: "3667",
    VDNumber: "43840021",
    RegisteredPopulation: "2438",
    VotingStationName: "OLD NHLALAKAHLE COMMUNITY HALL",
    Address: "KHOMBA ROAD  NHLALAKAHLE  GREYTOWN",
    Latitude: "-29.0702",
    Longitude: "30.6031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11571",
    WardLookupId: "3667",
    VDNumber: "43840043",
    RegisteredPopulation: "1583",
    VotingStationName: "BUHLEBUYEZA SECONDARY SCHOOL",
    Address: "555 KHOMBA ROAD  ENHLALALAHLE  GREYTOWN",
    Latitude: "-29.0802",
    Longitude: "30.6086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11572",
    WardLookupId: "3667",
    VDNumber: "43840065",
    RegisteredPopulation: "1229",
    VotingStationName: "THOLINHLANHLA PRIMARY SCHOOL",
    Address: "KHOMBA ROAD  NHLALAKAHLE  UMVOTI [GREYTOWN])",
    Latitude: "-29.0671",
    Longitude: "30.6025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11573",
    WardLookupId: "3668",
    VDNumber: "43770270",
    RegisteredPopulation: "949",
    VotingStationName: "MUDEN COMMUNITY HALL",
    Address: "MAIN ROAD  MUDEN  UMVOTI",
    Latitude: "-28.9773",
    Longitude: "30.3748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11574",
    WardLookupId: "3668",
    VDNumber: "43774252",
    RegisteredPopulation: "787",
    VotingStationName: "SHESHISAMSAMO SCHOOL",
    Address: "MUDEN KEATES DRIFT ROAD  MUDEN  UMVOTI",
    Latitude: "-28.9216",
    Longitude: "30.4251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11575",
    WardLookupId: "3668",
    VDNumber: "43774331",
    RegisteredPopulation: "748",
    VotingStationName: "OPATHE PRIMARY SCHOOL",
    Address:
      "MUDEN ROAD NEAR POLICE STATION  OPHATHE MISSION  UMVOTI [GREYTOWN]",
    Latitude: "-28.9599",
    Longitude: "30.351",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11576",
    WardLookupId: "3668",
    VDNumber: "43774386",
    RegisteredPopulation: "722",
    VotingStationName: "CEBOLESIZWE SCHOOL",
    Address: "MUDEN / WEENEN RD  MUDEN  MOOI MPOFANA [MOOIRIVIER]",
    Latitude: "-28.9599",
    Longitude: "30.2968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11577",
    WardLookupId: "3668",
    VDNumber: "43774858",
    RegisteredPopulation: "291",
    VotingStationName: "MUDEN COMBINED SCHOOL",
    Address: "MUDEN  MACHUNWINI TRADITIONAL COUNCIL",
    Latitude: "-28.9476",
    Longitude: "30.3804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11578",
    WardLookupId: "3669",
    VDNumber: "43840032",
    RegisteredPopulation: "2373",
    VotingStationName: "GREYTOWN TOWN HALL",
    Address: "41 BELL STREET  GREYTOWN  UMVOTI MUNICIPALITY",
    Latitude: "-29.0588",
    Longitude: "30.5929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11579",
    WardLookupId: "3669",
    VDNumber: "43840076",
    RegisteredPopulation: "334",
    VotingStationName: "ELIJAH CHRISTAIN MINISTRIES",
    Address: "278 YORK STREET  GREYTOWN  UMVOTI MUNICIPALITY",
    Latitude: "-29.0664",
    Longitude: "30.5779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11580",
    WardLookupId: "3669",
    VDNumber: "43840087",
    RegisteredPopulation: "765",
    VotingStationName: "GREYTOWN SECONDARY SCHOOL",
    Address: "KELLY STREET  GREYTOWN  UMVOTI MUNICIPALITY",
    Latitude: "-29.0561",
    Longitude: "30.6017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11581",
    WardLookupId: "3669",
    VDNumber: "43840098",
    RegisteredPopulation: "189",
    VotingStationName: "GREYTOWN HIGH SCHOOL",
    Address: "DURBAN STREET  GREYTOWN  UMVOTI MUNICIPALITY",
    Latitude: "-29.0699",
    Longitude: "30.5765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11582",
    WardLookupId: "3670",
    VDNumber: "43773105",
    RegisteredPopulation: "420",
    VotingStationName: "QUARME FARM SCHOOL",
    Address: "D225 MOOI RIVER ROAD  QUARME FARM  GREYTOWN",
    Latitude: "-29.0929",
    Longitude: "30.5109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11583",
    WardLookupId: "3670",
    VDNumber: "43773453",
    RegisteredPopulation: "589",
    VotingStationName: "SEVEN OAKS PRIMARY SCHOOL",
    Address: "RIETVLEI ROAD  SEVEN OAKS  GREYTOWN",
    Latitude: "-29.2029",
    Longitude: "30.5751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11584",
    WardLookupId: "3670",
    VDNumber: "43773464",
    RegisteredPopulation: "381",
    VotingStationName: "UPPER UMVOTI PRIMARY SCHOOL",
    Address: "RIETVLEI ROAD  SEVEN OAKES  GREYTOWN",
    Latitude: "-29.2062",
    Longitude: "30.4403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11585",
    WardLookupId: "3670",
    VDNumber: "43774612",
    RegisteredPopulation: "500",
    VotingStationName: "KHULEKANI SCHOOL",
    Address: "R33 PMB ROAD AHRENS TURN OFF  RED GATE FARM  UMVOTI",
    Latitude: "-29.1402",
    Longitude: "30.6209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11586",
    WardLookupId: "3670",
    VDNumber: "43840010",
    RegisteredPopulation: "1765",
    VotingStationName: "GREYTOWN JUNIOR SCHOOL",
    Address: "DURBAN STREET  GREYTOWN  UMVOTI MUNICIPALITY",
    Latitude: "-29.0645",
    Longitude: "30.5872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11587",
    WardLookupId: "3671",
    VDNumber: "43770269",
    RegisteredPopulation: "1883",
    VotingStationName: "MT ERNESTINA PRIMARY SECONDARY SCHOOL",
    Address: "MUDEN ROAD  LUNEBERG FARM  GREYTOWN",
    Latitude: "-28.9868",
    Longitude: "30.4393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11588",
    WardLookupId: "3671",
    VDNumber: "43773318",
    RegisteredPopulation: "548",
    VotingStationName: "ELSIE MTSHALI SCHOOL",
    Address: "D222 RIETVLEI ROAD  GAYWOOD SITE NO 1093  GREYTOWN",
    Latitude: "-29.1251",
    Longitude: "30.4574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11589",
    WardLookupId: "3671",
    VDNumber: "43774106",
    RegisteredPopulation: "838",
    VotingStationName: "EYETHU PRIMARY SCHOOL",
    Address: "WEENEN/MUDEN MAIN ROAD  ROCKYDRIFT  MUDEN",
    Latitude: "-28.977",
    Longitude: "30.3296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11590",
    WardLookupId: "3671",
    VDNumber: "43774476",
    RegisteredPopulation: "270",
    VotingStationName: "CRANHAM PRIMARY SCHOOL",
    Address: "HIGH PEAK FARM  MOOI MPOFANA",
    Latitude: "-29.0804",
    Longitude: "30.3502",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11591",
    WardLookupId: "3671",
    VDNumber: "43774869",
    RegisteredPopulation: "554",
    VotingStationName: "LEMBETHE PRIMARY SCHOOL",
    Address: "MUDEN  MACHUNWINI TRADITIONAL COUNCIL",
    Latitude: "-29.0273",
    Longitude: "30.3733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11592",
    WardLookupId: "3671",
    VDNumber: "43840054",
    RegisteredPopulation: "557",
    VotingStationName: "ITHEMBANI PRIMARY SCHOOL",
    Address: "HOLMESDALE FARM  FARM  UMVOTI",
    Latitude: "-29.0287",
    Longitude: "30.5564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11593",
    WardLookupId: "3672",
    VDNumber: "43410877",
    RegisteredPopulation: "621",
    VotingStationName: "KWASENGE PRIMARY SCHOOL",
    Address: "VIA KRANSKOP TO KWASENGE NADI ROAD  KWASENGE  UMVOTI",
    Latitude: "-28.8057",
    Longitude: "30.7717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11594",
    WardLookupId: "3672",
    VDNumber: "43416233",
    RegisteredPopulation: "392",
    VotingStationName: "KWADINDA PRIMARY SCHOOL",
    Address:
      "D215 AMAKHABELA TRIBAL AUTHORITY NINGIZA AREA  AMAKHABELA  UMVOTI",
    Latitude: "-28.8307",
    Longitude: "30.7508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11595",
    WardLookupId: "3672",
    VDNumber: "43620050",
    RegisteredPopulation: "504",
    VotingStationName: "MZIWENKOSI COMBINED PRIMARY SCHOOL",
    Address: "NADI ROAD  NZALA-MSINGA  GREYTOWN",
    Latitude: "-28.8403",
    Longitude: "30.6638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11596",
    WardLookupId: "3672",
    VDNumber: "43620128",
    RegisteredPopulation: "882",
    VotingStationName: "DOLO COMBINED SCHOOL",
    Address: "D17  KWA DOLO  MBOMVU T/C",
    Latitude: "-28.878",
    Longitude: "30.733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11597",
    WardLookupId: "3672",
    VDNumber: "43620139",
    RegisteredPopulation: "289",
    VotingStationName: "EHLANZENI COMBINED PRIMARY SCHOOL",
    Address: "NADI NGUBEVU ROAD D 17  NADI MSINGA  MBOMVU T/C",
    Latitude: "-28.8622",
    Longitude: "30.6976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11598",
    WardLookupId: "3672",
    VDNumber: "43620151",
    RegisteredPopulation: "453",
    VotingStationName: "MBOMVU COMBINED PRIMARY SCHOOL",
    Address: "D 17 NADI ROAD  NQOLENI MSINGA  MBOMVU T/C",
    Latitude: "-28.8568",
    Longitude: "30.6884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11599",
    WardLookupId: "3672",
    VDNumber: "43620173",
    RegisteredPopulation: "788",
    VotingStationName: "ESIBILA COMBINED PRIMARY SCHOOL",
    Address: "D I7 NGUBEVU ROAD  MSINGA  MBOMVU T/C",
    Latitude: "-28.8201",
    Longitude: "30.6583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11600",
    WardLookupId: "3672",
    VDNumber: "43625831",
    RegisteredPopulation: "311",
    VotingStationName: "NDLANGAMANDLA PRIMARY SCHOOL",
    Address: "PHAKWE  MSINGA  MBOMVU TRIBAL AUTHORITY",
    Latitude: "-28.8174",
    Longitude: "30.6357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11601",
    WardLookupId: "3672",
    VDNumber: "43625842",
    RegisteredPopulation: "354",
    VotingStationName: "MZAMO CRECHE",
    Address: "ENZALA  TUGELA FERRY  MBOMVU TRIBAL",
    Latitude: "-28.8356",
    Longitude: "30.6914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11602",
    WardLookupId: "3673",
    VDNumber: "43770382",
    RegisteredPopulation: "973",
    VotingStationName: "SANGWENI PRIMARY SCHOOL",
    Address: "C/O THOKOZA CAFE  MTHEMBU SITHOLE EMBANGWENI AREA  UMVOTI",
    Latitude: "-29.1222",
    Longitude: "30.7781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11603",
    WardLookupId: "3673",
    VDNumber: "43770393",
    RegisteredPopulation: "1269",
    VotingStationName: "BANGUMUZI PRIMARY SCHOOL",
    Address: "MBOVU TRIBAL AUTHORITY  MBUBA  GREYTOWN",
    Latitude: "-29.1335",
    Longitude: "30.7703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11604",
    WardLookupId: "3673",
    VDNumber: "43774959",
    RegisteredPopulation: "842",
    VotingStationName: "ZAKHELE PRIMARY SCHOOL",
    Address: "ZAKHELE  GREYTOWN  CELE TA",
    Latitude: "-29.1291",
    Longitude: "30.7854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11605",
    WardLookupId: "3673",
    VDNumber: "43775444",
    RegisteredPopulation: "494",
    VotingStationName: "MBUBA COMMUNITY HALL",
    Address: "ARHENS ROAD  ENTEMBISWENI  BOMVU B",
    Latitude: "-29.1401",
    Longitude: "30.7684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11606",
    WardLookupId: "3674",
    VDNumber: "43620061",
    RegisteredPopulation: "245",
    VotingStationName: "PHAKWE PRIMARY SCHOOL",
    Address: "PHAKWE MAIN ROAD  KWANODLOZI AREA  GREYTOWN",
    Latitude: "-28.8765",
    Longitude: "30.636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11607",
    WardLookupId: "3674",
    VDNumber: "43620140",
    RegisteredPopulation: "542",
    VotingStationName: "SOMASHI HIGH SCHOOL",
    Address: "PHAKWE MAIN ROAD  DOLO AREA  MSINGA",
    Latitude: "-28.8687",
    Longitude: "30.6947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11608",
    WardLookupId: "3674",
    VDNumber: "43770236",
    RegisteredPopulation: "871",
    VotingStationName: "NGOME SCHOOL",
    Address: "DUNDEE ROAD  NGOME  UMVOTI",
    Latitude: "-28.9317",
    Longitude: "30.5739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11609",
    WardLookupId: "3674",
    VDNumber: "43774634",
    RegisteredPopulation: "1365",
    VotingStationName: "MBONGELENI PRIMARY SCHOOL",
    Address: "MPANZA AREA,  UMVOTI  UMVOTI",
    Latitude: "-28.9097",
    Longitude: "30.5468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11610",
    WardLookupId: "3674",
    VDNumber: "43774791",
    RegisteredPopulation: "486",
    VotingStationName: "LOOTSHOEK PRIMARY SCHOOL",
    Address: "INADI ROAD D215  NKONKA FARM  (KZN245 - UMVOTI [GREYTOWN])",
    Latitude: "-28.9064",
    Longitude: "30.7751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11611",
    WardLookupId: "3674",
    VDNumber: "43774881",
    RegisteredPopulation: "331",
    VotingStationName: "EMSENGENI CRECHE",
    Address: "NGOME  NGOME TRADITIONAL COUNCIL",
    Latitude: "-28.9014",
    Longitude: "30.6401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11612",
    WardLookupId: "3674",
    VDNumber: "43775477",
    RegisteredPopulation: "378",
    VotingStationName: "NGOME HALL",
    Address: "DUNDE ROAD  GREYTOWN  NGOME",
    Latitude: "-28.9247",
    Longitude: "30.5683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11613",
    WardLookupId: "3674",
    VDNumber: "43775668",
    RegisteredPopulation: "210",
    VotingStationName: "INHLEKABAYENI COMMUNITY HALL",
    Address: "KWASENGE ROAD  MAVALWANE AREA  AMAKHABELA TC",
    Latitude: "-28.849",
    Longitude: "30.7851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11614",
    WardLookupId: "1465",
    VDNumber: "43621769",
    RegisteredPopulation: "235",
    VotingStationName: "ENKETHENI JUNIOR PRIMARY SCHOOL",
    Address:
      "D537 BORDER FARM, AMAJUBA PASS   KWAGGASNEK ENKETHENI FARM, VOLKRUST  NEWCASTLE",
    Latitude: "-27.4661",
    Longitude: "29.7125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11615",
    WardLookupId: "1465",
    VDNumber: "43621837",
    RegisteredPopulation: "349",
    VotingStationName: "GLEN CALDER PRIMARY SCHOOL",
    Address: "D96 MEMEL ROAD  NEWCASTLE  NEWCASTLE",
    Latitude: "-27.7504",
    Longitude: "29.8406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11616",
    WardLookupId: "1465",
    VDNumber: "43621927",
    RegisteredPopulation: "2226",
    VotingStationName: "CLAVIS COMBINED PRIMARY SCHOOL",
    Address: "595 CNR CURCH & NEK STREE, CHARLES  CHARLESTOWN  NEWCASTLE",
    Latitude: "-27.4257",
    Longitude: "29.8721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11617",
    WardLookupId: "1465",
    VDNumber: "43622029",
    RegisteredPopulation: "343",
    VotingStationName: "BOTHAS  PASS  PRIMARY SCHOOL",
    Address: "BOTHAS PASS  NEWCASTLE  NEWCASTLE",
    Latitude: "-27.6499",
    Longitude: "29.7417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11618",
    WardLookupId: "1465",
    VDNumber: "43625370",
    RegisteredPopulation: "913",
    VotingStationName: "INGOGO PRIMARY SCHOOL",
    Address: "D377 SKUIKLIP 25KM FROM N11  INGOGO  NEWCASTLE",
    Latitude: "-27.5768",
    Longitude: "29.9192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11619",
    WardLookupId: "1465",
    VDNumber: "43625381",
    RegisteredPopulation: "385",
    VotingStationName: "AMAHOBE PRIMARY SCHOOL",
    Address: "11 N11 ROAD  NEWCASTLE  NEWCASTLE",
    Latitude: "-27.4736",
    Longitude: "29.8738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11620",
    WardLookupId: "1465",
    VDNumber: "43626012",
    RegisteredPopulation: "305",
    VotingStationName: "EKUBONGENI PRIMARY SCHOOL",
    Address: "INGOGO  INGOGO FARM  NEWCASTLE",
    Latitude: "-27.5865",
    Longitude: "29.8681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11621",
    WardLookupId: "1465",
    VDNumber: "43626269",
    RegisteredPopulation: "162",
    VotingStationName: "EMTHUNZINI PRIMARY SCHOOL",
    Address: "N 11  INGOGO  NEWCASTLE",
    Latitude: "-27.6343",
    Longitude: "29.961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11622",
    WardLookupId: "1466",
    VDNumber: "43670099",
    RegisteredPopulation: "2198",
    VotingStationName: "DRAKENSBERG PRIMARY SCHOOL",
    Address: "PADDOCK AVE,   PIONEER PARK  NEWCASTLE",
    Latitude: "-27.7232",
    Longitude: "29.9355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11623",
    WardLookupId: "1466",
    VDNumber: "43670729",
    RegisteredPopulation: "1481",
    VotingStationName: "CORNER ALBETROS & LOERIE STREET (TENT)",
    Address: "ALBATROS  SIGNAL HILL  NEWCASTLE",
    Latitude: "-27.7229",
    Longitude: "29.9191",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11624",
    WardLookupId: "1466",
    VDNumber: "43670730",
    RegisteredPopulation: "1400",
    VotingStationName: "FERRUM HIGH SCHOOL",
    Address: "8 AQUAMARINE DRIVE  SUNNYRIDGE  NEWCASTLE",
    Latitude: "-27.7308",
    Longitude: "29.9467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11625",
    WardLookupId: "1467",
    VDNumber: "43670011",
    RegisteredPopulation: "2454",
    VotingStationName: "LENNOXTON PRIMARY SCHOOL",
    Address: "12 DRAPER STREET, LENNOXTON  LENNOXTON  NEWCASTLE",
    Latitude: "-27.773",
    Longitude: "29.914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11626",
    WardLookupId: "1467",
    VDNumber: "43670022",
    RegisteredPopulation: "2136",
    VotingStationName: "SURYAVILLE  HIGHER PRIMARY SCHOOL",
    Address: "17 MANZIL CRESCENT  SURYAVILLE  NEWCASTLE",
    Latitude: "-27.7629",
    Longitude: "29.9079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11627",
    WardLookupId: "1468",
    VDNumber: "43670088",
    RegisteredPopulation: "1402",
    VotingStationName: "FARMERS HALL",
    Address:
      "HARDWICK STREET OPP NEWCASTLE    OPP NEWCASTLE INN PIONEER PARK  NEWCASTLE",
    Latitude: "-27.7436",
    Longitude: "29.9383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11628",
    WardLookupId: "1468",
    VDNumber: "43670101",
    RegisteredPopulation: "1849",
    VotingStationName: "EVANGELISH LUTHERISCHE CHURCH",
    Address: "15 OUKLIP  HUTTEN HEIGHTS  NEWCASTLE",
    Latitude: "-27.7355",
    Longitude: "29.9208",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11629",
    WardLookupId: "1468",
    VDNumber: "43670673",
    RegisteredPopulation: "1302",
    VotingStationName: "HUTTENPARK PRIMARY SCHOOL",
    Address: "4 RIETBOK AVENUE, HUTTEHEIGHTS  HUTTEN HEIGHTS  NEWCASTLE",
    Latitude: "-27.74",
    Longitude: "29.9256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11630",
    WardLookupId: "1469",
    VDNumber: "43670066",
    RegisteredPopulation: "2842",
    VotingStationName: "HOPE HIGH SCHOOL",
    Address: "77 TUGELA AVENUE  NCANDU PARK NEWCASTLE  NEWCASTLE",
    Latitude: "-27.7256",
    Longitude: "29.9748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11631",
    WardLookupId: "1469",
    VDNumber: "43670077",
    RegisteredPopulation: "3325",
    VotingStationName: "PANORAMA COMBINED SCHOOL",
    Address: "16 JBM HERTZOG AVENUE  BARRY HERTZOG PARK  NEWCASTLE",
    Latitude: "-27.7337",
    Longitude: "29.956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11632",
    WardLookupId: "1470",
    VDNumber: "43622096",
    RegisteredPopulation: "702",
    VotingStationName: "SIZANANI SECONDARY SCHOOL",
    Address: "8583 LESLEY FARM  JAKKALS FARM 5MINS FRP P483 RD  NEWCASTLE",
    Latitude: "-27.7598",
    Longitude: "30.1248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11633",
    WardLookupId: "1470",
    VDNumber: "43625101",
    RegisteredPopulation: "1290",
    VotingStationName: "SIYATHUTHUKA  PRIMARY SCHOOL",
    Address: "MANZANA FARM  OSIZWENI  NEWCASTLE, LESLEY FARM, ENYONINI",
    Latitude: "-27.7592",
    Longitude: "30.1355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11634",
    WardLookupId: "1470",
    VDNumber: "43625538",
    RegisteredPopulation: "1271",
    VotingStationName: "JOBSTOWN COMBINED SCHOOL",
    Address:
      "JOBSTOWN FARM 4KM FROM ISCOR H  4KM FROM ISCOR HOSTEL  NEWCASTLE [NEWCASTLE]",
    Latitude: "-27.7359",
    Longitude: "30.1194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11635",
    WardLookupId: "1470",
    VDNumber: "43625965",
    RegisteredPopulation: "2143",
    VotingStationName: "KALVARI CENTRE",
    Address: "LISTER FARM  NEWCASTLE",
    Latitude: "-27.7552",
    Longitude: "30.0914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11636",
    WardLookupId: "1471",
    VDNumber: "43622647",
    RegisteredPopulation: "2033",
    VotingStationName: "SGODIPHOLA PRIMARY SCHOOL",
    Address:
      "178 WITTEKLIP FARM, MNDOZO FARM. D  MNDOZO 1MINS FROM P 483 RD  NEWCASTLE",
    Latitude: "-27.7433",
    Longitude: "30.1949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11637",
    WardLookupId: "1471",
    VDNumber: "43625954",
    RegisteredPopulation: "1999",
    VotingStationName: "IZAZI HIGH SCHOOL",
    Address:
      "OSIZWENI  AMAJUBA MNDOZO FARM 10MIN FROM P483 RD  WITTEKLIP FARM, MNDOZO  NEAR DICKSY HALT",
    Latitude: "-27.7615",
    Longitude: "30.1828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11638",
    WardLookupId: "1471",
    VDNumber: "43670572",
    RegisteredPopulation: "2010",
    VotingStationName: "ZCC CHURCH",
    Address: "2600 OSIZWENI D  AMAJUBA OSIZWENI  NEWCASTLE",
    Latitude: "-27.775",
    Longitude: "30.1694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11639",
    WardLookupId: "1471",
    VDNumber: "43670853",
    RegisteredPopulation: "281",
    VotingStationName: "EMADANYINI TEMPORARY VOTING STATION(TENT)",
    Address: "MANZANA  OSIZWENI C  NEWCASTLE",
    Latitude: "-27.7744",
    Longitude: "30.1747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11640",
    WardLookupId: "1472",
    VDNumber: "43670505",
    RegisteredPopulation: "2174",
    VotingStationName: "PHAMBILI JUNIOR PRIMARY SCHOOL",
    Address: "1401 OSIZWENI  NEWCASTLE",
    Latitude: "-27.7799",
    Longitude: "30.1516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11641",
    WardLookupId: "1472",
    VDNumber: "43670538",
    RegisteredPopulation: "2332",
    VotingStationName: "INDONI LOWER PRIMARY SCHOOL",
    Address: "C162 EMATSHEKETSHENI, OSIZWENI  OSIZWENI  NEWCASTLE",
    Latitude: "-27.7753",
    Longitude: "30.1598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11642",
    WardLookupId: "1473",
    VDNumber: "43622074",
    RegisteredPopulation: "1720",
    VotingStationName: "INVERNESS COMBINED PRIMARY SCHOOL",
    Address: "INVERNESS FARM NEXT TO OSIZWEN  INVERNESS FARM  NEWCASTLE",
    Latitude: "-27.7871",
    Longitude: "30.1767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11643",
    WardLookupId: "1473",
    VDNumber: "43670550",
    RegisteredPopulation: "2329",
    VotingStationName: "ROMAN CATHOLIC CHURCH OSIZWENI",
    Address: "D663 OSIZWENI   OSIZWENI  NEWCASTLE",
    Latitude: "-27.7869",
    Longitude: "30.1625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11644",
    WardLookupId: "1473",
    VDNumber: "43670741",
    RegisteredPopulation: "1547",
    VotingStationName: "OSIZWENI HIGH SCHOOL",
    Address: "E747 OSIZWENI  OSIZWENI E  NEWCASTLE",
    Latitude: "-27.7944",
    Longitude: "30.1602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11645",
    WardLookupId: "1474",
    VDNumber: "43670516",
    RegisteredPopulation: "2202",
    VotingStationName: "ENHLOKWENI JUNIOR PRIMARY SCHOOL",
    Address: "A570 OSIZWENI OPP. LONGHOMES  OSIZWENI  NEWCASTLE",
    Latitude: "-27.7889",
    Longitude: "30.1494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11646",
    WardLookupId: "1474",
    VDNumber: "43670561",
    RegisteredPopulation: "1687",
    VotingStationName: "ZIBAMBELENI HIGH SCHOOL",
    Address: "C1415 OSIZWENI NEXT BOB SHOP  OSZIWENI  NEWCASTLE",
    Latitude: "-27.7813",
    Longitude: "30.1572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11647",
    WardLookupId: "1474",
    VDNumber: "43670864",
    RegisteredPopulation: "348",
    VotingStationName: "VUMANISABELO SCHOOL",
    Address: "KWA D  OSIZWENI D  NEWCASTLE",
    Latitude: "-27.7819",
    Longitude: "30.1652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11648",
    WardLookupId: "1475",
    VDNumber: "43670448",
    RegisteredPopulation: "2331",
    VotingStationName: "THEMBALENTSHA HIGH SCHOOL",
    Address: "A8074 OSIZWENI  OSIZWENI  NEWCASTLE",
    Latitude: "-27.7911",
    Longitude: "30.1251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11649",
    WardLookupId: "1475",
    VDNumber: "43670460",
    RegisteredPopulation: "1865",
    VotingStationName: "NDABANHLE HIGHER  PRIMARY SCHOOL",
    Address: "A3423 OSIZWENI NEXT TO KWANOBUHLE  OSIZWENI  NEWCASTLE",
    Latitude: "-27.7858",
    Longitude: "30.1224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11650",
    WardLookupId: "1475",
    VDNumber: "43670752",
    RegisteredPopulation: "500",
    VotingStationName: "OSIZWENI COMMUNITY HALL",
    Address: "8119 OA1 OSIZWENI NEXT BOXER STORE  OSIZWENI A  NEWCASTLE",
    Latitude: "-27.7912",
    Longitude: "30.1309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11651",
    WardLookupId: "1476",
    VDNumber: "43670404",
    RegisteredPopulation: "1141",
    VotingStationName: "SIZAMOKUHLE HIGHER PRIMARY SCHOOL",
    Address:
      "5518 LISTER FARM  OSIZWENI 35KM FROM NEWCASTLE P483 RD  NEWCASTLE",
    Latitude: "-27.764",
    Longitude: "30.1121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11652",
    WardLookupId: "1476",
    VDNumber: "43670415",
    RegisteredPopulation: "1989",
    VotingStationName: "CEBELIHLE SENIOR PRIMARY SCHOOL",
    Address:
      "JOHNSTON FARM, 20KM FROM NEWCA   AMAJUBA JOHNSTONE FARM   20 KM FROM NEWCASTLE P483",
    Latitude: "-27.7731",
    Longitude: "30.1211",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11653",
    WardLookupId: "1476",
    VDNumber: "43670594",
    RegisteredPopulation: "2639",
    VotingStationName: "EKUKHANYENI SHOW THE WAY CRECHE",
    Address: "JOHNSTOWN  BLAAUWBOSCH  NEWCASTLE",
    Latitude: "-27.7676",
    Longitude: "30.1032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11654",
    WardLookupId: "1477",
    VDNumber: "43670437",
    RegisteredPopulation: "2224",
    VotingStationName: "XOLANI HIGH SCHOOL",
    Address: "4876 OSIZWENI 4TH ROBOT TURN RIGHT  OSIZWENI  NEWCASTLE",
    Latitude: "-27.7821",
    Longitude: "30.1138",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11655",
    WardLookupId: "1477",
    VDNumber: "43670471",
    RegisteredPopulation: "2252",
    VotingStationName: "NOKUKHANYA JUNIOR PRIMARY SCHOOL",
    Address: "B2952 KWANOBUHLE 1KM FROM MAIN ROAD  OSIZWENI  NEWCASTLE",
    Latitude: "-27.7832",
    Longitude: "30.1258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11656",
    WardLookupId: "1478",
    VDNumber: "43670325",
    RegisteredPopulation: "2348",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "M604  MADADENI  NEWCASTLE",
    Latitude: "-27.7692",
    Longitude: "30.0792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11657",
    WardLookupId: "1478",
    VDNumber: "43670336",
    RegisteredPopulation: "2467",
    VotingStationName: "SIPHESIHLE PRIMARY SCHOOL",
    Address: "P1518 SECTION 7 , MADADENI  MADADENI  NEWCASTLE",
    Latitude: "-27.7648",
    Longitude: "30.084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11658",
    WardLookupId: "1478",
    VDNumber: "43670763",
    RegisteredPopulation: "1137",
    VotingStationName: "TENT AT PENTECOSTAL HOLINESS CHURCH",
    Address: "KWA P  MADADENI P  NEWCASTLE",
    Latitude: "-27.7607",
    Longitude: "30.0866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11659",
    WardLookupId: "1478",
    VDNumber: "43670820",
    RegisteredPopulation: "838",
    VotingStationName: "SECTION 7 KWA N BACK OPPOSITE MLANGENI SHOPS",
    Address: "MN9  MADADENI N  NEWCASTLE",
    Latitude: "-27.7709",
    Longitude: "30.0855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11660",
    WardLookupId: "1479",
    VDNumber: "43670370",
    RegisteredPopulation: "1580",
    VotingStationName: "AME CHURCH",
    Address:
      "TURN RIGHT AT ZULU`S GARAGE   NTSHANGASE 10 MIN FROM P272 RD  TO BLAAUBOSCH  NEWCASTLE",
    Latitude: "-27.8028",
    Longitude: "30.0875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11661",
    WardLookupId: "1479",
    VDNumber: "43670381",
    RegisteredPopulation: "3029",
    VotingStationName: "EZAMAKHANYA PRIMARY SCHOOL",
    Address:
      "STEILSDRIFT ROAD NEXT SJ ZULU   RD TO BLAAUBOSCH RIGHT 9 MILES  AMAJUBA",
    Latitude: "-27.8088",
    Longitude: "30.095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11662",
    WardLookupId: "1479",
    VDNumber: "43670628",
    RegisteredPopulation: "1175",
    VotingStationName: "NZIMENDE JUNIOR PRIMARY SCHOOL",
    Address: "DRY CUT FARM  BLAAUBOSCH 5 MIN FROM P483 RD  NEWCASTLE",
    Latitude: "-27.8025",
    Longitude: "30.063",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11663",
    WardLookupId: "1480",
    VDNumber: "43670358",
    RegisteredPopulation: "3636",
    VotingStationName: "MTIMANDE LOWER PRIMARY SCHOOL",
    Address:
      "GOODHOPE STORE INDUSTRIAL AREA  BLAAUWBOSCH LAAGTE, SHONGWE STAND  NEWCASTLE",
    Latitude: "-27.7839",
    Longitude: "30.0796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11664",
    WardLookupId: "1480",
    VDNumber: "43670369",
    RegisteredPopulation: "1965",
    VotingStationName: "SIZWE HIGHER PRIMARY SCHOOL",
    Address: "STEILDRIFT ROAD, FROM 9 MILES   BLAAUWBOSCH  NEWCASTLE",
    Latitude: "-27.7958",
    Longitude: "30.0836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11665",
    WardLookupId: "1480",
    VDNumber: "43670774",
    RegisteredPopulation: "727",
    VotingStationName: "MZAMO HIGH SCHOOL",
    Address: "BLAAUWBOSCH  NEXT TO ITHEKU  BUS STOP 5 MINS P483 RD  NEWCASTLE",
    Latitude: "-27.7913",
    Longitude: "30.0977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11666",
    WardLookupId: "1481",
    VDNumber: "43670426",
    RegisteredPopulation: "2397",
    VotingStationName: "SEBENZANI JUNIOR PRIMARY SCHOOL",
    Address: "A6142 0SIZWENI, NEXT TO MAKASANE STO  OSIZWENI  NEWCASTLE",
    Latitude: "-27.7905",
    Longitude: "30.1105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11667",
    WardLookupId: "1481",
    VDNumber: "43670459",
    RegisteredPopulation: "2105",
    VotingStationName: "SESIYABONGA HIGH SCHOOL",
    Address: "A6140 OSIZWENI  OSIZWENI  NEWCASTLE",
    Latitude: "-27.7911",
    Longitude: "30.1151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11668",
    WardLookupId: "1481",
    VDNumber: "43670831",
    RegisteredPopulation: "733",
    VotingStationName: "KINGDOM BIBLE CHURCH INTERNATIONAL",
    Address: "P296 ROAD  BLAAUBOSCH NEXT TO KWAMAGADLELA SHOPS  NEWCASTLE",
    Latitude: "-27.796",
    Longitude: "30.1071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11669",
    WardLookupId: "1482",
    VDNumber: "43670347",
    RegisteredPopulation: "1285",
    VotingStationName: "ST LEWIS BERTRAND`S HIGH SCHOOL",
    Address: "BLAAUWBOSH FARM, 28KM FROM N11  AMAJUBA , BLAAUBOSCH  NEWCASTLE",
    Latitude: "-27.7819",
    Longitude: "30.0942",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11670",
    WardLookupId: "1482",
    VDNumber: "43670392",
    RegisteredPopulation: "1662",
    VotingStationName: "SIPHUMELELE JUNIOR PRIMARY SCHOOL",
    Address: "5552 NEXT TO DLADLA SHOP OSIZWENI  OSIZWENI  NEWCASTLE",
    Latitude: "-27.7858",
    Longitude: "30.1064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11671",
    WardLookupId: "1482",
    VDNumber: "43670785",
    RegisteredPopulation: "1135",
    VotingStationName: "SISIZAKELE  PRIMARY SCHOOL",
    Address: "3221 BLAAUWBOSCH CATHOLIC CHURCH  BLAAUWBOSCH  NEWCASTLE",
    Latitude: "-27.7757",
    Longitude: "30.091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11672",
    WardLookupId: "1482",
    VDNumber: "43670796",
    RegisteredPopulation: "1214",
    VotingStationName: "NHLOSOKUHLE  PRIMARY SCHOOL",
    Address:
      "BLAAUWBOSCH  ITHEKU BUS STOP, BLAAUBOSCH 2MINS FRPM P483 RD  NEWCASTLE",
    Latitude: "-27.7898",
    Longitude: "30.0931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11673",
    WardLookupId: "1483",
    VDNumber: "43670303",
    RegisteredPopulation: "1841",
    VotingStationName: "MADADENI ASSEMBLY",
    Address: "SECTION 6 MADADENI  MADADENI  NEWCASTLE",
    Latitude: "-27.7785",
    Longitude: "30.0591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11674",
    WardLookupId: "1483",
    VDNumber: "43670314",
    RegisteredPopulation: "3167",
    VotingStationName: "MUZIKAYISE LOWER PRIMARY SCHOOL",
    Address: "L47 SECTION 7 STRAFFORD HILL   MADADENI  NEWCASTLE",
    Latitude: "-27.7745",
    Longitude: "30.0691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11675",
    WardLookupId: "1483",
    VDNumber: "43670684",
    RegisteredPopulation: "1099",
    VotingStationName: "SIMUNYE CLUSTER",
    Address: "MAD8 H39 MADADENI  MADADENI F  NEWCASTLE",
    Latitude: "-27.7822",
    Longitude: "30.0567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11676",
    WardLookupId: "1484",
    VDNumber: "43670123",
    RegisteredPopulation: "1993",
    VotingStationName: "VUKANI LOWER PRIMARY SCHOOL",
    Address: "10229 SECTION 1 MADADENI  MADADENI  NEWCASTLE",
    Latitude: "-27.7509",
    Longitude: "30.0188",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11677",
    WardLookupId: "1484",
    VDNumber: "43670202",
    RegisteredPopulation: "1425",
    VotingStationName: "MBUKENI HIGHER PRIMARY SCHOOL",
    Address: "4706 SECTION C MADADENI  MADADENI  NEWCASTLE",
    Latitude: "-27.7392",
    Longitude: "30.028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11678",
    WardLookupId: "1484",
    VDNumber: "43670639",
    RegisteredPopulation: "1278",
    VotingStationName: "HOFINA (TENT)",
    Address: "CANNAN AREA  MADADENI  NEWCASTLE",
    Latitude: "-27.7324",
    Longitude: "30.0332",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11679",
    WardLookupId: "1485",
    VDNumber: "43621679",
    RegisteredPopulation: "859",
    VotingStationName: "NCANDU COMBINED PRIMARY SCHOOL",
    Address: "NORMANDIEN ROAD  HOPE FARM  NEWCASTLE",
    Latitude: "-27.8166",
    Longitude: "29.8916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11680",
    WardLookupId: "1485",
    VDNumber: "43621680",
    RegisteredPopulation: "1196",
    VotingStationName: "ZIPHAKAMISENI  HIGH SCHOOL",
    Address:
      "1 KILBARCHAN , N11 TURN LEFT AND   TURN RIGHT TO KILBARCHAN  NEWCASTLE",
    Latitude: "-27.8375",
    Longitude: "29.963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11681",
    WardLookupId: "1485",
    VDNumber: "43621792",
    RegisteredPopulation: "1401",
    VotingStationName: "INGWE PRIMARY SCHOOL",
    Address:
      "BOSWORTH FARM 10KM FROM N11,   INGAGANE TURN RIGHT, 7KM   NEWCASTLE",
    Latitude: "-27.9",
    Longitude: "29.9991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11682",
    WardLookupId: "1485",
    VDNumber: "43621826",
    RegisteredPopulation: "595",
    VotingStationName: "HAIG PRIMARY SCHOOL",
    Address: "CHELMSFORD NOMANDIEN RD  NORMANDIEN, HAIG FARM  NEWCASTLE",
    Latitude: "-27.9736",
    Longitude: "29.8635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11683",
    WardLookupId: "1485",
    VDNumber: "43625112",
    RegisteredPopulation: "750",
    VotingStationName: "QHAWELESIZWE PRIMARY SCHOOL",
    Address:
      "TURN TO KARBOCHEM, ON GRAVEL    GRAVEL ROAD TO SETTLEMENT TIGER KLOOF  NEWCASTLE",
    Latitude: "-27.8026",
    Longitude: "29.9953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11684",
    WardLookupId: "1486",
    VDNumber: "43670112",
    RegisteredPopulation: "2142",
    VotingStationName: "VUMELANI LOWER PRIMARY SCHOOL",
    Address: "B2591 SECTION 2 MADADENI  MADADENI  NEWCASTLE",
    Latitude: "-27.7569",
    Longitude: "30.029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11685",
    WardLookupId: "1486",
    VDNumber: "43670134",
    RegisteredPopulation: "1921",
    VotingStationName: "MBALENHLE HIGH PRIMARY SCHOOL",
    Address: "2574 SECTION 2 MADADENI  MADADENI  NEWCASTLE",
    Latitude: "-27.7522",
    Longitude: "30.0268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11686",
    WardLookupId: "1486",
    VDNumber: "43670695",
    RegisteredPopulation: "1413",
    VotingStationName: "IKHWEZI HIGH SCHOOL",
    Address: "A10066 SECTION 1 MADADENI  MADADENI A  NEWCASTLE",
    Latitude: "-27.7611",
    Longitude: "30.0202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11687",
    WardLookupId: "1487",
    VDNumber: "43670190",
    RegisteredPopulation: "1463",
    VotingStationName: "HLABANA PRIMARY SCHOOL",
    Address: "C4701 SECTION 3 MADADENI  MADADENI  NEWCASTLE",
    Latitude: "-27.7453",
    Longitude: "30.0276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11688",
    WardLookupId: "1487",
    VDNumber: "43670213",
    RegisteredPopulation: "1587",
    VotingStationName: "PHIKELELA HIGH PRIMARY SCHOOL",
    Address: "4715 SECTION 3 MADADENI  MADADENI  NEWCASTLE",
    Latitude: "-27.7433",
    Longitude: "30.0379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11689",
    WardLookupId: "1487",
    VDNumber: "43670617",
    RegisteredPopulation: "962",
    VotingStationName: "ZUZA JUNIOR PRIMARY SCHOOL",
    Address: "C4708 SECTION 3 MADADENI  MADADENI  NEWCASTLE",
    Latitude: "-27.7379",
    Longitude: "30.0318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11690",
    WardLookupId: "1487",
    VDNumber: "43670808",
    RegisteredPopulation: "1086",
    VotingStationName: "KHULAKAHLE JUNIOR PRIMARY SCHOOL",
    Address: "4716 SECTION 3 MADADENI  MADADENI   NEWCASTLE",
    Latitude: "-27.742",
    Longitude: "30.0367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11691",
    WardLookupId: "1488",
    VDNumber: "43670145",
    RegisteredPopulation: "2084",
    VotingStationName: "KHASELIHLE LOWER PRIMARY SCHOOL",
    Address: "2577 SECTION 2 MADADENI  MADADENI  NEWCASTLE",
    Latitude: "-27.7539",
    Longitude: "30.0326",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11692",
    WardLookupId: "1488",
    VDNumber: "43670189",
    RegisteredPopulation: "1154",
    VotingStationName: "MADADENI COMMUNITY HALL",
    Address: "2031 SECTION 2 MADADENI  MADADENI  NEWCASTLE",
    Latitude: "-27.7487",
    Longitude: "30.034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11693",
    WardLookupId: "1488",
    VDNumber: "43670707",
    RegisteredPopulation: "1922",
    VotingStationName: "EZINCWADINI PRIMARY SCHOOL",
    Address: "D7305 SECTION 4 MADADENI  MADADENI D  NEWCASTLE",
    Latitude: "-27.7491",
    Longitude: "30.0441",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11694",
    WardLookupId: "1488",
    VDNumber: "43670842",
    RegisteredPopulation: "925",
    VotingStationName: "MAHON EVANGELICAL CHURCH OF SA",
    Address: "8321 ME31  MADADENI E  NEWCASTLE",
    Latitude: "-27.7562",
    Longitude: "30.0387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11695",
    WardLookupId: "1489",
    VDNumber: "43670033",
    RegisteredPopulation: "2953",
    VotingStationName: "FAIRLEIGH COMMUNITY HALL",
    Address: "21 ROBSON STREET  FAIRLEIGH  NEWCASTLE",
    Latitude: "-27.777",
    Longitude: "29.9298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11696",
    WardLookupId: "1489",
    VDNumber: "43670044",
    RegisteredPopulation: "1715",
    VotingStationName: "NEWCASTLE TOWN HALL",
    Address:
      "66 SCOTT STREET NEXT TO MUNIC OFF   NEXT TO NEWCASTLE LIBRARY  NEWCASTLE  NEWCASTLE",
    Latitude: "-27.7573",
    Longitude: "29.9315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11697",
    WardLookupId: "1490",
    VDNumber: "43670224",
    RegisteredPopulation: "2421",
    VotingStationName: "AMADADA HIGH SCHOOL",
    Address: "K541 SECTION 3, MADADENI ISCOR RD  MADADENI  NEWCASTLE",
    Latitude: "-27.74",
    Longitude: "30.0458",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11698",
    WardLookupId: "1490",
    VDNumber: "43670235",
    RegisteredPopulation: "2488",
    VotingStationName: "QAPHELANI JUNIOR PRIMARY SCHOOL",
    Address: "D7302 SECTION 4 MADADENI  MADADENI  NEWCASTLE",
    Latitude: "-27.7455",
    Longitude: "30.0529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11699",
    WardLookupId: "1490",
    VDNumber: "43670583",
    RegisteredPopulation: "298",
    VotingStationName: "MERIDIAN NEWCASTLE SCHOOL",
    Address: "MADADENI  MADADENI SECTION 4  NEWCASTLE",
    Latitude: "-27.7418",
    Longitude: "30.0578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11700",
    WardLookupId: "1490",
    VDNumber: "43670718",
    RegisteredPopulation: "1057",
    VotingStationName: "HLALANATHI  HIGH SCHOOL",
    Address: "D 7298 SECTION 4 MADADENI  MADADENI  NEWCASTLE",
    Latitude: "-27.7531",
    Longitude: "30.0533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11701",
    WardLookupId: "1491",
    VDNumber: "43670257",
    RegisteredPopulation: "2135",
    VotingStationName: "MARANATHA CHURCH",
    Address: "SECTION 4 MADADENI  MADADENI  NEWCASTLE",
    Latitude: "-27.7547",
    Longitude: "30.0533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11702",
    WardLookupId: "1491",
    VDNumber: "43670268",
    RegisteredPopulation: "2484",
    VotingStationName: "SECTION 6 ROMAN CATHOLIC CHURCH",
    Address: "F383  MADADENI SECTION 6  NEWCASTLE",
    Latitude: "-27.7614",
    Longitude: "30.0576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11703",
    WardLookupId: "1491",
    VDNumber: "43670651",
    RegisteredPopulation: "1515",
    VotingStationName: "CELANI PRIMARY SCHOOL",
    Address: "R 1526 SECTION 7 MADADENI  MADADENI R  NEWCASTLE",
    Latitude: "-27.7569",
    Longitude: "30.0672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11704",
    WardLookupId: "1492",
    VDNumber: "43670156",
    RegisteredPopulation: "1356",
    VotingStationName: "BUMBISIZWE SPECIAL SCHOOL",
    Address: "SECTION 5  MADADENI E  NEWCASTLE",
    Latitude: "-27.7642",
    Longitude: "30.0466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11705",
    WardLookupId: "1492",
    VDNumber: "43670167",
    RegisteredPopulation: "2385",
    VotingStationName: "MLAMLELI JUNIOR PRIMARY SCHOOL",
    Address:
      "9280 SECTION 5 MADADENI  MADADENI 4KM FROM MADADENI HOSPITAL  NEWCASTLE",
    Latitude: "-27.7571",
    Longitude: "30.0496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11706",
    WardLookupId: "1492",
    VDNumber: "43670178",
    RegisteredPopulation: "1945",
    VotingStationName: "NTOBEKO JUNIOR PRIMARY SCHOOL",
    Address: "E9290 SECTION 5, NEXT TO CLINIC NO 5  MADADENI  NEWCASTLE",
    Latitude: "-27.7546",
    Longitude: "30.042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11707",
    WardLookupId: "1493",
    VDNumber: "43670279",
    RegisteredPopulation: "2775",
    VotingStationName: "CATHULANI SENIOR PRIMARY SCHOOL",
    Address: "F596 SECTION 6 MADADENI  MADADENI  NEWCASTLE",
    Latitude: "-27.7699",
    Longitude: "30.0603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11708",
    WardLookupId: "1493",
    VDNumber: "43670291",
    RegisteredPopulation: "2744",
    VotingStationName: "MCEBOWOLWAZI PRIMARY SCHOOL",
    Address: "M1 SECTION7 MADADENI  MADADENI  NEWCASTLE",
    Latitude: "-27.7673",
    Longitude: "30.0696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11709",
    WardLookupId: "1494",
    VDNumber: "43622658",
    RegisteredPopulation: "1444",
    VotingStationName: "THIYASIZWE COMBINED PRIMARY SCHOOL",
    Address: "MANZANA FARM OLD OSIZWENI DEPO  OSIZWENI  NEWCASTLE",
    Latitude: "-27.77",
    Longitude: "30.1538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11710",
    WardLookupId: "1494",
    VDNumber: "43626034",
    RegisteredPopulation: "1443",
    VotingStationName: "OSIZWENI HANDICRAFT CENTRE",
    Address: "OFF 3088 OSIZ3 ST OSIZWENI  OSIZWENI  OSIZWENI",
    Latitude: "-27.7806",
    Longitude: "30.1323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11711",
    WardLookupId: "1494",
    VDNumber: "43670482",
    RegisteredPopulation: "1523",
    VotingStationName: "BUHLEBENTUTHUKO SENIOR PRIMARY SCHOOL",
    Address: "1398 OSIZWENI MAKHANYA STREET  OSIZWENI  NEWCASTLE",
    Latitude: "-27.7811",
    Longitude: "30.1395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11712",
    WardLookupId: "1494",
    VDNumber: "43670640",
    RegisteredPopulation: "638",
    VotingStationName: "ENHLONIPHWENI SENIOR PRIMARY SCHOOL",
    Address: "A2049 NEXT TO TOTAL GARAGE OSIZWENI  OSIZWENI  NEWCASTLE",
    Latitude: "-27.787",
    Longitude: "30.1339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11713",
    WardLookupId: "1495",
    VDNumber: "43622108",
    RegisteredPopulation: "2455",
    VotingStationName: "ESIDIDINI PRIMARY SCHOOL",
    Address:
      "1255 RIVERSMEET, MUSKRAAL 1 KM  FRO  MUSKRAAL 1KM FROM SECTION P1, MADADENI  NEWCASTLE",
    Latitude: "-27.7489",
    Longitude: "30.0847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11714",
    WardLookupId: "1495",
    VDNumber: "43670280",
    RegisteredPopulation: "2756",
    VotingStationName: "DUTCH REFORMED CHURCH",
    Address: "1701 KWA R SECTION 7  MADADENI R  NEWCASTLE",
    Latitude: "-27.7586",
    Longitude: "30.0696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11715",
    WardLookupId: "1496",
    VDNumber: "43670493",
    RegisteredPopulation: "2054",
    VotingStationName: "IZIKO JUNIOR PRIMARY SCHOOL",
    Address: "2772 OSIZWENI NEXT OSIZWENI STADIUM  OSIZWENI  NEWCASTLE",
    Latitude: "-27.794",
    Longitude: "30.1366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11716",
    WardLookupId: "1496",
    VDNumber: "43670527",
    RegisteredPopulation: "1302",
    VotingStationName: "BIBLE BAPTIST CHURCH",
    Address: "A115  OSIZWENI  NEWCASTLE",
    Latitude: "-27.7953",
    Longitude: "30.1477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11717",
    WardLookupId: "1496",
    VDNumber: "43670549",
    RegisteredPopulation: "2853",
    VotingStationName: "VULINDLELE PRIMARY SCHOOL",
    Address: "H1461 PASS BOB SHOP OSIZWENI  OSIZWENI   NEWCASTLE",
    Latitude: "-27.7989",
    Longitude: "30.1624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11718",
    WardLookupId: "1497",
    VDNumber: "43622085",
    RegisteredPopulation: "3095",
    VotingStationName: "ENKULULEKWENI  PRIMARY SCHOOL",
    Address: "DICKS HALT  NEWCASTLE",
    Latitude: "-27.7436",
    Longitude: "30.1765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11719",
    WardLookupId: "1497",
    VDNumber: "43625055",
    RegisteredPopulation: "1994",
    VotingStationName: "HLALUNOLWAZI SCHOOL",
    Address: "MANZANA  DICKS HALT  NEWCASTLE RURAL",
    Latitude: "-27.752",
    Longitude: "30.1578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11720",
    WardLookupId: "1497",
    VDNumber: "43626023",
    RegisteredPopulation: "1152",
    VotingStationName: "UMLONDOLOZI WETHU CRECHE",
    Address: "MANZANA  MANZANA FARM  NEWCASTLE",
    Latitude: "-27.7493",
    Longitude: "30.165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11721",
    WardLookupId: "1498",
    VDNumber: "43670055",
    RegisteredPopulation: "2430",
    VotingStationName: "RECREATION CENTRE",
    Address: "62 OAK STREET  ARBOR PARK  NEWCASTLE",
    Latitude: "-27.7738",
    Longitude: "29.9511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11722",
    WardLookupId: "1498",
    VDNumber: "43670606",
    RegisteredPopulation: "2627",
    VotingStationName: "V S ZULU PRIMARY  SCHOOL",
    Address: "KLIP RIVER ROAD  KWAMATHUKUZA  NEWCASTLE",
    Latitude: "-27.7633",
    Longitude: "30.0109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11723",
    WardLookupId: "1498",
    VDNumber: "43670819",
    RegisteredPopulation: "789",
    VotingStationName: "NEWCASTLE HIGH SCHOOL",
    Address: "81 ALLEN STREET   NEWCASTLE CENTRAL  NEWCASTLE",
    Latitude: "-27.7614",
    Longitude: "29.94",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11724",
    WardLookupId: "3675",
    VDNumber: "43621657",
    RegisteredPopulation: "144",
    VotingStationName: "KEMPLUST HALL",
    Address: "81 KEMPSLUST FARM  KWAMAGIDELA  UTRECHT",
    Latitude: "-27.46",
    Longitude: "30.5121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11725",
    WardLookupId: "3675",
    VDNumber: "43621747",
    RegisteredPopulation: "463",
    VotingStationName: "NDLAMLENZE TRIBAL COURT",
    Address: "407 STRYDFONTEIN FARM  INGCAKA  UTRECHT",
    Latitude: "-27.3442",
    Longitude: "30.3979",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11726",
    WardLookupId: "3675",
    VDNumber: "43621961",
    RegisteredPopulation: "139",
    VotingStationName: "MBATHANI LOWER PRIMARY SCHOOL",
    Address: "49 DAGERAAD FARM  MBATHANI  UTRECHT",
    Latitude: "-27.5215",
    Longitude: "30.2724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11727",
    WardLookupId: "3675",
    VDNumber: "43622557",
    RegisteredPopulation: "296",
    VotingStationName: "GELYK WATER PRIMARY SCHOOL",
    Address: "55 GELYK WATER FARM  GELYKWATER  UTRECHT",
    Latitude: "-27.5221",
    Longitude: "30.4018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11728",
    WardLookupId: "3675",
    VDNumber: "43622670",
    RegisteredPopulation: "268",
    VotingStationName: "DOROTHEA PRIMARY SCHOOL",
    Address: "1 MOOIHOEK  DOROTHEA  UTRECHT",
    Latitude: "-27.5832",
    Longitude: "30.596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11729",
    WardLookupId: "3675",
    VDNumber: "43622681",
    RegisteredPopulation: "448",
    VotingStationName: "WITUMFOLOZI COMBINED SCHOOL",
    Address: "57 WITUMVULOOS  WITUMFOLOZI  UTRECHT",
    Latitude: "-27.7079",
    Longitude: "30.6627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11730",
    WardLookupId: "3675",
    VDNumber: "43625134",
    RegisteredPopulation: "288",
    VotingStationName: "LUTHILUNYE COMBINED SCHOOL",
    Address: "17 VREDEHOF  LUTHILUNYE  UTRECHT",
    Latitude: "-27.3939",
    Longitude: "30.4049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11731",
    WardLookupId: "3675",
    VDNumber: "43625482",
    RegisteredPopulation: "378",
    VotingStationName: "LEMBE PRIMARY SCHOOL",
    Address: "27 BESTE HOOP FARM  MPOPHOMA  UTRECHT",
    Latitude: "-27.517",
    Longitude: "30.5352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11732",
    WardLookupId: "3676",
    VDNumber: "43680012",
    RegisteredPopulation: "1808",
    VotingStationName: "UTRECHT TOWN HALL",
    Address: "55 VOOR STREET  UTRECHT  UTRECHT",
    Latitude: "-27.6563",
    Longitude: "30.3217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11733",
    WardLookupId: "3676",
    VDNumber: "43680023",
    RegisteredPopulation: "1121",
    VotingStationName: "WHITE CITY HALL",
    Address: "2 PINE STREET  WHITE CITY  UTRECHT",
    Latitude: "-27.6713",
    Longitude: "30.3118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11734",
    WardLookupId: "3677",
    VDNumber: "43621815",
    RegisteredPopulation: "415",
    VotingStationName: "NDWAKAZANA COMBINED SCHOOL",
    Address: "184 FRISGEWAAGD FARM  NDWAKAZANA  UTRECHT",
    Latitude: "-27.8132",
    Longitude: "30.3646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11735",
    WardLookupId: "3677",
    VDNumber: "43621916",
    RegisteredPopulation: "286",
    VotingStationName: "NHLAZADOLO COMBINED SCHOOL",
    Address: "66 GROORTVLEI FARM  NHLAZADOLO  UTRECHT",
    Latitude: "-27.844",
    Longitude: "30.4902",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11736",
    WardLookupId: "3677",
    VDNumber: "43621949",
    RegisteredPopulation: "446",
    VotingStationName: "EMXHAKENI PRIMARY SCHOOL",
    Address: "148 WATERVAAL FARM ON THE MAIN ROA  EMXHAKENI  UTRECHT",
    Latitude: "-27.7685",
    Longitude: "30.2278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11737",
    WardLookupId: "3677",
    VDNumber: "43622546",
    RegisteredPopulation: "188",
    VotingStationName: "WATERVAL HALL",
    Address: "148 WATERVAL FARM CORRECTIONAL   WATERVAL FARM  UTRECHT",
    Latitude: "-27.7926",
    Longitude: "30.2564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11738",
    WardLookupId: "3677",
    VDNumber: "43625897",
    RegisteredPopulation: "292",
    VotingStationName: "WAAIHOEK  PRIMARY SCHOOL",
    Address: "173 WAAIHOEK FARM  WAAIHOEK  UTRECHT",
    Latitude: "-27.7723",
    Longitude: "30.392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11739",
    WardLookupId: "3677",
    VDNumber: "43625976",
    RegisteredPopulation: "355",
    VotingStationName: "ISIBONELESIHLE PRIMARY SCHOOL",
    Address: "162 ZANDSPRUIT FARM  ENKUKULEKWENI  UTRECHT",
    Latitude: "-27.7633",
    Longitude: "30.2888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11740",
    WardLookupId: "3677",
    VDNumber: "43626135",
    RegisteredPopulation: "127",
    VotingStationName: "MLWANE PRIMARY SCHOOL",
    Address: "77 PAARDEPOORT FARM  MLWANE  UTRECHT",
    Latitude: "-27.6957",
    Longitude: "30.4137",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11741",
    WardLookupId: "3678",
    VDNumber: "43621714",
    RegisteredPopulation: "372",
    VotingStationName: "SINQOBILE PRIMARY SCHOOL",
    Address: "412 KLOOFJE FARM  ZAAIHOEK  UTRECHT",
    Latitude: "-27.4316",
    Longitude: "30.0722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11742",
    WardLookupId: "3678",
    VDNumber: "43621725",
    RegisteredPopulation: "827",
    VotingStationName: "GROENVLEI COMBINED SCHOOL",
    Address: "370 GROENVLEI FARM  GROENVLEI  UTRECHT",
    Latitude: "-27.4622",
    Longitude: "30.2271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11743",
    WardLookupId: "3678",
    VDNumber: "43621758",
    RegisteredPopulation: "499",
    VotingStationName: "RESERVE CHURCH",
    Address: "R 543  RESERVE AREA  THEKWANE RIBAL AUTHORITY",
    Latitude: "-27.3827",
    Longitude: "29.9264",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11744",
    WardLookupId: "3678",
    VDNumber: "43621972",
    RegisteredPopulation: "308",
    VotingStationName: "KROMELLENBOOG PRIMARY SCHOOL",
    Address: "170 KROMELLENBOOG FARM  KROMM  UTRECHT",
    Latitude: "-27.6512",
    Longitude: "30.0305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11745",
    WardLookupId: "3678",
    VDNumber: "43622568",
    RegisteredPopulation: "423",
    VotingStationName: "ROOIWAL PRIMARY SCHOOL",
    Address: "7 ROODEKOP  ROOIWAL  UTRECHT",
    Latitude: "-27.556",
    Longitude: "29.9927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11746",
    WardLookupId: "3679",
    VDNumber: "43621983",
    RegisteredPopulation: "359",
    VotingStationName: "MADLANGENI PRIMARY SCHOOL",
    Address: "91 SWARTKOP FARM  MADLANGENI  UTRECHT",
    Latitude: "-27.6361",
    Longitude: "30.2727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11747",
    WardLookupId: "3679",
    VDNumber: "43622614",
    RegisteredPopulation: "1036",
    VotingStationName: "IZIMBUTHU COMBINED SCHOOL",
    Address: "179 BAROUW FARM   EZIMBUTHU   AMAJUBA DISCTRICT 25",
    Latitude: "-27.6976",
    Longitude: "30.2856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11748",
    WardLookupId: "3679",
    VDNumber: "43625505",
    RegisteredPopulation: "1187",
    VotingStationName: "ITHAMSANQA PRIMARY SCHOOL",
    Address: "154 VAALBANK ROAD  VAALBANK  UTRECHT",
    Latitude: "-27.7257",
    Longitude: "30.232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11749",
    WardLookupId: "3679",
    VDNumber: "43626146",
    RegisteredPopulation: "284",
    VotingStationName: "ENGODINI SCHOOL",
    Address: "178 KLIPSPRUIT FARM  AMANTUNGWA TRIBAL AUTHORITY  UTRECHT",
    Latitude: "-27.5537",
    Longitude: "30.106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11750",
    WardLookupId: "3680",
    VDNumber: "43621668",
    RegisteredPopulation: "666",
    VotingStationName: "ENZIMANE  COMBINED SCHOOL",
    Address: "127 LANGBUILT FARM  ENZIMANE  UTRECHT",
    Latitude: "-27.6425",
    Longitude: "30.5326",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11751",
    WardLookupId: "3680",
    VDNumber: "43621804",
    RegisteredPopulation: "196",
    VotingStationName: "ESIDAKENI PRIMARY SCHOOL",
    Address: "104 VAALBANK FARM  ESIDAKENI  UTRECHT",
    Latitude: "-27.6",
    Longitude: "30.4052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11752",
    WardLookupId: "3680",
    VDNumber: "43621938",
    RegisteredPopulation: "261",
    VotingStationName: "SLAGVELD PRIMARY SCHOOL",
    Address: "122 WELTEVREDEN FARM  NKUBUNDE  UTRECHT",
    Latitude: "-27.7866",
    Longitude: "30.544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11753",
    WardLookupId: "3680",
    VDNumber: "43622018",
    RegisteredPopulation: "425",
    VotingStationName: "KINGSLEY COMBINE SCHOOL",
    Address: "106 KLIPSPRUIT FARM  KINGSLEY  UTRECHT",
    Latitude: "-27.9342",
    Longitude: "30.518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11754",
    WardLookupId: "3680",
    VDNumber: "43680034",
    RegisteredPopulation: "249",
    VotingStationName: "EMALAHLENI COMBINED SCHOOL",
    Address: "R 34  KHAYELIHLE  UTRECHT",
    Latitude: "-27.6479",
    Longitude: "30.3028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11755",
    WardLookupId: "3680",
    VDNumber: "43680045",
    RegisteredPopulation: "145",
    VotingStationName: "BALGRAY HALL",
    Address: "00 BALGRAY  BALGRAY HOSTEL  UTRECHT",
    Latitude: "-27.6318",
    Longitude: "30.3612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11756",
    WardLookupId: "3681",
    VDNumber: "43621871",
    RegisteredPopulation: "411",
    VotingStationName: "SINQOBILE PRIMARY SCHOOL",
    Address: "NORMANDIEN  NORMANDIEN VILLAGE  AMAJUBA DISTRICT",
    Latitude: "-28.0281",
    Longitude: "29.7856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11757",
    WardLookupId: "3681",
    VDNumber: "43621882",
    RegisteredPopulation: "284",
    VotingStationName: "MVUYELWA PRIMARY SCHOOL",
    Address: "HORSESHOE FARM  NORMANIEN  DANNHAUSER",
    Latitude: "-28.0165",
    Longitude: "29.721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11758",
    WardLookupId: "3681",
    VDNumber: "43621893",
    RegisteredPopulation: "320",
    VotingStationName: "GARDENS PRIMARY SCHOOL",
    Address: "ALCOCKSPRUIT  ALCOCKSPRUIT FARM  DANNHAUSER",
    Latitude: "-27.9304",
    Longitude: "30.0057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11759",
    WardLookupId: "3681",
    VDNumber: "43622142",
    RegisteredPopulation: "85",
    VotingStationName: "MOSSDALE PRIMARY SCHOOL",
    Address: "LAURISTON  EAST FARM  LAURISTON EAST FARM  NEWCASTLE",
    Latitude: "-27.9742",
    Longitude: "29.9593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11760",
    WardLookupId: "3681",
    VDNumber: "43622377",
    RegisteredPopulation: "327",
    VotingStationName: "KALABASI CRECHE",
    Address: "HANSFORD FARM  SUNSET REST  DANNHAUSER",
    Latitude: "-28.1212",
    Longitude: "29.9813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11761",
    WardLookupId: "3681",
    VDNumber: "43624829",
    RegisteredPopulation: "2008",
    VotingStationName: "K. ALLEN COMMUNITY HALL",
    Address: "KOPPIE ALLEN  KOPPIE ALLEN /DANNHAUSER  AMAJUBA DISTRICT",
    Latitude: "-27.9656",
    Longitude: "30.0232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11762",
    WardLookupId: "3681",
    VDNumber: "43625336",
    RegisteredPopulation: "141",
    VotingStationName: "CELA COMBINED SCHOOL",
    Address: "KLIPPOORT FARM  NORMANDIEN  DANNHAUSER",
    Latitude: "-28.1254",
    Longitude: "29.7566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11763",
    WardLookupId: "3681",
    VDNumber: "43625347",
    RegisteredPopulation: "135",
    VotingStationName: "BISMARK FARM (TENT)",
    Address: "BISMARK  DANNHAUSER  DANNHAUSER",
    Latitude: "-28.0183",
    Longitude: "29.9904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11764",
    WardLookupId: "3681",
    VDNumber: "43690024",
    RegisteredPopulation: "395",
    VotingStationName: "MANZIMNYAMA PRIMARY SCHOOL",
    Address: "PUNGIBOURNE FARM,  NORMANDIEN  DANNHAUSER",
    Latitude: "-28.1225",
    Longitude: "29.8895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11765",
    WardLookupId: "3682",
    VDNumber: "43622805",
    RegisteredPopulation: "1595",
    VotingStationName: "DNC COMBINED SCHOOL",
    Address: "MAIN STREET  DURNACOL  DANNHAUSER",
    Latitude: "-28.0533",
    Longitude: "30.0174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11766",
    WardLookupId: "3682",
    VDNumber: "43630017",
    RegisteredPopulation: "1344",
    VotingStationName: "DANNHAUSER SECONDARY SCHOOL",
    Address: "46 MAIN ROAD  DANNHAUSER",
    Latitude: "-28.0196",
    Longitude: "30.056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11767",
    WardLookupId: "3682",
    VDNumber: "43630028",
    RegisteredPopulation: "1178",
    VotingStationName: "SIPHUTHANDO PUBLIC COMBINED SCHOOL",
    Address: "592(1) EMAFUSINI TOWNSHIP  DANNHAUSER [DURNACOL]",
    Latitude: "-28.03",
    Longitude: "30.0595",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11768",
    WardLookupId: "3682",
    VDNumber: "43630039",
    RegisteredPopulation: "426",
    VotingStationName: "DANNHAUSER PRIMARY SCHOOL",
    Address:
      "10 NEWCASTLE ROAD  (KZN254 - DANNHAUSER [DURNACOL])   (KZN254 - DANNHAUSER [DURNACOL])",
    Latitude: "-28.0114",
    Longitude: "30.0574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11769",
    WardLookupId: "3683",
    VDNumber: "43621781",
    RegisteredPopulation: "2415",
    VotingStationName: "PHATHAKAHLE SECONDARY SCHOOL",
    Address: "VERDRIET FARM  DANNHAUSER  MAJUBA DISTRICT",
    Latitude: "-27.9903",
    Longitude: "30.1477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11770",
    WardLookupId: "3683",
    VDNumber: "43622388",
    RegisteredPopulation: "672",
    VotingStationName: "SIKELELA PRIMARY SCHOOL",
    Address: "3 STATION ROAD  HATTINGSPRUIT  HATTINGSPRUIT",
    Latitude: "-28.0794",
    Longitude: "30.1137",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11771",
    WardLookupId: "3683",
    VDNumber: "43625549",
    RegisteredPopulation: "349",
    VotingStationName: "JOYLANDS PRIMARY SCHOOL",
    Address:
      "(STEN DRIVE INN FARM])   (HATTINGSPRUIT])   (KZN254 - DANNHAUSER [DURNACOL])",
    Latitude: "-28.1026",
    Longitude: "30.1521",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11772",
    WardLookupId: "3683",
    VDNumber: "43625998",
    RegisteredPopulation: "278",
    VotingStationName: "ENHLANHLENI PRIMARY SCHOOL",
    Address: "KLIPROETS AREA  NYANYADU  DANNHAUSER",
    Latitude: "-27.9939",
    Longitude: "30.1768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11773",
    WardLookupId: "3684",
    VDNumber: "43621736",
    RegisteredPopulation: "1233",
    VotingStationName: "HLOKOMANE PRIMARY SCHOOL",
    Address: "DORSET FARM  DANNHAUSER",
    Latitude: "-27.962",
    Longitude: "30.232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11774",
    WardLookupId: "3684",
    VDNumber: "43622276",
    RegisteredPopulation: "1264",
    VotingStationName: "ANGLICAN CHURCH OF SA",
    Address: "LADYBANK  NYANYADU  DANNHAUSER",
    Latitude: "-27.9649",
    Longitude: "30.2095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11775",
    WardLookupId: "3684",
    VDNumber: "43622412",
    RegisteredPopulation: "457",
    VotingStationName: "NYANYADU COMBINED SCHOOL",
    Address: "NYANYADU  CHESTER FARM  DANNHAUSER",
    Latitude: "-27.974",
    Longitude: "30.2593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11776",
    WardLookupId: "3684",
    VDNumber: "43624773",
    RegisteredPopulation: "141",
    VotingStationName: "NGISANA PRIMARY SCHOOL",
    Address: "ST. GEOGES FARM  NGISANA / UMZINYASHANA  DANNHAUSER",
    Latitude: "-28.0085",
    Longitude: "30.2255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11777",
    WardLookupId: "3684",
    VDNumber: "43625167",
    RegisteredPopulation: "504",
    VotingStationName: "THANDANANI CRECHE (TENT)",
    Address: "TRIM/CPRK FARM  NYANYADU   DANNHAUSER",
    Latitude: "-27.9646",
    Longitude: "30.2793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11778",
    WardLookupId: "3685",
    VDNumber: "43622210",
    RegisteredPopulation: "580",
    VotingStationName: "MZIWETHU HIGH SCHOOL",
    Address: "GRANGAAN  DRANGAAN FARM  DANNHAUSER",
    Latitude: "-27.8633",
    Longitude: "30.1526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11779",
    WardLookupId: "3685",
    VDNumber: "43622243",
    RegisteredPopulation: "820",
    VotingStationName: "MATA LOWER HIGHER PRIMARY SCHOOL",
    Address: "BRAAKWATER FARM  MBABANE  DANNHAUSER",
    Latitude: "-27.8494",
    Longitude: "30.1376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11780",
    WardLookupId: "3685",
    VDNumber: "43622366",
    RegisteredPopulation: "1256",
    VotingStationName: "THOLUMUSA CRECHE",
    Address: "CLONEEN AREA  EMBABANE  DANNHAUSER",
    Latitude: "-27.8865",
    Longitude: "30.145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11781",
    WardLookupId: "3685",
    VDNumber: "43624863",
    RegisteredPopulation: "433",
    VotingStationName: "SAINT PAUL ROMAN CATHOLIC CHURCH",
    Address: "DRAANGAN FARM  DRAANGAN  DANNHAUSER",
    Latitude: "-27.8755",
    Longitude: "30.1799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11782",
    WardLookupId: "3685",
    VDNumber: "43625583",
    RegisteredPopulation: "457",
    VotingStationName: "EKUBIKELWENI KWENKOSI CHURCH",
    Address:
      "SHEPSTONE LAKE FARM  DANNHAUSER  (KZN254 - DANNHAUSER [DURNACOL])",
    Latitude: "-27.8552",
    Longitude: "30.177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11783",
    WardLookupId: "3686",
    VDNumber: "43622399",
    RegisteredPopulation: "588",
    VotingStationName: "MILLFORD HALL",
    Address: "MILLFORD  GULE TRADITIONAL AUTHORITY  DANNHAUSER",
    Latitude: "-27.9559",
    Longitude: "30.2598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11784",
    WardLookupId: "3686",
    VDNumber: "43622423",
    RegisteredPopulation: "780",
    VotingStationName: "OKHALWENI PRIMARY SCHOOL",
    Address: "FLINT FARM  FLINT  DANNHAUSER",
    Latitude: "-27.9279",
    Longitude: "30.2464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11785",
    WardLookupId: "3686",
    VDNumber: "43622445",
    RegisteredPopulation: "1777",
    VotingStationName: "RUTLAND COMBINED PRIMARY SCHOOL",
    Address: "RUTLAND  RUTLAND FARM  DANNHAUSER",
    Latitude: "-27.945",
    Longitude: "30.2136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11786",
    WardLookupId: "3686",
    VDNumber: "43622467",
    RegisteredPopulation: "548",
    VotingStationName: "MEHLWANA PRIMARY SCHOOL",
    Address: "MEHLWANA  NKOSIBOMVU FARM  DANNAHAUSER",
    Latitude: "-27.9601",
    Longitude: "30.2019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11787",
    WardLookupId: "3687",
    VDNumber: "43622300",
    RegisteredPopulation: "935",
    VotingStationName: "HILLTOP PRIMARY SCHOOL",
    Address: "HILLTOP   DANNHAUSER  DANNHAUSER",
    Latitude: "-27.9582",
    Longitude: "30.1354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11788",
    WardLookupId: "3687",
    VDNumber: "43622535",
    RegisteredPopulation: "547",
    VotingStationName: "CACA PRIMARY SCHOOL",
    Address: "BUHLE BE ALLEN  UBUHLE BE ALLEN NUMBER 1  AMAJUBA DISTRICT",
    Latitude: "-27.9467",
    Longitude: "30.1583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11789",
    WardLookupId: "3687",
    VDNumber: "43624841",
    RegisteredPopulation: "1168",
    VotingStationName: "UBUHLE BE ALLEN PRIMARY SCHOOL",
    Address:
      "1 ALLEN FARM  BUHLE BE ALLEN NUMBER 1  AMAJUBA DISTRICT MUNICIPALITY",
    Latitude: "-27.9449",
    Longitude: "30.1705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11790",
    WardLookupId: "3687",
    VDNumber: "43625123",
    RegisteredPopulation: "455",
    VotingStationName: "KHALIMA COMBINED PRIMARY SCHOOL",
    Address: "SEELVE DONALD FARM  DANNHAUSER [DURNACOL]",
    Latitude: "-27.9667",
    Longitude: "30.1662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11791",
    WardLookupId: "3687",
    VDNumber: "43625156",
    RegisteredPopulation: "880",
    VotingStationName: "KWAM-DAKANE HALL",
    Address: "KWAMDAKANE CENTRE  ANNIEVILLE  DANNHAUSER",
    Latitude: "-27.9327",
    Longitude: "30.1626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11792",
    WardLookupId: "3688",
    VDNumber: "43622153",
    RegisteredPopulation: "1017",
    VotingStationName: "THATHUNYAWO HIGH SCHOOL",
    Address: "SPRINGBOKLAAGTE  DANNHAUSER",
    Latitude: "-27.8358",
    Longitude: "30.2439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11793",
    WardLookupId: "3688",
    VDNumber: "43622164",
    RegisteredPopulation: "355",
    VotingStationName: "SPOOKMILL COMBINED PRIMARY SCHOOL",
    Address: "SPOOKMILL FARM  DANNHAUSER",
    Latitude: "-27.8252",
    Longitude: "30.2553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11794",
    WardLookupId: "3688",
    VDNumber: "43622175",
    RegisteredPopulation: "869",
    VotingStationName: "EKUKHANYENI PRE-SCHOOL",
    Address: "NAAS FARM/ MAFAHLAWANE   EMAFAHLAWANE  DANNHAUSER",
    Latitude: "-27.8256",
    Longitude: "30.2256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11795",
    WardLookupId: "3688",
    VDNumber: "43622355",
    RegisteredPopulation: "999",
    VotingStationName: "IMPALA HIGH SCHOOL",
    Address: "SPRINGBOKLAAGTE FARM  DANNHAUSER  DANNHAUSER",
    Latitude: "-27.8432",
    Longitude: "30.24",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11796",
    WardLookupId: "3688",
    VDNumber: "43625358",
    RegisteredPopulation: "348",
    VotingStationName: "GREENOECK PRIMARY SCHOOL",
    Address: "GREENOECK  DANNHAUSER  DANNHAUSER",
    Latitude: "-27.8557",
    Longitude: "30.267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11797",
    WardLookupId: "3688",
    VDNumber: "43626001",
    RegisteredPopulation: "479",
    VotingStationName: "UMZILA PRIMARY SCHOOL",
    Address: "NAAS FARM  EMAFAHLAWANE  DANNHAUSER",
    Latitude: "-27.8328",
    Longitude: "30.2167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11798",
    WardLookupId: "3689",
    VDNumber: "43622030",
    RegisteredPopulation: "1366",
    VotingStationName: "SOSINDA PRIMARY SCHOOL",
    Address: "BIRKENSTOCK  BUHLEBOMZINYATHI  DANNHAUSER",
    Latitude: "-27.8183",
    Longitude: "30.1947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11799",
    WardLookupId: "3689",
    VDNumber: "43622052",
    RegisteredPopulation: "1054",
    VotingStationName: "SIMANGANYAWO COMBINED PRIMARY SCHOOL",
    Address: "EHUDULA  GROOTGELUK  DANNHAUSER",
    Latitude: "-27.8231",
    Longitude: "30.1526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11800",
    WardLookupId: "3689",
    VDNumber: "43624852",
    RegisteredPopulation: "894",
    VotingStationName: "BUHLE BOMZINYATHI COMMUNITY AUTHORITY",
    Address: "LEKKERWATER  DANNHAUSER  AMAJUBA DISTRICT",
    Latitude: "-27.8094",
    Longitude: "30.1566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11801",
    WardLookupId: "3689",
    VDNumber: "43625561",
    RegisteredPopulation: "453",
    VotingStationName: "ESISONKE PRIMARY SCHOOL",
    Address: "KWAFILIPU  DANNHAUSER",
    Latitude: "-27.8437",
    Longitude: "30.1914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11802",
    WardLookupId: "3689",
    VDNumber: "43625572",
    RegisteredPopulation: "759",
    VotingStationName: "PHAKAMANI PRE-SCHOOL",
    Address: "FULATHELA FARM  DANNHAUSER",
    Latitude: "-27.7897",
    Longitude: "30.1744",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11803",
    WardLookupId: "3690",
    VDNumber: "43622041",
    RegisteredPopulation: "1333",
    VotingStationName: "NTENDEKA PRIMARY SCHOOL",
    Address: "NTENDEKA NO2  NTENDEKA FARM  DANNHAUSER",
    Latitude: "-27.8341",
    Longitude: "30.0915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11804",
    WardLookupId: "3690",
    VDNumber: "43622063",
    RegisteredPopulation: "822",
    VotingStationName: "42 HALL",
    Address: "42 HALL  EARST BORN FARM  AMAJUBA DISTRICT MUNICIPALITY",
    Latitude: "-27.8116",
    Longitude: "30.1278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11805",
    WardLookupId: "3690",
    VDNumber: "43622186",
    RegisteredPopulation: "1020",
    VotingStationName: "MNYAMANDE HIGH SCHOOL",
    Address: "GAVIN/NTENDEKA FARM  NTENDEKA   DANNHAUSER",
    Latitude: "-27.8331",
    Longitude: "30.115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11806",
    WardLookupId: "3690",
    VDNumber: "43625369",
    RegisteredPopulation: "1047",
    VotingStationName: "ZIZAMELE ABET CENTRE",
    Address: "EASTBOURNE FARM  DANNHAUSER  DANNHAUSER",
    Latitude: "-27.7985",
    Longitude: "30.1457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11807",
    WardLookupId: "3691",
    VDNumber: "43622265",
    RegisteredPopulation: "898",
    VotingStationName: "SIBAHLE SINJE CRECHE",
    Address:
      "THIRST FARM   THIRST FARM DANNHAUSER   (KZN254 - DANNHAUSER [DURNACOL])",
    Latitude: "-27.9141",
    Longitude: "30.2032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11808",
    WardLookupId: "3691",
    VDNumber: "43622322",
    RegisteredPopulation: "1441",
    VotingStationName: "IPHUNGUPHUNGU PRIMARY SCHOOL",
    Address: "NELLY VALLEY  NELLY VALLEY FARM  DANNHAUSER",
    Latitude: "-27.9283",
    Longitude: "30.1772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11809",
    WardLookupId: "3691",
    VDNumber: "43622333",
    RegisteredPopulation: "1727",
    VotingStationName: "SIYAWELA PRIMARY SCHOOL",
    Address: "KIKEEL FARM  KILKEEL FARM  DANNHAUSER",
    Latitude: "-27.9031",
    Longitude: "30.1803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11810",
    WardLookupId: "3692",
    VDNumber: "43620308",
    RegisteredPopulation: "1655",
    VotingStationName: "SIYAKHULA PRE-SCHOOL",
    Address: "ANNIEVILLE  DANNHAUSER  DANNHAUSER",
    Latitude: "-27.9329",
    Longitude: "30.1576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11811",
    WardLookupId: "3692",
    VDNumber: "43622221",
    RegisteredPopulation: "681",
    VotingStationName: "ANNANDALE PRIMARY SCHOOL",
    Address: "ANNANDALE  ANNANDALE TRUST FARM  DANNHAUSER",
    Latitude: "-27.9155",
    Longitude: "30.1176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11812",
    WardLookupId: "3692",
    VDNumber: "43624830",
    RegisteredPopulation: "451",
    VotingStationName: "IKHAYELIHLE PRE-SCHOOL",
    Address: "DORINGKOP   DANNHAUSER  DANNHAUSER",
    Latitude: "-27.9394",
    Longitude: "30.1253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11813",
    WardLookupId: "3692",
    VDNumber: "43625594",
    RegisteredPopulation: "1013",
    VotingStationName: "KWADEDANGEDLULE HALL",
    Address: "ANNANDALE  ANNANDALE FARM  DANNHAUSER",
    Latitude: "-27.9018",
    Longitude: "30.1428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11814",
    WardLookupId: "3693",
    VDNumber: "43622197",
    RegisteredPopulation: "687",
    VotingStationName: "COOPER COMBINED PRIMARY SCHOOL",
    Address: "COOPER FARM  DANNHAUSER  DANNHAUSER",
    Latitude: "-27.8911",
    Longitude: "30.2757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11815",
    WardLookupId: "3693",
    VDNumber: "43622209",
    RegisteredPopulation: "414",
    VotingStationName: "FAIRBREEZE PRIMARY SCHOOL",
    Address: "FAIRBREEZE FARM  FAIRBREEZE   DANNHAUSER",
    Latitude: "-27.8768",
    Longitude: "30.2125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11816",
    WardLookupId: "3693",
    VDNumber: "43622434",
    RegisteredPopulation: "572",
    VotingStationName: "MOY PRIMARY SCHOOL",
    Address: "MOY FARM  DANNHAUSER",
    Latitude: "-27.9176",
    Longitude: "30.2307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11817",
    WardLookupId: "3693",
    VDNumber: "43625550",
    RegisteredPopulation: "1147",
    VotingStationName: "EMFUNDWENI HIGH SCHOOL",
    Address: "THIRST FARM  DANNHAUSER   DANNHAUSER",
    Latitude: "-27.8875",
    Longitude: "30.1978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11818",
    WardLookupId: "3693",
    VDNumber: "43625606",
    RegisteredPopulation: "757",
    VotingStationName: "FAIRBREEZE COMMUNITY HALL",
    Address: "FAIRBREEZE FARM  FAIRBREEZE   DANNHAUSER",
    Latitude: "-27.8739",
    Longitude: "30.2229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11819",
    WardLookupId: "3694",
    VDNumber: "43501542",
    RegisteredPopulation: "319",
    VotingStationName: "MPENVANE  PRIMARY SCHOOL",
    Address: "MPEVANE AREA  PAULPIETERSBURG  BHOVUNGANE TRIBAL",
    Latitude: "-27.5768",
    Longitude: "30.7159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11820",
    WardLookupId: "3694",
    VDNumber: "43501586",
    RegisteredPopulation: "187",
    VotingStationName: "BIVANE PRIMARY SCHOOL",
    Address: "BIVANE MISSION  BIVANE  BHOVUNGANE TRIBAL AUTHORITY",
    Latitude: "-27.5451",
    Longitude: "30.6948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11821",
    WardLookupId: "3694",
    VDNumber: "43501597",
    RegisteredPopulation: "184",
    VotingStationName: "FREIBURG PRIMARY SCHOOL",
    Address: "LONGRIDGE AREA  PPBURG - BHOVUNGANE AREA",
    Latitude: "-27.4993",
    Longitude: "30.6367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11822",
    WardLookupId: "3694",
    VDNumber: "43501609",
    RegisteredPopulation: "529",
    VotingStationName: "NKLANGOSI COMBINED PRIMARY SCHOOL",
    Address:
      "NKLANGOSI AREA - JABULA FARM  PAULPIETERSBURG  NKOSI TRIBAL AUTHORITY",
    Latitude: "-27.3865",
    Longitude: "30.5957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11823",
    WardLookupId: "3694",
    VDNumber: "43501610",
    RegisteredPopulation: "414",
    VotingStationName: "BRECHER PRIMARY  SCHOOL",
    Address: "BRECHER  NKOSI TRIBAL AUTHORITY",
    Latitude: "-27.3266",
    Longitude: "30.7385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11824",
    WardLookupId: "3694",
    VDNumber: "43501621",
    RegisteredPopulation: "648",
    VotingStationName: "IKUSASA ELIHLE HIGH SCHOOL",
    Address:
      "BAZANGOMA AREA -GROOTSPRUIT  BAZANGOMA AREA  NKOSI TRIBAL AUTHORITY",
    Latitude: "-27.4125",
    Longitude: "30.6735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11825",
    WardLookupId: "3694",
    VDNumber: "43501632",
    RegisteredPopulation: "984",
    VotingStationName: "LUNEBURG CASH STORE",
    Address: "LUNEBURG  NKOSI TRIBAL AUTHORITY",
    Latitude: "-27.3299",
    Longitude: "30.631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11826",
    WardLookupId: "3694",
    VDNumber: "43501654",
    RegisteredPopulation: "507",
    VotingStationName: "NDABAMBI  PRIMARY SCHOOL",
    Address: "NKONKOTHO AREA  NKONKOTHO  BHOVUNGANE TRIBAL AUTHORITY",
    Latitude: "-27.4773",
    Longitude: "30.7181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11827",
    WardLookupId: "3694",
    VDNumber: "43502666",
    RegisteredPopulation: "186",
    VotingStationName: "MANNJALO PRIMARY SCHOOL",
    Address:
      "LENJANE POLITIEK FARM  LENJANE POLITIEK FARM ENROUTE TO LUNEBURG  PAULPIETERSBURG",
    Latitude: "-27.3577",
    Longitude: "30.7705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11828",
    WardLookupId: "3694",
    VDNumber: "43504118",
    RegisteredPopulation: "170",
    VotingStationName: "KOTO  PRIMARY SCHOOL",
    Address: "KWALOTI FARM - ZEHARD NIEBUHR  KWALOTI FARM  EDUMBE",
    Latitude: "-27.3357",
    Longitude: "30.6713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11829",
    WardLookupId: "3694",
    VDNumber: "43504343",
    RegisteredPopulation: "79",
    VotingStationName: "PROTES PRIMARY SCHOOL",
    Address: "KEMPSLUST MINES  KWALEMBE AREA- KEMPLUST MINES  PAULPIETERSBURG",
    Latitude: "-27.4637",
    Longitude: "30.5196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11830",
    WardLookupId: "3694",
    VDNumber: "43504354",
    RegisteredPopulation: "352",
    VotingStationName: "EKHOMBELA PRIMARY SCHOOL",
    Address:
      "EKHOMBELA MISSION-NEXT TO NKOS  EKHOMBELA LUTHERAN MISSION  NKOSI TRIBAL AUTHORITY",
    Latitude: "-27.3378",
    Longitude: "30.5741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11831",
    WardLookupId: "3694",
    VDNumber: "43504365",
    RegisteredPopulation: "244",
    VotingStationName: "MAKATEESKOP PRIMARY SCHOOL",
    Address: "MAKATEESKOP FARM  PAULPIETERSBURG  PAULPIETERSBURG",
    Latitude: "-27.4374",
    Longitude: "30.7184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11832",
    WardLookupId: "3694",
    VDNumber: "43504387",
    RegisteredPopulation: "215",
    VotingStationName: "EHLOKO PRIMARY SCHOOL",
    Address:
      "WAKKERSTROOM ROAD -   PAST - NKLANGOSI SCHOOL - PAULPIETERSBURG  DEELSPRUIT FARM",
    Latitude: "-27.3864",
    Longitude: "30.5344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11833",
    WardLookupId: "3694",
    VDNumber: "43505221",
    RegisteredPopulation: "89",
    VotingStationName: "HLONGOMIYA SHOOL",
    Address: "HLONGOMIYA AREA  PAULPIETERSBURG  BHOVUNGANE TRIBAL AUTHORITY",
    Latitude: "-27.5195",
    Longitude: "30.6732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11834",
    WardLookupId: "3695",
    VDNumber: "43501711",
    RegisteredPopulation: "955",
    VotingStationName: "KWASA HIGH SCHOOL",
    Address:
      "EBILANYONI - ENGOJE SECTION  FRISCHGEWACHT -BILANYONI - PPBURG  PAULPIETERSBURG",
    Latitude: "-27.3868",
    Longitude: "30.9503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11835",
    WardLookupId: "3695",
    VDNumber: "43501722",
    RegisteredPopulation: "878",
    VotingStationName: "MANDLANA PRIMARY  SCHOOL",
    Address:
      "KWANGWANYA AREA  KWANGWANYA  AREA - PAULPIETERSBURG  HLATSHWAYO TRIBAL AUTHORITY",
    Latitude: "-27.3849",
    Longitude: "30.9238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11836",
    WardLookupId: "3695",
    VDNumber: "43501766",
    RegisteredPopulation: "1814",
    VotingStationName: "MUZIWESIZWE HIGH SCHOOL",
    Address:
      "MANGOSUTHU VILLAGE   MANGOSUTHU VILLAGE NEXT TO MAIN ROAD TO BILANYONI   PAULPIETERSBURG",
    Latitude: "-27.4098",
    Longitude: "30.9411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11837",
    WardLookupId: "3695",
    VDNumber: "43504938",
    RegisteredPopulation: "1319",
    VotingStationName: "MTHONJENI  INTERMEDIATE PRIMARY SCHOOL",
    Address:
      "MANGOSUTHU VILLAGE -   MANGOSUTHU VILLAGE - COM HALL - PPBURG  MTHETHWA TRIBAL COURT",
    Latitude: "-27.4036",
    Longitude: "30.94",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11838",
    WardLookupId: "3695",
    VDNumber: "43504949",
    RegisteredPopulation: "297",
    VotingStationName: "ESIYALWINI PRIMARY SCHOOL",
    Address:
      "EZIBOMVU  AREA  ESIYALWINI -EZIBOMVU AREA PPBURG  DLAMINI TRIBAL COURT",
    Latitude: "-27.4202",
    Longitude: "30.9588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11839",
    WardLookupId: "3696",
    VDNumber: "43540017",
    RegisteredPopulation: "1254",
    VotingStationName: "PAULPIETERSBURG TOWN HALL",
    Address:
      "10 HOOG STRAAT  PAULPIETERSBURG TOWN  PAULPIETERSBURG - EDUMBE MUNICIPALITY",
    Latitude: "-27.4232",
    Longitude: "30.8196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11840",
    WardLookupId: "3696",
    VDNumber: "43540028",
    RegisteredPopulation: "2740",
    VotingStationName: "DUMBE COMM HALL",
    Address: "DUMBE LOCATION  PAULPIETERSBURG  EDUMBE MUNICIPALITY",
    Latitude: "-27.4057",
    Longitude: "30.8197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11841",
    WardLookupId: "3696",
    VDNumber: "43540039",
    RegisteredPopulation: "1594",
    VotingStationName: "H MATSHINGA PRIMARY SCHOOL",
    Address:
      "DUMBE LOCATION  EDUMBE LOCATION -GREENFIELD AREA - PAULPIETERSBURG  EDUMBE [PAULPIETERSBURG]",
    Latitude: "-27.396",
    Longitude: "30.8259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11842",
    WardLookupId: "3696",
    VDNumber: "43540040",
    RegisteredPopulation: "266",
    VotingStationName: "SIBONGOKUHLE CRECHE",
    Address: "DUMBE LOCATION  EDUMBE LOCATION  PAULPIETERSBURG",
    Latitude: "-27.3984",
    Longitude: "30.8147",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11843",
    WardLookupId: "3697",
    VDNumber: "43501733",
    RegisteredPopulation: "1863",
    VotingStationName: "KANYE KANYE HIGH SCHOOL",
    Address:
      "BILANYONI- MOSCOW  BILANYONI -EMOSCOW - PAULPIETERSBURG  PPBURG - EDUMBE MUNICIPALITY",
    Latitude: "-27.3792",
    Longitude: "30.9541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11844",
    WardLookupId: "3697",
    VDNumber: "43501744",
    RegisteredPopulation: "2307",
    VotingStationName: "BILANYONI PRIMARY SCHOOL",
    Address: "BILANYONI- NEXT TO MAYABA STOR  EDUMBE  PPBURG",
    Latitude: "-27.3918",
    Longitude: "30.9527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11845",
    WardLookupId: "3697",
    VDNumber: "43504051",
    RegisteredPopulation: "657",
    VotingStationName: "ISIHLANGUSIKAZULU PRIMARY SCHOOL",
    Address:
      "BILANYONI VILLAGE   BILANYONI -NEXT TO THE GRAVEYARD - PAULPIETERSBURG- EDUMBE MUNICIPALITY",
    Latitude: "-27.3928",
    Longitude: "30.956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11846",
    WardLookupId: "3697",
    VDNumber: "43504129",
    RegisteredPopulation: "365",
    VotingStationName: "EMBIZENI COMBINED PRIMARY SCHOOL",
    Address:
      "EMBIZENI AREA  EMBIZENI AREA ON THE ROAD TO MPANGWINI-PPBURG  DLAMINI TRIBAL AUTHORITY",
    Latitude: "-27.3765",
    Longitude: "30.9666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11847",
    WardLookupId: "3697",
    VDNumber: "43505232",
    RegisteredPopulation: "315",
    VotingStationName: "EKUJABULENI PRIMARY SCHOOL",
    Address: "BILANYONI MOSCOW  BILANYONI  PAULPIETERSBURG",
    Latitude: "-27.3748",
    Longitude: "30.9523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11848",
    WardLookupId: "3698",
    VDNumber: "43501799",
    RegisteredPopulation: "1038",
    VotingStationName: "THOLAKELE PRIMARY SCHOOL",
    Address:
      "THOLAKELE AREA  THOLAKELE AREA NEXT TO SIZODELA SCHOOL - PPBURG  MTHETHWA TRIBAL COURT",
    Latitude: "-27.4462",
    Longitude: "30.9679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11849",
    WardLookupId: "3698",
    VDNumber: "43501823",
    RegisteredPopulation: "806",
    VotingStationName: "DLAMINI TRIBAL COURT",
    Address:
      "THOLAKELE- ENGOJE  THOLAKELE ENGOJE - PAST THOLAKELE COM HALL  DLAMINI TRIBAL AUTHORITY",
    Latitude: "-27.4363",
    Longitude: "30.9881",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11850",
    WardLookupId: "3698",
    VDNumber: "43501834",
    RegisteredPopulation: "569",
    VotingStationName: "ABAQULUSI HIGH SCHOOL",
    Address:
      "KLEINFONTEIN FARM - EKHUSENI  KLEINFONTEIN FARM -EKHUSENI NEAR KWAVOVA PPBURG  MTHETHWA TRIBAL COURT",
    Latitude: "-27.4734",
    Longitude: "31.0384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11851",
    WardLookupId: "3698",
    VDNumber: "43502789",
    RegisteredPopulation: "239",
    VotingStationName: "INDONDOKAZULU COMBINED PRIMARY SCHOOL",
    Address:
      "EZINKANGALA AREA  EZINKANGALA - PAST KWAMDUNYISWA NKOSI - PPBURG  MTHETHWA TRIBAL AUTHORITY",
    Latitude: "-27.475",
    Longitude: "30.9946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11852",
    WardLookupId: "3698",
    VDNumber: "43503780",
    RegisteredPopulation: "327",
    VotingStationName: "KWAMPUNZI COMBINED HIGH SCHOOL",
    Address:
      "KWAMPUNZI - EZINTABENI  KWAMPUNZI -EZINTABENI NEAR PHILMON NKOSI PPBURG  DLAMINI TRBAL AUTHORITY",
    Latitude: "-27.4002",
    Longitude: "30.9886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11853",
    WardLookupId: "3698",
    VDNumber: "43504253",
    RegisteredPopulation: "258",
    VotingStationName: "EMMANUEL WESLEAN CHURCH",
    Address: "HARTLAND MISSION  KWADLAMINI T/C",
    Latitude: "-27.4236",
    Longitude: "31.0456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11854",
    WardLookupId: "3698",
    VDNumber: "43504950",
    RegisteredPopulation: "590",
    VotingStationName: "KHANYISELUZULU HIGH SCHOOL",
    Address:
      "NGOJE AREA -ENKANINI  NGOJE AREA - PAST MAKHOBA`S SHOP  DLAMINI TRIBAL AREA",
    Latitude: "-27.4348",
    Longitude: "30.9767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11855",
    WardLookupId: "3698",
    VDNumber: "43505243",
    RegisteredPopulation: "191",
    VotingStationName: "SICELIMPUMELELO PRIMARY SCHOOL",
    Address: "KODLOMO  KODLOMO EZINKANGALA  MTHETHWA TRIBAL AUTHORITY",
    Latitude: "-27.4599",
    Longitude: "30.9947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11856",
    WardLookupId: "3698",
    VDNumber: "43505254",
    RegisteredPopulation: "300",
    VotingStationName: "ISIKHALISETHU SCHOOL",
    Address: "OPHUZANE  MTHETHWA TRIBAL AREA  MTHETHWA TRIBAL AREA",
    Latitude: "-27.4784",
    Longitude: "31.0192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11857",
    WardLookupId: "3699",
    VDNumber: "43501801",
    RegisteredPopulation: "425",
    VotingStationName: "ENKEMBENI PRIMARY SCHOOL",
    Address:
      "ENKEMBENI  ENKEMBENI -NEXT TO SOGADUZELA HIGH  DLAMINI TRIBAL AREA",
    Latitude: "-27.3897",
    Longitude: "31.0388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11858",
    WardLookupId: "3699",
    VDNumber: "43501856",
    RegisteredPopulation: "761",
    VotingStationName: "NGEBHUZANE  HIGH SCHOOL",
    Address:
      "PHELANDABA AREA  PHELANDABA AREA - NEXT TO UZWANE RIVER  PAULPIETERSBURG",
    Latitude: "-27.4506",
    Longitude: "31.0611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11859",
    WardLookupId: "3699",
    VDNumber: "43501867",
    RegisteredPopulation: "656",
    VotingStationName: "UZWANO HIGH SCHOOL",
    Address: "HARTLAND AREA  HARTLAND AREA -EMSHALANENI  KWADLAMINI T/C",
    Latitude: "-27.4189",
    Longitude: "31.0502",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11860",
    WardLookupId: "3699",
    VDNumber: "43501878",
    RegisteredPopulation: "393",
    VotingStationName: "SIZUZULU  PRIMARY SCHOOL",
    Address:
      "KWAGWEBU AREA  KWAGWEBU  AREA - PAST NGEBHUZANE SCHOOL  MTHETHWA TRIBAL AUTHORITY",
    Latitude: "-27.478",
    Longitude: "31.094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11861",
    WardLookupId: "3699",
    VDNumber: "43501889",
    RegisteredPopulation: "255",
    VotingStationName: "LUJOJWANA PRIMARY SCHOOL",
    Address:
      "LUJOJWANA AREA  LUJOJWANA AREA -PAST EMAKHOLWENI VILLAGE  DLAMINI TRIBAL AUTHORITY",
    Latitude: "-27.4426",
    Longitude: "31.1247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11862",
    WardLookupId: "3699",
    VDNumber: "43501890",
    RegisteredPopulation: "370",
    VotingStationName: "ENTUNGWINI COMBINED PRIMARY SCHOOL",
    Address:
      "NTUNGWINI  ENTUNGWINI EERSTELING FARM -PAST DELFKOM  PAULPIETERSBUG",
    Latitude: "-27.3598",
    Longitude: "31.1221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11863",
    WardLookupId: "3699",
    VDNumber: "43502790",
    RegisteredPopulation: "323",
    VotingStationName: "LAMLELA PRIMARY SCHOOL",
    Address: "LAMLELA  BHADENI AREA  PAULPIETERSBURG",
    Latitude: "-27.4392",
    Longitude: "31.0776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11864",
    WardLookupId: "3699",
    VDNumber: "43504062",
    RegisteredPopulation: "471",
    VotingStationName: "SIKHANYISELENI COMBINED SCHOOL",
    Address: "KWALUBENJANA  LUBENJANE AREA SETTLEMENT  PAULPIETERSBURG",
    Latitude: "-27.4481",
    Longitude: "31.0315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11865",
    WardLookupId: "3699",
    VDNumber: "43504264",
    RegisteredPopulation: "159",
    VotingStationName: "JIKANE PRIMARY SCHOOL",
    Address:
      "KWAJIKANE AREA  JIKANE-PAST MSHALANENI & MPUCUKO STORE  DLAMINI TRIBAL COURT",
    Latitude: "-27.4049",
    Longitude: "31.0716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11866",
    WardLookupId: "3699",
    VDNumber: "43504275",
    RegisteredPopulation: "214",
    VotingStationName: "EMADULINI PRIMARY SCHOOL",
    Address:
      "EMADULINI AREA  EMADULINI AREA - PAST LAMLELA SCHOOL  DLAMINI TRIBAL AUTHORITY",
    Latitude: "-27.4252",
    Longitude: "31.0814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11867",
    WardLookupId: "3699",
    VDNumber: "43504770",
    RegisteredPopulation: "103",
    VotingStationName: "SIKHULAKANCANE CRECHE (TENT)",
    Address:
      "KWANYOSI AREA  KWANYOSI -GWEBU -PAST SIZUZULU SCHOOL  DLAMINI TRIBAL AREA",
    Latitude: "-27.4697",
    Longitude: "31.1182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11868",
    WardLookupId: "3699",
    VDNumber: "43504781",
    RegisteredPopulation: "90",
    VotingStationName: "QAMBOKUHLE PRIMARY SCHOOL",
    Address: "ENTENGWANE AREA  DLAMINI TRIBAL AUTHORITY",
    Latitude: "-27.3417",
    Longitude: "31.0821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11869",
    WardLookupId: "3699",
    VDNumber: "43504792",
    RegisteredPopulation: "137",
    VotingStationName: "VULAMEHLO CRECHE",
    Address: "EMPANGWINI AREA  DLAMINI TRIBAL AREA",
    Latitude: "-27.3724",
    Longitude: "31.0089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11870",
    WardLookupId: "3700",
    VDNumber: "43501553",
    RegisteredPopulation: "508",
    VotingStationName: "ZUNGWINI PRIMARY SCHOOL",
    Address:
      "ZUNGWINI AREA  ZUNGWINI NEXT TO TIMBER MINING PLANT -EZUNGWINI  SHABALALA TRIBE",
    Latitude: "-27.6321",
    Longitude: "30.8172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11871",
    WardLookupId: "3700",
    VDNumber: "43501665",
    RegisteredPopulation: "422",
    VotingStationName: "AMANZASHISAYO PRIMARY SCHOOL",
    Address:
      "AMNZASHISAYO AREA   AMANZASHISAYO - NEXT TO  NATAL SPA HOTEL  KWAMTHETHWA TRIBAL AUTHORITY",
    Latitude: "-27.5453",
    Longitude: "30.8598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11872",
    WardLookupId: "3700",
    VDNumber: "43501676",
    RegisteredPopulation: "619",
    VotingStationName: "MAHLONI HIGHER PRIMARY SCHOOL",
    Address:
      "MAHLONI AREA  MAHLONI AREA ON THE WAY TO GAMAKAZI  KWAMTHETHWA TRIBAL AUTHORITY",
    Latitude: "-27.5553",
    Longitude: "30.9794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11873",
    WardLookupId: "3700",
    VDNumber: "43501687",
    RegisteredPopulation: "195",
    VotingStationName: "KWAGAMAKAZI HIGHER PRIMARY SCHOOL",
    Address:
      "KWAGAMAKAZI AREA  KWAGAMAKAZI - ON WAY TO MKABAYI`S TOMB  KWAMTHETHWA TRIBAL AUTHORITY",
    Latitude: "-27.597",
    Longitude: "31.0198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11874",
    WardLookupId: "3700",
    VDNumber: "43501845",
    RegisteredPopulation: "378",
    VotingStationName: "OBIVANE COMBINED PRIMARY SCHOOL",
    Address:
      "BELLEMERD FARM - EMOZENI  EMOZENI - BHADENI - BELMELLED FARM  KWAMTHETHWA TRIBAL AUTHORITY",
    Latitude: "-27.4996",
    Longitude: "31.0367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11875",
    WardLookupId: "3700",
    VDNumber: "43503779",
    RegisteredPopulation: "286",
    VotingStationName: "MATSHEKAZI PRIMARY SCHOOL",
    Address:
      "EMATSHEKAZI AREA   EMATSHEKAZI -ON THE ROAD TO MAHLONI  KWAMTHETHWA TRIBAL AUTHORITY",
    Latitude: "-27.6015",
    Longitude: "30.8931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11876",
    WardLookupId: "3700",
    VDNumber: "43503836",
    RegisteredPopulation: "574",
    VotingStationName: "KHALENI PRIMARY SCHOOL",
    Address:
      "KHAMBULE BATTLE FIELD AREA  KHALENI VILLAGE - KHAMBULE AREA  BHOVUNGANE TRIBAL AUTHORITY",
    Latitude: "-27.6783",
    Longitude: "30.7226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11877",
    WardLookupId: "3700",
    VDNumber: "43504040",
    RegisteredPopulation: "232",
    VotingStationName: "PIVAAN PRIMARY SCHOOL",
    Address:
      "KWAMAKHOKHOMBA FARM  EMAHHULUMBE AREA- MAKHOKHOMBA  MAHULUMBE AREA",
    Latitude: "-27.498",
    Longitude: "30.8014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11878",
    WardLookupId: "3700",
    VDNumber: "43504376",
    RegisteredPopulation: "155",
    VotingStationName: "KHAMBULA COMBINED SCHOOL",
    Address:
      "SCHULLBERG AREA - KHAMBULA   KHAMBULA MISSION - ON THE ROAD TO UTRECHT  PAULPIETERSBURG",
    Latitude: "-27.6337",
    Longitude: "30.6846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11879",
    WardLookupId: "3700",
    VDNumber: "43504804",
    RegisteredPopulation: "272",
    VotingStationName: "VUKUZAME PRIMARY SCHOOL",
    Address:
      "ENSINGANE AREA  ENSINGANE -ACROSS OBIVANE RIVER  MTHETHWA TRIBAL AREA",
    Latitude: "-27.4986",
    Longitude: "30.9654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11880",
    WardLookupId: "3700",
    VDNumber: "43505265",
    RegisteredPopulation: "59",
    VotingStationName: "NTSHENTEKA (TENT)",
    Address: "NTSHENTEKA AREA  PAULPIETERSBURG  MTHETHWA TRIBAL AUTHORITY",
    Latitude: "-27.6483",
    Longitude: "30.9394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11881",
    WardLookupId: "3700",
    VDNumber: "43505276",
    RegisteredPopulation: "173",
    VotingStationName: "IMBALENHLE CRECHE",
    Address: "MAHLONI AREA  PAULPIETERSBURG  KWAMTHETHWA TRIBAL",
    Latitude: "-27.5392",
    Longitude: "30.9894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11882",
    WardLookupId: "3700",
    VDNumber: "43505287",
    RegisteredPopulation: "168",
    VotingStationName: "MQWABE PRIMARY SCHOOL",
    Address: "MAKHWABE VILLAGE   PAULPIETERSBURG  MTHETHWA TRIBAL AUTHORITY",
    Latitude: "-27.5828",
    Longitude: "30.8134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11883",
    WardLookupId: "3701",
    VDNumber: "43501643",
    RegisteredPopulation: "554",
    VotingStationName: "PADDAFONTEIN COMBINED PRIMARY SCHOOL",
    Address:
      "PADDAFONTEIN FARM  NGASEZIKHWELENI - ENROUTE TO BILANYONI  EMAMBATHENI TRIBAL",
    Latitude: "-27.4056",
    Longitude: "30.8884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11884",
    WardLookupId: "3701",
    VDNumber: "43501777",
    RegisteredPopulation: "522",
    VotingStationName: "ZICABANGELE PRIMARY SCHOOL",
    Address:
      "NDENDENDE VILLAGE  NDENDENDE AREA EN ROUTE TO OPHUZANE  KWAMTHETHWA TRIBAL AUTHORITY",
    Latitude: "-27.4333",
    Longitude: "30.9276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11885",
    WardLookupId: "3701",
    VDNumber: "43501788",
    RegisteredPopulation: "1052",
    VotingStationName: "MTHETHWA TRIBAL COURT",
    Address:
      "EGODENI -ESIGODLWENI  EGODENI VILLAGE - OPHUZANE AREA  KWAMTHETHWA TRIBAL AUTHORITY",
    Latitude: "-27.4689",
    Longitude: "30.9505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11886",
    WardLookupId: "3701",
    VDNumber: "43503791",
    RegisteredPopulation: "1013",
    VotingStationName: "KWAMSOCO (TENT)",
    Address:
      "KWAMSOCO AREA  KWAMSOCO NGASENGULUBENI OPHUZANE  MTHETHWA TRIBAL AUTHORITY",
    Latitude: "-27.4855",
    Longitude: "30.9443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11887",
    WardLookupId: "3701",
    VDNumber: "43503814",
    RegisteredPopulation: "273",
    VotingStationName: "BROODSPRUIT PRIMARY  SCHOOL",
    Address:
      "NHLUNGWANE AREA  NHLUNGWANE NEXT TO NHLUNGWANE STORE  KWAHLATSHWAYO TRIBAL",
    Latitude: "-27.3655",
    Longitude: "30.8693",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11888",
    WardLookupId: "3701",
    VDNumber: "43504073",
    RegisteredPopulation: "1259",
    VotingStationName: "NHLAKANIPHO PRIMARY SCHOOL",
    Address:
      "MANGOSUTHU VILLAGE  MANGOSUTHU VILLAGE - NEXT TO HADEBE`S SHOP  KWAMTHETHWA TRIBAL",
    Latitude: "-27.4138",
    Longitude: "30.9198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11889",
    WardLookupId: "3701",
    VDNumber: "43504815",
    RegisteredPopulation: "354",
    VotingStationName: "OPHUZANE PRIMARY SCHOOL",
    Address:
      "OPHUZANE   OPHUZANE -NEXT TO QEDINDLALA STORE  MTHETHWA TRIBAL AUTHORITY",
    Latitude: "-27.4772",
    Longitude: "30.9487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11890",
    WardLookupId: "3702",
    VDNumber: "43501485",
    RegisteredPopulation: "629",
    VotingStationName: "EMPAKAMA PRIMARY SCHOOL",
    Address: "EMPAKAMA AREA  MGAZINI T/AUTHORITY  PONGOLA",
    Latitude: "-27.7202",
    Longitude: "31.7139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11891",
    WardLookupId: "3702",
    VDNumber: "43502161",
    RegisteredPopulation: "880",
    VotingStationName: "DWARSLAND  PRIMARY SCHOOL",
    Address:
      "R69 TOWARDS VRYHEID ROAD 38KM  DWARSLAND AREA  KLWANA TRIBAL AUTHORITY",
    Latitude: "-27.5269",
    Longitude: "31.4115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11892",
    WardLookupId: "3702",
    VDNumber: "43502172",
    RegisteredPopulation: "155",
    VotingStationName: "MORESON FARM SCHOOL",
    Address: "MORESON  PONGOLA",
    Latitude: "-27.5134",
    Longitude: "31.4886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11893",
    WardLookupId: "3702",
    VDNumber: "43502183",
    RegisteredPopulation: "317",
    VotingStationName: "MKHAYA PRIMARY SCHOOL",
    Address: "MKHAYA  DE JAGERS FARM  PONGOLA",
    Latitude: "-27.4637",
    Longitude: "31.5947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11894",
    WardLookupId: "3702",
    VDNumber: "43502194",
    RegisteredPopulation: "298",
    VotingStationName: "MAGUDU PRIMARY SCHOOL",
    Address:
      "R69 TO VRYHEID 35KM FROM NPG   MAGUDU AREA  MGAZINI TRIBAL AUTHORITY",
    Latitude: "-27.5336",
    Longitude: "31.6488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11895",
    WardLookupId: "3702",
    VDNumber: "43502295",
    RegisteredPopulation: "366",
    VotingStationName: "PETERGATE PRIMARY SCHOOL",
    Address: "R66 TO NND 36 KM  PETERGATE AREA  MGAZINI TRIBAL AUTHORITY",
    Latitude: "-27.6694",
    Longitude: "31.7216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11896",
    WardLookupId: "3702",
    VDNumber: "43502925",
    RegisteredPopulation: "408",
    VotingStationName: "VALLEYSIDE SCHOOL",
    Address: "VALLEYSIDE  UPHONGOLO  UPHONGOLO",
    Latitude: "-27.7309",
    Longitude: "31.642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11897",
    WardLookupId: "3702",
    VDNumber: "43504017",
    RegisteredPopulation: "278",
    VotingStationName: "PHENYANE  PRIMARY SCHOOL",
    Address: "R 66, 30KM TOWARDS VRYHEID  MAGUDU  MGAZINI TRIBAL AUTHORITY",
    Latitude: "-27.5711",
    Longitude: "31.6434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11898",
    WardLookupId: "3702",
    VDNumber: "43504994",
    RegisteredPopulation: "393",
    VotingStationName: "MANZAMHLOPHE SECONDARY SCHOOL",
    Address:
      "R69 TO VHD PASS DWARSLAND 5KM   MANZAMHLOPHE AREA  MGAZINI TRIBAL AUTHORITY",
    Latitude: "-27.5726",
    Longitude: "31.5222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11899",
    WardLookupId: "3702",
    VDNumber: "43505175",
    RegisteredPopulation: "96",
    VotingStationName: "PRINCE SOMCUBA PRIMARY PRIMARY SCHOOL",
    Address:
      "R 618, NEXT TO MHLABANENI STOR  UPHONGOLO  NTSHANGASE TRIBAL AUTHORITY/MAGUDU",
    Latitude: "-27.8309",
    Longitude: "31.5152",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11900",
    WardLookupId: "3703",
    VDNumber: "43550030",
    RegisteredPopulation: "3095",
    VotingStationName: "SIMANDLANGENTSHA SCHOOL",
    Address: "SMANDLA STREET  NCOTSHANE  PONGOLA",
    Latitude: "-27.3525",
    Longitude: "31.5783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11901",
    WardLookupId: "3703",
    VDNumber: "43550085",
    RegisteredPopulation: "1065",
    VotingStationName: "DWALENI HIGH SCHOOL",
    Address:
      "UKUTHULA STREET  NCOTSHANE TOWNSHIP B-SECTION  KZN262 - UPHONGOLO [PONGOLA]",
    Latitude: "-27.3508",
    Longitude: "31.5809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11902",
    WardLookupId: "3704",
    VDNumber: "43501902",
    RegisteredPopulation: "640",
    VotingStationName: "MSIBI TRIBAL COURT",
    Address: "ORANGEDAL AREA  ORANGEDAL TRUST  PONGOLA",
    Latitude: "-27.3446",
    Longitude: "31.1867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11903",
    WardLookupId: "3704",
    VDNumber: "43501924",
    RegisteredPopulation: "343",
    VotingStationName: "VIMBEMSHINI COMBINED PRIMARY SCHOOL",
    Address: "VIMBEMSHINI AREA  PONGOLA",
    Latitude: "-27.3427",
    Longitude: "31.2186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11904",
    WardLookupId: "3704",
    VDNumber: "43501935",
    RegisteredPopulation: "660",
    VotingStationName: "SIBIYANGENKOMO HIGH SCHOOL",
    Address: "ISIGODI KHIPHUNYAWO  KHIPHUNYAWO AREA  SIBIYA TRIBAL AUTHORITY",
    Latitude: "-27.307",
    Longitude: "31.2539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11905",
    WardLookupId: "3704",
    VDNumber: "43502802",
    RegisteredPopulation: "358",
    VotingStationName: "ENKUBUNJANI PRIMARY SCHOOL",
    Address: "12KM FROM KLIPWAL ROAD  DUNGAMANZI AREA  MSIBI TRIBAL AUTHORITY",
    Latitude: "-27.3749",
    Longitude: "31.1565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11906",
    WardLookupId: "3704",
    VDNumber: "43503016",
    RegisteredPopulation: "920",
    VotingStationName: "ESINQENI PRIMARY SCHOOL",
    Address: "8KM FROM KLIPWAL ROAD  ESINQENI AREA  MSIBI TRIBAL AUTHORITY",
    Latitude: "-27.3779",
    Longitude: "31.1965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11907",
    WardLookupId: "3704",
    VDNumber: "43503656",
    RegisteredPopulation: "1075",
    VotingStationName: "KWA-SIBIYA T/COURT",
    Address: "NTUMBANE  KHIPHUNYAWO  PONGOLA",
    Latitude: "-27.3076",
    Longitude: "31.2254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11908",
    WardLookupId: "3705",
    VDNumber: "43502026",
    RegisteredPopulation: "1026",
    VotingStationName: "UBUCOBUHLE SP SCHOOL",
    Address: "2KM FROM N2  KWAMABOPHE AREA  NTSHANGASE TRIBAL AUTHORITY",
    Latitude: "-27.3151",
    Longitude: "31.3759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11909",
    WardLookupId: "3705",
    VDNumber: "43502037",
    RegisteredPopulation: "1473",
    VotingStationName: "SIYETHEMBA COMBINED PRIMARY SCHOOL",
    Address:
      "1KM FROM N2 TOWARDS TSHELEJUBA  WHITE CLIFF AREA  NTSHANGASE TRIBAL AUTHORITY",
    Latitude: "-27.2961",
    Longitude: "31.3622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11910",
    WardLookupId: "3705",
    VDNumber: "43503948",
    RegisteredPopulation: "1056",
    VotingStationName: "ZIQALELE PRIMARY SCHOOL",
    Address: "400M FROM N2  GODLWAYO AREA  NTSHANGASE TRIBA AUTHORITY",
    Latitude: "-27.3117",
    Longitude: "31.4113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11911",
    WardLookupId: "3705",
    VDNumber: "43503993",
    RegisteredPopulation: "987",
    VotingStationName: "SIYAZIQEQESHA PRIMARY SCHOOL",
    Address: "PONGOLA  WHITE CLIFF EAST  UPHONGOLO",
    Latitude: "-27.2956",
    Longitude: "31.38",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11912",
    WardLookupId: "3705",
    VDNumber: "43505030",
    RegisteredPopulation: "421",
    VotingStationName: "REV. JACOB DLAMINI CRECHE",
    Address: "MVUNYANE AREA  PONGOLA  NTSHANGASE TRIBAL AUTHORITY",
    Latitude: "-27.3178",
    Longitude: "31.4472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11913",
    WardLookupId: "3706",
    VDNumber: "43501979",
    RegisteredPopulation: "1616",
    VotingStationName: "BAMBANANI HIGH SCHOOL",
    Address: "4KM FROM N2  BELGRADE AREA  NDLANGAMANDLA TRIBAL AUTHORITY",
    Latitude: "-27.2717",
    Longitude: "31.2742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11914",
    WardLookupId: "3706",
    VDNumber: "43503645",
    RegisteredPopulation: "1516",
    VotingStationName: "BELGRADE C HALL",
    Address: "1.5 KM FROM N2  BELGRADE AREA  NDLANGAMANDLA TRIBAL AUTHORITY",
    Latitude: "-27.2671",
    Longitude: "31.2814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11915",
    WardLookupId: "3706",
    VDNumber: "43505186",
    RegisteredPopulation: "417",
    VotingStationName: "RAINBOW CRECHE",
    Address: "PONGOLA  KWAGAQA AREA  NDLANGAMANDLA TRIBAL AUTHORITY",
    Latitude: "-27.2581",
    Longitude: "31.2701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11916",
    WardLookupId: "3707",
    VDNumber: "43501913",
    RegisteredPopulation: "317",
    VotingStationName: "SOMILE HIGH SCHOOL",
    Address: "19KM FROM N2  NTUMBANE AREA  SIBIYA TRIBAL AUTHORITY",
    Latitude: "-27.3542",
    Longitude: "31.2185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11917",
    WardLookupId: "3707",
    VDNumber: "43501946",
    RegisteredPopulation: "1068",
    VotingStationName: "KLIPWAL PRIMARY SCHOOL",
    Address:
      "FROM PIET RETIEF N2 ROAD 35KM  KLIPWAL AREA  SIBIYA TRIBAL AUTHORITY",
    Latitude: "-27.418",
    Longitude: "31.2458",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11918",
    WardLookupId: "3707",
    VDNumber: "43501957",
    RegisteredPopulation: "683",
    VotingStationName: "EKOSENTSHA SCHOOL",
    Address: "KWANKUNDLA  MAVUSO T/AUTHORITY  PONGOLA",
    Latitude: "-27.398",
    Longitude: "31.2651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11919",
    WardLookupId: "3707",
    VDNumber: "43502015",
    RegisteredPopulation: "622",
    VotingStationName: "KORTNEK LOWER PRIMARY SCHOOL",
    Address: "4KM FROM N2  KORTNEK AREA  MAVUSO TRIBAL AUTHORITY",
    Latitude: "-27.3839",
    Longitude: "31.2891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11920",
    WardLookupId: "3707",
    VDNumber: "43502408",
    RegisteredPopulation: "881",
    VotingStationName: "ALTONA HIGH PRIMARY SCHOOL",
    Address: "28KM FROM N2  ALTONA AREA  MSIBI TRIBAL AUTHORITY",
    Latitude: "-27.3818",
    Longitude: "31.2181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11921",
    WardLookupId: "3707",
    VDNumber: "43503971",
    RegisteredPopulation: "383",
    VotingStationName: "EBONGASPOORT SCHOOL",
    Address: "KWANKUNDLA  MAVUSO T/AUTHORITY  PONGOLA",
    Latitude: "-27.4006",
    Longitude: "31.2283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11922",
    WardLookupId: "3707",
    VDNumber: "43503982",
    RegisteredPopulation: "612",
    VotingStationName: "MANZABOMVU PRIMARY SCHOOL",
    Address: "ISIGODI AMANZABOMVU  KWAMANZABOMVU AREA  SIBIYA TRIBAL AUTHORITY",
    Latitude: "-27.3731",
    Longitude: "31.2322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11923",
    WardLookupId: "3708",
    VDNumber: "43502071",
    RegisteredPopulation: "1070",
    VotingStationName: "SIVULE PRIMARY SCHOOL",
    Address: "3KM FROM N2  SIVULE AREA  PONGOLA",
    Latitude: "-27.2985",
    Longitude: "31.4279",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11924",
    WardLookupId: "3708",
    VDNumber: "43502082",
    RegisteredPopulation: "1197",
    VotingStationName: "NTSHANGASE TRIBAL COURT",
    Address: "MKHWAKHWENI AREA  PONGOLA",
    Latitude: "-27.336",
    Longitude: "31.4534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11925",
    WardLookupId: "3708",
    VDNumber: "43503959",
    RegisteredPopulation: "724",
    VotingStationName: "SIBONILE PRIMARY SCHOOL",
    Address: "PONGOLA  KWALUBISI ESIDAKENI AREA  UPHONGOLO",
    Latitude: "-27.3213",
    Longitude: "31.4666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11926",
    WardLookupId: "3708",
    VDNumber: "43505007",
    RegisteredPopulation: "887",
    VotingStationName: "SITHAMBI COMMUNITY HALL",
    Address: "KWALUBISI  PONGOLA  NTSHANGASE TRIBAL AUTHORITY",
    Latitude: "-27.3321",
    Longitude: "31.4604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11927",
    WardLookupId: "3709",
    VDNumber: "43502059",
    RegisteredPopulation: "1239",
    VotingStationName: "MSHOKOBEZI PRIMARY SCHOOL",
    Address: "PONGOLA  GODLWAYO AREA  UPHONGOLO",
    Latitude: "-27.3217",
    Longitude: "31.4097",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11928",
    WardLookupId: "3709",
    VDNumber: "43502060",
    RegisteredPopulation: "517",
    VotingStationName: "PHONDWANE PRIMARY SCHOOL",
    Address: "APPIESHOEK TRUST  PONGOLA",
    Latitude: "-27.3707",
    Longitude: "31.4051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11929",
    WardLookupId: "3709",
    VDNumber: "43502093",
    RegisteredPopulation: "339",
    VotingStationName: "SIZAKALA PRIMARY SCHOOL",
    Address: "MZINSANGU AREA  PONGOLA",
    Latitude: "-27.3624",
    Longitude: "31.4326",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11930",
    WardLookupId: "3709",
    VDNumber: "43503038",
    RegisteredPopulation: "221",
    VotingStationName: "MAFELA PRIMARY SCHOOL",
    Address: "N/A  MAFELA AREA  UPHONGOLO",
    Latitude: "-27.3628",
    Longitude: "31.3715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11931",
    WardLookupId: "3709",
    VDNumber: "43503960",
    RegisteredPopulation: "331",
    VotingStationName: "SIZAKAHLE PRIMARY SCHOOL",
    Address: "PONGOLA  KWABHEMBE AREA  PONGOLA",
    Latitude: "-27.3419",
    Longitude: "31.4554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11932",
    WardLookupId: "3709",
    VDNumber: "43504286",
    RegisteredPopulation: "502",
    VotingStationName: "CHILDREN OF GOD CHURCH",
    Address: "OFF N2 HGHWAY  UPHONGOLO  NTSHANGASE TRIBAL AUTHORITY",
    Latitude: "-27.3423",
    Longitude: "31.4728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11933",
    WardLookupId: "3709",
    VDNumber: "43504297",
    RegisteredPopulation: "448",
    VotingStationName: "IKUSASALENTSHA EVENTS MANAGEMENT COORPERATIVE",
    Address: "KWABHEMBE AREA  NTSHANGASE T/AUTHORITY  PONGOLA",
    Latitude: "-27.3424",
    Longitude: "31.4609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11934",
    WardLookupId: "3709",
    VDNumber: "43505197",
    RegisteredPopulation: "313",
    VotingStationName: "GODLWAYO SERVICE CENTRE",
    Address: "PONGOLA  GODLWAYO AREA  NTSHANGASE TRIBAL AUTHORITY",
    Latitude: "-27.3115",
    Longitude: "31.4054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11935",
    WardLookupId: "3710",
    VDNumber: "43502206",
    RegisteredPopulation: "1517",
    VotingStationName: "MBHEKWA HIGH SCHOOL",
    Address: "1KM FROM N2  EMADANYINI AREA  SIMELANE TRIBAL AUTHORITY",
    Latitude: "-27.3785",
    Longitude: "31.5442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11936",
    WardLookupId: "3710",
    VDNumber: "43502824",
    RegisteredPopulation: "1049",
    VotingStationName: "QOPHUMLANDO CRECHE",
    Address: "3KM FROM MAIN ROAD N2  MAGENGENI AREA  PONGOLO",
    Latitude: "-27.3692",
    Longitude: "31.5518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11937",
    WardLookupId: "3710",
    VDNumber: "43503678",
    RegisteredPopulation: "1223",
    VotingStationName: "OZWENI PRIMARY SCHOOL",
    Address: "N2 13KM FROM PONGOLA   MADANYINI AREA  SIMELANE TRIBAL AUTHORITY",
    Latitude: "-27.3706",
    Longitude: "31.5282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11938",
    WardLookupId: "3710",
    VDNumber: "43503937",
    RegisteredPopulation: "1299",
    VotingStationName: "ESIKHANDWENI CRECHE",
    Address: "MADANYINI AREA  PONGOLOA",
    Latitude: "-27.3852",
    Longitude: "31.5652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11939",
    WardLookupId: "3711",
    VDNumber: "43503049",
    RegisteredPopulation: "680",
    VotingStationName: "MBOLOBA PRIMARY SCHOOL",
    Address:
      "17KM PASS SECTION A FROM NCOTS  MBOLOBA AREA  NTSHANGASE TRIBAL AUTHORITY",
    Latitude: "-27.3161",
    Longitude: "31.5629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11940",
    WardLookupId: "3711",
    VDNumber: "43550018",
    RegisteredPopulation: "1861",
    VotingStationName: "OPHONGOLO HIGH SCHOOL",
    Address: "NCOTSHANE ROAD  A-NCOTSHANE TOWNSHIP  PONGOLA TOWN",
    Latitude: "-27.3392",
    Longitude: "31.5829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11941",
    WardLookupId: "3711",
    VDNumber: "43550063",
    RegisteredPopulation: "672",
    VotingStationName: "NSUMPA OFFICE",
    Address: "MPANGAZITHA STREET  B-NCOTSHANE TOWNSHIP  UPHONGOLO",
    Latitude: "-27.3482",
    Longitude: "31.5792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11942",
    WardLookupId: "3711",
    VDNumber: "43550074",
    RegisteredPopulation: "1092",
    VotingStationName: "NCOTSHANE COMMUNITY HALL",
    Address: "MAIN STREET NEXT SPORTFIELD  NCOTSHANE B  PONGOLA",
    Latitude: "-27.3483",
    Longitude: "31.59",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11943",
    WardLookupId: "3711",
    VDNumber: "43550096",
    RegisteredPopulation: "418",
    VotingStationName: "SITHWELEKANZIMA CRE`CHE",
    Address: "ESIGUNGWINI AREA  PONGOLA  NTSHANGASE TRIBAL AUTHORITY",
    Latitude: "-27.3758",
    Longitude: "31.5837",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11944",
    WardLookupId: "3712",
    VDNumber: "43502228",
    RegisteredPopulation: "508",
    VotingStationName: "SHIYANGIBONE PRIMARY SCHOOL",
    Address: "SWARTKLOOF TRUST  PONGOLA",
    Latitude: "-27.4025",
    Longitude: "31.5668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11945",
    WardLookupId: "3712",
    VDNumber: "43502240",
    RegisteredPopulation: "419",
    VotingStationName: "JABULISANI PRIMARY SCHOOL",
    Address: "ONSVEWARG   PLOT 15  UPHONGOLO",
    Latitude: "-27.3467",
    Longitude: "31.6214",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11946",
    WardLookupId: "3712",
    VDNumber: "43502677",
    RegisteredPopulation: "1210",
    VotingStationName: "PONGOLA INTERMEDIATE SCHOOL",
    Address: "N/A  ESIGUNGWINI AREA  UPHONGOLO",
    Latitude: "-27.3907",
    Longitude: "31.5727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11947",
    WardLookupId: "3712",
    VDNumber: "43503050",
    RegisteredPopulation: "666",
    VotingStationName: "UMGINAMA INTER SCHOOL",
    Address: "MNGINAMA AREA  PONGOLA  UMGINAMA",
    Latitude: "-27.4239",
    Longitude: "31.6058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11948",
    WardLookupId: "3712",
    VDNumber: "43550041",
    RegisteredPopulation: "1340",
    VotingStationName: "MUNICIPAL OFFICES",
    Address: "MARTINSSTRAAT 61  PONGOLA TOWN  PONGOLA",
    Latitude: "-27.3776",
    Longitude: "31.617",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11949",
    WardLookupId: "3713",
    VDNumber: "43501980",
    RegisteredPopulation: "2114",
    VotingStationName: "SIGQAMISE HIGH SCHOOL",
    Address: "ITSHELEJUBA  ITSHELEJUBA AREA  PONGOLA",
    Latitude: "-27.2713",
    Longitude: "31.3442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11950",
    WardLookupId: "3713",
    VDNumber: "43501991",
    RegisteredPopulation: "755",
    VotingStationName: "MANYANDENI SCHOOL",
    Address: "HIGHLANDS AREA  PONGOLA",
    Latitude: "-27.2941",
    Longitude: "31.3059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11951",
    WardLookupId: "3713",
    VDNumber: "43502004",
    RegisteredPopulation: "616",
    VotingStationName: "MKHUNJINI COMBINED PRIMARY SCHOOL",
    Address:
      "N/A 34KM FROM BELGRADE   MANYANDENI AREA  NDLANGAMANDLA TRIBAL AUTHORITY",
    Latitude: "-27.3252",
    Longitude: "31.2937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11952",
    WardLookupId: "3713",
    VDNumber: "43502048",
    RegisteredPopulation: "352",
    VotingStationName: "NYAWOSHANE SCHOOL",
    Address: "DAHAWOLEE  PONGOLA",
    Latitude: "-27.3316",
    Longitude: "31.34",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11953",
    WardLookupId: "3713",
    VDNumber: "43505209",
    RegisteredPopulation: "87",
    VotingStationName: "MABONJANE (TENT)",
    Address:
      "KHIPHUNYAWO ROUTE, MABONJANE A  UPHONGOLO  NDLANGAMANDLA TRIBAL AUTHORITY",
    Latitude: "-27.2836",
    Longitude: "31.2698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11954",
    WardLookupId: "3714",
    VDNumber: "43502138",
    RegisteredPopulation: "1354",
    VotingStationName: "DUMENKUNGWINI PRIMARY SCHOOL",
    Address: "PONGOLA  MKHWAKHWENI  NTSHANGASE",
    Latitude: "-27.3352",
    Longitude: "31.5161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11955",
    WardLookupId: "3714",
    VDNumber: "43502150",
    RegisteredPopulation: "1225",
    VotingStationName: "MASITHOKOZE SCHOOL",
    Address: "MAGENGENI  MAGENGENI AREA  PONGOLA",
    Latitude: "-27.3524",
    Longitude: "31.5435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11956",
    WardLookupId: "3714",
    VDNumber: "43503926",
    RegisteredPopulation: "1289",
    VotingStationName: "SIMUNYE PRIMARY SCHOOL",
    Address:
      "LEFT SIDE OF NCOTSHANE ROAD  EMSUZWANENI AREA  NTSHANGASE TRIBAL AUTHORITY",
    Latitude: "-27.3464",
    Longitude: "31.56",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11957",
    WardLookupId: "3714",
    VDNumber: "43505210",
    RegisteredPopulation: "201",
    VotingStationName: "SIYATHUTHUKA PRIMARY SCHOOL",
    Address: "PONGOLA  KWAMLOGO AREA  NTSHANGASE TRIBAL AUTHORITY",
    Latitude: "-27.3348",
    Longitude: "31.4891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11958",
    WardLookupId: "3715",
    VDNumber: "43502262",
    RegisteredPopulation: "611",
    VotingStationName: "SELINDE SCHOOL",
    Address: "MAGENGENI AREA  PONGOLA",
    Latitude: "-27.3364",
    Longitude: "31.7004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11959",
    WardLookupId: "3715",
    VDNumber: "43502273",
    RegisteredPopulation: "512",
    VotingStationName: "GOLELA PRIMARY SCHOOL",
    Address: "GOLELA  PONGOLA",
    Latitude: "-27.3217",
    Longitude: "31.8925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11960",
    WardLookupId: "3715",
    VDNumber: "43502284",
    RegisteredPopulation: "1120",
    VotingStationName: "MYEKENI SECONDARY SCHOOL",
    Address: "21KM N2 TOWARDS MKHUZE   CANDOVER AREA  GUMBI TRIBAL AUTHORITY",
    Latitude: "-27.4617",
    Longitude: "31.9186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11961",
    WardLookupId: "3715",
    VDNumber: "43502307",
    RegisteredPopulation: "458",
    VotingStationName: "MAKOTI PRIMARY SCHOOL",
    Address: "22KM FROM MAGUDU TURNOFF  MAKOTI AREA  MGAZINI TRIBAL AUTHORITY",
    Latitude: "-27.5551",
    Longitude: "31.7785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11962",
    WardLookupId: "3715",
    VDNumber: "43502699",
    RegisteredPopulation: "328",
    VotingStationName: "LELIESHOEK PRIMARY SCHOOL",
    Address:
      "17KM FROM NONGOMA ROAD  LELIESHOEK FARM  MGAZINI TRIBAL AUTHORITY",
    Latitude: "-27.4222",
    Longitude: "31.7178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11963",
    WardLookupId: "3715",
    VDNumber: "43504028",
    RegisteredPopulation: "209",
    VotingStationName: "COTTLANDS INTERMEDIATE SCHOOL",
    Address: "26KM FROM MAGUDU ROAD  COTLANDS AREA  GUMBI TRIBAL AUTHORITY",
    Latitude: "-27.5322",
    Longitude: "31.834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11964",
    WardLookupId: "3715",
    VDNumber: "43550052",
    RegisteredPopulation: "739",
    VotingStationName: "THOKOZA CLUB",
    Address: "3KM FROM N2 EAST OF PONGOLA   THOKOZA AREA  SUGAR MILL VILLAGE",
    Latitude: "-27.3952",
    Longitude: "31.6526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11965",
    WardLookupId: "3716",
    VDNumber: "43502105",
    RegisteredPopulation: "478",
    VotingStationName: "SONDABA PRIMARY SCHOOL",
    Address:
      "31KM EAST OF SIMELANE T/C  KWASHOBA AREA  SIMELANE TRIBAL AUTHORITY",
    Latitude: "-27.3875",
    Longitude: "31.5004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11966",
    WardLookupId: "3716",
    VDNumber: "43502116",
    RegisteredPopulation: "569",
    VotingStationName: "GQAMNJENGELANGA PRIMARY SCHOOL",
    Address: "28KM FROM N2 ROAD  KWASHOBA AREA  SIMELANE TRIBAL AUTHORITY",
    Latitude: "-27.3779",
    Longitude: "31.473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11967",
    WardLookupId: "3716",
    VDNumber: "43502217",
    RegisteredPopulation: "964",
    VotingStationName: "SIMELANE TRIBAL COURT",
    Address:
      "NEXT TO KWASHOBA C. HALL  KWASHOBA TRUST  SIMELANE TRIBAL AUTHORITY",
    Latitude: "-27.3648",
    Longitude: "31.4866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11968",
    WardLookupId: "3716",
    VDNumber: "43503027",
    RegisteredPopulation: "462",
    VotingStationName: "SONDELA CRECHE",
    Address: "KWASHOBA AREA  PONGOLA  NTSHANGASE TRIBAL AUTHORITY",
    Latitude: "-27.3543",
    Longitude: "31.4818",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11969",
    WardLookupId: "3716",
    VDNumber: "43503667",
    RegisteredPopulation: "775",
    VotingStationName: "UGU PRIMARY SCHOOL",
    Address:
      "N2 ROAD TOWARDS PIETRETIEF 15K  DECKVILLE AREA  NTSHANGASE TRIBAL AUTHORITY",
    Latitude: "-27.3591",
    Longitude: "31.514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11970",
    WardLookupId: "3717",
    VDNumber: "43500181",
    RegisteredPopulation: "913",
    VotingStationName: "KHANYISA SECONDARY SCHOOL",
    Address: "R66 FROM VRYHEID TO LOUWSBURG  KWAXAMU AREA  LOUWSBURG",
    Latitude: "-27.6556",
    Longitude: "31.1905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11971",
    WardLookupId: "3717",
    VDNumber: "43501698",
    RegisteredPopulation: "849",
    VotingStationName: "INHLOSENHLE SECONDARY SCHOOL",
    Address: "D32 ROAD   FUDUKA AREA  CORONATION",
    Latitude: "-27.6012",
    Longitude: "31.09",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11972",
    WardLookupId: "3717",
    VDNumber: "43501700",
    RegisteredPopulation: "290",
    VotingStationName: "KWA BANAKILE COMBINED SCHOOL",
    Address: "KWABANAKILE AREA  PONGOLA",
    Latitude: "-27.4894",
    Longitude: "31.1653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11973",
    WardLookupId: "3717",
    VDNumber: "43502510",
    RegisteredPopulation: "222",
    VotingStationName: "KWATHEKWANE  PRIMARY SCHOOL",
    Address: "D32 NS  DWALHOEK FARM  CORONATION",
    Latitude: "-27.5486",
    Longitude: "31.0818",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11974",
    WardLookupId: "3717",
    VDNumber: "43503296",
    RegisteredPopulation: "505",
    VotingStationName: "ENHLANGWENI COMBINED SCHOOL",
    Address: "MOOIKLIP  LOUWSBURG/VRYHEID",
    Latitude: "-27.6251",
    Longitude: "31.2345",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11975",
    WardLookupId: "3717",
    VDNumber: "43510014",
    RegisteredPopulation: "2029",
    VotingStationName: "NGOJE SECONDARY SCHOOL",
    Address: "LOUWSBURG  KHAMBI TRIBAL AUTHORITY",
    Latitude: "-27.5778",
    Longitude: "31.2912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11976",
    WardLookupId: "3718",
    VDNumber: "43501441",
    RegisteredPopulation: "686",
    VotingStationName: "ESIHLENGENI COMBINED SCHOOL",
    Address: "R618 NONGOMA ROAD  ESIHLENGENI AREA  ABAQULUSI",
    Latitude: "-27.8272",
    Longitude: "31.3075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11977",
    WardLookupId: "3718",
    VDNumber: "43501520",
    RegisteredPopulation: "677",
    VotingStationName: "DLOMODLOMO PRIMARY SCHOOL",
    Address:
      "L2339 NGENETSHENI RESERVE-NONGOMA RO  MORGAN ZONE FARM  LOUWSBURG",
    Latitude: "-27.7102",
    Longitude: "31.4969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11978",
    WardLookupId: "3718",
    VDNumber: "43501564",
    RegisteredPopulation: "503",
    VotingStationName: "KWAMADAMU COMBINED SCHOOL",
    Address: "MAKWELA ROAD  LOUWSBUG  LOUWSBURG",
    Latitude: "-27.631",
    Longitude: "31.3153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11979",
    WardLookupId: "3718",
    VDNumber: "43501575",
    RegisteredPopulation: "588",
    VotingStationName: "MAKWELA COMBINED SCHOOL",
    Address: "D579 MAKWELA ROAD  MTHEBENI AREA P/S  LOUWSBURG",
    Latitude: "-27.6862",
    Longitude: "31.3491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11980",
    WardLookupId: "3718",
    VDNumber: "43502330",
    RegisteredPopulation: "354",
    VotingStationName: "MAYIME PRIMARY SCHOOL",
    Address: "NONGOMA ROAD  NGOME  VRYHEID",
    Latitude: "-27.8546",
    Longitude: "31.4535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11981",
    WardLookupId: "3718",
    VDNumber: "43503308",
    RegisteredPopulation: "217",
    VotingStationName: "KWASITHOLE PRIMARY SCHOOL",
    Address: "R69 KWASITHOLE AREA  THONGWANE RESERVE  LOUWSBURG",
    Latitude: "-27.5804",
    Longitude: "31.3627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11982",
    WardLookupId: "3718",
    VDNumber: "43503319",
    RegisteredPopulation: "615",
    VotingStationName: "MATHUNJWA SECONDARY SCHOOL",
    Address:
      "D308 MOUNTAIN VIEW  NEXT TO MOUNTAIN VIEW HOSPITAL(KWANGWELU)  VRYHEID",
    Latitude: "-27.782",
    Longitude: "31.4258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11983",
    WardLookupId: "3718",
    VDNumber: "43503555",
    RegisteredPopulation: "673",
    VotingStationName: "FORTUIN PRIMARY SCHOOL",
    Address: "NND/LOUWSBURG ROAD  EMTHEBENI  ABAQULUSI",
    Latitude: "-27.6224",
    Longitude: "31.3988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11984",
    WardLookupId: "3718",
    VDNumber: "43505018",
    RegisteredPopulation: "178",
    VotingStationName: "DUNDULUZI PRIMARY SCHOOL",
    Address: "KWAPELEPELE AREA  DLOMODLOMO  VRYHEID",
    Latitude: "-27.7998",
    Longitude: "31.4974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11985",
    WardLookupId: "3719",
    VDNumber: "43501463",
    RegisteredPopulation: "1127",
    VotingStationName: "WELKOM WINKEL- KWANGADI ESTOLO",
    Address: "R618 NONGOMA ROAD  KHAMBI  VRYHEID",
    Latitude: "-27.7717",
    Longitude: "31.2074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11986",
    WardLookupId: "3719",
    VDNumber: "43501812",
    RegisteredPopulation: "861",
    VotingStationName: "MFEMFE COMBINED SCHOOL",
    Address: "KHAMBI TRIBAL AUTHORITY  LOUWSBURG DISTRICT  VRYHEID",
    Latitude: "-27.7672",
    Longitude: "31.3544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11987",
    WardLookupId: "3719",
    VDNumber: "43502127",
    RegisteredPopulation: "949",
    VotingStationName: "SIVULINDLELA  COMBINED SCHOOL",
    Address:
      "FROM R618 TURN TO D442  FROM D442 TO LISBON CRECHE TURN RIGHT  VRYHEID",
    Latitude: "-27.7617",
    Longitude: "31.2953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11988",
    WardLookupId: "3719",
    VDNumber: "43502543",
    RegisteredPopulation: "387",
    VotingStationName: "KHAMBI TRIBAL COURT OFFICES",
    Address:
      "D94 KWANGENETSHENI  FROM R618 TURN TO D94 RD NEXT TO KHAMBI CLINIC  NGOTSHE",
    Latitude: "-27.7799",
    Longitude: "31.3131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11989",
    WardLookupId: "3719",
    VDNumber: "43503320",
    RegisteredPopulation: "426",
    VotingStationName: "KWANDLANDLA CRECHE",
    Address:
      "NGENETSHENI AREA  KWANDLANDLA AREA NEXT TO SUKUMA TUCK SHOP  VRYHEID",
    Latitude: "-27.7375",
    Longitude: "31.2802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11990",
    WardLookupId: "3719",
    VDNumber: "43504859",
    RegisteredPopulation: "680",
    VotingStationName: "IMPUMELELO YETHU PRIMARY SCHOOL",
    Address: "NGENETSHENI  EAST MINE  KHAMBI TRIBAL AUTHORITY",
    Latitude: "-27.7589",
    Longitude: "31.1835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11991",
    WardLookupId: "3719",
    VDNumber: "43505029",
    RegisteredPopulation: "258",
    VotingStationName: "MBHUDULA FARM",
    Address: "MBHUDULA  KHAMBI AREA  KHAMBI TRIBAL",
    Latitude: "-27.7143",
    Longitude: "31.3573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11992",
    WardLookupId: "3720",
    VDNumber: "43501317",
    RegisteredPopulation: "444",
    VotingStationName: "SIKHULILE COMBINED SCHOOL",
    Address: "BETHEL MISSION GLUCKSTATDT  SWARTFOLOZI  GLUCKSTADT",
    Latitude: "-27.9823",
    Longitude: "31.0714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11993",
    WardLookupId: "3720",
    VDNumber: "43501328",
    RegisteredPopulation: "786",
    VotingStationName: "GLUCKSTADT COMMUNITY HALL",
    Address: "ABAQULUSI [VRYHEID]  ",
    Latitude: "-27.9734",
    Longitude: "31.0395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11994",
    WardLookupId: "3720",
    VDNumber: "43501373",
    RegisteredPopulation: "561",
    VotingStationName: "IQHAWELESIZWE COMBINED PRIMARY SCHOOL",
    Address: "EKUHLENGENI MISSION OZWATHI  SWARTFOLOZI  GLUCKSTADT",
    Latitude: "-27.9343",
    Longitude: "31.2131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11995",
    WardLookupId: "3720",
    VDNumber: "43502891",
    RegisteredPopulation: "340",
    VotingStationName: "MPEMBENI PRIMARY SCHOOL",
    Address: "MPEMBENI  SWART UMFOLOZI  GLUCKSTADT",
    Latitude: "-27.8685",
    Longitude: "31.2596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11996",
    WardLookupId: "3720",
    VDNumber: "43502936",
    RegisteredPopulation: "409",
    VotingStationName: "SOKOYI PRIMARY SCHOOL",
    Address: "EL TORRO ESTATES  GLUCKSTAD DISTRICT  VRYHEID",
    Latitude: "-27.9961",
    Longitude: "31.2512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11997",
    WardLookupId: "3720",
    VDNumber: "43503331",
    RegisteredPopulation: "1234",
    VotingStationName: "SWART MFOLOZI COMMUNITY HALL",
    Address:
      "SWART MFOLOZI  NEXT TO EMPANGISWENI TRIBAL AUTHORITY  EMPANGISWENI TA",
    Latitude: "-27.9258",
    Longitude: "31.2159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11998",
    WardLookupId: "3720",
    VDNumber: "43504826",
    RegisteredPopulation: "604",
    VotingStationName: "SIYAPHAMBILI COMBINED SCHOOL",
    Address: "SWART MFOLOZI  EMPANGISWENI TRIBAL AUTHORITY",
    Latitude: "-27.9704",
    Longitude: "31.2904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "11999",
    WardLookupId: "3721",
    VDNumber: "43501384",
    RegisteredPopulation: "954",
    VotingStationName: "ENQABENI COMBINED SCHOOL",
    Address: "CEZA ROAD  UIKKMOS FARM  ABAQULUSI [VRYHEID]",
    Latitude: "-27.8784",
    Longitude: "31.1093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12000",
    WardLookupId: "3721",
    VDNumber: "43501429",
    RegisteredPopulation: "660",
    VotingStationName: "TRIANGLE STORE (TENT)",
    Address: "VRYHIED  R69 ROAD +- 10 KM FROM VRYHEID TOWN  VRYHEID",
    Latitude: "-27.7767",
    Longitude: "30.8847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12001",
    WardLookupId: "3721",
    VDNumber: "43501430",
    RegisteredPopulation: "1462",
    VotingStationName: "ENYATHI COMBINED SCHOOL",
    Address: "KWAMNYATHI AREA  ENYATHI MINE HOUSING SETTLEMENT  VRYHEID",
    Latitude: "-27.8273",
    Longitude: "31.0613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12002",
    WardLookupId: "3721",
    VDNumber: "43503342",
    RegisteredPopulation: "1127",
    VotingStationName: "MBILANA PRIMARY SCHOOL",
    Address: "VHD / ENYATHI ROAD  NO:150 RIETVLEI FARM  HLOBANE",
    Latitude: "-27.7562",
    Longitude: "31.0389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12003",
    WardLookupId: "3721",
    VDNumber: "43504141",
    RegisteredPopulation: "411",
    VotingStationName: "NTUTHWANE CRECHE",
    Address:
      "KZN263 - ABAQULUSI [VRYHEID]  KWAMGOBHOZI AREA  KZN263 - ABAQULUSI [VRYHEID]",
    Latitude: "-27.8149",
    Longitude: "31.1315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12004",
    WardLookupId: "3721",
    VDNumber: "43504837",
    RegisteredPopulation: "400",
    VotingStationName: "ZAMA CRECHE",
    Address: "KWAMNYATHI  BOSCHOEK  MSIYANE",
    Latitude: "-27.8104",
    Longitude: "31.1024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12005",
    WardLookupId: "3721",
    VDNumber: "43505041",
    RegisteredPopulation: "373",
    VotingStationName: "SONDOZI STORE",
    Address: "EMALAYININI  MDLALOSE",
    Latitude: "-27.7666",
    Longitude: "30.9428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12006",
    WardLookupId: "3722",
    VDNumber: "43501496",
    RegisteredPopulation: "574",
    VotingStationName: "SAKHIKUSASA PRIMARY SCHOOL",
    Address: "KWAVILAKAZI AREA  KWA VILAKAZI  VRYHEID",
    Latitude: "-27.7275",
    Longitude: "31.059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12007",
    WardLookupId: "3722",
    VDNumber: "43501508",
    RegisteredPopulation: "1411",
    VotingStationName: "NKONGOLWANA PRIMARY SCHOOL",
    Address: "HLOBANE  SITE  NO:150 NKONGOLWANE  KHAMBI TRIBAL AUTHORITY",
    Latitude: "-27.6879",
    Longitude: "31.0388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12008",
    WardLookupId: "3722",
    VDNumber: "43501519",
    RegisteredPopulation: "556",
    VotingStationName: "INKANYEZI YESIZWE COMBINED SCHOOL",
    Address: "R618 NONGOMA ROAD  ALPHA AREA NEXT TO MKHAMBA STORE  LOUWSBURG",
    Latitude: "-27.7496",
    Longitude: "31.1518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12009",
    WardLookupId: "3722",
    VDNumber: "43502341",
    RegisteredPopulation: "1578",
    VotingStationName: "KWANOTSHELWA SECONDARY SCHOOL",
    Address: "01 MAIN ROAD  ZAMOKUHLE VILLAGE  CORONATION",
    Latitude: "-27.6749",
    Longitude: "31.0627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12010",
    WardLookupId: "3722",
    VDNumber: "43502521",
    RegisteredPopulation: "366",
    VotingStationName: "MANDLAKAYISE COMBINED SCHOOL",
    Address: "BAQULUSINI AREA  VRYHEID",
    Latitude: "-27.6247",
    Longitude: "31.0779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12011",
    WardLookupId: "3722",
    VDNumber: "43503522",
    RegisteredPopulation: "455",
    VotingStationName: "WATERVAL STORE ( KWAMZWEZWE-TENT)",
    Address:
      "LOUWSBURG ROAD  LOUWSBURG   LOUWSBURG JUST AFTER CORONATION BRIDGE",
    Latitude: "-27.6368",
    Longitude: "31.1005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12012",
    WardLookupId: "3722",
    VDNumber: "43504860",
    RegisteredPopulation: "224",
    VotingStationName: "NTUTHUKO PRIMARY SCHOOL",
    Address: "KWAMCIYO AREA  HLOBANE  KHAMBI TRIBAL AUTHORITY",
    Latitude: "-27.7051",
    Longitude: "31.1071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12013",
    WardLookupId: "3722",
    VDNumber: "43505052",
    RegisteredPopulation: "307",
    VotingStationName: "REFORM ZION CHURCH",
    Address: "KWASTANELLA  VAALBANK  VRYHEID",
    Latitude: "-27.7165",
    Longitude: "31.0323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12014",
    WardLookupId: "3723",
    VDNumber: "43502149",
    RegisteredPopulation: "979",
    VotingStationName: "KWATHANDOKWAKHE INTERMEDIATE SCHOOL",
    Address: "D 260 SHOBA AREA  WELGEVONDEN FARM  VRYHEID",
    Latitude: "-27.7399",
    Longitude: "30.9201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12015",
    WardLookupId: "3723",
    VDNumber: "43502318",
    RegisteredPopulation: "629",
    VotingStationName: "BENICA PRIMARY SCHOOL",
    Address: "NATAL SPA RD, ZUNGWINI AREA  BENICA MISSION  VRYHEID",
    Latitude: "-27.7181",
    Longitude: "30.8725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12016",
    WardLookupId: "3723",
    VDNumber: "43503566",
    RegisteredPopulation: "354",
    VotingStationName: "EMSENI SCHOOL",
    Address: "VHD/PAULPIETERSBURG  NTSHENTEKA  ABAQULUSI",
    Latitude: "-27.6646",
    Longitude: "30.9319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12017",
    WardLookupId: "3723",
    VDNumber: "43570054",
    RegisteredPopulation: "1329",
    VotingStationName: "THUTHUKANI COMMUNITY HALL",
    Address: "THUTHUKANI VILLAGE  VAALBANK  HLOBANE",
    Latitude: "-27.716",
    Longitude: "31.0043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12018",
    WardLookupId: "3723",
    VDNumber: "43570166",
    RegisteredPopulation: "1782",
    VotingStationName: "SHALOM PRIMARY SCHOOL",
    Address:
      "MAIN STR, CLIFFDALE ,HLOBANE  KZN263 - ABAQULUSI [VRYHEID]  KZN263 - ABAQULUSI [VRYHEID]",
    Latitude: "-27.694",
    Longitude: "31.0237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12019",
    WardLookupId: "3724",
    VDNumber: "43502903",
    RegisteredPopulation: "489",
    VotingStationName: "RF GEVERS SAWMILL HALL-KWAMBANJANA",
    Address: "PAUL PIETERSBURG ROAD  RF GEVERS FARM  VRYHEID",
    Latitude: "-27.7318",
    Longitude: "30.725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12020",
    WardLookupId: "3724",
    VDNumber: "43504905",
    RegisteredPopulation: "312",
    VotingStationName: "ENDUMUKO COMBINED SCHOOL",
    Address:
      "R33 TURN RIGHT TO LANCASTER HILLS DRIVE TO HELLBERG FARM  MTHETHWA TRIBAL AUTHORITY",
    Latitude: "-27.7023",
    Longitude: "30.7616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12021",
    WardLookupId: "3724",
    VDNumber: "43570021",
    RegisteredPopulation: "1134",
    VotingStationName: "VRYHEID REISISDUIF KLUB",
    Address: "CECIL EMMET AREA  VRYHEID  ABAQULUSI",
    Latitude: "-27.7604",
    Longitude: "30.8026",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12022",
    WardLookupId: "3724",
    VDNumber: "43570144",
    RegisteredPopulation: "1847",
    VotingStationName: "LIBRARY HALL",
    Address: "CNR MARK AND HIGH STREET  VRYHEID  VRYHEID",
    Latitude: "-27.769",
    Longitude: "30.794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12023",
    WardLookupId: "3724",
    VDNumber: "43570212",
    RegisteredPopulation: "1355",
    VotingStationName: "ESIKHAME CRECHE",
    Address:
      "SASKO AREA  JAN MOOLMAN STR INTO NEW HOUSING SETTLEMENT  EXTENTION 16",
    Latitude: "-27.7641",
    Longitude: "30.8243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12024",
    WardLookupId: "3725",
    VDNumber: "43501418",
    RegisteredPopulation: "473",
    VotingStationName: "STILLWATER MOTEL TEMPORARY STATION",
    Address:
      "STILLWATER FARM  ON R34 DUNDEE RD TO INSIDE BP FILLING STATION  VRYHEID",
    Latitude: "-27.792",
    Longitude: "30.7412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12025",
    WardLookupId: "3725",
    VDNumber: "43570032",
    RegisteredPopulation: "1606",
    VotingStationName: "NUWE REPUBLIEK SCHOOL SPORTS FIELDS HALL",
    Address: "CNR EMMETT AND BOEREN STREET  VRYHEID  VRYHEID",
    Latitude: "-27.7756",
    Longitude: "30.7812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12026",
    WardLookupId: "3725",
    VDNumber: "43570155",
    RegisteredPopulation: "1473",
    VotingStationName: "PARK GROUNDS (TENT) NEXT TO DOE REGIONAL OFFICE",
    Address:
      "CNR WEST AND SOUTH STREET  VRYHEID NEXT TO DEPT OF EDUCATION  ABAQULUSI MUNICIPALITY",
    Latitude: "-27.7797",
    Longitude: "30.7861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12027",
    WardLookupId: "3725",
    VDNumber: "43570188",
    RegisteredPopulation: "652",
    VotingStationName: "VACANT LAND EDWARD STREET (TENT)",
    Address:
      "CNR CHURCH AND EDWARD STREET  CNR CHURCH AND WEST STREET,ON R34 TO DUNDEE",
    Latitude: "-27.7747",
    Longitude: "30.7766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12028",
    WardLookupId: "3726",
    VDNumber: "43570133",
    RegisteredPopulation: "1774",
    VotingStationName: "KING ZWELENTINI HALL",
    Address: "8TH AVENUE  CNR OF GAMBUSHE STR, OLD LOCATIO,.BHEKUZULU  VRYHEID",
    Latitude: "-27.777",
    Longitude: "30.8221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12029",
    WardLookupId: "3726",
    VDNumber: "43570199",
    RegisteredPopulation: "600",
    VotingStationName: "FILIDI SECONDARY SCHOOL",
    Address: "CNR GAMA AND NTUSI STREET  BHEKUZULU OLD LOCATION  VRYHEID",
    Latitude: "-27.7748",
    Longitude: "30.8202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12030",
    WardLookupId: "3726",
    VDNumber: "43570201",
    RegisteredPopulation: "1581",
    VotingStationName: "FAITH MISSION CHURCH",
    Address: "BHEKUZULU LOCATION  VRYHEID",
    Latitude: "-27.7818",
    Longitude: "30.8257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12031",
    WardLookupId: "3727",
    VDNumber: "43570065",
    RegisteredPopulation: "1834",
    VotingStationName: "BHEKISIZWE CRECHE",
    Address: "1ST AVENUE AND NDLELA STR  BHEKUZULU LOCATION  VRYHEID",
    Latitude: "-27.7826",
    Longitude: "30.8197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12032",
    WardLookupId: "3727",
    VDNumber: "43570111",
    RegisteredPopulation: "1179",
    VotingStationName: "BHEKUZULU LIBRARY HALL-EBHARENI",
    Address: "HLABANGANI STREET  BHEKUZULU NEW LOCATION  VRYHEID",
    Latitude: "-27.7764",
    Longitude: "30.8172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12033",
    WardLookupId: "3728",
    VDNumber: "43501272",
    RegisteredPopulation: "283",
    VotingStationName: "KANDASPUNT PRIMARY SCHOOL",
    Address: "NQUTHU ROAD  NQUTHU ROAD, INSIDE NCOME PRISON  VRYHEID",
    Latitude: "-27.9254",
    Longitude: "30.6488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12034",
    WardLookupId: "3728",
    VDNumber: "43501340",
    RegisteredPopulation: "274",
    VotingStationName: "INGWENYANE PRIMARY SCHOOL",
    Address: "MONDLO  OTHAKA TRIBAL AUTHORITY",
    Latitude: "-27.9457",
    Longitude: "30.8161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12035",
    WardLookupId: "3728",
    VDNumber: "43501362",
    RegisteredPopulation: "160",
    VotingStationName: "BEMBASKOP COMBINED SCHOOL",
    Address:
      "BLOODRIVER AREA-ALONG NQUTHU R  OFF R34 RD AFTER PASSING NCOME RIVER  VRYHEID",
    Latitude: "-27.9032",
    Longitude: "30.589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12036",
    WardLookupId: "3728",
    VDNumber: "43502329",
    RegisteredPopulation: "1023",
    VotingStationName: "KWA GWEBU COMMUNITY HALL",
    Address: "KROMELLANBOOG FARM  KROMELLANBOOG FARM AT KWAGWEBU AREA  VRYHEID",
    Latitude: "-28.1419",
    Longitude: "30.9102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12037",
    WardLookupId: "3728",
    VDNumber: "43503386",
    RegisteredPopulation: "1836",
    VotingStationName: "ISOLOMUZI SECONDARY SCHOOL",
    Address: "BLOCK B ,EMONDLO  476/477 BLOCK B EMONDLO  VRYHEID/EMONDLO",
    Latitude: "-27.9724",
    Longitude: "30.7106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12038",
    WardLookupId: "3728",
    VDNumber: "43504501",
    RegisteredPopulation: "382",
    VotingStationName: "METZELFONTEIN COMBINED SCHOOL",
    Address:
      "BABANANGO ROAD  ON BABANANGO ROAD TO KROMELLENBOOG FARM  METZELFONTEIN-KWAGWEBU AREA",
    Latitude: "-28.0706",
    Longitude: "30.8759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12039",
    WardLookupId: "3728",
    VDNumber: "43505063",
    RegisteredPopulation: "99",
    VotingStationName: "SICEMFUNDO CRECHE",
    Address: "KWAMSHOMOLOZA  STILLWATER  VRYHEID",
    Latitude: "-27.8263",
    Longitude: "30.7459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12040",
    WardLookupId: "3728",
    VDNumber: "43505074",
    RegisteredPopulation: "168",
    VotingStationName: "JIMANE CRECHE",
    Address: "KWAJIMANE  EMONDLO  OTHAKA",
    Latitude: "-27.9545",
    Longitude: "30.7628",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12041",
    WardLookupId: "3729",
    VDNumber: "43501339",
    RegisteredPopulation: "344",
    VotingStationName: "SILWENI  COMBINED SCHOOL",
    Address:
      "OFF R34 TO ULUNDI  OFF R34 TO ULUNDI TURNRIGHT AFTER RAILWAY BRIDGE  VRYHEID",
    Latitude: "-27.9066",
    Longitude: "30.9",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12042",
    WardLookupId: "3729",
    VDNumber: "43501351",
    RegisteredPopulation: "203",
    VotingStationName: "INKAMANA COMBINED SCHOOL",
    Address: "R34 TO ULUNDI  FROM VHD TO ULUNDI +-8KM TURN RIGHT  VRYHEID",
    Latitude: "-27.8211",
    Longitude: "30.8156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12043",
    WardLookupId: "3729",
    VDNumber: "43501395",
    RegisteredPopulation: "245",
    VotingStationName: "THOLATHEMBA PRIMARY SCHOOL",
    Address:
      "R34 TO ULUNDI  NEXT TO THE RD IN R34 TO ULUNDI,+-10KM TURN TO LEF  VRYHEID-OPPOSITE INKAMANA MISSION",
    Latitude: "-27.8322",
    Longitude: "30.828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12044",
    WardLookupId: "3729",
    VDNumber: "43501531",
    RegisteredPopulation: "418",
    VotingStationName: "HLUMA COMBINED SCHOOL",
    Address:
      "+-8 KM FROM VRYHEID TOWN  NEXT TO R69 RD,OPPOSITE LIVESTOCK SALE YARD  LOUWSBURG",
    Latitude: "-27.7705",
    Longitude: "30.8341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12045",
    WardLookupId: "3729",
    VDNumber: "43570122",
    RegisteredPopulation: "1584",
    VotingStationName: "MPOFINI SECONDARY SCHOOL",
    Address: "BHEKUZULU NEW LOCATION  VRYHEID",
    Latitude: "-27.792",
    Longitude: "30.8197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12046",
    WardLookupId: "3729",
    VDNumber: "43570223",
    RegisteredPopulation: "1866",
    VotingStationName: "KWABHANYA SECONDARY SCHOOL",
    Address:
      "OLD RDP HOUSE AREA-EMADOSHINI  BHEKUZULU OLD LOCATION-EMADOSHINI AMADALA  BHEKUZULU LOCATION",
    Latitude: "-27.7817",
    Longitude: "30.8314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12047",
    WardLookupId: "3730",
    VDNumber: "43501092",
    RegisteredPopulation: "1067",
    VotingStationName: "EDULINI PRIMARY SCHOOL",
    Address:
      "HLAHLINDLELA ROAD  TURN LEFT AT SIYAKHATHALA CLINIC AFTER LOSS VILLAG  EMONDLO",
    Latitude: "-28.1166",
    Longitude: "30.8349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12048",
    WardLookupId: "3730",
    VDNumber: "43501104",
    RegisteredPopulation: "514",
    VotingStationName: "UQWEQWE  SECONDARY SCHOOL",
    Address: "MVUNYANE  HLAHLINDLELA TRIBAL AUTHORITY,UQWEQWE AREA  VRYHEID",
    Latitude: "-28.1207",
    Longitude: "30.8043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12049",
    WardLookupId: "3730",
    VDNumber: "43501137",
    RegisteredPopulation: "937",
    VotingStationName: "QONDISANE PRIMARY SCHOOL",
    Address:
      "MANYATHI CROSSROADS, TURN LEFT  FROM NQUTU RD TURN LEFT,BEFORE SYAKHATHALA CLINIC  HLALINDLELA",
    Latitude: "-28.0918",
    Longitude: "30.7853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12050",
    WardLookupId: "3730",
    VDNumber: "43501148",
    RegisteredPopulation: "1249",
    VotingStationName: "NGALI SECONDARY SCHOOL",
    Address: "MVUNYANE ROAD  ON MVUNYANEE ROAD  NEXT TO BUS STOP  MVUNYANE",
    Latitude: "-28.0756",
    Longitude: "30.7797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12051",
    WardLookupId: "3730",
    VDNumber: "43501216",
    RegisteredPopulation: "745",
    VotingStationName: "HLATINGWE PRIMARY  SCHOOL",
    Address:
      "EMDUNDUBEZINI AREA  TURN LEFT FROM THE CELL C TOWER,AFTER ST. TERESA  HLALINDLELA",
    Latitude: "-28.0573",
    Longitude: "30.761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12052",
    WardLookupId: "3731",
    VDNumber: "43501115",
    RegisteredPopulation: "1195",
    VotingStationName: "ISIGODI PRIMARY SCHOOL",
    Address: "NQUTHU ROAD  MVUNYANE RESERVE,HLAHLINDLEA AUTHORITY  MVUYANE",
    Latitude: "-28.1169",
    Longitude: "30.7611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12053",
    WardLookupId: "3731",
    VDNumber: "43501126",
    RegisteredPopulation: "673",
    VotingStationName: "MUZIWEPHAHLA SECONDARY SCHOOL",
    Address: "NQUTHU  ST PAUL MISSION NEXT TO EBUTCHER ESITOLO  EMONDLO",
    Latitude: "-28.097",
    Longitude: "30.6876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12054",
    WardLookupId: "3731",
    VDNumber: "43501160",
    RegisteredPopulation: "1158",
    VotingStationName: "THELEZI  PRIMARY SCHOOL",
    Address: "HLAHLINDLELA AUTHORITY  ST PAUL MISSION  VRYHEID",
    Latitude: "-28.0855",
    Longitude: "30.677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12055",
    WardLookupId: "3731",
    VDNumber: "43501182",
    RegisteredPopulation: "608",
    VotingStationName: "SIQOPHUMLANDO SECONDARY SCHOOL",
    Address: "HLAHLINDLELA  KABHOBOZANE AREA,UNDER NCECENI MOUNTAIN  EMONDLO",
    Latitude: "-28.0477",
    Longitude: "30.6776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12056",
    WardLookupId: "3731",
    VDNumber: "43503375",
    RegisteredPopulation: "213",
    VotingStationName: "NTSHIBANTSHIBA PRIMARY  SCHOOL",
    Address: "NQUTHU ROAD  NEXT TO HLAHLINDLELA TRIBAL COURT  ABAQULUSI",
    Latitude: "-28.0388",
    Longitude: "30.7139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12057",
    WardLookupId: "3731",
    VDNumber: "43504871",
    RegisteredPopulation: "481",
    VotingStationName: "NDUDUZO PRIMARY SCHOOL",
    Address:
      "THELEZINI AREA  THELEZI TURN LEFT FROM  MAIN RD TO EMHLANGENI AREA  NQUTHU",
    Latitude: "-28.0681",
    Longitude: "30.644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12058",
    WardLookupId: "3732",
    VDNumber: "43501238",
    RegisteredPopulation: "2471",
    VotingStationName: "EMADRESINI  PRIMARY SCHOOL",
    Address: "NQUTHU-EMONDLO RD  BLOCK C, EMONDLO  EMONDLO",
    Latitude: "-28.0126",
    Longitude: "30.7387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12059",
    WardLookupId: "3732",
    VDNumber: "43503869",
    RegisteredPopulation: "1143",
    VotingStationName: "KHETHELO SECONDARY SCHOOL",
    Address: "BLOCK C EMONDLO  EMONDLO",
    Latitude: "-27.996",
    Longitude: "30.7264",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12060",
    WardLookupId: "3732",
    VDNumber: "43505085",
    RegisteredPopulation: "465",
    VotingStationName: "EMADRESINI  NEW CRECHE",
    Address: "EMADRESINI AREA  EMONDLO   HLAHLINDLELA",
    Latitude: "-28.0019",
    Longitude: "30.7262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12061",
    WardLookupId: "3733",
    VDNumber: "43501193",
    RegisteredPopulation: "1226",
    VotingStationName: "KWAFUZOKUHLE  PRIMARY SCHOOL",
    Address:
      "NCECENI AREA-NQUTHU ROAD  TURN LEFT FROM NQUTU RD,BEFORE NXANGIPHILILE STORE  HLALINDLELA",
    Latitude: "-28.0354",
    Longitude: "30.6395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12062",
    WardLookupId: "3733",
    VDNumber: "43501205",
    RegisteredPopulation: "861",
    VotingStationName: "MACHANCA SECONDARY SCHOOL",
    Address: "FROM NQUTU RD,PASS CHURCH  MACHANCA AREA  HLALINDLELA",
    Latitude: "-28.0199",
    Longitude: "30.6515",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12063",
    WardLookupId: "3733",
    VDNumber: "43501249",
    RegisteredPopulation: "1171",
    VotingStationName: "MVUZINI SECONDARY SCHOOL",
    Address:
      "EMVUZINI AREA  FROM MONDLO RD,PASS CEMENTRY NEXT TO NXAWULA STORE  ABAQULUSI",
    Latitude: "-28.0071",
    Longitude: "30.6762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12064",
    WardLookupId: "3733",
    VDNumber: "43503881",
    RegisteredPopulation: "1643",
    VotingStationName: "PHEMBUKUTHULA  PRIMARY SCHOOL",
    Address:
      "EMADRESSINI AREA  TRADO FARM, EMADRESINI,EMONDLO  HLAHLINDLELA TA",
    Latitude: "-27.989",
    Longitude: "30.7029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12065",
    WardLookupId: "3734",
    VDNumber: "43504882",
    RegisteredPopulation: "845",
    VotingStationName: "BABY BEAR CRECHE",
    Address: "MKHUMBANE  EMONDLO  HLALINDLELA",
    Latitude: "-27.997",
    Longitude: "30.7366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12066",
    WardLookupId: "3734",
    VDNumber: "43570100",
    RegisteredPopulation: "2100",
    VotingStationName: "VELANKOSI PRIMARY SCHOOL",
    Address: "BLOCK A EMONDLO  EMONDLO  VRYHEID",
    Latitude: "-27.9884",
    Longitude: "30.7245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12067",
    WardLookupId: "3734",
    VDNumber: "43570177",
    RegisteredPopulation: "1288",
    VotingStationName: "NHLIZIYONHLE PRIMARY SCHOOL",
    Address: "2658 BLOCK B  BLOCK B,EMONDLO  EMONDLO TOWNSHIP",
    Latitude: "-27.9818",
    Longitude: "30.7128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12068",
    WardLookupId: "3735",
    VDNumber: "43501261",
    RegisteredPopulation: "1479",
    VotingStationName: "LINDUMTHETHO PRIMARY  SCHOOL",
    Address: "EMONDLO  C SECTION  BHEKUMTHETHO T/A",
    Latitude: "-27.979",
    Longitude: "30.7315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12069",
    WardLookupId: "3735",
    VDNumber: "43503397",
    RegisteredPopulation: "1358",
    VotingStationName: "ZWELISHA CRECHE",
    Address: "BLOCK C  EMONDLO  HLALINDLELA",
    Latitude: "-27.9684",
    Longitude: "30.7269",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12070",
    WardLookupId: "3735",
    VDNumber: "43503533",
    RegisteredPopulation: "1123",
    VotingStationName: "IGOQO PRIMARY SCHOOL",
    Address: "BLOCK C  BLOCK C ,BHEKUMTHETHO AREA  EMONDLO",
    Latitude: "-27.9747",
    Longitude: "30.731",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12071",
    WardLookupId: "3736",
    VDNumber: "43501250",
    RegisteredPopulation: "1211",
    VotingStationName: "SILINDEKAHLE PRIMARY SCHOOL",
    Address: "B SECTION EZANSI KWESTADUIM  1646 BLOCK B,EMONDLO  EMONDLO",
    Latitude: "-27.9751",
    Longitude: "30.7049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12072",
    WardLookupId: "3736",
    VDNumber: "43503364",
    RegisteredPopulation: "1408",
    VotingStationName: "SEKETHWAYO SECONDARY SCHOOL",
    Address: "B SECTION  BLOCK B 44, MONDLO  EMONDLO",
    Latitude: "-27.9728",
    Longitude: "30.7054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12073",
    WardLookupId: "3736",
    VDNumber: "43570098",
    RegisteredPopulation: "2053",
    VotingStationName: "FISOKUHLE PRIMARY SCHOOL",
    Address: "NGWANE  BLOCK B 2487, EMONDLO  MONDLO AREA",
    Latitude: "-27.9778",
    Longitude: "30.7099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12074",
    WardLookupId: "3737",
    VDNumber: "43501159",
    RegisteredPopulation: "738",
    VotingStationName: "GUDU SECONDARY SCHOOL",
    Address:
      "NQUTHU ROAD  NQUTU RD,TURN LEFT ON 2ND BRIGDE,NEXT TO SOCCERFIE  MVUNYANE",
    Latitude: "-28.0393",
    Longitude: "30.7287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12075",
    WardLookupId: "3737",
    VDNumber: "43501227",
    RegisteredPopulation: "1236",
    VotingStationName: "MPUCUKO PRIMARY SCHOOL",
    Address:
      "EN ROUTE MHLONGO FARM ROAD  EN ROUTE MHLONGO FARM ROAD NEXT TO THE SHOPS  EMONDLO",
    Latitude: "-28.0161",
    Longitude: "30.7595",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12076",
    WardLookupId: "3737",
    VDNumber: "43503353",
    RegisteredPopulation: "955",
    VotingStationName: "HLAHLINDLELA COMMUNITY HALL",
    Address:
      "OPPOSITE KWAMNYATHI STORE  CORNER OF MVUNYANE INTERSECTION & ENQUTU  EMONDLO/MVUNYANE",
    Latitude: "-28.0502",
    Longitude: "30.7195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12077",
    WardLookupId: "3737",
    VDNumber: "43503858",
    RegisteredPopulation: "704",
    VotingStationName: "PHUCUKA  PRIMARY SCHOOL",
    Address:
      "MHLONGO FARM-EMONDLO  MHLONGO FARM NEXT TO EKUBONGENI STORE  HLAHLINDLELA",
    Latitude: "-28.0052",
    Longitude: "30.7801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12078",
    WardLookupId: "3737",
    VDNumber: "43505096",
    RegisteredPopulation: "456",
    VotingStationName: "NGILANDI CRECHE",
    Address: "ENGILANDI  EMONDLO  HLALINDLELA",
    Latitude: "-28.0331",
    Longitude: "30.7462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12079",
    WardLookupId: "3738",
    VDNumber: "43501407",
    RegisteredPopulation: "428",
    VotingStationName: "HLANGUZA  COMBINED SCHOOL",
    Address:
      "VHD-DUNDEE ROAD  NEXT TO R34,LEFT OPPOSITE SCHEEPERSNEK STORE  VRYHEID",
    Latitude: "-27.8312",
    Longitude: "30.7012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12080",
    WardLookupId: "3738",
    VDNumber: "43570043",
    RegisteredPopulation: "1429",
    VotingStationName: "OLD CLINIC BUILDING",
    Address: "CNR HIGH AND SOUTH STREET  VRYHEID  VRYHEID",
    Latitude: "-27.7671",
    Longitude: "30.8013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12081",
    WardLookupId: "3738",
    VDNumber: "43570076",
    RegisteredPopulation: "2314",
    VotingStationName: "LAKESIDE HALL",
    Address: "OAK STREET  LAKESIDE TOWNSHIP NEXT TO LAKESIDE PRIMARY  VRYHEID",
    Latitude: "-27.7944",
    Longitude: "30.8124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12082",
    WardLookupId: "1499",
    VDNumber: "43500901",
    RegisteredPopulation: "465",
    VotingStationName: "ENTABENIYEZULU PRIMARY SCHOOL",
    Address: "ENTABENIYEZULU AREA  VILLAGE  NONGOMA, MANDLAKAZI TRIBAL",
    Latitude: "-27.7723",
    Longitude: "31.9706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12083",
    WardLookupId: "1499",
    VDNumber: "43500967",
    RegisteredPopulation: "1499",
    VotingStationName: "MANDLAKAZI J.S.SCHOOL",
    Address: "NONGOMA  BHANGANOMA AREA  MANDLAKAZI TRIBAL",
    Latitude: "-27.7534",
    Longitude: "31.8885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12084",
    WardLookupId: "1499",
    VDNumber: "43500978",
    RegisteredPopulation: "1086",
    VotingStationName: "BANGANOMA  PRIMARY SCHOOL",
    Address: "NONGOMA   SIWELA  AREA   MANDLAKAZI TRIBAL",
    Latitude: "-27.7245",
    Longitude: "31.9158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12085",
    WardLookupId: "1499",
    VDNumber: "43500989",
    RegisteredPopulation: "760",
    VotingStationName: "MDUBANE PRIMARY  SCHOOL",
    Address: "NONGOMA  MDUBANE AREA   MANDLAKAZI TRIBAL",
    Latitude: "-27.7398",
    Longitude: "31.8637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12086",
    WardLookupId: "1499",
    VDNumber: "43501025",
    RegisteredPopulation: "689",
    VotingStationName: "OBANE PRIMARY  SCHOOL",
    Address: "NONGOMA  MADWALENI AREA   MANDLAKAZI  TRIBAL",
    Latitude: "-27.6492",
    Longitude: "31.9434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12087",
    WardLookupId: "1499",
    VDNumber: "43503915",
    RegisteredPopulation: "539",
    VotingStationName: "SENZOKUHLE SECONDARY SCHOOL",
    Address: "NONGOMA  ENTABENIYEZULU AREA  MANDLAKAZI TRIBAL",
    Latitude: "-27.794",
    Longitude: "31.9627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12088",
    WardLookupId: "1500",
    VDNumber: "43500798",
    RegisteredPopulation: "526",
    VotingStationName: "MTHWADLANA PRIMARY SCHOOL",
    Address:
      "MAGONSINI AREA/ BOMBOLO AREA  NEXT TO MOBILE CLINIC SITE  MANDLAKAZI TRIBAL, BOMBOLO ROAD",
    Latitude: "-27.935",
    Longitude: "31.9408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12089",
    WardLookupId: "1500",
    VDNumber: "43500800",
    RegisteredPopulation: "518",
    VotingStationName: "NDEMA COMBINED PRIMARY SCHOOL",
    Address: "MATSHEKETSHE AREA  MATSHEKETSHE  AREA   NONGOMA",
    Latitude: "-27.8989",
    Longitude: "31.9383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12090",
    WardLookupId: "1500",
    VDNumber: "43500899",
    RegisteredPopulation: "1119",
    VotingStationName: "SOJIYISA HIGH SCHOOL",
    Address:
      "MANDLAKAZI TA SIBONELO RESERVE  SIBONELO AREA   MANDLAKAZI TRIBAL",
    Latitude: "-27.8697",
    Longitude: "31.8282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12091",
    WardLookupId: "1500",
    VDNumber: "43500945",
    RegisteredPopulation: "544",
    VotingStationName: "BOMBOLO PRIMARY  SCHOOL",
    Address: "NONGOMA   BOMBOLO AREA   MANDLAKAZI TRIBAL",
    Latitude: "-27.8959",
    Longitude: "31.9782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12092",
    WardLookupId: "1500",
    VDNumber: "43502486",
    RegisteredPopulation: "764",
    VotingStationName: "EBUHLENIBENKOSI PRIMARY  SCHOOL",
    Address: "NONGOMA   EBUHLENI  AREA   MANDLAKAZI TRIBAL",
    Latitude: "-27.8479",
    Longitude: "31.9118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12093",
    WardLookupId: "1500",
    VDNumber: "43503904",
    RegisteredPopulation: "421",
    VotingStationName: "NGANGAYIPHI PRIMARY SCHOOL",
    Address:
      "NGANGAYIPHI AREA SIBONELO ROAD  NONGOMA   NONGOMA, MADLAKAZI TRIBAL",
    Latitude: "-27.8877",
    Longitude: "31.8663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12094",
    WardLookupId: "1500",
    VDNumber: "43504185",
    RegisteredPopulation: "370",
    VotingStationName: "OVUKANENI PRIMARY SCHOOL",
    Address: "NONGOMA   KONJOKO AREA   MANDLAKAZI TRIBAL",
    Latitude: "-27.8391",
    Longitude: "31.8635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12095",
    WardLookupId: "1500",
    VDNumber: "43504208",
    RegisteredPopulation: "357",
    VotingStationName: "MDUNA PRIMARY",
    Address: "N/A  NONGOMA   NONGOMA NU",
    Latitude: "-27.9234",
    Longitude: "31.9783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12096",
    WardLookupId: "1501",
    VDNumber: "43500990",
    RegisteredPopulation: "795",
    VotingStationName: "BHAQALESIZWE HIGH  SCHOOL",
    Address: "NONGOMA  MGXANYINI AREA   MANDLAKAZI TRIBAL",
    Latitude: "-27.7141",
    Longitude: "31.7863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12097",
    WardLookupId: "1501",
    VDNumber: "43501003",
    RegisteredPopulation: "1217",
    VotingStationName: "BHEKINTINTA  HIGH  SCHOOL",
    Address: "NONGOMA   SOVANE AREA   MANDLAKAZI  TRIBAL",
    Latitude: "-27.6427",
    Longitude: "31.7878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12098",
    WardLookupId: "1501",
    VDNumber: "43501014",
    RegisteredPopulation: "545",
    VotingStationName: "ZIMELE PRIMARY  SCHOOL",
    Address: "NONGOMA   OKHALWENI AREA   MANDLAKAZI TRIBAL",
    Latitude: "-27.6728",
    Longitude: "31.8708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12099",
    WardLookupId: "1501",
    VDNumber: "43501968",
    RegisteredPopulation: "1290",
    VotingStationName: "KOMBUZI  PRIMARY SCHOOL",
    Address: "NONGOMA   KOMBUZI AREA  MANDLAKAZI TRIBAL",
    Latitude: "-27.7389",
    Longitude: "31.7396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12100",
    WardLookupId: "1501",
    VDNumber: "43503409",
    RegisteredPopulation: "547",
    VotingStationName: "NGENGENI PRIMARY  SCHOOL",
    Address: "NONGOMA   NGENGENI AREA   MANDLAKAZI TRIBAL",
    Latitude: "-27.739",
    Longitude: "31.8194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12101",
    WardLookupId: "1501",
    VDNumber: "43503410",
    RegisteredPopulation: "705",
    VotingStationName: "DOMBA HIGH  SCHOOL",
    Address: "NONGOMA   KOMBUZI AREA  MANDLAKAZI TRIBAL",
    Latitude: "-27.7727",
    Longitude: "31.7321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12102",
    WardLookupId: "1501",
    VDNumber: "43504534",
    RegisteredPopulation: "428",
    VotingStationName: "MAPHEMBENI PRIMARY SCHOOL",
    Address: "SOVANE  AREA   NONGOMA   MANDLAKAZI TRIBAL",
    Latitude: "-27.6078",
    Longitude: "31.8017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12103",
    WardLookupId: "1502",
    VDNumber: "43500732",
    RegisteredPopulation: "822",
    VotingStationName: "OSINGINSINGINI PRIMARY  SCHOOL",
    Address: "MASUNDWINI AREA  VILLAGE  NONGOMA",
    Latitude: "-27.9883",
    Longitude: "31.6879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12104",
    WardLookupId: "1502",
    VDNumber: "43500776",
    RegisteredPopulation: "832",
    VotingStationName: "KWANZIMA  PRIMARY SCHOOL",
    Address: "NONGOMA  KWAZNIMA AREA   MANDLAKAZI TRIBAL",
    Latitude: "-27.9665",
    Longitude: "31.7172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12105",
    WardLookupId: "1502",
    VDNumber: "43502385",
    RegisteredPopulation: "690",
    VotingStationName: "THUSANA  PRIMARY SCHOOL",
    Address: "HLABISA/MKUZE ROAD  MBONJENI AREA  NONGOMA",
    Latitude: "-27.9232",
    Longitude: "31.7212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12106",
    WardLookupId: "1502",
    VDNumber: "43502644",
    RegisteredPopulation: "669",
    VotingStationName: "MANGQWASHU PRIMARY  SCHOOL",
    Address: "NONGOMA  EDLABE AREA  MANDLAKAZI TRIBAL",
    Latitude: "-27.9075",
    Longitude: "31.6933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12107",
    WardLookupId: "1502",
    VDNumber: "43504398",
    RegisteredPopulation: "1479",
    VotingStationName: "EMANQELENI HALL",
    Address: "MANQELENI AREA   NONGOMA  MANDLAKAZI  TRIBAL",
    Latitude: "-27.918",
    Longitude: "31.7579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12108",
    WardLookupId: "1502",
    VDNumber: "43504400",
    RegisteredPopulation: "460",
    VotingStationName: "MANZEZULU PRIMARY SCHOOL",
    Address: "NONGOMA   MANZEZULU AREA   MANDLAKAZI TRIBAL",
    Latitude: "-27.9557",
    Longitude: "31.6801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12109",
    WardLookupId: "1502",
    VDNumber: "43504411",
    RegisteredPopulation: "490",
    VotingStationName: "NKALANENI  PRIMARY SCHOOL",
    Address: "NONGOMA   NKALANENI  AREA   MANDLAKAZI  TRIBAL",
    Latitude: "-28.0147",
    Longitude: "31.6899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12110",
    WardLookupId: "1503",
    VDNumber: "43500923",
    RegisteredPopulation: "724",
    VotingStationName: "MSEBE PRIMARY  SCHOOL",
    Address: "NONGOMA   MSEBE   AREA   MANDLAKAZI  TRIBAL",
    Latitude: "-27.8091",
    Longitude: "31.7995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12111",
    WardLookupId: "1503",
    VDNumber: "43500934",
    RegisteredPopulation: "1500",
    VotingStationName: "BANZANA HIGH SCHOOL",
    Address: "NONGOMA   NJOKO AREA   MANDLAKAZI TRIBAL",
    Latitude: "-27.8041",
    Longitude: "31.8463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12112",
    WardLookupId: "1503",
    VDNumber: "43502497",
    RegisteredPopulation: "563",
    VotingStationName: "ZAMANI PRIMARY  SCHOOL",
    Address: "EZIMNISINI AREA  ZAMANI  AREA   NONGOMA",
    Latitude: "-27.7571",
    Longitude: "31.7972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12113",
    WardLookupId: "1503",
    VDNumber: "43503421",
    RegisteredPopulation: "794",
    VotingStationName: "LANGALESIZWE PRIMARY  SCHOOL",
    Address: "LANGALESIZWE   AREA  MSEBE AREA  MANDLAKAZI TRIBAL",
    Latitude: "-27.8086",
    Longitude: "31.8217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12114",
    WardLookupId: "1503",
    VDNumber: "43504422",
    RegisteredPopulation: "319",
    VotingStationName: "IMPUPHUSI  PRIMARY SCHOOL",
    Address: "NONGOMA   IMPUPHUSI AREA   MANDLAKAZI  TRIBAL",
    Latitude: "-27.8399",
    Longitude: "31.7631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12115",
    WardLookupId: "1503",
    VDNumber: "43504433",
    RegisteredPopulation: "810",
    VotingStationName: "PHAPHASI CRECHE",
    Address: "NONGOMA   NHLEBELA NEAR MBALI STORE   MANDLAKAZI TRIBAL",
    Latitude: "-27.8432",
    Longitude: "31.7985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12116",
    WardLookupId: "1504",
    VDNumber: "43500473",
    RegisteredPopulation: "673",
    VotingStationName: "ECELUKWAZI PRIMARY  SCHOOL",
    Address:
      "NONGOMA  DENGENI AREA  NEXT TO DENGENI CLINIC   MANDLAKAZI TRIBAL",
    Latitude: "-28.002",
    Longitude: "31.7792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12117",
    WardLookupId: "1504",
    VDNumber: "43500754",
    RegisteredPopulation: "734",
    VotingStationName: "CWAYISA LOWER PRIMARY SCHOOL",
    Address: "NONGOMA  EKUVUKENI AREA  MANDLAKAZI TRIBAL",
    Latitude: "-27.9968",
    Longitude: "31.7286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12118",
    WardLookupId: "1504",
    VDNumber: "43502239",
    RegisteredPopulation: "1278",
    VotingStationName: "ESIDINSI PRIMARY SCHOOL",
    Address: "NONGOMA   SINDINSI AREA   MANDLAKAZI TRIBAL",
    Latitude: "-27.9473",
    Longitude: "31.7721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12119",
    WardLookupId: "1504",
    VDNumber: "43502396",
    RegisteredPopulation: "1074",
    VotingStationName: "BAZINI PRIMARY  SCHOOL",
    Address: "HLABISA MAIN ROAD  BAZINI AREA   MANDLAKAZI  TRIBAL",
    Latitude: "-27.9167",
    Longitude: "31.7825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12120",
    WardLookupId: "1504",
    VDNumber: "43502633",
    RegisteredPopulation: "932",
    VotingStationName: "MANDLAKAZI TRIBAL COURT",
    Address: "NONGOMA   ESIPHAMBANWENI  NONGOMA",
    Latitude: "-27.9095",
    Longitude: "31.7583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12121",
    WardLookupId: "1505",
    VDNumber: "43500787",
    RegisteredPopulation: "796",
    VotingStationName: "WELA  PRIMARY SCHOOL",
    Address: "WELA RESERVE  NHLWATHI LINE   MANDLAKAZI TRIBAL",
    Latitude: "-27.9827",
    Longitude: "31.921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12122",
    WardLookupId: "1505",
    VDNumber: "43500866",
    RegisteredPopulation: "870",
    VotingStationName: "MANDLEZULU PRIMARY SCHOOL",
    Address: "EMPUQWINI AREA  MTHWADLANA LINE   MANDLAKAZI TRIBAL",
    Latitude: "-27.9362",
    Longitude: "31.8482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12123",
    WardLookupId: "1505",
    VDNumber: "43502251",
    RegisteredPopulation: "658",
    VotingStationName: "BONGOKUHLE  HIGH SCHOOL",
    Address: "NONGOMA   DONGOTHULE AREA   NONGOMA",
    Latitude: "-27.9274",
    Longitude: "31.9063",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12124",
    WardLookupId: "1505",
    VDNumber: "43503454",
    RegisteredPopulation: "285",
    VotingStationName: "SIZWELBANTU  PRIMARY SCHOOL",
    Address: "NONGOMA  NHLWATHI  LINE   MANDLAKAZI TRIBAL",
    Latitude: "-27.9562",
    Longitude: "31.9172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12125",
    WardLookupId: "1505",
    VDNumber: "43503689",
    RegisteredPopulation: "249",
    VotingStationName: "NTATAKUSA PRIMARY  SCHOOL",
    Address: "MTUBATUBA ROAD  WELA AREA   MANDLAKAZI TRIBA",
    Latitude: "-27.9572",
    Longitude: "31.876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12126",
    WardLookupId: "1505",
    VDNumber: "43503702",
    RegisteredPopulation: "329",
    VotingStationName: "INTUTHUKO  PRIMARYSCHOOL",
    Address: "HLABISA  ROAD  SINKONKONKO AREA   MANDLAKAZI TRIBAL",
    Latitude: "-28.0281",
    Longitude: "31.9334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12127",
    WardLookupId: "1505",
    VDNumber: "43504219",
    RegisteredPopulation: "443",
    VotingStationName: "NGADUMBILI PRIMARY  SCHOOL",
    Address: "HLABISA LINE   NGADUMBILI  AREA   MANDLAKAZI TRIBAL",
    Latitude: "-27.9782",
    Longitude: "31.8844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12128",
    WardLookupId: "1505",
    VDNumber: "43504444",
    RegisteredPopulation: "356",
    VotingStationName: "GWEBU  PRIMARY SCHOOL",
    Address: "NONGOMA   GWEBU AREA   MANDLAKAZI TRIBAL",
    Latitude: "-28.0135",
    Longitude: "31.8911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12129",
    WardLookupId: "1505",
    VDNumber: "43505108",
    RegisteredPopulation: "180",
    VotingStationName: "KANCANA KANCANE CRECHE",
    Address: "NONGOMA  ENKUNGWINI AREA  MANDLAKAZI TRIBAL",
    Latitude: "-27.9155",
    Longitude: "31.8286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12130",
    WardLookupId: "1506",
    VDNumber: "43500765",
    RegisteredPopulation: "2030",
    VotingStationName: "SIBUSISO HIGH SCHOOL",
    Address: "BUXEDEN  BUXEDEN AREA   MANDLAKAZI TRIBAL",
    Latitude: "-28.0029",
    Longitude: "31.8492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12131",
    WardLookupId: "1506",
    VDNumber: "43500877",
    RegisteredPopulation: "1180",
    VotingStationName: "BHEKUMTHETHO PRIMARY SCHOOL",
    Address: "NONGOMA/HLABISA M/ROAD  BHEKUMTHETHO AREA   MANDLAKAZI TRIBAL",
    Latitude: "-27.9392",
    Longitude: "31.8171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12132",
    WardLookupId: "1506",
    VDNumber: "43502767",
    RegisteredPopulation: "1003",
    VotingStationName: "UBUMBANO PRIMARY  SCHOOL",
    Address: "NONGOMA  UBUMBANO  AREA  MANDLAKAZI TRIBAL",
    Latitude: "-27.976",
    Longitude: "31.8572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12133",
    WardLookupId: "1506",
    VDNumber: "43504220",
    RegisteredPopulation: "265",
    VotingStationName: "EHLEZENI C.P. SCHOOL",
    Address: "NONGOMA   EHLEZENI AREA   MANDLAKAZI TRIBAL",
    Latitude: "-27.9679",
    Longitude: "31.8134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12134",
    WardLookupId: "1507",
    VDNumber: "43500721",
    RegisteredPopulation: "1991",
    VotingStationName: "NOGCOYI PRIMARY SCHOOL",
    Address: "NONGOMA  HOLINYOKA AREA   USUTHU TRIBAL",
    Latitude: "-27.9557",
    Longitude: "31.6502",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12135",
    WardLookupId: "1507",
    VDNumber: "43503465",
    RegisteredPopulation: "2537",
    VotingStationName: "KING BHEKUZULU HIGH SCHOOL",
    Address: "NONGOMA  LINDIZWE  AREA   USUTHU TRIBAL",
    Latitude: "-27.9268",
    Longitude: "31.6449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12136",
    WardLookupId: "1507",
    VDNumber: "43503476",
    RegisteredPopulation: "1158",
    VotingStationName: "KWADENGE  HIGH SCHOOL",
    Address: "NONGOMA  KWADENGE  AREA   MATHENI TRIBAL",
    Latitude: "-27.9749",
    Longitude: "31.634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12137",
    WardLookupId: "1508",
    VDNumber: "43500574",
    RegisteredPopulation: "1239",
    VotingStationName: "LETHUKUKHANYA PRIMARY SCHOOL",
    Address: "LETHUKUKHANYA AREA  DABHAZI AREA   MANDLAKAZI TRIBAL",
    Latitude: "-28.062",
    Longitude: "31.772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12138",
    WardLookupId: "1508",
    VDNumber: "43500743",
    RegisteredPopulation: "368",
    VotingStationName: "NKAWU SECONDARY SCHOOL",
    Address: "MAPHOPHOMA ROAD   NKAWU AREA   USUTHU TRIABL",
    Latitude: "-28.0537",
    Longitude: "31.7829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12139",
    WardLookupId: "1508",
    VDNumber: "43502352",
    RegisteredPopulation: "1103",
    VotingStationName: "INGWEMNYAMA PRIMARY  SCHOOL",
    Address: "NONGOMA   EKUVUKENI AREA   NONGOMA",
    Latitude: "-28.0168",
    Longitude: "31.7238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12140",
    WardLookupId: "1508",
    VDNumber: "43504039",
    RegisteredPopulation: "270",
    VotingStationName: "INGWENYABEYIGWINYA PRIMARY SCHOOL",
    Address: "MAPHOPHOMA  ROAD   DABHAZI AREA   MANDLAKAZI TRIBAL",
    Latitude: "-28.0331",
    Longitude: "31.7969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12141",
    WardLookupId: "1508",
    VDNumber: "43504174",
    RegisteredPopulation: "318",
    VotingStationName: "NOKWANE HIGH SCHOOL",
    Address: "NEAR ST JOHN CHURCH  DABHAZI AREA  NONGOMA, MANDLAKAZI TRIBAL",
    Latitude: "-28.069",
    Longitude: "31.7393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12142",
    WardLookupId: "1508",
    VDNumber: "43504961",
    RegisteredPopulation: "810",
    VotingStationName: "KLOLWENI PRIMARY  SCHOOL",
    Address: "NONGOMA  EKUVUKENI   MANDLAKAZI TRIBAL",
    Latitude: "-28.0274",
    Longitude: "31.7366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12143",
    WardLookupId: "1509",
    VDNumber: "43500440",
    RegisteredPopulation: "613",
    VotingStationName: "MASOKANENI PRIMARY  SCHOOL",
    Address: "NONGOMA   VILLAGE  NONGOMA",
    Latitude: "-28.184",
    Longitude: "31.7287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12144",
    WardLookupId: "1509",
    VDNumber: "43500541",
    RegisteredPopulation: "929",
    VotingStationName: "NGOLOTSHA COMBINED PRIMARY SCHOOL",
    Address: "NGOLOTSHA AREA  VILLAGE  NONGOMA",
    Latitude: "-28.1945",
    Longitude: "31.78",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12145",
    WardLookupId: "1509",
    VDNumber: "43500552",
    RegisteredPopulation: "544",
    VotingStationName: "MAKHEME COMBINED PRIMARY SCHOOL",
    Address: "MAPHOPHOMA  AREA   MAKHEME AREA   MANDLAKAZI TRIBAL",
    Latitude: "-28.1362",
    Longitude: "31.7736",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12146",
    WardLookupId: "1509",
    VDNumber: "43500563",
    RegisteredPopulation: "918",
    VotingStationName: "ENDLOZANA LOWER PRIMARY SCHOOL",
    Address: "ENDLOZANA AREA  ENDLOZANE  AREA   MANDLAKAZI  TRIBAL",
    Latitude: "-28.1151",
    Longitude: "31.7625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12147",
    WardLookupId: "1509",
    VDNumber: "43500585",
    RegisteredPopulation: "1340",
    VotingStationName: "NZOBO PRIMARY  SCHOOL",
    Address: "NONGOMA   DABHAZI AREA   MANDLAKAZI TRIBAL",
    Latitude: "-28.0608",
    Longitude: "31.722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12148",
    WardLookupId: "1509",
    VDNumber: "43504455",
    RegisteredPopulation: "441",
    VotingStationName: "MGABHANYONGO PRIMARY SCHOOL",
    Address: "NONGOMA   KWELIBOMVU AREA   MANDLAKAZI TRIBAL",
    Latitude: "-28.1208",
    Longitude: "31.7202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12149",
    WardLookupId: "1510",
    VDNumber: "43500529",
    RegisteredPopulation: "730",
    VotingStationName: "EMANONO HIGH SCHOOL",
    Address: "NONGOMA   MANHLANHLE  AREA   MANDLAKAZI TRIBAL",
    Latitude: "-28.102",
    Longitude: "31.6065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12150",
    WardLookupId: "1510",
    VDNumber: "43500596",
    RegisteredPopulation: "889",
    VotingStationName: "MAPHOPHOMA PRIMARY SCHOOL",
    Address: "MAPHOPHOMA   NONGOMA  MATHENI TRIBAL",
    Latitude: "-28.0396",
    Longitude: "31.6802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12151",
    WardLookupId: "1510",
    VDNumber: "43502475",
    RegisteredPopulation: "495",
    VotingStationName: "SIYAPHAKAMA HIGH SCHOOL",
    Address: "NONGOMA   MANDLAKAZI TRIBAL",
    Latitude: "-28.1604",
    Longitude: "31.6915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12152",
    WardLookupId: "1510",
    VDNumber: "43502655",
    RegisteredPopulation: "642",
    VotingStationName: "NZAMA SCHOOL",
    Address: "OKHUKHO  ROAD   VILLAGE  NONGOMA",
    Latitude: "-28.1777",
    Longitude: "31.656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12153",
    WardLookupId: "1510",
    VDNumber: "43502723",
    RegisteredPopulation: "1112",
    VotingStationName: "MZIYAQOKWA PRIMARY SCHOOL",
    Address: "MACEKANENI    AREA  VILLAGE  DISTRICT",
    Latitude: "-28.1115",
    Longitude: "31.6729",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12154",
    WardLookupId: "1510",
    VDNumber: "43504983",
    RegisteredPopulation: "585",
    VotingStationName: "BHOBHOBHO PRIMARY SCHOOL",
    Address: "NONGOMA  MATHENI TRIBAL",
    Latitude: "-28.0605",
    Longitude: "31.669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12155",
    WardLookupId: "1511",
    VDNumber: "43500608",
    RegisteredPopulation: "982",
    VotingStationName: "QONQO PRIMARY SCHOOL",
    Address: "NEXT TO SIBIYA  TUCK SHOP  QONQO AREA  NONGOMA, MATHENI TRIBAL",
    Latitude: "-28.0533",
    Longitude: "31.6367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12156",
    WardLookupId: "1511",
    VDNumber: "43502554",
    RegisteredPopulation: "671",
    VotingStationName: "ENTUTHUKWENI PRIMARY  SCHOOL",
    Address: "NEXT TO NTUTHUKWENI TUCK SHOP  ENTUTHUKWENI AREA  MATHENI TRIBAL",
    Latitude: "-28.0035",
    Longitude: "31.6449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12157",
    WardLookupId: "1511",
    VDNumber: "43502734",
    RegisteredPopulation: "800",
    VotingStationName: "NKULU  PRIMARY SCHOOL",
    Address: "NONGOMA  KWANKULU AREA   MATHENI TRIBAL",
    Latitude: "-28.0593",
    Longitude: "31.596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12158",
    WardLookupId: "1511",
    VDNumber: "43502868",
    RegisteredPopulation: "1352",
    VotingStationName: "GOMONDO PRIMARY  SCHOOL",
    Address: "NONGOMA  EKUBUSENI  AREA   USUTHU TRIBAL",
    Latitude: "-27.9628",
    Longitude: "31.6186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12159",
    WardLookupId: "1512",
    VDNumber: "43500518",
    RegisteredPopulation: "595",
    VotingStationName: "DAYENI PRIMARY  SCHOOL",
    Address: "NONGOMA   KWADAYENI AREA   USUTHU TRIBAL",
    Latitude: "-28.0836",
    Longitude: "31.541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12160",
    WardLookupId: "1512",
    VDNumber: "43502363",
    RegisteredPopulation: "1198",
    VotingStationName: "MAHLOMBE PRIMARY  SCHOOL",
    Address: "NONGOMA   EVUNA AREA   USUTHU TRIBAL",
    Latitude: "-27.9998",
    Longitude: "31.5913",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12161",
    WardLookupId: "1512",
    VDNumber: "43502835",
    RegisteredPopulation: "572",
    VotingStationName: "ST EMANUEL PRIMARY  SCHOOL",
    Address: "NONGOMA   ESIGANGENI   AREA   USUTHU TRIBAL",
    Latitude: "-28.0192",
    Longitude: "31.5969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12162",
    WardLookupId: "1512",
    VDNumber: "43503690",
    RegisteredPopulation: "932",
    VotingStationName: "KWAMUSI PRIMARY  SCHOOL",
    Address: "NONGOMA    ONYANGO  AREA   USUTHU TRIBAL",
    Latitude: "-28.1199",
    Longitude: "31.5259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12163",
    WardLookupId: "1512",
    VDNumber: "43503713",
    RegisteredPopulation: "869",
    VotingStationName: "TSHODO PRIMARY  SCHOOL",
    Address: "NONGOMA  EZINHLENHLENI AREA   USUTHU TRIBAL",
    Latitude: "-28.0227",
    Longitude: "31.5714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12164",
    WardLookupId: "1512",
    VDNumber: "43504972",
    RegisteredPopulation: "709",
    VotingStationName: "EZIMPAKANENI PRIMARY SCHOOL",
    Address: "NONGOMA  EZIMPAKANENI AREA  USUTHU TRIBAL",
    Latitude: "-28.053",
    Longitude: "31.5163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12165",
    WardLookupId: "1512",
    VDNumber: "43505119",
    RegisteredPopulation: "366",
    VotingStationName: "MABINDA CRECHE",
    Address: "NONGOMA  EZILONYENI  AREA  USUTHU TRIBAL",
    Latitude: "-27.964",
    Longitude: "31.606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12166",
    WardLookupId: "1513",
    VDNumber: "43500664",
    RegisteredPopulation: "538",
    VotingStationName: "KOHLOKOLO PRIMARY  SCHOOL",
    Address: "NONGOMA  KOHLOKOLO AREA   USUTHU TRIBAL",
    Latitude: "-28.0367",
    Longitude: "31.4929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12167",
    WardLookupId: "1513",
    VDNumber: "43500697",
    RegisteredPopulation: "1650",
    VotingStationName: "FALAZA HIGH SCHOOL",
    Address: "NONGOMA  USUTHU AREA   USUTHU TRIBAL",
    Latitude: "-27.958",
    Longitude: "31.4991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12168",
    WardLookupId: "1513",
    VDNumber: "43502756",
    RegisteredPopulation: "898",
    VotingStationName: "SONKESHANA  PRIMARY SCHOOL",
    Address: "NONGOMA  MHAMBUMA AREA   USUTHU TRIBAL",
    Latitude: "-28.003",
    Longitude: "31.5256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12169",
    WardLookupId: "1513",
    VDNumber: "43503544",
    RegisteredPopulation: "794",
    VotingStationName: "SIZABONKE  HIGH  SCHOOL",
    Address: "NONGOMA  NDINDINDI AREA   USUTHU TRIBAL",
    Latitude: "-27.8951",
    Longitude: "31.4897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12170",
    WardLookupId: "1513",
    VDNumber: "43504477",
    RegisteredPopulation: "560",
    VotingStationName: "PHENYANE CRECHE",
    Address: "NONGOMA  PHENYANE AREA    USUTHU TRIBAL",
    Latitude: "-27.9816",
    Longitude: "31.533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12171",
    WardLookupId: "1514",
    VDNumber: "43500833",
    RegisteredPopulation: "790",
    VotingStationName: "NQOKOTHO PRIMARY SCHOOL",
    Address: "NONGOMA  NQOKOTHO AREA  USUTHU TRIBAL",
    Latitude: "-27.8578",
    Longitude: "31.6675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12172",
    WardLookupId: "1514",
    VDNumber: "43503498",
    RegisteredPopulation: "1563",
    VotingStationName: "MLOKOTHWA HIGH SCHOOL",
    Address: "NONGOMA  NHLOPHENKULU AREA   USUTHU TRIBAL",
    Latitude: "-27.8612",
    Longitude: "31.6276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12173",
    WardLookupId: "1514",
    VDNumber: "43530027",
    RegisteredPopulation: "2380",
    VotingStationName: "NKOSIYETHU PRIMARY SCHOOL",
    Address: "NONGOMA  PHALULE AREA   USUTHU  TRIBAL",
    Latitude: "-27.8957",
    Longitude: "31.6417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12174",
    WardLookupId: "1514",
    VDNumber: "43530049",
    RegisteredPopulation: "227",
    VotingStationName: "NEW LIFE SCHOOL",
    Address: "NONGOMA   OPHALULE  AREA  USUTHU TRIBAL",
    Latitude: "-27.8923",
    Longitude: "31.6529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12175",
    WardLookupId: "1515",
    VDNumber: "43500888",
    RegisteredPopulation: "932",
    VotingStationName: "MAGENQEZA PRIMARY  SCHOOL",
    Address: "NONGOMA  BHETHANI  AREA   USUTHU TRIBAL",
    Latitude: "-27.8375",
    Longitude: "31.5921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12176",
    WardLookupId: "1515",
    VDNumber: "43502846",
    RegisteredPopulation: "1008",
    VotingStationName: "QHOQHOZA PRIMARY SCHOOL",
    Address: "QHOQHOZA AREA  VILLAGE  NONGOMA",
    Latitude: "-27.7936",
    Longitude: "31.5796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12177",
    WardLookupId: "1515",
    VDNumber: "43503500",
    RegisteredPopulation: "542",
    VotingStationName: "EFEFE  PRIMARY SCHOOL",
    Address: "NONGOMA  EFEFEF AREA   USUTHU TRIBAL",
    Latitude: "-27.8465",
    Longitude: "31.5448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12178",
    WardLookupId: "1515",
    VDNumber: "43503724",
    RegisteredPopulation: "1114",
    VotingStationName: "MTHONBOWESIZWE HIGH  SCHOOL",
    Address: "NONGOMA   MAJOMELA AREA   USUTHU TRIBAL",
    Latitude: "-27.8339",
    Longitude: "31.5581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12179",
    WardLookupId: "1515",
    VDNumber: "43504242",
    RegisteredPopulation: "753",
    VotingStationName: "NTOMBAZI PRIMARY SCHOOL",
    Address: "NONGOMA   BHUQWINI AREA   USUTHU TRIBAL",
    Latitude: "-27.8506",
    Longitude: "31.5259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12180",
    WardLookupId: "1516",
    VDNumber: "43500912",
    RegisteredPopulation: "638",
    VotingStationName: "KWAMADUMA  PRIMARY  SCHOOL",
    Address: "NONGOMA  MEYAMA RESERVE  MANDLAKAZIO TRIBAL",
    Latitude: "-27.8318",
    Longitude: "31.7185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12181",
    WardLookupId: "1516",
    VDNumber: "43500956",
    RegisteredPopulation: "983",
    VotingStationName: "ENZONDWANE PRIMARY  SCHOOL",
    Address: "NONGOMA   ENZONDWANE  AREA   MANDLAKAZI TRABAL",
    Latitude: "-27.8036",
    Longitude: "31.7309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12182",
    WardLookupId: "1516",
    VDNumber: "43501474",
    RegisteredPopulation: "704",
    VotingStationName: "KITAKITA  HIGH SCHOOL",
    Address: "NONGOMA   NKUNZANA AREA    USUTHU TRIBAL",
    Latitude: "-27.7522",
    Longitude: "31.6994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12183",
    WardLookupId: "1516",
    VDNumber: "43502532",
    RegisteredPopulation: "1005",
    VotingStationName: "MSHANELOWESIZWE HIGH  SCHOOL",
    Address: "NONGOMA  MSHANELO AREA   USUTHU TRIBAL",
    Latitude: "-27.8265",
    Longitude: "31.6393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12184",
    WardLookupId: "1516",
    VDNumber: "43503511",
    RegisteredPopulation: "1634",
    VotingStationName: "BANGAMAYE PRIMARY SCHOOL",
    Address: "NONGOMA  KWA BANGAMAYE AREA  USUTHU TRIBAL",
    Latitude: "-27.7915",
    Longitude: "31.6763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12185",
    WardLookupId: "1516",
    VDNumber: "43504488",
    RegisteredPopulation: "407",
    VotingStationName: "MZWENI  PRIMARY SCHOOL",
    Address: "NONGOMA   EMZWENI AREA   USUTHU TRIBAL",
    Latitude: "-27.8279",
    Longitude: "31.6734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12186",
    WardLookupId: "1517",
    VDNumber: "43530016",
    RegisteredPopulation: "1821",
    VotingStationName: "NONGOMA MUNICIPALITY",
    Address: "LOT 103  MAIN STREET   NONGOMA  TOWN  USUTHU TRIBAL",
    Latitude: "-27.9063",
    Longitude: "31.6465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12187",
    WardLookupId: "1517",
    VDNumber: "43530038",
    RegisteredPopulation: "890",
    VotingStationName: "NONGOMA PRIMARY SCHOOL",
    Address: "LOT 102 MAIN STREET  NONGOMA   USUTHU TRIBAL",
    Latitude: "-27.9032",
    Longitude: "31.6457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12188",
    WardLookupId: "1518",
    VDNumber: "43500844",
    RegisteredPopulation: "757",
    VotingStationName: "NQABAYEMBUBE HIGH SCHOOL",
    Address: "NONGOMA  KWAVILANE AREA   MANDLAKAZI TRIBAL",
    Latitude: "-27.8705",
    Longitude: "31.7035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12189",
    WardLookupId: "1518",
    VDNumber: "43500855",
    RegisteredPopulation: "1022",
    VotingStationName: "NGXONGWANE  PRIMARY SCHOOL",
    Address: "NONGOMA  EKUBUNGAZELENI AREA  MANDLAKAZI TRIBAL",
    Latitude: "-27.8795",
    Longitude: "31.7747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12190",
    WardLookupId: "1518",
    VDNumber: "43502778",
    RegisteredPopulation: "1039",
    VotingStationName: "MANZIMAKHULU  PRIMARY SCHOOL",
    Address: "MANDLAKAZI TRIBAL AUTHORITY  MANZIMAKHULU AREA   USUTHU  TRIBAL",
    Latitude: "-27.888",
    Longitude: "31.6778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12191",
    WardLookupId: "1518",
    VDNumber: "43503432",
    RegisteredPopulation: "462",
    VotingStationName: "BUKHALINI PRIMARY SCHOOL",
    Address: "NONGOMA   KWA SEME AREA   NONGOMA, MANDLAKAZI TRIBAL",
    Latitude: "-27.8987",
    Longitude: "31.7228",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12192",
    WardLookupId: "1518",
    VDNumber: "43503443",
    RegisteredPopulation: "646",
    VotingStationName: "NKABANE  PRIMARY SCHOOL",
    Address: "NONGOMA   NKABANE AREA  MANDLAKAZI TRIBAL",
    Latitude: "-27.8661",
    Longitude: "31.7384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12193",
    WardLookupId: "1518",
    VDNumber: "43504231",
    RegisteredPopulation: "742",
    VotingStationName: "NGETHULE PRIMARY",
    Address: "NONGOMA  WHITE CITY   USUTHU TRIBAL",
    Latitude: "-27.9107",
    Longitude: "31.6666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12194",
    WardLookupId: "1519",
    VDNumber: "43500125",
    RegisteredPopulation: "910",
    VotingStationName: "KWA-MINYA PRIMARY SCHOOL",
    Address: "NONGOMA  KWA-MINYA AREA, NEAR VOKWANE DAM   USUTHU TRIBAL",
    Latitude: "-27.9226",
    Longitude: "31.6117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12195",
    WardLookupId: "1519",
    VDNumber: "43500811",
    RegisteredPopulation: "1095",
    VotingStationName: "PHUMZUZULU  HIGH SCHOOL",
    Address: "NONGOMA   MAHHASHINI AREA   USUTHU TRIBAL",
    Latitude: "-27.879",
    Longitude: "31.5312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12196",
    WardLookupId: "1519",
    VDNumber: "43500822",
    RegisteredPopulation: "898",
    VotingStationName: "ESUSWENI  PRIMARY SCHOOL",
    Address: "NONGOMA  MANGAMHLOPHE AREA   USUTHU TRIBAL",
    Latitude: "-27.8764",
    Longitude: "31.5949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12197",
    WardLookupId: "1519",
    VDNumber: "43501452",
    RegisteredPopulation: "674",
    VotingStationName: "NOMZINTO HIGH SCHOOL",
    Address: "NONGOMA  MPUNZANA AREA   USUTHU TRIBAL",
    Latitude: "-27.9191",
    Longitude: "31.5665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12198",
    WardLookupId: "1519",
    VDNumber: "43504499",
    RegisteredPopulation: "896",
    VotingStationName: "MAHHASHINI CRECHE",
    Address: "NONGOMA  MAYENI AREA     USUTHU TRIBAL",
    Latitude: "-27.8601",
    Longitude: "31.5119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12199",
    WardLookupId: "1520",
    VDNumber: "43500620",
    RegisteredPopulation: "536",
    VotingStationName: "SIZANA PRIMARY SCHOOL",
    Address: "CEZA NEXT TO SIZANA CLINIC  MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-27.9272",
    Longitude: "31.4066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12200",
    WardLookupId: "1520",
    VDNumber: "43500642",
    RegisteredPopulation: "945",
    VotingStationName: "MDUMELA HIGH  SCHOOL",
    Address: "EKUSHUMAYELENI  NEXT TO ENYOKENI  MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-27.9616",
    Longitude: "31.4554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12201",
    WardLookupId: "1520",
    VDNumber: "43500675",
    RegisteredPopulation: "515",
    VotingStationName: "ESIKHWEBEZANE PRIMARY  SCHOOL",
    Address: "NEXT TO DLAMINI STORE  MAHLABATHINI   BUTHELEZI T/A",
    Latitude: "-27.9155",
    Longitude: "31.4414",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12202",
    WardLookupId: "1520",
    VDNumber: "43500709",
    RegisteredPopulation: "594",
    VotingStationName: "PHILIBANE PRIMARY SCHOOL",
    Address: "MANTUNGWINI AREA  CENCETHWINI AREA   MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-27.9494",
    Longitude: "31.4761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12203",
    WardLookupId: "1520",
    VDNumber: "43500710",
    RegisteredPopulation: "373",
    VotingStationName: "EKWANDENI PRIMARY SCHOOL",
    Address:
      "EMANTUNGWINI AREA  NEXT TO NHLANHLENI HALL  MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-27.9174",
    Longitude: "31.4811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12204",
    WardLookupId: "1520",
    VDNumber: "43502688",
    RegisteredPopulation: "376",
    VotingStationName: "NINGIZIMU PRIMARY SCHOOL",
    Address:
      "EMAKLOZENI AREA  NEXT TO SIYABONGA STORE  MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-27.8794",
    Longitude: "31.4822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12205",
    WardLookupId: "1520",
    VDNumber: "43503072",
    RegisteredPopulation: "566",
    VotingStationName: "MKHOMBE HIGH SCHOOL",
    Address: "ENYOKENI  OMBIMBINI AREA  MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-27.8949",
    Longitude: "31.4569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12206",
    WardLookupId: "1521",
    VDNumber: "43500631",
    RegisteredPopulation: "880",
    VotingStationName: "HLANGANAKAHLE PRIMARY SCHOOL",
    Address: "NONDAYANE  EZEMBENI AREA  MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-28.0024",
    Longitude: "31.4028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12207",
    WardLookupId: "1521",
    VDNumber: "43500653",
    RegisteredPopulation: "783",
    VotingStationName: "MHONGOZINI PRIMARY SCHOOL",
    Address: "MHONGOZINI AREA  MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-28.0196",
    Longitude: "31.4362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12208",
    WardLookupId: "1521",
    VDNumber: "43503094",
    RegisteredPopulation: "758",
    VotingStationName: "NGOZA PRIMARY  SCHOOL",
    Address: "OSUTHU EKUSHUMAYELENI AREA   MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-27.9999",
    Longitude: "31.4845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12209",
    WardLookupId: "1521",
    VDNumber: "43503106",
    RegisteredPopulation: "938",
    VotingStationName: "MHLAMBANSILA HIGH  SCHOOL",
    Address: "CEZA NEXTTO BRUSH STORE  MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-27.9691",
    Longitude: "31.4063",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12210",
    WardLookupId: "1521",
    VDNumber: "43504567",
    RegisteredPopulation: "453",
    VotingStationName: "MVULELENI PRIMARY SCHOOL",
    Address: "NONDAYANA  MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-28.0323",
    Longitude: "31.4566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12211",
    WardLookupId: "1521",
    VDNumber: "43505120",
    RegisteredPopulation: "292",
    VotingStationName: "NOMPONJWANA CRECHE",
    Address: "SWELANKOMO AREA  ULUNDI  BUTHELEZI T/A",
    Latitude: "-27.9901",
    Longitude: "31.4542",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12212",
    WardLookupId: "1521",
    VDNumber: "43505142",
    RegisteredPopulation: "294",
    VotingStationName: "MNTEYITHUME  CRECHE",
    Address: "OPHISWENI AREA  ULUNDI  BUTHELEZI T/A",
    Latitude: "-27.974",
    Longitude: "31.4689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12213",
    WardLookupId: "1522",
    VDNumber: "43500686",
    RegisteredPopulation: "698",
    VotingStationName: "NSUKANGIHLALE  PRIMARY SCHOOL",
    Address: "CEZA  NEXT TO THULASIZWE HOSPITAL  MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-27.9617",
    Longitude: "31.3923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12214",
    WardLookupId: "1522",
    VDNumber: "43502745",
    RegisteredPopulation: "634",
    VotingStationName: "QUNGISIBINDI CRECHE",
    Address: "CEZA EGAZINI  NEXT TO CEZA HOSPITAL   MAHLABATHINI  NDEBELE T/A",
    Latitude: "-27.9865",
    Longitude: "31.368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12215",
    WardLookupId: "1522",
    VDNumber: "43503083",
    RegisteredPopulation: "737",
    VotingStationName: "ZAKHELENI PRIMARY SCHOOL",
    Address: "CEZA SIZANA NEXT TO SIZANA CLINIC  MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-27.9345",
    Longitude: "31.4062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12216",
    WardLookupId: "1522",
    VDNumber: "43503117",
    RegisteredPopulation: "743",
    VotingStationName: "INDLUYAMANDLA PRIMARY SCHOOL",
    Address:
      "CEZA EGAZINI  NEXT TO THULASIZWE HOSPITAL  MAHLABATHINI  NDEBELE T/A",
    Latitude: "-27.9571",
    Longitude: "31.3667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12217",
    WardLookupId: "1522",
    VDNumber: "43504578",
    RegisteredPopulation: "761",
    VotingStationName: "NQABAYOKUPHILA CRECHE",
    Address:
      "CEZA EGAZINI NEXT TO NCEDAMACEBO HALL  MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-27.9732",
    Longitude: "31.3672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12218",
    WardLookupId: "1523",
    VDNumber: "43500462",
    RegisteredPopulation: "604",
    VotingStationName: "PRINCE THEMBITSHE PRIMARY SCHOOL",
    Address: "STEDHAM  NEXT TO STEDHAM CLINIC  MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-28.0628",
    Longitude: "31.3029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12219",
    WardLookupId: "1523",
    VDNumber: "43500484",
    RegisteredPopulation: "1110",
    VotingStationName: "PHOPHOMENI PRIMARY SCHOOL",
    Address:
      "CEZA ESIDAKENI AREA NEXT TO SIDAKENI CLINIC  MAHLABATHNI  NDEBELE T/A",
    Latitude: "-28.0141",
    Longitude: "31.3446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12220",
    WardLookupId: "1523",
    VDNumber: "43500619",
    RegisteredPopulation: "1037",
    VotingStationName: "CEZA PRIMARY SCHOOL",
    Address: "CEZA  ULUNDI  BUTHELEZI T/A",
    Latitude: "-27.9975",
    Longitude: "31.3738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12221",
    WardLookupId: "1523",
    VDNumber: "43501294",
    RegisteredPopulation: "396",
    VotingStationName: "MAPHOVELA HIGH SCHOOL",
    Address: "CEZA OGEDLENI AREA  NEXT TO STEDHAM  MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-28.0489",
    Longitude: "31.3142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12222",
    WardLookupId: "1523",
    VDNumber: "43502509",
    RegisteredPopulation: "447",
    VotingStationName: "NDEBELE TRIBAL COURT",
    Address: "MLOVU RESERVE  MAHLABATHINI  NDEBELE T/A",
    Latitude: "-28.068",
    Longitude: "31.3486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12223",
    WardLookupId: "1523",
    VDNumber: "43504332",
    RegisteredPopulation: "417",
    VotingStationName: "NGONGOMA PRIMARY SCHOOL",
    Address:
      "CEZA OGEDLENI  NEXT TO SINQOBILE STORE  MAHLABATHINI  NDEBELE T/A",
    Latitude: "-28.0393",
    Longitude: "31.3538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12224",
    WardLookupId: "1523",
    VDNumber: "43504589",
    RegisteredPopulation: "245",
    VotingStationName: "EMBUKISWENI PRIMARY SCHOOL",
    Address:
      "CEZA EMBUKISWENI AREA  NEXT TO CEZA HOSPITAL  HLABATHINI  NDEBELE T/A",
    Latitude: "-27.9902",
    Longitude: "31.3574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12225",
    WardLookupId: "1524",
    VDNumber: "43500451",
    RegisteredPopulation: "589",
    VotingStationName: "MFABENI PRIMARY SCHOOL",
    Address:
      "MFABENI EDULINI AREA  NEXT TO MFABENI STORE  MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-28.0987",
    Longitude: "31.4466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12226",
    WardLookupId: "1524",
    VDNumber: "43500507",
    RegisteredPopulation: "687",
    VotingStationName: "NSABEKHULUMA HIGH SCHOOL",
    Address:
      "NSABEKHULUMA ESIBOMVU  OKHALWENI AREA  MAHLABATHINI  BUTHELZI T/A",
    Latitude: "-28.0736",
    Longitude: "31.4621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12227",
    WardLookupId: "1524",
    VDNumber: "43502712",
    RegisteredPopulation: "687",
    VotingStationName: "SAWOTI PRIMARY SCHOOL",
    Address:
      "DAMBA AREA  NEXT TO MAGAGADOLO CLINIC  MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-28.0318",
    Longitude: "31.4072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12228",
    WardLookupId: "1524",
    VDNumber: "43503757",
    RegisteredPopulation: "548",
    VotingStationName: "GWAZINYATHI PRIMARY SCHOOL",
    Address:
      "CEZA ROAD QHUDEBE AREA  NEXT TO PHANGODE STORE  MAHLABATHINI  BUTHELZI T/A",
    Latitude: "-28.0894",
    Longitude: "31.4766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12229",
    WardLookupId: "1524",
    VDNumber: "43504590",
    RegisteredPopulation: "485",
    VotingStationName: "OZWELENI PRIMARY  SCHOOL",
    Address:
      "CEZA ROAD EZIMFABENI NEXT TO MPANZA STATION  MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-28.0683",
    Longitude: "31.447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12230",
    WardLookupId: "1524",
    VDNumber: "43504602",
    RegisteredPopulation: "712",
    VotingStationName: "TSHANIBEZWE HIGH SCHOOL",
    Address: "EZIMFABENI AREA  MAHLABATHINI  BUTHELEZI TRIBAL AUTHORITY",
    Latitude: "-28.1073",
    Longitude: "31.4249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12231",
    WardLookupId: "1524",
    VDNumber: "43505164",
    RegisteredPopulation: "329",
    VotingStationName: "THOLUKUKHANYA CRECHE",
    Address: "KWAMEKE AREA  MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-28.1165",
    Longitude: "31.4967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12232",
    WardLookupId: "1525",
    VDNumber: "43500428",
    RegisteredPopulation: "965",
    VotingStationName: "IDLEBE PRIMARY SCHOOL",
    Address: "IDLEBE  ULUNDI  BUTHELEZI T/A",
    Latitude: "-28.1331",
    Longitude: "31.3452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12233",
    WardLookupId: "1525",
    VDNumber: "43500439",
    RegisteredPopulation: "1197",
    VotingStationName: "MWELASE PRIMARY SCHOOL",
    Address: "IDLEBE  ULUNDI  BUTHELEZI T/A",
    Latitude: "-28.105",
    Longitude: "31.3289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12234",
    WardLookupId: "1525",
    VDNumber: "43500495",
    RegisteredPopulation: "929",
    VotingStationName: "MASHIYANE PRIMARY SCHOOL",
    Address: "DLABANE  ULUNDI  BUTHELEZI T/A",
    Latitude: "-28.0372",
    Longitude: "31.3826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12235",
    WardLookupId: "1525",
    VDNumber: "43502701",
    RegisteredPopulation: "394",
    VotingStationName: "MABEKA PRIMARY SCHOOL",
    Address: "MABEKA AREA  MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-28.0878",
    Longitude: "31.3869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12236",
    WardLookupId: "1525",
    VDNumber: "43503623",
    RegisteredPopulation: "578",
    VotingStationName: "MFEKAZI CRECHE",
    Address: "MFEKAZI  MAHLABATHINI  BUTHELEZI TRIBAL AUTHORITY",
    Latitude: "-28.0836",
    Longitude: "31.4058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12237",
    WardLookupId: "1526",
    VDNumber: "43500372",
    RegisteredPopulation: "475",
    VotingStationName: "XASANA PRIMARY SCHOOL",
    Address: "XASANA AREA   MAHLABATHINI  ZUNGU T/A",
    Latitude: "-28.1536",
    Longitude: "31.583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12238",
    WardLookupId: "1526",
    VDNumber: "43500383",
    RegisteredPopulation: "466",
    VotingStationName: "ZINQUNYANENI PRIMARY SCHOOL",
    Address: "ZINQUNYANENI AREA  MAHLABATHINI  ZUNGU T/A",
    Latitude: "-28.1311",
    Longitude: "31.5848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12239",
    WardLookupId: "1526",
    VDNumber: "43500394",
    RegisteredPopulation: "958",
    VotingStationName: "KWAMAME PRIMARY SCHOOL",
    Address: "KWAMAME  ULUNDI  BUTHELZI T/A",
    Latitude: "-28.1422",
    Longitude: "31.4894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12240",
    WardLookupId: "1526",
    VDNumber: "43502420",
    RegisteredPopulation: "777",
    VotingStationName: "MQINISENI HIGH SCHOOL",
    Address:
      "MASHONA ROAD EXOLO NEXT TO MDLETSHE STORE   MAHLABATHINI  ZUNGU T/A",
    Latitude: "-28.1771",
    Longitude: "31.5453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12241",
    WardLookupId: "1526",
    VDNumber: "43503128",
    RegisteredPopulation: "596",
    VotingStationName: "BHOKWENI STORE",
    Address:
      "MASHONA ROAD OBHOKWENI AREA NEXT TO BHOKWENI SCHOOL  MAHLABATHINI  ZUNGU T/A",
    Latitude: "-28.1504",
    Longitude: "31.5164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12242",
    WardLookupId: "1526",
    VDNumber: "43503599",
    RegisteredPopulation: "463",
    VotingStationName: "ISIPHIVA PRIMARY SCHOOL",
    Address: "ISIPHIVA AREA  OKHUKHO AREA  ZUNGU T/A",
    Latitude: "-28.1744",
    Longitude: "31.6315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12243",
    WardLookupId: "1527",
    VDNumber: "43500305",
    RegisteredPopulation: "234",
    VotingStationName: "MNQAKWE  PRIMARY SCHOOL",
    Address:
      "MASHONA ROAD MNQAKWE AREA  NEXT TO MABHIDA STORE  MAHLABATHINI  ZUNGU T/A",
    Latitude: "-28.1858",
    Longitude: "31.6069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12244",
    WardLookupId: "1527",
    VDNumber: "43500350",
    RegisteredPopulation: "751",
    VotingStationName: "JIKAZA PRIMARY SCHOOL",
    Address: "JIKAZA AREA  MAHLABATHINI  ZUNGU T/A",
    Latitude: "-28.2017",
    Longitude: "31.4681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12245",
    WardLookupId: "1527",
    VDNumber: "43500361",
    RegisteredPopulation: "580",
    VotingStationName: "EWELA PRIMARY SCHOOL",
    Address: "EWELA AREA  MAHLABATHINI  ZUNGU T/A",
    Latitude: "-28.2356",
    Longitude: "31.5368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12246",
    WardLookupId: "1527",
    VDNumber: "43503139",
    RegisteredPopulation: "408",
    VotingStationName: "VUTHELA PRIMARY SCHOOL",
    Address:
      "MASHONA ROAD KWAVUTHELA  NEXT TO QALASI STORE  MAHLABATHINI  ZUNGU T/A",
    Latitude: "-28.2097",
    Longitude: "31.4971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12247",
    WardLookupId: "1527",
    VDNumber: "43503140",
    RegisteredPopulation: "1119",
    VotingStationName: "MALIYAMAKHANDA PRIMARY SCHOOL",
    Address: "MASHONA  ULUNDI  BUTHELEZI T/A",
    Latitude: "-28.2268",
    Longitude: "31.4709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12248",
    WardLookupId: "1527",
    VDNumber: "43504309",
    RegisteredPopulation: "258",
    VotingStationName: "TSHENDLOVU PRIMARY SCHOOL",
    Address: "MGANIMBOBO  MAHLABATHINI  ZUNGU TRIBAL AUTHORITY",
    Latitude: "-28.2199",
    Longitude: "31.5803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12249",
    WardLookupId: "1527",
    VDNumber: "43504613",
    RegisteredPopulation: "236",
    VotingStationName: "HLANGANANI PRIMARY SCHOOL",
    Address: "HLANGANANI  MAHLABATHINI  ZUNGU T/A",
    Latitude: "-28.1705",
    Longitude: "31.5001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12250",
    WardLookupId: "1527",
    VDNumber: "43504624",
    RegisteredPopulation: "308",
    VotingStationName: "BHOKWENI PRIMARY  SCHOOL",
    Address: "BHOKWENI  MAHLABATHINI  ZUNGU T/A",
    Latitude: "-28.1863",
    Longitude: "31.5369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12251",
    WardLookupId: "1528",
    VDNumber: "43500282",
    RegisteredPopulation: "1178",
    VotingStationName: "ENKONJENI COMBINED PRIMARY SCHOOL",
    Address: "ENKONJENI  MAHLABATHINI  MBATHA T/A",
    Latitude: "-28.2419",
    Longitude: "31.3867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12252",
    WardLookupId: "1528",
    VDNumber: "43500338",
    RegisteredPopulation: "911",
    VotingStationName: "EKUDUBEKENI HIGH SCHOOL",
    Address: "KONJENI (NEXT TO NKONJENI HOSP  MAHLABATHINI  ULUNDI",
    Latitude: "-28.2342",
    Longitude: "31.4124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12253",
    WardLookupId: "1528",
    VDNumber: "43503151",
    RegisteredPopulation: "762",
    VotingStationName: "BUTHELEZI TRADITIONAL COURT",
    Address:
      "ENKONJENI(NEXT TO PHINDANGENE)  NKONJENI/MAHLABATHINI  ULUNDI/BUTHELEZI T/A",
    Latitude: "-28.2175",
    Longitude: "31.4167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12254",
    WardLookupId: "1528",
    VDNumber: "43503162",
    RegisteredPopulation: "574",
    VotingStationName: "NZIMAKAZI CRECHE",
    Address: "NKONJENI  MAHLABATHINI  ULUNDI",
    Latitude: "-28.2395",
    Longitude: "31.3966",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12255",
    WardLookupId: "1528",
    VDNumber: "43504635",
    RegisteredPopulation: "503",
    VotingStationName: "MBULULISA CRECHE",
    Address: "NKONJENI  MAHLABATHINI  MBATHA TRIBAL AUTHORITY",
    Latitude: "-28.2374",
    Longitude: "31.3677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12256",
    WardLookupId: "1528",
    VDNumber: "43504646",
    RegisteredPopulation: "446",
    VotingStationName: "KWAZIKHONELE PRIMARY SCHOOL",
    Address: "NKONJENI/ZILULWANE  ZULULAND  ULUNDI/BUTHELEZI T/A",
    Latitude: "-28.2328",
    Longitude: "31.436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12257",
    WardLookupId: "1529",
    VDNumber: "43500327",
    RegisteredPopulation: "630",
    VotingStationName: "BHAQA PRIMARY SCHOOL",
    Address: "MHLAHLANE/VUKA AREA       MAHLABATHINI  ULUNDI/MBATHA T/A",
    Latitude: "-28.1952",
    Longitude: "31.3366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12258",
    WardLookupId: "1529",
    VDNumber: "43500349",
    RegisteredPopulation: "719",
    VotingStationName: "MADAKA PRIMARY  SCHOOL",
    Address: "MADAKA AREA   MAHLABATHINI  BUTHELEZI T/A",
    Latitude: "-28.1827",
    Longitude: "31.441",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12259",
    WardLookupId: "1529",
    VDNumber: "43500406",
    RegisteredPopulation: "475",
    VotingStationName: "THAPHOKUHLE PRIMARY SCHOOL",
    Address: "MHLAHLANE(UPPER MHLAHLANE)  MAHLABATHINI  ULUNDI/MBATHA T/A",
    Latitude: "-28.1809",
    Longitude: "31.318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12260",
    WardLookupId: "1529",
    VDNumber: "43500417",
    RegisteredPopulation: "165",
    VotingStationName: "ZIHLALO PRIMARY SCHOOL",
    Address: "MAHLABATHINI/ZIHLALO AREA  MAHLABATHINI  ULUNDI BUTHELEZI T/A",
    Latitude: "-28.1765",
    Longitude: "31.3978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12261",
    WardLookupId: "1529",
    VDNumber: "43502442",
    RegisteredPopulation: "776",
    VotingStationName: "SICOCO LOWER PRIMARY SCHOOL",
    Address:
      "NKONJENI/KWANOMDIYA AREA  MBATHA TRIBAL AUTHORITY  ULUNDI/BUTHELEZI T/A",
    Latitude: "-28.2292",
    Longitude: "31.3798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12262",
    WardLookupId: "1529",
    VDNumber: "43503173",
    RegisteredPopulation: "331",
    VotingStationName: "OTHINI CHRECHE",
    Address: "ILEBE/OHINI  IDLEBE/MAHLABATHINI  ULUNDI/BUTHELEZI T/A",
    Latitude: "-28.1305",
    Longitude: "31.3806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12263",
    WardLookupId: "1529",
    VDNumber: "43503184",
    RegisteredPopulation: "699",
    VotingStationName: "GOQO HALL",
    Address: "IDLEBE AREA  MAHLABATHINI  BUTHELEZI TRIBAL AUTHORITY",
    Latitude: "-28.1674",
    Longitude: "31.3604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12264",
    WardLookupId: "1529",
    VDNumber: "43503634",
    RegisteredPopulation: "241",
    VotingStationName: "LETHITHENBA PRIMARY SCHOOL",
    Address:
      "LANGAKAZI/LANGAKAZI AREA  LANGAKAZI/MAHLABATHINI  ULUNDI/BUTHELEZI T/A",
    Latitude: "-28.1548",
    Longitude: "31.4244",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12265",
    WardLookupId: "1530",
    VDNumber: "43500260",
    RegisteredPopulation: "676",
    VotingStationName: "MPUNGOSE TRIBAL COURT",
    Address: "NTENDEKA ROAD/NTENDEKA  NTENDEKA/ULUNDI  ULUNDI/MPUNGOSE T/A",
    Latitude: "-28.27",
    Longitude: "31.4171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12266",
    WardLookupId: "1530",
    VDNumber: "43500293",
    RegisteredPopulation: "1947",
    VotingStationName: "MAHLABATHINI HIGH SCHOOL",
    Address: "KING DINIZULU HIGHWAY  KWASISHWILI  ULUNDI",
    Latitude: "-28.2627",
    Longitude: "31.444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12267",
    WardLookupId: "1530",
    VDNumber: "43503768",
    RegisteredPopulation: "460",
    VotingStationName: "THOLULWAZI CRECHE",
    Address: "NKONJENI/KWA-NCWANE  NKONJENI/MAHLABATHINI  ULUNDI/MBATHA T/A",
    Latitude: "-28.2403",
    Longitude: "31.4153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12268",
    WardLookupId: "1530",
    VDNumber: "43504310",
    RegisteredPopulation: "160",
    VotingStationName: "MHLABAMUNI PRIMARY SCHOOL",
    Address:
      "NKONJENI/MPOLWENI AREA  NKONJENI/ MAHLABATHINI  ULUNDI/MBATHA T/A",
    Latitude: "-28.2637",
    Longitude: "31.3715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12269",
    WardLookupId: "1530",
    VDNumber: "43504657",
    RegisteredPopulation: "501",
    VotingStationName: "INDIPENDENT BAPTIST CHURCH",
    Address:
      "GRAVEL ROAD BEHIND D SEC  MAHLABATHINI  MPUNGOSE TRIBAL AUTHORITY",
    Latitude: "-28.2772",
    Longitude: "31.4461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12270",
    WardLookupId: "1531",
    VDNumber: "43504321",
    RegisteredPopulation: "1073",
    VotingStationName: "SIKHALELUMUZI PRIMARY SCHOOL",
    Address:
      "MBILANE/EKAMU(NEXT TO TANKS)  MBILANE/MAHLABATHINI  ULUNDI/MPUNGOSE T/A",
    Latitude: "-28.267",
    Longitude: "31.4274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12271",
    WardLookupId: "1531",
    VDNumber: "43560042",
    RegisteredPopulation: "1886",
    VotingStationName: "PRINCE DABULAMANZI PRIMARY  SCHOOL",
    Address:
      "1183 KING ZWELITHINI STR./C-SECTION  C/SECTION/ULUNDI  ULUNDI/MPUNGOSE T/A",
    Latitude: "-28.2885",
    Longitude: "31.4291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12272",
    WardLookupId: "1531",
    VDNumber: "43560086",
    RegisteredPopulation: "577",
    VotingStationName: "ULUNDI PRIVATE SCHOOL",
    Address: "MBELEBELENI/ B-NORTH  B-NORTH/ULUNDI  ULUNDI/MPUNGOSE T/A",
    Latitude: "-28.2843",
    Longitude: "31.4235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12273",
    WardLookupId: "1531",
    VDNumber: "43560109",
    RegisteredPopulation: "538",
    VotingStationName: "INQOLOBANE COMPUTER CENTRE",
    Address: "MKHAZANE ST./ B-NORTH  ULUNDI  ULUNDIMPUNGOSE T/A",
    Latitude: "-28.2999",
    Longitude: "31.4173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12274",
    WardLookupId: "1532",
    VDNumber: "43500248",
    RegisteredPopulation: "922",
    VotingStationName: "MPUNGAMHLOPHE HIGH SCHOOL",
    Address:
      "MPUNGAMHLOPHE/DANNY DALTON  DENNY DALTON/BABANANGO  ULUNDI/NOBAMBA T/A",
    Latitude: "-28.2674",
    Longitude: "31.2551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12275",
    WardLookupId: "1532",
    VDNumber: "43501036",
    RegisteredPopulation: "1137",
    VotingStationName: "NHLAZATSHE FARMERS HALL",
    Address:
      "NHLAZATSHE(NHLAZATSHE STORE)  NHLAZATSHE/MAHLABATHINI  ULUNDIKWANSIMBI T/A",
    Latitude: "-28.181",
    Longitude: "31.2512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12276",
    WardLookupId: "1532",
    VDNumber: "43501047",
    RegisteredPopulation: "425",
    VotingStationName: "SITHOLINHLANHLA HIGH SCHOOL",
    Address:
      "NHLAZATSHE/MISSION  NHLAZATSHE/MAHLABATHINI  ULUNDI/KWANSIMBI T/A",
    Latitude: "-28.1645",
    Longitude: "31.2284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12277",
    WardLookupId: "1532",
    VDNumber: "43501058",
    RegisteredPopulation: "505",
    VotingStationName: "KONFOOR COMBINED SCHOOL",
    Address:
      "VRYHEID RD(THANDABANTU STORE)  GLUCKSTADT/VRYHEID  VRYHEID/KWANSIMBI T/A",
    Latitude: "-28.0625",
    Longitude: "31.047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12278",
    WardLookupId: "1532",
    VDNumber: "43504668",
    RegisteredPopulation: "469",
    VotingStationName: "NKANYISO CRECHE",
    Address: "MPUNGAMHLOPHE  NOBAMBA TRIBAL COURT  ULUNDI",
    Latitude: "-28.2558",
    Longitude: "31.2401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12279",
    WardLookupId: "1532",
    VDNumber: "43504679",
    RegisteredPopulation: "169",
    VotingStationName: "SIZANEMPI PRIMARY SCHOOL",
    Address:
      "R66 TURN RIGHT TO KWAGADE       NHLAZATSHE/MAHLABATHINI  ULUNDI/KWANSIMBI T/A",
    Latitude: "-28.2262",
    Longitude: "31.2186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12280",
    WardLookupId: "1532",
    VDNumber: "43504680",
    RegisteredPopulation: "223",
    VotingStationName: "LETHUKUKHANYA PRIMARY SCHOOL",
    Address:
      "IDLEBE/TURN LEFT TO MASHUDU     NHLAZATSHE/MAHLABATHINI  ULUNDIKWANSIMBI T/A",
    Latitude: "-28.1419",
    Longitude: "31.3051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12281",
    WardLookupId: "1532",
    VDNumber: "43624818",
    RegisteredPopulation: "374",
    VotingStationName: "ERASMUSFONTEIN HIGH SCHOOL",
    Address: "NHLAZATSHE ROAD  ULUNDI  KWA NSIMBI TRIBAL AUTHORITY",
    Latitude: "-28.1437",
    Longitude: "31.128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12282",
    WardLookupId: "1533",
    VDNumber: "43500147",
    RegisteredPopulation: "642",
    VotingStationName: "KWANYONI PRIMARY SCHOOL",
    Address:
      "OKHUKHO RD/NEXT TO NTABANKULU  KWANYONI/ MAHLABATHINI  ULUNDI/XIMBA T/A",
    Latitude: "-28.297",
    Longitude: "31.5935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12283",
    WardLookupId: "1533",
    VDNumber: "43502464",
    RegisteredPopulation: "763",
    VotingStationName: "NJOMELWANE PRIMARY  SCHOOL",
    Address:
      "OKHUKHO RD/NJOMELWANA AREA  NHLUNGWANE/MAHLABATHINI  ULUNDI/XIMBA T/A",
    Latitude: "-28.3664",
    Longitude: "31.6283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12284",
    WardLookupId: "1533",
    VDNumber: "43502622",
    RegisteredPopulation: "366",
    VotingStationName: "SIBONGAKONKE CRECHE",
    Address:
      "EWELA/(NEXT TO MBANDA STORE)  MBANDA/MAHLABATHINI  ULUND/ZUNGU/T/A",
    Latitude: "-28.2417",
    Longitude: "31.5788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12285",
    WardLookupId: "1533",
    VDNumber: "43503218",
    RegisteredPopulation: "451",
    VotingStationName: "HLUSHWANA PRIMARY SCHOOL",
    Address:
      "OKHUKHO RD/HLUSHWANA AREA  HLUSHWANA/MAHLABATHINI  ULUNDI/XIMBA T/A",
    Latitude: "-28.3089",
    Longitude: "31.6561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12286",
    WardLookupId: "1533",
    VDNumber: "43503229",
    RegisteredPopulation: "537",
    VotingStationName: "NTILINGWE PRIMARY SCHOOL",
    Address:
      "OKHUKHO RD/(NEXT TO XIMBA T/A   NHLUNGWANE/MAHLABATHINI  ULUNDIXIMBA T/A",
    Latitude: "-28.3135",
    Longitude: "31.554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12287",
    WardLookupId: "1533",
    VDNumber: "43503612",
    RegisteredPopulation: "528",
    VotingStationName: "KWANJOJO CRECHE",
    Address: "EWELA RD(NJOJO AREA)   EWELA/MAHLABATHINI  ULUNDI/ZUNGU T/A",
    Latitude: "-28.247",
    Longitude: "31.5564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12288",
    WardLookupId: "1533",
    VDNumber: "43504758",
    RegisteredPopulation: "646",
    VotingStationName: "XIMBA TRIBAL COURT",
    Address: "OKHUKHO RD/XIMBA T/C  NHLUNGWANE /MAHLABATHINI  ULUNDI/XIMBA T/A",
    Latitude: "-28.3056",
    Longitude: "31.5418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12289",
    WardLookupId: "1533",
    VDNumber: "43504769",
    RegisteredPopulation: "412",
    VotingStationName: "NHLUNGWANE HIGH SCHOOL",
    Address:
      "OKHUKHO RD/KWAZILULWANA AREA  NHLUNGWANE/ MAHLABATHINI  ULUNDI/XIMBA T/A",
    Latitude: "-28.3497",
    Longitude: "31.6335",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12290",
    WardLookupId: "1534",
    VDNumber: "43500136",
    RegisteredPopulation: "1399",
    VotingStationName: "NQULWANE PRIMARY SCHOOL",
    Address: "NQULWANE  MAHLABATHINI  MLABA",
    Latitude: "-28.3368",
    Longitude: "31.6636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12291",
    WardLookupId: "1534",
    VDNumber: "43500158",
    RegisteredPopulation: "851",
    VotingStationName: "OKHUKHO HIGHER PRIMARY SCHOOL",
    Address: "OKHUKHO MINE RD/OKHUKHO  OKHUKHO/MAHLABATHINI  ULUNDI/ZUNGU T/A",
    Latitude: "-28.2447",
    Longitude: "31.6712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12292",
    WardLookupId: "1534",
    VDNumber: "43500530",
    RegisteredPopulation: "740",
    VotingStationName: "MHAWUKELWA COMBINED PRIMARY SCHOOL",
    Address: "OKHUKHO/ELOMO AREA  OKHUKHO/MAHLABATHINI  ULUNDI/ZUNGU T/A",
    Latitude: "-28.2244",
    Longitude: "31.6305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12293",
    WardLookupId: "1534",
    VDNumber: "43503230",
    RegisteredPopulation: "529",
    VotingStationName: "MFANAWENDLELA PRIMARY SCHOOL",
    Address:
      "OKHUKHORD/EMAKHALATHINI AREA  OKHUKHO/MAHLABATHINI  ULUNDIZUNGU T/A",
    Latitude: "-28.189",
    Longitude: "31.6868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12294",
    WardLookupId: "1534",
    VDNumber: "43503746",
    RegisteredPopulation: "362",
    VotingStationName: "NGQOLOTHI PRIMARY  SCHOOL",
    Address:
      "OKHUKHO RD(NEXT TO COOLSTATION  NHLUNGWANE/ MAHLABATHINI  ULUNDI/XIMBA T/A",
    Latitude: "-28.3718",
    Longitude: "31.6509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12295",
    WardLookupId: "1534",
    VDNumber: "43504163",
    RegisteredPopulation: "772",
    VotingStationName: "GCINUXOLO PRIMARY SCHOOL",
    Address:
      "OKHUKHO MINE RD/EMAROMENI  OKHUKHO/MAHLABATHINI  ULUNDI/ZUNGU T/A",
    Latitude: "-28.2628",
    Longitude: "31.6793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12296",
    WardLookupId: "1535",
    VDNumber: "43500079",
    RegisteredPopulation: "1516",
    VotingStationName: "MALANDELA HIGH SCHOOL",
    Address: "BABANANGO  LUKHWAZI",
    Latitude: "-28.3769",
    Longitude: "31.0806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12297",
    WardLookupId: "1535",
    VDNumber: "43500192",
    RegisteredPopulation: "98",
    VotingStationName: "KWA-DINGANE PRIMARY SCHOOL",
    Address:
      "DINGAANSTAAT RD/DINGAANSTAAT  KWA-DINGANE/KWANOBAMBA  ULUNDI/KWANOBAMBA T/A",
    Latitude: "-28.4331",
    Longitude: "31.2778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12298",
    WardLookupId: "1535",
    VDNumber: "43502419",
    RegisteredPopulation: "792",
    VotingStationName: "MAKHOSINI PRIMARY SCHOOL",
    Address:
      "VRYHEID RD/MAKHOSIN             MAKHOSINI/BABANANGO  ULUNDI/KWANOBAMBA T/A",
    Latitude: "-28.3889",
    Longitude: "31.2743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12299",
    WardLookupId: "1535",
    VDNumber: "43503005",
    RegisteredPopulation: "202",
    VotingStationName: "SCHURWEBERG PRIMARY SCHOOL",
    Address: "BABANANGO/COKOLO AREA  COKOLO AREA/BABANANGO  ULUNDI/KWANOBAMBA",
    Latitude: "-28.357",
    Longitude: "31.1306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12300",
    WardLookupId: "1535",
    VDNumber: "43503252",
    RegisteredPopulation: "404",
    VotingStationName: "MHLATHUZE PRIMARY  SCHOOL",
    Address:
      "BABANANGO RD/ZIQONGWANA AREA  BABANANGO/BABANANGO  ULUNDI/KWANOBAMBA T/A",
    Latitude: "-28.4963",
    Longitude: "31.1638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12301",
    WardLookupId: "1535",
    VDNumber: "43503285",
    RegisteredPopulation: "516",
    VotingStationName: "JONGIMFUNDO PRIMARY SCHOOL",
    Address:
      "BABANANGO RD/KWEYEZULU  KWEYEZULU/BABANANGO  ULUNDI/KWANOBAMBA T/A",
    Latitude: "-28.3527",
    Longitude: "31.2195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12302",
    WardLookupId: "1535",
    VDNumber: "43504084",
    RegisteredPopulation: "168",
    VotingStationName: "MAGANDA PRIMARY SCHOOL",
    Address: "KWAMAGANDA  ULUNDI [ULUNDI]  ULUNDI [ULUNDI]",
    Latitude: "-28.2751",
    Longitude: "31.1352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12303",
    WardLookupId: "1536",
    VDNumber: "43500215",
    RegisteredPopulation: "676",
    VotingStationName: "MPHOTHI PRIMARY SCHOOL",
    Address: "MABEDLANE ROAD/MPHOTHI  MPHOTHI/MAHLABATHINI  ULUNDI/MBATHA T/A",
    Latitude: "-28.302",
    Longitude: "31.343",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12304",
    WardLookupId: "1536",
    VDNumber: "43500226",
    RegisteredPopulation: "813",
    VotingStationName: "EZIBINDINI PRIMARY SCHOOL",
    Address: "0 ULUNDI  MAHLABATHINI",
    Latitude: "-28.2598",
    Longitude: "31.2732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12305",
    WardLookupId: "1536",
    VDNumber: "43500259",
    RegisteredPopulation: "1063",
    VotingStationName: "MABEDLANA PRIMARY SCHOOL",
    Address: "MABEDLANA  MAHLABATHINI  MPUNGOSE T/A",
    Latitude: "-28.3001",
    Longitude: "31.3806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12306",
    WardLookupId: "1536",
    VDNumber: "43500271",
    RegisteredPopulation: "687",
    VotingStationName: "EMBUDLE COMBINED PRIMARY SCHOOL",
    Address: "EMHLAHLANE//EMBUDLE  MAHLABATHITHI  ULUNDI/MPUNGOSE T/A",
    Latitude: "-28.265",
    Longitude: "31.337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12307",
    WardLookupId: "1536",
    VDNumber: "43502611",
    RegisteredPopulation: "209",
    VotingStationName: "BUHLEBELANGA PRIMARY SCHOOL",
    Address: "MAWULASHE  MAHLABATHINI  ULUNDI/ KWANOMBA T/A",
    Latitude: "-28.2956",
    Longitude: "31.3031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12308",
    WardLookupId: "1536",
    VDNumber: "43503195",
    RegisteredPopulation: "462",
    VotingStationName: "QWASHA PRIMARY SCHOOL",
    Address: "QWASHA AREA  MAHLABATHINI  ULUNDI/ MBATHA T/A",
    Latitude: "-28.2218",
    Longitude: "31.305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12309",
    WardLookupId: "1536",
    VDNumber: "43503241",
    RegisteredPopulation: "345",
    VotingStationName: "BAMBANANI CRECHE",
    Address: "DANNY DALTON  ULUNDI  DANNY DALTON / KWA-NOBAMBA",
    Latitude: "-28.3023",
    Longitude: "31.2706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12310",
    WardLookupId: "1537",
    VDNumber: "43502598",
    RegisteredPopulation: "784",
    VotingStationName: "ULUNDI PRIMARY SCHOOL",
    Address: "0 EZIHLABENI  MAHLABATHINI  ULUNDI/MPUNGOSE T/A",
    Latitude: "-28.3181",
    Longitude: "31.4447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12311",
    WardLookupId: "1537",
    VDNumber: "43504725",
    RegisteredPopulation: "680",
    VotingStationName: "SIPHUMELELE CRECHE",
    Address: "L-SECTION  MAHLABATHINI  ULUNDI/MPUNGOSE T/A",
    Latitude: "-28.3158",
    Longitude: "31.4363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12312",
    WardLookupId: "1537",
    VDNumber: "43504736",
    RegisteredPopulation: "712",
    VotingStationName: "GUGWINI CRECHE",
    Address: "MHLWATHINI AREA  MAHLABATHINI  ULUNDI/MPUNGOSE T/A",
    Latitude: "-28.333",
    Longitude: "31.4251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12313",
    WardLookupId: "1537",
    VDNumber: "43560019",
    RegisteredPopulation: "1666",
    VotingStationName: "GABANGAYE PRIMARY SCHOOL",
    Address: "NKONE STREET, A-SECTION  MAHLABATHINI  ULUNDI",
    Latitude: "-28.3385",
    Longitude: "31.4161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12314",
    WardLookupId: "1538",
    VDNumber: "43502587",
    RegisteredPopulation: "716",
    VotingStationName: "THENGISANGAYE PRIMARY SCHOOL",
    Address: "0 AIR PORT ROAD/MTIKINI  MAHLABATHINI  MAHLABATHINI",
    Latitude: "-28.3214",
    Longitude: "31.4281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12315",
    WardLookupId: "1538",
    VDNumber: "43504691",
    RegisteredPopulation: "525",
    VotingStationName: "HERE IS LIFE MINISTRIES",
    Address: "THOKOZA AREA  MAHLABATHINI  ULUNDI/MPUNGOSE T/A",
    Latitude: "-28.3288",
    Longitude: "31.4196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12316",
    WardLookupId: "1538",
    VDNumber: "43504714",
    RegisteredPopulation: "567",
    VotingStationName: "EZIPHOZONKE CRECHE",
    Address: "MTIKINI AREA  MAHLABATHINI  ULUNDI/MPUNGOSE T/A",
    Latitude: "-28.3156",
    Longitude: "31.4251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12317",
    WardLookupId: "1538",
    VDNumber: "43560053",
    RegisteredPopulation: "1594",
    VotingStationName: "B - SOUTH HALL",
    Address: "102 MAKHOSINI CRESCENT B-SOUTH  B SOUTH  ULUNDI",
    Latitude: "-28.3054",
    Longitude: "31.4173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12318",
    WardLookupId: "1538",
    VDNumber: "43560110",
    RegisteredPopulation: "93",
    VotingStationName: "MFANISENI CRECHE",
    Address: "MBANGAYIYA   ULUNDI  MPUNGOSE T/A",
    Latitude: "-28.2992",
    Longitude: "31.4047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12319",
    WardLookupId: "1539",
    VDNumber: "43500114",
    RegisteredPopulation: "620",
    VotingStationName: "NQUNQA PRIMARY SCHOOL",
    Address: "OKHUKHO ROAD/ NQUNQA  MAHLABATHINI  ULUNDI/XIMBA T/A",
    Latitude: "-28.3109",
    Longitude: "31.5258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12320",
    WardLookupId: "1539",
    VDNumber: "43500169",
    RegisteredPopulation: "632",
    VotingStationName: "ZONDELA PRIMARY SCHOOL",
    Address: "NTUKWINI ROAD/ZONDELA  MAHLABATHINI  ULUNDI/MPUNGOSE T/A",
    Latitude: "-28.2998",
    Longitude: "31.4696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12321",
    WardLookupId: "1539",
    VDNumber: "43502453",
    RegisteredPopulation: "615",
    VotingStationName: "NTANDAKUWELA PRIMARY SCHOOL",
    Address: "NTANDAKUWELA RESERVE  MAHLABATHINI  ULUNDI/ZUNGI T/A",
    Latitude: "-28.2545",
    Longitude: "31.4881",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12322",
    WardLookupId: "1539",
    VDNumber: "43504095",
    RegisteredPopulation: "318",
    VotingStationName: "VUKUZA HIGH SCHOOL",
    Address: "NODWENGU RESERVE  MAHLABATHINI  ULUNDI/XIMBA T/A",
    Latitude: "-28.2862",
    Longitude: "31.5047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12323",
    WardLookupId: "1539",
    VDNumber: "43504107",
    RegisteredPopulation: "213",
    VotingStationName: "NHLUNGWANE TRAINING CENTRE",
    Address: "NHUNGWANE NEXT TO CLINIC  MAHLABATHINI  ULUNDI/XIMBA T/A",
    Latitude: "-28.3067",
    Longitude: "31.5011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12324",
    WardLookupId: "1539",
    VDNumber: "43504703",
    RegisteredPopulation: "454",
    VotingStationName: "SANGOYANE CHURCH",
    Address: "SANGOYANE AREA  MAHABATHINI  ULUNDI/MPUNGOSE T/A",
    Latitude: "-28.2794",
    Longitude: "31.4681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12325",
    WardLookupId: "1539",
    VDNumber: "43560064",
    RegisteredPopulation: "1127",
    VotingStationName: "GQOKAMANDLA HIGH SCHOOL",
    Address: "EZIHLABENI AREA  MAHLABATHINI  ULUNDI/MPUNGOSE T/A",
    Latitude: "-28.3241",
    Longitude: "31.4597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12326",
    WardLookupId: "1540",
    VDNumber: "43502600",
    RegisteredPopulation: "1968",
    VotingStationName: "MAQHINGENDODA HIGH SCHOOL",
    Address:
      "OLD MAIN RD/MKHAZANE AREA  MKHAZANE/MAHLABATHINI  ULUNDI/MPUNGOSE T/A",
    Latitude: "-28.3207",
    Longitude: "31.3946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12327",
    WardLookupId: "1540",
    VDNumber: "43502970",
    RegisteredPopulation: "176",
    VotingStationName: "MOBILE VOTING STATION (OPHATHE GAME RESERVE)",
    Address: "MELMOTH ROAD  MAHLABATHINI   MPUNGOSE TRIBAL AUTHORITY",
    Latitude: "-28.365",
    Longitude: "31.39",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12328",
    WardLookupId: "1540",
    VDNumber: "43503207",
    RegisteredPopulation: "440",
    VotingStationName: "PHAPHAMA CRECHE",
    Address: "NDLOVANA AREA  ULUNDI  MPUNGOSE T/A",
    Latitude: "-28.3065",
    Longitude: "31.366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12329",
    WardLookupId: "1540",
    VDNumber: "43503601",
    RegisteredPopulation: "495",
    VotingStationName: "MANDLENI CAMP",
    Address:
      "R34/EMANDLENI(ACROSS UMFOLOZI  MANDLENI/MAHLABATHIUNI  ULUNDI/MPUNGOSE T/A",
    Latitude: "-28.3594",
    Longitude: "31.4187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12330",
    WardLookupId: "1540",
    VDNumber: "43504006",
    RegisteredPopulation: "578",
    VotingStationName: "NDLOVANA HIGH SCHOOL",
    Address: "MABEDLANE/NDONSA AREA  ULUNDI/MAHLABATHINI  ULUNDI/MPUNGOSE T/A",
    Latitude: "-28.3012",
    Longitude: "31.3852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12331",
    WardLookupId: "1540",
    VDNumber: "43504152",
    RegisteredPopulation: "784",
    VotingStationName: "GODLUMKHONTO PRIMARY SCHOOL",
    Address:
      "MKHAZANE/S`BUNJANA AREA  MKHAZANE/MAHLABATHINI  ULUNDI MPUNGOSE T/A",
    Latitude: "-28.3173",
    Longitude: "31.3814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12332",
    WardLookupId: "1540",
    VDNumber: "43505153",
    RegisteredPopulation: "47",
    VotingStationName: "UMTHOMBO KAJOB CHURCH",
    Address: "N/A  ULUNDI  MPUNGOSE TRIBAL",
    Latitude: "-28.3032",
    Longitude: "31.3799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12333",
    WardLookupId: "1541",
    VDNumber: "43560031",
    RegisteredPopulation: "2269",
    VotingStationName: "NSIKAYENDLU HIGH SCHOOL",
    Address: "1178 DR DENNIS MADIDE STREET  KWASISHWILI  ULUNDI",
    Latitude: "-28.2775",
    Longitude: "31.4391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12334",
    WardLookupId: "1541",
    VDNumber: "43560097",
    RegisteredPopulation: "1534",
    VotingStationName: "ZONDELA HALL",
    Address: "ZONDELA LOCATION  K/SECTION  ULUNDI",
    Latitude: "-28.3084",
    Longitude: "31.4419",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12335",
    WardLookupId: "1542",
    VDNumber: "43500170",
    RegisteredPopulation: "762",
    VotingStationName: "SENZANGAKHONA HIGH SCHOOL",
    Address: "MAKHOSINI  MAHLABATHINI  ULUNDI/KWANOBAMBA T/A",
    Latitude: "-28.364",
    Longitude: "31.266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12336",
    WardLookupId: "1542",
    VDNumber: "43500204",
    RegisteredPopulation: "992",
    VotingStationName: "LANDULWAZI PRIMARY SCHOOL",
    Address: "SGODIPHOLA AREA  BABANANGO  ULUNDI/KWANOBAMBA T/A",
    Latitude: "-28.3428",
    Longitude: "31.3014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12337",
    WardLookupId: "1542",
    VDNumber: "43500237",
    RegisteredPopulation: "1194",
    VotingStationName: "GOJE PRIMARY SCHOOL",
    Address: "0 DANNY-DALTON  BABANANGO  ULUNDI/KWANOBAMBA T/A",
    Latitude: "-28.2911",
    Longitude: "31.2496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12338",
    WardLookupId: "1542",
    VDNumber: "43502431",
    RegisteredPopulation: "488",
    VotingStationName: "NKAMELWANE PRIMARY SCHOOL",
    Address: "DANNY DALTON RESERVE  MAHLABATHINI  ULUNDI/KWANOBAMBA T/A",
    Latitude: "-28.3253",
    Longitude: "31.256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12339",
    WardLookupId: "1542",
    VDNumber: "43503274",
    RegisteredPopulation: "313",
    VotingStationName: "HLUNGULWANE PRIMARY SCHOOL",
    Address: "DANNY-DALTON  BABANANGO  ULUNDI/KWANOBAMBA T/A",
    Latitude: "-28.3295",
    Longitude: "31.2247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12340",
    WardLookupId: "1543",
    VDNumber: "43500091",
    RegisteredPopulation: "670",
    VotingStationName: "EKUJULUKENI PRIMARY SCHOOL",
    Address: "0 EKUJULUKENI  MAHLABATHINI  ULUNDI/XIMBA T/A",
    Latitude: "-28.4101",
    Longitude: "31.483",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12341",
    WardLookupId: "1543",
    VDNumber: "43500103",
    RegisteredPopulation: "601",
    VotingStationName: "ZILULWANE PRIMARY SCHOOL",
    Address: "OKHUKHO RD/DINDI AREA  MAHLABATHINI  ULUNDI/XIMBA T/A",
    Latitude: "-28.3367",
    Longitude: "31.6003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12342",
    WardLookupId: "1543",
    VDNumber: "43502576",
    RegisteredPopulation: "1083",
    VotingStationName: "SIBANISAKHE HIGH SCHOOL",
    Address: "HLOPHEKHULU  MAHLABATHINI  XIMBA",
    Latitude: "-28.3516",
    Longitude: "31.4984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12343",
    WardLookupId: "1543",
    VDNumber: "43502857",
    RegisteredPopulation: "663",
    VotingStationName: "IZINHLENDLE LOWER PRIMARY SCHOOL",
    Address: "0 NKONJANE  MAHLABATHINI  ULUNDI/XIMBA T/A",
    Latitude: "-28.3429",
    Longitude: "31.5595",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12344",
    WardLookupId: "1543",
    VDNumber: "43503735",
    RegisteredPopulation: "184",
    VotingStationName: "PHUZAMANZI SCHOOL",
    Address:
      "EZIKHUMBENI AREA MAHLABATHINI  ULUNDI  XIMBA TRIBAL AUTHORITY/ULUNDI",
    Latitude: "-28.3739",
    Longitude: "31.5477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12345",
    WardLookupId: "1543",
    VDNumber: "43504747",
    RegisteredPopulation: "273",
    VotingStationName: "NGUQE PRIMARY SCHOOL",
    Address: "NGUQE AREA  MAHLABATHINI  ULUNDI/XIMBA T/A",
    Latitude: "-28.3712",
    Longitude: "31.4558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12346",
    WardLookupId: "1544",
    VDNumber: "43410383",
    RegisteredPopulation: "1936",
    VotingStationName: "SHAYINA HIGH SCHOOL",
    Address: "N/A EKUTHUKUZENI  MANGUZI  KWA-TEMBE (NEXT TO MANGUZI STADIUM)",
    Latitude: "-26.9869",
    Longitude: "32.7363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12347",
    WardLookupId: "1544",
    VDNumber: "43417672",
    RegisteredPopulation: "1543",
    VotingStationName: "MANGUZI PRIMARY SCHOOL",
    Address:
      "EKUTHUKUZENI AREA  KWANGWANASE  KWATEMBE (NEXT TO MANGUZI HOSPITAL)",
    Latitude: "-26.9814",
    Longitude: "32.7533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12348",
    WardLookupId: "1544",
    VDNumber: "43418673",
    RegisteredPopulation: "700",
    VotingStationName: "MAPUTA PRIMARY SCHOOL",
    Address: "N/A NEXT TO MAPUTA CLINIC  KWANGWANASE  TEMBE",
    Latitude: "-26.9912",
    Longitude: "32.7432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12349",
    WardLookupId: "1545",
    VDNumber: "43410529",
    RegisteredPopulation: "1471",
    VotingStationName: "ESIPHAHLENI PRIMARY SCHOOL",
    Address:
      "N/A ISIPHAHLENI  MBAZWANA  KWA-MBILA (NEXT TO ESIPHAHLENI CLINIC)",
    Latitude: "-27.5272",
    Longitude: "32.6224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12350",
    WardLookupId: "1545",
    VDNumber: "43410541",
    RegisteredPopulation: "1466",
    VotingStationName: "MNTANENKOSI HIGH SCHOOL",
    Address: "N/A MANZIBOMVU  MBAZWANA  MBILA (NEXT TO SIYABONGA STORE)",
    Latitude: "-27.5404",
    Longitude: "32.5501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12351",
    WardLookupId: "1545",
    VDNumber: "43410686",
    RegisteredPopulation: "458",
    VotingStationName: "TENT AT SODWANA BAY LODGE",
    Address: "SODWANA BAY LODGE BOOMGATE  MBAZWANA  MBILA TRADITIONAL COUNCIL",
    Latitude: "-27.5473",
    Longitude: "32.6682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12352",
    WardLookupId: "1545",
    VDNumber: "43416468",
    RegisteredPopulation: "838",
    VotingStationName: "GALILE APOSTOLIC CHURCH",
    Address:
      "N/A QONGWANA AREA  MBAZWANA  KWA-MBILA (NEXT TO SBONOKUHLE BOTTLE STORE)",
    Latitude: "-27.5094",
    Longitude: "32.653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12353",
    WardLookupId: "1545",
    VDNumber: "43418684",
    RegisteredPopulation: "539",
    VotingStationName: "OZABENI PRIMARY SCHOOL",
    Address: "5 KM OFFRAMP R22 FROM MBAZWANA  MBAZWANA  KWAMBILA TRIBAL",
    Latitude: "-27.5201",
    Longitude: "32.576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12354",
    WardLookupId: "1546",
    VDNumber: "43410495",
    RegisteredPopulation: "729",
    VotingStationName: "OTHUNGWINI PRIMARY SCHOOL",
    Address:
      "N/A OTHUNGWINI  MBAZWANA  KWA-MBILA (NEXT TO UTHUNGWINI MOBILE CLINIC)",
    Latitude: "-27.4742",
    Longitude: "32.658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12355",
    WardLookupId: "1546",
    VDNumber: "43410530",
    RegisteredPopulation: "3207",
    VotingStationName: "MPIYAKHE HIGH SCHOOL",
    Address: "N/A NEXT TO MBAZWANA LIBRARY  MBAZWANA  MBILA",
    Latitude: "-27.4841",
    Longitude: "32.5866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12356",
    WardLookupId: "1546",
    VDNumber: "43410697",
    RegisteredPopulation: "897",
    VotingStationName: "DUMILE PRIMARY SCHOOL",
    Address:
      "N/A EZINQENI AREA, LAKE SIBHAYI  MBAZWANA  KWA-MBILA (NEXT TO SIZABANTU STORE)",
    Latitude: "-27.417",
    Longitude: "32.6632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12357",
    WardLookupId: "1547",
    VDNumber: "43410170",
    RegisteredPopulation: "1305",
    VotingStationName: "EMASAKENI PRIMARY SCHOOL",
    Address:
      "N/A EMASAKENI  KWAGEORGE  KWA-TEMBE (NEXT  ENKATHWINI CHURCH OF HOLY GHOST)",
    Latitude: "-26.997",
    Longitude: "32.7814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12358",
    WardLookupId: "1547",
    VDNumber: "43410406",
    RegisteredPopulation: "1502",
    VotingStationName: "NONIKELA PRIMARY SCHOOL",
    Address:
      "N/A THENGANI A  KWANGWANASE  KWA-TEMBE (NEXT TO ETSHENI TAXI/BUS STOP)",
    Latitude: "-27.0164",
    Longitude: "32.7345",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12359",
    WardLookupId: "1547",
    VDNumber: "43410417",
    RegisteredPopulation: "721",
    VotingStationName: "MNYAYIZA PRIMARY SCHOOL",
    Address:
      "N/A MNYAYIZA AREA  KWANGWANASE  KWA-TEMBE (NEXT TO SHENGEZA HIGH SCHOOL)",
    Latitude: "-27.0504",
    Longitude: "32.7539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12360",
    WardLookupId: "1547",
    VDNumber: "43410428",
    RegisteredPopulation: "243",
    VotingStationName: "EMALANGENI PRIMARY SCHOOL",
    Address:
      "N/A EMALANGENI  KWANGWANASE  KWA-TEMBE (NEXT TO BANGNEK BEACH, WETLAND PARK)",
    Latitude: "-27.0554",
    Longitude: "32.8304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12361",
    WardLookupId: "1547",
    VDNumber: "43415478",
    RegisteredPopulation: "1163",
    VotingStationName: "MASHALAZA HIGH SCHOOL",
    Address:
      "N/A ENKATHWINI AREA  KWANGWANASE  KWA-TEMBE (NEXT TO ENKATHWINI MISSION)",
    Latitude: "-27.0133",
    Longitude: "32.7634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12362",
    WardLookupId: "1548",
    VDNumber: "43410585",
    RegisteredPopulation: "572",
    VotingStationName: "MABASO TRIBAL AUTHORITY",
    Address:
      "N/A MSELENI AREA  UBOMBO  KWAMABASO TC (NEXT TO DOT CAMP EMSELENI)",
    Latitude: "-27.3628",
    Longitude: "32.5359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12363",
    WardLookupId: "1548",
    VDNumber: "43412497",
    RegisteredPopulation: "2526",
    VotingStationName: "NEW ERA PRIMARY SCHOOL",
    Address:
      "N/A KWANHLAMVU AREA  MSELENI AREA  KWA-MABASO (NEXT TO MSELENI HOSPITAL)",
    Latitude: "-27.3299",
    Longitude: "32.5542",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12364",
    WardLookupId: "1548",
    VDNumber: "43415827",
    RegisteredPopulation: "856",
    VotingStationName: "VIMBUKHALO PRIMARY SCHOOL",
    Address: "NEXT TO MABASO TRIBAL  EMSELENI AREA  UMHLABUYALINGANA",
    Latitude: "-27.3687",
    Longitude: "32.5239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12365",
    WardLookupId: "1548",
    VDNumber: "43415849",
    RegisteredPopulation: "479",
    VotingStationName: "MBOMA PRIMARY SCHOOL",
    Address: "R22 BETWEEN MSELENI AND MBAZWANA  MSELENI  MABASO TC",
    Latitude: "-27.4027",
    Longitude: "32.555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12366",
    WardLookupId: "1548",
    VDNumber: "43417111",
    RegisteredPopulation: "370",
    VotingStationName: "MAGCEKENI PRIMARY SCHOOL",
    Address:
      "N/A BETWEEN NTSHONGWE AND VIMBUKHALO SCH  MSELENI AREA  MABASO T/A",
    Latitude: "-27.3769",
    Longitude: "32.5005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12367",
    WardLookupId: "1549",
    VDNumber: "43410169",
    RegisteredPopulation: "2657",
    VotingStationName: "NODINEKA HIGH SCHOOL",
    Address:
      "R22 KWASKHEMELELE AREA  KWANGWANASE  KWA-TEMBE (AT SKHEMELELE SHOPS)",
    Latitude: "-27.0402",
    Longitude: "32.2733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12368",
    WardLookupId: "1549",
    VDNumber: "43414433",
    RegisteredPopulation: "944",
    VotingStationName: "MTIKINI PRIMARY SCHOOL",
    Address: "N/A MTIKINI NEXT TO SIKHEMELELE  EMTIKINI AREA  TEMBE T/A",
    Latitude: "-27.0477",
    Longitude: "32.3339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12369",
    WardLookupId: "1549",
    VDNumber: "43414477",
    RegisteredPopulation: "1256",
    VotingStationName: "MENGU PRIMARY SCHOOL",
    Address:
      "N/A KWALULWANE AREA  KWALULWANE  KWA-TEMBE (NEXT TO HIGH PRAISE CHURCH)",
    Latitude: "-27.0799",
    Longitude: "32.2852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12370",
    WardLookupId: "1550",
    VDNumber: "43410518",
    RegisteredPopulation: "634",
    VotingStationName: "MPHAKATHINI PRIMARY SCHOOL",
    Address:
      "N/A MPHAKATHINI AREA  MBAZWANE AREA  KWA-MABASO (NEXT TO KWAMUZI TUCKSHOP)",
    Latitude: "-27.4834",
    Longitude: "32.4545",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12371",
    WardLookupId: "1550",
    VDNumber: "43410631",
    RegisteredPopulation: "745",
    VotingStationName: "HLULABANTU PRIMARY SCHOOL",
    Address:
      "N/A HLULABANTU AREA  MBAZWANA  KWAMABASO (NEXT TO ISIMANGALISO NATURE BOOMGATE)",
    Latitude: "-27.5748",
    Longitude: "32.4711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12372",
    WardLookupId: "1550",
    VDNumber: "43410642",
    RegisteredPopulation: "389",
    VotingStationName: "OLD TRIBAL COURT",
    Address:
      "N/A MBUBENI AREA  MBAZWANA  KWA-MABASO (NEXT TO INKOSI NXUMALO`S HOME)",
    Latitude: "-27.4757",
    Longitude: "32.4937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12373",
    WardLookupId: "1550",
    VDNumber: "43415838",
    RegisteredPopulation: "774",
    VotingStationName: "MAHLAKWE PRIMARY SCHOOL",
    Address: "N/A NEXT TO UBUHLEBUYEZA TUCK SHOP  MBAZWANA  MABASO",
    Latitude: "-27.4855",
    Longitude: "32.5215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12374",
    WardLookupId: "1550",
    VDNumber: "43415850",
    RegisteredPopulation: "557",
    VotingStationName: "MTIWE PRIMARY SCHOOL",
    Address:
      "N/A MALOBENI AREA  UBOMBO  KWAMASHABANE  (NEXT TO NGWANE BOTTLE STORE)",
    Latitude: "-27.452",
    Longitude: "32.3032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12375",
    WardLookupId: "1550",
    VDNumber: "43416457",
    RegisteredPopulation: "655",
    VotingStationName: "OQONDWENI PRIMARY SCHOOL",
    Address:
      "N/A OQONDWENI AREA  MBAZWANA  KWA-MABASO (NEXT TO OQONDWENI CLINIC)",
    Latitude: "-27.5607",
    Longitude: "32.4107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12376",
    WardLookupId: "1550",
    VDNumber: "43417087",
    RegisteredPopulation: "789",
    VotingStationName: "KWASHODI PRIMARY SCHOOL",
    Address: "N/A NEXT TO KWASHODI MOBILE CLINIC  MBAZWANA  MABASO",
    Latitude: "-27.537",
    Longitude: "32.5182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12377",
    WardLookupId: "1550",
    VDNumber: "44420048",
    RegisteredPopulation: "650",
    VotingStationName: "MBAZWANA TRAFFIC DEPARTMENT",
    Address: "R22 MAIN ROAD, MBAZWANA  MBAZWANA  MBILA TRADITIONAL AUTHORITY",
    Latitude: "-27.4774",
    Longitude: "32.5811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12378",
    WardLookupId: "1551",
    VDNumber: "43410439",
    RegisteredPopulation: "1104",
    VotingStationName: "PHASULA PRIMARY SCHOOL",
    Address:
      "N/A VELABUSHA AREA  KWANGWANASE  KWA-TEMBE (NEXT TO NHLANHLIVELE HIGH SCHOOL)",
    Latitude: "-27.1405",
    Longitude: "32.6984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12379",
    WardLookupId: "1551",
    VDNumber: "43411889",
    RegisteredPopulation: "533",
    VotingStationName: "DUNDUBALA HIGH SCHOOL",
    Address:
      "N/A KWASONTO AREA  KWANGWANASE  KWA-TEMBE (NEXT TO KWASONTO MOBILE CLINIC)",
    Latitude: "-27.2769",
    Longitude: "32.6226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12380",
    WardLookupId: "1551",
    VDNumber: "43411902",
    RegisteredPopulation: "759",
    VotingStationName: "MANZENGWENYA PRIMARY SCHOOL",
    Address:
      "N/A MANZENGWENYA AREA  KWANGWANASE  KWA-TEMBE (NEXT TO THOLUSIZO TUCKSHOP)",
    Latitude: "-27.258",
    Longitude: "32.676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12381",
    WardLookupId: "1551",
    VDNumber: "43414927",
    RegisteredPopulation: "476",
    VotingStationName: "LANGELIHLE SENIOR SECONDARY SCHOOL",
    Address:
      "N/A KWAZIBI AREA  KWANGWANASE  KWA-TEMBE (NEXT TO KWAZIBI CLINIC)",
    Latitude: "-27.1415",
    Longitude: "32.7573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12382",
    WardLookupId: "1551",
    VDNumber: "43415366",
    RegisteredPopulation: "394",
    VotingStationName: "MABIBI PRIMARY SCHOOL",
    Address: "N/A MABIBI AREA  KWANGWANASE  KWA-TEMBE (NEXT TO MABIBI CLINIC)",
    Latitude: "-27.3289",
    Longitude: "32.73",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12383",
    WardLookupId: "1551",
    VDNumber: "43415423",
    RegisteredPopulation: "440",
    VotingStationName: "VULULWAZI PRIMARY SCHOOL",
    Address:
      "N/A KWAMPUKANI AREA  KWANGWANASE  KWA-TEMBE (NEXT TO LALANEK AND ROCKTAIL BEACH)",
    Latitude: "-27.1731",
    Longitude: "32.7608",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12384",
    WardLookupId: "1551",
    VDNumber: "43415434",
    RegisteredPopulation: "376",
    VotingStationName: "KWAMQOBELA PRIMARY SCHOOL",
    Address:
      "N/A KWAMQOBELA AREA  KWANGWANASE  KWA-TEMBE (NEXT TO MQOBELA FAITH ASSEMBLY CHURCH)",
    Latitude: "-27.1404",
    Longitude: "32.7888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12385",
    WardLookupId: "1551",
    VDNumber: "43417098",
    RegisteredPopulation: "209",
    VotingStationName: "PHUMANI PRIMARY SCHOOL",
    Address:
      "N/A KUTSHILITSHILI AREA  KWASONTO  KWA-TEMBE (NEXT TO TSHILITSHILI CLINIC/EDIPHINI)",
    Latitude: "-27.2381",
    Longitude: "32.6499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12386",
    WardLookupId: "1552",
    VDNumber: "43410125",
    RegisteredPopulation: "1823",
    VotingStationName: "BHEKABANTU PRIMARY SCHOOL",
    Address:
      "N/A BHEKABANTU  KWANGWANASE  KWA-TEMBE (NEXT TO BHEKABANTU CLINIC)",
    Latitude: "-26.9449",
    Longitude: "32.3359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12387",
    WardLookupId: "1552",
    VDNumber: "43414444",
    RegisteredPopulation: "1305",
    VotingStationName: "SIHANGWANA SCHOOL",
    Address:
      "N/A NEXT TO KWANDABA CLINIC  SIHANGWANA[MAKHANISI RES]  KWANGWANASE",
    Latitude: "-27.0135",
    Longitude: "32.3173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12388",
    WardLookupId: "1552",
    VDNumber: "43418718",
    RegisteredPopulation: "852",
    VotingStationName: "MDUMISA PRIMARY SCHOOL",
    Address:
      "N/A EMBANGWENI AREA  KWANGWANASE  KWA-TEMBE (NEXT TO KWAMALI STORE)",
    Latitude: "-26.8946",
    Longitude: "32.3576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12389",
    WardLookupId: "1553",
    VDNumber: "43410372",
    RegisteredPopulation: "190",
    VotingStationName: "NKOVUKENI PRIMARY SCHOOL",
    Address:
      "N/A NKOVUKENI AREA  KWANGWANASE  KWA-TEMBE (BEYOND MAKAWULENI LAKE)",
    Latitude: "-26.9282",
    Longitude: "32.8662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12390",
    WardLookupId: "1553",
    VDNumber: "43414916",
    RegisteredPopulation: "1175",
    VotingStationName: "ST JOSEPHS PRIMARY SCHOOL",
    Address:
      "R22 KWAMAHLUNGULU AREA  KWANGWANASE  KWA-TEMBE (NEXT TO KWAMAHLUNGULU CLINIC)",
    Latitude: "-26.8903",
    Longitude: "32.8036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12391",
    WardLookupId: "1553",
    VDNumber: "43415445",
    RegisteredPopulation: "626",
    VotingStationName: "THRELFAAL PRIMARY SCHOOL",
    Address:
      "N/A KWAMVUTSHANI AREA  KWANGWANASE  KWA-TEMBE (NEXT TO UTSHWAYELO CAMPSITES)",
    Latitude: "-26.89",
    Longitude: "32.8564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12392",
    WardLookupId: "1553",
    VDNumber: "43415456",
    RegisteredPopulation: "803",
    VotingStationName: "NHLANGE JUNIOR SECONDARY SCHOOL",
    Address:
      "N/A KWAMAZAMBANE AREA  KWANGWANASE  KWA-TEMBE (NEXT TO KWAMAZAMBANE CLINIC)",
    Latitude: "-26.9375",
    Longitude: "32.8217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12393",
    WardLookupId: "1553",
    VDNumber: "43416480",
    RegisteredPopulation: "652",
    VotingStationName: "KWAHLOMULA PRIMARY SCHOOL",
    Address:
      "N/A KWAMAZAMBANE AREA  KWANGWANASE  KWA-TEMBE (NEXT TO KWAHLOMULA ROMAN CATHOLIC)",
    Latitude: "-26.9161",
    Longitude: "32.8255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12394",
    WardLookupId: "1553",
    VDNumber: "43418729",
    RegisteredPopulation: "574",
    VotingStationName: "STAR OF THE SEA PRIMARY SCHOOL",
    Address:
      "R22 KWAMAZAMBANE  KWANGWANASE  KWA-TEMBE (NEXT TO STAR OF THE SEA HIGH SCHOOL)",
    Latitude: "-26.948",
    Longitude: "32.7807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12395",
    WardLookupId: "1554",
    VDNumber: "43410181",
    RegisteredPopulation: "2811",
    VotingStationName: "LIBUYILE PRIMARY SCHOOL",
    Address: "R22 NEAR EZANGOMENI  THENGANI  TEMBE",
    Latitude: "-27.0192",
    Longitude: "32.6826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12396",
    WardLookupId: "1554",
    VDNumber: "43418730",
    RegisteredPopulation: "1339",
    VotingStationName: "THENGANI PRIMARY SCHOOL",
    Address: "R22 THENGANI (NEAR NTIMBANE BUSSTO  KWANGWANASE  TEMBE T/A",
    Latitude: "-27.0173",
    Longitude: "32.7029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12397",
    WardLookupId: "1554",
    VDNumber: "43418741",
    RegisteredPopulation: "759",
    VotingStationName: "MASULUMANE CP SCHOOL",
    Address: "THENGANI A  TEMBE TRIBAL AUTHORITY   KWANGWANASE",
    Latitude: "-26.996",
    Longitude: "32.7086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12398",
    WardLookupId: "1555",
    VDNumber: "43410046",
    RegisteredPopulation: "1043",
    VotingStationName: "KWAMSHUDU PRIMARY SCHOOL",
    Address: "N/A MSHUDU AREA  KWANGWANASE  KWA-TEMBE (NEXT TO MSHUDU CLINIC)",
    Latitude: "-26.9215",
    Longitude: "32.717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12399",
    WardLookupId: "1555",
    VDNumber: "43410338",
    RegisteredPopulation: "1772",
    VotingStationName: "THANDIZWE COMBINED PRIMARY SCHOOL",
    Address:
      "N/A THANDIZWE AREA  KWANGWANASE  KWA-TEMBE (NEXT TO NDUNANKULU MASINGA)",
    Latitude: "-26.975",
    Longitude: "32.7103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12400",
    WardLookupId: "1555",
    VDNumber: "43414905",
    RegisteredPopulation: "407",
    VotingStationName: "MLOLI PRIMARY SCHOOL",
    Address:
      "N/A MLILO AREA  KWANGWANASE  KWA-TEMBE (BETWEEN MFAKUBHEKA AND NYAMAZANE)",
    Latitude: "-26.9288",
    Longitude: "32.6424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12401",
    WardLookupId: "1555",
    VDNumber: "43415883",
    RegisteredPopulation: "455",
    VotingStationName: "MFAKUBHEKA PRIMARY SCHOOL",
    Address:
      "N/A MFAKUBHEKA AREA  KWANGWANASE  KWA-TEMBE (NEXT TO PHANDINDLALA TUCKSHOP)",
    Latitude: "-26.9029",
    Longitude: "32.6048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12402",
    WardLookupId: "1555",
    VDNumber: "43416491",
    RegisteredPopulation: "557",
    VotingStationName: "GAZINI PRIMARY SCHOOL",
    Address: "N/A GAZINI AREA  KWANGWANASE  KWA-TEMBE (NEXT TO GAZINI CLINIC)",
    Latitude: "-26.8976",
    Longitude: "32.6646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12403",
    WardLookupId: "1555",
    VDNumber: "44420071",
    RegisteredPopulation: "303",
    VotingStationName: "PIKININI MOBILE CLINIC",
    Address:
      "KWANYAMAZANE NEAR AHIHANYENI H  KWANGWANASE  TEMBE TRIBAL AUTHORITY",
    Latitude: "-26.9414",
    Longitude: "32.6983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12404",
    WardLookupId: "1556",
    VDNumber: "43414499",
    RegisteredPopulation: "2066",
    VotingStationName: "NOTHANDO HIGH  SCHOOL",
    Address:
      "D9 ZAMAZAMA AREA  ZAMAZAMA   KWA-TEMBE(ALONG D9 ROAD, NEXT TO ZAMAZAMA STORE)",
    Latitude: "-27.1393",
    Longitude: "32.287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12405",
    WardLookupId: "1556",
    VDNumber: "43414590",
    RegisteredPopulation: "1980",
    VotingStationName: "MBOZA PRIMARY SCHOOL",
    Address: "D9 MBOZA AREA  KWANGWANASE  KWA-TEMBE (NEXT TO MBOZA PROJECT)",
    Latitude: "-27.1787",
    Longitude: "32.2706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12406",
    WardLookupId: "1557",
    VDNumber: "43414613",
    RegisteredPopulation: "1157",
    VotingStationName: "WELCOME PRIMARY SCHOOL",
    Address:
      "D9 WELCOME AREA  MBAZWANA  MASHABANE (NEXT TO KHILIKHILI BUTCHERY)",
    Latitude: "-27.304",
    Longitude: "32.2535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12407",
    WardLookupId: "1557",
    VDNumber: "43414624",
    RegisteredPopulation: "1632",
    VotingStationName: "HLOKOHLOKO PRIMARY SCHOOL",
    Address:
      "N/A HLOKOHLOKO AREA  KWANGWANASE  MASHABANE TC ( NEXT TO KWAMAHLANGU STORE)",
    Latitude: "-27.222",
    Longitude: "32.2724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12408",
    WardLookupId: "1557",
    VDNumber: "43414657",
    RegisteredPopulation: "1683",
    VotingStationName: "MADONELA COMBINED PRIMARY SCHOOL",
    Address: "D9 MADONELA AREA  UBOMBO  MASHABANE( NEXT TO MADONELA CLINIC)",
    Latitude: "-27.3422",
    Longitude: "32.2626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12409",
    WardLookupId: "1557",
    VDNumber: "43415142",
    RegisteredPopulation: "1050",
    VotingStationName: "HLAZANE COMBINED PRIMARY SCHOOL",
    Address:
      "N/A HLAZANE AREA  KWANGWANASE  MASHABANE (NEXT TO HLAZANE MOBILE CLINIC)",
    Latitude: "-27.2611",
    Longitude: "32.265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12410",
    WardLookupId: "1557",
    VDNumber: "43417100",
    RegisteredPopulation: "228",
    VotingStationName: "SIYABUSA CRECHE",
    Address: "N/A BIVA AREA  UBOMBO  KWAMASHABANE (NEXT TO TOP FARM RESEARCH)",
    Latitude: "-27.4264",
    Longitude: "32.2562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12411",
    WardLookupId: "1558",
    VDNumber: "43410484",
    RegisteredPopulation: "377",
    VotingStationName: "PHUZEMTHONJENI PRIMARY SCHOOL",
    Address:
      "N/A BANGIZWE AREA  UBOMBO  KWAMABASO (FROM MSELENI ON ROUTE TO MANABA CLINIC)",
    Latitude: "-27.326",
    Longitude: "32.4969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12412",
    WardLookupId: "1558",
    VDNumber: "43411812",
    RegisteredPopulation: "1113",
    VotingStationName: "NJINJI PRIMARY SCHOOL",
    Address: "R22 MAIN ROAD  BANGIZWE  MABASA TRIBAL",
    Latitude: "-27.2723",
    Longitude: "32.4899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12413",
    WardLookupId: "1558",
    VDNumber: "43412846",
    RegisteredPopulation: "597",
    VotingStationName: "ESIBHOWENI PRIMARY SCHOOL",
    Address:
      "N/A ESIBHOWENI AREA  NTSHONGWE AREA  KWA-MASHABANE (NEXT TO SALVATION ARMY CHURCH)",
    Latitude: "-27.3798",
    Longitude: "32.4258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12414",
    WardLookupId: "1558",
    VDNumber: "43413634",
    RegisteredPopulation: "1038",
    VotingStationName: "TSHONGWE COMBINED PRIMARY SCHOOL",
    Address:
      "N/A TSHONGWE AREA  NTSHONGWE AREA  KWA-MASHABANE (NEXT TO TSHONGWE CLINIC)",
    Latitude: "-27.4356",
    Longitude: "32.3684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12415",
    WardLookupId: "1558",
    VDNumber: "43414635",
    RegisteredPopulation: "597",
    VotingStationName: "MANTINTI HIGH SCHOOL",
    Address:
      "N/A MANYAMPISI 34 AREA  MBAZWANA  KWA-MASHABANE (NEXT TO MASHABANE TC)",
    Latitude: "-27.3137",
    Longitude: "32.3658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12416",
    WardLookupId: "1558",
    VDNumber: "43414714",
    RegisteredPopulation: "360",
    VotingStationName: "MUNYU PRIMARY SCHOOL",
    Address:
      "N/A MUNYU AREA  UBOMBO  KWA-MASHABANE (NEXT TO MUNYU MOBILE CLINIC)",
    Latitude: "-27.3504",
    Longitude: "32.3482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12417",
    WardLookupId: "1558",
    VDNumber: "43415861",
    RegisteredPopulation: "386",
    VotingStationName: "BUYANI PRIMARY SCHOOL",
    Address:
      "N/A MOZI  MBAZWANA  KWA-MASHABANE (NEXT TO ZAMINHLANHLA TUCKSHOP)",
    Latitude: "-27.4567",
    Longitude: "32.4095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12418",
    WardLookupId: "1559",
    VDNumber: "43414703",
    RegisteredPopulation: "613",
    VotingStationName: "EMAFA COMBINED PRIMARY SCHOOL",
    Address: "N/A NEXT TO MANABA STORE  EMANABA AREA  UMKHANYAKUDE",
    Latitude: "-27.2266",
    Longitude: "32.4298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12419",
    WardLookupId: "1559",
    VDNumber: "43414871",
    RegisteredPopulation: "2010",
    VotingStationName: "ESICABAZINI DEVELOPMENT CENTRE",
    Address:
      "N/A ESICABAZINI AREA  KWANGWANASE  KWATEMBE (ESICABAZINI DEVELOPMENT CENTRE)",
    Latitude: "-27.0736",
    Longitude: "32.4666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12420",
    WardLookupId: "1559",
    VDNumber: "43415254",
    RegisteredPopulation: "758",
    VotingStationName: "MSHANGUZANA SCHOOL",
    Address: "N/A NEXT TO MANABA CLINIC  EMANABA AREA  MASHABANE",
    Latitude: "-27.2858",
    Longitude: "32.4296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12421",
    WardLookupId: "1559",
    VDNumber: "43415872",
    RegisteredPopulation: "814",
    VotingStationName: "MANQAKULANA COMBINED PRIMARY SCHOOL",
    Address:
      "N/A MANQAKULANA   EMANQAKULANA AREA  KWATEMBE (NEXT TO MANQAKULANA TUCKSHOP)",
    Latitude: "-27.1303",
    Longitude: "32.4467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12422",
    WardLookupId: "1559",
    VDNumber: "43416008",
    RegisteredPopulation: "356",
    VotingStationName: "INGUTSHANA PRIMARY SCHOOL",
    Address:
      "N/A INGUTSHANA AREA  KWANGWANASE  KWA-TEMBE (ON ROUTE TO COASTAL CASHEW FROM R22)",
    Latitude: "-27.237",
    Longitude: "32.5571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12423",
    WardLookupId: "1559",
    VDNumber: "43418707",
    RegisteredPopulation: "502",
    VotingStationName: "MQHIYAMA PRIMARY SCHOOL",
    Address:
      "R22 ENDLONDWENI AREA  KWANGWANASE  KWA-TEMBE (NEXT TO MAGALI TUCKSCHOP)",
    Latitude: "-27.1895",
    Longitude: "32.5485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12424",
    WardLookupId: "1559",
    VDNumber: "44420037",
    RegisteredPopulation: "201",
    VotingStationName: "ASIBUYENI PRIMARY SCHOOL",
    Address: "P522 AT TEMBE ELEPHANT PARK   KWANGWANASE  TEMBE TRIBAL",
    Latitude: "-27.0504",
    Longitude: "32.4212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12425",
    WardLookupId: "1560",
    VDNumber: "43410091",
    RegisteredPopulation: "1162",
    VotingStationName: "KWAMASONDO PRIMARY SCHOOL",
    Address: "N/A NEXT TO MSHUDU H. SCHOOL  KWAMASONDO AREA  KWANGWANASE",
    Latitude: "-26.944",
    Longitude: "32.7292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12426",
    WardLookupId: "1560",
    VDNumber: "43410394",
    RegisteredPopulation: "1641",
    VotingStationName: "SIZAMINQUBEKO COMBINED SCHOOL",
    Address: "N/A NEXT TO LALALAPHA LODGE  ENGOZINI AREA  TEMBE",
    Latitude: "-26.9562",
    Longitude: "32.759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12427",
    WardLookupId: "1560",
    VDNumber: "43415894",
    RegisteredPopulation: "738",
    VotingStationName: "NDWANGU PRIMARY SCHOOL",
    Address:
      "R22 THELIZOLO AREA  KWANGWANASE  KWA-TEMBE (WEST-SIDE OF KWASOKALEZANGOMA)",
    Latitude: "-26.9216",
    Longitude: "32.7639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12428",
    WardLookupId: "1560",
    VDNumber: "43418695",
    RegisteredPopulation: "664",
    VotingStationName: "SIKHETHIWE PRIMARY SCHOOL",
    Address:
      "N/A KWAGEORGE AREA  KWANGWANASE  KWA-TEMBE (NEXT TO ENGOJE, KUMASHAYINYONI)",
    Latitude: "-26.9763",
    Longitude: "32.768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12429",
    WardLookupId: "1560",
    VDNumber: "43418763",
    RegisteredPopulation: "736",
    VotingStationName: "MFULAWEZWE SCHOOL",
    Address: "EKUTHUKUZENI   TEMBE TRIBAL  AUTHORITY  KWANGWANASE",
    Latitude: "-26.9671",
    Longitude: "32.7514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12430",
    WardLookupId: "1561",
    VDNumber: "43410013",
    RegisteredPopulation: "1232",
    VotingStationName: "NSALAMANGA HIGH SCHOOL",
    Address:
      "R22 ENDLODLWENI AREA  KWANGWANASE  KWA-TEMBE ( OPPOSITE ENDLONDLWENI PRIMARY)",
    Latitude: "-27.1427",
    Longitude: "32.5504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12431",
    WardLookupId: "1561",
    VDNumber: "43410158",
    RegisteredPopulation: "1114",
    VotingStationName: "PHOHLO PRIMARY SCHOOL",
    Address:
      "R22 PHOHLO AREA  KWANGWANASE  KWA-TEMBE (4 KM FROM PHELANDABA STORE)",
    Latitude: "-27.0567",
    Longitude: "32.5514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12432",
    WardLookupId: "1561",
    VDNumber: "43410361",
    RegisteredPopulation: "864",
    VotingStationName: "MHLUPHEKI HIGH SCHOOL",
    Address: "N/A NEXT TO TEMBE ROYAL HOUSE  EMFIHLWENI AREA  TEMBE T/A",
    Latitude: "-26.971",
    Longitude: "32.6065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12433",
    WardLookupId: "1561",
    VDNumber: "43416019",
    RegisteredPopulation: "567",
    VotingStationName: "EMAGUQENI PRIMARY SCHOOL",
    Address: "N/A KWAMAKHANYA AREA  KWANGWANASE  KWA-TEMBE (AT NONDWAYIZA )",
    Latitude: "-27.0461",
    Longitude: "32.597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12434",
    WardLookupId: "1561",
    VDNumber: "43416479",
    RegisteredPopulation: "711",
    VotingStationName: "MAKABONGWE PRIMARY SCHOOL",
    Address:
      "R22 PHELANDABA AREA  KWANGWANASE  KWA-TEMBE (NEXT TO PHELANDABA CIRCLE)",
    Latitude: "-27.0844",
    Longitude: "32.5634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12435",
    WardLookupId: "1562",
    VDNumber: "43410473",
    RegisteredPopulation: "1141",
    VotingStationName: "MHLEKAZI PRIMARY  SCHOOL",
    Address:
      "MHLEKAZI CRN D1887 AND A1159  MHLEKAZI AREA, NEAR NGWENYA TRIBAL  AUTHORITY  MKUZE",
    Latitude: "-27.7071",
    Longitude: "32.1492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12436",
    WardLookupId: "1562",
    VDNumber: "43410709",
    RegisteredPopulation: "657",
    VotingStationName: "TSHANENI FARM",
    Address: "TSHANENI AREA  ISAMBULO  NTSINDE",
    Latitude: "-27.6168",
    Longitude: "32.0697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12437",
    WardLookupId: "1562",
    VDNumber: "43414174",
    RegisteredPopulation: "794",
    VotingStationName: "EZIMBIDLENI PRIMARY SCHOOL",
    Address:
      "EMPILENI AREA,  D1887 RD  EMPILENI AREA, NEXT TO MDOLOMBA HIGH / NGEWENYA TR  MKUZE",
    Latitude: "-27.7593",
    Longitude: "32.2031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12438",
    WardLookupId: "1562",
    VDNumber: "43414185",
    RegisteredPopulation: "282",
    VotingStationName: "BHIYELA FARMERS ASSOCIATION HALL",
    Address: "P2/8 (N2 SOUTH)  MKUZE  UBOMBO - FARM LAND",
    Latitude: "-27.7948",
    Longitude: "32.1457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12439",
    WardLookupId: "1562",
    VDNumber: "43416198",
    RegisteredPopulation: "862",
    VotingStationName: "VELAKUKHANYE PRIMARY SCHOOL",
    Address: "MANGE, A1151, NEXT MANGE CLINI  MKUZE  MYENI TRIBAL AUTHORITY",
    Latitude: "-27.7666",
    Longitude: "32.1786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12440",
    WardLookupId: "1562",
    VDNumber: "43417245",
    RegisteredPopulation: "368",
    VotingStationName: "BHEKIMIKHONTO PRIMARY SCHOOL",
    Address: "MFUNGAMLILO AREA, D1887  NGWENYA TRIBAL AUTHORITY  MKUZE",
    Latitude: "-27.7314",
    Longitude: "32.18",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12441",
    WardLookupId: "1563",
    VDNumber: "43410620",
    RegisteredPopulation: "402",
    VotingStationName: "NTSINDE HIGH SCHOOL",
    Address: "EBALENI AREA   NSINDE TRIBAL (NEXT TO OLD AIRPORT SITE)  UBOMBO",
    Latitude: "-27.5391",
    Longitude: "32.0859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12442",
    WardLookupId: "1563",
    VDNumber: "43412554",
    RegisteredPopulation: "895",
    VotingStationName: "VEZUKUZA PRIMARY SCHOOL",
    Address: "P236, EZINKUNINI AREA.  NSINDE TRIBAL AUTHORITY  UBOMBO",
    Latitude: "-27.5283",
    Longitude: "32.1041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12443",
    WardLookupId: "1563",
    VDNumber: "43413869",
    RegisteredPopulation: "934",
    VotingStationName: "NKANGALA PRIMIRY SCHOOL",
    Address: "INKANGALA AREA, A1180  INSINDE TRIBAL   UBOMBO",
    Latitude: "-27.5557",
    Longitude: "32.0453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12444",
    WardLookupId: "1563",
    VDNumber: "43414084",
    RegisteredPopulation: "642",
    VotingStationName: "MAJOZINI PRIMARY SCHOOL",
    Address: "D 1823, MAJOZINI  AREA.  NSINDE TRIBAL AUTHORITY  JOZINI",
    Latitude: "-27.4893",
    Longitude: "32.0194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12445",
    WardLookupId: "1563",
    VDNumber: "43416727",
    RegisteredPopulation: "970",
    VotingStationName: "ST ALEXIST PRIMARY SCHOOL",
    Address: "NOHHIHHI AREA, P236  INSINDE TRIBAL   TOWN",
    Latitude: "-27.5481",
    Longitude: "32.0791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12446",
    WardLookupId: "1563",
    VDNumber: "43419315",
    RegisteredPopulation: "483",
    VotingStationName: "OPHANDE PRIMARY SCHOOL",
    Address: "OPHANDE, P236  JOZINI  NTSINDE",
    Latitude: "-27.5",
    Longitude: "32.1034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12447",
    WardLookupId: "1564",
    VDNumber: "43410664",
    RegisteredPopulation: "1338",
    VotingStationName: "KWADONSA PRIMARY SCHOOL",
    Address: "MTHAMBALALA AREA, D1883  JOBE TRIBAL A  UBOMBO",
    Latitude: "-27.5505",
    Longitude: "32.3262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12448",
    WardLookupId: "1564",
    VDNumber: "43410675",
    RegisteredPopulation: "577",
    VotingStationName: "EKUDILIKENI PRIMARY SCHOOL",
    Address: "EKUDILIKENI, A1138  JOZINI  JOBE",
    Latitude: "-27.5821",
    Longitude: "32.3996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12449",
    WardLookupId: "1564",
    VDNumber: "43412576",
    RegisteredPopulation: "2375",
    VotingStationName: "IZINESHE COMBINED PRIMARY SCHOOL",
    Address: "IZINESHE AREA, D820  KWAJOBE TRIBAL   UBOMBO",
    Latitude: "-27.6376",
    Longitude: "32.3541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12450",
    WardLookupId: "1564",
    VDNumber: "43417526",
    RegisteredPopulation: "222",
    VotingStationName: "MOZI PRIMARY  SCHOOL",
    Address:
      "MOZI AREA  JOZINI [MKUZE]  MASHABANE T/C, NEXT TO NTSHONGWE CLINIC",
    Latitude: "-27.503",
    Longitude: "32.3981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12451",
    WardLookupId: "1564",
    VDNumber: "43419089",
    RegisteredPopulation: "760",
    VotingStationName: "MABANDLENI HIGH SCHOOL",
    Address: "KWAJOBE AREA, D820  UBOMBO  JOBE TRIBAL",
    Latitude: "-27.5982",
    Longitude: "32.3208",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12452",
    WardLookupId: "1565",
    VDNumber: "43410619",
    RegisteredPopulation: "556",
    VotingStationName: "MUTHIDLWE COMBINED PRIMARY SCHOOL",
    Address: "A 1140, EMUTHINDWE AREA.  JOZINI  KWAJOBE TRIBAL",
    Latitude: "-27.5542",
    Longitude: "32.1833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12453",
    WardLookupId: "1565",
    VDNumber: "43415669",
    RegisteredPopulation: "878",
    VotingStationName: "EGUJINI PRIMARY SCHOOL",
    Address:
      "A1138Q, EGUJINI AREA.  NSINDE TRIBAL (NEXT TO MY GOD STORE)  UBOMBO",
    Latitude: "-27.556",
    Longitude: "32.1399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12454",
    WardLookupId: "1565",
    VDNumber: "43416200",
    RegisteredPopulation: "1563",
    VotingStationName: "CEZWANA CLINIC",
    Address: "A1138Q, CNR D820, CEZWANA AREA  KWAJOBE TRIBAL  UBOMBO",
    Latitude: "-27.5413",
    Longitude: "32.2592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12455",
    WardLookupId: "1565",
    VDNumber: "43417773",
    RegisteredPopulation: "474",
    VotingStationName: "EKUVELENI PRIMARY SCHOOL",
    Address: "A 1149, EKUVELENI AREA.  KWAJOBE TRIBAL   JOZINI",
    Latitude: "-27.5147",
    Longitude: "32.2973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12456",
    WardLookupId: "1565",
    VDNumber: "43418842",
    RegisteredPopulation: "489",
    VotingStationName: "SIBONOKUHLE HALL",
    Address: "MUTHIDLWE AREA  JOZINI   KWAJOBE T/C IN WARD 4",
    Latitude: "-27.5778",
    Longitude: "32.2115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12457",
    WardLookupId: "1565",
    VDNumber: "43418853",
    RegisteredPopulation: "407",
    VotingStationName: "ISIKHALISANAMUHLA PRIMARY SCHOOL",
    Address: "D 820, CEZWANE AREA  UBOMBO  JOBE TRIBAL",
    Latitude: "-27.5593",
    Longitude: "32.285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12458",
    WardLookupId: "1566",
    VDNumber: "43412385",
    RegisteredPopulation: "896",
    VotingStationName: "INJULA COMBINED SCHOOL",
    Address: "HLALANATHI AREA, P444  UBOMBO  ISIQAKATHA",
    Latitude: "-27.4926",
    Longitude: "32.1848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12459",
    WardLookupId: "1566",
    VDNumber: "43415670",
    RegisteredPopulation: "666",
    VotingStationName: "KWAMAKHONYENI PRIMARY SCHOOL",
    Address: "EMAKHONYENI AREA.  SIQAKATHA TRIBAL  UBOMBO",
    Latitude: "-27.474",
    Longitude: "32.1206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12460",
    WardLookupId: "1566",
    VDNumber: "43417155",
    RegisteredPopulation: "1240",
    VotingStationName: "SIQAKATHA PRIMARY SCHOOL",
    Address: "NGEDLEZA AREA,P444, NEXT CLINI  SIQAKATHA TRIBAL   UBOMBO",
    Latitude: "-27.4772",
    Longitude: "32.2286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12461",
    WardLookupId: "1566",
    VDNumber: "43417166",
    RegisteredPopulation: "2216",
    VotingStationName: "SINETHEZEKILE HIGH SCHOOL",
    Address: "D 9, EMAKHONYENI AREA.  SIQAKATHA TRIBAL   JOZINI",
    Latitude: "-27.4502",
    Longitude: "32.1182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12462",
    WardLookupId: "1566",
    VDNumber: "43419090",
    RegisteredPopulation: "504",
    VotingStationName: "BHANJANA PRIMARY SCHOOL",
    Address: "D 1835,ESIQAKATHA AREA  UBOMBO  SQAKATHA",
    Latitude: "-27.4596",
    Longitude: "32.1461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12463",
    WardLookupId: "1566",
    VDNumber: "44430353",
    RegisteredPopulation: "285",
    VotingStationName: "LIFALETHU HIGH SCHOOL",
    Address: "SQAKATHA  UBOMBO  JOBE TRIBAL",
    Latitude: "-27.5265",
    Longitude: "32.2498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12464",
    WardLookupId: "1567",
    VDNumber: "43410574",
    RegisteredPopulation: "1111",
    VotingStationName: "MAMFENE COMBINED PRIMARY SCHOOL",
    Address: "D1834, MAMFENE AREA.  SIQAKATHA TRIBAL  JOZINI",
    Latitude: "-27.372",
    Longitude: "32.2238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12465",
    WardLookupId: "1567",
    VDNumber: "43412510",
    RegisteredPopulation: "1071",
    VotingStationName: "IGUGULESIZWE SECONDARY SCHOOL",
    Address: "MKHONJENI AREA NEXT TO MAKHATH  ISIQAKATHA TRIBAL, D1834  UBOMBO",
    Latitude: "-27.4453",
    Longitude: "32.1609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12466",
    WardLookupId: "1567",
    VDNumber: "43412532",
    RegisteredPopulation: "1560",
    VotingStationName: "BIVA COMBINED PRIMARY SCHOOL",
    Address: "IBIVA AREA NEXT F21 ROAD  ISIQAKATHA TRIBAL   JOZINI",
    Latitude: "-27.4291",
    Longitude: "32.2128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12467",
    WardLookupId: "1567",
    VDNumber: "43419102",
    RegisteredPopulation: "945",
    VotingStationName: "NTENGA PRIMARY SCHOOL",
    Address: "D 1834, NYAWOSHANA AREA  ISIQAKATHA TRIBAL  JOZINI",
    Latitude: "-27.3916",
    Longitude: "32.2043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12468",
    WardLookupId: "1568",
    VDNumber: "43410596",
    RegisteredPopulation: "2167",
    VotingStationName: "EZIBUKWENI HIGH SCHOOL",
    Address: "MAPHAYA AREA  MYENI TRIBAL   JOZINI",
    Latitude: "-27.4294",
    Longitude: "32.093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12469",
    WardLookupId: "1568",
    VDNumber: "43412565",
    RegisteredPopulation: "917",
    VotingStationName: "EMBUYISELO PRIMARY SCHOOL",
    Address: "17 AREA JOZINI TOWN  INSINDE TRIBAL   UBOMBO",
    Latitude: "-27.4361",
    Longitude: "32.057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12470",
    WardLookupId: "1568",
    VDNumber: "43417223",
    RegisteredPopulation: "1318",
    VotingStationName: "JOZINI PRIMARY SCHOOL",
    Address: "JOZINI TOWN  JOZINI BOTTOM TOWN  MYENI TRIBAL",
    Latitude: "-27.4318",
    Longitude: "32.065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12471",
    WardLookupId: "1568",
    VDNumber: "43417751",
    RegisteredPopulation: "749",
    VotingStationName: "KWAQONDILE PRIMARY  SCHOOL",
    Address: "P 236, KWANDILE AREA  INSINDE TRIBAL   JOZINI",
    Latitude: "-27.4626",
    Longitude: "32.0943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12472",
    WardLookupId: "1568",
    VDNumber: "43417762",
    RegisteredPopulation: "490",
    VotingStationName: "INKUTHAZEKO PRIMARY SCHOOL",
    Address:
      "EMACHIBINI P522-1 MPHOWENI ARE  JOZINI   NSINDE T/C, ALONG P522 RAOD FROM JOZINI TO MKUZE",
    Latitude: "-27.4575",
    Longitude: "32.0235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12473",
    WardLookupId: "1569",
    VDNumber: "43410552",
    RegisteredPopulation: "906",
    VotingStationName: "AMANDLETHU HIGH SCHOOL",
    Address:
      "D1837, EMPONDWANE AREA  JOZINI   NYAWO T/C, NEXT MPONDWANE  COMMUNITY HALL",
    Latitude: "-27.3799",
    Longitude: "32.002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12474",
    WardLookupId: "1569",
    VDNumber: "43410563",
    RegisteredPopulation: "2725",
    VotingStationName: "EMSIYANE SENIOR SECONDARY SCHOOL",
    Address: "D 850, EMSIYANE AREA  JOZINI  NYAWO TRIBAL AUTHORITY",
    Latitude: "-27.385",
    Longitude: "32.0771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12475",
    WardLookupId: "1569",
    VDNumber: "43416211",
    RegisteredPopulation: "694",
    VotingStationName: "ENDABENI PRIMARY SCHOOL",
    Address: "D 850, EMSIYANE AREA  JOZINI  NYAWO TRIBAL AUTHORITY",
    Latitude: "-27.3527",
    Longitude: "32.0708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12476",
    WardLookupId: "1569",
    VDNumber: "43417177",
    RegisteredPopulation: "1145",
    VotingStationName: "OTHOBOTHINI PRIMARY SCHOOL",
    Address:
      "OTHOBOTHINI AREA   JOZINI  NYAWO T/C, NEXT TO DUTCH REFORMED CHURCH",
    Latitude: "-27.4016",
    Longitude: "32.0923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12477",
    WardLookupId: "1569",
    VDNumber: "43419258",
    RegisteredPopulation: "644",
    VotingStationName: "MGABADELI PRIMARY SCHOOL",
    Address: "D1837, MGABADELI  JOZINI  NYAWO TRIBAL",
    Latitude: "-27.3913",
    Longitude: "32.0634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12478",
    WardLookupId: "1570",
    VDNumber: "43414556",
    RegisteredPopulation: "624",
    VotingStationName: "EKULINGENI COMBINED PRIMARY SCHOOL",
    Address:
      "KWAYANGENI AREA, MOMBENI  INGWAVUMA  NYAWO T/C, NEXT MJAKELA HIGH SCHOOL,",
    Latitude: "-27.2744",
    Longitude: "32.0241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12479",
    WardLookupId: "1570",
    VDNumber: "43414578",
    RegisteredPopulation: "1042",
    VotingStationName: "BHEKINDODA PRIMARY  SCHOOL",
    Address:
      "- A1200, BHEKINDODA AREA  INGWAVUMA   NYAWAO T/C, NEXT TO OTHANDWENI CHURCH",
    Latitude: "-27.2409",
    Longitude: "32.1125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12480",
    WardLookupId: "1570",
    VDNumber: "43414680",
    RegisteredPopulation: "1265",
    VotingStationName: "OPHONDWENI PRIMARY SCHOOL",
    Address:
      "A1196, OPHONDWENI AREA  INGWAVUMA   NYAWO T/C, NEXT TO OPHONDWENI CLINIC",
    Latitude: "-27.3018",
    Longitude: "32.0983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12481",
    WardLookupId: "1570",
    VDNumber: "43414691",
    RegisteredPopulation: "935",
    VotingStationName: "GWALUWENI PRIMARY SCHOOL",
    Address: "CNR D1837, A1195GWALUWENI AREA  INGWAVUMA  NYAWO T/C",
    Latitude: "-27.3158",
    Longitude: "31.9788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12482",
    WardLookupId: "1570",
    VDNumber: "43417188",
    RegisteredPopulation: "321",
    VotingStationName: "LUMBE COMMUNITY CRECHE",
    Address:
      "CNR 850 , A1193 LUMBE AREA  JOZINI   NYAWO T/C, NEXT TO INDABENI PRIMARY AND BHEKINDLEN",
    Latitude: "-27.3247",
    Longitude: "32.0643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12483",
    WardLookupId: "1570",
    VDNumber: "43417234",
    RegisteredPopulation: "532",
    VotingStationName: "MHLUMENI PRIMARY SCHOOL",
    Address: "YANGENI AREA, NEXT TOP STORE  INGWAVUMA  NYAWO T/C",
    Latitude: "-27.2677",
    Longitude: "32.0573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12484",
    WardLookupId: "1570",
    VDNumber: "43419337",
    RegisteredPopulation: "278",
    VotingStationName: "LUBAMBO CRECHE",
    Address: "D850, LUBAMBO AREA  JOZINI  NYAWO TRIBAL",
    Latitude: "-27.232",
    Longitude: "32.0877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12485",
    WardLookupId: "1571",
    VDNumber: "43414589",
    RegisteredPopulation: "552",
    VotingStationName: "MDLADLA HIGH SCHOOL",
    Address: "KHWAMBUZI AREA  INGWAVUMA  NYAWO T/C, NEXT TO KHWAMBUZI CLINIC",
    Latitude: "-27.1831",
    Longitude: "32.1885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12486",
    WardLookupId: "1571",
    VDNumber: "43414602",
    RegisteredPopulation: "1634",
    VotingStationName: "MZINYENI PRIMARY SCHOOL",
    Address:
      "MZINYENI AREA  INGWAVUMA  NYAWO T/C, NEXT TO MZINYENI COMMUNITY HALL",
    Latitude: "-27.2256",
    Longitude: "32.1988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12487",
    WardLookupId: "1571",
    VDNumber: "43414646",
    RegisteredPopulation: "1359",
    VotingStationName: "KWASHUKELA PRIMARY SCHOOL",
    Address: "KWASHUKELA AREA  INGWAVUMA  NYAWO T/C, NEXT TO DHA OFFICES",
    Latitude: "-27.2651",
    Longitude: "32.2124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12488",
    WardLookupId: "1571",
    VDNumber: "43417717",
    RegisteredPopulation: "411",
    VotingStationName: "EMPUMELELWENI PRIMARY SCHOOL",
    Address: "IZAKHELENI AREA  INGWAVUMA  NYAWO T/C, NEXT TO MDLADLA HIGH",
    Latitude: "-27.2023",
    Longitude: "32.1757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12489",
    WardLookupId: "1571",
    VDNumber: "43417728",
    RegisteredPopulation: "515",
    VotingStationName: "KWAMANQWASHU PRIMARY SCHOOL",
    Address:
      "KWAMANQWASHU AREA  INGWAVUMA   NYAWO T/C, NEXT TO KWAMANQWASHU STORE",
    Latitude: "-27.2621",
    Longitude: "32.1477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12490",
    WardLookupId: "1571",
    VDNumber: "43417740",
    RegisteredPopulation: "904",
    VotingStationName: "MZONDI PRIMARY  SCHOOL",
    Address: "MZONDI AREA  INGWAVUMA   NYAWO T/C, NEXT TO ZENZELE STORE",
    Latitude: "-27.19",
    Longitude: "32.1388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12491",
    WardLookupId: "1572",
    VDNumber: "43410271",
    RegisteredPopulation: "978",
    VotingStationName: "NTABAYENGWE PRIMARY SCHOOL",
    Address:
      "INTABAYENGWE AREA  INGWAVUMA  MNGOMEZULU T/C, NEXT TO NQOBIZANI HIGH SCHOOL",
    Latitude: "-27.1045",
    Longitude: "32.0753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12492",
    WardLookupId: "1572",
    VDNumber: "43410462",
    RegisteredPopulation: "885",
    VotingStationName: "NSONGWENI PRIMARY SCHOOL",
    Address:
      "D1840, NONJINJIKAZI AREA  INGWAVUMA  MNGOMEZULU T/C,  NEXT NONJNJIKAZI COMMUNITY HALL",
    Latitude: "-27.0909",
    Longitude: "32.1183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12493",
    WardLookupId: "1572",
    VDNumber: "43410507",
    RegisteredPopulation: "1381",
    VotingStationName: "INGWAVUMA SENIOR SECONDARY SCHOOL",
    Address:
      "P443, MAGUGU AREA  INGWAVUIMA  MNGOMEZULU T/C, NEXT TO KHETHANI PRIMARY SCHOOL",
    Latitude: "-27.133",
    Longitude: "32.0282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12494",
    WardLookupId: "1572",
    VDNumber: "43414534",
    RegisteredPopulation: "855",
    VotingStationName: "EMLAMBONGWENYA PRIMARY SCHOOL",
    Address:
      "A1198, MLAMBONGWENYA AREA  INGWAVUMA  NYAWO T/C, NEXT TO NYAMANE HIGH SCHOOL",
    Latitude: "-27.1795",
    Longitude: "32.0905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12495",
    WardLookupId: "1573",
    VDNumber: "43410147",
    RegisteredPopulation: "1201",
    VotingStationName: "PHUNGAZA HIGH SCHOOL",
    Address:
      "P443, ESIWENI AREA  INGWAVUMA  NYAWO T/C, NEXT BHAMBANANA SHOPPING COMPLEX",
    Latitude: "-27.1074",
    Longitude: "32.1407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12496",
    WardLookupId: "1573",
    VDNumber: "43410440",
    RegisteredPopulation: "1582",
    VotingStationName: "ENKULISWENI PRIMARY SCHOOL",
    Address: "BHAMBANANA  ENKULISWENI (TOPI 14585)  NYAWO T/C",
    Latitude: "-27.0978",
    Longitude: "32.177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12497",
    WardLookupId: "1573",
    VDNumber: "43414488",
    RegisteredPopulation: "1378",
    VotingStationName: "SHEMULA PRIMARY SCHOOL",
    Address:
      "D1836, SHEMULA AREA  INGWAVUMA  NYAWO T/C, NEXT TO SHEMULA CLINIC",
    Latitude: "-27.0837",
    Longitude: "32.2277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12498",
    WardLookupId: "1573",
    VDNumber: "43414545",
    RegisteredPopulation: "1091",
    VotingStationName: "KHONYA PRIMARY SCHOOL",
    Address:
      "D1836, MLAWU AREA  INGWAVUMA  NYAWO T/C, BETWEEN KHWAMBUZI CLINIC AND SHEMULA CL",
    Latitude: "-27.1425",
    Longitude: "32.2116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12499",
    WardLookupId: "1573",
    VDNumber: "43419269",
    RegisteredPopulation: "968",
    VotingStationName: "OKHAYENI PRIMARY. SCHOOL",
    Address: "P522-1, BHAMBANANA AREA  BHAMBANANA  NYAWO T/A",
    Latitude: "-27.1415",
    Longitude: "32.1551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12500",
    WardLookupId: "1574",
    VDNumber: "43414455",
    RegisteredPopulation: "2794",
    VotingStationName: "NAMANENI HIGH SCHOOL",
    Address: "P435, EMAKHANISI AREA  INGWAVUMA  NYAWO TRIBAL AUTHORITY",
    Latitude: "-26.9931",
    Longitude: "32.2747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12501",
    WardLookupId: "1574",
    VDNumber: "43414466",
    RegisteredPopulation: "1556",
    VotingStationName: "AMAQHAWE PRIMARY SCHOOL",
    Address: "P522-2, MAKWAKWA AREA  INGWAVUMA  NYAWO T/C, NEXT TO BOOM GATE",
    Latitude: "-27.0373",
    Longitude: "32.2368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12502",
    WardLookupId: "1574",
    VDNumber: "43417706",
    RegisteredPopulation: "1219",
    VotingStationName: "MBODLA PRIMARY SCHOOL",
    Address:
      "MBODLA AREA  INGWAVUMA  NYAWO T/C, NEXT TO MBODLA STORE  AND NEW HOPE PROJ",
    Latitude: "-27.0403",
    Longitude: "32.1974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12503",
    WardLookupId: "1575",
    VDNumber: "43410136",
    RegisteredPopulation: "776",
    VotingStationName: "KHETWAYO PRIMARY  SCHOOL",
    Address:
      "D1886, EMACHOBENI AREA  INGWAVUMA  MNGOMEZULU T/C, NEXT TO ROYAL FAMILY HOUSE",
    Latitude: "-27.1131",
    Longitude: "32.006",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12504",
    WardLookupId: "1575",
    VDNumber: "43414501",
    RegisteredPopulation: "1553",
    VotingStationName: "LUNDINI PRIMARY SCHOOL",
    Address: "SHESHI AREA  INGWAVUMA  MNGOMEZULU T/C, NEXT TO TOWN HOUSE",
    Latitude: "-27.1434",
    Longitude: "31.9905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12505",
    WardLookupId: "1575",
    VDNumber: "43414512",
    RegisteredPopulation: "984",
    VotingStationName: "ISIHLANGWINI PRIMARY SCHOOL",
    Address:
      "- D1837, ISIHLANGWINI AREA  INGWAVUMA  NYAWO T/C, NEXT TO ISIHLANGWINI COMMUNITY HALL",
    Latitude: "-27.184",
    Longitude: "31.9795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12506",
    WardLookupId: "1575",
    VDNumber: "43414523",
    RegisteredPopulation: "745",
    VotingStationName: "EMTHONJENI COMBINED PRIMARY SCHOOL",
    Address: "D1838, EMTHONJENI AREA  INGWAVUMA  INGWAVUMA",
    Latitude: "-27.2212",
    Longitude: "32.0049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12507",
    WardLookupId: "1575",
    VDNumber: "43415692",
    RegisteredPopulation: "196",
    VotingStationName: "SHANGE PRIMARY SCHOOL",
    Address:
      "A1197, KWABHEKA AREA  INGWAVUMA  NYAWO T/C, NEXT TO SIHLANGWINI COMMUNITY HALL",
    Latitude: "-27.1943",
    Longitude: "32.0142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12508",
    WardLookupId: "1575",
    VDNumber: "44430375",
    RegisteredPopulation: "291",
    VotingStationName: "NANSINDLELA COMBINED SCHOOL",
    Address: "MAGUGU  INGWAVUMA  MNGOMEZULU TRIBAL",
    Latitude: "-27.1417",
    Longitude: "32.0141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12509",
    WardLookupId: "1575",
    VDNumber: "44430397",
    RegisteredPopulation: "365",
    VotingStationName: "ISICELOSETHU PRIMARY SCHOOL",
    Address: "SAYETHINI  INGWAVUMA  MNGOMEZULU TRIBAL",
    Latitude: "-27.1297",
    Longitude: "31.9901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12510",
    WardLookupId: "1576",
    VDNumber: "43410024",
    RegisteredPopulation: "744",
    VotingStationName: "LUDAKA PRIMARY SCHOOL",
    Address:
      "A1168, ENDABENI AREA  INGWAVUMA  MATHENJWA T/C, NEXT TO MBIKWANE SECONDARY SCHOOL",
    Latitude: "-26.9412",
    Longitude: "32.0285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12511",
    WardLookupId: "1576",
    VDNumber: "43410080",
    RegisteredPopulation: "1264",
    VotingStationName: "EMANYISENI PRIMARY SCHOOL",
    Address:
      "A1175, ONYAWENI AREA  INGWAVUMA  MATHENJWA T/C, NEXT TO MANYISENI CLINIC",
    Latitude: "-26.9132",
    Longitude: "32.0302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12512",
    WardLookupId: "1576",
    VDNumber: "43410215",
    RegisteredPopulation: "1819",
    VotingStationName: "EKUHLEHLENI PRIMARY  SCHOOL",
    Address:
      "EKUHLEHLENI AREA  INGWAVUMA  MATHENJWA T/C, NEXT TO EKUHLEHLENI CLINIC",
    Latitude: "-26.8569",
    Longitude: "32.0424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12513",
    WardLookupId: "1576",
    VDNumber: "43414826",
    RegisteredPopulation: "531",
    VotingStationName: "KHUME COMBINED PRIMARY SCHOOL",
    Address:
      "D1842, KHUME AREA  NGWAVUMA  MATHENJWA T/C, NEXT TO SOLAR WATER PUMP",
    Latitude: "-26.9237",
    Longitude: "32.0836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12514",
    WardLookupId: "1577",
    VDNumber: "43410057",
    RegisteredPopulation: "716",
    VotingStationName: "MPOLIMPOLINI COMBINED PRIMARY SCHOOL",
    Address:
      "A1176, EMAGWANGA AREA  INGWAVUMA  MATHENJWA T/C, NEXT TO NGCINIMUTHETHO STORE",
    Latitude: "-26.9048",
    Longitude: "32.1482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12515",
    WardLookupId: "1577",
    VDNumber: "43410192",
    RegisteredPopulation: "340",
    VotingStationName: "MAPHINDELA PRIMARY  SCHOOL",
    Address:
      "- D1883, INDUMO AREA  INGWAVUMA  MATHENJWA T/C, NEXT TO NDUMO SPAR",
    Latitude: "-26.9298",
    Longitude: "32.213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12516",
    WardLookupId: "1577",
    VDNumber: "43414387",
    RegisteredPopulation: "1738",
    VotingStationName: "THELAMAMA PRIMARY SCHOOL",
    Address:
      "- KWAMWAYI AREA   INGWAVUMA  MATHENJWA T/C, NEXT TO THELAMAMA COMMUNITY HALL",
    Latitude: "-26.9299",
    Longitude: "32.2892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12517",
    WardLookupId: "1577",
    VDNumber: "43414400",
    RegisteredPopulation: "942",
    VotingStationName: "ST PHILLIPS PRIMARY SCHOOL",
    Address:
      "- P435, MTHAKI AREA  INGWAVUMA   MATHENJWA T/C,  NEXT TO POLICE STATION",
    Latitude: "-26.9234",
    Longitude: "32.258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12518",
    WardLookupId: "1577",
    VDNumber: "43416738",
    RegisteredPopulation: "1057",
    VotingStationName: "NDUMO TELECENTRE",
    Address:
      "KWAMTHANTI AREA   INGWAVUMA   MATHENJWA T/C, NEXT TO MTHANTI HIGH SCHOOL",
    Latitude: "-26.9366",
    Longitude: "32.2739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12519",
    WardLookupId: "1577",
    VDNumber: "43417694",
    RegisteredPopulation: "586",
    VotingStationName: "EZIPHOSHENI PRIMARY SCHOOL",
    Address:
      "A1176, IZIPHOSHENI AREA  INGWAVUMA   MATHENJWA T/C, NEXT TO VULAMEHLO CRECHE",
    Latitude: "-26.9095",
    Longitude: "32.1884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12520",
    WardLookupId: "1578",
    VDNumber: "43410035",
    RegisteredPopulation: "617",
    VotingStationName: "EMBANDLENI PRIMARY  SCHOOL",
    Address:
      "D1841, EMBANDLENI AREA  INGWAVUMA  MATHENJWA T/C, NEXT TO MBANDLENI CLINIC",
    Latitude: "-26.9651",
    Longitude: "32.1385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12521",
    WardLookupId: "1578",
    VDNumber: "43410204",
    RegisteredPopulation: "493",
    VotingStationName: "MADEYA COMBINED PRIMARY SCHOOL",
    Address:
      "- D1841, MADEYA AREA  INGWAVUMA  MATHENJWA T/C, NEXT TO THEMBALOXOLO TUCK SHOP",
    Latitude: "-27.0366",
    Longitude: "32.14",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12522",
    WardLookupId: "1578",
    VDNumber: "43414411",
    RegisteredPopulation: "920",
    VotingStationName: "LOVE OF GOD CHURCH",
    Address:
      "P435, EMAHLABENI AREA   INGWAVUMA   MATHENJWA T/C, NEXT TO NAMANENI HIGH SCHOOL",
    Latitude: "-26.9756",
    Longitude: "32.2612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12523",
    WardLookupId: "1578",
    VDNumber: "43414422",
    RegisteredPopulation: "1053",
    VotingStationName: "MZIKI PRIMARY SCHOOL",
    Address: "MSIZINI AREA  INGWAVUMA  NYAWO T/C, NEXT TO LINDOKUHLE STORE",
    Latitude: "-26.9931",
    Longitude: "32.232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12524",
    WardLookupId: "1578",
    VDNumber: "43416705",
    RegisteredPopulation: "583",
    VotingStationName: "EMUNYWANA PRIMARY SCHOOL",
    Address: "EMUYWANA AREA  INGWAVUMA   MATHENJWA T/C, NEXT TO KWAMALI STORE",
    Latitude: "-26.9769",
    Longitude: "32.1872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12525",
    WardLookupId: "1578",
    VDNumber: "43417199",
    RegisteredPopulation: "289",
    VotingStationName: "MGEDULA PRIMARY SCHOOL",
    Address:
      "D1911, MGEDULA AREA  INGWAVUMA   MATHENJWA T/C, NEXT TO IBANDLA LIKAKRISTU CHURCH",
    Latitude: "-26.9844",
    Longitude: "32.1338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12526",
    WardLookupId: "1578",
    VDNumber: "43417346",
    RegisteredPopulation: "881",
    VotingStationName: "IMPALA SECONDARY SCHOOL",
    Address:
      "IMPALA AREA  INGWAVUMA   MATHENJWA T/C, NEXT TO MAPHINDELA COMMUNITY HALL",
    Latitude: "-26.959",
    Longitude: "32.2115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12527",
    WardLookupId: "1579",
    VDNumber: "43410068",
    RegisteredPopulation: "1280",
    VotingStationName: "NKUNGWIN PRIMARY SCHOOL",
    Address: "NKUNGWINI  INGWAVUMA  MNGOMEZULU T.C.",
    Latitude: "-27.0203",
    Longitude: "32.0249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12528",
    WardLookupId: "1579",
    VDNumber: "43410079",
    RegisteredPopulation: "393",
    VotingStationName: "NYATHINI PRIMARY SCHOOL",
    Address:
      "ENYATHINI AREA  INGWAVUMA  MNGOMEZULU T/C, NEXT TO UKUKHANYA KWENYATHI TRADIN",
    Latitude: "-27.0622",
    Longitude: "32.0795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12529",
    WardLookupId: "1579",
    VDNumber: "43410103",
    RegisteredPopulation: "1447",
    VotingStationName: "EMNGCELENI PRIMARY SCHOOL",
    Address:
      "ENGONYAMENI AREA  INGWAVUMA  MNGOMEZULU T/C, NEXT TO LUBELO HIGH SCHOOL",
    Latitude: "-26.9778",
    Longitude: "32.0126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12530",
    WardLookupId: "1579",
    VDNumber: "43410114",
    RegisteredPopulation: "562",
    VotingStationName: "OSHABENI PRIMARY SCHOOL",
    Address: "OSHABENI AREA  INGWAVUMA  MNGOMEZULU T/C, NEXT TO VIMBA STORE",
    Latitude: "-27.0282",
    Longitude: "32.0505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12531",
    WardLookupId: "1579",
    VDNumber: "43414815",
    RegisteredPopulation: "378",
    VotingStationName: "EMNGQOBELWENI PRIMARY SCHOOL",
    Address: "EBOVINI AREA  INGWAVUMA  MATHENJWA T/C, NEXT TO NDLOVU STORE",
    Latitude: "-26.9691",
    Longitude: "32.0553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12532",
    WardLookupId: "1579",
    VDNumber: "43419348",
    RegisteredPopulation: "247",
    VotingStationName: "DINABANYE PRIMARY SCHOOL",
    Address: "INGWAVUMA ROAD  JOZINI  MNGOMEZULU TRIBAL",
    Latitude: "-27.0479",
    Longitude: "32.025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12533",
    WardLookupId: "1580",
    VDNumber: "43410327",
    RegisteredPopulation: "1194",
    VotingStationName: "MFINGOSE PRIMARY SCHOOL",
    Address:
      "- A1196, MFINGOSE AREA  JOZINI   NYAWO T/C, NEXT TO NONDABUYA CLINIC",
    Latitude: "-27.3482",
    Longitude: "32.1575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12534",
    WardLookupId: "1580",
    VDNumber: "43414668",
    RegisteredPopulation: "672",
    VotingStationName: "KWAPHAWINI  PRIMARY SCHOOL",
    Address:
      "MASABE AREA  INGWAVUMA  NYAWO T/C, NEXT TO KWAMTHOKOZINI TRADING STORE",
    Latitude: "-27.3045",
    Longitude: "32.1997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12535",
    WardLookupId: "1580",
    VDNumber: "43414679",
    RegisteredPopulation: "1418",
    VotingStationName: "NONDABUYA EVANGELICAL CHURCH",
    Address:
      "NONDABUYA AREA  JOZINI  NYAWO T/C, NEXT TO NONDABUYA PRIMARY SCHOOL",
    Latitude: "-27.3364",
    Longitude: "32.115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12536",
    WardLookupId: "1580",
    VDNumber: "43417201",
    RegisteredPopulation: "1263",
    VotingStationName: "OHLALWINI  PRIMARY SCHOOL",
    Address:
      "D1836, OHLALWINI AREA  VILLAGE  NYAWO T/C, NEXT TO KWADLUDLU STORE",
    Latitude: "-27.3674",
    Longitude: "32.1198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12537",
    WardLookupId: "1580",
    VDNumber: "43417739",
    RegisteredPopulation: "299",
    VotingStationName: "MAGOBHOYI CRECHE`",
    Address: "MAGOBHOYI AREA  INGWAVUMA  NYAWO T/C",
    Latitude: "-27.3272",
    Longitude: "32.2058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12538",
    WardLookupId: "1581",
    VDNumber: "43412521",
    RegisteredPopulation: "2164",
    VotingStationName: "UMLINGO  HALL",
    Address:
      "P234, IKLEBE ROARD, MKUZE  MKUZE  NSIDE TRIBAL, NEXT TO ENGEN GARAGE MKUZE",
    Latitude: "-27.6218",
    Longitude: "32.0338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12539",
    WardLookupId: "1581",
    VDNumber: "43415311",
    RegisteredPopulation: "47",
    VotingStationName: "MANTUMA CAMP ( MKUZE )",
    Address: "- P563, UBOMBO FARMS  -MKHUZE  NTSINDE T/C",
    Latitude: "-27.5993",
    Longitude: "32.2181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12540",
    WardLookupId: "1581",
    VDNumber: "43416716",
    RegisteredPopulation: "732",
    VotingStationName: "OPHANSI PRIMARY SCHOOL",
    Address: "OPHANSI  JOZINI  JOZINI [MKUZE]",
    Latitude: "-27.6007",
    Longitude: "32.3145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12541",
    WardLookupId: "1581",
    VDNumber: "43417144",
    RegisteredPopulation: "771",
    VotingStationName: "TSHANENI PRIMARY SCHOOL",
    Address: "A1150, TSHANENI  MKUZE  NTSINDE T/C",
    Latitude: "-27.6318",
    Longitude: "32.107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12542",
    WardLookupId: "1581",
    VDNumber: "43417212",
    RegisteredPopulation: "837",
    VotingStationName: "MTHWAZI COMBINED SCHOOL",
    Address: "245 KLEBE ROAD, THEMBALETHU VILLAG  MKUZE  NTSINDE TRIBAL",
    Latitude: "-27.6181",
    Longitude: "32.0283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12543",
    WardLookupId: "1582",
    VDNumber: "43450017",
    RegisteredPopulation: "3076",
    VotingStationName: "KWAMSANE COMMUNITY HALL",
    Address: "DUBE STREET  KWAMSANE  MTUBATUBA",
    Latitude: "-28.4347",
    Longitude: "32.1437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12544",
    WardLookupId: "1582",
    VDNumber: "43450062",
    RegisteredPopulation: "676",
    VotingStationName: "NQIWANENI PRIMARY SCHOOL",
    Address: "00 BHEJANE  KWA MSANE  MTUBATUBA",
    Latitude: "-28.427",
    Longitude: "32.1457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12545",
    WardLookupId: "1583",
    VDNumber: "43450040",
    RegisteredPopulation: "2162",
    VotingStationName: "APOSTOLIC CHURCH  SOUTHERN AFRICA",
    Address:
      "MSANE RES NEXT TO SHISANYAMA   MTUBATUBA  MPUKUNYONI TRIBAL AUTHORITY",
    Latitude: "-28.4256",
    Longitude: "32.1537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12546",
    WardLookupId: "1583",
    VDNumber: "43450051",
    RegisteredPopulation: "2011",
    VotingStationName: "MNOTHO PRIMARY SCHOOL",
    Address: "KHANYISA STREET,KWAMSANE T/S  MTUBATUBA  MPUKUNYONI",
    Latitude: "-28.4518",
    Longitude: "32.1353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12547",
    WardLookupId: "1584",
    VDNumber: "43413836",
    RegisteredPopulation: "1537",
    VotingStationName: "UBUHLEBEMVELO COMBINED PUBLIC SCHOOL",
    Address: "DUKUDUKU NEXT TO THE CLINIC  KHULA VILLAGE  DUKUDUKU NORTH",
    Latitude: "-28.3787",
    Longitude: "32.3611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12548",
    WardLookupId: "1584",
    VDNumber: "43413847",
    RegisteredPopulation: "1850",
    VotingStationName: "SILETHUKUKHANYA HIGH SCHOOL",
    Address:
      "KHULA VILLAGE DUKUDUKU   KHULA VILLAGE  DUKUDUKU MPUKUNYONI TRIBAL AUTHORITY",
    Latitude: "-28.3697",
    Longitude: "32.3595",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12549",
    WardLookupId: "1584",
    VDNumber: "43413858",
    RegisteredPopulation: "127",
    VotingStationName: "NYALAZI ENTERPRISES",
    Address: "MFEKAYI AREA  NYALAZI  MTUBATUBA- MPUKUNYONI TRIBAL",
    Latitude: "-28.2205",
    Longitude: "32.3072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12550",
    WardLookupId: "1584",
    VDNumber: "43418077",
    RegisteredPopulation: "578",
    VotingStationName: "MZABALAZO PRIMARY SCHOOL",
    Address: "R618 DUKUDUKU KWANDONYANE AREA   MTUBATUBA  DUKUDUKU",
    Latitude: "-28.3782",
    Longitude: "32.3767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12551",
    WardLookupId: "1584",
    VDNumber: "43418303",
    RegisteredPopulation: "1093",
    VotingStationName: "KHULA NOMATHIYA HALL",
    Address: "R618 KHULA VILLAGE DUKUDUKU AREA   MTUBATUBA  MTUBATUBA",
    Latitude: "-28.3754",
    Longitude: "32.3658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12552",
    WardLookupId: "1584",
    VDNumber: "44460019",
    RegisteredPopulation: "340",
    VotingStationName: "DUKUDUKU SPORTS GROUND (TENT)",
    Address: "DUKUDUKU  MPUKUNYONI TRIBAL  MTUBATUBA",
    Latitude: "-28.3905",
    Longitude: "32.345",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12553",
    WardLookupId: "1584",
    VDNumber: "44460020",
    RegisteredPopulation: "325",
    VotingStationName: "EZWENELISHA TENT NKAZIMULWENI TENT",
    Address: "DUKUDUKU ENKAZIMULWENI   MPUKUNYONI TRIBAL  MTUBATUBA",
    Latitude: "-28.3933",
    Longitude: "32.3208",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12554",
    WardLookupId: "1585",
    VDNumber: "43413825",
    RegisteredPopulation: "2295",
    VotingStationName: "VEZOBALA HIGH  SCHOOL",
    Address: "DUKUDUKU   DUKUDUKU SOUTH  ENYAMAZANENI SOUTH",
    Latitude: "-28.4037",
    Longitude: "32.3455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12555",
    WardLookupId: "1585",
    VDNumber: "43417032",
    RegisteredPopulation: "1206",
    VotingStationName: "KHIPHINKUNZI PRIMARY SCHOOL",
    Address: "KHAYELISHA,DUKUDUKU AREA,MTUBA  MTUBATUBA  MPUKUNYONI",
    Latitude: "-28.4074",
    Longitude: "32.3587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12556",
    WardLookupId: "1585",
    VDNumber: "43490011",
    RegisteredPopulation: "834",
    VotingStationName: "ARRIE VIVIERS GEMEENSKAPSAAL",
    Address: "STEENBRAS AVE  ST LUCIA  ST LUCIA",
    Latitude: "-28.3775",
    Longitude: "32.4138",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12557",
    WardLookupId: "1586",
    VDNumber: "43413612",
    RegisteredPopulation: "1771",
    VotingStationName: "MTUBATUBA TOWN HALL",
    Address: "105 ST LUCIA ROAD  MTUBATUBA  MTUBATUBA",
    Latitude: "-28.4138",
    Longitude: "32.1902",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12558",
    WardLookupId: "1586",
    VDNumber: "43413623",
    RegisteredPopulation: "827",
    VotingStationName: "TENT AT MONZI SOCCER FIELD",
    Address: "P397 MONZI ROAD - MONZI FARM AREA  MTUBATUBA  MTUBATUBA",
    Latitude: "-28.4483",
    Longitude: "32.2908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12559",
    WardLookupId: "1586",
    VDNumber: "43415489",
    RegisteredPopulation: "858",
    VotingStationName: "UMFOLOZI PRIMARY SCHOOL",
    Address: "R618 RIVERVIEW ROAD MTUBATUBA  RIVERVIEW  MTUBATUBA",
    Latitude: "-28.4343",
    Longitude: "32.1846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12560",
    WardLookupId: "1586",
    VDNumber: "43415490",
    RegisteredPopulation: "1309",
    VotingStationName: "MONZI ULOA PRIMARY SCHOOL",
    Address: "EZWENELISHA NEXT TO LIGHTHOUSE  MTUBATUBA  MTUBATUBA RURAL",
    Latitude: "-28.4188",
    Longitude: "32.3118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12561",
    WardLookupId: "1586",
    VDNumber: "43417021",
    RegisteredPopulation: "935",
    VotingStationName: "NORDALE HALL",
    Address: "EAGLE DRIVE,NORDALE,MTUBATUBA  MTUBATUBA  MPUKUNYONI",
    Latitude: "-28.3997",
    Longitude: "32.2175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12562",
    WardLookupId: "1586",
    VDNumber: "44460031",
    RegisteredPopulation: "262",
    VotingStationName: "NKANINI TENT",
    Address: "MTUBATUBA  ",
    Latitude: "-28.4241",
    Longitude: "32.337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12563",
    WardLookupId: "1587",
    VDNumber: "43413735",
    RegisteredPopulation: "3428",
    VotingStationName: "AMANDLESIZWE PRIMARY SCHOOL",
    Address: "D1918 EPHAPHASI ROAD  NQOPHENI AREA  MPUKUNYONI (UMKHANYAKUDE)",
    Latitude: "-28.3923",
    Longitude: "32.1609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12564",
    WardLookupId: "1587",
    VDNumber: "43416132",
    RegisteredPopulation: "1937",
    VotingStationName: "VEYANA LOWER PRIMARY SCHOOL",
    Address: "R618 NONGOMA RD.  MPUKUNYONI AREA  HLABISA/UMKHANYAKUDE",
    Latitude: "-28.3692",
    Longitude: "32.1735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12565",
    WardLookupId: "1588",
    VDNumber: "43414275",
    RegisteredPopulation: "652",
    VotingStationName: "MBONGENI HIGH SCHOOL",
    Address:
      "D759 SHIKISHELA RD. MTUBATUBA  OPHONDWENI AREA  MPUKUNYONI (UMKHANYAKUDE)",
    Latitude: "-28.2298",
    Longitude: "32.2131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12566",
    WardLookupId: "1588",
    VDNumber: "43414286",
    RegisteredPopulation: "1044",
    VotingStationName: "BETHANGWE COMBINED PRIMARY SCHOOL",
    Address: "OPHONDWENI  OPHONDWENI AREA  HLABISA/UMKHANYAKUDE",
    Latitude: "-28.1571",
    Longitude: "32.2425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12567",
    WardLookupId: "1588",
    VDNumber: "43417537",
    RegisteredPopulation: "364",
    VotingStationName: "MAVANA PRIMARY SCHOOL",
    Address: "D1909 NTONDWENI RESERVE   MTUBATUBA  MPUKUNYONI MTUBATUBA",
    Latitude: "-28.1569",
    Longitude: "32.2002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12568",
    WardLookupId: "1588",
    VDNumber: "43417548",
    RegisteredPopulation: "606",
    VotingStationName: "NDIMANDE HIGH SCHOOL",
    Address: "D1909 OPHONDWENI ROAD  HLABISA [SOMKELE]  HLABISA [SOMKELE]",
    Latitude: "-28.2028",
    Longitude: "32.1776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12569",
    WardLookupId: "1588",
    VDNumber: "43418336",
    RegisteredPopulation: "729",
    VotingStationName: "ENDOMBENI PRIMARY SCHOOL",
    Address: "MTUBATUBA  ENDOMBENI  MTUBATUBA",
    Latitude: "-28.1907",
    Longitude: "32.2428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12570",
    WardLookupId: "1588",
    VDNumber: "43418347",
    RegisteredPopulation: "852",
    VotingStationName: "BATHATHE PRIMARY SCHOOL",
    Address: "N2 - MFEKAYI BEHIND ZAMIMPILO MARKET  MFEKAYI  M TUBATUBA",
    Latitude: "-28.1513",
    Longitude: "32.2753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12571",
    WardLookupId: "1589",
    VDNumber: "43414219",
    RegisteredPopulation: "2442",
    VotingStationName: "EQAKWINI HIGH SCHOOL",
    Address: "P495 QAKWINI RESERVE  MPUKUNYONI MTUBATUBA",
    Latitude: "-28.1498",
    Longitude: "32.3173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12572",
    WardLookupId: "1589",
    VDNumber: "43414231",
    RegisteredPopulation: "1546",
    VotingStationName: "MFEKAYI LOWER PRIMARY SCHOOL",
    Address: "P495 MFEKAYI RD.  MFEKAYI AREA  MPUKUNYONI (UMKHANYAKUDE)",
    Latitude: "-28.1948",
    Longitude: "32.3127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12573",
    WardLookupId: "1589",
    VDNumber: "43417133",
    RegisteredPopulation: "698",
    VotingStationName: "GILONKI PUBLIC SECONDARY SCHOOL",
    Address: "P495 MFEKAYI RESERVE  MPUKUNYONI MTUBATUBA",
    Latitude: "-28.1699",
    Longitude: "32.3296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12574",
    WardLookupId: "1589",
    VDNumber: "43418101",
    RegisteredPopulation: "698",
    VotingStationName: "ETHEMBENI EVANGELICAL CHURCH",
    Address: "MFEKAYI NEXT TO NDUKUZEMPI  MFEKAYI AREA  MPUKUNYONI MTUBATUBA",
    Latitude: "-28.1663",
    Longitude: "32.2994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12575",
    WardLookupId: "1590",
    VDNumber: "43414208",
    RegisteredPopulation: "1588",
    VotingStationName: "KUFEZEKILE HIGH SCHOOL",
    Address: "P495 NKUNDUSI RESERVE  MPUKUNYONI MTUBATUBA",
    Latitude: "-28.1108",
    Longitude: "32.3456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12576",
    WardLookupId: "1590",
    VDNumber: "43414725",
    RegisteredPopulation: "101",
    VotingStationName: "CAPE VIDAL (TENT)",
    Address: "CAPEVIDALE  MTUBATUBA  MTUBATUBA",
    Latitude: "-28.1262",
    Longitude: "32.5573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12577",
    WardLookupId: "1590",
    VDNumber: "43417122",
    RegisteredPopulation: "1094",
    VotingStationName: "CHWEBENI PRIMARY SCHOOL",
    Address: "P495 NKUNDUSI RESERVE  MPUKUNYONI MTUBATUBA",
    Latitude: "-28.0871",
    Longitude: "32.3616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12578",
    WardLookupId: "1590",
    VDNumber: "43418145",
    RegisteredPopulation: "620",
    VotingStationName: "QALAKAHLE PRIMARY SCHOOL",
    Address: "NKUNDUSI  MPUKUNYONI MTUBATUBA",
    Latitude: "-28.1271",
    Longitude: "32.3441",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12579",
    WardLookupId: "1591",
    VDNumber: "43414220",
    RegisteredPopulation: "1135",
    VotingStationName: "MFUNDABASHA PRIMARY SCHOOL",
    Address: "N2 MFEKAYI NEXT TO ZAMIMPILO  MFEKAYI AREA  MPUKUNYONI MTUBATUBA",
    Latitude: "-28.1681",
    Longitude: "32.2812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12580",
    WardLookupId: "1591",
    VDNumber: "43414242",
    RegisteredPopulation: "1052",
    VotingStationName: "MASIBONISANE HIGH SCHOOL",
    Address: "P495 MFEKAYI   MFEKAYI AREA  MTUBATUBA/UMKHANYAKUDE",
    Latitude: "-28.19",
    Longitude: "32.2861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12581",
    WardLookupId: "1591",
    VDNumber: "43414264",
    RegisteredPopulation: "1063",
    VotingStationName: "NSABALELE PRIMARY SCHOOL",
    Address: "P495 MFEKAYI  MFEKAYI AREA  MPUKUNYONI (UMKHANYAKUDE)",
    Latitude: "-28.1838",
    Longitude: "32.2715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12582",
    WardLookupId: "1591",
    VDNumber: "43418112",
    RegisteredPopulation: "869",
    VotingStationName: "NGOTWENI PRIMARY SCHOOL",
    Address: "N2 TURN RIGHT AT MCHAKWINI MARKET  MCHAKWINI  MTUBATUBA",
    Latitude: "-28.2135",
    Longitude: "32.2918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12583",
    WardLookupId: "1591",
    VDNumber: "43418358",
    RegisteredPopulation: "665",
    VotingStationName: "VUKANI CRECHE",
    Address: "P495 MFEKAYI AREA BONGUMENZI STORE  MTUBATUBA  MTUBATUBA",
    Latitude: "-28.1916",
    Longitude: "32.2942",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12584",
    WardLookupId: "1592",
    VDNumber: "43414006",
    RegisteredPopulation: "1397",
    VotingStationName: "BHOKOZA COMBINED PRIMARY SCHOOL",
    Address: "N2 PHONGOLA  SHIKISHELA AREA  MPUKUNYONI(UMKHNYAKUDE)",
    Latitude: "-28.2416",
    Longitude: "32.2725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12585",
    WardLookupId: "1592",
    VDNumber: "43414253",
    RegisteredPopulation: "2112",
    VotingStationName: "EMCHAKWINI HIGH SCHOOL",
    Address: "MFEKAYI  MTUBATUBA/UMKHANYAKUDE",
    Latitude: "-28.2085",
    Longitude: "32.2821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12586",
    WardLookupId: "1592",
    VDNumber: "43417841",
    RegisteredPopulation: "743",
    VotingStationName: "THANGO PRIMARY SCHOOL",
    Address:
      "R618 NATIONAL ROAD  EMCHAKWINI RESRVE  HLABISA, MPUKUNYONI TRIBAL",
    Latitude: "-28.2173",
    Longitude: "32.2469",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12587",
    WardLookupId: "1592",
    VDNumber: "43418369",
    RegisteredPopulation: "979",
    VotingStationName: "BHEKAMANDLA PAYPOINT",
    Address: "MFEKAYI SHIKISHELA ROAD   MFEKAYI BHEKAMANDLA  MTUBATUBA",
    Latitude: "-28.2561",
    Longitude: "32.2665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12588",
    WardLookupId: "1593",
    VDNumber: "43413971",
    RegisteredPopulation: "593",
    VotingStationName: "MBILINI LOWER PRIMARY SCHOOL",
    Address: "MADWALENI   MADWALENI RESERVE  MTUBATUBA/UMKHANYAKUDE",
    Latitude: "-28.292",
    Longitude: "32.1901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12589",
    WardLookupId: "1593",
    VDNumber: "43413982",
    RegisteredPopulation: "2649",
    VotingStationName: "NKOSANE HIGH SCHOOL",
    Address: "D759 SHIKISHELA RD.  SHIKISHELA AREA  MPUKUNYONI (UMKHANYAKUDE)",
    Latitude: "-28.2982",
    Longitude: "32.2473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12590",
    WardLookupId: "1593",
    VDNumber: "43413993",
    RegisteredPopulation: "1855",
    VotingStationName: "BHELEBANE PRIMARY SCHOOL",
    Address: "MADWALENI  MADWALENI RESERVE  MTUBATUBA/ UMKHANYAKUDE",
    Latitude: "-28.2649",
    Longitude: "32.2201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12591",
    WardLookupId: "1593",
    VDNumber: "44460042",
    RegisteredPopulation: "403",
    VotingStationName: "SIYAZAMA CRECHE",
    Address: "SHIKISHELA AREA  MPUKUNYONI TRIBAL  MTUBATUBA",
    Latitude: "-28.2788",
    Longitude: "32.2497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12592",
    WardLookupId: "1594",
    VDNumber: "43413724",
    RegisteredPopulation: "1954",
    VotingStationName: "UPHAPHASI HIGHER PRIMARY SCHOOL",
    Address:
      "P484 UPHAPHASI NEAR SIYATHUTHUKA SC  UPHAPHASI RESERVE  MTUBATUBA/ UMKHANYAKUDE",
    Latitude: "-28.3977",
    Longitude: "32.1398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12593",
    WardLookupId: "1594",
    VDNumber: "43413780",
    RegisteredPopulation: "2044",
    VotingStationName: "EBASWAZINI LOWER PRIMARY SCHOOL",
    Address: "R618 NONGOMA ROAD  PHAPHASI AREA  MPUKUNYONI (UMKHANYAKUDE)",
    Latitude: "-28.3625",
    Longitude: "32.1451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12594",
    WardLookupId: "1594",
    VDNumber: "43418088",
    RegisteredPopulation: "1119",
    VotingStationName: "KWAMNYAKAZA PAYPOINT",
    Address: "NKOMBOSE   NKOMBOSE AREA  MTUBATUBA",
    Latitude: "-28.4055",
    Longitude: "32.1575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12595",
    WardLookupId: "1595",
    VDNumber: "43413702",
    RegisteredPopulation: "1190",
    VotingStationName: "KWAMSHAYA COMBINED PRIMARY SCHOOL",
    Address: "KWAMSHAYA RD.  KWAMSHAYA AREA  MPUKUNYONI (UMKHANYAKUDE)",
    Latitude: "-28.4233",
    Longitude: "32.1018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12596",
    WardLookupId: "1595",
    VDNumber: "43413713",
    RegisteredPopulation: "1539",
    VotingStationName: "SIYAQALA HIGH SCHOOL",
    Address: "P484 SIYAQALA INKATHA AREA  INKATHA AREA  MTUBTUBA",
    Latitude: "-28.4035",
    Longitude: "32.0969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12597",
    WardLookupId: "1595",
    VDNumber: "43413779",
    RegisteredPopulation: "1614",
    VotingStationName: "NOMATHIYA HIGHER SECONDARY SCHOOL",
    Address: "D857 SOMKHELE   MPUKUNYONI/SOMKHELE AREA  MTUBATUBA/UMKHANYAKUDE",
    Latitude: "-28.3449",
    Longitude: "32.1037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12598",
    WardLookupId: "1596",
    VDNumber: "43413757",
    RegisteredPopulation: "612",
    VotingStationName: "NKOLOKOTHO COMBINED PRIMARY SCHOOL",
    Address:
      "D857 NKOLOKOTHO ROAD  MPUKUNYONI/NKOLOKOTHO RESERVE  MTUBATUBA/UMKHANYAKUDE",
    Latitude: "-28.3866",
    Longitude: "32.0489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12599",
    WardLookupId: "1596",
    VDNumber: "43413768",
    RegisteredPopulation: "827",
    VotingStationName: "QALANGOKUHLE PRIMARY SCHOOL",
    Address:
      "D857 NKOLOKOTHO ROAD  MPUKUNYONI/NKOLOKOTHO AREA  MTUBATUBA/UMKHANYAKUDE",
    Latitude: "-28.3577",
    Longitude: "32.0665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12600",
    WardLookupId: "1596",
    VDNumber: "43413791",
    RegisteredPopulation: "689",
    VotingStationName: "NKONYANE LOWER PRIMARY SCHOOL",
    Address: "ESIYEMBENI AREA  MPUKUNYONI (UMKHAMYAKUDE)",
    Latitude: "-28.3194",
    Longitude: "32.0049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12601",
    WardLookupId: "1596",
    VDNumber: "43413803",
    RegisteredPopulation: "850",
    VotingStationName: "QALAKANCANE LOWER PRIMARY SCHOOL",
    Address: "ESIYEMBENI AREA  MACHIBINI AREA  MPUKUNYONI (UMKHANYAKUDE)",
    Latitude: "-28.2886",
    Longitude: "32.0111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12602",
    WardLookupId: "1596",
    VDNumber: "43416154",
    RegisteredPopulation: "1180",
    VotingStationName: "MACHIBINI COMBINED SCHOOL",
    Address: "R618 MACHIBINI  MACHIBINI MPUKUNYONI  MTUBATUBA/UMKHANYAKUDE",
    Latitude: "-28.2801",
    Longitude: "32.0405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12603",
    WardLookupId: "1596",
    VDNumber: "43418370",
    RegisteredPopulation: "629",
    VotingStationName: "ASIPHIKELELE HIGH SCHOOL",
    Address: "MTUBATUBA  NKOLOKOTHO  MTUBATUBA",
    Latitude: "-28.3364",
    Longitude: "32.0399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12604",
    WardLookupId: "1597",
    VDNumber: "43413892",
    RegisteredPopulation: "1755",
    VotingStationName: "EKWAKHENI SCHOOL",
    Address: "R618 THANDANANI RD.  MAKHAMBANE AREA  MPUKUNYONI (UMKHANYAKUDE)",
    Latitude: "-28.343",
    Longitude: "32.186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12605",
    WardLookupId: "1597",
    VDNumber: "43413904",
    RegisteredPopulation: "994",
    VotingStationName: "SIYELULAMA JUNIOR SECONDARY SCHOOL",
    Address: "OGENGELE AREA  MPUKUNYONI (UMKHANYAKUDE)",
    Latitude: "-28.3316",
    Longitude: "32.1669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12606",
    WardLookupId: "1597",
    VDNumber: "43413960",
    RegisteredPopulation: "898",
    VotingStationName: "MAKHAMBANE PRIMARY SCHOOL",
    Address: "MAKHAMBANE AREA  MPUKUNYONI TRIBAL  MTUBATUBA",
    Latitude: "-28.3169",
    Longitude: "32.2158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12607",
    WardLookupId: "1597",
    VDNumber: "43418381",
    RegisteredPopulation: "894",
    VotingStationName: "WELANI PRIMARY SCHOOL",
    Address: "R618 OGENGELE SOMKHELE ROAD  OGENGELE  MTUBATUBA",
    Latitude: "-28.3526",
    Longitude: "32.153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12608",
    WardLookupId: "1598",
    VDNumber: "43413915",
    RegisteredPopulation: "659",
    VotingStationName: "BHEKUMUSA PRIMARY SCHOOL",
    Address: "D857 SOMKHELE RD.  MPUKUNYONI  MTUBATUBA/UMKHANYAKUDE",
    Latitude: "-28.2663",
    Longitude: "32.1412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12609",
    WardLookupId: "1598",
    VDNumber: "43413937",
    RegisteredPopulation: "960",
    VotingStationName: "INKOSENHLE LOWER PRIMARY SCHOOL",
    Address: "OGENGELE AREA  MAPHELENI AREA  MPUKUNYONI (UMKHANYAKUDE)",
    Latitude: "-28.3003",
    Longitude: "32.1648",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12610",
    WardLookupId: "1598",
    VDNumber: "43413948",
    RegisteredPopulation: "521",
    VotingStationName: "ISIGCINO LOWER PRIMARY SCHOOL",
    Address:
      "MBONGOLWANE ROAD  MAPHELENI/OPHONDWENI  MPUKUNYONI (UMKHANYAKUDE)",
    Latitude: "-28.2334",
    Longitude: "32.1815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12611",
    WardLookupId: "1598",
    VDNumber: "43417784",
    RegisteredPopulation: "803",
    VotingStationName: "KWAGXABA HIGH SCHOOL",
    Address: "SOMKHELE   OPHANDWENI AREA   MTUBATUBA",
    Latitude: "-28.2713",
    Longitude: "32.1599",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12612",
    WardLookupId: "1598",
    VDNumber: "43418123",
    RegisteredPopulation: "1132",
    VotingStationName: "MTUBA PRIMARY SCHOOL",
    Address: "D2390 SOMKHELE NEAR AFRICA CENTRE  SOMKHELE  MTUBATUBA",
    Latitude: "-28.3467",
    Longitude: "32.1136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12613",
    WardLookupId: "1599",
    VDNumber: "43413746",
    RegisteredPopulation: "1614",
    VotingStationName: "VUKUZENZELE CRECHE",
    Address: "MAHUJINI AREA GUNJANENI  MPUKUNYONI TRIBAL  MTUBATUBA",
    Latitude: "-28.2743",
    Longitude: "32.0878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12614",
    WardLookupId: "1599",
    VDNumber: "43413881",
    RegisteredPopulation: "923",
    VotingStationName: "SIPHELELE JUNIOR PRIMARY SCHOOL",
    Address: "R618 ESIGQOGQOGQWENI ROAD  MYEKI AREA  MPUKUNYONI (UMKHANYAKUDE)",
    Latitude: "-28.3185",
    Longitude: "32.0713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12615",
    WardLookupId: "1599",
    VDNumber: "43416143",
    RegisteredPopulation: "1793",
    VotingStationName: "MSIZIWAMAKRESTU LOWER PRIMARY SCHOOL",
    Address: "D857 SOMKHELE RD.  MPUKUNYONI  HLABISA/UMKHANYAKUDE",
    Latitude: "-28.3213",
    Longitude: "32.1112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12616",
    WardLookupId: "1599",
    VDNumber: "43418392",
    RegisteredPopulation: "839",
    VotingStationName: "ESIQIWINI HIGH SCHOOL",
    Address: "MTUBATUBA  GUNJANENI  MTUBATUBA",
    Latitude: "-28.2431",
    Longitude: "32.083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12617",
    WardLookupId: "1600",
    VDNumber: "43413814",
    RegisteredPopulation: "520",
    VotingStationName: "BHEKIMPILO LOWER PRIMARY SCHOOL",
    Address: "P495 QUBUKA ROAD  MAHUJINI AREA  MPUKUNYONI (UMKHANYAKUDE)",
    Latitude: "-28.2148",
    Longitude: "32.047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12618",
    WardLookupId: "1600",
    VDNumber: "43413926",
    RegisteredPopulation: "1238",
    VotingStationName: "KHULUBONE PRIMARY SCHOOL",
    Address: "NSOLWENI AREA  MPUKUNYONI TRIBAL  MTUBATUBA",
    Latitude: "-28.1996",
    Longitude: "32.0911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12619",
    WardLookupId: "1600",
    VDNumber: "43413959",
    RegisteredPopulation: "926",
    VotingStationName: "KWAMTHOLO COMBINED PRIMARY SCHOOL",
    Address: "P348 GUNJANENI ROAD  MVUTSHINI AREA  MPUKUNYONI (UMKHANYAKUDE)",
    Latitude: "-28.1803",
    Longitude: "32.1241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12620",
    WardLookupId: "1600",
    VDNumber: "43416109",
    RegisteredPopulation: "851",
    VotingStationName: "NTANDABANTU PRIMARY SCHOOL",
    Address: "R618 NTANDABANTU  MTUBATUBA/UMKHANYAKUDE",
    Latitude: "-28.2451",
    Longitude: "32.0356",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12621",
    WardLookupId: "1600",
    VDNumber: "43416165",
    RegisteredPopulation: "771",
    VotingStationName: "DUMA COMBINED PRIMARY SCHOOL",
    Address: "P562 GUNJANENI   MPUKUNYONI  MTUBATUBA/UMKHANYAKUDE",
    Latitude: "-28.161",
    Longitude: "32.1596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12622",
    WardLookupId: "1600",
    VDNumber: "43418134",
    RegisteredPopulation: "1022",
    VotingStationName: "ISOLESIZWE HIGH SCHOOL",
    Address: "OPHONDWENI AREA  GWABALANDA AREA  MTUBATUBA",
    Latitude: "-28.2332",
    Longitude: "32.1425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12623",
    WardLookupId: "1601",
    VDNumber: "43413690",
    RegisteredPopulation: "2584",
    VotingStationName: "ZITIKE LOWER PRIMARY SCHOOL",
    Address: "ZITIKE SCHOOL  KWA MSANE RESERVE  MTUBATUBA",
    Latitude: "-28.434",
    Longitude: "32.1531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12624",
    WardLookupId: "1601",
    VDNumber: "43417380",
    RegisteredPopulation: "1137",
    VotingStationName: "ENGWENI PRIMARY SCHOOL",
    Address: "INDLOVU VILLAGE ,MTUBATUBA  MTUBATUBA  MPUKUNYONI",
    Latitude: "-28.4283",
    Longitude: "32.1703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12625",
    WardLookupId: "1601",
    VDNumber: "43418314",
    RegisteredPopulation: "1188",
    VotingStationName: "HILL 70 PRIMARY SCHOOL",
    Address: "NEXT TO THE SPORTS GROUND  INDLOVU VILLAGE  MTUBATUBA",
    Latitude: "-28.4295",
    Longitude: "32.1637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12626",
    WardLookupId: "3739",
    VDNumber: "43414118",
    RegisteredPopulation: "1290",
    VotingStationName: "KWA GIBA HIGH SCHOOL",
    Address: "OPPOSITE MNQOBOKAZI COMM. HALL  MNQOBOKAZI  MNQOBOKAZI TRIBAL",
    Latitude: "-27.7191",
    Longitude: "32.4636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12627",
    WardLookupId: "3739",
    VDNumber: "43415300",
    RegisteredPopulation: "1342",
    VotingStationName: "MNQOBOKAZI PRIMARY SCHOOL",
    Address:
      "NEAR MNQOBOKAZI BHEKANI MARKET  MNQOBOKAZI AREA  MNQOBOKAZI TRIBAL",
    Latitude: "-27.6922",
    Longitude: "32.4467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12628",
    WardLookupId: "3739",
    VDNumber: "43416378",
    RegisteredPopulation: "433",
    VotingStationName: "BUHLEBUVELE PRIMARY SCHOOL",
    Address:
      "3 KILOS FROM KWA SHOES STORE  MNQOBOKAZI  MNQOBOKAZI TRIBAL AUTHORITY",
    Latitude: "-27.7096",
    Longitude: "32.4443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12629",
    WardLookupId: "3739",
    VDNumber: "43416389",
    RegisteredPopulation: "597",
    VotingStationName: "BHEKAMANGWANE PRIMARY SCHOOL",
    Address:
      "NTONGANE TEMPLE  TURN RIGHT FROM R22 GRAVEL ROAD NEXT TO NTONGANE T  MNQOBOKAZI TRIBAL",
    Latitude: "-27.7368",
    Longitude: "32.4662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12630",
    WardLookupId: "3739",
    VDNumber: "43418527",
    RegisteredPopulation: "513",
    VotingStationName: "NKOMO JUNIOR PRIMARY  SCHOOL",
    Address: "TURN RIGHT FROM MNQOBOKAZI HAL  MNQOBOKAZI  MNQOBOKAZI TRIBAL",
    Latitude: "-27.7157",
    Longitude: "32.4701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12631",
    WardLookupId: "3740",
    VDNumber: "43414163",
    RegisteredPopulation: "1018",
    VotingStationName: "MDINWA PRIMARY SCHOOL",
    Address: "NEAR PHOYISA MNGOMEZULU HOME  MDUKU  MAKHASA TRIBAL",
    Latitude: "-27.8522",
    Longitude: "32.3741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12632",
    WardLookupId: "3740",
    VDNumber: "43416345",
    RegisteredPopulation: "1047",
    VotingStationName: "MAKHASA HIGH SCHOOL",
    Address: "NEXT TO MAKHASA TRIBAL AUTHORI  MAKHASA  MAKHASA TRIBAL",
    Latitude: "-27.8273",
    Longitude: "32.3821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12633",
    WardLookupId: "3740",
    VDNumber: "43416367",
    RegisteredPopulation: "1015",
    VotingStationName: "MDUDLA PRIMARY SCHOOL",
    Address: "OPPOSITE CELINHLANHLA STORE  MDUKU  MAKHASA TRIBAL",
    Latitude: "-27.8008",
    Longitude: "32.3836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12634",
    WardLookupId: "3740",
    VDNumber: "43417357",
    RegisteredPopulation: "375",
    VotingStationName: "OPEN SPACE NEAR CLOETE FARM TENT",
    Address: "KWA KAAS FARM  KAAS FARM EZIMBUZINI  MAKHASA TRIBAL",
    Latitude: "-27.8861",
    Longitude: "32.3795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12635",
    WardLookupId: "3740",
    VDNumber: "43417683",
    RegisteredPopulation: "108",
    VotingStationName: "PHINDA FOREST",
    Address: "7 KM FROM PHINDA FOREST`S GATE  MDUKU  MAKHASA TRIBAL",
    Latitude: "-27.777",
    Longitude: "32.349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12636",
    WardLookupId: "3741",
    VDNumber: "43414129",
    RegisteredPopulation: "718",
    VotingStationName: "MANQOBA PRIMARY SCHOOL",
    Address:
      "MANQOBA AREA  TURN RIGHT AT VODACOM TOWER AND TAKE GRAVEL ROAD F  MNQOBOKAZI TRIBAL",
    Latitude: "-27.76",
    Longitude: "32.4542",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12637",
    WardLookupId: "3741",
    VDNumber: "43414141",
    RegisteredPopulation: "777",
    VotingStationName: "KHATHAZILE PRIMARY SCHOOL",
    Address:
      "NO NYATHINI AREA  NEXT TO OLD PHUMZAKHELE STORE  ENYATHINI AREA  MAKHASA TRIBAL",
    Latitude: "-27.8205",
    Longitude: "32.4288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12638",
    WardLookupId: "3741",
    VDNumber: "43414152",
    RegisteredPopulation: "1056",
    VotingStationName: "MDUKU HIGH SCHOOL",
    Address: "- TURN RIGHT AT DEV.CENTRE 2 KM   -MDUKU  MAKHASA TRIBAL",
    Latitude: "-27.8265",
    Longitude: "32.3932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12639",
    WardLookupId: "3741",
    VDNumber: "43416187",
    RegisteredPopulation: "669",
    VotingStationName: "SH GUMEDE PRIMARY SCHOOL",
    Address: "MAHONGOZA AREA  MAKHASA  MAKHASA TIBAL AUTHORITY",
    Latitude: "-27.8508",
    Longitude: "32.4085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12640",
    WardLookupId: "3741",
    VDNumber: "43416334",
    RegisteredPopulation: "892",
    VotingStationName: "NONTSHWILIZA PRIMARY SCHOOL",
    Address:
      "SIKWAKWANENI AREA  TURN LEFT OPPOSITTE NONTSHWILIZA HALL DRIVE FOR 1K  MAKHASA TRIBAL",
    Latitude: "-27.7861",
    Longitude: "32.4165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12641",
    WardLookupId: "3741",
    VDNumber: "43418538",
    RegisteredPopulation: "381",
    VotingStationName: "NQOBIZIZWE PRIMARY SCHOOL",
    Address:
      "NUKAMKHONTO AREA  FROM EMATHAYINI STOP TAKE RIGHT AND DRIVE FOR 2 KM  MNQOBOKAZI TRIBAL",
    Latitude: "-27.7815",
    Longitude: "32.4632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12642",
    WardLookupId: "3742",
    VDNumber: "43414095",
    RegisteredPopulation: "767",
    VotingStationName: "NQUTSHINI PRIMARY SCHOOL",
    Address: "NEXT TO VEZUKUKHANYA STORE  NQUTSHINI  NIBELA TRIBAL",
    Latitude: "-27.939",
    Longitude: "32.4417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12643",
    WardLookupId: "3742",
    VDNumber: "43414107",
    RegisteredPopulation: "860",
    VotingStationName: "NIBELA TRIBAL HALL",
    Address: "NEXT TO TRIBAL AUTHORITY  NIBELA  NIBELA TRIBAL",
    Latitude: "-27.8723",
    Longitude: "32.4585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12644",
    WardLookupId: "3742",
    VDNumber: "43414130",
    RegisteredPopulation: "986",
    VotingStationName: "BANGIZWE PRIMARY SCHOOL",
    Address:
      "NO EZIMPONDWENI CLINIC TURN LEFT  NIBELA TRIBAL EZIMPONDWENI AREA  HLUHLUWE  NIBELA TRIBAL",
    Latitude: "-27.8042",
    Longitude: "32.4627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12645",
    WardLookupId: "3742",
    VDNumber: "43416176",
    RegisteredPopulation: "936",
    VotingStationName: "QOMUKUPHILA PRIMARY SCHOOL",
    Address:
      "QOMUKUPHILA AREA  OPPOSITE QOMUKUPHILA COMMUNITY HALL  NIBELA TRIBAL",
    Latitude: "-27.8453",
    Longitude: "32.4575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12646",
    WardLookupId: "3742",
    VDNumber: "43416356",
    RegisteredPopulation: "535",
    VotingStationName: "NIBELA PRIMARY SCHOOL",
    Address:
      "NIBELA AREA  NEXT TO VUSI`S TUCK SHOP AND SGQOKOSEMBUZI`S HOME  NIBELA TRIBAL AUTHORITY",
    Latitude: "-27.8982",
    Longitude: "32.4567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12647",
    WardLookupId: "3743",
    VDNumber: "43414196",
    RegisteredPopulation: "431",
    VotingStationName: "FALSE BAY OPEN SPACE TENT",
    Address: "R22 FALSE BAY ROAD  HLUHLUWE  MDLETSHENI TRADITIONAL",
    Latitude: "-27.9584",
    Longitude: "32.3145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12648",
    WardLookupId: "3743",
    VDNumber: "43414297",
    RegisteredPopulation: "244",
    VotingStationName: "PHINDA SONDELA CENTRE",
    Address:
      "PHINDA SONDELA CENTRE PHINDAS   PHINDA SONDELA COMPOUND ,FROM N2  TURN RIGHT  HLUHLUWE",
    Latitude: "-27.8872",
    Longitude: "32.2348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12649",
    WardLookupId: "3743",
    VDNumber: "43415221",
    RegisteredPopulation: "1113",
    VotingStationName: "HLUHLUWE LIBRARY HALL",
    Address:
      "163 ZEBRA STREET  HLUHLUWE , MUNICIPLAITY OFFICES LIBRARY BUILDING  HLUHLUWE",
    Latitude: "-28.0184",
    Longitude: "32.2734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12650",
    WardLookupId: "3743",
    VDNumber: "43415502",
    RegisteredPopulation: "247",
    VotingStationName: "GLENPARK PRIMARY FARM SCHOOL",
    Address:
      "GLENPARK FARM SCHOOL BUSHLANDS  BUSHLANDS VIA DUMAZULU LODGE BUSHLANDS STORE  MTUBATUBA BUSHLANDS",
    Latitude: "-28.1158",
    Longitude: "32.2896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12651",
    WardLookupId: "3743",
    VDNumber: "43417256",
    RegisteredPopulation: "1902",
    VotingStationName: "PHUMLANI SIVANANDA PRIMARY SCHOOL",
    Address: "DUBE STREET  HLUHLUWE  HLUHLUWE",
    Latitude: "-28.028",
    Longitude: "32.2714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12652",
    WardLookupId: "3744",
    VDNumber: "43410305",
    RegisteredPopulation: "188",
    VotingStationName: "SIZWAYINKOSI SENIOR PRIMARY SCHOOL",
    Address:
      "P470 MZINENE ROAD HLBISA  MDLETSHENI AREA MZINENE RESERVE HLABISA  MDLETSHENI TRADITIONAL AUTHORITY",
    Latitude: "-27.9646",
    Longitude: "32.117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12653",
    WardLookupId: "3744",
    VDNumber: "43410316",
    RegisteredPopulation: "677",
    VotingStationName: "BUKIMVELO HIGH SCHOOL",
    Address:
      "P470 HLUHLUWE ROAD  GABADELA RESERVE  MDLETSHENI TRADITIONAL AUTHORITY /UMKHANYAKUDE",
    Latitude: "-27.9751",
    Longitude: "32.0451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12654",
    WardLookupId: "3744",
    VDNumber: "43410451",
    RegisteredPopulation: "1009",
    VotingStationName: "MKHALIPHI HIGH SCHOOL",
    Address:
      "P451 INHLWATHI RD.  NHLWATHI RESERVE  MDLETSHENI TRADITIONAL AUTHORITY/UMKHANYAKUDE",
    Latitude: "-28.0019",
    Longitude: "31.9794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12655",
    WardLookupId: "3744",
    VDNumber: "43414792",
    RegisteredPopulation: "803",
    VotingStationName: "IZINTAMBANE COMBINED PRIMARY SCHOOL",
    Address:
      "D1917 HLUHLUWE ROAD  EZINTAMBANE RESERVE  MDLETSHENI TRADITIONAL AUTHORITY (UMKHANYAKUDE)",
    Latitude: "-27.9407",
    Longitude: "32.1758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12656",
    WardLookupId: "3744",
    VDNumber: "43414893",
    RegisteredPopulation: "483",
    VotingStationName: "NCWABAKAZI LOWER PRIMARY SCHOOL",
    Address:
      "P450 HLUHLUWE RD  NCWABAKAZI AREA  MDLETSHENI TRADITIONAL AUTHORITY/UMKHANYAKUDE",
    Latitude: "-27.9475",
    Longitude: "32",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12657",
    WardLookupId: "3744",
    VDNumber: "43415917",
    RegisteredPopulation: "789",
    VotingStationName: "NTAMINEMIDWA SECONDARY SCHOOL",
    Address:
      "P450 INHLWATHI RD.  MTHWADLWANA RESERVE  MDLETSHENI TRADITIONAL AUTHORITY /UMKHANYAKUDE",
    Latitude: "-27.9576",
    Longitude: "31.9628",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12658",
    WardLookupId: "3744",
    VDNumber: "43417335",
    RegisteredPopulation: "432",
    VotingStationName: "MAFA PRIMARY SCHOOL",
    Address:
      "A2115 KOFENISI  KOFENISI AREA  MDLETSHENI TRADITIONAL AUTHORITY/UMKHANYAKUDE",
    Latitude: "-27.9566",
    Longitude: "32.0348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12659",
    WardLookupId: "3744",
    VDNumber: "43419023",
    RegisteredPopulation: "432",
    VotingStationName: "MAJINDI PRIMARY SCHOOL",
    Address:
      "P470 MAKHOWE ROAD  MAKHOWE AREA  MDLETSHENI TRADITIONAL AUTHORITY",
    Latitude: "-27.9608",
    Longitude: "32.1424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12660",
    WardLookupId: "3744",
    VDNumber: "43419270",
    RegisteredPopulation: "191",
    VotingStationName: "QALINDLELA PRIMARY SCHOOL",
    Address:
      "A2118 OFF A 2120 VUKANI ROAD  SINKONKONKO AREA  MDLETSHENI TRIBAL AUTHORITY",
    Latitude: "-27.9866",
    Longitude: "31.9453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12661",
    WardLookupId: "3745",
    VDNumber: "43414332",
    RegisteredPopulation: "853",
    VotingStationName: "EZIFUNDENI HIGH SCHOOL",
    Address:
      "P453 MDLETSHENI RD.  EZIFUNDENI AREA  HLABISA MDLETSHENI TRADITIONAL /UMKHANYAKUDE",
    Latitude: "-28.0069",
    Longitude: "32.1551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12662",
    WardLookupId: "3745",
    VDNumber: "43414343",
    RegisteredPopulation: "572",
    VotingStationName: "MAKHOWE PRIMARY SCHOOL",
    Address: "P543 MDLETSHENI  MZINENE AREA  MDLETSHENI TRADITIONAL AUTHORITY",
    Latitude: "-27.9906",
    Longitude: "32.1305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12663",
    WardLookupId: "3745",
    VDNumber: "43414354",
    RegisteredPopulation: "617",
    VotingStationName: "HLUHLUWE PRIMARY SCHOOL",
    Address:
      "P470 HLUHLUWE ROAD  KWAQHOQHOQHO AREA  MDLETSHENI TRADITIONAL AUTHORITY",
    Latitude: "-27.9839",
    Longitude: "32.183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12664",
    WardLookupId: "3745",
    VDNumber: "43414365",
    RegisteredPopulation: "881",
    VotingStationName: "CWAKEME HIGH SCHOOL",
    Address:
      "P470 MDLETSHENI  KWAQHOQHOQHO AREA  MDLETSHENI TRADITIONAL AUTHORITY (UMKHANYAKUDE)",
    Latitude: "-27.9717",
    Longitude: "32.1707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12665",
    WardLookupId: "3745",
    VDNumber: "43416121",
    RegisteredPopulation: "435",
    VotingStationName: "MACABUZELA PRIMARY SCHOOL",
    Address:
      "D855 MDLETSHENI RD.  MACABUZELA AREA  MDLETSHENI TRADITIONAL AUTHORITY /UMKHANYAKUDE",
    Latitude: "-28.0272",
    Longitude: "32.158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12666",
    WardLookupId: "3745",
    VDNumber: "43419034",
    RegisteredPopulation: "522",
    VotingStationName: "EKUPHILENI APOSTOLIC CHURCH",
    Address: "P475 HLUHLUWE ROAD  KWASMOLO  MDLETSHENI TRADITIONAL AUTHORITY",
    Latitude: "-28.0161",
    Longitude: "32.1971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12667",
    WardLookupId: "3745",
    VDNumber: "43419078",
    RegisteredPopulation: "836",
    VotingStationName: "INTUTHUKOYAMAZULU PRIMARY SCHOOL",
    Address: "P453 EZIFUNDENI  EZIFUNDENI AREA  MDLETSHENI TRIBAL AUTHORITY",
    Latitude: "-28.0174",
    Longitude: "32.1955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12668",
    WardLookupId: "3746",
    VDNumber: "43413870",
    RegisteredPopulation: "213",
    VotingStationName: "HLUHLUWE MFOLOZI GAME RESERVE TENT",
    Address: "R618 NONGOMA ROAD  HLABISA  ABAKWAHLABISA TRADITIONAL AUTHORITY",
    Latitude: "-28.2504",
    Longitude: "31.9905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12669",
    WardLookupId: "3746",
    VDNumber: "43414310",
    RegisteredPopulation: "752",
    VotingStationName: "DULIKHULU COMBINED PRIMARY SCHOOL",
    Address:
      "D8565 HLUHLUWE DAM ROAD  NOMPONDO AREA  MDLETSHENI TRADITIONAL AUTHORITY(UMKHANYAKUDE)",
    Latitude: "-28.089",
    Longitude: "32.1816",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12670",
    WardLookupId: "3746",
    VDNumber: "43414321",
    RegisteredPopulation: "926",
    VotingStationName: "MAGENGEBULA HIGHER PRIMARY SCHOOL",
    Address: "P453 HLUHLUWE  NOMPONDO AREA  HLUHLUWE",
    Latitude: "-28.0513",
    Longitude: "32.1723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12671",
    WardLookupId: "3746",
    VDNumber: "43415120",
    RegisteredPopulation: "575",
    VotingStationName: "THUTHUKANI CRECHE",
    Address: "A2136 MACABUZELA  ODAKANENI AREA  MDLETSHENI TRIBAL AUTHORITY",
    Latitude: "-28.0291",
    Longitude: "32.1314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12672",
    WardLookupId: "3746",
    VDNumber: "43419045",
    RegisteredPopulation: "543",
    VotingStationName: "MPELENYANE JUNIOR PRIMARY SCHOOL",
    Address:
      "D1810 MPELENYANE ROAD  MPELENYANE RESERVE  ABAKWAHLABISA TRADITIONAL",
    Latitude: "-28.1859",
    Longitude: "31.8676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12673",
    WardLookupId: "3746",
    VDNumber: "43419067",
    RegisteredPopulation: "594",
    VotingStationName: "BONGA HIGH SCHOOL",
    Address:
      "A2136 HLUHLUWE ROAD  MZINENE AREA  MDLETSHENI TRADITIONAL  AUTHORITY",
    Latitude: "-27.9936",
    Longitude: "32.1116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12674",
    WardLookupId: "3746",
    VDNumber: "43419326",
    RegisteredPopulation: "636",
    VotingStationName: "SIPHOSABADLETSHE JUNIOR SECONDARY SCHOOL",
    Address:
      "D856 HLUHLUWE DAM RD  NOMPONDO AREA  MDLETSHENI TRSDITIONAL AUTHORITY",
    Latitude: "-28.0674",
    Longitude: "32.1674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12675",
    WardLookupId: "3747",
    VDNumber: "43410226",
    RegisteredPopulation: "643",
    VotingStationName: "EKUPHINDISENI COMBINED PRIMARY SCHOOL",
    Address:
      "D1905 MDLETSHENI  EKUPHINDISENI AREA  MDLETSHENI TRAADITIONAL (UMKHANYAKUDE)",
    Latitude: "-28.0313",
    Longitude: "32.0442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12676",
    WardLookupId: "3747",
    VDNumber: "43410293",
    RegisteredPopulation: "1060",
    VotingStationName: "PHUMOWAKHE SENIOR PRIMARY SCHOOL",
    Address:
      "P470 HLUHLUWE ROAD  MATSHAMHLOPHE RESERVE  MDLETSHENI /UMKHANYAKUDE",
    Latitude: "-27.9939",
    Longitude: "31.9982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12677",
    WardLookupId: "3747",
    VDNumber: "43415131",
    RegisteredPopulation: "740",
    VotingStationName: "EZIBAYENI PRIMARY SCHOOL",
    Address:
      "P475 EZIBAYENI RD.  EZIBAYENI RESERVE  MDLETSHENI TRADITIONAL AUTHORITY(UMKHANYAKUDE)",
    Latitude: "-28.0005",
    Longitude: "32.0606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12678",
    WardLookupId: "3747",
    VDNumber: "43416110",
    RegisteredPopulation: "368",
    VotingStationName: "GULA PRIMARY SCHOOL",
    Address: "A2131 GULA ROAD  GULA RESERVE  MDLETSHENI TRADITIONAL AUTHORITY",
    Latitude: "-28.0294",
    Longitude: "32.0075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12679",
    WardLookupId: "3747",
    VDNumber: "43416301",
    RegisteredPopulation: "542",
    VotingStationName: "IZINDABAZAMANINA HIGH SCHOOL",
    Address: "D453 MDLETSHENI RD.  MANSIYA RESERVE  HLABISA/UMKHANYAKUDE",
    Latitude: "-28.0429",
    Longitude: "32.0306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12680",
    WardLookupId: "3747",
    VDNumber: "43419012",
    RegisteredPopulation: "259",
    VotingStationName: "SICELAMANDLA CRECHE",
    Address: "P475 EZIBAYENI  EZIBAYENI AREA  MDLETSHENI TRIBAL AUTHORITY",
    Latitude: "-27.9939",
    Longitude: "32.0425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12681",
    WardLookupId: "3748",
    VDNumber: "43410237",
    RegisteredPopulation: "638",
    VotingStationName: "MPANZAKAZI PRIMARY SCHOOL",
    Address:
      "A2122 NHLWATHI  EGULA/NHLWATHI  HLABISA/MDLETSHENI TRADITIONAL AUTHORITY",
    Latitude: "-28.055",
    Longitude: "31.9936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12682",
    WardLookupId: "3748",
    VDNumber: "43410248",
    RegisteredPopulation: "527",
    VotingStationName: "MGANGATHO COMBINED PRIMARY SCHOOL",
    Address:
      "A743 MGANGATHO AREA  MGANGATHO RESERVE  HLABISA ABASEMPEMBENI (UMKHANYAKUDE)",
    Latitude: "-28.0647",
    Longitude: "31.8752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12683",
    WardLookupId: "3748",
    VDNumber: "43410259",
    RegisteredPopulation: "669",
    VotingStationName: "MAYAKAZI PRIMARY SCHOOL",
    Address:
      "MPEMBENI ROAD  MGANGATHO/MPEMBENI  HLABISA ABASEMPEMBENI (UMKHANYAKUDE)",
    Latitude: "-28.0715",
    Longitude: "31.9539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12684",
    WardLookupId: "3748",
    VDNumber: "43410282",
    RegisteredPopulation: "809",
    VotingStationName: "SOVANE PRIMARY SCHOOL",
    Address: "D1905 SOVANE AREA  HLABISA ABASEMPEMBENI",
    Latitude: "-28.1118",
    Longitude: "31.971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12685",
    WardLookupId: "3748",
    VDNumber: "43414882",
    RegisteredPopulation: "720",
    VotingStationName: "NKIWANENI PRIMARY SCHOOL",
    Address:
      "A2129 OFF REMP DF1905 EKUPHINDISENI.  OPHAPHASI AREA MDLETSHENI  HLABISA/UMKHANYAKUDE",
    Latitude: "-28.0796",
    Longitude: "31.9756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12686",
    WardLookupId: "3748",
    VDNumber: "43417975",
    RegisteredPopulation: "592",
    VotingStationName: "MTHEKELEZI PRIMARY SCHOOL",
    Address:
      "D1905 MPEMPENI ROAD  EZIDONINI RESERVE  ABASEMPEMBENI TRADITIONAL AUTHORITY",
    Latitude: "-28.1335",
    Longitude: "31.9787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12687",
    WardLookupId: "3748",
    VDNumber: "43418998",
    RegisteredPopulation: "617",
    VotingStationName: "INHLWATHI PRIMARY SCHOOL",
    Address:
      "A2122 OFF P451 ROADNHLWATHI MISSION  INHLWATHI MISSION  MDLETSHENI TRIBAL AUTHORITY",
    Latitude: "-28.0177",
    Longitude: "31.9767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12688",
    WardLookupId: "3749",
    VDNumber: "43410260",
    RegisteredPopulation: "1112",
    VotingStationName: "INQUBEKO PRIMARY SCHOOL",
    Address:
      "P451 MPEMBENI ROAD  MPEMBENI AREA  HLABISA TOWN MPEMBENI TRADITIONAL AUTHORITY",
    Latitude: "-28.1354",
    Longitude: "31.9308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12689",
    WardLookupId: "3749",
    VDNumber: "43414040",
    RegisteredPopulation: "644",
    VotingStationName: "BAZANENI LOWER PRIMARY SCHOOL",
    Address: "P453 MPEMBENI RD.  BAZANENI AREA  HLABISA ABAKWA (UMKHANYAKUDE)",
    Latitude: "-28.1415",
    Longitude: "31.8956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12690",
    WardLookupId: "3749",
    VDNumber: "43414062",
    RegisteredPopulation: "674",
    VotingStationName: "THEMBAMANDLA LOWER PRIMARY SCHOOL",
    Address:
      "A1799 HLABISA MABHOKWENI ROAD  ESTEZI RESERVE HLABISA  HLABISA ABASEMPEMBENI TRADITIONAL AUTHORITY",
    Latitude: "-28.1121",
    Longitude: "31.8891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12691",
    WardLookupId: "3749",
    VDNumber: "43417852",
    RegisteredPopulation: "302",
    VotingStationName: "SIYAZAMA CRECHE",
    Address:
      "A2152 MPEMBENI ROAD KOSEME  KOSEME MPEMBENI RESERVE  HLABISA,MPEMBENI TRIBAL",
    Latitude: "-28.1436",
    Longitude: "31.9357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12692",
    WardLookupId: "3749",
    VDNumber: "43418976",
    RegisteredPopulation: "601",
    VotingStationName: "IMBALIKHONA JUNIOR PRIMARY SCHOOL",
    Address:
      "P451 MBEMBENI  MATHUNZI RESERVE MPEMBENI AREA  HLABISA / MPEMBENI TRIBAL AUTHORITY",
    Latitude: "-28.1219",
    Longitude: "31.935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12693",
    WardLookupId: "3749",
    VDNumber: "43418987",
    RegisteredPopulation: "315",
    VotingStationName: "EKWETHEMBENI PRIMARY SCHOOL",
    Address:
      "A2152 EKWETHEMBENI RESERVE MPEMBENI   EKWETHEMBENI AREA  HLABISA / MPEMBENI TRIBAL AUTHORITY",
    Latitude: "-28.1158",
    Longitude: "31.9088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12694",
    WardLookupId: "3749",
    VDNumber: "43419001",
    RegisteredPopulation: "777",
    VotingStationName: "MBOPHA HIGH SCHOOL",
    Address: "A1799 HLABISA MISSION  HLABISA ABAKWAHLABISA TRADITIONAL",
    Latitude: "-28.1386",
    Longitude: "31.8787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12695",
    WardLookupId: "3750",
    VDNumber: "43414039",
    RegisteredPopulation: "1047",
    VotingStationName: "MABHOKISINI COMMUNITY HALL",
    Address: "R618 NONGOMA ROAD  MABHOKISINI  HLABISA ABAKWA/UMKHANYAKUDE",
    Latitude: "-28.1798",
    Longitude: "31.8688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12696",
    WardLookupId: "3750",
    VDNumber: "43450039",
    RegisteredPopulation: "2738",
    VotingStationName: "ISBANI PRIMARY SCHOOL",
    Address: "P451 MPEMBENI ROAD  HLABISA MISSION  ABAKWAHLABISA",
    Latitude: "-28.1445",
    Longitude: "31.8785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12697",
    WardLookupId: "3750",
    VDNumber: "43450073",
    RegisteredPopulation: "410",
    VotingStationName: "MALUSI OMUHLE PRIMARY SCHOOL",
    Address:
      "D1907 MATSHAMNYAMA ROAD  NKANJINI AREA  HLABISA TOWN ABAKWAHLABISA TRADITIONAL",
    Latitude: "-28.1414",
    Longitude: "31.8589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12698",
    WardLookupId: "3751",
    VDNumber: "43414017",
    RegisteredPopulation: "1097",
    VotingStationName: "HLAMBANYATHI PRIMARY SCHOOL",
    Address:
      "D1810 MPELENYANE RD,HLAMBANYATHI.  HLABISA ABAKWAHLABISA (UMKHANYAKUDE DISTRICT)",
    Latitude: "-28.1771",
    Longitude: "31.8186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12699",
    WardLookupId: "3751",
    VDNumber: "43414028",
    RegisteredPopulation: "689",
    VotingStationName: "MMISO JUNIOR SECONDARY SCHOOL",
    Address:
      "D1907 MATSHAMNYAMA HLABISA RESERVE MATSHAMNYAMA AREA NEXT TO TRADITIONAL COURT HLABISA ABAKWAHLABISA",
    Latitude: "-28.1314",
    Longitude: "31.8606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12700",
    WardLookupId: "3751",
    VDNumber: "43414051",
    RegisteredPopulation: "844",
    VotingStationName: "NGEBEZA HIGH SCHOOL",
    Address: "R618 NONGOMA ROAD  HLABISA QUNWANE AREA  HLABISA/ ABAKWAHLABISA",
    Latitude: "-28.0674",
    Longitude: "31.8405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12701",
    WardLookupId: "3751",
    VDNumber: "43414073",
    RegisteredPopulation: "1240",
    VotingStationName: "PHUNYANE PRIMARY SCHOOL",
    Address:
      "D907 MATSHAMNYAMA NHLAHLENI AREA  MATSHAMNYAMA AREA  HLABISA /ABAKWAHLABISA",
    Latitude: "-28.1317",
    Longitude: "31.8377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12702",
    WardLookupId: "3751",
    VDNumber: "43417986",
    RegisteredPopulation: "627",
    VotingStationName: "QUNWANE COMMUNITY HALL",
    Address: "R618 QUNWANE   QUNWANE AREA HLABISA  ABAKWAHLABISA/QUNWANE",
    Latitude: "-28.0509",
    Longitude: "31.8392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12703",
    WardLookupId: "3752",
    VDNumber: "43413588",
    RegisteredPopulation: "1407",
    VotingStationName: "LANGALIBOMVU HIGH SCHOOL",
    Address: "HLANZENI  SOKHULU RESERVE  SOKHULU TRIBAL AUTHORITY",
    Latitude: "-28.5223",
    Longitude: "32.3639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12704",
    WardLookupId: "3752",
    VDNumber: "43413599",
    RegisteredPopulation: "754",
    VotingStationName: "GROSOTTA FAITH MISSION CHURCH",
    Address:
      "MANZAMNYAMA NEXT TO SIGWAZA   SOKHULU RESERVE  SOKHULU TRIBAL AUTHORITY",
    Latitude: "-28.5525",
    Longitude: "32.3213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12705",
    WardLookupId: "3752",
    VDNumber: "43418864",
    RegisteredPopulation: "914",
    VotingStationName: "KHULASANDE CRECHE",
    Address:
      "HLANZENI AT FUNDISANANI STORE  SOKHULU RESERVE  SOKHULU TRIBAL AUTHORITY",
    Latitude: "-28.5029",
    Longitude: "32.3498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12706",
    WardLookupId: "3752",
    VDNumber: "43418875",
    RegisteredPopulation: "551",
    VotingStationName: "SIYAGAQA CRECH",
    Address: "ETHUKWINI  KWA-MBONAMBI  SOKHULU TRIBAL AUTHORITY",
    Latitude: "-28.5306",
    Longitude: "32.3484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12707",
    WardLookupId: "3753",
    VDNumber: "43413511",
    RegisteredPopulation: "2555",
    VotingStationName: "KWAMBONAMBI PRIMARY SCHOOL",
    Address: "BREDELIA STREET  KWAMBONAMBI  KWAMBONAMBI",
    Latitude: "-28.5986",
    Longitude: "32.0833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12708",
    WardLookupId: "3753",
    VDNumber: "43413656",
    RegisteredPopulation: "738",
    VotingStationName: "EMPUMELELWENI PRIMARY SCHOOL",
    Address: "N/A EMPUMELELWENI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.5507",
    Longitude: "32.0566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12709",
    WardLookupId: "3753",
    VDNumber: "43415119",
    RegisteredPopulation: "499",
    VotingStationName: "MTHWANA PRIMARY SCHOOL",
    Address: "EMPUMENI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.5108",
    Longitude: "32.0829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12710",
    WardLookupId: "3753",
    VDNumber: "43415614",
    RegisteredPopulation: "188",
    VotingStationName: "K16 FARM HALL",
    Address: "N2 ROAD AT NSELENI STATION  KWA-MBONAMBI  KWA-MBONAMBI",
    Latitude: "-28.6768",
    Longitude: "32.0444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12711",
    WardLookupId: "3753",
    VDNumber: "43417627",
    RegisteredPopulation: "499",
    VotingStationName: "PHOZIPHOZI PRIMARY SCHOOL",
    Address: "N/A EZIDONINI  MTHETHWA RESERVE   MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.5439",
    Longitude: "32.0657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12712",
    WardLookupId: "3754",
    VDNumber: "43413533",
    RegisteredPopulation: "1307",
    VotingStationName: "MANQAMU HIGH SCHOOL",
    Address: "N/A NKUNZEBOMVU  KWA-MBONAMBI  NKUNZEBOMVU TRIBAL AUTHORITY",
    Latitude: "-28.6761",
    Longitude: "32.2045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12713",
    WardLookupId: "3754",
    VDNumber: "43413555",
    RegisteredPopulation: "731",
    VotingStationName: "EMANKWATHINI PRIMARY SCHOOL",
    Address:
      "N/A EMANKWATHINI  KWAMBONAMBI RESERVE  NKUNZEBOMVU TRIBAL AUTHORITY",
    Latitude: "-28.6538",
    Longitude: "32.2094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12714",
    WardLookupId: "3754",
    VDNumber: "43415625",
    RegisteredPopulation: "1097",
    VotingStationName: "NHLABANE PRIMARY SCHOOL",
    Address: "N/A NHLABANE  KWA-MBONAMBI RESERVE  NKUNZEBOMVU TRIBAL AUTHORITY",
    Latitude: "-28.6415",
    Longitude: "32.2395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12715",
    WardLookupId: "3754",
    VDNumber: "43418886",
    RegisteredPopulation: "535",
    VotingStationName: "GOYINTETHE CRECHE",
    Address: "GOYINTETHE  KWA-MBONAMBI  MONAMBI TRIBAL AUTHORITY",
    Latitude: "-28.6687",
    Longitude: "32.2231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12716",
    WardLookupId: "3755",
    VDNumber: "43413566",
    RegisteredPopulation: "997",
    VotingStationName: "NDLABEYILANDULA HIGH SCHOOL",
    Address: "N/A NDLABEYILANDULA  KWA-MBONAMBI RESERVE  NKUNZEBOMVU RESERVE",
    Latitude: "-28.6311",
    Longitude: "32.2279",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12717",
    WardLookupId: "3755",
    VDNumber: "43413577",
    RegisteredPopulation: "762",
    VotingStationName: "ONTINGWENI PRIMARY SCHOOL",
    Address: "N/A ONTINGWENI  SOKHULU RESERVE  KWAMBONAMBI",
    Latitude: "-28.5733",
    Longitude: "32.2639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12718",
    WardLookupId: "3755",
    VDNumber: "43413601",
    RegisteredPopulation: "1276",
    VotingStationName: "SIBULULWANE PRIMARY SCHOOL",
    Address:
      "N/A EMALALENI APHEZULU  SOKHULU RESERVE  SOKHULU TRIBAL AUTHORITY",
    Latitude: "-28.5208",
    Longitude: "32.2785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12719",
    WardLookupId: "3755",
    VDNumber: "43416424",
    RegisteredPopulation: "100",
    VotingStationName: "PENICUIK FARM HALL",
    Address: "N2 PENICUIK FARM  KWA-MBONAMBI  KWA-MBONAMBI",
    Latitude: "-28.574",
    Longitude: "32.1108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12720",
    WardLookupId: "3755",
    VDNumber: "43417571",
    RegisteredPopulation: "764",
    VotingStationName: "DUMISANE PRE-SCHOOL",
    Address: "N/A EMALALENI APHANSI  SOKHULU RESERVE  SOKHULU TRIBAL AUTHORITY",
    Latitude: "-28.5379",
    Longitude: "32.306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12721",
    WardLookupId: "3755",
    VDNumber: "43417818",
    RegisteredPopulation: "924",
    VotingStationName: "DLEMUDLEMU HIGH SCHOOL",
    Address: "N/A EMANZAMNYAMA  SOKHULU RESERVE  SOKHULU TRIBAL AUTHORITY",
    Latitude: "-28.5524",
    Longitude: "32.3126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12722",
    WardLookupId: "3756",
    VDNumber: "43416075",
    RegisteredPopulation: "836",
    VotingStationName: "MABHODLA PRIMARY SCHOOL",
    Address: "N/A ESHOBENI  KWA-MBONAMBI RESERVE  NKUNZEBOMVU TRIBAL AUTHORITY",
    Latitude: "-28.7017",
    Longitude: "32.1569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12723",
    WardLookupId: "3756",
    VDNumber: "43416996",
    RegisteredPopulation: "1835",
    VotingStationName: "ENHLANZINI PRIMARY SCHOOL",
    Address:
      "N/A ENHLANZINI  KWA-MBONAMBI RESERVE  NKUNZEBOMVU TRIBAL AUTHORITY",
    Latitude: "-28.698",
    Longitude: "32.1804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12724",
    WardLookupId: "3756",
    VDNumber: "43418000",
    RegisteredPopulation: "1340",
    VotingStationName: "IKHAYA LETHEMBA COMMUNITY PROJECT",
    Address:
      "N/A NZALABANTU  KWA-MBONAMBI RESERVE  NKUNZEBOMVU TRIBAL AUTHORITY",
    Latitude: "-28.7123",
    Longitude: "32.1635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12725",
    WardLookupId: "3756",
    VDNumber: "43418897",
    RegisteredPopulation: "1039",
    VotingStationName: "VUKUSEBENZE SEWING CENTRE",
    Address: "NZALABANTU   KWA-MBONAMBI  KWA-MBONAMBI",
    Latitude: "-28.7116",
    Longitude: "32.1698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12726",
    WardLookupId: "3757",
    VDNumber: "43413522",
    RegisteredPopulation: "3310",
    VotingStationName: "MZINGAZI PRIMARY SCHOOL",
    Address: "N/A MZINGAZI  KWAMBONAMBI RESERVE  NKUNZEBOMVU TRIBAL AUTHORITY",
    Latitude: "-28.7491",
    Longitude: "32.1387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12727",
    WardLookupId: "3757",
    VDNumber: "43418909",
    RegisteredPopulation: "1232",
    VotingStationName: "ENTOKOZWENI CRECH",
    Address: "N/A MZINGAZI  KWA-MBONAMBI RESERVE  NKUNZEBOMVU TRIBAL AUTHORITY",
    Latitude: "-28.7447",
    Longitude: "32.1432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12728",
    WardLookupId: "3758",
    VDNumber: "43413432",
    RegisteredPopulation: "1302",
    VotingStationName: "BALONDO HIGH SCHOOL",
    Address: "MABHUYENI  MHLANA  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.6288",
    Longitude: "31.9688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12729",
    WardLookupId: "3758",
    VDNumber: "43413454",
    RegisteredPopulation: "1205",
    VotingStationName: "CANAAN PRIMARY SCHOOL",
    Address: "N/A NTOYENI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.6033",
    Longitude: "31.9924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12730",
    WardLookupId: "3758",
    VDNumber: "43416682",
    RegisteredPopulation: "1197",
    VotingStationName: "CWAKA PRIMARY SCHOOL",
    Address: "N/A MABHUYENI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.6237",
    Longitude: "31.936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12731",
    WardLookupId: "3758",
    VDNumber: "43416693",
    RegisteredPopulation: "759",
    VotingStationName: "SIBONOKUHLE HIGH SCHOOL",
    Address: "N/A EMABHUYENI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.6455",
    Longitude: "31.9699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12732",
    WardLookupId: "3759",
    VDNumber: "43413465",
    RegisteredPopulation: "2014",
    VotingStationName: "UYENGO HIGH SCHOOL",
    Address: "N/A EMBABE  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.5862",
    Longitude: "32.0219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12733",
    WardLookupId: "3759",
    VDNumber: "43416435",
    RegisteredPopulation: "585",
    VotingStationName: "QEDUMONA PRIMARY SCHOOL",
    Address: "N/A MAKHANDENI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.5563",
    Longitude: "32.0111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12734",
    WardLookupId: "3759",
    VDNumber: "43417616",
    RegisteredPopulation: "1407",
    VotingStationName: "HOMELEIGH COMBINED SCHOOL",
    Address: "N/A THANDAZA  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.5734",
    Longitude: "32.0429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12735",
    WardLookupId: "3759",
    VDNumber: "43418921",
    RegisteredPopulation: "848",
    VotingStationName: "EKUTHULENI CRECH",
    Address: "N/A EKUTHULENI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.5603",
    Longitude: "32.0504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12736",
    WardLookupId: "3760",
    VDNumber: "43413241",
    RegisteredPopulation: "1307",
    VotingStationName: "THEMBALIMBE PRIMARY SCHOOL",
    Address: "DONDOTHA  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.5509",
    Longitude: "31.9469",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12737",
    WardLookupId: "3760",
    VDNumber: "43413443",
    RegisteredPopulation: "636",
    VotingStationName: "MNQAGAYI PRIMARY SCHOOL",
    Address: "N/A MNQAGAYI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.5809",
    Longitude: "31.9193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12738",
    WardLookupId: "3760",
    VDNumber: "43415636",
    RegisteredPopulation: "1123",
    VotingStationName: "MGATSHELWA PRIMARY SCHOOL",
    Address: "N/A KWALAMULA  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.5951",
    Longitude: "31.9367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12739",
    WardLookupId: "3760",
    VDNumber: "43416086",
    RegisteredPopulation: "570",
    VotingStationName: "ZAMIMPILO CRECHE",
    Address: "N/A MAKHANDENI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.5566",
    Longitude: "31.9829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12740",
    WardLookupId: "3760",
    VDNumber: "43417605",
    RegisteredPopulation: "609",
    VotingStationName: "SOMOTHA PRIMARY SCHOOL",
    Address: "N/A OQAZWINI   MTHETHWA RESERVE   MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.58",
    Longitude: "31.9668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12741",
    WardLookupId: "3760",
    VDNumber: "43417829",
    RegisteredPopulation: "608",
    VotingStationName: "ANGLICAN CHURCH",
    Address: "N/A DONDOTHA  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.563",
    Longitude: "31.937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12742",
    WardLookupId: "3761",
    VDNumber: "43413252",
    RegisteredPopulation: "439",
    VotingStationName: "MVAMANZI PRIMARY SCHOOL",
    Address: "N/A MVAMANZI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.497",
    Longitude: "31.9292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12743",
    WardLookupId: "3761",
    VDNumber: "43413285",
    RegisteredPopulation: "441",
    VotingStationName: "GEGEDE PRIMARY SCHOOL",
    Address: "GEGEDE  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.4467",
    Longitude: "31.9617",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12744",
    WardLookupId: "3761",
    VDNumber: "43413296",
    RegisteredPopulation: "385",
    VotingStationName: "MUNDI SECONDARY SCHOOL",
    Address: "ENKANYEZI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.4946",
    Longitude: "31.9738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12745",
    WardLookupId: "3761",
    VDNumber: "43413308",
    RegisteredPopulation: "930",
    VotingStationName: "MCUTHUNGU PRIMARY SCHOOL",
    Address: "NHLABOSINI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.4966",
    Longitude: "31.9976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12746",
    WardLookupId: "3761",
    VDNumber: "43416097",
    RegisteredPopulation: "604",
    VotingStationName: "LUHLANGA PRIMARY SCHOOL",
    Address: "EMATHOLENI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.5338",
    Longitude: "31.9685",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12747",
    WardLookupId: "3761",
    VDNumber: "43416402",
    RegisteredPopulation: "663",
    VotingStationName: "KHISHWA PRIMARY SCHOOL",
    Address: "EMATHOLENI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.5165",
    Longitude: "31.9561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12748",
    WardLookupId: "3761",
    VDNumber: "43418011",
    RegisteredPopulation: "574",
    VotingStationName: "UMBIYA HIGH SCHOOL",
    Address: "EPHATHANE  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.4931",
    Longitude: "32.0173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12749",
    WardLookupId: "3762",
    VDNumber: "43413689",
    RegisteredPopulation: "1509",
    VotingStationName: "EKUPHELENI HIGH SCHOOL",
    Address: "CINGCI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.4719",
    Longitude: "32.105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12750",
    WardLookupId: "3762",
    VDNumber: "43416985",
    RegisteredPopulation: "770",
    VotingStationName: "NODUMO CRECHE",
    Address: "N/A NODUMO  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.4548",
    Longitude: "32.1157",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12751",
    WardLookupId: "3762",
    VDNumber: "43417582",
    RegisteredPopulation: "936",
    VotingStationName: "FREE METHODIST CHURCH",
    Address: "N/A EMPUMENI  MTHETHWA RESERVE  NHLANA TRIBAL AUTHORITY",
    Latitude: "-28.4925",
    Longitude: "32.1171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12752",
    WardLookupId: "3762",
    VDNumber: "43417830",
    RegisteredPopulation: "1006",
    VotingStationName: "SALPINE PRIMARY SCHOOL",
    Address: "N/A CINGCI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.4773",
    Longitude: "32.1286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12753",
    WardLookupId: "3762",
    VDNumber: "44490012",
    RegisteredPopulation: "344",
    VotingStationName: "IMPILO YESIZWE CENTRE",
    Address: "ENYONINI  KWA-MBONAMBI  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.4634",
    Longitude: "32.079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12754",
    WardLookupId: "3763",
    VDNumber: "43413218",
    RegisteredPopulation: "1769",
    VotingStationName: "EFUYENI PRIMARY SCHOOL",
    Address: "EFUYENI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.4877",
    Longitude: "31.8614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12755",
    WardLookupId: "3763",
    VDNumber: "43413230",
    RegisteredPopulation: "906",
    VotingStationName: "SABHUZA PRIMARY SCHOOL",
    Address: "ESABHUZA  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.5372",
    Longitude: "31.9086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12756",
    WardLookupId: "3763",
    VDNumber: "43416244",
    RegisteredPopulation: "1206",
    VotingStationName: "NOMUWA PRIMARY SCHOOL",
    Address: "EMAKHWEZINI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.5106",
    Longitude: "31.8878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12757",
    WardLookupId: "3763",
    VDNumber: "43416390",
    RegisteredPopulation: "699",
    VotingStationName: "MPHATHISWANO PRIMARY SCHOOL",
    Address: "N/A EMATHOLENI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.5247",
    Longitude: "31.9296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12758",
    WardLookupId: "3764",
    VDNumber: "43413263",
    RegisteredPopulation: "805",
    VotingStationName: "SIYOKOMANE PRIMARY SCHOOL",
    Address: "NTUTHUNGA 2  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.429",
    Longitude: "31.9247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12759",
    WardLookupId: "3764",
    VDNumber: "43413274",
    RegisteredPopulation: "791",
    VotingStationName: "OCILWANE PRIMARY SCHOOL",
    Address: "OCILWANE  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.4073",
    Longitude: "31.9872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12760",
    WardLookupId: "3764",
    VDNumber: "43414837",
    RegisteredPopulation: "544",
    VotingStationName: "NOVUNULA PRIMARY SCHOOL",
    Address: "NOVUNULA  MTHETHWA RESREVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.3711",
    Longitude: "31.9887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12761",
    WardLookupId: "3764",
    VDNumber: "43415096",
    RegisteredPopulation: "911",
    VotingStationName: "NTUTHUNGA PRIMARY SCHOOL",
    Address: "N/A NTUTHUNGA 1  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.46",
    Longitude: "31.926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12762",
    WardLookupId: "3764",
    VDNumber: "43416413",
    RegisteredPopulation: "330",
    VotingStationName: "MENDU PRIMARY SCHOOL",
    Address: "KWA-MENDU  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.4498",
    Longitude: "31.9975",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12763",
    WardLookupId: "3764",
    VDNumber: "43417436",
    RegisteredPopulation: "217",
    VotingStationName: "DUTCH CHURCH",
    Address: "N/A KWAMENDU  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.4419",
    Longitude: "31.9954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12764",
    WardLookupId: "3764",
    VDNumber: "43418965",
    RegisteredPopulation: "397",
    VotingStationName: "ESIGWACENI PRIMARY SCHOOL",
    Address: "SHAYAMOYA  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.4924",
    Longitude: "31.9082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12765",
    WardLookupId: "3765",
    VDNumber: "43417414",
    RegisteredPopulation: "1285",
    VotingStationName: "MKHAYIDENI HIGH SCHOOL",
    Address: "EMKHAYIDENI  KWA-MBONAMABI RESERVE  NKUNZEBOMVU TRIBAL AUTHORITY",
    Latitude: "-28.733",
    Longitude: "32.1516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12766",
    WardLookupId: "3765",
    VDNumber: "43417593",
    RegisteredPopulation: "1829",
    VotingStationName: "EKUVUKENI ANGLICAN CHURCH",
    Address:
      "N/A MZINGAZI   KWA-MBONAMBI RESERVE  NKUNZEBOMVU TRIBAL AUTHORITY",
    Latitude: "-28.7489",
    Longitude: "32.1332",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12767",
    WardLookupId: "3765",
    VDNumber: "43418943",
    RegisteredPopulation: "1198",
    VotingStationName: "VICTORY FELLOWSHIP CHURCH",
    Address: "TSHINGIMPISI  KWA-MBONAMBI  MABHODLA TRIBAL AUTHORITY",
    Latitude: "-28.7388",
    Longitude: "32.1491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12768",
    WardLookupId: "3766",
    VDNumber: "43413319",
    RegisteredPopulation: "709",
    VotingStationName: "ESIGAGANENI PRIMARY SCHOOL",
    Address: "N/A ESIGAGANENI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.5373",
    Longitude: "32.02",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12769",
    WardLookupId: "3766",
    VDNumber: "43413645",
    RegisteredPopulation: "1183",
    VotingStationName: "MBUSOWABATHETHWA HIGH SCHOOL",
    Address: "N/A NTOBOZI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.5301",
    Longitude: "32.043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12770",
    WardLookupId: "3766",
    VDNumber: "43413667",
    RegisteredPopulation: "905",
    VotingStationName: "NKIYANKIYA PRIMARY SCHOOL",
    Address: "N/A NKIYANKIYA   MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.476",
    Longitude: "32.0425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12771",
    WardLookupId: "3766",
    VDNumber: "43415647",
    RegisteredPopulation: "1094",
    VotingStationName: "WELABASHA HIGH SCHOOL",
    Address: "N/A OSHWASHWENI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.4431",
    Longitude: "32.0262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12772",
    WardLookupId: "3766",
    VDNumber: "43418932",
    RegisteredPopulation: "581",
    VotingStationName: "EKUSAYENI CRECHE",
    Address: "EKUSAYENI  MTHETHWA RESERVE  MHLANA TRIBAL AUTHORITY",
    Latitude: "-28.5132",
    Longitude: "32.0688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12773",
    WardLookupId: "3767",
    VDNumber: "43413544",
    RegisteredPopulation: "2737",
    VotingStationName: "NZALABANTU PRIMARY SCHOOL",
    Address: "ENZALABANTU  KWAMBONAMBI RESERVE  NKUNZEBOMBU TRIBAL AOUTHORITY",
    Latitude: "-28.7187",
    Longitude: "32.1621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12774",
    WardLookupId: "3767",
    VDNumber: "43418954",
    RegisteredPopulation: "916",
    VotingStationName: "FAITH MISSION CHURCH",
    Address:
      "EZINSIZWENI AT REV MTHIYANE  KWA-MBONAMBI  MABHODLA TRIBAL AUTHORITY",
    Latitude: "-28.7129",
    Longitude: "32.1592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12775",
    WardLookupId: "3768",
    VDNumber: "43413173",
    RegisteredPopulation: "416",
    VotingStationName: "NKONINGA PRIMARY SCHOOL",
    Address:
      "MAMBUKA ROAD-MVAZANE AREA MVAZANE AREA-MAMBUKA TAC EMPANGENI-MAMBUKA TRADITIONAL ADMINISTRATIVE CNCL",
    Latitude: "-28.5549",
    Longitude: "31.8159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12776",
    WardLookupId: "3768",
    VDNumber: "43413184",
    RegisteredPopulation: "792",
    VotingStationName: "MAQWABE COMBINED PRIMARY SCHOOL",
    Address:
      "NTAMBANANA-OTHINI AREA  OTHINI AREA-MABUKA TAC  EMPANGENI-MAMBUKA TRADITIONAL ADMINISTRATIVE COUNC",
    Latitude: "-28.5582",
    Longitude: "31.8647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12777",
    WardLookupId: "3768",
    VDNumber: "43413207",
    RegisteredPopulation: "1243",
    VotingStationName: "MANDLAZIN PRIMARY SCHOOL",
    Address:
      "NTAMBANANA-NTUZUMA AREA NTUZUMA AREA-MAMBUKA TAC EMPANGENI-MAMBUKA TRADITIONAL ADMINISTRATIVE COUNC",
    Latitude: "-28.5237",
    Longitude: "31.8382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12778",
    WardLookupId: "3768",
    VDNumber: "43413229",
    RegisteredPopulation: "713",
    VotingStationName: "BHILIYA COMBINED PRIMARY SCHOOL",
    Address:
      "D312 SANGOYANA ROAD-BHILIYA AREA BHILIYA AREA-SOMOPHO TRADITIONAL ADMIN COUNCIL EMPANGENI",
    Latitude: "-28.4535",
    Longitude: "31.8389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12779",
    WardLookupId: "3768",
    VDNumber: "43416895",
    RegisteredPopulation: "512",
    VotingStationName: "MNINGI HIGH SCHOOL",
    Address:
      "MAMBUKA ROAD-NTAMBANANA AREA NTAMBANANA AREA-MAMBUKA TAC EMPANGENI-MAMBUKA TRADITIONAL ADMIN COUNCIL",
    Latitude: "-28.5619",
    Longitude: "31.8542",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12780",
    WardLookupId: "3768",
    VDNumber: "43416907",
    RegisteredPopulation: "719",
    VotingStationName: "MVAZANA COMMUNITY HALL",
    Address: "MVAZANA  KWA-MBONAMBI  MTHIYANE",
    Latitude: "-28.5276",
    Longitude: "31.8163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12781",
    WardLookupId: "3768",
    VDNumber: "43417391",
    RegisteredPopulation: "214",
    VotingStationName: "ZAKHEKAHLE HIGH SCHOOL",
    Address:
      "D312 HEATONVILLE ROAD-SANGOYANA   SANGOYANA-SOMOPHO TAC  EMPANGENI-SOMOPHO TRADITIONAL ADMIN COUNCIL",
    Latitude: "-28.4479",
    Longitude: "31.8118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12782",
    WardLookupId: "1602",
    VDNumber: "43480144",
    RegisteredPopulation: "2521",
    VotingStationName: "AGS LEWENDE WATERS",
    Address: "43 PINKI POOL   MEERENSEE  RICHARDS BAY- LOODSVISLYN",
    Latitude: "-28.775",
    Longitude: "32.1184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12783",
    WardLookupId: "1602",
    VDNumber: "43480234",
    RegisteredPopulation: "1959",
    VotingStationName: "SITHOLINHLAHLA PRIMARY SCHOOL",
    Address: "MZINGAZI  CURRENT  RICHARDS BAY",
    Latitude: "-28.7612",
    Longitude: "32.1301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12784",
    WardLookupId: "1602",
    VDNumber: "43480403",
    RegisteredPopulation: "1350",
    VotingStationName: "NAWE ZIFUNZE DISABLED AND ORPHAN CENTRE",
    Address: "12053 CURRENT   MEERENSEE  RICHARDS BAY - MZINGAZI AGRIVILLAGE",
    Latitude: "-28.7627",
    Longitude: "32.1324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12785",
    WardLookupId: "1603",
    VDNumber: "43480043",
    RegisteredPopulation: "2716",
    VotingStationName: "VELDENVLEI PRIMARY SCHOOL",
    Address: "WEIGELIA STREET  VELDENVLEI  RICHARDS BAY",
    Latitude: "-28.7388",
    Longitude: "32.0571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12786",
    WardLookupId: "1603",
    VDNumber: "43480133",
    RegisteredPopulation: "2062",
    VotingStationName: "RICHARDS BAY PRIMARY SCHOOL",
    Address: "GRUNTER GULLY  MEERENSEE  UMHLATHUZE ( RICHARDS BAY )",
    Latitude: "-28.7795",
    Longitude: "32.0974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12787",
    WardLookupId: "1603",
    VDNumber: "43480302",
    RegisteredPopulation: "267",
    VotingStationName: "BAY HALL",
    Address:
      "BAY VIEW BOULEVALD  KZN282 -MEERENSEE  KZN282 - UMHLATHUZE [RICHARDS BAY]",
    Latitude: "-28.794",
    Longitude: "32.0926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12788",
    WardLookupId: "1603",
    VDNumber: "43480335",
    RegisteredPopulation: "975",
    VotingStationName: "ST ANDREWS ANGLICAN CHURCH",
    Address: "18 ELWEBOOG  MEERENSEE  RICHARDS BAY",
    Latitude: "-28.788",
    Longitude: "32.0984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12789",
    WardLookupId: "1604",
    VDNumber: "43480199",
    RegisteredPopulation: "1792",
    VotingStationName: "RICHARDIA PRIMARY SCHOOL",
    Address: "VIA RICHARDIA  ARBORETUM  UMHLATHUZE ( RICHARDS BAY )",
    Latitude: "-28.7623",
    Longitude: "32.067",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12790",
    WardLookupId: "1604",
    VDNumber: "43480201",
    RegisteredPopulation: "2709",
    VotingStationName: "ARBORETUM PRIMARY SCHOOL",
    Address: "WATERBERRYWOOD  ARBORETUM  RICHARDS BAY",
    Latitude: "-28.7541",
    Longitude: "32.0635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12791",
    WardLookupId: "1604",
    VDNumber: "43480313",
    RegisteredPopulation: "1901",
    VotingStationName: "JOHN ROSS COLLEGE",
    Address: "JOHN ROSS HIGHWAY  ARBORETUM  RICHARSDS BAY",
    Latitude: "-28.761",
    Longitude: "32.0557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12792",
    WardLookupId: "1604",
    VDNumber: "43480346",
    RegisteredPopulation: "441",
    VotingStationName: "VELDENVLEI COMMUNITY CENTRE",
    Address: "MARK STRASSE STREET  VELDENVLEI  RICHARDS BAY",
    Latitude: "-28.7391",
    Longitude: "32.061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12793",
    WardLookupId: "1604",
    VDNumber: "43480425",
    RegisteredPopulation: "101",
    VotingStationName: "EVANGELICAL CHURCH R/BAY",
    Address: "MANDLAZINI PHASE 7  RICHARDS BAY   MAMBUKA T.A",
    Latitude: "-28.7515",
    Longitude: "32.0862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12794",
    WardLookupId: "1605",
    VDNumber: "43480021",
    RegisteredPopulation: "2892",
    VotingStationName: "BIRDSWOOD SECONDARY SCHOOL",
    Address: "BULBUL BEND  BIRDSWOOD   RICHARDS BAY",
    Latitude: "-28.7355",
    Longitude: "32.0832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12795",
    WardLookupId: "1605",
    VDNumber: "43480223",
    RegisteredPopulation: "1600",
    VotingStationName: "MANDLANZINI COMMUNITY HALL",
    Address: "MANDLANZINI RESERVE  MANDLANZINI  MTHIYANE TRIBAL AUTHORITY",
    Latitude: "-28.7384",
    Longitude: "32.1041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12796",
    WardLookupId: "1605",
    VDNumber: "43480289",
    RegisteredPopulation: "1911",
    VotingStationName: "LIZWI HIGH SCHOOL",
    Address: "MANDLANZINI  MADLANZINI AGRI VILLAGE  RICHARDS BAY",
    Latitude: "-28.7424",
    Longitude: "32.0994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12797",
    WardLookupId: "1605",
    VDNumber: "43480357",
    RegisteredPopulation: "738",
    VotingStationName: "MANDLAZINI ASSEMBLES OF GOD",
    Address: "MANDLAZINI RESERVE  MANDLAZINI PHASE 6  RICHARDS BAY",
    Latitude: "-28.7397",
    Longitude: "32.1196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12798",
    WardLookupId: "1606",
    VDNumber: "43413397",
    RegisteredPopulation: "422",
    VotingStationName: "DOVER COMBINED SCHOOL",
    Address: "OLD MTUBATUBA  ROAD   LOWER UMFOLOZI FARM LAND  EMPANGENI",
    Latitude: "-28.7079",
    Longitude: "31.9019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12799",
    WardLookupId: "1606",
    VDNumber: "43413410",
    RegisteredPopulation: "1113",
    VotingStationName: "HLUMA SCHOOL",
    Address: "MAYENI RESERVE  RICHARDS BAY",
    Latitude: "-28.6559",
    Longitude: "31.9816",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12800",
    WardLookupId: "1606",
    VDNumber: "43413487",
    RegisteredPopulation: "1547",
    VotingStationName: "BHEJANE SCHOOL",
    Address: "BHEJANE TRIBAL AUTHORITY  RICHARDS BAY",
    Latitude: "-28.6672",
    Longitude: "31.999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12801",
    WardLookupId: "1606",
    VDNumber: "43415715",
    RegisteredPopulation: "1906",
    VotingStationName: "NSEZI PRIMARY SCHOOL",
    Address: "MKHOMA RESERVE  ENSELENI   BHEJANE TRIBAL AUTHORITY",
    Latitude: "-28.6774",
    Longitude: "32.0112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12802",
    WardLookupId: "1606",
    VDNumber: "43417874",
    RegisteredPopulation: "644",
    VotingStationName: "EZISHABENI PRIMARY SCHOOL",
    Address: "MATHUNZI RESERVE  ENSELENI  BHEJANE TRABIL AUTHORITY",
    Latitude: "-28.668",
    Longitude: "31.9728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12803",
    WardLookupId: "1606",
    VDNumber: "43480212",
    RegisteredPopulation: "158",
    VotingStationName: "KWAZULU-NATAL NATURE CONSERVATION",
    Address: "N2 HIGHWAY  NSELENI NATURE RESERVE  RICHARDS BAY",
    Latitude: "-28.6897",
    Longitude: "31.9996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12804",
    WardLookupId: "1606",
    VDNumber: "44500036",
    RegisteredPopulation: "278",
    VotingStationName: "LIVING WORD AFM",
    Address: "MKHOMA AREA  ENSELENI  BHEJANE T.A",
    Latitude: "-28.6745",
    Longitude: "32.0216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12805",
    WardLookupId: "1607",
    VDNumber: "43413421",
    RegisteredPopulation: "544",
    VotingStationName: "MANZIMPOFU PRIMARY SCHOOL",
    Address: "ESKHONKWANENI RESERVE  NSELENI  BHEJANE TRADIONAL AUTHORITY",
    Latitude: "-28.6343",
    Longitude: "31.9953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12806",
    WardLookupId: "1607",
    VDNumber: "43413476",
    RegisteredPopulation: "562",
    VotingStationName: "BHEJANE COMMUNITY HALL",
    Address: "P494 BHEJANE ROAD   ENSELENI   BHEJANE TRADITIONAL COURT",
    Latitude: "-28.6604",
    Longitude: "31.9995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12807",
    WardLookupId: "1607",
    VDNumber: "43413500",
    RegisteredPopulation: "2148",
    VotingStationName: "MAZIMAZANE CRECHE & PRE SCHOOL",
    Address:
      "NEXT TO MHAWU STORE - NSELENI   MAZIMAZANA AREA   BHEJANE TRADITIONAL AUTHORITY",
    Latitude: "-28.6565",
    Longitude: "32.0168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12808",
    WardLookupId: "1607",
    VDNumber: "43418404",
    RegisteredPopulation: "965",
    VotingStationName: "THE HOUSE OF APOSTLES CHURCH",
    Address: "MAZIMAZANE RESERVE  ENSELI  BHEJANE TRIBAL AUTHORITY",
    Latitude: "-28.6572",
    Longitude: "32.0192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12809",
    WardLookupId: "1607",
    VDNumber: "43480245",
    RegisteredPopulation: "1557",
    VotingStationName: "NSELENI COMMUNITY HALL",
    Address: "UMVIYO STREET  NSELENI  RICHARDS BAY",
    Latitude: "-28.6635",
    Longitude: "32.0201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12810",
    WardLookupId: "1608",
    VDNumber: "43413498",
    RegisteredPopulation: "897",
    VotingStationName: "SAPOKWE TRAINING CENTRE",
    Address: "SAPOKWE RESERVE  NSELENI  RICHARDS BAY",
    Latitude: "-28.6728",
    Longitude: "32.0255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12811",
    WardLookupId: "1608",
    VDNumber: "43480076",
    RegisteredPopulation: "2443",
    VotingStationName: "NSELENI LUTHERAN CHURCH",
    Address: "UMVIYO STREET  NSELENI  RICHARDS BAY",
    Latitude: "-28.6592",
    Longitude: "32.0264",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12812",
    WardLookupId: "1608",
    VDNumber: "43480368",
    RegisteredPopulation: "1010",
    VotingStationName: "FULL GOSPEL CHURCH",
    Address: "JAMELA RESERVE  NSELENI  BHEJANE TRIBAL AUTHORITY",
    Latitude: "-28.6586",
    Longitude: "32.0309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12813",
    WardLookupId: "1609",
    VDNumber: "43415704",
    RegisteredPopulation: "2253",
    VotingStationName: "GWEJOBOMVU S.S. SCHOOL",
    Address: "359 IBHUBESI STREET   NSELENI  EMPANGENI",
    Latitude: "-28.6651",
    Longitude: "32.0195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12814",
    WardLookupId: "1609",
    VDNumber: "43480065",
    RegisteredPopulation: "1915",
    VotingStationName: "THOLOKUHLE SENIOR SECONDARY SCHOOL",
    Address: "UCHAKIDE  NSELENI  RICHARDS BAY",
    Latitude: "-28.6667",
    Longitude: "32.0197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12815",
    WardLookupId: "1610",
    VDNumber: "43420047",
    RegisteredPopulation: "2146",
    VotingStationName: "GRANTHAM PARK PRIMARY SCHOOL",
    Address: "8 MANGROVE AVE  GRANTHAM PARK  EMPANGENI",
    Latitude: "-28.7673",
    Longitude: "31.8969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12816",
    WardLookupId: "1610",
    VDNumber: "43420081",
    RegisteredPopulation: "2719",
    VotingStationName: "WOOD AND RAW PRIMARY SCHOOL",
    Address: "INQE AVENUE  UMHLATHUZE VILLAGE  EMPANGENI",
    Latitude: "-28.7764",
    Longitude: "31.88",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12817",
    WardLookupId: "1610",
    VDNumber: "43420126",
    RegisteredPopulation: "1402",
    VotingStationName: "RICHEM SENIOR SECONDARY SCHOOL",
    Address: "37 SIGMA ROAD  RICHEM  EMPANGENI",
    Latitude: "-28.7692",
    Longitude: "31.8881",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12818",
    WardLookupId: "1611",
    VDNumber: "43412789",
    RegisteredPopulation: "1037",
    VotingStationName: "QAMBOKWETHU PRIMARY SCHOOL",
    Address: "MATHOLONJENI RESERVE  KWADLANGEZWA  MKHWANAZI TRIBAL AUTHORITY",
    Latitude: "-28.8689",
    Longitude: "31.8158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12819",
    WardLookupId: "1611",
    VDNumber: "43412790",
    RegisteredPopulation: "2227",
    VotingStationName: "MVUZEMVUZE PRIMARY SCHOOL",
    Address: "KONJANE RESERVE  KWA DLANGEZWA  MKHWANAZI TRIBAL AUTHORITY",
    Latitude: "-28.879",
    Longitude: "31.8322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12820",
    WardLookupId: "1611",
    VDNumber: "43412802",
    RegisteredPopulation: "1274",
    VotingStationName: "MKHWANAZI TRIBAL COURT",
    Address: "KWADLANGEZWA  UMHLATHUZE",
    Latitude: "-28.8572",
    Longitude: "31.8274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12821",
    WardLookupId: "1611",
    VDNumber: "43412813",
    RegisteredPopulation: "861",
    VotingStationName: "MBUYISENI SENIOR SECONDARY SCHOOL",
    Address: "KWALUGWEBA RESERVE  ESKHAWINI  RICHARDS BAY",
    Latitude: "-28.842",
    Longitude: "31.8247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12822",
    WardLookupId: "1611",
    VDNumber: "43415816",
    RegisteredPopulation: "306",
    VotingStationName: "ONGOYE LUTHERAN CHURCH",
    Address: "ONGOYE MISSION  KWA-DLANGEZWA  MKHWANAZI TRIBAL AUTHORITY",
    Latitude: "-28.8467",
    Longitude: "31.8004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12823",
    WardLookupId: "1612",
    VDNumber: "43412318",
    RegisteredPopulation: "1388",
    VotingStationName: "NHLANGENYUKE SCHOOL",
    Address: "NHLANGENYUKE RESERVE  ONGOYE  MKHWANAZI TRIBAL AUTHORITY",
    Latitude: "-28.9013",
    Longitude: "31.7282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12824",
    WardLookupId: "1612",
    VDNumber: "43412442",
    RegisteredPopulation: "700",
    VotingStationName: "SIYABONGA HIGH SCHOOL",
    Address: "GUGUSHE RESERVE  MANZAMNYAMA  MKHWANAZI TRIBAL AUTHORITY",
    Latitude: "-28.8757",
    Longitude: "31.7558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12825",
    WardLookupId: "1612",
    VDNumber: "43415726",
    RegisteredPopulation: "1461",
    VotingStationName: "NSIWA PRIMARY SCHOOL",
    Address: "MATHOLONJENI RESERVE  KWA DLANGEZWA  MKHWANAZI TRIBAL AUTHORITY",
    Latitude: "-28.8889",
    Longitude: "31.7959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12826",
    WardLookupId: "1612",
    VDNumber: "43416031",
    RegisteredPopulation: "1077",
    VotingStationName: "ZICABANGELE PRIMARY SCHOOL",
    Address: "NTUZE RESERVE  KWA-DLANGEZWA  MKHWANAZI TRIBAL AUTHORITY",
    Latitude: "-28.8916",
    Longitude: "31.7575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12827",
    WardLookupId: "1612",
    VDNumber: "43417076",
    RegisteredPopulation: "270",
    VotingStationName: "NIKIZA HIGH SCHOOL",
    Address: "ONGOYE MISSION  KWADLANGEZWA  MKHWANAZI TRIBAL AUTHORITY",
    Latitude: "-28.8587",
    Longitude: "31.8051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12828",
    WardLookupId: "1612",
    VDNumber: "44500025",
    RegisteredPopulation: "202",
    VotingStationName: "PHEMBOKUHLE PR SCHOOL",
    Address: "ONGOYE ROAD  KWA DLANGEZWA  MKHWANAZI T.A",
    Latitude: "-28.8566",
    Longitude: "31.7798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12829",
    WardLookupId: "1613",
    VDNumber: "43412745",
    RegisteredPopulation: "2384",
    VotingStationName: "MAQHAMA COMBINED PRIMARY SCHOOL",
    Address: "MANDLANKALA RESERVE  ESIKHAWINI  DUBE TRIBAL AUTHORITY",
    Latitude: "-28.8303",
    Longitude: "31.9451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12830",
    WardLookupId: "1613",
    VDNumber: "43412756",
    RegisteredPopulation: "1943",
    VotingStationName: "MCABANGO HIGH SCHOOL",
    Address: "NO STREET  MADLANKALA RESERVE  RICHARDS BAY",
    Latitude: "-28.8254",
    Longitude: "31.9522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12831",
    WardLookupId: "1613",
    VDNumber: "43416042",
    RegisteredPopulation: "654",
    VotingStationName: "KWAMAKHANDA STORE",
    Address: "ESIKHAWINI   MANDLANKALA AREA  DUBE TRADITIONAL AUTHORITY",
    Latitude: "-28.8473",
    Longitude: "31.9172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12832",
    WardLookupId: "1613",
    VDNumber: "43418415",
    RegisteredPopulation: "1453",
    VotingStationName: "MANDLANKALA COMMUNITY HALL",
    Address: "00 MANDLANKALA RESERVE  ESIKHALENI  DUBE TRABAL AUTHORITY",
    Latitude: "-28.8395",
    Longitude: "31.9424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12833",
    WardLookupId: "1613",
    VDNumber: "43480155",
    RegisteredPopulation: "283",
    VotingStationName: "UMHLATHUZE FARM PR. SCHOOL",
    Address: "MANDLANKSLA RESERVE  ESKHAWINI  SUGAR VALLEY ESTATE FARM",
    Latitude: "-28.8186",
    Longitude: "31.9485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12834",
    WardLookupId: "1614",
    VDNumber: "43412701",
    RegisteredPopulation: "2487",
    VotingStationName: "AMABUYE COMBINED SCHOOL",
    Address: "EMABUYENI RESERVE  ESIKHAWINI  DUBE TRIBAL AUTHORITY",
    Latitude: "-28.8624",
    Longitude: "31.9568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12835",
    WardLookupId: "1614",
    VDNumber: "43412723",
    RegisteredPopulation: "1768",
    VotingStationName: "NDESHENI HIGH SCHOOL",
    Address: "EMPEMBENI  EMPEMBENI RESERVE  RICHARDS BAY",
    Latitude: "-28.8473",
    Longitude: "31.984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12836",
    WardLookupId: "1614",
    VDNumber: "43417043",
    RegisteredPopulation: "1541",
    VotingStationName: "MKHOBOSA PRIMARY SCHOOL",
    Address: "MKHOBOSA RESERVE  ESIKHAWINI  DUBE TRIBAL AUTHORITY",
    Latitude: "-28.8699",
    Longitude: "31.9397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12837",
    WardLookupId: "1614",
    VDNumber: "43418426",
    RegisteredPopulation: "1120",
    VotingStationName: "EMPEMBENI PRIMARY SCHOOL",
    Address: "00 MPEMBENI RESERVE  ESIKHALENI  DUBE TRIBAL AUTHORITY",
    Latitude: "-28.8624",
    Longitude: "31.982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12838",
    WardLookupId: "1615",
    VDNumber: "43412464",
    RegisteredPopulation: "1150",
    VotingStationName: "GOBANDLOVU COMMUNITY HALL",
    Address: "GOBANDLOVU  ESIKHAWINI  DUBE TRIBAL AUTHRITY",
    Latitude: "-28.8524",
    Longitude: "31.9056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12839",
    WardLookupId: "1615",
    VDNumber: "43412767",
    RegisteredPopulation: "1125",
    VotingStationName: "MATAMZANA DUBE SCHOOL",
    Address: "GOBANDLOVU RESERVE  ESIKHAWINI  DUBE TRIBAL AUTHORITY",
    Latitude: "-28.8554",
    Longitude: "31.9161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12840",
    WardLookupId: "1615",
    VDNumber: "43415759",
    RegisteredPopulation: "808",
    VotingStationName: "HOLY BIBLE FOCUSED CHURCH",
    Address: "MANDLANKALA  ESIKHAWINI  DUBE TRIBAL AUTHORITY",
    Latitude: "-28.8428",
    Longitude: "31.9327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12841",
    WardLookupId: "1615",
    VDNumber: "43416053",
    RegisteredPopulation: "2145",
    VotingStationName: "DUBE VILLAGE CHURCH",
    Address: "DUBE VILLAGE  ESIKHAWINI  DUBE TRIBAL AUTHORITY",
    Latitude: "-28.8591",
    Longitude: "31.9243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12842",
    WardLookupId: "1615",
    VDNumber: "43418437",
    RegisteredPopulation: "593",
    VotingStationName: "MANDLANKALA PRIMARY SCHOOL",
    Address: "MANDLANKALA RESERVE  ESIKHAWINI  DUBE TRADITIONAL AUTHORITY",
    Latitude: "-28.8474",
    Longitude: "31.9327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12843",
    WardLookupId: "1615",
    VDNumber: "43418448",
    RegisteredPopulation: "577",
    VotingStationName: "GOBANDLOVU PRIMARY SCHOOL",
    Address: "GOBANDLOVU AREA  ESIKHAWINI  DUBE TRADITIONAL AUTHORITY",
    Latitude: "-28.8549",
    Longitude: "31.9127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12844",
    WardLookupId: "1616",
    VDNumber: "43412734",
    RegisteredPopulation: "1038",
    VotingStationName: "NCOMBO PRIMARY SCHOOL",
    Address: "MPEMBENI  NCOMBO AREA  UMHLATHUZE",
    Latitude: "-28.867",
    Longitude: "31.9641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12845",
    WardLookupId: "1616",
    VDNumber: "43415748",
    RegisteredPopulation: "1203",
    VotingStationName: "NTABENI PR SCHOOL",
    Address: "NDIDIMA RESERVE  EMPEMBENI  DUBE TRIBAL AUTHORITY",
    Latitude: "-28.8831",
    Longitude: "31.9412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12846",
    WardLookupId: "1616",
    VDNumber: "43415771",
    RegisteredPopulation: "2866",
    VotingStationName: "ENGALANGALA PRIMARY SCHOOL",
    Address: "MKHOBOSA RESERVE  ESIKHAWINI  DUBE TRIBAL AUTHORITY",
    Latitude: "-28.8807",
    Longitude: "31.9175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12847",
    WardLookupId: "1616",
    VDNumber: "43418459",
    RegisteredPopulation: "935",
    VotingStationName: "MJABULISENI PRIMARY SCHOOL",
    Address: "NDINDIMA RESRVE  EMPEMBENI  DUBE TRIBAL AUTHORITY",
    Latitude: "-28.8724",
    Longitude: "31.9357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12848",
    WardLookupId: "1616",
    VDNumber: "44500047",
    RegisteredPopulation: "502",
    VotingStationName: "CHRISTIAN UNITY CHURCH",
    Address: "NGALANGALA ROAD  ESIKHAWINI   DUBE T.A",
    Latitude: "-28.8718",
    Longitude: "31.9271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12849",
    WardLookupId: "1617",
    VDNumber: "43412712",
    RegisteredPopulation: "2355",
    VotingStationName: "QHUBEKANI CRECHE AND PRE-SCHOOL",
    Address: "MKHOBOSA AREA   ESIKHAWINI   DUBE TRADITIONAL AUTHORITY",
    Latitude: "-28.8684",
    Longitude: "31.9278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12850",
    WardLookupId: "1617",
    VDNumber: "43415760",
    RegisteredPopulation: "987",
    VotingStationName: "BAJABULILE PRIMARY SCHOOL",
    Address: "MTHIMUNYE STRRET  ESIKHAWINI  RICHARDS BAY",
    Latitude: "-28.8681",
    Longitude: "31.9215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12851",
    WardLookupId: "1617",
    VDNumber: "43417065",
    RegisteredPopulation: "718",
    VotingStationName: "THE CHURCH OF JESUS CHRIST OF LATTER DAY SAINTS",
    Address: "MSHEKISANE STREET   ESIKHAWINI   RICHARDS BAY",
    Latitude: "-28.8653",
    Longitude: "31.9229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12852",
    WardLookupId: "1617",
    VDNumber: "43418460",
    RegisteredPopulation: "1278",
    VotingStationName: "CHRISTIAN EKUKHANYENI CRECHE  AND PRE SCHOOL",
    Address: "MKHOBOSA RESERVE  ESIKHAWINI  DUBE TRADITIONAL AUTORITY",
    Latitude: "-28.8628",
    Longitude: "31.9283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12853",
    WardLookupId: "1618",
    VDNumber: "43417054",
    RegisteredPopulation: "1480",
    VotingStationName: "ESIKHAWINI PARKS DEPOT",
    Address: "MDLEBE MPUMA  ESIKHAWINI  RICHARDS BAY",
    Latitude: "-28.8627",
    Longitude: "31.9115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12854",
    WardLookupId: "1618",
    VDNumber: "43480032",
    RegisteredPopulation: "1455",
    VotingStationName: "TISAND TECHNICAL HIGH SCHOOL",
    Address: "THOKOZANI  ESIKHAWINI  RICHARDS BAY",
    Latitude: "-28.8658",
    Longitude: "31.9121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12855",
    WardLookupId: "1618",
    VDNumber: "43480111",
    RegisteredPopulation: "2031",
    VotingStationName: "HLAMVANA HIGH SCHOOL",
    Address: "UMNYEZANE STREET  ESIKHAWINI  RICHARDS BAY",
    Latitude: "-28.864",
    Longitude: "31.9183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12856",
    WardLookupId: "1619",
    VDNumber: "43412453",
    RegisteredPopulation: "1117",
    VotingStationName: "MNTOKHONA PRIMARY SCHOOL",
    Address: "MLALAZI RESERVE  ESIKHAWINI  MKHWANAZI TRIBAL AUTHORITY",
    Latitude: "-28.93",
    Longitude: "31.821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12857",
    WardLookupId: "1619",
    VDNumber: "43412475",
    RegisteredPopulation: "2684",
    VotingStationName: "MHLANGA PRIMARY SCHOOL",
    Address: "PORTDUNFORD RESERVE  ESIKHAWINI  MKHWANAZI TRIBAL AUTHORITY",
    Latitude: "-28.9209",
    Longitude: "31.8398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12858",
    WardLookupId: "1619",
    VDNumber: "43412677",
    RegisteredPopulation: "1610",
    VotingStationName: "INJABULOYESIZWE SCHOOL",
    Address: "MAHULU RESERVE  PORT DUNFORD  RICHARDS BAY",
    Latitude: "-28.9139",
    Longitude: "31.8832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12859",
    WardLookupId: "1620",
    VDNumber: "43415737",
    RegisteredPopulation: "2311",
    VotingStationName: "RESTORATION OF HOPE CHURCH",
    Address: "J1 18 SECTION AREA   ESIKHAWINI  MKHWANAZI TRADITIONAL AUTHORITY",
    Latitude: "-28.8953",
    Longitude: "31.8782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12860",
    WardLookupId: "1620",
    VDNumber: "43480098",
    RegisteredPopulation: "720",
    VotingStationName: "MDLAMFE HIGH SCHOOL",
    Address: "MDLEBE MPUMA STREET  ESIKHAWINI  RICHARDS BAY",
    Latitude: "-28.8838",
    Longitude: "31.8958",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12861",
    WardLookupId: "1620",
    VDNumber: "43480100",
    RegisteredPopulation: "3049",
    VotingStationName: "SIKHULANGE MFUNDO PRIMARY SCHOOL",
    Address: "J671 IMPUNGUSHE ROAD  ESIKHAWINI  RICHARDS BAY",
    Latitude: "-28.8902",
    Longitude: "31.8869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12862",
    WardLookupId: "1621",
    VDNumber: "43415243",
    RegisteredPopulation: "1855",
    VotingStationName: "DLAMVUZO SS SCHOOL",
    Address: "J 1811 ESIKHAWINI   RICHARDS BAY",
    Latitude: "-28.8733",
    Longitude: "31.8963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12863",
    WardLookupId: "1621",
    VDNumber: "43480122",
    RegisteredPopulation: "1988",
    VotingStationName: "ENKANYISWENI CONGREGATION LUTHERAN CHURCH",
    Address: "NYEZANE  ESIKHAWINI  RICHARDS BAY",
    Latitude: "-28.8761",
    Longitude: "31.8993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12864",
    WardLookupId: "1621",
    VDNumber: "43480290",
    RegisteredPopulation: "1829",
    VotingStationName: "ESIKHAWINI EVANGELICAL CHURCH",
    Address: "NTIMBANE ROAD  ESIKHAWINI  UMHLATHUZE [RICHARDS BAY]",
    Latitude: "-28.8827",
    Longitude: "31.8909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12865",
    WardLookupId: "1622",
    VDNumber: "43480087",
    RegisteredPopulation: "1726",
    VotingStationName: "MAGISTRATE OFFICE",
    Address: "ILANDA  ESIKHAWINI  RICHARDS BAY",
    Latitude: "-28.8761",
    Longitude: "31.9031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12866",
    WardLookupId: "1622",
    VDNumber: "43480256",
    RegisteredPopulation: "1891",
    VotingStationName: "IKHANDLELA JUNIOR SECONDARY SCHOOL",
    Address: "H 1842 NTOKOZO STREET  ESIKHAWINI  RICHARDS BAY",
    Latitude: "-28.8719",
    Longitude: "31.914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12867",
    WardLookupId: "1622",
    VDNumber: "43480267",
    RegisteredPopulation: "1518",
    VotingStationName: "ETHAKASANI PRIMARY SCHOOL",
    Address: "782 ISIKHOVA ROAD  ESIKHAWINI  RICHARDS BAY",
    Latitude: "-28.8735",
    Longitude: "31.9088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12868",
    WardLookupId: "1622",
    VDNumber: "43480379",
    RegisteredPopulation: "967",
    VotingStationName: "HOLINESS UNION CHURCH",
    Address: "INTAKA STREET  ESIKHAWINI   RICHARDS BAY",
    Latitude: "-28.872",
    Longitude: "31.9027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12869",
    WardLookupId: "1623",
    VDNumber: "43412688",
    RegisteredPopulation: "1856",
    VotingStationName: "UNITED NATIONAL CHURCH LUTHERAN",
    Address: "EMADENGENI RESERVE  ESIKHAWINI  MKHWANAZI TRADITIONAL AUTHORITY",
    Latitude: "-28.901",
    Longitude: "31.8919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12870",
    WardLookupId: "1623",
    VDNumber: "43412699",
    RegisteredPopulation: "3137",
    VotingStationName: "AMANDOSI PRIMARY SCHOOL",
    Address: "KWANDAYA AREA  ESIKHAWINI  UMHLATHUZE",
    Latitude: "-28.8917",
    Longitude: "31.912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12871",
    WardLookupId: "1623",
    VDNumber: "43418471",
    RegisteredPopulation: "1381",
    VotingStationName: "SOUTH AFRICAN GENERAL MISSION (SAGM)",
    Address: "ENSIMBINI  KWANDAYA AREA   MKHWANAZI TRADITIONAL AUTHORITY",
    Latitude: "-28.8883",
    Longitude: "31.8974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12872",
    WardLookupId: "1624",
    VDNumber: "43420014",
    RegisteredPopulation: "882",
    VotingStationName: "HEUWELAND PRIMARY SCHOOL",
    Address: "39 BIGGER STREET  GRANTHAM PARK  EMPANGENI",
    Latitude: "-28.7536",
    Longitude: "31.8858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12873",
    WardLookupId: "1624",
    VDNumber: "43420036",
    RegisteredPopulation: "3016",
    VotingStationName: "EMPANGENI CIVIC CENTRE",
    Address: "UNION STREET  EMPANGENI  UMHLATHUZE",
    Latitude: "-28.7448",
    Longitude: "31.8908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12874",
    WardLookupId: "1624",
    VDNumber: "43420115",
    RegisteredPopulation: "617",
    VotingStationName: "SIYAKHANYISA PRIMARY SCHOOL",
    Address: "NKULULEKO  EMPANGENI MILLING  EMPANGENI",
    Latitude: "-28.748",
    Longitude: "31.9171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12875",
    WardLookupId: "1624",
    VDNumber: "43420159",
    RegisteredPopulation: "711",
    VotingStationName: "EMPANGENI PREPARATORY SCHOOL",
    Address: "IMPALA STREET  MPANGELE  EMPANGENI",
    Latitude: "-28.7483",
    Longitude: "31.8924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12876",
    WardLookupId: "1625",
    VDNumber: "43415793",
    RegisteredPopulation: "1800",
    VotingStationName: "KHANYISANI CRECHE",
    Address: "DONDOLO AREA  EMPANGENI  MADLEBE TRIBAL AUTHORITY",
    Latitude: "-28.7638",
    Longitude: "31.868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12877",
    WardLookupId: "1625",
    VDNumber: "43418482",
    RegisteredPopulation: "689",
    VotingStationName: "AMANGWE HIGH SCHOOL",
    Address: "0 DONDOLO  MATSHANA  MADLEBE TRIBAL AUTHORITY",
    Latitude: "-28.7545",
    Longitude: "31.8589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12878",
    WardLookupId: "1625",
    VDNumber: "43420058",
    RegisteredPopulation: "1499",
    VotingStationName: "QALAKABUSHA CORRECTIONAL SERVICES",
    Address: "R34 MELMOTH ROAD   HILLVIEW  MADLEBE TRADITIONAL AUTHORITY",
    Latitude: "-28.7352",
    Longitude: "31.8785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12879",
    WardLookupId: "1625",
    VDNumber: "43420070",
    RegisteredPopulation: "875",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "653 ST LUWANGA STREET  NGWELEZANE  EMPANGENI",
    Latitude: "-28.7687",
    Longitude: "31.8698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12880",
    WardLookupId: "1625",
    VDNumber: "43420137",
    RegisteredPopulation: "1684",
    VotingStationName: "UMHLATHUZE CHRECHE",
    Address: "10976 NKANYAMBA ROAD   UMHLATHUZE VILLAGE   EMPANGENI",
    Latitude: "-28.7677",
    Longitude: "31.8792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12881",
    WardLookupId: "1626",
    VDNumber: "43412880",
    RegisteredPopulation: "2529",
    VotingStationName: "KANGIKO SCHOOL",
    Address: "MATSHANA  UMHLATHUZE",
    Latitude: "-28.7519",
    Longitude: "31.8549",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12882",
    WardLookupId: "1626",
    VDNumber: "43413353",
    RegisteredPopulation: "1499",
    VotingStationName: "QHUBANDABA PRIMARY SCHOOL",
    Address:
      "NONGWELEZA ROAD  NONGWELEZA AREA  EMPANGENI, MADLEBE TRADITIONAL COUNCIL",
    Latitude: "-28.7481",
    Longitude: "31.8029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12883",
    WardLookupId: "1626",
    VDNumber: "43415782",
    RegisteredPopulation: "884",
    VotingStationName: "CHRISTIAN BETHAN CHURCH",
    Address: "MANKWANYANENI AREA  EMPANGENI-MADLEBE T/A",
    Latitude: "-28.7398",
    Longitude: "31.8313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12884",
    WardLookupId: "1626",
    VDNumber: "43418493",
    RegisteredPopulation: "316",
    VotingStationName: "NKOSAZANA PRIMARY SCHOOL",
    Address:
      "EMPANGENI, MADLEBE TRADITIONAL NONGWELEZA AREA QHUBANDABA PRI SCHOOL MADLEBE TRADITIONAL AUTHORITY",
    Latitude: "-28.7689",
    Longitude: "31.7947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12885",
    WardLookupId: "1627",
    VDNumber: "43480166",
    RegisteredPopulation: "2028",
    VotingStationName: "AQUADENE COMMUNITY HALL",
    Address: "ACHORUS ANCOR  AQUADENE  RICHARDS BAY",
    Latitude: "-28.7158",
    Longitude: "32.0361",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12886",
    WardLookupId: "1627",
    VDNumber: "43480177",
    RegisteredPopulation: "2955",
    VotingStationName: "BRACKENHAM COMMUNITY HALL",
    Address: "KZN282 - UMHLATHUZE [RICHARDS BAY]  ",
    Latitude: "-28.7216",
    Longitude: "32.0421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12887",
    WardLookupId: "1627",
    VDNumber: "43480380",
    RegisteredPopulation: "1027",
    VotingStationName: "RICHARDS BAY SEC. SCHOOL",
    Address: "VIA DAVALIA   BRACKENHAM   RICHARDS BAY",
    Latitude: "-28.7281",
    Longitude: "32.0427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12888",
    WardLookupId: "1628",
    VDNumber: "43412868",
    RegisteredPopulation: "2004",
    VotingStationName: "ISIGISI PRIMARY SCHOOL",
    Address: "MADLEBE  UMHLATHUZE",
    Latitude: "-28.7644",
    Longitude: "31.8419",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12889",
    WardLookupId: "1628",
    VDNumber: "43420069",
    RegisteredPopulation: "1773",
    VotingStationName: "PESHEYA COMBINED PRIMARY SCHOOL",
    Address: "PESHEYA ROAD  NGWELEZANE  UMHLATHUZE",
    Latitude: "-28.7775",
    Longitude: "31.851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12890",
    WardLookupId: "1628",
    VDNumber: "43420092",
    RegisteredPopulation: "1514",
    VotingStationName: "NONGWELEZA DAYCARE CENTRE",
    Address: "A1695 MADLEBE ROAD   NGWELEZANE  EMPANGENI",
    Latitude: "-28.7683",
    Longitude: "31.8601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12891",
    WardLookupId: "1628",
    VDNumber: "43420148",
    RegisteredPopulation: "667",
    VotingStationName: "EKWETHABENI LUTHERAN CHURCH",
    Address: "A880  MANGOSUTHU ROAD    NGWELEZANE   EMPANGENI",
    Latitude: "-28.7727",
    Longitude: "31.8554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12892",
    WardLookupId: "1629",
    VDNumber: "43412059",
    RegisteredPopulation: "1198",
    VotingStationName: "NGWELEZANE HOSPITAL",
    Address: "THANDUYISE HIGHWAY- NURSE HOME  NGWELEZANA  EMPANGENI",
    Latitude: "-28.7768",
    Longitude: "31.8662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12893",
    WardLookupId: "1629",
    VDNumber: "43418516",
    RegisteredPopulation: "1023",
    VotingStationName: "BOMVINI CRECHE",
    Address: "BOMVINI AREA   NGWELEZANA  MADLEBE TRADITIONAL AUTHORITY",
    Latitude: "-28.7879",
    Longitude: "31.8665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12894",
    WardLookupId: "1629",
    VDNumber: "43420025",
    RegisteredPopulation: "1990",
    VotingStationName: "NGWELEZANE CIVIC CENTRE",
    Address: "ISUNDU ROAD  NGWELEZANE  EMPANGENI",
    Latitude: "-28.775",
    Longitude: "31.8628",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12895",
    WardLookupId: "1629",
    VDNumber: "43420104",
    RegisteredPopulation: "1866",
    VotingStationName: "THANDUYISE HIGH SCHOOL",
    Address: "B1003 THANDUYISE DRIVE  NGWELEZANA  EMPANGENI",
    Latitude: "-28.7829",
    Longitude: "31.864",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12896",
    WardLookupId: "1630",
    VDNumber: "43412824",
    RegisteredPopulation: "1487",
    VotingStationName: "ENIWE PRIMARY SCHOOL",
    Address: "INIWE  RICHARDS BAY",
    Latitude: "-28.8318",
    Longitude: "31.8646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12897",
    WardLookupId: "1630",
    VDNumber: "43412835",
    RegisteredPopulation: "2294",
    VotingStationName: "BUHLEBETHU COMMUNITY CENTRE",
    Address: "MADUNSULA AREA   NDABAYAKHE   MADLEBE TRADITIONAL AUTHORITY",
    Latitude: "-28.8089",
    Longitude: "31.8654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12898",
    WardLookupId: "1630",
    VDNumber: "43412879",
    RegisteredPopulation: "996",
    VotingStationName: "NQUTSHINI PRIMARY SCHOOL",
    Address: "NQUTSHINI AREA   NQUTSHINI  MADLEBE TRADITIONAL AUTHORITY",
    Latitude: "-28.8027",
    Longitude: "31.8307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12899",
    WardLookupId: "1630",
    VDNumber: "43415030",
    RegisteredPopulation: "831",
    VotingStationName: "NDABAYAKHE PRIMARY SCHOOL",
    Address: "NDABAYAKHE AREA   NDABAYAKHE  MADLEBE TRADITIONAL AUTHORITY",
    Latitude: "-28.7962",
    Longitude: "31.861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12900",
    WardLookupId: "1630",
    VDNumber: "43418066",
    RegisteredPopulation: "1402",
    VotingStationName: "NGQOLOTHI LUTHERAN CHURCH",
    Address: "NGQOLOTHI AREA   NQUTSHINI   MADLEBE TRADITIONAL AUTHORITY",
    Latitude: "-28.7875",
    Longitude: "31.8472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12901",
    WardLookupId: "1630",
    VDNumber: "44500014",
    RegisteredPopulation: "272",
    VotingStationName: "AMAZING GRACE CHURCH",
    Address: "MANGOSUTHU HIGH WAY  MADLEBE T.A   NGWELEZANE",
    Latitude: "-28.78",
    Longitude: "31.8497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12902",
    WardLookupId: "1631",
    VDNumber: "43480188",
    RegisteredPopulation: "3242",
    VotingStationName: "ONGOYE HIGH SCHOOL",
    Address: "168 MFUNDO ROAD  VULINDLELA  RICHARDS BAY",
    Latitude: "-28.859",
    Longitude: "31.8381",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12903",
    WardLookupId: "1631",
    VDNumber: "43480414",
    RegisteredPopulation: "296",
    VotingStationName: "APOSTOLIC FAITH MISSION CHURCH",
    Address: "MHLATHUZE AREA  KWA DLANGEZWA  MKWANAZI T.A",
    Latitude: "-28.8421",
    Longitude: "31.8528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12904",
    WardLookupId: "1632",
    VDNumber: "43413139",
    RegisteredPopulation: "167",
    VotingStationName: "JABULANI DISABLED CENTRE",
    Address:
      "R34 MTIMONA AREA  MTIMONA AREA-OBUKA TAC  EMPANGENI-OBUKA TRADITIONAL ADMIN COUNCIL",
    Latitude: "-28.6901",
    Longitude: "31.7072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12905",
    WardLookupId: "1632",
    VDNumber: "43413140",
    RegisteredPopulation: "370",
    VotingStationName: "HOLY CROSS ANGLICAN CHURCH",
    Address: "HEATONVILLE ROAD  EMPANGENI   EMPANGENI",
    Latitude: "-28.6639",
    Longitude: "31.7859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12906",
    WardLookupId: "1632",
    VDNumber: "43413364",
    RegisteredPopulation: "672",
    VotingStationName: "QHAMUKA HIGH SCHOOL",
    Address:
      "OBIZO ROAD-ISIHUZU AREA - SOMOPHO TAC EMPANGENI-SOMOPHO TRADITIONAL ADMINISTRATIVE COUNC",
    Latitude: "-28.6892",
    Longitude: "31.8479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12907",
    WardLookupId: "1632",
    VDNumber: "43415805",
    RegisteredPopulation: "1442",
    VotingStationName: "EBINGOMA PRIMARY SCHOOL",
    Address:
      "EBINGOMA AREA-MEVAMHLOPHE RESE  MADLEBE TRADITIONAL COUNCIL  EMPANGENI-MADLEBE TRADITIONAL COUNCIL",
    Latitude: "-28.7519",
    Longitude: "31.7807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12908",
    WardLookupId: "1632",
    VDNumber: "43416884",
    RegisteredPopulation: "1005",
    VotingStationName: "NGOGODO PRIMARY SCHOOL",
    Address:
      "D312 HEATONVILE ROAD-GXIGXI AREA  GXIGXI VILLAGE-SOMOPHO TRADITIONAL ADMINISTRATIVE COUNC",
    Latitude: "-28.7031",
    Longitude: "31.8321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12909",
    WardLookupId: "1632",
    VDNumber: "43417919",
    RegisteredPopulation: "780",
    VotingStationName: "SIPHIWAYINKOSI PRIMARY SCHOOL",
    Address: "LUWAMBA ROARD   KHEYIZANA AREA - OBUKA   OBUKA TRIBAL AUTHORITY",
    Latitude: "-28.6229",
    Longitude: "31.704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12910",
    WardLookupId: "1632",
    VDNumber: "43418235",
    RegisteredPopulation: "299",
    VotingStationName: "FATIMA ROMAN CHURCH",
    Address:
      "D130 FATIMA AREA  FATIMA AREA-OBUKA TAC  EMPANGENI-OBUKA TRADITIONAL ADMIN COUNCIL",
    Latitude: "-28.6644",
    Longitude: "31.6741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12911",
    WardLookupId: "1632",
    VDNumber: "43418257",
    RegisteredPopulation: "999",
    VotingStationName: "MACEKANE CRECHE",
    Address:
      "NTABINAMASI AREA  NTAMBINAMASI AREA-SOMOPHO TAC  EMPANGENI-SOMOPHO TRADITIONAL ADMINISTRATIVE COUNC",
    Latitude: "-28.713",
    Longitude: "31.8573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12912",
    WardLookupId: "1633",
    VDNumber: "43413162",
    RegisteredPopulation: "722",
    VotingStationName: "NDONDWANE PRIMARY SCHOOL",
    Address:
      "NDONDWANE ROAD-NDONDWANE AREA-OBIZO TAC EMPANGENI-OBIZO TRADITIONAL ADMINISTRATIVE COUNCIL",
    Latitude: "-28.6086",
    Longitude: "31.8669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12913",
    WardLookupId: "1633",
    VDNumber: "43413375",
    RegisteredPopulation: "1736",
    VotingStationName: "MACEKANE COMBINED PRIMARY SCHOOL",
    Address:
      "OBIZO ROAD-MACEKANE AREA -SOMOPHO TAC  EMPANGENI-SOMOPHO TRADITIONAL ADMINISTRATIVE COUNC",
    Latitude: "-28.7067",
    Longitude: "31.8691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12914",
    WardLookupId: "1633",
    VDNumber: "43413386",
    RegisteredPopulation: "1126",
    VotingStationName: "BHEKIKUSASA HIGH SCHOOL",
    Address:
      "P568 OBIZO ROAD-OBIZO AREA  OBIZO AREA-UBIZO TAC  EMPANGENI-UBIZO TRADITIONAL ADMIN COUNCIL",
    Latitude: "-28.6573",
    Longitude: "31.8769",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12915",
    WardLookupId: "1633",
    VDNumber: "43415108",
    RegisteredPopulation: "820",
    VotingStationName: "MKHONTO HIGH SCHOOL",
    Address:
      "NDONDWANE ROAD-KWA-HLAZA AREA-OBIZO TAC  EMPANGENI-OBIZO TRADITIONAL ADMINISTRATIVE COUNCIL",
    Latitude: "-28.6208",
    Longitude: "31.9044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12916",
    WardLookupId: "1633",
    VDNumber: "43416851",
    RegisteredPopulation: "268",
    VotingStationName: "MALONGWENI STORE",
    Address:
      "NDONDWANE ROAD-MALONGWENI AREA -OBIZO TAC  EMPANGENI-OBIZO TRADITIONAL ADMINISTRATIVE COUNCIL",
    Latitude: "-28.5907",
    Longitude: "31.8891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12917",
    WardLookupId: "1633",
    VDNumber: "43416862",
    RegisteredPopulation: "359",
    VotingStationName: "THANDOKUHLE CRECHE",
    Address:
      "MAQEDIPULETI AREA  MAQEDIPULETI AREA-MAMBUKA TAC  EMPANGENI-MAMBUKA TRADITIONAL ADMIN COUNCIL",
    Latitude: "-28.5782",
    Longitude: "31.868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12918",
    WardLookupId: "1633",
    VDNumber: "43418246",
    RegisteredPopulation: "903",
    VotingStationName: "UBIZO TRADITIONAL ADMINISTRATIVE COUNCIL (TAC)",
    Address: "NDONDWANE  UBIZO TAC  EMPANGENI",
    Latitude: "-28.6038",
    Longitude: "31.8478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12919",
    WardLookupId: "1633",
    VDNumber: "43418268",
    RegisteredPopulation: "688",
    VotingStationName: "ZENZELE CRECHE",
    Address:
      "OBIZO ROAD-ISIHUZU VILLAGE -SOMOPHO TAC  EMPANGENI-SOMOPHO TRADITIONAL ADIMINISTRATIVE COUN",
    Latitude: "-28.6729",
    Longitude: "31.8497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12920",
    WardLookupId: "1634",
    VDNumber: "43413128",
    RegisteredPopulation: "3265",
    VotingStationName: "SABOKWE PRIMARY SCHOOL",
    Address:
      "D253 ROAD-BHUCANANA AREA  BHUCANANA RESERVE-OBUKA TRADITIONAL ADMINISTRATIVE COUNCIL",
    Latitude: "-28.5953",
    Longitude: "31.7423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12921",
    WardLookupId: "1634",
    VDNumber: "43413151",
    RegisteredPopulation: "1147",
    VotingStationName: "MAMBUKA COMBINED PRIMARY SCHOOL",
    Address:
      "NTAMBANANA ROAD-MAMBUKA AREA  MAMBUKA AREA-MAMBUKA TAC  EMPANGENI-MAMBUKA TRADITIONAL ADMIN COUNCIL",
    Latitude: "-28.5766",
    Longitude: "31.8426",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12922",
    WardLookupId: "1634",
    VDNumber: "43416918",
    RegisteredPopulation: "1080",
    VotingStationName: "SIKHANYISELE CENTRE",
    Address:
      "KWABIYELA ROAD-MANGCENGCE AREA  MANGCENGCE AREA-OBUKA TAC  EMPANGENI",
    Latitude: "-28.5878",
    Longitude: "31.7254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12923",
    WardLookupId: "1635",
    VDNumber: "43412778",
    RegisteredPopulation: "877",
    VotingStationName: "FELIXTON LIBRARY",
    Address: "FELIXTON MILL AREA   FELXTON  EMPANGENI",
    Latitude: "-28.8319",
    Longitude: "31.8888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12924",
    WardLookupId: "1635",
    VDNumber: "43480324",
    RegisteredPopulation: "3287",
    VotingStationName: "STUDENT CENTRE AT UNIZULU",
    Address: "KHANDISA AREA   KWA-DLANGEZWA   MKHWANAZI TRADITIONAL AUTHORITY",
    Latitude: "-28.8526",
    Longitude: "31.8501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12925",
    WardLookupId: "1635",
    VDNumber: "43480391",
    RegisteredPopulation: "960",
    VotingStationName: "AFRICAN GOSPEL CHURCH",
    Address: "KHANDISA AREA  KWA DLANGEZWA  MKHWANAZI T.A",
    Latitude: "-28.8584",
    Longitude: "31.8586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12926",
    WardLookupId: "3769",
    VDNumber: "43411250",
    RegisteredPopulation: "518",
    VotingStationName: "IZINYOSI PRIMARY SCHOOL",
    Address: "IZINYOSI AREA  MVUZANE TRIBAL AUTHORITY  ESHOWE",
    Latitude: "-28.7759",
    Longitude: "31.2027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12927",
    WardLookupId: "3769",
    VDNumber: "43411340",
    RegisteredPopulation: "540",
    VotingStationName: "THEMBELANI PRIMARY SCHOOL",
    Address: "GOBHOTANA AREA  MVUZANE TRIBAL AUTHORITY  ESHOWE",
    Latitude: "-28.8381",
    Longitude: "31.1993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12928",
    WardLookupId: "3769",
    VDNumber: "43411351",
    RegisteredPopulation: "569",
    VotingStationName: "NXUSA COMBINED PRIMARY SCHOOL",
    Address: "PHINDAVELE AREA  MVUZANE TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.8821",
    Longitude: "31.1942",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12929",
    WardLookupId: "3769",
    VDNumber: "43411520",
    RegisteredPopulation: "599",
    VotingStationName: "MDANSANE LOWER PRIMARY SCHOOL",
    Address: "IZIMPONGO EZIPHEZULU  MVUZANE TRIBAL AUTHORITY  ESHOWE",
    Latitude: "-28.8439",
    Longitude: "31.2882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12930",
    WardLookupId: "3769",
    VDNumber: "43414983",
    RegisteredPopulation: "431",
    VotingStationName: "KWA-BIYELA PRIMARY SCHOOL",
    Address: "MFOMFOLOZI   MVUZANE TRIBAL AUTHORITY  ESHOWE",
    Latitude: "-28.7931",
    Longitude: "31.2004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12931",
    WardLookupId: "3769",
    VDNumber: "43415568",
    RegisteredPopulation: "603",
    VotingStationName: "HHASHI HIGH SCHOOL",
    Address: "MBILENI AREA  MVUZANE TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.8094",
    Longitude: "31.2619",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12932",
    WardLookupId: "3770",
    VDNumber: "43411294",
    RegisteredPopulation: "606",
    VotingStationName: "NGWEMNYAMA PRIMARY SCHOOL",
    Address: "NGWEMNYAMA AREA  OYAYA TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.9314",
    Longitude: "31.0856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12933",
    WardLookupId: "3770",
    VDNumber: "43411317",
    RegisteredPopulation: "1105",
    VotingStationName: "MAMBA COMBINED PRIMARY SCHOOL",
    Address: "DIBHASE AREA  SHANGE TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.8417",
    Longitude: "31.1456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12934",
    WardLookupId: "3770",
    VDNumber: "43411328",
    RegisteredPopulation: "675",
    VotingStationName: "SITHILO PRIMARY SCHOOL",
    Address: "SITILO AREA  OYAYA TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.886",
    Longitude: "31.1201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12935",
    WardLookupId: "3770",
    VDNumber: "43411339",
    RegisteredPopulation: "300",
    VotingStationName: "MACHOTSHANENI COMBINED PRIMARY SCHOOL",
    Address: "MACHOTSHANENI AREA  OYAYA TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.9253",
    Longitude: "31.1419",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12936",
    WardLookupId: "3770",
    VDNumber: "43414972",
    RegisteredPopulation: "834",
    VotingStationName: "CEREMONY PRIMARY SCHOOL",
    Address: "NHLALIDAKIWE AREA  SHANGE TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.8898",
    Longitude: "31.068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12937",
    WardLookupId: "3770",
    VDNumber: "43416503",
    RegisteredPopulation: "534",
    VotingStationName: "KWAZIKWAKHE PRIMARY SCHOOL",
    Address: "MAMBA RESERVE  UMLALAZI [ESHOWE]",
    Latitude: "-28.8599",
    Longitude: "31.109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12938",
    WardLookupId: "3770",
    VDNumber: "43418549",
    RegisteredPopulation: "268",
    VotingStationName: "MAMBA ONE STOP CENTRE",
    Address: "DIBHASE AREA  SHANGE TRADITIONAL UATHORITY  ESHOWE",
    Latitude: "-28.8312",
    Longitude: "31.1213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12939",
    WardLookupId: "3771",
    VDNumber: "43411362",
    RegisteredPopulation: "931",
    VotingStationName: "MBONGOLWANE COMBINED PRIMARY SCHOOL",
    Address:
      "MKHWISHIMANE  NEAR HOSPITAL  OYAYA (NTULI) TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.9278",
    Longitude: "31.1924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12940",
    WardLookupId: "3771",
    VDNumber: "43411430",
    RegisteredPopulation: "357",
    VotingStationName: "NOKHALELA SECONDARY SCHOOL",
    Address: "KHANGELANI AREA  OYAYA (NTULI) TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-29.007",
    Longitude: "31.2311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12941",
    WardLookupId: "3771",
    VDNumber: "43411733",
    RegisteredPopulation: "700",
    VotingStationName: "SILAMBO PRIMARY SCHOOL",
    Address: "SILAMBO AREA  OYAYA TRADITIONAL AUTHORITY  NTUMENI",
    Latitude: "-28.9617",
    Longitude: "31.1739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12942",
    WardLookupId: "3771",
    VDNumber: "43412082",
    RegisteredPopulation: "185",
    VotingStationName: "UMMANGALISO COMBINED PRIMARY SCHOOL",
    Address:
      "MPOFANA AREA  BANGINDODA TRADITIONAL AUTHORITY  BANGINDODA TRIBAL AUTHORITY",
    Latitude: "-29.0411",
    Longitude: "31.1874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12943",
    WardLookupId: "3771",
    VDNumber: "43415186",
    RegisteredPopulation: "280",
    VotingStationName: "EDAKENI PRIMARY SCHOOL",
    Address: "EDAKENI AREA  OYAYA TRADITIONAL AOUTHORITY  ESHOWE",
    Latitude: "-29.0304",
    Longitude: "31.137",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12944",
    WardLookupId: "3771",
    VDNumber: "43415579",
    RegisteredPopulation: "306",
    VotingStationName: "NCEKUYA PRIMARY SCHOOL",
    Address: "MBIZANE AREA  OYAYATRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.9923",
    Longitude: "31.1888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12945",
    WardLookupId: "3771",
    VDNumber: "43415580",
    RegisteredPopulation: "404",
    VotingStationName: "NOMAPHINDELA CRECHE",
    Address: "NKULISABANTWANA AREA   OYAYA TRADITIONAL AREA  ESHOWE",
    Latitude: "-28.9907",
    Longitude: "31.2185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12946",
    WardLookupId: "3771",
    VDNumber: "43418561",
    RegisteredPopulation: "563",
    VotingStationName: "MAFUNDA HIGH SCHOOL",
    Address: "MBONGOLWANE  NTULI TRADITIONAL COUNCIL  ESHOWE",
    Latitude: "-28.9318",
    Longitude: "31.199",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12947",
    WardLookupId: "3772",
    VDNumber: "43411384",
    RegisteredPopulation: "1005",
    VotingStationName: "NGEDLEZI PRIMARY SCHOOL",
    Address: "VIA NDIKILINI HALT  MBONGOLWANE AREA  KWA-PETT",
    Latitude: "-28.895",
    Longitude: "31.2174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12948",
    WardLookupId: "3772",
    VDNumber: "43411407",
    RegisteredPopulation: "1095",
    VotingStationName: "MAVUMENGWANA HIGH SCHOOL",
    Address: "NTULI T/AREA  NDAYINI  ESHOWE",
    Latitude: "-28.9277",
    Longitude: "31.2277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12949",
    WardLookupId: "3772",
    VDNumber: "43411418",
    RegisteredPopulation: "816",
    VotingStationName: "EMASUNDWINI PRIMARY SCHOOL",
    Address: "EMASUNDWINI  OYAYA TRADITIONAL AUTHORITY  NTULI TRIBAL AREA",
    Latitude: "-28.9515",
    Longitude: "31.2692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12950",
    WardLookupId: "3772",
    VDNumber: "43414376",
    RegisteredPopulation: "904",
    VotingStationName: "THEMBALESIZWE PRIMARY SCHOOL",
    Address: "NDIKILINI AREA  OYAYA TRADITIONAL AUTHORITY  KWA-PETT",
    Latitude: "-28.9154",
    Longitude: "31.2564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12951",
    WardLookupId: "3772",
    VDNumber: "43418550",
    RegisteredPopulation: "383",
    VotingStationName: "NDLONGOLWANE HIGH SCHOOL",
    Address: "MPAPHALA  MVUZANE TRADITIONAL COUNCIL  ESHOWE",
    Latitude: "-28.8715",
    Longitude: "31.2393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12952",
    WardLookupId: "3773",
    VDNumber: "43411395",
    RegisteredPopulation: "493",
    VotingStationName: "MANXELE HIGH SCHOOL",
    Address: "NGILANDELA AREA  OYAYA TRADITIONAL COUNCIL  ESHOWE",
    Latitude: "-28.9602",
    Longitude: "31.305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12953",
    WardLookupId: "3773",
    VDNumber: "43411429",
    RegisteredPopulation: "762",
    VotingStationName: "NDLUYESILO HIGH SCHOOL",
    Address:
      "SAMUNGU(ON ROAD TO KHANGELANI)  BANGINDODA TRADITIONAL AUTHORITY   BANGINDODA T/A, ESHOWE",
    Latitude: "-28.9921",
    Longitude: "31.2965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12954",
    WardLookupId: "3773",
    VDNumber: "43411722",
    RegisteredPopulation: "453",
    VotingStationName: "SAMUNGU COMBINED PRIMARY SCHOOL",
    Address:
      "SAMUNGU (NEXT TO CLINIC)  BANGINDODA TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-29.0024",
    Longitude: "31.3264",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12955",
    WardLookupId: "3773",
    VDNumber: "43411744",
    RegisteredPopulation: "436",
    VotingStationName: "HLUNGWINI COMBINED PRIMARY SCHOOL",
    Address:
      "EMANKWANYANENI AREA  BANGINDODA TRADITIONAL AUTHORITY  NANGINDODA T/ A, ESHOWE",
    Latitude: "-29.0768",
    Longitude: "31.2891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12956",
    WardLookupId: "3773",
    VDNumber: "43411755",
    RegisteredPopulation: "1315",
    VotingStationName: "NGUDWINI PRIMARY SCHOOL",
    Address:
      "SHAYINJA AREA  BANGINDODA TRADITIONAL AUTHORITY  BANGINDODA T/A, ESHOWE",
    Latitude: "-29.0515",
    Longitude: "31.2504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12957",
    WardLookupId: "3773",
    VDNumber: "43411845",
    RegisteredPopulation: "356",
    VotingStationName: "MAQOTHA PRIMARY SCHOOL",
    Address:
      "MTHOMBENI AREA  BANGINDODA TRADITIONAL AUTHORITY  BANGINDODA TRIBAL AUTHORITY",
    Latitude: "-29.0968",
    Longitude: "31.2656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12958",
    WardLookupId: "3773",
    VDNumber: "43417661",
    RegisteredPopulation: "155",
    VotingStationName: "VULINGQONDO STORE TENT",
    Address: "MPOFANA  AREA  NGUDWINI AREA  BANGINDODA TRIBAL  AUTHORITY",
    Latitude: "-29.0407",
    Longitude: "31.2225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12959",
    WardLookupId: "3774",
    VDNumber: "43411485",
    RegisteredPopulation: "470",
    VotingStationName: "NYEMBE COMBINED PRIMARY SCHOOL",
    Address: "EZIMBIDLI AREA  MOMBENI TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.9709",
    Longitude: "31.3807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12960",
    WardLookupId: "3774",
    VDNumber: "43411766",
    RegisteredPopulation: "319",
    VotingStationName: "MANQINDI PRIMARY SCHOOL",
    Address:
      "EMANKENGANENI AREA  MOMBENI TRADITIONAL AUTHORITY  MOMBENI T/A, ESHOWE",
    Latitude: "-29.1241",
    Longitude: "31.3096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12961",
    WardLookupId: "3774",
    VDNumber: "43411777",
    RegisteredPopulation: "364",
    VotingStationName: "HAYINYAMA PRIMARY SCHOOL",
    Address: "MOMBENI TRIB AUTH  MANKENGANENI RES  ESHOWE",
    Latitude: "-29.0964",
    Longitude: "31.3189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12962",
    WardLookupId: "3774",
    VDNumber: "43412093",
    RegisteredPopulation: "1119",
    VotingStationName: "GCOTSHENI PRIMARY SCHOOL",
    Address: "MOMBENI TRIB AUTH  GCOTSHENI AREA  ESHOWE",
    Latitude: "-29.0282",
    Longitude: "31.3459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12963",
    WardLookupId: "3774",
    VDNumber: "43412116",
    RegisteredPopulation: "484",
    VotingStationName: "MOMBENI PRIMARY SCHOOL",
    Address:
      "IZINLEZUKA NEAR TRIBAL COURT  MOMBENI TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.9926",
    Longitude: "31.3748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12964",
    WardLookupId: "3774",
    VDNumber: "43415007",
    RegisteredPopulation: "739",
    VotingStationName: "MGEBISA PRIMARY SCHOOL",
    Address: "MOMBENI TRIB AUTH  MPHENDLE RES  ESHOWE",
    Latitude: "-29.0777",
    Longitude: "31.349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12965",
    WardLookupId: "3774",
    VDNumber: "43418572",
    RegisteredPopulation: "417",
    VotingStationName: "BIYELA COMMUNITY SERVICE CENTRE",
    Address: "ZINHLEZUKA  MOMBENI T/A  MOMBENI TRADITIONAL AUTHORITY",
    Latitude: "-28.9943",
    Longitude: "31.3493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12966",
    WardLookupId: "3775",
    VDNumber: "43411373",
    RegisteredPopulation: "285",
    VotingStationName: "NTUMENI PRIMARY SCHOOL",
    Address: "NTUMENI FARM LAND  NTUMENI SUGAR MILL  NTUMENI",
    Latitude: "-28.8917",
    Longitude: "31.3129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12967",
    WardLookupId: "3775",
    VDNumber: "43411452",
    RegisteredPopulation: "319",
    VotingStationName: "DONSINTABA PRIMARY SCHOOL",
    Address: "MAWOSHENI AREA  SHANGE TRADITIONAL AUTHORITY  SHANGE T/A, ESHOWE",
    Latitude: "-28.9485",
    Longitude: "31.3571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12968",
    WardLookupId: "3775",
    VDNumber: "43411463",
    RegisteredPopulation: "1302",
    VotingStationName: "SARON PRIMARY SCHOOL",
    Address: "NKANINI AREA   SHANGE TRADITIONAL AUTHORITY  SHANGE T/A, ESHOWE",
    Latitude: "-28.9333",
    Longitude: "31.4041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12969",
    WardLookupId: "3775",
    VDNumber: "43415209",
    RegisteredPopulation: "430",
    VotingStationName: "BROCKLEE FARM",
    Address: "BROCKLEE FARM  NTUMENI FARMING AREA  ESHOWE",
    Latitude: "-28.8716",
    Longitude: "31.407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12970",
    WardLookupId: "3775",
    VDNumber: "43417481",
    RegisteredPopulation: "901",
    VotingStationName: "MATHIBELANA PRIMARY SCHOOL",
    Address:
      "MATHIBELANA RESERVE  SHANGE TRADITIONAL AUTHORITY  SHANGE T/C, ESHOWE",
    Latitude: "-28.9254",
    Longitude: "31.4237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12971",
    WardLookupId: "3775",
    VDNumber: "43430048",
    RegisteredPopulation: "435",
    VotingStationName: "MPUSHINI PARK COMMUNITY HALL",
    Address: "MPUSHINI, WINDHAM RD, ESHOWE  UMLALAZI MUNICIPALITY  ESHOWE",
    Latitude: "-28.9007",
    Longitude: "31.4413",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12972",
    WardLookupId: "3775",
    VDNumber: "44520038",
    RegisteredPopulation: "108",
    VotingStationName: "SEVENTH DAY ADVENTIST CHURCH",
    Address: "JOHN ROSS HIGHWAY  ESHOWE  ESHOWE",
    Latitude: "-28.9024",
    Longitude: "31.466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12973",
    WardLookupId: "3776",
    VDNumber: "43411564",
    RegisteredPopulation: "886",
    VotingStationName: "GCININHLIZIYO COMBINED PRIMARY SCHOOL",
    Address: "SHANGE TRIB AUTH  VUMA AREA  ESHOWE",
    Latitude: "-28.805",
    Longitude: "31.3462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12974",
    WardLookupId: "3776",
    VDNumber: "43411575",
    RegisteredPopulation: "890",
    VotingStationName: "NTABANTUZUMA HIGH SCHOOL",
    Address: "EKHOLWENI TRIB AUTH  EKHOLWENI RES  NTUMENI",
    Latitude: "-28.8547",
    Longitude: "31.3307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12975",
    WardLookupId: "3776",
    VDNumber: "43411586",
    RegisteredPopulation: "1009",
    VotingStationName: "ENHLISA PRIMARY SCHOOL",
    Address: "SHANGE TRIB AUTH  VUMA RES  ESHOWE",
    Latitude: "-28.8227",
    Longitude: "31.3657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12976",
    WardLookupId: "3776",
    VDNumber: "43415591",
    RegisteredPopulation: "702",
    VotingStationName: "NSUKAZI PRIMARY SCHOOL",
    Address: "SHANGE TRIB AUTH  VUMA RES  ESHOWE",
    Latitude: "-28.7883",
    Longitude: "31.3853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12977",
    WardLookupId: "3776",
    VDNumber: "43416514",
    RegisteredPopulation: "968",
    VotingStationName: "KHOLWENI COMMUNITY HALL",
    Address: "NTUMENI AREA  UMLALAZI [ESHOWE]",
    Latitude: "-28.859",
    Longitude: "31.3222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12978",
    WardLookupId: "3776",
    VDNumber: "44520016",
    RegisteredPopulation: "115",
    VotingStationName: "NGUNUNDU TEMP STATION (TENT)",
    Address: "NGUNUNDU  NGUNUNDU AREA  KHOLWENI TRIBAL AUTHORITY",
    Latitude: "-28.8155",
    Longitude: "31.3269",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12979",
    WardLookupId: "3776",
    VDNumber: "44520027",
    RegisteredPopulation: "171",
    VotingStationName: "PHAPHAMA PEACE CENTRE",
    Address: "NSUKAZI AREA  SHANGE TRIBAL AUTHORITY",
    Latitude: "-28.783",
    Longitude: "31.3428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12980",
    WardLookupId: "3777",
    VDNumber: "43411597",
    RegisteredPopulation: "363",
    VotingStationName: "KWA-MPOFU PRIMARY SCHOOL",
    Address: "MPUNGOSE T/AREA  KWA-MPOFU  ESHOWE",
    Latitude: "-28.8296",
    Longitude: "31.4339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12981",
    WardLookupId: "3777",
    VDNumber: "43411643",
    RegisteredPopulation: "914",
    VotingStationName: "MAGUQU PRIMARY SCHOOL",
    Address:
      "MBIZA NO 2 / DANGWENI  MPUNGOSE TRADITIONAL AUTHORITY  MPUNGOSE TRIBAL AUTH",
    Latitude: "-28.8103",
    Longitude: "31.503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12982",
    WardLookupId: "3777",
    VDNumber: "43412060",
    RegisteredPopulation: "1345",
    VotingStationName: "UMLALAZI COMBINED PRIMARY SCHOOL",
    Address: "MPUNGOSE T/AREA  UMLALAZI  ESHOWE",
    Latitude: "-28.8389",
    Longitude: "31.4882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12983",
    WardLookupId: "3777",
    VDNumber: "43414994",
    RegisteredPopulation: "663",
    VotingStationName: "MUNTUYEDWA PRIMARY SCHOOL",
    Address: "ESIPHEZI AREA  MPUNGOSE TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.8231",
    Longitude: "31.4025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12984",
    WardLookupId: "3777",
    VDNumber: "43416525",
    RegisteredPopulation: "303",
    VotingStationName: "ZIBAMBELE PRIMARY SCHOOL",
    Address:
      "THINTUMKHABA NEAR HLOKOHLOKO  MPUNGOSE TRADITIONAL AUTHORITY  UMLALAZI [ESHOWE]",
    Latitude: "-28.8395",
    Longitude: "31.4556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12985",
    WardLookupId: "3778",
    VDNumber: "43412363",
    RegisteredPopulation: "933",
    VotingStationName: "GAWOZI JUNIOR SECONDARY SCHOOL",
    Address: "MPUNGOSE T/AREA  UMLALAZI  ESHOWE",
    Latitude: "-28.8348",
    Longitude: "31.5146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12986",
    WardLookupId: "3778",
    VDNumber: "43414769",
    RegisteredPopulation: "1393",
    VotingStationName: "ESIQWAQWENI COMBINED PRIMARY SCHOOL",
    Address:
      "EZIQWAQWENI AREA  MPUNGOSE TRADITIONAL AUTHORITY  ESHOWE [UMLALAZI]",
    Latitude: "-28.8597",
    Longitude: "31.5112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12987",
    WardLookupId: "3778",
    VDNumber: "43416536",
    RegisteredPopulation: "1228",
    VotingStationName: "NKUME PRIMARY SCHOOL",
    Address: "EMAQELENI  MPUNGOSE TRADITIONAL COUNCIL  MPUNGOSE T/A",
    Latitude: "-28.8338",
    Longitude: "31.5062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12988",
    WardLookupId: "3778",
    VDNumber: "43416604",
    RegisteredPopulation: "874",
    VotingStationName: "MPUNGOSE COMMUNITY HALL",
    Address:
      "MBIZA NO. 1 OPP. TRIBAL COURT  MPUNGOSE TRADITIONAL AUTHORITY  ESHOWE [UMLALAZI]",
    Latitude: "-28.8472",
    Longitude: "31.4894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12989",
    WardLookupId: "3778",
    VDNumber: "43418594",
    RegisteredPopulation: "452",
    VotingStationName: "BABTIST CHURCH",
    Address: "EMAQELENI  MPUNGOSE TRADITIONAL COUNCIL  ESHOWE",
    Latitude: "-28.8309",
    Longitude: "31.5277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12990",
    WardLookupId: "3779",
    VDNumber: "43430026",
    RegisteredPopulation: "1453",
    VotingStationName: "ESHOWE TOWN HALL",
    Address: "OSBORN ROAD  ESHOWE  ESHOWE",
    Latitude: "-28.8972",
    Longitude: "31.4667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12991",
    WardLookupId: "3779",
    VDNumber: "43430037",
    RegisteredPopulation: "548",
    VotingStationName: "SUNNYDALE HALL",
    Address: "CHARLES HORSLEY AVE  SUNNYDALE  ESHOWE",
    Latitude: "-28.8871",
    Longitude: "31.487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12992",
    WardLookupId: "3779",
    VDNumber: "43430060",
    RegisteredPopulation: "862",
    VotingStationName: "ESHOWE HIGH SCHOOL",
    Address: "25 STEPHEN OFFER  ESHOWE  ESHOWE",
    Latitude: "-28.8913",
    Longitude: "31.4611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12993",
    WardLookupId: "3779",
    VDNumber: "43430071",
    RegisteredPopulation: "1240",
    VotingStationName: "SUNNYDALE PEACE CENTRE",
    Address: "2867 EXTENSION 2867, BISHOP BIYASE   SUNNYDALE  ESHOWE",
    Latitude: "-28.8817",
    Longitude: "31.4824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12994",
    WardLookupId: "3779",
    VDNumber: "43430105",
    RegisteredPopulation: "550",
    VotingStationName: "NORWOOD HALL",
    Address: "NORWOOD 1ST BUILDING ON RT   NORWOOD  ESHOWE",
    Latitude: "-28.8931",
    Longitude: "31.4753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12995",
    WardLookupId: "3780",
    VDNumber: "43430015",
    RegisteredPopulation: "1751",
    VotingStationName: "KING DINUZULU COMMUNITY HALL",
    Address: "NKOSI NDWANDWE RD  ESHOWE  ESHOWE",
    Latitude: "-28.8889",
    Longitude: "31.4968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12996",
    WardLookupId: "3780",
    VDNumber: "43430059",
    RegisteredPopulation: "1213",
    VotingStationName: "UBAMBISWANO HIGH SCHOOL",
    Address: "UBAMBISWANO STREET  KING DINUZULU  ESHOWE",
    Latitude: "-28.8877",
    Longitude: "31.4937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12997",
    WardLookupId: "3780",
    VDNumber: "43430116",
    RegisteredPopulation: "530",
    VotingStationName: "BONAMUVA PRIMARY SCHOOL",
    Address: "KING DINUZULU  UMLALAZI  ESHOWE",
    Latitude: "-28.8901",
    Longitude: "31.4989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12998",
    WardLookupId: "3781",
    VDNumber: "43412352",
    RegisteredPopulation: "585",
    VotingStationName: "ALL SAINTS CATHOLIC CHURCH",
    Address:
      "MAWUDLU - ALONG RD TO MPUMAZE  KZN284 - UMLALAZI [ESHOWE]  MPUNGOSE T/C",
    Latitude: "-28.9028",
    Longitude: "31.5301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "12999",
    WardLookupId: "3781",
    VDNumber: "43414770",
    RegisteredPopulation: "613",
    VotingStationName: "KHANYANGODUMO PRIMARY SCHOOL",
    Address:
      "IZINDOPHI ALONG MKHUKHUZE RV  ESHOWE  MPUNGOSE TRADITIONAL COUNCIL",
    Latitude: "-28.9094",
    Longitude: "31.5037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13000",
    WardLookupId: "3781",
    VDNumber: "43415029",
    RegisteredPopulation: "846",
    VotingStationName: "KWA-MONDI PRIMARY SCHOOL",
    Address:
      "THAWENI NEAR ZWELITHINI HIGH  ESHOWE  KWA-MONDI TRADITIONAL COUNCIL",
    Latitude: "-28.8948",
    Longitude: "31.5035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13001",
    WardLookupId: "3781",
    VDNumber: "43417492",
    RegisteredPopulation: "1079",
    VotingStationName: "KWAMONDI TRIBAL COURT",
    Address:
      "NYANINI, ALONG RD TO KING DIN  ESHOWE  KWA-MONDI TRADITIONAL COUNCIL",
    Latitude: "-28.8949",
    Longitude: "31.4876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13002",
    WardLookupId: "3781",
    VDNumber: "43417920",
    RegisteredPopulation: "543",
    VotingStationName: "KWAMFANA CRECHE",
    Address: "KWAMFANA  KWAMONDI TRADITIONAL COUNCIL  ESHOWE",
    Latitude: "-28.8757",
    Longitude: "31.4951",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13003",
    WardLookupId: "3781",
    VDNumber: "43430082",
    RegisteredPopulation: "1150",
    VotingStationName: "BAQAQE PRIMARY SCHOOL",
    Address: "NYANINI, NEXT TO COLLEGE  ESHOWE  KWAMONDI TRIBAL AUTHORITY",
    Latitude: "-28.8947",
    Longitude: "31.4804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13004",
    WardLookupId: "3782",
    VDNumber: "43412330",
    RegisteredPopulation: "925",
    VotingStationName: "ENDLOVINI PRIMARY SCHOOL",
    Address:
      "NOSHUNGU,NEXT TO ONGOYE FOREST  MZIMELA TRADITIONAL AUTHORITY  MTUNZINI",
    Latitude: "-28.8713",
    Longitude: "31.6423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13005",
    WardLookupId: "3782",
    VDNumber: "43412341",
    RegisteredPopulation: "900",
    VotingStationName: "MPUMAZE COMBINED PRIMARY SCHOOL",
    Address: "MPUMAZE, ALONG MAIN RD  ESHOWE  MPUNGOSE TRADITIONAL COUNCIL",
    Latitude: "-28.8967",
    Longitude: "31.5686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13006",
    WardLookupId: "3782",
    VDNumber: "43412396",
    RegisteredPopulation: "677",
    VotingStationName: "NJINGILI HIGH SCHOOL",
    Address: "MBANGAYIYA AREA  MZIMELA TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.864",
    Longitude: "31.579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13007",
    WardLookupId: "3782",
    VDNumber: "43412486",
    RegisteredPopulation: "750",
    VotingStationName: "UMHLATUZANA COMBINED PRIMARY SCHOOL",
    Address: "MPUNGOSE T/AREA  UMHLATHUZANA  ESHOWE",
    Latitude: "-28.8566",
    Longitude: "31.569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13008",
    WardLookupId: "3782",
    VDNumber: "43416569",
    RegisteredPopulation: "586",
    VotingStationName: "ENTENJANE PRIMARY SCHOOL",
    Address: "ENTENJANE RESERVE  KWMONDI T/C  KWAMONDI T/A",
    Latitude: "-28.8871",
    Longitude: "31.5396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13009",
    WardLookupId: "3782",
    VDNumber: "43416648",
    RegisteredPopulation: "580",
    VotingStationName: "SIPHOSO HIGH SCHOOL",
    Address: "N/A  MPUNGOSE TRIBAL AREA  MPUNGOSE TRIBAL AUTH",
    Latitude: "-28.8989",
    Longitude: "31.5796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13010",
    WardLookupId: "3783",
    VDNumber: "43411474",
    RegisteredPopulation: "1118",
    VotingStationName: "IMFIHLO PRIMARY SCHOOL",
    Address: "NGOGO RESERVE  KWAKHOZA TRIBAL AUTHORITY  ESHOWE",
    Latitude: "-28.9464",
    Longitude: "31.4423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13011",
    WardLookupId: "3783",
    VDNumber: "43411496",
    RegisteredPopulation: "541",
    VotingStationName: "NOGOBHOZA PRIMARY SCHOOL",
    Address: "NOGOBHOZA AREA  KHOZA TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.9584",
    Longitude: "31.4921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13012",
    WardLookupId: "3783",
    VDNumber: "43411508",
    RegisteredPopulation: "604",
    VotingStationName: "PHINDULIMI HIGH SCHOOL",
    Address: "OBUMBA RESERVE  KWAKHOZA TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.955",
    Longitude: "31.4721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13013",
    WardLookupId: "3783",
    VDNumber: "43411665",
    RegisteredPopulation: "943",
    VotingStationName: "VELAMUVA HIGH SCHOOL",
    Address: "SALVESHE RESERVE  KWAKHOZA TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.9968",
    Longitude: "31.4735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13014",
    WardLookupId: "3783",
    VDNumber: "43416547",
    RegisteredPopulation: "466",
    VotingStationName: "SIPHEZI PRIMARY  SCHOOL",
    Address: "EZINGWENYA  KWA-KHOZA TRADITIONAL COUNCIL  KWAKHOZA T/A",
    Latitude: "-28.9715",
    Longitude: "31.4348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13015",
    WardLookupId: "3783",
    VDNumber: "43417896",
    RegisteredPopulation: "865",
    VotingStationName: "KWAJAZI PRIMARY SCHOOL",
    Address:
      "PHAPHU RESERVE  KWAKHOZA TRIBAL AUTHORITY  KWAKHOZA TRIBAL AUTHORITY",
    Latitude: "-28.9205",
    Longitude: "31.4506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13016",
    WardLookupId: "3784",
    VDNumber: "43412206",
    RegisteredPopulation: "1399",
    VotingStationName: "HEMFANE PRIMARY SCHOOL",
    Address: "HEMFANE RESERVE  NZUZA TRADITIONAL COUNCIL  ESHOWE",
    Latitude: "-28.9615",
    Longitude: "31.5788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13017",
    WardLookupId: "3784",
    VDNumber: "43412217",
    RegisteredPopulation: "974",
    VotingStationName: "EMVUTSHINI PRIMARY SCHOOL",
    Address:
      "MVUTSHINI  NZUZA TRADITIONAL COUNCIL  NZUZA TRADITIONAL AUTHORITY",
    Latitude: "-28.943",
    Longitude: "31.5635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13018",
    WardLookupId: "3784",
    VDNumber: "43412239",
    RegisteredPopulation: "793",
    VotingStationName: "NOMDUMO PRIMARY SCHOOL",
    Address: "MPUNGOSE T/AREA  MAKHILIMBA RES  ESHOWE",
    Latitude: "-28.9195",
    Longitude: "31.5891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13019",
    WardLookupId: "3784",
    VDNumber: "43412240",
    RegisteredPopulation: "638",
    VotingStationName: "MAGUMBOMANE LOWER PRIMARY SCHOOL",
    Address: "NZUZA TRIB AUTH  WOMBANE RES  MTUNZINI",
    Latitude: "-28.9444",
    Longitude: "31.5477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13020",
    WardLookupId: "3784",
    VDNumber: "43416570",
    RegisteredPopulation: "339",
    VotingStationName: "ST BENEDICTINE CHURCH",
    Address: "N/A  NZUZA TRIBAL AUTHORITY  NZUZA TRIBAL AUTH",
    Latitude: "-28.9402",
    Longitude: "31.534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13021",
    WardLookupId: "3784",
    VDNumber: "43418606",
    RegisteredPopulation: "446",
    VotingStationName: "NGOBE`S SITE TENT",
    Address: "MAHUDLU  MPUNGOSE TRADITIONAL COUNCIL  ESHOWE",
    Latitude: "-28.9013",
    Longitude: "31.54",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13022",
    WardLookupId: "3785",
    VDNumber: "43412071",
    RegisteredPopulation: "483",
    VotingStationName: "WOMBANE PRIMARY SCHOOL.",
    Address: "NZUZA T/AUTH  VEKEZA RES  ESHOWE",
    Latitude: "-28.9722",
    Longitude: "31.5481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13023",
    WardLookupId: "3785",
    VDNumber: "43412228",
    RegisteredPopulation: "858",
    VotingStationName: "IVUKAYIBAMBE HIGHER PRIMARY SCHOOL",
    Address: "NZUZA T/AUTH  AMAQHULU RES  MTUNZINI",
    Latitude: "-28.9679",
    Longitude: "31.6127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13024",
    WardLookupId: "3785",
    VDNumber: "43412251",
    RegisteredPopulation: "930",
    VotingStationName: "OGAYA COMMUNITY HCENTRE",
    Address: "NZUZA T/AREA  NHLABABO RESERVE  ESHOWE",
    Latitude: "-28.9405",
    Longitude: "31.6232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13025",
    WardLookupId: "3785",
    VDNumber: "43414758",
    RegisteredPopulation: "812",
    VotingStationName: "ENYEZANE LOWER / HIGHER PRIMARY SCHOOL",
    Address: "ENYEZANE RES 9  NZUZA T/A   NZUZA TRIBAL AUTHORITY",
    Latitude: "-28.9859",
    Longitude: "31.5569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13026",
    WardLookupId: "3785",
    VDNumber: "43416581",
    RegisteredPopulation: "618",
    VotingStationName: "NXENJANE LOWER PRIMARY SCHOOL",
    Address: "MABHOKWENI  HEMFANE RES  UMLALAZI [ESHOWE]",
    Latitude: "-28.9797",
    Longitude: "31.5757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13027",
    WardLookupId: "3785",
    VDNumber: "43418617",
    RegisteredPopulation: "452",
    VotingStationName: "DUTCH REFORMED CHURCH",
    Address: "ENYEZANE  NZUZA TRADITIONAL COUNCIL  ESHOWE",
    Latitude: "-28.9883",
    Longitude: "31.5739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13028",
    WardLookupId: "3786",
    VDNumber: "43411711",
    RegisteredPopulation: "1096",
    VotingStationName: "ETHAFENI PRIMARY SCHOOL",
    Address: "KHOZA TRIB AUTH  EZINGWENYA RES  ESHOWE",
    Latitude: "-29.0102",
    Longitude: "31.491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13029",
    WardLookupId: "3786",
    VDNumber: "43412026",
    RegisteredPopulation: "580",
    VotingStationName: "AMATIKULU PRIMARY SCHOOL",
    Address: "MILL ROAD  AMATIKULU VILLAGE  ESHOWE",
    Latitude: "-29.0477",
    Longitude: "31.5269",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13030",
    WardLookupId: "3786",
    VDNumber: "43412037",
    RegisteredPopulation: "347",
    VotingStationName: "WOLI / SIYAMDUMISA FARM",
    Address: "WOLI  NEXT TO ZULULAND TRACTOR  SIYAMDUMISA FARM  GINGINDLOVU",
    Latitude: "-29.0183",
    Longitude: "31.6061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13031",
    WardLookupId: "3786",
    VDNumber: "43440016",
    RegisteredPopulation: "1474",
    VotingStationName: "GINGINDLOVU COMMUNITY HALL",
    Address: "MAIN STREET NEXT TO SHOPRITE  GINGINDLOVU  GINGNDLOVU",
    Latitude: "-29.0309",
    Longitude: "31.5884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13032",
    WardLookupId: "3787",
    VDNumber: "43412183",
    RegisteredPopulation: "1212",
    VotingStationName: "SABEKA COMMUNITY CENTRE",
    Address: "SABEKA AREA  NZUZA T/A  GINGINDLOVU",
    Latitude: "-28.9733",
    Longitude: "31.6456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13033",
    WardLookupId: "3787",
    VDNumber: "43412273",
    RegisteredPopulation: "174",
    VotingStationName: "BALENI FARM",
    Address: "BALENI FARM  MTUNZINI  MTUNZINI",
    Latitude: "-28.9472",
    Longitude: "31.7073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13034",
    WardLookupId: "3787",
    VDNumber: "43412307",
    RegisteredPopulation: "541",
    VotingStationName: "VELESHOWE PRIMARY SCHOOL",
    Address: "OBANJENI  OGAGWINI  OGAGWINI TRIBAL AUTHORITY",
    Latitude: "-28.9528",
    Longitude: "31.6586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13035",
    WardLookupId: "3787",
    VDNumber: "43470019",
    RegisteredPopulation: "1854",
    VotingStationName: "MTUNZINI TOWN HALL",
    Address: "11 HELY HUTCHISON ST  MTUNZINI  ESHOWE",
    Latitude: "-28.9522",
    Longitude: "31.7577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13036",
    WardLookupId: "3788",
    VDNumber: "43412194",
    RegisteredPopulation: "862",
    VotingStationName: "NZUZA TRIBAL COURT",
    Address: "NZUZA TRIB AUTH  ENSINGWENI AREA  MTUNZINI",
    Latitude: "-28.9565",
    Longitude: "31.6406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13037",
    WardLookupId: "3788",
    VDNumber: "43412262",
    RegisteredPopulation: "672",
    VotingStationName: "ST MARIA ROMAN CATHOLIC CHURCH",
    Address: "OBANJENI AREA  OBANJENI          NZUZA TRIBAL AUTHORITY",
    Latitude: "-28.9338",
    Longitude: "31.6748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13038",
    WardLookupId: "3788",
    VDNumber: "43412295",
    RegisteredPopulation: "857",
    VotingStationName: "OBANJENI PRIMARY SCHOOL",
    Address: "ZULU T/AREA  OBANJENI  MTUNZINI",
    Latitude: "-28.9243",
    Longitude: "31.7082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13039",
    WardLookupId: "3788",
    VDNumber: "43416660",
    RegisteredPopulation: "1086",
    VotingStationName: "OBANJENI CRECHE",
    Address: "OBANJENI  OBANJENI TRIBAL AUTHORITY  OGAGWINI TRIBAL AUTHORITY",
    Latitude: "-28.9334",
    Longitude: "31.6887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13040",
    WardLookupId: "3788",
    VDNumber: "43418628",
    RegisteredPopulation: "518",
    VotingStationName: "KWASIBHAMU PEACE CENTRE",
    Address: "KWASIBHAMU  OGAGWINI / OBANJENI TRADITIONAL COUNCIL  MTUNZINI",
    Latitude: "-28.9199",
    Longitude: "31.723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13041",
    WardLookupId: "3788",
    VDNumber: "43418639",
    RegisteredPopulation: "725",
    VotingStationName: "BHEKAMANZIMELA PRIMARY SCHOOL",
    Address: "NOZANDLA AREA  MZIMELA TRADITIONAL AUTHORITY  MTUNZINI NU",
    Latitude: "-28.9106",
    Longitude: "31.631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13042",
    WardLookupId: "3789",
    VDNumber: "43412284",
    RegisteredPopulation: "464",
    VotingStationName: "ZINQOBELE SENIOR SECONDARY SCHOOL",
    Address: "EZIKOSHI RESERVE  MZIMELA TRADITIONAL AUTHORITY  MTUNZINI",
    Latitude: "-28.8893",
    Longitude: "31.6549",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13043",
    WardLookupId: "3789",
    VDNumber: "43412329",
    RegisteredPopulation: "808",
    VotingStationName: "LINDELIHLE PRIMARY SCHOOL",
    Address: "EZINGWENYA RESERVE  MZIMELA TRADITIONAL AUTHORITY  MTUNZINI",
    Latitude: "-28.9067",
    Longitude: "31.6966",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13044",
    WardLookupId: "3789",
    VDNumber: "43412419",
    RegisteredPopulation: "1114",
    VotingStationName: "SIBAMBISENE PRIMARY SCHOOL",
    Address: "QWAYINDUKU RESERVE  MZIMELA TRADITIONAL AUTHORITY  MTUNZINI",
    Latitude: "-28.8821",
    Longitude: "31.7068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13045",
    WardLookupId: "3789",
    VDNumber: "43416659",
    RegisteredPopulation: "651",
    VotingStationName: "YETHENI PRIMARY SCHOOL",
    Address:
      "YETHENI,NEXT TO GOLELA T/COURT  MZIMELA TRADITIONAL AUTHORITY  MTUNZINI",
    Latitude: "-28.9044",
    Longitude: "31.666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13046",
    WardLookupId: "3789",
    VDNumber: "43418640",
    RegisteredPopulation: "404",
    VotingStationName: "KHANYAKUFIKILE PRIMARY SCHOOL",
    Address:
      "EMANYAMENI RESERVE  MZIMELA TRADITIONAL AUTHORITY  MZIMELA TRADITIONAL COUNCIL",
    Latitude: "-28.8755",
    Longitude: "31.672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13047",
    WardLookupId: "3790",
    VDNumber: "43412420",
    RegisteredPopulation: "762",
    VotingStationName: "ZIMEME HIGH SCHOOL",
    Address: "MANZAMNYAMA RESERVE  MZIMELA TRADITIONAL AUTHORITY  MTUNZINI",
    Latitude: "-28.8672",
    Longitude: "31.7388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13048",
    WardLookupId: "3790",
    VDNumber: "43412509",
    RegisteredPopulation: "532",
    VotingStationName: "ISIGODO PUBLIC PRIMARY SCHOOL",
    Address: "MHLATHUZANA RESERVE  MZIMELA TRADITIONAL AUTHORITY  MTUNZINI",
    Latitude: "-28.8423",
    Longitude: "31.6157",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13049",
    WardLookupId: "3790",
    VDNumber: "43412666",
    RegisteredPopulation: "965",
    VotingStationName: "SIBHAKUZA SENIOR SECONDARY SCHOOL",
    Address: "MZIMELA T/AREA  PHONGOLA  RES  MTUNZINI",
    Latitude: "-28.8247",
    Longitude: "31.7761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13050",
    WardLookupId: "3790",
    VDNumber: "43416637",
    RegisteredPopulation: "427",
    VotingStationName: "MADOLO PEACE CENTRE HALL",
    Address:
      "LUBISANA  MZIMELA TRADITIONAL COUNCIL  MZIMELA TRADITIONAL COUNCIL",
    Latitude: "-28.8352",
    Longitude: "31.7965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13051",
    WardLookupId: "3790",
    VDNumber: "43416671",
    RegisteredPopulation: "490",
    VotingStationName: "MASHANANANDANA HIGH  SCHOOL",
    Address:
      "QWAYINDUKU RESERVE  MZIMELA TRADITIONAL  AUTHORITY  UMLALAZI [ESHOWE]",
    Latitude: "-28.8909",
    Longitude: "31.7313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13052",
    WardLookupId: "3790",
    VDNumber: "43417931",
    RegisteredPopulation: "393",
    VotingStationName: "MTHINTOMBI PRIMARY SCHOOL",
    Address: "SABE AREA  MZIMELA TRADITIONAL AUTTORITY  MTUNZINI",
    Latitude: "-28.8347",
    Longitude: "31.81",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13053",
    WardLookupId: "3791",
    VDNumber: "43412644",
    RegisteredPopulation: "769",
    VotingStationName: "NTSHIDI COMBINED PRIMARY SCHOOL",
    Address: "NTSHIDI AREA  MZIMELA TRADITIONAL COUNCIL  MTUNZINI",
    Latitude: "-28.7936",
    Longitude: "31.7291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13054",
    WardLookupId: "3791",
    VDNumber: "43412655",
    RegisteredPopulation: "731",
    VotingStationName: "OYEMENI PRIMARY SCHOOL",
    Address: "OYEMENI RESERVE  MZIMELA TRADITIONAL AUTHORITY  MTUNZINI",
    Latitude: "-28.8121",
    Longitude: "31.6749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13055",
    WardLookupId: "3791",
    VDNumber: "43412857",
    RegisteredPopulation: "898",
    VotingStationName: "MEHLATHATHANI PRIMARY SCHOOL",
    Address: "MACEKANE RESERVE  MZIMELA TRADITIONAL AUTHORITY  MTUNZINI",
    Latitude: "-28.8048",
    Longitude: "31.8034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13056",
    WardLookupId: "3791",
    VDNumber: "43413342",
    RegisteredPopulation: "930",
    VotingStationName: "NTENESHANA PRIMARY SCHOOL",
    Address: "MZIMELA T/AREA  NTENESHANA RES  MTUNZINI",
    Latitude: "-28.784",
    Longitude: "31.7621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13057",
    WardLookupId: "3791",
    VDNumber: "43415603",
    RegisteredPopulation: "240",
    VotingStationName: "OHHAHENI CRECH",
    Address: "OHHAHENI RESERVE  MTUNZINI  MZIMELA TRIBAL AUTHORITY",
    Latitude: "-28.8089",
    Longitude: "31.7072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13058",
    WardLookupId: "3792",
    VDNumber: "43412633",
    RegisteredPopulation: "1322",
    VotingStationName: "MGITSHWA HIGH SCHOOL",
    Address:
      "NDLANGUBO, NEXT TO CLINIC  NDLANGUBO TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.7665",
    Longitude: "31.7002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13059",
    WardLookupId: "3792",
    VDNumber: "43413331",
    RegisteredPopulation: "1348",
    VotingStationName: "NGQAMUZANA PRIMARY SCHOOL",
    Address: "NCEKWANE AREA  MZIMELA TRADITIONAL AUTHORITY  MTUNZINI",
    Latitude: "-28.76",
    Longitude: "31.7285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13060",
    WardLookupId: "3792",
    VDNumber: "43417504",
    RegisteredPopulation: "462",
    VotingStationName: "INDEPENDENT BAPTIST CHURCH",
    Address: "KWA-MANYATHI AREA  MZIMELA TRADITIONAL AUTHORITY  MTUNZINI",
    Latitude: "-28.7873",
    Longitude: "31.6965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13061",
    WardLookupId: "3792",
    VDNumber: "43418651",
    RegisteredPopulation: "668",
    VotingStationName: "ST. SAVIOR ANGLICAN CHURCH",
    Address: "KHABINGWE (EZITENDENI)  NDLANGUBO TRADITIONAL CAUTHORITY  ESHOWE",
    Latitude: "-28.7727",
    Longitude: "31.6713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13062",
    WardLookupId: "3793",
    VDNumber: "43412611",
    RegisteredPopulation: "475",
    VotingStationName: "ENQOLENI COMBINED PRIMARY SCHOOL",
    Address:
      "ENQOLENI, NEAR LUTHERAN CHURCH  ENQNDLANGUBO TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.7706",
    Longitude: "31.6547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13063",
    WardLookupId: "3793",
    VDNumber: "43413320",
    RegisteredPopulation: "1146",
    VotingStationName: "UFASIMBA PRIMARY SCHOOL",
    Address:
      "OFASIMBA, OVER MHLATHUZE RIVER  NDLANGUBO TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.7271",
    Longitude: "31.7103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13064",
    WardLookupId: "3793",
    VDNumber: "43416592",
    RegisteredPopulation: "728",
    VotingStationName: "MNGAMPONDO PRIMARY  SCHOOL",
    Address:
      "MNGAMPONDO AREA  NDLANGOBO TRADITIONAL AUTHORITY  UMLALAZI [ESHOWE]",
    Latitude: "-28.7169",
    Longitude: "31.6834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13065",
    WardLookupId: "3793",
    VDNumber: "43416626",
    RegisteredPopulation: "725",
    VotingStationName: "ENGONYAMENI APOSTOLIC CHURCH",
    Address:
      "KWATHUSI,NEAR MHLATHUZE RIVER  MZIMELA TRADITIONAL AUTHORITY  UMLALAZI [MTUNZINI]",
    Latitude: "-28.7415",
    Longitude: "31.7439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13066",
    WardLookupId: "3793",
    VDNumber: "43417885",
    RegisteredPopulation: "589",
    VotingStationName: "KWA-SIBONDA PRIMARY SCHOOL",
    Address: "KWANOMYACA RESERVE  NDLANGUBO TRADITIONAL AUTHPORITY  ESHOWE",
    Latitude: "-28.7399",
    Longitude: "31.6861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13067",
    WardLookupId: "3794",
    VDNumber: "43412048",
    RegisteredPopulation: "1065",
    VotingStationName: "HABENI COMBINED PRIMARY SCHOOL",
    Address:
      "MBANGATHUBANE, ALONG P230  BHEKESHOWE TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.8016",
    Longitude: "31.5566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13068",
    WardLookupId: "3794",
    VDNumber: "43412598",
    RegisteredPopulation: "409",
    VotingStationName: "QUEEN NANDI PRIMARY SCHOOL",
    Address: "MBANGATHUBANE AREA  BHEKESHOWE TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.8024",
    Longitude: "31.6059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13069",
    WardLookupId: "3794",
    VDNumber: "43412622",
    RegisteredPopulation: "1128",
    VotingStationName: "BHEKESHOWE COMMUNITY CENTRE",
    Address: "MAQHWAKAZI AREA  BHEKESHOWE TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.7878",
    Longitude: "31.6178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13070",
    WardLookupId: "3794",
    VDNumber: "43414781",
    RegisteredPopulation: "517",
    VotingStationName: "ELOMOYA COMBINED PRIMARY SCHOOL",
    Address:
      "KWELOMOYA, MGOSWANENI TURN OFF  BEKESHOWE TRADITIONA AUTHORITY  ESHOWE",
    Latitude: "-28.8194",
    Longitude: "31.5963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13071",
    WardLookupId: "3794",
    VDNumber: "43416615",
    RegisteredPopulation: "720",
    VotingStationName: "MAQHWAKZI PRIMARY SCHOOL",
    Address:
      "MAQHWAKAZI AREA  BHEKESHOWE TRADITIONAL AUTHORITY  UMLALAZI [ESHOWE]",
    Latitude: "-28.7866",
    Longitude: "31.6272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13072",
    WardLookupId: "3794",
    VDNumber: "43418662",
    RegisteredPopulation: "604",
    VotingStationName: "MATHEKU HIGH SCHOOL",
    Address: "HABENI AREA  BHEKESHOWE TRADITIONAL COUNCIL  ESHOWE",
    Latitude: "-28.7961",
    Longitude: "31.5638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13073",
    WardLookupId: "3795",
    VDNumber: "43411632",
    RegisteredPopulation: "639",
    VotingStationName: "NCEMANENI COMBINED PRIMARY SCHOOL",
    Address: "MPUNGOSE T/AREA  NCEMANENI RES  ESHOWE",
    Latitude: "-28.7969",
    Longitude: "31.4776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13074",
    WardLookupId: "3795",
    VDNumber: "43412543",
    RegisteredPopulation: "316",
    VotingStationName: "DUKANENI PRIMARY SCHOOL",
    Address: "NKWALENI FARMS  DUKANENI AREA  ESHOWE",
    Latitude: "-28.7302",
    Longitude: "31.5914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13075",
    WardLookupId: "3795",
    VDNumber: "43412587",
    RegisteredPopulation: "781",
    VotingStationName: "INYATHI PRIMARY SCHOOL",
    Address:
      "NGODINI, ALONG NKWALINI FARMS  BHEKESHOWE TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.7668",
    Longitude: "31.5899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13076",
    WardLookupId: "3795",
    VDNumber: "43412600",
    RegisteredPopulation: "893",
    VotingStationName: "NDONGANDE PRIMARY SCHOOL",
    Address: "MNQADAYI AREA  NDLANGUBO TRADITIONAL AUTHORITY  ESHOWE",
    Latitude: "-28.7476",
    Longitude: "31.6526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13077",
    WardLookupId: "3795",
    VDNumber: "43416558",
    RegisteredPopulation: "555",
    VotingStationName: "MANDAWE PRIMARY SCHOOL",
    Address:
      "MANDAWE ALONG P230  MPUNGOSE TRADITIONAL AUTHORITY  ESHOWE (UMLALAZI)",
    Latitude: "-28.807",
    Longitude: "31.5332",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13078",
    WardLookupId: "3796",
    VDNumber: "43413049",
    RegisteredPopulation: "265",
    VotingStationName: "ENCANYINI PRIMARY SCHOOL",
    Address: "YANGUYE AREA  BIYELA RESERVE  MELMOTH/YANGUYE TRIBAL AUTHORITY",
    Latitude: "-28.5272",
    Longitude: "31.5024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13079",
    WardLookupId: "3796",
    VDNumber: "43413050",
    RegisteredPopulation: "699",
    VotingStationName: "YANGUYE TRIBAL COURT",
    Address: "YANGUYE AREA  BIYELA RESERVE  MELMOTH/YANGUYE TRIBAL AUTHORITY",
    Latitude: "-28.4891",
    Longitude: "31.5115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13080",
    WardLookupId: "3796",
    VDNumber: "43413061",
    RegisteredPopulation: "324",
    VotingStationName: "SIZANANI PAYPOINT",
    Address: "YANGUYE AREA  MTHONJANENI  YANGUYE TRIBAL AUTHORITY",
    Latitude: "-28.4607",
    Longitude: "31.5611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13081",
    WardLookupId: "3796",
    VDNumber: "43416794",
    RegisteredPopulation: "513",
    VotingStationName: "MLINDAZWE LOWER PRIMARY SCHOOL",
    Address: "MAHEHE AREA  MELMOTH  MTHONJANENI [MELMOTH]",
    Latitude: "-28.522",
    Longitude: "31.5461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13082",
    WardLookupId: "3796",
    VDNumber: "43416806",
    RegisteredPopulation: "475",
    VotingStationName: "CANDOLO CRECHE",
    Address: "NEXT TO CANDOLO STORE   MELMOTH  YANGUYE TRIBAL AUTHORITY",
    Latitude: "-28.4604",
    Longitude: "31.5337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13083",
    WardLookupId: "3796",
    VDNumber: "43416817",
    RegisteredPopulation: "650",
    VotingStationName: "YANGUYE  HIGH SCHOOL",
    Address: "KWEYEZULU AREA  YANGUYE  YANGUYE TRIBAL AUTHORITY",
    Latitude: "-28.4854",
    Longitude: "31.5362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13084",
    WardLookupId: "3796",
    VDNumber: "43416929",
    RegisteredPopulation: "397",
    VotingStationName: "NUNGWINI PRIMARY SCHOOL",
    Address:
      "NUNGWINI ROAD  MELMOTH  OBUKA TRADITIONAL ADMINISTRATIVE COUNCIL-MELMOTH",
    Latitude: "-28.5664",
    Longitude: "31.5547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13085",
    WardLookupId: "3796",
    VDNumber: "43417379",
    RegisteredPopulation: "315",
    VotingStationName: "NQEKWANE PRIMARY SCHOOL",
    Address: "NQEKWANE AREA   MELMOTH  YANGUYE TRIBAL AUTHORITY",
    Latitude: "-28.4125",
    Longitude: "31.5593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13086",
    WardLookupId: "3796",
    VDNumber: "43418022",
    RegisteredPopulation: "354",
    VotingStationName: "NDABENHLE PRIMARY SCHOOL",
    Address: "MAWANDA ROAD / YANGUYE  MELMOTH  SAGUYE TRIBAL AUTHORITY",
    Latitude: "-28.486",
    Longitude: "31.5581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13087",
    WardLookupId: "3797",
    VDNumber: "43500024",
    RegisteredPopulation: "263",
    VotingStationName: "MELMOTH CHRISTIAN PRIVATE SCHOOL",
    Address: "2 MAGWAZA ROAD  MELMOTH  MELMOTH",
    Latitude: "-28.6034",
    Longitude: "31.3933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13088",
    WardLookupId: "3797",
    VDNumber: "43520026",
    RegisteredPopulation: "2299",
    VotingStationName: "THUBALETHU HALL",
    Address: "3 THUBALETHU PHASE 3  MELMOTH  MTHONJANENI [MELMOTH]",
    Latitude: "-28.5643",
    Longitude: "31.3924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13089",
    WardLookupId: "3797",
    VDNumber: "43520037",
    RegisteredPopulation: "468",
    VotingStationName: "DESTINY CARE AND GROW",
    Address: "17 WILFRED STREET  MELMOTH  MELMOTH",
    Latitude: "-28.5863",
    Longitude: "31.3937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13090",
    WardLookupId: "3797",
    VDNumber: "43520048",
    RegisteredPopulation: "606",
    VotingStationName: "THUBALETHU CRECHE",
    Address: "170 MANDONDO CRESENT  MELMOTH  MELMOTH",
    Latitude: "-28.568",
    Longitude: "31.3888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13091",
    WardLookupId: "3798",
    VDNumber: "43412981",
    RegisteredPopulation: "972",
    VotingStationName: "UBUQHAWEBETHU PRIMARY SCHOOL",
    Address:
      "NDEVU ROAD-NJOMELWANE VILLAGE  NJOMELWANE VILLAGE-OBUKA TAC  MELMOTH",
    Latitude: "-28.5599",
    Longitude: "31.5019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13092",
    WardLookupId: "3798",
    VDNumber: "43414859",
    RegisteredPopulation: "454",
    VotingStationName: "EMKHINDINI PRIMARY  SCHOOL",
    Address: "EMKHINDINI AREA  MELMOTH  NTEMBENI",
    Latitude: "-28.665",
    Longitude: "31.4847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13093",
    WardLookupId: "3798",
    VDNumber: "43417290",
    RegisteredPopulation: "350",
    VotingStationName: "SINQOBILE SECONDARY  SCHOOL",
    Address: "EMKHINDINI AREA  MELMOTH  NTEMBENI TRIBAL AUTHORITY",
    Latitude: "-28.6672",
    Longitude: "31.4806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13094",
    WardLookupId: "3798",
    VDNumber: "43418033",
    RegisteredPopulation: "487",
    VotingStationName: "EMAYENI CRECHE",
    Address: "MAYENI AREA NEXT TO R34  MELMOTH  NTEMBENI TRIBAL AUTHORITY",
    Latitude: "-28.6623",
    Longitude: "31.5027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13095",
    WardLookupId: "3798",
    VDNumber: "43504512",
    RegisteredPopulation: "338",
    VotingStationName: "FONTEIN TEMP STATION (TENT)",
    Address: "FONTEIN COMPOUND  MTHONJANENI  NTEMBENI TRIBAL COURT",
    Latitude: "-28.6544",
    Longitude: "31.431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13096",
    WardLookupId: "3798",
    VDNumber: "43520015",
    RegisteredPopulation: "1151",
    VotingStationName: "NTUTHUKO PRIMARY SCHOOL",
    Address: "12 REINHOLD STREET  MELMOTH  MELMOTH TOWN",
    Latitude: "-28.5937",
    Longitude: "31.4006",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13097",
    WardLookupId: "3799",
    VDNumber: "43500013",
    RegisteredPopulation: "847",
    VotingStationName: "KWANXUSA HIGH SCHOOL",
    Address: "KWAMAGWAZA MISSION  MELMOTH  NTEMBENI TRIBAL COURT",
    Latitude: "-28.6328",
    Longitude: "31.3297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13098",
    WardLookupId: "3799",
    VDNumber: "43500035",
    RegisteredPopulation: "668",
    VotingStationName: "MFULE MISSION SCHOOL",
    Address: "IMFULE MISSION/IMFULE RD  MELMOTH  MPUNGOSE TRIBAL AUTHORITY",
    Latitude: "-28.5331",
    Longitude: "31.3837",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13099",
    WardLookupId: "3799",
    VDNumber: "43500046",
    RegisteredPopulation: "188",
    VotingStationName: "MFULAZANE (TENT)",
    Address: "68 MAIN RD NEXT TO MONDI COMPOUND  MELMOTH R68  NTEMBENI",
    Latitude: "-28.5342",
    Longitude: "31.3281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13100",
    WardLookupId: "3799",
    VDNumber: "43500057",
    RegisteredPopulation: "403",
    VotingStationName: "MCAKWINI PRIMARY SCHOOL",
    Address: "0 MCAKWINI AREA  MELMOTH  NTEMBENI TRIBAL COURT",
    Latitude: "-28.6258",
    Longitude: "31.3029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13101",
    WardLookupId: "3799",
    VDNumber: "43500068",
    RegisteredPopulation: "397",
    VotingStationName: "KATAZO STORE",
    Address: "68 R68 NKANDLA ROAD EKHATHAZA  KATAZO  MELMOTH",
    Latitude: "-28.5108",
    Longitude: "31.2574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13102",
    WardLookupId: "3799",
    VDNumber: "43503892",
    RegisteredPopulation: "269",
    VotingStationName: "SIBONOKUHL CRECHE",
    Address: "THUNZINI AREA  MELMOTH  NTEMEBENI TC",
    Latitude: "-28.6634",
    Longitude: "31.2879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13103",
    WardLookupId: "3799",
    VDNumber: "43504523",
    RegisteredPopulation: "215",
    VotingStationName: "QALAKAHLE  CRECHE",
    Address: "THENGELA ROAD  MELMOTH  MAGWAZA MISSION",
    Latitude: "-28.6593",
    Longitude: "31.2889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13104",
    WardLookupId: "3800",
    VDNumber: "43411542",
    RegisteredPopulation: "954",
    VotingStationName: "MFANEFILE PRIMARY  SCHOOL",
    Address: "MFANEFILE AREA /KWANKOSANA  MELMOTH  NTEMBENI TRIBAL COURT",
    Latitude: "-28.6837",
    Longitude: "31.3593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13105",
    WardLookupId: "3800",
    VDNumber: "43411553",
    RegisteredPopulation: "488",
    VotingStationName: "UMGABHI COMBINED PRIMARY SCHOOL",
    Address: "UMGABHI AREA  MELMOTH /UMGABHI  NTEMBENI TRIBAL AUTHORITY",
    Latitude: "-28.7399",
    Longitude: "31.3795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13106",
    WardLookupId: "3800",
    VDNumber: "43416828",
    RegisteredPopulation: "462",
    VotingStationName: "NOMNDAYI  CRECHE",
    Address: "MFANEFILE AREA  MELMOTH  NTEMBENI TRIBAL COURT",
    Latitude: "-28.705",
    Longitude: "31.3727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13107",
    WardLookupId: "3800",
    VDNumber: "43417795",
    RegisteredPopulation: "251",
    VotingStationName: "MAZULE CRECHE",
    Address: "MAZULE AREA  NTEMBENI/ MTHONJANENI  NTEMBENI TRIBAL COURT",
    Latitude: "-28.6809",
    Longitude: "31.3877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13108",
    WardLookupId: "3800",
    VDNumber: "43418280",
    RegisteredPopulation: "510",
    VotingStationName: "MFANEFILE  PAY POINT",
    Address:
      "MFANEFILE AREA NEAR KWANKOSANA  MELMOTH  NTEMBENI TRIBAL AUTHORITY",
    Latitude: "-28.6702",
    Longitude: "31.3537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13109",
    WardLookupId: "3800",
    VDNumber: "43504545",
    RegisteredPopulation: "414",
    VotingStationName: "KWAMAHLOKOZA COMPOUND",
    Address: "MAGWAZA MAIN ROAD/OTHINI  MELMOTH  NTEMBENI TRIBAL COURT",
    Latitude: "-28.6353",
    Longitude: "31.3471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13110",
    WardLookupId: "3800",
    VDNumber: "43504556",
    RegisteredPopulation: "276",
    VotingStationName: "MANZAWAYO CRECHE",
    Address: "MANZAWAYO AREA  MFANEFILE   NTEMBENI TRIBAL COURT",
    Latitude: "-28.6704",
    Longitude: "31.3374",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13111",
    WardLookupId: "3801",
    VDNumber: "43411609",
    RegisteredPopulation: "363",
    VotingStationName: "MEHLAMASHA PRIMARY  SCHOOL",
    Address: "MEHLAMASHA AREA  MEHLAMASHA  NTEMBENI TRIBAL COURT",
    Latitude: "-28.7468",
    Longitude: "31.452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13112",
    WardLookupId: "3801",
    VDNumber: "43415658",
    RegisteredPopulation: "920",
    VotingStationName: "ENTEMBENI HIGH  SCHOOL",
    Address:
      "EKUTHULENI AREA  MELMOTH/EKUTHULENI RESERVE  NTEMBENI TRIBAL AUTHORITY",
    Latitude: "-28.7064",
    Longitude: "31.422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13113",
    WardLookupId: "3801",
    VDNumber: "43416840",
    RegisteredPopulation: "332",
    VotingStationName: "GQOKUBUKHOSI SECONDARY SCHOOL",
    Address: "MATSHANSUNDU AREA  MELMOTH  NTEMBENI TRIBAL COURT",
    Latitude: "-28.7202",
    Longitude: "31.4843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13114",
    WardLookupId: "3801",
    VDNumber: "43417289",
    RegisteredPopulation: "322",
    VotingStationName: "EKUTHULENI HALL",
    Address:
      "EKUTHULENI MAIN ROAD  EKUTHULENI/MELMOTH  NTEMBENI TRIBAL AUTHORITY",
    Latitude: "-28.7241",
    Longitude: "31.4488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13115",
    WardLookupId: "3801",
    VDNumber: "43417368",
    RegisteredPopulation: "203",
    VotingStationName: "NSENGENI CRECHE",
    Address: "EKUTHULENI AREA  EKUTHULENI /MELMOTH  NTEMBENI TRIBAL AUTHORITY",
    Latitude: "-28.6819",
    Longitude: "31.4351",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13116",
    WardLookupId: "3801",
    VDNumber: "43418044",
    RegisteredPopulation: "593",
    VotingStationName: "NTANDOKAZI LOWER SCHOOL",
    Address: "DUBENI AREA  MELMOTH  NTEMBENI TRIBAL",
    Latitude: "-28.6754",
    Longitude: "31.44",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13117",
    WardLookupId: "3801",
    VDNumber: "43418279",
    RegisteredPopulation: "351",
    VotingStationName: "NOGAJUKA PRIMARY SCHOOL",
    Address: "EKUTHULENI AREA  MELMOTH  NTEMBENI TRIBAL AUTHORITY",
    Latitude: "-28.7094",
    Longitude: "31.4335",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13118",
    WardLookupId: "3802",
    VDNumber: "43412925",
    RegisteredPopulation: "430",
    VotingStationName: "ST PAULS COMBINED PRIMARY SCHOOL",
    Address: "KWANOMPONJWANA AREA  MELMOTH  OBUKA TRIBAL AUTHORITY",
    Latitude: "-28.6952",
    Longitude: "31.5738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13119",
    WardLookupId: "3802",
    VDNumber: "43412936",
    RegisteredPopulation: "617",
    VotingStationName: "KWAGCONCO COMBINED PRIMARY  SCHOOL",
    Address: "KWAGCONGCO AREA  MELMOTH  NTEMBENI TRIBAL AUTHORITY",
    Latitude: "-28.6657",
    Longitude: "31.5127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13120",
    WardLookupId: "3802",
    VDNumber: "43412947",
    RegisteredPopulation: "929",
    VotingStationName: "SIZANOTHO PRIMARY SCHOOL",
    Address: "HLABATHINI AREA  MELMOTH  NTEMBENI TRIBAL AUTHORITY",
    Latitude: "-28.6389",
    Longitude: "31.5353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13121",
    WardLookupId: "3802",
    VDNumber: "43415041",
    RegisteredPopulation: "707",
    VotingStationName: "SITHEKU SECONDARY SCHOOL",
    Address: "NDABAZENSANGU AREA  NKWENKWE   NTEMBENI TRIBAL AUTHORITY",
    Latitude: "-28.6631",
    Longitude: "31.5342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13122",
    WardLookupId: "3802",
    VDNumber: "43415052",
    RegisteredPopulation: "856",
    VotingStationName: "MAKHASANENI PRIMARY SCHOOL",
    Address: "MAKHASANENI AREA  MELMOTH  NTEMBENI TRIBAL AUTHORITY",
    Latitude: "-28.6117",
    Longitude: "31.498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13123",
    WardLookupId: "3802",
    VDNumber: "43417302",
    RegisteredPopulation: "500",
    VotingStationName: "THUTHUKANI CRECHE",
    Address: "OHAWULE AREA  MELMOTH  NTEMBENI TRIBAL AUTHORITY",
    Latitude: "-28.69",
    Longitude: "31.5406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13124",
    WardLookupId: "3803",
    VDNumber: "43411610",
    RegisteredPopulation: "865",
    VotingStationName: "MXOSHENI COMBINED SCHOOL",
    Address: "DUBENI AREA  MELMOTH  NTEMBENI TRIBAL AUTHORITY",
    Latitude: "-28.6903",
    Longitude: "31.4616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13125",
    WardLookupId: "3803",
    VDNumber: "43411621",
    RegisteredPopulation: "1183",
    VotingStationName: "ENTEMBENI TRIBAL COURT",
    Address: "R 66 MAIN ROAD NDUNDULU AREA  MELMOTH  NTEMBENI",
    Latitude: "-28.6796",
    Longitude: "31.511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13126",
    WardLookupId: "3803",
    VDNumber: "43412891",
    RegisteredPopulation: "787",
    VotingStationName: "SIYAVUNA PRIMARY SCHOOL",
    Address: "NKISA AREA  MELMOTH/NKWALINI  NTEMBENI TRIBAL AUTHORITY",
    Latitude: "-28.7148",
    Longitude: "31.5079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13127",
    WardLookupId: "3803",
    VDNumber: "43416839",
    RegisteredPopulation: "359",
    VotingStationName: "BEDLANE LOWER PRIMARY SCHOOL",
    Address: "BEDLANE AREA  MELMOTH/NKWALINI AREA  NTEMBENI TRIBAL AUTHORITY",
    Latitude: "-28.7085",
    Longitude: "31.5462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13128",
    WardLookupId: "3803",
    VDNumber: "43418291",
    RegisteredPopulation: "305",
    VotingStationName: "NDUNDULU PRIMARY SCHOOL",
    Address: "R66 MAIN RD NDUNDULU AREA  MELMOTH  NTEMBENI TRIBAL AUTHORITY",
    Latitude: "-28.6895",
    Longitude: "31.5147",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13129",
    WardLookupId: "3804",
    VDNumber: "43412903",
    RegisteredPopulation: "384",
    VotingStationName: "QONDINDLELA SECONDARY SCHOOL",
    Address:
      "R34 NKWALINI ROAD-EHLANZENI AREA  EHLANZENI AREA-OBUKA TAC  EMPANGENI",
    Latitude: "-28.6785",
    Longitude: "31.6456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13130",
    WardLookupId: "3804",
    VDNumber: "43412914",
    RegisteredPopulation: "1059",
    VotingStationName: "EMASANGWENI LOWER PRIMARY SCHOOL",
    Address:
      "P571 MASANGWENI-MASANGWENI AREA  MASANGWENI-OBUKA TAC  EMPANGENI-OBUKA TRADITIONAL ADMIN COUNCIL",
    Latitude: "-28.6787",
    Longitude: "31.6285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13131",
    WardLookupId: "3804",
    VDNumber: "43412969",
    RegisteredPopulation: "580",
    VotingStationName: "ENHLUBE PRIMARY SCHOOL",
    Address:
      "P534 NOMPONJWANA ROAD-NOMPONJWANA  NOMPONJWANA AREA-OBUKA TAC  MELMOTH",
    Latitude: "-28.6448",
    Longitude: "31.5785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13132",
    WardLookupId: "3804",
    VDNumber: "43416064",
    RegisteredPopulation: "633",
    VotingStationName: "BHONKOLO CRECHE",
    Address:
      "BHONKOLO AREA  OBUKA TRADITIONAL ADMINISTRATION COUNCIL  UBUKA TRIBAL AUTHORITY",
    Latitude: "-28.6604",
    Longitude: "31.5493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13133",
    WardLookupId: "3804",
    VDNumber: "43416873",
    RegisteredPopulation: "406",
    VotingStationName: "STABO PRIMARY SCHOOL",
    Address:
      "D571 RD-MPEMVU AREA  MPEMVU AREA-OBUKA TAC  MELMOTH-OBUKA TRADITIONAL ADMIN COUNCIL",
    Latitude: "-28.6612",
    Longitude: "31.6113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13134",
    WardLookupId: "3804",
    VDNumber: "43418213",
    RegisteredPopulation: "418",
    VotingStationName: "NKWENKWE ROMAN CHURCH(NEXT TO BOY BOY STORE)",
    Address:
      "D1577 RD-NKWENKWE AREA  NKWENKWE AREA-OBUKA TAC  MELMOTH-OBUKA TRADITIONAL ADMIN COUNCIL",
    Latitude: "-28.6794",
    Longitude: "31.5797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13135",
    WardLookupId: "3804",
    VDNumber: "43418224",
    RegisteredPopulation: "481",
    VotingStationName: "MAJAJI COMBINED PRIMARY SCHOOL",
    Address:
      "R34 OLD MAIN RD-QUNEBE AREA  QUNENEBE-EMASANGWENI-OBUKA TAC  EMPANGENI",
    Latitude: "-28.6915",
    Longitude: "31.6432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13136",
    WardLookupId: "3805",
    VDNumber: "43412958",
    RegisteredPopulation: "369",
    VotingStationName: "PHUMOSIZINI HIGH SCHOOL",
    Address:
      "KWABHADAZA AREA  KWABHADAZA /MELMOTH  OBUKA TRADITIONAL ADMINISTRATIVE COUNCIL-MELMOTH",
    Latitude: "-28.5832",
    Longitude: "31.5401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13137",
    WardLookupId: "3805",
    VDNumber: "43412970",
    RegisteredPopulation: "481",
    VotingStationName: "KWELENTABA LOWER PRIMARY SCHOOL",
    Address:
      "D1579 QOMNTABA ROAD-QOMNTABA AREA  QOMNTABA AREA-OBUKA TRIBAL AUTHORITY  MELMOTH",
    Latitude: "-28.6359",
    Longitude: "31.6409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13138",
    WardLookupId: "3805",
    VDNumber: "43413005",
    RegisteredPopulation: "432",
    VotingStationName: "NGQUNGQU COMBINED PRIMARY SCHOOL",
    Address:
      "D253 MAWANDA ROAD-NGQUNGQU AREA  NGQUNGQU AREA-OBUKA TRADITIONAL ADMIN COUNCIL  MELMOTH",
    Latitude: "-28.5656",
    Longitude: "31.5974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13139",
    WardLookupId: "3805",
    VDNumber: "43413016",
    RegisteredPopulation: "634",
    VotingStationName: "MABHENSA PRIMARY SCHOOL",
    Address:
      "D877 MABHENSA AREA  MABHENSA AREA-OBUKA TRIBAL AUTHORITY  EMPANGENI",
    Latitude: "-28.5709",
    Longitude: "31.6657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13140",
    WardLookupId: "3805",
    VDNumber: "43415063",
    RegisteredPopulation: "478",
    VotingStationName: "GAMU CRECHE",
    Address: "EMPANGENI  OBUKA  OBUKA",
    Latitude: "-28.5947",
    Longitude: "31.6555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13141",
    WardLookupId: "3805",
    VDNumber: "43415928",
    RegisteredPopulation: "442",
    VotingStationName: "SIVANADA CRECHE",
    Address:
      "QOMNTABA ROAD-NCEMANE AREA  NCEMANE AREA-OBUKA TAC  EMPANGENI-OBUKA TRADITIONAL ADMIN COUNCIL",
    Latitude: "-28.6165",
    Longitude: "31.6725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13142",
    WardLookupId: "3805",
    VDNumber: "43416930",
    RegisteredPopulation: "726",
    VotingStationName: "SITHINTA PRIMARY SCHOOL",
    Address:
      "D534 MFULE ROAD-ISITHINTA AREA  ISITHINTA VILLAGE-OBUKA TRADITIONAL ADMIN COUNCIL  MELMOTH",
    Latitude: "-28.6031",
    Longitude: "31.6097",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13143",
    WardLookupId: "3805",
    VDNumber: "43418202",
    RegisteredPopulation: "258",
    VotingStationName: "OGQABHIYENI CRECHE",
    Address: "MFULE ROAD-OGQABHIYENI AREA  OGQABHIYENI AREA-OBUKA TAC  MELMOTH",
    Latitude: "-28.5745",
    Longitude: "31.6219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13144",
    WardLookupId: "3806",
    VDNumber: "43412992",
    RegisteredPopulation: "767",
    VotingStationName: "ESIQHOMANENI PRIMARY SCHOOL",
    Address:
      "D534 UPPER NSELENI AREA  UPPER NSELENI AREA-OBUKA TRADITIONAL ADMIN COUNCIL  MELMOTH",
    Latitude: "-28.5593",
    Longitude: "31.6372",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13145",
    WardLookupId: "3806",
    VDNumber: "43413038",
    RegisteredPopulation: "721",
    VotingStationName: "GOBIHLAHLA COMBINED PRIMARY SCHOOL",
    Address: "P701 ROAD-GOBIHLAHLA AREA  GOBIHLAHLA AREA-OBUKA TAC  EMPANGENI",
    Latitude: "-28.5172",
    Longitude: "31.6556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13146",
    WardLookupId: "3806",
    VDNumber: "43416941",
    RegisteredPopulation: "808",
    VotingStationName: "HAWAII COMMUNITY HALL",
    Address:
      "MAIN ROAD  ESIDAKENI RESERVE, EMPANGENI, 3880  KWABIYELA TRIBAL AUTHORITY",
    Latitude: "-28.5662",
    Longitude: "31.6853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13147",
    WardLookupId: "3806",
    VDNumber: "43416952",
    RegisteredPopulation: "487",
    VotingStationName: "OBUKA TRADITIONAL ADMINISTRATIVE COUNCIL",
    Address:
      "D1579 KWAMBIZA AREA  KWAMBIZA AREA-OBUKA TRADITIONAL ADMIN COUNCIL  EMPANGENI",
    Latitude: "-28.5623",
    Longitude: "31.6544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13148",
    WardLookupId: "3806",
    VDNumber: "43418190",
    RegisteredPopulation: "469",
    VotingStationName: "MABHULA CRECHE",
    Address:
      "MKHANDLWINI-EZIGQIBANENI  EZIGQIBANENI-OBUKA TAC  EMPANGENI-OBUKA TRADITIONAL ADMINISTRATIVE COUNCIL",
    Latitude: "-28.5385",
    Longitude: "31.6869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13149",
    WardLookupId: "3807",
    VDNumber: "43413027",
    RegisteredPopulation: "742",
    VotingStationName: "NKOSITHANDILE HIGH SCHOOL",
    Address:
      "P701 ROAD-MKHANDLWINI AREA  MKHANDLWINI AREA-OBUKA TAC  EMPANGENI-OBUKA TRADITIONAL ADMIN COUNCIL",
    Latitude: "-28.5159",
    Longitude: "31.6854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13150",
    WardLookupId: "3807",
    VDNumber: "43413072",
    RegisteredPopulation: "399",
    VotingStationName: "LUMBI CRECHE",
    Address:
      "LUMBI ROAD-LUMBI AREA  LUMBI AREA-OBUKA TAC  MELMOTH-OBUKA ADMINISTRATIVE COUNCIL",
    Latitude: "-28.4405",
    Longitude: "31.6029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13151",
    WardLookupId: "3807",
    VDNumber: "43413083",
    RegisteredPopulation: "780",
    VotingStationName: "OBUKA JUNIOR SECONDARY SCHOOL",
    Address: "D253 MAWANDA ROAD-MAWANDA AREA  MAWANDA AREA-OBUKA TAC  MELMOTH",
    Latitude: "-28.4864",
    Longitude: "31.6039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13152",
    WardLookupId: "3807",
    VDNumber: "43415276",
    RegisteredPopulation: "280",
    VotingStationName: "OVICENI PRIMARY SCHOOL",
    Address:
      "MAWANDA ROAD  OVICENI AREA  MELMOTH-OBUKA TRADITIONAL ADMINISTRATIVE COUNCIL",
    Latitude: "-28.5212",
    Longitude: "31.607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13153",
    WardLookupId: "3807",
    VDNumber: "43416963",
    RegisteredPopulation: "186",
    VotingStationName: "SHAYAMOYA TUCK SHOP (TENT)",
    Address:
      "GOBIHLAHLA ROAD-SHAYAMOYA AREA  SHAYAMOYA AREA-OBUKA TAC  EMPANGENI",
    Latitude: "-28.5082",
    Longitude: "31.672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13154",
    WardLookupId: "3807",
    VDNumber: "43416974",
    RegisteredPopulation: "286",
    VotingStationName: "NTOMBOKAZI LOWER PRIMARY SCHOOL",
    Address:
      "NTOMBOKAZI VILLAGE  NTOMBOKAZI VILLAGE NEXT TO DEBE-OBUKA TAC  MELMOTH",
    Latitude: "-28.4561",
    Longitude: "31.6463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13155",
    WardLookupId: "3807",
    VDNumber: "43417908",
    RegisteredPopulation: "395",
    VotingStationName: "MAPHUKANQOLA PRIMARY SCHOOL",
    Address:
      "D253 MAWANDA ROAD  MAPHUKANQOLA AREA-OBUKA TAC  MELMOTH-OBUKA TRADITIONAL ADMINISTRATIVE COUNCIL",
    Latitude: "-28.5061",
    Longitude: "31.6267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13156",
    WardLookupId: "3808",
    VDNumber: "43413094",
    RegisteredPopulation: "1102",
    VotingStationName: "MANZAMHLOPHE HIGH SCHOOL",
    Address:
      "P701 DEBE ROAD-MADUMA VILLAGE  MADUMA VILLAGE-OBUKA TRADITIONAL ADMIN COUNCIL  EMPANGENI",
    Latitude: "-28.4837",
    Longitude: "31.7099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13157",
    WardLookupId: "3808",
    VDNumber: "43413106",
    RegisteredPopulation: "1100",
    VotingStationName: "DEBE COMMUNITY HALL",
    Address: "DEBE ROAD  EDEBE RESERVE, EMPANGENI, 3880  KWABIYELA",
    Latitude: "-28.4581",
    Longitude: "31.6803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13158",
    WardLookupId: "3808",
    VDNumber: "43413117",
    RegisteredPopulation: "494",
    VotingStationName: "SANGOYANA COMBINED PRIMARY SCHOOL",
    Address:
      "D312 SANGOYAN ROAD-SANGOYANA  SANGOYANA RESERVE-SOMOPHO TRADITIONAL ADMIN COUNCIL  EMPANGENI",
    Latitude: "-28.4589",
    Longitude: "31.7716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13159",
    WardLookupId: "3808",
    VDNumber: "43418189",
    RegisteredPopulation: "440",
    VotingStationName: "EZWENILETHU HIGH SCHOOL",
    Address:
      "D312 ROAD-SANGOYANA AREA  SANGOYANA AREA-SOMOPHO TAC  EMPANGENI-SOMOPHO TRADITIONAL ADMIN COUNCIL",
    Latitude: "-28.4725",
    Longitude: "31.761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13160",
    WardLookupId: "3809",
    VDNumber: "43410732",
    RegisteredPopulation: "813",
    VotingStationName: "CHWEZI TRADITIONAL COURT",
    Address: "EZIBHEMBENI  CHWEZI  NKANDLA",
    Latitude: "-28.4613",
    Longitude: "31.009",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13161",
    WardLookupId: "3809",
    VDNumber: "43410754",
    RegisteredPopulation: "364",
    VotingStationName: "CHWEZI PRIMARY SCHOOL",
    Address: "NOMAHAYE VILLAGE  NOMAHAYE  NKANDLA",
    Latitude: "-28.4654",
    Longitude: "30.9743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13162",
    WardLookupId: "3809",
    VDNumber: "43410912",
    RegisteredPopulation: "377",
    VotingStationName: "AMATSHENSIKAZI PRIMARY SCHOOL",
    Address: "PHAMBANA VILLAGE  AMATSHENSIKAZI  NKANDLA",
    Latitude: "-28.4909",
    Longitude: "31.0514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13163",
    WardLookupId: "3809",
    VDNumber: "43410923",
    RegisteredPopulation: "676",
    VotingStationName: "ITHALA SECONDARY SCHOOL",
    Address: "MSHISANDLU VILLAGE  MSHISANDLU  NKANDLA",
    Latitude: "-28.5166",
    Longitude: "31.072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13164",
    WardLookupId: "3809",
    VDNumber: "43411092",
    RegisteredPopulation: "708",
    VotingStationName: "NKUNGUMATHE PRIMARY SCHOOL",
    Address: "NKUNGUMATHE  CHWEZI RESERVE  CHWEZI TRIBAL AUTHORITY",
    Latitude: "-28.5207",
    Longitude: "31.0892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13165",
    WardLookupId: "3809",
    VDNumber: "43416277",
    RegisteredPopulation: "399",
    VotingStationName: "INDATSHE PRIMARY SCHOOL",
    Address: "GOSWENI VILLAGE  INDATSHE AREA  NKANDLA",
    Latitude: "-28.4937",
    Longitude: "31.0256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13166",
    WardLookupId: "3809",
    VDNumber: "43417638",
    RegisteredPopulation: "707",
    VotingStationName: "GABANGENKOSI PRIMARY SCHOOL",
    Address: "CHWEZI VILLAGE  CHWEZI AREA  CHWEZI TRADITIONAL CENTRE",
    Latitude: "-28.4443",
    Longitude: "30.9906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13167",
    WardLookupId: "3809",
    VDNumber: "43417807",
    RegisteredPopulation: "450",
    VotingStationName: "SIBUSISILE PRIMARY SCHOOL",
    Address:
      "MANZAMNYAMA VILLAGE  MANZAMNYAMA  MPUNGOSE NORTH TRADITIONAL COUNCIL",
    Latitude: "-28.543",
    Longitude: "31.1043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13168",
    WardLookupId: "3809",
    VDNumber: "43419281",
    RegisteredPopulation: "270",
    VotingStationName: "SISHISHILI PRIMARY SCHOOL",
    Address: "OHLELO VILLAGE  OHLELO  CHWEZI T/C",
    Latitude: "-28.477",
    Longitude: "31.0612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13169",
    WardLookupId: "3810",
    VDNumber: "43410743",
    RegisteredPopulation: "958",
    VotingStationName: "MPOTHOLO PRIMARY SCHOOL",
    Address: "NKANDLA  MPOTHOLO  NGANGEZWE TRIBAL AUTHORITY",
    Latitude: "-28.4573",
    Longitude: "30.8604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13170",
    WardLookupId: "3810",
    VDNumber: "43410776",
    RegisteredPopulation: "453",
    VotingStationName: "CUNGCWANA PRIMARY SCHOOL",
    Address: "NKANDLA  BHACANE  KHABELA TRIBAL AUTHORITY",
    Latitude: "-28.5021",
    Longitude: "30.9095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13171",
    WardLookupId: "3810",
    VDNumber: "43410787",
    RegisteredPopulation: "1185",
    VotingStationName: "BHUQWINI SECONDARY SCHOOL",
    Address: "QHUME  MABHUQWINI  CUNU TRIBAL AUTHORITY",
    Latitude: "-28.5196",
    Longitude: "30.8361",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13172",
    WardLookupId: "3810",
    VDNumber: "43410811",
    RegisteredPopulation: "377",
    VotingStationName: "SIBAHLENGEMVELO PRIMARY  SCHOOL",
    Address: "MAGINDINI  MABHUQWINI  KHABELA TRIBAL AUTHORITY",
    Latitude: "-28.5226",
    Longitude: "30.8616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13173",
    WardLookupId: "3810",
    VDNumber: "43416749",
    RegisteredPopulation: "536",
    VotingStationName: "EKUPHIWENI SECONDARY SCHOOL",
    Address: "MONA AREA  NKANDLA [NKANDLA]",
    Latitude: "-28.5681",
    Longitude: "30.8398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13174",
    WardLookupId: "3811",
    VDNumber: "43410710",
    RegisteredPopulation: "897",
    VotingStationName: "FORT LOUIS PRIMARY SCHOOL",
    Address: "WOZAWOZA AREA  WOZAWOZA  NGNGEZWE TRADITIONAL CENTRE",
    Latitude: "-28.4204",
    Longitude: "30.9703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13175",
    WardLookupId: "3811",
    VDNumber: "43410721",
    RegisteredPopulation: "669",
    VotingStationName: "NONGAMLANA SECONDARY SCHOOL",
    Address: "HLEKEZELA AREA  NGANGEZWE TRADITIONAL CENTRE/NKANDLA",
    Latitude: "-28.4264",
    Longitude: "30.9203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13176",
    WardLookupId: "3811",
    VDNumber: "43410765",
    RegisteredPopulation: "485",
    VotingStationName: "OHLAHLA PRIMARY SCHOOL",
    Address: "VIMBIMBOBO AREA  OHLAHLA  CUNU TRADITIONAL CENTRE/ NKANDLA",
    Latitude: "-28.4701",
    Longitude: "30.9422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13177",
    WardLookupId: "3811",
    VDNumber: "43410800",
    RegisteredPopulation: "541",
    VotingStationName: "KHABELA TRADITIONAL COURT",
    Address:
      "NGAPHAKATHI AREA  NGAPHAKATHI  NKANDLA/ KHABELA TARDITIONAL CENTRE",
    Latitude: "-28.5367",
    Longitude: "30.9074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13178",
    WardLookupId: "3811",
    VDNumber: "43410901",
    RegisteredPopulation: "725",
    VotingStationName: "MBATSHAZWA SECONDARY SCHOOL",
    Address: "ETHALENI AREA  ETHALENI  NKANDLA/ KHABELA TRADITIONAL CENTRE",
    Latitude: "-28.496",
    Longitude: "30.9767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13179",
    WardLookupId: "3811",
    VDNumber: "43416750",
    RegisteredPopulation: "423",
    VotingStationName: "LUSHABA SECONDARY SCHOOL",
    Address: "SIDASHI AREA  SIDASHI   NKANDLA/ KHABELA TRADITIONAL CENTRE",
    Latitude: "-28.5393",
    Longitude: "30.9686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13180",
    WardLookupId: "3812",
    VDNumber: "43411070",
    RegisteredPopulation: "1025",
    VotingStationName: "BAMBISANANI CENTRE",
    Address:
      "ETHALANENI AREA  ETHALANENI  NKANDLA/EKUKHANYENI TRADITIONAL CENTRE",
    Latitude: "-28.5544",
    Longitude: "31.0442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13181",
    WardLookupId: "3812",
    VDNumber: "43411081",
    RegisteredPopulation: "1374",
    VotingStationName: "EKUKHANYENI TRADITIONAL COURT",
    Address:
      "EKUKHANYENI AREA  EKUKHANYENI  NKANDLA/EKUKHANYENI TRADITIONAL CENTRE",
    Latitude: "-28.5423",
    Longitude: "31.0114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13182",
    WardLookupId: "3812",
    VDNumber: "43415984",
    RegisteredPopulation: "795",
    VotingStationName: "NGWEGWENI COMMUNITY HALL",
    Address:
      "NGWEGWENI AREA  NGWEGWENI  NKANDLA/EKUKHANYENI TRADITIONAL CENTRE",
    Latitude: "-28.576",
    Longitude: "31.0694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13183",
    WardLookupId: "3812",
    VDNumber: "43417278",
    RegisteredPopulation: "213",
    VotingStationName: "MTHANDANHLE GENERAL DEALER",
    Address: "MTHANDANHLE AREA  MTHANDANHLE  EKUKHANYENI TRADITIONAL CENTRE",
    Latitude: "-28.5664",
    Longitude: "30.9648",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13184",
    WardLookupId: "3812",
    VDNumber: "43419214",
    RegisteredPopulation: "436",
    VotingStationName: "MBIZWE SECONDARY SCHOOL",
    Address: "N/A MBIZWE AREA  EKUKHANYENI TRADITIONAL CENTRE  NKANDLA RURAL",
    Latitude: "-28.5474",
    Longitude: "31.052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13185",
    WardLookupId: "3813",
    VDNumber: "43411115",
    RegisteredPopulation: "639",
    VotingStationName: "NQUNDU PRIMARY SCHOOL",
    Address:
      "NQUNDU AREA  KWANQUNDU  NKANDLA/MPUNGOSE NORTH TRADITIONAL CENTRE",
    Latitude: "-28.5592",
    Longitude: "31.1105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13186",
    WardLookupId: "3813",
    VDNumber: "43411126",
    RegisteredPopulation: "698",
    VotingStationName: "MPUNGOSE TRADITIONAL COURT (NORTH)",
    Address:
      "ESIGCALABENI AREA  ESIGCALABENI  NKANDLA/MPUNGOSE NORTH TRADITIONAL CENTRE",
    Latitude: "-28.565",
    Longitude: "31.1402",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13187",
    WardLookupId: "3813",
    VDNumber: "43411137",
    RegisteredPopulation: "1655",
    VotingStationName: "NKANDLA MULTI-PURPOSE CENTRE",
    Address: "5 MAIN ROAD  NKANDLA  NKANDLA",
    Latitude: "-28.6244",
    Longitude: "31.0921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13188",
    WardLookupId: "3813",
    VDNumber: "43414950",
    RegisteredPopulation: "1030",
    VotingStationName: "PHALANE PRIMARY SCHOOL",
    Address: "CHOLWANE  MPUNGOSE TRIBAL AUTHORITY",
    Latitude: "-28.5846",
    Longitude: "31.1055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13189",
    WardLookupId: "3813",
    VDNumber: "43419225",
    RegisteredPopulation: "352",
    VotingStationName: "SIGANANDA PRIMARY SCHOOL",
    Address: "318 MAREE  SINQOBILE LOCATION  NKANDLA",
    Latitude: "-28.6281",
    Longitude: "31.0886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13190",
    WardLookupId: "3813",
    VDNumber: "43419236",
    RegisteredPopulation: "350",
    VotingStationName: "ZUNGENI PRIMARY SCHOOL",
    Address: "ZUNGENI AREA  ZUNGENI   NKANDLA/MAHLAYIZENI TRADITIONAL CENTRE",
    Latitude: "-28.6032",
    Longitude: "31.1461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13191",
    WardLookupId: "3814",
    VDNumber: "43411148",
    RegisteredPopulation: "1000",
    VotingStationName: "MAHLAYIZENI TRADITIONAL COURT",
    Address:
      "MABENGELA AREA  MABENGELA  NKANDLA/MAHALYIZENI TRADITIONAL CENTRE",
    Latitude: "-28.6394",
    Longitude: "31.1658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13192",
    WardLookupId: "3814",
    VDNumber: "43411193",
    RegisteredPopulation: "872",
    VotingStationName: "MATHIYA PRIMARY SCHOOL",
    Address: "MATHIYA AREA  MATHIYA  NKANDLA/CHUBE TRADITIONAL CENTRE",
    Latitude: "-28.6479",
    Longitude: "31.079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13193",
    WardLookupId: "3814",
    VDNumber: "43411216",
    RegisteredPopulation: "481",
    VotingStationName: "VUMANHLAMVU TENT",
    Address:
      "VUMANHLAMVU AREA  VUMANHLAMVU NEXT TO ANGLICAN CHURCH  NKANDLA/MPUNGOSE SOUTH T/C",
    Latitude: "-28.6869",
    Longitude: "31.1146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13194",
    WardLookupId: "3814",
    VDNumber: "43411238",
    RegisteredPopulation: "807",
    VotingStationName: "MVAYIZA COMBINED PRIMARY SCHOOL",
    Address:
      "MVAYIZA SCHOOL  NKANDLA  NKANDLA/MPUNGOSE SOUTH TRADITIONAL CENTRE",
    Latitude: "-28.6897",
    Longitude: "31.203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13195",
    WardLookupId: "3814",
    VDNumber: "43415287",
    RegisteredPopulation: "642",
    VotingStationName: "SIBHAKABHAKA PRIMARY SCHOOL",
    Address:
      "MANDABA AREA  SIBHAKABHAKA NEXT TO TRADITIONAL COURT  NKANDLA/MPUNGOSE SOUTH TRADITIONAL CENTRE",
    Latitude: "-28.6772",
    Longitude: "31.2225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13196",
    WardLookupId: "3814",
    VDNumber: "43419179",
    RegisteredPopulation: "714",
    VotingStationName: "NDWENI COMMUNITY HALL",
    Address:
      "NDWENI AREA  MAHLAYIZENI TRADITIONAL CENTRE  NKANDLA/MAHLAYIZENI TRADITIONAL CENTRE",
    Latitude: "-28.6478",
    Longitude: "31.1101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13197",
    WardLookupId: "3815",
    VDNumber: "43411182",
    RegisteredPopulation: "605",
    VotingStationName: "CHUBE TRADITIONAL  COURT",
    Address: "EZINTININI AREA  EZINTININI  NKANDLA/CHUBE TRADITIONAL CENTRE",
    Latitude: "-28.6944",
    Longitude: "31.1077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13198",
    WardLookupId: "3815",
    VDNumber: "43411227",
    RegisteredPopulation: "395",
    VotingStationName: "MAKHANYEZI PRIMARY SCHOOL",
    Address: "MAPHOTHWE AREA  MAPHOTHWE  NKANDLA/MANGIDINI TRADITIONAL CENTRE",
    Latitude: "-28.6958",
    Longitude: "31.1573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13199",
    WardLookupId: "3815",
    VDNumber: "43411249",
    RegisteredPopulation: "806",
    VotingStationName: "BHILIBANA SECONDARY SCHOOL",
    Address: "EMAHLATHINI AREA  EMAHLATHINI  NKANDLA/MANGIDINI T/C",
    Latitude: "-28.7561",
    Longitude: "31.1775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13200",
    WardLookupId: "3815",
    VDNumber: "43411519",
    RegisteredPopulation: "950",
    VotingStationName: "ENYAWOSHANE PRIMARY SCHOOL",
    Address: "VEZIMANZI AREA  VEZIMANZI  NKANDLA/MANGIDINI T/C",
    Latitude: "-28.7746",
    Longitude: "31.2526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13201",
    WardLookupId: "3815",
    VDNumber: "43411531",
    RegisteredPopulation: "690",
    VotingStationName: "FANGELAKHE PRIMARY SCHOOL",
    Address: "MAPHIHLI AREA  MAPHIHLI  NKANDLA/MANGIDINI T/C",
    Latitude: "-28.7779",
    Longitude: "31.3116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13202",
    WardLookupId: "3815",
    VDNumber: "43415298",
    RegisteredPopulation: "406",
    VotingStationName: "ENHLABABO PRIMARY SCHOOL",
    Address: "ENHLABABO AREA  ENHLABABO  NKANDLA/MANGIDIDNI T/C",
    Latitude: "-28.7387",
    Longitude: "31.2577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13203",
    WardLookupId: "3815",
    VDNumber: "43417942",
    RegisteredPopulation: "175",
    VotingStationName: "MAQHASHIYA PRIMARY SCHOOL",
    Address: "EHLABATHINI AREA  EHLABATHINI  NKANDLA/MANGIDINI T/C",
    Latitude: "-28.7166",
    Longitude: "31.273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13204",
    WardLookupId: "3815",
    VDNumber: "44540029",
    RegisteredPopulation: "176",
    VotingStationName: "SIZAMOKUHLE CRECHE",
    Address: "HLWEHLWE  MANGIDINI",
    Latitude: "-28.8036",
    Longitude: "31.2442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13205",
    WardLookupId: "3816",
    VDNumber: "43410822",
    RegisteredPopulation: "349",
    VotingStationName: "ENTSHIZA PRIMARY SCHOOL",
    Address: "ENTSHIZA AREA  ENTSHIZA  NKANDLA/VULINDLELA T/C",
    Latitude: "-28.6293",
    Longitude: "30.7663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13206",
    WardLookupId: "3816",
    VDNumber: "43410833",
    RegisteredPopulation: "1254",
    VotingStationName: "NGWEKAZI SECONDARY SCHOOL",
    Address: "NSINGABANTU AREA  QHUDENI  NKANDLA/NSINGABANTU TRUST FARM",
    Latitude: "-28.6085",
    Longitude: "30.8639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13207",
    WardLookupId: "3816",
    VDNumber: "43410844",
    RegisteredPopulation: "243",
    VotingStationName: "EZIJIBENI PRIMARY SCHOOL",
    Address: "EZIJIBENI AREA  AMATSHEMADE  NKANDLA/NGONO T/C",
    Latitude: "-28.6881",
    Longitude: "30.8572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13208",
    WardLookupId: "3816",
    VDNumber: "43411003",
    RegisteredPopulation: "673",
    VotingStationName: "GODIDE COMMUNITY HALL",
    Address: "DLOLWANE AREA  DLOLWANE  NKANDLA/GODIDE T/C",
    Latitude: "-28.7012",
    Longitude: "30.9249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13209",
    WardLookupId: "3816",
    VDNumber: "43417953",
    RegisteredPopulation: "384",
    VotingStationName: "MAKHATHINI PRIMARY SCHOOL",
    Address: "EKHOMBE AREA  EKHOMBE  NKANDLA/XULU T/C",
    Latitude: "-28.6439",
    Longitude: "30.8993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13210",
    WardLookupId: "3816",
    VDNumber: "43419180",
    RegisteredPopulation: "692",
    VotingStationName: "QHUDENI COMBINED SCHOOL",
    Address: "MZIMHLOPHE AREA  QHUDENI  NKANDLA/QHUDENI TRUST FARM",
    Latitude: "-28.6163",
    Longitude: "30.8419",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13211",
    WardLookupId: "3817",
    VDNumber: "43410855",
    RegisteredPopulation: "473",
    VotingStationName: "EMAKHWABE PRIMARY SCHOOL",
    Address: "DINA AREA  DINA  NKANDLA/NGONO T/C",
    Latitude: "-28.7049",
    Longitude: "30.673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13212",
    WardLookupId: "3817",
    VDNumber: "43410866",
    RegisteredPopulation: "597",
    VotingStationName: "NHLOSHANE PRIMARY SCHOOL",
    Address: "NHLOSHANE AREA  ENHLOSHANE  NKANDLA/NGONO T/C",
    Latitude: "-28.7182",
    Longitude: "30.7422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13213",
    WardLookupId: "3817",
    VDNumber: "43410888",
    RegisteredPopulation: "1044",
    VotingStationName: "SAVUYE PRIMARY SCHOOL",
    Address: "SAVUYE AREA  SAVUYE  NKANDLA/NGONO T/C",
    Latitude: "-28.7126",
    Longitude: "30.8289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13214",
    WardLookupId: "3817",
    VDNumber: "43410899",
    RegisteredPopulation: "1039",
    VotingStationName: "MFONGOSI PRIMARY SCHOOL",
    Address: "MFONGOSI AREA  MFONGOSI  NKANDLA/NGONO T/C",
    Latitude: "-28.714",
    Longitude: "30.8035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13215",
    WardLookupId: "3817",
    VDNumber: "43416255",
    RegisteredPopulation: "443",
    VotingStationName: "NSUNGUZA PRIMARY SCHOOL",
    Address: "SHOBALENYATHI  SHOBALENYATHI AREA  NKANDLA/NGONOT/C",
    Latitude: "-28.6835",
    Longitude: "30.7014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13216",
    WardLookupId: "3817",
    VDNumber: "43416761",
    RegisteredPopulation: "171",
    VotingStationName: "BHEDLANA PRIMARY SCHOOL",
    Address: "SAMUNGU AREA  NKANDLA /NGONO T/C",
    Latitude: "-28.7331",
    Longitude: "30.7377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13217",
    WardLookupId: "3817",
    VDNumber: "43419292",
    RegisteredPopulation: "625",
    VotingStationName: "DININTULI PRIMARY",
    Address: "N/A DININTULI AREA  NKANDLA RESERVE  NGONO TRIBAL AUTHORITY",
    Latitude: "-28.723",
    Longitude: "30.7018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13218",
    WardLookupId: "3818",
    VDNumber: "43410798",
    RegisteredPopulation: "437",
    VotingStationName: "MADLOZI PRIMARY SCHOOL",
    Address: "MADLOZI AREA  NDIKWE  NKANDLA/KHABELA T/C",
    Latitude: "-28.5949",
    Longitude: "30.8928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13219",
    WardLookupId: "3818",
    VDNumber: "43411036",
    RegisteredPopulation: "379",
    VotingStationName: "NJIKINI PRIMARY SCHOOL",
    Address: "MAHALTHINI AREA  MAHLATHINI  NKANDLA/XULU T/C",
    Latitude: "-28.6299",
    Longitude: "30.9302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13220",
    WardLookupId: "3818",
    VDNumber: "43411047",
    RegisteredPopulation: "1120",
    VotingStationName: "MVUTSHINI COMMUNITY SERVICE CENTRE",
    Address: "NKUNZEBOMVU AREA  ENKUNZEBOMVU  NKANDLA/XULU T/C",
    Latitude: "-28.6471",
    Longitude: "30.9424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13221",
    WardLookupId: "3818",
    VDNumber: "43411058",
    RegisteredPopulation: "543",
    VotingStationName: "THULANI ZCC",
    Address:
      "THULANI AREA  AMAPHUTHU TRADITIONAL CENTRE  NKANDLA/AMAPHUTHU T/C",
    Latitude: "-28.6385",
    Longitude: "30.9998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13222",
    WardLookupId: "3818",
    VDNumber: "43411069",
    RegisteredPopulation: "82",
    VotingStationName: "NDIKWE PRIMARY",
    Address:
      "N/A MTHANDNHLE AREA  NKANDLA RESERVE  EKUKHANYENI TRIBAL AUTHORITY",
    Latitude: "-28.5681",
    Longitude: "30.9576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13223",
    WardLookupId: "3818",
    VDNumber: "43415153",
    RegisteredPopulation: "104",
    VotingStationName: "MNDUNDUZELI PRIMARY SCHOOL",
    Address: "MNDUNDUZELI AREA  MNDUNDUZELI  NKANDLA/EKUKHANYENI T/C",
    Latitude: "-28.6017",
    Longitude: "30.9254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13224",
    WardLookupId: "3818",
    VDNumber: "43416772",
    RegisteredPopulation: "809",
    VotingStationName: "AMAPHUTHU TRADITIONAL COURT",
    Address: "AMAPHUTHU AREA  AMAPHUTHU  NKANDLA/AMAPHUTHU T/C",
    Latitude: "-28.5949",
    Longitude: "31.0347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13225",
    WardLookupId: "3818",
    VDNumber: "43419203",
    RegisteredPopulation: "527",
    VotingStationName: "EZIMAMBENI PRIMARY SCHOOL",
    Address:
      "EZIMAMBENI AREA  AMAPHUTHU TRADITIONAL CENTRE  NKANDLA/ AMAPHUTHU T/C",
    Latitude: "-28.6131",
    Longitude: "30.9985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13226",
    WardLookupId: "3819",
    VDNumber: "43411205",
    RegisteredPopulation: "1187",
    VotingStationName: "KWAMTSHWILI PRIMARY SCHOOL",
    Address: "KWAMTSHWILI AREA  KWAMTSHWILI  NKANDLA/CHUBE T/C",
    Latitude: "-28.5953",
    Longitude: "31.0827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13227",
    WardLookupId: "3819",
    VDNumber: "43415939",
    RegisteredPopulation: "255",
    VotingStationName: "DLABE PRIMARY SCHOOL",
    Address: "DLABE AREA  NKANDLA/CHUBE T/C",
    Latitude: "-28.713",
    Longitude: "31.0557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13228",
    WardLookupId: "3819",
    VDNumber: "43415940",
    RegisteredPopulation: "1094",
    VotingStationName: "OPHINDWENI COMMUNITY HALL",
    Address: "MAZAMBANENI AREA  EMAZAMBANENI  NKANDLACHUBE T/C",
    Latitude: "-28.6739",
    Longitude: "31.0927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13229",
    WardLookupId: "3819",
    VDNumber: "43415995",
    RegisteredPopulation: "103",
    VotingStationName: "SIMANJALO PRIMARY SCHOOL",
    Address: "MALENJE AREA  SIMANJALO  NKANDLA/CHUBE T/C",
    Latitude: "-28.7655",
    Longitude: "31.0168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13230",
    WardLookupId: "3819",
    VDNumber: "43416266",
    RegisteredPopulation: "725",
    VotingStationName: "MDLELANGA PRIMARY SCHOOL",
    Address: "MDLELANGA AREA  MDLELANGA SCHOOL  NKANDLA/CHUBE T/C",
    Latitude: "-28.6233",
    Longitude: "31.048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13231",
    WardLookupId: "3819",
    VDNumber: "43416288",
    RegisteredPopulation: "247",
    VotingStationName: "NGOMANKULU PRIMARY SCHOOL",
    Address: "QUTSHINI AREA  QUTSHINI  NKANDLA/CHUBE T/C",
    Latitude: "-28.7411",
    Longitude: "31.0481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13232",
    WardLookupId: "3819",
    VDNumber: "44540018",
    RegisteredPopulation: "251",
    VotingStationName: "SIHAMBAKAHLE PRIMARY SCHOOL",
    Address: "NKONISA  CHUBE",
    Latitude: "-28.6061",
    Longitude: "31.0467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13233",
    WardLookupId: "3820",
    VDNumber: "43410945",
    RegisteredPopulation: "684",
    VotingStationName: "PHOLELA HALL",
    Address: "PHOLELA AREA  PHOLELA  NKANDLA/GODIDE T/C",
    Latitude: "-28.7164",
    Longitude: "30.855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13234",
    WardLookupId: "3820",
    VDNumber: "43411025",
    RegisteredPopulation: "865",
    VotingStationName: "NTINGWE PRIMARY SCHOOL",
    Address: "KWASALOFU AREA  KWASALOFU  NKANDLA/IZIGQOZA T/C",
    Latitude: "-28.6821",
    Longitude: "30.9706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13235",
    WardLookupId: "3820",
    VDNumber: "43415951",
    RegisteredPopulation: "123",
    VotingStationName: "GUBHELA PRIMARY SCHOOL",
    Address: "MASOLOSOLO AREA  MASOLOSOLO  NKANDLA/GODIDE T/C",
    Latitude: "-28.7191",
    Longitude: "30.997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13236",
    WardLookupId: "3820",
    VDNumber: "43415962",
    RegisteredPopulation: "315",
    VotingStationName: "GODIDE TRADITIONAL COURT",
    Address: "JOKWENI AREA  EJOKWENI AREA  NKANDLA/GODIDE T/C",
    Latitude: "-28.7201",
    Longitude: "30.9226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13237",
    WardLookupId: "3820",
    VDNumber: "43416783",
    RegisteredPopulation: "513",
    VotingStationName: "KWAGUGU PRIMARY SCHOOL",
    Address: "EZIMPISINI AREA  EZIMPISINI  NKANDLA/IZIGQOZA T/C",
    Latitude: "-28.6775",
    Longitude: "30.9395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13238",
    WardLookupId: "3820",
    VDNumber: "43417267",
    RegisteredPopulation: "166",
    VotingStationName: "MACALA PRIMARY SCHOOL",
    Address: "MACALA AREA  MACALA  NKANDLA/GODIDE T/C",
    Latitude: "-28.7371",
    Longitude: "30.9658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13239",
    WardLookupId: "3820",
    VDNumber: "43417403",
    RegisteredPopulation: "238",
    VotingStationName: "MAQHUBANDABA PRIMARY SCHOOL",
    Address: "MANYANE AREA  MAQHUBANDABA  NKANDLA/GODIDE T/C",
    Latitude: "-28.7324",
    Longitude: "30.9388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13240",
    WardLookupId: "3820",
    VDNumber: "43417649",
    RegisteredPopulation: "265",
    VotingStationName: "LUNGELANI PRIMARY SCHOOL",
    Address: "SIKHWANE AREA  SIKHWANE  NKANDLA/NGONO T/C",
    Latitude: "-28.7025",
    Longitude: "30.8732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13241",
    WardLookupId: "3820",
    VDNumber: "43417650",
    RegisteredPopulation: "549",
    VotingStationName: "MASIHLOBANE CRECHE",
    Address: "MSOBETSHENI AREA  MSOBETSHENI  NKANDLA/IZIGQOZA T/C",
    Latitude: "-28.6636",
    Longitude: "30.9917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13242",
    WardLookupId: "3820",
    VDNumber: "43417964",
    RegisteredPopulation: "437",
    VotingStationName: "VUMUKUKHANYA PRIMARY SCHOOL",
    Address: "DLOLWANE AREA  DLOLWANE  NKANDLA/GODIDE T/C",
    Latitude: "-28.7092",
    Longitude: "30.9427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13243",
    WardLookupId: "3821",
    VDNumber: "43410956",
    RegisteredPopulation: "811",
    VotingStationName: "JAMESONS DRIFT COMMUNITY HALL",
    Address: "MWANE AREA  MWANE   NKANDLA/GODIDE T/C",
    Latitude: "-28.7747",
    Longitude: "30.9034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13244",
    WardLookupId: "3821",
    VDNumber: "43410990",
    RegisteredPopulation: "867",
    VotingStationName: "MATHOLAMNYAMA SECONDARY SCHOOL",
    Address: "ETULWANE AREA  ETULWANE  NKANDLA/KWAMAGWAZA T/C",
    Latitude: "-28.7817",
    Longitude: "30.9656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13245",
    WardLookupId: "3821",
    VDNumber: "43411159",
    RegisteredPopulation: "1236",
    VotingStationName: "KWAMAGWAZA TRADITIONAL COURT",
    Address: "MTHUNGWENI  EMTHUNGWINI  NKANDLA/KWAMAGWAZA T/C",
    Latitude: "-28.8313",
    Longitude: "31.0343",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13246",
    WardLookupId: "3821",
    VDNumber: "43411160",
    RegisteredPopulation: "665",
    VotingStationName: "NSUNDUKAZI PRIMARY SCHOOL",
    Address: "NSUNDUKAZI  MAGWAZA  NKANDLA/KWAMAGWAZA T/C",
    Latitude: "-28.81",
    Longitude: "31.0339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13247",
    WardLookupId: "3821",
    VDNumber: "43415973",
    RegisteredPopulation: "609",
    VotingStationName: "QHOSHANGANI PRIMARY SCHOOL",
    Address: "QHOSHANGANI  QHOSHANGANI AREA  NKANDLA/KWAMAGWAZA T/C",
    Latitude: "-28.7967",
    Longitude: "30.9276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13248",
    WardLookupId: "3821",
    VDNumber: "43419191",
    RegisteredPopulation: "406",
    VotingStationName: "SIZWESONKE SECONDARY SCHOOL",
    Address:
      "KWAMAGWAZA AREA  KWAMAGWAZA TRADITIONAL CENTRE  NKANDLA/KWAMAGWAZA T/C",
    Latitude: "-28.849",
    Longitude: "31.0004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13249",
    WardLookupId: "3822",
    VDNumber: "43411171",
    RegisteredPopulation: "280",
    VotingStationName: "NOYIBEWU PRIMARY SCHOOL",
    Address: "NOYIBEWU AREA  CHUBE  NKANDLA/CHUBE T/C",
    Latitude: "-28.7877",
    Longitude: "31.0556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13250",
    WardLookupId: "3822",
    VDNumber: "43411261",
    RegisteredPopulation: "261",
    VotingStationName: "IZINDLOZI TRADITIONAL COURT",
    Address: "MAWANE  IZINDLOZI  IZINDLOZI TRIBAL AUTHORITY",
    Latitude: "-28.7784",
    Longitude: "31.1422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13251",
    WardLookupId: "3822",
    VDNumber: "43411272",
    RegisteredPopulation: "582",
    VotingStationName: "HHALAMBU PRIMARY SCHOOL",
    Address: "HHALAMBU  HHALAMBU AREA  NKANDLA/KWAMAGWAZA T/C",
    Latitude: "-28.7774",
    Longitude: "31.1129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13252",
    WardLookupId: "3822",
    VDNumber: "43411283",
    RegisteredPopulation: "414",
    VotingStationName: "KHOMO PRIMARY SCHOOL",
    Address: "EZIHLABENI AREA  EZIHLABENI  NKANDLA/IZINDLOZI T/C",
    Latitude: "-28.8189",
    Longitude: "31.1656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13253",
    WardLookupId: "3822",
    VDNumber: "43411306",
    RegisteredPopulation: "745",
    VotingStationName: "KWANXAMALALA TRADITIONAL COURT",
    Address: "OSWANINI AREA  OSWANINI  NKANDLA/KWANXAMALALA T/C",
    Latitude: "-28.8625",
    Longitude: "31.0843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13254",
    WardLookupId: "3822",
    VDNumber: "43414848",
    RegisteredPopulation: "413",
    VotingStationName: "KING CETSHWAYO SECONDARY SCHOOL",
    Address: "IZIMBIDLA AREA  IZIMBIDLA  NKANDLA/CHUBE T/C",
    Latitude: "-28.7623",
    Longitude: "31.077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13255",
    WardLookupId: "3822",
    VDNumber: "43414961",
    RegisteredPopulation: "1761",
    VotingStationName: "NTOLWANE PRIMARY SCHOOL",
    Address: "ENTOLWANE AREA  ENTOLWANE  NKANDLA/KWANXAMALALA T/C",
    Latitude: "-28.8257",
    Longitude: "31.1216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13256",
    WardLookupId: "1636",
    VDNumber: "43411991",
    RegisteredPopulation: "986",
    VotingStationName: "EBENDLE PRIMARY SCHOOL",
    Address:
      "DOKODWENI AREA  MACAMBINI TRIBAL AUTHORITY  ENDONDAKUSUKA MUNICIPALITY",
    Latitude: "-29.0951",
    Longitude: "31.618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13257",
    WardLookupId: "1636",
    VDNumber: "43412004",
    RegisteredPopulation: "1058",
    VotingStationName: "AMATIGULU HIGH SCHOOL",
    Address:
      "DOKODWENI AREA  MACAMBINI TRIBAL AUTHORITY  ENDONDAKUSUKA MUNICIPALITY",
    Latitude: "-29.0744",
    Longitude: "31.6248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13258",
    WardLookupId: "1636",
    VDNumber: "43412015",
    RegisteredPopulation: "1110",
    VotingStationName: "THEKELIMFUNDO PRIMARY SCHOOL",
    Address:
      "DOKODWENI AREA  MACAMBINI TRIBAL AUTHORITY  ENDONDAKUSUKA MUNICIPALITY",
    Latitude: "-29.0616",
    Longitude: "31.644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13259",
    WardLookupId: "1636",
    VDNumber: "43417447",
    RegisteredPopulation: "550",
    VotingStationName: "MANGQAKAZA COMMUNITY HALL",
    Address: "WARD 1 43417447  DOKODWENI  MANDENI",
    Latitude: "-29.0665",
    Longitude: "31.5982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13260",
    WardLookupId: "1636",
    VDNumber: "43417559",
    RegisteredPopulation: "872",
    VotingStationName: "INGOBAMAKHOSI HIGH  SCHOOL",
    Address: "WARD 1 VD:43417559  DOKODWENI  MANDENI",
    Latitude: "-29.0776",
    Longitude: "31.5893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13261",
    WardLookupId: "1637",
    VDNumber: "43411924",
    RegisteredPopulation: "1611",
    VotingStationName: "SIKHUTHELE  HIGH SCHOOL",
    Address: "WARD 2  OHWEBEDE   MACAMBINI TRIBAL AUTHORITY",
    Latitude: "-29.1854",
    Longitude: "31.5119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13262",
    WardLookupId: "1637",
    VDNumber: "43411946",
    RegisteredPopulation: "808",
    VotingStationName: "EMTHALENI  COMBINED PRIMARY SCHOOL",
    Address:
      "NYATHINI ROAD  NYATHINI AREA, NEAR THENGELA STORE   MACAMBINI TRIBAL AUTHORITY",
    Latitude: "-29.122",
    Longitude: "31.558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13263",
    WardLookupId: "1637",
    VDNumber: "43411979",
    RegisteredPopulation: "944",
    VotingStationName: "EMHLUBULWENI  PRIMARY SCHOOL",
    Address: "OHWEBEDE AREA  EMHLUBULWENI   MACAMBINI TRIBAL AUTHORITY",
    Latitude: "-29.1529",
    Longitude: "31.5443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13264",
    WardLookupId: "1637",
    VDNumber: "43418156",
    RegisteredPopulation: "887",
    VotingStationName: "SAKHESETHU  PRIMARY SCHOOL",
    Address: "MANGETHE   MANGETHE AREA  MACAMBINI TRIBAL AUTHORITY",
    Latitude: "-29.1921",
    Longitude: "31.4974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13265",
    WardLookupId: "1638",
    VDNumber: "43415210",
    RegisteredPopulation: "855",
    VotingStationName: "NEW LIFE ACADEMY",
    Address: "MANGETHE   MANDINI  MACAMBINI TRIBAL AUTHORITY",
    Latitude: "-29.1784",
    Longitude: "31.4724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13266",
    WardLookupId: "1638",
    VDNumber: "43415535",
    RegisteredPopulation: "316",
    VotingStationName: "IFALETHU  PRIMARY SCHOOL",
    Address: "EMHLUBULWENI AREA  MACAMBINI TRIBAL AUTHORITY  ENDONDAKUSUKA",
    Latitude: "-29.1576",
    Longitude: "31.503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13267",
    WardLookupId: "1638",
    VDNumber: "43417458",
    RegisteredPopulation: "263",
    VotingStationName: "LOMO SUPERITE STORE",
    Address: "MAKHWANINI AREA  ENDONDAKUSUKA  ESILENGENI",
    Latitude: "-29.1402",
    Longitude: "31.4803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13268",
    WardLookupId: "1638",
    VDNumber: "43460018",
    RegisteredPopulation: "604",
    VotingStationName: "TUGELA SECONDARY SCHOOL",
    Address: "2 PETUNIA ROAD  TUGELA  MANDENI",
    Latitude: "-29.1792",
    Longitude: "31.4161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13269",
    WardLookupId: "1638",
    VDNumber: "43460029",
    RegisteredPopulation: "1785",
    VotingStationName: "NG KERK - MANDINI",
    Address: "2 PLOVER CRESCENT  MANDINI  MANDENI",
    Latitude: "-29.1555",
    Longitude: "31.4114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13270",
    WardLookupId: "1638",
    VDNumber: "43460119",
    RegisteredPopulation: "742",
    VotingStationName: "DESTINY GLOBAL COMMUNITY CHURCH",
    Address: "MAIN ROAD  HIGH VIEW PARK  TUGELA",
    Latitude: "-29.1897",
    Longitude: "31.4156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13271",
    WardLookupId: "1639",
    VDNumber: "43460096",
    RegisteredPopulation: "1424",
    VotingStationName: "PADIANAGAR OPEN SPACE (TENT)",
    Address: "ZICORNIA A STREET   TUGELA   MANDENI",
    Latitude: "-29.1763",
    Longitude: "31.4083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13272",
    WardLookupId: "1639",
    VDNumber: "43580976",
    RegisteredPopulation: "224",
    VotingStationName: "THE BEDMASTERS WORKSHOP",
    Address: "WARD 4 VD: 43580976  NORVAS FARM   NEWARK",
    Latitude: "-29.2111",
    Longitude: "31.3718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13273",
    WardLookupId: "1639",
    VDNumber: "43582237",
    RegisteredPopulation: "2819",
    VotingStationName: "NEWARK  PRIMARY SCHOOL",
    Address: "HLOMENDLINI   MANDENI",
    Latitude: "-29.1858",
    Longitude: "31.3967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13274",
    WardLookupId: "1640",
    VDNumber: "43411788",
    RegisteredPopulation: "635",
    VotingStationName: "DENDETHU  PRIMARY SCHOOL",
    Address:
      "DENDETHU RESERVE  MATHONSI TRIBAL AUTHORITY  ENDONDAKUSUKA MUNICIPALITY",
    Latitude: "-29.1281",
    Longitude: "31.3413",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13275",
    WardLookupId: "1640",
    VDNumber: "43411799",
    RegisteredPopulation: "1932",
    VotingStationName: "ENEMBE PRIMARY SCHOOL",
    Address: "ROAD D1654 ENEMBE RESERVE  MATHONSI TRIBAL AUTHORITY  MANDENI",
    Latitude: "-29.0994",
    Longitude: "31.3589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13276",
    WardLookupId: "1640",
    VDNumber: "43411878",
    RegisteredPopulation: "1363",
    VotingStationName: "IWETANE PRIMARY SCHOOL",
    Address:
      "MATSHEKETSHENI AREA  MATHONSI TRIBAL AUTHORITY  ENDONDAKUSUKA MUNICIPALITY",
    Latitude: "-29.133",
    Longitude: "31.3786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13277",
    WardLookupId: "1640",
    VDNumber: "43417469",
    RegisteredPopulation: "485",
    VotingStationName: "SINETHEMBA WETANE CRECHE",
    Address: "NEAR ZAMOKUHLE STORE   MATSHEKETSHENI   WETANE AREA",
    Latitude: "-29.1219",
    Longitude: "31.3626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13278",
    WardLookupId: "1641",
    VDNumber: "43411698",
    RegisteredPopulation: "946",
    VotingStationName: "NKOMIDLI PRIMARY SCHOOL",
    Address: "BASHIBHISI AREA  NDULINDE  ENDONDAKUSUKA MUNICIPALITY",
    Latitude: "-29.0158",
    Longitude: "31.4312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13279",
    WardLookupId: "1641",
    VDNumber: "43411700",
    RegisteredPopulation: "813",
    VotingStationName: "NKWENKWEZI  SECONDARY SCHOOL",
    Address: "MANDINI  SIKHONYANE TRIBAL AUTHORITY",
    Latitude: "-29.0414",
    Longitude: "31.4326",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13280",
    WardLookupId: "1641",
    VDNumber: "43412138",
    RegisteredPopulation: "1429",
    VotingStationName: "EVUTHA COMBINED PRIMARY SCHOOL",
    Address: "NYONI MAIN ROAD  NKUNZEMPUNGU 1  ENDONDAKUSUKA MUNICIPALITY",
    Latitude: "-29.0484",
    Longitude: "31.4798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13281",
    WardLookupId: "1641",
    VDNumber: "43415513",
    RegisteredPopulation: "571",
    VotingStationName: "ISIKHONYANE LOWER PRIMARY SCHOOL",
    Address:
      "NDULINDE STORE ROAD  NDULINDE - MFUZE AREA  SIKHONYANE TRIBAL AUTHORITY",
    Latitude: "-29.0241",
    Longitude: "31.4021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13282",
    WardLookupId: "1642",
    VDNumber: "43411801",
    RegisteredPopulation: "3617",
    VotingStationName: "IMPOQABULUNGA  HIGH SCHOOL",
    Address: "DARK CITY   MANDENI   MATHONSI",
    Latitude: "-29.1476",
    Longitude: "31.3993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13283",
    WardLookupId: "1642",
    VDNumber: "43416299",
    RegisteredPopulation: "1201",
    VotingStationName: "ETHEL MTHIYANE SPECIAL SCHOOL",
    Address: "MANDENI   MAJUBA AREA  MATHONSI TRIBAL AUTHORITY",
    Latitude: "-29.1371",
    Longitude: "31.391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13284",
    WardLookupId: "1643",
    VDNumber: "43411968",
    RegisteredPopulation: "717",
    VotingStationName: "MGOVUZO  PRIMARY SCHOOL",
    Address: "MATIGULU   MATIGULU BRIDGE  AMATIGULU",
    Latitude: "-29.0662",
    Longitude: "31.5787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13285",
    WardLookupId: "1643",
    VDNumber: "43411980",
    RegisteredPopulation: "914",
    VotingStationName: "SOMSHOKO SECONDARY SCHOOL",
    Address:
      "NYATHINI AREA  MACAMBINI TRIBAL AUTHORITY  ENDONDAKUSUKA MUNICIPALITY",
    Latitude: "-29.1203",
    Longitude: "31.5745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13286",
    WardLookupId: "1643",
    VDNumber: "43415018",
    RegisteredPopulation: "1027",
    VotingStationName: "ISINYABUSI  TECHNICAL HIGH  SCHOOL",
    Address: "MATIGULU BRIDGE   MACAMBINI TRIBAL AUTHORITY",
    Latitude: "-29.0715",
    Longitude: "31.5557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13287",
    WardLookupId: "1643",
    VDNumber: "43415524",
    RegisteredPopulation: "1018",
    VotingStationName: "LAMBOTHI HIGH SCHOOL",
    Address:
      "NYATHINI   NYATHINI NEXT TO BETHEL CHURCH   MACAMBINI TRIBAL AUTHORITY",
    Latitude: "-29.1178",
    Longitude: "31.5286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13288",
    WardLookupId: "1643",
    VDNumber: "43418178",
    RegisteredPopulation: "830",
    VotingStationName: "LOKOTHWAYO PRIMARY SCHOOL",
    Address: "MACAMBINI   MACAMBINI TRIBAL AUTHORITY",
    Latitude: "-29.0907",
    Longitude: "31.5457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13289",
    WardLookupId: "1644",
    VDNumber: "43411935",
    RegisteredPopulation: "1999",
    VotingStationName: "NDONDAKUSUKA HIGH SCHOOL",
    Address: "MANGETHE AREA   MANGETHE   MACAMBINI TRIBAL AUTHORITY",
    Latitude: "-29.1356",
    Longitude: "31.4655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13290",
    WardLookupId: "1644",
    VDNumber: "43411957",
    RegisteredPopulation: "760",
    VotingStationName: "NKUNZEMALUNDA  PRIMARY SCHOOL",
    Address: "NYATHINI  MACAMBINI   MACAMBINI TRIBAL AUTHORITY",
    Latitude: "-29.1052",
    Longitude: "31.4933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13291",
    WardLookupId: "1644",
    VDNumber: "43416020",
    RegisteredPopulation: "425",
    VotingStationName: "CHURCH OF THE  NAZARENE",
    Address: "MACAMBINI   MCAMBINI TRIBAL AUTHORITY",
    Latitude: "-29.1301",
    Longitude: "31.51",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13292",
    WardLookupId: "1644",
    VDNumber: "43417515",
    RegisteredPopulation: "463",
    VotingStationName: "ST PATRICKS CATHOLIC CHURCH",
    Address: "KWAMPUNGOSE AREA WARD 9  MANGETHE  MANDENI",
    Latitude: "-29.1602",
    Longitude: "31.5129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13293",
    WardLookupId: "1644",
    VDNumber: "43418167",
    RegisteredPopulation: "469",
    VotingStationName: "GINA STORE",
    Address: "MACAMBINI  MACAMBINI TRIBAL AUTHORITY",
    Latitude: "-29.1362",
    Longitude: "31.5289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13294",
    WardLookupId: "1644",
    VDNumber: "43418785",
    RegisteredPopulation: "831",
    VotingStationName: "NYATHINI  BAPTIST CHURCH",
    Address: "MANDENI   MACAMBINI TRIBAL AUTHORITY",
    Latitude: "-29.1179",
    Longitude: "31.4978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13295",
    WardLookupId: "1645",
    VDNumber: "43411654",
    RegisteredPopulation: "1274",
    VotingStationName: "MANDENI  PRIMARY SCHOOL",
    Address: "MASOMONCO AREA  SUNDUMBILI  MANDENI MUNICIPALITY",
    Latitude: "-29.1277",
    Longitude: "31.4055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13296",
    WardLookupId: "1645",
    VDNumber: "43415546",
    RegisteredPopulation: "1512",
    VotingStationName: "GCWALAMOYA PRIMARY SCHOOL",
    Address: "ENEMBE ROAD  GQUMENI AREA  ENDONDAKUSUKA MUNICIPALITY",
    Latitude: "-29.1127",
    Longitude: "31.3898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13297",
    WardLookupId: "1645",
    VDNumber: "43418796",
    RegisteredPopulation: "720",
    VotingStationName: "CRANBURN PRIMARY SCHOOL",
    Address: "KHENNAN AREA   NYONI  MANDENI",
    Latitude: "-29.1136",
    Longitude: "31.4193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13298",
    WardLookupId: "1645",
    VDNumber: "43418808",
    RegisteredPopulation: "1320",
    VotingStationName: "APOSTOLIC CHURCH",
    Address: "MASOMONCO   MATHONSI TRIABL AUTHORITY",
    Latitude: "-29.1162",
    Longitude: "31.4029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13299",
    WardLookupId: "1646",
    VDNumber: "43411890",
    RegisteredPopulation: "952",
    VotingStationName: "ZITHULELE COMMUNITY HALL",
    Address: "NDULINDE   NYONI  SIKHONYANE TRIBAL AUTHORITY",
    Latitude: "-29.0572",
    Longitude: "31.3976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13300",
    WardLookupId: "1646",
    VDNumber: "43414804",
    RegisteredPopulation: "1187",
    VotingStationName: "MCATSHANGELWA  PRIMARY SCHOOL",
    Address: "THEMBENI AREA   MATHONSI TRIBAL COURT  MATHONSI TRIBAL AUTHORITY",
    Latitude: "-29.0671",
    Longitude: "31.4148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13301",
    WardLookupId: "1646",
    VDNumber: "44560021",
    RegisteredPopulation: "343",
    VotingStationName: "NKUNZEMPUNGA GROUND  (TENT)",
    Address: "NKUNZEMPUNGA   NYONI  NDULINDE",
    Latitude: "-29.055",
    Longitude: "31.4368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13302",
    WardLookupId: "1647",
    VDNumber: "43411856",
    RegisteredPopulation: "3281",
    VotingStationName: "MATHONSI TRIBAL COURT",
    Address: "ENEMBE ROAD  MATHONSI AREA   MANDENI",
    Latitude: "-29.1199",
    Longitude: "31.3876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13303",
    WardLookupId: "1647",
    VDNumber: "43411867",
    RegisteredPopulation: "764",
    VotingStationName: "DUNGA PRIMARY SCHOOL",
    Address: "MANCUNGWINI AREA  SUNDUMBILI  MATHONSI T.A.",
    Latitude: "-29.108",
    Longitude: "31.3824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13304",
    WardLookupId: "1647",
    VDNumber: "43417009",
    RegisteredPopulation: "1676",
    VotingStationName: "SHEKEMBUYA ECD CENTRE",
    Address: "SANGWENI ELINCANE   SITHEBE   MATHONSI",
    Latitude: "-29.088",
    Longitude: "31.4",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13305",
    WardLookupId: "1648",
    VDNumber: "43460052",
    RegisteredPopulation: "2555",
    VotingStationName: "SIYAVIKELWA HIGHER PRIMARY SCHOOL",
    Address: "IRELAND   SUNDUMBILI   MANDENI",
    Latitude: "-29.1265",
    Longitude: "31.3967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13306",
    WardLookupId: "1648",
    VDNumber: "43460120",
    RegisteredPopulation: "571",
    VotingStationName: "BACK TO THE WORD CHURCH",
    Address: "UDUMO AREA  SUNDUMBILI  SUNDUMBILI",
    Latitude: "-29.123",
    Longitude: "31.3932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13307",
    WardLookupId: "1649",
    VDNumber: "43460041",
    RegisteredPopulation: "2555",
    VotingStationName: "UBUHLEBESUNDUMBILI PRIMARY  SCHOOL",
    Address: "SUNDUMBILI  MANDENI   MATHONSI TRIBAL AUTHORITY",
    Latitude: "-29.1315",
    Longitude: "31.3882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13308",
    WardLookupId: "1649",
    VDNumber: "43460074",
    RegisteredPopulation: "1274",
    VotingStationName: "SUNDUMBILI METHODIST CHURCH",
    Address: "WARD 14 VD:43460074  LOT 921 REDHILL  MANDENI",
    Latitude: "-29.1307",
    Longitude: "31.3956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13309",
    WardLookupId: "1650",
    VDNumber: "43460063",
    RegisteredPopulation: "1905",
    VotingStationName: "SUNDUMBILI PRIMARY SCHOOL",
    Address: "SKHALAMBAZO   SUNDUMBILI   MANDENI",
    Latitude: "-29.1334",
    Longitude: "31.4013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13310",
    WardLookupId: "1650",
    VDNumber: "43460085",
    RegisteredPopulation: "1228",
    VotingStationName: "THUKELA  SENIOR SECONDARY SCHOOL",
    Address: "MANONO ROAD  MANDENI  ILEMBE DISTRICT",
    Latitude: "-29.1368",
    Longitude: "31.3971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13311",
    WardLookupId: "1651",
    VDNumber: "43411913",
    RegisteredPopulation: "778",
    VotingStationName: "HLANGANANI  PRIMARY  SCHOOL",
    Address: "NDULINDE ROAD  NKUNZEMPUNGA 2  ENDONDAKUSUKA MUNICIPALITY",
    Latitude: "-29.0752",
    Longitude: "31.4493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13312",
    WardLookupId: "1651",
    VDNumber: "43412161",
    RegisteredPopulation: "674",
    VotingStationName: "EKUVUKENI PRIMARY SCHOOL",
    Address: "NYONI MAIN ROAD  NYONI  MANDENI",
    Latitude: "-29.0775",
    Longitude: "31.4599",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13313",
    WardLookupId: "1651",
    VDNumber: "43415557",
    RegisteredPopulation: "1457",
    VotingStationName: "LINDAYIPHI JUNIOR PRIMARY SCHOOL",
    Address: "NYONI ROAD  SITHEBE AREA  ENDONDAKUSUKA MUNICIPALITY",
    Latitude: "-29.0896",
    Longitude: "31.4318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13314",
    WardLookupId: "1651",
    VDNumber: "43418820",
    RegisteredPopulation: "743",
    VotingStationName: "MHAMBUMA STORE (TENT)",
    Address: "ISITHEBE  MHAMBUMA STORE  MANDENI",
    Latitude: "-29.0816",
    Longitude: "31.4358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13315",
    WardLookupId: "1652",
    VDNumber: "43414747",
    RegisteredPopulation: "2160",
    VotingStationName: "ISITHEBE PRIMARY SCHOOL",
    Address: "MHAMBUMA  ENDONDAKUSUKA  ENDONDAKUSUKA",
    Latitude: "-29.0875",
    Longitude: "31.4194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13316",
    WardLookupId: "1652",
    VDNumber: "43418831",
    RegisteredPopulation: "1591",
    VotingStationName: "BAHLE BONKE CRECHE & PRE-SCHOOL",
    Address: "ZAVUTHA   SITHBE   MANDENI",
    Latitude: "-29.0956",
    Longitude: "31.4215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13317",
    WardLookupId: "1652",
    VDNumber: "44560010",
    RegisteredPopulation: "147",
    VotingStationName: "NONGOMA ISITHEBE (TENT)",
    Address: "NDLONDLWENI AREA  ISITHEBE  MATHONSI",
    Latitude: "-29.0986",
    Longitude: "31.4031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13318",
    WardLookupId: "1653",
    VDNumber: "43411823",
    RegisteredPopulation: "1717",
    VotingStationName: "ISITHEBE COMMUNITY HALL",
    Address: "MAKHEMPINI AREA  ISITHEBE  ENDONDAKUSUKA MUNICIPALITY",
    Latitude: "-29.08",
    Longitude: "31.41",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13319",
    WardLookupId: "1653",
    VDNumber: "43417010",
    RegisteredPopulation: "1725",
    VotingStationName: "ZION CONGREGATIONAL CHURCH",
    Address: "SITHEBE   MATHONSI T/A",
    Latitude: "-29.0864",
    Longitude: "31.4287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13320",
    WardLookupId: "1653",
    VDNumber: "43418819",
    RegisteredPopulation: "615",
    VotingStationName: "APOSTOLIC FAITH MISSION",
    Address: "SITHEBE   SITHEBE AREA   MATHONSI TRIBAL AUTHORITY",
    Latitude: "-29.0781",
    Longitude: "31.4191",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13321",
    WardLookupId: "3823",
    VDNumber: "43582260",
    RegisteredPopulation: "1008",
    VotingStationName: "SOKESIMBONE COMMUNITY HALL",
    Address: "0 SOKESIMBONE   KWADUKUZA  DORINGKOP - MATHONSI TRIBAL AUTHORITY",
    Latitude: "-29.1955",
    Longitude: "31.2533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13322",
    WardLookupId: "3823",
    VDNumber: "43582282",
    RegisteredPopulation: "2635",
    VotingStationName: "ST CHRISTOPHER SCHOOL",
    Address: "DOESBURG ROAD  SAKHAMKHANYA AREA  KWADUKUZA",
    Latitude: "-29.2174",
    Longitude: "31.3241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13323",
    WardLookupId: "3823",
    VDNumber: "43583834",
    RegisteredPopulation: "165",
    VotingStationName: "APOSTOLIC FAITH MISSION CHURCH",
    Address: "HOLWOOD   KEARSNEY  KWADUKUZA",
    Latitude: "-29.2954",
    Longitude: "31.2535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13324",
    WardLookupId: "3823",
    VDNumber: "43584431",
    RegisteredPopulation: "360",
    VotingStationName: "NONOTI SCHOOL",
    Address: "SAKHAMKHANYA  DORINGKOP  KWADUKUZA",
    Latitude: "-29.2388",
    Longitude: "31.3346",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13325",
    WardLookupId: "3824",
    VDNumber: "43610015",
    RegisteredPopulation: "1529",
    VotingStationName: "DARNALL LEE PRIMARY SCHOOL",
    Address: "0 PROSPECT ROAD  DARNALL  DARNALL",
    Latitude: "-29.2652",
    Longitude: "31.3604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13326",
    WardLookupId: "3824",
    VDNumber: "43610037",
    RegisteredPopulation: "2233",
    VotingStationName: "ZAMANI COMMUNITY HALL",
    Address: "ZAMANI  DARNALL  DARNALL",
    Latitude: "-29.2799",
    Longitude: "31.3624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13327",
    WardLookupId: "3824",
    VDNumber: "43610048",
    RegisteredPopulation: "780",
    VotingStationName: "SOWETO CLINIC",
    Address: "ZAMANI  ZAMANI TOWNSHIP  DARNALL",
    Latitude: "-29.274",
    Longitude: "31.3552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13328",
    WardLookupId: "3825",
    VDNumber: "43581168",
    RegisteredPopulation: "391",
    VotingStationName: "PROSPECT ESTATES FARM HALL",
    Address: "OFF R102 PROSPECT ESTATES  DARNALL  DARNALL",
    Latitude: "-29.2522",
    Longitude: "31.4162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13329",
    WardLookupId: "3825",
    VDNumber: "43581191",
    RegisteredPopulation: "1140",
    VotingStationName: "NEW GUELDERLAND HALL",
    Address: "STEWARDTS FARM  KWADUKUZA  NEW GUELDERLAND",
    Latitude: "-29.3285",
    Longitude: "31.3416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13330",
    WardLookupId: "3825",
    VDNumber: "43582327",
    RegisteredPopulation: "826",
    VotingStationName: "I`M INSTITUTION CHURCH",
    Address: "NONOTI  NONOTI AREA  KWADUKUZA",
    Latitude: "-29.2485",
    Longitude: "31.3228",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13331",
    WardLookupId: "3825",
    VDNumber: "43582620",
    RegisteredPopulation: "464",
    VotingStationName: "BONGIMFUNDO PRIMARY SCHOOL",
    Address: "JAYSMIDTS FARM ROAD  DARNALL  ZINKWAZI",
    Latitude: "-29.2859",
    Longitude: "31.3971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13332",
    WardLookupId: "3825",
    VDNumber: "43584442",
    RegisteredPopulation: "851",
    VotingStationName: "NYATHIKAZI COMMUNITY HALL",
    Address: "0 NONOTI  NYATHIKAZI AREA  MATHONSI TRIBAL AUTHORITY",
    Latitude: "-29.2492",
    Longitude: "31.3432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13333",
    WardLookupId: "3825",
    VDNumber: "43584453",
    RegisteredPopulation: "465",
    VotingStationName: "NONOTI MOUTH COMMUNITY HALL",
    Address: "KWADUKUZA  NONOTI  KWADUKUZA",
    Latitude: "-29.3128",
    Longitude: "31.3973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13334",
    WardLookupId: "3825",
    VDNumber: "43584464",
    RegisteredPopulation: "176",
    VotingStationName: "KHUTHALANI PRIMARY SCHOOL",
    Address: "NTWASHINI  NTWASHINI AREA  MATHONSI TRIBAL AUTHORITY",
    Latitude: "-29.2886",
    Longitude: "31.2961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13335",
    WardLookupId: "3825",
    VDNumber: "43610026",
    RegisteredPopulation: "438",
    VotingStationName: "MAGAI PARK MUNIC BUILDING",
    Address: "MAGAI ROAD  DARNALL  ZINKWAZI",
    Latitude: "-29.2906",
    Longitude: "31.4369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13336",
    WardLookupId: "3826",
    VDNumber: "43582844",
    RegisteredPopulation: "5883",
    VotingStationName: "RA PADAYACHEE SCHOOL",
    Address: "SAWMILLS ROAD  SHAKASHEAD  UMHLALI",
    Latitude: "-29.4973",
    Longitude: "31.2023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13337",
    WardLookupId: "3827",
    VDNumber: "43600250",
    RegisteredPopulation: "2821",
    VotingStationName: "LINDELANI COMMUNITY HALL",
    Address: "SECTION A LINDELANI AREA  LINDELANI  STANGER",
    Latitude: "-29.3151",
    Longitude: "31.3016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13338",
    WardLookupId: "3827",
    VDNumber: "43600294",
    RegisteredPopulation: "1550",
    VotingStationName: "EXT 36 HALL",
    Address: "EXT 36 VILLAGE  LINDELANI LOCATION  KWADUKUZA/STANGER",
    Latitude: "-29.3257",
    Longitude: "31.304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13339",
    WardLookupId: "3828",
    VDNumber: "43590034",
    RegisteredPopulation: "4240",
    VotingStationName: "BALLITO LIBRARY",
    Address: "51 COMPENSATION BEACH ROAD  BALLITO  BALLITO",
    Latitude: "-29.5406",
    Longitude: "31.2164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13340",
    WardLookupId: "3829",
    VDNumber: "43580932",
    RegisteredPopulation: "601",
    VotingStationName: "ETETE SCHOOL",
    Address: "GLENDALE HEIGHTS  RIETVALLEY  SHAKASKRAAL",
    Latitude: "-29.4087",
    Longitude: "31.1806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13341",
    WardLookupId: "3829",
    VDNumber: "43590078",
    RegisteredPopulation: "3761",
    VotingStationName: "TINLEY MANOR PRIMARY SCHOOL",
    Address: "2 ETETE   VELANI TOWNSHIP  SHAKASKRAAL",
    Latitude: "-29.4094",
    Longitude: "31.238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13342",
    WardLookupId: "3830",
    VDNumber: "43590056",
    RegisteredPopulation: "4327",
    VotingStationName: "DINUPHOZO PRIMARY SCHOOL",
    Address: "LOT 1445 ROSEHILL ROAD  NKOBONGO  SHAKASKRAAL",
    Latitude: "-29.4438",
    Longitude: "31.2116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13343",
    WardLookupId: "3831",
    VDNumber: "43581258",
    RegisteredPopulation: "211",
    VotingStationName: "NGIGIMBE PRIMARY SCHOOL",
    Address: "MGIGIMBE FARM  CRANBROOK  GROUTVILLE",
    Latitude: "-29.3535",
    Longitude: "31.2005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13344",
    WardLookupId: "3831",
    VDNumber: "43600148",
    RegisteredPopulation: "2575",
    VotingStationName: "MALENDE COMMUNITY HALL",
    Address: "GROUTVILLE  MALENDE AREA  KWADUKUZA/STANGER",
    Latitude: "-29.3972",
    Longitude: "31.2307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13345",
    WardLookupId: "3831",
    VDNumber: "43600160",
    RegisteredPopulation: "877",
    VotingStationName: "HANGOES COMMUNITY HALL",
    Address: "WARD 09  CRANBROOK  STANGER",
    Latitude: "-29.3732",
    Longitude: "31.2371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13346",
    WardLookupId: "3831",
    VDNumber: "43600441",
    RegisteredPopulation: "497",
    VotingStationName: "SIYATHUTHUKA CRECHE",
    Address: "NEW GROUT  MALENDE  KWADUKUZA",
    Latitude: "-29.404",
    Longitude: "31.2432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13347",
    WardLookupId: "3831",
    VDNumber: "43600452",
    RegisteredPopulation: "246",
    VotingStationName: "ZILUNGISELE PRIMARY SCHOOL",
    Address: "MALENDE  DUBE TRIBAL AREA",
    Latitude: "-29.3808",
    Longitude: "31.2289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13348",
    WardLookupId: "3832",
    VDNumber: "43600137",
    RegisteredPopulation: "2292",
    VotingStationName: "BW VILAKAZI PRIMARY SCHOOL",
    Address: "00 GROUTVILLE  GROUTVILLE MISSION  KWADUKUZA",
    Latitude: "-29.3935",
    Longitude: "31.2504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13349",
    WardLookupId: "3832",
    VDNumber: "43600340",
    RegisteredPopulation: "1183",
    VotingStationName: "MPHEYANE COMMUNITY HALL",
    Address: "MHLONGO VILLAGE  GROUTVILLE MISSION  KWADUKUZA/STANGER",
    Latitude: "-29.3889",
    Longitude: "31.2417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13350",
    WardLookupId: "3833",
    VDNumber: "43583711",
    RegisteredPopulation: "283",
    VotingStationName: "HARRY BODASINGH PRIMARY SCHOOL",
    Address: "HYDE PARK  NEW GUIDERLAND  HYDE PARK SUGAR ESTATE",
    Latitude: "-29.311",
    Longitude: "31.3561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13351",
    WardLookupId: "3833",
    VDNumber: "43600126",
    RegisteredPopulation: "2127",
    VotingStationName: "THEMBENI PRIMARY SCHOOL",
    Address: "THEMBENI AREA WARD 11  GROUTVILLE  STANGER",
    Latitude: "-29.3917",
    Longitude: "31.2778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13352",
    WardLookupId: "3833",
    VDNumber: "43600205",
    RegisteredPopulation: "563",
    VotingStationName: "BLYTHEDALE CARAVAN PARK",
    Address: "15 OCEAN DRIVE  BLYTHEDALE  KWADUKUZA",
    Latitude: "-29.3712",
    Longitude: "31.3514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13353",
    WardLookupId: "3833",
    VDNumber: "43600351",
    RegisteredPopulation: "2062",
    VotingStationName: "THANDANANI COMMUNITY HALL",
    Address: "THEMBENI  KWADUKUZA/STANGER",
    Latitude: "-29.3882",
    Longitude: "31.2827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13354",
    WardLookupId: "3834",
    VDNumber: "43580909",
    RegisteredPopulation: "315",
    VotingStationName: "TINLEY MANOR BEACH LIFE GUARD OFFICE",
    Address: "LAGOON DRIVE  TILEY MANOR  DOLPHIN COAST",
    Latitude: "-29.4519",
    Longitude: "31.2859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13355",
    WardLookupId: "3834",
    VDNumber: "43600081",
    RegisteredPopulation: "4038",
    VotingStationName: "MBHEKAMUZI COMBINED SCHOOL",
    Address: "THEMBENI  KWADUKUZA",
    Latitude: "-29.397",
    Longitude: "31.2827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13356",
    WardLookupId: "3835",
    VDNumber: "43600047",
    RegisteredPopulation: "2943",
    VotingStationName: "GLENHILLS SECONDARY SCHOOL",
    Address: "MARS DRIVE  GLENHILLS  STANGER",
    Latitude: "-29.3387",
    Longitude: "31.2663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13357",
    WardLookupId: "3835",
    VDNumber: "43600249",
    RegisteredPopulation: "1809",
    VotingStationName: "STANGER TRAINING CENTRE",
    Address: "PALM HAVEN ROAD  GLENHILLS  STANGER",
    Latitude: "-29.3318",
    Longitude: "31.2557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13358",
    WardLookupId: "3835",
    VDNumber: "43600430",
    RegisteredPopulation: "776",
    VotingStationName: "GLENHILLS SHAYAMOYA HALL",
    Address: "04 TAURUS STREET  KWADUKUZA  GLENHILLS",
    Latitude: "-29.3385",
    Longitude: "31.2579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13359",
    WardLookupId: "3836",
    VDNumber: "43600182",
    RegisteredPopulation: "2469",
    VotingStationName: "LLOYDS COMMUNITY HALL",
    Address: "MELVILLE  STANGER  STANGER",
    Latitude: "-29.3644",
    Longitude: "31.2598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13360",
    WardLookupId: "3836",
    VDNumber: "43600362",
    RegisteredPopulation: "2037",
    VotingStationName: "SIBONGISENI CRECHE",
    Address: "SHOMBELA  MELVILLE  KWADUKUZA",
    Latitude: "-29.3668",
    Longitude: "31.2568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13361",
    WardLookupId: "3837",
    VDNumber: "43600070",
    RegisteredPopulation: "1536",
    VotingStationName: "GLEDHOW PRIMARY SCHOOL",
    Address: "GLEDHOW SOUTH VILLAGE  GLEDHOW  STANGER",
    Latitude: "-29.3664",
    Longitude: "31.2857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13362",
    WardLookupId: "3837",
    VDNumber: "43600261",
    RegisteredPopulation: "2958",
    VotingStationName: "NKUKHWINI PRIMARY SCHOOL",
    Address: "ENKUKWINI SCHOOL  ENKUKWINI  KWADUKUZA [STANGER]",
    Latitude: "-29.3697",
    Longitude: "31.2774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13363",
    WardLookupId: "3837",
    VDNumber: "43600373",
    RegisteredPopulation: "1164",
    VotingStationName: "INDEPENDENT BAPTIST CHURCH",
    Address:
      "NO 2035 2ND STOP NEXT TO SQEDA STORE  NTSHAWINI  KWADUKUZA/STANGER",
    Latitude: "-29.3634",
    Longitude: "31.2683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13364",
    WardLookupId: "3837",
    VDNumber: "43600463",
    RegisteredPopulation: "185",
    VotingStationName: "GLEDHOW RUGBY CLUB",
    Address: "LARKFIELD  KWADUKUZA",
    Latitude: "-29.3529",
    Longitude: "31.292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13365",
    WardLookupId: "3838",
    VDNumber: "43600036",
    RegisteredPopulation: "2969",
    VotingStationName: "STANGER SECONDARY SCHOOL",
    Address: "CNR GRAMMAR & ELIZABETH ST  STANGER  STANGER",
    Latitude: "-29.3347",
    Longitude: "31.2905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13366",
    WardLookupId: "3838",
    VDNumber: "43600092",
    RegisteredPopulation: "1694",
    VotingStationName: "STANGER HEIGHTS PRIMARY SCHOOL",
    Address: "6 THUCKER ROAD  STANGER HEIGHTS  STANGER",
    Latitude: "-29.3318",
    Longitude: "31.2779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13367",
    WardLookupId: "3838",
    VDNumber: "43600384",
    RegisteredPopulation: "396",
    VotingStationName: "ASHRAM PRIMARY SCHOOL",
    Address: "MORNINGSIDE  DAWNSIDE  KWADUKUZA/STANGER",
    Latitude: "-29.3372",
    Longitude: "31.2985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13368",
    WardLookupId: "3838",
    VDNumber: "43600429",
    RegisteredPopulation: "876",
    VotingStationName: "KWA-ZUNGU OPEN SPACE (TENT)",
    Address: "LOT 14 GLENHILLS  KWADUKUZA  NYONIYAMANZI AREA",
    Latitude: "-29.3264",
    Longitude: "31.2666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13369",
    WardLookupId: "3839",
    VDNumber: "43582495",
    RegisteredPopulation: "438",
    VotingStationName: "L BODASING SENIOR PRIMARY SCHOOL",
    Address: "FAWSLEY PARK  KEARSNEY  KWADUKUZA",
    Latitude: "-29.3325",
    Longitude: "31.2348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13370",
    WardLookupId: "3839",
    VDNumber: "43600025",
    RegisteredPopulation: "4146",
    VotingStationName: "STANGER MANOR PRIMARY SCHOOL",
    Address: "27 MARIGOLD STREET  STANGER MANOR  STANGER",
    Latitude: "-29.3176",
    Longitude: "31.2786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13371",
    WardLookupId: "3840",
    VDNumber: "43600104",
    RegisteredPopulation: "2776",
    VotingStationName: "TSHELENKOSI HIGH SCHOOL",
    Address: "610 SCHOOL ROAD  SHAKAVILLE  STANGER",
    Latitude: "-29.3243",
    Longitude: "31.2991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13372",
    WardLookupId: "3840",
    VDNumber: "43600395",
    RegisteredPopulation: "1243",
    VotingStationName: "MBOZAMO CRECHE",
    Address: "MBOZAMO  SHAKAVILLE  KWADUKUZA",
    Latitude: "-29.3181",
    Longitude: "31.2975",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13373",
    WardLookupId: "3841",
    VDNumber: "43600058",
    RegisteredPopulation: "3155",
    VotingStationName: "KWADUKUZA TOWN HALL",
    Address: "KING SHAKA STREET  STANGER  KWADUKUZA",
    Latitude: "-29.3383",
    Longitude: "31.2907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13374",
    WardLookupId: "3841",
    VDNumber: "43600227",
    RegisteredPopulation: "538",
    VotingStationName: "DAWNVIEW PRIMARY SCHOOL",
    Address: "9 CATO STREET  CBD  KWADUKUZA",
    Latitude: "-29.3397",
    Longitude: "31.2956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13375",
    WardLookupId: "3841",
    VDNumber: "43600407",
    RegisteredPopulation: "1352",
    VotingStationName: "HEBRON COMMUNITY CHURCH",
    Address: "ROCKWELL  ROCKY PARK  KWADUKUZA/STANGER",
    Latitude: "-29.3372",
    Longitude: "31.2765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13376",
    WardLookupId: "3842",
    VDNumber: "43580910",
    RegisteredPopulation: "969",
    VotingStationName: "TINLEY MANOR VILLAGE(TENT)",
    Address: "TINLEY MANOR  SHAKASKRAAL",
    Latitude: "-29.4377",
    Longitude: "31.2507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13377",
    WardLookupId: "3842",
    VDNumber: "43590023",
    RegisteredPopulation: "1453",
    VotingStationName: "RADHA ROOPSINGH SCHOOL",
    Address: "OLD MAIN ROAD  ETETE  SHAKASKRAAL",
    Latitude: "-29.4162",
    Longitude: "31.2425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13378",
    WardLookupId: "3842",
    VDNumber: "43590102",
    RegisteredPopulation: "894",
    VotingStationName: "ENTUTHUKWENI CRECHE",
    Address: "NEXT TO KNOCKOUT STORE  ETETE AREA  SHAKASKRAAL",
    Latitude: "-29.4263",
    Longitude: "31.2343",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13379",
    WardLookupId: "3842",
    VDNumber: "43590135",
    RegisteredPopulation: "1093",
    VotingStationName: "ETETE COMMUNITY HALL",
    Address: "SNYMAN AREA  ETETE  SHAKASKRAAL",
    Latitude: "-29.4221",
    Longitude: "31.2399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13380",
    WardLookupId: "3843",
    VDNumber: "43581506",
    RegisteredPopulation: "1060",
    VotingStationName: "AKBAR TRADING STORE",
    Address: "DOORKLOOF  ESENEMBE  KWADUKUZA/RURAL",
    Latitude: "-29.4882",
    Longitude: "31.0828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13381",
    WardLookupId: "3843",
    VDNumber: "43583542",
    RegisteredPopulation: "2199",
    VotingStationName: "DRIEFONTEIN COMMUNITY HALL",
    Address: "ISENEMBE  UPPER TONGAAT",
    Latitude: "-29.4682",
    Longitude: "31.1026",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13382",
    WardLookupId: "3843",
    VDNumber: "43590124",
    RegisteredPopulation: "2218",
    VotingStationName: "TOWNSEND HALL",
    Address: "66 TOWNSEND ROAD  BALLITO  DOLPHIN COAST/BALLITO",
    Latitude: "-29.5436",
    Longitude: "31.2096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13383",
    WardLookupId: "3844",
    VDNumber: "43584330",
    RegisteredPopulation: "2751",
    VotingStationName: "SIZANI COMBINED PRIMARY SCHOOL",
    Address: "FOXHILL LANE  UMHLALI  KWADUKUZA",
    Latitude: "-29.4866",
    Longitude: "31.2276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13384",
    WardLookupId: "3844",
    VDNumber: "43590113",
    RegisteredPopulation: "2781",
    VotingStationName: "SALT ROCK LIBRARY",
    Address: "00 SALT ROCK ROAD  SALT ROCK  KWADUKUZA",
    Latitude: "-29.5066",
    Longitude: "31.2345",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13385",
    WardLookupId: "3845",
    VDNumber: "43590089",
    RegisteredPopulation: "1647",
    VotingStationName: "EMAROMENI SCHOOL",
    Address: "168 CEMETRY ROAD  KWADUKUZA  KWADUKUZA",
    Latitude: "-29.4381",
    Longitude: "31.2205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13386",
    WardLookupId: "3845",
    VDNumber: "43590090",
    RegisteredPopulation: "3230",
    VotingStationName: "ESHAYAMOYA COMMUNITY HALL",
    Address: "SHAYAMOYA  SHAYAMOYA LOCATION  SHAKASKRAAL",
    Latitude: "-29.4405",
    Longitude: "31.2153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13387",
    WardLookupId: "3845",
    VDNumber: "43590146",
    RegisteredPopulation: "1504",
    VotingStationName: "GUZANA SENIOR SCHOOL",
    Address: "SHAYAMOYA  SHAKASKRAAL  SHAKASKRAAL",
    Latitude: "-29.4354",
    Longitude: "31.2068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13388",
    WardLookupId: "3846",
    VDNumber: "43600216",
    RegisteredPopulation: "1430",
    VotingStationName: "CHIEF ALBERT LUTHULI HIGH SCHOOL",
    Address: "NKUKHWINI  NTSHAWINI  KWADUKUZA",
    Latitude: "-29.3702",
    Longitude: "31.2692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13389",
    WardLookupId: "3846",
    VDNumber: "43600272",
    RegisteredPopulation: "2434",
    VotingStationName: "MELVILLE  PRIMARY SCHOOL",
    Address: "1 OLD MILL ROAD  MELVILLE  KWADUKUZA/STANGER",
    Latitude: "-29.3761",
    Longitude: "31.2552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13390",
    WardLookupId: "3846",
    VDNumber: "43600418",
    RegisteredPopulation: "841",
    VotingStationName: "JOY TO THE WORLD CHURCH",
    Address: "NTSHAWINI  KWADUKUZA/STANGER",
    Latitude: "-29.3654",
    Longitude: "31.2612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13391",
    WardLookupId: "3847",
    VDNumber: "43581124",
    RegisteredPopulation: "522",
    VotingStationName: "RAMLAKAN PRIMARY SCHOOL",
    Address: "MAPUMULO ROAD  SAN SOUCI  HLOMENDLINI TRIBAL AUTHORITY",
    Latitude: "-29.187",
    Longitude: "31.3476",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13392",
    WardLookupId: "3847",
    VDNumber: "43582271",
    RegisteredPopulation: "2139",
    VotingStationName: "NYAKANA PRIMARY SCHOOL",
    Address: "MDLEBENI  BULWER FARM  MATHONSI TRIBAL AUTHORITY",
    Latitude: "-29.1705",
    Longitude: "31.2907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13393",
    WardLookupId: "3847",
    VDNumber: "43583902",
    RegisteredPopulation: "1213",
    VotingStationName: "TSHELABANTU PRIMARY SCHOOL",
    Address: "BULWER FARM  DORINKOP  STANGER",
    Latitude: "-29.1895",
    Longitude: "31.2942",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13394",
    WardLookupId: "3847",
    VDNumber: "43584127",
    RegisteredPopulation: "371",
    VotingStationName: "OHLANGE COMMUNITY HALL",
    Address: "OHLANGE ROAD  DORINGKOP  HLOMENDLINI",
    Latitude: "-29.183",
    Longitude: "31.3083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13395",
    WardLookupId: "3848",
    VDNumber: "43600238",
    RegisteredPopulation: "2748",
    VotingStationName: "NTSHAWINI COMM HALL",
    Address: "NEXT TO NERVAS STORE  NTSHAWINI  KWADUKUZA/STANGER",
    Latitude: "-29.3582",
    Longitude: "31.2697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13396",
    WardLookupId: "3848",
    VDNumber: "43600328",
    RegisteredPopulation: "650",
    VotingStationName: "GLENHILLS REVIVAL CHURCH",
    Address: "LOT 16  GLENHILLS  KWADUKUZA",
    Latitude: "-29.3408",
    Longitude: "31.2582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13397",
    WardLookupId: "3849",
    VDNumber: "43580213",
    RegisteredPopulation: "1461",
    VotingStationName: "ASHVILLE STATE AIDED SCHOOL",
    Address: "R 74  MADUNDUBE AREA  KWADUKUZA RURAL",
    Latitude: "-29.2676",
    Longitude: "31.1945",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13398",
    WardLookupId: "3849",
    VDNumber: "43581236",
    RegisteredPopulation: "409",
    VotingStationName: "KEARSNEY COMBINE SCHOOL",
    Address: "R 74  KEARSNEY FARM  KWADUKUZA RURAL",
    Latitude: "-29.2969",
    Longitude: "31.2407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13399",
    WardLookupId: "3849",
    VDNumber: "43581269",
    RegisteredPopulation: "1312",
    VotingStationName: "PROSPECT FARM PRIMARY SCHOOL",
    Address: "R 74  MADUNDUBE AREA  KWADUKUZA RURAL",
    Latitude: "-29.277",
    Longitude: "31.1991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13400",
    WardLookupId: "3849",
    VDNumber: "43581281",
    RegisteredPopulation: "575",
    VotingStationName: "ENSIKENI PRIMARY SCHOOL",
    Address: "EMBIZENI RESERVE  NODUNGA NO 1  KWADUKUZA RURAL",
    Latitude: "-29.3136",
    Longitude: "31.1949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13401",
    WardLookupId: "3850",
    VDNumber: "43582361",
    RegisteredPopulation: "390",
    VotingStationName: "OSIZWENI SCHOOL",
    Address: "P3 ISENEMBE ROAD  COMPENSATION  TONGAAT",
    Latitude: "-29.4482",
    Longitude: "31.1203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13402",
    WardLookupId: "3850",
    VDNumber: "43582822",
    RegisteredPopulation: "1095",
    VotingStationName: "COMPENSATION TRADING STORE",
    Address: "R102  COMPENSATION  DOLPHIN COAST",
    Latitude: "-29.492",
    Longitude: "31.1639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13403",
    WardLookupId: "3850",
    VDNumber: "43590067",
    RegisteredPopulation: "2425",
    VotingStationName: "SHAKASKRAAL SECONDARY SCHOOL",
    Address: "62 SCHOOL ROAD  SHAKASKRAAL  SHAKASKRAAL",
    Latitude: "-29.4468",
    Longitude: "31.2141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13404",
    WardLookupId: "3851",
    VDNumber: "43600283",
    RegisteredPopulation: "1018",
    VotingStationName: "GROUTVILLE SECONDARY SCHOOL",
    Address: "DUBE VILLAGE  GROUTVILLE  KWADUKUZA/STANGER",
    Latitude: "-29.399",
    Longitude: "31.2536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13405",
    WardLookupId: "3851",
    VDNumber: "43600306",
    RegisteredPopulation: "2299",
    VotingStationName: "AFRICAN GOSPEL CHURCH",
    Address: "NEXT TO GROUTVILLE STATION  DUBE VILLAGE  KWADUKUZA/STANGER",
    Latitude: "-29.4034",
    Longitude: "31.2609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13406",
    WardLookupId: "3851",
    VDNumber: "43600339",
    RegisteredPopulation: "1578",
    VotingStationName: "OLD APOSTOLIC CHURCH",
    Address: "CHARLOTTEDALE  KWADUKUZA",
    Latitude: "-29.385",
    Longitude: "31.2643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13407",
    WardLookupId: "1654",
    VDNumber: "43580998",
    RegisteredPopulation: "512",
    VotingStationName: "HULETT COMBINED PRIMARY SCHOOL",
    Address: "R74  DORING KOP  DORING KOP-MATHONSI TA",
    Latitude: "-29.2178",
    Longitude: "31.232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13408",
    WardLookupId: "1654",
    VDNumber: "43581270",
    RegisteredPopulation: "311",
    VotingStationName: "GLENMIL BABTIST CHURCH",
    Address:
      "GLENDALE AREA EZIMANGWENI AREA  EZIMANGWENI AREA  CELE NHLANGWINI",
    Latitude: "-29.2932",
    Longitude: "31.1586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13409",
    WardLookupId: "1654",
    VDNumber: "43581304",
    RegisteredPopulation: "401",
    VotingStationName: "PARUKABAD PRIMARY SCHOOL",
    Address:
      "GLENDALE AREA PARUKAPAD AREA  PARUKABAD EMSHININI KASHUKELA  PARUKABAD-EMSHINI KASHUKELA",
    Latitude: "-29.2831",
    Longitude: "31.1272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13410",
    WardLookupId: "1654",
    VDNumber: "43582080",
    RegisteredPopulation: "1001",
    VotingStationName: "SAKHUMUZI  PRIMARY SCHOOL",
    Address: "ENTUBENI AREA  NDWEDWE  CELE NHLANGWINI",
    Latitude: "-29.2175",
    Longitude: "31.1424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13411",
    WardLookupId: "1654",
    VDNumber: "43583788",
    RegisteredPopulation: "441",
    VotingStationName: "UBUHLEBEPHUPHO PRIMARY SCHOOL",
    Address: "ENHLANGWINI AREA PHEYANE AREA  PHEYANA AREA  CELE NHLANGWINI",
    Latitude: "-29.2459",
    Longitude: "31.1311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13412",
    WardLookupId: "1654",
    VDNumber: "43584475",
    RegisteredPopulation: "660",
    VotingStationName: "GLENDALE COMMUNITY HALL",
    Address:
      "EMSHININI KASHUKELA GLENDALE   GLENDALE   CELE NHLANGWINI,NHLANGWINI",
    Latitude: "-29.2956",
    Longitude: "31.1373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13413",
    WardLookupId: "1655",
    VDNumber: "43581461",
    RegisteredPopulation: "832",
    VotingStationName: "INSUZEGCWENZA COMBINED PRIMARY SCHOOL",
    Address: "NSUZEGCWENSA AREA   ENSUZE  GCWENSA TC",
    Latitude: "-29.3877",
    Longitude: "30.93",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13414",
    WardLookupId: "1655",
    VDNumber: "43581472",
    RegisteredPopulation: "542",
    VotingStationName: "AMABUTHO PRIMARY SCHOOL",
    Address: "INSUZE AREA AMABUTHO AREA  NDWEDWE  GCWENSA TC",
    Latitude: "-29.3674",
    Longitude: "30.9499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13415",
    WardLookupId: "1655",
    VDNumber: "43581483",
    RegisteredPopulation: "1534",
    VotingStationName: "DALIBO PRIMARY SCHOOL",
    Address: "KWAGCWENSA AREA DALIBHO AREA  NDWEDWE  GCWENSA TC",
    Latitude: "-29.3764",
    Longitude: "30.9041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13416",
    WardLookupId: "1655",
    VDNumber: "43581595",
    RegisteredPopulation: "281",
    VotingStationName: "GCWENSA HALL",
    Address: "EMANZAMNYAMA AREA GCWENSA HALL  EMANZAMNYAMA  GCWENSA TC",
    Latitude: "-29.4233",
    Longitude: "30.9289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13417",
    WardLookupId: "1655",
    VDNumber: "43581630",
    RegisteredPopulation: "469",
    VotingStationName: "WEWE PRIMARY SCHOOL",
    Address: "WEWE AREA OZWATHINI AREA  (NDWEDWE)  GCWENSA TC",
    Latitude: "-29.4121",
    Longitude: "30.9408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13418",
    WardLookupId: "1656",
    VDNumber: "43581539",
    RegisteredPopulation: "210",
    VotingStationName: "KWAPHIYOSE",
    Address:
      "KWAPHIYOSE AREA PHIYOSE FARM  KWAPHIYOSE AREA  MAGWAZA TRIBAL COURT",
    Latitude: "-29.3833",
    Longitude: "31.1186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13419",
    WardLookupId: "1656",
    VDNumber: "43581540",
    RegisteredPopulation: "314",
    VotingStationName: "ML SULTAN KRAANSKLOOF SCHOOL",
    Address: "ML SULTAN KRAANSLOOF AREA  NDWEDWE  MAGWAZA TRIBAL COURT",
    Latitude: "-29.3594",
    Longitude: "31.1199",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13420",
    WardLookupId: "1656",
    VDNumber: "43581573",
    RegisteredPopulation: "385",
    VotingStationName: "KRUISFONTEIN PRIMARY SCHOOL",
    Address: "MAGWAZA AREA KRUISIFONTEIN   NDWEDWE  QWABE WATERFALL TC",
    Latitude: "-29.4474",
    Longitude: "31.0578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13421",
    WardLookupId: "1656",
    VDNumber: "43582350",
    RegisteredPopulation: "1041",
    VotingStationName: "SIPHIWE SECONDARY SCHOOL",
    Address: "EMTHOMBISA AREA SIPHIWE AREA  NDWEDWE  QWABE WATERFALL TC",
    Latitude: "-29.4154",
    Longitude: "31.0565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13422",
    WardLookupId: "1656",
    VDNumber: "43583519",
    RegisteredPopulation: "602",
    VotingStationName: "QWABE COMMUNITY HALL",
    Address: "QWABE WATERFALL AREA  NDWEDWE  QWABE TRIBAL COURT",
    Latitude: "-29.3784",
    Longitude: "31.0392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13423",
    WardLookupId: "1656",
    VDNumber: "43583913",
    RegisteredPopulation: "739",
    VotingStationName: "GLENDALE PRIMARY SCHOOL",
    Address:
      "GLENDALE AREA, GLENDALE POLICE  GLENDALE ENHLANGWINI  CELE NHLANGWINI TC",
    Latitude: "-29.3247",
    Longitude: "31.124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13424",
    WardLookupId: "1656",
    VDNumber: "43584701",
    RegisteredPopulation: "149",
    VotingStationName: "MAGWAZA COMMUNITY HALL",
    Address: "N/A  MAGWAZA AREA  MAGWAZA TRIBAL COURT",
    Latitude: "-29.4599",
    Longitude: "31.0478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13425",
    WardLookupId: "1657",
    VDNumber: "43770652",
    RegisteredPopulation: "645",
    VotingStationName: "PHAMBELA PRIMARY SCHOOL",
    Address: "EZIKOTSHINI AREA MLWAMDLE AREA  NDWEDWE  MLAMULI TC",
    Latitude: "-29.28",
    Longitude: "31.0154",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13426",
    WardLookupId: "1657",
    VDNumber: "43770663",
    RegisteredPopulation: "777",
    VotingStationName: "ISITHELO-SETHU PRIMARY SCHOOL",
    Address: "MLAMULA   KWAMLAMULA AREA  MLAMULA TRIBAL AUTHORITY",
    Latitude: "-29.3162",
    Longitude: "31.0131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13427",
    WardLookupId: "1657",
    VDNumber: "43770731",
    RegisteredPopulation: "970",
    VotingStationName: "CHIBINI PRIMARY SCHOOL",
    Address: "CHIBINI AREA  BHAMSHELA  MLAMULA TRIBAL AUTHORITY",
    Latitude: "-29.2765",
    Longitude: "30.931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13428",
    WardLookupId: "1657",
    VDNumber: "43773576",
    RegisteredPopulation: "916",
    VotingStationName: "SION PRIMARY SCHOOL",
    Address:
      "MANDLAKAZI AREA NGCONGANGCONGA  NDWEDWE  MLAMULA TRIBAL AUTHORITY",
    Latitude: "-29.3352",
    Longitude: "30.9651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13429",
    WardLookupId: "1658",
    VDNumber: "43770685",
    RegisteredPopulation: "948",
    VotingStationName: "SIYAPHUMULA SECONDARY SCHOOL",
    Address: "SIYAPHUMALA  NEWS PAPER AREA  NDWEDWE  KWA MLAMULI TC",
    Latitude: "-29.33",
    Longitude: "30.9082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13430",
    WardLookupId: "1658",
    VDNumber: "43770753",
    RegisteredPopulation: "663",
    VotingStationName: "QALAKAHLE SECONDARY SCHOOL",
    Address: "DEDA  QALAKAHLE AREA  MLAMULA TRIBAL COUNCIL",
    Latitude: "-29.2875",
    Longitude: "30.9123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13431",
    WardLookupId: "1658",
    VDNumber: "43770764",
    RegisteredPopulation: "560",
    VotingStationName: "SOGIDI PRIMARY SCHOOL",
    Address: "SOGIDI AREA  NDWEDWE  MLAMULA TRIBAL AUTHORITY",
    Latitude: "-29.312",
    Longitude: "30.8889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13432",
    WardLookupId: "1658",
    VDNumber: "43770775",
    RegisteredPopulation: "1127",
    VotingStationName: "DEDA PRIMARY SCHOOL",
    Address: "KWADEDA AREA  NDWEDWE  MLAMULA TRABAL AUTHORITY",
    Latitude: "-29.3042",
    Longitude: "30.9027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13433",
    WardLookupId: "1658",
    VDNumber: "43770786",
    RegisteredPopulation: "621",
    VotingStationName: "NGAYIPHI PRIMARY SCHOOL",
    Address:
      "NGAYIPHI  NGCONGANGCONGA AREA  NOORDSBERG AREA  MLAMULA TRIBAL AUTHORITY",
    Latitude: "-29.3173",
    Longitude: "30.9215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13434",
    WardLookupId: "1659",
    VDNumber: "43581551",
    RegisteredPopulation: "1106",
    VotingStationName: "SIMUNYE  HIGH SCHOOL",
    Address: "SIMUNYE AREA  NDWEDWE  GCWENSA TC",
    Latitude: "-29.3591",
    Longitude: "30.915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13435",
    WardLookupId: "1659",
    VDNumber: "43581584",
    RegisteredPopulation: "366",
    VotingStationName: "SIYAZAKHA CRECHE",
    Address: "EMAHLABATHINI AREA  NDWEDWE  MLAMULI T/C",
    Latitude: "-29.3619",
    Longitude: "30.9393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13436",
    WardLookupId: "1659",
    VDNumber: "43770674",
    RegisteredPopulation: "397",
    VotingStationName: "QHUBAKAHLE PRIMARY SCHOOL",
    Address: "OQGOLWENI AREA  NDWEDWE  MLAMULA TRIBAL AUTHORITY",
    Latitude: "-29.358",
    Longitude: "30.8917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13437",
    WardLookupId: "1659",
    VDNumber: "43770696",
    RegisteredPopulation: "1499",
    VotingStationName: "NOODSBERG PRIMARY  SCHOOL",
    Address: "NEWS PAPER EKHO AREA  NDWEDWE  MLAMULA TRIBAL AUTHORITY",
    Latitude: "-29.3406",
    Longitude: "30.8981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13438",
    WardLookupId: "1660",
    VDNumber: "43581629",
    RegisteredPopulation: "669",
    VotingStationName: "MARY GREY PRIMARY SCHOOL",
    Address: "MARY GRAY AREA  NDWEDWE  NKUMBANYUSWA TRIBAL COURT",
    Latitude: "-29.3608",
    Longitude: "30.9807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13439",
    WardLookupId: "1660",
    VDNumber: "43582192",
    RegisteredPopulation: "185",
    VotingStationName: "NJUBANJUBA SECONDARY  SCHOOL",
    Address: "KWALUTHULI ARERA  NDWEDWE  LUTHULI T.C.",
    Latitude: "-29.3349",
    Longitude: "31.0566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13440",
    WardLookupId: "1660",
    VDNumber: "43582204",
    RegisteredPopulation: "306",
    VotingStationName: "GOGOVUMA PRIMARY SCHOOL",
    Address: "GOGOVUMA AREA  NDWEDWE  LUTHULI TC",
    Latitude: "-29.3214",
    Longitude: "31.0449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13441",
    WardLookupId: "1660",
    VDNumber: "43582349",
    RegisteredPopulation: "622",
    VotingStationName: "LUTHULI COURT HOUSE",
    Address: "1 KWALUTHULI AREA  NDWEDWE  LUTHULI T.C.",
    Latitude: "-29.3475",
    Longitude: "31.0543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13442",
    WardLookupId: "1660",
    VDNumber: "43582732",
    RegisteredPopulation: "476",
    VotingStationName: "HLALAKAHLE PRIMARY SCHOOL",
    Address: "KWATHAYELA HLALAKAHLE AREA  NDWEDWE  NKUMBANYUSWA TRIBAL COURT",
    Latitude: "-29.3309",
    Longitude: "31.0058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13443",
    WardLookupId: "1660",
    VDNumber: "43583924",
    RegisteredPopulation: "739",
    VotingStationName: "ZAKHELE PRIMARY SCHOOL",
    Address: "ZAKHELE AREA  EZAKHELE AREA  NKUMBA-NYUSWA TRIBAL",
    Latitude: "-29.3623",
    Longitude: "31.011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13444",
    WardLookupId: "1661",
    VDNumber: "43580842",
    RegisteredPopulation: "518",
    VotingStationName: "MCATHU PRIMARY SCHOOL",
    Address: "EMCATHU EMATHOLEAMPEVU AREA  NDWEDWE  NKUMBANYUSWA TRIBAL COURT",
    Latitude: "-29.4253",
    Longitude: "30.9904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13445",
    WardLookupId: "1661",
    VDNumber: "43581438",
    RegisteredPopulation: "401",
    VotingStationName: "MAPHOLOBA PRIMARY SCHOOL",
    Address: "INSUZE AREA  NDWEDWE  KWAGWENSA T.C.",
    Latitude: "-29.4111",
    Longitude: "30.9716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13446",
    WardLookupId: "1661",
    VDNumber: "43581618",
    RegisteredPopulation: "1952",
    VotingStationName: "QINISANI HIGH SCHOOL",
    Address: "KWAMAZIBUKO QINISANI AREA  NDWEDWE  NKUMBANYUSWA TRIBAL COURT",
    Latitude: "-29.3882",
    Longitude: "30.987",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13447",
    WardLookupId: "1661",
    VDNumber: "43584161",
    RegisteredPopulation: "517",
    VotingStationName: "ESIDUMBINI PRIMARY SCHOOL",
    Address: "ESIDUMBINI AREA  NDWEDWE  NKUMBANYUSWA TRIBAL COURT",
    Latitude: "-29.3816",
    Longitude: "31.002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13448",
    WardLookupId: "1661",
    VDNumber: "43584172",
    RegisteredPopulation: "486",
    VotingStationName: "EMSENGENI PRIMARY SCHOOL",
    Address: "EMSENGENI OZWATHINI AREA  NDWEDWE  KWAGCWENSA TC",
    Latitude: "-29.3959",
    Longitude: "30.9501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13449",
    WardLookupId: "1662",
    VDNumber: "43581674",
    RegisteredPopulation: "765",
    VotingStationName: "SOTOBE HIGH SCHOOL",
    Address: "SOTOBE OZWATHINI AREA  NDWEDWE   NODWENGU TC",
    Latitude: "-29.4354",
    Longitude: "30.8783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13450",
    WardLookupId: "1662",
    VDNumber: "43581708",
    RegisteredPopulation: "460",
    VotingStationName: "MAYELESWENI LOWER PRIMARY SCHOOL",
    Address: "MAYELISWENI NHLANGAZI AREA  NDWEDWE   NODWENGU TC",
    Latitude: "-29.4513",
    Longitude: "30.8406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13451",
    WardLookupId: "1662",
    VDNumber: "43581719",
    RegisteredPopulation: "1297",
    VotingStationName: "MONTOBELLO HOSPITAL",
    Address: "MONTOBELO AREA  NDWEDWE  NODWENGU TC",
    Latitude: "-29.4394",
    Longitude: "30.8096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13452",
    WardLookupId: "1662",
    VDNumber: "43581720",
    RegisteredPopulation: "1253",
    VotingStationName: "NONDABULA PRIMARY SCHOOL",
    Address: "NONDABULA OZWATHINI AREA  NDWEDWE  NODWENGU TC",
    Latitude: "-29.4071",
    Longitude: "30.853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13453",
    WardLookupId: "1662",
    VDNumber: "43584486",
    RegisteredPopulation: "275",
    VotingStationName: "ESIBHENGWINI HALL",
    Address: "ESIBHENGWINI AREA  NDWEDWE  NODWENGU TRIBAL",
    Latitude: "-29.4268",
    Longitude: "30.824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13454",
    WardLookupId: "1663",
    VDNumber: "43581517",
    RegisteredPopulation: "938",
    VotingStationName: "MAGONGOLO PRIMARY SCHOOL",
    Address: "EMAGONGOLO AREA  NDWEDWE  NKUMBANYUSWA TC",
    Latitude: "-29.4576",
    Longitude: "30.969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13455",
    WardLookupId: "1663",
    VDNumber: "43581652",
    RegisteredPopulation: "580",
    VotingStationName: "MAQOKOMELA PRIMARY SCHOOL",
    Address: "MAQOKOMELA OZWATHIN AREA  NDWEDWE  NODWENGU TRIBAL AUTHORITY",
    Latitude: "-29.4596",
    Longitude: "30.9024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13456",
    WardLookupId: "1663",
    VDNumber: "43581663",
    RegisteredPopulation: "736",
    VotingStationName: "NTABASKOP LOWER PRIMARY SCHOOL",
    Address: "NTABASKOP AREA  NDWEDWE  KWA NKUMBANYUSWA TC",
    Latitude: "-29.4412",
    Longitude: "30.9331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13457",
    WardLookupId: "1663",
    VDNumber: "43582675",
    RegisteredPopulation: "569",
    VotingStationName: "DUBEYANA PRIMARY SCHOOL",
    Address: "DUBEYANA AREA  NDWEDWE  NKUMBANYUSWA TC",
    Latitude: "-29.4403",
    Longitude: "30.968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13458",
    WardLookupId: "1663",
    VDNumber: "43583430",
    RegisteredPopulation: "633",
    VotingStationName: "DIKWAYO PRIMARY SCHOOL",
    Address: "KWADIKWAYO AREA  NDWEDWE  NKUMBANYUSWA TC",
    Latitude: "-29.4761",
    Longitude: "30.9388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13459",
    WardLookupId: "1663",
    VDNumber: "43584677",
    RegisteredPopulation: "169",
    VotingStationName: "MASIMDUMISE SCHOOL",
    Address: "MASIMDUMISE KWASIMELANE AREA  KWASIMELANE AREA  NKUMBANYUSWA TC",
    Latitude: "-29.4821",
    Longitude: "30.9752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13460",
    WardLookupId: "1664",
    VDNumber: "43581562",
    RegisteredPopulation: "551",
    VotingStationName: "NTATHAKUSA PRIMARY SCHOOL",
    Address: "MATHOLAMNYAMA NTATHAKUSA AREA  NDWEDWE  QADI T/C",
    Latitude: "-29.4552",
    Longitude: "31.0144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13461",
    WardLookupId: "1664",
    VDNumber: "43582653",
    RegisteredPopulation: "1044",
    VotingStationName: "MANQONDO PRIMARY SCHOOL",
    Address: "SONKOMBO MANQONDO AREA  NDWEDWE  ILEMBE",
    Latitude: "-29.4832",
    Longitude: "30.9953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13462",
    WardLookupId: "1664",
    VDNumber: "43582664",
    RegisteredPopulation: "415",
    VotingStationName: "MAYIKA PRIMARY SCHOOL",
    Address: "OKHALWENI AREA  QADI TRIBAL COUNCIL",
    Latitude: "-29.4617",
    Longitude: "31.034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13463",
    WardLookupId: "1664",
    VDNumber: "43582686",
    RegisteredPopulation: "639",
    VotingStationName: "MASHIYAMAHLE SECONDARY SCHOOL",
    Address: "MWOLOKOHLO EZIBOMVININ AREA  NDWEDWE   NKUMBANYUSWA-TC",
    Latitude: "-29.4995",
    Longitude: "31.0196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13464",
    WardLookupId: "1664",
    VDNumber: "43582697",
    RegisteredPopulation: "919",
    VotingStationName: "EMLANDELENI PRIMARY SCHOOL",
    Address: "MWOLOKOHLO EZIBOMVININ AREA  NDWEDWE  QADI",
    Latitude: "-29.4952",
    Longitude: "31.0307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13465",
    WardLookupId: "1664",
    VDNumber: "43583935",
    RegisteredPopulation: "650",
    VotingStationName: "AFRICAN GOSPEL CHURCH",
    Address: "KWANGCAMU  ISIGUDU AREA  NDWEDWE  NKUMBA-NYUSWA TC",
    Latitude: "-29.496",
    Longitude: "31.0002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13466",
    WardLookupId: "1665",
    VDNumber: "43581494",
    RegisteredPopulation: "560",
    VotingStationName: "IKHOHLWA PRIMARY SCHOOL",
    Address: "MONA IKHOHLWA AREA  NDWEDWE  MAVELA T/A",
    Latitude: "-29.542",
    Longitude: "31.0468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13467",
    WardLookupId: "1665",
    VDNumber: "43582798",
    RegisteredPopulation: "1036",
    VotingStationName: "ENKWAMBASE SCHOOL",
    Address: "MONA NDONDOLO AREA  NDWEDWE  QADI TC",
    Latitude: "-29.5339",
    Longitude: "31.0673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13468",
    WardLookupId: "1665",
    VDNumber: "43582990",
    RegisteredPopulation: "787",
    VotingStationName: "HLONIPHANI SCHOOL",
    Address: "MONA NDONDOLO AREA  NDWEDWE  QWABE TC",
    Latitude: "-29.5393",
    Longitude: "31.0544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13469",
    WardLookupId: "1665",
    VDNumber: "43583946",
    RegisteredPopulation: "793",
    VotingStationName: "MGEZENGWANE",
    Address: "N/A  MONA MGEZENGWANE  QWABE TRIBAL AUTHORITY",
    Latitude: "-29.5232",
    Longitude: "31.0452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13470",
    WardLookupId: "1665",
    VDNumber: "43584497",
    RegisteredPopulation: "734",
    VotingStationName: "MAKHENI PRIMARY SCHOOL",
    Address: "MONA EZIBOMVINI AREA  NDWEDWE   QADI TRIBAL",
    Latitude: "-29.5163",
    Longitude: "31.0416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13471",
    WardLookupId: "1665",
    VDNumber: "43584510",
    RegisteredPopulation: "951",
    VotingStationName: "UTHANDO CHRISTIAN CHURCH",
    Address: "MONA MGANWINI AREA  NDWEDWE  QADI AREA",
    Latitude: "-29.5316",
    Longitude: "31.084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13472",
    WardLookupId: "1666",
    VDNumber: "43580718",
    RegisteredPopulation: "777",
    VotingStationName: "UMSUNDUZE PRIMARY SCHOOL SCHOOL",
    Address: "MSUNDUZE RESERVE AREA  NDWEDWE  NKUMBA-NYUSWA TRIBAL",
    Latitude: "-29.5198",
    Longitude: "30.9935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13473",
    WardLookupId: "1666",
    VDNumber: "43581999",
    RegisteredPopulation: "995",
    VotingStationName: "MAKHAWULA PRIMARY",
    Address: "KWAZINI MAKHAWULA AREA  NDWEDWE  QADI T/C",
    Latitude: "-29.5725",
    Longitude: "30.959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13474",
    WardLookupId: "1666",
    VDNumber: "43582709",
    RegisteredPopulation: "1111",
    VotingStationName: "LIHLE ITHEMBA TECHNICAL HIGH SCHOOL",
    Address: "UMSUNDUZE AREA  NDWEDWEMSUNDUZI AREA  NKUMBA-NYUSWA TRIBAL",
    Latitude: "-29.5233",
    Longitude: "31.0068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13475",
    WardLookupId: "1666",
    VDNumber: "43582754",
    RegisteredPopulation: "1222",
    VotingStationName: "NQAKATHELA SECONDARY SCHOOL",
    Address: "ESDULINI NTAPHUKA AREA  NDWEDWE  MAVELA TC",
    Latitude: "-29.5547",
    Longitude: "30.9647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13476",
    WardLookupId: "1666",
    VDNumber: "43583968",
    RegisteredPopulation: "536",
    VotingStationName: "MASIBAMBANE CRECHE",
    Address: "1516 NTAPHUKA SUKUMANI AREA  NDWEDWE  NKUMBA-NYUSWA TC",
    Latitude: "-29.5399",
    Longitude: "31.0006",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13477",
    WardLookupId: "1667",
    VDNumber: "43582529",
    RegisteredPopulation: "706",
    VotingStationName: "PENTECOSTAL HALL",
    Address: "KWABHANOYI OAKFORD AREA  NDWEDWE  MAVELA TRIBAL",
    Latitude: "-29.5731",
    Longitude: "31.0061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13478",
    WardLookupId: "1667",
    VDNumber: "43582530",
    RegisteredPopulation: "925",
    VotingStationName: "EZINDLOVINI PRIMARY SCHOOL",
    Address: "MAIN ROAD EZINDLOVINI  NDWEDWE  MAVELA TC",
    Latitude: "-29.5417",
    Longitude: "31.0165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13479",
    WardLookupId: "1667",
    VDNumber: "43582541",
    RegisteredPopulation: "602",
    VotingStationName: "KWANOMPANDE  PRIMARY SCHOOL",
    Address: "N/A KWAPOMU NTAPHUKA AREA  NDWEDWE  MAVELA TC",
    Latitude: "-29.5567",
    Longitude: "30.9929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13480",
    WardLookupId: "1667",
    VDNumber: "43582585",
    RegisteredPopulation: "253",
    VotingStationName: "NDODEMBI SCHOOL",
    Address: "MSHAYANYOKA OAKFORD AREA  NDWEDWE  QADI TC",
    Latitude: "-29.5827",
    Longitude: "30.9943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13481",
    WardLookupId: "1667",
    VDNumber: "43583957",
    RegisteredPopulation: "1351",
    VotingStationName: "METHODIST CHURCH",
    Address: "EZITALUKENI MTHEBENI AREA  EMTHEBENI AREA  MAVELA TC",
    Latitude: "-29.5508",
    Longitude: "31.0205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13482",
    WardLookupId: "1667",
    VDNumber: "43584509",
    RegisteredPopulation: "624",
    VotingStationName: "TENT AT ROMAN CATHOLIC CHURCH",
    Address: "KONKOSI  QWABE TRIBAL AUTHORITY",
    Latitude: "-29.5317",
    Longitude: "31.0287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13483",
    WardLookupId: "1668",
    VDNumber: "43581450",
    RegisteredPopulation: "880",
    VotingStationName: "DUMEZWENI SCHOOL",
    Address: "DUMEZWENI MAYEKENI AREA   NDWEDWE  MAVELA TC",
    Latitude: "-29.5105",
    Longitude: "30.957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13484",
    WardLookupId: "1668",
    VDNumber: "43581854",
    RegisteredPopulation: "1198",
    VotingStationName: "JONNY MAKHATHINI CIVIC CENTRE",
    Address: "P100 NDWEDWE CENTRAL  NDWEDWE   QADI T/C",
    Latitude: "-29.5161",
    Longitude: "30.934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13485",
    WardLookupId: "1668",
    VDNumber: "43582574",
    RegisteredPopulation: "455",
    VotingStationName: "MTHUKUTHELI PRIMARY SCHOOL",
    Address: "KWAMZOBE MONA AREA  NDWEDWE  MAVELA TC",
    Latitude: "-29.5008",
    Longitude: "30.9667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13486",
    WardLookupId: "1668",
    VDNumber: "43582710",
    RegisteredPopulation: "871",
    VotingStationName: "UMZOKHULAYO JUNIOR PRIMARY SCHOOL",
    Address: "UMZOKHULAYO AREA  NDWEDWE  NKUMBA-NYUSWA TC",
    Latitude: "-29.519",
    Longitude: "30.9791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13487",
    WardLookupId: "1668",
    VDNumber: "43582743",
    RegisteredPopulation: "1103",
    VotingStationName: "GASELA COMBINED PRIMARY SCHOOL",
    Address: "NTAPHUKA MPUNGENI AREA  NDWEDWE  NKUMBA-NYUSWA TC",
    Latitude: "-29.5281",
    Longitude: "30.9475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13488",
    WardLookupId: "1669",
    VDNumber: "43581405",
    RegisteredPopulation: "205",
    VotingStationName: "NTENDENI PRIMARY SCHOOL",
    Address: "EBKHWEBEZI WOSIYANA ARAE  NDWEDWE  WOSIYANA TC",
    Latitude: "-29.5175",
    Longitude: "30.8144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13489",
    WardLookupId: "1669",
    VDNumber: "43581797",
    RegisteredPopulation: "343",
    VotingStationName: "GWENI LOWER PRIMARY SCHOOL",
    Address: "WOSIYANA NHLANGAKAZI AREA  NDWEDWE  WOSIYANA TC",
    Latitude: "-29.4843",
    Longitude: "30.8115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13490",
    WardLookupId: "1669",
    VDNumber: "43581821",
    RegisteredPopulation: "667",
    VotingStationName: "MADUNA COMBINED PRIMARY SCHOOL",
    Address: "N/A  NDWEDWE  ILEMBE",
    Latitude: "-29.5214",
    Longitude: "30.7454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13491",
    WardLookupId: "1669",
    VDNumber: "43581832",
    RegisteredPopulation: "286",
    VotingStationName: "KWAMKHUMBUZI PRIMARY SCHOOL",
    Address: "EHHOQWENI AREA  NDWEDWE  MALANGENI TC",
    Latitude: "-29.4731",
    Longitude: "30.7522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13492",
    WardLookupId: "1669",
    VDNumber: "43581887",
    RegisteredPopulation: "598",
    VotingStationName: "GCINIMFUNDO SECONDARY SCHOOL",
    Address: "MSILILE MALANGENI AREA  NDWEDWE  MALANGENI TC",
    Latitude: "-29.5431",
    Longitude: "30.7424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13493",
    WardLookupId: "1669",
    VDNumber: "43581900",
    RegisteredPopulation: "490",
    VotingStationName: "WOSIYANA HIGH SCHOOL",
    Address: "NOBANGA WOSIYANA AREA  NDWEDWE  WOSIYANE TC",
    Latitude: "-29.5462",
    Longitude: "30.8062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13494",
    WardLookupId: "1669",
    VDNumber: "43581911",
    RegisteredPopulation: "617",
    VotingStationName: "EMAKHASINI PRIMARY SCHOOL",
    Address: "NGOZA CIBANE AREA  KWA NGOZA  CIBANE TC",
    Latitude: "-29.5562",
    Longitude: "30.7714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13495",
    WardLookupId: "1669",
    VDNumber: "43581922",
    RegisteredPopulation: "421",
    VotingStationName: "SONTSHENGE PRIMARY SCHOOL",
    Address: "SONTSHENGE MALANGENI AREA  NDWEDWE  MALANGENI TC",
    Latitude: "-29.5321",
    Longitude: "30.7717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13496",
    WardLookupId: "1670",
    VDNumber: "43581337",
    RegisteredPopulation: "595",
    VotingStationName: "ISAVIYO PRIMARY SCHOOL",
    Address: "MARWENGULA GAWULA AREA  NDWEDWE  CHILI TC",
    Latitude: "-29.6068",
    Longitude: "30.86",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13497",
    WardLookupId: "1670",
    VDNumber: "43581348",
    RegisteredPopulation: "407",
    VotingStationName: "EMANYONINI PRIMARY SCHOOL",
    Address: "MANYONINI KWASHNGASE AREA  NDWEDWE   KWASHANGASE TC",
    Latitude: "-29.5884",
    Longitude: "30.8442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13498",
    WardLookupId: "1670",
    VDNumber: "43581382",
    RegisteredPopulation: "495",
    VotingStationName: "EZIMPONDWENI PRIMARY SCHOOL",
    Address: "EZIMPONDWENI AREA  NDWEDWE  KWASHANGASE TC",
    Latitude: "-29.5671",
    Longitude: "30.8059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13499",
    WardLookupId: "1670",
    VDNumber: "43581944",
    RegisteredPopulation: "584",
    VotingStationName: "MSHIYANE SECONDARY SCHOOL",
    Address: "MBUYENI MSHIYANE AREA  NDWEDWE  SHANGASE TC",
    Latitude: "-29.5778",
    Longitude: "30.862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13500",
    WardLookupId: "1670",
    VDNumber: "43581977",
    RegisteredPopulation: "834",
    VotingStationName: "DLABAZANE HIGHER PRIMARY SCHOOL",
    Address: "MEPHO UMDLOTI AREA   NDWEDWE  NGCOLOSI TC",
    Latitude: "-29.5825",
    Longitude: "30.8867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13501",
    WardLookupId: "1670",
    VDNumber: "43583643",
    RegisteredPopulation: "488",
    VotingStationName: "KHUMALO TRIBAL COURT",
    Address: "KWABHULUSHE MATATA AREA  NDWEDWE  KHUMALO T/A",
    Latitude: "-29.6182",
    Longitude: "30.8213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13502",
    WardLookupId: "1670",
    VDNumber: "43584521",
    RegisteredPopulation: "321",
    VotingStationName: "INHLOSANA CRECHE",
    Address: "NGCOLOSI NHLOSANA AREA  NDWEDWE  KWA NGCOLOSI TC",
    Latitude: "-29.5782",
    Longitude: "30.898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13503",
    WardLookupId: "1670",
    VDNumber: "43584688",
    RegisteredPopulation: "177",
    VotingStationName: "MEME PRIMARY SCHOOL",
    Address: "MEME KHUMALO AREA  NDWEDWE  KHUMALO TC",
    Latitude: "-29.6",
    Longitude: "30.8219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13504",
    WardLookupId: "1670",
    VDNumber: "43584699",
    RegisteredPopulation: "200",
    VotingStationName: "DULINI PRIMARY SCHOOL",
    Address: "KWASHANGASE DULINI AREA  NDWEDWE  SHANGASE T C",
    Latitude: "-29.5623",
    Longitude: "30.8546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13505",
    WardLookupId: "1671",
    VDNumber: "43581876",
    RegisteredPopulation: "762",
    VotingStationName: "THAFAMASI PRIMARY SCHOOL",
    Address: "THAFAMASI KWASHANGASE AREA  NDWEDWE  SHANGASE TC",
    Latitude: "-29.5359",
    Longitude: "30.8534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13506",
    WardLookupId: "1671",
    VDNumber: "43581898",
    RegisteredPopulation: "425",
    VotingStationName: "SIQONDOKUHLE PRIMARY SCHOOL",
    Address: "N/A  EMAKHULUSENI AREA  QADI TRIBAL COURT",
    Latitude: "-29.5462",
    Longitude: "30.8962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13507",
    WardLookupId: "1671",
    VDNumber: "43581933",
    RegisteredPopulation: "599",
    VotingStationName: "KWA SHANGASE PRIMARY SCHOOL",
    Address: "NSINGWENI AREA  NDWEDWE  KWA SHANGASE TRIBAL",
    Latitude: "-29.5475",
    Longitude: "30.8739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13508",
    WardLookupId: "1671",
    VDNumber: "43581955",
    RegisteredPopulation: "1331",
    VotingStationName: "MADLOKOVU PRIMARY SCHOOL",
    Address: "KWAZINI NDWEDWE MISSION AREA  NDWEDWE  QADI TC",
    Latitude: "-29.5656",
    Longitude: "30.9254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13509",
    WardLookupId: "1671",
    VDNumber: "43583025",
    RegisteredPopulation: "726",
    VotingStationName: "KWANOKUSHO COMBINED PRIMARY SCHOOL",
    Address: "NEW VILLAGE MISSION  NDWEDWE  QADI AREA",
    Latitude: "-29.5308",
    Longitude: "30.9137",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13510",
    WardLookupId: "1672",
    VDNumber: "43581427",
    RegisteredPopulation: "517",
    VotingStationName: "INHLANGANISO PRIMARY SCHOOL",
    Address: "SIMAMANE AREA  NDWEDWE   KWAHLOPHE TC",
    Latitude: "-29.5085",
    Longitude: "30.897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13511",
    WardLookupId: "1672",
    VDNumber: "43581449",
    RegisteredPopulation: "468",
    VotingStationName: "VULELA PRIMARY SCHOOL",
    Address: "MKHUKHUZE AREA  NDWEDWE  QADI TC",
    Latitude: "-29.5276",
    Longitude: "30.8925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13512",
    WardLookupId: "1672",
    VDNumber: "43581731",
    RegisteredPopulation: "878",
    VotingStationName: "KWA-SIMAMANE PRIMARY SCHOOL",
    Address: "SIMAMANE AREA  NDWEDWE  KWANYUSWA TC",
    Latitude: "-29.4865",
    Longitude: "30.8985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13513",
    WardLookupId: "1672",
    VDNumber: "43581742",
    RegisteredPopulation: "704",
    VotingStationName: "ESIGEDLENI PRIMARY SCHOOL",
    Address: "ESIGEDLENI AREA  NDWEDWE  NKUMBA-NYUSWA TC",
    Latitude: "-29.4651",
    Longitude: "30.8669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13514",
    WardLookupId: "1672",
    VDNumber: "43581753",
    RegisteredPopulation: "353",
    VotingStationName: "BHEKAMAFA COMBINED PRIMARY SCHOOL",
    Address: "MANGANGENI MAIN ROAD  BHEKAMAFA AREA  NKUMBA-NYUSWA TRIBAL",
    Latitude: "-29.4899",
    Longitude: "30.8425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13515",
    WardLookupId: "1672",
    VDNumber: "43581809",
    RegisteredPopulation: "291",
    VotingStationName: "MANTINGWANE COMBINED PRIMARY SCHOOL",
    Address: "N/A  NDWEDWE  ILEMBE",
    Latitude: "-29.5039",
    Longitude: "30.8733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13516",
    WardLookupId: "1672",
    VDNumber: "43581865",
    RegisteredPopulation: "460",
    VotingStationName: "VUMAZONKE TRIBAL COURT",
    Address: "MANGANGENI AREA  VUMAZONKE TRIBAL COUNCIL",
    Latitude: "-29.5194",
    Longitude: "30.8672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13517",
    WardLookupId: "1672",
    VDNumber: "43582721",
    RegisteredPopulation: "591",
    VotingStationName: "ZUBANE  COMBINED PRIMARY SCHOOL",
    Address: "NSIMBINI ROAD   NKALANKULU AREA  NKUMBA-NYUSWA TRIBAL",
    Latitude: "-29.4951",
    Longitude: "30.9277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13518",
    WardLookupId: "1672",
    VDNumber: "43584408",
    RegisteredPopulation: "132",
    VotingStationName: "MAYELISWENI (TENT)",
    Address: "NHLANGAKAZI AREA  NDWEDWE  WOSIYANA TRIBAL COURT",
    Latitude: "-29.4669",
    Longitude: "30.8424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13519",
    WardLookupId: "1673",
    VDNumber: "43770056",
    RegisteredPopulation: "606",
    VotingStationName: "EWOSI-VULEKA PRIMARY SCHOOL",
    Address: "P15 EWOSI NEXT TO LUTHERAN CHURCH  KRANSKOP  AMANGCOLOSI",
    Latitude: "-28.9173",
    Longitude: "30.9867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13520",
    WardLookupId: "1673",
    VDNumber: "43770191",
    RegisteredPopulation: "1045",
    VotingStationName: "UNTUNJAMBILI PRIMARY SCHOOL",
    Address: "UNTUNJAMBILI AREA NEXT TO HOSP  KRANSKOP  MAPUMULO",
    Latitude: "-28.9399",
    Longitude: "30.9447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13521",
    WardLookupId: "1673",
    VDNumber: "43773790",
    RegisteredPopulation: "1412",
    VotingStationName: "NGCOLOSI HIGH SCHOOL",
    Address:
      "NEXT TO UNTUNJAMBILI HOSPITAL   UNTUNJAMBILI AREA  KRANSKOP/AMANGCOLOSI TRIBAL AUTHORITY",
    Latitude: "-28.9454",
    Longitude: "30.9437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13522",
    WardLookupId: "1673",
    VDNumber: "43774522",
    RegisteredPopulation: "650",
    VotingStationName: "JEHOVA NGIHLENGE CRECHE",
    Address: "OPPOSITE BATA SHOE FACTORY  KRANSKOP  MAPHUMULO",
    Latitude: "-28.9342",
    Longitude: "30.9367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13523",
    WardLookupId: "1673",
    VDNumber: "43775129",
    RegisteredPopulation: "462",
    VotingStationName: "QUBUKA CRECHE",
    Address:
      "NTUNJAMBILI  AMANGCOLOSI NEXT TO KHUMBULANI LUTHERAN CHURCH  KRANSKOP",
    Latitude: "-28.9432",
    Longitude: "30.9288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13524",
    WardLookupId: "1673",
    VDNumber: "43775646",
    RegisteredPopulation: "160",
    VotingStationName: "EKHATHA PRIMARY SCHOOL",
    Address: "NTUNJAMBILI  KRANSKOP  AMANGCOLOSI",
    Latitude: "-28.9453",
    Longitude: "30.973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13525",
    WardLookupId: "1674",
    VDNumber: "43770012",
    RegisteredPopulation: "348",
    VotingStationName: "HLANGABEZA HIGH SCHOOL",
    Address: "KHOHLWA AREA NEXT TUKELA RIVER  KRANSKOP  MAPHUMULO",
    Latitude: "-28.8971",
    Longitude: "31.0233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13526",
    WardLookupId: "1674",
    VDNumber: "43770023",
    RegisteredPopulation: "312",
    VotingStationName: "MANDLALATHI PRIMARY SCHOOL",
    Address:
      "KWAVUMBU AREA  NGCOLOSI AREA NEXT TO MANDLALATHI CLINIC  KRANSKOP",
    Latitude: "-28.8807",
    Longitude: "30.9831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13527",
    WardLookupId: "1674",
    VDNumber: "43770034",
    RegisteredPopulation: "445",
    VotingStationName: "THETHANDABA PRIMARY SCHOOL",
    Address:
      "OSHIKISHINI AREA  KRANSKOP  MAPHUMULO AMANGCOLOSI TRIBAL AUTHORITY",
    Latitude: "-28.8946",
    Longitude: "30.9384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13528",
    WardLookupId: "1674",
    VDNumber: "43770067",
    RegisteredPopulation: "438",
    VotingStationName: "HLUPHIZWE  PRIMAY SCHOOL",
    Address: "EWOSI AREA ALONG D881 ROAD  KRANSKOP  MGCOLOSI TRIBAL AUTHORITY",
    Latitude: "-28.9193",
    Longitude: "31.0184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13529",
    WardLookupId: "1674",
    VDNumber: "43770078",
    RegisteredPopulation: "620",
    VotingStationName: "NOKUBUSA PRIMARY SCHOOL",
    Address: "MAMBULU AREA ALONG D881 ROAD  KRANSKOP  MAPHUMULO",
    Latitude: "-28.975",
    Longitude: "31.0338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13530",
    WardLookupId: "1674",
    VDNumber: "43770089",
    RegisteredPopulation: "485",
    VotingStationName: "SONDOKHULU PRIMARY SCHOOL",
    Address: "MAMBULU AREA ALONG D881 ROAD  KRANSKOP  MAPHUMULO",
    Latitude: "-28.9876",
    Longitude: "31.0625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13531",
    WardLookupId: "1674",
    VDNumber: "43770146",
    RegisteredPopulation: "346",
    VotingStationName: "DINGIZWE COMBINED PRIMARY  SCHOOL",
    Address: "MAHLONGWA AREA   KRANSKOP  MAPHUMULO",
    Latitude: "-29.0329",
    Longitude: "31.0759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13532",
    WardLookupId: "1674",
    VDNumber: "43770179",
    RegisteredPopulation: "453",
    VotingStationName: "ENDANDATHWENI PRIMARY SCHOOL",
    Address: "EKHATHA AREA ALONG KHATHA ROAD  KRANSKOP  MAPUMULO",
    Latitude: "-28.9677",
    Longitude: "30.9953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13533",
    WardLookupId: "1674",
    VDNumber: "43770180",
    RegisteredPopulation: "589",
    VotingStationName: "MAMBULU PRIMARY SCHOOL",
    Address: "MAMBULU AREA (ESIWENI AREA)  KRANSKOP  MAPHUMULO",
    Latitude: "-28.9942",
    Longitude: "31.0118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13534",
    WardLookupId: "1674",
    VDNumber: "43773813",
    RegisteredPopulation: "197",
    VotingStationName: "ESESE LOWER PRIMARY SCHOOL",
    Address: "MAHLONGWA AREA   MAPHUMULO  KRANSKOP",
    Latitude: "-29.0297",
    Longitude: "31.0284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13535",
    WardLookupId: "1674",
    VDNumber: "43775130",
    RegisteredPopulation: "330",
    VotingStationName: "NOPHUNGWA PRIMARY SCHOOL",
    Address: "KWAVUMBU AREA   ILEMBE  NGCOLOSI",
    Latitude: "-28.8742",
    Longitude: "31.0243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13536",
    WardLookupId: "1675",
    VDNumber: "43581012",
    RegisteredPopulation: "283",
    VotingStationName: "ESIHLUSHWANENI COMBINED SCHOOL",
    Address: "ESIHLUSHWANENI AREA ALONG D881  MAPHUMULO 38  MAPHUMULO",
    Latitude: "-29.0615",
    Longitude: "31.0807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13537",
    WardLookupId: "1675",
    VDNumber: "43581023",
    RegisteredPopulation: "184",
    VotingStationName: "EMBUSWENI PRIMARY SCHOOL",
    Address: "WOZA AREA ALONG TSHOBHO ROAD  MAPHUMULO 39  MAPUMULO",
    Latitude: "-29.0865",
    Longitude: "31.1387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13538",
    WardLookupId: "1675",
    VDNumber: "43581045",
    RegisteredPopulation: "757",
    VotingStationName: "JONASE HIGH  SCHOOL",
    Address: "EKUNQOBENI AREA ALONG D1536 RO  MAPHUMULO 35  MAPHUMULO",
    Latitude: "-29.1135",
    Longitude: "31.104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13539",
    WardLookupId: "1675",
    VDNumber: "43583632",
    RegisteredPopulation: "222",
    VotingStationName: "MBITANE PRIMARY SCHOOL",
    Address: "MBITANE AREA ALONG D881 ROAD  ILEMBE   MAPUMULO",
    Latitude: "-29.1079",
    Longitude: "31.0391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13540",
    WardLookupId: "1675",
    VDNumber: "43583799",
    RegisteredPopulation: "202",
    VotingStationName: "MBULWINI PRIMARY SCHOOL",
    Address:
      "D1534 KWANODUNGA AREA D1534  MBULWINI AREA  MAPHUMULO/NODUNGA TRIBAL AUTHORITY",
    Latitude: "-29.1208",
    Longitude: "31.1412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13541",
    WardLookupId: "1675",
    VDNumber: "43583856",
    RegisteredPopulation: "238",
    VotingStationName: "INKONJANE SECONDARY SCHOOL",
    Address: "D1537 MABOBHANE AREA  MAPHUMULO  NODUNGA",
    Latitude: "-29.0947",
    Longitude: "31.201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13542",
    WardLookupId: "1675",
    VDNumber: "43584262",
    RegisteredPopulation: "169",
    VotingStationName: "THALA PRIMARY SCHOOL",
    Address: "EMTHOMBENI AREA ALONG D1533 RO  MAPHUMUULO  MAPHUMULO",
    Latitude: "-29.1224",
    Longitude: "31.1678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13543",
    WardLookupId: "1675",
    VDNumber: "43770090",
    RegisteredPopulation: "285",
    VotingStationName: "NOMAKHALADI PRIMARY SCHOOL",
    Address: "MPHISE AREA  KRANSKOP  KWACELE TRIBAL AUTHORITY",
    Latitude: "-28.9697",
    Longitude: "31.124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13544",
    WardLookupId: "1675",
    VDNumber: "43770102",
    RegisteredPopulation: "805",
    VotingStationName: "MPHISE HIGHER PRIMARY SCHOOL",
    Address: "MPHISE AREA NEXT TO THE CLINIC  KRANSKOP  MAPHUMULO",
    Latitude: "-29.0171",
    Longitude: "31.0995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13545",
    WardLookupId: "1675",
    VDNumber: "43773857",
    RegisteredPopulation: "152",
    VotingStationName: "ELUNGENI PRIMARY SCHOOL",
    Address: "MBITANE AREA ALONG D881 ROAD  MAPHUMULO  MAPHUMULO",
    Latitude: "-29.0789",
    Longitude: "31.0497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13546",
    WardLookupId: "1675",
    VDNumber: "43775657",
    RegisteredPopulation: "149",
    VotingStationName: "MANGONGO PRIMARY SCHOOL",
    Address: "MPHISE VILLAGE  KRANSKOP  KWACELE T.A.",
    Latitude: "-29.0279",
    Longitude: "31.1232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13547",
    WardLookupId: "1676",
    VDNumber: "43580224",
    RegisteredPopulation: "1120",
    VotingStationName: "MAQUMBI PRIMARY SCHOOL",
    Address:
      "NEXT TO MAQUMBI SPORTS FIELD  MAPHUMULO(KWAMSHUKANGUBO AREA)  MAPHUMULO(MKHONTO TRIBAL AUTHORITY)",
    Latitude: "-29.1767",
    Longitude: "31.1902",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13548",
    WardLookupId: "1676",
    VDNumber: "43580729",
    RegisteredPopulation: "819",
    VotingStationName: "OTIMATI PRIMARY SCHOOL",
    Address:
      "KWANODUNGA ALONG D1533 ROAD OQAQENI AREA, NEXT TO OQAQENI CLINIC MAPHUMULO/KWANODUNGA TAC",
    Latitude: "-29.1552",
    Longitude: "31.147",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13549",
    WardLookupId: "1676",
    VDNumber: "43580886",
    RegisteredPopulation: "967",
    VotingStationName: "ISIBANISEZWE HIGH SCHOOL",
    Address: "MZULWINI AREA ALONG D894 ROAD  MAPUMULO 26  MAPUMULO",
    Latitude: "-29.1974",
    Longitude: "31.1655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13550",
    WardLookupId: "1676",
    VDNumber: "43581102",
    RegisteredPopulation: "256",
    VotingStationName: "MVUMASE COMBINED  PRIMARY SCHOOL",
    Address:
      "MVUMASE AREA ALONG D1537 ROAD  MAQUMBI AREA  MAPHUMULO/MKHONTO TRIBAL AUTHORITY",
    Latitude: "-29.1247",
    Longitude: "31.238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13551",
    WardLookupId: "1676",
    VDNumber: "43581113",
    RegisteredPopulation: "218",
    VotingStationName: "SIYACOTHOZA PRIMARY SCHOOL",
    Address: "KWANZUZA AREA ALONG D1537 ROAD  MAPHUMULO 27  MAPHUMULO",
    Latitude: "-29.1425",
    Longitude: "31.2031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13552",
    WardLookupId: "1676",
    VDNumber: "43581146",
    RegisteredPopulation: "435",
    VotingStationName: "NSUNGWINI COMBINED SCHOOL",
    Address: "KWAMSHIBE AREA ALONG D1539  MAPUMULO  ILEMBE",
    Latitude: "-29.1645",
    Longitude: "31.2546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13553",
    WardLookupId: "1676",
    VDNumber: "43582068",
    RegisteredPopulation: "684",
    VotingStationName: "EZITHABENI SECONDARY SCHOOL",
    Address: "OQAQENI AREA ALONG D1533  MAPHUMULO 33  MAPHUMULO",
    Latitude: "-29.1425",
    Longitude: "31.1621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13554",
    WardLookupId: "1676",
    VDNumber: "43584329",
    RegisteredPopulation: "458",
    VotingStationName: "MANKAYIYANA PRIMARY SCHOOL",
    Address: "MZULWINI AREA ALONG R74 ROAD  ILEMBE  MAPHUMULO",
    Latitude: "-29.1735",
    Longitude: "31.1554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13555",
    WardLookupId: "1676",
    VDNumber: "43584622",
    RegisteredPopulation: "184",
    VotingStationName: "OYENGWENI CRECHE",
    Address: "OYENGWENI AREA  MAPHUMULO  MKHONTO TRIBAL AUTHORITY",
    Latitude: "-29.1463",
    Longitude: "31.2859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13556",
    WardLookupId: "1676",
    VDNumber: "43584723",
    RegisteredPopulation: "261",
    VotingStationName: "EMPUNGENI PRIMARY SCHOOL",
    Address: "MVUMASE AREA  MAPHUMULO  MKHONTO T.A.",
    Latitude: "-29.1105",
    Longitude: "31.2013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13557",
    WardLookupId: "1677",
    VDNumber: "43770124",
    RegisteredPopulation: "1731",
    VotingStationName: "PHEZUKOMKHONO HIGH SCHOOL",
    Address:
      "KWAMXHOSA AREA   1KM FROM MBHEKAPHANSI CLINIC  HLONGWA TRIBAL AUTHORITY",
    Latitude: "-29.0867",
    Longitude: "30.9701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13558",
    WardLookupId: "1677",
    VDNumber: "43770157",
    RegisteredPopulation: "514",
    VotingStationName: "KWANGQUNGQUSHE PRIMARY SCHOOL",
    Address: "MAHLONGWA THAFAMASI AREA D219   KRANSKOP  MAPHUMULO",
    Latitude: "-29.0517",
    Longitude: "31.0117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13559",
    WardLookupId: "1677",
    VDNumber: "43770326",
    RegisteredPopulation: "336",
    VotingStationName: "OGAGWINI  PRIMARY SCHOOL",
    Address: "ALONG D1527 (NDUKENDE AREA)  EMABOMVINI  MAPHUMULO",
    Latitude: "-29.1207",
    Longitude: "30.9609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13560",
    WardLookupId: "1677",
    VDNumber: "43770337",
    RegisteredPopulation: "288",
    VotingStationName: "MENYEZWAYO HIGH SCHOOL",
    Address: "MABOMVINI(NDUKENDE AREA)  MAPHUMULO  AMABOVU TRIBAL AUTHORITY",
    Latitude: "-29.1022",
    Longitude: "30.9167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13561",
    WardLookupId: "1677",
    VDNumber: "43773543",
    RegisteredPopulation: "1009",
    VotingStationName: "KHOMBA PRIMARY SCHOOL",
    Address: "MABOMVINI (NDUKENDE AREA)  MAPHUMULO  AMABOVU TRIBAL AUTHORITY",
    Latitude: "-29.086",
    Longitude: "30.9387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13562",
    WardLookupId: "1677",
    VDNumber: "43773947",
    RegisteredPopulation: "660",
    VotingStationName: "INHLOKOZI HIGH SCHOOL",
    Address: "00 MTHOMBENI MISSION  ILEMBE  MAPUMULO",
    Latitude: "-29.0879",
    Longitude: "31.0091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13563",
    WardLookupId: "1677",
    VDNumber: "43774229",
    RegisteredPopulation: "235",
    VotingStationName: "KHETHOKUHLE CRECHE",
    Address: "ALONG D881 ROAD MBITANE AREA  ILEMBE  MAPHUMULO",
    Latitude: "-29.097",
    Longitude: "31.0386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13564",
    WardLookupId: "1677",
    VDNumber: "43774724",
    RegisteredPopulation: "299",
    VotingStationName: "VULAMEHLO HALL",
    Address: "MBITANE AREA (NGIDI ROAD)  ILEMBE  MAPHUMULO",
    Latitude: "-29.1025",
    Longitude: "31.0164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13565",
    WardLookupId: "1678",
    VDNumber: "43581067",
    RegisteredPopulation: "837",
    VotingStationName: "JOEL PRIMARY SCHOOL",
    Address: "MASIWELA  MAPHUMULO  MKHONTO T.A.",
    Latitude: "-29.1343",
    Longitude: "31.068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13566",
    WardLookupId: "1678",
    VDNumber: "43583474",
    RegisteredPopulation: "1044",
    VotingStationName: "UMPHUMULO PRIMARY SCHOOL",
    Address: "UMPHUMULO MISSION NEXT TO HOSP  ILEMBE  MAPUMULO",
    Latitude: "-29.1367",
    Longitude: "31.0397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13567",
    WardLookupId: "1678",
    VDNumber: "43584273",
    RegisteredPopulation: "150",
    VotingStationName: "ZIBUSELE SHOP",
    Address: "NO STREET ADRESS AVAILABLE  MAPHUMULO  MAPHUMULO",
    Latitude: "-29.1221",
    Longitude: "31.0536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13568",
    WardLookupId: "1678",
    VDNumber: "43770281",
    RegisteredPopulation: "544",
    VotingStationName: "BALCOMBS HILL PRIMARY SCHOOL",
    Address: "MABOMVINI AREA ALONG R74 ROAD  KRANSKOP  MAPHUMULO",
    Latitude: "-29.105",
    Longitude: "30.9972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13569",
    WardLookupId: "1678",
    VDNumber: "43770292",
    RegisteredPopulation: "232",
    VotingStationName: "TSHUTSHUTSHU SECONDARY SCHOOL",
    Address: "OCHENI AREA  MAPHUMULO  ZUBANE T.A.",
    Latitude: "-29.1263",
    Longitude: "31.0045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13570",
    WardLookupId: "1678",
    VDNumber: "43770348",
    RegisteredPopulation: "259",
    VotingStationName: "KWANCENGWA COMMUNITY HALL",
    Address: "MENYEZWAYO AREA  MAPHUMULO  MABOMVINI TRIBAL AUTHORITY",
    Latitude: "-29.1225",
    Longitude: "30.9019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13571",
    WardLookupId: "1678",
    VDNumber: "43770427",
    RegisteredPopulation: "456",
    VotingStationName: "MABOMVINI TRIBAL COURT",
    Address: "ALONG D1527  MAPHUMULO  AMABOVU TRIBAL AUTHORITY",
    Latitude: "-29.1415",
    Longitude: "30.9573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13572",
    WardLookupId: "1678",
    VDNumber: "43770438",
    RegisteredPopulation: "62",
    VotingStationName: "DANGAZELA  PRIMARY SCHOOL",
    Address: "MABOMVINI AREA  MAPUMULO  MAPHUMULO",
    Latitude: "-29.1678",
    Longitude: "30.9622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13573",
    WardLookupId: "1678",
    VDNumber: "43773846",
    RegisteredPopulation: "133",
    VotingStationName: "VUKUZENZELE PRIMARY SCHOOL",
    Address: "EMBIZENI AREA  MAPHUMULO  KWAZUBANE TRIBAL AUTHORITY",
    Latitude: "-29.1705",
    Longitude: "30.9954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13574",
    WardLookupId: "1678",
    VDNumber: "43774230",
    RegisteredPopulation: "1081",
    VotingStationName: "MASIPHILE COMMUNITY HALL",
    Address: "CORNER OF R 74 AND D881 ROAD  ILEMBE  ZUBANE TRIBAL AUTHORITY",
    Latitude: "-29.1126",
    Longitude: "31.0209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13575",
    WardLookupId: "1678",
    VDNumber: "43774713",
    RegisteredPopulation: "91",
    VotingStationName: "NKANGALA (TENT)",
    Address: "NKANGALA  MAPHUMULO  MABOMVINI TRIBAL AUTHORITY",
    Latitude: "-29.1876",
    Longitude: "30.9461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13576",
    WardLookupId: "1678",
    VDNumber: "43775567",
    RegisteredPopulation: "215",
    VotingStationName: "MPANGELE PRIMARY SCHOOL",
    Address: "NYONEBOMVU AREA  MAPHUMUMULO  MABOMVINI TRIBAL AUTHORITY",
    Latitude: "-29.162",
    Longitude: "30.923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13577",
    WardLookupId: "1679",
    VDNumber: "43580875",
    RegisteredPopulation: "637",
    VotingStationName: "SAKHISIZWE HIGH SCHOOL",
    Address:
      "P711 MTHANDENI VILLAGE  MAPHUMULO  MAPHUMULO/QWABE-MTHANDENI TRIBAL AUTHORITY",
    Latitude: "-29.2902",
    Longitude: "31.0707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13578",
    WardLookupId: "1679",
    VDNumber: "43581371",
    RegisteredPopulation: "409",
    VotingStationName: "MESENI PRIMARY SCHOOL",
    Address:
      "OPPOSITE MANSOMINI CANE FIELDS  ILEMBE  QWABE-MTHANDENI TRIBAL AUTHORITY",
    Latitude: "-29.3061",
    Longitude: "31.0802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13579",
    WardLookupId: "1679",
    VDNumber: "43581416",
    RegisteredPopulation: "453",
    VotingStationName: "QWABE SECONDARY SCHOOL",
    Address: "QWABE   UPHONJWANE RESERVE  MAPHUMULO/QWABE TRIBAL AUTHORITY",
    Latitude: "-29.3263",
    Longitude: "31.0153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13580",
    WardLookupId: "1679",
    VDNumber: "43582125",
    RegisteredPopulation: "415",
    VotingStationName: "QWABE-MTHANDENI TRIBAL COURT",
    Address: "P711 MTHANDENI AREA  MAPHUMULO  QWBE-MTHANDENI TRIBAL AUTHORITY",
    Latitude: "-29.2583",
    Longitude: "31.0774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13581",
    WardLookupId: "1679",
    VDNumber: "43582136",
    RegisteredPopulation: "613",
    VotingStationName: "ESINAMFINI COMMUNITY HALL",
    Address:
      "ALONG GLENDALE ROAD ESINAMFINI  ILEMBE  QWABE-MTHANDENI TRIBAL AUTHORITY",
    Latitude: "-29.307",
    Longitude: "31.0623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13582",
    WardLookupId: "1679",
    VDNumber: "43582158",
    RegisteredPopulation: "705",
    VotingStationName: "EMTHANDENI COMBINED PRIMARY SCHOOL",
    Address: "P711 EMTHANDENI  MAPHUMULO/ QWABE-MTHANDENI TRIBAL AUTHORITY",
    Latitude: "-29.2804",
    Longitude: "31.0968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13583",
    WardLookupId: "1679",
    VDNumber: "43582170",
    RegisteredPopulation: "245",
    VotingStationName: "MANSOMINI COMMUNITY HALL",
    Address: "ALONG GLENDALE ROAD  MAPUMULO   QWABE MTANDENI TRIBAL AUTHORITY",
    Latitude: "-29.2926",
    Longitude: "31.0952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13584",
    WardLookupId: "1679",
    VDNumber: "43584295",
    RegisteredPopulation: "251",
    VotingStationName: "FUNDANI PRIMARY SCHOOL",
    Address: "GLENDALE ROAD  MAQWAYIBENI AREA NEAR NDUNA MAVIMBELA  MAPHUMULO",
    Latitude: "-29.3332",
    Longitude: "31.0756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13585",
    WardLookupId: "1679",
    VDNumber: "43584341",
    RegisteredPopulation: "368",
    VotingStationName: "SIBUSISIWE CHURCH",
    Address:
      "ALONG GLENDALE ROAD GOGOVUMA A  ILEMBE  QWABE-MTHANDENI TRIBAL AUTHORITY",
    Latitude: "-29.3067",
    Longitude: "31.0478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13586",
    WardLookupId: "1680",
    VDNumber: "43580505",
    RegisteredPopulation: "497",
    VotingStationName: "SIYATHUTHUKA MAQADI CRECHE",
    Address: "P711 MAQADINI AREA  AMAQADI AREA  MAPUMULO",
    Latitude: "-29.2074",
    Longitude: "31.041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13587",
    WardLookupId: "1680",
    VDNumber: "43580640",
    RegisteredPopulation: "376",
    VotingStationName: "EMANTANJENI COMMUNITY HALL",
    Address: "OPPOSITE EMANTANJENI SHOP  ILEMBE  AMAQADI TRIBAL AUTHOURITY",
    Latitude: "-29.2192",
    Longitude: "31.0645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13588",
    WardLookupId: "1680",
    VDNumber: "43580707",
    RegisteredPopulation: "747",
    VotingStationName: "IKHUSANA PRIMARY SCHOOL",
    Address: "MAPHUMULO  AMAMBEDU AREA  AMAMBEDU TRIBAL AUTHORITY",
    Latitude: "-29.2493",
    Longitude: "31.0714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13589",
    WardLookupId: "1680",
    VDNumber: "43580864",
    RegisteredPopulation: "397",
    VotingStationName: "IMUSHANE PRIMARY SCHOOL",
    Address: "IMUSHASNE  IMUSHANE AREA  MAPHUMULO/AMAMBEDU TRIBAL AUTHORITY",
    Latitude: "-29.2348",
    Longitude: "31.1122",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13590",
    WardLookupId: "1680",
    VDNumber: "43582091",
    RegisteredPopulation: "341",
    VotingStationName: "MPUSHINI TRIBAL COURT",
    Address: "MAPHUMULO  AMAMBEDU AREA  AMAMBEDU TRIBAL AUTHORITY",
    Latitude: "-29.2301",
    Longitude: "31.0934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13591",
    WardLookupId: "1680",
    VDNumber: "43582147",
    RegisteredPopulation: "414",
    VotingStationName: "MPUMLWANE PRIMARY SCHOOL",
    Address:
      "EMBIZENI VILLAGE  NEAR NTUTHUKWENI STORE  MAPHUMULO/AMAQADI TRIBAL AUTHORITY",
    Latitude: "-29.243",
    Longitude: "31.0411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13592",
    WardLookupId: "1680",
    VDNumber: "43582169",
    RegisteredPopulation: "505",
    VotingStationName: "EMBONQENI PRIMARY SCHOOL",
    Address:
      "MAPHUMULO  VIMBUKHALO MANKENTSHA AREA  MAPHUMULO/AMAMBEDU TRIBAL AUTHORUTY",
    Latitude: "-29.2545",
    Longitude: "31.1081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13593",
    WardLookupId: "1680",
    VDNumber: "43582181",
    RegisteredPopulation: "472",
    VotingStationName: "EZAGQAYINI COMMUNITY HALL",
    Address:
      "711 EZAGQAYINI NEXT UMVOTI BRIDGE  ILEMBE  AMAQADI TRIBAL AUTHORITY",
    Latitude: "-29.2795",
    Longitude: "31.0376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13594",
    WardLookupId: "1680",
    VDNumber: "43583867",
    RegisteredPopulation: "290",
    VotingStationName: "UBUHLEBEMBIZA SECONDARY SCHOOL",
    Address: "MBIZENI AREA  EMBIZENI AREA  MAPHUMULO/AMAQADI TRIBAL AUTHORITY",
    Latitude: "-29.2635",
    Longitude: "31.0325",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13595",
    WardLookupId: "1680",
    VDNumber: "43584284",
    RegisteredPopulation: "324",
    VotingStationName: "MOYAMUHLE PRIMARY SCHOOL",
    Address: "P711 AMAQADI  MAPHUMULO  AMAQADI TRIBAL AUTHORITY",
    Latitude: "-29.2288",
    Longitude: "31.0367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13596",
    WardLookupId: "1680",
    VDNumber: "43584363",
    RegisteredPopulation: "204",
    VotingStationName: "KHAWULEZA COMMUNITY HALL",
    Address:
      "1531 MATIWANE AREA EMAMBWEDWINI  ILEMBE  MPUSHINI TRIBAL AUTHORITY",
    Latitude: "-29.2195",
    Longitude: "31.1215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13597",
    WardLookupId: "1681",
    VDNumber: "43580741",
    RegisteredPopulation: "612",
    VotingStationName: "DLUDLA PRIMARY SCHOOL",
    Address:
      "D1530 AMAMBEDU  AMAMBEDU AREA  MAPHUMULO/AMAMBEDU TRIBAL AUTHORITY",
    Latitude: "-29.197",
    Longitude: "31.1229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13598",
    WardLookupId: "1681",
    VDNumber: "43580921",
    RegisteredPopulation: "410",
    VotingStationName: "SABUYAZE SECONDARY SCHOOL",
    Address: "D893 NEXT TO MUNICIPAL OFFICES  KWAMKHONTO AREA  MAPHUMULO",
    Latitude: "-29.1633",
    Longitude: "31.0689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13599",
    WardLookupId: "1681",
    VDNumber: "43582079",
    RegisteredPopulation: "600",
    VotingStationName: "PHAKADE  PRIMARY SCHOOL",
    Address: "MVOZANE AREA   MAPUMULO  MKHONTO TRIBAL AUTHORITY",
    Latitude: "-29.1918",
    Longitude: "31.0949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13600",
    WardLookupId: "1681",
    VDNumber: "43583722",
    RegisteredPopulation: "533",
    VotingStationName: "EMATENDENI HALL",
    Address: "KWABHOVA  MAPHUMULO  MAPHUMULO",
    Latitude: "-29.1855",
    Longitude: "31.1382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13601",
    WardLookupId: "1681",
    VDNumber: "43583733",
    RegisteredPopulation: "571",
    VotingStationName: "ENDLELENI PRIMARY SCHOOL",
    Address:
      "AMAMBEDU VILLAGE  NHLAKAZI RESERVE  MAPHUMULO/AMAMBEDU TRIBAL AUTHORITY",
    Latitude: "-29.2134",
    Longitude: "31.0845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13602",
    WardLookupId: "1681",
    VDNumber: "43583878",
    RegisteredPopulation: "647",
    VotingStationName: "NYAMAZANE PRIMARY SCHOOL",
    Address:
      "NYAMAZANE  NYAMAZANE AREA, NEAR MAKHEDAMA STORE  MAPHUMULO/MKHONTO TRIBAL AUTHORITY",
    Latitude: "-29.1637",
    Longitude: "31.0844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13603",
    WardLookupId: "1681",
    VDNumber: "43583889",
    RegisteredPopulation: "281",
    VotingStationName: "SIPHINHLANHLA SECONDARY SCHOOL",
    Address: "AMAQADI AREA   MAPHUMULO  AMAQADI TRIBAL AUTHORITY",
    Latitude: "-29.1992",
    Longitude: "31.0823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13604",
    WardLookupId: "1681",
    VDNumber: "43584149",
    RegisteredPopulation: "317",
    VotingStationName: "MAZISI NTULI PRIMARY SCHOOL",
    Address:
      "D1532 NO STREET ADRESS AVAILABLE  MAPHUMULO  MKHONTO TRIBAL AUTHORITY",
    Latitude: "-29.1854",
    Longitude: "31.116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13605",
    WardLookupId: "1681",
    VDNumber: "43584374",
    RegisteredPopulation: "295",
    VotingStationName: "THE CHRISTIAN CATHOLIC CHURCH IN SOUTH AFRICA",
    Address: "MTHOMBENI AREA  MAPHUMULO  MKHONTO TRIBAL AUTHORITY",
    Latitude: "-29.176",
    Longitude: "31.1311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13606",
    WardLookupId: "1681",
    VDNumber: "43584385",
    RegisteredPopulation: "428",
    VotingStationName: "SAYIZI-NTULI PRIMARY SCHOOL",
    Address: "MTHOMBENI AREA  MAPHUMULO  MKHONTO TRIBAL AUTHORITY",
    Latitude: "-29.1707",
    Longitude: "31.0964",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13607",
    WardLookupId: "1682",
    VDNumber: "43580897",
    RegisteredPopulation: "630",
    VotingStationName: "NSONGANSONGA PRIMARY SCHOOL",
    Address:
      "ALONG R74 NEAR EZINKAMBENI RES  MAPHUMULO  MKHONTO TRIBAL AUTHORITY",
    Latitude: "-29.1672",
    Longitude: "31.1148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13608",
    WardLookupId: "1682",
    VDNumber: "43581078",
    RegisteredPopulation: "637",
    VotingStationName: "ZAMAZISA PRIMARY SCHOOL",
    Address:
      "NO STREET NAME  NTOMBIHLULUNINA AREA, NEAR ZAMAZISA STORE (CLINIC)  MAPUMULO/MKHONTO TAC",
    Latitude: "-29.1454",
    Longitude: "31.088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13609",
    WardLookupId: "1682",
    VDNumber: "43581089",
    RegisteredPopulation: "1620",
    VotingStationName: "MKHONTO TRIBAL COURT",
    Address:
      "R74 TSHOBHO AREA  MAPHUMULO NEXT TO SAKHUXOLO MULTI-SKILLS CENTRE  MAPHUMULO/MKHONTO TAC",
    Latitude: "-29.1387",
    Longitude: "31.0695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13610",
    WardLookupId: "1682",
    VDNumber: "43583890",
    RegisteredPopulation: "1057",
    VotingStationName: "NYAMAZANE PRE PRIMARY SCHOOL",
    Address: "D893 NEXT TO MAPHUMULO POST OFFICE  KWAMKHONTO  MAPHUMULO",
    Latitude: "-29.1557",
    Longitude: "31.0674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13611",
    WardLookupId: "1682",
    VDNumber: "43584419",
    RegisteredPopulation: "549",
    VotingStationName: "BEKEZELA PRE SCHOOL",
    Address: "D893 OPPOSITE MAKHEDAMA SHOP  NYAMAZANE  MAPHUMULO",
    Latitude: "-29.1624",
    Longitude: "31.0836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13612",
    WardLookupId: "1682",
    VDNumber: "43584420",
    RegisteredPopulation: "399",
    VotingStationName: "KWANDABA PRIMARY SCHOOL",
    Address: "KWANDABA AREA  MAPHUMULO  MKHONTO",
    Latitude: "-29.1581",
    Longitude: "31.1313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13613",
    WardLookupId: "1683",
    VDNumber: "43580628",
    RegisteredPopulation: "461",
    VotingStationName: "EMKHOVINI COMMUNITY HALL",
    Address:
      "P381 NO STREET ADRESS AVAILABLE  EMKHOVINI AREA NEAR DLAKATHI STORE  MAPHUMULO/EMBO TRIBAL AUTHORITY",
    Latitude: "-29.232",
    Longitude: "31.0088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13614",
    WardLookupId: "1683",
    VDNumber: "43580639",
    RegisteredPopulation: "230",
    VotingStationName: "MAPHUMULO COMBINED PRIMARY SCHOOL",
    Address: "AMAQADI NEXT TO UCC CHURCH  ILEMBE (EMAQADINI AREA)  MAPHUMULO",
    Latitude: "-29.1924",
    Longitude: "31.0577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13615",
    WardLookupId: "1683",
    VDNumber: "43581090",
    RegisteredPopulation: "867",
    VotingStationName: "SIPHAMANDLA PRIMARY SCHOOL",
    Address: "EMNYAMENI AREA  MAPUMULO  KWAZUBANE TRIBAL AUTHORITY",
    Latitude: "-29.1684",
    Longitude: "31.0486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13616",
    WardLookupId: "1683",
    VDNumber: "43770304",
    RegisteredPopulation: "578",
    VotingStationName: "OCHENI PRIMARY SCHOOL",
    Address: "D1528 OCHENI AREA  MAPHUMULO  KWAZUBANE TRIBAL AUTHORITY",
    Latitude: "-29.1548",
    Longitude: "31.0115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13617",
    WardLookupId: "1683",
    VDNumber: "43770315",
    RegisteredPopulation: "581",
    VotingStationName: "ESINDI PRIMARY SCHOOL",
    Address: "NEAR THEMBENI STORE  MAPUMULO  EMBO TRIBAL AUTHORITY",
    Latitude: "-29.2039",
    Longitude: "30.9965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13618",
    WardLookupId: "1683",
    VDNumber: "43770528",
    RegisteredPopulation: "190",
    VotingStationName: "ESIQHOQHWENI SECONDARY SCHOOL",
    Address: "EHHOLWENI AREA  MAPHUMULO  MAPHUMULO",
    Latitude: "-29.2094",
    Longitude: "30.9667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13619",
    WardLookupId: "1683",
    VDNumber: "43770551",
    RegisteredPopulation: "454",
    VotingStationName: "ISITHUNDU COMBINED PRIMARY SCHOOL",
    Address: "NEXT TO ST. PHELOMENA STORE  ISITHUNDU AREA  MAPHUMULO",
    Latitude: "-29.2466",
    Longitude: "30.9972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13620",
    WardLookupId: "1683",
    VDNumber: "43772564",
    RegisteredPopulation: "370",
    VotingStationName: "HLIMBITWA COMBINED PRIMARY SCHOOL",
    Address:
      "D1527 HLIMBITHWA AREA, MAPHUMULO  MAPHUMULO  KWAZUBANE TRIBAL AUTHORITY",
    Latitude: "-29.1875",
    Longitude: "31.0168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13621",
    WardLookupId: "1683",
    VDNumber: "43773969",
    RegisteredPopulation: "356",
    VotingStationName: "NOTHWENI PRIMARY SCHOOL",
    Address: "D1527 NOTHWENI AREA  MAPHUMULO  KWAZUBANE TRIBAL AUTHORITY",
    Latitude: "-29.1653",
    Longitude: "31.0221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13622",
    WardLookupId: "1683",
    VDNumber: "43774511",
    RegisteredPopulation: "143",
    VotingStationName: "KWANOFOHLA CRECHE",
    Address: "MAPHUMULO  KWANOFOHLA AREA  AMANGCOLOSI TRIBAL AUTHORITY",
    Latitude: "-29.2467",
    Longitude: "31.0146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13623",
    WardLookupId: "1684",
    VDNumber: "43900017",
    RegisteredPopulation: "4175",
    VotingStationName: "JJ MHLONGO HALL",
    Address: "154 HORSESHOE  BHONGWENI  KOKSTAD",
    Latitude: "-30.5442",
    Longitude: "29.4525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13624",
    WardLookupId: "1685",
    VDNumber: "43772755",
    RegisteredPopulation: "277",
    VotingStationName: "BEERSHEBA PRIMARY SCHOOL",
    Address: "R626  BEERSHEBA FARM  SWARTBERG",
    Latitude: "-30.059",
    Longitude: "29.3528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13625",
    WardLookupId: "1685",
    VDNumber: "43772766",
    RegisteredPopulation: "234",
    VotingStationName: "WANSBECK ANGLICAN CHURCH",
    Address: "D622 WANSBECK  WANSBECK FARM  KOKSTAD",
    Latitude: "-29.9999",
    Longitude: "29.1775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13626",
    WardLookupId: "1685",
    VDNumber: "43772777",
    RegisteredPopulation: "291",
    VotingStationName: "NEW AMALFI SALE YARDS",
    Address: "D604 NEW AMALFI  NEW AMALFI FARM  CERDARVILLE",
    Latitude: "-30.2839",
    Longitude: "29.1799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13627",
    WardLookupId: "1685",
    VDNumber: "43773880",
    RegisteredPopulation: "95",
    VotingStationName: "ST BERNARDS PEAK",
    Address: "D609 MIDDLE VALLEY FARM  SWARTBERG  KOKSTAD",
    Latitude: "-30.0564",
    Longitude: "29.1082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13628",
    WardLookupId: "1685",
    VDNumber: "43774173",
    RegisteredPopulation: "693",
    VotingStationName: "SHALOM PRIMARY SCHOOL",
    Address: "R617  ERIN FARM  SWARTBERG",
    Latitude: "-30.2495",
    Longitude: "29.3549",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13629",
    WardLookupId: "1685",
    VDNumber: "43774768",
    RegisteredPopulation: "383",
    VotingStationName: "KRAANSDRAAI PRIMARY SCHOOL",
    Address: "2 KRAANSDRAAI FARM  KOKSTAD  GREATER KOKSTAD",
    Latitude: "-30.1341",
    Longitude: "29.1926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13630",
    WardLookupId: "1685",
    VDNumber: "43774779",
    RegisteredPopulation: "1858",
    VotingStationName: "FRANKLIN SECONDARY SCHOOL",
    Address:
      "HENOP STREET  FRANKLIN VILLAGE, 4710  (KZN433 - GREATER KOKSTAD [KOKSTAD])",
    Latitude: "-30.3157",
    Longitude: "29.4481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13631",
    WardLookupId: "1685",
    VDNumber: "43775264",
    RegisteredPopulation: "71",
    VotingStationName: "CURRAGH PRIMARY SCHOOL",
    Address: "D670 SWARTBERG  CURRAGH FARM  SWATBERG",
    Latitude: "-29.9628",
    Longitude: "29.3506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13632",
    WardLookupId: "1685",
    VDNumber: "43775578",
    RegisteredPopulation: "110",
    VotingStationName: "NTSIMBINI PRIMARY SCHOOL",
    Address: "TELGTE MISSION  SWARTBERG  SWARTBERG",
    Latitude: "-30.2198",
    Longitude: "29.2059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13633",
    WardLookupId: "1686",
    VDNumber: "43900051",
    RegisteredPopulation: "2971",
    VotingStationName: "TOWN HALL",
    Address: "78 HOPE STREET  KOKSTAD  KOKSTAD",
    Latitude: "-30.5496",
    Longitude: "29.4237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13634",
    WardLookupId: "1686",
    VDNumber: "43900130",
    RegisteredPopulation: "836",
    VotingStationName: "ESAYIDI FET COLLEGE",
    Address: "927 SCOTTS STREET  KOKSTAD  KOKSTAD",
    Latitude: "-30.5587",
    Longitude: "29.4205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13635",
    WardLookupId: "1686",
    VDNumber: "43900163",
    RegisteredPopulation: "572",
    VotingStationName: "KOKSTAD COLLEGE",
    Address: "N/A THE AVENUE  KOKSTAD  KOKSTAD",
    Latitude: "-30.5468",
    Longitude: "29.4116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13636",
    WardLookupId: "1687",
    VDNumber: "43900107",
    RegisteredPopulation: "3986",
    VotingStationName: "NOMZAMO PRIMARY SCHOOL",
    Address: "1030 THABO MBHEKI ROAD  SHAYAMOYA  KOKSTAD",
    Latitude: "-30.5207",
    Longitude: "29.444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13637",
    WardLookupId: "1688",
    VDNumber: "43900028",
    RegisteredPopulation: "2238",
    VotingStationName: "TUNTULWANA HALL",
    Address: "719 NKQUBELA ROAD  BHONGWENI  KOKSTAD",
    Latitude: "-30.5485",
    Longitude: "29.4407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13638",
    WardLookupId: "1688",
    VDNumber: "43900062",
    RegisteredPopulation: "1010",
    VotingStationName: "POWER OF LOVE CHURCH",
    Address: "24 MAIN  KOKSTAD  KOKSTAD",
    Latitude: "-30.5542",
    Longitude: "29.4291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13639",
    WardLookupId: "1689",
    VDNumber: "43772733",
    RegisteredPopulation: "681",
    VotingStationName: "THIBANE COMBINED SCHOOL",
    Address: "D604  PAKKIES FARM  KOKSTAD",
    Latitude: "-30.5699",
    Longitude: "29.5877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13640",
    WardLookupId: "1689",
    VDNumber: "43772744",
    RegisteredPopulation: "576",
    VotingStationName: "NEW MARKET SCHOOL",
    Address: "R61 NEW MARKET  KOKSTAD",
    Latitude: "-30.3819",
    Longitude: "29.4732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13641",
    WardLookupId: "1689",
    VDNumber: "43772788",
    RegisteredPopulation: "436",
    VotingStationName: "CHARLTON SENIOR PRIMARY SCHOOL",
    Address: "29 BORNAPARTE ROAD  KROMDRAAI FARM  KOKSTAD",
    Latitude: "-30.5269",
    Longitude: "29.1904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13642",
    WardLookupId: "1689",
    VDNumber: "43774184",
    RegisteredPopulation: "369",
    VotingStationName: "GLENTHORNE/UMZINLTANGA TRAINING CENTRE",
    Address: "GLENTHORNE FARM  KOKSTAD",
    Latitude: "-30.5695",
    Longitude: "29.425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13643",
    WardLookupId: "1689",
    VDNumber: "43774814",
    RegisteredPopulation: "288",
    VotingStationName: "RUSTFONTEIN P SCHOOL",
    Address: "RUSTFONTEIN FARM  KOKSTAD",
    Latitude: "-30.4868",
    Longitude: "29.2949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13644",
    WardLookupId: "1689",
    VDNumber: "43774825",
    RegisteredPopulation: "215",
    VotingStationName: "WATERSPORT CLUBHOUSE",
    Address: "CRYSTAL SPRINGS DAM  KOKSTAD",
    Latitude: "-30.5074",
    Longitude: "29.3964",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13645",
    WardLookupId: "1689",
    VDNumber: "43774836",
    RegisteredPopulation: "379",
    VotingStationName: "ST MICHAELS PRIMARY SCHOOL",
    Address: "THORNHAM FARM  KARGS POST  KOKSTAD",
    Latitude: "-30.5134",
    Longitude: "29.5218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13646",
    WardLookupId: "1689",
    VDNumber: "43774847",
    RegisteredPopulation: "503",
    VotingStationName: "INGELI PRIMARY SCHOOL",
    Address: "D708 THUTHUKANI  THUTHUKANI FARM  KOKSTAD",
    Latitude: "-30.6043",
    Longitude: "29.5499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13647",
    WardLookupId: "1689",
    VDNumber: "43775501",
    RegisteredPopulation: "95",
    VotingStationName: "FOLES HILL PRIMARY",
    Address: "FOLES HILL FARM  ",
    Latitude: "-30.365",
    Longitude: "29.3507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13648",
    WardLookupId: "1690",
    VDNumber: "43900084",
    RegisteredPopulation: "2837",
    VotingStationName: "SHAYAMOYA HIGH SCHOOL",
    Address:
      "5033 CHIEF ALBERT LUTHULI  CHIEF ALBERT LUTHULI ROAD, SHAYAMOYA  KOKSTAD",
    Latitude: "-30.5331",
    Longitude: "29.4309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13649",
    WardLookupId: "1690",
    VDNumber: "43900095",
    RegisteredPopulation: "1477",
    VotingStationName: "EXTENSION 7 HALL",
    Address: "44 MT CURRIE DRIVE  EXTENSION 7  KOKSTAD",
    Latitude: "-30.5348",
    Longitude: "29.4078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13650",
    WardLookupId: "1691",
    VDNumber: "43900040",
    RegisteredPopulation: "2896",
    VotingStationName: "CARL MALCOMES HIGH SCHOOL",
    Address: "225 SIWELA LANE  BHONGWENI LOCATION  KOKSTAD",
    Latitude: "-30.5457",
    Longitude: "29.4396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13651",
    WardLookupId: "1691",
    VDNumber: "43900196",
    RegisteredPopulation: "779",
    VotingStationName: "LOWER XOLOXOLO PRIMARY",
    Address: "291 GCADINJA ROAD  BHONGWENI  KOKSTAD",
    Latitude: "-30.5458",
    Longitude: "29.4385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13652",
    WardLookupId: "1692",
    VDNumber: "43900118",
    RegisteredPopulation: "1623",
    VotingStationName: "PRESBYTARIAN CHURCH",
    Address: "00 MASINYANE ROAD  BHONGWENI  KOKSTAD",
    Latitude: "-30.5475",
    Longitude: "29.4433",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13653",
    WardLookupId: "1692",
    VDNumber: "43900141",
    RegisteredPopulation: "1197",
    VotingStationName: "XOLOXOLO SPS",
    Address: "AREA 5 & 6  KOKSTAD  KOKSTAD",
    Latitude: "-30.5413",
    Longitude: "29.4352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13654",
    WardLookupId: "1692",
    VDNumber: "43900152",
    RegisteredPopulation: "1326",
    VotingStationName: "NONKQUBELA PRIMARY SCHOOL",
    Address: "8268 NKQUBELA ROAD  BHONGWENI  KOKSTAD",
    Latitude: "-30.5464",
    Longitude: "29.4454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13655",
    WardLookupId: "1692",
    VDNumber: "43900174",
    RegisteredPopulation: "803",
    VotingStationName: "KOKSTAD PRIMARY SCHOOL",
    Address: "2 MILLSTREAM ROAD  KOKSTAD  KOKSTAD",
    Latitude: "-30.5424",
    Longitude: "29.4307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13656",
    WardLookupId: "1693",
    VDNumber: "43900039",
    RegisteredPopulation: "2174",
    VotingStationName: "COMMUNITY HALL",
    Address: "128 MURRAY STREET  KOKSTAD  KOKSTAD",
    Latitude: "-30.5404",
    Longitude: "29.4271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13657",
    WardLookupId: "1693",
    VDNumber: "43900129",
    RegisteredPopulation: "3446",
    VotingStationName: "SEVEN FOUNTAINS SENIOR PRIMARY SCHOOL",
    Address: "75 THABO MBEKI  SHAYAMOYA  KOKSTAD",
    Latitude: "-30.5278",
    Longitude: "29.4345",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13658",
    WardLookupId: "1694",
    VDNumber: "43771642",
    RegisteredPopulation: "888",
    VotingStationName: "NCAKUBANA COMBINED PRIMARY SCHOOL",
    Address: "NCAKUBANA LOCATION  NCAKUBANA  IXOPO",
    Latitude: "-30.1419",
    Longitude: "29.9145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13659",
    WardLookupId: "1694",
    VDNumber: "43771653",
    RegisteredPopulation: "1191",
    VotingStationName: "MAHEHLE COMBINED PRIMARY SCHOOL",
    Address: "MAHEHLE   MAHEHLE  AREA   IXOPO",
    Latitude: "-30.1154",
    Longitude: "29.8852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13660",
    WardLookupId: "1694",
    VDNumber: "43774353",
    RegisteredPopulation: "266",
    VotingStationName: "MAHAFANA COMBINED PRIMARY SCHOOL",
    Address: "MAHAFANA  MDLANKUKHU  IXOPO",
    Latitude: "-30.1978",
    Longitude: "29.9427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13661",
    WardLookupId: "1694",
    VDNumber: "43775231",
    RegisteredPopulation: "753",
    VotingStationName: "SENZAKAHLE PRIMARY SCHOOL",
    Address: "MAHEHLE  IXOPO",
    Latitude: "-30.1202",
    Longitude: "29.9011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13662",
    WardLookupId: "1694",
    VDNumber: "43994009",
    RegisteredPopulation: "595",
    VotingStationName: "WEBBSTOWN COMBINED PRIMARY SCHOOL",
    Address: "CABAZI LOCATION  CABAZI  IXOPO",
    Latitude: "-30.2709",
    Longitude: "29.9696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13663",
    WardLookupId: "1695",
    VDNumber: "43771530",
    RegisteredPopulation: "633",
    VotingStationName: "CARISBROOK COMBINED PRIMARY SCHOOL",
    Address: "CARISBROOKE LOCATION  D414, CARRISBROOK FARM   IXOPO",
    Latitude: "-30.2176",
    Longitude: "30.0388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13664",
    WardLookupId: "1695",
    VDNumber: "43771552",
    RegisteredPopulation: "321",
    VotingStationName: "THE FLAXTON FARM",
    Address: "PORTION 2 OF THE FLAXTON FARM  THE FLAXTON FARM  IXOPO",
    Latitude: "-30.1308",
    Longitude: "30.0331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13665",
    WardLookupId: "1695",
    VDNumber: "43771596",
    RegisteredPopulation: "938",
    VotingStationName: "LUSIBA COMBINED PRIMARY SCHOOL",
    Address: "CHIBINI LOCATION  OFAFA  IXOPO",
    Latitude: "-30.0955",
    Longitude: "30.0651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13666",
    WardLookupId: "1695",
    VDNumber: "43774319",
    RegisteredPopulation: "542",
    VotingStationName: "SONQOBASIMUNYE COMBINED SCHOOL",
    Address: "HOPEWELL  IXOPO",
    Latitude: "-30.2149",
    Longitude: "30.0621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13667",
    WardLookupId: "1695",
    VDNumber: "43890015",
    RegisteredPopulation: "1799",
    VotingStationName: "IXOPO PRIMARY SCHOOL",
    Address: "05 COMMERCIAL ROAD   IXOPO  IXOPO",
    Latitude: "-30.1533",
    Longitude: "30.0602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13668",
    WardLookupId: "1696",
    VDNumber: "43771563",
    RegisteredPopulation: "607",
    VotingStationName: "MAGIDIGIDI COMBINED PRIMARY SCHOOL",
    Address: "(LUFAFA) SIGEDLENI NO5L  LUFAFA AREA  IXOPO",
    Latitude: "-30.0583",
    Longitude: "30.1616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13669",
    WardLookupId: "1696",
    VDNumber: "43771574",
    RegisteredPopulation: "731",
    VotingStationName: "MPOFINI COMBINED PRIMARY SCHOOL",
    Address: "MPOFINI  OFAFA AREA  IXOPO",
    Latitude: "-30.0379",
    Longitude: "30.1249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13670",
    WardLookupId: "1696",
    VDNumber: "43771585",
    RegisteredPopulation: "614",
    VotingStationName: "LUFAFA COMBINED SCHOOL",
    Address: "LUFAFA LOCATION  IXOPO  IXOPO",
    Latitude: "-30.0584",
    Longitude: "30.1076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13671",
    WardLookupId: "1696",
    VDNumber: "43771608",
    RegisteredPopulation: "682",
    VotingStationName: "NTAKAMA PRIMARY SCHOOL",
    Address: "NTAKAMA  LUFAFA  VUKANI",
    Latitude: "-30.0488",
    Longitude: "30.0865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13672",
    WardLookupId: "1696",
    VDNumber: "43771619",
    RegisteredPopulation: "775",
    VotingStationName: "BHENSELA COMBINED PRIMARY SCHOOL",
    Address: "UMGOBANTSIMBI  UFAFA LOCATION  IXOPO",
    Latitude: "-30.0633",
    Longitude: "30.0688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13673",
    WardLookupId: "1696",
    VDNumber: "43771620",
    RegisteredPopulation: "998",
    VotingStationName: "CEKAZI COMBINED PRIMARY SCHOOL",
    Address: "MASHAKENI AREA  OFAFA  IXOPO",
    Latitude: "-30.0768",
    Longitude: "30.0395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13674",
    WardLookupId: "1696",
    VDNumber: "43775242",
    RegisteredPopulation: "475",
    VotingStationName: "NKULULEKO HALL",
    Address: "OFAFA  VUKANI",
    Latitude: "-30.0327",
    Longitude: "30.1127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13675",
    WardLookupId: "1697",
    VDNumber: "43771541",
    RegisteredPopulation: "814",
    VotingStationName: "MARIATHAL COMBINED PRIMARY SCHOOL",
    Address: "EMAKHOLWENI  MARIATHAL MISSION  IXOPO",
    Latitude: "-30.1169",
    Longitude: "30.0948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13676",
    WardLookupId: "1697",
    VDNumber: "43775253",
    RegisteredPopulation: "241",
    VotingStationName: "EMAZIZINI JUNIOR PRIMARY SCHOOL",
    Address: "EHLANZENI  EMAKHOLWENI  IXOPO",
    Latitude: "-30.1324",
    Longitude: "30.1792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13677",
    WardLookupId: "1697",
    VDNumber: "43890026",
    RegisteredPopulation: "2737",
    VotingStationName: "VILLAGE PRIMARY SCHOOL",
    Address: "FAIRVIEW   FAIRVIEW LOCATION  IXOPO",
    Latitude: "-30.1597",
    Longitude: "30.0693",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13678",
    WardLookupId: "1697",
    VDNumber: "43890037",
    RegisteredPopulation: "1069",
    VotingStationName: "IXOPO STATE AIDED PRIMARY SCHOOL",
    Address: "28 NORTHWAY DRIVE  MORNINGSIDE  IXOPO",
    Latitude: "-30.1529",
    Longitude: "30.0684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13679",
    WardLookupId: "1698",
    VDNumber: "43990016",
    RegisteredPopulation: "444",
    VotingStationName: "NKWELETSHENI COMBINED PRIMARY SCHOOL",
    Address: "UMKHUNYA  UMKHUNYA A/A   HIGHFLATS",
    Latitude: "-30.1329",
    Longitude: "30.3981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13680",
    WardLookupId: "1698",
    VDNumber: "43990027",
    RegisteredPopulation: "682",
    VotingStationName: "ESIQANDULWENI COMBINED PRIMARY SCHOOL",
    Address: "ESIQANDULWENI  EHLANZENI  IXOPO",
    Latitude: "-30.1016",
    Longitude: "30.4033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13681",
    WardLookupId: "1698",
    VDNumber: "43990050",
    RegisteredPopulation: "235",
    VotingStationName: "SIYAVELA CRECHE",
    Address: "EMAHLUBINI  HIGHFLATS  IXOPO",
    Latitude: "-30.1601",
    Longitude: "30.4011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13682",
    WardLookupId: "1698",
    VDNumber: "43990151",
    RegisteredPopulation: "474",
    VotingStationName: "NONGENGANE COMBINED PRIMARY SCHOOL",
    Address: "EMGANGENI  BHOBHOBHO  UMZINTO",
    Latitude: "-30.2183",
    Longitude: "30.3422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13683",
    WardLookupId: "1698",
    VDNumber: "43990162",
    RegisteredPopulation: "593",
    VotingStationName: "UKUTHULA HIGH SCHOOL",
    Address: "UKUTHULA  ISANGCWABA  IXOPO",
    Latitude: "-30.1997",
    Longitude: "30.4038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13684",
    WardLookupId: "1698",
    VDNumber: "43990263",
    RegisteredPopulation: "281",
    VotingStationName: "BAMBISANANI CRECHE",
    Address: "UMKHUMBANE AREA  BHOBHOBHO AREA  UMZINTO",
    Latitude: "-30.2279",
    Longitude: "30.3579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13685",
    WardLookupId: "1698",
    VDNumber: "43993356",
    RegisteredPopulation: "306",
    VotingStationName: "IZASENGWA LOWER PRIMARY SCHOOL",
    Address: "UMKHUNYA ROAD D1101  IXOPO  IXOPO",
    Latitude: "-30.1653",
    Longitude: "30.4185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13686",
    WardLookupId: "1698",
    VDNumber: "43993604",
    RegisteredPopulation: "350",
    VotingStationName: "SIYAKHA CRECHE",
    Address: "BHOBHOBHO  UMZINTO  VULAMEHLO",
    Latitude: "-30.202",
    Longitude: "30.3647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13687",
    WardLookupId: "1698",
    VDNumber: "43994324",
    RegisteredPopulation: "368",
    VotingStationName: "KWABACWABILEYO METHODIST CHURCH",
    Address: "IXOPO  SANGCWABA  IXOPO",
    Latitude: "-30.1803",
    Longitude: "30.3937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13688",
    WardLookupId: "1699",
    VDNumber: "43990342",
    RegisteredPopulation: "1065",
    VotingStationName: "ST NICHOLAUS COMBINED PRIMARY SCHOOL",
    Address: "GUDLUCINGO  HLOKOZI A/A  HIGHFLATS",
    Latitude: "-30.3333",
    Longitude: "30.2311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13689",
    WardLookupId: "1699",
    VDNumber: "43990353",
    RegisteredPopulation: "1433",
    VotingStationName: "ZAMAFUTHI COMBINED PRIMARY SCHOOL",
    Address: "ENTAPHA   HLOKOZI AREA  HIGHFLATS",
    Latitude: "-30.3203",
    Longitude: "30.2363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13690",
    WardLookupId: "1699",
    VDNumber: "43990498",
    RegisteredPopulation: "1706",
    VotingStationName: "HLOKOZI HIGH SCHOOL",
    Address: "HLOKOZI  MAYIBUYE A/A  HIGHFLATS",
    Latitude: "-30.338",
    Longitude: "30.2653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13691",
    WardLookupId: "1700",
    VDNumber: "43990230",
    RegisteredPopulation: "795",
    VotingStationName: "NDELA SECONDARY SCHOOL",
    Address: "MGANGENI  UMZINTO  UMZINTO",
    Latitude: "-30.2305",
    Longitude: "30.367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13692",
    WardLookupId: "1700",
    VDNumber: "43990386",
    RegisteredPopulation: "2528",
    VotingStationName: "IMPUNGA HIGH SCHOOL",
    Address: "NJANE  JOLIVET  UMZINTO",
    Latitude: "-30.2658",
    Longitude: "30.3494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13693",
    WardLookupId: "1700",
    VDNumber: "43994425",
    RegisteredPopulation: "1026",
    VotingStationName: "UKUTHULA TRIBAL COURT",
    Address: "ENJANE   UKUTHULA   UMZINTO",
    Latitude: "-30.2549",
    Longitude: "30.3618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13694",
    WardLookupId: "1701",
    VDNumber: "43990364",
    RegisteredPopulation: "681",
    VotingStationName: "BONGINDAWO COMBINED PRIMARY SCHOOL",
    Address: "BONGINDAWE  UMZINTO  VULAMEHLO",
    Latitude: "-30.3075",
    Longitude: "30.3314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13695",
    WardLookupId: "1701",
    VDNumber: "43990375",
    RegisteredPopulation: "762",
    VotingStationName: "LINGELETHU LOWER PRIMARY SCHOOL",
    Address: "GOXO LOC.  HLUTHANKUNGU A/A  HIGHFLATS",
    Latitude: "-30.2914",
    Longitude: "30.2995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13696",
    WardLookupId: "1701",
    VDNumber: "43990409",
    RegisteredPopulation: "1177",
    VotingStationName: "SOMELULWAZI HIGHER PRIMARY SCHOOL",
    Address: "ETHAFENI LOCATION  HLUTHANKUNGU A/A  HIGHFLATS",
    Latitude: "-30.2877",
    Longitude: "30.3251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13697",
    WardLookupId: "1701",
    VDNumber: "43990410",
    RegisteredPopulation: "1127",
    VotingStationName: "LUSIBALUKHULU HIGH SCHOOL",
    Address: "SPORTING NATION LOCATION  HLOKOZI A/A  HIGHFLATS",
    Latitude: "-30.3623",
    Longitude: "30.2907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13698",
    WardLookupId: "1701",
    VDNumber: "43993985",
    RegisteredPopulation: "843",
    VotingStationName: "MANTULELA COMBINED PRIMARY SCHOOL",
    Address: "MAYIBUYE LOC.  HLOKOZI A/A  HIGHFLATS",
    Latitude: "-30.3397",
    Longitude: "30.2704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13699",
    WardLookupId: "1701",
    VDNumber: "43994874",
    RegisteredPopulation: "345",
    VotingStationName: "MBALENHLE PRIMARY SCHOOL",
    Address: "JOLIVET  GOXE  HIGHFLATS",
    Latitude: "-30.2776",
    Longitude: "30.3348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13700",
    WardLookupId: "1702",
    VDNumber: "43771507",
    RegisteredPopulation: "359",
    VotingStationName: "KINTAIL PRIMARY SCHOOL",
    Address: "NHLAVINI LOCATION  EMAKHUZENI A/A  IXOPO",
    Latitude: "-30.0477",
    Longitude: "30.2415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13701",
    WardLookupId: "1702",
    VDNumber: "43990038",
    RegisteredPopulation: "645",
    VotingStationName: "PHUMOBALA COMBINED PRIMARY SCHOOL",
    Address: "EGQUMENI LOC.  EMBO A/A   HIGHFLATS",
    Latitude: "-30.1225",
    Longitude: "30.3302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13702",
    WardLookupId: "1702",
    VDNumber: "43990049",
    RegisteredPopulation: "413",
    VotingStationName: "SIZISIZWE HIGH SCHOOL",
    Address: "EMAKHUZENI  SPRINGVALE  IXOPO",
    Latitude: "-30.1144",
    Longitude: "30.3063",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13703",
    WardLookupId: "1702",
    VDNumber: "43993569",
    RegisteredPopulation: "1376",
    VotingStationName: "MAZONGO COMBINED PRIMARY SCHOOL",
    Address: "KO-KHOZA  EMAHLABATHINI A/A  IXOPO",
    Latitude: "-30.15",
    Longitude: "30.3033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13704",
    WardLookupId: "1702",
    VDNumber: "43993570",
    RegisteredPopulation: "392",
    VotingStationName: "LUDLIKI LOWER PRIMARY SCHOOL",
    Address: "KOZONDI-SPRINGVALE AREA  HIGHFLATS  IXOPO",
    Latitude: "-30.076",
    Longitude: "30.3082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13705",
    WardLookupId: "1702",
    VDNumber: "43994212",
    RegisteredPopulation: "273",
    VotingStationName: "SPRINGVALE MISSION SCHOOL",
    Address: "SPRINGVALE  EMAKHUZENI/EMISSION  IXOPO",
    Latitude: "-30.0988",
    Longitude: "30.2914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13706",
    WardLookupId: "1703",
    VDNumber: "43992232",
    RegisteredPopulation: "485",
    VotingStationName: "SKHUNYANE PRIMARY SCHOOL",
    Address: "EBHAYI  NHLALWANE   HIGHFLATS",
    Latitude: "-30.3949",
    Longitude: "30.1815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13707",
    WardLookupId: "1703",
    VDNumber: "43992456",
    RegisteredPopulation: "470",
    VotingStationName: "SUKUMA HALL",
    Address: "KWAMZAMO  HIGHFLATS  IXOPO",
    Latitude: "-30.3735",
    Longitude: "30.1457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13708",
    WardLookupId: "1703",
    VDNumber: "43992872",
    RegisteredPopulation: "788",
    VotingStationName: "MHLABATSHANE MISSION",
    Address: "ST FAITHS ROAD  MHLABATSHANA  IXOPO",
    Latitude: "-30.3963",
    Longitude: "30.1481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13709",
    WardLookupId: "1703",
    VDNumber: "43992962",
    RegisteredPopulation: "351",
    VotingStationName: "MALEZULU COMBINED PRIMARY SCHOOL",
    Address: "MALEZULU AREA  HIGHFLATS  IXOPO",
    Latitude: "-30.373",
    Longitude: "30.1469",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13710",
    WardLookupId: "1703",
    VDNumber: "43992973",
    RegisteredPopulation: "1480",
    VotingStationName: "ZWELITHULE PRIMARY SCHOOL",
    Address: "KWATHATHANI  KWATHATHANI AREA   HIGHFLATS",
    Latitude: "-30.3608",
    Longitude: "30.1718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13711",
    WardLookupId: "1704",
    VDNumber: "43992322",
    RegisteredPopulation: "312",
    VotingStationName: "KWADLADLA SCHOOL",
    Address: "KWADLADLA  NOKWEJA  IXOPO",
    Latitude: "-30.3185",
    Longitude: "30.0029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13712",
    WardLookupId: "1704",
    VDNumber: "43992377",
    RegisteredPopulation: "497",
    VotingStationName: "BHEKUKUPHIWA COMBINED PRIMARY SCHOOL",
    Address: "NOKWEJA LOCATION  TOPS   IXOPO",
    Latitude: "-30.3137",
    Longitude: "30.0268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13713",
    WardLookupId: "1704",
    VDNumber: "43992388",
    RegisteredPopulation: "270",
    VotingStationName: "MARY HELP PRIMARY SCHOOL",
    Address: "NCALU LOCATION  MARY HELP MISSION   IXOPO",
    Latitude: "-30.2696",
    Longitude: "30.0511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13714",
    WardLookupId: "1704",
    VDNumber: "43992399",
    RegisteredPopulation: "1121",
    VotingStationName: "KWAMBINGELELI COMBINED PRIMARY SCHOOL",
    Address: "NOKWEJA  PLAINHILL  IXOPO",
    Latitude: "-30.2614",
    Longitude: "30.0202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13715",
    WardLookupId: "1704",
    VDNumber: "43993075",
    RegisteredPopulation: "1398",
    VotingStationName: "NOKWEJA COMBINED PRIMARY SCHOOL",
    Address: "NOKWEJA  NOKWEJA A/A  IXOPO",
    Latitude: "-30.2907",
    Longitude: "30.0234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13716",
    WardLookupId: "1704",
    VDNumber: "43994223",
    RegisteredPopulation: "376",
    VotingStationName: "MSHOBASHOBI COMBINED PRIMARY SCHOOL",
    Address: "NEAR MARY HELP MISSION  NOKWEJA AREA   IXOPO",
    Latitude: "-30.2642",
    Longitude: "30.0375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13717",
    WardLookupId: "1704",
    VDNumber: "43994234",
    RegisteredPopulation: "320",
    VotingStationName: "MAKHONZA COMBINED PRIMARY SCHOOL",
    Address: "MAKHONZA AREA  NOKWEJA   IXOPO",
    Latitude: "-30.2859",
    Longitude: "29.9986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13718",
    WardLookupId: "1705",
    VDNumber: "43992186",
    RegisteredPopulation: "512",
    VotingStationName: "DUNGE TRADITIONAL COURT",
    Address: "BOVINI AREA  EMAZABEKWENI A/A  HIGHFLATS",
    Latitude: "-30.3096",
    Longitude: "30.0724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13719",
    WardLookupId: "1705",
    VDNumber: "43992311",
    RegisteredPopulation: "1514",
    VotingStationName: "AMAZABEKO HIGH SCHOOL",
    Address: "EMAZABEKWENI  AMAZABEKO  HIGHFLATS",
    Latitude: "-30.2537",
    Longitude: "30.0986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13720",
    WardLookupId: "1705",
    VDNumber: "43992478",
    RegisteredPopulation: "789",
    VotingStationName: "NTABAKUNUKA COMBINED PRIMARY SCHOOL",
    Address: "NTABAKUNUKA  EMAZABEKWENI  IXOPO",
    Latitude: "-30.2435",
    Longitude: "30.1053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13721",
    WardLookupId: "1705",
    VDNumber: "43993772",
    RegisteredPopulation: "828",
    VotingStationName: "SKEYI COMBINED PRIMARY SCHOOL",
    Address: "UMGODI  BLACK STORE A/A  HIGHFLATS",
    Latitude: "-30.3038",
    Longitude: "30.1544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13722",
    WardLookupId: "1705",
    VDNumber: "43994010",
    RegisteredPopulation: "471",
    VotingStationName: "KHULUMA HIGHER PRIMARY SCHOOL",
    Address: "BOVINI  EMAZABEKWENI A/A  HIGHFLATS",
    Latitude: "-30.3043",
    Longitude: "30.0805",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13723",
    WardLookupId: "1705",
    VDNumber: "43994896",
    RegisteredPopulation: "194",
    VotingStationName: "UMDABU COMMUNITY HALL",
    Address: "MDABU  EMAZABEKWENI   HIGHFLATS",
    Latitude: "-30.273",
    Longitude: "30.0823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13724",
    WardLookupId: "1706",
    VDNumber: "43771484",
    RegisteredPopulation: "674",
    VotingStationName: "HIGHFLATS PUBLIC PRIMARY SCHOOL",
    Address: "HIGHFLATS MAIN ROAD  HIGHFLATS  HIGHFLATS",
    Latitude: "-30.261",
    Longitude: "30.1991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13725",
    WardLookupId: "1706",
    VDNumber: "43771495",
    RegisteredPopulation: "196",
    VotingStationName: "STUARTSVILLE GROUND (TENT)",
    Address: "STUARTVILLE NORTH   HLUTHANKUNGU  HIGHFLATS",
    Latitude: "-30.2582",
    Longitude: "30.3077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13726",
    WardLookupId: "1706",
    VDNumber: "43771518",
    RegisteredPopulation: "208",
    VotingStationName: "MEHLOMANE COMBINED PRIMARY SCHOOL",
    Address: "NHLAMVINI LOCATION  HIGHFLATS  IXOPO",
    Latitude: "-30.2247",
    Longitude: "30.1832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13727",
    WardLookupId: "1706",
    VDNumber: "43992175",
    RegisteredPopulation: "751",
    VotingStationName: "THEMBA MNGUNI HALL",
    Address: "ESITOLO ESIMNYAMA  MASHUMI  HIGHFLATS",
    Latitude: "-30.3524",
    Longitude: "30.1382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13728",
    WardLookupId: "1706",
    VDNumber: "43992197",
    RegisteredPopulation: "266",
    VotingStationName: "MBAMBALALA PUBLIC SCHOOL",
    Address: "UMGODI AREA  BLACK STORE  HIGHFLATS",
    Latitude: "-30.3507",
    Longitude: "30.057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13729",
    WardLookupId: "1706",
    VDNumber: "43993200",
    RegisteredPopulation: "394",
    VotingStationName: "NHLAVANA COMBINED PRIMARY SCHOOL",
    Address: "NHLAVANA  ELANGENI/UMZIMKHULU  IXOPO",
    Latitude: "-30.3772",
    Longitude: "30.1289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13730",
    WardLookupId: "1706",
    VDNumber: "43993996",
    RegisteredPopulation: "817",
    VotingStationName: "MASHUMI COMBINED PRIMARY SCHOOL",
    Address: "UMGODI  MASHUMI  HIGHFLATS",
    Latitude: "-30.3401",
    Longitude: "30.1241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13731",
    WardLookupId: "1706",
    VDNumber: "43994885",
    RegisteredPopulation: "263",
    VotingStationName: "UMGODI PRIMARY SCHOOL",
    Address: "UMGODI   NHLANGWINI  HIGHFLATS",
    Latitude: "-30.3504",
    Longitude: "30.0981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13732",
    WardLookupId: "1707",
    VDNumber: "43992287",
    RegisteredPopulation: "1032",
    VotingStationName: "NHLANGWINI MULTIPURPOSE CENTRE",
    Address: "NDWEBU  NHLANGWINI  UMZUMBE",
    Latitude: "-30.4419",
    Longitude: "30.2038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13733",
    WardLookupId: "1707",
    VDNumber: "43992298",
    RegisteredPopulation: "1183",
    VotingStationName: "NDWEBU COMBINED PRIMARY SCHOOL",
    Address: "NDWEBU  HIGHFLATS  UMZUMBE",
    Latitude: "-30.4179",
    Longitude: "30.2023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13734",
    WardLookupId: "1707",
    VDNumber: "43994313",
    RegisteredPopulation: "930",
    VotingStationName: "ELWAZI HIGH SCHOOL",
    Address: "NHLANGWINI ROAD  HIGHFLATS  UMZUMBE [UMZUMBE]",
    Latitude: "-30.4056",
    Longitude: "30.2113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13735",
    WardLookupId: "1707",
    VDNumber: "43994706",
    RegisteredPopulation: "89",
    VotingStationName: "MBHEKABANTU SOCIETY CHURCH",
    Address: "ENKULU  NHLANGWINI  HIGHFLATS",
    Latitude: "-30.4588",
    Longitude: "30.2037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13736",
    WardLookupId: "1707",
    VDNumber: "43994717",
    RegisteredPopulation: "459",
    VotingStationName: "MDIBANISO COMPREHENSIVE SCHOOL",
    Address: "HIGHFLATS  NDWEBU  NHLANGWINI",
    Latitude: "-30.4286",
    Longitude: "30.1909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13737",
    WardLookupId: "1708",
    VDNumber: "11830014",
    RegisteredPopulation: "1056",
    VotingStationName: "INDAWANA PRIMARY SCHOOL",
    Address:
      "NEXT NDAWANA SENIOR SECONDARY   ELUSUTHU VILLAGE, NDAWANA A/A  UMZIMKULU",
    Latitude: "-29.9905",
    Longitude: "29.4501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13738",
    WardLookupId: "1708",
    VDNumber: "11830025",
    RegisteredPopulation: "909",
    VotingStationName: "MANGENI JUNIOR SECONDARY SCHOOL",
    Address: "MANGENI VILLAGE  UMZIMKULU",
    Latitude: "-30.0631",
    Longitude: "29.5332",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13739",
    WardLookupId: "1708",
    VDNumber: "11830081",
    RegisteredPopulation: "824",
    VotingStationName: "DELAMZI JUNIOR SECONDARY SCHOOL",
    Address: "DELAMUZI  UMZIMKULU",
    Latitude: "-30.0975",
    Longitude: "29.5809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13740",
    WardLookupId: "1708",
    VDNumber: "11830799",
    RegisteredPopulation: "307",
    VotingStationName: "LUKHASINI PRIMARY SCHOOL",
    Address: "ELUKHASINI  LUKHASINI A/A  UMZIMKULU",
    Latitude: "-30.0707",
    Longitude: "29.6065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13741",
    WardLookupId: "1708",
    VDNumber: "11830878",
    RegisteredPopulation: "220",
    VotingStationName: "TSAWULE  JUNIOR SECONDARY SCHOOL",
    Address: "TSAWULE  UMZIMKHULU RURAL",
    Latitude: "-30.0544",
    Longitude: "29.478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13742",
    WardLookupId: "1708",
    VDNumber: "11830889",
    RegisteredPopulation: "433",
    VotingStationName: "ST  PATRIC  JUNIOR SECONDARY SCHOOL",
    Address: "KHAYEKA  UMZIMKULU",
    Latitude: "-30.0716",
    Longitude: "29.5681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13743",
    WardLookupId: "1708",
    VDNumber: "11830890",
    RegisteredPopulation: "229",
    VotingStationName: "MGANU JUNIOR SECONDARY SCHOOL",
    Address: "NGWAGWANE VILLAGE   NGWAGWANE  UMZIMKULU",
    Latitude: "-29.9856",
    Longitude: "29.4709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13744",
    WardLookupId: "1708",
    VDNumber: "11831497",
    RegisteredPopulation: "735",
    VotingStationName: "NDAWANA PRIMARY SCHOOL",
    Address:
      "NEXT TO WESSEL CHURCH   MPOPHOMENI LOCATION ,NDAWANA A/A  UMZIMKHULU",
    Latitude: "-29.9809",
    Longitude: "29.435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13745",
    WardLookupId: "1708",
    VDNumber: "11831576",
    RegisteredPopulation: "165",
    VotingStationName: "ELUCINGWENI PRIMARY SCHOOL",
    Address: "ELUCINGWENI  UMZIMKHULU",
    Latitude: "-30.0862",
    Longitude: "29.6336",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13746",
    WardLookupId: "1709",
    VDNumber: "11830058",
    RegisteredPopulation: "1027",
    VotingStationName: "CORINTH JUNIOR SECONDARY SCHOOL",
    Address: "CORINTH LOC  UMZIMKULU",
    Latitude: "-30.0986",
    Longitude: "29.7227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13747",
    WardLookupId: "1709",
    VDNumber: "11830069",
    RegisteredPopulation: "918",
    VotingStationName: "ENGWAQA JUNIOR SECONDARY SCHOOL",
    Address: "ENGWAQA LOC  ENGWAQA  UMZIMKULU",
    Latitude: "-30.1361",
    Longitude: "29.6571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13748",
    WardLookupId: "1709",
    VDNumber: "11830867",
    RegisteredPopulation: "1010",
    VotingStationName: "NYANISWENI JUNIOR SECONDARY SCHOOL",
    Address: "ENYANISWENI VILLAGE  NYANISWENI  UMZIMKULU",
    Latitude: "-30.1154",
    Longitude: "29.6783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13749",
    WardLookupId: "1709",
    VDNumber: "11830913",
    RegisteredPopulation: "330",
    VotingStationName: "EDGERTON PRIMARY SCHOOL",
    Address: "EDGERTON  EDGETON  UMZIMKHULU",
    Latitude: "-30.1083",
    Longitude: "29.6547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13750",
    WardLookupId: "1709",
    VDNumber: "11830980",
    RegisteredPopulation: "818",
    VotingStationName: "LUPONGOLO COMBINED SCHOOL",
    Address: "LUPONGOLO VILLAGE  LUPONGOLO  UMZIMKULU",
    Latitude: "-30.1065",
    Longitude: "29.7109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13751",
    WardLookupId: "1709",
    VDNumber: "11831004",
    RegisteredPopulation: "505",
    VotingStationName: "RIVERSIDE  HALL",
    Address: "NEXT TO SASSA OFFICES   RIVERSIDE LOCATION,RIVERSIDE  UMZIMKULU",
    Latitude: "-30.0841",
    Longitude: "29.7015",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13752",
    WardLookupId: "1710",
    VDNumber: "11830092",
    RegisteredPopulation: "1070",
    VotingStationName: "NTSIKENI JUNIOR SECONDARY SCHOOL",
    Address: "NEXT TO NTSIKENI HALL  NTSIKENI LOC  UMZIMKULU",
    Latitude: "-30.138",
    Longitude: "29.5856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13753",
    WardLookupId: "1710",
    VDNumber: "11830531",
    RegisteredPopulation: "611",
    VotingStationName: "BOMVINI JUNIOR SECONDARY SCHOOL",
    Address: "BOMVINI LOC  UMZIMKULU",
    Latitude: "-30.1076",
    Longitude: "29.6171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13754",
    WardLookupId: "1710",
    VDNumber: "11830553",
    RegisteredPopulation: "473",
    VotingStationName: "MALENGE JUNIOR SECONDARY SCHOOL",
    Address: "MALENGE LOC  UMZIMKULU",
    Latitude: "-30.1528",
    Longitude: "29.6161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13755",
    WardLookupId: "1710",
    VDNumber: "11830902",
    RegisteredPopulation: "484",
    VotingStationName: "TENT (NEXT TO JONGIKHAYA STORE)",
    Address: "MNCWEBA VILLAGE  BOMVINI  UMZIMKHULU",
    Latitude: "-30.0964",
    Longitude: "29.5991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13756",
    WardLookupId: "1710",
    VDNumber: "11830946",
    RegisteredPopulation: "472",
    VotingStationName: "ST GREGORY MISSION",
    Address: "SPRINGS VILLAGE  NTSIKENI  UMZIMKHULU",
    Latitude: "-30.144",
    Longitude: "29.5889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13757",
    WardLookupId: "1710",
    VDNumber: "11830957",
    RegisteredPopulation: "356",
    VotingStationName: "KUYASA PRE-SCHOOL TENT",
    Address: "KWADEDA LOCATION  UMZIMKHULU",
    Latitude: "-30.1394",
    Longitude: "29.6265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13758",
    WardLookupId: "1710",
    VDNumber: "11831295",
    RegisteredPopulation: "395",
    VotingStationName: "ST. BERNARD SENIOR PRIMARY SCHOOL",
    Address: "NOZIYINGILI   NOZIYINGILI LOCATION  UMZIMKHULU [UMZIMKULU]",
    Latitude: "-30.1673",
    Longitude: "29.6437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13759",
    WardLookupId: "1711",
    VDNumber: "11830047",
    RegisteredPopulation: "564",
    VotingStationName: "HILLTOP JUNIOR SECONDARY SCHOOL",
    Address: "NOMARHANJANA LOCATION  MARAMBENI A/A  UMZIMKULU",
    Latitude: "-30.2124",
    Longitude: "29.539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13760",
    WardLookupId: "1711",
    VDNumber: "11830070",
    RegisteredPopulation: "816",
    VotingStationName: "DULATI JUNIOR SECONDARY SCHOOL",
    Address: "MAGQAGQENI LOCATION  MORAMBENI  UMZIMKULU",
    Latitude: "-30.1782",
    Longitude: "29.5582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13761",
    WardLookupId: "1711",
    VDNumber: "11830542",
    RegisteredPopulation: "688",
    VotingStationName: "MOUNT SHEBA JUNIOR SECONDARY SCHOOL",
    Address: "MT SHERBA LOCATION  MARAMBENI A/A  UMZIMKULU",
    Latitude: "-30.201",
    Longitude: "29.5814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13762",
    WardLookupId: "1711",
    VDNumber: "11830924",
    RegisteredPopulation: "831",
    VotingStationName: "TENT (NEXT BAWINILE STORE)",
    Address:
      "NEXT TO BAWINILE STORE  BAWINILE VILLAGE, MARAMBENI A/A  UMZIMKULU",
    Latitude: "-30.1556",
    Longitude: "29.5715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13763",
    WardLookupId: "1711",
    VDNumber: "11830935",
    RegisteredPopulation: "215",
    VotingStationName: "ZAMAZELA SENIOR PRIMARY SCHOOL",
    Address: "MASAMINI LOCATION  MARAMBENI A/A  UMZIMKULU",
    Latitude: "-30.1818",
    Longitude: "29.5263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13764",
    WardLookupId: "1711",
    VDNumber: "11830979",
    RegisteredPopulation: "1162",
    VotingStationName: "MKANGALA SENIOR PRIMARY SCHOOL",
    Address: "MKANGALA LOCATION  MARAMBENI A/A  UMZIMKHULU",
    Latitude: "-30.2285",
    Longitude: "29.5434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13765",
    WardLookupId: "1711",
    VDNumber: "11831509",
    RegisteredPopulation: "95",
    VotingStationName: "ST AUGUSTINE`S PRIMARY SCHOOL",
    Address: "ST AUGUSTINE  MARAMBENI A/A  UMZIMKHULU RURAL",
    Latitude: "-30.1797",
    Longitude: "29.5953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13766",
    WardLookupId: "1712",
    VDNumber: "11830148",
    RegisteredPopulation: "693",
    VotingStationName: "ANTIOCH JUNIOR SECONDARY SCHOOL",
    Address: "ANTIOCH VILLAGE  ANTIOCH   UMZIMKULU",
    Latitude: "-30.0935",
    Longitude: "29.8121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13767",
    WardLookupId: "1712",
    VDNumber: "11830205",
    RegisteredPopulation: "572",
    VotingStationName: "KUKHANYENI COMBINED SCHOOL",
    Address: "KUKHANYENI LOCATION  KUKHANYENI  UMZIMKULU",
    Latitude: "-30.0997",
    Longitude: "29.7675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13768",
    WardLookupId: "1712",
    VDNumber: "11830216",
    RegisteredPopulation: "809",
    VotingStationName: "ST MICHAELS JUNIOR SECONDARY SCHOOL",
    Address: "NEXT TO CLINIC  KWANDABAYILALI  UMZIMKULU",
    Latitude: "-30.1346",
    Longitude: "29.8191",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13769",
    WardLookupId: "1712",
    VDNumber: "11830777",
    RegisteredPopulation: "841",
    VotingStationName: "TONJENI JUNIOR SECONDARY SCHOOL",
    Address: "TONJENI LOCATION  TONJENI A/A  UMZIMKULU",
    Latitude: "-30.1293",
    Longitude: "29.7683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13770",
    WardLookupId: "1712",
    VDNumber: "11830991",
    RegisteredPopulation: "294",
    VotingStationName: "SYRIA SENIOR PRIMARY SCHOOL",
    Address: "SYRIA  SYRIA LOCATION  UMZIMKHULU",
    Latitude: "-30.1119",
    Longitude: "29.8409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13771",
    WardLookupId: "1712",
    VDNumber: "11831510",
    RegisteredPopulation: "335",
    VotingStationName: "BUNGENDE JUNIOR SECONDARY SCHOOL",
    Address: "GWIJENDLINI  UMZIMKHULU",
    Latitude: "-30.1361",
    Longitude: "29.8007",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13772",
    WardLookupId: "1712",
    VDNumber: "11831554",
    RegisteredPopulation: "140",
    VotingStationName: "NGWANGWANE CHURCH",
    Address: "NGWANGWANE LOCATION  NGWANGWANE  UMZIMKHULU",
    Latitude: "-30.066",
    Longitude: "29.7843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13773",
    WardLookupId: "1713",
    VDNumber: "11830115",
    RegisteredPopulation: "380",
    VotingStationName: "LOURDES JUNIOR SECONDARY SCHOOL",
    Address: "NEXT TO ROMAN CATHOLIC MISSION  LOURDES A/A  UMZIMKULU",
    Latitude: "-30.2012",
    Longitude: "29.6829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13774",
    WardLookupId: "1713",
    VDNumber: "11830126",
    RegisteredPopulation: "354",
    VotingStationName: "THEMBALIHLE JUNIOR SECONDARY SCHOOL",
    Address: "THEMBALIHLE  UMZIMKULU",
    Latitude: "-30.2537",
    Longitude: "29.7152",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13775",
    WardLookupId: "1713",
    VDNumber: "11830250",
    RegisteredPopulation: "538",
    VotingStationName: "ST ALOIS JUNIOR SECONDARY SCHOOL",
    Address: "DIPHINI LOCATION  LOURDES A/A  UMZIMKULU",
    Latitude: "-30.21",
    Longitude: "29.6944",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13776",
    WardLookupId: "1713",
    VDNumber: "11830564",
    RegisteredPopulation: "714",
    VotingStationName: "ST PAULS JUNIOR SECONDARY SCHOOL",
    Address: "ST PAULS VILLAGE  UMZIMKULU",
    Latitude: "-30.2593",
    Longitude: "29.6746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13777",
    WardLookupId: "1713",
    VDNumber: "11830766",
    RegisteredPopulation: "191",
    VotingStationName: "UPPER IBISI JUNIOR SECONDARY SCHOOL",
    Address: "NDZOMBANE LOCATION  UMZIMKULU",
    Latitude: "-30.2917",
    Longitude: "29.7255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13778",
    WardLookupId: "1713",
    VDNumber: "11831015",
    RegisteredPopulation: "311",
    VotingStationName: "CETYWAYO JUNIOR SECONDARY SCHOOL",
    Address: "VUKUZENZELE LOCATION  LOURDES  A/A  UMZIMKULU",
    Latitude: "-30.2156",
    Longitude: "29.6228",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13779",
    WardLookupId: "1713",
    VDNumber: "11831082",
    RegisteredPopulation: "396",
    VotingStationName: "VUKANI JUNIOR SECONDARY SCHOOL",
    Address: "MAGCAKINI LOCATION  MAGCAKINI  UMZIMKULU",
    Latitude: "-30.2783",
    Longitude: "29.7423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13780",
    WardLookupId: "1713",
    VDNumber: "11831420",
    RegisteredPopulation: "935",
    VotingStationName: "QONDOKUHLE HALL",
    Address:
      "EMATYENI LOCATION  LOURDES A/A  (KZN435 - UMZIMKHULU [UMZIMKULU])",
    Latitude: "-30.2131",
    Longitude: "29.7129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13781",
    WardLookupId: "1714",
    VDNumber: "11830171",
    RegisteredPopulation: "1230",
    VotingStationName: "PETROS JOZANA JUNIOR SECONDARY SCHOOL CLASS ROOM 1",
    Address: "NAZARETH  UMZIMKHULU  NAZARETH",
    Latitude: "-30.1829",
    Longitude: "29.8707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13782",
    WardLookupId: "1714",
    VDNumber: "11830182",
    RegisteredPopulation: "396",
    VotingStationName: "GQWALU PRIMARY SCHOOL",
    Address: "GQWALU LOCATION  GQWALI A/A  UMZIMKULU",
    Latitude: "-30.197",
    Longitude: "29.9121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13783",
    WardLookupId: "1714",
    VDNumber: "11830193",
    RegisteredPopulation: "734",
    VotingStationName: "MFULAMHLE JUNIOR SECONDARY SCHOOL",
    Address: "MFULAMHLE LOCATION  UMZIMKULU",
    Latitude: "-30.2074",
    Longitude: "29.9132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13784",
    WardLookupId: "1714",
    VDNumber: "11831026",
    RegisteredPopulation: "1243",
    VotingStationName: "NGUSE JUNIOR SECONDARY SCHOOL",
    Address: "KWANGUSE LOCATION  MFULAMHLE A/A  UMZIMKULU",
    Latitude: "-30.2143",
    Longitude: "29.931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13785",
    WardLookupId: "1714",
    VDNumber: "11831307",
    RegisteredPopulation: "317",
    VotingStationName: "MARANJANA JUNIOR SECONDARY SCHOOL",
    Address: "MADUNA LOCATION  MFULAMHLE A/A  UMZIMKHULU [UMZIMKULU]",
    Latitude: "-30.2077",
    Longitude: "29.8844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13786",
    WardLookupId: "1715",
    VDNumber: "11830104",
    RegisteredPopulation: "622",
    VotingStationName: "SINGISI JUNIOR SECONDARY SCHOOL",
    Address: "SINGISI LOC  UMZIMKULU",
    Latitude: "-30.2506",
    Longitude: "29.5481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13787",
    WardLookupId: "1715",
    VDNumber: "11830137",
    RegisteredPopulation: "667",
    VotingStationName: "NGUNJINI JUNIOR SECONDARY SCHOOL",
    Address: "NGUNJINI LOC  UMZIMKULU",
    Latitude: "-30.3252",
    Longitude: "29.6328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13788",
    WardLookupId: "1715",
    VDNumber: "11830261",
    RegisteredPopulation: "494",
    VotingStationName: "MNCEBA JUNIOR SECONDARY SCHOOL",
    Address: "KWASENTI  UMZIMKULU",
    Latitude: "-30.3572",
    Longitude: "29.7144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13789",
    WardLookupId: "1715",
    VDNumber: "11830272",
    RegisteredPopulation: "932",
    VotingStationName: "BRAND WOOD JUNIOR SECONDARY SCHOOL",
    Address: "BRAND WOOD  UMZIMKULU",
    Latitude: "-30.3513",
    Longitude: "29.6186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13790",
    WardLookupId: "1715",
    VDNumber: "11830968",
    RegisteredPopulation: "410",
    VotingStationName: "ZIBUKELI SENIOR PRIMARY SCHOOL",
    Address: "DRESINI  DRESINI VILLAGE  UMZIMKHULU",
    Latitude: "-30.268",
    Longitude: "29.5016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13791",
    WardLookupId: "1715",
    VDNumber: "11831071",
    RegisteredPopulation: "270",
    VotingStationName: "VULAMEHLO JUNIOR SECONDARY SCHOOL",
    Address: "ROCKMOUNT LOCATION  MTHWANA A/A  UMZIMKULU",
    Latitude: "-30.3509",
    Longitude: "29.6738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13792",
    WardLookupId: "1715",
    VDNumber: "11831385",
    RegisteredPopulation: "315",
    VotingStationName: "VULINDLELA PRIMARY SCHOOL",
    Address: "FOURTEEN LOCATION  MTHWANE A/A  UMZIMKHULU",
    Latitude: "-30.3703",
    Longitude: "29.7519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13793",
    WardLookupId: "1715",
    VDNumber: "11831431",
    RegisteredPopulation: "164",
    VotingStationName: "PHELANYONI PRIMARY SCHOOL",
    Address:
      "PHELANYONI  PHELANYONI LOCATION  (KZN435 - UMZIMKHULU [UMZIMKULU])",
    Latitude: "-30.3236",
    Longitude: "29.7239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13794",
    WardLookupId: "1715",
    VDNumber: "11831442",
    RegisteredPopulation: "263",
    VotingStationName: "DRIEFONTEIN SECONDARY SCHOOL",
    Address: "DRIEFONTEIN   DRIEFONTEIN A/A  UMZIMKHULU",
    Latitude: "-30.3088",
    Longitude: "29.6694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13795",
    WardLookupId: "1716",
    VDNumber: "11830249",
    RegisteredPopulation: "1053",
    VotingStationName: "MHLABAS COMBINED SCHOOL",
    Address: "CHANCELE LOCATION  UMZIMKULU",
    Latitude: "-30.2995",
    Longitude: "29.8164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13796",
    WardLookupId: "1716",
    VDNumber: "11830283",
    RegisteredPopulation: "553",
    VotingStationName: "MBUMBULWANA JUNIOR SECONDARY SCHOOL",
    Address: "KWACEBE VILLAGE  KWANDZELU  UMZIMKULU",
    Latitude: "-30.31",
    Longitude: "29.7907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13797",
    WardLookupId: "1716",
    VDNumber: "11830373",
    RegisteredPopulation: "709",
    VotingStationName: "FOUNTAINS JUNIOR SECONDARY SCHOOL",
    Address: "MATHATHANE LOCATION  UMZIMKULU",
    Latitude: "-30.3215",
    Longitude: "29.9119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13798",
    WardLookupId: "1716",
    VDNumber: "11830610",
    RegisteredPopulation: "592",
    VotingStationName: "EZIMPUNGENI JUNIOR SECONDARY SCHOOL",
    Address: "EZIMPUNGENI LOC  UMZIMKULU",
    Latitude: "-30.3635",
    Longitude: "29.8149",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13799",
    WardLookupId: "1716",
    VDNumber: "11831093",
    RegisteredPopulation: "352",
    VotingStationName: "NDLOZANA JUNIOR SECONDARY SCHOOL",
    Address: "N/A  NZELU- CEBE  UMZIMKULU",
    Latitude: "-30.3047",
    Longitude: "29.763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13800",
    WardLookupId: "1716",
    VDNumber: "11831105",
    RegisteredPopulation: "377",
    VotingStationName: "MANDLAZI JUNIOR SECONDARY SCHOOL",
    Address: "N/A  MAHAWINI  UMZIMKULU",
    Latitude: "-30.3516",
    Longitude: "29.8283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13801",
    WardLookupId: "1717",
    VDNumber: "11830294",
    RegisteredPopulation: "610",
    VotingStationName: "READSDALE JUNIOR SECONDARY SCHOOL",
    Address: "READSDALE A/A  READSDALE LOCATION  UMZIMKULU",
    Latitude: "-30.4448",
    Longitude: "29.6968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13802",
    WardLookupId: "1717",
    VDNumber: "11830306",
    RegisteredPopulation: "512",
    VotingStationName: "SAMARIA JUNIOR SECONDARY SCHOOL",
    Address: "ST CONRADE LOCATION  MTHWANE A/A  UMZIMKULU",
    Latitude: "-30.4028",
    Longitude: "29.6807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13803",
    WardLookupId: "1717",
    VDNumber: "11830676",
    RegisteredPopulation: "484",
    VotingStationName: "NGQUMARENI JUNIOR SECONDARY SCHOOL",
    Address: "NGQUMARENI LOC  UMZIMKULU",
    Latitude: "-30.5271",
    Longitude: "29.6245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13804",
    WardLookupId: "1717",
    VDNumber: "11830700",
    RegisteredPopulation: "857",
    VotingStationName: "GLENGARY JUNIOR SECONDARY SCHOOL",
    Address: "GLENGARY LOCATION  MTHWANA A/A  UMZIMKULU",
    Latitude: "-30.3798",
    Longitude: "29.6519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13805",
    WardLookupId: "1717",
    VDNumber: "11831059",
    RegisteredPopulation: "250",
    VotingStationName: "TWEEFONTEIN SECONDARY SCHOOL",
    Address: "TWEEFONTEIN LOCATION  UMTHWANE A/A  UMZIMKHULU",
    Latitude: "-30.4301",
    Longitude: "29.664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13806",
    WardLookupId: "1717",
    VDNumber: "11831060",
    RegisteredPopulation: "588",
    VotingStationName: "FAIRFIELD PRE -SCHOOL",
    Address: "KWANJUNGA VILLAGE  MTHWANE A/A  UMZIMKHULU",
    Latitude: "-30.3677",
    Longitude: "29.6366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13807",
    WardLookupId: "1717",
    VDNumber: "11831127",
    RegisteredPopulation: "378",
    VotingStationName: "VEZUKHANYA JUNIOR SECONDARY SCHOOL",
    Address: "KWAFILE LOCATION  MTHWANE A/A  UMZIMKULU",
    Latitude: "-30.4742",
    Longitude: "29.6752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13808",
    WardLookupId: "1717",
    VDNumber: "11831138",
    RegisteredPopulation: "148",
    VotingStationName: "MOYENI JUNIOR SECONDARY SCHOOL",
    Address: "MPOLA VILLAGE  MTHWANE A/A  UMZIMKULU",
    Latitude: "-30.5096",
    Longitude: "29.7069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13809",
    WardLookupId: "1717",
    VDNumber: "11831453",
    RegisteredPopulation: "578",
    VotingStationName: "BLOEMFONTEIN PRIMARY SCHOOL",
    Address: "NCAMBELE LOCATION  MTHWANE A/A  UMZIMKHULU",
    Latitude: "-30.4042",
    Longitude: "29.6502",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13810",
    WardLookupId: "1717",
    VDNumber: "11831464",
    RegisteredPopulation: "104",
    VotingStationName: "SONWABILE PRIMARY SCHOOL",
    Address:
      "KLIPSTRUIT LOCATION  MTHWANA A/A  (KZN435 - UMZIMKHULU [UMZIMKULU])",
    Latitude: "-30.5159",
    Longitude: "29.6735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13811",
    WardLookupId: "1718",
    VDNumber: "11830351",
    RegisteredPopulation: "981",
    VotingStationName: "IBISI COMMUNITY HALL",
    Address: "IBISI LOC  UMZIMKULU",
    Latitude: "-30.4102",
    Longitude: "29.8894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13812",
    WardLookupId: "1718",
    VDNumber: "11830362",
    RegisteredPopulation: "1019",
    VotingStationName: "MLOZANA JUNIOR SECONDARY SCHOOL",
    Address: "KWAMEYI LOCATION  UMZIMKHULU  KWAMEYI LOCATION",
    Latitude: "-30.4051",
    Longitude: "29.9038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13813",
    WardLookupId: "1718",
    VDNumber: "11830621",
    RegisteredPopulation: "838",
    VotingStationName: "VIERKANT JUNIOR SECONDARY SCHOOL",
    Address: "KWAJAMES  UMZIMKULU",
    Latitude: "-30.3904",
    Longitude: "29.8711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13814",
    WardLookupId: "1718",
    VDNumber: "11830711",
    RegisteredPopulation: "622",
    VotingStationName: "SIHLONTLWENI JUNIOR SECONDARY SCHOOL",
    Address: "SIHLONTLWENI  UMZIMKULU",
    Latitude: "-30.4057",
    Longitude: "29.8313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13815",
    WardLookupId: "1718",
    VDNumber: "11831161",
    RegisteredPopulation: "645",
    VotingStationName: "ILUNGELO PROJECT-HALL",
    Address: "CATER MSOMI VILLAGE  IBISI  UMZIMKHULU",
    Latitude: "-30.4171",
    Longitude: "29.8959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13816",
    WardLookupId: "1719",
    VDNumber: "11830036",
    RegisteredPopulation: "160",
    VotingStationName: "KWATSHAKA JUNIOR SECONDARY SCHOOL",
    Address: "MBUMBANI LOC  UMZIMKULU",
    Latitude: "-30.4797",
    Longitude: "29.8049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13817",
    WardLookupId: "1719",
    VDNumber: "11830340",
    RegisteredPopulation: "518",
    VotingStationName: "BOMBASI JUNIOR SECONDARY SCHOOL",
    Address: "MACHUNWINI LOCATION  MACHUNWINI A/A  UMZIMKULU",
    Latitude: "-30.4997",
    Longitude: "29.7896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13818",
    WardLookupId: "1719",
    VDNumber: "11830496",
    RegisteredPopulation: "1092",
    VotingStationName: "RIETVLEI JUNIOR SECONDARY SCHOOL",
    Address: "EMHLANGENI LOCATIONI  RIETVLEI  UMZIMKHULU",
    Latitude: "-30.4896",
    Longitude: "29.8337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13819",
    WardLookupId: "1719",
    VDNumber: "11830698",
    RegisteredPopulation: "794",
    VotingStationName: "MBUZWENI JUNIOR SECONDARY SCHOOL",
    Address: "EMBUZWENI LOCATION  MBUZWENI LOC  UMZIMKULU",
    Latitude: "-30.4948",
    Longitude: "29.8113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13820",
    WardLookupId: "1719",
    VDNumber: "11831150",
    RegisteredPopulation: "533",
    VotingStationName: "ZIMEMA SENIOR PRIMARY",
    Address: "ZIMEMA  UMZIMKULU  UMZIMKULU",
    Latitude: "-30.5053",
    Longitude: "29.7618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13821",
    WardLookupId: "1719",
    VDNumber: "11831183",
    RegisteredPopulation: "544",
    VotingStationName: "RIETVLEI SENIOR SECONDARY SCHOOL",
    Address: "NXAPHANXAPHENI LOCATION  RIETVLEI   UMZIMKULU",
    Latitude: "-30.4826",
    Longitude: "29.8403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13822",
    WardLookupId: "1719",
    VDNumber: "11831318",
    RegisteredPopulation: "204",
    VotingStationName: "JIRAH SENIOR PRIMARY SCHOOL",
    Address: "MASAMINI LOCATION  MASAMINI  UMZIMKHULU [UMZIMKULU]",
    Latitude: "-30.4438",
    Longitude: "29.8393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13823",
    WardLookupId: "1720",
    VDNumber: "11830441",
    RegisteredPopulation: "288",
    VotingStationName: "TEEKLOOF JUNIOR SECONDARY SCHOOL",
    Address: "NGUDWINI  UMZIMKULU",
    Latitude: "-30.417",
    Longitude: "29.9361",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13824",
    WardLookupId: "1720",
    VDNumber: "11830463",
    RegisteredPopulation: "810",
    VotingStationName: "MFUNDWENI JUNIOR SECONDARY SCHOOL",
    Address: "MFUNDWENI LOC  UMZIMKULU",
    Latitude: "-30.4345",
    Longitude: "29.9017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13825",
    WardLookupId: "1720",
    VDNumber: "11830474",
    RegisteredPopulation: "1358",
    VotingStationName: "ZWELIBANZI JUNIOR SECONDARY SCHOOL",
    Address: "KOKSHILL LOC  UMZIMKULU",
    Latitude: "-30.4442",
    Longitude: "29.8672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13826",
    WardLookupId: "1720",
    VDNumber: "11831217",
    RegisteredPopulation: "803",
    VotingStationName: "NTLABENI JUNIOR SECONDARY SCHOOL",
    Address: "NTLABENI LOCATION  NTLABENI  UMZIMKULU",
    Latitude: "-30.4611",
    Longitude: "29.8714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13827",
    WardLookupId: "1720",
    VDNumber: "11831228",
    RegisteredPopulation: "781",
    VotingStationName: "EMFUNDWENI PRE SCHOOL",
    Address: "EBHODINI LOCATION  EMFUNDWENI  UMZIMKHULU",
    Latitude: "-30.4249",
    Longitude: "29.8982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13828",
    WardLookupId: "1720",
    VDNumber: "11831352",
    RegisteredPopulation: "347",
    VotingStationName: "HAMBANATHI PRIMARY SCHOOL",
    Address: "HAMBANATHI LOCATION  (KZN435 - UMZIMKHULU [UMZIMKULU])",
    Latitude: "-30.4143",
    Longitude: "29.9522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13829",
    WardLookupId: "1721",
    VDNumber: "11830452",
    RegisteredPopulation: "804",
    VotingStationName: "JERICO JUNIOR SECONDARY SCHOOL",
    Address: "JERICO LOC  UMZIMKULU",
    Latitude: "-30.4657",
    Longitude: "29.9662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13830",
    WardLookupId: "1721",
    VDNumber: "11830508",
    RegisteredPopulation: "474",
    VotingStationName: "MAGOGO JUNIOR SECONDARY SCHOOL",
    Address: "GUGWINI A/A  UMZIMKULU",
    Latitude: "-30.5282",
    Longitude: "29.9856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13831",
    WardLookupId: "1721",
    VDNumber: "11830519",
    RegisteredPopulation: "1640",
    VotingStationName: "GUGWINI JUNIOR SECONDARY SCHOOL",
    Address: "GUGWINI LOC  UMZIMKULU",
    Latitude: "-30.495",
    Longitude: "29.9355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13832",
    WardLookupId: "1721",
    VDNumber: "11830520",
    RegisteredPopulation: "122",
    VotingStationName: "LUKHOLWENI JUNIOR SECONDARY SCHOOL",
    Address: "LUKHOLWENI  MABHISANA A/A  FODO TRIBAL AUTHORITY,UMZIMKHULU",
    Latitude: "-30.446",
    Longitude: "29.9918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13833",
    WardLookupId: "1721",
    VDNumber: "11831239",
    RegisteredPopulation: "910",
    VotingStationName: "MZOKHANYO JUNIOR SECONDARY SCHOOL",
    Address: "MZOKHANYO  MZOKHANYO VILLAGE  UMZIMKHULU",
    Latitude: "-30.5176",
    Longitude: "29.9205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13834",
    WardLookupId: "1721",
    VDNumber: "11831419",
    RegisteredPopulation: "261",
    VotingStationName: "ENDLOVINI PRE-SCHOOL",
    Address: "ENDLOVINI  FODO TRIBAL AUTHORITY",
    Latitude: "-30.484",
    Longitude: "30.0069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13835",
    WardLookupId: "1721",
    VDNumber: "11831565",
    RegisteredPopulation: "158",
    VotingStationName: "MASAMINI CHURCH",
    Address: "MASAMINI LOCATION  GUGWINI A/A  FODO TRIBAL AUTHORITY,UMZIMKHULU",
    Latitude: "-30.5427",
    Longitude: "29.9636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13836",
    WardLookupId: "1722",
    VDNumber: "11830430",
    RegisteredPopulation: "639",
    VotingStationName: "GLOUCESTER JUNIOR SECONDARY SCHOOL",
    Address: "GEBENI  GCEBENI  UMZIMKULU",
    Latitude: "-30.4013",
    Longitude: "30.0748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13837",
    WardLookupId: "1722",
    VDNumber: "11830665",
    RegisteredPopulation: "538",
    VotingStationName: "DEEPKLOOF JUNIOR SECONDARY SCHOOL",
    Address: "DEEPKLOOF LOC  UMZIMKULU",
    Latitude: "-30.3769",
    Longitude: "29.9577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13838",
    WardLookupId: "1722",
    VDNumber: "11830744",
    RegisteredPopulation: "829",
    VotingStationName: "LONGKLOOF JUNIOR SECONDARY SCHOOL",
    Address: "KROEMHOEK A/A  UMZIMKULU",
    Latitude: "-30.4072",
    Longitude: "30.0132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13839",
    WardLookupId: "1722",
    VDNumber: "11830755",
    RegisteredPopulation: "739",
    VotingStationName: "SUMMERFIELD JUNIOR SECONDARY SCHOOL",
    Address: "SUMMERFIELD  UMZIMKHULU  UMZIMKHULU",
    Latitude: "-30.3822",
    Longitude: "30.0306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13840",
    WardLookupId: "1722",
    VDNumber: "11830823",
    RegisteredPopulation: "354",
    VotingStationName: "THORNE BUSH JUNIOR SECONDARY SCHOOL",
    Address: "THORNE BUSH  THORNE  BUSH  UMZIMKULU",
    Latitude: "-30.4136",
    Longitude: "29.9875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13841",
    WardLookupId: "1722",
    VDNumber: "11831240",
    RegisteredPopulation: "482",
    VotingStationName: "RAYINI-STORE",
    Address: "GCEBENI LOCATION  KROEMHOEK A/A  UMZIMKULU",
    Latitude: "-30.4082",
    Longitude: "30.0555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13842",
    WardLookupId: "1722",
    VDNumber: "11831543",
    RegisteredPopulation: "723",
    VotingStationName: "WESSEL CHURCH",
    Address: "BOMBO  LOCATION  BOMBO LOCATION  UMZIMKHULU",
    Latitude: "-30.38",
    Longitude: "30.0182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13843",
    WardLookupId: "1723",
    VDNumber: "11740014",
    RegisteredPopulation: "1302",
    VotingStationName: "UMZIMKULU TOWN HALL",
    Address: "169 MAIN STREET  UMZIMKULU  UMZIMKULU",
    Latitude: "-30.26",
    Longitude: "29.9405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13844",
    WardLookupId: "1723",
    VDNumber: "11740025",
    RegisteredPopulation: "1514",
    VotingStationName: "VUMAZONKE SENIOR PRIMARY SCHOOL.",
    Address: "SISULU  SISULU UMZIMKHULU  UMZIMKHULU",
    Latitude: "-30.2727",
    Longitude: "29.9406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13845",
    WardLookupId: "1723",
    VDNumber: "11740036",
    RegisteredPopulation: "684",
    VotingStationName: "UMZIMKULU JUNIOR SECONDARY SCHOOL",
    Address: "NEXT TO MUSILUM CHURCH  MAJALIDINI  UMZIMKULU",
    Latitude: "-30.266",
    Longitude: "29.9364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13846",
    WardLookupId: "1723",
    VDNumber: "11740047",
    RegisteredPopulation: "1738",
    VotingStationName: "MZWANDILE MHLAULI HALL",
    Address: "EXTENSION 6  NEW CITY   UMZIMKHULU",
    Latitude: "-30.2652",
    Longitude: "29.927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13847",
    WardLookupId: "1724",
    VDNumber: "11830384",
    RegisteredPopulation: "1277",
    VotingStationName: "CLYDESDALE JUNIOR SECONDARY SCHOOL",
    Address: "CLYDESDALE  UMZIMKHULU  CLYDESDALE LOCATION",
    Latitude: "-30.2959",
    Longitude: "29.9408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13848",
    WardLookupId: "1724",
    VDNumber: "11830395",
    RegisteredPopulation: "961",
    VotingStationName: "HOPEWELL JUNIOR SECONDARY SCHOOL",
    Address: "KWADAYI LOCATION  UMZIMKULU",
    Latitude: "-30.3136",
    Longitude: "29.9696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13849",
    WardLookupId: "1724",
    VDNumber: "11831284",
    RegisteredPopulation: "1443",
    VotingStationName: "EBUTA JUNIOR SECONDARY SCHOOL",
    Address:
      "BOARDER  UMZIMKHULU  NEAR TOTAL GARAGE BOARDER LOCATION, CLYDESDALE A/A",
    Latitude: "-30.2794",
    Longitude: "29.932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13850",
    WardLookupId: "1724",
    VDNumber: "11831363",
    RegisteredPopulation: "314",
    VotingStationName: "GIJIMA (TENT)",
    Address: "GIJIMA   UMZIMKHULU",
    Latitude: "-30.327",
    Longitude: "29.9529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13851",
    WardLookupId: "1724",
    VDNumber: "11831374",
    RegisteredPopulation: "798",
    VotingStationName: "VULEKANI SPECIAL SCHOOL",
    Address: "CLYDESDALE  UMZIMKHULU",
    Latitude: "-30.2878",
    Longitude: "29.9431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13852",
    WardLookupId: "1725",
    VDNumber: "11830317",
    RegisteredPopulation: "313",
    VotingStationName: "TEX NYBERT JUNIOR SECONDARY SCHOOL",
    Address: "MTHALENI LOCATION  MTHWANA A/A  UMZIMKULU",
    Latitude: "-30.4603",
    Longitude: "29.7435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13853",
    WardLookupId: "1725",
    VDNumber: "11830328",
    RegisteredPopulation: "448",
    VotingStationName: "BONTRAND JUNIOR SECONDARY SCHOOL",
    Address: "BONTRAND  BONTRAND A/A  UMZIMKULU",
    Latitude: "-30.4121",
    Longitude: "29.8157",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13854",
    WardLookupId: "1725",
    VDNumber: "11830339",
    RegisteredPopulation: "571",
    VotingStationName: "ST FAITHS JUNIOR SECONDARY SCHOOL",
    Address: "ESKHULU LOCATION  MTHWANE A/A  UMZIMKULU",
    Latitude: "-30.4149",
    Longitude: "29.7183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13855",
    WardLookupId: "1725",
    VDNumber: "11830722",
    RegisteredPopulation: "589",
    VotingStationName: "VICTORY JUNIOR SECONDARY SCHOOL",
    Address: "EMMISA LOCATION  MTHWANA A/A  UMZIMKULU",
    Latitude: "-30.4203",
    Longitude: "29.7619",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13856",
    WardLookupId: "1725",
    VDNumber: "11830812",
    RegisteredPopulation: "489",
    VotingStationName: "ZWELONKE JUNIOR SECONDARY SCHOOL",
    Address: "PANIKUKU LOCATION  MTHWANA A/A  UMZIMKULU",
    Latitude: "-30.4381",
    Longitude: "29.7461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13857",
    WardLookupId: "1725",
    VDNumber: "11831149",
    RegisteredPopulation: "135",
    VotingStationName: "BONGINCEBA JUNIOR SECONDARY SCHOOL",
    Address: "KWABASE LOCATION  BASE  UMZIMKULU",
    Latitude: "-30.4885",
    Longitude: "29.7461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13858",
    WardLookupId: "1725",
    VDNumber: "11831172",
    RegisteredPopulation: "186",
    VotingStationName: "MAGQAQGENI  ROMAN  CATHOLIC  CHURCH",
    Address: "EMAGQAGQENI LOCATION  MAGQAGQENI  UMZIMKULU",
    Latitude: "-30.3797",
    Longitude: "29.7172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13859",
    WardLookupId: "1725",
    VDNumber: "11831396",
    RegisteredPopulation: "407",
    VotingStationName: "GCWENSA JUNIOR SECONDARY SCHOOL",
    Address: "GCWENTSA LOCATION  MTHWANE A/A  UMZIMKHULU",
    Latitude: "-30.3923",
    Longitude: "29.7714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13860",
    WardLookupId: "1725",
    VDNumber: "11831408",
    RegisteredPopulation: "484",
    VotingStationName: "MVOLOZANA PRIMARY SCHOOL",
    Address:
      "MVOLOZANA LOCATION  MTHWANA A/A  (KZN435 - UMZIMKHULU [UMZIMKULU])",
    Latitude: "-30.4569",
    Longitude: "29.7304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13861",
    WardLookupId: "1726",
    VDNumber: "11830238",
    RegisteredPopulation: "587",
    VotingStationName: "MVUBUKAZI JUNIOR SECONDARY SCHOOL",
    Address: "KWABHALA LOCATION  MVUBUKAZI A/A  UMZIMKULU",
    Latitude: "-30.2739",
    Longitude: "29.8384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13862",
    WardLookupId: "1726",
    VDNumber: "11830575",
    RegisteredPopulation: "638",
    VotingStationName: "ST BARNABAS JUNIOR SECONARY SCHOOL",
    Address: "ST BANABAS LOCATION  BARNABAS  UMZIMKHULU",
    Latitude: "-30.2245",
    Longitude: "29.8222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13863",
    WardLookupId: "1726",
    VDNumber: "11830609",
    RegisteredPopulation: "499",
    VotingStationName: "BUHLEBEZWE JUNIOR SECONDARY SCHOOL",
    Address: "ELUSIZINI LOCATION  MVUBUKAZI A/A  UMZIMKULU",
    Latitude: "-30.2603",
    Longitude: "29.8268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13864",
    WardLookupId: "1726",
    VDNumber: "11830834",
    RegisteredPopulation: "396",
    VotingStationName: "NTLAMBAMASOKA JUNIOR SECONDARY SCHOOL",
    Address: "NTLAMBAMASOKA   UMZIMKULU",
    Latitude: "-30.2711",
    Longitude: "29.8898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13865",
    WardLookupId: "1726",
    VDNumber: "11830845",
    RegisteredPopulation: "490",
    VotingStationName: "EMMAUS JUNIOR SECONARY SCHOOL",
    Address: "NEXT TO ROMAN CATHOLIC MISSION  EMMAUS  UMZIMKULU",
    Latitude: "-30.2607",
    Longitude: "29.7917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13866",
    WardLookupId: "1726",
    VDNumber: "11831037",
    RegisteredPopulation: "222",
    VotingStationName: "MBIZWENI  HALL",
    Address: "MBIZWENI  UMZIMKULU",
    Latitude: "-30.2568",
    Longitude: "29.9265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13867",
    WardLookupId: "1726",
    VDNumber: "11831116",
    RegisteredPopulation: "244",
    VotingStationName: "ZAMANI SENIOR PRIMARY SCHOOL",
    Address: "CABAZI LOCATION  MVUBUKAZI A/A  UMZIMKULU",
    Latitude: "-30.292",
    Longitude: "29.8418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13868",
    WardLookupId: "1726",
    VDNumber: "11831475",
    RegisteredPopulation: "662",
    VotingStationName: "ZIZAMELE PRIMARY  SCHOOL",
    Address: "NEXT TO TUCKSHOP  MOUNTAIN  UMZIMKHULU",
    Latitude: "-30.2382",
    Longitude: "29.8479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13869",
    WardLookupId: "1727",
    VDNumber: "11830407",
    RegisteredPopulation: "1045",
    VotingStationName: "HIGHLANDS JUNIOR SECONDARY SCHOOL",
    Address: "MADAKENI  UMZIMKHULU  NEXT TO SPORTSFIELD KWAMADAKENI LOCATION",
    Latitude: "-30.3374",
    Longitude: "29.9364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13870",
    WardLookupId: "1727",
    VDNumber: "11830418",
    RegisteredPopulation: "289",
    VotingStationName: "STRANGERS REST JUNIOR SECONDARY SCHOOL",
    Address: "ESIGANGQA LOCATION  KREOMHOEK A/A  UMZIMKULU",
    Latitude: "-30.3437",
    Longitude: "29.9732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13871",
    WardLookupId: "1727",
    VDNumber: "11830429",
    RegisteredPopulation: "304",
    VotingStationName: "L MTHIMKULU JUNIOR SECONDARY SCHOOL",
    Address: "NKUNGWINI LOCATION  KROEMHOEK A/A  UMZIMKULU",
    Latitude: "-30.3569",
    Longitude: "29.9908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13872",
    WardLookupId: "1727",
    VDNumber: "11830632",
    RegisteredPopulation: "1166",
    VotingStationName: "THEMBENI JUNIOR SECONDARY SCHOOL CLASS ROOM 1",
    Address: "ETEMBENI  UMZIMKHULU  ETHEMBENI LOCATION",
    Latitude: "-30.3724",
    Longitude: "29.9072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13873",
    WardLookupId: "1727",
    VDNumber: "11830643",
    RegisteredPopulation: "1012",
    VotingStationName: "WASHBANK JUNIOR SECONDARY SCHOOL CLASS ROOM 1",
    Address: "KWASKUMANE   UMZIMKHULU  KWASKUMANE LOCATION KROMHOEK A/A",
    Latitude: "-30.3597",
    Longitude: "29.953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13874",
    WardLookupId: "1727",
    VDNumber: "11830654",
    RegisteredPopulation: "382",
    VotingStationName: "KROMHOEK JUNIOR SECONDARY SCHOOL",
    Address: "KROEMHOEK LOCATIO  KROEMHOEK A/A  UMZIMKULU",
    Latitude: "-30.3418",
    Longitude: "30.012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13875",
    WardLookupId: "1727",
    VDNumber: "11831330",
    RegisteredPopulation: "254",
    VotingStationName: "STRALHOEK SENIOR PRIMARY SCHOOL",
    Address: "BLEMA LOCATION  ETHEMBENI A/A  UMZIMKHULU [UMZIMKULU]",
    Latitude: "-30.358",
    Longitude: "29.9012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13876",
    WardLookupId: "1727",
    VDNumber: "11831341",
    RegisteredPopulation: "363",
    VotingStationName: "NEW METHODIST CHURCH OF SOUTHERN AFRIC",
    Address: "MOYENI LOCATION  KROEMHOEK A/A  UMZIMKHULU",
    Latitude: "-30.3698",
    Longitude: "29.9716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13877",
    WardLookupId: "1728",
    VDNumber: "11830159",
    RegisteredPopulation: "679",
    VotingStationName: "DUMISA JUNIOR SECONDARY SCHOOL",
    Address: "DUMISA LOCATION  LOURDES A/A  UMZIMKULU",
    Latitude: "-30.2416",
    Longitude: "29.748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13878",
    WardLookupId: "1728",
    VDNumber: "11830160",
    RegisteredPopulation: "467",
    VotingStationName: "NOMBEWU JUNIOR SECONDARY SCHOOL",
    Address: "NGQOKOZWENI LOCATION  SILAHLA A/A  UMZIMKULU",
    Latitude: "-30.2006",
    Longitude: "29.8513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13879",
    WardLookupId: "1728",
    VDNumber: "11830227",
    RegisteredPopulation: "443",
    VotingStationName: "MKATSHANA JUNIOR SECONDARY SCHOOL",
    Address: "MAKHENCENI LOCATION  NOGIDIDI  UMZIMKULU",
    Latitude: "-30.1489",
    Longitude: "29.8503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13880",
    WardLookupId: "1728",
    VDNumber: "11830586",
    RegisteredPopulation: "402",
    VotingStationName: "MYEMBE JUNIOR SECONDARY SCHOOL",
    Address: "MYEMBE LOCATION  MYEMBE VILLAGE  UMZIMKULU",
    Latitude: "-30.1766",
    Longitude: "29.7853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13881",
    WardLookupId: "1728",
    VDNumber: "11830597",
    RegisteredPopulation: "327",
    VotingStationName: "NYAKA JUNIOR SECONDARY SCHOOL",
    Address: "KWAMAKHANYA LOCATION  NYAKA A/A  UMZIMKHULU",
    Latitude: "-30.1604",
    Longitude: "29.8031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13882",
    WardLookupId: "1728",
    VDNumber: "11830733",
    RegisteredPopulation: "420",
    VotingStationName: "GUDLINTABA JUNIOR SECONDARY SCHOOL",
    Address: "KWAGUDLINTABA LOCATION  GUDLINTABA LOCATION  UMZIMKULU",
    Latitude: "-30.1734",
    Longitude: "29.8229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13883",
    WardLookupId: "1728",
    VDNumber: "11830856",
    RegisteredPopulation: "456",
    VotingStationName: "CABANE JUNIOR SECONDARY SCHOOL",
    Address: "SAYIMANE LOCATION  CABANE  UMZIMKULU",
    Latitude: "-30.2025",
    Longitude: "29.7974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13884",
    WardLookupId: "1728",
    VDNumber: "11831521",
    RegisteredPopulation: "537",
    VotingStationName: "EMMAUS SENIOR SECONARY SCHOOL",
    Address: "EMMAUS  UMZIMKHULU",
    Latitude: "-30.2614",
    Longitude: "29.7737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13885",
    WardLookupId: "1729",
    VDNumber: "11830485",
    RegisteredPopulation: "438",
    VotingStationName: "MAHOBE JUNIOR SECONDARY SCHOOL",
    Address:
      "NEXT ROMAN CATHOLIC MISSION  EMATSHENI LOCATION,MAHOBE   UMZIMKULU",
    Latitude: "-30.4857",
    Longitude: "29.891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13886",
    WardLookupId: "1729",
    VDNumber: "11830687",
    RegisteredPopulation: "904",
    VotingStationName: "DEEPDALE JUNIOR SECONDARY SCHOOL",
    Address: "DEEPDALE  UMZIMKULU",
    Latitude: "-30.5549",
    Longitude: "29.902",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13887",
    WardLookupId: "1729",
    VDNumber: "11830801",
    RegisteredPopulation: "346",
    VotingStationName: "COMMONSVALLEY JUNIOR SECONDARY SCHOOL",
    Address: "KWAMBULUMBA LOCATION  COMMONSVALLEY  UMZIMKULU",
    Latitude: "-30.5255",
    Longitude: "29.8524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13888",
    WardLookupId: "1729",
    VDNumber: "11831194",
    RegisteredPopulation: "329",
    VotingStationName: "EASTLAND SENIOR PRIMARY SCHOOL",
    Address: "MAHLOMANE LOCATION  MAHLOMANE  UMZIMKULU",
    Latitude: "-30.5071",
    Longitude: "29.8271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13889",
    WardLookupId: "1729",
    VDNumber: "11831206",
    RegisteredPopulation: "232",
    VotingStationName: "MAWUSHE SENIOR PRIMARY SCHOOL",
    Address: "RAWUKA LOCATION  RAWUKA  UMZIMKULU",
    Latitude: "-30.4875",
    Longitude: "29.8536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13890",
    WardLookupId: "1729",
    VDNumber: "11831251",
    RegisteredPopulation: "417",
    VotingStationName: "ZAMUKULUNGISA SENIOR PRIMARY SCHOOL",
    Address: "KWAKHILIVA LOCATION  GUGWINI  UMZIMKULU",
    Latitude: "-30.5269",
    Longitude: "29.8968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13891",
    WardLookupId: "1729",
    VDNumber: "11831329",
    RegisteredPopulation: "162",
    VotingStationName: "MAHOBE CHURCH",
    Address:
      "ESIPHAHLENI LOCATION  MAHOBE, GUGWINI A/A  UMZIMKHULU [UMZIMKULU]",
    Latitude: "-30.4789",
    Longitude: "29.9237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13892",
    WardLookupId: "1729",
    VDNumber: "11831486",
    RegisteredPopulation: "304",
    VotingStationName: "BAMBANANI COMBINED SCHOOL",
    Address: "EFAMU  RIETVLEI  EFAMU",
    Latitude: "-30.498",
    Longitude: "29.8659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13893",
    WardLookupId: "1729",
    VDNumber: "11831532",
    RegisteredPopulation: "246",
    VotingStationName: "MASAKANE JUNIOR PRIMARY SCHOOL",
    Address: "MEMEKA LOCATION  KWAMEMEKA A/A  UMZIMKHULU RURAL",
    Latitude: "-30.546",
    Longitude: "29.9261",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13894",
    WardLookupId: "1729",
    VDNumber: "11831587",
    RegisteredPopulation: "269",
    VotingStationName: "DOVEDALE JUNIOR SECONDARY SCHOOL",
    Address: "MAKHALENI  GUGWINI  GUGWINI",
    Latitude: "-30.5567",
    Longitude: "29.865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13895",
    WardLookupId: "1730",
    VDNumber: "43772711",
    RegisteredPopulation: "313",
    VotingStationName: "RIDGE COMBINED SCHOOL",
    Address:
      "00 RIDGE MKHOMAZANA  MKHOMAZANA VILLAGE, NEXT TO NGUBANE TRADING STORE  UNDERBERG",
    Latitude: "-29.6717",
    Longitude: "29.6809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13896",
    WardLookupId: "1730",
    VDNumber: "43772722",
    RegisteredPopulation: "104",
    VotingStationName: "SOLOKOHLO P SCHOOL",
    Address:
      "00 STOFFELTON  STOFFELTON, IMPENDLE ROAD FROM BULWER TOWN.  BATLOKOA TRIBAL AUTHORITY",
    Latitude: "-29.6844",
    Longitude: "29.7358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13897",
    WardLookupId: "1730",
    VDNumber: "43772878",
    RegisteredPopulation: "684",
    VotingStationName: "MQATSHENI HALL",
    Address:
      "D1243 UMQATSHENI   UMQATSHENI LOCATION, NEXT TO MQATSHENI PRIMARY   HIMEVILLE",
    Latitude: "-29.5985",
    Longitude: "29.5175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13898",
    WardLookupId: "1730",
    VDNumber: "43773127",
    RegisteredPopulation: "1018",
    VotingStationName: "SOMANGWE PRIMARY SCHOOL",
    Address:
      "STEPMORE NOTTINGHAM ROAD  STEPMORE, NEXTT TO STEPMORE HEALTH FARCILITY  BATLOKOA TRIBAL AUTHORITY",
    Latitude: "-29.5945",
    Longitude: "29.5824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13899",
    WardLookupId: "1730",
    VDNumber: "43774498",
    RegisteredPopulation: "433",
    VotingStationName: "NTWASAHLOBO CRECHE",
    Address:
      "NTWASAHLOBO LOCATION  NERTHERBY, IMPENDLE ROAD NEXT TO NTWASAHLOBO HIGH   IMPENDLE",
    Latitude: "-29.6429",
    Longitude: "29.6792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13900",
    WardLookupId: "1730",
    VDNumber: "43774500",
    RegisteredPopulation: "145",
    VotingStationName: "THUNZI PRIMARY SCHOOL",
    Address: "KWATHUNZI  UNDERBERG",
    Latitude: "-29.6114",
    Longitude: "29.6284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13901",
    WardLookupId: "1730",
    VDNumber: "43774678",
    RegisteredPopulation: "454",
    VotingStationName: "PHONSIMPI PRIMARY SCHOOL",
    Address:
      "UMQATSHENI   KWAZINKWANA AREA TOWARDS  VERGENEGEN BOARDER  HIMEVILLE",
    Latitude: "-29.5792",
    Longitude: "29.5381",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13902",
    WardLookupId: "1730",
    VDNumber: "43774937",
    RegisteredPopulation: "192",
    VotingStationName: "MHLANGENI COMMUNITY HALL",
    Address: "00 MHLANGENI  MHLANGENI NEAR THE MKHOMAZI RIVER  BATLOKOA TRIBAL",
    Latitude: "-29.5931",
    Longitude: "29.5553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13903",
    WardLookupId: "1730",
    VDNumber: "43774948",
    RegisteredPopulation: "167",
    VotingStationName: "MKHOMAZANA PRIMARY SCHOOL (RESERVED SPACE)TENT",
    Address:
      "00 MKHOMAZANA  MKHOMAZANA, NEXT TO RIDGE AREA PRIMARY SCHOOL  RIGDE AREA, EMGQUTSHANA",
    Latitude: "-29.6729",
    Longitude: "29.6428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13904",
    WardLookupId: "1731",
    VDNumber: "43772834",
    RegisteredPopulation: "493",
    VotingStationName: "BERGVIEW PRIMARY SCHOOL",
    Address:
      "MAIN ROAD, OPPOSITE ELAND REST  DRAKENSBURG,TOWARDS DRAKENSBURG GARDENS/HOTEL  UNDERBERG,",
    Latitude: "-29.7438",
    Longitude: "29.2719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13905",
    WardLookupId: "1731",
    VDNumber: "43772856",
    RegisteredPopulation: "455",
    VotingStationName: "KWAPITELA PRIMARY SCHOOL",
    Address:
      "MAIN ROAD, KWAPITELA  HIMEVILLE, SANIPASS ROAD KWAMHLOPHE FARM  HIMEVILLE",
    Latitude: "-29.6552",
    Longitude: "29.4922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13906",
    WardLookupId: "1731",
    VDNumber: "43772867",
    RegisteredPopulation: "527",
    VotingStationName: "ENHLANHLENI COMBINED SECONDARY SCHOOL",
    Address:
      "MAIN ROAD, PEVENCEY ROAD   ENHLANHLENI, NEXT TO THE HALL AND CRECHE  UNDERBERG",
    Latitude: "-29.7919",
    Longitude: "29.6131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13907",
    WardLookupId: "1731",
    VDNumber: "43774465",
    RegisteredPopulation: "268",
    VotingStationName: "BEERSHEBA PRIMARY SCHOOL",
    Address:
      "00 MAHWAQA LOCATION   MAHWAQA, NEXT TO ST TERRESA CATHOLIIC CHURCH  UNDERBERG",
    Latitude: "-29.7801",
    Longitude: "29.6643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13908",
    WardLookupId: "1731",
    VDNumber: "43970025",
    RegisteredPopulation: "1592",
    VotingStationName: "HIMEVILLE, JABULANI HALL",
    Address:
      "ARBUCKLE STREET  HIMEVILLE LOCATION OPPOSITE A PLAYGROUND  HIMEVILLE",
    Latitude: "-29.7586",
    Longitude: "29.5076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13909",
    WardLookupId: "1732",
    VDNumber: "43772812",
    RegisteredPopulation: "52",
    VotingStationName: "ANDERSON-WORKSHOP",
    Address:
      "N/A KILMUN ROAD  GLENNELLY TRUST, SPRINGBURN FARM NEXT TO RIVER  UNDERBERG, SPRINGBURN FARM",
    Latitude: "-29.8703",
    Longitude: "29.596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13910",
    WardLookupId: "1732",
    VDNumber: "43772823",
    RegisteredPopulation: "479",
    VotingStationName: "ST FRANCIS  CHURCH",
    Address:
      "N/A BUSHMANSNEK MAIN ROAD  BUSHMANSNEK FARM, BUSHMASNECK ROAD  UNDERBERG",
    Latitude: "-29.8083",
    Longitude: "29.3583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13911",
    WardLookupId: "1732",
    VDNumber: "43970014",
    RegisteredPopulation: "2783",
    VotingStationName: "UNDERBERG RAILWAY STATION",
    Address: "OLD MAIN ROAD   UNDERBERG  UNDERBERG",
    Latitude: "-29.7929",
    Longitude: "29.4968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13912",
    WardLookupId: "1732",
    VDNumber: "43970036",
    RegisteredPopulation: "142",
    VotingStationName: "EKHUBENI PRIMARY SCHOOL",
    Address:
      "R617 MAIN ROAD TO SWARTBERG  EKHUBENI, EKHUBENI FARM R617 MAIN ROAD  UNDERBERG",
    Latitude: "-29.8785",
    Longitude: "29.4193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13913",
    WardLookupId: "1733",
    VDNumber: "43771787",
    RegisteredPopulation: "215",
    VotingStationName: "NGXOLA PRIMARY SCHOOL",
    Address: "NEXT TO COMMUNITY HALL  AMAKHUZE   AMAKHUZE",
    Latitude: "-30.0205",
    Longitude: "29.586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13914",
    WardLookupId: "1733",
    VDNumber: "43771798",
    RegisteredPopulation: "692",
    VotingStationName: "EMAGCAKINI PRIMARY SCHOOL",
    Address: "NEXT TO  COMMUNITY HALL  UNDERBERG  AMAKHUZE",
    Latitude: "-29.9844",
    Longitude: "29.549",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13915",
    WardLookupId: "1733",
    VDNumber: "43771800",
    RegisteredPopulation: "827",
    VotingStationName: "IMVULENI PRIMARY SCHOOL",
    Address: "NEXT TO COMMUNITY HALL  THONSINI  AMAKHUZE",
    Latitude: "-29.9787",
    Longitude: "29.519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13916",
    WardLookupId: "1733",
    VDNumber: "43773925",
    RegisteredPopulation: "1718",
    VotingStationName: "MASHAYILANGA PRIMARY SCHOOL",
    Address: "MAYEZA ROAD, EZIDWENI  AMAKHUZE  AMAKHUZE",
    Latitude: "-29.9452",
    Longitude: "29.5774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13917",
    WardLookupId: "1733",
    VDNumber: "43775051",
    RegisteredPopulation: "838",
    VotingStationName: "MADWALENI PRIMARY SCHOOL",
    Address: "D243 NEXT TO R HARDINGHAM SCHOOL  AMAKHUZE  UNDERBERG",
    Latitude: "-29.9618",
    Longitude: "29.5259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13918",
    WardLookupId: "1734",
    VDNumber: "43771765",
    RegisteredPopulation: "1123",
    VotingStationName: "KHUKHULELA PRIMARY SCHOOL",
    Address: "NEXT TO EMASAMENI HALL  AMAKHUZE   CREIGHTON",
    Latitude: "-30.0032",
    Longitude: "29.692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13919",
    WardLookupId: "1734",
    VDNumber: "43771811",
    RegisteredPopulation: "731",
    VotingStationName: "SKOFILL HIGH SCHOOL",
    Address: "NEXT TO EZIDWENI HALL  BULWER  AMAKHUZE",
    Latitude: "-29.9604",
    Longitude: "29.5912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13920",
    WardLookupId: "1734",
    VDNumber: "43771822",
    RegisteredPopulation: "665",
    VotingStationName: "NOMAGAGA  PRIMARY SCHOOL",
    Address: "NEXT TO MPUMLWANE CATHOLIC CHU  AMAKHUZE  AMAKHUZE",
    Latitude: "-29.9662",
    Longitude: "29.6859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13921",
    WardLookupId: "1734",
    VDNumber: "43774803",
    RegisteredPopulation: "597",
    VotingStationName: "MLINDELI PRIMARY SCHOOL",
    Address: "NEXT TO HORSE RACE COURSE  DAZINI  AMAKHUZE TRIBAL AUTHORITY",
    Latitude: "-29.9624",
    Longitude: "29.6353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13922",
    WardLookupId: "1734",
    VDNumber: "43775062",
    RegisteredPopulation: "452",
    VotingStationName: "NDODENI COMMUNITY LIBRARY",
    Address: "NEXT TO CATHOLIC CHURCH  CREIGHTON  CREIGHTON",
    Latitude: "-29.9898",
    Longitude: "29.6768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13923",
    WardLookupId: "1735",
    VDNumber: "43771721",
    RegisteredPopulation: "1393",
    VotingStationName: "ESIBOVINI  PRIMARY SCHOOL",
    Address:
      "NEXT TO ESIBOVINI COMMUNITY HALL, CREIGHTON  CREIGHTON  MADZIKANE",
    Latitude: "-30.0325",
    Longitude: "29.7291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13924",
    WardLookupId: "1735",
    VDNumber: "43771754",
    RegisteredPopulation: "706",
    VotingStationName: "CENTOCOW HIGH SCHOOL",
    Address: "MAKHOLWENI AREA CENTOCOW  SBONELESIHLE  CENTOCOW",
    Latitude: "-30.03",
    Longitude: "29.7096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13925",
    WardLookupId: "1735",
    VDNumber: "43771833",
    RegisteredPopulation: "778",
    VotingStationName: "QULASHE COMBINED PRIMARY SCHOOL",
    Address: "NEXT TO QULASHE CLINIC  QULASHE  AMAKHUZE",
    Latitude: "-29.9142",
    Longitude: "29.6502",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13926",
    WardLookupId: "1735",
    VDNumber: "43771844",
    RegisteredPopulation: "761",
    VotingStationName: "HLABENI  PRIMARY SCHOOL",
    Address: "NEXT TO HLABENI CRESHE  HLABENI  AMAKHUZE",
    Latitude: "-29.9673",
    Longitude: "29.7113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13927",
    WardLookupId: "1735",
    VDNumber: "43771855",
    RegisteredPopulation: "442",
    VotingStationName: "INDUMAKUDE  PRIMARY SCHOOL",
    Address: "NEXT TO AMAKHUZE HIGH SCHOOL  BULWER  AMAKHUZE",
    Latitude: "-29.9172",
    Longitude: "29.7117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13928",
    WardLookupId: "1735",
    VDNumber: "43774218",
    RegisteredPopulation: "454",
    VotingStationName: "NGCESHENI PRIMARY SCHOOL",
    Address: "NEXT TO COMMUNITY HALL  BULWER  AMAKHUZE",
    Latitude: "-29.9265",
    Longitude: "29.7026",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13929",
    WardLookupId: "1735",
    VDNumber: "43775073",
    RegisteredPopulation: "351",
    VotingStationName: "ESICEDENI LOWER PRIMARY SCHOOL",
    Address: "DODO VILLAGE,NEXT TO CRECHE  QULASHE  AMAKHUZE",
    Latitude: "-29.9341",
    Longitude: "29.6641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13930",
    WardLookupId: "1736",
    VDNumber: "43771686",
    RegisteredPopulation: "522",
    VotingStationName: "TARS VALLEY COMBINED PRIMARY SCHOOL",
    Address: "OPPOSITE COMMUNITY HALL  CREIGHTON  CREIGHTON",
    Latitude: "-29.9792",
    Longitude: "29.7868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13931",
    WardLookupId: "1736",
    VDNumber: "43771866",
    RegisteredPopulation: "673",
    VotingStationName: "EMNQUNDEKWENI  PRIMARY SCHOOL",
    Address: "OPPOSITE COMMUNITY HALL  EMNQUNDEKWENI  AMAKHUZE",
    Latitude: "-29.8889",
    Longitude: "29.7064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13932",
    WardLookupId: "1736",
    VDNumber: "43771877",
    RegisteredPopulation: "560",
    VotingStationName: "MAHLAHLA SECONDARY SCHOOL",
    Address: "NEXT TO GALA CATHOLIC CHURCH  BULWER  AMAKHUZE",
    Latitude: "-29.92",
    Longitude: "29.7464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13933",
    WardLookupId: "1736",
    VDNumber: "43771888",
    RegisteredPopulation: "2047",
    VotingStationName: "GQUMENI PRIMARY SCHOOL",
    Address: "NEXT TO MADULINI HIGH SCHOOL  AMAKHUZE TA  AMAKHUZE",
    Latitude: "-29.8953",
    Longitude: "29.748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13934",
    WardLookupId: "1737",
    VDNumber: "43771732",
    RegisteredPopulation: "1498",
    VotingStationName: "SONYONGWANE HIGH SCHOOL",
    Address: "OPPOSITE COMMUNITY HALL  CREIGHTON  MADZIKANE",
    Latitude: "-30.0596",
    Longitude: "29.7521",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13935",
    WardLookupId: "1737",
    VDNumber: "43771743",
    RegisteredPopulation: "1476",
    VotingStationName: "EMKHAZENI PRIMARY SCHOOL",
    Address: "NEXT TO MADZIKANE TRIBAL COURT  MADZIKANE   CREIGHTON",
    Latitude: "-30.0639",
    Longitude: "29.6989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13936",
    WardLookupId: "1737",
    VDNumber: "43771776",
    RegisteredPopulation: "616",
    VotingStationName: "EMWANENI PRIMARY SCHOOL",
    Address: "OPPOSITE COMMUNITY HALL  UNDERBERG  AMAKHUZE",
    Latitude: "-30.0304",
    Longitude: "29.611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13937",
    WardLookupId: "1737",
    VDNumber: "43773914",
    RegisteredPopulation: "500",
    VotingStationName: "GXALINGENWA PRIMARY SCHOOL",
    Address: "NEXT TO GINYANI HIGH SCHOOL  BULWER  AMAKHUZE",
    Latitude: "-30.0245",
    Longitude: "29.664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13938",
    WardLookupId: "1737",
    VDNumber: "43775589",
    RegisteredPopulation: "276",
    VotingStationName: "KUMKANI PRIMARY SCHOOL",
    Address: "BHOBHOYI LOCATION   MADZIKANE TRIBAL AUTHORITY  CREIGHTON",
    Latitude: "-30.0646",
    Longitude: "29.7392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13939",
    WardLookupId: "1738",
    VDNumber: "43771978",
    RegisteredPopulation: "903",
    VotingStationName: "NKWEZELA  PRIMARY SCHOOL",
    Address: "NEXT TO PRESBETERIAN CHURCH, NKWEZELA  NKWEZELA  DONNYBROOK",
    Latitude: "-29.8735",
    Longitude: "29.7975",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13940",
    WardLookupId: "1738",
    VDNumber: "43771990",
    RegisteredPopulation: "962",
    VotingStationName: "BHAMBATHA  PRIMARY SCHOOL",
    Address: "NEXT TO BHAMBATHA HALL, NKWEZELA, BULWER  BULWER  MEMELA",
    Latitude: "-29.8729",
    Longitude: "29.8107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13941",
    WardLookupId: "1738",
    VDNumber: "43772003",
    RegisteredPopulation: "1142",
    VotingStationName: "ENGUDWINI PRIMARY SCHOOL",
    Address: "NEXT TO PRESBYTERIAN CHURCH  ENGUDWINI  MEMELA",
    Latitude: "-29.8661",
    Longitude: "29.8464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13942",
    WardLookupId: "1738",
    VDNumber: "43775107",
    RegisteredPopulation: "895",
    VotingStationName: "NKWEZELA HALL",
    Address: "NEXT TO MZWENDABA P SCHOOL  NKWEZELA  MACALA GWALA",
    Latitude: "-29.8596",
    Longitude: "29.8014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13943",
    WardLookupId: "1739",
    VDNumber: "43771923",
    RegisteredPopulation: "879",
    VotingStationName: "POLELA HIGH SCHOOL",
    Address:
      "NEXT TO TURN TABLE TRUST, BULWER  BULWER, R617 UNDERBER MAIN ROAD  BULWER",
    Latitude: "-29.8166",
    Longitude: "29.7714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13944",
    WardLookupId: "1739",
    VDNumber: "43771989",
    RegisteredPopulation: "937",
    VotingStationName: "DINGEKA HIGH SCHOOL",
    Address:
      "NKWEZELA LOCATION  GOBHOGOBHO NEXT TO GWALA CLINIC  AMACALA GWALA",
    Latitude: "-29.8543",
    Longitude: "29.7984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13945",
    WardLookupId: "1739",
    VDNumber: "43772014",
    RegisteredPopulation: "240",
    VotingStationName: "MPHEPHETHA  PRIMARY SCHOOL",
    Address: "OPPOSITE SIQHOZA HIGH SCHOOL  NKWEZELA  DONNYBROOK",
    Latitude: "-29.8482",
    Longitude: "29.8225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13946",
    WardLookupId: "1739",
    VDNumber: "43772047",
    RegisteredPopulation: "687",
    VotingStationName: "NGONYAMA HIGH SCHOOL",
    Address:
      "NEXT TO ST MICHAELS, ROMAN CATHOLIC CHURCH, CHIBI  CHIBINI LOCATION, MARUTSWA ROAD   BHIDLA",
    Latitude: "-29.8105",
    Longitude: "29.8084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13947",
    WardLookupId: "1739",
    VDNumber: "43774375",
    RegisteredPopulation: "652",
    VotingStationName: "VUSINDABA PRIMARY SCHOOL",
    Address:
      "XOSHEYAKHE VILLAGE, BULWER  BULWER, COME AND SEE ARE NEXT TO THE HALL  BHIDLA",
    Latitude: "-29.784",
    Longitude: "29.8058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13948",
    WardLookupId: "1740",
    VDNumber: "43772081",
    RegisteredPopulation: "739",
    VotingStationName: "BHIDLA PRIMARY SCHOOL",
    Address: "BULWER  NEXT TO ST CATHERINES, ROMAN CATHOLIC CHURCH  BHIDLA",
    Latitude: "-29.7699",
    Longitude: "29.8611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13949",
    WardLookupId: "1740",
    VDNumber: "43772092",
    RegisteredPopulation: "256",
    VotingStationName: "EMACABAZINI PRIMARY SCHOOL",
    Address: "ESHESHI VILLAGE,NEXT TO HALL  BULWER  BHIDLA",
    Latitude: "-29.7485",
    Longitude: "29.9046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13950",
    WardLookupId: "1740",
    VDNumber: "43772104",
    RegisteredPopulation: "735",
    VotingStationName: "ENKUMBA PRIMARY SCHOOL",
    Address: "ENKUMBA  NEXT TO BOXER STORE  BHIDLA",
    Latitude: "-29.7534",
    Longitude: "29.8622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13951",
    WardLookupId: "1740",
    VDNumber: "43772115",
    RegisteredPopulation: "496",
    VotingStationName: "EMANGWANENI  PRIMARY SCHOOL",
    Address: "KWANDONYELA, BULWER  NEXT TO KWAZUMAS STORE  EMANGWANENI",
    Latitude: "-29.7152",
    Longitude: "29.801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13952",
    WardLookupId: "1740",
    VDNumber: "43772126",
    RegisteredPopulation: "1391",
    VotingStationName: "ENKELABANTWANA  PRIMARY SCHOOL",
    Address: "ESHARP LOCATION  NEXT TO ST PAULS, ROMAN CATHOLIC CHURCH  BHIDLA",
    Latitude: "-29.7649",
    Longitude: "29.8006",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13953",
    WardLookupId: "1740",
    VDNumber: "43775118",
    RegisteredPopulation: "356",
    VotingStationName: "MAFOHLA PRIMARY SCHOOL",
    Address:
      "NEXT TO KWAKHUMALO STORE   NKELABANTWANA, MAFOHLA AREA  KWA BHIDLA",
    Latitude: "-29.7501",
    Longitude: "29.7849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13954",
    WardLookupId: "1741",
    VDNumber: "43771912",
    RegisteredPopulation: "526",
    VotingStationName: "MNYWANENI PRIMARY SCHOOL",
    Address:
      "MNYWANENI LOCATION, DONNYBROOK  SANDANEZWE ROAD NEXT TO SAPPI FOREST DEPORT  DONNYBROOK",
    Latitude: "-29.9229",
    Longitude: "29.9703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13955",
    WardLookupId: "1741",
    VDNumber: "43772025",
    RegisteredPopulation: "506",
    VotingStationName: "MEMELA  PRIMARY SCHOOL",
    Address: "NEXT TO RAMOROBI HIGH SCHOOL  DONNYBROOK  MEMELA",
    Latitude: "-29.8392",
    Longitude: "29.8929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13956",
    WardLookupId: "1741",
    VDNumber: "43772036",
    RegisteredPopulation: "804",
    VotingStationName: "NDABAKAZIBUZWA  HIGH SCHOOL",
    Address:
      "SIZANANJANA VILLAGE, BULWER  NEXT TO KWAMNYAMANA PRIMARY SCHOOL  ZASHUKE",
    Latitude: "-29.8213",
    Longitude: "29.9358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13957",
    WardLookupId: "1741",
    VDNumber: "43772058",
    RegisteredPopulation: "512",
    VotingStationName: "SINDAWONYE PRIMARY SCHOOL",
    Address: "MQULELA VILLAGE,BULWER  NEXT TO MQULELA HIGH SCHOOL  BHIDLA",
    Latitude: "-29.7887",
    Longitude: "29.8465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13958",
    WardLookupId: "1741",
    VDNumber: "43772069",
    RegisteredPopulation: "951",
    VotingStationName: "ZASHUKE HIGH SCHOOL",
    Address:
      "BETHLEHEM, BULWER  NEXT TO COMMUNITY HALL AND LUHHANA PRIMARY  ZASHUKE",
    Latitude: "-29.8029",
    Longitude: "29.8735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13959",
    WardLookupId: "1742",
    VDNumber: "43771956",
    RegisteredPopulation: "1243",
    VotingStationName: "SEAFORD PRIMARY SCHOOL",
    Address: "R617 NTEKANENI VILLAGE   MEMELA   MEMELA",
    Latitude: "-29.8845",
    Longitude: "29.8486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13960",
    WardLookupId: "1742",
    VDNumber: "43771967",
    RegisteredPopulation: "1117",
    VotingStationName: "DUMABEZWE HIGH SCHOOL",
    Address: "NEXT TO MAHAMULA STORE  ST CHARLES LOCATION  MEMELA",
    Latitude: "-29.8895",
    Longitude: "29.8065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13961",
    WardLookupId: "1742",
    VDNumber: "43773835",
    RegisteredPopulation: "641",
    VotingStationName: "DONNYBROOK PRIMARY SCHOOL",
    Address: "D782 NEXT TO DONNYBROOK SAPS  DONNYBROOK  MEMELA",
    Latitude: "-29.929",
    Longitude: "29.8762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13962",
    WardLookupId: "1742",
    VDNumber: "43773981",
    RegisteredPopulation: "533",
    VotingStationName: "SOKHELA PRIMARY SCHOOL",
    Address: "NEXT TO COMMUNITY HALL  DONNYBROOK  MEMELA",
    Latitude: "-29.9445",
    Longitude: "29.8686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13963",
    WardLookupId: "1743",
    VDNumber: "43771697",
    RegisteredPopulation: "479",
    VotingStationName: "NOMGIDI  PRIMARY SCHOOL",
    Address: "NEXT TO JABULA STORE,R612 ROAD  DONNYBROOK  MEMELA",
    Latitude: "-29.9766",
    Longitude: "29.9083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13964",
    WardLookupId: "1743",
    VDNumber: "43771709",
    RegisteredPopulation: "586",
    VotingStationName: "MJILA  PRIMARY SCHOOL",
    Address: "NEXT TO CATHOLIC CHURCH  DONNYBROOK  MEMELA",
    Latitude: "-29.9633",
    Longitude: "29.867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13965",
    WardLookupId: "1743",
    VDNumber: "43771710",
    RegisteredPopulation: "542",
    VotingStationName: "WOODHURST  PRIMARY SCHOOL",
    Address: "NEXT TO WOODHURST HALL  UMJILA  MEMELA",
    Latitude: "-29.9579",
    Longitude: "29.8533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13966",
    WardLookupId: "1743",
    VDNumber: "43773644",
    RegisteredPopulation: "901",
    VotingStationName: "NDEBENI PRIMARY SCHOOL",
    Address: "R612 NEXT TO IMAGE OF GOD CHURCH  MEMELA   MEMELA",
    Latitude: "-29.9527",
    Longitude: "29.8897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13967",
    WardLookupId: "1743",
    VDNumber: "43774364",
    RegisteredPopulation: "373",
    VotingStationName: "GLENMAIZE PRIMARY SCHOOL",
    Address: "GLENMAIZE VILLAGE R612 ROAD  IXOPO  IXOPO",
    Latitude: "-30.0839",
    Longitude: "29.9845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13968",
    WardLookupId: "1743",
    VDNumber: "43775084",
    RegisteredPopulation: "298",
    VotingStationName: "MICHAEL SHELEMBE PRIMARY SCHOOL",
    Address: "NEXT TO NKWASHAKAZI AFM CHURCH  MEMELA   MEMELA",
    Latitude: "-29.958",
    Longitude: "29.8818",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13969",
    WardLookupId: "1743",
    VDNumber: "43820018",
    RegisteredPopulation: "703",
    VotingStationName: "CREIGHTON AGRICULTURAL HALL",
    Address: "CREIGHTON MAIN STREET, CREIGHTON, 3263  CREIGHTON  CREIGHTON",
    Latitude: "-30.028",
    Longitude: "29.8365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13970",
    WardLookupId: "1744",
    VDNumber: "43771664",
    RegisteredPopulation: "239",
    VotingStationName: "GLENBAIN SAPPI BOARDROOM",
    Address: "NEXT TO GLENBAINSAPPI COMPOUND  SAPPI COMPOUND   IXOPO",
    Latitude: "-30.0028",
    Longitude: "30.0476",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13971",
    WardLookupId: "1744",
    VDNumber: "43771675",
    RegisteredPopulation: "229",
    VotingStationName: "JANI PRIMARY SCHOOL",
    Address: "LILYDALE FARM, EASTWORLDS  DONNYBROOK  IXOPO",
    Latitude: "-30.0003",
    Longitude: "29.9676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13972",
    WardLookupId: "1744",
    VDNumber: "43771899",
    RegisteredPopulation: "907",
    VotingStationName: "QOYINTABA PRIMARY SCHOOL",
    Address: "NEXT TO MASAMINI HIGH SCHOOL  MBAJWA FARM VILLAGE  IXOPO",
    Latitude: "-29.9861",
    Longitude: "30.021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13973",
    WardLookupId: "1744",
    VDNumber: "43771901",
    RegisteredPopulation: "257",
    VotingStationName: "VALENTINE COMBINED PRIMARY SCHOOL",
    Address: "VALENTINE LOCATION, IXOPO  MASAMINI VILLAGE  IXOPO",
    Latitude: "-29.9581",
    Longitude: "30.1028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13974",
    WardLookupId: "1744",
    VDNumber: "43771934",
    RegisteredPopulation: "932",
    VotingStationName: "KHETHOKUHLE PRIMARY SCHOOL",
    Address: "NEXT TO COMMUNITY HALL  DONNYBROOK  VEZOKUHLE",
    Latitude: "-29.8957",
    Longitude: "30.0211",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13975",
    WardLookupId: "1744",
    VDNumber: "43773633",
    RegisteredPopulation: "678",
    VotingStationName: "SANDANEZWE  PRIMARY SCHOOL",
    Address: "SANDANEZWE LOCATION, DONNYBROOK  DONNYBROOK  VEZOKUHLE",
    Latitude: "-29.8651",
    Longitude: "29.9987",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13976",
    WardLookupId: "1744",
    VDNumber: "43775095",
    RegisteredPopulation: "386",
    VotingStationName: "MATSHANHLOLA PRIMARY SCHOOL",
    Address: "OPPOSITE COMMUNITY HALL  MASAMENI  1XOPO",
    Latitude: "-29.96",
    Longitude: "30.0322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13977",
    WardLookupId: "1745",
    VDNumber: "76140053",
    RegisteredPopulation: "1287",
    VotingStationName: "N`WAMAVIMBI HIGH SCHOOL",
    Address: "XIMAWUSA  GIYANI",
    Latitude: "-23.3813",
    Longitude: "30.3607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13978",
    WardLookupId: "1745",
    VDNumber: "76140064",
    RegisteredPopulation: "1295",
    VotingStationName: "XIMUWINI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  BLINKWATER VILLAGE  GIYANI",
    Latitude: "-23.4114",
    Longitude: "30.3349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13979",
    WardLookupId: "1745",
    VDNumber: "76140165",
    RegisteredPopulation: "1148",
    VotingStationName: "HAAKA PRIMARY SCHOOL",
    Address: "N/A  NOBLEHOEK VILLAGE  GIYANI",
    Latitude: "-23.3456",
    Longitude: "30.3745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13980",
    WardLookupId: "1745",
    VDNumber: "76140963",
    RegisteredPopulation: "350",
    VotingStationName: "MSENGI TRADITIONAL COUNCIL",
    Address: "NO STREET ADDRESS  BLINKWATER  GIYANI NU",
    Latitude: "-23.4083",
    Longitude: "30.3426",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13981",
    WardLookupId: "1746",
    VDNumber: "76220029",
    RegisteredPopulation: "919",
    VotingStationName: "NGHECHE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  PHIKELA VILLAGE  GIYANI",
    Latitude: "-23.3271",
    Longitude: "30.4003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13982",
    WardLookupId: "1746",
    VDNumber: "76220041",
    RegisteredPopulation: "823",
    VotingStationName: "HONOKA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  RIVALA VILLAGE  GIYANI",
    Latitude: "-23.3523",
    Longitude: "30.452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13983",
    WardLookupId: "1746",
    VDNumber: "76220074",
    RegisteredPopulation: "1276",
    VotingStationName: "MBURENI SCHOOL",
    Address: "NO STREET ADDRESS  MASHAVELA  GREATER GIYANI [GIYANI]",
    Latitude: "-23.3366",
    Longitude: "30.4832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13984",
    WardLookupId: "1746",
    VDNumber: "76220827",
    RegisteredPopulation: "616",
    VotingStationName: "LANGUTELANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MAVHUZA  GREATER GIYANI",
    Latitude: "-23.2996",
    Longitude: "30.4385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13985",
    WardLookupId: "1747",
    VDNumber: "76210613",
    RegisteredPopulation: "620",
    VotingStationName: "NTSHUXI COMBINE SCHOOL",
    Address: "NTSHUXI VILLAGE   GIYANI   GIYANI",
    Latitude: "-23.2599",
    Longitude: "30.3518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13986",
    WardLookupId: "1747",
    VDNumber: "76220030",
    RegisteredPopulation: "1125",
    VotingStationName: "BABANGU PRIMARY SCHOOL",
    Address: "BABANGU VILLAGE   GREATER GIYANI",
    Latitude: "-23.2825",
    Longitude: "30.4396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13987",
    WardLookupId: "1747",
    VDNumber: "76220063",
    RegisteredPopulation: "1390",
    VotingStationName: "ANDERSON PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  NDENGEZA A VILLAGE  GIYANI",
    Latitude: "-23.3136",
    Longitude: "30.4041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13988",
    WardLookupId: "1747",
    VDNumber: "76220759",
    RegisteredPopulation: "832",
    VotingStationName: "MAKHOSANI PRIMARY SCHOOL",
    Address: "NDENGEZA RDP  GIYANI  NDENGEZA",
    Latitude: "-23.2646",
    Longitude: "30.385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13989",
    WardLookupId: "1748",
    VDNumber: "76220119",
    RegisteredPopulation: "1073",
    VotingStationName: "MALENGA HIGH SCHOOL",
    Address: "N`WAMANKENA   N`WAMANKENA VILLAGE  GIYANI",
    Latitude: "-23.3704",
    Longitude: "30.5176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13990",
    WardLookupId: "1748",
    VDNumber: "76220131",
    RegisteredPopulation: "1047",
    VotingStationName: "MASWANGANYI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MASWANGANYI VILLAGE  GIYANI",
    Latitude: "-23.3188",
    Longitude: "30.6102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13991",
    WardLookupId: "1748",
    VDNumber: "76220670",
    RegisteredPopulation: "973",
    VotingStationName: "HAWUKA HIGH SCHOOL",
    Address: "NO STREET ADDRESS  BASANI VILLAGE  GIYANI",
    Latitude: "-23.3552",
    Longitude: "30.5303",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13992",
    WardLookupId: "1748",
    VDNumber: "76221132",
    RegisteredPopulation: "597",
    VotingStationName: "KHUNGULU PRIMARY SCHOOL",
    Address: "N`WAMANKENA  GIYANI  NHLANEKI TRIBAL AUTHORITY",
    Latitude: "-23.3756",
    Longitude: "30.5334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13993",
    WardLookupId: "1749",
    VDNumber: "76220243",
    RegisteredPopulation: "1355",
    VotingStationName: "MATSAMBU HIGH SCHOOL",
    Address: "NO STREET ADDRESS  SIFASONKE VILLAGE  GIYANI",
    Latitude: "-23.212",
    Longitude: "30.5555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13994",
    WardLookupId: "1749",
    VDNumber: "76220625",
    RegisteredPopulation: "1326",
    VotingStationName: "TIRHANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  TOMU VILLAGE  GIYANI",
    Latitude: "-23.1881",
    Longitude: "30.5958",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13995",
    WardLookupId: "1749",
    VDNumber: "76220681",
    RegisteredPopulation: "1234",
    VotingStationName: "NKURI TRIBAL OFFICE",
    Address: "ZAMANI VILLAGE  GIYANI NU",
    Latitude: "-23.2539",
    Longitude: "30.5396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13996",
    WardLookupId: "1750",
    VDNumber: "76220018",
    RegisteredPopulation: "1358",
    VotingStationName: "MACEMA HIGH SCHOOL",
    Address: "NO STREET ADDRESS  NHLANIKI VILLAGE  GIYANI",
    Latitude: "-23.2819",
    Longitude: "30.5004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13997",
    WardLookupId: "1750",
    VDNumber: "76220052",
    RegisteredPopulation: "724",
    VotingStationName: "KAYANENE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GONONO SETTLEMENT  GIYANI",
    Latitude: "-23.2762",
    Longitude: "30.4853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13998",
    WardLookupId: "1750",
    VDNumber: "76220838",
    RegisteredPopulation: "943",
    VotingStationName: "NYANISI HIGH SCHOOL",
    Address: "NO STREET ADDRESS  HLANEKI  GIYANI",
    Latitude: "-23.299",
    Longitude: "30.5024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "13999",
    WardLookupId: "1750",
    VDNumber: "76220973",
    RegisteredPopulation: "1242",
    VotingStationName: "KUTSAKENI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  KHANI VILLAGE  GREATER GIYANI [GIYANI]",
    Latitude: "-23.3508",
    Longitude: "30.497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14000",
    WardLookupId: "1751",
    VDNumber: "76220142",
    RegisteredPopulation: "1242",
    VotingStationName: "KHOMISANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  BODE VILLAGE  GIYANI",
    Latitude: "-23.3345",
    Longitude: "30.6382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14001",
    WardLookupId: "1751",
    VDNumber: "76220164",
    RegisteredPopulation: "1515",
    VotingStationName: "FAMANDA HIGH SCHOOL",
    Address: "NO STREET ADDRESS  SIYANDHANI VILLAGE  GIYANI",
    Latitude: "-23.2984",
    Longitude: "30.6403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14002",
    WardLookupId: "1751",
    VDNumber: "76220175",
    RegisteredPopulation: "1216",
    VotingStationName: "SIYANDHANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  SIYANDHANI VILLAGE  GIYANI",
    Latitude: "-23.3002",
    Longitude: "30.6609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14003",
    WardLookupId: "1751",
    VDNumber: "76221121",
    RegisteredPopulation: "464",
    VotingStationName: "GOSPEL REVIVAL CHURCH",
    Address: "SIYANDHANI  GIYANI  SIYANDHANI TRIBAL AUTHORITY",
    Latitude: "-23.3066",
    Longitude: "30.6601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14004",
    WardLookupId: "1752",
    VDNumber: "76140233",
    RegisteredPopulation: "786",
    VotingStationName: "DULANG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  BOTSHABELO VILLAGE  GIYANI",
    Latitude: "-23.4321",
    Longitude: "30.5159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14005",
    WardLookupId: "1752",
    VDNumber: "76140389",
    RegisteredPopulation: "1044",
    VotingStationName: "MALOBA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  SEKHIMING  GIYANI",
    Latitude: "-23.4345",
    Longitude: "30.5632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14006",
    WardLookupId: "1752",
    VDNumber: "76220085",
    RegisteredPopulation: "850",
    VotingStationName: "DINGAMANZI PRIMARY SCHOOL",
    Address: "DINGAMANZI VILLAGE   GIYANI",
    Latitude: "-23.3779",
    Longitude: "30.5819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14007",
    WardLookupId: "1752",
    VDNumber: "76220669",
    RegisteredPopulation: "774",
    VotingStationName: "NHLUVUKO SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS  SHIMANGE VILLAGE  GIYANI",
    Latitude: "-23.3931",
    Longitude: "30.5851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14008",
    WardLookupId: "1752",
    VDNumber: "76221064",
    RegisteredPopulation: "631",
    VotingStationName: "SILAWA EARLY LEARNING CENTRE",
    Address: "SILAWA  SILAWA VILLAGE  GIYANI NU",
    Latitude: "-23.3967",
    Longitude: "30.588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14009",
    WardLookupId: "1753",
    VDNumber: "76220232",
    RegisteredPopulation: "1434",
    VotingStationName: "HIPAMBUKILE SCHOOL",
    Address: "NO STREET ADDRESS  HOMU 14B VILLAGE  GIYANI",
    Latitude: "-23.3317",
    Longitude: "30.7785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14010",
    WardLookupId: "1753",
    VDNumber: "76220366",
    RegisteredPopulation: "1472",
    VotingStationName: "TANANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  HOMU 14A VILLAGE  GIYANI",
    Latitude: "-23.3081",
    Longitude: "30.8034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14011",
    WardLookupId: "1753",
    VDNumber: "76220917",
    RegisteredPopulation: "565",
    VotingStationName: "THE REVIVAL BIBLE CHURCH",
    Address: "14B (HOMU)  HOMU TRIBAL AUTHORITY  GIYANI",
    Latitude: "-23.3315",
    Longitude: "30.7698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14012",
    WardLookupId: "1753",
    VDNumber: "76221075",
    RegisteredPopulation: "441",
    VotingStationName: "MAFUMANI SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS  HOMU 14A  GIYANI NU",
    Latitude: "-23.3183",
    Longitude: "30.7882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14013",
    WardLookupId: "1754",
    VDNumber: "76220209",
    RegisteredPopulation: "1414",
    VotingStationName: "GIJA-NGOVE HIGH SCHOOL",
    Address: "NO STREET ADDRESS  NGOVE VILLAGE  GIYANI",
    Latitude: "-23.3569",
    Longitude: "30.7355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14014",
    WardLookupId: "1754",
    VDNumber: "76220210",
    RegisteredPopulation: "1333",
    VotingStationName: "NKOMO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  NKOMO 22B VILLAGE  GIYANI",
    Latitude: "-23.4041",
    Longitude: "30.7835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14015",
    WardLookupId: "1754",
    VDNumber: "76220221",
    RegisteredPopulation: "1279",
    VotingStationName: "MAHUMANI TRIBAL OFFICE",
    Address: "NKOMO HEADKRAAL  NKOMO 22B VILLAGE  GIYANI",
    Latitude: "-23.4139",
    Longitude: "30.7839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14016",
    WardLookupId: "1754",
    VDNumber: "76221008",
    RegisteredPopulation: "114",
    VotingStationName: "KHONGELA DAY CARE CENTRE",
    Address: "NKOMO  GIYANI  MAHUMANI TRIBAL",
    Latitude: "-23.4499",
    Longitude: "30.7906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14017",
    WardLookupId: "1755",
    VDNumber: "76220557",
    RegisteredPopulation: "1316",
    VotingStationName: "VURHONGA PRIMARY SCHOOL",
    Address: "GIYANI SECTION E1  GIYANI TOWNSHIP  GIYANI",
    Latitude: "-23.2994",
    Longitude: "30.6986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14018",
    WardLookupId: "1755",
    VDNumber: "76220568",
    RegisteredPopulation: "708",
    VotingStationName: "NDZALAMA PRIMARY SCHOOL",
    Address: "GIYANI SECTION D1  GIYANI",
    Latitude: "-23.309",
    Longitude: "30.7037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14019",
    WardLookupId: "1755",
    VDNumber: "76220603",
    RegisteredPopulation: "988",
    VotingStationName: "AGAPE CHURCH",
    Address: "SECTION E  GREATER GIYANI MUNICIPALITY  GIYANI",
    Latitude: "-23.2956",
    Longitude: "30.6989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14020",
    WardLookupId: "1755",
    VDNumber: "76221143",
    RegisteredPopulation: "702",
    VotingStationName: "7TH DAY ADVENTIST CHURCH",
    Address: "GIYANI  GIYANI E  GIYANI",
    Latitude: "-23.2993",
    Longitude: "30.7076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14021",
    WardLookupId: "1755",
    VDNumber: "76221154",
    RegisteredPopulation: "296",
    VotingStationName: "EVANGELICAL PRESBYTERAN CHURCH",
    Address: "GIYANI E  GIYANI",
    Latitude: "-23.3033",
    Longitude: "30.6989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14022",
    WardLookupId: "1756",
    VDNumber: "76220579",
    RegisteredPopulation: "1258",
    VotingStationName: "SUKANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GIYANI SECTION A  GIYANI",
    Latitude: "-23.316",
    Longitude: "30.7247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14023",
    WardLookupId: "1756",
    VDNumber: "76220580",
    RegisteredPopulation: "1176",
    VotingStationName: "NHLALALA PRIMARY SCHOOL",
    Address: "EVUXAKENI ROAD  NYAGELANE  GIYANI",
    Latitude: "-23.3159",
    Longitude: "30.7329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14024",
    WardLookupId: "1756",
    VDNumber: "76220850",
    RegisteredPopulation: "876",
    VotingStationName: "THANDABANTU PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  HOMU 14C  GREATER GIYANI",
    Latitude: "-23.3202",
    Longitude: "30.7423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14025",
    WardLookupId: "1756",
    VDNumber: "76221165",
    RegisteredPopulation: "428",
    VotingStationName: "GIYANI HIGH SCHOOL",
    Address: "GIYANI A  GIYANI",
    Latitude: "-23.3202",
    Longitude: "30.726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14026",
    WardLookupId: "1756",
    VDNumber: "76221176",
    RegisteredPopulation: "480",
    VotingStationName: "PENTECOSTAL ASSEMBLIES OF GOD",
    Address: "GIYANI-A  GIYANI -A  GIYANI",
    Latitude: "-23.3092",
    Longitude: "30.7278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14027",
    WardLookupId: "1757",
    VDNumber: "76220591",
    RegisteredPopulation: "706",
    VotingStationName: "RISINGA HIGH SCHOOL",
    Address: "NO STREET ADDRESS  GIYANI SECTION D2  GIYANI",
    Latitude: "-23.3054",
    Longitude: "30.7194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14028",
    WardLookupId: "1757",
    VDNumber: "76220614",
    RegisteredPopulation: "1942",
    VotingStationName: "GIYANI COMPREHENSIVE SCHOOL",
    Address: "NO STREET ADDRESS  GIYANI SECTION F  GIYANI",
    Latitude: "-23.2878",
    Longitude: "30.7073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14029",
    WardLookupId: "1757",
    VDNumber: "76220984",
    RegisteredPopulation: "1394",
    VotingStationName: "ABC CRECHE",
    Address: "NO STREET ADDRESS  GIYANI  MOPANI",
    Latitude: "-23.2836",
    Longitude: "30.7168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14030",
    WardLookupId: "1757",
    VDNumber: "76221187",
    RegisteredPopulation: "634",
    VotingStationName: "HEALING MINISTRY CHURCH",
    Address: "GIYANI-F  ",
    Latitude: "-23.2937",
    Longitude: "30.7128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14031",
    WardLookupId: "1757",
    VDNumber: "76221198",
    RegisteredPopulation: "438",
    VotingStationName: "DEPT. OF AGRICULTURE OFFICES",
    Address: "MAKOXA B9  GIYANI   HOMU",
    Latitude: "-23.2622",
    Longitude: "30.7266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14032",
    WardLookupId: "1758",
    VDNumber: "76220265",
    RegisteredPopulation: "1957",
    VotingStationName: "HATSHAMA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MAKOSHA SETTLEMENT  GIYANI",
    Latitude: "-23.254",
    Longitude: "30.7507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14033",
    WardLookupId: "1758",
    VDNumber: "76220287",
    RegisteredPopulation: "1511",
    VotingStationName: "MBHANGAZEKI HIGH SCHOOL",
    Address: "NO STREET ADDRESS  XIKUKWANI VILLAGE  GIYANI",
    Latitude: "-23.2429",
    Longitude: "30.7039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14034",
    WardLookupId: "1758",
    VDNumber: "76221086",
    RegisteredPopulation: "571",
    VotingStationName: "XIKUKWANA PRIMARY SCHOOL",
    Address: "N0 STREET ADDRESS  XIKUKWANI VILLAGE  GIYANI NU",
    Latitude: "-23.2308",
    Longitude: "30.7104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14035",
    WardLookupId: "1758",
    VDNumber: "76221200",
    RegisteredPopulation: "700",
    VotingStationName: "APOSTOLIC FAITH MISSION",
    Address: "XIKUKWANA   GIYANI  MABUNDA TRIBAL AUTHORITY",
    Latitude: "-23.2405",
    Longitude: "30.7087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14036",
    WardLookupId: "1758",
    VDNumber: "76221299",
    RegisteredPopulation: "399",
    VotingStationName: "IN HIM WORSHIP CENTRE",
    Address: "XIKUKWANI  MOPANI  GIYANI",
    Latitude: "-23.2405",
    Longitude: "30.6921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14037",
    WardLookupId: "1759",
    VDNumber: "76220298",
    RegisteredPopulation: "1142",
    VotingStationName: "DUMAZI HIGH SCHOOL",
    Address: "NO STREET ADDRESS  XIVULANI  GREATER GIYANI",
    Latitude: "-23.1416",
    Longitude: "30.709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14038",
    WardLookupId: "1759",
    VDNumber: "76220300",
    RegisteredPopulation: "1283",
    VotingStationName: "TSHEMBHANI PRIMARY SCHOOL",
    Address: "N`WADZEKUDZEKU  GIYANI  XIVITI",
    Latitude: "-23.1114",
    Longitude: "30.727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14039",
    WardLookupId: "1759",
    VDNumber: "76221097",
    RegisteredPopulation: "662",
    VotingStationName: "NKAMI SECONDARY SCHOOL",
    Address: "N`WADZEKUDZEKU  XIVITI",
    Latitude: "-23.1274",
    Longitude: "30.7247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14040",
    WardLookupId: "1759",
    VDNumber: "76221211",
    RegisteredPopulation: "710",
    VotingStationName: "HANYANYANI PRIMARY SCHOOL",
    Address: "XIVULANI   GIYANI  THOMO TRIBAL AUTHORITY",
    Latitude: "-23.1479",
    Longitude: "30.7131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14041",
    WardLookupId: "1760",
    VDNumber: "76220311",
    RegisteredPopulation: "1210",
    VotingStationName: "MNINGINISI PRIMARY SCHOOL",
    Address: "MNINGINISI BLOCK 3  GIYANI  XIVITI",
    Latitude: "-23.1458",
    Longitude: "30.802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14042",
    WardLookupId: "1760",
    VDNumber: "76220322",
    RegisteredPopulation: "1313",
    VotingStationName: "PFUNANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MNINGINISI BLOCK 2  GIYANI",
    Latitude: "-23.1897",
    Longitude: "30.8519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14043",
    WardLookupId: "1760",
    VDNumber: "76220658",
    RegisteredPopulation: "748",
    VotingStationName: "NGHATSANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MHLAVA WILLEM VILLAGE  GIYANI",
    Latitude: "-23.2185",
    Longitude: "30.8559",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14044",
    WardLookupId: "1760",
    VDNumber: "76220894",
    RegisteredPopulation: "1043",
    VotingStationName: "MNINGINISI CRECHE",
    Address: "NO STREET ADDRESS  MNINGINISI  MOPANI",
    Latitude: "-23.1435",
    Longitude: "30.809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14045",
    WardLookupId: "1761",
    VDNumber: "76220344",
    RegisteredPopulation: "1797",
    VotingStationName: "BENSON SHIVITI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  THOMO VILLAGE  GREATER GIYANI",
    Latitude: "-23.2574",
    Longitude: "30.7976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14046",
    WardLookupId: "1761",
    VDNumber: "76220906",
    RegisteredPopulation: "841",
    VotingStationName: "HANYANI THOMO SCHOOL",
    Address: "NO STREET ADDRESS  THOMO  GREATER GIYANI",
    Latitude: "-23.2555",
    Longitude: "30.7871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14047",
    WardLookupId: "1761",
    VDNumber: "76221019",
    RegisteredPopulation: "860",
    VotingStationName: "THOMO PRIMARY SCHOOL",
    Address:
      "NO STREET ADDRESS  THOMO VILLAGE BLOCK 7  GREATER GIYANI [GIYANI]",
    Latitude: "-23.2432",
    Longitude: "30.794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14048",
    WardLookupId: "1762",
    VDNumber: "76220333",
    RegisteredPopulation: "1445",
    VotingStationName: "HATLANI MUYEXE HIGH SCHOOL",
    Address: "MUYEXE   GIYANI  XIVITI",
    Latitude: "-23.2007",
    Longitude: "30.9126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14049",
    WardLookupId: "1762",
    VDNumber: "76220355",
    RegisteredPopulation: "1254",
    VotingStationName: "KHAKHALA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  KHAKHALA VILLAGE  GIYANI",
    Latitude: "-23.2675",
    Longitude: "30.8531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14050",
    WardLookupId: "1762",
    VDNumber: "76220377",
    RegisteredPopulation: "982",
    VotingStationName: "SOLANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GAWULA VILLAGE  GIYANI",
    Latitude: "-23.3142",
    Longitude: "30.8919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14051",
    WardLookupId: "1762",
    VDNumber: "76221109",
    RegisteredPopulation: "668",
    VotingStationName: "MUYEXE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MUYEXE VILLAGE  GIYANI NU",
    Latitude: "-23.1901",
    Longitude: "30.9195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14052",
    WardLookupId: "1762",
    VDNumber: "76221233",
    RegisteredPopulation: "721",
    VotingStationName: "GAWULA HIGH SCHOOL",
    Address: "GIYANI  HOMU TRIBAL AUTHORITY",
    Latitude: "-23.303",
    Longitude: "30.8863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14053",
    WardLookupId: "1763",
    VDNumber: "76220399",
    RegisteredPopulation: "1037",
    VotingStationName: "NDINDANI-HLOMELA HIGH SCHOOL",
    Address: "NO STREET ADDRESS  NDINDANI VILLAGE  GIYANI",
    Latitude: "-23.3832",
    Longitude: "30.9653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14054",
    WardLookupId: "1763",
    VDNumber: "76220715",
    RegisteredPopulation: "536",
    VotingStationName: "TSHOVANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  HLOMELA VILLAGE BLOCK 11B  GIYANI",
    Latitude: "-23.3962",
    Longitude: "30.9807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14055",
    WardLookupId: "1763",
    VDNumber: "76220782",
    RegisteredPopulation: "1461",
    VotingStationName: "GIVA-MAHLATHI HIGH SCHOOL",
    Address: "NO STREET ADDRESS  MAHLATHI VILLAGE  GIYANI",
    Latitude: "-23.3493",
    Longitude: "30.9453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14056",
    WardLookupId: "1763",
    VDNumber: "76290981",
    RegisteredPopulation: "165",
    VotingStationName: "SHINGWEDZI CAMP",
    Address: "KRUGER PARK  LIMDMA33",
    Latitude: "-23.1082",
    Longitude: "31.4359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14057",
    WardLookupId: "1764",
    VDNumber: "76220276",
    RegisteredPopulation: "1359",
    VotingStationName: "PFUXETANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MAVALANI VILLAGE  GIYANI",
    Latitude: "-23.2153",
    Longitude: "30.7119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14058",
    WardLookupId: "1764",
    VDNumber: "76220861",
    RegisteredPopulation: "1421",
    VotingStationName: "MAVALANI HIGH SCHOOL",
    Address: "NO STREET ADDRESS  MAVALANI  GREATER GIYANI",
    Latitude: "-23.208",
    Longitude: "30.7049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14059",
    WardLookupId: "1764",
    VDNumber: "76220872",
    RegisteredPopulation: "340",
    VotingStationName: "TABERNACLE WORSHIP CENTRE INTERNATIONAL",
    Address: "N/A  BONWANI  GREATER GIYANI",
    Latitude: "-23.1885",
    Longitude: "30.7125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14060",
    WardLookupId: "1764",
    VDNumber: "76220883",
    RegisteredPopulation: "890",
    VotingStationName: "MAFANELE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MBATLO  GREATER GIYANI",
    Latitude: "-23.1551",
    Longitude: "30.7198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14061",
    WardLookupId: "1765",
    VDNumber: "76220153",
    RegisteredPopulation: "1325",
    VotingStationName: "HIVUYERIWILE SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS  DZINGIDZINGI VILLAGE  GIYANI",
    Latitude: "-23.3362",
    Longitude: "30.6535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14062",
    WardLookupId: "1765",
    VDNumber: "76220197",
    RegisteredPopulation: "1581",
    VotingStationName: "KHAMBANI PRIMARY SCHOOL",
    Address: "NO STREE ADDRESS   KA NGOVE   GREATER GIYANI",
    Latitude: "-23.3688",
    Longitude: "30.7127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14063",
    WardLookupId: "1765",
    VDNumber: "76220692",
    RegisteredPopulation: "788",
    VotingStationName: "KREMETART COMMUNITY HALL",
    Address: "KREMETART STREET  KREMETART  GIYANI",
    Latitude: "-23.3233",
    Longitude: "30.6792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14064",
    WardLookupId: "1765",
    VDNumber: "76221244",
    RegisteredPopulation: "613",
    VotingStationName: "SHALOM EARLY LEARNING CENTRE",
    Address: "NGOVE  GIYANI  MABUNDA TRIBAL AUTHORITY",
    Latitude: "-23.3616",
    Longitude: "30.7174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14065",
    WardLookupId: "1766",
    VDNumber: "76220502",
    RegisteredPopulation: "1442",
    VotingStationName: "KHWEZU PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  SHAWELA VILLAGE  GIYANI",
    Latitude: "-23.485",
    Longitude: "30.8715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14066",
    WardLookupId: "1766",
    VDNumber: "76220793",
    RegisteredPopulation: "1686",
    VotingStationName: "LELENI PRIMARY SCHOOL",
    Address: "XIKHUMBA   GIYANI   MAHUMANI",
    Latitude: "-23.5026",
    Longitude: "30.8875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14067",
    WardLookupId: "1766",
    VDNumber: "76221031",
    RegisteredPopulation: "820",
    VotingStationName: "BALENI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  SHAWELA VILLAGE  GIYANI",
    Latitude: "-23.4857",
    Longitude: "30.8604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14068",
    WardLookupId: "1767",
    VDNumber: "76220490",
    RegisteredPopulation: "888",
    VotingStationName: "KHEYI PRIMARY SCHOOL",
    Address: "N/A  KHEYI  GREATER GIYANI",
    Latitude: "-23.5809",
    Longitude: "30.8694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14069",
    WardLookupId: "1767",
    VDNumber: "76220513",
    RegisteredPopulation: "1320",
    VotingStationName: "FAZA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  NSAVULANI VILLAGE  GIYANI",
    Latitude: "-23.5085",
    Longitude: "30.9537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14070",
    WardLookupId: "1767",
    VDNumber: "76220748",
    RegisteredPopulation: "770",
    VotingStationName: "VUYANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MBHEDHLE VILLAGE  GIYANI",
    Latitude: "-23.555",
    Longitude: "30.8387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14071",
    WardLookupId: "1767",
    VDNumber: "76220962",
    RegisteredPopulation: "862",
    VotingStationName: "MUSHIYANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MUSHIYANI VILLAGE  GREATER GIYANI",
    Latitude: "-23.6024",
    Longitude: "30.9047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14072",
    WardLookupId: "1767",
    VDNumber: "76221042",
    RegisteredPopulation: "655",
    VotingStationName: "NGHILAZI PRIMARY SCHOOL",
    Address: "GUWELA   GIYANI   DZUMERI",
    Latitude: "-23.5484",
    Longitude: "30.8611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14073",
    WardLookupId: "1768",
    VDNumber: "76220423",
    RegisteredPopulation: "1478",
    VotingStationName: "UKUTHULA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MAGEVA VILLAGE  GIYANI",
    Latitude: "-23.549",
    Longitude: "30.7113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14074",
    WardLookupId: "1768",
    VDNumber: "76220489",
    RegisteredPopulation: "820",
    VotingStationName: "MUNGHONHOMA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MUNGHONGHOMA  GREATER GIYANI",
    Latitude: "-23.536",
    Longitude: "30.7948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14075",
    WardLookupId: "1768",
    VDNumber: "76220939",
    RegisteredPopulation: "782",
    VotingStationName: "BVUMA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  LOLOKA  GREATER GIYANI",
    Latitude: "-23.5553",
    Longitude: "30.8093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14076",
    WardLookupId: "1768",
    VDNumber: "76220940",
    RegisteredPopulation: "838",
    VotingStationName: "NGONYAMA HIGH SCHOOL",
    Address: "NO STREET ADDRESS  MAGEVA VILLAGE  GREATER GIYANI",
    Latitude: "-23.5446",
    Longitude: "30.7142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14077",
    WardLookupId: "1769",
    VDNumber: "76220401",
    RegisteredPopulation: "1262",
    VotingStationName: "DZUMERI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  DZUMERI  GIYANI",
    Latitude: "-23.5744",
    Longitude: "30.7064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14078",
    WardLookupId: "1769",
    VDNumber: "76220412",
    RegisteredPopulation: "1377",
    VotingStationName: "NYUMBHANI SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS  NDHAMBI VILLAGE  GIYANI",
    Latitude: "-23.5694",
    Longitude: "30.6929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14079",
    WardLookupId: "1769",
    VDNumber: "76220704",
    RegisteredPopulation: "1010",
    VotingStationName: "NDZHOVELA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  DANIEL RAVALELE VILLAGE  MOPANI",
    Latitude: "-23.5788",
    Longitude: "30.6547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14080",
    WardLookupId: "1769",
    VDNumber: "76220737",
    RegisteredPopulation: "718",
    VotingStationName: "ASSEMBLIES OF GOD CHURCH",
    Address: "NO STREET ADDRESS  MAGEVA TOWNSHIP  GIYANI",
    Latitude: "-23.5625",
    Longitude: "30.7159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14081",
    WardLookupId: "1769",
    VDNumber: "76221312",
    RegisteredPopulation: "241",
    VotingStationName: "CHARLIE RHANGANI PRIMARY SCHOOL",
    Address: "MAGEVA   MOPANI  GIYANI",
    Latitude: "-23.559",
    Longitude: "30.7122",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14082",
    WardLookupId: "1770",
    VDNumber: "76220108",
    RegisteredPopulation: "862",
    VotingStationName: "PHADI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  BAMBENI VILLAGE  GIYANI",
    Latitude: "-23.4913",
    Longitude: "30.7004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14083",
    WardLookupId: "1770",
    VDNumber: "76220186",
    RegisteredPopulation: "1364",
    VotingStationName: "SKHUNYANI SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS  SKHUNYANI VILLAGE  GIYANI",
    Latitude: "-23.3986",
    Longitude: "30.6738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14084",
    WardLookupId: "1770",
    VDNumber: "76220636",
    RegisteredPopulation: "1007",
    VotingStationName: "MUSWANAMA SCHOOL",
    Address: "NO STREET ADDRESS  MAPHATA  GIYANI",
    Latitude: "-23.5115",
    Longitude: "30.7616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14085",
    WardLookupId: "1770",
    VDNumber: "76220647",
    RegisteredPopulation: "1016",
    VotingStationName: "RIRHANDZU PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  NKOMO 22A VILLAGE  GIYANI",
    Latitude: "-23.4447",
    Longitude: "30.7549",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14086",
    WardLookupId: "1770",
    VDNumber: "76221255",
    RegisteredPopulation: "636",
    VotingStationName: "MHLANGANISWENI PRIMARY SCHOOL",
    Address: "SKHUNYANI   GIYANI  MABUNDA TRIBAL AUTHORITY",
    Latitude: "-23.3911",
    Longitude: "30.6733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14087",
    WardLookupId: "1771",
    VDNumber: "76220456",
    RegisteredPopulation: "1272",
    VotingStationName: "MHINTLWA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  KHASHANE VILLAGE  GIYANI",
    Latitude: "-23.6758",
    Longitude: "30.8113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14088",
    WardLookupId: "1771",
    VDNumber: "76220467",
    RegisteredPopulation: "1008",
    VotingStationName: "XITLAKATI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  XITLAKATI VILLAGE  MOPANI",
    Latitude: "-23.6597",
    Longitude: "30.8812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14089",
    WardLookupId: "1771",
    VDNumber: "76220478",
    RegisteredPopulation: "715",
    VotingStationName: "MATSOTSOSELA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MATSOTSOSELA VILLAGE  GREATER GIYANI [GIYANI]",
    Latitude: "-23.6059",
    Longitude: "30.8282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14090",
    WardLookupId: "1771",
    VDNumber: "76220951",
    RegisteredPopulation: "542",
    VotingStationName: "MAYEPHU PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MAYEPHU VILLAGE  GREATER GIYANI",
    Latitude: "-23.5879",
    Longitude: "30.7816",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14091",
    WardLookupId: "1771",
    VDNumber: "76220995",
    RegisteredPopulation: "607",
    VotingStationName: "MZILELA PRIMARY SCHOOL",
    Address: "MZILELA VILLAGE  GREATER GIYANI  GREATER GIYANI [GIYANI]",
    Latitude: "-23.6006",
    Longitude: "30.8163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14092",
    WardLookupId: "1772",
    VDNumber: "76220434",
    RegisteredPopulation: "1681",
    VotingStationName: "GOVANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MPHAGANI VILLAGE  GIYANI",
    Latitude: "-23.6175",
    Longitude: "30.7058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14093",
    WardLookupId: "1772",
    VDNumber: "76220445",
    RegisteredPopulation: "1375",
    VotingStationName: "ZAVA THUSONG CENTRE",
    Address: "GIYANI  DZUMERI TRIBAL AUTHORITY",
    Latitude: "-23.6335",
    Longitude: "30.7351",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14094",
    WardLookupId: "1772",
    VDNumber: "76221110",
    RegisteredPopulation: "497",
    VotingStationName: "SASEKANI HIGH SCHOOL",
    Address: "NO STREET ADDRESS  MPHAGANI VILLAGE  GIYANI NU",
    Latitude: "-23.6194",
    Longitude: "30.6956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14095",
    WardLookupId: "1772",
    VDNumber: "76221266",
    RegisteredPopulation: "483",
    VotingStationName: "KATEKANI PRIMARY SCHOOL",
    Address: "N`WAMARHANGA   GIYANI  DZUMERI TRIBAL AUTHORITY",
    Latitude: "-23.6399",
    Longitude: "30.7369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14096",
    WardLookupId: "1773",
    VDNumber: "76220524",
    RegisteredPopulation: "1161",
    VotingStationName: "MAGULASAVI HIGH SCHOOL",
    Address: "NO STREET ADDRESS  MAKHUVA VILLAGE  GIYANI",
    Latitude: "-23.5812",
    Longitude: "30.9613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14097",
    WardLookupId: "1773",
    VDNumber: "76220535",
    RegisteredPopulation: "1258",
    VotingStationName: "NYIKO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MBAULA VILLAGE  GIYANI",
    Latitude: "-23.6179",
    Longitude: "31.0316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14098",
    WardLookupId: "1773",
    VDNumber: "76220546",
    RegisteredPopulation: "892",
    VotingStationName: "MAKHETO HIGH SCHOOL",
    Address: "MAKHUVA  MAKHUVA TRIBAL AUTHORITY",
    Latitude: "-23.5533",
    Longitude: "31.0567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14099",
    WardLookupId: "1773",
    VDNumber: "76221277",
    RegisteredPopulation: "712",
    VotingStationName: "ALBERT MABE PRIMARY SCHOOL",
    Address: "GIYANI  MAKHUVA TRIBAL AUTHORITY",
    Latitude: "-23.5856",
    Longitude: "30.9851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14100",
    WardLookupId: "1773",
    VDNumber: "76221301",
    RegisteredPopulation: "375",
    VotingStationName: "MASIZA HIGH SCHOOL",
    Address: "MBAWULA  MOPANI   GIYANI",
    Latitude: "-23.604",
    Longitude: "31.0233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14101",
    WardLookupId: "1774",
    VDNumber: "76220120",
    RegisteredPopulation: "1340",
    VotingStationName: "TLHARIHANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MAPUVE VILLAGE  GIYANI",
    Latitude: "-23.2836",
    Longitude: "30.574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14102",
    WardLookupId: "1774",
    VDNumber: "76220254",
    RegisteredPopulation: "1618",
    VotingStationName: "NGHALALUME PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  NGHALALUME VILLAGE  GIYANI",
    Latitude: "-23.2333",
    Longitude: "30.6128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14103",
    WardLookupId: "1774",
    VDNumber: "76221053",
    RegisteredPopulation: "698",
    VotingStationName: "CHAMANDU HIGH SCHOOL",
    Address: "NO STREET ADDRESS  MAPUVE VILLAGE  GIYANI NU",
    Latitude: "-23.2819",
    Longitude: "30.5816",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14104",
    WardLookupId: "1775",
    VDNumber: "76220388",
    RegisteredPopulation: "1473",
    VotingStationName: "RHIDA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MAPAYENI  GREATER GIYANI",
    Latitude: "-23.3561",
    Longitude: "30.8229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14105",
    WardLookupId: "1775",
    VDNumber: "76220726",
    RegisteredPopulation: "1119",
    VotingStationName: "VUHEHLI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  VUHEHLI VILLAGE  GIYANI",
    Latitude: "-23.4052",
    Longitude: "30.8941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14106",
    WardLookupId: "1775",
    VDNumber: "76220928",
    RegisteredPopulation: "360",
    VotingStationName: "EDWARD HOMU HIGH SCHOOL",
    Address: "NO STREET ADDRESS  MAPAYENI  GREATER GIYANI",
    Latitude: "-23.3604",
    Longitude: "30.8329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14107",
    WardLookupId: "1775",
    VDNumber: "76221020",
    RegisteredPopulation: "235",
    VotingStationName: "MUNYANGANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  NWA KHUWANI VILLAGE  MOPANI",
    Latitude: "-23.3841",
    Longitude: "30.8582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14108",
    WardLookupId: "1776",
    VDNumber: "76140503",
    RegisteredPopulation: "1395",
    VotingStationName: "LEAKHALE PRIMARY SCHOOL",
    Address: "MODJADJI ROAD  MADUMELENG VILLAGE  BOLOBEDU",
    Latitude: "-23.611",
    Longitude: "30.3282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14109",
    WardLookupId: "1776",
    VDNumber: "76140716",
    RegisteredPopulation: "1373",
    VotingStationName: "BOLOBEDU PRIMARY SCHOOL",
    Address: "BOLOBEDU  BOLEBEDU  TZANEEN",
    Latitude: "-23.6226",
    Longitude: "30.3459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14110",
    WardLookupId: "1776",
    VDNumber: "76140761",
    RegisteredPopulation: "1266",
    VotingStationName: "LETSEKU PRIMARY SCHOOL",
    Address: "MOKWAKWAILA ROAD  MATSHWI VILLAGE  MOPANI",
    Latitude: "-23.5955",
    Longitude: "30.3439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14111",
    WardLookupId: "1776",
    VDNumber: "76141144",
    RegisteredPopulation: "262",
    VotingStationName: "MODIKA HIGH SCHOOL",
    Address: "KHEKHUTING  MOPANI  MODJADJISKLOOF",
    Latitude: "-23.6263",
    Longitude: "30.3221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14112",
    WardLookupId: "1777",
    VDNumber: "76140424",
    RegisteredPopulation: "1163",
    VotingStationName: "RATHAGA PRIMARY SCHOOL",
    Address: "MODJADJI ROAD  BODUPE VILLAGE  BOLOBEDU",
    Latitude: "-23.6631",
    Longitude: "30.2592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14113",
    WardLookupId: "1777",
    VDNumber: "76140446",
    RegisteredPopulation: "1585",
    VotingStationName: "MOSHAKGA PRIMARY SCHOOL",
    Address: "MODJADJI ROAD  MOSHAKGA VILLAGE  BOLOBEDU",
    Latitude: "-23.6526",
    Longitude: "30.2672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14114",
    WardLookupId: "1777",
    VDNumber: "76140536",
    RegisteredPopulation: "514",
    VotingStationName: "MAKABA-SHOP",
    Address: "MAKABA VILLAGE  MOPANI  GA-MAKABA",
    Latitude: "-23.6696",
    Longitude: "30.2663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14115",
    WardLookupId: "1778",
    VDNumber: "76050042",
    RegisteredPopulation: "936",
    VotingStationName: "M .W KGATLA LOWER/HIGHER PRIMARY SCHOOL",
    Address: "1415 KGAPANE  ROAD  KGAPANE VILLAGE  BOLOBEDU",
    Latitude: "-23.6492",
    Longitude: "30.2235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14116",
    WardLookupId: "1778",
    VDNumber: "76050097",
    RegisteredPopulation: "900",
    VotingStationName: "MAPAANA PRIMARY SCHOOL",
    Address: "423 MAPAANA ROAD  MAPAANA VILLAGE  BOLOBEDU DISTRICT",
    Latitude: "-23.6383",
    Longitude: "30.2289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14117",
    WardLookupId: "1778",
    VDNumber: "76050110",
    RegisteredPopulation: "1273",
    VotingStationName: "MODUBATSE SECONDARY SCHOOL",
    Address: "NO STREET ADRESS AVAILIBLE  KGAPANE HOME 2000  POLOKWANE/MOPANI",
    Latitude: "-23.6479",
    Longitude: "30.2271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14118",
    WardLookupId: "1779",
    VDNumber: "76050019",
    RegisteredPopulation: "795",
    VotingStationName: "KGAPANE HIGH SCHOOL",
    Address: "MANNENGBURG SECTION  KGAPANE TOWNSHIP  BOLOBEDU",
    Latitude: "-23.6403",
    Longitude: "30.2131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14119",
    WardLookupId: "1779",
    VDNumber: "76050031",
    RegisteredPopulation: "2809",
    VotingStationName: "MAMATLEPA KGASHANE HIGHER PRIMARY SCHOOL",
    Address: "658 MAPOLANKENG  KGAPANE TOWNSHIP  BOLOBEDU",
    Latitude: "-23.6397",
    Longitude: "30.2195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14120",
    WardLookupId: "1780",
    VDNumber: "76050020",
    RegisteredPopulation: "1429",
    VotingStationName: "MEDINGEN PRIMARY SCHOOL",
    Address: "MEDINGEN ROAD  MEDINGEN VILLAGE  BOLOBEDU",
    Latitude: "-23.6415",
    Longitude: "30.2457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14121",
    WardLookupId: "1780",
    VDNumber: "76050086",
    RegisteredPopulation: "601",
    VotingStationName: "FUTURE LEADERS EDUCARE CENTRE",
    Address: "819 MANDELA PARK  MOPANI  MODJADJISKLOOF",
    Latitude: "-23.6558",
    Longitude: "30.2377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14122",
    WardLookupId: "1780",
    VDNumber: "76050132",
    RegisteredPopulation: "303",
    VotingStationName: "FELLOWSHIP ASSEMBLIES OF GOD",
    Address: "MEIDINGEN  BOLOBEDU  SEKGOTA",
    Latitude: "-23.6298",
    Longitude: "30.2518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14123",
    WardLookupId: "1780",
    VDNumber: "76140514",
    RegisteredPopulation: "420",
    VotingStationName: "MALEMATSHA PRIMARY SCHOOL",
    Address: "NO MALEMATJA  BOLOBEDU",
    Latitude: "-23.6182",
    Longitude: "30.2829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14124",
    WardLookupId: "1780",
    VDNumber: "76140794",
    RegisteredPopulation: "285",
    VotingStationName: "BANA BA THARI CAFE (TENT)",
    Address: "GA-MALEMATSHA  MOPANI  GA-MALEMATSHA",
    Latitude: "-23.6252",
    Longitude: "30.2637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14125",
    WardLookupId: "1780",
    VDNumber: "76140817",
    RegisteredPopulation: "279",
    VotingStationName: "MARAKA CAFE (TENT)",
    Address: "MEDINGEN VILLAGE  BOLOBEDU  GREATER LETABA RURAL",
    Latitude: "-23.6214",
    Longitude: "30.2738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14126",
    WardLookupId: "1781",
    VDNumber: "76140468",
    RegisteredPopulation: "1155",
    VotingStationName: "MAOLWE HIGH SCHOOL",
    Address: "MODJADJI ROAD  MOKWASELE VILLAGE  BOLOBEDU",
    Latitude: "-23.6377",
    Longitude: "30.2886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14127",
    WardLookupId: "1781",
    VDNumber: "76140479",
    RegisteredPopulation: "1375",
    VotingStationName: "SHOTONG PRIMARY SCHOOL",
    Address: "MODJADJI ROAD  SHOTONG  VILLAGE  BOLOBEDU",
    Latitude: "-23.6319",
    Longitude: "30.2967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14128",
    WardLookupId: "1781",
    VDNumber: "76140750",
    RegisteredPopulation: "556",
    VotingStationName: "RAMPHENYANE SHOP (TENT)",
    Address: "RAMPHENYANE  BOLOBEDU  GREATER LETABA RURAL",
    Latitude: "-23.6453",
    Longitude: "30.2783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14129",
    WardLookupId: "1781",
    VDNumber: "76141065",
    RegisteredPopulation: "768",
    VotingStationName: "G.N SESHAI SHOP",
    Address: "MOKWASELE  MOPANI  MODJADJI",
    Latitude: "-23.6421",
    Longitude: "30.285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14130",
    WardLookupId: "1782",
    VDNumber: "76140457",
    RegisteredPopulation: "862",
    VotingStationName: "RAMAKHUMA PRIMARY SCHOOL",
    Address: "MEIDINGEN ROAD  RABOTHATA VILLAGE  BOLOBEDU",
    Latitude: "-23.6346",
    Longitude: "30.2773",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14131",
    WardLookupId: "1782",
    VDNumber: "76140480",
    RegisteredPopulation: "1414",
    VotingStationName: "KHEKHUTINI PRIMARY SCHOOL",
    Address: "MODJADJI ROAD  MOHLAKAMOSOMA VILLAGE  BOLOBEDU",
    Latitude: "-23.6211",
    Longitude: "30.3216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14132",
    WardLookupId: "1782",
    VDNumber: "76140491",
    RegisteredPopulation: "369",
    VotingStationName: "LEKUKELA PRIMARY SCHOOL",
    Address: "MODJADJI ROAD  MOLLONG VILLAGE  BOLOBEDU",
    Latitude: "-23.6132",
    Longitude: "30.3087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14133",
    WardLookupId: "1782",
    VDNumber: "76140806",
    RegisteredPopulation: "590",
    VotingStationName: "MMANKOPANA PRIMARY SCHOOL",
    Address: "X MODJADJI ROAD  IKETLENG VILLAGE  BOLOBEDU",
    Latitude: "-23.6224",
    Longitude: "30.2999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14134",
    WardLookupId: "1782",
    VDNumber: "76140828",
    RegisteredPopulation: "379",
    VotingStationName: "MASELESELE PRIMARY SCHOOL",
    Address: "N/A  MOLLONG VILLAGE  BOLOBEDU",
    Latitude: "-23.6111",
    Longitude: "30.3132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14135",
    WardLookupId: "1783",
    VDNumber: "76050064",
    RegisteredPopulation: "1564",
    VotingStationName: "SEFAHONE PRIMARY SCHOOL",
    Address: "MODJADJI ROAD  RAPITSI VILLAGE  BOLOBEDU",
    Latitude: "-23.6586",
    Longitude: "30.2334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14136",
    WardLookupId: "1783",
    VDNumber: "76050109",
    RegisteredPopulation: "473",
    VotingStationName: "MODJADJIS FET COLLEGE",
    Address: "MODJADJI  ",
    Latitude: "-23.6502",
    Longitude: "30.223",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14137",
    WardLookupId: "1783",
    VDNumber: "76050121",
    RegisteredPopulation: "614",
    VotingStationName: "KHUBUDI SECONDARY SCHOOL COMITTEE",
    Address: "GA -RAPITSI  BOLOBEDU  RAPITSI",
    Latitude: "-23.6633",
    Longitude: "30.2331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14138",
    WardLookupId: "1783",
    VDNumber: "76140413",
    RegisteredPopulation: "1068",
    VotingStationName: "TSHWENI SECONDARY SCHOOL",
    Address: "MODJADJI ROAD  RAKWADU VILLAGE  BOLOBEDU",
    Latitude: "-23.6644",
    Longitude: "30.2488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14139",
    WardLookupId: "1784",
    VDNumber: "76330346",
    RegisteredPopulation: "936",
    VotingStationName: "MALADUMA PRIMARY SCHOOL",
    Address: "SEKGOPO ROAD  SEKGOPO VILLAGE  SEKGOSESE",
    Latitude: "-23.6266",
    Longitude: "29.9665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14140",
    WardLookupId: "1784",
    VDNumber: "76330357",
    RegisteredPopulation: "757",
    VotingStationName: "LEBOWAGANYANE PRIMARY SCHOOL",
    Address: "SEKGOPO ROAD  SEKGOPO VILLAGE  SEKGOSESE",
    Latitude: "-23.6198",
    Longitude: "29.9708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14141",
    WardLookupId: "1784",
    VDNumber: "76330368",
    RegisteredPopulation: "1063",
    VotingStationName: "MOTSHEUDI HIGH SCHOOL",
    Address: "KGOSI ROAD  SEKGOPO VILLAGE  SEKGOSESE",
    Latitude: "-23.6189",
    Longitude: "29.9807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14142",
    WardLookupId: "1784",
    VDNumber: "76330469",
    RegisteredPopulation: "1019",
    VotingStationName: "MATSWIDIKANYE PRIMARY SCHOOL",
    Address:
      "1456 BLOCK A SEKGOPO, GA MAILULA  MOPANI  GREATER LETABA [DUIWELSKLOOF]",
    Latitude: "-23.6333",
    Longitude: "29.964",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14143",
    WardLookupId: "1785",
    VDNumber: "76330379",
    RegisteredPopulation: "768",
    VotingStationName: "KILETSA PRIMARY SCHOOL",
    Address: "SEKGOPO ROAD  LEHLARENG VILLAGE  SEKGOSESE",
    Latitude: "-23.6109",
    Longitude: "29.9833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14144",
    WardLookupId: "1785",
    VDNumber: "76330380",
    RegisteredPopulation: "780",
    VotingStationName: "MAKHABENI PRIMARY SCHOOL",
    Address: "SEKGOPO ROAD  SEKGOPO VILLAGE  SEKGOSESE",
    Latitude: "-23.6054",
    Longitude: "30.0015",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14145",
    WardLookupId: "1785",
    VDNumber: "76330391",
    RegisteredPopulation: "1482",
    VotingStationName: "MOTSEKETLA PRIMARY SCHOOL",
    Address: "BOTTLE STORE ROAD  SEKGOPO VILLAGE  SEKGOSESE",
    Latitude: "-23.6035",
    Longitude: "29.9891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14146",
    WardLookupId: "1785",
    VDNumber: "76330403",
    RegisteredPopulation: "811",
    VotingStationName: "KGOLA KA LELEME HIGH SCHOOL",
    Address: "GA-RAMOADI  MOPANI  GA-SEKGOPO",
    Latitude: "-23.6098",
    Longitude: "30.0126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14147",
    WardLookupId: "1786",
    VDNumber: "76140525",
    RegisteredPopulation: "1439",
    VotingStationName: "MATSWI PRIMARY SCHOOL",
    Address: "MOKWAKWAILA ROAD  MATSHWI VILLAGE  BOLOBEDU DISTRICT",
    Latitude: "-23.5878",
    Longitude: "30.3605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14148",
    WardLookupId: "1786",
    VDNumber: "76140952",
    RegisteredPopulation: "883",
    VotingStationName: "THLABELENI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  GA MATSWHE  MOPANI ",
    Latitude: "-23.5805",
    Longitude: "30.3706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14149",
    WardLookupId: "1786",
    VDNumber: "76140974",
    RegisteredPopulation: "496",
    VotingStationName: "VINEYARD CHRISTIAN CHURCH",
    Address: "GA-MATSHWI  MOPANI  MODJADJI",
    Latitude: "-23.5916",
    Longitude: "30.3739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14150",
    WardLookupId: "1786",
    VDNumber: "76141076",
    RegisteredPopulation: "683",
    VotingStationName: "MATSHWI SOCCER GROUND (TENT)",
    Address: "MATSHWI  MOPANI  MODJADJISKLOOF",
    Latitude: "-23.5782",
    Longitude: "30.3631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14151",
    WardLookupId: "1787",
    VDNumber: "76330278",
    RegisteredPopulation: "564",
    VotingStationName: "MASEHLONE LOWER & HIGHER PRIMARY SCHOOL",
    Address: "ITIELENE ROAD  ITIELENE VILLAGE  SEKGOSESE",
    Latitude: "-23.4005",
    Longitude: "30.104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14152",
    WardLookupId: "1787",
    VDNumber: "76330289",
    RegisteredPopulation: "653",
    VotingStationName: "MOHALE CAFE",
    Address: "ITIELENE  MOPANI  GA-RAPHAHLELO",
    Latitude: "-23.4072",
    Longitude: "30.0959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14153",
    WardLookupId: "1787",
    VDNumber: "76330302",
    RegisteredPopulation: "725",
    VotingStationName: "MAKWA PRIMARY SCHOOL",
    Address: "LEPHAI ROAD  LEPHAI VILLAGE  SEKGOSESE",
    Latitude: "-23.4481",
    Longitude: "30.0333",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14154",
    WardLookupId: "1787",
    VDNumber: "76330313",
    RegisteredPopulation: "1615",
    VotingStationName: "PHEEHA TRIBAL OFFICE",
    Address: "561 PHEEHA ROAD  PHEEHA VILLAGE  SEKGOSESE",
    Latitude: "-23.461",
    Longitude: "30.0546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14155",
    WardLookupId: "1787",
    VDNumber: "76330470",
    RegisteredPopulation: "411",
    VotingStationName: "MAKELLE PRIMARY SCHOOL",
    Address: "GOUDPLAAS  SEKGOSESE  PHEEHA",
    Latitude: "-23.5239",
    Longitude: "30.0508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14156",
    WardLookupId: "1788",
    VDNumber: "76330223",
    RegisteredPopulation: "652",
    VotingStationName: "MOTSOKOTSA PRIMARY SCHOOL",
    Address: "PHOOKO ROAD  PHOOKO VILLAGE  SOEKMEKAAR",
    Latitude: "-23.3565",
    Longitude: "30.1435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14157",
    WardLookupId: "1788",
    VDNumber: "76330234",
    RegisteredPopulation: "1067",
    VotingStationName: "VAALWATER VISITING POINT HALL",
    Address: "VAALWATER ROAD  VAALWATER VILLAGE  SEKGOSESE",
    Latitude: "-23.3921",
    Longitude: "30.1562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14158",
    WardLookupId: "1788",
    VDNumber: "76330256",
    RegisteredPopulation: "1310",
    VotingStationName: "SENWAMOKGOPE LOWER PRIMARY SCHOOL",
    Address: "162 VAALWATER ROAD  SENWAMOKGOPE VILLAGE  SEKGOSESE",
    Latitude: "-23.4104",
    Longitude: "30.1547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14159",
    WardLookupId: "1788",
    VDNumber: "76330290",
    RegisteredPopulation: "776",
    VotingStationName: "VAALWATER PRE-SCHOOL",
    Address: "VAALWATER ROAD  VAALWATER VILLAGE  SEKGOSESE",
    Latitude: "-23.417",
    Longitude: "30.1522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14160",
    WardLookupId: "1788",
    VDNumber: "76330593",
    RegisteredPopulation: "769",
    VotingStationName: "MANGOAKO HIGH SCHOOL",
    Address: "ITIELENE  MOPANI  GARAPHAHLELO",
    Latitude: "-23.3972",
    Longitude: "30.1131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14161",
    WardLookupId: "1789",
    VDNumber: "76330267",
    RegisteredPopulation: "1525",
    VotingStationName: "NAHAKWE SECONDARY SCHOOL",
    Address: "184 LEMONDOKOP  LEMONDOKOP VILLAGE  SEKGOSESE",
    Latitude: "-23.4454",
    Longitude: "30.1502",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14162",
    WardLookupId: "1789",
    VDNumber: "76330324",
    RegisteredPopulation: "351",
    VotingStationName: "BERTIE VAN ZYL EDMS (BPK) (TENT)",
    Address: "MAKEPISI FARM  GREATER LETABA  MODJADJISKLOOF",
    Latitude: "-23.4974",
    Longitude: "30.2232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14163",
    WardLookupId: "1789",
    VDNumber: "76330425",
    RegisteredPopulation: "680",
    VotingStationName: "MOOKETSI COMMUNITY HALL",
    Address: "375 GIYANI ROAD  MOOKETSI  SEKGOSESE",
    Latitude: "-23.5918",
    Longitude: "30.1002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14164",
    WardLookupId: "1789",
    VDNumber: "76330458",
    RegisteredPopulation: "108",
    VotingStationName: "PLATLAND PRIMARY SCHOOL",
    Address: "PLATLAND FARM  MOPANI  MODJADJISKLOOF",
    Latitude: "-23.6111",
    Longitude: "30.1879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14165",
    WardLookupId: "1789",
    VDNumber: "76330515",
    RegisteredPopulation: "598",
    VotingStationName: "NAMATSABO PRIMARY SCHOOL",
    Address: "LEMONDOKOP  GREATER LETABA  SEKGOSESE NU",
    Latitude: "-23.4315",
    Longitude: "30.1615",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14166",
    WardLookupId: "1790",
    VDNumber: "76330199",
    RegisteredPopulation: "1354",
    VotingStationName: "RAPHAHLELO TRADITIONAL COUNCIL",
    Address: "0004 RAPHAHLELO ROAD  RAPHAHLELO VILLAGE  SEKGOSESE",
    Latitude: "-23.372",
    Longitude: "30.158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14167",
    WardLookupId: "1790",
    VDNumber: "76330201",
    RegisteredPopulation: "1097",
    VotingStationName: "PHOOKO TRIBAL OFFICE",
    Address: "AI1 PHOOKO STREET  PHOOKO VILLAGE  SEKGOSESE",
    Latitude: "-23.3537",
    Longitude: "30.1528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14168",
    WardLookupId: "1790",
    VDNumber: "76330212",
    RegisteredPopulation: "862",
    VotingStationName: "RAMAITE LOWER PRIMARY SCHOOL",
    Address: "GA-PHOOKO  MOPANI  MAMAILA, PHOOKO",
    Latitude: "-23.3612",
    Longitude: "30.1606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14169",
    WardLookupId: "1790",
    VDNumber: "76330481",
    RegisteredPopulation: "663",
    VotingStationName: "LIFE FELLOWSHIP CENTRE CHURCH",
    Address: "MAHEMBENI  SEKGOSESE  RAPHAHLELO",
    Latitude: "-23.371",
    Longitude: "30.1686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14170",
    WardLookupId: "1791",
    VDNumber: "76330111",
    RegisteredPopulation: "1134",
    VotingStationName: "MAHOCHOMBA PRIMARY SCHOOL",
    Address: "159 ROTTERDAM ROAD  ROTTERDAM VILLAGE  HLANGANANI",
    Latitude: "-23.4096",
    Longitude: "30.2573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14171",
    WardLookupId: "1791",
    VDNumber: "76330166",
    RegisteredPopulation: "675",
    VotingStationName: "TSHANGWANE PRIMARY SCHOOL",
    Address: "SEKGOSESE ROAD  SEPHUKUBJE VILLAGE  SEKGOSESE",
    Latitude: "-23.39",
    Longitude: "30.2378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14172",
    WardLookupId: "1791",
    VDNumber: "76330177",
    RegisteredPopulation: "1110",
    VotingStationName: "KOLOBETONA SECONDARY SCHOOL",
    Address: "SEKGOSESE ROAD  SEPHUKUBJE VILLAGE  SEKGOSESE DISTRICT",
    Latitude: "-23.4014",
    Longitude: "30.2251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14173",
    WardLookupId: "1791",
    VDNumber: "76330537",
    RegisteredPopulation: "458",
    VotingStationName: "ROTTERDAM HIGH SCHOOL",
    Address: "ROTTERDAM  MOPANI  MODJADJISKLOOF",
    Latitude: "-23.4134",
    Longitude: "30.2497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14174",
    WardLookupId: "1791",
    VDNumber: "76330571",
    RegisteredPopulation: "153",
    VotingStationName: "TSHAMISEKA PRIMARY SCHOOL",
    Address: "ROTTERDAM  MOPANI  VUYANI",
    Latitude: "-23.4216",
    Longitude: "30.238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14175",
    WardLookupId: "1792",
    VDNumber: "76330144",
    RegisteredPopulation: "1203",
    VotingStationName: "MAMAILA PRIMARY SCHOOL",
    Address: "139 MAMAILA ROAD  MAMAILA VILLAGE  SEKGOSESE",
    Latitude: "-23.3531",
    Longitude: "30.2404",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14176",
    WardLookupId: "1792",
    VDNumber: "76330155",
    RegisteredPopulation: "739",
    VotingStationName: "MAMAILA KOLOBETONA TRADITIONAL COUNCIL",
    Address: "23 MAMAILA  ROAD  MAMAILA VILLAGE  SEKGOSESE",
    Latitude: "-23.3567",
    Longitude: "30.2275",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14177",
    WardLookupId: "1792",
    VDNumber: "76330447",
    RegisteredPopulation: "860",
    VotingStationName: "LEKGOLO PRIMARY SCHOOL",
    Address: "MAMAILA ROAD  MAMAILA KOLOBETONA VILLAGE  SEKGOSESE",
    Latitude: "-23.3613",
    Longitude: "30.239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14178",
    WardLookupId: "1792",
    VDNumber: "76330504",
    RegisteredPopulation: "761",
    VotingStationName: "SETSEKANA PRIMARY SCHOOL",
    Address: "MAMAILA  SEKGOSESE  MAMAILA",
    Latitude: "-23.3522",
    Longitude: "30.252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14179",
    WardLookupId: "1792",
    VDNumber: "76330548",
    RegisteredPopulation: "486",
    VotingStationName: "RABATSWANA PRIMARY SCHOOL",
    Address: "MAMAILA  MOPANI  MAMAILA",
    Latitude: "-23.344",
    Longitude: "30.2392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14180",
    WardLookupId: "1793",
    VDNumber: "76330188",
    RegisteredPopulation: "1058",
    VotingStationName: "KOPANANG PRIMARY SCHOOL",
    Address: "157 SEPHUKHUBJE  SEPHUKHUBJE VILLAGE  SEKGOSESE",
    Latitude: "-23.4106",
    Longitude: "30.2122",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14181",
    WardLookupId: "1793",
    VDNumber: "76330245",
    RegisteredPopulation: "1215",
    VotingStationName: "SEBELAOLO PRIMARY SCHOOL",
    Address: "RAPHAHLELO ROAD  RAPHAHLELO VILLAGE  SEKGOSESE",
    Latitude: "-23.3785",
    Longitude: "30.1704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14182",
    WardLookupId: "1793",
    VDNumber: "76330559",
    RegisteredPopulation: "596",
    VotingStationName: "MANKETE PRIMARY SCHOOL",
    Address: "SEPHUKUBJE  MOPANI  MODJADJISKLOOF",
    Latitude: "-23.4227",
    Longitude: "30.2053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14183",
    WardLookupId: "1793",
    VDNumber: "76330560",
    RegisteredPopulation: "528",
    VotingStationName: "KHUDUGANE SECONDARY SCHOOL",
    Address: "GARAPHAHLELO  MOPANI  GARAPHAHLELO",
    Latitude: "-23.3838",
    Longitude: "30.1774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14184",
    WardLookupId: "1794",
    VDNumber: "76140031",
    RegisteredPopulation: "917",
    VotingStationName: "LEBAKA PRIMARY SCHOOL",
    Address: "MODJADJI ROAD  MOHLABANENG VILLAGE  BOLOBEDU",
    Latitude: "-23.4633",
    Longitude: "30.3668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14185",
    WardLookupId: "1794",
    VDNumber: "76140266",
    RegisteredPopulation: "1255",
    VotingStationName: "MOLATE SECONDARY SCHOOL",
    Address: "GIYANI ROAD  JAMELA VILLAGE  BOLOBEDU",
    Latitude: "-23.4462",
    Longitude: "30.3427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14186",
    WardLookupId: "1794",
    VDNumber: "76140323",
    RegisteredPopulation: "604",
    VotingStationName: "JAMELA PRIMARY SCHOOL",
    Address: "GIYANI ROAD  JAMELA VILLAGE  BOLOBEDU",
    Latitude: "-23.4461",
    Longitude: "30.3488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14187",
    WardLookupId: "1794",
    VDNumber: "76141087",
    RegisteredPopulation: "753",
    VotingStationName: "LEBAKA ONE STOP CENTRE",
    Address: "MOHLABANENG  MOPANI  MODJADJISKLOOF",
    Latitude: "-23.4624",
    Longitude: "30.3717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14188",
    WardLookupId: "1795",
    VDNumber: "76140143",
    RegisteredPopulation: "1456",
    VotingStationName: "NOKANE PRIMARY SCHOOL",
    Address: "DITSHOSING ROAD  DITSHOSING VILLAGE  BOLOBEDU",
    Latitude: "-23.4868",
    Longitude: "30.3389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14189",
    WardLookupId: "1795",
    VDNumber: "76140211",
    RegisteredPopulation: "1343",
    VotingStationName: "PIPA LOWER & HIGHER PRIMARY SCHOOL",
    Address: "GIYANI ROAD  MAPHALLE VILLAGE  BOLOBEDU",
    Latitude: "-23.4665",
    Longitude: "30.3066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14190",
    WardLookupId: "1795",
    VDNumber: "76140277",
    RegisteredPopulation: "1046",
    VotingStationName: "MODISHA SECONDARY SCHOOL",
    Address: "GIYANI ROAD  MAPHALLE VILLAGE  BOLOBEDU",
    Latitude: "-23.4629",
    Longitude: "30.3172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14191",
    WardLookupId: "1795",
    VDNumber: "76141155",
    RegisteredPopulation: "89",
    VotingStationName: "NGOBENI M.H XISUKA CAR SERVICES",
    Address: "701 SHIAWELO B  MOPANI  MODJADJISKLOOF",
    Latitude: "-23.4653",
    Longitude: "30.3369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14192",
    WardLookupId: "1796",
    VDNumber: "76140020",
    RegisteredPopulation: "1022",
    VotingStationName: "MANONYANENG SECONDARY SCHOOL",
    Address: "MODJADJI ROAD  MOHLABANENG VILLAGE  BOLOBEDU",
    Latitude: "-23.4713",
    Longitude: "30.3767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14193",
    WardLookupId: "1796",
    VDNumber: "76140097",
    RegisteredPopulation: "1149",
    VotingStationName: "FEMANE SCHOOL COMMITTEE",
    Address: "MODJADJI ROAD  FEMANE VILLAGE  BOLOBEDU",
    Latitude: "-23.499",
    Longitude: "30.4075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14194",
    WardLookupId: "1796",
    VDNumber: "76140345",
    RegisteredPopulation: "833",
    VotingStationName: "MOTSIPA SECONDARY SCHOOL",
    Address: "FOTLOTLO  MOPANI  GA-RAMAROKA",
    Latitude: "-23.511",
    Longitude: "30.355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14195",
    WardLookupId: "1796",
    VDNumber: "76140985",
    RegisteredPopulation: "426",
    VotingStationName: "DISHA HOME BASED CARE",
    Address: "DISHOSING  BOLOBEDU  LEBEA",
    Latitude: "-23.4975",
    Longitude: "30.3297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14196",
    WardLookupId: "1797",
    VDNumber: "76140042",
    RegisteredPopulation: "427",
    VotingStationName: "KUBUNE  PRIMARY SCHOOL",
    Address:
      "NO STREET ADDRESS AVAILABLE  MAMAILA VILLAGE   MAMAILA VILLAGE IN LETABA",
    Latitude: "-23.3863",
    Longitude: "30.4359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14197",
    WardLookupId: "1797",
    VDNumber: "76140200",
    RegisteredPopulation: "1056",
    VotingStationName: "NAKAMPE PRIMARY SCHOOL",
    Address: "GIYANI ROAD  NAKAMPE VILLAGE  BOLOBEDU",
    Latitude: "-23.3925",
    Longitude: "30.4595",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14198",
    WardLookupId: "1797",
    VDNumber: "76140288",
    RegisteredPopulation: "1138",
    VotingStationName: "PHAKENG PRIMARY SCHOOL",
    Address: "GIYANI ROAD  MAKGAKGAPATSE VILLAGE  BOLOBEDU",
    Latitude: "-23.3688",
    Longitude: "30.4971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14199",
    WardLookupId: "1797",
    VDNumber: "76140390",
    RegisteredPopulation: "1111",
    VotingStationName: "CHRIS HANI COMMUNITY HALL",
    Address: "GIYANI ROAD  MAMAILA VILLAGE  BOLOBEDU",
    Latitude: "-23.3921",
    Longitude: "30.4404",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14200",
    WardLookupId: "1797",
    VDNumber: "76140783",
    RegisteredPopulation: "792",
    VotingStationName: "TSEANA HIGH SCHOOL",
    Address: "GIYANI ROAD  GA-MAMAILA  BOLOBEDU",
    Latitude: "-23.385",
    Longitude: "30.4358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14201",
    WardLookupId: "1798",
    VDNumber: "76140176",
    RegisteredPopulation: "1069",
    VotingStationName: "RAMMILA SECONDARY SCHOOL",
    Address: "GIYANI ROAD  SEDIBENG VILLAGE  BOLOBEDU",
    Latitude: "-23.3846",
    Longitude: "30.3912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14202",
    WardLookupId: "1798",
    VDNumber: "76140198",
    RegisteredPopulation: "1167",
    VotingStationName: "RAMOLLO PRIMARY SCHOOL",
    Address: "GIYANI ROAD  BELLVUE VILLAGE  BOLOBEDU",
    Latitude: "-23.4236",
    Longitude: "30.4046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14203",
    WardLookupId: "1798",
    VDNumber: "76140244",
    RegisteredPopulation: "745",
    VotingStationName: "MAUFOTA PRIMARY SCHOOL",
    Address: "GIYANI ROAD  MAUPA VILLAGE  BOLOBEDU",
    Latitude: "-23.4201",
    Longitude: "30.3802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14204",
    WardLookupId: "1798",
    VDNumber: "76140873",
    RegisteredPopulation: "632",
    VotingStationName: "RAMATIMANA PRIMARY SCHOOL",
    Address: "GIYANI ROAD  SEFOFOTSE VILLAGE  BOLOBEDU",
    Latitude: "-23.4382",
    Longitude: "30.4096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14205",
    WardLookupId: "1798",
    VDNumber: "76141098",
    RegisteredPopulation: "291",
    VotingStationName: "BELLEVUE CLINIC (TENT)",
    Address: "BELLEVUE  MOPANI  BELLEVUE",
    Latitude: "-23.4127",
    Longitude: "30.4205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14206",
    WardLookupId: "1798",
    VDNumber: "76141100",
    RegisteredPopulation: "326",
    VotingStationName: "KHEODI SECONDARY SCHOOL",
    Address: "BELLEVUE  MOPANI  BOLOBEDU",
    Latitude: "-23.4285",
    Longitude: "30.3987",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14207",
    WardLookupId: "1799",
    VDNumber: "76140110",
    RegisteredPopulation: "710",
    VotingStationName: "KHATHOLA SECONDARY SCHOOL",
    Address: "GIYANI ROAD  MAMOKGADI VILLAGE  BOLOBEDU",
    Latitude: "-23.4265",
    Longitude: "30.4464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14208",
    WardLookupId: "1799",
    VDNumber: "76140121",
    RegisteredPopulation: "803",
    VotingStationName: "KHUMELONG  PRIMARY SCHOOL",
    Address: "MODJADJI ROAD  SEAPHOLE VILLAGE  BOLOBEDU",
    Latitude: "-23.4976",
    Longitude: "30.4617",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14209",
    WardLookupId: "1799",
    VDNumber: "76140187",
    RegisteredPopulation: "960",
    VotingStationName: "KHUTSONG PRIMARY SCHOOL",
    Address: "MODJADJI ROAD  NTATA VILLAGE  BOLOBEDU",
    Latitude: "-23.4753",
    Longitude: "30.4486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14210",
    WardLookupId: "1799",
    VDNumber: "76140918",
    RegisteredPopulation: "816",
    VotingStationName: "NORTHAMPTON PRIM SCHOOL",
    Address: "DUIWELSKLOOF  ",
    Latitude: "-23.4671",
    Longitude: "30.4456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14211",
    WardLookupId: "1800",
    VDNumber: "76140222",
    RegisteredPopulation: "932",
    VotingStationName: "LERALE PRIMARY SCHOOL",
    Address: "MODJADJI ROAD  BOQA VILLAGE  BOLOBEDU",
    Latitude: "-23.5244",
    Longitude: "30.6108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14212",
    WardLookupId: "1800",
    VDNumber: "76140255",
    RegisteredPopulation: "731",
    VotingStationName: "METSI- A - PHEPA PRIMARY",
    Address: "MODJADJI ROAD  MOHLELE VILLAGE  BOLOBEDU",
    Latitude: "-23.4886",
    Longitude: "30.5762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14213",
    WardLookupId: "1800",
    VDNumber: "76140312",
    RegisteredPopulation: "926",
    VotingStationName: "BORELETSANE PRIMARY SCHOOL",
    Address: "MODJADJI ROAD  ABEL VILLAGE  BOLOBEDU",
    Latitude: "-23.487",
    Longitude: "30.6176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14214",
    WardLookupId: "1800",
    VDNumber: "76140851",
    RegisteredPopulation: "640",
    VotingStationName: "TAULOME PRIMARY SCHOOL",
    Address: "TAULOME VILLAGE  BOLOBEDU",
    Latitude: "-23.5202",
    Longitude: "30.553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14215",
    WardLookupId: "1800",
    VDNumber: "76280575",
    RegisteredPopulation: "818",
    VotingStationName: "LEKGWARENG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  LEKGWARENG VILLAGE  MOPANI",
    Latitude: "-23.5703",
    Longitude: "30.627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14216",
    WardLookupId: "1801",
    VDNumber: "76140356",
    RegisteredPopulation: "914",
    VotingStationName: "KGOBAKANANE  PRIMARY SCHOOL",
    Address: "MODJADJI ROAD  KURANTA VILLAGE  BOLOBEDU",
    Latitude: "-23.4569",
    Longitude: "30.5227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14217",
    WardLookupId: "1801",
    VDNumber: "76140884",
    RegisteredPopulation: "631",
    VotingStationName: "TSOGANG PRIMARY SCHOOL",
    Address: "NO STREET NAME  RAMODUMO  GREATER LETABA [DUIWELSKLOOF]",
    Latitude: "-23.477",
    Longitude: "30.5534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14218",
    WardLookupId: "1801",
    VDNumber: "76220096",
    RegisteredPopulation: "1128",
    VotingStationName: "THABISONG PRIMARY SCHOOL",
    Address: "JOKONG ROAD  JOKONG VILLAGE  BOLOBEDU",
    Latitude: "-23.424",
    Longitude: "30.6074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14219",
    WardLookupId: "1801",
    VDNumber: "76220760",
    RegisteredPopulation: "904",
    VotingStationName: "SHAMIRIRI COMBINED SCHOOL",
    Address: "001 SHAMFANA VILLAGE  GIYANI",
    Latitude: "-23.4615",
    Longitude: "30.6572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14220",
    WardLookupId: "1801",
    VDNumber: "76220805",
    RegisteredPopulation: "804",
    VotingStationName: "MAMPEULE SECONDARY SCHOOL",
    Address: "MAMPEULE ROAD  MAMPEULE VILLAGE  GIYANI",
    Latitude: "-23.4412",
    Longitude: "30.6422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14221",
    WardLookupId: "1802",
    VDNumber: "76140086",
    RegisteredPopulation: "646",
    VotingStationName: "SETLOUMANE PRIMARY SCHOOL",
    Address: "RAMPEPE ROAD  RAMPEPE VILLAGE  BOLOBEDU",
    Latitude: "-23.5372",
    Longitude: "30.4552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14222",
    WardLookupId: "1802",
    VDNumber: "76140109",
    RegisteredPopulation: "962",
    VotingStationName: "RAMA SECONDARY SCHOOL",
    Address: "MODJADJI ROAD  MAHEKGWE VILLAGE  BOLOBEDU",
    Latitude: "-23.4974",
    Longitude: "30.5163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14223",
    WardLookupId: "1802",
    VDNumber: "76140132",
    RegisteredPopulation: "760",
    VotingStationName: "MAMONYOHA PRIMARY SCHOOL",
    Address: "MODJADJI ROAD  MAMANYOHA VILLAGE  BOLOBEDU",
    Latitude: "-23.5217",
    Longitude: "30.5179",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14224",
    WardLookupId: "1802",
    VDNumber: "76140840",
    RegisteredPopulation: "715",
    VotingStationName: "THLOTHLOKWE PRIMARY SCHOOL",
    Address: "HLOHLOKWE  THLOTHLOKWE VILLAGE  BOLOBEDU",
    Latitude: "-23.5544",
    Longitude: "30.495",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14225",
    WardLookupId: "1802",
    VDNumber: "76140907",
    RegisteredPopulation: "729",
    VotingStationName: "MOHOKONE PRIMARY SCHOOL",
    Address: "NO STREET NAME  MOHOKONI VILLAGE  BOLOBEDU",
    Latitude: "-23.5097",
    Longitude: "30.4453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14226",
    WardLookupId: "1803",
    VDNumber: "76330087",
    RegisteredPopulation: "953",
    VotingStationName: "MAHUNTSI PRIMARY SCHOOL",
    Address: "153 ROTTERDAM ROAD  ROTTERDAM VILLAGE  HLANGANANI",
    Latitude: "-23.3927",
    Longitude: "30.2922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14227",
    WardLookupId: "1803",
    VDNumber: "76330098",
    RegisteredPopulation: "1349",
    VotingStationName: "VUYANI TRIBAL OFFICE",
    Address: "469 ROTTERDAM ROAD  ROTTERDAM VILLAGE  HLANGANANI",
    Latitude: "-23.4009",
    Longitude: "30.2755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14228",
    WardLookupId: "1803",
    VDNumber: "76330492",
    RegisteredPopulation: "499",
    VotingStationName: "BEREA ASSEMBLIES OF GOD",
    Address: "ROTTERDAM  VUYANI  GREATER LETABA",
    Latitude: "-23.4015",
    Longitude: "30.268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14229",
    WardLookupId: "1803",
    VDNumber: "76330582",
    RegisteredPopulation: "657",
    VotingStationName: "DUVULA MAHUNTSI HIGH SCHOOL",
    Address: "ROTTERDAM  MODJADJIESKLOOF  GREATER LETABA",
    Latitude: "-23.4007",
    Longitude: "30.2795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14230",
    WardLookupId: "1804",
    VDNumber: "76050053",
    RegisteredPopulation: "1444",
    VotingStationName: "LAERSKOOL DUIWELSKLOOF",
    Address: "9 MENTZ STREET  DUIWELSKLOOF  DUIWELSKLOOF",
    Latitude: "-23.694",
    Longitude: "30.1443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14231",
    WardLookupId: "1804",
    VDNumber: "76140862",
    RegisteredPopulation: "869",
    VotingStationName: "MAUPA PRIMARY SCHOOL",
    Address: "MOKGOBA VILLAGE; MODJADJISKLOOF; 0835  MOKGOBA VILAGE  BOLOBEDU",
    Latitude: "-23.7109",
    Longitude: "30.1316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14232",
    WardLookupId: "1804",
    VDNumber: "76330414",
    RegisteredPopulation: "1131",
    VotingStationName: "MANTSHA PRIMARY SCHOOL",
    Address: "SEKGOPO ROAD  SEKGOPO VILLAGE  SEKGOSESE",
    Latitude: "-23.6161",
    Longitude: "30.0168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14233",
    WardLookupId: "1805",
    VDNumber: "76140154",
    RegisteredPopulation: "601",
    VotingStationName: "HORIZON THUSANANG",
    Address: "MATIPANE   MODJADJISKLOOF  GREATER LETABA",
    Latitude: "-23.5552",
    Longitude: "30.3838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14234",
    WardLookupId: "1805",
    VDNumber: "76140334",
    RegisteredPopulation: "742",
    VotingStationName: "MOTHOBEKHI PRIMARY SCHOOL",
    Address: "NO STREET NAME  MOTHOBEKGI VILLAGE  BOLOBEDU",
    Latitude: "-23.5462",
    Longitude: "30.3749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14235",
    WardLookupId: "1805",
    VDNumber: "76140378",
    RegisteredPopulation: "462",
    VotingStationName: "SEKGOTHI  PRIMARY SCHOOL",
    Address: "MODJADJI ROAD  MABULANA VILLAGE  BOLOBEDU",
    Latitude: "-23.5721",
    Longitude: "30.3456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14236",
    WardLookupId: "1805",
    VDNumber: "76140402",
    RegisteredPopulation: "752",
    VotingStationName: "RAMAROKA PRIMARY SCHOOL",
    Address: "MODJADJI ROAD  POLASENG VILLAGE  BOLOBEDU",
    Latitude: "-23.5314",
    Longitude: "30.3936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14237",
    WardLookupId: "1805",
    VDNumber: "76140895",
    RegisteredPopulation: "648",
    VotingStationName: "SEKGWARE HIGH SCHOOL",
    Address: "MODJADJI ROAD  SEKGOTHI VILLAGE  BOLOBEDU",
    Latitude: "-23.5816",
    Longitude: "30.3362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14238",
    WardLookupId: "1805",
    VDNumber: "76141111",
    RegisteredPopulation: "243",
    VotingStationName: "RAMAROKA EMMANUEL CHURCH",
    Address: "FOHLOHLO  MOPANI  RAMAROKA",
    Latitude: "-23.5131",
    Longitude: "30.3628",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14239",
    WardLookupId: "1806",
    VDNumber: "76140019",
    RegisteredPopulation: "1146",
    VotingStationName: "SENOPELWA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  SENOPELWA  MOPANI",
    Latitude: "-23.5614",
    Longitude: "30.383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14240",
    WardLookupId: "1806",
    VDNumber: "76140299",
    RegisteredPopulation: "992",
    VotingStationName: "MASOPHA SECONDARY SCHOOL",
    Address: "MATIPANE  BOLEBEDU  TZANEEN",
    Latitude: "-23.5577",
    Longitude: "30.4008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14241",
    WardLookupId: "1806",
    VDNumber: "76140301",
    RegisteredPopulation: "927",
    VotingStationName: "MORAPALALA PRIMARY SCHOOL",
    Address: "BOLEBEDU  TZANEEN",
    Latitude: "-23.5975",
    Longitude: "30.4193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14242",
    WardLookupId: "1806",
    VDNumber: "76140367",
    RegisteredPopulation: "1521",
    VotingStationName: "SENAKWE PRIMARY SCHOOL",
    Address: "MAWA  GREATER TZANEEN  TZANEEN",
    Latitude: "-23.5457",
    Longitude: "30.4174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14243",
    WardLookupId: "1806",
    VDNumber: "76140749",
    RegisteredPopulation: "690",
    VotingStationName: "MABJIPELONG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MABJIPELONG VILLAGE  MOPANI",
    Latitude: "-23.5825",
    Longitude: "30.4634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14244",
    WardLookupId: "1806",
    VDNumber: "76141043",
    RegisteredPopulation: "532",
    VotingStationName: "MOLOTOTSI CO-OPERATIVE HALL",
    Address: "MOKWAKWAILA  BOLOBEDU  MOKWAKWAILA",
    Latitude: "-23.5292",
    Longitude: "30.4248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14245",
    WardLookupId: "1807",
    VDNumber: "76141032",
    RegisteredPopulation: "324",
    VotingStationName: "MAULOKO PRIMARY SCHOOL",
    Address: "MAWA  GREATER TZANEEN  TZANEEN",
    Latitude: "-23.5709",
    Longitude: "30.4561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14246",
    WardLookupId: "1807",
    VDNumber: "76280025",
    RegisteredPopulation: "1100",
    VotingStationName: "MOKGWATHI L/H PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MODJADJI  MOPONI",
    Latitude: "-23.5958",
    Longitude: "30.5831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14247",
    WardLookupId: "1807",
    VDNumber: "76280036",
    RegisteredPopulation: "1171",
    VotingStationName: "VALLAMBROSA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MODJADJI  MOPANI",
    Latitude: "-23.5794",
    Longitude: "30.5838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14248",
    WardLookupId: "1807",
    VDNumber: "76280070",
    RegisteredPopulation: "1011",
    VotingStationName: "MOHALE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BOLOBEDU  MOPANI",
    Latitude: "-23.6108",
    Longitude: "30.5412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14249",
    WardLookupId: "1807",
    VDNumber: "76280553",
    RegisteredPopulation: "919",
    VotingStationName: "MAWA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MAWA BLOCK 8  MOPANI",
    Latitude: "-23.591",
    Longitude: "30.5268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14250",
    WardLookupId: "1807",
    VDNumber: "76280564",
    RegisteredPopulation: "763",
    VotingStationName: "MASEFORA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MAWA BLOCK 9  MOPANI",
    Latitude: "-23.5684",
    Longitude: "30.5433",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14251",
    WardLookupId: "1808",
    VDNumber: "76280014",
    RegisteredPopulation: "1421",
    VotingStationName: "OOGHOEK PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BOLOBEDU  MOPANI",
    Latitude: "-23.627",
    Longitude: "30.532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14252",
    WardLookupId: "1808",
    VDNumber: "76280058",
    RegisteredPopulation: "1296",
    VotingStationName: "XIHOKO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  XIHOKO  MOPANI",
    Latitude: "-23.6647",
    Longitude: "30.5013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14253",
    WardLookupId: "1808",
    VDNumber: "76280092",
    RegisteredPopulation: "988",
    VotingStationName: "MIRAGOMA SCHOOL",
    Address: "BOLOBEDU  RAMOTSHINYADI VILLAGE  TZANEEN",
    Latitude: "-23.6242",
    Longitude: "30.5909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14254",
    WardLookupId: "1808",
    VDNumber: "76280159",
    RegisteredPopulation: "990",
    VotingStationName: "MAKOTI HALL",
    Address: "NO STREET ADDRESS AVAILABLE  RAMOCHINYADI VILLAGE  MOPANI",
    Latitude: "-23.6382",
    Longitude: "30.5912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14255",
    WardLookupId: "1808",
    VDNumber: "76280722",
    RegisteredPopulation: "470",
    VotingStationName: "NDZHUNGULWANA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  XIHOKO  MOPANI",
    Latitude: "-23.6733",
    Longitude: "30.5091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14256",
    WardLookupId: "1808",
    VDNumber: "76280733",
    RegisteredPopulation: "489",
    VotingStationName: "MASAKHANE PREPARATORY SCHOOL",
    Address: "NO STREET ADDRESS AVALAIBLE  WALE   MOPANI  ",
    Latitude: "-23.6315",
    Longitude: "30.5372",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14257",
    WardLookupId: "1808",
    VDNumber: "76280788",
    RegisteredPopulation: "627",
    VotingStationName: "GA MOLOKWANE MOSHATE",
    Address: "GA MOLOKWANE  RAMOCHINYADI  RAMOCHINYADI",
    Latitude: "-23.6425",
    Longitude: "30.591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14258",
    WardLookupId: "1809",
    VDNumber: "76280137",
    RegisteredPopulation: "893",
    VotingStationName: "NYAVANA TRIBAL OFFICE",
    Address: "XIHOKO  LETABA  TZANEEN",
    Latitude: "-23.6359",
    Longitude: "30.496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14259",
    WardLookupId: "1809",
    VDNumber: "76280148",
    RegisteredPopulation: "1155",
    VotingStationName: "GWAMBENI SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  RITAVI  MOPANI",
    Latitude: "-23.6546",
    Longitude: "30.4847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14260",
    WardLookupId: "1809",
    VDNumber: "76280373",
    RegisteredPopulation: "1629",
    VotingStationName: "NWAMUNGOLOLO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  RIKHOTSO  MOPANI",
    Latitude: "-23.6593",
    Longitude: "30.4573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14261",
    WardLookupId: "1809",
    VDNumber: "76280395",
    RegisteredPopulation: "540",
    VotingStationName: "MAKGOPE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BOLOBEDU  MOPANI",
    Latitude: "-23.6174",
    Longitude: "30.4637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14262",
    WardLookupId: "1809",
    VDNumber: "76280586",
    RegisteredPopulation: "558",
    VotingStationName: "MOLOKWANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  GA-WALE VILLAGE  MOPANI",
    Latitude: "-23.6262",
    Longitude: "30.4868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14263",
    WardLookupId: "1809",
    VDNumber: "76280687",
    RegisteredPopulation: "408",
    VotingStationName: "MDINGAZI SECONDARY SCHOOL",
    Address: "XIHOKO  GREATER TZANEEN  NYAVANA TRIBAL AUTHORITY ",
    Latitude: "-23.6397",
    Longitude: "30.4837",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14264",
    WardLookupId: "1809",
    VDNumber: "76280889",
    RegisteredPopulation: "354",
    VotingStationName: "MAMODINI HIGH SCHOOL",
    Address: "MOOKGO BLOCK 7  XIHOKO  MOOKGO",
    Latitude: "-23.6127",
    Longitude: "30.4963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14265",
    WardLookupId: "1810",
    VDNumber: "76280081",
    RegisteredPopulation: "709",
    VotingStationName: "HETISEKA SECONDARY SCHOOL",
    Address: "MSIPHANI  NWAMITWA  VALOYI TRIBAL AUTHORITY",
    Latitude: "-23.71",
    Longitude: "30.434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14266",
    WardLookupId: "1810",
    VDNumber: "76280328",
    RegisteredPopulation: "1330",
    VotingStationName: "MAHWAHWA HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MAMITWA  MOPANI",
    Latitude: "-23.735",
    Longitude: "30.4738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14267",
    WardLookupId: "1810",
    VDNumber: "76280384",
    RegisteredPopulation: "2582",
    VotingStationName: "VHULAKAJHANI PRIMARY SCHOOL",
    Address: "NKAMBAKO  LETSITELE",
    Latitude: "-23.7356",
    Longitude: "30.4864",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14268",
    WardLookupId: "1810",
    VDNumber: "76280643",
    RegisteredPopulation: "217",
    VotingStationName: "TOTALREPENTANCE MINISTRIES OF ALL CHURCHES",
    Address: "52-54 DEESIDE  LETSITELE  NWAMITWA",
    Latitude: "-23.7785",
    Longitude: "30.5218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14269",
    WardLookupId: "1810",
    VDNumber: "76280698",
    RegisteredPopulation: "599",
    VotingStationName: "MALUBANA SENIOR PRIMARY SCHOOL",
    Address: "NWAMITWA  NKAMBAKO  MOPANI",
    Latitude: "-23.7336",
    Longitude: "30.4965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14270",
    WardLookupId: "1810",
    VDNumber: "76280799",
    RegisteredPopulation: "462",
    VotingStationName: "MUGWAZENI SECONDARY SCHOOL",
    Address: "MUGWAZENI  NWAMITWA  NWAMITWA",
    Latitude: "-23.7268",
    Longitude: "30.4544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14271",
    WardLookupId: "1811",
    VDNumber: "76140738",
    RegisteredPopulation: "286",
    VotingStationName: "MASEKWANE PRIMARY SCHOOL",
    Address: "PJAPJAMELA  PJAPJAMELA VILLAGE  BOLOBEDU",
    Latitude: "-23.6349",
    Longitude: "30.4139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14272",
    WardLookupId: "1811",
    VDNumber: "76280104",
    RegisteredPopulation: "1333",
    VotingStationName: "MAVABASA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MAMITWA  MOPANI",
    Latitude: "-23.6853",
    Longitude: "30.4544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14273",
    WardLookupId: "1811",
    VDNumber: "76280317",
    RegisteredPopulation: "1315",
    VotingStationName: "XIBUKULANA SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MAMITWA  MOPANI",
    Latitude: "-23.6605",
    Longitude: "30.4484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14274",
    WardLookupId: "1811",
    VDNumber: "76280340",
    RegisteredPopulation: "1214",
    VotingStationName: "SEVENGWANA SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MEADOWBANK  MOPANI",
    Latitude: "-23.6742",
    Longitude: "30.4282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14275",
    WardLookupId: "1811",
    VDNumber: "76280362",
    RegisteredPopulation: "1283",
    VotingStationName: "MAVELE SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MEADOWBANK  MOPANI",
    Latitude: "-23.675",
    Longitude: "30.4187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14276",
    WardLookupId: "1812",
    VDNumber: "76140435",
    RegisteredPopulation: "746",
    VotingStationName: "MADUMANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BOLEBEDU  MOPANI",
    Latitude: "-23.6283",
    Longitude: "30.3983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14277",
    WardLookupId: "1812",
    VDNumber: "76140558",
    RegisteredPopulation: "340",
    VotingStationName: "KHEOPENE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  KHEOPENG VILLAGE  MOPANI",
    Latitude: "-23.639",
    Longitude: "30.3608",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14278",
    WardLookupId: "1812",
    VDNumber: "76140592",
    RegisteredPopulation: "1080",
    VotingStationName: "MORUTJI PRIMARY SCHOOL",
    Address: "MORUTSI VILLAGE  BOLEBEDU  TZANEEN",
    Latitude: "-23.6637",
    Longitude: "30.3618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14279",
    WardLookupId: "1812",
    VDNumber: "76140705",
    RegisteredPopulation: "842",
    VotingStationName: "MATSEKE HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BOLEBEDU  MOPANI",
    Latitude: "-23.6763",
    Longitude: "30.3605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14280",
    WardLookupId: "1812",
    VDNumber: "76140727",
    RegisteredPopulation: "1286",
    VotingStationName: "MOTHOMENG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BOLEBEDU  MOPANI",
    Latitude: "-23.6432",
    Longitude: "30.3839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14281",
    WardLookupId: "1812",
    VDNumber: "76140929",
    RegisteredPopulation: "779",
    VotingStationName: "BOTLUDI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BOLEBEDU  MOPANI",
    Latitude: "-23.6513",
    Longitude: "30.3821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14282",
    WardLookupId: "1812",
    VDNumber: "76140996",
    RegisteredPopulation: "421",
    VotingStationName: "TUMEDI PRIMARY SCHOOL",
    Address: "MOROATSHEHLA  TUMEDI  BOLOBEDU SOUTH",
    Latitude: "-23.6195",
    Longitude: "30.3788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14283",
    WardLookupId: "1813",
    VDNumber: "76140075",
    RegisteredPopulation: "697",
    VotingStationName: "SEMARELA SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BOLEBEDU  MOPONI",
    Latitude: "-23.6548",
    Longitude: "30.3622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14284",
    WardLookupId: "1813",
    VDNumber: "76140604",
    RegisteredPopulation: "1887",
    VotingStationName: "RELELA COMMUNITY HALL",
    Address: "NO STREET ADDRESS AVAILABLE  BOLEBEDU  MOPANI",
    Latitude: "-23.6758",
    Longitude: "30.3327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14285",
    WardLookupId: "1813",
    VDNumber: "76140637",
    RegisteredPopulation: "596",
    VotingStationName: "SETHONG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BOLEBEDU  MOPANI",
    Latitude: "-23.6726",
    Longitude: "30.3445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14286",
    WardLookupId: "1813",
    VDNumber: "76140660",
    RegisteredPopulation: "367",
    VotingStationName: "SETHONG  PRE-SCHOOL",
    Address: "SETHONG VILLAGE  BOLOBEDU  MOPANI",
    Latitude: "-23.6693",
    Longitude: "30.3501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14287",
    WardLookupId: "1813",
    VDNumber: "76140839",
    RegisteredPopulation: "920",
    VotingStationName: "MABJE-A-KGORO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  RELELA VILLAGE,BOLOBEDU  MOPANI",
    Latitude: "-23.6699",
    Longitude: "30.3244",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14288",
    WardLookupId: "1813",
    VDNumber: "76141009",
    RegisteredPopulation: "188",
    VotingStationName: "MATOKANE SECONDARY SCHOOL",
    Address: "RELELA  BOLOBEDU SOUTH  TZANEEN",
    Latitude: "-23.6726",
    Longitude: "30.3337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14289",
    WardLookupId: "1813",
    VDNumber: "76141021",
    RegisteredPopulation: "392",
    VotingStationName: "RELELA ASSEMBLIES OF GOD",
    Address: "RELELA VILLAGE  MODJADJI  GREATER TZANEEN",
    Latitude: "-23.6776",
    Longitude: "30.3216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14290",
    WardLookupId: "1813",
    VDNumber: "76141054",
    RegisteredPopulation: "225",
    VotingStationName: "SETHEENI PRE-SCHOOL",
    Address: "SETHEENI  BOLOBEDU  SEMARELA",
    Latitude: "-23.6555",
    Longitude: "30.3479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14291",
    WardLookupId: "1814",
    VDNumber: "76140547",
    RegisteredPopulation: "545",
    VotingStationName: "SEFOLWE PRIMARY SCHOOL",
    Address: "MODJADJI ROAD  SEFOLWE VILLAGE  BOLOBEDU",
    Latitude: "-23.6508",
    Longitude: "30.3105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14292",
    WardLookupId: "1814",
    VDNumber: "76140569",
    RegisteredPopulation: "2125",
    VotingStationName: "TLHAPEDI PRIMARY SCHOOL",
    Address: "MOPYE  BOLEBEDU  TZANEEN",
    Latitude: "-23.6814",
    Longitude: "30.2851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14293",
    WardLookupId: "1814",
    VDNumber: "76140570",
    RegisteredPopulation: "918",
    VotingStationName: "KELEKESHE HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  JOKONG VILLAGE  MOPANI",
    Latitude: "-23.6683",
    Longitude: "30.3017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14294",
    WardLookupId: "1814",
    VDNumber: "76140648",
    RegisteredPopulation: "1188",
    VotingStationName: "KGWEKGWE SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BOLEBEDU  MOPANI",
    Latitude: "-23.68",
    Longitude: "30.3128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14295",
    WardLookupId: "1814",
    VDNumber: "76141010",
    RegisteredPopulation: "595",
    VotingStationName: "MOLEKETLA PRIMARY SCHOOL",
    Address: "MOTUPA  BOLOBEDU  BOLOBEDU",
    Latitude: "-23.6713",
    Longitude: "30.3089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14296",
    WardLookupId: "1814",
    VDNumber: "76141166",
    RegisteredPopulation: "234",
    VotingStationName: "KHEBABANE CRECHE",
    Address: "KHEBABANE  BOLOBEDU SOUTH  MODJADJI TRIBAL AUTHORITY",
    Latitude: "-23.6754",
    Longitude: "30.2753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14297",
    WardLookupId: "1814",
    VDNumber: "76141177",
    RegisteredPopulation: "293",
    VotingStationName: "MOHLATLEGO MACHABA SECONDRY SCHOOL",
    Address: "MARIRONI  BOLOBEDU SOUTH  MODJADJI TRIBAL AUTHORITY",
    Latitude: "-23.6843",
    Longitude: "30.3036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14298",
    WardLookupId: "1815",
    VDNumber: "76140581",
    RegisteredPopulation: "2494",
    VotingStationName: "MOTUPA KGOMO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BOLOBEDU  MOPANI",
    Latitude: "-23.6831",
    Longitude: "30.2953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14299",
    WardLookupId: "1815",
    VDNumber: "76140615",
    RegisteredPopulation: "1501",
    VotingStationName: "MOKUTUPI PRIMARY SCHOOL",
    Address: "BOLEBEDU  TZANEEN",
    Latitude: "-23.6898",
    Longitude: "30.3125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14300",
    WardLookupId: "1815",
    VDNumber: "76140941",
    RegisteredPopulation: "1155",
    VotingStationName: "PHOLO-YA-HLABA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  KUBJANA VILLAGE  MOPANI",
    Latitude: "-23.691",
    Longitude: "30.2984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14301",
    WardLookupId: "1816",
    VDNumber: "76140626",
    RegisteredPopulation: "689",
    VotingStationName: "MAPITLULA PRIMARY SCHOOL",
    Address: "BOLEBEDU  TZANEEN",
    Latitude: "-23.6951",
    Longitude: "30.3224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14302",
    WardLookupId: "1816",
    VDNumber: "76140659",
    RegisteredPopulation: "726",
    VotingStationName: "BOKE HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BOLEBEDU  MOPANI",
    Latitude: "-23.6899",
    Longitude: "30.3325",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14303",
    WardLookupId: "1816",
    VDNumber: "76140671",
    RegisteredPopulation: "483",
    VotingStationName: "MATSATSI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  BOLEBEDU  MOPANI",
    Latitude: "-23.6892",
    Longitude: "30.3688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14304",
    WardLookupId: "1816",
    VDNumber: "76140682",
    RegisteredPopulation: "838",
    VotingStationName: "THAPANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BOLEBEDU  MOPANI",
    Latitude: "-23.7036",
    Longitude: "30.3279",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14305",
    WardLookupId: "1816",
    VDNumber: "76140693",
    RegisteredPopulation: "742",
    VotingStationName: "MALOTI SECONDARY SCHOOL",
    Address: "LEREJENG  BOLEBEDU  TZANEEN",
    Latitude: "-23.6921",
    Longitude: "30.3509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14306",
    WardLookupId: "1816",
    VDNumber: "76140930",
    RegisteredPopulation: "947",
    VotingStationName: "SARA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BOLEBEDU  MOPANI",
    Latitude: "-23.7006",
    Longitude: "30.3375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14307",
    WardLookupId: "1816",
    VDNumber: "76280508",
    RegisteredPopulation: "923",
    VotingStationName: "MAHLEBEZULU PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BABANANA VILLAGE  MOPANI",
    Latitude: "-23.7094",
    Longitude: "30.3969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14308",
    WardLookupId: "1817",
    VDNumber: "76280485",
    RegisteredPopulation: "1809",
    VotingStationName: "NHLENGELETI PRIMARY SCHOOL",
    Address:
      "NO STREET ADDRESS AVAILABLE  NWAMITWA  NO STREET ADDRESS AVAILABLE",
    Latitude: "-23.7446",
    Longitude: "30.405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14309",
    WardLookupId: "1817",
    VDNumber: "76280496",
    RegisteredPopulation: "1319",
    VotingStationName: "FOFOZA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MAMITWA  MOPANI",
    Latitude: "-23.7298",
    Longitude: "30.4049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14310",
    WardLookupId: "1817",
    VDNumber: "76280632",
    RegisteredPopulation: "1112",
    VotingStationName: "NWAJAHENI HIGHER PRIMARY SCHOOL",
    Address:
      "NO STREET ADDRESS AVAILABLE  RWANDA/NWAMITWA  GREATER TZANEEN [TZANEEN]",
    Latitude: "-23.7498",
    Longitude: "30.4119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14311",
    WardLookupId: "1817",
    VDNumber: "76280700",
    RegisteredPopulation: "591",
    VotingStationName: "SHONGANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVALAIBLE  NWAMITWA  MOPANI",
    Latitude: "-23.7192",
    Longitude: "30.4166",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14312",
    WardLookupId: "1817",
    VDNumber: "76280711",
    RegisteredPopulation: "290",
    VotingStationName: "LWANDLAMUNI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVALAIBLE  MOPANI/GA RISAVA  TZANEEN  ",
    Latitude: "-23.7252",
    Longitude: "30.3957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14313",
    WardLookupId: "1817",
    VDNumber: "76280801",
    RegisteredPopulation: "205",
    VotingStationName: "NWAMITWA TRIBAL OFFICE",
    Address: "NWAMITWA  ",
    Latitude: "-23.7342",
    Longitude: "30.4065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14314",
    WardLookupId: "1818",
    VDNumber: "76110027",
    RegisteredPopulation: "620",
    VotingStationName: "SILWERSEE SCHOOL",
    Address: "BRODERSTROOMDRIFT  TZANEEN  MOPANI",
    Latitude: "-23.7689",
    Longitude: "30.2281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14315",
    WardLookupId: "1818",
    VDNumber: "76280069",
    RegisteredPopulation: "441",
    VotingStationName: "TARENTAAL CONFERENCE CENTRE",
    Address: "R71 PHALABORWA ROAD  TARENTAALRAND 524 LT  MOPANI",
    Latitude: "-23.8194",
    Longitude: "30.3041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14316",
    WardLookupId: "1818",
    VDNumber: "76280463",
    RegisteredPopulation: "1450",
    VotingStationName: "MBHEKWANA SENIOR SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MAMITWA  MOPANI",
    Latitude: "-23.7333",
    Longitude: "30.384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14317",
    WardLookupId: "1818",
    VDNumber: "76280474",
    RegisteredPopulation: "1677",
    VotingStationName: "FAVASI PRIMARY SCHOOL",
    Address: "MANDIHAKAZI  MAMITWA  TZENEEN",
    Latitude: "-23.7376",
    Longitude: "30.3707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14318",
    WardLookupId: "1818",
    VDNumber: "76280654",
    RegisteredPopulation: "154",
    VotingStationName: "RIVERSIDE SCHOOL",
    Address: "TZANEEN  LETSITELE  GREATER TZANEEN [TZANEEN]",
    Latitude: "-23.7941",
    Longitude: "30.4424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14319",
    WardLookupId: "1818",
    VDNumber: "76280812",
    RegisteredPopulation: "554",
    VotingStationName: "TSAKANI PRIMARY SCHOOL",
    Address: "TSAKANI PRIMARY SCHOOL  MANDLAGAZI  NWAMITWA",
    Latitude: "-23.7351",
    Longitude: "30.3591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14320",
    WardLookupId: "1819",
    VDNumber: "76110173",
    RegisteredPopulation: "2814",
    VotingStationName: "TZANEEN CATHOLIC CHURCH",
    Address: "241 VOORTREKER STREET  AQUA PARK  TZANEEN",
    Latitude: "-23.8195",
    Longitude: "30.1574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14321",
    WardLookupId: "1819",
    VDNumber: "76110218",
    RegisteredPopulation: "582",
    VotingStationName: "MANORVLEI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVALAIBLE  TZANEEN  MOPANI ",
    Latitude: "-23.8168",
    Longitude: "30.1708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14322",
    WardLookupId: "1819",
    VDNumber: "76240010",
    RegisteredPopulation: "780",
    VotingStationName: "THUSANANG ASSOCIATION HALL",
    Address: "PLOT 35 TROUTWATERSHOLDING G5  MAGOEBASKLOOF  TZANEEN",
    Latitude: "-23.9026",
    Longitude: "29.9943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14323",
    WardLookupId: "1819",
    VDNumber: "76240043",
    RegisteredPopulation: "596",
    VotingStationName: "NOORDE WENKE HALL",
    Address: "NORTHERN TIMBER  MAIN ROAD  POLITSI  TZANEEN",
    Latitude: "-23.7824",
    Longitude: "30.0996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14324",
    WardLookupId: "1819",
    VDNumber: "76240414",
    RegisteredPopulation: "261",
    VotingStationName: "TSANENG( WESTFALIA) PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  WESTFALIA  MOPANI",
    Latitude: "-23.7432",
    Longitude: "30.1168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14325",
    WardLookupId: "1819",
    VDNumber: "76240425",
    RegisteredPopulation: "709",
    VotingStationName: "POLITSI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  POLITSI  MOPANI",
    Latitude: "-23.7675",
    Longitude: "30.1017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14326",
    WardLookupId: "1819",
    VDNumber: "76240436",
    RegisteredPopulation: "560",
    VotingStationName: "DRIEHOEK PRIMARY SCHOOL",
    Address: "NO STREET  MOPANI  HAENETZBURG",
    Latitude: "-23.9194",
    Longitude: "29.8827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14327",
    WardLookupId: "1819",
    VDNumber: "76330335",
    RegisteredPopulation: "285",
    VotingStationName: "VREEDZAAM FARM",
    Address: "MOOKETSI  ",
    Latitude: "-23.6837",
    Longitude: "29.9967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14328",
    WardLookupId: "1820",
    VDNumber: "76110016",
    RegisteredPopulation: "1698",
    VotingStationName: "HOERSKOOL BEN VORSTER",
    Address: "2E BOUNDARY STREET  TZANEEN  GREATER TZANEEN",
    Latitude: "-23.8372",
    Longitude: "30.1528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14329",
    WardLookupId: "1820",
    VDNumber: "76110140",
    RegisteredPopulation: "2323",
    VotingStationName: "TZANEEN PRIMARY SCHOOL",
    Address: "AGATHA STREET  TZANEEN  MOPANI",
    Latitude: "-23.8314",
    Longitude: "30.1557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14330",
    WardLookupId: "1820",
    VDNumber: "76110207",
    RegisteredPopulation: "1105",
    VotingStationName: "TZANEEN EMMANUEL ASSEMBLY",
    Address: "PERTUNIA STREET  MOPANI/FLORA PARK  TZANEEN  ",
    Latitude: "-23.8462",
    Longitude: "30.1632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14331",
    WardLookupId: "1820",
    VDNumber: "76110229",
    RegisteredPopulation: "604",
    VotingStationName: "TALANA HALL",
    Address: "TALANA  TZANEEN  TZANEEN",
    Latitude: "-23.8295",
    Longitude: "30.1775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14332",
    WardLookupId: "1821",
    VDNumber: "76110094",
    RegisteredPopulation: "2574",
    VotingStationName: "KHUJWANA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  KHUJWANA  MOPANI",
    Latitude: "-23.9321",
    Longitude: "30.2289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14333",
    WardLookupId: "1821",
    VDNumber: "76240021",
    RegisteredPopulation: "216",
    VotingStationName: "LEPELLE NORTHERN WATER BOARD HALL",
    Address: "R528 GEORGES VALLEY  HAENERTSBURG  MOPANI",
    Latitude: "-23.9404",
    Longitude: "29.9825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14334",
    WardLookupId: "1821",
    VDNumber: "76240065",
    RegisteredPopulation: "702",
    VotingStationName: "ALLESBESTE DAYCARE",
    Address: "ALLESBESTE FARM  GREATER TZANEEN   MAGOEBASKLOOF",
    Latitude: "-23.8045",
    Longitude: "30.12",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14335",
    WardLookupId: "1821",
    VDNumber: "76240087",
    RegisteredPopulation: "515",
    VotingStationName: "GRYSAPPEL PRIMARY SCHOOL",
    Address: "R528 GEORGES VALLEY  ROUMAN VILLAGE  MOPANI",
    Latitude: "-23.8852",
    Longitude: "30.0738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14336",
    WardLookupId: "1821",
    VDNumber: "76240100",
    RegisteredPopulation: "1271",
    VotingStationName: "THABENG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  LENYENYE  MOPANI",
    Latitude: "-23.9497",
    Longitude: "30.2205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14337",
    WardLookupId: "1821",
    VDNumber: "76240447",
    RegisteredPopulation: "412",
    VotingStationName: "HAENERTSBURG VILLAGE HALL",
    Address: "NO STREET ADDRESS AVAILABLE  HAENETSBURG  TZANEEN",
    Latitude: "-23.9426",
    Longitude: "29.9392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14338",
    WardLookupId: "1822",
    VDNumber: "76110061",
    RegisteredPopulation: "1238",
    VotingStationName: "NKOWANKOWA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  NKOWANKOWA  MOPANI",
    Latitude: "-23.8911",
    Longitude: "30.2796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14339",
    WardLookupId: "1822",
    VDNumber: "76110195",
    RegisteredPopulation: "85",
    VotingStationName: "VONA MATI FARM",
    Address: "BONNIE BRAE COUNTRY CLUB  GREATER TZANEEN   VONA MATI",
    Latitude: "-23.8339",
    Longitude: "30.2196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14340",
    WardLookupId: "1822",
    VDNumber: "76240076",
    RegisteredPopulation: "1590",
    VotingStationName: "CHARLES MATHONSI SEC SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  GA-MOKGOLOBOTO  MOPANI",
    Latitude: "-23.9006",
    Longitude: "30.2569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14341",
    WardLookupId: "1822",
    VDNumber: "76280351",
    RegisteredPopulation: "475",
    VotingStationName: "LETABA LANDGOED PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  LETABA ESTATES  MOPANI",
    Latitude: "-23.8567",
    Longitude: "30.3079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14342",
    WardLookupId: "1822",
    VDNumber: "76280531",
    RegisteredPopulation: "1947",
    VotingStationName: "SEBONE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MOKGOLOBOTO VILLAGE  MOPANI",
    Latitude: "-23.8847",
    Longitude: "30.25",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14343",
    WardLookupId: "1823",
    VDNumber: "76110083",
    RegisteredPopulation: "2572",
    VotingStationName: "DAN PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  DAN  MOPANI",
    Latitude: "-23.9112",
    Longitude: "30.2672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14344",
    WardLookupId: "1823",
    VDNumber: "76110106",
    RegisteredPopulation: "1484",
    VotingStationName: "MARGARET SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MOHLABA CROSS  MOPANI",
    Latitude: "-23.9421",
    Longitude: "30.2708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14345",
    WardLookupId: "1823",
    VDNumber: "76110162",
    RegisteredPopulation: "1638",
    VotingStationName: "MATIMU HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  KHUJWANA  MOPANI",
    Latitude: "-23.9263",
    Longitude: "30.2419",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14346",
    WardLookupId: "1824",
    VDNumber: "76110038",
    RegisteredPopulation: "2034",
    VotingStationName: "BANKUNA HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  NKOWANKOWA  MOPANI",
    Latitude: "-23.8887",
    Longitude: "30.2954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14347",
    WardLookupId: "1824",
    VDNumber: "76110128",
    RegisteredPopulation: "1709",
    VotingStationName: "COMMUNITY HALL",
    Address: "NO STREET ADDRESS AVAILABLE  NKOWANKOWA TOWNSHIP  MOPANI",
    Latitude: "-23.8879",
    Longitude: "30.2847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14348",
    WardLookupId: "1824",
    VDNumber: "76110241",
    RegisteredPopulation: "438",
    VotingStationName: "RITAVI SENIOR PRIMARY SCHOOL",
    Address: "RITAVI PRIMARY SCHOOL  NKOWANKOWA  NKOWANKOWA",
    Latitude: "-23.8855",
    Longitude: "30.2886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14349",
    WardLookupId: "1824",
    VDNumber: "76110252",
    RegisteredPopulation: "876",
    VotingStationName: "DZJ HIGH SCHOOL",
    Address: "76110128  TZANEEN",
    Latitude: "-23.8859",
    Longitude: "30.2984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14350",
    WardLookupId: "1825",
    VDNumber: "76110139",
    RegisteredPopulation: "607",
    VotingStationName: "TIVUMBENI MULTIPURPOSE CENTRE",
    Address: "NO STREET ADDRESS AVAILBLE  NKOWANKOWA  MOPANI",
    Latitude: "-23.8922",
    Longitude: "30.2707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14351",
    WardLookupId: "1825",
    VDNumber: "76110151",
    RegisteredPopulation: "2243",
    VotingStationName: "BANANA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  DAN  MOPANI",
    Latitude: "-23.8997",
    Longitude: "30.276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14352",
    WardLookupId: "1825",
    VDNumber: "76110263",
    RegisteredPopulation: "626",
    VotingStationName: "MAGOZA SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  DAN VILLAGE  MHLABA",
    Latitude: "-23.9016",
    Longitude: "30.2754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14353",
    WardLookupId: "1825",
    VDNumber: "76240379",
    RegisteredPopulation: "1103",
    VotingStationName: "MAVUMBHA  SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  DAN VILLAGE  MOPANI",
    Latitude: "-23.9054",
    Longitude: "30.2709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14354",
    WardLookupId: "1826",
    VDNumber: "76110072",
    RegisteredPopulation: "3467",
    VotingStationName: "BOMBELENI SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  NKOWANKOWA  MOPANI",
    Latitude: "-23.8889",
    Longitude: "30.3091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14355",
    WardLookupId: "1826",
    VDNumber: "76110274",
    RegisteredPopulation: "1128",
    VotingStationName: "MASUNGULO PRIMARY SCHOOL",
    Address: "DAN VILLAGE  NKOWANKOWA  MHLABA",
    Latitude: "-23.8895",
    Longitude: "30.322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14356",
    WardLookupId: "1827",
    VDNumber: "76240199",
    RegisteredPopulation: "1067",
    VotingStationName: "RITA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  LENYENYE  MOPANI",
    Latitude: "-23.9779",
    Longitude: "30.2935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14357",
    WardLookupId: "1827",
    VDNumber: "76280227",
    RegisteredPopulation: "859",
    VotingStationName: "MHANGWENI PRIMARY SCHOOL",
    Address: "MHANGWENIE  LETSITELE",
    Latitude: "-23.977",
    Longitude: "30.3206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14358",
    WardLookupId: "1827",
    VDNumber: "76280250",
    RegisteredPopulation: "979",
    VotingStationName: "MAFARANA COMBINED PRIMARY SCHOOL",
    Address: "MAFARANA  MAFARANA VILLAGE  TZANEEN",
    Latitude: "-23.9622",
    Longitude: "30.3503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14359",
    WardLookupId: "1827",
    VDNumber: "76280294",
    RegisteredPopulation: "605",
    VotingStationName: "LEFARA SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  LEFARA  MOPANI",
    Latitude: "-23.9485",
    Longitude: "30.3058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14360",
    WardLookupId: "1827",
    VDNumber: "76280520",
    RegisteredPopulation: "695",
    VotingStationName: "KHOPHO PRIMARY SCHOOL",
    Address: "RITA  KHOPO VILLAGE  NAPHUNO",
    Latitude: "-23.9721",
    Longitude: "30.2873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14361",
    WardLookupId: "1827",
    VDNumber: "76280845",
    RegisteredPopulation: "805",
    VotingStationName: "THE HOLY GALILEA APOSTOLIC CHURCH IN ZION",
    Address: "MSHENGUVILLE  RITA  BAKGAGA BA-MAAKE TRIBAL AUTHORITY",
    Latitude: "-23.9762",
    Longitude: "30.3",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14362",
    WardLookupId: "1828",
    VDNumber: "76280407",
    RegisteredPopulation: "907",
    VotingStationName: "LAERSKOOL DR. ANNECKE SCHOOL",
    Address: "BOTHA STREET  LETSITELE  MOPANI",
    Latitude: "-23.8911",
    Longitude: "30.3899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14363",
    WardLookupId: "1828",
    VDNumber: "76280430",
    RegisteredPopulation: "1840",
    VotingStationName: "TITO MBOWENI SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  NWAMITWA  MOPANI",
    Latitude: "-23.8893",
    Longitude: "30.3459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14364",
    WardLookupId: "1828",
    VDNumber: "76280452",
    RegisteredPopulation: "1270",
    VotingStationName: "MARIVENI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  NKOWANKOWA  MOPANI",
    Latitude: "-23.8925",
    Longitude: "30.3482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14365",
    WardLookupId: "1828",
    VDNumber: "76280665",
    RegisteredPopulation: "283",
    VotingStationName: "LEONORA PRIMARY SCHOOL",
    Address: "EILAND ROAD  MOPANI  LETSITELE",
    Latitude: "-23.8459",
    Longitude: "30.4146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14366",
    WardLookupId: "1828",
    VDNumber: "76280890",
    RegisteredPopulation: "110",
    VotingStationName: "THE JUNCTION FARM SCHOOL",
    Address: "MAHELA  LETSITELE  MAHAHELA",
    Latitude: "-23.8666",
    Longitude: "30.3801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14367",
    WardLookupId: "1828",
    VDNumber: "76280924",
    RegisteredPopulation: "262",
    VotingStationName: "SHIPUNGU HIGH SCHOOL",
    Address: "ZANGHOMA VILLAGE  LETSITELE  NKUNA TRIBAL AUTHORITY",
    Latitude: "-23.9012",
    Longitude: "30.3479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14368",
    WardLookupId: "1829",
    VDNumber: "76280272",
    RegisteredPopulation: "1129",
    VotingStationName: "MUHLABA TRIBAL OFFICE",
    Address: "NO STREET ADDRESS AVAILABLE  MUHLABA  MOPANI",
    Latitude: "-23.9335",
    Longitude: "30.2905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14369",
    WardLookupId: "1829",
    VDNumber: "76280283",
    RegisteredPopulation: "1018",
    VotingStationName: "TOTWANA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  ZANGHOMA  MOPANI",
    Latitude: "-23.9259",
    Longitude: "30.3368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14370",
    WardLookupId: "1829",
    VDNumber: "76280441",
    RegisteredPopulation: "1694",
    VotingStationName: "PETANENGE JUNIOR SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  PETANENGE VILLAGE  MOPANI",
    Latitude: "-23.9033",
    Longitude: "30.3112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14371",
    WardLookupId: "1829",
    VDNumber: "76280823",
    RegisteredPopulation: "1287",
    VotingStationName: "TINGHITSI LOWER PRIMARY SCHOOL",
    Address: "SASEKANI VILLAGE  PETANENGE  MHLABA",
    Latitude: "-23.9419",
    Longitude: "30.2827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14372",
    WardLookupId: "1829",
    VDNumber: "76280834",
    RegisteredPopulation: "554",
    VotingStationName: "MARITO PRIMARY SCHOOL",
    Address: "PETANENGE  MHLABA",
    Latitude: "-23.9074",
    Longitude: "30.3003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14373",
    WardLookupId: "1830",
    VDNumber: "76280238",
    RegisteredPopulation: "1185",
    VotingStationName: "PROFESSOR HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  NTSAKO VILLAGE  MOPANI",
    Latitude: "-23.9706",
    Longitude: "30.3919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14374",
    WardLookupId: "1830",
    VDNumber: "76280249",
    RegisteredPopulation: "1046",
    VotingStationName: "MBETANA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  SEDAN VILLAGE  MOPANI",
    Latitude: "-23.9723",
    Longitude: "30.4269",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14375",
    WardLookupId: "1830",
    VDNumber: "76280261",
    RegisteredPopulation: "674",
    VotingStationName: "JACK MASHAMBA PRIMARY SCHOOL",
    Address: "MULATI VILLAGE  TZANEEN",
    Latitude: "-23.9473",
    Longitude: "30.3971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14376",
    WardLookupId: "1830",
    VDNumber: "76280609",
    RegisteredPopulation: "467",
    VotingStationName: "SCOTCH MABOKO SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MULATI VILLAGE  MOPANI",
    Latitude: "-23.9456",
    Longitude: "30.4059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14377",
    WardLookupId: "1830",
    VDNumber: "76280610",
    RegisteredPopulation: "437",
    VotingStationName: "MLUNGHISI COMBINED SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MULATI VILLAGE  MOPANI",
    Latitude: "-23.9444",
    Longitude: "30.4162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14378",
    WardLookupId: "1830",
    VDNumber: "76280621",
    RegisteredPopulation: "609",
    VotingStationName: "NYANTSHIRI PRIMARY SCHOOL",
    Address: "NTSAKO VILLAGE  BONN  GREATER TZANEEN [TZANEEN]",
    Latitude: "-23.9598",
    Longitude: "30.3804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14379",
    WardLookupId: "1830",
    VDNumber: "76280744",
    RegisteredPopulation: "779",
    VotingStationName: "RINDZANI CRECHE",
    Address: "NO STREET ADDRESS AVALAIBLE  MOPANI/MAFARANI  TZANEEN  ",
    Latitude: "-23.9609",
    Longitude: "30.3692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14380",
    WardLookupId: "1830",
    VDNumber: "76280902",
    RegisteredPopulation: "466",
    VotingStationName: "MBANGWA PRIMARY SCHOOL",
    Address: "BONN  ",
    Latitude: "-23.9783",
    Longitude: "30.3927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14381",
    WardLookupId: "1831",
    VDNumber: "76240289",
    RegisteredPopulation: "774",
    VotingStationName: "MASHILOANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  LENYENYE  MOPANI",
    Latitude: "-24.1014",
    Longitude: "30.2739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14382",
    WardLookupId: "1831",
    VDNumber: "76240526",
    RegisteredPopulation: "388",
    VotingStationName: "MOTHAPOLO CRECHE",
    Address: "HWEETJI  MASHILOANE  MASHILOANE",
    Latitude: "-24.0902",
    Longitude: "30.2931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14383",
    WardLookupId: "1831",
    VDNumber: "76260337",
    RegisteredPopulation: "313",
    VotingStationName: "ALLERGRAINE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BOURDEOUX VILLAGE  MOPANI",
    Latitude: "-24.072",
    Longitude: "30.3604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14384",
    WardLookupId: "1831",
    VDNumber: "76280160",
    RegisteredPopulation: "878",
    VotingStationName: "HOVHENI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  JULESBURG  MOPANI",
    Latitude: "-24.1",
    Longitude: "30.3321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14385",
    WardLookupId: "1831",
    VDNumber: "76280171",
    RegisteredPopulation: "1418",
    VotingStationName: "DUMELA HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  JULESBURG  MOPANI",
    Latitude: "-24.068",
    Longitude: "30.3289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14386",
    WardLookupId: "1831",
    VDNumber: "76280182",
    RegisteredPopulation: "587",
    VotingStationName: "MAGEZA SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  SOLANI VILLAGE  MOPANI",
    Latitude: "-24.0577",
    Longitude: "30.3172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14387",
    WardLookupId: "1831",
    VDNumber: "76280913",
    RegisteredPopulation: "368",
    VotingStationName: "HOXANI CRECHE",
    Address: "NYANYUKANI  MAGEZA  NYANYUKANI",
    Latitude: "-24.0567",
    Longitude: "30.3207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14388",
    WardLookupId: "1832",
    VDNumber: "76240256",
    RegisteredPopulation: "2058",
    VotingStationName: "BOKGAGA HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  LENYENYE  MOPANI",
    Latitude: "-24.0351",
    Longitude: "30.2746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14389",
    WardLookupId: "1832",
    VDNumber: "76240278",
    RegisteredPopulation: "2288",
    VotingStationName: "MOGAPENG LOWER PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MOGAPENG VILLAGE  MOPANI",
    Latitude: "-24.0654",
    Longitude: "30.2834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14390",
    WardLookupId: "1832",
    VDNumber: "76240481",
    RegisteredPopulation: "1262",
    VotingStationName: "MATSHELAPATA MUSLIM CHURCH",
    Address: "MATSHELAPATA  BOKGAGA  BOKGAGA",
    Latitude: "-24.031",
    Longitude: "30.2812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14391",
    WardLookupId: "1833",
    VDNumber: "76240267",
    RegisteredPopulation: "1854",
    VotingStationName: "PHEPENE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  LENYENYE  MOPANI",
    Latitude: "-24.0464",
    Longitude: "30.3144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14392",
    WardLookupId: "1833",
    VDNumber: "76240346",
    RegisteredPopulation: "604",
    VotingStationName: "BURGHERSDORP SECONDARY SCHOOL",
    Address: "BURGHERSDORP   GREATER TZANEEN  SHILUVANE",
    Latitude: "-24.0141",
    Longitude: "30.3068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14393",
    WardLookupId: "1833",
    VDNumber: "76240458",
    RegisteredPopulation: "704",
    VotingStationName: "MAPONYE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MOGAPENG VILLAGE  MOPANI",
    Latitude: "-24.0572",
    Longitude: "30.3007",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14394",
    WardLookupId: "1833",
    VDNumber: "76280193",
    RegisteredPopulation: "1785",
    VotingStationName: "BURGHERSDORP CATHOLIC CHURCH",
    Address: "BURGHERSDORP VILLAGE  ",
    Latitude: "-23.9972",
    Longitude: "30.3163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14395",
    WardLookupId: "1833",
    VDNumber: "76280216",
    RegisteredPopulation: "915",
    VotingStationName: "PHANGASASA HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  GAVAZA VILLAGE  MOPANI",
    Latitude: "-23.9896",
    Longitude: "30.3342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14396",
    WardLookupId: "1834",
    VDNumber: "76240357",
    RegisteredPopulation: "974",
    VotingStationName: "MYAKAYAKA SECONDARY SCHOOL",
    Address: "MYAKAYAKA  MYAKAYAKA VILLAGE  TZANEEN",
    Latitude: "-24.0094",
    Longitude: "30.2811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14397",
    WardLookupId: "1834",
    VDNumber: "76240368",
    RegisteredPopulation: "2069",
    VotingStationName: "NAPSCOM SECONDARY SCHOOL",
    Address: "TICKEYLINE  RAMALEMA VILLAGE  NAPHUNO",
    Latitude: "-23.9847",
    Longitude: "30.2909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14398",
    WardLookupId: "1834",
    VDNumber: "76240492",
    RegisteredPopulation: "403",
    VotingStationName: "ITIRELENG EDUCARE CENTRE",
    Address: "ITIRELENG DAY CARE CENTRE  BOKGAGA  BOKGAGA",
    Latitude: "-24.001",
    Longitude: "30.2783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14399",
    WardLookupId: "1834",
    VDNumber: "76280205",
    RegisteredPopulation: "1644",
    VotingStationName: "NTWANANO J/P SCHOOL",
    Address: "BURGERSDORP VILLAGE  BURGERSDORP  TZANEEN",
    Latitude: "-24.0102",
    Longitude: "30.3066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14400",
    WardLookupId: "1835",
    VDNumber: "76240177",
    RegisteredPopulation: "1087",
    VotingStationName: "NELSON RAMODIKE SECONDARY SCHOOL",
    Address: "NAVANE  LENYENYE  TZANEEN",
    Latitude: "-23.9824",
    Longitude: "30.2761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14401",
    WardLookupId: "1835",
    VDNumber: "76240201",
    RegisteredPopulation: "1966",
    VotingStationName: "MONTSHENG LOWER/HIGHER PRIMARY SCHOOL",
    Address: "MONTSHENG VILLAGE  LENYENYE  MOPANI",
    Latitude: "-23.9928",
    Longitude: "30.2832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14402",
    WardLookupId: "1835",
    VDNumber: "76240212",
    RegisteredPopulation: "1338",
    VotingStationName: "MMAPHAI L/H RIMARY SCHOOL",
    Address: "RAMALEMA VILLAGE  LENYENYE  TZANEEN",
    Latitude: "-23.9999",
    Longitude: "30.2735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14403",
    WardLookupId: "1836",
    VDNumber: "76110049",
    RegisteredPopulation: "1934",
    VotingStationName: "SEBOYE HIGHER SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  LENYENYE  MOPONI",
    Latitude: "-23.9757",
    Longitude: "30.2637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14404",
    WardLookupId: "1836",
    VDNumber: "76110050",
    RegisteredPopulation: "2326",
    VotingStationName: "LENYENYE SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  LENYENYE  MOPANI",
    Latitude: "-23.9683",
    Longitude: "30.2705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14405",
    WardLookupId: "1836",
    VDNumber: "76110184",
    RegisteredPopulation: "1182",
    VotingStationName: "KGAHARA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  LENYENYE TOWNSHIP  MOPANI",
    Latitude: "-23.9712",
    Longitude: "30.273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14406",
    WardLookupId: "1836",
    VDNumber: "76110285",
    RegisteredPopulation: "397",
    VotingStationName: "MOGOBOYA RAMODIKE PRIMARY SCHOOL",
    Address: "MABITLENG  LENYEYE  BOKGAGA",
    Latitude: "-23.9786",
    Longitude: "30.2567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14407",
    WardLookupId: "1837",
    VDNumber: "76110117",
    RegisteredPopulation: "1991",
    VotingStationName: "PONANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MOHLABA CROSS  MOPANI",
    Latitude: "-23.9532",
    Longitude: "30.2688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14408",
    WardLookupId: "1837",
    VDNumber: "76110230",
    RegisteredPopulation: "939",
    VotingStationName: "MATLHARI HIGH SCHOOL",
    Address: "MHLABA CROSS  MHLABA  MHLABA",
    Latitude: "-23.957",
    Longitude: "30.2644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14409",
    WardLookupId: "1837",
    VDNumber: "76240166",
    RegisteredPopulation: "1399",
    VotingStationName: "MOIME PRIMARY SCHOOL",
    Address: "LENYENYE  TZANEEN",
    Latitude: "-23.9704",
    Longitude: "30.241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14410",
    WardLookupId: "1838",
    VDNumber: "76240144",
    RegisteredPopulation: "1021",
    VotingStationName: "MODUPI PRIMARY SCHOOL",
    Address: "MOTHOPONG VILLAGE  LENYENYE  GA-MOGOBOYA",
    Latitude: "-24.0027",
    Longitude: "30.2205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14411",
    WardLookupId: "1838",
    VDNumber: "76240155",
    RegisteredPopulation: "635",
    VotingStationName: "MOHLODUMELA PRIMARY SCHOOL",
    Address: "LEOLO VILLAGE  GA-MOGOBOYA  LENYENYE",
    Latitude: "-24.0268",
    Longitude: "30.1896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14412",
    WardLookupId: "1838",
    VDNumber: "76240234",
    RegisteredPopulation: "1065",
    VotingStationName: "BAKGAGA BA-MAAKE TRADITIONAL AUTHORITY",
    Address: "MAAKE HEADKRAAL MOSIYE  BOKGAGA VILLAGE  TZANEEN",
    Latitude: "-24.0345",
    Longitude: "30.2351",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14413",
    WardLookupId: "1838",
    VDNumber: "76240324",
    RegisteredPopulation: "830",
    VotingStationName: "MOGOBOYA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MOSORONG  MOPANI",
    Latitude: "-24.0169",
    Longitude: "30.1914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14414",
    WardLookupId: "1838",
    VDNumber: "76240504",
    RegisteredPopulation: "728",
    VotingStationName: "MAFUTSANE SECONDARY SCHOOL",
    Address: "GA MOGOBOYA  TZANEEN  GA RAKOMA",
    Latitude: "-24.0018",
    Longitude: "30.2334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14415",
    WardLookupId: "1838",
    VDNumber: "76240548",
    RegisteredPopulation: "947",
    VotingStationName: "LEKGOLO MAAKE PRIMARY",
    Address: "NO STREET ADDRESS AVAILABLE MOIME VILLAGE  TZANEEN  LENYENYE",
    Latitude: "-23.975",
    Longitude: "30.2505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14416",
    WardLookupId: "1839",
    VDNumber: "76240111",
    RegisteredPopulation: "1344",
    VotingStationName: "MOKHAPA HIGH SCHOOL",
    Address: "KHOPO  MOGOBOYA  NAPHUNO",
    Latitude: "-23.987",
    Longitude: "30.202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14417",
    WardLookupId: "1839",
    VDNumber: "76240122",
    RegisteredPopulation: "793",
    VotingStationName: "LESEKA LOWER PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MAHLOGWE  MOPANI",
    Latitude: "-23.9887",
    Longitude: "30.1637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14418",
    WardLookupId: "1839",
    VDNumber: "76240133",
    RegisteredPopulation: "893",
    VotingStationName: "LEPHEPANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  LEPHEPANE VILLAGE  MOPANI",
    Latitude: "-23.989",
    Longitude: "30.1818",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14419",
    WardLookupId: "1839",
    VDNumber: "76240403",
    RegisteredPopulation: "697",
    VotingStationName: "MAHLANE HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  RASEBALANE  MOPANI",
    Latitude: "-24.0045",
    Longitude: "30.1807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14420",
    WardLookupId: "1839",
    VDNumber: "76240469",
    RegisteredPopulation: "585",
    VotingStationName: "DAYCARE CENTRE",
    Address: "NO STREET ADDRESS AVALAIBLE  LEPHEPHANE  MOPANI ",
    Latitude: "-23.9865",
    Longitude: "30.1853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14421",
    WardLookupId: "1839",
    VDNumber: "76240470",
    RegisteredPopulation: "1048",
    VotingStationName: "MAJE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVALAIBLE  MOPANI/LEPHEPHANE  TZANEEN  ",
    Latitude: "-23.9803",
    Longitude: "30.2042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14422",
    WardLookupId: "1840",
    VDNumber: "76240223",
    RegisteredPopulation: "1469",
    VotingStationName: "SEMANA PRIMARY SCHOOL",
    Address: "MOHLATLARENG  GA MAAKE  ",
    Latitude: "-24.0097",
    Longitude: "30.2716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14423",
    WardLookupId: "1840",
    VDNumber: "76240245",
    RegisteredPopulation: "1808",
    VotingStationName: "MAAKE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  LENYENYE  MOPANI",
    Latitude: "-24.0192",
    Longitude: "30.2546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14424",
    WardLookupId: "1840",
    VDNumber: "76240335",
    RegisteredPopulation: "681",
    VotingStationName: "RASEMANA LOWER PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MOKOMOTJI VILLAGE  MOPANI",
    Latitude: "-23.995",
    Longitude: "30.258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14425",
    WardLookupId: "1840",
    VDNumber: "76240515",
    RegisteredPopulation: "558",
    VotingStationName: "PHEREHLA MAAKE SECONDARY SCHOOL",
    Address: "MAKHWIBIDUNG  GREATER TZANEEN  MAAKE TRIBAL AUTHORITY",
    Latitude: "-24.0214",
    Longitude: "30.2764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14426",
    WardLookupId: "1840",
    VDNumber: "76240537",
    RegisteredPopulation: "582",
    VotingStationName: "MMATSEBE CRECHE",
    Address:
      "NO STREET ADDRESS AVAILABLE SUNYSIDE VILLAGE  TZANEEN  MAAKE TRIBAL",
    Latitude: "-24.0037",
    Longitude: "30.2709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14427",
    WardLookupId: "3852",
    VDNumber: "76080247",
    RegisteredPopulation: "1023",
    VotingStationName: "MPHEMONATE CRECHE",
    Address: "63 NYAKELANG 1  PHALABORWA  MAKHUSHANE",
    Latitude: "-23.9555",
    Longitude: "31.0377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14428",
    WardLookupId: "3852",
    VDNumber: "76080326",
    RegisteredPopulation: "758",
    VotingStationName: "RETHUSITSWE PRIMARY SCHOOL",
    Address: "363 FOSKOR ZONE D NAMAKGALE   NAMKGALE FOSKOR  MOPANI  ",
    Latitude: "-23.9468",
    Longitude: "31.0393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14429",
    WardLookupId: "3852",
    VDNumber: "76080449",
    RegisteredPopulation: "1322",
    VotingStationName: "BAPTIST CHURCH",
    Address: "630 MANDELA ZONE D  NAMAKGALE PALESA  NAMAKGALE",
    Latitude: "-23.942",
    Longitude: "31.0317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14430",
    WardLookupId: "3852",
    VDNumber: "76080539",
    RegisteredPopulation: "553",
    VotingStationName: "FOSKOR PRIMARY SCHOOL",
    Address: "760 FOSKOR ZONE D   NAMAKGALE  NAMAKGALE",
    Latitude: "-23.9472",
    Longitude: "31.037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14431",
    WardLookupId: "3853",
    VDNumber: "76080203",
    RegisteredPopulation: "1722",
    VotingStationName: "MAKHUSHANE TRIBAL AUTHORITYS (R1)",
    Address: "01 MAKHUSHANE MOSHATE  BA- PHALABORWA  MAKHUSHANE",
    Latitude: "-23.9659",
    Longitude: "31.0454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14432",
    WardLookupId: "3853",
    VDNumber: "76080348",
    RegisteredPopulation: "1364",
    VotingStationName: "BURNING FIRE JESUS MINISTRIES, MAKHUSHANE",
    Address: "747 HANNIVILLE MAKHUSHANE  HONEYVILLE  MAKHUSHANE",
    Latitude: "-23.956",
    Longitude: "31.0293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14433",
    WardLookupId: "3853",
    VDNumber: "76080438",
    RegisteredPopulation: "680",
    VotingStationName: "ST PATRICK MATHIBELA PRIMARY SCHOOL",
    Address: "338 SHANGANE MAKHUSHANE  PHALABORWA  MAKHUSHANE",
    Latitude: "-23.9593",
    Longitude: "31.0519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14434",
    WardLookupId: "3853",
    VDNumber: "76080461",
    RegisteredPopulation: "407",
    VotingStationName: "KANANA EMMANUEL ASSEMBLY",
    Address: "KANANA MAKHUSHANE  BA -PHALABORWA  BA -PHALABORWA",
    Latitude: "-23.9556",
    Longitude: "31.0609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14435",
    WardLookupId: "3853",
    VDNumber: "76080494",
    RegisteredPopulation: "363",
    VotingStationName: "THABANG DAY CARE CENTRE",
    Address: "50 GARDEN VIEW MAKHUSHANE  MAKHUSHANE  MAKHUSHANE",
    Latitude: "-23.9649",
    Longitude: "31.0644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14436",
    WardLookupId: "3853",
    VDNumber: "76080562",
    RegisteredPopulation: "297",
    VotingStationName: "JESUS HEALS AND JESUS SAVES",
    Address: "KANANA  MAKHUSHANE  MAKHUSHANE",
    Latitude: "-23.9495",
    Longitude: "31.0565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14437",
    WardLookupId: "3853",
    VDNumber: "76080731",
    RegisteredPopulation: "236",
    VotingStationName: "JESUS IS THE WAY",
    Address: "SEBERA  [PHALABOWA  MAKHUSHANE",
    Latitude: "-23.966",
    Longitude: "31.0335",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14438",
    WardLookupId: "3853",
    VDNumber: "76080742",
    RegisteredPopulation: "285",
    VotingStationName: "MMAPULA DAY CARE CENTRE",
    Address: "407 MAKHUSHANE   MAKHUSAHNE",
    Latitude: "-23.9549",
    Longitude: "31.0406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14439",
    WardLookupId: "3854",
    VDNumber: "76080135",
    RegisteredPopulation: "1453",
    VotingStationName: "PHULANIBYIHOLA PRIMARY SCHOOL",
    Address: "457 NO STREET NAME  BEN FARM A  PHALABORWA",
    Latitude: "-23.916",
    Longitude: "31.0351",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14440",
    WardLookupId: "3854",
    VDNumber: "76080146",
    RegisteredPopulation: "1925",
    VotingStationName: "NTSHUXEKO SECONDARY SCHOOL",
    Address: "674 BEN FARM C  BEN FARM C EXT  PHALABORWA",
    Latitude: "-23.905",
    Longitude: "31.0461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14441",
    WardLookupId: "3854",
    VDNumber: "76080573",
    RegisteredPopulation: "516",
    VotingStationName: "KURHULA PRIMARY SCHOOL",
    Address: "196 KURHULA ZONE B  LULEKANI  MAJEJE",
    Latitude: "-23.8902",
    Longitude: "31.0798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14442",
    WardLookupId: "3854",
    VDNumber: "76080719",
    RegisteredPopulation: "349",
    VotingStationName: "MATIMBA DAY CARE CENTRE",
    Address: "BENFARM  PHALABOWA  MAJEJE",
    Latitude: "-23.9142",
    Longitude: "31.0547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14443",
    WardLookupId: "3854",
    VDNumber: "76080720",
    RegisteredPopulation: "245",
    VotingStationName: "B.N NTSANWISI",
    Address: "5013 BENFARM ZONE B  PHALABORWA  MAJEJE",
    Latitude: "-23.9062",
    Longitude: "31.0547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14444",
    WardLookupId: "3855",
    VDNumber: "76080045",
    RegisteredPopulation: "1373",
    VotingStationName: "VUXENI HIGH SCHOOL",
    Address: "813  NAMAKGALE ZONE A   PHALABORWA",
    Latitude: "-23.9391",
    Longitude: "31.0364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14445",
    WardLookupId: "3855",
    VDNumber: "76080056",
    RegisteredPopulation: "1415",
    VotingStationName: "ZAMANI PRIMARY SCHOOL",
    Address: "3522 ROCKS STREET  NAMAKGALE ZONE B  PHALABORWA",
    Latitude: "-23.9293",
    Longitude: "31.0377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14446",
    WardLookupId: "3856",
    VDNumber: "76080067",
    RegisteredPopulation: "1397",
    VotingStationName: "IKETLENG EARLY LEARNING CENTRE",
    Address: "3511 LEBONENG ZONE A  PHALABORWA  NAMAKGALE",
    Latitude: "-23.9327",
    Longitude: "31.0331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14447",
    WardLookupId: "3856",
    VDNumber: "76080360",
    RegisteredPopulation: "1004",
    VotingStationName: "REFILWE PRIMARY SCHOOL",
    Address: "3466 NAMAKGALE ZONE B  PHALABORWA",
    Latitude: "-23.9268",
    Longitude: "31.03",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14448",
    WardLookupId: "3856",
    VDNumber: "76080517",
    RegisteredPopulation: "873",
    VotingStationName: "LEKANG DAY CARE CENTRE",
    Address: "MANDELA VILLAGE   PHALABORWA  NAMAKGALE",
    Latitude: "-23.9414",
    Longitude: "31.0274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14449",
    WardLookupId: "3856",
    VDNumber: "76080607",
    RegisteredPopulation: "318",
    VotingStationName: "CHURCH OF THE NAZARENE",
    Address: "1132 THULASIZWE 1  NAMAKGALE A  PHALABORWA",
    Latitude: "-23.936",
    Longitude: "31.0376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14450",
    WardLookupId: "3857",
    VDNumber: "76080258",
    RegisteredPopulation: "1127",
    VotingStationName: "REGENERATION CHRISTIAN BIBLE CHURCH.",
    Address: "TOPVILLE  NAMAKGALE  PHALABORWA",
    Latitude: "-23.9412",
    Longitude: "31.013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14451",
    WardLookupId: "3857",
    VDNumber: "76080393",
    RegisteredPopulation: "1867",
    VotingStationName: "REFENTSE PRIMARY SCHOOL",
    Address: "125 MANDELA ZONE E  MANDELA   NAMAKGALE  ",
    Latitude: "-23.9375",
    Longitude: "31.0193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14452",
    WardLookupId: "3857",
    VDNumber: "76080483",
    RegisteredPopulation: "686",
    VotingStationName: "SELEMATSELA DROP-IN CENTRE",
    Address: "MSHENGUVILLE  NAMAKGALE ZONE D  NAMAKGALE",
    Latitude: "-23.947",
    Longitude: "31.0259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14453",
    WardLookupId: "3858",
    VDNumber: "76080078",
    RegisteredPopulation: "1825",
    VotingStationName: "LETHABONG EARLY LEARNING CENTRE",
    Address: "1294 ZONE C  NAMAKGALE  PHALABORWA",
    Latitude: "-23.9249",
    Longitude: "31.0171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14454",
    WardLookupId: "3858",
    VDNumber: "76080506",
    RegisteredPopulation: "534",
    VotingStationName: "CHRISTIAN ASSEMBLIES",
    Address: "1463 ZONE C -   NAMAKGALE-C  PHALABORWA",
    Latitude: "-23.9305",
    Longitude: "31.014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14455",
    WardLookupId: "3858",
    VDNumber: "76080595",
    RegisteredPopulation: "610",
    VotingStationName: "LUTHERAN CHURCH SCORE",
    Address: "629 SCORE NAMAKGALE  PHALABORWA  NAMAKGALE",
    Latitude: "-23.9292",
    Longitude: "31.0183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14456",
    WardLookupId: "3858",
    VDNumber: "76080618",
    RegisteredPopulation: "1007",
    VotingStationName: "TSWELOPELE CHILDCARE",
    Address: "565 LONG HOMES ZONE C  PHALABORWA  NAMAKGALE",
    Latitude: "-23.9314",
    Longitude: "31.0224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14457",
    WardLookupId: "3859",
    VDNumber: "76080157",
    RegisteredPopulation: "2249",
    VotingStationName: "MABINE NORTH SCHOOL",
    Address: "27 MASHISHIMALE R3 MOHLABENG  MASHISHIMALE R3  PHALABORWA",
    Latitude: "-23.9355",
    Longitude: "30.991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14458",
    WardLookupId: "3859",
    VDNumber: "76080382",
    RegisteredPopulation: "1048",
    VotingStationName: "MASHISHIMALE LUTHERAN CHURCH",
    Address: "686 TLAPENG MASHISHIMALE  GA MASHISHIMALE  MOPANI  ",
    Latitude: "-23.954",
    Longitude: "30.9841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14459",
    WardLookupId: "3859",
    VDNumber: "76080629",
    RegisteredPopulation: "752",
    VotingStationName: "LEBEKO SCHOOL",
    Address: "MASHISHIMALE R3  PHALABORWA  MASHISHIMALE",
    Latitude: "-23.946",
    Longitude: "30.9974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14460",
    WardLookupId: "3859",
    VDNumber: "76080753",
    RegisteredPopulation: "493",
    VotingStationName: "MARUPING DAY CARE CENTRE",
    Address: "1/1 MASHISHIMALE MATSHELAPATA  PHALABORWA  MASHISHIMALE",
    Latitude: "-23.9338",
    Longitude: "31.0041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14461",
    WardLookupId: "3860",
    VDNumber: "76080168",
    RegisteredPopulation: "1707",
    VotingStationName: "MASHISHIMALE PRIMARY SCHOOL",
    Address:
      "MOSEMANENG MASHISHIMALE  MASHISHIMALE  BA-PHALABORWA [PHALABORWA]",
    Latitude: "-23.9701",
    Longitude: "30.9898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14462",
    WardLookupId: "3860",
    VDNumber: "76080191",
    RegisteredPopulation: "1288",
    VotingStationName: "LEPHALA EARLY LEARNING CENTRES",
    Address: "577 MAUNE MAKHUSHANE  BA- PHALABORWA  BA- PHALABORWA",
    Latitude: "-23.9713",
    Longitude: "31.0376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14463",
    WardLookupId: "3860",
    VDNumber: "76080630",
    RegisteredPopulation: "273",
    VotingStationName: "BOLLANOTO PRIMARY SCHOOL",
    Address: "002 MAKHUSHANE R2 MAUNE  NAMAKGALE  MAKHUSHANE",
    Latitude: "-23.9776",
    Longitude: "31.0406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14464",
    WardLookupId: "3860",
    VDNumber: "76080641",
    RegisteredPopulation: "659",
    VotingStationName: "SUPRISE DAY CARE CENTRE",
    Address: "01 MAPIKIRI MAKHUSHANE R2  PHALABORWA  PHALABORWA",
    Latitude: "-23.9728",
    Longitude: "31.0203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14465",
    WardLookupId: "3861",
    VDNumber: "76080214",
    RegisteredPopulation: "248",
    VotingStationName: "GRIETJIE PRIVATE NATURE RESERVE",
    Address: "GRIETJIE  ",
    Latitude: "-24.0487",
    Longitude: "31.0172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14466",
    WardLookupId: "3861",
    VDNumber: "76080236",
    RegisteredPopulation: "1342",
    VotingStationName: "MODUME SCHOOL",
    Address:
      "312 TSHUBJE MASHISHIMALE  MASHISHIMALE  BA-PHALABORWA [PHALABORWA]",
    Latitude: "-23.9908",
    Longitude: "30.9907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14467",
    WardLookupId: "3861",
    VDNumber: "76080269",
    RegisteredPopulation: "857",
    VotingStationName: "MATOME MALATJIE SCHOOL",
    Address: "144 MMABATHO MASEKE  MASEKE  BA-PHALABORWA [PHALABORWA]",
    Latitude: "-23.9933",
    Longitude: "31.0128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14468",
    WardLookupId: "3861",
    VDNumber: "76080304",
    RegisteredPopulation: "280",
    VotingStationName: "MASEKE TRIBAL OFFICE",
    Address: "368 MOSHATE MASEKE  MASEKE  BA-PHALABORWA [PHALABORWA]",
    Latitude: "-23.9862",
    Longitude: "31.0141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14469",
    WardLookupId: "3861",
    VDNumber: "76080528",
    RegisteredPopulation: "319",
    VotingStationName: "LEHALABILE ROTARY OLDERBROEK",
    Address: "BOELANG VILLAGE MAKHUSHANE  BA- PHALABORWA  BA- PHALABORWA",
    Latitude: "-23.9858",
    Longitude: "31.0666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14470",
    WardLookupId: "3861",
    VDNumber: "76080696",
    RegisteredPopulation: "418",
    VotingStationName: "MOSOMA CAFE",
    Address: "802 MAKGWARENG  MASEKE VILLAGE  MASEKE TRADITIONAL AUTHORITY",
    Latitude: "-23.9906",
    Longitude: "31.0214",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14471",
    WardLookupId: "3862",
    VDNumber: "76080180",
    RegisteredPopulation: "2200",
    VotingStationName: "ACTIVITY HALL",
    Address: "CNR NELSON & SEALENE  PHALABORWA  PHALABORWA",
    Latitude: "-23.9505",
    Longitude: "31.1366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14472",
    WardLookupId: "3862",
    VDNumber: "76080359",
    RegisteredPopulation: "411",
    VotingStationName: "BOIKHUTSONG GUEST HOUSE",
    Address: "15 LEKKER BREEK  MOPANI   PHALABORWA  ",
    Latitude: "-23.9443",
    Longitude: "31.0851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14473",
    WardLookupId: "3862",
    VDNumber: "76080674",
    RegisteredPopulation: "565",
    VotingStationName: "LAERSKOOL PHALABORWA SUID",
    Address: "35 MOPANI  PHALABORWA  PHALABORWA",
    Latitude: "-23.949",
    Longitude: "31.1421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14474",
    WardLookupId: "3862",
    VDNumber: "76080685",
    RegisteredPopulation: "406",
    VotingStationName: "HOUSE OF WORSHIP",
    Address: "PRESIDENT BRANDT  PHALABORWA  PHALABORWA",
    Latitude: "-23.9411",
    Longitude: "31.1489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14475",
    WardLookupId: "3862",
    VDNumber: "76290969",
    RegisteredPopulation: "169",
    VotingStationName: "MOPANI CAMP,",
    Address: "KRUGER NATIONAL PARK  ",
    Latitude: "-23.5207",
    Longitude: "31.3954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14476",
    WardLookupId: "3862",
    VDNumber: "76291050",
    RegisteredPopulation: "170",
    VotingStationName: "LETABA CAMP",
    Address: "KRUGER NATIONAL PARK  PHALABORWA  KRUGER NATIONAL PARK",
    Latitude: "-23.855",
    Longitude: "31.5767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14477",
    WardLookupId: "3862",
    VDNumber: "76291061",
    RegisteredPopulation: "172",
    VotingStationName: "OLIFANTS CAMP",
    Address: "OLIFANTS CAMP  KRUGER NATIONAL PARK  KRUGER NATIONAL PARK",
    Latitude: "-24.0055",
    Longitude: "31.74",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14478",
    WardLookupId: "3863",
    VDNumber: "76080179",
    RegisteredPopulation: "2063",
    VotingStationName: "FRANS DU TOIT HIGH SCHOOL",
    Address: "5 JANSEN CRESCENT  PHALABORWA  PHALABORWA",
    Latitude: "-23.9312",
    Longitude: "31.1394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14479",
    WardLookupId: "3863",
    VDNumber: "76080225",
    RegisteredPopulation: "358",
    VotingStationName: "HEBRON HALL",
    Address:
      "HEBRON SCHITTOGHT MILLITARY BA  SCHIETOGHT MILITARY   BA-PHALABORWA",
    Latitude: "-23.9146",
    Longitude: "31.1072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14480",
    WardLookupId: "3863",
    VDNumber: "76080416",
    RegisteredPopulation: "1149",
    VotingStationName: "ROOISKOOL PRIMARY SCHOOL",
    Address: "COMPRETAM  MOPANI  PHALABORWA TOWN ",
    Latitude: "-23.9372",
    Longitude: "31.1359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14481",
    WardLookupId: "3864",
    VDNumber: "76080102",
    RegisteredPopulation: "1873",
    VotingStationName: "PONDO PRIMARY SCHOOL",
    Address: "456 SECTION A  LULEKANI  PHALABORWA",
    Latitude: "-23.8715",
    Longitude: "31.0712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14482",
    WardLookupId: "3864",
    VDNumber: "76080472",
    RegisteredPopulation: "1293",
    VotingStationName: "GINGIRIKANI CRECHE",
    Address: "KURHULA VILLAGE  LULEKANI-IA  MAJEJE TRADITIONAL AUTHORITY",
    Latitude: "-23.8885",
    Longitude: "31.0838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14483",
    WardLookupId: "3864",
    VDNumber: "76080652",
    RegisteredPopulation: "479",
    VotingStationName: "NKATEKO HIGH SCHOOL",
    Address: "473 JOSEPH SHEMANE STREET LULEKANI  PHALABORWA  LUEKANI",
    Latitude: "-23.8659",
    Longitude: "31.0739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14484",
    WardLookupId: "3865",
    VDNumber: "76080089",
    RegisteredPopulation: "1707",
    VotingStationName: "MAJEJE HIGH SCHOOL",
    Address: "1551 SECTION B  LULEKANI  PHALABORWA",
    Latitude: "-23.8674",
    Longitude: "31.0866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14485",
    WardLookupId: "3865",
    VDNumber: "76080090",
    RegisteredPopulation: "1563",
    VotingStationName: "KURISANI EARLY LEARNING CENTRE",
    Address: "480 SECTION A  LULEKANI A  PHALABORWA",
    Latitude: "-23.864",
    Longitude: "31.0715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14486",
    WardLookupId: "3865",
    VDNumber: "76080764",
    RegisteredPopulation: "88",
    VotingStationName: "LULEKANI COMMUNITY HALL",
    Address: "LULEKANI  PHALABORWA  LULEKANI",
    Latitude: "-23.865",
    Longitude: "31.0782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14487",
    WardLookupId: "3866",
    VDNumber: "76080270",
    RegisteredPopulation: "458",
    VotingStationName: "RDP HOUSE",
    Address: "1966 LULEKANI RDP   LULEKANI  PHALABORWA",
    Latitude: "-23.8558",
    Longitude: "31.0865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14488",
    WardLookupId: "3866",
    VDNumber: "76080281",
    RegisteredPopulation: "1195",
    VotingStationName: "LULEKANI PRIMARY SCHOOL",
    Address:
      "479 NNGHUGHUNYANI STREET LULEKANI   BA-PHALABORWA [PHALABORWA]  LULEKANI",
    Latitude: "-23.8592",
    Longitude: "31.0764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14489",
    WardLookupId: "3866",
    VDNumber: "76080292",
    RegisteredPopulation: "546",
    VotingStationName: "NWASORINI PRIMARY SCHOOL",
    Address:
      "134 CRESCEND STREET LULEKANI  BA-PHALABORWA [PHALABORWA]  LULEKANI",
    Latitude: "-23.862",
    Longitude: "31.0812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14490",
    WardLookupId: "3866",
    VDNumber: "76080371",
    RegisteredPopulation: "429",
    VotingStationName: "SOUL HEALING MINISTRY",
    Address: "ZONE B EXTENSION  LULEKANI  BIKO EXTENSION",
    Latitude: "-23.8541",
    Longitude: "31.0817",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14491",
    WardLookupId: "3866",
    VDNumber: "76080405",
    RegisteredPopulation: "1088",
    VotingStationName: "BARANUKA HIGH SCHOOL",
    Address: "1163 MATIKOXIKAYA MAJEJE  MATIKO-XIKAYA  MAJEJE ",
    Latitude: "-23.857",
    Longitude: "31.0484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14492",
    WardLookupId: "3866",
    VDNumber: "76080551",
    RegisteredPopulation: "630",
    VotingStationName: "N`WARISENGA PRIMARY SCHOOL",
    Address: "1227 FAR EAST   LULEKANI, ZONE B  LULEKANI",
    Latitude: "-23.8629",
    Longitude: "31.0899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14493",
    WardLookupId: "3867",
    VDNumber: "76080113",
    RegisteredPopulation: "2334",
    VotingStationName: "XIPHAMELA SCHOOL",
    Address: "354 SECTION B LULEKANI  MATIKO XIKAYA  PHALABORWA",
    Latitude: "-23.8659",
    Longitude: "31.0522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14494",
    WardLookupId: "3867",
    VDNumber: "76080124",
    RegisteredPopulation: "1849",
    VotingStationName: "CHUCHEKANI SCHOOL",
    Address: "554B SECTION B  HUMULANI  PHALABORWA",
    Latitude: "-23.8788",
    Longitude: "31.0614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14495",
    WardLookupId: "3867",
    VDNumber: "76080663",
    RegisteredPopulation: "815",
    VotingStationName: "AFM MATIKO - XIKAYA",
    Address: "MATIKO - XIKAYA ZONE B  PHALABORWA  LULEKANI",
    Latitude: "-23.868",
    Longitude: "31.0571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14496",
    WardLookupId: "3868",
    VDNumber: "76080427",
    RegisteredPopulation: "1286",
    VotingStationName: "MASHAVELA PRIMARY SCHOOL",
    Address: "708 MAJEJE  ZONE B  MAJEJE  MAJEJE  ",
    Latitude: "-23.8993",
    Longitude: "31.0417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14497",
    WardLookupId: "3868",
    VDNumber: "76280306",
    RegisteredPopulation: "858",
    VotingStationName: "MAKIKELE SCHOOL",
    Address: "32 NEW STAND SELWANE  LULEKANI  PHALABORWA",
    Latitude: "-23.6909",
    Longitude: "30.9343",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14498",
    WardLookupId: "3868",
    VDNumber: "76280676",
    RegisteredPopulation: "814",
    VotingStationName: "MASASWIVONA SECONDARY SCHOOL",
    Address: "325 MAHALE SELWANA  LULEKANI  PHALABORWA",
    Latitude: "-23.6952",
    Longitude: "30.9699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14499",
    WardLookupId: "3868",
    VDNumber: "76280878",
    RegisteredPopulation: "1104",
    VotingStationName: "NYAKELANG CRECHE",
    Address: "NYAKELANG VILLAGE  PHALABORWA  SELWANA",
    Latitude: "-23.6951",
    Longitude: "30.9549",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14500",
    WardLookupId: "3869",
    VDNumber: "76280115",
    RegisteredPopulation: "770",
    VotingStationName: "GRAVELOTTE MUNICIPAL OFFICES",
    Address: "GRAVELLOTTE  PHALABORWA  GRAVELLOTTE",
    Latitude: "-23.9524",
    Longitude: "30.6131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14501",
    WardLookupId: "3869",
    VDNumber: "76280418",
    RegisteredPopulation: "344",
    VotingStationName: "ATKV EILAND SPA",
    Address: "AVENTURA EILAND  LETSITELE  PHALABORWA",
    Latitude: "-23.6578",
    Longitude: "30.6733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14502",
    WardLookupId: "3869",
    VDNumber: "76280429",
    RegisteredPopulation: "387",
    VotingStationName: "LESEDING SCHOOL",
    Address: "STIBIUM MINE  GRAVELOTTE  PHALABORWA",
    Latitude: "-23.9068",
    Longitude: "30.6813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14503",
    WardLookupId: "3869",
    VDNumber: "76280597",
    RegisteredPopulation: "292",
    VotingStationName: "PRIESKA SCHOOL",
    Address: "PRIESKA VILLAGE   SELWANE VILLAGE  LULEKANI",
    Latitude: "-23.6945",
    Longitude: "30.7465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14504",
    WardLookupId: "3869",
    VDNumber: "76280755",
    RegisteredPopulation: "817",
    VotingStationName: "MADJADJI PRIMARY SCHOOL",
    Address: "350 MOSELAKGOMO SELWANA  GA SELWANE  SELWANA ",
    Latitude: "-23.7011",
    Longitude: "30.9123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14505",
    WardLookupId: "3869",
    VDNumber: "76280766",
    RegisteredPopulation: "401",
    VotingStationName: "EHLEKETANI PRIMARY SCHOOL",
    Address: "MAJEJE 3  SELWANA  PHALABORWA",
    Latitude: "-23.7017",
    Longitude: "30.886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14506",
    WardLookupId: "3869",
    VDNumber: "76280777",
    RegisteredPopulation: "691",
    VotingStationName: "BA-SELWANA TRADITIONAL AUTHORITY",
    Address: "SELWANA  SELWANA VILLAGE  SELWANA VILLAGE",
    Latitude: "-23.695",
    Longitude: "30.9288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14507",
    WardLookupId: "3869",
    VDNumber: "76280856",
    RegisteredPopulation: "21",
    VotingStationName: "CHESTER FARM",
    Address: "CHESTER PLOT  PHALABORWA  SELWANA",
    Latitude: "-23.7802",
    Longitude: "30.6586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14508",
    WardLookupId: "3869",
    VDNumber: "76280867",
    RegisteredPopulation: "217",
    VotingStationName: "MABETE CAFE.",
    Address: "EILAND FARMS  PHALABORWA  MABETE FARMS",
    Latitude: "-23.6849",
    Longitude: "30.6107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14509",
    WardLookupId: "3870",
    VDNumber: "76080034",
    RegisteredPopulation: "1128",
    VotingStationName: "METHODIST CHURCH",
    Address: "NAMAKGALE ZONE A  NAMAKGALE  PHALABORWA",
    Latitude: "-23.938",
    Longitude: "31.0487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14510",
    WardLookupId: "3870",
    VDNumber: "76080315",
    RegisteredPopulation: "856",
    VotingStationName: "MHALAMHALA PRIMARY SCHOOL",
    Address: "180 NAMAKGALE ZONE A   NAMAKGALE  NAMAKGALE",
    Latitude: "-23.9413",
    Longitude: "31.0465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14511",
    WardLookupId: "3870",
    VDNumber: "76080337",
    RegisteredPopulation: "674",
    VotingStationName: "ASSEMBLIES OF GOD CHURCH",
    Address: "112 NAMAKGALE  ZONE A   NAMAKGALE  PHALABORWA",
    Latitude: "-23.9461",
    Longitude: "31.0486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14512",
    WardLookupId: "3870",
    VDNumber: "76080450",
    RegisteredPopulation: "586",
    VotingStationName: "KGOPSANE PRIMARY SCHOOL",
    Address: "1793 NO NAMAKGALE ZONE A   NAMAKGALE ZONE A  NAMAKAGLE  ",
    Latitude: "-23.9324",
    Longitude: "31.0446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14513",
    WardLookupId: "3870",
    VDNumber: "76080540",
    RegisteredPopulation: "147",
    VotingStationName: "MAKHUSHANE CAMP",
    Address: "MAKHUSHANE  ",
    Latitude: "-23.9268",
    Longitude: "31.0541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14514",
    WardLookupId: "3870",
    VDNumber: "76080584",
    RegisteredPopulation: "377",
    VotingStationName: "BURNING FIRE JESUS MINISTRY",
    Address: "MALUNGANE VILLAGE  PHALABORWA  MAKHUSHANE",
    Latitude: "-23.9294",
    Longitude: "31.0465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14515",
    WardLookupId: "3870",
    VDNumber: "76080708",
    RegisteredPopulation: "181",
    VotingStationName: "NAMAKGALE PRIMARY SCHOOL",
    Address: "1801  NAMAKGALE ZONE A   NAMAKGALE  NAMAKGALE",
    Latitude: "-23.9385",
    Longitude: "31.0438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14516",
    WardLookupId: "3871",
    VDNumber: "76260023",
    RegisteredPopulation: "2570",
    VotingStationName: "HOEDSPRUIT COUNCIL OFFICES (LIBRARY)",
    Address: "65 SPRINGBOK ST  HOEDSPRUIT  MOPANI",
    Latitude: "-24.3531",
    Longitude: "30.9475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14517",
    WardLookupId: "3871",
    VDNumber: "76260506",
    RegisteredPopulation: "1392",
    VotingStationName: "VAN RENSBURG HALL",
    Address: "NO STREET ADDRESS AVAILABLE  DRAKENSIG   MOPANI ",
    Latitude: "-24.3441",
    Longitude: "30.9338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14518",
    WardLookupId: "3871",
    VDNumber: "76260517",
    RegisteredPopulation: "416",
    VotingStationName: "HOEDSPRUIT HUB",
    Address: "NO STREET ADDRESS AVAILABLE  BAVARIA ESTATE  MOPANI  ",
    Latitude: "-24.3798",
    Longitude: "30.884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14519",
    WardLookupId: "3872",
    VDNumber: "76260157",
    RegisteredPopulation: "1135",
    VotingStationName: "MADIYE SCHOOL",
    Address: "N/A  WILLOWS VILLAGE  MOPANI",
    Latitude: "-24.3682",
    Longitude: "30.6161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14520",
    WardLookupId: "3872",
    VDNumber: "76260304",
    RegisteredPopulation: "531",
    VotingStationName: "GOV HALL",
    Address: "N/A  JONKMANSPRUIT  MOPANI",
    Latitude: "-24.4039",
    Longitude: "30.7895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14521",
    WardLookupId: "3872",
    VDNumber: "76260315",
    RegisteredPopulation: "783",
    VotingStationName: "KAMPERSRUS SAAL",
    Address: "KAMPERSRUS  MOPANI",
    Latitude: "-24.4998",
    Longitude: "30.8934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14522",
    WardLookupId: "3872",
    VDNumber: "76260494",
    RegisteredPopulation: "1098",
    VotingStationName: "REAGISHENG BUSINESS COLLEGE",
    Address: "NO STREET ADDRESS AVAILABLE  THE WILLOWS  MOPANI  ",
    Latitude: "-24.3601",
    Longitude: "30.6125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14523",
    WardLookupId: "3872",
    VDNumber: "76260607",
    RegisteredPopulation: "111",
    VotingStationName: "MOLETELE CPA - SCORTIA",
    Address: "SCORTIA  MOPANI  HOEDSPRUIT - KGOSHI MOLETELE",
    Latitude: "-24.5023",
    Longitude: "30.9759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14524",
    WardLookupId: "3873",
    VDNumber: "76260034",
    RegisteredPopulation: "1101",
    VotingStationName: "MAATLA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  FINALE VILLAGE  MOPANI",
    Latitude: "-24.3235",
    Longitude: "30.7069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14525",
    WardLookupId: "3873",
    VDNumber: "76260168",
    RegisteredPopulation: "1193",
    VotingStationName: "DIPHUTI PRIMARY SCHOOL",
    Address: "THE OAKS VILLAGE  MOPANI DISTRICT  GA MAMETJA",
    Latitude: "-24.3582",
    Longitude: "30.6698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14526",
    WardLookupId: "3873",
    VDNumber: "76260438",
    RegisteredPopulation: "509",
    VotingStationName: "SEKOKO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MABELHOUSE  MOPANI  ",
    Latitude: "-24.3331",
    Longitude: "30.6992",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14527",
    WardLookupId: "3873",
    VDNumber: "76260528",
    RegisteredPopulation: "767",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "THE OAKS  MOPANI  HOEDSPRUIT",
    Latitude: "-24.365",
    Longitude: "30.6756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14528",
    WardLookupId: "3873",
    VDNumber: "76260618",
    RegisteredPopulation: "546",
    VotingStationName: "NAZARENE REVIVAL CHURCH",
    Address: "THE OAKS VILLAGE  MOPANI  HOEDSPRUIT",
    Latitude: "-24.3558",
    Longitude: "30.6722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14529",
    WardLookupId: "3874",
    VDNumber: "76260045",
    RegisteredPopulation: "930",
    VotingStationName: "KGOPONG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MABINS B  MOPANI",
    Latitude: "-24.4128",
    Longitude: "30.5563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14530",
    WardLookupId: "3874",
    VDNumber: "76260067",
    RegisteredPopulation: "962",
    VotingStationName: "MABINS TRIBAL OFFICE",
    Address: "MABINS A  MOPANI",
    Latitude: "-24.3769",
    Longitude: "30.5416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14531",
    WardLookupId: "3874",
    VDNumber: "76260461",
    RegisteredPopulation: "764",
    VotingStationName: "MAHUPJE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  SEDAWA  MOPANI  ",
    Latitude: "-24.3646",
    Longitude: "30.5377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14532",
    WardLookupId: "3874",
    VDNumber: "76260483",
    RegisteredPopulation: "533",
    VotingStationName: "DIPONE HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MABINS B (BOCHABELO)  MOPANI  ",
    Latitude: "-24.4145",
    Longitude: "30.5685",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14533",
    WardLookupId: "3874",
    VDNumber: "76260629",
    RegisteredPopulation: "395",
    VotingStationName: "MAMETJA PRIMARY SCHOOL",
    Address: "MABINS - GA MAMETJA  MOPANI  HOEDSPRUIT",
    Latitude: "-24.3853",
    Longitude: "30.5431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14534",
    WardLookupId: "3875",
    VDNumber: "76260078",
    RegisteredPopulation: "1003",
    VotingStationName: "SEDAWA COMMUNITY HALL",
    Address: "SEDAWA  MOPANI",
    Latitude: "-24.3585",
    Longitude: "30.5512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14535",
    WardLookupId: "3875",
    VDNumber: "76260090",
    RegisteredPopulation: "724",
    VotingStationName: "MOLELANE SCHOOL",
    Address: "N/A  MOLALANA VILLAGE  MOPANI",
    Latitude: "-24.3289",
    Longitude: "30.5935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14536",
    WardLookupId: "3875",
    VDNumber: "76260179",
    RegisteredPopulation: "954",
    VotingStationName: "SANTENG PRIMARY SCHOOL",
    Address: "SANTENG VILLAGE  MARULENG  MAMETJA",
    Latitude: "-24.3494",
    Longitude: "30.5949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14537",
    WardLookupId: "3875",
    VDNumber: "76260472",
    RegisteredPopulation: "291",
    VotingStationName: "DIOKE PRE - SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  SEDAWA  MOPANI  ",
    Latitude: "-24.3531",
    Longitude: "30.5626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14538",
    WardLookupId: "3875",
    VDNumber: "76260595",
    RegisteredPopulation: "490",
    VotingStationName: "CROSSING DAY CARE CENTRE",
    Address: "MOLALANA  MOPANI  HOEDSPRUIT",
    Latitude: "-24.3419",
    Longitude: "30.5644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14539",
    WardLookupId: "3876",
    VDNumber: "76260102",
    RegisteredPopulation: "677",
    VotingStationName: "MASEKANE PRIMARY SCHOOL",
    Address: "NA  WORCESTER  MARULENG",
    Latitude: "-24.2919",
    Longitude: "30.5568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14540",
    WardLookupId: "3876",
    VDNumber: "76260124",
    RegisteredPopulation: "664",
    VotingStationName: "KUBJANAME PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  ENABLE VILLAGE  MOPANI",
    Latitude: "-24.2784",
    Longitude: "30.5254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14541",
    WardLookupId: "3876",
    VDNumber: "76260270",
    RegisteredPopulation: "1076",
    VotingStationName: "MALEBALONG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BISMARK VILLAGE  MOPANI",
    Latitude: "-24.2078",
    Longitude: "30.5025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14542",
    WardLookupId: "3876",
    VDNumber: "76260450",
    RegisteredPopulation: "718",
    VotingStationName: "MMALESIBA HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  ENABLE  MOPANI  ",
    Latitude: "-24.2867",
    Longitude: "30.5304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14543",
    WardLookupId: "3876",
    VDNumber: "76260573",
    RegisteredPopulation: "433",
    VotingStationName: "NEW LIFE MINISTRIES",
    Address: "BISMARK - MARULENG  MOPANI  HOEDSPRUIT",
    Latitude: "-24.2084",
    Longitude: "30.5116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14544",
    WardLookupId: "3876",
    VDNumber: "76280519",
    RegisteredPopulation: "258",
    VotingStationName: "MICA CENTRE",
    Address: "MICA  MOPANI",
    Latitude: "-24.1584",
    Longitude: "30.8327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14545",
    WardLookupId: "3877",
    VDNumber: "76260089",
    RegisteredPopulation: "1043",
    VotingStationName: "MAMPSHE PRIMARY SCHOOL",
    Address: "N/A  SETJOPERENG VILLAGE  MOPANI",
    Latitude: "-24.3097",
    Longitude: "30.4967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14546",
    WardLookupId: "3877",
    VDNumber: "76260113",
    RegisteredPopulation: "1290",
    VotingStationName: "SEKOLO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  TURKEY  MOPANI",
    Latitude: "-24.3166",
    Longitude: "30.5091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14547",
    WardLookupId: "3877",
    VDNumber: "76260146",
    RegisteredPopulation: "717",
    VotingStationName: "BUTSWANA PRIMARY SCHOOL",
    Address: "N/A  BUTSWANA VILLAGE  MOPANI",
    Latitude: "-24.3291",
    Longitude: "30.5451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14548",
    WardLookupId: "3877",
    VDNumber: "76260584",
    RegisteredPopulation: "274",
    VotingStationName: "RAKGOLOKOANA HIGH SCHOOL",
    Address: "ENABLE  MOPANI  HOEDSPRUIT",
    Latitude: "-24.3074",
    Longitude: "30.5123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14549",
    WardLookupId: "3877",
    VDNumber: "76260674",
    RegisteredPopulation: "261",
    VotingStationName: "PASEKA DAY CARE CENTRE",
    Address: "KGOGONGWE, BUTSWANA  MOPANI DISTRICT  HOEDSPRUIT",
    Latitude: "-24.3182",
    Longitude: "30.5237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14550",
    WardLookupId: "3878",
    VDNumber: "76260203",
    RegisteredPopulation: "1815",
    VotingStationName: "MAKGAUNG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MAKGAUNG  MOPANI",
    Latitude: "-24.2863",
    Longitude: "30.4544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14551",
    WardLookupId: "3878",
    VDNumber: "76260348",
    RegisteredPopulation: "746",
    VotingStationName: "LETAU PRE-SCHOOL",
    Address: "MAKGAUNG VILLAGE  MARULENG  MOPANI",
    Latitude: "-24.3028",
    Longitude: "30.4861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14552",
    WardLookupId: "3878",
    VDNumber: "76260382",
    RegisteredPopulation: "193",
    VotingStationName: "THAMBO DROPPING CENTRE",
    Address: "100 THAMBO, TURKEY 3, NTSWELEMOTSE  MOPANI  HOEDSPRUIT",
    Latitude: "-24.293",
    Longitude: "30.4928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14553",
    WardLookupId: "3878",
    VDNumber: "76260449",
    RegisteredPopulation: "733",
    VotingStationName: "MAGAINGWANA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  TURKEY ZONE 4  MOPANI  ",
    Latitude: "-24.3048",
    Longitude: "30.4778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14554",
    WardLookupId: "3878",
    VDNumber: "76260539",
    RegisteredPopulation: "837",
    VotingStationName: "MAMOKAILE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MAKGAUNG   MOPANI  ",
    Latitude: "-24.2886",
    Longitude: "30.472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14555",
    WardLookupId: "3879",
    VDNumber: "76260214",
    RegisteredPopulation: "1139",
    VotingStationName: "METZ TRIBAL OFFICE",
    Address: "N/A  METZ 2  MOPANI",
    Latitude: "-24.2569",
    Longitude: "30.4652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14556",
    WardLookupId: "3879",
    VDNumber: "76260292",
    RegisteredPopulation: "1121",
    VotingStationName: "MOLALATLADI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  METZ 1  MARULENG",
    Latitude: "-24.2385",
    Longitude: "30.4777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14557",
    WardLookupId: "3879",
    VDNumber: "76260405",
    RegisteredPopulation: "682",
    VotingStationName: "NKAMOGELE MONARENG DROP IN CENTRE",
    Address: "METZ VILLAGE  MOETLADIMO  BANARENG BA GA LETSOALO",
    Latitude: "-24.2451",
    Longitude: "30.4711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14558",
    WardLookupId: "3879",
    VDNumber: "76260416",
    RegisteredPopulation: "332",
    VotingStationName: "RANKAGELE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  METZ  MOPANI  ",
    Latitude: "-24.2272",
    Longitude: "30.4838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14559",
    WardLookupId: "3879",
    VDNumber: "76260630",
    RegisteredPopulation: "299",
    VotingStationName: "METZ JUNIOR PRIMARY SCHOOL",
    Address: "METZ VILLAGE  MOPANI  HOEDSPRUIT",
    Latitude: "-24.253",
    Longitude: "30.4659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14560",
    WardLookupId: "3879",
    VDNumber: "76260696",
    RegisteredPopulation: "60",
    VotingStationName: "BRILLIANT LIFE CRECHE",
    Address: "METZ VILLAGE  MOETLADIMO  BANARENG BA GA LETSOALO",
    Latitude: "-24.264",
    Longitude: "30.4571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14561",
    WardLookupId: "3880",
    VDNumber: "76260012",
    RegisteredPopulation: "1135",
    VotingStationName: "SEKORORO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  SOFAYA  MOPANI",
    Latitude: "-24.2361",
    Longitude: "30.3979",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14562",
    WardLookupId: "3880",
    VDNumber: "76260135",
    RegisteredPopulation: "1321",
    VotingStationName: "LEKANE PRIMARY SCHOOL",
    Address: "N/A  MADEIRA VILLAGE  MOPANI",
    Latitude: "-24.2616",
    Longitude: "30.4293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14563",
    WardLookupId: "3880",
    VDNumber: "76260427",
    RegisteredPopulation: "594",
    VotingStationName: "SEKGALABJANA HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MADEIRA  MOPANI  ",
    Latitude: "-24.2659",
    Longitude: "30.4359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14564",
    WardLookupId: "3880",
    VDNumber: "76260641",
    RegisteredPopulation: "401",
    VotingStationName: "SOFAYA COMMUNITY BUSINESS CENTRE",
    Address: "SOFAYA VILLAGE  MOPANI  HOEDSPRUIT",
    Latitude: "-24.2319",
    Longitude: "30.4102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14565",
    WardLookupId: "3881",
    VDNumber: "76260180",
    RegisteredPopulation: "1280",
    VotingStationName: "TIMAMOGOLO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  HLOHLOKWE  MOPANI",
    Latitude: "-24.2211",
    Longitude: "30.439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14566",
    WardLookupId: "3881",
    VDNumber: "76260281",
    RegisteredPopulation: "1262",
    VotingStationName: "MMAKAU PRIMARY SCHOOL",
    Address: "N/A  TICKEYLINE  MOPANI",
    Latitude: "-24.207",
    Longitude: "30.4441",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14567",
    WardLookupId: "3881",
    VDNumber: "76260371",
    RegisteredPopulation: "1055",
    VotingStationName: "TJALAATJALA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  TICKEYLINE  MARULENG [HOEDSPRUIT]",
    Latitude: "-24.2266",
    Longitude: "30.429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14568",
    WardLookupId: "3881",
    VDNumber: "76260562",
    RegisteredPopulation: "597",
    VotingStationName: "NAZARENE CHURCH",
    Address: "HLOHLOKWE  MOPANI  HOEDSPRUIT",
    Latitude: "-24.2141",
    Longitude: "30.4406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14569",
    WardLookupId: "3881",
    VDNumber: "76260652",
    RegisteredPopulation: "289",
    VotingStationName: "MAKHUTSWE CIRCUIT OF EDUCATION",
    Address: "TICKEYLINE - MOKGATLONG  MOPANI  HOEDSPRUIT",
    Latitude: "-24.2284",
    Longitude: "30.4412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14570",
    WardLookupId: "3882",
    VDNumber: "76260191",
    RegisteredPopulation: "1245",
    VotingStationName: "BANARENG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  LORRAINE  MOPANI",
    Latitude: "-24.1903",
    Longitude: "30.4267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14571",
    WardLookupId: "3882",
    VDNumber: "76260360",
    RegisteredPopulation: "1213",
    VotingStationName: "MAKGWAHLENG SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BELLVILLE  MARULENG [HOEDSPRUIT]",
    Latitude: "-24.202",
    Longitude: "30.423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14572",
    WardLookupId: "3882",
    VDNumber: "76260551",
    RegisteredPopulation: "1102",
    VotingStationName: "KHUDU HIGH SCHOOL",
    Address: "LORRAINE  MOPANI  HOEDSPRUIT",
    Latitude: "-24.1931",
    Longitude: "30.4146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14573",
    WardLookupId: "3882",
    VDNumber: "76260663",
    RegisteredPopulation: "327",
    VotingStationName: "UNITED REFORMED CHURCH",
    Address: "LORRAINE VILLAGE  MOPANI  HOEDSPRUIT",
    Latitude: "-24.1882",
    Longitude: "30.422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14574",
    WardLookupId: "3883",
    VDNumber: "76260247",
    RegisteredPopulation: "254",
    VotingStationName: "OFCOLACO",
    Address: "OFCOLACO  MOPANI",
    Latitude: "-24.0825",
    Longitude: "30.3933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14575",
    WardLookupId: "3883",
    VDNumber: "76260258",
    RegisteredPopulation: "1495",
    VotingStationName: "MAALOBAANE HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BALLOON  MOPANI",
    Latitude: "-24.1955",
    Longitude: "30.379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14576",
    WardLookupId: "3883",
    VDNumber: "76260269",
    RegisteredPopulation: "1071",
    VotingStationName: "THAPO-A-NKONA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  CALAIS  MARULENG",
    Latitude: "-24.1337",
    Longitude: "30.3309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14577",
    WardLookupId: "3883",
    VDNumber: "76260393",
    RegisteredPopulation: "349",
    VotingStationName: "BALOON MAANTÅ ANA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BALOON  MOPANI  ",
    Latitude: "-24.1844",
    Longitude: "30.3817",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14578",
    WardLookupId: "3883",
    VDNumber: "76260685",
    RegisteredPopulation: "326",
    VotingStationName: "CALAIS SECONDARY SCHOOL",
    Address: "CALAIS VILLAGE.  MOPANI DISTRICT  HOEDSPRUIT",
    Latitude: "-24.133",
    Longitude: "30.3397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14579",
    WardLookupId: "3884",
    VDNumber: "76260225",
    RegisteredPopulation: "1145",
    VotingStationName: "SEKORORO TRIBAL OFFICE",
    Address: "SEKORORO  MOPANI",
    Latitude: "-24.2103",
    Longitude: "30.4075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14580",
    WardLookupId: "3884",
    VDNumber: "76260236",
    RegisteredPopulation: "588",
    VotingStationName: "IKAGENG CRECHE",
    Address: "MAHLOMELONG  TRICHARDTSDAL  SEKORORO",
    Latitude: "-24.2223",
    Longitude: "30.3978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:43",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:43",
  },
  {
    VotingStationId: "14581",
    WardLookupId: "3884",
    VDNumber: "76260359",
    RegisteredPopulation: "696",
    VotingStationName: "LEFOKE PRIMARY SCHOOL",
    Address: "KANANA VILLAGE  KANANA  MARULENG [HOEDSPRUIT]",
    Latitude: "-24.2132",
    Longitude: "30.4126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14582",
    WardLookupId: "3884",
    VDNumber: "76260540",
    RegisteredPopulation: "568",
    VotingStationName: "MATHABA GENERAL DEALER",
    Address: "MOSHATE - GASEKORORO  HOEDSPRUIT  SEKORORO",
    Latitude: "-24.2026",
    Longitude: "30.4103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14583",
    WardLookupId: "1841",
    VDNumber: "76070077",
    RegisteredPopulation: "635",
    VotingStationName: "EL-SHADAI",
    Address: "SCHOLTZ  VHEMBE  MUSINA ",
    Latitude: "-22.3542",
    Longitude: "30.0448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14584",
    WardLookupId: "1841",
    VDNumber: "76370306",
    RegisteredPopulation: "135",
    VotingStationName: "LEEUDRAAI (LIMPOPO SAFARIS)",
    Address: "LEEUDRAAI  LEEUDRAAI FARM  MUSINA",
    Latitude: "-22.3548",
    Longitude: "30.2795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14585",
    WardLookupId: "1841",
    VDNumber: "76370429",
    RegisteredPopulation: "246",
    VotingStationName: "SCHUIDRIFT COMBINED SCHOOL",
    Address: "TSHIPISE  SCHUIDRIFT FARM  MUSINA",
    Latitude: "-22.6259",
    Longitude: "30.1484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14586",
    WardLookupId: "1841",
    VDNumber: "76370441",
    RegisteredPopulation: "79",
    VotingStationName: "BOKMAKIERIE FARM",
    Address: "BOKMAKIERIE FARM  MOPANE  MUSINA",
    Latitude: "-22.6385",
    Longitude: "29.9192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14587",
    WardLookupId: "1841",
    VDNumber: "76370452",
    RegisteredPopulation: "532",
    VotingStationName: "DEPARTMENT OF WATER AFFAIRS TSHIPISE",
    Address: "TSHIPISE WATER AFFAIRS  TSHIPISE  MUSINA",
    Latitude: "-22.6147",
    Longitude: "30.1603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14588",
    WardLookupId: "1841",
    VDNumber: "76370463",
    RegisteredPopulation: "287",
    VotingStationName: "ESMEFOUR FARM SCHOOL",
    Address: "ESMEFOUR FARM  HA-MAFOTO  MESSINA",
    Latitude: "-22.3259",
    Longitude: "30.492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14589",
    WardLookupId: "1841",
    VDNumber: "76370474",
    RegisteredPopulation: "414",
    VotingStationName: "DOREEN FARM ESTATE SCHOOL",
    Address: "DOREEN FARM  DOREEN  TSHIPISE",
    Latitude: "-22.5087",
    Longitude: "30.2159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14590",
    WardLookupId: "1841",
    VDNumber: "76370867",
    RegisteredPopulation: "162",
    VotingStationName: "DOREEN CAMP TENT",
    Address: "DOREEN FARM ESTATE  MUSINA  DOREEN",
    Latitude: "-22.5042",
    Longitude: "30.2372",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14591",
    WardLookupId: "1841",
    VDNumber: "76371037",
    RegisteredPopulation: "348",
    VotingStationName: "TANDA VILLAGE (NWANEDI GATE)",
    Address: "NO STREET ADDRESS AVAILABLE  TANDA [FOLOVHODWE]  VHEMBE  ",
    Latitude: "-22.586",
    Longitude: "30.416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14592",
    WardLookupId: "1841",
    VDNumber: "76371082",
    RegisteredPopulation: "415",
    VotingStationName: "TSHIKHUDINI CRECHE",
    Address: "TSHIKHUDINI  VHEMBE  MUSINA",
    Latitude: "-22.5481",
    Longitude: "30.4074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14593",
    WardLookupId: "1842",
    VDNumber: "76070022",
    RegisteredPopulation: "1716",
    VotingStationName: "BEITBRIDGE PRIMARY SCHOOL",
    Address: "MATSWALE EXT 8  MUSINA-MATSWALE  MUSINA TOWN",
    Latitude: "-22.3354",
    Longitude: "30.0184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14594",
    WardLookupId: "1842",
    VDNumber: "76120028",
    RegisteredPopulation: "258",
    VotingStationName: "PONTDRIFT POLICE STATION",
    Address: "POINTDRIFT BORDER  PONTDRIFT BORDER POST  MESSINA [MESSINA]",
    Latitude: "-22.217",
    Longitude: "29.1397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14595",
    WardLookupId: "1842",
    VDNumber: "76120220",
    RegisteredPopulation: "119",
    VotingStationName: "SCHOOL AT MPATHAKA BROMBEEK FARM",
    Address: "NO STREET ADDRESS  MPATHAKA  VHEMBE",
    Latitude: "-22.5317",
    Longitude: "29.3508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14596",
    WardLookupId: "1842",
    VDNumber: "76370317",
    RegisteredPopulation: "250",
    VotingStationName: "WATERPLAAS CASH STORE (WIEPIE) -TENT",
    Address: "WIEPIE   MUSINA  WIEPIE FARM",
    Latitude: "-22.1875",
    Longitude: "29.5452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14597",
    WardLookupId: "1842",
    VDNumber: "76370339",
    RegisteredPopulation: "152",
    VotingStationName: "NAPLES FARM - TENT",
    Address: "LINTON  MUSINA  LINTON",
    Latitude: "-22.3985",
    Longitude: "29.573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14598",
    WardLookupId: "1842",
    VDNumber: "76370340",
    RegisteredPopulation: "309",
    VotingStationName: "MAROI FARM SCHOOL",
    Address: "NO STREET ADDRESS  MESSINA [MESSINA]  MESSINA [MESSINA]",
    Latitude: "-22.1955",
    Longitude: "29.871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14599",
    WardLookupId: "1842",
    VDNumber: "76370384",
    RegisteredPopulation: "143",
    VotingStationName: "MAANDA FARM",
    Address: "DELFT CAFE  MUSINA  DELFT CAFE",
    Latitude: "-22.5634",
    Longitude: "29.7651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14600",
    WardLookupId: "1842",
    VDNumber: "76370407",
    RegisteredPopulation: "288",
    VotingStationName: "MOPANE INTERMEDIATE SCHOOL",
    Address: "MUSINA  MOPANE  MUSINA",
    Latitude: "-22.6167",
    Longitude: "29.8553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14601",
    WardLookupId: "1842",
    VDNumber: "76370957",
    RegisteredPopulation: "144",
    VotingStationName: "OVERFLAKTE FARM 5-TENT",
    Address: "OVERFLAKTE  MUSINA  OVERFLAKTE FARM",
    Latitude: "-22.1288",
    Longitude: "29.659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14602",
    WardLookupId: "1843",
    VDNumber: "76070033",
    RegisteredPopulation: "2646",
    VotingStationName: "MUSINA HIGH SCHOOL",
    Address: "WINNIE MANDELA  MUSHONGOVILLE  MUSINA",
    Latitude: "-22.3306",
    Longitude: "30.0258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14603",
    WardLookupId: "1843",
    VDNumber: "76070099",
    RegisteredPopulation: "484",
    VotingStationName: "ST MARTIN DEPORRESS CATHOLIC CHURCH",
    Address: "RALPH SMALL  NANCEFIELD  MUSINA",
    Latitude: "-22.3335",
    Longitude: "30.0257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14604",
    WardLookupId: "1843",
    VDNumber: "76070101",
    RegisteredPopulation: "603",
    VotingStationName: "RIXILE PRIMARY SCHOOL",
    Address: "NANCEFIELD  MUSINA  MUSINA TOWN",
    Latitude: "-22.3346",
    Longitude: "30.0347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14605",
    WardLookupId: "1844",
    VDNumber: "76070044",
    RegisteredPopulation: "1861",
    VotingStationName: "COMMUNITY HALL NANCEFIELD",
    Address: "RALPH SMALL STREET  NANCEFIELD  MUSINA",
    Latitude: "-22.3323",
    Longitude: "30.0334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14606",
    WardLookupId: "1844",
    VDNumber: "76070066",
    RegisteredPopulation: "962",
    VotingStationName: "LERATO CRECHE",
    Address: "MUSINA  SKOONPLAAS  MUSINA",
    Latitude: "-22.3336",
    Longitude: "30.0383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14607",
    WardLookupId: "1844",
    VDNumber: "76070112",
    RegisteredPopulation: "323",
    VotingStationName: "MAKUSHU PRIMARY SCHOOL",
    Address: "NANCEFIELD  MESSINA-NANCEFIELD  MUSINA",
    Latitude: "-22.3311",
    Longitude: "30.0302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14608",
    WardLookupId: "1845",
    VDNumber: "76070088",
    RegisteredPopulation: "2237",
    VotingStationName: "RENNAISANCE SECONDARY SCHOOL",
    Address: "LITCHI CRESENT   FREEDOM PARK EXT 8  VHEMBE  ",
    Latitude: "-22.3392",
    Longitude: "30.0178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14609",
    WardLookupId: "1845",
    VDNumber: "76070123",
    RegisteredPopulation: "1167",
    VotingStationName: "SHANDUKA CRECHE",
    Address: "MATSWALE PHASE 6  MESSINA MATSWALE  MUSINA",
    Latitude: "-22.3382",
    Longitude: "30.0146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14610",
    WardLookupId: "1846",
    VDNumber: "76070011",
    RegisteredPopulation: "1548",
    VotingStationName: "AGRICULTURAL SHOW GROUNDS",
    Address: "PAT HARRISON STREET  MUSINA  MUSINA",
    Latitude: "-22.3592",
    Longitude: "30.0307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14611",
    WardLookupId: "1846",
    VDNumber: "76070055",
    RegisteredPopulation: "1419",
    VotingStationName: "ST MARTIN DE PORRES PRIMARY SCHOOL",
    Address: "HARPER STREET  HARPER MINE  VHEMBE",
    Latitude: "-22.3556",
    Longitude: "30.0062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14612",
    WardLookupId: "1846",
    VDNumber: "76070134",
    RegisteredPopulation: "417",
    VotingStationName: "KEMBEL MUNICIPAL SATELLITE OFFICE",
    Address: "KEMBEL  MESSINA   KEMBEL",
    Latitude: "-22.3699",
    Longitude: "29.9915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14613",
    WardLookupId: "1846",
    VDNumber: "76070145",
    RegisteredPopulation: "766",
    VotingStationName: "NEW MISSION WORSHIP TEMPLE",
    Address: "NANCEFIELD 9  NANCEFIELD  MUSINA",
    Latitude: "-22.3427",
    Longitude: "30.009",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14614",
    WardLookupId: "1847",
    VDNumber: "76340437",
    RegisteredPopulation: "495",
    VotingStationName: "TSHAPINDA PRIMARY SCHOOL",
    Address: "FOLOVHODWE TSHAPINDA VILLAGE  MUSINA  RAMBUDA",
    Latitude: "-22.5744",
    Longitude: "30.4272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14615",
    WardLookupId: "1847",
    VDNumber: "76340448",
    RegisteredPopulation: "1103",
    VotingStationName: "RATSHIBVUMO SECONDARY SCHOOL",
    Address: "MUSWODI TSHISIMANI  MUSINA  RAMBUDA TRIBAL AUTHORITY",
    Latitude: "-22.5812",
    Longitude: "30.4856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14616",
    WardLookupId: "1847",
    VDNumber: "76340606",
    RegisteredPopulation: "1421",
    VotingStationName: "DZIMAULI SECONDARY SCHOOL",
    Address: "FOLOVHODWE  MUSINA  RAMBUDA",
    Latitude: "-22.5913",
    Longitude: "30.4309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14617",
    WardLookupId: "1847",
    VDNumber: "76340662",
    RegisteredPopulation: "314",
    VotingStationName: "MAMPHODO SECONDARY SCHOOL",
    Address: "GUMELA   MUSINA  RAMBUDA",
    Latitude: "-22.6522",
    Longitude: "30.4621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14618",
    WardLookupId: "1848",
    VDNumber: "76340516",
    RegisteredPopulation: "979",
    VotingStationName: "RATSHISASE SECONDARY SCHOOL",
    Address: "MATSHENA VILLAGE  MUSINA  TSHIKUNDAMALEMA",
    Latitude: "-22.4736",
    Longitude: "30.612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14619",
    WardLookupId: "1848",
    VDNumber: "76340651",
    RegisteredPopulation: "662",
    VotingStationName: "TSHIUNGANI PRIMARY SCHOOL",
    Address: "TSHIUNGANI   MUSINA  RAMBUDA",
    Latitude: "-22.4995",
    Longitude: "30.5819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14620",
    WardLookupId: "1848",
    VDNumber: "76341168",
    RegisteredPopulation: "819",
    VotingStationName: "MABVETE COMMUNITY HALL",
    Address: "MABVETE   MUSINA  RAMBUDA",
    Latitude: "-22.4724",
    Longitude: "30.5821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14621",
    WardLookupId: "1848",
    VDNumber: "76341203",
    RegisteredPopulation: "479",
    VotingStationName: "MASEA PRIMARY SCHOOL",
    Address: "MASEA VILLAGE  MUSINA  TSHIKUNDAMALEMA TRIBAL AREA",
    Latitude: "-22.4706",
    Longitude: "30.5945",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14622",
    WardLookupId: "1848",
    VDNumber: "76370856",
    RegisteredPopulation: "1519",
    VotingStationName: "MADIMBO PRIMARY SCHOOL",
    Address: "MADIMBO  MUSINA MUNICIPALITY",
    Latitude: "-22.4511",
    Longitude: "30.5665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14623",
    WardLookupId: "1848",
    VDNumber: "76371071",
    RegisteredPopulation: "161",
    VotingStationName: "NZUMBULULO CHRISTIAN CHURCH",
    Address: "DOMBONI 2  MUSINA  MUSINA RURAL",
    Latitude: "-22.4988",
    Longitude: "30.5081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14624",
    WardLookupId: "1849",
    VDNumber: "76340471",
    RegisteredPopulation: "491",
    VotingStationName: "SIGONDE PRIMARY SCHOOL",
    Address: "NO STREET NAME  SIGONDE VILLAGE  MUTALE",
    Latitude: "-22.3701",
    Longitude: "30.7145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14625",
    WardLookupId: "1849",
    VDNumber: "76340527",
    RegisteredPopulation: "941",
    VotingStationName: "MANENZHE TRIBAL COUNCIL",
    Address: "MANENZHE VILLAGE  MANENZHE  MUTALE",
    Latitude: "-22.47",
    Longitude: "30.6842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14626",
    WardLookupId: "1849",
    VDNumber: "76340774",
    RegisteredPopulation: "433",
    VotingStationName: "MAPAKONI PRIMARY SCHOOL",
    Address: "NO STREET NAME  MANENZHE  MUTALE [MUTALE-MASISI]",
    Latitude: "-22.4649",
    Longitude: "30.6466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14627",
    WardLookupId: "1849",
    VDNumber: "76340910",
    RegisteredPopulation: "428",
    VotingStationName: "GUMBU JUNIOR PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  GUMBU VILLAGE  VHEMBE  ",
    Latitude: "-22.3602",
    Longitude: "30.793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14628",
    WardLookupId: "1849",
    VDNumber: "76340943",
    RegisteredPopulation: "569",
    VotingStationName: "BALE PRIMARY SCHOOL",
    Address: "BALE  MUSINA  MANENZHE ",
    Latitude: "-22.4342",
    Longitude: "30.6663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14629",
    WardLookupId: "1849",
    VDNumber: "76341078",
    RegisteredPopulation: "427",
    VotingStationName: "MAKAVHINI PRIMARY SCHOOL",
    Address: "MAKAVHINI VILLAGE  MUSINA  MANENZHE TRIBAL AUTHORITY",
    Latitude: "-22.4496",
    Longitude: "30.7127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14630",
    WardLookupId: "1849",
    VDNumber: "76370968",
    RegisteredPopulation: "979",
    VotingStationName: "MALALE PRIMARY SCHOOL",
    Address: "MALALE  MUSINA  RAMBUDA",
    Latitude: "-22.3965",
    Longitude: "30.5942",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14631",
    WardLookupId: "1850",
    VDNumber: "76340493",
    RegisteredPopulation: "338",
    VotingStationName: "TSHIRUNZINI PRIMARY SCHOOL",
    Address: "TSHIRUNZINI  MUSINA  TSHIKUNDAMALEMA",
    Latitude: "-22.5732",
    Longitude: "30.6447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14632",
    WardLookupId: "1850",
    VDNumber: "76340538",
    RegisteredPopulation: "870",
    VotingStationName: "TSHIPISE PRIMARY SCHOOL",
    Address: "SAGOLE  MUSINA  TSHIKUNDAMALEMA TRIBAL",
    Latitude: "-22.5319",
    Longitude: "30.6774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14633",
    WardLookupId: "1850",
    VDNumber: "76340549",
    RegisteredPopulation: "243",
    VotingStationName: "MADANGANI PRIMARY SCHOOL",
    Address: "TSHIVALONI  MUSINA  TSHIKUNDAMALEMA",
    Latitude: "-22.4937",
    Longitude: "30.8124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14634",
    WardLookupId: "1850",
    VDNumber: "76340561",
    RegisteredPopulation: "281",
    VotingStationName: "MUKUNUNDE PRIMARY SCHOOL",
    Address: "MUKUNUNDE  MUSINA  TSHIKUNDAMALEMA",
    Latitude: "-22.4682",
    Longitude: "30.768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14635",
    WardLookupId: "1850",
    VDNumber: "76340572",
    RegisteredPopulation: "183",
    VotingStationName: "MATATANI PRIMARY SCHOOL",
    Address: "MATATANI  MUSINA  TSHIKUNDAMALEMA",
    Latitude: "-22.5637",
    Longitude: "30.7652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14636",
    WardLookupId: "1850",
    VDNumber: "76340785",
    RegisteredPopulation: "170",
    VotingStationName: "DOMBONI PRIMARY SCHOOL",
    Address: "DOMBONI  MUSINA  TSHIKUNDAMALEMA",
    Latitude: "-22.4883",
    Longitude: "30.7454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14637",
    WardLookupId: "1850",
    VDNumber: "76340796",
    RegisteredPopulation: "179",
    VotingStationName: "MBODI PRIMARY SCHOOL",
    Address: "MBODI TSHA NTHA  MUSINA  TSHIKUNDAMALEMA",
    Latitude: "-22.5498",
    Longitude: "30.7159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14638",
    WardLookupId: "1850",
    VDNumber: "76340808",
    RegisteredPopulation: "222",
    VotingStationName: "TSHOKOTSHOKO PRIMARY SCHOOL",
    Address: "TSHOKOTSHOKO  MUSINA  TSHIKUNDAMALEMA",
    Latitude: "-22.6095",
    Longitude: "30.63",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14639",
    WardLookupId: "1850",
    VDNumber: "76340965",
    RegisteredPopulation: "287",
    VotingStationName: "TSHIVHONGWENI PRIMARY SCHOOL",
    Address: "MUTALE   ",
    Latitude: "-22.5715",
    Longitude: "30.5934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14640",
    WardLookupId: "1850",
    VDNumber: "76340976",
    RegisteredPopulation: "430",
    VotingStationName: "MADIFHA PRIMARY SCHOOL",
    Address: "ZWIGODINI  MUSINA  TSHIKUNDAMALEMA ",
    Latitude: "-22.5265",
    Longitude: "30.621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14641",
    WardLookupId: "1850",
    VDNumber: "76341090",
    RegisteredPopulation: "257",
    VotingStationName: "MBODI II COMMUNITY CRECHE",
    Address: "MBODI TSHA FHASI  MUSINA  TSHIKUNDAMALEMA TRIBAL",
    Latitude: "-22.5454",
    Longitude: "30.743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14642",
    WardLookupId: "1850",
    VDNumber: "76341214",
    RegisteredPopulation: "218",
    VotingStationName: "DAMBALE PRIMARY SCHOOL",
    Address: "DAMBALE  MUSINA  TSHIKUNDAMALEMA",
    Latitude: "-22.5097",
    Longitude: "30.6892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14643",
    WardLookupId: "1851",
    VDNumber: "76340459",
    RegisteredPopulation: "1113",
    VotingStationName: "MALILELE SECONDARY SCHOOL",
    Address: "SHAKADZA VILLAGE  MUSINA  THENGWE",
    Latitude: "-22.6188",
    Longitude: "30.5554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14644",
    WardLookupId: "1851",
    VDNumber: "76340707",
    RegisteredPopulation: "525",
    VotingStationName: "MUKOVHAWABALE PRIMARY SCHOOL",
    Address: "MUKOVHAWABALE  MUSINA  THENGWE",
    Latitude: "-22.5736",
    Longitude: "30.5494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14645",
    WardLookupId: "1851",
    VDNumber: "76340718",
    RegisteredPopulation: "953",
    VotingStationName: "MALINGE PRIMARY SCHOOL",
    Address: "MUSWODI DIPENI  MUSINA  RAMBUDA",
    Latitude: "-22.5744",
    Longitude: "30.518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14646",
    WardLookupId: "1851",
    VDNumber: "76340875",
    RegisteredPopulation: "229",
    VotingStationName: "GUNDANI PRIMARY SCHOOL",
    Address: "THENGWE  MUSINA  THENGWE  ",
    Latitude: "-22.6569",
    Longitude: "30.5641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14647",
    WardLookupId: "1851",
    VDNumber: "76341089",
    RegisteredPopulation: "313",
    VotingStationName: "MUSUNDA PRIMARY SCHOOL",
    Address: "MUSUNDA   MUSINA  RAMBUDA",
    Latitude: "-22.6479",
    Longitude: "30.4878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14648",
    WardLookupId: "1851",
    VDNumber: "76341157",
    RegisteredPopulation: "376",
    VotingStationName: "THE LIVING GOSPEL WORLD MISSION CHURCH",
    Address: "MUSWODI DIPENI  MUSINA  RAMBUDA",
    Latitude: "-22.5809",
    Longitude: "30.526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14649",
    WardLookupId: "1852",
    VDNumber: "76290914",
    RegisteredPopulation: "230",
    VotingStationName: "PUNDA MARIA CAMP",
    Address: "KRUGER PARK  LIMDMA33",
    Latitude: "-22.6915",
    Longitude: "31.0169",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14650",
    WardLookupId: "1852",
    VDNumber: "76340460",
    RegisteredPopulation: "682",
    VotingStationName: "MASISI PRIMARY SCHOOL",
    Address: "MUTELE TRIBAL  MUSINA  MUTELE",
    Latitude: "-22.4183",
    Longitude: "30.8695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14651",
    WardLookupId: "1852",
    VDNumber: "76340550",
    RegisteredPopulation: "843",
    VotingStationName: "TSHIKONDENI PRIMARY SCHOOL",
    Address: "SANARI  MUSINA  MUTELE",
    Latitude: "-22.4822",
    Longitude: "30.8744",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14652",
    WardLookupId: "1852",
    VDNumber: "76340617",
    RegisteredPopulation: "398",
    VotingStationName: "MUTELE TRIBAL OFFICE",
    Address: "MUTELE A  MUSINA  MUTELE",
    Latitude: "-22.4731",
    Longitude: "30.9167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14653",
    WardLookupId: "1852",
    VDNumber: "76340640",
    RegisteredPopulation: "210",
    VotingStationName: "TSHIKUYU PRIMARY SCHOOL",
    Address: "TSHIKUYU  MUSINA  MUTELE",
    Latitude: "-22.418",
    Longitude: "30.9506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14654",
    WardLookupId: "1852",
    VDNumber: "76340820",
    RegisteredPopulation: "396",
    VotingStationName: "MADAVHILA PRIMARY SCHOOL",
    Address: "MADAVHILA  MUSINA  MUTELE",
    Latitude: "-22.4039",
    Longitude: "31.0297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14655",
    WardLookupId: "1852",
    VDNumber: "76340921",
    RegisteredPopulation: "236",
    VotingStationName: "TSHENZHELANI PRIMARY SCHOOL",
    Address: "TSHENZHELANI  MUSINA  TSHIKUNDAMALEMA ",
    Latitude: "-22.3849",
    Longitude: "30.829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14656",
    WardLookupId: "1852",
    VDNumber: "76340932",
    RegisteredPopulation: "368",
    VotingStationName: "MARAMANZHI PRIMARY SCHOOL",
    Address: "MARAMANZHI  MUSINA  TSHIKUNDAMALEMA ",
    Latitude: "-22.429",
    Longitude: "30.8053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14657",
    WardLookupId: "1852",
    VDNumber: "76341179",
    RegisteredPopulation: "73",
    VotingStationName: "BILENI COMMUNITY HALL",
    Address: "BILENI  MUSINA  MUTELE",
    Latitude: "-22.4609",
    Longitude: "30.9516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14658",
    WardLookupId: "1852",
    VDNumber: "76341180",
    RegisteredPopulation: "419",
    VotingStationName: "MASISI TAXI RANK OFFICE",
    Address: "MASISI LOCATION  MUSINA  TSHIKUNDAMALEMA",
    Latitude: "-22.4225",
    Longitude: "30.8611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14659",
    WardLookupId: "1852",
    VDNumber: "76341191",
    RegisteredPopulation: "248",
    VotingStationName: "DOVHO PRIMARY SCHOOL",
    Address: "DOVHO VILLAGE  MUSINA  TSHIKUNDAMALEMA",
    Latitude: "-22.4235",
    Longitude: "30.8934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14660",
    WardLookupId: "3885",
    VDNumber: "76340280",
    RegisteredPopulation: "667",
    VotingStationName: "SHESHE PRIMARY SCHOOL",
    Address: "NO STREET NAME  SHESHE VILLAGE  MUTALE",
    Latitude: "-22.8195",
    Longitude: "30.3034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14661",
    WardLookupId: "3885",
    VDNumber: "76340291",
    RegisteredPopulation: "1071",
    VotingStationName: "TSHIXWADZA PRIMARY SCHOOL",
    Address: "NO STREET NAME  RAMBUDA  MUTALE",
    Latitude: "-22.7994",
    Longitude: "30.3475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14662",
    WardLookupId: "3885",
    VDNumber: "76340628",
    RegisteredPopulation: "956",
    VotingStationName: "KHAKHU TRIBAL OFFICE",
    Address: "KHAKHU  KHAKHU VILLAGE  KHAKHU",
    Latitude: "-22.8333",
    Longitude: "30.2532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14663",
    WardLookupId: "3885",
    VDNumber: "76340730",
    RegisteredPopulation: "523",
    VotingStationName: "LUHENI PRIMARY SCHOOL",
    Address: "NO STREET NAME  LUHENI VILLAGE  MUTALE [MUTALE-MASISI]",
    Latitude: "-22.8053",
    Longitude: "30.3698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14664",
    WardLookupId: "3885",
    VDNumber: "76340831",
    RegisteredPopulation: "329",
    VotingStationName: "DZAMBA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  DZAMBA VILLAGE  KHAKHU ",
    Latitude: "-22.8101",
    Longitude: "30.3295",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14665",
    WardLookupId: "3885",
    VDNumber: "76341236",
    RegisteredPopulation: "274",
    VotingStationName: "MUILADI PRIMARY SCHOOL",
    Address: "TSHIFUME VILLAGE  KHAKHU TRIBAL  KHAKHU",
    Latitude: "-22.8285",
    Longitude: "30.289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14666",
    WardLookupId: "3885",
    VDNumber: "76341247",
    RegisteredPopulation: "91",
    VotingStationName: "MADZWORORO TRADITIONAL HALL",
    Address: "MADZWORORO  KHAKHU TRIBAL   KHAKHU",
    Latitude: "-22.7911",
    Longitude: "30.2856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14667",
    WardLookupId: "3885",
    VDNumber: "76370182",
    RegisteredPopulation: "739",
    VotingStationName: "KHAKHU SECONDARY SCHOOL",
    Address: "NO STREET NAME  KHAKHU VILLAGE  KHAKHU",
    Latitude: "-22.8358",
    Longitude: "30.2703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14668",
    WardLookupId: "3885",
    VDNumber: "76370801",
    RegisteredPopulation: "155",
    VotingStationName: "MANGWELE PRIMARY SCHOOL",
    Address: "NO STREET NAME  MANGWELE  KHAKHU",
    Latitude: "-22.7866",
    Longitude: "30.2323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14669",
    WardLookupId: "3886",
    VDNumber: "76340303",
    RegisteredPopulation: "491",
    VotingStationName: "VELE SECONDARY SCHOOL",
    Address: "NO STREET NAME  RAMBUDA  MUTALE",
    Latitude: "-22.7655",
    Longitude: "30.3434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14670",
    WardLookupId: "3886",
    VDNumber: "76340314",
    RegisteredPopulation: "405",
    VotingStationName: "MAVHODE PRIMARY SCHOOL",
    Address: "NO STREET NAME  MAVHODE  RAMBUDA",
    Latitude: "-22.7452",
    Longitude: "30.3713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14671",
    WardLookupId: "3886",
    VDNumber: "76340347",
    RegisteredPopulation: "400",
    VotingStationName: "MABILA PRIMARY SCHOOL",
    Address: "NO STREET NAME  MABILA VILLAGE  RAMBUDA",
    Latitude: "-22.6906",
    Longitude: "30.4799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14672",
    WardLookupId: "3886",
    VDNumber: "76340358",
    RegisteredPopulation: "694",
    VotingStationName: "TSHAMULUNGWI PRIMARY SCHOOL",
    Address: "MUTALE  RAMBUDA",
    Latitude: "-22.7413",
    Longitude: "30.4101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14673",
    WardLookupId: "3886",
    VDNumber: "76340369",
    RegisteredPopulation: "715",
    VotingStationName: "MATAVHELA SECONDARY SCHOOL",
    Address: "NO STREET NAME  MUTALE  THENGWE",
    Latitude: "-22.6729",
    Longitude: "30.5256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14674",
    WardLookupId: "3886",
    VDNumber: "76340752",
    RegisteredPopulation: "276",
    VotingStationName: "TSHITANDANI PRIMARY SCHOOL",
    Address: "NO STREET NAME  TSHITANDANI  RAMBUDA",
    Latitude: "-22.7075",
    Longitude: "30.4091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14675",
    WardLookupId: "3886",
    VDNumber: "76340864",
    RegisteredPopulation: "404",
    VotingStationName: "MAFUKANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MAFUKANI VILLAGE  THENGWE ",
    Latitude: "-22.6725",
    Longitude: "30.5565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14676",
    WardLookupId: "3886",
    VDNumber: "76340886",
    RegisteredPopulation: "386",
    VotingStationName: "GOGOGO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  GOGOGO   RAMBUDA ",
    Latitude: "-22.7775",
    Longitude: "30.3343",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14677",
    WardLookupId: "3886",
    VDNumber: "76341045",
    RegisteredPopulation: "268",
    VotingStationName: "NGALAVHANI PRIMARY SCHOOL",
    Address: "NGALAVHANI VILLAGE  RAMBUDA TRIBAL AUTHORITY",
    Latitude: "-22.685",
    Longitude: "30.4968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14678",
    WardLookupId: "3886",
    VDNumber: "76341056",
    RegisteredPopulation: "252",
    VotingStationName: "GUYUNI PRIMARY SCHOOL",
    Address: "GUYUNI VILLAGE  RAMBUDA TRIBAL AUTHORITY",
    Latitude: "-22.7306",
    Longitude: "30.4747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14679",
    WardLookupId: "3886",
    VDNumber: "76341067",
    RegisteredPopulation: "217",
    VotingStationName: "MADATSHITSHI PRIMARY SCHOOL",
    Address: "MADATSHITSHI VILLAGE  MADATSHITSHI  RAMBUDA TRIBAL AUTHORITY",
    Latitude: "-22.7409",
    Longitude: "30.3432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14680",
    WardLookupId: "3886",
    VDNumber: "76370834",
    RegisteredPopulation: "144",
    VotingStationName: "GOMBANI PRIMARY SCHOOL",
    Address: "GOMBANI  RAMBUDA",
    Latitude: "-22.6837",
    Longitude: "30.3177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14681",
    WardLookupId: "3887",
    VDNumber: "76340370",
    RegisteredPopulation: "1758",
    VotingStationName: "MUTALE TOWN HALL",
    Address: "NO STREET NAME  TSHILAMBA  THENGWE",
    Latitude: "-22.7363",
    Longitude: "30.5246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14682",
    WardLookupId: "3887",
    VDNumber: "76340392",
    RegisteredPopulation: "1186",
    VotingStationName: "BASHASHA PRIMARY SCHOOL",
    Address: "NO STREET NAME  THENGWE  MUTALE",
    Latitude: "-22.7272",
    Longitude: "30.5359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14683",
    WardLookupId: "3887",
    VDNumber: "76340763",
    RegisteredPopulation: "483",
    VotingStationName: "LUKAU PRIMARY SCHOOL",
    Address: "LUKAU  LUKAU VILLAGE  MUTALE [MUTALE-MASISI]",
    Latitude: "-22.7404",
    Longitude: "30.5159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14684",
    WardLookupId: "3887",
    VDNumber: "76340842",
    RegisteredPopulation: "527",
    VotingStationName: "B.R. NEMULODI PRIMARY SCHOOL",
    Address: "MULODI VILLAGE  THENGWE ",
    Latitude: "-22.7434",
    Longitude: "30.5538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14685",
    WardLookupId: "3887",
    VDNumber: "76340853",
    RegisteredPopulation: "631",
    VotingStationName: "MUTALE HOME ECONOMICS",
    Address: "MUTALE  TSHISHIVHE VILLAGE  THENGWE",
    Latitude: "-22.7511",
    Longitude: "30.5327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14686",
    WardLookupId: "3887",
    VDNumber: "76341135",
    RegisteredPopulation: "82",
    VotingStationName: "KHUNGUNI COMMUNITY HALL",
    Address: "KHUNGUNI  MUTALE  THENGWE TRIBAL",
    Latitude: "-22.7262",
    Longitude: "30.5037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14687",
    WardLookupId: "3887",
    VDNumber: "76341146",
    RegisteredPopulation: "232",
    VotingStationName: "THE WILL OF GOD CHURCH",
    Address: "NO SRTEET NAME  MUTALE  THENGWE",
    Latitude: "-22.7463",
    Longitude: "30.5106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14688",
    WardLookupId: "3888",
    VDNumber: "76340235",
    RegisteredPopulation: "839",
    VotingStationName: "MAGILEDZHI PRIMARY SCHOOL",
    Address: "MAGILEDZHI  MUTALE  THENGWE",
    Latitude: "-22.7353",
    Longitude: "30.5958",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14689",
    WardLookupId: "3888",
    VDNumber: "76340404",
    RegisteredPopulation: "1269",
    VotingStationName: "LADZANI PRIMARY SCHOOL",
    Address: "MUTALE  THENGWE",
    Latitude: "-22.717",
    Longitude: "30.5598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14690",
    WardLookupId: "3888",
    VDNumber: "76340415",
    RegisteredPopulation: "989",
    VotingStationName: "TSHIDONGOLOLWE PRIMARY SCHOOL",
    Address: "TSHIDONGOLOLWE  MUTALE  THENGWE",
    Latitude: "-22.6929",
    Longitude: "30.5874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14691",
    WardLookupId: "3888",
    VDNumber: "76340426",
    RegisteredPopulation: "942",
    VotingStationName: "TSHIKUNDAMALEMA TRIBAL COUNCIL",
    Address: "TSHIKUNDAMALEMA  MUTALE  TSHIKUNDAMALEMA",
    Latitude: "-22.6712",
    Longitude: "30.691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14692",
    WardLookupId: "3888",
    VDNumber: "76340639",
    RegisteredPopulation: "957",
    VotingStationName: "NYADZANI SECONDARY SCHOOL",
    Address: "MAPATE  MAPATE VILLAGE  THENGWE",
    Latitude: "-22.6752",
    Longitude: "30.6224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14693",
    WardLookupId: "3889",
    VDNumber: "76340190",
    RegisteredPopulation: "357",
    VotingStationName: "LAVHURALA PRIMARY SCHOOL",
    Address: "LAMVI VILLAGE  MPHAPHULI",
    Latitude: "-22.6715",
    Longitude: "30.7399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14694",
    WardLookupId: "3889",
    VDNumber: "76340202",
    RegisteredPopulation: "374",
    VotingStationName: "TSHIBALO PRIMARY SCHOOL",
    Address: "VHURISHUNI  VHURISHUNI VILLAGE  MPHAPHULI",
    Latitude: "-22.6754",
    Longitude: "30.7985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14695",
    WardLookupId: "3889",
    VDNumber: "76340213",
    RegisteredPopulation: "1007",
    VotingStationName: "NDARIENI SECONDARY SCHOOL",
    Address: "VHURIVHURI VILLAGE  MPHAPHULI",
    Latitude: "-22.7032",
    Longitude: "30.7638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14696",
    WardLookupId: "3889",
    VDNumber: "76340224",
    RegisteredPopulation: "491",
    VotingStationName: "TAKALANI NYAWEDZENI PRIMARY SCHOOL",
    Address: "SAMBANDOU  SAMBANDOU VILLAGE  MPHAPHULI",
    Latitude: "-22.7303",
    Longitude: "30.7012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14697",
    WardLookupId: "3889",
    VDNumber: "76340246",
    RegisteredPopulation: "1128",
    VotingStationName: "SAMBANDOU PRIMARY SCHOOL",
    Address: "SAMBANDOU VILLAGE  SAMBANDOU  MPHAPHULI",
    Latitude: "-22.7396",
    Longitude: "30.6537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14698",
    WardLookupId: "3889",
    VDNumber: "76340257",
    RegisteredPopulation: "755",
    VotingStationName: "TSHANZHE PRIMARY SCHOOL",
    Address: "TSHANZHE  THENGWE",
    Latitude: "-22.7013",
    Longitude: "30.6579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14699",
    WardLookupId: "3889",
    VDNumber: "76340583",
    RegisteredPopulation: "745",
    VotingStationName: "MAVUNDE PRIMARY SCHOOL",
    Address: "MAVUNDE VILLAGE  MAVUNDE  MPHAPHULI",
    Latitude: "-22.7146",
    Longitude: "30.7455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14700",
    WardLookupId: "3889",
    VDNumber: "76340695",
    RegisteredPopulation: "675",
    VotingStationName: "MANGAYA PRIMARY SCHOOL",
    Address: "MANGAYA VILLAGE  MUTALE  THENGWE",
    Latitude: "-22.7191",
    Longitude: "30.6268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14701",
    WardLookupId: "3889",
    VDNumber: "76341102",
    RegisteredPopulation: "293",
    VotingStationName: "SUMBANA SECONDARY SCHOOL",
    Address: "NO STREET NAME  THULAMELA TOWN  SUMBANA VILLAGE",
    Latitude: "-22.6714",
    Longitude: "30.7574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14702",
    WardLookupId: "3890",
    VDNumber: "76340123",
    RegisteredPopulation: "267",
    VotingStationName: "PFUKONI JUNIOR PRIMARY SCHOOL",
    Address: "PFUKONI VILLAGE  LAMBANI  MPHAPHULI",
    Latitude: "-22.7169",
    Longitude: "30.8523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14703",
    WardLookupId: "3890",
    VDNumber: "76340134",
    RegisteredPopulation: "613",
    VotingStationName: "MASETONI PRIMARY SCHOOL",
    Address: "MASETONI LAMBANI  MASETONI LAMBANI VILLAGE  MAPHAPHULI",
    Latitude: "-22.7232",
    Longitude: "30.882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14704",
    WardLookupId: "3890",
    VDNumber: "76340145",
    RegisteredPopulation: "640",
    VotingStationName: "LAMBANI PRIMARY SCHOOL",
    Address: "LAMBANI VILLAGE  MPHAPHULI",
    Latitude: "-22.7217",
    Longitude: "30.8334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14705",
    WardLookupId: "3890",
    VDNumber: "76340156",
    RegisteredPopulation: "394",
    VotingStationName: "GUNDA PRIMARY SCHOOL",
    Address: "GUNDA VILLAGE  MPHAPHULI",
    Latitude: "-22.7086",
    Longitude: "30.8412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14706",
    WardLookupId: "3890",
    VDNumber: "76340178",
    RegisteredPopulation: "1304",
    VotingStationName: "KAREL NGIGIDENI PRIMARY SCHOOL",
    Address: "MAKUYA VILLAGE  MKUYA TRIBAL",
    Latitude: "-22.6415",
    Longitude: "30.8375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14707",
    WardLookupId: "3890",
    VDNumber: "76340482",
    RegisteredPopulation: "306",
    VotingStationName: "TSHIKALANGE PRIMARY SCHOOL",
    Address: "MAKUYA VILLAGE  MAKUYA  MAKUYA TRIBAL",
    Latitude: "-22.5868",
    Longitude: "30.8575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14708",
    WardLookupId: "3890",
    VDNumber: "76340505",
    RegisteredPopulation: "284",
    VotingStationName: "MUKOMA PRIMARY SCHOOL",
    Address: "TSHIKONDENI VILLAGE  MUTALE  MUTELE TRIBAL",
    Latitude: "-22.4835",
    Longitude: "30.9",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14709",
    WardLookupId: "3890",
    VDNumber: "76340673",
    RegisteredPopulation: "541",
    VotingStationName: "LAMVI PRIMARY SCHOOL",
    Address: "NWIINI  NWINI VILLAGE  MPHAPHULI",
    Latitude: "-22.6547",
    Longitude: "30.7971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14710",
    WardLookupId: "3890",
    VDNumber: "76340819",
    RegisteredPopulation: "226",
    VotingStationName: "MATSHIKIRI  PRIMARY SCHOOL",
    Address: "MUTALE  TSHIKUNDAMALEMA",
    Latitude: "-22.5948",
    Longitude: "30.8981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14711",
    WardLookupId: "3890",
    VDNumber: "76340897",
    RegisteredPopulation: "401",
    VotingStationName: "KHAVHAMBE PRIMARY SCHOOL",
    Address: "KHAVHAMBE  KHAVHAMBE VILLAGE  MAKUYA ",
    Latitude: "-22.6605",
    Longitude: "30.8154",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14712",
    WardLookupId: "3890",
    VDNumber: "76340909",
    RegisteredPopulation: "101",
    VotingStationName: "TSHIANZWANE PRIMARY SCHOOL",
    Address: "MALUDZHAWELA  MALUDZHAWELA VILLAGE  THENGWE ",
    Latitude: "-22.5388",
    Longitude: "30.8644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14713",
    WardLookupId: "3890",
    VDNumber: "76340987",
    RegisteredPopulation: "799",
    VotingStationName: "MMBOFHENI PRIMARY SCHOOL",
    Address: "MAHAGALA  LAMBANI  MPHAPHULI",
    Latitude: "-22.748",
    Longitude: "30.8787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14714",
    WardLookupId: "3891",
    VDNumber: "76340088",
    RegisteredPopulation: "1080",
    VotingStationName: "TONDALUSHAKA SECONDARY SCHOOL",
    Address: "TSHAULU  MPHAPHULI",
    Latitude: "-22.7973",
    Longitude: "30.7523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14715",
    WardLookupId: "3891",
    VDNumber: "76340099",
    RegisteredPopulation: "724",
    VotingStationName: "DUVHULEDZA PRIMARY SCHOOL",
    Address: "DUVHULEDZA VILLAGE  DUVHULEDZA   MPHAPHULI",
    Latitude: "-22.7562",
    Longitude: "30.7896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14716",
    WardLookupId: "3891",
    VDNumber: "76340101",
    RegisteredPopulation: "1064",
    VotingStationName: "BEGWA PRIMARY SCHOOL",
    Address: "BEGWA  MPHAPHULI",
    Latitude: "-22.7818",
    Longitude: "30.8022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14717",
    WardLookupId: "3891",
    VDNumber: "76340112",
    RegisteredPopulation: "892",
    VotingStationName: "MAHAGALA PRIMARY SCHOOL",
    Address: "MAHAGALA VILLAGE  MAHAGALA  MPHAPHULI",
    Latitude: "-22.7572",
    Longitude: "30.8593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14718",
    WardLookupId: "3891",
    VDNumber: "76341001",
    RegisteredPopulation: "989",
    VotingStationName: "LUKALO PRIMARY SCHOOL",
    Address: "LUKALO  MPHAPHULI",
    Latitude: "-22.777",
    Longitude: "30.8329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14719",
    WardLookupId: "3891",
    VDNumber: "76341012",
    RegisteredPopulation: "510",
    VotingStationName: "MUBVUMONI PRIMARY SCHOOL",
    Address: "MUBVUMONI  MPHAPHULI",
    Latitude: "-22.7446",
    Longitude: "30.7867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14720",
    WardLookupId: "3892",
    VDNumber: "76340033",
    RegisteredPopulation: "881",
    VotingStationName: "MUTHULI PRIMARY SCHOOL",
    Address: "GABA VILLAGE  GABA  MPHAPHULI",
    Latitude: "-22.8166",
    Longitude: "30.7288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14721",
    WardLookupId: "3892",
    VDNumber: "76340044",
    RegisteredPopulation: "1233",
    VotingStationName: "ZION ACTS CHURCH",
    Address: "GABA VILLAGE  GABA  MPHAPHULI",
    Latitude: "-22.8011",
    Longitude: "30.7224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14722",
    WardLookupId: "3892",
    VDNumber: "76340055",
    RegisteredPopulation: "751",
    VotingStationName: "THOMANI PRIMARY SCHOOL",
    Address: "GABA VILLAGE  GABA  MPHAPHULI",
    Latitude: "-22.8054",
    Longitude: "30.7363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14723",
    WardLookupId: "3892",
    VDNumber: "76340066",
    RegisteredPopulation: "991",
    VotingStationName: "MANZEMBA PRIMARY SCHOOL",
    Address: "BULUNI VILLAGE  MANZEMBA  MPHAPHULI",
    Latitude: "-22.8244",
    Longitude: "30.758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14724",
    WardLookupId: "3892",
    VDNumber: "76340077",
    RegisteredPopulation: "1505",
    VotingStationName: "MUTSHETSHE PRIMARY SCHOOL",
    Address: "MUTSHETSHE VILLAGE  MUTSHETSHE  MPHAPHULI",
    Latitude: "-22.7985",
    Longitude: "30.7749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14725",
    WardLookupId: "3892",
    VDNumber: "76340998",
    RegisteredPopulation: "862",
    VotingStationName: "TSHAULU CLINIC",
    Address: "TSHAULU  MPHAPHULI ",
    Latitude: "-22.8046",
    Longitude: "30.753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14726",
    WardLookupId: "3893",
    VDNumber: "76340011",
    RegisteredPopulation: "1172",
    VotingStationName: "FUNZWANI SECONDARY SCHOOL",
    Address: "TSHIDZINI VILLAGE  TSHIDZINI  MPHAPHULI",
    Latitude: "-22.8394",
    Longitude: "30.6734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14727",
    WardLookupId: "3893",
    VDNumber: "76340022",
    RegisteredPopulation: "1581",
    VotingStationName: "MPANDELI SECONDARY SCHOOL",
    Address: "TSHIFUDI VILLAGE  TSHIFUDI  MPHAPHULI",
    Latitude: "-22.8126",
    Longitude: "30.6991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14728",
    WardLookupId: "3893",
    VDNumber: "76340167",
    RegisteredPopulation: "286",
    VotingStationName: "MAHUNGUWI PRIMARY SCHOOL",
    Address: "MAHUNGUWI VILLAGE  MPHAPHULI",
    Latitude: "-22.7785",
    Longitude: "30.6494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14729",
    WardLookupId: "3893",
    VDNumber: "76340381",
    RegisteredPopulation: "430",
    VotingStationName: "TSWERA PRIMARY SCHOOL",
    Address: "TSWERA VILLAGE  MPHAPHULI",
    Latitude: "-22.7562",
    Longitude: "30.6108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14730",
    WardLookupId: "3893",
    VDNumber: "76341023",
    RegisteredPopulation: "573",
    VotingStationName: "DENZHE PRIMARY SCHOOL",
    Address: "TSHIDZINI  MPHAPHULI ",
    Latitude: "-22.8288",
    Longitude: "30.6913",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14731",
    WardLookupId: "3893",
    VDNumber: "76341034",
    RegisteredPopulation: "467",
    VotingStationName: "TSHAMUTSHEDZI PRIMARY SCHOOL",
    Address: "TSHAMUTSHEDZI  MPHAPHULI",
    Latitude: "-22.8261",
    Longitude: "30.6577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14732",
    WardLookupId: "3893",
    VDNumber: "76341113",
    RegisteredPopulation: "498",
    VotingStationName: "TENT AT GONELA SCHOOL",
    Address: "TSHIFUDI  MPHAPHULI",
    Latitude: "-22.8164",
    Longitude: "30.7062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14733",
    WardLookupId: "3894",
    VDNumber: "76100015",
    RegisteredPopulation: "1657",
    VotingStationName: "MATANGARI PRIMARY SCHOOL",
    Address: "MATANGARI  THOHOYANDOU  VHEMBE",
    Latitude: "-22.8023",
    Longitude: "30.5377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14734",
    WardLookupId: "3894",
    VDNumber: "76100150",
    RegisteredPopulation: "827",
    VotingStationName: "SILEMALE SECONDARY SCHOOL",
    Address: "NO STREET NAMES  KHUBVI  VHEMBE",
    Latitude: "-22.8142",
    Longitude: "30.5479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14735",
    WardLookupId: "3894",
    VDNumber: "76100958",
    RegisteredPopulation: "1364",
    VotingStationName: "LUNANGWE SECONDARY SCHOOL",
    Address: "MIANZWI  TSHIVHASE",
    Latitude: "-22.8064",
    Longitude: "30.4911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14736",
    WardLookupId: "3894",
    VDNumber: "76101128",
    RegisteredPopulation: "1186",
    VotingStationName: "JIM MASINDI SECONDARY SCHOOL",
    Address: "THULAMELA   ",
    Latitude: "-22.8054",
    Longitude: "30.5554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14737",
    WardLookupId: "3894",
    VDNumber: "76101364",
    RegisteredPopulation: "537",
    VotingStationName: "NDIDIVHANI PRIMARY SCHOOL",
    Address: "MATANGARI  TSHIVHASE",
    Latitude: "-22.7874",
    Longitude: "30.5276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14738",
    WardLookupId: "3894",
    VDNumber: "76340268",
    RegisteredPopulation: "685",
    VotingStationName: "RAVHURA PRIMARY SCHOOL",
    Address: "SHADANI VILLAGE  MAKONDE  TSHIVHASE",
    Latitude: "-22.7831",
    Longitude: "30.5613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14739",
    WardLookupId: "3895",
    VDNumber: "76100071",
    RegisteredPopulation: "2093",
    VotingStationName: "KHUBVI PRIMARY SCHOOL",
    Address: "KHUBVI  TSHIVHASE",
    Latitude: "-22.8296",
    Longitude: "30.556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14740",
    WardLookupId: "3895",
    VDNumber: "76100093",
    RegisteredPopulation: "834",
    VotingStationName: "TSHISELUSELU PRIMARY SCHOOL",
    Address: "DAMANI  TSHIVHASE",
    Latitude: "-22.8407",
    Longitude: "30.5363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14741",
    WardLookupId: "3895",
    VDNumber: "76101049",
    RegisteredPopulation: "1187",
    VotingStationName: "MAKHUVHA PRIMARY SCHOOL",
    Address: "MAKHUVHA  TSHIVHASE",
    Latitude: "-22.8718",
    Longitude: "30.5082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14742",
    WardLookupId: "3895",
    VDNumber: "76101083",
    RegisteredPopulation: "788",
    VotingStationName: "GILBERT NDANGANENI PRIMARY SCHOOL",
    Address: "KHUBVI  TSHIVHASE",
    Latitude: "-22.8384",
    Longitude: "30.5671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14743",
    WardLookupId: "3895",
    VDNumber: "76101375",
    RegisteredPopulation: "453",
    VotingStationName: "TODAYS NATION CENTRE",
    Address: "KHUBVI VILLAGE  KHUBVI  TSHIVHASE",
    Latitude: "-22.8262",
    Longitude: "30.5568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14744",
    WardLookupId: "3895",
    VDNumber: "76101386",
    RegisteredPopulation: "767",
    VotingStationName: "MUHUYU PRIMARY SCHOOL",
    Address: "MUHUYU  TSHIVHASE",
    Latitude: "-22.8218",
    Longitude: "30.5092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14745",
    WardLookupId: "3895",
    VDNumber: "76101544",
    RegisteredPopulation: "332",
    VotingStationName: "DAMANI COMMUNITY CRECH",
    Address: "DAMANI  TSHIVHASE",
    Latitude: "-22.8344",
    Longitude: "30.5148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14746",
    WardLookupId: "3896",
    VDNumber: "76100048",
    RegisteredPopulation: "1320",
    VotingStationName: "TSHIVHILWI PRIMARY SCHOOL",
    Address: "TSHIVHILWI  TSHIVHASE",
    Latitude: "-22.8437",
    Longitude: "30.6315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14747",
    WardLookupId: "3896",
    VDNumber: "76100149",
    RegisteredPopulation: "1439",
    VotingStationName: "MAKONDE PRIMARY SCHOOL",
    Address: "MAKONDE  TSHIVHASE",
    Latitude: "-22.8036",
    Longitude: "30.57",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14748",
    WardLookupId: "3896",
    VDNumber: "76100161",
    RegisteredPopulation: "736",
    VotingStationName: "TSHIKAMBE PRIMARY SCHOOL",
    Address: "TSHIKAMBE VILLAGE  TSHIKMBE  MPHAPHULI",
    Latitude: "-22.8474",
    Longitude: "30.6564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14749",
    WardLookupId: "3896",
    VDNumber: "76100734",
    RegisteredPopulation: "1128",
    VotingStationName: "BABABA SECONDARY SCHOOL",
    Address: "LUVHIMBI  HA-LUVHIMBI  TSHIVHASE",
    Latitude: "-22.8153",
    Longitude: "30.6116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14750",
    WardLookupId: "3896",
    VDNumber: "76101409",
    RegisteredPopulation: "587",
    VotingStationName: "TENT AT LONDOLANI SCHOOL",
    Address: "TSHIVHILWI  MPHAPHULI",
    Latitude: "-22.8504",
    Longitude: "30.623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14751",
    WardLookupId: "3896",
    VDNumber: "76101454",
    RegisteredPopulation: "552",
    VotingStationName: "U DRIVE DRIVING SCHOOL",
    Address: "MAKONDE VILLAGE  MAKONDE  TSHIVHASE",
    Latitude: "-22.8243",
    Longitude: "30.5648",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14752",
    WardLookupId: "3897",
    VDNumber: "76100172",
    RegisteredPopulation: "1267",
    VotingStationName: "MUKULA SECONDARY SCHOOL",
    Address: "MUKULA  THOHOYANDOU  VHEMBE",
    Latitude: "-22.8629",
    Longitude: "30.5756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14753",
    WardLookupId: "3897",
    VDNumber: "76100194",
    RegisteredPopulation: "1715",
    VotingStationName: "TSHIDIMBINI SECONDARY SCHOOL",
    Address: "TSHIDIMBINI VILLAGE  TSHIDIMBINI  TSHIVHASE",
    Latitude: "-22.872",
    Longitude: "30.5411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14754",
    WardLookupId: "3897",
    VDNumber: "76100745",
    RegisteredPopulation: "1277",
    VotingStationName: "MPHATHELE PRIMARY SCHOOL",
    Address: "MUKULA  TSHIVHASE",
    Latitude: "-22.865",
    Longitude: "30.5623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14755",
    WardLookupId: "3897",
    VDNumber: "76100756",
    RegisteredPopulation: "444",
    VotingStationName: "MUTAVHE PRIMARY SCHOOL",
    Address: "MUKULA    TSHIVHASE",
    Latitude: "-22.8577",
    Longitude: "30.612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14756",
    WardLookupId: "3897",
    VDNumber: "76100981",
    RegisteredPopulation: "1099",
    VotingStationName: "RAMUSHASHA PRIMARY SCHOOL",
    Address: "MUKULA VILLAGE  MUKULA  TSHIVHASE",
    Latitude: "-22.8608",
    Longitude: "30.5895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14757",
    WardLookupId: "3897",
    VDNumber: "76101555",
    RegisteredPopulation: "259",
    VotingStationName: "GUMANI TECHNICAL SCHOOL",
    Address: "VONDWE  TSHIVHASE",
    Latitude: "-22.8799",
    Longitude: "30.5471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14758",
    WardLookupId: "3898",
    VDNumber: "76100217",
    RegisteredPopulation: "914",
    VotingStationName: "NGUDZA PRIMARY SCHOOL",
    Address: "NGUDZA  TSHIVHASE",
    Latitude: "-22.9025",
    Longitude: "30.5212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14759",
    WardLookupId: "3898",
    VDNumber: "76100228",
    RegisteredPopulation: "1430",
    VotingStationName: "DZINGAHE PRIMARY SCHOOL",
    Address: "DZINGAHE VILLAGE  DZINGAHE  TSHIVHASE",
    Latitude: "-22.9113",
    Longitude: "30.5207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14760",
    WardLookupId: "3898",
    VDNumber: "76100947",
    RegisteredPopulation: "1456",
    VotingStationName: "KHADZINGE SECONDARY SCHOOL",
    Address: "HA MAKHUVHA  MAKHUVHA  TSHIVHASE",
    Latitude: "-22.8775",
    Longitude: "30.5195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14761",
    WardLookupId: "3898",
    VDNumber: "76101005",
    RegisteredPopulation: "503",
    VotingStationName: "RAMUGONDO SECONDARY SCHOOL",
    Address: "NGUDZA  NGUDZA VILLAGE  TSHIVHASE",
    Latitude: "-22.9071",
    Longitude: "30.4945",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14762",
    WardLookupId: "3898",
    VDNumber: "76101320",
    RegisteredPopulation: "1078",
    VotingStationName: "VONDWE PRIMARY  SCHOOL",
    Address: "VONDWE  TSHIVHASE",
    Latitude: "-22.8768",
    Longitude: "30.541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14763",
    WardLookupId: "3898",
    VDNumber: "76101331",
    RegisteredPopulation: "811",
    VotingStationName: "MUTSHALINGA PRIMARY SCHOOL",
    Address: "VONDWE  TSHIVHASE  ",
    Latitude: "-22.8883",
    Longitude: "30.5242",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14764",
    WardLookupId: "3899",
    VDNumber: "76340325",
    RegisteredPopulation: "631",
    VotingStationName: "RAMBUDA TRIBAL COUNCIL",
    Address: "RAMBUDA CHIEFS KRAAL  HA RAMMBUDA  RAMBUDA",
    Latitude: "-22.7933",
    Longitude: "30.4064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14765",
    WardLookupId: "3899",
    VDNumber: "76340336",
    RegisteredPopulation: "680",
    VotingStationName: "RAMBUDA SECONDARY SCHOOL",
    Address: "RAMBUDA CHIEF KRAAL  RAMMBUDA  RAMMBUDA",
    Latitude: "-22.7871",
    Longitude: "30.4526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14766",
    WardLookupId: "3899",
    VDNumber: "76340594",
    RegisteredPopulation: "1468",
    VotingStationName: "TSHANDAMA PRIMARY SCHOOL",
    Address: "TSHANDAMA  THENGWE",
    Latitude: "-22.757",
    Longitude: "30.5037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14767",
    WardLookupId: "3899",
    VDNumber: "76340729",
    RegisteredPopulation: "891",
    VotingStationName: "TSHAVHADINDA PRIMARY SCHOOL",
    Address: "DZIMAULI HEADKRAAL  DZIMAULI   DZIMAULI",
    Latitude: "-22.7943",
    Longitude: "30.4304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14768",
    WardLookupId: "3899",
    VDNumber: "76340741",
    RegisteredPopulation: "776",
    VotingStationName: "PILE PRIMARY SCHOOL",
    Address: "PILE  RAMMBUDA",
    Latitude: "-22.7767",
    Longitude: "30.4696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14769",
    WardLookupId: "3899",
    VDNumber: "76340954",
    RegisteredPopulation: "527",
    VotingStationName: "TSHAPASHA PRIMARY SCHOOL",
    Address: "TSHAPASHA  TSHAPASHA VILLAGE  RAMMBUDA ",
    Latitude: "-22.7822",
    Longitude: "30.4609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14770",
    WardLookupId: "3899",
    VDNumber: "76341124",
    RegisteredPopulation: "232",
    VotingStationName: "TSHANDAMA ARTS AND CULTURE",
    Address: "TSHANDAMA  THENGWE",
    Latitude: "-22.76",
    Longitude: "30.5156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14771",
    WardLookupId: "3899",
    VDNumber: "76341225",
    RegisteredPopulation: "402",
    VotingStationName: "MBONENI PIMARY SCHOOL",
    Address: "TSHIBVUMO  RAMMBUDA",
    Latitude: "-22.7834",
    Longitude: "30.4436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14772",
    WardLookupId: "3900",
    VDNumber: "76100239",
    RegisteredPopulation: "682",
    VotingStationName: "DZWABONI SECONDARY SCHOOL",
    Address: "TSHIFIFI  MPHAPHULI",
    Latitude: "-22.9306",
    Longitude: "30.54",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14773",
    WardLookupId: "3900",
    VDNumber: "76100600",
    RegisteredPopulation: "642",
    VotingStationName: "MBAHE PRIMARY SCHOOL",
    Address: "NO STREET NAME  MBAHE  VHEMBE",
    Latitude: "-22.8947",
    Longitude: "30.5931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14774",
    WardLookupId: "3900",
    VDNumber: "76100767",
    RegisteredPopulation: "1200",
    VotingStationName: "LUTANDALE PRIMARY SCHOOL",
    Address: "LUFULE  LUFULE VILLAGE  MPHAPHULI",
    Latitude: "-22.9424",
    Longitude: "30.5045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14775",
    WardLookupId: "3900",
    VDNumber: "76101038",
    RegisteredPopulation: "646",
    VotingStationName: "J.R. TSHIKALANGE PRIMARY SCHOOL",
    Address: "BUNZHE VILLAGE  TSHIFIFI BUNZHE VILLAGE  MPHAPHULI",
    Latitude: "-22.9435",
    Longitude: "30.5368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14776",
    WardLookupId: "3900",
    VDNumber: "76101117",
    RegisteredPopulation: "914",
    VotingStationName: "TSHIVHULANI PRIMARY SCHOOL",
    Address: "TSHIVHULANI VILLAGE  TSHIVHULANI  MPHAPHULI",
    Latitude: "-22.9302",
    Longitude: "30.4937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14777",
    WardLookupId: "3900",
    VDNumber: "76101207",
    RegisteredPopulation: "413",
    VotingStationName: "MULAMULI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  TSHIFIFI/VHUTSAVHA  VHEMBE  ",
    Latitude: "-22.8865",
    Longitude: "30.5981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14778",
    WardLookupId: "3900",
    VDNumber: "76101218",
    RegisteredPopulation: "776",
    VotingStationName: "NEW COVENET CHURCH",
    Address: "NO STREET ADDRESS AVAILABLE  SIAMBE/GOLOKWE  VHEMBE  ",
    Latitude: "-22.924",
    Longitude: "30.5547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14779",
    WardLookupId: "3900",
    VDNumber: "76101229",
    RegisteredPopulation: "1193",
    VotingStationName: "SIAMBE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  SIBASA  VHEMBE  ",
    Latitude: "-22.935",
    Longitude: "30.521",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14780",
    WardLookupId: "3900",
    VDNumber: "76101566",
    RegisteredPopulation: "159",
    VotingStationName: "TSHITANINI BRIDE OF CHRIST CHURCH",
    Address: "TSHITANINI  MPHAPHULI",
    Latitude: "-22.906",
    Longitude: "30.5561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14781",
    WardLookupId: "3901",
    VDNumber: "76100059",
    RegisteredPopulation: "2194",
    VotingStationName: "MBALENI PRIMARY SCHOOL",
    Address: "MBALENI  MPHAPHULI",
    Latitude: "-22.9479",
    Longitude: "30.4934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14782",
    WardLookupId: "3901",
    VDNumber: "76100903",
    RegisteredPopulation: "1263",
    VotingStationName: "TSHIKEVHA TENT",
    Address: "THOHOYANDOU  THOHONDOU   MPHAPHULI",
    Latitude: "-22.9617",
    Longitude: "30.489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14783",
    WardLookupId: "3901",
    VDNumber: "76100914",
    RegisteredPopulation: "1025",
    VotingStationName: "MAGIDI PRIMARY -SCHOOL",
    Address: "MAGIDI  MAGIDI VILLAGE  MPHAPHULI",
    Latitude: "-22.955",
    Longitude: "30.4789",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14784",
    WardLookupId: "3901",
    VDNumber: "76101241",
    RegisteredPopulation: "1065",
    VotingStationName: "MPHAPHULI SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MAKWARELA/MBALENI  VHEMBE  ",
    Latitude: "-22.948",
    Longitude: "30.4815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14785",
    WardLookupId: "3902",
    VDNumber: "76100330",
    RegisteredPopulation: "1175",
    VotingStationName: "THOHOYANDOU SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  P WEST  VHEMBE",
    Latitude: "-22.9785",
    Longitude: "30.4543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14786",
    WardLookupId: "3902",
    VDNumber: "76100363",
    RegisteredPopulation: "1538",
    VotingStationName: "MAHWASANE PRIMARY SCHOOL",
    Address: "NO STREET NAME  BLOCK J  VHEMBE DISTRICT",
    Latitude: "-22.9923",
    Longitude: "30.4502",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14787",
    WardLookupId: "3902",
    VDNumber: "76100992",
    RegisteredPopulation: "890",
    VotingStationName: "MULEDANE LUTHERAN CHURCH",
    Address: "MULEDANE  THOHOYANDOU  VHEMBE",
    Latitude: "-22.9983",
    Longitude: "30.4573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14788",
    WardLookupId: "3902",
    VDNumber: "76101162",
    RegisteredPopulation: "817",
    VotingStationName: "MANIINI THUSANO RURAL CRECHE",
    Address: "NO STREET ADDRESS AVAILABLE  MANIINI  VHEMBE  ",
    Latitude: "-22.989",
    Longitude: "30.4728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14789",
    WardLookupId: "3902",
    VDNumber: "76101533",
    RegisteredPopulation: "700",
    VotingStationName: "TSHILUVHI PRIMARY SCHOOL",
    Address: "R524  THULAMELA  THULAMELA",
    Latitude: "-22.9793",
    Longitude: "30.4603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14790",
    WardLookupId: "3903",
    VDNumber: "76100037",
    RegisteredPopulation: "1138",
    VotingStationName: "TSHIKHUDINI PRIMARY SCHOOL",
    Address: "NO STREET NAMES  TSHIKHUDINI  THOHOYANDOU",
    Latitude: "-22.9587",
    Longitude: "30.536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14791",
    WardLookupId: "3903",
    VDNumber: "76100240",
    RegisteredPopulation: "1030",
    VotingStationName: "TSHILALA SECONDARY SCHOOL",
    Address: "NO STREET NAME  DUMASI  VHEMBE DISTRICT",
    Latitude: "-22.9586",
    Longitude: "30.5552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14792",
    WardLookupId: "3903",
    VDNumber: "76100611",
    RegisteredPopulation: "610",
    VotingStationName: "MUTOTI PRIMARY SCHOOL",
    Address: "MUTOTI  MUTOTI VILLAGE  THOHOYANDOU",
    Latitude: "-22.9613",
    Longitude: "30.5943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14793",
    WardLookupId: "3903",
    VDNumber: "76100622",
    RegisteredPopulation: "456",
    VotingStationName: "MANGONDI PRIMARY SCHOOL",
    Address: "NO STREET NAME  MANGONDI  VHEMBE",
    Latitude: "-22.9397",
    Longitude: "30.6137",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14794",
    WardLookupId: "3903",
    VDNumber: "76101173",
    RegisteredPopulation: "848",
    VotingStationName: "LUKWARANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BUDELI  VHEMBE  ",
    Latitude: "-22.9731",
    Longitude: "30.5676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14795",
    WardLookupId: "3903",
    VDNumber: "76101184",
    RegisteredPopulation: "640",
    VotingStationName: "TSHILIVHO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  TSHILIVHO  VHEMBE  ",
    Latitude: "-22.9574",
    Longitude: "30.5642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14796",
    WardLookupId: "3903",
    VDNumber: "76101195",
    RegisteredPopulation: "522",
    VotingStationName: "LUKHAIMANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  TSHAMUTILIKWA  VHEMBE  ",
    Latitude: "-22.9257",
    Longitude: "30.5984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14797",
    WardLookupId: "3903",
    VDNumber: "76101410",
    RegisteredPopulation: "776",
    VotingStationName: "NTSUMBEDZENI PRIMARY SCHOOL",
    Address: "MAKHUVHA   THOHOYANDOU  THULAMELA",
    Latitude: "-22.9477",
    Longitude: "30.5872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14798",
    WardLookupId: "3903",
    VDNumber: "76101511",
    RegisteredPopulation: "408",
    VotingStationName: "GOLE SECONDARY SCHOOL",
    Address: "VILLAGE  THULAMELA  TRIBAL AUTHORITY",
    Latitude: "-22.9461",
    Longitude: "30.6129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14799",
    WardLookupId: "3904",
    VDNumber: "76100251",
    RegisteredPopulation: "1373",
    VotingStationName: "DZIVHANI PRIMARY SCHOOL",
    Address: "MPHEGO  HA MPHEGO  HA THUKHUTHA",
    Latitude: "-22.9686",
    Longitude: "30.524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14800",
    WardLookupId: "3904",
    VDNumber: "76100262",
    RegisteredPopulation: "1291",
    VotingStationName: "TSHIULUNGOMA PRIMARY SCHOOL",
    Address: "TSHIULUNGOMA  HA THUKHUTHA",
    Latitude: "-22.9855",
    Longitude: "30.5027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14801",
    WardLookupId: "3904",
    VDNumber: "76100778",
    RegisteredPopulation: "1766",
    VotingStationName: "ANGLICAN CHURCH LUFULE",
    Address: "LUFULE 02  HA THUKHUTHA",
    Latitude: "-22.9601",
    Longitude: "30.5195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14802",
    WardLookupId: "3904",
    VDNumber: "76101151",
    RegisteredPopulation: "629",
    VotingStationName: "MAKANYU PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MPHEGO  VHEMBE  ",
    Latitude: "-22.9825",
    Longitude: "30.516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14803",
    WardLookupId: "3904",
    VDNumber: "76101421",
    RegisteredPopulation: "651",
    VotingStationName: "MAKWARELA EXTENTION - 3 (TENT)",
    Address: "MAKWARELA EXTENTION 03  MILUWANI",
    Latitude: "-22.9608",
    Longitude: "30.4969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14804",
    WardLookupId: "3904",
    VDNumber: "76101487",
    RegisteredPopulation: "980",
    VotingStationName: "MURATHO PRIMARY SCHOOL",
    Address: "MURATHO  HA THUKHUTHA",
    Latitude: "-22.9732",
    Longitude: "30.5068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14805",
    WardLookupId: "3905",
    VDNumber: "76100284",
    RegisteredPopulation: "764",
    VotingStationName: "THOHOYANDOU APOSTOLIC FAITH MISSION",
    Address: "THOHOYANDOU UNIT G  THULAMELA  THOHOYANDOU",
    Latitude: "-22.9734",
    Longitude: "30.4767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14806",
    WardLookupId: "3905",
    VDNumber: "76100295",
    RegisteredPopulation: "686",
    VotingStationName: "THOHOYANDOU COMMUNITY HALL",
    Address: "THOHOYANDOU P.EAST  THOHOYANDOU P EAST  MPHAPHULI",
    Latitude: "-22.977",
    Longitude: "30.4587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14807",
    WardLookupId: "3905",
    VDNumber: "76100329",
    RegisteredPopulation: "1396",
    VotingStationName: "TSHISHONGA PRIMARY SCHOOL",
    Address: "GOLGOTHA VILLAGE  GOLGOTHA  MPHAPHULI",
    Latitude: "-22.9659",
    Longitude: "30.4524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14808",
    WardLookupId: "3905",
    VDNumber: "76100497",
    RegisteredPopulation: "1228",
    VotingStationName: "THOHOYANDOU TECHNICAL SCHOOL",
    Address: "BLOCK F  THOHOYANDOU BLOCK F  MPHAPHULI",
    Latitude: "-22.9689",
    Longitude: "30.47",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14809",
    WardLookupId: "3905",
    VDNumber: "76100879",
    RegisteredPopulation: "745",
    VotingStationName: "TSHEDZA COMPREHENSIVE PRIMARY SCHOOL",
    Address: "THOHOYANDOU BLOCK G  HA MPHAPHULI",
    Latitude: "-22.9678",
    Longitude: "30.4831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14810",
    WardLookupId: "3905",
    VDNumber: "76100880",
    RegisteredPopulation: "355",
    VotingStationName: "CHRISTIAN FELLOWSHIP  CHURCH  UNIT D",
    Address: "THOHOYANDOU UNIT D  HA MPHAPHULI",
    Latitude: "-22.9613",
    Longitude: "30.4707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14811",
    WardLookupId: "3906",
    VDNumber: "76100396",
    RegisteredPopulation: "1330",
    VotingStationName: "MAUNGANI PRIMARY SCHOOL",
    Address: "MAUNGANI  MAUNGANI VILLAGE  MAUNGANI",
    Latitude: "-22.9738",
    Longitude: "30.4278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14812",
    WardLookupId: "3906",
    VDNumber: "76100857",
    RegisteredPopulation: "1045",
    VotingStationName: "RALSON TSHINANNE SECONDARY SCHOOL",
    Address: "NGOVHELA VILLAGE  HA TSHIVHASE",
    Latitude: "-22.9595",
    Longitude: "30.4365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14813",
    WardLookupId: "3906",
    VDNumber: "76101252",
    RegisteredPopulation: "1022",
    VotingStationName: "TVS MAUNGANI TENT",
    Address: "MAUNGANI  THOHOYANDOU",
    Latitude: "-22.981",
    Longitude: "30.423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14814",
    WardLookupId: "3906",
    VDNumber: "76101500",
    RegisteredPopulation: "547",
    VotingStationName: "TENT AT MARUDE",
    Address: "MARUDE VILLAGE  MARUDE  THOHOYANDOU",
    Latitude: "-22.971",
    Longitude: "30.439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14815",
    WardLookupId: "3906",
    VDNumber: "76101522",
    RegisteredPopulation: "3991",
    VotingStationName: "VENDA UNIVERSITY",
    Address: "MARUDE VILLAGE  MARUDE  THOHOYANDOU",
    Latitude: "-22.9772",
    Longitude: "30.4432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14816",
    WardLookupId: "3907",
    VDNumber: "76100307",
    RegisteredPopulation: "1242",
    VotingStationName: "CHRIST THE KING PRIMARY SCHOOL",
    Address: "MAKWARELA  SIBASA",
    Latitude: "-22.943",
    Longitude: "30.4898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14817",
    WardLookupId: "3907",
    VDNumber: "76100318",
    RegisteredPopulation: "1513",
    VotingStationName: "PHASWANA SECONDARY SCHOOL",
    Address: "SIBASA  ",
    Latitude: "-22.9445",
    Longitude: "30.473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14818",
    WardLookupId: "3907",
    VDNumber: "76100891",
    RegisteredPopulation: "874",
    VotingStationName: "MPHAPHULI TRADITIONAL COUNCIL",
    Address: "SIBASA  ",
    Latitude: "-22.9303",
    Longitude: "30.4688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14819",
    WardLookupId: "3907",
    VDNumber: "76101432",
    RegisteredPopulation: "520",
    VotingStationName: "NTHETSHELESENI SECONDARY SCHOOL",
    Address: "THULAMELA  ",
    Latitude: "-22.9354",
    Longitude: "30.4939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14820",
    WardLookupId: "3907",
    VDNumber: "76101443",
    RegisteredPopulation: "396",
    VotingStationName: "MBILWI BAPTIST CHURCH",
    Address: "MBILWI  MPHAPHULI  MPHAPHULI",
    Latitude: "-22.9371",
    Longitude: "30.4718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14821",
    WardLookupId: "3908",
    VDNumber: "76100408",
    RegisteredPopulation: "1543",
    VotingStationName: "LIGEGE SECONDARY SCHOOL",
    Address: "DUTHUNI  DUTHUNI VILLAGE  HA TSHIVHASE",
    Latitude: "-22.9749",
    Longitude: "30.3989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14822",
    WardLookupId: "3908",
    VDNumber: "76100431",
    RegisteredPopulation: "1463",
    VotingStationName: "DENGA TSHIVHASE SECONDARY SCHOOL",
    Address: "NO STREET NAME  PHIPHIDI VILLAGE  VHEMBE DISTRICT",
    Latitude: "-22.9551",
    Longitude: "30.381",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14823",
    WardLookupId: "3908",
    VDNumber: "76100925",
    RegisteredPopulation: "1929",
    VotingStationName: "GUVHUKUVHU SECONDARY SCHOOL",
    Address: "MADAMALALA VILLAGE  HA MADAMALALA VILLAGE  PHIPHIDI",
    Latitude: "-22.956",
    Longitude: "30.415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14824",
    WardLookupId: "3908",
    VDNumber: "76101274",
    RegisteredPopulation: "505",
    VotingStationName: "BRIDE OF CHRIST FELLOWSHIP CHURCH NDONDOLA",
    Address: "PHIPHIDI NDONDOLA  NDONDOLA  PHIPHIDI",
    Latitude: "-22.9692",
    Longitude: "30.4041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14825",
    WardLookupId: "3908",
    VDNumber: "76101577",
    RegisteredPopulation: "439",
    VotingStationName: "RATSHITANGA PRIMARY SCHOOL",
    Address: "NGULUMBI  NGWENANI YA HA THEMELI",
    Latitude: "-22.9473",
    Longitude: "30.4086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14826",
    WardLookupId: "3909",
    VDNumber: "76100127",
    RegisteredPopulation: "1217",
    VotingStationName: "PFANO PRIMARY SCHOOL",
    Address: "HA MAPHOLI/NGWENANI  NGWENANI YA HA MAPHOLI  HA TSHIVHASE",
    Latitude: "-22.8946",
    Longitude: "30.4596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14827",
    WardLookupId: "3909",
    VDNumber: "76100936",
    RegisteredPopulation: "1745",
    VotingStationName: "MUHUYUWATHOMBA SECONDARY SCHOOL",
    Address: "TSHITEREKE  THOHOYANDOU  VHEMBE",
    Latitude: "-22.8835",
    Longitude: "30.509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14828",
    WardLookupId: "3909",
    VDNumber: "76100969",
    RegisteredPopulation: "1787",
    VotingStationName: "ZWITHUZWAVHUDI  SECONDARY SCHOOL",
    Address: "GONDENI  HA TSHIVHASE",
    Latitude: "-22.9175",
    Longitude: "30.4354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14829",
    WardLookupId: "3909",
    VDNumber: "76101106",
    RegisteredPopulation: "694",
    VotingStationName: "GOOLDVILLE MISSION SCHOOL",
    Address: "HOSPITAL STREET  THOHOYANDOU  THULAMELA [THOHOYANDOU]",
    Latitude: "-22.8877",
    Longitude: "30.4807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14830",
    WardLookupId: "3910",
    VDNumber: "76100026",
    RegisteredPopulation: "1436",
    VotingStationName: "TSWINGA PRIMARY SCHOOL",
    Address: "TSWINGA  HA MPHAPHULI",
    Latitude: "-23.0266",
    Longitude: "30.4849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14831",
    WardLookupId: "3910",
    VDNumber: "76100790",
    RegisteredPopulation: "343",
    VotingStationName: "MASHAWANA PRIMARY SCHOOL",
    Address: "MASHAWANA  MASHAWANA VILLAGE  HA MPHAPHULI",
    Latitude: "-23.0613",
    Longitude: "30.4707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14832",
    WardLookupId: "3910",
    VDNumber: "76210860",
    RegisteredPopulation: "1312",
    VotingStationName: "DZWERANI SENIOR SCHOOL",
    Address: "DZWERANI  LWAMONDO DZWERANI  LWAMONDO",
    Latitude: "-23.0569",
    Longitude: "30.4251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14833",
    WardLookupId: "3910",
    VDNumber: "76210871",
    RegisteredPopulation: "1460",
    VotingStationName: "MAHEMATSHENA PRIMARY SCHOOL",
    Address: "NO STREET NAME  DZWERANI  VHEMBE DISTRICT",
    Latitude: "-23.0666",
    Longitude: "30.4231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14834",
    WardLookupId: "3910",
    VDNumber: "76211300",
    RegisteredPopulation: "1407",
    VotingStationName: "CENTAURI COMBINED PRIVATE SCHOOL",
    Address: "DZWERANI MAHEMATSHENA  LWAMONDO  LWAMONDO",
    Latitude: "-23.0744",
    Longitude: "30.4082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14835",
    WardLookupId: "3911",
    VDNumber: "76370238",
    RegisteredPopulation: "1670",
    VotingStationName: "TSHIKOMBANI PRIMARY SCHOOL",
    Address: "NO STREET NAME  TSHIKOMBANI VILLAGE  VHEMBE DISTRICT",
    Latitude: "-22.9075",
    Longitude: "30.2195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14836",
    WardLookupId: "3911",
    VDNumber: "76370249",
    RegisteredPopulation: "1069",
    VotingStationName: "SHAVHANI PRIMARY SCHOOL",
    Address: "SHANZHA  DOPENI  DOPENI",
    Latitude: "-22.9375",
    Longitude: "30.2192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14837",
    WardLookupId: "3911",
    VDNumber: "76370250",
    RegisteredPopulation: "1900",
    VotingStationName: "MUTUWAFHETHU PRIMARY SCHOOL",
    Address: "NO STREET NAME  DOPENI VILLAGE  VHEMBE DISTRICT",
    Latitude: "-22.9243",
    Longitude: "30.2081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14838",
    WardLookupId: "3911",
    VDNumber: "76370845",
    RegisteredPopulation: "963",
    VotingStationName: "MANDALA -PRIMARY SCHOOL",
    Address: "THULAMELA  ",
    Latitude: "-22.9168",
    Longitude: "30.2327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14839",
    WardLookupId: "3911",
    VDNumber: "76370913",
    RegisteredPopulation: "912",
    VotingStationName: "TSHIRENZHENI PRIMARY SCHOOL",
    Address: "NO STREET NAME  TSHIRENZHENI VILLAGE  VHEMBE DISTRICT",
    Latitude: "-22.9033",
    Longitude: "30.2065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14840",
    WardLookupId: "3912",
    VDNumber: "76370171",
    RegisteredPopulation: "1494",
    VotingStationName: "TSHITHUTHUNI PRIMARY SCHOOL",
    Address: "TSHITHUTHUNI VILLAGE  THULAMELA  THOHOYANDOU",
    Latitude: "-22.8726",
    Longitude: "30.1972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14841",
    WardLookupId: "3912",
    VDNumber: "76370193",
    RegisteredPopulation: "706",
    VotingStationName: "TSHIHENI PRIMARY SCHOOL",
    Address: "NO STREET NAME  TSHIHENI VILLAGE  VHEMBE DISTRICT",
    Latitude: "-22.8814",
    Longitude: "30.2575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14842",
    WardLookupId: "3912",
    VDNumber: "76370205",
    RegisteredPopulation: "1288",
    VotingStationName: "THONONDA PRIMARY SCHOOL",
    Address: "THONONDA VILLAGE  THULAMELA  THOHOYANDOU",
    Latitude: "-22.8655",
    Longitude: "30.237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14843",
    WardLookupId: "3912",
    VDNumber: "76370227",
    RegisteredPopulation: "617",
    VotingStationName: "MILABONI PRIMARY SCHOOL",
    Address: "NO STREET NAME   MILAMBONI VILLAGE  VHEMBE DISTRICT",
    Latitude: "-22.8877",
    Longitude: "30.2365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14844",
    WardLookupId: "3912",
    VDNumber: "76370924",
    RegisteredPopulation: "560",
    VotingStationName: "RAMASHIA SECONDARY SCHOOL",
    Address: "NO STREET NAME  MUDUNUNGU VILLAGE  VHEMBE DISTRICT",
    Latitude: "-22.8911",
    Longitude: "30.2248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14845",
    WardLookupId: "3912",
    VDNumber: "76370935",
    RegisteredPopulation: "152",
    VotingStationName: "DENZHE MANAME COMMUNITY CRECHE",
    Address: "TSHITHUTHUNI VILLAGE  THULAMELA  TSHIVHASE",
    Latitude: "-22.8439",
    Longitude: "30.2122",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14846",
    WardLookupId: "3912",
    VDNumber: "76371026",
    RegisteredPopulation: "251",
    VotingStationName: "TSHIAVHA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  TSHIAVHA  VHEMBE  ",
    Latitude: "-22.8505",
    Longitude: "30.2759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14847",
    WardLookupId: "3913",
    VDNumber: "76100105",
    RegisteredPopulation: "566",
    VotingStationName: "TSHIDZIVHE PRIMARY SCHOOL",
    Address: "TSHIDZIVHE  TSHIVHASE",
    Latitude: "-22.8492",
    Longitude: "30.389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14848",
    WardLookupId: "3913",
    VDNumber: "76100116",
    RegisteredPopulation: "885",
    VotingStationName: "MUKUMBANI PRIMARY SCHOOL.",
    Address: "MUKUMBANI VILLAGE  MUKUMBANI  TSHIVHASE",
    Latitude: "-22.8983",
    Longitude: "30.411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14849",
    WardLookupId: "3913",
    VDNumber: "76100464",
    RegisteredPopulation: "1386",
    VotingStationName: "MUELEKANYI TSHIVHASE TECHNOLOGY SCHOOL",
    Address: "TSHILAPFENE  ",
    Latitude: "-22.8973",
    Longitude: "30.4379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14850",
    WardLookupId: "3913",
    VDNumber: "76100475",
    RegisteredPopulation: "575",
    VotingStationName: "MAKWARANI PRIMARY SCHOOL.",
    Address: "MAKWARANI VILLAGE  MAKWARANI  TSHIVHASE",
    Latitude: "-22.8585",
    Longitude: "30.4272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14851",
    WardLookupId: "3913",
    VDNumber: "76101016",
    RegisteredPopulation: "348",
    VotingStationName: "TSHILUNGWI PRIMARY SCHOOL",
    Address: "TSHILUNGWI VILLAGE  TSHILUNGWI  TSHIVHASE",
    Latitude: "-22.8529",
    Longitude: "30.3614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14852",
    WardLookupId: "3913",
    VDNumber: "76101027",
    RegisteredPopulation: "473",
    VotingStationName: "MUDZIDZIDZI PRIMARY SCHOOL",
    Address: "MUDZIDZIDZI  MUDZIDZIDZI VILLAGE  TSHIVHASE",
    Latitude: "-22.8394",
    Longitude: "30.418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14853",
    WardLookupId: "3913",
    VDNumber: "76101308",
    RegisteredPopulation: "662",
    VotingStationName: "TSHIVHUNGULULU PRIMARY SCHOOL",
    Address: "TSHIVHUNGULULU  RADALI/TSHIVHUNGULULU  NZHELELE",
    Latitude: "-22.9048",
    Longitude: "30.4224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14854",
    WardLookupId: "3913",
    VDNumber: "76370946",
    RegisteredPopulation: "74",
    VotingStationName: "TSHAROTHA PRIMARY SCHOOL",
    Address: "TSHITANGANI VILLAGE  TSHITANGANI  TSHIVHASE",
    Latitude: "-22.874",
    Longitude: "30.3112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14855",
    WardLookupId: "3914",
    VDNumber: "76100060",
    RegisteredPopulation: "871",
    VotingStationName: "THENZHENI PRIMARY SCHOOL",
    Address: "THENZHENI  MUHUYU",
    Latitude: "-22.8324",
    Longitude: "30.4847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14856",
    WardLookupId: "3914",
    VDNumber: "76100138",
    RegisteredPopulation: "1333",
    VotingStationName: "MBAHELA PRIMARY SCHOOL",
    Address: "MBAHELA  ",
    Latitude: "-22.8103",
    Longitude: "30.4521",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14857",
    WardLookupId: "3914",
    VDNumber: "76100206",
    RegisteredPopulation: "1547",
    VotingStationName: "TSHIVHASE SECONDARY SCHOOL",
    Address: "VHUFULI  TSHIVHASE",
    Latitude: "-22.8832",
    Longitude: "30.4831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14858",
    WardLookupId: "3914",
    VDNumber: "76101296",
    RegisteredPopulation: "761",
    VotingStationName: "KHALILALE PRIMARY SCHOOL",
    Address: "MAVHOLA  TSHIVHASE",
    Latitude: "-22.8831",
    Longitude: "30.4626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14859",
    WardLookupId: "3914",
    VDNumber: "76101342",
    RegisteredPopulation: "628",
    VotingStationName: "MPHIGALALE PRIMARY SCHOOL",
    Address: "VHUFULI  TSHIVHASE",
    Latitude: "-22.8739",
    Longitude: "30.4856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14860",
    WardLookupId: "3914",
    VDNumber: "76101353",
    RegisteredPopulation: "359",
    VotingStationName: "MUTSHENZHENI PRIMARY SCHOOL",
    Address: "MUTSHENZHENI  TSHIVHASE",
    Latitude: "-22.8062",
    Longitude: "30.4392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14861",
    WardLookupId: "3914",
    VDNumber: "76101397",
    RegisteredPopulation: "482",
    VotingStationName: "TSHIPAKO PRIMARY SCHOOL",
    Address: "TSHIPAKO  TSHIVHASE ",
    Latitude: "-22.8408",
    Longitude: "30.4801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14862",
    WardLookupId: "3914",
    VDNumber: "76101588",
    RegisteredPopulation: "120",
    VotingStationName: "TSHIWANI REFORMED CHURCH",
    Address: "TSHIWANI  TSHIVHASE  TSHIPAKO",
    Latitude: "-22.8416",
    Longitude: "30.4532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14863",
    WardLookupId: "3915",
    VDNumber: "76100442",
    RegisteredPopulation: "1085",
    VotingStationName: "MURANGONI PRIMARY SCHOOL",
    Address: "MURANNGONI  MURANGONI VILLAGE  HA TSHIVHASA MUKUMBANI",
    Latitude: "-22.9212",
    Longitude: "30.3765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14864",
    WardLookupId: "3915",
    VDNumber: "76100453",
    RegisteredPopulation: "1735",
    VotingStationName: "NGWENANI PRIMARY SCHOOL",
    Address: "HA THEMELI  NGWENANI YA THEMELI  NGWENANI HA THEMELI",
    Latitude: "-22.9382",
    Longitude: "30.4309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14865",
    WardLookupId: "3915",
    VDNumber: "76100970",
    RegisteredPopulation: "1093",
    VotingStationName: "MATONDONI PRIMARY SCHOOL",
    Address: "MATONDONI  MATONDONI VILLAGE  HA TSHIVHASA",
    Latitude: "-22.9408",
    Longitude: "30.386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14866",
    WardLookupId: "3915",
    VDNumber: "76101061",
    RegisteredPopulation: "1038",
    VotingStationName: "DOVHONI PRIMARY SCHOOL",
    Address: "PHIPHIDI VILLAGE  PHIPHIDI  HA TSHIVHASA MUKUMBANI",
    Latitude: "-22.9513",
    Longitude: "30.3797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14867",
    WardLookupId: "3915",
    VDNumber: "76101319",
    RegisteredPopulation: "837",
    VotingStationName: "TSHIMEDZWA PRIMARY SCHOOL",
    Address: "LUNUNGWI  HA TSHIVHASA ",
    Latitude: "-22.9231",
    Longitude: "30.4206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14868",
    WardLookupId: "3916",
    VDNumber: "76100420",
    RegisteredPopulation: "1673",
    VotingStationName: "MAPATE PRIMARY SCHOOL",
    Address: "MAPATE  DUTHUNI",
    Latitude: "-22.9903",
    Longitude: "30.359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14869",
    WardLookupId: "3916",
    VDNumber: "76210972",
    RegisteredPopulation: "359",
    VotingStationName: "BELEMU PRIMARY SCHOOL",
    Address: "NO STREET NAME  LWAMONDO HA BELEMU  VHEMBE DISTRICT",
    Latitude: "-22.9885",
    Longitude: "30.3412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14870",
    WardLookupId: "3916",
    VDNumber: "76370261",
    RegisteredPopulation: "1539",
    VotingStationName: "PHIRIPHIRI SECONDARY SCHOOL",
    Address: "FONDWE  FONDWE VILLAGE  TSHIVHASA",
    Latitude: "-22.9171",
    Longitude: "30.2582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14871",
    WardLookupId: "3916",
    VDNumber: "76370272",
    RegisteredPopulation: "874",
    VotingStationName: "TSHIVHILIDULU PRIMARY SCHOOL",
    Address: "TSHIVHILIDULU  TSHIVHILIDULU VILLAGE  TSHIVHASA",
    Latitude: "-22.9312",
    Longitude: "30.2433",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14872",
    WardLookupId: "3916",
    VDNumber: "76370485",
    RegisteredPopulation: "1725",
    VotingStationName: "JACK LAVHENGWA SECONDARY SCHOOL",
    Address: "KHALAVHA  KHALAVHA VILLAGE  TSHIVHASA",
    Latitude: "-22.9264",
    Longitude: "30.2744",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14873",
    WardLookupId: "3917",
    VDNumber: "76210905",
    RegisteredPopulation: "1390",
    VotingStationName: "LWAMONDO HIGH SCHOOL",
    Address: "MATHULE  LWAMONDO MATHULE  LWAMONDO",
    Latitude: "-23.0431",
    Longitude: "30.3944",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14874",
    WardLookupId: "3917",
    VDNumber: "76210916",
    RegisteredPopulation: "1082",
    VotingStationName: "DEPT OF AGRICULTURE",
    Address: "KHUMBE  LWAMONDO",
    Latitude: "-23.0375",
    Longitude: "30.3805",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14875",
    WardLookupId: "3917",
    VDNumber: "76210927",
    RegisteredPopulation: "1033",
    VotingStationName: "MUTSHIPISI PRIMARY SCHOOL",
    Address: "ZWAVHAVHILI  VILLAGE  ZWAVHAVHILI - LWAMONDO  LWAMONDO",
    Latitude: "-23.0264",
    Longitude: "30.3963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14876",
    WardLookupId: "3917",
    VDNumber: "76210983",
    RegisteredPopulation: "632",
    VotingStationName: "TSHIFHUMULO PRIMARY SCHOOL",
    Address: "KHUMBE  LWAMONDO KHUMBE  LWAMONDO",
    Latitude: "-23.0484",
    Longitude: "30.3876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14877",
    WardLookupId: "3917",
    VDNumber: "76210994",
    RegisteredPopulation: "387",
    VotingStationName: "DZONDO PRIMARY SCHOOL",
    Address: "TSHIDZETE  LWAMONDO TSHIDZETE  LWAMONDO",
    Latitude: "-23.0535",
    Longitude: "30.382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14878",
    WardLookupId: "3917",
    VDNumber: "76211322",
    RegisteredPopulation: "1328",
    VotingStationName: "MUGAGULI SECONDARY SCHOOL",
    Address: "LWAMONDO  LWAMONDO VILLAGE  THOHOYANDOU",
    Latitude: "-23.0456",
    Longitude: "30.3992",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14879",
    WardLookupId: "3917",
    VDNumber: "76211715",
    RegisteredPopulation: "477",
    VotingStationName: "TSHISHUSHURU TENT",
    Address: "TSHISHUSHURU VILLAGE  TSHISHUSHURU  LWAMONDO",
    Latitude: "-23.0365",
    Longitude: "30.4027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14880",
    WardLookupId: "3918",
    VDNumber: "76210938",
    RegisteredPopulation: "970",
    VotingStationName: "LUVHAIVHAI SECONDARY SCHOOL",
    Address: "MUTANDANI LWAMONDO  MUTANDANI- LWAMONDO  LWAMONDO",
    Latitude: "-23.0173",
    Longitude: "30.3783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14881",
    WardLookupId: "3918",
    VDNumber: "76210949",
    RegisteredPopulation: "1508",
    VotingStationName: "SHONDONI SECONDARY SCHOOL",
    Address: "MATATANI  LWAMONDO MATATANI  LWAMONDO",
    Latitude: "-23.0172",
    Longitude: "30.3604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14882",
    WardLookupId: "3918",
    VDNumber: "76210950",
    RegisteredPopulation: "969",
    VotingStationName: "MAKAKAVHALE SECONDARY SCHOOL",
    Address: "TSHIVHALE  LWAMONDO TSHIVHALE  LWAMONDO",
    Latitude: "-22.9927",
    Longitude: "30.3459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14883",
    WardLookupId: "3918",
    VDNumber: "76210961",
    RegisteredPopulation: "403",
    VotingStationName: "MASAMBELO PRIMARY SCHOOL",
    Address: "TSHIEMA  LWAMONDO TSHIEMA  LWAMONDO",
    Latitude: "-23.0161",
    Longitude: "30.3437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14884",
    WardLookupId: "3918",
    VDNumber: "76211311",
    RegisteredPopulation: "623",
    VotingStationName: "MANGOMANI PRIMARY SCHOOL",
    Address: "MUTANDANI  LWAMONDO",
    Latitude: "-23.0217",
    Longitude: "30.3898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14885",
    WardLookupId: "3918",
    VDNumber: "76211489",
    RegisteredPopulation: "451",
    VotingStationName: "TSHIVHALE LUKAU PRIMARY SCHOOL",
    Address: "NO STREET NAME  THOHOYANDOU  LUKAU VILLAGE",
    Latitude: "-23.0039",
    Longitude: "30.3458",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14886",
    WardLookupId: "3919",
    VDNumber: "76100374",
    RegisteredPopulation: "1761",
    VotingStationName: "TSHISAHULU PRIMARY SCHOOL",
    Address: "TSHISAULU  TSHISAHULU  MAKUMBANE",
    Latitude: "-22.9953",
    Longitude: "30.3993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14887",
    WardLookupId: "3919",
    VDNumber: "76100385",
    RegisteredPopulation: "1944",
    VotingStationName: "LWANDANI SECONDARY SCHOOL",
    Address: "TSHISAULU  TSHISAHULU VILLAGE  MAKUMBANE",
    Latitude: "-22.9903",
    Longitude: "30.3908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14888",
    WardLookupId: "3919",
    VDNumber: "76100846",
    RegisteredPopulation: "1157",
    VotingStationName: "RATSHIEDANE PRIMARY SCHOOL",
    Address: "DUTHUNI  HA TSHIVHASA",
    Latitude: "-22.9762",
    Longitude: "30.3943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14889",
    WardLookupId: "3919",
    VDNumber: "76101263",
    RegisteredPopulation: "528",
    VotingStationName: "TSHIHE PRIMARY SCHOOL.",
    Address: "DUTHUNI VILLAGE  DUTHUNI  HA TSHIVHASA",
    Latitude: "-22.9617",
    Longitude: "30.3738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14890",
    WardLookupId: "3919",
    VDNumber: "76211298",
    RegisteredPopulation: "788",
    VotingStationName: "MAKUMBANI PRIMARY SCHOOL",
    Address: "TSHISAULU VILLAGE  TSHISAULU  MAKUMBANI",
    Latitude: "-22.9966",
    Longitude: "30.4085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14891",
    WardLookupId: "3920",
    VDNumber: "76100341",
    RegisteredPopulation: "1292",
    VotingStationName: "MANAMANI PRIMARY SCHOOL",
    Address: "NO STREET NAME  MANAMANI  MANAMANI",
    Latitude: "-23.043",
    Longitude: "30.436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14892",
    WardLookupId: "3920",
    VDNumber: "76100802",
    RegisteredPopulation: "1733",
    VotingStationName: "DZINDI PRIMARY SCHOOL",
    Address: "MATIENI  SHAYANDIMA  MAKUMBANE",
    Latitude: "-23.0244",
    Longitude: "30.4295",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14893",
    WardLookupId: "3920",
    VDNumber: "76100813",
    RegisteredPopulation: "968",
    VotingStationName: "JIM TSHIVHONELO PRIMARY SCHOOL",
    Address: "MULEDANE  MULEDANE VILLAGE  MULEDANE",
    Latitude: "-23.0046",
    Longitude: "30.444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14894",
    WardLookupId: "3920",
    VDNumber: "76100824",
    RegisteredPopulation: "1119",
    VotingStationName: "BLOCK J ASSEMBLES OF GOD",
    Address: "MULEDANE  ",
    Latitude: "-23.0016",
    Longitude: "30.4594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14895",
    WardLookupId: "3920",
    VDNumber: "76101498",
    RegisteredPopulation: "827",
    VotingStationName: "TSWINGA CLINIC",
    Address: "TSWINGA  ",
    Latitude: "-23.0212",
    Longitude: "30.4755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14896",
    WardLookupId: "3921",
    VDNumber: "76100510",
    RegisteredPopulation: "1424",
    VotingStationName: "TSHIDUMBI PRIMARY SCHOOL",
    Address: "SHAYANDIMA  MAKUMBANE",
    Latitude: "-23.0003",
    Longitude: "30.4257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14897",
    WardLookupId: "3921",
    VDNumber: "76100521",
    RegisteredPopulation: "1009",
    VotingStationName: "TSHISAULU ITSANI CRECHE",
    Address: "ITSANI  MAKUMBANE",
    Latitude: "-22.9995",
    Longitude: "30.4099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14898",
    WardLookupId: "3921",
    VDNumber: "76100565",
    RegisteredPopulation: "1640",
    VotingStationName: "MASHILA PRIMARY SCHOOL",
    Address: "ITSANI  MAKUMBANE",
    Latitude: "-23.0122",
    Longitude: "30.4174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14899",
    WardLookupId: "3921",
    VDNumber: "76100677",
    RegisteredPopulation: "2181",
    VotingStationName: "THIVHULAWI PRIMARY SCHOOL",
    Address: "ITSANI  MAKUMBANE",
    Latitude: "-23.0193",
    Longitude: "30.4151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14900",
    WardLookupId: "3922",
    VDNumber: "76100509",
    RegisteredPopulation: "1624",
    VotingStationName: "SHAYANDIMA SECONDARY SCHOOL",
    Address: "NO STREET NAME  SHAYANDIMA  MAKUMBANE",
    Latitude: "-23.0052",
    Longitude: "30.4284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14901",
    WardLookupId: "3922",
    VDNumber: "76100532",
    RegisteredPopulation: "1147",
    VotingStationName: "TSHIVHUYUNI PRIMARY SCHOOL",
    Address: "NO STREET NAME  SHAYANDIMA  MAKUMBANE",
    Latitude: "-23.0106",
    Longitude: "30.4401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14902",
    WardLookupId: "3922",
    VDNumber: "76100543",
    RegisteredPopulation: "1519",
    VotingStationName: "MANZERE PRIMARY SCHOOL",
    Address: "SHAYANDIMA  MAKUMBANE",
    Latitude: "-23.0108",
    Longitude: "30.4313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14903",
    WardLookupId: "3922",
    VDNumber: "76100835",
    RegisteredPopulation: "1094",
    VotingStationName: "MULEDANE PRIMARY SCHOOL",
    Address: "NO STREET NAME  MULEDANE VILLAGE  MULEDANE",
    Latitude: "-22.9923",
    Longitude: "30.4463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14904",
    WardLookupId: "3923",
    VDNumber: "76100554",
    RegisteredPopulation: "582",
    VotingStationName: "MATHIELEDZHA P/SCHOOL",
    Address: "MANGONDI  MUTAVHANANI  MPHAPHULI",
    Latitude: "-22.9338",
    Longitude: "30.6327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14905",
    WardLookupId: "3923",
    VDNumber: "76100576",
    RegisteredPopulation: "794",
    VotingStationName: "MURAGA PRIMARY SCHOOL",
    Address: "MURAGA  HAMURAGA  MPHAPHULI",
    Latitude: "-22.9128",
    Longitude: "30.6548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14906",
    WardLookupId: "3923",
    VDNumber: "76100587",
    RegisteredPopulation: "493",
    VotingStationName: "MALAMANGWA PRIMARY SCHOOL",
    Address: "MALAMANGWA  MALAMANGWA  VILLAGE  MPHAPHULI",
    Latitude: "-22.9069",
    Longitude: "30.6343",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14907",
    WardLookupId: "3923",
    VDNumber: "76100598",
    RegisteredPopulation: "545",
    VotingStationName: "NWELI PRIMARY SCHOOL",
    Address: "NWELI VILLAGE  MPHAPHULI",
    Latitude: "-22.8813",
    Longitude: "30.6145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14908",
    WardLookupId: "3923",
    VDNumber: "76100633",
    RegisteredPopulation: "539",
    VotingStationName: "MATSIKA PRIMARY SCHOOL",
    Address: "MATSIKA  MATSIKA VILLAGE  MPHAPHULI",
    Latitude: "-22.8675",
    Longitude: "30.6877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14909",
    WardLookupId: "3923",
    VDNumber: "76100644",
    RegisteredPopulation: "515",
    VotingStationName: "THIFHURIWI PRIMARY SCHOOL",
    Address: "GONDENI STERKSTROOM  GONDENI  MPHAPHULI",
    Latitude: "-22.8957",
    Longitude: "30.6594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14910",
    WardLookupId: "3923",
    VDNumber: "76100655",
    RegisteredPopulation: "398",
    VotingStationName: "TSHAMAVHUDZI PRIMARY SCHOOL",
    Address: "TSHAMAVHUDZI  MPHAPHULI",
    Latitude: "-22.8862",
    Longitude: "30.6534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14911",
    WardLookupId: "3923",
    VDNumber: "76100699",
    RegisteredPopulation: "849",
    VotingStationName: "MAFENYA PRIMARY SCHOOL",
    Address: "MALAVUWE  MPHAPHULI",
    Latitude: "-22.8715",
    Longitude: "30.6478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14912",
    WardLookupId: "3923",
    VDNumber: "76100701",
    RegisteredPopulation: "489",
    VotingStationName: "FHATUWANI SECONDARY SCHOOL",
    Address: "MALAVUWE  MPHAPHULI",
    Latitude: "-22.8715",
    Longitude: "30.6367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14913",
    WardLookupId: "3924",
    VDNumber: "76100419",
    RegisteredPopulation: "1318",
    VotingStationName: "HEALTH CENTRE (NGOVHELA)",
    Address: "NO STREET NAME  NGOVHELA VILLAGE  NGOVHELA",
    Latitude: "-22.9527",
    Longitude: "30.4293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14914",
    WardLookupId: "3924",
    VDNumber: "76100486",
    RegisteredPopulation: "1093",
    VotingStationName: "MAKWARELA PRIMARY SCHOOL",
    Address: "NO STREET NAME  MILUWANI VILLAGE  MILUWANI",
    Latitude: "-22.9507",
    Longitude: "30.4682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14915",
    WardLookupId: "3924",
    VDNumber: "76100868",
    RegisteredPopulation: "724",
    VotingStationName: "KIDEO LUCKY COMMUNITY CRECHE",
    Address: "NO STREET NAME  MILUWANI VILLAGE  MILUWANI",
    Latitude: "-22.9542",
    Longitude: "30.4581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14916",
    WardLookupId: "3924",
    VDNumber: "76101230",
    RegisteredPopulation: "724",
    VotingStationName: "HOLY IS THE LAMB GIVING MINISTRY",
    Address: "MBILWI VILLAGE  WHITE AREA  MPHAPHULI",
    Latitude: "-22.9404",
    Longitude: "30.4572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14917",
    WardLookupId: "3924",
    VDNumber: "76101285",
    RegisteredPopulation: "1090",
    VotingStationName: "MAMALI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  NGOVHELA BACKSIDE  NGOVHELA",
    Latitude: "-22.9531",
    Longitude: "30.4449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14918",
    WardLookupId: "3925",
    VDNumber: "76100273",
    RegisteredPopulation: "2042",
    VotingStationName: "MANIINI PRIMARY SCHOOL",
    Address: "NO STREET NAME  THOHOYANDOU BLOCK K  MANIINI",
    Latitude: "-22.9871",
    Longitude: "30.4809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14919",
    WardLookupId: "3925",
    VDNumber: "76100789",
    RegisteredPopulation: "1076",
    VotingStationName: "TSHIKOVHA TRADITIONAL COUNCIL",
    Address: "MANIINI TSHIKOVHA  MANIINI  MANIINI HA TSHIKOVHA",
    Latitude: "-22.9916",
    Longitude: "30.4946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14920",
    WardLookupId: "3925",
    VDNumber: "76101072",
    RegisteredPopulation: "731",
    VotingStationName: "HAGGAI PRIVATE SCHOOL",
    Address: "MANIINI  THOHOYANDOU  THULAMELA [THOHOYANDOU]",
    Latitude: "-22.9819",
    Longitude: "30.4783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14921",
    WardLookupId: "3925",
    VDNumber: "76101465",
    RegisteredPopulation: "611",
    VotingStationName: "WISDOM COMBINED SCHOOL",
    Address: "NO STREET NAME  MANIINI BLOCK M  MANIINI VILLAGE",
    Latitude: "-22.9762",
    Longitude: "30.4892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14922",
    WardLookupId: "3925",
    VDNumber: "76101476",
    RegisteredPopulation: "766",
    VotingStationName: "MUVHI TSHIKOVHA PRIMARY SCHOOL",
    Address: "MANINI  THULAMELA  MANINI",
    Latitude: "-22.9959",
    Longitude: "30.4918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14923",
    WardLookupId: "1853",
    VDNumber: "76210196",
    RegisteredPopulation: "2043",
    VotingStationName: "MBOKOTA PRIMARY SCHOOL",
    Address: "MBHOKOTA  ELIM   NKHENSANI TRIBAL OFFICE",
    Latitude: "-23.2",
    Longitude: "30.0937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14924",
    WardLookupId: "1853",
    VDNumber: "76210208",
    RegisteredPopulation: "473",
    VotingStationName: "HS PHILLIPS MEMORIAL SECONDARY SCHOOL",
    Address: "SHIRLEY  ELIM  MAKHADO",
    Latitude: "-23.1807",
    Longitude: "30.0826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14925",
    WardLookupId: "1853",
    VDNumber: "76210220",
    RegisteredPopulation: "994",
    VotingStationName: "SITHUMANI PRIMARY SCHOOL",
    Address: "CHAVANI  LOUIS TRICHARDT",
    Latitude: "-23.2021",
    Longitude: "30.1243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14926",
    WardLookupId: "1853",
    VDNumber: "76210545",
    RegisteredPopulation: "452",
    VotingStationName: "SHIRLEY PRIMARY SCHOOL",
    Address: "SHIRLEY VILLAGE  ELIM  LTT",
    Latitude: "-23.1737",
    Longitude: "30.0822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14927",
    WardLookupId: "1853",
    VDNumber: "76211580",
    RegisteredPopulation: "678",
    VotingStationName: "MBOKOTA FULL GOSPEL CHURCH",
    Address: "MBOKOTA VILLAGE  ELIM  NKHENSANI TRIBAL",
    Latitude: "-23.1991",
    Longitude: "30.1033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14928",
    WardLookupId: "1854",
    VDNumber: "76210501",
    RegisteredPopulation: "1373",
    VotingStationName: "ELIM SENIOR SECONDARY SCHOOL",
    Address: "2145 MAKHONGELE ELIM  ELIM HOSPITAL  NJHAKANJHAKA TRIBAL OFFICE",
    Latitude: "-23.1595",
    Longitude: "30.0526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14929",
    WardLookupId: "1854",
    VDNumber: "76210512",
    RegisteredPopulation: "908",
    VotingStationName: "MASUNGULO PRIMARY SCHOOL",
    Address: "STAND NO 9209, ELIM PASTORAL V  ELIM  NJHAKANJHAKA TRIBAL OFFICE",
    Latitude: "-23.1703",
    Longitude: "30.0487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14930",
    WardLookupId: "1854",
    VDNumber: "76210523",
    RegisteredPopulation: "976",
    VotingStationName: "ELIM/SHIRLEY TRIBAL COUNCIL",
    Address: "SHIRLEY  ELIM/SHIRLEY  LTT",
    Latitude: "-23.1679",
    Longitude: "30.0655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14931",
    WardLookupId: "1854",
    VDNumber: "76211120",
    RegisteredPopulation: "1466",
    VotingStationName: "WATERVAL HIGH SCHOOL",
    Address: "STAND NO 815A, WATERVAL LOCATI  ELIM   WATERVAL LOCATION",
    Latitude: "-23.1579",
    Longitude: "30.0781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14932",
    WardLookupId: "1854",
    VDNumber: "76211647",
    RegisteredPopulation: "672",
    VotingStationName: "MAHONISI SECONDARY SCHOOL",
    Address: "WATERFAL  ELIM  MAKHADO",
    Latitude: "-23.1557",
    Longitude: "30.0657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14933",
    WardLookupId: "1855",
    VDNumber: "76210804",
    RegisteredPopulation: "1286",
    VotingStationName: "RASIVHETSHELE PRIMARY SCHOOL",
    Address: "MANGILASI  TSHIVHAZWAULU  NESENGANI",
    Latitude: "-23.0967",
    Longitude: "30.3794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14934",
    WardLookupId: "1855",
    VDNumber: "76210882",
    RegisteredPopulation: "752",
    VotingStationName: "TSHIVHAZWAULU CHRISTIAN ACADEMY SCHOOL",
    Address: "NEAR KOLOKOSHANI VILLAGE  MAKHADO / VUWANI  TSHIVHAZWAULU",
    Latitude: "-23.0842",
    Longitude: "30.3878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14935",
    WardLookupId: "1855",
    VDNumber: "76211041",
    RegisteredPopulation: "724",
    VotingStationName: "MUTSINDONI FAITH MISSION",
    Address: "TSHAKHUMA  MUTSINDONI VILLAGE  MAKHADO",
    Latitude: "-23.0652",
    Longitude: "30.3236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14936",
    WardLookupId: "1855",
    VDNumber: "76211287",
    RegisteredPopulation: "1035",
    VotingStationName: "TSHIKURUKURU PRIMARY SCHOOL",
    Address: "NEXT TO HAMUTSHA CLINIC  HA MUTSHA, CODESA  MAKHADO",
    Latitude: "-23.0639",
    Longitude: "30.3509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14937",
    WardLookupId: "1855",
    VDNumber: "76211478",
    RegisteredPopulation: "1411",
    VotingStationName: "MUUNGAMUNWE PRIMARY SCHOOL",
    Address: "TSHIVHULANI  MAKHADO  TSHIVHULANI/TSHINO",
    Latitude: "-23.0688",
    Longitude: "30.3716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14938",
    WardLookupId: "1856",
    VDNumber: "76210769",
    RegisteredPopulation: "1867",
    VotingStationName: "EDSON NESENGANI SENIOR SCHOOL",
    Address: "TSHINO  NESENGANI  THOHOYANDOU",
    Latitude: "-23.1178",
    Longitude: "30.4042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14939",
    WardLookupId: "1856",
    VDNumber: "76210770",
    RegisteredPopulation: "504",
    VotingStationName: "NESENGANI TRIBAL OFFICE",
    Address: "TSHINO VILLAGE  VUWANI  VUWANI",
    Latitude: "-23.1086",
    Longitude: "30.4094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14940",
    WardLookupId: "1856",
    VDNumber: "76210781",
    RegisteredPopulation: "1028",
    VotingStationName: "KOLOKOSHANI SECONDARY SCHOOL",
    Address: "STAND NO A082, MANGILASI VILLA  NESENGANI  THOHOYANDOU",
    Latitude: "-23.0959",
    Longitude: "30.3917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14941",
    WardLookupId: "1856",
    VDNumber: "76211175",
    RegisteredPopulation: "1299",
    VotingStationName: "TSHIVHAZWAULU PRIMARY SCHOOL",
    Address: "TSHIVHAZWAULU VILLAGE  HA-RAMUKHUBA  LOUIS TRICHARDT",
    Latitude: "-23.102",
    Longitude: "30.3963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14942",
    WardLookupId: "1857",
    VDNumber: "76210051",
    RegisteredPopulation: "1317",
    VotingStationName: "MULIMA TERRITORIAL COUNCIL",
    Address: "THONDONI  MULIMA  LOUIS TRICHARDT",
    Latitude: "-23.3138",
    Longitude: "30.0429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14943",
    WardLookupId: "1857",
    VDNumber: "76210062",
    RegisteredPopulation: "623",
    VotingStationName: "MUDIKHOMU PRIMARY SCHOOL",
    Address: "LAMBANI  MULIMA  LOUIS TRICHARDT",
    Latitude: "-23.2959",
    Longitude: "30.0525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14944",
    WardLookupId: "1857",
    VDNumber: "76210095",
    RegisteredPopulation: "501",
    VotingStationName: "TSHIILAHO PRIMARY SCHOOL",
    Address: "RAMARU VILLAGE  NTHABALALA  TSHITALE",
    Latitude: "-23.2765",
    Longitude: "30.0186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14945",
    WardLookupId: "1857",
    VDNumber: "76210534",
    RegisteredPopulation: "1168",
    VotingStationName: "SHIHLOBYENI PRIMARY SCHOOL",
    Address: "MAGANGENI  SHIHLOBYENI/MAGANGENI  NJAKANJAKA",
    Latitude: "-23.1855",
    Longitude: "30.0575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14946",
    WardLookupId: "1857",
    VDNumber: "76211186",
    RegisteredPopulation: "758",
    VotingStationName: "RIVERPLAATS PRIMARY SCHOOL",
    Address: "STAND NO 495, RIVERPLAATS  ELIM  NKHENSANI TRIBAL",
    Latitude: "-23.2326",
    Longitude: "30.0537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14947",
    WardLookupId: "1857",
    VDNumber: "76211681",
    RegisteredPopulation: "293",
    VotingStationName: "MASETHE PRIMARY SCHOOL",
    Address: "HA-MASETHE  TSHITALE  MAKHADO RURAL",
    Latitude: "-23.2578",
    Longitude: "30.0511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14948",
    WardLookupId: "1858",
    VDNumber: "76060032",
    RegisteredPopulation: "2052",
    VotingStationName: "MANELEDZI SECONDARY SCHOOL",
    Address: "TSHIOZWI  TSHIOZWI VILLAGE  LOUIS TRICHARDT",
    Latitude: "-23.0798",
    Longitude: "29.7933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14949",
    WardLookupId: "1858",
    VDNumber: "76060043",
    RegisteredPopulation: "2362",
    VotingStationName: "AMAZING GRACE ASSEMBLY",
    Address: "MADOMBIDZHA ZONE 2  LOUIS TRICHARDT",
    Latitude: "-23.1167",
    Longitude: "29.8129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14950",
    WardLookupId: "1859",
    VDNumber: "76060065",
    RegisteredPopulation: "1584",
    VotingStationName: "MAGAU PRIMARY SCHOOL",
    Address: "MAGAU  MAGAU VILLAGE  LOUIS TRICHARDT",
    Latitude: "-23.1323",
    Longitude: "29.8518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14951",
    WardLookupId: "1859",
    VDNumber: "76060234",
    RegisteredPopulation: "2710",
    VotingStationName: "MASEDI COMBINE SCHOOL",
    Address: "TSHIKOTA  MAKHADO",
    Latitude: "-23.0468",
    Longitude: "29.8773",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14952",
    WardLookupId: "1859",
    VDNumber: "76060627",
    RegisteredPopulation: "1182",
    VotingStationName: "EMMANUEL CHRISTIAN CHURCH",
    Address: "NEWTOWN  LOUIS TRICHARDT",
    Latitude: "-23.0329",
    Longitude: "29.9214",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14953",
    WardLookupId: "1860",
    VDNumber: "76060021",
    RegisteredPopulation: "2684",
    VotingStationName: "TOWN LIBRARY ACTIVITY ROOM",
    Address: "C/O ERASMUS & KRUGER STREETS  LOUISTRICHARDT  LOUIS TRICHARDT",
    Latitude: "-23.0434",
    Longitude: "29.9088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14954",
    WardLookupId: "1860",
    VDNumber: "76060582",
    RegisteredPopulation: "575",
    VotingStationName: "MAKHADO COMPREHENSIVE SECONDARY SCHOOL",
    Address: "KING FISHER STREET  MAKHADO PARK - LTT TOWN  LOUIS TRICHARDT  ",
    Latitude: "-23.0604",
    Longitude: "29.9184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14955",
    WardLookupId: "1860",
    VDNumber: "76060638",
    RegisteredPopulation: "679",
    VotingStationName: "DE-MONTFORT CATHOLIC SCHOOL",
    Address: "45 BURGER STREET  OLD TOWN  LOUIS TRICHARDT",
    Latitude: "-23.0498",
    Longitude: "29.9056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14956",
    WardLookupId: "1861",
    VDNumber: "76060010",
    RegisteredPopulation: "2206",
    VotingStationName: "LOUIS TRICHARDT SKOUSAAL",
    Address: "H/V SIBASAWEG & EERSTELAAN  LOUIS TRICHARDT  LOUIS TRICHARDT",
    Latitude: "-23.0479",
    Longitude: "29.9237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14957",
    WardLookupId: "1861",
    VDNumber: "76370676",
    RegisteredPopulation: "1057",
    VotingStationName: "MATSHAVHAWE PRIMARY SCHOOL",
    Address: "MATSHAVHAWE  MATSHAVHAWE TRIBAL OFFICE",
    Latitude: "-22.9731",
    Longitude: "30.1038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14958",
    WardLookupId: "1861",
    VDNumber: "76370991",
    RegisteredPopulation: "156",
    VotingStationName: "KHUNDA PRIMARY SCHOOL",
    Address: "KHUNDA MATSHAVHAWE  MAKHADO [LOUIS TRICHARDT]",
    Latitude: "-22.9532",
    Longitude: "30.1255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14959",
    WardLookupId: "1862",
    VDNumber: "76370025",
    RegisteredPopulation: "1273",
    VotingStationName: "NNGWENI SECONDARY SCHOOL",
    Address: "MAKHADO  DZANANI LOCATION  MPHEPHU",
    Latitude: "-22.8967",
    Longitude: "30.0311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14960",
    WardLookupId: "1862",
    VDNumber: "76370564",
    RegisteredPopulation: "661",
    VotingStationName: "TSHITUNI PRIMARY SCHOOL",
    Address: "TSHITUNI TSHA MAPILA  MAPILA  MPHEPHU",
    Latitude: "-22.9107",
    Longitude: "30.0478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14961",
    WardLookupId: "1862",
    VDNumber: "76370823",
    RegisteredPopulation: "872",
    VotingStationName: "F.N. RAVELE SECONDARY SCHOOL",
    Address: "DIVHANI  NZHELELE",
    Latitude: "-22.9166",
    Longitude: "30.0754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14962",
    WardLookupId: "1862",
    VDNumber: "76370902",
    RegisteredPopulation: "1208",
    VotingStationName: "DZIVHALANOMBE PRIMARY SCHOOL",
    Address: "MAWONI  MPHEPHU",
    Latitude: "-22.8916",
    Longitude: "30.0629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14963",
    WardLookupId: "1862",
    VDNumber: "76371015",
    RegisteredPopulation: "441",
    VotingStationName: "BALALILA PRIMARY SCHOOL",
    Address: "TSHITUNI TSHANNTHA  DZANANI  VHEMBE   ",
    Latitude: "-22.8994",
    Longitude: "30.0326",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14964",
    WardLookupId: "1863",
    VDNumber: "76210118",
    RegisteredPopulation: "880",
    VotingStationName: "RALUOMBE SECONDARY SCHOOL",
    Address: "MASHAMBA BUSHY PARK  MASHAMBA  ELIM",
    Latitude: "-23.2368",
    Longitude: "30.1306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14965",
    WardLookupId: "1863",
    VDNumber: "76210129",
    RegisteredPopulation: "991",
    VotingStationName: "TSHITANGULE PRIMARY SCHOOL",
    Address: "MASHAMBA RIVERSDALE  MASHAMBA  ELIM",
    Latitude: "-23.2353",
    Longitude: "30.1546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14966",
    WardLookupId: "1863",
    VDNumber: "76210152",
    RegisteredPopulation: "1445",
    VotingStationName: "MASHAMBA PRIMARY SCHOOL",
    Address: "MASHAMBA BUSHY PARK  MASHAMBA  ELIM",
    Latitude: "-23.2338",
    Longitude: "30.1405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14967",
    WardLookupId: "1863",
    VDNumber: "76210163",
    RegisteredPopulation: "568",
    VotingStationName: "MUFEBA PRIMARY SCHOOL",
    Address: "STAND NO 20,MUFEBA VILLAGE  MASHAMBA  ELIM",
    Latitude: "-23.247",
    Longitude: "30.1931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14968",
    WardLookupId: "1863",
    VDNumber: "76211153",
    RegisteredPopulation: "821",
    VotingStationName: "WAYENI PRIMARY SCHOOL",
    Address: "WAYENI VILLAGE  MASHAMBA  ELIM",
    Latitude: "-23.2407",
    Longitude: "30.2182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14969",
    WardLookupId: "1863",
    VDNumber: "76211412",
    RegisteredPopulation: "273",
    VotingStationName: "MUSANDIWA PRIMARY SCHOOL",
    Address: "STAND NO T64, TSHIVHANGANI    HAMULIMA  ELIM",
    Latitude: "-23.262",
    Longitude: "30.1395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14970",
    WardLookupId: "1864",
    VDNumber: "76210107",
    RegisteredPopulation: "1475",
    VotingStationName: "LOTSHA PRIMARY SCHOOL",
    Address: "MUKONDENI  MULIMA  LTT",
    Latitude: "-23.2609",
    Longitude: "30.1061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14971",
    WardLookupId: "1864",
    VDNumber: "76210130",
    RegisteredPopulation: "796",
    VotingStationName: "MUWAWENI PRIMARY SCHOOL",
    Address: "MUWAWENI  MAKHADO",
    Latitude: "-23.3233",
    Longitude: "30.1144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14972",
    WardLookupId: "1864",
    VDNumber: "76210141",
    RegisteredPopulation: "663",
    VotingStationName: "MADADZHI PRIMARY SCHOOL",
    Address: "MADADZHI  MULIMA  LOUIS TRICHARDT",
    Latitude: "-23.3136",
    Longitude: "30.1197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14973",
    WardLookupId: "1864",
    VDNumber: "76210174",
    RegisteredPopulation: "1113",
    VotingStationName: "TSHIVHUYUNI PRIMARY SCHOOL",
    Address: "DZHIYAWOLALA  TSHIVHUYUNI  ELIM",
    Latitude: "-23.2368",
    Longitude: "30.083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14974",
    WardLookupId: "1864",
    VDNumber: "76211333",
    RegisteredPopulation: "165",
    VotingStationName: "LOTAVHA PRIMARY SCHOOL",
    Address: "NEXT TO BUS STOP ON THE MAIN ROAD  SLANGER  MAKHADO",
    Latitude: "-23.3524",
    Longitude: "30.0732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14975",
    WardLookupId: "1864",
    VDNumber: "76211366",
    RegisteredPopulation: "430",
    VotingStationName: "EDZISANI PRIMARY SCHOOL",
    Address: "TSWIKA MUWAWENI  TSWIKA  MAKHADO [LOUIS TRICHARDT]",
    Latitude: "-23.3123",
    Longitude: "30.099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14976",
    WardLookupId: "1864",
    VDNumber: "76211423",
    RegisteredPopulation: "152",
    VotingStationName: "LUHUFHE CRECHE",
    Address: "LUHUFHE VILLAGE  MUWAWENI  ELIM",
    Latitude: "-23.2842",
    Longitude: "30.091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14977",
    WardLookupId: "1864",
    VDNumber: "76211579",
    RegisteredPopulation: "462",
    VotingStationName: "MULINDATHAVHA PRIMARY SCHOOL",
    Address: "TSHIVHUYUNI  MASHAMBA  ELIM",
    Latitude: "-23.2285",
    Longitude: "30.0855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14978",
    WardLookupId: "1865",
    VDNumber: "76210185",
    RegisteredPopulation: "561",
    VotingStationName: "MASEKANI PRIMARY SCHOOL",
    Address: "MBOKOTA  ELIM  LTT",
    Latitude: "-23.2069",
    Longitude: "30.1054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14979",
    WardLookupId: "1865",
    VDNumber: "76210231",
    RegisteredPopulation: "1392",
    VotingStationName: "XILUMANI PRIMARY SCHOOL",
    Address: "PHANDLULA VILLA  CHAVANI  LOUIS TRICHARDT",
    Latitude: "-23.1984",
    Longitude: "30.1489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14980",
    WardLookupId: "1865",
    VDNumber: "76210242",
    RegisteredPopulation: "1466",
    VotingStationName: "SHINGUWA HIGH SCHOOL",
    Address: "CHAVANI VILLAGE  CHAVANI  ELIM",
    Latitude: "-23.1998",
    Longitude: "30.1394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14981",
    WardLookupId: "1865",
    VDNumber: "76210264",
    RegisteredPopulation: "877",
    VotingStationName: "MASIZA HIGH SCHOOL",
    Address: "NWAXINYAMANI  CHAVANI  HLANGANANI",
    Latitude: "-23.2015",
    Longitude: "30.1636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14982",
    WardLookupId: "1866",
    VDNumber: "76210253",
    RegisteredPopulation: "859",
    VotingStationName: "NWAXINYAMANI PRIMARY SCHOOL",
    Address: "NWAXINYAMANI  LOUIS TRICHARDT",
    Latitude: "-23.1925",
    Longitude: "30.1604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14983",
    WardLookupId: "1866",
    VDNumber: "76210275",
    RegisteredPopulation: "709",
    VotingStationName: "SHIKOSANA PRIMARY SCHOOL",
    Address: "MAVHINA  NWAXINYAMANI  LTT",
    Latitude: "-23.1898",
    Longitude: "30.1732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14984",
    WardLookupId: "1866",
    VDNumber: "76210286",
    RegisteredPopulation: "1252",
    VotingStationName: "TSHIVHADE PRIMARY SCHOOL",
    Address: "BODWE MASHAU  MASHAU  LTT",
    Latitude: "-23.1847",
    Longitude: "30.176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14985",
    WardLookupId: "1866",
    VDNumber: "76210297",
    RegisteredPopulation: "2131",
    VotingStationName: "MASUNGI PRIMARY SCHOOL",
    Address: "SKHUNYANE  BUNGENI  LTT",
    Latitude: "-23.1975",
    Longitude: "30.1888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14986",
    WardLookupId: "1866",
    VDNumber: "76211636",
    RegisteredPopulation: "412",
    VotingStationName: "MAVHINA PRIMARY SCHOOL",
    Address: "BODWE  HAMASHAU  BODWE",
    Latitude: "-23.1884",
    Longitude: "30.1888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14987",
    WardLookupId: "1867",
    VDNumber: "76210444",
    RegisteredPopulation: "1339",
    VotingStationName: "VALDEZIA HIGH PRIMARY SCHOOL",
    Address: "VALDEZIA  LOUISTRICHARDT",
    Latitude: "-23.1036",
    Longitude: "30.18",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14988",
    WardLookupId: "1867",
    VDNumber: "76210477",
    RegisteredPopulation: "1454",
    VotingStationName: "OZIAS DAVHANA SENIOR SECONDARY SCHOOL",
    Address: "MPHENI BLOCK B  MPHENI  LTT",
    Latitude: "-23.1438",
    Longitude: "30.0569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14989",
    WardLookupId: "1867",
    VDNumber: "76210499",
    RegisteredPopulation: "1385",
    VotingStationName: "MUTHUHADINI PRIMARY SCHOOL",
    Address: "ELIM ROAD  MPHENI ELIM  ELIM",
    Latitude: "-23.1474",
    Longitude: "30.051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14990",
    WardLookupId: "1867",
    VDNumber: "76211456",
    RegisteredPopulation: "424",
    VotingStationName: "TSHIISAPHUNGO PRIMARY SCHOOL",
    Address: "VARI VILLAGE ( MORDER FLEI)  ELIM  ELIM",
    Latitude: "-23.1717",
    Longitude: "30.0401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14991",
    WardLookupId: "1867",
    VDNumber: "76211591",
    RegisteredPopulation: "327",
    VotingStationName: "REVEALING CHRIST CHAMPION CHURCH",
    Address: "DILINDE MPHENI  MPHENI  ELIM",
    Latitude: "-23.1376",
    Longitude: "30.0717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14992",
    WardLookupId: "1867",
    VDNumber: "76211603",
    RegisteredPopulation: "476",
    VotingStationName: "TSHIMONELO PRIMARY SCHOOL",
    Address: "MPHENI BLOCK C  ELIM  MAKHADO",
    Latitude: "-23.1376",
    Longitude: "30.0638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14993",
    WardLookupId: "1868",
    VDNumber: "76210466",
    RegisteredPopulation: "2462",
    VotingStationName: "MULWELI PRIMARY SCHOOL",
    Address: "MPHENI HAMAKHAKHI BLOCK D2  ELIM  ELIM",
    Latitude: "-23.14",
    Longitude: "30.0797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14994",
    WardLookupId: "1868",
    VDNumber: "76210488",
    RegisteredPopulation: "1412",
    VotingStationName: "DJUNANI PRIMARY SCHOOL",
    Address: "WATERVAL  ELIM HOSPITAL  LTT",
    Latitude: "-23.1513",
    Longitude: "30.0743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14995",
    WardLookupId: "1868",
    VDNumber: "76211658",
    RegisteredPopulation: "818",
    VotingStationName: "HLALELANI PRIMARY SCHOOL",
    Address: "WATERFAL  ELIM  MAKHADO",
    Latitude: "-23.1582",
    Longitude: "30.0829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14996",
    WardLookupId: "1869",
    VDNumber: "76370069",
    RegisteredPopulation: "323",
    VotingStationName: "ONISMUS SUNDANI PRIMARY SCHOOL",
    Address: "MAZUWA  NZHELELE",
    Latitude: "-22.9558",
    Longitude: "30.1831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14997",
    WardLookupId: "1869",
    VDNumber: "76370070",
    RegisteredPopulation: "1360",
    VotingStationName: "HUMBELANI SECONDARY SCHOOL",
    Address: "PHADZIMA  PHADZIMA/DZUMBATHOHO  NZHELELE",
    Latitude: "-22.9404",
    Longitude: "30.1693",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14998",
    WardLookupId: "1869",
    VDNumber: "76370081",
    RegisteredPopulation: "1309",
    VotingStationName: "LIVHUWANI PRIMARY SCHOOL",
    Address: "PHADZIMA  MAZHAZHANI  NZHELELE",
    Latitude: "-22.9549",
    Longitude: "30.2041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "14999",
    WardLookupId: "1869",
    VDNumber: "76370092",
    RegisteredPopulation: "750",
    VotingStationName: "VHULAUDZI  SECONDARY SCHOOL",
    Address: "TSHITAVHA  NZHELELE",
    Latitude: "-22.9614",
    Longitude: "30.1856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15000",
    WardLookupId: "1869",
    VDNumber: "76370610",
    RegisteredPopulation: "545",
    VotingStationName: "MATZHEKETZHEKE PRIMARY SCHOOL",
    Address: "MAKHADO   ",
    Latitude: "-22.9279",
    Longitude: "30.1411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15001",
    WardLookupId: "1870",
    VDNumber: "76210039",
    RegisteredPopulation: "631",
    VotingStationName: "MUILA PRIMARY SCHOOL",
    Address: "MUILA TSHITALE (NEAR POLICE ST  MAKHADO  MAKHADO MULIMA RURAL",
    Latitude: "-23.3617",
    Longitude: "29.9822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15002",
    WardLookupId: "1870",
    VDNumber: "76210040",
    RegisteredPopulation: "1157",
    VotingStationName: "PFANANANI CHILDCARE CENTRE",
    Address: "MULIMA  NTHABALALA  MULIMA",
    Latitude: "-23.3195",
    Longitude: "29.9987",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15003",
    WardLookupId: "1870",
    VDNumber: "76211210",
    RegisteredPopulation: "498",
    VotingStationName: "MUISE PRIMARY SCHOOL",
    Address: "HAMAMPHAGI  MULIMA  MULIMA",
    Latitude: "-23.3254",
    Longitude: "29.9742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15004",
    WardLookupId: "1870",
    VDNumber: "76211221",
    RegisteredPopulation: "1172",
    VotingStationName: "MUILA TRADITIONAL COUNCIL",
    Address: "HA MUILA  TSHITALE  TSHITALE",
    Latitude: "-23.3672",
    Longitude: "29.9803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15005",
    WardLookupId: "1870",
    VDNumber: "76211467",
    RegisteredPopulation: "531",
    VotingStationName: "TUWANI PRIMARY SCHOOL",
    Address: "MAKHADO  ",
    Latitude: "-23.3483",
    Longitude: "29.9897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15006",
    WardLookupId: "1871",
    VDNumber: "76060571",
    RegisteredPopulation: "931",
    VotingStationName: "MUNZHEDZI GENERAL DEALER",
    Address: "NO STREET ADDRESS AVAILABLE  MUNZHEDZI   VHEMBE  ",
    Latitude: "-23.2169",
    Longitude: "29.9862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15007",
    WardLookupId: "1871",
    VDNumber: "76060616",
    RegisteredPopulation: "431",
    VotingStationName: "TSHIAWELO SECONDARY SCHOOL",
    Address: "MAKHADO  ",
    Latitude: "-23.2212",
    Longitude: "29.9905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15008",
    WardLookupId: "1871",
    VDNumber: "76210073",
    RegisteredPopulation: "465",
    VotingStationName: "NTHABALALA PRIMARY SCHOOL",
    Address: "MATHULE B  NTHABALALA  LOUISTRICHARDT",
    Latitude: "-23.2868",
    Longitude: "29.9608",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15009",
    WardLookupId: "1871",
    VDNumber: "76210084",
    RegisteredPopulation: "709",
    VotingStationName: "NTHABALALA TERRITORIAL COUNCIL",
    Address: "THONDONI  NTHABALALA  LOUIS TRICHARDT",
    Latitude: "-23.2645",
    Longitude: "29.9757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15010",
    WardLookupId: "1871",
    VDNumber: "76211131",
    RegisteredPopulation: "1381",
    VotingStationName: "MICHAEL DENGA RAMABULANA SCHOOL",
    Address: "MAILASKOP  LTT",
    Latitude: "-23.2205",
    Longitude: "29.9612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15011",
    WardLookupId: "1871",
    VDNumber: "76211142",
    RegisteredPopulation: "805",
    VotingStationName: "THWALIMA SECONDARY SCHOOL",
    Address: "HAMADUWA  TSHITALE  LOUIS TRICHARDT",
    Latitude: "-23.2924",
    Longitude: "29.993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15012",
    WardLookupId: "1871",
    VDNumber: "76211670",
    RegisteredPopulation: "357",
    VotingStationName: "MASINDI PRIMARY SCHOOL",
    Address: "HA MAILA  MAILASKOP  MAKHADO",
    Latitude: "-23.2255",
    Longitude: "29.9607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15013",
    WardLookupId: "1871",
    VDNumber: "76211748",
    RegisteredPopulation: "271",
    VotingStationName: "KANANA SETTLEMEMT (TENT)",
    Address: "KANANA  MAKHADO  NTHABALALA TRIBAL OFFICE",
    Latitude: "-23.2148",
    Longitude: "29.9604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15014",
    WardLookupId: "1872",
    VDNumber: "76060144",
    RegisteredPopulation: "2108",
    VotingStationName: "MUNZHEDZI PRIMARY SCHOOL",
    Address: "VLEIFFONTEIN   MAKHADO  MUNZHEDZI TRIBAL OFFICE",
    Latitude: "-23.2153",
    Longitude: "29.9937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15015",
    WardLookupId: "1872",
    VDNumber: "76060155",
    RegisteredPopulation: "750",
    VotingStationName: "KREMETARTSAAL",
    Address: "LUIPERD SINGEL  AIRFORCE BASE RES AREA  LOUIS TRICHARDT",
    Latitude: "-23.1709",
    Longitude: "29.7148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15016",
    WardLookupId: "1872",
    VDNumber: "76210017",
    RegisteredPopulation: "181",
    VotingStationName: "BANDELIERKOP POLICE STATION (TENT)",
    Address: "BANDELIERKOP  LOUIS TRICHARDT  LOUISTRICHARDT",
    Latitude: "-23.3177",
    Longitude: "29.8037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15017",
    WardLookupId: "1872",
    VDNumber: "76210028",
    RegisteredPopulation: "453",
    VotingStationName: "LUPENYO PRIMARY SCHOOL",
    Address: "THOTHOLOLO  MUILA  LOUIS TRICHARDT",
    Latitude: "-23.3568",
    Longitude: "29.9505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15018",
    WardLookupId: "1872",
    VDNumber: "76211355",
    RegisteredPopulation: "358",
    VotingStationName: "MUROGOLO PRIMARY SCHOOL",
    Address: "KWAAIDRAAI  MUILA KWAAI DRAAI  MAKHADO [LOUIS TRICHARDT]",
    Latitude: "-23.4002",
    Longitude: "29.9183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15019",
    WardLookupId: "1872",
    VDNumber: "76211669",
    RegisteredPopulation: "239",
    VotingStationName: "RASILA SECONDARY SCHOOL",
    Address: "MUILA  MUUMONI  MAKHADO",
    Latitude: "-23.369",
    Longitude: "29.9437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15020",
    WardLookupId: "1872",
    VDNumber: "76211726",
    RegisteredPopulation: "282",
    VotingStationName: "TALIFHISANI DAY CARE CENTRE",
    Address: "LAGOS  MAKHADO MUNICIPALITY  MAILASKOP",
    Latitude: "-23.2271",
    Longitude: "29.9406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15021",
    WardLookupId: "1873",
    VDNumber: "76060256",
    RegisteredPopulation: "94",
    VotingStationName: "SWONGOZWI MUSANDA",
    Address: "SWONGOZWIMUSANDA  MAKHADO",
    Latitude: "-22.9988",
    Longitude: "29.8554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15022",
    WardLookupId: "1873",
    VDNumber: "76120208",
    RegisteredPopulation: "643",
    VotingStationName: "FHEMBELEDZANI PRIMARY SCHOOL",
    Address: "WATERPOORT  ALLDAYS",
    Latitude: "-22.8967",
    Longitude: "29.6291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15023",
    WardLookupId: "1873",
    VDNumber: "76370519",
    RegisteredPopulation: "1969",
    VotingStationName: "GEORGE MBULAHENI SCHOOL",
    Address: "TSHIKUWI  NZHELELE",
    Latitude: "-22.9016",
    Longitude: "29.9507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15024",
    WardLookupId: "1873",
    VDNumber: "76370520",
    RegisteredPopulation: "887",
    VotingStationName: "MALEMA PRIMARY SCHOOL",
    Address: "LUVHALANI  NZHELELE",
    Latitude: "-22.9042",
    Longitude: "29.9605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15025",
    WardLookupId: "1873",
    VDNumber: "76370665",
    RegisteredPopulation: "1308",
    VotingStationName: "TSHIROLWE PRIMARY SCHOOL",
    Address: "TSHIROLWE  NZHELELE",
    Latitude: "-22.9002",
    Longitude: "30.0111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15026",
    WardLookupId: "1874",
    VDNumber: "76060087",
    RegisteredPopulation: "1903",
    VotingStationName: "TSHILWAVHUSIKU HEALTH CENTRE",
    Address: "HA RAVELE  LOUIS TRICHARDT",
    Latitude: "-23.0967",
    Longitude: "29.7383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15027",
    WardLookupId: "1874",
    VDNumber: "76060177",
    RegisteredPopulation: "1672",
    VotingStationName: "GOGOBOLE PRIMARY SCHOOL",
    Address: "GOGOBOLE  GOGOBOLE VILLAGE  LOUIS TRICHARDT",
    Latitude: "-23.08",
    Longitude: "29.7727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15028",
    WardLookupId: "1874",
    VDNumber: "76060199",
    RegisteredPopulation: "1769",
    VotingStationName: "SINTHUMULE SECONDARY SCHOOL",
    Address: "HA-RAMANTSHA  HA-RAMANTSHA VILLAGE  LOUIS TRICHARDT",
    Latitude: "-23.0999",
    Longitude: "29.7639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15029",
    WardLookupId: "1875",
    VDNumber: "76060054",
    RegisteredPopulation: "1464",
    VotingStationName: "TSHIKHWANI PRIMARY SCHOOL",
    Address: "TSHIKHWANI  TSHIKHWANI VILLAGE  LOUIS TRICHARDT",
    Latitude: "-23.1389",
    Longitude: "29.7914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15030",
    WardLookupId: "1875",
    VDNumber: "76060188",
    RegisteredPopulation: "1068",
    VotingStationName: "MADOMBIDZHA PRIMARY SCHOOL",
    Address: "MADOMBIDZHA  MADOMBIDZHA VILLAGE  LOUIS TRICHARDT",
    Latitude: "-23.1241",
    Longitude: "29.8183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15031",
    WardLookupId: "1875",
    VDNumber: "76060245",
    RegisteredPopulation: "2052",
    VotingStationName: "PETAMUKANDA  PRIMARY SCHOOL",
    Address: "MADOMBIDZHA  MAKHADO",
    Latitude: "-23.1225",
    Longitude: "29.7959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15032",
    WardLookupId: "1875",
    VDNumber: "76060593",
    RegisteredPopulation: "519",
    VotingStationName: "MUTINGATI CRECHE",
    Address: "NO STREET ADDRESS AVAILABLE  MAGAU   VHEMBE ",
    Latitude: "-23.1279",
    Longitude: "29.8385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15033",
    WardLookupId: "1876",
    VDNumber: "76060076",
    RegisteredPopulation: "1423",
    VotingStationName: "MURALENI P. SCHOOL",
    Address: "MADABANI  MADABANI  VILLAGE  LOUIS TRICHARDT",
    Latitude: "-23.0915",
    Longitude: "29.7171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15034",
    WardLookupId: "1876",
    VDNumber: "76060098",
    RegisteredPopulation: "1497",
    VotingStationName: "SWONGOZWI JUNIOR SECONDARY SCHOOL",
    Address: "MADODONGA  KUTAMA  MAKHADO",
    Latitude: "-23.088",
    Longitude: "29.6788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15035",
    WardLookupId: "1876",
    VDNumber: "76060122",
    RegisteredPopulation: "1393",
    VotingStationName: "KUTAMA SECONDARY SCHOOL",
    Address: "MUDULUNI  KUTAMA  KUTAMA TRIBAL OFFICE",
    Latitude: "-23.071",
    Longitude: "29.6477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15036",
    WardLookupId: "1876",
    VDNumber: "76060649",
    RegisteredPopulation: "675",
    VotingStationName: "MADODONGA PRIMARY SCHOOL",
    Address: "MADODONGA  SINTHUMULE  MAKHADO",
    Latitude: "-23.0901",
    Longitude: "29.678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15037",
    WardLookupId: "1877",
    VDNumber: "76060100",
    RegisteredPopulation: "975",
    VotingStationName: "MUGORORWANE PRIMARY SCHOOL",
    Address: "MURALENI  MURALENI VILLAGE  LOUIS TRICHARDT",
    Latitude: "-23.064",
    Longitude: "29.7201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15038",
    WardLookupId: "1877",
    VDNumber: "76060111",
    RegisteredPopulation: "1717",
    VotingStationName: "MAEBANE PRIMARY SCHOOL",
    Address: "MAEBANE  MAEBANE VILLAGE  LOUIS TRICHARDT",
    Latitude: "-23.0414",
    Longitude: "29.6546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15039",
    WardLookupId: "1877",
    VDNumber: "76060201",
    RegisteredPopulation: "997",
    VotingStationName: "MADAHENI PRIMARY SCHOOL",
    Address: "MAKHITHA  MAKHITHA VILLAGE  LOUIS TRICHARDT",
    Latitude: "-23.0518",
    Longitude: "29.6744",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15040",
    WardLookupId: "1877",
    VDNumber: "76060212",
    RegisteredPopulation: "1341",
    VotingStationName: "PHARANI PRIMARY SCHOOL",
    Address: "MIDORONI  MIDORONI VILLAGE  LTT",
    Latitude: "-23.0299",
    Longitude: "29.6385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15041",
    WardLookupId: "1878",
    VDNumber: "76060133",
    RegisteredPopulation: "944",
    VotingStationName: "MUUNGADI PRIMARY SCHOOL",
    Address: "ZAMENKOMSTE VILLAGE  LOUIS TRICHARDT",
    Latitude: "-23.0644",
    Longitude: "29.5886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15042",
    WardLookupId: "1878",
    VDNumber: "76060166",
    RegisteredPopulation: "1153",
    VotingStationName: "MANAVHELA PRIMARY SCHOOL",
    Address: "MANAVHELA  MANAVHELA VILLAGE  LOUIS TRICHARDT",
    Latitude: "-23.072",
    Longitude: "29.6201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15043",
    WardLookupId: "1878",
    VDNumber: "76060223",
    RegisteredPopulation: "1818",
    VotingStationName: "KUTAMA TERRITORIAL COUNCIL OFFICES",
    Address: "TSHIKWARANI  TSHIKWARANI VILLAGE  LOUIS TRICHARDT",
    Latitude: "-23.0568",
    Longitude: "29.6311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15044",
    WardLookupId: "1878",
    VDNumber: "76060605",
    RegisteredPopulation: "544",
    VotingStationName: "GREAT REVIVAL MINISTRIES",
    Address: "TSHIKWARANI  MAKHADO  KUTAMA",
    Latitude: "-23.046",
    Longitude: "29.6243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15045",
    WardLookupId: "1878",
    VDNumber: "76120073",
    RegisteredPopulation: "414",
    VotingStationName: "MARA PRIMARY SCHOOL",
    Address: "BUYSDORP  ALLDAYS",
    Latitude: "-23.0654",
    Longitude: "29.3941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15046",
    WardLookupId: "1879",
    VDNumber: "76211007",
    RegisteredPopulation: "1484",
    VotingStationName: "GWAMASENGA SECONDARY SCHOOL",
    Address: "GOVHA  TSIANDA  THOHOYANDOU",
    Latitude: "-23.05",
    Longitude: "30.3487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15047",
    WardLookupId: "1879",
    VDNumber: "76211018",
    RegisteredPopulation: "1243",
    VotingStationName: "HAMUTSHA PRIMARY SCHOOL",
    Address: "THONDONI  HAMUTSHA  VUWANI",
    Latitude: "-23.0514",
    Longitude: "30.3599",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15048",
    WardLookupId: "1879",
    VDNumber: "76211029",
    RegisteredPopulation: "1074",
    VotingStationName: "TSHIPETANE SECONDARY SCHOOL",
    Address: "MUUMONI  HAMUTSHA  LTT",
    Latitude: "-23.0619",
    Longitude: "30.366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15049",
    WardLookupId: "1879",
    VDNumber: "76211692",
    RegisteredPopulation: "496",
    VotingStationName: "MAFHUMULELE PRIMAY SCHOOL",
    Address: "HA- MUTSHA  VUWANI  MAKHADO",
    Latitude: "-23.0571",
    Longitude: "30.3671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15050",
    WardLookupId: "1879",
    VDNumber: "76211704",
    RegisteredPopulation: "651",
    VotingStationName: "MUTANGWA MANUGU PRIMARY SCHOOL",
    Address: "GOVHAMAGIDI  TSIANDA  TSHAKHUMA",
    Latitude: "-23.0485",
    Longitude: "30.3527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15051",
    WardLookupId: "1880",
    VDNumber: "76211085",
    RegisteredPopulation: "2548",
    VotingStationName: "RALUTHAGA PRIMARY SCHOOL",
    Address: "MAVHULANI  TSHAKHUMA  LOUISTRICHARDT",
    Latitude: "-23.0423",
    Longitude: "30.2981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15052",
    WardLookupId: "1880",
    VDNumber: "76211096",
    RegisteredPopulation: "948",
    VotingStationName: "MASUVHELELE PRIMARY SCHOOL",
    Address: "MULANGAPHUMA  TSHAKHUMA  THOHOYANDOU",
    Latitude: "-23.0532",
    Longitude: "30.2921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15053",
    WardLookupId: "1880",
    VDNumber: "76211108",
    RegisteredPopulation: "588",
    VotingStationName: "GOOD SHEPHERD COMMUNITY CRECHE",
    Address: "STAND 21. TSHIINGANWE  MAKHADO  TSHAKHUMA",
    Latitude: "-23.0602",
    Longitude: "30.2952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15054",
    WardLookupId: "1880",
    VDNumber: "76211119",
    RegisteredPopulation: "858",
    VotingStationName: "LEVUBU LAERSKOOL",
    Address: "LEVUBU  LEVUBU 15LT  LOUIS TRICHARDT",
    Latitude: "-23.0943",
    Longitude: "30.2903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15055",
    WardLookupId: "1880",
    VDNumber: "76211445",
    RegisteredPopulation: "741",
    VotingStationName: "TSHITAVHADULU PRIMARY SCHOOL",
    Address: "TSHITAVHADULU  LOUIS TRICHARDT  TSHAKHUMA",
    Latitude: "-23.0688",
    Longitude: "30.2897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15056",
    WardLookupId: "1881",
    VDNumber: "76211030",
    RegisteredPopulation: "1256",
    VotingStationName: "MBOSWOBENI PRIMARY SCHOOL",
    Address: "MASHAMBA  TSIANDA  MAKHADO",
    Latitude: "-23.0511",
    Longitude: "30.3368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15057",
    WardLookupId: "1881",
    VDNumber: "76211052",
    RegisteredPopulation: "1142",
    VotingStationName: "MATAVHA PRIMARY SCHOOL",
    Address: "DZAMANWA  TSHAKHUMA/MATAVHA  THOHOYANDOU",
    Latitude: "-23.0633",
    Longitude: "30.3201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15058",
    WardLookupId: "1881",
    VDNumber: "76211063",
    RegisteredPopulation: "991",
    VotingStationName: "LUVHALANI PRIMARY SCHOOL",
    Address: "LUVHALANI  TSHAKHUMA  VUWANI",
    Latitude: "-23.0551",
    Longitude: "30.3042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15059",
    WardLookupId: "1881",
    VDNumber: "76211074",
    RegisteredPopulation: "1101",
    VotingStationName: "TSHIEMUEMU SENIOR SCHOOL",
    Address: "LUVHALANI  TSHAKHUMA  LTT",
    Latitude: "-23.0598",
    Longitude: "30.3017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15060",
    WardLookupId: "1882",
    VDNumber: "76370036",
    RegisteredPopulation: "1075",
    VotingStationName: "BELE PRIMARY SCHOOL",
    Address: "VUVHA  NZHELELE",
    Latitude: "-22.993",
    Longitude: "30.2008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15061",
    WardLookupId: "1882",
    VDNumber: "76370047",
    RegisteredPopulation: "531",
    VotingStationName: "MAGUADA SCHOOL",
    Address: "VUVHA  NZHELELE",
    Latitude: "-22.9886",
    Longitude: "30.2078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15062",
    WardLookupId: "1882",
    VDNumber: "76370058",
    RegisteredPopulation: "1350",
    VotingStationName: "TSHIFHIRE PRIMARY SCHOOL",
    Address: "MAELULA  NZHELELE",
    Latitude: "-22.9835",
    Longitude: "30.1494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15063",
    WardLookupId: "1882",
    VDNumber: "76370788",
    RegisteredPopulation: "1217",
    VotingStationName: "NYATEMA PRIMARY SCHOOL",
    Address: "MURUNWA  LOUIS TRICHARDT",
    Latitude: "-22.9804",
    Longitude: "30.1631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15064",
    WardLookupId: "1882",
    VDNumber: "76370799",
    RegisteredPopulation: "927",
    VotingStationName: "RADZILANI PRIMARY SCHOOL",
    Address: "TSHEDZA  LOUIS TRICHARDT",
    Latitude: "-22.9732",
    Longitude: "30.1857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15065",
    WardLookupId: "1883",
    VDNumber: "76210422",
    RegisteredPopulation: "1018",
    VotingStationName: "VHUDZANI SECONDARY SCHOOL",
    Address: "THONDONI  MASHAU  LOUISTRICHARDT",
    Latitude: "-23.1406",
    Longitude: "30.1878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15066",
    WardLookupId: "1883",
    VDNumber: "76210433",
    RegisteredPopulation: "1025",
    VotingStationName: "KETLANI PRIMARY SCHOOL",
    Address: "MAMBEDI  VALDEZIA  HLANGANANI",
    Latitude: "-23.1195",
    Longitude: "30.2172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15067",
    WardLookupId: "1883",
    VDNumber: "76210455",
    RegisteredPopulation: "705",
    VotingStationName: "DR MARIVATE",
    Address: "MAHAMU  VALDESIA  LOUIS TRICHARDT",
    Latitude: "-23.1052",
    Longitude: "30.2049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15068",
    WardLookupId: "1883",
    VDNumber: "76211614",
    RegisteredPopulation: "951",
    VotingStationName: "NKHENSA LOWER PRIMARY SCHOOL",
    Address: "VALDEZIA  KHOMANANI  MAKHADO",
    Latitude: "-23.1082",
    Longitude: "30.1819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15069",
    WardLookupId: "1883",
    VDNumber: "76370733",
    RegisteredPopulation: "236",
    VotingStationName: "GODENI FARM HALL",
    Address: "SCHOONUITZICHT FARM RATOMBO  GODENI FARM  GODENI",
    Latitude: "-23.0602",
    Longitude: "30.1494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15070",
    WardLookupId: "1883",
    VDNumber: "76371004",
    RegisteredPopulation: "1072",
    VotingStationName: "MAKWATAMBANI PRIMARY SCHOOL",
    Address: "MAELULA  MAILULA   SINTHUMULE",
    Latitude: "-22.9904",
    Longitude: "30.1265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15071",
    WardLookupId: "1884",
    VDNumber: "76370104",
    RegisteredPopulation: "714",
    VotingStationName: "TSHALA SECONDARY SCHOOL",
    Address: "MAMUHOHI  NZHELELE",
    Latitude: "-22.8705",
    Longitude: "30.1169",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15072",
    WardLookupId: "1884",
    VDNumber: "76370115",
    RegisteredPopulation: "1252",
    VotingStationName: "GADABI PRIMARY SCHOOL",
    Address: "MANDIWANA  NZHELELE",
    Latitude: "-22.875",
    Longitude: "30.1245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15073",
    WardLookupId: "1884",
    VDNumber: "76370148",
    RegisteredPopulation: "1407",
    VotingStationName: "DZATA JUNIOR SECONDARY SCHOOL",
    Address: "MIGAVHINI  NZHELELE",
    Latitude: "-22.8754",
    Longitude: "30.14",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15074",
    WardLookupId: "1884",
    VDNumber: "76370643",
    RegisteredPopulation: "1588",
    VotingStationName: "DIMBANYIKA SECONDARY SCHOOL",
    Address: "FUNYUFUNYU  NZHELELE",
    Latitude: "-22.8928",
    Longitude: "30.1154",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15075",
    WardLookupId: "1885",
    VDNumber: "76370126",
    RegisteredPopulation: "929",
    VotingStationName: "SHURA PRIMARY SCHOOL",
    Address: "MAKATU  NZHELELE",
    Latitude: "-22.8809",
    Longitude: "30.16",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15076",
    WardLookupId: "1885",
    VDNumber: "76370137",
    RegisteredPopulation: "1142",
    VotingStationName: "MPHEPHU SECONDARY SCHOOL",
    Address: "MANNGO VILLAGE  MANNGO  MPHEPHU",
    Latitude: "-22.8854",
    Longitude: "30.1538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15077",
    WardLookupId: "1885",
    VDNumber: "76370159",
    RegisteredPopulation: "694",
    VotingStationName: "SENDEDZA PRIMARY SCHOOL",
    Address: "SENDEDZA  NZHELELE",
    Latitude: "-22.8921",
    Longitude: "30.1702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15078",
    WardLookupId: "1885",
    VDNumber: "76370160",
    RegisteredPopulation: "1006",
    VotingStationName: "MUSHAATHONI SECONDARY SCHOOL",
    Address: "SENDEDZA  TSHAVHALOVHEDZI  NZHELELE",
    Latitude: "-22.8968",
    Longitude: "30.1815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15079",
    WardLookupId: "1885",
    VDNumber: "76370216",
    RegisteredPopulation: "819",
    VotingStationName: "SILOAM PRIMARY SCHOOL",
    Address: "SILOAM  NZHELELE",
    Latitude: "-22.8964",
    Longitude: "30.1903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15080",
    WardLookupId: "1886",
    VDNumber: "76370575",
    RegisteredPopulation: "1709",
    VotingStationName: "KOKWANE PRIMARY SCHOOL",
    Address: "MATIDZA  NZHELELE",
    Latitude: "-22.8803",
    Longitude: "30.0678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15081",
    WardLookupId: "1886",
    VDNumber: "76370586",
    RegisteredPopulation: "1680",
    VotingStationName: "RABALI PRIMARY SCHOOL",
    Address: "RABALI  NZHELELE",
    Latitude: "-22.8761",
    Longitude: "30.0973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15082",
    WardLookupId: "1886",
    VDNumber: "76370597",
    RegisteredPopulation: "1430",
    VotingStationName: "PATRICK RAMAANO SECONDARY SCHOOL",
    Address: "RAMAVHOYA  NZHELELE",
    Latitude: "-22.8797",
    Longitude: "30.0809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15083",
    WardLookupId: "1886",
    VDNumber: "76371059",
    RegisteredPopulation: "759",
    VotingStationName: "PRAISE AND WORSHIP CENTRE",
    Address: "MATANDA  MPHEPHU  MPHEPHU",
    Latitude: "-22.8829",
    Longitude: "30.1037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15084",
    WardLookupId: "1887",
    VDNumber: "76370014",
    RegisteredPopulation: "1066",
    VotingStationName: "MUTITITI PRIMARY SCHOOL",
    Address: "MUTITITI  NZHELELE",
    Latitude: "-22.8832",
    Longitude: "29.9954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15085",
    WardLookupId: "1887",
    VDNumber: "76370542",
    RegisteredPopulation: "1303",
    VotingStationName: "MARANIKWE PRIMARY SCHOOL",
    Address: "TSHITUNI  NZHELELE",
    Latitude: "-22.877",
    Longitude: "30.0624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15086",
    WardLookupId: "1887",
    VDNumber: "76370553",
    RegisteredPopulation: "625",
    VotingStationName: "MAPAKOPHELE PRIMARY SCHOOL",
    Address: "MAPAKOPHELE  NZHELELE",
    Latitude: "-22.8862",
    Longitude: "30.0397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15087",
    WardLookupId: "1887",
    VDNumber: "76370812",
    RegisteredPopulation: "708",
    VotingStationName: "LUATAME SECONDARY SCHOOL",
    Address: "TSHITUNI  TSHITUNI TSHA FHASI  NZHELELE",
    Latitude: "-22.86",
    Longitude: "30.0535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15088",
    WardLookupId: "1887",
    VDNumber: "76370889",
    RegisteredPopulation: "259",
    VotingStationName: "TSHABVUMA PRIMARY SCHOOL",
    Address: "PFUMBADA  DZANANI",
    Latitude: "-22.8609",
    Longitude: "30.0811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15089",
    WardLookupId: "1887",
    VDNumber: "76370890",
    RegisteredPopulation: "458",
    VotingStationName: "HILLSIDE COMBINED SCHOOL",
    Address: "DZANANI  DZANANI EXTENTION  MPHEPHU",
    Latitude: "-22.8943",
    Longitude: "30.0319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15090",
    WardLookupId: "1888",
    VDNumber: "76370328",
    RegisteredPopulation: "1056",
    VotingStationName: "MAMVUKA SECONDARY SCHOOL",
    Address: "MAMVUKA  NZHELELE",
    Latitude: "-22.8688",
    Longitude: "29.9297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15091",
    WardLookupId: "1888",
    VDNumber: "76370508",
    RegisteredPopulation: "869",
    VotingStationName: "MANYII PRIMARY SCHOOL",
    Address: "MANYII  NZHELELE",
    Latitude: "-22.8653",
    Longitude: "29.9267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15092",
    WardLookupId: "1888",
    VDNumber: "76370531",
    RegisteredPopulation: "1287",
    VotingStationName: "LIPHAKHA PRIMARY SCHOOL",
    Address: "MATSA  NZHELELE",
    Latitude: "-22.8689",
    Longitude: "29.9548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15093",
    WardLookupId: "1888",
    VDNumber: "76370687",
    RegisteredPopulation: "1253",
    VotingStationName: "LANGANANI PRIMARY SCHOOL",
    Address: "MUDIMELI  NZHELELE",
    Latitude: "-22.8046",
    Longitude: "29.9514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15094",
    WardLookupId: "1888",
    VDNumber: "76370980",
    RegisteredPopulation: "766",
    VotingStationName: "PARADISE MANAME COMBINED SCHOOL",
    Address:
      "PARADISE TSHIKUWI  PARADISE (TSHIKUWI)  MAKHADO [LOUIS TRICHARDT]",
    Latitude: "-22.8986",
    Longitude: "29.9272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15095",
    WardLookupId: "1889",
    VDNumber: "76370294",
    RegisteredPopulation: "402",
    VotingStationName: "SANE PRIMARY SCHOOL",
    Address: "SANE VILLAGE  NZHELELE  SANE",
    Latitude: "-22.7738",
    Longitude: "30.1791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15096",
    WardLookupId: "1889",
    VDNumber: "76370496",
    RegisteredPopulation: "569",
    VotingStationName: "TSHIENDEULU PRIMARY SCHOOL",
    Address: "TSHIENDEULU  TSHIENDEULU VILLAGE  MPHEPHU",
    Latitude: "-22.8298",
    Longitude: "30.172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15097",
    WardLookupId: "1889",
    VDNumber: "76370698",
    RegisteredPopulation: "369",
    VotingStationName: "MAANGANI PRIMARY SCHOOL",
    Address: "MAANGANI VILLAGE.   MAANGANI  MPHEPHU",
    Latitude: "-22.8257",
    Longitude: "30.0538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15098",
    WardLookupId: "1889",
    VDNumber: "76370700",
    RegisteredPopulation: "775",
    VotingStationName: "MAPANI PRIMARY SCHOOL",
    Address: "MAKUSHU  NZHELELE",
    Latitude: "-22.7647",
    Longitude: "30.0562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15099",
    WardLookupId: "1889",
    VDNumber: "76370711",
    RegisteredPopulation: "385",
    VotingStationName: "MUSEKWA PRIMARY SCHOOL",
    Address: "MUSEKWA  NZHELELE",
    Latitude: "-22.7778",
    Longitude: "30.0758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15100",
    WardLookupId: "1889",
    VDNumber: "76370722",
    RegisteredPopulation: "417",
    VotingStationName: "TSHIANANE SECONDARY SCHOOL",
    Address: "MUSEKWA  NGUNDU VILLAGE  NZHELELE",
    Latitude: "-22.7857",
    Longitude: "30.1042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15101",
    WardLookupId: "1889",
    VDNumber: "76370744",
    RegisteredPopulation: "680",
    VotingStationName: "KHOMELE PRIMARY SCHOOL",
    Address: "KHOMELE    MPHEPHU",
    Latitude: "-22.7064",
    Longitude: "30.2664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15102",
    WardLookupId: "1889",
    VDNumber: "76370755",
    RegisteredPopulation: "156",
    VotingStationName: "KHARIVHA PRIMARY SCHOOL",
    Address: "NDOUVHADA  NZHELELE",
    Latitude: "-22.7193",
    Longitude: "30.2181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15103",
    WardLookupId: "1889",
    VDNumber: "76370766",
    RegisteredPopulation: "192",
    VotingStationName: "DOLIDOLI PRIMARY SCHOOL",
    Address: "DOLIDOLI  MPHEPHU",
    Latitude: "-22.7122",
    Longitude: "30.1832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15104",
    WardLookupId: "1889",
    VDNumber: "76370777",
    RegisteredPopulation: "634",
    VotingStationName: "RAMABULANA SECONDARY SCHOOL",
    Address: "STRAIGHTHARDT  STRAIGHTHARDT/HA-LISHIVHA  NZHELELE",
    Latitude: "-22.7545",
    Longitude: "30.1445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15105",
    WardLookupId: "1889",
    VDNumber: "76370979",
    RegisteredPopulation: "129",
    VotingStationName: "TSHISHIRU PRIMARY SCHOOL",
    Address: "SMOKEY VILLAGE  MAKHADO [LOUIS TRICHARDT]",
    Latitude: "-22.6642",
    Longitude: "30.2874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15106",
    WardLookupId: "1890",
    VDNumber: "76370609",
    RegisteredPopulation: "977",
    VotingStationName: "MAULUMA PRIMARY SCHOOL",
    Address: "MAULUMA  MAKHADO",
    Latitude: "-22.9281",
    Longitude: "30.1355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15107",
    WardLookupId: "1890",
    VDNumber: "76370621",
    RegisteredPopulation: "1240",
    VotingStationName: "TSWIME SECONDARY SCHOOL",
    Address: "MAULUMA  NZHELELE",
    Latitude: "-22.9221",
    Longitude: "30.1195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15108",
    WardLookupId: "1890",
    VDNumber: "76370632",
    RegisteredPopulation: "882",
    VotingStationName: "RALIPHASWA PRIMARY SCHOOL",
    Address: "RALIPHASWA  NZHELELE",
    Latitude: "-22.8957",
    Longitude: "30.1238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15109",
    WardLookupId: "1890",
    VDNumber: "76370654",
    RegisteredPopulation: "511",
    VotingStationName: "MPHAILA PRIMARY SCHOOL",
    Address: "MPHAILA  NZHELELE",
    Latitude: "-22.8982",
    Longitude: "30.1537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15110",
    WardLookupId: "1890",
    VDNumber: "76370878",
    RegisteredPopulation: "1063",
    VotingStationName: "MAKUNGWI PRIMARY SCHOOL",
    Address: "MAKUNGWI  MAKUNGWI VILLAGE  DZANANI",
    Latitude: "-22.9124",
    Longitude: "30.1196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15111",
    WardLookupId: "1890",
    VDNumber: "76371048",
    RegisteredPopulation: "532",
    VotingStationName: "TSHAMAKWATINI PRIMARY SCHOOL",
    Address: "HA- MAVHUNGA  MAVHUNGA  MAKHADO",
    Latitude: "-22.92",
    Longitude: "30.1095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15112",
    WardLookupId: "1890",
    VDNumber: "76371060",
    RegisteredPopulation: "390",
    VotingStationName: "FUNYUFUNYU PRIMARY SCHOOL",
    Address: "HA FUNYUFUNYU  DZANANI  MAKHADO",
    Latitude: "-22.8854",
    Longitude: "30.1174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15113",
    WardLookupId: "3926",
    VDNumber: "76330054",
    RegisteredPopulation: "1167",
    VotingStationName: "NKANYANI PRIMARY SCHOOL",
    Address: "OLIFANTSHOEK VILLAGE  MALAMULELE  RUNGULANI TRIBAL OFFICE",
    Latitude: "-23.3091",
    Longitude: "30.2901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15114",
    WardLookupId: "3926",
    VDNumber: "76330065",
    RegisteredPopulation: "1163",
    VotingStationName: "RUNGULANI TRIBAL OFFICE",
    Address: "OLIFANTSHOEK VILLAGE  MALAMULELE  RUNGULANI TRIBAL OFFICE",
    Latitude: "-23.3327",
    Longitude: "30.2744",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15115",
    WardLookupId: "3926",
    VDNumber: "76330076",
    RegisteredPopulation: "939",
    VotingStationName: "OLIFANTSHOEK SWISS MISSION",
    Address: "OLIFANTSHOEK VILLAGE  MALAMULELE  RUNGULANI TRIBAL OFFICE",
    Latitude: "-23.3432",
    Longitude: "30.2767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15116",
    WardLookupId: "3926",
    VDNumber: "76330133",
    RegisteredPopulation: "792",
    VotingStationName: "PFUMELANI HIGHER PRIMARY SCHOOL",
    Address: "OLIFANTSHOEK VILLAGE  MALAMULELE  RUNGULANI TRIBAL OFFICE",
    Latitude: "-23.3522",
    Longitude: "30.2891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15117",
    WardLookupId: "3926",
    VDNumber: "76330436",
    RegisteredPopulation: "621",
    VotingStationName: "MARHORHWANI MALALI SECONDARY SCHOOL",
    Address:
      "OLIFANTSHOEK VILLAGE            MALAMULELE  RUNGULANI TRIBAL OFFICE",
    Latitude: "-23.3322",
    Longitude: "30.2864",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15118",
    WardLookupId: "3927",
    VDNumber: "76210624",
    RegisteredPopulation: "897",
    VotingStationName: "MAWELA PRIMARY SCHOOL",
    Address: "SERENI  MASAKONA  LTT",
    Latitude: "-23.2715",
    Longitude: "30.1705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15119",
    WardLookupId: "3927",
    VDNumber: "76210635",
    RegisteredPopulation: "740",
    VotingStationName: "TSHINANGA SECONDARY SCHOOL",
    Address: "MAGORO VILLAGE  MASAKONA  LTT",
    Latitude: "-23.2917",
    Longitude: "30.2398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15120",
    WardLookupId: "3927",
    VDNumber: "76210646",
    RegisteredPopulation: "1225",
    VotingStationName: "MASAKONA LOWER PRIMARY SCHOOL",
    Address: "MASAKONA VILLAGE  MALAMULELE  MASAKONA TRIBAL OFFICE",
    Latitude: "-23.2732",
    Longitude: "30.2571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15121",
    WardLookupId: "3927",
    VDNumber: "76210668",
    RegisteredPopulation: "1094",
    VotingStationName: "RIVONINGO PRIMARY SCHOOL",
    Address: "RIBUNGWANI VILLAGE  MALAMULELE  TIYANI TRIBAL OFFICE",
    Latitude: "-23.2814",
    Longitude: "30.2922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15122",
    WardLookupId: "3928",
    VDNumber: "76211513",
    RegisteredPopulation: "526",
    VotingStationName: "YINGWANI RIBUNGWANI SECONDARY SCHOOL",
    Address: "RIBUNGWANI  VONGANI  HLANGANANI",
    Latitude: "-23.2734",
    Longitude: "30.2899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15123",
    WardLookupId: "3928",
    VDNumber: "76330010",
    RegisteredPopulation: "957",
    VotingStationName: "NWAMALOBYE SECONDARY SCHOOL",
    Address: "MAGORO  TIYANI   TIYANI TRABAL OFFICE",
    Latitude: "-23.2967",
    Longitude: "30.3325",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15124",
    WardLookupId: "3928",
    VDNumber: "76330021",
    RegisteredPopulation: "773",
    VotingStationName: "KULANI PRIMARY SCHOOL",
    Address: "SHIHIMU VILLAGE  MALAMULELE  TIYANI TRIBAL OFFICE",
    Latitude: "-23.2955",
    Longitude: "30.3234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15125",
    WardLookupId: "3928",
    VDNumber: "76330032",
    RegisteredPopulation: "1457",
    VotingStationName: "TIYANI TRIBAL OFFICE",
    Address: "MAGORO VILLAGE  MALAMULELE  TIYANI TRIBAL OFFICE",
    Latitude: "-23.2992",
    Longitude: "30.3144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15126",
    WardLookupId: "3928",
    VDNumber: "76330043",
    RegisteredPopulation: "1385",
    VotingStationName: "VUNGELA PRIMARY SCHOOL",
    Address: "MANGULWANA VILLAGE  MALAMULELE  TIYANI TRIBAL OFFICE",
    Latitude: "-23.3045",
    Longitude: "30.3034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15127",
    WardLookupId: "3929",
    VDNumber: "76210332",
    RegisteredPopulation: "677",
    VotingStationName: "HLUVUKA HIGH SCHOOL",
    Address: "BUNGENI VILLAGE  MALAMULELE  BUNGENI TRIBAL AUTHORITY",
    Latitude: "-23.2057",
    Longitude: "30.2315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15128",
    WardLookupId: "3929",
    VDNumber: "76210343",
    RegisteredPopulation: "451",
    VotingStationName: "NJAKANJAKA PRIMARY SCHOOL",
    Address: "NJAKANJAKA VILLAGE  MALAMULELE  BUNGENI TRIBAL AUTHORITY",
    Latitude: "-23.2122",
    Longitude: "30.2399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15129",
    WardLookupId: "3929",
    VDNumber: "76210365",
    RegisteredPopulation: "1141",
    VotingStationName: "MARHOLENI SENIOR SCHOOL",
    Address: "NJAKANJAKA VILLAGE  MALAMULELE  BUNGENI TRIBAL AUTHORITY",
    Latitude: "-23.2166",
    Longitude: "30.2444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15130",
    WardLookupId: "3929",
    VDNumber: "76211164",
    RegisteredPopulation: "1282",
    VotingStationName: "MAHATLANI PRIMARY SCHOOL",
    Address: "MAHATLANI VILLAGE  MALAMULELE  MAHATLANI TRIBAL AUTHORITY",
    Latitude: "-23.2376",
    Longitude: "30.2797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15131",
    WardLookupId: "3930",
    VDNumber: "76210309",
    RegisteredPopulation: "913",
    VotingStationName: "BHUNGENI PRIMARY SCHOOL",
    Address: "SHITACHI VILLAGE  MALAMULELE  BUNGENI TRIBAL AUTHORITY",
    Latitude: "-23.2088",
    Longitude: "30.2077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15132",
    WardLookupId: "3930",
    VDNumber: "76210310",
    RegisteredPopulation: "1201",
    VotingStationName: "SHITACHI PRIMARY SCHOOL",
    Address: "BUNGENI VILLAGE  BUNGENI  BUNGENI TRIBAL AUTHORITY",
    Latitude: "-23.2054",
    Longitude: "30.2225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15133",
    WardLookupId: "3930",
    VDNumber: "76210321",
    RegisteredPopulation: "1691",
    VotingStationName: "MTSWETENI PRIMARY SCHOOL",
    Address: "MTSWETENI VILLAGE  MALAMULELE  BUNGENI TRIBAL AUTHORITY",
    Latitude: "-23.191",
    Longitude: "30.2287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15134",
    WardLookupId: "3930",
    VDNumber: "76210354",
    RegisteredPopulation: "364",
    VotingStationName: "NWAMHANDZI PRIMARY SCHOOL",
    Address: "NWAMHANDZI VILLAGE  MALAMULELE  BUNGENI TRIBAL AUTHORITY",
    Latitude: "-23.2204",
    Longitude: "30.235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15135",
    WardLookupId: "3930",
    VDNumber: "76211625",
    RegisteredPopulation: "512",
    VotingStationName: "RUSSEL BUNGENI SECONDARY SCHOOL",
    Address: "BUNGENI VILLAGE  MALAMULELE  BUNGENI TRIBAL AUTHORITY",
    Latitude: "-23.2035",
    Longitude: "30.2011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15136",
    WardLookupId: "3931",
    VDNumber: "76210376",
    RegisteredPopulation: "585",
    VotingStationName: "RADZAMBO SECONDARY SCHOOL",
    Address: "MATSILA VILLAGE  MALAMULELE  MASHAU TRIBAL AUTHORITY",
    Latitude: "-23.1925",
    Longitude: "30.2627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15137",
    WardLookupId: "3931",
    VDNumber: "76210589",
    RegisteredPopulation: "1431",
    VotingStationName: "NKUZANA PRIMARY SCHOOL",
    Address: "NKUZANA VILLAGE  MALAMULELE  KHOMANANI TRIBAL AUTHORITY",
    Latitude: "-23.2152",
    Longitude: "30.2916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15138",
    WardLookupId: "3931",
    VDNumber: "76211243",
    RegisteredPopulation: "831",
    VotingStationName: "HLANGANANI RDP SECTION - TEMPORARY (TENT)",
    Address: "NKUZANA VILLAGE  MALAMULELE  KHOMANANI TRIBAL AUTHORITY",
    Latitude: "-23.2214",
    Longitude: "30.2681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15139",
    WardLookupId: "3931",
    VDNumber: "76211434",
    RegisteredPopulation: "440",
    VotingStationName: "MHLURI PRIMARY SCHOOL",
    Address: "NKUZANA VILLAGE  MALAMULELE  KHOMANANI TRIBAL AUTHORITY",
    Latitude: "-23.2094",
    Longitude: "30.2547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15140",
    WardLookupId: "3931",
    VDNumber: "76211546",
    RegisteredPopulation: "177",
    VotingStationName: "DE HOOP PRIMARY SCHOOL",
    Address: "MATSILA VILLAGE  MALAMULELE  MASIA TRIBAL AUTHORITY",
    Latitude: "-23.1878",
    Longitude: "30.2797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15141",
    WardLookupId: "3931",
    VDNumber: "76211568",
    RegisteredPopulation: "609",
    VotingStationName: "GIJA PRIMARY SCHOOL",
    Address: "NKUZANA VILLAGE  MALAMULELE  KHOMANANI TRIBAL AUTHORITY",
    Latitude: "-23.2088",
    Longitude: "30.286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15142",
    WardLookupId: "3932",
    VDNumber: "76210387",
    RegisteredPopulation: "741",
    VotingStationName: "FRANK MUKHASWAKULE SCHOOL",
    Address: "MASHAU,THENGA  MALAMULELE  MASHAU TRIBAL AUTHORITY",
    Latitude: "-23.1751",
    Longitude: "30.2344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15143",
    WardLookupId: "3932",
    VDNumber: "76210398",
    RegisteredPopulation: "1190",
    VotingStationName: "MATSINDEVHE PRIMARY SCHOOL",
    Address: "MASHAU,MATSINDEVHE  MALAMULELE  MASHAU TRIBAL AUTHORITY",
    Latitude: "-23.1725",
    Longitude: "30.2093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15144",
    WardLookupId: "3932",
    VDNumber: "76210400",
    RegisteredPopulation: "1271",
    VotingStationName: "VHAFAMADI HIGH SCHOOL",
    Address: "MASHAU,TSHIRANDO  MALAMULELE  MASHAU TRIBAL AUTHORITY",
    Latitude: "-23.1574",
    Longitude: "30.2253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15145",
    WardLookupId: "3932",
    VDNumber: "76211557",
    RegisteredPopulation: "369",
    VotingStationName: "MPHAGANE PRIMARY SCHOOL",
    Address: "MAGWENI HA MASHAU  MALAMULELE  MASHAU TRIBAL AUTHORITY",
    Latitude: "-23.1635",
    Longitude: "30.227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15146",
    WardLookupId: "3933",
    VDNumber: "76210411",
    RegisteredPopulation: "1097",
    VotingStationName: "KHWARA SECONDARY SCHOOL",
    Address: "MASHAU,DOLI VILLAGE  MALAMULELE  MASHAU TRIBAL AUTHORITY",
    Latitude: "-23.1595",
    Longitude: "30.2662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15147",
    WardLookupId: "3933",
    VDNumber: "76210556",
    RegisteredPopulation: "1298",
    VotingStationName: "RAMAUBA SECONDARY SCHOOL",
    Address: "MASIA VILLAGE  MALAMULELE  MASIA TRIBAL AUTHORITY",
    Latitude: "-23.1799",
    Longitude: "30.3067",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15148",
    WardLookupId: "3933",
    VDNumber: "76211254",
    RegisteredPopulation: "604",
    VotingStationName: "LUPEDZE PRIMARY SCHOOL",
    Address: "MASHAU,MUKHORO VILLAGE  MALAMULELE  MASHAU TRIBAL AUTHORITY",
    Latitude: "-23.1616",
    Longitude: "30.2448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15149",
    WardLookupId: "3933",
    VDNumber: "76211401",
    RegisteredPopulation: "557",
    VotingStationName: "MATHOTHWE JUNIOR PRIMARY SCHOOL",
    Address: "MASHAU VILLAGE  MALAMULELE  MASHAU TRIBAL AUTHORITY",
    Latitude: "-23.1665",
    Longitude: "30.2852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15150",
    WardLookupId: "3934",
    VDNumber: "76210567",
    RegisteredPopulation: "726",
    VotingStationName: "KURULENI PRIMARY SCHOOL",
    Address: "KURULENI VILLAGE  MALAMULELE  KURULENI",
    Latitude: "-23.1472",
    Longitude: "30.359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15151",
    WardLookupId: "3934",
    VDNumber: "76210578",
    RegisteredPopulation: "1141",
    VotingStationName: "VHANGANI PRIMARY SCHOOL",
    Address: "VHANGANI VILLAGE  MALAMULELE  MASIA TRIBAL AUTHORITY",
    Latitude: "-23.2073",
    Longitude: "30.3328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15152",
    WardLookupId: "3934",
    VDNumber: "76210736",
    RegisteredPopulation: "695",
    VotingStationName: "TSHINAVHE SECONDARY SCHOOL",
    Address: "VYEBOOM C VILLAGE  MALAMULELE  MASIA TRIBAL AUTHORITY",
    Latitude: "-23.1425",
    Longitude: "30.3863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15153",
    WardLookupId: "3934",
    VDNumber: "76211344",
    RegisteredPopulation: "518",
    VotingStationName: "MUHUVHINI PRIMARY SCHOOL",
    Address: "MUHUVHINI,KURULENI VILLAGE  MALAMULELE  KURULENI",
    Latitude: "-23.1703",
    Longitude: "30.3421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15154",
    WardLookupId: "3934",
    VDNumber: "76211535",
    RegisteredPopulation: "339",
    VotingStationName: "MUDINANE SECONDARY SCHOOL",
    Address: "MASIA VILLAGE  MALAMULELE  MASIA TRIBAL AUTHORITY",
    Latitude: "-23.1939",
    Longitude: "30.3312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15155",
    WardLookupId: "3935",
    VDNumber: "76210590",
    RegisteredPopulation: "1482",
    VotingStationName: "KHOMANANI TRADITIONAL COUNCIL",
    Address: "MAJOSI  KHOMANANI  LOUIS TRICHARDT",
    Latitude: "-23.2214",
    Longitude: "30.3355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15156",
    WardLookupId: "3935",
    VDNumber: "76210602",
    RegisteredPopulation: "1249",
    VotingStationName: "CALEDON PRIMARY SCHOOL",
    Address: "NWAMATATANI VILLAGE  MALAMULELE  KHOMANANI TRIBAL AUTHORITY",
    Latitude: "-23.2476",
    Longitude: "30.367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15157",
    WardLookupId: "3935",
    VDNumber: "76211377",
    RegisteredPopulation: "495",
    VotingStationName: "KHOMANANI HIGH SCHOOL",
    Address: "MAJOSI VILLAGE  MALAMULELE  KHOMANANI TRIBAL AUTHORITY",
    Latitude: "-23.2244",
    Longitude: "30.3527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15158",
    WardLookupId: "3935",
    VDNumber: "76211502",
    RegisteredPopulation: "557",
    VotingStationName: "JOE MABENDLE PRIMARY SCHOOL",
    Address: "CALEDON  ELIM  MAKHADO",
    Latitude: "-23.234",
    Longitude: "30.3727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15159",
    WardLookupId: "3935",
    VDNumber: "76211524",
    RegisteredPopulation: "442",
    VotingStationName: "KHAPHAKHAPHA PRIMARY SCHOOL",
    Address: "MAJOSI VILLAGE  MALAMULELE  KHOMANANI TRIBAL AUTHORITY",
    Latitude: "-23.225",
    Longitude: "30.3416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15160",
    WardLookupId: "3936",
    VDNumber: "76210679",
    RegisteredPopulation: "568",
    VotingStationName: "PEMBUNUKA PRIMARY SCHOOL",
    Address: "MAKHASA VILLAGE  MALAMULELE  KHOMANANI TRIBAL AUTHORITY",
    Latitude: "-23.2189",
    Longitude: "30.4148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15161",
    WardLookupId: "3936",
    VDNumber: "76210703",
    RegisteredPopulation: "1018",
    VotingStationName: "MADOBI PRIMARY SCHOOL",
    Address: "MADOBI VILLAGE  MALAMULELE  KHOMANANI TRIBAL AUTHORITY",
    Latitude: "-23.1894",
    Longitude: "30.3949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15162",
    WardLookupId: "3936",
    VDNumber: "76210725",
    RegisteredPopulation: "975",
    VotingStationName: "TSHIRUNZANANI PRIMARY SCHOOL",
    Address: "MASIA,VYEBOOM A  MALAMULELE  MASIA TRIBAL AUTHORITY",
    Latitude: "-23.1622",
    Longitude: "30.3986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15163",
    WardLookupId: "3936",
    VDNumber: "76211197",
    RegisteredPopulation: "564",
    VotingStationName: "SUNDANI PRIMARY SCHOOL",
    Address: "SUNDANI VILLAGE  LTT",
    Latitude: "-23.2271",
    Longitude: "30.4265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15164",
    WardLookupId: "3936",
    VDNumber: "76211276",
    RegisteredPopulation: "628",
    VotingStationName: "AVHATONDI PRIMARY SCHOOL",
    Address: "VYEBOOM  VILLAGE  MALAMULELE  MASIA TRIBAL AUTHORITY",
    Latitude: "-23.141",
    Longitude: "30.3964",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15165",
    WardLookupId: "3937",
    VDNumber: "76210680",
    RegisteredPopulation: "290",
    VotingStationName: "HAMADZEMBE TRIBAL OFFICES",
    Address: "BOFULAMATO HAMADZEMBE  VUWANI  NNGWEKHULU . DAVHANA",
    Latitude: "-23.1937",
    Longitude: "30.426",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15166",
    WardLookupId: "3937",
    VDNumber: "76210691",
    RegisteredPopulation: "670",
    VotingStationName: "NNGWEKHULU PRIMARY SCHOOL",
    Address: "NNGWEKHULU VILLAGE  MALAMULELE  DAVHANA TRIBAL AUTHORITY",
    Latitude: "-23.1806",
    Longitude: "30.4334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15167",
    WardLookupId: "3937",
    VDNumber: "76210714",
    RegisteredPopulation: "1206",
    VotingStationName: "MATAMELA PRIMARY SCHOOL",
    Address: "BALANGANANI  DAVHANA  VUWANI",
    Latitude: "-23.2119",
    Longitude: "30.4614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15168",
    WardLookupId: "3937",
    VDNumber: "76210848",
    RegisteredPopulation: "940",
    VotingStationName: "MATODZI SECONDARY SCHOOL",
    Address: "MALONGA VILLAGE  MALAMULELE  TSHIMBUPFE TRIBAL",
    Latitude: "-23.2343",
    Longitude: "30.5159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15169",
    WardLookupId: "3937",
    VDNumber: "76211232",
    RegisteredPopulation: "285",
    VotingStationName: "MPOFU PRIMARY SCHOOL",
    Address: "MABIDI VILLAGE  MALAMULELE  DAVHANA TRIBAL AUTHORITY",
    Latitude: "-23.2341",
    Longitude: "30.4904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15170",
    WardLookupId: "3937",
    VDNumber: "76211399",
    RegisteredPopulation: "108",
    VotingStationName: "NDAENI PRIMARY SCHOOL",
    Address: "TSHIRULULUNI VILLAGE  MALAMULELE  DAVHANA TRIBAL AUTHORITY",
    Latitude: "-23.1784",
    Longitude: "30.4678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15171",
    WardLookupId: "3938",
    VDNumber: "76210815",
    RegisteredPopulation: "1484",
    VotingStationName: "SIGWAVHULIMU SCHOOL",
    Address: "MUTHEIWANA  TSHIMBUPFE  VUWANI",
    Latitude: "-23.1558",
    Longitude: "30.4713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15172",
    WardLookupId: "3938",
    VDNumber: "76210826",
    RegisteredPopulation: "673",
    VotingStationName: "TSHIMBUPFE TRADITIONAL COUNCIL",
    Address: "TSHIMBUPFE  VUWANI",
    Latitude: "-23.1767",
    Longitude: "30.4855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15173",
    WardLookupId: "3938",
    VDNumber: "76210837",
    RegisteredPopulation: "598",
    VotingStationName: "HANANI PRIMARY SCHOOL",
    Address: "HANANI VILLAGE  TSHIMBUPFE  VUWANI",
    Latitude: "-23.1745",
    Longitude: "30.5229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15174",
    WardLookupId: "3938",
    VDNumber: "76211265",
    RegisteredPopulation: "856",
    VotingStationName: "MUTHEIWANA SECONDARY SCHOOL",
    Address: "SHIELFARM - TSHIMBUPFE  MUTHEIWANA SEC SCHOOL  VUWANI",
    Latitude: "-23.1493",
    Longitude: "30.4606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15175",
    WardLookupId: "3939",
    VDNumber: "76210747",
    RegisteredPopulation: "878",
    VotingStationName: "VUWANI MUNICIPAL BUILDING",
    Address: "VUWANI LOC  VUWANI  VUWANI",
    Latitude: "-23.1304",
    Longitude: "30.4199",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15176",
    WardLookupId: "3939",
    VDNumber: "76210758",
    RegisteredPopulation: "1432",
    VotingStationName: "TSHIVHULANA PRIMARY SCHOOL",
    Address: "TSHIVHULANA  TSHIMBUPFE  VUWANI",
    Latitude: "-23.1325",
    Longitude: "30.4288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15177",
    WardLookupId: "3939",
    VDNumber: "76210859",
    RegisteredPopulation: "1413",
    VotingStationName: "RATSHIKWEKWETE SCHOOL",
    Address: "TSHITUNGULWANE  TSHIMBUPFE  VUWANI",
    Latitude: "-23.0996",
    Longitude: "30.4434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15178",
    WardLookupId: "3939",
    VDNumber: "76211388",
    RegisteredPopulation: "574",
    VotingStationName: "MANAVHELA ASSEMBLY OF GOD",
    Address: "NO STREET ADDRESS AVAILABLE  MANAVHELA   VHEMBE  ",
    Latitude: "-23.1",
    Longitude: "30.4608",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15179",
    WardLookupId: "3940",
    VDNumber: "76211490",
    RegisteredPopulation: "216",
    VotingStationName: "SEWADAWADA PRIMARY SCHOOL",
    Address: "MUZIAFERA  VUWANI  VUWANI",
    Latitude: "-23.0911",
    Longitude: "30.4564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15180",
    WardLookupId: "3940",
    VDNumber: "76290093",
    RegisteredPopulation: "1022",
    VotingStationName: "MBHALATI PRIMARY SCHOOL",
    Address: "MBHALATI  MALAMULELE",
    Latitude: "-23.1199",
    Longitude: "30.5",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15181",
    WardLookupId: "3940",
    VDNumber: "76290105",
    RegisteredPopulation: "1375",
    VotingStationName: "TWANANANI PRIMARY SCHOOL",
    Address: "MDABULA-SALAMI  MALAMULELE  MALAMULELE",
    Latitude: "-23.1235",
    Longitude: "30.5313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15182",
    WardLookupId: "3940",
    VDNumber: "76290116",
    RegisteredPopulation: "902",
    VotingStationName: "MACHELE PRIMARY SCHOOL",
    Address: "MDADULA-MACHELE  MALAMULELE  MALAMULELE",
    Latitude: "-23.1372",
    Longitude: "30.5246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15183",
    WardLookupId: "3940",
    VDNumber: "76291117",
    RegisteredPopulation: "661",
    VotingStationName: "RISANA PRIMARY SCHOOL",
    Address: "MUDABULA VILLAGE  MALAMULELE  MALAMULELE",
    Latitude: "-23.1232",
    Longitude: "30.5184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15184",
    WardLookupId: "3941",
    VDNumber: "76290127",
    RegisteredPopulation: "786",
    VotingStationName: "MULAMULA PRIMARY SCHOOL",
    Address: "STAND NO. 70, DUMELA VILLAGE  MALAMULELE  MULAMULA TRIBAL",
    Latitude: "-23.1188",
    Longitude: "30.5954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15185",
    WardLookupId: "3941",
    VDNumber: "76290138",
    RegisteredPopulation: "858",
    VotingStationName: "MUDABULA PRIMARY SCHOOL",
    Address: "XIHOSANA VILLAGE  MALAMULELE  MDAVULA TRIBAL",
    Latitude: "-23.1636",
    Longitude: "30.5628",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15186",
    WardLookupId: "3941",
    VDNumber: "76290149",
    RegisteredPopulation: "622",
    VotingStationName: "MUSWANE PRIMARY SCHOOL",
    Address: "MUSWANE VILLAGE  MALAMULELE  MULAMULA TRIBAL",
    Latitude: "-23.1472",
    Longitude: "30.6302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15187",
    WardLookupId: "3941",
    VDNumber: "76290846",
    RegisteredPopulation: "717",
    VotingStationName: "NHOMBELANI PRIMARY SCHOOL",
    Address: "NHOMBELANI VILLAGE  MALAMULELE  MULAMULA TRIBAL",
    Latitude: "-23.1297",
    Longitude: "30.5781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15188",
    WardLookupId: "3941",
    VDNumber: "76291016",
    RegisteredPopulation: "521",
    VotingStationName: "SHIHOSANA HEALING MINISTERY CHURCH",
    Address: "SHIHOSANA VILLAGE  MALAMULELE   MDABULA TRIBAL",
    Latitude: "-23.1635",
    Longitude: "30.5786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15189",
    WardLookupId: "3941",
    VDNumber: "76291038",
    RegisteredPopulation: "460",
    VotingStationName: "FRANS SOMBHANI SECONDARY SCHOOL",
    Address: "MUSWANE VILLAGE  MALAMULELE  MULAMULA TRIBAL ",
    Latitude: "-23.1567",
    Longitude: "30.6282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15190",
    WardLookupId: "3941",
    VDNumber: "76291049",
    RegisteredPopulation: "639",
    VotingStationName: "JIM YINGWANE SECONDARY SCHOOL",
    Address: "DUMELA VILLAGE  MALAMULELE  MULAMULA TRIBAL",
    Latitude: "-23.12",
    Longitude: "30.6086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15191",
    WardLookupId: "3942",
    VDNumber: "76290059",
    RegisteredPopulation: "930",
    VotingStationName: "HASANI LAWRENCE PRIMARY SCHOOL",
    Address: "STAND NO. 710, MUKHOMI VILLAGE  MALAMULELE  MUKHOMI TRIBAL",
    Latitude: "-23.0692",
    Longitude: "30.5782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15192",
    WardLookupId: "3942",
    VDNumber: "76290060",
    RegisteredPopulation: "671",
    VotingStationName: "MUKHOMI TRADITIONAL COUNCIL",
    Address: "MUKHOMI VILLAGE  MALAMULELE  MUKHOMI TRIBAL",
    Latitude: "-23.0724",
    Longitude: "30.5743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15193",
    WardLookupId: "3942",
    VDNumber: "76290071",
    RegisteredPopulation: "1285",
    VotingStationName: "HLAWULEKANI PRIMARY SCHOOL",
    Address: "GUMBANI VILLAGE  MALAMULELE  MUKHOMI TRIBAL",
    Latitude: "-23.0876",
    Longitude: "30.5504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15194",
    WardLookupId: "3942",
    VDNumber: "76290082",
    RegisteredPopulation: "914",
    VotingStationName: "MAHLEFUNYE PRIMARY SCHOOL",
    Address: "STAND NO. 800, MULAMULA VILLAG  MALAMULELE  MULAMULA TRIBAL",
    Latitude: "-23.1063",
    Longitude: "30.567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15195",
    WardLookupId: "3942",
    VDNumber: "76290790",
    RegisteredPopulation: "779",
    VotingStationName: "CHANYELA PRIMARY SCHOOL",
    Address: "MULAMULA  MULAMULA VILLAGE  MALAMULELE",
    Latitude: "-23.1092",
    Longitude: "30.5518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15196",
    WardLookupId: "3942",
    VDNumber: "76291027",
    RegisteredPopulation: "543",
    VotingStationName: "MUKHOMI BETHEL FULL GOSPEL CHURCH",
    Address: "MUKHOMI VILLAGE  MALAMULELE  MUKHOMI TRIBAL",
    Latitude: "-23.0769",
    Longitude: "30.5708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15197",
    WardLookupId: "3943",
    VDNumber: "76101050",
    RegisteredPopulation: "957",
    VotingStationName: "TSHINETISE PRIMARY SCHOOL",
    Address: "TSHITOMBONI VILLAGE  MALAMULELE  MULENZHE TRIBAL",
    Latitude: "-23.0344",
    Longitude: "30.5107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15198",
    WardLookupId: "3943",
    VDNumber: "76101140",
    RegisteredPopulation: "173",
    VotingStationName: "TAMBAULATE PRIMARY SCHOOL",
    Address: "TAMBAULATE  MALAMULELE  MULENZHE TRIBAL",
    Latitude: "-23.0507",
    Longitude: "30.5102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15199",
    WardLookupId: "3943",
    VDNumber: "76290015",
    RegisteredPopulation: "638",
    VotingStationName: "HASANE PRIMARY SCHOOL",
    Address: "HASANI - DAKARI  MALAMULELE  MALAMULELE",
    Latitude: "-23.0885",
    Longitude: "30.4805",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15200",
    WardLookupId: "3943",
    VDNumber: "76290026",
    RegisteredPopulation: "873",
    VotingStationName: "PFUNEKANI PRIMARY SCHOOL",
    Address: "HASANI - DAKARI  MALAMULELE  MALAMULELE",
    Latitude: "-23.0942",
    Longitude: "30.4841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15201",
    WardLookupId: "3943",
    VDNumber: "76290037",
    RegisteredPopulation: "1371",
    VotingStationName: "XIMUNWANA SEC SCHOOL",
    Address: "PHAPHAZELA VILLAGE  MALAMULELE  MUKHOMI TRIBAL AUTHORITY",
    Latitude: "-23.0678",
    Longitude: "30.5468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15202",
    WardLookupId: "3943",
    VDNumber: "76290048",
    RegisteredPopulation: "1148",
    VotingStationName: "GUWELA PRIMARY SCHOOL",
    Address: "PHAPHAZELA  MALAMULELE  MALAMULELE",
    Latitude: "-23.0632",
    Longitude: "30.5492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15203",
    WardLookupId: "3944",
    VDNumber: "76100352",
    RegisteredPopulation: "601",
    VotingStationName: "MULENZHE TRADITIONAL COUNCIL",
    Address: "MULENZHE VILLAGE  MALAMULELE  MULENZHE TRIBAL",
    Latitude: "-22.9957",
    Longitude: "30.5796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15204",
    WardLookupId: "3944",
    VDNumber: "76100712",
    RegisteredPopulation: "1131",
    VotingStationName: "DIDIDI PRIMARY SCHOOL",
    Address: "DIDIDI VILLAGE  MALAMULELE  MULENZHE TRIBAL",
    Latitude: "-23.0174",
    Longitude: "30.5143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15205",
    WardLookupId: "3944",
    VDNumber: "76100723",
    RegisteredPopulation: "762",
    VotingStationName: "KHAKHANWA PRIMARY SCHOOL",
    Address: "KHAKHANWA VILLAGE  MALAMULELE  MULENZHE TRIBAL",
    Latitude: "-23.0183",
    Longitude: "30.6035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15206",
    WardLookupId: "3944",
    VDNumber: "76101094",
    RegisteredPopulation: "819",
    VotingStationName: "MOVHE SECONDARY SCHOOL",
    Address: "MULENZHE VILLAGE  MALAMULELE  MULENZHE TRIBAL",
    Latitude: "-23.0061",
    Longitude: "30.5588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15207",
    WardLookupId: "3944",
    VDNumber: "76101139",
    RegisteredPopulation: "197",
    VotingStationName: "MUTSHENA JNR PRIMARY SCHOOL",
    Address: "DOVHENI VILLAGE  MALAMULELE  MULENZHE TRIBAL",
    Latitude: "-22.9952",
    Longitude: "30.6185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15208",
    WardLookupId: "3944",
    VDNumber: "76291151",
    RegisteredPopulation: "218",
    VotingStationName: "TOVHOWANI PRIMARY SCHOOL",
    Address: "ROTOVHOWA - TOVHOWANI  MALAMULELE  MULENZHE TRIBAL",
    Latitude: "-23.0518",
    Longitude: "30.5464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15209",
    WardLookupId: "3945",
    VDNumber: "76290150",
    RegisteredPopulation: "1077",
    VotingStationName: "TSHAMISEKA PRIMARY SCHOOL",
    Address: "SHIGAMANI VILLAGE   MALAMULELE  SHIGAMANI TRIBAL",
    Latitude: "-23.0955",
    Longitude: "30.6488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15210",
    WardLookupId: "3945",
    VDNumber: "76290161",
    RegisteredPopulation: "872",
    VotingStationName: "MAHONISI PRIMARY SCHOOL",
    Address: "MAHONISI VILLAGE  MALAMULELE  MAVAMBE TRIBAL",
    Latitude: "-23.0553",
    Longitude: "30.6372",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15211",
    WardLookupId: "3945",
    VDNumber: "76290172",
    RegisteredPopulation: "988",
    VotingStationName: "MPHAMBO TRADITIONAL- AUTHORITY",
    Address: "MPHAMBO VILLAGE   MALAMULELE  MPHAMBO TRIBAL",
    Latitude: "-23.1001",
    Longitude: "30.67",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15212",
    WardLookupId: "3945",
    VDNumber: "76290183",
    RegisteredPopulation: "913",
    VotingStationName: "MHEHO PRIMARY SCHOOL",
    Address: "MPHAMBO VILLAGE   MALAMULELE  MPHAMBO TRIBAL",
    Latitude: "-23.0827",
    Longitude: "30.681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15213",
    WardLookupId: "3945",
    VDNumber: "76290880",
    RegisteredPopulation: "637",
    VotingStationName: "MAHONISI COMMUNITY DEVELOPMENT",
    Address: "MAHONISI VILLAGE  MALAMULELE  MAVAMBE TRIBAL",
    Latitude: "-23.0498",
    Longitude: "30.6312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15214",
    WardLookupId: "3946",
    VDNumber: "76290206",
    RegisteredPopulation: "1826",
    VotingStationName: "TIVANANI PRIMARY SCHOOL",
    Address: "JIMMY JONES VILLAGE  MALAMULELE  MAVAMBE TRIBAL",
    Latitude: "-23.0338",
    Longitude: "30.6457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15215",
    WardLookupId: "3946",
    VDNumber: "76290217",
    RegisteredPopulation: "932",
    VotingStationName: "MAKUMEKE PRIMARY SCHOOL",
    Address: "MAKUMEKE VILLAGE  MALAMULELE  MAVAMBE TRIBAL",
    Latitude: "-22.9779",
    Longitude: "30.6432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15216",
    WardLookupId: "3946",
    VDNumber: "76290228",
    RegisteredPopulation: "689",
    VotingStationName: "MAVAMBE PRIMARY SCHOOL",
    Address: "MAVAMBE VILLAGE  MALAMULELE  MAVAMBE TRIBAL",
    Latitude: "-23.007",
    Longitude: "30.6306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15217",
    WardLookupId: "3946",
    VDNumber: "76290802",
    RegisteredPopulation: "976",
    VotingStationName: "SHIRILELE HIGH SCHOOL",
    Address: "MAVAMBE VILLAGE  MALAMULELE  MAVAMBE TRIBAL",
    Latitude: "-23.0067",
    Longitude: "30.649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15218",
    WardLookupId: "3946",
    VDNumber: "76291005",
    RegisteredPopulation: "661",
    VotingStationName: "MALAMULELE NORTH A F M",
    Address: "MAVAMBE VILLAGE  MALAMULELE  MAVAMBE TRIBAL",
    Latitude: "-22.9967",
    Longitude: "30.636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15219",
    WardLookupId: "3946",
    VDNumber: "76291106",
    RegisteredPopulation: "59",
    VotingStationName: "RHULANI PUBLIC WORKS  SITE",
    Address: "RHULANI  MALAMULELE  MAVAMBE TRIBAL",
    Latitude: "-23.0104",
    Longitude: "30.6747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15220",
    WardLookupId: "3947",
    VDNumber: "76290251",
    RegisteredPopulation: "1214",
    VotingStationName: "MAGODA PRIMARY SCHOOL",
    Address: "JEROME VILLAGE   MALAMULELE  MAVAMBE TRIBAL",
    Latitude: "-22.9782",
    Longitude: "30.677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15221",
    WardLookupId: "3947",
    VDNumber: "76290262",
    RegisteredPopulation: "757",
    VotingStationName: "TIYISELANI PRIMARY SCHOOL",
    Address: "GANDLANANI VILLAGE   MALAMULELE  MAVAMBE TRIBAL",
    Latitude: "-22.9696",
    Longitude: "30.682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15222",
    WardLookupId: "3947",
    VDNumber: "76290273",
    RegisteredPopulation: "1097",
    VotingStationName: "RIPINDZI PRIMARY SCHOOL",
    Address: "GANDLANANI VILLAGE   MALAMULELE  MAVAMBE TRIBAL",
    Latitude: "-22.9598",
    Longitude: "30.6658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15223",
    WardLookupId: "3947",
    VDNumber: "76290284",
    RegisteredPopulation: "1101",
    VotingStationName: "MANAVELE PRIMARY SCHOOL",
    Address: "ROODHUIS 285T VILLAGE  MALAMULELE  XIGALO",
    Latitude: "-22.9535",
    Longitude: "30.6897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15224",
    WardLookupId: "3947",
    VDNumber: "76291094",
    RegisteredPopulation: "498",
    VotingStationName: "HLUVUKANI CRECHE",
    Address: "JEROME VILLAGE   MALAMULELE  MAVAMBE TRIBAL",
    Latitude: "-22.9785",
    Longitude: "30.6887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15225",
    WardLookupId: "3948",
    VDNumber: "76290813",
    RegisteredPopulation: "1414",
    VotingStationName: "MAGANGENI PRIMARY SCHOOL",
    Address: "MALAMULELE LOCATION  THULAMELA  MALAMULELE",
    Latitude: "-23.0047",
    Longitude: "30.6909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15226",
    WardLookupId: "3948",
    VDNumber: "76290824",
    RegisteredPopulation: "2051",
    VotingStationName: "XIHLOVO PRIMARY SCHOOL",
    Address: "MALAMULELE SECTION B  MALAMULELE LOCATION  MALAMULELE",
    Latitude: "-22.9988",
    Longitude: "30.7028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15227",
    WardLookupId: "3948",
    VDNumber: "76290936",
    RegisteredPopulation: "970",
    VotingStationName: "EPP MHINGA HIGH SCHOOL",
    Address: "MALAMULELE SECTION C  MALAMULELE  MALAMULELE",
    Latitude: "-23.0053",
    Longitude: "30.7074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15228",
    WardLookupId: "3948",
    VDNumber: "76291083",
    RegisteredPopulation: "668",
    VotingStationName: "VICTORY REVIVAL CHURCH",
    Address: "MALAMULELE SECTION D  MALAMULELE TOWN  MALAMULELE TOWN",
    Latitude: "-22.9976",
    Longitude: "30.7173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15229",
    WardLookupId: "3949",
    VDNumber: "76290194",
    RegisteredPopulation: "1142",
    VotingStationName: "TITIRHELENI PRIMARY SCHOOL",
    Address: "DINGA VILLAGE  MALAMULELE  MAVAMBE TRIBAL",
    Latitude: "-23.04",
    Longitude: "30.7079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15230",
    WardLookupId: "3949",
    VDNumber: "76290239",
    RegisteredPopulation: "1009",
    VotingStationName: "LANGUTANI PRIMARY SCHOOL",
    Address: "XITHLELANI  MALAMULELE  MAVAMBE TRIBAL",
    Latitude: "-23.0218",
    Longitude: "30.6828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15231",
    WardLookupId: "3949",
    VDNumber: "76290240",
    RegisteredPopulation: "915",
    VotingStationName: "KHANANI PRIMARY SCHOOL",
    Address: "BOLTMAN B - MENELE VILLAGE  MALAMULELE  MADONSI TRIBAL",
    Latitude: "-23.0172",
    Longitude: "30.7116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15232",
    WardLookupId: "3949",
    VDNumber: "76290329",
    RegisteredPopulation: "1213",
    VotingStationName: "MAPAPILA PRIMARY SCHOOL",
    Address: "MAPAPILA VILLAGE  MALAMULELE  MAVAMBE TRIBAL",
    Latitude: "-23.0639",
    Longitude: "30.7376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15233",
    WardLookupId: "3949",
    VDNumber: "76290958",
    RegisteredPopulation: "1139",
    VotingStationName: "MAHUNTSI SECONDARY SCHOOL",
    Address: "XITHLELANI VILLAGE   MALAMULELE  MAVAMBE TRIBAL",
    Latitude: "-23.0229",
    Longitude: "30.6927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15234",
    WardLookupId: "3950",
    VDNumber: "76290295",
    RegisteredPopulation: "1300",
    VotingStationName: "MDANISI PRIMARY SCHOOL",
    Address: "BOLTMAN A VILLAGE  MALAMULELE  MADONSI TRIBAL AUTHORITY",
    Latitude: "-23.0018",
    Longitude: "30.733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15235",
    WardLookupId: "3950",
    VDNumber: "76290307",
    RegisteredPopulation: "1330",
    VotingStationName: "MAKHAPULE PRIMARY SCHOOL",
    Address: "MUCHIPISI VILLAGE  MALAMULELE  MADONSI TRIBAL AUTHORITY",
    Latitude: "-23.0353",
    Longitude: "30.7319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15236",
    WardLookupId: "3950",
    VDNumber: "76290330",
    RegisteredPopulation: "851",
    VotingStationName: "KHUPUKANI PRIMARY SCHOOL",
    Address: "MATSAKALI  VILLAGE  MALAMULELE  MADONSI TRIBAL AUTHORITY",
    Latitude: "-23.0509",
    Longitude: "30.7616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15237",
    WardLookupId: "3950",
    VDNumber: "76290453",
    RegisteredPopulation: "704",
    VotingStationName: "TSHIKIWANI PRIMARY SCHOOL",
    Address: "GIDJAMHANDZENI VILLAGE  MALAMULELE  MADONSI TRIBAL AUTHORITY",
    Latitude: "-23.009",
    Longitude: "30.784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15238",
    WardLookupId: "3951",
    VDNumber: "76290341",
    RegisteredPopulation: "1308",
    VotingStationName: "SHIGALO PRIMARY SCHOOL",
    Address: "SHIGALO VILLAGE   MALAMULELE  SHIGALO TRIBAL COUNCIL",
    Latitude: "-22.9375",
    Longitude: "30.7139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15239",
    WardLookupId: "3951",
    VDNumber: "76290352",
    RegisteredPopulation: "1476",
    VotingStationName: "SHIGALO TRADITIONAL- COUNCIL",
    Address: "THULAMELA  ",
    Latitude: "-22.9295",
    Longitude: "30.7197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15240",
    WardLookupId: "3951",
    VDNumber: "76290431",
    RegisteredPopulation: "945",
    VotingStationName: "SHIBANGWA PRIMARY SCHOOL",
    Address: "GREEN FARM VILLAGE  MALAMULELE  MADONSI TRIBAL AUTHORITY",
    Latitude: "-22.9577",
    Longitude: "30.7437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15241",
    WardLookupId: "3951",
    VDNumber: "76290442",
    RegisteredPopulation: "884",
    VotingStationName: "TSUNDZUKANI PRIMARY SCHOOL",
    Address: "GREEN FARM VILLAGE  MALAMULELE  MADONSI TRIBAL AUTHORITY",
    Latitude: "-22.9619",
    Longitude: "30.7653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15242",
    WardLookupId: "3951",
    VDNumber: "76290835",
    RegisteredPopulation: "572",
    VotingStationName: "SHILUME PRIMARY SCHOOL",
    Address: "PHUNGWANE VILLAGE  MALAMULELE  MADONSI TRIBAL AUTHORITY",
    Latitude: "-22.978",
    Longitude: "30.7885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15243",
    WardLookupId: "3952",
    VDNumber: "76290363",
    RegisteredPopulation: "583",
    VotingStationName: "MADADZHE SECONDARY SCHOOL",
    Address: "TSHIKONELO VILLAGE  MALAMULELE  TSHIKONELO TRIBAL AUTHORITY",
    Latitude: "-22.8882",
    Longitude: "30.7277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15244",
    WardLookupId: "3952",
    VDNumber: "76290374",
    RegisteredPopulation: "1630",
    VotingStationName: "MPHAKATHI PRIMARY SCHOOL",
    Address: "MPHAKATHI VILLAGE  MALAMULELE  SHIGALO TRIBAL COUNCIL",
    Latitude: "-22.902",
    Longitude: "30.7222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15245",
    WardLookupId: "3952",
    VDNumber: "76290385",
    RegisteredPopulation: "841",
    VotingStationName: "NYAVANI PRIMARY SCHOOL",
    Address: "NYAVANI VILLAGE  MALAMULELE  SHIGALO TRIBAL COUNCIL",
    Latitude: "-22.8988",
    Longitude: "30.7631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15246",
    WardLookupId: "3952",
    VDNumber: "76290396",
    RegisteredPopulation: "917",
    VotingStationName: "TSHIKONELO PRIMARY SCHOOL",
    Address: "TSHIKONELO VILLAGE  MALAMULELE  TSHIKONELO TRIBAL AUTHORITY",
    Latitude: "-22.8778",
    Longitude: "30.721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15247",
    WardLookupId: "3952",
    VDNumber: "76290947",
    RegisteredPopulation: "532",
    VotingStationName: "MADZIKUSE COMBINED PRIMARY SCHOOL",
    Address: "TSHIKONELO VILLAGE  MALAMULELE  TSHIKONELO TRIBAL AUTHORITY",
    Latitude: "-22.8727",
    Longitude: "30.7493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15248",
    WardLookupId: "3952",
    VDNumber: "76290970",
    RegisteredPopulation: "646",
    VotingStationName: "MAKHASA PRIMARY SCHOOL",
    Address: "MAKHASA VILLAGE  MALAMULELE  SHIGALO TRIBAL COUNCIL",
    Latitude: "-22.9277",
    Longitude: "30.7784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15249",
    WardLookupId: "3953",
    VDNumber: "76290408",
    RegisteredPopulation: "716",
    VotingStationName: "MANGHENA PRIMARY SCHOOL",
    Address: "NO STREET NAME  SHIKUNDU VILLAGE  VHEMBE",
    Latitude: "-22.8664",
    Longitude: "30.7707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15250",
    WardLookupId: "3953",
    VDNumber: "76290419",
    RegisteredPopulation: "1116",
    VotingStationName: "HLENGANI PRIMARY SCHOOL",
    Address: "HLENGANI  SHIKUNDU VILLAGE  MALAMULELE",
    Latitude: "-22.8691",
    Longitude: "30.792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15251",
    WardLookupId: "3953",
    VDNumber: "76290475",
    RegisteredPopulation: "1358",
    VotingStationName: "MAYEKE PRIMARY SCHOOL",
    Address: "TSHIKUNDU 262MT  MALAMULELE  MALAMULELE",
    Latitude: "-22.8314",
    Longitude: "30.8072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15252",
    WardLookupId: "3953",
    VDNumber: "76290486",
    RegisteredPopulation: "249",
    VotingStationName: "GONANI PRIMARY SCHOOL",
    Address: "NO STREET NAME  SHIKUNDU VILLAGE  VHEMBE",
    Latitude: "-22.8248",
    Longitude: "30.7816",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15253",
    WardLookupId: "3953",
    VDNumber: "76290497",
    RegisteredPopulation: "1011",
    VotingStationName: "TLHELANI PRIMARY SCHOOL",
    Address: "XIFAXANI VILLAGE  / XIKUNDU  SASELAMANI  THULAMELA [THOHOYANDOU]",
    Latitude: "-22.8296",
    Longitude: "30.821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15254",
    WardLookupId: "3953",
    VDNumber: "76290879",
    RegisteredPopulation: "656",
    VotingStationName: "MPHAWENI -PRIMARY SCHOOL",
    Address: "139 PHAWENI VILLAGE  MALAMULELE  XIKHUNDU TRIBAL AUTHORITY",
    Latitude: "-22.8856",
    Longitude: "30.7778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15255",
    WardLookupId: "3953",
    VDNumber: "76291139",
    RegisteredPopulation: "306",
    VotingStationName: "SHIKUNDU HIGH SCHOOL",
    Address: "THULAMELA   ",
    Latitude: "-22.8414",
    Longitude: "30.8132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15256",
    WardLookupId: "3954",
    VDNumber: "76290420",
    RegisteredPopulation: "741",
    VotingStationName: "HISEKELANI PRIMARY SCHOOL",
    Address: "NGHEZIMANI VILLAGE  MALAMULELE  XIKHUNDU TRIBAL AUTHORITY",
    Latitude: "-22.8934",
    Longitude: "30.8057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15257",
    WardLookupId: "3954",
    VDNumber: "76290464",
    RegisteredPopulation: "1138",
    VotingStationName: "TIVONELENI PRIMARY SCHOOL",
    Address: "NKOVANI VILLAGE  MALAMULELE  XIKUNDU TRIBAL AUTHORITY",
    Latitude: "-22.9426",
    Longitude: "30.8188",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15258",
    WardLookupId: "3954",
    VDNumber: "76290655",
    RegisteredPopulation: "1272",
    VotingStationName: "HANGALAKANI PRIMARY SCHOOL",
    Address: "NKAVELE VILLAGE  MALAMULELE  NTLHAVENI TRADITIONAL AUTHORITY",
    Latitude: "-22.8918",
    Longitude: "30.8483",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15259",
    WardLookupId: "3954",
    VDNumber: "76290677",
    RegisteredPopulation: "1273",
    VotingStationName: "NTLHAVENI COMMUNITY AUTHORITY",
    Address: "NTLHAVENI C VILLAGE  MALAMULELE  NTLHAVENI TRADITIONAL AUTHORITY",
    Latitude: "-22.9165",
    Longitude: "30.8747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15260",
    WardLookupId: "3954",
    VDNumber: "76291072",
    RegisteredPopulation: "452",
    VotingStationName: "HITEKANI PRIMARY SCHOOL",
    Address: "NGHEZIMANI VILLAGE  MALAMULELE  XIKUNDU TRIBAL AUTHORITY",
    Latitude: "-22.9084",
    Longitude: "30.8082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15261",
    WardLookupId: "3955",
    VDNumber: "76290587",
    RegisteredPopulation: "1724",
    VotingStationName: "MAXAKADZI PRIMARY SCHOOL",
    Address: "JOSEPH VILLAGE  MALAMULELE  MHINGA TRIBAL AUTHORITY",
    Latitude: "-22.7732",
    Longitude: "30.9585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15262",
    WardLookupId: "3955",
    VDNumber: "76290598",
    RegisteredPopulation: "1425",
    VotingStationName: "BASOPA SECONDARY SCHOOL",
    Address: "JOSEPH VILLAGE  MALAMULELE  MHINGA TRIBAL AUTHORITY",
    Latitude: "-22.8149",
    Longitude: "30.901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15263",
    WardLookupId: "3955",
    VDNumber: "76290600",
    RegisteredPopulation: "904",
    VotingStationName: "MAPHOPHE PRIMARY SCHOOL",
    Address: "MAPHOPHE VILLAGE  MALAMULELE  MHINGA TRIBAL AUTHORITY",
    Latitude: "-22.8235",
    Longitude: "30.9114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15264",
    WardLookupId: "3955",
    VDNumber: "76290992",
    RegisteredPopulation: "647",
    VotingStationName: "MHINGA VILLE CRECHE",
    Address: "NO STREET ADDRESS AVAILABLE  MHINGA  VHEMBE  ",
    Latitude: "-22.7943",
    Longitude: "30.8971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15265",
    WardLookupId: "3956",
    VDNumber: "76290543",
    RegisteredPopulation: "1584",
    VotingStationName: "RHANGANI PRIMARY SCHOOL",
    Address: "MHINGA VILLAGE ZONE 02  MALAMULELE  MHINGA TRIBAL AUTHORITY",
    Latitude: "-22.7785",
    Longitude: "30.8974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15266",
    WardLookupId: "3956",
    VDNumber: "76290554",
    RegisteredPopulation: "1058",
    VotingStationName: "MHINGA PRIMARY SCHOOL",
    Address: "N/A MHINGA VILLAGE ZONE 02  MALAMULELE  MHINGA TRIBAL AUTHORITY",
    Latitude: "-22.7708",
    Longitude: "30.9011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15267",
    WardLookupId: "3956",
    VDNumber: "76290565",
    RegisteredPopulation: "747",
    VotingStationName: "NKHAVI PRIMARY SCHOOL",
    Address: "MHINGA VILLAGE ZONE 01  MALAMULELE  MHINGA TRIBAL AUTHORITY",
    Latitude: "-22.7577",
    Longitude: "30.8999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15268",
    WardLookupId: "3956",
    VDNumber: "76290576",
    RegisteredPopulation: "1474",
    VotingStationName: "MATIYANI PRIMARY SCHOOL",
    Address: "MATIYANI VILLAGE  MALAMULELE  MHINGA TRIBAL AUTHORITY",
    Latitude: "-22.7444",
    Longitude: "30.9787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15269",
    WardLookupId: "3957",
    VDNumber: "76290509",
    RegisteredPopulation: "1374",
    VotingStationName: "MAGOMANI PRIMARY SCHOOL",
    Address: "MAGOMANI VILLAGE  MALAMULELE  XIKUNDU TRIBAL AUTHORITY",
    Latitude: "-22.8073",
    Longitude: "30.8291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15270",
    WardLookupId: "3957",
    VDNumber: "76290510",
    RegisteredPopulation: "694",
    VotingStationName: "XIMIXONI PRIMARY SCHOOL",
    Address: "SASELAMANI VILLAGE  MALAMULELE  XIKUNDU TRIBAL AUTHORITY",
    Latitude: "-22.825",
    Longitude: "30.8641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15271",
    WardLookupId: "3957",
    VDNumber: "76290521",
    RegisteredPopulation: "1194",
    VotingStationName: "VONGANI PRIMARY SCHOOL",
    Address: "SASELAMANI VILLAGE  MALAMULELE  XIKUNDU TRIBAL AUTHORITY",
    Latitude: "-22.8342",
    Longitude: "30.8582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15272",
    WardLookupId: "3957",
    VDNumber: "76290532",
    RegisteredPopulation: "1124",
    VotingStationName: "BOTSOLENI PRIMARY SCHOOL",
    Address: "BOTSOLENI VILLAGE  MALAMULELE  MHINGA TRIBAL AUTHORITY",
    Latitude: "-22.7843",
    Longitude: "30.8647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15273",
    WardLookupId: "3957",
    VDNumber: "76290891",
    RegisteredPopulation: "741",
    VotingStationName: "SASELAMANI B",
    Address: "SHIKUNDU NEW TOWN  MALAMULELE  XIKUNDU TRIBAL AUTHORITY",
    Latitude: "-22.8583",
    Longitude: "30.8268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15274",
    WardLookupId: "3957",
    VDNumber: "76291128",
    RegisteredPopulation: "486",
    VotingStationName: "MAHLOHLWANI PRIMARY SCHOOL",
    Address: "SASELAMANI VILLAGE  MALAMULELE  XIKUNDU TRIBAL AUTHORITY",
    Latitude: "-22.8496",
    Longitude: "30.8659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15275",
    WardLookupId: "3958",
    VDNumber: "76290611",
    RegisteredPopulation: "1094",
    VotingStationName: "BOXAHUKU PRIMARY SCHOOL",
    Address: "MABILIGWE VILLAGE  MALAMULELE  MHINGA TRIBAL AUTHORITY",
    Latitude: "-22.837",
    Longitude: "30.9471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15276",
    WardLookupId: "3958",
    VDNumber: "76290622",
    RegisteredPopulation: "769",
    VotingStationName: "MAKULEKE PRIMARY SCHOOL",
    Address: "MAKULEKE VILLAGE  MALAMULELE  MHINGA TRIBAL AUTHORITY",
    Latitude: "-22.8641",
    Longitude: "30.9205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15277",
    WardLookupId: "3958",
    VDNumber: "76290633",
    RegisteredPopulation: "1473",
    VotingStationName: "JOAS PHAHLELA PRIMARY SCHOOL",
    Address: "MAKULEKE VILLAGE  MALAMULELE  MHINGA TRIBAL AUTHORITY",
    Latitude: "-22.8711",
    Longitude: "30.9164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15278",
    WardLookupId: "3958",
    VDNumber: "76290644",
    RegisteredPopulation: "930",
    VotingStationName: "MALEDZA PRIMARY SCHOOL",
    Address: "HLUNGAWANI VILLAGE  MALAMULELE  NTLHAVENI TRADITIONAL AUTHORITY",
    Latitude: "-22.8688",
    Longitude: "30.886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15279",
    WardLookupId: "3958",
    VDNumber: "76290666",
    RegisteredPopulation: "903",
    VotingStationName: "MAKAHLULE PRIMARY SCHOOL",
    Address: "MAKAHLULE VILLAGE  MALAMULELE  MHINGA TRIBAL AUTHORITY",
    Latitude: "-22.8922",
    Longitude: "30.9169",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15280",
    WardLookupId: "3959",
    VDNumber: "76290688",
    RegisteredPopulation: "965",
    VotingStationName: "NKANDZIYI PRIMARY SCHOOL",
    Address: "BEVULA VILLAGE   MALAMULELE  GIDJANA TRIBAL AUTHORITY",
    Latitude: "-22.9237",
    Longitude: "30.909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15281",
    WardLookupId: "3959",
    VDNumber: "76290699",
    RegisteredPopulation: "912",
    VotingStationName: "NGHOMUNGHOMU PRIMARY SCHOOL",
    Address: "NGHOMUNGHOMU VILLAGE  MALAMULELE  GIDJANA TRIBAL AUTHORITY",
    Latitude: "-22.9596",
    Longitude: "30.8659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15282",
    WardLookupId: "3959",
    VDNumber: "76290701",
    RegisteredPopulation: "932",
    VotingStationName: "MASHOBYE PRIMARY SCHOOL",
    Address: "MASHOBYE VILLAGE  MALAMULELE  GIDJANA TRIBAL AUTHORITY",
    Latitude: "-22.9664",
    Longitude: "30.8913",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15283",
    WardLookupId: "3959",
    VDNumber: "76290756",
    RegisteredPopulation: "1244",
    VotingStationName: "GIDJANA TRADITIONAL COUNCIL",
    Address: "GIDJANA VILLAGE  MALAMULELE  GIDJANA TRIBAL AUTHORITY",
    Latitude: "-23.0154",
    Longitude: "30.8852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15284",
    WardLookupId: "3960",
    VDNumber: "76290712",
    RegisteredPopulation: "758",
    VotingStationName: "MERWE PRIMARY SCHOOL",
    Address: "MERWE VILLAGE  MALAMULELE  MADONSI TRIBAL AUTHORITY",
    Latitude: "-23.0831",
    Longitude: "30.829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15285",
    WardLookupId: "3960",
    VDNumber: "76290723",
    RegisteredPopulation: "721",
    VotingStationName: "MABAYENI PRIMARY SCHOOL",
    Address: "SHISASI VILLAGE  MALAMULELE  MADONSI TRIBAL AUTHORITY",
    Latitude: "-23.081",
    Longitude: "30.8479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15286",
    WardLookupId: "3960",
    VDNumber: "76290745",
    RegisteredPopulation: "840",
    VotingStationName: "GOVHU PRIMARY SCHOOL",
    Address: "GOVHU VILLAGE  MALAMULELE  MADONSI TRIBAL AUTHORITY",
    Latitude: "-23.0241",
    Longitude: "30.8629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15287",
    WardLookupId: "3960",
    VDNumber: "76290767",
    RegisteredPopulation: "851",
    VotingStationName: "TINYIKO PRIMARY SCHOOL",
    Address: "LOMBARD VILLAGE  MALAMULELE  MTITITI TRIBAL AUATHORITY",
    Latitude: "-23.0652",
    Longitude: "30.8768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15288",
    WardLookupId: "3960",
    VDNumber: "76290857",
    RegisteredPopulation: "655",
    VotingStationName: "SHIKATSA PRIMARY SCHOOL",
    Address: "HALAHALA VILLAGE  MALAMULELE  MADONSI TRIBAL AUTHORITY",
    Latitude: "-23.0654",
    Longitude: "30.7933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15289",
    WardLookupId: "3960",
    VDNumber: "76290903",
    RegisteredPopulation: "442",
    VotingStationName: "KHODOBI PRIMARY SCHOOL",
    Address: "PENINGHOTSA VILLAGE  MALAMULELE  MADONSI TRIBAL AUTHORITY",
    Latitude: "-23.0191",
    Longitude: "30.8393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15290",
    WardLookupId: "3961",
    VDNumber: "76290734",
    RegisteredPopulation: "1114",
    VotingStationName: "SHIGOMBE PRIMARY SCHOOL",
    Address: "JILONGO VILLAGE  MALAMULELE  MADONSI TRIBAL AUTHORITY",
    Latitude: "-23.0947",
    Longitude: "30.8518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15291",
    WardLookupId: "3961",
    VDNumber: "76290778",
    RegisteredPopulation: "1180",
    VotingStationName: "PHATIMA PRIMARY SCHOOL",
    Address: "PLANGE VILLAGE  MALAMULELE  MTITITI TRIBAL AUATHORITY",
    Latitude: "-23.1006",
    Longitude: "30.8986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15292",
    WardLookupId: "3961",
    VDNumber: "76290789",
    RegisteredPopulation: "1241",
    VotingStationName: "FUMANI PRIMARY SCHOOL",
    Address: "ALTEN VILLAGE  MALAMULELE  MTITITI TRIBAL AUATHORITY",
    Latitude: "-23.1418",
    Longitude: "30.9151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15293",
    WardLookupId: "3961",
    VDNumber: "76290925",
    RegisteredPopulation: "491",
    VotingStationName: "MTITITI HIGH SCHOOL",
    Address: "MTITITI VILLAGE  MALAMULELE  MTITITI TRIBAL AUATHORITY",
    Latitude: "-23.1106",
    Longitude: "30.8872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15294",
    WardLookupId: "3961",
    VDNumber: "76291140",
    RegisteredPopulation: "45",
    VotingStationName: "VLAKTEPLAAS CAMP",
    Address: "KRUGER NATIONAL PARK  MALAMULELE  KRUGER NATIONAL PARK",
    Latitude: "-22.8728",
    Longitude: "31.2299",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15295",
    WardLookupId: "3962",
    VDNumber: "76130625",
    RegisteredPopulation: "327",
    VotingStationName: "THABANTSHO PRIMARY SCHOOL",
    Address: "AULDLANGSYDE  AULDLANGSYDE VILLAGE  BOCHUM",
    Latitude: "-23.3007",
    Longitude: "28.9804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15296",
    WardLookupId: "3962",
    VDNumber: "76320031",
    RegisteredPopulation: "503",
    VotingStationName: "KETUDI SECONDARY SCHOOL",
    Address: "N/A NO STREET NAME  SADU  CAPRICORN",
    Latitude: "-23.3175",
    Longitude: "28.7589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15297",
    WardLookupId: "3962",
    VDNumber: "76320042",
    RegisteredPopulation: "330",
    VotingStationName: "CRACOUW PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  CRACOUW  CAPRICON",
    Latitude: "-23.4183",
    Longitude: "28.8041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15298",
    WardLookupId: "3962",
    VDNumber: "76320053",
    RegisteredPopulation: "503",
    VotingStationName: "SESHANE PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  EARLY DAWN  SENWABARWANA",
    Latitude: "-23.3251",
    Longitude: "28.9404",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15299",
    WardLookupId: "3962",
    VDNumber: "76320064",
    RegisteredPopulation: "175",
    VotingStationName: "MABETWA PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  NORMA/A,B  SENWABARWANA",
    Latitude: "-23.3919",
    Longitude: "28.9435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15300",
    WardLookupId: "3962",
    VDNumber: "76320075",
    RegisteredPopulation: "381",
    VotingStationName: "MAFIFING PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  HARRISWICH  CAPRICON",
    Latitude: "-23.418",
    Longitude: "28.8843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15301",
    WardLookupId: "3962",
    VDNumber: "76321290",
    RegisteredPopulation: "179",
    VotingStationName: "MOGOHLONG PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  KGATLU  SENWABARWANA",
    Latitude: "-23.4026",
    Longitude: "28.9122",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15302",
    WardLookupId: "3962",
    VDNumber: "76321357",
    RegisteredPopulation: "412",
    VotingStationName: "MASHILO PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  BASEWATER  CAPRICON",
    Latitude: "-23.3876",
    Longitude: "28.7973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15303",
    WardLookupId: "3962",
    VDNumber: "76321368",
    RegisteredPopulation: "203",
    VotingStationName: "AURORA  PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS AVAILIBLE  AURORA  SENWABARWANA ",
    Latitude: "-23.458",
    Longitude: "28.8762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15304",
    WardLookupId: "3962",
    VDNumber: "76321492",
    RegisteredPopulation: "136",
    VotingStationName: "LEKITING PRIMARY SCHOOL",
    Address: "LEKITING VILLAGE  CAPRICORN  SENWABARWANA",
    Latitude: "-23.4358",
    Longitude: "28.9204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15305",
    WardLookupId: "3962",
    VDNumber: "76321504",
    RegisteredPopulation: "101",
    VotingStationName: "EVANGELICAL LUTHERN CHURCH",
    Address: "UYTKYK NO 3  BLOUBERG  BLOUBERG RURAL",
    Latitude: "-23.3959",
    Longitude: "28.9755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15306",
    WardLookupId: "3962",
    VDNumber: "76321650",
    RegisteredPopulation: "232",
    VotingStationName: "KGOKONYANE SECONDARY SCHOOL",
    Address: "LEWANENG  BLOUBERG  SENWABARWANA",
    Latitude: "-23.3975",
    Longitude: "28.8813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15307",
    WardLookupId: "3963",
    VDNumber: "76130096",
    RegisteredPopulation: "209",
    VotingStationName: "HLOHLODI PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  BARNEN  CAPRICON",
    Latitude: "-23.3057",
    Longitude: "29.0204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15308",
    WardLookupId: "3963",
    VDNumber: "76130186",
    RegisteredPopulation: "309",
    VotingStationName: "MMALOTLO SECONDARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  MOKUMURU  CAPRICON",
    Latitude: "-23.2604",
    Longitude: "29.0195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15309",
    WardLookupId: "3963",
    VDNumber: "76130232",
    RegisteredPopulation: "850",
    VotingStationName: "MASEALELE PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  GEMARKE  CAPRICON",
    Latitude: "-23.3061",
    Longitude: "29.0456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15310",
    WardLookupId: "3963",
    VDNumber: "76130366",
    RegisteredPopulation: "630",
    VotingStationName: "DIKGOALE PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  LEMONSIDE  CAPRICON",
    Latitude: "-23.2524",
    Longitude: "28.9765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15311",
    WardLookupId: "3963",
    VDNumber: "76320299",
    RegisteredPopulation: "1175",
    VotingStationName: "MAMADISHA PRIMARY SCHOOL",
    Address: "SHOONGEZEIGHT  BLOUBERG  SENWABARWANA",
    Latitude: "-23.3387",
    Longitude: "29.0428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15312",
    WardLookupId: "3963",
    VDNumber: "76321111",
    RegisteredPopulation: "289",
    VotingStationName: "MARATHA PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  SCHOONGEZICHT A  CAPRICON",
    Latitude: "-23.3411",
    Longitude: "29.0107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15313",
    WardLookupId: "3964",
    VDNumber: "76130164",
    RegisteredPopulation: "550",
    VotingStationName: "MMATHEKO PRIMARY SCHOOL",
    Address: "NA NO STREET ADDRESS  GALLASHIELS  CAPRICON",
    Latitude: "-23.2301",
    Longitude: "28.8822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15314",
    WardLookupId: "3964",
    VDNumber: "76130467",
    RegisteredPopulation: "805",
    VotingStationName: "MAHLASE HIGH SCHOOL",
    Address: "N/A NO STREET ADDRESS  MILBANK/MANGALO  BLOUBERG RURAL",
    Latitude: "-23.2217",
    Longitude: "28.9354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15315",
    WardLookupId: "3964",
    VDNumber: "76130490",
    RegisteredPopulation: "747",
    VotingStationName: "HOSEAMOCHEMI PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  MILTONDUFF  CAPRICON",
    Latitude: "-23.2089",
    Longitude: "28.9883",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15316",
    WardLookupId: "3964",
    VDNumber: "76130580",
    RegisteredPopulation: "735",
    VotingStationName: "RADIRA HIGH SCHOOL",
    Address: "N/A NO STREET ADDRESS  MILLBANK  CAPRICON",
    Latitude: "-23.2488",
    Longitude: "28.945",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15317",
    WardLookupId: "3964",
    VDNumber: "76130670",
    RegisteredPopulation: "216",
    VotingStationName: "MATHUWE PRIMARY SCHOOL",
    Address: "N/A N/A  NEW JERUSALEM  BLOUBERG [BOCHUM/MY DARLING]",
    Latitude: "-23.2409",
    Longitude: "28.8577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15318",
    WardLookupId: "3964",
    VDNumber: "76130759",
    RegisteredPopulation: "420",
    VotingStationName: "KAILANE PRE SCHOOL",
    Address: "BOCHUM  CAPRICORN  SENWABARWANA",
    Latitude: "-23.2167",
    Longitude: "28.9445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15319",
    WardLookupId: "3964",
    VDNumber: "76130760",
    RegisteredPopulation: "189",
    VotingStationName: "LESEDI PRE -SCHOOL (TENT)",
    Address: "GA - MAHWITING  BLOUBERG  SENWABARWANA",
    Latitude: "-23.2332",
    Longitude: "29.0019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15320",
    WardLookupId: "3965",
    VDNumber: "76130120",
    RegisteredPopulation: "488",
    VotingStationName: "SEANEHO PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  DRIEKOPPIES  SENWABARWANA",
    Latitude: "-23.2267",
    Longitude: "28.7096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15321",
    WardLookupId: "3965",
    VDNumber: "76130131",
    RegisteredPopulation: "298",
    VotingStationName: "PHUMATLA SECONDARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  DEVILLIERSDALE  CAPRICON",
    Latitude: "-23.2295",
    Longitude: "28.7801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15322",
    WardLookupId: "3965",
    VDNumber: "76130524",
    RegisteredPopulation: "380",
    VotingStationName: "MOKIDIANE-MORUKHU SCHOOL",
    Address: "N/A NO STREET ADDRESS  NORMANDY  CAPRICON",
    Latitude: "-23.1842",
    Longitude: "28.8066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15323",
    WardLookupId: "3965",
    VDNumber: "76130715",
    RegisteredPopulation: "409",
    VotingStationName: "MOHLAKENG PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS AVAILIBLE  DEVILLIERSDALE  CAPRICORN ",
    Latitude: "-23.2569",
    Longitude: "28.7956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15324",
    WardLookupId: "3965",
    VDNumber: "76130771",
    RegisteredPopulation: "263",
    VotingStationName: "SELOWE PRIMARY SCHOOL",
    Address: "SILVERMYN VILLAGE  CAPRICORN  BOCHUM",
    Latitude: "-23.2082",
    Longitude: "28.7512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15325",
    WardLookupId: "3965",
    VDNumber: "76321199",
    RegisteredPopulation: "556",
    VotingStationName: "MATUNTUNYANE PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  MONTZ  CAPRICON",
    Latitude: "-23.3215",
    Longitude: "28.6869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15326",
    WardLookupId: "3965",
    VDNumber: "76321302",
    RegisteredPopulation: "284",
    VotingStationName: "SEPHAOWENG PRIMARY SCHOOL",
    Address: "NA NA  NONPARELLA  BLOUBERG [BOCHUM/MY DARLING]",
    Latitude: "-23.2729",
    Longitude: "28.7427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15327",
    WardLookupId: "3965",
    VDNumber: "76321313",
    RegisteredPopulation: "281",
    VotingStationName: "MASEBE PRIMARY SCHOOL",
    Address: "N/A NO STREET NAME  SWARTZ  CAPRICORN",
    Latitude: "-23.2744",
    Longitude: "28.7106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15328",
    WardLookupId: "3965",
    VDNumber: "76510199",
    RegisteredPopulation: "497",
    VotingStationName: "BALTIMORE PRIMARY SCHOOL",
    Address: "PO BOX 36  BALTIMORE  ELLISRAS",
    Latitude: "-23.2632",
    Longitude: "28.4273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15329",
    WardLookupId: "3966",
    VDNumber: "76120332",
    RegisteredPopulation: "190",
    VotingStationName: "MASHILOMPANA PRIMARY",
    Address: "BLOUBERG  CAPRICORN  SENWABARWANA",
    Latitude: "-23.0042",
    Longitude: "28.7014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15330",
    WardLookupId: "3966",
    VDNumber: "76130018",
    RegisteredPopulation: "790",
    VotingStationName: "MPHELA SECONDARY SCHOOL",
    Address: "BLOUBERG  ",
    Latitude: "-23.0237",
    Longitude: "28.7014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15331",
    WardLookupId: "3966",
    VDNumber: "76130029",
    RegisteredPopulation: "687",
    VotingStationName: "SEIKGONE SECONDARY SCHOOL",
    Address: "N/A NO STREET NAME  DIEPSLOOT  CAPRICON",
    Latitude: "-23.0717",
    Longitude: "28.7094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15332",
    WardLookupId: "3966",
    VDNumber: "76130108",
    RegisteredPopulation: "444",
    VotingStationName: "MAKALANG PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  GROOTDRAAI  CAPRICON",
    Latitude: "-23.1169",
    Longitude: "28.7064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15333",
    WardLookupId: "3966",
    VDNumber: "76130119",
    RegisteredPopulation: "1024",
    VotingStationName: "SEFOTO PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  WINDHOEK  CAPRICON",
    Latitude: "-23.163",
    Longitude: "28.7184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15334",
    WardLookupId: "3966",
    VDNumber: "76130513",
    RegisteredPopulation: "560",
    VotingStationName: "SEFOTWANE PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  VERGELEGEN  CAPRICON",
    Latitude: "-23.1316",
    Longitude: "28.7068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15335",
    WardLookupId: "3966",
    VDNumber: "76130636",
    RegisteredPopulation: "690",
    VotingStationName: "MMAGONI WATER COMMUNITY",
    Address: "N/A  LESFORTEIN  BLOUBERG",
    Latitude: "-23.1489",
    Longitude: "28.782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15336",
    WardLookupId: "3967",
    VDNumber: "76130030",
    RegisteredPopulation: "1054",
    VotingStationName: "KAWENE PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  MY-DARLING FARM  CAPRICON",
    Latitude: "-23.0836",
    Longitude: "28.7998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15337",
    WardLookupId: "3967",
    VDNumber: "76130052",
    RegisteredPopulation: "510",
    VotingStationName: "TUMAKGOLE HIGH SCHOOL",
    Address:
      "N/A NO STREET NAME  BULTFONTEIN & BERGENDAL OR MMAMOLEKA  CAPRICORN",
    Latitude: "-23.1063",
    Longitude: "28.7452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15338",
    WardLookupId: "3967",
    VDNumber: "76130085",
    RegisteredPopulation: "1110",
    VotingStationName: "SPRINGFIELD PRE-SCHOOL",
    Address: "BLOUBERG [BOCHUM/MY DARLING]  ",
    Latitude: "-23.1423",
    Longitude: "28.8123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15339",
    WardLookupId: "3967",
    VDNumber: "76130658",
    RegisteredPopulation: "433",
    VotingStationName: "MAKHUTLA PRIMARY SCHOOL",
    Address: "N/A NO STREET NAME  GA KGATLA  CAPRICON",
    Latitude: "-23.0877",
    Longitude: "28.8716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15340",
    WardLookupId: "3967",
    VDNumber: "76130805",
    RegisteredPopulation: "398",
    VotingStationName: "TLHAKAUMA PRIMARY SCHOOL",
    Address: "BERGENDAL  BLOUBERG",
    Latitude: "-23.115",
    Longitude: "28.7355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15341",
    WardLookupId: "3968",
    VDNumber: "76130153",
    RegisteredPopulation: "495",
    VotingStationName: "BOTSHWA PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  SWEETHOME  CAPRICON",
    Latitude: "-23.1831",
    Longitude: "28.8436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15342",
    WardLookupId: "3968",
    VDNumber: "76130175",
    RegisteredPopulation: "1230",
    VotingStationName: "KOBE PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  BLACKHILL  CAPRICON",
    Latitude: "-23.1724",
    Longitude: "28.8835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15343",
    WardLookupId: "3968",
    VDNumber: "76130197",
    RegisteredPopulation: "618",
    VotingStationName: "MATJEKETLANE PRIMARY SCHOOL",
    Address: "N/A NO SRTEET NAME  BOGNAFORUM  SENWABARWANA",
    Latitude: "-23.1608",
    Longitude: "28.9328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15344",
    WardLookupId: "3968",
    VDNumber: "76130221",
    RegisteredPopulation: "491",
    VotingStationName: "RANKHUMANENG PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  BOGNAFARM  CAPRICON",
    Latitude: "-23.1644",
    Longitude: "28.902",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15345",
    WardLookupId: "3968",
    VDNumber: "76130243",
    RegisteredPopulation: "492",
    VotingStationName: "LEHLOEDI PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  LIPZIG/SEPHIKENG  CAPRICON",
    Latitude: "-23.1334",
    Longitude: "28.898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15346",
    WardLookupId: "3968",
    VDNumber: "76130254",
    RegisteredPopulation: "655",
    VotingStationName: "SESALONG PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  LEPZIG/SESALONG  CAPRICON",
    Latitude: "-23.1442",
    Longitude: "28.9228",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15347",
    WardLookupId: "3969",
    VDNumber: "76130298",
    RegisteredPopulation: "420",
    VotingStationName: "DITATSU PRIMARY SCHOOL",
    Address: "NA NO STREET ADDRESS  DITATSU  CAPRICON",
    Latitude: "-23.2054",
    Longitude: "29.0499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15348",
    WardLookupId: "3969",
    VDNumber: "76130344",
    RegisteredPopulation: "427",
    VotingStationName: "DINOKO PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  BRAOD HILL/GA-MAILANA  CAPRICON",
    Latitude: "-23.2022",
    Longitude: "29.0169",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15349",
    WardLookupId: "3969",
    VDNumber: "76130423",
    RegisteredPopulation: "416",
    VotingStationName: "TEFU PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  GA-MASHALANE  CAPRICON",
    Latitude: "-23.2516",
    Longitude: "29.0954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15350",
    WardLookupId: "3969",
    VDNumber: "76130445",
    RegisteredPopulation: "406",
    VotingStationName: "MAMASONYA PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  TERWESCHEN  CAPRICON",
    Latitude: "-23.2261",
    Longitude: "29.0434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15351",
    WardLookupId: "3969",
    VDNumber: "76130478",
    RegisteredPopulation: "679",
    VotingStationName: "SENWABARWANA PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  BORKUM/DILAENENG  CAPRICON",
    Latitude: "-23.275",
    Longitude: "29.1293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15352",
    WardLookupId: "3969",
    VDNumber: "76130557",
    RegisteredPopulation: "780",
    VotingStationName: "RAMOLELEKWA PRE-SCHOOL",
    Address: "N/A NO STREET ADDRESS  BORKUM FARM  CAPRICON",
    Latitude: "-23.2721",
    Longitude: "29.1236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15353",
    WardLookupId: "3969",
    VDNumber: "76130737",
    RegisteredPopulation: "386",
    VotingStationName: "MAOKENG (TENT)",
    Address: "DILAENENG VILLAGE  BLOUBERG  DILAENENG",
    Latitude: "-23.2659",
    Longitude: "29.1087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15354",
    WardLookupId: "3969",
    VDNumber: "76130849",
    RegisteredPopulation: "289",
    VotingStationName: "MAKGOMOTSHWEU PRIMARY SCHOOL",
    Address: "GA - MAMOLELE  BLOUBERG  SENWABARWANA",
    Latitude: "-23.2678",
    Longitude: "29.0655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15355",
    WardLookupId: "3969",
    VDNumber: "76130850",
    RegisteredPopulation: "125",
    VotingStationName: "DIKOLOI SECONDARY SCHOOL",
    Address: "MILTONDUFF  HANANWA TRIBAL",
    Latitude: "-23.2087",
    Longitude: "29.001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15356",
    WardLookupId: "3970",
    VDNumber: "76130300",
    RegisteredPopulation: "1167",
    VotingStationName: "RADIKGOBETHE HIGH SCHOOL",
    Address: "N/A NO STREET ADDRESS  INVERAAN  CAPRICON",
    Latitude: "-23.1374",
    Longitude: "28.9901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15357",
    WardLookupId: "3970",
    VDNumber: "76130333",
    RegisteredPopulation: "579",
    VotingStationName: "RAPHAHLA SECONDARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  NAIRN  CAPRICON",
    Latitude: "-23.1521",
    Longitude: "29.0289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15358",
    WardLookupId: "3970",
    VDNumber: "76130355",
    RegisteredPopulation: "1123",
    VotingStationName: "JOHN MOLOKOMME PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  NAIRN  CAPRICON",
    Latitude: "-23.1414",
    Longitude: "29.0024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15359",
    WardLookupId: "3970",
    VDNumber: "76130377",
    RegisteredPopulation: "300",
    VotingStationName: "SEMETSE PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  THE GLADE/MORALE  CAPRICON",
    Latitude: "-23.0763",
    Longitude: "29.0368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15360",
    WardLookupId: "3970",
    VDNumber: "76130489",
    RegisteredPopulation: "545",
    VotingStationName: "MATSWAKE HIGH SCHOOL",
    Address: "N/A NO STREET ADDRESS  GA-MANAKA (BULL-BULL)  CAPRICON",
    Latitude: "-23.121",
    Longitude: "29.0519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15361",
    WardLookupId: "3970",
    VDNumber: "76130726",
    RegisteredPopulation: "519",
    VotingStationName: "RAMARULA PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS AVAILIBLE  DANZIGHT  CAPRICORN ",
    Latitude: "-23.1276",
    Longitude: "29.0197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15362",
    WardLookupId: "3971",
    VDNumber: "76130412",
    RegisteredPopulation: "767",
    VotingStationName: "SEAKAMELA TRIBAL AUTHORITY",
    Address: "N/A NO STREET NAME  AVON/BOCHUM  CAPRICORN",
    Latitude: "-23.139",
    Longitude: "29.0965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15363",
    WardLookupId: "3971",
    VDNumber: "76130456",
    RegisteredPopulation: "962",
    VotingStationName: "MALUSI HIGH SCHOOL",
    Address: "N/A NO STREET ADDRESS  AVON  CAPRICON",
    Latitude: "-23.144",
    Longitude: "29.1004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15364",
    WardLookupId: "3971",
    VDNumber: "76130614",
    RegisteredPopulation: "1148",
    VotingStationName: "AVON CIVIC CENTRE",
    Address: "N/A  AVON  BLOUBERG (BOUHUM/MY DARLING)",
    Latitude: "-23.1437",
    Longitude: "29.1025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15365",
    WardLookupId: "3971",
    VDNumber: "76130681",
    RegisteredPopulation: "1044",
    VotingStationName: "DITHEBELE SECONDARY SCHOOL",
    Address:
      "N/A NO STREET ADDRESS  BLOUBERG [BOCHUM/MY DARLING]  SENWABARWANA",
    Latitude: "-23.1437",
    Longitude: "29.1153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15366",
    WardLookupId: "3972",
    VDNumber: "76130209",
    RegisteredPopulation: "423",
    VotingStationName: "RAMATEMA HIGH SCHOOL",
    Address: "N/A NO STREET ADDRESS  DANTZIG  CAPRICON",
    Latitude: "-23.217",
    Longitude: "29.0814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15367",
    WardLookupId: "3972",
    VDNumber: "76130322",
    RegisteredPopulation: "2118",
    VotingStationName: "RALEKWANA SECODARY SCHOOL",
    Address: "PURASPAN VILLAGE  BOCHUM",
    Latitude: "-23.1666",
    Longitude: "29.1289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15368",
    WardLookupId: "3972",
    VDNumber: "76130535",
    RegisteredPopulation: "349",
    VotingStationName: "SEHWIDITSANE PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  BULL-BULL,BOCHUM  CAPRICON",
    Latitude: "-23.1395",
    Longitude: "29.0639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15369",
    WardLookupId: "3972",
    VDNumber: "76130568",
    RegisteredPopulation: "351",
    VotingStationName: "TSWATSANE PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  PICKUM VILLAGE  CAPRICON",
    Latitude: "-23.1856",
    Longitude: "29.0725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15370",
    WardLookupId: "3972",
    VDNumber: "76130704",
    RegisteredPopulation: "608",
    VotingStationName: "MANTOTOLE PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  BLOUBERG [BOCHUM/MY DARLING]  CAPRICON",
    Latitude: "-23.1749",
    Longitude: "29.0455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15371",
    WardLookupId: "3973",
    VDNumber: "76120219",
    RegisteredPopulation: "380",
    VotingStationName: "VIVO (TENT)",
    Address: "VIVO  ALLDAYS",
    Latitude: "-23.0485",
    Longitude: "29.2769",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15372",
    WardLookupId: "3973",
    VDNumber: "76130265",
    RegisteredPopulation: "1536",
    VotingStationName: "INDERMARK COMMUNITY HALL",
    Address: "N/A  INDERMARK  BLOUBERG",
    Latitude: "-23.0809",
    Longitude: "29.1077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15373",
    WardLookupId: "3973",
    VDNumber: "76130287",
    RegisteredPopulation: "908",
    VotingStationName: "KGOBOKANANG P SCHOOL",
    Address: "N/A NO STREET ADDRESS  INDERMARK  CAPRICON",
    Latitude: "-23.073",
    Longitude: "29.1042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15374",
    WardLookupId: "3973",
    VDNumber: "76130434",
    RegisteredPopulation: "726",
    VotingStationName: "BOIKHUTSO PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  INDERMARK  CAPRICON",
    Latitude: "-23.0627",
    Longitude: "29.097",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15375",
    WardLookupId: "3973",
    VDNumber: "76130827",
    RegisteredPopulation: "464",
    VotingStationName: "MAPHUTHA SECONDARY SCHOOL",
    Address: "INDERMARK  BLOUBERG  SENWABARWANA",
    Latitude: "-23.0919",
    Longitude: "29.1109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15376",
    WardLookupId: "3974",
    VDNumber: "76120107",
    RegisteredPopulation: "843",
    VotingStationName: "MMATSELA HIGH SCHOOL",
    Address: "PAX VILLAGE  BLOUBERG  KIBI",
    Latitude: "-22.9472",
    Longitude: "28.9231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15377",
    WardLookupId: "3974",
    VDNumber: "76120129",
    RegisteredPopulation: "621",
    VotingStationName: "NONYANA PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  EDWINSDALE/KROMHOEK  MOKWENA",
    Latitude: "-22.9824",
    Longitude: "29.0036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15378",
    WardLookupId: "3974",
    VDNumber: "76120130",
    RegisteredPopulation: "770",
    VotingStationName: "MABEA SECONDARY SCHOOL",
    Address: "BLOUBERG    BLOUBERG  SENWABARWANA",
    Latitude: "-22.9843",
    Longitude: "28.9575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15379",
    WardLookupId: "3974",
    VDNumber: "76130063",
    RegisteredPopulation: "371",
    VotingStationName: "MAKGAFEIA PRIMARY SCHOOL",
    Address: "NA NA  THE GLEN  BOCHUM",
    Latitude: "-23.0113",
    Longitude: "28.9535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15380",
    WardLookupId: "3974",
    VDNumber: "76130579",
    RegisteredPopulation: "254",
    VotingStationName: "SEPANYA PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  GLENFERNIS  CAPRICON]",
    Latitude: "-23.0403",
    Longitude: "28.9231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15381",
    WardLookupId: "3974",
    VDNumber: "76130669",
    RegisteredPopulation: "427",
    VotingStationName: "MAMOYAHABO PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  RADITSHABA  CAPRICON",
    Latitude: "-23.0174",
    Longitude: "28.9939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15382",
    WardLookupId: "3975",
    VDNumber: "76130142",
    RegisteredPopulation: "651",
    VotingStationName: "SEIPHI SECONDARY SCHOOL",
    Address: "NA NA  BROADHILL  BOCHUM",
    Latitude: "-23.1773",
    Longitude: "29.0084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15383",
    WardLookupId: "3975",
    VDNumber: "76130210",
    RegisteredPopulation: "815",
    VotingStationName: "MALEBOHO HIGH SCHOOL",
    Address: "NA NA  BUFFELSHOEK/KWARUNG  BOCHUM",
    Latitude: "-23.1389",
    Longitude: "28.9419",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15384",
    WardLookupId: "3975",
    VDNumber: "76130276",
    RegisteredPopulation: "1245",
    VotingStationName: "BAHANANWA TRIBAL AUTHORITY",
    Address: "N/A NO STREET ADDRESS  BOSEHLA  CAPRICON",
    Latitude: "-23.1313",
    Longitude: "28.957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15385",
    WardLookupId: "3975",
    VDNumber: "76130388",
    RegisteredPopulation: "663",
    VotingStationName: "MAMPOTE PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  MAMPOTE/ADDNEY  CAPRICON",
    Latitude: "-23.1936",
    Longitude: "28.9649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15386",
    WardLookupId: "3975",
    VDNumber: "76130782",
    RegisteredPopulation: "657",
    VotingStationName: "BODIROA PRIMARY SCHOOL",
    Address: "MALEBOHO  GA MALEBOHO  SENWABARWANA",
    Latitude: "-23.131",
    Longitude: "28.9758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15387",
    WardLookupId: "3976",
    VDNumber: "76120040",
    RegisteredPopulation: "1271",
    VotingStationName: "SELELO PRIMARY SCHOOL",
    Address: "NA NO STREET ADDRESS  DEVREDE  SENWABARWANA",
    Latitude: "-22.9166",
    Longitude: "29.0191",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15388",
    WardLookupId: "3976",
    VDNumber: "76120118",
    RegisteredPopulation: "1381",
    VotingStationName: "MATHIPA MAKGATO SECONDARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  KROMHOEK  CAPRICON",
    Latitude: "-22.9308",
    Longitude: "29.0337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15389",
    WardLookupId: "3976",
    VDNumber: "76120309",
    RegisteredPopulation: "405",
    VotingStationName: "MAKGATHO TRIBAL OFFICE",
    Address: "BLOUBERG [BOCHUM/MY DARLING]  ",
    Latitude: "-22.9326",
    Longitude: "29.0265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15390",
    WardLookupId: "3976",
    VDNumber: "76120365",
    RegisteredPopulation: "539",
    VotingStationName: "MABOI PRIMARY SCHOOL",
    Address: "KROMHOEK GA-MAKGATO  BLOUBERG  SENWABARWANA",
    Latitude: "-22.9297",
    Longitude: "29.0364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15391",
    WardLookupId: "3977",
    VDNumber: "76120095",
    RegisteredPopulation: "1119",
    VotingStationName: "KGALUSHI HIGH SCHOOL",
    Address: "N/A NO STREET ADDRESS  TOOVERFONTEIN  CAPRICON",
    Latitude: "-22.9037",
    Longitude: "28.7757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15392",
    WardLookupId: "3977",
    VDNumber: "76120196",
    RegisteredPopulation: "1103",
    VotingStationName: "RASEKGALE PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  ELDORADO  SENWABARWANA",
    Latitude: "-22.8955",
    Longitude: "28.811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15393",
    WardLookupId: "3977",
    VDNumber: "76120242",
    RegisteredPopulation: "321",
    VotingStationName: "MAMA PRIMARY SCHOOL",
    Address: "GA -MASHAMAITE VILLAGE  CAPRICORN  SENWABAREWANA",
    Latitude: "-22.9002",
    Longitude: "28.8567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15394",
    WardLookupId: "3977",
    VDNumber: "76120253",
    RegisteredPopulation: "404",
    VotingStationName: "MAKGARI  PRIMARY SCHOOL",
    Address: "BURGERREGT  BLOUBERG  SENWABARWANA",
    Latitude: "-22.9452",
    Longitude: "28.8663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15395",
    WardLookupId: "3977",
    VDNumber: "76120275",
    RegisteredPopulation: "853",
    VotingStationName: "MASHIE PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  ESOUARINCA  SENWABARWANA",
    Latitude: "-22.9205",
    Longitude: "28.8079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15396",
    WardLookupId: "3977",
    VDNumber: "76120354",
    RegisteredPopulation: "633",
    VotingStationName: "MOHLAELA PRE SCHOOL",
    Address: "BOCHUM  CAPRICORN  SENWABARWANA",
    Latitude: "-22.8885",
    Longitude: "28.7563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15397",
    WardLookupId: "3978",
    VDNumber: "76120084",
    RegisteredPopulation: "744",
    VotingStationName: "KIBI TRIBAL AUTHORITY",
    Address: "KIBI  MABOTHA  ALLDAYS",
    Latitude: "-22.8364",
    Longitude: "28.8469",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15398",
    WardLookupId: "3978",
    VDNumber: "76120141",
    RegisteredPopulation: "321",
    VotingStationName: "RAPHOTOLOLO SCHOOL",
    Address: "N/A NO STREET ADDRESS  ARRIE  SENWABARWANA",
    Latitude: "-22.8063",
    Longitude: "28.7634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15399",
    WardLookupId: "3978",
    VDNumber: "76120152",
    RegisteredPopulation: "739",
    VotingStationName: "MODIKWA PRIMARY SCHOOL",
    Address: "N/A NO STREET NAME  SIMPSON RAMASWIKANA  SENWABARWANA",
    Latitude: "-22.7515",
    Longitude: "28.7916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15400",
    WardLookupId: "3978",
    VDNumber: "76120163",
    RegisteredPopulation: "709",
    VotingStationName: "MAHADIKANA SECONDARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  GROOTPAN  CAPRICON",
    Latitude: "-22.7639",
    Longitude: "28.8346",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15401",
    WardLookupId: "3978",
    VDNumber: "76120174",
    RegisteredPopulation: "604",
    VotingStationName: "MPEBE PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  LONGDEN  SENWABARWANA",
    Latitude: "-22.7893",
    Longitude: "28.8889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15402",
    WardLookupId: "3978",
    VDNumber: "76120231",
    RegisteredPopulation: "328",
    VotingStationName: "MORONGWA PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  SIAS (MODIMONTHUSE)  CAPRICON",
    Latitude: "-22.7869",
    Longitude: "28.8009",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15403",
    WardLookupId: "3978",
    VDNumber: "76120321",
    RegisteredPopulation: "202",
    VotingStationName: "MANTSHABE PRIMARY SCHOOL",
    Address: "N/A NO STREET NAME  BLOUBERG [BOCHUM/MY DARLING]  CAPRICON",
    Latitude: "-22.8616",
    Longitude: "28.8848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15404",
    WardLookupId: "3979",
    VDNumber: "76120017",
    RegisteredPopulation: "1221",
    VotingStationName: "ALLDAYS COMMUNITY HALL",
    Address: "140 EXT 01 MAROELA STREET  CAPRICORN  ALLDAYS",
    Latitude: "-22.6781",
    Longitude: "29.107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15405",
    WardLookupId: "3979",
    VDNumber: "76120051",
    RegisteredPopulation: "1239",
    VotingStationName: "MASETE PUBLIC PRIMARY SCHOOL TAAIBOSCHGROEY",
    Address: "N/A NO STREET ADDRESS  TAAIBOSCHGROET  CAPRICON",
    Latitude: "-22.8501",
    Longitude: "28.9135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15406",
    WardLookupId: "3979",
    VDNumber: "76120310",
    RegisteredPopulation: "1351",
    VotingStationName: "THOKAMPE HIGH SCHOOL",
    Address: "N/A NO STREET ADDRESS  BLOUBERG [BOCHUM/MY DARLING]  CAPRICON",
    Latitude: "-22.8461",
    Longitude: "28.9084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15407",
    WardLookupId: "3979",
    VDNumber: "76120376",
    RegisteredPopulation: "552",
    VotingStationName: "ALLDAYS PRIMARY SCHOOL -",
    Address: "ALLDAYS  BLOUBERG  ALLDAYS",
    Latitude: "-22.6662",
    Longitude: "29.1136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15408",
    WardLookupId: "3980",
    VDNumber: "76130041",
    RegisteredPopulation: "1504",
    VotingStationName: "CAPRICON FET COLLEGE( SENWABARWANA CAMPUS)",
    Address: "N/A NO STREET ADDRESS  BOCHUM TOWNSHIP  SENWABARWANA",
    Latitude: "-23.2875",
    Longitude: "29.1329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15409",
    WardLookupId: "3980",
    VDNumber: "76130546",
    RegisteredPopulation: "828",
    VotingStationName: "WITTEN PRE-SCHOOL",
    Address: "N/A NO STREET ADDRESS  WITTEN FARM(MAREIS)  CAPRICON",
    Latitude: "-23.2546",
    Longitude: "29.146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15410",
    WardLookupId: "3980",
    VDNumber: "76130692",
    RegisteredPopulation: "987",
    VotingStationName: "MAPHETSA PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  WITTEN  CAPRICON]",
    Latitude: "-23.2577",
    Longitude: "29.1464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15411",
    WardLookupId: "3980",
    VDNumber: "76130748",
    RegisteredPopulation: "1129",
    VotingStationName: "BOCHUM SHOWGROUND HALL",
    Address: "BOCHUM TOWN SHIP  CAPRICORN  SENWABARWANA",
    Latitude: "-23.2844",
    Longitude: "29.1389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15412",
    WardLookupId: "3980",
    VDNumber: "76130838",
    RegisteredPopulation: "566",
    VotingStationName: "BLOUBERG MUNICIPAL SATELLITE OFFICE",
    Address: "WITTEN VILLAGE  BLOUBERG  CAPRICORN",
    Latitude: "-23.2538",
    Longitude: "29.1377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15413",
    WardLookupId: "3981",
    VDNumber: "76120062",
    RegisteredPopulation: "345",
    VotingStationName: "RASEKHUTA PRIMARY SCHOOL",
    Address: "N/A THORP  ALLDAYS",
    Latitude: "-22.9497",
    Longitude: "28.6855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15414",
    WardLookupId: "3981",
    VDNumber: "76120185",
    RegisteredPopulation: "700",
    VotingStationName: "MALENKWANA PRIMARY SCHOOL",
    Address: "WEGDRAAI  ALLDAYS",
    Latitude: "-22.8897",
    Longitude: "28.7051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15415",
    WardLookupId: "3981",
    VDNumber: "76120264",
    RegisteredPopulation: "687",
    VotingStationName: "MOSHOKWA SECONDARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  SLAAPHOEK  CAPRICON",
    Latitude: "-22.8953",
    Longitude: "28.7354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15416",
    WardLookupId: "3981",
    VDNumber: "76120286",
    RegisteredPopulation: "284",
    VotingStationName: "MATSUOKWANE SECONDARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  ARCHIBOLD  CAPRICON",
    Latitude: "-22.9756",
    Longitude: "28.6994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15417",
    WardLookupId: "3981",
    VDNumber: "76120297",
    RegisteredPopulation: "354",
    VotingStationName: "MMAKGOMO PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  GEDION  CAPRICON",
    Latitude: "-22.9031",
    Longitude: "28.6792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15418",
    WardLookupId: "3981",
    VDNumber: "76120343",
    RegisteredPopulation: "201",
    VotingStationName: "PHIRIMELA DAY CARE CENTRE",
    Address: "77 GA-MOTADI  CAPRICORN  SENWABARWANA",
    Latitude: "-22.8913",
    Longitude: "28.6856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15419",
    WardLookupId: "3981",
    VDNumber: "76510010",
    RegisteredPopulation: "260",
    VotingStationName: "TANT POP SHOP MANDEVILLE",
    Address: "MAASTROOM  BLOUBERG   HANANWA TRIBAL OFFICE",
    Latitude: "-22.7418",
    Longitude: "28.4522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15420",
    WardLookupId: "3981",
    VDNumber: "76510043",
    RegisteredPopulation: "492",
    VotingStationName: "SEKHUNG INTERMEDIATE SCHOOL",
    Address: "TOLWE  BLOUBERG  LEPHALALE",
    Latitude: "-23.0087",
    Longitude: "28.5658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15421",
    WardLookupId: "3981",
    VDNumber: "76510054",
    RegisteredPopulation: "428",
    VotingStationName: "BOITHUTO SCHOOL",
    Address: "EENDVOGELSDRIFT  SWARTWATER  ELLISRAS",
    Latitude: "-22.8444",
    Longitude: "28.2092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15422",
    WardLookupId: "3982",
    VDNumber: "76320097",
    RegisteredPopulation: "299",
    VotingStationName: "MOSEHLENG PRIMARY SCHOOL",
    Address: "N/A NO STREET ADDRESS  MOSEHLENG  CAPRICON",
    Latitude: "-23.4895",
    Longitude: "28.9155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15423",
    WardLookupId: "3982",
    VDNumber: "76320143",
    RegisteredPopulation: "228",
    VotingStationName: "WELGELEGEN PRIMARY SCHOOL",
    Address: "MOLETJIE  PIETERSBURG",
    Latitude: "-23.445",
    Longitude: "28.9869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15424",
    WardLookupId: "3982",
    VDNumber: "76320200",
    RegisteredPopulation: "934",
    VotingStationName: "NARE MOHLALERWA PRIMARY SCHOOL",
    Address: "N/A GA- SEBOTSI VILLAGE  MOLETJIE  AGANANG",
    Latitude: "-23.5246",
    Longitude: "28.993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15425",
    WardLookupId: "3982",
    VDNumber: "76320211",
    RegisteredPopulation: "756",
    VotingStationName: "BORUME PRIMARY SCHOOL",
    Address: "N/A COOPERSPARK VILLAGE  MOLETJIE  AGANANG",
    Latitude: "-23.4742",
    Longitude: "29.0412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15426",
    WardLookupId: "3982",
    VDNumber: "76321133",
    RegisteredPopulation: "221",
    VotingStationName: "SEOBI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  UITKYK NO.1  CAPRICORN",
    Latitude: "-23.3944",
    Longitude: "29.0167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15427",
    WardLookupId: "3982",
    VDNumber: "76321177",
    RegisteredPopulation: "303",
    VotingStationName: "ROSENKRANTZ PRIMARY SCHOOL",
    Address: "N/A ROSENKRANTZ VILLAGE  MOLETJIE  AGANANG",
    Latitude: "-23.495",
    Longitude: "28.9879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15428",
    WardLookupId: "3982",
    VDNumber: "76321278",
    RegisteredPopulation: "171",
    VotingStationName: "KHOLOFELO PRE-SCHOOL",
    Address: "NO STREET ADDRESS  TERBRUGGE  CAPRICORN]",
    Latitude: "-23.4047",
    Longitude: "29.0647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15429",
    WardLookupId: "3982",
    VDNumber: "76321289",
    RegisteredPopulation: "281",
    VotingStationName: "BURGWAL PRIMARY SCHOOL",
    Address: "-  BURGWAL  AGANANG [MOLETJI/MATLALA]",
    Latitude: "-23.4462",
    Longitude: "29.0323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15430",
    WardLookupId: "3982",
    VDNumber: "76321346",
    RegisteredPopulation: "93",
    VotingStationName: "METHODIST CHURCH-UIT KYK NO.2",
    Address: "UIT KYK NO.2  BLOUBERG MUNICIPALITY  MATLALA",
    Latitude: "-23.4092",
    Longitude: "28.9954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15431",
    WardLookupId: "3983",
    VDNumber: "76320109",
    RegisteredPopulation: "702",
    VotingStationName: "NGWANALLELA HIGH SCHOOL",
    Address: "N/A AMBERGADE VILLAGE  AMBERGADE  AGANANG",
    Latitude: "-23.5196",
    Longitude: "28.9372",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15432",
    WardLookupId: "3983",
    VDNumber: "76320176",
    RegisteredPopulation: "813",
    VotingStationName: "MAMEHLABE SENIOR SECONDARY SCHOOL",
    Address: "A/N MAMEHLABE VILLAGE  AGANANG",
    Latitude: "-23.5496",
    Longitude: "28.9734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15433",
    WardLookupId: "3983",
    VDNumber: "76320244",
    RegisteredPopulation: "577",
    VotingStationName: "MOSHIBI MOKOBODI PRIMARY SCHOOL",
    Address: "MOKOBODI  POSCHLAAGTE  AGANANG",
    Latitude: "-23.5852",
    Longitude: "29.0035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15434",
    WardLookupId: "3983",
    VDNumber: "76320255",
    RegisteredPopulation: "365",
    VotingStationName: "PROSPECT PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  PROSPECT  CAPRICORN",
    Latitude: "-23.616",
    Longitude: "29.012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15435",
    WardLookupId: "3983",
    VDNumber: "76321155",
    RegisteredPopulation: "645",
    VotingStationName: "LEOKANENG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  LEOKANENG  CAPRICORN",
    Latitude: "-23.522",
    Longitude: "29.0225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15436",
    WardLookupId: "3983",
    VDNumber: "76321559",
    RegisteredPopulation: "280",
    VotingStationName: "PROSPECT TRADING STORE",
    Address: "PROSPECT VILLAGE  CAPRICORN  BAKONE",
    Latitude: "-23.5937",
    Longitude: "29.0106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15437",
    WardLookupId: "1891",
    VDNumber: "76180024",
    RegisteredPopulation: "195",
    VotingStationName: "BOERLANDS 444 LAERSKOOL",
    Address: "NO ADRESS  DENDRON VILLAGE  DENDRON",
    Latitude: "-23.3291",
    Longitude: "29.522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15438",
    WardLookupId: "1891",
    VDNumber: "76190306",
    RegisteredPopulation: "306",
    VotingStationName: "BOSBUILT HALL",
    Address: "ZZ2 RIETGAT  PLANTKOPJE PLAAS  P/ BURG",
    Latitude: "-23.6716",
    Longitude: "29.7533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15439",
    WardLookupId: "1891",
    VDNumber: "76190508",
    RegisteredPopulation: "537",
    VotingStationName: "SOEKMEKAAR TOWN HALL",
    Address: "119 KERK  SOEKMEKAAR  SEKGOSESE",
    Latitude: "-23.4948",
    Longitude: "29.9286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15440",
    WardLookupId: "1891",
    VDNumber: "76190586",
    RegisteredPopulation: "1144",
    VotingStationName: "ITSHOMELENG PRIMARY SCHOOL",
    Address: "N/A  SOEKMEKAAR  NTHABISENG",
    Latitude: "-23.4782",
    Longitude: "29.9102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15441",
    WardLookupId: "1891",
    VDNumber: "76190597",
    RegisteredPopulation: "683",
    VotingStationName: "CAPRICORN PARK CLINIC",
    Address: "STAND 397.SOEKMEKAAR  SOEKMEKAAR  MOREBENG",
    Latitude: "-23.4998",
    Longitude: "29.9462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15442",
    WardLookupId: "1891",
    VDNumber: "76190621",
    RegisteredPopulation: "140",
    VotingStationName: "DEELKRAAL PRIMARY SCHOOL",
    Address: "N/A  RAMOKGOPA  RAMOKGOPA",
    Latitude: "-23.4665",
    Longitude: "29.8675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15443",
    WardLookupId: "1891",
    VDNumber: "76190665",
    RegisteredPopulation: "350",
    VotingStationName: "RATSAKA CRECHE",
    Address: "185 GA RASTAKA  MOLEMOLE  RATSAKA TRIBAL AUTHORITY",
    Latitude: "-23.4951",
    Longitude: "29.9506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15444",
    WardLookupId: "1892",
    VDNumber: "76190193",
    RegisteredPopulation: "1632",
    VotingStationName: "MASEDI SCHOOL",
    Address: "MASEDI VILLAGE  BOTLOKWA GA/ RAMOKGOPA  SEKGOSESE",
    Latitude: "-23.4631",
    Longitude: "29.8347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15445",
    WardLookupId: "1892",
    VDNumber: "76190272",
    RegisteredPopulation: "851",
    VotingStationName: "SEALE SECONDARY SCHOOL",
    Address: "KGATLA VILLAGE  EISLEBEN  SEKGOSESE",
    Latitude: "-23.5051",
    Longitude: "29.8329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15446",
    WardLookupId: "1892",
    VDNumber: "76190283",
    RegisteredPopulation: "1121",
    VotingStationName: "MASWAHLENE  PRIMARY  SCHOOL",
    Address: "EISLEBEN VILLAGE  EISLEBEN  SEKGOSESE GA MASEKELA",
    Latitude: "-23.5234",
    Longitude: "29.835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15447",
    WardLookupId: "1892",
    VDNumber: "76190294",
    RegisteredPopulation: "915",
    VotingStationName: "TABUDI HIGH SCHOOL",
    Address: "EISLEBEN VILLAGE  EISLEBEN  SEKGOSESE",
    Latitude: "-23.5338",
    Longitude: "29.827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15448",
    WardLookupId: "1893",
    VDNumber: "76190205",
    RegisteredPopulation: "780",
    VotingStationName: "RAMOKGOPA TRADITIONAL AUTHORITY",
    Address: "MOSHATE VILLAGE  GA-RAMOKGOPA  SEKGOSESE",
    Latitude: "-23.472",
    Longitude: "29.8052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15449",
    WardLookupId: "1893",
    VDNumber: "76190216",
    RegisteredPopulation: "873",
    VotingStationName: "MANTEKEDI SCHOOL",
    Address: "THOKA VILLAGE  GA-RAMOKGOPA  SEKGOSESE",
    Latitude: "-23.4602",
    Longitude: "29.7999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15450",
    WardLookupId: "1893",
    VDNumber: "76190238",
    RegisteredPopulation: "977",
    VotingStationName: "MOKOMENE SCHOOL",
    Address: "STAND 0014 MASEROLO  GA-RAMOKGOPA  RAMOKGOPA",
    Latitude: "-23.4688",
    Longitude: "29.8159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15451",
    WardLookupId: "1893",
    VDNumber: "76190531",
    RegisteredPopulation: "714",
    VotingStationName: "MOKORORWANE PRE-SCHOOL",
    Address: "HA-PHASHA  BOTLOKWA  SEKGOSESE",
    Latitude: "-23.4324",
    Longitude: "29.7365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15452",
    WardLookupId: "1893",
    VDNumber: "76190632",
    RegisteredPopulation: "275",
    VotingStationName: "MASHAA HIGH SCHOOL",
    Address: "STANDW100.MASHAHA SECTION .  MOGWADI  RAMOKGOPA",
    Latitude: "-23.4557",
    Longitude: "29.8336",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15453",
    WardLookupId: "1894",
    VDNumber: "76190227",
    RegisteredPopulation: "786",
    VotingStationName: "SEPHALA PRIMARY SCHOOL",
    Address: "SEPHALA VILLAGE  GA-RAMOKGOPA  SEKGOSESE",
    Latitude: "-23.4534",
    Longitude: "29.8136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15454",
    WardLookupId: "1894",
    VDNumber: "76190249",
    RegisteredPopulation: "988",
    VotingStationName: "RAMOTLHALE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-RAMOKGOPA  SEKGOSESE",
    Latitude: "-23.4619",
    Longitude: "29.8125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15455",
    WardLookupId: "1894",
    VDNumber: "76190250",
    RegisteredPopulation: "941",
    VotingStationName: "MAPALEDI SCHOOL",
    Address: "MADIEHE-MAPALEDI  GA-RAMOKGOPA  SEKGOSESE",
    Latitude: "-23.4511",
    Longitude: "29.8242",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15456",
    WardLookupId: "1894",
    VDNumber: "76190261",
    RegisteredPopulation: "775",
    VotingStationName: "BODUMA PRIMARY SCHOOL",
    Address: "MADIEHE VILLAGE  GA- RAMOKGOPA  SEKGOSESE",
    Latitude: "-23.462",
    Longitude: "29.8271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15457",
    WardLookupId: "1895",
    VDNumber: "76190036",
    RegisteredPopulation: "556",
    VotingStationName: "MAKGATO TRIBAL COUNCIL",
    Address: "MAKGATO VILAGE  MAKGATO VILLAGE  MAKGATO",
    Latitude: "-23.5112",
    Longitude: "29.6597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15458",
    WardLookupId: "1895",
    VDNumber: "76190047",
    RegisteredPopulation: "1241",
    VotingStationName: "SOKALEHOLO PRIMARY SCHOOL",
    Address: "STAND 625 MAKGATO  LETSATSANA VILLAGE  MAKGATO",
    Latitude: "-23.5053",
    Longitude: "29.6674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15459",
    WardLookupId: "1895",
    VDNumber: "76190058",
    RegisteredPopulation: "1281",
    VotingStationName: "MAMOTSHANA PRIMARY SCHOOL",
    Address: "SEKONYE  SEKONYE LEB VILLAGE  MACHAKA",
    Latitude: "-23.5111",
    Longitude: "29.6846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15460",
    WardLookupId: "1895",
    VDNumber: "76190610",
    RegisteredPopulation: "529",
    VotingStationName: "MOTLALAOHLE SECONDARY SCHOOL",
    Address: "MAKGATO   MOLEMOLE   MAKGATO",
    Latitude: "-23.5039",
    Longitude: "29.6934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15461",
    WardLookupId: "1896",
    VDNumber: "76190069",
    RegisteredPopulation: "821",
    VotingStationName: "KGWADU PRIMARY SCHOOL",
    Address: "SEKONYE  VILLAGE  SEKONYE VILLAGE  SEKGOSESE",
    Latitude: "-23.5061",
    Longitude: "29.6985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15462",
    WardLookupId: "1896",
    VDNumber: "76190070",
    RegisteredPopulation: "1124",
    VotingStationName: "KHUNWANA PRIMARY SCHOOL",
    Address: "SEKONYE   SEKONYE VILLAGE  MACHAKA",
    Latitude: "-23.5112",
    Longitude: "29.7085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15463",
    WardLookupId: "1896",
    VDNumber: "76190081",
    RegisteredPopulation: "702",
    VotingStationName: "MOSIMA SCHOOL",
    Address: "SPRINGS VILLAGE  SEKGOSESE",
    Latitude: "-23.5036",
    Longitude: "29.7153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15464",
    WardLookupId: "1896",
    VDNumber: "76190092",
    RegisteredPopulation: "415",
    VotingStationName: "RAMPO HIGH SCHOOL",
    Address: "NO STREET ADDRESS  SEKONYE VILLAGE  SEKGOSESE",
    Latitude: "-23.5058",
    Longitude: "29.706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15465",
    WardLookupId: "1896",
    VDNumber: "76190553",
    RegisteredPopulation: "604",
    VotingStationName: "MAPHOROKO COMMUNITY HALL",
    Address: "MAPHOROKO  MPHAKANE 1  DIKGADING",
    Latitude: "-23.4974",
    Longitude: "29.704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15466",
    WardLookupId: "1897",
    VDNumber: "76190160",
    RegisteredPopulation: "1089",
    VotingStationName: "TIDIMA HIGH SCHOOL",
    Address: "NO STREET ADDRESS  MATSEKE VILLAGE  SEKGOSESE",
    Latitude: "-23.4935",
    Longitude: "29.7395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15467",
    WardLookupId: "1897",
    VDNumber: "76190171",
    RegisteredPopulation: "848",
    VotingStationName: "MAUTSWI COMMUNITY SCHOOL",
    Address: "RAMATSOWE VILLAGE  BOTLOKWA  BOTLOKWA RAMATSOWE",
    Latitude: "-23.5067",
    Longitude: "29.7367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15468",
    WardLookupId: "1897",
    VDNumber: "76190182",
    RegisteredPopulation: "1360",
    VotingStationName: "MABEBA PRIMARY SCHOOL",
    Address: "NO STREET  RAMATJOWE VILLAGE  SEKGOSESE",
    Latitude: "-23.4821",
    Longitude: "29.7555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15469",
    WardLookupId: "1897",
    VDNumber: "76190575",
    RegisteredPopulation: "1076",
    VotingStationName: "SEFENE CRECHE",
    Address: "BOTLOKWA MPHAKANE  MOLEMOLE  MOLEMOLE RURAL",
    Latitude: "-23.4877",
    Longitude: "29.7327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15470",
    WardLookupId: "1898",
    VDNumber: "76190014",
    RegisteredPopulation: "481",
    VotingStationName: "LETHEBA HIGH SCHOOL",
    Address: "NO STREET ADDRESS  SEKAKENE  SEKGOSESE",
    Latitude: "-23.4621",
    Longitude: "29.6992",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15471",
    WardLookupId: "1898",
    VDNumber: "76190025",
    RegisteredPopulation: "1416",
    VotingStationName: "SEKAKENE COMMUNITY HALL",
    Address: "NO STREET ADDRESS  SEKAKENE VILLAGE  SEKGOSESE",
    Latitude: "-23.4568",
    Longitude: "29.6891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15472",
    WardLookupId: "1898",
    VDNumber: "76190104",
    RegisteredPopulation: "1183",
    VotingStationName: "BOTLOKWA SCHOOL",
    Address: "NO STREET ADDRESS  POLATLA VILLAGE  SEKGOSESE",
    Latitude: "-23.488",
    Longitude: "29.708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15473",
    WardLookupId: "1898",
    VDNumber: "76190115",
    RegisteredPopulation: "692",
    VotingStationName: "SHAPO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MANGATA VILLAGE  SEKGOSESE",
    Latitude: "-23.4649",
    Longitude: "29.7089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15474",
    WardLookupId: "1899",
    VDNumber: "76190137",
    RegisteredPopulation: "1672",
    VotingStationName: "PULEDI HIGHER PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  BOTLOKWA  SEKGOSESE",
    Latitude: "-23.4843",
    Longitude: "29.7206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15475",
    WardLookupId: "1899",
    VDNumber: "76190148",
    RegisteredPopulation: "577",
    VotingStationName: "MACHAKA TRIBAL OFFICE",
    Address: "MPHAKANE VILLAGE  SEKGOSESE",
    Latitude: "-23.4957",
    Longitude: "29.7186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15476",
    WardLookupId: "1899",
    VDNumber: "76190159",
    RegisteredPopulation: "950",
    VotingStationName: "MOTEKU SCHOOL",
    Address: "MATSEKE VILLAGE  SEKGOSESE",
    Latitude: "-23.4983",
    Longitude: "29.7235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15477",
    WardLookupId: "1899",
    VDNumber: "76190609",
    RegisteredPopulation: "587",
    VotingStationName: "ITUMELENG CRECHE",
    Address: "MATOKS  MOLEMOLE  MATOKS",
    Latitude: "-23.4739",
    Longitude: "29.7274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15478",
    WardLookupId: "1900",
    VDNumber: "76180035",
    RegisteredPopulation: "1826",
    VotingStationName: "DENDRON LAERSKOOL",
    Address: "NO STREET ADDRESS  DENDRON SURBUB  DENDRON",
    Latitude: "-23.3578",
    Longitude: "29.3267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15479",
    WardLookupId: "1900",
    VDNumber: "76180046",
    RegisteredPopulation: "278",
    VotingStationName: "MAKGALONG LUTHERN CHURCH",
    Address: "MAKGALONG A  MOLEMOLE  MOLETSIE TRIBAL OFFICE",
    Latitude: "-23.4725",
    Longitude: "29.2293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15480",
    WardLookupId: "1900",
    VDNumber: "76320750",
    RegisteredPopulation: "1284",
    VotingStationName: "CHECHEMA SECONDARY SCHOOL",
    Address: "MAROWE  AGANANG  AGANANG",
    Latitude: "-23.508",
    Longitude: "29.2539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15481",
    WardLookupId: "1900",
    VDNumber: "76321335",
    RegisteredPopulation: "388",
    VotingStationName: "MOLETJANA PRIMARY SCHOOL",
    Address: "MAROWE VILLAGE  CAPRICORN  MOLETJIE",
    Latitude: "-23.5083",
    Longitude: "29.2771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15482",
    WardLookupId: "1901",
    VDNumber: "76180068",
    RegisteredPopulation: "1023",
    VotingStationName: "KGOTLWANA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  KONINGKRANTZ  BOCHUM",
    Latitude: "-23.3263",
    Longitude: "29.2276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15483",
    WardLookupId: "1901",
    VDNumber: "76180136",
    RegisteredPopulation: "980",
    VotingStationName: "MOHODI TRIBAL OFFICE",
    Address: "MOHODI  MOHODI GA MANTHATA  BOCHUM",
    Latitude: "-23.3275",
    Longitude: "29.2377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15484",
    WardLookupId: "1901",
    VDNumber: "76180204",
    RegisteredPopulation: "379",
    VotingStationName: "MASEKO DRIVING SCHOOL.",
    Address: "MOHODI  MOLEMOLE  MOHODI MANTHATA",
    Latitude: "-23.3363",
    Longitude: "29.2256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15485",
    WardLookupId: "1901",
    VDNumber: "76180248",
    RegisteredPopulation: "167",
    VotingStationName: "MAKERA CRECHE",
    Address: "GA POOPEDI  MOLEMOLE  MOLEMOLE",
    Latitude: "-23.3495",
    Longitude: "29.1885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15486",
    WardLookupId: "1901",
    VDNumber: "76180259",
    RegisteredPopulation: "640",
    VotingStationName: "MALATJA CRECHE",
    Address: "MOHODI GA MANTHATA  MOLEMOLE  MOHODI MANTHATA",
    Latitude: "-23.3304",
    Longitude: "29.2227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15487",
    WardLookupId: "1901",
    VDNumber: "76180271",
    RegisteredPopulation: "182",
    VotingStationName: "KOEK-KOEK CRECHE",
    Address: "N/A  MOLEMOLE  MAUPSE VILLAGE",
    Latitude: "-23.3436",
    Longitude: "29.1724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15488",
    WardLookupId: "1902",
    VDNumber: "76180079",
    RegisteredPopulation: "1996",
    VotingStationName: "NTHLODUMELA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA MAPONTO  BOCHUM",
    Latitude: "-23.3109",
    Longitude: "29.2101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15489",
    WardLookupId: "1902",
    VDNumber: "76180125",
    RegisteredPopulation: "1268",
    VotingStationName: "GEORGE TLADI TECHNICAL SCHOOL",
    Address: "NO STREET ADDRESS  MOHODI GA MANTHATA  BOCHUM",
    Latitude: "-23.3169",
    Longitude: "29.2382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15490",
    WardLookupId: "1902",
    VDNumber: "76180215",
    RegisteredPopulation: "459",
    VotingStationName: "MAPONTO MEAT MARKET",
    Address: "MAPONTO  MOLEMOLE",
    Latitude: "-23.3176",
    Longitude: "29.2215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15491",
    WardLookupId: "1903",
    VDNumber: "76180114",
    RegisteredPopulation: "1526",
    VotingStationName: "RAPOHO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  WURTHSDORP  DENDRON",
    Latitude: "-23.3186",
    Longitude: "29.2417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15492",
    WardLookupId: "1903",
    VDNumber: "76180158",
    RegisteredPopulation: "936",
    VotingStationName: "MONYEMATHULA HIGHER PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA MADIKANA VILLAGE  B0CHUM",
    Latitude: "-23.2959",
    Longitude: "29.251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15493",
    WardLookupId: "1903",
    VDNumber: "76180237",
    RegisteredPopulation: "429",
    VotingStationName: "MAMOLELE SCHOOL",
    Address: "NO STREET ADDRESS  MANTHATA  MOLEMOLE [DENDRON/DIKGALE]",
    Latitude: "-23.2851",
    Longitude: "29.2462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15494",
    WardLookupId: "1903",
    VDNumber: "76180260",
    RegisteredPopulation: "338",
    VotingStationName: "LEHAIWA SECONDARY SCHOOL",
    Address: "N/A  MOLEMOLE  MADIKANA VILLAGE",
    Latitude: "-23.2925",
    Longitude: "29.243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15495",
    WardLookupId: "1903",
    VDNumber: "76180282",
    RegisteredPopulation: "193",
    VotingStationName: "MANKWE PARK TENT",
    Address: "MANKWE PARK  MOLEMOLE  MANTHATA TRIBAL OFFICE",
    Latitude: "-23.3285",
    Longitude: "29.2453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15496",
    WardLookupId: "1904",
    VDNumber: "76130074",
    RegisteredPopulation: "352",
    VotingStationName: "MONYESEBODU PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  BRILLIANT VILLAGE  BOCHUM",
    Latitude: "-23.3456",
    Longitude: "29.0588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15497",
    WardLookupId: "1904",
    VDNumber: "76130311",
    RegisteredPopulation: "374",
    VotingStationName: "PHAHAMANG SECONDARY SCHOOL",
    Address: "OVERDYK  OVERDYK GA-MOLELE  OVERDYK VILLAGE",
    Latitude: "-23.3046",
    Longitude: "29.1017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15498",
    WardLookupId: "1904",
    VDNumber: "76130401",
    RegisteredPopulation: "291",
    VotingStationName: "MATOMEAMOSIMA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  BOULAST FARM  BOCHUM",
    Latitude: "-23.371",
    Longitude: "29.1171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15499",
    WardLookupId: "1904",
    VDNumber: "76180057",
    RegisteredPopulation: "675",
    VotingStationName: "PHALA HIGH SCHOOL",
    Address: "NO STREET ADDRESS  GA-BROOKMAN  BOCHUM",
    Latitude: "-23.3014",
    Longitude: "29.1625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15500",
    WardLookupId: "1904",
    VDNumber: "76180169",
    RegisteredPopulation: "355",
    VotingStationName: "PHUTHI PRIMARY SCHOOL",
    Address: "N/A  MOLEMOLE  MOKGEHLE VILLAGE",
    Latitude: "-23.3704",
    Longitude: "29.1817",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15501",
    WardLookupId: "1904",
    VDNumber: "76180170",
    RegisteredPopulation: "453",
    VotingStationName: "BRUSSELS NGOAKO SCHOOL",
    Address: "NO STREET ADDRESS  BRUSSELS  BOCHUM",
    Latitude: "-23.3732",
    Longitude: "29.1489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15502",
    WardLookupId: "1904",
    VDNumber: "76180181",
    RegisteredPopulation: "703",
    VotingStationName: "MADIKANA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  DENDRON RHEILAND  DENDRON",
    Latitude: "-23.4092",
    Longitude: "29.1262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15503",
    WardLookupId: "1904",
    VDNumber: "76180192",
    RegisteredPopulation: "231",
    VotingStationName: "RADIBOLOTJE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  LISSA  SESHEGO",
    Latitude: "-23.4017",
    Longitude: "29.164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15504",
    WardLookupId: "1904",
    VDNumber: "76180226",
    RegisteredPopulation: "276",
    VotingStationName: "RASELAELO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  KOEKKOEK  MOLEMOLE [DENDRON/DIKGALE]",
    Latitude: "-23.3322",
    Longitude: "29.1457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15505",
    WardLookupId: "1905",
    VDNumber: "76320648",
    RegisteredPopulation: "705",
    VotingStationName: "CHIKA SENIOR SECONDARY SCHOOL",
    Address: "MABITSELA LEESDALE  MOLETJIE  AGANANG",
    Latitude: "-23.5234",
    Longitude: "29.0732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15506",
    WardLookupId: "1905",
    VDNumber: "76320659",
    RegisteredPopulation: "535",
    VotingStationName: "MMASEHLONG PRIMARY SCHOOL",
    Address: "CRADOCK  MMASEHLONG  AGANANG",
    Latitude: "-23.4894",
    Longitude: "29.1069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15507",
    WardLookupId: "1905",
    VDNumber: "76320693",
    RegisteredPopulation: "656",
    VotingStationName: "PHAUDI PRIMARY SCHOOL",
    Address: "PHAUDI  PIETERSBURG",
    Latitude: "-23.5036",
    Longitude: "29.1503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15508",
    WardLookupId: "1905",
    VDNumber: "76320996",
    RegisteredPopulation: "686",
    VotingStationName: "LETLHOTLHONG PRIMARY SCHOOL",
    Address: "FLORAH/FAIRLOURIE  MOLETJIE  AGANANG",
    Latitude: "-23.5368",
    Longitude: "29.1516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15509",
    WardLookupId: "1905",
    VDNumber: "76321021",
    RegisteredPopulation: "755",
    VotingStationName: "KARABI SENIOR SECONDARY SCHOOL",
    Address: "PHAGO  MOLETJIE  AGANANG",
    Latitude: "-23.567",
    Longitude: "29.1774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15510",
    WardLookupId: "1905",
    VDNumber: "76321526",
    RegisteredPopulation: "423",
    VotingStationName: "BOTSHOLLA SECONDARY SCHOOL",
    Address: "FAIRLORIE VILLAGE  CAPRICORN DISTRICT  MOLETJIE",
    Latitude: "-23.5518",
    Longitude: "29.1471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15511",
    WardLookupId: "1905",
    VDNumber: "76321537",
    RegisteredPopulation: "326",
    VotingStationName: "LEWENG PRIMARY SCHOOL",
    Address: "GA-PHAGO  CAPRICORN   MOLETJIE",
    Latitude: "-23.5606",
    Longitude: "29.1887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15512",
    WardLookupId: "1906",
    VDNumber: "76320121",
    RegisteredPopulation: "610",
    VotingStationName: "WITLIG LOWER  PRIMARY SCHOOL",
    Address: "N/A MOHLAJENG VILLAGE  MOLETJIE WITLIG  AGANANG",
    Latitude: "-23.4514",
    Longitude: "29.1018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15513",
    WardLookupId: "1906",
    VDNumber: "76320323",
    RegisteredPopulation: "315",
    VotingStationName: "GA SAKO CRECHE",
    Address: "GA-SAKO LISA  MOLEMOLE MUNICIPALITY  GA-SAKO",
    Latitude: "-23.4154",
    Longitude: "29.1651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15514",
    WardLookupId: "1906",
    VDNumber: "76320671",
    RegisteredPopulation: "695",
    VotingStationName: "KANANA SCHOOL",
    Address: "N/A KANANA VILLAGE  MOLETJIE  AGANANG",
    Latitude: "-23.4523",
    Longitude: "29.139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15515",
    WardLookupId: "1906",
    VDNumber: "76320705",
    RegisteredPopulation: "601",
    VotingStationName: "NKOANA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  KOLOPO NKOANA  CAPRICORN",
    Latitude: "-23.485",
    Longitude: "29.1875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15516",
    WardLookupId: "1906",
    VDNumber: "76320727",
    RegisteredPopulation: "879",
    VotingStationName: "MAGOSHI PRIMARY SCHOOL",
    Address: "MARIBANA VILLAGE  AGANANG  AGANANG",
    Latitude: "-23.5132",
    Longitude: "29.1989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15517",
    WardLookupId: "1906",
    VDNumber: "76321201",
    RegisteredPopulation: "567",
    VotingStationName: "SEKURUWE COMBINED SCHOOL",
    Address: "SEKURUWE VILLAGE  AGANANG",
    Latitude: "-23.4572",
    Longitude: "29.2128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15518",
    WardLookupId: "1907",
    VDNumber: "76310186",
    RegisteredPopulation: "1219",
    VotingStationName: "MALEMA PRIMARY SCHOOL",
    Address: "NEW LANDS  MASHASHANE  MASHASHANE",
    Latitude: "-23.8446",
    Longitude: "29.2432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15519",
    WardLookupId: "1907",
    VDNumber: "76310197",
    RegisteredPopulation: "831",
    VotingStationName: "D.LUNDS FARMS (PTY)LTD",
    Address: "LUNDS  POLOKWANE",
    Latitude: "-23.9896",
    Longitude: "29.3564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15520",
    WardLookupId: "1907",
    VDNumber: "76310210",
    RegisteredPopulation: "639",
    VotingStationName: "SEBOTSI COMBINED SCHOOL",
    Address: "BERGENEK VILLAGE  LEDWABA  POLOKWANE",
    Latitude: "-24.1838",
    Longitude: "29.3348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15521",
    WardLookupId: "1907",
    VDNumber: "76310221",
    RegisteredPopulation: "1347",
    VotingStationName: "THOKGWANENG PRIMARY SCHOOL",
    Address: "THOKGWANENG   CHUENE  POLOKWANE",
    Latitude: "-24.1855",
    Longitude: "29.4729",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15522",
    WardLookupId: "1907",
    VDNumber: "76310232",
    RegisteredPopulation: "324",
    VotingStationName: "SILOE SCHOOL FOR THE BLIND",
    Address: "THOKGWANENG  CHUENE  POLOKWANE",
    Latitude: "-24.1837",
    Longitude: "29.4635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15523",
    WardLookupId: "1907",
    VDNumber: "76310254",
    RegisteredPopulation: "1292",
    VotingStationName: "KGETSA PRIMARY SCHOOL",
    Address: "NO STREET NAME  MMATOBOLE  POLOKWANE",
    Latitude: "-24.1097",
    Longitude: "29.5758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15524",
    WardLookupId: "1907",
    VDNumber: "76310300",
    RegisteredPopulation: "1519",
    VotingStationName: "MARATAPELO PRIMARY SCHOOL",
    Address: "MARATAPELO   CHUENE  PIETERSBURG",
    Latitude: "-24.207",
    Longitude: "29.4814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15525",
    WardLookupId: "1907",
    VDNumber: "76310333",
    RegisteredPopulation: "715",
    VotingStationName: "THOGOA HIGH SCHOOL",
    Address: "THOGOA  CHUENE  POLOKWANE",
    Latitude: "-24.1845",
    Longitude: "29.4821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15526",
    WardLookupId: "1907",
    VDNumber: "76310489",
    RegisteredPopulation: "1065",
    VotingStationName: "JUJU VALLEY (MIDDELPUNT FARM) TENT",
    Address: "676 MIDDELPUNT FARM  POLOKWANE  POLOKWANE",
    Latitude: "-23.8711",
    Longitude: "29.379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15527",
    WardLookupId: "1908",
    VDNumber: "76300387",
    RegisteredPopulation: "1382",
    VotingStationName: "MOEFADI PRIMARY SCHOOL",
    Address: "GA-THABA   CHUENE  POLOKWANE",
    Latitude: "-24.1131",
    Longitude: "29.6132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15528",
    WardLookupId: "1908",
    VDNumber: "76310029",
    RegisteredPopulation: "498",
    VotingStationName: "TSHEHLO HIGH SCHOOL",
    Address: "GA-CHUENE  CHUENE  POLOKWANE",
    Latitude: "-24.206",
    Longitude: "29.5043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15529",
    WardLookupId: "1908",
    VDNumber: "76310276",
    RegisteredPopulation: "1478",
    VotingStationName: "MAHWIBITSWANE HIGH SCHOOL",
    Address: "MAHWIBITSANE  MAJA  POLOKWANE",
    Latitude: "-24.1382",
    Longitude: "29.5482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15530",
    WardLookupId: "1908",
    VDNumber: "76310287",
    RegisteredPopulation: "1142",
    VotingStationName: "BOKGOBELO LOWER PRIMARY SCHOOL",
    Address: "GA - MAJA  MAJA  MAJA TRIBAL AUTHORITY",
    Latitude: "-24.182",
    Longitude: "29.5381",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15531",
    WardLookupId: "1908",
    VDNumber: "76310298",
    RegisteredPopulation: "781",
    VotingStationName: "MARULANENG BAPTIST CHURCH",
    Address: "MARULANENG VILLAGE  MAJA  POLOKWANE",
    Latitude: "-24.1907",
    Longitude: "29.5085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15532",
    WardLookupId: "1908",
    VDNumber: "76310355",
    RegisteredPopulation: "1041",
    VotingStationName: "FEKE PRIMARY SCHOOL",
    Address: "FEKE  MAJA  POLOKWANE",
    Latitude: "-24.1639",
    Longitude: "29.5546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15533",
    WardLookupId: "1909",
    VDNumber: "76300376",
    RegisteredPopulation: "639",
    VotingStationName: "SEKGWENG PRIMARY SCHOOL",
    Address: "SEKGWENG  MOLEPO  POLOKWANE",
    Latitude: "-24.089",
    Longitude: "29.6747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15534",
    WardLookupId: "1909",
    VDNumber: "76300488",
    RegisteredPopulation: "1478",
    VotingStationName: "MAISHA HIGH SCHOOL",
    Address: "MARIPATHEKONG  MOLEPO  POLOKWANE",
    Latitude: "-24.0411",
    Longitude: "29.793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15535",
    WardLookupId: "1909",
    VDNumber: "76300512",
    RegisteredPopulation: "701",
    VotingStationName: "MOGANO PRIMARY SCHOOL",
    Address: "GA MOGANO  MOLEPO  POLOKWANE",
    Latitude: "-24.0557",
    Longitude: "29.7421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15536",
    WardLookupId: "1909",
    VDNumber: "76300523",
    RegisteredPopulation: "819",
    VotingStationName: "MOTHAPO PRIMARY SCHOOL",
    Address: "MMAROBO  MOLEPO  POLOKWANE",
    Latitude: "-24.0246",
    Longitude: "29.7029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15537",
    WardLookupId: "1909",
    VDNumber: "76300545",
    RegisteredPopulation: "568",
    VotingStationName: "MAMPA PRIMARY SCHOOL",
    Address: "GA LEKGOTHWANE  MOLEPO  POLOKWANE",
    Latitude: "-24.0499",
    Longitude: "29.6771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15538",
    WardLookupId: "1909",
    VDNumber: "76300556",
    RegisteredPopulation: "842",
    VotingStationName: "DIHLOPANENG PRIMARY SCHOOL",
    Address: "DIHLOPANENG  MOLEPO  POLOKWANE",
    Latitude: "-24.0638",
    Longitude: "29.7065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15539",
    WardLookupId: "1909",
    VDNumber: "76300590",
    RegisteredPopulation: "432",
    VotingStationName: "MAKGALAPANE COMBINED SCHOOL",
    Address: "MMAKATSANE  MOLEPO  POLOKWANE",
    Latitude: "-24.0882",
    Longitude: "29.7124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15540",
    WardLookupId: "1909",
    VDNumber: "76300602",
    RegisteredPopulation: "474",
    VotingStationName: "CHAKGA SECONDARY SCHOOL",
    Address: "MMAKATA  MOLEPO  POLOKWANE",
    Latitude: "-24.0842",
    Longitude: "29.6312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15541",
    WardLookupId: "1909",
    VDNumber: "76300657",
    RegisteredPopulation: "228",
    VotingStationName: "MOLEPO PRIMARY SCHOOL",
    Address: "BETHEL  MOLEPO  POLOKWANE",
    Latitude: "-24.0376",
    Longitude: "29.7609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15542",
    WardLookupId: "1909",
    VDNumber: "76300679",
    RegisteredPopulation: "327",
    VotingStationName: "TSHEBELA HIGH SCHOOL",
    Address: "TSHEBELA VILLAGE  MOLEPO  MOLEPO TRIBAL AUTHORITY",
    Latitude: "-24.0328",
    Longitude: "29.74",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15543",
    WardLookupId: "1910",
    VDNumber: "76300455",
    RegisteredPopulation: "1627",
    VotingStationName: "MAKGOKA SENIOR SECONDARY SCHOOL",
    Address: "BOYNE  MORIA  POLOKWANE",
    Latitude: "-23.9473",
    Longitude: "29.7908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15544",
    WardLookupId: "1910",
    VDNumber: "76300466",
    RegisteredPopulation: "907",
    VotingStationName: "MANKGAILE PRIMARY SCHOOL",
    Address: "MANKGAILE  MOLEPO  POLOKWANE",
    Latitude: "-23.9795",
    Longitude: "29.7908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15545",
    WardLookupId: "1910",
    VDNumber: "76300477",
    RegisteredPopulation: "1622",
    VotingStationName: "SEHLALE PRIMARY SCHOOL",
    Address: "SEHLALE  MOLEPO  POLOKWANE",
    Latitude: "-23.9996",
    Longitude: "29.8033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15546",
    WardLookupId: "1910",
    VDNumber: "76300501",
    RegisteredPopulation: "881",
    VotingStationName: "MMAPADI SECONDARY SCHOOL",
    Address: "GA  - RAMPHERI  MOLEPO  POLOKWANE",
    Latitude: "-24.0097",
    Longitude: "29.7528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15547",
    WardLookupId: "1910",
    VDNumber: "76300613",
    RegisteredPopulation: "532",
    VotingStationName: "MAGATOLLE PRIMARY SCHOOL",
    Address: "MAKUBUNG VILLAGE  MOLEPO  POLOKWANE",
    Latitude: "-24.0145",
    Longitude: "29.8124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15548",
    WardLookupId: "1910",
    VDNumber: "76300668",
    RegisteredPopulation: "497",
    VotingStationName: "ST BEDES HIGH SCHOOL",
    Address: "SOBIACCO  MOLEPO  POLOKWANE  ",
    Latitude: "-23.9877",
    Longitude: "29.7544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15549",
    WardLookupId: "1910",
    VDNumber: "76300680",
    RegisteredPopulation: "241",
    VotingStationName: "MOKGOHLOA 2 (TENT)",
    Address: "SOBIACCO  MOLEPO  MOLEPO",
    Latitude: "-23.9653",
    Longitude: "29.7593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15550",
    WardLookupId: "1910",
    VDNumber: "76300848",
    RegisteredPopulation: "191",
    VotingStationName: "MMAWESHI PRIMARY SCHOOL",
    Address: "MAKENG  MANKWENG/BOYNE  MOLEPO TRIBAL",
    Latitude: "-23.9463",
    Longitude: "29.8159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15551",
    WardLookupId: "1911",
    VDNumber: "76300040",
    RegisteredPopulation: "1824",
    VotingStationName: "THOMO PRIMARY SCHOOL",
    Address: "NOBODY  MOTHAPO  POLOKWANE",
    Latitude: "-23.8929",
    Longitude: "29.6647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15552",
    WardLookupId: "1911",
    VDNumber: "76300398",
    RegisteredPopulation: "1197",
    VotingStationName: "LAASTEHOOP PRIMARY SCHOOL",
    Address: "LAASTEHOOP  MOTHAPO  POLOKWANE",
    Latitude: "-23.9801",
    Longitude: "29.6584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15553",
    WardLookupId: "1911",
    VDNumber: "76300411",
    RegisteredPopulation: "1139",
    VotingStationName: "MALATSWA HIGH SCHOOL",
    Address: "LAASTEHOOP  MOLEPO  POLOKWANE",
    Latitude: "-23.9885",
    Longitude: "29.6433",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15554",
    WardLookupId: "1911",
    VDNumber: "76300422",
    RegisteredPopulation: "855",
    VotingStationName: "MAPHEFO PRIMARY SCHOOL",
    Address: "PODILE   MOLEPO  POLOKWANE",
    Latitude: "-24.0103",
    Longitude: "29.6533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15555",
    WardLookupId: "1911",
    VDNumber: "76300534",
    RegisteredPopulation: "919",
    VotingStationName: "MAMODIKELENG PRIMARY SCHOOL",
    Address: "GA-SEBATI  MOLEPO  POLOKWANE",
    Latitude: "-24.0361",
    Longitude: "29.6665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15556",
    WardLookupId: "1911",
    VDNumber: "76300578",
    RegisteredPopulation: "1241",
    VotingStationName: "MOJAPELO COMMUNITY COUNCIL",
    Address: "GA MABOI   MOJAPELO  POLOKWANE",
    Latitude: "-23.9427",
    Longitude: "29.642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15557",
    WardLookupId: "1911",
    VDNumber: "76300859",
    RegisteredPopulation: "606",
    VotingStationName: "THE APOSTOLIC FAITH MISSION CHURCH (NOBODY)",
    Address: "NOBODY RDP HOUSES GA-MOTHAPO  MOTHAPO  POLOKWANE",
    Latitude: "-23.8967",
    Longitude: "29.6741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15558",
    WardLookupId: "1912",
    VDNumber: "76300017",
    RegisteredPopulation: "1374",
    VotingStationName: "PEPPS POLOKWANE PREPARATORY SCHOOL AND COLLEGE",
    Address: "1 PLOT MYNGENOEGEN  DALMADA  POLOKWANE",
    Latitude: "-23.8998",
    Longitude: "29.517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15559",
    WardLookupId: "1912",
    VDNumber: "76300039",
    RegisteredPopulation: "1426",
    VotingStationName: "MAPUDITHOMO PRIMARY SCHOOL",
    Address: "NO STREET NAME  THAKGALANG  POLOKWANE",
    Latitude: "-23.8995",
    Longitude: "29.6591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15560",
    WardLookupId: "1912",
    VDNumber: "76300062",
    RegisteredPopulation: "2509",
    VotingStationName: "MAMAHLO PRIMARY SCHOOL",
    Address: "NO STREET NAME  NOBODY  PIETERSBURG",
    Latitude: "-23.8787",
    Longitude: "29.6657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15561",
    WardLookupId: "1912",
    VDNumber: "76300882",
    RegisteredPopulation: "231",
    VotingStationName: "MAMAHULE TRADITIONAL AUTHORITY",
    Address: "MAMAHULE   POLOKWANE",
    Latitude: "-23.9242",
    Longitude: "29.5888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15562",
    WardLookupId: "1912",
    VDNumber: "76310243",
    RegisteredPopulation: "1152",
    VotingStationName: "NGWANAMAGO PRIMARY SCHOOL",
    Address: "NGWANAMAGO  MAJA  POLOKWANE",
    Latitude: "-24.1001",
    Longitude: "29.5921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15563",
    WardLookupId: "1913",
    VDNumber: "76300365",
    RegisteredPopulation: "1736",
    VotingStationName: "GERSON NTJIE SECONDARY SCHOOL",
    Address: "GA-MAGOOA  MOTHAPO  POLOKWANE",
    Latitude: "-23.9353",
    Longitude: "29.7121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15564",
    WardLookupId: "1913",
    VDNumber: "76300433",
    RegisteredPopulation: "1032",
    VotingStationName: "BAKGAKGA BA-MOTHAPO TRADITIONAL COUNCIL",
    Address: "PHUTI THOLONGWE  MOTHAPO   POLOKWANE",
    Latitude: "-23.9582",
    Longitude: "29.7024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15565",
    WardLookupId: "1913",
    VDNumber: "76300444",
    RegisteredPopulation: "1014",
    VotingStationName: "MAKGOBAKETSE SECONDARY SCHOOL",
    Address: "PHOMOLONG  BAKGAGA BA MOTHAPO  POLOKWANE",
    Latitude: "-23.9496",
    Longitude: "29.7219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15566",
    WardLookupId: "1913",
    VDNumber: "76300567",
    RegisteredPopulation: "1104",
    VotingStationName: "TJATJANENG PRIMARY SCHOOL",
    Address: "TJATJANENG   MOTHAPO  POLOKWANE",
    Latitude: "-23.9686",
    Longitude: "29.6867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15567",
    WardLookupId: "1913",
    VDNumber: "76300691",
    RegisteredPopulation: "406",
    VotingStationName: "PHUTI PRIMARY SCHOOL",
    Address: "PHUTI VILLAGE  BAKGAGA BA MOTHAPO  POLOKWANE",
    Latitude: "-23.9543",
    Longitude: "29.7094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15568",
    WardLookupId: "1913",
    VDNumber: "76300714",
    RegisteredPopulation: "689",
    VotingStationName: "MPONEGELE KE ITIRELE COMMUNITY PROJECT",
    Address:
      "MANTSANA VILLAGE  MANKWENG  BAKGAKGA BA MOTHAPO TRIBAL AUTHOURITY",
    Latitude: "-23.923",
    Longitude: "29.7029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15569",
    WardLookupId: "1913",
    VDNumber: "76300837",
    RegisteredPopulation: "408",
    VotingStationName: "MAMELA CRECHE",
    Address: "GA MOJAPELO  POLOKWANE  MOJAPELO",
    Latitude: "-23.9475",
    Longitude: "29.7276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15570",
    WardLookupId: "1914",
    VDNumber: "76090226",
    RegisteredPopulation: "4549",
    VotingStationName: "GREENSIDE PRIMARY SCHOOL",
    Address: "EXTENSION 44  SESHEGO  POLOKWANE",
    Latitude: "-23.8923",
    Longitude: "29.4178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15571",
    WardLookupId: "1914",
    VDNumber: "76090327",
    RegisteredPopulation: "1567",
    VotingStationName: "LIFE RESTORATION CHURCH",
    Address: "EXTENSION 76  POLOKWANE",
    Latitude: "-23.88",
    Longitude: "29.3972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15572",
    WardLookupId: "1914",
    VDNumber: "76090338",
    RegisteredPopulation: "1104",
    VotingStationName: "EXTENSION 73 (TENT)",
    Address: "EXTENSION 73  SESHEGO  POLOKWANE",
    Latitude: "-23.8739",
    Longitude: "29.4002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15573",
    WardLookupId: "1914",
    VDNumber: "76090349",
    RegisteredPopulation: "2124",
    VotingStationName: "NEW LOOK PRIMARY SCHOOL",
    Address: "EXTENSION 71  POLOKWANE  POLOKWANE",
    Latitude: "-23.8844",
    Longitude: "29.4044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15574",
    WardLookupId: "1915",
    VDNumber: "76310164",
    RegisteredPopulation: "400",
    VotingStationName: "MOTSE MARIA HIGH SCHOOL",
    Address: "DOORINGSTRUIT  DOORINGSPRUIT  POLOKWANE",
    Latitude: "-23.8578",
    Longitude: "29.2067",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15575",
    WardLookupId: "1915",
    VDNumber: "76310175",
    RegisteredPopulation: "716",
    VotingStationName: "MAPANGULA PRIMARY SCHOOL",
    Address: "MAPANGULA  MASHASHANE  POLOKWANE",
    Latitude: "-23.8599",
    Longitude: "29.1815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15576",
    WardLookupId: "1915",
    VDNumber: "76310399",
    RegisteredPopulation: "336",
    VotingStationName: "VAALKOP HOSTEL",
    Address: "N/A  VAALKOP  POLOKWANE",
    Latitude: "-23.8239",
    Longitude: "29.2114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15577",
    WardLookupId: "1915",
    VDNumber: "76310467",
    RegisteredPopulation: "100",
    VotingStationName: "JONAS MANTJIU COMMERCIAL SCHOOL",
    Address: "MAPANGULA  MASHASHANE  MASHASHANE",
    Latitude: "-23.8595",
    Longitude: "29.195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15578",
    WardLookupId: "1915",
    VDNumber: "76320019",
    RegisteredPopulation: "784",
    VotingStationName: "MAKGWARENG PRIMARY SCHOOL",
    Address: "MAMADILA  MASHASHANE  POLOKWANE",
    Latitude: "-23.7919",
    Longitude: "29.2184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15579",
    WardLookupId: "1915",
    VDNumber: "76320569",
    RegisteredPopulation: "1271",
    VotingStationName: "MATOLOKHALO PRIMARY SCHOOL",
    Address: "SENGATANE  MASHASHANE  POLOKWANE",
    Latitude: "-23.8309",
    Longitude: "29.2902",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15580",
    WardLookupId: "1915",
    VDNumber: "76320817",
    RegisteredPopulation: "1643",
    VotingStationName: "KOMAPE MOLAPO PRIMARY SCHOOL",
    Address: "KOMAPE MOLAPO  MOLETJIE  POLOKWANE",
    Latitude: "-23.7484",
    Longitude: "29.2866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15581",
    WardLookupId: "1915",
    VDNumber: "76320895",
    RegisteredPopulation: "1599",
    VotingStationName: "HOSEA NTSOANE HIGHER PRIMARY SCHOOL",
    Address: "CHEBENG  MOLETJIE  POLOKWANE",
    Latitude: "-23.8116",
    Longitude: "29.2728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15582",
    WardLookupId: "1915",
    VDNumber: "76321166",
    RegisteredPopulation: "703",
    VotingStationName: "MANKGAKISHA PRIMARY SCHOOL",
    Address: "GA-MAKWEYA  MOLETJIE  POLOKWANE",
    Latitude: "-23.8115",
    Longitude: "29.259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15583",
    WardLookupId: "1916",
    VDNumber: "76320479",
    RegisteredPopulation: "1339",
    VotingStationName: "MAKGOFE HIGH SCHOOL",
    Address: "MAKGOFE  MOLETJIE  POLOKWANE",
    Latitude: "-23.8079",
    Longitude: "29.3642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15584",
    WardLookupId: "1916",
    VDNumber: "76320491",
    RegisteredPopulation: "1516",
    VotingStationName: "ERNEST MATLOU PRIMARY SCHOOL",
    Address: "BLOODRIVER  MOLETJIE  POLOKWANE",
    Latitude: "-23.8215",
    Longitude: "29.3716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15585",
    WardLookupId: "1916",
    VDNumber: "76320503",
    RegisteredPopulation: "1426",
    VotingStationName: "MOLAUTSI SECONDARY SCHOOL",
    Address: "BLOODRIVER  MOLETJIE  POLOKWANE",
    Latitude: "-23.8156",
    Longitude: "29.3705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15586",
    WardLookupId: "1916",
    VDNumber: "76320514",
    RegisteredPopulation: "1358",
    VotingStationName: "GRACELAND COMBINED SCHOOL",
    Address: "MMOTONG PETER NCHABELENG  MOLETJIE  POLOKWANE",
    Latitude: "-23.8047",
    Longitude: "29.3577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15587",
    WardLookupId: "1916",
    VDNumber: "76321379",
    RegisteredPopulation: "806",
    VotingStationName: "DAIRING EXTENSION SETHOA CRECHE",
    Address: "DAIRING EXTENSION  MOLETJIE  POLOKWANE  ",
    Latitude: "-23.8227",
    Longitude: "29.3577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15588",
    WardLookupId: "1916",
    VDNumber: "76321380",
    RegisteredPopulation: "908",
    VotingStationName: "MASESHA PRIMARY SCHOOL",
    Address: "MASESHA   MOLETJIE TRADITIONAL AUTHORITY  POLOKWANE",
    Latitude: "-23.8267",
    Longitude: "29.3645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15589",
    WardLookupId: "1916",
    VDNumber: "76321403",
    RegisteredPopulation: "477",
    VotingStationName: "CALVARY APOSTOLIC CHURCH",
    Address: "2647 MAKGOFE EXTENSION 2  MOLETJIE  POLOKWANE",
    Latitude: "-23.8172",
    Longitude: "29.3638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15590",
    WardLookupId: "1916",
    VDNumber: "76321481",
    RegisteredPopulation: "945",
    VotingStationName: "BAKONE HALL",
    Address: "KWENA MOLOTO 1  MOLETJIE   POLOKWANE",
    Latitude: "-23.8356",
    Longitude: "29.3474",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15591",
    WardLookupId: "1916",
    VDNumber: "76321616",
    RegisteredPopulation: "612",
    VotingStationName: "KWENA MOLOTO 2 (TENT)",
    Address: "KWENA MOLOTO 2  MOLETJIE  POLOKWANE",
    Latitude: "-23.8298",
    Longitude: "29.3371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15592",
    WardLookupId: "1917",
    VDNumber: "76090013",
    RegisteredPopulation: "1468",
    VotingStationName: "DR A.M.S MAKUNYANE SECONDARY SCHOOL",
    Address: "1071 ZONE 5  SESHEGO  POLOKWANE",
    Latitude: "-23.8444",
    Longitude: "29.36",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15593",
    WardLookupId: "1917",
    VDNumber: "76090080",
    RegisteredPopulation: "3017",
    VotingStationName: "REV M.P MALATJIE PRIMARY SCHOOL",
    Address: "711 ZONE 8  SESHEGO  POLOKWANE",
    Latitude: "-23.8606",
    Longitude: "29.3685",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15594",
    WardLookupId: "1917",
    VDNumber: "76090192",
    RegisteredPopulation: "2779",
    VotingStationName: "ALF MAKALENG PRIMARY SCHOOL",
    Address: "ZONE 5  SESHEGO  POLOKWANE",
    Latitude: "-23.8476",
    Longitude: "29.3525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15595",
    WardLookupId: "1918",
    VDNumber: "76090046",
    RegisteredPopulation: "2896",
    VotingStationName: "KHAISO HIGH SCHOOL",
    Address: "1564 ZONE 3  SESHEGO  POLOKWANE",
    Latitude: "-23.8413",
    Longitude: "29.3846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15596",
    WardLookupId: "1918",
    VDNumber: "76090079",
    RegisteredPopulation: "2641",
    VotingStationName: "REV M.E MAKGATHO HIGH SCHOOL",
    Address: "625 ZONE 4  SESHEGO  POLOKWANE",
    Latitude: "-23.846",
    Longitude: "29.3772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15597",
    WardLookupId: "1919",
    VDNumber: "76090091",
    RegisteredPopulation: "2788",
    VotingStationName: "MPONEGELE PRIMARY SCHOOL",
    Address: "1927 ZONE 1  SESHEGO  POLOKWANE",
    Latitude: "-23.8644",
    Longitude: "29.3926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15598",
    WardLookupId: "1919",
    VDNumber: "76090103",
    RegisteredPopulation: "3022",
    VotingStationName: "MOHLAKANENG HIGH SCHOOL",
    Address: "1936 ZONE 1  SESHEGO  POLOKWANE",
    Latitude: "-23.8581",
    Longitude: "29.3897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15599",
    WardLookupId: "1919",
    VDNumber: "76090305",
    RegisteredPopulation: "1160",
    VotingStationName: "PHISHEGO JUNIOR PRIMARY SCHOOL",
    Address: "14TH SESHEGO  SESHEGO  POLOKWANE",
    Latitude: "-23.8638",
    Longitude: "29.389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15600",
    WardLookupId: "1920",
    VDNumber: "76090237",
    RegisteredPopulation: "1303",
    VotingStationName: "EMDO (TENT)",
    Address: "ACACIA PARK  EMDO  POLOKWANE",
    Latitude: "-23.8684",
    Longitude: "29.4287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15601",
    WardLookupId: "1920",
    VDNumber: "76090248",
    RegisteredPopulation: "2212",
    VotingStationName: "LUTHULI PARK COMBINED SCHOOL",
    Address: "LUTHULI PARK  SESHEGO  POLOKWANE",
    Latitude: "-23.8548",
    Longitude: "29.4144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15602",
    WardLookupId: "1920",
    VDNumber: "76090293",
    RegisteredPopulation: "1397",
    VotingStationName: "MASEALA PROGRESSIVE HIGH SCHOOL",
    Address: "LEGAE LA BATHO  SESHEGO  POLOKWANE",
    Latitude: "-23.873",
    Longitude: "29.4102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15603",
    WardLookupId: "1920",
    VDNumber: "76090316",
    RegisteredPopulation: "894",
    VotingStationName: "LESEDI DAY CARE",
    Address: "9A LUTHULI PARK  SESHEGO  POLOKWANE",
    Latitude: "-23.8458",
    Longitude: "29.4043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15604",
    WardLookupId: "1920",
    VDNumber: "76090372",
    RegisteredPopulation: "641",
    VotingStationName: "MAHLASEDI PARK (TENT)",
    Address: "MAHLASEDI  MAHLASEDI PARK  POLOKWANE",
    Latitude: "-23.8705",
    Longitude: "29.4233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15605",
    WardLookupId: "1920",
    VDNumber: "76090383",
    RegisteredPopulation: "1657",
    VotingStationName: "LUTHULI (TENT)",
    Address: "LUTHULI   SESHEGO",
    Latitude: "-23.8503",
    Longitude: "29.415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15606",
    WardLookupId: "1920",
    VDNumber: "76090394",
    RegisteredPopulation: "705",
    VotingStationName: "LESEDI PARK (TENT)",
    Address: "LESEDI PARK  POLOKWANE",
    Latitude: "-23.8655",
    Longitude: "29.4245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15607",
    WardLookupId: "1921",
    VDNumber: "76320086",
    RegisteredPopulation: "1559",
    VotingStationName: "CHOKWE PRIMARY SCHOOL",
    Address: "GA-CHOKOE  MOLETJIE  POLOKWANE",
    Latitude: "-23.6282",
    Longitude: "29.3186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15608",
    WardLookupId: "1921",
    VDNumber: "76320334",
    RegisteredPopulation: "936",
    VotingStationName: "SEHLAGANE PRIMARY SCHOOL",
    Address: "GA-MATAMANYANE  MOLETJIE  POLOKWANE",
    Latitude: "-23.67",
    Longitude: "29.3465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15609",
    WardLookupId: "1921",
    VDNumber: "76320682",
    RegisteredPopulation: "630",
    VotingStationName: "HLABANA PRIMARY SCHOOL",
    Address: "MADIHORONG/MASHITA  MOLETJIE  POLOKWANE",
    Latitude: "-23.6258",
    Longitude: "29.2833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15610",
    WardLookupId: "1921",
    VDNumber: "76320716",
    RegisteredPopulation: "817",
    VotingStationName: "KAPUTLA NKOANA PRIMARY SCHOOL",
    Address: "MASHOBOHLENG  MOLETJIE  POLOKWANE",
    Latitude: "-23.6592",
    Longitude: "29.3248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15611",
    WardLookupId: "1921",
    VDNumber: "76320749",
    RegisteredPopulation: "899",
    VotingStationName: "MOHWIBIDU PRIMARY SCHOOL",
    Address: "GA-SETATI  MOLETJIE  POLOKWANE",
    Latitude: "-23.6471",
    Longitude: "29.3531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15612",
    WardLookupId: "1921",
    VDNumber: "76320952",
    RegisteredPopulation: "974",
    VotingStationName: "MORUPAHALE PRIMARY SCHOOL",
    Address: "GA MABITSELA  MOLETJIE  POLOKWANE",
    Latitude: "-23.6806",
    Longitude: "29.3284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15613",
    WardLookupId: "1921",
    VDNumber: "76321267",
    RegisteredPopulation: "780",
    VotingStationName: "PELA-BELA PRIMARY SCHOOL",
    Address: "GA-MASHAMAITE  MOLETJIE  POLOKWANE",
    Latitude: "-23.5802",
    Longitude: "29.2791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15614",
    WardLookupId: "1921",
    VDNumber: "76321414",
    RegisteredPopulation: "322",
    VotingStationName: "KOROSO PRIMARY SCHOOL",
    Address: "GA-KOBO  MOLETJIE  POLOKWANE",
    Latitude: "-23.6327",
    Longitude: "29.3038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15615",
    WardLookupId: "1921",
    VDNumber: "76321605",
    RegisteredPopulation: "398",
    VotingStationName: "KGOLOKGOTHA APOSTOLIC CHURCH",
    Address: "GA MATAMANYANE  MOLETJIE  POLOKWANE",
    Latitude: "-23.6658",
    Longitude: "29.3472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15616",
    WardLookupId: "1922",
    VDNumber: "76320367",
    RegisteredPopulation: "1041",
    VotingStationName: "MONYONG HIGH SCHOOL",
    Address: "GA-MAKGAKGA  MOLETJIE   POLOKWANE",
    Latitude: "-23.7901",
    Longitude: "29.3639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15617",
    WardLookupId: "1922",
    VDNumber: "76320413",
    RegisteredPopulation: "1656",
    VotingStationName: "OR MABOTJA HIGH SCHOOL",
    Address: "MAKGOFE  MOLETJIE  POLOKWANE",
    Latitude: "-23.8023",
    Longitude: "29.369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15618",
    WardLookupId: "1922",
    VDNumber: "76320480",
    RegisteredPopulation: "1162",
    VotingStationName: "APRIL MAKGAKGA PRIMARY SCHOOL",
    Address: "MAKGOFE  MOLETJIE  POLOKWANE",
    Latitude: "-23.8005",
    Longitude: "29.3614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15619",
    WardLookupId: "1922",
    VDNumber: "76320536",
    RegisteredPopulation: "1301",
    VotingStationName: "MOCHOCHO PRIMARY SCHOOL",
    Address: "GA MABOTJA  MOLETJIE  POLOKWANE",
    Latitude: "-23.8094",
    Longitude: "29.3433",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15620",
    WardLookupId: "1922",
    VDNumber: "76320547",
    RegisteredPopulation: "854",
    VotingStationName: "NAPE TLADI PRIMARY SCHOOL",
    Address: "MOKGOKONG  MOLETJIE  POLOKWANE",
    Latitude: "-23.8044",
    Longitude: "29.3347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15621",
    WardLookupId: "1922",
    VDNumber: "76320558",
    RegisteredPopulation: "1410",
    VotingStationName: "MATSIRI MOKGOKONG PRIMARY SCHOOL",
    Address: "GA-MOKGOKONG  MOLETJIE  POLOKWANE",
    Latitude: "-23.8173",
    Longitude: "29.3297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15622",
    WardLookupId: "1922",
    VDNumber: "76321425",
    RegisteredPopulation: "457",
    VotingStationName: "MOTINTI (TENT)",
    Address: "MOTINTI   MOLETJIE  POLOKWANE",
    Latitude: "-23.7767",
    Longitude: "29.3379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15623",
    WardLookupId: "1922",
    VDNumber: "76321436",
    RegisteredPopulation: "256",
    VotingStationName: "GREEN PASTURES COMBINED CHRISTIAN SCHOOL",
    Address: "GA MABOTJA-MAKGAO PARK  MOLETJIE  POLOKWANE",
    Latitude: "-23.8165",
    Longitude: "29.3475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15624",
    WardLookupId: "1923",
    VDNumber: "76090057",
    RegisteredPopulation: "2483",
    VotingStationName: "MASEDIBU HIGH SCHOOL",
    Address: "3835 ZONE 2  SESHEGO  PIETERSBURG",
    Latitude: "-23.8478",
    Longitude: "29.3921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15625",
    WardLookupId: "1923",
    VDNumber: "76090068",
    RegisteredPopulation: "2246",
    VotingStationName: "KWENA MOLOTO COLLEGE(MASTEC)",
    Address: "3838 ZONE 2  SESHEGO  POLOKWANE",
    Latitude: "-23.8501",
    Longitude: "29.3882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15626",
    WardLookupId: "1923",
    VDNumber: "76090259",
    RegisteredPopulation: "1657",
    VotingStationName: "MILLENNIUM COMBINED SCHOOL",
    Address: "MADIBA STREET  MADIBA PARK  POLOKWANE",
    Latitude: "-23.8659",
    Longitude: "29.4055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15627",
    WardLookupId: "1924",
    VDNumber: "76320301",
    RegisteredPopulation: "521",
    VotingStationName: "BAILAFURI PRIMARY SCHOOL",
    Address: "MATHATHO  MOLETJIE  POLOKWANE",
    Latitude: "-23.695",
    Longitude: "29.3087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15628",
    WardLookupId: "1924",
    VDNumber: "76320761",
    RegisteredPopulation: "763",
    VotingStationName: "MOLETJIE TRADITIONAL AUTHORITY",
    Address: "MOLETJIE MOSHATE  MOLETJIE/CHIEFS KRAAL  POLOKWANE",
    Latitude: "-23.7317",
    Longitude: "29.3033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15629",
    WardLookupId: "1924",
    VDNumber: "76320772",
    RegisteredPopulation: "241",
    VotingStationName: "MOLOTO SENIOR PRIMARY SCHOOL",
    Address: "MOLOTO  MOLETJIE  POLOKWANE",
    Latitude: "-23.7362",
    Longitude: "29.3031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15630",
    WardLookupId: "1924",
    VDNumber: "76320794",
    RegisteredPopulation: "1307",
    VotingStationName: "SESHIGO HIGH SCHOOL",
    Address: "SESHIGO HIGH  MOLETJIE  POLOKWANE",
    Latitude: "-23.7633",
    Longitude: "29.3078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15631",
    WardLookupId: "1924",
    VDNumber: "76320806",
    RegisteredPopulation: "1772",
    VotingStationName: "MOKGOBA JUNIOR PRIMARY SCHOOL",
    Address: "GA-NGOASHENG  MOLETJIE  POLOKWANE",
    Latitude: "-23.7452",
    Longitude: "29.3036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15632",
    WardLookupId: "1924",
    VDNumber: "76320828",
    RegisteredPopulation: "684",
    VotingStationName: "MOSHASHA PRIMARY SCHOOL",
    Address: "GA-KOMAPE   MOLETJIE  POLOKWANE",
    Latitude: "-23.7272",
    Longitude: "29.2911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15633",
    WardLookupId: "1924",
    VDNumber: "76321447",
    RegisteredPopulation: "558",
    VotingStationName: "EARLY CHILDWOOD DEV ELOPMENT CENTRE",
    Address: "LEOKAMA VILLAGE   MOLETJIE  MOLETJIE TRIBAL AUTHORITY",
    Latitude: "-23.7769",
    Longitude: "29.3131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15634",
    WardLookupId: "1924",
    VDNumber: "76321458",
    RegisteredPopulation: "429",
    VotingStationName: "ASSEMBLIES OF GOD CHURCH",
    Address: "SETLOGONG  MOLETJIE  MOLETJIE TRIBAL AUTHORITY",
    Latitude: "-23.7551",
    Longitude: "29.3121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15635",
    WardLookupId: "1924",
    VDNumber: "76321469",
    RegisteredPopulation: "135",
    VotingStationName: "MASEDIBU LUTHERAN CHURCH",
    Address: "MASEDIBU VILLAGE  MOLETJIE  MOLETJIE TRIBAL AUTHORITY",
    Latitude: "-23.7166",
    Longitude: "29.2805",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15636",
    WardLookupId: "1924",
    VDNumber: "76321470",
    RegisteredPopulation: "520",
    VotingStationName: "MABOKELELE PRIMARY SCHOOL",
    Address: "MABOKELELE  MOLETJIE  MOLETJIE TRIBAL AUTHORITY",
    Latitude: "-23.7558",
    Longitude: "29.2971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15637",
    WardLookupId: "1924",
    VDNumber: "76321627",
    RegisteredPopulation: "379",
    VotingStationName: "MOLALAHARE PRIMARY SCHOOL",
    Address: "GA RAPUDI  MOLETJIE  POLOKWANE",
    Latitude: "-23.7241",
    Longitude: "29.3088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15638",
    WardLookupId: "1924",
    VDNumber: "76321638",
    RegisteredPopulation: "319",
    VotingStationName: "MMASERA CRECHE AND PRE-SCHOOL",
    Address: "MADIKOTI  MOLETJIE  MOLETJIE TRIBAL",
    Latitude: "-23.7386",
    Longitude: "29.3153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15639",
    WardLookupId: "1924",
    VDNumber: "76321649",
    RegisteredPopulation: "144",
    VotingStationName: "MMAKGABO SEN.SECONDARY SCHOOL",
    Address: "GA-MATLOU MASHASHE  MOLETJIE  MOLETJIE TRADITIONAL AUTHORITY",
    Latitude: "-23.7139",
    Longitude: "29.315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15640",
    WardLookupId: "1925",
    VDNumber: "76090147",
    RegisteredPopulation: "4061",
    VotingStationName: "WESTENBURG SECONDARY SCHOOL",
    Address: "75 DAN HARRIS STR  WESTENBURG  POLOKWANE",
    Latitude: "-23.9081",
    Longitude: "29.4296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15641",
    WardLookupId: "1925",
    VDNumber: "76090158",
    RegisteredPopulation: "2791",
    VotingStationName: "TAXILA SECONDARY SCHOOL",
    Address: "347 347 ORIENT DRIVE  NIRVANA  POLOKWANE",
    Latitude: "-23.9152",
    Longitude: "29.4352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15642",
    WardLookupId: "1926",
    VDNumber: "76090170",
    RegisteredPopulation: "4990",
    VotingStationName: "FLORAPARK COMPREHENSIVE PRIMARY SCHOOL",
    Address: "JANSMYN EN VON MARLE  PIETERSBURG  POLOKWANE",
    Latitude: "-23.9145",
    Longitude: "29.4962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15643",
    WardLookupId: "1926",
    VDNumber: "76090260",
    RegisteredPopulation: "1632",
    VotingStationName: "WATERLAND",
    Address: "01 LANDROSE MARE  BENINA PARK  POLOKWANE [PIETERSBURG]",
    Latitude: "-23.9222",
    Longitude: "29.4538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15644",
    WardLookupId: "1927",
    VDNumber: "76090125",
    RegisteredPopulation: "6064",
    VotingStationName: "LAERSKOOL PIETERSBURG OOS",
    Address: "KLEINENBERGSTRAAT 102  POLOKWANE  POLOKWANE",
    Latitude: "-23.8991",
    Longitude: "29.4774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15645",
    WardLookupId: "1928",
    VDNumber: "76090169",
    RegisteredPopulation: "3684",
    VotingStationName: "FLORAPARK COMPREHENSIVE HIGH SCHOOL",
    Address: "ERASMUS  PIETERSBURG  PIETERSBURG",
    Latitude: "-23.9165",
    Longitude: "29.4813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15646",
    WardLookupId: "1928",
    VDNumber: "76090181",
    RegisteredPopulation: "3543",
    VotingStationName: "LAERSKOOL IVY PARK",
    Address: "SMITSWEG  IVY PARK  POLOKWANE",
    Latitude: "-23.9254",
    Longitude: "29.4406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15647",
    WardLookupId: "1929",
    VDNumber: "76090114",
    RegisteredPopulation: "3879",
    VotingStationName: "LAERSKOOL PIET HUGO",
    Address: "VERMIKULIET STREET  LADANNA  POLOKWANE",
    Latitude: "-23.8749",
    Longitude: "29.4492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15648",
    WardLookupId: "1929",
    VDNumber: "76090204",
    RegisteredPopulation: "1078",
    VotingStationName: "DREAM VALLEY ACCOMODATION",
    Address: "N1 ROAD  POLOKWANE  POLOKWANE",
    Latitude: "-23.8319",
    Longitude: "29.4905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15649",
    WardLookupId: "1929",
    VDNumber: "76090282",
    RegisteredPopulation: "2384",
    VotingStationName: "FULL GOSPEL CHURCH - BET SHALOM",
    Address: "C/O DE WET STREET &OUTSPAN AVE  BENDOR  POLOKWANE  ",
    Latitude: "-23.8835",
    Longitude: "29.485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15650",
    WardLookupId: "1929",
    VDNumber: "76090350",
    RegisteredPopulation: "1308",
    VotingStationName: "MITCHELL HOUSE SCHOOL",
    Address: "GA-MITCHELLE TO BOLOVIA LODGE  POLOKWANE  POLOKWANE",
    Latitude: "-23.8687",
    Longitude: "29.5093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15651",
    WardLookupId: "1930",
    VDNumber: "76190452",
    RegisteredPopulation: "1925",
    VotingStationName: "MANANGA JUNIOR PRIMARY SCHOOL",
    Address: "BETHANIA SECTION, MAKOTOPONG  MAKOTOPONG  POLOKWANE",
    Latitude: "-23.819",
    Longitude: "29.6405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15652",
    WardLookupId: "1930",
    VDNumber: "76190474",
    RegisteredPopulation: "1252",
    VotingStationName: "MAKOTOPONG GENERAL CONSTRUCTION HARDWARE",
    Address: "MAKOTOPONG  MANKWENG  MAKOTOPONG",
    Latitude: "-23.8027",
    Longitude: "29.6412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15653",
    WardLookupId: "1930",
    VDNumber: "76190564",
    RegisteredPopulation: "552",
    VotingStationName: "ITIRELENG PRIMARY SCHOOL",
    Address: "N/A  KOTISHING VILLAGE  POLOKWANE",
    Latitude: "-23.8094",
    Longitude: "29.6134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15654",
    WardLookupId: "1930",
    VDNumber: "76190643",
    RegisteredPopulation: "851",
    VotingStationName: "PHOMOLONG SENIOR SECONDARY SCHOOL",
    Address: "MAKOTOPONG  POLOKWANE",
    Latitude: "-23.8237",
    Longitude: "29.6492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15655",
    WardLookupId: "1930",
    VDNumber: "76300028",
    RegisteredPopulation: "1268",
    VotingStationName: "MOTHIBA PRIMARY SCHOOL",
    Address: "NO STREET NAME  GA-MOTHIBA  PIETERSBURG",
    Latitude: "-23.8605",
    Longitude: "29.6429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15656",
    WardLookupId: "1930",
    VDNumber: "76300051",
    RegisteredPopulation: "1001",
    VotingStationName: "NGWANALAKA HIGH SCHOOL",
    Address: "NO STREET NAME  NGWANALAKA GA MOTHIBA  PIETERSBURG",
    Latitude: "-23.8761",
    Longitude: "29.6519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15657",
    WardLookupId: "1930",
    VDNumber: "76300860",
    RegisteredPopulation: "352",
    VotingStationName: "RAMATHOPE SEN.SECONDARY SCHOOL",
    Address: "NOBODY GA-MOTHIBA  MOTHIBA  MOTHIBA",
    Latitude: "-23.8688",
    Longitude: "29.6691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15658",
    WardLookupId: "1931",
    VDNumber: "76300196",
    RegisteredPopulation: "1333",
    VotingStationName: "PULA-MADIBOGO PRIMARY SCHOOL",
    Address: "MANKWENG TOWN  PIETERSBURG",
    Latitude: "-23.8854",
    Longitude: "29.7241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15659",
    WardLookupId: "1931",
    VDNumber: "76300208",
    RegisteredPopulation: "2509",
    VotingStationName: "TORONTO PRIMARY SCHOOL",
    Address: "B MANKWENG  PIETERSBURG",
    Latitude: "-23.8874",
    Longitude: "29.7154",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15660",
    WardLookupId: "1931",
    VDNumber: "76300219",
    RegisteredPopulation: "3516",
    VotingStationName: "DITLALEMESO HIGH SCHOOL",
    Address: "NO STREET NAME  MANKWENG UNIT C  POLOKWANE",
    Latitude: "-23.8817",
    Longitude: "29.7023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15661",
    WardLookupId: "1931",
    VDNumber: "76300725",
    RegisteredPopulation: "795",
    VotingStationName: "MORITING PRIMARY SCHOOL",
    Address: "MANKWENG  MANKWENG UNIT C",
    Latitude: "-23.874",
    Longitude: "29.7035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15662",
    WardLookupId: "1932",
    VDNumber: "76300185",
    RegisteredPopulation: "2261",
    VotingStationName: "HWITI HIGH SCHOOL",
    Address: "MANKWENG TOWN  ",
    Latitude: "-23.8902",
    Longitude: "29.7291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15663",
    WardLookupId: "1932",
    VDNumber: "76300589",
    RegisteredPopulation: "5681",
    VotingStationName: "UNIVERSITY OF LIMPOPO",
    Address: "NONE  MANKWENG  POLOKWANE",
    Latitude: "-23.8853",
    Longitude: "29.7342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15664",
    WardLookupId: "1933",
    VDNumber: "76300275",
    RegisteredPopulation: "3841",
    VotingStationName: "KLAAS MOTHAPO SECONDARY SCHOOL",
    Address: "NO STREET NAME  GA-THOKA  PIETERSBURG/MANKWENG",
    Latitude: "-23.902",
    Longitude: "29.7059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15665",
    WardLookupId: "1933",
    VDNumber: "76300286",
    RegisteredPopulation: "1727",
    VotingStationName: "DIOPONG PRIMARY SCHOOL",
    Address: "MAKANYE  PIETERSBURG",
    Latitude: "-23.8992",
    Longitude: "29.7238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15666",
    WardLookupId: "1933",
    VDNumber: "76300297",
    RegisteredPopulation: "2114",
    VotingStationName: "MAKANYE PRIMARY SCHOOL",
    Address: "NO STREET NAME  MAKANYE  POLOKWANE",
    Latitude: "-23.907",
    Longitude: "29.7384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15667",
    WardLookupId: "1933",
    VDNumber: "76300871",
    RegisteredPopulation: "355",
    VotingStationName: "MOGALADI DAYCARE CENTRE",
    Address: "PALEDI  GA-MOTHAPO  GA-MOTHAPO",
    Latitude: "-23.8909",
    Longitude: "29.6959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15668",
    WardLookupId: "1934",
    VDNumber: "76240302",
    RegisteredPopulation: "1002",
    VotingStationName: "KATANE PRIMARY SCHOOL",
    Address: "SEGWASHI  SEGWESHI (GA MAMABOLO)  PIETERSBURG",
    Latitude: "-23.9081",
    Longitude: "29.8756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15669",
    WardLookupId: "1934",
    VDNumber: "76300321",
    RegisteredPopulation: "2353",
    VotingStationName: "MEGORING PRIMARY SCHOOL",
    Address:
      "NO STREET NAME  MENTZ VILLAGE GA SHILOANE SECTION  CAPRICON DISTRICT",
    Latitude: "-23.9093",
    Longitude: "29.7663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15670",
    WardLookupId: "1934",
    VDNumber: "76300332",
    RegisteredPopulation: "1402",
    VotingStationName: "MAKGEFOLA PRIMARY SCHOOL",
    Address: "NO STREET NAME  MATSHELAPATA MENTZ  PIETERSBURG",
    Latitude: "-23.9209",
    Longitude: "29.7784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15671",
    WardLookupId: "1934",
    VDNumber: "76300354",
    RegisteredPopulation: "886",
    VotingStationName: "MAHLANHLE PRIMARY SCHOOL",
    Address: "NO STREET NAME  MAHLANHLE GA MAMABOLO  CAPRICON DISTRICT",
    Latitude: "-23.9042",
    Longitude: "29.8087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15672",
    WardLookupId: "1934",
    VDNumber: "76300736",
    RegisteredPopulation: "708",
    VotingStationName: "DIKELEDI GENERAL DEALER TENT",
    Address: "MENTZ  MSHONGO  MANKWENG  GA-MOTHAPO",
    Latitude: "-23.9134",
    Longitude: "29.7564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15673",
    WardLookupId: "1934",
    VDNumber: "76300781",
    RegisteredPopulation: "454",
    VotingStationName: "MAKGONGOANA HIGH SCHOOL",
    Address: "MAKANYE  POLOKWANE  MOLEPO",
    Latitude: "-23.9042",
    Longitude: "29.7444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15674",
    WardLookupId: "1935",
    VDNumber: "76190373",
    RegisteredPopulation: "880",
    VotingStationName: "DIKGALE TRADITIONAL COUNCIL",
    Address: "DIKGALE  DIKGOPHENG  PIETERSBURG",
    Latitude: "-23.7619",
    Longitude: "29.791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15675",
    WardLookupId: "1935",
    VDNumber: "76190441",
    RegisteredPopulation: "1433",
    VotingStationName: "MOSEBO PRIMARY SCHOOL",
    Address: "NO STREET NAME  MADIGA GA DIKGALE  CAPRICON DISTRICT",
    Latitude: "-23.8072",
    Longitude: "29.7218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15676",
    WardLookupId: "1935",
    VDNumber: "76240290",
    RegisteredPopulation: "523",
    VotingStationName: "MAKGOBA TRADITIONAL COUNCIL",
    Address: "MAKGOBA  HAENERTSBURG  POLOKWANE",
    Latitude: "-23.733",
    Longitude: "29.8257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15677",
    WardLookupId: "1935",
    VDNumber: "76300084",
    RegisteredPopulation: "325",
    VotingStationName: "MODIBONE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  KGWARENG SECTION SEGOPJE  PIETERSBURG",
    Latitude: "-23.7682",
    Longitude: "29.7916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15678",
    WardLookupId: "1935",
    VDNumber: "76300107",
    RegisteredPopulation: "1264",
    VotingStationName: "BJATLADI TRADITIONAL COUNCIL",
    Address: "N/A  SEGOPYE  PIETERSBURG",
    Latitude: "-23.8065",
    Longitude: "29.7971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15679",
    WardLookupId: "1935",
    VDNumber: "76300624",
    RegisteredPopulation: "495",
    VotingStationName: "KGOTLHO PRIMARY SCHOOL",
    Address: "MODUWANE GA DIKGALE  POLOKWANE [PIETERSBURG]",
    Latitude: "-23.8024",
    Longitude: "29.7579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15680",
    WardLookupId: "1935",
    VDNumber: "76300758",
    RegisteredPopulation: "560",
    VotingStationName: "MMAPHOTLA PRIMARY SCHOOL",
    Address: "GA-POTSE  POLOKWANE  POLOKWANE",
    Latitude: "-23.823",
    Longitude: "29.7891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15681",
    WardLookupId: "1935",
    VDNumber: "76300792",
    RegisteredPopulation: "386",
    VotingStationName: "SESOAI PRIMARY SCHOOL",
    Address: "BJATLADI  ",
    Latitude: "-23.8059",
    Longitude: "29.8005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15682",
    WardLookupId: "1935",
    VDNumber: "76300804",
    RegisteredPopulation: "328",
    VotingStationName: "BJATLADI SENIOR SECONDARY SCHOOL",
    Address: "GA MAMABOLO  BJATLADI  POLOKWANE RURAL",
    Latitude: "-23.8115",
    Longitude: "29.8012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15683",
    WardLookupId: "1936",
    VDNumber: "76240380",
    RegisteredPopulation: "311",
    VotingStationName: "MPHOME PRIMARY SCHOOL",
    Address: "N/A  MMASEALAMA GA-MMAMABOLO  POLOKWANE",
    Latitude: "-23.8278",
    Longitude: "29.8852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15684",
    WardLookupId: "1936",
    VDNumber: "76240391",
    RegisteredPopulation: "415",
    VotingStationName: "ROBERT MACHAKA SHOP",
    Address: "GA  - KGWARA  MANKWENG  MAMABOLO TRIBAL AUTHORITY",
    Latitude: "-23.8479",
    Longitude: "29.8545",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15685",
    WardLookupId: "1936",
    VDNumber: "76300095",
    RegisteredPopulation: "1013",
    VotingStationName: "MARIBE PRIMARY SCHOOL",
    Address: "NO STREET NAME  VAN VUUR  PIETERSBURG",
    Latitude: "-23.7965",
    Longitude: "29.8208",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15686",
    WardLookupId: "1936",
    VDNumber: "76300130",
    RegisteredPopulation: "1003",
    VotingStationName: "SEOLWANA SENIOR SECONDARY SCHOOL",
    Address: "NO STREET NAME  GA-MAILULA  PIETERSBURG",
    Latitude: "-23.8339",
    Longitude: "29.8395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15687",
    WardLookupId: "1936",
    VDNumber: "76300141",
    RegisteredPopulation: "1485",
    VotingStationName: "DOASHO HIGH SCHOOL",
    Address: "NO STREET NAME  GA-MAREDI  PIETERSBURG",
    Latitude: "-23.8548",
    Longitude: "29.8039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15688",
    WardLookupId: "1936",
    VDNumber: "76300152",
    RegisteredPopulation: "1579",
    VotingStationName: "MAKEKETELA PRIMARY SCHOOL",
    Address: "NO STREET NAME  GA KAMA  PIETERSBURG",
    Latitude: "-23.8667",
    Longitude: "29.765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15689",
    WardLookupId: "1936",
    VDNumber: "76300220",
    RegisteredPopulation: "518",
    VotingStationName: "MANKWENG TRADITIONAL COUNCIL",
    Address: "N/A  LESHOANE  PIETERSBURG",
    Latitude: "-23.8587",
    Longitude: "29.8408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15690",
    WardLookupId: "1936",
    VDNumber: "76300815",
    RegisteredPopulation: "335",
    VotingStationName: "MASEKWAMENG PRIMARY SCHOOL",
    Address: "GA MAMABOLO  POLOKWANE  MAMABOLO",
    Latitude: "-23.8437",
    Longitude: "29.8098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15691",
    WardLookupId: "1936",
    VDNumber: "76300826",
    RegisteredPopulation: "310",
    VotingStationName: "LESHOANE PRIMARY SCHOOL",
    Address: "LESHWANE  POLOKWANE  MAMABOLO",
    Latitude: "-23.8675",
    Longitude: "29.8028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15692",
    WardLookupId: "1937",
    VDNumber: "76300073",
    RegisteredPopulation: "1058",
    VotingStationName: "DIKWATA PRIMARY SCHOOL",
    Address: "MCHICHANE  PIETERSBURG",
    Latitude: "-23.8609",
    Longitude: "29.6886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15693",
    WardLookupId: "1937",
    VDNumber: "76300129",
    RegisteredPopulation: "415",
    VotingStationName: "KGOKONG PRIMARY SCHOOL",
    Address: "KGOKONG GA MMABOLO  PIETERSBURG",
    Latitude: "-23.8389",
    Longitude: "29.7776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15694",
    WardLookupId: "1937",
    VDNumber: "76300163",
    RegisteredPopulation: "1325",
    VotingStationName: "MOTHOLO PRIMARY SCHOOL",
    Address: "NO STREET NAME  GA-MOTHOLO  PIETERSBURG",
    Latitude: "-23.8724",
    Longitude: "29.7442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15695",
    WardLookupId: "1937",
    VDNumber: "76300174",
    RegisteredPopulation: "1295",
    VotingStationName: "MAMABUDUSHA SECONDARY SCHOOL",
    Address: "NO STREET NAME  MAMABUSHA  PIETERSBURG/MANKWENG",
    Latitude: "-23.8707",
    Longitude: "29.7378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15696",
    WardLookupId: "1937",
    VDNumber: "76300309",
    RegisteredPopulation: "1443",
    VotingStationName: "MALESA PRIMARY SCHOOL",
    Address: "NO STREET NAME  MALESA  PIETERSBURG",
    Latitude: "-23.9017",
    Longitude: "29.7637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15697",
    WardLookupId: "1937",
    VDNumber: "76300646",
    RegisteredPopulation: "913",
    VotingStationName: "FRANS MOHLALA HIGH SCHOOL",
    Address: "HLATLAGANYA  BJATLADI  POLOKWANE",
    Latitude: "-23.862",
    Longitude: "29.7158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15698",
    WardLookupId: "1937",
    VDNumber: "76300747",
    RegisteredPopulation: "279",
    VotingStationName: "KOKONADIKGALE PRIMARY SCHOOL",
    Address: "MPHALONG  MANKWENG  MPHALONG",
    Latitude: "-23.8298",
    Longitude: "29.7537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15699",
    WardLookupId: "1937",
    VDNumber: "76300769",
    RegisteredPopulation: "664",
    VotingStationName: "MATSIA IRAQ CRECHE",
    Address: "IRAQ  POLOKWANE  POLOKWANE",
    Latitude: "-23.8803",
    Longitude: "29.7492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15700",
    WardLookupId: "1938",
    VDNumber: "76190339",
    RegisteredPopulation: "1512",
    VotingStationName: "SOLOMONDALE PRIMARY SCHOOL",
    Address: "SOLOMONDALE  PIETERSBURG",
    Latitude: "-23.7748",
    Longitude: "29.6891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15701",
    WardLookupId: "1938",
    VDNumber: "76190407",
    RegisteredPopulation: "1091",
    VotingStationName: "MANTHEDING PRIMARY SCHOOL",
    Address: "NO STREET NAME  MANTHEDING VILLAGE  PIETERSBURG",
    Latitude: "-23.7678",
    Longitude: "29.7412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15702",
    WardLookupId: "1938",
    VDNumber: "76190418",
    RegisteredPopulation: "1149",
    VotingStationName: "MASEBODILA PRIMARY SCHOOL",
    Address: "DIKGALE  PIETERSBURG",
    Latitude: "-23.7536",
    Longitude: "29.7131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15703",
    WardLookupId: "1938",
    VDNumber: "76190429",
    RegisteredPopulation: "1648",
    VotingStationName: "SEBAYENG PRIMARY  SCHOOL",
    Address: "SEBAYENG 859 LS  SEBAYENG  PIETERSBURG",
    Latitude: "-23.7647",
    Longitude: "29.6997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15704",
    WardLookupId: "1938",
    VDNumber: "76190519",
    RegisteredPopulation: "945",
    VotingStationName: "MAPHUTO PRIMARY SCHOOL",
    Address: "NO STREET NAME  SEBAYENG  PIETERSBURG",
    Latitude: "-23.7711",
    Longitude: "29.6917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15705",
    WardLookupId: "1938",
    VDNumber: "76190654",
    RegisteredPopulation: "797",
    VotingStationName: "PENTECOSTAL PROTESTANT CHURCH",
    Address: "SEBAYENG UNIT D  MANKWENG  GA-DIKGALE",
    Latitude: "-23.7733",
    Longitude: "29.7013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15706",
    WardLookupId: "1939",
    VDNumber: "76190317",
    RegisteredPopulation: "843",
    VotingStationName: "MAROBALA PRIMARY SCHOOL",
    Address: "MAROBALA VILLAGE  DIKGALE  POLOKWANE",
    Latitude: "-23.6905",
    Longitude: "29.7843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15707",
    WardLookupId: "1939",
    VDNumber: "76190328",
    RegisteredPopulation: "1103",
    VotingStationName: "MORARO PRIMARY SCHOOL",
    Address: "MAROBALA   DIKGALE  POLOKWANE",
    Latitude: "-23.7084",
    Longitude: "29.7799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15708",
    WardLookupId: "1939",
    VDNumber: "76190340",
    RegisteredPopulation: "1172",
    VotingStationName: "MALESELA PRIMARY SCHOOL",
    Address: "TITIBE  PIETERSBURG",
    Latitude: "-23.7207",
    Longitude: "29.7977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15709",
    WardLookupId: "1939",
    VDNumber: "76190351",
    RegisteredPopulation: "512",
    VotingStationName: "MOGABANE PRIMARY SCHOOL",
    Address: "MOGABANE  DIKGALE  POLOKWANE",
    Latitude: "-23.7241",
    Longitude: "29.785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15710",
    WardLookupId: "1939",
    VDNumber: "76190362",
    RegisteredPopulation: "1073",
    VotingStationName: "MABOYANE PRIMARY SCHOOL",
    Address: "KHOLETSENG  DIKGALE  PIETERSBURG",
    Latitude: "-23.7476",
    Longitude: "29.7824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15711",
    WardLookupId: "1939",
    VDNumber: "76190384",
    RegisteredPopulation: "1309",
    VotingStationName: "RAMABU HIGH SCHOOL",
    Address: "MOKGOPO  DIKGALE  PIETERSBURG",
    Latitude: "-23.7497",
    Longitude: "29.7622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15712",
    WardLookupId: "1940",
    VDNumber: "76300231",
    RegisteredPopulation: "1130",
    VotingStationName: "THUNE PRIMARY SCHOOL",
    Address: "THUNE  MAMABOLO  POLOKWANE",
    Latitude: "-23.8706",
    Longitude: "29.8353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15713",
    WardLookupId: "1940",
    VDNumber: "76300242",
    RegisteredPopulation: "613",
    VotingStationName: "SEHLOMOLA PRIMARY SCHOOL",
    Address: "MAMPHAKA  MAMABOLO  POLOKWANE",
    Latitude: "-23.8708",
    Longitude: "29.8437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15714",
    WardLookupId: "1940",
    VDNumber: "76300264",
    RegisteredPopulation: "772",
    VotingStationName: "RAKOPI PRIMARY SCHOOL",
    Address: "SPITZKOP  MAMABOLO  POLOKWANE",
    Latitude: "-23.8891",
    Longitude: "29.8737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15715",
    WardLookupId: "1940",
    VDNumber: "76300310",
    RegisteredPopulation: "1913",
    VotingStationName: "HOLEGANG DAY CARE",
    Address: "BADIMONG  MAMABOLO  MAMABOLO TRIBAL",
    Latitude: "-23.9039",
    Longitude: "29.7768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15716",
    WardLookupId: "1940",
    VDNumber: "76300343",
    RegisteredPopulation: "1409",
    VotingStationName: "MAMABOLO PRIMARY SCHOOL",
    Address: "MONYWANENG    MAMABOLO  POLOKWANE",
    Latitude: "-23.893",
    Longitude: "29.8044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15717",
    WardLookupId: "1940",
    VDNumber: "76300635",
    RegisteredPopulation: "290",
    VotingStationName: "MMATLALA PRIMARY SCHOOL",
    Address: "MOHLAKENG  MAMABOLO  POLOKWANE",
    Latitude: "-23.8723",
    Longitude: "29.8641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15718",
    WardLookupId: "1940",
    VDNumber: "76300770",
    RegisteredPopulation: "814",
    VotingStationName: "NKOSHILO HIGH SCHOOL",
    Address: "GA-MAMABOLO  MANKWENG  MANKWENG TRIBAL AUTHORITY",
    Latitude: "-23.9045",
    Longitude: "29.7887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15719",
    WardLookupId: "1941",
    VDNumber: "76320132",
    RegisteredPopulation: "589",
    VotingStationName: "KGWAREDI PRIMARY SCHOOL",
    Address: "MMACHOANE  MOLETJIE  POLOKWANE",
    Latitude: "-23.6936",
    Longitude: "29.2414",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15720",
    WardLookupId: "1941",
    VDNumber: "76320154",
    RegisteredPopulation: "1003",
    VotingStationName: "SEBOKELA PRIMARY SCHOOL",
    Address: "MPHELATHANTSHA  MOLETJIE  POLOKWANE",
    Latitude: "-23.6787",
    Longitude: "29.258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15721",
    WardLookupId: "1941",
    VDNumber: "76320198",
    RegisteredPopulation: "454",
    VotingStationName: "MMATLOU PRIMARY SCHOOL",
    Address: "MAUPYE VILLAGE  MOLETJIE  POLOKWANE",
    Latitude: "-23.7062",
    Longitude: "29.185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15722",
    WardLookupId: "1941",
    VDNumber: "76320402",
    RegisteredPopulation: "881",
    VotingStationName: "MOREMI (RAMAKGAPHOLA) PRIMARY SCHOOL",
    Address: "GA-RAMAKGAPHOLA  MOLETJIE  POLOKWANE",
    Latitude: "-23.7631",
    Longitude: "29.2228",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15723",
    WardLookupId: "1941",
    VDNumber: "76320457",
    RegisteredPopulation: "868",
    VotingStationName: "SEETLA PRIMARY SCHOOL",
    Address: "GA-RANKHUWE  MOLETJIE  POLOKWANE",
    Latitude: "-23.5979",
    Longitude: "29.2467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15724",
    WardLookupId: "1941",
    VDNumber: "76320592",
    RegisteredPopulation: "716",
    VotingStationName: "MANAMELA PRIMARY SCHOOL",
    Address: "GA-MANAMELA  MOLETJIE  POLOKWANE",
    Latitude: "-23.7123",
    Longitude: "29.2248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15725",
    WardLookupId: "1941",
    VDNumber: "76320839",
    RegisteredPopulation: "737",
    VotingStationName: "LEKWALAKWALA PRIMARY SCHOOL",
    Address: "MONYWANENG  MOLETJIE  POLOKWANE",
    Latitude: "-23.6204",
    Longitude: "29.2479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15726",
    WardLookupId: "1941",
    VDNumber: "76320862",
    RegisteredPopulation: "590",
    VotingStationName: "MAMOLOKO PRIMARY SCHOOL",
    Address: "MAMOLOKO  MOLETJIE  POLOKWANE",
    Latitude: "-23.6823",
    Longitude: "29.2272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15727",
    WardLookupId: "1941",
    VDNumber: "76320930",
    RegisteredPopulation: "858",
    VotingStationName: "NKGELE PRIMARY SCHOOL",
    Address: "GA PIET  MOLETJIE  POLOKWANE",
    Latitude: "-23.6078",
    Longitude: "29.2229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15728",
    WardLookupId: "1941",
    VDNumber: "76321188",
    RegisteredPopulation: "728",
    VotingStationName: "PITSI A MASHILO PRIMARY SCHOOL",
    Address: "GA-MABILWANE  MOLETJIE  POLOKWANE",
    Latitude: "-23.645",
    Longitude: "29.1929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15729",
    WardLookupId: "1941",
    VDNumber: "76321324",
    RegisteredPopulation: "130",
    VotingStationName: "MACHEMA PRIMARY SCHOOL",
    Address: "HELENA  MOLETJIE  POLOKWANE",
    Latitude: "-23.7311",
    Longitude: "29.1898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15730",
    WardLookupId: "1942",
    VDNumber: "76090215",
    RegisteredPopulation: "244",
    VotingStationName: "TENT AT MIKES CHICKEN FARM",
    Address: "MIKES CHICKEN FARM  MAKGODU  MOLETSI",
    Latitude: "-23.81",
    Longitude: "29.4362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15731",
    WardLookupId: "1942",
    VDNumber: "76320356",
    RegisteredPopulation: "1409",
    VotingStationName: "MOKATI PRIMARY SCHOOL",
    Address: "KGOHLOANE  MOLETJIE  POLOKWANE",
    Latitude: "-23.7931",
    Longitude: "29.3772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15732",
    WardLookupId: "1942",
    VDNumber: "76320840",
    RegisteredPopulation: "972",
    VotingStationName: "JH MOLOTO SECONDARY SCHOOL",
    Address: "GA MABOTJA  MOLETJIE  POLOKWANE",
    Latitude: "-23.7237",
    Longitude: "29.3607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15733",
    WardLookupId: "1942",
    VDNumber: "76320851",
    RegisteredPopulation: "1036",
    VotingStationName: "RALEMA PRIMARY SCHOOL",
    Address: "GA-RAMPHELE  MOLETJIE  POLOKWANE",
    Latitude: "-23.7295",
    Longitude: "29.3396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15734",
    WardLookupId: "1942",
    VDNumber: "76320873",
    RegisteredPopulation: "1463",
    VotingStationName: "KGAKOA SECONDARY SCHOOL",
    Address: "NO STREET NAME  KGWAKWA/RAMONSOAKA D  PIETERSBURG",
    Latitude: "-23.7165",
    Longitude: "29.3982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15735",
    WardLookupId: "1942",
    VDNumber: "76320884",
    RegisteredPopulation: "1433",
    VotingStationName: "MARIPHU PRIMARY SCHOOL",
    Address: "NO STREET NAME  RAMONGOANA NO 2  PIETERSBURG",
    Latitude: "-23.7272",
    Longitude: "29.3998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15736",
    WardLookupId: "1943",
    VDNumber: "76090024",
    RegisteredPopulation: "1638",
    VotingStationName: "DOROTHY LANGA PRIMARY SCHOOL",
    Address: "3818 ZONE 2  SESHEGO  CAPRICORN",
    Latitude: "-23.844",
    Longitude: "29.3955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15737",
    WardLookupId: "1943",
    VDNumber: "76090035",
    RegisteredPopulation: "2586",
    VotingStationName: "BOIKETLO PRIMARY SCHOOL",
    Address: "1827 ZONE 3  SESHEGO  PIETERSBURG",
    Latitude: "-23.8372",
    Longitude: "29.3836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15738",
    WardLookupId: "1943",
    VDNumber: "76090361",
    RegisteredPopulation: "1034",
    VotingStationName: "SAMUEL THEMA PRIMARY SCHOOL",
    Address: "2092 BRAMFISHA  SESHEGO-C  SESHEGO",
    Latitude: "-23.8398",
    Longitude: "29.3889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15739",
    WardLookupId: "1943",
    VDNumber: "76090406",
    RegisteredPopulation: "142",
    VotingStationName: "MASEALA PROGRESSIVE PRIMARY SCHOOL",
    Address: "SESHEGO  POLOKWANE",
    Latitude: "-23.8348",
    Longitude: "29.4035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15740",
    WardLookupId: "1943",
    VDNumber: "76320424",
    RegisteredPopulation: "1281",
    VotingStationName: "THAKGALANG PRIMARY SCHOOL",
    Address: "NO STREET NAME  BLOODRIVER-GA MOHOLOLA  PIETERSBURG",
    Latitude: "-23.8171",
    Longitude: "29.3814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15741",
    WardLookupId: "1944",
    VDNumber: "76320907",
    RegisteredPopulation: "1141",
    VotingStationName: "RAMOGOBE PRIMARY SCHOOL",
    Address: "RAMONGOANA NO 1  MOLETJIE  POLOKWANE",
    Latitude: "-23.7097",
    Longitude: "29.4046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15742",
    WardLookupId: "1944",
    VDNumber: "76320918",
    RegisteredPopulation: "1786",
    VotingStationName: "MASEGELA LOWER PRIMARY SCHOOL",
    Address: "GA - SEMENYA  MOLETJIE  POLOKWANE",
    Latitude: "-23.697",
    Longitude: "29.4078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15743",
    WardLookupId: "1944",
    VDNumber: "76320941",
    RegisteredPopulation: "1390",
    VotingStationName: "PHUTI MAKIBELO PRIMARY SCHOOL",
    Address: "GA- MAKIBELO  MOLETJIE  POLOKWANE",
    Latitude: "-23.6593",
    Longitude: "29.3979",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15744",
    WardLookupId: "1944",
    VDNumber: "76320974",
    RegisteredPopulation: "2047",
    VotingStationName: "MALOKANENG PRIMARY SCHOOL",
    Address: "GA HLAHLA  MOLETJIE/HLAHLA VILLAGE  PIETERSBURG",
    Latitude: "-23.6469",
    Longitude: "29.4012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15745",
    WardLookupId: "1945",
    VDNumber: "76090136",
    RegisteredPopulation: "3751",
    VotingStationName: "LAERSKOOL PIETERSBURG NOORD",
    Address: "BICCARD STREET  POLOKWANE  POLOKWANE",
    Latitude: "-23.9012",
    Longitude: "29.454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15746",
    WardLookupId: "1945",
    VDNumber: "76090271",
    RegisteredPopulation: "3193",
    VotingStationName: "HOÃ‹R TEGNIESE SKOOL TOM NAUDE",
    Address: "WEBSTER STREET  WELGELEGEN  POLOKWANE  ",
    Latitude: "-23.8848",
    Longitude: "29.4596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15747",
    WardLookupId: "1946",
    VDNumber: "76310108",
    RegisteredPopulation: "517",
    VotingStationName: "PHATLA PHADIMA SPECIAL SCHOOL",
    Address: "MATLALENG  AGANANG  MASHASHANE",
    Latitude: "-23.9317",
    Longitude: "29.1288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15748",
    WardLookupId: "1946",
    VDNumber: "76310119",
    RegisteredPopulation: "864",
    VotingStationName: "BOTSIKANA SENIOR SECONDARY SCHOOL",
    Address: "KGASHA VILLAGE  MASHASHANE  POLOKWANE",
    Latitude: "-23.9648",
    Longitude: "29.1136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15749",
    WardLookupId: "1946",
    VDNumber: "76310120",
    RegisteredPopulation: "609",
    VotingStationName: "MASHASHANE TRIBAL AUTHORITY",
    Address: "MASHASHANE VILLAGE  MASHASHANE  POLOKWANE",
    Latitude: "-23.9405",
    Longitude: "29.1182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15750",
    WardLookupId: "1946",
    VDNumber: "76310131",
    RegisteredPopulation: "941",
    VotingStationName: "MORELEBA-KGANYAGO PRIMARY SCHOOL",
    Address: "SEGWAHLENG  MASHASHANE  POLOKWANE",
    Latitude: "-23.9514",
    Longitude: "29.1291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15751",
    WardLookupId: "1946",
    VDNumber: "76310142",
    RegisteredPopulation: "808",
    VotingStationName: "UTJANE PRIMARY SCHOOL",
    Address: "UTJANE   MASHASHANE  POLOKWANE",
    Latitude: "-23.9303",
    Longitude: "29.1694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15752",
    WardLookupId: "1946",
    VDNumber: "76310153",
    RegisteredPopulation: "664",
    VotingStationName: "SEBUSHI PRIMARY SCHOOL",
    Address: "MANYAPYE  MASHASHANE  POLOKWANE",
    Latitude: "-23.9079",
    Longitude: "29.1987",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15753",
    WardLookupId: "1946",
    VDNumber: "76310311",
    RegisteredPopulation: "857",
    VotingStationName: "MAUNE PRIMARY SCHOOL",
    Address: "SEBORA VILLAGE  MASHASHANE  POLOKWANE",
    Latitude: "-23.9471",
    Longitude: "29.1551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15754",
    WardLookupId: "1946",
    VDNumber: "76310377",
    RegisteredPopulation: "552",
    VotingStationName: "DR M J MADIBA HIGH SCHOOL",
    Address: "GA-MADIBA  MASHASHANE  POLOKWANE",
    Latitude: "-23.8851",
    Longitude: "29.1458",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15755",
    WardLookupId: "1946",
    VDNumber: "76310388",
    RegisteredPopulation: "809",
    VotingStationName: "KGOLOKGOTHLA LEDWABA HIGH SCHOOL",
    Address: "MAPATENG VILLAGE  MASHASHANE   POLOKWANE",
    Latitude: "-23.9448",
    Longitude: "29.1029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15756",
    WardLookupId: "1946",
    VDNumber: "76310412",
    RegisteredPopulation: "417",
    VotingStationName: "MADENATHAGA PRIMARY SCHOOL",
    Address: "MOHLONONG  MASHASHANE  POLOKWANE",
    Latitude: "-23.9009",
    Longitude: "29.1212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15757",
    WardLookupId: "1946",
    VDNumber: "76310456",
    RegisteredPopulation: "650",
    VotingStationName: "TUTWANA PRIMARY SCHOOL",
    Address: "GA-KGASHA  MASHASHANE  POLOKWANE",
    Latitude: "-23.9778",
    Longitude: "29.1057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15758",
    WardLookupId: "1946",
    VDNumber: "76310478",
    RegisteredPopulation: "302",
    VotingStationName: "TLAKALE MASHASHANE SEC. SCHOOL",
    Address: "MANDELA VILLAGE  MASHASHANE   POLOKWANE",
    Latitude: "-23.9157",
    Longitude: "29.1338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15759",
    WardLookupId: "1947",
    VDNumber: "76310041",
    RegisteredPopulation: "871",
    VotingStationName: "NNATILE SCHOOL",
    Address: "NO STREET ADDRESS  BERGZICHT VILLAGE  CAPRICORN",
    Latitude: "-23.8364",
    Longitude: "29.1292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15760",
    WardLookupId: "1947",
    VDNumber: "76310052",
    RegisteredPopulation: "652",
    VotingStationName: "JAWE-JAWE LEDWABA SENIOR SECONDARY SCHOOL",
    Address: "VENUS VILLAGE  MASHASHANE  POLOKWANE",
    Latitude: "-23.8222",
    Longitude: "29.0761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15761",
    WardLookupId: "1947",
    VDNumber: "76310063",
    RegisteredPopulation: "1209",
    VotingStationName: "MALOPENG PRIMARY SCHOOL",
    Address: "JUPITER  MASHASHANE  POLOKWANE",
    Latitude: "-23.8699",
    Longitude: "29.0317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15762",
    WardLookupId: "1947",
    VDNumber: "76310074",
    RegisteredPopulation: "760",
    VotingStationName: "SEEMOLE MARABA SECONDARY SCHOOL",
    Address: "KLEINROY   MASHASHANE  POLOKWANE",
    Latitude: "-23.906",
    Longitude: "29.078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15763",
    WardLookupId: "1947",
    VDNumber: "76310096",
    RegisteredPopulation: "790",
    VotingStationName: "MASHIANOKE SCHOOL",
    Address: "MONOTWANE  MASHASHANE  POLOKWANE",
    Latitude: "-23.8722",
    Longitude: "29.1156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15764",
    WardLookupId: "1947",
    VDNumber: "76310322",
    RegisteredPopulation: "536",
    VotingStationName: "TLHALERWA-KONE CRECHE CUM PRE",
    Address: "BELLINGSGATE   MASHASHANE  POLOKWANE",
    Latitude: "-23.8255",
    Longitude: "29.1048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15765",
    WardLookupId: "1947",
    VDNumber: "76310344",
    RegisteredPopulation: "476",
    VotingStationName: "RANTSHU PRIMARY SCHOOL",
    Address: "DIANA  MASHASHANE  POLOKWANE",
    Latitude: "-23.8753",
    Longitude: "29.0732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15766",
    WardLookupId: "1947",
    VDNumber: "76310434",
    RegisteredPopulation: "551",
    VotingStationName: "SEHLARE PRIMARY SCHOOL",
    Address: "VENUS  MASHASHANE  POLOKWANE ",
    Latitude: "-23.825",
    Longitude: "29.0634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15767",
    WardLookupId: "1947",
    VDNumber: "76310445",
    RegisteredPopulation: "350",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "MAPEDING  MASHASHANE  POLOKWANE",
    Latitude: "-23.8899",
    Longitude: "29.1314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15768",
    WardLookupId: "1947",
    VDNumber: "76320312",
    RegisteredPopulation: "747",
    VotingStationName: "MADIETANE PRIMARY SCHOOL",
    Address: "MADIETANE  BAKONE  POLOKWANE",
    Latitude: "-23.8078",
    Longitude: "29.0051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15769",
    WardLookupId: "1947",
    VDNumber: "76320345",
    RegisteredPopulation: "528",
    VotingStationName: "NGAKANA HIGH SCHOOL",
    Address: "MADIETANE  BAKONE  POLOKWANE",
    Latitude: "-23.8152",
    Longitude: "29.0172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15770",
    WardLookupId: "1948",
    VDNumber: "76310018",
    RegisteredPopulation: "871",
    VotingStationName: "MARABA TRIBAL OFFICE",
    Address: "KALKSPRUIT VILLAGE  MARABA  POLOKWANE",
    Latitude: "-23.7796",
    Longitude: "29.165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15771",
    WardLookupId: "1948",
    VDNumber: "76310030",
    RegisteredPopulation: "638",
    VotingStationName: "JONAS LESETJA LEDWABA SCHOOL",
    Address: "CHRISTINA   MARABA  AGANANG",
    Latitude: "-23.7889",
    Longitude: "29.1044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15772",
    WardLookupId: "1948",
    VDNumber: "76310085",
    RegisteredPopulation: "859",
    VotingStationName: "ABRAM SIBASA SCHOOL",
    Address: "GA-MAGONGOA  MARABA  POLOKWANE",
    Latitude: "-23.7833",
    Longitude: "29.1539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15773",
    WardLookupId: "1948",
    VDNumber: "76310366",
    RegisteredPopulation: "553",
    VotingStationName: "NGWANAMPHAHLELE SCHOOL",
    Address: "LEPOTLAKO   MARABA  POLOKWANE",
    Latitude: "-23.7841",
    Longitude: "29.1185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15774",
    WardLookupId: "1948",
    VDNumber: "76310401",
    RegisteredPopulation: "666",
    VotingStationName: "NTSODI BAMBO PRIMARY SCHOOL",
    Address: "WASCHBANK  MARABA  POLOKWANE",
    Latitude: "-23.7673",
    Longitude: "29.1258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15775",
    WardLookupId: "1948",
    VDNumber: "76310423",
    RegisteredPopulation: "758",
    VotingStationName: "APOSTOLIC FAITH MISSION",
    Address: "KALKSPRUIT  MARABA  POLOKWANE",
    Latitude: "-23.7908",
    Longitude: "29.1746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15776",
    WardLookupId: "1948",
    VDNumber: "76320110",
    RegisteredPopulation: "480",
    VotingStationName: "TSOGE  PRIMARY SCHOOL",
    Address: "MAINELENG VILLAGE  BAKONE  POLOKWANE",
    Latitude: "-23.7649",
    Longitude: "29.0745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15777",
    WardLookupId: "1948",
    VDNumber: "76320222",
    RegisteredPopulation: "708",
    VotingStationName: "PUDUTJE HIGHER PRIMARY SCHOOL",
    Address: "GA-MANAMELA  BAKONE  POLOKWANE",
    Latitude: "-23.783",
    Longitude: "29.0667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15778",
    WardLookupId: "1948",
    VDNumber: "76320378",
    RegisteredPopulation: "602",
    VotingStationName: "TAUKWENA PRIMARY SCHOOL",
    Address: "KORDON  BAKONE  POLOKWANE",
    Latitude: "-23.7117",
    Longitude: "29.062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15779",
    WardLookupId: "1948",
    VDNumber: "76321009",
    RegisteredPopulation: "365",
    VotingStationName: "KONKOTI PRIMARY SCHOOL",
    Address: "ZAAIPLAATZ VILLAGE  MARABA  AGANANG",
    Latitude: "-23.7363",
    Longitude: "29.0825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15780",
    WardLookupId: "1948",
    VDNumber: "76321065",
    RegisteredPopulation: "812",
    VotingStationName: "MOSHIDI SECONDARY SCHOOL",
    Address: "GA-SETSHABA  GA-MARABA  AGANANG",
    Latitude: "-23.7258",
    Longitude: "29.1322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15781",
    WardLookupId: "1948",
    VDNumber: "76321256",
    RegisteredPopulation: "441",
    VotingStationName: "KGOROSHI PRIMARY SCHOOL",
    Address: "GA-KGOROSHI  MOLETJIE  POLOKWANE",
    Latitude: "-23.7191",
    Longitude: "29.1107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15782",
    WardLookupId: "1949",
    VDNumber: "76320187",
    RegisteredPopulation: "482",
    VotingStationName: "KOKAMI PRIMARY SCHOOL",
    Address: "SEMANENG VILLAGE  BAKONE  AGANANG",
    Latitude: "-23.7506",
    Longitude: "28.9692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15783",
    WardLookupId: "1949",
    VDNumber: "76320233",
    RegisteredPopulation: "594",
    VotingStationName: "KGOPODI PRIMARY SCHOOL",
    Address: "NTLOLANE VILLAGE  BAKONE  POLOKWANE",
    Latitude: "-23.7204",
    Longitude: "28.976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15784",
    WardLookupId: "1949",
    VDNumber: "76320637",
    RegisteredPopulation: "656",
    VotingStationName: "SELOUTJO SECONDARY SCHOOL",
    Address: "SELOLO VILLAGE  BAKONE  POLOKWANE",
    Latitude: "-23.7474",
    Longitude: "28.9871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15785",
    WardLookupId: "1949",
    VDNumber: "76320660",
    RegisteredPopulation: "954",
    VotingStationName: "RACHEBOLE PRIMARY SCHOOL",
    Address: "GA-MAHOAI  BAKONE  POLOKWANE",
    Latitude: "-23.7163",
    Longitude: "29.0262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15786",
    WardLookupId: "1949",
    VDNumber: "76320985",
    RegisteredPopulation: "943",
    VotingStationName: "BAKONE TRIBAL OFFICE",
    Address: "SETUMONG  BAKONE TRIBAL  POLOKWANE",
    Latitude: "-23.7577",
    Longitude: "29.0441",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15787",
    WardLookupId: "1949",
    VDNumber: "76321054",
    RegisteredPopulation: "781",
    VotingStationName: "PHOFFU PRIMARY SCHOOL",
    Address: "PHOFFU  BAKONE TRIBAL  POLOKWANE",
    Latitude: "-23.8062",
    Longitude: "28.9255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15788",
    WardLookupId: "1949",
    VDNumber: "76321076",
    RegisteredPopulation: "838",
    VotingStationName: "DIBENG PRIMARY SCHOOL",
    Address: "DIBENG  BAKONE TRIBAL  POLOKWANE",
    Latitude: "-23.7762",
    Longitude: "28.9316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15789",
    WardLookupId: "1949",
    VDNumber: "76321087",
    RegisteredPopulation: "664",
    VotingStationName: "NKHUMISHE MMUSHI PRIMARY SCHOOL",
    Address: "KGOMOSCHOOL  BAKONE  POLOKWANE",
    Latitude: "-23.7328",
    Longitude: "28.9479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15790",
    WardLookupId: "1949",
    VDNumber: "76321100",
    RegisteredPopulation: "823",
    VotingStationName: "PULA SEOPA PRIMARY SCHOOL",
    Address: "PHETOLE VILLAGE  BAKONE TRIBAL  POLOKWANE",
    Latitude: "-23.8034",
    Longitude: "28.9804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15791",
    WardLookupId: "1949",
    VDNumber: "76321245",
    RegisteredPopulation: "420",
    VotingStationName: "RAMALAPA PRIMARY SCHOOL",
    Address: "GA-RAMALAPA  BAKONE TRIBAL  POLOKWANE",
    Latitude: "-23.7141",
    Longitude: "29.0461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15792",
    WardLookupId: "1949",
    VDNumber: "76321593",
    RegisteredPopulation: "227",
    VotingStationName: "MOGOSHI PRIMARY SCHOOL",
    Address: "SEDIE VILLAGE  BAKONE TRIBAL  POLOKWANE",
    Latitude: "-23.7522",
    Longitude: "29.0273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15793",
    WardLookupId: "1949",
    VDNumber: "76480016",
    RegisteredPopulation: "165",
    VotingStationName: "HARRY OPPENHEIMER AGRICULTURAL HIGH SCHOOL",
    Address: "LERNBERG   BAKONE TRIBAL  POLOKWANE",
    Latitude: "-23.7998",
    Longitude: "28.8936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15794",
    WardLookupId: "1950",
    VDNumber: "76320266",
    RegisteredPopulation: "613",
    VotingStationName: "MMAPHEKGO PRIMARY SCHOOL",
    Address: "MABOPANE  BAKONE TRIBAL  POLOKWANE",
    Latitude: "-23.6371",
    Longitude: "28.9824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15795",
    WardLookupId: "1950",
    VDNumber: "76320277",
    RegisteredPopulation: "827",
    VotingStationName: "TIBANEFONTEIN PRIMARY SCHOOL",
    Address: "TIBANEFONTEIN   BAKONE TRIBAL  POLOKWANE",
    Latitude: "-23.6519",
    Longitude: "29.0184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15796",
    WardLookupId: "1950",
    VDNumber: "76320435",
    RegisteredPopulation: "793",
    VotingStationName: "MMAKGABO PRIMARY SCHOOL",
    Address: "GA-RAMMOBOLA  MOLETJIE  POLOKWANE",
    Latitude: "-23.6341",
    Longitude: "29.0798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15797",
    WardLookupId: "1950",
    VDNumber: "76320468",
    RegisteredPopulation: "473",
    VotingStationName: "KGOMONTSWERE PRIMARY SCHOOL",
    Address: "BORATAPELO  BAKONE TRIBAL  POLOKWANE",
    Latitude: "-23.6948",
    Longitude: "28.9937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15798",
    WardLookupId: "1950",
    VDNumber: "76320570",
    RegisteredPopulation: "412",
    VotingStationName: "CHLOE PRIMARY SCHOOL",
    Address: "CHLOE  BAKONE TRIBAL  POLOKWANE",
    Latitude: "-23.6784",
    Longitude: "29.0827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15799",
    WardLookupId: "1950",
    VDNumber: "76320581",
    RegisteredPopulation: "826",
    VotingStationName: "NONG MODIKWA PRIMARY SCHOOL",
    Address: "HWIBI  BAKONE TRIBAL  POLOKWANE",
    Latitude: "-23.6685",
    Longitude: "28.9025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15800",
    WardLookupId: "1950",
    VDNumber: "76320604",
    RegisteredPopulation: "539",
    VotingStationName: "MAPHUTHA HIGHER PRIMARY SCHOOL",
    Address: "GOEDGEVOND  BAKONE TIRBAL  POLOKWANE",
    Latitude: "-23.6521",
    Longitude: "28.9231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15801",
    WardLookupId: "1950",
    VDNumber: "76320615",
    RegisteredPopulation: "763",
    VotingStationName: "MOETAGARE PRIMARY SCHOOL",
    Address: "MOETAGARE  BAKONE  POLOKWANE",
    Latitude: "-23.6595",
    Longitude: "28.9726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15802",
    WardLookupId: "1950",
    VDNumber: "76320626",
    RegisteredPopulation: "672",
    VotingStationName: "SEMORISHI PRIMARY SCHOOL",
    Address: "SEMORISHI  BAKONE TRIBAL  POLOKWANE",
    Latitude: "-23.6264",
    Longitude: "28.9563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15803",
    WardLookupId: "1950",
    VDNumber: "76321223",
    RegisteredPopulation: "606",
    VotingStationName: "MADUMA PRIMARY SCHOOL",
    Address: "JUNO  BAKONE TRIBAL  POLOKWANE",
    Latitude: "-23.6237",
    Longitude: "29.0255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15804",
    WardLookupId: "1950",
    VDNumber: "76321234",
    RegisteredPopulation: "1005",
    VotingStationName: "IKAGELENG MAKOBE PRIMARY SCHOOL",
    Address: "VLAKFONTEIN  BAKONE TRIBAL  POLOKWANE",
    Latitude: "-23.6782",
    Longitude: "29.0432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15805",
    WardLookupId: "1950",
    VDNumber: "76321391",
    RegisteredPopulation: "285",
    VotingStationName: "LEKGORONG PRIMARY SCHOOL",
    Address: "CHLOE VILLAGE  AGANANG MUNICIPALITY  MOLETJIE TRIBAL",
    Latitude: "-23.6566",
    Longitude: "29.0916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15806",
    WardLookupId: "1950",
    VDNumber: "76321661",
    RegisteredPopulation: "192",
    VotingStationName: "WORD REVIVAL MINISTRIES",
    Address: "CLOESDAM  AGANANG  MATLALA",
    Latitude: "-23.6978",
    Longitude: "29.0585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15807",
    WardLookupId: "1951",
    VDNumber: "76320020",
    RegisteredPopulation: "943",
    VotingStationName: "RAMPURU PRIMARY SCHOOL",
    Address: "GA-RAMPURU  MOLETJIE  POLOKWANE",
    Latitude: "-23.6748",
    Longitude: "29.1132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15808",
    WardLookupId: "1951",
    VDNumber: "76320288",
    RegisteredPopulation: "655",
    VotingStationName: "SEOKENG HIGH SCHOOL",
    Address: "CERIES  MOLETJIE  POLOKWANE",
    Latitude: "-23.6835",
    Longitude: "29.1316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15809",
    WardLookupId: "1951",
    VDNumber: "76320390",
    RegisteredPopulation: "776",
    VotingStationName: "SEFATALADI PRIMARY SCHOOL",
    Address: "RAMOSHOANA  MOLETJIE  POLOKWANE",
    Latitude: "-23.6331",
    Longitude: "29.0949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15810",
    WardLookupId: "1951",
    VDNumber: "76320525",
    RegisteredPopulation: "928",
    VotingStationName: "MANCHIMUDI PRIMARY SCHOOL",
    Address: "RAMETLWANA VILLAGE  MOLETJIE  POLOKWANE",
    Latitude: "-23.6409",
    Longitude: "29.1529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15811",
    WardLookupId: "1951",
    VDNumber: "76321010",
    RegisteredPopulation: "643",
    VotingStationName: "DIAKGANYA PRIMARY SCHOOL",
    Address: "FAIRLIE  MOLETJIE  POLOKWANE",
    Latitude: "-23.5645",
    Longitude: "29.0889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15812",
    WardLookupId: "1951",
    VDNumber: "76321032",
    RegisteredPopulation: "765",
    VotingStationName: "AGISANANG HIGH PRIMARY SCHOOL",
    Address: "LONSDALE VILLAGE  MOLETJIE  POLOKWANE",
    Latitude: "-23.6117",
    Longitude: "29.1538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15813",
    WardLookupId: "1951",
    VDNumber: "76321043",
    RegisteredPopulation: "709",
    VotingStationName: "RAPITSI PRIMARY SCHOOL",
    Address: "GA-RAPITSI  MOLETJIE  POLOKWANE",
    Latitude: "-23.5914",
    Longitude: "29.1089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15814",
    WardLookupId: "1951",
    VDNumber: "76321212",
    RegisteredPopulation: "767",
    VotingStationName: "KGABO PARK PRIMARY SCHOOL",
    Address: "KGABO PARK  MOLETJIE  POLOKWANE",
    Latitude: "-23.6421",
    Longitude: "29.1219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15815",
    WardLookupId: "1951",
    VDNumber: "76321548",
    RegisteredPopulation: "379",
    VotingStationName: "FIRE BIBLE CENTER",
    Address: "RAPITSI  MOLETJIE  POLOKWANE",
    Latitude: "-23.5957",
    Longitude: "29.1014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15816",
    WardLookupId: "1951",
    VDNumber: "76321560",
    RegisteredPopulation: "294",
    VotingStationName: "ST. STEPHENS ANGLICAN CHURCH",
    Address: "GA-RAMETLWANA   MOLETJIE  POLOKWANE",
    Latitude: "-23.6266",
    Longitude: "29.1524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15817",
    WardLookupId: "1951",
    VDNumber: "76321571",
    RegisteredPopulation: "312",
    VotingStationName: "BAHLALOGA SECONDARY SCHOOL",
    Address: "LONSDALE VILLAGE  MOLETJIE  POLOKWANE",
    Latitude: "-23.619",
    Longitude: "29.1652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15818",
    WardLookupId: "1951",
    VDNumber: "76321582",
    RegisteredPopulation: "403",
    VotingStationName: "MMADITSHIKA PRIMARY SCHOOL",
    Address: "CERES GA-NGWETJANA  MOLETJIE  POLOKWANE",
    Latitude: "-23.6882",
    Longitude: "29.1535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15819",
    WardLookupId: "1952",
    VDNumber: "76270013",
    RegisteredPopulation: "1048",
    VotingStationName: "BA SELOANE PRIMARY SCHOOL",
    Address: "MALATANE  LEPELLE-NKUMPI  GA-SELOANE",
    Latitude: "-24.5492",
    Longitude: "29.506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15820",
    WardLookupId: "1952",
    VDNumber: "76270024",
    RegisteredPopulation: "1036",
    VotingStationName: "BLYDRIFT PRIMARY SCHOOL",
    Address: "BLYDRIFT VILLAGE  LEBOWAKGOMO  PIETERBURG",
    Latitude: "-24.5215",
    Longitude: "29.4959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15821",
    WardLookupId: "1952",
    VDNumber: "76270462",
    RegisteredPopulation: "61",
    VotingStationName: "SEALAMAGORO COMBINED SCHOOL",
    Address: "KGWARIPE  ZEBEDIELA  MOLETLANE",
    Latitude: "-24.5823",
    Longitude: "29.4859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15822",
    WardLookupId: "1952",
    VDNumber: "76400018",
    RegisteredPopulation: "856",
    VotingStationName: "MMUDINAREADI LOWER PRIMARY SCHOOL",
    Address: "KLIPHUIWEL VILLAGE  GOMPIES  ZEBEDIELA",
    Latitude: "-24.6087",
    Longitude: "29.4619",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15823",
    WardLookupId: "1952",
    VDNumber: "76400322",
    RegisteredPopulation: "1134",
    VotingStationName: "RAMASODI LOWER PRIMARY SCHOOL",
    Address: "MAKGOPHONG  MAKGOPHONG VILLAGE  ZEBEDIELA",
    Latitude: "-24.5013",
    Longitude: "29.4524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15824",
    WardLookupId: "1953",
    VDNumber: "76400063",
    RegisteredPopulation: "1143",
    VotingStationName: "PHALAKGOROMOTHOA SENIOR SECONDARY",
    Address: "KHURENG VILLAGE  ZEBEDIELA  ",
    Latitude: "-24.5674",
    Longitude: "29.3808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15825",
    WardLookupId: "1953",
    VDNumber: "76400119",
    RegisteredPopulation: "1092",
    VotingStationName: "MOGOLOGOLO LOWER PRIMARY SCHOOL",
    Address: "MEHLARENG  ZEBEDIELA",
    Latitude: "-24.5379",
    Longitude: "29.439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15826",
    WardLookupId: "1953",
    VDNumber: "76400175",
    RegisteredPopulation: "747",
    VotingStationName: "MOREMOTSE SENIOR SECONDARY SCHOOL",
    Address: "SERULENG VILLAGE  ZEBEDIELA",
    Latitude: "-24.5671",
    Longitude: "29.445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15827",
    WardLookupId: "1953",
    VDNumber: "76400311",
    RegisteredPopulation: "894",
    VotingStationName: "KHURENG PRIMARY SCHOOL",
    Address: "KHURENG  KHURENG  VILLAGE  ZEBEDIELA",
    Latitude: "-24.5613",
    Longitude: "29.3926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15828",
    WardLookupId: "1954",
    VDNumber: "76400232",
    RegisteredPopulation: "756",
    VotingStationName: "NKGOSO SCHOOL",
    Address: "GA MOLAPO  ZEBEDIELA",
    Latitude: "-24.5178",
    Longitude: "29.4126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15829",
    WardLookupId: "1954",
    VDNumber: "76400344",
    RegisteredPopulation: "1158",
    VotingStationName: "MAKGWADING SCHOOL",
    Address: "NO STREET ADDRESS  GA MOLAPO  ZEBEDIELA",
    Latitude: "-24.4978",
    Longitude: "29.4166",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15830",
    WardLookupId: "1954",
    VDNumber: "76400366",
    RegisteredPopulation: "1047",
    VotingStationName: "DIPOFUNG PRIMARY SCHOOL",
    Address: "GEDROOGTE VILLAGE  ZEBEDIELA  ZEBEDIELA",
    Latitude: "-24.4999",
    Longitude: "29.3542",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15831",
    WardLookupId: "1955",
    VDNumber: "76400355",
    RegisteredPopulation: "854",
    VotingStationName: "SHUPENG LOWER PRIMARY SCHOOL",
    Address: "MAGATLE VILLAGE  ZEBEDIELA",
    Latitude: "-24.4495",
    Longitude: "29.3849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15832",
    WardLookupId: "1955",
    VDNumber: "76400377",
    RegisteredPopulation: "1237",
    VotingStationName: "MAGATLE PRIMARY SCHOOL",
    Address: "MAGATLE VILLAGE  MAGATLE  ZEBEDIELA",
    Latitude: "-24.4532",
    Longitude: "29.4048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15833",
    WardLookupId: "1955",
    VDNumber: "76400388",
    RegisteredPopulation: "680",
    VotingStationName: "MAPATJAKENG LOWER PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MAGATLE GOMPIES  ZEBEDIELA",
    Latitude: "-24.4513",
    Longitude: "29.4121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15834",
    WardLookupId: "1955",
    VDNumber: "76400489",
    RegisteredPopulation: "320",
    VotingStationName: "MANKHOLE HIGH SCHOOL",
    Address: "GEDROOGTE  LEPELLE-NKUMPI  ZEBEDIELA",
    Latitude: "-24.4975",
    Longitude: "29.3658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15835",
    WardLookupId: "1955",
    VDNumber: "76400490",
    RegisteredPopulation: "149",
    VotingStationName: "PIET N APHANE HIGH SCHOOL",
    Address: "MAGATLE  LEPELLE-NKUMPI  ZEBEDIELA",
    Latitude: "-24.4487",
    Longitude: "29.3783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15836",
    WardLookupId: "1956",
    VDNumber: "76400074",
    RegisteredPopulation: "1084",
    VotingStationName: "MOTSOFALA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MADISHA DITORO VILLAGE  ZEBEDIELA",
    Latitude: "-24.3975",
    Longitude: "29.3846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15837",
    WardLookupId: "1956",
    VDNumber: "76400265",
    RegisteredPopulation: "700",
    VotingStationName: "MMAMMATI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MADISHA DITORO  ZEBEDIELA",
    Latitude: "-24.4069",
    Longitude: "29.3772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15838",
    WardLookupId: "1956",
    VDNumber: "76400300",
    RegisteredPopulation: "1188",
    VotingStationName: "LETJATJI LOWER PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MADISHA LEOLO VILLAGE  ZEBEDIELA",
    Latitude: "-24.4117",
    Longitude: "29.431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15839",
    WardLookupId: "1956",
    VDNumber: "76400423",
    RegisteredPopulation: "316",
    VotingStationName: "MOTSERERENG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MOTSERERENG VILLAGE  ZEBEDIELA",
    Latitude: "-24.423",
    Longitude: "29.4824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15840",
    WardLookupId: "1957",
    VDNumber: "76400210",
    RegisteredPopulation: "1047",
    VotingStationName: "MAMOGOASHA PRIMARY",
    Address: "NO STREET ADDRESS  GA-MAMOGOASHA  ZEBEDIELA",
    Latitude: "-24.4094",
    Longitude: "29.338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15841",
    WardLookupId: "1957",
    VDNumber: "76400221",
    RegisteredPopulation: "551",
    VotingStationName: "MOLEMO SCHOOL",
    Address: "BOLAHLAKGOMO  BOLAHLAKGOMO VILLAGE  ZEBEDIELA",
    Latitude: "-24.4504",
    Longitude: "29.3111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15842",
    WardLookupId: "1957",
    VDNumber: "76400243",
    RegisteredPopulation: "626",
    VotingStationName: "BOLAHLAKGOMO SCHOOL",
    Address: "BOLAHLAKGOMO  BOLAHLAKGOMO VILLAGE  ZEBEDIELA",
    Latitude: "-24.4411",
    Longitude: "29.3239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15843",
    WardLookupId: "1957",
    VDNumber: "76400254",
    RegisteredPopulation: "1202",
    VotingStationName: "MADIBO SCHOOL",
    Address: "VOLOP  VOLOP  VILLAGE  ZEBEDIELA",
    Latitude: "-24.4324",
    Longitude: "29.33",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15844",
    WardLookupId: "1958",
    VDNumber: "76400197",
    RegisteredPopulation: "1556",
    VotingStationName: "SELLO LOWER PRIMARY SCHOOL",
    Address: "MAKWENG  MAKWENG VILLAGE  ZEBEDIELA",
    Latitude: "-24.3718",
    Longitude: "29.3783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15845",
    WardLookupId: "1958",
    VDNumber: "76400209",
    RegisteredPopulation: "580",
    VotingStationName: "MOKONE  PRIMARY SCHOOL",
    Address: "MOTANTANYANE   ZEBEDIELA  ZEBEDIELA",
    Latitude: "-24.3385",
    Longitude: "29.4276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15846",
    WardLookupId: "1958",
    VDNumber: "76400333",
    RegisteredPopulation: "1737",
    VotingStationName: "MADIKA LOWER PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MAKUSHWANENG VILLAGE  ZEBEDIELA",
    Latitude: "-24.3627",
    Longitude: "29.419",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15847",
    WardLookupId: "1959",
    VDNumber: "76400029",
    RegisteredPopulation: "1506",
    VotingStationName: "SEKUTUPU SCHOOL",
    Address: "MATHIBELA VILLAGE  LEPELLE-NKUMPI  LEPELLE-NKUMP / ZEBEDIELA",
    Latitude: "-24.3183",
    Longitude: "29.3424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15848",
    WardLookupId: "1959",
    VDNumber: "76400085",
    RegisteredPopulation: "1332",
    VotingStationName: "RAMAKGOTHO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA MOGOTLANE  ZEBEDIELA",
    Latitude: "-24.3483",
    Longitude: "29.3219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15849",
    WardLookupId: "1959",
    VDNumber: "76400434",
    RegisteredPopulation: "1025",
    VotingStationName: "MMABOLEPU SECONDARY SCHOOL",
    Address: "MATHIBELA  MOLETLANE",
    Latitude: "-24.3137",
    Longitude: "29.3492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15850",
    WardLookupId: "1960",
    VDNumber: "76400142",
    RegisteredPopulation: "951",
    VotingStationName: "RAMABELE HIGH SCHOOL",
    Address: "MABERENG  MOGOTO VILLAGE  ZEBEDIELA",
    Latitude: "-24.3582",
    Longitude: "29.2626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15851",
    WardLookupId: "1960",
    VDNumber: "76400186",
    RegisteredPopulation: "942",
    VotingStationName: "TINTELA PRIMARY SCHOOL",
    Address: "MOGOTO  MOGOTO  VILLAGE  ZEBEDIELA",
    Latitude: "-24.3444",
    Longitude: "29.2699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15852",
    WardLookupId: "1960",
    VDNumber: "76400467",
    RegisteredPopulation: "602",
    VotingStationName: "RAMOLOKOANE PRIMARY SCHOOL",
    Address: "MOGOTO  ZEBEDIELA  MOLETLANE",
    Latitude: "-24.3582",
    Longitude: "29.2771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15853",
    WardLookupId: "1960",
    VDNumber: "76400502",
    RegisteredPopulation: "433",
    VotingStationName: "MAPUTLA CAFE (TENT)",
    Address: "MOGOTO  LEPELLE-NKUMPI  ZEBEDIELA",
    Latitude: "-24.3426",
    Longitude: "29.2773",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15854",
    WardLookupId: "1961",
    VDNumber: "76400108",
    RegisteredPopulation: "1297",
    VotingStationName: "MASHIGOANA LOWER PRIMARY SCHOOL",
    Address: "HLAKANO  HLAKANO  VILLAGE  ZEBEDIELA",
    Latitude: "-24.3415",
    Longitude: "29.306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15855",
    WardLookupId: "1961",
    VDNumber: "76400401",
    RegisteredPopulation: "1345",
    VotingStationName: "HLAKANO COMMUNITY HALL",
    Address: "HLAKANO  LEPELLE NKUMPI  ZEBEDIELA",
    Latitude: "-24.3396",
    Longitude: "29.305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15856",
    WardLookupId: "1961",
    VDNumber: "76400445",
    RegisteredPopulation: "476",
    VotingStationName: "SETUKA SECONDARY SCHOOL",
    Address: "MAHLAROLLA  GA-MOGOTLANE  MOLETLANE",
    Latitude: "-24.3571",
    Longitude: "29.3139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15857",
    WardLookupId: "1962",
    VDNumber: "76400120",
    RegisteredPopulation: "1214",
    VotingStationName: "GAUTA JONATHAN PRIMARY SCHOOL",
    Address: "SEHLABENG  SEHLABENG VILLAGE  ZEBEDIELA",
    Latitude: "-24.3524",
    Longitude: "29.2945",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15858",
    WardLookupId: "1962",
    VDNumber: "76400164",
    RegisteredPopulation: "1157",
    VotingStationName: "MANAILENG PRIMARY SCHOOL",
    Address: "MANAILENG  MANAILENG  VILLAGE  ZEBEDIELA",
    Latitude: "-24.4005",
    Longitude: "29.2889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15859",
    WardLookupId: "1962",
    VDNumber: "76400456",
    RegisteredPopulation: "290",
    VotingStationName: "MOGOTO PRIMARY SCHOOL",
    Address: "MOGOTO  ZEBEDIELA  MOLETLANE",
    Latitude: "-24.3457",
    Longitude: "29.2843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15860",
    WardLookupId: "1962",
    VDNumber: "76400513",
    RegisteredPopulation: "763",
    VotingStationName: "SEHLABENG (TENT)",
    Address: "SEHLABENG  LEPELLE-NKUMPI  ZEBEDIELA",
    Latitude: "-24.3476",
    Longitude: "29.2928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15861",
    WardLookupId: "1963",
    VDNumber: "76400041",
    RegisteredPopulation: "1090",
    VotingStationName: "MATLADI PROJECT SCHOOL",
    Address: "MOLETLANE  MOLETLANE VILLAGE  ZEBEDIELA",
    Latitude: "-24.3707",
    Longitude: "29.3359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15862",
    WardLookupId: "1963",
    VDNumber: "76400052",
    RegisteredPopulation: "1227",
    VotingStationName: "MOLETLANE TRIBAL HALL",
    Address: "MOLETLANE  MOLETLANE  VILLAGE  ZEBEDIELA",
    Latitude: "-24.3626",
    Longitude: "29.3334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15863",
    WardLookupId: "1963",
    VDNumber: "76400478",
    RegisteredPopulation: "221",
    VotingStationName: "FRISCO GENERAL DEALER TENT",
    Address: "MOLETLANE MAWANENG  LEPELLE NKUMPI  ZEBEDIELA",
    Latitude: "-24.3841",
    Longitude: "29.3445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15864",
    WardLookupId: "1963",
    VDNumber: "76400524",
    RegisteredPopulation: "261",
    VotingStationName: "MOSHODO PRIMARY SCHOOL",
    Address: "MOLETLANE, MAWANENG  LEPELLE-NKUMPI  ZEBEDIELA",
    Latitude: "-24.3775",
    Longitude: "29.3282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15865",
    WardLookupId: "1963",
    VDNumber: "76400535",
    RegisteredPopulation: "265",
    VotingStationName: "CHITA KEKANA HIGH SCHOOL",
    Address: "MOLETLANE  LEPELLE-NKUMPI  ZEBEDIELA",
    Latitude: "-24.3637",
    Longitude: "29.3238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15866",
    WardLookupId: "1964",
    VDNumber: "76270068",
    RegisteredPopulation: "994",
    VotingStationName: "HWELERENG PRIMARY",
    Address: "HWELERENG  MPHAHLELE",
    Latitude: "-24.3547",
    Longitude: "29.4612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15867",
    WardLookupId: "1964",
    VDNumber: "76270305",
    RegisteredPopulation: "1427",
    VotingStationName: "MOSEPIDI SECONDARY SCHOOL",
    Address: "MMAKOTSE VILLAGE  LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.3215",
    Longitude: "29.4405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15868",
    WardLookupId: "1964",
    VDNumber: "76270316",
    RegisteredPopulation: "934",
    VotingStationName: "GA-LEDWABA TRIBAL HALL",
    Address: "GA-LEDWABA VILLAGE  GA-LEDWABA/LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.267",
    Longitude: "29.4202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15869",
    WardLookupId: "1964",
    VDNumber: "76270338",
    RegisteredPopulation: "479",
    VotingStationName: "THOKA PRIMARY SCHOOL",
    Address: "SEPANAPUDI VILLAGE  SEPANAPUDI  POLOKWANE",
    Latitude: "-24.2672",
    Longitude: "29.4718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15870",
    WardLookupId: "1965",
    VDNumber: "76400030",
    RegisteredPopulation: "1259",
    VotingStationName: "NGWANA BAHLALERWA SECONDARY SCHOOL",
    Address: "MATOME  LEPELLE-NKUMPI  ZEBEDIELA",
    Latitude: "-24.2927",
    Longitude: "29.3955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15871",
    WardLookupId: "1965",
    VDNumber: "76400131",
    RegisteredPopulation: "555",
    VotingStationName: "SETHWETHWA JUNIOR SECONDARY SCHOOL",
    Address: "MAKWENG  MAKWENG  VILLAGE  ZEBEDIELA",
    Latitude: "-24.3632",
    Longitude: "29.3752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15872",
    WardLookupId: "1965",
    VDNumber: "76400276",
    RegisteredPopulation: "710",
    VotingStationName: "RAKGOATHA  PRIMARY SCHOOL",
    Address: "GA RAKGOATHA  GA RAKGOATHA  VILLAGE  ZEBEDIELA",
    Latitude: "-24.3501",
    Longitude: "29.3607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15873",
    WardLookupId: "1965",
    VDNumber: "76400287",
    RegisteredPopulation: "843",
    VotingStationName: "MAROBA SENIOR SECONDARY SCHOOL",
    Address: "GA RAKGOATHA  GROOTHOEK  ZEBEDIELA",
    Latitude: "-24.3492",
    Longitude: "29.363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15874",
    WardLookupId: "1965",
    VDNumber: "76400298",
    RegisteredPopulation: "1287",
    VotingStationName: "PHISHOANA PRIMARY SCHOOL",
    Address: "GA RAKGOATHA  ZEBEDIELA  ZEBEDIELA",
    Latitude: "-24.3358",
    Longitude: "29.37",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15875",
    WardLookupId: "1966",
    VDNumber: "76270282",
    RegisteredPopulation: "2500",
    VotingStationName: "DR DIXON MPHAHLELE PRIMARY SCHOOL",
    Address: "ZONE F LEBOWAKGOMO  LEBOWAKGOMO  LEBOWAKGOMO",
    Latitude: "-24.3159",
    Longitude: "29.4738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15876",
    WardLookupId: "1966",
    VDNumber: "76270349",
    RegisteredPopulation: "1944",
    VotingStationName: "EUREKA PRIMARY SCHOOL",
    Address: "ZONE B LEBOKWAGOMO  LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.3091",
    Longitude: "29.4682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15877",
    WardLookupId: "1967",
    VDNumber: "76270259",
    RegisteredPopulation: "2427",
    VotingStationName: "LAFATA PRIMARY SCHOOL",
    Address: "ZONE S  LEBOWAKGOMO  LEBOWAKGOMO",
    Latitude: "-24.308",
    Longitude: "29.5329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15878",
    WardLookupId: "1967",
    VDNumber: "76270350",
    RegisteredPopulation: "671",
    VotingStationName: "HILLSIDE PARK PRIMARY SCHOOL",
    Address: "LEBOWAKGOMO A  LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.3089",
    Longitude: "29.5428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15879",
    WardLookupId: "1968",
    VDNumber: "76270215",
    RegisteredPopulation: "1195",
    VotingStationName: "MOGODUMO PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.2952",
    Longitude: "29.538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15880",
    WardLookupId: "1968",
    VDNumber: "76270293",
    RegisteredPopulation: "825",
    VotingStationName: "LEBOWAKGOMO ZONE P (TENT)",
    Address: "ZONE P  LEPELLE-NKUMPI  LEBOWAKGOMO",
    Latitude: "-24.3055",
    Longitude: "29.5029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15881",
    WardLookupId: "1968",
    VDNumber: "76270372",
    RegisteredPopulation: "1664",
    VotingStationName: "FULL GOSPEL CHURCH",
    Address: "UNIT R LEBOWAKGOMO  LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.2994",
    Longitude: "29.5335",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15882",
    WardLookupId: "1968",
    VDNumber: "76270529",
    RegisteredPopulation: "595",
    VotingStationName: "LEBOWAKGOMO PHASE3 AND Q (TENT)",
    Address: "ZONE R  LEBOWAKGOMO  LEBOWAKGOMO",
    Latitude: "-24.3052",
    Longitude: "29.5174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15883",
    WardLookupId: "1969",
    VDNumber: "76270260",
    RegisteredPopulation: "1381",
    VotingStationName: "NTSEEKGOPU PUBLIC SCHOOL",
    Address: "UNIT A LEBOWAKGOMO  LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.3037",
    Longitude: "29.5395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15884",
    WardLookupId: "1969",
    VDNumber: "76270271",
    RegisteredPopulation: "1583",
    VotingStationName: "LITTLEBEDFORDVIEW PRIMARY SCHOOL",
    Address: "LEBOWAKGOMO A  LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.2961",
    Longitude: "29.5456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15885",
    WardLookupId: "1970",
    VDNumber: "76270136",
    RegisteredPopulation: "550",
    VotingStationName: "BOGALENG PRIMARY SCHOOL",
    Address: "MARALALENG VILLAGE  LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.3637",
    Longitude: "29.6217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15886",
    WardLookupId: "1970",
    VDNumber: "76270192",
    RegisteredPopulation: "1286",
    VotingStationName: "SEFALAOLO PRIMARY SCHOOL",
    Address: "SEFALAOLO VILL  SEFALAOLO/LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.3209",
    Longitude: "29.628",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15887",
    WardLookupId: "1970",
    VDNumber: "76270361",
    RegisteredPopulation: "561",
    VotingStationName: "THAMAGANE PRIMARY",
    Address: "THAMAGANE  MPHAHLELE",
    Latitude: "-24.3834",
    Longitude: "29.5314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15888",
    WardLookupId: "1970",
    VDNumber: "76270439",
    RegisteredPopulation: "197",
    VotingStationName: "MALEKAPANE PRIMARY SCHOOL",
    Address: "MALEKAPANE  MPHAHLELE  LEPELE-NKUMPI [LEBOWAKGOMO]",
    Latitude: "-24.3941",
    Longitude: "29.5996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15889",
    WardLookupId: "1970",
    VDNumber: "76270473",
    RegisteredPopulation: "660",
    VotingStationName: "SEDIMOTHOLE SECONDARY SCHOOL",
    Address: "GA-MASHOENE  LEPELLE- NKUMPI  GA-MPHAHLELE",
    Latitude: "-24.3088",
    Longitude: "29.6174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15890",
    WardLookupId: "1970",
    VDNumber: "76270495",
    RegisteredPopulation: "634",
    VotingStationName: "DITHABANENG PRIMARY SCHOOL",
    Address: "DITHABANENG  MPHAHLELE",
    Latitude: "-24.3655",
    Longitude: "29.5796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15891",
    WardLookupId: "1971",
    VDNumber: "76270035",
    RegisteredPopulation: "1074",
    VotingStationName: "HLAGATSE PRIMARY SCHOOL",
    Address: "MARULAHENG VILL  LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.4607",
    Longitude: "29.5151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15892",
    WardLookupId: "1971",
    VDNumber: "76270046",
    RegisteredPopulation: "1175",
    VotingStationName: "CHUEU-E-KGOLO HIGH SCHOOL",
    Address: "LENTING VILLAGE  LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.4295",
    Longitude: "29.5221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15893",
    WardLookupId: "1971",
    VDNumber: "76270079",
    RegisteredPopulation: "723",
    VotingStationName: "MOROTSE PRIMARY SCHOOL",
    Address: "MOROTSE VILLAGE  LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.3971",
    Longitude: "29.5358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15894",
    WardLookupId: "1971",
    VDNumber: "76270484",
    RegisteredPopulation: "253",
    VotingStationName: "MORORE PRIMARY SCHOOL",
    Address: "MAMATONYA  LEPELLE- NKUMPI  GA-MPHAHLELE",
    Latitude: "-24.4825",
    Longitude: "29.4946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15895",
    WardLookupId: "1971",
    VDNumber: "76400096",
    RegisteredPopulation: "671",
    VotingStationName: "DITLAKANENG SCHOOL",
    Address: "MAKGOPHONG  MAKGOPHONG VILLAGE  ZEBEDIELA",
    Latitude: "-24.4779",
    Longitude: "29.4508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15896",
    WardLookupId: "1972",
    VDNumber: "76270114",
    RegisteredPopulation: "1139",
    VotingStationName: "MAKURUNG PRIMARY SCHOOL",
    Address: "MAKURUNG VILL  LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.3456",
    Longitude: "29.5547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15897",
    WardLookupId: "1972",
    VDNumber: "76270125",
    RegisteredPopulation: "1684",
    VotingStationName: "THAGA-E-TALA SENIOR SECONDARY SCHOOL",
    Address: "MAKURUNG/DITHABANENG  LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.3474",
    Longitude: "29.5724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15898",
    WardLookupId: "1972",
    VDNumber: "76270530",
    RegisteredPopulation: "398",
    VotingStationName: "MANEENG PRIMARY SCHOOL",
    Address: "MAKURUNG  LEPELLE-NKUMPI  MPHAHLELE",
    Latitude: "-24.3464",
    Longitude: "29.5471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15899",
    WardLookupId: "1972",
    VDNumber: "76270541",
    RegisteredPopulation: "286",
    VotingStationName: "MAKURUNG MULTI PURPOSE CENTRE",
    Address: "MAKURUNG  LEPELLE-NKUMPI  MPHAHLELE",
    Latitude: "-24.3377",
    Longitude: "29.5569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15900",
    WardLookupId: "1973",
    VDNumber: "76270204",
    RegisteredPopulation: "877",
    VotingStationName: "MPHAHLELE TRADITIONAL OFFICE",
    Address: "SELETENG VILLAGE  SELETENG/LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.3037",
    Longitude: "29.6413",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15901",
    WardLookupId: "1973",
    VDNumber: "76270226",
    RegisteredPopulation: "1313",
    VotingStationName: "MARETLWANENG PRIMARY SCHOOL",
    Address: "MAMAOLO VILL  MAMAOLO/LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.3173",
    Longitude: "29.5754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15902",
    WardLookupId: "1973",
    VDNumber: "76270237",
    RegisteredPopulation: "1731",
    VotingStationName: "TLOURWE SENIOR SCHOOL",
    Address: "MAMAOLO VILLAGE  MAMAOLO/LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.2947",
    Longitude: "29.5629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15903",
    WardLookupId: "1974",
    VDNumber: "76270170",
    RegisteredPopulation: "603",
    VotingStationName: "PATOGA PRIMARY SCHOOL",
    Address: "PATAGO VILLAGE  PATOGA/LEBOWKGOMO  PIETERSBURG",
    Latitude: "-24.2942",
    Longitude: "29.6421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15904",
    WardLookupId: "1974",
    VDNumber: "76270417",
    RegisteredPopulation: "885",
    VotingStationName: "MATSOBANE PRIMARY SCHOOL",
    Address: "SELETENG  MPHAHLELE",
    Latitude: "-24.305",
    Longitude: "29.6414",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15905",
    WardLookupId: "1974",
    VDNumber: "76270428",
    RegisteredPopulation: "374",
    VotingStationName: "MOUPO PRIMARY SCHOOL",
    Address: "PHALAKWANE  LEPELLE-NKUMPI  MPHAHLELE",
    Latitude: "-24.2739",
    Longitude: "29.6457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15906",
    WardLookupId: "1974",
    VDNumber: "76270574",
    RegisteredPopulation: "275",
    VotingStationName: "NTHABELENG CRECHE",
    Address: "BOLATJANE  GA-MPHAHLELE  GA-MPHAHLELE",
    Latitude: "-24.2801",
    Longitude: "29.6179",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15907",
    WardLookupId: "1974",
    VDNumber: "76360350",
    RegisteredPopulation: "762",
    VotingStationName: "BOLOPA PRIMARY SCHOOL",
    Address: "HWELESHANENG  MPHAHLELE",
    Latitude: "-24.265",
    Longitude: "29.6262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15908",
    WardLookupId: "1975",
    VDNumber: "76270181",
    RegisteredPopulation: "1720",
    VotingStationName: "TAU-PHUTI SECONDARY SCHOOL",
    Address: "MAEJANE VILLAGE  MAEJANE/LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.3162",
    Longitude: "29.6709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15909",
    WardLookupId: "1975",
    VDNumber: "76270383",
    RegisteredPopulation: "1500",
    VotingStationName: "MATIME SECONDARY SCHOOL",
    Address: "MATIME VILLAGE  MATIME/LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.3145",
    Longitude: "29.6518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15910",
    WardLookupId: "1976",
    VDNumber: "76270103",
    RegisteredPopulation: "792",
    VotingStationName: "LESETSI PRIMARY SCHOOL",
    Address: "LESETSI VILLAGE  PIETERSBURG",
    Latitude: "-24.3528",
    Longitude: "29.7119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15911",
    WardLookupId: "1976",
    VDNumber: "76270147",
    RegisteredPopulation: "1850",
    VotingStationName: "PITJENG YA THUTO PRIMARY SCHOOL",
    Address: "MASHITE  LEPELLE NKUMPI  MPHAHLELE",
    Latitude: "-24.3069",
    Longitude: "29.6838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15912",
    WardLookupId: "1976",
    VDNumber: "76270158",
    RegisteredPopulation: "509",
    VotingStationName: "MADISEI PRIMARY SCHOOL",
    Address: "MASHITE VILLAGE  PIETERSBURG  PIETERSBURG",
    Latitude: "-24.3129",
    Longitude: "29.7237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15913",
    WardLookupId: "1976",
    VDNumber: "76270406",
    RegisteredPopulation: "300",
    VotingStationName: "GWARAGWARA PRIMARY SCHOOL",
    Address: "N/A  NKOTOKWANE VILLAGE  MATHABATHA",
    Latitude: "-24.4128",
    Longitude: "29.7355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15914",
    WardLookupId: "1976",
    VDNumber: "76270440",
    RegisteredPopulation: "42",
    VotingStationName: "MATINKANE RDP HOUSE",
    Address: "MATINKANE  LEPELLE-NKUMPI  MPHAHLELE",
    Latitude: "-24.4454",
    Longitude: "29.7162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15915",
    WardLookupId: "1976",
    VDNumber: "76270451",
    RegisteredPopulation: "258",
    VotingStationName: "SAMPSE PRIMARY SCHOOL",
    Address: "SAMPSE  LEPELLE- NKUMPI  GA-MPHAHLELE",
    Latitude: "-24.3313",
    Longitude: "29.7349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15916",
    WardLookupId: "1977",
    VDNumber: "76270248",
    RegisteredPopulation: "1339",
    VotingStationName: "MPHACHUE SECONDARY SCHOOL",
    Address: "MOGODI VILLAGE  MOGODI/LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.2704",
    Longitude: "29.5682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15917",
    WardLookupId: "1977",
    VDNumber: "76270327",
    RegisteredPopulation: "545",
    VotingStationName: "MOLAPO MATEBELE PRIMARY SCHOOL",
    Address: "STAANPLAAS VILLAGE  LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.2406",
    Longitude: "29.5275",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15918",
    WardLookupId: "1977",
    VDNumber: "76270507",
    RegisteredPopulation: "534",
    VotingStationName: "MMAKOTO FOOTBALL GROUND (TENT)",
    Address: "MMAKOTO  MOGODI  MPHAHLELE",
    Latitude: "-24.2722",
    Longitude: "29.5768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15919",
    WardLookupId: "1977",
    VDNumber: "76270518",
    RegisteredPopulation: "231",
    VotingStationName: "SEROKOLOSENYANE PRIMARY SCHOOL",
    Address: "LEPELLE-NKUPI  ",
    Latitude: "-24.2534",
    Longitude: "29.5447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15920",
    WardLookupId: "1977",
    VDNumber: "76270552",
    RegisteredPopulation: "345",
    VotingStationName: "SEKURWANENG PRIMARY SCHOOL",
    Address: "MOGODI  LEPELLE-NKUMPI  MPHAHLELE",
    Latitude: "-24.2822",
    Longitude: "29.5745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15921",
    WardLookupId: "1977",
    VDNumber: "76270563",
    RegisteredPopulation: "305",
    VotingStationName: "MOKOPU CRECHE",
    Address: "MOOIPLAAS  LEPELLE-NKUMPI  MPHAHLELE",
    Latitude: "-24.2329",
    Longitude: "29.5337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15922",
    WardLookupId: "1977",
    VDNumber: "76360754",
    RegisteredPopulation: "769",
    VotingStationName: "SEROBANENG PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  SEROBANENG VILLAGE  MPHAHLELE",
    Latitude: "-24.2622",
    Longitude: "29.6084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15923",
    WardLookupId: "1977",
    VDNumber: "76361036",
    RegisteredPopulation: "321",
    VotingStationName: "HLABIRWA AND MONARE SHOP TENT",
    Address: "HWELESHANENG  LEPELLE NKUMPI  MPHAHLELE",
    Latitude: "-24.2605",
    Longitude: "29.6324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15924",
    WardLookupId: "1978",
    VDNumber: "76360103",
    RegisteredPopulation: "1212",
    VotingStationName: "MATHABATHA HALL",
    Address: "MOSHATE  MATHABATHA  PIETERSBURG",
    Latitude: "-24.2042",
    Longitude: "29.8712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15925",
    WardLookupId: "1978",
    VDNumber: "76360114",
    RegisteredPopulation: "659",
    VotingStationName: "DIKGEU PRIMARY SCHOOL",
    Address: "MADIKELENG  MATHABATHA  PIETERSBURG",
    Latitude: "-24.227",
    Longitude: "29.8265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15926",
    WardLookupId: "1978",
    VDNumber: "76360136",
    RegisteredPopulation: "897",
    VotingStationName: "MATSIMELA HIGH SCHOOL",
    Address: "MAKGOPA  MATHABATHA  PIETERSBURG",
    Latitude: "-24.2375",
    Longitude: "29.8078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15927",
    WardLookupId: "1978",
    VDNumber: "76360675",
    RegisteredPopulation: "321",
    VotingStationName: "LEGOBOLE PRIMARY SCHOOL",
    Address: "MASESELENG  MATHABATHA  MATHABATHA",
    Latitude: "-24.2269",
    Longitude: "29.8076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15928",
    WardLookupId: "1978",
    VDNumber: "76360732",
    RegisteredPopulation: "554",
    VotingStationName: "MOSHABI PRIMARY SCHOOL",
    Address:
      "MATHABATHA VILLAGE  (LIM355 - LEPELE-NKUMPI [LEBOWAKGOMO])   (LIM355 - LEPELE-NKUMPI [MATHABATHA",
    Latitude: "-24.2382",
    Longitude: "29.8315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15929",
    WardLookupId: "1978",
    VDNumber: "76360743",
    RegisteredPopulation: "372",
    VotingStationName: "BODUTLOLO PRIMARY SCHOOL",
    Address: "BODUTLOLO  LEPELLE-NKUMPI  MATHABATHA",
    Latitude: "-24.2669",
    Longitude: "29.7703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15930",
    WardLookupId: "1979",
    VDNumber: "76360057",
    RegisteredPopulation: "724",
    VotingStationName: "KOMANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MAMPA VILLAGE  MAFEFE",
    Latitude: "-24.1259",
    Longitude: "30.1049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15931",
    WardLookupId: "1979",
    VDNumber: "76360079",
    RegisteredPopulation: "983",
    VotingStationName: "MAHLATJANE HALL",
    Address: "MAHLATJANE  MAFEFE  PIETERSBURG",
    Latitude: "-24.187",
    Longitude: "30.0629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15932",
    WardLookupId: "1979",
    VDNumber: "76360080",
    RegisteredPopulation: "288",
    VotingStationName: "MAMONGAO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  RAMONWANE  MAFEFE",
    Latitude: "-24.1764",
    Longitude: "29.9997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15933",
    WardLookupId: "1979",
    VDNumber: "76360091",
    RegisteredPopulation: "684",
    VotingStationName: "TLOUATIBA PRIMARY SCHOOL",
    Address: "MPHAANENG  MATHABATHA  PIETERSBURG",
    Latitude: "-24.2106",
    Longitude: "29.9168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15934",
    WardLookupId: "1979",
    VDNumber: "76360686",
    RegisteredPopulation: "150",
    VotingStationName: "MAHUNE  PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MASHUSHU VILLAGE  NOKOTLOU",
    Latitude: "-24.1128",
    Longitude: "30.1212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15935",
    WardLookupId: "1979",
    VDNumber: "76360901",
    RegisteredPopulation: "420",
    VotingStationName: "MOKOLOBANE PRIMARY SCHOOL",
    Address: "SUCCESS VILLAGE  MATHABATHA  MATHABATHA",
    Latitude: "-24.2164",
    Longitude: "29.8888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15936",
    WardLookupId: "1979",
    VDNumber: "76360912",
    RegisteredPopulation: "261",
    VotingStationName: "SHADIBJENG CRECHE",
    Address: "SHADIBJENG  LEPELLE-NKUMPI  MAFEFE",
    Latitude: "-24.1734",
    Longitude: "30.0441",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15937",
    WardLookupId: "1980",
    VDNumber: "76360013",
    RegisteredPopulation: "522",
    VotingStationName: "PHOSANE SECONDARY SCHOOL",
    Address: "MOTSANE   MAFEFE",
    Latitude: "-24.2851",
    Longitude: "30.2099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15938",
    WardLookupId: "1980",
    VDNumber: "76360024",
    RegisteredPopulation: "776",
    VotingStationName: "KGALEMA HIGH SCHOOL",
    Address: "NGWANEME  MAFEFE  PIETERSBURG",
    Latitude: "-24.2007",
    Longitude: "30.0991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15939",
    WardLookupId: "1980",
    VDNumber: "76360035",
    RegisteredPopulation: "836",
    VotingStationName: "MAFEFE TRIBAL HALL",
    Address: "MOSATE  MAFEFE  PIETERSBURG",
    Latitude: "-24.2037",
    Longitude: "30.1131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15940",
    WardLookupId: "1980",
    VDNumber: "76360068",
    RegisteredPopulation: "599",
    VotingStationName: "MASHABASHABA PRIMARY SCHOOL",
    Address: "KAPA  MAFEFE  PIETERSBURG",
    Latitude: "-24.1771",
    Longitude: "30.0991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15941",
    WardLookupId: "1980",
    VDNumber: "76360585",
    RegisteredPopulation: "425",
    VotingStationName: "PHOPHEDI SECONDARY SCHOOL",
    Address: "DUBLIN  MAFEFE  PIETERSBURG",
    Latitude: "-24.2633",
    Longitude: "30.1527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15942",
    WardLookupId: "1980",
    VDNumber: "76360833",
    RegisteredPopulation: "206",
    VotingStationName: "MAREDI 2 PRIMARY SCHOOL",
    Address: "MAREDI  MAFEFE  MAFEFE",
    Latitude: "-24.2903",
    Longitude: "30.1583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15943",
    WardLookupId: "1980",
    VDNumber: "76360844",
    RegisteredPopulation: "190",
    VotingStationName: "MATALANE PRIMARY SCHOOL",
    Address: "MALAKABANENG  MAFEFE",
    Latitude: "-24.1907",
    Longitude: "30.0896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15944",
    WardLookupId: "1980",
    VDNumber: "76360978",
    RegisteredPopulation: "174",
    VotingStationName: "MANKELE PRIMARY SCHOOL",
    Address: "MANKELE  LEPELLE-NKUMPI  MAFEFE",
    Latitude: "-24.2901",
    Longitude: "30.3103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15945",
    WardLookupId: "1981",
    VDNumber: "76270057",
    RegisteredPopulation: "1171",
    VotingStationName: "BOSCHPLAAS PRIMARY SCHOOL",
    Address: "TOOSENG VILLAGE  LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.4503",
    Longitude: "29.5634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15946",
    WardLookupId: "1981",
    VDNumber: "76270080",
    RegisteredPopulation: "684",
    VotingStationName: "TJIANE PRIMARY SCHOOL",
    Address: "TJIANE VILLAGE  LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.4144",
    Longitude: "29.6057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15947",
    WardLookupId: "1981",
    VDNumber: "76270091",
    RegisteredPopulation: "1501",
    VotingStationName: "PHAUWE SENIOR SECONDARY SCHOOL",
    Address: "LEKURUNG/MALEMATI  LEKURUNG/LEBOWAKGOMO  PIETERSBURG",
    Latitude: "-24.392",
    Longitude: "29.6462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15948",
    WardLookupId: "3984",
    VDNumber: "76460047",
    RegisteredPopulation: "704",
    VotingStationName: "THABAZIMBI TVET",
    Address: "REGOROGILE  WATERBERG  THABAZIMBI",
    Latitude: "-24.5729",
    Longitude: "27.3823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15949",
    WardLookupId: "3984",
    VDNumber: "76500019",
    RegisteredPopulation: "164",
    VotingStationName: "GRENS WINKEL",
    Address: "THABAZIMBI  ",
    Latitude: "-24.5553",
    Longitude: "26.5475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15950",
    WardLookupId: "3984",
    VDNumber: "76500031",
    RegisteredPopulation: "130",
    VotingStationName: "LETSWAI METSI PRIMARY SCHOOL",
    Address:
      "NO STREET NAME AVAILABLE  THABAZIMBI [THABAZIMBI]  THABAZIMBI [THABAZIMBI]",
    Latitude: "-24.402",
    Longitude: "26.901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15951",
    WardLookupId: "3984",
    VDNumber: "76500042",
    RegisteredPopulation: "295",
    VotingStationName: "SEKGWENG PRIMARY SCHOOL",
    Address: "FARM ROOIBOKKRAAL  THABAZIMBI  THABAZIMBI",
    Latitude: "-24.2133",
    Longitude: "26.9133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15952",
    WardLookupId: "3984",
    VDNumber: "76500053",
    RegisteredPopulation: "256",
    VotingStationName: "CANTERBURY TENT",
    Address: "CANTERBURY FARM  THABAZIMBI  THABAZIMBI",
    Latitude: "-23.9868",
    Longitude: "27.175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15953",
    WardLookupId: "3984",
    VDNumber: "76500075",
    RegisteredPopulation: "684",
    VotingStationName: "GROENVLEI SECONDARY SCHOOL",
    Address: "THABAZIMBI/SENTRUM ROAD  SENTRUM  WATERBERG",
    Latitude: "-24.2599",
    Longitude: "27.3242",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15954",
    WardLookupId: "3984",
    VDNumber: "76500086",
    RegisteredPopulation: "398",
    VotingStationName: "KAALDRAAI TENT",
    Address: "KAALDRAAI  THABAZIMBI  THABAZIMBI",
    Latitude: "-24.5851",
    Longitude: "27.2312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15955",
    WardLookupId: "3984",
    VDNumber: "76500277",
    RegisteredPopulation: "294",
    VotingStationName: "RABOGALE PRIMARY SCHOOL",
    Address:
      "NO STREET ADDRESS AVAILABLE  THABAZIMBI [THABAZIMBI]  THABAZIMBI [THABAZIMBI]",
    Latitude: "-24.4395",
    Longitude: "27.1109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15956",
    WardLookupId: "3984",
    VDNumber: "76500390",
    RegisteredPopulation: "34",
    VotingStationName: "HEUNINGVLEI PRIMARY SCHOOL",
    Address: "HONINGVELI FARM  THABAZIMBI  THABAZIMBI",
    Latitude: "-24.3255",
    Longitude: "27.3928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15957",
    WardLookupId: "3984",
    VDNumber: "76500402",
    RegisteredPopulation: "98",
    VotingStationName: "KESARONA PRIMARY SCHOOL",
    Address: "THABA THOLO FARM  THABAZIMBI  THABAZIMBI",
    Latitude: "-24.5729",
    Longitude: "27.3099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15958",
    WardLookupId: "3985",
    VDNumber: "76460058",
    RegisteredPopulation: "1419",
    VotingStationName: "HOÃ‹RSKOOL FRIKKIE MEYER",
    Address: "THABAZIMBI  ",
    Latitude: "-24.5911",
    Longitude: "27.4151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15959",
    WardLookupId: "3985",
    VDNumber: "76460081",
    RegisteredPopulation: "1015",
    VotingStationName: "EXT 8 TENT",
    Address: "STEENBOK  EXT 8  THABAZIMBI",
    Latitude: "-24.5815",
    Longitude: "27.4163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15960",
    WardLookupId: "3985",
    VDNumber: "76500097",
    RegisteredPopulation: "486",
    VotingStationName: "SPITSKOP SPECIAL NEEDS SCHOOL",
    Address: "SPITSKOP  THABAZIMBI  THABAZIMBI",
    Latitude: "-24.5846",
    Longitude: "27.4552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15961",
    WardLookupId: "3985",
    VDNumber: "76500266",
    RegisteredPopulation: "143",
    VotingStationName: "ZIMTHABI LODGE",
    Address: "ZIMTHABI  THABAZIMBI  THABAZIMBI",
    Latitude: "-24.5654",
    Longitude: "27.6043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15962",
    WardLookupId: "3985",
    VDNumber: "76500378",
    RegisteredPopulation: "140",
    VotingStationName: "SEKELBOS KONTANT WINKEL",
    Address: "MARAKELE  THABAZIMBI  THABAZIMBI",
    Latitude: "-24.5238",
    Longitude: "27.5175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15963",
    WardLookupId: "3985",
    VDNumber: "76540181",
    RegisteredPopulation: "734",
    VotingStationName: "ROOIBERG THABA KHIBIDU SCHOOL",
    Address: "ROOIBERG  ROOIBERG / THABAZIMBI  WATERBERG",
    Latitude: "-24.7808",
    Longitude: "27.7287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15964",
    WardLookupId: "3985",
    VDNumber: "76540259",
    RegisteredPopulation: "606",
    VotingStationName: "ROOIBERG COMMUNITY HALL",
    Address: "ROOIBERG  THABAZIMBI  THABAZIMBI",
    Latitude: "-24.7854",
    Longitude: "27.7367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15965",
    WardLookupId: "3986",
    VDNumber: "76500154",
    RegisteredPopulation: "3654",
    VotingStationName: "ZWARTKOP CHROME MINE SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  FARM ZWARTKOP  THABAZIMBI",
    Latitude: "-24.7854",
    Longitude: "27.2894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15966",
    WardLookupId: "3986",
    VDNumber: "76500323",
    RegisteredPopulation: "1606",
    VotingStationName: "SMASHBLOCK CWP COMMUNITY CENTRE",
    Address: "SMASHBLOCK  THABAZIMBI  THABAZIMBI",
    Latitude: "-24.7819",
    Longitude: "27.2877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15967",
    WardLookupId: "3987",
    VDNumber: "76500109",
    RegisteredPopulation: "251",
    VotingStationName: "THABANG YOUTH CENTRE",
    Address: "NO STREET ADDRESS  KLIPGAT FARM  THABAZIMBI",
    Latitude: "-24.6657",
    Longitude: "27.4374",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15968",
    WardLookupId: "3987",
    VDNumber: "76500110",
    RegisteredPopulation: "549",
    VotingStationName: "MUNICIPAL OFFICE  LEEUPOORT HOLIDAY TOWNSHIP",
    Address: "ERF NR 1  THABAZIMBI  WATERBERG",
    Latitude: "-24.9148",
    Longitude: "27.6353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15969",
    WardLookupId: "3987",
    VDNumber: "76500121",
    RegisteredPopulation: "735",
    VotingStationName: "KOEDOESKOP PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  KOEDOESKOP  THABAZIMBI",
    Latitude: "-24.8834",
    Longitude: "27.5184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15970",
    WardLookupId: "3987",
    VDNumber: "76500233",
    RegisteredPopulation: "242",
    VotingStationName: "MAMORAKA PRIMARY SCHOOL",
    Address:
      "NO STREET ADDRESS AVAILABLE  THABAZIMBI [THABAZIMBI]  THABAZIMBI [THABAZIMBI]",
    Latitude: "-24.7139",
    Longitude: "27.4409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15971",
    WardLookupId: "3987",
    VDNumber: "76500288",
    RegisteredPopulation: "158",
    VotingStationName: "MADOING",
    Address: "BELLE VIEW  THABAZIMBI  THABAZIMBI",
    Latitude: "-25.0519",
    Longitude: "27.528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15972",
    WardLookupId: "3987",
    VDNumber: "76500334",
    RegisteredPopulation: "480",
    VotingStationName: "TSHEPONG CRECHE",
    Address: "RAPHUTI  THABAZIMBI  THABAZIMBI",
    Latitude: "-24.9116",
    Longitude: "27.6366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15973",
    WardLookupId: "3987",
    VDNumber: "76500345",
    RegisteredPopulation: "272",
    VotingStationName: "NTSWE TSHIPI COMMUNITY HALL",
    Address: "IPELEGENG  THABAZIMBI",
    Latitude: "-24.6",
    Longitude: "27.4",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15974",
    WardLookupId: "3987",
    VDNumber: "76540169",
    RegisteredPopulation: "265",
    VotingStationName: "LEEUPOORT ONTSPANNINGSENTRUM (C-MYN)",
    Address: "LEEUPOORT ONTSPANNING SENTRUM  LEEUPOORT /THABAZIMBI  WATERBERG",
    Latitude: "-24.9073",
    Longitude: "27.7281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15975",
    WardLookupId: "3987",
    VDNumber: "76540215",
    RegisteredPopulation: "491",
    VotingStationName: "KROMDRAAI MARULA HALL",
    Address: "N/A  KROMDRAAI  THABAZIMBI",
    Latitude: "-24.9606",
    Longitude: "27.878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15976",
    WardLookupId: "3988",
    VDNumber: "76500020",
    RegisteredPopulation: "538",
    VotingStationName: "DWAALBOOM PRIMARY SCHOOL",
    Address:
      "NO STREET NAME AVAILABLE  THABAZIMBI [THABAZIMBI]  THABAZIMBI [THABAZIMBI]",
    Latitude: "-24.7269",
    Longitude: "26.8091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15977",
    WardLookupId: "3988",
    VDNumber: "76500165",
    RegisteredPopulation: "1370",
    VotingStationName: "SWARTKLIP NUMINE HALL",
    Address: "ANGLO PLATINUM MINE  THABAZIMBI  WATERBERG",
    Latitude: "-24.9405",
    Longitude: "27.1541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15978",
    WardLookupId: "3988",
    VDNumber: "76500222",
    RegisteredPopulation: "367",
    VotingStationName: "SWARTKLIP B HOSTEL RICHARD SHAFT (TENT)",
    Address: "N/A  SWARTKLIP MINE  THABAZIMBI",
    Latitude: "-24.9613",
    Longitude: "27.1523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15979",
    WardLookupId: "3988",
    VDNumber: "76500255",
    RegisteredPopulation: "90",
    VotingStationName: "DWAALBOOM - NOORD BRABAND SKOOL",
    Address:
      "NO STREET ADDRESS AVAILABLE  THABAZIMBI [THABAZIMBI]  THABAZIMBI [THABAZIMBI]",
    Latitude: "-24.6482",
    Longitude: "26.7821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15980",
    WardLookupId: "3988",
    VDNumber: "76500312",
    RegisteredPopulation: "195",
    VotingStationName: "ALTYD WAKER GENERAL DEALER",
    Address: "MAROELASFONTEIN  WATERBERG  THABAZIMBI",
    Latitude: "-24.7854",
    Longitude: "27.1995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15981",
    WardLookupId: "3989",
    VDNumber: "76500143",
    RegisteredPopulation: "1318",
    VotingStationName: "NORTHAM PLATINUM BLIKSAAL",
    Address: "AMANDELBULT  THABAZIMBI",
    Latitude: "-24.7833",
    Longitude: "27.4065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15982",
    WardLookupId: "3989",
    VDNumber: "76500200",
    RegisteredPopulation: "996",
    VotingStationName: "DITSHABA HOSTEL TAXI RANK (TENT)",
    Address: "N/A  AMANDELBULT MINE/ANGLO PLATINUM  THABAZIMBI",
    Latitude: "-24.7602",
    Longitude: "27.3878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15983",
    WardLookupId: "3989",
    VDNumber: "76500367",
    RegisteredPopulation: "210",
    VotingStationName: "THEKGANANG TECHNICAL HIGH SCHOOL",
    Address: "RETHABILE  WATERBERG  THABAZIMBI",
    Latitude: "-24.7975",
    Longitude: "27.3407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15984",
    WardLookupId: "3990",
    VDNumber: "76500299",
    RegisteredPopulation: "3760",
    VotingStationName: "KRAUSE PRIMARY SCHOOL",
    Address:
      "NO STREET ADDRESS AVAILABLE  THABAZIMBI [THABAZIMBI]  THABAZIMBI [THABAZIMBI]",
    Latitude: "-24.97",
    Longitude: "27.2707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15985",
    WardLookupId: "3990",
    VDNumber: "76500413",
    RegisteredPopulation: "295",
    VotingStationName: "DISNEY JUNIOR DAY CARE CENTRE",
    Address: "NORTHAM  THABAZIMBI  THABAZIMBI",
    Latitude: "-24.9658",
    Longitude: "27.2596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15986",
    WardLookupId: "3990",
    VDNumber: "76500424",
    RegisteredPopulation: "681",
    VotingStationName: "BUSY BEE DAY CARE CENTRE",
    Address: "NORTHAM  THABAZIMBI  THABAZIMBI",
    Latitude: "-24.9725",
    Longitude: "27.2661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15987",
    WardLookupId: "3991",
    VDNumber: "76500132",
    RegisteredPopulation: "1511",
    VotingStationName: "LAPA NORTHAM",
    Address: "THABAZIMBI [THABAZIMBI]  ",
    Latitude: "-24.9528",
    Longitude: "27.2679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15988",
    WardLookupId: "3991",
    VDNumber: "76500176",
    RegisteredPopulation: "1208",
    VotingStationName: "TUMELA TAXI RANK (TENT)",
    Address: "NULL  THABAZIMBI  AMANDELBULT MINE",
    Latitude: "-24.8238",
    Longitude: "27.2843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15989",
    WardLookupId: "3991",
    VDNumber: "76500356",
    RegisteredPopulation: "696",
    VotingStationName: "SMASHBLOCK SPORTS GROUND TENT",
    Address: "SMASHBLOCK INFORMAL SETTLEMENT  WATERBERG  THABAZIMBI",
    Latitude: "-24.7861",
    Longitude: "27.2752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15990",
    WardLookupId: "3991",
    VDNumber: "76500389",
    RegisteredPopulation: "376",
    VotingStationName: "EL-SHADDAI MINISTRIES (TENT)",
    Address: "NORTHAM EXT 6  THABAZIMBI  THABAZIMBI",
    Latitude: "-24.9526",
    Longitude: "27.2578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15991",
    WardLookupId: "3992",
    VDNumber: "76460115",
    RegisteredPopulation: "246",
    VotingStationName: "LAERSKOOL THABAZIMBI",
    Address: "THABAZIMBI  WATERBERG  THABAZIMBI",
    Latitude: "-24.5925",
    Longitude: "27.4068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15992",
    WardLookupId: "3992",
    VDNumber: "76500198",
    RegisteredPopulation: "895",
    VotingStationName: "REGOROGILE 2 COMMUNITY HALL THABAZIMBI",
    Address: "REGOROGILE 2 / THABAZIMBI  WATERBERG",
    Latitude: "-24.5988",
    Longitude: "27.3956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15993",
    WardLookupId: "3992",
    VDNumber: "76500244",
    RegisteredPopulation: "2767",
    VotingStationName: "SASSA THABAZIMBI",
    Address: "REGOROGILE  THABAZIMBI  THABAZIMBI",
    Latitude: "-24.5909",
    Longitude: "27.3915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15994",
    WardLookupId: "3993",
    VDNumber: "76460025",
    RegisteredPopulation: "2290",
    VotingStationName: "REGOROGILE 1 COMMUNITY HALL THABAZIMBI",
    Address: "BOITSHOKO CRESCENT  REGOROGILE 1 / THABAZIMBI  WATERBERG",
    Latitude: "-24.5839",
    Longitude: "27.3842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15995",
    WardLookupId: "3993",
    VDNumber: "76460036",
    RegisteredPopulation: "1203",
    VotingStationName: "MABOGOPEDI SECONDARY SCHOOL",
    Address: "SHAI DRIVE  THABAZIMBI [THABAZIMBI]  THABAZIMBI [THABAZIMBI]",
    Latitude: "-24.5767",
    Longitude: "27.3817",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15996",
    WardLookupId: "3993",
    VDNumber: "76460092",
    RegisteredPopulation: "462",
    VotingStationName: "DEO GLORIA PRIMARY SCHOOL",
    Address: "MONARENG STREET  REGOROGILE  THABAZIMBI",
    Latitude: "-24.5801",
    Longitude: "27.3869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15997",
    WardLookupId: "3994",
    VDNumber: "76500187",
    RegisteredPopulation: "843",
    VotingStationName: "AMANDELBULT MLANJE SPORTS GROUND (TENT)",
    Address: "NULL  THABAZIMBI [THABAZIMBI]  THABAZIMBI [THABAZIMBI]",
    Latitude: "-24.7994",
    Longitude: "27.329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15998",
    WardLookupId: "3994",
    VDNumber: "76500211",
    RegisteredPopulation: "1638",
    VotingStationName: "NORTHAM PLATINUM COMMUNITY HALL",
    Address: "N/A  NORTHAM MINE/ANGL0 PLATINUM  THABAZIMBI",
    Latitude: "-24.8283",
    Longitude: "27.3357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "15999",
    WardLookupId: "3995",
    VDNumber: "76460014",
    RegisteredPopulation: "1585",
    VotingStationName: "THABAZIMBI DEPARTMENT OF LABOUR",
    Address: "CNR EILAND & STEENBOOK  THABAZIMBI  THABAZIMBI",
    Latitude: "-24.5877",
    Longitude: "27.408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16000",
    WardLookupId: "3995",
    VDNumber: "76460070",
    RegisteredPopulation: "522",
    VotingStationName: "THABAZIMBI SHOWGROUND",
    Address: "THABAZIMBI  ",
    Latitude: "-24.5821",
    Longitude: "27.4086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16001",
    WardLookupId: "3995",
    VDNumber: "76460104",
    RegisteredPopulation: "1424",
    VotingStationName: "EXTENTION 4 (TENT)",
    Address: "EXTENTION 4  REGOROGILE  THABAZIMBI",
    Latitude: "-24.5783",
    Longitude: "27.3908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16002",
    WardLookupId: "3995",
    VDNumber: "76460126",
    RegisteredPopulation: "534",
    VotingStationName: "REGOROGILE SPORTS FOR ALL",
    Address: "REGOROGILE  THABAZIMBI  THABAZIMBI",
    Latitude: "-24.5807",
    Longitude: "27.3833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16003",
    WardLookupId: "1982",
    VDNumber: "76420043",
    RegisteredPopulation: "3990",
    VotingStationName: "MARAPONG DEVELOPMENT CENTRE",
    Address: "1093 TLOU STREET  LEPHALALE  LEPHALALE",
    Latitude: "-23.657",
    Longitude: "27.634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16004",
    WardLookupId: "1982",
    VDNumber: "76420054",
    RegisteredPopulation: "3044",
    VotingStationName: "DITHEKU PRIMARY SCHOOL",
    Address: "RAMAHLODI STREET  LEPHALALE  LEPHALALE",
    Latitude: "-23.6558",
    Longitude: "27.6339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16005",
    WardLookupId: "1983",
    VDNumber: "76420010",
    RegisteredPopulation: "4443",
    VotingStationName: "NELSONSKOP PRIMARY SCHOOL",
    Address: "522 MOHWILIRI STREET  MARAPONG LOCATION  LEPHALALE",
    Latitude: "-23.6574",
    Longitude: "27.6265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16006",
    WardLookupId: "1983",
    VDNumber: "76420098",
    RegisteredPopulation: "593",
    VotingStationName: "CONTRACTORS VILLAGE",
    Address: "MARAPONG  LEPHALALE  MARAPONG",
    Latitude: "-23.6591",
    Longitude: "27.6003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16007",
    WardLookupId: "1983",
    VDNumber: "76420100",
    RegisteredPopulation: "1856",
    VotingStationName: "PHEGELELO HIGH SCHOOL",
    Address: "MOSETLHA  MARAPONG  LEPHALALE",
    Latitude: "-23.6516",
    Longitude: "27.6176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16008",
    WardLookupId: "1984",
    VDNumber: "76420076",
    RegisteredPopulation: "2793",
    VotingStationName: "MABALANE SELEKA HIGH SCHOOL",
    Address: "100 RIETSPRUIT  LEPHALALE  LEPHALALE",
    Latitude: "-23.6931",
    Longitude: "27.6815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16009",
    WardLookupId: "1984",
    VDNumber: "76490028",
    RegisteredPopulation: "939",
    VotingStationName: "LESEDI TSHUKUDU THUSONG CENTRE",
    Address: "STEENBOKPAN  LEPHALALE  LEPHALALE",
    Latitude: "-23.7188",
    Longitude: "27.2776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16010",
    WardLookupId: "1984",
    VDNumber: "76490039",
    RegisteredPopulation: "271",
    VotingStationName: "AFGUNS",
    Address: "AFGUNS   FARM  LEPHALALE  ELLISRAS",
    Latitude: "-23.83",
    Longitude: "27.6758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16011",
    WardLookupId: "1984",
    VDNumber: "76490040",
    RegisteredPopulation: "215",
    VotingStationName: "LIMPOPODRAAI HALL",
    Address: "LIMPOPO DRAAI   FARM  LEPHALALE  ELLISRAS",
    Latitude: "-23.3716",
    Longitude: "27.5897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16012",
    WardLookupId: "1984",
    VDNumber: "76490309",
    RegisteredPopulation: "42",
    VotingStationName: "BOTSHELONG TENT",
    Address: "GROOTLAAGTE FARM  LEPHALALE  LEPHALALE",
    Latitude: "-23.8755",
    Longitude: "27.3513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16013",
    WardLookupId: "1984",
    VDNumber: "76490310",
    RegisteredPopulation: "88",
    VotingStationName: "MOTLHOPI PRIMARY SCHOOL (TENT)",
    Address: "KRUISPAD FARM  STEENBOKPAN  LEPHALALE",
    Latitude: "-23.6676",
    Longitude: "27.0982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16014",
    WardLookupId: "1984",
    VDNumber: "76490321",
    RegisteredPopulation: "63",
    VotingStationName: "MATSHANENG  (TENT)",
    Address: "DOORNLAAGTE FARM  LEPHALALE  LEPHALALE",
    Latitude: "-23.7786",
    Longitude: "27.2967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16015",
    WardLookupId: "1984",
    VDNumber: "76490332",
    RegisteredPopulation: "73",
    VotingStationName: "TENT AT MANCHESTER FARM",
    Address: "MANCHESTER FARM  LEPHALALE  LEPHALALE",
    Latitude: "-23.4296",
    Longitude: "27.3485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16016",
    WardLookupId: "1985",
    VDNumber: "76420032",
    RegisteredPopulation: "2218",
    VotingStationName: "MOGOL CLUB HALL",
    Address: "1 GEORGE WELLS  AVENUE  ONVERWACHT  ELLISRAS",
    Latitude: "-23.685",
    Longitude: "27.6949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16017",
    WardLookupId: "1985",
    VDNumber: "76420065",
    RegisteredPopulation: "1133",
    VotingStationName: "LEPHALALE MUNICIPALITY LAPA",
    Address: "CNR OF DOUWATER AND JOE SLOVO STR  LEPHALALE  LEPHALALE ",
    Latitude: "-23.6869",
    Longitude: "27.6966",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16018",
    WardLookupId: "1985",
    VDNumber: "76420111",
    RegisteredPopulation: "519",
    VotingStationName: "LIVING LETTERS MINISTRIES",
    Address: "O R TAMBO   LEPHALALE  LEPHALALE",
    Latitude: "-23.6822",
    Longitude: "27.7403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16019",
    WardLookupId: "1986",
    VDNumber: "76490062",
    RegisteredPopulation: "1058",
    VotingStationName: "TENT AT MAMOJELA PARK",
    Address: "MAMOJELA PARK  LEPHALALE  LEPHALALE",
    Latitude: "-23.6432",
    Longitude: "27.7514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16020",
    WardLookupId: "1986",
    VDNumber: "76490073",
    RegisteredPopulation: "193",
    VotingStationName: "TLHOTSONG WINKEL",
    Address: "RHENOSTERHOEK  FARM  LEPHALALE  LEPHALALE",
    Latitude: "-23.8641",
    Longitude: "27.7953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16021",
    WardLookupId: "1986",
    VDNumber: "76490084",
    RegisteredPopulation: "95",
    VotingStationName: "PLAAS MAKILAKA",
    Address: "PLAAS MAKILLAKA  LEPHALALE  LEPHALALE",
    Latitude: "-23.442",
    Longitude: "27.8432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16022",
    WardLookupId: "1986",
    VDNumber: "76490185",
    RegisteredPopulation: "1255",
    VotingStationName: "RADIBAKI PRIMARY SCHOOL",
    Address: "KAULETSI VILLAGE  LEPHALALE  ELLISRAS",
    Latitude: "-23.2439",
    Longitude: "27.9346",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16023",
    WardLookupId: "1986",
    VDNumber: "76490231",
    RegisteredPopulation: "836",
    VotingStationName: "MOKONE NKWENOKO SCHOOL",
    Address: "DITLOUNG VILLAGE  LEPHALALE  ELLISRAS",
    Latitude: "-23.4165",
    Longitude: "28.0511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16024",
    WardLookupId: "1986",
    VDNumber: "76490455",
    RegisteredPopulation: "956",
    VotingStationName: "TLOTLANANG PRE SCHOOL",
    Address: "STEVE BIKO  LEPHALALE  SHONGOANE",
    Latitude: "-23.5741",
    Longitude: "28.0837",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16025",
    WardLookupId: "1986",
    VDNumber: "76500064",
    RegisteredPopulation: "164",
    VotingStationName: "HOOPDAL SCHOOL",
    Address: "KAREEHOEK FARM  THABAZIMBI  ELLISRAS",
    Latitude: "-24.2704",
    Longitude: "27.4866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16026",
    WardLookupId: "1986",
    VDNumber: "76530090",
    RegisteredPopulation: "158",
    VotingStationName: "MELKRIVIER HALL",
    Address: "MUISVOGELKRAAL FARM  VAALWATER  ELLISRAS",
    Latitude: "-23.9894",
    Longitude: "28.3713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16027",
    WardLookupId: "1986",
    VDNumber: "76530102",
    RegisteredPopulation: "187",
    VotingStationName: "MARELETSANE (TENT)",
    Address: "MARELETSANE  LEPHALALE  LEPHALALE",
    Latitude: "-24.1958",
    Longitude: "27.9259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16028",
    WardLookupId: "1986",
    VDNumber: "76530113",
    RegisteredPopulation: "207",
    VotingStationName: "GA-PHAKULA COMBINED SCHOOL",
    Address: "BULGERIVIER FARM  VAALWATER  ELLISRAS",
    Latitude: "-24.1242",
    Longitude: "27.6969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16029",
    WardLookupId: "1986",
    VDNumber: "76530124",
    RegisteredPopulation: "132",
    VotingStationName: "LAPOLOGANG (TENT)",
    Address: "HERMANUSDORING  LEPHALALE  LEPHALALE",
    Latitude: "-24.1173",
    Longitude: "27.8189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16030",
    WardLookupId: "1986",
    VDNumber: "76530135",
    RegisteredPopulation: "203",
    VotingStationName: "VISGAT WINKEL",
    Address: "STEENBOKFONTEIN FARM  VAALWATER  ELLISRAS",
    Latitude: "-24.0508",
    Longitude: "28.0883",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16031",
    WardLookupId: "1987",
    VDNumber: "76490118",
    RegisteredPopulation: "1661",
    VotingStationName: "SHONGOANE COMMUNITY HALL",
    Address: "SHONGOANE 1  LEPHALALE  ELLISRAS",
    Latitude: "-23.5676",
    Longitude: "28.1019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16032",
    WardLookupId: "1987",
    VDNumber: "76490163",
    RegisteredPopulation: "1462",
    VotingStationName: "MATSHWARA SCHOOL",
    Address: "SHONGOANE 1  SHONGOANE VILLAGE 1  ELLISRAS",
    Latitude: "-23.5772",
    Longitude: "28.1032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16033",
    WardLookupId: "1987",
    VDNumber: "76510470",
    RegisteredPopulation: "909",
    VotingStationName: "MMERWA PRIMARY SCHOOL",
    Address: "GA PHAHLADIRA  VILLAGE  LEPHALALE  MOGALAKWENA",
    Latitude: "-23.5745",
    Longitude: "28.1291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16034",
    WardLookupId: "1988",
    VDNumber: "76490141",
    RegisteredPopulation: "1165",
    VotingStationName: "RAMOJAPUDI SCHOOL",
    Address: "MMATLADI VILLAGE,SHONGOANE 2  LEPHALALE  ELLISRAS",
    Latitude: "-23.5675",
    Longitude: "28.1233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16035",
    WardLookupId: "1988",
    VDNumber: "76490242",
    RegisteredPopulation: "484",
    VotingStationName: "MORAKOLO SCHOOL",
    Address: "MMATLADI VILLAGE  SHONGOANE 2  ELLISRAS",
    Latitude: "-23.5607",
    Longitude: "28.1325",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16036",
    WardLookupId: "1988",
    VDNumber: "76490264",
    RegisteredPopulation: "1798",
    VotingStationName: "BATLHALERWA SCHOOL",
    Address: "GA MONYEKI VILLAGE,SHONGOANE 3  LEPHALALE  ELLISRAS",
    Latitude: "-23.5262",
    Longitude: "28.1353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16037",
    WardLookupId: "1988",
    VDNumber: "76490343",
    RegisteredPopulation: "453",
    VotingStationName: "BANGALONG PRIMARY SCHOOL",
    Address: "BANGALONG  VILLAGE  LEPHALALE  SHONGOANE",
    Latitude: "-23.4915",
    Longitude: "28.1801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16038",
    WardLookupId: "1988",
    VDNumber: "76490466",
    RegisteredPopulation: "275",
    VotingStationName: "TSHUKUDU PRIMARY SCHOOL",
    Address: "GA MONYEKI,SHONGOANE 3  LEPHALALE  LEPHALALE RURAL",
    Latitude: "-23.5361",
    Longitude: "28.1339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16039",
    WardLookupId: "1989",
    VDNumber: "76490051",
    RegisteredPopulation: "483",
    VotingStationName: "DIMPE SECONDARY SCHOOL",
    Address: "KITTY VILLAGE  LEPHALALE  ELLISRAS",
    Latitude: "-23.3602",
    Longitude: "28.1175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16040",
    WardLookupId: "1989",
    VDNumber: "76490152",
    RegisteredPopulation: "1415",
    VotingStationName: "ABBOTSPOORT SCHOOL",
    Address: "ABBOTSPOORT VILLAGE  LEPHALALE  ELLISRAS",
    Latitude: "-23.4516",
    Longitude: "28.0848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16041",
    WardLookupId: "1989",
    VDNumber: "76490208",
    RegisteredPopulation: "722",
    VotingStationName: "MAETELETJA PRIMARY SCHOOL",
    Address: "GA MAETELETJA VILLAGE  LEPHALALE  ELLISRAS",
    Latitude: "-23.4413",
    Longitude: "28.1779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16042",
    WardLookupId: "1989",
    VDNumber: "76490220",
    RegisteredPopulation: "1393",
    VotingStationName: "WILLIAM PHASHA PRE-SCHOOL",
    Address: "ABBOTSPOORT  VILLAGE  LEPHALALE  ELLISRAS",
    Latitude: "-23.4573",
    Longitude: "28.0986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16043",
    WardLookupId: "1989",
    VDNumber: "76490297",
    RegisteredPopulation: "690",
    VotingStationName: "BORUTLA MOSIMA PRIMARY SCHOOL",
    Address: "DIPOMPONG VILLAGE  LEPHALALE  LEPHALALE",
    Latitude: "-23.4042",
    Longitude: "28.1255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16044",
    WardLookupId: "1989",
    VDNumber: "76490354",
    RegisteredPopulation: "531",
    VotingStationName: "MOABI PRIMARY SCHOOL",
    Address: "KELETSE KE MME VILLAGE  LEPHALALE  WATERBERG  ",
    Latitude: "-23.3546",
    Longitude: "28.1637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16045",
    WardLookupId: "1990",
    VDNumber: "76490107",
    RegisteredPopulation: "484",
    VotingStationName: "MOSIMA PRIMARY SCHOOL",
    Address: "HLAGALAKWENA VILLAGE  LEPHALALE  ELLISRAS",
    Latitude: "-23.3724",
    Longitude: "28.0649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16046",
    WardLookupId: "1990",
    VDNumber: "76490253",
    RegisteredPopulation: "530",
    VotingStationName: "DIAMMONA SCHOOL",
    Address: "MONGALO VILLAGE  LEPHALALE   LEPHALALE [ELLISRAS]",
    Latitude: "-23.3234",
    Longitude: "28.0669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16047",
    WardLookupId: "1990",
    VDNumber: "76490286",
    RegisteredPopulation: "840",
    VotingStationName: "WALTER SISULU HALL",
    Address: "JOE SLOVO  LEPHALALE [ELLISRAS]  LEPHALALE [ELLISRAS]",
    Latitude: "-23.3429",
    Longitude: "28.0182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16048",
    WardLookupId: "1990",
    VDNumber: "76490376",
    RegisteredPopulation: "476",
    VotingStationName: "RABODITSE SECONDARY SCHOOL",
    Address: "REABETSWE VILLAGE  LEPHALALE  WATERBERG  ",
    Latitude: "-23.3608",
    Longitude: "28.0894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16049",
    WardLookupId: "1990",
    VDNumber: "76490387",
    RegisteredPopulation: "785",
    VotingStationName: "SEOKETSENG SECONDARY SCHOOL",
    Address: "MMALETSWAI VILLAGE  LEPHALALE  WATERBERG  ",
    Latitude: "-23.3736",
    Longitude: "28.0335",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16050",
    WardLookupId: "1990",
    VDNumber: "76490411",
    RegisteredPopulation: "545",
    VotingStationName: "MAKHURUMULA PRIMARY SCHOOL",
    Address: "SENOELA VILLAGE  LEPHALALE  LEPHALALE",
    Latitude: "-23.2983",
    Longitude: "28.0817",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16051",
    WardLookupId: "1990",
    VDNumber: "76490477",
    RegisteredPopulation: "526",
    VotingStationName: "MORUKHURUKHUNG PRIMARY SCHOOL",
    Address: "LERUPURUPUNG VILLAGE  LEPHALALE  LEPHALALE RURAL",
    Latitude: "-23.3147",
    Longitude: "28.013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16052",
    WardLookupId: "1991",
    VDNumber: "76490196",
    RegisteredPopulation: "853",
    VotingStationName: "KLIPSPRUIT PRIMARY SCHOOL",
    Address: "KGOBAGODIMO VILLAGE  LEPHALALE  ELLISRAS",
    Latitude: "-23.2783",
    Longitude: "28.0104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16053",
    WardLookupId: "1991",
    VDNumber: "76490219",
    RegisteredPopulation: "828",
    VotingStationName: "KGOMO-MOKGALO PRIMARY SCHOOL",
    Address: "BOTSALANONG VILLAGE  LEPHALALE  ELLISRAS",
    Latitude: "-23.256",
    Longitude: "28.0529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16054",
    WardLookupId: "1991",
    VDNumber: "76490365",
    RegisteredPopulation: "427",
    VotingStationName: "MOCHEKO PRIMARY SCHOOL",
    Address: "GA-MOCHEKO VILLAGE  LEPHALALE  WATERBERG  ",
    Latitude: "-23.324",
    Longitude: "28.1359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16055",
    WardLookupId: "1991",
    VDNumber: "76490398",
    RegisteredPopulation: "748",
    VotingStationName: "TSHIRELETSO SECONDARY SCHOOL",
    Address: "LETLORA VILLAGE  LEPHALALE  WATERBERG ",
    Latitude: "-23.2993",
    Longitude: "27.9684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16056",
    WardLookupId: "1991",
    VDNumber: "76490422",
    RegisteredPopulation: "266",
    VotingStationName: "MADIBANENG PRIMARY SCHOOL",
    Address: "MADIBANENG VILLAGE  LEPHALALE  LEPHALALE",
    Latitude: "-23.2109",
    Longitude: "27.993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16057",
    WardLookupId: "1991",
    VDNumber: "76490433",
    RegisteredPopulation: "220",
    VotingStationName: "TSHELAMMAKE PRIMARY SCHOOL",
    Address: "TSHELAMMAKE VILLAGE  LEPHALALE  LEPHALALE",
    Latitude: "-23.2695",
    Longitude: "27.9499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16058",
    WardLookupId: "1991",
    VDNumber: "76490488",
    RegisteredPopulation: "361",
    VotingStationName: "MANANYE HIGH SCHOOL",
    Address: "KOPANONG VILLAGE  LEPHALALE  LEPHALALE RURAL",
    Latitude: "-23.2484",
    Longitude: "28.0372",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16059",
    WardLookupId: "1991",
    VDNumber: "76490499",
    RegisteredPopulation: "226",
    VotingStationName: "SEGALE PRIMARY SCHOOL",
    Address: "SEGALE VILLAGE  LEPHALALE  LEPHALALE RURAL",
    Latitude: "-23.247",
    Longitude: "28.0762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16060",
    WardLookupId: "1992",
    VDNumber: "76490017",
    RegisteredPopulation: "1394",
    VotingStationName: "SEFITLHOGO PRIMARY SCHOOL",
    Address: "RIETFONTEIN VILLAGE  LEPHALALE  ELLISRAS",
    Latitude: "-23.1293",
    Longitude: "27.9795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16061",
    WardLookupId: "1992",
    VDNumber: "76490130",
    RegisteredPopulation: "816",
    VotingStationName: "MFAKE PRIMARY SCHOOL",
    Address: "BOTSHABELO VILLAGE  LEPHALALE  ELLISRAS",
    Latitude: "-23.1797",
    Longitude: "28.0394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16062",
    WardLookupId: "1992",
    VDNumber: "76490400",
    RegisteredPopulation: "715",
    VotingStationName: "LETUPU SECONDARY SCHOOL",
    Address: "MORWE VILLAGE  LEPHALALE  WATERBERG ",
    Latitude: "-23.1672",
    Longitude: "28.0086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16063",
    WardLookupId: "1992",
    VDNumber: "76490444",
    RegisteredPopulation: "312",
    VotingStationName: "RAMAKWAPENG PRIMARY SCHOOL",
    Address: "LEBU VILLAGE  LEPHALALE  LEPHALALE",
    Latitude: "-23.1764",
    Longitude: "27.9803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16064",
    WardLookupId: "1992",
    VDNumber: "76490501",
    RegisteredPopulation: "454",
    VotingStationName: "TSHEESEBE PRIMARY SCHOOL",
    Address: "MOONG VILLAGE  LEPHALALE  LEPHALALE RURAL",
    Latitude: "-23.2015",
    Longitude: "28.0605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16065",
    WardLookupId: "1992",
    VDNumber: "76510021",
    RegisteredPopulation: "221",
    VotingStationName: "TOMBURKE MAGISTRATE COURT TENT",
    Address: "TOMBURKE FARM  LEPHALALE  LEPHALALE",
    Latitude: "-23.0699",
    Longitude: "27.999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16066",
    WardLookupId: "1992",
    VDNumber: "76510032",
    RegisteredPopulation: "141",
    VotingStationName: "BAUPO GAME LODGE",
    Address: "KILDARE FARM  LEPHALALE  LEPHALALE",
    Latitude: "-23.0935",
    Longitude: "28.2333",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16067",
    WardLookupId: "1992",
    VDNumber: "76510571",
    RegisteredPopulation: "192",
    VotingStationName: "GROBLERSBRUG SPORTS GROUND (TENT)",
    Address: "GROBLERSBRIDGE  PORT OF ENTRY  LEPHALALE  LEPHALALE",
    Latitude: "-22.9992",
    Longitude: "27.946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16068",
    WardLookupId: "1993",
    VDNumber: "76490129",
    RegisteredPopulation: "1412",
    VotingStationName: "SELEKA COMMUNITY HALL",
    Address: "SELEKA VILLAGE  LEPHALALE  ELLISRAS",
    Latitude: "-23.216",
    Longitude: "27.9083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16069",
    WardLookupId: "1993",
    VDNumber: "76490174",
    RegisteredPopulation: "1231",
    VotingStationName: "RASERITE SCHOOL",
    Address: "RASERITI MAIN STREET  SELEKA VILLAGE  ELLISRAS",
    Latitude: "-23.2325",
    Longitude: "27.9011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16070",
    WardLookupId: "1993",
    VDNumber: "76490275",
    RegisteredPopulation: "740",
    VotingStationName: "MOTLHASEDI PRIMARY SCHOOL",
    Address: "MOTLHASEDI VILLAGE  LEPHALALE  ELLISRAS",
    Latitude: "-23.2167",
    Longitude: "27.8809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16071",
    WardLookupId: "1993",
    VDNumber: "76490512",
    RegisteredPopulation: "329",
    VotingStationName: "SELEKA  PRIMARY SCHOOL",
    Address: "GA SELEKA  LEPHALALE  SELEKA",
    Latitude: "-23.2182",
    Longitude: "27.9102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16072",
    WardLookupId: "1993",
    VDNumber: "76490523",
    RegisteredPopulation: "518",
    VotingStationName: "BAITSWE HIGH SCHOOL",
    Address: "MOTLHASEDI VILLAGE  LEPHALALE  LEPHALALE RURAL",
    Latitude: "-23.222",
    Longitude: "27.8808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16073",
    WardLookupId: "1994",
    VDNumber: "76420021",
    RegisteredPopulation: "2662",
    VotingStationName: "HOÃ‹RSKOOL ELLISRAS",
    Address: "OR THAMBO ROAD  LEPHALALE  LEPHALALE",
    Latitude: "-23.666",
    Longitude: "27.7379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16074",
    WardLookupId: "1994",
    VDNumber: "76420087",
    RegisteredPopulation: "1361",
    VotingStationName: "LEPHALALE FET COLLEGE",
    Address: "NELSON MANDELA & NGOAKO RAMAHL  LEPHALALE  LEPHALELE",
    Latitude: "-23.6816",
    Longitude: "27.6877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16075",
    WardLookupId: "3996",
    VDNumber: "76470059",
    RegisteredPopulation: "1912",
    VotingStationName: "WARMBAD LAERSKOOL",
    Address: "80 MOFFAT STREET  WARMBATHS  WARMBATHS",
    Latitude: "-24.8824",
    Longitude: "28.2819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16076",
    WardLookupId: "3996",
    VDNumber: "76470183",
    RegisteredPopulation: "1369",
    VotingStationName: "NG KERK WARMBAD",
    Address: "CNR BOTHA & MENTZ  BELA BELA  BELA BELA",
    Latitude: "-24.8773",
    Longitude: "28.2886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16077",
    WardLookupId: "3997",
    VDNumber: "76470037",
    RegisteredPopulation: "1790",
    VotingStationName: "RAELENG HIGH SCHOOL",
    Address: "7963 HECTOR PETERSON   BELA-BELA  BELA-BELA",
    Latitude: "-24.8798",
    Longitude: "28.3168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16078",
    WardLookupId: "3997",
    VDNumber: "76470071",
    RegisteredPopulation: "1015",
    VotingStationName: "MMAMAKWA SCHOOL",
    Address: "207 KHOTA  EXTENSION1, BELA  WARMBATHS",
    Latitude: "-24.8888",
    Longitude: "28.3097",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16079",
    WardLookupId: "3997",
    VDNumber: "76470093",
    RegisteredPopulation: "446",
    VotingStationName: "BELA BELA COMMUNITY HALL",
    Address: "NGOATE STR  BELA-BELA  BELA-BELA [WARMBAD]",
    Latitude: "-24.8888",
    Longitude: "28.3126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16080",
    WardLookupId: "3998",
    VDNumber: "76470015",
    RegisteredPopulation: "1559",
    VotingStationName: "ALBERT LETHULI PRIMARY SCHOOL",
    Address: "NO 38  EXTENTION 5  BELA BELA",
    Latitude: "-24.8829",
    Longitude: "28.324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16081",
    WardLookupId: "3998",
    VDNumber: "76470105",
    RegisteredPopulation: "1378",
    VotingStationName: "SECTION 5 PARK (TENT)",
    Address: "MARABASTAD  BELA-BELA  BELA-BELA [WARMBAD]",
    Latitude: "-24.8853",
    Longitude: "28.3246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16082",
    WardLookupId: "3999",
    VDNumber: "76470116",
    RegisteredPopulation: "1321",
    VotingStationName: "ANTIOCH RIVER CHURCH",
    Address: "8395 PHOMOLONG  BELA BELA",
    Latitude: "-24.8819",
    Longitude: "28.3297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16083",
    WardLookupId: "3999",
    VDNumber: "76470127",
    RegisteredPopulation: "160",
    VotingStationName: "TOWOOMBA HALL",
    Address: "R516 ROAD BELA BELA  BELA-BELA [WARMBAD]  BELA-BELA [WARMBAD]",
    Latitude: "-24.8994",
    Longitude: "28.3243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16084",
    WardLookupId: "3999",
    VDNumber: "76470138",
    RegisteredPopulation: "97",
    VotingStationName: "ROODEKUIL CAFE 1",
    Address: "10 ROODEKUIL CAFE  BELA BELA  WATERBERG",
    Latitude: "-24.9692",
    Longitude: "28.3482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16085",
    WardLookupId: "3999",
    VDNumber: "76470194",
    RegisteredPopulation: "1540",
    VotingStationName: "EXTENTION 8 (TENT)",
    Address: "EXTENTION 8  BELA-BELA  BELA-BELA",
    Latitude: "-24.8885",
    Longitude: "28.3338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16086",
    WardLookupId: "3999",
    VDNumber: "76540046",
    RegisteredPopulation: "304",
    VotingStationName: "MATHINTHA SCHOOL",
    Address: "SEOTDORINGLAAGTE STREET  MATHINTHA  WATERBERG",
    Latitude: "-25.0417",
    Longitude: "28.4949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16087",
    WardLookupId: "3999",
    VDNumber: "76540079",
    RegisteredPopulation: "540",
    VotingStationName: "SETTLERS AGRICULTURAL HIGH SCHOOL",
    Address: "SETTLES AGRICULTURAL SCHOOL  SETTLERS  SETTLERS",
    Latitude: "-24.9544",
    Longitude: "28.5382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16088",
    WardLookupId: "4000",
    VDNumber: "76470026",
    RegisteredPopulation: "2256",
    VotingStationName: "BELA BELA HIGH SCHOOL",
    Address: "751 MOKONE STREET  BELA-BELA  BELA BELA",
    Latitude: "-24.8893",
    Longitude: "28.3182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16089",
    WardLookupId: "4000",
    VDNumber: "76470206",
    RegisteredPopulation: "735",
    VotingStationName: "NEW JERUSALEM CHURCH",
    Address: "1230 KUTU STREET  BELA BELA  BELA-BELA",
    Latitude: "-24.891",
    Longitude: "28.3164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16090",
    WardLookupId: "4001",
    VDNumber: "76470048",
    RegisteredPopulation: "2615",
    VotingStationName: "THUSANANG SPECIAL SCHOOL",
    Address: "ERF 1547  EXTENSION 2, LESEDING  BELA BELA",
    Latitude: "-24.8813",
    Longitude: "28.312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16091",
    WardLookupId: "4001",
    VDNumber: "76470150",
    RegisteredPopulation: "295",
    VotingStationName: "PUBLIC WORKS HALLS",
    Address: "20 LUNA  BELA-BELA  BELA-BELA",
    Latitude: "-24.8829",
    Longitude: "28.2986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16092",
    WardLookupId: "4002",
    VDNumber: "76470060",
    RegisteredPopulation: "1844",
    VotingStationName: "MULTI-PURPOSE HALL",
    Address: "7590 7590 PALALA STREET  BELA BELA  BELA BELA",
    Latitude: "-24.8789",
    Longitude: "28.3248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16093",
    WardLookupId: "4002",
    VDNumber: "76470228",
    RegisteredPopulation: "723",
    VotingStationName: "BELA BELA CHRISTIAN CHURCH",
    Address: "6915 EXTENSION 6B  EXTESION 6B  BELA BELA",
    Latitude: "-24.8724",
    Longitude: "28.3207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16094",
    WardLookupId: "4002",
    VDNumber: "76540147",
    RegisteredPopulation: "724",
    VotingStationName: "EMMERENTIA GELDENHUYS",
    Address: "EMMERENTIA GELDEN HUYS  BUYSKOP  WARMBATHS",
    Latitude: "-24.8735",
    Longitude: "28.3154",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16095",
    WardLookupId: "4002",
    VDNumber: "76540192",
    RegisteredPopulation: "518",
    VotingStationName: "TSAKANE CRECHE",
    Address: "TSAKANE  WATREBERG  WATERBERG",
    Latitude: "-24.8405",
    Longitude: "28.3781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16096",
    WardLookupId: "4002",
    VDNumber: "76540260",
    RegisteredPopulation: "169",
    VotingStationName: "SONDELA NATURE RESERVE & SPA",
    Address: "TWEEFONTEIN  BELA BELA",
    Latitude: "-24.9272",
    Longitude: "28.3933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16097",
    WardLookupId: "4003",
    VDNumber: "54570078",
    RegisteredPopulation: "1690",
    VotingStationName: "KHOBONGWANE PRIMARY SCHOOL",
    Address: "MMAMETIHAKE  MMAMETIHAKE VILLAGE  MORETELE 2",
    Latitude: "-25.1343",
    Longitude: "28.6412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16098",
    WardLookupId: "4003",
    VDNumber: "76540057",
    RegisteredPopulation: "1128",
    VotingStationName: "BATHOPELE SCHOOL",
    Address: "607 BOTHLALE AVENUE, PIENAARSR  BELA BELA  PIENAARSRIVIER",
    Latitude: "-25.2136",
    Longitude: "28.2971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16099",
    WardLookupId: "4003",
    VDNumber: "76540068",
    RegisteredPopulation: "264",
    VotingStationName: "RUST DE WINTER SAPS STATION",
    Address: "POLICE STATION  RUST DE WINTER  RUST DE WINTER",
    Latitude: "-25.203",
    Longitude: "28.6077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16100",
    WardLookupId: "4004",
    VDNumber: "76470082",
    RegisteredPopulation: "1324",
    VotingStationName: "SPA PARK PRIMARY SCHOOL",
    Address: "70 WOODPECKER STR  BOSDULF  BELA-BELA",
    Latitude: "-24.8767",
    Longitude: "28.2719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16101",
    WardLookupId: "4004",
    VDNumber: "76470149",
    RegisteredPopulation: "182",
    VotingStationName: "PLATRIVIER CAFÃ‰ (TENT)",
    Address: "NULL  BELA-BELA [WARMBAD]  BELA-BELA [WARMBAD]",
    Latitude: "-24.9606",
    Longitude: "28.3123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16102",
    WardLookupId: "4004",
    VDNumber: "76470239",
    RegisteredPopulation: "436",
    VotingStationName: "PEET PADSTAL",
    Address: "15 ROOIBERG ROAD, NOODHULP  PLOT 129, NOODHULP FARMS  BELA BELA",
    Latitude: "-24.9007",
    Longitude: "28.252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16103",
    WardLookupId: "4004",
    VDNumber: "76540080",
    RegisteredPopulation: "435",
    VotingStationName: "RIETSPRUIT FARM",
    Address: "39 RIETSPRUIT 527KQ  MABULA  BELA BELA",
    Latitude: "-24.8228",
    Longitude: "27.9931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16104",
    WardLookupId: "4004",
    VDNumber: "76540091",
    RegisteredPopulation: "314",
    VotingStationName: "SAAMWERK GENERAL DEALER",
    Address: "474 FARM 474 KR  RUSOORD  BELA BELA",
    Latitude: "-24.87",
    Longitude: "28.092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16105",
    WardLookupId: "4004",
    VDNumber: "76540226",
    RegisteredPopulation: "643",
    VotingStationName: "RHENOSTERKLOOF PRIMARY SCHOOL",
    Address: "FARM VINGERKRAAL  BELA-BELA",
    Latitude: "-24.73",
    Longitude: "27.9863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16106",
    WardLookupId: "4004",
    VDNumber: "76540237",
    RegisteredPopulation: "1019",
    VotingStationName: "BLAAUWBOSCHSKUIL SCHOOL",
    Address: "MASAKHANE, BELA BELA  BELA BELA  MASAKHANE",
    Latitude: "-25.0757",
    Longitude: "28.283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16107",
    WardLookupId: "4005",
    VDNumber: "76510087",
    RegisteredPopulation: "543",
    VotingStationName: "MABOPA-ABO-MPUWA SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MONTE & DUREN  MOGALAKWENA",
    Latitude: "-23.3906",
    Longitude: "28.6763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16108",
    WardLookupId: "4005",
    VDNumber: "76510098",
    RegisteredPopulation: "278",
    VotingStationName: "BATLOKWA TRIBAL",
    Address: "NO STREET NUMBER  MATTANAU  MOGALAKWENA",
    Latitude: "-23.4253",
    Longitude: "28.7941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16109",
    WardLookupId: "4005",
    VDNumber: "76510155",
    RegisteredPopulation: "671",
    VotingStationName: "MASHAO MABUSHA  PRIMARY",
    Address: "TIPENG  STEILLOOP  REBONE",
    Latitude: "-23.462",
    Longitude: "28.6428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16110",
    WardLookupId: "4005",
    VDNumber: "76510379",
    RegisteredPopulation: "490",
    VotingStationName: "MOROBA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  SODOMA  MOKOPANE",
    Latitude: "-23.5068",
    Longitude: "28.7256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16111",
    WardLookupId: "4005",
    VDNumber: "76510380",
    RegisteredPopulation: "497",
    VotingStationName: "PALEDI HIGH SCHOOL",
    Address: "NO STREET NAME  SETUPULANE  MOGALAKWENA",
    Latitude: "-23.4896",
    Longitude: "28.7127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16112",
    WardLookupId: "4005",
    VDNumber: "76510391",
    RegisteredPopulation: "681",
    VotingStationName: "DITLOU PRIMARY SCHOOL",
    Address: "NO STREET NAME  THABALESHOBA  MOGALEKWENA",
    Latitude: "-23.4814",
    Longitude: "28.6617",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16113",
    WardLookupId: "4005",
    VDNumber: "76510403",
    RegisteredPopulation: "292",
    VotingStationName: "GALAKWINSTROOM PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  GALAKWENA & REBONE  MOKOPANE",
    Latitude: "-23.4245",
    Longitude: "28.6378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16114",
    WardLookupId: "4005",
    VDNumber: "76510414",
    RegisteredPopulation: "358",
    VotingStationName: "RAMASHILO PRIMARY SCHOOL",
    Address: "NO STREET NAME & NUMBER  BREADA  MOGALAKWENA",
    Latitude: "-23.3692",
    Longitude: "28.703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16115",
    WardLookupId: "4005",
    VDNumber: "76510425",
    RegisteredPopulation: "343",
    VotingStationName: "THLAKO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  PREEZBURG  REBONE",
    Latitude: "-23.4551",
    Longitude: "28.7144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16116",
    WardLookupId: "4005",
    VDNumber: "76510436",
    RegisteredPopulation: "452",
    VotingStationName: "NKOBO HIGH SCHOOL",
    Address: "NO STREET ADDRESS  POLLEN  MOGALAKWENA",
    Latitude: "-23.3939",
    Longitude: "28.7389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16117",
    WardLookupId: "4005",
    VDNumber: "76510504",
    RegisteredPopulation: "176",
    VotingStationName: "LENNES PRIMARY SCHOOL",
    Address: "NO STREET NAME  BAKENBERG  MOGALAKWENA [POTGIETERSRUS]",
    Latitude: "-23.4685",
    Longitude: "28.7466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16118",
    WardLookupId: "4006",
    VDNumber: "76510100",
    RegisteredPopulation: "614",
    VotingStationName: "MODISHA PRIMARY SCHOOL",
    Address: "WATERVAAL  REBONE  STEILOOP",
    Latitude: "-23.5583",
    Longitude: "28.725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16119",
    WardLookupId: "4006",
    VDNumber: "76510111",
    RegisteredPopulation: "418",
    VotingStationName: "MAKOBE TRIBAL HALL",
    Address: "MAKOBE CLOSER SETTLEMENT  MAKOBE  REBONE",
    Latitude: "-23.5408",
    Longitude: "28.8107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16120",
    WardLookupId: "4006",
    VDNumber: "76510122",
    RegisteredPopulation: "620",
    VotingStationName: "MATHEKGA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  BAKENBERG  POTGIETERSRUS",
    Latitude: "-23.5249",
    Longitude: "28.8304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16121",
    WardLookupId: "4006",
    VDNumber: "76510166",
    RegisteredPopulation: "934",
    VotingStationName: "RALELEDU SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS  UITZICHT  REBONE",
    Latitude: "-23.5131",
    Longitude: "28.6702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16122",
    WardLookupId: "4006",
    VDNumber: "76510177",
    RegisteredPopulation: "426",
    VotingStationName: "RATINKIE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MATJITJILENG  MOKOPANE",
    Latitude: "-23.4844",
    Longitude: "28.8289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16123",
    WardLookupId: "4006",
    VDNumber: "76510290",
    RegisteredPopulation: "693",
    VotingStationName: "MAGOBA PRIMARY SCHOOL",
    Address: "BLINKWATER  MOKERONG",
    Latitude: "-23.5381",
    Longitude: "28.7909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16124",
    WardLookupId: "4007",
    VDNumber: "76510188",
    RegisteredPopulation: "1089",
    VotingStationName: "SEPHUTHI HIGH SCHOOL",
    Address: "TAYEATSOALA VILL  REBONE  ",
    Latitude: "-23.471",
    Longitude: "28.5558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16125",
    WardLookupId: "4007",
    VDNumber: "76510289",
    RegisteredPopulation: "1450",
    VotingStationName: "REBONE COMMUNITY HALL",
    Address: "NO STREET NUMBER  REBONE  REBONE",
    Latitude: "-23.4311",
    Longitude: "28.6094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16126",
    WardLookupId: "4007",
    VDNumber: "76510357",
    RegisteredPopulation: "465",
    VotingStationName: "MAHLORA PRIMARY SCHOOL",
    Address: "STERKWATER  ",
    Latitude: "-23.5354",
    Longitude: "28.626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16127",
    WardLookupId: "4007",
    VDNumber: "76510481",
    RegisteredPopulation: "877",
    VotingStationName: "MPEDI HIGH SCHOOL",
    Address: "NO STREET NAME & NUMBER  REBONE  MOGALAKWENA",
    Latitude: "-23.4828",
    Longitude: "28.5365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16128",
    WardLookupId: "4007",
    VDNumber: "76510515",
    RegisteredPopulation: "449",
    VotingStationName: "KGOBUKI PRIMARY SCHOOL",
    Address: "TAUEATSOALA VILLAGE  REBONE  MOGALAKWENA [POTGIETERSRUS]",
    Latitude: "-23.4674",
    Longitude: "28.5606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16129",
    WardLookupId: "4008",
    VDNumber: "76320165",
    RegisteredPopulation: "948",
    VotingStationName: "LEKHURENG PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  LEKHURENG  MOKOPANE",
    Latitude: "-23.5767",
    Longitude: "28.9229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16130",
    WardLookupId: "4008",
    VDNumber: "76321122",
    RegisteredPopulation: "458",
    VotingStationName: "MODIANYANA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-CHIPANA  MOKERONG",
    Latitude: "-23.5951",
    Longitude: "28.9584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16131",
    WardLookupId: "4008",
    VDNumber: "76480847",
    RegisteredPopulation: "222",
    VotingStationName: "CLERMONT PRIMARY SCHOOL",
    Address: "CLERMONT  ",
    Latitude: "-23.7707",
    Longitude: "28.7979",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16132",
    WardLookupId: "4008",
    VDNumber: "76510133",
    RegisteredPopulation: "858",
    VotingStationName: "RAMELA PRIMARY SCHOOL",
    Address: "TENERIFFE  REBONE",
    Latitude: "-23.5295",
    Longitude: "28.8827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16133",
    WardLookupId: "4008",
    VDNumber: "76510144",
    RegisteredPopulation: "475",
    VotingStationName: "KGAKGATHU HIGH SCHOOL",
    Address: "SCIRAPPES  SCIRAPPIES  REBONE",
    Latitude: "-23.574",
    Longitude: "28.8323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16134",
    WardLookupId: "4008",
    VDNumber: "76510324",
    RegisteredPopulation: "656",
    VotingStationName: "HAM NO 1 PRIMARY SCHOOL",
    Address: "SCIRRAPIES  MOKOPANE",
    Latitude: "-23.6277",
    Longitude: "28.8513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16135",
    WardLookupId: "4008",
    VDNumber: "76510492",
    RegisteredPopulation: "543",
    VotingStationName: "MASHOSHO -PRIMARY SCHOOL",
    Address: "GA TLHAKO  MOGALAKWENA",
    Latitude: "-23.5702",
    Longitude: "28.8405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16136",
    WardLookupId: "4008",
    VDNumber: "76510582",
    RegisteredPopulation: "102",
    VotingStationName: "KGOTSORO PRIMARY SCHOOL",
    Address: "SCIRRAPPIES  STEILOOP  REBONE",
    Latitude: "-23.5638",
    Longitude: "28.8127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16137",
    WardLookupId: "4009",
    VDNumber: "76480094",
    RegisteredPopulation: "181",
    VotingStationName: "MMANKISHE PRE-SCHOOL",
    Address: "STAND NO. 10020, KGOPEDI   BAKENBERG  BAKENBERG",
    Latitude: "-23.6998",
    Longitude: "28.7155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16138",
    WardLookupId: "4009",
    VDNumber: "76480128",
    RegisteredPopulation: "217",
    VotingStationName: "NONGTHOKWA PRIMARY SCHOOL",
    Address: "BUFFELSHOEK  MOKOPANE",
    Latitude: "-23.6883",
    Longitude: "28.7616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16139",
    WardLookupId: "4009",
    VDNumber: "76480139",
    RegisteredPopulation: "213",
    VotingStationName: "LECHABANGWA PRE-SCHOOL",
    Address: "NO STREET NAME & NUMBER  MATEBELENG  MOGALAKWENA",
    Latitude: "-23.7649",
    Longitude: "28.7469",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16140",
    WardLookupId: "4009",
    VDNumber: "76480858",
    RegisteredPopulation: "363",
    VotingStationName: "VLAKFONTEIN RESTAURANT (TENT)",
    Address: "NULL  MAHABANENG  MOKOPANE",
    Latitude: "-23.7417",
    Longitude: "28.7527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16141",
    WardLookupId: "4009",
    VDNumber: "76480869",
    RegisteredPopulation: "367",
    VotingStationName: "NKEKEHLALWA PRIMARY SCHOOL",
    Address: "DIPICHI  MOKOPANE",
    Latitude: "-23.7247",
    Longitude: "28.7132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16142",
    WardLookupId: "4009",
    VDNumber: "76510256",
    RegisteredPopulation: "613",
    VotingStationName: "SEGOLE PRIMARY SCHOOL",
    Address: "SEGOLE 1  MOKOPANE",
    Latitude: "-23.6033",
    Longitude: "28.6367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16143",
    WardLookupId: "4009",
    VDNumber: "76510267",
    RegisteredPopulation: "314",
    VotingStationName: "GRASVLEI COMMUNITY HALL",
    Address: "NO STREET NUMBER  GRASVLEI  MOKOPANE",
    Latitude: "-23.6415",
    Longitude: "28.7029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16144",
    WardLookupId: "4009",
    VDNumber: "76510278",
    RegisteredPopulation: "532",
    VotingStationName: "TIBERIUS COMMUNITY HALL",
    Address: "TIBERIUS  MOKOPANE",
    Latitude: "-23.6273",
    Longitude: "28.759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16145",
    WardLookupId: "4009",
    VDNumber: "76510302",
    RegisteredPopulation: "293",
    VotingStationName: "JORDAN PRIMARY SCHOOL",
    Address: "MPHELLO  MOKOPANE",
    Latitude: "-23.6634",
    Longitude: "28.7194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16146",
    WardLookupId: "4009",
    VDNumber: "76510313",
    RegisteredPopulation: "304",
    VotingStationName: "SEHOJANE PRIMARY SCHOOL",
    Address: "NO STREET NAME  SEHOLE NO. 2  MOGALAKWENA",
    Latitude: "-23.5848",
    Longitude: "28.6647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16147",
    WardLookupId: "4009",
    VDNumber: "76510368",
    RegisteredPopulation: "388",
    VotingStationName: "SEPOBE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GALELIA  REBONE",
    Latitude: "-23.5845",
    Longitude: "28.7609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16148",
    WardLookupId: "4009",
    VDNumber: "76510526",
    RegisteredPopulation: "256",
    VotingStationName: "KGOKOLO PRIMARY SCHOOL",
    Address: "TIPENG VILLAGE  REBONE  MOGALAKWENA [POTGIETERSRUS]",
    Latitude: "-23.6407",
    Longitude: "28.6632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16149",
    WardLookupId: "4010",
    VDNumber: "76510201",
    RegisteredPopulation: "688",
    VotingStationName: "MUSHI PRIMARY SCHOOL",
    Address: "GA MUSHI  MOKOPANE  REBONE",
    Latitude: "-23.5453",
    Longitude: "28.4843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16150",
    WardLookupId: "4010",
    VDNumber: "76510212",
    RegisteredPopulation: "837",
    VotingStationName: "MPHARI SECONDARY SCHOOL",
    Address: "VIANA  MOKOPANE",
    Latitude: "-23.5874",
    Longitude: "28.488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16151",
    WardLookupId: "4010",
    VDNumber: "76510223",
    RegisteredPopulation: "509",
    VotingStationName: "MATLOU MEMORIAL SCHOOL",
    Address: "NO STREET ADDRESS  RAPADI  MOKOPANE",
    Latitude: "-23.5964",
    Longitude: "28.5219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16152",
    WardLookupId: "4010",
    VDNumber: "76510234",
    RegisteredPopulation: "597",
    VotingStationName: "NKIDIKITLANA -TRADITIONAL COUNCIL",
    Address: "2002 NKIDIKITLANA VILLAGE  REBONE  MOGALAKWENA",
    Latitude: "-23.561",
    Longitude: "28.5972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16153",
    WardLookupId: "4010",
    VDNumber: "76510245",
    RegisteredPopulation: "567",
    VotingStationName: "NGWANAKWADI PRIMARY SCHOOL",
    Address: "NONG-DIPERE  MOKOPANE",
    Latitude: "-23.5947",
    Longitude: "28.5707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16154",
    WardLookupId: "4010",
    VDNumber: "76510346",
    RegisteredPopulation: "363",
    VotingStationName: "SERUPA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-CHERE  MOKOPANE",
    Latitude: "-23.5634",
    Longitude: "28.5067",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16155",
    WardLookupId: "4010",
    VDNumber: "76510469",
    RegisteredPopulation: "540",
    VotingStationName: "K K MONARE PRIMARY SCHOOL",
    Address: "GA-MONARE  MOKOPANE",
    Latitude: "-23.5854",
    Longitude: "28.5478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16156",
    WardLookupId: "4011",
    VDNumber: "76480252",
    RegisteredPopulation: "335",
    VotingStationName: "KGAKGALA PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  DIKGOKGOPHENG  MOKERONG",
    Latitude: "-23.8937",
    Longitude: "28.5949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16157",
    WardLookupId: "4011",
    VDNumber: "76480263",
    RegisteredPopulation: "438",
    VotingStationName: "MAPITIKAMA PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  SCHULPADKRAAL  MOKERONG",
    Latitude: "-23.9388",
    Longitude: "28.5833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16158",
    WardLookupId: "4011",
    VDNumber: "76480274",
    RegisteredPopulation: "747",
    VotingStationName: "RAOWESHE PRIMARY SCHOOL",
    Address: "MAMATLAKALA  MOKERONG",
    Latitude: "-23.9804",
    Longitude: "28.587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16159",
    WardLookupId: "4011",
    VDNumber: "76480331",
    RegisteredPopulation: "970",
    VotingStationName: "LESODI MMOTLANA PRIMARY SCHOOL",
    Address: "NO STREET NUMBER  LESODI  MOKOPANE",
    Latitude: "-23.9633",
    Longitude: "28.6125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16160",
    WardLookupId: "4011",
    VDNumber: "76481219",
    RegisteredPopulation: "140",
    VotingStationName: "KGAUBOTLHALE SECONDARY SCHOOL",
    Address: "MOKAMOLE  MOKOPANE  BAKENBERG",
    Latitude: "-23.9455",
    Longitude: "28.5899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16161",
    WardLookupId: "4011",
    VDNumber: "76510065",
    RegisteredPopulation: "298",
    VotingStationName: "MARKEN PRIMARY -SCHOOL",
    Address: "01 MAIN STREET  MARKEN  MARKEN",
    Latitude: "-23.5924",
    Longitude: "28.3871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16162",
    WardLookupId: "4011",
    VDNumber: "76510335",
    RegisteredPopulation: "413",
    VotingStationName: "RALEHUMANE PRIMARY SCHOOL",
    Address: "UITSPANNING  MOKOPANAE",
    Latitude: "-23.6514",
    Longitude: "28.465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16163",
    WardLookupId: "4011",
    VDNumber: "76510447",
    RegisteredPopulation: "378",
    VotingStationName: "NTEBELELENG PRIMARY SCHOOL",
    Address: "SENITA  MOKOPANE",
    Latitude: "-23.607",
    Longitude: "28.5081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16164",
    WardLookupId: "4011",
    VDNumber: "76510548",
    RegisteredPopulation: "56",
    VotingStationName: "TOUCHSTONE- PRE-PARATORY SCHOOL",
    Address: "MOGALAKWENA  ",
    Latitude: "-23.7309",
    Longitude: "28.4003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16165",
    WardLookupId: "4011",
    VDNumber: "76510559",
    RegisteredPopulation: "117",
    VotingStationName: "MOEPEL FARM SCHOOL",
    Address: "NO STREET ADDRESS  MOEPEL FARM  MOGALAKWENA [POTGIETERSRUS]",
    Latitude: "-23.7753",
    Longitude: "28.4319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16166",
    WardLookupId: "4011",
    VDNumber: "76510560",
    RegisteredPopulation: "113",
    VotingStationName: "RHENOSTERSFONTEIN SCHOOL",
    Address: "MOEPEL FARM  MOKOPANE  MARKEN",
    Latitude: "-23.8776",
    Longitude: "28.4557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16167",
    WardLookupId: "4012",
    VDNumber: "76480050",
    RegisteredPopulation: "203",
    VotingStationName: "MAPOLA PRE-SCHOOL",
    Address: "LENKWANE  MOKOPANE  LENKWANE",
    Latitude: "-23.7083",
    Longitude: "28.5881",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16168",
    WardLookupId: "4012",
    VDNumber: "76480061",
    RegisteredPopulation: "440",
    VotingStationName: "DIRETSANENG PRIMARY SCHOOL",
    Address: "RUSLIT  ",
    Latitude: "-23.6792",
    Longitude: "28.6467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16169",
    WardLookupId: "4012",
    VDNumber: "76480072",
    RegisteredPopulation: "439",
    VotingStationName: "NELLY PRIMARY SCHOOL",
    Address: "NELLY  MOKERONG",
    Latitude: "-23.7216",
    Longitude: "28.6409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16170",
    WardLookupId: "4012",
    VDNumber: "76480083",
    RegisteredPopulation: "560",
    VotingStationName: "MAHLOMA PRIMARY SCHOOL",
    Address: "NO STREET NUMBER  WYDHOEK  MOKERONG",
    Latitude: "-23.7357",
    Longitude: "28.6071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16171",
    WardLookupId: "4012",
    VDNumber: "76480117",
    RegisteredPopulation: "538",
    VotingStationName: "GALAKWINSTROOM PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  BAKENBERG  MOKOPANE",
    Latitude: "-23.7696",
    Longitude: "28.6291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16172",
    WardLookupId: "4012",
    VDNumber: "76480162",
    RegisteredPopulation: "564",
    VotingStationName: "MONENE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  PAULOS  MOKOPANE",
    Latitude: "-23.7468",
    Longitude: "28.6549",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16173",
    WardLookupId: "4012",
    VDNumber: "76480870",
    RegisteredPopulation: "408",
    VotingStationName: "BATHOKWA HIGH SCHOOL",
    Address: "WYDHOEK VILLAGE  BAKENBERG  MOGALAKWENA [POTGIETERSRUS]",
    Latitude: "-23.6873",
    Longitude: "28.586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16174",
    WardLookupId: "4012",
    VDNumber: "76510458",
    RegisteredPopulation: "383",
    VotingStationName: "TSELAPEDI PRIMARY SCHOOL",
    Address: "MOSHUKA  MOKOPANE  STEILOOP",
    Latitude: "-23.632",
    Longitude: "28.6164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16175",
    WardLookupId: "4012",
    VDNumber: "76510537",
    RegisteredPopulation: "236",
    VotingStationName: "MOSHUKA  KGORONG  (TENT)",
    Address: "MOSHUKA   MOSHUKA VILLAGE  MOGALAKWENA RURAL",
    Latitude: "-23.6415",
    Longitude: "28.6247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16176",
    WardLookupId: "4013",
    VDNumber: "76480106",
    RegisteredPopulation: "375",
    VotingStationName: "MALAPILE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  SWARTKOP  MOGALAKWENA",
    Latitude: "-23.7551",
    Longitude: "28.7164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16177",
    WardLookupId: "4013",
    VDNumber: "76480151",
    RegisteredPopulation: "582",
    VotingStationName: "RAMOHLATSWANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  KROMKLOOF  MOKERONG",
    Latitude: "-23.7828",
    Longitude: "28.6535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16178",
    WardLookupId: "4013",
    VDNumber: "76480173",
    RegisteredPopulation: "629",
    VotingStationName: "BOKWIDI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  DOORINGFONTEIN  BOKWIDI",
    Latitude: "-23.8241",
    Longitude: "28.6534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16179",
    WardLookupId: "4013",
    VDNumber: "76480195",
    RegisteredPopulation: "454",
    VotingStationName: "SEKANEKAMOYI PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  JAKKALSKUIL  BAKENBURG",
    Latitude: "-23.833",
    Longitude: "28.6078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16180",
    WardLookupId: "4013",
    VDNumber: "76480218",
    RegisteredPopulation: "445",
    VotingStationName: "NKONTLHA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  KABEANE  BAKENBURG",
    Latitude: "-23.8582",
    Longitude: "28.6117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16181",
    WardLookupId: "4013",
    VDNumber: "76480353",
    RegisteredPopulation: "811",
    VotingStationName: "SUSWE PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  MABULA-DIHLARE  BAKENBURG",
    Latitude: "-23.8822",
    Longitude: "28.683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16182",
    WardLookupId: "4013",
    VDNumber: "76480364",
    RegisteredPopulation: "464",
    VotingStationName: "RAMOKGAKGELE PRIMARY SCHOOL",
    Address: "NO STR NUMBER  HERMANSDAL  BAKENBURG",
    Latitude: "-23.8769",
    Longitude: "28.6594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16183",
    WardLookupId: "4013",
    VDNumber: "76480623",
    RegisteredPopulation: "609",
    VotingStationName: "NKGORU PRIMARY SCHOOL",
    Address: "BASTERSPAD  MOKOPANE  BAKENBERG",
    Latitude: "-23.8883",
    Longitude: "28.6221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16184",
    WardLookupId: "4013",
    VDNumber: "76481185",
    RegisteredPopulation: "286",
    VotingStationName: "ALLIANCE CHURCH",
    Address: "BASTERSPAD  MOKOPANE  BAKENBERG",
    Latitude: "-23.9001",
    Longitude: "28.6269",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16185",
    WardLookupId: "4013",
    VDNumber: "76481196",
    RegisteredPopulation: "93",
    VotingStationName: "GEORGE MASEBE HOSPITAL TENT",
    Address: "MABULA DIHLARE  MOKOPANE  BAKENBERG",
    Latitude: "-23.8751",
    Longitude: "28.6924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16186",
    WardLookupId: "4014",
    VDNumber: "76480140",
    RegisteredPopulation: "312",
    VotingStationName: "KGOPEDI PRIMARY SCHOOL TAOLOME",
    Address: "NO STREET ADDRESS  TAOLOME VILLAGE  MOKERONG",
    Latitude: "-23.7841",
    Longitude: "28.8137",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16187",
    WardLookupId: "4014",
    VDNumber: "76480184",
    RegisteredPopulation: "320",
    VotingStationName: "SEREMA HIGH SCHOOL",
    Address: "NO STREET ADDRESS  VAN WYKSPAN  MOKOPANE",
    Latitude: "-23.8152",
    Longitude: "28.6854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16188",
    WardLookupId: "4014",
    VDNumber: "76480230",
    RegisteredPopulation: "1346",
    VotingStationName: "LEUBANENG PRIMARY SCHOOL",
    Address: "MARULANENG  MOKERONG",
    Latitude: "-23.8499",
    Longitude: "28.6944",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16189",
    WardLookupId: "4014",
    VDNumber: "76480386",
    RegisteredPopulation: "229",
    VotingStationName: "SELWABOSHIGO SCHOOL",
    Address: "NO STREET NUMBER  GOEDEHOOP  MOKOPANE",
    Latitude: "-23.801",
    Longitude: "28.7838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16190",
    WardLookupId: "4014",
    VDNumber: "76480397",
    RegisteredPopulation: "260",
    VotingStationName: "MASIPA PRIMARY SCHOOL",
    Address: "NO STREET NAME  GA MASIPA  MOKERONG",
    Latitude: "-23.821",
    Longitude: "28.7464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16191",
    WardLookupId: "4014",
    VDNumber: "76480409",
    RegisteredPopulation: "1030",
    VotingStationName: "MALOKONG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  PUDIAKGOPA  MOKOPANE",
    Latitude: "-23.8325",
    Longitude: "28.7934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16192",
    WardLookupId: "4014",
    VDNumber: "76480476",
    RegisteredPopulation: "757",
    VotingStationName: "RABASOTHO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MAUTJANE/KWENAITE  BAKENBERG",
    Latitude: "-23.8553",
    Longitude: "28.7523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16193",
    WardLookupId: "4014",
    VDNumber: "76481073",
    RegisteredPopulation: "512",
    VotingStationName: "KGOMO SHIA KWENA PRIMARY SCHOOL",
    Address: "MARULANENG  MOKOPANE",
    Latitude: "-23.853",
    Longitude: "28.6824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16194",
    WardLookupId: "4014",
    VDNumber: "76481208",
    RegisteredPopulation: "48",
    VotingStationName: "LEPHADIMISHA SECONDARY SCHOOL",
    Address: "GOODHOPE  MOKOPANE  BAKENBERG",
    Latitude: "-23.8085",
    Longitude: "28.8085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16195",
    WardLookupId: "4015",
    VDNumber: "76480421",
    RegisteredPopulation: "398",
    VotingStationName: "NKGAKGAUTHA SCHOOL MALOKONGKOP",
    Address: "NO STREET ADDRESS  MALOKONGKOP  MOKOPANE",
    Latitude: "-23.8645",
    Longitude: "28.8298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16196",
    WardLookupId: "4015",
    VDNumber: "76480432",
    RegisteredPopulation: "555",
    VotingStationName: "ROOIWAL PRIMARY SCHOOL",
    Address: "ROOIWAL  MOKERONG",
    Latitude: "-23.8653",
    Longitude: "28.8484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16197",
    WardLookupId: "4015",
    VDNumber: "76480443",
    RegisteredPopulation: "449",
    VotingStationName: "DITLOTSWANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  DITLOTSWANE VILLAGE  BAKENBURG",
    Latitude: "-23.9106",
    Longitude: "28.8275",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16198",
    WardLookupId: "4015",
    VDNumber: "76480487",
    RegisteredPopulation: "1011",
    VotingStationName: "TALLASENG COMMUNITY HALL",
    Address: "P O BOX 57  BAKENBERG  MOKERONG",
    Latitude: "-23.8604",
    Longitude: "28.7673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16199",
    WardLookupId: "4015",
    VDNumber: "76480498",
    RegisteredPopulation: "343",
    VotingStationName: "BAKENBERG HIGH SCHOOL",
    Address: "NO STREET ADDRESS  BAKENBERG  MOKOPANE",
    Latitude: "-23.8648",
    Longitude: "28.7646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16200",
    WardLookupId: "4015",
    VDNumber: "76480511",
    RegisteredPopulation: "958",
    VotingStationName: "SARAH -LANGA PRE-SCHOOL",
    Address: "MOGALAKWENA  ",
    Latitude: "-23.8647",
    Longitude: "28.7554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16201",
    WardLookupId: "4015",
    VDNumber: "76481095",
    RegisteredPopulation: "191",
    VotingStationName: "BAKENBERG PENSIONERS ASSOCIATION",
    Address: "BAKENBERG VILLAGE  BAKENBERG  BAKENBERG",
    Latitude: "-23.8861",
    Longitude: "28.7635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16202",
    WardLookupId: "4015",
    VDNumber: "76481220",
    RegisteredPopulation: "68",
    VotingStationName: "MATSOGELLA SECONDARY SCHOOL",
    Address: "ROOIWAL  MOKOPANE  MAPELA",
    Latitude: "-23.8557",
    Longitude: "28.8497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16203",
    WardLookupId: "4016",
    VDNumber: "76450024",
    RegisteredPopulation: "1505",
    VotingStationName: "N.D -MOKONYANE",
    Address: "MAHWELERENG  MOGALAKWENA",
    Latitude: "-24.1281",
    Longitude: "29",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16204",
    WardLookupId: "4016",
    VDNumber: "76450158",
    RegisteredPopulation: "1672",
    VotingStationName: "TSWELAPELE CRECHE",
    Address: "1323 NEO STREET, EXT 17 ALUTA PARK  MOKOPANE  MOKOPANE",
    Latitude: "-24.1461",
    Longitude: "29.0036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16205",
    WardLookupId: "4016",
    VDNumber: "76450169",
    RegisteredPopulation: "1406",
    VotingStationName: "CHRIS HANI PRIMARY SCHOOL",
    Address: "CHRIS HANI STREET  ALUTA PARK   MOKOPANE",
    Latitude: "-24.1542",
    Longitude: "28.9995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16206",
    WardLookupId: "4017",
    VDNumber: "76480500",
    RegisteredPopulation: "962",
    VotingStationName: "GODWIN MASEBE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  PHAFOLA  MOKERONG",
    Latitude: "-23.9136",
    Longitude: "28.8796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16207",
    WardLookupId: "4017",
    VDNumber: "76480522",
    RegisteredPopulation: "1311",
    VotingStationName: "MALEYA HIGH SCHOOL",
    Address: "SEKURIEWE  MOKERONG",
    Latitude: "-23.941",
    Longitude: "28.9443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16208",
    WardLookupId: "4017",
    VDNumber: "76480566",
    RegisteredPopulation: "843",
    VotingStationName: "KHUTSANG PRIMARY SCHOOL",
    Address: "SKIMMING  MAPELA",
    Latitude: "-23.9818",
    Longitude: "28.88",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16209",
    WardLookupId: "4017",
    VDNumber: "76480577",
    RegisteredPopulation: "439",
    VotingStationName: "TLAKANA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-CHABA  BAKENBURG",
    Latitude: "-23.9525",
    Longitude: "28.8637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16210",
    WardLookupId: "4017",
    VDNumber: "76480836",
    RegisteredPopulation: "663",
    VotingStationName: "LUXEMBURG -PRIMARY SCHOOL",
    Address: "WITRIVIER  MOGALAKWENA",
    Latitude: "-23.8888",
    Longitude: "28.9079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16211",
    WardLookupId: "4017",
    VDNumber: "76480881",
    RegisteredPopulation: "640",
    VotingStationName: "LIMBURG PRIMARY SCHOOL",
    Address: "LIMBURG  MAPELA  MOGALAKWENA [POTGIETERSRUS]",
    Latitude: "-23.8619",
    Longitude: "28.9157",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16212",
    WardLookupId: "4017",
    VDNumber: "76481107",
    RegisteredPopulation: "122",
    VotingStationName: "MOHLOHLO SOCCER GROUND (TENT)",
    Address: "MOHLOHLO VILLAGE  OLD MOHLOHLO  MOKOAPANE",
    Latitude: "-23.9574",
    Longitude: "28.9055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16213",
    WardLookupId: "4018",
    VDNumber: "76480588",
    RegisteredPopulation: "1043",
    VotingStationName: "MABUELA RAMORULANE PRIMARY SCHOOL",
    Address: "MABUELA RAMORULANE  ",
    Latitude: "-23.9512",
    Longitude: "28.8053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16214",
    WardLookupId: "4018",
    VDNumber: "76480656",
    RegisteredPopulation: "513",
    VotingStationName: "MAMAGOLA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MAMAALA-MAGOPE  BAKENBURG",
    Latitude: "-23.9742",
    Longitude: "28.8478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16215",
    WardLookupId: "4018",
    VDNumber: "76480667",
    RegisteredPopulation: "416",
    VotingStationName: "MOSOGE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MOSOGE VILLAGE  BAKENBURG",
    Latitude: "-23.9463",
    Longitude: "28.8331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16216",
    WardLookupId: "4018",
    VDNumber: "76480678",
    RegisteredPopulation: "525",
    VotingStationName: "MMOPA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MABUSELA  BAKENBURG",
    Latitude: "-23.9342",
    Longitude: "28.8453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16217",
    WardLookupId: "4018",
    VDNumber: "76480689",
    RegisteredPopulation: "656",
    VotingStationName: "MAPELA JUNIOR PRIMARY SCHOOL",
    Address: "7 FOTHANE VILLAGE  MAPELA  MAPELA",
    Latitude: "-23.958",
    Longitude: "28.8476",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16218",
    WardLookupId: "4018",
    VDNumber: "76480690",
    RegisteredPopulation: "791",
    VotingStationName: "MANTUTULE SENIOR SECONDARY SCHOOL",
    Address: "MAPELA  MOKOPANE  MAPELA",
    Latitude: "-23.9499",
    Longitude: "28.8432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16219",
    WardLookupId: "4018",
    VDNumber: "76480825",
    RegisteredPopulation: "453",
    VotingStationName: "MAPUSO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MMAHLOGO  MOKOPANE",
    Latitude: "-23.9657",
    Longitude: "28.8143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16220",
    WardLookupId: "4018",
    VDNumber: "76480904",
    RegisteredPopulation: "612",
    VotingStationName: "MMATEDU SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS  MAPELA  MOGALAKWENA [POTGIETERSRUS]",
    Latitude: "-23.9507",
    Longitude: "28.802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16221",
    WardLookupId: "4019",
    VDNumber: "76480319",
    RegisteredPopulation: "693",
    VotingStationName: "SESHWAHLA SECONDARY SCHOOL",
    Address: "NO STREET ADRESS  RANTLAKANA  BAKENBURG",
    Latitude: "-23.9369",
    Longitude: "28.6226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16222",
    WardLookupId: "4019",
    VDNumber: "76480320",
    RegisteredPopulation: "751",
    VotingStationName: "MANGWEDI PRIMARY SCHOOL",
    Address: "MAKENENG  MOKERONG",
    Latitude: "-23.9515",
    Longitude: "28.6204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16223",
    WardLookupId: "4019",
    VDNumber: "76480342",
    RegisteredPopulation: "624",
    VotingStationName: "ABIOT KOLOBE PRIMARY SCHOOL",
    Address: "LEYDEN  BAKENBURG",
    Latitude: "-23.9237",
    Longitude: "28.6862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16224",
    WardLookupId: "4019",
    VDNumber: "76480375",
    RegisteredPopulation: "622",
    VotingStationName: "GIVEN MANGOLO PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  KADITSHWENE  BAKENBURG",
    Latitude: "-23.9026",
    Longitude: "28.7097",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16225",
    WardLookupId: "4019",
    VDNumber: "76480454",
    RegisteredPopulation: "512",
    VotingStationName: "MPHAKA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  SEPHARANE  BAKENBURG",
    Latitude: "-23.9209",
    Longitude: "28.7755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16226",
    WardLookupId: "4019",
    VDNumber: "76480915",
    RegisteredPopulation: "623",
    VotingStationName: "ELIZABETH KEKAE PRE-PRIMARY SCHOOL",
    Address: "LYDEN VILLAGE  BAKENBERG  MOGALAKWENA [POTGIETERSRUS]",
    Latitude: "-23.9327",
    Longitude: "28.6805",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16227",
    WardLookupId: "4020",
    VDNumber: "76480049",
    RegisteredPopulation: "301",
    VotingStationName: "WEENEN COMBINED SCHOOL",
    Address: "NO STREET ADDRESS  WEENEN FARM (MOKOPANE)  MOGALAKWENA",
    Latitude: "-24.1368",
    Longitude: "29.1367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16228",
    WardLookupId: "4020",
    VDNumber: "76480241",
    RegisteredPopulation: "1307",
    VotingStationName: "MARCUS MASIBE - 05849 PRIMARY SCHOOL",
    Address: "GA- PILA STERKWATER  GA -PILA- STIRKWATER  MOGALAKWENA",
    Latitude: "-24.0585",
    Longitude: "28.8151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16229",
    WardLookupId: "4020",
    VDNumber: "76480296",
    RegisteredPopulation: "471",
    VotingStationName: "STERKRIVIER HOÃ‹RSKOOL",
    Address: "PO STERKRIVIER  STERKRIVIER FARM  MOKERONG",
    Latitude: "-24.1582",
    Longitude: "28.7388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16230",
    WardLookupId: "4020",
    VDNumber: "76480410",
    RegisteredPopulation: "57",
    VotingStationName: "TINEMYN POLICE STATION",
    Address: "ZAAIPLAATS FARM  BAKENBURG",
    Latitude: "-24.0363",
    Longitude: "28.7751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16231",
    WardLookupId: "4020",
    VDNumber: "76480780",
    RegisteredPopulation: "425",
    VotingStationName: "MARIBASHOEK 49 TENT",
    Address: "49 MARIBASHOEK  MOKOPANE  MOKOPANE",
    Latitude: "-24.1953",
    Longitude: "29.0596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16232",
    WardLookupId: "4020",
    VDNumber: "76480959",
    RegisteredPopulation: "221",
    VotingStationName: "EILAND PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MOKOPANE  MOGALAKWENA [POTGIETERSRUS]",
    Latitude: "-24.1488",
    Longitude: "29.1738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16233",
    WardLookupId: "4020",
    VDNumber: "76480982",
    RegisteredPopulation: "196",
    VotingStationName: "KGOSHI`S RESIDENCE(TENT)",
    Address: "STERKWATER  MOKOPANE  MAPELA",
    Latitude: "-24.0437",
    Longitude: "28.8234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16234",
    WardLookupId: "4020",
    VDNumber: "76480993",
    RegisteredPopulation: "404",
    VotingStationName: "MATLHABA PRIMARY SCHOOL",
    Address: "STERKWATER  MOKOPANE",
    Latitude: "-24.052",
    Longitude: "28.8184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16235",
    WardLookupId: "4020",
    VDNumber: "76481231",
    RegisteredPopulation: "107",
    VotingStationName: "CHRYSALIS PRIVATE SCHOOL",
    Address: "47 UITLOOP FARM  MOKOPANE  MOKOPANE",
    Latitude: "-24.1273",
    Longitude: "29.0296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16236",
    WardLookupId: "4020",
    VDNumber: "76520077",
    RegisteredPopulation: "327",
    VotingStationName: "JAAGBAAN LODGE",
    Address: "36 JAAGBAAN  MOKOPANE  MOKOPANE",
    Latitude: "-24.2854",
    Longitude: "28.955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16237",
    WardLookupId: "4020",
    VDNumber: "76520123",
    RegisteredPopulation: "202",
    VotingStationName: "KAALLEEGTE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MOKOPANE  MOGALAKWENA [POTGIETERSRUS]",
    Latitude: "-24.2735",
    Longitude: "28.88",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16238",
    WardLookupId: "4021",
    VDNumber: "76480285",
    RegisteredPopulation: "1247",
    VotingStationName: "SEBILOANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MATLOU  MOKERONG",
    Latitude: "-24.0248",
    Longitude: "28.8535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16239",
    WardLookupId: "4021",
    VDNumber: "76480645",
    RegisteredPopulation: "638",
    VotingStationName: "HANS MASIBE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  HANS  BAKENBURG",
    Latitude: "-23.9979",
    Longitude: "28.8702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16240",
    WardLookupId: "4021",
    VDNumber: "76480757",
    RegisteredPopulation: "1205",
    VotingStationName: "JOHANNES MASIBE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MASHAHLENG  MOKERONG",
    Latitude: "-24.0355",
    Longitude: "28.8887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16241",
    WardLookupId: "4021",
    VDNumber: "76480926",
    RegisteredPopulation: "415",
    VotingStationName: "NKOKONE TRIBAL HALL",
    Address: "GA-MASENYA VILLAGE  MAPELA  MOGALAKWENA [POTGIETERSRUS]",
    Latitude: "-24.0023",
    Longitude: "28.8701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16242",
    WardLookupId: "4021",
    VDNumber: "76481006",
    RegisteredPopulation: "473",
    VotingStationName: "BATHUSENG O.V.C CENTRE",
    Address: "MASHAHLENG  MOKOPANE  MASHAHLENG",
    Latitude: "-24.0393",
    Longitude: "28.893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16243",
    WardLookupId: "4021",
    VDNumber: "76481017",
    RegisteredPopulation: "453",
    VotingStationName: "LUTHERAN CHURCH",
    Address: "GA CHOKOE  MOKOPANE  GA CHOKOE",
    Latitude: "-24.0141",
    Longitude: "28.8662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16244",
    WardLookupId: "4021",
    VDNumber: "76481129",
    RegisteredPopulation: "269",
    VotingStationName: "MADILWE SECONDARY SCHOOL",
    Address: "MAPELA VILLAGE  MAPELA  MAPELA",
    Latitude: "-24.031",
    Longitude: "28.891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16245",
    WardLookupId: "4022",
    VDNumber: "76480544",
    RegisteredPopulation: "829",
    VotingStationName: "PODILE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MOLEKANE  MOKERONG",
    Latitude: "-23.9978",
    Longitude: "28.9412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16246",
    WardLookupId: "4022",
    VDNumber: "76480555",
    RegisteredPopulation: "733",
    VotingStationName: "MACHIKIRI PRIMARY SCHOOL",
    Address: "MACHIKIRI  MOKERONG",
    Latitude: "-24.019",
    Longitude: "28.9579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16247",
    WardLookupId: "4022",
    VDNumber: "76480724",
    RegisteredPopulation: "1085",
    VotingStationName: "JACOB PUKA PRIMARY- SCHOOL",
    Address: "ARMOEDE  MOGALAKWENA",
    Latitude: "-23.9401",
    Longitude: "28.9903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16248",
    WardLookupId: "4022",
    VDNumber: "76480892",
    RegisteredPopulation: "841",
    VotingStationName: "LANGALIBALELE HIGH SCHOOL",
    Address: "NO STREET ADDRESS  MAPELA  MOGALAKWENA [POTGIETERSRUS]",
    Latitude: "-23.99",
    Longitude: "28.937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16249",
    WardLookupId: "4022",
    VDNumber: "76480960",
    RegisteredPopulation: "1051",
    VotingStationName: "JAN MALEBANA PRIMARY- SCHOOL",
    Address: "ARMOED FARM  WATERBERG",
    Latitude: "-23.9634",
    Longitude: "28.9757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16250",
    WardLookupId: "4022",
    VDNumber: "76481130",
    RegisteredPopulation: "555",
    VotingStationName: "IKAMOGELENG COMMUNITY CENTRE",
    Address: "MOLEKANE VILLAGE  GA MOLEKANE  MAPELA",
    Latitude: "-23.9927",
    Longitude: "28.9505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16251",
    WardLookupId: "4023",
    VDNumber: "76480533",
    RegisteredPopulation: "753",
    VotingStationName: "MOSESETJANE COMMUNITY HALL",
    Address: "66 MOKGAPA STREET, MOSESETJANE  MOKOPANE  MOKOPANE",
    Latitude: "-24.0871",
    Longitude: "28.9273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16252",
    WardLookupId: "4023",
    VDNumber: "76480599",
    RegisteredPopulation: "1152",
    VotingStationName: "MADIKOTIPUTSOA PRIMARY SCHOOL",
    Address: "SANDLOOT  ",
    Latitude: "-24.0432",
    Longitude: "28.8953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16253",
    WardLookupId: "4023",
    VDNumber: "76480612",
    RegisteredPopulation: "896",
    VotingStationName: "KGWAHLELE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MALEPETLEKE  MOKERONG",
    Latitude: "-24.079",
    Longitude: "28.9129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16254",
    WardLookupId: "4023",
    VDNumber: "76480814",
    RegisteredPopulation: "405",
    VotingStationName: "MASENYA COMMUNITY CENTRE",
    Address: "10405 GA MASENYA VILLAGE SANDSLOOT  SANDSLOOT  MAPELA",
    Latitude: "-24.0364",
    Longitude: "28.9056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16255",
    WardLookupId: "4023",
    VDNumber: "76480937",
    RegisteredPopulation: "557",
    VotingStationName: "SEPEDI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MOKOPANE  MOGALAKWENA [POTGIETERSRUS]",
    Latitude: "-24.0851",
    Longitude: "28.9293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16256",
    WardLookupId: "4023",
    VDNumber: "76481028",
    RegisteredPopulation: "508",
    VotingStationName: "MATSIBE HIGH SCHOOL",
    Address: "MALEPETLEKE  MOKOPANE  MALEPETLEKE",
    Latitude: "-24.0793",
    Longitude: "28.9161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16257",
    WardLookupId: "4023",
    VDNumber: "76481141",
    RegisteredPopulation: "541",
    VotingStationName: "BETHEL INTERNATIONAL CHRISTIAN CHURCH",
    Address: "MOSESETJANE VILLAGE  MOKOPANE  MOSESETJANE",
    Latitude: "-24.082",
    Longitude: "28.9356",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16258",
    WardLookupId: "4024",
    VDNumber: "76480308",
    RegisteredPopulation: "1167",
    VotingStationName: "NKGODI SIPHINGO PRIMARY SCHOOL",
    Address: "MAGONGOA  MOKERONG",
    Latitude: "-24.0692",
    Longitude: "28.9709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16259",
    WardLookupId: "4024",
    VDNumber: "76480746",
    RegisteredPopulation: "859",
    VotingStationName: "MOSHIBUDI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MOKABA  MOKERONG",
    Latitude: "-24.0513",
    Longitude: "28.9436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16260",
    WardLookupId: "4024",
    VDNumber: "76481039",
    RegisteredPopulation: "709",
    VotingStationName: "SOMAVHUGHA SECONDARY SCHOOL",
    Address: "NTONA MATJEKE SECTION  MOKOPANE  TSHAMAHANZI",
    Latitude: "-24.0741",
    Longitude: "28.9781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16261",
    WardLookupId: "4024",
    VDNumber: "76481040",
    RegisteredPopulation: "391",
    VotingStationName: "MAHLASEDI CRECHE",
    Address: "702 GA-MAGONGOA ZONE 3  MOKOPANE  MOKOPANE",
    Latitude: "-24.0595",
    Longitude: "28.976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16262",
    WardLookupId: "4024",
    VDNumber: "76481051",
    RegisteredPopulation: "585",
    VotingStationName: "BEN HLONGWANE SECONDARY SCHOOL",
    Address: "HLONGWANE SECTION   MOKOPANE  TSHAMANZHI",
    Latitude: "-24.0804",
    Longitude: "28.9874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16263",
    WardLookupId: "4024",
    VDNumber: "76481118",
    RegisteredPopulation: "185",
    VotingStationName: "MMOLAWA SECONDARY SCHOOL",
    Address: "GA-MOKABA  MAPELA",
    Latitude: "-24.0532",
    Longitude: "28.9513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16264",
    WardLookupId: "4024",
    VDNumber: "76481152",
    RegisteredPopulation: "455",
    VotingStationName: "MOLOHLA CRECHE",
    Address: "MAGONGOA ZONE 2  MOKOPANE  MAPELA",
    Latitude: "-24.066",
    Longitude: "28.9743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16265",
    WardLookupId: "4024",
    VDNumber: "76481242",
    RegisteredPopulation: "228",
    VotingStationName: "LINDELA CRECHE",
    Address: "TSHAMAHANZI  MOKOPANE  MOSHATE",
    Latitude: "-24.0731",
    Longitude: "28.9901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16266",
    WardLookupId: "4025",
    VDNumber: "76480027",
    RegisteredPopulation: "2153",
    VotingStationName: "CHUMANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  TSHAMAHANZI  MOKOPANE",
    Latitude: "-24.0768",
    Longitude: "28.9778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16267",
    WardLookupId: "4025",
    VDNumber: "76480791",
    RegisteredPopulation: "1421",
    VotingStationName: "MAKGUBUKETJA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  TSHAMAHANZI  MOGALAKWENA",
    Latitude: "-24.0848",
    Longitude: "28.9862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16268",
    WardLookupId: "4025",
    VDNumber: "76481163",
    RegisteredPopulation: "446",
    VotingStationName: "DUMAZI PRIMARY SCHOOL",
    Address: "TSHAMAHANSI VILLAGE  TSHAMAHANSI  TSHAMAHANSI VILLAGE",
    Latitude: "-24.0912",
    Longitude: "28.9935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16269",
    WardLookupId: "4025",
    VDNumber: "76481174",
    RegisteredPopulation: "390",
    VotingStationName: "MOSESANE BALOYI PRIMARY SCHOOL",
    Address: "TSHAMAHANSI VILLAGE  ",
    Latitude: "-24.0877",
    Longitude: "28.9745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16270",
    WardLookupId: "4026",
    VDNumber: "76450080",
    RegisteredPopulation: "1017",
    VotingStationName: "LEGAGANENG SCHOOL",
    Address: "NO STREET ADDRESS  MARUTENG  MOKOPANE",
    Latitude: "-24.1421",
    Longitude: "28.947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16271",
    WardLookupId: "4026",
    VDNumber: "76450091",
    RegisteredPopulation: "1703",
    VotingStationName: "JAKAMENE PRIMARY SCHOOL",
    Address: "VAALTYN  MOKOPANE",
    Latitude: "-24.1519",
    Longitude: "28.9436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16272",
    WardLookupId: "4026",
    VDNumber: "76450316",
    RegisteredPopulation: "425",
    VotingStationName: "KGABETLI PRIMARY SCHOOL",
    Address: "15 MAROTENG  MOKOPANE  MOSHATE",
    Latitude: "-24.1388",
    Longitude: "28.9467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16273",
    WardLookupId: "4026",
    VDNumber: "76450361",
    RegisteredPopulation: "556",
    VotingStationName: "RAMOGABUDI SECONDARY SCHOOL",
    Address: "451 MAROTENG  MOKOPANE  VAALTYN",
    Latitude: "-24.1459",
    Longitude: "28.9396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16274",
    WardLookupId: "4027",
    VDNumber: "76450226",
    RegisteredPopulation: "1050",
    VotingStationName: "MAMANGINA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MASODI  MOKOPANE",
    Latitude: "-24.1303",
    Longitude: "28.9587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16275",
    WardLookupId: "4027",
    VDNumber: "76450327",
    RegisteredPopulation: "391",
    VotingStationName: "MARUDA CHRISTIAN SCHOOL & DAY CARE CENTRE",
    Address: "NO STREET NAME  MASODI  MOKOPANE",
    Latitude: "-24.1194",
    Longitude: "28.9627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16276",
    WardLookupId: "4027",
    VDNumber: "76450338",
    RegisteredPopulation: "461",
    VotingStationName: "MASODI BAPTIST CHURCH",
    Address: "MASODI  MOKOPANE  MOKOPANE",
    Latitude: "-24.1152",
    Longitude: "28.9587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16277",
    WardLookupId: "4027",
    VDNumber: "76480601",
    RegisteredPopulation: "1665",
    VotingStationName: "LEKWA SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS  KGOBUDI  BAKENBERG",
    Latitude: "-24.0945",
    Longitude: "28.9447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16278",
    WardLookupId: "4027",
    VDNumber: "76480948",
    RegisteredPopulation: "557",
    VotingStationName: "KGOPEDINOTA PRIMARY SCHOOL",
    Address: "GA-KGOBODI  MOSESETJANE  MOGALAKWENA [POTGIETERSRUS]",
    Latitude: "-24.0893",
    Longitude: "28.9482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16279",
    WardLookupId: "4027",
    VDNumber: "76481062",
    RegisteredPopulation: "803",
    VotingStationName: "MASODI NEW STANDS (TENT)",
    Address: "NO STREET NAME  MASODI  MOKOPANE",
    Latitude: "-24.1169",
    Longitude: "28.9464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16280",
    WardLookupId: "4027",
    VDNumber: "76481084",
    RegisteredPopulation: "853",
    VotingStationName: "HANDS OF COMPASSION CHURCH",
    Address: "256 MZOMBANA VILLAGE  MOKOPANE  MOKOPANE",
    Latitude: "-24.1042",
    Longitude: "28.9613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16281",
    WardLookupId: "4028",
    VDNumber: "76450035",
    RegisteredPopulation: "1123",
    VotingStationName: "NTATA SECONDARY SCHOOL",
    Address: "MADIBA  MOKOPANE",
    Latitude: "-24.1364",
    Longitude: "28.9727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16282",
    WardLookupId: "4028",
    VDNumber: "76450237",
    RegisteredPopulation: "1429",
    VotingStationName: "KGABA HIGH SCHOOL",
    Address: "MADIBA  MOKOPANE",
    Latitude: "-24.1198",
    Longitude: "28.9882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16283",
    WardLookupId: "4028",
    VDNumber: "76450259",
    RegisteredPopulation: "754",
    VotingStationName: "KGATABELA PRIMARY SCHOOL",
    Address: "GA-MADIBA  MOGALAKWENA",
    Latitude: "-24.1304",
    Longitude: "28.9821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16284",
    WardLookupId: "4028",
    VDNumber: "76450349",
    RegisteredPopulation: "345",
    VotingStationName: "LESHOBA PRIMARY SCHOOL",
    Address: "1292 GA-MADIBA  MAHWELERENG  MOKOPANE",
    Latitude: "-24.1373",
    Longitude: "28.9713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16285",
    WardLookupId: "4028",
    VDNumber: "76450350",
    RegisteredPopulation: "350",
    VotingStationName: "THOBELA CENTRE FOR MENTALLY HANDICAPPED",
    Address: "126 BLOCK 9  MOKOPANE  MADIBA VILLAGE",
    Latitude: "-24.1234",
    Longitude: "28.9823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16286",
    WardLookupId: "4029",
    VDNumber: "76450114",
    RegisteredPopulation: "951",
    VotingStationName: "KGATI YA MOSHATE SECONDARY",
    Address: "MOSHATE  VAALTYN  MOKOPANE",
    Latitude: "-24.1595",
    Longitude: "28.9612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16287",
    WardLookupId: "4029",
    VDNumber: "76450215",
    RegisteredPopulation: "487",
    VotingStationName: "UNITING REFORMED CHURCH",
    Address: "NO STREET ADDRESS AVAILABLE  MOSHATE  MOGALAKWENA",
    Latitude: "-24.15",
    Longitude: "28.9584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16288",
    WardLookupId: "4029",
    VDNumber: "76450248",
    RegisteredPopulation: "664",
    VotingStationName: "MASEHLANENG SOCCER GROUND (TENT)",
    Address: "NO STREET ADDRESS  MASEHLANENG  MOKOPANE",
    Latitude: "-24.1382",
    Longitude: "28.963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16289",
    WardLookupId: "4029",
    VDNumber: "76450260",
    RegisteredPopulation: "716",
    VotingStationName: "TLHAKODISHO CARE CENTRE",
    Address: "MOSHATE  MOGALAKWENA",
    Latitude: "-24.1423",
    Longitude: "28.9573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16290",
    WardLookupId: "4029",
    VDNumber: "76450271",
    RegisteredPopulation: "1204",
    VotingStationName: "MOSHATE TRIBAL OFFICE",
    Address: "VAALTYN  MOKOPANE",
    Latitude: "-24.1483",
    Longitude: "28.9524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16291",
    WardLookupId: "4029",
    VDNumber: "76450372",
    RegisteredPopulation: "455",
    VotingStationName: "BAMBANANI OLD AGE CENTRE",
    Address: "GA-MICHELE  MOKOPANE  MOKOPANE",
    Latitude: "-24.1619",
    Longitude: "28.981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16292",
    WardLookupId: "4029",
    VDNumber: "76450394",
    RegisteredPopulation: "350",
    VotingStationName: "THUSONG GENERAL DEALER (TENT)",
    Address: "407 MOSHATE  MOKOPANE  MOSHATE",
    Latitude: "-24.1593",
    Longitude: "28.9578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16293",
    WardLookupId: "4029",
    VDNumber: "76450518",
    RegisteredPopulation: "300",
    VotingStationName: "TENT - PARKMORE",
    Address: "SEKGAKGAPENG PARKMORE  MOSHATE  MOKOPANE",
    Latitude: "-24.1726",
    Longitude: "28.9832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16294",
    WardLookupId: "4030",
    VDNumber: "76450068",
    RegisteredPopulation: "1945",
    VotingStationName: "RAPHELA PRIMARY SCHOOL",
    Address: "2284 ZONE 1  MAHWELERENG  MOKOPANE",
    Latitude: "-24.1421",
    Longitude: "28.9776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16295",
    WardLookupId: "4030",
    VDNumber: "76450204",
    RegisteredPopulation: "889",
    VotingStationName: "MOUNTAINVIEW SOCCER GROUNDS (TENT)",
    Address: "MOUNTAINVIEW  MOKOPANE",
    Latitude: "-24.1478",
    Longitude: "28.9719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16296",
    WardLookupId: "4030",
    VDNumber: "76450451",
    RegisteredPopulation: "751",
    VotingStationName: "NOMALEMA LOWER PRIMARY SCHOOL",
    Address: "MAHWELERENG  MOKOPANE",
    Latitude: "-24.1503",
    Longitude: "28.9781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16297",
    WardLookupId: "4030",
    VDNumber: "76450462",
    RegisteredPopulation: "612",
    VotingStationName: "CHURCH OF NAZARENE",
    Address: "MAHWELERENG  MOKOPANE",
    Latitude: "-24.1413",
    Longitude: "28.9839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16298",
    WardLookupId: "4030",
    VDNumber: "76450473",
    RegisteredPopulation: "982",
    VotingStationName: "MSHONGO SOCCER GROUNDS (TENT)",
    Address: "MAHWELERENG  MSHONGO  MOKOPANE",
    Latitude: "-24.1361",
    Longitude: "28.9877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16299",
    WardLookupId: "4031",
    VDNumber: "76450046",
    RegisteredPopulation: "1514",
    VotingStationName: "EBENEZER HIGH SCHOOL",
    Address: "227 ZONE 2  MAHWELERENG  MOKOPANE",
    Latitude: "-24.1364",
    Longitude: "28.9934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16300",
    WardLookupId: "4031",
    VDNumber: "76450192",
    RegisteredPopulation: "1694",
    VotingStationName: "SEGOOAKGALA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS   MAHWELERENG B  MOKOPANE",
    Latitude: "-24.1481",
    Longitude: "28.9867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16301",
    WardLookupId: "4031",
    VDNumber: "76450440",
    RegisteredPopulation: "785",
    VotingStationName: "TVS ZONE B TENT",
    Address: "MOKOPANE  ",
    Latitude: "-24.1322",
    Longitude: "28.9929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16302",
    WardLookupId: "4031",
    VDNumber: "76450484",
    RegisteredPopulation: "265",
    VotingStationName: "EDL RAMPOLA SECONDARY SCHOOL",
    Address: "MAHWELERENG  MOKOPANE",
    Latitude: "-24.1418",
    Longitude: "28.9917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16303",
    WardLookupId: "4032",
    VDNumber: "76450013",
    RegisteredPopulation: "1830",
    VotingStationName: "UNIT B (TENT)",
    Address: "UNIT B  MAHWELERENG  GREATER POTGIETERSRUS",
    Latitude: "-24.137",
    Longitude: "29.0002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16304",
    WardLookupId: "4032",
    VDNumber: "76450057",
    RegisteredPopulation: "1987",
    VotingStationName: "KGATELOPELE PRIMARY SCHOOL",
    Address: "HOSPITALVIEW  MOKOPANE",
    Latitude: "-24.1447",
    Longitude: "28.9962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16305",
    WardLookupId: "4032",
    VDNumber: "76450495",
    RegisteredPopulation: "1158",
    VotingStationName: "HOSPITALVIEW (TENT)",
    Address: "MAHWELERENG  MOKOPANE",
    Latitude: "-24.1547",
    Longitude: "28.9933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16306",
    WardLookupId: "4033",
    VDNumber: "76450125",
    RegisteredPopulation: "1556",
    VotingStationName: "KGAMANYANA -PRIMARY SCHOOL",
    Address: "POLAPARK  MOGALAKWENA",
    Latitude: "-24.1646",
    Longitude: "28.9856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16307",
    WardLookupId: "4033",
    VDNumber: "76450293",
    RegisteredPopulation: "2349",
    VotingStationName: "GWENANE SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  SEKGAKGAPENG EXT 19  WATERBERG  ",
    Latitude: "-24.1761",
    Longitude: "28.9853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16308",
    WardLookupId: "4033",
    VDNumber: "76450305",
    RegisteredPopulation: "884",
    VotingStationName: "MOKOPANE SOUTH PRIMARY SCHOOL",
    Address: "EXT 14  MOKOPANE",
    Latitude: "-24.1873",
    Longitude: "28.9849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16309",
    WardLookupId: "4033",
    VDNumber: "76450507",
    RegisteredPopulation: "642",
    VotingStationName: "MOGALAKWENA FOOTBALL FOR HOPE",
    Address: "PHOLA PARK  MAHWELERENG  MOKOPANE",
    Latitude: "-24.1676",
    Longitude: "28.9952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16310",
    WardLookupId: "4034",
    VDNumber: "76450136",
    RegisteredPopulation: "1971",
    VotingStationName: "MAGEME PRIMARY SCHOOL",
    Address: "SEKGAKGAPENG  MOGALAKWENA  SEKGAKGAPENG",
    Latitude: "-24.1741",
    Longitude: "28.9779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16311",
    WardLookupId: "4034",
    VDNumber: "76450147",
    RegisteredPopulation: "2461",
    VotingStationName: "NKAKABIDI SECONDARY SCHOOL",
    Address: "SEKGAKGAPENG  MOKOPANE",
    Latitude: "-24.1717",
    Longitude: "28.964",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16312",
    WardLookupId: "4034",
    VDNumber: "76450406",
    RegisteredPopulation: "576",
    VotingStationName: "MADIPHATLO-KGOMO PRIMARY SCHOOL",
    Address: "SEKGAKGAPENG  MOKOPANE  SEKGAKGAPENG",
    Latitude: "-24.1805",
    Longitude: "28.9723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16313",
    WardLookupId: "4035",
    VDNumber: "76450181",
    RegisteredPopulation: "1438",
    VotingStationName: "POTGIETERSRUS LAERSKOOL",
    Address: "00 GEYSER STREET  POTGIETERSRUS  MOKOPANE",
    Latitude: "-24.1956",
    Longitude: "29.0122",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16314",
    WardLookupId: "4035",
    VDNumber: "76450417",
    RegisteredPopulation: "1993",
    VotingStationName: "PIET POTGIETER HOÃ‹RSKOOL",
    Address: "GEYSER STREET  MOKOPANE  MOKOPANE",
    Latitude: "-24.1943",
    Longitude: "29.0084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16315",
    WardLookupId: "4035",
    VDNumber: "76450428",
    RegisteredPopulation: "1027",
    VotingStationName: "OPEN SPACE EXT 14 (TENT)",
    Address: "NEW SETTLEMENTS  MOKOPANE  EXTENSION 14",
    Latitude: "-24.1949",
    Longitude: "28.9882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16316",
    WardLookupId: "4035",
    VDNumber: "76450529",
    RegisteredPopulation: "438",
    VotingStationName: "WATERBERG HIGH SCHOOL",
    Address: "NYL PARK  MOKOPANE  MOKOPANE",
    Latitude: "-24.1889",
    Longitude: "28.9971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16317",
    WardLookupId: "4036",
    VDNumber: "76450170",
    RegisteredPopulation: "2185",
    VotingStationName: "MAYORS PARLOUR",
    Address: "54 RETIEF STREET  POTGIETERSRUS  MOKERONG",
    Latitude: "-24.1833",
    Longitude: "29.01",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16318",
    WardLookupId: "4036",
    VDNumber: "76450282",
    RegisteredPopulation: "1181",
    VotingStationName: "POTGIETERSRUS PRIMARY SCHOOL",
    Address: "AKASIA   MOKOPANE  MOGALAKWENA [POTGIETERSRUS]",
    Latitude: "-24.1786",
    Longitude: "28.9988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16319",
    WardLookupId: "4036",
    VDNumber: "76450439",
    RegisteredPopulation: "907",
    VotingStationName: "WRESTLING CLUB",
    Address: "RABE  MOKOPANE  MOKOPANE",
    Latitude: "-24.1909",
    Longitude: "29.0173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16320",
    WardLookupId: "1995",
    VDNumber: "76530012",
    RegisteredPopulation: "1561",
    VotingStationName: "VAALWATER PRIMARY SCHOOL",
    Address: "60 KIRKMAN STREET  VAALWATER  VAALWATER",
    Latitude: "-24.2979",
    Longitude: "28.1153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16321",
    WardLookupId: "1995",
    VDNumber: "76530203",
    RegisteredPopulation: "1637",
    VotingStationName: "LESEDING COMMUNITY HALL",
    Address: "LESEDING  MODIMOLLE [NYLSTROOM]",
    Latitude: "-24.2946",
    Longitude: "28.1234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16322",
    WardLookupId: "1995",
    VDNumber: "76530214",
    RegisteredPopulation: "1079",
    VotingStationName: "MAHLASEDI PRIMARY SCHOOL",
    Address: "LESEDING  VAALWATER",
    Latitude: "-24.2929",
    Longitude: "28.1301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16323",
    WardLookupId: "1996",
    VDNumber: "76530023",
    RegisteredPopulation: "142",
    VotingStationName: "GROENFONTEIN 141 KR",
    Address: "GROENFONTEIN  MODIMOLLE  VAALWATER",
    Latitude: "-24.3277",
    Longitude: "28.1089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16324",
    WardLookupId: "1996",
    VDNumber: "76530034",
    RegisteredPopulation: "1013",
    VotingStationName: "MOSHIA HIGH SCHOOL",
    Address: "MOSHIA  MABALENG  MABALENG",
    Latitude: "-24.4866",
    Longitude: "28.0653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16325",
    WardLookupId: "1996",
    VDNumber: "76530056",
    RegisteredPopulation: "315",
    VotingStationName: "LAERSKOOL DOORNFONTEIN",
    Address: "DOORNFONTEIN,PK ATOOM  DOORNFONTEIN, PK ATOOM  MODIMOLLE",
    Latitude: "-24.5524",
    Longitude: "28.3213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16326",
    WardLookupId: "1996",
    VDNumber: "76530067",
    RegisteredPopulation: "234",
    VotingStationName: "BOSCHDRAAI PRIMARY SCHOOL",
    Address: "BOSCHDRAAI FARM  VAALWATER  MODIMOLLE-MOOKGOPHONG",
    Latitude: "-24.1903",
    Longitude: "28.2885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16327",
    WardLookupId: "1996",
    VDNumber: "76530089",
    RegisteredPopulation: "249",
    VotingStationName: "MOHLAKAMOTALA HIGH SCHOOL",
    Address: "BOEKENHOUTSKLOOF  MODIMOLLE",
    Latitude: "-24.4571",
    Longitude: "28.1621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16328",
    WardLookupId: "1996",
    VDNumber: "76530146",
    RegisteredPopulation: "275",
    VotingStationName: "TREDSOM ROAD CAMP",
    Address: "NONE  VAALWATER  VAALWATER",
    Latitude: "-24.0873",
    Longitude: "28.2988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16329",
    WardLookupId: "1996",
    VDNumber: "76530179",
    RegisteredPopulation: "425",
    VotingStationName: "RATLADI SCHOOL (NYLSTEENMAKERY)",
    Address: "NYL STEENMAKERY  LOUBAD  MODIMOLLE",
    Latitude: "-24.5935",
    Longitude: "28.246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16330",
    WardLookupId: "1996",
    VDNumber: "76530180",
    RegisteredPopulation: "136",
    VotingStationName: "RANKINS PASS",
    Address: "RANKINS  RANKINS PASS  ALMA",
    Latitude: "-24.5289",
    Longitude: "27.9138",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16331",
    WardLookupId: "1996",
    VDNumber: "76530191",
    RegisteredPopulation: "195",
    VotingStationName: "REAHLAHLWA  SCHOOL",
    Address: "OLIFANTSBEEN  VAALWATER",
    Latitude: "-24.2276",
    Longitude: "28.0571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16332",
    WardLookupId: "1996",
    VDNumber: "76530225",
    RegisteredPopulation: "131",
    VotingStationName: "HEUNINGFONTEIN KONTANT WINKEL",
    Address: "ALMA  MODIMOLLE",
    Latitude: "-24.4748",
    Longitude: "28.291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16333",
    WardLookupId: "1996",
    VDNumber: "76530326",
    RegisteredPopulation: "41",
    VotingStationName: "EA DAVIDSON MEMORIAL SCHOOL",
    Address: "VAALWATER  ",
    Latitude: "-24.267",
    Longitude: "28.2763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16334",
    WardLookupId: "1996",
    VDNumber: "76540158",
    RegisteredPopulation: "360",
    VotingStationName: "NYLOEWER CAFE (PADSTAL)",
    Address: "SUSSENVALE  MODIMOLLE",
    Latitude: "-24.755",
    Longitude: "28.3533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16335",
    WardLookupId: "1997",
    VDNumber: "76530045",
    RegisteredPopulation: "2618",
    VotingStationName: "MOKOLO PRIMARY SCHOOL",
    Address: "LETSHOKGOHLA  LESEDING EXT. 1  MODIMOLLE [NYLSTROOM]",
    Latitude: "-24.2893",
    Longitude: "28.1216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16336",
    WardLookupId: "1997",
    VDNumber: "76530168",
    RegisteredPopulation: "812",
    VotingStationName: "VAALWATER BOERESAAL",
    Address: "10 MAIN ROAD  VAALWATER",
    Latitude: "-24.2844",
    Longitude: "28.1012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16337",
    WardLookupId: "1998",
    VDNumber: "76520099",
    RegisteredPopulation: "1796",
    VotingStationName: "BB MATLAILA P SCHOOL",
    Address: "29TH MOOKGOPHONG EXT 2  WATERBERG  MOOKGOPHONG",
    Latitude: "-24.5176",
    Longitude: "28.7297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16338",
    WardLookupId: "1998",
    VDNumber: "76520156",
    RegisteredPopulation: "805",
    VotingStationName: "MOOKGOPHONG PRIMARY SCHOOL",
    Address: "GROOTVALLEY 530 KR  NABOOMSPRUIT  MOOKGOPHONG",
    Latitude: "-24.5235",
    Longitude: "28.7287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16339",
    WardLookupId: "1998",
    VDNumber: "76520189",
    RegisteredPopulation: "1288",
    VotingStationName: "FIRE GOSPEL CENTRE",
    Address: "EXT5  NABOOMSPRUIT  MOOKGOPHONG",
    Latitude: "-24.5206",
    Longitude: "28.735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16340",
    WardLookupId: "1999",
    VDNumber: "76430011",
    RegisteredPopulation: "3204",
    VotingStationName: "LAERSKOOL EUGENE N MARAIS",
    Address: "4TH STREET  MOOKGOPHONG  MOOKGOPHONG",
    Latitude: "-24.5164",
    Longitude: "28.7101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16341",
    WardLookupId: "1999",
    VDNumber: "76520044",
    RegisteredPopulation: "233",
    VotingStationName: "MASUPATSELA FARM",
    Address: "R520  KLEIN PALALA  ELANDBOSCH  NABOOMSPRUIT",
    Latitude: "-24.2598",
    Longitude: "28.4981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16342",
    WardLookupId: "1999",
    VDNumber: "76520055",
    RegisteredPopulation: "387",
    VotingStationName: "LETAMONG COMBINE SCHOOL",
    Address: "BUFFELSDOORNS  MOOKGOPHONG  MOOKGOPHONG",
    Latitude: "-24.4424",
    Longitude: "28.6583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16343",
    WardLookupId: "1999",
    VDNumber: "76520134",
    RegisteredPopulation: "121",
    VotingStationName: "EUPHOBIA PLOT.",
    Address: "EUPHOBIA PLOT  NABOOMSPRUIT  MOOKGOPHONG",
    Latitude: "-24.4788",
    Longitude: "28.7088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16344",
    WardLookupId: "1999",
    VDNumber: "76520167",
    RegisteredPopulation: "228",
    VotingStationName: "TENT AT STERKFONTEIN 305KR",
    Address: "503 STERKFONTEIN  MOOKGOPHONG  MOOKGOPHONG",
    Latitude: "-24.2133",
    Longitude: "28.7297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16345",
    WardLookupId: "1999",
    VDNumber: "76520178",
    RegisteredPopulation: "92",
    VotingStationName: "RONDOMKRANS WINKEL",
    Address: "MAJESTEIT 213 KR  NABOOMSPRUIT  MOOKGOPHONG",
    Latitude: "-24.1437",
    Longitude: "28.5759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16346",
    WardLookupId: "1999",
    VDNumber: "76520202",
    RegisteredPopulation: "235",
    VotingStationName: "BUFLAND TENT",
    Address: "ROODEPOORT 314KR  MOOKGOPHONG  MOOKGOPHONG",
    Latitude: "-24.4055",
    Longitude: "28.6307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16347",
    WardLookupId: "2000",
    VDNumber: "76440067",
    RegisteredPopulation: "1787",
    VotingStationName: "MAOKENG PRIMARY SCHOOL",
    Address: "4162 MASENYA STREET  PHAGAMENG LOCATION  MODIMOLLE [NYLSTROOM]",
    Latitude: "-24.6954",
    Longitude: "28.4478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16348",
    WardLookupId: "2000",
    VDNumber: "76440089",
    RegisteredPopulation: "1175",
    VotingStationName: "SOLOMON MAHLANGU SECONDARY SCHOOL",
    Address: "MONOTONG STREET, 4519  PHAGAMENG  POLOKWANE / WATERBERG",
    Latitude: "-24.6887",
    Longitude: "28.4476",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16349",
    WardLookupId: "2000",
    VDNumber: "76540114",
    RegisteredPopulation: "230",
    VotingStationName: "PITINYANA SKOOL",
    Address: "CYFERFONTEIN  MODIMOLLE",
    Latitude: "-24.8001",
    Longitude: "28.4729",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16350",
    WardLookupId: "2000",
    VDNumber: "76540125",
    RegisteredPopulation: "285",
    VotingStationName: "MODIMOLLE FUNKY STAY",
    Address: "R101 MODIMOLLE  MODIMOLLE  MODIMOLLE",
    Latitude: "-24.6751",
    Longitude: "28.4421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16351",
    WardLookupId: "2000",
    VDNumber: "76540136",
    RegisteredPopulation: "219",
    VotingStationName: "UNA PIZZA LAPA",
    Address: "S  MODIMOLLE  MODIMOLLE",
    Latitude: "-24.7444",
    Longitude: "28.4402",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16352",
    WardLookupId: "2001",
    VDNumber: "76440034",
    RegisteredPopulation: "1786",
    VotingStationName: "MODIMOLLE PRIMARY SCHOOL",
    Address: "20 KUBUZIE STREET  PHAGAMENG  NYLSTROOM",
    Latitude: "-24.6952",
    Longitude: "28.4446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16353",
    WardLookupId: "2001",
    VDNumber: "76440113",
    RegisteredPopulation: "715",
    VotingStationName: "DAGBREEK PRIMARY SCHOOL",
    Address: "PHAGAMENG  MODIMOLLE",
    Latitude: "-24.6963",
    Longitude: "28.4409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16354",
    WardLookupId: "2001",
    VDNumber: "76440135",
    RegisteredPopulation: "62",
    VotingStationName: "NYLSTROOM PRIMARY SCHOOL",
    Address: "1 STEENBOK STREET  INDIAN TOWNSHIP  MODIMOLLE",
    Latitude: "-24.7187",
    Longitude: "28.4207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16355",
    WardLookupId: "2002",
    VDNumber: "76440056",
    RegisteredPopulation: "2300",
    VotingStationName: "PHAGAMENG HIGH SCHOOL",
    Address: "3845 NTLOGE STREET  PHAGAMENG  NYLSTROOM",
    Latitude: "-24.6926",
    Longitude: "28.4392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16356",
    WardLookupId: "2002",
    VDNumber: "76440146",
    RegisteredPopulation: "1127",
    VotingStationName: "KOPANONG CRECHE",
    Address: "6532 PHOMOLONG  MODIMOLLE  MODIMOLLE",
    Latitude: "-24.6932",
    Longitude: "28.426",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16357",
    WardLookupId: "2003",
    VDNumber: "76430033",
    RegisteredPopulation: "83",
    VotingStationName: "MATSWAPILONG FARM",
    Address: "N/A  BOEKENHOUT FARM  NABOOMSPRUIT",
    Latitude: "-24.6404",
    Longitude: "28.6568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16358",
    WardLookupId: "2003",
    VDNumber: "76430044",
    RegisteredPopulation: "1367",
    VotingStationName: "RAINBOW CRECHE`",
    Address: "GROOTVALLEY 529 KR  NABOOMSPRUIT  MOOKGOPHONG",
    Latitude: "-24.5289",
    Longitude: "28.7213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16359",
    WardLookupId: "2003",
    VDNumber: "76430077",
    RegisteredPopulation: "627",
    VotingStationName: "KOPANO CRECHE",
    Address: "EXT4  NABOOMSPRUIT  MOOKGOPHONG",
    Latitude: "-24.527",
    Longitude: "28.7219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16360",
    WardLookupId: "2003",
    VDNumber: "76430088",
    RegisteredPopulation: "51",
    VotingStationName: "BAD SE LOOP FARM",
    Address: "VLAKFONTEIN  BAD SE LOOP  MOOKGOPHONG",
    Latitude: "-24.5743",
    Longitude: "28.6459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16361",
    WardLookupId: "2003",
    VDNumber: "76520033",
    RegisteredPopulation: "1010",
    VotingStationName: "RIETFONTEIN 513 KR (TENT)",
    Address: "RIETFONTEIN  MOOKGOPHONG  NABOOMSPRUIT",
    Latitude: "-24.4491",
    Longitude: "28.6235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16362",
    WardLookupId: "2004",
    VDNumber: "76430022",
    RegisteredPopulation: "2095",
    VotingStationName: "MOOKGOPHONG COMMUNITY HALL",
    Address: "400 KGABO STREET  MOOKGOPHONG  NABOOMSPRUIT",
    Latitude: "-24.522",
    Longitude: "28.7239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16363",
    WardLookupId: "2004",
    VDNumber: "76430055",
    RegisteredPopulation: "712",
    VotingStationName: "DIKUBU PRIMARY SCHOOL",
    Address: "MOOKGOPONG  ",
    Latitude: "-24.5223",
    Longitude: "28.7237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16364",
    WardLookupId: "2004",
    VDNumber: "76520145",
    RegisteredPopulation: "389",
    VotingStationName: "MAKHUTJISHA HIGH SCHOOL",
    Address:
      "CORNER MAMABOLO AND KUTUMELA  STREET  MOKGOPONG TOWNSHIP  POLOKWANE / WATERBERG",
    Latitude: "-24.52",
    Longitude: "28.7265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16365",
    WardLookupId: "2005",
    VDNumber: "76440023",
    RegisteredPopulation: "2064",
    VotingStationName: "DIRA O DIRELWE",
    Address: "PHOMOLONG  MODIMOLLE",
    Latitude: "-24.6982",
    Longitude: "28.4271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16366",
    WardLookupId: "2005",
    VDNumber: "76440045",
    RegisteredPopulation: "1803",
    VotingStationName: "OR TAMBO MEMORIAL HALL",
    Address: "168 HARRY GWALA STREET  MODIMOLLE  WATERBERG",
    Latitude: "-24.7033",
    Longitude: "28.4074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16367",
    WardLookupId: "2005",
    VDNumber: "76440124",
    RegisteredPopulation: "700",
    VotingStationName: "MODIMOLLE CORRECTIONAL SERVICES HALL",
    Address: "NELSON MANDELA ROAD  MODIMOLLE  MODIMOLLE",
    Latitude: "-24.6985",
    Longitude: "28.4048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16368",
    WardLookupId: "2005",
    VDNumber: "76440157",
    RegisteredPopulation: "275",
    VotingStationName: "PETER NCHABALENG MEMORIAL HALL",
    Address: "496 MAZWAZA STREET  PHAGAMENG  MODIMOLLE",
    Latitude: "-24.6986",
    Longitude: "28.436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16369",
    WardLookupId: "2006",
    VDNumber: "76440012",
    RegisteredPopulation: "2564",
    VotingStationName: "SUSAN STRIJDOM SPECIAL SCHOOL",
    Address: "KERK STREET  MODIMOLLE  MODIMOLLE",
    Latitude: "-24.697",
    Longitude: "28.3981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16370",
    WardLookupId: "2006",
    VDNumber: "76440090",
    RegisteredPopulation: "866",
    VotingStationName: "KOKANJE RETIREMENT RESORT",
    Address: "NO STREET DETAIL  KOKANJE  MODIMOLE",
    Latitude: "-24.6866",
    Longitude: "28.3804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16371",
    WardLookupId: "2007",
    VDNumber: "76440078",
    RegisteredPopulation: "2556",
    VotingStationName: "LEKKERBREEK PRIMARY SCHOOL",
    Address: "STAND 6265  PHAGAMENG  MODIMOLLE",
    Latitude: "-24.6957",
    Longitude: "28.4206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16372",
    WardLookupId: "2007",
    VDNumber: "76440102",
    RegisteredPopulation: "1241",
    VotingStationName: "MODIMOLLE EXT 10 (TENT)",
    Address: "MODIMOLLE  EXTENSION 10  MODIMOLLE-MOOKGOPHONG",
    Latitude: "-24.6855",
    Longitude: "28.4154",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16373",
    WardLookupId: "2008",
    VDNumber: "76520011",
    RegisteredPopulation: "652",
    VotingStationName: "ROEDTAN SAPS",
    Address: "MAIN ROAD  ROEDTAN  NABOOMSPRUIT",
    Latitude: "-24.5962",
    Longitude: "29.0815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16374",
    WardLookupId: "2008",
    VDNumber: "76520022",
    RegisteredPopulation: "709",
    VotingStationName: "THUSANG COMMUNITY HALL",
    Address: "KGABO STR  THUSANG  NABOOMSPRUIT",
    Latitude: "-24.6205",
    Longitude: "29.0425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16375",
    WardLookupId: "2008",
    VDNumber: "76520066",
    RegisteredPopulation: "102",
    VotingStationName: "OVERWACHT FARM",
    Address: "OVERWACHT FARM  ROEDTAN/THUSANG  NABOOMSPRUIT",
    Latitude: "-24.866",
    Longitude: "29.0856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16376",
    WardLookupId: "2008",
    VDNumber: "76520088",
    RegisteredPopulation: "442",
    VotingStationName: "GRUISFONTEIN FARM",
    Address: "CRECY  MOOKGOPHONG",
    Latitude: "-24.6319",
    Longitude: "28.8407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16377",
    WardLookupId: "2008",
    VDNumber: "76520190",
    RegisteredPopulation: "127",
    VotingStationName: "MAMRUNYANE FARM COMBINED SCHOOL",
    Address: "GEGUND 332 KR  NABOOMSPRUIT  MOOKGOPHONG",
    Latitude: "-24.4452",
    Longitude: "28.9334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16378",
    WardLookupId: "2008",
    VDNumber: "76520213",
    RegisteredPopulation: "503",
    VotingStationName: "MOOKGOPHONG COMMUNITY CHURCH",
    Address: "NONE  MOOKGHOPONG  MOOKGHOPONG",
    Latitude: "-24.5271",
    Longitude: "28.734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16379",
    WardLookupId: "2008",
    VDNumber: "76520224",
    RegisteredPopulation: "107",
    VotingStationName: "MOOKGOPHONG LAPA",
    Address: "MOOKGOPHONG  MOOKGOPHONG AREA  MOOKGOPHONG EXTENSION 8",
    Latitude: "-24.5339",
    Longitude: "28.7354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16380",
    WardLookupId: "2008",
    VDNumber: "76540013",
    RegisteredPopulation: "258",
    VotingStationName: "TUINPLAAS POLICE STATION",
    Address: "MAIN ROAD  TUINPLAAS  NABOOMSPRUIT",
    Latitude: "-24.9024",
    Longitude: "28.7362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16381",
    WardLookupId: "2008",
    VDNumber: "76540103",
    RegisteredPopulation: "138",
    VotingStationName: "LEEUWAARDEN FARM",
    Address: "RAAD  TSWALO  MOOKGOPHONG",
    Latitude: "-24.8708",
    Longitude: "28.844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16382",
    WardLookupId: "2008",
    VDNumber: "76540248",
    RegisteredPopulation: "141",
    VotingStationName: "MOTHLAPING SCHOOL",
    Address: "FARM  VERLOREN FARM  POLOKWANE / WATERBERG",
    Latitude: "-24.7983",
    Longitude: "28.8391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16383",
    WardLookupId: "2009",
    VDNumber: "54620018",
    RegisteredPopulation: "1424",
    VotingStationName: "MABULE PRIMARY SCHOOL",
    Address: "PHALANE  MALEBITSA  SEKHUKHUNE",
    Latitude: "-24.9029",
    Longitude: "28.9308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16384",
    WardLookupId: "2009",
    VDNumber: "54620344",
    RegisteredPopulation: "838",
    VotingStationName: "CON ROUX PRIMARY SCHOOL",
    Address: "DRIEFONTEIN  MOUTSE 1",
    Latitude: "-24.9211",
    Longitude: "28.9533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16385",
    WardLookupId: "2009",
    VDNumber: "54620636",
    RegisteredPopulation: "427",
    VotingStationName: "MATABANE PRIMARY SCHOOL",
    Address: "MALEBITSA  SEKHUKHUNE  MARBLE HALL",
    Latitude: "-24.893",
    Longitude: "28.9198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16386",
    WardLookupId: "2009",
    VDNumber: "54620793",
    RegisteredPopulation: "429",
    VotingStationName: "SEBJALEBJALE SECONDARY SCHOOL",
    Address: "DRIEFONTEIN  MARBLE HALL  MATLALA",
    Latitude: "-24.9227",
    Longitude: "28.96",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16387",
    WardLookupId: "2010",
    VDNumber: "54620029",
    RegisteredPopulation: "1342",
    VotingStationName: "UITVLUGHT TRIBAL HALL",
    Address: "UITVLUGHT  MARBLE HALL  UITVLUGHT",
    Latitude: "-24.9386",
    Longitude: "28.9995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16388",
    WardLookupId: "2010",
    VDNumber: "54620131",
    RegisteredPopulation: "1375",
    VotingStationName: "RATHOKE PRIMAY SCHOOL",
    Address: "RATHOKE  RATHOKE/MAKEEPSVLEI  MOUTSE 1",
    Latitude: "-24.9289",
    Longitude: "29.0466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16389",
    WardLookupId: "2010",
    VDNumber: "54620489",
    RegisteredPopulation: "784",
    VotingStationName: "EPHRAIM MOGALE PRIMARY SCHOOL",
    Address: "N/A  UITVLUGHT  GREATER MARBLE HALL [MARBLE HALL]",
    Latitude: "-24.9319",
    Longitude: "28.9954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16390",
    WardLookupId: "2010",
    VDNumber: "54620805",
    RegisteredPopulation: "260",
    VotingStationName: "MABAKE SECONDARY SCHOOL",
    Address: "RATHOKE  MARBLE HALL  MARBLE HALL",
    Latitude: "-24.9377",
    Longitude: "29.0432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16391",
    WardLookupId: "2011",
    VDNumber: "54620030",
    RegisteredPopulation: "1322",
    VotingStationName: "TAU-TLOU PRIMARY SCHOOL",
    Address: "N/A  SPITSPUNT  SEKHUKHUNE",
    Latitude: "-24.9539",
    Longitude: "28.9202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16392",
    WardLookupId: "2011",
    VDNumber: "54620041",
    RegisteredPopulation: "1128",
    VotingStationName: "METSANANGWANA PRIMARY SCHOOL",
    Address: "KEEROM  KEEROM VILLAGE  SEKHUKHUNE",
    Latitude: "-24.9623",
    Longitude: "28.998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16393",
    WardLookupId: "2011",
    VDNumber: "54620445",
    RegisteredPopulation: "932",
    VotingStationName: "MMAKOLA PRIMARY SCHOOL",
    Address: "DOORNLAAGTE  SEKHUKHUNE",
    Latitude: "-24.9841",
    Longitude: "28.9749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16394",
    WardLookupId: "2012",
    VDNumber: "54620243",
    RegisteredPopulation: "1147",
    VotingStationName: "REPHAFOGILE SCHOOL",
    Address: "ZAMEKOST  ZAMEKOMST  MOUTSE 1",
    Latitude: "-24.9831",
    Longitude: "29.062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16395",
    WardLookupId: "2012",
    VDNumber: "54620434",
    RegisteredPopulation: "1071",
    VotingStationName: "MORE-O-MOSO SCHOOL",
    Address: "MAKEEPSVLEI  MOUTSE 1",
    Latitude: "-24.9449",
    Longitude: "29.0552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16396",
    WardLookupId: "2012",
    VDNumber: "54620625",
    RegisteredPopulation: "668",
    VotingStationName: "MATLEREKENG PRIMARY SCHOOL",
    Address: "MATLEREKENG  SEKHUKHUNE  MARBLE HALL",
    Latitude: "-24.9811",
    Longitude: "29.0475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16397",
    WardLookupId: "2012",
    VDNumber: "54620816",
    RegisteredPopulation: "390",
    VotingStationName: "BABINATLOU PRIMARY SCHOOL",
    Address: "MATLERIKENG  MARBLE HALL  KEKANA",
    Latitude: "-24.9804",
    Longitude: "29.0693",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16398",
    WardLookupId: "2012",
    VDNumber: "54620906",
    RegisteredPopulation: "82",
    VotingStationName: "REFILWE SECONDARY SCHOOL",
    Address: "TSHIKANOSHI  MARBLE HALL  SEKHUKHUNE",
    Latitude: "-24.9687",
    Longitude: "28.9915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16399",
    WardLookupId: "2013",
    VDNumber: "54620355",
    RegisteredPopulation: "571",
    VotingStationName: "MAHLAKODISHE SENIOR SECONDARY SCHOOL",
    Address:
      "NEXT MAINRAOD FROM ZAMEKOMST TO M/HALL,NEXT TO MAT  GA MATLALA, TOOITSKRAAL  MOUTSE 1",
    Latitude: "-25.0355",
    Longitude: "29.0915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16400",
    WardLookupId: "2013",
    VDNumber: "54620782",
    RegisteredPopulation: "641",
    VotingStationName: "MASHUNG TRIBAL OFFICE",
    Address: "MARBLE HALL  GREATER MARBLE HALL  MARBLE HALL",
    Latitude: "-25.0466",
    Longitude: "29.1087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16401",
    WardLookupId: "2013",
    VDNumber: "54670057",
    RegisteredPopulation: "645",
    VotingStationName: "EXCELSIOR COLLEGE",
    Address: "SIYABUSWA RD  TOITSKRAAL  MARBLE HALL",
    Latitude: "-25.0501",
    Longitude: "29.2101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16402",
    WardLookupId: "2013",
    VDNumber: "54670068",
    RegisteredPopulation: "669",
    VotingStationName: "MADIKOTI SCHOOL",
    Address: "WOLWEKRAAL  MARBLE HALL",
    Latitude: "-25.0811",
    Longitude: "29.2882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16403",
    WardLookupId: "2013",
    VDNumber: "54670237",
    RegisteredPopulation: "191",
    VotingStationName: "SIYANAMUKELA CAFE",
    Address: "12 LOSKOP NOORD  GREATER MARBLE HALL",
    Latitude: "-25.0161",
    Longitude: "29.2627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16404",
    WardLookupId: "2013",
    VDNumber: "54670338",
    RegisteredPopulation: "142",
    VotingStationName: "LAERSKOOL TOOITSKRAAL",
    Address: "TOOITSKRAAL  MARBLE HALL  MARBLE HALL",
    Latitude: "-25.0782",
    Longitude: "29.1525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16405",
    WardLookupId: "2013",
    VDNumber: "76520101",
    RegisteredPopulation: "560",
    VotingStationName: "MOOSRIVIER COMBINED SCHOOL",
    Address: "MOOSRIVIER FARM  MARBLE HALL",
    Latitude: "-25.0388",
    Longitude: "29.3669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16406",
    WardLookupId: "2014",
    VDNumber: "54620063",
    RegisteredPopulation: "969",
    VotingStationName: "MASHUNG SCHOOL",
    Address: "TOITSKRAAL  MOUTSE 1",
    Latitude: "-25.0504",
    Longitude: "29.1068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16407",
    WardLookupId: "2014",
    VDNumber: "54620254",
    RegisteredPopulation: "426",
    VotingStationName: "MOKGWANENG SCHOOL",
    Address: "MOKGWANENG  MOUTSE 1",
    Latitude: "-25.0329",
    Longitude: "28.9738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16408",
    WardLookupId: "2014",
    VDNumber: "54620265",
    RegisteredPopulation: "514",
    VotingStationName: "MAMANENG PRIMARY SCHOOL",
    Address: "WITFONTEIN  MOUTSE 1",
    Latitude: "-25.0241",
    Longitude: "29.0336",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16409",
    WardLookupId: "2014",
    VDNumber: "54620366",
    RegisteredPopulation: "978",
    VotingStationName: "MATLALA PRIMARY SCHOOL",
    Address: "TSHIKANOSHI  MOUTSE 1",
    Latitude: "-24.9882",
    Longitude: "28.9308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16410",
    WardLookupId: "2014",
    VDNumber: "54620614",
    RegisteredPopulation: "118",
    VotingStationName: "KGOMO-TLOU PRIMARY SCHOOL",
    Address: "N/A  MATLAARAMOSHEBO  SEKHUKHUNE",
    Latitude: "-25.0637",
    Longitude: "29.0718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16411",
    WardLookupId: "2015",
    VDNumber: "54560011",
    RegisteredPopulation: "2053",
    VotingStationName: "MARBLE HALL TOWN HALL",
    Address: "C/O MARBLE WEST STREET & 3RD AVE  MARBLE HALL  MARBLE HALL",
    Latitude: "-24.9675",
    Longitude: "29.2946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16412",
    WardLookupId: "2015",
    VDNumber: "54560022",
    RegisteredPopulation: "825",
    VotingStationName: "MARBLE HALL LAERSKOOL",
    Address:
      "MARBLE WEST  GREATER MARBLE HALL [MARBLE HALL]  GREATER MARBLE HALL [MARBLE HALL]",
    Latitude: "-24.9693",
    Longitude: "29.2928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16413",
    WardLookupId: "2015",
    VDNumber: "54560033",
    RegisteredPopulation: "326",
    VotingStationName: "ESTHER MALEKA HIGH SCHOOL",
    Address: "KGWANE NKADIMENG  MARBLE HALL  MARBLE HALL",
    Latitude: "-24.9658",
    Longitude: "29.2786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16414",
    WardLookupId: "2016",
    VDNumber: "76250572",
    RegisteredPopulation: "1622",
    VotingStationName: "MOGANYAKA COMMUNITY HALL",
    Address: "LEEUFONTEIN  LEEUWFONTEIN  MOGANYAKA",
    Latitude: "-24.9926",
    Longitude: "29.4005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16415",
    WardLookupId: "2016",
    VDNumber: "76250741",
    RegisteredPopulation: "715",
    VotingStationName: "NKOANE WILLIE MADITSI PRIMARY SCHOOL",
    Address: "N/A  LEEUFONTEIN  GREATER MARBLE HALL [MARBLE HALL]",
    Latitude: "-24.9983",
    Longitude: "29.4071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16416",
    WardLookupId: "2016",
    VDNumber: "76250752",
    RegisteredPopulation: "1291",
    VotingStationName: "IPCC",
    Address: "LEEUWFONTEIN  MARBLE HALL  SEKHUKHUNE",
    Latitude: "-24.9982",
    Longitude: "29.3888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16417",
    WardLookupId: "2016",
    VDNumber: "76251191",
    RegisteredPopulation: "498",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "LEEUFONTEIN  MARBLE HALL  LEEUFONTEIN",
    Latitude: "-24.9981",
    Longitude: "29.3963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16418",
    WardLookupId: "2017",
    VDNumber: "76250448",
    RegisteredPopulation: "1319",
    VotingStationName: "MONTSOSHABOSHEGO PRIMARY SCHOOL",
    Address: "MANAPYANE  MANAPYANE VILLAGE  MOGANYAKA",
    Latitude: "-24.9734",
    Longitude: "29.4048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16419",
    WardLookupId: "2017",
    VDNumber: "76250460",
    RegisteredPopulation: "917",
    VotingStationName: "RAHLAGANE TRADITIONAL AUTHORITY",
    Address: "MOGANYAKA  ",
    Latitude: "-24.9533",
    Longitude: "29.4146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16420",
    WardLookupId: "2017",
    VDNumber: "76250516",
    RegisteredPopulation: "907",
    VotingStationName: "NGWANAKWENA SECONDARY SCHOOL",
    Address: "NO STREET ADRESS  MANAPJANE VILLAGE  MOGANYAKA",
    Latitude: "-24.9681",
    Longitude: "29.4024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16421",
    WardLookupId: "2017",
    VDNumber: "76250921",
    RegisteredPopulation: "919",
    VotingStationName: "ST JOHNS APOSTOLIC CHURCH",
    Address: "MANAPYANE  MARBLE HALL  SEKHUKHUNE",
    Latitude: "-24.9838",
    Longitude: "29.4001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16422",
    WardLookupId: "2018",
    VDNumber: "76250101",
    RegisteredPopulation: "917",
    VotingStationName: "MPHAHLELE SCHOOL",
    Address: "MAMPHOKGO  SEKHUKHUNE",
    Latitude: "-24.9227",
    Longitude: "29.4245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16423",
    WardLookupId: "2018",
    VDNumber: "76250189",
    RegisteredPopulation: "1181",
    VotingStationName: "HUTUTU SECONDARY SCHOOL",
    Address: "MAKGATLE  MMAKGATLE  SEKHUKHUNE",
    Latitude: "-24.8749",
    Longitude: "29.4557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16424",
    WardLookupId: "2018",
    VDNumber: "76250763",
    RegisteredPopulation: "1010",
    VotingStationName: "MAMPHOKGO PRIMARY SCHOOL",
    Address: "N/A  MAMPHOKGO  GREATER MARBLE HALL [MARBLE HALL]",
    Latitude: "-24.9254",
    Longitude: "29.4228",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16425",
    WardLookupId: "2018",
    VDNumber: "76251203",
    RegisteredPopulation: "722",
    VotingStationName: "MMASWI-A-NAPE PRIMARY SCHOOL",
    Address: "MAMPHOKGO  MARBLE HALL  MAMPHOKGO",
    Latitude: "-24.9124",
    Longitude: "29.4317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16426",
    WardLookupId: "2019",
    VDNumber: "76250099",
    RegisteredPopulation: "483",
    VotingStationName: "MATHUNG SCHOOL",
    Address: "MOEDING  MOEDING VILLAGE  SEKHUKHUNE",
    Latitude: "-24.958",
    Longitude: "29.4578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16427",
    WardLookupId: "2019",
    VDNumber: "76250112",
    RegisteredPopulation: "1150",
    VotingStationName: "MATLALA TRIBAL OFFICE",
    Address: "NO STRRET ADRESS  MOHLALAOTWANE  SEKHUKHUNE",
    Latitude: "-24.8861",
    Longitude: "29.5549",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16428",
    WardLookupId: "2019",
    VDNumber: "76250314",
    RegisteredPopulation: "319",
    VotingStationName: "RAMOROKE SECONDARY SCHOOL",
    Address: "SELEBANENG  SEKHUKHUNE",
    Latitude: "-24.8858",
    Longitude: "29.6246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16429",
    WardLookupId: "2019",
    VDNumber: "76250662",
    RegisteredPopulation: "254",
    VotingStationName: "THOKE PRIMARY SCHOOL",
    Address:
      "19E PULENG VILLAGE RAKGWADI   GREATER MARBLE HALL [MARBLE HALL]  GREATER MARBLE HALL [MARBLE HALL]",
    Latitude: "-24.9613",
    Longitude: "29.5133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16430",
    WardLookupId: "2019",
    VDNumber: "76250774",
    RegisteredPopulation: "268",
    VotingStationName: "MAMASEGARE PRIMARY SCHOOL",
    Address:
      "NO ADRESS AVAILABLE  MMATILU( GA-RAKGWADI)  GREATER MARBLE HALL [MARBLE HALL]",
    Latitude: "-24.917",
    Longitude: "29.5121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16431",
    WardLookupId: "2019",
    VDNumber: "76250796",
    RegisteredPopulation: "574",
    VotingStationName: "APOSTOLIC CHURCH",
    Address: "CHURCH STREET  MOHLALAOTWANE  GREATER MARBLE HALL [MARBLE HALL]",
    Latitude: "-24.8717",
    Longitude: "29.5698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16432",
    WardLookupId: "2019",
    VDNumber: "76250819",
    RegisteredPopulation: "83",
    VotingStationName: "NKOANE PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  GORU  GREATER MARBLE HALL [MARBLE HALL]",
    Latitude: "-24.8664",
    Longitude: "29.5919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16433",
    WardLookupId: "2019",
    VDNumber: "76250943",
    RegisteredPopulation: "304",
    VotingStationName: "RAKGOADI PRIMARY SCHOOL",
    Address: "1032 MOHLAOTWANE RAKGWADI   EPHRAIM MOGALE   EPHRAIM MOGALE",
    Latitude: "-24.8851",
    Longitude: "29.5688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16434",
    WardLookupId: "2020",
    VDNumber: "76250291",
    RegisteredPopulation: "735",
    VotingStationName: "NYAANE PRIMARY SCHOOL",
    Address: "NO ADRESS AVAILABLE  BOEKENHOUTLAAGTE 865  MATLALA",
    Latitude: "-24.9365",
    Longitude: "29.6175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16435",
    WardLookupId: "2020",
    VDNumber: "76250303",
    RegisteredPopulation: "323",
    VotingStationName: "MMAKGATLE PRIMARY SCHOOL",
    Address:
      "NO ADRESS AVAILABLE  MMAKGATLE  GREATER MARBLE HALL [MARBLE HALL]",
    Latitude: "-24.9705",
    Longitude: "29.5861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16436",
    WardLookupId: "2020",
    VDNumber: "76250606",
    RegisteredPopulation: "1068",
    VotingStationName: "NTSHOENG PRIMARY SCHOOL",
    Address: "NO ADRESS AVAILABLE  NGWALEMONG  SEKHUKHUNE",
    Latitude: "-24.9752",
    Longitude: "29.5583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16437",
    WardLookupId: "2020",
    VDNumber: "76250808",
    RegisteredPopulation: "819",
    VotingStationName: "LEKOMETSE HIGH SCHOOL",
    Address: "MABITSI B GA-RAKGWADI  GREATER MARBLE HALL [MARBLE HALL]",
    Latitude: "-24.9386",
    Longitude: "29.5529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16438",
    WardLookupId: "2020",
    VDNumber: "76250897",
    RegisteredPopulation: "254",
    VotingStationName: "MABITSI PRIMARY SCHOOL",
    Address: "MABITSI A  SEKHUKHUNE  MARBLE HALL",
    Latitude: "-24.9344",
    Longitude: "29.5961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16439",
    WardLookupId: "2020",
    VDNumber: "76250909",
    RegisteredPopulation: "472",
    VotingStationName: "MOROPE MATLALA PRIMARY SCHOOL",
    Address: "VAALBANK  SEKHUKHUNE  MARBLE HALL",
    Latitude: "-24.9499",
    Longitude: "29.6086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16440",
    WardLookupId: "2020",
    VDNumber: "76250910",
    RegisteredPopulation: "524",
    VotingStationName: "MAHLARE SENIOR SECONDARY SCHOOL",
    Address: "SERITHING  SEKHUKHUNE  MARBLE HALL",
    Latitude: "-24.9312",
    Longitude: "29.5511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16441",
    WardLookupId: "2021",
    VDNumber: "76230266",
    RegisteredPopulation: "369",
    VotingStationName: "MAHLOELE PRIMARY SCHOOL",
    Address: "NO STREET NAME  MOHLOTSE VILLAGE  SEKHUKHUNE",
    Latitude: "-24.7997",
    Longitude: "29.5355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16442",
    WardLookupId: "2021",
    VDNumber: "76230323",
    RegisteredPopulation: "607",
    VotingStationName: "MOOMANE PRIMARY SCHOOL",
    Address: "NO ADRESS AVAILABLE  MOOMANE VILLAGE  SEKHUKHUNE",
    Latitude: "-24.801",
    Longitude: "29.6035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16443",
    WardLookupId: "2021",
    VDNumber: "76230356",
    RegisteredPopulation: "369",
    VotingStationName: "NGWANASENANA PRIMARY SCHOOL",
    Address: "N/A  DISANYANE  SEKHUKHUNE",
    Latitude: "-24.8201",
    Longitude: "29.682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16444",
    WardLookupId: "2021",
    VDNumber: "76230547",
    RegisteredPopulation: "271",
    VotingStationName: "MATHUKHUTHELA CRECHE",
    Address: "NO STREET ADDRESS AVAILABLE  MATHUKUTHELA  SEKHUKHUNE ",
    Latitude: "-24.826",
    Longitude: "29.6789",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16445",
    WardLookupId: "2021",
    VDNumber: "76230558",
    RegisteredPopulation: "90",
    VotingStationName: "MARUTLE PRIMARY SCHOOL",
    Address: "N/A  FRISCHGEWAGD  SEKHUKHUNE ",
    Latitude: "-24.8156",
    Longitude: "29.7299",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16446",
    WardLookupId: "2021",
    VDNumber: "76230569",
    RegisteredPopulation: "163",
    VotingStationName: "KHUTSO CRECH",
    Address: "MATHUKUTHELA  MARBLE HALL  MATHUKUTHELA",
    Latitude: "-24.8452",
    Longitude: "29.6827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16447",
    WardLookupId: "2021",
    VDNumber: "76230570",
    RegisteredPopulation: "306",
    VotingStationName: "KATISHI PRIMARY SCHOOL",
    Address: "MARBLE HALL  ",
    Latitude: "-24.8234",
    Longitude: "29.6104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16448",
    WardLookupId: "2021",
    VDNumber: "76250640",
    RegisteredPopulation: "319",
    VotingStationName: "FLAGBOSHIELO PRIMARY SCHOOL",
    Address: "NO ADRESS AVAILABLE  MATSEDING  SEKHUKHUNE",
    Latitude: "-24.7819",
    Longitude: "29.472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16449",
    WardLookupId: "2021",
    VDNumber: "76250651",
    RegisteredPopulation: "216",
    VotingStationName: "TOMPI SELEKA COLLEGE OF AGRICULTURE",
    Address: "TOMPI SELEKA  MARBLE HALL",
    Latitude: "-24.7933",
    Longitude: "29.4527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16450",
    WardLookupId: "2021",
    VDNumber: "76250730",
    RegisteredPopulation: "406",
    VotingStationName: "MOTSWALEDI SECONDARY SCHOOL",
    Address: "NO STREET NAME  GREENSIDE  GREATER MARBLE HALL",
    Latitude: "-24.8882",
    Longitude: "29.6677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16451",
    WardLookupId: "2021",
    VDNumber: "76350102",
    RegisteredPopulation: "298",
    VotingStationName: "NTITELENG PRIMARY SCHOOL",
    Address: "NO ADRESS AVAILABLE  MANOTOLWANE  SEKHUKHUNE",
    Latitude: "-24.8354",
    Longitude: "29.6292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16452",
    WardLookupId: "2021",
    VDNumber: "76350999",
    RegisteredPopulation: "150",
    VotingStationName: "GAREAGOPOLA PRIMARY SCHOOL",
    Address: "N/A  GAREAGOPOLA  SEKHUKHUNE",
    Latitude: "-24.8199",
    Longitude: "29.6532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16453",
    WardLookupId: "2022",
    VDNumber: "76250549",
    RegisteredPopulation: "767",
    VotingStationName: "KGOSI MATLALA TRIBAL OFFICE",
    Address: "DICHOEU  SEKHUKHUNE",
    Latitude: "-24.639",
    Longitude: "29.4481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16454",
    WardLookupId: "2022",
    VDNumber: "76250550",
    RegisteredPopulation: "1429",
    VotingStationName: "REGAE SCHOOL",
    Address: "NO STRRET ADRESS  TSANTSABELA  SEKHUKHUNE",
    Latitude: "-24.6663",
    Longitude: "29.4439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16455",
    WardLookupId: "2022",
    VDNumber: "76250820",
    RegisteredPopulation: "870",
    VotingStationName: "MOHLAHLANE PRIMARY SCHOOL",
    Address:
      "NO ADRESS AVAILABLE  TSANTSABELA  GREATER MARBLE HALL [MARBLE HALL]",
    Latitude: "-24.6629",
    Longitude: "29.4468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16456",
    WardLookupId: "2023",
    VDNumber: "54670080",
    RegisteredPopulation: "144",
    VotingStationName: "ROOIBOKKOP AGRICULTURAL OFFICE",
    Address: "SWARTKOP  MARBLE HALL",
    Latitude: "-24.8719",
    Longitude: "29.3494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16457",
    WardLookupId: "2023",
    VDNumber: "76250505",
    RegisteredPopulation: "1235",
    VotingStationName: "ELANDSKRAAL ADMINISTRATION OFFICE",
    Address: "ELANDSKRAAL  MIDDLE LEPELLE",
    Latitude: "-24.7152",
    Longitude: "29.4123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16458",
    WardLookupId: "2023",
    VDNumber: "76250527",
    RegisteredPopulation: "1294",
    VotingStationName: "UNITING REFORMED CHURCH",
    Address: "ELANDSKRAAL  MIDDLE LEPELLE",
    Latitude: "-24.7285",
    Longitude: "29.4082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16459",
    WardLookupId: "2023",
    VDNumber: "76250538",
    RegisteredPopulation: "907",
    VotingStationName: "MORARELA PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  MORARELA VILLAGE  MIDDLE LEPELLE",
    Latitude: "-24.7204",
    Longitude: "29.3734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16460",
    WardLookupId: "2023",
    VDNumber: "76250561",
    RegisteredPopulation: "585",
    VotingStationName: "IMMERPAN SCHOOL",
    Address: "NO STREET ADRESS  MBUZINI  SEKHUKHUNE",
    Latitude: "-24.6865",
    Longitude: "29.3851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16461",
    WardLookupId: "2023",
    VDNumber: "76251180",
    RegisteredPopulation: "64",
    VotingStationName: "MANGALA COMBINED SCHOOL",
    Address: "MARBLE HALL  GREATER MARBLE HALL  MARBLE HALL",
    Latitude: "-24.7116",
    Longitude: "29.4076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16462",
    WardLookupId: "2024",
    VDNumber: "76250325",
    RegisteredPopulation: "959",
    VotingStationName: "KOTOLE SCHOOL",
    Address: "NO STREET NAME  DITHOLONG  MATLALA",
    Latitude: "-24.8304",
    Longitude: "29.479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16463",
    WardLookupId: "2024",
    VDNumber: "76250336",
    RegisteredPopulation: "1566",
    VotingStationName: "MATLALA HOSPITAL",
    Address: "TSIMANYANE  MATLALA",
    Latitude: "-24.8328",
    Longitude: "29.5021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16464",
    WardLookupId: "2024",
    VDNumber: "76250347",
    RegisteredPopulation: "594",
    VotingStationName: "MOTODI SECONDARY SCHOOL",
    Address: "NO STREET ADRESS  MOGALATSANA VILLAGE  MARBLE HALL",
    Latitude: "-24.7335",
    Longitude: "29.436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16465",
    WardLookupId: "2024",
    VDNumber: "76250639",
    RegisteredPopulation: "492",
    VotingStationName: "MASOGANENG PRIMARY SCHOOL",
    Address: "NO STREET NAME  PHETWANE VILLAGE  SEKHUKHUNE",
    Latitude: "-24.7696",
    Longitude: "29.4383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16466",
    WardLookupId: "2024",
    VDNumber: "76250932",
    RegisteredPopulation: "575",
    VotingStationName: "LEGADIMANE PRIMARY SCHOOL",
    Address: "LETEBEJANE  SEKHUKHUNE  MARBLE HALL",
    Latitude: "-24.8359",
    Longitude: "29.4667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16467",
    WardLookupId: "2024",
    VDNumber: "76520112",
    RegisteredPopulation: "45",
    VotingStationName: "SCHUINSDRAAI NATURE RESERVE",
    Address: "SALIESLOOT  ROOIBOKKOP  SEKHUKHUNE",
    Latitude: "-24.8605",
    Longitude: "29.3627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16468",
    WardLookupId: "2025",
    VDNumber: "54620232",
    RegisteredPopulation: "239",
    VotingStationName: "BATAU SCHOOL",
    Address: "NO STREET ADDRESS  WALKRAAL B  SEKHUKHUNE",
    Latitude: "-25.1983",
    Longitude: "29.0539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16469",
    WardLookupId: "2025",
    VDNumber: "54620300",
    RegisteredPopulation: "1010",
    VotingStationName: "MOTETI SCHOOL",
    Address: "NO STREET ADDRESS  MOTETI B  SEKHUKHUNE",
    Latitude: "-25.2273",
    Longitude: "29.0896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16470",
    WardLookupId: "2025",
    VDNumber: "54620591",
    RegisteredPopulation: "1224",
    VotingStationName: "REBONE SCHOOL",
    Address: "MOTETI A  RAMAPHOSA VILLAGE  SEKHUKHUNE",
    Latitude: "-25.2343",
    Longitude: "29.0394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16471",
    WardLookupId: "2025",
    VDNumber: "54620603",
    RegisteredPopulation: "881",
    VotingStationName: "SLOVO PRIMARY SCHOOL",
    Address: "GROBLERSDAL  ",
    Latitude: "-25.2237",
    Longitude: "29.0726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16472",
    WardLookupId: "2025",
    VDNumber: "54620670",
    RegisteredPopulation: "390",
    VotingStationName: "LUSAKA PRIMARY SCHOOL",
    Address: "LUSAKA   LUSAKA VILLAGE  GROBLERSDAL",
    Latitude: "-25.2276",
    Longitude: "29.1074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16473",
    WardLookupId: "2025",
    VDNumber: "54620827",
    RegisteredPopulation: "131",
    VotingStationName: "7 DE LAAN DAY CARE CENTRE",
    Address: "OORLOG SETTLEMENT  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.1974",
    Longitude: "29.0705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16474",
    WardLookupId: "2025",
    VDNumber: "54620838",
    RegisteredPopulation: "305",
    VotingStationName: "THLAKO SECONDARY SCHOOL",
    Address: "MOTETI VILLAGE  SEKHUKHUNE DISTRICT  GROBLERSDAL",
    Latitude: "-25.2265",
    Longitude: "29.0823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16475",
    WardLookupId: "2025",
    VDNumber: "54620928",
    RegisteredPopulation: "185",
    VotingStationName: "MMABOSE MAHLANGU",
    Address: "MMABOSE VILLAGE  SEKHUKHUNE  BANTWANE TRIBAL",
    Latitude: "-25.2226",
    Longitude: "29.0462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16476",
    WardLookupId: "2026",
    VDNumber: "54620209",
    RegisteredPopulation: "1745",
    VotingStationName: "NKADIMENG SCHOOL",
    Address: "NO STREET ADDRESS  DENNILTON VILLAGE  SEKHUKHUNE",
    Latitude: "-25.2492",
    Longitude: "29.0407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16477",
    WardLookupId: "2026",
    VDNumber: "54620221",
    RegisteredPopulation: "1130",
    VotingStationName: "MOHLAKO SCHOOL",
    Address: "NO STREET ADDRESS  MOTETI A  SEKHUKHUNE",
    Latitude: "-25.2385",
    Longitude: "29.0486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16478",
    WardLookupId: "2026",
    VDNumber: "54620513",
    RegisteredPopulation: "1288",
    VotingStationName: "SUPATSELA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GREATER GROBLERSDAL [GROBLERSDAL]  SEKHUKHUNE",
    Latitude: "-25.2416",
    Longitude: "29.0784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16479",
    WardLookupId: "2026",
    VDNumber: "54620771",
    RegisteredPopulation: "366",
    VotingStationName: "JESUS IS LORD SCHOOL",
    Address: "MOTETI  ",
    Latitude: "-25.2476",
    Longitude: "29.0483",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16480",
    WardLookupId: "2026",
    VDNumber: "54620849",
    RegisteredPopulation: "410",
    VotingStationName: "MATSHELAPATA (TENT)",
    Address: "MATSHELAPATA VILLAGE  SEKHUKHUNE DISTRICT  GROBLERSDAL",
    Latitude: "-25.2549",
    Longitude: "29.0471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16481",
    WardLookupId: "2027",
    VDNumber: "54590317",
    RegisteredPopulation: "815",
    VotingStationName: "PHUTHIKWENA COMBINED SCHOOL",
    Address: "NO STREET ADDRESS  KGAPHAMADI VILLAGE  SEKHUKHUNE",
    Latitude: "-25.1292",
    Longitude: "29.1124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16482",
    WardLookupId: "2027",
    VDNumber: "54620287",
    RegisteredPopulation: "1023",
    VotingStationName: "KAITSWE SCHOOL",
    Address: "NO STREET ADDRESS  KGOBOKWANE  SEKHUKHUNE",
    Latitude: "-25.1422",
    Longitude: "29.1048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16483",
    WardLookupId: "2027",
    VDNumber: "54620298",
    RegisteredPopulation: "1290",
    VotingStationName: "RAMATSHAGALALA SCHOOL",
    Address: "NO STREET ADDRESS  VALSCHFONTEIN 33 J.S.  SEKHUKHUNE",
    Latitude: "-25.1434",
    Longitude: "29.0907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16484",
    WardLookupId: "2027",
    VDNumber: "54620399",
    RegisteredPopulation: "600",
    VotingStationName: "LEHWELERE SCHOOL",
    Address: "NO STREET ADDRESS  NAGANENG  SEKHUKHUNE",
    Latitude: "-25.1551",
    Longitude: "29.1155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16485",
    WardLookupId: "2028",
    VDNumber: "54620120",
    RegisteredPopulation: "899",
    VotingStationName: "HOSIA APHANE SCHOOL",
    Address: "NO STREET ADDRESS  WALKRAAL A  SEKHUKHUNE",
    Latitude: "-25.1527",
    Longitude: "29.0949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16486",
    WardLookupId: "2028",
    VDNumber: "54620377",
    RegisteredPopulation: "915",
    VotingStationName: "MAGATE-LEOPE SCHOOL",
    Address: "NO STREET ADDRESS  ZONDAGSFONTEIN  SEKHUKHUNE",
    Latitude: "-25.1996",
    Longitude: "29.1496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16487",
    WardLookupId: "2028",
    VDNumber: "54620401",
    RegisteredPopulation: "933",
    VotingStationName: "RAMATSETSE SCHOOL",
    Address: "NO STREET ADDRESS  WALKRAAL B  SEKHUKHUNE",
    Latitude: "-25.1924",
    Longitude: "29.1059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16488",
    WardLookupId: "2028",
    VDNumber: "54620580",
    RegisteredPopulation: "1127",
    VotingStationName: "LESEDI/EKUKHANYENI COMMUNITY EDUCARE",
    Address:
      "146 WAALKRAAL A EXTENSION   SEKHUKHUNE  GREATER GROBLERSDAL RURAL",
    Latitude: "-25.1528",
    Longitude: "29.0808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16489",
    WardLookupId: "2028",
    VDNumber: "54620850",
    RegisteredPopulation: "392",
    VotingStationName: "WAALKRAAL B (TENT)",
    Address: "WAALKRAAL B  SEKHUKHUNE DISTRICT  GROBLERSDAL",
    Latitude: "-25.1571",
    Longitude: "29.098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16490",
    WardLookupId: "2029",
    VDNumber: "54620074",
    RegisteredPopulation: "1228",
    VotingStationName: "MPHELENG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MPHELENG  SEKHUKHUNE",
    Latitude: "-25.2014",
    Longitude: "29.1629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16491",
    WardLookupId: "2029",
    VDNumber: "54620085",
    RegisteredPopulation: "1166",
    VotingStationName: "RAMONOKANE SCHOOL",
    Address: "NO STREET ADDRESS  UITSPANNING B  SEKHUKHUNE",
    Latitude: "-25.2238",
    Longitude: "29.1655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16492",
    WardLookupId: "2029",
    VDNumber: "54620647",
    RegisteredPopulation: "696",
    VotingStationName: "MAILA NKOKONONO SCHOOL",
    Address: "MARAPONG  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.2161",
    Longitude: "29.1639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16493",
    WardLookupId: "2029",
    VDNumber: "54620658",
    RegisteredPopulation: "614",
    VotingStationName: "MPHELENG CRECHE",
    Address: "MPHELENG  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.1991",
    Longitude: "29.1519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16494",
    WardLookupId: "2030",
    VDNumber: "54620175",
    RegisteredPopulation: "442",
    VotingStationName: "NTOTA SCHOOL",
    Address: "NO STREET ADDRESS  UITSPANNING  SEKHUKHUNE",
    Latitude: "-25.2496",
    Longitude: "29.1523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16495",
    WardLookupId: "2030",
    VDNumber: "54620210",
    RegisteredPopulation: "1813",
    VotingStationName: "UMTHOMBO WEMFUNDO SCHOOL",
    Address: "NO STREET ADDRESS  KIRKVORSCHFONTEIN B  SEKHUKHUNE",
    Latitude: "-25.2536",
    Longitude: "29.1337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16496",
    WardLookupId: "2030",
    VDNumber: "54620524",
    RegisteredPopulation: "347",
    VotingStationName: "PALEDI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  UITSPANNING  SEKHUKHUNE]",
    Latitude: "-25.2573",
    Longitude: "29.1517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16497",
    WardLookupId: "2030",
    VDNumber: "54620669",
    RegisteredPopulation: "918",
    VotingStationName: "SHENGE GENERAL DEALER",
    Address: "GROBLERSDAL  KERKSFOSFONTEIN  GROBLERSDAL",
    Latitude: "-25.2414",
    Longitude: "29.1218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16498",
    WardLookupId: "2031",
    VDNumber: "54620197",
    RegisteredPopulation: "1729",
    VotingStationName: "SIBISI SCHOOL",
    Address: "DENNILTON VILLAGE  GROBLERSDAL",
    Latitude: "-25.2586",
    Longitude: "29.135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16499",
    WardLookupId: "2031",
    VDNumber: "54620456",
    RegisteredPopulation: "362",
    VotingStationName: "THEJANE SECONDARY SCHOOL",
    Address: "TEN MORGAN  TEN MORGAN VILLAGE  MOTETI",
    Latitude: "-25.2585",
    Longitude: "29.1052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16500",
    WardLookupId: "2031",
    VDNumber: "54620490",
    RegisteredPopulation: "644",
    VotingStationName: "DIBATHUTO SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS  GREATER GROBLERSDAL [GROBLERSDAL]  SEKHUKHUNE",
    Latitude: "-25.2502",
    Longitude: "29.0587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16501",
    WardLookupId: "2031",
    VDNumber: "54620546",
    RegisteredPopulation: "662",
    VotingStationName: "MCY DEVELOPMENT",
    Address: "KIRKFORCHFONTEIN DENNILTON  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.2615",
    Longitude: "29.1248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16502",
    WardLookupId: "2031",
    VDNumber: "54620681",
    RegisteredPopulation: "448",
    VotingStationName: "NYAKOROANA-PRIMARY SCHOOL",
    Address: "DENNILTON  GROBLERSDAL",
    Latitude: "-25.2728",
    Longitude: "29.1296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16503",
    WardLookupId: "2031",
    VDNumber: "54620692",
    RegisteredPopulation: "167",
    VotingStationName: "MANONG CHOEU PROJECTS (PTY) LTD",
    Address: "DENNILTON  SEKHUKHUNE  DENNILTON",
    Latitude: "-25.2553",
    Longitude: "29.1195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16504",
    WardLookupId: "2031",
    VDNumber: "54620917",
    RegisteredPopulation: "82",
    VotingStationName: "ST JOSEF SCHOOL",
    Address: "TEN MORGAN  GROBLERSDAL  SEKHUKHUNE",
    Latitude: "-25.2501",
    Longitude: "29.1091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16505",
    WardLookupId: "2032",
    VDNumber: "54620108",
    RegisteredPopulation: "1086",
    VotingStationName: "RAMOGOSETSI SCHOOL",
    Address: "NO ATREET ADDRESS  MARAPONG VILLAGE  SEKHUKHUNE",
    Latitude: "-25.3088",
    Longitude: "29.2198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16506",
    WardLookupId: "2032",
    VDNumber: "54620322",
    RegisteredPopulation: "1353",
    VotingStationName: "MOROATHEBE SCHOOL",
    Address: "NO STREET ADDRESS  ELANDSDOORN A  SEKHUKHUNE",
    Latitude: "-25.2988",
    Longitude: "29.2309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16507",
    WardLookupId: "2032",
    VDNumber: "54620478",
    RegisteredPopulation: "131",
    VotingStationName: "EPILEPSY SOUTH AFRICA ELANDSDOORN CENTRE",
    Address: "NO STREET ADDRESS  SEMPUPURU VILLAGE  SEKHUKHUNE",
    Latitude: "-25.2888",
    Longitude: "29.2184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16508",
    WardLookupId: "2032",
    VDNumber: "54620579",
    RegisteredPopulation: "366",
    VotingStationName: "EKUCATHULENI PRIMARY SCHOOL",
    Address: "ELANDSDOORN  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.2832",
    Longitude: "29.1954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16509",
    WardLookupId: "2032",
    VDNumber: "54620704",
    RegisteredPopulation: "318",
    VotingStationName: "MARAPONG PRIMARY SCHOOL",
    Address: "MARAPONG  ",
    Latitude: "-25.3041",
    Longitude: "29.2158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16510",
    WardLookupId: "2032",
    VDNumber: "54620748",
    RegisteredPopulation: "214",
    VotingStationName: "LUTHERN CHURCH-ELANDSDOORN",
    Address: "ELANDSDOORN  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.2875",
    Longitude: "29.202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16511",
    WardLookupId: "2033",
    VDNumber: "54620096",
    RegisteredPopulation: "1325",
    VotingStationName: "NJINGA-SINDANE SCHOOL",
    Address: "NO STREET ADDRESS  ELANDSDOORN C  SEKHUKHUNE",
    Latitude: "-25.29",
    Longitude: "29.1919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16512",
    WardLookupId: "2033",
    VDNumber: "54620467",
    RegisteredPopulation: "893",
    VotingStationName: "PHOOKO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  DENNILTON VILLAGE  SEKHUKHUNE",
    Latitude: "-25.2906",
    Longitude: "29.1487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16513",
    WardLookupId: "2033",
    VDNumber: "54620557",
    RegisteredPopulation: "1111",
    VotingStationName: "ENDLESS HOPE BIBLE CHURCH",
    Address: "746 ELANDSDOORN E, FACE 4  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.2892",
    Longitude: "29.1835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16514",
    WardLookupId: "2033",
    VDNumber: "54620715",
    RegisteredPopulation: "608",
    VotingStationName: "NALEDI YA MESO CRECHE",
    Address: "ELANDSDOORN  ",
    Latitude: "-25.295",
    Longitude: "29.1982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16515",
    WardLookupId: "2033",
    VDNumber: "54620872",
    RegisteredPopulation: "493",
    VotingStationName: "MOUTSE CIRCUIT OFFICE",
    Address: "DENNILTON   SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.2784",
    Longitude: "29.1831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16516",
    WardLookupId: "2034",
    VDNumber: "54620153",
    RegisteredPopulation: "1595",
    VotingStationName: "BANTWANE SCHOOL",
    Address: "NO STREET ADDRESS  KWARRIELAAGTE  SEKHUKHUKHUNE",
    Latitude: "-25.32",
    Longitude: "29.2637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16517",
    WardLookupId: "2034",
    VDNumber: "54620164",
    RegisteredPopulation: "742",
    VotingStationName: "KAU-MAGANA SCHOOL",
    Address: "NO STREET ADDRESS  LESEHLENG  SEKHUKHUNE",
    Latitude: "-25.3063",
    Longitude: "29.2332",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16518",
    WardLookupId: "2034",
    VDNumber: "54620568",
    RegisteredPopulation: "766",
    VotingStationName: "TAGANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  NTWANE  SEKHUKUNE",
    Latitude: "-25.3088",
    Longitude: "29.2504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16519",
    WardLookupId: "2034",
    VDNumber: "54620726",
    RegisteredPopulation: "719",
    VotingStationName: "LESEHLENG PAY POINT (TENT)",
    Address: "LESEHLENG   LESEHLENG VILLAGE  GROBLERSDAL",
    Latitude: "-25.3154",
    Longitude: "29.2417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16520",
    WardLookupId: "2034",
    VDNumber: "54620737",
    RegisteredPopulation: "248",
    VotingStationName: "FK. JIANE SCHOOL",
    Address: "GROBLERSDAL  ",
    Latitude: "-25.3004",
    Longitude: "29.2593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16521",
    WardLookupId: "2035",
    VDNumber: "54620119",
    RegisteredPopulation: "646",
    VotingStationName: "MAPULA SCHOOL",
    Address: "NO STREET ADDRESS  UITSPANNIG A  SEKHUKHUNE",
    Latitude: "-25.2521",
    Longitude: "29.2094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16522",
    WardLookupId: "2035",
    VDNumber: "54620186",
    RegisteredPopulation: "743",
    VotingStationName: "SEREME COMBINED SCHOOL",
    Address: "NO STREET ADDRESS  UITSPANNING NO 9  SEKHUKHUNE",
    Latitude: "-25.2706",
    Longitude: "29.1763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16523",
    WardLookupId: "2035",
    VDNumber: "54620333",
    RegisteredPopulation: "1501",
    VotingStationName: "TLOU-KWENA SCHOOL",
    Address: "NO STREET ADDRESS  ELANDSDOORN TOWN  SEKHUKHUNE",
    Latitude: "-25.284",
    Longitude: "29.1911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16524",
    WardLookupId: "2035",
    VDNumber: "54620388",
    RegisteredPopulation: "281",
    VotingStationName: "BLOEMPOORT SCHOOL",
    Address: "NO STREET ADDRESS  BLOMPOORT VILLAGE  SEKHUKHUNE",
    Latitude: "-25.2386",
    Longitude: "29.205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16525",
    WardLookupId: "2035",
    VDNumber: "54620861",
    RegisteredPopulation: "316",
    VotingStationName: "NGOATO A MPHELA SECONDARY SCHOOL",
    Address: "UITSPANING  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.2496",
    Longitude: "29.2187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16526",
    WardLookupId: "2036",
    VDNumber: "54620142",
    RegisteredPopulation: "1319",
    VotingStationName: "MOTHIBEDI SCHOOL",
    Address: "NO STREET ADDRESS  THABAKHUBEDU  SEKHUKHUNE",
    Latitude: "-25.3343",
    Longitude: "29.2471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16527",
    WardLookupId: "2036",
    VDNumber: "54620311",
    RegisteredPopulation: "925",
    VotingStationName: "THABAKHUBEDU SCHOOL",
    Address: "NO STREET ADDRESS  THABAKHUBEDU  SEKHUKHUNE",
    Latitude: "-25.3496",
    Longitude: "29.2556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16528",
    WardLookupId: "2036",
    VDNumber: "54620759",
    RegisteredPopulation: "459",
    VotingStationName: "FAWCETT MATHEBE JUNIOR SECONDARY SCHOOL",
    Address: "THABAKWIBIDU  ",
    Latitude: "-25.3427",
    Longitude: "29.263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16529",
    WardLookupId: "2036",
    VDNumber: "54670024",
    RegisteredPopulation: "172",
    VotingStationName: "BUFFELSVALLEI MAHLANGU INDUNA",
    Address:
      "BUFFELSVALLEI FARM   SEKHUKHUNE  ELIAS MOTSOALEDI LOCAL MUNICIPALITY",
    Latitude: "-25.2309",
    Longitude: "29.6557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16530",
    WardLookupId: "2036",
    VDNumber: "54670035",
    RegisteredPopulation: "237",
    VotingStationName: "RONDEKOP SCHOOL",
    Address: "RONDEKOP  GROBLERSDAL",
    Latitude: "-25.2184",
    Longitude: "29.4038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16531",
    WardLookupId: "2036",
    VDNumber: "54670079",
    RegisteredPopulation: "375",
    VotingStationName: "LOSKOPDAM PARK (TENT)",
    Address: "NO STREET ADDRESS  LOSKOP DAM ROAD  SEKHUKHUNE",
    Latitude: "-25.4078",
    Longitude: "29.3722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16532",
    WardLookupId: "2036",
    VDNumber: "54670215",
    RegisteredPopulation: "327",
    VotingStationName: "KINGDOM REVIVAL CENTRE-MAGAGAMATALA",
    Address: "MAGAGAMATALA STOFFBERG  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.334",
    Longitude: "29.6305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16533",
    WardLookupId: "2036",
    VDNumber: "54670226",
    RegisteredPopulation: "232",
    VotingStationName: "DEWAGENDRIFT PRIMARY SCHOOL",
    Address:
      "NO STREET ADDRESS  GREATER GROBLERSDAL [GROBLERSDAL]  GREATER GROBLERSDAL [GROBLERSDAL]",
    Latitude: "-25.3593",
    Longitude: "29.4171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16534",
    WardLookupId: "2036",
    VDNumber: "54670248",
    RegisteredPopulation: "108",
    VotingStationName: "SIZANI PRIMARY SCHOOL",
    Address: "STOFFBERG ROAD  STOFFBERG  SEKHUKHUNE",
    Latitude: "-25.3411",
    Longitude: "29.7094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16535",
    WardLookupId: "2036",
    VDNumber: "54670271",
    RegisteredPopulation: "35",
    VotingStationName: "BUFFELSVALLEI PRIMARY SCHOOL",
    Address: "STOFBERG ROAD  ",
    Latitude: "-25.1885",
    Longitude: "29.5674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16536",
    WardLookupId: "2037",
    VDNumber: "54520017",
    RegisteredPopulation: "2148",
    VotingStationName: "GROBLERSDAL ACADEMY",
    Address: "1 SYRINGA  SEKHUKHUNE  GROOBLERSDAL",
    Latitude: "-25.1678",
    Longitude: "29.388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16537",
    WardLookupId: "2037",
    VDNumber: "54520028",
    RegisteredPopulation: "996",
    VotingStationName: "GROBLERSDAL RUGBY STADIUM",
    Address: "KRUGER STREET  GREATER GROBLERSDAL [GROBLERSDAL]  SEKHUKHUNE",
    Latitude: "-25.1712",
    Longitude: "29.388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16538",
    WardLookupId: "2037",
    VDNumber: "54520039",
    RegisteredPopulation: "617",
    VotingStationName: "LAERSKOOL JULIAN MULLER",
    Address: "18 BARLOW STREET  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.1658",
    Longitude: "29.3927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16539",
    WardLookupId: "2038",
    VDNumber: "54620052",
    RegisteredPopulation: "1062",
    VotingStationName: "RATHLAGANE SCHOOL",
    Address: "NO STREET ADDRESS  KUILSRIVIER  SEKHUKHUNE",
    Latitude: "-25.1578",
    Longitude: "29.1691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16540",
    WardLookupId: "2038",
    VDNumber: "54620760",
    RegisteredPopulation: "248",
    VotingStationName: "DITHAMAGA SCHOOLS",
    Address: "MATLALA LEHWELERE  GROBLERSDAL",
    Latitude: "-25.146",
    Longitude: "29.1584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16541",
    WardLookupId: "2038",
    VDNumber: "54620883",
    RegisteredPopulation: "474",
    VotingStationName: "MASEHLA SHOP",
    Address: "MATLALA LEHWELERE  SEKHUKHUNE  KGOSHI MATLALA",
    Latitude: "-25.1452",
    Longitude: "29.1523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16542",
    WardLookupId: "2038",
    VDNumber: "54670046",
    RegisteredPopulation: "494",
    VotingStationName: "RAKEDIWANE SCHOOL",
    Address: "NO STREET ADDRESS  KLIPBANK  GROBLERSDAL",
    Latitude: "-25.1347",
    Longitude: "29.3905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16543",
    WardLookupId: "2038",
    VDNumber: "54670103",
    RegisteredPopulation: "341",
    VotingStationName: "VAALFONTEIN (TENT)",
    Address: "VAALFONTEIN  VAALFONTEIN FARM  SEKHUKHUNE",
    Latitude: "-25.1093",
    Longitude: "29.2955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16544",
    WardLookupId: "2038",
    VDNumber: "54670147",
    RegisteredPopulation: "542",
    VotingStationName: "AQUAVILLE SCHOOL",
    Address: "NO STREET ADDRESS  GROBLERSDAL VILLAGEG  SEKHUKHUNE",
    Latitude: "-25.1737",
    Longitude: "29.3497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16545",
    WardLookupId: "2038",
    VDNumber: "54670259",
    RegisteredPopulation: "991",
    VotingStationName: "SG COLLEGE",
    Address: "GROBLERSDAL  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.1373",
    Longitude: "29.3838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16546",
    WardLookupId: "2039",
    VDNumber: "54670136",
    RegisteredPopulation: "946",
    VotingStationName: "REHLAHLILWE PUBLIC SCHOOL",
    Address: "NO STREET ADDRESS  ZAAIPLAAS VILLAGE  SEKHUKHUNE",
    Latitude: "-25.1142",
    Longitude: "29.7574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16547",
    WardLookupId: "2039",
    VDNumber: "54670169",
    RegisteredPopulation: "798",
    VotingStationName: "MAKHUMA PUBLIC SCHOOL",
    Address: "NO STREET ADDRESS  SEHLAKWANE VILLAGE  SEKHUKHUNE",
    Latitude: "-25.0145",
    Longitude: "29.8972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16548",
    WardLookupId: "2039",
    VDNumber: "54670170",
    RegisteredPopulation: "361",
    VotingStationName: "MATHAKGE PUBLIC SCHOOL",
    Address: "NO STREET ADDRESS  SEHLAKWANE  SEKHUKHUNE",
    Latitude: "-25.1608",
    Longitude: "29.6688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16549",
    WardLookupId: "2039",
    VDNumber: "54670192",
    RegisteredPopulation: "431",
    VotingStationName: "LERIANA SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS  HOLNEK  SEKHUKHUNE",
    Latitude: "-25.1255",
    Longitude: "29.7271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16550",
    WardLookupId: "2039",
    VDNumber: "54670316",
    RegisteredPopulation: "397",
    VotingStationName: "RETHABILE PRE-SCHOOL",
    Address: "1192 SEHLAKWANE  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.1131",
    Longitude: "29.7687",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16551",
    WardLookupId: "2039",
    VDNumber: "76250167",
    RegisteredPopulation: "232",
    VotingStationName: "ZAMAKHUHLE SCHOOL",
    Address: "NO STREET ADDRESS  GOEDHOOP 152JS  SEKHUKHUNE",
    Latitude: "-25.1027",
    Longitude: "29.7324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16552",
    WardLookupId: "2039",
    VDNumber: "76250224",
    RegisteredPopulation: "622",
    VotingStationName: "MATHULA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MATHULA VILLAGE  SEKHUKHUNE",
    Latitude: "-25.1042",
    Longitude: "29.7395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16553",
    WardLookupId: "2040",
    VDNumber: "54670114",
    RegisteredPopulation: "1175",
    VotingStationName: "SKHOSANA SKOOL",
    Address: "NO STREET ADDRESS  SAAIPLAAS  SEKHUKHUNE",
    Latitude: "-25.1647",
    Longitude: "29.7626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16554",
    WardLookupId: "2040",
    VDNumber: "54670125",
    RegisteredPopulation: "694",
    VotingStationName: "SEHLAKWANA SCHOOL",
    Address: "NO STREET ADDRESS  SEHLAKWANA  SEKHUKHUNE",
    Latitude: "-25.172",
    Longitude: "29.7736",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16555",
    WardLookupId: "2040",
    VDNumber: "54670158",
    RegisteredPopulation: "743",
    VotingStationName: "MABHANDE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  SEHLAKWANE VILLAGE  SEKHUKHUNE",
    Latitude: "-25.1471",
    Longitude: "29.7563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16556",
    WardLookupId: "2040",
    VDNumber: "54670204",
    RegisteredPopulation: "709",
    VotingStationName: "EMBOKOTHWENI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  SEHLAKWANA  SEKHUKHUNE",
    Latitude: "-25.1769",
    Longitude: "29.7346",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16557",
    WardLookupId: "2040",
    VDNumber: "54670282",
    RegisteredPopulation: "464",
    VotingStationName: "ZAAIPLAAS PRIMARY SCHOOL",
    Address: "ZAAIPLAAS  ",
    Latitude: "-25.1667",
    Longitude: "29.7585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16558",
    WardLookupId: "2040",
    VDNumber: "54670293",
    RegisteredPopulation: "343",
    VotingStationName: "DONKIE STOP",
    Address: "SEHLAKWANE  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.1946",
    Longitude: "29.7754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16559",
    WardLookupId: "2040",
    VDNumber: "54670305",
    RegisteredPopulation: "381",
    VotingStationName: "UBUHLEBUYEZA CRECHE",
    Address: "GROBLERSDAL  ",
    Latitude: "-25.1504",
    Longitude: "29.7656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16560",
    WardLookupId: "2041",
    VDNumber: "76250404",
    RegisteredPopulation: "1269",
    VotingStationName: "LEKGOLANE PRE-SCHOOL",
    Address: "NO STREET ADDRESS  TAFELKOP  SEKHUKHUNE",
    Latitude: "-25.0352",
    Longitude: "29.5075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16561",
    WardLookupId: "2041",
    VDNumber: "76250426",
    RegisteredPopulation: "1123",
    VotingStationName: "RITE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  TAFELKOP VILLAGE  SEKHUKHUNE",
    Latitude: "-25.0319",
    Longitude: "29.5181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16562",
    WardLookupId: "2041",
    VDNumber: "76250853",
    RegisteredPopulation: "987",
    VotingStationName: "ABRAM SEROTE HIGH SCHOOL",
    Address: "NO STREET ADDRESS  TAFELKOP  SEKHUKHUNE",
    Latitude: "-25.0445",
    Longitude: "29.5103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16563",
    WardLookupId: "2042",
    VDNumber: "76250011",
    RegisteredPopulation: "324",
    VotingStationName: "MAKGOPHENG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  SPITSKOP 874  SEKHUKHUNE",
    Latitude: "-24.9833",
    Longitude: "29.8294",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16564",
    WardLookupId: "2042",
    VDNumber: "76250044",
    RegisteredPopulation: "1330",
    VotingStationName: "MAROBE HIGH SCHOOL",
    Address: "NO STREET ADDRESS  SYFERFONTEIN  SEKHUKHUNE",
    Latitude: "-25.023",
    Longitude: "29.7824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16565",
    WardLookupId: "2042",
    VDNumber: "76250190",
    RegisteredPopulation: "880",
    VotingStationName: "SOMAKATE HIGH SCHOOL",
    Address: "NO STREET ADDRESS  SPITSKOP  SEKHUKHUNE",
    Latitude: "-24.996",
    Longitude: "29.8112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16566",
    WardLookupId: "2042",
    VDNumber: "76250202",
    RegisteredPopulation: "585",
    VotingStationName: "MNINWA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  SYFERFONTEIN  SEKHUKHUNE",
    Latitude: "-25.0184",
    Longitude: "29.7951",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16567",
    WardLookupId: "2042",
    VDNumber: "76250707",
    RegisteredPopulation: "630",
    VotingStationName: "MAKEKE SHOP",
    Address: "249 MMOTWANENG VILLAG  SEKHUKHUNE  NDEBELE TRIBAL AUTHORITY",
    Latitude: "-25.0149",
    Longitude: "29.7505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16568",
    WardLookupId: "2043",
    VDNumber: "76250213",
    RegisteredPopulation: "824",
    VotingStationName: "JAFTA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  DINDELA  SEKHUKHUNE",
    Latitude: "-25.0604",
    Longitude: "29.7217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16569",
    WardLookupId: "2043",
    VDNumber: "76250594",
    RegisteredPopulation: "577",
    VotingStationName: "MOKGALABYE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  THABALEBOTO VILLAGE  SEKHUKHUNE",
    Latitude: "-25.0329",
    Longitude: "29.7201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16570",
    WardLookupId: "2043",
    VDNumber: "76250684",
    RegisteredPopulation: "329",
    VotingStationName: "MAPHEPHA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GREATER GROBLERSDAL   SEKHUKHUNE",
    Latitude: "-25.0756",
    Longitude: "29.6965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16571",
    WardLookupId: "2043",
    VDNumber: "76250695",
    RegisteredPopulation: "470",
    VotingStationName: "KAUSIME PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  PAARDEPLASS  SEKHUKHUNE",
    Latitude: "-25.0333",
    Longitude: "29.7493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16572",
    WardLookupId: "2043",
    VDNumber: "76250718",
    RegisteredPopulation: "217",
    VotingStationName: "MPHUROME (TENT)",
    Address: "NO STREET ADDRESS  MATHULA VILLAGE  SEKHUKHUNE",
    Latitude: "-25.0918",
    Longitude: "29.7516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16573",
    WardLookupId: "2043",
    VDNumber: "76250886",
    RegisteredPopulation: "415",
    VotingStationName: "MASHIANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  DINDELA VILLAGE  SEKHUKHUNE",
    Latitude: "-25.045",
    Longitude: "29.7382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16574",
    WardLookupId: "2043",
    VDNumber: "76250954",
    RegisteredPopulation: "156",
    VotingStationName: "LOBOLI SCHOOL",
    Address: "SYFERFONTEIN  ",
    Latitude: "-25.0686",
    Longitude: "29.7103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16575",
    WardLookupId: "2043",
    VDNumber: "76250976",
    RegisteredPopulation: "537",
    VotingStationName: "ZAMA ZAMA HIGH SCHOOL",
    Address: "HLOGOTLOU  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.0148",
    Longitude: "29.7347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16576",
    WardLookupId: "2044",
    VDNumber: "76250088",
    RegisteredPopulation: "1602",
    VotingStationName: "MPHEZULU HIGH SCHOOL",
    Address: "NO STREET ADDRESS  HLOGOTLOU TOWNSHIP  SEKHUKHUNE",
    Latitude: "-25.0285",
    Longitude: "29.7351",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16577",
    WardLookupId: "2044",
    VDNumber: "76250864",
    RegisteredPopulation: "1468",
    VotingStationName: "MASHEGO SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS  HLOGOTLOU TOWNSHIP  SEKHUKHUNE",
    Latitude: "-25.0142",
    Longitude: "29.7295",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16578",
    WardLookupId: "2044",
    VDNumber: "76250965",
    RegisteredPopulation: "899",
    VotingStationName: "QHUBANE PRIMARY SCHOOL",
    Address: "HLOGOTLOU  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.0248",
    Longitude: "29.7358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16579",
    WardLookupId: "2045",
    VDNumber: "76250145",
    RegisteredPopulation: "805",
    VotingStationName: "NJOMA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  KGAPHAMADI VILLAGE  SEKHUKHUNE",
    Latitude: "-24.9873",
    Longitude: "29.6783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16580",
    WardLookupId: "2045",
    VDNumber: "76250257",
    RegisteredPopulation: "1012",
    VotingStationName: "MOTSEPHIRI PRIMARY SCHOOL",
    Address: "MOTSEPHIRI VILLAGE  GROBLERSDAL",
    Latitude: "-24.9861",
    Longitude: "29.7018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16581",
    WardLookupId: "2045",
    VDNumber: "76250729",
    RegisteredPopulation: "681",
    VotingStationName: "PHAPHAMANI COMBINED SCHOOL",
    Address: "NO STREET ADDRESS  JERUSALEM  SEKHUKHUNE",
    Latitude: "-25.0029",
    Longitude: "29.7079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16582",
    WardLookupId: "2045",
    VDNumber: "76250987",
    RegisteredPopulation: "516",
    VotingStationName: "IZIKHALI SCHOOL",
    Address: "JERUSALEM  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.0036",
    Longitude: "29.719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16583",
    WardLookupId: "2045",
    VDNumber: "76251236",
    RegisteredPopulation: "682",
    VotingStationName: "SANTA KGAPAMADI HBC",
    Address: "KGAPHAMADI   SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-24.9838",
    Longitude: "29.6753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16584",
    WardLookupId: "2046",
    VDNumber: "76250279",
    RegisteredPopulation: "983",
    VotingStationName: "BONANG BONANI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  LEGOLANENG VILLAGE  SEKHUKHUNE",
    Latitude: "-24.9662",
    Longitude: "29.6175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16585",
    WardLookupId: "2046",
    VDNumber: "76250628",
    RegisteredPopulation: "1607",
    VotingStationName: "SINDILE SCHOOL",
    Address: "NO STREET ADDRESS  LUCKAU VILLAGE  SEKHUKHUNE",
    Latitude: "-25.0112",
    Longitude: "29.6418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16586",
    WardLookupId: "2046",
    VDNumber: "76250998",
    RegisteredPopulation: "424",
    VotingStationName: "BATHO BOHLE CRECHE",
    Address: "MAGAUNG  SEKHUKHUNE  NDEBELE TRIBAL AUTHORITY",
    Latitude: "-25.0082",
    Longitude: "29.6217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16587",
    WardLookupId: "2046",
    VDNumber: "76251001",
    RegisteredPopulation: "392",
    VotingStationName: "HLANGANANI SCHOOL",
    Address: "MOTSEPHIRI  ",
    Latitude: "-25.0028",
    Longitude: "29.6751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16588",
    WardLookupId: "2046",
    VDNumber: "76251012",
    RegisteredPopulation: "271",
    VotingStationName: "KGAPAMADI SPORTS GROUND (TENT)",
    Address: "SEPHAKU  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.0088",
    Longitude: "29.6647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16589",
    WardLookupId: "2046",
    VDNumber: "76251023",
    RegisteredPopulation: "157",
    VotingStationName: "MARESELENG HIGH SCHOOL",
    Address: "LEGOLANENG  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-24.9601",
    Longitude: "29.6236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16590",
    WardLookupId: "2046",
    VDNumber: "76251247",
    RegisteredPopulation: "362",
    VotingStationName: "MAPHEPHA COMMUNITY OFFICE",
    Address: "MOGAUNG, MAPHEPHA  SEKHUKHUNE  NDEBELE TRIBAL AUTHORITY",
    Latitude: "-25.0136",
    Longitude: "29.6343",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16591",
    WardLookupId: "2046",
    VDNumber: "76251281",
    RegisteredPopulation: "205",
    VotingStationName: "EVANGELICAN CHURCH",
    Address: "BOZA   LUCKAU  GA MAKENA",
    Latitude: "-24.9941",
    Longitude: "29.6115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16592",
    WardLookupId: "2047",
    VDNumber: "76250022",
    RegisteredPopulation: "649",
    VotingStationName: "SOKALI  PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  SEPHAKU VILLAGE  SEKHUKHUNE",
    Latitude: "-25.0597",
    Longitude: "29.6569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16593",
    WardLookupId: "2047",
    VDNumber: "76250123",
    RegisteredPopulation: "910",
    VotingStationName: "NGULU HIGH SCHOOL",
    Address: "NO STREET ADDRESS  SEPHAKU VILLAGE  SEKHUKHUNE",
    Latitude: "-25.0169",
    Longitude: "29.6643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16594",
    WardLookupId: "2047",
    VDNumber: "76250134",
    RegisteredPopulation: "984",
    VotingStationName: "ZWANANI HIGHER PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  SEPHAKU VILLAGE  SEKHUKHUNE",
    Latitude: "-25.0207",
    Longitude: "29.6678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16595",
    WardLookupId: "2047",
    VDNumber: "76250246",
    RegisteredPopulation: "710",
    VotingStationName: "MZIMOHLOPHE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  VLAAKFONTEIN VILLAGE  SEKHUKHUNE",
    Latitude: "-25.0352",
    Longitude: "29.6742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16596",
    WardLookupId: "2047",
    VDNumber: "76251056",
    RegisteredPopulation: "504",
    VotingStationName: "LEKALA PRIMARY SCHOOL",
    Address: "GROBLERSDAL  ",
    Latitude: "-25.0576",
    Longitude: "29.6531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16597",
    WardLookupId: "2048",
    VDNumber: "76250033",
    RegisteredPopulation: "1273",
    VotingStationName: "PHOKANOKA HIGH SCHOOL",
    Address: "NO STREET ADDRESS  LUCKAU VILLAGE  SEKHUKHUNE",
    Latitude: "-24.9988",
    Longitude: "29.6099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16598",
    WardLookupId: "2048",
    VDNumber: "76250617",
    RegisteredPopulation: "1000",
    VotingStationName: "MAKEKE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  LACKAU VILLAGE  SEKHUKHUNE",
    Latitude: "-24.9941",
    Longitude: "29.604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16599",
    WardLookupId: "2048",
    VDNumber: "76251034",
    RegisteredPopulation: "464",
    VotingStationName: "ST PAUL HIGH SCHOOL",
    Address: "LUCKAU CHILOANENG VILLAGE  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.0107",
    Longitude: "29.5964",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16600",
    WardLookupId: "2048",
    VDNumber: "76251045",
    RegisteredPopulation: "490",
    VotingStationName: "LUCKAU  CLINIC",
    Address: "LUCKAU  ",
    Latitude: "-25.0104",
    Longitude: "29.6119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16601",
    WardLookupId: "2049",
    VDNumber: "76250392",
    RegisteredPopulation: "1258",
    VotingStationName: "MATHOLO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GAMATSEPE  SEKHUKHUNE",
    Latitude: "-25.0251",
    Longitude: "29.5013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16602",
    WardLookupId: "2049",
    VDNumber: "76250459",
    RegisteredPopulation: "1018",
    VotingStationName: "DIKGALAOPENG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  DIKGALAOPENG VILLAGE  SEKHUKHUNE",
    Latitude: "-24.9815",
    Longitude: "29.4606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16603",
    WardLookupId: "2049",
    VDNumber: "76251089",
    RegisteredPopulation: "588",
    VotingStationName: "HLAKUDI PRIMARY SCHOOL",
    Address: "GA MATSEPE  ",
    Latitude: "-25.0191",
    Longitude: "29.4927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16604",
    WardLookupId: "2049",
    VDNumber: "76251157",
    RegisteredPopulation: "551",
    VotingStationName: "KOROKORONG (TENT)",
    Address: "TAFELKOP  SEKHUKHUNE  GROOBLERSDAL",
    Latitude: "-25.0356",
    Longitude: "29.5216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16605",
    WardLookupId: "2049",
    VDNumber: "76251214",
    RegisteredPopulation: "352",
    VotingStationName: "MAKAEPEA PRIMARY SCHOOL",
    Address: "RAMOGWERANE VILLAGE  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.0133",
    Longitude: "29.5437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16606",
    WardLookupId: "2050",
    VDNumber: "76250370",
    RegisteredPopulation: "1315",
    VotingStationName: "BAKGAGA-BAKGOPA TRIBAL HALL",
    Address: "NO STREET ADDRESS  TAFELKOP VILLAGE  SEKHUKHUNE",
    Latitude: "-25.0491",
    Longitude: "29.4966",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16607",
    WardLookupId: "2050",
    VDNumber: "76250437",
    RegisteredPopulation: "944",
    VotingStationName: "KENNETH MASEKELA SCHOOL",
    Address: "NO STREET ADDRESS  TAFELKOP VILLAGE  SEKHUKHUNE",
    Latitude: "-25.0502",
    Longitude: "29.508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16608",
    WardLookupId: "2050",
    VDNumber: "76251078",
    RegisteredPopulation: "493",
    VotingStationName: "ELIAS MASANGO PRIMARY SCHOOL",
    Address: "GA MATSEPE  ",
    Latitude: "-25.0396",
    Longitude: "29.4993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16609",
    WardLookupId: "2050",
    VDNumber: "76251090",
    RegisteredPopulation: "383",
    VotingStationName: "RAMMUPUDU PRIMARY SCHOOL",
    Address: "TAFELKOP  GROBLERSDAL",
    Latitude: "-25.055",
    Longitude: "29.5068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16610",
    WardLookupId: "2050",
    VDNumber: "76251102",
    RegisteredPopulation: "325",
    VotingStationName: "MPHAGE SCHOOL",
    Address: "BAKGAKGA BA KOPA   TAFELKOP  GROBLESDAL",
    Latitude: "-25.047",
    Longitude: "29.4834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16611",
    WardLookupId: "2051",
    VDNumber: "76250358",
    RegisteredPopulation: "1272",
    VotingStationName: "MONAMUDI-MATSEPE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MOTLOPUNYA VILLAGE  SEKHUKHUNE",
    Latitude: "-25.066",
    Longitude: "29.484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16612",
    WardLookupId: "2051",
    VDNumber: "76250369",
    RegisteredPopulation: "928",
    VotingStationName: "MAJAKANENG COMMUNITY OFFICE",
    Address:
      "Z2A 78B MAJAKANENG SECTION, TAFELK  SEKHUKHUNE  BAKGAGA BAKOPA TRIBAL AUTHORITY",
    Latitude: "-25.0633",
    Longitude: "29.5005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16613",
    WardLookupId: "2051",
    VDNumber: "76250583",
    RegisteredPopulation: "877",
    VotingStationName: "ONISMUS MOGAFE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GREATER GROBLERSDAL   SEKHUKHUNE",
    Latitude: "-25.0688",
    Longitude: "29.5093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16614",
    WardLookupId: "2051",
    VDNumber: "76251124",
    RegisteredPopulation: "320",
    VotingStationName: "TAFELKOP SPORTS GROUND (TENT)",
    Address: "TAFELKOP  GROBLERSDAL",
    Latitude: "-25.0707",
    Longitude: "29.5044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16615",
    WardLookupId: "2051",
    VDNumber: "76251135",
    RegisteredPopulation: "660",
    VotingStationName: "JACOB SEFAKO PRIMARY SCHOOL",
    Address: "TAFELKOP  GROBLERSDAL",
    Latitude: "-25.0693",
    Longitude: "29.4841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16616",
    WardLookupId: "2052",
    VDNumber: "76250381",
    RegisteredPopulation: "1205",
    VotingStationName: "DIPAKAPAKENG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  TAFELKOP VILLAGE  SEKHUKHUNE",
    Latitude: "-25.0332",
    Longitude: "29.5366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16617",
    WardLookupId: "2052",
    VDNumber: "76250415",
    RegisteredPopulation: "1064",
    VotingStationName: "MOILANONG MATSEPE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  TAFELKOP VILLAGE  SEKHUKHUNE",
    Latitude: "-25.0387",
    Longitude: "29.5316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16618",
    WardLookupId: "2052",
    VDNumber: "76250673",
    RegisteredPopulation: "740",
    VotingStationName: "BOLEU HIGH SCHOOL",
    Address: "NO STREET ADDRESS  TAFELKOP VILLAGE  SEKHUKHUNE",
    Latitude: "-25.06",
    Longitude: "29.5122",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16619",
    WardLookupId: "2052",
    VDNumber: "76251146",
    RegisteredPopulation: "235",
    VotingStationName: "MOGUDI SCHOOL",
    Address: "GROBLERSDAL  ",
    Latitude: "-25.0402",
    Longitude: "29.5358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16620",
    WardLookupId: "2052",
    VDNumber: "76251179",
    RegisteredPopulation: "452",
    VotingStationName: "APOSTOLIC FAITH MISSION",
    Address: "TAFELKOP  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.0446",
    Longitude: "29.5175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16621",
    WardLookupId: "2052",
    VDNumber: "76251258",
    RegisteredPopulation: "572",
    VotingStationName: "RAMANARE MAGAMPA SCHOOL",
    Address: "TAFELKOP STADIUM VIEW   SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.0646",
    Longitude: "29.5165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16622",
    WardLookupId: "2053",
    VDNumber: "76250055",
    RegisteredPopulation: "971",
    VotingStationName: "MATSITSI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  STERKFONTEIN VILLAGE  SEKHUKHUNE",
    Latitude: "-25.0518",
    Longitude: "29.6164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16623",
    WardLookupId: "2053",
    VDNumber: "76250066",
    RegisteredPopulation: "568",
    VotingStationName: "GOODHOPE HIGH SCHOOL",
    Address: "NO STREET ADDRESS  BOEKENHOUT  SEKHUKHUNE",
    Latitude: "-25.0456",
    Longitude: "29.577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16624",
    WardLookupId: "2053",
    VDNumber: "76250077",
    RegisteredPopulation: "610",
    VotingStationName: "IKHUTSENG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  STERKFONTEIN VILLAGE  SEKHUKHUNE",
    Latitude: "-25.0361",
    Longitude: "29.5887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16625",
    WardLookupId: "2053",
    VDNumber: "76250178",
    RegisteredPopulation: "613",
    VotingStationName: "LAFATA HOME BASED CARE",
    Address: "RAMOGWERANE  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.014",
    Longitude: "29.5681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16626",
    WardLookupId: "2053",
    VDNumber: "76250268",
    RegisteredPopulation: "399",
    VotingStationName: "MOGANETSWA  PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  BOEKENHOUTKLOOF  SEKHUKHUNE",
    Latitude: "-25.059",
    Longitude: "29.5661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16627",
    WardLookupId: "2053",
    VDNumber: "76251067",
    RegisteredPopulation: "265",
    VotingStationName: "BAFALADI PRIMARY SCHOOL",
    Address: "MANTHOLE  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.024",
    Longitude: "29.5705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16628",
    WardLookupId: "2053",
    VDNumber: "76251225",
    RegisteredPopulation: "164",
    VotingStationName: "MPUDULLE CRECHE",
    Address: "RAMOGWERANE  SEKHUKHUNE  PHETLA TRIBAL AUTHORITY",
    Latitude: "-25.0057",
    Longitude: "29.5558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16629",
    WardLookupId: "2054",
    VDNumber: "54660089",
    RegisteredPopulation: "231",
    VotingStationName: "TONTELDOOS FARM SCHOOL (TENT)",
    Address: "TONDELTOOS  SEKHUKHUNE  ROSENEKAAL",
    Latitude: "-25.3231",
    Longitude: "29.9914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16630",
    WardLookupId: "2054",
    VDNumber: "54670013",
    RegisteredPopulation: "1146",
    VotingStationName: "SANGO SCHOOL",
    Address: "NO STREET ADDRESS  STOFFBERG  SEKHUKHUNE",
    Latitude: "-25.3756",
    Longitude: "29.8564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16631",
    WardLookupId: "2054",
    VDNumber: "54670260",
    RegisteredPopulation: "294",
    VotingStationName: "GOD`S GRACE SHOP",
    Address: "LAERSDRIFT  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.3001",
    Longitude: "29.85",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16632",
    WardLookupId: "2054",
    VDNumber: "54680137",
    RegisteredPopulation: "1086",
    VotingStationName: "ROOSSENEKAAL COMMUNITY HALL",
    Address: "HUGO STREET  ROOSSENKRAAL  SEKHUKHUNE",
    Latitude: "-25.1934",
    Longitude: "29.9243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16633",
    WardLookupId: "2054",
    VDNumber: "54680250",
    RegisteredPopulation: "374",
    VotingStationName: "MOHLAREKOMA HIGH SCHOOL",
    Address: "NO STREET ADDRESS  ROSENEKAAL  SEKHUKHUNE",
    Latitude: "-25.1889",
    Longitude: "29.9192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16634",
    WardLookupId: "2054",
    VDNumber: "54680351",
    RegisteredPopulation: "225",
    VotingStationName: "TIGERS`HOEK (TENT)",
    Address: "ROOSENEKAAL   SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.1019",
    Longitude: "29.9413",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16635",
    WardLookupId: "2054",
    VDNumber: "54680362",
    RegisteredPopulation: "256",
    VotingStationName: "MAPOCH COMBINED SCHOOL",
    Address: "NKAKABOLENG  SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.1252",
    Longitude: "29.9553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16636",
    WardLookupId: "2054",
    VDNumber: "54680373",
    RegisteredPopulation: "79",
    VotingStationName: "NGWANAMARUMO PRIMARY SCHOOL",
    Address: "ROOSENEKAAL   SEKHUKHUNE   GROBLERSDAL",
    Latitude: "-25.2539",
    Longitude: "29.9497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16637",
    WardLookupId: "2054",
    VDNumber: "54680384",
    RegisteredPopulation: "201",
    VotingStationName: "RAILWAY STATION TENT",
    Address: "STATION  SEKHUKHUNE  ROSENEKAAL",
    Latitude: "-25.1872",
    Longitude: "29.8932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16638",
    WardLookupId: "2055",
    VDNumber: "54670091",
    RegisteredPopulation: "195",
    VotingStationName: "DYNAMIQUE PRIVATE SCHOOL",
    Address: "STOFFBERG ROAD  GROBLERSDAL  SEKHUKHUNE",
    Latitude: "-25.1454",
    Longitude: "29.4375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16639",
    WardLookupId: "2055",
    VDNumber: "54670327",
    RegisteredPopulation: "85",
    VotingStationName: "G8 FARM (TENT)",
    Address: "MAFATO FARM   SEKHUKHUNE  GROBLERSDAL",
    Latitude: "-25.0881",
    Longitude: "29.4331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16640",
    WardLookupId: "2055",
    VDNumber: "76250482",
    RegisteredPopulation: "2273",
    VotingStationName: "REFILWE SECONDARY SCHOOL",
    Address: "MOTETEMA  ELIAS MOTSOALEDI LOCAL MUNICIPALITY",
    Latitude: "-25.1007",
    Longitude: "29.4615",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16641",
    WardLookupId: "2055",
    VDNumber: "76250831",
    RegisteredPopulation: "2062",
    VotingStationName: "IKAGENG PRIMARY SCHOOL",
    Address: "710 MOTETEMA  ELIAS MOTSOALEDI LOCAL MUNICIPALITY",
    Latitude: "-25.0937",
    Longitude: "29.4625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16642",
    WardLookupId: "2056",
    VDNumber: "76250156",
    RegisteredPopulation: "582",
    VotingStationName: "ONANI HIGHER PRIMARY SCHOOL",
    Address: "MONTERLUS MPUDULLE  HLOGOTLOU VILLAGE  SEKHUKHUNE",
    Latitude: "-25.0098",
    Longitude: "29.7427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16643",
    WardLookupId: "2056",
    VDNumber: "76250875",
    RegisteredPopulation: "1286",
    VotingStationName: "HLALANIKAHLE COMMUNITY HALL",
    Address: "HLALANOKAHLE MPUDULLE  MAKHUDUTHAMAGA  SEKHUKHUNE  ",
    Latitude: "-24.9939",
    Longitude: "29.7233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16644",
    WardLookupId: "2056",
    VDNumber: "76350023",
    RegisteredPopulation: "1321",
    VotingStationName: "KOPJENG SCHOOL",
    Address: "KUTUPU  MAKHUDUTHAMAGA  PHOKOANE TRIBAL",
    Latitude: "-24.9449",
    Longitude: "29.7167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16645",
    WardLookupId: "2056",
    VDNumber: "76350854",
    RegisteredPopulation: "209",
    VotingStationName: "AREIKHULENG PRIMARY SCHOOL",
    Address: "GA - TSHEHLA  MAKHUDUTHAMAGA  MAKHUDUTHAMAGA",
    Latitude: "-24.993",
    Longitude: "29.7554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16646",
    WardLookupId: "2056",
    VDNumber: "76351170",
    RegisteredPopulation: "645",
    VotingStationName: "MOKGOSHI PRIMARY SCHOOL",
    Address: "KUTUPU  MAKHUDUTHAMAGA  MATLALA",
    Latitude: "-24.9451",
    Longitude: "29.7139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16647",
    WardLookupId: "2056",
    VDNumber: "76351338",
    RegisteredPopulation: "267",
    VotingStationName: "THUTAMAHLALE PRE- SCHOOL (TENT)",
    Address: "KATLEGONG  MAKHUDUTHAMAGA  JANE FURSE",
    Latitude: "-24.9687",
    Longitude: "29.7536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16648",
    WardLookupId: "2057",
    VDNumber: "76350034",
    RegisteredPopulation: "1048",
    VotingStationName: "MASHILE PRIMARY SCHOOL",
    Address: "GA MABINTANE  MAKHUDUTHAMAGA  SEKHUKHUNE",
    Latitude: "-24.9135",
    Longitude: "29.6695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16649",
    WardLookupId: "2057",
    VDNumber: "76350382",
    RegisteredPopulation: "1690",
    VotingStationName: "LEHLAKE PRIMARY SCHOOL",
    Address: "PHOKOANE  MAKHUDUTHAMAGA  PHOKOANE TRIBAL",
    Latitude: "-24.8693",
    Longitude: "29.7276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16650",
    WardLookupId: "2057",
    VDNumber: "76350393",
    RegisteredPopulation: "814",
    VotingStationName: "PHOKOANE TRIBAL HALL",
    Address: "OHOKOANE  MAKHUDUTHAMAGA  PHOKOANE TRIBAL",
    Latitude: "-24.8547",
    Longitude: "29.7376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16651",
    WardLookupId: "2057",
    VDNumber: "76350977",
    RegisteredPopulation: "423",
    VotingStationName: "MOTLANKANE PRIMARY SCHOOL",
    Address: "MOGUDI  MAKHUDUTHAMAGA  PHOKOANE TRIBAL",
    Latitude: "-24.8551",
    Longitude: "29.7673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16652",
    WardLookupId: "2057",
    VDNumber: "76351181",
    RegisteredPopulation: "364",
    VotingStationName: "MOKGERETLI SECONDARY SCHOOL",
    Address: "PHOKOANE TOISHI  MAKHUDUTHAMAGA  MATLALA",
    Latitude: "-24.8718",
    Longitude: "29.7155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16653",
    WardLookupId: "2058",
    VDNumber: "76350360",
    RegisteredPopulation: "1350",
    VotingStationName: "PETLWANE PRIMARY SCHOOL",
    Address: "PHOKOANE MGABABA  MAKHUDUTHAMAGA  PHOKOANE TRIBAL",
    Latitude: "-24.8681",
    Longitude: "29.7577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16654",
    WardLookupId: "2058",
    VDNumber: "76350371",
    RegisteredPopulation: "1430",
    VotingStationName: "MOKGOMA PRIMARY SCHOOL",
    Address: "PHOKOANE MALEGALE  MAKHUDUTHAMAGA  PHOKOANE TRIBAL",
    Latitude: "-24.869",
    Longitude: "29.7449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16655",
    WardLookupId: "2058",
    VDNumber: "76350786",
    RegisteredPopulation: "555",
    VotingStationName: "MAKOSHALA PRE-SCHOOL",
    Address: "MAPAENG  NEBO",
    Latitude: "-24.8727",
    Longitude: "29.7514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16656",
    WardLookupId: "2058",
    VDNumber: "76351091",
    RegisteredPopulation: "578",
    VotingStationName: "MOKGAPANENG PRE SCHOOL (TENT)",
    Address: "MOKGAPANENG   MAKHUDUTHAMAGA  PHOKOANE TRIBAL",
    Latitude: "-24.8902",
    Longitude: "29.7647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16657",
    WardLookupId: "2058",
    VDNumber: "76351192",
    RegisteredPopulation: "425",
    VotingStationName: "LEHWELERE SECONDARY SCHOOL",
    Address: "PHOKOANE MGABABA  MAKHUDUTHAMAGA  MATLALA",
    Latitude: "-24.8723",
    Longitude: "29.761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16658",
    WardLookupId: "2059",
    VDNumber: "76350056",
    RegisteredPopulation: "787",
    VotingStationName: "MAMOKGOKOLOSHI HIGH SCHOOL",
    Address: "VIERFONTEIN  MAKHUDUTHAMAGA  PHOKOANE TRIBAL",
    Latitude: "-24.9622",
    Longitude: "29.7643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16659",
    WardLookupId: "2059",
    VDNumber: "76350089",
    RegisteredPopulation: "1308",
    VotingStationName: "KWENA TSHWENE PRIMARY SCHOOL",
    Address: "RIETFONTEIN NGWARITSI  MAKHUDUTHAMAGA  PHOKOANE TRIBAL",
    Latitude: "-24.9525",
    Longitude: "29.8005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16660",
    WardLookupId: "2059",
    VDNumber: "76350090",
    RegisteredPopulation: "919",
    VotingStationName: "KGAHLANAMORULANE HIGH SCHOOL",
    Address: "RIETFONTEIN  MAKHUDUTHAMAGA  PHOKOANE TRIBAL",
    Latitude: "-24.945",
    Longitude: "29.8244",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16661",
    WardLookupId: "2059",
    VDNumber: "76351103",
    RegisteredPopulation: "389",
    VotingStationName: "MOGALATLADI PRIMARY SCHOOL",
    Address: "RIETFONTEIN  SEKHUKHUNE  NEBO",
    Latitude: "-24.9472",
    Longitude: "29.8115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16662",
    WardLookupId: "2059",
    VDNumber: "76351114",
    RegisteredPopulation: "541",
    VotingStationName: "KATLEGONG SPORTS- GROUND (TENT)",
    Address: "VIERFONTEIN  MAKHUDUTHAMAGA  PHOKOANE TRIBAL",
    Latitude: "-24.9619",
    Longitude: "29.7559",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16663",
    WardLookupId: "2059",
    VDNumber: "76351204",
    RegisteredPopulation: "259",
    VotingStationName: "THABONG PRIMARY SCHOOL",
    Address: "VLEESCHBOOM  MAKHUDUTHAMAGA  MATLALA",
    Latitude: "-24.9659",
    Longitude: "29.7677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16664",
    WardLookupId: "2060",
    VDNumber: "76350124",
    RegisteredPopulation: "1232",
    VotingStationName: "TSHWATLHLAKGE PRIMARY SCHOOL",
    Address: "MASERUMULE PARK  MAKHUDUTHAMAGA  PHOKOANE TRIBAL",
    Latitude: "-24.9238",
    Longitude: "29.7612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16665",
    WardLookupId: "2060",
    VDNumber: "76350157",
    RegisteredPopulation: "1019",
    VotingStationName: "MOTEANE SECONDARY SCHOOL",
    Address: "LEEUKRAAL  MAKHUDUTHAMAGA  PHOKOANE TRIBAL",
    Latitude: "-24.9205",
    Longitude: "29.8238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16666",
    WardLookupId: "2060",
    VDNumber: "76350191",
    RegisteredPopulation: "1187",
    VotingStationName: "MMESHI PRIMARY SCHOOL",
    Address: "MOHLAREKOMA  MAKHUDUTHAMAGA  PHOKOANE TRIBAL",
    Latitude: "-24.8816",
    Longitude: "29.7799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16667",
    WardLookupId: "2060",
    VDNumber: "76350810",
    RegisteredPopulation: "305",
    VotingStationName: "MAHLAKANASELENG PRIMARY SCHOOL",
    Address: "MATLAKATLE  MAKHUDUTHAMAGA  PHOKOANE TRIBAL",
    Latitude: "-24.8921",
    Longitude: "29.8049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16668",
    WardLookupId: "2060",
    VDNumber: "76351215",
    RegisteredPopulation: "460",
    VotingStationName: "MASERUMPARK B (TENT)",
    Address: "MASERUMPARK  MAKHUDUTHAMAGA  MATLALA",
    Latitude: "-24.9276",
    Longitude: "29.7538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16669",
    WardLookupId: "2061",
    VDNumber: "76350168",
    RegisteredPopulation: "904",
    VotingStationName: "LEHLAKONG PRIMARY SCHOOL",
    Address: "EENZAAM  MAKHUDUTHAMAGA  KGOSHI KGOLOKO",
    Latitude: "-24.9524",
    Longitude: "29.8866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16670",
    WardLookupId: "2061",
    VDNumber: "76350179",
    RegisteredPopulation: "1083",
    VotingStationName: "GA-MMALEBESE PRIMARY SCHOOL",
    Address: "EENZAAM-MARE  MAKHUDUTHAMAGA  KGOSHI KGOLOKO",
    Latitude: "-24.9612",
    Longitude: "29.8725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16671",
    WardLookupId: "2061",
    VDNumber: "76350247",
    RegisteredPopulation: "645",
    VotingStationName: "MORULANA PRIMARY SCHOOL",
    Address: "PATANTSHWANA  MAKHUDUTHAMAGA  MAKHUDUTHAMAGA",
    Latitude: "-24.9203",
    Longitude: "29.8717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16672",
    WardLookupId: "2061",
    VDNumber: "76350269",
    RegisteredPopulation: "723",
    VotingStationName: "PHATANTSWANE COMMUNITY CENTRE",
    Address: "PHATANTSWANE  MAKHUDUTHAMAGA  MAKHUDUTHAMAGA",
    Latitude: "-24.9324",
    Longitude: "29.8986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16673",
    WardLookupId: "2061",
    VDNumber: "76350359",
    RegisteredPopulation: "465",
    VotingStationName: "MAFETATSHUBELA PRIMARY SCHOOL",
    Address: "EENZAAM MARE  MAKHUDUTHAMAGA  MAKHUDUTHAMAGA",
    Latitude: "-24.9679",
    Longitude: "29.832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16674",
    WardLookupId: "2062",
    VDNumber: "76350203",
    RegisteredPopulation: "430",
    VotingStationName: "KATUDI PRIMARY SCHOOL",
    Address: "DIKATHONE  MAKHUDUTHAMAGA  KWENA - MADIHLABA",
    Latitude: "-24.8776",
    Longitude: "29.8607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16675",
    WardLookupId: "2062",
    VDNumber: "76350225",
    RegisteredPopulation: "848",
    VotingStationName: "GA-MALAKA COMMUNITY CENTRE",
    Address: "GA-MALAKA  MAKHUDUTHAMAGA  KGOSHI MALAKA",
    Latitude: "-24.8564",
    Longitude: "29.8832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16676",
    WardLookupId: "2062",
    VDNumber: "76350236",
    RegisteredPopulation: "815",
    VotingStationName: "THOTO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  THOTO  SEKHUKHUNE",
    Latitude: "-24.899",
    Longitude: "29.8706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16677",
    WardLookupId: "2062",
    VDNumber: "76350270",
    RegisteredPopulation: "238",
    VotingStationName: "PHIRIAGAE HIGH SCHOOL",
    Address: "NO STREET ADDRESS  GLEN-COWIE  SEKHUKHUNE",
    Latitude: "-24.8305",
    Longitude: "29.8742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16678",
    WardLookupId: "2062",
    VDNumber: "76350449",
    RegisteredPopulation: "306",
    VotingStationName: "GA-MANTLHANYANA SCHOOL",
    Address: "GA- MANTLHANYANE  MAKHUDUTHAMAGA  PEDI MAMONE",
    Latitude: "-24.846",
    Longitude: "29.9195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16679",
    WardLookupId: "2062",
    VDNumber: "76351125",
    RegisteredPopulation: "202",
    VotingStationName: "DIKWETSE PRIMARY SCHOOL",
    Address: "SETEBONG  MAKHUDUTHAMAGA  PEDI MAMONE",
    Latitude: "-24.8635",
    Longitude: "29.8364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16680",
    WardLookupId: "2062",
    VDNumber: "76351136",
    RegisteredPopulation: "241",
    VotingStationName: "MANOTONG PRIMARY SCHOOL",
    Address: "MANOTONG   SEKHUKHUNE  MALAKA",
    Latitude: "-24.8426",
    Longitude: "29.8494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16681",
    WardLookupId: "2062",
    VDNumber: "76351226",
    RegisteredPopulation: "578",
    VotingStationName: "SEOPO SECONDARY SCHOOL",
    Address: "THOTO  MAKHUDUTHAMAGA  PEDI MAMONE",
    Latitude: "-24.8884",
    Longitude: "29.8722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16682",
    WardLookupId: "2063",
    VDNumber: "76350506",
    RegisteredPopulation: "653",
    VotingStationName: "ST RITAS HOSPITAL",
    Address: "GLEN COWIE  MAKHUDUTHAMAGA  KWENA - MADIHLABA",
    Latitude: "-24.844",
    Longitude: "29.8087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16683",
    WardLookupId: "2063",
    VDNumber: "76350764",
    RegisteredPopulation: "1031",
    VotingStationName: "MATSHUMANE HIGH SCHOOL",
    Address: "MATHOUSAND  MAKHUDUTHAMAGA  KWENA - MADIHLABA",
    Latitude: "-24.8368",
    Longitude: "29.8032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16684",
    WardLookupId: "2063",
    VDNumber: "76350797",
    RegisteredPopulation: "889",
    VotingStationName: "MOLEIJANE PRIMARY SCHOOL",
    Address: "BROOKLYN  MAKHUDUTHAMAGA  PHOKOANE TRIBAL",
    Latitude: "-24.8678",
    Longitude: "29.769",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16685",
    WardLookupId: "2063",
    VDNumber: "76350809",
    RegisteredPopulation: "1102",
    VotingStationName: "TLAME PRIMARY SCHOOL",
    Address: "GLEN COWIE  MAKHUDUTHAMAGA  PHOKOANE TRIBAL",
    Latitude: "-24.8486",
    Longitude: "29.7923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16686",
    WardLookupId: "2063",
    VDNumber: "76350911",
    RegisteredPopulation: "694",
    VotingStationName: "LIVING WATERS PRIVATE SCHOOL",
    Address: "NO STREET NAME  CAPRIVI  SEKHUKHUNE",
    Latitude: "-24.8232",
    Longitude: "29.8203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16687",
    WardLookupId: "2063",
    VDNumber: "76351237",
    RegisteredPopulation: "423",
    VotingStationName: "LEOKANA CRECHE",
    Address: "MORGENSON  MAKHUDUTHAMAGA  KWENA MADIHLABA",
    Latitude: "-24.8339",
    Longitude: "29.8208",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16688",
    WardLookupId: "2064",
    VDNumber: "76350517",
    RegisteredPopulation: "1688",
    VotingStationName: "PONTI SECONDARY SCHOOL",
    Address: "MORGENSON  MAKHUDUTHAMAGA  KWENA - MADIHLABA",
    Latitude: "-24.8325",
    Longitude: "29.8243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16689",
    WardLookupId: "2064",
    VDNumber: "76350629",
    RegisteredPopulation: "1118",
    VotingStationName: "KOPANONG PRIMARY SCHOOL",
    Address: "RIVERSIDE B  MAKHUDUTHAMAGA  PEDI MAMONE",
    Latitude: "-24.7913",
    Longitude: "29.8267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16690",
    WardLookupId: "2064",
    VDNumber: "76350922",
    RegisteredPopulation: "1010",
    VotingStationName: "REBONE SECONDARY SCHOOL",
    Address: "RIVERSIDE A  MAKHUDUTHAMAGA  PEDI MAMONE",
    Latitude: "-24.7962",
    Longitude: "29.8283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16691",
    WardLookupId: "2064",
    VDNumber: "76351147",
    RegisteredPopulation: "480",
    VotingStationName: "MAPALAGADI PRIMARY SCHOOL",
    Address: "GLEN COWIE NEW STAND  MAKHUDUTHAMAGA  KWENA MADIHLABA",
    Latitude: "-24.8267",
    Longitude: "29.8285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16692",
    WardLookupId: "2064",
    VDNumber: "76351248",
    RegisteredPopulation: "661",
    VotingStationName: "PHOTO PRIMARY SCHOOL",
    Address: "RIVERSIDE  MAKHUDUTHAMAGA  KWENA MADIHLABA",
    Latitude: "-24.8098",
    Longitude: "29.834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16693",
    WardLookupId: "2065",
    VDNumber: "76230525",
    RegisteredPopulation: "516",
    VotingStationName: "MAPOGO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  PHUSHULANG  SEKHUKHUNE",
    Latitude: "-24.7739",
    Longitude: "29.787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16694",
    WardLookupId: "2065",
    VDNumber: "76350528",
    RegisteredPopulation: "1334",
    VotingStationName: "MOLOI TRIBAL HALL",
    Address: "GA-MOLOI  MAKHUDUTHAMAGA  KWENA - MADIHLABA",
    Latitude: "-24.8035",
    Longitude: "29.811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16695",
    WardLookupId: "2065",
    VDNumber: "76350573",
    RegisteredPopulation: "355",
    VotingStationName: "MMAKUBU PRIMARY SCHOOL",
    Address: "PHUSHULANG  MAKHUDUTHAMAGA  PHOKOANE TRIBAL",
    Latitude: "-24.7898",
    Longitude: "29.7684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16696",
    WardLookupId: "2065",
    VDNumber: "76350595",
    RegisteredPopulation: "469",
    VotingStationName: "MORIPANE PRIMARY SCHOOL",
    Address: "MORIPANE  MAKHUDUTHAMAGA  PEDI MAMONE",
    Latitude: "-24.7704",
    Longitude: "29.8182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16697",
    WardLookupId: "2065",
    VDNumber: "76350775",
    RegisteredPopulation: "1051",
    VotingStationName: "NGWANAMATLANG SECONDARY SCHOOL",
    Address: "NGWANAMATLANG   MAKHUDUTHAMAGA  PEDI MAMONE",
    Latitude: "-24.7691",
    Longitude: "29.8416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16698",
    WardLookupId: "2065",
    VDNumber: "76351169",
    RegisteredPopulation: "560",
    VotingStationName: "MORIPANE (TENT)",
    Address: "MORIPANE  MAKHUDUTHAMAGA  PEDI MAMONE",
    Latitude: "-24.7689",
    Longitude: "29.8263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16699",
    WardLookupId: "2065",
    VDNumber: "76351259",
    RegisteredPopulation: "498",
    VotingStationName: "LEGALETLWA SECONDARY SCHOOL",
    Address: "GA- MADIHLABA  MAKHUDUTHAMAGA  KWENA MADIHLABA",
    Latitude: "-24.8041",
    Longitude: "29.8231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16700",
    WardLookupId: "2066",
    VDNumber: "76350472",
    RegisteredPopulation: "745",
    VotingStationName: "MONAPENAPE PRIMARY SCHOOL",
    Address: "GA - MOLEPANE  MAKHUDUTHAMAGA  MATLALA",
    Latitude: "-24.7989",
    Longitude: "29.8843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16701",
    WardLookupId: "2066",
    VDNumber: "76350551",
    RegisteredPopulation: "917",
    VotingStationName: "MASHEGOANYANA PRIMARY SCHOOL",
    Address: "MOKWETE  MAKHUDUTHAMAGA  PEDI MAMONE",
    Latitude: "-24.7806",
    Longitude: "29.8477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16702",
    WardLookupId: "2066",
    VDNumber: "76350630",
    RegisteredPopulation: "977",
    VotingStationName: "JANE FURSE HOSPITAL",
    Address: "VERGELEGEN A JANE FURSE  MAKHUDUTHAMAGA  PEDI MAMONE",
    Latitude: "-24.7651",
    Longitude: "29.8684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16703",
    WardLookupId: "2066",
    VDNumber: "76351260",
    RegisteredPopulation: "411",
    VotingStationName: "MOLEPANE SECONDARY SCHOOL",
    Address: "MOLEPANE MABONENG  NEBO  MATLALA",
    Latitude: "-24.808",
    Longitude: "29.8786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16704",
    WardLookupId: "2066",
    VDNumber: "76351271",
    RegisteredPopulation: "404",
    VotingStationName: "LEHLOGEDI CRECHE",
    Address: "VERGELEGEN A EXTENSION  NEBO  PEDI MAMONE",
    Latitude: "-24.7673",
    Longitude: "29.863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16705",
    WardLookupId: "2067",
    VDNumber: "76350258",
    RegisteredPopulation: "426",
    VotingStationName: "MANTIMO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MAKGERU  SEKHUKHUNE",
    Latitude: "-24.804",
    Longitude: "29.9661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16706",
    WardLookupId: "2067",
    VDNumber: "76350405",
    RegisteredPopulation: "1311",
    VotingStationName: "MASERALA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MAKGANE  SEKHUKHUNE",
    Latitude: "-24.7972",
    Longitude: "29.9553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16707",
    WardLookupId: "2067",
    VDNumber: "76350618",
    RegisteredPopulation: "663",
    VotingStationName: "MAROTA-MAKGANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MAKGERU  SEKHUKHUNE",
    Latitude: "-24.7903",
    Longitude: "29.9622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16708",
    WardLookupId: "2067",
    VDNumber: "76350742",
    RegisteredPopulation: "805",
    VotingStationName: "MORETSELE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MORETSELE  SEKHUKHUNE",
    Latitude: "-24.7719",
    Longitude: "29.8896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16709",
    WardLookupId: "2067",
    VDNumber: "76350900",
    RegisteredPopulation: "896",
    VotingStationName: "LEHUTJWANA SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS  MORETSELA  MAKHUDUTHAMAGA [NGWARITSI]",
    Latitude: "-24.7694",
    Longitude: "29.9135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16710",
    WardLookupId: "2067",
    VDNumber: "76350933",
    RegisteredPopulation: "479",
    VotingStationName: "RATAU  MAKGANE TRIBAL OFFICE",
    Address: "NO STREET ADDRESS  GA-RATAU  SEKHUKHUNE",
    Latitude: "-24.8074",
    Longitude: "29.9465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16711",
    WardLookupId: "2068",
    VDNumber: "76350012",
    RegisteredPopulation: "1149",
    VotingStationName: "TSHEHLWANENG HIGH SCHOOL",
    Address: "NO STREET  TSHEHLWANENG  SEKHUKHUNE",
    Latitude: "-24.7874",
    Longitude: "29.9702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16712",
    WardLookupId: "2068",
    VDNumber: "76350078",
    RegisteredPopulation: "1406",
    VotingStationName: "MOGASHOA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  TSHEHLWANENG  SEKHUKHUNE",
    Latitude: "-24.7911",
    Longitude: "29.9844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16713",
    WardLookupId: "2068",
    VDNumber: "76350483",
    RegisteredPopulation: "1028",
    VotingStationName: "NOKOMEETSE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  SCHOONOORD  SEKHUKHUNE",
    Latitude: "-24.7671",
    Longitude: "29.9993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16714",
    WardLookupId: "2068",
    VDNumber: "76350966",
    RegisteredPopulation: "890",
    VotingStationName: "MMAKGWALE (TENT)",
    Address: "PHASE FOUR  GA-MOGASHOA  SEKHUKHUNE",
    Latitude: "-24.7707",
    Longitude: "29.996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16715",
    WardLookupId: "2069",
    VDNumber: "76350045",
    RegisteredPopulation: "470",
    VotingStationName: "MANCHAKGATHE PRIMARY SCHOOL",
    Address: "GA-SEKELE  SEKHUKHUNE",
    Latitude: "-24.7386",
    Longitude: "30.0331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16716",
    WardLookupId: "2069",
    VDNumber: "76350337",
    RegisteredPopulation: "493",
    VotingStationName: "MATSHUPE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  LEOLO  SEKHUKHUNE",
    Latitude: "-24.7753",
    Longitude: "30.0733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16717",
    WardLookupId: "2069",
    VDNumber: "76350438",
    RegisteredPopulation: "1457",
    VotingStationName: "MALOMA TRIBAL HALL",
    Address: "NO STREET ADDRESS  SCHOORNOORD  SEKHUKHUNE",
    Latitude: "-24.7607",
    Longitude: "30.0113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16718",
    WardLookupId: "2069",
    VDNumber: "76350461",
    RegisteredPopulation: "905",
    VotingStationName: "SEOPELA TRIBAL OFFICE",
    Address: "NO STREET ADDRESS  SCHOONOORD GA-SEOPELA  SEKHUKHUNE",
    Latitude: "-24.7332",
    Longitude: "30.0118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16719",
    WardLookupId: "2069",
    VDNumber: "76390072",
    RegisteredPopulation: "1030",
    VotingStationName: "LEGAPANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  STEELPOORT  SEKHUKHUNE",
    Latitude: "-24.7257",
    Longitude: "30.1035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16720",
    WardLookupId: "2070",
    VDNumber: "76200656",
    RegisteredPopulation: "228",
    VotingStationName: "HONOKO PRIMARY SCHOOL",
    Address: "LEOLO  BURGERSFORT  LEOLO",
    Latitude: "-24.636",
    Longitude: "30.0441",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16721",
    WardLookupId: "2070",
    VDNumber: "76350135",
    RegisteredPopulation: "483",
    VotingStationName: "MMATHABENG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MAILAMAPITSA  SEKHUKHUNE",
    Latitude: "-24.6357",
    Longitude: "29.9969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16722",
    WardLookupId: "2070",
    VDNumber: "76350540",
    RegisteredPopulation: "331",
    VotingStationName: "MAGOLEGO TRIBAL HALL",
    Address: "NO STREET ADDRESS  GA-MAGOLEGO  SEKHUKHUNE",
    Latitude: "-24.6832",
    Longitude: "30.0557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16723",
    WardLookupId: "2070",
    VDNumber: "76350641",
    RegisteredPopulation: "545",
    VotingStationName: "MABHEDLA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  HOEPEKRANS  SEKHUKHUNE",
    Latitude: "-24.6423",
    Longitude: "30.0847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16724",
    WardLookupId: "2070",
    VDNumber: "76350708",
    RegisteredPopulation: "1099",
    VotingStationName: "MOLESHATLOU SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MAILAMAPITSANE  SEKHUKHUNE",
    Latitude: "-24.6631",
    Longitude: "30.0086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16725",
    WardLookupId: "2070",
    VDNumber: "76351013",
    RegisteredPopulation: "367",
    VotingStationName: "PITSI PRIMARY SCHOOL",
    Address: "MAILAMAPITSANE  SEKHUKHUNE  MAILA",
    Latitude: "-24.6745",
    Longitude: "30.0235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16726",
    WardLookupId: "2070",
    VDNumber: "76390016",
    RegisteredPopulation: "313",
    VotingStationName: "MAILAMAPITSANE TRIBAL OFFICE",
    Address: "GA-MAILAMAPITSANA  SEKHUKHUNE",
    Latitude: "-24.6499",
    Longitude: "30.0104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16727",
    WardLookupId: "2070",
    VDNumber: "76390229",
    RegisteredPopulation: "153",
    VotingStationName: "KGETEDI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  TSWELE  SEKHUKHUNE",
    Latitude: "-24.6338",
    Longitude: "30.0207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16728",
    WardLookupId: "2070",
    VDNumber: "76390230",
    RegisteredPopulation: "253",
    VotingStationName: "DLAMINI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  EMKHONDWENI  SEKHUKHUNE",
    Latitude: "-24.74",
    Longitude: "30.0538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16729",
    WardLookupId: "2071",
    VDNumber: "76350214",
    RegisteredPopulation: "978",
    VotingStationName: "LEGARE SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS  SCHOORNOORD  SEKHUKHUNE",
    Latitude: "-24.7424",
    Longitude: "29.9967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16730",
    WardLookupId: "2071",
    VDNumber: "76350315",
    RegisteredPopulation: "739",
    VotingStationName: "PHUTLO-TAU HIGH SCHOOL",
    Address: "NO STREET ADDRESS  SCHOONOORD  SEKHUKHUNE",
    Latitude: "-24.7537",
    Longitude: "29.9878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16731",
    WardLookupId: "2071",
    VDNumber: "76350450",
    RegisteredPopulation: "724",
    VotingStationName: "MASHEGOANA TSWALEDI TRIBAL HALL",
    Address: "GA-MASHEGOANA  SCHOONOORD  SEKHUKHUNE",
    Latitude: "-24.7422",
    Longitude: "30.003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16732",
    WardLookupId: "2071",
    VDNumber: "76350944",
    RegisteredPopulation: "1273",
    VotingStationName: "DIHLABANENG SPORTSGROUND (TENT)",
    Address: "DIHLABANENG  MAKHUDUTHAMAGA  KGOSHI NKADIMENG",
    Latitude: "-24.708",
    Longitude: "29.9816",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16733",
    WardLookupId: "2071",
    VDNumber: "76351282",
    RegisteredPopulation: "439",
    VotingStationName: "MADIKANONO PRIMARY SCHOOL",
    Address: "KOTSIRI  SEKHUKHUNE  KGOSHI MOGASHOA",
    Latitude: "-24.7562",
    Longitude: "29.9814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16734",
    WardLookupId: "2071",
    VDNumber: "76351361",
    RegisteredPopulation: "410",
    VotingStationName: "DIHLABANENG PRIMARY SCHOOL",
    Address: "DIHLABANENG  SEKHUKHUNE  TAU NKADIMENG",
    Latitude: "-24.7229",
    Longitude: "29.9852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16735",
    WardLookupId: "2072",
    VDNumber: "76350494",
    RegisteredPopulation: "941",
    VotingStationName: "MANGANENG TRIBAL  OFFICE",
    Address: "MANGANENG  MAKHUDUTHAMAGA  MANGANENG",
    Latitude: "-24.665",
    Longitude: "29.9737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16736",
    WardLookupId: "2072",
    VDNumber: "76350562",
    RegisteredPopulation: "638",
    VotingStationName: "MAMPURU 1 PRIMARY SCHOOL",
    Address: "NO STREET  SEKHUKHUNE  SEKHUKHUNE",
    Latitude: "-24.695",
    Longitude: "30.0027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16737",
    WardLookupId: "2072",
    VDNumber: "76350821",
    RegisteredPopulation: "1153",
    VotingStationName: "MANGANENG (TENT)",
    Address: "MANGANENG  MAKHUDUTHAMAGA  MANGANENG",
    Latitude: "-24.6692",
    Longitude: "29.9554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16738",
    WardLookupId: "2072",
    VDNumber: "76350832",
    RegisteredPopulation: "317",
    VotingStationName: "MALOKE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-TONA  SEKHUKHUNE",
    Latitude: "-24.6815",
    Longitude: "29.9869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16739",
    WardLookupId: "2072",
    VDNumber: "76350955",
    RegisteredPopulation: "532",
    VotingStationName: "MONAMOLEDI SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS  0925660204  MAKHUDUTHAMAGA [NGWARITSI]",
    Latitude: "-24.6869",
    Longitude: "29.9592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16740",
    WardLookupId: "2072",
    VDNumber: "76351035",
    RegisteredPopulation: "348",
    VotingStationName: "MANGANENG PRIMARY SCHOOL",
    Address: "MANGANENG   SEKHUKHUNE  MANGANENG",
    Latitude: "-24.6696",
    Longitude: "29.9675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16741",
    WardLookupId: "2073",
    VDNumber: "76350113",
    RegisteredPopulation: "1747",
    VotingStationName: "BAFEDI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  JANE FURSE  SEKHUKHUNE",
    Latitude: "-24.7568",
    Longitude: "29.8762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16742",
    WardLookupId: "2073",
    VDNumber: "76350753",
    RegisteredPopulation: "697",
    VotingStationName: "ARETHABENG PRIMARY",
    Address: "VERGELEGEN  SEKHUKHUNE",
    Latitude: "-24.7562",
    Longitude: "29.8624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16743",
    WardLookupId: "2073",
    VDNumber: "76350843",
    RegisteredPopulation: "953",
    VotingStationName: "JANE FURSE RDP 1 (TENT)",
    Address: "JANE FURSE RDP  NEBO  PEDI MAMONE",
    Latitude: "-24.7621",
    Longitude: "29.8423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16744",
    WardLookupId: "2073",
    VDNumber: "76351305",
    RegisteredPopulation: "451",
    VotingStationName: "BAROPODI PRIMARY SCHOOL",
    Address: "GA- MORABA  NEBO  MORABA",
    Latitude: "-24.7642",
    Longitude: "29.8753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16745",
    WardLookupId: "2073",
    VDNumber: "76351316",
    RegisteredPopulation: "336",
    VotingStationName: "JANE FURSE RDP 2 (TENT)",
    Address: "JANE FURSE  MAKHUDUTHAMAGA MUNICIPALITY  PEDI MAMONE",
    Latitude: "-24.7619",
    Longitude: "29.8475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16746",
    WardLookupId: "2074",
    VDNumber: "76350427",
    RegisteredPopulation: "611",
    VotingStationName: "MAROTA MADIBONG TRIBAL AUTHORITY",
    Address: "MADIBONG  JANE-FURSE  SEKHUKHUNE",
    Latitude: "-24.7343",
    Longitude: "29.8825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16747",
    WardLookupId: "2074",
    VDNumber: "76350652",
    RegisteredPopulation: "788",
    VotingStationName: "MADIBONG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MADIBONG  SEKHUKHUNE",
    Latitude: "-24.7391",
    Longitude: "29.886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16748",
    WardLookupId: "2074",
    VDNumber: "76350674",
    RegisteredPopulation: "1120",
    VotingStationName: "MAMAKOKO STORE (TENT)",
    Address: "MADIBONG MASWIELONG  MADIBONG  MAROTA MADIBONG",
    Latitude: "-24.7394",
    Longitude: "29.8742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16749",
    WardLookupId: "2074",
    VDNumber: "76350731",
    RegisteredPopulation: "584",
    VotingStationName: "KGOLOKO HIGH SCHOOL",
    Address: "NO STREET ADDRESS  MASHISHING JANE FURSE  SEKHUKHUNE",
    Latitude: "-24.7285",
    Longitude: "29.8623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16750",
    WardLookupId: "2074",
    VDNumber: "76350988",
    RegisteredPopulation: "1417",
    VotingStationName: "JANE FURSE COMPREHENSIVE SCHOOL",
    Address: "NO STREET ADDRESS  VERGELEGEN C  SEKHUKHUNE DISTRICT",
    Latitude: "-24.7527",
    Longitude: "29.8517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16751",
    WardLookupId: "2074",
    VDNumber: "76351046",
    RegisteredPopulation: "291",
    VotingStationName: "FREDDY MOKGABUDI PRIMARY SCHOOL",
    Address: "GA-MOKGWATJANA  SEKHUKHUNE  MADIBONG",
    Latitude: "-24.7178",
    Longitude: "29.8855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16752",
    WardLookupId: "2074",
    VDNumber: "76351349",
    RegisteredPopulation: "255",
    VotingStationName: "MADINOGE KGOLOKO",
    Address: "MADIBONG  JANE FURSE  KGOSHI KGOLOKO",
    Latitude: "-24.728",
    Longitude: "29.8756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16753",
    WardLookupId: "2075",
    VDNumber: "76230479",
    RegisteredPopulation: "535",
    VotingStationName: "TISANE TRIBAL HALL",
    Address: "NO STREET ADDRESS  GA-TISANE  SEKHUKHUNE",
    Latitude: "-24.7028",
    Longitude: "29.7753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16754",
    WardLookupId: "2075",
    VDNumber: "76350696",
    RegisteredPopulation: "1076",
    VotingStationName: "MAMONE TRIBAL HALL",
    Address: "NO STREET ADDRESS  MAMONE  SEKHUKHUNE",
    Latitude: "-24.7117",
    Longitude: "29.8025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16755",
    WardLookupId: "2075",
    VDNumber: "76350719",
    RegisteredPopulation: "1048",
    VotingStationName: "CHURCH OF JESUS CHRIST",
    Address: "MAMONE GA RANTHO  NEBO  PEDI MAMONE",
    Latitude: "-24.7234",
    Longitude: "29.7834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16756",
    WardLookupId: "2075",
    VDNumber: "76351057",
    RegisteredPopulation: "630",
    VotingStationName: "SEBODU CRECHE",
    Address: "MAMONE MANYELETI  JANE FURSE  KGOSHI SEKWATI",
    Latitude: "-24.7269",
    Longitude: "29.7973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16757",
    WardLookupId: "2075",
    VDNumber: "76351293",
    RegisteredPopulation: "216",
    VotingStationName: "SEKWATI PRIMARY SCHOOL",
    Address: "MAMONE MANYELETI  NEBO  KGOSHI SEKWATI MAMPURU",
    Latitude: "-24.7308",
    Longitude: "29.7827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16758",
    WardLookupId: "2075",
    VDNumber: "76351350",
    RegisteredPopulation: "136",
    VotingStationName: "MATOBOLE PRIMARY SCHOOL",
    Address: "MAMONE  MAKHUDUTHAMAGA  KGOSHI SEKWATI",
    Latitude: "-24.7122",
    Longitude: "29.8103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16759",
    WardLookupId: "2076",
    VDNumber: "76230503",
    RegisteredPopulation: "1219",
    VotingStationName: "MOTLOKWE PRIMARY SCHOOL",
    Address: "MAMONE MATSOKE  MAKHUDUTHAMAGA  PEDI MAMONE",
    Latitude: "-24.6992",
    Longitude: "29.8092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16760",
    WardLookupId: "2076",
    VDNumber: "76350067",
    RegisteredPopulation: "1190",
    VotingStationName: "MAMONE ROME CHURCH",
    Address: "NO STREET ADDRESS  MAMONE GA- SEKWATI  NEBO",
    Latitude: "-24.7233",
    Longitude: "29.8105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16761",
    WardLookupId: "2076",
    VDNumber: "76351068",
    RegisteredPopulation: "523",
    VotingStationName: "MALEKUTU HIGH SCHOOL",
    Address: "MAMONE TANZANIA SECTION  SEKHUKHUNE  GA-SEKWATI",
    Latitude: "-24.7257",
    Longitude: "29.8222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16762",
    WardLookupId: "2076",
    VDNumber: "76351079",
    RegisteredPopulation: "359",
    VotingStationName: "MAKUBARATE PRIMARY SCHOOL",
    Address: "MAMONE GA-MOHLALA  SEKHUKHUNE  GA-SEKWATI",
    Latitude: "-24.7336",
    Longitude: "29.8008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16763",
    WardLookupId: "2076",
    VDNumber: "76351080",
    RegisteredPopulation: "423",
    VotingStationName: "MANYAKA SPORTS GROUND (TENT)",
    Address: "MANYAKA  MAMONE  PEDI MAMONE TRIBALAUTHORITY",
    Latitude: "-24.7332",
    Longitude: "29.8209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16764",
    WardLookupId: "2077",
    VDNumber: "76350304",
    RegisteredPopulation: "1033",
    VotingStationName: "MALEGALE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MADIBANENG  SEKHUKHUNE",
    Latitude: "-24.6102",
    Longitude: "29.969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16765",
    WardLookupId: "2077",
    VDNumber: "76350663",
    RegisteredPopulation: "924",
    VotingStationName: "SERAKI SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS  TSATANE  SEKHUKHUNE",
    Latitude: "-24.642",
    Longitude: "29.9454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16766",
    WardLookupId: "2077",
    VDNumber: "76350720",
    RegisteredPopulation: "701",
    VotingStationName: "MAMOLOBE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MALEGALE  SEKHUKHUNE",
    Latitude: "-24.6325",
    Longitude: "29.9332",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16767",
    WardLookupId: "2077",
    VDNumber: "76350887",
    RegisteredPopulation: "363",
    VotingStationName: "MAGOMARELE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MALEGALE  SEKHUKHUNE",
    Latitude: "-24.6336",
    Longitude: "29.9432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16768",
    WardLookupId: "2077",
    VDNumber: "76350898",
    RegisteredPopulation: "744",
    VotingStationName: "TSATANE NEW STAND VACANT LAND (TENT)",
    Address: "NO STREET ADDRESS  TSATANE  SEKHUKHUNE",
    Latitude: "-24.6448",
    Longitude: "29.9349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16769",
    WardLookupId: "2077",
    VDNumber: "76360563",
    RegisteredPopulation: "735",
    VotingStationName: "PEBETSE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MALEGALE  SEKHUKHUNE",
    Latitude: "-24.6186",
    Longitude: "29.9373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16770",
    WardLookupId: "2078",
    VDNumber: "76350146",
    RegisteredPopulation: "458",
    VotingStationName: "DINOTJI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MAILASEGOLO  SEKHUKHUNE",
    Latitude: "-24.6109",
    Longitude: "29.8928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16771",
    WardLookupId: "2078",
    VDNumber: "76350180",
    RegisteredPopulation: "991",
    VotingStationName: "MAROTA MARULANENG TRADITIONAL COMMUNITY HALL",
    Address: "MARULANENG  SEKHUKHUNE",
    Latitude: "-24.6746",
    Longitude: "29.901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16772",
    WardLookupId: "2078",
    VDNumber: "76350416",
    RegisteredPopulation: "461",
    VotingStationName: "MATHIBENG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MATHIBENG  SEKHUKHUNE",
    Latitude: "-24.6207",
    Longitude: "29.9148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16773",
    WardLookupId: "2078",
    VDNumber: "76350685",
    RegisteredPopulation: "870",
    VotingStationName: "MAILA  SCHOOL",
    Address: "NO STREET ADDRESS  GA-MAILASEGOLO  SEKHUKHUNE",
    Latitude: "-24.6267",
    Longitude: "29.8676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16774",
    WardLookupId: "2078",
    VDNumber: "76350865",
    RegisteredPopulation: "285",
    VotingStationName: "MAPHALE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MAROTASEGOLO  SEKHUKHUNE",
    Latitude: "-24.6333",
    Longitude: "29.8571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16775",
    WardLookupId: "2078",
    VDNumber: "76350876",
    RegisteredPopulation: "241",
    VotingStationName: "MASELESELENG PRIMARY SCHOOL",
    Address: "NO STREET NAME  MASELESELENG  SEKHUKHUNE",
    Latitude: "-24.6477",
    Longitude: "29.8871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16776",
    WardLookupId: "2078",
    VDNumber: "76351327",
    RegisteredPopulation: "360",
    VotingStationName: "PHETHEDI PRE-SCHOOL (TENT)",
    Address: "MARULANENG  SEKHUKHUNE  MAROTA MARULANENG",
    Latitude: "-24.6799",
    Longitude: "29.9023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16777",
    WardLookupId: "2079",
    VDNumber: "76230165",
    RegisteredPopulation: "277",
    VotingStationName: "LOBETHAL PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-PHAAHLA  SEKHUKHUNE",
    Latitude: "-24.698",
    Longitude: "29.7597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16778",
    WardLookupId: "2079",
    VDNumber: "76230187",
    RegisteredPopulation: "1251",
    VotingStationName: "PHAAHLA TRIBAL HALL",
    Address: "NO STREET ADDRESS  GA-PHAAHLA MAKADIKWE  SEKHUKHUNE",
    Latitude: "-24.6957",
    Longitude: "29.7321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16779",
    WardLookupId: "2079",
    VDNumber: "76230222",
    RegisteredPopulation: "832",
    VotingStationName: "MPODI PRE-SCHOOL",
    Address: "NO STREET ADDRESS  DIPHAGANE  SEKHUKHUNE",
    Latitude: "-24.671",
    Longitude: "29.7435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16780",
    WardLookupId: "2079",
    VDNumber: "76230446",
    RegisteredPopulation: "475",
    VotingStationName: "THOLONG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-PHAAHLA  SEKHUKHUNE",
    Latitude: "-24.6872",
    Longitude: "29.6177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16781",
    WardLookupId: "2079",
    VDNumber: "76230468",
    RegisteredPopulation: "181",
    VotingStationName: "THABADIPHIRI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-PHAAHLA  SEKHUKHUNE",
    Latitude: "-24.6669",
    Longitude: "29.6586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16782",
    WardLookupId: "2079",
    VDNumber: "76230615",
    RegisteredPopulation: "463",
    VotingStationName: "SEROKOLOANA PRIMARY SCHOOL",
    Address: "DIPHAGANE  SEKHUKHUNE  DIPHAGANE",
    Latitude: "-24.6594",
    Longitude: "29.7384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16783",
    WardLookupId: "2079",
    VDNumber: "76230626",
    RegisteredPopulation: "424",
    VotingStationName: "BOHWELABATHO PRIMARY SCHOOL",
    Address: "MAMOSHALELE  SEKHUKHUNE  PHAAHLA",
    Latitude: "-24.7003",
    Longitude: "29.7451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16784",
    WardLookupId: "2079",
    VDNumber: "76230659",
    RegisteredPopulation: "261",
    VotingStationName: "MMAPHADIME SECONDARY SCHOOL",
    Address: "MEERLUST  SEKHUKHUNE  GA-PHAAHLA",
    Latitude: "-24.6889",
    Longitude: "29.7335",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16785",
    WardLookupId: "2080",
    VDNumber: "76230233",
    RegisteredPopulation: "704",
    VotingStationName: "MAREI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MASHABELA  SEKHUKHUNE",
    Latitude: "-24.6234",
    Longitude: "29.7395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16786",
    WardLookupId: "2080",
    VDNumber: "76230334",
    RegisteredPopulation: "847",
    VotingStationName: "NKGONYELETSE HIGH SCHOOL",
    Address: "NO STREET ADDRESS  GA-MASHABELA  SEKHUKHUNE",
    Latitude: "-24.6336",
    Longitude: "29.739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16787",
    WardLookupId: "2080",
    VDNumber: "76230345",
    RegisteredPopulation: "846",
    VotingStationName: "MOHWELERE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MOHWELERE  NEBO",
    Latitude: "-24.5991",
    Longitude: "29.716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16788",
    WardLookupId: "2080",
    VDNumber: "76230413",
    RegisteredPopulation: "262",
    VotingStationName: "MAMORITHING PRIMARY SCHOOL",
    Address: "MAOLOLO  GA-MASHABELA  NEBO",
    Latitude: "-24.6457",
    Longitude: "29.699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16789",
    WardLookupId: "2080",
    VDNumber: "76230457",
    RegisteredPopulation: "364",
    VotingStationName: "LEDIITJE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MASEMOLA MOLEBELEDI  NEBO",
    Latitude: "-24.6019",
    Longitude: "29.6815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16790",
    WardLookupId: "2080",
    VDNumber: "76230514",
    RegisteredPopulation: "541",
    VotingStationName: "PHUTHIKWENA  JUNIOR SEC SCHOOL",
    Address: "NO STREET ADDRESS  MATSHATSHA  SEKHUKHUNE",
    Latitude: "-24.5673",
    Longitude: "29.7667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16791",
    WardLookupId: "2080",
    VDNumber: "76230536",
    RegisteredPopulation: "191",
    VotingStationName: "NALA PRIMARY SCHOOL",
    Address: "NO STREER ADDRESS  GA-SELEPE  MASHABELA",
    Latitude: "-24.6396",
    Longitude: "29.7843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16792",
    WardLookupId: "2080",
    VDNumber: "76230637",
    RegisteredPopulation: "527",
    VotingStationName: "MASHABELA PRIMARY SCHOOL",
    Address: "MAHLAKANASELENG  SEKHUKHUNE  GA-MASHABELA",
    Latitude: "-24.6223",
    Longitude: "29.728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16793",
    WardLookupId: "2080",
    VDNumber: "76230648",
    RegisteredPopulation: "327",
    VotingStationName: "MOGOBELALA PRIMARY SCHOOL",
    Address: "GA MACHACHA  SEKHUKHUNE  GA-MASHABELA",
    Latitude: "-24.5811",
    Longitude: "29.7575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16794",
    WardLookupId: "2081",
    VDNumber: "76230132",
    RegisteredPopulation: "329",
    VotingStationName: "KGARUTHUTHU PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MARISHANE  SEKHUKHUNE",
    Latitude: "-24.7611",
    Longitude: "29.7112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16795",
    WardLookupId: "2081",
    VDNumber: "76230176",
    RegisteredPopulation: "282",
    VotingStationName: "RAILE (TENT)",
    Address: "BOTHASPRUIT  NEBO  KGOSHI MARISHANE",
    Latitude: "-24.7587",
    Longitude: "29.7595",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16796",
    WardLookupId: "2081",
    VDNumber: "76230198",
    RegisteredPopulation: "933",
    VotingStationName: "BOPEDI BAPEDI HIGH SCHOOL",
    Address: "NO STREET ADDRESS  GA-MARISHANE  NEBO",
    Latitude: "-24.7267",
    Longitude: "29.7539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16797",
    WardLookupId: "2081",
    VDNumber: "76230200",
    RegisteredPopulation: "1087",
    VotingStationName: "MARISHANE TRIBAL HALL",
    Address: "NO STREET ADDRESS  GA-MARISHANE  SEKHUKHUNE",
    Latitude: "-24.7276",
    Longitude: "29.7359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16798",
    WardLookupId: "2081",
    VDNumber: "76230378",
    RegisteredPopulation: "643",
    VotingStationName: "TEME PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MARISHANE  SEKHUKHUNE",
    Latitude: "-24.718",
    Longitude: "29.733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16799",
    WardLookupId: "2081",
    VDNumber: "76230390",
    RegisteredPopulation: "446",
    VotingStationName: "LEHLAGARE MARISHANEPRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MARISHANE  SEKHUKHUNE",
    Latitude: "-24.745",
    Longitude: "29.6807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16800",
    WardLookupId: "2081",
    VDNumber: "76230693",
    RegisteredPopulation: "272",
    VotingStationName: "RANONG PRIMARY SCHOOL",
    Address: "MAMPANA THABENG  MAKHUDUTHAMAGA  KGOSHI TISANA",
    Latitude: "-24.7841",
    Longitude: "29.7123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16801",
    WardLookupId: "2082",
    VDNumber: "76230019",
    RegisteredPopulation: "1292",
    VotingStationName: "MASEMOLA TRIBAL HALL",
    Address: "NO STREET ADDRESS  GA-MASEMOLA  SEKHUKHUNE",
    Latitude: "-24.5734",
    Longitude: "29.6444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16802",
    WardLookupId: "2082",
    VDNumber: "76230053",
    RegisteredPopulation: "920",
    VotingStationName: "MOKALAPA PRIMARY SCHOOL",
    Address: "MABOPANE  GA-MASEMOLA  NEBO",
    Latitude: "-24.5743",
    Longitude: "29.6702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16803",
    WardLookupId: "2082",
    VDNumber: "76230064",
    RegisteredPopulation: "828",
    VotingStationName: "EKELE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MASEMOLA  NEBO",
    Latitude: "-24.5604",
    Longitude: "29.6509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16804",
    WardLookupId: "2082",
    VDNumber: "76230301",
    RegisteredPopulation: "456",
    VotingStationName: "LEWALEMOLOMO HIGH SCHOOL",
    Address: "NO STREET ADDRESS  GA-MASEMOLA  SEKHUKHUNE",
    Latitude: "-24.6232",
    Longitude: "29.658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16805",
    WardLookupId: "2083",
    VDNumber: "76230020",
    RegisteredPopulation: "1070",
    VotingStationName: "THABAMPSHE PRIMARY SCHOOL",
    Address: "THABAMPSHE GA-MASEMOLA  MAKHUDUTHAMAGA  SEFOKA",
    Latitude: "-24.5425",
    Longitude: "29.6993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16806",
    WardLookupId: "2083",
    VDNumber: "76230097",
    RegisteredPopulation: "586",
    VotingStationName: "MAKANTANE PRIMARY SCHOOL",
    Address: "TSWAING GA-MASEMOLA  MAKHUDUTHAMAGA  MASEMOLA",
    Latitude: "-24.4739",
    Longitude: "29.6803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16807",
    WardLookupId: "2083",
    VDNumber: "76230244",
    RegisteredPopulation: "419",
    VotingStationName: "MANNYETE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MASEMOLA WONDERBOOM  NEBO",
    Latitude: "-24.4689",
    Longitude: "29.6093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16808",
    WardLookupId: "2083",
    VDNumber: "76230255",
    RegisteredPopulation: "346",
    VotingStationName: "DIPHALE HIGH SCHOOL",
    Address: "VLAKPLAATS  GA-MASEMOLA  NEBO",
    Latitude: "-24.4809",
    Longitude: "29.6587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16809",
    WardLookupId: "2083",
    VDNumber: "76230480",
    RegisteredPopulation: "712",
    VotingStationName: "SEKALE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MAHWIBITSANE  SEKHUKHUNE",
    Latitude: "-24.5471",
    Longitude: "29.6276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16810",
    WardLookupId: "2083",
    VDNumber: "76230592",
    RegisteredPopulation: "451",
    VotingStationName: "MOGAILE PRIMARY SCHOOL",
    Address: "GA-MAPHUTHA  SEKHUKHUNE  GA-MASEMOLA",
    Latitude: "-24.5589",
    Longitude: "29.6323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16811",
    WardLookupId: "2083",
    VDNumber: "76230604",
    RegisteredPopulation: "382",
    VotingStationName: "MATLEBJANE SECONDARY SCHOOL",
    Address: "THABAMPSHE  SEKHUKHUNE  GA-MASEMOLA",
    Latitude: "-24.5364",
    Longitude: "29.6848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16812",
    WardLookupId: "2083",
    VDNumber: "76230671",
    RegisteredPopulation: "215",
    VotingStationName: "MACHELANE PRIMARY SCHOOL",
    Address: "THABAMPSHE  NEBO  MASEMOLA",
    Latitude: "-24.5516",
    Longitude: "29.7176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16813",
    WardLookupId: "2084",
    VDNumber: "76230042",
    RegisteredPopulation: "481",
    VotingStationName: "MMAKGWABE PRIMARY SCHOOL",
    Address: "GA-MASEMOLA  MAKHUDUTHAMAGA  MASEMOLA",
    Latitude: "-24.5847",
    Longitude: "29.5279",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16814",
    WardLookupId: "2084",
    VDNumber: "76230075",
    RegisteredPopulation: "754",
    VotingStationName: "THABANASWANA PRIMARY SCHOOL",
    Address: "GA-MASEMOLA  MAKHUDUTHAMAGA  MASEMOLA",
    Latitude: "-24.5314",
    Longitude: "29.6089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16815",
    WardLookupId: "2084",
    VDNumber: "76230110",
    RegisteredPopulation: "686",
    VotingStationName: "KHUDUTSEKE PRIMARY SCHOOL",
    Address: "MOLEMELA GA-MASEMOLA  MAKHUDUTHAMAGA  MASEMOLA",
    Latitude: "-24.569",
    Longitude: "29.6359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16816",
    WardLookupId: "2084",
    VDNumber: "76230121",
    RegisteredPopulation: "523",
    VotingStationName: "MALOPE PRIMARY SCHOOL",
    Address: "GA-MASEMOLA  MAKHUDUTHAMAGA  MASEMOLA",
    Latitude: "-24.5668",
    Longitude: "29.5427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16817",
    WardLookupId: "2084",
    VDNumber: "76230277",
    RegisteredPopulation: "514",
    VotingStationName: "THABANAPITSI PRIMARY SCHOOL",
    Address: "GA-MASEMOLA  MAKHUDUTHAMAGA  MASEMOLA",
    Latitude: "-24.6204",
    Longitude: "29.486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16818",
    WardLookupId: "2084",
    VDNumber: "76230299",
    RegisteredPopulation: "419",
    VotingStationName: "MAHLOLWANENG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MASEMOLA  SEKHUKHUNE",
    Latitude: "-24.5797",
    Longitude: "29.6024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16819",
    WardLookupId: "2084",
    VDNumber: "76230312",
    RegisteredPopulation: "523",
    VotingStationName: "SHUSHU PRIMARY SCHOOL",
    Address: "GA-MASEMOLA  MAKHUDUTHAMAGA  MASEMOLA",
    Latitude: "-24.6298",
    Longitude: "29.57",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16820",
    WardLookupId: "2085",
    VDNumber: "76230031",
    RegisteredPopulation: "825",
    VotingStationName: "MOLWETJI HIGH SCHOOL",
    Address: "MOGALADI  MAKHUDUTHAMAGA  PHAAHLA",
    Latitude: "-24.7722",
    Longitude: "29.5404",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16821",
    WardLookupId: "2085",
    VDNumber: "76230086",
    RegisteredPopulation: "445",
    VotingStationName: "TSWATAGO PRIMARY SCHOOL",
    Address: "GA-MASEMOLA  MAKHUDUTHAMAGA  MASEMOLA",
    Latitude: "-24.6697",
    Longitude: "29.4707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16822",
    WardLookupId: "2085",
    VDNumber: "76230109",
    RegisteredPopulation: "634",
    VotingStationName: "TISANE PRIMARY SCHOOL",
    Address: "GA-MAMPANA MASANTENG  MAKHUDUTHAMAGA  MAMPANA",
    Latitude: "-24.7595",
    Longitude: "29.4944",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16823",
    WardLookupId: "2085",
    VDNumber: "76230143",
    RegisteredPopulation: "706",
    VotingStationName: "MOTSATSI PRIMARY SCHOOL",
    Address: "GA-MARISHANE  MAKHUDUTHAMAGA  MORWANGWATO",
    Latitude: "-24.6968",
    Longitude: "29.4501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16824",
    WardLookupId: "2085",
    VDNumber: "76230424",
    RegisteredPopulation: "666",
    VotingStationName: "BAITHUDIMOHLAHLEDI  PRIMARY SCHOOL",
    Address: "N/A GA MAMPANA  MAKHUDUTHAMAGA  MAMPANA",
    Latitude: "-24.737",
    Longitude: "29.5291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16825",
    WardLookupId: "2085",
    VDNumber: "76230581",
    RegisteredPopulation: "96",
    VotingStationName: "MANGOLWANE SECONDARY SCHOOL",
    Address: "LEGOTONG  GA-MARISHANE  MAKHUDUTHAMAGA  MARISHANE",
    Latitude: "-24.7403",
    Longitude: "29.5445",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16826",
    WardLookupId: "2085",
    VDNumber: "76230682",
    RegisteredPopulation: "375",
    VotingStationName: "MAMAKGATLAPE PRIMARY SCHOOL",
    Address: "MOGALADI   MAKHUDUTHAMAGA  KGOSHI PHAAHLA",
    Latitude: "-24.7687",
    Longitude: "29.5499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16827",
    WardLookupId: "2086",
    VDNumber: "76230154",
    RegisteredPopulation: "619",
    VotingStationName: "KATANG PRIMARY SCHOOL",
    Address: "MAKHUTSO GA MAPANA  MAKHUDUTHAMAGA  MAMPANA",
    Latitude: "-24.7273",
    Longitude: "29.5546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16828",
    WardLookupId: "2086",
    VDNumber: "76230211",
    RegisteredPopulation: "256",
    VotingStationName: "MAFOKO PRIMARY SCHOOL",
    Address: "GA MARISHANE  MAKHUDUTHAMAGA  MARISHANE",
    Latitude: "-24.7738",
    Longitude: "29.6046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16829",
    WardLookupId: "2086",
    VDNumber: "76230288",
    RegisteredPopulation: "879",
    VotingStationName: "MAMPANA TRIBAL HALL",
    Address: "GA MAMPANA  MAKHUDUTHAMAGA  MAMPANA TRIBAL",
    Latitude: "-24.7167",
    Longitude: "29.5375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16830",
    WardLookupId: "2086",
    VDNumber: "76230367",
    RegisteredPopulation: "632",
    VotingStationName: "MOGALETLWA PRIMARY SCHOOL",
    Address: "VLAKPLAATS GA MARISHANE  MAKHUDUTHAMAGA  MORWANGWATO",
    Latitude: "-24.7006",
    Longitude: "29.6471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16831",
    WardLookupId: "2086",
    VDNumber: "76230389",
    RegisteredPopulation: "317",
    VotingStationName: "MODISHANE PRIMARY SCHOOL",
    Address: "KUTOPO / GOEDEHOOP GA-MARISHAN  MAKHUDUTHAMAGA  MORWANGWATO",
    Latitude: "-24.7764",
    Longitude: "29.6666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16832",
    WardLookupId: "2086",
    VDNumber: "76230402",
    RegisteredPopulation: "455",
    VotingStationName: "THINGWA PRIMARY SCHOOL",
    Address: "GA-MARISHANE  MAKHUDUTHAMAGA  MORWANGWATO",
    Latitude: "-24.7391",
    Longitude: "29.6387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16833",
    WardLookupId: "2086",
    VDNumber: "76230435",
    RegisteredPopulation: "434",
    VotingStationName: "HOPEFIELD PRIMARY SCHOOL",
    Address: "HOPEFIELD GA MARISHANE  MAKHUDUTHAMAGA  MORWANGWATO",
    Latitude: "-24.7099",
    Longitude: "29.5884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16834",
    WardLookupId: "2086",
    VDNumber: "76230660",
    RegisteredPopulation: "216",
    VotingStationName: "THUTLWAMAKWA PRIMARY SCHOOL",
    Address: "MMANGWANYANA GA-MARISHANE  MAKHUDUTHAMAGA  MARISHANE",
    Latitude: "-24.7742",
    Longitude: "29.6897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16835",
    WardLookupId: "4037",
    VDNumber: "54820021",
    RegisteredPopulation: "481",
    VotingStationName: "LAERSKOOL OHRIGSTAD",
    Address: "PORTION 123  OHRIGSTAD  OHRIGSTAD",
    Latitude: "-24.7413",
    Longitude: "30.5549",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16836",
    WardLookupId: "4037",
    VDNumber: "54860047",
    RegisteredPopulation: "383",
    VotingStationName: "MAEPA PRIMARY SCHOOL",
    Address: "OHRISTAD/BURGERSFORT ROAD  MAEPA  VILLAGE  ORIGHSTAD",
    Latitude: "-24.7211",
    Longitude: "30.5318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16837",
    WardLookupId: "4037",
    VDNumber: "54860069",
    RegisteredPopulation: "157",
    VotingStationName: "PURE PLAAS NURSERY (TENT)",
    Address: "ORIGHSTAD  GREATER TUBATSE  ORIGHSTAD",
    Latitude: "-24.8285",
    Longitude: "30.5591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16838",
    WardLookupId: "4037",
    VDNumber: "76380059",
    RegisteredPopulation: "1025",
    VotingStationName: "MAREOLOGE PRIMARY SCHOOL",
    Address: "LEBOENG  PRAKTISEER",
    Latitude: "-24.5063",
    Longitude: "30.6527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16839",
    WardLookupId: "4037",
    VDNumber: "76380307",
    RegisteredPopulation: "544",
    VotingStationName: "MAKOPUNG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MAKOPUNG /MASEHLELE VILLAGE  SEKHUKHUNE",
    Latitude: "-24.5034",
    Longitude: "30.5928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16840",
    WardLookupId: "4037",
    VDNumber: "76380330",
    RegisteredPopulation: "481",
    VotingStationName: "MATLABONG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MALAENENG VILLAGE  SEKHUKHUNE",
    Latitude: "-24.5444",
    Longitude: "30.6738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16841",
    WardLookupId: "4037",
    VDNumber: "76380374",
    RegisteredPopulation: "445",
    VotingStationName: "MOKUTUNG PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  MOKUTUNG  SEKHUKHUNE",
    Latitude: "-24.5811",
    Longitude: "30.5986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16842",
    WardLookupId: "4037",
    VDNumber: "76380497",
    RegisteredPopulation: "375",
    VotingStationName: "MAKGALANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MAKGALANE VILLAGE  SEKHUKHUNE",
    Latitude: "-24.5159",
    Longitude: "30.6331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16843",
    WardLookupId: "4037",
    VDNumber: "76380510",
    RegisteredPopulation: "510",
    VotingStationName: "MOHLATSENGWANE HIGH SCHOOL",
    Address: "NO STREET ADDRESS  MAPARENG VILLAGE  SEKHUKHUNE",
    Latitude: "-24.551",
    Longitude: "30.6539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16844",
    WardLookupId: "4038",
    VDNumber: "54860104",
    RegisteredPopulation: "1873",
    VotingStationName: "KGAHLANONG HIGH SCHOOL",
    Address: "NO ADDRESS  EERSTEGELUK,GA-MAAPODILE  SEKHUKHUNE",
    Latitude: "-24.7306",
    Longitude: "30.1357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16845",
    WardLookupId: "4038",
    VDNumber: "54860227",
    RegisteredPopulation: "1502",
    VotingStationName: "MAELEBE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  TUKAKGOMO TOWNSHIP  SEKHUKHUNE",
    Latitude: "-24.7432",
    Longitude: "30.1436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16846",
    WardLookupId: "4038",
    VDNumber: "54860238",
    RegisteredPopulation: "526",
    VotingStationName: "TUKAKGOMO (TENT)",
    Address: "TUKAKGOMO EXT.  BURGERSFORT  TUKAKGOMO EXT",
    Latitude: "-24.7619",
    Longitude: "30.1559",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16847",
    WardLookupId: "4038",
    VDNumber: "54860272",
    RegisteredPopulation: "417",
    VotingStationName: "MAHLWAKWENA S/GROUND (TENT)",
    Address: "GA-MAHLWAKWENA  SEKHUKHUNE  GA-MAHLWAKWENA",
    Latitude: "-24.7272",
    Longitude: "30.1852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16848",
    WardLookupId: "4038",
    VDNumber: "54860283",
    RegisteredPopulation: "412",
    VotingStationName: "LUTHEREN CHURCH LONGTILL TOWNSHIP",
    Address: "LONGTILL TOWNSHIP  SEKHUKHUNE  LONGTILL TOWNSHIP",
    Latitude: "-24.7375",
    Longitude: "30.1277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16849",
    WardLookupId: "4039",
    VDNumber: "76360439",
    RegisteredPopulation: "701",
    VotingStationName: "LEGANABATHO PRIMARY SCHOOL",
    Address: "MOHLALETSE,TJATE  SEKHUKHUNE  SEKHUKHUNE TRIBAL",
    Latitude: "-24.4735",
    Longitude: "29.8845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16850",
    WardLookupId: "4039",
    VDNumber: "76360440",
    RegisteredPopulation: "500",
    VotingStationName: "THETE HIGH SCHOOL",
    Address: "NO STREET NAME  MOHLALETSE  SEKHUKHUNE",
    Latitude: "-24.4916",
    Longitude: "29.8941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16851",
    WardLookupId: "4039",
    VDNumber: "76360451",
    RegisteredPopulation: "1120",
    VotingStationName: "MAPHUTHE PRIMARY SCHOOL",
    Address: "NO STREET NAME  MOHLALETSE  SEKHUKHUNE",
    Latitude: "-24.4843",
    Longitude: "29.8707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16852",
    WardLookupId: "4039",
    VDNumber: "76360619",
    RegisteredPopulation: "483",
    VotingStationName: "PEU HIGH SCHOOL",
    Address: "NO STREET NAME  PEU VILLAGE  SEKHUKHUNE",
    Latitude: "-24.468",
    Longitude: "29.9149",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16853",
    WardLookupId: "4039",
    VDNumber: "76360709",
    RegisteredPopulation: "1497",
    VotingStationName: "MAEBE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MOHLALETSE  SEKHUKHUNE",
    Latitude: "-24.4928",
    Longitude: "29.8812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16854",
    WardLookupId: "4039",
    VDNumber: "76360811",
    RegisteredPopulation: "449",
    VotingStationName: "MOSHIANE PRIMARY SCHOOL",
    Address:
      "NO STREET ADDRESS AVAILABLE  GA PHASHA MOHLALETSE  POLOKWANE / SEKHUKHUNE",
    Latitude: "-24.482",
    Longitude: "29.8867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16855",
    WardLookupId: "4039",
    VDNumber: "76360989",
    RegisteredPopulation: "307",
    VotingStationName: "SEBATANE CHRECH",
    Address: "TSWERENG VILLAGE  SEKHUKHUNE  SEKHUKHUNE",
    Latitude: "-24.4845",
    Longitude: "29.911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16856",
    WardLookupId: "4040",
    VDNumber: "76200791",
    RegisteredPopulation: "306",
    VotingStationName: "IMBITA PRIVATE SCHOOL",
    Address: "GA-MAROGA  SEKHUKHUNE  GA-MAROGA",
    Latitude: "-24.6229",
    Longitude: "30.1898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16857",
    WardLookupId: "4040",
    VDNumber: "76200803",
    RegisteredPopulation: "445",
    VotingStationName: "MATJIANENG CRECHE",
    Address: "RIBA CROSS  GREATE TUBATSE  GA-RIBA",
    Latitude: "-24.6211",
    Longitude: "30.1984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16858",
    WardLookupId: "4040",
    VDNumber: "76380408",
    RegisteredPopulation: "3040",
    VotingStationName: "LEHLABA HIGH SCHOOL",
    Address: "NO STREET ADDRESS  RIBA-CROSS  SEKHUKHUNE",
    Latitude: "-24.6222",
    Longitude: "30.2117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16859",
    WardLookupId: "4040",
    VDNumber: "76380598",
    RegisteredPopulation: "734",
    VotingStationName: "LUTHEREN CHURCH",
    Address: "RIBA-CROSS  BURGERSFORT  RIBA-CROSS",
    Latitude: "-24.6178",
    Longitude: "30.2131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16860",
    WardLookupId: "4041",
    VDNumber: "76380172",
    RegisteredPopulation: "1674",
    VotingStationName: "SEKABATE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MADISENG  SEKHUKHUNE",
    Latitude: "-24.6225",
    Longitude: "30.2341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16861",
    WardLookupId: "4041",
    VDNumber: "76380532",
    RegisteredPopulation: "1814",
    VotingStationName: "MOGOLO HIGH SCHOOL",
    Address: "NO STREET ADDRESS  RIBA-CROSS  SEKHUKHUNE",
    Latitude: "-24.6264",
    Longitude: "30.2075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16862",
    WardLookupId: "4041",
    VDNumber: "76380600",
    RegisteredPopulation: "587",
    VotingStationName: "MOREWANE PRIMARY SCHOOL",
    Address:
      "RIBA CROSS MADITHONGWANE SECT  FETAKGOMOTUBATSE  BURGERSFORT GA RIBA",
    Latitude: "-24.6159",
    Longitude: "30.2247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16863",
    WardLookupId: "4041",
    VDNumber: "76380611",
    RegisteredPopulation: "638",
    VotingStationName: "NTHAME PRIMARY SCHOOL",
    Address: "GA-MADISENG MANDELA SECTION  BURGERSFORT  GA-MADISENG",
    Latitude: "-24.6305",
    Longitude: "30.2297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16864",
    WardLookupId: "4041",
    VDNumber: "76380712",
    RegisteredPopulation: "293",
    VotingStationName: "RIBA (TENT)",
    Address: "GA RIBA  GREATER TUBATSE  BAKONE PHUTI",
    Latitude: "-24.6033",
    Longitude: "30.2075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16865",
    WardLookupId: "4041",
    VDNumber: "76380802",
    RegisteredPopulation: "474",
    VotingStationName: "MASHAMUTHANE LONDON (TENT)",
    Address: "GA-MASHAMUTHANE  SEKHUKHUNE  GA-MASHAMUTHANE",
    Latitude: "-24.6396",
    Longitude: "30.2403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16866",
    WardLookupId: "4042",
    VDNumber: "76390049",
    RegisteredPopulation: "1007",
    VotingStationName: "KGOBOKO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  PHASHA VILLAGE  SEKHUKHUNE",
    Latitude: "-24.7567",
    Longitude: "30.1232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16867",
    WardLookupId: "4042",
    VDNumber: "76390050",
    RegisteredPopulation: "773",
    VotingStationName: "ROKA-PHASHA TRIBAL AUTHORITY",
    Address: "NO STREET ADDRESS  GA-PHASHA  SEKHUKHUNE",
    Latitude: "-24.7795",
    Longitude: "30.1039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16868",
    WardLookupId: "4042",
    VDNumber: "76390061",
    RegisteredPopulation: "1127",
    VotingStationName: "NKOKWANE PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  EERSTEGELUK  SEKHUKHUNE",
    Latitude: "-24.8304",
    Longitude: "30.0728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16869",
    WardLookupId: "4042",
    VDNumber: "76390083",
    RegisteredPopulation: "1338",
    VotingStationName: "MAMPURU PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MAMPURU   SEKHUKHUNE",
    Latitude: "-24.8122",
    Longitude: "30.0875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16870",
    WardLookupId: "4042",
    VDNumber: "76390117",
    RegisteredPopulation: "1048",
    VotingStationName: "NGWANATHEKO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-PHASHA MAMPURU  SEKHUKHUNE",
    Latitude: "-24.8009",
    Longitude: "30.0969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16871",
    WardLookupId: "4042",
    VDNumber: "76390241",
    RegisteredPopulation: "537",
    VotingStationName: "MANTE PRIMARY SCHOOL",
    Address: "GA PHASHA  SEKHUKHUNE  GA PHASHA",
    Latitude: "-24.7735",
    Longitude: "30.1094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16872",
    WardLookupId: "4043",
    VDNumber: "76200319",
    RegisteredPopulation: "1515",
    VotingStationName: "BONWANKWE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  DRIEKOP-DIFATANENG   SEKHUKHUNE",
    Latitude: "-24.5983",
    Longitude: "30.1474",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16873",
    WardLookupId: "4043",
    VDNumber: "76200320",
    RegisteredPopulation: "1293",
    VotingStationName: "GOWE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GOWE VILLAGE  SEKHUKHUNE",
    Latitude: "-24.6094",
    Longitude: "30.1731",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16874",
    WardLookupId: "4043",
    VDNumber: "76200577",
    RegisteredPopulation: "710",
    VotingStationName: "TUMISHI PRIMARY SCHOOL",
    Address: "DRIEKOP-KAMPENG  BURGERSFORT  DRIEKOP",
    Latitude: "-24.5944",
    Longitude: "30.1751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16875",
    WardLookupId: "4043",
    VDNumber: "76200779",
    RegisteredPopulation: "433",
    VotingStationName: "MAPONONG S/GROUND (TENT)",
    Address: "GA-MAROGA  SEKHUKHUNE  GA-MAROGA",
    Latitude: "-24.6093",
    Longitude: "30.1399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16876",
    WardLookupId: "4043",
    VDNumber: "76200780",
    RegisteredPopulation: "965",
    VotingStationName: "FRANS SECTION (TENT)",
    Address: "GA-MAROGA  SEKHUKHUNE  GA-MAROGA",
    Latitude: "-24.6019",
    Longitude: "30.1649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16877",
    WardLookupId: "4044",
    VDNumber: "76200049",
    RegisteredPopulation: "732",
    VotingStationName: "DIHLABAKELA HIGH SCHOOL",
    Address: "NO STREET ADDRESS  DIPHALE  SEKHUKHUNE",
    Latitude: "-24.5044",
    Longitude: "30.0492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16878",
    WardLookupId: "4044",
    VDNumber: "76200050",
    RegisteredPopulation: "544",
    VotingStationName: "HLAHLANA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MADIKANA  SEKHUKHUNE",
    Latitude: "-24.4971",
    Longitude: "30.0954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16879",
    WardLookupId: "4044",
    VDNumber: "76200061",
    RegisteredPopulation: "894",
    VotingStationName: "TSWAKO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA MOHLALA  SEKHUKHUNE",
    Latitude: "-24.5275",
    Longitude: "30.0641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16880",
    WardLookupId: "4044",
    VDNumber: "76200263",
    RegisteredPopulation: "494",
    VotingStationName: "SEFUFULE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MASHABELA MODIMOLLE  SEKHUKHUNE",
    Latitude: "-24.4732",
    Longitude: "30.0447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16881",
    WardLookupId: "4044",
    VDNumber: "76200421",
    RegisteredPopulation: "373",
    VotingStationName: "DIKETEPE PRIMARY SCHOOL",
    Address: "NO SREET ADDRESS AVAILABLE  MANTSAKANE  SEKHUKHUNE  ",
    Latitude: "-24.4732",
    Longitude: "30.0641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16882",
    WardLookupId: "4044",
    VDNumber: "76200522",
    RegisteredPopulation: "220",
    VotingStationName: "HLONG PRIMARY SCHOOL",
    Address: "DIPHALE-SEUWE  BURGERSFORT  DIPHALE-SEUWE",
    Latitude: "-24.544",
    Longitude: "30.048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16883",
    WardLookupId: "4044",
    VDNumber: "76200533",
    RegisteredPopulation: "319",
    VotingStationName: "RETHABILE ABET CENTER",
    Address: "DIPHALE-SEUWE  BURGERSFORT  DIPHALE-SEUWE",
    Latitude: "-24.5277",
    Longitude: "30.0679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16884",
    WardLookupId: "4044",
    VDNumber: "76200544",
    RegisteredPopulation: "331",
    VotingStationName: "MABORAGANE SECONDARY SCHOOL",
    Address: "MODIMOLLE DRIEKOP  FETAKGOMOTUBATSE  BURGERSFORT GA MASHABELA",
    Latitude: "-24.4843",
    Longitude: "30.0319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16885",
    WardLookupId: "4044",
    VDNumber: "76200555",
    RegisteredPopulation: "302",
    VotingStationName: "MAGABANENG PRIMARY SCHOOL",
    Address: "DIPHALE  BURGERSFORT  DIPHALE",
    Latitude: "-24.5193",
    Longitude: "30.0437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16886",
    WardLookupId: "4044",
    VDNumber: "76200601",
    RegisteredPopulation: "315",
    VotingStationName: "LESIBE PRIMARY SCHOOL",
    Address: "DIPHALE  BURGERSFORT  DIPHALE",
    Latitude: "-24.5205",
    Longitude: "30.0849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16887",
    WardLookupId: "4044",
    VDNumber: "76200814",
    RegisteredPopulation: "319",
    VotingStationName: "MAGABANENG COMMUNITY HALL",
    Address: "MAGABANENG  SEKHUKHUNE  MAGABANENG",
    Latitude: "-24.4927",
    Longitude: "30.0662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16888",
    WardLookupId: "4044",
    VDNumber: "76200825",
    RegisteredPopulation: "273",
    VotingStationName: "MAFETE PRIMARY SCHOOL",
    Address: "DIPHALE  SEKHUKHUNE  GA-MOHLALA",
    Latitude: "-24.4917",
    Longitude: "30.0561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16889",
    WardLookupId: "4044",
    VDNumber: "76200836",
    RegisteredPopulation: "215",
    VotingStationName: "GA-LEKWADU (TENT)",
    Address: "DIPHALE SEUWE  SEKHUKHUNE  DIPHALE SEUWE",
    Latitude: "-24.5351",
    Longitude: "30.069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16890",
    WardLookupId: "4044",
    VDNumber: "76200847",
    RegisteredPopulation: "176",
    VotingStationName: "MOLEKANE PRIMARY SCHOOL",
    Address: "DIPHALE, GA-MAKHWAI  SEKHUKHUNE  GA-MOHLALA",
    Latitude: "-24.5539",
    Longitude: "30.0613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16891",
    WardLookupId: "4045",
    VDNumber: "76200139",
    RegisteredPopulation: "648",
    VotingStationName: "SHAI PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  HARECROFT  SEKHUKHUNE",
    Latitude: "-24.3084",
    Longitude: "30.1734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16892",
    WardLookupId: "4045",
    VDNumber: "76200229",
    RegisteredPopulation: "792",
    VotingStationName: "LETOLWANE HIGH SCHOOL",
    Address: "GA-LETOLWANE  SHAKUNG VILLAGE  DRIEKOP",
    Latitude: "-24.4008",
    Longitude: "30.1347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16893",
    WardLookupId: "4045",
    VDNumber: "76200296",
    RegisteredPopulation: "1442",
    VotingStationName: "MMUTLANE HIGH SCHOOL",
    Address: "NO STREET ADDRESS  MALOKELA   SEKHUKHUNE",
    Latitude: "-24.3618",
    Longitude: "30.1653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16894",
    WardLookupId: "4045",
    VDNumber: "76200353",
    RegisteredPopulation: "942",
    VotingStationName: "THOKOANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MOROKE-THOKOANE   SEKHUKHUNE",
    Latitude: "-24.4122",
    Longitude: "30.0825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16895",
    WardLookupId: "4045",
    VDNumber: "76200454",
    RegisteredPopulation: "591",
    VotingStationName: "MABU PRIMARY SCHOOL",
    Address: "NO SREET ADDRESS AVAILABLE  SEHUNYANE  SEKHUKHUNE ",
    Latitude: "-24.3632",
    Longitude: "30.1144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16896",
    WardLookupId: "4045",
    VDNumber: "76200566",
    RegisteredPopulation: "426",
    VotingStationName: "KWATA PRIMARY SCHOOL",
    Address: "GA PHALA MABOLOKE DRIEKOP  FETAKGOMOTUBATSE  GA PHALA DRIEKOP",
    Latitude: "-24.3469",
    Longitude: "30.1549",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16897",
    WardLookupId: "4045",
    VDNumber: "76200858",
    RegisteredPopulation: "438",
    VotingStationName: "MOLOPO PRIMARY SCHOOL",
    Address: "MALOKELA  SEKHUKHUNE  MALOKELA",
    Latitude: "-24.378",
    Longitude: "30.1776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16898",
    WardLookupId: "4046",
    VDNumber: "76200106",
    RegisteredPopulation: "532",
    VotingStationName: "MONAMPANE LOWER/HIGHER SCHOOL",
    Address: "NO STREET ADDRESS  GA MASHABELA VILLAGE  SEKHUKHUNE",
    Latitude: "-24.4591",
    Longitude: "30.0044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16899",
    WardLookupId: "4046",
    VDNumber: "76200117",
    RegisteredPopulation: "836",
    VotingStationName: "MOTLAMMOTSE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MONGATANE VILLAGE  SEKHUKHUNE",
    Latitude: "-24.4385",
    Longitude: "30.0062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16900",
    WardLookupId: "4046",
    VDNumber: "76200128",
    RegisteredPopulation: "774",
    VotingStationName: "MOSEGO HIGH SCHOOL",
    Address: "NO STREET ADDRESS  TJATE-NTSWANENG  SEKHUKHUNE",
    Latitude: "-24.5045",
    Longitude: "30.0188",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16901",
    WardLookupId: "4046",
    VDNumber: "76200151",
    RegisteredPopulation: "839",
    VotingStationName: "DIPHALA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MOROKE-MADIFAHLANE  SEKHUKHUNE",
    Latitude: "-24.3976",
    Longitude: "30.0604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16902",
    WardLookupId: "4046",
    VDNumber: "76200443",
    RegisteredPopulation: "512",
    VotingStationName: "MAKGOPA PRIMARY SCHOOL",
    Address: "NO SREET ADDRESS AVAILABLE  GA-MAKGOPA  SEKHUKHUNE  ",
    Latitude: "-24.4004",
    Longitude: "30.0224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16903",
    WardLookupId: "4046",
    VDNumber: "76200465",
    RegisteredPopulation: "632",
    VotingStationName: "TSHIHLO SECONDARY SCHOOL",
    Address: "NO SREET ADDRESS AVAILABLE  SERAFA  SEKHUKHUNE  ",
    Latitude: "-24.4",
    Longitude: "30.0487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16904",
    WardLookupId: "4046",
    VDNumber: "76200476",
    RegisteredPopulation: "331",
    VotingStationName: "NTWA SELLO CAFE",
    Address: "GA-MASHABELA  BURGERSFORT  GA-MASHABELA",
    Latitude: "-24.4348",
    Longitude: "30.0023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16905",
    WardLookupId: "4046",
    VDNumber: "76200487",
    RegisteredPopulation: "197",
    VotingStationName: "NKGABANE YARD (TENT)",
    Address: "MAAKGAKE  TUBATSE  GA-MASHABELA",
    Latitude: "-24.4335",
    Longitude: "30.0188",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16906",
    WardLookupId: "4046",
    VDNumber: "76200498",
    RegisteredPopulation: "256",
    VotingStationName: "LEPHENYE HIGH SCHOOL",
    Address: "GA-MASHABELA  BURGERSFORT  MASHABELA",
    Latitude: "-24.4554",
    Longitude: "30.0185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16907",
    WardLookupId: "4046",
    VDNumber: "76200511",
    RegisteredPopulation: "154",
    VotingStationName: "TELEKI SECONDARY SCHOOL",
    Address: "DITHABANENG VILLAGE  BURGERSFORT  GA-MASHABELA",
    Latitude: "-24.439",
    Longitude: "30.0402",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16908",
    WardLookupId: "4046",
    VDNumber: "76200746",
    RegisteredPopulation: "290",
    VotingStationName: "ITIRELE CRECHE",
    Address: "BURGERSFORT  TUBATSE  GA-MASHABELA",
    Latitude: "-24.4483",
    Longitude: "30.0086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16909",
    WardLookupId: "4046",
    VDNumber: "76200757",
    RegisteredPopulation: "87",
    VotingStationName: "GA-MALATJIE (TENT)",
    Address: "TIDINTITJANE  SEKHUKHUNE  GA-MASHABELA",
    Latitude: "-24.487",
    Longitude: "30.0079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16910",
    WardLookupId: "4047",
    VDNumber: "76200072",
    RegisteredPopulation: "543",
    VotingStationName: "MORAKADIETA PRIMARY SCHOOL",
    Address: "MAHLOKWANE  MAHLOKWANE/DRIEKOP VILLAGE  DILOKONG",
    Latitude: "-24.5613",
    Longitude: "30.1121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16911",
    WardLookupId: "4047",
    VDNumber: "76200252",
    RegisteredPopulation: "995",
    VotingStationName: "MAROGA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MAROGA   SEKHUKHUNE",
    Latitude: "-24.5411",
    Longitude: "30.1483",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16912",
    WardLookupId: "4047",
    VDNumber: "76200409",
    RegisteredPopulation: "1050",
    VotingStationName: "SEBOPE PRIMARY SCHOOL",
    Address:
      "DRIEKOP  GOPOLALEGABA VILLAGE  [BURGERSFORT/OHRIGSTAD/EASTERN TUBATSE]",
    Latitude: "-24.5608",
    Longitude: "30.1649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16913",
    WardLookupId: "4047",
    VDNumber: "76200612",
    RegisteredPopulation: "473",
    VotingStationName: "MAPUTLE PRIMARY SCHOOL",
    Address: "LEGAPENG SECTION BURGERSFORT  FETAKGOMOTUBATSE  GA MAROGA",
    Latitude: "-24.545",
    Longitude: "30.1542",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16914",
    WardLookupId: "4047",
    VDNumber: "76200623",
    RegisteredPopulation: "570",
    VotingStationName: "MABOWELETSE PRIMARY SCHOOL",
    Address: "GA-MAROGA  BURGERSFORT  GA-MAROGA",
    Latitude: "-24.5261",
    Longitude: "30.1268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16915",
    WardLookupId: "4047",
    VDNumber: "76200667",
    RegisteredPopulation: "518",
    VotingStationName: "MOLAKA PRIMARY SCHOOL",
    Address: "MAANGSHOEK/GA-SEKITI  BURGERSFORT  MAANDAGSHOEK/GA-SEKITI",
    Latitude: "-24.5801",
    Longitude: "30.0972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16916",
    WardLookupId: "4047",
    VDNumber: "76200768",
    RegisteredPopulation: "581",
    VotingStationName: "BONWANKWE CHURCH",
    Address: "MOOHOEK  TUBATSE  GA-MAROGA",
    Latitude: "-24.5924",
    Longitude: "30.1545",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16917",
    WardLookupId: "4047",
    VDNumber: "76200948",
    RegisteredPopulation: "205",
    VotingStationName: "MABILU CAFE (TENT)",
    Address: "DIGABANE VILLAGE   MANDAAGSHOEK  BAROKA BA MAMPHAHLANE",
    Latitude: "-24.5561",
    Longitude: "30.0988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16918",
    WardLookupId: "4048",
    VDNumber: "54860182",
    RegisteredPopulation: "241",
    VotingStationName: "MATHOLENI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MATIMATSATSI VILLAGE  SEKHUKHUNE",
    Latitude: "-24.6719",
    Longitude: "30.1139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16919",
    WardLookupId: "4048",
    VDNumber: "76200016",
    RegisteredPopulation: "1168",
    VotingStationName: "MORULADILEPE PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  GA-DIPHALE  SEKHUKHUNE",
    Latitude: "-24.6016",
    Longitude: "30.1165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16920",
    WardLookupId: "4048",
    VDNumber: "76200027",
    RegisteredPopulation: "1215",
    VotingStationName: "PHUTINARE HIGH SCHOOL",
    Address: "NO STREET ADRESS  MAANDAGSHOEK  SEKHUKHUNE",
    Latitude: "-24.5955",
    Longitude: "30.0801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16921",
    WardLookupId: "4048",
    VDNumber: "76200397",
    RegisteredPopulation: "578",
    VotingStationName: "RATAU PRIMARY SCHOOL",
    Address:
      "MAMPHLANE  MAMPHAHLANE VILLAGE  [BURGERSFORT/OHRIGSTAD/EASTERN TUBATSE]",
    Latitude: "-24.5846",
    Longitude: "30.0682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16922",
    WardLookupId: "4048",
    VDNumber: "76200634",
    RegisteredPopulation: "124",
    VotingStationName: "MAKOBOTE PRIMARY SCHOOL",
    Address: "LEOLO  BURGERSFORT  LEOLO",
    Latitude: "-24.5788",
    Longitude: "30.0289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16923",
    WardLookupId: "4048",
    VDNumber: "76200645",
    RegisteredPopulation: "525",
    VotingStationName: "MAMPHAHLANE COMMUNITY HALL",
    Address: "GA MAMPHAHLANE  MAANDAGSHOEK  BAROKA BA MAMPHAHLANE",
    Latitude: "-24.6166",
    Longitude: "30.0947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16924",
    WardLookupId: "4048",
    VDNumber: "76200892",
    RegisteredPopulation: "559",
    VotingStationName: "BALOTJANENG APOSTOLIC CHURCH",
    Address: "GA-MANKGAGANYANE  SEKHUKHUNE  MANDAGSHOEK",
    Latitude: "-24.6085",
    Longitude: "30.126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16925",
    WardLookupId: "4049",
    VDNumber: "76380149",
    RegisteredPopulation: "1763",
    VotingStationName: "ITIRELE PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  PRAKTISEER TOWNSHIP  SEKHUKHUNE",
    Latitude: "-24.5864",
    Longitude: "30.3142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16926",
    WardLookupId: "4049",
    VDNumber: "76380150",
    RegisteredPopulation: "1504",
    VotingStationName: "MAGISTRATE COURTS",
    Address: "PRAKTISEER MAGISTRATE COURT  PRAKTISEER  PRAKTISEER",
    Latitude: "-24.5807",
    Longitude: "30.3234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16927",
    WardLookupId: "4049",
    VDNumber: "76380576",
    RegisteredPopulation: "983",
    VotingStationName: "SHEKINAH CHRISTIAN WORHIP CENTRE",
    Address: "PRAKTISEER  TUBATSE  BURGERSFORT",
    Latitude: "-24.5927",
    Longitude: "30.3114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16928",
    WardLookupId: "4049",
    VDNumber: "76380622",
    RegisteredPopulation: "1746",
    VotingStationName: "BATUBATSE PRIMARY SCHOOL",
    Address: "PRAKTISEER NEW STAND  BURGERSFORT  PRAKTISEER",
    Latitude: "-24.5837",
    Longitude: "30.3241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16929",
    WardLookupId: "4049",
    VDNumber: "76380857",
    RegisteredPopulation: "394",
    VotingStationName: "SEGORONG II PRIMARY SCHOOL",
    Address: "SHUSHUMELA  PRAKTISEER  BURGERSFORT",
    Latitude: "-24.5895",
    Longitude: "30.3356",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16930",
    WardLookupId: "4049",
    VDNumber: "76380914",
    RegisteredPopulation: "126",
    VotingStationName: "TINY DARLINGS DAY CARE",
    Address: "PRAKTISEER   FETAKGOMO TUBATSE MUNICIPALITY   BURGERSFORT",
    Latitude: "-24.5999",
    Longitude: "30.3232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16931",
    WardLookupId: "4050",
    VDNumber: "76200162",
    RegisteredPopulation: "1507",
    VotingStationName: "NTWAMPE HIGH SCHOOL",
    Address: "NO STREET ADRESS  MOROKE  SEKHUKHUNE",
    Latitude: "-24.3833",
    Longitude: "30.071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16932",
    WardLookupId: "4050",
    VDNumber: "76200173",
    RegisteredPopulation: "596",
    VotingStationName: "MALEGODI PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  GA-MOSHIRA  SEKHUKHUNE",
    Latitude: "-24.3491",
    Longitude: "30.1017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16933",
    WardLookupId: "4050",
    VDNumber: "76200285",
    RegisteredPopulation: "878",
    VotingStationName: "MOTLOULELA SENIOR SECONDARY SCHOOL",
    Address: "NO STREET ADRESS  MOTLOULELA VILLAGE  SEKHUKHUNE",
    Latitude: "-24.318",
    Longitude: "30.1174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16934",
    WardLookupId: "4050",
    VDNumber: "76200364",
    RegisteredPopulation: "558",
    VotingStationName: "LEDINGWE PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  DITHABANENG   SEKHUKHUNE",
    Latitude: "-24.3121",
    Longitude: "30.0685",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16935",
    WardLookupId: "4050",
    VDNumber: "76200410",
    RegisteredPopulation: "654",
    VotingStationName: "MAGOBANYE HIGH SCHOOL",
    Address: "NO STREET ADRESS  MAGOBADING VILLAGE  SEKHUKHUNE",
    Latitude: "-24.3558",
    Longitude: "30.0799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16936",
    WardLookupId: "4050",
    VDNumber: "76200678",
    RegisteredPopulation: "468",
    VotingStationName: "MAEPA PRIMARY SCHOOL",
    Address: "MOROKE  BURGERSFORT  MOROKE",
    Latitude: "-24.3757",
    Longitude: "30.0539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16937",
    WardLookupId: "4050",
    VDNumber: "76200869",
    RegisteredPopulation: "253",
    VotingStationName: "MOROKE SEKHUTLONG CRECHE",
    Address: "MOROKE  GREATER TUBATSE  MAGAKALA",
    Latitude: "-24.4054",
    Longitude: "30.0798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16938",
    WardLookupId: "4051",
    VDNumber: "76200184",
    RegisteredPopulation: "668",
    VotingStationName: "MASHISHI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MASHISHI   SEKHUKHUNE",
    Latitude: "-24.4618",
    Longitude: "30.1146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16939",
    WardLookupId: "4051",
    VDNumber: "76200195",
    RegisteredPopulation: "780",
    VotingStationName: "TEKANANG HIGH SCHOOL",
    Address: "NO STREET ADDRESS  MORAPANENG  SEKHUKHUNE",
    Latitude: "-24.4326",
    Longitude: "30.0493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16940",
    WardLookupId: "4051",
    VDNumber: "76200207",
    RegisteredPopulation: "1057",
    VotingStationName: "MAAKOPI HIGH SCHOOL",
    Address: "NO STREET ADDRESS  GA-KGOETE  SEKHUKHUNE",
    Latitude: "-24.4387",
    Longitude: "30.0897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16941",
    WardLookupId: "4051",
    VDNumber: "76200218",
    RegisteredPopulation: "369",
    VotingStationName: "MASETE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MASETE  SEKHUKHUNE",
    Latitude: "-24.4436",
    Longitude: "30.1264",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16942",
    WardLookupId: "4051",
    VDNumber: "76200230",
    RegisteredPopulation: "593",
    VotingStationName: "MOROLENG PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  SHAKUNG  SEKHUKHUNE",
    Latitude: "-24.4443",
    Longitude: "30.1665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16943",
    WardLookupId: "4051",
    VDNumber: "76200500",
    RegisteredPopulation: "497",
    VotingStationName: "MASEBUDI PRIMARY SCHOOL",
    Address: "DITOBELENG VILLAGE  BURGERSFORT  GA MASHABELA",
    Latitude: "-24.448",
    Longitude: "30.0632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16944",
    WardLookupId: "4051",
    VDNumber: "76200588",
    RegisteredPopulation: "381",
    VotingStationName: "MAKGAMATHU HIGH SCHOOL",
    Address: "GA-MASHISHI  BURGERSFORT  GA-MASHISHI",
    Latitude: "-24.4586",
    Longitude: "30.1059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16945",
    WardLookupId: "4051",
    VDNumber: "76200689",
    RegisteredPopulation: "415",
    VotingStationName: "MAKOFANE PRIMARY",
    Address: "MAPAENG SECTION SHAKUNG   FETAKGOMOTUBATSE   GA MAKOFANE",
    Latitude: "-24.4261",
    Longitude: "30.1492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16946",
    WardLookupId: "4051",
    VDNumber: "76200870",
    RegisteredPopulation: "415",
    VotingStationName: "NARENG THOKOANE TRIBAL OFFICES",
    Address: "GA-KGWETE  SEKHUKHUNE  GA-KGWETE",
    Latitude: "-24.4329",
    Longitude: "30.0896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16947",
    WardLookupId: "4051",
    VDNumber: "76200881",
    RegisteredPopulation: "337",
    VotingStationName: "GA-KGWETE (TENT)",
    Address: "GA-KGWENE NEW STANDS  SEKHUKHUNE  GA-KGWETE",
    Latitude: "-24.4471",
    Longitude: "30.092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16948",
    WardLookupId: "4051",
    VDNumber: "76200926",
    RegisteredPopulation: "174",
    VotingStationName: "MPHOGO PRIMARY SCHOOL",
    Address: "GA-MPHOGO CRYDON  SEKHUKHUNE  SEKHUKHUNE",
    Latitude: "-24.4314",
    Longitude: "30.1084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16949",
    WardLookupId: "4051",
    VDNumber: "76200937",
    RegisteredPopulation: "307",
    VotingStationName: "SEOKE PRIMARY SCHOOL",
    Address: "GA-MASHISHI, DRIEKOP  SEKHUKHUNE  SEKHUKHUNE",
    Latitude: "-24.4526",
    Longitude: "30.1081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16950",
    WardLookupId: "4052",
    VDNumber: "76200140",
    RegisteredPopulation: "866",
    VotingStationName: "MOGOLAHLOGO HIGH SCHOOL",
    Address: "NO STREET ADRESS  WIMBLENDON  SEKHUKHUNE",
    Latitude: "-24.4064",
    Longitude: "30.1992",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16951",
    WardLookupId: "4052",
    VDNumber: "76200432",
    RegisteredPopulation: "791",
    VotingStationName: "MATSIRI PRIMARY SCHOOL",
    Address: "NO SREET ADDRESS AVAILABLE  GA-MATSIRI  SEKHUKHUNE  ",
    Latitude: "-24.4272",
    Longitude: "30.2141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16952",
    WardLookupId: "4052",
    VDNumber: "76380015",
    RegisteredPopulation: "873",
    VotingStationName: "LEAGATHOKO HIGH SCHOOL PENGE",
    Address: "NO STREET ADRESS  PENGE MINE  SEKHUKHUNE",
    Latitude: "-24.3617",
    Longitude: "30.2973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16953",
    WardLookupId: "4052",
    VDNumber: "76380026",
    RegisteredPopulation: "283",
    VotingStationName: "KANAMA SCHOOL",
    Address: "NO STREET ADRESS  GA-MALEPE  SEKHUKHUNE",
    Latitude: "-24.4265",
    Longitude: "30.2805",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16954",
    WardLookupId: "4052",
    VDNumber: "76380093",
    RegisteredPopulation: "692",
    VotingStationName: "MOTSHANA PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  GA-MOTSHANA  SEKHUKHUNE",
    Latitude: "-24.4754",
    Longitude: "30.2994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16955",
    WardLookupId: "4052",
    VDNumber: "76380419",
    RegisteredPopulation: "930",
    VotingStationName: "ROTOLE PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  GA-MOKGOTHO  SEKHUKHUNE",
    Latitude: "-24.3336",
    Longitude: "30.3237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16956",
    WardLookupId: "4052",
    VDNumber: "76380633",
    RegisteredPopulation: "247",
    VotingStationName: "MAKWALE PRIMARY SCHOOL",
    Address: "PENGE  BURGERSFORT  PENGE",
    Latitude: "-24.3524",
    Longitude: "30.3176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16957",
    WardLookupId: "4052",
    VDNumber: "76380644",
    RegisteredPopulation: "162",
    VotingStationName: "KGAKANTSHANE PRIMARY SCHOOL 2",
    Address: "PENGE  BURGERSFORT  PENGE",
    Latitude: "-24.4602",
    Longitude: "30.2705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16958",
    WardLookupId: "4052",
    VDNumber: "76380655",
    RegisteredPopulation: "143",
    VotingStationName: "MAKIDI SECONDARY SCHOOL",
    Address: "PENGE  BURGERSFORT  PENGE",
    Latitude: "-24.3213",
    Longitude: "30.2809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16959",
    WardLookupId: "4052",
    VDNumber: "76380734",
    RegisteredPopulation: "526",
    VotingStationName: "PENGE COMMUNICTY HALL",
    Address: "PENGE  BURGERSFORT  PENGE",
    Latitude: "-24.3851",
    Longitude: "30.2897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16960",
    WardLookupId: "4053",
    VDNumber: "76200083",
    RegisteredPopulation: "958",
    VotingStationName: "MAMOGEGE HIGH SCHOOL",
    Address: "NO STREET ADRESS  GA-MAAPEA/MOTLOLO  SEKHUKHUNE",
    Latitude: "-24.4967",
    Longitude: "30.1562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16961",
    WardLookupId: "4053",
    VDNumber: "76200241",
    RegisteredPopulation: "896",
    VotingStationName: "MANYAKA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-MANYAKA   SEKHUKHUNE",
    Latitude: "-24.4807",
    Longitude: "30.1212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16962",
    WardLookupId: "4053",
    VDNumber: "76200342",
    RegisteredPopulation: "677",
    VotingStationName: "NTOSHANG PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  GA-MAHLOKWANE   SEKHUKHUNE",
    Latitude: "-24.5276",
    Longitude: "30.1055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16963",
    WardLookupId: "4053",
    VDNumber: "76200375",
    RegisteredPopulation: "1290",
    VotingStationName: "SELALA PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  GA-SELALA  SEKHUKHUNE",
    Latitude: "-24.4988",
    Longitude: "30.1374",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16964",
    WardLookupId: "4053",
    VDNumber: "76200599",
    RegisteredPopulation: "388",
    VotingStationName: "MOKWADIBE HIGH SCHOOL",
    Address: "GA-MANYAKA  BURGESFORT  GA-MANYAKA",
    Latitude: "-24.4825",
    Longitude: "30.1171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16965",
    WardLookupId: "4053",
    VDNumber: "76200690",
    RegisteredPopulation: "278",
    VotingStationName: "PHOGOLE PRIMARY SCHOOL",
    Address:
      "MAANDAGSHOEK-GA MAHLOKWANE  BURGERSFORT  MAANDAGSHOEK-GA MAHLOKWANE",
    Latitude: "-24.5498",
    Longitude: "30.1062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16966",
    WardLookupId: "4053",
    VDNumber: "76200702",
    RegisteredPopulation: "528",
    VotingStationName: "MOHLOPHE PRIMARY SCHOOL",
    Address: "GA-MPHETHI  BURGERSFORT  GA-MPHETHI",
    Latitude: "-24.4753",
    Longitude: "30.1455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16967",
    WardLookupId: "4053",
    VDNumber: "76200713",
    RegisteredPopulation: "381",
    VotingStationName: "RATANANG SENIOR SECONDARY SCHOOL",
    Address: "GA-SELALA  BURGERSFORT  GA-SELALA",
    Latitude: "-24.4923",
    Longitude: "30.126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16968",
    WardLookupId: "4053",
    VDNumber: "76200904",
    RegisteredPopulation: "212",
    VotingStationName: "PHOGOJANE DRIVING SCHOOL (TENT)",
    Address: "GA-SELALA NEW STANDS  SEKHUKHUNE  GA-SELALA",
    Latitude: "-24.5072",
    Longitude: "30.145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16969",
    WardLookupId: "4054",
    VDNumber: "54820032",
    RegisteredPopulation: "1667",
    VotingStationName: "MOGALES A BAGALE T.H.S",
    Address: "BURGERSFORT  ",
    Latitude: "-24.6864",
    Longitude: "30.3406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16970",
    WardLookupId: "4054",
    VDNumber: "54820065",
    RegisteredPopulation: "525",
    VotingStationName: "APIESDORING S/GROUND (TENT)",
    Address: "APISDORING  BURGERSFORT  APISDORING",
    Latitude: "-24.6549",
    Longitude: "30.3404",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16971",
    WardLookupId: "4054",
    VDNumber: "54820076",
    RegisteredPopulation: "771",
    VotingStationName: "BURGERSFORT EXT. 10 (TENT)",
    Address: "BURGERSFORT EXT. 10  BURGERSFORT  BURGERSFORT",
    Latitude: "-24.6655",
    Longitude: "30.3227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16972",
    WardLookupId: "4054",
    VDNumber: "54860171",
    RegisteredPopulation: "1556",
    VotingStationName: "MAFEMANE PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  GA-MANOKE   SEKHUKHUNE",
    Latitude: "-24.6407",
    Longitude: "30.3309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16973",
    WardLookupId: "4054",
    VDNumber: "54860294",
    RegisteredPopulation: "514",
    VotingStationName: "TUBATSE PRIMARY SCHOOL",
    Address: "GA-MANOKE  SEKHUKHUNE  GA-MANOKE",
    Latitude: "-24.6369",
    Longitude: "30.3162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16974",
    WardLookupId: "4055",
    VDNumber: "76200308",
    RegisteredPopulation: "1426",
    VotingStationName: "SEHLAKU HIGH SCHOOL",
    Address: "NO STREET ADRESS  GA-MOHLOPI  SEKHUKHUNE",
    Latitude: "-24.5839",
    Longitude: "30.1614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16975",
    WardLookupId: "4055",
    VDNumber: "76200386",
    RegisteredPopulation: "565",
    VotingStationName: "MALELENG PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  MOTLOLO /GA MAFOLO  SEKHUKHUNE",
    Latitude: "-24.5305",
    Longitude: "30.1609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16976",
    WardLookupId: "4055",
    VDNumber: "76200735",
    RegisteredPopulation: "397",
    VotingStationName: "KGOKODIBENG HIGH SCHOOL",
    Address: "MOTLOLO  GREATER TUBATSE  MOTLOLO",
    Latitude: "-24.5234",
    Longitude: "30.17",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16977",
    WardLookupId: "4055",
    VDNumber: "76200959",
    RegisteredPopulation: "222",
    VotingStationName: "THUTO KE LESEDI CRECHE",
    Address:
      "GA MATHIPA BARCELONA SECTION  FETAKGOMO TUBATSE MUNICIPALITY   GA MATHIPA",
    Latitude: "-24.5307",
    Longitude: "30.1536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16978",
    WardLookupId: "4055",
    VDNumber: "76380116",
    RegisteredPopulation: "791",
    VotingStationName: "BAKONE-PHUTI TRIBAL AUTHORITY",
    Address: "NO STREET ADRESS  GA-RIBA  SEKHUKHUNE",
    Latitude: "-24.5648",
    Longitude: "30.2233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16979",
    WardLookupId: "4055",
    VDNumber: "76380396",
    RegisteredPopulation: "511",
    VotingStationName: "RIBA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA RIBA  SEKHUKHUNE",
    Latitude: "-24.6071",
    Longitude: "30.203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16980",
    WardLookupId: "4055",
    VDNumber: "76380666",
    RegisteredPopulation: "259",
    VotingStationName: "TANTANYANE PRIMARY SCHOOL",
    Address: "MODUPI SECTION  GA RIBA  FETAKGOMOTUBATSE  GA RIBA",
    Latitude: "-24.5393",
    Longitude: "30.2073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16981",
    WardLookupId: "4055",
    VDNumber: "76380677",
    RegisteredPopulation: "765",
    VotingStationName: "LETAU SENIOR SECONDARY SCHOOL",
    Address: "GA RIBA NEWSTANDS DRIEKOP  DRIEKOP  GA RIBA",
    Latitude: "-24.5547",
    Longitude: "30.2236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16982",
    WardLookupId: "4056",
    VDNumber: "76380183",
    RegisteredPopulation: "1067",
    VotingStationName: "MAHLAGAUME SCHOOL",
    Address: "NO STREET ADRESS  BOTHASHOEK  SEKHUKHUNE",
    Latitude: "-24.6203",
    Longitude: "30.2903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16983",
    WardLookupId: "4056",
    VDNumber: "76380194",
    RegisteredPopulation: "1417",
    VotingStationName: "MOKGABUDI PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  BOTHASHOEK  SEKHUKHUNE",
    Latitude: "-24.6096",
    Longitude: "30.284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16984",
    WardLookupId: "4056",
    VDNumber: "76380206",
    RegisteredPopulation: "1180",
    VotingStationName: "TSWELOPELE SCHOOL",
    Address: "NO STREET ADRESS  BOTHASHOEK   SEKHUKHUNE",
    Latitude: "-24.5994",
    Longitude: "30.302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16985",
    WardLookupId: "4056",
    VDNumber: "76380688",
    RegisteredPopulation: "663",
    VotingStationName: "MMIDITSI HIGH SCHOOL",
    Address: "BOTHASHOEK  BURGERSFORT  BOTHASHOEK",
    Latitude: "-24.6255",
    Longitude: "30.2985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16986",
    WardLookupId: "4056",
    VDNumber: "76380745",
    RegisteredPopulation: "531",
    VotingStationName: "BOTHASHOEK ART AND CULTURE (TENT)",
    Address: "BOTHASHOEK  SEKHUKHUNE  GA-RAMAUBE",
    Latitude: "-24.6036",
    Longitude: "30.3023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16987",
    WardLookupId: "4056",
    VDNumber: "76380756",
    RegisteredPopulation: "655",
    VotingStationName: "PHAAHLA SECONDARY SCHOOL",
    Address: "BOTHASHOEK  SEKHUKHUNE  GA-RAMAUBE",
    Latitude: "-24.6008",
    Longitude: "30.2845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16988",
    WardLookupId: "4056",
    VDNumber: "76380880",
    RegisteredPopulation: "348",
    VotingStationName: "MAHLAGAUME PRIMARY SCHOOL 2",
    Address:
      "BOTHASHOEK  FETAKGOMO TUBATSE MUNICIPALITY   GA RAMAUBE BOTHASHOEK",
    Latitude: "-24.6138",
    Longitude: "30.3067",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16989",
    WardLookupId: "4057",
    VDNumber: "76200094",
    RegisteredPopulation: "678",
    VotingStationName: "ROOTSE PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  GA-PODILE  SEKHUKHUNE",
    Latitude: "-24.4807",
    Longitude: "30.1777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16990",
    WardLookupId: "4057",
    VDNumber: "76200274",
    RegisteredPopulation: "1367",
    VotingStationName: "MAFOLO PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  GA-MOTLOLO   SEKHUKHUNE",
    Latitude: "-24.5159",
    Longitude: "30.1784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16991",
    WardLookupId: "4057",
    VDNumber: "76200724",
    RegisteredPopulation: "316",
    VotingStationName: "NKGOMELENG SENIOR SECONDARY SCHOOL",
    Address: "GA PODILE DRIEKOP  DRIEKOP  GA PODILE",
    Latitude: "-24.4743",
    Longitude: "30.1753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16992",
    WardLookupId: "4057",
    VDNumber: "76200915",
    RegisteredPopulation: "625",
    VotingStationName: "THIBEDI PRIMARY SCHOOL",
    Address: "MOTLOLO  SEKHUKHUNE  GA-MAFOLO",
    Latitude: "-24.5075",
    Longitude: "30.1852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16993",
    WardLookupId: "4057",
    VDNumber: "76380037",
    RegisteredPopulation: "550",
    VotingStationName: "MAPITI PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  SEKOPUNG  SEKHUKHUNE",
    Latitude: "-24.4671",
    Longitude: "30.2077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16994",
    WardLookupId: "4057",
    VDNumber: "76380060",
    RegisteredPopulation: "524",
    VotingStationName: "MOOKOTSI SCHOOL",
    Address: "NO STREET ADRESS  GA MAKOFANE   SEKHUKHUNE",
    Latitude: "-24.4818",
    Longitude: "30.2233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16995",
    WardLookupId: "4057",
    VDNumber: "76380071",
    RegisteredPopulation: "497",
    VotingStationName: "KGOMATAU SCHOOL EDENDALE",
    Address: "NO STREET ADRESS  EDENDALE  SEKHUKHUNE",
    Latitude: "-24.4663",
    Longitude: "30.2535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16996",
    WardLookupId: "4057",
    VDNumber: "76380105",
    RegisteredPopulation: "939",
    VotingStationName: "MOISELE PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  GA-MAKOFANE (MOSHATE)  SEKHUKHUNE",
    Latitude: "-24.4982",
    Longitude: "30.2249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16997",
    WardLookupId: "4058",
    VDNumber: "76380217",
    RegisteredPopulation: "1061",
    VotingStationName: "MOUKANGOE HIGH SCHOOL",
    Address: "GA-MOTODI  NABOOMKOPPIES  PRAKTESEER",
    Latitude: "-24.5584",
    Longitude: "30.3393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16998",
    WardLookupId: "4058",
    VDNumber: "76380239",
    RegisteredPopulation: "536",
    VotingStationName: "MOTODI COMMUNITY HALL",
    Address: "NO STREET ADRESS  GA-MOTODI  SEKHUKHUNE",
    Latitude: "-24.5584",
    Longitude: "30.3516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "16999",
    WardLookupId: "4058",
    VDNumber: "76380240",
    RegisteredPopulation: "721",
    VotingStationName: "DIKOTOPE SECONDARY SCHOOL",
    Address: "NO STREET ADRESS  GA-MOTODI  SEKHUKHUNE",
    Latitude: "-24.5431",
    Longitude: "30.3646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17000",
    WardLookupId: "4058",
    VDNumber: "76380262",
    RegisteredPopulation: "95",
    VotingStationName: "MAKOTASENG SCHOOL",
    Address: "NO STREET ADRESS  MAKWATASENG  SEKHUKHUNE",
    Latitude: "-24.4885",
    Longitude: "30.4139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17001",
    WardLookupId: "4058",
    VDNumber: "76380329",
    RegisteredPopulation: "924",
    VotingStationName: "NTLAISHENG PRIMARY SCHOOL",
    Address: "TAUNG  SEKHUKHUNE  TAUNG",
    Latitude: "-24.462",
    Longitude: "30.3991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17002",
    WardLookupId: "4058",
    VDNumber: "76380385",
    RegisteredPopulation: "399",
    VotingStationName: "KGOBALALE PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  GA-MATOKOMANE  SEKHUKHUNE",
    Latitude: "-24.5111",
    Longitude: "30.3794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17003",
    WardLookupId: "4058",
    VDNumber: "76380543",
    RegisteredPopulation: "363",
    VotingStationName: "SEDIBENG PRE-SCHOOL",
    Address: "NO STREET ADRESS  GA MOTODI VILLAGE  SEKHUKHUNE",
    Latitude: "-24.5517",
    Longitude: "30.3491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17004",
    WardLookupId: "4058",
    VDNumber: "76380767",
    RegisteredPopulation: "532",
    VotingStationName: "LEKUBUSHAI PRIMARY SCHOOL",
    Address: "TAUNG  SEKHUKHUNE  TAUNG",
    Latitude: "-24.4534",
    Longitude: "30.3988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17005",
    WardLookupId: "4059",
    VDNumber: "76380048",
    RegisteredPopulation: "667",
    VotingStationName: "MAHLASHI PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  MAHLASHI  SDEKHUKHUNE",
    Latitude: "-24.5408",
    Longitude: "30.4342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17006",
    WardLookupId: "4059",
    VDNumber: "76380284",
    RegisteredPopulation: "834",
    VotingStationName: "MORETHUSHE SCHOOL",
    Address: "NO STREET ADRESS  ALVERTON  SEKHUKHUNE",
    Latitude: "-24.5673",
    Longitude: "30.3672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17007",
    WardLookupId: "4059",
    VDNumber: "76380295",
    RegisteredPopulation: "265",
    VotingStationName: "MAATSEREPE PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  ALVERTON  SEKHUKHUNE",
    Latitude: "-24.5864",
    Longitude: "30.3969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17008",
    WardLookupId: "4059",
    VDNumber: "76380318",
    RegisteredPopulation: "1867",
    VotingStationName: "LEHLABILE SENIOR SECONDARY  SCHOOL",
    Address: "NO STREET ADRESS  ALVERTON  SEKHUKHUNE",
    Latitude: "-24.5773",
    Longitude: "30.3759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17009",
    WardLookupId: "4059",
    VDNumber: "76380363",
    RegisteredPopulation: "855",
    VotingStationName: "KGOTLOPONG PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  KGOTLOPONG  SEKHUKHUNE",
    Latitude: "-24.5559",
    Longitude: "30.4802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17010",
    WardLookupId: "4059",
    VDNumber: "76380442",
    RegisteredPopulation: "417",
    VotingStationName: "ITSOTSENG PRIMARY SCHOOL",
    Address: "NO SREET ADRESS  MAFARAFARA VILLAGE  SEKHUKHUNE",
    Latitude: "-24.4933",
    Longitude: "30.4252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17011",
    WardLookupId: "4059",
    VDNumber: "76380925",
    RegisteredPopulation: "227",
    VotingStationName: "TSHEGOFATSO CRECHE AND NURSURY",
    Address: "NAZARATH VILLAGE  FETAKGOMO TUBATSE MUNICIPALITY   KGOETE TRIBAL",
    Latitude: "-24.5929",
    Longitude: "30.3605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17012",
    WardLookupId: "4060",
    VDNumber: "54860261",
    RegisteredPopulation: "177",
    VotingStationName: "SELAHLE TRIBAL HALL",
    Address: "MAJADITSHUKUDU  TUBATSE  SELAHLE",
    Latitude: "-24.6616",
    Longitude: "30.4663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17013",
    WardLookupId: "4060",
    VDNumber: "76380138",
    RegisteredPopulation: "1044",
    VotingStationName: "LEGOLENG PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  KGAUTSWANE VILLAGE  SEKHUKHUNE",
    Latitude: "-24.6161",
    Longitude: "30.5454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17014",
    WardLookupId: "4060",
    VDNumber: "76380431",
    RegisteredPopulation: "913",
    VotingStationName: "DIPITSI PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  MAKGWARENG VILLAGE  SEKHUKHUNE",
    Latitude: "-24.5752",
    Longitude: "30.5347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17015",
    WardLookupId: "4060",
    VDNumber: "76380464",
    RegisteredPopulation: "1000",
    VotingStationName: "KGAUTSWANA MOSHATE",
    Address: "NO STREET ADRESS  KGAUTSWANA  TRIBAL AUTHORITY  SEKHUKHUNE",
    Latitude: "-24.587",
    Longitude: "30.5306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17016",
    WardLookupId: "4060",
    VDNumber: "76380475",
    RegisteredPopulation: "815",
    VotingStationName: "MAHLAHLE PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  PAENG VILLAGE  SEKHUKHUNE",
    Latitude: "-24.6412",
    Longitude: "30.5647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17017",
    WardLookupId: "4060",
    VDNumber: "76380868",
    RegisteredPopulation: "298",
    VotingStationName: "MATSHAILE SENIOR SECONDARY SCHOOL",
    Address: "RIETFONTEIN, OHRIGSTAD  SEKHUKHUNE  SEKHUKHUNE",
    Latitude: "-24.6309",
    Longitude: "30.5546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17018",
    WardLookupId: "4060",
    VDNumber: "76380891",
    RegisteredPopulation: "85",
    VotingStationName: "MAKGOPA PRIMARY SCHOOL",
    Address:
      "KGAUTSWANE VILLAGE  FETAKGOMO TUBATSE MUNICIPALITY   KGOETE TRIBAL",
    Latitude: "-24.5634",
    Longitude: "30.5424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17019",
    WardLookupId: "4061",
    VDNumber: "76380420",
    RegisteredPopulation: "1695",
    VotingStationName: "BATAU HIGH SCHOOL",
    Address: "NO STREET ADRESS  MASHAMUTHANE VILLAGE  SEKHUKHUNE",
    Latitude: "-24.6475",
    Longitude: "30.2699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17020",
    WardLookupId: "4061",
    VDNumber: "76380453",
    RegisteredPopulation: "1027",
    VotingStationName: "MOHLARUTSE HIGH SCHOOL",
    Address: "NO STREET ADRESS  GA-MADISENG VILLAGE  SEKHUKHUNE",
    Latitude: "-24.6296",
    Longitude: "30.2353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17021",
    WardLookupId: "4061",
    VDNumber: "76380521",
    RegisteredPopulation: "455",
    VotingStationName: "KABISHI PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  GA MASHAMTHANE VILLAGE  SEKHUKHUNE",
    Latitude: "-24.6421",
    Longitude: "30.2768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17022",
    WardLookupId: "4061",
    VDNumber: "76380699",
    RegisteredPopulation: "562",
    VotingStationName: "MARESELENG S/GROUND (TENT)",
    Address: "MARESELENG   FETAKGOMOTUBATSE  MASHAMOTHANE MOSHATE SECTION",
    Latitude: "-24.6584",
    Longitude: "30.2764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17023",
    WardLookupId: "4061",
    VDNumber: "76380701",
    RegisteredPopulation: "471",
    VotingStationName: "GA-MABELANE (TENT)",
    Address: "BOTHASHOEK  BURGERSFORT  BOTHASHOEK",
    Latitude: "-24.6358",
    Longitude: "30.2943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17024",
    WardLookupId: "4061",
    VDNumber: "76380778",
    RegisteredPopulation: "728",
    VotingStationName: "MASHAMUTHANE ZONE 8 (TENT)",
    Address: "GA-MASHAMUTHANE  SEKHUKHUNE  GA-MASHAMUTHANE",
    Latitude: "-24.6351",
    Longitude: "30.2527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17025",
    WardLookupId: "4061",
    VDNumber: "76380789",
    RegisteredPopulation: "363",
    VotingStationName: "WEM PRIVATE SCHOOL",
    Address: "GA-MASHAMUTHANE  SEKHUKHUNE  GA-MASHAMUTHANE",
    Latitude: "-24.6515",
    Longitude: "30.2868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17026",
    WardLookupId: "4061",
    VDNumber: "76380790",
    RegisteredPopulation: "474",
    VotingStationName: "MOSEBO PRIMARY SCHOOL",
    Address: "GA-MASHAMOTHANE  SEKHUKHUNE  GA-MASHAMOTHANE",
    Latitude: "-24.6421",
    Longitude: "30.2519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17027",
    WardLookupId: "4061",
    VDNumber: "76380813",
    RegisteredPopulation: "287",
    VotingStationName: "AFM CHURCH OF SA",
    Address: "MASHIFANE PARK  SEKHUKHUNE  GA-MASHAMUTHANE",
    Latitude: "-24.6553",
    Longitude: "30.2847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17028",
    WardLookupId: "4062",
    VDNumber: "76380127",
    RegisteredPopulation: "567",
    VotingStationName: "MORABA PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  GA-MORABA  SEKHUKHUNE",
    Latitude: "-24.4687",
    Longitude: "30.6436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17029",
    WardLookupId: "4062",
    VDNumber: "76380161",
    RegisteredPopulation: "402",
    VotingStationName: "SEKIBIDI PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  TSWENYANE VILLAGE  SEKHUKHUNE",
    Latitude: "-24.4765",
    Longitude: "30.608",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17030",
    WardLookupId: "4062",
    VDNumber: "76380251",
    RegisteredPopulation: "957",
    VotingStationName: "LEBOENG PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  LEBOENG VILLAGE  SEKHUKHUNE",
    Latitude: "-24.4902",
    Longitude: "30.6649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17031",
    WardLookupId: "4062",
    VDNumber: "76380341",
    RegisteredPopulation: "610",
    VotingStationName: "THOROMETSANE PRIMARY SCHOOL",
    Address: "PHIRING   FETAKGOMOTUBATSE  GA KGOSHI DINKWANYANE",
    Latitude: "-24.5278",
    Longitude: "30.6984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17032",
    WardLookupId: "4062",
    VDNumber: "76380486",
    RegisteredPopulation: "481",
    VotingStationName: "BANARENG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA SEPEKE/BANARENG VILLAGE  SEKHUKHUNE",
    Latitude: "-24.4852",
    Longitude: "30.6307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17033",
    WardLookupId: "4062",
    VDNumber: "76380509",
    RegisteredPopulation: "321",
    VotingStationName: "TSWENYANE PRIMARY SCHOOL",
    Address: "LEPELLE  TSWENYANE VILLAGE  TSWENYANE",
    Latitude: "-24.4453",
    Longitude: "30.6077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17034",
    WardLookupId: "4062",
    VDNumber: "76380565",
    RegisteredPopulation: "692",
    VotingStationName: "SN NKOANA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  GA-NKOANA   SEKHUKHUNE",
    Latitude: "-24.4719",
    Longitude: "30.6714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17035",
    WardLookupId: "4062",
    VDNumber: "76380824",
    RegisteredPopulation: "474",
    VotingStationName: "PITSANENG PRIMARY SCHOOL",
    Address: "GA-MORABA  SEKHUKHUNE  GA-MORABA",
    Latitude: "-24.4637",
    Longitude: "30.6348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17036",
    WardLookupId: "4062",
    VDNumber: "76380903",
    RegisteredPopulation: "96",
    VotingStationName: "MALEMENG CRECHE",
    Address: "MALEMENG  FETAKGOMO-TUBATSE  KOBENG TRIBAL AUTHORITY",
    Latitude: "-24.4377",
    Longitude: "30.6233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17037",
    WardLookupId: "4063",
    VDNumber: "54820043",
    RegisteredPopulation: "633",
    VotingStationName: "MAHLAGARE PRIMARY SCHOOL",
    Address: "NO SREET ADDRESS AVAILABLE  DITHAMAGA  SEKHUKHUNE ",
    Latitude: "-24.8918",
    Longitude: "30.0671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17038",
    WardLookupId: "4063",
    VDNumber: "54820054",
    RegisteredPopulation: "223",
    VotingStationName: "DITHAMAGA PRIMARY SCHOOL",
    Address: "NO SREET ADDRESS AVAILABLE  MASHAGOSEBO  SEKHUKHUNE  ",
    Latitude: "-24.8371",
    Longitude: "30.1583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17039",
    WardLookupId: "4063",
    VDNumber: "54860025",
    RegisteredPopulation: "803",
    VotingStationName: "MADIBENG PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  HLALANIKAHLE VILLAGE  SEKHUKHUNE",
    Latitude: "-24.9213",
    Longitude: "29.9809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17040",
    WardLookupId: "4063",
    VDNumber: "54860250",
    RegisteredPopulation: "717",
    VotingStationName: "MAREMELE PRIMARY SCHOOL",
    Address: "HLALANIGAHLE  BURGERSFORT  HLALANIGAHLE",
    Latitude: "-24.9094",
    Longitude: "29.997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17041",
    WardLookupId: "4063",
    VDNumber: "76390140",
    RegisteredPopulation: "819",
    VotingStationName: "BAHLAKWANA BA-MALEKANA TRADITIONAL COUNCIL",
    Address: "NO STREET ADRESS  STEELPOORT  SEKHUKHUNE",
    Latitude: "-24.8911",
    Longitude: "30.0087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17042",
    WardLookupId: "4063",
    VDNumber: "76390173",
    RegisteredPopulation: "426",
    VotingStationName: "PAPONG PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  STEELPOORT  SEKHUKHUNE",
    Latitude: "-24.8809",
    Longitude: "30.0288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17043",
    WardLookupId: "4063",
    VDNumber: "76390195",
    RegisteredPopulation: "800",
    VotingStationName: "NGWANA THULARE PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  STEELPOORT  SEKHUKHUNE",
    Latitude: "-24.8788",
    Longitude: "29.9956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17044",
    WardLookupId: "4063",
    VDNumber: "76390252",
    RegisteredPopulation: "312",
    VotingStationName: "GOBETSE HIGH SCHOOL",
    Address: "GA MALEKANA  SEKHUKHUNE  MALEKANA",
    Latitude: "-24.8896",
    Longitude: "30.0149",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17045",
    WardLookupId: "4064",
    VDNumber: "76390151",
    RegisteredPopulation: "820",
    VotingStationName: "TIBA MOSHITO PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  STEELPOORT  SEKHUKHUNE",
    Latitude: "-24.865",
    Longitude: "29.9669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17046",
    WardLookupId: "4064",
    VDNumber: "76390162",
    RegisteredPopulation: "1051",
    VotingStationName: "NGWANANGWATO HIGH SCHOOL",
    Address: "NO STREET ADRESS  GA-NGWAABE  SEKHUKHUNE",
    Latitude: "-24.8747",
    Longitude: "29.9675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17047",
    WardLookupId: "4064",
    VDNumber: "76390184",
    RegisteredPopulation: "1234",
    VotingStationName: "NGWAABE HIGH SCHOOL",
    Address: "NO STREET ADRESS  STEELPOORT  SEKHUKHUNE",
    Latitude: "-24.8749",
    Longitude: "29.9795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17048",
    WardLookupId: "4064",
    VDNumber: "76390218",
    RegisteredPopulation: "1643",
    VotingStationName: "MASHA PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  STEELPOORT  SEKHUKHUNE",
    Latitude: "-24.884",
    Longitude: "29.9907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17049",
    WardLookupId: "4064",
    VDNumber: "76390263",
    RegisteredPopulation: "402",
    VotingStationName: "MASAGO PRIMARY SCHOOL",
    Address: "GA-MASHA  SEKHUKHUNE  GA MASHA",
    Latitude: "-24.886",
    Longitude: "29.9756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17050",
    WardLookupId: "4065",
    VDNumber: "76390027",
    RegisteredPopulation: "741",
    VotingStationName: "NTAKE PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  STEELPOORT  SEKHUKHUNE",
    Latitude: "-24.8476",
    Longitude: "29.9594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17051",
    WardLookupId: "4065",
    VDNumber: "76390106",
    RegisteredPopulation: "1086",
    VotingStationName: "DIKGAGENG PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  STEELPOORT  SEKHUKHUNE",
    Latitude: "-24.872",
    Longitude: "29.9287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17052",
    WardLookupId: "4065",
    VDNumber: "76390128",
    RegisteredPopulation: "723",
    VotingStationName: "MADIETE PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  STEELPOORT  SEKHUKHUNE",
    Latitude: "-24.8649",
    Longitude: "29.9478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17053",
    WardLookupId: "4065",
    VDNumber: "76390139",
    RegisteredPopulation: "1135",
    VotingStationName: "MAPHOKO PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  MASEVEN  SEKHUKHUNE",
    Latitude: "-24.9181",
    Longitude: "29.9553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17054",
    WardLookupId: "4065",
    VDNumber: "76390207",
    RegisteredPopulation: "1091",
    VotingStationName: "BAHLAKWANA BAMAPHOPHA TRADITIONAL AUTHORITY",
    Address: "NO STREET ADRESS  GA-MAPHOPHA  SEKHUKHUNE",
    Latitude: "-24.8531",
    Longitude: "29.9624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17055",
    WardLookupId: "4066",
    VDNumber: "76380082",
    RegisteredPopulation: "937",
    VotingStationName: "MOKOBOLA SCHOOL",
    Address: "NO STREET ADRESS  MOKOBOLA  SEKHUKHUNE",
    Latitude: "-24.5064",
    Longitude: "30.2781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17056",
    WardLookupId: "4066",
    VDNumber: "76380273",
    RegisteredPopulation: "1079",
    VotingStationName: "MABOCHA PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  MABOTSHA  SEKHUKHUNE",
    Latitude: "-24.5257",
    Longitude: "30.288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17057",
    WardLookupId: "4066",
    VDNumber: "76380554",
    RegisteredPopulation: "1626",
    VotingStationName: "KOBOTI PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  PRAKTISEER  SEKHUKHUNE",
    Latitude: "-24.5725",
    Longitude: "30.3104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17058",
    WardLookupId: "4066",
    VDNumber: "76380587",
    RegisteredPopulation: "1435",
    VotingStationName: "SHUSHUMELA S/GROUND (TENT)",
    Address: "NO SREET ADDRESS AVAILABLE  GA-MOTODI  SEKHUKHUNE",
    Latitude: "-24.5756",
    Longitude: "30.3293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17059",
    WardLookupId: "4066",
    VDNumber: "76380723",
    RegisteredPopulation: "453",
    VotingStationName: "KWELEDI SECONDARY SCHOOL",
    Address: "PRAKTISEER TOWNSHIP  SEKHUKHUNE  PRAKTISEER TOWNSHIP",
    Latitude: "-24.5789",
    Longitude: "30.3165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17060",
    WardLookupId: "4066",
    VDNumber: "76380835",
    RegisteredPopulation: "569",
    VotingStationName: "THABANE PRIMARY SCHOOL",
    Address: "MABOCHA  SEKHUKHUNE  MABOCHA",
    Latitude: "-24.5414",
    Longitude: "30.2923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17061",
    WardLookupId: "4066",
    VDNumber: "76380846",
    RegisteredPopulation: "284",
    VotingStationName: "SEHLOI PRIMARY SCHOOL",
    Address: "MABOCHA  SEKHUKHUNE  MABOCHA",
    Latitude: "-24.5163",
    Longitude: "30.291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17062",
    WardLookupId: "4066",
    VDNumber: "76380879",
    RegisteredPopulation: "314",
    VotingStationName: "(TENT) SHUSHUMELA SPORTS GROUND",
    Address: "TUBATSE MUNICIPALITY  SEKHUKHUNE  SEKHUKHUNE",
    Latitude: "-24.5616",
    Longitude: "30.327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17063",
    WardLookupId: "4067",
    VDNumber: "54820010",
    RegisteredPopulation: "1100",
    VotingStationName: "LAERSKOOL STEELPOORT",
    Address: "PAD P169/2  PLAAS GOUDMYN-STEELPOORT  SEKHUKHUNE",
    Latitude: "-24.726",
    Longitude: "30.2035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17064",
    WardLookupId: "4067",
    VDNumber: "54860058",
    RegisteredPopulation: "178",
    VotingStationName: "FRAAIUITZIGHT FARM STAY",
    Address: "VUKANINI FARM  GREATER TUBATSE  BURGERSFORT",
    Latitude: "-24.7832",
    Longitude: "30.3793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17065",
    WardLookupId: "4067",
    VDNumber: "54860081",
    RegisteredPopulation: "251",
    VotingStationName: "BOERBOOMKRAAL SCHOOL",
    Address: "NO STREET ADRESS  OHRIGSTAD  SEKHUKHUNE",
    Latitude: "-24.8213",
    Longitude: "30.3492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17066",
    WardLookupId: "4067",
    VDNumber: "54860115",
    RegisteredPopulation: "944",
    VotingStationName: "MANGABANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  TCL-STEELPOORT  SEKHUKHUNE",
    Latitude: "-24.6595",
    Longitude: "30.19",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17067",
    WardLookupId: "4067",
    VDNumber: "54860160",
    RegisteredPopulation: "1361",
    VotingStationName: "MARAKABELA  PRIMARY SCHOOL",
    Address: "NO STREET ADRESS  DRESDEN  SEKHUKHUNE",
    Latitude: "-24.6395",
    Longitude: "30.3711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17068",
    WardLookupId: "4067",
    VDNumber: "54860249",
    RegisteredPopulation: "558",
    VotingStationName: "MAGAKANTSHE PRIMARY SCHOOL",
    Address: "RIBA-CROSS MAKGEMENG  BURGERSFORT  RIBA-CROSS-MAKGEMENG",
    Latitude: "-24.6366",
    Longitude: "30.1842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17069",
    WardLookupId: "4067",
    VDNumber: "54860306",
    RegisteredPopulation: "186",
    VotingStationName: "KOPI PRIMARY SCHOOL",
    Address: "GA-KOPI  SEKHUKHUNE  GA-KOPI",
    Latitude: "-24.8404",
    Longitude: "30.2683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17070",
    WardLookupId: "4068",
    VDNumber: "76360158",
    RegisteredPopulation: "542",
    VotingStationName: "MOGALE PRIMARY SCHOOL",
    Address: "NO STREET NAME  MOOLYK  SEKHUKHUNE",
    Latitude: "-24.264",
    Longitude: "29.9552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17071",
    WardLookupId: "4068",
    VDNumber: "76360170",
    RegisteredPopulation: "1062",
    VotingStationName: "MORWASWI SECONDARY SCHOOL",
    Address: "NO STREET NAME  TJIBENG  SEKHUKHUNE",
    Latitude: "-24.288",
    Longitude: "29.9459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17072",
    WardLookupId: "4068",
    VDNumber: "76360181",
    RegisteredPopulation: "809",
    VotingStationName: "NYAKU SECONDARY SCHOOL",
    Address: "NO STREET NAME  GANKWANA  PIETERSBURG",
    Latitude: "-24.2439",
    Longitude: "29.9594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17073",
    WardLookupId: "4068",
    VDNumber: "76360259",
    RegisteredPopulation: "1124",
    VotingStationName: "SELATOLE HIGH SCHOOL",
    Address: "NO STREET NAME  MAKGALANOTO  PIETERSBURG",
    Latitude: "-24.3298",
    Longitude: "30.0033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17074",
    WardLookupId: "4068",
    VDNumber: "76360260",
    RegisteredPopulation: "605",
    VotingStationName: "MALEGASE PRIMARY SCHOOL",
    Address: "NO STREET NAME  GAMOHLALA  PIETERSBURG",
    Latitude: "-24.3547",
    Longitude: "30.0107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17075",
    WardLookupId: "4068",
    VDNumber: "76360271",
    RegisteredPopulation: "776",
    VotingStationName: "KGAGUDI SCHOOL",
    Address: "SEOKODIBENG  PRAKTISEER",
    Latitude: "-24.3398",
    Longitude: "30.0528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17076",
    WardLookupId: "4068",
    VDNumber: "76360664",
    RegisteredPopulation: "549",
    VotingStationName: "TSWERENG PRIMARY SCHOOL",
    Address: "NO STREET NAME  LEDINGWE   VILLAGE  SEKHUKHUNE",
    Latitude: "-24.2873",
    Longitude: "30.0233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17077",
    WardLookupId: "4068",
    VDNumber: "76360967",
    RegisteredPopulation: "148",
    VotingStationName: "SEKGWENG CRECHE",
    Address: "SHUBUSHUBUNG  FETAKGOMO  NKOANA",
    Latitude: "-24.234",
    Longitude: "29.8869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17078",
    WardLookupId: "4068",
    VDNumber: "76360990",
    RegisteredPopulation: "307",
    VotingStationName: "MANKU PRIMARY SCHOOL",
    Address: "DILOKONG, GAPHASHA  SEKHUKHUNE  SEKHUKHUNE",
    Latitude: "-24.3322",
    Longitude: "30.0301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17079",
    WardLookupId: "4068",
    VDNumber: "76361003",
    RegisteredPopulation: "262",
    VotingStationName: "MAKGALANOTO PRIMARY SCHOOL",
    Address: "PHASHA MAKGALANOTO VILLAGE  SEKHUKHUNE  PHASHA MAKGALANOTO",
    Latitude: "-24.3426",
    Longitude: "30.0008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17080",
    WardLookupId: "4069",
    VDNumber: "76200331",
    RegisteredPopulation: "907",
    VotingStationName: "GANGAZA HIGH SCHOOL",
    Address: "NO STREET ADDRESS  SWAZI MONYAMANE  SEKHUKHUNE",
    Latitude: "-24.4073",
    Longitude: "29.9953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17081",
    WardLookupId: "4069",
    VDNumber: "76360215",
    RegisteredPopulation: "747",
    VotingStationName: "SELEPE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  SELEPE  SEKHUKHUNE",
    Latitude: "-24.3481",
    Longitude: "29.9236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17082",
    WardLookupId: "4069",
    VDNumber: "76360226",
    RegisteredPopulation: "1268",
    VotingStationName: "LEFAKGOMO SECONDARY SCHOOL",
    Address: "NO STREET ADRESS  SELEPE  SEKHUKHUNE",
    Latitude: "-24.3504",
    Longitude: "29.9342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17083",
    WardLookupId: "4069",
    VDNumber: "76360237",
    RegisteredPopulation: "753",
    VotingStationName: "MANOTOANE PRIMARY SCHOOL",
    Address: "GA-MANOTOANE  SEKHUKHUNE  MANOTOANE",
    Latitude: "-24.3574",
    Longitude: "29.9487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17084",
    WardLookupId: "4069",
    VDNumber: "76360248",
    RegisteredPopulation: "635",
    VotingStationName: "RAMOKO PRIMARY SCHOOL",
    Address: "NO STREET NAME  PHASHASKRAAL  SEKHUKHUNE",
    Latitude: "-24.3786",
    Longitude: "29.9799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17085",
    WardLookupId: "4069",
    VDNumber: "76360721",
    RegisteredPopulation: "629",
    VotingStationName: "SEJADIPUDI PRIMARY SCHOOL",
    Address: "NO STREET NAME  GA-SELEPE  SEKHUKHUNE",
    Latitude: "-24.3362",
    Longitude: "29.8981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17086",
    WardLookupId: "4069",
    VDNumber: "76360798",
    RegisteredPopulation: "309",
    VotingStationName: "MOTSATSANA PRIMARY SCHOOL",
    Address:
      "NO STREET ADDRESS AVAILABLE  ATOK MOSOTSE  POLOKWANE / SEKHUKHUNE",
    Latitude: "-24.3547",
    Longitude: "29.9806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17087",
    WardLookupId: "4070",
    VDNumber: "76360147",
    RegisteredPopulation: "1501",
    VotingStationName: "SEROKOLO HIGH SCHOOL",
    Address: "5002 BOGALATLADI,ATOK,0749  FETAKGOMO TUBATSE  BAROKA NKOANA",
    Latitude: "-24.2604",
    Longitude: "29.8569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17088",
    WardLookupId: "4070",
    VDNumber: "76360169",
    RegisteredPopulation: "791",
    VotingStationName: "MONAMETSE PRIMARY SCHOOL",
    Address: "NO STREET NAME  GANKWANA MONAMETSE  SEKHUKHUNE",
    Latitude: "-24.3087",
    Longitude: "29.9182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17089",
    WardLookupId: "4070",
    VDNumber: "76360620",
    RegisteredPopulation: "727",
    VotingStationName: "MAFENE PRIMARY SCHOOL",
    Address: "NO STREET NAME  GANKWANA  SEKHUKHUNE",
    Latitude: "-24.3069",
    Longitude: "29.8316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17090",
    WardLookupId: "4070",
    VDNumber: "76360697",
    RegisteredPopulation: "794",
    VotingStationName: "POTLAKE SECONDARY SCHOOL",
    Address: "NO STREET NAME  ATOK  SEKHUKHUNE",
    Latitude: "-24.2848",
    Longitude: "29.859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17091",
    WardLookupId: "4070",
    VDNumber: "76360710",
    RegisteredPopulation: "491",
    VotingStationName: "MAHUDU PRIMARY SCHOOL",
    Address: "NO STREET NAME  MARUPING  SEKGUKGUNE",
    Latitude: "-24.3102",
    Longitude: "29.8636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17092",
    WardLookupId: "4070",
    VDNumber: "76360855",
    RegisteredPopulation: "389",
    VotingStationName: "MAFISE PRIMARY SCHOOL",
    Address: "ATOK VILLAGE  FETAKGOMO  FETAKGOMO",
    Latitude: "-24.2592",
    Longitude: "29.8272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17093",
    WardLookupId: "4070",
    VDNumber: "76360866",
    RegisteredPopulation: "444",
    VotingStationName: "BOGALATLADI PRIMARY SCHOOL",
    Address: "ATOK VILLAGE  FETAKGOMO  FETAKGOMO",
    Latitude: "-24.2619",
    Longitude: "29.8715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17094",
    WardLookupId: "4070",
    VDNumber: "76360877",
    RegisteredPopulation: "180",
    VotingStationName: "MABOTSANENG CRECHE",
    Address: "10047 MASHIKWE VILLAGE  FETAKGOMO  FETAKGOMO",
    Latitude: "-24.2648",
    Longitude: "29.8973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17095",
    WardLookupId: "4070",
    VDNumber: "76360956",
    RegisteredPopulation: "236",
    VotingStationName: "MPHANENG PRIMARY SCHOOL",
    Address: "MPHANENG VILLAGE  FETAKGOMO  NKOANA",
    Latitude: "-24.2949",
    Longitude: "29.7668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17096",
    WardLookupId: "4070",
    VDNumber: "76361014",
    RegisteredPopulation: "296",
    VotingStationName: "DITHOLONG PRE SCHOOL",
    Address: "GA-MOKGOTHO, ATOK  SEKHUKHUNE  SEKHUKHUNE",
    Latitude: "-24.3176",
    Longitude: "29.9371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17097",
    WardLookupId: "4071",
    VDNumber: "76360192",
    RegisteredPopulation: "689",
    VotingStationName: "MODIMOLLE PRIMARY SCHOOL",
    Address: "NO STREET NAME  GANKWANA  SEKHUKHUNE",
    Latitude: "-24.3174",
    Longitude: "29.7698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17098",
    WardLookupId: "4071",
    VDNumber: "76360204",
    RegisteredPopulation: "588",
    VotingStationName: "INDIE PRIMARY SCHOOL",
    Address: "INDIE  FETAKGOMO  GA - MAISELA",
    Latitude: "-24.3768",
    Longitude: "29.8227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17099",
    WardLookupId: "4071",
    VDNumber: "76360282",
    RegisteredPopulation: "545",
    VotingStationName: "MAPHOTLE PRIMARY SCHOOL",
    Address: "NO STREET NAME  GA-NKWANA  SEKHUKHUNE",
    Latitude: "-24.412",
    Longitude: "29.7714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17100",
    WardLookupId: "4071",
    VDNumber: "76360293",
    RegisteredPopulation: "867",
    VotingStationName: "TAUMANKOTSANE COMMUNITY HALL",
    Address: "MOSHATE  APEL  SEKHUKHUNE",
    Latitude: "-24.4073",
    Longitude: "29.7609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17101",
    WardLookupId: "4071",
    VDNumber: "76360305",
    RegisteredPopulation: "883",
    VotingStationName: "NKWANA PRIMARY SCHOOL.",
    Address: "GANKWANA  SEKHUKHUNE  BAROKA BA NKWANA",
    Latitude: "-24.4055",
    Longitude: "29.7898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17102",
    WardLookupId: "4071",
    VDNumber: "76360608",
    RegisteredPopulation: "594",
    VotingStationName: "PELANGWE COMMUNITY HALL",
    Address: "FETAKGOMO PELANGWE  PIETERSBURG",
    Latitude: "-24.3556",
    Longitude: "29.7762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17103",
    WardLookupId: "4071",
    VDNumber: "76360653",
    RegisteredPopulation: "392",
    VotingStationName: "MAISELA MAHLABAPHOOKO",
    Address: "01 MOSHATE  GA-MAISELA MAHLABAPHOOKO  SEKHUKHUNE",
    Latitude: "-24.4119",
    Longitude: "29.808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17104",
    WardLookupId: "4072",
    VDNumber: "76360316",
    RegisteredPopulation: "943",
    VotingStationName: "MADITHAME SENIOR SECONDARY SCHOOL",
    Address: "NO STREET NAME  NCHABELENG  FETAKGOMO",
    Latitude: "-24.4228",
    Longitude: "29.7516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17105",
    WardLookupId: "4072",
    VDNumber: "76360327",
    RegisteredPopulation: "718",
    VotingStationName: "MASHA MAKOPOLE TRIBAL",
    Address: "STYDKRAAL A  GA-MASHA  SEKHUKHUNE",
    Latitude: "-24.4543",
    Longitude: "29.7448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17106",
    WardLookupId: "4072",
    VDNumber: "76360361",
    RegisteredPopulation: "1871",
    VotingStationName: "NGWANAMALA SECONDARY SCHOOL",
    Address: "GA NCHABELENG  TAU NCHABELENG",
    Latitude: "-24.4299",
    Longitude: "29.8109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17107",
    WardLookupId: "4072",
    VDNumber: "76360372",
    RegisteredPopulation: "677",
    VotingStationName: "TAU NCHABELENG TRADITIONAL COUNCIL",
    Address: "FETAKGOMO MATADI  GANCHABELENG  PIETERSBURG",
    Latitude: "-24.4342",
    Longitude: "29.8262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17108",
    WardLookupId: "4072",
    VDNumber: "76360642",
    RegisteredPopulation: "994",
    VotingStationName: "FRANK MASHILE SECONDARY SCHOOL",
    Address: "NO STREET NAME  MASHUNG GA-NKWANA  SEKHUKHUNE",
    Latitude: "-24.4229",
    Longitude: "29.7738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17109",
    WardLookupId: "4072",
    VDNumber: "76360787",
    RegisteredPopulation: "548",
    VotingStationName: "TLAKALE PRIMARY SCHOOL",
    Address: "MASHUNG  FETAKGOMO  POLOKWANE/ SEKHUKHUNE",
    Latitude: "-24.4264",
    Longitude: "29.7936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17110",
    WardLookupId: "4072",
    VDNumber: "76361025",
    RegisteredPopulation: "396",
    VotingStationName: "MOENYANE PRIMARY SCHOOL",
    Address: "MOOIPLUS VILLAGE  MOOIPLUS  TUBATSE",
    Latitude: "-24.4406",
    Longitude: "29.7439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17111",
    WardLookupId: "4073",
    VDNumber: "76360338",
    RegisteredPopulation: "666",
    VotingStationName: "STRYDKRAAL COMMUNITY HALL",
    Address: "STRYDKRAAL VILLAGE  APEL  MASHA MAKOPOLE",
    Latitude: "-24.4744",
    Longitude: "29.7125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17112",
    WardLookupId: "4073",
    VDNumber: "76360349",
    RegisteredPopulation: "477",
    VotingStationName: "THOBEHLALE PRIMARY SCHOOL",
    Address: "NO STREET NAME  GA-MATLALA  SEKHUKHUNE",
    Latitude: "-24.5046",
    Longitude: "29.7723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17113",
    WardLookupId: "4073",
    VDNumber: "76360518",
    RegisteredPopulation: "1032",
    VotingStationName: "MABOE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MPHANAMA  VILLAGE  SEKHUKHUNE",
    Latitude: "-24.5956",
    Longitude: "29.7926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17114",
    WardLookupId: "4073",
    VDNumber: "76360529",
    RegisteredPopulation: "1224",
    VotingStationName: "MPHANAMA PRIMARY SCHOOL",
    Address: "NO STREET NAME  MPHANAMA  PIETERSBURG",
    Latitude: "-24.5863",
    Longitude: "29.8151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17115",
    WardLookupId: "4073",
    VDNumber: "76360530",
    RegisteredPopulation: "1231",
    VotingStationName: "DITHOTWANENG SECONDARY SCHOOL",
    Address: "NO STREET NAME  MPHANAMA  PIETERSBURG",
    Latitude: "-24.5877",
    Longitude: "29.8329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17116",
    WardLookupId: "4073",
    VDNumber: "76360888",
    RegisteredPopulation: "787",
    VotingStationName: "ROKA RADINGWANA TRADITIONAL COUNCIL",
    Address: "GARADINGWANA  FETAKGOMO  FETAKGOMO",
    Latitude: "-24.5576",
    Longitude: "29.8547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17117",
    WardLookupId: "4073",
    VDNumber: "76360945",
    RegisteredPopulation: "233",
    VotingStationName: "MAKELEPENG SECONDARY SCHOOL",
    Address: "SEPAKAPAKENG  FETAKGOMO  MPHANAMA",
    Latitude: "-24.588",
    Longitude: "29.7725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17118",
    WardLookupId: "4074",
    VDNumber: "76360473",
    RegisteredPopulation: "996",
    VotingStationName: "SEROKA TRIBAL HALL",
    Address: "FETAKGOMO MOSHATE  MOHLALETSE  SEKHUKHUNE",
    Latitude: "-24.5089",
    Longitude: "29.9328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17119",
    WardLookupId: "4074",
    VDNumber: "76360484",
    RegisteredPopulation: "522",
    VotingStationName: "MOLETJE PRIMARY SCHOOL",
    Address: "NO STREET NAME  GAPHAAHLA  SEKHUKHUNE",
    Latitude: "-24.5524",
    Longitude: "29.9454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17120",
    WardLookupId: "4074",
    VDNumber: "76360495",
    RegisteredPopulation: "768",
    VotingStationName: "MASHILABELE PRIMARY SCHOOL",
    Address: "NO STREET NAME  GA ORIA  SEKHUKHUNE",
    Latitude: "-24.5765",
    Longitude: "29.9093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17121",
    WardLookupId: "4074",
    VDNumber: "76360541",
    RegisteredPopulation: "1203",
    VotingStationName: "RADIMMELA PRIMARY SCHOOL",
    Address: "NO STREET NAME  GARADINGWANA  SEKHUKHUNE",
    Latitude: "-24.5692",
    Longitude: "29.8579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17122",
    WardLookupId: "4074",
    VDNumber: "76360765",
    RegisteredPopulation: "289",
    VotingStationName: "MOKHINE HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  GA PHAAHLA   SEKHUKHUNE",
    Latitude: "-24.5686",
    Longitude: "29.9344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17123",
    WardLookupId: "4074",
    VDNumber: "76360776",
    RegisteredPopulation: "324",
    VotingStationName: "MANKOPODI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  GA-SEROKA  SEKHUKHUNE",
    Latitude: "-24.5431",
    Longitude: "29.9234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17124",
    WardLookupId: "4074",
    VDNumber: "76360899",
    RegisteredPopulation: "260",
    VotingStationName: "MMOTONG PRIMARY SCHOOL",
    Address:
      "1007 PHAGENG ,GA RADINGWANA  FETAKGOMO TUBATSE  MOHLALA TRADITIONAL AUTHORITY",
    Latitude: "-24.5795",
    Longitude: "29.8886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17125",
    WardLookupId: "4074",
    VDNumber: "76360934",
    RegisteredPopulation: "349",
    VotingStationName: "MASEHLENG PRIMARY SCHOOL",
    Address: "MASEHLENG VILLAGE  FETAKGOMO  PHAHLAMANOGE",
    Latitude: "-24.5583",
    Longitude: "29.9537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17126",
    WardLookupId: "4074",
    VDNumber: "76361047",
    RegisteredPopulation: "252",
    VotingStationName: "MASHILABELE SERVICE POINT (TENT)",
    Address: "MASHILABELE  FETAKGOMO  TAU-PHAHLAMOHLAKA",
    Latitude: "-24.5486",
    Longitude: "29.9073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17127",
    WardLookupId: "4075",
    VDNumber: "76360383",
    RegisteredPopulation: "654",
    VotingStationName: "HANS KOMANE SECONDARY SCHOOL",
    Address: "NO STREET NAME  GANCHABELENG  SEKHUKHUNE",
    Latitude: "-24.4438",
    Longitude: "29.8263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17128",
    WardLookupId: "4075",
    VDNumber: "76360394",
    RegisteredPopulation: "1524",
    VotingStationName: "MOKHULWANE HIGH SCHOOL",
    Address: "NO STREET NAME  GA-NCHABELENG  SEKHUKHUNE",
    Latitude: "-24.4454",
    Longitude: "29.84",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17129",
    WardLookupId: "4075",
    VDNumber: "76360406",
    RegisteredPopulation: "564",
    VotingStationName: "PHUTAKWE HIGH SCHOOL",
    Address: "NO STREET NAME  MOHLALETSE  SEKHUKHUNE",
    Latitude: "-24.4616",
    Longitude: "29.8615",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17130",
    WardLookupId: "4075",
    VDNumber: "76360417",
    RegisteredPopulation: "438",
    VotingStationName: "TSHWEELE PRIMARY SCHOOL",
    Address: "NO STREET NAME  MOHLALETSE  SEKHUKHUNE",
    Latitude: "-24.4565",
    Longitude: "29.8696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17131",
    WardLookupId: "4075",
    VDNumber: "76360428",
    RegisteredPopulation: "623",
    VotingStationName: "MOROAMOCHE PRIMARY SCHOOL",
    Address: "FETAKGOMO GAMATSIMELA  MOHLALETSE  SEKHUKHUNE",
    Latitude: "-24.4679",
    Longitude: "29.8724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17132",
    WardLookupId: "4075",
    VDNumber: "76360800",
    RegisteredPopulation: "617",
    VotingStationName: "LERAJANE PRIMARY SCHOOL",
    Address:
      "NO STREET ADDRESS AVAILABLE]  LEREJANE / MOHLALETSE  POLOKWANE  / SEKHUKHUNE",
    Latitude: "-24.4686",
    Longitude: "29.8572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17133",
    WardLookupId: "4075",
    VDNumber: "76360822",
    RegisteredPopulation: "297",
    VotingStationName: "MAESELA PRIMARY SCHOOL",
    Address: "GA MAESELA,MOHLALETSE  SEKHUKHUNE  SEKHUKHUNE TRIBAL",
    Latitude: "-24.4456",
    Longitude: "29.8631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17134",
    WardLookupId: "4126",
    VDNumber: "54220148",
    RegisteredPopulation: "1074",
    VotingStationName: "NDLELA TRADITIONAL COUNCIL OFFICES",
    Address: "E215C DIEPDALE  MPULUZI  MPULUZI",
    Latitude: "-26.4621",
    Longitude: "30.7983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17135",
    WardLookupId: "4126",
    VDNumber: "54220205",
    RegisteredPopulation: "531",
    VotingStationName: "SOKO PRIMARY SCHOOL",
    Address: "E607D DIEPDALE  MPULUZI  MPULUZI",
    Latitude: "-26.4477",
    Longitude: "30.7831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17136",
    WardLookupId: "4126",
    VDNumber: "54220216",
    RegisteredPopulation: "1001",
    VotingStationName: "METHULA HIGH SCHOOL",
    Address: "E356H DIEPDALE TRUST  MPULUZI  MPULUZI",
    Latitude: "-26.4295",
    Longitude: "30.7757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17137",
    WardLookupId: "4126",
    VDNumber: "54220306",
    RegisteredPopulation: "835",
    VotingStationName: "LILANGA HIGH SCHOOL",
    Address: "11001 DIEPDALE   MPULUZI  MPULUZI",
    Latitude: "-26.4574",
    Longitude: "30.7912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17138",
    WardLookupId: "4126",
    VDNumber: "54220373",
    RegisteredPopulation: "214",
    VotingStationName: "NORDEN PRIMARY SCHOOL",
    Address: "10002 NORDEN FARM  MPULUZI  MPULUZI",
    Latitude: "-26.4409",
    Longitude: "30.7142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17139",
    WardLookupId: "4126",
    VDNumber: "54410206",
    RegisteredPopulation: "510",
    VotingStationName: "ENKHANINI SECONDARY SCHOOL",
    Address: "1 SYDE TRUST  MPULUZI  MPULUZI",
    Latitude: "-26.4698",
    Longitude: "30.7481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17140",
    WardLookupId: "4127",
    VDNumber: "54220069",
    RegisteredPopulation: "1382",
    VotingStationName: "REV SA NKOSI HIGH SCHOOL",
    Address: "E584B FERNIE   MPULUZI  MPULUZI",
    Latitude: "-26.4122",
    Longitude: "30.77",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17141",
    WardLookupId: "4127",
    VDNumber: "54220137",
    RegisteredPopulation: "969",
    VotingStationName: "ZITHOBE PRIMARY SCHOOL",
    Address: "E1148 FERNIE B  MPULUZI  MPULUZI",
    Latitude: "-26.4124",
    Longitude: "30.7819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17142",
    WardLookupId: "4127",
    VDNumber: "54220283",
    RegisteredPopulation: "1240",
    VotingStationName: "LINDZALOKUHLE PRIMARY SCHOOL",
    Address: "E194 FERNIE B  MPULUZI  MPULUZI",
    Latitude: "-26.4008",
    Longitude: "30.7799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17143",
    WardLookupId: "4127",
    VDNumber: "54220294",
    RegisteredPopulation: "264",
    VotingStationName: "PHAMBILI SECONDARY SCHOOL",
    Address: "E496C FERNIE EXTENSION 5  MPULUZI  MPULUZI",
    Latitude: "-26.4277",
    Longitude: "30.7933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17144",
    WardLookupId: "4127",
    VDNumber: "54220317",
    RegisteredPopulation: "538",
    VotingStationName: "MABOMBE PRIMARY SCHOOL",
    Address: "E775B FERNIE  MPULUZI  MPULUZI",
    Latitude: "-26.4082",
    Longitude: "30.773",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17145",
    WardLookupId: "4128",
    VDNumber: "54220025",
    RegisteredPopulation: "1148",
    VotingStationName: "LOBHENGULA PRIMARY SCHOOL",
    Address: "E1238 FERNIE  A  MPULUZI  MPULUZI",
    Latitude: "-26.3969",
    Longitude: "30.7949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17146",
    WardLookupId: "4128",
    VDNumber: "54220036",
    RegisteredPopulation: "932",
    VotingStationName: "HLALANI PRIMARY SCHOOL",
    Address: "E216 FERNIE A  MPULUZI  MPULUZI",
    Latitude: "-26.3881",
    Longitude: "30.7916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17147",
    WardLookupId: "4128",
    VDNumber: "54220171",
    RegisteredPopulation: "671",
    VotingStationName: "KHUPHUKANI PRIMARY SCHOOL",
    Address: "E1874 FERNIE A  MPULUZI  MPULUZI",
    Latitude: "-26.4093",
    Longitude: "30.7974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17148",
    WardLookupId: "4128",
    VDNumber: "54220182",
    RegisteredPopulation: "270",
    VotingStationName: "NTOKOZO PRIMARY SCHOOL",
    Address: "NDONGA TRUST  MPULUZI  MPULUZI",
    Latitude: "-26.3676",
    Longitude: "30.7721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17149",
    WardLookupId: "4128",
    VDNumber: "54220328",
    RegisteredPopulation: "718",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "FERNIE A  MPULUZI  MPULUZI",
    Latitude: "-26.3948",
    Longitude: "30.7981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17150",
    WardLookupId: "4129",
    VDNumber: "54220014",
    RegisteredPopulation: "1325",
    VotingStationName: "ROMAN CATHOLIC CHURCH HALL",
    Address: "A319 MAYFLOWER  MPULUZI   MPULUZI",
    Latitude: "-26.299",
    Longitude: "30.7766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17151",
    WardLookupId: "4129",
    VDNumber: "54220249",
    RegisteredPopulation: "991",
    VotingStationName: "ZAMA ZAMA GENERAL DEALER",
    Address: "E825 GOBA SECTION, MAYFLOWER  MPULUZI  MPULUZI",
    Latitude: "-26.3105",
    Longitude: "30.7812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17152",
    WardLookupId: "4129",
    VDNumber: "54220261",
    RegisteredPopulation: "257",
    VotingStationName: "IHLOBANE PRIMARY SCHOOL",
    Address: "E133 NDONGA TRUST  MPULUZI  MPULUZI",
    Latitude: "-26.3408",
    Longitude: "30.7804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17153",
    WardLookupId: "4129",
    VDNumber: "54220339",
    RegisteredPopulation: "566",
    VotingStationName: "VULINDLELA DAY CARE CENTRE",
    Address: "E602 GOBA SECTION, MAYFLOWER  MPULUZI  MPULUZI",
    Latitude: "-26.3088",
    Longitude: "30.7815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17154",
    WardLookupId: "4129",
    VDNumber: "54220384",
    RegisteredPopulation: "525",
    VotingStationName: "SEBENTA SENIOR PHASE SCHOOL",
    Address: "A589 MAYFLOWER   MPULUZI  MPULUZI",
    Latitude: "-26.3022",
    Longitude: "30.7767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17155",
    WardLookupId: "4130",
    VDNumber: "54220058",
    RegisteredPopulation: "973",
    VotingStationName: "HLONIPHANI PRIMARY SCHOOL",
    Address: "E58 DUNDONALD NUMBER 2, SLOVO  MPULUZI  MPULUZI",
    Latitude: "-26.2169",
    Longitude: "30.8451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17156",
    WardLookupId: "4130",
    VDNumber: "54220126",
    RegisteredPopulation: "1177",
    VotingStationName: "EKUPHAKAMENI COMBINED SCHOOL",
    Address: "E522 DUNDONALD NUMBER 1  MPULUZI  MPULUZI",
    Latitude: "-26.2429",
    Longitude: "30.8262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17157",
    WardLookupId: "4130",
    VDNumber: "54220340",
    RegisteredPopulation: "568",
    VotingStationName: "LIGUGU HIGH SCHOOL",
    Address: "E08 DUNDONALD NUMBER 1  MPULUZI  MPULUZI",
    Latitude: "-26.2313",
    Longitude: "30.8292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17158",
    WardLookupId: "4130",
    VDNumber: "54220395",
    RegisteredPopulation: "686",
    VotingStationName: "ALPHEU ELECTRICAL, BUILDING AND CIVIL CONSTRUCTION",
    Address: "306 DUNDONALD  NUMBER 1  MPULUZI  MPULUZI",
    Latitude: "-26.245",
    Longitude: "30.8204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17159",
    WardLookupId: "4131",
    VDNumber: "54210169",
    RegisteredPopulation: "514",
    VotingStationName: "HOLEKA SECONDARY SCHOOL",
    Address: "1192 SITHOBELA REDHILL, OSHOEK ROAD  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.2111",
    Longitude: "30.9386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17160",
    WardLookupId: "4131",
    VDNumber: "54210327",
    RegisteredPopulation: "382",
    VotingStationName: "MADZANGA PRIMARY SCHOOL",
    Address: "116 HARTEBEESKOP  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.1834",
    Longitude: "30.923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17161",
    WardLookupId: "4131",
    VDNumber: "54210350",
    RegisteredPopulation: "306",
    VotingStationName: "CHURCH OF THE LORD JESUS CHRIST HALL",
    Address: "157 OSHOEK  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.2159",
    Longitude: "30.9792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17162",
    WardLookupId: "4131",
    VDNumber: "54220070",
    RegisteredPopulation: "811",
    VotingStationName: "LUSUSHWANA HIGH SCHOOL",
    Address: "E194 BETTYSGOED  DUNDONALD  DUNDONALD",
    Latitude: "-26.2577",
    Longitude: "30.9046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17163",
    WardLookupId: "4131",
    VDNumber: "54220159",
    RegisteredPopulation: "1299",
    VotingStationName: "MADLANGAMPISI TRIBAL OFFICES",
    Address: "E325  SWALLOWNEST, DUNDONALD  MPULUZI  MPULUZI",
    Latitude: "-26.2364",
    Longitude: "30.8792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17164",
    WardLookupId: "4131",
    VDNumber: "54220227",
    RegisteredPopulation: "565",
    VotingStationName: "EMFUMBENI TRADITIONAL COUNCIL OFFICES",
    Address: "E45 ROBINSDALE  DUNDONALD  DUNDONALD",
    Latitude: "-26.2673",
    Longitude: "30.8979",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17165",
    WardLookupId: "4132",
    VDNumber: "54220081",
    RegisteredPopulation: "912",
    VotingStationName: "MP MAGAGULA SECONDARY SCHOOL",
    Address: "E247 MAYFLOWER GATE  MPULUZI  MPULUZI",
    Latitude: "-26.2911",
    Longitude: "30.7316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17166",
    WardLookupId: "4132",
    VDNumber: "54220092",
    RegisteredPopulation: "1424",
    VotingStationName: "TISITENI SENIOR PHASE SCHOOL",
    Address: "283 MAYFLOWER  GERT SIBANDE",
    Latitude: "-26.2916",
    Longitude: "30.7665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17167",
    WardLookupId: "4132",
    VDNumber: "54220250",
    RegisteredPopulation: "393",
    VotingStationName: "KHANYA PRIMARY SCHOOL",
    Address: "E133 MAYFLOWER GATE  MPULUZI  MPULUZI",
    Latitude: "-26.3008",
    Longitude: "30.7344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17168",
    WardLookupId: "4132",
    VDNumber: "54220362",
    RegisteredPopulation: "589",
    VotingStationName: "KHULULEKA DAY CARE CENTRE",
    Address: "G69C MAYFLOWER EMANKANJINI  MPULUZI  MPULUZI",
    Latitude: "-26.2828",
    Longitude: "30.7683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17169",
    WardLookupId: "4132",
    VDNumber: "54220418",
    RegisteredPopulation: "400",
    VotingStationName: "MAYFLOWER HIGH SCHOOL",
    Address: "D281 MAYFLOWER  MPULUZI  MPULUZI",
    Latitude: "-26.2952",
    Longitude: "30.7757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17170",
    WardLookupId: "4133",
    VDNumber: "54210057",
    RegisteredPopulation: "1269",
    VotingStationName: "MLONDOZI PRIMARY SCHOOL",
    Address: "86 LITJELEMBUBE  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.1683",
    Longitude: "30.9018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17171",
    WardLookupId: "4133",
    VDNumber: "54210068",
    RegisteredPopulation: "804",
    VotingStationName: "NTABANHLE PRIMARY SCHOOL",
    Address: "2 OSHOEK  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.2074",
    Longitude: "30.9798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17172",
    WardLookupId: "4133",
    VDNumber: "54210170",
    RegisteredPopulation: "1035",
    VotingStationName: "MASAKHANE COMBINED SCHOOL",
    Address: "003300 BAMPOEN TRUST  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.151",
    Longitude: "30.8546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17173",
    WardLookupId: "4134",
    VDNumber: "54220047",
    RegisteredPopulation: "1402",
    VotingStationName: "PHUMULANI PRIMARY SCHOOL",
    Address: "E1167 DUNDONALD REDHILL  MPULUZI  MPULUZI",
    Latitude: "-26.2598",
    Longitude: "30.8274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17174",
    WardLookupId: "4134",
    VDNumber: "54220160",
    RegisteredPopulation: "721",
    VotingStationName: "PHUMELELE PRIMARY SCHOOL",
    Address: "E138 ESANDLENI  MPULUZI  MPULUZI",
    Latitude: "-26.2918",
    Longitude: "30.8348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17175",
    WardLookupId: "4134",
    VDNumber: "54220238",
    RegisteredPopulation: "407",
    VotingStationName: "NTABABOMVU PRIMARY SCHOOL",
    Address: "E23 NTABABOMVU  MPULUZI  MPULUZI",
    Latitude: "-26.2661",
    Longitude: "30.8052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17176",
    WardLookupId: "4134",
    VDNumber: "54220272",
    RegisteredPopulation: "351",
    VotingStationName: "BHEKOKUHLE PRIMARY SCHOOL",
    Address: "65 HEREFORD  MPULUZI  GERT SIBANDE",
    Latitude: "-26.2905",
    Longitude: "30.8015",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17177",
    WardLookupId: "4134",
    VDNumber: "54220351",
    RegisteredPopulation: "366",
    VotingStationName: "SILINDILE PRIMARY SCHOOL",
    Address: "1 WAVERLEY TRUST  MPULUZI  MPULUZI",
    Latitude: "-26.3356",
    Longitude: "30.8702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17178",
    WardLookupId: "4134",
    VDNumber: "54220407",
    RegisteredPopulation: "721",
    VotingStationName: "SITANANI PRIMARY SCHOOL",
    Address: "C376 SUNCITY, MAYFLOWER  MPULUZI  MPULUZI",
    Latitude: "-26.2853",
    Longitude: "30.778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17179",
    WardLookupId: "4134",
    VDNumber: "54220441",
    RegisteredPopulation: "163",
    VotingStationName: "LANDULWAZI PRIMARY SCHOOL",
    Address: "E171 ENGODINI TRUST  MPULUZI  MPULUZI",
    Latitude: "-26.2509",
    Longitude: "30.7653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17180",
    WardLookupId: "4135",
    VDNumber: "54210013",
    RegisteredPopulation: "1805",
    VotingStationName: "DIEPGEZET PRIMARY SCHOOL",
    Address: "2 ELUKWATINI C  ELUKWATINI  C  GERT SIBANDE",
    Latitude: "-26.0492",
    Longitude: "30.8126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17181",
    WardLookupId: "4135",
    VDNumber: "54210248",
    RegisteredPopulation: "1185",
    VotingStationName: "VUKA PRIMARY SCHOOL",
    Address: "194 ELUKWATINI  B  ELUKWATINI    ELUKWATINI",
    Latitude: "-26.0503",
    Longitude: "30.8213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17182",
    WardLookupId: "4135",
    VDNumber: "54210260",
    RegisteredPopulation: "512",
    VotingStationName: "SIKHONYANE CHURCH",
    Address: "13 ARAMBURG  ELUKWATINI   ELUKWATINI",
    Latitude: "-26.041",
    Longitude: "30.8157",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17183",
    WardLookupId: "4135",
    VDNumber: "54210271",
    RegisteredPopulation: "584",
    VotingStationName: "HARVEST TABERNACLE CHURCH",
    Address: "488 NHLAZATSHE NUMBER 6  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0326",
    Longitude: "30.7975",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17184",
    WardLookupId: "4135",
    VDNumber: "54210361",
    RegisteredPopulation: "288",
    VotingStationName: "CHURCH OF GOD HALL",
    Address: "102 ARAMBURG  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0515",
    Longitude: "30.8392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17185",
    WardLookupId: "4136",
    VDNumber: "54220104",
    RegisteredPopulation: "1440",
    VotingStationName: "SIYABONGA HIGH SCHOOL",
    Address: "E418 GLENMORE  MPULUZI  MPULUZI",
    Latitude: "-26.2628",
    Longitude: "30.8458",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17186",
    WardLookupId: "4136",
    VDNumber: "54220115",
    RegisteredPopulation: "1863",
    VotingStationName: "TSATSELANI PRIMARY SCHOOL",
    Address: "E1368 GLENMORE  MPULUZI  MPULUZI",
    Latitude: "-26.2523",
    Longitude: "30.8541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17187",
    WardLookupId: "4136",
    VDNumber: "54220430",
    RegisteredPopulation: "674",
    VotingStationName: "INTERNATIONAL PENTECOSTAL HOLYNESS CHURCH (IPHC)",
    Address: "E2503 GLENMORE  DUNDONALD  DUNDONALD",
    Latitude: "-26.2449",
    Longitude: "30.8654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17188",
    WardLookupId: "4137",
    VDNumber: "54200023",
    RegisteredPopulation: "386",
    VotingStationName: "NGONINI PRIMARY SCHOOL",
    Address: "23 NGONINI TRUST  EKULINDENI  EKULINDENI",
    Latitude: "-26.0346",
    Longitude: "31.0451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17189",
    WardLookupId: "4137",
    VDNumber: "54200034",
    RegisteredPopulation: "1719",
    VotingStationName: "EKULINDENI COMMUNITY HALL",
    Address: "504 EKULINDENI TOWNSHIP  EKULINDENI  GERT SIBANDE",
    Latitude: "-26.0459",
    Longitude: "31.0216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17190",
    WardLookupId: "4137",
    VDNumber: "54200045",
    RegisteredPopulation: "662",
    VotingStationName: "HAMBANATSI PRIMARY SCHOOL",
    Address: "133 NHLABA TRUST  EKULINDENI  EKULINDENI",
    Latitude: "-26.0741",
    Longitude: "30.9999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17191",
    WardLookupId: "4137",
    VDNumber: "54200056",
    RegisteredPopulation: "71",
    VotingStationName: "ENTSABENI BUILDING",
    Address: "HIGHLANDS FARM, JOSEFSDAL TRUST  EKULINDENI  EKULINDENI",
    Latitude: "-25.9438",
    Longitude: "31.1173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17192",
    WardLookupId: "4137",
    VDNumber: "54200078",
    RegisteredPopulation: "680",
    VotingStationName: "EKULINDENI HIGH SCHOOL",
    Address: "1592 EKULINDENI TOWNSHIP  EKULINDENI  EKULINDENI",
    Latitude: "-26.0465",
    Longitude: "31.0273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17193",
    WardLookupId: "4138",
    VDNumber: "54120125",
    RegisteredPopulation: "1278",
    VotingStationName: "MAKHOSONKE PRIMARY SCHOOL",
    Address: "152 EMAHLABATSINI, TJAKASTAD  ELUKWATINI  ELUKWATINI",
    Latitude: "-25.9979",
    Longitude: "30.8169",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17194",
    WardLookupId: "4138",
    VDNumber: "54120136",
    RegisteredPopulation: "488",
    VotingStationName: "KHUZULWANDLE SECONDARY SCHOOL",
    Address: "A01 MANDLAMAKHULU TRIBAL, TJAKASTAD  TJAKASTAD  GERT SIBANDE",
    Latitude: "-26.0092",
    Longitude: "30.8059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17195",
    WardLookupId: "4138",
    VDNumber: "54120215",
    RegisteredPopulation: "1041",
    VotingStationName: "SAMBULO CHURCH",
    Address: "116 TJAKASTAD TOP CENTRE  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0054",
    Longitude: "30.8209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17196",
    WardLookupId: "4138",
    VDNumber: "54120259",
    RegisteredPopulation: "419",
    VotingStationName: "IMBALI HIGHER PRIMARY SCHOOL",
    Address: "THUBELISHA, TJAKASTAD  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0094",
    Longitude: "30.8126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17197",
    WardLookupId: "4139",
    VDNumber: "54210080",
    RegisteredPopulation: "1355",
    VotingStationName: "BANTFWABETFU HIGH SCHOOL",
    Address: "772A ELUKWATINI   ",
    Latitude: "-26.0602",
    Longitude: "30.7945",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17198",
    WardLookupId: "4139",
    VDNumber: "54210237",
    RegisteredPopulation: "1283",
    VotingStationName: "IMBONDVO PRIMARY SCHOOL",
    Address: "14 JULIUS MKHONTO   ELUKWATINI    ELUKWATINI",
    Latitude: "-26.0654",
    Longitude: "30.7814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17199",
    WardLookupId: "4139",
    VDNumber: "54210282",
    RegisteredPopulation: "940",
    VotingStationName: "ELUKWATINI PRIMARY SCHOOL",
    Address: "200A ELUKWATINI    GERT SIBANDE  ELUKWATINI",
    Latitude: "-26.0529",
    Longitude: "30.7971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17200",
    WardLookupId: "4139",
    VDNumber: "54210293",
    RegisteredPopulation: "645",
    VotingStationName: "NHLAZATSHE PRIMARY SCHOOL",
    Address: "285 A NHLAZATSHE NUMBER 3  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0692",
    Longitude: "30.7706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17201",
    WardLookupId: "4140",
    VDNumber: "54160039",
    RegisteredPopulation: "1140",
    VotingStationName: "EZENZELENI PRIMARY SCHOOL",
    Address: "2147 SOBHUZA STREET, SILOBELA  CAROLINA  CAROLINA",
    Latitude: "-26.0887",
    Longitude: "30.1194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17202",
    WardLookupId: "4140",
    VDNumber: "54160040",
    RegisteredPopulation: "2112",
    VotingStationName: "ZINIKELENI HIGH SCHOOL",
    Address: "2145 SOBHUZA STREET  CAROLINA  CAROLINA",
    Latitude: "-26.088",
    Longitude: "30.1104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17203",
    WardLookupId: "4140",
    VDNumber: "54160051",
    RegisteredPopulation: "507",
    VotingStationName: "SOBHUZA PRIMARY SCHOOL",
    Address: "2180 SILOBELA LOCATION  CAROLINA  CAROLINA",
    Latitude: "-26.0801",
    Longitude: "30.1223",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17204",
    WardLookupId: "4140",
    VDNumber: "54160073",
    RegisteredPopulation: "202",
    VotingStationName: "MADRESS ISLAAMIA CHURCH HALL",
    Address: "MADRESSA ISLAAMIA, CAROPARK  CAROLINA  CAROLINA",
    Latitude: "-26.0761",
    Longitude: "30.1311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17205",
    WardLookupId: "4140",
    VDNumber: "54160084",
    RegisteredPopulation: "476",
    VotingStationName: "NEDERDUITSCH HERVORMDE KERK",
    Address: "737 VOORTREKKER STREET, CAROLINA  CAROLINA  CAROLINA",
    Latitude: "-26.0717",
    Longitude: "30.1198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17206",
    WardLookupId: "4140",
    VDNumber: "54160107",
    RegisteredPopulation: "624",
    VotingStationName: "THE APOSTOLIC FAITH MISSION OF S.A CHURCH HALL",
    Address: "6444 EXTENSION SILOBELA LOCATION  CAROLINA  CAROLINA",
    Latitude: "-26.0876",
    Longitude: "30.1034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17207",
    WardLookupId: "4141",
    VDNumber: "54210024",
    RegisteredPopulation: "1515",
    VotingStationName: "SISUKUMILE SECONDARY SCHOOL",
    Address: "50 LOCHIEL  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.1386",
    Longitude: "30.7849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17208",
    WardLookupId: "4141",
    VDNumber: "54210035",
    RegisteredPopulation: "1076",
    VotingStationName: "HLABANGAMEHLO HIGH SCHOOL",
    Address: "524 A NHLAZATSHE NUMBER 3  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0703",
    Longitude: "30.7669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17209",
    WardLookupId: "4141",
    VDNumber: "54210215",
    RegisteredPopulation: "139",
    VotingStationName: "IMBALI DAY CARE CENTRE CRECHE",
    Address: "B33 BELVEDER   ELUKWATINI  ELUKWATINI",
    Latitude: "-26.1907",
    Longitude: "30.8218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17210",
    WardLookupId: "4141",
    VDNumber: "54210259",
    RegisteredPopulation: "1010",
    VotingStationName: "CHRISTIAN APOSTOLIC CHURCH IN ZION",
    Address: "296 NHLAZATSHE NUMBER 1  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.066",
    Longitude: "30.7627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17211",
    WardLookupId: "4141",
    VDNumber: "54410149",
    RegisteredPopulation: "279",
    VotingStationName: "THE BROOK PRIMARY SCHOOL",
    Address: "114 THE BROOK FARM  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.1812",
    Longitude: "30.6853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17212",
    WardLookupId: "4141",
    VDNumber: "54410228",
    RegisteredPopulation: "152",
    VotingStationName: "MILLIKEN BUILDING",
    Address: "MILLIKEN VILLAGE, HOUSE NO. 1  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.2103",
    Longitude: "30.6551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17213",
    WardLookupId: "4142",
    VDNumber: "54120013",
    RegisteredPopulation: "190",
    VotingStationName: "IMMANUEL INTERNATIONAL BIBLE COLLEGE",
    Address: "C11 AVENTURA, EMANZANA  EMANZANA  EMANZANA",
    Latitude: "-25.9824",
    Longitude: "30.5292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17214",
    WardLookupId: "4142",
    VDNumber: "54120046",
    RegisteredPopulation: "834",
    VotingStationName: "EMANZANA MUNICIPAL OFFICES",
    Address: "GOODMAN AND ROBERTSON  STREET  EMANZANA  EMANZANA",
    Latitude: "-25.959",
    Longitude: "30.568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17215",
    WardLookupId: "4142",
    VDNumber: "54120057",
    RegisteredPopulation: "164",
    VotingStationName: "INYATHANA BUILDING",
    Address: "FARM DORSTBULT NO 33-IT  CAROLINA  CAROLINA",
    Latitude: "-26.1269",
    Longitude: "30.4075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17216",
    WardLookupId: "4142",
    VDNumber: "54120080",
    RegisteredPopulation: "816",
    VotingStationName: "SUNDUZA COMBINED SCHOOL",
    Address: "723 WINKELHAAK FARM  BADPLAAS  CAROLINA",
    Latitude: "-25.9489",
    Longitude: "30.5893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17217",
    WardLookupId: "4142",
    VDNumber: "54120237",
    RegisteredPopulation: "392",
    VotingStationName: "EMBHULENI ROYAL HALL",
    Address: "EMBHULENI RESIDENCE  EMANZANA  EMANZANA",
    Latitude: "-25.9654",
    Longitude: "30.5723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17218",
    WardLookupId: "4142",
    VDNumber: "54120248",
    RegisteredPopulation: "703",
    VotingStationName: "ALIANCE CHURCH",
    Address: "484 DLAMINI B  BADPLAAS  BADPLAAS",
    Latitude: "-25.9528",
    Longitude: "30.5822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17219",
    WardLookupId: "4143",
    VDNumber: "54120024",
    RegisteredPopulation: "489",
    VotingStationName: "MHOLA PRIMARY SCHOOL",
    Address: "AVONTUUR TRUST  GERT SIBANDE",
    Latitude: "-25.9652",
    Longitude: "30.807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17220",
    WardLookupId: "4143",
    VDNumber: "54120103",
    RegisteredPopulation: "900",
    VotingStationName: "MAQHAWUZELA INTERMEDIATE SCHOOL",
    Address: "2038 MAQHAWUZELA SECTION, TJAKASTAD  ELUKWATINI  ELUKWATINI",
    Latitude: "-25.9779",
    Longitude: "30.8139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17221",
    WardLookupId: "4143",
    VDNumber: "54120114",
    RegisteredPopulation: "850",
    VotingStationName: "EKUKHANYENI PRIMARY SCHOOL",
    Address: "97 EKULINDENI SECTION, TJAKASTAD  ELUKWATINI  ELUKWATINI",
    Latitude: "-25.9822",
    Longitude: "30.8316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17222",
    WardLookupId: "4143",
    VDNumber: "54120181",
    RegisteredPopulation: "318",
    VotingStationName: "UMBHEJEKA PRIMARY SCHOOL",
    Address: "UMBHEJEKA TRUST  GERT SIBANDE",
    Latitude: "-25.9781",
    Longitude: "30.8722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17223",
    WardLookupId: "4143",
    VDNumber: "54120192",
    RegisteredPopulation: "1160",
    VotingStationName: "INSIKA SECONDARY SCHOOL",
    Address: "03 SUNCITY, TJAKASTAD  ELUKWATINI  ELUKWATINI",
    Latitude: "-25.9929",
    Longitude: "30.8106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17224",
    WardLookupId: "4143",
    VDNumber: "54120271",
    RegisteredPopulation: "538",
    VotingStationName: "GOD HELP US GLOBAL CHURCH HALL",
    Address: "472 TJAKASTAD EMABOVINI SECTION  ELUKWATINI  ELUKWATINI",
    Latitude: "-25.994",
    Longitude: "30.8214",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17225",
    WardLookupId: "4144",
    VDNumber: "54200012",
    RegisteredPopulation: "362",
    VotingStationName: "NGILANDI SECONDARY SCHOOL",
    Address: "PLOT 111 STEYNSDORP  EKULINDENI  EKULINDENI",
    Latitude: "-26.1243",
    Longitude: "30.9809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17226",
    WardLookupId: "4144",
    VDNumber: "54200067",
    RegisteredPopulation: "187",
    VotingStationName: "LUBHAQA PRIMARY SCHOOL",
    Address: "38 EBUTSINI TRUST  EKULINDENI  EKULINDENI",
    Latitude: "-26.1053",
    Longitude: "30.9436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17227",
    WardLookupId: "4144",
    VDNumber: "54200089",
    RegisteredPopulation: "390",
    VotingStationName: "STEYNSDORP PRIMARY SCHOOL",
    Address: "140 VLAKPLAAS, STEYNSDORP  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.1409",
    Longitude: "30.9796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17228",
    WardLookupId: "4144",
    VDNumber: "54210046",
    RegisteredPopulation: "1915",
    VotingStationName: "FATHER CHARLES PRIMARY SCHOOL",
    Address: "4 MOOIPLAAS  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0681",
    Longitude: "30.8768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17229",
    WardLookupId: "4144",
    VDNumber: "54210125",
    RegisteredPopulation: "440",
    VotingStationName: "INKABA PRIMARY SCHOOL",
    Address: "11 ENKABA   ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0908",
    Longitude: "30.8991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17230",
    WardLookupId: "4144",
    VDNumber: "54210305",
    RegisteredPopulation: "347",
    VotingStationName: "BONGUMKHWANAZI PRIMARY SCHOOL",
    Address: "7 ENKOMENI, MOOIPLAAS  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0553",
    Longitude: "30.8961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17231",
    WardLookupId: "4145",
    VDNumber: "54210103",
    RegisteredPopulation: "1686",
    VotingStationName: "DLOMODLOMO SECONDARY SCHOOL",
    Address: "228-9 NHLAZATSHE NUMBER 4B  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0476",
    Longitude: "30.7845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17232",
    WardLookupId: "4145",
    VDNumber: "54210226",
    RegisteredPopulation: "965",
    VotingStationName: "EMSENI JUNIOR SECONDARY SCHOOL",
    Address: "1239 NHLAZATSHE NUMBER 4  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0528",
    Longitude: "30.7749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17233",
    WardLookupId: "4145",
    VDNumber: "54210338",
    RegisteredPopulation: "434",
    VotingStationName: "THOLULWAZI PRE-SCHOOL",
    Address: "365 NHLAZATSHE 4B  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0448",
    Longitude: "30.7965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17234",
    WardLookupId: "4146",
    VDNumber: "54160017",
    RegisteredPopulation: "1823",
    VotingStationName: "CAROLINA TOWN HALL",
    Address: "28 KERK STREET  CAROLINA  GERT SIBANDE",
    Latitude: "-26.0673",
    Longitude: "30.1144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17235",
    WardLookupId: "4146",
    VDNumber: "54160062",
    RegisteredPopulation: "442",
    VotingStationName: "PADKAMP COMMUNITY HALL",
    Address: "790 THIRD STREET  CAROLINA  CAROLINA",
    Latitude: "-26.0641",
    Longitude: "30.125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17236",
    WardLookupId: "4146",
    VDNumber: "54390012",
    RegisteredPopulation: "140",
    VotingStationName: "NOOITGEDACHT DAM OFFICE",
    Address: "FARM WINTERSHOEK 451JT  CAROLINA  CATOLINA",
    Latitude: "-25.9553",
    Longitude: "30.0831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17237",
    WardLookupId: "4146",
    VDNumber: "54390023",
    RegisteredPopulation: "208",
    VotingStationName: "BAADJIESBULT COMBINED SCHOOL",
    Address: "APPELDORN FARM, CAROLINA  CAROLINA  CAROLINA",
    Latitude: "-26.1342",
    Longitude: "30.25",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17238",
    WardLookupId: "4146",
    VDNumber: "54390034",
    RegisteredPopulation: "462",
    VotingStationName: "SIPHAKAMILE COMBINED SCHOOL",
    Address: "496JS 496JS STRATHRAE COLLIERY  FARM  CAROLINA  CAROLINA",
    Latitude: "-25.9416",
    Longitude: "29.9374",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17239",
    WardLookupId: "4146",
    VDNumber: "54390045",
    RegisteredPopulation: "324",
    VotingStationName: "THOKOZANI COMBINED SCHOOL",
    Address: "PLOT 418 JT VLAKFONTEIN FARM, MOEDIG  CAROLINA  CAROLINA",
    Latitude: "-25.8961",
    Longitude: "30.1783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17240",
    WardLookupId: "4146",
    VDNumber: "54390056",
    RegisteredPopulation: "227",
    VotingStationName: "EZINDONGENI PRIMARY SCHOOL",
    Address: "VAALBANK FB6  CAROLINA  CAROLINA",
    Latitude: "-26.1804",
    Longitude: "29.9735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17241",
    WardLookupId: "4146",
    VDNumber: "54390067",
    RegisteredPopulation: "249",
    VotingStationName: "ONBEKEND COMMUNITY HALL",
    Address: "41 IT PORTION 3  DROPVALEIN   CAROLINA  CAROLINA",
    Latitude: "-26.1002",
    Longitude: "30.1231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17242",
    WardLookupId: "4146",
    VDNumber: "54410015",
    RegisteredPopulation: "232",
    VotingStationName: "WELGEMEEND  PRIMARY SCHOOL",
    Address: "WELGEMEEND FARM  CAROLINA  CAROLINA",
    Latitude: "-26.206",
    Longitude: "29.8576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17243",
    WardLookupId: "4147",
    VDNumber: "54160028",
    RegisteredPopulation: "886",
    VotingStationName: "SILOBELA COMMUNITY HALL",
    Address: "9 SILOBELA  GERT SIBANDE",
    Latitude: "-26.0878",
    Longitude: "30.1141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17244",
    WardLookupId: "4147",
    VDNumber: "54160095",
    RegisteredPopulation: "1612",
    VotingStationName: "SILOBELA ROMAN CATHOLIC CHURCH HALL",
    Address: "1506/17 SILOBELA  CAROLINA  CAROLINA",
    Latitude: "-26.091",
    Longitude: "30.1156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17245",
    WardLookupId: "4148",
    VDNumber: "54120035",
    RegisteredPopulation: "305",
    VotingStationName: "DIYANE BUILDING",
    Address: "DIYANE/VERGELEGEN FARM  EMANZANA  EMANZANA",
    Latitude: "-25.9955",
    Longitude: "30.6967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17246",
    WardLookupId: "4148",
    VDNumber: "54120068",
    RegisteredPopulation: "452",
    VotingStationName: "ENGELSEDRAAI COMBINED SCHOOL",
    Address: "1 ENGELSEDRAAI FARM, EBUKHOSINI   EMANZANA  EMANZANA",
    Latitude: "-25.8385",
    Longitude: "30.5909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17247",
    WardLookupId: "4148",
    VDNumber: "54120091",
    RegisteredPopulation: "297",
    VotingStationName: "VRIESLAND PRIMARY SCHOOL",
    Address: "PJ26 VRIESLAND FARM MANTJOLO VILLAG  EMANZANA  EMANZANA",
    Latitude: "-25.8569",
    Longitude: "30.7107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17248",
    WardLookupId: "4148",
    VDNumber: "54120147",
    RegisteredPopulation: "196",
    VotingStationName: "MAHLABATHINI COMMUNITY HALL",
    Address: "20 MAHLABATHINI FARM  EMANZANA  EMANZANA",
    Latitude: "-25.8019",
    Longitude: "30.6449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17249",
    WardLookupId: "4148",
    VDNumber: "54120226",
    RegisteredPopulation: "1258",
    VotingStationName: "ENGABEZWENI SECONDARY SCHOOL",
    Address: "2 DLAMINI C, BADPLAAS  BADPLAAS  GERT SIBANDE",
    Latitude: "-25.9591",
    Longitude: "30.5805",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17250",
    WardLookupId: "4148",
    VDNumber: "54120260",
    RegisteredPopulation: "257",
    VotingStationName: "CHIEF KJ MALAZA PRIMARY SCHOOL",
    Address: "2003 KAMALAHLEKA, TJAKASTAD  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0026",
    Longitude: "30.7714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17251",
    WardLookupId: "4148",
    VDNumber: "54120282",
    RegisteredPopulation: "154",
    VotingStationName: "VYGEBOOM PRIMARY SCHOOL",
    Address: "VYGEBOOM FARM  EMANZANA  EMANZANA",
    Latitude: "-25.9147",
    Longitude: "30.6556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17252",
    WardLookupId: "4148",
    VDNumber: "54120293",
    RegisteredPopulation: "222",
    VotingStationName: "ALLIANCE CHURCH IN SA",
    Address: "32 NHLAZATSHE NUMBER 7B  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0228",
    Longitude: "30.7649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17253",
    WardLookupId: "4149",
    VDNumber: "54210114",
    RegisteredPopulation: "1671",
    VotingStationName: "LAMAGADLELA PRIMARY SCHOOL",
    Address: "250 NHLAZATSHE 6  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0292",
    Longitude: "30.794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17254",
    WardLookupId: "4149",
    VDNumber: "54210192",
    RegisteredPopulation: "766",
    VotingStationName: "LETSAKUTHULA PRIMARY SCHOOL",
    Address: "155 NHLAZATSHE NUMBER 7  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0254",
    Longitude: "30.7844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17255",
    WardLookupId: "4149",
    VDNumber: "54210204",
    RegisteredPopulation: "1270",
    VotingStationName: "MKHOMAZANE PRIMARY SCHOOL",
    Address: "441 NHLAZATSHE NO. 2  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0462",
    Longitude: "30.7804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17256",
    WardLookupId: "4149",
    VDNumber: "54210316",
    RegisteredPopulation: "760",
    VotingStationName: "KENNAN PRE-SCHOOL",
    Address: "569 NHLAZATSHE NUMBER 5  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0361",
    Longitude: "30.7845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17257",
    WardLookupId: "4150",
    VDNumber: "54210079",
    RegisteredPopulation: "1588",
    VotingStationName: "TIMELENI PRIMARY SCHOOL",
    Address: "187 SOLLY STREET  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.06",
    Longitude: "30.7702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17258",
    WardLookupId: "4150",
    VDNumber: "54210091",
    RegisteredPopulation: "1645",
    VotingStationName: "SIYETA PRIMARY SCHOOL",
    Address: "959 NHLAZATSHE NUMBER 2  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0515",
    Longitude: "30.7638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17259",
    WardLookupId: "4150",
    VDNumber: "54210349",
    RegisteredPopulation: "409",
    VotingStationName: "ICIBI CHRISTIAN CHURCH IN ZION",
    Address: "75 NHLAZATSHE NUMBER 4C  ELUKWATINI  ELUKWATINI",
    Latitude: "-26.0553",
    Longitude: "30.7658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17260",
    WardLookupId: "2087",
    VDNumber: "54230105",
    RegisteredPopulation: "1942",
    VotingStationName: "ITHAFA COMPREHENSIVE HIGH SCHOOL",
    Address: "2847 MANDELA DR STREET WESSELTON   WESSELTON LOCATION  ERMELO",
    Latitude: "-26.4943",
    Longitude: "29.97",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17261",
    WardLookupId: "2087",
    VDNumber: "54230150",
    RegisteredPopulation: "1284",
    VotingStationName: "REGGIE MASUKU SECONDARY SCHOOL",
    Address:
      "3753 ALBERT LUTHULI DRIVE STREET   WESSELTON [ERMELO]  MSUKALIGWA [ERMELO]",
    Latitude: "-26.4899",
    Longitude: "29.9641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17262",
    WardLookupId: "2088",
    VDNumber: "54230093",
    RegisteredPopulation: "1889",
    VotingStationName: "AFRICAN FREE CHURCH",
    Address: "THUSI VILLAGE  WESSELTON  ERMELO",
    Latitude: "-26.4879",
    Longitude: "29.9541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17263",
    WardLookupId: "2088",
    VDNumber: "54230228",
    RegisteredPopulation: "2502",
    VotingStationName: "QEDUSIZI DAY CARE",
    Address: "6201 EXT 6 THUSI VILLAGE  WESSELTON [ERMELO]  ERMELO",
    Latitude: "-26.494",
    Longitude: "29.954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17264",
    WardLookupId: "2089",
    VDNumber: "54230048",
    RegisteredPopulation: "1098",
    VotingStationName: "CASSIM PARK COMMUNITY HALL",
    Address: "30 LITTLEDALE STREET  CASSIMPARK  ERMELO",
    Latitude: "-26.5235",
    Longitude: "29.9643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17265",
    WardLookupId: "2089",
    VDNumber: "54230172",
    RegisteredPopulation: "810",
    VotingStationName: "APOSTOLIC FAITH MISSION",
    Address: "306 HLUBI STREET  MSUKALIGWA [ERMELO]  MSUKALIGWA [ERMELO]",
    Latitude: "-26.517",
    Longitude: "29.9697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17266",
    WardLookupId: "2089",
    VDNumber: "54230194",
    RegisteredPopulation: "1212",
    VotingStationName: "MSEBE COMBINED SCHOOL",
    Address: "462 MABILISA STREET  WESSELTON  MSUKALIGWA [ERMELO]",
    Latitude: "-26.5151",
    Longitude: "29.9673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17267",
    WardLookupId: "2089",
    VDNumber: "54230206",
    RegisteredPopulation: "1697",
    VotingStationName: "J.J VAN DER MERWE SCHOOL",
    Address: "22 HUBBARD STREET  DEBRUIN PARK  MSUKALIGWA",
    Latitude: "-26.5355",
    Longitude: "29.9724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17268",
    WardLookupId: "2090",
    VDNumber: "54230060",
    RegisteredPopulation: "1747",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "774 MOTHOPENG STREET WESSELTON ERM  WESSELTON  ERMELO",
    Latitude: "-26.506",
    Longitude: "29.9744",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17269",
    WardLookupId: "2090",
    VDNumber: "54230149",
    RegisteredPopulation: "1073",
    VotingStationName: "NEW HOLY SPIRIT CATHOLIC CHURCH",
    Address: "3333 13TH AVENUE EXT 2 WESSELTON ER  MSUKALIGWA [ERMELO]  ERMELO",
    Latitude: "-26.5052",
    Longitude: "29.9781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17270",
    WardLookupId: "2091",
    VDNumber: "54230082",
    RegisteredPopulation: "2249",
    VotingStationName: "BASHELE PRIMARY SCHOOL",
    Address: "1827 THANJEKWAYO STREET  WESSELTON  ERMELO",
    Latitude: "-26.5004",
    Longitude: "29.9727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17271",
    WardLookupId: "2091",
    VDNumber: "54230240",
    RegisteredPopulation: "735",
    VotingStationName: "ROMAN CATHOLIC CHURCH TOWN",
    Address: "59 KLEIN STREET   ERMELO  ERMELO",
    Latitude: "-26.5131",
    Longitude: "29.997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17272",
    WardLookupId: "2092",
    VDNumber: "54230026",
    RegisteredPopulation: "1706",
    VotingStationName: "WESSELTON LIBRARY",
    Address: "283 JIYANE STREET  WESSELTON LOCATION  ERMELO",
    Latitude: "-26.5146",
    Longitude: "29.9726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17273",
    WardLookupId: "2092",
    VDNumber: "54230183",
    RegisteredPopulation: "1033",
    VotingStationName: "WESSELTON COMMUNITY HALL",
    Address: "1 MABILISA STREET  MSUKALIGWA [ERMELO]  MSUKALIGWA [ERMELO]",
    Latitude: "-26.5193",
    Longitude: "29.9753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17274",
    WardLookupId: "2092",
    VDNumber: "54230239",
    RegisteredPopulation: "571",
    VotingStationName: "POWER STATION OPEN SPACE (TENT)",
    Address: "POWER STREET  WESSELTON  ERMELO",
    Latitude: "-26.5115",
    Longitude: "29.9811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17275",
    WardLookupId: "2093",
    VDNumber: "54230015",
    RegisteredPopulation: "1871",
    VotingStationName: "ELLA DE BRUYN HALL",
    Address: "CNR KERK & TAUTE STREET  JOUBERT PARK  ERMELO",
    Latitude: "-26.5276",
    Longitude: "29.9892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17276",
    WardLookupId: "2093",
    VDNumber: "54230059",
    RegisteredPopulation: "2015",
    VotingStationName: "FIRE STATION",
    Address: "CNR BORDER & WEDGEWOOD AVE  ERMELO  ERMELO",
    Latitude: "-26.5227",
    Longitude: "29.993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17277",
    WardLookupId: "2094",
    VDNumber: "54230037",
    RegisteredPopulation: "1594",
    VotingStationName: "SPORT INN",
    Address: "43 WES STREET  ERMELO  ERMELO",
    Latitude: "-26.5288",
    Longitude: "29.9817",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17278",
    WardLookupId: "2094",
    VDNumber: "54230161",
    RegisteredPopulation: "2029",
    VotingStationName: "ERMELO TECHNICAL COLLEGE",
    Address: "1896 MEL METZ STREET  MSUKALIGWA [ERMELO]  MSUKALIGWA [ERMELO]",
    Latitude: "-26.5419",
    Longitude: "29.9865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17279",
    WardLookupId: "2094",
    VDNumber: "54410048",
    RegisteredPopulation: "147",
    VotingStationName: "RIETVLEI FARM (TENT)",
    Address: "433 IS RIETVLEI FARM  ERMELO  ERMELO",
    Latitude: "-26.512",
    Longitude: "29.8324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17280",
    WardLookupId: "2094",
    VDNumber: "54410330",
    RegisteredPopulation: "307",
    VotingStationName: "KANDLELENI CRECHE",
    Address: "450 IS UITZIGT FARM  ERMELO  ERMELO",
    Latitude: "-26.6252",
    Longitude: "29.7841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17281",
    WardLookupId: "2094",
    VDNumber: "54410341",
    RegisteredPopulation: "93",
    VotingStationName: "SPRINGBOKFONTEIN FARM (TENT)",
    Address: "425 IS SPRINGBOKFONTEIN FARM  SPRINGBOKFONTEIN  DAVEL",
    Latitude: "-26.5556",
    Longitude: "29.6758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17282",
    WardLookupId: "2094",
    VDNumber: "54410352",
    RegisteredPopulation: "129",
    VotingStationName: "RIETSPRUIT FARM",
    Address: "RIETSPRUIT  ERMELO  ERMELO",
    Latitude: "-26.6096",
    Longitude: "29.9506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17283",
    WardLookupId: "2095",
    VDNumber: "54230116",
    RegisteredPopulation: "1813",
    VotingStationName: "WESSELTON PRIMARY SCHOOL",
    Address: "4747 OR THAMBO STREET  WESSELTON  ERMELO",
    Latitude: "-26.4835",
    Longitude: "29.9586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17284",
    WardLookupId: "2095",
    VDNumber: "54230127",
    RegisteredPopulation: "1121",
    VotingStationName: "ZAMOKUHLE DAY CARE CENTRE",
    Address: "4747 OR THAMBO STREET  WESSELTON  ERMELO",
    Latitude: "-26.4817",
    Longitude: "29.959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17285",
    WardLookupId: "2095",
    VDNumber: "54410071",
    RegisteredPopulation: "255",
    VotingStationName: "TORBANITE PRIMARY SCHOOL",
    Address: "100 IT MOOIFONTEIN FARM  ERMELO DISTRICT  ERMELO",
    Latitude: "-26.4264",
    Longitude: "30.0173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17286",
    WardLookupId: "2095",
    VDNumber: "54410318",
    RegisteredPopulation: "1542",
    VotingStationName: "THUBELIHLE DAY CARE CENTRE",
    Address: "WESSELTON  EKHAYELISHA   ERMELO",
    Latitude: "-26.4922",
    Longitude: "29.9419",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17287",
    WardLookupId: "2095",
    VDNumber: "54410363",
    RegisteredPopulation: "563",
    VotingStationName: "KAMABUZA INFORMAL SETTLEMENT (TENT)",
    Address: "261 IT VAN OUDSHOORN  ERMELO  ERMELO",
    Latitude: "-26.4937",
    Longitude: "30.0051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17288",
    WardLookupId: "2096",
    VDNumber: "54180019",
    RegisteredPopulation: "2185",
    VotingStationName: "DAVEL PRIMARY SCHOOL",
    Address: "58 KWADELA  DAVEL",
    Latitude: "-26.4625",
    Longitude: "29.6632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17289",
    WardLookupId: "2096",
    VDNumber: "54180020",
    RegisteredPopulation: "470",
    VotingStationName: "NEW LIFE CHURCH",
    Address: "2 DAVEL  ",
    Latitude: "-26.4481",
    Longitude: "29.6688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17290",
    WardLookupId: "2096",
    VDNumber: "54380022",
    RegisteredPopulation: "291",
    VotingStationName: "UMFUDLANA COMBINED SCHOOL",
    Address: "MSUKALIGWA [ERMELO]  ",
    Latitude: "-26.3363",
    Longitude: "29.7198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17291",
    WardLookupId: "2096",
    VDNumber: "54410037",
    RegisteredPopulation: "159",
    VotingStationName: "SIZA FARM (TENT)",
    Address: "266 IS UITZICHT FARM  KWADELA   DAVEL",
    Latitude: "-26.4434",
    Longitude: "29.7512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17292",
    WardLookupId: "2096",
    VDNumber: "54410239",
    RegisteredPopulation: "187",
    VotingStationName: "WITBANK FARM TENT",
    Address: "219 MOOIFLEI   KWADELA   MSUKALIGWA",
    Latitude: "-26.2367",
    Longitude: "29.764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17293",
    WardLookupId: "2096",
    VDNumber: "54410240",
    RegisteredPopulation: "105",
    VotingStationName: "REMHOOGTE FARM",
    Address: "428 IS REMHOOGTE FARM  MSUKALIGWA [ERMELO]  MSUKALIGWA [ERMELO]",
    Latitude: "-26.5267",
    Longitude: "29.7448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17294",
    WardLookupId: "2096",
    VDNumber: "54410273",
    RegisteredPopulation: "93",
    VotingStationName: "TWEEFONTEIN FARM (TENT)",
    Address: "249 TWEEFONTEIN FARM  TWEEFONTEIN   ERMELO",
    Latitude: "-26.3369",
    Longitude: "29.8004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17295",
    WardLookupId: "2097",
    VDNumber: "54410127",
    RegisteredPopulation: "2052",
    VotingStationName: "BEE MASEKO SECONDARY SCHOOL",
    Address: "SHEEPMOOR VILLAGE  SHEEPMOOR ON ROAD 532  ERMELO",
    Latitude: "-26.7172",
    Longitude: "30.2955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17296",
    WardLookupId: "2097",
    VDNumber: "54410217",
    RegisteredPopulation: "677",
    VotingStationName: "VULINGGQONDO PRIMARY SCHOOL",
    Address: "MSUKALIGWA [ERMELO]  ",
    Latitude: "-26.6729",
    Longitude: "29.9505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17297",
    WardLookupId: "2097",
    VDNumber: "54410251",
    RegisteredPopulation: "164",
    VotingStationName: "WELGELEGEN FARM (TENT)",
    Address: "DE EMIGRATE ROAD  NULL  MSUKALIGWA [ERMELO]",
    Latitude: "-26.6684",
    Longitude: "30.0707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17298",
    WardLookupId: "2097",
    VDNumber: "54410374",
    RegisteredPopulation: "203",
    VotingStationName: "HABILE PRIMARY SCHOOL",
    Address: "LEIDEN  SHEEPMOOR RURAL  SHEEPMOOR",
    Latitude: "-26.8129",
    Longitude: "30.2882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17299",
    WardLookupId: "2097",
    VDNumber: "54410385",
    RegisteredPopulation: "136",
    VotingStationName: "OVERVAL PRIMARY SCHOOL",
    Address: "BURHMANSVALLEI  ERMELO  ERMELO",
    Latitude: "-26.692",
    Longitude: "30.1438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17300",
    WardLookupId: "2097",
    VDNumber: "54410396",
    RegisteredPopulation: "129",
    VotingStationName: "LUNGELO PRIM SCHOOL",
    Address: "AFFERSPRUIT  MSUKALIGWA  ERMELO",
    Latitude: "-26.6219",
    Longitude: "29.8857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17301",
    WardLookupId: "2097",
    VDNumber: "54410408",
    RegisteredPopulation: "155",
    VotingStationName: "HOLLAND FARM (TENT)",
    Address: "471AS HOLLAND FARM  KWAMAHLAFUNA   ERMELO",
    Latitude: "-26.7542",
    Longitude: "29.8856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17302",
    WardLookupId: "2098",
    VDNumber: "54290011",
    RegisteredPopulation: "1561",
    VotingStationName: "LOTHAIR PRIMARY SCHOOL, SILINDILE",
    Address: "MAIN ROAD  SILINDILE  LOTHAIR",
    Latitude: "-26.389",
    Longitude: "30.4602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17303",
    WardLookupId: "2098",
    VDNumber: "54410194",
    RegisteredPopulation: "1738",
    VotingStationName: "THE ALLIANCE CHURCH OF SA",
    Address: "INGAGKA LOCATION  WARBURTON  MP302 - MSUKALIGWA [ERMELO]",
    Latitude: "-26.2279",
    Longitude: "30.4788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17304",
    WardLookupId: "2098",
    VDNumber: "54410442",
    RegisteredPopulation: "143",
    VotingStationName: "ISABELLADALE PRIMARY SCHOOL",
    Address: "ISABELLADALE  WARBURTON  WARBURTON",
    Latitude: "-26.2101",
    Longitude: "30.5882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17305",
    WardLookupId: "2098",
    VDNumber: "54410453",
    RegisteredPopulation: "78",
    VotingStationName: "BUSBY OIL",
    Address: "222 IT BUSBY  LOTHAIR",
    Latitude: "-26.287",
    Longitude: "30.5896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17306",
    WardLookupId: "2099",
    VDNumber: "54150016",
    RegisteredPopulation: "2631",
    VotingStationName: "BREYTEN MUNICIPAL OFFICES",
    Address: "224 HOY STREET  BREYTEN  BREYTEN",
    Latitude: "-26.3054",
    Longitude: "29.9798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17307",
    WardLookupId: "2099",
    VDNumber: "54150027",
    RegisteredPopulation: "1427",
    VotingStationName: "SIYAZI PRIMARY SCHOOL",
    Address: "1 MAFRED HOMES  KWAZANELE  BREYTEN",
    Latitude: "-26.3164",
    Longitude: "29.9779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17308",
    WardLookupId: "2100",
    VDNumber: "54150038",
    RegisteredPopulation: "604",
    VotingStationName: "MKHOMAZI PRIMARY SCHOOL",
    Address: "BREYTEN  ",
    Latitude: "-26.3173",
    Longitude: "29.9818",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17309",
    WardLookupId: "2100",
    VDNumber: "54410026",
    RegisteredPopulation: "2310",
    VotingStationName: "KWAZANELE COMMUNITY HALL",
    Address: "MAIN ROAD  KWAZANELE  BREYTEN",
    Latitude: "-26.3142",
    Longitude: "29.9832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17310",
    WardLookupId: "2101",
    VDNumber: "54290022",
    RegisteredPopulation: "2291",
    VotingStationName: "SILINDILE COMMUNITY HALL",
    Address: "SILINDILE NEW LOCATION  MSUKALIGWA [ERMELO]  MSUKALIGWA [ERMELO]",
    Latitude: "-26.3943",
    Longitude: "30.4559",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17311",
    WardLookupId: "2101",
    VDNumber: "54410138",
    RegisteredPopulation: "337",
    VotingStationName: "CLIFTON PRIMARY SCHOOL",
    Address: "143 IT FARM  LOTHAIR   LOTHAIR",
    Latitude: "-26.4817",
    Longitude: "30.4548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17312",
    WardLookupId: "2101",
    VDNumber: "54410150",
    RegisteredPopulation: "572",
    VotingStationName: "MTHULWANE SCHOOL",
    Address: "42 DAVIDALE  ",
    Latitude: "-26.4226",
    Longitude: "30.6357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17313",
    WardLookupId: "2101",
    VDNumber: "54410284",
    RegisteredPopulation: "452",
    VotingStationName: "THE GEM SCHOOL",
    Address: "ERMELO  ",
    Latitude: "-26.3222",
    Longitude: "30.6446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17314",
    WardLookupId: "2101",
    VDNumber: "54410419",
    RegisteredPopulation: "324",
    VotingStationName: "RINGKINK PRIMARY",
    Address: "229 IT RINGKINK  EKUTHULENI VILLAGE  LOTHAIR",
    Latitude: "-26.365",
    Longitude: "30.6194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17315",
    WardLookupId: "2101",
    VDNumber: "54410420",
    RegisteredPopulation: "130",
    VotingStationName: "LETHOKUHLE CHRECHE",
    Address: "229 IT BLAIRMORE  LOTHAIR",
    Latitude: "-26.3879",
    Longitude: "30.731",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17316",
    WardLookupId: "2101",
    VDNumber: "54410486",
    RegisteredPopulation: "183",
    VotingStationName: "DAMESFONTEIN HALL",
    Address: "226 IT DAMESFONTEIN  LOTHAIR  LOTHAIR",
    Latitude: "-26.3951",
    Longitude: "30.5487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17317",
    WardLookupId: "2102",
    VDNumber: "54230217",
    RegisteredPopulation: "3442",
    VotingStationName: "NEW ERMELO PRIMARY SCHOOL",
    Address: "MP302 - MSUKALIGWA [ERMELO]  ",
    Latitude: "-26.5463",
    Longitude: "30.0005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17318",
    WardLookupId: "2102",
    VDNumber: "54410093",
    RegisteredPopulation: "2082",
    VotingStationName: "ENYIBE PRIMARY SCHOOL",
    Address:
      "SQUATTER AREA NAMED ENYEBE  PORTION 109 OF FARM VAN OUTSHOORNSTROOM 261 IT  ERMELO",
    Latitude: "-26.5357",
    Longitude: "30.0207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17319",
    WardLookupId: "2102",
    VDNumber: "54410431",
    RegisteredPopulation: "98",
    VotingStationName: "TENT AT VAN OUDSHOORN FARM",
    Address: "VAN OUDSHOORN  ERMELO  ERMELO",
    Latitude: "-26.5304",
    Longitude: "30.0714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17320",
    WardLookupId: "2103",
    VDNumber: "54230071",
    RegisteredPopulation: "2042",
    VotingStationName: "PHUMULA PRIMARY SCHOOL",
    Address: "2461 MPOFU STREET  WESSELTON  ERMELO",
    Latitude: "-26.5002",
    Longitude: "29.9655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17321",
    WardLookupId: "2103",
    VDNumber: "54230138",
    RegisteredPopulation: "719",
    VotingStationName: "PIETER MABUZA PRIMARY SCHOOL",
    Address: "1488 MABUZA  WESSELTON  ERMELO",
    Latitude: "-26.5048",
    Longitude: "29.9699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17322",
    WardLookupId: "2103",
    VDNumber: "54230251",
    RegisteredPopulation: "594",
    VotingStationName: "PARADISE PENTECOSTAL CHURCH",
    Address: "THANJEKWAYO STR   WESSELTON  ERMELO",
    Latitude: "-26.4995",
    Longitude: "29.9701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17323",
    WardLookupId: "2104",
    VDNumber: "54410082",
    RegisteredPopulation: "480",
    VotingStationName: "CAMDEN COMBINED SCHOOL",
    Address: "19 POPLAR STREET  CAMDEN  RURAL",
    Latitude: "-26.5987",
    Longitude: "30.0837",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17324",
    WardLookupId: "2104",
    VDNumber: "54410116",
    RegisteredPopulation: "690",
    VotingStationName: "UMZIMVELO AGRICULTURAL SCHOOL",
    Address: "24KM ON ERMELO/AMSTERDAM ROAD  RURAL  ERMELO",
    Latitude: "-26.5585",
    Longitude: "30.2387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17325",
    WardLookupId: "2104",
    VDNumber: "54410161",
    RegisteredPopulation: "700",
    VotingStationName: "JERICHO DAM RECREATION HALL",
    Address: "FARM JERICHO 304-1T  ERMELO DISTRICT OFF ROAD 1049  AMSTERDAM",
    Latitude: "-26.6614",
    Longitude: "30.4854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17326",
    WardLookupId: "2104",
    VDNumber: "54410262",
    RegisteredPopulation: "400",
    VotingStationName: "ENDOTSHENI PRIMARY SCHOOL",
    Address: "MSUKALIGWA [ERMELO]  ",
    Latitude: "-26.4545",
    Longitude: "30.1681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17327",
    WardLookupId: "2104",
    VDNumber: "54410464",
    RegisteredPopulation: "252",
    VotingStationName: "KOKSDAL FARM",
    Address: "308 IT KOKSDAL   KOKSDAL  ERMELO",
    Latitude: "-26.7505",
    Longitude: "30.4562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17328",
    WardLookupId: "2104",
    VDNumber: "54410475",
    RegisteredPopulation: "106",
    VotingStationName: "FOXHILL FARM",
    Address: "140 IT FOXHILL  LOTHAIR  LOTHAIR",
    Latitude: "-26.4618",
    Longitude: "30.3576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17329",
    WardLookupId: "2104",
    VDNumber: "54410510",
    RegisteredPopulation: "120",
    VotingStationName: "THEMBELIHLE DAY CARE",
    Address: "ERMELO  ZANDSPRUIT   SHEEPMOOR",
    Latitude: "-26.7296",
    Longitude: "30.3833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17330",
    WardLookupId: "2105",
    VDNumber: "54170018",
    RegisteredPopulation: "772",
    VotingStationName: "CHRISSIESMEER COMMUNITY HALL",
    Address: "151 KING EDWARD STREET  CHRISSIESMEER  CHRISSIESMEER",
    Latitude: "-26.2822",
    Longitude: "30.2109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17331",
    WardLookupId: "2105",
    VDNumber: "54170029",
    RegisteredPopulation: "952",
    VotingStationName: "LAKE CHRISSIE HIGH SCHOOL",
    Address: "MP302 - MSUKALIGWA [ERMELO]  ",
    Latitude: "-26.2754",
    Longitude: "30.2285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17332",
    WardLookupId: "2105",
    VDNumber: "54410105",
    RegisteredPopulation: "270",
    VotingStationName: "FLORENCE STORE",
    Address: "01 MAIN ROAD STREET  CHRISSIESMEER   CHRISSIESMEER",
    Latitude: "-26.28",
    Longitude: "30.2285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17333",
    WardLookupId: "2105",
    VDNumber: "54410497",
    RegisteredPopulation: "567",
    VotingStationName: "KWACHIBIKHULU PRIMARY SCHOOL",
    Address: "880 ISIDINGO LOCATION  ISIDINGO  CHRISSIESMEER",
    Latitude: "-26.2726",
    Longitude: "30.222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17334",
    WardLookupId: "2105",
    VDNumber: "54410509",
    RegisteredPopulation: "177",
    VotingStationName: "TENT AT PINE VALLEY",
    Address: "NGANGA LOCATION  ERMELO  WARBURTON",
    Latitude: "-26.2321",
    Longitude: "30.4638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17335",
    WardLookupId: "4151",
    VDNumber: "54470088",
    RegisteredPopulation: "2228",
    VotingStationName: "DRIEFONTEIN LOCAL OFFICES",
    Address: "CORNER STREET  MKHIZE VILLAGE  PIET RETIEF",
    Latitude: "-26.9938",
    Longitude: "30.4145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17336",
    WardLookupId: "4151",
    VDNumber: "54470099",
    RegisteredPopulation: "1128",
    VotingStationName: "ISIBANESEZWE PRIMARY SCHOOL",
    Address: "110 LINDELANI  DRIEFONTEIN  PIET RETIEF",
    Latitude: "-27.0184",
    Longitude: "30.4479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17337",
    WardLookupId: "4151",
    VDNumber: "54470190",
    RegisteredPopulation: "519",
    VotingStationName: "PHAPHAMANI PRIMARY SCHOOL",
    Address: "KWANGEMA NORTH  MKHONDO[PIET RETIEF]",
    Latitude: "-27.0204",
    Longitude: "30.4697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17338",
    WardLookupId: "4152",
    VDNumber: "54470077",
    RegisteredPopulation: "396",
    VotingStationName: "MOYENI LOWER PRIMARY SCHOOL",
    Address: "MKHIZE VILLAGE  DRIEFONTEIN  PIETRETIEF",
    Latitude: "-26.9167",
    Longitude: "30.3311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17339",
    WardLookupId: "4152",
    VDNumber: "54470156",
    RegisteredPopulation: "1394",
    VotingStationName: "SAKHISIZWE PRIMARY SCHOOL",
    Address: "110 LINDELANI  LINDELANI [DRIEFONTEIN]  MKHONDO [PIET RETIEF]",
    Latitude: "-26.973",
    Longitude: "30.4253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17340",
    WardLookupId: "4152",
    VDNumber: "54470167",
    RegisteredPopulation: "414",
    VotingStationName: "CABANGANI PRIMARY SCHOOL",
    Address: "EZIBOMVINI  DRIEFONTEIN  MKHONDO [PIET RETIEF]",
    Latitude: "-26.9914",
    Longitude: "30.4587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17341",
    WardLookupId: "4152",
    VDNumber: "54470178",
    RegisteredPopulation: "544",
    VotingStationName: "WELGEVOND PRIMARY SCHOOL",
    Address: "SHABALALA  MKHONDO [PIET RETIEF]",
    Latitude: "-26.9544",
    Longitude: "30.3948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17342",
    WardLookupId: "4152",
    VDNumber: "54470189",
    RegisteredPopulation: "526",
    VotingStationName: "JIKANTATHU COMBINED SCHOOL",
    Address: "12 PAMPOEN VILLAGE  MHKONDO  MKHONDO [PIET RETIEF]",
    Latitude: "-26.8475",
    Longitude: "30.3403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17343",
    WardLookupId: "4152",
    VDNumber: "54470202",
    RegisteredPopulation: "444",
    VotingStationName: "SUKUMANI SIBAMBANE FARMERS",
    Address: "DONKERHOEK  DRIEFONTEIN  PIETRETIEF",
    Latitude: "-27.0135",
    Longitude: "30.2981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17344",
    WardLookupId: "4153",
    VDNumber: "54440063",
    RegisteredPopulation: "354",
    VotingStationName: "NTITHANE PRIMARY SCHOOL",
    Address: "UITGEVALLEN FARM  MKHONDO",
    Latitude: "-27.1712",
    Longitude: "30.6703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17345",
    WardLookupId: "4153",
    VDNumber: "54440085",
    RegisteredPopulation: "1699",
    VotingStationName: "NGEMA COMBINED SCHOOL",
    Address: "NGEMA  KWANGEMA  DRIEFONTEIN",
    Latitude: "-27.0775",
    Longitude: "30.5143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17346",
    WardLookupId: "4153",
    VDNumber: "54440209",
    RegisteredPopulation: "1758",
    VotingStationName: "INJABULO COMBINED SCHOOL",
    Address: "DIERKESDORP[MABOLA]  MKHONDO [PIET RETIEF]",
    Latitude: "-27.1727",
    Longitude: "30.4026",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17347",
    WardLookupId: "4153",
    VDNumber: "54440221",
    RegisteredPopulation: "294",
    VotingStationName: "TSHONDO PRIMARY SCHOOL",
    Address: "JINDAL VILLAGE  MKHONDO  MKHONDO [PIET RETIEF]",
    Latitude: "-27.0742",
    Longitude: "30.5625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17348",
    WardLookupId: "4153",
    VDNumber: "54440276",
    RegisteredPopulation: "579",
    VotingStationName: "ETSHENI PRIMARY SCHOOL",
    Address: "KWANGEMA  MKHONDO[PIETRETIEF]",
    Latitude: "-27.0626",
    Longitude: "30.4624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17349",
    WardLookupId: "4154",
    VDNumber: "54440096",
    RegisteredPopulation: "1292",
    VotingStationName: "ESIBUSISWENI COMBINED SCHOOL",
    Address:
      "HARTEBEESFONTEIN FARM  HARTBEESFONTEIN FARM (R29)  MKHONDO[PIET RETIEF]",
    Latitude: "-26.7987",
    Longitude: "30.4749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17350",
    WardLookupId: "4154",
    VDNumber: "54440142",
    RegisteredPopulation: "2165",
    VotingStationName: "DRIEPAN PRIMARY SCHOOL",
    Address: "148 MAQOBONGWANE  ISWEPE  MKHONDO [PIET RETIEF]",
    Latitude: "-26.841",
    Longitude: "30.532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17351",
    WardLookupId: "4155",
    VDNumber: "54110023",
    RegisteredPopulation: "2567",
    VotingStationName: "KWATHANDEKA HALL",
    Address: "KWATHANDEKA  AMSTERDAM",
    Latitude: "-26.653",
    Longitude: "30.6819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17352",
    WardLookupId: "4155",
    VDNumber: "54110034",
    RegisteredPopulation: "1540",
    VotingStationName: "FRANS COMMUNITY HALL",
    Address: "AMSTERDAM  MKHONDO[PIET RETIEF]",
    Latitude: "-26.639",
    Longitude: "30.6572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17353",
    WardLookupId: "4156",
    VDNumber: "54440153",
    RegisteredPopulation: "1162",
    VotingStationName: "MLILO COMBINED SCHOOL",
    Address: "RUSTPLAAS FARM  PIET RETIEF DISTRICT  PIET RETIEF DISTRICT",
    Latitude: "-26.9045",
    Longitude: "30.7676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17354",
    WardLookupId: "4156",
    VDNumber: "54440164",
    RegisteredPopulation: "572",
    VotingStationName: "IDALIA PRIMARY SCHOOL",
    Address: "IDALIA  MKHONDO [PIET RETIEF]",
    Latitude: "-26.8913",
    Longitude: "30.6312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17355",
    WardLookupId: "4156",
    VDNumber: "54440175",
    RegisteredPopulation: "604",
    VotingStationName: "YELLOWSTONE PRIMARY SCHOOL",
    Address: "WOLWENKOP FARM  PIET RETIEF DISTRICT  PIET RETIEF DISTRICT",
    Latitude: "-26.7837",
    Longitude: "30.6825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17356",
    WardLookupId: "4156",
    VDNumber: "54440287",
    RegisteredPopulation: "163",
    VotingStationName: "UHLELO OPEN SPACE",
    Address: "IDALIA  PIET RETIEF  KWANDWALAZA",
    Latitude: "-26.8688",
    Longitude: "30.6501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17357",
    WardLookupId: "4156",
    VDNumber: "54440344",
    RegisteredPopulation: "271",
    VotingStationName: "ZIWELILE PRIMARY SCHOOL",
    Address: "AMAKHAYA FARM  MKHONDO",
    Latitude: "-26.9445",
    Longitude: "30.687",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17358",
    WardLookupId: "4156",
    VDNumber: "54440366",
    RegisteredPopulation: "290",
    VotingStationName: "MEHLWEMAMBA PRIMARY SCHOOL",
    Address: "HAARLEM FARM  MEHLWEMAMBA  PIET RETIEF",
    Latitude: "-26.8351",
    Longitude: "30.7906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17359",
    WardLookupId: "4156",
    VDNumber: "54440377",
    RegisteredPopulation: "435",
    VotingStationName: "HOLDESHEIM PRIMARY SCHOOL",
    Address: "KWADLOTHOVU  PIET RETIEF  PIET RETIEF",
    Latitude: "-26.86",
    Longitude: "30.7082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17360",
    WardLookupId: "4156",
    VDNumber: "54440401",
    RegisteredPopulation: "289",
    VotingStationName: "DERBY PRIMARY SCHOOL",
    Address: "DERBY  ",
    Latitude: "-26.8688",
    Longitude: "30.7986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17361",
    WardLookupId: "4156",
    VDNumber: "54440423",
    RegisteredPopulation: "214",
    VotingStationName: "SIYEZA PRIMARY SCHOOL",
    Address: "WATERSIDE  PIET RETIEF",
    Latitude: "-26.7954",
    Longitude: "30.6242",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17362",
    WardLookupId: "4157",
    VDNumber: "54320015",
    RegisteredPopulation: "2057",
    VotingStationName: "PIET RETIEF PRIMARY SCHOOL",
    Address: "CHURCH STREET  PIET RETIEF",
    Latitude: "-27",
    Longitude: "30.8011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17363",
    WardLookupId: "4157",
    VDNumber: "54320105",
    RegisteredPopulation: "1889",
    VotingStationName: "MARANATHA COMBINED SCHOOL",
    Address: "KRUGER STREET  PIET RETIEF  PIET RETIEF",
    Latitude: "-27.005",
    Longitude: "30.8066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17364",
    WardLookupId: "4158",
    VDNumber: "54440120",
    RegisteredPopulation: "665",
    VotingStationName: "KLEINVRYSTAAT PRIMARY SCHOOL",
    Address: "VAALKOP VILLAGE  KLEINVRYSTAAT  MKHONDO",
    Latitude: "-26.9123",
    Longitude: "30.9112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17365",
    WardLookupId: "4158",
    VDNumber: "54440131",
    RegisteredPopulation: "706",
    VotingStationName: "KEMP SIDING SCHOOL",
    Address: "VROEGVELD FARM  KEMPSIDING  PIET RETIEF",
    Latitude: "-26.9533",
    Longitude: "30.7656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17366",
    WardLookupId: "4158",
    VDNumber: "54440186",
    RegisteredPopulation: "876",
    VotingStationName: "MAVIMBELA STATION GARAGE",
    Address: "ATHALIA  PIET RETIEF  PIET RETIEF",
    Latitude: "-26.9735",
    Longitude: "30.9278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17367",
    WardLookupId: "4158",
    VDNumber: "54440298",
    RegisteredPopulation: "367",
    VotingStationName: "NOKUTHULA PRIMARY SCHOOL",
    Address: "SGUDADA  MKHONDO",
    Latitude: "-26.8932",
    Longitude: "30.8484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17368",
    WardLookupId: "4158",
    VDNumber: "54440355",
    RegisteredPopulation: "1031",
    VotingStationName: "AJAX RIVER OF LIFE CHURCH",
    Address: "AJAX FARM  PIET RETIEF  PIET RETIEF",
    Latitude: "-26.9618",
    Longitude: "30.7946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17369",
    WardLookupId: "4158",
    VDNumber: "54440412",
    RegisteredPopulation: "399",
    VotingStationName: "KEMPSIDING LOCAL CHURCH",
    Address: "EMAPHEPHENI  KEMPSIDING  PIET RETIEF",
    Latitude: "-26.9565",
    Longitude: "30.7763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17370",
    WardLookupId: "4159",
    VDNumber: "54440018",
    RegisteredPopulation: "787",
    VotingStationName: "DELFKOM PRIMARY SCHOOL",
    Address: "KLIPVAL  PIET RETIEF",
    Latitude: "-27.3074",
    Longitude: "31.1644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17371",
    WardLookupId: "4159",
    VDNumber: "54440029",
    RegisteredPopulation: "344",
    VotingStationName: "MANTONGA PRIMARY SCHOOL",
    Address: "MONTONGA FARM  PIET RETIEF   PIET RETIEF",
    Latitude: "-27.3093",
    Longitude: "31.0175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17372",
    WardLookupId: "4159",
    VDNumber: "54440030",
    RegisteredPopulation: "1078",
    VotingStationName: "BLOEMENDAL PRIMARY SCHOOL",
    Address: "BLOEMENDAL FARM  PIET RETIEF   PIET RETIEF",
    Latitude: "-27.1612",
    Longitude: "31.0197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17373",
    WardLookupId: "4159",
    VDNumber: "54440210",
    RegisteredPopulation: "507",
    VotingStationName: "BERGPLAAS PRIMARY SCHOOL",
    Address: "BERGPLAAS VILLAGE  KWANDWALAZA  CHIEF MAHLOBO TRIBAL AUTHORITY",
    Latitude: "-27.254",
    Longitude: "31.259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17374",
    WardLookupId: "4159",
    VDNumber: "54440300",
    RegisteredPopulation: "526",
    VotingStationName: "CANA HIGH SCHOOL",
    Address: "SULPHURSPRINGS FARM  SULPHURESPRINGS   PIET RETIEF",
    Latitude: "-27.2241",
    Longitude: "31.1155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17375",
    WardLookupId: "4159",
    VDNumber: "54440311",
    RegisteredPopulation: "528",
    VotingStationName: "MOOLMAN PRIMARY SCHOOL",
    Address: "STEENKOPPIES FARM  MOOLMAN  PIET RETEIF",
    Latitude: "-27.138",
    Longitude: "30.9006",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17376",
    WardLookupId: "4159",
    VDNumber: "54440388",
    RegisteredPopulation: "366",
    VotingStationName: "BERBICE PRIMARY SCHOOL",
    Address: "BERBICE FARM  KLIPVAL  PIET RETIEF",
    Latitude: "-27.1736",
    Longitude: "31.0961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17377",
    WardLookupId: "4160",
    VDNumber: "54320026",
    RegisteredPopulation: "2128",
    VotingStationName: "KEMPVILLE COMMUNITY HALL",
    Address: "COMMERCIAL ROAD  KEMPVILLE  PIET RETIEF",
    Latitude: "-27.0227",
    Longitude: "30.8044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17378",
    WardLookupId: "4160",
    VDNumber: "54320093",
    RegisteredPopulation: "1092",
    VotingStationName: "THANDUKUKHANYA OLD AGE HOME",
    Address: "EZINDLOVINI  MKHONDO [PIET RETIEF]",
    Latitude: "-27.0197",
    Longitude: "30.7884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17379",
    WardLookupId: "4161",
    VDNumber: "54320037",
    RegisteredPopulation: "1231",
    VotingStationName: "NDAWONYE PRIMARY SCHOOL",
    Address: "KHUMALO STREET  ETHANDAKUKHANYA  PIET RETIEF",
    Latitude: "-27.0228",
    Longitude: "30.7816",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17380",
    WardLookupId: "4161",
    VDNumber: "54320116",
    RegisteredPopulation: "664",
    VotingStationName: "LUTHERAN CHURCH",
    Address: "MAGADENI  PIET RETIEF  MKHONDO",
    Latitude: "-27.0179",
    Longitude: "30.7824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17381",
    WardLookupId: "4161",
    VDNumber: "54440322",
    RegisteredPopulation: "1655",
    VotingStationName: "APOSTOLIC FAITH MISSION",
    Address: "SBETHA  THANDUKUKHANYA  MKHONDO",
    Latitude: "-27.0222",
    Longitude: "30.7782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17382",
    WardLookupId: "4161",
    VDNumber: "54440399",
    RegisteredPopulation: "963",
    VotingStationName: "EZIPHUNZINI SPACE (TENT)",
    Address: "EZIPHUNZINI AREA  THNDUKUKHANYA  MKHONDO",
    Latitude: "-27.0291",
    Longitude: "30.763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17383",
    WardLookupId: "4162",
    VDNumber: "54320048",
    RegisteredPopulation: "1778",
    VotingStationName: "ETHANDAKUKHANYA COMMUNITY HALL",
    Address: "DHLAMINI STREET  ETHANDAKUKHANYA  PIET RETIEF",
    Latitude: "-27.0285",
    Longitude: "30.7818",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17384",
    WardLookupId: "4162",
    VDNumber: "54320127",
    RegisteredPopulation: "1491",
    VotingStationName: "AMADLELO ALUHLAZA HIGH SCHOOL",
    Address: "LONG HOMES  THANDUKUKHANYA  MKHONDO",
    Latitude: "-27.0319",
    Longitude: "30.7845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17385",
    WardLookupId: "4163",
    VDNumber: "54320059",
    RegisteredPopulation: "1119",
    VotingStationName: "SWEDISH FREE CHURCH",
    Address: "XULU STREET  ETHANDAKUKHANYA  PIET RETIEF",
    Latitude: "-27.026",
    Longitude: "30.7782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17386",
    WardLookupId: "4163",
    VDNumber: "54320082",
    RegisteredPopulation: "1709",
    VotingStationName: "ST JONES APOSTOLIC FAITH MISSION",
    Address: "SBETHAMOYA  ETHANDAKUKHANYA  PIET RETIEF",
    Latitude: "-27.0272",
    Longitude: "30.7741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17387",
    WardLookupId: "4163",
    VDNumber: "54320172",
    RegisteredPopulation: "907",
    VotingStationName: "PROGRESS CRECHE",
    Address: "ETHANDAKUKHANYA  PIET RETIEF  PIET RETIEF",
    Latitude: "-27.0369",
    Longitude: "30.7768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17388",
    WardLookupId: "4164",
    VDNumber: "54320071",
    RegisteredPopulation: "2262",
    VotingStationName: "RIVER OF LIFE CHRISTIAN CENTRE",
    Address: "KOTZE STREET  HARMONY PARK  MKHONDO [PIET RETIEF]",
    Latitude: "-27.0173",
    Longitude: "30.8088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17389",
    WardLookupId: "4164",
    VDNumber: "54320138",
    RegisteredPopulation: "1994",
    VotingStationName: "MOVING CHURCH",
    Address: "KWANTOMBAZANE  THANDUKUKHANYA  PIET RETIEF",
    Latitude: "-27.0433",
    Longitude: "30.7822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17390",
    WardLookupId: "4165",
    VDNumber: "54440041",
    RegisteredPopulation: "894",
    VotingStationName: "KWAMATSHAMHLOPHE PRIMARY SCHOOL",
    Address: "WITKLIP FARM  KWAMATSHAMHLOPHE  PIET RETIEF",
    Latitude: "-27.2821",
    Longitude: "30.9732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17391",
    WardLookupId: "4165",
    VDNumber: "54440052",
    RegisteredPopulation: "361",
    VotingStationName: "EKUPHILENI PRIMARY SCHOOL",
    Address: "DE MOOLEN FARM  PIET RETIEF DISTRICT  PIET RETIEF DISTRICT",
    Latitude: "-27.2918",
    Longitude: "30.5827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17392",
    WardLookupId: "4165",
    VDNumber: "54440074",
    RegisteredPopulation: "526",
    VotingStationName: "LAERSKOOL WITTENBERG",
    Address: "WITTENBERG FARM  NEAR WITTENBERG ROAD  PIET RETIEF DISTRICT",
    Latitude: "-27.128",
    Longitude: "30.7913",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17393",
    WardLookupId: "4165",
    VDNumber: "54440232",
    RegisteredPopulation: "513",
    VotingStationName: "NEDERLAND PRIMARY SCHOOL",
    Address: "BENGAL VILLAGE  MKHONDO  MKHONDO [PIET RETIEF]",
    Latitude: "-27.3013",
    Longitude: "30.8385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17394",
    WardLookupId: "4165",
    VDNumber: "54440243",
    RegisteredPopulation: "368",
    VotingStationName: "MISPAH PRIMARY SCHOOL",
    Address: "BEYERS FARM  BEYERS  MKHONDO [PIET RETIEF]",
    Latitude: "-27.0482",
    Longitude: "30.8471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17395",
    WardLookupId: "4165",
    VDNumber: "54440254",
    RegisteredPopulation: "165",
    VotingStationName: "SALEM COMBINED SCHOOL",
    Address: "SALEM MISSION  MKHONDO [PIET RETIEF]",
    Latitude: "-27.0753",
    Longitude: "30.7975",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17396",
    WardLookupId: "4165",
    VDNumber: "54440333",
    RegisteredPopulation: "1154",
    VotingStationName: "ZENDELINGSPOST COMBINED SCHOOL",
    Address: "EMTOMBE MISSION LUNEBERG  ENTOMBE MISSION  MKHONDO",
    Latitude: "-27.2804",
    Longitude: "30.6787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17397",
    WardLookupId: "4166",
    VDNumber: "54320060",
    RegisteredPopulation: "3077",
    VotingStationName: "IMIZAMOYETHU PRIMARY SCHOOL",
    Address: "F PHOLA PARK  PIET RETEIF",
    Latitude: "-27.0392",
    Longitude: "30.7775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17398",
    WardLookupId: "4166",
    VDNumber: "54320149",
    RegisteredPopulation: "908",
    VotingStationName: "KHANYISA DAY CARE CRECHE",
    Address: "5898 PHOLA PARK  PHOLA PARK  PIET RETIEF",
    Latitude: "-27.0452",
    Longitude: "30.7785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17399",
    WardLookupId: "4167",
    VDNumber: "54320150",
    RegisteredPopulation: "1634",
    VotingStationName: "KIDDIES DAY CARE",
    Address: "3904 NOMANDIYA STREET  PHOSWA  PIET RETIEF",
    Latitude: "-27.0332",
    Longitude: "30.7742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17400",
    WardLookupId: "4167",
    VDNumber: "54320161",
    RegisteredPopulation: "965",
    VotingStationName: "MELOKUHLA DAY CARE",
    Address: "MANGOSUTHU  THANDUKUKHANYA  PIET RETIEF",
    Latitude: "-27.0433",
    Longitude: "30.7714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17401",
    WardLookupId: "4167",
    VDNumber: "54440119",
    RegisteredPopulation: "870",
    VotingStationName: "NKONJANENI CRECHE",
    Address: "40B EZINKONJANENI  EZINKONJANENI  PIET RETIEF",
    Latitude: "-26.9965",
    Longitude: "30.7762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17402",
    WardLookupId: "4167",
    VDNumber: "54440197",
    RegisteredPopulation: "477",
    VotingStationName: "WEEBER SCHOOL",
    Address: "MADABUKELA FARM  PIET RETIEF",
    Latitude: "-27.0611",
    Longitude: "30.7044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17403",
    WardLookupId: "4168",
    VDNumber: "54470044",
    RegisteredPopulation: "406",
    VotingStationName: "OTHANDWENI CENTRE",
    Address: "KWANGEMA DRIEFONTIEN ROAD  HEYSHOPE  MKHONDO[PIET RETIEF]",
    Latitude: "-26.9772",
    Longitude: "30.5214",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17404",
    WardLookupId: "4168",
    VDNumber: "54470112",
    RegisteredPopulation: "1617",
    VotingStationName: "QEDELA HIGH SCHOOL",
    Address: "MKHIZE VILLAGE  DRIEFONTEIN  PIET RTIEF",
    Latitude: "-26.9813",
    Longitude: "30.4529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17405",
    WardLookupId: "4168",
    VDNumber: "54470213",
    RegisteredPopulation: "631",
    VotingStationName: "MPUMALANGA APOSTOLIC CHURCH IN ZION",
    Address: "EZAKHENI OLD STAND  DRIEFONTEIN  PIET RETIEF",
    Latitude: "-26.9841",
    Longitude: "30.4625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17406",
    WardLookupId: "4169",
    VDNumber: "54110012",
    RegisteredPopulation: "1518",
    VotingStationName: "AMSTERDAM COMMUNITY HALL",
    Address: "PRESIDENT STREET  ARMSTERDAM  MKHONDO[PIET RETIEF]",
    Latitude: "-26.6225",
    Longitude: "30.6613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17407",
    WardLookupId: "4169",
    VDNumber: "54410172",
    RegisteredPopulation: "1124",
    VotingStationName: "KAMNIGO PRIMARY SCHOOL",
    Address: "KAMNIGO  KAMNIGO VILLAGE  AMSTERDAM",
    Latitude: "-26.5898",
    Longitude: "30.7267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17408",
    WardLookupId: "4169",
    VDNumber: "54410183",
    RegisteredPopulation: "277",
    VotingStationName: "GLEN AGGY CHURCH",
    Address: "GLEN AGGY  AMSTERDAM  AMSTERDAM",
    Latitude: "-26.5573",
    Longitude: "30.6377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17409",
    WardLookupId: "4169",
    VDNumber: "54410295",
    RegisteredPopulation: "302",
    VotingStationName: "BEKETELANI PRIMARY SCHOOL",
    Address: "STAFORD VILLAGE  AMSTERDAM  PIET RETIEF",
    Latitude: "-26.532",
    Longitude: "30.7449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17410",
    WardLookupId: "4169",
    VDNumber: "54410307",
    RegisteredPopulation: "296",
    VotingStationName: "NSEPHE COMMUNITY CENTRE",
    Address: "DAVID FORBES  AMSTERDAM  PIET RETIEF",
    Latitude: "-26.6385",
    Longitude: "30.5662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17411",
    WardLookupId: "4170",
    VDNumber: "54340017",
    RegisteredPopulation: "1830",
    VotingStationName: "VUKUZAKHE MULTIPURPOSE HALL",
    Address: "MAVUSO STREET   VUKUZAKHE TOWNSHIP  PIXLEY KA SEME",
    Latitude: "-27.362",
    Longitude: "29.9036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17412",
    WardLookupId: "4170",
    VDNumber: "54340084",
    RegisteredPopulation: "2844",
    VotingStationName: "QHUBULWAZI SECONDARY SCHOOL",
    Address: "VUKUZAKHE EXTENSION 2  VUKUZAKHE  VOLKSRUST",
    Latitude: "-27.3628",
    Longitude: "29.9074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17413",
    WardLookupId: "4171",
    VDNumber: "54340039",
    RegisteredPopulation: "969",
    VotingStationName: "VUKUZAKHE COMMUNITY HALL",
    Address: "C/O MAVUSO & MADONSELA STREET  VUKUZAKHE  VOLKSRUST",
    Latitude: "-27.3694",
    Longitude: "29.901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17414",
    WardLookupId: "4171",
    VDNumber: "54340040",
    RegisteredPopulation: "1297",
    VotingStationName: "ZIZAMELENI PRIMARY SCHOOL",
    Address: "C/O SHABALALA & KHUMALO STREET  VUKUZAKHE  VOLKSRUST",
    Latitude: "-27.366",
    Longitude: "29.9045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17415",
    WardLookupId: "4172",
    VDNumber: "54340051",
    RegisteredPopulation: "1973",
    VotingStationName: "ELANGWANE SCHOOL",
    Address: "WESELI STREET  VUKUZAKHE  VOLKSRUST",
    Latitude: "-27.373",
    Longitude: "29.9027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17416",
    WardLookupId: "4172",
    VDNumber: "54340062",
    RegisteredPopulation: "648",
    VotingStationName: "VUKUZAKHE METHODIST CHURCH",
    Address: "WESSEL STREET   VOLKSRUST   VUKUZAKHE",
    Latitude: "-27.372",
    Longitude: "29.8984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17417",
    WardLookupId: "4172",
    VDNumber: "54340095",
    RegisteredPopulation: "399",
    VotingStationName: "LAINGSNEK RECREATIONAL CENTRE",
    Address: "RAILWAY STREET  VOLKSRUST  VOLKSRUST",
    Latitude: "-27.3731",
    Longitude: "29.8878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17418",
    WardLookupId: "4173",
    VDNumber: "54340028",
    RegisteredPopulation: "1552",
    VotingStationName: "VOLKSRUST TOWN HALL",
    Address: "CNR JOUBERT AND LAINGSNEK STR  VOLKSRUST  VOLKSRUST",
    Latitude: "-27.368",
    Longitude: "29.8846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17419",
    WardLookupId: "4173",
    VDNumber: "54340073",
    RegisteredPopulation: "2427",
    VotingStationName: "PIONEER LAERSKOOL",
    Address: "10 SCHOON & ZONDO STREET  VOLKSRUST  PIXLEY KA SEME",
    Latitude: "-27.366",
    Longitude: "29.8792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17420",
    WardLookupId: "4173",
    VDNumber: "54460010",
    RegisteredPopulation: "257",
    VotingStationName: "IZINHLIZIYO SCHOOL",
    Address: "WELVERDIEND FARM  VOLKSRUST  VOLKSRUST",
    Latitude: "-27.3274",
    Longitude: "29.714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17421",
    WardLookupId: "4173",
    VDNumber: "54460043",
    RegisteredPopulation: "358",
    VotingStationName: "LOUWRA PRIMARY SCHOOL",
    Address: "OUDEHOUTKLOOF FARM  VOLKSRUST  VOLKSRUST",
    Latitude: "-27.2581",
    Longitude: "29.8848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17422",
    WardLookupId: "4174",
    VDNumber: "54350018",
    RegisteredPopulation: "1125",
    VotingStationName: "UNITING REFORMED CHURCH",
    Address: "MNDEBELE ST  ESIZAMELENI  WAKKERSTROOM",
    Latitude: "-27.35",
    Longitude: "30.1568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17423",
    WardLookupId: "4174",
    VDNumber: "54350029",
    RegisteredPopulation: "1344",
    VotingStationName: "ESIZAMELENI COMMUNITY HALL",
    Address: "MNDEBELE ST  ESIZAMELENI  WAKKERSTROOM",
    Latitude: "-27.3507",
    Longitude: "30.1635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17424",
    WardLookupId: "4174",
    VDNumber: "54350030",
    RegisteredPopulation: "995",
    VotingStationName: "WAKKERSTROOM TOWN HALL",
    Address: "153 VAN RIEBEECK ST  WAKKERSTROOM  WAKKERSTROOM",
    Latitude: "-27.3552",
    Longitude: "30.1433",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17425",
    WardLookupId: "4175",
    VDNumber: "54310014",
    RegisteredPopulation: "2191",
    VotingStationName: "SIYAZENZELA COMMUNITY HALL",
    Address: "SIYAZENZELA SPORTGRONDE  PERDEKOP  PERDEKOP",
    Latitude: "-27.1544",
    Longitude: "29.6308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17426",
    WardLookupId: "4175",
    VDNumber: "54310025",
    RegisteredPopulation: "741",
    VotingStationName: "PERDEKOP TOWN HALL",
    Address: "DURBAN STREET  VOLKSRUST  PERDEKOP",
    Latitude: "-27.1691",
    Longitude: "29.6277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17427",
    WardLookupId: "4175",
    VDNumber: "54460021",
    RegisteredPopulation: "187",
    VotingStationName: "BUTHANA PRIMARY SCHOOL",
    Address: "HONNINGVALLEI 104 HS  PERDEKOP  PERDEKOP",
    Latitude: "-27.283",
    Longitude: "29.5316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17428",
    WardLookupId: "4175",
    VDNumber: "54460032",
    RegisteredPopulation: "183",
    VotingStationName: "BAMBELELANI PRIMARY SCHOOL",
    Address: "HOLFONTEIN FARM  VOLKSRUST  VOLKSRUST",
    Latitude: "-27.1755",
    Longitude: "29.7493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17429",
    WardLookupId: "4175",
    VDNumber: "54460087",
    RegisteredPopulation: "181",
    VotingStationName: "BEKAPHEZULU SCHOOL",
    Address: "HARTBEESFONTEIN 134 HS  VOLKSRUST  VOLKSRUST",
    Latitude: "-27.4384",
    Longitude: "29.6355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17430",
    WardLookupId: "4176",
    VDNumber: "54100022",
    RegisteredPopulation: "1582",
    VotingStationName: "AMERSFOORT AGRICULTURAL HALL",
    Address: "PLEIN STR  AMERSFOORT   PIXLEY KA SEME MUNIC",
    Latitude: "-27.0054",
    Longitude: "29.8742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17431",
    WardLookupId: "4176",
    VDNumber: "54100033",
    RegisteredPopulation: "1997",
    VotingStationName: "AMERSFOORT LOCAL COUNCIL",
    Address: "607 PHONANGWANE STR  EZAMOKUHLE PROPER  AMERSFOORT",
    Latitude: "-27.0006",
    Longitude: "29.8504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17432",
    WardLookupId: "4176",
    VDNumber: "54100055",
    RegisteredPopulation: "98",
    VotingStationName: "KWASKHOVA JUNIOR SECONDARY SCHOOL",
    Address: "ELANDANDSBERG FARM 371 IT  AMERSFOORT  AMERSFOORT",
    Latitude: "-26.9299",
    Longitude: "30.0243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17433",
    WardLookupId: "4176",
    VDNumber: "54410060",
    RegisteredPopulation: "122",
    VotingStationName: "BHULOHOLIGWA SCHOOL",
    Address: "BEHENDELYN FARM  AMERSFOORT  AMERSFOORT",
    Latitude: "-26.7765",
    Longitude: "30.0011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17434",
    WardLookupId: "4177",
    VDNumber: "54100011",
    RegisteredPopulation: "1652",
    VotingStationName: "AMERSFOORT COMBINED SCHOOL",
    Address: "212 PHOLANGWANE STR  AMERSFOORT  AMERSFOORT",
    Latitude: "-26.9978",
    Longitude: "29.8448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17435",
    WardLookupId: "4177",
    VDNumber: "54100044",
    RegisteredPopulation: "791",
    VotingStationName: "EZAMOKUHLE COMM HALL",
    Address: "EZAMOKUHLE  AMERSFOORT  EZAMOKUHLE AMERSFOORT",
    Latitude: "-26.9983",
    Longitude: "29.8476",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17436",
    WardLookupId: "4177",
    VDNumber: "54360020",
    RegisteredPopulation: "319",
    VotingStationName: "INJUBUKU PRIMARY SCHOOL",
    Address: "KLIPPLAATDRIF FARM  KLIPPLAATDRIF FARM 504 IS  AMERSFOORT",
    Latitude: "-26.9146",
    Longitude: "29.8971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17437",
    WardLookupId: "4177",
    VDNumber: "54360042",
    RegisteredPopulation: "269",
    VotingStationName: "SANQOTHO PRIMARY SCHOOL",
    Address: "MOOIGENOEG FARM  MOOIGENOEG FARM 525 IS  AMERSFOORT",
    Latitude: "-26.9416",
    Longitude: "29.7662",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17438",
    WardLookupId: "4177",
    VDNumber: "54360121",
    RegisteredPopulation: "97",
    VotingStationName: "UKULUNGA PRIMARY SCHOOL",
    Address: "N/A  AMERSFOORT  AMERSFOORT",
    Latitude: "-27.0554",
    Longitude: "29.7742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17439",
    WardLookupId: "4178",
    VDNumber: "54360019",
    RegisteredPopulation: "1458",
    VotingStationName: "DAGGAKRAAL COMMUNITY CENTRE",
    Address: "SINQOBILE A  DAGGAKRAAL  AMERSFOORT",
    Latitude: "-27.1272",
    Longitude: "29.9692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17440",
    WardLookupId: "4178",
    VDNumber: "54360031",
    RegisteredPopulation: "433",
    VotingStationName: "ETEMBENI PRIMARY SCHOOL",
    Address: "STAND 141  DAGGAKRAAL NO 2  AMERSFOORT",
    Latitude: "-27.11",
    Longitude: "29.9857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17441",
    WardLookupId: "4178",
    VDNumber: "54360110",
    RegisteredPopulation: "1157",
    VotingStationName: "DAGGAKRAAL MULTIPURPOSE HALL",
    Address: "DAGGAKRAAL NO 1  VLAKPOORT  DAGGAKRAAL",
    Latitude: "-27.1306",
    Longitude: "29.9824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17442",
    WardLookupId: "4179",
    VDNumber: "54360053",
    RegisteredPopulation: "580",
    VotingStationName: "KALKOENKRANS PRIMARY SCHOOL",
    Address: "KALKOENKRANS FARM  AMERSFOORT",
    Latitude: "-26.9317",
    Longitude: "30.1069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17443",
    WardLookupId: "4179",
    VDNumber: "54360097",
    RegisteredPopulation: "1617",
    VotingStationName: "VLAKPOORT STORE",
    Address: "FARM VLAKPOORT 89  AMERSFOORT",
    Latitude: "-27.1461",
    Longitude: "29.9794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17444",
    WardLookupId: "4179",
    VDNumber: "54360109",
    RegisteredPopulation: "538",
    VotingStationName: "SEME SECONDARY SCHOOL",
    Address: "DAGGAKRAAL NO 1  DAGGAKRAAL  DAGGAKRAAL",
    Latitude: "-27.0914",
    Longitude: "30.0081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17445",
    WardLookupId: "4179",
    VDNumber: "54460076",
    RegisteredPopulation: "219",
    VotingStationName: "SIGWINYAMANA (TENT)",
    Address: "LANGBERG 85 HT  WAKKERSTROOM  WAKKERSTROOM",
    Latitude: "-27.2299",
    Longitude: "30.0645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17446",
    WardLookupId: "4179",
    VDNumber: "54470011",
    RegisteredPopulation: "178",
    VotingStationName: "LANGFONTEIN FARM",
    Address: "LANGFONTEIN 84 HT  WAKKERSTROOM  WAKKERSTROOM",
    Latitude: "-27.2629",
    Longitude: "30.1465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17447",
    WardLookupId: "4179",
    VDNumber: "54470055",
    RegisteredPopulation: "196",
    VotingStationName: "GOUDHOEK FARM",
    Address: "GOUDHOEK 124 HT  WAKKERSTROOM  WAKKERSTROOM",
    Latitude: "-27.3103",
    Longitude: "30.2313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17448",
    WardLookupId: "4179",
    VDNumber: "54470134",
    RegisteredPopulation: "381",
    VotingStationName: "MADLANGAMPISI TRADITIONAL AUTHORITY",
    Address: "POORTJIE 32 HT  WAKKERSTROOM  WAKKERSTROOM",
    Latitude: "-27.0856",
    Longitude: "30.3507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17449",
    WardLookupId: "4179",
    VDNumber: "54470145",
    RegisteredPopulation: "256",
    VotingStationName: "BHEKITHEMBA PRIMARY SCHOOL",
    Address: "ZUURBRON/PAARDEPLAATZ  WAKKERSTROOM  SEME",
    Latitude: "-27.2906",
    Longitude: "30.4421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17450",
    WardLookupId: "4180",
    VDNumber: "54360064",
    RegisteredPopulation: "1477",
    VotingStationName: "SIMTHOLILE DAY CARE CENTRE",
    Address: "DAGGAKRAAL  AMERSFOORT  DAGGAKRAAL",
    Latitude: "-27.1475",
    Longitude: "29.9517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17451",
    WardLookupId: "4180",
    VDNumber: "54360075",
    RegisteredPopulation: "660",
    VotingStationName: "NALITUBA SECONDARY SCHOOL",
    Address: "NEXT TO STAND 102  DAGGAKRAAL NO 3  DAGGAKRAAL",
    Latitude: "-27.1387",
    Longitude: "29.9423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17452",
    WardLookupId: "4180",
    VDNumber: "54360086",
    RegisteredPopulation: "1137",
    VotingStationName: "THULASIZWE HALL",
    Address: "STAND 34  DAGGAKRAAL NO 3  AMERSFOORT",
    Latitude: "-27.1231",
    Longitude: "29.9315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17453",
    WardLookupId: "2106",
    VDNumber: "54330072",
    RegisteredPopulation: "2631",
    VotingStationName: "ST JOHNS CHURCH",
    Address: "2373/1 GIVUNJATHE STR  SAKHILE  STANDERTON",
    Latitude: "-26.9685",
    Longitude: "29.1996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17454",
    WardLookupId: "2106",
    VDNumber: "54330195",
    RegisteredPopulation: "530",
    VotingStationName: "HLOBISA PRIMARY SCHOOL",
    Address: "1495 ZULU STREET  SAKHILE  STANDERTON",
    Latitude: "-26.9662",
    Longitude: "29.202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17455",
    WardLookupId: "2107",
    VDNumber: "54330094",
    RegisteredPopulation: "1441",
    VotingStationName: "LINDELANGA PRIMARY SCHOOL",
    Address: "2712 GATSHENI STR  SAKHILE  STANDERTON",
    Latitude: "-26.9715",
    Longitude: "29.2029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17456",
    WardLookupId: "2107",
    VDNumber: "54330106",
    RegisteredPopulation: "1565",
    VotingStationName: "THANDEKA PRIMARY SCHOOL",
    Address: "3064 SHABANGU STREET  SAKHILE  STANDERTON",
    Latitude: "-26.9696",
    Longitude: "29.2087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17457",
    WardLookupId: "2108",
    VDNumber: "54330050",
    RegisteredPopulation: "1126",
    VotingStationName: "AZALEA COMMUNITY HALL",
    Address: "10 SYDNEY DE LANGE STREET  AZALEA  STANDERTON",
    Latitude: "-26.9556",
    Longitude: "29.229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17458",
    WardLookupId: "2108",
    VDNumber: "54330162",
    RegisteredPopulation: "1884",
    VotingStationName: "STANWEST COMMUNITY HALL",
    Address: "5 TALJAARD STREET  STANWEST  STANDERTON",
    Latitude: "-26.9613",
    Longitude: "29.2263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17459",
    WardLookupId: "2108",
    VDNumber: "54330207",
    RegisteredPopulation: "850",
    VotingStationName: "KHULA-SAKHILE SECONDARY SCHOOL",
    Address: "3689 PALM STREET  SAKHILE  STANDERTON",
    Latitude: "-26.9628",
    Longitude: "29.2228",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17460",
    WardLookupId: "2109",
    VDNumber: "54330049",
    RegisteredPopulation: "2005",
    VotingStationName: "KALIE DE HAAS PRIMARY SCHOOL",
    Address: "42 NELSON MANDELA DRIVE  MEYERVILLE  STANDERTON",
    Latitude: "-26.9699",
    Longitude: "29.2554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17461",
    WardLookupId: "2109",
    VDNumber: "54330218",
    RegisteredPopulation: "741",
    VotingStationName: "GODS`S LOVE ASSEMBLY",
    Address: "9883 SANTA ROAD  STANDERTON  SAKHILE",
    Latitude: "-26.9676",
    Longitude: "29.2261",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17462",
    WardLookupId: "2110",
    VDNumber: "54330061",
    RegisteredPopulation: "1738",
    VotingStationName: "HOLY CATHOLIC APOSTOLIC IMPUMALANGA CHURCH IN ZION",
    Address: "3279 BHENGU STR  SAKHILE  STANDERTON",
    Latitude: "-26.9628",
    Longitude: "29.2074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17463",
    WardLookupId: "2110",
    VDNumber: "54330117",
    RegisteredPopulation: "2160",
    VotingStationName: "LESEDI PRIMARY SCHOOL",
    Address: "2071 MONAHENG STR  SAKHILE  STANDERTON",
    Latitude: "-26.9673",
    Longitude: "29.2102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17464",
    WardLookupId: "2111",
    VDNumber: "54330027",
    RegisteredPopulation: "1201",
    VotingStationName: "SAKHILE COMMUNITY HALL",
    Address: "65 PHIRI STREET  SAKHILE  STANDERTON",
    Latitude: "-26.9652",
    Longitude: "29.2199",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17465",
    WardLookupId: "2111",
    VDNumber: "54330128",
    RegisteredPopulation: "1308",
    VotingStationName: "MADI COMBINED SCHOOL",
    Address: "1220 MOKOENA STREET  SAKHILE  STANDERTON",
    Latitude: "-26.9708",
    Longitude: "29.2228",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17466",
    WardLookupId: "2111",
    VDNumber: "54330140",
    RegisteredPopulation: "797",
    VotingStationName: "FAITH MISSION CHURCH",
    Address: "180 KUBEKA STREET  SAKHILE  STANDERTON",
    Latitude: "-26.9676",
    Longitude: "29.2165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17467",
    WardLookupId: "2112",
    VDNumber: "54330139",
    RegisteredPopulation: "2150",
    VotingStationName: "THUTO-THEBE SECONDARY SCHOOL",
    Address: "607 RDP HOUSE EXT 1  STANDERTON  LEKWA",
    Latitude: "-26.9473",
    Longitude: "29.2205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17468",
    WardLookupId: "2112",
    VDNumber: "54330184",
    RegisteredPopulation: "1283",
    VotingStationName: "COJ SERVICE CHURCH",
    Address: "0 ROBERTSDRIFT ROAD  SAKHILE  STANDERTON",
    Latitude: "-26.9502",
    Longitude: "29.2063",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17469",
    WardLookupId: "2112",
    VDNumber: "54330229",
    RegisteredPopulation: "1070",
    VotingStationName: "MASIBAMBANENI DAY CARE CENTRE",
    Address: "5903 EXTENTION 6  STANDERTON",
    Latitude: "-26.9419",
    Longitude: "29.2039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17470",
    WardLookupId: "2113",
    VDNumber: "54330016",
    RegisteredPopulation: "2754",
    VotingStationName: "JEUGKRAG PRIMARY SCHOOL",
    Address: "2 ESDOORN AVENUE  EXTENSION 3  STANDERTON",
    Latitude: "-26.9241",
    Longitude: "29.2377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17471",
    WardLookupId: "2113",
    VDNumber: "54330173",
    RegisteredPopulation: "734",
    VotingStationName: "KHUNJULIWE SECONDARY SCHOOL",
    Address: "01 EXTENTION 8  STANDERTON  STANDERTON",
    Latitude: "-26.9272",
    Longitude: "29.2052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17472",
    WardLookupId: "2113",
    VDNumber: "54330241",
    RegisteredPopulation: "510",
    VotingStationName: "CHRISTIAN CHURCH",
    Address: "23 SPRINGBOK STREET  KOSMOS PARK  STANDERTON",
    Latitude: "-26.9191",
    Longitude: "29.2227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17473",
    WardLookupId: "2113",
    VDNumber: "54450020",
    RegisteredPopulation: "375",
    VotingStationName: "GROOTDRAAI DAM COMMUNITY HALL",
    Address: "GROOTDRAAI DAM  WATER AFFAIRS  STANDERTON",
    Latitude: "-26.8994",
    Longitude: "29.2955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17474",
    WardLookupId: "2114",
    VDNumber: "54450031",
    RegisteredPopulation: "418",
    VotingStationName: "CIJA PRIMARY SCHOOL",
    Address: "R 547 ROAD NEAR VAL  ROODEBANK  STANDERTON",
    Latitude: "-26.6681",
    Longitude: "28.9858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17475",
    WardLookupId: "2114",
    VDNumber: "54450064",
    RegisteredPopulation: "1072",
    VotingStationName: "HOLMDENE SENIOR SECONDARY SCHOOL",
    Address: "19P JONKERSDAM ROAD  HOLMDENE  STANDERTON",
    Latitude: "-26.8552",
    Longitude: "29.0682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17476",
    WardLookupId: "2114",
    VDNumber: "54450154",
    RegisteredPopulation: "302",
    VotingStationName: "KLIPPOORT SCHOOL",
    Address: "KLIPPOORT FARM  VAL  STANDERTON",
    Latitude: "-26.985",
    Longitude: "28.9522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17477",
    WardLookupId: "2114",
    VDNumber: "54450165",
    RegisteredPopulation: "178",
    VotingStationName: "GOEDGEWOON SCHOOL",
    Address: "FARM GOEDGEVONDEN  GOEDGEVONDEN  STANDERTON",
    Latitude: "-27.0091",
    Longitude: "29.1454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17478",
    WardLookupId: "2114",
    VDNumber: "54450200",
    RegisteredPopulation: "162",
    VotingStationName: "J W CRONJE FARM",
    Address: "0 NEXT TO R546  SPRIMGBOK FONTEIN  STANDERTON",
    Latitude: "-26.7902",
    Longitude: "29.2075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17479",
    WardLookupId: "2114",
    VDNumber: "54450222",
    RegisteredPopulation: "332",
    VotingStationName: "EARLYBIRD PRIMARY SCHOOL",
    Address:
      "EARLYBIRD FARM ELINTREE ROAD   EARLYBIRD FARM RURAL AREA  STANDERTON",
    Latitude: "-26.909",
    Longitude: "29.1817",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17480",
    WardLookupId: "2115",
    VDNumber: "54330038",
    RegisteredPopulation: "2831",
    VotingStationName: "STANDERTON TOWN HALL",
    Address: "18 & 20 ANDRIES PRETORIUS STR  STANDERTON  STANDERTON",
    Latitude: "-26.9517",
    Longitude: "29.2436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17481",
    WardLookupId: "2116",
    VDNumber: "54450187",
    RegisteredPopulation: "2741",
    VotingStationName: "SHUKUMA PRIMARY SCHOOL",
    Address: "00 GOEDGEWOON  SAKHILE  LEKWA [STANDERTON]",
    Latitude: "-26.9854",
    Longitude: "29.2127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17482",
    WardLookupId: "2116",
    VDNumber: "54450211",
    RegisteredPopulation: "2281",
    VotingStationName: "ROOIKOPPEN COMMUNITY CENTRE",
    Address: "01 MAIN ROAD  SAKHILE /ROOIKOPPEN  STANDERTON",
    Latitude: "-26.9911",
    Longitude: "29.2084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17483",
    WardLookupId: "2117",
    VDNumber: "54380033",
    RegisteredPopulation: "255",
    VotingStationName: "KWAGGALAAGTE STATE AIDED SCHOOL",
    Address: "FARM SUKKELAAR 421IS  STANDERTON  ROAD P48-1 N/S",
    Latitude: "-26.5816",
    Longitude: "29.532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17484",
    WardLookupId: "2117",
    VDNumber: "54410059",
    RegisteredPopulation: "316",
    VotingStationName: "MOBILE VOTING STATION (BRAKFONTEIN FARM)",
    Address: "R39 STANDERTON TO ERMELO ROAD  MORGENZON  LEKWA/STANDERTON",
    Latitude: "-26.6763",
    Longitude: "29.7355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17485",
    WardLookupId: "2117",
    VDNumber: "54450075",
    RegisteredPopulation: "2100",
    VotingStationName: "JABULA CRESCENT PRE-SCHOOL",
    Address: "427 JABULA CRESCENT  THUTHUKANI  STANDERTON",
    Latitude: "-26.7862",
    Longitude: "29.3035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17486",
    WardLookupId: "2117",
    VDNumber: "54450176",
    RegisteredPopulation: "204",
    VotingStationName: "AMALUNGELO SCHOOL",
    Address: "CROSSING HENDRIKSPAN AND JOUBE  MORGENZON  LEKWA [STANDERTON]",
    Latitude: "-26.7334",
    Longitude: "29.4536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17487",
    WardLookupId: "2117",
    VDNumber: "54450198",
    RegisteredPopulation: "111",
    VotingStationName: "KHOLISA PRIMARY SCHOOL",
    Address: "01 GROOTFOMTEIM PUMP STATION  JOUBERTSVLEI  LEKWA [STANDERTON]",
    Latitude: "-26.6764",
    Longitude: "29.2795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17488",
    WardLookupId: "2118",
    VDNumber: "54450019",
    RegisteredPopulation: "1359",
    VotingStationName: "QONDULWAZI SECONDARY SCHOOL",
    Address: "R23 PLATRAND TURN OFF  PLATRAND  LEKWA [STANDERTON]",
    Latitude: "-27.11",
    Longitude: "29.451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17489",
    WardLookupId: "2118",
    VDNumber: "54450053",
    RegisteredPopulation: "342",
    VotingStationName: "IMISEBEYELANGA SECONDARY SCHOOL",
    Address: "DEKUILEN ROAD / DE KUILEN FARM  DEKUILEN  STANDERTON",
    Latitude: "-27.0881",
    Longitude: "29.1731",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17490",
    WardLookupId: "2118",
    VDNumber: "54450086",
    RegisteredPopulation: "553",
    VotingStationName: "ERDZAK PRIMARY SCHOOL",
    Address: "VREDE ROAD  EARLYBIRD FARM  STANDERTON",
    Latitude: "-26.9896",
    Longitude: "29.253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17491",
    WardLookupId: "2118",
    VDNumber: "54450143",
    RegisteredPopulation: "324",
    VotingStationName: "JG GRABE FARM BUILDING",
    Address: "FARM VILLIERA  VILLIERIA  STANDERTON",
    Latitude: "-26.9471",
    Longitude: "29.5368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17492",
    WardLookupId: "2119",
    VDNumber: "54300013",
    RegisteredPopulation: "2412",
    VotingStationName: "NQOBANGOLWAZI SCHOOL HALL",
    Address: "455 BRITS STREET  MORGENZON  STANDERTON",
    Latitude: "-26.7377",
    Longitude: "29.6152",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17493",
    WardLookupId: "2119",
    VDNumber: "54300024",
    RegisteredPopulation: "1333",
    VotingStationName: "SIZAKHELE PRIMARY SCHOOL",
    Address: "BRITS AND DOYER STR  SIZAKHELE (MORGENZON)  LEKWA [STANDERTON]",
    Latitude: "-26.7345",
    Longitude: "29.6072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17494",
    WardLookupId: "2120",
    VDNumber: "54330151",
    RegisteredPopulation: "2791",
    VotingStationName: "ISIFISOSETHU PRIMARY SCHOOL",
    Address: "3439 EXT 6  EXTENTION 6  LEKWA [STANDERTON]",
    Latitude: "-26.939",
    Longitude: "29.2136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17495",
    WardLookupId: "2120",
    VDNumber: "54330230",
    RegisteredPopulation: "1511",
    VotingStationName: "COMMUNITY CENTRE EXTENTION 6",
    Address: "01  MAIN ROAD EXT 6  STANDERTON  SAKHILE",
    Latitude: "-26.9425",
    Longitude: "29.2218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17496",
    WardLookupId: "2121",
    VDNumber: "54130025",
    RegisteredPopulation: "1078",
    VotingStationName: "SETSHENG SECONDARY SCHOOL",
    Address: "1061 NZIMANDE STR  SIYATHEMBA  BALFOUR",
    Latitude: "-26.6458",
    Longitude: "28.6137",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17497",
    WardLookupId: "2121",
    VDNumber: "54130070",
    RegisteredPopulation: "1433",
    VotingStationName: "QALABOCHA PRIMARY SCHOOL",
    Address: "387 NHLAPO STR  SIYATHEMBA  DIPALESENG [BALFOUR]",
    Latitude: "-26.6484",
    Longitude: "28.6103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17498",
    WardLookupId: "2121",
    VDNumber: "54130104",
    RegisteredPopulation: "895",
    VotingStationName: "NEW SALEMA CHURCH",
    Address:
      "5257 5257 EXT 7 SIYATHEMBA BALFOUR  SIYATHEMBA TOWNSHIP  MP306 - DIPALESENG [BALFOUR]",
    Latitude: "-26.6415",
    Longitude: "28.6185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17499",
    WardLookupId: "2121",
    VDNumber: "54130148",
    RegisteredPopulation: "517",
    VotingStationName: "SAINT PETERS CHURCH IN ZION",
    Address: "4862 EXT 5  SIYATHEMBA  BALFOUR",
    Latitude: "-26.6524",
    Longitude: "28.6073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17500",
    WardLookupId: "2122",
    VDNumber: "54130047",
    RegisteredPopulation: "1903",
    VotingStationName: "ISIFISO SETHU SECONDARY SCHOOL",
    Address: "3338 MANDELA SECTION  SIYATHEMBA  BALFOUR",
    Latitude: "-26.6535",
    Longitude: "28.6168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17501",
    WardLookupId: "2122",
    VDNumber: "54130092",
    RegisteredPopulation: "712",
    VotingStationName: "THE FIRST ZION CHRISTIAN SALEMA EKUPHUMLENI CHURCH",
    Address: "187 MASHININI STREET  SIYATHEMBA  BALFOUR",
    Latitude: "-26.6511",
    Longitude: "28.6115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17502",
    WardLookupId: "2122",
    VDNumber: "54130137",
    RegisteredPopulation: "671",
    VotingStationName: "GOOD HOPE DAY CARE CENTRE",
    Address: "1165 SIYATHEMBA  BALFOUR",
    Latitude: "-26.6503",
    Longitude: "28.6182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17503",
    WardLookupId: "2122",
    VDNumber: "54370054",
    RegisteredPopulation: "194",
    VotingStationName: "MODDERBULT PRIMARY SCHOOL",
    Address: "MODDERBULT FARM  BALFOUR RURAL  BALFOUR",
    Latitude: "-26.5344",
    Longitude: "28.664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17504",
    WardLookupId: "2123",
    VDNumber: "54130036",
    RegisteredPopulation: "1504",
    VotingStationName: "NG KERK HALL",
    Address: "112 THEMBA SHOZI STREET  BALFOUR  BALFOUR",
    Latitude: "-26.6639",
    Longitude: "28.5887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17505",
    WardLookupId: "2123",
    VDNumber: "54130115",
    RegisteredPopulation: "476",
    VotingStationName: "DEPARTMENT OF SOCIAL DEVELOPMENT",
    Address: "695 MAQHAWE STR  BALFOUR  BALFOUR",
    Latitude: "-26.6531",
    Longitude: "28.5928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17506",
    WardLookupId: "2123",
    VDNumber: "54130171",
    RegisteredPopulation: "89",
    VotingStationName: "SUNFIELD HOME FORTUNA RIETFONTEIN",
    Address: "RIETFONTEIN FARM  BALFOUR  RIETFONTEIN FARM",
    Latitude: "-26.6051",
    Longitude: "28.5502",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17507",
    WardLookupId: "2123",
    VDNumber: "54240027",
    RegisteredPopulation: "539",
    VotingStationName: "GREYLINGSTAD MUNICIPAL BUILDING",
    Address: "601 MAIN STR  GREYLINGSTAD  GREYLINGSTAD",
    Latitude: "-26.7448",
    Longitude: "28.7446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17508",
    WardLookupId: "2123",
    VDNumber: "54370065",
    RegisteredPopulation: "93",
    VotingStationName: "PHIWAMANDLA FARM SCHOOL",
    Address: "WOLVENFONTEIN  ON OFF ROAD P90-2  BALFOUR DISTRICT",
    Latitude: "-26.9602",
    Longitude: "28.6835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17509",
    WardLookupId: "2123",
    VDNumber: "54370098",
    RegisteredPopulation: "117",
    VotingStationName: "SOPHIA PRIMARY SCHOOL",
    Address: "FARM DASPOORT 564IR  BALFOUR DISTRICT ON  DISTRICT ROAD 308",
    Latitude: "-26.7384",
    Longitude: "28.6262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17510",
    WardLookupId: "2124",
    VDNumber: "54130014",
    RegisteredPopulation: "1424",
    VotingStationName: "BONUKUKHANYA PRIMARY SCHOOL",
    Address: "2678 MONARENG STR  SIYATHEMBA  BALFOUR",
    Latitude: "-26.6564",
    Longitude: "28.6109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17511",
    WardLookupId: "2124",
    VDNumber: "54130081",
    RegisteredPopulation: "1014",
    VotingStationName: "SIYATHEMBA THUSONG COMMUNITY CENTRE",
    Address: "5080 EAST STREET SIYATHEMBA  SIYATHEMBA  BALFOUR",
    Latitude: "-26.6554",
    Longitude: "28.6032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17512",
    WardLookupId: "2124",
    VDNumber: "54130126",
    RegisteredPopulation: "552",
    VotingStationName: "SHILO CRECHE",
    Address: "2801 MONARENG STREET  SIYATHEMBA  BALFOUR",
    Latitude: "-26.6592",
    Longitude: "28.6094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17513",
    WardLookupId: "2124",
    VDNumber: "54130159",
    RegisteredPopulation: "593",
    VotingStationName: "MAHON EVANGELICAL CHURCH IN SA",
    Address: "3030 MANDELA SEC  SIYATHEMBA  BALFOUR",
    Latitude: "-26.66",
    Longitude: "28.611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17514",
    WardLookupId: "2124",
    VDNumber: "54130160",
    RegisteredPopulation: "660",
    VotingStationName: "THEMBALETHU DAY CARE CENTRE",
    Address: "6212 SLOVO SECT  SIYATHEMBA  BALFOUR",
    Latitude: "-26.6607",
    Longitude: "28.6041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17515",
    WardLookupId: "2125",
    VDNumber: "54250017",
    RegisteredPopulation: "715",
    VotingStationName: "JOY DAY CARE CENTRE",
    Address: "128 CENTRAL  STR  GROOTVLEI  GROOTVLEI",
    Latitude: "-26.8042",
    Longitude: "28.505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17516",
    WardLookupId: "2125",
    VDNumber: "54250028",
    RegisteredPopulation: "1438",
    VotingStationName: "TSHEPEHA SECONDARY SCHOOL",
    Address: "44 GROOTVLEI EXT 1  EXT 1  GROOTVLEI",
    Latitude: "-26.7942",
    Longitude: "28.5079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17517",
    WardLookupId: "2125",
    VDNumber: "54370021",
    RegisteredPopulation: "46",
    VotingStationName: "MOUNT HERMON",
    Address: "RUSFONTEIN  BALFOUR DISTRICT OFF  BALFOUR",
    Latitude: "-26.8201",
    Longitude: "28.6188",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17518",
    WardLookupId: "2125",
    VDNumber: "54370032",
    RegisteredPopulation: "992",
    VotingStationName: "GROOTVLEI ESKOM HALL",
    Address: "H SECTION  GROOTVLEI  BALFOUR",
    Latitude: "-26.7654",
    Longitude: "28.4842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17519",
    WardLookupId: "2125",
    VDNumber: "54370076",
    RegisteredPopulation: "108",
    VotingStationName: "TIKELO PRIMARY SCHOOL",
    Address: "DIPALESENG   ",
    Latitude: "-26.8193",
    Longitude: "28.3258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17520",
    WardLookupId: "2125",
    VDNumber: "54370087",
    RegisteredPopulation: "56",
    VotingStationName: "OHLANGENI FARM SCHOOL",
    Address: "FARM RIETFONTEIN  GROOTVLEI  BALFOUR",
    Latitude: "-26.9573",
    Longitude: "28.4942",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17521",
    WardLookupId: "2126",
    VDNumber: "54240016",
    RegisteredPopulation: "1801",
    VotingStationName: "MLAMLANKUNZI PRIMARY SCHOOL",
    Address: "127 TWALA STREET  GREYLINGSTAD  GREYLINGSTAD",
    Latitude: "-26.7642",
    Longitude: "28.7764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17522",
    WardLookupId: "2126",
    VDNumber: "54240038",
    RegisteredPopulation: "1351",
    VotingStationName: "NTHOROANA NEW COMMUNITY HALL",
    Address: "MAIN STREET  NTHOROANA   NTHOROANA TOWNSHIP",
    Latitude: "-26.7596",
    Longitude: "28.7721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17523",
    WardLookupId: "2126",
    VDNumber: "54240049",
    RegisteredPopulation: "99",
    VotingStationName: "MAHEMSFONTEIN PRIMARY SCHOOL",
    Address: "MAHEMSFONTEIN FARM  GREYLINGSTAD  NTHOROANE",
    Latitude: "-26.7038",
    Longitude: "28.8606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17524",
    WardLookupId: "2126",
    VDNumber: "54450042",
    RegisteredPopulation: "365",
    VotingStationName: "LAWRESTON PRIMARY SCHOOL",
    Address: "LOWRESTON FARM  GREYLINGSTAD  BALFOUR",
    Latitude: "-26.7717",
    Longitude: "28.8623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17525",
    WardLookupId: "4181",
    VDNumber: "54280021",
    RegisteredPopulation: "2250",
    VotingStationName: "SIDUNGULWAZI PRIMARY SCHOOL",
    Address: "2973 4 AVE, EXT 13  LEBOHANG, EXT 13  LEANDRA",
    Latitude: "-26.3779",
    Longitude: "28.9114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17526",
    WardLookupId: "4181",
    VDNumber: "54280065",
    RegisteredPopulation: "1041",
    VotingStationName: "LAERSKOOL LEANDRA",
    Address: "CIVIC LANE STREET  LESLIE  LEANDRA",
    Latitude: "-26.3712",
    Longitude: "28.9174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17527",
    WardLookupId: "4181",
    VDNumber: "54280122",
    RegisteredPopulation: "158",
    VotingStationName: "EENDRACHT LICENSING OFFICES",
    Address: "604 ADRIANA STREET  LEANDRA  EENDRACHT",
    Latitude: "-26.3825",
    Longitude: "28.8829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17528",
    WardLookupId: "4181",
    VDNumber: "54420038",
    RegisteredPopulation: "322",
    VotingStationName: "ZITHOBE PRIMARY SCHOOL",
    Address:
      "FARM MOEDVERLOREN  88-15 HIGHVELD RIDGE DISTRICT  HIGHVELD RIDGEHIGHVELD RIDGE",
    Latitude: "-26.2791",
    Longitude: "29.0272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17529",
    WardLookupId: "4181",
    VDNumber: "54420049",
    RegisteredPopulation: "122",
    VotingStationName: "MADOLO PRIMARY SCHOOL",
    Address: "PAARDEFONTEIN FARM  ",
    Latitude: "-26.5023",
    Longitude: "28.8236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17530",
    WardLookupId: "4182",
    VDNumber: "54280076",
    RegisteredPopulation: "2514",
    VotingStationName: "LEANDRA RDP COMMUNITY HALL",
    Address: "VILAKAZI AVENUE  LEBOHANG  GOVAN MBEKI [HIGHVELD RIDGE]",
    Latitude: "-26.3806",
    Longitude: "28.9374",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17531",
    WardLookupId: "4182",
    VDNumber: "54280100",
    RegisteredPopulation: "1006",
    VotingStationName: "ANGLICAN CHURCH",
    Address: "1117 EXT 17  LEBOHANG T/SHIP  LEANDRA",
    Latitude: "-26.3827",
    Longitude: "28.9296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17532",
    WardLookupId: "4182",
    VDNumber: "54420162",
    RegisteredPopulation: "1167",
    VotingStationName: "UNITED CHURCH OF CHRIST",
    Address: "6622 EXT 17, LEBOHANG TOWN  LEANDRA RDP EXTENSION  SECUNDA",
    Latitude: "-26.3835",
    Longitude: "28.9464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17533",
    WardLookupId: "4183",
    VDNumber: "54280010",
    RegisteredPopulation: "1579",
    VotingStationName: "P E MAZIYA PRIMARY SCHOOL",
    Address: "842 INTUMBANE DRIVE, EXT 7  LEBOHANG  LEANDRA",
    Latitude: "-26.3852",
    Longitude: "28.9226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17534",
    WardLookupId: "4183",
    VDNumber: "54280032",
    RegisteredPopulation: "2340",
    VotingStationName: "MPUMELELO PRIMARY SCHOOL",
    Address: "101 ISIKHOVA STREET  LEBOHANG PROP  LEANDRA",
    Latitude: "-26.3823",
    Longitude: "28.9151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17535",
    WardLookupId: "4183",
    VDNumber: "54280098",
    RegisteredPopulation: "419",
    VotingStationName: "SABATH CHURCH",
    Address: "1704, EXTENSION 11  LESLIE  GOVAN MBEKI",
    Latitude: "-26.3819",
    Longitude: "28.9211",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17536",
    WardLookupId: "4184",
    VDNumber: "54260119",
    RegisteredPopulation: "2208",
    VotingStationName: "THOLUKWAZI PRIMARY SCHOOL",
    Address: "2052 MAKHUBU DRIVE  EMBALENHLE  HIGHVELD RIDGE",
    Latitude: "-26.5433",
    Longitude: "29.0658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17537",
    WardLookupId: "4184",
    VDNumber: "54260142",
    RegisteredPopulation: "1942",
    VotingStationName: "MBALENHLE PRIMARY SCHOOL",
    Address: "MASILELA STR  EMBALENHLE  HIGHVELD RIDGE",
    Latitude: "-26.5424",
    Longitude: "29.0654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17538",
    WardLookupId: "4185",
    VDNumber: "54260052",
    RegisteredPopulation: "3219",
    VotingStationName: "LAERSKOOL ORANJEGLOED",
    Address: "25 PIETER WENNING STR  SECUNDA  SECUNDA",
    Latitude: "-26.5213",
    Longitude: "29.2027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17539",
    WardLookupId: "4185",
    VDNumber: "54420128",
    RegisteredPopulation: "242",
    VotingStationName: "MUZIMUHLE COMBINED SCHOOL",
    Address: "SHAFT NO. 1 WINKELHAAK MINE  TWISDRAAI  EVANDER",
    Latitude: "-26.5089",
    Longitude: "29.1312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17540",
    WardLookupId: "4186",
    VDNumber: "54280043",
    RegisteredPopulation: "1639",
    VotingStationName: "DIFA NKOSI COMMUNITY HALL",
    Address: "1095 INTUMBANE DRIVE  LEBOHANG EXT 9  LEANDRA",
    Latitude: "-26.3765",
    Longitude: "28.9221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17541",
    WardLookupId: "4186",
    VDNumber: "54280087",
    RegisteredPopulation: "757",
    VotingStationName: "LEANDRA HEALTH CARE CENTRE_TENT",
    Address: "326 EXTENTION 11  LEBOHANG  LEANDRA",
    Latitude: "-26.3755",
    Longitude: "28.9221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17542",
    WardLookupId: "4186",
    VDNumber: "54280111",
    RegisteredPopulation: "488",
    VotingStationName: "DUTCH CHURCH",
    Address: "1566 EXT 09  LEBOHANG  LEANDRA",
    Latitude: "-26.3801",
    Longitude: "28.9248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17543",
    WardLookupId: "4187",
    VDNumber: "54260096",
    RegisteredPopulation: "2130",
    VotingStationName: "KIRIYATSWANE HIGH SCHOOL",
    Address: "469 THABABOSIU AVENUE, EXTENSION 9  EMBALENHLE  GOVAN MBEKI",
    Latitude: "-26.5336",
    Longitude: "29.0875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17544",
    WardLookupId: "4187",
    VDNumber: "54260377",
    RegisteredPopulation: "1347",
    VotingStationName: "LIFALETHU PRIMARY SCHOOL",
    Address: "2869 FONO SIBIYA DRIVE  EMBALENHLE  EMBALENHLE",
    Latitude: "-26.5377",
    Longitude: "29.0789",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17545",
    WardLookupId: "4188",
    VDNumber: "54260186",
    RegisteredPopulation: "2236",
    VotingStationName: "VUKUZITHATHE PRIMARY SCHOOL",
    Address: "11232 ALBERT LUTHULI DRIVE  EMBALENHLE  EMBALENHLE",
    Latitude: "-26.5676",
    Longitude: "29.083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17546",
    WardLookupId: "4188",
    VDNumber: "54260197",
    RegisteredPopulation: "2282",
    VotingStationName: "LETHUKUPHILA HOME BASE (EXT14)",
    Address: "11269 JOHNSON MLAMBO CRESENT  EXT 14   EMBALENHLE",
    Latitude: "-26.5684",
    Longitude: "29.0781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17547",
    WardLookupId: "4188",
    VDNumber: "54260344",
    RegisteredPopulation: "522",
    VotingStationName: "LESEDI NURSERY SCHOOL",
    Address: "CNR B HOLOMISA AND MCWAYIZENI STREET  EMBALENHLE  GOVAN MBEKI",
    Latitude: "-26.5693",
    Longitude: "29.0821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17548",
    WardLookupId: "4189",
    VDNumber: "54260265",
    RegisteredPopulation: "2014",
    VotingStationName: "SASOL CLUB HALL",
    Address: "2937 INGWE DRIVE  EMBALENHLE  SECUNDA",
    Latitude: "-26.5493",
    Longitude: "29.0744",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17549",
    WardLookupId: "4189",
    VDNumber: "54420139",
    RegisteredPopulation: "2096",
    VotingStationName: "AGCI CHURCH",
    Address: "1115 EXT 22   EMBALENHLE  EMBALENHLE",
    Latitude: "-26.5653",
    Longitude: "29.0673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17550",
    WardLookupId: "4189",
    VDNumber: "54420184",
    RegisteredPopulation: "756",
    VotingStationName: "SIYAQHAKAZA DAY CARE CENTRE",
    Address: "597  EXTENSION 22  EMBALENHLE",
    Latitude: "-26.5663",
    Longitude: "29.0609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17551",
    WardLookupId: "4190",
    VDNumber: "54260153",
    RegisteredPopulation: "2679",
    VotingStationName: "VUKUKHANYE CRECHE",
    Address: "EMBALENHLE TOWNSHIP  ",
    Latitude: "-26.5688",
    Longitude: "29.096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17552",
    WardLookupId: "4190",
    VDNumber: "54260243",
    RegisteredPopulation: "986",
    VotingStationName: "CHARL CILLIERS MULTI-PURPOSE HALL",
    Address: "CILLIERS STREET  CILLIERS  CHARL",
    Latitude: "-26.6623",
    Longitude: "29.1831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17553",
    WardLookupId: "4190",
    VDNumber: "54260298",
    RegisteredPopulation: "1767",
    VotingStationName: "SUNRISE DAYCARE CENTRE",
    Address: "15445 EXTENSION 15  EMBALENHLE  GOVAN MBEKI",
    Latitude: "-26.5707",
    Longitude: "29.0929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17554",
    WardLookupId: "4190",
    VDNumber: "54450097",
    RegisteredPopulation: "414",
    VotingStationName: "BRANDSPRUIT MINE HALL (SASOL MINE)",
    Address: "BRANDSPRUIT MAIN SHAFT  SECUNDA  SECUNDA",
    Latitude: "-26.5899",
    Longitude: "29.1482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17555",
    WardLookupId: "4191",
    VDNumber: "54260120",
    RegisteredPopulation: "1973",
    VotingStationName: "KUSASALETHU COMPREHENSIVE SCHOOL",
    Address: "4067 INGWEMPISI ROAD  EMBALENHLE  HIGHVELD RIDGE",
    Latitude: "-26.5421",
    Longitude: "29.08",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17556",
    WardLookupId: "4191",
    VDNumber: "54260232",
    RegisteredPopulation: "2371",
    VotingStationName: "BUYANI PRIMARY SCHOOL",
    Address: "MAKHUBO DRIVE  EMBALENHLE  HIGHVELD RIDGE",
    Latitude: "-26.5371",
    Longitude: "29.0728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17557",
    WardLookupId: "4191",
    VDNumber: "54260311",
    RegisteredPopulation: "756",
    VotingStationName: "THORISONG PRIMARY SCHOOL",
    Address:
      "STAND 2029 CNR KHUBEKA AND SIBIYA STREET EXTENSION 7  EMBALENHLE  GOVAN MBEKI",
    Latitude: "-26.5401",
    Longitude: "29.0743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17558",
    WardLookupId: "4191",
    VDNumber: "54260355",
    RegisteredPopulation: "770",
    VotingStationName: "THOMAS NHLABATHI SEC SCHOOL",
    Address: "2100 DR NGWASHENG  EMBALENHLE  SECUNDA",
    Latitude: "-26.5428",
    Longitude: "29.0711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17559",
    WardLookupId: "4192",
    VDNumber: "54260287",
    RegisteredPopulation: "2681",
    VotingStationName: "IBANDLA LIKA KRESTU JESU JJM",
    Address: "18684 CHRI HANI EXT 19  EMBALENHLE  EMBALENHLE",
    Latitude: "-26.5573",
    Longitude: "29.0905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17560",
    WardLookupId: "4192",
    VDNumber: "54260333",
    RegisteredPopulation: "1163",
    VotingStationName: "ST AUGUSTINE ROMAN CATHOLIC CHURCH",
    Address: "N/A  HIGHVELD RIDGE  HIGHVELD RIDGE",
    Latitude: "-26.5622",
    Longitude: "29.085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17561",
    WardLookupId: "4193",
    VDNumber: "54260221",
    RegisteredPopulation: "2257",
    VotingStationName: "ISIPHEPHELO MULTI PURPOSE CENTER",
    Address: "23146 23146 STAND EXT 14  EMBALENHLE  SECUNDA",
    Latitude: "-26.5615",
    Longitude: "29.0744",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17562",
    WardLookupId: "4193",
    VDNumber: "54260401",
    RegisteredPopulation: "1187",
    VotingStationName: "KI THWALA SECONDARY SCHOOL",
    Address: "1084 EXTENSION 14  EMBALENHLE  GOVAN MBEKI",
    Latitude: "-26.5706",
    Longitude: "29.0754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17563",
    WardLookupId: "4193",
    VDNumber: "54260490",
    RegisteredPopulation: "639",
    VotingStationName: "WINGS OF LOVE",
    Address: "9836 EXT14  EMBALENHLE  EMBALENHLE",
    Latitude: "-26.5655",
    Longitude: "29.0755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17564",
    WardLookupId: "4194",
    VDNumber: "54260210",
    RegisteredPopulation: "2326",
    VotingStationName: "CHURCH OF JESUS CHRIST EXT14",
    Address: "20746 EXT 18  EMBALENHLE  EMBALENHLE",
    Latitude: "-26.5592",
    Longitude: "29.077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17565",
    WardLookupId: "4194",
    VDNumber: "54260412",
    RegisteredPopulation: "1684",
    VotingStationName: "SIQALO SEMFUNDO",
    Address: "EXT 14 EMBALENHLET /SHIP  EMBALENHLE  EMBALENHLE",
    Latitude: "-26.5544",
    Longitude: "29.0804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17566",
    WardLookupId: "4194",
    VDNumber: "54260524",
    RegisteredPopulation: "647",
    VotingStationName: "EMBALENHLE UXOLO LWETHU BAKERY HALL",
    Address: "1105 EXT 14  EMBALENHLE  EMBALENHLE",
    Latitude: "-26.564",
    Longitude: "29.0794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17567",
    WardLookupId: "4195",
    VDNumber: "54140037",
    RegisteredPopulation: "1350",
    VotingStationName: "SAKHISIZWE PRIMARY SCHOOL",
    Address: "3886 KANANA EXT 4  EMZINONI  BETHAL",
    Latitude: "-26.4918",
    Longitude: "29.4283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17568",
    WardLookupId: "4195",
    VDNumber: "54140093",
    RegisteredPopulation: "2059",
    VotingStationName: "BETHAL TOWN HALL",
    Address: "CNR DANIE NORTJE & MARKET STR  BETHAL  BETHAL",
    Latitude: "-26.4566",
    Longitude: "29.4608",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17569",
    WardLookupId: "4195",
    VDNumber: "54380011",
    RegisteredPopulation: "373",
    VotingStationName: "HIRSAW ESTATE",
    Address: "VLAKKUILEN FARM  BETHAL  ",
    Latitude: "-26.2732",
    Longitude: "29.4568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17570",
    WardLookupId: "4195",
    VDNumber: "54380044",
    RegisteredPopulation: "279",
    VotingStationName: "PHILANI DAY CARE CENTRE",
    Address: "06 NAUDIASFONTEIN FARM  BETHAL  BETHAL",
    Latitude: "-26.4429",
    Longitude: "29.5314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17571",
    WardLookupId: "4195",
    VDNumber: "54380055",
    RegisteredPopulation: "300",
    VotingStationName: "ENKUNDLENI HIGHER PRIMARY SCHOOL",
    Address: "FARM KAFFERSTAD 79-IS  BETHAL DISTRICT COR  ROAD D420 & P52-3",
    Latitude: "-26.3435",
    Longitude: "29.3597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17572",
    WardLookupId: "4195",
    VDNumber: "54380066",
    RegisteredPopulation: "226",
    VotingStationName: "KHUPHUKANI PRIMARY SCHOOL",
    Address:
      "FARM RUSTFONTEIN 109IS  BETHAL DISTRICT ON  ROAD 450 6KM OFF ROAD P5-2",
    Latitude: "-26.429",
    Longitude: "29.3921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17573",
    WardLookupId: "4195",
    VDNumber: "54380077",
    RegisteredPopulation: "231",
    VotingStationName: "THANDA_BANTU COMMUNITY (TENT)",
    Address: "299 FARM STERKFONTEIN  BETHAL  BETHAL",
    Latitude: "-26.5964",
    Longitude: "29.4392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17574",
    WardLookupId: "4196",
    VDNumber: "54260254",
    RegisteredPopulation: "2137",
    VotingStationName: "THISTLE GROVE COMBINED SCHOOL",
    Address: "8 HADEDA STREET  KINROSS  HIGHVELD RIDGE",
    Latitude: "-26.4105",
    Longitude: "29.0756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17575",
    WardLookupId: "4196",
    VDNumber: "54260300",
    RegisteredPopulation: "3758",
    VotingStationName: "KINROSS PRIMARY SCHOOL",
    Address: "STAND 4298 EXTENSION 25  KINROSS  GOVAN MBEKI",
    Latitude: "-26.4056",
    Longitude: "29.067",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17576",
    WardLookupId: "4196",
    VDNumber: "54420173",
    RegisteredPopulation: "44",
    VotingStationName: "EMDIBINI COMBINED SCHOOL",
    Address: "GROOTPAN ROAD  SECUNDA  VAALBANK FARM",
    Latitude: "-26.3879",
    Longitude: "29.0998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17577",
    WardLookupId: "4196",
    VDNumber: "54420195",
    RegisteredPopulation: "388",
    VotingStationName: "LIVING WATERS MINISTRY",
    Address: "MARIKANA  EXT 25  KINROSS",
    Latitude: "-26.3991",
    Longitude: "29.0583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17578",
    WardLookupId: "4197",
    VDNumber: "54260063",
    RegisteredPopulation: "2382",
    VotingStationName: "TP STRATTEN PRIMARY SCHOOL",
    Address: "22 AACHEN STR  EVANDER  HIGHVELD RIDGE",
    Latitude: "-26.4747",
    Longitude: "29.1095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17579",
    WardLookupId: "4197",
    VDNumber: "54260209",
    RegisteredPopulation: "1019",
    VotingStationName: "LAERSKOOL KINROSS",
    Address: "3RD AVENUE  KINROSS  KINROSS",
    Latitude: "-26.4196",
    Longitude: "29.0949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17580",
    WardLookupId: "4197",
    VDNumber: "54260276",
    RegisteredPopulation: "837",
    VotingStationName: "EVANDER GOLD MINES HOSTEL",
    Address: "NO 7 SHAFT  EVANDER GOLD MINES  HIGHVELD RIDGE",
    Latitude: "-26.4707",
    Longitude: "29.0941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17581",
    WardLookupId: "4197",
    VDNumber: "54260502",
    RegisteredPopulation: "321",
    VotingStationName: "LAERSKOOL KINROSS SPORTS FIELDS",
    Address: "1 KROON STREET  KINROSS  KINROSS",
    Latitude: "-26.4219",
    Longitude: "29.1029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17582",
    WardLookupId: "4198",
    VDNumber: "54260074",
    RegisteredPopulation: "1979",
    VotingStationName: "GERT SIBANDE TECHNICAL COLLEGE",
    Address: "37 ROTTERDAM ROAD  EVANDER  HIGHVELD RIDGE",
    Latitude: "-26.468",
    Longitude: "29.1092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17583",
    WardLookupId: "4198",
    VDNumber: "54260478",
    RegisteredPopulation: "778",
    VotingStationName: "LAERSKOOL HOÃ‹VELD",
    Address: "15 TOULOUSE ROAD  EVANDER  EVANDER",
    Latitude: "-26.4708",
    Longitude: "29.1154",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17584",
    WardLookupId: "4198",
    VDNumber: "54420106",
    RegisteredPopulation: "2296",
    VotingStationName: "SUNSET PARK (TENT)",
    Address: "5663 PARK WATERSON STREET, SUNSET PARK  SECUNDA  GOVAN MBEKI",
    Latitude: "-26.5037",
    Longitude: "29.1755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17585",
    WardLookupId: "4199",
    VDNumber: "54260131",
    RegisteredPopulation: "1575",
    VotingStationName: "UNITED DUTCH REFORMED CHURCH",
    Address: "2069 ALLAN MAKUNGA  EMBALENHLE  HIGHVELD RIDGE",
    Latitude: "-26.5482",
    Longitude: "29.066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17586",
    WardLookupId: "4199",
    VDNumber: "54260366",
    RegisteredPopulation: "779",
    VotingStationName: "ZAMOKUHLE PRIMARY SCHOOL",
    Address:
      "CNR ELLEN MAKHUNGA AND EMBALENHLE DRIVE, ETENSION   EMBALENHLE  GOVAN MBEKI",
    Latitude: "-26.547",
    Longitude: "29.0683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17587",
    WardLookupId: "4199",
    VDNumber: "54260423",
    RegisteredPopulation: "643",
    VotingStationName: "AFRIKA CHURCH",
    Address: "2062 EXTENSION 1  EMBALENHLE  GOVAN MBEKI",
    Latitude: "-26.5506",
    Longitude: "29.0671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17588",
    WardLookupId: "4199",
    VDNumber: "54420050",
    RegisteredPopulation: "354",
    VotingStationName: "BRENDAN VILLAGE COMMUNITY HALL",
    Address: "BRENDAN VILLAGE  HIGHVELD RIDGE",
    Latitude: "-26.5008",
    Longitude: "29.0392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17589",
    WardLookupId: "4199",
    VDNumber: "54420083",
    RegisteredPopulation: "298",
    VotingStationName: "LEEUPAN STORE",
    Address:
      "FARM SPRINGBOKDRAAI  277-15 HIGHVELD RIDGE DISTRICT  HIGHVELD RIDGE",
    Latitude: "-26.5377",
    Longitude: "29.0182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17590",
    WardLookupId: "4200",
    VDNumber: "54260399",
    RegisteredPopulation: "2404",
    VotingStationName: "WORD OF LIFE CHURCH",
    Address: "EXT10 EMBALENHLE TOWNSHIP  EMBALENHLE  EMBALENHLE TOWNSHIP",
    Latitude: "-26.5308",
    Longitude: "29.0689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17591",
    WardLookupId: "4200",
    VDNumber: "54260535",
    RegisteredPopulation: "1454",
    VotingStationName: "VUKUZENZELE DAY CARE",
    Address: "2372 EXT 16  EMBALENHLE  EMBALENHLE",
    Latitude: "-26.534",
    Longitude: "29.0697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17592",
    WardLookupId: "4201",
    VDNumber: "54260041",
    RegisteredPopulation: "2472",
    VotingStationName: "LAERSKOOL SECUNDA",
    Address: "RISSIKSTRAAT  SECUNDA  HIGHVELD RIDGE",
    Latitude: "-26.5095",
    Longitude: "29.1936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17593",
    WardLookupId: "4201",
    VDNumber: "54260434",
    RegisteredPopulation: "2144",
    VotingStationName: "GOULDVELD SHOPPING CENTRE",
    Address: "2 MOOR STREET  EXT 22  SECUNDA",
    Latitude: "-26.4944",
    Longitude: "29.1972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17594",
    WardLookupId: "4202",
    VDNumber: "54140015",
    RegisteredPopulation: "2720",
    VotingStationName: "SEAD CLINIC (TENT)",
    Address: "5926 EXT 5  MZINONI  BETHAL",
    Latitude: "-26.4998",
    Longitude: "29.4318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17595",
    WardLookupId: "4202",
    VDNumber: "54140150",
    RegisteredPopulation: "2275",
    VotingStationName: "INSINDISO FREEDOM CHURCH",
    Address: "EXT6 EMZINONI BETHAL  BETHAL  EMZINONI TOWNSHIP",
    Latitude: "-26.5054",
    Longitude: "29.4287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17596",
    WardLookupId: "4203",
    VDNumber: "54140026",
    RegisteredPopulation: "1109",
    VotingStationName: "IKHETHELO SECONDARY SCHOOL",
    Address: "3885 VUYANI STREET  MZINONI  BETHAL",
    Latitude: "-26.4854",
    Longitude: "29.4346",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17597",
    WardLookupId: "4203",
    VDNumber: "54140127",
    RegisteredPopulation: "1495",
    VotingStationName: "JUBILEE CHRISTIAN CHURCH",
    Address: "4558 EXT 4  MZINONI  BETHAL",
    Latitude: "-26.4936",
    Longitude: "29.4289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17598",
    WardLookupId: "4203",
    VDNumber: "54140149",
    RegisteredPopulation: "1000",
    VotingStationName: "LANGELIHLE PRIMARY SCHOOL",
    Address: "4063 CNR ACE & SHONGWE STREET  MZINONI  BETHAL",
    Latitude: "-26.4913",
    Longitude: "29.4354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17599",
    WardLookupId: "4204",
    VDNumber: "54140059",
    RegisteredPopulation: "2614",
    VotingStationName: "VUKANINI PRIMARY SCHOOL",
    Address: "343 5TH AVENUE  MZINONI TOWNSHIP  BETHAL 2310",
    Latitude: "-26.4801",
    Longitude: "29.453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17600",
    WardLookupId: "4204",
    VDNumber: "54140060",
    RegisteredPopulation: "1654",
    VotingStationName: "JEHOVA NISSIE CHURCH",
    Address: "493 TAMBO VILLAGE  MZINONI  BETHAL",
    Latitude: "-26.4773",
    Longitude: "29.4493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17601",
    WardLookupId: "4205",
    VDNumber: "54260029",
    RegisteredPopulation: "2632",
    VotingStationName: "LAERSKOOL TRICHARDT",
    Address: "KERK STRAAT  SECUNDA  HIGHVELD RIDGE",
    Latitude: "-26.4923",
    Longitude: "29.2238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17602",
    WardLookupId: "4205",
    VDNumber: "54260445",
    RegisteredPopulation: "2880",
    VotingStationName: "LAERSKOOL GOEDEHOOP",
    Address: "CNR STANFORD AND MAUCHIN STREET  SECUNDA  GOVAN MBEKI",
    Latitude: "-26.507",
    Longitude: "29.2138",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17603",
    WardLookupId: "4205",
    VDNumber: "54420072",
    RegisteredPopulation: "720",
    VotingStationName: "TERRANOVA CONFERENCE CENTRE.",
    Address: "1 RIVER ROAD   TERRA NOVA  TRICHARDT",
    Latitude: "-26.4829",
    Longitude: "29.2331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17604",
    WardLookupId: "4205",
    VDNumber: "54420151",
    RegisteredPopulation: "452",
    VotingStationName: "TRICHARDT EBENEZAR CHURCH",
    Address: "VLAKLAAGTE FARM  SECUNDA  TRICHARDT",
    Latitude: "-26.4601",
    Longitude: "29.2166",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17605",
    WardLookupId: "4206",
    VDNumber: "54140048",
    RegisteredPopulation: "2496",
    VotingStationName: "AD NKOSI HIGH SCHOOL",
    Address: "3380 EXT 2  MZINONI  BETHAL",
    Latitude: "-26.481",
    Longitude: "29.4468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17606",
    WardLookupId: "4206",
    VDNumber: "54140138",
    RegisteredPopulation: "2631",
    VotingStationName: "IKHWEZI PRIMARY SCHOOL",
    Address: "EMZINONI TOWNSHIP  BETHAL  BETHAL",
    Latitude: "-26.4842",
    Longitude: "29.4749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17607",
    WardLookupId: "4207",
    VDNumber: "54140071",
    RegisteredPopulation: "1746",
    VotingStationName: "MZINONI SECONDARY SCHOOL",
    Address: "46 6TH STREET  MZINONI  BETHAL",
    Latitude: "-26.4707",
    Longitude: "29.4586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17608",
    WardLookupId: "4207",
    VDNumber: "54140116",
    RegisteredPopulation: "1287",
    VotingStationName: "MZINONI MUNICIPAL OFFICES",
    Address: "1500 1ST ST  MZINONI  BETHAL",
    Latitude: "-26.4674",
    Longitude: "29.4559",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17609",
    WardLookupId: "4208",
    VDNumber: "54140082",
    RegisteredPopulation: "2787",
    VotingStationName: "M D COOVADIA COMBINED SCHOOL",
    Address: "1 MOGRA STREET  MILAN PARK  BETHAL",
    Latitude: "-26.4711",
    Longitude: "29.4655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17610",
    WardLookupId: "4208",
    VDNumber: "54140105",
    RegisteredPopulation: "2948",
    VotingStationName: "LAERSKOOL MARITJIE VAN NIEKERK",
    Address: "NEW BETHAL EAST  BETHAL  BETHAL",
    Latitude: "-26.4396",
    Longitude: "29.49",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17611",
    WardLookupId: "4209",
    VDNumber: "54260085",
    RegisteredPopulation: "3627",
    VotingStationName: "SHAPEVE PRIMARY SCHOOL",
    Address: "9400 EXTENSION 4 DRIVE  EMBALENHLE  HIGHVELD RIDGE",
    Latitude: "-26.5315",
    Longitude: "29.0909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17612",
    WardLookupId: "4209",
    VDNumber: "54260467",
    RegisteredPopulation: "1301",
    VotingStationName: "KGOTSO TSOTETSI HALL",
    Address: "9774 EXT 12   EMBALENHLE  EMBALENHLE",
    Latitude: "-26.5301",
    Longitude: "29.0947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17613",
    WardLookupId: "4210",
    VDNumber: "54260018",
    RegisteredPopulation: "3424",
    VotingStationName: "LAERSKOOL KRUINPARK",
    Address: "H/V SHEBA & VAALRIVIER  SECUNDA  HIGHVELD RIDGE",
    Latitude: "-26.5191",
    Longitude: "29.2258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17614",
    WardLookupId: "4210",
    VDNumber: "54260388",
    RegisteredPopulation: "1610",
    VotingStationName: "HOÃ‹RSKOOL SECUNDA",
    Address: "CNR ETTIEN ROUSSEA AND COEN BRITS STREET SECUNDA  GOVAN MBEKI",
    Latitude: "-26.5128",
    Longitude: "29.1956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17615",
    WardLookupId: "4211",
    VDNumber: "54260175",
    RegisteredPopulation: "2171",
    VotingStationName: "THE UNITING PRESBYTERIAN CHURCH 38TH STREET",
    Address: "38TH STREET  SECUNDA  EMBALENHLE",
    Latitude: "-26.5713",
    Longitude: "29.0861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17616",
    WardLookupId: "4211",
    VDNumber: "54260513",
    RegisteredPopulation: "1408",
    VotingStationName: "AFRICAN PRESBYTERIAN CHURCH 13TH STREET",
    Address: "13TH STREET  SECUNDA  EMBALENHLE",
    Latitude: "-26.5653",
    Longitude: "29.0897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17617",
    WardLookupId: "4212",
    VDNumber: "54260108",
    RegisteredPopulation: "1939",
    VotingStationName: "BASIZENI SPECIAL SCHOOL",
    Address: "7869 DR VICTOR NHLAPO AVE  EMBALENHLE  HIGHVELD RIDGE",
    Latitude: "-26.5301",
    Longitude: "29.0787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17618",
    WardLookupId: "4212",
    VDNumber: "54260456",
    RegisteredPopulation: "1232",
    VotingStationName: "CALVARI HARVEST CHURCH",
    Address: "7743 EXT 11  EMBALENHLE  EMBALENHLE",
    Latitude: "-26.5269",
    Longitude: "29.078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17619",
    WardLookupId: "4212",
    VDNumber: "54420140",
    RegisteredPopulation: "2032",
    VotingStationName: "PRESENCE OF GOD CHURCH(EXT19)",
    Address: "27103 EXT 26  EMBALENHLE  SECUNDA",
    Latitude: "-26.5233",
    Longitude: "29.0845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17620",
    WardLookupId: "4213",
    VDNumber: "54190076",
    RegisteredPopulation: "2153",
    VotingStationName: "BAZANI PRIMARY SCHOOL",
    Address: "891 SHABANGU STR  BOTLENG  DELMAS",
    Latitude: "-26.1308",
    Longitude: "28.6929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17621",
    WardLookupId: "4213",
    VDNumber: "54190100",
    RegisteredPopulation: "1370",
    VotingStationName: "PRAISE CHRISTIAN CENTRE",
    Address: "10 COPPER STREET   DELMAS INDUSTRIAL AREA  DELMAS",
    Latitude: "-26.1352",
    Longitude: "28.6894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17622",
    WardLookupId: "4214",
    VDNumber: "54190054",
    RegisteredPopulation: "2007",
    VotingStationName: "HOLY ETHOPIAN METHODIST CHURCH",
    Address: "CNR RAKOENA AND LEKALAKALA STR  DELMAS DISTRICT  DELMAS",
    Latitude: "-26.1227",
    Longitude: "28.6944",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17623",
    WardLookupId: "4214",
    VDNumber: "54190065",
    RegisteredPopulation: "1099",
    VotingStationName: "BOTLENG SECONDARY SCHOOL",
    Address: "898 SHABANGU STREET  BOTLENG  DELMAS",
    Latitude: "-26.1304",
    Longitude: "28.6982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17624",
    WardLookupId: "4215",
    VDNumber: "54190087",
    RegisteredPopulation: "2301",
    VotingStationName: "MM MOTLOUNG SECONDARY SCHOOL",
    Address: "MANDELA DRIVE  BOTLENG X4  DELMAS",
    Latitude: "-26.1012",
    Longitude: "28.7001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17625",
    WardLookupId: "4215",
    VDNumber: "54400104",
    RegisteredPopulation: "2293",
    VotingStationName: "VEZUMFUNDO PRIMARY SCHOOL",
    Address: "BOTLENG  DELMAS",
    Latitude: "-26.0917",
    Longitude: "28.6952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17626",
    WardLookupId: "4215",
    VDNumber: "54400137",
    RegisteredPopulation: "616",
    VotingStationName: "MT CALVARY PENTECOSTAL CHURCH",
    Address: "BOTLENG  DELMAS",
    Latitude: "-26.0997",
    Longitude: "28.6812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17627",
    WardLookupId: "4216",
    VDNumber: "54190010",
    RegisteredPopulation: "1803",
    VotingStationName: "BOTLENG X 3 COMMUNITY HALL",
    Address: "3315 NKOSI STREET  BOTLENG  DELMAS",
    Latitude: "-26.1043",
    Longitude: "28.6948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17628",
    WardLookupId: "4216",
    VDNumber: "54190111",
    RegisteredPopulation: "1628",
    VotingStationName: "MDUMISENI PRIMARY SCHOOL",
    Address: "4161 NTSHANGASE  BOTLENG EXT3  DELMAS",
    Latitude: "-26.1048",
    Longitude: "28.702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17629",
    WardLookupId: "4217",
    VDNumber: "54190043",
    RegisteredPopulation: "2355",
    VotingStationName: "PHAPHAMANI SECONDARY SCHOOL",
    Address: "3486 MLAMBO STREET  BOTLENG  DELMAS",
    Latitude: "-26.1054",
    Longitude: "28.6902",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17630",
    WardLookupId: "4217",
    VDNumber: "54190155",
    RegisteredPopulation: "1219",
    VotingStationName: "REFORMED APOSTOLIC CHURCH",
    Address: "BOTLENG  BOTLENG EXT 3  DELMAS",
    Latitude: "-26.1058",
    Longitude: "28.6811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17631",
    WardLookupId: "4218",
    VDNumber: "54190032",
    RegisteredPopulation: "1756",
    VotingStationName: "MALITA OLD AGE HOME",
    Address: "45 SESDE STRAAT  DELMAS  DELMAS",
    Latitude: "-26.1464",
    Longitude: "28.6855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17632",
    WardLookupId: "4218",
    VDNumber: "54190144",
    RegisteredPopulation: "1864",
    VotingStationName: "DELPARK PRIMARY SCHOOL",
    Address: "652 UGANDA   DELMAS  DELMAS",
    Latitude: "-26.1399",
    Longitude: "28.6961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17633",
    WardLookupId: "4219",
    VDNumber: "54190098",
    RegisteredPopulation: "1442",
    VotingStationName: "SUNDRA PUBLIC LIBRARY",
    Address: "54 RENIE/BOOTH AVENUE  DELMAS DISTRICT  DELMAS",
    Latitude: "-26.1846",
    Longitude: "28.5371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17634",
    WardLookupId: "4219",
    VDNumber: "54190133",
    RegisteredPopulation: "844",
    VotingStationName: "WITKLIP OPEN SPACE (TENT)",
    Address: "OPEN FIELD/SPACE  WITKLIP  DELMAS",
    Latitude: "-26.1346",
    Longitude: "28.7062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17635",
    WardLookupId: "4219",
    VDNumber: "54400047",
    RegisteredPopulation: "359",
    VotingStationName: "SWARTKLIP COMBINED SCHOOL",
    Address: "01 SWARKLIP  DELMAS  SWARKLIP FARM",
    Latitude: "-26.167",
    Longitude: "28.6892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17636",
    WardLookupId: "4219",
    VDNumber: "54400058",
    RegisteredPopulation: "254",
    VotingStationName: "VLAKPLAATS COMMUNITY HALL",
    Address: "268 FARM VLAKPLAATS 268IR  DELMAS DISTRICT  ROAD D1134",
    Latitude: "-26.2314",
    Longitude: "28.7809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17637",
    WardLookupId: "4219",
    VDNumber: "54400069",
    RegisteredPopulation: "216",
    VotingStationName: "RIETKUIL OPEN SPACE(TENT)",
    Address: "2490IR FARM RIETKUIL  DELMAS DISTRICT  DELMAS",
    Latitude: "-26.1896",
    Longitude: "28.7707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17638",
    WardLookupId: "4219",
    VDNumber: "54400092",
    RegisteredPopulation: "496",
    VotingStationName: "FUNDISISA PRIMARY SCHOOL",
    Address: "FARM GOEDGEDACHT  228IR DELMAS DISTRICT  ON ROAD D2543",
    Latitude: "-26.1061",
    Longitude: "28.753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17639",
    WardLookupId: "4219",
    VDNumber: "54420016",
    RegisteredPopulation: "364",
    VotingStationName: "DIEPLAAGTE COMMUNITY HALL",
    Address: "01 BRAKFONTEIN  DELMAS  BRAKFONTEIN FARM",
    Latitude: "-26.2105",
    Longitude: "28.8826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17640",
    WardLookupId: "4220",
    VDNumber: "54190021",
    RegisteredPopulation: "2412",
    VotingStationName: "RIETKOL PRIMARY SCHOOL",
    Address: "ELOFF  DELMAS",
    Latitude: "-26.1599",
    Longitude: "28.6066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17641",
    WardLookupId: "4220",
    VDNumber: "54400036",
    RegisteredPopulation: "1153",
    VotingStationName: "RIETKOLNEIGHBOURHOOD WATCH CLUB",
    Address: "199 KLEIN STRAAT RIETKOL  RIETKOL  DELMAS",
    Latitude: "-26.1302",
    Longitude: "28.5594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17642",
    WardLookupId: "4221",
    VDNumber: "54190122",
    RegisteredPopulation: "1343",
    VotingStationName: "BRAM FISHER HALL",
    Address: "01 VD WALT STREET  DELMAS  DELMAS",
    Latitude: "-26.1488",
    Longitude: "28.6742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17643",
    WardLookupId: "4221",
    VDNumber: "54400014",
    RegisteredPopulation: "495",
    VotingStationName: "MANYAZELA PRIMARY SCHOOL",
    Address: "556 FARM WAAIKRAAL NO  556JR DISTRICT  DELMAS ON ROAD P95-2",
    Latitude: "-25.9965",
    Longitude: "28.6795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17644",
    WardLookupId: "4221",
    VDNumber: "54400070",
    RegisteredPopulation: "685",
    VotingStationName: "MNDENI COMMUNITY CENTRE",
    Address: "82 ARGENT FARM  ARGENT  DELMAS",
    Latitude: "-26.065",
    Longitude: "28.8111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17645",
    WardLookupId: "4221",
    VDNumber: "54400081",
    RegisteredPopulation: "270",
    VotingStationName: "OKELA PRIMARY SCHOOL",
    Address: "206 FARM GROENFONTEIN NO  206IR DISTRICT DELMAS  ON ROAD D38",
    Latitude: "-26.0304",
    Longitude: "28.7678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17646",
    WardLookupId: "4221",
    VDNumber: "54400126",
    RegisteredPopulation: "683",
    VotingStationName: "ARBOR PRIMARY SCHOOL",
    Address: "213 PORTION 5  VLAKVARKFONTEIN  DELMAS",
    Latitude: "-26.0476",
    Longitude: "28.8894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17647",
    WardLookupId: "4221",
    VDNumber: "54400148",
    RegisteredPopulation: "424",
    VotingStationName: "ZOE BIBLE CHURCH",
    Address: "DR NELSON MANDELA  DELMAS  DELMAS",
    Latitude: "-26.0972",
    Longitude: "28.6815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17648",
    WardLookupId: "2127",
    VDNumber: "54650189",
    RegisteredPopulation: "1640",
    VotingStationName: "HLALANIKAHLE COMMUNITY HALL",
    Address: "143 HLALANIKAHLE EXT. 2  HLALANIKAHLE  EMALAHLENI",
    Latitude: "-25.8454",
    Longitude: "29.1211",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17649",
    WardLookupId: "2127",
    VDNumber: "54650426",
    RegisteredPopulation: "2718",
    VotingStationName: "HLALANIKAHLE EXT 2 (TENT)",
    Address: "NULL  HLALANIKAHLE EXT 2 INFORMAL SETTLEMENT  EMALAHLENI WITBANK",
    Latitude: "-25.8506",
    Longitude: "29.1179",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17650",
    WardLookupId: "2127",
    VDNumber: "54650505",
    RegisteredPopulation: "1730",
    VotingStationName: "J KANANDA PRIMARY SCHOOL",
    Address:
      "5995 HLALANIKAHLE PHASE 4  HLANIKAHLE PHASE 4  EMALAHLENI [WITBANK]",
    Latitude: "-25.8465",
    Longitude: "29.114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17651",
    WardLookupId: "2128",
    VDNumber: "54650145",
    RegisteredPopulation: "2348",
    VotingStationName: "BESILINDIE HIGHER PRIMARY SCHOOL",
    Address: "4838 HLALANIKAHLE PHASE 4  WITBANK",
    Latitude: "-25.8392",
    Longitude: "29.1169",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17652",
    WardLookupId: "2128",
    VDNumber: "54650392",
    RegisteredPopulation: "2434",
    VotingStationName: "OPEN SPACE HLALANIKHALE EXT 3 (TENT)",
    Address:
      "SHUKUSHUKAMA HAIR SALON  HLALANIKHALE X 3 INFORMAL SETTLEMENT  WITBANK",
    Latitude: "-25.842",
    Longitude: "29.1216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17653",
    WardLookupId: "2129",
    VDNumber: "54650044",
    RegisteredPopulation: "4619",
    VotingStationName: "KWAGUQA EXT 11 COMMUNITY HALL",
    Address: "KWAGUQA EXTENSION   KWAGUQA EXT 11  EMALAHLENI",
    Latitude: "-25.8594",
    Longitude: "29.1036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17654",
    WardLookupId: "2129",
    VDNumber: "54650459",
    RegisteredPopulation: "1437",
    VotingStationName: "MPUMELELO DAY CARE CENTRE",
    Address: "KWAGUQA EXT 11  EMALAHLENI  EMALAHLENI",
    Latitude: "-25.8654",
    Longitude: "29.1047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17655",
    WardLookupId: "2130",
    VDNumber: "54650167",
    RegisteredPopulation: "2297",
    VotingStationName: "SINEMPATHO DAY CARE CENTRE",
    Address: "SINEMPATHO DAY CARE CENTRE  EMALAHLENI  VOSMAN",
    Latitude: "-25.8469",
    Longitude: "29.1239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17656",
    WardLookupId: "2130",
    VDNumber: "54650224",
    RegisteredPopulation: "2705",
    VotingStationName: "TENT AT HLALANIKAHLE ADMINISTRATION OFFICE",
    Address: "HLALANIKAHLE  EMALAHLENI",
    Latitude: "-25.8539",
    Longitude: "29.1243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17657",
    WardLookupId: "2131",
    VDNumber: "54650112",
    RegisteredPopulation: "2119",
    VotingStationName: "EDWARD MATYEKA PRIMARY SCHOOL",
    Address: "1937 LUCY MASHIANE  KWA-GUQA EXT 4  WITBANK",
    Latitude: "-25.8591",
    Longitude: "29.1233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17658",
    WardLookupId: "2131",
    VDNumber: "54650178",
    RegisteredPopulation: "2222",
    VotingStationName: "EMPUCUKWENI SECONDARY SCHOOL",
    Address: "2971 CNR. TSOTETSI & NGOBE  KWA-GUQA EXT 5  EMALAHLENI",
    Latitude: "-25.8605",
    Longitude: "29.1321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17659",
    WardLookupId: "2131",
    VDNumber: "54650572",
    RegisteredPopulation: "588",
    VotingStationName: "NELSON NGOBENI PRIMARY SCHOOL",
    Address: "2918 TSOTETSI  KWAGUQA EXT 5  EMALAHLENI WITBANK",
    Latitude: "-25.8561",
    Longitude: "29.129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17660",
    WardLookupId: "2132",
    VDNumber: "54650055",
    RegisteredPopulation: "2677",
    VotingStationName: "ADMIN PAYING POINT EXT 6",
    Address: "6661 CNR. CHIEF MASANGO & MATHEW PHOSA  KWAGUQA EXT 6  WITBANK",
    Latitude: "-25.8625",
    Longitude: "29.1214",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17661",
    WardLookupId: "2132",
    VDNumber: "54650246",
    RegisteredPopulation: "3491",
    VotingStationName: "KWAGUQA PRIMARY SCHOOL",
    Address: "2110 MACHIBINI DRIVE  KWAGUQA EXT 2  WITBANK",
    Latitude: "-25.8699",
    Longitude: "29.1219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17662",
    WardLookupId: "2133",
    VDNumber: "54650213",
    RegisteredPopulation: "1854",
    VotingStationName: "ZACHEUS MALAZU SECONDARY SCHOOL",
    Address: "66 MACHIBINI STREET  KWAGUQA EXT 2  WITBANK",
    Latitude: "-25.8651",
    Longitude: "29.1083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17663",
    WardLookupId: "2133",
    VDNumber: "54650404",
    RegisteredPopulation: "687",
    VotingStationName: "MAPULE SINDANE COMBINED SCHOOL",
    Address: "557 LEKWA STREET  KWAGUQA EXT 2  WITBANK",
    Latitude: "-25.8685",
    Longitude: "29.1123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17664",
    WardLookupId: "2133",
    VDNumber: "54650538",
    RegisteredPopulation: "289",
    VotingStationName: "MORUTI MAKUSE PRIMARY SCHOOL",
    Address: "61 LEOLA KWAGUQA  KWAGUQA EXT 2  EMALAHLENI (WITBANK)",
    Latitude: "-25.8674",
    Longitude: "29.1068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17665",
    WardLookupId: "2133",
    VDNumber: "54650583",
    RegisteredPopulation: "1472",
    VotingStationName: "OPEN SPACE EXT 18 (OPPOSITE CITY WEST DRY CLEANERS)",
    Address: "9243-9236 CHIEF MASANGO  KWAGUQA EXT 18  EMALAHLENI",
    Latitude: "-25.8592",
    Longitude: "29.113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17666",
    WardLookupId: "2134",
    VDNumber: "54650202",
    RegisteredPopulation: "2119",
    VotingStationName: "NEW TWELVE APOSTOLIC CHURCH-VOSMAN",
    Address: "NEXT TO OLD GUNNERS GROUND  VOSMAN  VOSMAN",
    Latitude: "-25.873",
    Longitude: "29.1406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17667",
    WardLookupId: "2134",
    VDNumber: "54650617",
    RegisteredPopulation: "3236",
    VotingStationName: "EBENEZER DAY CARE",
    Address: "VOSMAN  EMALAHLENI  KWAGUQA",
    Latitude: "-25.868",
    Longitude: "29.1398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17668",
    WardLookupId: "2135",
    VDNumber: "54650088",
    RegisteredPopulation: "2406",
    VotingStationName: "CLEWER PRIMARY SCHOOL",
    Address: "ASCOT AVE  CLEWER  WITBANK",
    Latitude: "-25.9067",
    Longitude: "29.1366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17669",
    WardLookupId: "2135",
    VDNumber: "54650235",
    RegisteredPopulation: "2122",
    VotingStationName: "VOSMAN MULTI PURPOSE CENTER",
    Address: "VOSMAN  EMALAHLENI  EMALAHLENI",
    Latitude: "-25.8684",
    Longitude: "29.142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17670",
    WardLookupId: "2135",
    VDNumber: "54650561",
    RegisteredPopulation: "963",
    VotingStationName: "JEREMIA MDAKA PRIMARY SCHOOL",
    Address: "7995 PROF BENGU STR  VOSMAN EXT 16  EMALAHLENI [WITBANK]",
    Latitude: "-25.8747",
    Longitude: "29.15",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17671",
    WardLookupId: "2136",
    VDNumber: "54650336",
    RegisteredPopulation: "2782",
    VotingStationName: "KOPANANG HIGH SCHOOL",
    Address: "2084 KOALI STREET  ACKERVILLE  EMALAHLENI",
    Latitude: "-25.8677",
    Longitude: "29.1741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17672",
    WardLookupId: "2136",
    VDNumber: "54650347",
    RegisteredPopulation: "2025",
    VotingStationName: "DIVINE WORD MINISTRIES",
    Address: "SCHONLAND DRIVE   KWA-GUQA  EMALAHLENI",
    Latitude: "-25.8673",
    Longitude: "29.167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17673",
    WardLookupId: "2136",
    VDNumber: "54650415",
    RegisteredPopulation: "2136",
    VotingStationName: "KHONZIMFUNDO PRIMARY SCHOOL",
    Address: "1202 VAN DALEN STR  ACKERVILLE  EMALAHLENI",
    Latitude: "-25.8688",
    Longitude: "29.1816",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17674",
    WardLookupId: "2137",
    VDNumber: "54650268",
    RegisteredPopulation: "1381",
    VotingStationName: "KWAGUQA MAINTENANCE DEPOT",
    Address: "116/297 W A BOTHA STREET  LYNNVILLE  EMALAHLENI",
    Latitude: "-25.8816",
    Longitude: "29.1862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17675",
    WardLookupId: "2137",
    VDNumber: "54650291",
    RegisteredPopulation: "1965",
    VotingStationName: "SIBUKOSETHU PRIMARY SCHOOL",
    Address: "3221 JOHNSON DRIVE  ACKERVILLE  EMALAHLENI",
    Latitude: "-25.8747",
    Longitude: "29.1771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17676",
    WardLookupId: "2137",
    VDNumber: "54650640",
    RegisteredPopulation: "879",
    VotingStationName: "MALOMA PRIMARY SCHOOL",
    Address: "1 JANSON STREET  KWAGUQA  EMALAHLENI",
    Latitude: "-25.8744",
    Longitude: "29.1804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17677",
    WardLookupId: "2138",
    VDNumber: "54650370",
    RegisteredPopulation: "1321",
    VotingStationName: "PINE RIDGE COMBINED SCHOOL",
    Address: "28 CARNATION ROAD  PINE RIDGE  WITBANK",
    Latitude: "-25.8197",
    Longitude: "29.2039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17678",
    WardLookupId: "2138",
    VDNumber: "54650763",
    RegisteredPopulation: "2516",
    VotingStationName: "CORONATION SECONDARY SCHOOL",
    Address: "KLARINET EXT 6  EMALAHLENI  EMALAHLENI",
    Latitude: "-25.8206",
    Longitude: "29.2163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17679",
    WardLookupId: "2138",
    VDNumber: "54650796",
    RegisteredPopulation: "944",
    VotingStationName: "KLARINET EXT 7 OFFICE",
    Address: "KLARINET  EMALAHLENI",
    Latitude: "-25.834",
    Longitude: "29.222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17680",
    WardLookupId: "2138",
    VDNumber: "54650820",
    RegisteredPopulation: "544",
    VotingStationName: "MNS GATEAWAY CHURCH",
    Address: "KLARINET INFORMAL SETTLEMENT  EMALAHLENI  KLARINET",
    Latitude: "-25.8278",
    Longitude: "29.2313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17681",
    WardLookupId: "2138",
    VDNumber: "54650853",
    RegisteredPopulation: "915",
    VotingStationName: "TENT AT  SIYANQOBA LOCATION",
    Address: "TENT AT SIYANQOBA LOCATION  EMALAHLENI  SIYANQOBA",
    Latitude: "-25.8099",
    Longitude: "29.1745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17682",
    WardLookupId: "2138",
    VDNumber: "54700017",
    RegisteredPopulation: "544",
    VotingStationName: "TEADOR CAFE",
    Address: "VERENA ROAD  EMALAHLENI  EMALAHLENI",
    Latitude: "-25.7574",
    Longitude: "29.1109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17683",
    WardLookupId: "2139",
    VDNumber: "54650190",
    RegisteredPopulation: "2752",
    VotingStationName: "BONGINSIMBI SECONDARY SCHOOL",
    Address: "4052 THYS JONKER  ACKERVILLE  EMALAHLENI",
    Latitude: "-25.8725",
    Longitude: "29.1736",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17684",
    WardLookupId: "2139",
    VDNumber: "54650358",
    RegisteredPopulation: "1713",
    VotingStationName: "MPONDOZANKOMO FET COLLEGE",
    Address: "1 SCHONLAND DRIVE  ACKERVILLE  EMALAHLENI",
    Latitude: "-25.8764",
    Longitude: "29.1693",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17685",
    WardLookupId: "2140",
    VDNumber: "54650303",
    RegisteredPopulation: "2010",
    VotingStationName: "HOSTEL 1 OFFICE TUSHANANG",
    Address: "57 LYNN & BAKER STREET  TUSHANANG  EMALAHLENI",
    Latitude: "-25.8718",
    Longitude: "29.1933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17686",
    WardLookupId: "2140",
    VDNumber: "54650594",
    RegisteredPopulation: "2147",
    VotingStationName: "TENT AT 14 LIKAZI SPORTS GROUND",
    Address: "OLD CORONATION INFORMAL  EMALAHLENI  EMALAHLENI",
    Latitude: "-25.868",
    Longitude: "29.1922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17687",
    WardLookupId: "2140",
    VDNumber: "54650718",
    RegisteredPopulation: "1068",
    VotingStationName: "KWAGUQA DAY CARE CENTER",
    Address: "HECTORWAY KWAGUQA  EMALAHLENI  EMALAHLENI",
    Latitude: "-25.8748",
    Longitude: "29.1963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17688",
    WardLookupId: "2141",
    VDNumber: "54650437",
    RegisteredPopulation: "1864",
    VotingStationName: "ALEX MAMPANE INTERMEDIATE SCHOOL",
    Address: "OPEN SPACE INSIDE RDP VILLAGE  KLARINET RDP VILLAGE  EMALAHLENI",
    Latitude: "-25.8254",
    Longitude: "29.2066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17689",
    WardLookupId: "2141",
    VDNumber: "54650482",
    RegisteredPopulation: "2948",
    VotingStationName: "CHRIST ALL MINISTRIES CHURCH",
    Address: "OLD CORONATION INFORMAL SETTLE  EMALAHLENI  EMALAHLENI",
    Latitude: "-25.8669",
    Longitude: "29.2031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17690",
    WardLookupId: "2141",
    VDNumber: "54650628",
    RegisteredPopulation: "2908",
    VotingStationName: "KLARINET MULTI-PURPORSE CENTRE",
    Address: "KLARIENET  KLARINET  WITBANK-EMALAHLENI",
    Latitude: "-25.8332",
    Longitude: "29.2039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17691",
    WardLookupId: "2142",
    VDNumber: "54650279",
    RegisteredPopulation: "1318",
    VotingStationName: "ITERELENG PRIMARY SCHOOL",
    Address: "700 MULEKA STREET  THUSANANG  EMALAHLENI",
    Latitude: "-25.8732",
    Longitude: "29.1863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17692",
    WardLookupId: "2142",
    VDNumber: "54650314",
    RegisteredPopulation: "1149",
    VotingStationName: "MAGOBANE COMBINED SCHOOL",
    Address: "435 CHURCH STREET  THUSANANG  EMALAHLENI",
    Latitude: "-25.873",
    Longitude: "29.1911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17693",
    WardLookupId: "2142",
    VDNumber: "54650471",
    RegisteredPopulation: "1121",
    VotingStationName: "LYNNVILLE COMMUNITY HALL",
    Address: "1536 MATHABATHE STR  LYNNVILLE EXT 6  EMALAHLENI [WITBANK]",
    Latitude: "-25.8788",
    Longitude: "29.1911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17694",
    WardLookupId: "2142",
    VDNumber: "54650729",
    RegisteredPopulation: "376",
    VotingStationName: "HOLINESS CHURCH",
    Address: "W A BOTHA STREET  ACKERVILLE  WITBANK",
    Latitude: "-25.8739",
    Longitude: "29.1827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17695",
    WardLookupId: "2142",
    VDNumber: "54650785",
    RegisteredPopulation: "834",
    VotingStationName: "ELUKHANYISWENI SECONDARY SCHOOL",
    Address: "XUMA STREET  LYNVILLE  EMALAHLENI",
    Latitude: "-25.8778",
    Longitude: "29.1877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17696",
    WardLookupId: "2143",
    VDNumber: "54650257",
    RegisteredPopulation: "2472",
    VotingStationName: "PHAKAMA COMBINED  SCHOOL",
    Address: "1537 DUBE   LYNNVILLE  EMALAHLENI",
    Latitude: "-25.8808",
    Longitude: "29.1953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17697",
    WardLookupId: "2143",
    VDNumber: "54650280",
    RegisteredPopulation: "1457",
    VotingStationName: "WITBANK PRIMARY SCHOOL",
    Address: "317 DEMAS AVE  SCHOONGEZICHT  EMALAHLENI",
    Latitude: "-25.8844",
    Longitude: "29.1844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17698",
    WardLookupId: "2143",
    VDNumber: "54650460",
    RegisteredPopulation: "422",
    VotingStationName: "SIYATHELA EARLY LEARNING ASSOCIATION",
    Address:
      "1583 CNR LUTHULI AND MBONANI STR  LYNVILLE 8  EMALAHLENI [WITBANK]",
    Latitude: "-25.8775",
    Longitude: "29.1955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17699",
    WardLookupId: "2143",
    VDNumber: "54650493",
    RegisteredPopulation: "829",
    VotingStationName: "LICENSING OFFICE WITBANK",
    Address: "VAN RIEBECK   WITBANK EXT 3  EMALAHLENI",
    Latitude: "-25.869",
    Longitude: "29.2133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17700",
    WardLookupId: "2143",
    VDNumber: "54700129",
    RegisteredPopulation: "497",
    VotingStationName: "GREENSIDE RECREATION CENTRE",
    Address: "1 GREENSIDE VILLAGE   EMALAHLENI  GREENSIDE MINE",
    Latitude: "-25.9614",
    Longitude: "29.1756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17701",
    WardLookupId: "2144",
    VDNumber: "54650123",
    RegisteredPopulation: "5455",
    VotingStationName: "REYNO RIF PRIMARY SCHOOL",
    Address: "8 GAZELLE AVE  REYNO RIDGE  WITBANK",
    Latitude: "-25.9117",
    Longitude: "29.2501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17702",
    WardLookupId: "2144",
    VDNumber: "54650549",
    RegisteredPopulation: "1593",
    VotingStationName: "SPRING VALLEY PRIMARY SCHOOL",
    Address: "SPRING VALLEY FARM  SPRING VALLEY VILLAGE  EMALAHLENI [WITBANK]",
    Latitude: "-25.9209",
    Longitude: "29.2609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17703",
    WardLookupId: "2145",
    VDNumber: "54650101",
    RegisteredPopulation: "2795",
    VotingStationName: "DUVHAPARK PRIMARY SCHOOL",
    Address: "MINNIE ROTHMANN STREET  DUVHA PARK  WITBANK",
    Latitude: "-25.9386",
    Longitude: "29.2452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17704",
    WardLookupId: "2145",
    VDNumber: "54700051",
    RegisteredPopulation: "2687",
    VotingStationName: "TMV PROPERTY",
    Address: "A227 MASAKHANE VILLAGE  DUVHA POWER STATION  EMALAHLENI",
    Latitude: "-25.957",
    Longitude: "29.3301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17705",
    WardLookupId: "2146",
    VDNumber: "54650011",
    RegisteredPopulation: "4044",
    VotingStationName: "PANORAMA PRIMARY SCHOOL",
    Address: "17 GORDON ROAD  HEUWEL  WITBANK",
    Latitude: "-25.8526",
    Longitude: "29.2448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17706",
    WardLookupId: "2146",
    VDNumber: "54650550",
    RegisteredPopulation: "455",
    VotingStationName: "CHRISMA CONFERENCE CENTRE",
    Address:
      "OLD  MIDDELBURG ROAD R555  PLOT 146 KROMDRAAI  EMALAHLENI [WITBANK]",
    Latitude: "-25.8225",
    Longitude: "29.272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17707",
    WardLookupId: "2146",
    VDNumber: "54650730",
    RegisteredPopulation: "574",
    VotingStationName: "TENT AT HOÃ‹R TEGNIESE SKOOL",
    Address: "BEYER STREET  EMALAHLENI  EMALAHLENI",
    Latitude: "-25.8685",
    Longitude: "29.2312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17708",
    WardLookupId: "2146",
    VDNumber: "54650774",
    RegisteredPopulation: "1332",
    VotingStationName: "JAKARO PARK WORKSHOP (TENT)",
    Address: "EILEEN  WITBANK  EMALAHLENI",
    Latitude: "-25.8374",
    Longitude: "29.2477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17709",
    WardLookupId: "2147",
    VDNumber: "54650099",
    RegisteredPopulation: "2652",
    VotingStationName: "TASBETPARK PRIMARY SCHOOL",
    Address: "SIMFONIE STREET  TASBETPARK  WITBANK",
    Latitude: "-25.9194",
    Longitude: "29.2267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17710",
    WardLookupId: "2147",
    VDNumber: "54650651",
    RegisteredPopulation: "840",
    VotingStationName: "ILANGA SECONDARY SCHOOL",
    Address: "UTHINGAO PARK  TASBET PARK  EMALAHLENI",
    Latitude: "-25.9249",
    Longitude: "29.2277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17711",
    WardLookupId: "2147",
    VDNumber: "54650662",
    RegisteredPopulation: "1928",
    VotingStationName: "TP SILILO HIGH SCHOOL",
    Address: "25 KIEPERSOL STREET  EMALAHLENI  EMALAHLENI",
    Latitude: "-25.9257",
    Longitude: "29.2415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17712",
    WardLookupId: "2147",
    VDNumber: "54650808",
    RegisteredPopulation: "581",
    VotingStationName: "INTERNATIONAL EVANGELICAL CHRISTIAN CHURCH",
    Address: "05 SALUTE  TASBET PARK EXT 3  EMALAHLENI",
    Latitude: "-25.9313",
    Longitude: "29.2256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17713",
    WardLookupId: "2147",
    VDNumber: "54650831",
    RegisteredPopulation: "521",
    VotingStationName: "GREENDALE COMBINED SCHOOL",
    Address: "IMPALA STREET  EMALAHLENI  TASBET PARK EXT 1",
    Latitude: "-25.9226",
    Longitude: "29.2435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17714",
    WardLookupId: "2147",
    VDNumber: "54700084",
    RegisteredPopulation: "731",
    VotingStationName: "LANDAU COMBINED SCHOOL",
    Address: "LANDAU VILLAGE  AMCOAL TRAINING CENTRE MATIMBA VILLAGE  WITBANK",
    Latitude: "-25.9405",
    Longitude: "29.2222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17715",
    WardLookupId: "2148",
    VDNumber: "54650381",
    RegisteredPopulation: "2720",
    VotingStationName: "WITBANK CITY HALL CIVIC CENTRE",
    Address: "PRESIDENT MANDELA  WITBANK  EMALAHLENI",
    Latitude: "-25.8745",
    Longitude: "29.2164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17716",
    WardLookupId: "2148",
    VDNumber: "54650606",
    RegisteredPopulation: "449",
    VotingStationName: "OPEN SPACE ATLANTIC PARK STADIUM (TENT)",
    Address: "JELICO STREET  EMALAHLENI  EMALAHLENI",
    Latitude: "-25.8838",
    Longitude: "29.2138",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17717",
    WardLookupId: "2148",
    VDNumber: "54650673",
    RegisteredPopulation: "1006",
    VotingStationName: "ST THOMAS AQUNAS SCHOOLI",
    Address: "2 CRN RHODES AND ELIZABETH STREE  EMALAHLENI  EMALAHLENI",
    Latitude: "-25.8736",
    Longitude: "29.2212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17718",
    WardLookupId: "2149",
    VDNumber: "54650156",
    RegisteredPopulation: "3555",
    VotingStationName: "PHILLIP NDIMANDE SECONDARY SCHOOL",
    Address: "6550 KWAGUQA  KWAGUQA EXTENSION 10  WITBANK",
    Latitude: "-25.8428",
    Longitude: "29.1048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17719",
    WardLookupId: "2149",
    VDNumber: "54650527",
    RegisteredPopulation: "2252",
    VotingStationName: "BLACKHILL SCHOONGEZIGHT PRIMARY SCHOOL",
    Address: "5396 SOLLY MOROAPE STREET  KWAGUQA EXT 10  EMALAHLENI [WITBANK]",
    Latitude: "-25.8489",
    Longitude: "29.1083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17720",
    WardLookupId: "2149",
    VDNumber: "54650842",
    RegisteredPopulation: "136",
    VotingStationName: "ETHEMBENI CHIDREN`S HOME",
    Address: "KROMDRAAI ROAD, EMPUMELELWENI  EMALAHLENI  EMALAHLENI",
    Latitude: "-25.8339",
    Longitude: "29.0999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17721",
    WardLookupId: "2150",
    VDNumber: "54650033",
    RegisteredPopulation: "3442",
    VotingStationName: "WH DE KLERK SCHOOL",
    Address: "CNR CERES & MONTAQUE STR  MODELPARK  WITBANK",
    Latitude: "-25.8672",
    Longitude: "29.2469",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17722",
    WardLookupId: "2150",
    VDNumber: "54650684",
    RegisteredPopulation: "1019",
    VotingStationName: "TAALFEES PRIMARY SCHOOL",
    Address: "1 BEATTY STREET  EMALAHLENI  EMALAHLENI",
    Latitude: "-25.8817",
    Longitude: "29.226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17723",
    WardLookupId: "2150",
    VDNumber: "54650695",
    RegisteredPopulation: "499",
    VotingStationName: "TSHWANE UNIVERSITY OF TECHNOLOGY",
    Address: "01 OR TAMBO STREET  EMALAHLENI  EMALAHLENI",
    Latitude: "-25.8767",
    Longitude: "29.2344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17724",
    WardLookupId: "2150",
    VDNumber: "54650741",
    RegisteredPopulation: "610",
    VotingStationName: "COLLIERY TRANNING COLLEGE",
    Address: "DIRKSE  KLIPFONTEIN  WITBANK",
    Latitude: "-25.8983",
    Longitude: "29.2327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17725",
    WardLookupId: "2151",
    VDNumber: "54270031",
    RegisteredPopulation: "2634",
    VotingStationName: "SIBONGAMANDLA HIGH SCHOOL",
    Address: "358 HLOKOHLOKO  THUBELIHLE KRIEL  WITBANK",
    Latitude: "-26.2153",
    Longitude: "29.2905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17726",
    WardLookupId: "2151",
    VDNumber: "54270053",
    RegisteredPopulation: "2178",
    VotingStationName: "ELSADAI MINISTRIES",
    Address: "THUBELIHLE   EMALAHLENI  THUBELIHLE",
    Latitude: "-26.2154",
    Longitude: "29.2849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17727",
    WardLookupId: "2151",
    VDNumber: "54430040",
    RegisteredPopulation: "229",
    VotingStationName: "BONANI PRIMARY SCHOOL",
    Address: "DORSFONTEIN FARM  KRIEL  WITBANK",
    Latitude: "-26.2496",
    Longitude: "29.3397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17728",
    WardLookupId: "2151",
    VDNumber: "54430062",
    RegisteredPopulation: "1239",
    VotingStationName: "BONGINHLANHLA PRIMARY SCHOOL",
    Address: "339 MPUNGUSHE CRESENT  THUBELIHLE  EMALAHLENI [WITBANK]",
    Latitude: "-26.2206",
    Longitude: "29.3062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17729",
    WardLookupId: "2152",
    VDNumber: "54270019",
    RegisteredPopulation: "3006",
    VotingStationName: "FIRE STATION",
    Address: "3171 CNR. DAVID & HEINDICH STREET  GA-NALA  EMALAHLENI",
    Latitude: "-26.2517",
    Longitude: "29.2561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17730",
    WardLookupId: "2152",
    VDNumber: "54270042",
    RegisteredPopulation: "1272",
    VotingStationName: "KRIEL HIGH SCHOOL",
    Address: "54 MOOILAAN  KRIEL  EMALAHLENI [WITBANK]",
    Latitude: "-26.2566",
    Longitude: "29.265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17731",
    WardLookupId: "2152",
    VDNumber: "54270064",
    RegisteredPopulation: "567",
    VotingStationName: "LAERSKOOL KRIEL",
    Address: "CNR LINK AND GROEN STREET  EMALAHLENI  KRIEL",
    Latitude: "-26.2588",
    Longitude: "29.26",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17732",
    WardLookupId: "2152",
    VDNumber: "54270075",
    RegisteredPopulation: "639",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "CNR SWAN AND KING FISH   EMALAHLENI  KRIEL",
    Latitude: "-26.2682",
    Longitude: "29.2659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17733",
    WardLookupId: "2153",
    VDNumber: "54270020",
    RegisteredPopulation: "2160",
    VotingStationName: "ROEPERSFONTEIN MINISTRIES",
    Address: "PLAYFAIR STREET  KRIEL  WITBANK",
    Latitude: "-26.2494",
    Longitude: "29.2748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17734",
    WardLookupId: "2153",
    VDNumber: "54430028",
    RegisteredPopulation: "728",
    VotingStationName: "KWANALA PRIMARY SCHOOL",
    Address: "KRIEL COLLIERY ROAD  KRIEL POWER STATION  GANALA",
    Latitude: "-26.2498",
    Longitude: "29.1995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17735",
    WardLookupId: "2153",
    VDNumber: "54430039",
    RegisteredPopulation: "707",
    VotingStationName: "MATLA COAL MULTIPURPOSE HALL",
    Address: "MATLA COLLIERY  HAASFONTEIN PORTION 85-IS  KRIEL",
    Latitude: "-26.257",
    Longitude: "29.1179",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17736",
    WardLookupId: "2153",
    VDNumber: "54430073",
    RegisteredPopulation: "320",
    VotingStationName: "EMPHAKATHINI HALL",
    Address: "GA-NALA VILLAGE  EMALAHLENI  GA-NALA",
    Latitude: "-26.2618",
    Longitude: "29.1897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17737",
    WardLookupId: "2154",
    VDNumber: "54630042",
    RegisteredPopulation: "1863",
    VotingStationName: "PHOLA MULTI-PURPOSE CENTRE HALL",
    Address: "692 VULINDLELA STREET   PHOLA LOCATION  EMALAHLENI",
    Latitude: "-26.0083",
    Longitude: "29.0363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17738",
    WardLookupId: "2154",
    VDNumber: "54630053",
    RegisteredPopulation: "2191",
    VotingStationName: "THUTHUKANI PRIMARY SCHOOL",
    Address: "693 LERUTLE & FENYANE STREET  PHOLA LOCATION  EMALAHLENI",
    Latitude: "-26.009",
    Longitude: "29.0388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17739",
    WardLookupId: "2154",
    VDNumber: "54700039",
    RegisteredPopulation: "279",
    VotingStationName: "TENT AT IMBALENHLE PRIMARY SCHOOL",
    Address: "HEUWELFONTEIN FARM (R555 ROAD)  EMALAHLENI  KENDAL",
    Latitude: "-26.041",
    Longitude: "28.9721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17740",
    WardLookupId: "2154",
    VDNumber: "54700118",
    RegisteredPopulation: "939",
    VotingStationName: "LIVING WORD CHURCH",
    Address: "2428 DARK CITY  PHOLA LOCATION  EMALAHLENI",
    Latitude: "-25.9945",
    Longitude: "29.0318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17741",
    WardLookupId: "2155",
    VDNumber: "54650448",
    RegisteredPopulation: "3631",
    VotingStationName: "DUNBAR PRIMARY SCHOOL",
    Address: "NEXT TO SPORT GROUND  INFORMAL SETTLEMENT EXT 16  EMALAHLENI",
    Latitude: "-25.854",
    Longitude: "29.0965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17742",
    WardLookupId: "2155",
    VDNumber: "54650516",
    RegisteredPopulation: "2660",
    VotingStationName: "NONHLE DAY CARE CENTER",
    Address: "EMPUMELELWENI  EMALAHLENI  KWAGUQA EXT 11",
    Latitude: "-25.8667",
    Longitude: "29.0933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17743",
    WardLookupId: "2155",
    VDNumber: "54700073",
    RegisteredPopulation: "665",
    VotingStationName: "BALMORAL PRIMARY SCHOOL",
    Address: "FARM EENSAAMHEID  BALMORAL  EMALAHLENI",
    Latitude: "-25.8596",
    Longitude: "28.98",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17744",
    WardLookupId: "2155",
    VDNumber: "54700130",
    RegisteredPopulation: "274",
    VotingStationName: "SIBONGINDAWO SCHOOL",
    Address: "WILGE  WILGE POWER   PHOLA",
    Latitude: "-25.9753",
    Longitude: "28.9853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17745",
    WardLookupId: "2156",
    VDNumber: "54630031",
    RegisteredPopulation: "3045",
    VotingStationName: "MABANDE  HIGH SCHOOL",
    Address: "1042 PHOLA LOCATION  PHOLA   EMALAHLENI",
    Latitude: "-26.0048",
    Longitude: "29.0318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17746",
    WardLookupId: "2156",
    VDNumber: "54630064",
    RegisteredPopulation: "1268",
    VotingStationName: "OGIES COUNCIL OFFICES",
    Address: "MAIN ROAD  OGIES  EMALAHLENI",
    Latitude: "-26.0502",
    Longitude: "29.0556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17747",
    WardLookupId: "2156",
    VDNumber: "54630086",
    RegisteredPopulation: "273",
    VotingStationName: "SIYATHOKOZA SCHOOL",
    Address: "VULINDLELA STREET  EMALAHLENI  PHOLA",
    Latitude: "-26.0012",
    Longitude: "29.0373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17748",
    WardLookupId: "2156",
    VDNumber: "54700028",
    RegisteredPopulation: "754",
    VotingStationName: "KENDAL POWER STATION",
    Address: "POWER STATION PREMISES  KENDAL  EMALAHLENI",
    Latitude: "-26.0824",
    Longitude: "28.9741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17749",
    WardLookupId: "2157",
    VDNumber: "54630019",
    RegisteredPopulation: "3279",
    VotingStationName: "THOLULWAZI PROTECTIVE WORKSHOP",
    Address: "1571 PHOLA LOCATION  EMALAHLENI",
    Latitude: "-25.9945",
    Longitude: "29.039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17750",
    WardLookupId: "2157",
    VDNumber: "54630020",
    RegisteredPopulation: "2588",
    VotingStationName: "MEHLWANA SECONDARY SCHOOL",
    Address: "1573 MKHASIBE   PHOLA LOCATION  EMALAHLENI",
    Latitude: "-25.9951",
    Longitude: "29.0383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17751",
    WardLookupId: "2158",
    VDNumber: "54430051",
    RegisteredPopulation: "1724",
    VotingStationName: "RIETSPRUIT RECREATION CLUB",
    Address: "ROODEPOORT FARM  KRIEL  WITBANK",
    Latitude: "-26.1617",
    Longitude: "29.2013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17752",
    WardLookupId: "2158",
    VDNumber: "54430084",
    RegisteredPopulation: "519",
    VotingStationName: "VIKELWA INDUSTRIAL SCHOOL",
    Address: "TAVISDORP  EMALAHLENI  RIETSPRUIT",
    Latitude: "-26.12",
    Longitude: "29.1252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17753",
    WardLookupId: "2158",
    VDNumber: "54430095",
    RegisteredPopulation: "1217",
    VotingStationName: "LEHLAKA INTERMIDIATE SCHOOL",
    Address: "EMALAHLENI  ",
    Latitude: "-26.1632",
    Longitude: "29.1999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17754",
    WardLookupId: "2158",
    VDNumber: "54700095",
    RegisteredPopulation: "1117",
    VotingStationName: "SPRINGBOK SCHOOL MINE",
    Address: "INGWE DOUGLAS COLLIERY  VAN DYKS DRIFT VILLAGE  EMALAHLENI",
    Latitude: "-26.0822",
    Longitude: "29.3186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17755",
    WardLookupId: "2159",
    VDNumber: "54650066",
    RegisteredPopulation: "2258",
    VotingStationName: "KORFBAL CLUB OF WITBANK",
    Address: "VAN WYK LOUW STREET  WITBANK EXT 8  WITBANK",
    Latitude: "-25.8936",
    Longitude: "29.2232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17756",
    WardLookupId: "2159",
    VDNumber: "54650369",
    RegisteredPopulation: "2182",
    VotingStationName: "KLIPFONTEIN PRIMARY SCHOOL",
    Address: "80 PIENAAR STREET  WITBANK EXT 16  WITBANK",
    Latitude: "-25.9041",
    Longitude: "29.2422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17757",
    WardLookupId: "2159",
    VDNumber: "54650819",
    RegisteredPopulation: "377",
    VotingStationName: "4 U 2 STORE BUILDING",
    Address: "SPRINGBOK STREET  EMALAHLENI  TASBET PARK",
    Latitude: "-25.918",
    Longitude: "29.2407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17758",
    WardLookupId: "2160",
    VDNumber: "54650022",
    RegisteredPopulation: "2156",
    VotingStationName: "INDLELA COUNTRY ESTATE",
    Address: "50 NITA AVENUE  EMALAHLENI  EMALLAHLENI",
    Latitude: "-25.8832",
    Longitude: "29.254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17759",
    WardLookupId: "2160",
    VDNumber: "54650077",
    RegisteredPopulation: "1809",
    VotingStationName: "PATRIOT SECONDARY SCHOOL",
    Address: "BIRK HOLTZ STREET  DEL JUDOR EXT 2  WITBANK",
    Latitude: "-25.8989",
    Longitude: "29.2429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17760",
    WardLookupId: "2160",
    VDNumber: "54650639",
    RegisteredPopulation: "1275",
    VotingStationName: "BANKENVELD CURRO PRIVATE SCHOOL",
    Address: "GOUD  BANKENVELD  EMALAHLENI",
    Latitude: "-25.9052",
    Longitude: "29.2767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17761",
    WardLookupId: "2160",
    VDNumber: "54650752",
    RegisteredPopulation: "839",
    VotingStationName: "HIGHVELD PARK (TENT)",
    Address: "EMALAHLENI  ",
    Latitude: "-25.8556",
    Longitude: "29.2647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17762",
    WardLookupId: "4222",
    VDNumber: "54530029",
    RegisteredPopulation: "2483",
    VotingStationName: "MAZIYA COMBINED SCHOOL",
    Address: "DUBE STREET  KWAZAMOKUHLE  STEVE TSHWETE",
    Latitude: "-26.1314",
    Longitude: "29.7331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17763",
    WardLookupId: "4223",
    VDNumber: "54530018",
    RegisteredPopulation: "1417",
    VotingStationName: "HENDRINA PRIMARY SCHOOL",
    Address: "NKOSI STREET  KWAZAMOKUHLE  HENDRINA",
    Latitude: "-26.1367",
    Longitude: "29.7285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17764",
    WardLookupId: "4223",
    VDNumber: "54530052",
    RegisteredPopulation: "3079",
    VotingStationName: "REPUBLIC AND TRINITY CHURCH",
    Address: "4588 KWAZAMOKUHLE  HENDRINA",
    Latitude: "-26.1402",
    Longitude: "29.7303",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17765",
    WardLookupId: "4224",
    VDNumber: "54530030",
    RegisteredPopulation: "2187",
    VotingStationName: "HENDRINA MUNICIPAL OFFICES",
    Address: "MOUTON  HENDRINA  MIDDELBURG",
    Latitude: "-26.1606",
    Longitude: "29.716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17766",
    WardLookupId: "4224",
    VDNumber: "54530041",
    RegisteredPopulation: "1499",
    VotingStationName: "CHURCH OF CHRIST",
    Address: "KWAZAMOKUHLE  HENDRINA",
    Latitude: "-26.1249",
    Longitude: "29.7308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17767",
    WardLookupId: "4225",
    VDNumber: "54680025",
    RegisteredPopulation: "1751",
    VotingStationName: "KOORNFONTEIN PRIMARY SCHOOL",
    Address: "KOMATI  KOORNFONTEIN  MIDDELBURG",
    Latitude: "-26.1005",
    Longitude: "29.4565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17768",
    WardLookupId: "4225",
    VDNumber: "54680294",
    RegisteredPopulation: "519",
    VotingStationName: "BANK RECREATIONAL CLUB",
    Address: "MP313 - STEVE TSHWETE [MIDDELBURG]  ",
    Latitude: "-26.0018",
    Longitude: "29.4555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17769",
    WardLookupId: "4225",
    VDNumber: "54680306",
    RegisteredPopulation: "646",
    VotingStationName: "KOORNFONTEIN RECREATIONAL HALL",
    Address: "MP313 - STEVE TSHWETE [MIDDELBURG]  ",
    Latitude: "-26.084",
    Longitude: "29.4362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17770",
    WardLookupId: "4225",
    VDNumber: "54680328",
    RegisteredPopulation: "827",
    VotingStationName: "KOMATI MUNICIPAL OFFICE",
    Address: "KOMATI  MIDDELBURG",
    Latitude: "-26.0947",
    Longitude: "29.4609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17771",
    WardLookupId: "4226",
    VDNumber: "54680069",
    RegisteredPopulation: "708",
    VotingStationName: "MOLARE",
    Address: "HENDRINA ROAD  KANHYM ESTATES  MIDDELBURG",
    Latitude: "-25.8979",
    Longitude: "29.5677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17772",
    WardLookupId: "4226",
    VDNumber: "54680070",
    RegisteredPopulation: "2698",
    VotingStationName: "CYNTHIA MURPHY HALL",
    Address: "NJABULO  PULLENS HOPE  MIDDELBURG",
    Latitude: "-26.0145",
    Longitude: "29.5928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17773",
    WardLookupId: "4227",
    VDNumber: "54680058",
    RegisteredPopulation: "987",
    VotingStationName: "MIDDELBURG MINE SERVICES SCHOOL",
    Address: "INKANYEZE STREET  NALEDI VILLAGE  MIDDELBURG MINE",
    Latitude: "-25.9058",
    Longitude: "29.3857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17774",
    WardLookupId: "4227",
    VDNumber: "54680317",
    RegisteredPopulation: "603",
    VotingStationName: "EVERGREEN PRIMARY SCHOOL",
    Address: "MP313 - STEVE TSHWETE [MIDDELBURG]  ",
    Latitude: "-25.8443",
    Longitude: "29.4594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17775",
    WardLookupId: "4227",
    VDNumber: "54680441",
    RegisteredPopulation: "1847",
    VotingStationName: "ROCKDALE OPEN SPACE",
    Address: "ROCKDALE STREET  ROCKDALE  MIDDELBURG",
    Latitude: "-25.824",
    Longitude: "29.5308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17776",
    WardLookupId: "4227",
    VDNumber: "54680452",
    RegisteredPopulation: "1454",
    VotingStationName: "PHUMELELA SECONDARY SCHOOL",
    Address: "MIDDELBURG  EXTENSION 24  MIDDELBURG",
    Latitude: "-25.8138",
    Longitude: "29.5221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17777",
    WardLookupId: "4228",
    VDNumber: "54680081",
    RegisteredPopulation: "2017",
    VotingStationName: "LAERSKOOL RIETKUIL",
    Address: "1ST STREET  RIETKUIL  RIETKUIL",
    Latitude: "-25.9495",
    Longitude: "29.8073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17778",
    WardLookupId: "4228",
    VDNumber: "54680339",
    RegisteredPopulation: "202",
    VotingStationName: "ARNOT HALL",
    Address: "HENDRINA ROAD  ARNOT  MIDDELBURG",
    Latitude: "-25.9323",
    Longitude: "29.7763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17779",
    WardLookupId: "4228",
    VDNumber: "54680395",
    RegisteredPopulation: "654",
    VotingStationName: "SIKHULULIWE PRIMARY SCHOOL",
    Address: "ARNOT STATION  SIKHULULIWE TOWNSHIP  MIDDELBURG",
    Latitude: "-25.7623",
    Longitude: "29.7786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17780",
    WardLookupId: "4229",
    VDNumber: "54600218",
    RegisteredPopulation: "2144",
    VotingStationName: "NASARET COMMUNITY HALL",
    Address: "FORD NAPIER STREET  NASARET  MIDDELBURG",
    Latitude: "-25.8015",
    Longitude: "29.5105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17781",
    WardLookupId: "4229",
    VDNumber: "54680261",
    RegisteredPopulation: "2495",
    VotingStationName: "KHUL`UNOLWAZI PRIMARY SCHOOL",
    Address:
      "MP313 - STEVE TSHWETE [MIDDELBURG]  MP313 -7031 EXT 24  MP313 - STEVE TSHWETE [MIDDELBURG]",
    Latitude: "-25.8105",
    Longitude: "29.5156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17782",
    WardLookupId: "4230",
    VDNumber: "54680092",
    RegisteredPopulation: "616",
    VotingStationName: "IPANI PRIMARY SCHOOL",
    Address: "P/BAG 251836  MP313 - STEVE TSHWETE [MIDDELBURG]  BEESTEPAN FARM",
    Latitude: "-25.8039",
    Longitude: "29.7041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17783",
    WardLookupId: "4230",
    VDNumber: "54680115",
    RegisteredPopulation: "292",
    VotingStationName: "SULINYEMBEZI TEMPORARY VOTING STATION",
    Address: "417 JS FARM  NOOITGEDACHT FARM  MIDDELBURG",
    Latitude: "-25.71",
    Longitude: "29.7873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17784",
    WardLookupId: "4230",
    VDNumber: "54680126",
    RegisteredPopulation: "406",
    VotingStationName: "BANK FONTEIN COMBINED SCHOOL",
    Address: "C375 MIDDELBURG STOFFBERG   FARM BANKFONTEIN 375 JS  MIDDELBURG",
    Latitude: "-25.655",
    Longitude: "29.6353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17785",
    WardLookupId: "4230",
    VDNumber: "54680283",
    RegisteredPopulation: "230",
    VotingStationName: "JEBSA STORE (TENT)",
    Address: "ELANDLAAGTE  MIDDELBURG",
    Latitude: "-25.6415",
    Longitude: "29.5581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17786",
    WardLookupId: "4230",
    VDNumber: "54680418",
    RegisteredPopulation: "90",
    VotingStationName: "WOLMA FARM SCHOOL",
    Address: "STOFBURG ROAD  MIDDELBURG  MIDDELBURG",
    Latitude: "-25.7283",
    Longitude: "29.5426",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17787",
    WardLookupId: "4230",
    VDNumber: "54680429",
    RegisteredPopulation: "990",
    VotingStationName: "STEVE TSHWETE BOARDING SCHOOL",
    Address: ".  SOMAPHEPHA  MIDDELBURG",
    Latitude: "-25.6289",
    Longitude: "29.6257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17788",
    WardLookupId: "4230",
    VDNumber: "54680463",
    RegisteredPopulation: "248",
    VotingStationName: "HAMMELFONTEIN PRIMARY SCHOOL",
    Address: "0 EEDE  MIDDELBURG  MIDDELBURG",
    Latitude: "-25.8907",
    Longitude: "29.6287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17789",
    WardLookupId: "4231",
    VDNumber: "54600083",
    RegisteredPopulation: "2281",
    VotingStationName: "EASTDENE COMMUNITY HALL",
    Address: "92 VERDOORN ST  EASTDENE  MIDDELBURG",
    Latitude: "-25.7718",
    Longitude: "29.4803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17790",
    WardLookupId: "4231",
    VDNumber: "54680272",
    RegisteredPopulation: "2098",
    VotingStationName: "CHRISTIAN BIBLE CHURCH",
    Address: "N/A  RONDEBOSCH  MIDDELBURG",
    Latitude: "-25.7816",
    Longitude: "29.5034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17791",
    WardLookupId: "4232",
    VDNumber: "54600162",
    RegisteredPopulation: "3041",
    VotingStationName: "FAITH REVIVAL MIRACLE CENTRES INTERNATIONAL",
    Address: "HOPE CITY  HOPE CITY EXT 18  MIDDELBURG - MP",
    Latitude: "-25.7951",
    Longitude: "29.4671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17792",
    WardLookupId: "4233",
    VDNumber: "54600140",
    RegisteredPopulation: "4856",
    VotingStationName: "CORPUS CRISTI KERK",
    Address: "23 KRANSBERG STRAAT  MIDDELBURG  AERORAND",
    Latitude: "-25.8",
    Longitude: "29.4554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17793",
    WardLookupId: "4234",
    VDNumber: "54600173",
    RegisteredPopulation: "2086",
    VotingStationName: "LAERSKOOL CR SWART",
    Address: "30A SUID ST  MIDDELBURG  MIDDELBURG",
    Latitude: "-25.7786",
    Longitude: "29.4685",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17794",
    WardLookupId: "4234",
    VDNumber: "54600285",
    RegisteredPopulation: "1026",
    VotingStationName: "BEZUIDENHOUT HALL",
    Address: "MP313 - STEVE TSHWETE [MIDDELBURG]  ",
    Latitude: "-25.7612",
    Longitude: "29.4638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17795",
    WardLookupId: "4234",
    VDNumber: "54600320",
    RegisteredPopulation: "1538",
    VotingStationName: "MIDDELBURG PRIMARY SCHOOL",
    Address: "MAGAGULA STREET  MIDDELBURG  MIDDELBURG",
    Latitude: "-25.7791",
    Longitude: "29.4529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17796",
    WardLookupId: "4235",
    VDNumber: "54600151",
    RegisteredPopulation: "2132",
    VotingStationName: "TENT (MIDDELBURG HOSPITAL OPEN SPACE)",
    Address: "MP313 - STEVE TSHWETE [MIDDELBURG]  ",
    Latitude: "-25.7745",
    Longitude: "29.4489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17797",
    WardLookupId: "4235",
    VDNumber: "54600230",
    RegisteredPopulation: "1436",
    VotingStationName: "NG KERK",
    Address:
      "CNR VAN RIEBEECK AND GUSTAV PRELLER  STEVE TSHWETE [MIDDELBURG]  STEVE TSHWETE [MIDDELBURG]",
    Latitude: "-25.7733",
    Longitude: "29.4379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17798",
    WardLookupId: "4236",
    VDNumber: "54600027",
    RegisteredPopulation: "2459",
    VotingStationName: "KANONKOP LAERSKOOL",
    Address: "11 SERING ST  KANONKOP  MIDDELBURG",
    Latitude: "-25.751",
    Longitude: "29.4708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17799",
    WardLookupId: "4236",
    VDNumber: "54600049",
    RegisteredPopulation: "1684",
    VotingStationName: "KANONKOP HOÃ‹RSKOOL",
    Address: "5 JAKARANDA ST  KANONKOP  MIDDELBURG",
    Latitude: "-25.7425",
    Longitude: "29.4807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17800",
    WardLookupId: "4237",
    VDNumber: "54600016",
    RegisteredPopulation: "1617",
    VotingStationName: "DENNISIG, PARK 5075 NEXT TO CHURCH OF TODAY (TENT)",
    Address: "PARK 5075  DENNESIG  MIDDELBURG",
    Latitude: "-25.736",
    Longitude: "29.4639",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17801",
    WardLookupId: "4237",
    VDNumber: "54680238",
    RegisteredPopulation: "1851",
    VotingStationName: "DENNESIG LAERSKOOL",
    Address: "2 C R SWART STREET  DENNESIG  MIDDELBURG",
    Latitude: "-25.7332",
    Longitude: "29.4784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17802",
    WardLookupId: "4238",
    VDNumber: "54600184",
    RegisteredPopulation: "2402",
    VotingStationName: "KINGDOM AS WORSHIP CENTRE",
    Address: ".  NEWTOWN  MIDDELBURG",
    Latitude: "-25.7388",
    Longitude: "29.4281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17803",
    WardLookupId: "4239",
    VDNumber: "54600106",
    RegisteredPopulation: "2915",
    VotingStationName: "ERIC JIYANE HALL",
    Address: "NGWAKO STREET  MHLUZI  MIDDELBURG",
    Latitude: "-25.7508",
    Longitude: "29.4473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17804",
    WardLookupId: "4240",
    VDNumber: "54600094",
    RegisteredPopulation: "2308",
    VotingStationName: "REATLEGILE PRIMARY SCHOOL",
    Address: "BALOI STREET  MHLUZI  MIDDELBURG",
    Latitude: "-25.7472",
    Longitude: "29.4478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17805",
    WardLookupId: "4240",
    VDNumber: "54600274",
    RegisteredPopulation: "814",
    VotingStationName: "EKWAZINI HIGH SCHOOL",
    Address: "MP313 - STEVE TSHWETE [MIDDELBURG]  ",
    Latitude: "-25.7529",
    Longitude: "29.4407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17806",
    WardLookupId: "4241",
    VDNumber: "54600139",
    RegisteredPopulation: "1881",
    VotingStationName: "ELUSINDISWENI PRIMARY SCHOOL",
    Address: "4TH AVENUE  MHLUZI  MIDDELBURG",
    Latitude: "-25.7563",
    Longitude: "29.4353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17807",
    WardLookupId: "4241",
    VDNumber: "54600263",
    RegisteredPopulation: "1141",
    VotingStationName: "TENT AT MHLUZI SWIMMING POOL",
    Address: "4TH AVENUE  VUKA  MIDDELBURG",
    Latitude: "-25.7577",
    Longitude: "29.4337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17808",
    WardLookupId: "4241",
    VDNumber: "54600296",
    RegisteredPopulation: "811",
    VotingStationName: "BREINTJIELAND CRECHE",
    Address: "BEYERS NAUDE  MIDDELBURG  MIDDELBURG - MP",
    Latitude: "-25.7621",
    Longitude: "29.4574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17809",
    WardLookupId: "4242",
    VDNumber: "54600038",
    RegisteredPopulation: "3243",
    VotingStationName: "THUSHANANG PRIMARY SCHOOL",
    Address: "MELATO AVENUE  MHLUZI EXT 1  MIDDELBURG",
    Latitude: "-25.7632",
    Longitude: "29.4277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17810",
    WardLookupId: "4243",
    VDNumber: "54600117",
    RegisteredPopulation: "787",
    VotingStationName: "MANYANO PRIMARY SCHOOL",
    Address: "MATLAPA STREET  MHLUZI  MIDDELBURG",
    Latitude: "-25.7459",
    Longitude: "29.4391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17811",
    WardLookupId: "4243",
    VDNumber: "54600128",
    RegisteredPopulation: "2082",
    VotingStationName: "MPHANAMA HIGH SCHOOL",
    Address: "TSWELOPELE AVENUE  MHLUZI  MIDDELBURG",
    Latitude: "-25.7524",
    Longitude: "29.431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17812",
    WardLookupId: "4243",
    VDNumber: "54600308",
    RegisteredPopulation: "879",
    VotingStationName: "CHROMEVILLE HALL",
    Address: "THEMBALETHU  MHLUZI  MIDDELBURG",
    Latitude: "-25.7468",
    Longitude: "29.4333",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17813",
    WardLookupId: "4244",
    VDNumber: "54600050",
    RegisteredPopulation: "2171",
    VotingStationName: "ZIKHUPHULE PRIMARY SCHOOL",
    Address: "CHOCOLATE STREET  MHLUZI TOWSHIP  MIDDELBURG",
    Latitude: "-25.7548",
    Longitude: "29.427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17814",
    WardLookupId: "4244",
    VDNumber: "54600229",
    RegisteredPopulation: "2416",
    VotingStationName: "SOFUNDA SECONDARY SCHOOL",
    Address: "6661 ZANDILE ST  MHLUZI EXT 2  MIDDELBURG",
    Latitude: "-25.7538",
    Longitude: "29.4244",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17815",
    WardLookupId: "4245",
    VDNumber: "54600072",
    RegisteredPopulation: "2863",
    VotingStationName: "MTHOMBENI PRIMARY SCHOOL",
    Address: "MTHUNZINI  MHLUZI EXT 4  MIDDELBURG",
    Latitude: "-25.7628",
    Longitude: "29.4213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17816",
    WardLookupId: "4245",
    VDNumber: "54600241",
    RegisteredPopulation: "1454",
    VotingStationName: "EXT 7 OPEN SPACE, MHLUZI (TENT)",
    Address: "NGWENYAMA  STEVE TSHWETE MP 313  MIDDELBURG - MP",
    Latitude: "-25.7687",
    Longitude: "29.4164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17817",
    WardLookupId: "4246",
    VDNumber: "54600207",
    RegisteredPopulation: "3182",
    VotingStationName: "MUNICIPAL CLINIC EXT 8",
    Address: "IKAGENG ST  MHLUZI EXT 8  MIDDELBURG",
    Latitude: "-25.7593",
    Longitude: "29.4193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17818",
    WardLookupId: "4246",
    VDNumber: "54600342",
    RegisteredPopulation: "794",
    VotingStationName: "KUTANA CENTRE  UMHLUZI EXT.8",
    Address: "CNR TSWELOPELE, IKAGENG STREET  MHLUZI  MIDDELBURG",
    Latitude: "-25.76",
    Longitude: "29.4106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17819",
    WardLookupId: "4247",
    VDNumber: "54600061",
    RegisteredPopulation: "2855",
    VotingStationName: "MVUZO PRIMARY SCHOOL",
    Address: "MANDELA DRIVE  MHLUZI EXT 5  MIDDELBURG",
    Latitude: "-25.7623",
    Longitude: "29.4106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17820",
    WardLookupId: "4247",
    VDNumber: "54600319",
    RegisteredPopulation: "1596",
    VotingStationName: "BONISANANI PRE-SCHOLL",
    Address: "IKAGENG  EXTENSION 6  MIDDELBURG",
    Latitude: "-25.7633",
    Longitude: "29.4059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17821",
    WardLookupId: "4248",
    VDNumber: "54600195",
    RegisteredPopulation: "3078",
    VotingStationName: "NEWTOWN (TENT)",
    Address: "MP313 - STEVE TSHWETE [MIDDELBURG]  ",
    Latitude: "-25.7432",
    Longitude: "29.4212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17822",
    WardLookupId: "4248",
    VDNumber: "54600331",
    RegisteredPopulation: "529",
    VotingStationName: "SIKHULA NGOLWAZI CRECHE`",
    Address: "N/A  TOKOLOGO  MIDDELBURG",
    Latitude: "-25.7664",
    Longitude: "29.3971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17823",
    WardLookupId: "4249",
    VDNumber: "54600252",
    RegisteredPopulation: "4680",
    VotingStationName: "LD MOETANALO SECONDARY SCHOOL",
    Address:
      "8876 MAKATANE   STEVE TSHWETE [MIDDELBURG]  STEVE TSHWETE [MIDDELBURG]",
    Latitude: "-25.7678",
    Longitude: "29.4061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17824",
    WardLookupId: "4249",
    VDNumber: "54680104",
    RegisteredPopulation: "277",
    VotingStationName: "OLIFANT RIVER LODGE",
    Address: "1 PRESIDENTSRUS  MIDDELBURG  MIDDELBURG",
    Latitude: "-25.762",
    Longitude: "29.3127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17825",
    WardLookupId: "4249",
    VDNumber: "54680407",
    RegisteredPopulation: "443",
    VotingStationName: "UITKYK SPORTS GROUND (TENT)",
    Address: "UITKYK  MIDDELBURG",
    Latitude: "-25.8317",
    Longitude: "29.4199",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17826",
    WardLookupId: "4249",
    VDNumber: "54680485",
    RegisteredPopulation: "360",
    VotingStationName: "TENT AT WARD 28",
    Address: ".  NEWTOWN PROPER  MIDDELBURG",
    Latitude: "-25.7351",
    Longitude: "29.4185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17827",
    WardLookupId: "4250",
    VDNumber: "54680148",
    RegisteredPopulation: "2058",
    VotingStationName: "BATLAGAE PRIMARY SCHOOL",
    Address: "MP313 - STEVE TSHWETE [MIDDELBURG]  ",
    Latitude: "-25.6181",
    Longitude: "29.435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17828",
    WardLookupId: "4250",
    VDNumber: "54680159",
    RegisteredPopulation: "707",
    VotingStationName: "MKHULU COMBINED SCHOOL",
    Address: "012 N/A  FARM LAMMERSKOP  DOORNKOP",
    Latitude: "-25.5826",
    Longitude: "29.4554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17829",
    WardLookupId: "4250",
    VDNumber: "54680430",
    RegisteredPopulation: "1511",
    VotingStationName: "KAMAMPIMPANA MULTI PURPOSE CENTER",
    Address: "N11 GROBLERSDAL ROAD  DOORKOP  MIDDELBURG",
    Latitude: "-25.5924",
    Longitude: "29.4317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17830",
    WardLookupId: "4250",
    VDNumber: "54680474",
    RegisteredPopulation: "313",
    VotingStationName: "KRANSPOORT TVS",
    Address: "N11  KRANSPOORT  MIDDELBURG",
    Latitude: "-25.4378",
    Longitude: "29.4206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17831",
    WardLookupId: "4251",
    VDNumber: "54490057",
    RegisteredPopulation: "2533",
    VotingStationName: "ENTOKOZWENI COMMUNITY HALL",
    Address: "ENTOKOZWENI  BELFAST  SIYATHUTHUKA",
    Latitude: "-25.6802",
    Longitude: "30.0017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17832",
    WardLookupId: "4251",
    VDNumber: "54660056",
    RegisteredPopulation: "215",
    VotingStationName: "ZOEKOP FARM CHURCH",
    Address: "ZOEKOP  BELFAST  BELFAST",
    Latitude: "-25.8066",
    Longitude: "29.9843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17833",
    WardLookupId: "4251",
    VDNumber: "54660102",
    RegisteredPopulation: "475",
    VotingStationName: "THUSONG COMMUNITY HALL",
    Address: "WONDERFONTEIN  ",
    Latitude: "-25.806",
    Longitude: "29.8947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17834",
    WardLookupId: "4251",
    VDNumber: "54660179",
    RegisteredPopulation: "315",
    VotingStationName: "BLOMPLAAS PRIMARY SCHOOL",
    Address: "PAARDEPLAAS HADECO  BELFAST  BELFAST",
    Latitude: "-25.7392",
    Longitude: "30.006",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17835",
    WardLookupId: "4251",
    VDNumber: "54660191",
    RegisteredPopulation: "295",
    VotingStationName: "MAPHUMULA OPEN SPACE (TENT)",
    Address: "OLD MIDDELBURG ROAD  WONDERFONTEIN  WONDERFONTEIN",
    Latitude: "-25.8041",
    Longitude: "29.8389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17836",
    WardLookupId: "4251",
    VDNumber: "54660203",
    RegisteredPopulation: "186",
    VotingStationName: "NHLUPHEKO PRIMARY SCHOOL",
    Address: "SUNBURY  WONDERFONTEIN",
    Latitude: "-25.7649",
    Longitude: "29.9549",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17837",
    WardLookupId: "4252",
    VDNumber: "54490024",
    RegisteredPopulation: "871",
    VotingStationName: "ROMAN CATHOLIC HALL",
    Address: "61 MAYOR STREET  SIYATHUTHUKA  BELFAST",
    Latitude: "-25.6761",
    Longitude: "29.9878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17838",
    WardLookupId: "4252",
    VDNumber: "54660045",
    RegisteredPopulation: "106",
    VotingStationName: "WINTERSHOEK FARM (TENT)",
    Address: "WINTERSHOEK  BELFAST",
    Latitude: "-25.725",
    Longitude: "29.8884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17839",
    WardLookupId: "4252",
    VDNumber: "54660090",
    RegisteredPopulation: "169",
    VotingStationName: "MORESON FARM HOUSE",
    Address: "SPITSKOP  EMAKHAZENI  KWAGGASKOP",
    Latitude: "-25.6156",
    Longitude: "29.9235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17840",
    WardLookupId: "4252",
    VDNumber: "54660180",
    RegisteredPopulation: "143",
    VotingStationName: "UITVLUGT FARM  (TENT)",
    Address: "UITVLUGT FARM  EMAKHAZENI  UITVLUGT FARM",
    Latitude: "-25.6531",
    Longitude: "29.8444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17841",
    WardLookupId: "4252",
    VDNumber: "54680036",
    RegisteredPopulation: "515",
    VotingStationName: "MOOSA SHOPPING CENTRE",
    Address: "STOFFBERG ROAD/MOOSA SHOPPING   STOFFBERG  STOFFBERG",
    Latitude: "-25.4141",
    Longitude: "29.8249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17842",
    WardLookupId: "4252",
    VDNumber: "54680160",
    RegisteredPopulation: "174",
    VotingStationName: "AUGUSTINGS PRIMARY SCHOOL",
    Address: "WELVERDIEN ROAD  FARM WELVERDIEN 201 JS  BELFAST/DULLSTROOM",
    Latitude: "-25.5052",
    Longitude: "29.6681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17843",
    WardLookupId: "4252",
    VDNumber: "54680171",
    RegisteredPopulation: "484",
    VotingStationName: "ONGESIENS PRIMARY SCHOOL",
    Address:
      "PLOT 5/STOFFBERG ROAD  FARM ONGEZIEN 365 JS  STOFFBERG/EMAKHAZENI",
    Latitude: "-25.6036",
    Longitude: "29.7436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17844",
    WardLookupId: "4252",
    VDNumber: "54680249",
    RegisteredPopulation: "187",
    VotingStationName: "STERKLOOP HALL EKOSINI",
    Address: "WAPADSKLOOF ROAD  BELFAST  STOFFBERG",
    Latitude: "-25.5387",
    Longitude: "29.8555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17845",
    WardLookupId: "4252",
    VDNumber: "54680340",
    RegisteredPopulation: "204",
    VotingStationName: "TENT AT POOLZEE OPEN FIELD",
    Address: "STOFFBERG/MIDDELBURG ROSD  STOFFBERG  POOLZEE FARM",
    Latitude: "-25.505",
    Longitude: "29.7877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17846",
    WardLookupId: "4253",
    VDNumber: "54490035",
    RegisteredPopulation: "911",
    VotingStationName: "SIYATHUTHUKA COMMUNITY HALL",
    Address: "ZAKHENI  SIYATHUTHUKA  EMAKHAZENI",
    Latitude: "-25.6773",
    Longitude: "29.9916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17847",
    WardLookupId: "4253",
    VDNumber: "54490046",
    RegisteredPopulation: "1066",
    VotingStationName: "TWELVE APOSTOLIC CHURCH",
    Address: "ZAKHENI  BELFAST  SIYATHUTHUKA",
    Latitude: "-25.6711",
    Longitude: "29.991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17848",
    WardLookupId: "4253",
    VDNumber: "54490068",
    RegisteredPopulation: "685",
    VotingStationName: "KHAYALAMI SECONDARY SCHOOL HALL",
    Address: "1820 KHAYALAMI STREET  SIYATHUTHUKA  EMAKHAZENI",
    Latitude: "-25.6793",
    Longitude: "29.9926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17849",
    WardLookupId: "4253",
    VDNumber: "54490080",
    RegisteredPopulation: "614",
    VotingStationName: "SIYATHUTHUKA MUNICIPAL OFFICES",
    Address: "SIYATHUTHUKA  EMAKHAZENI",
    Latitude: "-25.6765",
    Longitude: "29.9959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17850",
    WardLookupId: "4254",
    VDNumber: "54500015",
    RegisteredPopulation: "1974",
    VotingStationName: "MPILONHLE PRIMARY SCHOOL",
    Address: "190 STAND 190  SAKHELWE  DULLSTROOM",
    Latitude: "-25.4097",
    Longitude: "30.088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17851",
    WardLookupId: "4254",
    VDNumber: "54500026",
    RegisteredPopulation: "507",
    VotingStationName: "EPILEPSY HALL",
    Address: "603 LIGWALAGWALA STREET  BELFAST  BELFAST",
    Latitude: "-25.422",
    Longitude: "30.1085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17852",
    WardLookupId: "4254",
    VDNumber: "54500037",
    RegisteredPopulation: "685",
    VotingStationName: "SIYIFUNILE SECONDARY SCHOOL",
    Address: "SAKHELWE  DULLSTROOM",
    Latitude: "-25.4122",
    Longitude: "30.0963",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17853",
    WardLookupId: "4254",
    VDNumber: "54660023",
    RegisteredPopulation: "227",
    VotingStationName: "HAMBANATHI PRIMARY SCHOOL",
    Address: "SANTA ESTATE LYDENBURG ROAD  FARM LUNSKLIP  DULLSTROOM",
    Latitude: "-25.3425",
    Longitude: "30.1712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17854",
    WardLookupId: "4254",
    VDNumber: "54660124",
    RegisteredPopulation: "133",
    VotingStationName: "FIELD AND STREAM (TENT)",
    Address: "TONTELDOOS  ROAD  DULLSTROOM  BELFAST",
    Latitude: "-25.386",
    Longitude: "29.9979",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17855",
    WardLookupId: "4254",
    VDNumber: "54660214",
    RegisteredPopulation: "84",
    VotingStationName: "HIGHLANDS GATE (TENT)",
    Address: "KRUISFONTEIN  DULLSTROOM  DULLSTROOM",
    Latitude: "-25.444",
    Longitude: "30.2033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17856",
    WardLookupId: "4255",
    VDNumber: "54660034",
    RegisteredPopulation: "111",
    VotingStationName: "BELHAMBRA FIELD (TENT)",
    Address: "SCHOEMANSKLOOF ROAD  MACHADODORP  SCHOEMANSKLOOF",
    Latitude: "-25.4459",
    Longitude: "30.4487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17857",
    WardLookupId: "4255",
    VDNumber: "54660067",
    RegisteredPopulation: "1023",
    VotingStationName: "SIKHULILE SECONDARY SCHOOL",
    Address: "572 HECTOR PETERSON STREET  EMTHONJENI  MACHADODORP",
    Latitude: "-25.6951",
    Longitude: "30.2457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17858",
    WardLookupId: "4255",
    VDNumber: "54660157",
    RegisteredPopulation: "316",
    VotingStationName: "MAKOSEENI PRIMARY SCHOOL",
    Address: "MAKOSEENI VILLAGE, SAPPI ELAND  MACHADODORP  BELFAST",
    Latitude: "-25.497",
    Longitude: "30.4988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17859",
    WardLookupId: "4255",
    VDNumber: "54690015",
    RegisteredPopulation: "120",
    VotingStationName: "TORBURNLEA PRIMARY SCHOOL",
    Address: "SLAAIHOEK  TORBURNLEA  WATERVAL BOVEN",
    Latitude: "-25.727",
    Longitude: "30.3482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17860",
    WardLookupId: "4255",
    VDNumber: "54690026",
    RegisteredPopulation: "180",
    VotingStationName: "MAMRE PRIMARY SCHOOL",
    Address: "MAMRE PLANTATIONS  SLAAIHOEK  WATERVAL BOVEN / MACHADODORP",
    Latitude: "-25.6955",
    Longitude: "30.5501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17861",
    WardLookupId: "4255",
    VDNumber: "54690037",
    RegisteredPopulation: "339",
    VotingStationName: "AIRLIE FIELDS OPEN SPACE TENT",
    Address: "RYRON ESTATE  NGODWANA  NGODWANA",
    Latitude: "-25.61",
    Longitude: "30.4784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17862",
    WardLookupId: "4255",
    VDNumber: "54690048",
    RegisteredPopulation: "245",
    VotingStationName: "WATERVAL BOVEN COMMUNITY HALL",
    Address: "WATERVAL BOVEN  ",
    Latitude: "-25.6369",
    Longitude: "30.3189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17863",
    WardLookupId: "4256",
    VDNumber: "54550010",
    RegisteredPopulation: "1139",
    VotingStationName: "MACHADO COUNCIL CHAMBER",
    Address: "MACHADODORP COUNCIL CHAMBER  MACHADODORP  BELFAST",
    Latitude: "-25.6629",
    Longitude: "30.2508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17864",
    WardLookupId: "4256",
    VDNumber: "54550021",
    RegisteredPopulation: "1186",
    VotingStationName: "DUMEZIZWENI PRIMARY SCHOOL",
    Address: "357 EMTHONJENI   BELFAST",
    Latitude: "-25.6956",
    Longitude: "30.2502",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17865",
    WardLookupId: "4256",
    VDNumber: "54550032",
    RegisteredPopulation: "1329",
    VotingStationName: "VUSI MASANGO HALL",
    Address: "COMMUNITY HALL  BELFAST  BELFAST",
    Latitude: "-25.6852",
    Longitude: "30.2487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17866",
    WardLookupId: "4256",
    VDNumber: "54660078",
    RegisteredPopulation: "196",
    VotingStationName: "WINNARSPOORT OPEN FIELD (TENT)",
    Address: "WINNARSPOORT ROAD  ENTOKOZWENI  ENTOKOZWENI",
    Latitude: "-25.6247",
    Longitude: "30.1922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17867",
    WardLookupId: "4256",
    VDNumber: "54660135",
    RegisteredPopulation: "95",
    VotingStationName: "MADOVA OLD SCHOOL",
    Address: "PALMER,DULLSTROOM ROAD  ZWARTKOPPIES  BELFAST",
    Latitude: "-25.5087",
    Longitude: "30.0434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17868",
    WardLookupId: "4257",
    VDNumber: "54640010",
    RegisteredPopulation: "1732",
    VotingStationName: "IMEMEZA HIGH SCHOOL",
    Address: "EMGWENYA LOCATION  EMGWENYA  WATERVAL BOVEN",
    Latitude: "-25.64",
    Longitude: "30.3439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17869",
    WardLookupId: "4257",
    VDNumber: "54640021",
    RegisteredPopulation: "830",
    VotingStationName: "OOSTERLIJN PRIMARY SCHOOL",
    Address: "5TH AVENUE  WATERVALBOVEN  BELFAST",
    Latitude: "-25.6436",
    Longitude: "30.3331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17870",
    WardLookupId: "4257",
    VDNumber: "54640032",
    RegisteredPopulation: "1102",
    VotingStationName: "EBHUDLWENI PRIMARY SCHOOL",
    Address: "EMGWENYA LOCATION  BELFAST  BELFAST",
    Latitude: "-25.6413",
    Longitude: "30.3409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17871",
    WardLookupId: "4258",
    VDNumber: "54490013",
    RegisteredPopulation: "1971",
    VotingStationName: "BEYERS NAUDE COMMUNITY HALL",
    Address: "24 SCHEEPERS  EMAKHAZENI  EMAKHAZENI",
    Latitude: "-25.6927",
    Longitude: "30.0348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17872",
    WardLookupId: "4258",
    VDNumber: "54490091",
    RegisteredPopulation: "400",
    VotingStationName: "DUTCH CHURCH",
    Address: "MADALA  EMAKHAZENI  EMAKHAZENI",
    Latitude: "-25.6862",
    Longitude: "30.0442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17873",
    WardLookupId: "4258",
    VDNumber: "54660113",
    RegisteredPopulation: "419",
    VotingStationName: "KAMLIMI OPEN SPACE (TENT)",
    Address: "EERSTELINGSFONTEIN  BELFAST  EERSTELINGSFONTEIN",
    Latitude: "-25.8786",
    Longitude: "30.013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17874",
    WardLookupId: "4258",
    VDNumber: "54660146",
    RegisteredPopulation: "124",
    VotingStationName: "MANYONI FARM PRIMARY SCHOOL",
    Address: "DULMANUTHA OLD FARM SCHOOL  DULMANUTHA   BELFAST",
    Latitude: "-25.7529",
    Longitude: "30.1541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17875",
    WardLookupId: "4258",
    VDNumber: "54660168",
    RegisteredPopulation: "242",
    VotingStationName: "UMMELI FARM PRIMARY  SCHOOL",
    Address: "FOXHILL FARM  BELFAST  BELFAST",
    Latitude: "-25.6903",
    Longitude: "30.059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17876",
    WardLookupId: "2161",
    VDNumber: "54540154",
    RegisteredPopulation: "3037",
    VotingStationName: "MATIMBA MIDDLE SCHOOL",
    Address: "MOLOTO   MOLOTO SOUTH  NKANGALA",
    Latitude: "-25.4656",
    Longitude: "28.6414",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17877",
    WardLookupId: "2161",
    VDNumber: "54540930",
    RegisteredPopulation: "616",
    VotingStationName: "ALL NATION CHRISTIAN CHURCH",
    Address: "MOLOTO  KWAMHLANGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.4569",
    Longitude: "28.6363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17878",
    WardLookupId: "2162",
    VDNumber: "54540390",
    RegisteredPopulation: "2081",
    VotingStationName: "KING MAKHOSONKE SECONDARY SCHOOL",
    Address: "MOLOTO SOUTH  MOLOTO  THEMBISISLE",
    Latitude: "-25.4478",
    Longitude: "28.6367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17879",
    WardLookupId: "2162",
    VDNumber: "54540682",
    RegisteredPopulation: "712",
    VotingStationName: "ELOHAY CHRISTIAN FELLOWSHIP MINISTRIES",
    Address: "MOLOTO  KWAMHLANGA   THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.4437",
    Longitude: "28.6268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17880",
    WardLookupId: "2162",
    VDNumber: "54540749",
    RegisteredPopulation: "1057",
    VotingStationName: "RETANG PRIMARY SCHOOL",
    Address:
      "MP315 - MOLOTO SOUTH]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.4547",
    Longitude: "28.6325",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17881",
    WardLookupId: "2162",
    VDNumber: "54540783",
    RegisteredPopulation: "862",
    VotingStationName: "MOLOTO RDP HOUSES OPEN SPACE (TENT)",
    Address: "MOLOTO  NKANGALA  KWAMHLANGA",
    Latitude: "-25.4432",
    Longitude: "28.6493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17882",
    WardLookupId: "2162",
    VDNumber: "54540840",
    RegisteredPopulation: "745",
    VotingStationName: "PETER MOKABA PRIMARY SCHOOL",
    Address: "MOLOTO  NKANGALA  KWAMHLANGA NU",
    Latitude: "-25.4492",
    Longitude: "28.642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17883",
    WardLookupId: "2163",
    VDNumber: "54540143",
    RegisteredPopulation: "1346",
    VotingStationName: "OLD AGE CENTER",
    Address: "MOLOTO  KWAMHLANGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.4571",
    Longitude: "28.6425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17884",
    WardLookupId: "2163",
    VDNumber: "54540165",
    RegisteredPopulation: "1849",
    VotingStationName: "NDZUNDZA MABHOKO SECONDARY SCHOOL",
    Address: "MOLOTO SOUTH  KWAMHLANGA - MKOBOLA",
    Latitude: "-25.4609",
    Longitude: "28.647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17885",
    WardLookupId: "2163",
    VDNumber: "54540457",
    RegisteredPopulation: "794",
    VotingStationName: "MOLOTO PRIMARY SCHOOL",
    Address: "MOLOTO NORTH  NKANGALA  KWAMUSI TRIBAL AUTHORITY",
    Latitude: "-25.4638",
    Longitude: "28.6531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17886",
    WardLookupId: "2163",
    VDNumber: "54540750",
    RegisteredPopulation: "857",
    VotingStationName: "MOLOTO OPEN SPACE (TENT)",
    Address: "MOLOTO  KWAMHLANGA  THEMBISILE HANI MUNICIPALTY",
    Latitude: "-25.4538",
    Longitude: "28.6539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17887",
    WardLookupId: "2163",
    VDNumber: "54540862",
    RegisteredPopulation: "619",
    VotingStationName: "ABUNDANT LOVE FELLOWSHIP CENTRE (ALFC)",
    Address:
      "1936 BLOCK 20 KWAMHLANGA  KWAMHLANGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.4734",
    Longitude: "28.6535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17888",
    WardLookupId: "2164",
    VDNumber: "54540086",
    RegisteredPopulation: "1314",
    VotingStationName: "MKHANYO PRIMARY SCHOOL",
    Address: "190 ZAKHENI  ZAKHENI VILLAGE  NKANGALA",
    Latitude: "-25.4053",
    Longitude: "28.7158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17889",
    WardLookupId: "2164",
    VDNumber: "54540514",
    RegisteredPopulation: "1142",
    VotingStationName: "ZAKHENI HIGH SCHOOL",
    Address: "ZAKHENINI  KWAMHLANGA  KWAMHLANGA",
    Latitude: "-25.4109",
    Longitude: "28.7166",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17890",
    WardLookupId: "2164",
    VDNumber: "54540761",
    RegisteredPopulation: "1218",
    VotingStationName: "THULANI PRIMARY SCHOOL",
    Address:
      "MP315 -MANDELA VILLAGE]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3973",
    Longitude: "28.7153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17891",
    WardLookupId: "2164",
    VDNumber: "54540794",
    RegisteredPopulation: "936",
    VotingStationName: "ZENZELENI DAY CARE CENTRE",
    Address: "ZAKHENI VILLAGE  NKANGALA  KWAMHLANGA",
    Latitude: "-25.4052",
    Longitude: "28.7082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17892",
    WardLookupId: "2164",
    VDNumber: "54540884",
    RegisteredPopulation: "905",
    VotingStationName: "RETHABILE DAY CARE(TENT)",
    Address: "MANDELA VILLAGE  KWAMHLANGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.3987",
    Longitude: "28.705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17893",
    WardLookupId: "2165",
    VDNumber: "54540200",
    RegisteredPopulation: "2025",
    VotingStationName: "PHUTHUMANI PRIMARY SCHOOL",
    Address: "223 THEMBALETHU  NKANGALA",
    Latitude: "-25.413",
    Longitude: "28.7737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17894",
    WardLookupId: "2165",
    VDNumber: "54540570",
    RegisteredPopulation: "1013",
    VotingStationName: "REFORMED CHURCH",
    Address:
      "MP315 - THEMBALETHU]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.4066",
    Longitude: "28.7798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17895",
    WardLookupId: "2165",
    VDNumber: "54540615",
    RegisteredPopulation: "576",
    VotingStationName: "SIBONGIMPUMELELO COMBINED PRIMARY SCHOOL",
    Address: "THEMBALETHU  KWAMHLANGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.4136",
    Longitude: "28.7824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17896",
    WardLookupId: "2165",
    VDNumber: "54540895",
    RegisteredPopulation: "714",
    VotingStationName: "LINDOKUHLE PRIMARY SCHOOL",
    Address: "BELFAST   KWAMHLANGA  THEMBISILE HANI",
    Latitude: "-25.4209",
    Longitude: "28.7709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17897",
    WardLookupId: "2166",
    VDNumber: "54540097",
    RegisteredPopulation: "2126",
    VotingStationName: "SIBONIWE PRIMARY SCHOOL",
    Address: "439 PHOLA PARK  NKANGALA",
    Latitude: "-25.4037",
    Longitude: "28.7466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17898",
    WardLookupId: "2166",
    VDNumber: "54540413",
    RegisteredPopulation: "322",
    VotingStationName: "ZIPHAKAMISENI PRIMARY SCHOOL",
    Address: "PHOLA  PHOLA PARK  THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.4098",
    Longitude: "28.7456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17899",
    WardLookupId: "2166",
    VDNumber: "54540569",
    RegisteredPopulation: "1197",
    VotingStationName: "KOOS TUCK SHOP OPEN SPACE (TENT)",
    Address: "PHOLA PARK  KWAMHLANGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.4136",
    Longitude: "28.7594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17900",
    WardLookupId: "2166",
    VDNumber: "54540705",
    RegisteredPopulation: "1823",
    VotingStationName: "SIZAMILE PRIMARY SCHOOL",
    Address:
      "MP315 PHOLA PARK]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.4073",
    Longitude: "28.7606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17901",
    WardLookupId: "2167",
    VDNumber: "54610028",
    RegisteredPopulation: "1721",
    VotingStationName: "PHAPHAMANI PRIMARY SCHOOL",
    Address: "1678 MATHYZENSLOOP  MATHYSENSLOOP  NKANGALA",
    Latitude: "-25.2808",
    Longitude: "28.9931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17902",
    WardLookupId: "2167",
    VDNumber: "54610196",
    RegisteredPopulation: "1024",
    VotingStationName: "MONIWA PRIMARY SCHOOL",
    Address: "669 BOEKENHOUTHOEK  NKANGALA",
    Latitude: "-25.2939",
    Longitude: "29.0103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17903",
    WardLookupId: "2167",
    VDNumber: "54610590",
    RegisteredPopulation: "448",
    VotingStationName: "ENDLINI YABO GOGO CENTER",
    Address: "BOEKENHOUTHOEK  KWAGGAFONTEIN  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.3041",
    Longitude: "29.0199",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17904",
    WardLookupId: "2168",
    VDNumber: "54610095",
    RegisteredPopulation: "963",
    VotingStationName: "MANGADISA PRIMARY SCHOOL",
    Address: "LANGKLOOF  NKANGALA",
    Latitude: "-25.52",
    Longitude: "29.0945",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17905",
    WardLookupId: "2168",
    VDNumber: "54610253",
    RegisteredPopulation: "1897",
    VotingStationName: "KWAKWARI PRIMARY SCHOOL",
    Address: "900 VERENA A  NKANGALA",
    Latitude: "-25.4806",
    Longitude: "29.021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17906",
    WardLookupId: "2168",
    VDNumber: "54610264",
    RegisteredPopulation: "1803",
    VotingStationName: "MHLUSHWA PRIMARY SCHOOL",
    Address: "1678 VERENA D  VERENA  NKANGALA",
    Latitude: "-25.468",
    Longitude: "29.0356",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17907",
    WardLookupId: "2168",
    VDNumber: "54610534",
    RegisteredPopulation: "104",
    VotingStationName: "BLY N BIETJIE PRIMARY SCHOOL",
    Address: "RIETFONTEIN  NKANGALA  VERENA",
    Latitude: "-25.4238",
    Longitude: "29.1909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17908",
    WardLookupId: "2168",
    VDNumber: "54610578",
    RegisteredPopulation: "445",
    VotingStationName: "CHINA CHURCH",
    Address: "VERENA  KWAGGAFONTEIN  THEMBISILE HANI MUCIPALITY",
    Latitude: "-25.4735",
    Longitude: "29.0186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17909",
    WardLookupId: "2168",
    VDNumber: "54700062",
    RegisteredPopulation: "155",
    VotingStationName: "JIYANE FARM HOUSE",
    Address: "FARM WATERFALL  NKANGALA  VERENA",
    Latitude: "-25.5586",
    Longitude: "29.1045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17910",
    WardLookupId: "2169",
    VDNumber: "54540121",
    RegisteredPopulation: "2097",
    VotingStationName: "MZIMHLOPHE HIGH SCHOOL",
    Address: "709 TWEEFONTEIN J  NKANGALA",
    Latitude: "-25.4149",
    Longitude: "28.8029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17911",
    WardLookupId: "2169",
    VDNumber: "54540211",
    RegisteredPopulation: "1630",
    VotingStationName: "MUSA HIGH SCHOOL",
    Address: "1987 BUHLEBUZILE  BUHLEBUZILE  NKANGALA",
    Latitude: "-25.43",
    Longitude: "28.7707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17912",
    WardLookupId: "2169",
    VDNumber: "54540817",
    RegisteredPopulation: "1027",
    VotingStationName: "AFRICAN EMANUEL CHURCH",
    Address: "ZENZELE  NKANGALA  KWAMHLANGA NU",
    Latitude: "-25.4183",
    Longitude: "28.785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17913",
    WardLookupId: "2169",
    VDNumber: "54540963",
    RegisteredPopulation: "271",
    VotingStationName: "CHRISTIAN FELLOWSHIP HEALING CHURCH",
    Address: "KHAYELITJHA  KWAMHLANGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.424",
    Longitude: "28.761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17914",
    WardLookupId: "2170",
    VDNumber: "54540378",
    RegisteredPopulation: "106",
    VotingStationName: "ISIKHAHLA PRIMARY SCHOOL",
    Address: "01 WELVERDIEND  ROOIPORT/WELVERDIEND  NKANGALA",
    Latitude: "-25.5159",
    Longitude: "28.8644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17915",
    WardLookupId: "2170",
    VDNumber: "54540479",
    RegisteredPopulation: "1159",
    VotingStationName: "BUHLEBESIZWE SECONDARY SCHOOL",
    Address: "BUHLEBESIZWE  KWAGGAFONTEN  THEMBISILE",
    Latitude: "-25.3895",
    Longitude: "28.8478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17916",
    WardLookupId: "2170",
    VDNumber: "54610152",
    RegisteredPopulation: "1531",
    VotingStationName: "BENZANGANI PRIMARY SCHOOL",
    Address: "1814 GEMSBOKSPRUIT  GEMSBOKSPRUIT  NKANGALA",
    Latitude: "-25.403",
    Longitude: "28.8898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17917",
    WardLookupId: "2170",
    VDNumber: "54610163",
    RegisteredPopulation: "1834",
    VotingStationName: "VUMABESALA HIGH SCHOOL",
    Address: "760 GEMSBOKSPRUIT  GEMBOKSPRUIT  NKANGALA",
    Latitude: "-25.4049",
    Longitude: "28.876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17918",
    WardLookupId: "2170",
    VDNumber: "54610220",
    RegisteredPopulation: "69",
    VotingStationName: "SWAARTKOPPIES",
    Address: "SWAARTKOPPIES  WOLVENKOP FARM  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.5067",
    Longitude: "28.9546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17919",
    WardLookupId: "2170",
    VDNumber: "54610523",
    RegisteredPopulation: "434",
    VotingStationName: "SITHOBELANI JUNIOR SECONDARY SCHOOL",
    Address: "GEMSBOKSPRUIT  NKANGALA  GEMSBOKSPRUIT",
    Latitude: "-25.4095",
    Longitude: "28.8648",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17920",
    WardLookupId: "2171",
    VDNumber: "54610231",
    RegisteredPopulation: "1936",
    VotingStationName: "VERENA PRIMARY SCHOOL",
    Address:
      "MP315 - VERENA B]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.4908",
    Longitude: "29.0164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17921",
    WardLookupId: "2171",
    VDNumber: "54610242",
    RegisteredPopulation: "1313",
    VotingStationName: "MGUDLWA HIGH SCHOOL",
    Address: "820 MGIBE TRUSTFARM  VERENA A  NKANGALA",
    Latitude: "-25.474",
    Longitude: "29.0147",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17922",
    WardLookupId: "2171",
    VDNumber: "54610275",
    RegisteredPopulation: "1755",
    VotingStationName: "SIZAKELE PRIMARY SCHOOL",
    Address: "354 WOLVENKOP  WOLWENKOP  NKANGALA",
    Latitude: "-25.5215",
    Longitude: "28.9895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17923",
    WardLookupId: "2171",
    VDNumber: "54610501",
    RegisteredPopulation: "773",
    VotingStationName: "SOMLINGO PRIMARY SCHOOL",
    Address:
      "MP315 - VERENA B]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.4855",
    Longitude: "29.011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17924",
    WardLookupId: "2172",
    VDNumber: "54540187",
    RegisteredPopulation: "1460",
    VotingStationName: "MGIBE PRIMARY SCHOOL",
    Address: "346 MGIBE P SCHOOL  TWEEFONTEIN D  NKANGALA",
    Latitude: "-25.3464",
    Longitude: "28.8045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17925",
    WardLookupId: "2172",
    VDNumber: "54540198",
    RegisteredPopulation: "1187",
    VotingStationName: "VUKUZAME SENIOR SECONDARY SCHOOL",
    Address: "500 TWEEFONTEIN C  NKANGALA",
    Latitude: "-25.3422",
    Longitude: "28.8151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17926",
    WardLookupId: "2172",
    VDNumber: "54540671",
    RegisteredPopulation: "1080",
    VotingStationName: "SIFUNDAKHONA PRIMARY SCHOOL",
    Address:
      "MP315 - TWEEFONTEIN C]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3417",
    Longitude: "28.8228",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17927",
    WardLookupId: "2173",
    VDNumber: "54540648",
    RegisteredPopulation: "1087",
    VotingStationName: "KHUPHUKANI PRIMARY SCHOOL",
    Address:
      "MP315 - TWEEFONTEIN B2]   MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3502",
    Longitude: "28.8339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17928",
    WardLookupId: "2173",
    VDNumber: "54610073",
    RegisteredPopulation: "1683",
    VotingStationName: "VULAMEHLO PRIMARY SCHOOL",
    Address:
      "MP315 - VLAKLAAGTE NO 1]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3384",
    Longitude: "28.8698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17929",
    WardLookupId: "2173",
    VDNumber: "54610208",
    RegisteredPopulation: "1178",
    VotingStationName: "FUNDAWAZI PRIMARY SCHOOL",
    Address: "LARRY MMABOLOVILLE  VLAKLAAGTE 1  NKANGALA",
    Latitude: "-25.3473",
    Longitude: "28.8571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17930",
    WardLookupId: "2173",
    VDNumber: "54610376",
    RegisteredPopulation: "960",
    VotingStationName: "QEDUDLAME PRIMARY SCHOOL",
    Address: "TWEEFONTEIN B2  KWAGGAFONTEIN  THEMBISILE",
    Latitude: "-25.3442",
    Longitude: "28.8349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17931",
    WardLookupId: "2174",
    VDNumber: "54540334",
    RegisteredPopulation: "2289",
    VotingStationName: "KGANTSHO PRIMARY SCHOOL",
    Address: "1946 MOUNTAINVIEW  MOUNTAINVIEW  NKANGALA",
    Latitude: "-25.4327",
    Longitude: "28.7265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17932",
    WardLookupId: "2174",
    VDNumber: "54540693",
    RegisteredPopulation: "897",
    VotingStationName: "LANGALIBALELE SCHOOL",
    Address:
      "MP315 - PHOLA]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.4054",
    Longitude: "28.7272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17933",
    WardLookupId: "2174",
    VDNumber: "54540806",
    RegisteredPopulation: "2019",
    VotingStationName: "ROROBHANI SECONDARY SCHOOL",
    Address: "MOUNTAINVIEW  NKANGALA  KWAMHLANGA",
    Latitude: "-25.4212",
    Longitude: "28.7255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17934",
    WardLookupId: "2174",
    VDNumber: "54540907",
    RegisteredPopulation: "1030",
    VotingStationName: "KING MAKHOSONKE 2 KINGDOM TRADITIONAL",
    Address: "1205 SHELDON VILLAGE  KWAMHLANGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.4325",
    Longitude: "28.74",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17935",
    WardLookupId: "2174",
    VDNumber: "54540941",
    RegisteredPopulation: "346",
    VotingStationName: "NDZUNDZA NDIYASE TRIBAL OFFICE",
    Address: "EMPUMELELWENI  KWMHLANGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.4401",
    Longitude: "28.7401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17936",
    WardLookupId: "2175",
    VDNumber: "54540222",
    RegisteredPopulation: "1548",
    VotingStationName: "MAPONDO PRIMARY SCHOOL",
    Address: "145 MAPONDO  TWEEFONTEIN E  NKANGALA",
    Latitude: "-25.3906",
    Longitude: "28.8352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17937",
    WardLookupId: "2175",
    VDNumber: "54540402",
    RegisteredPopulation: "1301",
    VotingStationName: "PHELWANE PRIMARY SCHOOL",
    Address: "226 TWEEFONTEIN E  KWAMHLANGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.398",
    Longitude: "28.8227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17938",
    WardLookupId: "2175",
    VDNumber: "54540547",
    RegisteredPopulation: "774",
    VotingStationName: "SOMAROBOGO PRIMARY SCHOOL",
    Address:
      "MP315 - TWEEFONTEIN E]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.403",
    Longitude: "28.8294",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17939",
    WardLookupId: "2175",
    VDNumber: "54540592",
    RegisteredPopulation: "927",
    VotingStationName: "ZIDOBHELE SECONDARY SCHOOL",
    Address:
      "MP315 - TWEEFONTEIN F]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3988",
    Longitude: "28.8142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17940",
    WardLookupId: "2176",
    VDNumber: "54540301",
    RegisteredPopulation: "1194",
    VotingStationName: "GUGULETHU PRIMARY SCHOOL",
    Address: "BUHLEBESIZWE  VLAAKLAGTE2  NKANGALA",
    Latitude: "-25.3864",
    Longitude: "28.8544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17941",
    WardLookupId: "2176",
    VDNumber: "54610107",
    RegisteredPopulation: "1830",
    VotingStationName: "KHULUFUNDE PRIMARY SCHOOL",
    Address: "BUHLEBESIZWE  VLAKLAAGTE 2  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.3806",
    Longitude: "28.8634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17942",
    WardLookupId: "2176",
    VDNumber: "54610321",
    RegisteredPopulation: "1421",
    VotingStationName: "ZAMINTUTHUKO PRIMARY SCHOOL",
    Address: "221 BUHLEBESIZWE  VLAKLAAGTE 2  NKANGALA",
    Latitude: "-25.3758",
    Longitude: "28.8654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17943",
    WardLookupId: "2176",
    VDNumber: "54610488",
    RegisteredPopulation: "740",
    VotingStationName: "SIKHULISIWE PRIMARY SCHOOL",
    Address:
      "MP315 - BUHLEBESIZWE ]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3822",
    Longitude: "28.8613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17944",
    WardLookupId: "2177",
    VDNumber: "54540233",
    RegisteredPopulation: "1298",
    VotingStationName: "ZAMANI PRIMARY SCHOOL",
    Address: "497 TWEEFONTEIN F  NKANGALA",
    Latitude: "-25.3998",
    Longitude: "28.8112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17945",
    WardLookupId: "2177",
    VDNumber: "54540312",
    RegisteredPopulation: "656",
    VotingStationName: "THOKOZANI SCHOOL",
    Address: "137 TWEEFONTEIN F  NKANGALA",
    Latitude: "-25.3988",
    Longitude: "28.8019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17946",
    WardLookupId: "2177",
    VDNumber: "54540367",
    RegisteredPopulation: "424",
    VotingStationName: "MABENA TRIBAL OFFICE (TENT)",
    Address: "234 TWEEFONTEIN M  NKANGALA",
    Latitude: "-25.3798",
    Longitude: "28.8087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17947",
    WardLookupId: "2177",
    VDNumber: "54540468",
    RegisteredPopulation: "840",
    VotingStationName: "BIZZY CORNER SHOP (TENT)",
    Address: "CHRIS HANI  THOKOZA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.3966",
    Longitude: "28.7897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17948",
    WardLookupId: "2177",
    VDNumber: "54540491",
    RegisteredPopulation: "848",
    VotingStationName: "PEOPLE`S FREEDOM CHURCH",
    Address: "MILIVA  KWAGGAFONTEIN  THEMBISILE",
    Latitude: "-25.3856",
    Longitude: "28.8288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17949",
    WardLookupId: "2177",
    VDNumber: "54540738",
    RegisteredPopulation: "639",
    VotingStationName: "ZAKHELE PRIMARY SCHOOL",
    Address:
      "MP315 - TWEEFONTEIN N]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3747",
    Longitude: "28.8071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17950",
    WardLookupId: "2178",
    VDNumber: "54540042",
    RegisteredPopulation: "1677",
    VotingStationName: "SOMSUSWA SECONDARY SCHOOL",
    Address: "1004 TWEEFONTEIN M  THOKOZA  NKANGALA",
    Latitude: "-25.3803",
    Longitude: "28.7874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17951",
    WardLookupId: "2178",
    VDNumber: "54540064",
    RegisteredPopulation: "1413",
    VotingStationName: "VEZUBUHLE HIGH SCHOOL",
    Address: "1095 VEZUBUHLE  VEZUBUHLE  NKANGALA",
    Latitude: "-25.3924",
    Longitude: "28.7758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17952",
    WardLookupId: "2178",
    VDNumber: "54540503",
    RegisteredPopulation: "318",
    VotingStationName: "MNYAMANA H. PRIMARY SCHOOL",
    Address: "VEZUBUHLE  KWAGGAFONTEIN  KWAMHLANGA",
    Latitude: "-25.3879",
    Longitude: "28.7766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17953",
    WardLookupId: "2178",
    VDNumber: "54540536",
    RegisteredPopulation: "570",
    VotingStationName: "MAVULA PRIMARY SCHOOL",
    Address: "VEZUBUHLE  KWAMHLANGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.3938",
    Longitude: "28.776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17954",
    WardLookupId: "2178",
    VDNumber: "54540581",
    RegisteredPopulation: "861",
    VotingStationName: "GOD IS ABLE EVANGELISTIC HEALING MINISTRIES",
    Address: "THOKOZA  KWAMHLANAGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.3841",
    Longitude: "28.7886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17955",
    WardLookupId: "2178",
    VDNumber: "54540727",
    RegisteredPopulation: "755",
    VotingStationName: "MAKERANA TRIBAL OFFICE",
    Address:
      "MP315 - THOKOZA]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3863",
    Longitude: "28.7808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17956",
    WardLookupId: "2179",
    VDNumber: "54540019",
    RegisteredPopulation: "1428",
    VotingStationName: "SIPHUMULE PRIMARY SCHOOL",
    Address: "99 SUNCITY  KWAMHLANGA  NKANGALA",
    Latitude: "-25.3698",
    Longitude: "28.7335",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17957",
    WardLookupId: "2179",
    VDNumber: "54540299",
    RegisteredPopulation: "2604",
    VotingStationName: "ZENZELENI PRIMARY SCHOOL",
    Address: "636 KAMEELPOORTNEK  KAMEELPOORTNEK / SUN CITY  NKANGALA",
    Latitude: "-25.3922",
    Longitude: "28.7314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17958",
    WardLookupId: "2179",
    VDNumber: "54540558",
    RegisteredPopulation: "1086",
    VotingStationName: "SIHLEZI PRIMARY SCHOOL",
    Address:
      "MP315 - SUN CITY AA]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3797",
    Longitude: "28.727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17959",
    WardLookupId: "2179",
    VDNumber: "54540952",
    RegisteredPopulation: "564",
    VotingStationName: "LETHABONG SECONDARY SCHOOL",
    Address: "KAMEELPOORTNEK C  KWAMHLANGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.3657",
    Longitude: "28.7295",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17960",
    WardLookupId: "2179",
    VDNumber: "54580181",
    RegisteredPopulation: "519",
    VotingStationName: "RONDEHOOG",
    Address: "SEBOTHOMA VILLAGE  KAMEELPOORT  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.2857",
    Longitude: "28.7277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17961",
    WardLookupId: "2180",
    VDNumber: "54540053",
    RegisteredPopulation: "1200",
    VotingStationName: "KHANYISA PRIMARY SCHOOL",
    Address: "1099 VEZUBUHLE  VEZUBUHLE  NKANGALA",
    Latitude: "-25.3869",
    Longitude: "28.7685",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17962",
    WardLookupId: "2180",
    VDNumber: "54540356",
    RegisteredPopulation: "1710",
    VotingStationName: "VEZUBUHLE COMMUNITY HALL",
    Address: "VEZUBUHLE  KWAMHLANGA  THEMBISILE HANI MUNICIPALTY",
    Latitude: "-25.3985",
    Longitude: "28.7683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17963",
    WardLookupId: "2180",
    VDNumber: "54540446",
    RegisteredPopulation: "1322",
    VotingStationName: "THE ROCK OF LIFE MINISTRY",
    Address: "SUN CITY A  KWAMHLANGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.3927",
    Longitude: "28.7486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17964",
    WardLookupId: "2180",
    VDNumber: "54540716",
    RegisteredPopulation: "1458",
    VotingStationName: "KHAYELITJHA PRIMARY SCHOOL",
    Address:
      "MP315 - SUN CITY]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3983",
    Longitude: "28.7438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17965",
    WardLookupId: "2181",
    VDNumber: "54610129",
    RegisteredPopulation: "1234",
    VotingStationName: "ZITHUTHUKISENI PRIMARY SCHOOL",
    Address:
      "MP315 - VLAKLAAGTE N01]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3383",
    Longitude: "28.8861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17966",
    WardLookupId: "2181",
    VDNumber: "54610286",
    RegisteredPopulation: "1172",
    VotingStationName: "HLEZIKUHLE PRIMARY SCHOOL",
    Address: "225 VLAKLAAGTE NO 1  VLAKLAAGTE  NKANGALA",
    Latitude: "-25.3319",
    Longitude: "28.8905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17967",
    WardLookupId: "2181",
    VDNumber: "54610499",
    RegisteredPopulation: "1392",
    VotingStationName: "QUBEKANI PRIMARY SCHOOL",
    Address:
      "(MP315 - VLAKLAAGTE N0 1)   (MP315 - THEMBISILE [KWAMHLANGA])   (MP315 - THEMBISILE [KWAMHLANGA])",
    Latitude: "-25.3323",
    Longitude: "28.8804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17968",
    WardLookupId: "2181",
    VDNumber: "54610602",
    RegisteredPopulation: "611",
    VotingStationName: "MABHOKO VILLAGE TRIBAL OFFICE",
    Address:
      "40126 MABHOKO VILLAGE  KWAGGAFONTEIN  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.33",
    Longitude: "28.9086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17969",
    WardLookupId: "2182",
    VDNumber: "54540075",
    RegisteredPopulation: "2142",
    VotingStationName: "SENZANGAKHONA HIGH SCHOOL",
    Address: "SENZANGAKHONA  MANDELA VILLAGE  NKANGALA",
    Latitude: "-25.3928",
    Longitude: "28.7173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17970",
    WardLookupId: "2182",
    VDNumber: "54540435",
    RegisteredPopulation: "1564",
    VotingStationName: "CHIEF LUTHULI PRIMARY SCHOOL",
    Address: "CHIEF ALBERT LUTHULI VILLAGE  THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.379",
    Longitude: "28.7222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17971",
    WardLookupId: "2182",
    VDNumber: "54540839",
    RegisteredPopulation: "996",
    VotingStationName: "MOTOANENG PRIMARY SCHOOL",
    Address: "THEMBISILE  ",
    Latitude: "-25.3861",
    Longitude: "28.7112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17972",
    WardLookupId: "2182",
    VDNumber: "54540918",
    RegisteredPopulation: "861",
    VotingStationName: "TWELVE APOSTLES CHURCH IN CHRIST",
    Address: "MAHLABATHINI  KWAMHLANGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.3784",
    Longitude: "28.7074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17973",
    WardLookupId: "2183",
    VDNumber: "54540020",
    RegisteredPopulation: "1557",
    VotingStationName: "JABULANI PRIMARY SCHOOL",
    Address:
      "MP315 - PHUMULA]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3628",
    Longitude: "28.8234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17974",
    WardLookupId: "2183",
    VDNumber: "54540389",
    RegisteredPopulation: "1068",
    VotingStationName: "KHULANGELWAZI PRIMARY SCHOOL",
    Address:
      "MP315 - TWEEFONTEIN A]  MP315 - THEMBISILE [KWAMHLANGA]   MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3502",
    Longitude: "28.8187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17975",
    WardLookupId: "2183",
    VDNumber: "54540659",
    RegisteredPopulation: "723",
    VotingStationName: "DUTCH CHURCH",
    Address:
      "MP315 - TWEEFONTEIN A]  MP315 - THEMBISILE [KWAMHLANGA]   MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3559",
    Longitude: "28.8205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17976",
    WardLookupId: "2183",
    VDNumber: "54540660",
    RegisteredPopulation: "855",
    VotingStationName: "MASIZAKHE PRIMARY SCHOOL",
    Address:
      "MP315 -TWEEFONTEIN A]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3566",
    Longitude: "28.8262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17977",
    WardLookupId: "2183",
    VDNumber: "54540929",
    RegisteredPopulation: "516",
    VotingStationName: "EKUPHUMULENI COMBINED SCHOOL",
    Address: "PHUMULA  TWEEFONTEIN-A  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.3675",
    Longitude: "28.8291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17978",
    WardLookupId: "2184",
    VDNumber: "54610040",
    RegisteredPopulation: "1495",
    VotingStationName: "KHAYALETHU PRIMARY SCHOOL",
    Address: "1432 MUHLAMONYANE  BOEKENHOUTHOEK  NKANGALA",
    Latitude: "-25.3084",
    Longitude: "29.0254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17979",
    WardLookupId: "2184",
    VDNumber: "54610219",
    RegisteredPopulation: "2155",
    VotingStationName: "NYABELA SENIOR PRIMARY SCHOOL",
    Address: "521 GOEDEREDE  GOEDEREDE C  NKANGALA",
    Latitude: "-25.3074",
    Longitude: "29.073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17980",
    WardLookupId: "2184",
    VDNumber: "54610477",
    RegisteredPopulation: "726",
    VotingStationName: "LELENGAYE PRIMARY SCHOOL",
    Address:
      "MP315 - BOEKENHOUTHOEK]   MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3205",
    Longitude: "29.0298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17981",
    WardLookupId: "2184",
    VDNumber: "54610545",
    RegisteredPopulation: "568",
    VotingStationName: "VAMUHLE PRIMARY SCHOOL",
    Address: "KWAGGAFONTEIN C  NKANGALA  MKOBOLA",
    Latitude: "-25.2962",
    Longitude: "29.0043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17982",
    WardLookupId: "2184",
    VDNumber: "54610556",
    RegisteredPopulation: "739",
    VotingStationName: "NASAREN CHURCH-BOEKENHOUTHOEK",
    Address: "BOEKENHOUTHOEK  NKANGALA  KWAGGAFONTEIN",
    Latitude: "-25.3126",
    Longitude: "29.0179",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17983",
    WardLookupId: "2184",
    VDNumber: "54620423",
    RegisteredPopulation: "807",
    VotingStationName: "THUSHANANG PRIMARY SCHOOL",
    Address: "MCHIPE VILLAGE  GOEDEREDE A  NKANGALA",
    Latitude: "-25.2934",
    Longitude: "29.0853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17984",
    WardLookupId: "2185",
    VDNumber: "54610084",
    RegisteredPopulation: "2077",
    VotingStationName: "SINDAWONYE PRIMARY SCHOOL",
    Address: "758 KWAGGAFONTEIN B  NKANGALA",
    Latitude: "-25.3204",
    Longitude: "28.9434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17985",
    WardLookupId: "2185",
    VDNumber: "54610309",
    RegisteredPopulation: "1256",
    VotingStationName: "ENTOKOZWENI SECONDARY SCHOOL",
    Address: "KWAGGAFONTEIN SECTION C  THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3187",
    Longitude: "28.9474",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17986",
    WardLookupId: "2185",
    VDNumber: "54610512",
    RegisteredPopulation: "638",
    VotingStationName: "JESUS CHRIST CHURCH IN ZION OF SOUTH AFRICA",
    Address: "KWAGGAFONTEIN B  KWAGGAFONTEIN  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.3306",
    Longitude: "28.9408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17987",
    WardLookupId: "2185",
    VDNumber: "54610567",
    RegisteredPopulation: "251",
    VotingStationName: "KWAGGAFONTEIN C COMMUNITY HALL",
    Address: "KWAGGAFONTEIN  NKANGALA  KWAMHLANGA",
    Latitude: "-25.3116",
    Longitude: "28.9506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17988",
    WardLookupId: "2186",
    VDNumber: "54610118",
    RegisteredPopulation: "1580",
    VotingStationName: "MGWEZANI PRIMARY SCHOOL",
    Address: "1098 KWAGGAFONTEIN C  KWAGGAFONTEIN C 2003  NKANGALA",
    Latitude: "-25.3069",
    Longitude: "28.957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17989",
    WardLookupId: "2186",
    VDNumber: "54610310",
    RegisteredPopulation: "751",
    VotingStationName: "THANDULWAZI PRIMARY SCHOOL",
    Address:
      "MP315 - KWAGGAFONTEIN C]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3152",
    Longitude: "28.9503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17990",
    WardLookupId: "2186",
    VDNumber: "54610433",
    RegisteredPopulation: "527",
    VotingStationName: "ANNOINTED GOSPEL CHURCH",
    Address:
      "MP315 - KWAGGAFONTEIN C]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3091",
    Longitude: "28.9563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17991",
    WardLookupId: "2186",
    VDNumber: "54610444",
    RegisteredPopulation: "1209",
    VotingStationName: "THUBELIHLE PRIMARY SCHOOL",
    Address:
      "MP315 - KWAGGAFONTEIN C]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3128",
    Longitude: "28.961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17992",
    WardLookupId: "2187",
    VDNumber: "54610039",
    RegisteredPopulation: "1611",
    VotingStationName: "EMTHUNZINI HIGH SCHOOL",
    Address: "342 KWAGGAFONTEIN A  NKANGALA",
    Latitude: "-25.2924",
    Longitude: "28.9378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17993",
    WardLookupId: "2187",
    VDNumber: "54610062",
    RegisteredPopulation: "1395",
    VotingStationName: "SIFIKILE PRIMARY SCHOOL",
    Address: "638 KWAGGAFONTEIN A  NKANGALA",
    Latitude: "-25.2927",
    Longitude: "28.9508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17994",
    WardLookupId: "2187",
    VDNumber: "54610354",
    RegisteredPopulation: "619",
    VotingStationName: "AFRICAN NATIVE CHURCH",
    Address:
      "MP315 - KWAGGAFONTEIN EMA 10]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.2889",
    Longitude: "28.9622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17995",
    WardLookupId: "2187",
    VDNumber: "54610365",
    RegisteredPopulation: "775",
    VotingStationName: "NJOMANI PRIMARY SCHOOL",
    Address: "MATHYZENSLOOP  THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.2848",
    Longitude: "28.9836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17996",
    WardLookupId: "2188",
    VDNumber: "54610141",
    RegisteredPopulation: "1786",
    VotingStationName: "EMATSHENI HIGHER PRIMARY SCHOOL",
    Address: "1098 KWAGGAFONTEIN  KWAGGAFONTEIN A  NKANGALA",
    Latitude: "-25.3023",
    Longitude: "28.9494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17997",
    WardLookupId: "2188",
    VDNumber: "54610422",
    RegisteredPopulation: "1079",
    VotingStationName: "LATTER RAIN CHURCH",
    Address:
      "MP315 - KWAGGAFONTEIN A]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.2989",
    Longitude: "28.9484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17998",
    WardLookupId: "2188",
    VDNumber: "54610589",
    RegisteredPopulation: "568",
    VotingStationName: "CW CHURCH",
    Address: "KWAGGAFONTEIN- A  KWAGGAFONTEIN-A  THEMBISILE HANI MUNICIPALTY",
    Latitude: "-25.2975",
    Longitude: "28.9394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "17999",
    WardLookupId: "2189",
    VDNumber: "54610051",
    RegisteredPopulation: "1559",
    VotingStationName: "MAFU HIGH SCHOOL",
    Address: "299 KWAGGAFONTEIN A  NKANGALA",
    Latitude: "-25.3032",
    Longitude: "28.9362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18000",
    WardLookupId: "2189",
    VDNumber: "54610411",
    RegisteredPopulation: "1227",
    VotingStationName: "EMTHONJENI PRIMARY SCHOOL",
    Address:
      "MP315 - KWAGGAFONTEIN A]   MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.2955",
    Longitude: "28.9343",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18001",
    WardLookupId: "2189",
    VDNumber: "54610455",
    RegisteredPopulation: "552",
    VotingStationName: "CIBILIZA SENIOR SECONDARY SCHOOL",
    Address:
      "MP315 - KWAGGAFONTEIN A]  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.2851",
    Longitude: "28.9357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18002",
    WardLookupId: "2189",
    VDNumber: "54610466",
    RegisteredPopulation: "700",
    VotingStationName: "MLOISANE STORE (TENT)",
    Address: "KWAGGAFONTEIN A  KWAGGAFONTEIN  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.2884",
    Longitude: "28.9439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18003",
    WardLookupId: "2190",
    VDNumber: "54540109",
    RegisteredPopulation: "1390",
    VotingStationName: "VUKUZENZELE HIGH SCHOOL",
    Address: "TWEEFONTEIN G  KWAMHLANGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.4058",
    Longitude: "28.7956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18004",
    WardLookupId: "2190",
    VDNumber: "54540110",
    RegisteredPopulation: "997",
    VotingStationName: "HLALISANANI PRIMARY SCHOOL",
    Address: "357 TWEEFONTEIN  TWEEFONTEIN G  NKANGALA",
    Latitude: "-25.4027",
    Longitude: "28.7955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18005",
    WardLookupId: "2190",
    VDNumber: "54540424",
    RegisteredPopulation: "1177",
    VotingStationName: "EDWALENI SECONDARY SCHOOL",
    Address: "TWEEFONTEIN J  KWAMHLNGA  THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.4121",
    Longitude: "28.8101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18006",
    WardLookupId: "2190",
    VDNumber: "54540626",
    RegisteredPopulation: "453",
    VotingStationName: "IAG ASSEMBLIES OF GOD CHURCH",
    Address: "TWEEFONTEIN H  KWAMHLANGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.4088",
    Longitude: "28.8033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18007",
    WardLookupId: "2190",
    VDNumber: "54540637",
    RegisteredPopulation: "543",
    VotingStationName: "EBENEZER GOSPEL MINISTRIES",
    Address: "TWEEFONTEIN G  KWAMHLANGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.4049",
    Longitude: "28.8001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18008",
    WardLookupId: "2191",
    VDNumber: "54610174",
    RegisteredPopulation: "1347",
    VotingStationName: "SINETJHUDU PRIMARY  SCHOOL",
    Address: "304 KWAGGA  KWAGGAFONTEIN D  NKANGALA",
    Latitude: "-25.3411",
    Longitude: "28.9508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18009",
    WardLookupId: "2191",
    VDNumber: "54610185",
    RegisteredPopulation: "1644",
    VotingStationName: "THUTHUKANI PRIMARY SCHOOL",
    Address: "463 KWAGGAFONTEIN E  NKANGALA",
    Latitude: "-25.3618",
    Longitude: "28.9407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18010",
    WardLookupId: "2191",
    VDNumber: "54610332",
    RegisteredPopulation: "727",
    VotingStationName: "HLOMANI PRIMARY SCHOOL",
    Address: "KWAGGAFONTEIN D  THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3455",
    Longitude: "28.9507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18011",
    WardLookupId: "2191",
    VDNumber: "54610343",
    RegisteredPopulation: "914",
    VotingStationName: "MBEDLWANA PRIMARY SCHOOL",
    Address: "KWAGGAFONTEIN D  THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.3584",
    Longitude: "28.9565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18012",
    WardLookupId: "2192",
    VDNumber: "54540132",
    RegisteredPopulation: "2457",
    VotingStationName: "KWAMHLANGA SHOW GROUNDS HALL",
    Address: "KWAMHLANGA  KWAMHLANGA TOWNSHIP  NKANGALA",
    Latitude: "-25.4296",
    Longitude: "28.7142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18013",
    WardLookupId: "2192",
    VDNumber: "54540176",
    RegisteredPopulation: "182",
    VotingStationName: "LOOPSPRUIT WINERY",
    Address: "LOOPSPRUIT  NKANGALA  KWAMHLANGA",
    Latitude: "-25.5511",
    Longitude: "28.7343",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18014",
    WardLookupId: "2192",
    VDNumber: "54540772",
    RegisteredPopulation: "1195",
    VotingStationName: "MUSI PRIMARY SCHOOL",
    Address:
      "MP315 - KWAMHLANGA  MP315 - THEMBISILE [KWAMHLANGA]  MP315 - THEMBISILE [KWAMHLANGA]",
    Latitude: "-25.4213",
    Longitude: "28.7079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18015",
    WardLookupId: "2192",
    VDNumber: "54540828",
    RegisteredPopulation: "236",
    VotingStationName: "BRONKS MINE",
    Address: "BRONKS MINE  KWAMHLANGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.557",
    Longitude: "28.7781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18016",
    WardLookupId: "2192",
    VDNumber: "54540873",
    RegisteredPopulation: "156",
    VotingStationName: "TETEMA PRIMARY SCHOOL",
    Address: "PLOT 456  VANDYK  NDEDEMA",
    Latitude: "-25.6473",
    Longitude: "28.8103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18017",
    WardLookupId: "2192",
    VDNumber: "54540974",
    RegisteredPopulation: "168",
    VotingStationName: "MAGODODNGO OPEN SPACE( TENT)",
    Address: "MOLOTO  KWAMHLANGA  THEMBISILE HANI MUNICIPALITY",
    Latitude: "-25.4539",
    Longitude: "28.6625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18018",
    WardLookupId: "4259",
    VDNumber: "54590148",
    RegisteredPopulation: "1359",
    VotingStationName: "RHUBULULULWAZI DAY CARE",
    Address: "KWAPHAAHLA  MDUTJANA",
    Latitude: "-25.1399",
    Longitude: "29.0582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18019",
    WardLookupId: "4259",
    VDNumber: "54590159",
    RegisteredPopulation: "1538",
    VotingStationName: "KWA-PHAAHLA COMMUNITY HALL",
    Address: "142 KWA-PHAAHLA SECTION  SIYABUSWA  MDUTJANA",
    Latitude: "-25.126",
    Longitude: "29.0573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18020",
    WardLookupId: "4259",
    VDNumber: "54590441",
    RegisteredPopulation: "928",
    VotingStationName: "SOBANTU SENIOR SECONDARY SCHOOL",
    Address: "MDUTJANA  MDUTJANA",
    Latitude: "-25.1348",
    Longitude: "29.0505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18021",
    WardLookupId: "4259",
    VDNumber: "54590463",
    RegisteredPopulation: "860",
    VotingStationName: "TENT AT SIYABUSWA OPEN SPACE (EXT D)",
    Address: "SIYABUSWA EXT D  SIYABUSWA  MDUTJANA",
    Latitude: "-25.1296",
    Longitude: "29.0431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18022",
    WardLookupId: "4260",
    VDNumber: "54590227",
    RegisteredPopulation: "1885",
    VotingStationName: "MARELENG PRIMARY SCHOOL",
    Address: "BONGIMFUNDO STREET  MAGANAGOBUSWA  SIYABUSWA",
    Latitude: "-25.1338",
    Longitude: "29.0778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18023",
    WardLookupId: "4260",
    VDNumber: "54590306",
    RegisteredPopulation: "1410",
    VotingStationName: "MAKOPANONG SCHOOL",
    Address: "C4586 MAGANAGOBUSWA VILLAGE  SIYABUSWA  MDUTJANA",
    Latitude: "-25.1147",
    Longitude: "29.0809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18024",
    WardLookupId: "4260",
    VDNumber: "54590373",
    RegisteredPopulation: "287",
    VotingStationName: "TOITSKRAAL OPEN SPACE",
    Address: "TOITSKRAAL  SIYABUSWA  MDUTJANA",
    Latitude: "-25.1029",
    Longitude: "29.1077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18025",
    WardLookupId: "4260",
    VDNumber: "54590474",
    RegisteredPopulation: "462",
    VotingStationName: "IKAGENG SS SCHOOL",
    Address: "873 MAKOPANONG VILLAGE  SIYABUSWA A  MDUTJANE",
    Latitude: "-25.1252",
    Longitude: "29.0757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18026",
    WardLookupId: "4260",
    VDNumber: "54590520",
    RegisteredPopulation: "597",
    VotingStationName: "ALPHA & OMEGA SPOKEN WORD MINISTRY",
    Address: "245 KGAPAMADIKGOBOKOANE  SIYABUSWA  KGAPAMADI",
    Latitude: "-25.1195",
    Longitude: "29.0962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18027",
    WardLookupId: "4261",
    VDNumber: "54590092",
    RegisteredPopulation: "1623",
    VotingStationName: "SIYABUSWA COMMUNITY HALL",
    Address: "665 SECTION A  SIYABUSWA A  SIYABUSWA",
    Latitude: "-25.1124",
    Longitude: "29.0657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18028",
    WardLookupId: "4261",
    VDNumber: "54590104",
    RegisteredPopulation: "1123",
    VotingStationName: "KABENZIWA PRIMARY SCHOOL",
    Address: "2340 SECTION A  SIYABUSWA A  MDUTJANA",
    Latitude: "-25.1206",
    Longitude: "29.0674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18029",
    WardLookupId: "4261",
    VDNumber: "54590395",
    RegisteredPopulation: "522",
    VotingStationName: "BUHLEBUZILE SECONDARY SCHOOL",
    Address: "1961 SECTION A  SIYABUSWA  MDUTJANA",
    Latitude: "-25.1138",
    Longitude: "29.0702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18030",
    WardLookupId: "4261",
    VDNumber: "54590485",
    RegisteredPopulation: "627",
    VotingStationName: "VULINDLELA PRIMARY SCHOOL",
    Address: "SIYABUSWA A  SIYABUSWA  NDZUNDZA MABUSA",
    Latitude: "-25.1132",
    Longitude: "29.0597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18031",
    WardLookupId: "4261",
    VDNumber: "54590496",
    RegisteredPopulation: "532",
    VotingStationName: "VICTORY CONVENTION CHURCH",
    Address: "SIYABUSWA  SIYABUSWA E  NDZUNDZA MABUSA",
    Latitude: "-25.1205",
    Longitude: "29.0598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18032",
    WardLookupId: "4262",
    VDNumber: "54590261",
    RegisteredPopulation: "1053",
    VotingStationName: "MASUKU JUNIOR PRIMARY SCHOOL",
    Address: "355 SECTION A  SIYABUSWA A  MDUTJANE",
    Latitude: "-25.1063",
    Longitude: "29.0586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18033",
    WardLookupId: "4262",
    VDNumber: "54590339",
    RegisteredPopulation: "733",
    VotingStationName: "VELANGEZWI COMBINED PRIMARY SCHOOL",
    Address: "B5788 SECTION D  SIYABUSWA  MDUTJANE",
    Latitude: "-25.1043",
    Longitude: "29.0745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18034",
    WardLookupId: "4262",
    VDNumber: "54590407",
    RegisteredPopulation: "687",
    VotingStationName: "MAQHAWE PRIMARY SCHOOL",
    Address: "999 SECTION A  SIYABUSWA  MDUTJANE",
    Latitude: "-25.1054",
    Longitude: "29.0669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18035",
    WardLookupId: "4262",
    VDNumber: "54590430",
    RegisteredPopulation: "992",
    VotingStationName: "EZWENILETHU PRIMARY SCHOOL",
    Address: "194 SIYABUSWA A  SIYABUSWA  MDUTJANE",
    Latitude: "-25.104",
    Longitude: "29.0649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18036",
    WardLookupId: "4263",
    VDNumber: "54590205",
    RegisteredPopulation: "1982",
    VotingStationName: "THEMBEKA PRIMARY SCHOOL",
    Address: "1036 SECTION B  SIYABUSWA   MDUTJANE",
    Latitude: "-25.1167",
    Longitude: "29.0478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18037",
    WardLookupId: "4263",
    VDNumber: "54590340",
    RegisteredPopulation: "1936",
    VotingStationName: "UBUHLEBETHU HIGH SCHOOL",
    Address: "357 SECTION B  SIYABUSWA   MDUTJANE",
    Latitude: "-25.1082",
    Longitude: "29.0497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18038",
    WardLookupId: "4264",
    VDNumber: "54590238",
    RegisteredPopulation: "1848",
    VotingStationName: "VEZILWAZI SECONDARY SCHOOL",
    Address: "SIYABUSWA C  MDUTJANA",
    Latitude: "-25.1166",
    Longitude: "29.034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18039",
    WardLookupId: "4264",
    VDNumber: "54590250",
    RegisteredPopulation: "1073",
    VotingStationName: "PRINCE SJ MAHLANGU SCHOOL",
    Address: "18 MOGONONONG VILLAGE  WELTEVREDE  MDUTJANA",
    Latitude: "-25.1295",
    Longitude: "29.0251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18040",
    WardLookupId: "4264",
    VDNumber: "54590351",
    RegisteredPopulation: "1185",
    VotingStationName: "ST PETERS LUTHERAN CHURCH",
    Address: "961 SIYABUSWA C  SIYABUSWA   MDUTJANA",
    Latitude: "-25.1224",
    Longitude: "29.0341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18041",
    WardLookupId: "4264",
    VDNumber: "54590362",
    RegisteredPopulation: "979",
    VotingStationName: "ANDISA PRIMARY SCHOOL",
    Address: "3050 MABUYENI VILLAGE  WELTEVREDE  MDUTJANA",
    Latitude: "-25.1177",
    Longitude: "29.0102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18042",
    WardLookupId: "4265",
    VDNumber: "54590137",
    RegisteredPopulation: "1692",
    VotingStationName: "RAMOKGELETSANE PRIMARY SCHOOL",
    Address: "1967 RAMOKGELETSANE VILLAGE  RAMOKGELETSANE  MDUTJANA",
    Latitude: "-25.0704",
    Longitude: "29.0257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18043",
    WardLookupId: "4265",
    VDNumber: "54590216",
    RegisteredPopulation: "1349",
    VotingStationName: "THABANA COMMUNITY HALL",
    Address: "THABANA  MDUTJANA",
    Latitude: "-25.0839",
    Longitude: "29.06",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18044",
    WardLookupId: "4265",
    VDNumber: "54590508",
    RegisteredPopulation: "1076",
    VotingStationName: "SITHABILE PRIMARY SCHOOL",
    Address: "THABANA  NDZUNDZA MABUSA",
    Latitude: "-25.0711",
    Longitude: "29.0577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18045",
    WardLookupId: "4266",
    VDNumber: "54590070",
    RegisteredPopulation: "1550",
    VotingStationName: "SIMUYEMBIWA JUNIOR PRIMARY SCHOOL",
    Address: "49 NKOSINI VILLAGE  WELTEVREDE  MDUTJANA",
    Latitude: "-25.0902",
    Longitude: "29.0002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18046",
    WardLookupId: "4266",
    VDNumber: "54590160",
    RegisteredPopulation: "1357",
    VotingStationName: "NDABEZITHA PRIMARY SCHOOL",
    Address: "08 NKOSINI  WELTEVREDE  MDUTJANA",
    Latitude: "-25.1023",
    Longitude: "29.0116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18047",
    WardLookupId: "4266",
    VDNumber: "54590283",
    RegisteredPopulation: "1021",
    VotingStationName: "MABUSABESALA HIGH SCHOOL",
    Address: "2 NKOSINI VILLAGE  WELTEVREDE  MDUTJANA",
    Latitude: "-25.1075",
    Longitude: "28.9915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18048",
    WardLookupId: "4267",
    VDNumber: "54590069",
    RegisteredPopulation: "1374",
    VotingStationName: "HLANGANANI HIGH SCHOOL",
    Address: "99 TEMBISILE STREET  KAMEELRIVIER B  MDUTJANA",
    Latitude: "-25.12",
    Longitude: "28.9895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18049",
    WardLookupId: "4267",
    VDNumber: "54590193",
    RegisteredPopulation: "1394",
    VotingStationName: "SIZANI SCHOOL",
    Address: "486 BUNGELA STREET  KAMEELRIVIER B  MDUTJANA",
    Latitude: "-25.1255",
    Longitude: "28.9992",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18050",
    WardLookupId: "4267",
    VDNumber: "54590418",
    RegisteredPopulation: "708",
    VotingStationName: "THOLULWAZI SECONDARY SCHOOL",
    Address: "22 THEMBISA STREET  KAMEELRIVER B  MDUTJANA",
    Latitude: "-25.1231",
    Longitude: "29.0039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18051",
    WardLookupId: "4268",
    VDNumber: "54590047",
    RegisteredPopulation: "1539",
    VotingStationName: "GAMORWE EDU CARE",
    Address: "GAMORWE  KAMEELRIVIER  MDUTJANA",
    Latitude: "-25.1327",
    Longitude: "28.9993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18052",
    WardLookupId: "4268",
    VDNumber: "54590058",
    RegisteredPopulation: "2098",
    VotingStationName: "MANTWANI PRIMARY SCHOOL",
    Address: "5410 WATERVAL B  WATERVAL  MDUTJANE",
    Latitude: "-25.1675",
    Longitude: "29.0005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18053",
    WardLookupId: "4268",
    VDNumber: "54590452",
    RegisteredPopulation: "805",
    VotingStationName: "NDZUNDZA MABUSA TRIBAL OFFICE",
    Address: "3238 MAGELEMBE VILLAGE  WATERVAL B  MDUTJANA",
    Latitude: "-25.1657",
    Longitude: "29.0125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18054",
    WardLookupId: "4269",
    VDNumber: "54590126",
    RegisteredPopulation: "1686",
    VotingStationName: "THABANG COMBINED PRIMARY SCHOOL",
    Address: "2138 VUKUZENZELE STREET  MMAKOLA VILLAGE  MDUTJANE",
    Latitude: "-25.1955",
    Longitude: "29.0267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18055",
    WardLookupId: "4269",
    VDNumber: "54590272",
    RegisteredPopulation: "1406",
    VotingStationName: "SIHLANGENE JUNIOR PRIMARY SCHOOL",
    Address: "6064 MTSHIKA STREET  WATERVAL TWOLINE  MDUTJANA",
    Latitude: "-25.1648",
    Longitude: "29.0238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18056",
    WardLookupId: "4269",
    VDNumber: "54590328",
    RegisteredPopulation: "1108",
    VotingStationName: "MAYISHA HIGH SCHOOL",
    Address: "3075 WATERVAL B  WATERVAL  MDUTJANE",
    Latitude: "-25.1724",
    Longitude: "29.0151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18057",
    WardLookupId: "4270",
    VDNumber: "54590115",
    RegisteredPopulation: "1609",
    VotingStationName: "NKOSIPHILE PRIMARY SCHOOL",
    Address: "310 GRAVEYARD SIDE  MATSHIDING VILLAGE  MDUTJANE",
    Latitude: "-25.2202",
    Longitude: "29.0195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18058",
    WardLookupId: "4270",
    VDNumber: "54590182",
    RegisteredPopulation: "1746",
    VotingStationName: "MAKHOSANA HIGH SCHOOL",
    Address: "290 MAROTHOBOLONG VILLAGE  MATSHIDING  MDUTJANA",
    Latitude: "-25.2021",
    Longitude: "29.0028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18059",
    WardLookupId: "4270",
    VDNumber: "54590429",
    RegisteredPopulation: "1178",
    VotingStationName: "SOVETJHEZA SECONDARY SCHOOL",
    Address: "124 MATSHIDING VILLAGE  MATSHIDING  MDUTJANA",
    Latitude: "-25.2127",
    Longitude: "29.0251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18060",
    WardLookupId: "4271",
    VDNumber: "54590036",
    RegisteredPopulation: "1767",
    VotingStationName: "EKUKHANYENI COMBINED SCHOOL",
    Address: "702 MGIDI STREET  SKIMMING  MDUTJANA",
    Latitude: "-25.2045",
    Longitude: "28.9677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18061",
    WardLookupId: "4271",
    VDNumber: "54590081",
    RegisteredPopulation: "1029",
    VotingStationName: "MADLAYEDWA SCHOOL",
    Address: "0 PIETERSKRAAL A  PIETERSKRAAL  MDUTJANA",
    Latitude: "-25.2103",
    Longitude: "28.9233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18062",
    WardLookupId: "4271",
    VDNumber: "54590294",
    RegisteredPopulation: "443",
    VotingStationName: "BOROLO PRIMARY SCHOOL",
    Address: "40 GA-MORWE  BOROLO VILLAGE  MDUTJANA",
    Latitude: "-25.181",
    Longitude: "28.9317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18063",
    WardLookupId: "4271",
    VDNumber: "54590519",
    RegisteredPopulation: "745",
    VotingStationName: "VUSA PRIMARY SCHOOL",
    Address: "MADLAYEDWA  PIETERSKRAAL A  NDZUNDA MABHOKO",
    Latitude: "-25.2034",
    Longitude: "28.9287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18064",
    WardLookupId: "4272",
    VDNumber: "54590025",
    RegisteredPopulation: "1659",
    VotingStationName: "BEKEZELA PRIMARY SCHOOL",
    Address: "1 MAPHOTLA VILLAGE  WOLWEKRAAL  MDUTJANA",
    Latitude: "-25.1473",
    Longitude: "28.9614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18065",
    WardLookupId: "4272",
    VDNumber: "54590171",
    RegisteredPopulation: "2338",
    VotingStationName: "SILINDILE JUNIOR PRIMARY SCHOOL",
    Address: "49 MAPHOTLA VILLAGE  MAPHOTLA  MDUTJANA",
    Latitude: "-25.1499",
    Longitude: "28.9732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18066",
    WardLookupId: "4273",
    VDNumber: "54580091",
    RegisteredPopulation: "1433",
    VotingStationName: "SOMLOKOTHWA PRIMARY SCHOOL",
    Address: "349  LEEUFONTEIN A  DIKGWALE  MBIBANE",
    Latitude: "-25.1817",
    Longitude: "28.8988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18067",
    WardLookupId: "4273",
    VDNumber: "54580103",
    RegisteredPopulation: "973",
    VotingStationName: "NDAYI PRIMARY SCHOOL",
    Address: "189 MOLAPOMOGOLO VILLAGE  KAMEELPOORT  MBIBANE",
    Latitude: "-25.284",
    Longitude: "28.82",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18068",
    WardLookupId: "4273",
    VDNumber: "54580169",
    RegisteredPopulation: "1434",
    VotingStationName: "VUKILE PRIMARY SCHOOL",
    Address: "125 LEEUFONTEIN B  DIGWALE  MBIBANE",
    Latitude: "-25.1994",
    Longitude: "28.8987",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18069",
    WardLookupId: "4273",
    VDNumber: "54580226",
    RegisteredPopulation: "85",
    VotingStationName: "MDALA NATURE RESERVE MAIN CAMP",
    Address: "MDALA NATURE RESERVE  DIGWALE  MBIBANE",
    Latitude: "-25.2658",
    Longitude: "28.7452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18070",
    WardLookupId: "4273",
    VDNumber: "54580260",
    RegisteredPopulation: "1203",
    VotingStationName: "REKWELE PRIMARY SCHOOL",
    Address: "408 DIGWALE  MBIBANE",
    Latitude: "-25.1729",
    Longitude: "28.9044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18071",
    WardLookupId: "4274",
    VDNumber: "54580079",
    RegisteredPopulation: "1736",
    VotingStationName: "MBUDUMA PRIMARY SCHOOL",
    Address: "397 LIBANGENI VILLAGE  VAALBANK  MBIBANE",
    Latitude: "-25.1523",
    Longitude: "28.8498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18072",
    WardLookupId: "4274",
    VDNumber: "54580136",
    RegisteredPopulation: "1674",
    VotingStationName: "EMFUNDWENI COMBINED SCHOOL",
    Address: "395 LIBANGENI EXTENSION B  VAALBANK  MBIBANE",
    Latitude: "-25.1608",
    Longitude: "28.8384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18073",
    WardLookupId: "4275",
    VDNumber: "54580068",
    RegisteredPopulation: "1588",
    VotingStationName: "SIZISIZWE PRIMARY SCHOOL",
    Address: "1403 LIBANGENI VILLAGE  VAALBANK  MBIBANE",
    Latitude: "-25.1505",
    Longitude: "28.8605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18074",
    WardLookupId: "4275",
    VDNumber: "54580080",
    RegisteredPopulation: "1833",
    VotingStationName: "MBONGO COMBINED SCHOOL",
    Address: "1415 ALLEMANSDRIFT C  ALLEMANSDRIFT   MBIBANE",
    Latitude: "-25.1413",
    Longitude: "28.8636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18075",
    WardLookupId: "4275",
    VDNumber: "54580271",
    RegisteredPopulation: "1244",
    VotingStationName: "MASANA PRIMARY SCHOOL",
    Address: "449 LIBANGENI VILLAGE  VAALBANK  MMBIBANE",
    Latitude: "-25.1514",
    Longitude: "28.8561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18076",
    WardLookupId: "4276",
    VDNumber: "54580035",
    RegisteredPopulation: "2145",
    VotingStationName: "UKUPHUMULA KWESIZWE SENIOR PRIMARY SCHOOL",
    Address: "ALLEMANDSDRIFT  MBIBANE",
    Latitude: "-25.1183",
    Longitude: "28.9115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18077",
    WardLookupId: "4276",
    VDNumber: "54580158",
    RegisteredPopulation: "1415",
    VotingStationName: "NKOSABO PRIMARY SCHOOL",
    Address: "1420 ALLEMANSDRIFT C  ALLEMANDRIFT  MBIBANE",
    Latitude: "-25.1072",
    Longitude: "28.9075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18078",
    WardLookupId: "4276",
    VDNumber: "54580282",
    RegisteredPopulation: "750",
    VotingStationName: "KWAMANALA SENIOR SECONDARY SCHOOL",
    Address: "19 ALLEMANSDRIFT C  ALLMANSDRIFT  MBIBANE",
    Latitude: "-25.1198",
    Longitude: "28.9012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18079",
    WardLookupId: "4277",
    VDNumber: "54580215",
    RegisteredPopulation: "1164",
    VotingStationName: "THEMBALIHLE PRIMARY SCHOOL",
    Address: "1925 ALLEMANSDRIFT D  ALLEMANDSDRIFT  MBIBANE",
    Latitude: "-25.1167",
    Longitude: "28.9203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18080",
    WardLookupId: "4277",
    VDNumber: "54580237",
    RegisteredPopulation: "985",
    VotingStationName: "MOUTSE PRIMARY SCHOOL",
    Address: "1020 MAKOMETSANE VILLAGE  MAKOMETSANE  MBIBANE",
    Latitude: "-25.1146",
    Longitude: "28.9438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18081",
    WardLookupId: "4277",
    VDNumber: "54590014",
    RegisteredPopulation: "1485",
    VotingStationName: "SIVUMELENE SCHOOL",
    Address: "90 KAMEELRIVIER A  KAMEELRIVIER  MBIBANE",
    Latitude: "-25.1336",
    Longitude: "28.9603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18082",
    WardLookupId: "4277",
    VDNumber: "54590384",
    RegisteredPopulation: "1274",
    VotingStationName: "RETHABILE PRIMARY SCHOOL",
    Address: "31P MADUBADUBA VILLAGE  MADUBADUBA  MBIBANE",
    Latitude: "-25.139",
    Longitude: "28.9454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18083",
    WardLookupId: "4278",
    VDNumber: "54580125",
    RegisteredPopulation: "780",
    VotingStationName: "MOLAPALAMA HIGH SCHOOL",
    Address: "J500 BLOEDFONTEIN  SENOTLELO  MBIBANE",
    Latitude: "-25.0554",
    Longitude: "28.9421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18084",
    WardLookupId: "4278",
    VDNumber: "54580192",
    RegisteredPopulation: "1313",
    VotingStationName: "MATHETHE PRIMARY SCHOOL",
    Address: "SENOTLELO  SENOTLELO VILLAGE  MBIBANE",
    Latitude: "-25.0419",
    Longitude: "28.9291",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18085",
    WardLookupId: "4278",
    VDNumber: "54580248",
    RegisteredPopulation: "811",
    VotingStationName: "MOHLATSENG PRIMARY SCHOOL",
    Address: "225 SECTION C49  SENOTLELO  MBIBANE",
    Latitude: "-25.0535",
    Longitude: "28.9212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18086",
    WardLookupId: "4278",
    VDNumber: "54580259",
    RegisteredPopulation: "741",
    VotingStationName: "MOKEBE HIGH SCHOOL",
    Address: "81 SECTION L  SENOTLELO VILLAGE  MDUTJANA",
    Latitude: "-25.0733",
    Longitude: "28.9287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18087",
    WardLookupId: "4279",
    VDNumber: "54570315",
    RegisteredPopulation: "752",
    VotingStationName: "KOME PRIMARY SCHOOL",
    Address: "188 MAJATLADI SECTION  LEFISOANE VILLAGE  MATHANJANE",
    Latitude: "-24.9768",
    Longitude: "28.8613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18088",
    WardLookupId: "4279",
    VDNumber: "54580013",
    RegisteredPopulation: "1160",
    VotingStationName: "KABETE PRIMARY SCHOOL",
    Address: "233 DE BEERSPUT  KOEDOESPOORT VILLAGE  MATHANJANE",
    Latitude: "-25.0491",
    Longitude: "28.8632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18089",
    WardLookupId: "4279",
    VDNumber: "54580046",
    RegisteredPopulation: "1615",
    VotingStationName: "ITSOSENG MIDDLE SCHOOL",
    Address: "87B TROYA TRUST  TROYA VILLAGE  MATHANJANE",
    Latitude: "-25.0558",
    Longitude: "28.8022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18090",
    WardLookupId: "4279",
    VDNumber: "54580293",
    RegisteredPopulation: "519",
    VotingStationName: "APOSTOLIC FAITH MISSION",
    Address: "MATEMPULE  MARAPYANE  SKILPADFONTEIN",
    Latitude: "-25.0631",
    Longitude: "28.8069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18091",
    WardLookupId: "4279",
    VDNumber: "54580305",
    RegisteredPopulation: "561",
    VotingStationName: "COMMUNITY BAPTIST CHURCH",
    Address: "KABETE  DEBEERSPRUIT  NDUNDZA",
    Latitude: "-25.0451",
    Longitude: "28.8548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18092",
    WardLookupId: "4280",
    VDNumber: "54570203",
    RegisteredPopulation: "609",
    VotingStationName: "LEFISOANE PRIMARY SCHOOL",
    Address: "LEFISWANE VILLAGE  MORETELE 2",
    Latitude: "-24.9607",
    Longitude: "28.8494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18093",
    WardLookupId: "4280",
    VDNumber: "54570214",
    RegisteredPopulation: "969",
    VotingStationName: "MALATSE HIGH SCHOOL",
    Address: "1323 MASOGANENG SECTION  LEFISO VILLAGE  MATHANJANE",
    Latitude: "-24.9514",
    Longitude: "28.8737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18094",
    WardLookupId: "4280",
    VDNumber: "54570225",
    RegisteredPopulation: "1201",
    VotingStationName: "LEFISO PRIMARY SCHOOL",
    Address: "1923 PAWENG SECTION  LEFISO VILLAGE  MATHANJANE",
    Latitude: "-24.9356",
    Longitude: "28.8948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18095",
    WardLookupId: "4280",
    VDNumber: "54570393",
    RegisteredPopulation: "284",
    VotingStationName: "RADIJOKO PRIMARY SCHOOL",
    Address: "LENYEKETSANE  LEFISO  BAKGATLA BA MOEPI",
    Latitude: "-24.9398",
    Longitude: "28.8536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18096",
    WardLookupId: "4280",
    VDNumber: "54580204",
    RegisteredPopulation: "1140",
    VotingStationName: "LEHLABILE HIGH SCHOOL",
    Address: "300 GA-MARIA  GA-MARIA VILLAGE  MATHANJANE",
    Latitude: "-24.9729",
    Longitude: "28.8714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18097",
    WardLookupId: "4281",
    VDNumber: "54570179",
    RegisteredPopulation: "1390",
    VotingStationName: "POTSANYANE PRIMARY SCHOOL",
    Address: "417 LEHOTLONG SECTION  MARAPYANE  MATHANJANE",
    Latitude: "-25.0044",
    Longitude: "28.8084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18098",
    WardLookupId: "4281",
    VDNumber: "54570180",
    RegisteredPopulation: "1060",
    VotingStationName: "KHAMANE HIGH SCHOOL",
    Address: "843 MATOTOBELE SECTION  MARAPYANE VILLAGE  MATHANJANE",
    Latitude: "-24.9904",
    Longitude: "28.779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18099",
    WardLookupId: "4281",
    VDNumber: "54570359",
    RegisteredPopulation: "1195",
    VotingStationName: "PATRICK MMANKOLOANE PRIMARY SCHOOL",
    Address: "2079 GA-MOCHA SECTION   MARAPYANE  MATHANJANE",
    Latitude: "-25.0126",
    Longitude: "28.7974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18100",
    WardLookupId: "4282",
    VDNumber: "54570102",
    RegisteredPopulation: "896",
    VotingStationName: "KGOPE PRIMARY SCHOOL",
    Address: "422 MOHWETE SECTION  SEABE VILLAGE  MATHANJANE",
    Latitude: "-25.0339",
    Longitude: "28.7178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18101",
    WardLookupId: "4282",
    VDNumber: "54570113",
    RegisteredPopulation: "936",
    VotingStationName: "SEABE TRIBAL OFFICE",
    Address: "2183 MOSATE SECTION  SEABE VILLAGE  MATHANJANE",
    Latitude: "-25.0152",
    Longitude: "28.6973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18102",
    WardLookupId: "4282",
    VDNumber: "54570191",
    RegisteredPopulation: "993",
    VotingStationName: "IKAGELENG PRIMARY SCHOOL",
    Address: "1569 DITSHWANENG SECTION  MARAPYANE VILLAGE  MATHANJANE",
    Latitude: "-25.0181",
    Longitude: "28.7841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18103",
    WardLookupId: "4282",
    VDNumber: "54570292",
    RegisteredPopulation: "751",
    VotingStationName: "SELALO MOEPI PRE-SCHOOL",
    Address: "3776 MATJIALLA SECTION  MARAPYANE VILLAGE  MATHANJANE",
    Latitude: "-25.0131",
    Longitude: "28.7642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18104",
    WardLookupId: "4282",
    VDNumber: "54570304",
    RegisteredPopulation: "488",
    VotingStationName: "MOLOTO MIDDLE SCHOOL",
    Address: "14 GREENSIDE  MARAPYANE   MATHANJANE",
    Latitude: "-25.004",
    Longitude: "28.7279",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18105",
    WardLookupId: "4283",
    VDNumber: "54570090",
    RegisteredPopulation: "784",
    VotingStationName: "NOKANENG TRIBAL OFFICE",
    Address: "201 SETATENG SECTION  NOKANENG  MATHANJANE",
    Latitude: "-25.0723",
    Longitude: "28.6504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18106",
    WardLookupId: "4283",
    VDNumber: "54570124",
    RegisteredPopulation: "1124",
    VotingStationName: "MMUTLE MIDDLE SCHOOL",
    Address: "1119 MOGOBENG SECTION  SEABE VILLAGE  MATHANJANE",
    Latitude: "-25.0266",
    Longitude: "28.6936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18107",
    WardLookupId: "4283",
    VDNumber: "54570135",
    RegisteredPopulation: "2020",
    VotingStationName: "DIKOTELO MIDDLE SCHOOL",
    Address: "256 TERATENG SECTION  SEABE VILLAGE  MATHANJANE",
    Latitude: "-25.0067",
    Longitude: "28.6759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18108",
    WardLookupId: "4284",
    VDNumber: "54570168",
    RegisteredPopulation: "498",
    VotingStationName: "PHAPHAMA EDU CARE",
    Address: "711 SEHOKHO VILLAGE  MATHANJANE",
    Latitude: "-25.102",
    Longitude: "28.7432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18109",
    WardLookupId: "4284",
    VDNumber: "54570281",
    RegisteredPopulation: "1039",
    VotingStationName: "THEKISO PRIMARY SCHOOL",
    Address: "692 NOKANENG MAIN ROAD   NOKANENG  MATHANJANE",
    Latitude: "-25.0737",
    Longitude: "28.6411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18110",
    WardLookupId: "4284",
    VDNumber: "54580057",
    RegisteredPopulation: "1211",
    VotingStationName: "LODING PRIMARY SCHOOL",
    Address: "34 ROOIKOPPEN A  LODING VILLAGE  MBIBANE",
    Latitude: "-25.1069",
    Longitude: "28.7587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18111",
    WardLookupId: "4284",
    VDNumber: "54580114",
    RegisteredPopulation: "452",
    VotingStationName: "SIBONGILE PRIMARY SCHOOL",
    Address: "56 DIHEKENG   DIHEKENG VILLAGE  MBIBANE",
    Latitude: "-25.0635",
    Longitude: "28.7712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18112",
    WardLookupId: "4284",
    VDNumber: "54580316",
    RegisteredPopulation: "804",
    VotingStationName: "MAKHOSONKE PRIMARY SCHOOL",
    Address: "DR JS MOROKA   ",
    Latitude: "-25.1137",
    Longitude: "28.7544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18113",
    WardLookupId: "4285",
    VDNumber: "54570012",
    RegisteredPopulation: "1485",
    VotingStationName: "SOMABEDLANA PRIMARY SCHOOL",
    Address: "372 GUGULETHU SECTION  KATJIBANE VILLAGE  MATHANJANE",
    Latitude: "-24.9962",
    Longitude: "28.624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18114",
    WardLookupId: "4285",
    VDNumber: "54570146",
    RegisteredPopulation: "1097",
    VotingStationName: "MOTSWEDI PRIMARY SCHOOL",
    Address: "1147 LESELESELENG SECTION  SEABE VILLAGE  MATHANJANE",
    Latitude: "-25.0268",
    Longitude: "28.6567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18115",
    WardLookupId: "4285",
    VDNumber: "54570337",
    RegisteredPopulation: "330",
    VotingStationName: "PUNGUTSHA SECONDARY SCHOOL",
    Address: "680A KATJIBANE  MATHANJANE",
    Latitude: "-25.0179",
    Longitude: "28.6278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18116",
    WardLookupId: "4285",
    VDNumber: "54570405",
    RegisteredPopulation: "752",
    VotingStationName: "LUTHERAN CHURCH OF SOUTH AFRICA",
    Address: "KATJIBANE  MATHANJANE  NDUNDZA PUNGUTSHA",
    Latitude: "-24.9916",
    Longitude: "28.6342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18117",
    WardLookupId: "4286",
    VDNumber: "54570067",
    RegisteredPopulation: "1038",
    VotingStationName: "TLHAME PRIMARY SCHOOL",
    Address: "1683 NEWSTAND SECTION  MMAMETIHAKE VILLAGE  MATHANJANE",
    Latitude: "-25.1139",
    Longitude: "28.5636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18118",
    WardLookupId: "4286",
    VDNumber: "54570089",
    RegisteredPopulation: "1156",
    VotingStationName: "NTESENG PRIMARY SCHOOL",
    Address: "1214 LESWATLHENG SECTION  NOKANENG  MATHANJANE",
    Latitude: "-25.0765",
    Longitude: "28.6297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18119",
    WardLookupId: "4286",
    VDNumber: "54570270",
    RegisteredPopulation: "1088",
    VotingStationName: "KAGISO PRIMARY SCHOOL",
    Address: "1691 NOKANENG  MATHANJANE",
    Latitude: "-25.0877",
    Longitude: "28.622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18120",
    WardLookupId: "4287",
    VDNumber: "54570045",
    RegisteredPopulation: "775",
    VotingStationName: "TABANE PRIMARY SCHOOL",
    Address: "513 PHELENDABA SECTION  MMAMETIHAKE VILLAGE  MATHANJANE",
    Latitude: "-25.1062",
    Longitude: "28.5239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18121",
    WardLookupId: "4287",
    VDNumber: "54570056",
    RegisteredPopulation: "621",
    VotingStationName: "MMADIKOLE PRIMARY SCHOOL",
    Address: "590 THABANENG SECTION  MMAMETLHAKE VILLAGE  MATHANJANE",
    Latitude: "-25.0958",
    Longitude: "28.5239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18122",
    WardLookupId: "4287",
    VDNumber: "54570258",
    RegisteredPopulation: "1499",
    VotingStationName: "ANDRIES MASHILE PRIMARY SCHOOL",
    Address: "832 PHAKE RATLHAGANA  PHAKE VILLAGE  MATHANJANE",
    Latitude: "-25.1481",
    Longitude: "28.5069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18123",
    WardLookupId: "4287",
    VDNumber: "54570360",
    RegisteredPopulation: "926",
    VotingStationName: "MMAMATLI SENIOR SECONDARY SCHOOL",
    Address: "1408 NAPIRI SECTION  MAMETLHAKE VILLAGE  MATHANJANE",
    Latitude: "-25.106",
    Longitude: "28.5518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18124",
    WardLookupId: "4287",
    VDNumber: "54570416",
    RegisteredPopulation: "400",
    VotingStationName: "MMAMETHLAKE HIGH SCHOOL",
    Address: "MMAMETHLAKE  BAKGATLA BA MALOKA",
    Latitude: "-25.1072",
    Longitude: "28.5123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18125",
    WardLookupId: "4288",
    VDNumber: "54570034",
    RegisteredPopulation: "1087",
    VotingStationName: "MAPALA MIDDLE SCHOOL",
    Address: "367 PHAKE THABENG  PHAKE THABENG VILLAGE  MATHANJANE",
    Latitude: "-25.1506",
    Longitude: "28.4861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18126",
    WardLookupId: "4288",
    VDNumber: "54570269",
    RegisteredPopulation: "1043",
    VotingStationName: "RANKAILE PRIMARY SCHOOL",
    Address: "173 PHAKE RANKAILE  PHAKE VILLAGE  MATHANJANE",
    Latitude: "-25.156",
    Longitude: "28.4574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18127",
    WardLookupId: "4288",
    VDNumber: "54570348",
    RegisteredPopulation: "375",
    VotingStationName: "MANTLOLE PRIMARY SCHOOL",
    Address: "2062 MANTLOLE SECTION  PANKOP  MATHANJANE",
    Latitude: "-25.1656",
    Longitude: "28.4394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18128",
    WardLookupId: "4288",
    VDNumber: "54570371",
    RegisteredPopulation: "808",
    VotingStationName: "BETHESDA MINISTRIES",
    Address: "REBONE RDP  PHAKE  MATHANJANA",
    Latitude: "-25.1558",
    Longitude: "28.4669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18129",
    WardLookupId: "4288",
    VDNumber: "54570427",
    RegisteredPopulation: "562",
    VotingStationName: "PHAKE PRIMARY SCHOOL",
    Address: "PHAKE   PHAKE THABENG  BAKGATLA BA MMAKAU",
    Latitude: "-25.1534",
    Longitude: "28.4823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18130",
    WardLookupId: "4289",
    VDNumber: "54570236",
    RegisteredPopulation: "1486",
    VotingStationName: "RATLHAGANA HIGH SCHOOL",
    Address: "1 PANKOP VILLAGE  MATHANJANE",
    Latitude: "-25.1717",
    Longitude: "28.4032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18131",
    WardLookupId: "4289",
    VDNumber: "54570247",
    RegisteredPopulation: "1131",
    VotingStationName: "MASOBYE PRIMARY SCHOOL",
    Address: "1789 PANKOP VILLAGE  MATHANJANE",
    Latitude: "-25.1736",
    Longitude: "28.4193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18132",
    WardLookupId: "4289",
    VDNumber: "54570326",
    RegisteredPopulation: "1498",
    VotingStationName: "MOCHA PRIMARY SCHOOL",
    Address: "281 MOEMA SECTION  PANKOP VILLAGE  MATHANJANE",
    Latitude: "-25.16",
    Longitude: "28.4162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18133",
    WardLookupId: "4289",
    VDNumber: "54570438",
    RegisteredPopulation: "468",
    VotingStationName: "MPOKO MALOKA MIDDLE SCHOOL",
    Address: "PANKOP  RATHLAGANA  BAKGATLA BA MALOKA",
    Latitude: "-25.1633",
    Longitude: "28.4272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18134",
    WardLookupId: "4290",
    VDNumber: "54760024",
    RegisteredPopulation: "2038",
    VotingStationName: "MASHISHING COMMUNITY HALL",
    Address: "HOOF STREET  MASHISHING  LYDENBURG",
    Latitude: "-25.0877",
    Longitude: "30.4209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18135",
    WardLookupId: "4290",
    VDNumber: "54760035",
    RegisteredPopulation: "1355",
    VotingStationName: "LESODI PRIMARY SCHOOL",
    Address: "MEESTER STREET  MASHISHING  LYDENBURG",
    Latitude: "-25.0879",
    Longitude: "30.4173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18136",
    WardLookupId: "4291",
    VDNumber: "54760091",
    RegisteredPopulation: "1323",
    VotingStationName: "MASHISHING EXT 6 HALL",
    Address: "TAMBO STREET  MASHISHING  EHLANZENI",
    Latitude: "-25.0862",
    Longitude: "30.4296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18137",
    WardLookupId: "4291",
    VDNumber: "54760114",
    RegisteredPopulation: "1822",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "728 JOCKLAAN STRAAT  TOWNSHIP  THABA CHWEU",
    Latitude: "-25.0831",
    Longitude: "30.4232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18138",
    WardLookupId: "4292",
    VDNumber: "54760013",
    RegisteredPopulation: "2656",
    VotingStationName: "MARAMBANE PRIMARY SCHOOL",
    Address: "DERDE STREET  MASHISHING  LYDENBURG",
    Latitude: "-25.0929",
    Longitude: "30.4187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18139",
    WardLookupId: "4292",
    VDNumber: "54760103",
    RegisteredPopulation: "1579",
    VotingStationName: "MASHISHING SECONDARY SCHOOL",
    Address: "843 2ND AVENUE STREET  VILLAGE  EHLANZENI",
    Latitude: "-25.0956",
    Longitude: "30.4168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18140",
    WardLookupId: "4293",
    VDNumber: "54860014",
    RegisteredPopulation: "1038",
    VotingStationName: "SHALOM CHURCH CENTRE",
    Address: "COROMANDEL FARM  EMSHININI  EHLANZENI",
    Latitude: "-25.2281",
    Longitude: "30.3586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18141",
    WardLookupId: "4293",
    VDNumber: "54860070",
    RegisteredPopulation: "1214",
    VotingStationName: "KLIPSPRUIT COMBINED SCHOOL",
    Address: "LYDENBURG / MACHADODORP ROAD  BADFONTEIN  LYDENBURG",
    Latitude: "-25.3494",
    Longitude: "30.3601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18142",
    WardLookupId: "4293",
    VDNumber: "54860159",
    RegisteredPopulation: "180",
    VotingStationName: "UITKYK PLANTATION",
    Address: "MAIN ROAD TO MAKOBULAAN  UITKYK VILLAGE  LYDENURG",
    Latitude: "-25.2088",
    Longitude: "30.5689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18143",
    WardLookupId: "4293",
    VDNumber: "54900019",
    RegisteredPopulation: "580",
    VotingStationName: "MONDI ACME PRIMARY SCHOOL",
    Address: "LONE CREEK ROAD  ACME  EHLANZENI",
    Latitude: "-25.1087",
    Longitude: "30.7515",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18144",
    WardLookupId: "4293",
    VDNumber: "54900075",
    RegisteredPopulation: "271",
    VotingStationName: "RHENOSTERHOEK COMPOUND HALL",
    Address: "SABIE LYDENBURG ROAD  MAKUSTEREN  EHLANZENI",
    Latitude: "-25.1966",
    Longitude: "30.6773",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18145",
    WardLookupId: "4294",
    VDNumber: "54660012",
    RegisteredPopulation: "877",
    VotingStationName: "DRAAIKRAAL SECONDARY SCHOOL",
    Address: "DRAAIKRAAL FARM  DRAALKRAAL  EHLANZENI",
    Latitude: "-25.2097",
    Longitude: "30.0231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18146",
    WardLookupId: "4294",
    VDNumber: "54760057",
    RegisteredPopulation: "1762",
    VotingStationName: "MARIFAAN PRIMARY SCHOOL",
    Address: "3361 ZWELAKHE STREET  VILLAGE  THABA CHWEU [SABIE]",
    Latitude: "-25.0756",
    Longitude: "30.4478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18147",
    WardLookupId: "4294",
    VDNumber: "54760080",
    RegisteredPopulation: "1795",
    VotingStationName: "KELLYSVILLE PRIMARY SCHOOL",
    Address: "PROTEA AVENUE  MASHISHING  EHLANZENI",
    Latitude: "-25.0816",
    Longitude: "30.4318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18148",
    WardLookupId: "4294",
    VDNumber: "54860036",
    RegisteredPopulation: "1176",
    VotingStationName: "BOSFONTEIN PRIMARY SCHOOL",
    Address: "NAAUPOORT  BOSFONTEIN FARM  LYDENBURG",
    Latitude: "-25.0984",
    Longitude: "30.2479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18149",
    WardLookupId: "4294",
    VDNumber: "54860317",
    RegisteredPopulation: "185",
    VotingStationName: "ENKELDOORN PRIMARY SCHOOL",
    Address: "SEKHUKHUNE ROAD  LYDENBURG  BOSHOEK",
    Latitude: "-25.1422",
    Longitude: "30.3444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18150",
    WardLookupId: "4295",
    VDNumber: "54800018",
    RegisteredPopulation: "2575",
    VotingStationName: "EKUTHULENI COMMUNITY HALL",
    Address: "NGQUNGQULU ST  SIMILE  SABIE",
    Latitude: "-25.0789",
    Longitude: "30.7819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18151",
    WardLookupId: "4295",
    VDNumber: "54800030",
    RegisteredPopulation: "1003",
    VotingStationName: "MEMEZILE HIGH SCHOOL",
    Address: "ZWELAKHE  SIMILE  SABIE",
    Latitude: "-25.075",
    Longitude: "30.7802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18152",
    WardLookupId: "4296",
    VDNumber: "54800029",
    RegisteredPopulation: "2849",
    VotingStationName: "SABIE LAERSKOOL",
    Address: "EIGHT AVENUE  SABIE TOWN  SABIE",
    Latitude: "-25.0978",
    Longitude: "30.7814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18153",
    WardLookupId: "4296",
    VDNumber: "54800041",
    RegisteredPopulation: "792",
    VotingStationName: "HARMONY HILL COM HALL",
    Address: "GLENS  HARMONY HILL  SABIE",
    Latitude: "-25.0988",
    Longitude: "30.7878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18154",
    WardLookupId: "4297",
    VDNumber: "54900031",
    RegisteredPopulation: "1315",
    VotingStationName: "MATIBIDI PRIMARY SCHOOL",
    Address: "MATIBIDI  GRASKOP  PILGRIM REST",
    Latitude: "-24.5943",
    Longitude: "30.7666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18155",
    WardLookupId: "4297",
    VDNumber: "54900042",
    RegisteredPopulation: "1477",
    VotingStationName: "MASHILANE TRIBAL OFFICE",
    Address: "01 MATIBIDI  PILGRIM REST",
    Latitude: "-24.6235",
    Longitude: "30.757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18156",
    WardLookupId: "4297",
    VDNumber: "54900053",
    RegisteredPopulation: "914",
    VotingStationName: "SHAKWANENG PRIMARY SCHOOL",
    Address: "MATIBIDI  GRASKOP  PILGRIM REST",
    Latitude: "-24.6371",
    Longitude: "30.7436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18157",
    WardLookupId: "4298",
    VDNumber: "54900020",
    RegisteredPopulation: "2009",
    VotingStationName: "DIENTJIE PRIMARY SCHOOL",
    Address: "LERORO-A  GRASKOP",
    Latitude: "-24.6152",
    Longitude: "30.7977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18158",
    WardLookupId: "4298",
    VDNumber: "54900097",
    RegisteredPopulation: "1356",
    VotingStationName: "MOREMELA KOBENG HIGH PRIMARY SCHOOL",
    Address: "MOREMELA  GRASKOP  PILGRIM REST",
    Latitude: "-24.6705",
    Longitude: "30.8023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18159",
    WardLookupId: "4298",
    VDNumber: "54900187",
    RegisteredPopulation: "450",
    VotingStationName: "PITAS PRIMARY SCHOOL",
    Address: "HLABEKISA  LERORO  GRASKOP",
    Latitude: "-24.6319",
    Longitude: "30.7931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18160",
    WardLookupId: "4298",
    VDNumber: "54900198",
    RegisteredPopulation: "723",
    VotingStationName: "L.L.MOGANE PRIMARY SCHOOL",
    Address: "MPHATO  MOREMELA  MOGANE TRIBAL",
    Latitude: "-24.6617",
    Longitude: "30.8116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18161",
    WardLookupId: "4299",
    VDNumber: "54730010",
    RegisteredPopulation: "2260",
    VotingStationName: "GRASKOP TOWN HALL",
    Address: "14 LOUIS TRICHARDT ST  GRASKOP  GRASKOP",
    Latitude: "-24.9315",
    Longitude: "30.841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18162",
    WardLookupId: "4299",
    VDNumber: "54900086",
    RegisteredPopulation: "463",
    VotingStationName: "WATERHOUTBOOM",
    Address: "GRASKOP HAZYVIEW  WATERHOUTBOOM FARM  PILGRIMS REST",
    Latitude: "-24.9528",
    Longitude: "30.9049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18163",
    WardLookupId: "4299",
    VDNumber: "54900132",
    RegisteredPopulation: "305",
    VotingStationName: "MONDI SAWMILL VILLAGE SCHOOL",
    Address: "GRASKOP TO MOREMELA ROAD  DRIEKOP PLANTATION  EHLANZENI",
    Latitude: "-24.8976",
    Longitude: "30.8391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18164",
    WardLookupId: "4299",
    VDNumber: "54900200",
    RegisteredPopulation: "585",
    VotingStationName: "SEKWAI SECONDARY SCHOOL",
    Address: "MOREMELA  MOGANE TRIBAL OFFICCE",
    Latitude: "-24.673",
    Longitude: "30.8",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18165",
    WardLookupId: "4300",
    VDNumber: "54870082",
    RegisteredPopulation: "817",
    VotingStationName: "MS MALHERBE COMBINED SCHOOL",
    Address: "MAIN ROAD NELSPRUIT SABIE  BRONDAL VILLAGE  EHLANZENI",
    Latitude: "-25.3548",
    Longitude: "30.8411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18166",
    WardLookupId: "4300",
    VDNumber: "54900121",
    RegisteredPopulation: "285",
    VotingStationName: "SPITSKOP COMPOUND HALL",
    Address: "SABIE WHITE RIVER ROAD  KOMATILAND  EHLANZENI",
    Latitude: "-25.1301",
    Longitude: "30.8158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18167",
    WardLookupId: "4300",
    VDNumber: "54900154",
    RegisteredPopulation: "125",
    VotingStationName: "MATUKUSANI COMMUNITY HALL",
    Address: "HAZYVIEW RAOD  BERGVLIET  EHLANZENI",
    Latitude: "-25.0917",
    Longitude: "30.8949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18168",
    WardLookupId: "4300",
    VDNumber: "54900176",
    RegisteredPopulation: "345",
    VotingStationName: "HENDRICKSDAL STATION",
    Address: "MP321 - THABA CHWEU [SABIE]  ",
    Latitude: "-25.1881",
    Longitude: "30.7755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18169",
    WardLookupId: "4300",
    VDNumber: "54910010",
    RegisteredPopulation: "933",
    VotingStationName: "DENSA SAWMILL COMMITTEE ROOM",
    Address: "SABIE /NELSPRUIT ROAD  DENSA SAWMILL  PILGRIMS REST",
    Latitude: "-25.3078",
    Longitude: "30.8184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18170",
    WardLookupId: "4300",
    VDNumber: "54910762",
    RegisteredPopulation: "429",
    VotingStationName: "WITKLIP HALL",
    Address: "WHITE RIVER  MPUMALANGA AREA  EHLANZENI",
    Latitude: "-25.2414",
    Longitude: "30.8815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18171",
    WardLookupId: "4301",
    VDNumber: "54760079",
    RegisteredPopulation: "2097",
    VotingStationName: "REHOBOTH CHURCH",
    Address: "10 CORNER KEET AND VOORTREKKER  LYDENBURG  LYDENBURG",
    Latitude: "-25.1022",
    Longitude: "30.4713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18172",
    WardLookupId: "4301",
    VDNumber: "54760125",
    RegisteredPopulation: "1812",
    VotingStationName: "LYDENBURG RECREATIONAL HALL",
    Address: "CORNER NEL & FINSBURY  LYDENBURG  EHLANZENI",
    Latitude: "-25.0983",
    Longitude: "30.4609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18173",
    WardLookupId: "4302",
    VDNumber: "54860137",
    RegisteredPopulation: "517",
    VotingStationName: "SPEKBOOM LUTHERAN CHURCH",
    Address: "LYDENBURG OHRIGSTAD ROAD  SPEKBOOM  LYDENBURG",
    Latitude: "-24.9951",
    Longitude: "30.4893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18174",
    WardLookupId: "4302",
    VDNumber: "54860193",
    RegisteredPopulation: "262",
    VotingStationName: "VALENCIA LODGE HALL",
    Address: "BURGESFORT ROAD  BEETGEKRAAL  LYDENBURG",
    Latitude: "-24.8767",
    Longitude: "30.3191",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18175",
    WardLookupId: "4302",
    VDNumber: "54860205",
    RegisteredPopulation: "183",
    VotingStationName: "MAKHOMANE COMMUNAL OFFICE",
    Address: "LYDENBURG ROAD FROM PILGRIMS  MAKHOMANE VILLAGE  OHRIGSTAD DAM",
    Latitude: "-24.9058",
    Longitude: "30.6124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18176",
    WardLookupId: "4302",
    VDNumber: "54860216",
    RegisteredPopulation: "159",
    VotingStationName: "BOOMPLAAS HALL",
    Address:
      "LYDENBURG TO BURGERSFORT ROAD (NEXT TO THE ROAD)  BOOMPLAAS  THABA CHWEU",
    Latitude: "-24.999",
    Longitude: "30.3968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18177",
    WardLookupId: "4302",
    VDNumber: "54900064",
    RegisteredPopulation: "1245",
    VotingStationName: "PILGRIMS REST COMBINED SCHOOL",
    Address: "MAIN ROAD  PILGRIMS REST  PILGRIMS REST",
    Latitude: "-24.89",
    Longitude: "30.7565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18178",
    WardLookupId: "4302",
    VDNumber: "54900109",
    RegisteredPopulation: "267",
    VotingStationName: "TWEEFONTEIN VILLAGE HALL",
    Address: "TWEEFONTEIN ROAD  TWEEFONTEIN  PILGRIMS REST",
    Latitude: "-25.0588",
    Longitude: "30.7777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18179",
    WardLookupId: "4302",
    VDNumber: "54900110",
    RegisteredPopulation: "316",
    VotingStationName: "MOSEHLANE MONDI SCHOOL",
    Address: "MONDI TIMBER  SABIE  PILGRIM REST",
    Latitude: "-24.944",
    Longitude: "30.7398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18180",
    WardLookupId: "4303",
    VDNumber: "54760046",
    RegisteredPopulation: "2396",
    VotingStationName: "LYDENBURG CIVIC CENTRE",
    Address: "CORNER CENTRAL & VILJOEN  LYDENBURG  EHLANZENI",
    Latitude: "-25.0938",
    Longitude: "30.4558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18181",
    WardLookupId: "4303",
    VDNumber: "54760068",
    RegisteredPopulation: "803",
    VotingStationName: "INDIAN COMMUNITY CENTRE HALL",
    Address:
      "TWEEDE STR 1596 DISASTER CENTRE,INDIAN CENTRE   LYDENBURG  THABA CHWEU",
    Latitude: "-25.0946",
    Longitude: "30.4416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18182",
    WardLookupId: "2193",
    VDNumber: "54750023",
    RegisteredPopulation: "1787",
    VotingStationName: "KHULA SECONDARY SCHOOL",
    Address: "KAMAQHEKEZA  KWALUGEDLANE  KWALUGEDLANE",
    Latitude: "-25.6657",
    Longitude: "31.8602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18183",
    WardLookupId: "2193",
    VDNumber: "54880342",
    RegisteredPopulation: "2786",
    VotingStationName: "INGWENYAMA COMBINED SCHOOL",
    Address: "BLOCK C  KWALUGEDLANE",
    Latitude: "-25.6523",
    Longitude: "31.8468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18184",
    WardLookupId: "2193",
    VDNumber: "54880498",
    RegisteredPopulation: "954",
    VotingStationName: "ZAMOKUHLE COMBINED SCHOOL",
    Address: "BLOCK C  NKOMAZI [NKOMAZI]",
    Latitude: "-25.6586",
    Longitude: "31.8519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18185",
    WardLookupId: "2193",
    VDNumber: "54880566",
    RegisteredPopulation: "586",
    VotingStationName: "ETINDZALENI PRIMARY SCHOOL",
    Address: "BLOCK C  SIBOSHWA  KOMATIPOORT",
    Latitude: "-25.6506",
    Longitude: "31.8538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18186",
    WardLookupId: "2194",
    VDNumber: "54880050",
    RegisteredPopulation: "2171",
    VotingStationName: "LAMBALATI SECONDARY SCHOOL",
    Address: "BLOCK B  NKOMAZI  KWALUGEDLANE",
    Latitude: "-25.6722",
    Longitude: "31.8137",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18187",
    WardLookupId: "2194",
    VDNumber: "54880296",
    RegisteredPopulation: "718",
    VotingStationName: "MAMBANE SECONDARY SCHOOL",
    Address: "BLOCK C  NKOMAZI  SIBOSHWA",
    Latitude: "-25.6639",
    Longitude: "31.8407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18188",
    WardLookupId: "2194",
    VDNumber: "54880319",
    RegisteredPopulation: "1320",
    VotingStationName: "CHIEF MAKUNYULA PRIMARY SCHOOL",
    Address: "BLOCK A  NKOMAZI  SIBOSHWA",
    Latitude: "-25.6867",
    Longitude: "31.8102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18189",
    WardLookupId: "2194",
    VDNumber: "54880500",
    RegisteredPopulation: "685",
    VotingStationName: "CHRISTIAN EMBASSY HOUSE MINISTRIES",
    Address: "BLOCK C  NKOMAZI  SIBOSHWA",
    Latitude: "-25.6679",
    Longitude: "31.8353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18190",
    WardLookupId: "2194",
    VDNumber: "54880555",
    RegisteredPopulation: "644",
    VotingStationName: "CHRISTIAN`S CHURCH OF GOD IN AFRICA",
    Address: "65 BLOCK A3  NKOMAZI  SIBOSHWA",
    Latitude: "-25.6814",
    Longitude: "31.8134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18191",
    WardLookupId: "2194",
    VDNumber: "54880634",
    RegisteredPopulation: "561",
    VotingStationName: "INKOMAZI HIGH SCHOOL",
    Address: "MANGWENI  KWALUGEDLANE  MALELANE",
    Latitude: "-25.7368",
    Longitude: "31.8191",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18192",
    WardLookupId: "2195",
    VDNumber: "54750045",
    RegisteredPopulation: "1262",
    VotingStationName: "SEKUSILE PRIMARY SCHOOL",
    Address: "KAMAQHEKEZA  NKOMAZI [NKOMAZI]",
    Latitude: "-25.6775",
    Longitude: "31.8665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18193",
    WardLookupId: "2195",
    VDNumber: "54880016",
    RegisteredPopulation: "1222",
    VotingStationName: "HOLINESS UNION CHURCH (BLOCK C)",
    Address: "BLOCK C  NKOMAZI  SIBOSHWA",
    Latitude: "-25.6667",
    Longitude: "31.8509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18194",
    WardLookupId: "2195",
    VDNumber: "54880421",
    RegisteredPopulation: "2239",
    VotingStationName: "MAGEWU PRIMARY SCHOOL",
    Address: "BLOCK C  ",
    Latitude: "-25.6768",
    Longitude: "31.8464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18195",
    WardLookupId: "2195",
    VDNumber: "54880645",
    RegisteredPopulation: "535",
    VotingStationName: "OLD WEM",
    Address: "BLOCK C  NKOMAZI  SIBOSHWA",
    Latitude: "-25.6869",
    Longitude: "31.8501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18196",
    WardLookupId: "2195",
    VDNumber: "54880656",
    RegisteredPopulation: "788",
    VotingStationName: "SINCOBILE SECONDARY SCHOOL",
    Address: "BLOCK C  NKOMAZI  SIBOSHWA TRIBAL",
    Latitude: "-25.6802",
    Longitude: "31.8396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18197",
    WardLookupId: "2196",
    VDNumber: "54750012",
    RegisteredPopulation: "2850",
    VotingStationName: "MJOKWANE SECONDARY SCHOOL",
    Address: "NAAS  NKOMAZI  SIBOSHWA TRIBAL",
    Latitude: "-25.6739",
    Longitude: "31.8661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18198",
    WardLookupId: "2196",
    VDNumber: "54750056",
    RegisteredPopulation: "1382",
    VotingStationName: "NAAS EMMANUEL CHURCH",
    Address: "NAAS  KAMAQHEKEZA  NKOMAZI [NKOMAZI]",
    Latitude: "-25.6715",
    Longitude: "31.8673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18199",
    WardLookupId: "2196",
    VDNumber: "54750078",
    RegisteredPopulation: "710",
    VotingStationName: "THE SALVATION ARMY (KAMACHEKEZA)",
    Address: "NAAS  KAMACHEKEZA  KOMATIPOORT",
    Latitude: "-25.6748",
    Longitude: "31.8561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18200",
    WardLookupId: "2196",
    VDNumber: "54750089",
    RegisteredPopulation: "401",
    VotingStationName: "THOLULWAZI MULTI PURPOSE CENTRE",
    Address: "KAMAQHEKEZA  NKOMAZI  SIBOSHWA",
    Latitude: "-25.6842",
    Longitude: "31.8543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18201",
    WardLookupId: "2197",
    VDNumber: "54880061",
    RegisteredPopulation: "1097",
    VotingStationName: "EMATHOLENI PRIMARY SCHOOL",
    Address: "BLOCK B  BLOCKB  SIBOSHWA TRIBAL",
    Latitude: "-25.6562",
    Longitude: "31.8066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18202",
    WardLookupId: "2197",
    VDNumber: "54880308",
    RegisteredPopulation: "1990",
    VotingStationName: "KHULAMSHIKA COMBINED SCHOOL",
    Address: "BLOCK B  NKOMAZI  SIBOSHWA",
    Latitude: "-25.6638",
    Longitude: "31.811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18203",
    WardLookupId: "2197",
    VDNumber: "54880410",
    RegisteredPopulation: "1960",
    VotingStationName: "MATJENI PRIMARY SCHOOL",
    Address: "BLOCK B  ",
    Latitude: "-25.6637",
    Longitude: "31.8258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18204",
    WardLookupId: "2197",
    VDNumber: "54880667",
    RegisteredPopulation: "555",
    VotingStationName: "MASIBONISANE PRIMARY SCHOOL",
    Address: "BLOCK B  NKOMAZI  SIBOSHWA TRIBAL",
    Latitude: "-25.6488",
    Longitude: "31.8109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18205",
    WardLookupId: "2198",
    VDNumber: "54750034",
    RegisteredPopulation: "1425",
    VotingStationName: "KOMATIPOORT SHOW HALL",
    Address: "412 ERF STREET  KOMATIPOORT  KOMATIPOORT",
    Latitude: "-25.4331",
    Longitude: "31.9548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18206",
    WardLookupId: "2198",
    VDNumber: "54750067",
    RegisteredPopulation: "413",
    VotingStationName: "CROMATI COMBINED SCHOOL",
    Address: "KOMATIPOORT  NKOMAZI  KOMATIPOORT",
    Latitude: "-25.4299",
    Longitude: "31.9375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18207",
    WardLookupId: "2198",
    VDNumber: "54880027",
    RegisteredPopulation: "225",
    VotingStationName: "MOBILE VOTING STATION (KOMATIDRAAI LANDGOED)",
    Address:
      "SCHOEMAN / KOMATIDRAAI SECTION 3&4 / COOPERSDAL  KOMATIPOORT  KOMATIPOORT",
    Latitude: "-25.5108",
    Longitude: "31.9178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18208",
    WardLookupId: "2198",
    VDNumber: "54880038",
    RegisteredPopulation: "1327",
    VotingStationName: "MBOMBO PRIMARY SCHOOL",
    Address: "DLUDLUMA  NKOMAZI  MALELANE",
    Latitude: "-25.6605",
    Longitude: "31.9516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18209",
    WardLookupId: "2198",
    VDNumber: "54880049",
    RegisteredPopulation: "1227",
    VotingStationName: "THULA PRIMARY SCHOOL",
    Address: "NGWENYENI  ",
    Latitude: "-25.6619",
    Longitude: "31.9032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18210",
    WardLookupId: "2198",
    VDNumber: "54880577",
    RegisteredPopulation: "653",
    VotingStationName: "MEHLOBOVU HIGH SCHOOL",
    Address: "NGWENYENI  KOMATIPOORT",
    Latitude: "-25.6619",
    Longitude: "31.8995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18211",
    WardLookupId: "2198",
    VDNumber: "54880690",
    RegisteredPopulation: "396",
    VotingStationName: "HOUSE OF HOPE",
    Address: "NAAS  NKOMAZI  SIBOSHWA",
    Latitude: "-25.6653",
    Longitude: "31.8759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18212",
    WardLookupId: "2198",
    VDNumber: "54890848",
    RegisteredPopulation: "253",
    VotingStationName: "MOBILE VOTING STATION (KAMEHLWANE FARM COMPOUND)",
    Address: "KAMEHLWANE  KOMATIPOORT  NKOMAZI",
    Latitude: "-25.4995",
    Longitude: "31.9432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18213",
    WardLookupId: "2199",
    VDNumber: "54770047",
    RegisteredPopulation: "299",
    VotingStationName: "MOBILE VOTING STATION (RIVERSIDE NGONINI COMPOUND)",
    Address: "RIVERSIDE  MALELANE  MALELANE",
    Latitude: "-25.4184",
    Longitude: "31.5962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18214",
    WardLookupId: "2199",
    VDNumber: "54780015",
    RegisteredPopulation: "1056",
    VotingStationName: "MARLOTH PARK MUNICIPAL OFFICES",
    Address: "CROCODILE ST  MARLOTH PARK  MARLOTH PARK",
    Latitude: "-25.3682",
    Longitude: "31.758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18215",
    WardLookupId: "2199",
    VDNumber: "54880511",
    RegisteredPopulation: "840",
    VotingStationName: "PROF. S.S. REPINGA COMBINED SCHOOL",
    Address: "BLOCK B  MP324 - NKOMAZI [NKOMAZI]  SIBOSHWA",
    Latitude: "-25.6446",
    Longitude: "31.8018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18216",
    WardLookupId: "2199",
    VDNumber: "54890039",
    RegisteredPopulation: "76",
    VotingStationName: "TOMAHAWK FARM COMPOUND(TENT)",
    Address: "TOMAHAWK  NKOMAZI  MALALANE",
    Latitude: "-25.6155",
    Longitude: "31.609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18217",
    WardLookupId: "2199",
    VDNumber: "54890387",
    RegisteredPopulation: "1567",
    VotingStationName: "MDLADLA PRIMARY SCHOOL 1",
    Address: "MDLADLA  NKOMAZI  MDLADLA",
    Latitude: "-25.6392",
    Longitude: "31.7262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18218",
    WardLookupId: "2199",
    VDNumber: "54890602",
    RegisteredPopulation: "493",
    VotingStationName: "LOWER SABIE",
    Address: "KRUGER GATE  NKOMAZI  NKOMAZI",
    Latitude: "-25.1209",
    Longitude: "31.9162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18219",
    WardLookupId: "2199",
    VDNumber: "54890725",
    RegisteredPopulation: "166",
    VotingStationName: "MOBILE VOTING STATION (KWA MADWALA PRIVATE GAME R)",
    Address: "SAVANNA  LOWHILLS  HECTORSPRUIT",
    Latitude: "-25.5468",
    Longitude: "31.6142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18220",
    WardLookupId: "2199",
    VDNumber: "54890927",
    RegisteredPopulation: "661",
    VotingStationName: "SIGWEJE HIGH SCHOOL",
    Address: "MDLADLA  VLAKBULT  MATSAMO TRIBAL",
    Latitude: "-25.6329",
    Longitude: "31.7301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18221",
    WardLookupId: "2200",
    VDNumber: "54880106",
    RegisteredPopulation: "1409",
    VotingStationName: "EKWENZENI PRIMARY SCHOOL",
    Address: "STEENBOK  KWALUGEDLANE  KWALUGEDLANE",
    Latitude: "-25.7539",
    Longitude: "31.8894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18222",
    WardLookupId: "2200",
    VDNumber: "54880117",
    RegisteredPopulation: "1633",
    VotingStationName: "MBHUNU SECONDARY SCHOOL",
    Address: "STEENBOK  KWALUGEDLANE  KWALUDLANE",
    Latitude: "-25.7396",
    Longitude: "31.8897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18223",
    WardLookupId: "2200",
    VDNumber: "54880476",
    RegisteredPopulation: "586",
    VotingStationName: "SHINYUKANE HIGH SCHOOL 800020511",
    Address: "800020511 KWALUGEDLANE VILLAGE  KWALUGEDLENE  NKOMAZI [NKOMAZI]",
    Latitude: "-25.7588",
    Longitude: "31.8406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18224",
    WardLookupId: "2200",
    VDNumber: "54880487",
    RegisteredPopulation: "1400",
    VotingStationName: "STEENBOK SECONDARY SCHOOL",
    Address: "STEENBOK  NKOMAZI [NKOMAZI]",
    Latitude: "-25.7257",
    Longitude: "31.8984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18225",
    WardLookupId: "2200",
    VDNumber: "54880678",
    RegisteredPopulation: "486",
    VotingStationName: "SAMBO PRIMARY SCHOOL",
    Address: "STEENBOK  NKOMAZI  SIBOSHWA TRIBAL",
    Latitude: "-25.7726",
    Longitude: "31.8934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18226",
    WardLookupId: "2201",
    VDNumber: "54880094",
    RegisteredPopulation: "2363",
    VotingStationName: "TONGAVIEW PRIMARY SCHOOL",
    Address: "TONGAVIEW  TONGA  MALALANE",
    Latitude: "-25.6946",
    Longitude: "31.7841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18227",
    WardLookupId: "2201",
    VDNumber: "54880285",
    RegisteredPopulation: "2401",
    VotingStationName: "LUGEDLANE PRIMARY SCHOOL",
    Address: "MANGWENI  SIBOSHWA TRIBAL",
    Latitude: "-25.7336",
    Longitude: "31.8105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18228",
    WardLookupId: "2201",
    VDNumber: "54880465",
    RegisteredPopulation: "974",
    VotingStationName: "MJEJANE HIGHER PRIMARY SCHOOL",
    Address: "MANGWENI  NKOMAZI [NKOMAZI]",
    Latitude: "-25.7297",
    Longitude: "31.8027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18229",
    WardLookupId: "2201",
    VDNumber: "54880533",
    RegisteredPopulation: "758",
    VotingStationName: "TONGA HOLINESS UNION CHURCH",
    Address: "TONGA  MALALANE",
    Latitude: "-25.6983",
    Longitude: "31.7879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18230",
    WardLookupId: "2202",
    VDNumber: "54880083",
    RegisteredPopulation: "1960",
    VotingStationName: "ZIBOKWANE PRIMARY SCHOOL",
    Address: "BLOCK A  KWALUGEDLANE  KWALUGEDLANE",
    Latitude: "-25.6883",
    Longitude: "31.7988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18231",
    WardLookupId: "2202",
    VDNumber: "54880544",
    RegisteredPopulation: "971",
    VotingStationName: "INDLANGEMABALA SECONDARY SCHOOL",
    Address: "BLOCK A  SIBOSHWA TRIBAL",
    Latitude: "-25.692",
    Longitude: "31.799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18232",
    WardLookupId: "2202",
    VDNumber: "54880689",
    RegisteredPopulation: "412",
    VotingStationName: "DD MABUZA COMPREHENSIVE HIGH SCHOOL",
    Address: "BLOCK A  NKOMAZI  SIBOSHWA TRIBAL",
    Latitude: "-25.6775",
    Longitude: "31.7988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18233",
    WardLookupId: "2202",
    VDNumber: "54890411",
    RegisteredPopulation: "1660",
    VotingStationName: "GEBHUZA HIGH SCHOOL",
    Address: "PHIVA  NKOMAZI  MALELANE",
    Latitude: "-25.6458",
    Longitude: "31.767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18234",
    WardLookupId: "2202",
    VDNumber: "54890422",
    RegisteredPopulation: "821",
    VotingStationName: "KWAJELUSA PRIMARY SCHOOL",
    Address: "PHIVA  ",
    Latitude: "-25.6455",
    Longitude: "31.7812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18235",
    WardLookupId: "2202",
    VDNumber: "54891052",
    RegisteredPopulation: "120",
    VotingStationName: "OLD TONGA COMMUNITY HALL",
    Address: "OLD TONGA VILLAGE  NKOMAZI  MATSAMO TRIBAL AUTHORITY",
    Latitude: "-25.6786",
    Longitude: "31.7807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18236",
    WardLookupId: "2203",
    VDNumber: "54880184",
    RegisteredPopulation: "843",
    VotingStationName: "MSHENGU INCLUSIVE SCHOOL",
    Address: "HOYI  NKOMAZI  HOYI TRIBAL",
    Latitude: "-25.7948",
    Longitude: "31.8562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18237",
    WardLookupId: "2203",
    VDNumber: "54880195",
    RegisteredPopulation: "1622",
    VotingStationName: "HOYI PRIMARY SCHOOL",
    Address: "HOYI  NKOMAZI  HOYI TRIBAL",
    Latitude: "-25.8149",
    Longitude: "31.8523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18238",
    WardLookupId: "2203",
    VDNumber: "54880409",
    RegisteredPopulation: "839",
    VotingStationName: "GOBA PRIMARY SCHOOL",
    Address: "GOBA  ",
    Latitude: "-25.8112",
    Longitude: "31.9164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18239",
    WardLookupId: "2203",
    VDNumber: "54880454",
    RegisteredPopulation: "932",
    VotingStationName: "HOYI JERUSALEM CHURCH",
    Address: "HOYI  NKOMAZI [NKOMAZI]",
    Latitude: "-25.8288",
    Longitude: "31.8501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18240",
    WardLookupId: "2203",
    VDNumber: "54880601",
    RegisteredPopulation: "610",
    VotingStationName: "CHRISTIAN FIRE CHURCH",
    Address: "HOYI  NKOMAZI  HOYI TRIBAL AUTHORITY",
    Latitude: "-25.8101",
    Longitude: "31.8568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18241",
    WardLookupId: "2204",
    VDNumber: "54880207",
    RegisteredPopulation: "1168",
    VotingStationName: "ETIMBONDVWENI PRIMARY SCHOOL",
    Address: "MANDULO  KOMATIPOORT",
    Latitude: "-25.8481",
    Longitude: "31.8503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18242",
    WardLookupId: "2204",
    VDNumber: "54880229",
    RegisteredPopulation: "1761",
    VotingStationName: "MBANGWANE PRIMARY SCHOOL",
    Address: "MBANGWANE  ",
    Latitude: "-25.8373",
    Longitude: "31.8908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18243",
    WardLookupId: "2204",
    VDNumber: "54880252",
    RegisteredPopulation: "834",
    VotingStationName: "TSAMBOKHULU PRIMARY SCHOOL",
    Address: "TSAMBOKHULU  ",
    Latitude: "-25.9479",
    Longitude: "31.9022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18244",
    WardLookupId: "2204",
    VDNumber: "54880364",
    RegisteredPopulation: "791",
    VotingStationName: "NSIZWANE PRIMARY SCHOOL",
    Address: "KHOMBASO  ",
    Latitude: "-25.883",
    Longitude: "31.88",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18245",
    WardLookupId: "2204",
    VDNumber: "54880375",
    RegisteredPopulation: "1030",
    VotingStationName: "LUTSANGO PRIMARY SCHOOL",
    Address: "MANANGA  ",
    Latitude: "-25.9578",
    Longitude: "31.8442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18246",
    WardLookupId: "2204",
    VDNumber: "54880702",
    RegisteredPopulation: "53",
    VotingStationName: "INTSABA TEMINCUMO PRAYER CENTRE",
    Address: "ENCABENI  MLAMBO TRIBAL AUTHORITY",
    Latitude: "-25.8569",
    Longitude: "31.837",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18247",
    WardLookupId: "2205",
    VDNumber: "54880173",
    RegisteredPopulation: "605",
    VotingStationName: "MBUDULA HIGH SCHOOL",
    Address: "MBUZINI  ",
    Latitude: "-25.925",
    Longitude: "31.9327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18248",
    WardLookupId: "2205",
    VDNumber: "54880263",
    RegisteredPopulation: "573",
    VotingStationName: "SAMORA MACHEL HIGH SCHOOL",
    Address: "MBUZINI  ",
    Latitude: "-25.9083",
    Longitude: "31.9171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18249",
    WardLookupId: "2205",
    VDNumber: "54880274",
    RegisteredPopulation: "861",
    VotingStationName: "KHULA-MLAMBO PRIMARY SCHOOL",
    Address: "MBUZINI  MLAMBO TRIBAL",
    Latitude: "-25.9104",
    Longitude: "31.9288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18250",
    WardLookupId: "2205",
    VDNumber: "54880397",
    RegisteredPopulation: "1566",
    VotingStationName: "MBUZINI PRIMARY SCHOOL",
    Address: "MBUZINI  ",
    Latitude: "-25.9355",
    Longitude: "31.9533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18251",
    WardLookupId: "2205",
    VDNumber: "54880432",
    RegisteredPopulation: "765",
    VotingStationName: "THEMBEKA PRE-SCHOOL",
    Address: "MBUZINI  MLAMBO TRIBAL AUTHORITY",
    Latitude: "-25.8942",
    Longitude: "31.9135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18252",
    WardLookupId: "2205",
    VDNumber: "54880443",
    RegisteredPopulation: "800",
    VotingStationName: "NDINDINDI PRIMARY SCHOOL",
    Address: "MBUZINI  NKOMAZI",
    Latitude: "-25.9294",
    Longitude: "31.9151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18253",
    WardLookupId: "2206",
    VDNumber: "54880230",
    RegisteredPopulation: "1538",
    VotingStationName: "SIZIMISELE PRIMARY SCHOOL",
    Address: "MASIBEKELA  MASIBEKLA  MASIBEKELA/KAHLAHLEYA",
    Latitude: "-25.8423",
    Longitude: "31.8218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18254",
    WardLookupId: "2206",
    VDNumber: "54880241",
    RegisteredPopulation: "1486",
    VotingStationName: "MC ZITHA PRIMARY SCHOOL",
    Address: "MASIBEKELA  MASIBEKLA  MASIBEKELA",
    Latitude: "-25.8646",
    Longitude: "31.8198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18255",
    WardLookupId: "2206",
    VDNumber: "54880386",
    RegisteredPopulation: "938",
    VotingStationName: "INJABULO PRIMARY SCHOOL",
    Address: "MASIBEKELA  ",
    Latitude: "-25.9093",
    Longitude: "31.7914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18256",
    WardLookupId: "2206",
    VDNumber: "54880599",
    RegisteredPopulation: "676",
    VotingStationName: "MASIBEKELA PRIMARY SCHOOL",
    Address: "MASIBEKELA  MLAMBO TRIBAL",
    Latitude: "-25.8816",
    Longitude: "31.8081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18257",
    WardLookupId: "2206",
    VDNumber: "54880612",
    RegisteredPopulation: "641",
    VotingStationName: "GOGO-MHLANGA PRIMARY SCHOOL",
    Address: "MASIBEKELA  MLAMBO TRIBAL",
    Latitude: "-25.8361",
    Longitude: "31.8217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18258",
    WardLookupId: "2207",
    VDNumber: "54890152",
    RegisteredPopulation: "1097",
    VotingStationName: "SOGASA PRIMARY SCHOOL",
    Address: "MGOBODZI  ",
    Latitude: "-25.8747",
    Longitude: "31.7069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18259",
    WardLookupId: "2207",
    VDNumber: "54890163",
    RegisteredPopulation: "1638",
    VotingStationName: "MAWEWE TRIBAL AUTHORITY OFFICE",
    Address: "MGOBODZI  ",
    Latitude: "-25.8659",
    Longitude: "31.7054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18260",
    WardLookupId: "2207",
    VDNumber: "54890499",
    RegisteredPopulation: "829",
    VotingStationName: "MKHONJWASE CRECHE (MGOBODZI)",
    Address: "MGOBODZI  ",
    Latitude: "-25.8427",
    Longitude: "31.689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18261",
    WardLookupId: "2207",
    VDNumber: "54890501",
    RegisteredPopulation: "813",
    VotingStationName: "MANZOLWANDLE HIGH SCHOOL",
    Address: "MGOBODZI  MAWEWE TRIBAL",
    Latitude: "-25.8545",
    Longitude: "31.7019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18262",
    WardLookupId: "2207",
    VDNumber: "54890668",
    RegisteredPopulation: "783",
    VotingStationName: "MAGUDU PRIMARY SCHOOL",
    Address: "MAGUDU  ",
    Latitude: "-25.8915",
    Longitude: "31.72",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18263",
    WardLookupId: "2207",
    VDNumber: "54890938",
    RegisteredPopulation: "379",
    VotingStationName: "MAWEWE PRIMARY SCHOOL",
    Address: "MGOBODI  MAWEWE TRIBAL",
    Latitude: "-25.8671",
    Longitude: "31.6953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18264",
    WardLookupId: "2208",
    VDNumber: "54890455",
    RegisteredPopulation: "1733",
    VotingStationName: "SIHLANGU PRIMARY SCHOOL",
    Address: "MADADENI  ",
    Latitude: "-25.81",
    Longitude: "31.7864",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18265",
    WardLookupId: "2208",
    VDNumber: "54890466",
    RegisteredPopulation: "895",
    VotingStationName: "DLAMADODA HIGH SCHOOL",
    Address: "MADADENI  ",
    Latitude: "-25.8224",
    Longitude: "31.7888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18266",
    WardLookupId: "2208",
    VDNumber: "54890477",
    RegisteredPopulation: "1328",
    VotingStationName: "SHAYAZA PRIMARY SCHOOL",
    Address: "SIBANGE  ",
    Latitude: "-25.8634",
    Longitude: "31.7935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18267",
    WardLookupId: "2208",
    VDNumber: "54890691",
    RegisteredPopulation: "1105",
    VotingStationName: "MAGUDU TRIBAL AUTHORITY",
    Address: "MAGUDU  MAWEWE TRIBAL",
    Latitude: "-25.8929",
    Longitude: "31.7256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18268",
    WardLookupId: "2208",
    VDNumber: "54890871",
    RegisteredPopulation: "385",
    VotingStationName: "SIYANAKEKELA EDUCARE CENTRE",
    Address: "SIBANGE  NKOMAZI  MAWEWE TRIBAL",
    Latitude: "-25.8428",
    Longitude: "31.7887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18269",
    WardLookupId: "2209",
    VDNumber: "54880139",
    RegisteredPopulation: "2307",
    VotingStationName: "PHAMBANISA PRIMARY SCHOOL",
    Address: "MANGWENI  ",
    Latitude: "-25.7509",
    Longitude: "31.8195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18270",
    WardLookupId: "2209",
    VDNumber: "54880162",
    RegisteredPopulation: "1919",
    VotingStationName: "MPHOTI PRIMARY SCHOOL",
    Address: "MANGWENI  ",
    Latitude: "-25.7555",
    Longitude: "31.8309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18271",
    WardLookupId: "2209",
    VDNumber: "54880522",
    RegisteredPopulation: "519",
    VotingStationName: "KINGDOM OF GOD CATHOLIC CHURCH",
    Address: "MP324 - NKOMAZI [NKOMAZI]  ",
    Latitude: "-25.7629",
    Longitude: "31.8308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18272",
    WardLookupId: "2209",
    VDNumber: "54880588",
    RegisteredPopulation: "548",
    VotingStationName: "THE SALVATION ARMY (MANGWENI)",
    Address: "MANGWENI  LUGEDLANE TRIBAL",
    Latitude: "-25.7472",
    Longitude: "31.828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18273",
    WardLookupId: "2209",
    VDNumber: "54880623",
    RegisteredPopulation: "423",
    VotingStationName: "LATTERRAIN CHURCH",
    Address: "MANGWENI  LUGEDLANE TRIBAL",
    Latitude: "-25.7546",
    Longitude: "31.823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18274",
    WardLookupId: "2210",
    VDNumber: "54890174",
    RegisteredPopulation: "1198",
    VotingStationName: "MAGOGENI PRIMARY SCHOOL",
    Address: "MAGOGENI  MGOGENI  MAGOGENI",
    Latitude: "-25.7997",
    Longitude: "31.6076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18275",
    WardLookupId: "2210",
    VDNumber: "54890309",
    RegisteredPopulation: "1263",
    VotingStationName: "MHLABA PRIMARY SCHOOL",
    Address: "BOSCHFONTEIN  ",
    Latitude: "-25.7605",
    Longitude: "31.6371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18276",
    WardLookupId: "2210",
    VDNumber: "54890310",
    RegisteredPopulation: "1601",
    VotingStationName: "SOSHANGANE HIGH SCHOOL",
    Address: "MAGOGENI  ",
    Latitude: "-25.7888",
    Longitude: "31.6156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18277",
    WardLookupId: "2210",
    VDNumber: "54890321",
    RegisteredPopulation: "1096",
    VotingStationName: "MHLABA TRIBAL OFFICES",
    Address: "MAGOGENI TRUST  MAGOGENI  MAGOGENI",
    Latitude: "-25.7943",
    Longitude: "31.599",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18278",
    WardLookupId: "2211",
    VDNumber: "54890398",
    RegisteredPopulation: "1527",
    VotingStationName: "MZINTI COMMUNITY HALL",
    Address: "MZINTI  ",
    Latitude: "-25.699",
    Longitude: "31.7427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18279",
    WardLookupId: "2211",
    VDNumber: "54890433",
    RegisteredPopulation: "1349",
    VotingStationName: "KWALODAKADA PRIMARY SCHOOL",
    Address: "NTUNDA  ",
    Latitude: "-25.7311",
    Longitude: "31.7569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18280",
    WardLookupId: "2211",
    VDNumber: "54890444",
    RegisteredPopulation: "835",
    VotingStationName: "PHINDELA PRIMARY SCHOOL",
    Address: "SIKHWAHLANE  ",
    Latitude: "-25.7643",
    Longitude: "31.7737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18281",
    WardLookupId: "2211",
    VDNumber: "54890703",
    RegisteredPopulation: "192",
    VotingStationName: "MAKECIA EDUCARE CENTRE",
    Address: "MZINTI VILLAGE  MZINTI  MATSAMO TRIBAL",
    Latitude: "-25.6901",
    Longitude: "31.7277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18282",
    WardLookupId: "2211",
    VDNumber: "54890736",
    RegisteredPopulation: "492",
    VotingStationName: "NJ MAHLANGU SECONDARY SCHOOL",
    Address: "SIKHWAHLANE  ",
    Latitude: "-25.7739",
    Longitude: "31.7815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18283",
    WardLookupId: "2211",
    VDNumber: "54890949",
    RegisteredPopulation: "370",
    VotingStationName: "SIKHWAHLANE HIGH SCHOOL",
    Address: "NTUNDA  MALELANE",
    Latitude: "-25.728",
    Longitude: "31.7616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18284",
    WardLookupId: "2211",
    VDNumber: "54890950",
    RegisteredPopulation: "362",
    VotingStationName: "KOBI PRIMARY SCHOOL",
    Address: "MZINTI  MALELANE",
    Latitude: "-25.7023",
    Longitude: "31.7493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18285",
    WardLookupId: "2212",
    VDNumber: "54890376",
    RegisteredPopulation: "1335",
    VotingStationName: "LIGUGULETFU PRIMARY SCHOOL",
    Address: "VLAKBULT  ",
    Latitude: "-25.6345",
    Longitude: "31.7127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18286",
    WardLookupId: "2212",
    VDNumber: "54890400",
    RegisteredPopulation: "1531",
    VotingStationName: "TIKHUNI HIGH SCHOOL",
    Address: "MZINTI  NKOMAZI MUNICIPALITY",
    Latitude: "-25.6947",
    Longitude: "31.7601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18287",
    WardLookupId: "2212",
    VDNumber: "54890837",
    RegisteredPopulation: "1017",
    VotingStationName: "MAGCEKENI PRIMARY SCHOOL",
    Address: "MZINTI  NKOMAZI [NKOMAZI]",
    Latitude: "-25.689",
    Longitude: "31.7629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18288",
    WardLookupId: "2212",
    VDNumber: "54890882",
    RegisteredPopulation: "1055",
    VotingStationName: "LIVING ROCK CHURCH",
    Address: "MP324 - NKOMAZI [NKOMAZI]  ",
    Latitude: "-25.6877",
    Longitude: "31.7706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18289",
    WardLookupId: "2212",
    VDNumber: "54891074",
    RegisteredPopulation: "132",
    VotingStationName: "TWILIGHT PRE SCHOOL OF EXCELLENCE",
    Address: "MZINTI  NKOMAZI  MATSAMO TRIBAL AUTHORITY",
    Latitude: "-25.6788",
    Longitude: "31.7245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18290",
    WardLookupId: "2213",
    VDNumber: "54890624",
    RegisteredPopulation: "1508",
    VotingStationName: "BANTFWABETFU PRE-SCHOOL",
    Address: "PHOSAVILLE  KAMHLUSHWA",
    Latitude: "-25.6526",
    Longitude: "31.682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18291",
    WardLookupId: "2213",
    VDNumber: "54890815",
    RegisteredPopulation: "1374",
    VotingStationName: "HOLINESS UNION CHURCH",
    Address: "PHOSAVILLE  KAMHLUSHWA  SHONGWE MISSION",
    Latitude: "-25.6446",
    Longitude: "31.681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18292",
    WardLookupId: "2213",
    VDNumber: "54890826",
    RegisteredPopulation: "1557",
    VotingStationName: "MTIMANDZE SECONDARY SCHOOL",
    Address: "DUNUSA  KAMHLUSHWA  KAMHLUSHWA",
    Latitude: "-25.6541",
    Longitude: "31.6918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18293",
    WardLookupId: "2213",
    VDNumber: "54890961",
    RegisteredPopulation: "476",
    VotingStationName: "HOECHST PRIMARY SCHOOL",
    Address: "PHOSAVILLE  KAMHLUSHWA  MALALANE",
    Latitude: "-25.6504",
    Longitude: "31.6811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18294",
    WardLookupId: "2213",
    VDNumber: "54890972",
    RegisteredPopulation: "804",
    VotingStationName: "THE GRACE CHURCH",
    Address: "DUNUSA   KAMHLUSHWA  MALALANE",
    Latitude: "-25.6409",
    Longitude: "31.6848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18295",
    WardLookupId: "2213",
    VDNumber: "54891030",
    RegisteredPopulation: "208",
    VotingStationName: "GOOD SHEPHERD CHRISTIAN FAMILY CHURCH",
    Address: "PHOSAVILLE  NKOMAZI  MATSAMO TRIBAL",
    Latitude: "-25.6395",
    Longitude: "31.6792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18296",
    WardLookupId: "2214",
    VDNumber: "54890657",
    RegisteredPopulation: "1995",
    VotingStationName: "KAMHLUSHWA COMMUNITY HALL",
    Address: "KAMHLUSHWA  SHONGWE",
    Latitude: "-25.6648",
    Longitude: "31.6814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18297",
    WardLookupId: "2214",
    VDNumber: "54890680",
    RegisteredPopulation: "1043",
    VotingStationName: "SIDLAMAFA HIGH SCHOOL",
    Address: "KAMHLUSHWA  ",
    Latitude: "-25.6606",
    Longitude: "31.6843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18298",
    WardLookupId: "2214",
    VDNumber: "54890747",
    RegisteredPopulation: "1241",
    VotingStationName: "MLUMATI TECHNICAL COLLEGE",
    Address: "KAMHLUSHWA  ",
    Latitude: "-25.6685",
    Longitude: "31.6909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18299",
    WardLookupId: "2214",
    VDNumber: "54890983",
    RegisteredPopulation: "386",
    VotingStationName: "LUVOLWETFU PRIMARY SCHOOL",
    Address: "KAMHLUSHWA  MALALANE",
    Latitude: "-25.6662",
    Longitude: "31.6791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18300",
    WardLookupId: "2214",
    VDNumber: "54891007",
    RegisteredPopulation: "179",
    VotingStationName: "MAHUSHE SECONDARY SCHOOL",
    Address: "MZINTI  NKOMAZI  MATSAMO",
    Latitude: "-25.69",
    Longitude: "31.7237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18301",
    WardLookupId: "2215",
    VDNumber: "54890185",
    RegisteredPopulation: "645",
    VotingStationName: "NDLEMANE PRIMARY SCHOOL",
    Address: "BOSCHFONTEIN  BOSHFONTEIN  BOSCHFONTEIN",
    Latitude: "-25.7415",
    Longitude: "31.6083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18302",
    WardLookupId: "2215",
    VDNumber: "54890196",
    RegisteredPopulation: "1208",
    VotingStationName: "ZWIDE PRIMARY SCHOOL",
    Address: "BOSCHFONTEIN  ",
    Latitude: "-25.7446",
    Longitude: "31.6256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18303",
    WardLookupId: "2215",
    VDNumber: "54890286",
    RegisteredPopulation: "466",
    VotingStationName: "MBAMBISO SECONDARY SCHOOL",
    Address: "BOSCHFONTEIN  NKOMAZI  MHLABA TRIBAL AUTHORITY",
    Latitude: "-25.744",
    Longitude: "31.6056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18304",
    WardLookupId: "2215",
    VDNumber: "54890297",
    RegisteredPopulation: "597",
    VotingStationName: "BOSCHFONTEIN HOLINESS UNION CHURCH",
    Address: "BOSCHFONTEIN  KAMBAMBISO  MALELANE",
    Latitude: "-25.747",
    Longitude: "31.6218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18305",
    WardLookupId: "2215",
    VDNumber: "54890758",
    RegisteredPopulation: "879",
    VotingStationName: "NEW GENERATION CHURCH",
    Address: "BOSCHFONTEIN  KAMBAMBISO  MHLABA TRIBAL",
    Latitude: "-25.7437",
    Longitude: "31.6331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18306",
    WardLookupId: "2215",
    VDNumber: "54891018",
    RegisteredPopulation: "134",
    VotingStationName: "TRUST IN CHRIST CHURCH(ANIVA)",
    Address: "ANIVA  NKOMAZI  MATSAMO TRIBAL AUTHORITY",
    Latitude: "-25.7267",
    Longitude: "31.6106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18307",
    WardLookupId: "2215",
    VDNumber: "54891029",
    RegisteredPopulation: "145",
    VotingStationName: "SWAZI CHRISTIAN CHURCH",
    Address: "BOSCHFONTEIN  NKOMAZI  MHLABA TRIBAL AUTHORITY",
    Latitude: "-25.7553",
    Longitude: "31.6351",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18308",
    WardLookupId: "2216",
    VDNumber: "54890208",
    RegisteredPopulation: "1826",
    VotingStationName: "PHAKAMANI PRIMARY SCHOOL",
    Address: "DRIEKOPPIES BONGANI  DRIEKOPPIES  DRIEKOPPIES",
    Latitude: "-25.7124",
    Longitude: "31.5644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18309",
    WardLookupId: "2216",
    VDNumber: "54890332",
    RegisteredPopulation: "1895",
    VotingStationName: "BUKHOSIBETFU PRIMARY SCHOOL",
    Address: "DRIEKOPPIES  ",
    Latitude: "-25.6993",
    Longitude: "31.5748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18310",
    WardLookupId: "2216",
    VDNumber: "54890859",
    RegisteredPopulation: "1012",
    VotingStationName: "ANIVA HOLINESS UNION CHURCH",
    Address: "MP324 - NKOMAZI [NKOMAZI]   ",
    Latitude: "-25.7176",
    Longitude: "31.6127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18311",
    WardLookupId: "2216",
    VDNumber: "54890994",
    RegisteredPopulation: "553",
    VotingStationName: "BONGANI HOLINESS UNION CHURCH",
    Address: "BONGANI  NKOMAZI  MATSAMO TRIBAL AUTHORITY",
    Latitude: "-25.7036",
    Longitude: "31.5646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18312",
    WardLookupId: "2217",
    VDNumber: "54890253",
    RegisteredPopulation: "1143",
    VotingStationName: "LOVUNYWA HIGH SCHOOL",
    Address: "LANGELOOP  ",
    Latitude: "-25.6883",
    Longitude: "31.6359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18313",
    WardLookupId: "2217",
    VDNumber: "54890264",
    RegisteredPopulation: "1520",
    VotingStationName: "MTFUNTINI PRIMARY SCHOOL",
    Address: "LANGELOOP  NKOMAZI  MATSAMO TRIBAL AUTHORITY",
    Latitude: "-25.6813",
    Longitude: "31.6452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18314",
    WardLookupId: "2217",
    VDNumber: "54890275",
    RegisteredPopulation: "1335",
    VotingStationName: "LOMATIDRAAI PRIMARY SCHOOL",
    Address: "LANGELOOP  ",
    Latitude: "-25.6764",
    Longitude: "31.6331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18315",
    WardLookupId: "2217",
    VDNumber: "54890365",
    RegisteredPopulation: "747",
    VotingStationName: "INTFUTFUKO PRIMARY SCHOOL",
    Address: "LANGELOOP  ",
    Latitude: "-25.6885",
    Longitude: "31.6263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18316",
    WardLookupId: "2217",
    VDNumber: "54890860",
    RegisteredPopulation: "582",
    VotingStationName: "LANGELOOP HOLINESS UNION CHURCH",
    Address: "MP324 - NKOMAZI [NKOMAZI]  ",
    Latitude: "-25.6995",
    Longitude: "31.629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18317",
    WardLookupId: "2218",
    VDNumber: "54890118",
    RegisteredPopulation: "882",
    VotingStationName: "DRIEKOPPIES COMMUNITY HALL",
    Address: "DRIEKOPPIES A  DRIEKOPPIES  MATSAMO TRIBAL",
    Latitude: "-25.6946",
    Longitude: "31.5493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18318",
    WardLookupId: "2218",
    VDNumber: "54890231",
    RegisteredPopulation: "775",
    VotingStationName: "CHRIST REEDEMPTION(ITSHE LEGUMBI)",
    Address: "MBOKODWENI STADIUM  DRIEKOPPIES  DRIEKOPPIES",
    Latitude: "-25.6999",
    Longitude: "31.5592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18319",
    WardLookupId: "2218",
    VDNumber: "54890343",
    RegisteredPopulation: "299",
    VotingStationName: "DRIEKOPPIES COMBINED SCHOOL",
    Address: "DRIEKOPPIES  NKOMAZI [NKOMAZI]",
    Latitude: "-25.69",
    Longitude: "31.5547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18320",
    WardLookupId: "2218",
    VDNumber: "54890354",
    RegisteredPopulation: "457",
    VotingStationName: "DRIEKOPPIES HOLINESS UNION CHURCH",
    Address: "DRIEKOPPIES  ",
    Latitude: "-25.6954",
    Longitude: "31.5561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18321",
    WardLookupId: "2218",
    VDNumber: "54890646",
    RegisteredPopulation: "1106",
    VotingStationName: "SIFUNDZEKHAYA PRIMARY SCHOOL",
    Address: "DRIEKOPPIES  SHONGWE",
    Latitude: "-25.7016",
    Longitude: "31.5548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18322",
    WardLookupId: "2218",
    VDNumber: "54890769",
    RegisteredPopulation: "616",
    VotingStationName: "DRIEKOPPIES ASSEMBLIES OF GOD",
    Address: "DRIEKOPPIES  NKOMAZI  MATSAMO TRIBAL AUTHORITY",
    Latitude: "-25.6954",
    Longitude: "31.5636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18323",
    WardLookupId: "2219",
    VDNumber: "54890220",
    RegisteredPopulation: "1793",
    VotingStationName: "BONGOKUHLE PRIMARY SCHOOL",
    Address: "SCHOEMANSDAL  ",
    Latitude: "-25.6919",
    Longitude: "31.5042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18324",
    WardLookupId: "2219",
    VDNumber: "54890578",
    RegisteredPopulation: "996",
    VotingStationName: "SCHOEMANSDAL PRIMARY SCHOOL",
    Address: "SCHOEMANSDAL  NKOMAZI  MATSAMO TRIBAL AUTHORITY",
    Latitude: "-25.7006",
    Longitude: "31.5003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18325",
    WardLookupId: "2219",
    VDNumber: "54890635",
    RegisteredPopulation: "1423",
    VotingStationName: "FAIRVIEW ACADEMY",
    Address: "SCHOEMANSDAL  NKOMAZI  MATSAMO TRIBAL AUTHORITY",
    Latitude: "-25.6872",
    Longitude: "31.4962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18326",
    WardLookupId: "2219",
    VDNumber: "54890905",
    RegisteredPopulation: "455",
    VotingStationName: "GOD`S FIRE CHRISTIAN CENTRE",
    Address: "SCHOEMANSDAL  MATSAMO TRIBAL AUTHORITY",
    Latitude: "-25.6731",
    Longitude: "31.5055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18327",
    WardLookupId: "2219",
    VDNumber: "54891063",
    RegisteredPopulation: "245",
    VotingStationName: "GOD IS GREAT CHRISTIAN FELLOWSHIP",
    Address: "SCHOEMANSDAL (KHULUBUSA)  NKOMAZI  MATSAMO TRIBAL",
    Latitude: "-25.6999",
    Longitude: "31.4851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18328",
    WardLookupId: "2220",
    VDNumber: "54890556",
    RegisteredPopulation: "897",
    VotingStationName: "ZITHULELE PRIMARY SCHOOL",
    Address: "SCHOEMANSDAL  ",
    Latitude: "-25.714",
    Longitude: "31.5111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18329",
    WardLookupId: "2220",
    VDNumber: "54890567",
    RegisteredPopulation: "1490",
    VotingStationName: "MATSAMO TRIBAL AUTHORITY",
    Address: "SCHOEMANSDAL  ",
    Latitude: "-25.7038",
    Longitude: "31.4998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18330",
    WardLookupId: "2220",
    VDNumber: "54890792",
    RegisteredPopulation: "1813",
    VotingStationName: "SABATHA PRIMARY SCHOOL",
    Address: "JEPPES REEF  ",
    Latitude: "-25.7196",
    Longitude: "31.4866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18331",
    WardLookupId: "2220",
    VDNumber: "54891085",
    RegisteredPopulation: "185",
    VotingStationName: "ZOE EDUCARE CENTRE",
    Address: "ZONE 10  NKOMAZI  MATSAMO TRIBAL AUTHORITY",
    Latitude: "-25.7066",
    Longitude: "31.482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18332",
    WardLookupId: "2221",
    VDNumber: "54770036",
    RegisteredPopulation: "836",
    VotingStationName: "HECTORSPRUIT COMMUNITY HALL",
    Address: "HECTORSPRUIT  ",
    Latitude: "-25.44",
    Longitude: "31.6846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18333",
    WardLookupId: "2221",
    VDNumber: "54890073",
    RegisteredPopulation: "339",
    VotingStationName: "MOBILE VOTING STATION (RICHTERSHOEK PRIMARY HEALTH",
    Address: "RITCHERSHOEK  MALELANE  NKOMAZI",
    Latitude: "-25.6417",
    Longitude: "31.612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18334",
    WardLookupId: "2221",
    VDNumber: "54890130",
    RegisteredPopulation: "758",
    VotingStationName: "LOTI PRIMARY SCHOOL",
    Address: "BUFFELSPRUIT  ",
    Latitude: "-25.6592",
    Longitude: "31.5237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18335",
    WardLookupId: "2221",
    VDNumber: "54890141",
    RegisteredPopulation: "1057",
    VotingStationName: "ZANDILE PRIMARY SCHOOL",
    Address: "BUFFELSPRUIT  SHONGWE MISSION",
    Latitude: "-25.658",
    Longitude: "31.5224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18336",
    WardLookupId: "2221",
    VDNumber: "54890613",
    RegisteredPopulation: "894",
    VotingStationName: "THANDA PRIMARY SCHOOL",
    Address: "DINDELA  NKOMAZI  HECTORSPRUIT",
    Latitude: "-25.4393",
    Longitude: "31.6573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18337",
    WardLookupId: "2221",
    VDNumber: "54890804",
    RegisteredPopulation: "884",
    VotingStationName: "BUFFELSPRUIT EMMANUEL FULL GOSPEL CHURCH",
    Address: "BUFFELSPRUIT  NKOMAZI  NKOMAZI",
    Latitude: "-25.6543",
    Longitude: "31.5153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18338",
    WardLookupId: "2221",
    VDNumber: "54890893",
    RegisteredPopulation: "760",
    VotingStationName: "NDLELA HIGH SCHOOL",
    Address: "BUFFELSPRUIT  SHONGWE MISSION  MALELANE",
    Latitude: "-25.6593",
    Longitude: "31.5073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18339",
    WardLookupId: "2221",
    VDNumber: "54891041",
    RegisteredPopulation: "339",
    VotingStationName: "EVANGELICAL CHURCH",
    Address: "MJEJANE  NKOMAZI  HECTORSPRUIT",
    Latitude: "-25.4434",
    Longitude: "31.7087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18340",
    WardLookupId: "2222",
    VDNumber: "54770014",
    RegisteredPopulation: "1465",
    VotingStationName: "LAERSKOOL MALELANE",
    Address: "MARULA STREET  MALELANE  MALELANE",
    Latitude: "-25.4931",
    Longitude: "31.5074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18341",
    WardLookupId: "2222",
    VDNumber: "54770058",
    RegisteredPopulation: "233",
    VotingStationName: "SIYATHUTHUKA COMMUNITY HALL",
    Address: "SIYATHUTHUKA  KAMKWARUKHWARU  MALALANE",
    Latitude: "-25.5041",
    Longitude: "31.4711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18342",
    WardLookupId: "2222",
    VDNumber: "54770069",
    RegisteredPopulation: "489",
    VotingStationName: "KOBWA COMMUNITY- HALL",
    Address: "VISAREND STREET  NKOMAZI  MALELANE",
    Latitude: "-25.4889",
    Longitude: "31.5002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18343",
    WardLookupId: "2222",
    VDNumber: "54890040",
    RegisteredPopulation: "447",
    VotingStationName: "SUIKERLAND SECONDARY SCHOOL",
    Address: "MHLATHI  NKOMAZI  MALALANE",
    Latitude: "-25.487",
    Longitude: "31.5146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18344",
    WardLookupId: "2222",
    VDNumber: "54890084",
    RegisteredPopulation: "723",
    VotingStationName: "KAPMUIDEN PRIMARY SCHOOL",
    Address: "KAPMUIDEN STATION  KAAPMUIDEN STATION  KAAPMUIDEN STATION",
    Latitude: "-25.5378",
    Longitude: "31.3324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18345",
    WardLookupId: "2222",
    VDNumber: "54890679",
    RegisteredPopulation: "1561",
    VotingStationName: "LOUIVILLE HALL",
    Address: "LOUIVILLE  LOUIVILLE CREEK  LOUIVILLE",
    Latitude: "-25.6721",
    Longitude: "31.2874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18346",
    WardLookupId: "2223",
    VDNumber: "54890017",
    RegisteredPopulation: "1255",
    VotingStationName: "MPUMELELO PRIMARY SCHOOL",
    Address: "MIDDELPLAAS  ",
    Latitude: "-25.7175",
    Longitude: "31.5525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18347",
    WardLookupId: "2223",
    VDNumber: "54890051",
    RegisteredPopulation: "748",
    VotingStationName: "CAMALAZA SECONDARY SCHOOL",
    Address: "MIDDELPLAAS  NKOMAZI  MATSAMO TRIBAL AUTHORITY",
    Latitude: "-25.7238",
    Longitude: "31.5555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18348",
    WardLookupId: "2223",
    VDNumber: "54890242",
    RegisteredPopulation: "1297",
    VotingStationName: "SCHULZENDAL PRIMARY SCHOOL",
    Address: "SCHULZENDAL  ",
    Latitude: "-25.7519",
    Longitude: "31.5378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18349",
    WardLookupId: "2223",
    VDNumber: "54890770",
    RegisteredPopulation: "805",
    VotingStationName: "MDZILI SECONDARY SCHOOL",
    Address: "SCHULZENDAL  MATSAMO TRIBAL",
    Latitude: "-25.7469",
    Longitude: "31.5507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18350",
    WardLookupId: "2224",
    VDNumber: "54890512",
    RegisteredPopulation: "1355",
    VotingStationName: "BUYANI PRIMARY SCHOOL",
    Address: "JEPPES REEF  ",
    Latitude: "-25.73",
    Longitude: "31.4727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18351",
    WardLookupId: "2224",
    VDNumber: "54890523",
    RegisteredPopulation: "1057",
    VotingStationName: "CHRISTIAN FAITH CHURCH",
    Address: "JEPPES REEF  SHONGWE MISSION  MATSAMO TRIBAL",
    Latitude: "-25.723",
    Longitude: "31.4827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18352",
    WardLookupId: "2224",
    VDNumber: "54890534",
    RegisteredPopulation: "1726",
    VotingStationName: "SISINI PRIMARY SCHOOL",
    Address: "JEPPES REEF  ",
    Latitude: "-25.7163",
    Longitude: "31.4709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18353",
    WardLookupId: "2224",
    VDNumber: "54890916",
    RegisteredPopulation: "305",
    VotingStationName: "JEPPES REEF HOLINESS UNION CHURCH",
    Address: "JEPPES REEF  MATSAMO TRIBAL",
    Latitude: "-25.7283",
    Longitude: "31.4685",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18354",
    WardLookupId: "2224",
    VDNumber: "54891096",
    RegisteredPopulation: "67",
    VotingStationName: "TINHLONHLA SECONDARY SCHOOL",
    Address: "JEPPES REEF  NKOMAZI  MATSAMO TRIBAL AUTHORITY",
    Latitude: "-25.7357",
    Longitude: "31.4811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18355",
    WardLookupId: "2225",
    VDNumber: "54890219",
    RegisteredPopulation: "1200",
    VotingStationName: "MAHLATSI HIGH SCHOOL",
    Address: "BUFFELSPRUIT  ",
    Latitude: "-25.6737",
    Longitude: "31.5157",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18356",
    WardLookupId: "2225",
    VDNumber: "54890589",
    RegisteredPopulation: "952",
    VotingStationName: "ENTOKOZWENI EDU-CARE",
    Address: "SCHOEMANSDAL  NKOMAZI  MATSAMO TRIBAL AUTHORITY",
    Latitude: "-25.7025",
    Longitude: "31.5126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18357",
    WardLookupId: "2225",
    VDNumber: "54890590",
    RegisteredPopulation: "1219",
    VotingStationName: "NJEYEZA HIGH SCHOOL",
    Address: "SCHOEMANSDAL  ",
    Latitude: "-25.6897",
    Longitude: "31.5098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18358",
    WardLookupId: "2225",
    VDNumber: "54890781",
    RegisteredPopulation: "1359",
    VotingStationName: "EKUPHUMULENI PRIMARY SCHOOL",
    Address: "EKUPHUMULENI  SCHOEMANSDAL",
    Latitude: "-25.6819",
    Longitude: "31.5143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18359",
    WardLookupId: "2226",
    VDNumber: "76170089",
    RegisteredPopulation: "2388",
    VotingStationName: "NJANJI PRIMARY SCHOOL",
    Address:
      "12 MKHUHLU TOWNSHIP  BUSHBUCKRIDGE  BUSHBUCKRIDGE [BUSHBUCKRIDGE]",
    Latitude: "-24.9883",
    Longitude: "31.24",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18360",
    WardLookupId: "2226",
    VDNumber: "76170281",
    RegisteredPopulation: "796",
    VotingStationName: "PHAPHAMA HIGHER PRIMARY SCHOOL",
    Address: "GOROMANE  HAZYVIEW",
    Latitude: "-25.0105",
    Longitude: "31.187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18361",
    WardLookupId: "2226",
    VDNumber: "76170438",
    RegisteredPopulation: "614",
    VotingStationName: "GEZINGQONDO SENIOR SECONDARY SCHOOL",
    Address: "GOROMANE  MKHUHLU",
    Latitude: "-25.0183",
    Longitude: "31.1712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18362",
    WardLookupId: "2226",
    VDNumber: "76170506",
    RegisteredPopulation: "1223",
    VotingStationName: "MADZUMA SENIOR SECONDARY SCHOOL",
    Address: "CALCUTTA A  MKHUHLU",
    Latitude: "-24.9734",
    Longitude: "31.1955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18363",
    WardLookupId: "2226",
    VDNumber: "76170742",
    RegisteredPopulation: "1958",
    VotingStationName: "NTSHUNXEKO COMBINED SCHOOL",
    Address: "MALUVANE ST  MKHULU  MKHUHLU TOWNSHIP",
    Latitude: "-24.9897",
    Longitude: "31.2293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18364",
    WardLookupId: "2226",
    VDNumber: "76170764",
    RegisteredPopulation: "328",
    VotingStationName: "HOXANI COLLEGE",
    Address: "MKHUHLU F  EHLANZENI  MKHUHLU KA MALUVANE",
    Latitude: "-25.0063",
    Longitude: "31.2161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18365",
    WardLookupId: "2227",
    VDNumber: "76170067",
    RegisteredPopulation: "596",
    VotingStationName: "MKHUKHUMBA SENIOR SECONDARY SCHOOL",
    Address: "MADRAS  MKHUHLU",
    Latitude: "-24.9874",
    Longitude: "31.1626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18366",
    WardLookupId: "2227",
    VDNumber: "76170078",
    RegisteredPopulation: "329",
    VotingStationName: "MATIKWANA HIGHER PRIMARY SCHOOL",
    Address: "MADRAS  HAZYVIEW",
    Latitude: "-24.9976",
    Longitude: "31.1692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18367",
    WardLookupId: "2227",
    VDNumber: "76170157",
    RegisteredPopulation: "1625",
    VotingStationName: "SELE HIGHER PRIMARY SCHOOL",
    Address: "TEKAMAHALA  MKHUHLU",
    Latitude: "-24.9585",
    Longitude: "31.2034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18368",
    WardLookupId: "2227",
    VDNumber: "76170180",
    RegisteredPopulation: "1008",
    VotingStationName: "RINDZANI JUNIOR SECONDARY SCHOOL",
    Address: "MADRAS  MKHUHLU",
    Latitude: "-24.9885",
    Longitude: "31.1431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18369",
    WardLookupId: "2227",
    VDNumber: "76170236",
    RegisteredPopulation: "964",
    VotingStationName: "HLULANI HIGHER PRIMARY SCHOOL",
    Address: "MADRAS  MKHUHLU",
    Latitude: "-24.9898",
    Longitude: "31.1493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18370",
    WardLookupId: "2227",
    VDNumber: "76170315",
    RegisteredPopulation: "869",
    VotingStationName: "NTSHWENYANA PRIMARY SCHOOL",
    Address: "OAKLEY B  OAKLEY  MKHUHLU",
    Latitude: "-24.946",
    Longitude: "31.1999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18371",
    WardLookupId: "2227",
    VDNumber: "76170629",
    RegisteredPopulation: "409",
    VotingStationName: "MAVIMBELA PRIMARY SCHOOL",
    Address: "HOXANI ROAD  EHLANZENI - MKHUHLU  MKHUHLU",
    Latitude: "-24.9682",
    Longitude: "31.2153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18372",
    WardLookupId: "2228",
    VDNumber: "76170168",
    RegisteredPopulation: "2515",
    VotingStationName: "MBATINI LOWER PRIMARY SCHOOL",
    Address: "CALCUTTA C  MKHUHLU",
    Latitude: "-24.9642",
    Longitude: "31.2494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18373",
    WardLookupId: "2228",
    VDNumber: "76170258",
    RegisteredPopulation: "2508",
    VotingStationName: "HOXANI TRIBAL CENTRE",
    Address: "CALCUTTA A  MKHUHLU",
    Latitude: "-24.9713",
    Longitude: "31.2191",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18374",
    WardLookupId: "2228",
    VDNumber: "76170562",
    RegisteredPopulation: "1492",
    VotingStationName: "MKHUHLU COMMUNITY HALL",
    Address: "MKHUHLU TOWNSHIP  BUSHBUCKRIDGE  BUSHBUCKRIDGE [BUSHBUCKRIDGE]",
    Latitude: "-24.9808",
    Longitude: "31.2347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18375",
    WardLookupId: "2228",
    VDNumber: "76170584",
    RegisteredPopulation: "874",
    VotingStationName: "MAPETEKWANE PRIMARY SCHOOL",
    Address: "MP325 - BUSHBUCKRIDGE [BUSHBUCKRIDGE]  ",
    Latitude: "-24.9645",
    Longitude: "31.226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18376",
    WardLookupId: "2228",
    VDNumber: "76170630",
    RegisteredPopulation: "307",
    VotingStationName: "MAKHOSANA HIGH SCHOOL",
    Address: "SANP ROAD  EHLANZENI - MKHUHLU  MKHUHLU",
    Latitude: "-24.959",
    Longitude: "31.2581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18377",
    WardLookupId: "2229",
    VDNumber: "76170337",
    RegisteredPopulation: "1202",
    VotingStationName: "KWANANG HIGHER PRIMARY SCHOOL",
    Address: "SANDFORD TRUST  MARITE  BUSHBUCKRIDGE",
    Latitude: "-24.9811",
    Longitude: "31.1265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18378",
    WardLookupId: "2229",
    VDNumber: "76170360",
    RegisteredPopulation: "1493",
    VotingStationName: "LAPISHE HIGHER PRIMARY SCHOOL",
    Address: "MARITE  HAZYVIEW",
    Latitude: "-24.9765",
    Longitude: "31.107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18379",
    WardLookupId: "2229",
    VDNumber: "76170371",
    RegisteredPopulation: "2293",
    VotingStationName: "MATHIPE SENIOR SECONDARY SCHOOL",
    Address: "MARITE  HAZYVIEW",
    Latitude: "-24.9636",
    Longitude: "31.1258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18380",
    WardLookupId: "2229",
    VDNumber: "76170607",
    RegisteredPopulation: "959",
    VotingStationName: "SKAPANDODA MASHABA SECONDARY SCHOOL",
    Address: "MADRAS  MP325 - BUSHBUCKRIDGE [BUSHBUCKRIDGE]  MATHIBELA",
    Latitude: "-24.9708",
    Longitude: "31.1383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18381",
    WardLookupId: "2229",
    VDNumber: "76170652",
    RegisteredPopulation: "386",
    VotingStationName: "MARITI COMMUNITY HALL",
    Address: "ALONG R40 ROAD  EHLANZENI-MARITI  MARITI",
    Latitude: "-24.9586",
    Longitude: "31.1255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18382",
    WardLookupId: "2230",
    VDNumber: "76170450",
    RegisteredPopulation: "823",
    VotingStationName: "MATHIBELA HIGH SCHOOL",
    Address: "ALEXANDRIA  BUSHBUCKRIDGE",
    Latitude: "-24.9486",
    Longitude: "31.1733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18383",
    WardLookupId: "2230",
    VDNumber: "76170461",
    RegisteredPopulation: "2106",
    VotingStationName: "ELEXANDRIA HIGHER PRIMARY SCHOOL",
    Address: "MARITE/ELEXANDRIA  BUSHBUCKRIDGE",
    Latitude: "-24.9599",
    Longitude: "31.1607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18384",
    WardLookupId: "2230",
    VDNumber: "76170472",
    RegisteredPopulation: "1469",
    VotingStationName: "MDUPING PRIMARY SCHOOL",
    Address: "MARITI  ELEXANDRIA  MKHUHLU",
    Latitude: "-24.9601",
    Longitude: "31.1347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18385",
    WardLookupId: "2230",
    VDNumber: "76170540",
    RegisteredPopulation: "1452",
    VotingStationName: "KWETSI PRIMARY SCHOOL",
    Address: "BUSHBUCKRIDGE  ",
    Latitude: "-24.959",
    Longitude: "31.1862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18386",
    WardLookupId: "2230",
    VDNumber: "76170663",
    RegisteredPopulation: "415",
    VotingStationName: "KHULONG PRIMARY SCHOOL",
    Address: "MARITI VILLAGE  EHLANZENI-MARITI  MARITI",
    Latitude: "-24.955",
    Longitude: "31.1407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18387",
    WardLookupId: "2231",
    VDNumber: "76170045",
    RegisteredPopulation: "1147",
    VotingStationName: "MATHUPA HIGHER PRIMARY SCHOOL",
    Address: "MARITE  BUSHBUCKRIDGE",
    Latitude: "-24.9533",
    Longitude: "31.1135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18388",
    WardLookupId: "2231",
    VDNumber: "76170113",
    RegisteredPopulation: "842",
    VotingStationName: "MARHONWANA HIGHER PRIMARY SCHOOL",
    Address: "MARONWANA  BUSHBUCKRIDGE",
    Latitude: "-24.9299",
    Longitude: "31.151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18389",
    WardLookupId: "2231",
    VDNumber: "76170359",
    RegisteredPopulation: "978",
    VotingStationName: "MAKOROMPANE LOWER PRIMARY SCHOOL",
    Address: "MARITE  HAZYVIEW",
    Latitude: "-24.9627",
    Longitude: "31.0918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18390",
    WardLookupId: "2231",
    VDNumber: "76170382",
    RegisteredPopulation: "1302",
    VotingStationName: "MACHIA MOKOENA SENIOR SECONDARY SCHOOL",
    Address: "MARITE  BUSHBUCKRIDGE",
    Latitude: "-24.9378",
    Longitude: "31.1105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18391",
    WardLookupId: "2231",
    VDNumber: "76170416",
    RegisteredPopulation: "934",
    VotingStationName: "EMFULENI PRIMARY SCHOOL",
    Address: "IREAGH A  XIMHUNGWE",
    Latitude: "-24.9763",
    Longitude: "31.0936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18392",
    WardLookupId: "2231",
    VDNumber: "76170427",
    RegisteredPopulation: "385",
    VotingStationName: "LM MOKOENA SENIOR SECONDARY SCHOOL",
    Address: "MARITE  HAZYVIEW",
    Latitude: "-24.9733",
    Longitude: "31.0845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18393",
    WardLookupId: "2231",
    VDNumber: "76170539",
    RegisteredPopulation: "1193",
    VotingStationName: "BADLANGAYE PRIMARY SCHOOL",
    Address: "ALONG R40 ROAD  EHLANZENI BUSHBUCKRIDGE  MARITE NKANINI VILLEGE",
    Latitude: "-24.9166",
    Longitude: "31.113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18394",
    WardLookupId: "2231",
    VDNumber: "76170674",
    RegisteredPopulation: "340",
    VotingStationName: "MP MOKOENA PRIMARY SCHOOL",
    Address: "MARITI VILLAGE  EHLANZENI-MARITI  MARITI",
    Latitude: "-24.9414",
    Longitude: "31.1117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18395",
    WardLookupId: "2232",
    VDNumber: "76150087",
    RegisteredPopulation: "690",
    VotingStationName: "THABAKGOLO PRIMARY SCHOOL",
    Address: "LONDON  BUSHBUCKRIDGE",
    Latitude: "-24.8123",
    Longitude: "31.0357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18396",
    WardLookupId: "2232",
    VDNumber: "76150278",
    RegisteredPopulation: "1860",
    VotingStationName: "DITAU SECONDARY SCHOOL",
    Address: "ZONE 01  SHATALE  BUSHBUCKRIDGE",
    Latitude: "-24.7991",
    Longitude: "31.0397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18397",
    WardLookupId: "2232",
    VDNumber: "76150289",
    RegisteredPopulation: "1414",
    VotingStationName: "NARISHE PRIMARY SCHOOL",
    Address: "MAGRASKOP  SHATALE MAGRASKOP  BUSHBUCKRIDGE",
    Latitude: "-24.7913",
    Longitude: "31.0308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18398",
    WardLookupId: "2232",
    VDNumber: "76150302",
    RegisteredPopulation: "1201",
    VotingStationName: "SERISHA SECONDARY SCHOOL",
    Address: "SHATALE  SHATALE TOWNSHIP  BUSHBUCKRIDGE",
    Latitude: "-24.7901",
    Longitude: "31.0409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18399",
    WardLookupId: "2232",
    VDNumber: "76150313",
    RegisteredPopulation: "552",
    VotingStationName: "NTSOELEMOLODI PRIMARY SCHOOL",
    Address: "MASAKENG  BUSHBUCKRIDGE  SHATALE",
    Latitude: "-24.8235",
    Longitude: "31.0529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18400",
    WardLookupId: "2233",
    VDNumber: "76150335",
    RegisteredPopulation: "1022",
    VotingStationName: "MATLOLANE PRIMARY SCHOOL",
    Address: "248 GA-MOTIBIDI  BUSHBUCKRIDGE  BUSHBUCKRIDGE",
    Latitude: "-24.7993",
    Longitude: "31.0707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18401",
    WardLookupId: "2233",
    VDNumber: "76150346",
    RegisteredPopulation: "1152",
    VotingStationName: "MORATISENG PRIMARY SCHOOL",
    Address: "BOIKHUTSO TRUST  BUSHBUCKRIDGE  BUSHBUCKRIDGE",
    Latitude: "-24.8196",
    Longitude: "31.0826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18402",
    WardLookupId: "2233",
    VDNumber: "76150380",
    RegisteredPopulation: "1821",
    VotingStationName: "DWARSLOOP CIVIC CENTRE",
    Address: "HOUSE NO A545  DWARSLOOP TOWNSHIP  BUSHBUCKRIDGE",
    Latitude: "-24.801",
    Longitude: "31.085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18403",
    WardLookupId: "2233",
    VDNumber: "76150683",
    RegisteredPopulation: "671",
    VotingStationName: "MASANA SECONDARY SCHOOL",
    Address: "PHASE 01  DWARSLOOP A  BUSHBUCKRIDGE",
    Latitude: "-24.7969",
    Longitude: "31.0901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18404",
    WardLookupId: "2233",
    VDNumber: "76150694",
    RegisteredPopulation: "879",
    VotingStationName: "ASSEMBLIES OF GOD CHURCH",
    Address: "PHASE 2  DWARSLOOP  DWARSLOOP",
    Latitude: "-24.7916",
    Longitude: "31.0877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18405",
    WardLookupId: "2233",
    VDNumber: "76150706",
    RegisteredPopulation: "337",
    VotingStationName: "ES MALELE SECONDARY  SCHOOL",
    Address: "GA-MOTIBIDI  BUSHBUCKRIDGE  DWARSLOOP",
    Latitude: "-24.8009",
    Longitude: "31.0634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18406",
    WardLookupId: "2234",
    VDNumber: "76150470",
    RegisteredPopulation: "2110",
    VotingStationName: "BUSHBUCKRIDGE SECONDARY SCHOOL",
    Address:
      "COLLEGE VIEW  EHLANZENI BUSHBUCKRIDGE  BUSHBUCKRIDGE COLLEGE VIEW",
    Latitude: "-24.846",
    Longitude: "31.0732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18407",
    WardLookupId: "2234",
    VDNumber: "76150492",
    RegisteredPopulation: "1923",
    VotingStationName: "BARNEY PRIMARY SCHOOL",
    Address: "MATENGTENG  BUSHBUCKRIDGE  BUSHBUCKRIDGE",
    Latitude: "-24.8546",
    Longitude: "31.0948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18408",
    WardLookupId: "2234",
    VDNumber: "76150616",
    RegisteredPopulation: "951",
    VotingStationName: "DIPHASWA PRIMARY SCHOOL",
    Address: "295 MPHENYATSATSI  MAPHENYATSATSI  BUSHBUCRIDGE",
    Latitude: "-24.8352",
    Longitude: "31.0304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18409",
    WardLookupId: "2234",
    VDNumber: "76150717",
    RegisteredPopulation: "656",
    VotingStationName: "IKEMELENG CRECHE",
    Address: "MASANA  BUSHBUCKRIDGE  EHLANZENI",
    Latitude: "-24.8492",
    Longitude: "31.0776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18410",
    WardLookupId: "2234",
    VDNumber: "76150829",
    RegisteredPopulation: "694",
    VotingStationName: "NTSIYE PRIMARY SCHOOL",
    Address: "MAVILJAN  EHLANZENI  BUSHBUCKRIDGE",
    Latitude: "-24.8446",
    Longitude: "31.0867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18411",
    WardLookupId: "2234",
    VDNumber: "76170618",
    RegisteredPopulation: "917",
    VotingStationName: "SEDIBENG SA THUTO PRIMARY SCHOOL",
    Address: "MAVILJAN  EHLANZENI  THABAKGOLO TRIBAL",
    Latitude: "-24.8617",
    Longitude: "31.1025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18412",
    WardLookupId: "2235",
    VDNumber: "76150054",
    RegisteredPopulation: "626",
    VotingStationName: "AMASHANGANA TRIBAL AUTHORITY",
    Address: "NEWFOREST A  BUSHBUCKRIDGE",
    Latitude: "-24.7356",
    Longitude: "31.1613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18413",
    WardLookupId: "2235",
    VDNumber: "76150414",
    RegisteredPopulation: "1840",
    VotingStationName: "SOSHANGANA HIGH SCHOOL",
    Address: "ATHURSTONE   ATHURSTONE VILLAGE  BUSHBUCKRIDGE",
    Latitude: "-24.7737",
    Longitude: "31.1348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18414",
    WardLookupId: "2235",
    VDNumber: "76150447",
    RegisteredPopulation: "1283",
    VotingStationName: "XINGALA MAKAMU SCHOOL",
    Address: "ATHURSTONE  BUSHBUCRIDGE",
    Latitude: "-24.7941",
    Longitude: "31.1305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18415",
    WardLookupId: "2235",
    VDNumber: "76150469",
    RegisteredPopulation: "1063",
    VotingStationName: "FREDDY SITHOLE SCHOOL",
    Address: "ATHURSTONE  BUSHBUCKRIDGE",
    Latitude: "-24.8063",
    Longitude: "31.1166",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18416",
    WardLookupId: "2235",
    VDNumber: "76150661",
    RegisteredPopulation: "1198",
    VotingStationName: "MPIKANISO PRIMARY SCHOOL",
    Address:
      "NEW FOREST TRUST THULAMAHASHE   BUSHBUCKRIDGE  BUSHBUCKRIDGE [BUSHBUCKRIDGE]",
    Latitude: "-24.7256",
    Longitude: "31.1536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18417",
    WardLookupId: "2235",
    VDNumber: "76150739",
    RegisteredPopulation: "515",
    VotingStationName: "BUYSONTO PRIMARY SCHOOL",
    Address: "BUYISONTO VILLAGE  EHLANZENI-ARTHURSTONE  ARTHURSTONE",
    Latitude: "-24.7832",
    Longitude: "31.1342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18418",
    WardLookupId: "2236",
    VDNumber: "76150188",
    RegisteredPopulation: "980",
    VotingStationName: "FAREL PRIMARY SCHOOL",
    Address: "EDENBURG C/ZOLA  THULAMAHASHE",
    Latitude: "-24.7751",
    Longitude: "31.0297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18419",
    WardLookupId: "2236",
    VDNumber: "76150256",
    RegisteredPopulation: "1896",
    VotingStationName: "MATHULE PRIMARY SCHOOL",
    Address: "R40 ROAD  VIOLET BANK  BUSHBUCKRIDGE",
    Latitude: "-24.7767",
    Longitude: "31.0487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18420",
    WardLookupId: "2236",
    VDNumber: "76150324",
    RegisteredPopulation: "1173",
    VotingStationName: "MALELE TRADITIONAL AUTHORITY OFFICE",
    Address: "BUSHBUCKRIDGE  ",
    Latitude: "-24.784",
    Longitude: "31.0642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18421",
    WardLookupId: "2236",
    VDNumber: "76150672",
    RegisteredPopulation: "989",
    VotingStationName: "SAILE PRIMARY SCHOOL",
    Address: "MP325 - BUSHBUCKRIDGE [BUSHBUCKRIDGE]  ",
    Latitude: "-24.7801",
    Longitude: "31.0712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18422",
    WardLookupId: "2236",
    VDNumber: "76150751",
    RegisteredPopulation: "487",
    VotingStationName: "SEKHUKHUSA HIGH SCHOOL",
    Address: "SHATALE VILLAGE  EHLANZENI-BUSHBUCKRIDGE  SHATALE",
    Latitude: "-24.7855",
    Longitude: "31.0577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18423",
    WardLookupId: "2236",
    VDNumber: "76150830",
    RegisteredPopulation: "785",
    VotingStationName: "NAZARETH CHURCH",
    Address: "VIOLETBANK  EHLANZENI  BUSHBUCKRIDGE",
    Latitude: "-24.7717",
    Longitude: "31.0454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18424",
    WardLookupId: "2236",
    VDNumber: "76150896",
    RegisteredPopulation: "334",
    VotingStationName: "ESSELMAN PRIMARY SCHOOL",
    Address: "RELANI  EHLANZENI  MALELE",
    Latitude: "-24.7709",
    Longitude: "31.0828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18425",
    WardLookupId: "2237",
    VDNumber: "76150065",
    RegisteredPopulation: "1177",
    VotingStationName: "MPISANA PRIMARY SCHOOL",
    Address: "TSUVULANI  NEWFOREST  BUSHBUCKRIDGE",
    Latitude: "-24.7463",
    Longitude: "31.1124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18426",
    WardLookupId: "2237",
    VDNumber: "76150076",
    RegisteredPopulation: "1162",
    VotingStationName: "QOKISI HIGH SCHOOL",
    Address: "ORINOCCO C  BUSHBUCKRIDGE",
    Latitude: "-24.7406",
    Longitude: "31.0943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18427",
    WardLookupId: "2237",
    VDNumber: "76150122",
    RegisteredPopulation: "1057",
    VotingStationName: "SBAMBAYANA PRIMARY SCHOOL",
    Address: "10 TSUVULANI TRUST  BUSHBUCKRIDGE  BUSHBUCKRIDGE [BUSHBUCKRIDGE]",
    Latitude: "-24.7338",
    Longitude: "31.0656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18428",
    WardLookupId: "2237",
    VDNumber: "76150212",
    RegisteredPopulation: "1635",
    VotingStationName: "MKHWEYANTABA HIGH SCHOOL",
    Address: "ORINOCCO A  DWARSLOOP  BUSHBUCKRIDGE",
    Latitude: "-24.7659",
    Longitude: "31.0721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18429",
    WardLookupId: "2237",
    VDNumber: "76150368",
    RegisteredPopulation: "514",
    VotingStationName: "MASWAMENI PRIMARY SCHOOL",
    Address: "ORINOCCO  BUSHBUCKRIDGE",
    Latitude: "-24.7532",
    Longitude: "31.056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18430",
    WardLookupId: "2237",
    VDNumber: "76150762",
    RegisteredPopulation: "350",
    VotingStationName: "HLAMALANI PRIMARY SCHOOL",
    Address: "HLAMALANI VILLAGE  EHLANZENI-DWARSLOOP  TSUVULANI",
    Latitude: "-24.7563",
    Longitude: "31.0828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18431",
    WardLookupId: "2238",
    VDNumber: "76150133",
    RegisteredPopulation: "832",
    VotingStationName: "MOKGAWANE PRIMARY SCHOOL",
    Address: "VIOLETBANK  BUSHBUCKRIDGE",
    Latitude: "-24.7381",
    Longitude: "31.0107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18432",
    WardLookupId: "2238",
    VDNumber: "76150199",
    RegisteredPopulation: "989",
    VotingStationName: "RIVONI CHURCH",
    Address: "DOSPAN  EHLANZENI  MOREIPUSO TRIBAL AUTHORITY",
    Latitude: "-24.7397",
    Longitude: "31.0312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18433",
    WardLookupId: "2238",
    VDNumber: "76150223",
    RegisteredPopulation: "815",
    VotingStationName: "LEKEDI HIGHER PRIMARY SCHOOL",
    Address: "ZOEKNOG  SOPHIA  BUSHBUCKRIDGE",
    Latitude: "-24.7724",
    Longitude: "30.9814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18434",
    WardLookupId: "2238",
    VDNumber: "76150245",
    RegisteredPopulation: "1258",
    VotingStationName: "LETSHELE SECONDARY SCHOOL",
    Address: "MAJEMBENI  VIOLET BANK  BUSHBUCKRIDGE",
    Latitude: "-24.7736",
    Longitude: "31.0197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18435",
    WardLookupId: "2238",
    VDNumber: "76150267",
    RegisteredPopulation: "533",
    VotingStationName: "THABAKGOLO TRADITIONAL AUTHORITY",
    Address: "THABAKGOLO  BUSHBUCKRIDGE",
    Latitude: "-24.7889",
    Longitude: "31.0197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18436",
    WardLookupId: "2238",
    VDNumber: "76150436",
    RegisteredPopulation: "1322",
    VotingStationName: "MATLALONG PRIMARY SCHOOL",
    Address: "SITE NO 999  VIOLET BANK B  BUSHBUCKRIDGE",
    Latitude: "-24.7588",
    Longitude: "31.0296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18437",
    WardLookupId: "2238",
    VDNumber: "76150773",
    RegisteredPopulation: "357",
    VotingStationName: "SHATALE PRIMARY SCHOOL",
    Address: "SHATALE VILLAGE  EHLANZENI-SHATALE  SHATALE",
    Latitude: "-24.7757",
    Longitude: "31.016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18438",
    WardLookupId: "2238",
    VDNumber: "76150784",
    RegisteredPopulation: "406",
    VotingStationName: "ALLIANCE CHURCH IN SA",
    Address: "SHATALE VILLAGE  EHLANZENI-SHATALE  SHATALE",
    Latitude: "-24.7611",
    Longitude: "31.031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18439",
    WardLookupId: "2238",
    VDNumber: "76150841",
    RegisteredPopulation: "511",
    VotingStationName: "EMOSWA MESIYA JERUSALEM CHURCH",
    Address: "RIVONI  EHLANZENI  CASTEEL",
    Latitude: "-24.7395",
    Longitude: "31.0251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18440",
    WardLookupId: "2239",
    VDNumber: "76160202",
    RegisteredPopulation: "1676",
    VotingStationName: "CASTEEL PRIMARY SCHOOL",
    Address: "CASTEEL  ACORNHOEK",
    Latitude: "-24.7217",
    Longitude: "31.0303",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18441",
    WardLookupId: "2239",
    VDNumber: "76160493",
    RegisteredPopulation: "682",
    VotingStationName: "BEN MATLOSHE HIGH SCHOOL",
    Address: "CASTEEL  ACORNHOEK",
    Latitude: "-24.7126",
    Longitude: "31.0122",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18442",
    WardLookupId: "2239",
    VDNumber: "76160505",
    RegisteredPopulation: "810",
    VotingStationName: "ASSEMBLIES OF GOD CHURCH",
    Address: "CASTEEL TRUST  EHLANZENI  MOREIPUSO TRIBAL AUTHORITY",
    Latitude: "-24.7223",
    Longitude: "31.0249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18443",
    WardLookupId: "2239",
    VDNumber: "76160549",
    RegisteredPopulation: "821",
    VotingStationName: "ALFRED MATSHINE COMMERCIAL SCHOOL",
    Address: "CASTEEL  ACORNHOEK",
    Latitude: "-24.7188",
    Longitude: "31.025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18444",
    WardLookupId: "2239",
    VDNumber: "76160561",
    RegisteredPopulation: "680",
    VotingStationName: "MASTRAATH HIGH SCHOOL",
    Address: "CASTEEL TRUST  EHLANZENI  MOEREIPUSO TRIBAL AUTHORITY",
    Latitude: "-24.7259",
    Longitude: "30.9957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18445",
    WardLookupId: "2239",
    VDNumber: "76160640",
    RegisteredPopulation: "1212",
    VotingStationName: "LEKANANG PRIMARY SCHOOL",
    Address: "CASTEEL  ",
    Latitude: "-24.7294",
    Longitude: "31.0054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18446",
    WardLookupId: "2239",
    VDNumber: "76161012",
    RegisteredPopulation: "343",
    VotingStationName: "MOSIPA HIGH SCHOOL",
    Address: "CASTEEL  EHLANZENI  ACORNHOEK",
    Latitude: "-24.7004",
    Longitude: "31.0298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18447",
    WardLookupId: "2239",
    VDNumber: "76161045",
    RegisteredPopulation: "323",
    VotingStationName: "KENNEN PRIMARY SCHOOL",
    Address: "CASTEEL  EHLANZENI  ACORNHOEK",
    Latitude: "-24.7323",
    Longitude: "31.0258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18448",
    WardLookupId: "2240",
    VDNumber: "76160011",
    RegisteredPopulation: "1618",
    VotingStationName: "MAPALANA PRIMARY SCHOOL",
    Address: "BOELANG  BUSHBUCKRIDGE",
    Latitude: "-24.5966",
    Longitude: "30.9684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18449",
    WardLookupId: "2240",
    VDNumber: "76160044",
    RegisteredPopulation: "1187",
    VotingStationName: "SEGANYANE PRIMARY SCHOOL",
    Address: "GREENVALLEY  ACORNHOEK",
    Latitude: "-24.5958",
    Longitude: "31.0217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18450",
    WardLookupId: "2240",
    VDNumber: "76160370",
    RegisteredPopulation: "941",
    VotingStationName: "MARIPE HIGH SCHOOL",
    Address: "GREEN VALLEY  ACORNHOEK",
    Latitude: "-24.6116",
    Longitude: "31.0182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18451",
    WardLookupId: "2240",
    VDNumber: "76160426",
    RegisteredPopulation: "1837",
    VotingStationName: "MAOTOLE PRIMARY SCHOOL",
    Address: "BROOKLYN  ACORNHOEK",
    Latitude: "-24.5996",
    Longitude: "30.9923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18452",
    WardLookupId: "2240",
    VDNumber: "76160730",
    RegisteredPopulation: "796",
    VotingStationName: "GERMAN CHILOANE PRIMARY SCHOOL",
    Address: "BROOKLYN  ACORNHOEK",
    Latitude: "-24.6085",
    Longitude: "30.983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18453",
    WardLookupId: "2240",
    VDNumber: "76160897",
    RegisteredPopulation: "496",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "BUSHBUCKRIDGE  ",
    Latitude: "-24.6136",
    Longitude: "30.9734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18454",
    WardLookupId: "2240",
    VDNumber: "76160921",
    RegisteredPopulation: "119",
    VotingStationName: "NKOTOBONA HIGH SCHOOL",
    Address: "BROOKLYN  EHLANZENI - ACORNHOEK  ACORNHOEK",
    Latitude: "-24.5855",
    Longitude: "30.9831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18455",
    WardLookupId: "2241",
    VDNumber: "76160033",
    RegisteredPopulation: "484",
    VotingStationName: "SENONE PRIMARY SCHOOL",
    Address: "ARTHURSEAT  ACORNHOEK",
    Latitude: "-24.6654",
    Longitude: "31.0418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18456",
    WardLookupId: "2241",
    VDNumber: "76160167",
    RegisteredPopulation: "1129",
    VotingStationName: "MOTLAMOGATSANE PRIMARY SCHOOL",
    Address: "ARTHURSEAT  ACORNHOEK",
    Latitude: "-24.64",
    Longitude: "31.0242",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18457",
    WardLookupId: "2241",
    VDNumber: "76160178",
    RegisteredPopulation: "1193",
    VotingStationName: "CRAIGHBURN PRIMARY SCHOOL",
    Address: "CRAIGBURN/ARTHURCITY  ACORNHOEK",
    Latitude: "-24.673",
    Longitude: "30.9887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18458",
    WardLookupId: "2241",
    VDNumber: "76160235",
    RegisteredPopulation: "1009",
    VotingStationName: "APLOS PRIMARY SCHOOL",
    Address: "ARTHURSEAT  ACORNHOEK",
    Latitude: "-24.6371",
    Longitude: "31.0085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18459",
    WardLookupId: "2241",
    VDNumber: "76160392",
    RegisteredPopulation: "888",
    VotingStationName: "NAZARENE CHURCH (MKHULULINE)",
    Address: "MP325 - BUSHBUCKRIDGE [BUSHBUCKRIDGE]  ",
    Latitude: "-24.6511",
    Longitude: "31.0221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18460",
    WardLookupId: "2241",
    VDNumber: "76160482",
    RegisteredPopulation: "1601",
    VotingStationName: "ARTHURSEAT CLINIC",
    Address: "ARTHURSEAT  ACORNHOEK",
    Latitude: "-24.6697",
    Longitude: "31.0204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18461",
    WardLookupId: "2241",
    VDNumber: "76160842",
    RegisteredPopulation: "802",
    VotingStationName: "DIMPHO DAY CARE CENTRE",
    Address:
      "MATSIKITSANE VILLEGE  EHLANZENI ACORNHOEK  ACORNHOEK MATSIKITSANE",
    Latitude: "-24.6401",
    Longitude: "31.0455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18462",
    WardLookupId: "2241",
    VDNumber: "76160909",
    RegisteredPopulation: "652",
    VotingStationName: "HLOAIA PRIMARY SCHOOL",
    Address: "GREENVALLEY TSAKANI  EHLANZENI-GREENVALLEY  GREENVALLEY",
    Latitude: "-24.6142",
    Longitude: "31.0075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18463",
    WardLookupId: "2242",
    VDNumber: "76160190",
    RegisteredPopulation: "1154",
    VotingStationName: "MADILE PRIMARY SCHOOL",
    Address: "ACORNHOEK  ",
    Latitude: "-24.6074",
    Longitude: "31.0872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18464",
    WardLookupId: "2242",
    VDNumber: "76160415",
    RegisteredPopulation: "1690",
    VotingStationName: "MAKWETSI PRIMARY SCHOOL",
    Address: "GREEN VALLEY  ACORNHOEK",
    Latitude: "-24.6252",
    Longitude: "31.0432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18465",
    WardLookupId: "2242",
    VDNumber: "76160437",
    RegisteredPopulation: "1537",
    VotingStationName: "EHLANZENI FET ACORNHOEK",
    Address: "ACORNHOEK  ACORNHOEK TRUST",
    Latitude: "-24.6013",
    Longitude: "31.0713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18466",
    WardLookupId: "2242",
    VDNumber: "76160572",
    RegisteredPopulation: "915",
    VotingStationName: "CHUEU PRIMARY SCHOOL",
    Address: "ROOIBOKLAAGTE  ROOIBOKLAAGTE A  ACORNHOEK",
    Latitude: "-24.6328",
    Longitude: "31.0837",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18467",
    WardLookupId: "2242",
    VDNumber: "76160932",
    RegisteredPopulation: "548",
    VotingStationName: "NAZARENE CHURCH (MAROMENG)",
    Address: "MAROMENG VILLAGE  EHLANZENI-ACORNHOEK  ACORNHOEK",
    Latitude: "-24.6127",
    Longitude: "31.0905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18468",
    WardLookupId: "2242",
    VDNumber: "76160943",
    RegisteredPopulation: "1085",
    VotingStationName: "POWERLINE PRIMARY SCHOOL",
    Address: "POWERLINE VILLAGE  EHLANZENI-ACORNHOEK  ACORNHOEK",
    Latitude: "-24.6063",
    Longitude: "31.0617",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18469",
    WardLookupId: "2242",
    VDNumber: "76161056",
    RegisteredPopulation: "530",
    VotingStationName: "LUTHERAN CHURCH",
    Address: "TECHNICAL AREA  EHLANZENI  ACORNHOEK",
    Latitude: "-24.6029",
    Longitude: "31.0758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18470",
    WardLookupId: "2243",
    VDNumber: "76160066",
    RegisteredPopulation: "1204",
    VotingStationName: "SEHLARE TRIBAL AUTHORITY",
    Address: "GREEN VALLEY  ACORNHOEK",
    Latitude: "-24.6092",
    Longitude: "31.0349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18471",
    WardLookupId: "2243",
    VDNumber: "76160099",
    RegisteredPopulation: "1447",
    VotingStationName: "GREENVALLEY PRIMARY SCHOOL",
    Address: "GREEN VALLEY  ACORNHOEK",
    Latitude: "-24.6179",
    Longitude: "31.0373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18472",
    WardLookupId: "2243",
    VDNumber: "76160101",
    RegisteredPopulation: "1476",
    VotingStationName: "SHOBIYANE HIGH SCHOOL",
    Address: "GREEN VALLEY  ACORNHOEK",
    Latitude: "-24.587",
    Longitude: "31.0652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18473",
    WardLookupId: "2243",
    VDNumber: "76160774",
    RegisteredPopulation: "877",
    VotingStationName: "KGWADITIBA PRIMARY SCHOOL",
    Address: "GREEN VALLEY  ACORNHOEK",
    Latitude: "-24.5917",
    Longitude: "31.0385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18474",
    WardLookupId: "2243",
    VDNumber: "76160820",
    RegisteredPopulation: "1264",
    VotingStationName: "LUMUKISA PRIMARY SCHOOL",
    Address: "BUSHBUCKRIDGE  ",
    Latitude: "-24.5775",
    Longitude: "31.0584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18475",
    WardLookupId: "2243",
    VDNumber: "76160853",
    RegisteredPopulation: "1158",
    VotingStationName: "MERRIAM MOGAKANE COMMUNITY HALL",
    Address: "MP325 - BUSHBUCKRIDGE [BUSHBUCKRIDGE]  ",
    Latitude: "-24.6014",
    Longitude: "31.0455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18476",
    WardLookupId: "2243",
    VDNumber: "76161034",
    RegisteredPopulation: "452",
    VotingStationName: "GREENVALLEY WESLEYAN CHURCH",
    Address: "KGAPAMADI  EHLANZENI  ACORNHOEK",
    Latitude: "-24.6032",
    Longitude: "31.0396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18477",
    WardLookupId: "2244",
    VDNumber: "76160077",
    RegisteredPopulation: "1231",
    VotingStationName: "PAULOS PRIMARY SCHOOL",
    Address: "ACORNHOEK  ",
    Latitude: "-24.603",
    Longitude: "31.1049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18478",
    WardLookupId: "2244",
    VDNumber: "76160145",
    RegisteredPopulation: "830",
    VotingStationName: "MAGWAGWAZA HIGH SCHOOL",
    Address: "ACORNHOEK  ",
    Latitude: "-24.6004",
    Longitude: "31.08",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18479",
    WardLookupId: "2244",
    VDNumber: "76160314",
    RegisteredPopulation: "959",
    VotingStationName: "MASINGITANA SECONDARY SCHOOL",
    Address: "OKHERNOOITBOOM  ACORNHOEK",
    Latitude: "-24.5789",
    Longitude: "31.1151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18480",
    WardLookupId: "2244",
    VDNumber: "76160684",
    RegisteredPopulation: "1424",
    VotingStationName: "MUGIDI PRIMARY SCHOOL",
    Address: "TIMBAVATI  EHLANZENI  ACORNHOEK",
    Latitude: "-24.5918",
    Longitude: "31.0912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18481",
    WardLookupId: "2244",
    VDNumber: "76160875",
    RegisteredPopulation: "682",
    VotingStationName: "ACORN-OAKS HIGH SCHOOL",
    Address: "140 TINTSWALO VILLAGE  EHLANZENI  ACORNHOEK",
    Latitude: "-24.5739",
    Longitude: "31.0802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18482",
    WardLookupId: "2244",
    VDNumber: "76160954",
    RegisteredPopulation: "423",
    VotingStationName: "WISDOM ENGLISH PRE-SCHOOL",
    Address: "TIMBAVATI ROAD  EHLANZENI-ACORNHOEK  ACORNHOEK",
    Latitude: "-24.5955",
    Longitude: "31.0753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18483",
    WardLookupId: "2244",
    VDNumber: "76160965",
    RegisteredPopulation: "594",
    VotingStationName: "BERETA PRIMARY SCHOOL",
    Address: "TIMBAVATI ROAD  EHLANZENI-KA-BERETA  ACORNHOEK TIMBAVATI",
    Latitude: "-24.5798",
    Longitude: "31.1063",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18484",
    WardLookupId: "2244",
    VDNumber: "76160987",
    RegisteredPopulation: "513",
    VotingStationName: "PFUNANANI DAY CARE CENTRE",
    Address: "BUSHBUCKRIDGE  ",
    Latitude: "-24.602",
    Longitude: "31.1058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18485",
    WardLookupId: "2245",
    VDNumber: "76160280",
    RegisteredPopulation: "1000",
    VotingStationName: "SIHLEKISI PRIMARY SCHOOL",
    Address: "ACORNHOEK  SIGAGULE/OKHERNOOITBOOM  ACORNHOEK",
    Latitude: "-24.572",
    Longitude: "31.1353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18486",
    WardLookupId: "2245",
    VDNumber: "76160369",
    RegisteredPopulation: "861",
    VotingStationName: "MAWUVANA PRIMARY SCHOOL",
    Address: "OKHERNOOITBOOM  ACORNHOEK",
    Latitude: "-24.6051",
    Longitude: "31.1475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18487",
    WardLookupId: "2245",
    VDNumber: "76160448",
    RegisteredPopulation: "562",
    VotingStationName: "MOSES HIGH SCHOOL",
    Address: "COTTONDALE TRUST  OKHERNOOITBOOM  ACORNHOEK",
    Latitude: "-24.6158",
    Longitude: "31.1492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18488",
    WardLookupId: "2245",
    VDNumber: "76160459",
    RegisteredPopulation: "1694",
    VotingStationName: "PHENDULANI HIGH SCHOOL",
    Address: "COTTONDALE  OKHERNOOITBOOM  ACORNHOEK",
    Latitude: "-24.6074",
    Longitude: "31.1349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18489",
    WardLookupId: "2245",
    VDNumber: "76160460",
    RegisteredPopulation: "1365",
    VotingStationName: "JAMEYANA PRIMARY SCHOOL",
    Address: "OKKERNOOITBOOM  OKHERNOOITBOOM  ACORNHOEK",
    Latitude: "-24.6116",
    Longitude: "31.1222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18490",
    WardLookupId: "2245",
    VDNumber: "76160976",
    RegisteredPopulation: "394",
    VotingStationName: "ASSEMBLIES FAITH MISSION",
    Address: "COTTENDALE MAIN ROAD  ACORNHOEK  COTTENDALE",
    Latitude: "-24.6146",
    Longitude: "31.1224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18491",
    WardLookupId: "2246",
    VDNumber: "76160213",
    RegisteredPopulation: "965",
    VotingStationName: "ECKSON MASOCHA PRIMARY SCHOOL",
    Address: "ACORNHOEK  ",
    Latitude: "-24.6221",
    Longitude: "31.1092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18492",
    WardLookupId: "2246",
    VDNumber: "76160224",
    RegisteredPopulation: "2009",
    VotingStationName: "TLADISHE HIGH SCHOOL",
    Address: "BUFFELSHOEK  ACORNHOEK",
    Latitude: "-24.6323",
    Longitude: "31.1284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18493",
    WardLookupId: "2246",
    VDNumber: "76160279",
    RegisteredPopulation: "1763",
    VotingStationName: "MAKGATHLISHE PRIMARY SCHOOL",
    Address: "BUFFELSHOEK  ACORNHOEK",
    Latitude: "-24.6238",
    Longitude: "31.156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18494",
    WardLookupId: "2246",
    VDNumber: "76160831",
    RegisteredPopulation: "955",
    VotingStationName: "LETSAMAILE PRIMARY SCHOOL",
    Address: "MP325 - BUSHBUCKRIDGE [BUSHBUCKRIDGE]  ",
    Latitude: "-24.6404",
    Longitude: "31.1475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18495",
    WardLookupId: "2246",
    VDNumber: "76161001",
    RegisteredPopulation: "796",
    VotingStationName: "BAPTIST CHURCH",
    Address: "BUFFELSHOEK  ACORNHOEK  BUFFELSHOEK",
    Latitude: "-24.6373",
    Longitude: "31.1302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18496",
    WardLookupId: "2247",
    VDNumber: "76150100",
    RegisteredPopulation: "718",
    VotingStationName: "BOMBANI HIGH SCHOOL",
    Address: "MAMBUMBU  EDENBURG B  THULAMAHASHE",
    Latitude: "-24.683",
    Longitude: "31.1764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18497",
    WardLookupId: "2247",
    VDNumber: "76150155",
    RegisteredPopulation: "1199",
    VotingStationName: "SONGENI PRIMARY SCHOOL",
    Address: "SONGENI  SONGENI VILLAGE  BUSHBUCKRIDGE",
    Latitude: "-24.6943",
    Longitude: "31.1714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18498",
    WardLookupId: "2247",
    VDNumber: "76160156",
    RegisteredPopulation: "705",
    VotingStationName: "SNIANYA PRIMARY SCHOOL",
    Address: "CHAMPAIGN  ROOIBOKLAAGTE C  ACORNHOEK",
    Latitude: "-24.6695",
    Longitude: "31.0601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18499",
    WardLookupId: "2247",
    VDNumber: "76160303",
    RegisteredPopulation: "816",
    VotingStationName: "SEBOSEGOLO PRIMARY SCHOOL",
    Address: "DINGLEDALE  ACORNHOEK",
    Latitude: "-24.6711",
    Longitude: "31.1485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18500",
    WardLookupId: "2247",
    VDNumber: "76160358",
    RegisteredPopulation: "1236",
    VotingStationName: "MOREI PRIMARY SCHOOL",
    Address: "DINGLEDALE  ACORNHOEK",
    Latitude: "-24.6974",
    Longitude: "31.1218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18501",
    WardLookupId: "2247",
    VDNumber: "76160550",
    RegisteredPopulation: "1223",
    VotingStationName: "ASSEMBLIES OF GOD CHURCH",
    Address: "ROOIBOKLAAGTE B  ACORNHOEK",
    Latitude: "-24.653",
    Longitude: "31.0671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18502",
    WardLookupId: "2247",
    VDNumber: "76160998",
    RegisteredPopulation: "559",
    VotingStationName: "FAITH MISSION CHRISTIAN CHURCH",
    Address: "CHOCHOCHO  EHLANZENI-DINGLEDALE  DINGLEDALE",
    Latitude: "-24.7025",
    Longitude: "31.1173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18503",
    WardLookupId: "2248",
    VDNumber: "76170012",
    RegisteredPopulation: "1598",
    VotingStationName: "THULANI PRIMARY SCHOOL",
    Address: "MATANGALENI  MKHUHLU",
    Latitude: "-24.9588",
    Longitude: "31.2715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18504",
    WardLookupId: "2248",
    VDNumber: "76170023",
    RegisteredPopulation: "1863",
    VotingStationName: "MVUYAZI PRIMARY SCHOOL",
    Address: "CORK  CORK VILLEGE  MKHUHLU",
    Latitude: "-24.9556",
    Longitude: "31.3221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18505",
    WardLookupId: "2248",
    VDNumber: "76170034",
    RegisteredPopulation: "586",
    VotingStationName: "DUMISANI SENIOR SECONDARY SCHOOL",
    Address: "CORK  MKHUHLU",
    Latitude: "-24.9493",
    Longitude: "31.314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18506",
    WardLookupId: "2248",
    VDNumber: "76170102",
    RegisteredPopulation: "1913",
    VotingStationName: "SHALAMUKA HIGHER PRIMARY SCHOOL",
    Address: "BELFAST  HAZYVIEW",
    Latitude: "-24.9546",
    Longitude: "31.3701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18507",
    WardLookupId: "2248",
    VDNumber: "76170269",
    RegisteredPopulation: "1445",
    VotingStationName: "MKHUMBINI HIGHER PRIMARY SCHOOL",
    Address: "CORK  MKHUHLU",
    Latitude: "-24.9556",
    Longitude: "31.3083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18508",
    WardLookupId: "2248",
    VDNumber: "76170753",
    RegisteredPopulation: "323",
    VotingStationName: "ASSEMBLIES OF GOD",
    Address: "SANP ROAD  MKHUHLU  MANTANGALENI-MKHULHU",
    Latitude: "-24.9632",
    Longitude: "31.272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18509",
    WardLookupId: "2249",
    VDNumber: "76170179",
    RegisteredPopulation: "1910",
    VotingStationName: "CUNNING MOORE PRIMARY SCHOOL",
    Address: "CUNNINGMOORE  MKHUHLU",
    Latitude: "-24.8959",
    Longitude: "31.2104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18510",
    WardLookupId: "2249",
    VDNumber: "76170449",
    RegisteredPopulation: "891",
    VotingStationName: "PLANTJIE HIGHER PRIMARY SCHOOL",
    Address: "BUSHBUCKRIDGE  OARKLEY  BUSHBUCKRIDGE",
    Latitude: "-24.9245",
    Longitude: "31.2476",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18511",
    WardLookupId: "2249",
    VDNumber: "76170494",
    RegisteredPopulation: "1948",
    VotingStationName: "OAKLEY PRIMARY SCHOOL",
    Address: "OAKLEY  BUSHBUCKRIDGE",
    Latitude: "-24.9321",
    Longitude: "31.2262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18512",
    WardLookupId: "2249",
    VDNumber: "76170551",
    RegisteredPopulation: "499",
    VotingStationName: "JAMES KHOSA HIGH SCHOOL",
    Address: "13 OKLEY TRUST  BUSHBUCKRIDGE  BUSHBUCKRIDGE [BUSHBUCKRIDGE]",
    Latitude: "-24.9142",
    Longitude: "31.2192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18513",
    WardLookupId: "2249",
    VDNumber: "76170595",
    RegisteredPopulation: "773",
    VotingStationName: "HLANGALEZWE LOWER PRIMARY SCHOOL",
    Address: "MP325 - BUSHBUCKRIDGE [BUSHBUCKRIDGE]  ",
    Latitude: "-24.9548",
    Longitude: "31.2421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18514",
    WardLookupId: "2249",
    VDNumber: "76170685",
    RegisteredPopulation: "1193",
    VotingStationName: "CHURCH OF THE NAZARENE",
    Address: "OAKLY ROAD  OAKLEY-EHLANZENI  OAKLY-MATHIBELA",
    Latitude: "-24.9396",
    Longitude: "31.2255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18515",
    WardLookupId: "2250",
    VDNumber: "76170090",
    RegisteredPopulation: "868",
    VotingStationName: "MANDONDO SENIOR SECONDARY SCHOOL",
    Address: "BELFAST  MKHUHLU",
    Latitude: "-24.9457",
    Longitude: "31.3717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18516",
    WardLookupId: "2250",
    VDNumber: "76170135",
    RegisteredPopulation: "1308",
    VotingStationName: "BABATI HIGHER PRIMARY SCHOOL",
    Address: "JUSTICIA  XIMHUNGWE",
    Latitude: "-24.8866",
    Longitude: "31.4118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18517",
    WardLookupId: "2250",
    VDNumber: "76170203",
    RegisteredPopulation: "1101",
    VotingStationName: "MABARHULE HIGH SCHOOL",
    Address: "LILYDALE  XIMHUNGWE",
    Latitude: "-24.8997",
    Longitude: "31.3911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18518",
    WardLookupId: "2250",
    VDNumber: "76170214",
    RegisteredPopulation: "1439",
    VotingStationName: "MADLALA SENIOR SECONDARY SCHOOL",
    Address: "JUSTICIA  XIMHUNGWE",
    Latitude: "-24.8732",
    Longitude: "31.4131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18519",
    WardLookupId: "2250",
    VDNumber: "76170304",
    RegisteredPopulation: "295",
    VotingStationName: "JJ MATSANE HIGHER PRIMARY SCHOOL",
    Address: "SOMERSET  XIMHUNGWE",
    Latitude: "-24.9232",
    Longitude: "31.3697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18520",
    WardLookupId: "2250",
    VDNumber: "76170348",
    RegisteredPopulation: "1269",
    VotingStationName: "HUNDZUKANI HIGHER PRIMARY SCHOOL",
    Address: "HUNTINGTON  XIMHUNGWE",
    Latitude: "-24.9177",
    Longitude: "31.416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18521",
    WardLookupId: "2251",
    VDNumber: "76170124",
    RegisteredPopulation: "1129",
    VotingStationName: "JONGILANGA TRIBAL AUTHORITY",
    Address: "KILDARE  XIMHUNGWE",
    Latitude: "-24.8933",
    Longitude: "31.3268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18522",
    WardLookupId: "2251",
    VDNumber: "76170270",
    RegisteredPopulation: "1249",
    VotingStationName: "RHANDZEKILE HIGHER PRIMARY SCHOOL",
    Address: "LILLYDALE  MKHUHLU",
    Latitude: "-24.8627",
    Longitude: "31.3626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18523",
    WardLookupId: "2251",
    VDNumber: "76170405",
    RegisteredPopulation: "942",
    VotingStationName: "BONDZENI SENIOR SECONDARY SCHOOL",
    Address: "KILDARE  XIMHUNGWE",
    Latitude: "-24.8728",
    Longitude: "31.3323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18524",
    WardLookupId: "2251",
    VDNumber: "76170483",
    RegisteredPopulation: "1983",
    VotingStationName: "MHLAHLE HIGHER PRIMARY SCHOOL",
    Address: "LILLYDALE  XIMHUNGWE",
    Latitude: "-24.8746",
    Longitude: "31.3787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18525",
    WardLookupId: "2251",
    VDNumber: "76170708",
    RegisteredPopulation: "476",
    VotingStationName: "HIBAMANDLA PRIMARY SCHOOL",
    Address: "KILLDARE VILLAGE  EHLANZENI-KILLDARE  KILLDARE",
    Latitude: "-24.875",
    Longitude: "31.3647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18526",
    WardLookupId: "2251",
    VDNumber: "76170719",
    RegisteredPopulation: "411",
    VotingStationName: "HLOMANI SECONDARY SCHOOL",
    Address: "BUSHBUCKRIDGE  ",
    Latitude: "-24.868",
    Longitude: "31.3607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18527",
    WardLookupId: "2252",
    VDNumber: "76150559",
    RegisteredPopulation: "876",
    VotingStationName: "GAVAZANA PRIMARY SCHOOL",
    Address: "NEWINGTON VILLAGE  NEWINGTON  BUSHBUCKRIDGE",
    Latitude: "-24.8144",
    Longitude: "31.3172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18528",
    WardLookupId: "2252",
    VDNumber: "76150560",
    RegisteredPopulation: "1293",
    VotingStationName: "MANYAKATANA PRIMARY SCHOOL",
    Address: "NEWINGTON  JONGILANGA",
    Latitude: "-24.8108",
    Longitude: "31.2902",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18529",
    WardLookupId: "2252",
    VDNumber: "76150582",
    RegisteredPopulation: "926",
    VotingStationName: "MAHLAHLUVANA PRIMARY SCHOOL",
    Address: "BUSHBUCKRIDGE DUMPFRIES B  RURAL  ",
    Latitude: "-24.7711",
    Longitude: "31.3202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18530",
    WardLookupId: "2252",
    VDNumber: "76150908",
    RegisteredPopulation: "334",
    VotingStationName: "KHAYALAMI CHRECHE",
    Address: "KHAYALAMI VILLAGE (RDP)  EHLANZENI  AGINCOURT",
    Latitude: "-24.8224",
    Longitude: "31.2729",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18531",
    WardLookupId: "2252",
    VDNumber: "76170146",
    RegisteredPopulation: "972",
    VotingStationName: "TIYANI HIGHER PRIMARY SCHOOL",
    Address: "IREAGH A  XIMHUNGWE",
    Latitude: "-24.8333",
    Longitude: "31.312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18532",
    WardLookupId: "2252",
    VDNumber: "76170225",
    RegisteredPopulation: "629",
    VotingStationName: "LUKA SENIOR SECONDARY SCHOOL",
    Address: "CROQVETLAWN A  XIMHUNGWE",
    Latitude: "-24.8451",
    Longitude: "31.2891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18533",
    WardLookupId: "2252",
    VDNumber: "76170292",
    RegisteredPopulation: "658",
    VotingStationName: "HLANGANANI HIGHER PRIMARY SCHOOL",
    Address: "GROQVETLAWN B  XIMHUNGWE",
    Latitude: "-24.8529",
    Longitude: "31.2872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18534",
    WardLookupId: "2252",
    VDNumber: "76170326",
    RegisteredPopulation: "896",
    VotingStationName: "MAGUDU HIGHER PRIMARY SCHOOL",
    Address: "IREAGH/MATEY  XIMHUNGWE",
    Latitude: "-24.8484",
    Longitude: "31.3405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18535",
    WardLookupId: "2252",
    VDNumber: "76170573",
    RegisteredPopulation: "203",
    VotingStationName: "CHRIST FOR LIFE CHURCH",
    Address: "CRUQUET LAWN TRUST  BUSHBUCKRIDGE  BUSHBUCKRIDGE",
    Latitude: "-24.8457",
    Longitude: "31.2465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18536",
    WardLookupId: "2252",
    VDNumber: "76170731",
    RegisteredPopulation: "509",
    VotingStationName: "NJOMBO PRE-SCHOOL",
    Address: "MASUKU VILLAGE  EHLANZENI-KA-MASUKU  KA-MASUKU",
    Latitude: "-24.8376",
    Longitude: "31.3099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18537",
    WardLookupId: "2253",
    VDNumber: "76150021",
    RegisteredPopulation: "933",
    VotingStationName: "WELANI PRIMARY SCHOOL",
    Address: "DUMPHRIES A  DUMPHRIES A VILLAGE  BUSHBUCKRIDGE",
    Latitude: "-24.7681",
    Longitude: "31.2899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18538",
    WardLookupId: "2253",
    VDNumber: "76150201",
    RegisteredPopulation: "698",
    VotingStationName: "HOKWE PRIMARY SCHOOL",
    Address: "MP STREAM  M.P ATREAM  BUSHBUCKRIDGE",
    Latitude: "-24.7945",
    Longitude: "31.2455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18539",
    WardLookupId: "2253",
    VDNumber: "76150504",
    RegisteredPopulation: "938",
    VotingStationName: "XANTHIA HIGH SCHOOL",
    Address: "XANTHIA  XANTHIA VILLAGE  BUSHBUCRIDGE",
    Latitude: "-24.8309",
    Longitude: "31.1606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18540",
    WardLookupId: "2253",
    VDNumber: "76150526",
    RegisteredPopulation: "865",
    VotingStationName: "MZILA HIGH SCHOOL",
    Address: "AGINCOURT  BUSHBUCKRIDGE",
    Latitude: "-24.8253",
    Longitude: "31.2286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18541",
    WardLookupId: "2253",
    VDNumber: "76150537",
    RegisteredPopulation: "704",
    VotingStationName: "XIMOYI KHOSA SCHOOL",
    Address: "AGINCOURT  BUSHBUCKRIDGE",
    Latitude: "-24.8245",
    Longitude: "31.2496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18542",
    WardLookupId: "2253",
    VDNumber: "76150548",
    RegisteredPopulation: "1046",
    VotingStationName: "KHAYELIHLE PRIMARY SCHOOL",
    Address: "AGINCOURT  BUSHBUCKRIDGE",
    Latitude: "-24.825",
    Longitude: "31.2601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18543",
    WardLookupId: "2253",
    VDNumber: "76150807",
    RegisteredPopulation: "506",
    VotingStationName: "MATSAVANE PRIMARY SCHOOL",
    Address: "AGINCOURT  EHLANZENI-AGINCOURT  AGINCOURT",
    Latitude: "-24.8237",
    Longitude: "31.2145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18544",
    WardLookupId: "2254",
    VDNumber: "76150098",
    RegisteredPopulation: "563",
    VotingStationName: "WISANI PRIMARY SCHOOL",
    Address: "0 WISANI  ROLLE VILLAGE  BUSHBUCKRIDGE",
    Latitude: "-24.7455",
    Longitude: "31.2341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18545",
    WardLookupId: "2254",
    VDNumber: "76150290",
    RegisteredPopulation: "1041",
    VotingStationName: "ZIGODE PRIMARY SCHOOL",
    Address: "0 ZIGODE  KUMANI VILLAGE  BUSHBUCKRIDGE",
    Latitude: "-24.7407",
    Longitude: "31.2005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18546",
    WardLookupId: "2254",
    VDNumber: "76150379",
    RegisteredPopulation: "831",
    VotingStationName: "FLORAH PHOPHE PRIMARY SCHOOL",
    Address: "ROLLE  BUSHBUCKRIDGE",
    Latitude: "-24.7385",
    Longitude: "31.2362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18547",
    WardLookupId: "2254",
    VDNumber: "76150458",
    RegisteredPopulation: "1162",
    VotingStationName: "NDIMANDE PRIMARY SCHOOL",
    Address: "HOKWE  MP STREAM VILLAGE  BUSHBUCKRIDGE",
    Latitude: "-24.7592",
    Longitude: "31.2415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18548",
    WardLookupId: "2254",
    VDNumber: "76150481",
    RegisteredPopulation: "1167",
    VotingStationName: "LANGA HIGH SCHOOL",
    Address: "MP STREAM  BUSHBUCKRIDGE",
    Latitude: "-24.7495",
    Longitude: "31.2214",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18549",
    WardLookupId: "2254",
    VDNumber: "76150593",
    RegisteredPopulation: "1047",
    VotingStationName: "MANUKUSE HIGH SCHOOL",
    Address: "MP STREAM  RURAL/BUSHBUCKRIDGE  MP STREAM",
    Latitude: "-24.7669",
    Longitude: "31.2147",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18550",
    WardLookupId: "2254",
    VDNumber: "76150852",
    RegisteredPopulation: "534",
    VotingStationName: "HLAVATHI PRIMARY SCHOOL",
    Address: "KA-MAKARINGE  MP STREAM  MP STREAM",
    Latitude: "-24.7762",
    Longitude: "31.1956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18551",
    WardLookupId: "2255",
    VDNumber: "54850013",
    RegisteredPopulation: "504",
    VotingStationName: "LONDOLOZI PRIVATE GAME RESERVE",
    Address: "LONDOLOZI PRIVATE GAME RESERVE  EHLANZENI  SKUKUZA",
    Latitude: "-24.4751",
    Longitude: "31.296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18552",
    WardLookupId: "2255",
    VDNumber: "54850024",
    RegisteredPopulation: "339",
    VotingStationName: "SATARA REST CAMP",
    Address: "SATARA VILLEGE  EHLANZENI SKUKUZA  KRUGER NATIONAL PARK",
    Latitude: "-24.393",
    Longitude: "31.7798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18553",
    WardLookupId: "2255",
    VDNumber: "54850035",
    RegisteredPopulation: "111",
    VotingStationName: "SAVANNA PRIVATE GAME LODGE",
    Address: "SAVANNA PRIVATE GAME LODGE  EHLANZENI  SKUKUZA",
    Latitude: "-24.8597",
    Longitude: "31.4021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18554",
    WardLookupId: "2255",
    VDNumber: "54850046",
    RegisteredPopulation: "93",
    VotingStationName: "TSHIMANIMANI MUSEUM",
    Address: "TSHIMANIMANI  KRUGER NATIONAL PARK   KRUGER NATIONAL PARK",
    Latitude: "-24.376",
    Longitude: "31.1609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18555",
    WardLookupId: "2255",
    VDNumber: "54850057",
    RegisteredPopulation: "115",
    VotingStationName: "SINGITA EBONY PRIVATE GAME RESERVE",
    Address: "KRUGER NATIONAL PARK  EHLANZENI  HAZYVIEW",
    Latitude: "-24.7763",
    Longitude: "31.399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18556",
    WardLookupId: "2255",
    VDNumber: "76160088",
    RegisteredPopulation: "618",
    VotingStationName: "MNISI TRIBAL AUTHORITY",
    Address: "ISLINGTON  ACORNHOEK",
    Latitude: "-24.6276",
    Longitude: "31.2641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18557",
    WardLookupId: "2255",
    VDNumber: "76160189",
    RegisteredPopulation: "1020",
    VotingStationName: "SESETE PRIMARY SCHOOL",
    Address: "BURLINGTON  ACORNHOEK",
    Latitude: "-24.6286",
    Longitude: "31.2166",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18558",
    WardLookupId: "2255",
    VDNumber: "76160268",
    RegisteredPopulation: "723",
    VotingStationName: "KHOKHOVELA PRIMARY SCHOOL",
    Address: "ISLINGTON  ACORNHOEK",
    Latitude: "-24.6174",
    Longitude: "31.265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18559",
    WardLookupId: "2255",
    VDNumber: "76160325",
    RegisteredPopulation: "571",
    VotingStationName: "MABONWANA HIGH SCHOOL",
    Address: "OKHERNOOITBOOM  ACORNHOEK",
    Latitude: "-24.6161",
    Longitude: "31.1596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18560",
    WardLookupId: "2255",
    VDNumber: "76160336",
    RegisteredPopulation: "621",
    VotingStationName: "ANDOVER PRIMARY SCHOOL",
    Address: "OKHERNOOITBOOM  ACORNHOEK",
    Latitude: "-24.6088",
    Longitude: "31.1673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18561",
    WardLookupId: "2255",
    VDNumber: "76160516",
    RegisteredPopulation: "803",
    VotingStationName: "MPHAKO PRIMARY SCHOOL",
    Address: "ISLINGTON  ACORNHOEK",
    Latitude: "-24.6285",
    Longitude: "31.2538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18562",
    WardLookupId: "2255",
    VDNumber: "76160527",
    RegisteredPopulation: "713",
    VotingStationName: "MADIZI HIGH SCHOOL",
    Address: "BURLINGTON  ACORNHOEK",
    Latitude: "-24.6237",
    Longitude: "31.2157",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18563",
    WardLookupId: "2256",
    VDNumber: "76150032",
    RegisteredPopulation: "2347",
    VotingStationName: "THULAMAHASHE CIVIC CENTRE",
    Address: "MAIN STREET  THULAMAHASHE A  THULAMAHASHE",
    Latitude: "-24.7263",
    Longitude: "31.2072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18564",
    WardLookupId: "2256",
    VDNumber: "76150043",
    RegisteredPopulation: "1226",
    VotingStationName: "ERIC NXUMALO SCHOOL",
    Address: "ERICK NXUMALO  THULAMAHASHE B  BUSHBUCKRIDGE",
    Latitude: "-24.7183",
    Longitude: "31.2235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18565",
    WardLookupId: "2256",
    VDNumber: "76150650",
    RegisteredPopulation: "1489",
    VotingStationName: "OLD STADIUM",
    Address: "THULAMAHASHE A  BUSHBUCKRIDGE",
    Latitude: "-24.7234",
    Longitude: "31.1944",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18566",
    WardLookupId: "2256",
    VDNumber: "76150818",
    RegisteredPopulation: "778",
    VotingStationName: "THULAMAHASHE NEW STADIUM",
    Address: "BUSHBUCKRIDGE  ",
    Latitude: "-24.7227",
    Longitude: "31.2071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18567",
    WardLookupId: "2256",
    VDNumber: "76150863",
    RegisteredPopulation: "515",
    VotingStationName: "MPITHI COMBINED SCHOOL",
    Address: "THULAMAHASHE B SECTION  THULAMAHASHE  THULAMAHASHE",
    Latitude: "-24.7213",
    Longitude: "31.2151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18568",
    WardLookupId: "2256",
    VDNumber: "76150919",
    RegisteredPopulation: "176",
    VotingStationName: "PENTACOSTAL ASSEMBLIES OF GOD",
    Address: "DARK CITY THULAMAHASHE  EHLANZENI  AMASHANGANA T/A",
    Latitude: "-24.7271",
    Longitude: "31.1829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18569",
    WardLookupId: "2257",
    VDNumber: "76160134",
    RegisteredPopulation: "608",
    VotingStationName: "MAHUBAHUBA HIGH PRIMARY SCHOOL",
    Address: "ZOEKNOG  ACORNHOEK",
    Latitude: "-24.7569",
    Longitude: "30.9749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18570",
    WardLookupId: "2257",
    VDNumber: "76160628",
    RegisteredPopulation: "1071",
    VotingStationName: "MOTLAMOGALE PRIMARY SCHOOL",
    Address: "MOREIPUSHO  CASTEEL  MOTLAMOGALE",
    Latitude: "-24.6998",
    Longitude: "31.0112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18571",
    WardLookupId: "2257",
    VDNumber: "76160639",
    RegisteredPopulation: "1505",
    VotingStationName: "MATLUSHE PRIMARY SCHOOL",
    Address: "WILLS  CASTEEL  ACORNHOEK",
    Latitude: "-24.7009",
    Longitude: "30.9855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18572",
    WardLookupId: "2257",
    VDNumber: "76160651",
    RegisteredPopulation: "736",
    VotingStationName: "MOREIPUSO TRADITIONAL AUTHORITY",
    Address: "CASTEEL  BUSHBUCKRIDGE",
    Latitude: "-24.6975",
    Longitude: "31.0013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18573",
    WardLookupId: "2257",
    VDNumber: "76160662",
    RegisteredPopulation: "1302",
    VotingStationName: "BEN W MASHEGO SECONDARY SCHOOL",
    Address: "ZOEKNOG  CASTEEL  BUSHBUCKRIDGE",
    Latitude: "-24.7447",
    Longitude: "30.9833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18574",
    WardLookupId: "2257",
    VDNumber: "76160808",
    RegisteredPopulation: "676",
    VotingStationName: "MAOLOSHE PRIMARY SCHOOL",
    Address: "BUSHBUCKRIDGE  ",
    Latitude: "-24.6877",
    Longitude: "30.9883",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18575",
    WardLookupId: "2257",
    VDNumber: "76161067",
    RegisteredPopulation: "298",
    VotingStationName: "CASTEEL CRECHE",
    Address: "MAGHAMOSENG  BUSHBUCKRIDGE  CASTEEL",
    Latitude: "-24.7177",
    Longitude: "31.0241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18576",
    WardLookupId: "2258",
    VDNumber: "76150605",
    RegisteredPopulation: "835",
    VotingStationName: "MANYANGANA HIGH SCHOOL",
    Address: "UTAH VILLAGE  RURAL  THULAMAHASHE",
    Latitude: "-24.688",
    Longitude: "31.449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18577",
    WardLookupId: "2258",
    VDNumber: "76160055",
    RegisteredPopulation: "1394",
    VotingStationName: "MALWANA PRIMARY SCHOOL",
    Address: "EGLINGTON C  HLUVUKANI",
    Latitude: "-24.6488",
    Longitude: "31.3465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18578",
    WardLookupId: "2258",
    VDNumber: "76160123",
    RegisteredPopulation: "1943",
    VotingStationName: "MUGHENA HIGH SCHOOL",
    Address: "EGLINGTON A  HLUVUKANI",
    Latitude: "-24.6457",
    Longitude: "31.3453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18579",
    WardLookupId: "2258",
    VDNumber: "76160257",
    RegisteredPopulation: "323",
    VotingStationName: "XINYEKETI PRIMARY SCHOOL",
    Address: "THORNDALE  ACORNHOEK",
    Latitude: "-24.6464",
    Longitude: "31.4469",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18580",
    WardLookupId: "2258",
    VDNumber: "76160606",
    RegisteredPopulation: "573",
    VotingStationName: "(SHORTY) MAHLATHI SCHOOL",
    Address: "SHORTY VILLAGE  HLUVUKANI",
    Latitude: "-24.6645",
    Longitude: "31.3715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18581",
    WardLookupId: "2258",
    VDNumber: "76160673",
    RegisteredPopulation: "452",
    VotingStationName: "SAMSON SIBUYI PRIMARY SCHOOL",
    Address: "SERVILLE B  ACORNHOEK",
    Latitude: "-24.6728",
    Longitude: "31.446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18582",
    WardLookupId: "2258",
    VDNumber: "76160695",
    RegisteredPopulation: "248",
    VotingStationName: "HANANANI PRIMARY SCHOOL",
    Address: "DIXIE  MANYELETI",
    Latitude: "-24.6928",
    Longitude: "31.4727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18583",
    WardLookupId: "2258",
    VDNumber: "76160707",
    RegisteredPopulation: "334",
    VotingStationName: "MUNYAMANA PRIMARY SCHOOL",
    Address: "SERVILLE A  HLUVUKANI",
    Latitude: "-24.6666",
    Longitude: "31.4134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18584",
    WardLookupId: "2258",
    VDNumber: "76160729",
    RegisteredPopulation: "256",
    VotingStationName: "SERVILLE PRE-SCHOOL",
    Address: "SERVILLE C  HLUVUKANI",
    Latitude: "-24.6526",
    Longitude: "31.4227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18585",
    WardLookupId: "2258",
    VDNumber: "76160886",
    RegisteredPopulation: "300",
    VotingStationName: "NKWENYANA DAY CARE CENTRE",
    Address: "HLUVUKANI MAIN ROAD  EHLANZENI-HLUVUKANI  HLUVUKANI",
    Latitude: "-24.6486",
    Longitude: "31.3357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18586",
    WardLookupId: "2258",
    VDNumber: "76161023",
    RegisteredPopulation: "434",
    VotingStationName: "FREE ALIANCE CHURCH",
    Address: "HLUVUKANI MAIN ROAD  EHLANZENI-HLUVUKANI  HLUVUKANI",
    Latitude: "-24.6476",
    Longitude: "31.3386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18587",
    WardLookupId: "2259",
    VDNumber: "76160022",
    RegisteredPopulation: "1015",
    VotingStationName: "MUCHUCHI PRIMARY SCHOOL",
    Address: "GOTTENBURG C  BUSHBUCKRIDGE",
    Latitude: "-24.6213",
    Longitude: "31.3744",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18588",
    WardLookupId: "2259",
    VDNumber: "76160381",
    RegisteredPopulation: "1062",
    VotingStationName: "MAHLEKISANA PRIMARY SCHOOL",
    Address: "WELVERDIEND  WERVERDIED  ACORNHOEK",
    Latitude: "-24.582",
    Longitude: "31.3245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18589",
    WardLookupId: "2259",
    VDNumber: "76160404",
    RegisteredPopulation: "615",
    VotingStationName: "HLALAKAHLE SCHOOL",
    Address: "GOTTENBURG B  GOTTENDBURG",
    Latitude: "-24.624",
    Longitude: "31.4311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18590",
    WardLookupId: "2259",
    VDNumber: "76160471",
    RegisteredPopulation: "1243",
    VotingStationName: "MAHLALE HIGH SCHOOL",
    Address: "WERVERDIED  ACORNHOEK",
    Latitude: "-24.5881",
    Longitude: "31.3412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18591",
    WardLookupId: "2259",
    VDNumber: "76160718",
    RegisteredPopulation: "1022",
    VotingStationName: "MANYELETI PRIMARY SCHOOL",
    Address: "GOTTENBURG A  ACOORNHOEK",
    Latitude: "-24.6373",
    Longitude: "31.4145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18592",
    WardLookupId: "2259",
    VDNumber: "76160763",
    RegisteredPopulation: "957",
    VotingStationName: "MTEMBENI PRIMARY SCHOOL",
    Address: "WERVERDIED  ACORNHOEK",
    Latitude: "-24.5699",
    Longitude: "31.3524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18593",
    WardLookupId: "2260",
    VDNumber: "76170056",
    RegisteredPopulation: "1132",
    VotingStationName: "MBHANDULE SENIOR SECONDARY SCHOOL",
    Address: "RONALDSEY  MKHUHLU",
    Latitude: "-24.9209",
    Longitude: "31.2894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18594",
    WardLookupId: "2260",
    VDNumber: "76170191",
    RegisteredPopulation: "1127",
    VotingStationName: "MCHAKA SENIOR SECONDARY SCHOOL",
    Address: "CUNNINGMOORE  XIMHUNGWE",
    Latitude: "-24.891",
    Longitude: "31.262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18595",
    WardLookupId: "2260",
    VDNumber: "76170247",
    RegisteredPopulation: "1423",
    VotingStationName: "DYONDZEKANI HIGHER PRIMARY SCHOOL",
    Address: "SOMERSET  XIMHUNGWE",
    Latitude: "-24.9135",
    Longitude: "31.3716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18596",
    WardLookupId: "2260",
    VDNumber: "76170393",
    RegisteredPopulation: "1545",
    VotingStationName: "BUNNY KHOZA SENIOR SECONDARY SCHOOL",
    Address: "IREAGH B/KILDARE B  XIMHUNGWE",
    Latitude: "-24.8743",
    Longitude: "31.3061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18597",
    WardLookupId: "2260",
    VDNumber: "76170696",
    RegisteredPopulation: "583",
    VotingStationName: "JONGILANGA PRIMARY SCHOOL",
    Address: "KILLDARE VILLAGE  EHLANZENI-KILLDARE  KILLDARE",
    Latitude: "-24.8876",
    Longitude: "31.3149",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18598",
    WardLookupId: "2260",
    VDNumber: "76170720",
    RegisteredPopulation: "497",
    VotingStationName: "SARINGWA PRIMARY SCHOOL",
    Address: "CUNNINGMOOR B  EHLANZENI - CUNNINGMOOR B  CUNNINGMOOR B",
    Latitude: "-24.8919",
    Longitude: "31.256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18599",
    WardLookupId: "2261",
    VDNumber: "76150111",
    RegisteredPopulation: "778",
    VotingStationName: "EDENBURG B OPEN SPACE (TENT)",
    Address: "EDENBURG B  EHLANZENI  MNISI TRADITIONAL AUTHORITY",
    Latitude: "-24.6882",
    Longitude: "31.2317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18600",
    WardLookupId: "2261",
    VDNumber: "76150144",
    RegisteredPopulation: "488",
    VotingStationName: "XILONGANA PRIMARY SCHOOL",
    Address: "EDENBURG  EDINBURG  BUSHBUCKRIDGE",
    Latitude: "-24.6786",
    Longitude: "31.2326",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18601",
    WardLookupId: "2261",
    VDNumber: "76150166",
    RegisteredPopulation: "1064",
    VotingStationName: "EZROM PRIMARY SCHOOL",
    Address: "ZOLA  EHLANZENI  BUSHBUCKRIDGE",
    Latitude: "-24.7066",
    Longitude: "31.1903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18602",
    WardLookupId: "2261",
    VDNumber: "76150177",
    RegisteredPopulation: "1070",
    VotingStationName: "GODIDE HIGH SCHOOL",
    Address: "ROLLE VILLEGE  ROLLE TRUST  THULAMAHASHE",
    Latitude: "-24.7113",
    Longitude: "31.2464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18603",
    WardLookupId: "2261",
    VDNumber: "76150234",
    RegisteredPopulation: "990",
    VotingStationName: "CHARLY MBUNGELE SCHOOL",
    Address: "ROLLE  BUSHBUCKRIDGE",
    Latitude: "-24.7373",
    Longitude: "31.2486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18604",
    WardLookupId: "2261",
    VDNumber: "76150425",
    RegisteredPopulation: "1368",
    VotingStationName: "ALLANDALE CRECHE",
    Address: "LUDLOW ROAD  ALLANDALE A  BUSHBUCKRIDGE",
    Latitude: "-24.7084",
    Longitude: "31.2746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18605",
    WardLookupId: "2261",
    VDNumber: "76150571",
    RegisteredPopulation: "516",
    VotingStationName: "MUZILIKAZI PRIMARY SCHOOL",
    Address: "DAMPFHRIES C  RURAL  THULAMAHASHE",
    Latitude: "-24.7474",
    Longitude: "31.3083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18606",
    WardLookupId: "2261",
    VDNumber: "76150885",
    RegisteredPopulation: "378",
    VotingStationName: "MALAMULE HIGH SCHOOL",
    Address: "EDINBURG  BUSHBUCHRIDGE",
    Latitude: "-24.6841",
    Longitude: "31.2309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18607",
    WardLookupId: "2262",
    VDNumber: "76150357",
    RegisteredPopulation: "917",
    VotingStationName: "MASILELA HIGH SCHOOL",
    Address: "BOIKHUTSO - RAMALEMA VILEGE  BOIKUTSO  BUSHBUCKRIDGE",
    Latitude: "-24.8199",
    Longitude: "31.0941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18608",
    WardLookupId: "2262",
    VDNumber: "76150391",
    RegisteredPopulation: "1597",
    VotingStationName: "KUFAKWEZWE HIGH SCHOOL",
    Address: "SASELANI MAIN ROAD  SASELANI VILLAGE  BUSHBUCKRIDGE",
    Latitude: "-24.7898",
    Longitude: "31.1094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18609",
    WardLookupId: "2262",
    VDNumber: "76150515",
    RegisteredPopulation: "728",
    VotingStationName: "HLUVUKANI PRIMARY SCHOOL",
    Address: "XANTHIA  XANTHIA VILLAGE  BUSHBUCKRIDGE",
    Latitude: "-24.8335",
    Longitude: "31.1431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18610",
    WardLookupId: "2262",
    VDNumber: "76150627",
    RegisteredPopulation: "1133",
    VotingStationName: "NGWARITSHANE HIGH SCHOOL",
    Address: "BUSHBUCKRIDGE R40 ROAD  SHANGAAN HILL  BUSHBUCKRIDGE",
    Latitude: "-24.8425",
    Longitude: "31.0941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18611",
    WardLookupId: "2262",
    VDNumber: "76150649",
    RegisteredPopulation: "544",
    VotingStationName: "BENGBALONA PRIMARY SCHOOL",
    Address: "KUTUNG  BUSHBUCKRIDGE",
    Latitude: "-24.8109",
    Longitude: "31.1118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18612",
    WardLookupId: "2262",
    VDNumber: "76150728",
    RegisteredPopulation: "303",
    VotingStationName: "FEED MY LAMBS PRE-SCHOOL",
    Address: "BUSHBUCKRIDGE  ",
    Latitude: "-24.8473",
    Longitude: "31.1024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18613",
    WardLookupId: "2262",
    VDNumber: "76150740",
    RegisteredPopulation: "949",
    VotingStationName: "SASELANI PRIMARY SCHOOL",
    Address: "SASELANI VILLAGE  EHLANZENI-DWARSLOOP  SASELANI-MAFEMANI",
    Latitude: "-24.8003",
    Longitude: "31.102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18614",
    WardLookupId: "2262",
    VDNumber: "76150874",
    RegisteredPopulation: "249",
    VotingStationName: "NKULUNGWANE HP SCHOOL",
    Address: "XANTHIA MAIN ROAD  XANTHIA  XANTHIA",
    Latitude: "-24.8208",
    Longitude: "31.1406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18615",
    WardLookupId: "2263",
    VDNumber: "76150010",
    RegisteredPopulation: "1137",
    VotingStationName: "HUMULANI PRIMARY SCHOOL",
    Address: "ATHOL   ATHOL VILLAGE  BUSHBUCKRIDGE",
    Latitude: "-24.715",
    Longitude: "31.3505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18616",
    WardLookupId: "2263",
    VDNumber: "76150403",
    RegisteredPopulation: "588",
    VotingStationName: "NDWANDE HIGH SCHOOL",
    Address: "LEPHONG VILLEGE  ALLANDALE B  BUSHBUCKRIDGE",
    Latitude: "-24.7054",
    Longitude: "31.3005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18617",
    WardLookupId: "2263",
    VDNumber: "76160112",
    RegisteredPopulation: "577",
    VotingStationName: "TSUVUKA PRIMARY SCHOOL",
    Address: "EGLINGTON A  ACORNHOEK",
    Latitude: "-24.6739",
    Longitude: "31.3145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18618",
    WardLookupId: "2263",
    VDNumber: "76160246",
    RegisteredPopulation: "1781",
    VotingStationName: "LUDLOW PRIMARY SCHOOL",
    Address: "LUDLOW  ACORNHOEK",
    Latitude: "-24.6753",
    Longitude: "31.2845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18619",
    WardLookupId: "2263",
    VDNumber: "76160291",
    RegisteredPopulation: "846",
    VotingStationName: "NXALATI PRIMARY SCHOOL",
    Address: "LUDLOW  ACORNHOEK",
    Latitude: "-24.6608",
    Longitude: "31.2783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18620",
    WardLookupId: "2263",
    VDNumber: "76160583",
    RegisteredPopulation: "1094",
    VotingStationName: "SOKISI HIGH SCHOOL",
    Address: "CLARE  ACORNHOEK",
    Latitude: "-24.6312",
    Longitude: "31.3077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18621",
    WardLookupId: "4304",
    VDNumber: "54740011",
    RegisteredPopulation: "2213",
    VotingStationName: "LAERSKOOL NUMBI",
    Address: "149 GOMPOUSINEL STR  HAZYVIEW VAK DORP  HAZYVIEW",
    Latitude: "-25.0375",
    Longitude: "31.1401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18622",
    WardLookupId: "4304",
    VDNumber: "54740077",
    RegisteredPopulation: "3221",
    VotingStationName: "TFOLINHLANHLA PRIMARY SCHOOL",
    Address: "12 HAZYVIEW  SHABALALA VILLAGE  HAZYVIEW",
    Latitude: "-25.0387",
    Longitude: "31.1701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18623",
    WardLookupId: "4304",
    VDNumber: "54740112",
    RegisteredPopulation: "325",
    VotingStationName: "LIBERTY WORLD MINISTRY",
    Address: "SANDFORD  MBOMBELA  KGARUDI  TRIBAL",
    Latitude: "-24.9895",
    Longitude: "31.1261",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18624",
    WardLookupId: "4304",
    VDNumber: "54740178",
    RegisteredPopulation: "774",
    VotingStationName: "PRAISE TABERNACLE CHURCH",
    Address: "MP322 - MBOMBELA [NELSPRUIT]   ",
    Latitude: "-25.0348",
    Longitude: "31.157",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18625",
    WardLookupId: "4304",
    VDNumber: "54740202",
    RegisteredPopulation: "266",
    VotingStationName: "THE NEW PARADISE CHURCH",
    Address: "SHABALALA TRUST  HAZYVIEW",
    Latitude: "-25.0334",
    Longitude: "31.1713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18626",
    WardLookupId: "4304",
    VDNumber: "54740280",
    RegisteredPopulation: "293",
    VotingStationName: "LIKUSASALETHU PRE-SCHOOL",
    Address: "SANFORD  KGARUDI TRIBAL AUTHORITY",
    Latitude: "-24.9971",
    Longitude: "31.142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18627",
    WardLookupId: "4304",
    VDNumber: "76170641",
    RegisteredPopulation: "348",
    VotingStationName: "MALAVUTELA HIGH SCHOOL",
    Address: "MARITE   BUSHBUCKRIDGE  MARITE A",
    Latitude: "-24.9831",
    Longitude: "31.1297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18628",
    WardLookupId: "4305",
    VDNumber: "54790151",
    RegisteredPopulation: "2247",
    VotingStationName: "BONGANI PRIMARY SCHOOL",
    Address: "1 BONGANI STREET  DAANJTIE  NELSPRUIT",
    Latitude: "-25.4169",
    Longitude: "31.1921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18629",
    WardLookupId: "4305",
    VDNumber: "54790342",
    RegisteredPopulation: "1648",
    VotingStationName: "MAYIBUYE HIGH SCHOOL",
    Address: "1 DAANJTIE STREET  DAANTJIE  NELSPRUIT",
    Latitude: "-25.4286",
    Longitude: "31.1884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18630",
    WardLookupId: "4305",
    VDNumber: "54790375",
    RegisteredPopulation: "1689",
    VotingStationName: "MPUMELELO EDUCARE CENTRE",
    Address: "DAANTJIE TRUST   DAANTJIE  NELSPRUIT",
    Latitude: "-25.4228",
    Longitude: "31.1928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18631",
    WardLookupId: "4305",
    VDNumber: "54790409",
    RegisteredPopulation: "964",
    VotingStationName: "TIGA PRIMARY SCHOOL",
    Address: "1 ENKOMENI  DAANTJIE TRUST  NELSPRUIT",
    Latitude: "-25.4255",
    Longitude: "31.1989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18632",
    WardLookupId: "4305",
    VDNumber: "54790522",
    RegisteredPopulation: "631",
    VotingStationName: "HAPPINESS CHRISTIAN CHURCH",
    Address: "NKOMENI  DAANTJIE  DAANTJIE",
    Latitude: "-25.4247",
    Longitude: "31.204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18633",
    WardLookupId: "4305",
    VDNumber: "54790746",
    RegisteredPopulation: "1157",
    VotingStationName: "MANDLESIVE HIGH SCHOOL",
    Address: "DAANTJIE TRUST  PIENAAR  MBOMBELA",
    Latitude: "-25.4008",
    Longitude: "31.1906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18634",
    WardLookupId: "4306",
    VDNumber: "54740033",
    RegisteredPopulation: "2092",
    VotingStationName: "LUNDANDA COMBINED SCHOOL",
    Address: "MAUSHU TRUST  MASHUSHU TRUST  HAZYVIEW",
    Latitude: "-25.1216",
    Longitude: "31.1339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18635",
    WardLookupId: "4306",
    VDNumber: "54740044",
    RegisteredPopulation: "1813",
    VotingStationName: "KHOMBINDLELA PRIMARY SCHOOL",
    Address: "MASHUSHU TRUST  HAZYVIEW",
    Latitude: "-25.1446",
    Longitude: "31.1263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18636",
    WardLookupId: "4306",
    VDNumber: "54740101",
    RegisteredPopulation: "840",
    VotingStationName: "RUNNING WATER EINDOMME",
    Address: "KIEPERSOL FARM  HAZYVIEW  MBOMBELA",
    Latitude: "-25.0821",
    Longitude: "31.0406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18637",
    WardLookupId: "4306",
    VDNumber: "54740156",
    RegisteredPopulation: "915",
    VotingStationName: "MAHUSHU ZION CATHOLIC CHURCH",
    Address: "MAHUSH  MAHUSHU  HAZYVIEW",
    Latitude: "-25.1276",
    Longitude: "31.1304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18638",
    WardLookupId: "4306",
    VDNumber: "54740167",
    RegisteredPopulation: "442",
    VotingStationName: "SHEKINAH COMMUNITY CHURCH CENTRE",
    Address: "MAHUSHU  HAZYVIEW",
    Latitude: "-25.1357",
    Longitude: "31.1261",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18639",
    WardLookupId: "4306",
    VDNumber: "54740189",
    RegisteredPopulation: "961",
    VotingStationName: "SIPHUMELELE HIGH SCHOOL",
    Address: "MAUSHU TUST  HAZYVIEW  HAZYVIEW",
    Latitude: "-25.1151",
    Longitude: "31.1285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18640",
    WardLookupId: "4306",
    VDNumber: "54740213",
    RegisteredPopulation: "627",
    VotingStationName: "HAZYVIEW COMPREHENSIVE SCHOOL",
    Address: "MAHUSHU  HAZYVIEW",
    Latitude: "-25.1104",
    Longitude: "31.1388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18641",
    WardLookupId: "4306",
    VDNumber: "54740224",
    RegisteredPopulation: "374",
    VotingStationName: "MAHUSHU D ASSEMBLY OF GOD",
    Address: "MAHUSHU D  MAHUSHU TRUST  HAZYVIEW",
    Latitude: "-25.138",
    Longitude: "31.1222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18642",
    WardLookupId: "4307",
    VDNumber: "54790038",
    RegisteredPopulation: "1883",
    VotingStationName: "PHAKANI PRIMARY SCHOOL",
    Address: "PHAKANE  ZWELISHA  NELSPRUIT",
    Latitude: "-25.3702",
    Longitude: "31.1911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18643",
    WardLookupId: "4307",
    VDNumber: "54790049",
    RegisteredPopulation: "2485",
    VotingStationName: "ZWELISHA PRIMARY SCHOOL",
    Address: "ZWELISHA  NELSPRUIT",
    Latitude: "-25.3748",
    Longitude: "31.1712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18644",
    WardLookupId: "4307",
    VDNumber: "54790623",
    RegisteredPopulation: "1370",
    VotingStationName: "EKUPHUMULENI CHRISTIAN CHURCH IN ZION",
    Address: "CLAU CLAU  MBUYANE TRIBAL",
    Latitude: "-25.3716",
    Longitude: "31.1763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18645",
    WardLookupId: "4308",
    VDNumber: "54910155",
    RegisteredPopulation: "1331",
    VotingStationName: "MDZIMBA PRIMARY SCHOOL",
    Address: "PHOLA TRUST  HAZYVIEW",
    Latitude: "-25.1698",
    Longitude: "31.1405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18646",
    WardLookupId: "4308",
    VDNumber: "54910548",
    RegisteredPopulation: "1536",
    VotingStationName: "SALUBINDZA PRIMARY SCHOOL",
    Address: "SALUBINDZA  SALUBINDZA TRUST  HAZYVIEW",
    Latitude: "-25.1373",
    Longitude: "31.1562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18647",
    WardLookupId: "4308",
    VDNumber: "54910571",
    RegisteredPopulation: "1188",
    VotingStationName: "BHEKISWAKO SECONDARY SCHOOL",
    Address: "BHEKISWAKO  HAZYVIEW",
    Latitude: "-25.1377",
    Longitude: "31.1401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18648",
    WardLookupId: "4308",
    VDNumber: "54910649",
    RegisteredPopulation: "703",
    VotingStationName: "BAMBANANI PRIMARY SCHOOL",
    Address: "PHOLA TRUST  PHOLA  HAZYVIEW",
    Latitude: "-25.164",
    Longitude: "31.1449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18649",
    WardLookupId: "4308",
    VDNumber: "54910784",
    RegisteredPopulation: "895",
    VotingStationName: "ARISE EDUCARE PROJECT",
    Address: "SALUBINDZA  HAZYVIEW",
    Latitude: "-25.1509",
    Longitude: "31.159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18650",
    WardLookupId: "4308",
    VDNumber: "54910818",
    RegisteredPopulation: "559",
    VotingStationName: "MASHUKELA GENERAL DEALER",
    Address: "PHOLA TRUST  HAZYVIEW",
    Latitude: "-25.174",
    Longitude: "31.1481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18651",
    WardLookupId: "4308",
    VDNumber: "54910919",
    RegisteredPopulation: "351",
    VotingStationName: "PHOLA SECONDARY SCHOOL",
    Address: "PHOLA TRUST  HAZYVIEW  HAZYVIEW",
    Latitude: "-25.1704",
    Longitude: "31.1424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18652",
    WardLookupId: "4308",
    VDNumber: "54910964",
    RegisteredPopulation: "609",
    VotingStationName: "ST. JOHNS CHURCH",
    Address: "DAYIZENZA  HAZYVIEW",
    Latitude: "-25.1487",
    Longitude: "31.1312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18653",
    WardLookupId: "4308",
    VDNumber: "54910986",
    RegisteredPopulation: "545",
    VotingStationName: "CELANI PRIMARY SCHOOL",
    Address: "SALUBINDZA  HAZYVIEW",
    Latitude: "-25.1351",
    Longitude: "31.1501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18654",
    WardLookupId: "4308",
    VDNumber: "54910997",
    RegisteredPopulation: "542",
    VotingStationName: "EYETHU DAY CARE CENTRE",
    Address: "SALUBINDZA TRUST  HAZYVIEW",
    Latitude: "-25.1328",
    Longitude: "31.1419",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18655",
    WardLookupId: "4308",
    VDNumber: "54911167",
    RegisteredPopulation: "259",
    VotingStationName: "SWISS MISSION IN SA",
    Address: "PHOLA TRUST  HAZYVIEW",
    Latitude: "-25.1643",
    Longitude: "31.1351",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18656",
    WardLookupId: "4309",
    VDNumber: "54910324",
    RegisteredPopulation: "839",
    VotingStationName: "PHOLA RESOURCE CENTRE",
    Address: "PHOLA  HAZYVIEW",
    Latitude: "-25.1612",
    Longitude: "31.1227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18657",
    WardLookupId: "4309",
    VDNumber: "54910357",
    RegisteredPopulation: "1987",
    VotingStationName: "MSHADZA SECONDARY SCHOOL",
    Address: "MSHADZA  MBOMBELA  HAZYVIEW",
    Latitude: "-25.1598",
    Longitude: "31.1184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18658",
    WardLookupId: "4309",
    VDNumber: "54910616",
    RegisteredPopulation: "1965",
    VotingStationName: "ZAMANI PRIMARY SCHOOL",
    Address: "PHOLA  PHOLA TRUST  HAZYVIEW",
    Latitude: "-25.1566",
    Longitude: "31.1278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18659",
    WardLookupId: "4309",
    VDNumber: "54910650",
    RegisteredPopulation: "921",
    VotingStationName: "SIYAFUNDZA PRIMARY SCHOOL",
    Address: "SIYAFUNDZA  MSHADZA TRUST  WHITE RIVER",
    Latitude: "-25.1713",
    Longitude: "31.1134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18660",
    WardLookupId: "4309",
    VDNumber: "54910795",
    RegisteredPopulation: "674",
    VotingStationName: "KANYISANI SECONDARY SCHOOL",
    Address: "MBOMBELA [NELSPRUIT]  ",
    Latitude: "-25.1753",
    Longitude: "31.1141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18661",
    WardLookupId: "4309",
    VDNumber: "54910885",
    RegisteredPopulation: "452",
    VotingStationName: "THE METHODIST CHURCH",
    Address: "MASOYI TRUST  MASOYI  HAZYVIEW",
    Latitude: "-25.1619",
    Longitude: "31.1193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18662",
    WardLookupId: "4309",
    VDNumber: "54911000",
    RegisteredPopulation: "678",
    VotingStationName: "FREE NEW TWELVE APOSTOLIC CHURCH",
    Address: "NKANINI  MSHADZA  HAZYVIEW",
    Latitude: "-25.155",
    Longitude: "31.1197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18663",
    WardLookupId: "4309",
    VDNumber: "54911178",
    RegisteredPopulation: "480",
    VotingStationName: "MASOYI SPECIAL CARE CENTRE",
    Address: "MSHADZA TRUST  HAZYVIEW",
    Latitude: "-25.1616",
    Longitude: "31.113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18664",
    WardLookupId: "4310",
    VDNumber: "54910290",
    RegisteredPopulation: "850",
    VotingStationName: "MDUMISENI PRIMARY SCHOOL",
    Address: "MASOYI  HAZYVIEW",
    Latitude: "-25.174",
    Longitude: "31.1274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18665",
    WardLookupId: "4310",
    VDNumber: "54910313",
    RegisteredPopulation: "1217",
    VotingStationName: "DUTCH REFORMED CHURCH",
    Address: "MANZINI  MANZINI TRUST  MBOMBELA",
    Latitude: "-25.179",
    Longitude: "31.1389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18666",
    WardLookupId: "4310",
    VDNumber: "54910335",
    RegisteredPopulation: "1552",
    VotingStationName: "MANZINI PRIMARY SCHOOL",
    Address: "CHOCHOCHO TRUST  CHOCHOCHO  HAZYVIEW",
    Latitude: "-25.181",
    Longitude: "31.1305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18667",
    WardLookupId: "4310",
    VDNumber: "54910346",
    RegisteredPopulation: "1336",
    VotingStationName: "MASOYI PRIMARY SCHOOL",
    Address: "SWALALA TRUST  MASOYI  HAZYVIEW",
    Latitude: "-25.1802",
    Longitude: "31.1138",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18668",
    WardLookupId: "4310",
    VDNumber: "54910661",
    RegisteredPopulation: "510",
    VotingStationName: "MTHUNZINI DUTCH REFORMED CHURCH",
    Address: "MTHUNZINI  PHOLA  HAZYVIEW",
    Latitude: "-25.1924",
    Longitude: "31.1115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18669",
    WardLookupId: "4310",
    VDNumber: "54910807",
    RegisteredPopulation: "315",
    VotingStationName: "LUNGISANI SECONDARY SCHOOL",
    Address: "TEKATAKHO  HAZYVIEW",
    Latitude: "-25.1883",
    Longitude: "31.125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18670",
    WardLookupId: "4310",
    VDNumber: "54910874",
    RegisteredPopulation: "283",
    VotingStationName: "THE REDEEMED CHRISTIAN CHURCH",
    Address: "SWALALA TRUST  HAZYVIEW",
    Latitude: "-25.1824",
    Longitude: "31.1133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18671",
    WardLookupId: "4310",
    VDNumber: "54910975",
    RegisteredPopulation: "378",
    VotingStationName: "APOSTOLIC GOSPEL CHURCH",
    Address: "PHOLA TRUST  HAZYVIEW",
    Latitude: "-25.1644",
    Longitude: "31.1327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18672",
    WardLookupId: "4310",
    VDNumber: "54911011",
    RegisteredPopulation: "322",
    VotingStationName: "TSEMBANANI PRIMARY SCHOOL",
    Address: "CHOCHOCHO  MANZINI TRUST  HAZYVIEW",
    Latitude: "-25.1834",
    Longitude: "31.1319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18673",
    WardLookupId: "4311",
    VDNumber: "54910470",
    RegisteredPopulation: "1330",
    VotingStationName: "INKANYEZI PRIMARY SCHOOL",
    Address: "MGANDUZWENI  WHITE RIVER",
    Latitude: "-25.221",
    Longitude: "31.0989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18674",
    WardLookupId: "4311",
    VDNumber: "54910481",
    RegisteredPopulation: "1448",
    VotingStationName: "SAKHILE HIGH SCHOOL",
    Address: "MGANDUZWENI  WHITE RIVER",
    Latitude: "-25.2219",
    Longitude: "31.0955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18675",
    WardLookupId: "4311",
    VDNumber: "54910504",
    RegisteredPopulation: "879",
    VotingStationName: "KHULANI COMBINED SCHOOL",
    Address: "JERUSALEM  HAZYVIEW",
    Latitude: "-25.1841",
    Longitude: "31.0969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18676",
    WardLookupId: "4311",
    VDNumber: "54910526",
    RegisteredPopulation: "1673",
    VotingStationName: "LEGOGOTE PRIMARY SCHOOL",
    Address: "1 LEGOGOTE  WHITE RIVER / NSIKAZI",
    Latitude: "-25.1925",
    Longitude: "31.103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18677",
    WardLookupId: "4311",
    VDNumber: "54910706",
    RegisteredPopulation: "1012",
    VotingStationName: "UPLANDS COLLEGE ASSOCIATION",
    Address: "WHITE RIVER  ",
    Latitude: "-25.2957",
    Longitude: "31.005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18678",
    WardLookupId: "4311",
    VDNumber: "54911022",
    RegisteredPopulation: "458",
    VotingStationName: "MGANDUZWENI ST. JOHNS CHURCH",
    Address: "MGANDUZWENI  WHITE RIVER",
    Latitude: "-25.2242",
    Longitude: "31.099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18679",
    WardLookupId: "4311",
    VDNumber: "54911033",
    RegisteredPopulation: "253",
    VotingStationName: "LEGOGOTE BCC CHURCH",
    Address: "LEGOGOTE  WHITE RIVER",
    Latitude: "-25.1937",
    Longitude: "31.1013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18680",
    WardLookupId: "4311",
    VDNumber: "54911189",
    RegisteredPopulation: "279",
    VotingStationName: "MAMALAND DAYCARE CENTRE",
    Address: "SWALALA  HAZYVIEW",
    Latitude: "-25.1806",
    Longitude: "31.1119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18681",
    WardLookupId: "4312",
    VDNumber: "54910144",
    RegisteredPopulation: "820",
    VotingStationName: "FAITH ASSEMBLY CHURCH OF SOUTH AFRICA",
    Address: "1 CHOCHOCHO  HAZYVIEW",
    Latitude: "-25.1835",
    Longitude: "31.1403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18682",
    WardLookupId: "4312",
    VDNumber: "54910436",
    RegisteredPopulation: "1180",
    VotingStationName: "MGCOBANENI PRIMARY SCHOOL",
    Address: "MGCOBANENI  HAZYVIEW",
    Latitude: "-25.2007",
    Longitude: "31.1502",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18683",
    WardLookupId: "4312",
    VDNumber: "54910447",
    RegisteredPopulation: "928",
    VotingStationName: "ENDLUNKULU PRIMARY SCHOOL",
    Address: "JERUSALEM  HAZYVIEW",
    Latitude: "-25.2134",
    Longitude: "31.1073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18684",
    WardLookupId: "4312",
    VDNumber: "54910458",
    RegisteredPopulation: "1045",
    VotingStationName: "MGANDUZWENI PRIMARY SCHOOL",
    Address: "MGANDUZWENI  WHITE RIVER / NSIKAZI",
    Latitude: "-25.2278",
    Longitude: "31.1041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18685",
    WardLookupId: "4312",
    VDNumber: "54910515",
    RegisteredPopulation: "1088",
    VotingStationName: "SAINT JOSEPH CATHOLIC MISSION",
    Address: "JERUSALEM  HAZYVIEW",
    Latitude: "-25.1967",
    Longitude: "31.1069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18686",
    WardLookupId: "4312",
    VDNumber: "54911044",
    RegisteredPopulation: "537",
    VotingStationName: "MGANDUZWENI ASSEMBLY OF GOD CHURCH",
    Address: "MGANDUZWENI  WHITE RIVER",
    Latitude: "-25.2222",
    Longitude: "31.1079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18687",
    WardLookupId: "4312",
    VDNumber: "54911055",
    RegisteredPopulation: "540",
    VotingStationName: "MGCOBANENI ST. JOHNS CHURCH",
    Address: "MGCOBANENI  HAZYVIEW",
    Latitude: "-25.1989",
    Longitude: "31.1446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18688",
    WardLookupId: "4313",
    VDNumber: "54910087",
    RegisteredPopulation: "1697",
    VotingStationName: "MHWAYI PRIMARY SCHOOL",
    Address: "CLAU CLAU TRUST  CLAU-CLAU TRUST  KABOKWENI",
    Latitude: "-25.3647",
    Longitude: "31.1636",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18689",
    WardLookupId: "4313",
    VDNumber: "54910100",
    RegisteredPopulation: "1694",
    VotingStationName: "MACAMELA PRIMARY SCHOOL",
    Address: "MACAMELA  CLAU-CLAU  WHITE RIVER / NSIKAZI",
    Latitude: "-25.3541",
    Longitude: "31.1657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18690",
    WardLookupId: "4313",
    VDNumber: "54910111",
    RegisteredPopulation: "1393",
    VotingStationName: "MBUYANE HIGH SCHOOL",
    Address: "CLAU CLAU TRUST  CLAU CLAU  KABOKWENI",
    Latitude: "-25.3569",
    Longitude: "31.1831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18691",
    WardLookupId: "4313",
    VDNumber: "54910391",
    RegisteredPopulation: "587",
    VotingStationName: "JABULANI PRIMARY SCHOOL",
    Address: "SIPELANYANE TRUST  PIENAAR  KANYAMAZANE",
    Latitude: "-25.3539",
    Longitude: "31.2678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18692",
    WardLookupId: "4313",
    VDNumber: "54910896",
    RegisteredPopulation: "872",
    VotingStationName: "ENKHOKHOKHWENI PRIMARY SCHOOL",
    Address: "CLAU CLAU TRUST  CLAU CLAU  KABOKWENI",
    Latitude: "-25.3608",
    Longitude: "31.1803",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18693",
    WardLookupId: "4313",
    VDNumber: "54910920",
    RegisteredPopulation: "366",
    VotingStationName: "MHLUME SECONDARY SCHOOL",
    Address: "CLAU CLAU TRUST  KABOKWENI  NELSPRUIT",
    Latitude: "-25.3673",
    Longitude: "31.1676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18694",
    WardLookupId: "4313",
    VDNumber: "54911066",
    RegisteredPopulation: "436",
    VotingStationName: "CLAU CLAU COMMUNITY HALL",
    Address: "CLAU CLAU  KABOKWENI  NELSPRUIT",
    Latitude: "-25.3639",
    Longitude: "31.1625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18695",
    WardLookupId: "4314",
    VDNumber: "54910076",
    RegisteredPopulation: "1960",
    VotingStationName: "NYALUNGA PRIMARY SCHOOL",
    Address: "CLAU CLAU TRUST  CLAU-CLAU  KABOKWENI",
    Latitude: "-25.3677",
    Longitude: "31.1519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18696",
    WardLookupId: "4314",
    VDNumber: "54910177",
    RegisteredPopulation: "1344",
    VotingStationName: "COPHETSHENI PRIMARY SCHOOL",
    Address: "1 NGODINI  NGODINI DAM  KABOKWENI",
    Latitude: "-25.3632",
    Longitude: "31.1257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18697",
    WardLookupId: "4314",
    VDNumber: "54910694",
    RegisteredPopulation: "795",
    VotingStationName: "VULEMEHLO PRIMARY SCHOOL",
    Address: "ERMELO TRUST  KABOKWENI",
    Latitude: "-25.3579",
    Longitude: "31.1497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18698",
    WardLookupId: "4314",
    VDNumber: "54910740",
    RegisteredPopulation: "902",
    VotingStationName: "EKUVUKENI APOSTOLIC CHURCH IN ZION",
    Address: "KHATHWANE  ERMELO TRUST  NELSPRUIT",
    Latitude: "-25.3539",
    Longitude: "31.1378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18699",
    WardLookupId: "4314",
    VDNumber: "54910773",
    RegisteredPopulation: "660",
    VotingStationName: "ARISE AND SHINE JEHOVA RAPHA MINISTRY",
    Address: "CLAU CLAU  KABOKWENI  NELSPRUIT",
    Latitude: "-25.3584",
    Longitude: "31.1629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18700",
    WardLookupId: "4314",
    VDNumber: "54910908",
    RegisteredPopulation: "433",
    VotingStationName: "GUDUZA SECONDARY SCHOOL",
    Address: "MBOMBELA  ",
    Latitude: "-25.3559",
    Longitude: "31.1246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18701",
    WardLookupId: "4314",
    VDNumber: "54911077",
    RegisteredPopulation: "479",
    VotingStationName: "CLAU CLAU FAITH MISSION CHURCH",
    Address: "CLAU CLAU  KABOKWENI  NELSPRUIT",
    Latitude: "-25.3661",
    Longitude: "31.1553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18702",
    WardLookupId: "4314",
    VDNumber: "54911088",
    RegisteredPopulation: "734",
    VotingStationName: "INSIGHT PRIMARY SCHOOL",
    Address: "NGODINI DAM  KABOKWENI  NELSPRUIT",
    Latitude: "-25.3573",
    Longitude: "31.1296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18703",
    WardLookupId: "4315",
    VDNumber: "54870015",
    RegisteredPopulation: "214",
    VotingStationName: "WELTEVREDEN COMPOUND HALL",
    Address: "SUDWALA ROAD  MBOMBELA  NELSPRUIT",
    Latitude: "-25.3381",
    Longitude: "30.5564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18704",
    WardLookupId: "4315",
    VDNumber: "54870026",
    RegisteredPopulation: "493",
    VotingStationName: "SCHOEMANSKLOOF FARMERS HALL",
    Address: "1 SCHOEMANSKLOOF ROAD  SCHOEMANSKLOOF  NELSPRUIT",
    Latitude: "-25.394",
    Longitude: "30.6021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18705",
    WardLookupId: "4315",
    VDNumber: "54870037",
    RegisteredPopulation: "996",
    VotingStationName: "NGODWANA RIVER SCHOOL",
    Address: "NGODWANA PLANTATION  NGODWANA  NELSPRUIT",
    Latitude: "-25.5682",
    Longitude: "30.6495",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18706",
    WardLookupId: "4315",
    VDNumber: "54870048",
    RegisteredPopulation: "1057",
    VotingStationName: "OLD JABULANI COMMUNITY HALL",
    Address: "1 NGODWANA STREET  NGODWANA  NELSPRUIT",
    Latitude: "-25.5861",
    Longitude: "30.651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18707",
    WardLookupId: "4315",
    VDNumber: "54870059",
    RegisteredPopulation: "319",
    VotingStationName: "BERLIN FARM SCHOOL",
    Address: "1 BERLIN STREET  BERLIN FARM  NELSPRUIT",
    Latitude: "-25.5641",
    Longitude: "30.7404",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18708",
    WardLookupId: "4315",
    VDNumber: "54870060",
    RegisteredPopulation: "1298",
    VotingStationName: "ALKMAAR NG CHURCH",
    Address: "KERKTERREIN  ALKMAAR  NELSPRUIT",
    Latitude: "-25.4431",
    Longitude: "30.8207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18709",
    WardLookupId: "4315",
    VDNumber: "54870093",
    RegisteredPopulation: "456",
    VotingStationName: "DESTINY COLLEGE",
    Address: "CAIRN  NELSPRUIT",
    Latitude: "-25.4469",
    Longitude: "30.8848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18710",
    WardLookupId: "4315",
    VDNumber: "54870194",
    RegisteredPopulation: "1078",
    VotingStationName: "MBOMBELA HOLIDAY RESORT",
    Address: "N4 ROAD  SCHAGEN  NELSPRUIT",
    Latitude: "-25.428",
    Longitude: "30.7886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18711",
    WardLookupId: "4315",
    VDNumber: "54870284",
    RegisteredPopulation: "733",
    VotingStationName: "LUVOLWETFU PRE-SCHOOL",
    Address: "ELANDSHOEK  NELSPRUIT",
    Latitude: "-25.4961",
    Longitude: "30.7103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18712",
    WardLookupId: "4316",
    VDNumber: "54790106",
    RegisteredPopulation: "1931",
    VotingStationName: "MATSULU PRIMARY SCHOOL",
    Address: "GOVEN MBEKI STREET  MATSULU A  NELSPRUIT",
    Latitude: "-25.5251",
    Longitude: "31.3627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18713",
    WardLookupId: "4316",
    VDNumber: "54790117",
    RegisteredPopulation: "2526",
    VotingStationName: "TSANDZANANI PRIMARY SCHOOL",
    Address: "MADIBA STREET  MATSULU  NELSPRUIT",
    Latitude: "-25.522",
    Longitude: "31.3544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18714",
    WardLookupId: "4316",
    VDNumber: "54790599",
    RegisteredPopulation: "1950",
    VotingStationName: "MATSULU COMMUNITY HALL",
    Address: "MASHABANE STREET  MATSULU  MBOMBELA [NELSPRUIT]",
    Latitude: "-25.5178",
    Longitude: "31.3525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18715",
    WardLookupId: "4316",
    VDNumber: "54790601",
    RegisteredPopulation: "733",
    VotingStationName: "ENZANI PRIMARY SCHOOL",
    Address: "SENATOR STREET  MATSULU  MBOMBELA [NELSPRUIT]",
    Latitude: "-25.52",
    Longitude: "31.3577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18716",
    WardLookupId: "4317",
    VDNumber: "54790083",
    RegisteredPopulation: "1979",
    VotingStationName: "EAST HIGH FURTHER EDUCATION & TRAINING COLLEGE",
    Address: "05  KIEPERSOL STREET  WEST ACRES  NELSPRUIT",
    Latitude: "-25.4725",
    Longitude: "30.9646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18717",
    WardLookupId: "4317",
    VDNumber: "54870183",
    RegisteredPopulation: "2265",
    VotingStationName: "HALLS LABOUR ADMIN OFFICE",
    Address: "MATAFFIN  MBOMBELA  NELSPRUIT",
    Latitude: "-25.4536",
    Longitude: "30.938",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18718",
    WardLookupId: "4317",
    VDNumber: "54870240",
    RegisteredPopulation: "345",
    VotingStationName: "PENRYN COLLEGE",
    Address: "PENRYN  NELSPRUIT",
    Latitude: "-25.4085",
    Longitude: "30.9755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18719",
    WardLookupId: "4317",
    VDNumber: "54870262",
    RegisteredPopulation: "2493",
    VotingStationName: "BOSCHRAND  PRIMARY SCHOOL",
    Address: "PHUMLANI  MSHOLOZI  WHITE RIVER",
    Latitude: "-25.3582",
    Longitude: "30.9889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18720",
    WardLookupId: "4317",
    VDNumber: "54870273",
    RegisteredPopulation: "1062",
    VotingStationName: "JOHN MDLULI PRIMARY SCHOOL",
    Address: "MATAFIN TRUST  NELSPRUIT  NELSPRUIT",
    Latitude: "-25.467",
    Longitude: "30.9308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18721",
    WardLookupId: "4317",
    VDNumber: "54870307",
    RegisteredPopulation: "542",
    VotingStationName: "EVEREST COLLEGE OF EXCELLENCE",
    Address: "EVEREST COLLEGE  NELSPRUIT",
    Latitude: "-25.342",
    Longitude: "30.9806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18722",
    WardLookupId: "4318",
    VDNumber: "54790027",
    RegisteredPopulation: "1520",
    VotingStationName: "ST PETERS SCHOOL",
    Address: "1 NEETHLING STREET  SONHEUWEL TOWNSHIP  NELSPRUIT",
    Latitude: "-25.4883",
    Longitude: "30.9702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18723",
    WardLookupId: "4318",
    VDNumber: "54790094",
    RegisteredPopulation: "4647",
    VotingStationName: "LAERSKOOL LAEVELD",
    Address: "ALIBAMAR STREET  MBOMBELA  NELSPRUIT",
    Latitude: "-25.4936",
    Longitude: "30.9573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18724",
    WardLookupId: "4318",
    VDNumber: "54790702",
    RegisteredPopulation: "1317",
    VotingStationName: "LUTHERAN CHURCH",
    Address: "PERCY FITZPATRICK  WEST ACRES  NELSPRUIT",
    Latitude: "-25.49",
    Longitude: "30.9529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18725",
    WardLookupId: "4318",
    VDNumber: "54790892",
    RegisteredPopulation: "830",
    VotingStationName: "CURRO PRIVATE SCHOOL",
    Address: "THE REST NATURE ESTATE  SONHEUWEL  NELSPRUIT",
    Latitude: "-25.516",
    Longitude: "30.9772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18726",
    WardLookupId: "4318",
    VDNumber: "54870116",
    RegisteredPopulation: "1308",
    VotingStationName: "PLOT 11 CROMDALE HALL",
    Address: "PLOT 11 CROMDALE  CRIMDALE   NELSPRUIT",
    Latitude: "-25.5249",
    Longitude: "30.9539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18727",
    WardLookupId: "4319",
    VDNumber: "54790016",
    RegisteredPopulation: "2034",
    VotingStationName: "NELSPRUIT CIVIC CENTRE",
    Address: "1 NEL STREET  SONHEUWEL TOWN  NELSPRUIT",
    Latitude: "-25.4755",
    Longitude: "30.9752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18728",
    WardLookupId: "4319",
    VDNumber: "54790072",
    RegisteredPopulation: "1713",
    VotingStationName: "EHLANZENI FET COLLEGE (NELSPRUIT)",
    Address: "C/O KORAALBOOM/KAAPSCHE HOOP  WEST ACRES TOWNSHIP  NELSPRUIT",
    Latitude: "-25.4718",
    Longitude: "30.9597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18729",
    WardLookupId: "4319",
    VDNumber: "54790803",
    RegisteredPopulation: "690",
    VotingStationName: "HOÃ‹RSKOOL BERGVLAM",
    Address: "NELSPRUIT  ",
    Latitude: "-25.4813",
    Longitude: "30.965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18730",
    WardLookupId: "4319",
    VDNumber: "54790904",
    RegisteredPopulation: "703",
    VotingStationName: "NELSPRUIT METHODIST CHURCH",
    Address: "NELSPRUIT  MBOMBELA  NELSPRUIT",
    Latitude: "-25.4767",
    Longitude: "30.9826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18731",
    WardLookupId: "4320",
    VDNumber: "54790061",
    RegisteredPopulation: "1561",
    VotingStationName: "VALENCIA PARK COMMUNITY HALL",
    Address: "1 DELPHINIUM STREET  VALENCIA PARK  NELSPRUIT",
    Latitude: "-25.4669",
    Longitude: "30.994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18732",
    WardLookupId: "4320",
    VDNumber: "54790421",
    RegisteredPopulation: "3225",
    VotingStationName: "LOWVELD COUNTRY CLUB",
    Address: "AURORA STREET  STEILTES  NELSPRUIT",
    Latitude: "-25.5004",
    Longitude: "31.0026",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18733",
    WardLookupId: "4320",
    VDNumber: "54790432",
    RegisteredPopulation: "3608",
    VotingStationName: "BERGLAND PRIMARY SCHOOL",
    Address: "NELSPRUIT  MBOMBELA  NELSPRUIT",
    Latitude: "-25.4924",
    Longitude: "30.9893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18734",
    WardLookupId: "4320",
    VDNumber: "54790487",
    RegisteredPopulation: "985",
    VotingStationName: "NELSVILLE COMMUNITY CENTRE",
    Address: "NELSVILLE  NELSPRUIT",
    Latitude: "-25.4783",
    Longitude: "31.0105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18735",
    WardLookupId: "4320",
    VDNumber: "54870105",
    RegisteredPopulation: "93",
    VotingStationName: "DUMA COUNRTY LODGE",
    Address: "N4 ROAD  KARINO  NELSPRUIT",
    Latitude: "-25.488",
    Longitude: "31.1463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18736",
    WardLookupId: "4320",
    VDNumber: "54870138",
    RegisteredPopulation: "582",
    VotingStationName: "MAYFERN COMMUNITY HALL",
    Address: "MAYFERN  NELSPRUIT",
    Latitude: "-25.4738",
    Longitude: "31.0387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18737",
    WardLookupId: "4320",
    VDNumber: "54870149",
    RegisteredPopulation: "1153",
    VotingStationName: "HLATI FARM HALL",
    Address: "HIGHLANDS  FARM  HIGHLANDS  NELSPRUIT",
    Latitude: "-25.5405",
    Longitude: "31.0208",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18738",
    WardLookupId: "4320",
    VDNumber: "54870239",
    RegisteredPopulation: "148",
    VotingStationName: "DE HOOP (TENT)",
    Address: "MARA (N4 ROAD)  DE HOOP  MBOMBELA",
    Latitude: "-25.5011",
    Longitude: "31.1634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18739",
    WardLookupId: "4320",
    VDNumber: "54870251",
    RegisteredPopulation: "362",
    VotingStationName: "KIAORA SHOPPING CENTRE",
    Address: "N4 ROAD  KIA ORA/KARINO  NELSPRUIT",
    Latitude: "-25.4727",
    Longitude: "31.0906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18740",
    WardLookupId: "4321",
    VDNumber: "54790230",
    RegisteredPopulation: "3173",
    VotingStationName: "TEKWANE PRIMARY SCHOOL",
    Address: "TEKWANE SOUTH  MBOMBELA [NELSPRUIT]",
    Latitude: "-25.4812",
    Longitude: "31.1446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18741",
    WardLookupId: "4321",
    VDNumber: "54790498",
    RegisteredPopulation: "2041",
    VotingStationName: "KAMAGUGU PRIMARY SCHOOL",
    Address: "NGWENYA STREET  KAMAGUGU  NELSPRUIT",
    Latitude: "-25.4555",
    Longitude: "31.0046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18742",
    WardLookupId: "4321",
    VDNumber: "54790667",
    RegisteredPopulation: "1239",
    VotingStationName: "TEKWANE NORTH COMMUNITY HALL",
    Address: "TEKWANE NORTH  MSOGWABA",
    Latitude: "-25.4317",
    Longitude: "31.1256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18743",
    WardLookupId: "4321",
    VDNumber: "54790814",
    RegisteredPopulation: "188",
    VotingStationName: "RIVERSIDE EBHUBESINI",
    Address: "RIVERSIDE  RIVERSIDE MSOGWABA  MSOGWABA",
    Latitude: "-25.4358",
    Longitude: "31.1497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18744",
    WardLookupId: "4321",
    VDNumber: "54790825",
    RegisteredPopulation: "909",
    VotingStationName: "WORD OF LIFE BIBLE CHURCH",
    Address: "ENTOKOZWENI  TEKWANE SOUTH  NELSPRUIT",
    Latitude: "-25.4752",
    Longitude: "31.1524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18745",
    WardLookupId: "4321",
    VDNumber: "54790926",
    RegisteredPopulation: "303",
    VotingStationName: "TEKWANE NORTH PHASE1 CHURCH",
    Address: "TEKWANE NORTH PHASE1 RDP HOUSE  TEKWANE NORTH  TEKWANE NORTH",
    Latitude: "-25.4396",
    Longitude: "31.1365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18746",
    WardLookupId: "4321",
    VDNumber: "54870172",
    RegisteredPopulation: "594",
    VotingStationName: "CHURCH ON THE HILL",
    Address: "KAMAGUGU  MBOMBELA [NELSPRUIT]  MBOMBELA [NELSPRUIT]",
    Latitude: "-25.4472",
    Longitude: "31.0132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18747",
    WardLookupId: "4321",
    VDNumber: "54870295",
    RegisteredPopulation: "732",
    VotingStationName: "MERIDIAN PRIVATE SCHOOL",
    Address: "KARINO  KARINO ESTATE  NELSPRUIT",
    Latitude: "-25.4649",
    Longitude: "31.1061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18748",
    WardLookupId: "4322",
    VDNumber: "54790229",
    RegisteredPopulation: "1159",
    VotingStationName: "MGWENYA COLLEGE",
    Address: "1 KANYAMAZANE STREET  KANYAMAZANE  NELSPRUIT",
    Latitude: "-25.4841",
    Longitude: "31.1809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18749",
    WardLookupId: "4322",
    VDNumber: "54790241",
    RegisteredPopulation: "1282",
    VotingStationName: "CATFULANI PRIMARY SCHOOL",
    Address: "24 MOYA STREET KANYAMAZANE 1214  KANYAMAZANE  NELSPRUIT",
    Latitude: "-25.4698",
    Longitude: "31.1799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18750",
    WardLookupId: "4322",
    VDNumber: "54790443",
    RegisteredPopulation: "2707",
    VotingStationName: "THANDULWAZI PRIMARY SCHOOL",
    Address: "2211 KANYAMAZANE STREET  KANYAMAZANE  NELSPRUIT",
    Latitude: "-25.4775",
    Longitude: "31.1814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18751",
    WardLookupId: "4322",
    VDNumber: "54790454",
    RegisteredPopulation: "1275",
    VotingStationName: "Z B KUNENE HIGH SCHOOL",
    Address: "1 KANYAMAZANE STREET  KANYAMAZANE  NELSPRUIT",
    Latitude: "-25.4704",
    Longitude: "31.1726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18752",
    WardLookupId: "4322",
    VDNumber: "54790500",
    RegisteredPopulation: "751",
    VotingStationName: "EHLANZENI FET COLLEGE (KANYAMAZANE CAMPUS)",
    Address: "KANYAMAZANE  NELSPRUIT",
    Latitude: "-25.4784",
    Longitude: "31.1719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18753",
    WardLookupId: "4323",
    VDNumber: "54790274",
    RegisteredPopulation: "2782",
    VotingStationName: "KHALIPHANI SECONDARY SCHOOL",
    Address: "KANYAMAZANE  MBOMBELA  NELSPRUIT",
    Latitude: "-25.4558",
    Longitude: "31.1696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18754",
    WardLookupId: "4323",
    VDNumber: "54790285",
    RegisteredPopulation: "2207",
    VotingStationName: "LEKAZI PRIMARY SCHOOL",
    Address: "1 KANYAMAZANE STREET  KANYAMAZANE  NELSPRUIT",
    Latitude: "-25.4478",
    Longitude: "31.1709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18755",
    WardLookupId: "4323",
    VDNumber: "54790656",
    RegisteredPopulation: "1613",
    VotingStationName: "ENTOKOZWENI PRE-SCHOOL",
    Address: "ENTOKOZWENI  MP322 - MBOMBELA [NELSPRUIT]",
    Latitude: "-25.4485",
    Longitude: "31.1652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18756",
    WardLookupId: "4323",
    VDNumber: "54790713",
    RegisteredPopulation: "504",
    VotingStationName: "ALLIANCE CHURCH",
    Address: "KANYAMAZANE  NELSPRUIT",
    Latitude: "-25.4536",
    Longitude: "31.1723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18757",
    WardLookupId: "4324",
    VDNumber: "54790252",
    RegisteredPopulation: "1978",
    VotingStationName: "KANYAMAZANE COMMUNITY HALL",
    Address: "1 KANYAMAZANE  NELSPRUIT",
    Latitude: "-25.4659",
    Longitude: "31.1833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18758",
    WardLookupId: "4324",
    VDNumber: "54790511",
    RegisteredPopulation: "1182",
    VotingStationName: "TENTELENI PRIMARY SCHOOL",
    Address: "MAKOTAPENI STREET  KANYAMAZANE  KANYAMAZANE",
    Latitude: "-25.4597",
    Longitude: "31.1839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18759",
    WardLookupId: "4324",
    VDNumber: "54790533",
    RegisteredPopulation: "1004",
    VotingStationName: "MARULA PARK PRE-SCHOOL",
    Address: "KANYAMAZANE-6  DAANTJIE TRIBAL",
    Latitude: "-25.4445",
    Longitude: "31.176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18760",
    WardLookupId: "4324",
    VDNumber: "54790612",
    RegisteredPopulation: "819",
    VotingStationName: "LEKAZI CENTRAL SECONDARY SCHOOL",
    Address: "MBOMBELA [NELSPRUIT]  ",
    Latitude: "-25.4567",
    Longitude: "31.18",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18761",
    WardLookupId: "4324",
    VDNumber: "54790645",
    RegisteredPopulation: "1391",
    VotingStationName: "TIPHEMBELENI PRIMARY SCHOOL",
    Address: "KANYAMAZANE  NELSPRUIT",
    Latitude: "-25.4559",
    Longitude: "31.1783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18762",
    WardLookupId: "4324",
    VDNumber: "54790915",
    RegisteredPopulation: "340",
    VotingStationName: "DELIVERANCE CHRISTIAN CHURCH",
    Address: "DAANTJIE  MBOMBELA  DAANTJIE",
    Latitude: "-25.442",
    Longitude: "31.1843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18763",
    WardLookupId: "4325",
    VDNumber: "54790320",
    RegisteredPopulation: "2191",
    VotingStationName: "YEDWA PRIMARY SCHOOL",
    Address: "1 STREET MSOGWABA  MSOGWABA  NELSPRUIT",
    Latitude: "-25.4284",
    Longitude: "31.159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18764",
    WardLookupId: "4325",
    VDNumber: "54790331",
    RegisteredPopulation: "2365",
    VotingStationName: "MSOGWABA PRIMARY SCHOOL",
    Address: "1 MSOGWABA STREET  MSOGWABA  NELSPRUIT",
    Latitude: "-25.4265",
    Longitude: "31.1729",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18765",
    WardLookupId: "4325",
    VDNumber: "54790364",
    RegisteredPopulation: "1073",
    VotingStationName: "ETIGUBHINI 12 APOSTOLIC CHURCH",
    Address: "119 MSOGWABA STREET  MSOGWABA  NELSPRUIT",
    Latitude: "-25.4221",
    Longitude: "31.1691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18766",
    WardLookupId: "4325",
    VDNumber: "54790836",
    RegisteredPopulation: "849",
    VotingStationName: "EJ SINGWANE SECONDARY SCHOOL",
    Address: "MSOGWABA  KABOKWENI  NELSPRUIT",
    Latitude: "-25.4305",
    Longitude: "31.172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18767",
    WardLookupId: "4325",
    VDNumber: "54790847",
    RegisteredPopulation: "725",
    VotingStationName: "NKOMENI CHURCH",
    Address: "NKOMENI  DAANTJIE  NELSPRUIT",
    Latitude: "-25.4344",
    Longitude: "31.1724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18768",
    WardLookupId: "4326",
    VDNumber: "54790296",
    RegisteredPopulation: "1641",
    VotingStationName: "PHOLANI PRIMARY SCHOOL",
    Address: "DAANTJIE  DAANTJIE TRIBAL AUTHORITY",
    Latitude: "-25.4535",
    Longitude: "31.1911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18769",
    WardLookupId: "4326",
    VDNumber: "54790319",
    RegisteredPopulation: "2879",
    VotingStationName: "MPAKENI TRADITIONAL COUNCIL",
    Address: "DAANTJIE STREET  MPAKENI  MPAKENI",
    Latitude: "-25.4349",
    Longitude: "31.1839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18770",
    WardLookupId: "4326",
    VDNumber: "54790870",
    RegisteredPopulation: "679",
    VotingStationName: "SIBUYILE PRIMARY SCHOOL",
    Address: "SIBUYILE VILLAGE  DAANTJIE  NELSPRUIT",
    Latitude: "-25.4349",
    Longitude: "31.1946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18771",
    WardLookupId: "4327",
    VDNumber: "54790184",
    RegisteredPopulation: "1485",
    VotingStationName: "LEPESE PRIMARY SCHOOL",
    Address: "LUPHISI  MPAKENI TRIBAL AUTHORITY",
    Latitude: "-25.4049",
    Longitude: "31.2796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18772",
    WardLookupId: "4327",
    VDNumber: "54790195",
    RegisteredPopulation: "872",
    VotingStationName: "MPAKENI PRIMARY SCHOOL",
    Address: "1 MPAKENI  NELSPRUIT",
    Latitude: "-25.4906",
    Longitude: "31.2473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18773",
    WardLookupId: "4327",
    VDNumber: "54790207",
    RegisteredPopulation: "1857",
    VotingStationName: "BONGINHLANHLA HIGH SCHOOL",
    Address: "1 DAANJTIE STREET  DAANTJIE  NELSPRUIT",
    Latitude: "-25.4376",
    Longitude: "31.2112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18774",
    WardLookupId: "4327",
    VDNumber: "54790308",
    RegisteredPopulation: "1483",
    VotingStationName: "SIBUYILE LUTHERAN CHURCH",
    Address: "SIBUYILE  DAANTJIE  NELSPRUIT",
    Latitude: "-25.4303",
    Longitude: "31.204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18775",
    WardLookupId: "4327",
    VDNumber: "54790476",
    RegisteredPopulation: "57",
    VotingStationName: "MTHETHOMUSHA NATURE RESERVE",
    Address: "PICNIC SITE  MPAKENI  MPAKENI",
    Latitude: "-25.4619",
    Longitude: "31.2071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18776",
    WardLookupId: "4327",
    VDNumber: "54790757",
    RegisteredPopulation: "697",
    VotingStationName: "NCAKINI HIGH SCHOOL",
    Address: "DAANTJIE TRUST  PIENAAR  NELSPRUIT",
    Latitude: "-25.4314",
    Longitude: "31.2289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18777",
    WardLookupId: "4327",
    VDNumber: "54790768",
    RegisteredPopulation: "568",
    VotingStationName: "THE NEW PARADISE CHURCH IN ZION",
    Address: "1 DAANTJIE TRUST  SIBUYILE  NELSPRUIT",
    Latitude: "-25.4328",
    Longitude: "31.2023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18778",
    WardLookupId: "4327",
    VDNumber: "54790858",
    RegisteredPopulation: "964",
    VotingStationName: "SIYAKHULA PRIMARY SCHOOL",
    Address: "DAANTJIE  NELSPRUIT",
    Latitude: "-25.4345",
    Longitude: "31.2111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18779",
    WardLookupId: "4328",
    VDNumber: "54740022",
    RegisteredPopulation: "1511",
    VotingStationName: "MAJIKA PRIMARY SCHOOL",
    Address: "1 SANDRIVER STREET  SANDRIVER VILLAGE  HAZYVIEW",
    Latitude: "-25.0943",
    Longitude: "31.141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18780",
    WardLookupId: "4328",
    VDNumber: "54740055",
    RegisteredPopulation: "1552",
    VotingStationName: "INKUZI HIGH SCHOOL",
    Address: "NUMBI  NUMBI TRUST  HAZYVIEW",
    Latitude: "-25.1237",
    Longitude: "31.1493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18781",
    WardLookupId: "4328",
    VDNumber: "54740088",
    RegisteredPopulation: "1643",
    VotingStationName: "NKAMBENI PRIMARY SCHOOL",
    Address: "SAND RIVER VILAGE  NKAMBENI TRUST  HAZYVIEW",
    Latitude: "-25.0799",
    Longitude: "31.1441",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18782",
    WardLookupId: "4328",
    VDNumber: "54740123",
    RegisteredPopulation: "1134",
    VotingStationName: "FAITH MISSION CHURCH",
    Address: "154 SHABALALA  HAZYVIEW",
    Latitude: "-25.0512",
    Longitude: "31.162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18783",
    WardLookupId: "4328",
    VDNumber: "54740145",
    RegisteredPopulation: "943",
    VotingStationName: "ALLIANCE CHURCH",
    Address: "SAND RIVER VILLAGE  MAJIKA   MBOMBELA [NELSPRUIT]",
    Latitude: "-25.0853",
    Longitude: "31.1428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18784",
    WardLookupId: "4328",
    VDNumber: "54740190",
    RegisteredPopulation: "622",
    VotingStationName: "MPUNZANA PRIMARY SCHOOL",
    Address: "SHABALALA TRUST  HAZYVIEW  HAZYVIEW",
    Latitude: "-25.0586",
    Longitude: "31.1627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18785",
    WardLookupId: "4328",
    VDNumber: "54740235",
    RegisteredPopulation: "606",
    VotingStationName: "SIYAMUKELA PRIMARY SCHOOL",
    Address: "SHABALALA TRUST  HAZYVIEW",
    Latitude: "-25.0471",
    Longitude: "31.1797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18786",
    WardLookupId: "4328",
    VDNumber: "54740246",
    RegisteredPopulation: "478",
    VotingStationName: "ELEPHANT PRIVATE COLLEGE",
    Address: "MAJIKA  HAZYVIEW",
    Latitude: "-25.0965",
    Longitude: "31.1328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18787",
    WardLookupId: "4328",
    VDNumber: "54740279",
    RegisteredPopulation: "168",
    VotingStationName: "MTHIMBA FET COLLEGE SUB-OFFICE",
    Address: "HAZY PARK  NKAMBENI  NKAMBENI TRADITIONAL COUNCIL",
    Latitude: "-25.0789",
    Longitude: "31.1293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18788",
    WardLookupId: "4329",
    VDNumber: "54790353",
    RegisteredPopulation: "1878",
    VotingStationName: "AFRICAN GAZA CHURCH",
    Address:
      "01018 MSOGWABA (NEXT TO KAHLOPHE CHU  CITY OF MBOMBELA  MSOGWABA TRIBAL",
    Latitude: "-25.4217",
    Longitude: "31.1611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18789",
    WardLookupId: "4329",
    VDNumber: "54790634",
    RegisteredPopulation: "2219",
    VotingStationName: "MAMINDZA PRIMARY SCHOOL",
    Address:
      "EBUHLENI VILLAGE  MAMELODI (MSOGWABA)  MP322 - MBOMBELA [NELSPRUIT]",
    Latitude: "-25.4097",
    Longitude: "31.1449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18790",
    WardLookupId: "4329",
    VDNumber: "54790881",
    RegisteredPopulation: "420",
    VotingStationName: "HILLARIA MTHETHWA SECONDARY SCHOOL",
    Address: "MSOGWABA  NELSPRUIT",
    Latitude: "-25.4254",
    Longitude: "31.1478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18791",
    WardLookupId: "4330",
    VDNumber: "54790162",
    RegisteredPopulation: "2159",
    VotingStationName: "SUKUMANI PRIMARY SCHOOL",
    Address: "857B STAND NO 857B  MATSULU  NELSPRUIT",
    Latitude: "-25.5096",
    Longitude: "31.3237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18792",
    WardLookupId: "4330",
    VDNumber: "54790173",
    RegisteredPopulation: "1605",
    VotingStationName: "SEHLULILE PRIMARY SCHOOL",
    Address: "1286B STAND NO 1286B  MATSULU B  NELSPRUIT",
    Latitude: "-25.5012",
    Longitude: "31.329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18793",
    WardLookupId: "4330",
    VDNumber: "54790555",
    RegisteredPopulation: "1678",
    VotingStationName: "BENJAMIN PRIMARY SCHOOL",
    Address: "MBOMBELA [NELSPRUIT]  ",
    Latitude: "-25.5055",
    Longitude: "31.3187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18794",
    WardLookupId: "4330",
    VDNumber: "54790566",
    RegisteredPopulation: "1203",
    VotingStationName: "MATSULU B COMMUNITY CENTRE",
    Address: "MBOMBELA [NELSPRUIT]  ",
    Latitude: "-25.5067",
    Longitude: "31.3289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18795",
    WardLookupId: "4330",
    VDNumber: "54790577",
    RegisteredPopulation: "1487",
    VotingStationName: "SIBONGILE PRIMARY SCHOOL",
    Address: "MATSULU  NELSPRUIT  MATSULU",
    Latitude: "-25.5305",
    Longitude: "31.3123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18796",
    WardLookupId: "4331",
    VDNumber: "54790139",
    RegisteredPopulation: "3541",
    VotingStationName: "FUNINDLELA PRIMARY SCHOOL",
    Address: "MATSULU  NELSPRUIT",
    Latitude: "-25.5033",
    Longitude: "31.3493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18797",
    WardLookupId: "4331",
    VDNumber: "54790140",
    RegisteredPopulation: "2476",
    VotingStationName: "TAKHALENI PRIMARY SCHOOL",
    Address: "STAND NO 671A  MATSULU  NELSPRUIT",
    Latitude: "-25.5139",
    Longitude: "31.3401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18798",
    WardLookupId: "4331",
    VDNumber: "54790544",
    RegisteredPopulation: "2116",
    VotingStationName: "SIBUSISWE HIGH SCHOOL",
    Address: "MATSULU  MBOMBELA [NELSPRUIT]",
    Latitude: "-25.5056",
    Longitude: "31.3407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18799",
    WardLookupId: "4331",
    VDNumber: "54790690",
    RegisteredPopulation: "880",
    VotingStationName: "MASITAKHE HIGH SCHOOL",
    Address: "MATSULU  NELSPRUIT",
    Latitude: "-25.5048",
    Longitude: "31.3517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18800",
    WardLookupId: "4332",
    VDNumber: "54790218",
    RegisteredPopulation: "3210",
    VotingStationName: "SOMCUBA PRIMARY SCHOOL",
    Address: "91 MSOGWABA  PIENAAR TRUST  MBOMBELA (NELSPRUIT)",
    Latitude: "-25.4055",
    Longitude: "31.1732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18801",
    WardLookupId: "4332",
    VDNumber: "54790386",
    RegisteredPopulation: "2043",
    VotingStationName: "FAITH ASSEMBLY CHURCH",
    Address: "MSOGWABA  NELSPRUIT",
    Latitude: "-25.4221",
    Longitude: "31.1743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18802",
    WardLookupId: "4332",
    VDNumber: "54790588",
    RegisteredPopulation: "1286",
    VotingStationName: "SHISHILA PRIMARY SCHOOL",
    Address: "SHISHILA  MSOGWABA  MBOMBELA [NELSPRUIT]",
    Latitude: "-25.423",
    Longitude: "31.1791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18803",
    WardLookupId: "4332",
    VDNumber: "54790724",
    RegisteredPopulation: "347",
    VotingStationName: "MKHIWENI ST. JOHNS CHURCH",
    Address: "MKHIWENI  MSOGWABA  NELSPRUIT",
    Latitude: "-25.4305",
    Longitude: "31.1811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18804",
    WardLookupId: "4332",
    VDNumber: "54790869",
    RegisteredPopulation: "636",
    VotingStationName: "GEDLEMBANE HIGH SCHOOL",
    Address: "GEDLEMBANE, PINAAR  DAANTJIE TRUST  NELSPRUIT",
    Latitude: "-25.4019",
    Longitude: "31.1657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18805",
    WardLookupId: "4333",
    VDNumber: "54830011",
    RegisteredPopulation: "2615",
    VotingStationName: "WHITE RIVER TOWN HALL",
    Address: "MGIYENI STREET  WHITE RIVER CENTRAL  WHITE RIVER",
    Latitude: "-25.3286",
    Longitude: "31.018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18806",
    WardLookupId: "4333",
    VDNumber: "54830022",
    RegisteredPopulation: "3942",
    VotingStationName: "ROB FERREIRA HIGH SCHOOL",
    Address: "WHITE RIVER  MBOMBELA  WHITE RIVER",
    Latitude: "-25.3265",
    Longitude: "31.0118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18807",
    WardLookupId: "4333",
    VDNumber: "54830099",
    RegisteredPopulation: "868",
    VotingStationName: "APOSTOLIC FAITH MISSION CHURCH",
    Address: "WHITE RIVER  MBOMBELA [NELSPRUIT]",
    Latitude: "-25.3244",
    Longitude: "31.0218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18808",
    WardLookupId: "4333",
    VDNumber: "54830112",
    RegisteredPopulation: "608",
    VotingStationName: "MBOMBELA PUBLIC SAFETY",
    Address: "WHITE RIVER  MBOMBELA",
    Latitude: "-25.336",
    Longitude: "31.0125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18809",
    WardLookupId: "4334",
    VDNumber: "54910212",
    RegisteredPopulation: "834",
    VotingStationName: "MTHAYIZA PRIMARY SCHOOL",
    Address: "MTHAYIZA  GUTSHWA-KOP  WHITE RIVER / NSIKAZI",
    Latitude: "-25.3147",
    Longitude: "31.161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18810",
    WardLookupId: "4334",
    VDNumber: "54910234",
    RegisteredPopulation: "1613",
    VotingStationName: "MAPHAKAMA PRIMARY SCHOOL",
    Address: "NKOHLAKALO MAIN ROAD  NKOHLAKALO  WHITE RIVER / NSIKAZI",
    Latitude: "-25.332",
    Longitude: "31.1495",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18811",
    WardLookupId: "4334",
    VDNumber: "54910245",
    RegisteredPopulation: "894",
    VotingStationName: "SIBHULO SECONDARY SCHOOL",
    Address: "BHUGA  BHUGA TRUST  KABOKWENI",
    Latitude: "-25.3154",
    Longitude: "31.1544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18812",
    WardLookupId: "4334",
    VDNumber: "54910256",
    RegisteredPopulation: "848",
    VotingStationName: "CHRISTIAN APOSTOLIC CHURCH IN ZION CITY",
    Address: "GUTSHWA, JULUKA  KABOKWENI  NELSPRUIT",
    Latitude: "-25.3069",
    Longitude: "31.1527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18813",
    WardLookupId: "4334",
    VDNumber: "54910278",
    RegisteredPopulation: "1224",
    VotingStationName: "MAKHAHLELA PRIMARY SCHOOL",
    Address: "MAKHALELA  GUTSHWA  WHITE RIVER / NSIKAZI",
    Latitude: "-25.3104",
    Longitude: "31.1453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18814",
    WardLookupId: "4334",
    VDNumber: "54910728",
    RegisteredPopulation: "930",
    VotingStationName: "ALLIANCE CHURCH",
    Address: "HALFWAY ALLIENCA  BHUGA  KABOKWENI",
    Latitude: "-25.3254",
    Longitude: "31.1544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18815",
    WardLookupId: "4334",
    VDNumber: "54911099",
    RegisteredPopulation: "483",
    VotingStationName: "NKOHLAKALO HOUSE OF FAITH CHURCH",
    Address: "NKOHLAKALO  KABOKWENI  NELSPRUIT",
    Latitude: "-25.3302",
    Longitude: "31.1434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18816",
    WardLookupId: "4334",
    VDNumber: "54911101",
    RegisteredPopulation: "294",
    VotingStationName: "ZIKODZE SECONDARY SCHOOL",
    Address: "GUTSHWA TRUST  KABOKWENI  NELSPRUIT",
    Latitude: "-25.2963",
    Longitude: "31.1433",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18817",
    WardLookupId: "4335",
    VDNumber: "54830033",
    RegisteredPopulation: "1965",
    VotingStationName: "KABOKWENI MUNICIPAL WORKSHOP",
    Address: "KABOKWENI  ",
    Latitude: "-25.3497",
    Longitude: "31.1134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18818",
    WardLookupId: "4335",
    VDNumber: "54830044",
    RegisteredPopulation: "2611",
    VotingStationName: "DWALENI PRIMARY SCHOOL",
    Address: "DWALENI TRUST  KABOKWENI  WHITE RIVER",
    Latitude: "-25.3619",
    Longitude: "31.0996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18819",
    WardLookupId: "4335",
    VDNumber: "54830077",
    RegisteredPopulation: "1347",
    VotingStationName: "THEMBA HOSPITAL CHURCH",
    Address: "1 KABOKWENI MAIN ROAD  KABOKWENI  WHITE RIVER",
    Latitude: "-25.3455",
    Longitude: "31.121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18820",
    WardLookupId: "4335",
    VDNumber: "54830134",
    RegisteredPopulation: "585",
    VotingStationName: "GUTSHWA PRIMARY SCHOOL",
    Address: "GUTSHWA  KABOKWENI",
    Latitude: "-25.3162",
    Longitude: "31.1438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18821",
    WardLookupId: "4335",
    VDNumber: "54830145",
    RegisteredPopulation: "745",
    VotingStationName: "TEKA TAKHO CHURCH ON THE HILL",
    Address: "TEKA TAKHO  KABOKWENI  KABOKWENI",
    Latitude: "-25.3448",
    Longitude: "31.1132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18822",
    WardLookupId: "4336",
    VDNumber: "54830055",
    RegisteredPopulation: "2055",
    VotingStationName: "KABOKWENI METHODIST CHURCH",
    Address: "1 KABOKWENI  ",
    Latitude: "-25.329",
    Longitude: "31.1386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18823",
    WardLookupId: "4336",
    VDNumber: "54830066",
    RegisteredPopulation: "1815",
    VotingStationName: "KABOKWENI COMMUNITY HALL",
    Address: "1 KABOKWENI STREET  KABOKWENI  WHITE RIVER",
    Latitude: "-25.3437",
    Longitude: "31.13",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18824",
    WardLookupId: "4336",
    VDNumber: "54830101",
    RegisteredPopulation: "877",
    VotingStationName: "KHUTSALANI HIGH SCHOOL",
    Address: "NGODINI TRUST  KABOKWENI  NELSPRUIT",
    Latitude: "-25.3418",
    Longitude: "31.1313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18825",
    WardLookupId: "4336",
    VDNumber: "54830156",
    RegisteredPopulation: "465",
    VotingStationName: "VULINDLELA SECONDARY SCHOOL",
    Address: "KABOKWENI  NELSPRUIT",
    Latitude: "-25.3495",
    Longitude: "31.1317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18826",
    WardLookupId: "4336",
    VDNumber: "54830167",
    RegisteredPopulation: "571",
    VotingStationName: "FAITH ASSEMBLY CHURCH OF SA",
    Address: "KABOKWENI  ",
    Latitude: "-25.3242",
    Longitude: "31.139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18827",
    WardLookupId: "4336",
    VDNumber: "54910829",
    RegisteredPopulation: "743",
    VotingStationName: "NKOHLAKALO FAITH ASSEMBLY CHURCH",
    Address: "NKOHLAKALO  KABOKWENI  NELSPRUIT",
    Latitude: "-25.3366",
    Longitude: "31.1439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18828",
    WardLookupId: "4337",
    VDNumber: "54910380",
    RegisteredPopulation: "1462",
    VotingStationName: "MABUZA HOME IMPROVERS",
    Address: "54B MALEKUTU  MALEKUTU VILLAGE  MBOMBELA",
    Latitude: "-25.2653",
    Longitude: "31.1998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18829",
    WardLookupId: "4337",
    VDNumber: "54910403",
    RegisteredPopulation: "1087",
    VotingStationName: "CHWENI PRIMARY SCHOOL",
    Address: "CHWENI  WHITE RIVER / NSIKAZI",
    Latitude: "-25.2347",
    Longitude: "31.1488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18830",
    WardLookupId: "4337",
    VDNumber: "54910414",
    RegisteredPopulation: "1197",
    VotingStationName: "MANDUNDU PRIMARY SCHOOL",
    Address: "MAKOKO  WHITE RIVER / NSIKAZI",
    Latitude: "-25.2243",
    Longitude: "31.2223",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18831",
    WardLookupId: "4337",
    VDNumber: "54910425",
    RegisteredPopulation: "1205",
    VotingStationName: "KUSILE PRIMARY SCHOOL",
    Address: "PHAMENI   PHAMENI TRUST  WHITE RIVER / NSIKAZI",
    Latitude: "-25.2139",
    Longitude: "31.1921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18832",
    WardLookupId: "4337",
    VDNumber: "54910852",
    RegisteredPopulation: "579",
    VotingStationName: "UNITING REFORMED CHURCH IN SOUTH AFRICA(DUTCH)",
    Address: "CHWENI TRUST  NSIKAZI",
    Latitude: "-25.2386",
    Longitude: "31.1495",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18833",
    WardLookupId: "4337",
    VDNumber: "54911156",
    RegisteredPopulation: "577",
    VotingStationName: "MAKOKO PRIMARY SCHOOL",
    Address: "MAKOKO TRUST  MAKOKO  HAZYVIEW",
    Latitude: "-25.2319",
    Longitude: "31.2131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18834",
    WardLookupId: "4338",
    VDNumber: "54910188",
    RegisteredPopulation: "1033",
    VotingStationName: "MLILO PRIMARY SCHOOL",
    Address: "1 ERMELO TRUST  KABOKWENI",
    Latitude: "-25.3484",
    Longitude: "31.1366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18835",
    WardLookupId: "4338",
    VDNumber: "54910223",
    RegisteredPopulation: "1153",
    VotingStationName: "MPOPOLI PRIMARY SCHOOL",
    Address: "NKOHLAKALO  KABOKWENI  WHITE RIVER / NSIKAZI",
    Latitude: "-25.3359",
    Longitude: "31.1485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18836",
    WardLookupId: "4338",
    VDNumber: "54910267",
    RegisteredPopulation: "1300",
    VotingStationName: "SIFUNINDLELA PRIMARY SCHOOL",
    Address: "SIFUNINDLELA  BHUGA  WHITE RIVER / NSIKAZI",
    Latitude: "-25.3093",
    Longitude: "31.1675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18837",
    WardLookupId: "4338",
    VDNumber: "54910683",
    RegisteredPopulation: "400",
    VotingStationName: "MVANGATINI PRIMARY SCHOOL",
    Address: "MVANGATINI  LUPHISI  MBOMBELA (NELSPRUIT)",
    Latitude: "-25.32",
    Longitude: "31.2182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18838",
    WardLookupId: "4338",
    VDNumber: "54910739",
    RegisteredPopulation: "1181",
    VotingStationName: "EMOUSE CHURCH",
    Address: "NKOHLAKALO  KABOKWENI  WHITE RIVER",
    Latitude: "-25.3257",
    Longitude: "31.1588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18839",
    WardLookupId: "4338",
    VDNumber: "54910942",
    RegisteredPopulation: "436",
    VotingStationName: "ASSEMBLIES OF CHRIST",
    Address: "SIFUNINDLELA  KABOKWENI  WHITE RIVER",
    Latitude: "-25.3134",
    Longitude: "31.169",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18840",
    WardLookupId: "4338",
    VDNumber: "54910953",
    RegisteredPopulation: "629",
    VotingStationName: "SILIGANE SECONDARY SCHOOL",
    Address: "1 THULANE TRUST  KABOKWENI  NELSPRUIT",
    Latitude: "-25.3206",
    Longitude: "31.1793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18841",
    WardLookupId: "4338",
    VDNumber: "54911123",
    RegisteredPopulation: "639",
    VotingStationName: "SITIMISELE HOME BASED CARE",
    Address: "MANGO ROAD, ERMELO TRUST  KABOKWENI  NELSPRUIT",
    Latitude: "-25.3444",
    Longitude: "31.1402",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18842",
    WardLookupId: "4338",
    VDNumber: "54911134",
    RegisteredPopulation: "957",
    VotingStationName: "WITH GOD WE CONQUER MINISTRY",
    Address: "SIFUNINDLELA  KABOKWENI  KHUMALO TRIBAL",
    Latitude: "-25.3052",
    Longitude: "31.1715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:44",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:44",
  },
  {
    VotingStationId: "18843",
    WardLookupId: "4339",
    VDNumber: "54790050",
    RegisteredPopulation: "1424",
    VotingStationName: "CHAKAZA PRIMARY SCHOOL",
    Address: "1 CHAKAZA STREET  ZWELISHA  MBOMBELA (NELSPRUIT)",
    Latitude: "-25.3841",
    Longitude: "31.1618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18844",
    WardLookupId: "4339",
    VDNumber: "54790678",
    RegisteredPopulation: "680",
    VotingStationName: "SABANE HIGH SCHOOL",
    Address: "ZWELISHA  KABOKWENI  NESLPRUIT",
    Latitude: "-25.3913",
    Longitude: "31.1565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18845",
    WardLookupId: "4339",
    VDNumber: "54910065",
    RegisteredPopulation: "1746",
    VotingStationName: "MTHOMBO HIGH SCHOOL",
    Address: "NEWSKOM  MBOMBELA (NELSPRUIT)",
    Latitude: "-25.3788",
    Longitude: "31.1537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18846",
    WardLookupId: "4339",
    VDNumber: "54910751",
    RegisteredPopulation: "861",
    VotingStationName: "BAPTIST CHURCH",
    Address: "NEWSKOM (MAGAGANE)  KABOKWENI  NELSPRUIT",
    Latitude: "-25.3812",
    Longitude: "31.1479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18847",
    WardLookupId: "4339",
    VDNumber: "54910841",
    RegisteredPopulation: "697",
    VotingStationName: "FAITH ASSEMBLY CHURCH",
    Address: "NEWSKOM  KABOKWENI  NELSPRUIT",
    Latitude: "-25.3931",
    Longitude: "31.1489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18848",
    WardLookupId: "4339",
    VDNumber: "54910931",
    RegisteredPopulation: "677",
    VotingStationName: "THE POWER OF GOD APOSTOLIC CHURCH",
    Address: "NEWSKOM  KABOKWENI  NELSPRUIT",
    Latitude: "-25.3755",
    Longitude: "31.1487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18849",
    WardLookupId: "4339",
    VDNumber: "54911145",
    RegisteredPopulation: "191",
    VotingStationName: "BONGAMLAMBO PRIMARY SCHOOL",
    Address: "NEWSKOM  NELSPRUIT",
    Latitude: "-25.3871",
    Longitude: "31.1512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18850",
    WardLookupId: "4340",
    VDNumber: "54910368",
    RegisteredPopulation: "1371",
    VotingStationName: "KHUMBULA HIGH SCHOOL HALL",
    Address: "1 KHUMBULA  WHITE RIVER / NSIKAZI",
    Latitude: "-25.2836",
    Longitude: "31.1727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18851",
    WardLookupId: "4340",
    VDNumber: "54910379",
    RegisteredPopulation: "1482",
    VotingStationName: "MALEKUTU PRIMARY SCHOOL",
    Address: "1 MALEKUTU  WHITE RIVER / NSIKAZI",
    Latitude: "-25.2799",
    Longitude: "31.1978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18852",
    WardLookupId: "4340",
    VDNumber: "54910627",
    RegisteredPopulation: "435",
    VotingStationName: "IMPUMELELO COMBINED SCHOOL",
    Address: "MDLANKOMO  MJEJANE  WHITE RIVER / NSIKAZI",
    Latitude: "-25.2766",
    Longitude: "31.2357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18853",
    WardLookupId: "4340",
    VDNumber: "54910830",
    RegisteredPopulation: "1454",
    VotingStationName: "PENTECOST JERUSALEM CHURCH",
    Address: "KHUMBULA  NSIKAZI  NELSPRUIT",
    Latitude: "-25.2912",
    Longitude: "31.1679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18854",
    WardLookupId: "4340",
    VDNumber: "54910863",
    RegisteredPopulation: "1237",
    VotingStationName: "MULTIPURPOSE CENTRE",
    Address: "MBOMBELA [NELSPRUIT]  ",
    Latitude: "-25.2851",
    Longitude: "31.1923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18855",
    WardLookupId: "4340",
    VDNumber: "54911112",
    RegisteredPopulation: "518",
    VotingStationName: "LIVING WORD ASSEMBLY OF GOD CHURCH",
    Address: "KHUMBULA  NSIKAZI  NELSPRUIT",
    Latitude: "-25.2853",
    Longitude: "31.177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18856",
    WardLookupId: "4341",
    VDNumber: "54830088",
    RegisteredPopulation: "2670",
    VotingStationName: "PHATHWA SECONDARY SCHOOL",
    Address: "2886 KABOKWENI  NELSPRUIT",
    Latitude: "-25.3279",
    Longitude: "31.1044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18857",
    WardLookupId: "4341",
    VDNumber: "54830178",
    RegisteredPopulation: "449",
    VotingStationName: "EVANGELICAL LUTHERAN CHURCH",
    Address: "EMBONISWENI  KABOKWENI",
    Latitude: "-25.3109",
    Longitude: "31.1018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18858",
    WardLookupId: "4341",
    VDNumber: "54870228",
    RegisteredPopulation: "422",
    VotingStationName: "MADLISA GENERAL STORE",
    Address: "KARINO  NELSPRUIT",
    Latitude: "-25.437",
    Longitude: "31.0992",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18859",
    WardLookupId: "4341",
    VDNumber: "54870318",
    RegisteredPopulation: "64",
    VotingStationName: "KIAAT RIDGE PRE-SCHOOL",
    Address: "KIAAT RIDGE  NELSPRUIT",
    Latitude: "-25.4079",
    Longitude: "30.9814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18860",
    WardLookupId: "4341",
    VDNumber: "54910054",
    RegisteredPopulation: "738",
    VotingStationName: "HILLSVIEW COMMUNITY HALL",
    Address: "HILLSVIEW  WHITE RIVER",
    Latitude: "-25.3078",
    Longitude: "31.0518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18861",
    WardLookupId: "4341",
    VDNumber: "54910537",
    RegisteredPopulation: "506",
    VotingStationName: "PRIMKOP DAM",
    Address: "AIRPORT ROAD (KMIA)  PRIMKOP  WHITE RIVER",
    Latitude: "-25.3859",
    Longitude: "31.0726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18862",
    WardLookupId: "4341",
    VDNumber: "54910717",
    RegisteredPopulation: "508",
    VotingStationName: "VERGENOEGD COVENANT FESTIVAL SITE",
    Address: "VERGENOEGD FARM  VERGENOEGD  WHITE RIVER",
    Latitude: "-25.3671",
    Longitude: "31.0144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18863",
    WardLookupId: "4342",
    VDNumber: "54740066",
    RegisteredPopulation: "1554",
    VotingStationName: "IFALETU PRIMARY SCHOOL",
    Address: "HAZYVIEW  NYONGANI TRUST  HAZYVIEW",
    Latitude: "-25.0539",
    Longitude: "31.1907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18864",
    WardLookupId: "4342",
    VDNumber: "54740099",
    RegisteredPopulation: "1875",
    VotingStationName: "GEORGE MHAWULI PRIMARY SCHOOL",
    Address: "NUMBI  HAZYVIEW",
    Latitude: "-25.1273",
    Longitude: "31.1645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18865",
    WardLookupId: "4342",
    VDNumber: "54740134",
    RegisteredPopulation: "922",
    VotingStationName: "SUCCESS EDUCARE CENTRE",
    Address: "NYONGANE  HAZYVIEW",
    Latitude: "-25.0454",
    Longitude: "31.1979",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18866",
    WardLookupId: "4342",
    VDNumber: "54740257",
    RegisteredPopulation: "438",
    VotingStationName: "SIVIKELEKILE PRE-SCHOOL",
    Address: "NUMBI TRUST  HAZYVIEW",
    Latitude: "-25.1355",
    Longitude: "31.163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18867",
    WardLookupId: "4342",
    VDNumber: "54740268",
    RegisteredPopulation: "513",
    VotingStationName: "GOODHOPE EDUCARE CENTRE",
    Address: "NYONGANE TRUST  HAZYVIEW",
    Latitude: "-25.0646",
    Longitude: "31.1878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18868",
    WardLookupId: "4342",
    VDNumber: "54910582",
    RegisteredPopulation: "395",
    VotingStationName: "MOBILE VOTING STATION (PRETORIUSKOP)",
    Address: "PRETORIUSKOP  KRUGER PARK  SKUKUZA",
    Latitude: "-25.1701",
    Longitude: "31.2681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18869",
    WardLookupId: "4342",
    VDNumber: "54910593",
    RegisteredPopulation: "932",
    VotingStationName: "SKUKUZA REST CAMP",
    Address: "KRUGER PARK  SKUKUZA  LOWVELD",
    Latitude: "-24.9959",
    Longitude: "31.592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18870",
    WardLookupId: "4343",
    VDNumber: "54790397",
    RegisteredPopulation: "1738",
    VotingStationName: "MBOKODO PRIMARY SCHOOL (MSOGWABA)",
    Address: "144 MBOKODO STREET  MSOGWABA  NELSPRUIT",
    Latitude: "-25.4177",
    Longitude: "31.1659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18871",
    WardLookupId: "4343",
    VDNumber: "54790410",
    RegisteredPopulation: "2154",
    VotingStationName: "VUKASAMBE PRIMARY SCHOOL",
    Address: "MSOGWABA STREET  MSOGWABA  NELSPRUIT",
    Latitude: "-25.4073",
    Longitude: "31.1555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18872",
    WardLookupId: "4343",
    VDNumber: "54790465",
    RegisteredPopulation: "2368",
    VotingStationName: "LIHAWU SECONDARY SCHOOL",
    Address: "LIHAWU   MSOGWABA  NELSPRUIT",
    Latitude: "-25.4053",
    Longitude: "31.1471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18873",
    WardLookupId: "4343",
    VDNumber: "54790735",
    RegisteredPopulation: "757",
    VotingStationName: "SINDZAWONYE PRIMARY SCHOOL",
    Address: "MSOGWABA TRUST  PIENAAR  NELSPRUIT",
    Latitude: "-25.4155",
    Longitude: "31.1686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18874",
    WardLookupId: "4343",
    VDNumber: "54790791",
    RegisteredPopulation: "651",
    VotingStationName: "VUKASAMBE ALLIANCE CHURCH",
    Address: "VUKASAMBE  MSOGWABA  MBOMBELA",
    Latitude: "-25.4129",
    Longitude: "31.155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18875",
    WardLookupId: "4344",
    VDNumber: "54720086",
    RegisteredPopulation: "2326",
    VotingStationName: "SIKHUTSELE PRIMARY SCHOOL",
    Address: "4091 EXTENSION 10 PHASE 2  EMJINDINI  BARBERTON",
    Latitude: "-25.7717",
    Longitude: "31.0157",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18876",
    WardLookupId: "4344",
    VDNumber: "54720121",
    RegisteredPopulation: "1650",
    VotingStationName: "AERODROME CENTRE",
    Address: "KRUGER ROAD  EMJINDINI  BARBERTON",
    Latitude: "-25.7819",
    Longitude: "31.0158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18877",
    WardLookupId: "4344",
    VDNumber: "54840045",
    RegisteredPopulation: "213",
    VotingStationName: "NELSHOOGTE VILLAGE HALL",
    Address: "NELSHOOGTE PLANTATION  BARBERTON  BARBERTON",
    Latitude: "-25.8312",
    Longitude: "30.8203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18878",
    WardLookupId: "4344",
    VDNumber: "54840056",
    RegisteredPopulation: "170",
    VotingStationName: "MONTROSE PLANTATION WORKSHOP",
    Address: "MONTROSE PLANTATION  BARBERTON  BARBERTON",
    Latitude: "-25.8763",
    Longitude: "30.9111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18879",
    WardLookupId: "4344",
    VDNumber: "54840113",
    RegisteredPopulation: "923",
    VotingStationName: "ALLIANCE CHURCH",
    Address: "KAMADAKWA NDLOVU  EMJINDINI TRUST  BARBERTON",
    Latitude: "-25.8104",
    Longitude: "30.9675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18880",
    WardLookupId: "4344",
    VDNumber: "54840157",
    RegisteredPopulation: "2008",
    VotingStationName: "CHIEF FUNWAKO SECONDARY SCHOOL",
    Address: "KRUGER ROAD  EMJINDINI TRUST  BARBERTON",
    Latitude: "-25.7906",
    Longitude: "30.9418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18881",
    WardLookupId: "4345",
    VDNumber: "54720019",
    RegisteredPopulation: "2815",
    VotingStationName: "TOWN HALL",
    Address: "C/O GENERAL & DE VILLIERS  BARBERTON  BARBERTON",
    Latitude: "-25.7901",
    Longitude: "31.0522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18882",
    WardLookupId: "4345",
    VDNumber: "54720020",
    RegisteredPopulation: "2434",
    VotingStationName: "EMJINDINI SECONDARY SCHOOL",
    Address: "SHONGWE STREET  BARBERTON  EMJINDINI",
    Latitude: "-25.7749",
    Longitude: "31.0312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18883",
    WardLookupId: "4345",
    VDNumber: "54720110",
    RegisteredPopulation: "796",
    VotingStationName: "NGWANE COMBINED SCHOOL",
    Address: "1  NEW CLERE  EMJINDINI  BARBERTON",
    Latitude: "-25.7746",
    Longitude: "31.0352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18884",
    WardLookupId: "4345",
    VDNumber: "54720132",
    RegisteredPopulation: "531",
    VotingStationName: "CATHYVILLE HALL",
    Address: "NAGPUR  CATHYVILLE  BARBERTON",
    Latitude: "-25.777",
    Longitude: "31.039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18885",
    WardLookupId: "4346",
    VDNumber: "54720109",
    RegisteredPopulation: "1357",
    VotingStationName: "BARBERTON HIGH SCHOOL",
    Address: "100 SHEBA ROAD  BARBERTON  BARBERTON",
    Latitude: "-25.7651",
    Longitude: "31.0503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18886",
    WardLookupId: "4346",
    VDNumber: "54840034",
    RegisteredPopulation: "301",
    VotingStationName: "DIXIE CHRISTIAN WORSHIP CENTRE",
    Address: "DIXIE FARM  BARBERTON",
    Latitude: "-25.7117",
    Longitude: "31.0528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18887",
    WardLookupId: "4346",
    VDNumber: "54840067",
    RegisteredPopulation: "561",
    VotingStationName: "GAYIMASHINI CHRISTIAN HOLY SPIRIT CHURCH IN ZION",
    Address: "NEW CONSORT  NOORDKAAP  BARBERTON",
    Latitude: "-25.648",
    Longitude: "31.0691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18888",
    WardLookupId: "4346",
    VDNumber: "54840102",
    RegisteredPopulation: "234",
    VotingStationName: "OOSTERBEECK FARM HIGHLANDS RECREATION HALL",
    Address: "DIE BILT 372JU  HIGHLANDS PLANTATION FARM  BARBERTON",
    Latitude: "-25.8283",
    Longitude: "31.0829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18889",
    WardLookupId: "4346",
    VDNumber: "54840135",
    RegisteredPopulation: "1055",
    VotingStationName: "SHEBA NAZARINE CHURCH",
    Address: "R38 SHEBA SIDING  SHEBA MINE  BARBERTON",
    Latitude: "-25.6947",
    Longitude: "31.1749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18890",
    WardLookupId: "4346",
    VDNumber: "54840146",
    RegisteredPopulation: "1975",
    VotingStationName: "FAIRVIEW PRIMARY SCHOOL",
    Address: "SINQOBILE  SINQOBILE TOWNSHIP  BARBERTON",
    Latitude: "-25.7405",
    Longitude: "31.0609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18891",
    WardLookupId: "4346",
    VDNumber: "54840179",
    RegisteredPopulation: "224",
    VotingStationName: "EKHIYENI PRIMARY SCHOOL",
    Address: "R40 ROAD  ST JOHNS MISSION  BARBERTON",
    Latitude: "-25.7427",
    Longitude: "31.0015",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18892",
    WardLookupId: "4346",
    VDNumber: "54840180",
    RegisteredPopulation: "175",
    VotingStationName: "SHIYALONGUBO COMMUNITY HALL",
    Address: "SHIYALONGUBO  LOUWS CREEK  BARBERTON",
    Latitude: "-25.7611",
    Longitude: "31.254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18893",
    WardLookupId: "4346",
    VDNumber: "54890095",
    RegisteredPopulation: "1100",
    VotingStationName: "ASSEMBLIES CHURCH OF GOD",
    Address: "R38 KAAPMUIDEN ROAD  LOWS CREEK  BARBERTON",
    Latitude: "-25.6272",
    Longitude: "31.3094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18894",
    WardLookupId: "4347",
    VDNumber: "54720031",
    RegisteredPopulation: "1681",
    VotingStationName: "THE CHURCH OF JESUS CHRIST",
    Address: "151 LONG HOMES  EMJINDINI  BARBERTON",
    Latitude: "-25.7671",
    Longitude: "31.0227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18895",
    WardLookupId: "4347",
    VDNumber: "54720042",
    RegisteredPopulation: "1736",
    VotingStationName: "KAMHOLA SENIOR SECONDARY SCHOOL",
    Address: "401 LONG HOMES  EMJINDINI  BARBERTON",
    Latitude: "-25.7676",
    Longitude: "31.0261",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18896",
    WardLookupId: "4347",
    VDNumber: "54720053",
    RegisteredPopulation: "1129",
    VotingStationName: "KAMHOLA JUNIOR PRIMARY SCHOOL",
    Address: "1269 KAMHOLA LOCATION  EMJINDINI  BARBERTON",
    Latitude: "-25.7713",
    Longitude: "31.024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18897",
    WardLookupId: "4347",
    VDNumber: "54720064",
    RegisteredPopulation: "1966",
    VotingStationName: "BETHLEHEM CHURCH IN ZION",
    Address: "3693 SHONGWE ROAD  EMJINDINI EXTENSION 10  BARBERTON",
    Latitude: "-25.7648",
    Longitude: "31.0172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18898",
    WardLookupId: "4348",
    VDNumber: "54720075",
    RegisteredPopulation: "1014",
    VotingStationName: "AMON NKOSI PRIMARY SCHOOL",
    Address: "EXTENSION 12  EMJINDINI TRUST  BARBERTON",
    Latitude: "-25.7588",
    Longitude: "31.0133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18899",
    WardLookupId: "4348",
    VDNumber: "54720097",
    RegisteredPopulation: "2771",
    VotingStationName: "MGABABA OPEN FIELD (TENT)",
    Address: "SHONGWE ROAD  EMJINDINI  BARBERTON",
    Latitude: "-25.7631",
    Longitude: "31.0112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18900",
    WardLookupId: "4348",
    VDNumber: "54840012",
    RegisteredPopulation: "510",
    VotingStationName: "ANRO BOERDERY COMMUNITY HALL",
    Address: "KAAPSEHOOP ROAD  ANRO BOERDERY FARM  BARBERTON",
    Latitude: "-25.5811",
    Longitude: "30.8428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18901",
    WardLookupId: "4348",
    VDNumber: "54840078",
    RegisteredPopulation: "767",
    VotingStationName: "GLENTHORPE PRIMARY SCHOOL",
    Address: "SAPPI GLENTHORPE  BARBERTON  BARBERTON",
    Latitude: "-25.7213",
    Longitude: "30.8521",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18902",
    WardLookupId: "4348",
    VDNumber: "54840089",
    RegisteredPopulation: "446",
    VotingStationName: "AJURY NURSARY HALL",
    Address: "R38 BADPLAAS ROAD  BARBERTON  BARBERTON",
    Latitude: "-25.7541",
    Longitude: "30.9654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18903",
    WardLookupId: "4348",
    VDNumber: "54840191",
    RegisteredPopulation: "182",
    VotingStationName: "NGK CHURCH",
    Address: "TEMBISA VILLAGE  BARBERTON  BARBERTON",
    Latitude: "-25.6265",
    Longitude: "30.7927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18904",
    WardLookupId: "4348",
    VDNumber: "54840203",
    RegisteredPopulation: "759",
    VotingStationName: "EMMANUEL ASSEMBLIES OF GOD",
    Address: "MSHOLOZI   EXTENSION 17  BARBERTON",
    Latitude: "-25.7662",
    Longitude: "31.0051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18905",
    WardLookupId: "4348",
    VDNumber: "54840214",
    RegisteredPopulation: "66",
    VotingStationName: "SENTEEKO TEA ESTATE HALL",
    Address: "SENTEEKO  SENTEEGO TEA ESTATE  BARBERTON",
    Latitude: "-25.7149",
    Longitude: "30.7654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18906",
    WardLookupId: "2264",
    VDNumber: "86660118",
    RegisteredPopulation: "666",
    VotingStationName: "MOROKWA - DITLOU PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  RUIGTESLOOT  MORETELE",
    Latitude: "-25.0258",
    Longitude: "27.8526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18907",
    WardLookupId: "2264",
    VDNumber: "86660141",
    RegisteredPopulation: "984",
    VotingStationName: "BALESENG PRIMARY SCHOOL",
    Address: "NO STREET NAME  LEBOTLOANE  LEBOTLWANE",
    Latitude: "-25.0617",
    Longitude: "27.9241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18908",
    WardLookupId: "2264",
    VDNumber: "86662233",
    RegisteredPopulation: "228",
    VotingStationName: "BOLLANTLOKWE PRIMARY SCHOOL",
    Address: "NO STREET NAME  BOLLANTLOKOE  MAKAPANSTAD",
    Latitude: "-25.0681",
    Longitude: "27.9847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18909",
    WardLookupId: "2264",
    VDNumber: "86662244",
    RegisteredPopulation: "969",
    VotingStationName: "MASHILO MATSHO PRIMARY SCHOOL",
    Address: "NO STREET NAME  LITTLE  MORETELE",
    Latitude: "-25.0864",
    Longitude: "27.9303",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18910",
    WardLookupId: "2264",
    VDNumber: "86662266",
    RegisteredPopulation: "442",
    VotingStationName: "TLHOLOE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  TLHOLWE  MORETELE",
    Latitude: "-25.112",
    Longitude: "27.9101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18911",
    WardLookupId: "2265",
    VDNumber: "86660084",
    RegisteredPopulation: "276",
    VotingStationName: "TLOUNANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  TLOUNANE  MORETELE",
    Latitude: "-25.1191",
    Longitude: "28.1824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18912",
    WardLookupId: "2265",
    VDNumber: "86660163",
    RegisteredPopulation: "802",
    VotingStationName: "MOGOTLHOANENG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  OLVERTON  MORETELE [TEMBA]",
    Latitude: "-25.0737",
    Longitude: "28.189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18913",
    WardLookupId: "2265",
    VDNumber: "86660185",
    RegisteredPopulation: "718",
    VotingStationName: "RAMMUTLA PRIMARY SCHOOL",
    Address: "SWARTBOOM  MORETELE  SWAARTBOOM",
    Latitude: "-25.0367",
    Longitude: "28.1333",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18914",
    WardLookupId: "2265",
    VDNumber: "86663021",
    RegisteredPopulation: "579",
    VotingStationName: "AGRICO VOYENTINE COMMUNITY HALL",
    Address: "VOYENTINE  MORETELE",
    Latitude: "-25.0638",
    Longitude: "28.2002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18915",
    WardLookupId: "2265",
    VDNumber: "86663076",
    RegisteredPopulation: "947",
    VotingStationName: "BADUMEDI SECONDARY SCHOOL",
    Address:
      "NO STREET ADDRESS AVAILABLE  (NW371 - MORETELE [TEMBA])   (NW371 - MORETELE [TEMBA])",
    Latitude: "-25.056",
    Longitude: "28.1893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18916",
    WardLookupId: "2266",
    VDNumber: "86660095",
    RegisteredPopulation: "956",
    VotingStationName: "CYFERSKUIL LUTHERAN CHURCH",
    Address: "CYFERSKUIL  MORETELE  MODIMOLLE",
    Latitude: "-25.0759",
    Longitude: "28.2339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18917",
    WardLookupId: "2266",
    VDNumber: "86660152",
    RegisteredPopulation: "1089",
    VotingStationName: "SWARISANANG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  CYFERSKUIL  CYFERSKUIL",
    Latitude: "-25.0807",
    Longitude: "28.2333",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18918",
    WardLookupId: "2266",
    VDNumber: "86662615",
    RegisteredPopulation: "982",
    VotingStationName: "UTSANE HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  CYFERSKUIL  MORETELE",
    Latitude: "-25.0731",
    Longitude: "28.2155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18919",
    WardLookupId: "2266",
    VDNumber: "86663245",
    RegisteredPopulation: "668",
    VotingStationName: "CYFERSKUIL COMMUNITY HALL",
    Address: "CYFERSKUIL  ",
    Latitude: "-25.0761",
    Longitude: "28.2268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18920",
    WardLookupId: "2267",
    VDNumber: "86660129",
    RegisteredPopulation: "487",
    VotingStationName: "MOKGOBOLA-SETSHEDI PRIMARY SCHOOL",
    Address: "SLAGBOOM  MORETELE",
    Latitude: "-25.0297",
    Longitude: "28",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18921",
    WardLookupId: "2267",
    VDNumber: "86660130",
    RegisteredPopulation: "1082",
    VotingStationName: "SEKGOPI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  LEBOTLWANE  LEBOTLWANE",
    Latitude: "-25.0469",
    Longitude: "27.9395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18922",
    WardLookupId: "2267",
    VDNumber: "86660196",
    RegisteredPopulation: "989",
    VotingStationName: "BOGOSHI MIDDLE SCHOOL",
    Address: "LEBOTLWANE  ",
    Latitude: "-25.0487",
    Longitude: "27.9338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18923",
    WardLookupId: "2267",
    VDNumber: "86662255",
    RegisteredPopulation: "454",
    VotingStationName: "NYAKALE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MMUKUBYANE  MMUKUBYANE",
    Latitude: "-24.999",
    Longitude: "27.9722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18924",
    WardLookupId: "2267",
    VDNumber: "86663256",
    RegisteredPopulation: "464",
    VotingStationName: "MOK GLAZING WORKSHOP",
    Address: "LEBOTLOANE  MORETELE  BAPHUTING BA GA NAWA",
    Latitude: "-25.0385",
    Longitude: "27.9409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18925",
    WardLookupId: "2268",
    VDNumber: "86660208",
    RegisteredPopulation: "423",
    VotingStationName: "AGRICO HALL",
    Address: "GA HABEDI  GAHABEDI",
    Latitude: "-25.1696",
    Longitude: "27.9059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18926",
    WardLookupId: "2268",
    VDNumber: "86660220",
    RegisteredPopulation: "872",
    VotingStationName: "RAMAIFALA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MORETELE  MORETELE",
    Latitude: "-25.1472",
    Longitude: "27.9613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18927",
    WardLookupId: "2268",
    VDNumber: "86660231",
    RegisteredPopulation: "390",
    VotingStationName: "SETUMO SEPHETHE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  SUTELONG  MORETELE",
    Latitude: "-25.1538",
    Longitude: "27.9616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18928",
    WardLookupId: "2268",
    VDNumber: "86662378",
    RegisteredPopulation: "159",
    VotingStationName: "DIKGOPHANENG COMMUNITY FUNDS",
    Address: "NO STREET ADDRESS  DIKGOPANENG  MORETELE",
    Latitude: "-25.2346",
    Longitude: "27.9199",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18929",
    WardLookupId: "2268",
    VDNumber: "86662413",
    RegisteredPopulation: "281",
    VotingStationName: "PUO-PHAA SECONDARY SCHOOL",
    Address: "JONATHAN  MORETELE  MAKAPANSTAD",
    Latitude: "-25.2058",
    Longitude: "27.8671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18930",
    WardLookupId: "2268",
    VDNumber: "86663087",
    RegisteredPopulation: "561",
    VotingStationName: "RAMASHITE PRIMARY SCHOOL",
    Address:
      "NO STREET ADDRESS   (NW371 - MORETELE [TEMBA])   (NW371 - MORETELE [TEMBA])",
    Latitude: "-25.1456",
    Longitude: "27.9515",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18931",
    WardLookupId: "2268",
    VDNumber: "86663098",
    RegisteredPopulation: "427",
    VotingStationName: "IKANENG HIGH SCHOOL",
    Address: "(NW371 - MORETELE [TEMBA])   ",
    Latitude: "-25.1553",
    Longitude: "27.972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18932",
    WardLookupId: "2269",
    VDNumber: "86660062",
    RegisteredPopulation: "355",
    VotingStationName: "TRANSACTIE COMMUNITY HALL",
    Address: "TRANSCATIE  MORETELE",
    Latitude: "-25.0202",
    Longitude: "28.0551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18933",
    WardLookupId: "2269",
    VDNumber: "86660073",
    RegisteredPopulation: "1230",
    VotingStationName: "LEHABE PRIMARY SCHOOL",
    Address: "NO STREET NAME  NGOBI  TEMBA",
    Latitude: "-25.0293",
    Longitude: "28.0855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18934",
    WardLookupId: "2269",
    VDNumber: "86660174",
    RegisteredPopulation: "803",
    VotingStationName: "DIPETLEWANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  DIPETLELOANE  MORETELE",
    Latitude: "-25.0745",
    Longitude: "28.1245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18935",
    WardLookupId: "2269",
    VDNumber: "86662604",
    RegisteredPopulation: "742",
    VotingStationName: "NGOBI COMBINED PRIMARY SCHOOL",
    Address: "NGOBI  MORETELE",
    Latitude: "-25.0138",
    Longitude: "28.0953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18936",
    WardLookupId: "2269",
    VDNumber: "86662626",
    RegisteredPopulation: "136",
    VotingStationName: "MOOKANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  SELEPE VILLAGE  MORETELE",
    Latitude: "-25.0679",
    Longitude: "28.0493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18937",
    WardLookupId: "2270",
    VDNumber: "86660297",
    RegisteredPopulation: "637",
    VotingStationName: "MAKGABETLOANE PRIMARY SCHOOL",
    Address: "N/A  MAKGABETLOANE  MADIBENG [BRITS]",
    Latitude: "-25.2653",
    Longitude: "27.9292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18938",
    WardLookupId: "2270",
    VDNumber: "86660646",
    RegisteredPopulation: "886",
    VotingStationName: "MOLOTLEGI MALEBYE PRIMARY SCHOOL",
    Address: "MMOTONG SECTION  SWARTDAM  MORETELE",
    Latitude: "-25.2936",
    Longitude: "28.055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18939",
    WardLookupId: "2270",
    VDNumber: "86662288",
    RegisteredPopulation: "226",
    VotingStationName: "LEBALANGWE PRIMARY SCHOOL",
    Address: "LEBALANGWE  MAKAPANSTAD  BAKWENA BA BOIKHUTSO",
    Latitude: "-25.3137",
    Longitude: "27.9958",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18940",
    WardLookupId: "2270",
    VDNumber: "86662356",
    RegisteredPopulation: "485",
    VotingStationName: "NYORWE PRIMARY SCHOOL",
    Address: "NYORWE  NOROKIE  MORETELE",
    Latitude: "-25.2757",
    Longitude: "28.029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18941",
    WardLookupId: "2270",
    VDNumber: "86662659",
    RegisteredPopulation: "313",
    VotingStationName: "KALKBANK PRIMARY SCHOOL",
    Address: "KALKBANK  MORETELE",
    Latitude: "-25.2872",
    Longitude: "27.9741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18942",
    WardLookupId: "2270",
    VDNumber: "86662660",
    RegisteredPopulation: "1166",
    VotingStationName: "RAMPEI TSHWAANE MIDDLE SCHOOL",
    Address: "SWARTDAM  MORETELE",
    Latitude: "-25.3194",
    Longitude: "28.0412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18943",
    WardLookupId: "2271",
    VDNumber: "86660028",
    RegisteredPopulation: "1486",
    VotingStationName: "MMAKAUNYANE HIGH SCHOOL",
    Address: "MMAKAUNYANE  MORETELE  MMAKAUNYANE",
    Latitude: "-25.3498",
    Longitude: "28.0071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18944",
    WardLookupId: "2271",
    VDNumber: "86662828",
    RegisteredPopulation: "1243",
    VotingStationName: "KHUBAMELO PRIMARY SCHOOL",
    Address: "MMAKAUNYANE  MORETELE",
    Latitude: "-25.3577",
    Longitude: "27.9945",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18945",
    WardLookupId: "2271",
    VDNumber: "86662930",
    RegisteredPopulation: "483",
    VotingStationName: "MMASEMPANE PRIMARY SCHOOL",
    Address: "MMAKAUNYANE VILLAGE  MORETELE [TEMBA]  MORETELE [TEMBA]",
    Latitude: "-25.3448",
    Longitude: "28.0056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18946",
    WardLookupId: "2272",
    VDNumber: "86660714",
    RegisteredPopulation: "1642",
    VotingStationName: "WORD ALIVE BIBLE CHURCH",
    Address: "MOTLA  GA-MOTLA  RATJIEPANE",
    Latitude: "-25.3618",
    Longitude: "28.0696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18947",
    WardLookupId: "2272",
    VDNumber: "86660725",
    RegisteredPopulation: "2213",
    VotingStationName: "T.K MOKONYANE PRIMARY SCHOOL",
    Address: "141 TK  MOEKA  MORETELE",
    Latitude: "-25.3624",
    Longitude: "28.0832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18948",
    WardLookupId: "2273",
    VDNumber: "86660242",
    RegisteredPopulation: "862",
    VotingStationName: "RANTEBENG II PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  DIKEBU  MORETELE",
    Latitude: "-25.2028",
    Longitude: "27.9682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18949",
    WardLookupId: "2273",
    VDNumber: "86660253",
    RegisteredPopulation: "644",
    VotingStationName: "DIRATSAGAE MIDDLE SCHOOL",
    Address: "NO STREET ADDRESS  DIKEBU  MORETELE",
    Latitude: "-25.1953",
    Longitude: "27.9738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18950",
    WardLookupId: "2273",
    VDNumber: "86660264",
    RegisteredPopulation: "1263",
    VotingStationName: "SEBOANENG SECONDARY SCHOOL",
    Address: "TLADISTAD  MORETELE",
    Latitude: "-25.2048",
    Longitude: "28.0305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18951",
    WardLookupId: "2273",
    VDNumber: "86660275",
    RegisteredPopulation: "442",
    VotingStationName: "TLADISTAD PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  TLADISTAD  MORETELE",
    Latitude: "-25.2035",
    Longitude: "28.0404",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18952",
    WardLookupId: "2273",
    VDNumber: "86662637",
    RegisteredPopulation: "100",
    VotingStationName: "LEKGOLO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  LEKGOLO  MORETELE",
    Latitude: "-25.1785",
    Longitude: "27.9897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18953",
    WardLookupId: "2273",
    VDNumber: "86662648",
    RegisteredPopulation: "204",
    VotingStationName: "MMATLHWAELE PRIMARY SCHOOL",
    Address: "NO STREET NAME  MMATLHWAELE  MORETELE",
    Latitude: "-25.2291",
    Longitude: "28.0138",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18954",
    WardLookupId: "2273",
    VDNumber: "86662839",
    RegisteredPopulation: "538",
    VotingStationName: "MOCHEKO SHOP (MOTSHEKO)",
    Address: "DIKEBU TRUST  MORETELE",
    Latitude: "-25.2144",
    Longitude: "27.9762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18955",
    WardLookupId: "2274",
    VDNumber: "86660758",
    RegisteredPopulation: "1810",
    VotingStationName: "THE CHIPMUNKS DAY CARE CENTRE",
    Address: "MOGOGELO  MORETELE  MOGOGELO",
    Latitude: "-25.3554",
    Longitude: "28.1322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18956",
    WardLookupId: "2274",
    VDNumber: "86660769",
    RegisteredPopulation: "1037",
    VotingStationName: "DIKGORWANENG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MOGOGELO  MOGOGELO",
    Latitude: "-25.3514",
    Longitude: "28.1455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18957",
    WardLookupId: "2274",
    VDNumber: "86660770",
    RegisteredPopulation: "741",
    VotingStationName: "BAPTIST CHURCH - MOGOGELO",
    Address: "MOGOGELO  MORETELE  HAMMANSKRAAL",
    Latitude: "-25.3538",
    Longitude: "28.1384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18958",
    WardLookupId: "2274",
    VDNumber: "86660781",
    RegisteredPopulation: "790",
    VotingStationName: "MOGOGELO ROMAN CATHOLIC CHURCH",
    Address: "MOGOGELO  ",
    Latitude: "-25.3602",
    Longitude: "28.1452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18959",
    WardLookupId: "2275",
    VDNumber: "86660488",
    RegisteredPopulation: "952",
    VotingStationName: "SEKITLA HIGH SCHOOL",
    Address: "MATHIBESTAD  MORETELE",
    Latitude: "-25.2925",
    Longitude: "28.1591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18960",
    WardLookupId: "2275",
    VDNumber: "86660534",
    RegisteredPopulation: "405",
    VotingStationName: "PULE MIDDLE SCHOOL",
    Address: "MATHIBESTADT  MATHIBESTAD  MORETELE",
    Latitude: "-25.2882",
    Longitude: "28.1793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18961",
    WardLookupId: "2275",
    VDNumber: "86660792",
    RegisteredPopulation: "905",
    VotingStationName: "MACHAKELA MAMODIBO SECONDARY SCHOOL",
    Address: "MOGOGELO  MORETELE  MOGOGELO",
    Latitude: "-25.3473",
    Longitude: "28.1419",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18962",
    WardLookupId: "2275",
    VDNumber: "86663100",
    RegisteredPopulation: "1066",
    VotingStationName: "THABANG DAY CARE CENTRE",
    Address: "MATHIBESTAD  MORETELE  BAHWADUBA",
    Latitude: "-25.2964",
    Longitude: "28.1677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18963",
    WardLookupId: "2275",
    VDNumber: "86663199",
    RegisteredPopulation: "208",
    VotingStationName: "BAHWADUBA MIDDLE SCHOOL",
    Address: "MORETELE  ",
    Latitude: "-25.2795",
    Longitude: "28.1499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18964",
    WardLookupId: "2276",
    VDNumber: "86660613",
    RegisteredPopulation: "1099",
    VotingStationName: "CAROUSEL CRECHE",
    Address: "CAROUSEL VIEW  MORETELE  MORETELE",
    Latitude: "-25.3295",
    Longitude: "28.2937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18965",
    WardLookupId: "2276",
    VDNumber: "86660635",
    RegisteredPopulation: "1013",
    VotingStationName: "BAIKEMEDI COMMUNITY HEALTH CENTRE",
    Address: "HANIE VIEW  MAUBANE  BAKGATLA BA MOCHA",
    Latitude: "-25.2918",
    Longitude: "28.2761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18966",
    WardLookupId: "2276",
    VDNumber: "86662547",
    RegisteredPopulation: "888",
    VotingStationName: "YA BATHO CRECHE`",
    Address: "CAROUSEL VIEW  MORETELE  MORETELE MUNICIPALITY",
    Latitude: "-25.3371",
    Longitude: "28.2826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18967",
    WardLookupId: "2276",
    VDNumber: "86663122",
    RegisteredPopulation: "594",
    VotingStationName: "ST JOHN APOSTOLIC CHURCH",
    Address: "PAPATSOVIEW  MORETELE  PAPATSO VIEW",
    Latitude: "-25.2972",
    Longitude: "28.287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18968",
    WardLookupId: "2276",
    VDNumber: "86663267",
    RegisteredPopulation: "730",
    VotingStationName: "AFRICAN CONTINENT MISSION CHURCH",
    Address: "CAROUSEL VIEW  MAKAPANSTAD  MORETELE",
    Latitude: "-25.3338",
    Longitude: "28.2812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18969",
    WardLookupId: "2277",
    VDNumber: "86660578",
    RegisteredPopulation: "755",
    VotingStationName: "DERTIG CIVIC",
    Address: "01 DERTIG  MORETELE  DERTIG",
    Latitude: "-25.2836",
    Longitude: "28.2298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18970",
    WardLookupId: "2277",
    VDNumber: "86660602",
    RegisteredPopulation: "1133",
    VotingStationName: "MMATSHEKO PRIMARY SCHOOL",
    Address: "DAN HOUSE  MORETELE",
    Latitude: "-25.2942",
    Longitude: "28.2388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18971",
    WardLookupId: "2277",
    VDNumber: "86662570",
    RegisteredPopulation: "1376",
    VotingStationName: "RAMAPHOSA KHOSANA SHOP",
    Address: "RAMAPHOSA  MAKAPANSTAD  BAKGATLA BA MOCHA",
    Latitude: "-25.2962",
    Longitude: "28.23",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18972",
    WardLookupId: "2277",
    VDNumber: "86663278",
    RegisteredPopulation: "796",
    VotingStationName: "ZION CITY APOSTOLIC CHURCH",
    Address: "DAN HOUSE  MAKAPANSTAD  MORETELE",
    Latitude: "-25.3042",
    Longitude: "28.2397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18973",
    WardLookupId: "2278",
    VDNumber: "86660422",
    RegisteredPopulation: "890",
    VotingStationName: "SA SEVENTH CHURCH OF GOD",
    Address: "GREENSIDE  ",
    Latitude: "-25.2757",
    Longitude: "28.2823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18974",
    WardLookupId: "2278",
    VDNumber: "86660444",
    RegisteredPopulation: "1117",
    VotingStationName: "MMAMARUMO PRIMARY SCHOOL",
    Address: "GREEN SIDE  MAUBANE",
    Latitude: "-25.2813",
    Longitude: "28.2735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18975",
    WardLookupId: "2278",
    VDNumber: "86662907",
    RegisteredPopulation: "720",
    VotingStationName: "LESANG BANA  CRECHE",
    Address: "MAUBANE  BAKGATLA BA MOCHA",
    Latitude: "-25.2745",
    Longitude: "28.2766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18976",
    WardLookupId: "2278",
    VDNumber: "86663290",
    RegisteredPopulation: "418",
    VotingStationName: "MODISE GENERAL DEALER",
    Address: "GREENSIDE  MAUBANE  BAKGATLA BA MOCHA",
    Latitude: "-25.2796",
    Longitude: "28.2813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18977",
    WardLookupId: "2278",
    VDNumber: "86663335",
    RegisteredPopulation: "350",
    VotingStationName: "SODI CRECHE",
    Address: "MAUBANE  MORETELE  MAUBANE",
    Latitude: "-25.2783",
    Longitude: "28.2578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18978",
    WardLookupId: "2279",
    VDNumber: "86660411",
    RegisteredPopulation: "583",
    VotingStationName: "BAFEDILE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MAUBANE  MORETELE",
    Latitude: "-25.2819",
    Longitude: "28.2588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18979",
    WardLookupId: "2279",
    VDNumber: "86660433",
    RegisteredPopulation: "2013",
    VotingStationName: "GLORY CHRISTIAN CHURCH",
    Address: "HANIE VIEW  MAUBANE  BAKGATLA BA MOCHA",
    Latitude: "-25.2867",
    Longitude: "28.2819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18980",
    WardLookupId: "2279",
    VDNumber: "86662895",
    RegisteredPopulation: "1097",
    VotingStationName: "TLAMELANG DAY CARE CENTRE",
    Address: "DAN HOUSE  MORETELE [TEMBA]  MORETELE [TEMBA]",
    Latitude: "-25.3037",
    Longitude: "28.2427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18981",
    WardLookupId: "2279",
    VDNumber: "86663289",
    RegisteredPopulation: "485",
    VotingStationName: "JUBILEE MISSION CHURCH - HANIE VIEW",
    Address: "HANIE VIEW  MAUBANE  BAKGATLA BA MOCHA",
    Latitude: "-25.2892",
    Longitude: "28.2731",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18982",
    WardLookupId: "2280",
    VDNumber: "86660309",
    RegisteredPopulation: "773",
    VotingStationName: "MAKAPANSTAD TRIBAL HALL",
    Address: "MAKAPANSTAD  ",
    Latitude: "-25.2376",
    Longitude: "28.1229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18983",
    WardLookupId: "2280",
    VDNumber: "86660321",
    RegisteredPopulation: "890",
    VotingStationName: "MOSETLHA PRIMARY SCHOOL",
    Address: "MAKAPANSTAD  MORETELE",
    Latitude: "-25.2444",
    Longitude: "28.1333",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18984",
    WardLookupId: "2280",
    VDNumber: "86660398",
    RegisteredPopulation: "252",
    VotingStationName: "LETLOPI MIDDLE SCHOOL",
    Address: "NO STREET NAME  THULWE  THULE",
    Latitude: "-25.2504",
    Longitude: "28.1996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18985",
    WardLookupId: "2280",
    VDNumber: "86660400",
    RegisteredPopulation: "1073",
    VotingStationName: "ALFRED MAUBANE SECONDARY SCHOOL",
    Address: "MAUBANE  MORETELE",
    Latitude: "-25.2701",
    Longitude: "28.2507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18986",
    WardLookupId: "2280",
    VDNumber: "86662277",
    RegisteredPopulation: "528",
    VotingStationName: "MMANJAAKGORA PRIMARY SCHOOL",
    Address: "PRIESKA  ",
    Latitude: "-25.2395",
    Longitude: "28.1709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18987",
    WardLookupId: "2280",
    VDNumber: "86662569",
    RegisteredPopulation: "458",
    VotingStationName: "DIPHALA EARLY CENTRE",
    Address: "OPPERMAN  MORETELE",
    Latitude: "-25.2498",
    Longitude: "28.2251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18988",
    WardLookupId: "2281",
    VDNumber: "86660523",
    RegisteredPopulation: "1260",
    VotingStationName: "SEMPAPA MIDDLE SCHOOL",
    Address: "MATHIBESTAD  LEFAHLENG",
    Latitude: "-25.2776",
    Longitude: "28.2",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18989",
    WardLookupId: "2281",
    VDNumber: "86660545",
    RegisteredPopulation: "699",
    VotingStationName: "GOOD LUCK SHOP",
    Address: "LEFATLHENG  MAKAPANSTAD  BAHWADUBA",
    Latitude: "-25.2738",
    Longitude: "28.2051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18990",
    WardLookupId: "2281",
    VDNumber: "86660556",
    RegisteredPopulation: "1242",
    VotingStationName: "TLHAGELE PRIMARY SCHOOL",
    Address: "LEFATHLENG  MATHIBESTAD  LEFAHLENG",
    Latitude: "-25.2762",
    Longitude: "28.207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18991",
    WardLookupId: "2282",
    VDNumber: "86660501",
    RegisteredPopulation: "1035",
    VotingStationName: "TLADI CRECHE",
    Address: "MATHIBESTAD  MORETELE",
    Latitude: "-25.2727",
    Longitude: "28.1861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18992",
    WardLookupId: "2282",
    VDNumber: "86660512",
    RegisteredPopulation: "1216",
    VotingStationName: "MATHIBESTAD PRIMARY SCHOOL",
    Address: "DITAU SECTION  MATHIBESTAD  MORETELE",
    Latitude: "-25.2869",
    Longitude: "28.1899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18993",
    WardLookupId: "2282",
    VDNumber: "86662862",
    RegisteredPopulation: "499",
    VotingStationName: "MMABODILA CRECHE",
    Address: "MATHIBESTAD  BAHWADUBA",
    Latitude: "-25.2722",
    Longitude: "28.1684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18994",
    WardLookupId: "2282",
    VDNumber: "86662918",
    RegisteredPopulation: "603",
    VotingStationName: "MODIMO  LERATO DAY CARE",
    Address: "RAMOGOGA SECTION  MATHIBESTAD  MATHIBESTAD",
    Latitude: "-25.2821",
    Longitude: "28.1975",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18995",
    WardLookupId: "2282",
    VDNumber: "86663302",
    RegisteredPopulation: "385",
    VotingStationName: "JUBILEE MISSION CENTRE",
    Address: "MATHIBESTAD  MORETELE  BAHWADUBA TRIBAL AUTHORITY",
    Latitude: "-25.2791",
    Longitude: "28.1754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18996",
    WardLookupId: "2283",
    VDNumber: "86660343",
    RegisteredPopulation: "1213",
    VotingStationName: "DIKELEDI MAKAPAN PRIMARY SCHOOL",
    Address: "SCHOOL  MAKAPANSTAD  MAKAPANSTAD",
    Latitude: "-25.2442",
    Longitude: "28.1125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18997",
    WardLookupId: "2283",
    VDNumber: "86660354",
    RegisteredPopulation: "1106",
    VotingStationName: "RAMOABI MIDDLE SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MAKAPANSTAD  MORETELE",
    Latitude: "-25.2598",
    Longitude: "28.1309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18998",
    WardLookupId: "2283",
    VDNumber: "86660365",
    RegisteredPopulation: "947",
    VotingStationName: "MMAMATHIBE MIDDLE SCHOOL",
    Address: "SCHOOL  MAKAPANSTAD  MAKAPANSTAD",
    Latitude: "-25.2617",
    Longitude: "28.1102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "18999",
    WardLookupId: "2283",
    VDNumber: "86660376",
    RegisteredPopulation: "705",
    VotingStationName: "MOTSHEGWA HIGH SCHOOL",
    Address: "SCHOOL  MAKAPANSTAD  MAKAPANASTAD",
    Latitude: "-25.2675",
    Longitude: "28.1123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19000",
    WardLookupId: "2283",
    VDNumber: "86662581",
    RegisteredPopulation: "484",
    VotingStationName: "TOPOLOGO CHRISTIAN CHURCH",
    Address: "MAKAPANSTAD  BAKGATLA BA MOSETLHA",
    Latitude: "-25.2505",
    Longitude: "28.1289",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19001",
    WardLookupId: "2284",
    VDNumber: "86660219",
    RegisteredPopulation: "675",
    VotingStationName: "MORATWE SECONDARY SCHOOL",
    Address: "KGOMO - KGOMO  MAKAPANSTAD  BAKGATLA BA MOSETLHA",
    Latitude: "-25.1541",
    Longitude: "28.0728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19002",
    WardLookupId: "2284",
    VDNumber: "86660310",
    RegisteredPopulation: "959",
    VotingStationName: "MANKALA TECHNICAL & COMMERCIAL HIGH SCHOOL",
    Address: "MAKAPANSTAD  BAKGATLA BA MOSETLHA",
    Latitude: "-25.233",
    Longitude: "28.1205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19003",
    WardLookupId: "2284",
    VDNumber: "86660332",
    RegisteredPopulation: "651",
    VotingStationName: "NCHAUPE HIGH SCHOOL",
    Address: "MATHIBESTAD  MAKAPANSTAD  MORETELE",
    Latitude: "-25.2317",
    Longitude: "28.1015",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19004",
    WardLookupId: "2284",
    VDNumber: "86660387",
    RegisteredPopulation: "636",
    VotingStationName: "TAU SEBELE MIDDLE SCHOOL",
    Address: "SCHOOL  MAKAPANSTAD  MORETELE",
    Latitude: "-25.2196",
    Longitude: "28.1209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19005",
    WardLookupId: "2284",
    VDNumber: "86662750",
    RegisteredPopulation: "177",
    VotingStationName: "MORANE SEFAKO",
    Address: "NO STREET ADDRESS  KONTANT  MORETELE",
    Latitude: "-25.1872",
    Longitude: "28.0941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19006",
    WardLookupId: "2284",
    VDNumber: "86662761",
    RegisteredPopulation: "153",
    VotingStationName: "MORATELE LUTHERAN CHURCH",
    Address: "MORATELE  MORETELE",
    Latitude: "-25.2087",
    Longitude: "28.1178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19007",
    WardLookupId: "2284",
    VDNumber: "86662929",
    RegisteredPopulation: "600",
    VotingStationName: "ROMAN CATHOLIC CHURCH - KGOMO KGOMO",
    Address: "KGOMO - KGOMO  MAKAPANSTAD  BAKGATLA BA MOSETLHA",
    Latitude: "-25.1511",
    Longitude: "28.0846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19008",
    WardLookupId: "2285",
    VDNumber: "86660567",
    RegisteredPopulation: "864",
    VotingStationName: "SEFAKO COMMUNITY CENTRE",
    Address: "LEFATLHENG  BAHWADUBA",
    Latitude: "-25.2896",
    Longitude: "28.2162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19009",
    WardLookupId: "2285",
    VDNumber: "86660589",
    RegisteredPopulation: "946",
    VotingStationName: "MATLAISANE HIGH SCHOOL",
    Address: "DERTIG  MAKAPANSTAD  BAKGATLA BA MOCHA",
    Latitude: "-25.2919",
    Longitude: "28.2241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19010",
    WardLookupId: "2285",
    VDNumber: "86660624",
    RegisteredPopulation: "678",
    VotingStationName: "RESOURCE CENTRE",
    Address: "BOSPLAAS WEST  BOSPLAAS",
    Latitude: "-25.3188",
    Longitude: "28.2501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19011",
    WardLookupId: "2285",
    VDNumber: "86661726",
    RegisteredPopulation: "357",
    VotingStationName: "RAMATLA PRIMARY SCHOOL",
    Address: "SUURMAN  ",
    Latitude: "-25.3456",
    Longitude: "28.2316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19012",
    WardLookupId: "2285",
    VDNumber: "86662536",
    RegisteredPopulation: "263",
    VotingStationName: "PLOT 33",
    Address: "33 PLOT 33 BOSPLAAS  BOSPLAAS 2  BOSPLAAS",
    Latitude: "-25.3213",
    Longitude: "28.2148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19013",
    WardLookupId: "2286",
    VDNumber: "86660286",
    RegisteredPopulation: "1737",
    VotingStationName: "MAKGATLHANONG PRIMARY SCHOOL",
    Address: "SCHOOL  MMAKAUNYANE  MMAKAUNYANE",
    Latitude: "-25.3625",
    Longitude: "28.0096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19014",
    WardLookupId: "2286",
    VDNumber: "86660679",
    RegisteredPopulation: "730",
    VotingStationName: "VICTORY RESTORATION FELLOWSHIP CHURCH",
    Address: "MORETELE   ",
    Latitude: "-25.3928",
    Longitude: "28.0442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19015",
    WardLookupId: "2286",
    VDNumber: "86660703",
    RegisteredPopulation: "642",
    VotingStationName: "MOTLHANA PRIMARY SCHOOL",
    Address: "GA-MOTLA  GAMOTLA",
    Latitude: "-25.359",
    Longitude: "28.0581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19016",
    WardLookupId: "2286",
    VDNumber: "86662367",
    RegisteredPopulation: "614",
    VotingStationName: "CHRIST THE ROCK OF GLORY MINISTRIES",
    Address: "TSHWENE  MAKAPANSTAD  MORETELE",
    Latitude: "-25.3824",
    Longitude: "28.0319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19017",
    WardLookupId: "2286",
    VDNumber: "86663111",
    RegisteredPopulation: "428",
    VotingStationName: "ITHUTENG HIGH SCHOOL",
    Address: "MORETELE  ",
    Latitude: "-25.341",
    Longitude: "28.0602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19018",
    WardLookupId: "2287",
    VDNumber: "86660455",
    RegisteredPopulation: "908",
    VotingStationName: "LEPONO PRIMARY SCHOOL",
    Address: "4434 RAMPERE  SECTION  MATHIBESTAD  MORETELE",
    Latitude: "-25.2635",
    Longitude: "28.1557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19019",
    WardLookupId: "2287",
    VDNumber: "86660466",
    RegisteredPopulation: "1014",
    VotingStationName: "SENTENG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MATHIBESTAD  MORETELE",
    Latitude: "-25.262",
    Longitude: "28.1686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19020",
    WardLookupId: "2287",
    VDNumber: "86660477",
    RegisteredPopulation: "936",
    VotingStationName: "MMAMPADI HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MATHIBESTAD  BAHWADUBA",
    Latitude: "-25.2682",
    Longitude: "28.1529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19021",
    WardLookupId: "2288",
    VDNumber: "86660657",
    RegisteredPopulation: "881",
    VotingStationName: "ST. JOHN APOSTOLIC CHURCH - VUMA",
    Address: "VUMA  MAKAPANSTAD  MORETELE",
    Latitude: "-25.333",
    Longitude: "28.0554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19022",
    WardLookupId: "2288",
    VDNumber: "86660668",
    RegisteredPopulation: "1239",
    VotingStationName: "KOSEA MOEKA PRIMARY SCHOOL",
    Address: "120 KMOEKA  MORETELE  MOEKA",
    Latitude: "-25.3371",
    Longitude: "28.054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19023",
    WardLookupId: "2288",
    VDNumber: "86660691",
    RegisteredPopulation: "903",
    VotingStationName: "ROMAN CATHOLIC CHURCH - GA MMOTLA",
    Address: "GA MMOTLA  MAKAPANSTAD  MORETELE",
    Latitude: "-25.3546",
    Longitude: "28.0637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19024",
    WardLookupId: "2288",
    VDNumber: "86662963",
    RegisteredPopulation: "1192",
    VotingStationName: "TOWER OF PRAISE CHRISTIAN CHURCH",
    Address: "GA-MOTLE  MORETELE [TEMBA]  MOTLE",
    Latitude: "-25.3507",
    Longitude: "28.0731",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19025",
    WardLookupId: "2288",
    VDNumber: "86663324",
    RegisteredPopulation: "600",
    VotingStationName: "WORD & FIRE MINISTRIES",
    Address: "MOEKA  MAKAPANSTAD  MORETELE",
    Latitude: "-25.3403",
    Longitude: "28.068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19026",
    WardLookupId: "2289",
    VDNumber: "86660680",
    RegisteredPopulation: "806",
    VotingStationName: "RETHUSITSWE COMBINED SCHOOL",
    Address: "KROMKUIL  WINTERVELDT",
    Latitude: "-25.3783",
    Longitude: "28.0668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19027",
    WardLookupId: "2289",
    VDNumber: "86660747",
    RegisteredPopulation: "787",
    VotingStationName: "LOALANE HIGH SCHOOL",
    Address: "RATJIEPAN  MORETELE  RATJIEPAN",
    Latitude: "-25.3426",
    Longitude: "28.0861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19028",
    WardLookupId: "2289",
    VDNumber: "86662817",
    RegisteredPopulation: "925",
    VotingStationName: "RAMOSHIE PRIMARY SCHOOL",
    Address: "MMAKAUNYANE  MORETELE",
    Latitude: "-25.3325",
    Longitude: "28.0902",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19029",
    WardLookupId: "2289",
    VDNumber: "86662952",
    RegisteredPopulation: "476",
    VotingStationName: "MOSONYANE MIDDLE SCHOOL",
    Address: "RATSIEPAN  TEMBA",
    Latitude: "-25.3348",
    Longitude: "28.0793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19030",
    WardLookupId: "2289",
    VDNumber: "86663346",
    RegisteredPopulation: "391",
    VotingStationName: "PHOLOKGOLO PRIMARY SCHOOL",
    Address: "RATJIEPAN  ",
    Latitude: "-25.3368",
    Longitude: "28.102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19031",
    WardLookupId: "4409",
    VDNumber: "86660039",
    RegisteredPopulation: "433",
    VotingStationName: "ATLANTA BOEREVEREENIGING SAAL",
    Address: "THABAZIMBI ROAD  ATLANTA  BRITS",
    Latitude: "-25.2068",
    Longitude: "27.5657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19032",
    WardLookupId: "4409",
    VDNumber: "86660040",
    RegisteredPopulation: "795",
    VotingStationName: "FAFUNG COMMUNITY HALL",
    Address: "FAFUNG ROAD   FAFUNG   FAFUNG COMMUNITY HALL CLUSTER 1",
    Latitude: "-25.1915",
    Longitude: "27.7913",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19033",
    WardLookupId: "4409",
    VDNumber: "86660051",
    RegisteredPopulation: "326",
    VotingStationName: "GA RASAI COMMUNITY HALL FAFUNG CLUSTER",
    Address: "RASAI AND KLIPVOOR ROAD   GA RASAI   GA RASAI",
    Latitude: "-25.1583",
    Longitude: "27.6904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19034",
    WardLookupId: "4409",
    VDNumber: "86660107",
    RegisteredPopulation: "302",
    VotingStationName: "MODISAKWANA PRIMARY SCHOOL",
    Address: "VIA RUSTENBURG RD  BEESTEKRAAL  BRITS",
    Latitude: "-25.3761",
    Longitude: "27.583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19035",
    WardLookupId: "4409",
    VDNumber: "86661333",
    RegisteredPopulation: "644",
    VotingStationName: "TSHEFOGE PRIMARY SCHOOL",
    Address: "N/A  JERICHO  BRITS",
    Latitude: "-25.2883",
    Longitude: "27.7208",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19036",
    WardLookupId: "4409",
    VDNumber: "86661355",
    RegisteredPopulation: "1070",
    VotingStationName: "LETHABONG PRIMARY SCHOOL",
    Address: "N/A  LEGONYANE  BRITS",
    Latitude: "-25.271",
    Longitude: "27.7783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19037",
    WardLookupId: "4409",
    VDNumber: "86662402",
    RegisteredPopulation: "123",
    VotingStationName: "KLIPVOORSTAD PRIMARY SCHOOL",
    Address: "KLIPVOORSTADT  BRITS",
    Latitude: "-25.0934",
    Longitude: "27.8055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19038",
    WardLookupId: "4409",
    VDNumber: "86662424",
    RegisteredPopulation: "251",
    VotingStationName: "SEPHAI TRIBAL HALL",
    Address: "22 SEPHAI  BRITS",
    Latitude: "-25.2455",
    Longitude: "27.8072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19039",
    WardLookupId: "4409",
    VDNumber: "86662446",
    RegisteredPopulation: "353",
    VotingStationName: "SEFATANARE COMBINED SCHOOL",
    Address: "KWARIEKRAAL VILLAGE  KWARIEKRAAL VILLAGE (MADIBENG  MADIBENG",
    Latitude: "-25.0516",
    Longitude: "27.6434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19040",
    WardLookupId: "4409",
    VDNumber: "86662457",
    RegisteredPopulation: "93",
    VotingStationName: "BUSH TOWN SHOP (TENT)",
    Address: "MATSHIKIRI  BEESTEKRAAL",
    Latitude: "-25.283",
    Longitude: "27.4843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19041",
    WardLookupId: "4409",
    VDNumber: "86662783",
    RegisteredPopulation: "204",
    VotingStationName: "DINALETSANA TRIBAL HALL",
    Address: "01 DINALETSANA  ROOIWAL  BRITS",
    Latitude: "-25.3158",
    Longitude: "27.7566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19042",
    WardLookupId: "4410",
    VDNumber: "86661287",
    RegisteredPopulation: "706",
    VotingStationName: "ROMAN CATHOLIC CHURCH JERICHO",
    Address: "JERICHO  MADIBENG [BRITS]",
    Latitude: "-25.3345",
    Longitude: "27.8146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19043",
    WardLookupId: "4410",
    VDNumber: "86661298",
    RegisteredPopulation: "811",
    VotingStationName: "CHARLES MAMOGALE PRIMARY SCHOOL",
    Address: "N/A  JERICHO  MADIBENG [BRITS",
    Latitude: "-25.339",
    Longitude: "27.8011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19044",
    WardLookupId: "4410",
    VDNumber: "86661300",
    RegisteredPopulation: "844",
    VotingStationName: "TRIBAL AUTHORITY HALL",
    Address: "JERICHO  MADIBENG [BRITS]",
    Latitude: "-25.3281",
    Longitude: "27.804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19045",
    WardLookupId: "4410",
    VDNumber: "86661311",
    RegisteredPopulation: "754",
    VotingStationName: "MADIBA-A-TOLOANE HIGH SCHOOL",
    Address: "N/A  JERICHO  MADIBENG [BRITS]",
    Latitude: "-25.316",
    Longitude: "27.7923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19046",
    WardLookupId: "4410",
    VDNumber: "86661322",
    RegisteredPopulation: "800",
    VotingStationName: "TUMELO KE THEBE DAY CARE CENTRE",
    Address: "1650 RASEGWATI SECTION   JERICHO  BAKWENA BA MOGOPA",
    Latitude: "-25.35",
    Longitude: "27.8071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19047",
    WardLookupId: "4410",
    VDNumber: "86662435",
    RegisteredPopulation: "545",
    VotingStationName: "TSOGWE PRIMARY SCHOOL",
    Address: "MMUPUDUNG VILLAGE  BRITS",
    Latitude: "-25.3639",
    Longitude: "27.7523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19048",
    WardLookupId: "4410",
    VDNumber: "86662468",
    RegisteredPopulation: "503",
    VotingStationName: "BAKWENA GENERA DEALER",
    Address: "N/A  BRITS  JERICHO VILLAGE",
    Latitude: "-25.3318",
    Longitude: "27.79",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19049",
    WardLookupId: "4410",
    VDNumber: "86663357",
    RegisteredPopulation: "409",
    VotingStationName: "MAKOPYE MORE MIDDLE SCHOOL",
    Address: "N/A  JERICHO  JERICHO",
    Latitude: "-25.344",
    Longitude: "27.8151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19050",
    WardLookupId: "4411",
    VDNumber: "86661513",
    RegisteredPopulation: "995",
    VotingStationName: "NTOLO HIGH SCHOOL",
    Address: "N/A  MADIDI VILLAGE  BRITS",
    Latitude: "-25.4557",
    Longitude: "27.9464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19051",
    WardLookupId: "4411",
    VDNumber: "86661524",
    RegisteredPopulation: "896",
    VotingStationName: "MPYATILO PRIMARY SCHOOL",
    Address: "N/A  MADIDI  BRITS",
    Latitude: "-25.4546",
    Longitude: "27.9316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19052",
    WardLookupId: "4411",
    VDNumber: "86661535",
    RegisteredPopulation: "1120",
    VotingStationName: "ROMAN CHURCH MADIDI",
    Address: "MADIDI  BRITS",
    Latitude: "-25.4436",
    Longitude: "27.9382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19053",
    WardLookupId: "4411",
    VDNumber: "86661546",
    RegisteredPopulation: "1077",
    VotingStationName: "HALL 15",
    Address: "MADIDI VILLAGE  BRITS",
    Latitude: "-25.4417",
    Longitude: "27.9581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19054",
    WardLookupId: "4411",
    VDNumber: "86663368",
    RegisteredPopulation: "415",
    VotingStationName: "MMAMONWANA PRIMARY SCHOOL",
    Address: "N/A  MADIDI  MADIDI",
    Latitude: "-25.4482",
    Longitude: "27.9484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19055",
    WardLookupId: "4412",
    VDNumber: "86661388",
    RegisteredPopulation: "790",
    VotingStationName: "PHUTHANANG PRIMARY SCHOOL",
    Address: "N/A  MABOLOKA VILLAGE  BRITS",
    Latitude: "-25.4323",
    Longitude: "27.8557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19056",
    WardLookupId: "4412",
    VDNumber: "86661399",
    RegisteredPopulation: "393",
    VotingStationName: "ROMAN CATHOLIC CHURCH HALL",
    Address: "MABOLOKA VILLAGE  BRITS",
    Latitude: "-25.4262",
    Longitude: "27.8555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19057",
    WardLookupId: "4412",
    VDNumber: "86661401",
    RegisteredPopulation: "1307",
    VotingStationName: "BATAUNG TRIBAL AUTHORITY HALL",
    Address: "MABOLOKA  BRITS",
    Latitude: "-25.4291",
    Longitude: "27.8603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19058",
    WardLookupId: "4412",
    VDNumber: "86661478",
    RegisteredPopulation: "671",
    VotingStationName: "GUMA ST MARKS CHURCH",
    Address: "MABOLOKA  BRITS",
    Latitude: "-25.4216",
    Longitude: "27.8525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19059",
    WardLookupId: "4412",
    VDNumber: "86661489",
    RegisteredPopulation: "1054",
    VotingStationName: "GOSPEL ASSEMBLY FELLOWSHIP",
    Address: "SESHUBA PLACE  DOORNKOP SECTION  MABOLOKA",
    Latitude: "-25.4199",
    Longitude: "27.8597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19060",
    WardLookupId: "4412",
    VDNumber: "86661490",
    RegisteredPopulation: "402",
    VotingStationName: "DUTCH REFORMED CHURCH",
    Address: "MABOLOKA VILLAGE  BRITS",
    Latitude: "-25.4276",
    Longitude: "27.8521",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19061",
    WardLookupId: "4413",
    VDNumber: "86661434",
    RegisteredPopulation: "1167",
    VotingStationName: "KOMANE PRIMARY SCHOOL",
    Address: "N/A  MABOLOKA VILLAGE  BRITS",
    Latitude: "-25.4282",
    Longitude: "27.8458",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19062",
    WardLookupId: "4413",
    VDNumber: "86661456",
    RegisteredPopulation: "2507",
    VotingStationName: "THUTO-PELE SECONDARY SCHOOL",
    Address: "N/A  MABOLOKA VILLAGE , ITIRELENG SECTION  MABOLOKA",
    Latitude: "-25.4283",
    Longitude: "27.8364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19063",
    WardLookupId: "4413",
    VDNumber: "86661467",
    RegisteredPopulation: "645",
    VotingStationName: "MOLEMIS CHURCH",
    Address: "MABOLOKA  BRITS",
    Latitude: "-25.4214",
    Longitude: "27.8431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19064",
    WardLookupId: "4413",
    VDNumber: "86662491",
    RegisteredPopulation: "1169",
    VotingStationName: "B K GUMA PRIMARY SCHOOL",
    Address: "RDP-MABOLOKA   MOBOLOKA   (NW372 - MADIBENG [BRITS])",
    Latitude: "-25.4445",
    Longitude: "27.8313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19065",
    WardLookupId: "4414",
    VDNumber: "86661366",
    RegisteredPopulation: "1190",
    VotingStationName: "SOLOMON MIDDLE SCHOOL",
    Address: "N/A  MABOLOKA VILLAGE  BRITS",
    Latitude: "-25.4494",
    Longitude: "27.8631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19066",
    WardLookupId: "4414",
    VDNumber: "86661377",
    RegisteredPopulation: "1467",
    VotingStationName: "FULL GOSPEL CHURCH",
    Address: "MABOLOKA VILLAGE  BRITS",
    Latitude: "-25.443",
    Longitude: "27.86",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19067",
    WardLookupId: "4414",
    VDNumber: "86661412",
    RegisteredPopulation: "1086",
    VotingStationName: "MPHATSOES CHURCH",
    Address: "MABOLOKA VILLAGE  BRITS",
    Latitude: "-25.4407",
    Longitude: "27.85",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19068",
    WardLookupId: "4414",
    VDNumber: "86661423",
    RegisteredPopulation: "1316",
    VotingStationName: "JUNIOR SPORT HALL",
    Address: "MABOLOKA  BRITS",
    Latitude: "-25.4452",
    Longitude: "27.837",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19069",
    WardLookupId: "4415",
    VDNumber: "86661658",
    RegisteredPopulation: "1991",
    VotingStationName: "ROMAN CHURCH MAJAKANENG",
    Address: "N/A  MAJAKANENG  MAJAKANENG",
    Latitude: "-25.7339",
    Longitude: "27.6714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19070",
    WardLookupId: "4415",
    VDNumber: "86661715",
    RegisteredPopulation: "1495",
    VotingStationName: "MAJAKANENG PRIMARY SCHOOL",
    Address: "N/A  MAJAKANENG VILLAGE  BRITS",
    Latitude: "-25.7479",
    Longitude: "27.6722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19071",
    WardLookupId: "4415",
    VDNumber: "86661737",
    RegisteredPopulation: "1396",
    VotingStationName: "ASSEMBLIES OF GOD MAJAKANENG",
    Address: "MAJAKANENG  BAPO BA MOGALE",
    Latitude: "-25.7412",
    Longitude: "27.6761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19072",
    WardLookupId: "4415",
    VDNumber: "86663379",
    RegisteredPopulation: "1270",
    VotingStationName: "MZANZI GENERAL DEALER MAJAKANENG",
    Address: "N/A  MAJAKANENG  MAJAKANENG",
    Latitude: "-25.7378",
    Longitude: "27.6671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19073",
    WardLookupId: "4416",
    VDNumber: "86661670",
    RegisteredPopulation: "1114",
    VotingStationName: "GOAKGANYA PRIMARY SCHOOL",
    Address: "N/A  KLIPGAT VILLAGE  BRITS",
    Latitude: "-25.442",
    Longitude: "27.9787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19074",
    WardLookupId: "4416",
    VDNumber: "86661681",
    RegisteredPopulation: "2379",
    VotingStationName: "MARANG PRIMARY SCHOOL",
    Address: "N/A  KLIPGAT VILLAGE  BRITS",
    Latitude: "-25.4603",
    Longitude: "27.9951",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19075",
    WardLookupId: "4416",
    VDNumber: "86663492",
    RegisteredPopulation: "2654",
    VotingStationName: "TENT AT MADIBENG HILLS SOCCER GROUND",
    Address: "MAKANYANENG  MADIBENG HILLS  MADIBENG HILLS",
    Latitude: "-25.463",
    Longitude: "28.0269",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19076",
    WardLookupId: "4417",
    VDNumber: "86670108",
    RegisteredPopulation: "2419",
    VotingStationName: "TSHEPAGALANG SECONDARY SCHOOL",
    Address: "N/A  LETLHABILE LOCATION  BRITS",
    Latitude: "-25.4768",
    Longitude: "27.8393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19077",
    WardLookupId: "4417",
    VDNumber: "86670209",
    RegisteredPopulation: "1929",
    VotingStationName: "OSALETSENG PRIMARY SCHOOL",
    Address: "N/A  LETLHABILE  BRITS",
    Latitude: "-25.4689",
    Longitude: "27.8473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19078",
    WardLookupId: "4417",
    VDNumber: "86670276",
    RegisteredPopulation: "780",
    VotingStationName: "TLHOAFALO PRIMARY SCHOOL",
    Address: "LETLHABILE  BRITS  LETLHABILE TOWNSHIP",
    Latitude: "-25.478",
    Longitude: "27.8432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19079",
    WardLookupId: "4418",
    VDNumber: "86661962",
    RegisteredPopulation: "2851",
    VotingStationName: "MICHA KGASI HIGH SCHOOL",
    Address: "N/A  KGABALATSANE VILLAGE  BRITS",
    Latitude: "-25.5387",
    Longitude: "27.988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19080",
    WardLookupId: "4418",
    VDNumber: "86662075",
    RegisteredPopulation: "1333",
    VotingStationName: "MERITING MIDDLE SCHOOL",
    Address: "N/A  KGABALATSANE VILLAGE  BRITS",
    Latitude: "-25.5341",
    Longitude: "27.9702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19081",
    WardLookupId: "4418",
    VDNumber: "86662086",
    RegisteredPopulation: "1417",
    VotingStationName: "KGABALATSANE PRIMARY SCHOOL",
    Address: "N/A  KGABALATSANE VILLAGE  BRITS",
    Latitude: "-25.5358",
    Longitude: "27.9533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19082",
    WardLookupId: "4418",
    VDNumber: "86663380",
    RegisteredPopulation: "1319",
    VotingStationName: "THE SAINT JACOB REVALATIONS CHURCH",
    Address: "KAGISANO  MADIBENG   BRITS",
    Latitude: "-25.5491",
    Longitude: "27.9725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19083",
    WardLookupId: "4419",
    VDNumber: "86670175",
    RegisteredPopulation: "2946",
    VotingStationName: "ITUMELENG PRIMARY SCHOOL",
    Address: "N/A  LETLHABILE LOCATION  BRITS",
    Latitude: "-25.4804",
    Longitude: "27.8335",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19084",
    WardLookupId: "4419",
    VDNumber: "86670221",
    RegisteredPopulation: "2279",
    VotingStationName: "B. MPOZA CHRISTIAN COLLEGE",
    Address: "BLOCK I  LETHLABILE  BRITS",
    Latitude: "-25.4631",
    Longitude: "27.8347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19085",
    WardLookupId: "4419",
    VDNumber: "86670344",
    RegisteredPopulation: "487",
    VotingStationName: "TENT AT LUTHERAN CHURCH BLOCK B LETLHABILE",
    Address: "BLOCK B MAPANTSULENG   LETLHABILE  BRITS",
    Latitude: "-25.4731",
    Longitude: "27.8358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19086",
    WardLookupId: "4420",
    VDNumber: "86670074",
    RegisteredPopulation: "1341",
    VotingStationName: "MOLELWANENG PRIMARY SCHOOL",
    Address: "N/A  LETLHABILE LOCATION  BRITS",
    Latitude: "-25.4816",
    Longitude: "27.839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19087",
    WardLookupId: "4420",
    VDNumber: "86670085",
    RegisteredPopulation: "252",
    VotingStationName: "LETLHABILE TOWN OFFICE",
    Address: "LETLHABILE  BRITS",
    Latitude: "-25.4939",
    Longitude: "27.8373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19088",
    WardLookupId: "4420",
    VDNumber: "86670119",
    RegisteredPopulation: "1877",
    VotingStationName: "TSHENOLO PRIMARY SCHOOL",
    Address: "N/A  LETLHABILE LOCATION  BRITS",
    Latitude: "-25.4878",
    Longitude: "27.8486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19089",
    WardLookupId: "4420",
    VDNumber: "86670120",
    RegisteredPopulation: "1321",
    VotingStationName: "LETLHABILE COMMUNITY HALL",
    Address: "LETLHABILE  BRITS",
    Latitude: "-25.4882",
    Longitude: "27.8423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19090",
    WardLookupId: "4420",
    VDNumber: "86670298",
    RegisteredPopulation: "1082",
    VotingStationName: "RELEBOGILE PRIMARY SCHOOL",
    Address: "N/A  LETLHABILE  LETLHABILE",
    Latitude: "-25.4936",
    Longitude: "27.8541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19091",
    WardLookupId: "4421",
    VDNumber: "86670030",
    RegisteredPopulation: "2383",
    VotingStationName: "OUKASIE COMMUNITY HALL",
    Address: "OUKASIE  OUKASIE VILLAGE  BRITS",
    Latitude: "-25.6084",
    Longitude: "27.7909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19092",
    WardLookupId: "4421",
    VDNumber: "86670142",
    RegisteredPopulation: "1907",
    VotingStationName: "ODI PRIMARY SCHOOL",
    Address: "OUKASIE  OUKASIE VILLAGE  BRITS",
    Latitude: "-25.6072",
    Longitude: "27.7934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19093",
    WardLookupId: "4421",
    VDNumber: "86670300",
    RegisteredPopulation: "829",
    VotingStationName: "ST  PETERS CHURCH OUKASIE",
    Address: "N/A  OUKASIE  OUKASIE",
    Latitude: "-25.6044",
    Longitude: "27.7897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19094",
    WardLookupId: "4421",
    VDNumber: "86670322",
    RegisteredPopulation: "783",
    VotingStationName: "KUTLWANO PRIMARY SCHOOL",
    Address: "N/A  BRITS  OUKASIE",
    Latitude: "-25.6033",
    Longitude: "27.7977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19095",
    WardLookupId: "4422",
    VDNumber: "86661872",
    RegisteredPopulation: "1016",
    VotingStationName: "SANDRIFT PRIMARY SCHOOL",
    Address: "VIA THABAZIMBI ROAD  SANDRIFT  BRITS",
    Latitude: "-25.4807",
    Longitude: "27.685",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19096",
    WardLookupId: "4422",
    VDNumber: "86661906",
    RegisteredPopulation: "570",
    VotingStationName: "KLEINFONTEIN COMBINED SCHOOL",
    Address: "THABAZIMBI RD  KLEINFONTEIN  BRITS",
    Latitude: "-25.5246",
    Longitude: "27.7529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19097",
    WardLookupId: "4422",
    VDNumber: "86662323",
    RegisteredPopulation: "623",
    VotingStationName: "VOORWAARTS PRIMARY SCHOOL",
    Address: "LANGSTRAAT  MAMOGALIESKRAAL  BRITS",
    Latitude: "-25.5389",
    Longitude: "27.8134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19098",
    WardLookupId: "4422",
    VDNumber: "86662345",
    RegisteredPopulation: "204",
    VotingStationName: "TENT @ SNYMANSDRIFT PRIMARY SCHOOL",
    Address: "BETHANIE ROAD  SOUTPANSDRIFT  GELUK FARMS",
    Latitude: "-25.529",
    Longitude: "27.65",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19099",
    WardLookupId: "4422",
    VDNumber: "86663391",
    RegisteredPopulation: "300",
    VotingStationName: "GREEN STORE",
    Address: "THABAZIMBI ROAD  BRITS  SANDRFIFT",
    Latitude: "-25.4165",
    Longitude: "27.6234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19100",
    WardLookupId: "4422",
    VDNumber: "86670232",
    RegisteredPopulation: "2710",
    VotingStationName: "OUKASIE EXT 5 (TENT)",
    Address: "(NW372 - MADIBENG [BRITS])   ",
    Latitude: "-25.5998",
    Longitude: "27.7903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19101",
    WardLookupId: "4423",
    VDNumber: "86661748",
    RegisteredPopulation: "2001",
    VotingStationName: "TLHASEDI PRIMARY SCHOOL",
    Address: "N/A  HEBRON VILLAGE  BRITS",
    Latitude: "-25.5538",
    Longitude: "28.0335",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19102",
    WardLookupId: "4423",
    VDNumber: "86661849",
    RegisteredPopulation: "2276",
    VotingStationName: "ITSOSENG COMMUNITY HALL",
    Address: "ITSOSENG VILLAGE - ERASMUS  BRITS",
    Latitude: "-25.5524",
    Longitude: "28.043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19103",
    WardLookupId: "4423",
    VDNumber: "86663403",
    RegisteredPopulation: "395",
    VotingStationName: "MMANOTSHE MODUANE HIGH SCHOOL",
    Address: "N/A  HEBRON  HEBRON",
    Latitude: "-25.5458",
    Longitude: "28.0185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19104",
    WardLookupId: "4423",
    VDNumber: "86663414",
    RegisteredPopulation: "588",
    VotingStationName: "AMPLIFIED CHRISTIAN MINISTRY",
    Address: "N/A  ITSOSENG  ITSOSENG",
    Latitude: "-25.5524",
    Longitude: "28.045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19105",
    WardLookupId: "4424",
    VDNumber: "86662020",
    RegisteredPopulation: "1616",
    VotingStationName: "TLHAKO A THAMAGA PRIMARY SCHOOL",
    Address: "N/A  HEBRON VILLAGE  BRITS",
    Latitude: "-25.5433",
    Longitude: "28.0286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19106",
    WardLookupId: "4424",
    VDNumber: "86662312",
    RegisteredPopulation: "1976",
    VotingStationName: "MADIBA UTLWA PRIMARY SCHOOL",
    Address: "ITOSENG  ITSOSENG VILLAGE - ERASMUS  BRITS",
    Latitude: "-25.5452",
    Longitude: "28.0371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19107",
    WardLookupId: "4424",
    VDNumber: "86663177",
    RegisteredPopulation: "801",
    VotingStationName: "ROMAN CATHOLIC CHURCH ITSOSENG",
    Address: "ITSOSENG   HEBRON   BRITS",
    Latitude: "-25.5491",
    Longitude: "28.0333",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19108",
    WardLookupId: "4424",
    VDNumber: "86663425",
    RegisteredPopulation: "586",
    VotingStationName: "ITSOSENG COMBINED SCHOOL",
    Address: "N/A  BRSITS  ITSOSENG",
    Latitude: "-25.5405",
    Longitude: "28.0373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19109",
    WardLookupId: "4425",
    VDNumber: "86661940",
    RegisteredPopulation: "831",
    VotingStationName: "MALINGOANE",
    Address: "N/A  RAMOGODI  MMAKAU",
    Latitude: "-25.6113",
    Longitude: "27.9718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19110",
    WardLookupId: "4425",
    VDNumber: "86661995",
    RegisteredPopulation: "940",
    VotingStationName: "PHATSOANE STORE",
    Address: "LENTSWE  MAPETLA  MMAKAU",
    Latitude: "-25.6006",
    Longitude: "27.9701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19111",
    WardLookupId: "4425",
    VDNumber: "86662019",
    RegisteredPopulation: "1073",
    VotingStationName: "POLONIA PRIMARY SCHOOL",
    Address: "MAKAU VILLAGE  BRITS",
    Latitude: "-25.6165",
    Longitude: "27.9615",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19112",
    WardLookupId: "4425",
    VDNumber: "86662031",
    RegisteredPopulation: "1616",
    VotingStationName: "RAMOGODU COMMUNITY HALL",
    Address: "RAMOGODU  BRITS",
    Latitude: "-25.6137",
    Longitude: "27.9659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19113",
    WardLookupId: "4425",
    VDNumber: "86662693",
    RegisteredPopulation: "1128",
    VotingStationName: "JEALOUS DOWN (TENT)",
    Address: "MAPETLA SECTION  MMAKAU VILLAGE  BRITS",
    Latitude: "-25.6058",
    Longitude: "27.9692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19114",
    WardLookupId: "4426",
    VDNumber: "86661782",
    RegisteredPopulation: "1323",
    VotingStationName: "THLOPANE PRIMARY SCHOOL",
    Address: "N/A  MMAKAU- RAMOLAPONG SECTION  MADIBENG",
    Latitude: "-25.6106",
    Longitude: "27.9312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19115",
    WardLookupId: "4426",
    VDNumber: "86661951",
    RegisteredPopulation: "1473",
    VotingStationName: "EMMANUEL OLD AGE HOME",
    Address: "NINIVA SECTION  MMAKAU VILLAGE  BRITS",
    Latitude: "-25.6099",
    Longitude: "27.9509",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19116",
    WardLookupId: "4426",
    VDNumber: "86662064",
    RegisteredPopulation: "906",
    VotingStationName: "MANGOPENG",
    Address: "N/A  MMAKAU  BAKGATLA BA MMAKAU",
    Latitude: "-25.6178",
    Longitude: "27.9506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19117",
    WardLookupId: "4426",
    VDNumber: "86663009",
    RegisteredPopulation: "674",
    VotingStationName: "BAKGATLA TRIBAL AUTHORITY",
    Address: "MMAKAU VILLAGE  BRITS",
    Latitude: "-25.6155",
    Longitude: "27.9413",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19118",
    WardLookupId: "4426",
    VDNumber: "86663436",
    RegisteredPopulation: "178",
    VotingStationName: "MOREKOLODI PRIMARY SCHOOL",
    Address: "N/A  MMAKAU  MMAKAU",
    Latitude: "-25.6061",
    Longitude: "27.9471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19119",
    WardLookupId: "4427",
    VDNumber: "86661760",
    RegisteredPopulation: "1525",
    VotingStationName: "MALATSE MOTSEPE HIGH SCHOOL",
    Address: "N/A  MMAKAU (CENTRAL)  BRITS",
    Latitude: "-25.6187",
    Longitude: "27.9401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19120",
    WardLookupId: "4427",
    VDNumber: "86661850",
    RegisteredPopulation: "1129",
    VotingStationName: "MOUMONG STORE",
    Address: "MMAKAU -  THETELE - SWITCH  SECTION  MADIBENG",
    Latitude: "-25.6116",
    Longitude: "27.9118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19121",
    WardLookupId: "4427",
    VDNumber: "86661894",
    RegisteredPopulation: "733",
    VotingStationName: "BLACK ROCK CHURCH",
    Address: "BLACK ROCK SECTION -MMAKAU  MADIBENG",
    Latitude: "-25.6114",
    Longitude: "27.917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19122",
    WardLookupId: "4427",
    VDNumber: "86661939",
    RegisteredPopulation: "316",
    VotingStationName: "DE-WILDT BIKE RESTAURANT",
    Address: "N/A  BRITS  BRITS",
    Latitude: "-25.6269",
    Longitude: "27.9473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19123",
    WardLookupId: "4427",
    VDNumber: "86662479",
    RegisteredPopulation: "1297",
    VotingStationName: "TSHENOLO THE HOLY CHURCH",
    Address: "MOTHOTLUNG MMAKAU ROAD  MMAKAU  BRITS",
    Latitude: "-25.6122",
    Longitude: "27.9051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19124",
    WardLookupId: "4427",
    VDNumber: "86663447",
    RegisteredPopulation: "619",
    VotingStationName: "TSHWARA HALL",
    Address: "N/A  MMAKAU  TSHWARA",
    Latitude: "-25.6245",
    Longitude: "27.9339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19125",
    WardLookupId: "4428",
    VDNumber: "86670052",
    RegisteredPopulation: "1278",
    VotingStationName: "KATAKANE PRIMARY SCHOOL",
    Address: "N/A  MOTHOTLUNG VILLAGE  BRITS",
    Latitude: "-25.5985",
    Longitude: "27.8812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19126",
    WardLookupId: "4428",
    VDNumber: "86670063",
    RegisteredPopulation: "1737",
    VotingStationName: "MOTHOTLUNG HIGH SCHOOL",
    Address: "N/A  MOTHOTLUNG LOCATION  BRITS",
    Latitude: "-25.6065",
    Longitude: "27.8874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19127",
    WardLookupId: "4428",
    VDNumber: "86670153",
    RegisteredPopulation: "1874",
    VotingStationName: "MOTHOTLUNG TOWN OFFICES",
    Address: "MOTHOTLUNG VILLAGE  BRITS",
    Latitude: "-25.6028",
    Longitude: "27.8839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19128",
    WardLookupId: "4429",
    VDNumber: "86661759",
    RegisteredPopulation: "403",
    VotingStationName: "PLOT 77 ZILKAATSNEK",
    Address: "77 ZILKAATSNEK  ZIKAATSNEK  BRITS",
    Latitude: "-25.6584",
    Longitude: "27.9119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19129",
    WardLookupId: "4429",
    VDNumber: "86661771",
    RegisteredPopulation: "1292",
    VotingStationName: "TENT AT RAS PRIMARY SCHOOL",
    Address: "R511 ROAD  DE KROON  BRITS",
    Latitude: "-25.664",
    Longitude: "27.8312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19130",
    WardLookupId: "4429",
    VDNumber: "86670029",
    RegisteredPopulation: "1814",
    VotingStationName: "IB DAMONS COMBINED SCHOOLS",
    Address: "15 STRELITZIAS ROAD  DAMONSVILLE  BRITS",
    Latitude: "-25.6264",
    Longitude: "27.8492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19131",
    WardLookupId: "4429",
    VDNumber: "86670197",
    RegisteredPopulation: "1429",
    VotingStationName: "APOSTOLIC FAITH MISSION OF SOUTH AFRICA",
    Address: "PAVEMENT ROAD AT EXT 1 MOTHOTL  MOTHOTLUNG  BRITS",
    Latitude: "-25.6081",
    Longitude: "27.8799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19132",
    WardLookupId: "4429",
    VDNumber: "86670254",
    RegisteredPopulation: "1048",
    VotingStationName: "TENT AT SEDIBENG METHODIST CHURCH",
    Address: "DAMONSVILLE EXTENTION  DAMONSVILLE  BRITS",
    Latitude: "-25.6235",
    Longitude: "27.854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19133",
    WardLookupId: "4429",
    VDNumber: "86670311",
    RegisteredPopulation: "738",
    VotingStationName: "BETHEL REFORM CHURCH IN SOUTH AFRICA",
    Address: "MOTHOTLUNG ROAD  MOTHOTLUNG  BRITS",
    Latitude: "-25.607",
    Longitude: "27.8758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19134",
    WardLookupId: "4430",
    VDNumber: "86670096",
    RegisteredPopulation: "3364",
    VotingStationName: "BRITS HIGH SCHOOL",
    Address: "JOHAN STREET  BRITS CBD  BRITS",
    Latitude: "-25.6264",
    Longitude: "27.7858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19135",
    WardLookupId: "4430",
    VDNumber: "86670265",
    RegisteredPopulation: "1282",
    VotingStationName: "DUTCH REFORMED CHURCH OUKASIE",
    Address: "OUKASIE  BRITS  OUKASIE TOWNSHIP",
    Latitude: "-25.6134",
    Longitude: "27.7901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19136",
    WardLookupId: "4430",
    VDNumber: "86670333",
    RegisteredPopulation: "780",
    VotingStationName: "TENT AT ETHOPIAN CATHOLIC CHURCH",
    Address: "GREEN SIDE SECTION   OUKASIE   BRITS",
    Latitude: "-25.6089",
    Longitude: "27.7974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19137",
    WardLookupId: "4431",
    VDNumber: "86670164",
    RegisteredPopulation: "3541",
    VotingStationName: "BRITS TOWN OFFICES",
    Address: "VAN VELDEN STREET  BRITS TOWN  BRITS",
    Latitude: "-25.6346",
    Longitude: "27.7759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19138",
    WardLookupId: "4431",
    VDNumber: "86670210",
    RegisteredPopulation: "625",
    VotingStationName: "PRIMINDIA HALL",
    Address: "BRITS  ",
    Latitude: "-25.6458",
    Longitude: "27.7794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19139",
    WardLookupId: "4431",
    VDNumber: "86670287",
    RegisteredPopulation: "704",
    VotingStationName: "LAERSKOOL BRITS",
    Address: "BRITS  ",
    Latitude: "-25.6455",
    Longitude: "27.7731",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19140",
    WardLookupId: "4432",
    VDNumber: "86661557",
    RegisteredPopulation: "627",
    VotingStationName: "RANTODI CENTRE",
    Address: "KLIPGAT  MADIBENG [BRITS]",
    Latitude: "-25.4515",
    Longitude: "27.9701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19141",
    WardLookupId: "4432",
    VDNumber: "86661568",
    RegisteredPopulation: "1743",
    VotingStationName: "MAHLABE PRIMARY SCHOOL",
    Address: "N/A  KLIPGAT VILLAGE  BRITS",
    Latitude: "-25.4762",
    Longitude: "27.97",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19142",
    WardLookupId: "4432",
    VDNumber: "86661579",
    RegisteredPopulation: "1515",
    VotingStationName: "DIRANG PRIMARY SCHOOL",
    Address: "N/A  KLIPGAT VILLAGE  BRITS",
    Latitude: "-25.4648",
    Longitude: "27.9834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19143",
    WardLookupId: "4432",
    VDNumber: "86663144",
    RegisteredPopulation: "377",
    VotingStationName: "LODIES SUPERMARKET",
    Address: "N/A  BRITS  KLIPGAT",
    Latitude: "-25.483",
    Longitude: "27.9947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19144",
    WardLookupId: "4432",
    VDNumber: "86663155",
    RegisteredPopulation: "725",
    VotingStationName: "THE REPUBLIC OF APOSTOLIC CHURCH OF ZION",
    Address: "KLIPGAT  BRITS  KLIPGAT",
    Latitude: "-25.4714",
    Longitude: "27.9926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19145",
    WardLookupId: "4433",
    VDNumber: "86661692",
    RegisteredPopulation: "595",
    VotingStationName: "KHULUSA PRIMARY SCHOOL",
    Address: "N/A  ZANDFONTEIN  MADIBENG",
    Latitude: "-25.6884",
    Longitude: "27.7883",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19146",
    WardLookupId: "4433",
    VDNumber: "86661917",
    RegisteredPopulation: "322",
    VotingStationName: "ROOIKOPPIES PRIMARY SCHOOL",
    Address: "VIA LANGBERG  ROOIKOPPIES  BRITS",
    Latitude: "-25.5871",
    Longitude: "27.7358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19147",
    WardLookupId: "4433",
    VDNumber: "86662187",
    RegisteredPopulation: "1462",
    VotingStationName: "ITIRELENG BAPO PRE-SCHOOL",
    Address: "296B SKOOLPLAAS  BAPONG  BAPO BA MOGALE",
    Latitude: "-25.7034",
    Longitude: "27.6798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19148",
    WardLookupId: "4433",
    VDNumber: "86662198",
    RegisteredPopulation: "1146",
    VotingStationName: "N.G KERK",
    Address: "BAPONG VILLAGE  BRITS",
    Latitude: "-25.7084",
    Longitude: "27.6701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19149",
    WardLookupId: "4433",
    VDNumber: "86662200",
    RegisteredPopulation: "637",
    VotingStationName: "TENT @ TLHOGOKGOLO PRIMARY SCHOOL",
    Address: "15 BAPONG  OUSTAD SECTION  BAPO BA MOGALE",
    Latitude: "-25.6902",
    Longitude: "27.7008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19150",
    WardLookupId: "4433",
    VDNumber: "86663043",
    RegisteredPopulation: "1309",
    VotingStationName: "BOKFONTEIN COMMUNITY HALL",
    Address: "HARTEBEESPOORT ROAD   BOKFONTEIN  BRITS",
    Latitude: "-25.6977",
    Longitude: "27.7578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19151",
    WardLookupId: "4433",
    VDNumber: "86663469",
    RegisteredPopulation: "450",
    VotingStationName: "MOGALE HIGH SCHOOL",
    Address: "N/A  BRITS  BAPONG",
    Latitude: "-25.6995",
    Longitude: "27.6772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19152",
    WardLookupId: "4434",
    VDNumber: "86662738",
    RegisteredPopulation: "4131",
    VotingStationName: "RAKGATLHA HIGH SCHOOL",
    Address: "STAND 223 WONDERKOP VILLAGE B  NKANYENG SECTION  BRITS",
    Latitude: "-25.6719",
    Longitude: "27.5211",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19153",
    WardLookupId: "4434",
    VDNumber: "86662974",
    RegisteredPopulation: "2209",
    VotingStationName: "TENT AT WONDERKOP CLINIC",
    Address: "WONDERKOP  DIKHIBIDUNG SECTION  BAPONG",
    Latitude: "-25.6726",
    Longitude: "27.5267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19154",
    WardLookupId: "4435",
    VDNumber: "86661805",
    RegisteredPopulation: "1652",
    VotingStationName: "MACHADAM COMBINED SCHOOL",
    Address: "N/A  MOOINOOI  BRITS",
    Latitude: "-25.7202",
    Longitude: "27.5567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19155",
    WardLookupId: "4435",
    VDNumber: "86662165",
    RegisteredPopulation: "1620",
    VotingStationName: "LEOKENG PRIMARY SCHOOL",
    Address: "N/A  MODDERSPRUIT VILLAGE  BRITS",
    Latitude: "-25.721",
    Longitude: "27.6484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19156",
    WardLookupId: "4435",
    VDNumber: "87010051",
    RegisteredPopulation: "2965",
    VotingStationName: "LAERSKOOL ELANDSKRAAL",
    Address: "MEERLUST AVENUE  MOOINOOI  BRITS",
    Latitude: "-25.7437",
    Longitude: "27.557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19157",
    WardLookupId: "4436",
    VDNumber: "86661669",
    RegisteredPopulation: "2031",
    VotingStationName: "MAJAKANENG WHITE HOUSE",
    Address: "MAJAKANENG MAIN ROAD  MAJAKANENG VILLAGE  BRITS",
    Latitude: "-25.7344",
    Longitude: "27.6782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19158",
    WardLookupId: "4436",
    VDNumber: "86662176",
    RegisteredPopulation: "1867",
    VotingStationName: "SEGWETLHANE PRIMARY SCHOOL",
    Address: "N/A  BAPONG VILLAGE  BRITS",
    Latitude: "-25.7196",
    Longitude: "27.6684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19159",
    WardLookupId: "4436",
    VDNumber: "86662514",
    RegisteredPopulation: "1123",
    VotingStationName: "JOHANE MOKOLOBETSI INTERMEDIATE SCHOOL",
    Address: "N/A  BAPONG VILLAGE  MADIBENG",
    Latitude: "-25.7174",
    Longitude: "27.6564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19160",
    WardLookupId: "4437",
    VDNumber: "86663054",
    RegisteredPopulation: "1503",
    VotingStationName: "SCHAUMBURG PRIMARY SCHOOL",
    Address: "(NW372 - MADIBENG [BRITS])   ",
    Latitude: "-25.8445",
    Longitude: "27.7045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19161",
    WardLookupId: "4437",
    VDNumber: "86680019",
    RegisteredPopulation: "1870",
    VotingStationName: "KOSMOS COMMUNITY HALL",
    Address: "BECKLANE STREET  KOSMOS  HARTBEESPOORT",
    Latitude: "-25.7414",
    Longitude: "27.847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19162",
    WardLookupId: "4437",
    VDNumber: "86680042",
    RegisteredPopulation: "2238",
    VotingStationName: "TVS BROEDERSTROOM CLINIC (TENT)",
    Address: "CLINIC  HAARTBEESPOORT  MADIBENG",
    Latitude: "-25.7771",
    Longitude: "27.8578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19163",
    WardLookupId: "4437",
    VDNumber: "86710013",
    RegisteredPopulation: "1777",
    VotingStationName: "SKEERPOORT PRIMARY SCHOOL",
    Address: "SKEERPOORT PRIMARY SCHOOL  SKEERPOORT   BRITS",
    Latitude: "-25.7931",
    Longitude: "27.7663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19164",
    WardLookupId: "4438",
    VDNumber: "86680031",
    RegisteredPopulation: "3427",
    VotingStationName: "TENT@ 11 MAUSER STREET,IFAFI",
    Address: "11 MAUSER STREET  IFAFI  HARTBEESPOORT",
    Latitude: "-25.7412",
    Longitude: "27.903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19165",
    WardLookupId: "4438",
    VDNumber: "86680053",
    RegisteredPopulation: "1868",
    VotingStationName: "SUNWAY COMMUNITY HALL",
    Address: "N/A  SUNWAY  BRITS",
    Latitude: "-25.716",
    Longitude: "27.9386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19166",
    WardLookupId: "4438",
    VDNumber: "86680064",
    RegisteredPopulation: "1492",
    VotingStationName: "TENT AT ST JUDE`S ANGLICAN CHURCH GROUND",
    Address: "PLOT 2 BEETHOVEN ROAD   HAARTBEESPOORT  HAARTBEESPOORT",
    Latitude: "-25.7297",
    Longitude: "27.9",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19167",
    WardLookupId: "4438",
    VDNumber: "86680075",
    RegisteredPopulation: "555",
    VotingStationName: "ENNIS THABONG PRIMARY SCHOOL",
    Address: "N/A  HAARTBEESPOORT  HARTEBEESPOORT",
    Latitude: "-25.7539",
    Longitude: "27.9167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19168",
    WardLookupId: "4439",
    VDNumber: "86661793",
    RegisteredPopulation: "360",
    VotingStationName: "TUSSEN DIE BOME",
    Address: "TUSSEN DIE BOME  MOOINOOI VILLAGE  BRITS",
    Latitude: "-25.7832",
    Longitude: "27.5376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19169",
    WardLookupId: "4439",
    VDNumber: "86662154",
    RegisteredPopulation: "3253",
    VotingStationName: "KGWANYAPE PRIMARY SCHOOL",
    Address: "N/A  MODDERSPRUIT VILLAGE  BRITS",
    Latitude: "-25.7264",
    Longitude: "27.6516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19170",
    WardLookupId: "4439",
    VDNumber: "86662503",
    RegisteredPopulation: "2224",
    VotingStationName: "TENT @ MODDERSPRUIT COMPLEX NETBALL GROUND",
    Address: "MODDERSPRUIT AREA  KATLEGONG SECTION  MODDERSPRUIT",
    Latitude: "-25.7341",
    Longitude: "27.6489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19171",
    WardLookupId: "4439",
    VDNumber: "86663504",
    RegisteredPopulation: "649",
    VotingStationName: "SEREOPHATHE PRIMARY SCHOOL",
    Address: "N/A  BOJANALA  BRITS",
    Latitude: "-25.7493",
    Longitude: "27.668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19172",
    WardLookupId: "4440",
    VDNumber: "86662143",
    RegisteredPopulation: "2118",
    VotingStationName: "SEDUPE PRIMARY SCHOOL",
    Address: "WONDERKOP ROAD  SEGWAELANE VILLAGE  BRITS",
    Latitude: "-25.6656",
    Longitude: "27.6066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19173",
    WardLookupId: "4440",
    VDNumber: "86663188",
    RegisteredPopulation: "644",
    VotingStationName: "MARUATONA PRIMARY SCHOOL",
    Address: "WONDERKOP  RUSTENBURG",
    Latitude: "-25.6697",
    Longitude: "27.5294",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19174",
    WardLookupId: "4440",
    VDNumber: "87010039",
    RegisteredPopulation: "2297",
    VotingStationName: "THUSANANG BUILDING",
    Address: "THUSANANG BUILDING  WONDERKOP HOSTEL  ",
    Latitude: "-25.6752",
    Longitude: "27.5297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19175",
    WardLookupId: "4441",
    VDNumber: "86662480",
    RegisteredPopulation: "897",
    VotingStationName: "TVS SEILENG FARM (TENT)",
    Address: "SIELLING FARM   MADIBENG   BRITS",
    Latitude: "-25.7073",
    Longitude: "27.8234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19176",
    WardLookupId: "4441",
    VDNumber: "86680020",
    RegisteredPopulation: "4248",
    VotingStationName: "GENERAAL HENDRIK SCHOEMAN SKOOL",
    Address: "MARAIS STR  BRITS  BRITS",
    Latitude: "-25.7275",
    Longitude: "27.8771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19177",
    WardLookupId: "4442",
    VDNumber: "86661254",
    RegisteredPopulation: "714",
    VotingStationName: "SHAKUNG PRIMARY SCHOOL",
    Address: "N/A  SHAKUNG  MADIBENG [BRITS]",
    Latitude: "-25.3389",
    Longitude: "27.9058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19178",
    WardLookupId: "4442",
    VDNumber: "86661265",
    RegisteredPopulation: "1287",
    VotingStationName: "MOILETSWANE PRIMARY SCHOOL",
    Address: "N/A  MOILETSWANE VILLAGE  BRITS",
    Latitude: "-25.3868",
    Longitude: "27.929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19179",
    WardLookupId: "4442",
    VDNumber: "86661276",
    RegisteredPopulation: "481",
    VotingStationName: "DIPOMPONG TRIBAL BUILDING",
    Address: "N/A  DIPOMPONG  BRITS",
    Latitude: "-25.39",
    Longitude: "27.9472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19180",
    WardLookupId: "4442",
    VDNumber: "86661344",
    RegisteredPopulation: "1375",
    VotingStationName: "LOT MASHIANE SECONDARY SCHOOL",
    Address: "LOT MASHINE SECTION  LETLHAKANENG  JERICHO",
    Latitude: "-25.3967",
    Longitude: "27.8191",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19181",
    WardLookupId: "4442",
    VDNumber: "86662996",
    RegisteredPopulation: "458",
    VotingStationName: "MADINYANE COMBINED SCHOOL",
    Address: "RAMOGATLA ROAD  MADINYANA  BRITS",
    Latitude: "-25.4",
    Longitude: "27.8659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19182",
    WardLookupId: "4442",
    VDNumber: "86663166",
    RegisteredPopulation: "556",
    VotingStationName: "TOLAMO PRIMARY SCHOOL",
    Address: "LETLHAKANENG VILLAGE  BRITS  LETLHAKANENG",
    Latitude: "-25.3892",
    Longitude: "27.8267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19183",
    WardLookupId: "4442",
    VDNumber: "86663526",
    RegisteredPopulation: "516",
    VotingStationName: "REFEMETSE DAY CARE CENTRE",
    Address: "MADIBENG   ",
    Latitude: "-25.4028",
    Longitude: "27.8315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19184",
    WardLookupId: "4443",
    VDNumber: "86661861",
    RegisteredPopulation: "396",
    VotingStationName: "RAMADIKELA PRIMARY SCHOOL",
    Address: "N/A  RANKOTEA VILLAGE  BRITS",
    Latitude: "-25.5763",
    Longitude: "27.8631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19185",
    WardLookupId: "4443",
    VDNumber: "86661984",
    RegisteredPopulation: "1669",
    VotingStationName: "SEHIBIDU PRIMARY SCHOOL",
    Address: "N/A  RABOKALA VILLAGE  BRITS",
    Latitude: "-25.5217",
    Longitude: "27.8969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19186",
    WardLookupId: "4443",
    VDNumber: "86662008",
    RegisteredPopulation: "675",
    VotingStationName: "MSIZAS PLACE",
    Address: "N/A  KGABALATSANE  KGABALATSANE",
    Latitude: "-25.5292",
    Longitude: "27.943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19187",
    WardLookupId: "4443",
    VDNumber: "86662053",
    RegisteredPopulation: "1815",
    VotingStationName: "BENNYS STORE",
    Address: "MMAKAU VILLAGE (THETELE SECTION )  MADIBENG",
    Latitude: "-25.5984",
    Longitude: "27.8909",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19188",
    WardLookupId: "4443",
    VDNumber: "86662985",
    RegisteredPopulation: "663",
    VotingStationName: "OSKRAAL PRIMARY SCHOOL",
    Address: "N/A  OSKRAAL VILLAGE  BRITS",
    Latitude: "-25.5126",
    Longitude: "27.8982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19189",
    WardLookupId: "4443",
    VDNumber: "86663470",
    RegisteredPopulation: "787",
    VotingStationName: "MALEDU MOLEPE MIDDLE SCHOOL",
    Address: "N/A  BRITS  RABOKALA",
    Latitude: "-25.5197",
    Longitude: "27.8825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19190",
    WardLookupId: "4444",
    VDNumber: "86661603",
    RegisteredPopulation: "2078",
    VotingStationName: "KEITUMETSE PRIMARY SCHOOL",
    Address: "N/A  KLIPGAT VILLAGE  BRITS",
    Latitude: "-25.4805",
    Longitude: "27.9998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19191",
    WardLookupId: "4444",
    VDNumber: "86662682",
    RegisteredPopulation: "1599",
    VotingStationName: "PHUTHA PRIMARY SCHOOL",
    Address: "N/A  KLIPGAT VILLAGE  BRITS",
    Latitude: "-25.485",
    Longitude: "28.0069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19192",
    WardLookupId: "4444",
    VDNumber: "86663133",
    RegisteredPopulation: "524",
    VotingStationName: "EAST-HETHLON CHURCH",
    Address: "N/A  BRITS  KLIPGAT",
    Latitude: "-25.4801",
    Longitude: "28.0074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19193",
    WardLookupId: "4445",
    VDNumber: "86661625",
    RegisteredPopulation: "1432",
    VotingStationName: "REFENTSE PRIMARY SCHOOL",
    Address: "N/A  KLIPGATVILLAGE  BRITS",
    Latitude: "-25.4909",
    Longitude: "28.0027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19194",
    WardLookupId: "4445",
    VDNumber: "86661636",
    RegisteredPopulation: "1761",
    VotingStationName: "KLIPGAT HIGH SCHOOL",
    Address: "N/A  KLIPGAT VILLAGE  BRITS",
    Latitude: "-25.4947",
    Longitude: "28.0004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19195",
    WardLookupId: "4445",
    VDNumber: "86661647",
    RegisteredPopulation: "2149",
    VotingStationName: "INKONJANE MIDDLE SCHOOL",
    Address: "N/A  KLIPGAT VILLAGE  BRITS",
    Latitude: "-25.4884",
    Longitude: "27.9915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19196",
    WardLookupId: "4446",
    VDNumber: "86670131",
    RegisteredPopulation: "2528",
    VotingStationName: "IKATISONG SECONDARY SCHOOL",
    Address: "N/A  LETLHABILE LOCATION  BRITS",
    Latitude: "-25.4935",
    Longitude: "27.8457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19197",
    WardLookupId: "4446",
    VDNumber: "86670186",
    RegisteredPopulation: "3444",
    VotingStationName: "SELAMODI COMBINED SCHOOL",
    Address: "N/A  CENTREVILLE INFORMAL SETTLEMENT  BRITS",
    Latitude: "-25.5066",
    Longitude: "27.8572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19198",
    WardLookupId: "4447",
    VDNumber: "86661883",
    RegisteredPopulation: "256",
    VotingStationName: "TENT @ MAMOGALESKRAAL PRIMARY SCHOOL",
    Address: "MAMOGALESKRAAL  BRITS",
    Latitude: "-25.5699",
    Longitude: "27.8014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19199",
    WardLookupId: "4447",
    VDNumber: "86670018",
    RegisteredPopulation: "2024",
    VotingStationName: "THORNHILL PRIMARY SCHOOL",
    Address: "6 KASTEELBERG  ELANDSRAND  BRITS",
    Latitude: "-25.6151",
    Longitude: "27.811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19200",
    WardLookupId: "4447",
    VDNumber: "86670041",
    RegisteredPopulation: "2573",
    VotingStationName: "BOTLHABELO HIGH SCHOOL",
    Address: "OUKASIE  OUKASIE VILLAGE  BRITS",
    Latitude: "-25.6051",
    Longitude: "27.8007",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19201",
    WardLookupId: "4448",
    VDNumber: "86661816",
    RegisteredPopulation: "1735",
    VotingStationName: "SONOP COMMUNITY HALL",
    Address: "SONOP  BRITS",
    Latitude: "-25.6493",
    Longitude: "27.6968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19202",
    WardLookupId: "4448",
    VDNumber: "86661838",
    RegisteredPopulation: "1120",
    VotingStationName: "PANSDRIFT PRIMARY SCHOOL",
    Address: "N/A  PANSDRIFT   BRITS",
    Latitude: "-25.5567",
    Longitude: "27.7026",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19203",
    WardLookupId: "4448",
    VDNumber: "86662301",
    RegisteredPopulation: "1860",
    VotingStationName: "EASTERN PLATINUM MINES LTD COMPLEX HALL",
    Address: "EASTERN PLATINUM  ",
    Latitude: "-25.6847",
    Longitude: "27.6138",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19204",
    WardLookupId: "4448",
    VDNumber: "86663010",
    RegisteredPopulation: "1013",
    VotingStationName: "SEGWAELANE PRE - SCHOOL",
    Address: "SEGWAELANE VILLAGE  BRITS",
    Latitude: "-25.6631",
    Longitude: "27.6251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19205",
    WardLookupId: "4448",
    VDNumber: "86663458",
    RegisteredPopulation: "1133",
    VotingStationName: "TEBOGO MIDDLE SCHOOL",
    Address: "N/A  BRITS  SONOP",
    Latitude: "-25.6427",
    Longitude: "27.706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19206",
    WardLookupId: "4449",
    VDNumber: "86661973",
    RegisteredPopulation: "1858",
    VotingStationName: "LOT PHALATSE PRIMARY SCHOOL",
    Address: "MOSEJA SECTION  HEBRON VILLAGE   BRITS",
    Latitude: "-25.5458",
    Longitude: "28.0122",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19207",
    WardLookupId: "4449",
    VDNumber: "86662042",
    RegisteredPopulation: "893",
    VotingStationName: "BAKWENA BA MOGOPA TRIBAL AUTHORITY",
    Address: "HEBRON VILLAGE  BRITS",
    Latitude: "-25.551",
    Longitude: "28.0174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19208",
    WardLookupId: "4449",
    VDNumber: "86663032",
    RegisteredPopulation: "2259",
    VotingStationName: "HEBRON TECHNICAL HIGH SCHOOL",
    Address: "LUCAS MANGOPE HIGHWAY  HEBRON VILLAGE  MADIBENG [BRITS]",
    Latitude: "-25.5398",
    Longitude: "28.0117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19209",
    WardLookupId: "4450",
    VDNumber: "86990989",
    RegisteredPopulation: "537",
    VotingStationName: "BOEKENHOUTFONTEIN (TENT)",
    Address: "PHOKENG & BOSHOEK ROAD  PHOKENG  RUSTENBURG",
    Latitude: "-25.5466",
    Longitude: "27.1271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19210",
    WardLookupId: "4450",
    VDNumber: "86991441",
    RegisteredPopulation: "1151",
    VotingStationName: "RASIMONE PRIMARY SCHOOL",
    Address: "RASIMONE  PHOKENG",
    Latitude: "-25.4662",
    Longitude: "27.1124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19211",
    WardLookupId: "4450",
    VDNumber: "86991788",
    RegisteredPopulation: "694",
    VotingStationName: "BOSHOEK PRIMARY SCHOOL",
    Address: "MAIN ROAD BOSHOEK  PHOKENG  PHOKENG",
    Latitude: "-25.5061",
    Longitude: "27.0984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19212",
    WardLookupId: "4450",
    VDNumber: "86992318",
    RegisteredPopulation: "943",
    VotingStationName: "MAFENYA PRIMARY SCHOOL",
    Address: "PHOKENG  MAFENYA SECTION  RUSTENBURG",
    Latitude: "-25.4326",
    Longitude: "27.1039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19213",
    WardLookupId: "4450",
    VDNumber: "87020265",
    RegisteredPopulation: "1948",
    VotingStationName: "BOTHIBELLO PRIMARY SCHOOL",
    Address: "BOTHIBELLO  PHATSIMA  RUSTENBURG",
    Latitude: "-25.3927",
    Longitude: "27.0096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19214",
    WardLookupId: "4450",
    VDNumber: "87020388",
    RegisteredPopulation: "1899",
    VotingStationName: "PHATSIMA MUNICIPAL OFFICES",
    Address: "PHATSIMA  RUSTENBURG [RUSTENBURG]",
    Latitude: "-25.3937",
    Longitude: "27.0178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19215",
    WardLookupId: "4451",
    VDNumber: "86991531",
    RegisteredPopulation: "769",
    VotingStationName: "NEW CHURCH OF SOUTHERN AFRICA",
    Address: "CHANENG  PHOKENG",
    Latitude: "-25.4069",
    Longitude: "27.1237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19216",
    WardLookupId: "4451",
    VDNumber: "86991542",
    RegisteredPopulation: "1523",
    VotingStationName: "CHANENG PRIMARY SCHOOL",
    Address: "CHANENG  RUSTENBURG",
    Latitude: "-25.4101",
    Longitude: "27.1204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19217",
    WardLookupId: "4451",
    VDNumber: "86991698",
    RegisteredPopulation: "4750",
    VotingStationName: "CHARORA HIGH SCHOOL",
    Address: "CHANENG  PHOKENG",
    Latitude: "-25.4323",
    Longitude: "27.1206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19218",
    WardLookupId: "4452",
    VDNumber: "86991474",
    RegisteredPopulation: "1646",
    VotingStationName: "BAFOKENG NORTH MINE RECREATION CENTRE",
    Address: "LUKA  PHOKENG",
    Latitude: "-25.4832",
    Longitude: "27.1675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19219",
    WardLookupId: "4452",
    VDNumber: "86991485",
    RegisteredPopulation: "2773",
    VotingStationName: "MOGONO PRIMARY SCHOOL",
    Address: "MOGONO SECTION  LUKA  RUSTENBURG",
    Latitude: "-25.4871",
    Longitude: "27.185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19220",
    WardLookupId: "4452",
    VDNumber: "86991519",
    RegisteredPopulation: "690",
    VotingStationName: "LUTHERAN CHURCH LUKA",
    Address: "RATHIBEDI SECTION  LUKA  RUSTENBURG",
    Latitude: "-25.5007",
    Longitude: "27.1812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19221",
    WardLookupId: "4453",
    VDNumber: "86991058",
    RegisteredPopulation: "672",
    VotingStationName: "OLD POLICE STATION BUILDING PHATLOGO",
    Address: "WINDSOR SECTION  PHOKENG  RUSTENBURG",
    Latitude: "-25.559",
    Longitude: "27.1532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19222",
    WardLookupId: "4453",
    VDNumber: "86991182",
    RegisteredPopulation: "2072",
    VotingStationName: "KELOKITSO EARLY LEARNING CENTRE",
    Address: "LUKA VILLAGE  RUSTENBURG",
    Latitude: "-25.5159",
    Longitude: "27.1855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19223",
    WardLookupId: "4453",
    VDNumber: "86991205",
    RegisteredPopulation: "3242",
    VotingStationName: "LUKA PRIMARY SCHOOL",
    Address: "PHIRING SECTION  LUKA  RUSTENBURG",
    Latitude: "-25.5078",
    Longitude: "27.1839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19224",
    WardLookupId: "4453",
    VDNumber: "86992835",
    RegisteredPopulation: "1079",
    VotingStationName: "RAMOTSE PRIMARY SCHOOL",
    Address: "MAIN ROAD TO SHOPS  TLEBEBE SECTION-LUKA  BAFOKENG",
    Latitude: "-25.5106",
    Longitude: "27.1921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19225",
    WardLookupId: "4454",
    VDNumber: "86991036",
    RegisteredPopulation: "1117",
    VotingStationName: "LESEDI APOSTOLIC CHURCH",
    Address: "LENATONG SECTION  PHOKENG  RUSTENBURG",
    Latitude: "-25.5873",
    Longitude: "27.1652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19226",
    WardLookupId: "4454",
    VDNumber: "86991137",
    RegisteredPopulation: "1109",
    VotingStationName: "MATLHWARE PRIMARY SCHOOL",
    Address: "LEMENONG SECTION  PHOKENG  RUSTENBURG",
    Latitude: "-25.5639",
    Longitude: "27.1627",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19227",
    WardLookupId: "4454",
    VDNumber: "86991890",
    RegisteredPopulation: "866",
    VotingStationName: "BAFOKENG HIGH SCHOOL",
    Address: "CORNER MAIN ROAD PHOKENG  PHOKENG  RUSTENBURG [RUSTENBURG]",
    Latitude: "-25.5929",
    Longitude: "27.1659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19228",
    WardLookupId: "4454",
    VDNumber: "86992060",
    RegisteredPopulation: "1410",
    VotingStationName: "KGALE PRIMARY SCHOOL",
    Address: "KGALE  PHOKENG  PHOKENG",
    Latitude: "-25.6042",
    Longitude: "27.1685",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19229",
    WardLookupId: "4454",
    VDNumber: "86992071",
    RegisteredPopulation: "810",
    VotingStationName: "LEKGOTLA LA LEMENONG (PH)",
    Address: "LEMENONG SECTION  PHOKENG  PHOKENG",
    Latitude: "-25.5662",
    Longitude: "27.1709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19230",
    WardLookupId: "4454",
    VDNumber: "86992846",
    RegisteredPopulation: "453",
    VotingStationName: "KELEDI MIDDLE SCHOOL",
    Address: "CNR MAIN & LEFARAGATLHE ROADS  KGALE SECTION  BAFOKENG",
    Latitude: "-25.6089",
    Longitude: "27.1776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19231",
    WardLookupId: "4454",
    VDNumber: "86992879",
    RegisteredPopulation: "396",
    VotingStationName: "KGALE NEW VOTING STATION",
    Address: "E3725 MAIN ROAD NEXT TO CALTEX  PHOKENG  KGALE",
    Latitude: "-25.6113",
    Longitude: "27.1762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19232",
    WardLookupId: "4455",
    VDNumber: "86990978",
    RegisteredPopulation: "922",
    VotingStationName: "MOTSWERE MOLOTLEGI EARLY LEARNING CENTRE",
    Address: "PHOKENG  RUSTENBURG",
    Latitude: "-25.5969",
    Longitude: "27.1451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19233",
    WardLookupId: "4455",
    VDNumber: "86990990",
    RegisteredPopulation: "851",
    VotingStationName: "MOREMOGOLO PRIMARY SCHOOL",
    Address: "LUTHERN CHURCH STREET  PHOKENG / SARON SECTION  PHOKENG",
    Latitude: "-25.5731",
    Longitude: "27.1528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19234",
    WardLookupId: "4455",
    VDNumber: "86991003",
    RegisteredPopulation: "1503",
    VotingStationName: "SARON PRIMARY SCHOOL",
    Address: "NOT AVAILABLE  PHOKENG  RUSTENBURG",
    Latitude: "-25.5865",
    Longitude: "27.1528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19235",
    WardLookupId: "4455",
    VDNumber: "86991014",
    RegisteredPopulation: "580",
    VotingStationName: "SEMANE EARLY LEARNING CENTER",
    Address: "PHOKENG  SARON SECTION  PHOKENG",
    Latitude: "-25.5793",
    Longitude: "27.1524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19236",
    WardLookupId: "4455",
    VDNumber: "86991025",
    RegisteredPopulation: "560",
    VotingStationName: "DITHABANENG STATION",
    Address: "N/A  PHOKENG DITHABANENG SECTION  PHOKENG",
    Latitude: "-25.5905",
    Longitude: "27.1534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19237",
    WardLookupId: "4455",
    VDNumber: "86991047",
    RegisteredPopulation: "564",
    VotingStationName: "MATALE MIDDLE SCHOOL",
    Address: "PHOKENG  RUSTENBURG",
    Latitude: "-25.5754",
    Longitude: "27.1581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19238",
    WardLookupId: "4455",
    VDNumber: "86992857",
    RegisteredPopulation: "694",
    VotingStationName: "ROMA CHURCH",
    Address:
      "(MARKOS ROAD) OPP. SOCCER GRND  MOTSWERE MOLOTLEGI SECTION  BAFOKENG",
    Latitude: "-25.5954",
    Longitude: "27.1411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19239",
    WardLookupId: "4455",
    VDNumber: "86992868",
    RegisteredPopulation: "491",
    VotingStationName: "MASOSOBANE HALL",
    Address: "NEXT TO CRECHE(OPPOSITE TARVE)  MASOSOBANE SECTION  BAFOKENG",
    Latitude: "-25.5913",
    Longitude: "27.146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19240",
    WardLookupId: "4456",
    VDNumber: "86991070",
    RegisteredPopulation: "1118",
    VotingStationName: "BOBUANTSWA PRIMARY SCHOOL",
    Address: "NOT AVAILABLE  BUANJA  RUSTENBURG",
    Latitude: "-25.5901",
    Longitude: "27.1882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19241",
    WardLookupId: "4456",
    VDNumber: "86991104",
    RegisteredPopulation: "1348",
    VotingStationName: "RATSIE GARAGE",
    Address: "RAPHAFANA  PHOKENG  RAPHAFANA SECTION",
    Latitude: "-25.6123",
    Longitude: "27.1901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19242",
    WardLookupId: "4456",
    VDNumber: "86991115",
    RegisteredPopulation: "2431",
    VotingStationName: "LEFARAGATLHE PRIMARY SCHOOL",
    Address: "LEFARAGATLHE  LEFARAGATLHE LETLHAKWANENG SECTION  PHOKENG",
    Latitude: "-25.6199",
    Longitude: "27.1967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19243",
    WardLookupId: "4456",
    VDNumber: "86992880",
    RegisteredPopulation: "749",
    VotingStationName: "LIFIKA IDWALA B CHURCH",
    Address: "BOIKANYO RD  LEFARAGATLHE  RUSTENBURG",
    Latitude: "-25.6117",
    Longitude: "27.1959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19244",
    WardLookupId: "4456",
    VDNumber: "87020456",
    RegisteredPopulation: "1055",
    VotingStationName: "LEKWAKWA PRIMARY SCHOOL",
    Address: "NOT AVAILABLE  RUSTENBURG [RUSTENBURG]  RUSTENBURG [RUSTENBURG]",
    Latitude: "-25.6275",
    Longitude: "27.2138",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19245",
    WardLookupId: "4457",
    VDNumber: "87020377",
    RegisteredPopulation: "2995",
    VotingStationName: "TLHABANE WEST PRIMARY SCHOOL",
    Address: "4950 MOYE CRESENT  GEELHOUT PARK,EXT 9  RUSTENBURG",
    Latitude: "-25.6514",
    Longitude: "27.2149",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19246",
    WardLookupId: "4457",
    VDNumber: "87020489",
    RegisteredPopulation: "3166",
    VotingStationName: "GEELHOUT PARK OPEN SPACE (TENT)",
    Address: "GEELHOUTPARK  TLHABANE-WEST  RUSTENBURG",
    Latitude: "-25.6449",
    Longitude: "27.2039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19247",
    WardLookupId: "4458",
    VDNumber: "87020164",
    RegisteredPopulation: "1800",
    VotingStationName: "HF TLOU HIGH SCHOOL",
    Address: "STAND 3527 BESTER TLHABANE  TLHABANE  RUSTENBURG",
    Latitude: "-25.641",
    Longitude: "27.2072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19248",
    WardLookupId: "4458",
    VDNumber: "87020210",
    RegisteredPopulation: "1919",
    VotingStationName: "TLHABANE COMMUNITY HALL",
    Address: "RUSTENBURG [RUSTENBURG]  ",
    Latitude: "-25.6397",
    Longitude: "27.2126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19249",
    WardLookupId: "4458",
    VDNumber: "87020221",
    RegisteredPopulation: "1844",
    VotingStationName: "J.D MOSIAH PRIMARY SCHOOL",
    Address: "STAND 2800  TLHABANE  RUSTENBURG",
    Latitude: "-25.6352",
    Longitude: "27.2075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19250",
    WardLookupId: "4459",
    VDNumber: "87020197",
    RegisteredPopulation: "3963",
    VotingStationName: "MOTLADIKGOADIGOADI PRIMARY SCHOOL",
    Address: "STAND 544MOTHUKA STREET  TLHABANE  RUSTENBURG",
    Latitude: "-25.6426",
    Longitude: "27.2193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19251",
    WardLookupId: "4459",
    VDNumber: "87020209",
    RegisteredPopulation: "1474",
    VotingStationName: "LUTHERAN BAPEDI CHURCH",
    Address: "TLHABANE  RUSTENBURG",
    Latitude: "-25.6454",
    Longitude: "27.2168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19252",
    WardLookupId: "4459",
    VDNumber: "87020434",
    RegisteredPopulation: "1023",
    VotingStationName: "NKUKISE PRIMARY SCHOOL",
    Address: "FOX LAKE  RUSTENBURG [RUSTENBURG]",
    Latitude: "-25.6423",
    Longitude: "27.2275",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19253",
    WardLookupId: "4460",
    VDNumber: "87020186",
    RegisteredPopulation: "2767",
    VotingStationName: "TLHABANE STADIUM (TENT)",
    Address: "348 LEBONE STREET  TLHABANE  RUSTENBURG",
    Latitude: "-25.6359",
    Longitude: "27.2207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19254",
    WardLookupId: "4460",
    VDNumber: "87020232",
    RegisteredPopulation: "2713",
    VotingStationName: "RAMPA PRIMARY SCHOOL",
    Address: "TLHABANE  RUSTENBURG",
    Latitude: "-25.6307",
    Longitude: "27.211",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19255",
    WardLookupId: "4461",
    VDNumber: "87020063",
    RegisteredPopulation: "3571",
    VotingStationName: "RETLAKGONA PRIMARY SCHOOL",
    Address: "NO STREET NAME FOUND  MERITING  RUSTENBURG",
    Latitude: "-25.6045",
    Longitude: "27.2641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19256",
    WardLookupId: "4461",
    VDNumber: "87020300",
    RegisteredPopulation: "3059",
    VotingStationName: "MERITING COMMUNITY HALL",
    Address: "NEAR  SPORTS GROUND  MERITING( PAARDEKRAAL)  RUSTENBURG",
    Latitude: "-25.5973",
    Longitude: "27.2566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19257",
    WardLookupId: "4461",
    VDNumber: "87020579",
    RegisteredPopulation: "489",
    VotingStationName: "MERITING (TENT)",
    Address: "RDPS @ SERALENG  SERALENG NEXT TO MINE HOUSES  SERALENG",
    Latitude: "-25.6015",
    Longitude: "27.251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19258",
    WardLookupId: "4462",
    VDNumber: "87020108",
    RegisteredPopulation: "4991",
    VotingStationName: "RUSTENBURG NORTH PRIMARY SCHOOL",
    Address: "JOHNSON  STREET  RUSTRENBURG  RUSTENBURG",
    Latitude: "-25.6528",
    Longitude: "27.2342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19259",
    WardLookupId: "4462",
    VDNumber: "87020175",
    RegisteredPopulation: "2559",
    VotingStationName: "OLD MUNICIPAL BUILDING",
    Address: "1111 LEBONE STREET  TLHABANE CORNER TUMAGOLE  RUSTENBURG",
    Latitude: "-25.6461",
    Longitude: "27.2216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19260",
    WardLookupId: "4463",
    VDNumber: "87020142",
    RegisteredPopulation: "2675",
    VotingStationName: "VASTRAP PRIMARY SCHOOL",
    Address: "STAND 2157 RUSTENBURG EXT.  RUSTENBURG  RUSTENBURG",
    Latitude: "-25.685",
    Longitude: "27.2323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19261",
    WardLookupId: "4463",
    VDNumber: "87020298",
    RegisteredPopulation: "2302",
    VotingStationName: "OLYMPIAPARK SPORT STADIUM",
    Address: "NOT APPLICABLE  GEELHOUT PARK EXT 4  RUSTENBURG",
    Latitude: "-25.6622",
    Longitude: "27.2212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19262",
    WardLookupId: "4463",
    VDNumber: "87020513",
    RegisteredPopulation: "958",
    VotingStationName: "NG KERK RUSTENBURG",
    Address: "CNR KERK & UNIE STRAAT  RUSTENBURG  RUSTENBURG",
    Latitude: "-25.6795",
    Longitude: "27.2276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19263",
    WardLookupId: "4463",
    VDNumber: "87020524",
    RegisteredPopulation: "1103",
    VotingStationName: "HARRY WHULFSE HALL",
    Address: "MARAIS STREET  RUSTENBURG  RUSTENBURG",
    Latitude: "-25.6744",
    Longitude: "27.2336",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19264",
    WardLookupId: "4464",
    VDNumber: "87020120",
    RegisteredPopulation: "2979",
    VotingStationName: "BERGSIG HIGH SCHOOL",
    Address: "C/OWOLMARANS&BOVEN  RUSTENBURG  RUSTENBURG",
    Latitude: "-25.6861",
    Longitude: "27.2244",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19265",
    WardLookupId: "4464",
    VDNumber: "87020153",
    RegisteredPopulation: "2795",
    VotingStationName: "PROTEAPARK PRIMARY SCHOOL",
    Address: "STAND 197 PROTEA PARK  PROTEAPARK  RUSTENBURG",
    Latitude: "-25.6915",
    Longitude: "27.2176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19266",
    WardLookupId: "4465",
    VDNumber: "86992082",
    RegisteredPopulation: "1369",
    VotingStationName: "MOOKA FARM HOUSE",
    Address: "165 MOOKA FARM  RIETVLEI  RUSTENBURG",
    Latitude: "-25.6316",
    Longitude: "27.156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19267",
    WardLookupId: "4465",
    VDNumber: "87020119",
    RegisteredPopulation: "4239",
    VotingStationName: "WA VAN ZYL HALL",
    Address: "PORTION 146 RTB TOWN & TOWNLANDS  RUSTENBURG",
    Latitude: "-25.6741",
    Longitude: "27.2125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19268",
    WardLookupId: "4465",
    VDNumber: "87020445",
    RegisteredPopulation: "2706",
    VotingStationName: "GEELHOUTPARK SECONDARY SCHOOL",
    Address: "31 AVENUE  GEELHOUTPARK VIEW  RUSTENBURG",
    Latitude: "-25.6563",
    Longitude: "27.2028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19269",
    WardLookupId: "4465",
    VDNumber: "87020535",
    RegisteredPopulation: "853",
    VotingStationName: "KLOOF PRIMARY SCHOOL",
    Address: "N/A  RUSTENBURG  RUSTENBURG",
    Latitude: "-25.6784",
    Longitude: "27.2091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19270",
    WardLookupId: "4466",
    VDNumber: "87020355",
    RegisteredPopulation: "3245",
    VotingStationName: "SELLY PARK SECONDARY SCHOOL",
    Address: "JOBURG ROAD  WATERVAL(NEAR MALL)  RUSTENBURG",
    Latitude: "-25.6943",
    Longitude: "27.2522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19271",
    WardLookupId: "4466",
    VDNumber: "87020467",
    RegisteredPopulation: "2917",
    VotingStationName: "CASHAN OPEN SPACE (TENT)",
    Address: "RUSTENBURG  RUSTENBURG [RUSTENBURG]  RUSTENBURG [RUSTENBURG]",
    Latitude: "-25.7097",
    Longitude: "27.2396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19272",
    WardLookupId: "4467",
    VDNumber: "87020074",
    RegisteredPopulation: "4451",
    VotingStationName: "DAVID BRINK PRIMARY SCHOOL",
    Address: "C/O KERK&RIDDER STR  RUSTENBURG  RUSTENBURG",
    Latitude: "-25.6585",
    Longitude: "27.2571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19273",
    WardLookupId: "4468",
    VDNumber: "87020243",
    RegisteredPopulation: "2580",
    VotingStationName: "BOIKAGONG SECONDARY SCHOOL",
    Address: "BOITEKONG EXTENSION 03  PAARDEKRAAL  RUSTENBURG",
    Latitude: "-25.6334",
    Longitude: "27.2782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19274",
    WardLookupId: "4468",
    VDNumber: "87020366",
    RegisteredPopulation: "2477",
    VotingStationName: "NEXT TO ITERELENG SUPERMARKET  (TENT)",
    Address: "SUNRISE PARK  RUSTENBURG",
    Latitude: "-25.6248",
    Longitude: "27.2721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19275",
    WardLookupId: "4468",
    VDNumber: "87020502",
    RegisteredPopulation: "3047",
    VotingStationName: "SUNRISEVIEW SECONDARY SCHOOL",
    Address: "SUNRISEVIEW  SUNRISE PARK VIEW  RUSTENBURG",
    Latitude: "-25.6321",
    Longitude: "27.2831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19276",
    WardLookupId: "4469",
    VDNumber: "87020030",
    RegisteredPopulation: "2521",
    VotingStationName: "KHOLOFELO ABET EDUCATION TRAINING CENTRE",
    Address: "NEXT TO CLINIC  BOITEKONG VILLAGE  RUSTENBURG",
    Latitude: "-25.6127",
    Longitude: "27.3165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19277",
    WardLookupId: "4469",
    VDNumber: "87020041",
    RegisteredPopulation: "2572",
    VotingStationName: "LETSIBOGO PRIMARY SCHOOL",
    Address: "NOT AVAILABLE  BOITEKONG  RUSTENBURG",
    Latitude: "-25.6085",
    Longitude: "27.315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19278",
    WardLookupId: "4469",
    VDNumber: "87020614",
    RegisteredPopulation: "1121",
    VotingStationName: "TSHIROLOGANG PRIMARY SCHOOL",
    Address: "MAIN ROAD TO TOWN.  BOITEKONG  BOITEKONG",
    Latitude: "-25.6108",
    Longitude: "27.3096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19279",
    WardLookupId: "4470",
    VDNumber: "87020018",
    RegisteredPopulation: "4088",
    VotingStationName: "BOITEKONG SECONDARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BOITEKONG  RUSTENBURG",
    Latitude: "-25.6208",
    Longitude: "27.3264",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19280",
    WardLookupId: "4470",
    VDNumber: "87020029",
    RegisteredPopulation: "2362",
    VotingStationName: "LUTHERAN CHURCH BOITEKONG",
    Address: "STAND 4104 BOITEKONG X 6  RUSTENBURG",
    Latitude: "-25.6178",
    Longitude: "27.3182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19281",
    WardLookupId: "4471",
    VDNumber: "86992037",
    RegisteredPopulation: "1992",
    VotingStationName: "KANANA HOSTEL(TENT)",
    Address: "BLESKOP  RUSTENBURG",
    Latitude: "-25.6508",
    Longitude: "27.3012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19282",
    WardLookupId: "4471",
    VDNumber: "86992891",
    RegisteredPopulation: "1152",
    VotingStationName: "SIZA (TENT)",
    Address: "OPPOSITE HOSTEL  RUSTENBURG  KANANA",
    Latitude: "-25.649",
    Longitude: "27.2971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19283",
    WardLookupId: "4471",
    VDNumber: "87020401",
    RegisteredPopulation: "5482",
    VotingStationName: "PAARDEKRAAL PRIMARY SCHOOL",
    Address: "NOT AVAILABLE  PAARDEKRAAL  RUSTENBURG",
    Latitude: "-25.6378",
    Longitude: "27.284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19284",
    WardLookupId: "4472",
    VDNumber: "86991317",
    RegisteredPopulation: "1444",
    VotingStationName: "KANA PRIMARY SCHOOL",
    Address: "MOTLHABENG SECTION  KANANA  RUSTENBURG",
    Latitude: "-25.5855",
    Longitude: "27.2998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19285",
    WardLookupId: "4472",
    VDNumber: "86991328",
    RegisteredPopulation: "477",
    VotingStationName: "KANANA LUTHERAN CHURCH",
    Address: "KANANA  RUSTENBURG",
    Latitude: "-25.5763",
    Longitude: "27.3123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19286",
    WardLookupId: "4472",
    VDNumber: "86991339",
    RegisteredPopulation: "1317",
    VotingStationName: "KANANA CATHOLIC CHURCH",
    Address: "KANANA  RUSTENBURG",
    Latitude: "-25.5696",
    Longitude: "27.3071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19287",
    WardLookupId: "4472",
    VDNumber: "86991340",
    RegisteredPopulation: "349",
    VotingStationName: "ST GERARD MAJELA MIDDLE SCHOOL",
    Address: "SETLHOKWE SECTION  KANANA  RUSTENBURG",
    Latitude: "-25.5484",
    Longitude: "27.3004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19288",
    WardLookupId: "4472",
    VDNumber: "86992532",
    RegisteredPopulation: "823",
    VotingStationName: "METHODIST CHURCH OF SOUTHERN AFRICA",
    Address: "KANANA  PHOKENG  KANANA",
    Latitude: "-25.5791",
    Longitude: "27.3013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19289",
    WardLookupId: "4472",
    VDNumber: "86992903",
    RegisteredPopulation: "698",
    VotingStationName: "KANANA (TENT)",
    Address: "KANANA  ROYAL BAFOKENG NATION",
    Latitude: "-25.5682",
    Longitude: "27.305",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19290",
    WardLookupId: "4472",
    VDNumber: "86992914",
    RegisteredPopulation: "620",
    VotingStationName: "CHACHALAZA 2 (TENT)",
    Address: "CHACHALAZA  KANANA  RUSTENBURG",
    Latitude: "-25.5894",
    Longitude: "27.2865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19291",
    WardLookupId: "4473",
    VDNumber: "86991092",
    RegisteredPopulation: "699",
    VotingStationName: "NO 9 SHAFT HOSTEL (TENT)",
    Address: "IMPALA  RUSTENBURG  RUSTENBURG LOCAL MUNICIPALITY",
    Latitude: "-25.5652",
    Longitude: "27.2533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19292",
    WardLookupId: "4473",
    VDNumber: "86992497",
    RegisteredPopulation: "5236",
    VotingStationName: "FREEDOM PARK SECONDARY SCHOOL",
    Address: "RUSTENBURG  ",
    Latitude: "-25.56",
    Longitude: "27.2354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19293",
    WardLookupId: "4473",
    VDNumber: "86992543",
    RegisteredPopulation: "746",
    VotingStationName: "NR 2 SHAFT HOSTEL",
    Address: "FREEDOM PARK  RUSTENBURG [RUSTENBURG]",
    Latitude: "-25.5731",
    Longitude: "27.2389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19294",
    WardLookupId: "4473",
    VDNumber: "86992992",
    RegisteredPopulation: "473",
    VotingStationName: "PLATINUM VILLAGE PRIMARY SCHOOL",
    Address:
      "CORNER STONE AND SILVER STREET  FREEDOMPARK PLATINUM VILLAGE  RUSTENBURG",
    Latitude: "-25.5511",
    Longitude: "27.2432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19295",
    WardLookupId: "4474",
    VDNumber: "86991610",
    RegisteredPopulation: "584",
    VotingStationName: "MOROGONG PRIMARY SCHOOL",
    Address: "246 MAILE  RUSTENBURG",
    Latitude: "-25.4055",
    Longitude: "27.2528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19296",
    WardLookupId: "4474",
    VDNumber: "86992307",
    RegisteredPopulation: "371",
    VotingStationName: "TSHIKAME MIDDLE SCHOOL",
    Address: "DUIPKUIL  RUSTENBURG",
    Latitude: "-25.3771",
    Longitude: "27.2657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19297",
    WardLookupId: "4474",
    VDNumber: "86992486",
    RegisteredPopulation: "693",
    VotingStationName: "MAREA MMAMODIMO CATHOLIC CHURCH",
    Address: "CHANENG  PHOKENG  RUSTENBURG [RUSTENBURG]",
    Latitude: "-25.4199",
    Longitude: "27.1243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19298",
    WardLookupId: "4474",
    VDNumber: "87070013",
    RegisteredPopulation: "2464",
    VotingStationName: "MATLHARE MOKAUTU HIGH SCHOOL",
    Address: "1254 MONAKATO  RIETSPRUIT  RUSTENBURG",
    Latitude: "-25.3554",
    Longitude: "27.2772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19299",
    WardLookupId: "4474",
    VDNumber: "87070024",
    RegisteredPopulation: "1067",
    VotingStationName: "MONAKATO PRIMARY SCHOOL",
    Address: "NOT AVAILABLE  MONNAKATO  RUSTENBURG [RUSTENBURG]",
    Latitude: "-25.3479",
    Longitude: "27.2764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19300",
    WardLookupId: "4474",
    VDNumber: "87070035",
    RegisteredPopulation: "916",
    VotingStationName: "MONNAKATO ROMAN CATHOLIC CHURCH",
    Address: "MONNAKATO  RUSTENBURG [RUSTENBURG]",
    Latitude: "-25.354",
    Longitude: "27.2661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19301",
    WardLookupId: "4475",
    VDNumber: "86991283",
    RegisteredPopulation: "708",
    VotingStationName: "CLINIC AT MOTSITLE",
    Address: "TANTANANA  RUSTENBURG",
    Latitude: "-25.4198",
    Longitude: "27.3405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19302",
    WardLookupId: "4475",
    VDNumber: "86991711",
    RegisteredPopulation: "855",
    VotingStationName: "TLASENG PRIMARY SCHOOL",
    Address: "NEXT TO TRAFFIC CIRCLE  TLASENG VILLAGE  RUSTENBURG",
    Latitude: "-25.4761",
    Longitude: "27.3519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19303",
    WardLookupId: "4475",
    VDNumber: "86991733",
    RegisteredPopulation: "1606",
    VotingStationName: "TSITSING PRIMARY SCHOOL",
    Address: "RAKHUDU SECTION  TSITSING  RUSTENBURG",
    Latitude: "-25.4712",
    Longitude: "27.3224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19304",
    WardLookupId: "4475",
    VDNumber: "86991744",
    RegisteredPopulation: "394",
    VotingStationName: "MAILE PRIMARY SCHOOL",
    Address: "MAILE (DIEPKUIL)  MAILE EXT  RUSTENBURG",
    Latitude: "-25.4393",
    Longitude: "27.2834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19305",
    WardLookupId: "4475",
    VDNumber: "86991902",
    RegisteredPopulation: "971",
    VotingStationName: "TANTANANA COMMUNITY HALL",
    Address: "TANTANANA  ROYAL BAFOKENG ADMINISTRATION",
    Latitude: "-25.3853",
    Longitude: "27.3536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19306",
    WardLookupId: "4475",
    VDNumber: "86992554",
    RegisteredPopulation: "468",
    VotingStationName: "IMPALA SOUTH HOSTEL",
    Address: "IMPALA  RUSTENBURG [RUSTENBURG]",
    Latitude: "-25.5343",
    Longitude: "27.2075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19307",
    WardLookupId: "4475",
    VDNumber: "86992622",
    RegisteredPopulation: "268",
    VotingStationName: "KITSO EARLY LEARNING CENTRE",
    Address: "N/A  MAILE  RUSTENBURG",
    Latitude: "-25.4458",
    Longitude: "27.3124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19308",
    WardLookupId: "4475",
    VDNumber: "86992925",
    RegisteredPopulation: "547",
    VotingStationName: "SEOLO JUNIOR SECONDARY SCHOOL",
    Address: "TLASENG  ROYAL BAFOKENG NATION",
    Latitude: "-25.4632",
    Longitude: "27.3429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19309",
    WardLookupId: "4476",
    VDNumber: "87020276",
    RegisteredPopulation: "2181",
    VotingStationName: "FAPHA PRIMARY SCHOOL",
    Address: "NOT AVAILABLE  HARTEBEESFONTEIN  RUSTENBURG",
    Latitude: "-25.4365",
    Longitude: "27.479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19310",
    WardLookupId: "4476",
    VDNumber: "87020311",
    RegisteredPopulation: "1912",
    VotingStationName: "LETHABONG COMMUNITY CRECHE",
    Address: "310 BOIKHUTSO STREET  LETHABONG(HARTEBEESFONTEIN  RUSTENBURG",
    Latitude: "-25.4421",
    Longitude: "27.4835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19311",
    WardLookupId: "4476",
    VDNumber: "87020322",
    RegisteredPopulation: "1945",
    VotingStationName: "UNCLE FRANK PRE SCHOOL",
    Address: "N/A  RUSTENBURG  HARTEBEESFONTEIN",
    Latitude: "-25.429",
    Longitude: "27.4808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19312",
    WardLookupId: "4476",
    VDNumber: "87020625",
    RegisteredPopulation: "1204",
    VotingStationName: "TRUE GOSPEL CHURCH",
    Address: "5649 SKIERLIK SECTION  RUSTEENBURG  LETHABONG",
    Latitude: "-25.4307",
    Longitude: "27.4727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19313",
    WardLookupId: "4477",
    VDNumber: "87020052",
    RegisteredPopulation: "1334",
    VotingStationName: "RUSKRAAL PRIMARY SCHOOL",
    Address: "2050 HARTBEESFONTEIN  HARTBEESFONTEIN  RUSTENBURG",
    Latitude: "-25.4461",
    Longitude: "27.5003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19314",
    WardLookupId: "4477",
    VDNumber: "87020254",
    RegisteredPopulation: "2635",
    VotingStationName: "BARELWANEDI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  HARTBEESFONTEIN  RUSTENBURG",
    Latitude: "-25.4399",
    Longitude: "27.507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19315",
    WardLookupId: "4477",
    VDNumber: "87020333",
    RegisteredPopulation: "2433",
    VotingStationName: "NOKA YA LERATO PRIMARY SCHOOL",
    Address: "LETHABONG  HARTEBEESFONTEIN  RUSTENBURG",
    Latitude: "-25.4323",
    Longitude: "27.4978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19316",
    WardLookupId: "4478",
    VDNumber: "86662097",
    RegisteredPopulation: "1193",
    VotingStationName: "MAUMONG PRIMARY SCHOOL",
    Address: "NOT AVAILABLE  RANKELENYANE  RUSTENBURG",
    Latitude: "-25.5833",
    Longitude: "27.4554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19317",
    WardLookupId: "4478",
    VDNumber: "86662132",
    RegisteredPopulation: "884",
    VotingStationName: "MAMOGALE-SEGALE PRIMARY SCHOOL",
    Address: "NOT AVAILABLE  MAKOLOKWE  RUSTENBURG",
    Latitude: "-25.6279",
    Longitude: "27.6235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19318",
    WardLookupId: "4478",
    VDNumber: "86662334",
    RegisteredPopulation: "1014",
    VotingStationName: "LEKGOTLA LA MAFALE",
    Address: "BERSEBA  BETHANIE  BETHANIE",
    Latitude: "-25.5525",
    Longitude: "27.5317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19319",
    WardLookupId: "4478",
    VDNumber: "86991654",
    RegisteredPopulation: "1608",
    VotingStationName: "MATETENENE SECONDARY SCHOOL",
    Address: "NOT AVAILABLE  RANKELENYANE  RUSTENBURG",
    Latitude: "-25.5715",
    Longitude: "27.4236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19320",
    WardLookupId: "4478",
    VDNumber: "86991665",
    RegisteredPopulation: "294",
    VotingStationName: "MABITSE PRIMARY SCHOOL",
    Address: "NOT APPLICABLE  RANKELENYANE  MABITSE VILLAGE",
    Latitude: "-25.5574",
    Longitude: "27.4103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19321",
    WardLookupId: "4479",
    VDNumber: "86662109",
    RegisteredPopulation: "1151",
    VotingStationName: "MODIKOE COMMUNITY HALL",
    Address: "MODIKOE  RUSTENBURG",
    Latitude: "-25.5263",
    Longitude: "27.5331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19322",
    WardLookupId: "4479",
    VDNumber: "86662110",
    RegisteredPopulation: "2766",
    VotingStationName: "DIMAPO PRIMARY SCHOOL",
    Address: "NOT AVAILABLE  BETHANIE  RUSTENBURG",
    Latitude: "-25.5575",
    Longitude: "27.6033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19323",
    WardLookupId: "4479",
    VDNumber: "86662749",
    RegisteredPopulation: "1272",
    VotingStationName: "BETHANY TRIBAL OFFICE",
    Address: "BETHANIE  RUSTENBURG",
    Latitude: "-25.5557",
    Longitude: "27.6074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19324",
    WardLookupId: "4479",
    VDNumber: "86663481",
    RegisteredPopulation: "616",
    VotingStationName: "BAKWENA HIGH SCHOOL",
    Address: "MODIKOE  MODIKOE TRIBAL AUTHORITY",
    Latitude: "-25.5249",
    Longitude: "27.5287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19325",
    WardLookupId: "4480",
    VDNumber: "86991643",
    RegisteredPopulation: "830",
    VotingStationName: "TLAPA PRIMARY SCHOOL",
    Address: "NOT AVAILABLE  LELORENG/TLAPA  RUSTENBURG",
    Latitude: "-25.6047",
    Longitude: "27.4349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19326",
    WardLookupId: "4480",
    VDNumber: "87010028",
    RegisteredPopulation: "4458",
    VotingStationName: "MARIKANA HIGH SCHOOL",
    Address: "PLOT 53  ROOIKOPPIES  MARIKANA",
    Latitude: "-25.6891",
    Longitude: "27.4856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19327",
    WardLookupId: "4480",
    VDNumber: "87010084",
    RegisteredPopulation: "893",
    VotingStationName: "MARIKANA COMMUNITY HALL",
    Address: "RUSTENBURG [RUSTENBURG]  ",
    Latitude: "-25.6979",
    Longitude: "27.4809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19328",
    WardLookupId: "4481",
    VDNumber: "86990800",
    RegisteredPopulation: "224",
    VotingStationName: "TENT AT WAGKRAAL FARM",
    Address: "WAGKRAAL ZUURPLAAT FARMS   RUSTENBURG   ZUURPLAAT",
    Latitude: "-25.7612",
    Longitude: "27.4055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19329",
    WardLookupId: "4481",
    VDNumber: "86990844",
    RegisteredPopulation: "358",
    VotingStationName: "TENT JOMAR GENERAL DEALER SUURPLAAT",
    Address: "SUURPLAAT  RUSTENBURG",
    Latitude: "-25.7878",
    Longitude: "27.3958",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19330",
    WardLookupId: "4481",
    VDNumber: "87010017",
    RegisteredPopulation: "4098",
    VotingStationName: "MARIKANA PRIMARY SCHOOL",
    Address: "NOT AVAILABLE  RUSTENBURG [RUSTENBURG]  RUSTENBURG [RUSTENBURG]",
    Latitude: "-25.7016",
    Longitude: "27.4739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19331",
    WardLookupId: "4481",
    VDNumber: "87010040",
    RegisteredPopulation: "751",
    VotingStationName: "RETIEF PRIMARY SCHOOL",
    Address: "KAFFERSKRAAL 342 JQ  MARIKANA SOUTH  RUSTENBURG",
    Latitude: "-25.7395",
    Longitude: "27.4765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19332",
    WardLookupId: "4481",
    VDNumber: "87010095",
    RegisteredPopulation: "1355",
    VotingStationName: "NG KERK MMADITLHOKOA",
    Address: "N/A  MMADITLHOKOA  RUSTENBURG",
    Latitude: "-25.7278",
    Longitude: "27.4876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19333",
    WardLookupId: "4482",
    VDNumber: "86990899",
    RegisteredPopulation: "1918",
    VotingStationName: "ENKANENG (TENT)",
    Address: "N/A  BLESKOP  RUSTENBURG",
    Latitude: "-25.6901",
    Longitude: "27.3814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19334",
    WardLookupId: "4482",
    VDNumber: "86991429",
    RegisteredPopulation: "697",
    VotingStationName: "BLESKOP NQAWANA HALL",
    Address: "N/A  RUSTENBURG  RUSTENBURG",
    Latitude: "-25.6982",
    Longitude: "27.3594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19335",
    WardLookupId: "4482",
    VDNumber: "86992509",
    RegisteredPopulation: "488",
    VotingStationName: "BLESKOP HOSTEL",
    Address: "ENKANENG  RUSTENBURG [RUSTENBURG]",
    Latitude: "-25.6947",
    Longitude: "27.3793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19336",
    WardLookupId: "4482",
    VDNumber: "86992633",
    RegisteredPopulation: "760",
    VotingStationName: "(TENT) ENKANENG 2",
    Address: "N/A  ENKANENG  RUSTENBURG",
    Latitude: "-25.6917",
    Longitude: "27.3894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19337",
    WardLookupId: "4482",
    VDNumber: "86992958",
    RegisteredPopulation: "168",
    VotingStationName: "(TENT) ENKANENG 3",
    Address: "ENKANENG  RUSTENBURG  RUSTENBURG LOCAL MUNICIPALITY",
    Latitude: "-25.697",
    Longitude: "27.3892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19338",
    WardLookupId: "4483",
    VDNumber: "86991373",
    RegisteredPopulation: "2222",
    VotingStationName: "MFIDIKWE PRIMARY SCHOOL",
    Address: "MFIDIKWE  RUSTENBURG",
    Latitude: "-25.6633",
    Longitude: "27.3422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19339",
    WardLookupId: "4483",
    VDNumber: "86991384",
    RegisteredPopulation: "268",
    VotingStationName: "(TENT)CENTRAL DEEP TRAINING CENTER",
    Address: "N/A  RUSTENBURG  RUSTENBURG",
    Latitude: "-25.6808",
    Longitude: "27.3486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19340",
    WardLookupId: "4483",
    VDNumber: "86991687",
    RegisteredPopulation: "1171",
    VotingStationName: "(TENT) ZAKHELE SQUATTERS CAMP",
    Address: "N/A  ZAKHELE  RUSTENBURG",
    Latitude: "-25.6554",
    Longitude: "27.331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19341",
    WardLookupId: "4483",
    VDNumber: "86992611",
    RegisteredPopulation: "2365",
    VotingStationName: "(TENT) BOKAMOSO OPEN SPACE",
    Address: "RUSTENBURG  ",
    Latitude: "-25.6741",
    Longitude: "27.3455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19342",
    WardLookupId: "4484",
    VDNumber: "86991407",
    RegisteredPopulation: "4068",
    VotingStationName: "KROONDAL PRIMARY SCHOOL",
    Address: "IKEMELENG   KROONDAL  KROONDAL",
    Latitude: "-25.7087",
    Longitude: "27.3152",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19343",
    WardLookupId: "4484",
    VDNumber: "86991418",
    RegisteredPopulation: "1383",
    VotingStationName: "KROONDAL GEMEENSKAPSAAL (TENT)",
    Address: "KROONDAL  RUSTENNURG LOCAL MUNICIPALITY",
    Latitude: "-25.725",
    Longitude: "27.3072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19344",
    WardLookupId: "4484",
    VDNumber: "86992341",
    RegisteredPopulation: "376",
    VotingStationName: "WATERVAL RECREATIONAL CENTRE MINE CLUB",
    Address: "BLESKOP MINE CLUB  BLESKOP NEAR 5 TANK  RUSTENBURG",
    Latitude: "-25.697",
    Longitude: "27.3264",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19345",
    WardLookupId: "4484",
    VDNumber: "86992644",
    RegisteredPopulation: "1360",
    VotingStationName: "AGAPE CHRISTIAN CENTRE",
    Address: "MATEBELENG  KROONDAL  RUSTENBURG",
    Latitude: "-25.7051",
    Longitude: "27.3226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19346",
    WardLookupId: "4484",
    VDNumber: "86992969",
    RegisteredPopulation: "613",
    VotingStationName: "TENT AT DINNIE ESTATE (KROONDAL)",
    Address: "DINNIE ESTATE (KROONDAL)  KROONDAL  RUSTENBURG",
    Latitude: "-25.7487",
    Longitude: "27.277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19347",
    WardLookupId: "4485",
    VDNumber: "86990754",
    RegisteredPopulation: "862",
    VotingStationName: "BOONS POLICE STATION TENT",
    Address: "BOONS  MATHOPESTAD  RUSTENBURG",
    Latitude: "-25.9667",
    Longitude: "27.2392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19348",
    WardLookupId: "4485",
    VDNumber: "86990776",
    RegisteredPopulation: "342",
    VotingStationName: "NAAUWPOORT PRIMARY SCHOOL",
    Address: "NEAR MAGALIESBURG ROAD  RUSTENBURG  RUSTENBURG",
    Latitude: "-25.8859",
    Longitude: "27.3149",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19349",
    WardLookupId: "4485",
    VDNumber: "86990787",
    RegisteredPopulation: "219",
    VotingStationName: "BREEDSVLEI PRIMARY SCHOOL",
    Address: "SUURPLAAT  RUSTENBURG",
    Latitude: "-26.0196",
    Longitude: "27.3874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19350",
    WardLookupId: "4485",
    VDNumber: "86990798",
    RegisteredPopulation: "691",
    VotingStationName: "KLOOFWATERS INTERMEDIATE SCHOOL",
    Address: "BUFFELSPOORT/KROMRIVIER  ",
    Latitude: "-25.8189",
    Longitude: "27.4394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19351",
    WardLookupId: "4485",
    VDNumber: "86990811",
    RegisteredPopulation: "995",
    VotingStationName: "REX MONATO PRIMARY SCHOOL",
    Address: "1254 MONAKATO  MONATO FARM, RIETSPRUIT  RUSTENBURG",
    Latitude: "-25.795",
    Longitude: "27.3317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19352",
    WardLookupId: "4485",
    VDNumber: "86990822",
    RegisteredPopulation: "771",
    VotingStationName: "NTSEDIMANE GARAGE (TENT)",
    Address: "RUSTENBURG  COMMISSIEDRIFT  RUSTENBURG",
    Latitude: "-25.7837",
    Longitude: "27.2301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19353",
    WardLookupId: "4485",
    VDNumber: "86991878",
    RegisteredPopulation: "682",
    VotingStationName: "MATHOPESTAD PRIMARY SCHOOL",
    Address: "NOT AVAILABLE  MATHOPESTAD  BOONS",
    Latitude: "-26.0133",
    Longitude: "27.1998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19354",
    WardLookupId: "4485",
    VDNumber: "86992329",
    RegisteredPopulation: "227",
    VotingStationName: "BAKUBUNG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BAKUBUNG VILLAGE  RUSTENBURG",
    Latitude: "-26.0428",
    Longitude: "27.2448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19355",
    WardLookupId: "4485",
    VDNumber: "86992330",
    RegisteredPopulation: "377",
    VotingStationName: "HASSENS CAFE",
    Address: "JOBURG ROAD  MANHARRAND  RUSTENBURG",
    Latitude: "-25.9147",
    Longitude: "27.4518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19356",
    WardLookupId: "4485",
    VDNumber: "86992521",
    RegisteredPopulation: "1802",
    VotingStationName: "TENT REX SUPERMARKET",
    Address: "RUSTENBURG  ",
    Latitude: "-25.7707",
    Longitude: "27.2722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19357",
    WardLookupId: "4486",
    VDNumber: "87020344",
    RegisteredPopulation: "4651",
    VotingStationName: "BANAPELE PRIMARY SCHOOL",
    Address: "MERITING EXTENSION 2  RUSTENBURG [RUSTENBURG]",
    Latitude: "-25.6162",
    Longitude: "27.2597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19358",
    WardLookupId: "4486",
    VDNumber: "87020546",
    RegisteredPopulation: "1247",
    VotingStationName: "LUSIKISIKI GROUND (TENT)",
    Address: "NOT AVAILABLE  RUSTENBURG  RUSTENBURG LOCAL MUNICIPALITY",
    Latitude: "-25.5991",
    Longitude: "27.2676",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19359",
    WardLookupId: "4487",
    VDNumber: "86991216",
    RegisteredPopulation: "2937",
    VotingStationName: "NEW FREEDOM PARK (TENT)",
    Address: "RUSTENBURG  FREEDOMPARK  RUSTENBURG [RUSTENBURG]",
    Latitude: "-25.5681",
    Longitude: "27.2423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19360",
    WardLookupId: "4487",
    VDNumber: "86992587",
    RegisteredPopulation: "1051",
    VotingStationName: "FREEDOM PARK INFORMAL SETTLEMENT (TENT)",
    Address: "RUSTENBURG  ",
    Latitude: "-25.5658",
    Longitude: "27.2464",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19361",
    WardLookupId: "4487",
    VDNumber: "86992947",
    RegisteredPopulation: "2477",
    VotingStationName: "NEW FREEDOM PARK ( (TENT 2)",
    Address: "FREEDOM PARK  FREEDOMPARK  RUSTENBURG",
    Latitude: "-25.5714",
    Longitude: "27.2474",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19362",
    WardLookupId: "4487",
    VDNumber: "86993005",
    RegisteredPopulation: "832",
    VotingStationName: "TENT AT MERITING EXT 4",
    Address: "MERITING EXT 4  RUSTENBURG",
    Latitude: "-25.5847",
    Longitude: "27.2526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19363",
    WardLookupId: "4488",
    VDNumber: "87020423",
    RegisteredPopulation: "4762",
    VotingStationName: "RAMOCHANA PRIMARY SCHOOL",
    Address: "RAMOCHANA  RUSTENBURG",
    Latitude: "-25.6374",
    Longitude: "27.2728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19364",
    WardLookupId: "4488",
    VDNumber: "87020591",
    RegisteredPopulation: "2134",
    VotingStationName: "RAMOTSHANA PRISON (TENT)",
    Address: "OPPOSITE PRISON  RUSTENBURG  RUSTENBURG",
    Latitude: "-25.6367",
    Longitude: "27.2659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19365",
    WardLookupId: "4489",
    VDNumber: "86991306",
    RegisteredPopulation: "1303",
    VotingStationName: "CHACHALAZA (TENT)",
    Address: "KANANA  RUSTENBURG",
    Latitude: "-25.5945",
    Longitude: "27.2889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19366",
    WardLookupId: "4489",
    VDNumber: "87020399",
    RegisteredPopulation: "2586",
    VotingStationName: "PAARDEKRAAL COMMUNITY HALL",
    Address: "EXT 1  PAARDEKRAAL  RUSTENBURG",
    Latitude: "-25.613",
    Longitude: "27.268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19367",
    WardLookupId: "4489",
    VDNumber: "87020478",
    RegisteredPopulation: "1813",
    VotingStationName: "KERESETE YA MORENA ROMAN CATHOLIC CHURCH",
    Address: "THABA ZIMBI ROAD  BOITEKONG  RUSTENBURG",
    Latitude: "-25.6084",
    Longitude: "27.3188",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19368",
    WardLookupId: "4489",
    VDNumber: "87020603",
    RegisteredPopulation: "2133",
    VotingStationName: "BOITEKONG (TENT)",
    Address: "END OF MAIN ROAD  BOITEKONG  RUSTENBURG",
    Latitude: "-25.5977",
    Longitude: "27.3073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19369",
    WardLookupId: "4490",
    VDNumber: "86992600",
    RegisteredPopulation: "2586",
    VotingStationName: "TENT - UNIT 13 HEALTH CENTRE",
    Address: "NEXT TO R510 ROAD  UNIT 13  RUSTENBURG",
    Latitude: "-25.5996",
    Longitude: "27.2826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19370",
    WardLookupId: "4491",
    VDNumber: "87020085",
    RegisteredPopulation: "2112",
    VotingStationName: "BOBBIES RUGBY CLUB (TENT)",
    Address: "KRUGER STR  RUSTENBURG  RUSTENBURG",
    Latitude: "-25.6602",
    Longitude: "27.2533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19371",
    WardLookupId: "4491",
    VDNumber: "87020131",
    RegisteredPopulation: "3466",
    VotingStationName: "RUSTENBURG TOWN HALL",
    Address: "CNR FATIMA BHAYAT AND BEYERS N  RUSTENBURG  RUSTENBURG",
    Latitude: "-25.6728",
    Longitude: "27.2444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19372",
    WardLookupId: "4491",
    VDNumber: "87020580",
    RegisteredPopulation: "2547",
    VotingStationName: "GOODLUCK SHOP (TENT)",
    Address: "OLD PRETORIA ROAD  WATERFALL EAST  RUSTENBURG",
    Latitude: "-25.6857",
    Longitude: "27.2612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19373",
    WardLookupId: "4492",
    VDNumber: "87020096",
    RegisteredPopulation: "1555",
    VotingStationName: "ZINNIAVILLE COMMUNITY HALL",
    Address: "ZINNIAVILLE  RUSTENBURG",
    Latitude: "-25.6467",
    Longitude: "27.2427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19374",
    WardLookupId: "4492",
    VDNumber: "87020412",
    RegisteredPopulation: "1507",
    VotingStationName: "JABULA HOSTEL",
    Address: "SONDELA  RUSTENBURG [RUSTENBURG]",
    Latitude: "-25.6207",
    Longitude: "27.2541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19375",
    WardLookupId: "4492",
    VDNumber: "87020490",
    RegisteredPopulation: "3282",
    VotingStationName: "TENT AT SERALENG AND NKANDLA",
    Address: "CENTRE SERALENG AND NKANDLA   SERALENG AND NKANDLA   RUSTENBURG",
    Latitude: "-25.6186",
    Longitude: "27.239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19376",
    WardLookupId: "4492",
    VDNumber: "87020557",
    RegisteredPopulation: "1275",
    VotingStationName: "KARLIEN PARK PRIMARY SCHOOL",
    Address: "ZINNIAVILLE  RUSTENBURG",
    Latitude: "-25.6436",
    Longitude: "27.2359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19377",
    WardLookupId: "4492",
    VDNumber: "87020568",
    RegisteredPopulation: "2901",
    VotingStationName: "SERALENG (TENT)",
    Address: "RDP HOUSES  SERALENG  RUSTENBURG",
    Latitude: "-25.6093",
    Longitude: "27.2441",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19378",
    WardLookupId: "4493",
    VDNumber: "86991159",
    RegisteredPopulation: "364",
    VotingStationName: "LESUNG COMMUNITY HALL",
    Address: "LESUNG  RUSTENBURG",
    Latitude: "-25.5198",
    Longitude: "27.3682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19379",
    WardLookupId: "4493",
    VDNumber: "86991193",
    RegisteredPopulation: "702",
    VotingStationName: "KHIBITSWANE PRIMARY SCHOOL",
    Address: "MOGAJANE  RUSTENBURG",
    Latitude: "-25.4897",
    Longitude: "27.3587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19380",
    WardLookupId: "4493",
    VDNumber: "86991238",
    RegisteredPopulation: "792",
    VotingStationName: "TSHARE PRIMARY SCHOOL",
    Address: "MOSENTHAL  RUSTENBURG",
    Latitude: "-25.5394",
    Longitude: "27.3536",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19381",
    WardLookupId: "4493",
    VDNumber: "86991722",
    RegisteredPopulation: "522",
    VotingStationName: "SERUTUBE PRIMARY SCHOOL",
    Address: "NEXT TO SHOP  SERUTUBE VILLAGE( KANANA)  RUSTENBURG",
    Latitude: "-25.5278",
    Longitude: "27.3028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19382",
    WardLookupId: "4493",
    VDNumber: "86992093",
    RegisteredPopulation: "1234",
    VotingStationName: "TSUNYANE PRIMARY SCHOOL",
    Address: "NEXT TO N4 ROAD  TSUNYANE VILLAGE  RUSTENBURG",
    Latitude: "-25.5335",
    Longitude: "27.4037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19383",
    WardLookupId: "4493",
    VDNumber: "86992374",
    RegisteredPopulation: "2413",
    VotingStationName: "IKAGENG MUNICIPAL OFFICES",
    Address: "N/A  MOSENTHAL  RUSTENBURG",
    Latitude: "-25.5423",
    Longitude: "27.3626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19384",
    WardLookupId: "4494",
    VDNumber: "86990888",
    RegisteredPopulation: "1823",
    VotingStationName: "PHOTSANENG PRIMARY SCHOOL",
    Address: "PHOTSANENG  RUSTENBURG [RUSTENBURG]",
    Latitude: "-25.6799",
    Longitude: "27.3744",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19385",
    WardLookupId: "4494",
    VDNumber: "86991351",
    RegisteredPopulation: "1642",
    VotingStationName: "TLHAGE PRIMARY SCHOOL",
    Address: "THEKWANE  RUSTENBURG",
    Latitude: "-25.6583",
    Longitude: "27.3669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19386",
    WardLookupId: "4494",
    VDNumber: "87010073",
    RegisteredPopulation: "1833",
    VotingStationName: "KAREE MINE",
    Address: "MARIKANA  RUSTENBURG [RUSTENBURG]",
    Latitude: "-25.6989",
    Longitude: "27.4498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19387",
    WardLookupId: "2290",
    VDNumber: "87030019",
    RegisteredPopulation: "2038",
    VotingStationName: "KGETLENG PRIMARY SCHOOL",
    Address: "317 BOTHATA  BOROLELO  SWARTRUGGENS",
    Latitude: "-25.6309",
    Longitude: "26.6842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19388",
    WardLookupId: "2290",
    VDNumber: "87030042",
    RegisteredPopulation: "588",
    VotingStationName: "UNITING REFORM CHURCH",
    Address: "83 DIKGANG   BOROLELO  SWARTRUGGENS",
    Latitude: "-25.6313",
    Longitude: "26.6809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19389",
    WardLookupId: "2291",
    VDNumber: "86990675",
    RegisteredPopulation: "212",
    VotingStationName: "RADIKWEGA VOTING STATION",
    Address: "SCHOONGESICHT  LINDLEYSPOORT  SWARTRUGGENS",
    Latitude: "-25.4709",
    Longitude: "26.7598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19390",
    WardLookupId: "2291",
    VDNumber: "86990743",
    RegisteredPopulation: "628",
    VotingStationName: "SWARTRUGGENS COMBINED SCHOOL",
    Address: "VISSER  SWARTRUGGENS  SWARTRUGGENS",
    Latitude: "-25.6455",
    Longitude: "26.6992",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19391",
    WardLookupId: "2291",
    VDNumber: "86991924",
    RegisteredPopulation: "200",
    VotingStationName: "HELAM RECREATION CLUB",
    Address: "NOOITGEDACHT FARM  SWARTRUGGENS  SWARTRUGGENS",
    Latitude: "-25.5952",
    Longitude: "26.6637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19392",
    WardLookupId: "2291",
    VDNumber: "87030020",
    RegisteredPopulation: "592",
    VotingStationName: "REEBONE SECONDARY SCHOOL",
    Address: "BARNARD STREET  SWARTRUGGENS  SWARTRUGGENS",
    Latitude: "-25.6553",
    Longitude: "26.6871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19393",
    WardLookupId: "2291",
    VDNumber: "87030031",
    RegisteredPopulation: "1465",
    VotingStationName: "KGALALELO DAYCARE CENTRE",
    Address: "721 EXTENSION 1  BOROLELO  SWARTRUGGENS",
    Latitude: "-25.6336",
    Longitude: "26.6797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19394",
    WardLookupId: "2292",
    VDNumber: "86990710",
    RegisteredPopulation: "217",
    VotingStationName: "MUSHLENDOW FARM",
    Address: "JP 494 PORTION 37  VOERKRAAL  KOSTER",
    Latitude: "-25.9755",
    Longitude: "26.8719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19395",
    WardLookupId: "2292",
    VDNumber: "86990721",
    RegisteredPopulation: "130",
    VotingStationName: "(TENT) GROENFONTEIN",
    Address: "GROENFONTEIN  DERBY  KOSTER",
    Latitude: "-25.9329",
    Longitude: "26.9824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19396",
    WardLookupId: "2292",
    VDNumber: "86990732",
    RegisteredPopulation: "223",
    VotingStationName: "KGWELA",
    Address: "454 SWIRFONTEIN  BONA LOUIS  KOSTER",
    Latitude: "-25.8585",
    Longitude: "26.6822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19397",
    WardLookupId: "2292",
    VDNumber: "87000027",
    RegisteredPopulation: "1908",
    VotingStationName: "KOSTER GEKOMBINEERDE SKOOL",
    Address: "49 NOORD STREET  KOSTER  KOSTER",
    Latitude: "-25.8567",
    Longitude: "26.9011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19398",
    WardLookupId: "2293",
    VDNumber: "87000016",
    RegisteredPopulation: "1485",
    VotingStationName: "KGALAGATSANE PRIMARY SCHOOL",
    Address: "800 MODIGELO STREET  REAGILE  KOSTER",
    Latitude: "-25.8457",
    Longitude: "26.8858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19399",
    WardLookupId: "2293",
    VDNumber: "87000049",
    RegisteredPopulation: "1381",
    VotingStationName: "POELANO FELLOWSHIP MINISTRY",
    Address: "SETHUNYE STREET  REAGILE, KOSTER  KGETLENGRIVIER [KOSTER]",
    Latitude: "-25.8439",
    Longitude: "26.8876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19400",
    WardLookupId: "2294",
    VDNumber: "87000050",
    RegisteredPopulation: "945",
    VotingStationName: "LESEDI EARLY LEARNING CENTRE",
    Address: "2670 NALEDI STREET  REAGILE  KOSTER",
    Latitude: "-25.8556",
    Longitude: "26.8839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19401",
    WardLookupId: "2294",
    VDNumber: "87000061",
    RegisteredPopulation: "1393",
    VotingStationName: "GRACE MISSION CENTRE",
    Address: "(NW374 - KGETLENGRIVIER [KOSTER])   ",
    Latitude: "-25.8507",
    Longitude: "26.8833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19402",
    WardLookupId: "2294",
    VDNumber: "87000072",
    RegisteredPopulation: "598",
    VotingStationName: "OLD MPHE BANA RESOURCE CENTRE",
    Address: "2248 SEAKE  REAGILE  KOSTER",
    Latitude: "-25.8569",
    Longitude: "26.8872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19403",
    WardLookupId: "2295",
    VDNumber: "86990866",
    RegisteredPopulation: "418",
    VotingStationName: "KOSTERDAM LAPA",
    Address: "KOSTERDAM  KOSTER  KOSTER",
    Latitude: "-25.7033",
    Longitude: "26.9064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19404",
    WardLookupId: "2295",
    VDNumber: "86990934",
    RegisteredPopulation: "308",
    VotingStationName: "HELPMEKAAR KONTANT WINKEL",
    Address: "LP 27 FARM  BRAKKLOOF  LINDLEYSPROOT",
    Latitude: "-25.4891",
    Longitude: "26.8975",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19405",
    WardLookupId: "2295",
    VDNumber: "86990956",
    RegisteredPopulation: "996",
    VotingStationName: "MOEDWIL HIGH SCHOOL",
    Address: "MOEDWIL  SWARTRUGGENS",
    Latitude: "-25.6313",
    Longitude: "26.9715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19406",
    WardLookupId: "2295",
    VDNumber: "86990967",
    RegisteredPopulation: "424",
    VotingStationName: "TSHEDIMOSONG SCHOOL",
    Address: "JQ316 BROODSKRAAL  RUSTENBURG FARM AREA",
    Latitude: "-25.6783",
    Longitude: "27.101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19407",
    WardLookupId: "2295",
    VDNumber: "86992048",
    RegisteredPopulation: "827",
    VotingStationName: "MAZISTA PRIMARY SCHOOL",
    Address: "MAZISTA RDP  MAZISTA  SWARTRUGGENS",
    Latitude: "-25.7296",
    Longitude: "26.7369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19408",
    WardLookupId: "2295",
    VDNumber: "86992475",
    RegisteredPopulation: "62",
    VotingStationName: "KWAGGASHOEK VOTING STATION",
    Address: "SWARTRUGGENS ROAD  KWAGGASHOEK  KGETLENGRIVIER [KOSTER]",
    Latitude: "-25.7772",
    Longitude: "26.6037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19409",
    WardLookupId: "2296",
    VDNumber: "87000038",
    RegisteredPopulation: "2502",
    VotingStationName: "METHODIST CHURCH",
    Address: "MAIMANE  REAGILE,KOSTER  KGETLENGRIVIER [KOSTER]",
    Latitude: "-25.8537",
    Longitude: "26.8889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19410",
    WardLookupId: "2297",
    VDNumber: "86990765",
    RegisteredPopulation: "288",
    VotingStationName: "MIDDELFONTEIN KONTANTWINKEL",
    Address: "MIDDELFONTEIN FARM  MIDDELFONTEIN  RUSTENBURG/KOSTER",
    Latitude: "-25.8613",
    Longitude: "27.1665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19411",
    WardLookupId: "2297",
    VDNumber: "87040010",
    RegisteredPopulation: "590",
    VotingStationName: "DERBY BOEREVEREENIGINGSAAL TENT",
    Address: "BARNARD STREET  DERBY  DERBY",
    Latitude: "-25.9114",
    Longitude: "27.0425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19412",
    WardLookupId: "2297",
    VDNumber: "87040021",
    RegisteredPopulation: "1611",
    VotingStationName: "MAREMA COMBINE SCHOOL",
    Address: "632 EXTENSION 1  REDIRILE  DERBY",
    Latitude: "-25.9042",
    Longitude: "27.0454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19413",
    WardLookupId: "2298",
    VDNumber: "86990046",
    RegisteredPopulation: "548",
    VotingStationName: "WELVERDINED COMMUNITY HALL",
    Address: "N/A  WELVERDINRND  MADIKWE",
    Latitude: "-24.9324",
    Longitude: "26.7164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19414",
    WardLookupId: "2298",
    VDNumber: "86990057",
    RegisteredPopulation: "339",
    VotingStationName: "NAKAIPAYA COMMUNITY HALL",
    Address: "KOEDOESFONTEIN/MANKAIPAYA  MADIKWE",
    Latitude: "-24.9918",
    Longitude: "26.5546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19415",
    WardLookupId: "2298",
    VDNumber: "86990068",
    RegisteredPopulation: "574",
    VotingStationName: "OBAKENG COMMUNITY HALL",
    Address: "N/A  OBAKENG  MADIKWE",
    Latitude: "-24.9233",
    Longitude: "26.6521",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19416",
    WardLookupId: "2298",
    VDNumber: "86990372",
    RegisteredPopulation: "216",
    VotingStationName: "MAUD/ZIBI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  RAMPAMPASPOORT  MADIKWE",
    Latitude: "-24.9864",
    Longitude: "26.6733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19417",
    WardLookupId: "2298",
    VDNumber: "86990507",
    RegisteredPopulation: "411",
    VotingStationName: "DE BRAK COMMUNITY HALL",
    Address: "00  DEBRAK VILLAGE  MADIKWE",
    Latitude: "-25.0204",
    Longitude: "26.4892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19418",
    WardLookupId: "2298",
    VDNumber: "86991126",
    RegisteredPopulation: "490",
    VotingStationName: "DWARSBERG COMMUNITY HALL",
    Address: "N/A  DWARSBERG  MADKWE",
    Latitude: "-24.9371",
    Longitude: "26.6178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19419",
    WardLookupId: "2298",
    VDNumber: "86991676",
    RegisteredPopulation: "774",
    VotingStationName: "MOLATEDI TRIBAL OFFICE",
    Address: "10082 MAIN ROAD  MOLATEDI  BATLOKWA BOO KGOSI",
    Latitude: "-24.8497",
    Longitude: "26.4955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19420",
    WardLookupId: "2298",
    VDNumber: "86991889",
    RegisteredPopulation: "434",
    VotingStationName: "LANGA LA SEMBO HIGH  SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  LOSMYTJERIE/GOEDEHOOP  MADIKWE",
    Latitude: "-24.9298",
    Longitude: "26.6757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19421",
    WardLookupId: "2298",
    VDNumber: "86992149",
    RegisteredPopulation: "288",
    VotingStationName: "DITHOTENG P SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  GOEDDEHOPE  MADIKWE",
    Latitude: "-24.9011",
    Longitude: "26.69",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19422",
    WardLookupId: "2299",
    VDNumber: "86990024",
    RegisteredPopulation: "945",
    VotingStationName: "SEDUMEDI PRIMARY SCHOOL",
    Address: "11 KGOSING SECTION  LETLHAKENG  MANKWE",
    Latitude: "-25.1485",
    Longitude: "26.7216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19423",
    WardLookupId: "2299",
    VDNumber: "86990035",
    RegisteredPopulation: "470",
    VotingStationName: "NALEDIYAMASA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  SESOBE  MADIKWE",
    Latitude: "-25.0403",
    Longitude: "26.4309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19424",
    WardLookupId: "2299",
    VDNumber: "86990462",
    RegisteredPopulation: "467",
    VotingStationName: "KATNEGAL COMMUNITY HALL",
    Address: "MONTSANA  MADIKWE",
    Latitude: "-25.116",
    Longitude: "26.5194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19425",
    WardLookupId: "2299",
    VDNumber: "86991452",
    RegisteredPopulation: "287",
    VotingStationName: "MONTSANA COMMUNITY HALL",
    Address: "00 00  MONTSANA  MADIKWE",
    Latitude: "-25.1009",
    Longitude: "26.578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19426",
    WardLookupId: "2299",
    VDNumber: "86991508",
    RegisteredPopulation: "587",
    VotingStationName: "PITSEDISULEJANG TRIBAL OFFICE",
    Address: "254 MOTSENG SECTION  PITSEDISULEJANG  MADIKWE",
    Latitude: "-25.0245",
    Longitude: "26.4809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19427",
    WardLookupId: "2299",
    VDNumber: "86991632",
    RegisteredPopulation: "535",
    VotingStationName: "KHAYAKHULU COMMUNITY  HALL",
    Address: "00 MISSION SECTION  KHAYAKHULU  MADIKWE",
    Latitude: "-25.1036",
    Longitude: "26.6597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19428",
    WardLookupId: "2299",
    VDNumber: "86992015",
    RegisteredPopulation: "338",
    VotingStationName: "RAMOKGOLELA COMMUNITY HALL",
    Address: "(NW375 - MOSES KOTANE [MOGWASE])   ",
    Latitude: "-25.0514",
    Longitude: "26.4206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19429",
    WardLookupId: "2299",
    VDNumber: "86992565",
    RegisteredPopulation: "356",
    VotingStationName: "MOTLHAJWE PRIMARY SCHOOL",
    Address: "(NW375 - MOSES KOTANE [MOGWASE])   ",
    Latitude: "-25.0681",
    Longitude: "26.4172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19430",
    WardLookupId: "2300",
    VDNumber: "86990451",
    RegisteredPopulation: "366",
    VotingStationName: "VOORDONKER COMMUNITY HALL",
    Address: "VOORDONKER  MADIKWE",
    Latitude: "-25.1323",
    Longitude: "26.6599",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19431",
    WardLookupId: "2300",
    VDNumber: "86990473",
    RegisteredPopulation: "453",
    VotingStationName: "MANAMELA COMMUNITY HALL",
    Address: "GA- MANAMELA  MADIKWE",
    Latitude: "-25.1749",
    Longitude: "26.6781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19432",
    WardLookupId: "2300",
    VDNumber: "86990484",
    RegisteredPopulation: "359",
    VotingStationName: "MMATAU TRIBAL OFFICE",
    Address: "29 KGOSING SECTION  MMATAU  MADIKWE",
    Latitude: "-25.2485",
    Longitude: "26.677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19433",
    WardLookupId: "2300",
    VDNumber: "86990530",
    RegisteredPopulation: "436",
    VotingStationName: "BAMAAKA TRIBAL HALL",
    Address: "RATSEGAE, SIGA  MADIKWE",
    Latitude: "-25.1972",
    Longitude: "26.5921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19434",
    WardLookupId: "2300",
    VDNumber: "86990552",
    RegisteredPopulation: "669",
    VotingStationName: "MOUBANA TRIBAL OFFICE",
    Address: "302 DITSHOSWANE SECTION  MOUBANE  MADIKWE",
    Latitude: "-25.2926",
    Longitude: "26.6368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19435",
    WardLookupId: "2300",
    VDNumber: "86992161",
    RegisteredPopulation: "487",
    VotingStationName: "LEKGATLE 2 PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MMATAU  MADIKWE",
    Latitude: "-25.2495",
    Longitude: "26.6684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19436",
    WardLookupId: "2300",
    VDNumber: "86992442",
    RegisteredPopulation: "54",
    VotingStationName: "MASEKOLOANE COMMUNITY HALL",
    Address: "N/A  MASEKOLOANE VILLAGE  MADIKWE",
    Latitude: "-25.182",
    Longitude: "26.6368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19437",
    WardLookupId: "2300",
    VDNumber: "86992677",
    RegisteredPopulation: "443",
    VotingStationName: "MMATAU COMMUNITY HALL",
    Address: "MMATAU  MADIKWE  BAKWENA BA MODIMOSANE",
    Latitude: "-25.2342",
    Longitude: "26.6618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19438",
    WardLookupId: "2300",
    VDNumber: "86992981",
    RegisteredPopulation: "172",
    VotingStationName: "BRAKKUIL COMMUNITY HALL",
    Address: "BRAKKUIL  MADIKWE  UITKYK TRIBAL AUTHORITY",
    Latitude: "-25.2576",
    Longitude: "26.4932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19439",
    WardLookupId: "2301",
    VDNumber: "86990518",
    RegisteredPopulation: "599",
    VotingStationName: "MOKGAUTSI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  BRAKKUIL  MADIKWE",
    Latitude: "-25.255",
    Longitude: "26.4906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19440",
    WardLookupId: "2301",
    VDNumber: "86990529",
    RegisteredPopulation: "1664",
    VotingStationName: "MOKGATLA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  KOFFIEKRAAL  MADIKWE",
    Latitude: "-25.2845",
    Longitude: "26.4255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19441",
    WardLookupId: "2301",
    VDNumber: "86990855",
    RegisteredPopulation: "284",
    VotingStationName: "SEFUTSWELO MIDDLE SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  UITKYK VILLAGE  MADIKWE",
    Latitude: "-25.198",
    Longitude: "26.4323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19442",
    WardLookupId: "2301",
    VDNumber: "86991621",
    RegisteredPopulation: "768",
    VotingStationName: "UITKYK TRIBAL OFFICE",
    Address: "UITKYK  MADIKWE",
    Latitude: "-25.2112",
    Longitude: "26.4203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19443",
    WardLookupId: "2301",
    VDNumber: "86992688",
    RegisteredPopulation: "310",
    VotingStationName: "GADIFELE CRECHE",
    Address: "MOSES KOTANE  ",
    Latitude: "-25.2041",
    Longitude: "26.4297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19444",
    WardLookupId: "2301",
    VDNumber: "86992699",
    RegisteredPopulation: "464",
    VotingStationName: "MAOTWE SECONDARY SCHOOL",
    Address: "00 BRAKKUIL  GA MOKGATLHA",
    Latitude: "-25.2646",
    Longitude: "26.4893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19445",
    WardLookupId: "2302",
    VDNumber: "86990079",
    RegisteredPopulation: "887",
    VotingStationName: "DISAKE COMMUNITY HALL",
    Address: "SELOKWANENG SEC.  DISAKE  MANKWE",
    Latitude: "-24.8338",
    Longitude: "27.0089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19446",
    WardLookupId: "2302",
    VDNumber: "86990080",
    RegisteredPopulation: "1330",
    VotingStationName: "KRAALHOEK COMMUNITY HALL",
    Address: "TLHABADIRA SEC.  KRAALHOEK  MANKWE",
    Latitude: "-24.9162",
    Longitude: "27.0781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19447",
    WardLookupId: "2302",
    VDNumber: "86991845",
    RegisteredPopulation: "672",
    VotingStationName: "MATLAMETLONG PRIMARY SCHOOL",
    Address: "55 DITLAGANA SECTION  MATLAMETLONG  MANKWE",
    Latitude: "-24.7905",
    Longitude: "26.9352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19448",
    WardLookupId: "2302",
    VDNumber: "86992701",
    RegisteredPopulation: "429",
    VotingStationName: "SEGALE MIDDLE SCHOOL",
    Address: "DISAKE   MOGWASE  BAKGATLA BA KGAFELA",
    Latitude: "-24.8297",
    Longitude: "27.0004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19449",
    WardLookupId: "2303",
    VDNumber: "86990136",
    RegisteredPopulation: "328",
    VotingStationName: "KAMEELBOOM COMMUNITY HALL",
    Address: "FISRT GATE SECTION  KAMEELBOOM/NGWEDING  MANKWE",
    Latitude: "-25.0215",
    Longitude: "26.9241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19450",
    WardLookupId: "2303",
    VDNumber: "86990350",
    RegisteredPopulation: "1332",
    VotingStationName: "MOTLHABE PRIMARY SCHOOL",
    Address: "MOTLHABE  MOGWASE  BAKGATLA BA KGAFELA",
    Latitude: "-25.0741",
    Longitude: "26.9521",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19451",
    WardLookupId: "2303",
    VDNumber: "86990361",
    RegisteredPopulation: "167",
    VotingStationName: "NKOGOLOWE COMMUNITY HALL",
    Address: "NKOGOLWE  ",
    Latitude: "-25.0299",
    Longitude: "26.754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19452",
    WardLookupId: "2303",
    VDNumber: "86990383",
    RegisteredPopulation: "447",
    VotingStationName: "MOLORWE COMMUNITY HALL",
    Address: "MOLORWE SECTION  MOLORWE  MANKWE",
    Latitude: "-25.0859",
    Longitude: "26.9064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19453",
    WardLookupId: "2303",
    VDNumber: "86991261",
    RegisteredPopulation: "353",
    VotingStationName: "MAPAPUTLE  COMMUNITY HALL",
    Address: "N/A  MAPAPUTLE VILLAGE  MANKWE",
    Latitude: "-24.9974",
    Longitude: "26.8318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19454",
    WardLookupId: "2303",
    VDNumber: "86991463",
    RegisteredPopulation: "132",
    VotingStationName: "RAMOSHIBITSWANA COMMUNITY HALL",
    Address: "RAMOSHIBITSWANA  MANKWE",
    Latitude: "-24.9562",
    Longitude: "26.9454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19455",
    WardLookupId: "2303",
    VDNumber: "86991913",
    RegisteredPopulation: "430",
    VotingStationName: "MOGODITSHANE COMMUNITY HALL",
    Address: "00 00  MOGODITSHANE  MANKWE",
    Latitude: "-25.0043",
    Longitude: "26.874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19456",
    WardLookupId: "2303",
    VDNumber: "86991957",
    RegisteredPopulation: "361",
    VotingStationName: "MANTSHO COMMUNITY HALL",
    Address: "GREENSIDE SECTION  MANTSHO  MANKWE",
    Latitude: "-25.0742",
    Longitude: "26.8189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19457",
    WardLookupId: "2303",
    VDNumber: "86992206",
    RegisteredPopulation: "309",
    VotingStationName: "MOGOBE COMMUNITY HALL",
    Address: "MOGOBE SECTION  DEKAMEELKUIL  MANKWE",
    Latitude: "-25.0576",
    Longitude: "26.862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19458",
    WardLookupId: "2304",
    VDNumber: "86990147",
    RegisteredPopulation: "659",
    VotingStationName: "SWARTKLIP MINE C HOSTEL (TENT)",
    Address: "SWARTKLIP MINE   MANKWE   BAKGATLA BA KGAFELA",
    Latitude: "-24.9798",
    Longitude: "27.1413",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19459",
    WardLookupId: "2304",
    VDNumber: "86990170",
    RegisteredPopulation: "780",
    VotingStationName: "MAKUKA SECONDARY SCHOOL",
    Address: "ATAMELENG SECTION  SEFIKILE  MANKWE",
    Latitude: "-24.9911",
    Longitude: "27.1957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19460",
    WardLookupId: "2304",
    VDNumber: "86990181",
    RegisteredPopulation: "1422",
    VotingStationName: "SEFIKILE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  SEFIKILE  MANKWE",
    Latitude: "-24.9899",
    Longitude: "27.1801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19461",
    WardLookupId: "2304",
    VDNumber: "86992228",
    RegisteredPopulation: "483",
    VotingStationName: "SEFIKILE (TENT)",
    Address: "GA RAMOSIDI  SEFIKILE  MANKWE",
    Latitude: "-25.0009",
    Longitude: "27.1727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19462",
    WardLookupId: "2305",
    VDNumber: "86990091",
    RegisteredPopulation: "294",
    VotingStationName: "RAMALEBYE PRIMARY SCHOOL",
    Address: "NGWEDING  NGWEDING VILLAGE  MANKWE",
    Latitude: "-25.0627",
    Longitude: "26.9926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19463",
    WardLookupId: "2305",
    VDNumber: "86990169",
    RegisteredPopulation: "964",
    VotingStationName: "MONONONO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MONONONO  MANKWE",
    Latitude: "-25.0453",
    Longitude: "27.1856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19464",
    WardLookupId: "2305",
    VDNumber: "86990305",
    RegisteredPopulation: "443",
    VotingStationName: "SEBELE PRIMARY SCHOOL",
    Address: "RAMATSHABA  LEGKRAAL  BAKGATLA BA KGAFELA",
    Latitude: "-25.1302",
    Longitude: "27.0579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19465",
    WardLookupId: "2305",
    VDNumber: "86991249",
    RegisteredPopulation: "772",
    VotingStationName: "MAGONG COMMUNITY HALL",
    Address: "00 00  MAGONG  MANKWE",
    Latitude: "-24.9937",
    Longitude: "26.9901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19466",
    WardLookupId: "2305",
    VDNumber: "86992217",
    RegisteredPopulation: "357",
    VotingStationName: "RAMONOTWANA PRIMARY SCHOOL",
    Address: "NTSWANALEMETSING  MOSES KOTANE",
    Latitude: "-25.0505",
    Longitude: "26.974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19467",
    WardLookupId: "2305",
    VDNumber: "86992239",
    RegisteredPopulation: "297",
    VotingStationName: "NG KERK BOFULE",
    Address: "BOFULE  MOGWASE  BAKGATLA BA KGAFELA",
    Latitude: "-25.1371",
    Longitude: "27.0394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19468",
    WardLookupId: "2305",
    VDNumber: "86992453",
    RegisteredPopulation: "176",
    VotingStationName: "MAGALANE COMMUNIT HALL",
    Address: "MAGALANE  BAKGATLA BA GAFELA  MOGWASE",
    Latitude: "-25.0265",
    Longitude: "26.9955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19469",
    WardLookupId: "2305",
    VDNumber: "86992734",
    RegisteredPopulation: "324",
    VotingStationName: "D D PILANE PRIMARY SCHOOL",
    Address: "MAGONG   MOGWASE  BAKGATLA BA KGAFELA",
    Latitude: "-24.9992",
    Longitude: "26.9791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19470",
    WardLookupId: "2306",
    VDNumber: "86990248",
    RegisteredPopulation: "831",
    VotingStationName: "LESETLHENG DUTCH REFORMED",
    Address: "LESETLHENG  MORULENG  MORULENG",
    Latitude: "-25.1512",
    Longitude: "27.1203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19471",
    WardLookupId: "2306",
    VDNumber: "86990259",
    RegisteredPopulation: "815",
    VotingStationName: "RAMOGA ROMAN CHURCH",
    Address: "RAMOGA  SECTION  RAMOGA/LESETLHENG  LESETLHENG",
    Latitude: "-25.1588",
    Longitude: "27.1472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19472",
    WardLookupId: "2306",
    VDNumber: "86990260",
    RegisteredPopulation: "1003",
    VotingStationName: "REOLEBOGE SPECIAL SCHOOL",
    Address: "MORULENG  SOULSPOORT  MANKWE",
    Latitude: "-25.1544",
    Longitude: "27.1782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19473",
    WardLookupId: "2306",
    VDNumber: "86992385",
    RegisteredPopulation: "1028",
    VotingStationName: "MATANGWANENG (TENT)",
    Address: "MORULENG  BAKGATLA BA KGAFELA",
    Latitude: "-25.163",
    Longitude: "27.1667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19474",
    WardLookupId: "2307",
    VDNumber: "86991979",
    RegisteredPopulation: "2010",
    VotingStationName: "OKOMELANG PRIMARY SCHOOL",
    Address: "20549 SEKGATLENG SECTION  SANDFONTEIN  MANKWE",
    Latitude: "-25.1799",
    Longitude: "27.27",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19475",
    WardLookupId: "2307",
    VDNumber: "86991980",
    RegisteredPopulation: "1394",
    VotingStationName: "DINKWE PRIMARY SCHOOL",
    Address: "SEPEDING SECTION  SANDFONTEIN  MANKWE",
    Latitude: "-25.1925",
    Longitude: "27.2631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19476",
    WardLookupId: "2308",
    VDNumber: "86991801",
    RegisteredPopulation: "1378",
    VotingStationName: "BOJATING PRIMARY SCHOOL",
    Address: "SELEMALE SECTION  BOJATING  MANKWE",
    Latitude: "-25.2023",
    Longitude: "27.383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19477",
    WardLookupId: "2308",
    VDNumber: "86991823",
    RegisteredPopulation: "1392",
    VotingStationName: "MAKGOPANENG.P.SCHOOL",
    Address: "MMOROGONG VILLAGE  MANKWE",
    Latitude: "-25.126",
    Longitude: "27.3797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19478",
    WardLookupId: "2308",
    VDNumber: "86992004",
    RegisteredPopulation: "724",
    VotingStationName: "PHADI COMMUNITY HALL",
    Address: "RAMOKOKA  PHADI  MOGWASE",
    Latitude: "-25.0849",
    Longitude: "27.4159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19479",
    WardLookupId: "2309",
    VDNumber: "86991799",
    RegisteredPopulation: "1972",
    VotingStationName: "KWENATLASE HIGH SCHOOL",
    Address: "LOSS SECTION  RAMOKOKASTAD  MANKWE",
    Latitude: "-25.1565",
    Longitude: "27.4315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19480",
    WardLookupId: "2309",
    VDNumber: "86991812",
    RegisteredPopulation: "890",
    VotingStationName: "BAPHALANE PRIMARY SCHOOL",
    Address: "STATENG SECTION  RAMOKOKASTAD  MANKWE",
    Latitude: "-25.151",
    Longitude: "27.4469",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19481",
    WardLookupId: "2309",
    VDNumber: "86992251",
    RegisteredPopulation: "340",
    VotingStationName: "THABENG LUTHERAN CHURCH",
    Address: "RAMOKOKA   BAPHALANE",
    Latitude: "-25.14",
    Longitude: "27.456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19482",
    WardLookupId: "2310",
    VDNumber: "86991430",
    RegisteredPopulation: "792",
    VotingStationName: "(TENT) - SUN CITY SPORTS AND SOCIAL CLUB",
    Address: "SUN VILLAGE  SUN CITY  SUN CITY",
    Latitude: "-25.3609",
    Longitude: "27.0972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19483",
    WardLookupId: "2310",
    VDNumber: "86992026",
    RegisteredPopulation: "96",
    VotingStationName: "EDUCATION AUDITORUIM CENTRE",
    Address: "MANYANE GAME PARK  MANYANE  MANKWE",
    Latitude: "-25.2555",
    Longitude: "27.2173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19484",
    WardLookupId: "2310",
    VDNumber: "87060012",
    RegisteredPopulation: "2299",
    VotingStationName: "BORITE PRIMARY SCHOOL",
    Address: "MABELE-A-PODI  MANKWE",
    Latitude: "-25.2888",
    Longitude: "27.1966",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19485",
    WardLookupId: "2311",
    VDNumber: "86991553",
    RegisteredPopulation: "1266",
    VotingStationName: "BAKUBUNG TRIBAL HALL",
    Address: "SECTION 1  LEDIG  MANKWE",
    Latitude: "-25.3611",
    Longitude: "27.0577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19486",
    WardLookupId: "2311",
    VDNumber: "86991564",
    RegisteredPopulation: "1006",
    VotingStationName: "BAKGATLHENG CRECHE",
    Address: "BAKGATLHENG SECTION  LEDIG  MANKWE",
    Latitude: "-25.3558",
    Longitude: "27.0743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19487",
    WardLookupId: "2311",
    VDNumber: "86992767",
    RegisteredPopulation: "1008",
    VotingStationName: "ITUMELENG MIDDLE SCHOOL",
    Address: "LEDIG  MOGWASE  BAKUBUNG TRIBAL AUTHORITY",
    Latitude: "-25.3622",
    Longitude: "27.0619",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19488",
    WardLookupId: "2312",
    VDNumber: "86990327",
    RegisteredPopulation: "1410",
    VotingStationName: "MOKHINE PRIMARY SCHOOL",
    Address: "LEROME  MANKWE",
    Latitude: "-25.2049",
    Longitude: "27.2144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19489",
    WardLookupId: "2312",
    VDNumber: "86992464",
    RegisteredPopulation: "1289",
    VotingStationName: "LEROME SOUTH (TENT)",
    Address: "Lerome South  MOSES KOTANE [MOGWASE]  MOSES KOTANE [MOGWASE]",
    Latitude: "-25.2243",
    Longitude: "27.2353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19490",
    WardLookupId: "2312",
    VDNumber: "87060034",
    RegisteredPopulation: "1278",
    VotingStationName: "JM NTSIME HIGH SCHOOL",
    Address: "UNIT4  MOGWASE  MOGWASE",
    Latitude: "-25.2581",
    Longitude: "27.2461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19491",
    WardLookupId: "2313",
    VDNumber: "86990013",
    RegisteredPopulation: "1283",
    VotingStationName: "DIKWEIPI PRIMARY SCHOOL",
    Address: "500 DIKWEIPI  MANKWE",
    Latitude: "-25.1813",
    Longitude: "27.2284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19492",
    WardLookupId: "2313",
    VDNumber: "86990349",
    RegisteredPopulation: "2213",
    VotingStationName: "WELGEVAL COMMUNITY HALL",
    Address: "MOSES KOTANE  ",
    Latitude: "-25.1961",
    Longitude: "27.2426",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19493",
    WardLookupId: "2313",
    VDNumber: "86992778",
    RegisteredPopulation: "709",
    VotingStationName: "RAPHURERE HIGH SCHOOL",
    Address: "767A WELGEVAL  MOGWASE  BAKGATLA BA KGAFELA",
    Latitude: "-25.1897",
    Longitude: "27.2435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19494",
    WardLookupId: "2314",
    VDNumber: "86990316",
    RegisteredPopulation: "1572",
    VotingStationName: "LEROME MIDDLE SCHOOL",
    Address: "1280 LERULENG SECTION  LEROME  BAKGATLA BA GA KGAFELA",
    Latitude: "-25.1959",
    Longitude: "27.2193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19495",
    WardLookupId: "2314",
    VDNumber: "86990338",
    RegisteredPopulation: "1035",
    VotingStationName: "MOSITWANE COMMUNITY HALL",
    Address: "LEROME   BAKGATLA BA KGAFELA  MOGWASE",
    Latitude: "-25.2081",
    Longitude: "27.2328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19496",
    WardLookupId: "2314",
    VDNumber: "86992431",
    RegisteredPopulation: "736",
    VotingStationName: "PHOLA PARK (TENT)",
    Address: "Lerome  MOSES KOTANE [MOGWASE]",
    Latitude: "-25.1909",
    Longitude: "27.2052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19497",
    WardLookupId: "2314",
    VDNumber: "86992970",
    RegisteredPopulation: "293",
    VotingStationName: "TSHWARAGANANG EARLY LEARNING CENTRE",
    Address: "WELGEVAL  MANKWE  BAKGATLA BAKGAFELA",
    Latitude: "-25.2067",
    Longitude: "27.2563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19498",
    WardLookupId: "2315",
    VDNumber: "86990574",
    RegisteredPopulation: "609",
    VotingStationName: "BOTLHALE PRIMARY SCHOOL",
    Address: "KORTKLOOF  LETLHAKANE/KORTLOOF  MANKWE",
    Latitude: "-25.4879",
    Longitude: "26.4519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19499",
    WardLookupId: "2315",
    VDNumber: "86990585",
    RegisteredPopulation: "861",
    VotingStationName: "GOITSEMANG MIDDLE SCHOOL",
    Address: "PELLA  MADIKWE",
    Latitude: "-25.4856",
    Longitude: "26.4957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19500",
    WardLookupId: "2315",
    VDNumber: "86990608",
    RegisteredPopulation: "1105",
    VotingStationName: "BAKWENA-BA-MORARE TRIBAL HALL",
    Address: "1561 KGOSING SECTION  PELLA  MADIKWE",
    Latitude: "-25.4686",
    Longitude: "26.4829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19501",
    WardLookupId: "2315",
    VDNumber: "86992172",
    RegisteredPopulation: "221",
    VotingStationName: "PELLA A LUTHERAN CHURCH",
    Address: "MAPHUSUMANENG SECTION  PELLA VILLAGE  MADIKWE",
    Latitude: "-25.4616",
    Longitude: "26.4859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19502",
    WardLookupId: "2315",
    VDNumber: "86992284",
    RegisteredPopulation: "565",
    VotingStationName: "TAPOS PRIMARY SCHOOL",
    Address: "MAIN ROAD  PELLA  BAKWENA BA MORARE",
    Latitude: "-25.4634",
    Longitude: "26.4748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19503",
    WardLookupId: "2316",
    VDNumber: "86990596",
    RegisteredPopulation: "1340",
    VotingStationName: "MORARE HIGH SCHOOL",
    Address: "LEFARAGATLHA  PELLA  BAKWENA BA MORARE",
    Latitude: "-25.4649",
    Longitude: "26.4575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19504",
    WardLookupId: "2316",
    VDNumber: "86991227",
    RegisteredPopulation: "372",
    VotingStationName: "GOBUSAMANG PRIMARY SCHOOL",
    Address: "ZONE 10  PELLA  BAKWENA BA MORARE",
    Latitude: "-25.4698",
    Longitude: "26.5026",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19505",
    WardLookupId: "2316",
    VDNumber: "87050011",
    RegisteredPopulation: "1903",
    VotingStationName: "MADIKWE TRC",
    Address: "MADIKWE  ",
    Latitude: "-25.3554",
    Longitude: "26.5258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19506",
    WardLookupId: "2317",
    VDNumber: "86990620",
    RegisteredPopulation: "987",
    VotingStationName: "THAKADU PRIMARY SCHOOL",
    Address: "SILVERKRAANS  MADIKWE",
    Latitude: "-25.4925",
    Longitude: "26.6192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19507",
    WardLookupId: "2317",
    VDNumber: "86990653",
    RegisteredPopulation: "1061",
    VotingStationName: "BATLOKWA TRIBAL OFFICE",
    Address: "SEOPENG SECTION  SILVERKRANS  MADIKWE",
    Latitude: "-25.4994",
    Longitude: "26.6238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19508",
    WardLookupId: "2317",
    VDNumber: "86990664",
    RegisteredPopulation: "1348",
    VotingStationName: "LESEDI APOSTOLIC CHURCH IN SA",
    Address: "MAKOBOLWE SECTION  SILVERKRANS  MADIKWE",
    Latitude: "-25.4955",
    Longitude: "26.6448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19509",
    WardLookupId: "2318",
    VDNumber: "86990563",
    RegisteredPopulation: "1341",
    VotingStationName: "MOTSATSI PRIMARY SCHOOL",
    Address: "VREDE  MOGWASE  BAKWENA",
    Latitude: "-25.4647",
    Longitude: "26.5546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19510",
    WardLookupId: "2318",
    VDNumber: "86990619",
    RegisteredPopulation: "971",
    VotingStationName: "MOJABENG PRIMARY SCHOOL",
    Address: "SESHIBITSWE  MANKWE",
    Latitude: "-25.4179",
    Longitude: "26.5603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19511",
    WardLookupId: "2318",
    VDNumber: "86990631",
    RegisteredPopulation: "1194",
    VotingStationName: "KGOSI BODIBA MIDDLE SCHOOL",
    Address: "N/A  SILVERKRANS  MADIKWE",
    Latitude: "-25.4905",
    Longitude: "26.6321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19512",
    WardLookupId: "2318",
    VDNumber: "86990642",
    RegisteredPopulation: "1144",
    VotingStationName: "MOTLHAPUTSENG HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  SILVERKRANS  MADIKWE",
    Latitude: "-25.4823",
    Longitude: "26.6035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19513",
    WardLookupId: "2319",
    VDNumber: "86990215",
    RegisteredPopulation: "2239",
    VotingStationName: "MACHAMA PRIMARY SCHOOL",
    Address: "MANAMAKGOTHENG  MANKWE",
    Latitude: "-25.1168",
    Longitude: "27.197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19514",
    WardLookupId: "2319",
    VDNumber: "86991250",
    RegisteredPopulation: "533",
    VotingStationName: "LESETLHENG COMMUNITY  HALL",
    Address: "00 00  LESETLHENG VILLAGE  MANKWE",
    Latitude: "-25.1433",
    Longitude: "27.1099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19515",
    WardLookupId: "2319",
    VDNumber: "86992576",
    RegisteredPopulation: "751",
    VotingStationName: "CHURCH OF CHRIST",
    Address: "(NW375 - MOSES KOTANE [MOGWASE])   ",
    Latitude: "-25.127",
    Longitude: "27.1855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19516",
    WardLookupId: "2320",
    VDNumber: "86990394",
    RegisteredPopulation: "279",
    VotingStationName: "SEOLONG TRIBAL OFFICE",
    Address: "SEOLONG  MANKWE",
    Latitude: "-25.1455",
    Longitude: "26.8255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19517",
    WardLookupId: "2320",
    VDNumber: "86990439",
    RegisteredPopulation: "895",
    VotingStationName: "MOTSISI PRIMARY SCHOOL",
    Address: "NKGARAGANE SECTION  MABESKRAAL  MANKWE",
    Latitude: "-25.1838",
    Longitude: "26.8103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19518",
    WardLookupId: "2320",
    VDNumber: "86990440",
    RegisteredPopulation: "714",
    VotingStationName: "METHODIST CHURCH IN AFRICA",
    Address: "STAND NUMBER 1601 LENGENENG  MABESKRAAL  MANKWE",
    Latitude: "-25.1915",
    Longitude: "26.7954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19519",
    WardLookupId: "2320",
    VDNumber: "86990495",
    RegisteredPopulation: "691",
    VotingStationName: "MAKWELENG PRIMARY SCHOOL",
    Address: "MAKWELENG  MADIKWE",
    Latitude: "-25.1642",
    Longitude: "26.7341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19520",
    WardLookupId: "2320",
    VDNumber: "86991609",
    RegisteredPopulation: "759",
    VotingStationName: "FIRST APOSTOLIC CHURCH",
    Address: "SELOCHA SECTION  MABESKRAAL  MANKWE",
    Latitude: "-25.1787",
    Longitude: "26.7757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19521",
    WardLookupId: "2321",
    VDNumber: "86990406",
    RegisteredPopulation: "946",
    VotingStationName: "MMOLOTSI PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MABESKRAAL  MANKWE",
    Latitude: "-25.2119",
    Longitude: "26.8272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19522",
    WardLookupId: "2321",
    VDNumber: "86990417",
    RegisteredPopulation: "904",
    VotingStationName: "MABESKRAAL PRIMARY SCHOOL",
    Address: "MOSEKENG SECTION  MABESKRAAL  MANKWE",
    Latitude: "-25.1979",
    Longitude: "26.8021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19523",
    WardLookupId: "2321",
    VDNumber: "86990428",
    RegisteredPopulation: "441",
    VotingStationName: "BATLHAKO TRIBAL AUTHORITY",
    Address: "KGOSING SECTION  MABESKRAAL  MANKWE",
    Latitude: "-25.2028",
    Longitude: "26.7985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19524",
    WardLookupId: "2321",
    VDNumber: "86991867",
    RegisteredPopulation: "568",
    VotingStationName: "MAKOSHONG PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MAKOSHONG  MANKWE",
    Latitude: "-25.2413",
    Longitude: "26.8421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19525",
    WardLookupId: "2321",
    VDNumber: "86992789",
    RegisteredPopulation: "427",
    VotingStationName: "MABESKRAAL (TENT)",
    Address: "MABESKRAAL  BATLHAKO",
    Latitude: "-25.2208",
    Longitude: "26.8239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19526",
    WardLookupId: "2321",
    VDNumber: "86992802",
    RegisteredPopulation: "422",
    VotingStationName: "FAITH MISSION CHURCH",
    Address: "TWEELAGTE  MOGWASE  BATLHALERWA",
    Latitude: "-25.2702",
    Longitude: "26.8541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19527",
    WardLookupId: "2322",
    VDNumber: "86990697",
    RegisteredPopulation: "1101",
    VotingStationName: "BAPO 2 TRIBAL OFFICE",
    Address: "X  BAPONG  MANKWE",
    Latitude: "-25.31",
    Longitude: "26.8456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19528",
    WardLookupId: "2322",
    VDNumber: "86991777",
    RegisteredPopulation: "737",
    VotingStationName: "KEOAGILE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MABAALSTAD-HOLFONTEIN  MADIKWE",
    Latitude: "-25.2722",
    Longitude: "26.7249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19529",
    WardLookupId: "2322",
    VDNumber: "86991935",
    RegisteredPopulation: "975",
    VotingStationName: "PUSO PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MABAALSTAD-RIETFONTEIN  MADIKWE",
    Latitude: "-25.3271",
    Longitude: "26.7621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19530",
    WardLookupId: "2322",
    VDNumber: "86992408",
    RegisteredPopulation: "782",
    VotingStationName: "METHODIST CHURCH OF SOUTH AFRICA",
    Address: "BAPONG VILLAGE  MANKWE",
    Latitude: "-25.3085",
    Longitude: "26.8402",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19531",
    WardLookupId: "2322",
    VDNumber: "86992420",
    RegisteredPopulation: "88",
    VotingStationName: "MORETELETSI HOSPITAL",
    Address: "MOSES KOTANE [MOGWASE]  ",
    Latitude: "-25.2974",
    Longitude: "26.8195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19532",
    WardLookupId: "2322",
    VDNumber: "86992790",
    RegisteredPopulation: "294",
    VotingStationName: "BAPHIRING TRIBAL OFFICE",
    Address: "KWA PHIRI  MADIKWE  BAPHIRING TRIBAL",
    Latitude: "-25.2913",
    Longitude: "26.7443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19533",
    WardLookupId: "2323",
    VDNumber: "86991272",
    RegisteredPopulation: "811",
    VotingStationName: "BATLHALERWA PRIMARY SCHOOL",
    Address: "N/A  TWEELAGTE  MANKWE",
    Latitude: "-25.2785",
    Longitude: "26.8542",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19534",
    WardLookupId: "2323",
    VDNumber: "86991395",
    RegisteredPopulation: "812",
    VotingStationName: "WITRANTJIE COMMUNITY HALL",
    Address: "00 00  WITRANTJIE  MANKWE",
    Latitude: "-25.274",
    Longitude: "26.9081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19535",
    WardLookupId: "2323",
    VDNumber: "86991856",
    RegisteredPopulation: "1453",
    VotingStationName: "MONTSAMAISA PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  PHALANE  MANKWE",
    Latitude: "-25.2648",
    Longitude: "26.8447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19536",
    WardLookupId: "2323",
    VDNumber: "86991946",
    RegisteredPopulation: "1092",
    VotingStationName: "MALEFO HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  TWEELAGTE  MANKWE",
    Latitude: "-25.2768",
    Longitude: "26.8623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19537",
    WardLookupId: "2324",
    VDNumber: "86990686",
    RegisteredPopulation: "315",
    VotingStationName: "BOTMAN PRIMARY SCHOOL",
    Address: "MABELLENG SECTION  MABELLENG  MANKWE",
    Latitude: "-25.2172",
    Longitude: "26.9128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19538",
    WardLookupId: "2324",
    VDNumber: "86990945",
    RegisteredPopulation: "601",
    VotingStationName: "MAOLOGANE PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MAOLOGANE  MANKWE",
    Latitude: "-25.2776",
    Longitude: "26.9421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19539",
    WardLookupId: "2324",
    VDNumber: "86991496",
    RegisteredPopulation: "1328",
    VotingStationName: "BATLENG HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  TLHATLHAGANYANE  MANKWE",
    Latitude: "-25.2187",
    Longitude: "26.9426",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19540",
    WardLookupId: "2324",
    VDNumber: "86992105",
    RegisteredPopulation: "616",
    VotingStationName: "MMOROGONG COMMUNITY HALL",
    Address: "MMOROGONG  MANKWE  BATLHAKO BA LEEMA",
    Latitude: "-25.2373",
    Longitude: "26.9446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19541",
    WardLookupId: "2324",
    VDNumber: "86992194",
    RegisteredPopulation: "450",
    VotingStationName: "MAKGOPHE COMMUNITY HALL",
    Address: "00 00  MAKGOPHE  MANKWE",
    Latitude: "-25.1221",
    Longitude: "26.8829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19542",
    WardLookupId: "2324",
    VDNumber: "86992813",
    RegisteredPopulation: "384",
    VotingStationName: "MODULE PRIMARY SCHOOL",
    Address: "WITRANTJIE  ",
    Latitude: "-25.275",
    Longitude: "26.9107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19543",
    WardLookupId: "2325",
    VDNumber: "86991586",
    RegisteredPopulation: "1653",
    VotingStationName: "MPEREBERE PRIMARY SCHOOL",
    Address: "LETLHABILE SECTION  LEDIG  MANKWE",
    Latitude: "-25.3676",
    Longitude: "27.062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19544",
    WardLookupId: "2325",
    VDNumber: "86991755",
    RegisteredPopulation: "1959",
    VotingStationName: "SUNNYBUSH EARLY LEARNING CENTRE",
    Address: "254 LEKWADI SECTION  LEDIG  MANKWE",
    Latitude: "-25.3744",
    Longitude: "27.0699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19545",
    WardLookupId: "2325",
    VDNumber: "86992824",
    RegisteredPopulation: "659",
    VotingStationName: "VICTORY BIBLE CHURCH",
    Address:
      "LEDIG  SEFUNAMANZI INFORMAL SETTLEMENT  BAKUBUNG TRIBAL AUTHORITY",
    Latitude: "-25.3827",
    Longitude: "27.0597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19546",
    WardLookupId: "2326",
    VDNumber: "86991171",
    RegisteredPopulation: "1913",
    VotingStationName: "MOKGALWANA PRIMARY SCHOOL",
    Address: "RANYAKA SECTION  MOKGALWANA  MANKWE",
    Latitude: "-24.8029",
    Longitude: "27.0008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19547",
    WardLookupId: "2326",
    VDNumber: "86992138",
    RegisteredPopulation: "682",
    VotingStationName: "MOCHUDI MIDDLE SCHOOL",
    Address: "MOCHUDI SECTION  MOKGALWANENG  MANKWE",
    Latitude: "-24.7928",
    Longitude: "26.9988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19548",
    WardLookupId: "2326",
    VDNumber: "86992363",
    RegisteredPopulation: "909",
    VotingStationName: "RANKAE P SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MOKGWALANENG  MOSES KOTANE",
    Latitude: "-24.7958",
    Longitude: "27.0229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19549",
    WardLookupId: "2327",
    VDNumber: "86991597",
    RegisteredPopulation: "2290",
    VotingStationName: "TSWAIDI HIGH SCHOOL",
    Address: "SECTION 6  LEDIG  MANKWE",
    Latitude: "-25.3578",
    Longitude: "27.0383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19550",
    WardLookupId: "2327",
    VDNumber: "86992116",
    RegisteredPopulation: "839",
    VotingStationName: "MAHOBIESKRAAL COMMUNITY HALL",
    Address: "N/A  MAHOBIESKRAAL VILLAGE  MANKWE",
    Latitude: "-25.3403",
    Longitude: "26.9695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19551",
    WardLookupId: "2327",
    VDNumber: "86992127",
    RegisteredPopulation: "867",
    VotingStationName: "TONKI CHURCH",
    Address: "SECTION 3  LEDIG VILLAGE  MANKWE",
    Latitude: "-25.3537",
    Longitude: "27.0479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19552",
    WardLookupId: "2328",
    VDNumber: "86990204",
    RegisteredPopulation: "508",
    VotingStationName: "MANAMAKGOTHENG COMMERCIAL HIGH SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  MANAMAKGOTHENG  MANKWE",
    Latitude: "-25.1287",
    Longitude: "27.2106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19553",
    WardLookupId: "2328",
    VDNumber: "86990226",
    RegisteredPopulation: "1655",
    VotingStationName: "MATUANA PRIMARY SCHOOL",
    Address: "RAMPIDI SECTION  MANAMAKGOTHENG/MORULENG  MANKWE",
    Latitude: "-25.1405",
    Longitude: "27.1945",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19554",
    WardLookupId: "2328",
    VDNumber: "86991991",
    RegisteredPopulation: "874",
    VotingStationName: "SEGAKWANENG PRIMARY SCHOOL",
    Address: "148 SEGAKWANA SECTION  SEGAKWANENG  MANKWE",
    Latitude: "-25.1701",
    Longitude: "27.196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19555",
    WardLookupId: "2328",
    VDNumber: "86992352",
    RegisteredPopulation: "653",
    VotingStationName: "SEGAKWANA BHAM SECTION (TENT)",
    Address: "BHAM SECTION  SEGAKWANA VILLAGE  MOSES KOTANE",
    Latitude: "-25.1616",
    Longitude: "27.1946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19556",
    WardLookupId: "2329",
    VDNumber: "86990271",
    RegisteredPopulation: "815",
    VotingStationName: "BAKGATLA PRIMARY SCHOOL",
    Address: "MAKRESTENG SECTION  MORULENG - PHIRIBATHO  MANKWE",
    Latitude: "-25.1682",
    Longitude: "27.1675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19557",
    WardLookupId: "2329",
    VDNumber: "86990293",
    RegisteredPopulation: "1805",
    VotingStationName: "NTHEBE PRIMARY SCHOOL",
    Address: "2514 MABODISA SECTION  MORULENG-MABODISA  MANKWE",
    Latitude: "-25.1726",
    Longitude: "27.1808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19558",
    WardLookupId: "2329",
    VDNumber: "86992273",
    RegisteredPopulation: "267",
    VotingStationName: "MARAPALLO TRIBAL HALL",
    Address: "MORULENG  MOGWASE  BAKGATLA BA KGAFELA",
    Latitude: "-25.1784",
    Longitude: "27.1692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19559",
    WardLookupId: "2329",
    VDNumber: "86992745",
    RegisteredPopulation: "221",
    VotingStationName: "MMAMITLWA PRIMARY SCHOOL",
    Address: "MORULENG  MOGWASE  BAKGATLA BA KAGAFELA",
    Latitude: "-25.163",
    Longitude: "27.1711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19560",
    WardLookupId: "2330",
    VDNumber: "86992295",
    RegisteredPopulation: "1358",
    VotingStationName: "PHILADELPHIA MINISTRIES",
    Address: "PIDIPIDI STREET  MOGWASE  MANKWE",
    Latitude: "-25.257",
    Longitude: "27.254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19561",
    WardLookupId: "2330",
    VDNumber: "86992756",
    RegisteredPopulation: "1793",
    VotingStationName: "RDP UNIT 8 TENT",
    Address: "UNIT 8  MOGWASE UNIT 8  MOSES KOTANE RURAL",
    Latitude: "-25.2826",
    Longitude: "27.2417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19562",
    WardLookupId: "2330",
    VDNumber: "87060023",
    RegisteredPopulation: "1063",
    VotingStationName: "MOGWASE DUTCH REFORMED",
    Address: "UNIT 2  MOGWASE  BAKGATLA BA KGAFELA",
    Latitude: "-25.2773",
    Longitude: "27.2149",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19563",
    WardLookupId: "2330",
    VDNumber: "87060045",
    RegisteredPopulation: "619",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "MOSES KOTANE [MOGWASE]  ",
    Latitude: "-25.275",
    Longitude: "27.2237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19564",
    WardLookupId: "2330",
    VDNumber: "87060056",
    RegisteredPopulation: "343",
    VotingStationName: "MORONGWA PRIMARY SCHOOL",
    Address: "295 TAU STREET  MOGWASE  MANKWE",
    Latitude: "-25.2767",
    Longitude: "27.219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19565",
    WardLookupId: "2331",
    VDNumber: "86990103",
    RegisteredPopulation: "852",
    VotingStationName: "MELOTONG PRIMARY SCHOOL",
    Address: "122 MATSHELAPATA SECTION  MOPYANE  MANKWE",
    Latitude: "-24.9491",
    Longitude: "27.0744",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19566",
    WardLookupId: "2331",
    VDNumber: "86990114",
    RegisteredPopulation: "895",
    VotingStationName: "MMAMODIMOKWANA PRIMARY SCHOOL",
    Address: "562 MOGALE SECTION  MANTSERRE  MANKWE",
    Latitude: "-24.9496",
    Longitude: "27.0919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19567",
    WardLookupId: "2331",
    VDNumber: "86990125",
    RegisteredPopulation: "823",
    VotingStationName: "BAPTIST CHURCH",
    Address: "340 MARIBE SECTION  MANTSERRE  MANKWE",
    Latitude: "-24.9425",
    Longitude: "27.0981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19568",
    WardLookupId: "2331",
    VDNumber: "86992712",
    RegisteredPopulation: "340",
    VotingStationName: "ISANG MIDDLE SCHOOL",
    Address: "KRAALHOEK   MOGWASE  BAKGATLA BA KGAFELA",
    Latitude: "-24.9192",
    Longitude: "27.0835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19569",
    WardLookupId: "2331",
    VDNumber: "86992723",
    RegisteredPopulation: "382",
    VotingStationName: "MODISE SECONDARY SCHOOL",
    Address: "MANTSERRE  MOGWASE  BAKGATLA BA KGAFELA",
    Latitude: "-24.9417",
    Longitude: "27.0839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19570",
    WardLookupId: "2332",
    VDNumber: "86551588",
    RegisteredPopulation: "527",
    VotingStationName: "BAROLONG-BOO-MARIBA TRIBAL HALL",
    Address: "00 TSHIDILAMOLOMO  TSHIDILAMOLOMO VILLAGE  MAFIKENG",
    Latitude: "-25.8289",
    Longitude: "24.6839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19571",
    WardLookupId: "2332",
    VDNumber: "86551599",
    RegisteredPopulation: "464",
    VotingStationName: "MASAMANE PRIMARY SCHOOL",
    Address: "00 MASAMANE  TSHIDILAMOLOMO  MAFIKENG",
    Latitude: "-25.8275",
    Longitude: "24.6208",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19572",
    WardLookupId: "2332",
    VDNumber: "86551601",
    RegisteredPopulation: "573",
    VotingStationName: "MAKGORI PRIMARY SCHOOL",
    Address: "00 MAKGORI VILLAGE  MAKGORI  MAFIKENG",
    Latitude: "-25.8396",
    Longitude: "24.7932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19573",
    WardLookupId: "2332",
    VDNumber: "86551612",
    RegisteredPopulation: "953",
    VotingStationName: "MABULE PRIMARY SCHOOL",
    Address: "00 MABULE VILLAGE  MAFIKENG  MABULE",
    Latitude: "-25.7742",
    Longitude: "24.5582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19574",
    WardLookupId: "2332",
    VDNumber: "86553287",
    RegisteredPopulation: "701",
    VotingStationName: "MATHATENG PRIMARY SCHOOL",
    Address: "MATHATENG  BAROLONG BA GA MARUMOLWA",
    Latitude: "-25.8484",
    Longitude: "24.6128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19575",
    WardLookupId: "2332",
    VDNumber: "86553300",
    RegisteredPopulation: "527",
    VotingStationName: "DINGATENG PRIMARY SCHOOL",
    Address: "DINGATENG  MABULE",
    Latitude: "-25.7748",
    Longitude: "24.5244",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19576",
    WardLookupId: "2332",
    VDNumber: "86554211",
    RegisteredPopulation: "246",
    VotingStationName: "MOTHIBINYANE HIGH SCHOOL",
    Address: "TSHIDILAMOLOMO  BAROLONG BA GA MARUMOLOA",
    Latitude: "-25.8326",
    Longitude: "24.7027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19577",
    WardLookupId: "2333",
    VDNumber: "86551500",
    RegisteredPopulation: "532",
    VotingStationName: "TSHIPITOTA PRIMARY SCHOOL",
    Address: "00 MAKGOBITSTAD VILLAGE  MAKGOBISTAD  MAFIKENG",
    Latitude: "-25.7452",
    Longitude: "25.0991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19578",
    WardLookupId: "2333",
    VDNumber: "86551511",
    RegisteredPopulation: "432",
    VotingStationName: "BAROLONG BA GA MAKGOBI TRIBAL OFFICE",
    Address: "00 MAKGOBISTAD  MAKGOBI STAT  MAFIKENG",
    Latitude: "-25.7488",
    Longitude: "25.0987",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19579",
    WardLookupId: "2333",
    VDNumber: "86551522",
    RegisteredPopulation: "422",
    VotingStationName: "LOPORUNG PRIMARY SCHOOL",
    Address: "00 LOPORUNG VILLAGE  MAFIKENG",
    Latitude: "-25.7585",
    Longitude: "24.9829",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19580",
    WardLookupId: "2333",
    VDNumber: "86551533",
    RegisteredPopulation: "424",
    VotingStationName: "LOGAGANE PRIMARY SCHOOL",
    Address: "00 LOGAGANE VILLAGE  LOGAGANE  MAFIKENG",
    Latitude: "-25.8171",
    Longitude: "24.8895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19581",
    WardLookupId: "2333",
    VDNumber: "86551566",
    RegisteredPopulation: "75",
    VotingStationName: "DITLOUNG PRIMARY SCHOOL",
    Address: "00 DITLOUNG VILLAGE  DITLOUNG  MAKGOBISTADT",
    Latitude: "-25.7973",
    Longitude: "25.0873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19582",
    WardLookupId: "2333",
    VDNumber: "86551577",
    RegisteredPopulation: "145",
    VotingStationName: "DIKHUKHUNG PRIMARY SCHOOL",
    Address: "00 SEMASHU/SELOSESHA  MAKGOBISTAD",
    Latitude: "-25.8298",
    Longitude: "24.9716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19583",
    WardLookupId: "2333",
    VDNumber: "86553074",
    RegisteredPopulation: "251",
    VotingStationName: "MAYAEYANE PRIMARY SCHOOL",
    Address: "00 MAYAEYANE  MAKGOBISTAD",
    Latitude: "-25.8829",
    Longitude: "25.1387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19584",
    WardLookupId: "2333",
    VDNumber: "86553164",
    RegisteredPopulation: "105",
    VotingStationName: "MHELO PRIMARY SCHOOL",
    Address: "00 SASANE  ",
    Latitude: "-25.8592",
    Longitude: "25.014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19585",
    WardLookupId: "2333",
    VDNumber: "86553502",
    RegisteredPopulation: "742",
    VotingStationName: "MAKGOBI PRIMARY SCHOOL",
    Address: "00 MAKGOBISTAT  ",
    Latitude: "-25.7593",
    Longitude: "25.1081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19586",
    WardLookupId: "2333",
    VDNumber: "86553940",
    RegisteredPopulation: "515",
    VotingStationName: "GAETSALE PRIMARY SCHOOL",
    Address: "MAKGOBISTAD  ",
    Latitude: "-25.7429",
    Longitude: "25.0834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19587",
    WardLookupId: "2333",
    VDNumber: "86553951",
    RegisteredPopulation: "169",
    VotingStationName: "TEMPORARY VOTING STATION MAKGOBISTAD RDP SECTION",
    Address: "MAKGOBISTAD RDP SECTION  MAKGOBISTAD   BAROLONG BA GA MAKGOBI",
    Latitude: "-25.77",
    Longitude: "25.0911",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19588",
    WardLookupId: "2334",
    VDNumber: "86551061",
    RegisteredPopulation: "378",
    VotingStationName: "TSHOGANYETSO PRIMARY SCHOOL",
    Address: "00 DISANENG VILLAGE  DISANENG  BATLHARO BA GA MASIBI",
    Latitude: "-25.7924",
    Longitude: "25.2793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19589",
    WardLookupId: "2334",
    VDNumber: "86551072",
    RegisteredPopulation: "984",
    VotingStationName: "IPELEGENG BATLHARO PRIMARY SCHOOL",
    Address: "DISANENG  BATLHARO BA GA MASIBI",
    Latitude: "-25.8036",
    Longitude: "25.2961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19590",
    WardLookupId: "2334",
    VDNumber: "86551083",
    RegisteredPopulation: "956",
    VotingStationName: "METHUSELE II PRIMARY SCHOOL",
    Address: "00 DISANENG  ",
    Latitude: "-25.8084",
    Longitude: "25.2648",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19591",
    WardLookupId: "2334",
    VDNumber: "86551094",
    RegisteredPopulation: "474",
    VotingStationName: "MOTEU PRIMARY SCHOOL",
    Address: "00 DITSHETLONG  DISANENG  MAFIKENG",
    Latitude: "-25.7928",
    Longitude: "25.2467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19592",
    WardLookupId: "2334",
    VDNumber: "86553962",
    RegisteredPopulation: "191",
    VotingStationName: "DISANENG AT RDP CRECHE (TENT)",
    Address: "DISANENG  ",
    Latitude: "-25.7781",
    Longitude: "25.2723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19593",
    WardLookupId: "2334",
    VDNumber: "86554222",
    RegisteredPopulation: "486",
    VotingStationName: "JAN MASIBI SECONDARY SCHOOL",
    Address: "DISANENG  BATLHARO BA MASIBI TRIBAL AUTHORITY",
    Latitude: "-25.8023",
    Longitude: "25.3034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19594",
    WardLookupId: "2335",
    VDNumber: "86551544",
    RegisteredPopulation: "378",
    VotingStationName: "MATLODING PRIMARY SCHOOL OLD - (TENT)",
    Address: "TSHIDILAMOLOMO  BAROLONG BOO MARUMOLOA",
    Latitude: "-25.909",
    Longitude: "24.6796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19595",
    WardLookupId: "2335",
    VDNumber: "86551555",
    RegisteredPopulation: "870",
    VotingStationName: "BAROLONG KWA MOSITA TRIBAL OFFICE",
    Address: "00 LOGAGENG  MOSHAWANA  LOGAGENG",
    Latitude: "-25.9431",
    Longitude: "24.6967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19596",
    WardLookupId: "2335",
    VDNumber: "86551623",
    RegisteredPopulation: "977",
    VotingStationName: "TSOSELETSO INTERMEDIATE SCHOOL",
    Address: "00 LOGAGENG  MOSHAWANE  LOGAGENG",
    Latitude: "-26.0077",
    Longitude: "24.8149",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19597",
    WardLookupId: "2335",
    VDNumber: "86553670",
    RegisteredPopulation: "605",
    VotingStationName: "NG KERK MATLODING",
    Address: "00 MATLODING  TSHIDILAMOLOMO  BAROLONG BA GA MARUMOLOA",
    Latitude: "-25.9112",
    Longitude: "24.6612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19598",
    WardLookupId: "2335",
    VDNumber: "86553973",
    RegisteredPopulation: "720",
    VotingStationName: "MOSITA PRIMARY SCHOOL",
    Address: "LOGAGENG  ",
    Latitude: "-25.9397",
    Longitude: "24.6935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19599",
    WardLookupId: "2335",
    VDNumber: "86554233",
    RegisteredPopulation: "528",
    VotingStationName: "MATLODING PRIMARY SCHOOL - NEW",
    Address: "MATLODING  BAROLONG BA GA MARUMOLOA BOO MARIBA",
    Latitude: "-25.9113",
    Longitude: "24.658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19600",
    WardLookupId: "2336",
    VDNumber: "86551690",
    RegisteredPopulation: "1121",
    VotingStationName: "SETLAGOLE TRIBAL OFFICE",
    Address: "LETSAPA SECTION  SETLAGOLE VILLAGE  SETLAGOLE",
    Latitude: "-26.2664",
    Longitude: "25.1319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19601",
    WardLookupId: "2336",
    VDNumber: "86553490",
    RegisteredPopulation: "973",
    VotingStationName: "SETLAGOLE PRIMARY SCHOOL",
    Address: "00 SETLAGOLE  SRTLAGOLE  SETLAGOLE",
    Latitude: "-26.2641",
    Longitude: "25.1282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19602",
    WardLookupId: "2336",
    VDNumber: "86553984",
    RegisteredPopulation: "586",
    VotingStationName: "MALEBOGO PRIMARY SCHOOL",
    Address: "SETLAGOLE  ",
    Latitude: "-26.2816",
    Longitude: "25.1271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19603",
    WardLookupId: "2336",
    VDNumber: "86554244",
    RegisteredPopulation: "524",
    VotingStationName: "SETLAGOLE COMPLEX (TENT)",
    Address: "SETLAGOLE  LETSAPA TRIBAL AUTHORITY",
    Latitude: "-26.2759",
    Longitude: "25.1187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19604",
    WardLookupId: "2337",
    VDNumber: "86552185",
    RegisteredPopulation: "1253",
    VotingStationName: "SEDI LA RONA PRE SCHOOL",
    Address: "TLHAPING  MADIBOGO  BAROLONG BOO RATLOU",
    Latitude: "-26.4324",
    Longitude: "25.2047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19605",
    WardLookupId: "2337",
    VDNumber: "86553096",
    RegisteredPopulation: "1202",
    VotingStationName: "GREEN CENTRAL PRIMARY SCHOOL",
    Address: "00 MADIBOGO  ",
    Latitude: "-26.4187",
    Longitude: "25.1821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19606",
    WardLookupId: "2337",
    VDNumber: "86553995",
    RegisteredPopulation: "581",
    VotingStationName: "TLHAPING COMMUNITY HALL",
    Address: "MADIBOGO  ",
    Latitude: "-26.4315",
    Longitude: "25.1996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19607",
    WardLookupId: "2338",
    VDNumber: "86552152",
    RegisteredPopulation: "1098",
    VotingStationName: "GS PHOI HIGH SCHOOL",
    Address: "00 MADIBOGO  MADIBOGOPAN  MADIBOGO",
    Latitude: "-26.4801",
    Longitude: "25.1432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19608",
    WardLookupId: "2338",
    VDNumber: "86552196",
    RegisteredPopulation: "1394",
    VotingStationName: "GAESEGWE PRIMARY SCHOOL",
    Address: "00 MADIBOGO  MADIBOGOPAN  MADIBOGO",
    Latitude: "-26.5086",
    Longitude: "25.1408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19609",
    WardLookupId: "2338",
    VDNumber: "86553298",
    RegisteredPopulation: "175",
    VotingStationName: "DIOLWANE TRIBAL OFFICE",
    Address: "00 DIOLWANE  MADIBOGO",
    Latitude: "-26.505",
    Longitude: "25.0583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19610",
    WardLookupId: "2338",
    VDNumber: "86554008",
    RegisteredPopulation: "511",
    VotingStationName: "MADIBOGOPAN PRIMARY SCHOOL",
    Address: "MADIBOGOPAN  ",
    Latitude: "-26.4952",
    Longitude: "25.1495",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19611",
    WardLookupId: "2339",
    VDNumber: "86552039",
    RegisteredPopulation: "610",
    VotingStationName: "TLHAKAJENG PRIMARY SCHOOL",
    Address: "00 TLHAKAJENG  KRAAIPAAN",
    Latitude: "-26.3031",
    Longitude: "25.3525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19612",
    WardLookupId: "2339",
    VDNumber: "86552040",
    RegisteredPopulation: "1037",
    VotingStationName: "NEW KRAAIPAN COMMUNITY HALL",
    Address: "00 NEW KRAAIPAN  KRAAIPAN",
    Latitude: "-26.2941",
    Longitude: "25.2952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19613",
    WardLookupId: "2339",
    VDNumber: "86553175",
    RegisteredPopulation: "487",
    VotingStationName: "RATLOU PRIMARY SCHOOL",
    Address: "00 KRAAIPAN  ",
    Latitude: "-26.3019",
    Longitude: "25.3307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19614",
    WardLookupId: "2339",
    VDNumber: "86553681",
    RegisteredPopulation: "1436",
    VotingStationName: "RETLAKGONA PRIMARY SCHOOL",
    Address: "00 MAREETSANE  ",
    Latitude: "-26.2187",
    Longitude: "25.4765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19615",
    WardLookupId: "2339",
    VDNumber: "86554020",
    RegisteredPopulation: "272",
    VotingStationName: "BATHO BATHO PRIMARY SCHOOL",
    Address: "MAREETSANE  ",
    Latitude: "-26.2018",
    Longitude: "25.4741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19616",
    WardLookupId: "2340",
    VDNumber: "86552129",
    RegisteredPopulation: "782",
    VotingStationName: "MADISEBO PRIMARY SCHOOL",
    Address: "00 GARELENG  GARELENG SEC  MADIBOGO",
    Latitude: "-26.4071",
    Longitude: "25.1409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19617",
    WardLookupId: "2340",
    VDNumber: "86552174",
    RegisteredPopulation: "951",
    VotingStationName: "MADIBOGO TRIBAL OFFICE",
    Address: "MADIBOGO  MADIBOGOPAN  MADIBOGO",
    Latitude: "-26.4403",
    Longitude: "25.1534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19618",
    WardLookupId: "2340",
    VDNumber: "86553063",
    RegisteredPopulation: "826",
    VotingStationName: "OBUSITSE INTERMIDIATE SCHOOL",
    Address: "00 MADIBOGO  ",
    Latitude: "-26.4227",
    Longitude: "25.1434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19619",
    WardLookupId: "2340",
    VDNumber: "86554019",
    RegisteredPopulation: "332",
    VotingStationName: "MOTSITLANE PRIMARY SCHOOL",
    Address: "MOTSITLANE  MADIBOGO MOTSITLANE",
    Latitude: "-26.4356",
    Longitude: "25.1496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19620",
    WardLookupId: "2340",
    VDNumber: "86554255",
    RegisteredPopulation: "421",
    VotingStationName: "UTLWANANG BAROLONG HIGH SCHOOL",
    Address: "LEHATLHENG  MADIBOGO  BAROLONG BA GA PHOI",
    Latitude: "-26.43",
    Longitude: "25.1731",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19621",
    WardLookupId: "2341",
    VDNumber: "86551656",
    RegisteredPopulation: "1424",
    VotingStationName: "MPHOETSILE PRIMARY SCHOOL",
    Address: "00 BATHO BATHO  MAREETSANE  MAREETSANE",
    Latitude: "-26.1911",
    Longitude: "25.4689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19622",
    WardLookupId: "2341",
    VDNumber: "86551757",
    RegisteredPopulation: "1215",
    VotingStationName: "MAREETSANE COMMUNITY HALL",
    Address: "BATHO-BATHO  MAREETSANE  MAREETSANE",
    Latitude: "-26.2043",
    Longitude: "25.4745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19623",
    WardLookupId: "2342",
    VDNumber: "86551814",
    RegisteredPopulation: "149",
    VotingStationName: "LAERSKOOL MAREETSANE",
    Address: "MAREETSANE STATION  MAREETSANE",
    Latitude: "-26.1481",
    Longitude: "25.422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19624",
    WardLookupId: "2342",
    VDNumber: "86552017",
    RegisteredPopulation: "810",
    VotingStationName: "AARON LETSAPA PRIMARY SCHOOL",
    Address: "00 KRAAIPAN  ",
    Latitude: "-26.2831",
    Longitude: "25.3095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19625",
    WardLookupId: "2342",
    VDNumber: "86552028",
    RegisteredPopulation: "1041",
    VotingStationName: "LETSAPA PRIMARY SCHOOL",
    Address: "00 KRAAIPAN  OLD KRAAIPAN  KRAAIPAN",
    Latitude: "-26.2958",
    Longitude: "25.2868",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19626",
    WardLookupId: "2342",
    VDNumber: "86553108",
    RegisteredPopulation: "259",
    VotingStationName: "KLIPDRIFT WINKEL",
    Address: "00 VRYBURG ROAD  MAREETSANE  MAREETSANE",
    Latitude: "-26.1038",
    Longitude: "25.2917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19627",
    WardLookupId: "2342",
    VDNumber: "86554277",
    RegisteredPopulation: "305",
    VotingStationName: "KRAAIPAN AT LESHOPE`S SHOP (TENT)",
    Address: "KRAAIPAN  BAROLONG BA GA LETSAPA",
    Latitude: "-26.3129",
    Longitude: "25.2905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19628",
    WardLookupId: "2342",
    VDNumber: "86554288",
    RegisteredPopulation: "415",
    VotingStationName: "BOITHAOPO SECONDARY SCHOOL",
    Address: "KRAAIPAN  BAROLONG BA GA PHOI BA GA LETSAPA",
    Latitude: "-26.2736",
    Longitude: "25.3025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19629",
    WardLookupId: "2343",
    VDNumber: "86552118",
    RegisteredPopulation: "1009",
    VotingStationName: "GOITSEONE PRIMARY SCHOOL",
    Address: "00 MADIBOGO  LENGANENG  MADIBOGO",
    Latitude: "-26.3918",
    Longitude: "25.1881",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19630",
    WardLookupId: "2343",
    VDNumber: "86552130",
    RegisteredPopulation: "820",
    VotingStationName: "TSOGANG  PRIMARY SCHOOL",
    Address: "00 DIKGATLHONG  MADIBOGO",
    Latitude: "-26.4003",
    Longitude: "25.1586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19631",
    WardLookupId: "2343",
    VDNumber: "86553085",
    RegisteredPopulation: "1250",
    VotingStationName: "KABELO PRIMARY SCHOOL",
    Address: "00 MADIBOGO  ",
    Latitude: "-26.4089",
    Longitude: "25.1876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19632",
    WardLookupId: "2343",
    VDNumber: "86553782",
    RegisteredPopulation: "527",
    VotingStationName: "FULL GOSPEL CHURCH OF CHRIST",
    Address: "PHAHAMENG SECTION  MADIBOGO  MADIBOGO",
    Latitude: "-26.3855",
    Longitude: "25.1762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19633",
    WardLookupId: "2344",
    VDNumber: "86551702",
    RegisteredPopulation: "1268",
    VotingStationName: "UPPER SETLAGOLE PRIMARY SCHOOL",
    Address: "00 UPPER SETLAGOLE  SETLAGOLE VILLAGE  SETLAGOLE",
    Latitude: "-26.327",
    Longitude: "25.1078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19634",
    WardLookupId: "2344",
    VDNumber: "86552107",
    RegisteredPopulation: "992",
    VotingStationName: "THUTLWANE PRIMARY SCHOOL",
    Address: "NO THUTLWANE  SETLAGOLE",
    Latitude: "-26.3256",
    Longitude: "25.1558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19635",
    WardLookupId: "2344",
    VDNumber: "86552141",
    RegisteredPopulation: "833",
    VotingStationName: "RAMABESA PRIMARY SCHOOL",
    Address: "00 RAMABESA  MADIBOGO",
    Latitude: "-26.3975",
    Longitude: "25.1154",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19636",
    WardLookupId: "2344",
    VDNumber: "86742591",
    RegisteredPopulation: "190",
    VotingStationName: "PAPIESVLAKTE SCHOOL",
    Address: "PAPIESVLAKTE  NALEDI [VRYBURG]",
    Latitude: "-26.3567",
    Longitude: "24.8574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19637",
    WardLookupId: "2345",
    VDNumber: "86551689",
    RegisteredPopulation: "981",
    VotingStationName: "AME CHURCH - SETLAGOLE",
    Address: "00 RAMOKOTO  SETLAGOLE",
    Latitude: "-26.2899",
    Longitude: "25.1185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19638",
    WardLookupId: "2345",
    VDNumber: "86552163",
    RegisteredPopulation: "1144",
    VotingStationName: "SENWAMATSANA PRIMARY SCHOOL",
    Address: "SENWAMATSANA  SENWAMETSANA  SETLAGOLE",
    Latitude: "-26.2912",
    Longitude: "25.1207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19639",
    WardLookupId: "2345",
    VDNumber: "86553793",
    RegisteredPopulation: "1755",
    VotingStationName: "ONKABETSE THUTO COMMERCIAL HIGH SCHOOL",
    Address: "00 SETLAGOLE  ",
    Latitude: "-26.3099",
    Longitude: "25.1352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19640",
    WardLookupId: "2346",
    VDNumber: "86551858",
    RegisteredPopulation: "1883",
    VotingStationName: "LABA PRIMARY SCHOOL",
    Address: "MERAKENG SECTION  DEELPAN VILLAGE  BAKOLOBENG",
    Latitude: "-26.3462",
    Longitude: "25.6165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19641",
    WardLookupId: "2346",
    VDNumber: "86551915",
    RegisteredPopulation: "428",
    VotingStationName: "THIPANYANE PRIMARY SCHOOL",
    Address: "WITPAN 1  DELAREYVILLE  TSWAING",
    Latitude: "-26.4387",
    Longitude: "25.5683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19642",
    WardLookupId: "2346",
    VDNumber: "86552208",
    RegisteredPopulation: "118",
    VotingStationName: "TENT AT SCHOONGEZIGHT FARM",
    Address: "01 SCHOONGEZIGHT FARM  TSWAING   DEELPAN",
    Latitude: "-26.3779",
    Longitude: "25.6645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19643",
    WardLookupId: "2346",
    VDNumber: "86552242",
    RegisteredPopulation: "86",
    VotingStationName: "TENT AT KAMEELBOOMBULT FARM",
    Address: "KAMEELBOOMBULT FARM  TSWAING  SANNIESHOFF",
    Latitude: "-26.4243",
    Longitude: "25.7222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19644",
    WardLookupId: "2346",
    VDNumber: "86552781",
    RegisteredPopulation: "115",
    VotingStationName: "DOORNBULT INTERMEDIATE SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  SANNIESHOF  TSWAING",
    Latitude: "-26.5534",
    Longitude: "25.677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19645",
    WardLookupId: "2346",
    VDNumber: "86553311",
    RegisteredPopulation: "331",
    VotingStationName: "BAHENTSWE PRIMARY SCHOOL",
    Address: "WITPAN 2  DELAREYVILLE  TSWAING",
    Latitude: "-26.4084",
    Longitude: "25.5983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19646",
    WardLookupId: "2346",
    VDNumber: "86554198",
    RegisteredPopulation: "567",
    VotingStationName: "RAMADIANE PRIMARY SCHOOL",
    Address: "DEELPAN  BAKOLOBENG",
    Latitude: "-26.3361",
    Longitude: "25.5875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19647",
    WardLookupId: "2347",
    VDNumber: "86552051",
    RegisteredPopulation: "1282",
    VotingStationName: "KHUNWANA PRIMARY SCHOOL",
    Address: "MODIBOA SECTION  ATAMELANG  TSWAING",
    Latitude: "-26.3764",
    Longitude: "25.3277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19648",
    WardLookupId: "2347",
    VDNumber: "86552062",
    RegisteredPopulation: "564",
    VotingStationName: "GOO-SELEKA PRIMARY SCHOOL",
    Address: "GOO-SELEKA SECTION  KHUNWANA  TSWAING",
    Latitude: "-26.3991",
    Longitude: "25.3513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19649",
    WardLookupId: "2347",
    VDNumber: "86553254",
    RegisteredPopulation: "840",
    VotingStationName: "MOGAWANE HIGH SCHOOL",
    Address: "0 MOKOTO SECTION  ATAMELANG  TSWAING",
    Latitude: "-26.3603",
    Longitude: "25.3548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19650",
    WardLookupId: "2347",
    VDNumber: "86553535",
    RegisteredPopulation: "464",
    VotingStationName: "BAROLONG BOO RATLOU BA GA SEITSHIRO",
    Address: "GOO-MOSHOETTE SECTION  KHUNWANA  TSWAING",
    Latitude: "-26.3711",
    Longitude: "25.3235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19651",
    WardLookupId: "2347",
    VDNumber: "86554390",
    RegisteredPopulation: "204",
    VotingStationName: "GOO MOLEFI PRIMARY SCHOOL",
    Address: "01 KHUNWANA  DELAREYVILLE  BAROLONG BOO RATLOU BA GA SEITSHIRO",
    Latitude: "-26.3489",
    Longitude: "25.3399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19652",
    WardLookupId: "2348",
    VDNumber: "86551959",
    RegisteredPopulation: "585",
    VotingStationName: "SIONE PRIMARY SCHOOL",
    Address: "THABA SIONE  ATAMELANG  TSWAING",
    Latitude: "-26.3559",
    Longitude: "25.4213",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19653",
    WardLookupId: "2348",
    VDNumber: "86552073",
    RegisteredPopulation: "603",
    VotingStationName: "MOFUFUTSO 2 PRIMARY SCHOOL",
    Address: "MOFUFUTSO 2  ATAMELANG  TSWAING",
    Latitude: "-26.4451",
    Longitude: "25.4072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19654",
    WardLookupId: "2348",
    VDNumber: "86552095",
    RegisteredPopulation: "280",
    VotingStationName: "MOGAWANE MOSHOETE PRIMARY SCHOOL",
    Address: "MAJENG  ATAMELANG  TSWAING",
    Latitude: "-26.4417",
    Longitude: "25.2573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19655",
    WardLookupId: "2348",
    VDNumber: "86552477",
    RegisteredPopulation: "339",
    VotingStationName: "IPAAKANYENG PRIMARY SCHOOL",
    Address: "MOFUFUTSO 1  ATAMELANG  TSWAING",
    Latitude: "-26.398",
    Longitude: "25.3967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19656",
    WardLookupId: "2348",
    VDNumber: "86552826",
    RegisteredPopulation: "409",
    VotingStationName: "SERITE MOSHOETE PRIMARY SCHOOL",
    Address: "MORENA SECTION  ATAMELANG  TSWAING",
    Latitude: "-26.4368",
    Longitude: "25.309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19657",
    WardLookupId: "2348",
    VDNumber: "86552848",
    RegisteredPopulation: "655",
    VotingStationName: "GABADISE (EARLY LEARNING CENTER)",
    Address: "05 SHALENG VILLAGE  ATAMELANG  TSWAING",
    Latitude: "-26.4353",
    Longitude: "25.3438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19658",
    WardLookupId: "2348",
    VDNumber: "86553591",
    RegisteredPopulation: "261",
    VotingStationName: "KEJAKILE MIDDLE SCHOOL",
    Address: "MOFUFUTSO 2  KHUNWANA  BAROLONG BOORA SEITSHIRO",
    Latitude: "-26.4326",
    Longitude: "25.4078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19659",
    WardLookupId: "2349",
    VDNumber: "86551713",
    RegisteredPopulation: "676",
    VotingStationName: "DIRETSANE COMMUNITY HALL",
    Address: "DIRETSANE VILLAGE  DELAREYVILLE  TSWAING",
    Latitude: "-26.4462",
    Longitude: "25.5108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19660",
    WardLookupId: "2349",
    VDNumber: "86551881",
    RegisteredPopulation: "598",
    VotingStationName: "KELEBOGILE PRIMARY SCHOOL",
    Address: "THAWANE VILLAGE  DELAREYVILLE  TSWAING",
    Latitude: "-26.4087",
    Longitude: "25.5284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19661",
    WardLookupId: "2349",
    VDNumber: "86551892",
    RegisteredPopulation: "683",
    VotingStationName: "RAMOKONYANE MIDDLE SCHOOL",
    Address: "KOPELA VILLAGE  DELAREYVILLE  TSWAING",
    Latitude: "-26.4274",
    Longitude: "25.5332",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19662",
    WardLookupId: "2349",
    VDNumber: "86553243",
    RegisteredPopulation: "782",
    VotingStationName: "KOPELA COMMUNITY HALL",
    Address: "KOPELA VILLAGE  DELAREYVILLE  TSWAING",
    Latitude: "-26.4277",
    Longitude: "25.5222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19663",
    WardLookupId: "2349",
    VDNumber: "86553579",
    RegisteredPopulation: "429",
    VotingStationName: "NOTO HIGH SCHOOL",
    Address: "KOPELA VILLAGE  ATAMELANG  TSWAING",
    Latitude: "-26.4214",
    Longitude: "25.5121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19664",
    WardLookupId: "2349",
    VDNumber: "86553580",
    RegisteredPopulation: "550",
    VotingStationName: "THEBEYAME PRIMARY SCHOOL",
    Address: "KOPELA VILLAGE  ATAMELANG  TSWAING",
    Latitude: "-26.4344",
    Longitude: "25.52",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19665",
    WardLookupId: "2350",
    VDNumber: "86551904",
    RegisteredPopulation: "866",
    VotingStationName: "GANNALAAGTE TRIBAL OFFICE",
    Address: "0 GA-MALOKA SECTION  DELAREYVILLE  TSWAING",
    Latitude: "-26.4742",
    Longitude: "25.5365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19666",
    WardLookupId: "2350",
    VDNumber: "86551926",
    RegisteredPopulation: "931",
    VotingStationName: "RAKGWEDI MIDDLE SCHOOL",
    Address: "0 RAKGWEDI SECTION  DELAREYVILLE  TSWAING",
    Latitude: "-26.4928",
    Longitude: "25.5222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19667",
    WardLookupId: "2350",
    VDNumber: "86553513",
    RegisteredPopulation: "948",
    VotingStationName: "IPONTSHENG EARLY LEARNING CENTRE.",
    Address: "I646 GANNALAAGTE RAKGWEDI SECTION  GANALALAGTE  BAKOLOBENG",
    Latitude: "-26.4803",
    Longitude: "25.5311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19668",
    WardLookupId: "2350",
    VDNumber: "86554200",
    RegisteredPopulation: "598",
    VotingStationName: "ITSOSENG BAKOLOBENG OLD AGE SOCIETY",
    Address: "01 GANALAAGTE VILLAGE  TSWAING, DELAREYVILLE  BAKOLOBENG",
    Latitude: "-26.4634",
    Longitude: "25.5337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19669",
    WardLookupId: "2351",
    VDNumber: "86551971",
    RegisteredPopulation: "1231",
    VotingStationName: "LERUNTSE-LESEDI SECONDARY SCHOOL",
    Address: "0 VRISCHGEWAAGD  ATAMELANG  TSWAING",
    Latitude: "-26.5385",
    Longitude: "25.4047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19670",
    WardLookupId: "2351",
    VDNumber: "86551982",
    RegisteredPopulation: "1283",
    VotingStationName: "THUSANO MIDDLE SCHOOL",
    Address: "0 VRISCHGEWAAGD VILLAGE  ATAMELANG  TSWAING",
    Latitude: "-26.5313",
    Longitude: "25.4155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19671",
    WardLookupId: "2351",
    VDNumber: "86552006",
    RegisteredPopulation: "147",
    VotingStationName: "MANAMOLELA CRECHE",
    Address: "0 MANAMOLELA VILLAGE  ATAMELANG  TSWAING",
    Latitude: "-26.4981",
    Longitude: "25.4101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19672",
    WardLookupId: "2351",
    VDNumber: "86553546",
    RegisteredPopulation: "845",
    VotingStationName: "MAROTSE PRIMARY SCHOOL",
    Address: "0 MAROTSE VILLAGE  ATAMELANG  TSWAING",
    Latitude: "-26.5283",
    Longitude: "25.3991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19673",
    WardLookupId: "2352",
    VDNumber: "86552084",
    RegisteredPopulation: "389",
    VotingStationName: "MIDDLETON A CRECHE",
    Address: "0 MIDDLETON A VILLAGE  ATAMELANG  TSWAING",
    Latitude: "-26.4751",
    Longitude: "25.2989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19674",
    WardLookupId: "2352",
    VDNumber: "86552488",
    RegisteredPopulation: "366",
    VotingStationName: "MIDDLETON B MIDDLE SCHOOL",
    Address: "MOKOPE VILLAGE  ATAMELANG  TSWAING",
    Latitude: "-26.4842",
    Longitude: "25.3331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19675",
    WardLookupId: "2352",
    VDNumber: "86552691",
    RegisteredPopulation: "196",
    VotingStationName: "BOSCHRAND OLD DAIRY",
    Address: "01 BOSCHRAND FARM  DELAREYVILLE  DELAREYVILLE",
    Latitude: "-26.5399",
    Longitude: "25.2162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19676",
    WardLookupId: "2352",
    VDNumber: "86552837",
    RegisteredPopulation: "324",
    VotingStationName: "MANONYANE CRECHE",
    Address: "NO STREET ADDRESS AVAILABLE  ATAMELANG  TSWAING",
    Latitude: "-26.4832",
    Longitude: "25.3696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19677",
    WardLookupId: "2352",
    VDNumber: "86570028",
    RegisteredPopulation: "1538",
    VotingStationName: "PHATSIMA HIGH SCHOOL",
    Address: "ATAMELANG TOWNSHIP  DELAREYVILLE, TSWAING  ATAMELANG",
    Latitude: "-26.5035",
    Longitude: "25.3669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19678",
    WardLookupId: "2352",
    VDNumber: "86570084",
    RegisteredPopulation: "590",
    VotingStationName: "UCC OF SOUTH AFRICA",
    Address: "0 NEXT TO POLICE STATION  ATAMELANG  TSWAING",
    Latitude: "-26.5035",
    Longitude: "25.3714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19679",
    WardLookupId: "2352",
    VDNumber: "86570095",
    RegisteredPopulation: "360",
    VotingStationName: "NG KERK IN AFRIKA",
    Address: "1 NORTH ROAD  ATAMELANG  TSWAING",
    Latitude: "-26.4972",
    Longitude: "25.3715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19680",
    WardLookupId: "2353",
    VDNumber: "86610012",
    RegisteredPopulation: "1750",
    VotingStationName: "AGISANANG COMMUNITY HALL",
    Address: "14 END ROAD  SANNIESHOF  TSWAING",
    Latitude: "-26.5591",
    Longitude: "25.8221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19681",
    WardLookupId: "2353",
    VDNumber: "86610023",
    RegisteredPopulation: "1312",
    VotingStationName: "SANNIESHOF MUNICIPAL OFFICE",
    Address: "1 MUNICIPAL OFFICE  SANNIESHOF  TSWAING",
    Latitude: "-26.5299",
    Longitude: "25.8088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19682",
    WardLookupId: "2353",
    VDNumber: "86610034",
    RegisteredPopulation: "466",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "1 AGISANANG LOCATION  SANNIESHOF  DELAREYVILLE",
    Latitude: "-26.5582",
    Longitude: "25.8241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19683",
    WardLookupId: "2354",
    VDNumber: "86550981",
    RegisteredPopulation: "333",
    VotingStationName: "BOTSHELO PRIMARY SCHOOL",
    Address: "01 EXCELSIOR FARM  DELAREYVILLE  TSWAING",
    Latitude: "-26.6588",
    Longitude: "25.2605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19684",
    WardLookupId: "2354",
    VDNumber: "86551678",
    RegisteredPopulation: "764",
    VotingStationName: "DUIKERBOS PRIMARY SCHOOL",
    Address: "GEYSDORP VILLAGE  DELAREYVILLE  DELAREYVILLE",
    Latitude: "-26.5429",
    Longitude: "25.2928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19685",
    WardLookupId: "2354",
    VDNumber: "86552680",
    RegisteredPopulation: "139",
    VotingStationName: "GROENTELAND FARM",
    Address: "01 GROENTELAND FARM  DELAREYVILLE  TSWAING",
    Latitude: "-26.806",
    Longitude: "25.2519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19686",
    WardLookupId: "2354",
    VDNumber: "86570039",
    RegisteredPopulation: "143",
    VotingStationName: "TENT AT BAILEY PRIMARY SCHOOL",
    Address: "BAILEY FARM  TSWAING  DELAREYVILLE",
    Latitude: "-26.614",
    Longitude: "25.3794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19687",
    WardLookupId: "2354",
    VDNumber: "86570062",
    RegisteredPopulation: "2171",
    VotingStationName: "LODIRILE SECONDARY SCHOOL",
    Address: "30 RDP SETTLEMENT  DELAREYVILLE  TSWAING",
    Latitude: "-26.6858",
    Longitude: "25.4396",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19688",
    WardLookupId: "2355",
    VDNumber: "86552220",
    RegisteredPopulation: "954",
    VotingStationName: "TLHOAFALO SECONDARY SCHOOL",
    Address: "0 AGISANANG LOCATION  SANNIESHOF  TSWAING",
    Latitude: "-26.5649",
    Longitude: "25.8244",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19689",
    WardLookupId: "2355",
    VDNumber: "86552253",
    RegisteredPopulation: "171",
    VotingStationName: "VERDIEND PRIMARY SCHOOL (TENT)",
    Address: "BAILEY FARM  TSWAING  DELAREYVILLE",
    Latitude: "-26.5252",
    Longitude: "25.79",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19690",
    WardLookupId: "2355",
    VDNumber: "86552703",
    RegisteredPopulation: "232",
    VotingStationName: "VERMAAS  BOERESAAL",
    Address: "0 VERMAAS FARM  SANNIESHOF  TSWAING",
    Latitude: "-26.5361",
    Longitude: "25.9961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19691",
    WardLookupId: "2355",
    VDNumber: "86552714",
    RegisteredPopulation: "133",
    VotingStationName: "ZOOIHUIS FARM",
    Address: "0 ZOOIHUIS FARM  SANNIESHOF  TSWAING",
    Latitude: "-26.4761",
    Longitude: "25.8797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19692",
    WardLookupId: "2355",
    VDNumber: "86553805",
    RegisteredPopulation: "1401",
    VotingStationName: "TIRISANO SECONDARY SCHOOL",
    Address: "1 RDP SETTLEMENT  AGISANANG   TSWAING",
    Latitude: "-26.5684",
    Longitude: "25.828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19693",
    WardLookupId: "2356",
    VDNumber: "86600022",
    RegisteredPopulation: "1266",
    VotingStationName: "LETSOPA COMMUNITY HALL",
    Address: "11 OLD ROAD  OTTOSDAL  TSWAING",
    Latitude: "-26.8066",
    Longitude: "25.9916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19694",
    WardLookupId: "2356",
    VDNumber: "86600055",
    RegisteredPopulation: "1518",
    VotingStationName: "NALEDI PRIMARY SCHOOL",
    Address: "10 LETSOPA LOCATION  OTTOSDAL  TSWAING",
    Latitude: "-26.8023",
    Longitude: "25.9779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19695",
    WardLookupId: "2356",
    VDNumber: "86600088",
    RegisteredPopulation: "760",
    VotingStationName: "LETSOPA PRIMARY SCHOOL",
    Address: "(NW382 - TSWAING [DELAREYVILLE])   ",
    Latitude: "-26.8075",
    Longitude: "25.9855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19696",
    WardLookupId: "2357",
    VDNumber: "86552219",
    RegisteredPopulation: "189",
    VotingStationName: "HOLFONTEIN FARM",
    Address: "01 HOLFONTEIN  OTTOSDAL  TSWAING",
    Latitude: "-26.7204",
    Longitude: "26.0938",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19697",
    WardLookupId: "2357",
    VDNumber: "86552231",
    RegisteredPopulation: "414",
    VotingStationName: "UITSCHOT PRIMARY SCHOOL",
    Address: "0 UITSCHOT FARM  OTTOSDAL  TSWAING",
    Latitude: "-26.6074",
    Longitude: "26.0395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19698",
    WardLookupId: "2357",
    VDNumber: "86552747",
    RegisteredPopulation: "93",
    VotingStationName: "RONDEPAN FARM",
    Address: "01 RONDEPAN  DELAREYVILLE  TSWAING",
    Latitude: "-26.6448",
    Longitude: "25.6405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19699",
    WardLookupId: "2357",
    VDNumber: "86552769",
    RegisteredPopulation: "125",
    VotingStationName: "TENT AT GESTOPTEFONTEIN FARM",
    Address: "GESTOPTEFONTEIN FARM  OTTOSDAL  OTTOSDAL",
    Latitude: "-26.718",
    Longitude: "25.9763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19700",
    WardLookupId: "2357",
    VDNumber: "86552770",
    RegisteredPopulation: "344",
    VotingStationName: "BOSPOORT COMBINED  SCHOOL",
    Address: "1 BOSPOORT  OTTOSDAL  TSWAING",
    Latitude: "-26.6173",
    Longitude: "26.1707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19701",
    WardLookupId: "2357",
    VDNumber: "86553265",
    RegisteredPopulation: "388",
    VotingStationName: "GERDAU BOERESAAL",
    Address: "1 GERDAU  OTTOSDAL  TSWAING",
    Latitude: "-26.4757",
    Longitude: "26.1031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19702",
    WardLookupId: "2357",
    VDNumber: "86553276",
    RegisteredPopulation: "137",
    VotingStationName: "PALMIETKUIL PRIMARY SCHOOL",
    Address: "0 PALMIETKUIL FARM  SANNIESHOF  TSWAING",
    Latitude: "-26.5998",
    Longitude: "25.8141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19703",
    WardLookupId: "2357",
    VDNumber: "86553557",
    RegisteredPopulation: "193",
    VotingStationName: "JATHOLIMA PRIMARY SCHOOL",
    Address: "0 N/A  OTTOSDAL  TSWAING",
    Latitude: "-26.6508",
    Longitude: "25.9513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19704",
    WardLookupId: "2357",
    VDNumber: "86570073",
    RegisteredPopulation: "137",
    VotingStationName: "BARBERSPAN FARM STORE",
    Address: "1 BARBERSPAN  DELAREYVILLE  TSWAING",
    Latitude: "-26.613",
    Longitude: "25.5851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19705",
    WardLookupId: "2357",
    VDNumber: "86600099",
    RegisteredPopulation: "809",
    VotingStationName: "LEBOGANG PRIMARY SCHOOL",
    Address: "LEROLE STREET  OTTOSDAL  OTTOSDAL",
    Latitude: "-26.799",
    Longitude: "25.9896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19706",
    WardLookupId: "2358",
    VDNumber: "86600033",
    RegisteredPopulation: "1982",
    VotingStationName: "TUMISANG PRIMARY SCHOOL",
    Address: "22 OLD ROAD  OTTOSDAL  TSWAING",
    Latitude: "-26.8018",
    Longitude: "25.9857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19707",
    WardLookupId: "2358",
    VDNumber: "86600044",
    RegisteredPopulation: "979",
    VotingStationName: "OTTOSDAL MUNICIPAL OFFICE",
    Address: "50 VOORTREKKER STREET  OTTOSDAL  TSWAING",
    Latitude: "-26.8113",
    Longitude: "26.0042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19708",
    WardLookupId: "2359",
    VDNumber: "86570017",
    RegisteredPopulation: "1685",
    VotingStationName: "DELAREYVILLE TOWN HALL",
    Address: "50 GEN. DELAREYSTREET  DELAREYVILLE  TSWAING",
    Latitude: "-26.6856",
    Longitude: "25.4568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19709",
    WardLookupId: "2359",
    VDNumber: "86570040",
    RegisteredPopulation: "88",
    VotingStationName: "DAMASCUS FARM",
    Address: "01 DAMASCUS  DELAREYVILLE  TSWAING",
    Latitude: "-26.5864",
    Longitude: "25.4377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19710",
    WardLookupId: "2359",
    VDNumber: "86570051",
    RegisteredPopulation: "360",
    VotingStationName: "TENT AT JACHTKRAAL FARM (INFORMAL SETTLEMENT)",
    Address: "JACHTKRAAL FARM  DELAREYVILLE  DELAREYVILLE",
    Latitude: "-26.7009",
    Longitude: "25.5225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19711",
    WardLookupId: "2359",
    VDNumber: "86570107",
    RegisteredPopulation: "1593",
    VotingStationName: "REATLEGILE PRIMARY SCHOOL",
    Address: "MATSHELAPAD EXT 8  DELAREYVILLE  DELAREYVILLE",
    Latitude: "-26.6751",
    Longitude: "25.4479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19712",
    WardLookupId: "2360",
    VDNumber: "86551993",
    RegisteredPopulation: "137",
    VotingStationName: "TENT AT PAUWKOP FARM",
    Address: "PAUWKOP FARM  DELAREYVILLE  DELAREYVILLE",
    Latitude: "-26.7966",
    Longitude: "25.3682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19713",
    WardLookupId: "2360",
    VDNumber: "86552398",
    RegisteredPopulation: "229",
    VotingStationName: "TENT AT BOSCHBULT FARM",
    Address: "01 ROSTRATAVILLE   DELAREYVILLE  DELAREYVILLE",
    Latitude: "-26.7935",
    Longitude: "25.722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19714",
    WardLookupId: "2360",
    VDNumber: "86552679",
    RegisteredPopulation: "137",
    VotingStationName: "ZOUTPAN FARM",
    Address: "01 ZOUTPAN FARM  DELAREYVILLE  TSWAING",
    Latitude: "-26.7537",
    Longitude: "25.4562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19715",
    WardLookupId: "2360",
    VDNumber: "86552736",
    RegisteredPopulation: "326",
    VotingStationName: "MAMORATWA COMBINED SCHOOL",
    Address: "0 N/A  OTTOSDAL  TSWAING",
    Latitude: "-26.8046",
    Longitude: "25.8028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19716",
    WardLookupId: "2360",
    VDNumber: "86552758",
    RegisteredPopulation: "165",
    VotingStationName: "CHARL DE WET SAAL",
    Address: "0 CHARL DE WET  DELAREYVILLE  TSWAING",
    Latitude: "-26.8111",
    Longitude: "25.6434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19717",
    WardLookupId: "2360",
    VDNumber: "86600011",
    RegisteredPopulation: "983",
    VotingStationName: "LERETLETSI LESEDI SECONDARY SCHOOL",
    Address: "20 DWARSA STREET  OTTOSDAL  TSWAING",
    Latitude: "-26.8072",
    Longitude: "25.9794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19718",
    WardLookupId: "2360",
    VDNumber: "86600066",
    RegisteredPopulation: "713",
    VotingStationName: "REALEKA SECONDARY SCHOOL",
    Address: "15 KWATA STREET  OTTOSDAL  TSWAING",
    Latitude: "-26.8043",
    Longitude: "25.9752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19719",
    WardLookupId: "2361",
    VDNumber: "86551128",
    RegisteredPopulation: "1047",
    VotingStationName: "MODIMOLA PRIMARY SCHOOL",
    Address: "MODIMOLA  MAFIKENG",
    Latitude: "-25.864",
    Longitude: "25.4541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19720",
    WardLookupId: "2361",
    VDNumber: "86551151",
    RegisteredPopulation: "1523",
    VotingStationName: "LEKOKO PRIMARY SCHOOL",
    Address: "LEKOKO  MAFIKENG",
    Latitude: "-25.9313",
    Longitude: "25.5025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19721",
    WardLookupId: "2361",
    VDNumber: "86551173",
    RegisteredPopulation: "904",
    VotingStationName: "SELEJE HIGH SCHOOL",
    Address: "MADIBE-MAKGABANA  ",
    Latitude: "-25.9263",
    Longitude: "25.4055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19722",
    WardLookupId: "2361",
    VDNumber: "86553018",
    RegisteredPopulation: "272",
    VotingStationName: "MAKGABANA PRIMARY SCHOOL",
    Address: "MAFIKENG  ",
    Latitude: "-25.897",
    Longitude: "25.3805",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19723",
    WardLookupId: "2362",
    VDNumber: "86551038",
    RegisteredPopulation: "918",
    VotingStationName: "MAILAKGANG PRIMARY SCHOOL",
    Address: "MAFIKENG  ",
    Latitude: "-25.7832",
    Longitude: "25.4094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19724",
    WardLookupId: "2362",
    VDNumber: "86551050",
    RegisteredPopulation: "335",
    VotingStationName: "MOLETSAMONGWE PRIMARY SCHOOL",
    Address: "MOLETSAMONGWE VILLAGE  MOLETSAMONGWE  MMABATHO",
    Latitude: "-25.8143",
    Longitude: "25.5037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19725",
    WardLookupId: "2362",
    VDNumber: "86551106",
    RegisteredPopulation: "485",
    VotingStationName: "MODIKO PRIMARY SCHOOL",
    Address: "KABE VILLAGE  MAFIKENG",
    Latitude: "-25.8665",
    Longitude: "25.3167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19726",
    WardLookupId: "2362",
    VDNumber: "86551139",
    RegisteredPopulation: "561",
    VotingStationName: "MADIBANA SCHOOL",
    Address: "MODIMOLA VILLAGE  MMABATHO",
    Latitude: "-25.84",
    Longitude: "25.4624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19727",
    WardLookupId: "2362",
    VDNumber: "86553007",
    RegisteredPopulation: "418",
    VotingStationName: "MASUTLHE 1 PRIMARY SCHOOL",
    Address: "MASUTLHE  MASUTLHE VILLAGE  MAFIKENG",
    Latitude: "-25.852",
    Longitude: "25.409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19728",
    WardLookupId: "2362",
    VDNumber: "86590176",
    RegisteredPopulation: "1433",
    VotingStationName: "GRACE BIBLE CHURCH",
    Address: "6895 CYDONIA  UNIT 15  MMABATHO",
    Latitude: "-25.849",
    Longitude: "25.5756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19729",
    WardLookupId: "2363",
    VDNumber: "86550756",
    RegisteredPopulation: "1056",
    VotingStationName: "OMEGA PRIMARY SCHOOL",
    Address: "MIGA VILLAGE  RAMATLABAMA  MAFIKENG",
    Latitude: "-25.6569",
    Longitude: "25.5666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19730",
    WardLookupId: "2363",
    VDNumber: "86550789",
    RegisteredPopulation: "987",
    VotingStationName: "BATLOUNG HIGH SCHOOL",
    Address: "IKOPELENG VILLAGE  RAMATLABAMA  MAFIKENG",
    Latitude: "-25.6845",
    Longitude: "25.6076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19731",
    WardLookupId: "2363",
    VDNumber: "86550790",
    RegisteredPopulation: "434",
    VotingStationName: "BAFOKENG BA MAGOKGWANE",
    Address: "MAGOKGWANE VILLAGE  RAMATLABAMA  MAFIKENG",
    Latitude: "-25.7236",
    Longitude: "25.6098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19732",
    WardLookupId: "2363",
    VDNumber: "86550802",
    RegisteredPopulation: "1931",
    VotingStationName: "MELORANE PRIMARY SCHOOL",
    Address: "SIX HUNDRED  MAFIKENG",
    Latitude: "-25.7086",
    Longitude: "25.6184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19733",
    WardLookupId: "2364",
    VDNumber: "86550701",
    RegisteredPopulation: "151",
    VotingStationName: "FARMERS HALL",
    Address: "JAGERSFONTEIN  MAFIKENG",
    Latitude: "-25.6195",
    Longitude: "25.7093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19734",
    WardLookupId: "2364",
    VDNumber: "86550778",
    RegisteredPopulation: "2193",
    VotingStationName: "BAKWENA BA-MOLOPYANE TRIBAL AUTHORITY",
    Address: "TSETSE VILLAGE  MAFIKENG",
    Latitude: "-25.7341",
    Longitude: "25.6691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19735",
    WardLookupId: "2364",
    VDNumber: "86552860",
    RegisteredPopulation: "232",
    VotingStationName: "ONKGOPOTSE TIRO COMPREHENSIVE SCHOOL",
    Address: "MAFIKENG  ",
    Latitude: "-25.7131",
    Longitude: "25.8501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19736",
    WardLookupId: "2364",
    VDNumber: "86650016",
    RegisteredPopulation: "970",
    VotingStationName: "OLD MAGISTRATES COURT",
    Address: "88 COMMISSIONER ST  OTTOSHOOP",
    Latitude: "-25.7496",
    Longitude: "25.9654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19737",
    WardLookupId: "2364",
    VDNumber: "86650027",
    RegisteredPopulation: "60",
    VotingStationName: "MOLEMANE EYE TOURISM DEPT",
    Address: "LICHTENBURG ROAD  OTTOSHOOP  MAFIKENG",
    Latitude: "-25.8127",
    Longitude: "26.0531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19738",
    WardLookupId: "2365",
    VDNumber: "86551320",
    RegisteredPopulation: "2497",
    VotingStationName: "MOKGWEETSI PRIMARY SCHOOL",
    Address: "PO LOMANYANENG  LOMANYANENG VILLAGE  MAFIKENG",
    Latitude: "-25.9024",
    Longitude: "25.6466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19739",
    WardLookupId: "2365",
    VDNumber: "86551331",
    RegisteredPopulation: "986",
    VotingStationName: "LOMANYANE PRIMARY SCHOOL",
    Address: "PO LOMANYANENG  LOMANYANENG VILLAGE  MAFIKENG",
    Latitude: "-25.8924",
    Longitude: "25.6543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19740",
    WardLookupId: "2366",
    VDNumber: "86550992",
    RegisteredPopulation: "890",
    VotingStationName: "MOGOSANE P SCHOOL",
    Address: "MOGOSANE VILLAGE  MOGOSANE  MAFIKENG",
    Latitude: "-25.7385",
    Longitude: "25.5513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19741",
    WardLookupId: "2366",
    VDNumber: "86551027",
    RegisteredPopulation: "405",
    VotingStationName: "ITEKENG PRIMARY SCHOOL",
    Address: "TLAPENG 2  VILLAGE  TLAPENG  MAFIKENG",
    Latitude: "-25.7196",
    Longitude: "25.4561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19742",
    WardLookupId: "2366",
    VDNumber: "86551735",
    RegisteredPopulation: "984",
    VotingStationName: "LOKALENG PRIMARY SCHOOL",
    Address: "LOKALENG  MAFIKENG",
    Latitude: "-25.7838",
    Longitude: "25.5698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19743",
    WardLookupId: "2366",
    VDNumber: "86552859",
    RegisteredPopulation: "132",
    VotingStationName: "REALEBOGA PRIMARY SCHOOL",
    Address: "MAFIKENG  ",
    Latitude: "-25.7236",
    Longitude: "25.5106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19744",
    WardLookupId: "2366",
    VDNumber: "86552871",
    RegisteredPopulation: "1058",
    VotingStationName: "MOJAMORAGO PRIMARY SCHOOL",
    Address: "MOLELWANE  MAHIKENG  MAHIKENG",
    Latitude: "-25.8018",
    Longitude: "25.6448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19745",
    WardLookupId: "2366",
    VDNumber: "86553029",
    RegisteredPopulation: "274",
    VotingStationName: "KOPANANG EARLY LEARNING CENTRE",
    Address: "1 MABUDUTSHWANA  MAFIKENG",
    Latitude: "-25.7509",
    Longitude: "25.4207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19746",
    WardLookupId: "2367",
    VDNumber: "86590075",
    RegisteredPopulation: "1973",
    VotingStationName: "MAFIKENG HIGH SCHOOL",
    Address: "19 SHIPPARD  GOLF VIEW  MAFIKENG",
    Latitude: "-25.863",
    Longitude: "25.6486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19747",
    WardLookupId: "2367",
    VDNumber: "86590154",
    RegisteredPopulation: "1935",
    VotingStationName: "RETLAMETSWE SPECIAL SCHOOL",
    Address: "IMPERIAL RESERVE  MAFIKENG",
    Latitude: "-25.8672",
    Longitude: "25.6344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19748",
    WardLookupId: "2368",
    VDNumber: "86552501",
    RegisteredPopulation: "1215",
    VotingStationName: "MMABATHO CIVIC CENTRE",
    Address: "DEPT OF LOCAL GOVT & HOUSING BUILDING  MMABATHO  MAFIKENG",
    Latitude: "-25.8354",
    Longitude: "25.6117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19749",
    WardLookupId: "2368",
    VDNumber: "86590109",
    RegisteredPopulation: "2342",
    VotingStationName: "BATSWANA HIGH SCHOOL",
    Address: "MONTSHIWA  MAFIKENG  MOLOPO",
    Latitude: "-25.8463",
    Longitude: "25.6249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19750",
    WardLookupId: "2369",
    VDNumber: "86550734",
    RegisteredPopulation: "566",
    VotingStationName: "BUHRMANNSDRIF LAERSKOOL",
    Address: "BUHRMANNSDRIF FARM  MAFIKENG",
    Latitude: "-25.8366",
    Longitude: "25.7934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19751",
    WardLookupId: "2369",
    VDNumber: "86553849",
    RegisteredPopulation: "336",
    VotingStationName: "SLURRY PRIMARY SCHOOL",
    Address: "MATSATSENG  SLURRY  MAFIKENG",
    Latitude: "-25.8166",
    Longitude: "25.8335",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19752",
    WardLookupId: "2369",
    VDNumber: "86590086",
    RegisteredPopulation: "1251",
    VotingStationName: "MADIBA HIGH SCHOOL",
    Address: "ROOIGROND  MAFIKENG",
    Latitude: "-25.9211",
    Longitude: "25.7954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19753",
    WardLookupId: "2369",
    VDNumber: "86590121",
    RegisteredPopulation: "1507",
    VotingStationName: "WOUTER DE VOS RUGBY STADIUM (HALL)",
    Address: "RUGBY STREET  RIVIERA PARK  MAFIKENG",
    Latitude: "-25.8599",
    Longitude: "25.6598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19754",
    WardLookupId: "2370",
    VDNumber: "86552882",
    RegisteredPopulation: "2024",
    VotingStationName: "LEGAE LA BANA",
    Address: "MAFIKENG  ",
    Latitude: "-25.8431",
    Longitude: "25.6077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19755",
    WardLookupId: "2370",
    VDNumber: "86590053",
    RegisteredPopulation: "2255",
    VotingStationName: "REDIBONE SECONDARY SCHOOL",
    Address: "1425 BATLAKO  UNIT 7  MMABATHO",
    Latitude: "-25.8388",
    Longitude: "25.601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19756",
    WardLookupId: "2371",
    VDNumber: "86551432",
    RegisteredPopulation: "1889",
    VotingStationName: "B M MOKITIME PRIMARY SCHOOL",
    Address: "SEWEDING  MAFIKENG",
    Latitude: "-25.8669",
    Longitude: "25.5882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19757",
    WardLookupId: "2371",
    VDNumber: "86552905",
    RegisteredPopulation: "1122",
    VotingStationName: "METHODIST CHURCH",
    Address: "PO MOTSOSENG  MOTSOSENG VILLAGE  MAFIKENG",
    Latitude: "-25.867",
    Longitude: "25.5822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19758",
    WardLookupId: "2371",
    VDNumber: "86554323",
    RegisteredPopulation: "512",
    VotingStationName: "WATER OFFICE EXTENTION (TENT)",
    Address: "PHOLA VILLAGE  PHOLA  MAFIKENG",
    Latitude: "-25.8565",
    Longitude: "25.5864",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19759",
    WardLookupId: "2372",
    VDNumber: "86590020",
    RegisteredPopulation: "4309",
    VotingStationName: "KEBONANG SECONDARY SCHOOL",
    Address: "4994 C/O TSHETLO & LORITO  UNIT 14  MMABATHO",
    Latitude: "-25.8588",
    Longitude: "25.5674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19760",
    WardLookupId: "2373",
    VDNumber: "86590031",
    RegisteredPopulation: "2981",
    VotingStationName: "EP LEKHELA PRIMARY SCHOOL",
    Address: "3083 VAN DEN BRINK DRIVE  UNIT 9  MMABATHO",
    Latitude: "-25.8521",
    Longitude: "25.5832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19761",
    WardLookupId: "2374",
    VDNumber: "86551454",
    RegisteredPopulation: "2257",
    VotingStationName: "GAISRAEL SCHOOL",
    Address: "MOCOSENG  MMABATHO",
    Latitude: "-25.8833",
    Longitude: "25.5772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19762",
    WardLookupId: "2374",
    VDNumber: "86552949",
    RegisteredPopulation: "875",
    VotingStationName: "MORIAN EPISCOPAL APOSTOLIC CHURCH IN ZION",
    Address: "PO MAGOGWE  MAGOGWE TAR VILLAGE  MAFIKENG",
    Latitude: "-25.8847",
    Longitude: "25.6011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19763",
    WardLookupId: "2374",
    VDNumber: "86553333",
    RegisteredPopulation: "1108",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "SEWEDING VILLAGE  MAFIKENG",
    Latitude: "-25.8692",
    Longitude: "25.5967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19764",
    WardLookupId: "2375",
    VDNumber: "86552376",
    RegisteredPopulation: "1840",
    VotingStationName: "BAROLONG RECREATION COMPLEX",
    Address: "MONTSHIWA STADT  MAFIKENG",
    Latitude: "-25.8719",
    Longitude: "25.6328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19765",
    WardLookupId: "2375",
    VDNumber: "86552387",
    RegisteredPopulation: "1118",
    VotingStationName: "AME CHAPPEL",
    Address: "LERWANENG  MAFIKENG",
    Latitude: "-25.8806",
    Longitude: "25.6369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19766",
    WardLookupId: "2375",
    VDNumber: "86552927",
    RegisteredPopulation: "690",
    VotingStationName: "AFRICAN GOSPEL CHURCH",
    Address: "TLOUNG  TLOUNG VILLAGE  MAFIKENG",
    Latitude: "-25.88",
    Longitude: "25.6144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19767",
    WardLookupId: "2376",
    VDNumber: "86552321",
    RegisteredPopulation: "1173",
    VotingStationName: "CHURCH OF THE NAZARENE",
    Address: "SEWEDING  MMABATHO",
    Latitude: "-25.8707",
    Longitude: "25.6057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19768",
    WardLookupId: "2376",
    VDNumber: "86552916",
    RegisteredPopulation: "597",
    VotingStationName: "FULL GOSPEL CHURCH",
    Address: "SEWEDING VILLAGE  MAFIKENG",
    Latitude: "-25.8632",
    Longitude: "25.6018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19769",
    WardLookupId: "2376",
    VDNumber: "86553423",
    RegisteredPopulation: "1558",
    VotingStationName: "MOLEMA PRIMARY SCHOOL",
    Address: "SEWEDING  MAFIKENG  BAROLONG BO RATSHIDI",
    Latitude: "-25.864",
    Longitude: "25.6101",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19770",
    WardLookupId: "2377",
    VDNumber: "86552938",
    RegisteredPopulation: "1164",
    VotingStationName: "ETHIOPIAN CHURCH OF SA",
    Address: "PO LOMANYANENG  LOMANYANENG VILLAGE  MAFIKENG",
    Latitude: "-25.886",
    Longitude: "25.6468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19771",
    WardLookupId: "2377",
    VDNumber: "86590132",
    RegisteredPopulation: "2190",
    VotingStationName: "DANVILLE SECONDARY SCHOOL",
    Address: "C/O MIDDEL & SCHOOL STR  DANVILLE  MAFIKENG",
    Latitude: "-25.8781",
    Longitude: "25.6537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19772",
    WardLookupId: "2378",
    VDNumber: "86551342",
    RegisteredPopulation: "1090",
    VotingStationName: "NG KERK IN AFRIKA",
    Address: "PO MAGOGWE  TLHABOLOGO VILLAGE  MAFIKENG",
    Latitude: "-25.8969",
    Longitude: "25.6404",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19773",
    WardLookupId: "2378",
    VDNumber: "86551364",
    RegisteredPopulation: "739",
    VotingStationName: "SEETSELE PRIMARY SCHOOL",
    Address: "MAFIKENG  GOO-RAMAKGETLA VILLAGE  MAFIKENG",
    Latitude: "-25.8839",
    Longitude: "25.6251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19774",
    WardLookupId: "2378",
    VDNumber: "86551409",
    RegisteredPopulation: "1256",
    VotingStationName: "LETEANE HIGH SCHOOL",
    Address: "STAND 12696 MAGOGOE TLOUNG  MAGOGOE TLOUNG VILLAGE  MAHIKENG",
    Latitude: "-25.886",
    Longitude: "25.6165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19775",
    WardLookupId: "2378",
    VDNumber: "86552354",
    RegisteredPopulation: "1007",
    VotingStationName: "BAROLONG HIGH SCHOOL",
    Address: "TLHABOLOGO  MAFIKENG",
    Latitude: "-25.8856",
    Longitude: "25.6287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19776",
    WardLookupId: "2379",
    VDNumber: "86551386",
    RegisteredPopulation: "1193",
    VotingStationName: "MOATLHODI SCHOOL",
    Address: "TLHABOLOGO  MAGOGWE KOI-KOI VILLAGE  MAFIKENG",
    Latitude: "-25.9076",
    Longitude: "25.618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19777",
    WardLookupId: "2379",
    VDNumber: "86551397",
    RegisteredPopulation: "1812",
    VotingStationName: "SENKGWE PRIMARY SCHOOL",
    Address: "TLHABOLOGO  MAGOGWE  MAFIKENG",
    Latitude: "-25.9067",
    Longitude: "25.6086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19778",
    WardLookupId: "2379",
    VDNumber: "86551410",
    RegisteredPopulation: "644",
    VotingStationName: "PHETLHU SCHOOL",
    Address: "MAGOGWE  MAFIKENG",
    Latitude: "-25.9069",
    Longitude: "25.6057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19779",
    WardLookupId: "2380",
    VDNumber: "86552309",
    RegisteredPopulation: "1951",
    VotingStationName: "PODILE SCHOOL",
    Address: "RAMOSADI  MAFIKENG",
    Latitude: "-25.8634",
    Longitude: "25.6209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19780",
    WardLookupId: "2380",
    VDNumber: "86590097",
    RegisteredPopulation: "2133",
    VotingStationName: "BODIRI PRIMARY SCHOOL",
    Address: "2135 MOLEBATSI  MONTSHIWA  MAFIKENG",
    Latitude: "-25.8414",
    Longitude: "25.6185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19781",
    WardLookupId: "2381",
    VDNumber: "86550835",
    RegisteredPopulation: "1126",
    VotingStationName: "BOITSHOKO SCHOOL",
    Address: "MAJEMANTSHO VILLAGE  MAJEMANTSHO  MAFIKENG",
    Latitude: "-25.8944",
    Longitude: "25.6617",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19782",
    WardLookupId: "2381",
    VDNumber: "86551263",
    RegisteredPopulation: "1516",
    VotingStationName: "SETUMO HIGH SCHOOL",
    Address: "MAJEMANTSHO  MAFIKENG",
    Latitude: "-25.9041",
    Longitude: "25.6687",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19783",
    WardLookupId: "2381",
    VDNumber: "86551274",
    RegisteredPopulation: "969",
    VotingStationName: "JM MOSIANE PRIMARY SCHOOL",
    Address: "MAJEMANTSHO  MAJEMANTSHO VILLAGE  MAFIKENG",
    Latitude: "-25.9041",
    Longitude: "25.6659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19784",
    WardLookupId: "2382",
    VDNumber: "86551049",
    RegisteredPopulation: "297",
    VotingStationName: "METHODIST CHURCH",
    Address: "TONTONYANE VILLAGE  TONTONYANE  MAFIKENG",
    Latitude: "-25.8777",
    Longitude: "25.5575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19785",
    WardLookupId: "2382",
    VDNumber: "86551296",
    RegisteredPopulation: "816",
    VotingStationName: "KOI-KOI PRIMARY SCHOOL",
    Address: "PO KOI-KOI  KOI-KOI VILLAGE  MAFIKENG",
    Latitude: "-25.936",
    Longitude: "25.5927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19786",
    WardLookupId: "2382",
    VDNumber: "86551319",
    RegisteredPopulation: "614",
    VotingStationName: "BOSWAJWARONA EARLY LEARNING CENTRE",
    Address: "SETLOPO  MAHIKENG",
    Latitude: "-25.9344",
    Longitude: "25.6392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19787",
    WardLookupId: "2382",
    VDNumber: "86551353",
    RegisteredPopulation: "1547",
    VotingStationName: "TUMISANG EARLY LEARNING CENTRE",
    Address: "SETLOPO  MAHIKENG",
    Latitude: "-25.9294",
    Longitude: "25.6164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19788",
    WardLookupId: "2382",
    VDNumber: "86553850",
    RegisteredPopulation: "1039",
    VotingStationName: "THUTO BOSWA PRIMARY SCHOOL",
    Address: "MAKHUBUNG  MAFIKENG",
    Latitude: "-25.913",
    Longitude: "25.547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19789",
    WardLookupId: "2383",
    VDNumber: "86551005",
    RegisteredPopulation: "1007",
    VotingStationName: "TSELAKGOSI MIDDLE SCHOOL",
    Address: "LOTLHAKANE VILLAGE  LOTLHAKANE  MAFIKENG",
    Latitude: "-26.0264",
    Longitude: "25.6189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19790",
    WardLookupId: "2383",
    VDNumber: "86551207",
    RegisteredPopulation: "1256",
    VotingStationName: "RAPULANA PRIMARY SCHOOL",
    Address: "LOTLHAKANE  MAFIKENG",
    Latitude: "-26.0284",
    Longitude: "25.6376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19791",
    WardLookupId: "2383",
    VDNumber: "86552455",
    RegisteredPopulation: "702",
    VotingStationName: "BOITUMELO PRIMARY SCHOOL",
    Address: "BETHEL  MAFIKENG",
    Latitude: "-26.0626",
    Longitude: "25.6709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19792",
    WardLookupId: "2383",
    VDNumber: "86553322",
    RegisteredPopulation: "1157",
    VotingStationName: "MOSENOGI PRIMARY SCHOOL",
    Address: "LOTLHAKANE VILLAGE  MAFIKENG",
    Latitude: "-26.008",
    Longitude: "25.6417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19793",
    WardLookupId: "2384",
    VDNumber: "86551791",
    RegisteredPopulation: "568",
    VotingStationName: "REITSOSITSE PRIMARY SCHOOL",
    Address: "PO UITKYK  UITKYK VILLAGE  MAFIKENG",
    Latitude: "-26.2087",
    Longitude: "25.5665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19794",
    WardLookupId: "2384",
    VDNumber: "86551825",
    RegisteredPopulation: "370",
    VotingStationName: "MOOIFONTEIN PRIMARY SCHOOL",
    Address: "MOOIFONTEIN  MAFIKENG",
    Latitude: "-26.2541",
    Longitude: "25.6039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19795",
    WardLookupId: "2384",
    VDNumber: "86551836",
    RegisteredPopulation: "634",
    VotingStationName: "GONTSE-MONNAPULA PRIMARY SCHOOL",
    Address: "SEBERIA  MAFIKENG",
    Latitude: "-26.2655",
    Longitude: "25.4603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19796",
    WardLookupId: "2384",
    VDNumber: "86551847",
    RegisteredPopulation: "409",
    VotingStationName: "REAITHUTA PRIMARY SCHOOL",
    Address: "LOMBAARDSLAAGTE  MAFIKENG",
    Latitude: "-26.2906",
    Longitude: "25.5943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19797",
    WardLookupId: "2384",
    VDNumber: "86551869",
    RegisteredPopulation: "535",
    VotingStationName: "TSHEDIMOS PRIMARY SCHOOL",
    Address: "MOOIPAN  MAHIKENG",
    Latitude: "-26.3492",
    Longitude: "25.5161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19798",
    WardLookupId: "2384",
    VDNumber: "86552466",
    RegisteredPopulation: "530",
    VotingStationName: "PHETOGO PRIMARY SCHOOL",
    Address: "ENSELSRUST  MAFIKENG",
    Latitude: "-26.2523",
    Longitude: "25.5471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19799",
    WardLookupId: "2384",
    VDNumber: "86552983",
    RegisteredPopulation: "372",
    VotingStationName: "THUTOMETSI PRIMARY SCHOOL",
    Address: "PO GOEDGEVONDEN  GOEDGEVONDEN VILLAGE  MAFIKENG",
    Latitude: "-26.2221",
    Longitude: "25.5238",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19800",
    WardLookupId: "2384",
    VDNumber: "86553221",
    RegisteredPopulation: "519",
    VotingStationName: "OBAKENG PRIMARY SCHOOL",
    Address: "WELTEVREDE  MAFIKENG",
    Latitude: "-26.3225",
    Longitude: "25.4929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19801",
    WardLookupId: "2385",
    VDNumber: "86551195",
    RegisteredPopulation: "844",
    VotingStationName: "MANNO PRIMARY SCHOOL",
    Address: "PO MAKOUSPAN  MAKOUSPAN VILLAGE  MAFIKENG",
    Latitude: "-26.0986",
    Longitude: "25.622",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19802",
    WardLookupId: "2385",
    VDNumber: "86551724",
    RegisteredPopulation: "380",
    VotingStationName: "DRIE-HOEK CRECHE",
    Address: "DRIEHOEK  MAFIKENG",
    Latitude: "-26.1445",
    Longitude: "25.7231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19803",
    WardLookupId: "2385",
    VDNumber: "86551768",
    RegisteredPopulation: "603",
    VotingStationName: "MAHUBE PRIMARY SCHOOL",
    Address: "NOOITGEDACHT  MAFIKENG",
    Latitude: "-26.1373",
    Longitude: "25.6397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19804",
    WardLookupId: "2385",
    VDNumber: "86551779",
    RegisteredPopulation: "372",
    VotingStationName: "BAPONG CRECHE",
    Address: "RADITHUSO  MAFIKENG",
    Latitude: "-26.2026",
    Longitude: "25.6198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19805",
    WardLookupId: "2385",
    VDNumber: "86551803",
    RegisteredPopulation: "569",
    VotingStationName: "PUDULOGO PRIMARY SCHOOL",
    Address: "BROOKSBY  BROOKSBY VILLAGE  MAFIKENG",
    Latitude: "-26.2495",
    Longitude: "25.6681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19806",
    WardLookupId: "2385",
    VDNumber: "86553232",
    RegisteredPopulation: "447",
    VotingStationName: "MEDUPE PRIMARY SCHOOL",
    Address: "MAFIKENG  ",
    Latitude: "-26.208",
    Longitude: "25.6941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19807",
    WardLookupId: "2385",
    VDNumber: "86553344",
    RegisteredPopulation: "604",
    VotingStationName: "KAGISANO PRIMARY SCHOOL",
    Address: "NAAUWPOORT VILLAGE  MAFIKENG",
    Latitude: "-26.1909",
    Longitude: "25.5735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19808",
    WardLookupId: "2386",
    VDNumber: "86551117",
    RegisteredPopulation: "734",
    VotingStationName: "THELESHO PRIMARY SCHOOL",
    Address: "MADIBE-GA-KUBU  MAFIKENG",
    Latitude: "-25.9979",
    Longitude: "25.4957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19809",
    WardLookupId: "2386",
    VDNumber: "86551162",
    RegisteredPopulation: "805",
    VotingStationName: "SEKOLOPO MIDDLE SCHOOL",
    Address: "MORWASHETLHA  MORWATSHETLHA  MAFIKENG",
    Latitude: "-25.9463",
    Longitude: "25.4708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19810",
    WardLookupId: "2386",
    VDNumber: "86551184",
    RegisteredPopulation: "1076",
    VotingStationName: "SESHUPO PRIMARY SCHOOL",
    Address: "TSHUNYANE  MAFIKENG",
    Latitude: "-26.04",
    Longitude: "25.5253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19811",
    WardLookupId: "2386",
    VDNumber: "86552994",
    RegisteredPopulation: "602",
    VotingStationName: "MANTSA PRIMARY SCHOOL",
    Address: "PO MANTSA  MANTSA VILLAGE  MAFIKENG",
    Latitude: "-26.0688",
    Longitude: "25.5723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19812",
    WardLookupId: "2386",
    VDNumber: "86553355",
    RegisteredPopulation: "385",
    VotingStationName: "LELOKWANE PRIMARY SCHOOL",
    Address: "TSOKWANE VILLAGE  MAFIKENG",
    Latitude: "-26.0067",
    Longitude: "25.5329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19813",
    WardLookupId: "2386",
    VDNumber: "86554301",
    RegisteredPopulation: "497",
    VotingStationName: "TAU PRIMARY SCHOOL",
    Address: "NO STREETS  MADIBE GA TAU VILLAGE  MAFIKENG",
    Latitude: "-26.0022",
    Longitude: "25.4567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19814",
    WardLookupId: "2387",
    VDNumber: "86550037",
    RegisteredPopulation: "756",
    VotingStationName: "MATLHONYANE PRIMARY SCHOOL",
    Address: "MATLHONYANE  MAFIKENG",
    Latitude: "-25.9786",
    Longitude: "25.6946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19815",
    WardLookupId: "2387",
    VDNumber: "86551218",
    RegisteredPopulation: "576",
    VotingStationName: "TIEGOTAWANA SECONDARY SCHOOL",
    Address: "DITHAKONG  DITHAKONG VILLAGE  MAFIKENG",
    Latitude: "-25.9809",
    Longitude: "25.6314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19816",
    WardLookupId: "2387",
    VDNumber: "86552657",
    RegisteredPopulation: "1230",
    VotingStationName: "MOTUBA HIGH SCHOOL",
    Address: "MATSHEPE  MAFIKENG  BAROLONG BO RATSHIDI",
    Latitude: "-26.0213",
    Longitude: "25.7439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19817",
    WardLookupId: "2387",
    VDNumber: "86552668",
    RegisteredPopulation: "341",
    VotingStationName: "BONTLE PRIMARY SCHOOL",
    Address: "SCHOONGEZIGHT VILLAGE  MAFIKENG",
    Latitude: "-26.0477",
    Longitude: "25.711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19818",
    WardLookupId: "2387",
    VDNumber: "86553614",
    RegisteredPopulation: "105",
    VotingStationName: "ODIRILE PRE - SCHOOL",
    Address: "MATILE 2 ( LEHURUTSHI )  LICHTENBURG [LICHTENBURG]",
    Latitude: "-26.0555",
    Longitude: "25.731",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19819",
    WardLookupId: "2387",
    VDNumber: "86590187",
    RegisteredPopulation: "536",
    VotingStationName: "DIHATSHWANE PRIMARY SCHOOL",
    Address: "DIHATSHWANE VILLAGE  MAFIKENG",
    Latitude: "-25.9085",
    Longitude: "25.7548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19820",
    WardLookupId: "2387",
    VDNumber: "86590222",
    RegisteredPopulation: "405",
    VotingStationName: "NGWANA SEJO EARLY LEARNING CENTRE",
    Address: "MOSIANE VIEW  MAFIKENG",
    Latitude: "-25.8909",
    Longitude: "25.6747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19821",
    WardLookupId: "2388",
    VDNumber: "86552512",
    RegisteredPopulation: "3333",
    VotingStationName: "SIGNAL HILL PRIMARY SCHOOL",
    Address: "SIGNAL HILL  TOP VILLAGE  MAHIKENG",
    Latitude: "-25.8196",
    Longitude: "25.6532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19822",
    WardLookupId: "2388",
    VDNumber: "86590200",
    RegisteredPopulation: "675",
    VotingStationName: "METHODIST CHURCH",
    Address: "LONELY PARK  MAFIKENG",
    Latitude: "-25.8252",
    Longitude: "25.6431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19823",
    WardLookupId: "2389",
    VDNumber: "86552534",
    RegisteredPopulation: "2046",
    VotingStationName: "REKOPANE PRIMARY SCHOOL",
    Address: "LONELYPARK  MAFIKENG",
    Latitude: "-25.8385",
    Longitude: "25.6561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19824",
    WardLookupId: "2389",
    VDNumber: "86553366",
    RegisteredPopulation: "1498",
    VotingStationName: "MOLAPISI PRIMARY SCHOOL",
    Address: "SIGNAL HILL  MAFIKENG",
    Latitude: "-25.8496",
    Longitude: "25.663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19825",
    WardLookupId: "2390",
    VDNumber: "86551465",
    RegisteredPopulation: "1342",
    VotingStationName: "WATER OFFICE",
    Address: "PHOLA  MMABATHO",
    Latitude: "-25.8566",
    Longitude: "25.5952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19826",
    WardLookupId: "2390",
    VDNumber: "86551498",
    RegisteredPopulation: "2236",
    VotingStationName: "TETLANO PRIMARY SCHOOL",
    Address: "MOTLHABENG  MMABATHO",
    Latitude: "-25.8509",
    Longitude: "25.6021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19827",
    WardLookupId: "2390",
    VDNumber: "86554312",
    RegisteredPopulation: "530",
    VotingStationName: "THE ALLIANCE CHURCH",
    Address: "MOTLHABENG  MAFIKENG",
    Latitude: "-25.8483",
    Longitude: "25.5936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19828",
    WardLookupId: "2391",
    VDNumber: "86551229",
    RegisteredPopulation: "444",
    VotingStationName: "SENWELO PRIMARY SCHOOL",
    Address: "DITHAKONG WEST  DITHAKONG WEST VILLAGE  MAFIKENG",
    Latitude: "-25.9837",
    Longitude: "25.6018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19829",
    WardLookupId: "2391",
    VDNumber: "86551230",
    RegisteredPopulation: "1814",
    VotingStationName: "SELERI PRIMARY SCHOOL",
    Address: "DITHAKONG EAST VILLAGE  MAFIKENG",
    Latitude: "-25.9732",
    Longitude: "25.6271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19830",
    WardLookupId: "2391",
    VDNumber: "86551241",
    RegisteredPopulation: "710",
    VotingStationName: "PUONUGWE ELC",
    Address: "SETLOPO - MATIKIRING VILLAGE  MAFIKENG",
    Latitude: "-25.9345",
    Longitude: "25.6666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19831",
    WardLookupId: "2391",
    VDNumber: "86553434",
    RegisteredPopulation: "472",
    VotingStationName: "MOKAILA INTERMEDIATE SCHOOL",
    Address: "MADIBE VILLAGE  MADIBE  MAFIKENG",
    Latitude: "-26.019",
    Longitude: "25.579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19832",
    WardLookupId: "2392",
    VDNumber: "86590110",
    RegisteredPopulation: "1326",
    VotingStationName: "SOL PLAATJIE SCHOOL",
    Address: "7752 MAKHENE ROAD  UNIT 6  MMABATHO",
    Latitude: "-25.819",
    Longitude: "25.6233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19833",
    WardLookupId: "2392",
    VDNumber: "86590143",
    RegisteredPopulation: "1828",
    VotingStationName: "ROTARY EARLY LEARNING CENTRE",
    Address: "LONELY PARK  MAFIKENG",
    Latitude: "-25.8367",
    Longitude: "25.6498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19834",
    WardLookupId: "2393",
    VDNumber: "86590165",
    RegisteredPopulation: "1265",
    VotingStationName: "MODIREDI PRIMARY SCHOOL",
    Address: "1965 SELLO THOE STREET  UNIT 8  MMABATHO",
    Latitude: "-25.8425",
    Longitude: "25.5902",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19835",
    WardLookupId: "2393",
    VDNumber: "86590198",
    RegisteredPopulation: "2630",
    VotingStationName: "MOSIAME MILDRED MOGAPI PRIMARY SCHOOL",
    Address: "EXTENSION 39  MMABATHO",
    Latitude: "-25.8383",
    Longitude: "25.5791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19836",
    WardLookupId: "2393",
    VDNumber: "86590211",
    RegisteredPopulation: "607",
    VotingStationName: "MMABATHO HIGH SCHOOL",
    Address: "CUL 20 STEVE BIKO ST  UNIT 2  MMABATHO",
    Latitude: "-25.8321",
    Longitude: "25.5978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19837",
    WardLookupId: "2394",
    VDNumber: "86551375",
    RegisteredPopulation: "1177",
    VotingStationName: "RAMAINE HIGH SCHOOL",
    Address: "PO MAGOGWE  MAGOGWE SHEBELAKWANO  MAFIKENG",
    Latitude: "-25.9089",
    Longitude: "25.6039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19838",
    WardLookupId: "2394",
    VDNumber: "86551421",
    RegisteredPopulation: "2481",
    VotingStationName: "KEOLEBOGILE PRIMARY SCHOOL",
    Address: "MAGOGWE  MAFIKENG",
    Latitude: "-25.8974",
    Longitude: "25.5937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19839",
    WardLookupId: "2395",
    VDNumber: "86551252",
    RegisteredPopulation: "2213",
    VotingStationName: "BAKANG PRIMARY SCHOOL",
    Address: "SETLOPO BOSIGO  MAHIKENG",
    Latitude: "-25.9393",
    Longitude: "25.6619",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19840",
    WardLookupId: "2395",
    VDNumber: "86552961",
    RegisteredPopulation: "659",
    VotingStationName: "AME CHURCH",
    Address: "PO SETLOPO  SETLOPO MOKGWEETSI VILLAGE  MAFIKENG",
    Latitude: "-25.9127",
    Longitude: "25.6516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19841",
    WardLookupId: "2395",
    VDNumber: "86552972",
    RegisteredPopulation: "633",
    VotingStationName: "HOLY CHRISTIAN APOSTOLIC CHURCH OF GOD",
    Address: "MAJEMANTSHO  MAHIKENG",
    Latitude: "-25.918",
    Longitude: "25.6665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19842",
    WardLookupId: "2395",
    VDNumber: "86553030",
    RegisteredPopulation: "807",
    VotingStationName: "THUTONG PRIMARY SCHOOL",
    Address: "SETLOPO  MAFIKENG",
    Latitude: "-25.918",
    Longitude: "25.6309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19843",
    WardLookupId: "2396",
    VDNumber: "86550903",
    RegisteredPopulation: "283",
    VotingStationName: "DUDFIELD PRIMARY SCHOOL",
    Address: "DUDFIELD FIRM  LICHTENBURG",
    Latitude: "-26.1981",
    Longitude: "25.9998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19844",
    WardLookupId: "2396",
    VDNumber: "86554031",
    RegisteredPopulation: "130",
    VotingStationName: "N.G KERK IN AFRICA",
    Address: "DITSOBOTLA  ",
    Latitude: "-26.141",
    Longitude: "26.1189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19845",
    WardLookupId: "2396",
    VDNumber: "86554042",
    RegisteredPopulation: "137",
    VotingStationName: "GOODNEWS CRECHE",
    Address: "DITSOBOTLA  ",
    Latitude: "-26.2274",
    Longitude: "25.9847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19846",
    WardLookupId: "2396",
    VDNumber: "86580131",
    RegisteredPopulation: "1429",
    VotingStationName: "GAETSHO HIGH SCHOOL",
    Address: "89 MAKGALESTREET  BOIKHUTSO  LICHTENBURG",
    Latitude: "-26.1799",
    Longitude: "26.1326",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19847",
    WardLookupId: "2396",
    VDNumber: "86580276",
    RegisteredPopulation: "523",
    VotingStationName: "PHATSIMA PRIMARY SCHOOL",
    Address: "386 MOJAKI  BOIKHUTSO LOCATION  LICHTENBURG [LICHTENBURG]",
    Latitude: "-26.1783",
    Longitude: "26.1376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19848",
    WardLookupId: "2396",
    VDNumber: "86580377",
    RegisteredPopulation: "392",
    VotingStationName: "DITSOBOTLA PRIMARY SCHOOL",
    Address: "00 MAKGALE  BOIKHUTSO   LICHTENBURG",
    Latitude: "-26.1799",
    Longitude: "26.1308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19849",
    WardLookupId: "2397",
    VDNumber: "86580052",
    RegisteredPopulation: "1192",
    VotingStationName: "GEREFORMEERDE KERK",
    Address: "MOJAPELO STREET  BOIKHUTSO  LICHTENBURG",
    Latitude: "-26.181",
    Longitude: "26.1407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19850",
    WardLookupId: "2397",
    VDNumber: "86580063",
    RegisteredPopulation: "1453",
    VotingStationName: "JM LEKGETHA  HIGH SCHOOL",
    Address: "1588 PRETORIUS STREET  BOIKHUTSO  LICHTENBURG",
    Latitude: "-26.1829",
    Longitude: "26.1379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19851",
    WardLookupId: "2397",
    VDNumber: "86580197",
    RegisteredPopulation: "410",
    VotingStationName: "SHURKRAN PRIMARY SCHOOL",
    Address: "STR NAME N/A  SHUKRAN SECTION  DITSOBOTLA",
    Latitude: "-26.1651",
    Longitude: "26.1495",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19852",
    WardLookupId: "2398",
    VDNumber: "86553186",
    RegisteredPopulation: "2160",
    VotingStationName: "THE HOLY CHURCH OF GOD",
    Address: "EXTENTION 02  BOIKHUTSO  LICHTENBURG",
    Latitude: "-26.1865",
    Longitude: "26.1265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19853",
    WardLookupId: "2398",
    VDNumber: "86554334",
    RegisteredPopulation: "1594",
    VotingStationName: "THABO MPEMPE PRIMARY SCHOOL",
    Address: "4934 EXT 3 BOIKHUTSO  BOIKHUTSO  LICHTENBURG",
    Latitude: "-26.1884",
    Longitude: "26.1143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19854",
    WardLookupId: "2399",
    VDNumber: "86580142",
    RegisteredPopulation: "1474",
    VotingStationName: "NG KERK BLYDEVILLE",
    Address: "ORNAMENT  BLYDEVILLE  LICHTENBURG",
    Latitude: "-26.178",
    Longitude: "26.1602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19855",
    WardLookupId: "2399",
    VDNumber: "86580300",
    RegisteredPopulation: "858",
    VotingStationName: "BLYDEVILLE MIDDELBARE SKOOL",
    Address: "1516 TEACOCK  BLYDEVILLE LOCATION  LICHTENBURG [LICHTENBURG]",
    Latitude: "-26.1756",
    Longitude: "26.1575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19856",
    WardLookupId: "2399",
    VDNumber: "86580322",
    RegisteredPopulation: "1248",
    VotingStationName: "GENERAL APOSTOLIC CHURCH IN SA",
    Address: "570 BLOSSOM STREET BLYDEVILLE  DITSOBOTLA   LICHTENBURG",
    Latitude: "-26.1825",
    Longitude: "26.155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19857",
    WardLookupId: "2400",
    VDNumber: "86550868",
    RegisteredPopulation: "247",
    VotingStationName: "MADUTU LUTHEREN CHURCH",
    Address: "MADUTU FARM  DITSOBOTLA  COLIGNY",
    Latitude: "-26.3462",
    Longitude: "26.211",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19858",
    WardLookupId: "2400",
    VDNumber: "86550914",
    RegisteredPopulation: "399",
    VotingStationName: "RAPOO PRIMARY SCHOOL",
    Address: "(NW384 - DITSOBOTLA [LICHTENBURG])   ",
    Latitude: "-26.2426",
    Longitude: "26.0818",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19859",
    WardLookupId: "2400",
    VDNumber: "86552578",
    RegisteredPopulation: "434",
    VotingStationName: "PLATVLEI PRIMARY SCHOOL",
    Address: "PLATVLEI FARM  LICHTENBURG",
    Latitude: "-26.2699",
    Longitude: "26.2107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19860",
    WardLookupId: "2400",
    VDNumber: "86552792",
    RegisteredPopulation: "492",
    VotingStationName: "TIRELO PRIMARY SCHOOL",
    Address: "VAALBANK  DITSOBOTLA",
    Latitude: "-26.4496",
    Longitude: "26.191",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19861",
    WardLookupId: "2400",
    VDNumber: "86552804",
    RegisteredPopulation: "230",
    VotingStationName: "NAGANASENTLE PRIMARY SCHOOL",
    Address: "STR NAME N/A  GOEDGEDAGHT FARM  DITSOBOTLA",
    Latitude: "-26.5001",
    Longitude: "26.3142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19862",
    WardLookupId: "2400",
    VDNumber: "86580164",
    RegisteredPopulation: "994",
    VotingStationName: "PENTECOSTAL HOLLINESS KINGDOM ASSEMBLY CHURCH",
    Address: "EXTENTION  03  BLYDEVILLEI  LICHTENBURG",
    Latitude: "-26.1883",
    Longitude: "26.1583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19863",
    WardLookupId: "2400",
    VDNumber: "86580311",
    RegisteredPopulation: "959",
    VotingStationName: "MK RAMPINE PRIMARY SCHOOL",
    Address: "2435 JACARANDA STREET  BLYDEVILLE  LICHTENBURG",
    Latitude: "-26.1894",
    Longitude: "26.1579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19864",
    WardLookupId: "2401",
    VDNumber: "86553388",
    RegisteredPopulation: "393",
    VotingStationName: "DOORNHOEK PRIMARY SCHOOL",
    Address: "STR NAME N/A  DOORENHOEK  DITSOBOTLA",
    Latitude: "-26.1802",
    Longitude: "26.3164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19865",
    WardLookupId: "2401",
    VDNumber: "86580029",
    RegisteredPopulation: "2167",
    VotingStationName: "TENT AT SONOP HALL",
    Address: "HENDRIK POTGEITER  KIESRVILLE  LICHTENBURG",
    Latitude: "-26.1669",
    Longitude: "26.173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19866",
    WardLookupId: "2401",
    VDNumber: "86580366",
    RegisteredPopulation: "1401",
    VotingStationName: "YUSUF DADOO PRIMARY SCHOOL",
    Address: "1 ALFA STREET KIESERVILLE  DITSOBOTLA  LICHTENBURG",
    Latitude: "-26.1723",
    Longitude: "26.1805",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19867",
    WardLookupId: "2402",
    VDNumber: "86550420",
    RegisteredPopulation: "557",
    VotingStationName: "MANANA PRIMARY SCHOOL",
    Address: "PO BOX 478   LICHTENBURG  DITSOBOTLA",
    Latitude: "-26.1045",
    Longitude: "26.2193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19868",
    WardLookupId: "2402",
    VDNumber: "86580018",
    RegisteredPopulation: "1447",
    VotingStationName: "LICHTENBURG TOWN HALL",
    Address: "MELLVILL STREET  LICHTENBURG  LICHTENBURG",
    Latitude: "-26.1502",
    Longitude: "26.1593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19869",
    WardLookupId: "2402",
    VDNumber: "86580209",
    RegisteredPopulation: "168",
    VotingStationName: "LAFARGE HALL",
    Address: "LICHTENBURG  ",
    Latitude: "-26.1404",
    Longitude: "26.1783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19870",
    WardLookupId: "2402",
    VDNumber: "86580287",
    RegisteredPopulation: "598",
    VotingStationName: "LICHTENBURG AFRIKAANSE LAERSKOOL",
    Address: "GREEFF  LICHTENBURG TOWN  LICHTENBURG [LICHTENBURG]",
    Latitude: "-26.1519",
    Longitude: "26.165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19871",
    WardLookupId: "2402",
    VDNumber: "86580298",
    RegisteredPopulation: "941",
    VotingStationName: "LICHTENBURG ENGLISH PRIMARY SCHOOL",
    Address: "WOUTER STREET  LICHTENBURG  LICHTENBURG [LICHTENBURG]",
    Latitude: "-26.14",
    Longitude: "26.1626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19872",
    WardLookupId: "2403",
    VDNumber: "86550947",
    RegisteredPopulation: "131",
    VotingStationName: "MODISAKGOMO FARM SCHOOL",
    Address: "MODISAKGOMO FARM  ITSOSENG  LICHTENBURG",
    Latitude: "-26.1652",
    Longitude: "25.887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19873",
    WardLookupId: "2403",
    VDNumber: "86580030",
    RegisteredPopulation: "1175",
    VotingStationName: "DINGAKE PRIMARY SCHOOL",
    Address: "STR NAME N/A  VERDWAAL 1  LICHTENBURG",
    Latitude: "-26.086",
    Longitude: "25.9054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19874",
    WardLookupId: "2403",
    VDNumber: "86580153",
    RegisteredPopulation: "1203",
    VotingStationName: "DIRELANG PRE - SCHOOL",
    Address: "NO STREET NAME AVAIL  VERDWAAL  LICHTENBURG",
    Latitude: "-26.1092",
    Longitude: "25.892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19875",
    WardLookupId: "2403",
    VDNumber: "86580221",
    RegisteredPopulation: "978",
    VotingStationName: "THUTO KE LESEDI PRE-SCHOOL",
    Address: "NO STR NAME AVAILABLE  VERDWAAL 2  LICHTENBURG",
    Latitude: "-26.1011",
    Longitude: "25.8843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19876",
    WardLookupId: "2404",
    VDNumber: "86580074",
    RegisteredPopulation: "1095",
    VotingStationName: "BAITSHOKI HIGH SCHOOL",
    Address: "2548 TEKETESTREETE  ITSOSENG  LICHTENBURG",
    Latitude: "-26.0954",
    Longitude: "25.8699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19877",
    WardLookupId: "2404",
    VDNumber: "86580175",
    RegisteredPopulation: "889",
    VotingStationName: "ITSOSENG MUNICIPAL OFFICES",
    Address: "ITSOSENG  DITSOBOTLA  ITSOSENG",
    Latitude: "-26.0864",
    Longitude: "25.8843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19878",
    WardLookupId: "2404",
    VDNumber: "86580232",
    RegisteredPopulation: "486",
    VotingStationName: "YOUTH CENTRE",
    Address: "DITSOBOTLA [LICHTENBURG]  ",
    Latitude: "-26.0941",
    Longitude: "25.8765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19879",
    WardLookupId: "2404",
    VDNumber: "86580254",
    RegisteredPopulation: "851",
    VotingStationName: "MOHLATSWA PRIMARY SCHOOL",
    Address: "4325 ITSOSENG ZONE 3  DITSOBOTLA",
    Latitude: "-26.0923",
    Longitude: "25.8815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19880",
    WardLookupId: "2404",
    VDNumber: "86580333",
    RegisteredPopulation: "394",
    VotingStationName: "ITSOSENG HEALTH CENTRE",
    Address: "(NW384 - DITSOBOTLA [LICHTENBURG])   ",
    Latitude: "-26.0896",
    Longitude: "25.869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19881",
    WardLookupId: "2404",
    VDNumber: "86580344",
    RegisteredPopulation: "281",
    VotingStationName: "LORE PRIMARY SCHOOL",
    Address: "2856 ZONE 03  ITSOSENG   LICHTENBURG",
    Latitude: "-26.087",
    Longitude: "25.8861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19882",
    WardLookupId: "2405",
    VDNumber: "86580085",
    RegisteredPopulation: "1185",
    VotingStationName: "BOITEKO PRIMARY SCHOOL",
    Address: "ZONE 03  ITSOSENG   LICHTENBURG",
    Latitude: "-26.0874",
    Longitude: "25.8732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19883",
    WardLookupId: "2405",
    VDNumber: "86580108",
    RegisteredPopulation: "598",
    VotingStationName: "HANDY CRAFT CENTRE",
    Address: "1177 MAROPEFELASTREET  ITSOSENG  LICHTENBURG",
    Latitude: "-26.0771",
    Longitude: "25.8794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19884",
    WardLookupId: "2405",
    VDNumber: "86580120",
    RegisteredPopulation: "738",
    VotingStationName: "AME CHURCH ITSOSENG",
    Address: "150/1 DL MOKALE STREET  ITSOSENG  LICHTENBURG",
    Latitude: "-26.0714",
    Longitude: "25.8806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19885",
    WardLookupId: "2405",
    VDNumber: "86580186",
    RegisteredPopulation: "756",
    VotingStationName: "REGOLOTSWE HIGH SCHOOL",
    Address: "STR NAME N/A  ITSOSENG  LICHTENBURG",
    Latitude: "-26.0811",
    Longitude: "25.8762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19886",
    WardLookupId: "2405",
    VDNumber: "86580355",
    RegisteredPopulation: "226",
    VotingStationName: "METHODIST CHURCH ITSOSENG",
    Address: "(NW384 - DITSOBOTLA [LICHTENBURG])   ",
    Latitude: "-26.0769",
    Longitude: "25.8839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19887",
    WardLookupId: "2406",
    VDNumber: "86580041",
    RegisteredPopulation: "840",
    VotingStationName: "SHEILA MULTIPURPOSE HALL",
    Address: "SHEILA VILLAGE  SHEILA  LICHTENBURG",
    Latitude: "-26.0576",
    Longitude: "25.9349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19888",
    WardLookupId: "2406",
    VDNumber: "86580119",
    RegisteredPopulation: "923",
    VotingStationName: "TSWELELOPELE HIGH SCHOOL",
    Address: "1170 ZONE 3 ITSOSENG 2744  ITSOSENG ZONE 3  DITSOBOTLA",
    Latitude: "-26.0802",
    Longitude: "25.8846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19889",
    WardLookupId: "2406",
    VDNumber: "86580210",
    RegisteredPopulation: "1252",
    VotingStationName: "PONELE-PELE PRIMARY SCHOOL",
    Address:
      "STR NAME N/A                                       ITSOSENG  LICHTENBURG",
    Latitude: "-26.079",
    Longitude: "25.8932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19890",
    WardLookupId: "2406",
    VDNumber: "86580265",
    RegisteredPopulation: "855",
    VotingStationName: "ASSEMBLIES OF GOD",
    Address: "UNIT 3  ITSOSENG   LICHTENBURG [LICHTENBURG]",
    Latitude: "-26.0753",
    Longitude: "25.8873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19891",
    WardLookupId: "2407",
    VDNumber: "86552602",
    RegisteredPopulation: "1056",
    VotingStationName: "MAKGWE PRIMARY SCHOOL",
    Address: "STR NAME N/A  BODIBE  LICHTENBURG",
    Latitude: "-26.0509",
    Longitude: "25.8533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19892",
    WardLookupId: "2407",
    VDNumber: "86552624",
    RegisteredPopulation: "1247",
    VotingStationName: "BODIBE TRIBAL OFFICES",
    Address: "BODIBE  LICHTENBURG",
    Latitude: "-26.0605",
    Longitude: "25.8607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19893",
    WardLookupId: "2407",
    VDNumber: "86553939",
    RegisteredPopulation: "313",
    VotingStationName: "EL ELYON MINISTRIES",
    Address: "BODIBE VILLAGE  DITSOBOTLA  LICHTENBURG",
    Latitude: "-26.0575",
    Longitude: "25.8589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19894",
    WardLookupId: "2407",
    VDNumber: "86554356",
    RegisteredPopulation: "365",
    VotingStationName: "AME CHURCH BODIBE",
    Address: "NO STREET NAME  BODIBE  RAKGOLO",
    Latitude: "-26.0652",
    Longitude: "25.8638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19895",
    WardLookupId: "2408",
    VDNumber: "86552613",
    RegisteredPopulation: "1328",
    VotingStationName: "METHODIST CHURCH BODIBE",
    Address: "NO STR NAME AVAILABLE  BODIBE VILLAGE  DITSOBOTLA",
    Latitude: "-26.0625",
    Longitude: "25.8418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19896",
    WardLookupId: "2408",
    VDNumber: "86553210",
    RegisteredPopulation: "843",
    VotingStationName: "ST JOHN CHURCH",
    Address: "BODIBE ( PHATSIMA)  DITSOBOTLA",
    Latitude: "-26.0526",
    Longitude: "25.8174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19897",
    WardLookupId: "2408",
    VDNumber: "86553377",
    RegisteredPopulation: "281",
    VotingStationName: "BAFUNDI DISCIPLE CHURCH",
    Address: "71103 RAKGOLO SECTION  DITSOBOTLA  BODIBE VILLAGE",
    Latitude: "-26.0905",
    Longitude: "25.8638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19898",
    WardLookupId: "2408",
    VDNumber: "86553647",
    RegisteredPopulation: "752",
    VotingStationName: "ST JUDAS CHURCH",
    Address: "BODIBE VILLAGE  DITSOBOTLA  BODIBE",
    Latitude: "-26.0758",
    Longitude: "25.8546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19899",
    WardLookupId: "2409",
    VDNumber: "86552646",
    RegisteredPopulation: "1255",
    VotingStationName: "E.H. MOGASE PRIMARY SCHOOL",
    Address: "DITLHWANENG SECTION  BODIBE  LICHTENBURG",
    Latitude: "-26.0479",
    Longitude: "25.8329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19900",
    WardLookupId: "2409",
    VDNumber: "86552815",
    RegisteredPopulation: "604",
    VotingStationName: "MIKE`S SUPERMARKET",
    Address: "BODIBE  DITHWANENG",
    Latitude: "-26.0437",
    Longitude: "25.8418",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19901",
    WardLookupId: "2409",
    VDNumber: "86553209",
    RegisteredPopulation: "592",
    VotingStationName: "MMUAGABO PRIMARY SCHOOL",
    Address: "STR NAME N/A  BODIBE  LICHTENBURG",
    Latitude: "-26.0469",
    Longitude: "25.812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19902",
    WardLookupId: "2409",
    VDNumber: "86553636",
    RegisteredPopulation: "306",
    VotingStationName: "NG KERK BODIBE",
    Address: "BODIBE (TAXI RANK)  LICHTENBURG [LICHTENBURG]",
    Latitude: "-26.0599",
    Longitude: "25.8561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19903",
    WardLookupId: "2409",
    VDNumber: "86554345",
    RegisteredPopulation: "363",
    VotingStationName: "MOKAKANA PRIMARY SCHOOL",
    Address: "BODIBE  LICHTENBURG",
    Latitude: "-26.0673",
    Longitude: "25.8472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19904",
    WardLookupId: "2409",
    VDNumber: "86554367",
    RegisteredPopulation: "125",
    VotingStationName: "MALOKA GENERAL DEALER",
    Address: "BODIBE  DITHWANENG",
    Latitude: "-26.0362",
    Longitude: "25.8481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19905",
    WardLookupId: "2410",
    VDNumber: "86560016",
    RegisteredPopulation: "1494",
    VotingStationName: "NG KERK TLHABOLOGANG",
    Address: "957 MCMILLIAN  TLHABOLOGANG   COLIGNY",
    Latitude: "-26.3309",
    Longitude: "26.2993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19906",
    WardLookupId: "2410",
    VDNumber: "86560027",
    RegisteredPopulation: "1308",
    VotingStationName: "COLIGNY HOÃ‹RSKOOL",
    Address: "28 ATKINSON   COLIGNY  COLIGNY",
    Latitude: "-26.3365",
    Longitude: "26.3132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19907",
    WardLookupId: "2410",
    VDNumber: "86560049",
    RegisteredPopulation: "1174",
    VotingStationName: "EXTENTION 06 SHOP",
    Address: "1011 TLHABOLOGANG  COLIGNY",
    Latitude: "-26.3302",
    Longitude: "26.3103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19908",
    WardLookupId: "2411",
    VDNumber: "86550363",
    RegisteredPopulation: "283",
    VotingStationName: "SOMMERPLAAS WINKEL",
    Address: "GROOTFONTEIN FARM  DITSOBOTLA  LICHTENBURG",
    Latitude: "-25.7919",
    Longitude: "26.3834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19909",
    WardLookupId: "2411",
    VDNumber: "86550385",
    RegisteredPopulation: "541",
    VotingStationName: "CARLISONIA PRIMARY SCHOOL",
    Address: "NO STR NAME N/A  WELVERDIEN  LICHTENBURG",
    Latitude: "-25.9766",
    Longitude: "26.1859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19910",
    WardLookupId: "2411",
    VDNumber: "86550408",
    RegisteredPopulation: "1150",
    VotingStationName: "TREASURE TROVE PRIMARY SCHOOL",
    Address: "NO STR NAME N/A  BAKERVILLE  LICHTENBURG",
    Latitude: "-25.984",
    Longitude: "26.0816",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19911",
    WardLookupId: "2411",
    VDNumber: "86550431",
    RegisteredPopulation: "716",
    VotingStationName: "GRASFONTEIN COMMUNITY HALL",
    Address: "NO GRASFONTEIN VILLAGE  LICHTENBURG",
    Latitude: "-25.9955",
    Longitude: "26.0479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19912",
    WardLookupId: "2411",
    VDNumber: "86550723",
    RegisteredPopulation: "280",
    VotingStationName: "BALUBANK PRIMARY SCHOOL.",
    Address: "100 PLOT 22  BLAUBANK FARM  LICHTENBURG",
    Latitude: "-26.0064",
    Longitude: "25.9139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19913",
    WardLookupId: "2411",
    VDNumber: "86553603",
    RegisteredPopulation: "109",
    VotingStationName: "TLAMELO PRIMARY SCHOOL",
    Address: "STR NAME N/A  MOLAMU  LICHTENBURG [LICHTENBURG]",
    Latitude: "-25.9183",
    Longitude: "25.8697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19914",
    WardLookupId: "2411",
    VDNumber: "86553917",
    RegisteredPopulation: "134",
    VotingStationName: "MALOGAGA WINKEL /SHOP",
    Address: "RIETVLEI FARM  DITSOBOTLA  LICHTENBURG",
    Latitude: "-25.6997",
    Longitude: "26.1827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19915",
    WardLookupId: "2411",
    VDNumber: "86580243",
    RegisteredPopulation: "208",
    VotingStationName: "GREEFSLAAGTES FARM (TENT)",
    Address: "GREEFSLAAGTE FARM  DITSOBOTLA  LICHTENBURG",
    Latitude: "-26.089",
    Longitude: "26.0186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19916",
    WardLookupId: "2412",
    VDNumber: "86550396",
    RegisteredPopulation: "243",
    VotingStationName: "VERENIGDE GEREFORMEERDE KERK",
    Address: "NO RIETPAN/VLAKPAN  LICHTENBURG",
    Latitude: "-25.995",
    Longitude: "26.5051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19917",
    WardLookupId: "2412",
    VDNumber: "86550419",
    RegisteredPopulation: "733",
    VotingStationName: "GAMOTLATLA COMMUNITY HALL",
    Address: "GAMOTLATLA  LICHTENBURG  BAKOLOBENG",
    Latitude: "-26.0722",
    Longitude: "26.5806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19918",
    WardLookupId: "2412",
    VDNumber: "86550442",
    RegisteredPopulation: "124",
    VotingStationName: "UNITING REFORMED CHURCH",
    Address: "TWEEBUFFELS FARM  DITSOBOTLA  COLIGNY",
    Latitude: "-26.1541",
    Longitude: "26.4633",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19919",
    WardLookupId: "2412",
    VDNumber: "86550453",
    RegisteredPopulation: "210",
    VotingStationName: "PHOLOSO PRIMARY SCHOOL",
    Address: "STR NAME N/A  BULTFONTEIN  COLIGNY",
    Latitude: "-26.2315",
    Longitude: "26.3836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19920",
    WardLookupId: "2412",
    VDNumber: "86550846",
    RegisteredPopulation: "248",
    VotingStationName: "BETHEL-MONAMALADI PRIMARY SCHOOL",
    Address: "STR NAME N/A  HOLGAT  COLIGNY",
    Latitude: "-26.2917",
    Longitude: "26.4581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19921",
    WardLookupId: "2412",
    VDNumber: "86550879",
    RegisteredPopulation: "179",
    VotingStationName: "KOGODIMO PRIMARY SCHOOL",
    Address: "VAALBANK FARM  COLIGNY  VAALBANK",
    Latitude: "-26.4173",
    Longitude: "26.4943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19922",
    WardLookupId: "2412",
    VDNumber: "86553399",
    RegisteredPopulation: "171",
    VotingStationName: "KONOPO TRIBAL OFFICE",
    Address: "KONOPO VILLAGE  LICHTENBURG",
    Latitude: "-26.333",
    Longitude: "26.4097",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19923",
    WardLookupId: "2412",
    VDNumber: "86553625",
    RegisteredPopulation: "88",
    VotingStationName: "NG KERK",
    Address: "SWARTRAND  LICHTENBURG [LICHTENBURG]",
    Latitude: "-26.0399",
    Longitude: "26.6135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19924",
    WardLookupId: "2412",
    VDNumber: "86553692",
    RegisteredPopulation: "376",
    VotingStationName: "BATLOUNG CPA",
    Address: "BOTSHABELO  LICHTENBURG",
    Latitude: "-26.2346",
    Longitude: "26.4406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19925",
    WardLookupId: "2412",
    VDNumber: "86554378",
    RegisteredPopulation: "527",
    VotingStationName: "SCOTLAND EXTENTION 8 TENT",
    Address: "EXTENTION 08  TLHABOBOLOGANG LOCATION   COLIGNY",
    Latitude: "-26.3111",
    Longitude: "26.2952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19926",
    WardLookupId: "2412",
    VDNumber: "86990709",
    RegisteredPopulation: "298",
    VotingStationName: "VLAKPAN LAERSKOOL",
    Address: "STR NAME N/A  GROOTPAN FARM  LICHTENBURG",
    Latitude: "-25.9703",
    Longitude: "26.5453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19927",
    WardLookupId: "2412",
    VDNumber: "86992419",
    RegisteredPopulation: "130",
    VotingStationName: "RUTA-BANA PRIMARY SCHOOL",
    Address: "TAFELKOP  DITSOBOTLA",
    Latitude: "-25.9675",
    Longitude: "26.7143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19928",
    WardLookupId: "2412",
    VDNumber: "86992598",
    RegisteredPopulation: "174",
    VotingStationName: "LIEBENBERG SLATE",
    Address: "(NW384 - DITSOBOTLA [LICHTENBURG])   ",
    Latitude: "-25.8291",
    Longitude: "26.61",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19929",
    WardLookupId: "2413",
    VDNumber: "86553658",
    RegisteredPopulation: "1424",
    VotingStationName: "REBONEILWE SECONDARY SCHOOL",
    Address: "2135 TINA MOREMEDI STREET  TLHABOLOGANG  DITSOBOTLA",
    Latitude: "-26.3257",
    Longitude: "26.2945",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19930",
    WardLookupId: "2413",
    VDNumber: "86553838",
    RegisteredPopulation: "514",
    VotingStationName: "BOITSHEPO CRECHE",
    Address: "1784 EXT 5 COLIGNY   DITSOBOTLA  COLIGNY",
    Latitude: "-26.3223",
    Longitude: "26.2949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19931",
    WardLookupId: "2413",
    VDNumber: "86560038",
    RegisteredPopulation: "1459",
    VotingStationName: "MORE HIGH SCHOOL",
    Address: "720 MOSINO STREET  TLHABOLOGANG  COLIGNY",
    Latitude: "-26.3276",
    Longitude: "26.3016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19932",
    WardLookupId: "2414",
    VDNumber: "86550925",
    RegisteredPopulation: "556",
    VotingStationName: "RRAPOO PRIMARY SCHOOL.",
    Address: "10080 MATILE  LICHTENBURG",
    Latitude: "-26.0813",
    Longitude: "25.7575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19933",
    WardLookupId: "2414",
    VDNumber: "86550936",
    RegisteredPopulation: "996",
    VotingStationName: "METHODIST CHURCH OF SA",
    Address: "SPRINGBOKPAN  LICHTENBURG",
    Latitude: "-26.1206",
    Longitude: "25.7761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19934",
    WardLookupId: "2414",
    VDNumber: "86550958",
    RegisteredPopulation: "131",
    VotingStationName: "ZOETMELKSVALLEI SKOOL",
    Address: "885 KHUDU STREET  DITSOBOTLA  LICHTENBURG",
    Latitude: "-26.2085",
    Longitude: "25.8888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19935",
    WardLookupId: "2414",
    VDNumber: "86552590",
    RegisteredPopulation: "488",
    VotingStationName: "MOTSWAISO PRIMARY SCHOOL",
    Address: "STR NAME N/A  MEETMEKAAR  LICHTENBURG",
    Latitude: "-26.0444",
    Longitude: "25.7566",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19936",
    WardLookupId: "2414",
    VDNumber: "86552635",
    RegisteredPopulation: "820",
    VotingStationName: "MOTLHAKU PRIMARY SCHOOL",
    Address: "STR NAME N/A  BODIBE VILLAGE  LICHTENBURG",
    Latitude: "-26.0476",
    Longitude: "25.8014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19937",
    WardLookupId: "2414",
    VDNumber: "86553669",
    RegisteredPopulation: "264",
    VotingStationName: "TWELVE APOSTOLIC CHURCH",
    Address: "MATILE VILLAGE   LICHTEBURG   BANOGENG TRIBAL",
    Latitude: "-26.0787",
    Longitude: "25.7644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19938",
    WardLookupId: "2415",
    VDNumber: "86550970",
    RegisteredPopulation: "464",
    VotingStationName: "GAMALOKA TRIBAL OFFICE",
    Address: "GAMALOKA VILLAGE  DITSOBOTLA   GAMALOKA VILLAGE",
    Latitude: "-26.4249",
    Longitude: "26.0971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19939",
    WardLookupId: "2415",
    VDNumber: "86553827",
    RegisteredPopulation: "202",
    VotingStationName: "SYFERFONTEIN HALL",
    Address: "(NW384 - DITSOBOTLA [LICHTENBURG])   ",
    Latitude: "-26.4076",
    Longitude: "26.1495",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19940",
    WardLookupId: "2415",
    VDNumber: "86630014",
    RegisteredPopulation: "1641",
    VotingStationName: "KGOKE LESABE HIGH SCHOOL",
    Address: "10351 BISSIESVLEI   ",
    Latitude: "-26.362",
    Longitude: "25.9083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19941",
    WardLookupId: "2415",
    VDNumber: "86630025",
    RegisteredPopulation: "1304",
    VotingStationName: "METHODIST CHURCH BIESIESVLEI",
    Address: "(NW384 - DITSOBOTLA [LICHTENBURG])   ",
    Latitude: "-26.3621",
    Longitude: "25.9057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19942",
    WardLookupId: "2416",
    VDNumber: "86550026",
    RegisteredPopulation: "467",
    VotingStationName: "LEKGOPHUNG PRIMARY SCHOOL",
    Address: "00 LEKGOPHUNG VILLAGE  ZEERUST  BALETE BA GA MOKGOJWA",
    Latitude: "-24.8062",
    Longitude: "26.0107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19943",
    WardLookupId: "2416",
    VDNumber: "86550138",
    RegisteredPopulation: "451",
    VotingStationName: "MMASEBUDULE PRIMARY SCHOOL",
    Address: "KGETLENG SECTION  MMASEBUDULE VILLAGE  ZEERUST",
    Latitude: "-25.041",
    Longitude: "26.3021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19944",
    WardLookupId: "2416",
    VDNumber: "86550464",
    RegisteredPopulation: "549",
    VotingStationName: "ZAKHELENI PRIMARY SCHOOL",
    Address: "SWARTKOPFONTEIN  ZEERUST  AMACINA KA THAMBE",
    Latitude: "-24.9092",
    Longitude: "25.919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19945",
    WardLookupId: "2416",
    VDNumber: "86552499",
    RegisteredPopulation: "598",
    VotingStationName: "SUPINGSTAD TRIBAL OFFICE",
    Address: "NEXT TO SUPINGSTAD POST OFFICE  SUPINGSTAD VILLAGE  ZEERUST",
    Latitude: "-24.7926",
    Longitude: "26.0527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19946",
    WardLookupId: "2416",
    VDNumber: "86553052",
    RegisteredPopulation: "696",
    VotingStationName: "MATTHEWS MANGOPE HIGH SCHOOL",
    Address: "SUPINGSTAD  ZEERUST  BAHURUTSHE BA GA SUPING",
    Latitude: "-24.7864",
    Longitude: "26.0823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19947",
    WardLookupId: "2416",
    VDNumber: "86553715",
    RegisteredPopulation: "428",
    VotingStationName: "LEKGOPHU LUTHERAN CHURCH",
    Address: "LEKGOPHUNG  ZEERUST  BALETE BA GA MOKGOJWA",
    Latitude: "-24.8033",
    Longitude: "26.0196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19948",
    WardLookupId: "2416",
    VDNumber: "86554053",
    RegisteredPopulation: "277",
    VotingStationName: "ST. ANTHONY CATHOLIC CHURCH",
    Address: "SUPINGSTAD  ZEERUST  BAHURUTSHE BA GA SUPING TRIBAL AUTHORITY",
    Latitude: "-24.7945",
    Longitude: "26.0581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19949",
    WardLookupId: "2417",
    VDNumber: "86550475",
    RegisteredPopulation: "679",
    VotingStationName: "KELERAYAMANG PRIMARY SCHOOL",
    Address: "SIKWANE SECTION, MOSHANA   ZEERUST  BAHURUTSHE BA GA LENCOE",
    Latitude: "-24.9863",
    Longitude: "25.9045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19950",
    WardLookupId: "2417",
    VDNumber: "86550486",
    RegisteredPopulation: "758",
    VotingStationName: "LIVING ROCK MINISTRIES CHURCH",
    Address: "MOSHANA VILLAGE  MOSHANA  BAHURUTSHE BA GA LENCOE",
    Latitude: "-24.9674",
    Longitude: "25.8928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19951",
    WardLookupId: "2417",
    VDNumber: "86550497",
    RegisteredPopulation: "728",
    VotingStationName: "SAMENYANE PRIMARY SCHOOL",
    Address: "LESETLHA SECTION, MOSHANA  ZEERUST  BAHURUTSHE BA GA LENCOE",
    Latitude: "-24.9697",
    Longitude: "25.8762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19952",
    WardLookupId: "2417",
    VDNumber: "86550510",
    RegisteredPopulation: "682",
    VotingStationName: "TRIBAL OFFICE WITKLEIGAT",
    Address: "KGOSING SECTION, MOSHANA  ZEERUST  BAHURUTSHE BA GA LENCOE",
    Latitude: "-24.9743",
    Longitude: "25.8883",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19953",
    WardLookupId: "2417",
    VDNumber: "86553726",
    RegisteredPopulation: "524",
    VotingStationName: "NTATAISE EARLY LEARNING CENTRE",
    Address: "DITAMPANA SEC, MOSHANA VILLAGE  ZEERUST  ZEERUST",
    Latitude: "-24.9741",
    Longitude: "25.8698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19954",
    WardLookupId: "2418",
    VDNumber: "86550509",
    RegisteredPopulation: "595",
    VotingStationName: "MOSHANA PRIMARY SCHOOL",
    Address: "MADIBANENG SECTION  MOSHANA  ZEERUST",
    Latitude: "-24.9833",
    Longitude: "25.8874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19955",
    WardLookupId: "2418",
    VDNumber: "86550521",
    RegisteredPopulation: "934",
    VotingStationName: "LETLHAKANE PRIMARY SCHOOL",
    Address: "STREET NAME NOT AVAILABLE  RIETPAN VILLAGE  ZEERUST",
    Latitude: "-25.1131",
    Longitude: "25.9469",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19956",
    WardLookupId: "2418",
    VDNumber: "86550532",
    RegisteredPopulation: "727",
    VotingStationName: "RABOTSILE PRIMARY SCHOOL",
    Address: "DRIEFONTEIN VILLAGE  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.1418",
    Longitude: "25.9663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19957",
    WardLookupId: "2418",
    VDNumber: "86550543",
    RegisteredPopulation: "885",
    VotingStationName: "ITEBATEBELE ABET CENTER",
    Address: "SEBAKO SECTION  DRIEFONTEIN  ZEERUST",
    Latitude: "-25.144",
    Longitude: "25.9565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19958",
    WardLookupId: "2418",
    VDNumber: "86553704",
    RegisteredPopulation: "361",
    VotingStationName: "MMAJANE HIGH SCHOOL",
    Address: "(NW385 - RAMOTSHERE MOILOA [ZEERUST])   ",
    Latitude: "-25.1275",
    Longitude: "25.9616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19959",
    WardLookupId: "2418",
    VDNumber: "86554064",
    RegisteredPopulation: "232",
    VotingStationName: "MOKGOLA PRIMARY SCHOOL",
    Address: "DRIEFONTEIN VILLAGE  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.1424",
    Longitude: "25.9737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19960",
    WardLookupId: "2418",
    VDNumber: "86554176",
    RegisteredPopulation: "205",
    VotingStationName: "TSWELEDISO EARLY LEARNING CENTRE",
    Address:
      "GOORA MARUMO SECTION, LOBATLA   ZEEERUST  BAHURUTSHE BA GA GOPANE",
    Latitude: "-25.1872",
    Longitude: "25.8494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19961",
    WardLookupId: "2419",
    VDNumber: "86550565",
    RegisteredPopulation: "767",
    VotingStationName: "MAREKWA PRIMARY",
    Address: "MOTSWEDI  ZEERUST  BAHURUTSHE BOO MANYANA",
    Latitude: "-25.2772",
    Longitude: "25.8856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19962",
    WardLookupId: "2419",
    VDNumber: "86550576",
    RegisteredPopulation: "617",
    VotingStationName: "MOTSWEDI HIGH SCHOOL",
    Address: "MOTSEDI/GOPANE ROAD  MOTSWEDI  ZEERUST",
    Latitude: "-25.2863",
    Longitude: "25.8767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19963",
    WardLookupId: "2419",
    VDNumber: "86550587",
    RegisteredPopulation: "1029",
    VotingStationName: "BORAKALALO PRIMARY SCHOOL",
    Address: "STREET NAME NOT AVAILABLE  BORAKALALO  ZEERUST",
    Latitude: "-25.2853",
    Longitude: "25.9142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19964",
    WardLookupId: "2419",
    VDNumber: "86550598",
    RegisteredPopulation: "561",
    VotingStationName: "MENWE PRIMARY SCHOOL",
    Address: "BORAKALALO VILLAGE  ZEERUST  BAHURUTSHE BA GA GOPANE",
    Latitude: "-25.2816",
    Longitude: "25.9025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19965",
    WardLookupId: "2419",
    VDNumber: "86553467",
    RegisteredPopulation: "501",
    VotingStationName: "TLHAOLE HIGH SCHOOL",
    Address: "BORAKALALO, TLHAOLE SECTION  ZEERUST  BAHURUTSHE BA GA GOPANE",
    Latitude: "-25.2753",
    Longitude: "25.9243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19966",
    WardLookupId: "2419",
    VDNumber: "86553478",
    RegisteredPopulation: "77",
    VotingStationName: "REAGILE TRIBAL OFFICE",
    Address: "REAGILE  VILLAGE  BAHURUTSHE BA GA MOTHOAGAE",
    Latitude: "-25.2932",
    Longitude: "25.9922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19967",
    WardLookupId: "2419",
    VDNumber: "86554075",
    RegisteredPopulation: "341",
    VotingStationName: "ELEVEN EXPERIENCE FOOTBALL GROUND (TENT)",
    Address: "BORAKALALO VILLAGE  ZEERUST  ZEERUST",
    Latitude: "-25.2732",
    Longitude: "25.913",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19968",
    WardLookupId: "2420",
    VDNumber: "86550554",
    RegisteredPopulation: "601",
    VotingStationName: "LOBATLA PRIMARY SCHOOL",
    Address: "STREET NAME NOT AVAILABLE  LOBATLA  ZEERUST",
    Latitude: "-25.1785",
    Longitude: "25.8407",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19969",
    WardLookupId: "2420",
    VDNumber: "86550622",
    RegisteredPopulation: "1043",
    VotingStationName: "TLHOMESO PRIMARY SCHOOL",
    Address: "MOTLHABENG SECTION, GOPANE  ZEERUST  ZEERUST",
    Latitude: "-25.3087",
    Longitude: "25.8466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19970",
    WardLookupId: "2420",
    VDNumber: "86550633",
    RegisteredPopulation: "537",
    VotingStationName: "LEFOKO PRIMARY SCHOOL",
    Address: "NEXT TO GOPANE ROMAN CATHOLIC   ZEERUST  BAHURUTSHE BA GA GOPANE",
    Latitude: "-25.3064",
    Longitude: "25.8075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19971",
    WardLookupId: "2420",
    VDNumber: "86550666",
    RegisteredPopulation: "824",
    VotingStationName: "GOPANE TRIBAL OFFICE",
    Address: "GOPAVE, KGOSING SECTION  ZEERUST  BAHURUTSHE BA GA GOPANE",
    Latitude: "-25.3079",
    Longitude: "25.8031",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19972",
    WardLookupId: "2420",
    VDNumber: "86552589",
    RegisteredPopulation: "114",
    VotingStationName: "SOLOMON MAJOVA EARLY LEARNING CENTRE",
    Address: "MMUTSHWEU  ZEERUST  ZEERUST",
    Latitude: "-25.2268",
    Longitude: "25.8011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19973",
    WardLookupId: "2420",
    VDNumber: "86554097",
    RegisteredPopulation: "531",
    VotingStationName: "SHADUME EARLY LEARNING CENTRE",
    Address:
      "GOPANE VILLAGE  ZEERUST  BAHURUTSHE BA GA GOPANE TRIBAL AUTHORUTY",
    Latitude: "-25.3024",
    Longitude: "25.7924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19974",
    WardLookupId: "2420",
    VDNumber: "86554187",
    RegisteredPopulation: "267",
    VotingStationName: "MOTHEO EARLY LEARNING CENTRE",
    Address: "GASEANE SECTION  LOBATLA   VILLAGE  BAHURUTSHE BA GA GOPANE",
    Latitude: "-25.193",
    Longitude: "25.8216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19975",
    WardLookupId: "2421",
    VDNumber: "86550183",
    RegisteredPopulation: "376",
    VotingStationName: "BOROTHAMADI PRIMARY SCHOOL",
    Address: "BOROTHAMADI  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.3993",
    Longitude: "25.8602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19976",
    WardLookupId: "2421",
    VDNumber: "86550644",
    RegisteredPopulation: "943",
    VotingStationName: "MOKUTONG PRIMARY SCHOOL",
    Address: "MADIBANENG SECTION  ZEERUST  BAHURUTSHE BA GA GOPANE",
    Latitude: "-25.331",
    Longitude: "25.8161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19977",
    WardLookupId: "2421",
    VDNumber: "86550677",
    RegisteredPopulation: "774",
    VotingStationName: "NTLATSANG MIDDLE SCHOOL",
    Address: "GOPANE, PUANA  ZEERUST  BAHURUTSHE BA GA GOPANE",
    Latitude: "-25.319",
    Longitude: "25.7948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19978",
    WardLookupId: "2421",
    VDNumber: "86550688",
    RegisteredPopulation: "266",
    VotingStationName: "DIKHUDU PRIMARY SCHOOL",
    Address: "GARA DIKHUDU, N4 ROAD  ZEERUST  BAHURUTSHE BA GA GOPANE",
    Latitude: "-25.3291",
    Longitude: "25.765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19979",
    WardLookupId: "2421",
    VDNumber: "86553760",
    RegisteredPopulation: "518",
    VotingStationName: "NTIME O MPHELE NGWANA- EARLY LEARNING CENTRE",
    Address: "DINOKANA  RAMOTSHERE MOILOA  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.4321",
    Longitude: "25.8302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19980",
    WardLookupId: "2421",
    VDNumber: "86554109",
    RegisteredPopulation: "414",
    VotingStationName: "KNOW AND GROW EARLY LEARNING CENTRE",
    Address:
      "GOPANE, OLD NKABARI MIDDLE SC  ZEERUST  BAHURUTSHE BA GA GOPANE TRIBAL AUTHORITY",
    Latitude: "-25.3382",
    Longitude: "25.816",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19981",
    WardLookupId: "2422",
    VDNumber: "86550116",
    RegisteredPopulation: "419",
    VotingStationName: "PHAKEDI HIGH SCHOOL",
    Address: "MOKGOLA, PHAKEDI SECTION  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.3174",
    Longitude: "26.133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19982",
    WardLookupId: "2422",
    VDNumber: "86550127",
    RegisteredPopulation: "764",
    VotingStationName: "MASHELWA PRIMARY SCHOOL",
    Address: "MOKGOLA, MMERENTSHE SECTION   ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.3264",
    Longitude: "26.1257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19983",
    WardLookupId: "2422",
    VDNumber: "86550149",
    RegisteredPopulation: "483",
    VotingStationName: "MANOGELO PRIMARY SCHOOL",
    Address: "1 MOKGOLA, MANOGELO SECTION  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.3266",
    Longitude: "26.118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19984",
    WardLookupId: "2422",
    VDNumber: "86550611",
    RegisteredPopulation: "468",
    VotingStationName: "NYETSE TRIBAL HALL",
    Address: "NYETSE  NYETSE VILLAGE  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.2981",
    Longitude: "26.0293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19985",
    WardLookupId: "2422",
    VDNumber: "86553737",
    RegisteredPopulation: "453",
    VotingStationName: "SESAMOTHO PRIMARY SCHOOL",
    Address:
      "MOKGOLA, SESAMOTHO SECTION  MOKGOLA VILLAGE   BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.3373",
    Longitude: "26.1183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19986",
    WardLookupId: "2422",
    VDNumber: "86553748",
    RegisteredPopulation: "367",
    VotingStationName: "MAJABE PRIMARY SCHOOL",
    Address: "MOKGOLA, MOKGOLA SECTION  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.3242",
    Longitude: "26.1408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19987",
    WardLookupId: "2422",
    VDNumber: "86553906",
    RegisteredPopulation: "494",
    VotingStationName: "BOBUANTJWA (TENT)",
    Address: "MANOGELWA SECTION BOBUANTJWA SECTION  ZEERUST  ZEERUST",
    Latitude: "-25.3144",
    Longitude: "26.1205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19988",
    WardLookupId: "2422",
    VDNumber: "86554110",
    RegisteredPopulation: "227",
    VotingStationName: "MOSUNO OPEN FIELD (TENT)",
    Address: "MOSUNO SECTION,MOKGOLA VILLAGE  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.3351",
    Longitude: "26.1297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19989",
    WardLookupId: "2423",
    VDNumber: "86550150",
    RegisteredPopulation: "865",
    VotingStationName: "BANABAKAE PRIMARY SCHOOL",
    Address:
      "LEKUBU, NEXT TO LUTHERAN CHURC  ZEERUST  BAHURUTSHE BA GA SEBOGODI",
    Latitude: "-25.3877",
    Longitude: "26.0876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19990",
    WardLookupId: "2423",
    VDNumber: "86550161",
    RegisteredPopulation: "962",
    VotingStationName: "MMOPAKHUKHU ABET CENTRE",
    Address:
      "GAMAROLE, LEKUBU, OLD MALEBELL  ZEERUST  BAHURUTSHE BA GA SEBOGODI",
    Latitude: "-25.3898",
    Longitude: "26.0991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19991",
    WardLookupId: "2423",
    VDNumber: "86550172",
    RegisteredPopulation: "477",
    VotingStationName: "MALEBELLE PRIMARY SCHOOL",
    Address: "LEKUBU, RWANENG SECTION  ZEERUST  BAHURUTSHE BA GA SEBOGODI",
    Latitude: "-25.3808",
    Longitude: "26.0946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19992",
    WardLookupId: "2423",
    VDNumber: "86553445",
    RegisteredPopulation: "306",
    VotingStationName: "TENT AT TSHWARAGANANG EARLY LEARNING CENTRE",
    Address: "BRAKALLAGTE  RAMOTSHERE MOILOA  BAHURUTSHE BA GA SEBOGODI",
    Latitude: "-25.4036",
    Longitude: "26.079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19993",
    WardLookupId: "2423",
    VDNumber: "86553456",
    RegisteredPopulation: "407",
    VotingStationName: "MOKGAKALA HIGH SCHOOL",
    Address: "LEKUBU, DITSHOSWANE SECTION  ZEERUST  BAHURUTSHE BA GA SEBOGODI",
    Latitude: "-25.3922",
    Longitude: "26.0898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19994",
    WardLookupId: "2424",
    VDNumber: "86550194",
    RegisteredPopulation: "911",
    VotingStationName: "RAMATU HIGH SCHOOL",
    Address: "DINOKANA, GARA TSARA  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.4423",
    Longitude: "25.8492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19995",
    WardLookupId: "2424",
    VDNumber: "86550206",
    RegisteredPopulation: "285",
    VotingStationName: "POE ABET CENTER",
    Address: "DINOKANA, GAMOETSANA SECTION  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.4429",
    Longitude: "25.8587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19996",
    WardLookupId: "2424",
    VDNumber: "86550239",
    RegisteredPopulation: "1229",
    VotingStationName: "GAREOSENYE PRIMARY SCHOOL",
    Address: "DINOKANA,GOO MOKGATLHE SECTION  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.4353",
    Longitude: "25.8703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19997",
    WardLookupId: "2424",
    VDNumber: "86552422",
    RegisteredPopulation: "232",
    VotingStationName: "MARAMAGE PRIMARY SCHOOL",
    Address: "DINOKANA, MARAMAGE  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.3806",
    Longitude: "25.8765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19998",
    WardLookupId: "2424",
    VDNumber: "86553119",
    RegisteredPopulation: "504",
    VotingStationName: "MMELEGIKOMA EARLY LEARNING CENTRE",
    Address: "PUANA SECTION  ZEERUST  BAHURUTSHE BA GA MOILOA, DINOKANA",
    Latitude: "-25.4301",
    Longitude: "25.8645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "19999",
    WardLookupId: "2425",
    VDNumber: "86550228",
    RegisteredPopulation: "1671",
    VotingStationName: "DINOKANA TRIBAL OFFICE",
    Address: "DINOKANA, GAARA MOILOA  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.453",
    Longitude: "25.865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20000",
    WardLookupId: "2425",
    VDNumber: "86550251",
    RegisteredPopulation: "461",
    VotingStationName: "KEOBUSITSE PRIMARY SCHOOL",
    Address: "DINOKANA  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.4473",
    Longitude: "25.8712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20001",
    WardLookupId: "2425",
    VDNumber: "86554121",
    RegisteredPopulation: "515",
    VotingStationName: "LAPOLOGANG EARLY LEARNING CENTRE",
    Address: "DINOKANA  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.4538",
    Longitude: "25.8568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20002",
    WardLookupId: "2425",
    VDNumber: "86554132",
    RegisteredPopulation: "418",
    VotingStationName: "ITUMELENG EARLY LEARNING CENTRE",
    Address:
      "DINOKANA VILLAGE  ZEERUST  BAHURUTSHE BA GA MOILOA TRIBAL AUTHORITY",
    Latitude: "-25.4661",
    Longitude: "25.8692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20003",
    WardLookupId: "2426",
    VDNumber: "86550262",
    RegisteredPopulation: "710",
    VotingStationName: "RAMOTSHERE HIGH SCHOOL",
    Address: "DINOKANA-N4 ROAD  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.4658",
    Longitude: "25.8919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20004",
    WardLookupId: "2426",
    VDNumber: "86550273",
    RegisteredPopulation: "835",
    VotingStationName: "MONNAAMERE PRIMARY SCHOOL",
    Address: "DINOKANA-RAMESEGA SECTION  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.4679",
    Longitude: "25.8851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20005",
    WardLookupId: "2426",
    VDNumber: "86550284",
    RegisteredPopulation: "1071",
    VotingStationName: "RENONOFILE PRIMARY SCHOOL",
    Address: "DINOKANA, N4 PERDEVLEI SECTION  ZEERUST  ZEERUST",
    Latitude: "-25.4764",
    Longitude: "25.9082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20006",
    WardLookupId: "2426",
    VDNumber: "86553771",
    RegisteredPopulation: "224",
    VotingStationName: "MMAMOSWANE PRIMARY SCHOOL",
    Address:
      "DINOKANA, MMAMOSWANE SECTION  ZEERUST  ZEERUSTBAHURUTSHE BA GA MOILOA",
    Latitude: "-25.4801",
    Longitude: "25.8751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20007",
    WardLookupId: "2426",
    VDNumber: "86553872",
    RegisteredPopulation: "329",
    VotingStationName: "N.R. MANDELA HIGH SCHOOL",
    Address: "DINOKANA-N4 ROAD  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.4899",
    Longitude: "25.9251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20008",
    WardLookupId: "2427",
    VDNumber: "86550307",
    RegisteredPopulation: "1902",
    VotingStationName: "BOSUGAKOBO PRIMARY SCHOOL",
    Address: "DINOKANA, SELOSESHA SECTION  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.4952",
    Longitude: "25.9293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20009",
    WardLookupId: "2427",
    VDNumber: "86620046",
    RegisteredPopulation: "1660",
    VotingStationName: "MESEGA PRIMARY SCHOOL",
    Address: "1818 LEHURUTSHE UNIT 1  ZEERUST  ZEERUST",
    Latitude: "-25.4861",
    Longitude: "25.969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20010",
    WardLookupId: "2427",
    VDNumber: "86620080",
    RegisteredPopulation: "459",
    VotingStationName: "NGOTWANE HIGH SCHOOL",
    Address: "LEHURUTSHE UNIT 1  ZEERUST  ZEERUST",
    Latitude: "-25.481",
    Longitude: "25.9674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20011",
    WardLookupId: "2428",
    VDNumber: "86550048",
    RegisteredPopulation: "1414",
    VotingStationName: "NTSWELETSOKU PRIMARY SCHOOL",
    Address: "NTSWELETSOKU  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.4294",
    Longitude: "25.961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20012",
    WardLookupId: "2428",
    VDNumber: "86553131",
    RegisteredPopulation: "1226",
    VotingStationName: "PITSO TOLO PRIMARY SCHOOL",
    Address: "NTSWELETSOKU, SETETE SECTION  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.4526",
    Longitude: "25.9711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20013",
    WardLookupId: "2428",
    VDNumber: "86554143",
    RegisteredPopulation: "308",
    VotingStationName: "LNT TRAINING CENTRE",
    Address: "SETETE SECTION  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.4462",
    Longitude: "25.9577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20014",
    WardLookupId: "2428",
    VDNumber: "86554154",
    RegisteredPopulation: "394",
    VotingStationName: "SETETE LUTHERAN CHURCH",
    Address: "NTSWELETSOKU VILLAGE  ZEERUST  BAHURUTSHE BA GA MOILOA",
    Latitude: "-25.4603",
    Longitude: "25.9716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20015",
    WardLookupId: "2429",
    VDNumber: "86550813",
    RegisteredPopulation: "1331",
    VotingStationName: "MOTSAALORE SECONDARY SCHOOL",
    Address: "MOTSAALORE SECTION  KHUNOTSWANE  ZEERUST",
    Latitude: "-25.5612",
    Longitude: "25.8287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20016",
    WardLookupId: "2429",
    VDNumber: "86550824",
    RegisteredPopulation: "416",
    VotingStationName: "REGOPOLENG PRIMARY SCHOOL",
    Address: "STREET NAME NOT AVAILABLE  MATLHASE VILLAGE  ZEERUST",
    Latitude: "-25.498",
    Longitude: "25.7419",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20017",
    WardLookupId: "2429",
    VDNumber: "86552444",
    RegisteredPopulation: "472",
    VotingStationName: "PEME PRIMARY SCHOOL",
    Address: "STREET NAME NOT AVAILABLE  MADUTLE VILLAGE  ZEERUST",
    Latitude: "-25.494",
    Longitude: "25.6704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20018",
    WardLookupId: "2429",
    VDNumber: "86553894",
    RegisteredPopulation: "150",
    VotingStationName: "RELEBONE  EARLY LEARNING CENTRE",
    Address: "N4 ROAD, WILLOWPARK  ZEERUST  WILLOWPARK FARM",
    Latitude: "-25.5134",
    Longitude: "25.9929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20019",
    WardLookupId: "2429",
    VDNumber: "86620035",
    RegisteredPopulation: "1561",
    VotingStationName: "LEHURUTSHE CIVIC CENTRE",
    Address: "2422 LEHURUTSHE SHOPPING COMPLEX  LEHURUTSHE  ZEERUST",
    Latitude: "-25.4913",
    Longitude: "25.9865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20020",
    WardLookupId: "2430",
    VDNumber: "86620013",
    RegisteredPopulation: "1440",
    VotingStationName: "IKAGELENG COMMUNITY HALL",
    Address: "367/998 SEMENYA STREET  IKAGELENG  ZEERUST",
    Latitude: "-25.5603",
    Longitude: "26.0974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20021",
    WardLookupId: "2430",
    VDNumber: "86620068",
    RegisteredPopulation: "1214",
    VotingStationName: "HENRYVILLE PRIMARY SCHOOL",
    Address: "5000 HUGO STREET  OLIENHOUTPARK-ZZERUST  ZEERUST",
    Latitude: "-25.5685",
    Longitude: "26.0898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20022",
    WardLookupId: "2430",
    VDNumber: "86620079",
    RegisteredPopulation: "600",
    VotingStationName: "IKAGELENG HIGH SCHOOL",
    Address: "(NW385 - RAMOTSHERE MOILOA [ZEERUST])   ",
    Latitude: "-25.5662",
    Longitude: "26.0988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20023",
    WardLookupId: "2430",
    VDNumber: "86620103",
    RegisteredPopulation: "327",
    VotingStationName: "MARANATHA ASSEMBLIES OF GOD CHURCH",
    Address: "TAMBOTIE, CORNER SUIKERBOS  ZEERUST  ZEERUST",
    Latitude: "-25.5532",
    Longitude: "26.0921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20024",
    WardLookupId: "2431",
    VDNumber: "86553142",
    RegisteredPopulation: "303",
    VotingStationName: "NULL",
    Address: "NULL",
    Latitude: null,
    Longitude: null,
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20025",
    WardLookupId: "2431",
    VDNumber: "86553883",
    RegisteredPopulation: "870",
    VotingStationName: "KHUNOTSWANE PRIMARY SCHOOL",
    Address: "MADIBANA SECTION, KHUNOTSWANE   ZEERUST  KHUNOTSWANE VILLAGE",
    Latitude: "-25.5712",
    Longitude: "25.8282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20026",
    WardLookupId: "2431",
    VDNumber: "86620024",
    RegisteredPopulation: "2620",
    VotingStationName: "ZEERUST TOWN HALL",
    Address: "1259 COETZEE ST, BEHIND ABSA BANK  ZEERUST  ZEERUST",
    Latitude: "-25.5461",
    Longitude: "26.0794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20027",
    WardLookupId: "2431",
    VDNumber: "86620091",
    RegisteredPopulation: "321",
    VotingStationName: "LAERSKOOL ZEERUST",
    Address: "OPPOSITE ZEERUST POLICE STATIO  ZEERUST  ZEERUST",
    Latitude: "-25.5389",
    Longitude: "26.0799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20028",
    WardLookupId: "2432",
    VDNumber: "86550060",
    RegisteredPopulation: "515",
    VotingStationName: "DORINGLAAGTE PRIMARY SCHOOL",
    Address: "NO STREET ADRESS AVAILABLE  DORINGLAAGTE FARM  ZEERUST",
    Latitude: "-25.2351",
    Longitude: "26.3288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20029",
    WardLookupId: "2432",
    VDNumber: "86550071",
    RegisteredPopulation: "494",
    VotingStationName: "MOGOPA TRIBAL OFFICE",
    Address: "NEXT TO MOGOPA COMMUNITYCLINIC  MOGOPA  ZEERUST",
    Latitude: "-25.2084",
    Longitude: "26.3965",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20030",
    WardLookupId: "2432",
    VDNumber: "86550082",
    RegisteredPopulation: "613",
    VotingStationName: "SKUINSDRIFT PRIMARY SCHOOL",
    Address: "STREET NAME NOT AVAILABLE  SKUINSDRIFT  ZEERUST",
    Latitude: "-25.3719",
    Longitude: "26.3904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20031",
    WardLookupId: "2432",
    VDNumber: "86550105",
    RegisteredPopulation: "179",
    VotingStationName: "TAJBHAI & SONS GENERAL DEALER",
    Address: "ENSELSBERG  ZEERUST",
    Latitude: "-25.3692",
    Longitude: "26.2501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20032",
    WardLookupId: "2432",
    VDNumber: "86550318",
    RegisteredPopulation: "136",
    VotingStationName: "TENT AT GOEDGELEGEN BOTTLE STORE",
    Address: "NULL  GOEDGELEGEN FARM  ZEERUST",
    Latitude: "-25.5238",
    Longitude: "26.3153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20033",
    WardLookupId: "2432",
    VDNumber: "86553041",
    RegisteredPopulation: "247",
    VotingStationName: "LAERSKOOL NIETVERDIEND",
    Address: "ZEERUST/KOPFONTEIN ROAD  ZEERUST  ZEERUST",
    Latitude: "-25.0215",
    Longitude: "26.1604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20034",
    WardLookupId: "2432",
    VDNumber: "86553153",
    RegisteredPopulation: "226",
    VotingStationName: "MORUTI PRIMARY SCHOOL",
    Address: "ZEERUST/RIEKERTSDAM ROAD  RIEKERTSDAM FARMS  ZEERUST",
    Latitude: "-25.5138",
    Longitude: "26.2175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20035",
    WardLookupId: "2432",
    VDNumber: "86553412",
    RegisteredPopulation: "129",
    VotingStationName: "TENT AT SHINING STARS GROUNDS",
    Address: "MAROELAKOP  ZEERUST  BAHURUTSHE BOO MANYANA",
    Latitude: "-25.0798",
    Longitude: "26.2391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20036",
    WardLookupId: "2432",
    VDNumber: "86553759",
    RegisteredPopulation: "127",
    VotingStationName: "TENT AT PHALALO PRIMARY SCHOOL.",
    Address: "PHALALO FARMS  ZEERUST  ZEERUST",
    Latitude: "-25.4443",
    Longitude: "26.2946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20037",
    WardLookupId: "2432",
    VDNumber: "86554165",
    RegisteredPopulation: "294",
    VotingStationName: "SAKALENGWE HIGH SCHOOL",
    Address: "MMASEBUDULE VILLAGE  ZEERUST  BAHURUTSHE BOO MANYANA",
    Latitude: "-25.0498",
    Longitude: "26.3123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20038",
    WardLookupId: "2432",
    VDNumber: "86554389",
    RegisteredPopulation: "177",
    VotingStationName: "THUTO KE LESEDI ELC",
    Address: "VENTURE  ZEERUST  ZEERUST",
    Latitude: "-25.4203",
    Longitude: "26.3798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20039",
    WardLookupId: "2433",
    VDNumber: "86550240",
    RegisteredPopulation: "868",
    VotingStationName: "NTEBOGANG MIDDLE SCHOOL",
    Address: "SEFERELLA SECTION  DINOKANA  ZEERUST",
    Latitude: "-25.4533",
    Longitude: "25.8917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20040",
    WardLookupId: "2433",
    VDNumber: "86550600",
    RegisteredPopulation: "388",
    VotingStationName: "TSHWARAGANO PRIMARY SCHOOL",
    Address: "STREET NAME NOT AVAILABLE  MOTSWEDI/POOSEDUMANE  ZEERUST",
    Latitude: "-25.3018",
    Longitude: "25.9203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20041",
    WardLookupId: "2433",
    VDNumber: "86550699",
    RegisteredPopulation: "251",
    VotingStationName: "RAMOKGETHWA PRIMARY SCHOOL",
    Address: "STREET NAME NOT AVAILABLE  MANTSIE  ZEERUST",
    Latitude: "-25.3381",
    Longitude: "25.9279",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20042",
    WardLookupId: "2433",
    VDNumber: "86552433",
    RegisteredPopulation: "790",
    VotingStationName: "LEKOLWANE PRIMARY SCHOOL",
    Address: "LEKUJWANA, NEXT TO LETHERAN CH  MOSWEU  ZEERUST",
    Latitude: "-25.3744",
    Longitude: "25.9825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20043",
    WardLookupId: "2433",
    VDNumber: "86553489",
    RegisteredPopulation: "458",
    VotingStationName: "MOSEKAPHOFU SECONDARY SCHOOL",
    Address: "MMATSHETLHWANE SECTION  MOSWEU VILLAGE  ZEERUST",
    Latitude: "-25.3757",
    Longitude: "25.95",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20044",
    WardLookupId: "2433",
    VDNumber: "86553861",
    RegisteredPopulation: "360",
    VotingStationName: "REARABILWE MIDDLE SCHOOL",
    Address: "MMATLHAGENG SECTION  ZEERUST  NTSWELETSOKU VILLAGE",
    Latitude: "-25.4212",
    Longitude: "25.9551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20045",
    WardLookupId: "2433",
    VDNumber: "86554086",
    RegisteredPopulation: "226",
    VotingStationName: "BORAKALALO COMMUNITY HALL",
    Address: "BORAKALALO VILLAGE  ZEERUST  BAHURUTSHE BA GA GOPANE",
    Latitude: "-25.2858",
    Longitude: "25.9072",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20046",
    WardLookupId: "2434",
    VDNumber: "86550329",
    RegisteredPopulation: "160",
    VotingStationName: "TENT AT ISHMAEL TRADING STORE",
    Address: "TWYFELSPOORT- N4  ZEERUST  ZEERUST",
    Latitude: "-25.6535",
    Longitude: "26.4862",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20047",
    WardLookupId: "2434",
    VDNumber: "86550341",
    RegisteredPopulation: "232",
    VotingStationName: "CLAASE SLATE WORKS",
    Address: "ZEERUST/KOSTER ROAD  ZEERUST  ZEERUST",
    Latitude: "-25.6326",
    Longitude: "26.1294",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20048",
    WardLookupId: "2434",
    VDNumber: "86553401",
    RegisteredPopulation: "286",
    VotingStationName: "MASEGATSANE LODGE",
    Address: "29 KLAARSTROOM FARM  GROOT MARICO  ZEERUST",
    Latitude: "-25.5545",
    Longitude: "26.2124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20049",
    WardLookupId: "2434",
    VDNumber: "86553928",
    RegisteredPopulation: "268",
    VotingStationName: "TENT AT RIETVLEI",
    Address: "RIETVLEI  ZEERUST",
    Latitude: "-25.7073",
    Longitude: "26.2737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20050",
    WardLookupId: "2434",
    VDNumber: "86620057",
    RegisteredPopulation: "1255",
    VotingStationName: "ST JOHN APOSTOLIC CHURCH",
    Address: "419 NKETSI STREET, NALEDI SECTION  IKAGELENG LOCATION  ZEERUST",
    Latitude: "-25.5588",
    Longitude: "26.1013",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20051",
    WardLookupId: "2434",
    VDNumber: "86640015",
    RegisteredPopulation: "1954",
    VotingStationName: "MARICO ACADEMY",
    Address: "GROOT MARICO  ZEERUST  GROOT MARICO",
    Latitude: "-25.5971",
    Longitude: "26.421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20052",
    WardLookupId: "4495",
    VDNumber: "86741556",
    RegisteredPopulation: "218",
    VotingStationName: "DIRKIESRUS SHOP",
    Address: "DIRKUMAS RUST  DIRKIESRUS  VRYBURG",
    Latitude: "-26.3441",
    Longitude: "24.7081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20053",
    WardLookupId: "4495",
    VDNumber: "86741668",
    RegisteredPopulation: "78",
    VotingStationName: "ROSSS FARM TENT",
    Address: "ROSS FARM TENT  STELLA  VRYBURG",
    Latitude: "-26.476",
    Longitude: "24.8146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20054",
    WardLookupId: "4495",
    VDNumber: "86741679",
    RegisteredPopulation: "186",
    VotingStationName: "PANHOEKS FARM (TENT)",
    Address: "1 PANHOEK FARM  DR RUTH MOMPATI DISTRICT  VRYBURG",
    Latitude: "-26.5057",
    Longitude: "24.9068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20055",
    WardLookupId: "4495",
    VDNumber: "86741680",
    RegisteredPopulation: "364",
    VotingStationName: "KAMEEL LAERSKOOL",
    Address: "N/A  ELSRUST FARM  BOPHIRIMA",
    Latitude: "-26.5967",
    Longitude: "25.0395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20056",
    WardLookupId: "4495",
    VDNumber: "86743390",
    RegisteredPopulation: "1857",
    VotingStationName: "SETUMO INTERMEDIATE SCHOOL",
    Address: "REKGARATLHILE TOWNSHIP  REKGARATLHILE  STELLA",
    Latitude: "-26.5502",
    Longitude: "24.8489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20057",
    WardLookupId: "4495",
    VDNumber: "86743581",
    RegisteredPopulation: "390",
    VotingStationName: "GEDULDSPAN FARM HOUSE",
    Address: "STELLA  VRYBURG",
    Latitude: "-26.3248",
    Longitude: "24.7251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20058",
    WardLookupId: "4495",
    VDNumber: "86760018",
    RegisteredPopulation: "760",
    VotingStationName: "JAPIE KOTZE HALL",
    Address: "STELLA STREET  STELLA  VRYBURG",
    Latitude: "-26.5456",
    Longitude: "24.8598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20059",
    WardLookupId: "4496",
    VDNumber: "86553524",
    RegisteredPopulation: "693",
    VotingStationName: "BROEDERSPUT PRIMARY SCHOOL",
    Address: "0 BROEDERSPUT FARM  VRYBURG  VRYBURG",
    Latitude: "-26.8351",
    Longitude: "25.1007",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20060",
    WardLookupId: "4496",
    VDNumber: "86740061",
    RegisteredPopulation: "571",
    VotingStationName: "DEVONDALE INTERMEDIATE SCHOOL",
    Address: "PO BOX 2613  DEVONDALE  BOPHIRIMA",
    Latitude: "-26.7624",
    Longitude: "24.8905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20061",
    WardLookupId: "4496",
    VDNumber: "86742221",
    RegisteredPopulation: "95",
    VotingStationName: "BOEREPLAAS",
    Address: "BOEREPLAAS  NALEDI",
    Latitude: "-26.7871",
    Longitude: "24.786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20062",
    WardLookupId: "4496",
    VDNumber: "86770019",
    RegisteredPopulation: "2225",
    VotingStationName: "COLRIDGE TENT SUB STATION 1",
    Address: "1265 FRENCH STREET  COLRIDGE  VRYBURG",
    Latitude: "-26.9502",
    Longitude: "24.7392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20063",
    WardLookupId: "4497",
    VDNumber: "86770109",
    RegisteredPopulation: "316",
    VotingStationName: "KISMET COMMUNITY HALL",
    Address: "VRYBURG  ",
    Latitude: "-26.943",
    Longitude: "24.7317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20064",
    WardLookupId: "4497",
    VDNumber: "86770110",
    RegisteredPopulation: "2098",
    VotingStationName: "FLORADENE PRIMARY SCHOOL SUB STATION 1",
    Address: "02 TIEROOG  COLRIDGE  VRYBURG",
    Latitude: "-26.9433",
    Longitude: "24.739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20065",
    WardLookupId: "4497",
    VDNumber: "86770154",
    RegisteredPopulation: "679",
    VotingStationName: "COLINDA SCHOOL",
    Address: "15 COLRIDGE  VRYBURG",
    Latitude: "-26.9466",
    Longitude: "24.7352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20066",
    WardLookupId: "4498",
    VDNumber: "86770075",
    RegisteredPopulation: "2186",
    VotingStationName: "PULE LEEUW HIGH SCHOOL",
    Address: "MAKGOBI STREET  HUHUDI  VRYBURG",
    Latitude: "-26.9825",
    Longitude: "24.7412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20067",
    WardLookupId: "4498",
    VDNumber: "86770176",
    RegisteredPopulation: "779",
    VotingStationName: "RETLADIRA PRIMARY SCHOOL",
    Address: "2654 MATLHOKO STREET  HUHUDI  VRYBURG",
    Latitude: "-26.9783",
    Longitude: "24.739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20068",
    WardLookupId: "4499",
    VDNumber: "86741736",
    RegisteredPopulation: "1288",
    VotingStationName: "DITHAKWANENG TOWN HALL",
    Address: "DITHAKWANENG  BOPHIRIMA",
    Latitude: "-27.0793",
    Longitude: "24.5399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20069",
    WardLookupId: "4499",
    VDNumber: "86741770",
    RegisteredPopulation: "147",
    VotingStationName: "TSHWARANANG PRIMARY SCHOOL",
    Address: "PO BOX 122  GENOA  BOPHIRIMA",
    Latitude: "-27.1171",
    Longitude: "24.8696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20070",
    WardLookupId: "4499",
    VDNumber: "86741804",
    RegisteredPopulation: "199",
    VotingStationName: "GROOTPAN FARM (TENT)",
    Address: "DELAREY ROAD  VRYBURG  VRYBURG",
    Latitude: "-26.87",
    Longitude: "25.0225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20071",
    WardLookupId: "4499",
    VDNumber: "86742243",
    RegisteredPopulation: "115",
    VotingStationName: "ARMOEDSVLAKTE P SCHOOL",
    Address: "PO BOX 14  ARMOEDSVLAKTE  BOPHIRIMA",
    Latitude: "-26.9595",
    Longitude: "24.6531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20072",
    WardLookupId: "4499",
    VDNumber: "86742254",
    RegisteredPopulation: "51",
    VotingStationName: "HALFWAY STORE",
    Address: "BRUSSELS  NALEDI",
    Latitude: "-27.2069",
    Longitude: "24.7425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20073",
    WardLookupId: "4499",
    VDNumber: "86742603",
    RegisteredPopulation: "126",
    VotingStationName: "TENT AT MASSOUWSKOP",
    Address: "GANYESA ROAD  VRYBURG  VRYBURG",
    Latitude: "-26.8211",
    Longitude: "24.5094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20074",
    WardLookupId: "4499",
    VDNumber: "86743592",
    RegisteredPopulation: "440",
    VotingStationName: "MODISAKOMA INTERMEDIATE SCHOOL.",
    Address: "DITHAKWANENG  VRYBURG  VRYBURG",
    Latitude: "-27.0787",
    Longitude: "24.5355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20075",
    WardLookupId: "4499",
    VDNumber: "86743604",
    RegisteredPopulation: "107",
    VotingStationName: "LILIESPRUIT PRIMARY SCHOOL",
    Address: "04 LILIESFONTEIN FARM  LILIESFONTEIN  VRYBURG",
    Latitude: "-27.0554",
    Longitude: "24.9863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20076",
    WardLookupId: "4499",
    VDNumber: "86820622",
    RegisteredPopulation: "171",
    VotingStationName: "CALVYN FARM SCHOOL",
    Address: "PO BOX 355  CALVYN  SCHWEIZER RENEKE",
    Latitude: "-27.0117",
    Longitude: "25.3318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20077",
    WardLookupId: "4499",
    VDNumber: "86820778",
    RegisteredPopulation: "166",
    VotingStationName: "WILDEHONDEPAN (TENT)",
    Address: "1 WILDEHONDEPAN  DR RUTH MOMPATI DISTRICT  VRYBURG",
    Latitude: "-26.8866",
    Longitude: "25.2383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20078",
    WardLookupId: "4499",
    VDNumber: "86820789",
    RegisteredPopulation: "42",
    VotingStationName: "TENT AT LOSASA YARD GENERAL DEALER",
    Address: "SCHWEIZER ROAD  VRYBURG  VRYBURG",
    Latitude: "-26.9853",
    Longitude: "25.0561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20079",
    WardLookupId: "4500",
    VDNumber: "86770042",
    RegisteredPopulation: "1570",
    VotingStationName: "JOHN FRYLINCK HIGH SCHOOL",
    Address: "2656 CRUIZE STREET  HUHUDI  VRYBURG",
    Latitude: "-26.9728",
    Longitude: "24.7371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20080",
    WardLookupId: "4500",
    VDNumber: "86770086",
    RegisteredPopulation: "722",
    VotingStationName: "HUHUDI COMMUNITY HALL",
    Address: "MOSIAPOA STREET  HUHUDI  VRYBURG",
    Latitude: "-26.9684",
    Longitude: "24.7413",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20081",
    WardLookupId: "4500",
    VDNumber: "86770097",
    RegisteredPopulation: "487",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "10 METSI STREET  VRYBURG  BOPHIRIMA",
    Latitude: "-26.9699",
    Longitude: "24.7357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20082",
    WardLookupId: "4501",
    VDNumber: "86741163",
    RegisteredPopulation: "158",
    VotingStationName: "MEXICO FARM",
    Address: "N/A  MEXICO  BOPHIRIMA",
    Latitude: "-26.6215",
    Longitude: "24.849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20083",
    WardLookupId: "4501",
    VDNumber: "86741725",
    RegisteredPopulation: "134",
    VotingStationName: "ONTWIKKELINGS FARM",
    Address: "FARM  DR RUTH MOMPATI DISTRICT  VRYBURG",
    Latitude: "-26.8262",
    Longitude: "24.6043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20084",
    WardLookupId: "4501",
    VDNumber: "86770020",
    RegisteredPopulation: "3199",
    VotingStationName: "BANQUET HALL",
    Address: "19 MARKET STREET  VRYBURG  VRYBURG",
    Latitude: "-26.9577",
    Longitude: "24.721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20085",
    WardLookupId: "4501",
    VDNumber: "86770165",
    RegisteredPopulation: "530",
    VotingStationName: "VRYBURG HIGH SCHOOL",
    Address: "01 DE KOCK  VRYBURG CENTRAL  VRYBURG",
    Latitude: "-26.9532",
    Longitude: "24.7282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20086",
    WardLookupId: "4501",
    VDNumber: "86770187",
    RegisteredPopulation: "553",
    VotingStationName: "TENT AT EXTENSION 28.",
    Address: "COLOURBLOOK  COLOURBLOK  VRYBURG",
    Latitude: "-26.9785",
    Longitude: "24.7184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20087",
    WardLookupId: "4501",
    VDNumber: "86770198",
    RegisteredPopulation: "684",
    VotingStationName: "TENT AT PENTECOSTAL CHURCH EXT 25",
    Address: "4406 COLOURBLOK  BAZANI  VRYBURG",
    Latitude: "-26.9786",
    Longitude: "24.7095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20088",
    WardLookupId: "4502",
    VDNumber: "86770064",
    RegisteredPopulation: "1624",
    VotingStationName: "GYMNASIUM",
    Address: "MOKGOSI STREET  HUHUDI  VRYBURG",
    Latitude: "-26.9783",
    Longitude: "24.7426",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20089",
    WardLookupId: "4502",
    VDNumber: "86770143",
    RegisteredPopulation: "568",
    VotingStationName: "FULL GOSPEL CHURCH",
    Address: "(NW392 - NALEDI [VRYBURG])   ",
    Latitude: "-26.9788",
    Longitude: "24.7484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20090",
    WardLookupId: "4503",
    VDNumber: "86770121",
    RegisteredPopulation: "1918",
    VotingStationName: "THUTO LESEDI SCHOOL SUB STATION 1",
    Address: "4406 KRICH NAIDOO  HUHUDI TOWNSHIP  VRYBURG",
    Latitude: "-26.9846",
    Longitude: "24.7444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20091",
    WardLookupId: "4504",
    VDNumber: "86770031",
    RegisteredPopulation: "1508",
    VotingStationName: "OPEN GROUND AT THE DUTCH REFORMED CHURCH (TENT)",
    Address: "COR. ROYDEN ROAD AND MACKENZIE  CENTRAL  VRYBURG",
    Latitude: "-26.9566",
    Longitude: "24.7425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20092",
    WardLookupId: "4504",
    VDNumber: "86770053",
    RegisteredPopulation: "1542",
    VotingStationName: "BOPAGANANG SCHOOL",
    Address: "2101 SEGAWANA STREET  HUHUDI  VRYBURG",
    Latitude: "-26.9751",
    Longitude: "24.7449",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20093",
    WardLookupId: "4504",
    VDNumber: "86770132",
    RegisteredPopulation: "521",
    VotingStationName: "TENT AT NAPWA YARD",
    Address: "3187 SEGAWANA  HUHUDI  VRYBURG",
    Latitude: "-26.967",
    Longitude: "24.7448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20094",
    WardLookupId: "2435",
    VDNumber: "86820587",
    RegisteredPopulation: "1352",
    VotingStationName: "GLAUDINA LIBRARY HALL",
    Address: "17 MARKET  GLAUDINA  SCHWEIZER-RENEKE",
    Latitude: "-27.0365",
    Longitude: "25.6281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20095",
    WardLookupId: "2435",
    VDNumber: "86820600",
    RegisteredPopulation: "2065",
    VotingStationName: "KAMOGELO PRIMARY SCHOOL",
    Address: "447AND 476 MIGDOL  SCHWEIZER RENEKE",
    Latitude: "-26.906",
    Longitude: "25.4722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20096",
    WardLookupId: "2436",
    VDNumber: "86920049",
    RegisteredPopulation: "2351",
    VotingStationName: "KOLONG PRIMARY SCHOOL",
    Address: "3563 MUSUMA STREET  IPELEGENG  SCHWEIZER-RENEKE",
    Latitude: "-27.1974",
    Longitude: "25.3014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20097",
    WardLookupId: "2436",
    VDNumber: "86920128",
    RegisteredPopulation: "515",
    VotingStationName: "TSHWARAGANELO PRIMARY SCHOOL",
    Address: "114 MOTSEOTHATA STREET  IPELEGENG LOCATION  SCHWEIZER RENEKE",
    Latitude: "-27.2001",
    Longitude: "25.307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20098",
    WardLookupId: "2437",
    VDNumber: "86820677",
    RegisteredPopulation: "356",
    VotingStationName: "AVONDSTER SHOP",
    Address: "N/A  AVONDSTER/ VRYHEID 134HO  SCHWEIZER RENEKE",
    Latitude: "-27.4383",
    Longitude: "25.1786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20099",
    WardLookupId: "2437",
    VDNumber: "86821106",
    RegisteredPopulation: "126",
    VotingStationName: "NOOITGEDAGCHT FARM",
    Address: "(NW393 - MAMUSA [SCHWEIZER-RENEKE])   ",
    Latitude: "-27.5246",
    Longitude: "25.1319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20100",
    WardLookupId: "2437",
    VDNumber: "86970011",
    RegisteredPopulation: "1711",
    VotingStationName: "AMALIA PRIMARY SCHOOL",
    Address: "NA NA  AMALIA  SCHWEIZER -RENEKE",
    Latitude: "-27.249",
    Longitude: "25.0339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20101",
    WardLookupId: "2437",
    VDNumber: "86970022",
    RegisteredPopulation: "897",
    VotingStationName: "URCSA CHURCH",
    Address: "141 CHURCH STREET  MOLATSWANENG LOCATION  AMALIA",
    Latitude: "-27.2505",
    Longitude: "25.0316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20102",
    WardLookupId: "2438",
    VDNumber: "86920038",
    RegisteredPopulation: "2063",
    VotingStationName: "MMATHARI CRECHE",
    Address: "1123 ONGALE STREET  IPELEGENG LOCATION  SCHWEIZER-RENEKE",
    Latitude: "-27.2048",
    Longitude: "25.3012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20103",
    WardLookupId: "2438",
    VDNumber: "86920139",
    RegisteredPopulation: "673",
    VotingStationName: "AFRICAN CHURCH",
    Address: "526 LEBITSA BOSHOFF STREET  IPELEGENG LOCATION  SCHWEIZER-RENEKE",
    Latitude: "-27.2036",
    Longitude: "25.304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20104",
    WardLookupId: "2439",
    VDNumber: "86820655",
    RegisteredPopulation: "229",
    VotingStationName: "JIM PAYNE HALL",
    Address: "AMALIA  ",
    Latitude: "-27.2498",
    Longitude: "25.0469",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20105",
    WardLookupId: "2439",
    VDNumber: "86820802",
    RegisteredPopulation: "62",
    VotingStationName: "HOUTVOLOP",
    Address: "FARM  SCHWEIZER RENEKE",
    Latitude: "-27.3132",
    Longitude: "25.3163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20106",
    WardLookupId: "2439",
    VDNumber: "86920072",
    RegisteredPopulation: "684",
    VotingStationName: "MAMUSA PRIMARY SCHOOL",
    Address: "455 MAPHEKE STREET  IPELEGENG  MAMUSA [SCHWEIZER-RENEKE]",
    Latitude: "-27.2054",
    Longitude: "25.297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20107",
    WardLookupId: "2439",
    VDNumber: "86920106",
    RegisteredPopulation: "1874",
    VotingStationName: "INDEPENDENT CHRISTIAN CONGREGATIONAL CHURCH",
    Address: "(NW393 - MAMUSA [SCHWEIZER-RENEKE])   ",
    Latitude: "-27.2083",
    Longitude: "25.2898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20108",
    WardLookupId: "2440",
    VDNumber: "86920027",
    RegisteredPopulation: "1855",
    VotingStationName: "IKGOMOTSENG PRIMARY SCHOOL",
    Address: "3563 MUSUMA STREET  IPELEGENG  SCHWEIZER-RENEKE",
    Latitude: "-27.2033",
    Longitude: "25.2915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20109",
    WardLookupId: "2440",
    VDNumber: "86920083",
    RegisteredPopulation: "1358",
    VotingStationName: "METHODIST CHRISTIAN CHURCH",
    Address: "IPELEGENG  MAMUSA",
    Latitude: "-27.2059",
    Longitude: "25.2893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20110",
    WardLookupId: "2441",
    VDNumber: "86820611",
    RegisteredPopulation: "108",
    VotingStationName: "MORNAY FARM SCHOOL",
    Address: "MORNAY  SCHWEIZER-RENEKE  SCHWEIZER RENEKE",
    Latitude: "-27.0642",
    Longitude: "25.2598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20111",
    WardLookupId: "2441",
    VDNumber: "86920061",
    RegisteredPopulation: "1736",
    VotingStationName: "SMALL MINI MARKET",
    Address: "2807 SERAMERIEKERT  IPELEGENG  MAMUSA [SCHWEIZER-RENEKE]",
    Latitude: "-27.202",
    Longitude: "25.2875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20112",
    WardLookupId: "2441",
    VDNumber: "86920094",
    RegisteredPopulation: "1858",
    VotingStationName: "REMMOGO EARLY LEARNING CENTER",
    Address: "7604 EXT 6  SCHWEIZER RENEKE  IPELEGENG LOCATION",
    Latitude: "-27.1894",
    Longitude: "25.296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20113",
    WardLookupId: "2441",
    VDNumber: "86920140",
    RegisteredPopulation: "414",
    VotingStationName: "PIET SERAME SHOP",
    Address: "9354 EXT 8  IPELEGENG EXT 8  SCHWEIZER RENEKE",
    Latitude: "-27.1898",
    Longitude: "25.2842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20114",
    WardLookupId: "2442",
    VDNumber: "86920016",
    RegisteredPopulation: "1821",
    VotingStationName: "TIRANO CHURCH",
    Address: "2922 SEBOTHO STREET  IPELEGENG LOCATION  SCHWEIZER RENEKE",
    Latitude: "-27.1992",
    Longitude: "25.2877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20115",
    WardLookupId: "2442",
    VDNumber: "86920117",
    RegisteredPopulation: "1012",
    VotingStationName: "REABETSWE SECONDARY SCHOOL",
    Address: "4594 MUYLA STREET  IPELEGENG LOCATION  SCHWEIZER RENEKE",
    Latitude: "-27.1986",
    Longitude: "25.2892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20116",
    WardLookupId: "2443",
    VDNumber: "86820633",
    RegisteredPopulation: "303",
    VotingStationName: "DOORNPLAAT FARM",
    Address: "NA NA  FARM  SCHWEIZER - RENEKE",
    Latitude: "-27.1584",
    Longitude: "25.4729",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20117",
    WardLookupId: "2443",
    VDNumber: "86820644",
    RegisteredPopulation: "358",
    VotingStationName: "MIMOSA OFFICE",
    Address: "SCHWEIZER RENEKE  SCHWEIZER-RENEKE  SCHWEIZER-RENEKE",
    Latitude: "-27.2049",
    Longitude: "25.3323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20118",
    WardLookupId: "2443",
    VDNumber: "86820813",
    RegisteredPopulation: "150",
    VotingStationName: "GRASPAN",
    Address: "NA NA  FARM  SCHWEIZER  - RENEKE",
    Latitude: "-27.3495",
    Longitude: "25.5225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20119",
    WardLookupId: "2443",
    VDNumber: "86920050",
    RegisteredPopulation: "1773",
    VotingStationName: "MUNICIPAL OFFICES",
    Address: "28 SCHWEIZER STREET  MAMUSA   SCHWEIZER-RENEKE",
    Latitude: "-27.1941",
    Longitude: "25.3282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20120",
    WardLookupId: "2444",
    VDNumber: "86740836",
    RegisteredPopulation: "667",
    VotingStationName: "OKETSANG PRIMARY SCHOOL",
    Address: "NEXT TO THE SOCCER PITCH  QHO VILLAGE  GREATER TAUNG",
    Latitude: "-27.5668",
    Longitude: "24.5123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20121",
    WardLookupId: "2444",
    VDNumber: "86741703",
    RegisteredPopulation: "277",
    VotingStationName: "LYKSO PRIMARY SCHOOL",
    Address: "NEXT TO KURUMAN ROAD  LYKSO FARM  GREATER TAUNG",
    Latitude: "-27.2121",
    Longitude: "24.0943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20122",
    WardLookupId: "2444",
    VDNumber: "86742265",
    RegisteredPopulation: "115",
    VotingStationName: "HOWARD` FARM",
    Address: "NEXT TO FONTEINTJIE  REIVILO FARMS  GREATER TAUNG",
    Latitude: "-27.2128",
    Longitude: "24.3083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20123",
    WardLookupId: "2444",
    VDNumber: "86742298",
    RegisteredPopulation: "476",
    VotingStationName: "SEOLESENG MIDDLE SCHOOL",
    Address: "NEXT TO SHOP  VAALTYN VILLAGE  GREATER TAUNG",
    Latitude: "-27.6442",
    Longitude: "24.4714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20124",
    WardLookupId: "2444",
    VDNumber: "86750017",
    RegisteredPopulation: "794",
    VotingStationName: "REIVILO HIGH SCHOOL",
    Address: "5 DE JAGER STRAAT  REIVILO  GREATER TAUNG",
    Latitude: "-27.5654",
    Longitude: "24.1807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20125",
    WardLookupId: "2444",
    VDNumber: "86750028",
    RegisteredPopulation: "1363",
    VotingStationName: "BOIPELO COMMUNITY HALL",
    Address: "NEXT TO HOSTEL  BOIPELO TOWNSHIP  GREATER TAUNG",
    Latitude: "-27.5661",
    Longitude: "24.1915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20126",
    WardLookupId: "2444",
    VDNumber: "86750039",
    RegisteredPopulation: "63",
    VotingStationName: "LE ROY TENT",
    Address: "REIVILO  GREATER TAUNG  GREATER TAUNG MUNICIPALITY",
    Latitude: "-27.3613",
    Longitude: "24.2028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20127",
    WardLookupId: "2445",
    VDNumber: "86740678",
    RegisteredPopulation: "836",
    VotingStationName: "MOTLHAMARE PRIMARY SCHOOL",
    Address: "NEXT TO MAIN ROAD  MORETELE VILLAGE  GREATER TAUNG",
    Latitude: "-27.3167",
    Longitude: "24.6918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20128",
    WardLookupId: "2445",
    VDNumber: "86740960",
    RegisteredPopulation: "1157",
    VotingStationName: "MOITSIEMANG PRIMARY SCHOOL",
    Address: "NEXT TO CHIEFS HOUSE  MAGANENG VILLAGE  GREATER TAUNG",
    Latitude: "-27.2671",
    Longitude: "24.6778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20129",
    WardLookupId: "2445",
    VDNumber: "86742311",
    RegisteredPopulation: "309",
    VotingStationName: "MONCHO PRIMARY SCHOOL",
    Address: "NEXT TO SHOP  KHAUKHWE  GREATER TAUNG",
    Latitude: "-27.3369",
    Longitude: "24.637",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20130",
    WardLookupId: "2445",
    VDNumber: "86743097",
    RegisteredPopulation: "788",
    VotingStationName: "NTSWANAHATSHE METHODIST CHURCH",
    Address: "NEXT TO KGOTLA  NTSWANAHATSHE VILLAGE  GREATER TAUNG",
    Latitude: "-27.3116",
    Longitude: "24.6953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20131",
    WardLookupId: "2445",
    VDNumber: "86743110",
    RegisteredPopulation: "436",
    VotingStationName: "THE CHURCH OF FIRST BORN - DRYHARTS",
    Address: "NEXT TO TRIBAL OFFICE  DRYHARTS VILLAGE  GREATER TAUNG",
    Latitude: "-27.3371",
    Longitude: "24.7249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20132",
    WardLookupId: "2446",
    VDNumber: "86740656",
    RegisteredPopulation: "1167",
    VotingStationName: "THAPAMA HIGH SCHOOL",
    Address: "NEXT TO CLINIC  MATLAPANENG VILLAGE  GREATER TAUNG",
    Latitude: "-27.4209",
    Longitude: "24.6854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20133",
    WardLookupId: "2446",
    VDNumber: "86740803",
    RegisteredPopulation: "329",
    VotingStationName: "LOSELONG PRIMARY SCHOOL",
    Address: "NEXT TO MAIN ROAD  LOSELONG VILLAGE  GREATER TAUNG",
    Latitude: "-27.4244",
    Longitude: "24.597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20134",
    WardLookupId: "2446",
    VDNumber: "86742300",
    RegisteredPopulation: "1493",
    VotingStationName: "GASEONALE PRIMARY SCHOOL",
    Address: "NEXT TO MAIN ROAD  CHOSENG VILLAGE  BOPHIRIMA DISTRICT",
    Latitude: "-27.3812",
    Longitude: "24.6851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20135",
    WardLookupId: "2446",
    VDNumber: "86742625",
    RegisteredPopulation: "361",
    VotingStationName: "NTIKANG PRIMARY SCHOOL",
    Address: "NEXT TO MAIN ROAD LOSELONG  SEDIBENG VILLAGE  GREATER TAUNG",
    Latitude: "-27.3842",
    Longitude: "24.6499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20136",
    WardLookupId: "2446",
    VDNumber: "86743233",
    RegisteredPopulation: "721",
    VotingStationName: "ITLAMENG PRIMARY SCHOOL",
    Address: "0 NEXT TO SHOP  MASE VILLAGE  GREATER TAUNG",
    Latitude: "-27.4437",
    Longitude: "24.6926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20137",
    WardLookupId: "2447",
    VDNumber: "86740487",
    RegisteredPopulation: "1261",
    VotingStationName: "DRYHARTS PRIMARY SCHOOL",
    Address: "NEXT TO TRIBAL OFFICE  DRYHARTS VILLAGE  GREATER TAUNG",
    Latitude: "-27.3396",
    Longitude: "24.7283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20138",
    WardLookupId: "2447",
    VDNumber: "86742322",
    RegisteredPopulation: "820",
    VotingStationName: "MOLEMOENG PRIMARY SCHOOL",
    Address: "END OF MAIN ROAD  DRYHARTS VILLAGE  GREATER TAUNG",
    Latitude: "-27.3283",
    Longitude: "24.7352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20139",
    WardLookupId: "2447",
    VDNumber: "86743109",
    RegisteredPopulation: "726",
    VotingStationName: "MOLEHABANGWE MIDDLE SCHOOL",
    Address: "0 NEXT TO RAILWAY LINE  DRYHARTS VILLAGE  GREATER TAUNG",
    Latitude: "-27.3218",
    Longitude: "24.7285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20140",
    WardLookupId: "2447",
    VDNumber: "86743132",
    RegisteredPopulation: "344",
    VotingStationName: "RADITSHIDI PRIMARY SCHOOL",
    Address: "0 NEXT TO KGOTLA  MYRA  GREATER TAUNG [REIVILO]",
    Latitude: "-27.3828",
    Longitude: "24.7691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20141",
    WardLookupId: "2447",
    VDNumber: "86743389",
    RegisteredPopulation: "290",
    VotingStationName: "TSHIMOLOGO METHODIST CHURCH",
    Address: "NEXT TO RAILWAY LINE  MYRA VILLAGE  GREATER TAUNG",
    Latitude: "-27.3784",
    Longitude: "24.7688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20142",
    WardLookupId: "2448",
    VDNumber: "86740702",
    RegisteredPopulation: "814",
    VotingStationName: "SEBEGILWE MIDDLE SCHOOL",
    Address: "NEXT TO MAIN ROAD  MATLHAKO II VILLAGE  GREATER TAUNG",
    Latitude: "-27.3942",
    Longitude: "24.7141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20143",
    WardLookupId: "2448",
    VDNumber: "86743121",
    RegisteredPopulation: "671",
    VotingStationName: "THAGAMOSO PRIMARY SCHOOL",
    Address: "0 NEXT TO RAILWAY LINE  MATLHAKO II-PUDIMOE  GREATER TAUNG",
    Latitude: "-27.394",
    Longitude: "24.7174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20144",
    WardLookupId: "2448",
    VDNumber: "86810014",
    RegisteredPopulation: "2014",
    VotingStationName: "MAATLA SECONDARY SCHOOL",
    Address: "PUDUMONG  GREATER TAUNG  BA-GA-PHUDUHUCWANA TRADITIONAL COUNCIL",
    Latitude: "-27.4133",
    Longitude: "24.7239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20145",
    WardLookupId: "2448",
    VDNumber: "86810025",
    RegisteredPopulation: "761",
    VotingStationName: "MATLHAKO1 COMMUNITY HALL",
    Address: "0 NEXT TO KGOTLA  MATLHAKO  1  GREATER TAUNG",
    Latitude: "-27.4062",
    Longitude: "24.7267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20146",
    WardLookupId: "2449",
    VDNumber: "86740623",
    RegisteredPopulation: "852",
    VotingStationName: "MORWALELA PRIMARY SCHOOL",
    Address: "NEXT TO CLINIC  MOGOPELA B VILLAGE  GREATER TAUNG",
    Latitude: "-27.4167",
    Longitude: "24.7612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20147",
    WardLookupId: "2449",
    VDNumber: "86740634",
    RegisteredPopulation: "861",
    VotingStationName: "MOGOPELA PRIMARY SCHOOL",
    Address:
      "NEXT TO MAIN ROAD TO COKONYANE  MOGOPELA A VILLAGE  GREATER TAUNG",
    Latitude: "-27.4649",
    Longitude: "24.7421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20148",
    WardLookupId: "2449",
    VDNumber: "86740713",
    RegisteredPopulation: "1085",
    VotingStationName: "MYRA PRIMARY SCHOOL",
    Address: "NEXT TO MAIN ROAD  MYRA VILLAGE  GREATER TAUNG",
    Latitude: "-27.3969",
    Longitude: "24.7675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20149",
    WardLookupId: "2449",
    VDNumber: "86743187",
    RegisteredPopulation: "669",
    VotingStationName: "KEIKANAMANG PRIMARY SCHOOL",
    Address: "0 NEXT TO MAIN ROAD  COKONYANE  GREATER TAUNG",
    Latitude: "-27.4824",
    Longitude: "24.7964",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20150",
    WardLookupId: "2449",
    VDNumber: "86743840",
    RegisteredPopulation: "50",
    VotingStationName: "KHIBITSWANE EXTENSION",
    Address:
      "KHIBITSWANE VILLAGE  TAUNG CENTRAL  BA-GA-PHUDUHUCWANA TRADITIONAL COUNCIL",
    Latitude: "-27.5098",
    Longitude: "24.7666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20151",
    WardLookupId: "2450",
    VDNumber: "86740645",
    RegisteredPopulation: "957",
    VotingStationName: "LESHOBO MIDDLE SCHOOL",
    Address: "NEXT TO LESHOBO CLINIC  LESHOBO VILLAGE  GREATER TAUNG",
    Latitude: "-27.4789",
    Longitude: "24.6996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20152",
    WardLookupId: "2450",
    VDNumber: "86740724",
    RegisteredPopulation: "908",
    VotingStationName: "TLHARESENG PRIMARY SCHOOL",
    Address:
      "NEXT TO MAIN ROAD TO MATLAPANENG  MOKGARENG VILLAGE  GREATER TAUNG",
    Latitude: "-27.5165",
    Longitude: "24.7006",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20153",
    WardLookupId: "2450",
    VDNumber: "86740735",
    RegisteredPopulation: "887",
    VotingStationName: "NTOKWE PRIMARY SCHOOL",
    Address: "NEXT TO CLINIC  MOKGARENG VILLAGE  BOPHIRIMA DISTRICT",
    Latitude: "-27.5318",
    Longitude: "24.7053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20154",
    WardLookupId: "2450",
    VDNumber: "86740746",
    RegisteredPopulation: "731",
    VotingStationName: "SETSHWARAPELO PRIMARY SCHOOL",
    Address: "NEXT TO MAIN ROAD  MOKGARENG VILLAGE  GREATER TAUNG",
    Latitude: "-27.5511",
    Longitude: "24.6999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20155",
    WardLookupId: "2450",
    VDNumber: "86743626",
    RegisteredPopulation: "397",
    VotingStationName: "BETHSEDA HEALING FAITH MISSION",
    Address:
      "MOKGARENG VILLAGE  GREATER TAUNG MUNICIPALITY  BA-GA-PHUDUHUCWANA TRIBAL AUTHORITY",
    Latitude: "-27.5527",
    Longitude: "24.6939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20156",
    WardLookupId: "2451",
    VDNumber: "86740768",
    RegisteredPopulation: "819",
    VotingStationName: "MAROPE PRIMARY SCHOOL",
    Address: "NEXT TO METHODIST CHURCH  ROIWAAL VILLAGE  GREATER TAUNG",
    Latitude: "-27.5667",
    Longitude: "24.7266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20157",
    WardLookupId: "2451",
    VDNumber: "86740780",
    RegisteredPopulation: "1158",
    VotingStationName: "THUSO PRIMARY SCHOOL",
    Address: "NEXT TO KGOTLA  LOKGABENG VILLAGE  GREATER TAUNG",
    Latitude: "-27.5638",
    Longitude: "24.6912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20158",
    WardLookupId: "2451",
    VDNumber: "86742388",
    RegisteredPopulation: "1182",
    VotingStationName: "GOITSEONE MANKUROANE PRIMARY SCHOOL",
    Address:
      "EXTENTION 6  TAUNG STATION  BA-GA-PHUDUHUCWANA TRADITIONAL COUNCIL",
    Latitude: "-27.5613",
    Longitude: "24.7574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20159",
    WardLookupId: "2451",
    VDNumber: "86743086",
    RegisteredPopulation: "182",
    VotingStationName: "LETLHAPONG EARLY LEARNING CENTRE",
    Address: "0 NEXT TO KGOTLA  LETLHAPONG  GREATER TAUNG",
    Latitude: "-27.5737",
    Longitude: "24.5425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20160",
    WardLookupId: "2451",
    VDNumber: "86743154",
    RegisteredPopulation: "441",
    VotingStationName: "SPIRIT OF HOLINESS CHURCH IN AFRICA",
    Address: "0 NEXT MAIN ROAD  LOKGABENG  GREATER TAUNG",
    Latitude: "-27.5671",
    Longitude: "24.6844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20161",
    WardLookupId: "2451",
    VDNumber: "86743165",
    RegisteredPopulation: "422",
    VotingStationName: "PELONNGWE PRIMARY SCHOOL",
    Address: "0 NEXT TO MAIN ROAD  TAKANENG  GREATER TAUNG",
    Latitude: "-27.5873",
    Longitude: "24.6163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20162",
    WardLookupId: "2451",
    VDNumber: "86743402",
    RegisteredPopulation: "95",
    VotingStationName: "CHIEF FM MONCHWE",
    Address: "NEXT TO REIVILO ROAD  GASEBUSHO VILLAGE  GREATER TAUNG",
    Latitude: "-27.5502",
    Longitude: "24.5517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20163",
    WardLookupId: "2451",
    VDNumber: "86743659",
    RegisteredPopulation: "45",
    VotingStationName: "MD MOKGOSI",
    Address:
      "DIRETSANENG VILLAGE  GREATER TAUNG MUNICIPALITY  BAPHUDUHUCWANA TRIBAL COUNCIL",
    Latitude: "-27.5832",
    Longitude: "24.6406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20164",
    WardLookupId: "2452",
    VDNumber: "86740814",
    RegisteredPopulation: "826",
    VotingStationName: "LEKWENE PRIMARY SCHOOL",
    Address: "0 NEXT ROMAN CATHOLIC CHURCH  BUXTON VILLAGE  GREATER TAUNG",
    Latitude: "-27.6256",
    Longitude: "24.638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20165",
    WardLookupId: "2452",
    VDNumber: "86740825",
    RegisteredPopulation: "502",
    VotingStationName: "GANOKE PRIMARY SCHOOL",
    Address: "NEXT TO RIVER  TAMASIKWA  GREATER TAUNG",
    Latitude: "-27.6098",
    Longitude: "24.612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20166",
    WardLookupId: "2452",
    VDNumber: "86740847",
    RegisteredPopulation: "1044",
    VotingStationName: "HARTSRIVIER PRIMARY SCHOOL",
    Address: "NEXT TO MAIN ROAD  MOCWEDING VILLAGE  GREATER TAUNG",
    Latitude: "-27.6928",
    Longitude: "24.6692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20167",
    WardLookupId: "2452",
    VDNumber: "86742399",
    RegisteredPopulation: "436",
    VotingStationName: "AGOSI MABATSANE EARLY LEARNING CENTER",
    Address:
      "TLAPENG VILLAGE  GREATER TAUNG  BA-GA-MOTHIBI TRADITIONAL COUNCIL",
    Latitude: "-27.7335",
    Longitude: "24.6865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20168",
    WardLookupId: "2452",
    VDNumber: "86743266",
    RegisteredPopulation: "460",
    VotingStationName: "UNITED CONGREGATIONAL CHURCH OF SA",
    Address:
      "0 NEXT TO MAIN ROAD  MOTLHABENG(MOCWEDING)  BAPHUDUHUCWANA TRADITIONAL COUNCIL",
    Latitude: "-27.6662",
    Longitude: "24.6722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20169",
    WardLookupId: "2452",
    VDNumber: "86743356",
    RegisteredPopulation: "712",
    VotingStationName: "PELOTSHWEU MIDDLE SCHOOL",
    Address: "0 NEXT TO MAIN ROAD  BUXTON  GREATER TAUNG",
    Latitude: "-27.6336",
    Longitude: "24.6394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20170",
    WardLookupId: "2452",
    VDNumber: "86743457",
    RegisteredPopulation: "67",
    VotingStationName: "LG SETLETSE (HOUSE)",
    Address: "THOMENG VILLAGE  TAUNG  GREATER TAUNG",
    Latitude: "-27.6962",
    Longitude: "24.6037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20171",
    WardLookupId: "2453",
    VDNumber: "86740599",
    RegisteredPopulation: "146",
    VotingStationName: "MAKAME PRIMARY SCHOOL",
    Address: "NEXT TO CHIEFS HOUSE  HELLENSPAN VILLAGE  GREATER TAUNG",
    Latitude: "-27.421",
    Longitude: "24.8307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20172",
    WardLookupId: "2453",
    VDNumber: "86740601",
    RegisteredPopulation: "1543",
    VotingStationName: "MARUBISING SECONDARY SCHOOL",
    Address: "NEXT TO MAIN ROAD TO MODIMONG  COKONYANE VILLAGE  GREATER TAUNG",
    Latitude: "-27.49",
    Longitude: "24.8",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20173",
    WardLookupId: "2453",
    VDNumber: "86740612",
    RegisteredPopulation: "705",
    VotingStationName: "SAKU PRIMARY SCHOOL",
    Address: "NEXT MODIMONG TRADING STORE  MODIMONG  GREATER TAUNG",
    Latitude: "-27.45",
    Longitude: "24.8855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20174",
    WardLookupId: "2453",
    VDNumber: "86742344",
    RegisteredPopulation: "311",
    VotingStationName: "BECHUANA METHODIST CHURCH",
    Address: "MODIMONG VILLAGE  TAUNG  BA GA PHUDUHUTSWANA",
    Latitude: "-27.4221",
    Longitude: "24.8955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20175",
    WardLookupId: "2453",
    VDNumber: "86743176",
    RegisteredPopulation: "511",
    VotingStationName: "EBETSAMANG PRIMARY SCHOOL",
    Address: "NEXT TO MAIN ROAD  MODIMONG VILLAGE  GREATER TAUNG",
    Latitude: "-27.4534",
    Longitude: "24.8704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20176",
    WardLookupId: "2453",
    VDNumber: "86743435",
    RegisteredPopulation: "63",
    VotingStationName: "G.J MOTLHASI",
    Address: "MADIBANENG  TAUNG  MADIBANENG VILLAGE",
    Latitude: "-27.4784",
    Longitude: "24.8749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20177",
    WardLookupId: "2453",
    VDNumber: "86743446",
    RegisteredPopulation: "174",
    VotingStationName: "SF MOLATLHEGI",
    Address: "SETLHABENG VILLAGE  TAUNG  SETLHABENG VILLAGE",
    Latitude: "-27.4641",
    Longitude: "24.9029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20178",
    WardLookupId: "2454",
    VDNumber: "86740443",
    RegisteredPopulation: "952",
    VotingStationName: "MMABANA CULTURAL CENTRE",
    Address: "NEXT TO MAIN ROAD  VEERTIEN/THOTENG  GREATER TAUNG",
    Latitude: "-27.5364",
    Longitude: "24.7741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20179",
    WardLookupId: "2454",
    VDNumber: "86740454",
    RegisteredPopulation: "1274",
    VotingStationName: "KGOSIETSILE LETHOLA HIGH SCHOOL",
    Address: "NEXT TO SEILEPRIMSCH AND CHURCH  LOKALENG VILLAGE  GREATER TAUNG",
    Latitude: "-27.5247",
    Longitude: "24.764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20180",
    WardLookupId: "2454",
    VDNumber: "86740465",
    RegisteredPopulation: "814",
    VotingStationName: "LESANG KASIENYANE PRIMARY SCHOOL",
    Address: "NEXT TO PRE SCHOOL  KHIBITSWANE VILLAGE  GREATER TAUNG",
    Latitude: "-27.5134",
    Longitude: "24.7715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20181",
    WardLookupId: "2455",
    VDNumber: "86740409",
    RegisteredPopulation: "836",
    VotingStationName: "NHOLE PRIMARY SCHOOL",
    Address: "NHOLE VILLAGE  BOPHIRIMA DISTRICT",
    Latitude: "-27.5379",
    Longitude: "24.8044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20182",
    WardLookupId: "2455",
    VDNumber: "86741927",
    RegisteredPopulation: "1077",
    VotingStationName: "BAISITSE PRIMARY SCHOOL",
    Address: "NEXT TO MAIN ROAD  MANOKWANE VILLAGE  GREATER TAUNG",
    Latitude: "-27.5586",
    Longitude: "24.8003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20183",
    WardLookupId: "2455",
    VDNumber: "86742355",
    RegisteredPopulation: "249",
    VotingStationName: "MOKGOSI PRIMARY SCHOOL",
    Address: "NEXT TO MAIN ROAD  LOTHWANYENG  GREATER TAUNG",
    Latitude: "-27.5942",
    Longitude: "24.8275",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20184",
    WardLookupId: "2455",
    VDNumber: "86742377",
    RegisteredPopulation: "688",
    VotingStationName: "NTHAPELANG PRIMARY SCHOOL",
    Address: "NEXT TO MAIN ROAD  MATOLONG VILLAGE  GREATER TAUNG",
    Latitude: "-27.582",
    Longitude: "24.7533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20185",
    WardLookupId: "2455",
    VDNumber: "86743200",
    RegisteredPopulation: "642",
    VotingStationName: "KUANENG (TENT)",
    Address:
      "KUANENG  MANOKWANE VILLAGE  BA-GA-PHUDUHUCWANA TRADITIONAL COUNCIL",
    Latitude: "-27.5508",
    Longitude: "24.8081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20186",
    WardLookupId: "2455",
    VDNumber: "86743558",
    RegisteredPopulation: "298",
    VotingStationName: "ST PAULS PRIMARY SCHOOL",
    Address: "NUMBER 1 VILLAGE  TAUNG  TAUNG",
    Latitude: "-27.5375",
    Longitude: "24.7971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20187",
    WardLookupId: "2456",
    VDNumber: "86740410",
    RegisteredPopulation: "593",
    VotingStationName: "ST PAULS HIGH SCHOOL",
    Address: "0 ROMAN CATHOLIC CHURCH  NOMMER ONE VILLAGE  GREATER TAUNG",
    Latitude: "-27.5377",
    Longitude: "24.7929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20188",
    WardLookupId: "2456",
    VDNumber: "86740421",
    RegisteredPopulation: "1203",
    VotingStationName: "RABODIGELO PRIMARY SCHOOL",
    Address: "NEXT THATE MOLATLHWA SCHOOL  KGATLENG VILLAGE  GREATER TAUNG",
    Latitude: "-27.5308",
    Longitude: "24.7823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20189",
    WardLookupId: "2456",
    VDNumber: "86740476",
    RegisteredPopulation: "1086",
    VotingStationName: "BAPHUDUHUCWANA TRADITIONAL COUNCIL HALL",
    Address:
      "CHIEFS COURT AREA  CHIEFS COURT/KGOSING  BAPHUDUHUCWANA TRADITIONAL COUNCIL",
    Latitude: "-27.5258",
    Longitude: "24.7922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20190",
    WardLookupId: "2456",
    VDNumber: "86743211",
    RegisteredPopulation: "358",
    VotingStationName: "THE CHURCH OF FIRST BORN",
    Address: "0 NEXT TO BUTCHERY  LOKALENG  GREATER TAUNG",
    Latitude: "-27.5271",
    Longitude: "24.7717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20191",
    WardLookupId: "2456",
    VDNumber: "86743222",
    RegisteredPopulation: "545",
    VotingStationName: "OLD ANGLICAN CHURCH MISSION HOUSE",
    Address: "VEERTIEN VILLAGE  TAUNG",
    Latitude: "-27.5373",
    Longitude: "24.7791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20192",
    WardLookupId: "2456",
    VDNumber: "86743637",
    RegisteredPopulation: "307",
    VotingStationName: "PINAGARE HIGH SCHOOL",
    Address:
      "PINAGARE VILLAGE  GREATER TAUNG MUNICIPALITY  BA-GA-PHUDUHUCWANA TRIBAL AUTHORITY",
    Latitude: "-27.5405",
    Longitude: "24.7746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20193",
    WardLookupId: "2457",
    VDNumber: "86740375",
    RegisteredPopulation: "695",
    VotingStationName: "BA-GA-MAIDI TRADITIONAL COUNCIL OFFICE",
    Address: "MANTHESTAD  GREATER TAUNG  BA-GA-MAIDI TRADITIONAL COUNCIL",
    Latitude: "-27.5453",
    Longitude: "24.8846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20194",
    WardLookupId: "2457",
    VDNumber: "86740386",
    RegisteredPopulation: "566",
    VotingStationName: "TSHIPO MANGWEGAPE PRIMARY SCHOOL",
    Address: "NEXT TO MAIN ROAD TO MANTHE  RANSTAD VILLAGE  GREATER TAUNG",
    Latitude: "-27.5454",
    Longitude: "24.8198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20195",
    WardLookupId: "2457",
    VDNumber: "86743198",
    RegisteredPopulation: "303",
    VotingStationName: "PHATLHANYANE PRIMARY SCHOOL",
    Address: "0 NEXT TO MAIN ROAD  KOLONG  GREATER TAUNG",
    Latitude: "-27.5436",
    Longitude: "24.836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20196",
    WardLookupId: "2457",
    VDNumber: "86743277",
    RegisteredPopulation: "810",
    VotingStationName: "REATLEGILE METHODIST CHURCH OF SA",
    Address: "0 NEXT TO BEER HALL  MANTHESTAD  GREATER TAUNG",
    Latitude: "-27.5491",
    Longitude: "24.891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20197",
    WardLookupId: "2457",
    VDNumber: "86743288",
    RegisteredPopulation: "713",
    VotingStationName: "KEBINELANG MIDDLE SCHOOL",
    Address: "NEXT TO MAIN ROAD  MANTHE  GREATER TAUNG",
    Latitude: "-27.5438",
    Longitude: "24.9005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20198",
    WardLookupId: "2457",
    VDNumber: "86743514",
    RegisteredPopulation: "323",
    VotingStationName: "SERASENGWE PRIMARY SCHOOL",
    Address: "SEODI PARK  TAUNG  TLAPENG",
    Latitude: "-27.5409",
    Longitude: "24.9656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20199",
    WardLookupId: "2458",
    VDNumber: "86740230",
    RegisteredPopulation: "741",
    VotingStationName: "INDEPENDENT CONGREGATIONAL CHURCH - MOLELEMA",
    Address:
      "NEXT TO THE MAIN ROAD  MOLELEMA VILLGE  BA-GA-MAIDI TRADITIONAL COUNCIL",
    Latitude: "-27.6571",
    Longitude: "25.0066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20200",
    WardLookupId: "2458",
    VDNumber: "86740241",
    RegisteredPopulation: "1360",
    VotingStationName: "MOLELEMA PRIMARY SCHOOL",
    Address: "MOLELEMA  TAUNG  BA-GA-MAIDI TRADITIONAL COUNCIL",
    Latitude: "-27.6548",
    Longitude: "24.9968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20201",
    WardLookupId: "2458",
    VDNumber: "86740263",
    RegisteredPopulation: "696",
    VotingStationName: "BA-GA- MAIDI PRIMARY SCHOOL",
    Address: "NEXT TO MAI ROAD  KHUDUTLOU VILLAGE  GREATER TAUNG",
    Latitude: "-27.6633",
    Longitude: "25.0523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20202",
    WardLookupId: "2458",
    VDNumber: "86743299",
    RegisteredPopulation: "314",
    VotingStationName: "PHOLOHOLO PRIMARY SCHOOL",
    Address: "0 NEXT TO MAIN ROAD  VERGRNOEG(MOLELEMA VILL)  GREATER TAUNG",
    Latitude: "-27.6386",
    Longitude: "24.9802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20203",
    WardLookupId: "2459",
    VDNumber: "86740274",
    RegisteredPopulation: "1085",
    VotingStationName: "TSHWARANG THATA PRIMARY SCHOOL",
    Address: "NEXT TO MAIN ROAD  LONGANENG VILLAGE  GREATER TAUNG",
    Latitude: "-27.6258",
    Longitude: "24.9466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20204",
    WardLookupId: "2459",
    VDNumber: "86740285",
    RegisteredPopulation: "694",
    VotingStationName: "GOBOPAMANG PRIMARY SCHOOL",
    Address: "NEXT TO MAIN ROAD  MOTHANTHANYANENG  GREATER TAUNG",
    Latitude: "-27.6609",
    Longitude: "24.907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20205",
    WardLookupId: "2459",
    VDNumber: "86740342",
    RegisteredPopulation: "444",
    VotingStationName: "JOSEPH SAKU MIDDLE SCHOOL",
    Address: "NEXT TO MAIN ROAD  MAGOGONG VILLAGE  GREATER TAUNG",
    Latitude: "-27.6598",
    Longitude: "24.8084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20206",
    WardLookupId: "2459",
    VDNumber: "86740364",
    RegisteredPopulation: "495",
    VotingStationName: "POLOKOETSILE PRIMARY SCHOOL",
    Address: "NEXT TO MAIN ROAD  MOROKWENG  VILLAGE  GREATER TAUNG",
    Latitude: "-27.5815",
    Longitude: "24.9421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20207",
    WardLookupId: "2459",
    VDNumber: "86741893",
    RegisteredPopulation: "626",
    VotingStationName: "PITSONG PRIMARY SCHOOL",
    Address: "NEXT TO ROMAN CATHOLIC CHURCH  PITSONG VILLAGE  GREATER TAUNG",
    Latitude: "-27.5901",
    Longitude: "24.8631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20208",
    WardLookupId: "2459",
    VDNumber: "86742412",
    RegisteredPopulation: "382",
    VotingStationName: "KEEMEMANG PRIMARY SCHOOL",
    Address: "NEXT TO MAIN ROAD  GRASPAN VILLAGE  GREATER TAUNG",
    Latitude: "-27.6692",
    Longitude: "24.8757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20209",
    WardLookupId: "2459",
    VDNumber: "86742423",
    RegisteredPopulation: "357",
    VotingStationName: "BOIKUTLO PRIMARY SCHOOL",
    Address: "NEXT TO KGOTLA  DIKHUTING VILLAGE  GREATER TAUNG",
    Latitude: "-27.636",
    Longitude: "24.8571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20210",
    WardLookupId: "2459",
    VDNumber: "86743648",
    RegisteredPopulation: "24",
    VotingStationName: "LJ MOSHI (TENT)",
    Address: "GAMOKAKE  GREATER TAUNG  BA GA MAIDI",
    Latitude: "-27.6025",
    Longitude: "24.8428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20211",
    WardLookupId: "2460",
    VDNumber: "86740319",
    RegisteredPopulation: "803",
    VotingStationName: "MAPHOITSILE COMMUNITY HALL",
    Address:
      "MAPHOITSILE VILLAGE  GREATER TAUNG MUNICIPALITY  BA-GA-PHUDUHUCWANA TRADITIONAL COUNCIL",
    Latitude: "-27.6131",
    Longitude: "24.7846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20212",
    WardLookupId: "2460",
    VDNumber: "86740320",
    RegisteredPopulation: "1159",
    VotingStationName: "MAPHOITSILE PRIMARY SCHOOL",
    Address: "MAPHOITSILE  GREATER TAUNG",
    Latitude: "-27.5991",
    Longitude: "24.7896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20213",
    WardLookupId: "2460",
    VDNumber: "86740331",
    RegisteredPopulation: "781",
    VotingStationName: "OLEBILE PRIMARY SCHOOL",
    Address: "MAGOGONG VILLAGE  TAUNG  GREATER TAUNG",
    Latitude: "-27.6349",
    Longitude: "24.8142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20214",
    WardLookupId: "2460",
    VDNumber: "86740353",
    RegisteredPopulation: "939",
    VotingStationName: "SEDIBATHUTO PRIMARY SCHOOL",
    Address: "MAGOGONG  MAGOGONG VILLAGE  GREATER TAUNG",
    Latitude: "-27.6529",
    Longitude: "24.8131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20215",
    WardLookupId: "2460",
    VDNumber: "86743525",
    RegisteredPopulation: "350",
    VotingStationName: "NATIVE INDEPENDENT CONGREGATIONAL CHUR - GAMODUANA",
    Address: "GAMODUANA VILLAGE  TAUNG",
    Latitude: "-27.6261",
    Longitude: "24.797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20216",
    WardLookupId: "2461",
    VDNumber: "86740296",
    RegisteredPopulation: "1352",
    VotingStationName: "GALESHEWE PRIMARY SCHOOL",
    Address: "MODUTUNG VILLAGE  BOPHIRIMA DISTRICT",
    Latitude: "-27.6839",
    Longitude: "24.8246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20217",
    WardLookupId: "2461",
    VDNumber: "86742434",
    RegisteredPopulation: "1813",
    VotingStationName: "KOPANANG PRIMARY SCHOOL",
    Address: "NEXT TO CLINIC  DIPLANKENG VILLAGE  GREATER TAUNG",
    Latitude: "-27.6598",
    Longitude: "24.7997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20218",
    WardLookupId: "2461",
    VDNumber: "86743301",
    RegisteredPopulation: "669",
    VotingStationName: "APOSTOLIC FAITH MISSION OF SA",
    Address: "0 NEXT TO MAIN ROAD  MAGOGONG  GREATER TAUNG",
    Latitude: "-27.662",
    Longitude: "24.8131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20219",
    WardLookupId: "2462",
    VDNumber: "86740858",
    RegisteredPopulation: "1113",
    VotingStationName: "MMATLAKITSO PRIMARY SCHOOL",
    Address: "NEXT TO MAIN ROAD  LOWER MAJEAKGORO VILLAGE  BOPHIRIMA",
    Latitude: "-27.7753",
    Longitude: "24.6579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20220",
    WardLookupId: "2462",
    VDNumber: "86741758",
    RegisteredPopulation: "915",
    VotingStationName: "RELEMOGILE MIDDLE SCHOOL",
    Address: "NEXT TO ROMAN CATHOLIC CHURCH  UPPER MAJEAKGORO  GREATER TAUNG",
    Latitude: "-27.8021",
    Longitude: "24.6196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20221",
    WardLookupId: "2462",
    VDNumber: "86743244",
    RegisteredPopulation: "324",
    VotingStationName: "ST JOHN APOSTOLIC FAITH MISSION",
    Address: "0 NEXT TO MAIN ROAD  LOWER MAJEAKGORO  GREATER TAUNG",
    Latitude: "-27.7685",
    Longitude: "24.6721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20222",
    WardLookupId: "2462",
    VDNumber: "86743255",
    RegisteredPopulation: "943",
    VotingStationName: "TLHABOLOGANG COMMUNITY HALL",
    Address: "0 NEXT TO CLINIC  LOWER MAJEAKGORO  GREATER TAUNG",
    Latitude: "-27.7718",
    Longitude: "24.6681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20223",
    WardLookupId: "2462",
    VDNumber: "86743806",
    RegisteredPopulation: "81",
    VotingStationName: "MASHATARA TENT",
    Address: "LOWER-MAJEAKGORO  TAUNG  BA-GA-MOTHIBI TRADITIONAL COUNCIL",
    Latitude: "-27.7973",
    Longitude: "24.6642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20224",
    WardLookupId: "2463",
    VDNumber: "86740915",
    RegisteredPopulation: "343",
    VotingStationName: "ETEBARE PRIMARY SCHOOL",
    Address: "NEXT TO MAIN ROAD  THOTA YA TAU  GREATER TAUNG",
    Latitude: "-28.0577",
    Longitude: "24.5468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20225",
    WardLookupId: "2463",
    VDNumber: "86740937",
    RegisteredPopulation: "780",
    VotingStationName: "MULTI PURPOSE CENTRE",
    Address: "LOSASANENG VILLAGE  TAUNG  BA-GA-MOTHIBI TRADITIONAL COUNCIL",
    Latitude: "-28.0257",
    Longitude: "24.5318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20226",
    WardLookupId: "2463",
    VDNumber: "86740948",
    RegisteredPopulation: "1017",
    VotingStationName: "KGOMOTSO TRADITIONAL COUNCIL HALL",
    Address: "NEXT TO MAIN ROAD  KGOMOTSO VILLAGE  TAUNG DISTRICT",
    Latitude: "-27.9802",
    Longitude: "24.6068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20227",
    WardLookupId: "2463",
    VDNumber: "86742467",
    RegisteredPopulation: "157",
    VotingStationName: "MMADITHAMAGA PRIMARY SCHOOL",
    Address:
      "NEXT TO MAIN ROAD  GREATER TAUNG  BA-GA-MOTHIBI TRADITIONAL COUNCIL",
    Latitude: "-28.001",
    Longitude: "24.5557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20228",
    WardLookupId: "2463",
    VDNumber: "86743323",
    RegisteredPopulation: "671",
    VotingStationName: "THUTO-NEO PRIMARY SCHOOL",
    Address: "NEXT TO POLICE STATION  KGOMOTSO VILILAGE  GREATER TAUNG",
    Latitude: "-27.9818",
    Longitude: "24.6117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20229",
    WardLookupId: "2463",
    VDNumber: "86743345",
    RegisteredPopulation: "426",
    VotingStationName: "TSHABELANG PRIMARY SCHOOL",
    Address: "0 NEXT TO MAIN ROAD  KAMEELPUTS  GREATER TAUNG",
    Latitude: "-28.0407",
    Longitude: "24.5263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20230",
    WardLookupId: "2464",
    VDNumber: "86740869",
    RegisteredPopulation: "672",
    VotingStationName: "BA-GA-MOTHIBI TRADITIONAL COUNCIL OFFICE",
    Address: "NEXT TO MAIN-ROAD  SEKHING VILLAGE  GREATER TAUNG",
    Latitude: "-27.8617",
    Longitude: "24.624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20231",
    WardLookupId: "2464",
    VDNumber: "86740959",
    RegisteredPopulation: "354",
    VotingStationName: "BAKANG PRIMARY SCHOOL",
    Address: "TAUNG  RIETFONTEIN VILLAGE  GREATER TAUNG",
    Latitude: "-27.8429",
    Longitude: "24.4845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20232",
    WardLookupId: "2464",
    VDNumber: "86742445",
    RegisteredPopulation: "1111",
    VotingStationName: "SEKHING PRIMARY SCHOOL",
    Address: "NEXT TO CLINIC  SEKHING VILLAGE  GREATER TAUNG",
    Latitude: "-27.8425",
    Longitude: "24.6288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20233",
    WardLookupId: "2464",
    VDNumber: "86743312",
    RegisteredPopulation: "948",
    VotingStationName: "REBONE SEDIBA INTERMEDIATE SCHOOL",
    Address: "0 NEXT TO KGOTLA  SEODING  GREATER TAUNG",
    Latitude: "-27.8806",
    Longitude: "24.6096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20234",
    WardLookupId: "2465",
    VDNumber: "86740072",
    RegisteredPopulation: "583",
    VotingStationName: "UCCSA - MADIPELESA",
    Address: "MADIPELESA  BA-GA-MOTHIBI",
    Latitude: "-27.9",
    Longitude: "24.3996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20235",
    WardLookupId: "2465",
    VDNumber: "86740904",
    RegisteredPopulation: "922",
    VotingStationName: "MMAMUTLA PRIMARY SCHOOL",
    Address: "NEXT TO MAIN ROAD  MMAMUTLA VILLAGE  GREATER TAUNG",
    Latitude: "-28.0648",
    Longitude: "24.5123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20236",
    WardLookupId: "2465",
    VDNumber: "86740926",
    RegisteredPopulation: "661",
    VotingStationName: "GATAOTE PRIMARY SCHOOL",
    Address: "NEXT TO CLINIC  GATAOTE VILLAGE  GREATER TAUNG",
    Latitude: "-28.0242",
    Longitude: "24.4969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20237",
    WardLookupId: "2465",
    VDNumber: "86742456",
    RegisteredPopulation: "757",
    VotingStationName: "SHALENG TRADITIONAL COUNCIL HALL",
    Address: "NEXT TO MAIN ROAD  SHALENG VILLAGE  GREATER TAUNG",
    Latitude: "-27.9082",
    Longitude: "24.4621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20238",
    WardLookupId: "2465",
    VDNumber: "86743334",
    RegisteredPopulation: "500",
    VotingStationName: "HOLY CROSS ROMAN CATHOLIC CHURCH",
    Address: "0 NEXT TO MAIN ROAD  MADIPELESA  GREATER TAUNG",
    Latitude: "-27.8998",
    Longitude: "24.4075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20239",
    WardLookupId: "2466",
    VDNumber: "86740218",
    RegisteredPopulation: "749",
    VotingStationName: "ST ANTHONY ROMAN CATHOLIC CHURCH",
    Address: "41E TLAPENG KGORI  TLAPENG  GREATER TAUNG",
    Latitude: "-27.5283",
    Longitude: "24.9517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20240",
    WardLookupId: "2466",
    VDNumber: "86740229",
    RegisteredPopulation: "746",
    VotingStationName: "MATSHENG PRIMARY SCHOOL",
    Address: "MATSHENG VILLAGE  MATSHENG  GREATER TAUNG",
    Latitude: "-27.5617",
    Longitude: "25.0071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20241",
    WardLookupId: "2466",
    VDNumber: "86740252",
    RegisteredPopulation: "917",
    VotingStationName: "KOKOMENG PRIMARY SCHOOL",
    Address: "NEXT TO WATER RESERVOIR  KOKOMENG VILLAGE  GREATER TAUNG",
    Latitude: "-27.6253",
    Longitude: "25.0485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20242",
    WardLookupId: "2466",
    VDNumber: "86742401",
    RegisteredPopulation: "196",
    VotingStationName: "ST ELIZABETH ROMAN CATHOLIC CHURCH",
    Address: "NEXT TO KGOTLA  TLAPENG YO SERASENGWE  GREATER TAUNG",
    Latitude: "-27.4862",
    Longitude: "24.9605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20243",
    WardLookupId: "2466",
    VDNumber: "86743660",
    RegisteredPopulation: "569",
    VotingStationName: "MAKGETHE MIDDLE SCHOOL",
    Address:
      "MATSHENG VILLAGE  GREATER TAUNG MUNICIPALITY  BA-GA-MAIDI TRIBAL AUTHORITY",
    Latitude: "-27.5656",
    Longitude: "25.0008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20244",
    WardLookupId: "2467",
    VDNumber: "86740757",
    RegisteredPopulation: "1165",
    VotingStationName: "REIKAGILE MIDDLE SCHOOL",
    Address: "NEXT ROMAN CATHOLIC CHURCH  MOKASSA II VILLAGE  GREATER TAUNG",
    Latitude: "-27.6017",
    Longitude: "24.6773",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20245",
    WardLookupId: "2467",
    VDNumber: "86740791",
    RegisteredPopulation: "642",
    VotingStationName: "MOKASSA 1 PRIMARY SCHOOL",
    Address: "NEXT TO CLINIC  MOKASSA1 VILLAGE  GREATER TAUNG",
    Latitude: "-27.618",
    Longitude: "24.7231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20246",
    WardLookupId: "2467",
    VDNumber: "86742366",
    RegisteredPopulation: "189",
    VotingStationName: "SAVIOUR APOSTOLIC CHURCH.",
    Address: "MOKASSA I  TAUNG  GREATER TAUNG",
    Latitude: "-27.5833",
    Longitude: "24.7277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20247",
    WardLookupId: "2467",
    VDNumber: "86742614",
    RegisteredPopulation: "585",
    VotingStationName: "METHODIST CHURCH OF SOUTH AFRICA",
    Address: "NEXT TOLIVESTOCK DIPPING POST  MOKASSA II VILLAGE  GREATER TAUNG",
    Latitude: "-27.6237",
    Longitude: "24.6747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20248",
    WardLookupId: "2467",
    VDNumber: "86743143",
    RegisteredPopulation: "675",
    VotingStationName: "NATIVE INDEPENDENT CONGREGATIONAL CHURCH OF SA",
    Address: "0 NEXT TO KGOTLA  MOKASSA  II  GREATER TAUNG",
    Latitude: "-27.6094",
    Longitude: "24.6739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20249",
    WardLookupId: "2467",
    VDNumber: "86743413",
    RegisteredPopulation: "314",
    VotingStationName: "CHIEF NJ GOSHUPELWANG",
    Address: "NEXT TO TRIBAL KRAAL  MAJANENG VILLAGE  GREATER TAUNG",
    Latitude: "-27.6203",
    Longitude: "24.7417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20250",
    WardLookupId: "2467",
    VDNumber: "86743424",
    RegisteredPopulation: "167",
    VotingStationName: "REBONE LESEDI CRECHE",
    Address: "TAKAPORI VILLAGE  TAUNG  TAKAPORI VILLAGE",
    Latitude: "-27.616",
    Longitude: "24.7007",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20251",
    WardLookupId: "2468",
    VDNumber: "86830027",
    RegisteredPopulation: "1645",
    VotingStationName: "AME  CHURCH",
    Address: "907 KWENA STREET  BOITUMELONG  BLOEMHOF",
    Latitude: "-27.6368",
    Longitude: "25.5693",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20252",
    WardLookupId: "2468",
    VDNumber: "86830072",
    RegisteredPopulation: "1528",
    VotingStationName: "ST JOHN APOSTOLIC CHURCH",
    Address: "CHRISTIANA  ",
    Latitude: "-27.6327",
    Longitude: "25.5695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20253",
    WardLookupId: "2469",
    VDNumber: "86830049",
    RegisteredPopulation: "2082",
    VotingStationName: "THAMAGANE PRIMARY SCHOOL",
    Address: "921 GOPANE STREET  BOITUMELONG  BOITUMELONG",
    Latitude: "-27.6399",
    Longitude: "25.5702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20254",
    WardLookupId: "2469",
    VDNumber: "86830061",
    RegisteredPopulation: "692",
    VotingStationName: "GAOPALELWE SECONDARY SCHOOL",
    Address: "1493 TSHETLHO  BOITUMELONG  BLOEMHOF",
    Latitude: "-27.6419",
    Longitude: "25.5645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20255",
    WardLookupId: "2470",
    VDNumber: "86830038",
    RegisteredPopulation: "2781",
    VotingStationName: "THUTO-LORE PUBLICSCHOOL",
    Address: "2111 PHUTHADITJHABA  BOITUMELONG  BLOEMHOF",
    Latitude: "-27.6445",
    Longitude: "25.5732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20256",
    WardLookupId: "2471",
    VDNumber: "86820666",
    RegisteredPopulation: "61",
    VotingStationName: "ZEWEFONTEIN FARM",
    Address: "ZEWEFONTEIN FARM  BLOEMHOF  BLOEMHOF",
    Latitude: "-27.4533",
    Longitude: "25.6287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20257",
    WardLookupId: "2471",
    VDNumber: "86830016",
    RegisteredPopulation: "1069",
    VotingStationName: "BLOEMHOF TOWN HALL",
    Address: "03 TULLEKIN STREET  BLOEMHOF  BLOEMHOF",
    Latitude: "-27.6508",
    Longitude: "25.6083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20258",
    WardLookupId: "2471",
    VDNumber: "86830050",
    RegisteredPopulation: "1363",
    VotingStationName: "COVERDALE COMMUNITY HALL",
    Address: "1967 PROTEA STREET  COVERDALE  BLOEMHOF",
    Latitude: "-27.6362",
    Longitude: "25.5877",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20259",
    WardLookupId: "2471",
    VDNumber: "86830083",
    RegisteredPopulation: "133",
    VotingStationName: "BLOEMHOF PRIMARY SCHOOL",
    Address: "10 KISMET STREET  SALAMAAT  BLOEMHOF",
    Latitude: "-27.641",
    Longitude: "25.5942",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20260",
    WardLookupId: "2471",
    VDNumber: "86830094",
    RegisteredPopulation: "718",
    VotingStationName: "INDEPENDENT  CHRISTIAN CONGREGATION CHURCH",
    Address: "4760 LEBEGO  BOITUMELONG  BLLOEMHOF",
    Latitude: "-27.6441",
    Longitude: "25.5755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20261",
    WardLookupId: "2472",
    VDNumber: "86840039",
    RegisteredPopulation: "1689",
    VotingStationName: "AFRICAN CATHOLIC CHURCH",
    Address: "1683 GOPOLANG STREET  UTLWANANG  CHRISTIANA",
    Latitude: "-27.8888",
    Longitude: "25.1286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20262",
    WardLookupId: "2472",
    VDNumber: "86840051",
    RegisteredPopulation: "864",
    VotingStationName: "LERONA SECONDARY SCHOOL",
    Address: "614 KGALEMELO STREET  UTLWANANG  LEKWA-TEEMANE",
    Latitude: "-27.8909",
    Longitude: "25.1194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20263",
    WardLookupId: "2473",
    VDNumber: "86820093",
    RegisteredPopulation: "62",
    VotingStationName: "KOPPIE ANKEL FARM (TENT)",
    Address: "N/A CHRISTIANA/SWEIZERRENEKE ROAD  CHRISTIANA  CHRISTIANA",
    Latitude: "-27.6347",
    Longitude: "25.0968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20264",
    WardLookupId: "2473",
    VDNumber: "86820307",
    RegisteredPopulation: "56",
    VotingStationName: "TENT AT WELTEVREDEN FARM",
    Address: "WELTEVREDEN FARM  BLOEMHOF  BLOEMHOF",
    Latitude: "-27.5316",
    Longitude: "25.3825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20265",
    WardLookupId: "2473",
    VDNumber: "86820598",
    RegisteredPopulation: "173",
    VotingStationName: "BRITTEN STATION SHOP",
    Address: "BLOEMHOF  CHRISTIANA",
    Latitude: "-27.7456",
    Longitude: "25.3482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20266",
    WardLookupId: "2473",
    VDNumber: "86840017",
    RegisteredPopulation: "2147",
    VotingStationName: "CHRISTIANA TOWN HALL",
    Address: "CNR DIRKIE UYS & ROBYN STREETS  CHRISTIANA",
    Latitude: "-27.9118",
    Longitude: "25.1638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20267",
    WardLookupId: "2473",
    VDNumber: "86840073",
    RegisteredPopulation: "256",
    VotingStationName: "KROMELBOOG HIGH SCHOOL (BEEFMASTER)",
    Address: "BEEFMASTER  BEEFMASTER FARM  CHRISTIANA",
    Latitude: "-27.8832",
    Longitude: "25.1934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20268",
    WardLookupId: "2474",
    VDNumber: "86820015",
    RegisteredPopulation: "1246",
    VotingStationName: "UTLWANANG COMMUNITY HALL",
    Address: "09 BOIKETLO  UTLWANANG  LOC  LEKWA-TEEMANE",
    Latitude: "-27.8949",
    Longitude: "25.1261",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20269",
    WardLookupId: "2474",
    VDNumber: "86840062",
    RegisteredPopulation: "987",
    VotingStationName: "GELUKSOORD HALL",
    Address: "90 COLLINSINGLE STREET  GELUKSOORD  LEKWA-TEEMANE",
    Latitude: "-27.9011",
    Longitude: "25.1461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20270",
    WardLookupId: "2474",
    VDNumber: "86840084",
    RegisteredPopulation: "1194",
    VotingStationName: "GELUKSOORD TEMPORARY STATION (TENT)",
    Address: "NONE LANGGEWAAG  GELUKSOORD  CHRISTIANA",
    Latitude: "-27.9031",
    Longitude: "25.1337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20271",
    WardLookupId: "2475",
    VDNumber: "86820981",
    RegisteredPopulation: "1591",
    VotingStationName: "MARANATHA CHRECHE",
    Address: "943 KGALEMELO STREET  UTLWANANG  LEKWA-TEEMANE [CHRISTIANA]",
    Latitude: "-27.8884",
    Longitude: "25.1177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20272",
    WardLookupId: "2475",
    VDNumber: "86840095",
    RegisteredPopulation: "1188",
    VotingStationName: "EXT 4  UTLWANANG",
    Address: "NONE UTLWANANG  CHRISTIANA  CHRISTIANA",
    Latitude: "-27.8905",
    Longitude: "25.115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20273",
    WardLookupId: "2476",
    VDNumber: "86741107",
    RegisteredPopulation: "972",
    VotingStationName: "MMUSI PRIMARY SCHOOL",
    Address: "(NW391 - KAGISANO [GANYESA])   ",
    Latitude: "-26.4551",
    Longitude: "24.4022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20274",
    WardLookupId: "2476",
    VDNumber: "86741242",
    RegisteredPopulation: "1482",
    VotingStationName: "SEITSANG PRIMARY SCHOOL",
    Address: "TLAKGAMENG  BOPHIRIMA",
    Latitude: "-26.4478",
    Longitude: "24.3295",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20275",
    WardLookupId: "2476",
    VDNumber: "86741534",
    RegisteredPopulation: "199",
    VotingStationName: "BABALA (TENT)",
    Address: "KADEBIS FARM   GANYESA  BA GA LETHOGILE",
    Latitude: "-26.3421",
    Longitude: "24.4761",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20276",
    WardLookupId: "2476",
    VDNumber: "86741624",
    RegisteredPopulation: "235",
    VotingStationName: "ALLETASRUS BOERESAAL",
    Address: "ALLETASRUS FARM  BOPHIRIMA",
    Latitude: "-26.6514",
    Longitude: "24.4917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20277",
    WardLookupId: "2476",
    VDNumber: "86742917",
    RegisteredPopulation: "731",
    VotingStationName: "KUDUNKGWANE PRIMARY SCHOOL",
    Address: "NW391 -KAGISANO(GANYESA)  KUDUNKGWANE  KAGISANO [GANYESA]",
    Latitude: "-26.4048",
    Longitude: "24.3987",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20278",
    WardLookupId: "2476",
    VDNumber: "86800013",
    RegisteredPopulation: "267",
    VotingStationName: "PIET PLESSIS COMMUNITY HALL",
    Address: "PIET PLESSIS  BOPHIRIMA",
    Latitude: "-26.1627",
    Longitude: "24.408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20279",
    WardLookupId: "2477",
    VDNumber: "86740106",
    RegisteredPopulation: "963",
    VotingStationName: "PICA- A- PAU HIGH SCHOOL",
    Address: "GANYESA  POMFRET  BA GA LETLHOGILE",
    Latitude: "-25.8106",
    Longitude: "23.5258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20280",
    WardLookupId: "2477",
    VDNumber: "86741084",
    RegisteredPopulation: "90",
    VotingStationName: "EBENEZER PRIMARY SCHOOL",
    Address: "FARM  EBENEZER",
    Latitude: "-25.5781",
    Longitude: "23.8584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20281",
    WardLookupId: "2477",
    VDNumber: "86741455",
    RegisteredPopulation: "678",
    VotingStationName: "TOSCA MUNICIPAL BOARDROOM",
    Address: "GANYESA  TOSCA FARM  BA GA LETLHOGILE",
    Latitude: "-25.8818",
    Longitude: "23.9602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20282",
    WardLookupId: "2477",
    VDNumber: "86741499",
    RegisteredPopulation: "191",
    VotingStationName: "GEMSBOKVLAKTE STORE",
    Address: "GEMSBOKVLAKTE  BOPHIRIMA",
    Latitude: "-25.7277",
    Longitude: "24.284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20283",
    WardLookupId: "2477",
    VDNumber: "86742007",
    RegisteredPopulation: "192",
    VotingStationName: "VERGELEE SHOP",
    Address: "VERGELEE  BOPHIRIMA",
    Latitude: "-25.7768",
    Longitude: "24.1957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20284",
    WardLookupId: "2477",
    VDNumber: "86742108",
    RegisteredPopulation: "98",
    VotingStationName: "JAKKALSKOP COMMUNITY HALL",
    Address: "JAKKALSKOP  VRYBURG",
    Latitude: "-25.8049",
    Longitude: "23.6901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20285",
    WardLookupId: "2477",
    VDNumber: "86742131",
    RegisteredPopulation: "36",
    VotingStationName: "MOBILE VOTING STATION (FORRES FARM)",
    Address: "FORRES FARM              FORRES  FORRES",
    Latitude: "-25.9358",
    Longitude: "23.9534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20286",
    WardLookupId: "2477",
    VDNumber: "86742906",
    RegisteredPopulation: "226",
    VotingStationName: "NAMEKA EARLY LEARNING CENTER",
    Address: "SALVADOR  POMFRET  POMFRET",
    Latitude: "-25.8269",
    Longitude: "23.5259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20287",
    WardLookupId: "2477",
    VDNumber: "86742939",
    RegisteredPopulation: "264",
    VotingStationName: "HIKANE PRIMARY SCHOOL",
    Address: "NW391-KAGISANO(GANYESA)  MOROKWANENG  KAGISANO [GANYESA]",
    Latitude: "-26.0801",
    Longitude: "23.725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20288",
    WardLookupId: "2478",
    VDNumber: "86741488",
    RegisteredPopulation: "159",
    VotingStationName: "LENIESDEEL FARMERS HALL",
    Address: "LENIESDEEL  BOPHIRIMA",
    Latitude: "-26.2743",
    Longitude: "23.9959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20289",
    WardLookupId: "2478",
    VDNumber: "86741501",
    RegisteredPopulation: "73",
    VotingStationName: "BOSHOEK",
    Address: "BOSHOEK  BOPHIRIMA",
    Latitude: "-25.92",
    Longitude: "24.5018",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20290",
    WardLookupId: "2478",
    VDNumber: "86741837",
    RegisteredPopulation: "961",
    VotingStationName: "KEIPATILE PRIMARY SCHOOL",
    Address: "NW391-KAGISANO(GANYESA)  MOROKWENG  BOPHIRIMA",
    Latitude: "-26.1434",
    Longitude: "23.7971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20291",
    WardLookupId: "2478",
    VDNumber: "86741848",
    RegisteredPopulation: "1519",
    VotingStationName: "OTHAILE PRIMARY SCHOOL",
    Address: "MOROKWENG  BA GA MARIBA",
    Latitude: "-26.1399",
    Longitude: "23.7897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20292",
    WardLookupId: "2478",
    VDNumber: "86742074",
    RegisteredPopulation: "62",
    VotingStationName: "VENTERSRUST FARM",
    Address: "VENTERSRUST  VRYBURG",
    Latitude: "-25.9888",
    Longitude: "24.0874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20293",
    WardLookupId: "2478",
    VDNumber: "86742085",
    RegisteredPopulation: "47",
    VotingStationName: "HURLEY FARM",
    Address: "HURLEY  PIET PLESSIS",
    Latitude: "-25.9583",
    Longitude: "24.3155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20294",
    WardLookupId: "2478",
    VDNumber: "86742096",
    RegisteredPopulation: "60",
    VotingStationName: "KOMMANDANTSPRUIT FARM",
    Address: "FARM  PIET PLESSIS",
    Latitude: "-26.1777",
    Longitude: "24.5088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20295",
    WardLookupId: "2478",
    VDNumber: "86742940",
    RegisteredPopulation: "181",
    VotingStationName: "BANCHO PRIMARY SCHOOL",
    Address: "NW391-KAGISANO(GANYESA)  TSHETSHU  KAGISANO [GANYESA]",
    Latitude: "-26.15",
    Longitude: "23.8478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20296",
    WardLookupId: "2478",
    VDNumber: "86800024",
    RegisteredPopulation: "1005",
    VotingStationName: "TSHOLOFELO COMMUNITY HALL",
    Address: "(NW391 - KAGISANO [GANYESA])   ",
    Latitude: "-26.1627",
    Longitude: "24.4186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20297",
    WardLookupId: "2479",
    VDNumber: "86740184",
    RegisteredPopulation: "1500",
    VotingStationName: "TLOU LE TAU TRIBAL HALL",
    Address: "GANYESA  BOPHIRIMA",
    Latitude: "-26.5853",
    Longitude: "24.1776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20298",
    WardLookupId: "2479",
    VDNumber: "86742995",
    RegisteredPopulation: "675",
    VotingStationName: "GANYESA SEDIBENG (TENT)",
    Address: "POLICE STATION RD  GANYESA  TLOU LE TAU TRIBAL AUTHORITY",
    Latitude: "-26.601",
    Longitude: "24.1878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20299",
    WardLookupId: "2479",
    VDNumber: "86743468",
    RegisteredPopulation: "1017",
    VotingStationName: "PHOHUNG TRIBAL HALL",
    Address: "(NW391 - KAGISANO [GANYESA])   ",
    Latitude: "-26.6108",
    Longitude: "24.1918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20300",
    WardLookupId: "2479",
    VDNumber: "86743479",
    RegisteredPopulation: "636",
    VotingStationName: "TONG COMPREHENSIVE SCHOOL",
    Address: "(NW391 - KAGISANO [GANYESA])   ",
    Latitude: "-26.5904",
    Longitude: "24.1847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20301",
    WardLookupId: "2480",
    VDNumber: "86740139",
    RegisteredPopulation: "1101",
    VotingStationName: "PHATSIMO DAY CARE CENTRE",
    Address: "GANYESA  BAROLONG BOO TLOU LE TAU",
    Latitude: "-26.6194",
    Longitude: "24.193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20302",
    WardLookupId: "2480",
    VDNumber: "86740173",
    RegisteredPopulation: "1625",
    VotingStationName: "BORE SECONDARY SCHOOL",
    Address: "NONE  GANYESA  BOPHIRIMA",
    Latitude: "-26.609",
    Longitude: "24.1755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20303",
    WardLookupId: "2480",
    VDNumber: "86742928",
    RegisteredPopulation: "400",
    VotingStationName: "TENT (DITSHUKUTSHWANENG VILLAGE (TENT)",
    Address: "DITSHUKUTSHWANENG  LOUWNA  VRYBURG",
    Latitude: "-26.8901",
    Longitude: "24.231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20304",
    WardLookupId: "2480",
    VDNumber: "86743075",
    RegisteredPopulation: "90",
    VotingStationName: "MALEMBA FARM (TENT)",
    Address: "MALEMBA FARM  LOUWNA  VRYBURG",
    Latitude: "-26.777",
    Longitude: "24.1353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20305",
    WardLookupId: "2481",
    VDNumber: "86741253",
    RegisteredPopulation: "1073",
    VotingStationName: "PHAPOSANE PRIMARY SCHOOL",
    Address: "PHAPOSANE  BOPHIRIMA",
    Latitude: "-26.5673",
    Longitude: "24.3139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20306",
    WardLookupId: "2481",
    VDNumber: "86741264",
    RegisteredPopulation: "926",
    VotingStationName: "GAMANYAI COMMUNITY HALL",
    Address: "GAMANYAI VILLAGE  KAGISANO LOCAL MUNICIPALITY",
    Latitude: "-26.5071",
    Longitude: "24.3452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20307",
    WardLookupId: "2481",
    VDNumber: "86741545",
    RegisteredPopulation: "275",
    VotingStationName: "COETZERSDAM WINKEL",
    Address: "COETZERSDAM  BOPHIRIMA",
    Latitude: "-26.7966",
    Longitude: "24.2551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20308",
    WardLookupId: "2481",
    VDNumber: "86743008",
    RegisteredPopulation: "652",
    VotingStationName: "PHAPOSANE TRIBAL HALL",
    Address: "NONE  PHAPOSANE  KAGISANO [GANYESA]",
    Latitude: "-26.5567",
    Longitude: "24.3169",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20309",
    WardLookupId: "2481",
    VDNumber: "86743615",
    RegisteredPopulation: "563",
    VotingStationName: "MAAMOGWA PRIMARY SCHOOL",
    Address: "PHAPHOSANE  GANYESA  GANYESA",
    Latitude: "-26.5383",
    Longitude: "24.33",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20310",
    WardLookupId: "2482",
    VDNumber: "86740027",
    RegisteredPopulation: "974",
    VotingStationName: "KAKANYO PRIMARY SCHOOL",
    Address: "SOUTHEY  BOPHIRIMA",
    Latitude: "-26.7727",
    Longitude: "24.0161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20311",
    WardLookupId: "2482",
    VDNumber: "86741567",
    RegisteredPopulation: "686",
    VotingStationName: "KGOKGOLE TRIBAL HALL",
    Address: "KGOKGOLE  BOPHIRIMA",
    Latitude: "-26.7151",
    Longitude: "23.922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20312",
    WardLookupId: "2482",
    VDNumber: "86742186",
    RegisteredPopulation: "879",
    VotingStationName: "TSHANENG PRIMARY SCHOOL",
    Address: "TSHANENG  VILLAGE  KAGISANO LOCAL MUNICIPALITY",
    Latitude: "-26.6141",
    Longitude: "24.0558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20313",
    WardLookupId: "2482",
    VDNumber: "86742197",
    RegisteredPopulation: "718",
    VotingStationName: "DIPODI PRIMARY SCHOOL",
    Address: "DIPODI VILLAGE  BOPHIRIMA",
    Latitude: "-26.6967",
    Longitude: "24.042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20314",
    WardLookupId: "2482",
    VDNumber: "86742568",
    RegisteredPopulation: "586",
    VotingStationName: "KGOKGOJANE COMMUNITY HALL",
    Address: "KGOKGOJANE  BOPHIRIMA",
    Latitude: "-26.749",
    Longitude: "23.9804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20315",
    WardLookupId: "2482",
    VDNumber: "86742579",
    RegisteredPopulation: "190",
    VotingStationName: "KAMOGELO PRIMARY SCHOOL",
    Address: "PENBROEK VILLAGE  BOPHIRIMA",
    Latitude: "-26.7003",
    Longitude: "23.8694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20316",
    WardLookupId: "2483",
    VDNumber: "86740207",
    RegisteredPopulation: "615",
    VotingStationName: "GAEGANE PRIMARY SCHOOL",
    Address: "NW391-KAGISANO(GANYESA)  VERGENOEG  BOPHIRIMA",
    Latitude: "-26.8175",
    Longitude: "23.8652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20317",
    WardLookupId: "2483",
    VDNumber: "86741219",
    RegisteredPopulation: "771",
    VotingStationName: "ECKRON PRIMARY SCHOOL",
    Address: "MMADINONYANE  BOPHIRIMA",
    Latitude: "-26.8058",
    Longitude: "23.763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20318",
    WardLookupId: "2483",
    VDNumber: "86741613",
    RegisteredPopulation: "497",
    VotingStationName: "LOUWNA PRIMARY SCHOOL",
    Address: "LOUWNA  BOPHIRIMA",
    Latitude: "-26.9001",
    Longitude: "24.1495",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20319",
    WardLookupId: "2483",
    VDNumber: "86742209",
    RegisteredPopulation: "410",
    VotingStationName: "TLAPENG PRIMARY SCHOOL",
    Address: "SOUTHEY  VRAGAS VILLAGE  KAGISANO LOCAL MUNICIPALITY",
    Latitude: "-26.8281",
    Longitude: "23.9284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20320",
    WardLookupId: "2483",
    VDNumber: "86742210",
    RegisteredPopulation: "474",
    VotingStationName: "ITIRELENG COMMUNITY HALL",
    Address: "(NW391 - KAGISANO [GANYESA])   ",
    Latitude: "-26.7774",
    Longitude: "23.9525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20321",
    WardLookupId: "2483",
    VDNumber: "86742546",
    RegisteredPopulation: "131",
    VotingStationName: "KATLARELO PRIMARY SCHOOL",
    Address: "ESDALE VILLAGE  BOPHIRIMA",
    Latitude: "-26.7772",
    Longitude: "23.698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20322",
    WardLookupId: "2483",
    VDNumber: "86742557",
    RegisteredPopulation: "322",
    VotingStationName: "POUVAL COMMUNITY HALL",
    Address: "POUVAL  BOPHIRIMA",
    Latitude: "-26.8441",
    Longitude: "23.8572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20323",
    WardLookupId: "2484",
    VDNumber: "86740049",
    RegisteredPopulation: "859",
    VotingStationName: "LONGANENG PRIMARY SCHOOL",
    Address: "MOROKWENG  BOPHIRIMA",
    Latitude: "-26.1242",
    Longitude: "23.7528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20324",
    WardLookupId: "2484",
    VDNumber: "86740094",
    RegisteredPopulation: "868",
    VotingStationName: "BONA-BONA PRIMARY SCHOOL",
    Address: "BONA-BONA  GANYESA  BAGA MARIBA TRIBAL  AUTHORITY",
    Latitude: "-26.2354",
    Longitude: "23.5385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20325",
    WardLookupId: "2484",
    VDNumber: "86740117",
    RegisteredPopulation: "484",
    VotingStationName: "MABONE PRIMARY SCHOOL",
    Address: "NW391-KAGISANO(GANYESA)  KOKWANA  BOPHIRIMA",
    Latitude: "-26.0444",
    Longitude: "23.4897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20326",
    WardLookupId: "2484",
    VDNumber: "86740689",
    RegisteredPopulation: "345",
    VotingStationName: "AGELELANG THUTO PRIMARY SCHOOL",
    Address: "MANYELEDI  BOPHIRIMA",
    Latitude: "-26.1658",
    Longitude: "23.4646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20327",
    WardLookupId: "2484",
    VDNumber: "86741646",
    RegisteredPopulation: "174",
    VotingStationName: "MATLHABA-TLHABE COMMUNITY HALL",
    Address: "MATLHABA-TLHABE  BAGA MARIBA",
    Latitude: "-26.2107",
    Longitude: "23.6581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20328",
    WardLookupId: "2484",
    VDNumber: "86742489",
    RegisteredPopulation: "337",
    VotingStationName: "MAPITIKI PRIMARY SCHOOL",
    Address: "MATLODING VILLAGE  BOPHIRIMA",
    Latitude: "-26.1089",
    Longitude: "23.461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20329",
    WardLookupId: "2484",
    VDNumber: "86743536",
    RegisteredPopulation: "74",
    VotingStationName: "PHEPANE (TENT)",
    Address: "PHEPANE  MOROKWENG  BA GA MARIBA",
    Latitude: "-26.1088",
    Longitude: "23.3161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20330",
    WardLookupId: "2484",
    VDNumber: "86743839",
    RegisteredPopulation: "163",
    VotingStationName: "MOROKWENG AME CHURCH",
    Address: "MOROKWENG  BAROLONG BA GA MARIBA",
    Latitude: "-26.1294",
    Longitude: "23.7682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20331",
    WardLookupId: "2485",
    VDNumber: "86741859",
    RegisteredPopulation: "1285",
    VotingStationName: "BA GA MARIBA TRADITIONAL COUNCIL",
    Address: "MOROKWENG  BOPHIRIMA",
    Latitude: "-26.1254",
    Longitude: "23.7706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20332",
    WardLookupId: "2485",
    VDNumber: "86742951",
    RegisteredPopulation: "1197",
    VotingStationName: "MOROKWENG COMMUNITY HALL",
    Address: "MOROKWENG  KAGISANO [GANYESA]",
    Latitude: "-26.1091",
    Longitude: "23.7595",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20333",
    WardLookupId: "2485",
    VDNumber: "86742962",
    RegisteredPopulation: "724",
    VotingStationName: "OGODISENG SECONDARY SCHOOL",
    Address: "-  MOROKWENG  KAGISANO [GANYESA]",
    Latitude: "-26.116",
    Longitude: "23.778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20334",
    WardLookupId: "2485",
    VDNumber: "86742973",
    RegisteredPopulation: "743",
    VotingStationName: "MONCHUSI SECONDARY SCHOOL",
    Address: "NW391-KAGISANO(GANYESA)  MOROKWENG  KAGISANO [GANYESA]",
    Latitude: "-26.1206",
    Longitude: "23.7906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20335",
    WardLookupId: "2485",
    VDNumber: "86743491",
    RegisteredPopulation: "429",
    VotingStationName: "BOTSALANO PRIMARY SCHOOL",
    Address: "(NW391 - KAGISANO [GANYESA])   ",
    Latitude: "-26.0901",
    Longitude: "23.7403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20336",
    WardLookupId: "2486",
    VDNumber: "86740083",
    RegisteredPopulation: "1016",
    VotingStationName: "DITEHO SECONDARY SCHOOL",
    Address: "NONE  MOROKWANENG  BOPHIRIMA",
    Latitude: "-26.0934",
    Longitude: "23.7321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20337",
    WardLookupId: "2486",
    VDNumber: "86742490",
    RegisteredPopulation: "447",
    VotingStationName: "MAKALAATHUTLWA PRIMARY SCHOOL",
    Address: "MAKALAATHUTLWA  BOPHIRIMA",
    Latitude: "-25.9584",
    Longitude: "23.5516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20338",
    WardLookupId: "2486",
    VDNumber: "86742502",
    RegisteredPopulation: "390",
    VotingStationName: "MMAHENG COMMUNITY HALL",
    Address: "MMAHENG  BOPHIRIMA",
    Latitude: "-26.0168",
    Longitude: "23.5916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20339",
    WardLookupId: "2486",
    VDNumber: "86742513",
    RegisteredPopulation: "225",
    VotingStationName: "BANA-BOTLHE PRIMARY SCHOOL",
    Address: "DIHATSHWE VILLAGE  KAGISANO LOCAL MUNICIPALITY",
    Latitude: "-26.0503",
    Longitude: "23.6554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20340",
    WardLookupId: "2486",
    VDNumber: "86742524",
    RegisteredPopulation: "316",
    VotingStationName: "THUTO-LESEDI PRIMARY SCHOOL",
    Address: "LEEU-AAR VILLAGE  BOPHIRIMA",
    Latitude: "-26.0621",
    Longitude: "23.6871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20341",
    WardLookupId: "2486",
    VDNumber: "86743064",
    RegisteredPopulation: "407",
    VotingStationName: "GAMONTSHONYANE PRIMARY SCHOOL",
    Address: "NW391-KAGISANO(GANYESA)  GAMONTSHONYANE  KAGISANO [GANYESA]",
    Latitude: "-25.999",
    Longitude: "23.5483",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20342",
    WardLookupId: "2486",
    VDNumber: "86743480",
    RegisteredPopulation: "190",
    VotingStationName: "KIBITWE PRIMARY SCHOOL",
    Address: "(NW391 - KAGISANO [GANYESA])   ",
    Latitude: "-26.0223",
    Longitude: "23.7316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20343",
    WardLookupId: "2486",
    VDNumber: "86743503",
    RegisteredPopulation: "474",
    VotingStationName: "TSEOGE COMMUNITY HALL",
    Address: "(NW391 - KAGISANO [GANYESA])   ",
    Latitude: "-25.9988",
    Longitude: "23.5178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20344",
    WardLookupId: "2487",
    VDNumber: "86740038",
    RegisteredPopulation: "445",
    VotingStationName: "MOGOMOTSI PRIMARY SCHOOL",
    Address: "TSENG VILLAGE  BOPHIRIMA",
    Latitude: "-25.9935",
    Longitude: "23.452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20345",
    WardLookupId: "2487",
    VDNumber: "86740128",
    RegisteredPopulation: "666",
    VotingStationName: "SETLHABETSI PRIMARY SCHOOL",
    Address: "NW391-KAGISANO(GANYESA)  TSENG  BOPHIRIMA",
    Latitude: "-25.9003",
    Longitude: "23.5035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20346",
    WardLookupId: "2487",
    VDNumber: "86741422",
    RegisteredPopulation: "165",
    VotingStationName: "KGALALELO PRIMARY SCHOOL",
    Address: "NONE  VILLAGE  VORSTERSHOOP",
    Latitude: "-25.8388",
    Longitude: "23.0251",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20347",
    WardLookupId: "2487",
    VDNumber: "86741433",
    RegisteredPopulation: "1135",
    VotingStationName: "BRAY INTERMIDIATE SCHOOL",
    Address: "MAIN STREET  BRAY  BRAY",
    Latitude: "-25.4657",
    Longitude: "23.7068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20348",
    WardLookupId: "2487",
    VDNumber: "86742119",
    RegisteredPopulation: "84",
    VotingStationName: "MOBILE VOTING STATION (MATTOPPI FARM)",
    Address: "MATTOPPI FARM  MATTOPPI  MATTOPPI",
    Latitude: "-25.3556",
    Longitude: "23.4997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20349",
    WardLookupId: "2487",
    VDNumber: "86742120",
    RegisteredPopulation: "60",
    VotingStationName: "OTTOWA FARM TENT",
    Address: "TOSCA  ",
    Latitude: "-25.5937",
    Longitude: "23.3759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20350",
    WardLookupId: "2487",
    VDNumber: "86742142",
    RegisteredPopulation: "71",
    VotingStationName: "MOBILE VOTING STATION (MOKOPONG FARM)",
    Address: "MAKOPONG SHOP  MOKOPONG  MOKOPONG",
    Latitude: "-25.3667",
    Longitude: "22.978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20351",
    WardLookupId: "2487",
    VDNumber: "86742153",
    RegisteredPopulation: "34",
    VotingStationName: "RADNOR FARM",
    Address: "VOSTERSHOOP  BOPHIRIMA",
    Latitude: "-26.1105",
    Longitude: "22.8823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20352",
    WardLookupId: "2487",
    VDNumber: "86742164",
    RegisteredPopulation: "626",
    VotingStationName: "KONKE PRIMARY SCHOOL",
    Address: "KONKE  BOPHIRIMA",
    Latitude: "-25.9858",
    Longitude: "23.518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20353",
    WardLookupId: "2488",
    VDNumber: "86741275",
    RegisteredPopulation: "1107",
    VotingStationName: "TLAKGAMENG TRIBAL HALL",
    Address: "TLAKGAMENG  BOPHIRIMA",
    Latitude: "-26.4681",
    Longitude: "24.3411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20354",
    WardLookupId: "2488",
    VDNumber: "86741286",
    RegisteredPopulation: "1034",
    VotingStationName: "SEBETWANE SECONDARY SCHOOL",
    Address: "TLAKGAMENG  BOPHIRIMA",
    Latitude: "-26.4646",
    Longitude: "24.3482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20355",
    WardLookupId: "2488",
    VDNumber: "86742984",
    RegisteredPopulation: "1054",
    VotingStationName: "TLAKGAMENG ROMAN CATHOLIC CHURCH",
    Address: "GANYESA  TLAKGAMENG  BA GA LETLHOGILE",
    Latitude: "-26.476",
    Longitude: "24.3408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20356",
    WardLookupId: "2489",
    VDNumber: "86740151",
    RegisteredPopulation: "1123",
    VotingStationName: "MONNAPHANG SEBOGODI PRIMARY SCHOOL",
    Address: "NONE  GANYESA  BOPHIRIMA",
    Latitude: "-26.5831",
    Longitude: "24.1647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20357",
    WardLookupId: "2489",
    VDNumber: "86741095",
    RegisteredPopulation: "671",
    VotingStationName: "MOSWANE INTERMEDIATE SCHOOL",
    Address: "NW391-KAGISANO(GANYESA)  MOSWANA VILLAGE  KAGISANO MUNICIPALITY",
    Latitude: "-26.5287",
    Longitude: "24.2295",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20358",
    WardLookupId: "2489",
    VDNumber: "86741578",
    RegisteredPopulation: "819",
    VotingStationName: "KGOSITHEBE COMBINED SCHOOL",
    Address: "NW391-KAGISANO(GANYESA)  AUSTREY  KAGISANO [GANYESA]",
    Latitude: "-26.468",
    Longitude: "24.1793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20359",
    WardLookupId: "2489",
    VDNumber: "86743042",
    RegisteredPopulation: "381",
    VotingStationName: "NCHELANG PRIMARY SCHOOL",
    Address: "NW391-KAGISANO(GANYESA)  GOODWOOD  KAGISANO [GANYESA]",
    Latitude: "-26.4561",
    Longitude: "24.2609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20360",
    WardLookupId: "2489",
    VDNumber: "86743367",
    RegisteredPopulation: "690",
    VotingStationName: "NG CHURCH GANYESA",
    Address: "GANYESA  KAGISANO-MOLOPO  BA GA LETLHOGILE",
    Latitude: "-26.5882",
    Longitude: "24.1631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20361",
    WardLookupId: "2490",
    VDNumber: "86740162",
    RegisteredPopulation: "1721",
    VotingStationName: "THIBOGANG LOWER PRIMARY SCHOOL",
    Address: "GANYESA  BOPHIRIMA",
    Latitude: "-26.6009",
    Longitude: "24.164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20362",
    WardLookupId: "2490",
    VDNumber: "86740195",
    RegisteredPopulation: "1244",
    VotingStationName: "TLAPENG COMMUNITY HALL",
    Address: "(NW391 - KAGISANO [GANYESA])   ",
    Latitude: "-26.6511",
    Longitude: "24.1064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20363",
    WardLookupId: "2490",
    VDNumber: "86742175",
    RegisteredPopulation: "299",
    VotingStationName: "MAGAABUE PRIMARY SCHOOL",
    Address: "MAGAABUE  BOPHIRIMA",
    Latitude: "-26.6267",
    Longitude: "24.1362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20364",
    WardLookupId: "2491",
    VDNumber: "86820183",
    RegisteredPopulation: "481",
    VotingStationName: "OORBIETJIESFONTEIN SCHOOL",
    Address: "HARTBEESFONTEIN  KLERKSDORP",
    Latitude: "-26.8304",
    Longitude: "26.3751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20365",
    WardLookupId: "2491",
    VDNumber: "86820194",
    RegisteredPopulation: "305",
    VotingStationName: "MATLHAPI FARM SCHOOL",
    Address: "NA  MATLHAPI FARM  HARTBEESFONTEIN",
    Latitude: "-26.8832",
    Longitude: "26.201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20366",
    WardLookupId: "2491",
    VDNumber: "86820217",
    RegisteredPopulation: "425",
    VotingStationName: "PHAKELA COMBINE SCHOOL",
    Address: "FARM SCHOOL  KLERKSDORP [KLERKSDORP]",
    Latitude: "-26.7705",
    Longitude: "26.2482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20367",
    WardLookupId: "2491",
    VDNumber: "86820970",
    RegisteredPopulation: "323",
    VotingStationName: "MELLIODORA GENERAL DEALER",
    Address: "MELLIODORA  HARTBEESFONTEIN",
    Latitude: "-26.7909",
    Longitude: "26.1304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20368",
    WardLookupId: "2491",
    VDNumber: "86821049",
    RegisteredPopulation: "1233",
    VotingStationName: "GEREFORMEERDE KERK",
    Address: "N/A THABO MBEKI  TIGANE  HARTBEESFONTEIN",
    Latitude: "-26.7445",
    Longitude: "26.4167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20369",
    WardLookupId: "2491",
    VDNumber: "86821117",
    RegisteredPopulation: "2479",
    VotingStationName: "KABELANO PRIMARY SCHOOL",
    Address: "2403 FREEDOM  TIGANE  HARTBEESFONTEIN",
    Latitude: "-26.7383",
    Longitude: "26.4144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20370",
    WardLookupId: "2491",
    VDNumber: "86860042",
    RegisteredPopulation: "417",
    VotingStationName: "TOWN COUNCIL HARTBEESFONTEIN",
    Address: "HARTBEESFONTEIN  ",
    Latitude: "-26.7651",
    Longitude: "26.4204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20371",
    WardLookupId: "2492",
    VDNumber: "86860019",
    RegisteredPopulation: "2739",
    VotingStationName: "BAKANG PRIMARY SCHOOL",
    Address: "1164 MOLEFE  TIGANE  KLERKSDORP",
    Latitude: "-26.7363",
    Longitude: "26.4232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20372",
    WardLookupId: "2492",
    VDNumber: "86860020",
    RegisteredPopulation: "2139",
    VotingStationName: "TIGANE PRIMARY SCHOOL",
    Address: "417 MOLEFE STR  TIGANE  HARTEBEESFONTEIN",
    Latitude: "-26.7437",
    Longitude: "26.4231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20373",
    WardLookupId: "2493",
    VDNumber: "86870245",
    RegisteredPopulation: "1046",
    VotingStationName: "MANZILPARK COMMUNITY HALL",
    Address: "34 MINTY  MANZILPARK  KLERKSDORP",
    Latitude: "-26.8655",
    Longitude: "26.5895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20374",
    WardLookupId: "2493",
    VDNumber: "86870256",
    RegisteredPopulation: "2240",
    VotingStationName: "ALABAMA PRIMARY SCHOOL",
    Address: "2577 CYCAMORE  ALABAMA  KLERKSDORP",
    Latitude: "-26.8717",
    Longitude: "26.5956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20375",
    WardLookupId: "2493",
    VDNumber: "86870267",
    RegisteredPopulation: "2021",
    VotingStationName: "ALABAMA SECONDARY SCHOOL",
    Address: "NIEUWENHOUDT  ALABAMA  KLERKSDORP",
    Latitude: "-26.8712",
    Longitude: "26.5789",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20376",
    WardLookupId: "2494",
    VDNumber: "86820161",
    RegisteredPopulation: "140",
    VotingStationName: "MOTSAGE`S FARM (SYFERFONTEIN)",
    Address: "MOTSAGE`S FARM   SYFERFONTEIN  SYFERFONTEIN FARM",
    Latitude: "-27.0205",
    Longitude: "26.3326",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20377",
    WardLookupId: "2494",
    VDNumber: "86820172",
    RegisteredPopulation: "503",
    VotingStationName: "METHODIST CHURCH  DOMINIONVILLE",
    Address: "3 DOMINIONVILLE   DOMINIONVILLE VILLAGE  DOMINIONVILLE VILLAGE",
    Latitude: "-26.9022",
    Longitude: "26.3941",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20378",
    WardLookupId: "2494",
    VDNumber: "86870379",
    RegisteredPopulation: "1962",
    VotingStationName: "THUSANO PRE-SCHOOL",
    Address: "24326 EXTENSION 21  JOUBERTON  KLERKSDORP [KLERKSDORP]",
    Latitude: "-26.8815",
    Longitude: "26.5728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20379",
    WardLookupId: "2494",
    VDNumber: "86870458",
    RegisteredPopulation: "2840",
    VotingStationName: "PRESTIGE SECONDARY SCHOOL",
    Address: "3652 C/O BRINK & STANDEEL  KLERKSDORP  ALABAMA",
    Latitude: "-26.8772",
    Longitude: "26.5692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20380",
    WardLookupId: "2494",
    VDNumber: "86870560",
    RegisteredPopulation: "964",
    VotingStationName: "EXCELSIOR PRIMARY SCHOOL",
    Address: "1 EXT 4 ALABAMA  ALABAMA  KLERKSDORP",
    Latitude: "-26.8735",
    Longitude: "26.5653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20381",
    WardLookupId: "2495",
    VDNumber: "86870144",
    RegisteredPopulation: "1807",
    VotingStationName: "COCEKANI HIGH SCHOOL",
    Address: "MATLOSANE  JOUBERTON  KLERKSDORP",
    Latitude: "-26.879",
    Longitude: "26.607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20382",
    WardLookupId: "2495",
    VDNumber: "86870177",
    RegisteredPopulation: "1571",
    VotingStationName: "ZIMELE-GEQE PUBLIC SCHOOL",
    Address: "MOROKAPULA  JOUBERTON  KLERKSDORP",
    Latitude: "-26.885",
    Longitude: "26.6012",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20383",
    WardLookupId: "2495",
    VDNumber: "86870504",
    RegisteredPopulation: "868",
    VotingStationName: "THE CHRIST CHURCH OF GOD",
    Address: "UMSENGE  JOUBERTON  KLERKSDORP",
    Latitude: "-26.8774",
    Longitude: "26.6011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20384",
    WardLookupId: "2496",
    VDNumber: "86870346",
    RegisteredPopulation: "2376",
    VotingStationName: "CHRIS HANI SECONDARY SCHOOL",
    Address: "ISIBHEDLELE  JOUBERTON  KLERKSDORP",
    Latitude: "-26.9058",
    Longitude: "26.6084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20385",
    WardLookupId: "2496",
    VDNumber: "86870357",
    RegisteredPopulation: "1939",
    VotingStationName: "CHRISTIAN FELLOWSHIP EXT 23",
    Address: "3 EXT 23  JOUBERTON  KLERKSDORP",
    Latitude: "-26.9054",
    Longitude: "26.616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20386",
    WardLookupId: "2497",
    VDNumber: "86870076",
    RegisteredPopulation: "2157",
    VotingStationName: "REAL FOUNDATION CHRIST CHURCH - EXT 22",
    Address: "PHUTHADITSHABA  JOUBERTON EXT 22  KLERKSDORP",
    Latitude: "-26.9034",
    Longitude: "26.589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20387",
    WardLookupId: "2497",
    VDNumber: "86870414",
    RegisteredPopulation: "1281",
    VotingStationName: "TSAMMA SECONDARY SCHOOL",
    Address: "PRIVATE X18  JOUBERTON  KLERKSDORP [KLERKSDORP]",
    Latitude: "-26.892",
    Longitude: "26.5826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20388",
    WardLookupId: "2497",
    VDNumber: "86870515",
    RegisteredPopulation: "892",
    VotingStationName: "ASSEMBLIES OF GOD(ENAMA STREET)",
    Address: "4234 EXT. 7  JOUBERTON  KLERKSDORP",
    Latitude: "-26.8946",
    Longitude: "26.589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20389",
    WardLookupId: "2498",
    VDNumber: "86870111",
    RegisteredPopulation: "1016",
    VotingStationName: "EDISANG PRIMARY SCHOOL",
    Address: "KUAMUSI  JOUBERTON  KLERKSDORP",
    Latitude: "-26.8879",
    Longitude: "26.5864",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20390",
    WardLookupId: "2498",
    VDNumber: "86870122",
    RegisteredPopulation: "1249",
    VotingStationName: "ASSEMBLIES OF GOD MOVEMENT(PHUTHADITSHABA STREET)",
    Address: "4449 EXT. 7 (EAST)  JOUBERTON  KLERKSDORP",
    Latitude: "-26.8942",
    Longitude: "26.5848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20391",
    WardLookupId: "2498",
    VDNumber: "86870133",
    RegisteredPopulation: "1124",
    VotingStationName: "KAKATLELA PRIMARY SCHOOL",
    Address: "LERUBISE  JOUBERTON  KLERKSDORP",
    Latitude: "-26.8847",
    Longitude: "26.5938",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20392",
    WardLookupId: "2498",
    VDNumber: "86870166",
    RegisteredPopulation: "1609",
    VotingStationName: "TIANG PRIMARY SCHOOL",
    Address: "JABULANI  JOUBERTON  KLERKSDORP",
    Latitude: "-26.8883",
    Longitude: "26.5971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20393",
    WardLookupId: "2498",
    VDNumber: "86870368",
    RegisteredPopulation: "903",
    VotingStationName: "WE CAN PRE SCHOOL",
    Address: "PRIVATE BAG X12  JOUBERTON  KLERKSDORP [KLERKSDORP]",
    Latitude: "-26.8907",
    Longitude: "26.5898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20394",
    WardLookupId: "2499",
    VDNumber: "86870155",
    RegisteredPopulation: "2004",
    VotingStationName: "LETLHABILE BAPTISM CHURCH",
    Address: "UMKANGAZI  JOUBERTON   KLERKSDORP",
    Latitude: "-26.8843",
    Longitude: "26.6104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20395",
    WardLookupId: "2499",
    VDNumber: "86870188",
    RegisteredPopulation: "1328",
    VotingStationName: "EMPILISWENE CLINIC",
    Address: "UMKANGANZI STR  JOUBERTON  KLERKSDORP",
    Latitude: "-26.8872",
    Longitude: "26.6034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20396",
    WardLookupId: "2499",
    VDNumber: "86870380",
    RegisteredPopulation: "1620",
    VotingStationName: "NOAHS ARK CRECHE",
    Address: "13962 EXT 11  JOUBERTON  KLERKSDORP",
    Latitude: "-26.8868",
    Longitude: "26.6043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20397",
    WardLookupId: "2500",
    VDNumber: "86870212",
    RegisteredPopulation: "1473",
    VotingStationName: "ST. PAUL`S ROMAN CATHOLIC CHURCH",
    Address: "23TH STREET  JOUBERTON  KLERKSDORP",
    Latitude: "-26.8929",
    Longitude: "26.6032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20398",
    WardLookupId: "2500",
    VDNumber: "86870223",
    RegisteredPopulation: "1896",
    VotingStationName: "MOKOLOKOTOANE PRIMARY SCHOOL",
    Address: "PHELANDABA  JOUBERTON  KLERKSDORP",
    Latitude: "-26.8916",
    Longitude: "26.5956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20399",
    WardLookupId: "2500",
    VDNumber: "86870425",
    RegisteredPopulation: "1048",
    VotingStationName: "KHAYALETHU PUBLIC SCHOOL",
    Address: "PRIVATE BAG X10  JOUBERTON  KLERKSDORP",
    Latitude: "-26.896",
    Longitude: "26.5934",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20400",
    WardLookupId: "2501",
    VDNumber: "86870199",
    RegisteredPopulation: "1719",
    VotingStationName: "JOUBERTON COMMUNITY HALL",
    Address: "PHELANDABA  JOUBERTON  KLERKSDORP",
    Latitude: "-26.892",
    Longitude: "26.6107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20401",
    WardLookupId: "2501",
    VDNumber: "86870201",
    RegisteredPopulation: "1300",
    VotingStationName: "AKOFANG PRIMARY SCHOOL",
    Address: "1851 TSELANTLE  JOUBERTON  KLERKSDORP",
    Latitude: "-26.8935",
    Longitude: "26.6046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20402",
    WardLookupId: "2501",
    VDNumber: "86870403",
    RegisteredPopulation: "1075",
    VotingStationName: "TSHEDIMOSETSO PRIMARY SCHOOL",
    Address: "PRIVATE BAG X2  JOUBERTON  KLERKSDORP",
    Latitude: "-26.8979",
    Longitude: "26.6144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20403",
    WardLookupId: "2501",
    VDNumber: "86870526",
    RegisteredPopulation: "645",
    VotingStationName: "BOITUMELO PRIMARY SCHOOL",
    Address: "1844 PHAKAMILE  JOUBERTON  KLERKSDORP",
    Latitude: "-26.8975",
    Longitude: "26.6081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20404",
    WardLookupId: "2502",
    VDNumber: "86821083",
    RegisteredPopulation: "78",
    VotingStationName: "YSTERSPRUIT FARM",
    Address: "YSTERSPRUIT  KLERKSDORP  YSTERSPRUIT",
    Latitude: "-27.0009",
    Longitude: "26.4841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20405",
    WardLookupId: "2502",
    VDNumber: "86821128",
    RegisteredPopulation: "1825",
    VotingStationName: "LITTLE ONES ACADEMY",
    Address: "27755 EXT 24  JOUBERTON EXT 24  KLERKSDORP",
    Latitude: "-26.9112",
    Longitude: "26.5901",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20406",
    WardLookupId: "2502",
    VDNumber: "86870436",
    RegisteredPopulation: "1209",
    VotingStationName: "ROMAN CATHOLIC CHURCH EXT 22",
    Address: "17313 PHAMBILI ST  JOUBERTON  KLERKSDORP [KLERKSDORP]",
    Latitude: "-26.9059",
    Longitude: "26.5906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20407",
    WardLookupId: "2502",
    VDNumber: "86870447",
    RegisteredPopulation: "1497",
    VotingStationName: "HOUSE OF JOY",
    Address: "18235 EXT 17  JOUBERTON  KLERKSDORP",
    Latitude: "-26.9151",
    Longitude: "26.5979",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20408",
    WardLookupId: "2503",
    VDNumber: "86870234",
    RegisteredPopulation: "2045",
    VotingStationName: "AREFADIMEHENG SECONDARY SCHOOL",
    Address: "6172 EXT12  JOUBERTON  KLERKSDORP",
    Latitude: "-26.9014",
    Longitude: "26.5998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20409",
    WardLookupId: "2503",
    VDNumber: "86870324",
    RegisteredPopulation: "1846",
    VotingStationName: "SEDIKO PRIMARY SCHOOL",
    Address: "15756 LETLHABILE STREET EXT 15  JOUBERTON  KLERKSDORP",
    Latitude: "-26.9033",
    Longitude: "26.5961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20410",
    WardLookupId: "2503",
    VDNumber: "86870537",
    RegisteredPopulation: "724",
    VotingStationName: "CHURCH OF CHRIST",
    Address: "X2532 PHAKAMILE  JUBERTON  KLERKSDORP",
    Latitude: "-26.8969",
    Longitude: "26.5998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20411",
    WardLookupId: "2504",
    VDNumber: "86870313",
    RegisteredPopulation: "2071",
    VotingStationName: "MOFATLHUSI SECONDARY SCHOOL",
    Address: "MADIBA  JOUBERTON  KLERKSDORP",
    Latitude: "-26.9114",
    Longitude: "26.6066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20412",
    WardLookupId: "2504",
    VDNumber: "86870335",
    RegisteredPopulation: "1870",
    VotingStationName: "ST. PAULS CHURCH",
    Address: "16675 DR. MOROKA  JOUBERTON  KLERKSDORP",
    Latitude: "-26.9109",
    Longitude: "26.6046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20413",
    WardLookupId: "2504",
    VDNumber: "86870548",
    RegisteredPopulation: "922",
    VotingStationName: "THEA MORAFE PRIMARY SCHOOL",
    Address: "14735 SUGARBUSH  JOUBERTON  KLERKSDORP",
    Latitude: "-26.9091",
    Longitude: "26.6111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20414",
    WardLookupId: "2505",
    VDNumber: "86870054",
    RegisteredPopulation: "2514",
    VotingStationName: "SCHOONSPRUIT HIGH SCHOOL",
    Address: "LEEMHUIS STREET  SONGLOED  KLERKSDORP",
    Latitude: "-26.8608",
    Longitude: "26.6442",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20415",
    WardLookupId: "2505",
    VDNumber: "86870065",
    RegisteredPopulation: "3091",
    VotingStationName: "MEIRINGSPARK PRIMARY SCHOOL",
    Address: "OOMJACOB  MEIRINGSPARK  KLERKSDORP",
    Latitude: "-26.8616",
    Longitude: "26.6322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20416",
    WardLookupId: "2506",
    VDNumber: "86870043",
    RegisteredPopulation: "3088",
    VotingStationName: "LA HOFF PRIMARY SCHOOL",
    Address: "C/O CORY & SANGIRO STREETS  LA HOFF  KLERKSDORP",
    Latitude: "-26.8279",
    Longitude: "26.654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20417",
    WardLookupId: "2506",
    VDNumber: "86870470",
    RegisteredPopulation: "1633",
    VotingStationName: "NG KERK FLIMIEDA",
    Address: "C/O VUURPYL & TULP  KLERKSDORP  FLIMIEDA",
    Latitude: "-26.8391",
    Longitude: "26.6504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20418",
    WardLookupId: "2507",
    VDNumber: "86870010",
    RegisteredPopulation: "2545",
    VotingStationName: "NHK DORINGKRUIN",
    Address: "C/O BOEKENHOUT & HIBISCUS STR  DORINGKRUIN  KLERKSDORP",
    Latitude: "-26.8169",
    Longitude: "26.6889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20419",
    WardLookupId: "2507",
    VDNumber: "86870032",
    RegisteredPopulation: "2748",
    VotingStationName: "WESVALIA HIGH SCHOOL",
    Address: "C/O READMAN & SCOTT STREET  WILKOPPIES  KLERKSDORP",
    Latitude: "-26.8432",
    Longitude: "26.6629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20420",
    WardLookupId: "2507",
    VDNumber: "86870559",
    RegisteredPopulation: "1322",
    VotingStationName: "GRACE LIFE CHURCH",
    Address: "73 AMETIS  KLERKSDORP  KLERKSDORP",
    Latitude: "-26.8373",
    Longitude: "26.6786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20421",
    WardLookupId: "2508",
    VDNumber: "86820228",
    RegisteredPopulation: "426",
    VotingStationName: "MAHEELO INTERMEDIATE SCHOOL",
    Address: "FARM SCHOOL  TIGANE  HARTEBEESFONTEIN",
    Latitude: "-26.7054",
    Longitude: "26.3882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20422",
    WardLookupId: "2508",
    VDNumber: "86820239",
    RegisteredPopulation: "787",
    VotingStationName: "AGS CHURCH HARTBEESFONTEIN",
    Address: "37 IRIS  HARTBEESFONTEIN  HARTBEESFONTEIN",
    Latitude: "-26.7606",
    Longitude: "26.4298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20423",
    WardLookupId: "2508",
    VDNumber: "86820240",
    RegisteredPopulation: "197",
    VotingStationName: "DUPPERSPOS PRIMARY SCHOOL",
    Address: "NA  TSELEME FARM  HARTBEESFONTEIN",
    Latitude: "-26.5276",
    Longitude: "26.4457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20424",
    WardLookupId: "2508",
    VDNumber: "86820273",
    RegisteredPopulation: "606",
    VotingStationName: "BOSWORTH PRIMARY SCHOOL",
    Address: "1 BOSWORTH PRIMARY SCHOOL  WITPOORT FARM  KLERKSDORP",
    Latitude: "-26.7491",
    Longitude: "26.5856",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20425",
    WardLookupId: "2508",
    VDNumber: "86820284",
    RegisteredPopulation: "210",
    VotingStationName: "FAAN MENTJIES",
    Address: "ROAD  KLERKSDORP  KLERKSDORP",
    Latitude: "-26.735",
    Longitude: "26.7049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20426",
    WardLookupId: "2508",
    VDNumber: "86820318",
    RegisteredPopulation: "426",
    VotingStationName: "LETLHASEDI COMBINED SCHOOL",
    Address: "P.O. BOX 547  BONNE ESPERANCE 339IP  HAARTEBEESFONTEIN",
    Latitude: "-26.6321",
    Longitude: "26.5966",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20427",
    WardLookupId: "2508",
    VDNumber: "86821027",
    RegisteredPopulation: "76",
    VotingStationName: "OPRAAP GENERAL DEALER",
    Address: "OPRAAP  KLERKSDORP",
    Latitude: "-26.5153",
    Longitude: "26.5577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20428",
    WardLookupId: "2508",
    VDNumber: "86821038",
    RegisteredPopulation: "241",
    VotingStationName: "MMBATHO PRIMARY SCHOOL",
    Address: "P.O. BOX 272  VENTERSDORP  KLERKSDORP",
    Latitude: "-26.5611",
    Longitude: "26.628",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20429",
    WardLookupId: "2508",
    VDNumber: "86821050",
    RegisteredPopulation: "489",
    VotingStationName: "PELONOMI INTERMEDIATE SCHOOL",
    Address: "POTCHEFSTROOM [POTCHEFSTROOM]  ",
    Latitude: "-26.7831",
    Longitude: "26.8117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20430",
    WardLookupId: "2508",
    VDNumber: "86821061",
    RegisteredPopulation: "406",
    VotingStationName: "THARI COMMUNITY HALL",
    Address: "FREEMANVILLE  KLERKSDORP",
    Latitude: "-26.8075",
    Longitude: "26.5706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20431",
    WardLookupId: "2508",
    VDNumber: "86821184",
    RegisteredPopulation: "147",
    VotingStationName: "BIANRIC - IKETLENG FARM INN",
    Address: "63 VENTERSDORP ROAD   KLERKSDORP DAME AREA  KLERSDORP DAME AREA",
    Latitude: "-26.839",
    Longitude: "26.6246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20432",
    WardLookupId: "2508",
    VDNumber: "86870492",
    RegisteredPopulation: "271",
    VotingStationName: "THE WHITE HOUSE GUESTHOUSE",
    Address: "YUSSIF DADOO  KLERKSDORP  KLERKSDORP",
    Latitude: "-26.8182",
    Longitude: "26.6666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20433",
    WardLookupId: "2509",
    VDNumber: "86870278",
    RegisteredPopulation: "1852",
    VotingStationName: "GREEK COMMUNITY HALL",
    Address: "161 PARK STREET  NESERHOF  KLERKSDORP",
    Latitude: "-26.8834",
    Longitude: "26.6599",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20434",
    WardLookupId: "2509",
    VDNumber: "86870289",
    RegisteredPopulation: "1868",
    VotingStationName: "CIVIC CENTRE BANQUET HALL",
    Address: "PRETORIA  NEW TOWN  KLERKSDORP",
    Latitude: "-26.8672",
    Longitude: "26.6641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20435",
    WardLookupId: "2509",
    VDNumber: "86870391",
    RegisteredPopulation: "1712",
    VotingStationName: "ROOSHEUWEL PRIMARY SCHOOL",
    Address: "C/O GOEDEHOOP AND SENEKAL  ROOSHEUWEL  KLERKSDORP",
    Latitude: "-26.8777",
    Longitude: "26.6421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20436",
    WardLookupId: "2510",
    VDNumber: "86900104",
    RegisteredPopulation: "1929",
    VotingStationName: "BATHABILE PRIMARY SCHOOL",
    Address: "MAFOHLA STREET  KANANA PROPEROF ORKNEY  KLERKSDORP",
    Latitude: "-26.9648",
    Longitude: "26.6343",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20437",
    WardLookupId: "2510",
    VDNumber: "86900171",
    RegisteredPopulation: "1823",
    VotingStationName: "NTATAISE PRIMARY SCHOOL",
    Address: "3954 LITHEKO  EXTENTION 4, KANANA, ORKNEY  KLERKSDORP",
    Latitude: "-26.9653",
    Longitude: "26.6249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20438",
    WardLookupId: "2511",
    VDNumber: "86900148",
    RegisteredPopulation: "2101",
    VotingStationName: "VAAL REEFS PRIMARY",
    Address: "TSHEPE DRIVE  VAAL REEFS  ORKNEY",
    Latitude: "-26.9402",
    Longitude: "26.7209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20439",
    WardLookupId: "2511",
    VDNumber: "86900159",
    RegisteredPopulation: "386",
    VotingStationName: "HOSTEL NO 5 COMMUNITY HALL",
    Address: "NO 5 SHAFT (HOSTEL)  KLERKSDORP",
    Latitude: "-26.9106",
    Longitude: "26.7532",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20440",
    WardLookupId: "2511",
    VDNumber: "86900160",
    RegisteredPopulation: "1135",
    VotingStationName: "EAST SINGLE QUARTERS HALL",
    Address: "FIRST AVENUE  UMUZIMUHLE  ORKNEY",
    Latitude: "-26.9332",
    Longitude: "26.7373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20441",
    WardLookupId: "2512",
    VDNumber: "86900227",
    RegisteredPopulation: "2347",
    VotingStationName: "ALIVE PRE-SCHOOL",
    Address: "20877 NEWTOWN  KANANA  ORKNEY",
    Latitude: "-26.9462",
    Longitude: "26.6456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20442",
    WardLookupId: "2512",
    VDNumber: "86900250",
    RegisteredPopulation: "1103",
    VotingStationName: "ST MARTINS CATHOLIC CHURCH",
    Address: "747 MAFOHLA STREET  KANANA  ORKNEY",
    Latitude: "-26.9483",
    Longitude: "26.6388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20443",
    WardLookupId: "2512",
    VDNumber: "86900306",
    RegisteredPopulation: "1418",
    VotingStationName: "SMILLING KIDS PRE SCHOOL",
    Address: "19598 EXTENSION 13  KANANA  ORKNEY",
    Latitude: "-26.9403",
    Longitude: "26.6537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20444",
    WardLookupId: "2513",
    VDNumber: "86900092",
    RegisteredPopulation: "3243",
    VotingStationName: "ARE-FENYENG PUBLIC SCHOOL",
    Address: "4373 STAND  KANANA  ORKNEY",
    Latitude: "-26.9448",
    Longitude: "26.6339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20445",
    WardLookupId: "2513",
    VDNumber: "86900238",
    RegisteredPopulation: "2395",
    VotingStationName: "TSHEBEDISANA SECONDARY SCHOOL",
    Address: "BUFFALO  KANANA  ORKNEY",
    Latitude: "-26.9469",
    Longitude: "26.6295",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20446",
    WardLookupId: "2514",
    VDNumber: "86900070",
    RegisteredPopulation: "1697",
    VotingStationName: "ATAMELA PRIMARY SCHOOL",
    Address: "X8533 MASERU  KANANA  ORKENY",
    Latitude: "-26.962",
    Longitude: "26.6185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20447",
    WardLookupId: "2514",
    VDNumber: "86900081",
    RegisteredPopulation: "1412",
    VotingStationName: "MMOKENG SECONDARY SCHOOL",
    Address: "3677 GABORONE STREET  EXTENSION 4  KANANA ORKNEY",
    Latitude: "-26.9619",
    Longitude: "26.6278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20448",
    WardLookupId: "2514",
    VDNumber: "86900249",
    RegisteredPopulation: "1651",
    VotingStationName: "RETHABILE EDU CARE CENTRE",
    Address: "8425 MBELE  KANANA  ORKNEY",
    Latitude: "-26.9663",
    Longitude: "26.6184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20449",
    WardLookupId: "2515",
    VDNumber: "86900126",
    RegisteredPopulation: "1905",
    VotingStationName: "KANANA SECONDARY SCHOOL",
    Address: "1495 RAMCWANA  STILFONTEIN  ORKNEY",
    Latitude: "-26.9615",
    Longitude: "26.633",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20450",
    WardLookupId: "2515",
    VDNumber: "86900137",
    RegisteredPopulation: "1714",
    VotingStationName: "THUTO-TSEBA MIDDLE SCHOOL",
    Address: "2245 DLAMINI STREET  KANANA  KLERKSDORP",
    Latitude: "-26.9618",
    Longitude: "26.6446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20451",
    WardLookupId: "2515",
    VDNumber: "86900283",
    RegisteredPopulation: "1280",
    VotingStationName: "INYATELO PRIMARY SCHOOL",
    Address: "KHANYISA STREET  KANANA  ORKNEY",
    Latitude: "-26.9567",
    Longitude: "26.6384",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20452",
    WardLookupId: "2516",
    VDNumber: "86900058",
    RegisteredPopulation: "1926",
    VotingStationName: "HLOKOZA SHOP",
    Address: "HOUSE Z11037  KANANA EXT. 10  ORKENY",
    Latitude: "-26.976",
    Longitude: "26.626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20453",
    WardLookupId: "2516",
    VDNumber: "86900115",
    RegisteredPopulation: "1564",
    VotingStationName: "KANANA COMMUNITY HALL",
    Address: "ID KHUMALO STREET  KANANA PROPER  KANANA ORKNEY",
    Latitude: "-26.9661",
    Longitude: "26.6415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20454",
    WardLookupId: "2516",
    VDNumber: "86900294",
    RegisteredPopulation: "1089",
    VotingStationName: "CHRIS HANI SOCCERFIELD EXT 10 (TENT)",
    Address: "N/A  ORKNEY  KANANA EXT 10",
    Latitude: "-26.9743",
    Longitude: "26.6311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20455",
    WardLookupId: "2517",
    VDNumber: "86900047",
    RegisteredPopulation: "1521",
    VotingStationName: "TIISETSANG PRE-SCHOOL AND CRECHE",
    Address: "12152 EXT.11  KANANA  ORKNEY",
    Latitude: "-26.9702",
    Longitude: "26.6075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20456",
    WardLookupId: "2517",
    VDNumber: "86900069",
    RegisteredPopulation: "1929",
    VotingStationName: "SELANG THUTO PRIMARY SCHOOL",
    Address: "LENONG STREET  EXTENTION 9 KANANA,ORKNEY  KLERKSDORP",
    Latitude: "-26.9706",
    Longitude: "26.624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20457",
    WardLookupId: "2517",
    VDNumber: "86900261",
    RegisteredPopulation: "1519",
    VotingStationName: "MATLHALENG SECONDARY SCHOOL",
    Address: "LENONG STREET  KANANA  ORKNEY",
    Latitude: "-26.9688",
    Longitude: "26.6175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20458",
    WardLookupId: "2518",
    VDNumber: "86820138",
    RegisteredPopulation: "259",
    VotingStationName: "REGINA KONTANTWINKEL",
    Address: "ORKNEY  KLERKSDORP",
    Latitude: "-27.0904",
    Longitude: "26.5061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20459",
    WardLookupId: "2518",
    VDNumber: "86900014",
    RegisteredPopulation: "2512",
    VotingStationName: "NOORDVAAL PRIMARY SCHOOL",
    Address: "BYRON AVENUE  ORKNEY  KLERKSDORP",
    Latitude: "-26.9882",
    Longitude: "26.6836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20460",
    WardLookupId: "2518",
    VDNumber: "86900025",
    RegisteredPopulation: "1671",
    VotingStationName: "ORKNEY SECONDARY SCHOOL",
    Address: "BROWNING AVENUE  ORKNEY  KLERKSDORP",
    Latitude: "-26.9771",
    Longitude: "26.678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20461",
    WardLookupId: "2518",
    VDNumber: "86900182",
    RegisteredPopulation: "457",
    VotingStationName: "SHAFT NO 10 HALL TAU LAKOA",
    Address: "TAU LAKOA  ORKNEY  KLERKSDORP",
    Latitude: "-26.9994",
    Longitude: "26.6075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20462",
    WardLookupId: "2519",
    VDNumber: "86870290",
    RegisteredPopulation: "1869",
    VotingStationName: "JOHAN DE WIT HALL",
    Address: "RANDLESPARK  KLERKSDORP",
    Latitude: "-26.8985",
    Longitude: "26.6764",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20463",
    WardLookupId: "2519",
    VDNumber: "86900036",
    RegisteredPopulation: "2435",
    VotingStationName: "ORKNEY PRIMARY SCHOOL",
    Address: "HOOD ROAD  ORKNEY  ORKNEY",
    Latitude: "-26.98",
    Longitude: "26.6645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20464",
    WardLookupId: "2520",
    VDNumber: "86930017",
    RegisteredPopulation: "2935",
    VotingStationName: "PEOPLES HALL",
    Address: "HAGARD STREET  STILFONTEIN  KLERKSDORP",
    Latitude: "-26.8455",
    Longitude: "26.7535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20465",
    WardLookupId: "2520",
    VDNumber: "86930152",
    RegisteredPopulation: "2096",
    VotingStationName: "DRIEFONTEINE LAERSKOOL",
    Address: "HARTSTRAAT  STILFONTEIN  STILFONTEIN",
    Latitude: "-26.8471",
    Longitude: "26.7677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20466",
    WardLookupId: "2521",
    VDNumber: "86930040",
    RegisteredPopulation: "2943",
    VotingStationName: "STILFONTEIN PRIMARY SCHOOL",
    Address: "C/O KRUGER & CRESCENT & JOUBERT RD  STILFONTEIN  KLERKSDORP",
    Latitude: "-26.839",
    Longitude: "26.7792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20467",
    WardLookupId: "2521",
    VDNumber: "86930130",
    RegisteredPopulation: "2520",
    VotingStationName: "DIRANG KA NATLA PRIMARY SCHOOL",
    Address: "MOKGETHI  KHUMA   STILFONTEIN",
    Latitude: "-26.8536",
    Longitude: "26.827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20468",
    WardLookupId: "2522",
    VDNumber: "86930039",
    RegisteredPopulation: "2767",
    VotingStationName: "LIBRARY KHUMA EXT 8",
    Address: "N/A EXTENTION 8  KHUMA  STILFONTEIN",
    Latitude: "-26.8531",
    Longitude: "26.8185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20469",
    WardLookupId: "2522",
    VDNumber: "86930163",
    RegisteredPopulation: "1257",
    VotingStationName: "THE CHURCH OF THE HOLY GHOST",
    Address: "EXT 8  KHUMA  STILFONTEIN",
    Latitude: "-26.8473",
    Longitude: "26.8148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20470",
    WardLookupId: "2523",
    VDNumber: "86930051",
    RegisteredPopulation: "3197",
    VotingStationName: "VUYANI MAWETHU SECONDARY SCHOOL",
    Address: "5514 MDAKA STREET  KHUMA  KLERKSDORP",
    Latitude: "-26.853",
    Longitude: "26.8504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20471",
    WardLookupId: "2523",
    VDNumber: "86930073",
    RegisteredPopulation: "715",
    VotingStationName: "GEMSTONE PARK",
    Address: "GEMSTONE PARK  BUFFELS  STILFONTEIN",
    Latitude: "-26.8995",
    Longitude: "26.8008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20472",
    WardLookupId: "2524",
    VDNumber: "86930107",
    RegisteredPopulation: "2172",
    VotingStationName: "NOZIZWE PRIMARY SCHOOL",
    Address: "450 MAZIBUKO STREET  KHUMA  STILFONTEIN",
    Latitude: "-26.8464",
    Longitude: "26.8653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20473",
    WardLookupId: "2524",
    VDNumber: "86930129",
    RegisteredPopulation: "1979",
    VotingStationName: "TUKISANG PRIMARY SCHOOL",
    Address: "592 TSOLO  KHUMA  STILFONTEIN",
    Latitude: "-26.8468",
    Longitude: "26.8694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20474",
    WardLookupId: "2525",
    VDNumber: "86930095",
    RegisteredPopulation: "1915",
    VotingStationName: "ABONTLE PRIMARY SCHOOL",
    Address: "SEWENTIENDE STREET  KHUMA  STILFONTEIN",
    Latitude: "-26.8505",
    Longitude: "26.8742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20475",
    WardLookupId: "2525",
    VDNumber: "86930174",
    RegisteredPopulation: "1128",
    VotingStationName: "GENERAL FAITH IN ZION CHURCH",
    Address: "7148 SOTHOMELA STREET EXT. 7  KHUMA  STILFONTEIN",
    Latitude: "-26.8584",
    Longitude: "26.8642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20476",
    WardLookupId: "2525",
    VDNumber: "86930185",
    RegisteredPopulation: "921",
    VotingStationName: "THUTO-THEBE SECONDARY SCHOOL",
    Address: "1360 POPO MOLEFE  KHUMA  STILFONTEIN",
    Latitude: "-26.8459",
    Longitude: "26.8752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20477",
    WardLookupId: "2526",
    VDNumber: "86870302",
    RegisteredPopulation: "1471",
    VotingStationName: "NG KLERKSDORP -SUID ELLATON",
    Address: "CHARLES  ELLATON  KLERKSDORP",
    Latitude: "-26.9054",
    Longitude: "26.6597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20478",
    WardLookupId: "2526",
    VDNumber: "86900272",
    RegisteredPopulation: "2740",
    VotingStationName: "KANANA PRIMARY SCHOOL",
    Address: "22271 HONESTLEY STREET  KANANA  ORKNEY",
    Latitude: "-26.9331",
    Longitude: "26.6485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20479",
    WardLookupId: "2527",
    VDNumber: "86870087",
    RegisteredPopulation: "1697",
    VotingStationName: "ITHUSENG PRIMARY SCHOOL",
    Address: "PHUTHADITSHABA  JOUBERTON  KLERKSDORP",
    Latitude: "-26.8848",
    Longitude: "26.583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20480",
    WardLookupId: "2527",
    VDNumber: "86870098",
    RegisteredPopulation: "1812",
    VotingStationName: "JONNYS INN",
    Address: "19020 EXT 19 EAST  JOUBETON  KLERKSDORP",
    Latitude: "-26.8783",
    Longitude: "26.596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20481",
    WardLookupId: "2527",
    VDNumber: "86870100",
    RegisteredPopulation: "2198",
    VotingStationName: "ARE-ITSHOKENG PUBLIC SCHOOL",
    Address: "KGOGONOKA  JOUBERTON  KLERKSDORP",
    Latitude: "-26.8882",
    Longitude: "26.5796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20482",
    WardLookupId: "2528",
    VDNumber: "86930084",
    RegisteredPopulation: "2806",
    VotingStationName: "BORAKANELO SECONDARY SCHOOL",
    Address: "CHURCH STREET  KHUMA  STILFONTEIN",
    Latitude: "-26.8532",
    Longitude: "26.8591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20483",
    WardLookupId: "2528",
    VDNumber: "86930118",
    RegisteredPopulation: "1553",
    VotingStationName: "HATA BUTLE PRIMARY SCHOOL",
    Address: "2454 MOKGETHI  KHUMA  STILFONTEIN",
    Latitude: "-26.8511",
    Longitude: "26.8684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20484",
    WardLookupId: "2529",
    VDNumber: "86870021",
    RegisteredPopulation: "4525",
    VotingStationName: "GOUDKOP PRIMARY SCHOOL",
    Address: "PLATAN AVENUE  FLAMWOOD  KLERKSDORP",
    Latitude: "-26.8489",
    Longitude: "26.6904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20485",
    WardLookupId: "2529",
    VDNumber: "86870571",
    RegisteredPopulation: "406",
    VotingStationName: "NG KERK GOUDKOP",
    Address: "6 GOUDKOP AVENUE  FLAMWOOD  KLERKSDORP",
    Latitude: "-26.8368",
    Longitude: "26.6971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20486",
    WardLookupId: "4505",
    VDNumber: "86960065",
    RegisteredPopulation: "1965",
    VotingStationName: "GONTSE PRIMARY SCHOOL",
    Address: "1836 EXTENSION 10  TSWELELANG  WOLMARANSSTAD",
    Latitude: "-27.2226",
    Longitude: "25.9672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20487",
    WardLookupId: "4505",
    VDNumber: "86960100",
    RegisteredPopulation: "1040",
    VotingStationName: "MAITEMOGELO COMPREHENSIVE HIGH SCHOOL",
    Address: "940 EXT 10 MAIN STREET  TSWELELANG  WOLMARANSSTAD",
    Latitude: "-27.2178",
    Longitude: "25.9616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20488",
    WardLookupId: "4506",
    VDNumber: "86960010",
    RegisteredPopulation: "2072",
    VotingStationName: "BOINEELO PUBLIC SCHOOL",
    Address: "HOOFWEG  TSWELELANG  WOLMARANSSTAD",
    Latitude: "-27.2346",
    Longitude: "25.9728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20489",
    WardLookupId: "4506",
    VDNumber: "86960098",
    RegisteredPopulation: "989",
    VotingStationName: "MARANATHA REFORMED CHURCH OF CHRIST",
    Address: "92 TOKOLOGO STR  TSWELELANG  WOLMARANSSTAD",
    Latitude: "-27.2292",
    Longitude: "25.9737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20490",
    WardLookupId: "4507",
    VDNumber: "86960032",
    RegisteredPopulation: "2089",
    VotingStationName: "RAGOGANG PRIMARY SCHOOL",
    Address: "POTSANA  TSWELELANG  WOLMARANSSTAD",
    Latitude: "-27.2223",
    Longitude: "25.9786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20491",
    WardLookupId: "4507",
    VDNumber: "86960076",
    RegisteredPopulation: "1053",
    VotingStationName: "AFRICAN METHODIST EPISCOPAL CHURCH",
    Address: "342 PHUTI  TSWELELANG  WOLMARANSSTAD",
    Latitude: "-27.2244",
    Longitude: "25.9832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20492",
    WardLookupId: "4508",
    VDNumber: "86960021",
    RegisteredPopulation: "2093",
    VotingStationName: "BOPHEPA PUBLIC SCHOOL",
    Address: "439 MOSUHLANE STR  TSWELELANG  WOLMARANSSTAD",
    Latitude: "-27.2305",
    Longitude: "25.9795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20493",
    WardLookupId: "4508",
    VDNumber: "86960054",
    RegisteredPopulation: "624",
    VotingStationName: "BOIPELO BOITUMELONG CRECHE",
    Address: "1444 CNR MASIBI AND MASILO STREET  TSWELELANG  WOLMARANSSTAD",
    Latitude: "-27.2356",
    Longitude: "25.9768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20494",
    WardLookupId: "4508",
    VDNumber: "86960111",
    RegisteredPopulation: "453",
    VotingStationName: "REABONA  SECONDARY SCHOOL",
    Address: "115 MPOLOKENG STREET  TSWELELANG  WOLMARANS STAD",
    Latitude: "-27.2241",
    Longitude: "25.9737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20495",
    WardLookupId: "4509",
    VDNumber: "86820060",
    RegisteredPopulation: "334",
    VotingStationName: "SKIETPAN PRIMARY SCHOOL",
    Address: "00 N/A  WOLMARANSSTAD  WOLMARANSSTAD",
    Latitude: "-27.1314",
    Longitude: "25.8244",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20496",
    WardLookupId: "4509",
    VDNumber: "86820824",
    RegisteredPopulation: "55",
    VotingStationName: "MAHLOMABEDI PRIMARY SCHOOL",
    Address: "00 N/A  BRAKVLEI  WOLMARANSSTAD",
    Latitude: "-26.9771",
    Longitude: "25.7222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20497",
    WardLookupId: "4509",
    VDNumber: "86820846",
    RegisteredPopulation: "115",
    VotingStationName: "ELKORNIA FARM SCHOOL",
    Address: "00 N/A  KATBOSFONTEIN  WOLMARANSSTAD",
    Latitude: "-27.1248",
    Longitude: "25.6916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20498",
    WardLookupId: "4509",
    VDNumber: "86960043",
    RegisteredPopulation: "2186",
    VotingStationName: "WOLLIES TOWN HALL",
    Address: "19 BROADBENT STR  WOLMARANSSTAD",
    Latitude: "-27.2001",
    Longitude: "25.9835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20499",
    WardLookupId: "4509",
    VDNumber: "86960122",
    RegisteredPopulation: "1039",
    VotingStationName: "WELCOME DAY CARE CENTRE",
    Address: "4608 EXTENSION 15  TSWELELANG   WOLMARANSSTAD",
    Latitude: "-27.2142",
    Longitude: "25.9716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20500",
    WardLookupId: "4510",
    VDNumber: "86820127",
    RegisteredPopulation: "189",
    VotingStationName: "DIEPKUIL SHOP",
    Address: "DIEPKUIL  LEEUDORINGSTAD",
    Latitude: "-27.3276",
    Longitude: "26.3425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20501",
    WardLookupId: "4510",
    VDNumber: "86880022",
    RegisteredPopulation: "2311",
    VotingStationName: "REA BOPA EARLY LEARNING CENTRE",
    Address: "2028 LEGODI  KGAKALA  LEEUDORINGSTAD",
    Latitude: "-27.2145",
    Longitude: "26.2749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20502",
    WardLookupId: "4510",
    VDNumber: "86880033",
    RegisteredPopulation: "915",
    VotingStationName: "LEEUDORINGSTAD MUNICIPALITY OFFICE",
    Address: "51 PAUL KRUGER STR  LEEUDORINGSTAD WEST  LEEUDORINGSTAD",
    Latitude: "-27.2367",
    Longitude: "26.2378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20503",
    WardLookupId: "4511",
    VDNumber: "86880011",
    RegisteredPopulation: "1698",
    VotingStationName: "THAKADU PRIMARY SCHOOL",
    Address: "435 LESANE STREET  KGAKALA  LEEUDORINGSTAD",
    Latitude: "-27.2197",
    Longitude: "26.2663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20504",
    WardLookupId: "4511",
    VDNumber: "86880044",
    RegisteredPopulation: "891",
    VotingStationName: "TIRAGALO SECONDARY SCHOOL",
    Address: "20 BORWA STREET  KGAKALA  LEEUDORINGSTAD",
    Latitude: "-27.2198",
    Longitude: "26.2706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20505",
    WardLookupId: "4512",
    VDNumber: "86820071",
    RegisteredPopulation: "308",
    VotingStationName: "SAMUEL PHIRI PRIMARY SCHOOL",
    Address: "00 N/A  VOGELSTRUISKUIL  WOLMARANSSTAD",
    Latitude: "-26.9715",
    Longitude: "25.8068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20506",
    WardLookupId: "4512",
    VDNumber: "86820116",
    RegisteredPopulation: "99",
    VotingStationName: "MASIMONG FARM SCHOOL",
    Address: "00 N/A  SYFERBULT  LEEUDORINGSTAD",
    Latitude: "-27.3527",
    Longitude: "26.2268",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20507",
    WardLookupId: "4512",
    VDNumber: "86820149",
    RegisteredPopulation: "165",
    VotingStationName: "DOORNFONTEIN FARM SCHOOL",
    Address: "00 N/A  WOLMARANSSTAD  WOLMARANSSTAD",
    Latitude: "-26.9292",
    Longitude: "26.1672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20508",
    WardLookupId: "4512",
    VDNumber: "86820150",
    RegisteredPopulation: "98",
    VotingStationName: "INHOEK FARM SCHOOL",
    Address: "00 N/A  LEEUDORINGSTAD  LEEUDORINGSTAD",
    Latitude: "-27.23",
    Longitude: "26.151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20509",
    WardLookupId: "4512",
    VDNumber: "86820835",
    RegisteredPopulation: "220",
    VotingStationName: "KLIPRIF KONTANTWINKEL",
    Address: "0 KLIPRIF  WOLMARANSSTAD",
    Latitude: "-26.9395",
    Longitude: "25.9282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20510",
    WardLookupId: "4512",
    VDNumber: "86821139",
    RegisteredPopulation: "1970",
    VotingStationName: "KOKETSO PRIMARY SCHOOL",
    Address: "EXTENSION 5  KGAKALA  LEEUDORINGSTAD",
    Latitude: "-27.2265",
    Longitude: "26.2443",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20511",
    WardLookupId: "4512",
    VDNumber: "86980012",
    RegisteredPopulation: "1212",
    VotingStationName: "WITPOORT OFFICE COUNCIL CHAMBER",
    Address: "115 VOORTREKKERSTRAAT  WITPOORT  WOLMARANSSTAD",
    Latitude: "-27.1759",
    Longitude: "26.1224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20512",
    WardLookupId: "4513",
    VDNumber: "86820048",
    RegisteredPopulation: "110",
    VotingStationName: "PHAKEDI PRIMARY SCHOOL",
    Address: "00 BRANDEWYSKUIL  MAKWASSIE  MAKWASSIE",
    Latitude: "-27.4054",
    Longitude: "26.0616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20513",
    WardLookupId: "4513",
    VDNumber: "86821241",
    RegisteredPopulation: "216",
    VotingStationName: "PEACE IN JERUSALEM ZION CHURCH",
    Address: "3385 LEBALENG EXTENSION 3  LEBALENG  MAKWASSIE",
    Latitude: "-27.3352",
    Longitude: "25.9754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20514",
    WardLookupId: "4513",
    VDNumber: "86890012",
    RegisteredPopulation: "2297",
    VotingStationName: "ATLARELANG PRIMARY SCHOOL",
    Address: "1233 KWANTLE STREET  LEBALENG  MAKWASSIE",
    Latitude: "-27.3278",
    Longitude: "25.9773",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20515",
    WardLookupId: "4513",
    VDNumber: "86890023",
    RegisteredPopulation: "526",
    VotingStationName: "MAKWASSIE PRIMARY SCHOOL",
    Address: "10 ELLIS STREET  MAKWASSIE  MAKWASSIE",
    Latitude: "-27.3133",
    Longitude: "25.9973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20516",
    WardLookupId: "4514",
    VDNumber: "86820026",
    RegisteredPopulation: "148",
    VotingStationName: "HONINGKRANS SHOP",
    Address: "RONDEVLEI 126HP, OLD SCHOOL  DOORNBULT",
    Latitude: "-27.5405",
    Longitude: "26.0633",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20517",
    WardLookupId: "4514",
    VDNumber: "86820037",
    RegisteredPopulation: "574",
    VotingStationName: "BOSKUIL COMBINED SCHOOL",
    Address: "00 N/A  KINGSWOOD  WOLMARANSSTAD",
    Latitude: "-27.412",
    Longitude: "25.8796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20518",
    WardLookupId: "4514",
    VDNumber: "86820059",
    RegisteredPopulation: "228",
    VotingStationName: "HOLLOWAYSRUST PRIMARY SCHOOL",
    Address: "N/A  HOLLOWAYSTRUST  WOLMARANSSTAD",
    Latitude: "-27.2071",
    Longitude: "25.8262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20519",
    WardLookupId: "4514",
    VDNumber: "86820105",
    RegisteredPopulation: "83",
    VotingStationName: "VLIEEKRAAL FARM",
    Address: "VLIEEKRAAL  MAKWASSIE",
    Latitude: "-27.4907",
    Longitude: "26.0823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20520",
    WardLookupId: "4514",
    VDNumber: "86960087",
    RegisteredPopulation: "2848",
    VotingStationName: "TROTSVILLE PRIMARY SCHOOL",
    Address: "4 CNR STEYTLER AND SESING  TROSTVILLE  WOLMARANSSTAD",
    Latitude: "-27.2054",
    Longitude: "25.9624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20521",
    WardLookupId: "4514",
    VDNumber: "86960133",
    RegisteredPopulation: "190",
    VotingStationName: "RISE AND SHINE CRECHE",
    Address: "6919 TSWELELANG EXTENSION 17  TSWELELANG   WOLMARANSSTAD",
    Latitude: "-27.2258",
    Longitude: "25.9529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20522",
    WardLookupId: "4514",
    VDNumber: "86960144",
    RegisteredPopulation: "162",
    VotingStationName: "CONGREGATIONAL MEMORIAL CHURCH OF CHRIST IN AFRICA",
    Address: "6438 TSWELELANG EXTENSION 17  WOLMARANSSTAD",
    Latitude: "-27.2218",
    Longitude: "25.9586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20523",
    WardLookupId: "4515",
    VDNumber: "86890034",
    RegisteredPopulation: "2170",
    VotingStationName: "LERUNTSE LESEDI SECONDARY SCHOOL",
    Address: "1067 MOGOKARE STREET  LEBALENG  MAKWASSIE",
    Latitude: "-27.3273",
    Longitude: "25.9818",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20524",
    WardLookupId: "4515",
    VDNumber: "86890045",
    RegisteredPopulation: "1727",
    VotingStationName: "REATLEGILE CRECHE",
    Address: "27 BERENG STREET  LEBALENG  MAKWASSIE",
    Latitude: "-27.3273",
    Longitude: "25.9718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20525",
    WardLookupId: "2530",
    VDNumber: "86821094",
    RegisteredPopulation: "2084",
    VotingStationName: "UNITING REFORMED CHURCH IN SA",
    Address: "10733 LENONG  BOIPATONG  POTCHEFSTROOM",
    Latitude: "-26.7413",
    Longitude: "27.0108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20526",
    WardLookupId: "2530",
    VDNumber: "86910341",
    RegisteredPopulation: "612",
    VotingStationName: "GEREFORMEERDE KERK EXT 3",
    Address: "IKAGENG   SARAFINA EXT 03  POTCHEFSTROOM [POTCHEFSTROOM]",
    Latitude: "-26.7353",
    Longitude: "27.0199",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20527",
    WardLookupId: "2530",
    VDNumber: "86910587",
    RegisteredPopulation: "1038",
    VotingStationName: "IPELENG LIBRARY",
    Address: "BIG STREET  IKAGENG  POTCHEFSTROOM",
    Latitude: "-26.7376",
    Longitude: "27.016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20528",
    WardLookupId: "2531",
    VDNumber: "86820453",
    RegisteredPopulation: "372",
    VotingStationName: "TOLMAY SHOP",
    Address: "N/A  KROMDRAAI 420IP  POTCHEFSTROOM",
    Latitude: "-26.8629",
    Longitude: "26.9392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20529",
    WardLookupId: "2531",
    VDNumber: "86820464",
    RegisteredPopulation: "1696",
    VotingStationName: "NG MIEDERPARK",
    Address: "AARBEI LAAN  MIEDERPARK  POTCHEFSTROOM",
    Latitude: "-26.7387",
    Longitude: "27.0978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20530",
    WardLookupId: "2531",
    VDNumber: "86820475",
    RegisteredPopulation: "142",
    VotingStationName: "OLD POLICE STATION",
    Address: "N/A  VENTERSKROON  POTCHEFSTROOM",
    Latitude: "-26.8866",
    Longitude: "27.2693",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20531",
    WardLookupId: "2531",
    VDNumber: "86820486",
    RegisteredPopulation: "735",
    VotingStationName: "GROENEWALDâ€™S RESIDENCE",
    Address: "N/A  ROODEKRAAL 454IQ  POTCHEFSTROOM",
    Latitude: "-26.8237",
    Longitude: "27.1696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20532",
    WardLookupId: "2531",
    VDNumber: "86820543",
    RegisteredPopulation: "291",
    VotingStationName: "BUFFELSHOEK POLICE STATION",
    Address: "PARYS LINE  BUFFELSHOEK 471IQ  POTCHEFSTROOM",
    Latitude: "-26.8031",
    Longitude: "27.4",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20533",
    WardLookupId: "2531",
    VDNumber: "86820554",
    RegisteredPopulation: "220",
    VotingStationName: "EARTH ADVENTURES",
    Address: "21 FARM  KOPIESKRAAL  POTCHEFSTROOM",
    Latitude: "-26.9046",
    Longitude: "27.3997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20534",
    WardLookupId: "2531",
    VDNumber: "86820688",
    RegisteredPopulation: "437",
    VotingStationName: "NTHEBE PRIMARY SCHOOL",
    Address: "BRONKONHOST  LINDEQUESDRIFT  POTCHEFSTROOM",
    Latitude: "-26.7376",
    Longitude: "27.5725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20535",
    WardLookupId: "2532",
    VDNumber: "86820510",
    RegisteredPopulation: "927",
    VotingStationName: "VYFHOEK SCHOOL",
    Address: "N/A  VYFHOEK  POTCHEFSTROOM",
    Latitude: "-26.6766",
    Longitude: "27.1568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20536",
    WardLookupId: "2532",
    VDNumber: "86820521",
    RegisteredPopulation: "976",
    VotingStationName: "FIKADIBENG PRIMARY SCHOOL",
    Address: "BOTHA STR  KLIPDRIFT  POTCHEFSTROOM",
    Latitude: "-26.6442",
    Longitude: "27.2568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20537",
    WardLookupId: "2532",
    VDNumber: "86910307",
    RegisteredPopulation: "1213",
    VotingStationName: "AGS SUID CHURCH (MIEDERPARK)",
    Address: "10  MAHERRY STREET   MIEDERPARK  POTCHEFSTROOM",
    Latitude: "-26.7352",
    Longitude: "27.0984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20538",
    WardLookupId: "2532",
    VDNumber: "86910532",
    RegisteredPopulation: "2241",
    VotingStationName: "DIE WILGE HIGH SCHOOL",
    Address: "NEETHLING STREET  BAILIE PARK  POTCHEFSTROOM",
    Latitude: "-26.7104",
    Longitude: "27.1168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20539",
    WardLookupId: "2533",
    VDNumber: "86910015",
    RegisteredPopulation: "2155",
    VotingStationName: "POTCHEFSTROOM PRIMARY SCHOOL",
    Address: "KRUGER ST.  POTCHEFSTROOM CENTRAL  POTCHEFSTROOM",
    Latitude: "-26.7404",
    Longitude: "27.0898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20540",
    WardLookupId: "2533",
    VDNumber: "86910363",
    RegisteredPopulation: "914",
    VotingStationName: "ST EPHRAIM ROMAN CATHOLIC CHURCH",
    Address: "MANDELA ROAD  KANANA  POTCHEFSTROOM [POTCHEFSTROOM]",
    Latitude: "-26.736",
    Longitude: "27.0444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20541",
    WardLookupId: "2534",
    VDNumber: "86910026",
    RegisteredPopulation: "2755",
    VotingStationName: "ML FICK SCHOOL",
    Address: "203 VAN RIEBEECK ST.  POTCHEFSTROOM CENTRAL  POTCHEFSTROOM",
    Latitude: "-26.7248",
    Longitude: "27.0917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20542",
    WardLookupId: "2535",
    VDNumber: "86910060",
    RegisteredPopulation: "1261",
    VotingStationName: "N.W.P HEALTH",
    Address: "GERRIT MARITZ STREET  DASSIERAND  POTCHEFSTROOM",
    Latitude: "-26.6983",
    Longitude: "27.0768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20543",
    WardLookupId: "2535",
    VDNumber: "86910161",
    RegisteredPopulation: "1577",
    VotingStationName: "NG KERK NORTH (MOKOKO)",
    Address: "RAMHITSHANE STREET  IKAGENG  POTCHEFSTROOM [POTCHEFSTROOM]",
    Latitude: "-26.7192",
    Longitude: "27.0606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20544",
    WardLookupId: "2535",
    VDNumber: "86910486",
    RegisteredPopulation: "383",
    VotingStationName: "IKAGENG MUNICIPAL OFFICES HALL",
    Address: "IKAGENG  POTCHEFSTROOM  POTCHEFSTROOM",
    Latitude: "-26.7193",
    Longitude: "27.0629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20545",
    WardLookupId: "2536",
    VDNumber: "86910385",
    RegisteredPopulation: "2472",
    VotingStationName: "NG KERK",
    Address: "N/A  POTCHEFSTROOM  POTCHEFSTROOM [POTCHEFSTROOM]",
    Latitude: "-26.6922",
    Longitude: "27.0872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20546",
    WardLookupId: "2536",
    VDNumber: "86910598",
    RegisteredPopulation: "1924",
    VotingStationName: "NG KERK(DIE BULT)",
    Address: "HOFFMAN STREET  POTCHEFSTROOM  POTCHEFSTROOM",
    Latitude: "-26.6954",
    Longitude: "27.0906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20547",
    WardLookupId: "2537",
    VDNumber: "86910149",
    RegisteredPopulation: "1459",
    VotingStationName: "BOTOKA TECHNICAL SCHOOL",
    Address: "1 MOGOTSI ST.  IKAGENG  POTCHEFSTROOM",
    Latitude: "-26.7248",
    Longitude: "27.0572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20548",
    WardLookupId: "2537",
    VDNumber: "86910318",
    RegisteredPopulation: "660",
    VotingStationName: "THEMBALIDANISI  PRIMARY SCHOOL",
    Address: "TONAKGOLO STREET  IKAGENG  POTCHEFSTROOM [POTCHEFSTROOM]",
    Latitude: "-26.7236",
    Longitude: "27.0582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20549",
    WardLookupId: "2537",
    VDNumber: "86910419",
    RegisteredPopulation: "722",
    VotingStationName: "BOIKI TLHAPI CLINIC",
    Address: "BATHOENG ST  IKAGENG  POTCHEFSTROOM",
    Latitude: "-26.7176",
    Longitude: "27.0562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20550",
    WardLookupId: "2538",
    VDNumber: "86910228",
    RegisteredPopulation: "2799",
    VotingStationName: "BOITSHOKO SCHOOL",
    Address:
      "CNR LEKHELE & MAPHONGO STREET  IKAGENG  POTCHEFSTROOM [POTCHEFSTROOM]",
    Latitude: "-26.7188",
    Longitude: "27.0461",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20551",
    WardLookupId: "2538",
    VDNumber: "86910240",
    RegisteredPopulation: "1040",
    VotingStationName: "MOHADIN COMMUNITY HALL",
    Address: "MOHADIN  POTCHEFSTROOM",
    Latitude: "-26.7156",
    Longitude: "27.0332",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20552",
    WardLookupId: "2539",
    VDNumber: "86910127",
    RegisteredPopulation: "1751",
    VotingStationName: "MADIBENG SCHOOL",
    Address: "4622 NKOSI STR  IKAGENG  POTCHEFSTROOM",
    Latitude: "-26.7251",
    Longitude: "27.0468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20553",
    WardLookupId: "2539",
    VDNumber: "86910150",
    RegisteredPopulation: "1056",
    VotingStationName: "GOVAN MBEKI COMMUNITY HALL",
    Address: "PHUDU STREET  IKAGENG  POTCHEFSTROOM",
    Latitude: "-26.7215",
    Longitude: "27.0515",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20554",
    WardLookupId: "2540",
    VDNumber: "86910183",
    RegisteredPopulation: "971",
    VotingStationName: "ANGLICAN CHURCH RESURRECTION",
    Address: "0 LEGODI STR  IKAGENG  POTCHEFSTROOM",
    Latitude: "-26.7146",
    Longitude: "27.0593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20555",
    WardLookupId: "2540",
    VDNumber: "86910497",
    RegisteredPopulation: "493",
    VotingStationName: "NANOGANG PRIMARY SCHOOL",
    Address: "IKAGENG  POTCHEFSTROOM  POTCHEFSTROOM",
    Latitude: "-26.7128",
    Longitude: "27.0628",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20556",
    WardLookupId: "2540",
    VDNumber: "86910644",
    RegisteredPopulation: "257",
    VotingStationName: "WITRAND HOSPITAL HALL",
    Address: "N/A  DASIRAND  POTCHEFSTROOM",
    Latitude: "-26.6878",
    Longitude: "27.0715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20557",
    WardLookupId: "2541",
    VDNumber: "86910239",
    RegisteredPopulation: "1977",
    VotingStationName: "LESEGO PRIMARY SCHOOL",
    Address: "2983 MARAGANYE STREET  IKAGENG  POTCHEFSTROOM",
    Latitude: "-26.7278",
    Longitude: "27.0541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20558",
    WardLookupId: "2541",
    VDNumber: "86910329",
    RegisteredPopulation: "712",
    VotingStationName: "KEAGILE PRIMARY SCHOOL",
    Address: "N/A  IKAGENG  POTCHEFSTROOM [POTCHEFSTROOM]",
    Latitude: "-26.7282",
    Longitude: "27.0513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20559",
    WardLookupId: "2541",
    VDNumber: "86910543",
    RegisteredPopulation: "522",
    VotingStationName: "IKAGENG MAGISTRATE COURT",
    Address: "MOGOTSI  IKAGENG  POTCHEFSTROOM",
    Latitude: "-26.7211",
    Longitude: "27.0624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20560",
    WardLookupId: "2542",
    VDNumber: "86910206",
    RegisteredPopulation: "1883",
    VotingStationName: "B.M CHOABI PRIMARY SCHOOL",
    Address: "60 KLEURPOORT  PROMOSA  POTCHEFSTROOM",
    Latitude: "-26.7011",
    Longitude: "27.0308",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20561",
    WardLookupId: "2542",
    VDNumber: "86910284",
    RegisteredPopulation: "981",
    VotingStationName: "PROMOSA METHODIST CHURCH",
    Address: "BLOMMETJIE  PROMOSA  POTCHEFSTROOM",
    Latitude: "-26.7006",
    Longitude: "27.0347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20562",
    WardLookupId: "2542",
    VDNumber: "86910420",
    RegisteredPopulation: "984",
    VotingStationName: "TODDLER TOWN DAY CARE",
    Address: "N/A  PROMOSA  POTCHEFSTROOM",
    Latitude: "-26.6924",
    Longitude: "27.0337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20563",
    WardLookupId: "2543",
    VDNumber: "86910172",
    RegisteredPopulation: "2027",
    VotingStationName: "BOITIRELO PRIMARY SCHOOL",
    Address: "CNR RADITSABENG AND MAMOGALE STREETS  IKAGENG  POTCHEFSTROOM",
    Latitude: "-26.7165",
    Longitude: "27.052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20564",
    WardLookupId: "2543",
    VDNumber: "86910408",
    RegisteredPopulation: "1259",
    VotingStationName: "TSHEPO PRIMARY SCHOOL",
    Address: "DITHANE  IKAGENG  POTCHEFSTROOM",
    Latitude: "-26.7125",
    Longitude: "27.0524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20565",
    WardLookupId: "2544",
    VDNumber: "86910037",
    RegisteredPopulation: "1436",
    VotingStationName: "POTCHEFSTROOM CENTRAL PRIMARY SCHOOL",
    Address: "45 KRUGER STREET  POTCHEFSTROOM  POTCHEFSTROOM",
    Latitude: "-26.7153",
    Longitude: "27.09",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20566",
    WardLookupId: "2544",
    VDNumber: "86910475",
    RegisteredPopulation: "770",
    VotingStationName: "POTCHEFSTROOM DIENSSENTRUM VIR DIE BEJAARDES",
    Address: "90 CHRIS HANI  POTCHEFSTROOM  POTCHEFSTROOM",
    Latitude: "-26.7273",
    Longitude: "27.0766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20567",
    WardLookupId: "2544",
    VDNumber: "86910565",
    RegisteredPopulation: "679",
    VotingStationName: "PRESIDENT PRETORIUS PRIMARY SCHOOL",
    Address: "PETER MOKABA  CENTRAL  POTCHEFSTROOM",
    Latitude: "-26.7054",
    Longitude: "27.0947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20568",
    WardLookupId: "2544",
    VDNumber: "86910600",
    RegisteredPopulation: "221",
    VotingStationName: "ANGLICAN CHURCH(ST MARYS)",
    Address: "AUTO  POTCHEFSTROOM CENTRAL  POTCHEFSTROOM",
    Latitude: "-26.7125",
    Longitude: "27.0961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20569",
    WardLookupId: "2545",
    VDNumber: "86910116",
    RegisteredPopulation: "1155",
    VotingStationName: "TLOKWE SECONDARY SCHOOL",
    Address: "N/A  IKAGENG  POTCHEFSTROOM",
    Latitude: "-26.7255",
    Longitude: "27.044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20570",
    WardLookupId: "2545",
    VDNumber: "86910138",
    RegisteredPopulation: "1477",
    VotingStationName: "PUDULOGO SCHOOL",
    Address: "MADI STR  IKAGENG  POTCHEFSTROOM",
    Latitude: "-26.7278",
    Longitude: "27.0497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20571",
    WardLookupId: "2545",
    VDNumber: "86910431",
    RegisteredPopulation: "990",
    VotingStationName: "OLD APOSTOLIC CHURCH",
    Address: "6178 NKOSI  POTCHEFSTROOM  POTCHEFSTROOM",
    Latitude: "-26.7325",
    Longitude: "27.0422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20572",
    WardLookupId: "2546",
    VDNumber: "86910217",
    RegisteredPopulation: "1549",
    VotingStationName: "KEOTSHEPILE PRIMARY SCHOOL",
    Address: "EXT 07  TSHEPISONG  POTCHEFSTROOM",
    Latitude: "-26.7187",
    Longitude: "27.0211",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20573",
    WardLookupId: "2546",
    VDNumber: "86910442",
    RegisteredPopulation: "1861",
    VotingStationName: "PROMOSA EXT 3 (TENT)",
    Address: "POTCHEFSTROOM  ",
    Latitude: "-26.6864",
    Longitude: "27.03",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20574",
    WardLookupId: "2546",
    VDNumber: "86910666",
    RegisteredPopulation: "333",
    VotingStationName: "HAPPY LAND DAY CARE CENTRE",
    Address: "N/A  PROMOSA EXT 3  POTCHEFSTROOM",
    Latitude: "-26.6887",
    Longitude: "27.0353",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20575",
    WardLookupId: "2547",
    VDNumber: "86910082",
    RegisteredPopulation: "1742",
    VotingStationName: "CHRIS HANI COMMUNITY HALL",
    Address: "6399 STADIUM STREET  IKAGENG  POTCHEFSTROOM",
    Latitude: "-26.7413",
    Longitude: "27.017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20576",
    WardLookupId: "2547",
    VDNumber: "86910352",
    RegisteredPopulation: "1776",
    VotingStationName: "DITAELONG PRIMARY SCHOOL",
    Address: "N/A  SARAFINA  POTCHEFSTROOM [POTCHEFSTROOM]",
    Latitude: "-26.7402",
    Longitude: "27.0256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20577",
    WardLookupId: "2548",
    VDNumber: "86910093",
    RegisteredPopulation: "2233",
    VotingStationName: "SEIPHEMELO SCHOOL",
    Address: "IKANENG STREET  KANANA  POTCHEFSTROOM",
    Latitude: "-26.7342",
    Longitude: "27.0405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20578",
    WardLookupId: "2548",
    VDNumber: "86910330",
    RegisteredPopulation: "1142",
    VotingStationName: "TOP CITY KEGOMODITSE (TENT)",
    Address: "KEGOMODITSE  IKAGENG TOP CITY  POTCHEFSTROOM",
    Latitude: "-26.7318",
    Longitude: "27.0359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20579",
    WardLookupId: "2549",
    VDNumber: "86820756",
    RegisteredPopulation: "1887",
    VotingStationName: "AERIAL  SECTION EXT 6 (TENT)",
    Address: "10733 LENONG STREET  IKAGENG  POTCHESTROOM",
    Latitude: "-26.7393",
    Longitude: "27.0113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20580",
    WardLookupId: "2549",
    VDNumber: "86910576",
    RegisteredPopulation: "645",
    VotingStationName: "BA SEOBI HIGH SCHOOL",
    Address: "MADIKIZELA  IKAGENG LOCATION  POTCHEFSTROOM",
    Latitude: "-26.7232",
    Longitude: "27.0198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20581",
    WardLookupId: "2549",
    VDNumber: "86910622",
    RegisteredPopulation: "1038",
    VotingStationName: "FULL GOSPEL CHURCH",
    Address: "BOIPELO  IKAGENG  POTCHEFSTROOM",
    Latitude: "-26.7264",
    Longitude: "27.0187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20582",
    WardLookupId: "2549",
    VDNumber: "86910633",
    RegisteredPopulation: "474",
    VotingStationName: "ST AUGUSTINE ANGLICAN CHURCH",
    Address: "JOSE STREET  IKAGENG  POTCHEFSTROOM",
    Latitude: "-26.736",
    Longitude: "27.0156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20583",
    WardLookupId: "2550",
    VDNumber: "86910194",
    RegisteredPopulation: "2374",
    VotingStationName: "PROMOSA COMMUNITY HALL",
    Address: "32 CHRIS SWART STR  PROMOSA  POTCHEFSTROOM",
    Latitude: "-26.7071",
    Longitude: "27.0334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20584",
    WardLookupId: "2550",
    VDNumber: "86910453",
    RegisteredPopulation: "1300",
    VotingStationName: "SPORTSGROUND EXT 7 (TENT)",
    Address: "POTCHEFSTROOM  ",
    Latitude: "-26.7125",
    Longitude: "27.0236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20585",
    WardLookupId: "2551",
    VDNumber: "86910251",
    RegisteredPopulation: "2410",
    VotingStationName: "CACHET PARK (BULT) TENT",
    Address: "N/A  THE BULT  POTCHEFSTROOM",
    Latitude: "-26.6937",
    Longitude: "27.0935",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20586",
    WardLookupId: "2552",
    VDNumber: "86910374",
    RegisteredPopulation: "2617",
    VotingStationName: "FERDINAND POSTMA SCHOOL",
    Address: "N/A  POTCHEFSTROOM  POTCHEFSTROOM",
    Latitude: "-26.7069",
    Longitude: "27.1145",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20587",
    WardLookupId: "2552",
    VDNumber: "86910464",
    RegisteredPopulation: "1752",
    VotingStationName: "POTCHEFSTROOM GIMNASIUM PRIMARY SCHOOL",
    Address: "20 MOLEN STREET  POTCHEFSTROOM  POTCHEFSTROOM",
    Latitude: "-26.6871",
    Longitude: "27.0972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20588",
    WardLookupId: "2552",
    VDNumber: "86910521",
    RegisteredPopulation: "640",
    VotingStationName: "CRISTA GALLI VENUE",
    Address: "R501 TOWN ROAD   POTCHEFSTROOM  POTCHEFSTROOM",
    Latitude: "-26.6732",
    Longitude: "27.1054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20589",
    WardLookupId: "2553",
    VDNumber: "86910048",
    RegisteredPopulation: "1064",
    VotingStationName: "TOWN HALL",
    Address: "KERK STREET  POTCHEFSTROOM CENTRAL  POTCHEFSTROOM",
    Latitude: "-26.7154",
    Longitude: "27.0976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20590",
    WardLookupId: "2553",
    VDNumber: "86910295",
    RegisteredPopulation: "1558",
    VotingStationName: "NG GRIMBEEKPARK",
    Address: "N/A  POTCHESTROOM  POTCHEFSTROOM [POTCHEFSTROOM]",
    Latitude: "-26.7179",
    Longitude: "27.1134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20591",
    WardLookupId: "2554",
    VDNumber: "86910059",
    RegisteredPopulation: "1415",
    VotingStationName: "SENWES PARK",
    Address: "WALTER SISULU  POTCHEFSTROOM CENTRAL  POTCHEFSTROOM",
    Latitude: "-26.696",
    Longitude: "27.0984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20592",
    WardLookupId: "2554",
    VDNumber: "86910611",
    RegisteredPopulation: "1098",
    VotingStationName: "VUSELELA FET COLLEGE",
    Address: "KOLBE STREET  CENTRAL  POTCHEFSTROOM",
    Latitude: "-26.7058",
    Longitude: "27.0896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20593",
    WardLookupId: "2554",
    VDNumber: "86910677",
    RegisteredPopulation: "217",
    VotingStationName: "PINKSTER PROTESTANTE KERK",
    Address: "CNR RIVIER  POTCHEFSTROOM  POTCHEFSTROOM",
    Latitude: "-26.7091",
    Longitude: "27.1019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20594",
    WardLookupId: "2555",
    VDNumber: "86910273",
    RegisteredPopulation: "1727",
    VotingStationName: "TSHUPANE SCHOOL",
    Address: "N/A  EXT 7  IKAGENG",
    Latitude: "-26.7276",
    Longitude: "27.02",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20595",
    WardLookupId: "2555",
    VDNumber: "86910396",
    RegisteredPopulation: "1102",
    VotingStationName: "ROMAN CATHOLIC CHURCH EXT 7 (ASSUMPTION PARISH)",
    Address: "CNR KEFENTSE AND BOIKOBO STR   POTCHEFSTROOM   POTCHEFSTROOM",
    Latitude: "-26.7212",
    Longitude: "27.0241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20596",
    WardLookupId: "2555",
    VDNumber: "86910509",
    RegisteredPopulation: "906",
    VotingStationName: "REJOY DAY CARE",
    Address: "1ST AVENUE,IKAGENG  POTCHEFSTROOM  POTCHEFSTROOM",
    Latitude: "-26.7136",
    Longitude: "27.0279",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20597",
    WardLookupId: "2555",
    VDNumber: "86910655",
    RegisteredPopulation: "145",
    VotingStationName: "EXT 13 YARONA DAY CARE CENTRE",
    Address: "N/A  IKAGENG EXT 13  POTCHEFSTROOM",
    Latitude: "-26.731",
    Longitude: "27.0207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20598",
    WardLookupId: "2556",
    VDNumber: "86820295",
    RegisteredPopulation: "649",
    VotingStationName: "JANE LETSAPA PRIMARY SCHOOL",
    Address:
      "N/A  POTCHEFSTROOM [POTCHEFSTROOM]  POTCHEFSTROOM [POTCHEFSTROOM]",
    Latitude: "-26.7334",
    Longitude: "26.9261",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20599",
    WardLookupId: "2556",
    VDNumber: "86820992",
    RegisteredPopulation: "2491",
    VotingStationName: "DAN TLOME PRIMARY SCHOOL",
    Address: "N/A  EXT 11  POTCHEFSTROOM",
    Latitude: "-26.7505",
    Longitude: "27.0103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20600",
    WardLookupId: "2557",
    VDNumber: "86820442",
    RegisteredPopulation: "525",
    VotingStationName: "RYSMIERBULT HALL",
    Address: "VENTERSDORP  RUSMIERBULT  VENTERSDORP",
    Latitude: "-26.3537",
    Longitude: "27.1317",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20601",
    WardLookupId: "2557",
    VDNumber: "86820497",
    RegisteredPopulation: "319",
    VotingStationName: "MUISKRAAL",
    Address: "MUISKRAAL  MUISKRAAL 129IQ  VENTERSDORP",
    Latitude: "-26.4238",
    Longitude: "27.1131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20602",
    WardLookupId: "2557",
    VDNumber: "86820509",
    RegisteredPopulation: "713",
    VotingStationName: "BATTLE TO SURVIVE SCHOOL",
    Address: "N/A  POTCHEFSTROOM  POTCHEFSTROOM",
    Latitude: "-26.5738",
    Longitude: "27.1227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20603",
    WardLookupId: "2557",
    VDNumber: "86820936",
    RegisteredPopulation: "174",
    VotingStationName: "DEBEERSKRAAL PRIMARY SCHOOL",
    Address: "N/A N/A  DEBEERSKRAAL  VENTERSDORP",
    Latitude: "-26.2976",
    Longitude: "27.1628",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20604",
    WardLookupId: "2557",
    VDNumber: "86910071",
    RegisteredPopulation: "1477",
    VotingStationName: "NW MILITARY REFERENCE LIBRARY",
    Address: "MEYER  MILITARY BASED  POTCHEFSTROOM",
    Latitude: "-26.686",
    Longitude: "27.0818",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20605",
    WardLookupId: "2558",
    VDNumber: "86940018",
    RegisteredPopulation: "1901",
    VotingStationName: "TSHING COMMUNITY HALL",
    Address: "LEGAKABE  STREET  TSHING  VENTERSDORP",
    Latitude: "-26.3221",
    Longitude: "26.7924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20606",
    WardLookupId: "2558",
    VDNumber: "86940041",
    RegisteredPopulation: "1619",
    VotingStationName: "NDLEBE (TENT)",
    Address: "Kotane Street TSHING  VENTERSDORP",
    Latitude: "-26.3187",
    Longitude: "26.7905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20607",
    WardLookupId: "2559",
    VDNumber: "86940029",
    RegisteredPopulation: "1841",
    VotingStationName: "THUTOBOSWA HIGH SCHOOL",
    Address: "SEKGAPANE  STREET  TSHING  VENTERSDORP",
    Latitude: "-26.3198",
    Longitude: "26.8008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20608",
    WardLookupId: "2559",
    VDNumber: "86940052",
    RegisteredPopulation: "773",
    VotingStationName: "TSHING PRIMARY SCHOOL",
    Address: "MANAKA STREET  TSHING  VENTERSDORP",
    Latitude: "-26.3194",
    Longitude: "26.8118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20609",
    WardLookupId: "2559",
    VDNumber: "86940074",
    RegisteredPopulation: "516",
    VotingStationName: "LETSHELEMANE PRIMARY SCHOOL",
    Address: "LEGAKABE  TSHING LOCATION  VENTERSDORP",
    Latitude: "-26.3219",
    Longitude: "26.8002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20610",
    WardLookupId: "2560",
    VDNumber: "86820408",
    RegisteredPopulation: "222",
    VotingStationName: "SYFERFONTEIN PRIMARY SCHOOL",
    Address: "N/A SYFERFONTEIN  VENTERSDORP",
    Latitude: "-26.3252",
    Longitude: "27.0416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20611",
    WardLookupId: "2560",
    VDNumber: "86820431",
    RegisteredPopulation: "106",
    VotingStationName: "SWARTPLAAS SHOP",
    Address: "SWARTPLAAS  UITKYK  VENTERSDORP",
    Latitude: "-26.2447",
    Longitude: "26.9652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20612",
    WardLookupId: "2560",
    VDNumber: "86820857",
    RegisteredPopulation: "1235",
    VotingStationName: "POELANO SECONDARY SCHOOL",
    Address: "N/A  GOEDGEVONDEN  GOEDGEVONDEN",
    Latitude: "-26.155",
    Longitude: "26.8943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20613",
    WardLookupId: "2560",
    VDNumber: "86820868",
    RegisteredPopulation: "702",
    VotingStationName: "REGOROGILE COMBINED SCHOOL",
    Address: "GAMOGOPA  VENTERSDORP  BAKWENA BA MOGOPA",
    Latitude: "-26.097",
    Longitude: "26.8225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20614",
    WardLookupId: "2560",
    VDNumber: "86820925",
    RegisteredPopulation: "94",
    VotingStationName: "KLIPGAT FARM",
    Address: "N/A KLIPGAT  NOOTGEDACHT  VENTERSDORP",
    Latitude: "-26.1131",
    Longitude: "27.1643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20615",
    WardLookupId: "2560",
    VDNumber: "86821005",
    RegisteredPopulation: "794",
    VotingStationName: "BOIKHUTSONG COMMUNITY HALL",
    Address: "1 BOIKHUTSONG  BOIKHUTSONG VILLAGE  VENTERSDORP",
    Latitude: "-26.1859",
    Longitude: "26.9082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20616",
    WardLookupId: "2561",
    VDNumber: "86820419",
    RegisteredPopulation: "886",
    VotingStationName: "BOIKHUTSO COMMUNITY HALL",
    Address: "1 VENTERSDORP  BOIKHUTSO VILLAGE  VENTERSDORP",
    Latitude: "-26.2244",
    Longitude: "26.8158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20617",
    WardLookupId: "2561",
    VDNumber: "86821151",
    RegisteredPopulation: "190",
    VotingStationName: "MADIBA CHRECHE",
    Address: "APPELDRAAI  VENTERSDORP  APPELDRAAI",
    Latitude: "-26.2232",
    Longitude: "26.8405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20618",
    WardLookupId: "2561",
    VDNumber: "86821195",
    RegisteredPopulation: "583",
    VotingStationName: "MOTAUNG PRIMARY SCHOOL",
    Address: "BOIKHUTSO  VENTERSDORP",
    Latitude: "-26.2185",
    Longitude: "26.8111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20619",
    WardLookupId: "2561",
    VDNumber: "86940030",
    RegisteredPopulation: "1707",
    VotingStationName: "KGOLOLOSEGO INTERMEDIATE SCHOOL",
    Address: "08 CNR VAN RIEBECK &  VOORTREKKER  VENTERSDORP  VENTERSDORP",
    Latitude: "-26.3179",
    Longitude: "26.8246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20620",
    WardLookupId: "2562",
    VDNumber: "86820420",
    RegisteredPopulation: "699",
    VotingStationName: "WELGEVONDEN PRIMARY SCHOOL",
    Address: "WELGEVONDEN  VENTERSDORP",
    Latitude: "-26.1614",
    Longitude: "26.8099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20621",
    WardLookupId: "2562",
    VDNumber: "86820891",
    RegisteredPopulation: "85",
    VotingStationName: "BORETHE PRIMARY SCHOOL",
    Address:
      "SWARTRUGGENS ROAD, CECILIAS HOME TURN-OFF +/-10KM  POLKA  VENTERSDORP",
    Latitude: "-26.2198",
    Longitude: "26.6802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20622",
    WardLookupId: "2562",
    VDNumber: "86821140",
    RegisteredPopulation: "467",
    VotingStationName: "TSWANATSATSI PRIMARY SCHOOL",
    Address: "TSETSE   VENTERSDORP",
    Latitude: "-26.1758",
    Longitude: "26.7964",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20623",
    WardLookupId: "2562",
    VDNumber: "86821207",
    RegisteredPopulation: "379",
    VotingStationName: "THUTOLESEDI EARLY LEARNING CENTRE",
    Address: "N/A  WELGEVONDEN  VENTERSDORP",
    Latitude: "-26.1615",
    Longitude: "26.8177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20624",
    WardLookupId: "2562",
    VDNumber: "86940063",
    RegisteredPopulation: "2264",
    VotingStationName: "EXTENSION 5 COMMUNITY HALL",
    Address: "5 EXTENSION  TSHING  VENTERSDORP",
    Latitude: "-26.3104",
    Longitude: "26.7986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20625",
    WardLookupId: "2563",
    VDNumber: "86820329",
    RegisteredPopulation: "248",
    VotingStationName: "MAKOKSKRAAL BOERESAAL",
    Address: "MAKOKSKRAAL  MAKOKSKRAAL 203IP  VENTERSDORP",
    Latitude: "-26.3435",
    Longitude: "26.6242",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20626",
    WardLookupId: "2563",
    VDNumber: "86820330",
    RegisteredPopulation: "163",
    VotingStationName: "KAALFONTEIN FARM",
    Address: "KAALFONTEIN  VENTERSDORP  VENTERSDORP",
    Latitude: "-26.5101",
    Longitude: "26.86",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20627",
    WardLookupId: "2563",
    VDNumber: "86820352",
    RegisteredPopulation: "165",
    VotingStationName: "CECILIAâ€™S HOME PRIMARY SCHOOL",
    Address: "SWEETHOME  BOSCHKOP  VENTERSDORP",
    Latitude: "-26.3074",
    Longitude: "26.5609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20628",
    WardLookupId: "2563",
    VDNumber: "86820363",
    RegisteredPopulation: "248",
    VotingStationName: "RABANA PRIMARY SCHOOL",
    Address: "RIERSPRUITDAM PAD  KLIPPLAATDRIFT  VENTERSDORP",
    Latitude: "-26.4304",
    Longitude: "26.7362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20629",
    WardLookupId: "2563",
    VDNumber: "86820374",
    RegisteredPopulation: "193",
    VotingStationName: "DUDUETSANG PRIMARY SCHOOL",
    Address: "KLIPPLAATDRIFT  VENTERSDORP",
    Latitude: "-26.395",
    Longitude: "26.7683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20630",
    WardLookupId: "2563",
    VDNumber: "86820385",
    RegisteredPopulation: "281",
    VotingStationName: "DOVESDALE RECREATIONAL CENTRE",
    Address: "POTCHEFSTROOM PAD  ROODEKOP/VLAKFONTEIN  VENTERSDORP",
    Latitude: "-26.4342",
    Longitude: "26.9315",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20631",
    WardLookupId: "2563",
    VDNumber: "86820396",
    RegisteredPopulation: "174",
    VotingStationName: "IKHUTSENG SCHOOL",
    Address: "POTCHEFSTROOM PAD  WITPOORT 231IP  VENTERSDORP",
    Latitude: "-26.4849",
    Longitude: "26.9791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20632",
    WardLookupId: "2563",
    VDNumber: "86820767",
    RegisteredPopulation: "212",
    VotingStationName: "KROMDRAAI ALGEMENE HANDELAAR (ELEAZAR) TENT",
    Address: "ELEAZAR  POTCHEFSTROOM  POTCHEFSTROOM",
    Latitude: "-26.6079",
    Longitude: "26.9085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20633",
    WardLookupId: "2563",
    VDNumber: "86820879",
    RegisteredPopulation: "139",
    VotingStationName: "OPANG DIATLA PRIMARY SCHOOL",
    Address: "N/A N/A  RATZEGAAISKRAAL  VENTERSDORP",
    Latitude: "-26.3853",
    Longitude: "26.7276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20634",
    WardLookupId: "2563",
    VDNumber: "86820903",
    RegisteredPopulation: "136",
    VotingStationName: "DUMELANG PRIMARY SCHOOL",
    Address: "N/A N/A  ROOIPOORT  VENTERSDORP",
    Latitude: "-26.3929",
    Longitude: "26.5379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20635",
    WardLookupId: "2563",
    VDNumber: "86820914",
    RegisteredPopulation: "256",
    VotingStationName: "OPRAAPSAAL",
    Address: "VENTERSDORP  ",
    Latitude: "-26.4999",
    Longitude: "26.5706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20636",
    WardLookupId: "2563",
    VDNumber: "86820947",
    RegisteredPopulation: "247",
    VotingStationName: "BUFFELVLEI ALGEMENE HANDELAAR",
    Address: "N/A BUFFELSVLEI  VENTERSDORP",
    Latitude: "-26.4884",
    Longitude: "26.6592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20637",
    WardLookupId: "2563",
    VDNumber: "86820958",
    RegisteredPopulation: "211",
    VotingStationName: "MELITE SHOP",
    Address: "R30  STERKSTROOM  VENTERSDORP",
    Latitude: "-26.4473",
    Longitude: "26.7052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20638",
    WardLookupId: "2563",
    VDNumber: "86821230",
    RegisteredPopulation: "448",
    VotingStationName: "TENT - LUMKILEVILLE NEXT TO N14",
    Address: "MARIKANA  LUMKILEVILLE   TLOKWE",
    Latitude: "-26.3214",
    Longitude: "26.7838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20639",
    WardLookupId: "4610",
    VDNumber: "65800010",
    RegisteredPopulation: "718",
    VotingStationName: "KUBOES MUNICIPALITY HALL",
    Address: "91 MAIN ROAD  KUBOES  PORT NOLLOTH",
    Latitude: "-28.447",
    Longitude: "16.9891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20640",
    WardLookupId: "4610",
    VDNumber: "65800021",
    RegisteredPopulation: "373",
    VotingStationName: "EKSTEENFONTEIN COMMUNITY HALL",
    Address: "RIVER STREET  EKSTEENFONTEIN  NAMAQUALAND",
    Latitude: "-28.8251",
    Longitude: "17.2543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20641",
    WardLookupId: "4610",
    VDNumber: "65800043",
    RegisteredPopulation: "105",
    VotingStationName: "DIE GROENSAAL",
    Address: "REUNING MINE  SENDELINGSDRIFT  NAMQUALAND",
    Latitude: "-28.1234",
    Longitude: "16.8926",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20642",
    WardLookupId: "4610",
    VDNumber: "65800054",
    RegisteredPopulation: "351",
    VotingStationName: "LEKKERSING COMMUNITY HALL",
    Address: "223 LINKS STREET  LEKKERSING  MAMAQUALAND",
    Latitude: "-29.0019",
    Longitude: "17.0999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20643",
    WardLookupId: "4611",
    VDNumber: "65800032",
    RegisteredPopulation: "722",
    VotingStationName: "SANDDRIFT COMMUNITY HALL",
    Address: "REIERLAAN  SANDDRIFT  NAMAQUALAND",
    Latitude: "-28.4124",
    Longitude: "16.7749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20644",
    WardLookupId: "4611",
    VDNumber: "65840014",
    RegisteredPopulation: "953",
    VotingStationName: "N ORSMONDSAAL ALEXANDER BAY",
    Address: "ORANJE ROAD  ALEXANDER BAY  NAMAQUALAND",
    Latitude: "-28.6124",
    Longitude: "16.491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20645",
    WardLookupId: "4612",
    VDNumber: "65790018",
    RegisteredPopulation: "1804",
    VotingStationName: "SIZAMILE CLINIC HALL",
    Address: "2374 SIZWE STREET  SIZAMILE, PORT NOLLOTH  NAMAQUALAND",
    Latitude: "-29.2576",
    Longitude: "16.8834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20646",
    WardLookupId: "4613",
    VDNumber: "65790029",
    RegisteredPopulation: "2164",
    VotingStationName: "DROP IN CENTRE",
    Address: "516 BURDEN  PORT NOLLOTH  NOLLOTHVILLE",
    Latitude: "-29.2598",
    Longitude: "16.875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20647",
    WardLookupId: "4614",
    VDNumber: "65720011",
    RegisteredPopulation: "3511",
    VotingStationName: "CONCORDIA COMMUNITY HALL",
    Address: "BETHELSTREET  NAMAQUALAND  CONCORDIA",
    Latitude: "-29.544",
    Longitude: "17.9467",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20648",
    WardLookupId: "4615",
    VDNumber: "65820012",
    RegisteredPopulation: "2429",
    VotingStationName: "STEINKOPF IMMANUEL HALL",
    Address: "KRAMERSTREET  STEINKOPF  SPRINGBOK",
    Latitude: "-29.257",
    Longitude: "17.7348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20649",
    WardLookupId: "4615",
    VDNumber: "65850037",
    RegisteredPopulation: "330",
    VotingStationName: "ROOIWAL PRIMARY SCHOOL",
    Address: "SCHOOL STREET  ROOIWAL  NAMAQUALAND",
    Latitude: "-28.7744",
    Longitude: "17.4272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20650",
    WardLookupId: "4615",
    VDNumber: "65850059",
    RegisteredPopulation: "338",
    VotingStationName: "VIOOLSDRIFT PRIMARY SCHOOL",
    Address: "SCHOOL STREET  NAMAQUALAND  VIOOLSDRIFT",
    Latitude: "-28.7794",
    Longitude: "17.6237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20651",
    WardLookupId: "4616",
    VDNumber: "65820023",
    RegisteredPopulation: "2670",
    VotingStationName: "STEINKOPF COMMUNITY HALL",
    Address: "BRECHERWEG  STEINKOPF  SPRINGBOK",
    Latitude: "-29.2656",
    Longitude: "17.7344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20652",
    WardLookupId: "4616",
    VDNumber: "65820034",
    RegisteredPopulation: "197",
    VotingStationName: "GOODHOUSE CLINIC",
    Address: "SCHOOL STREET  NAMAQUALAND  GOODHOUSE",
    Latitude: "-28.9124",
    Longitude: "18.2114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20653",
    WardLookupId: "4616",
    VDNumber: "65820045",
    RegisteredPopulation: "372",
    VotingStationName: "BULLETRAP SERVICE POINT HALL",
    Address: "MAIN ROAD  BULLETRAP  BULLETRAP",
    Latitude: "-29.4652",
    Longitude: "17.767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20654",
    WardLookupId: "4617",
    VDNumber: "65810022",
    RegisteredPopulation: "1875",
    VotingStationName: "SPRINGBOK POLICE CLUB",
    Address: "KOPERBERG, SPRINGBOK  NAMAKWALAND  SPRINGBOK",
    Latitude: "-29.6674",
    Longitude: "17.8951",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20655",
    WardLookupId: "4617",
    VDNumber: "65810066",
    RegisteredPopulation: "83",
    VotingStationName: "FONTEINTJIE COMMUNITY HALL",
    Address: "HOOFSTRAAT  NAMAKWALAND  FONTEINTJIE",
    Latitude: "-29.6745",
    Longitude: "17.9205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20656",
    WardLookupId: "4617",
    VDNumber: "65860038",
    RegisteredPopulation: "48",
    VotingStationName: "GAMOEPSAAL",
    Address: "GAMOEP FARM  SPRINGBOK  NAMAQUALAND",
    Latitude: "-29.8931",
    Longitude: "18.4164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20657",
    WardLookupId: "4617",
    VDNumber: "65860061",
    RegisteredPopulation: "27",
    VotingStationName: "GAMOEP SAAMTREKSAAL",
    Address: "BOESMANSLAND FARM  SPRINGBOK  NAMAQUALAND",
    Latitude: "-29.5154",
    Longitude: "18.3132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20658",
    WardLookupId: "4617",
    VDNumber: "65870051",
    RegisteredPopulation: "829",
    VotingStationName: "CAROLUSBERG RECREATION CLUB",
    Address: "MAIN ROAD  NAMAQUALAND  CAROLUSBERG",
    Latitude: "-29.6329",
    Longitude: "17.9498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20659",
    WardLookupId: "4618",
    VDNumber: "65810011",
    RegisteredPopulation: "2920",
    VotingStationName: "BERGSIG LIBRA HALL",
    Address: "VAN NIEKERK STREET  NAMAQUALAND  SPRINGBOK",
    Latitude: "-29.651",
    Longitude: "17.8759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20660",
    WardLookupId: "4619",
    VDNumber: "65720022",
    RegisteredPopulation: "198",
    VotingStationName: "ROOIWINKEL CLINIC",
    Address: "ROOIWINKEL  NAMAKWALAND  ROOIWINKEL, OKIEP",
    Latitude: "-29.5634",
    Longitude: "17.8665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20661",
    WardLookupId: "4619",
    VDNumber: "65770016",
    RegisteredPopulation: "3526",
    VotingStationName: "OKIEP CIVIC HALL",
    Address: "CHURCHSTREET  OKIEP  OKIEP",
    Latitude: "-29.5903",
    Longitude: "17.8751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20662",
    WardLookupId: "4620",
    VDNumber: "65810033",
    RegisteredPopulation: "1408",
    VotingStationName: "PAROGIE HALL MATJIESKLOOF",
    Address: "SALIESTREET  NAMAQUALAND  MATJIESKLOOF",
    Latitude: "-29.6718",
    Longitude: "17.8653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20663",
    WardLookupId: "4620",
    VDNumber: "65810044",
    RegisteredPopulation: "1525",
    VotingStationName: "BERGSIG VAALWATER COMMUNITY HALL",
    Address: "VAALWATER  BERGSIG  SPRINGBOK",
    Latitude: "-29.6507",
    Longitude: "17.8694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20664",
    WardLookupId: "4621",
    VDNumber: "65750014",
    RegisteredPopulation: "1978",
    VotingStationName: "KOMAGGAS COMMUNITY HALL",
    Address: "SCHOOL STREET  NAMAQUALAND  KOMAGGAS",
    Latitude: "-29.797",
    Longitude: "17.4792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20665",
    WardLookupId: "4621",
    VDNumber: "65750025",
    RegisteredPopulation: "757",
    VotingStationName: "BUFFELSRIVIER COMMUNITY HALL",
    Address: "HOOF STREET  BUFFELSRIVIER",
    Latitude: "-29.702",
    Longitude: "17.604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20666",
    WardLookupId: "4621",
    VDNumber: "65840025",
    RegisteredPopulation: "540",
    VotingStationName: "KLEINZEE SUPER DOME HALL",
    Address: "HOSPITAL STREET  NAMAKWALAND  KLEINZEE",
    Latitude: "-29.674",
    Longitude: "17.0685",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20667",
    WardLookupId: "4622",
    VDNumber: "65870028",
    RegisteredPopulation: "1514",
    VotingStationName: "NABABEEP HIGH SCHOOL",
    Address: "220 RUGBY STREET  NABABEEP  NABABEEP",
    Latitude: "-29.5947",
    Longitude: "17.7844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20668",
    WardLookupId: "4622",
    VDNumber: "65870062",
    RegisteredPopulation: "1924",
    VotingStationName: "NABABEEP JUNIOR CLUB",
    Address: "MAIN STREET, NABABEEP  NAMAKWALAND  NABABEEP",
    Latitude: "-29.5911",
    Longitude: "17.7801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20669",
    WardLookupId: "4623",
    VDNumber: "65740013",
    RegisteredPopulation: "718",
    VotingStationName: "COMMUNITY HALL KROONSIG",
    Address: "MAIN ROAD  KAMIESKROON  KAMIESKROON",
    Latitude: "-30.216",
    Longitude: "17.9229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20670",
    WardLookupId: "4623",
    VDNumber: "65760059",
    RegisteredPopulation: "383",
    VotingStationName: "SPOEGRIVIER GEMEENSKAPSAAL",
    Address: "MAIN ROAD  SPOEGRIVIER  SPOEGRIVIER",
    Latitude: "-30.3473",
    Longitude: "17.7609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20671",
    WardLookupId: "4623",
    VDNumber: "65840036",
    RegisteredPopulation: "136",
    VotingStationName: "CASTLE HILL",
    Address: "KLUB STREET  KOIINGNAAS  GARIES",
    Latitude: "-30.2012",
    Longitude: "17.2798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20672",
    WardLookupId: "4623",
    VDNumber: "65850026",
    RegisteredPopulation: "226",
    VotingStationName: "SOEBATSFONTEIN SKOOL",
    Address: "299 SHAW  LELIEFONTEIN  LELIEFONTEIN",
    Latitude: "-30.1183",
    Longitude: "17.595",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20673",
    WardLookupId: "4623",
    VDNumber: "65850048",
    RegisteredPopulation: "458",
    VotingStationName: "ERIC BAKER HALL HONDEKLIPBAAI",
    Address: "HONDEKLIPBAAI  GARIES",
    Latitude: "-30.3179",
    Longitude: "17.2783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20674",
    WardLookupId: "4624",
    VDNumber: "65730012",
    RegisteredPopulation: "1406",
    VotingStationName: "GARIES GEMEENSKAPSAAL",
    Address: "BASSON STREET  GARIES  GARIES",
    Latitude: "-30.5714",
    Longitude: "17.9873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20675",
    WardLookupId: "4624",
    VDNumber: "65760105",
    RegisteredPopulation: "427",
    VotingStationName: "KLIPFONTEIN GEMEENSKAPSAAL",
    Address: "MAIN ROAD  KLIPFONTEIN  KLIPFONTEIN",
    Latitude: "-30.5044",
    Longitude: "17.8312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20676",
    WardLookupId: "4624",
    VDNumber: "65860050",
    RegisteredPopulation: "238",
    VotingStationName: "LEPELFONTEIN CHURCH HALL",
    Address: "LEPELFONTEIN  GARIES  GARIES",
    Latitude: "-31.0454",
    Longitude: "17.8463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20677",
    WardLookupId: "4625",
    VDNumber: "65760015",
    RegisteredPopulation: "1016",
    VotingStationName: "ALPHA RAADSAAL",
    Address: "KHARKAMS  GARIES",
    Latitude: "-30.37",
    Longitude: "17.8881",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20678",
    WardLookupId: "4625",
    VDNumber: "65760048",
    RegisteredPopulation: "406",
    VotingStationName: "KHEIS GEMEENSKAPSAAL",
    Address: "KHEIS  KAMIESBERG  KAMIESBERG",
    Latitude: "-30.462",
    Longitude: "17.993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20679",
    WardLookupId: "4625",
    VDNumber: "65760060",
    RegisteredPopulation: "228",
    VotingStationName: "TWEERIVIER GEMEENSKAPSAAL",
    Address: "TWEERIVIER  GARIES",
    Latitude: "-30.3557",
    Longitude: "17.9937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20680",
    WardLookupId: "4626",
    VDNumber: "65760026",
    RegisteredPopulation: "477",
    VotingStationName: "LELIEFONTEIN GEMEENSKAPSAAL",
    Address: "H/V SHAWNY LINKS STRAAT  LELIEFONTEIN  GARIES",
    Latitude: "-30.3139",
    Longitude: "18.0804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20681",
    WardLookupId: "4626",
    VDNumber: "65760037",
    RegisteredPopulation: "289",
    VotingStationName: "JAKARANDA KLEUTERSKOOL",
    Address: "0 NO NAME  KAMASIES  NAMAQUALAND",
    Latitude: "-30.0317",
    Longitude: "18.2691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20682",
    WardLookupId: "4626",
    VDNumber: "65760071",
    RegisteredPopulation: "362",
    VotingStationName: "PAULSHOEK GEMEENSKAPSAAL",
    Address: "PAULSHOEK  ",
    Latitude: "-30.3669",
    Longitude: "18.2561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20683",
    WardLookupId: "4626",
    VDNumber: "65760082",
    RegisteredPopulation: "349",
    VotingStationName: "NOURIVIER GEMEENSKAPSAAL",
    Address: "NOURIVIER  GARIES",
    Latitude: "-30.2144",
    Longitude: "18.1372",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20684",
    WardLookupId: "4626",
    VDNumber: "65760093",
    RegisteredPopulation: "270",
    VotingStationName: "ROOIFONTEIN GEMEENSKAPSAAL",
    Address: "ROOIFONTEIN  GARIES",
    Latitude: "-30.0671",
    Longitude: "18.2604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20685",
    WardLookupId: "4627",
    VDNumber: "65900022",
    RegisteredPopulation: "3069",
    VotingStationName: "LENIE SKIPPERS HALL",
    Address: "VOORTREKKER  CALVINIA - WES  CALVINIA",
    Latitude: "-31.4638",
    Longitude: "19.7679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20686",
    WardLookupId: "4628",
    VDNumber: "65900011",
    RegisteredPopulation: "3062",
    VotingStationName: "REGINA VG KERKSAAL",
    Address: "BERGSTRAAT  CALVINIA  CALVINIA - WES",
    Latitude: "-31.4653",
    Longitude: "19.7657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20687",
    WardLookupId: "4629",
    VDNumber: "65010046",
    RegisteredPopulation: "147",
    VotingStationName: "ZWARTKOP - VG KERKSAAL",
    Address: "0 CALVINIA  ",
    Latitude: "-30.1337",
    Longitude: "21.0575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20688",
    WardLookupId: "4629",
    VDNumber: "65010079",
    RegisteredPopulation: "29",
    VotingStationName: "BOSKOP",
    Address: "NO STREETS  BRANDVLEI  NAMAQUALAND",
    Latitude: "-30.2529",
    Longitude: "20.6871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20689",
    WardLookupId: "4629",
    VDNumber: "65890019",
    RegisteredPopulation: "2090",
    VotingStationName: "COMMUNITY HALL BRANDVLEI",
    Address: "535 KORT STREET  BRANDVLEI  BRANDVLEI",
    Latitude: "-30.4725",
    Longitude: "20.4858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20690",
    WardLookupId: "4629",
    VDNumber: "65980064",
    RegisteredPopulation: "109",
    VotingStationName: "KLIPWERF KERKSAAL",
    Address: "CALVINIA  ",
    Latitude: "-31.1542",
    Longitude: "19.8887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20691",
    WardLookupId: "4629",
    VDNumber: "65980132",
    RegisteredPopulation: "64",
    VotingStationName: "DASSIESTRATE",
    Address: "0 NO STREET  CALVINIA  CALVINIA",
    Latitude: "-31.2433",
    Longitude: "20.2344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20692",
    WardLookupId: "4630",
    VDNumber: "65940015",
    RegisteredPopulation: "675",
    VotingStationName: "PROTEA HIGH SCHOOL",
    Address: "00 WYLAND STREET  NIEUWOUDTVILLE  NIEUWOUDTVILLE",
    Latitude: "-31.3775",
    Longitude: "19.1172",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20693",
    WardLookupId: "4630",
    VDNumber: "65940026",
    RegisteredPopulation: "911",
    VotingStationName: "VG KERKSAAL - NIEUWOUDTVILLE",
    Address: "LELIESTRAAT  HANTAM  NIEUWOUDTVILLE",
    Latitude: "-31.3685",
    Longitude: "19.1159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20694",
    WardLookupId: "4630",
    VDNumber: "65980020",
    RegisteredPopulation: "190",
    VotingStationName: "DIEPDRIFT PRIMARY SCHOOL",
    Address: "0 NO STREET  CALVINIA  CALVINIA",
    Latitude: "-31.5654",
    Longitude: "20.2865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20695",
    WardLookupId: "4630",
    VDNumber: "65980031",
    RegisteredPopulation: "292",
    VotingStationName: "MIDDELPOS PRIMARY SCHOOL HOSTEL",
    Address: "0 MAIN STREET  CALVINIA  MIDDELPOS",
    Latitude: "-31.905",
    Longitude: "20.2275",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20696",
    WardLookupId: "4630",
    VDNumber: "65980042",
    RegisteredPopulation: "96",
    VotingStationName: "BRAKFONTEIN",
    Address: "BRAKFONTEIN  ",
    Latitude: "-32.2428",
    Longitude: "19.5309",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20697",
    WardLookupId: "4630",
    VDNumber: "65980053",
    RegisteredPopulation: "132",
    VotingStationName: "PAPKUILSFONTEIN",
    Address: "0 0  HANTAM  NIEUWOUDTVILLE",
    Latitude: "-31.558",
    Longitude: "19.1824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20698",
    WardLookupId: "4630",
    VDNumber: "65980086",
    RegisteredPopulation: "40",
    VotingStationName: "ONDER DOWNES",
    Address: "0 NO STREET  CALVINIA  HANTAM",
    Latitude: "-31.5051",
    Longitude: "19.9299",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20699",
    WardLookupId: "4630",
    VDNumber: "65980097",
    RegisteredPopulation: "57",
    VotingStationName: "GOUNA",
    Address: "0 NO STREETS  HANTAM  CALVINIA",
    Latitude: "-31.5051",
    Longitude: "20.5083",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20700",
    WardLookupId: "4630",
    VDNumber: "65980109",
    RegisteredPopulation: "50",
    VotingStationName: "VONDELINGSFONTEIN",
    Address: "CALVINIA  ",
    Latitude: "-31.7521",
    Longitude: "19.8917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20701",
    WardLookupId: "4630",
    VDNumber: "65980110",
    RegisteredPopulation: "65",
    VotingStationName: "SONOP",
    Address: "CALVINIA  ",
    Latitude: "-31.949",
    Longitude: "19.7457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20702",
    WardLookupId: "4631",
    VDNumber: "65930014",
    RegisteredPopulation: "2068",
    VotingStationName: "GEMEENSKAPSAAL LOERIESFONTEIN",
    Address: "ROSSOUW STREET  LOERIESFONTEIN  LOERIESFONTEIN",
    Latitude: "-30.9507",
    Longitude: "19.4366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20703",
    WardLookupId: "4631",
    VDNumber: "65980019",
    RegisteredPopulation: "118",
    VotingStationName: "RAMSKOP SHOOTING RANGE",
    Address: "HANTAM  ",
    Latitude: "-31.5068",
    Longitude: "19.7399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20704",
    WardLookupId: "4631",
    VDNumber: "65980121",
    RegisteredPopulation: "37",
    VotingStationName: "HANTAMSDRIFT",
    Address: "NIEUWOUDTVILLE  ",
    Latitude: "-31.2178",
    Longitude: "19.1859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20705",
    WardLookupId: "4631",
    VDNumber: "65980143",
    RegisteredPopulation: "87",
    VotingStationName: "GROOT TOREN FARM",
    Address: "CALVINIA  ",
    Latitude: "-31.3103",
    Longitude: "19.7095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20706",
    WardLookupId: "4632",
    VDNumber: "65970018",
    RegisteredPopulation: "2042",
    VotingStationName: "VG KERKSAAL",
    Address: "MIDDEL STREET  KAROO HOOGLAND MUNICIPALITY  WILLISTON",
    Latitude: "-31.3434",
    Longitude: "20.928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20707",
    WardLookupId: "4633",
    VDNumber: "65920013",
    RegisteredPopulation: "1981",
    VotingStationName: "GEMEENSKAPSENTRUM",
    Address: "COMMERCIAL STR  KAROO HOOGLAND  FRASERBURG",
    Latitude: "-31.9044",
    Longitude: "21.5141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20708",
    WardLookupId: "4634",
    VDNumber: "65010103",
    RegisteredPopulation: "44",
    VotingStationName: "HAASFONTEIN",
    Address: "HAASFONTEIN FARM  KAROO HOOGLAND MUNICIPALITY  WILLISTON",
    Latitude: "-30.6517",
    Longitude: "20.9881",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20709",
    WardLookupId: "4634",
    VDNumber: "65920024",
    RegisteredPopulation: "98",
    VotingStationName: "GOLF KLUB HUIS",
    Address: "SERRIER  KAROO HOOGLAND  FRASERBURG",
    Latitude: "-31.9223",
    Longitude: "21.5141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20710",
    WardLookupId: "4634",
    VDNumber: "66000013",
    RegisteredPopulation: "163",
    VotingStationName: "GOODHOPE FARM",
    Address: "GOODHOPE FARM  KAROO HOOGLAND  FRASERBURG",
    Latitude: "-31.8546",
    Longitude: "21.9025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20711",
    WardLookupId: "4634",
    VDNumber: "66000024",
    RegisteredPopulation: "59",
    VotingStationName: "ROOIPOORT FARM",
    Address: "FARM  KAROO HOOGLAND  FRASERBURG",
    Latitude: "-31.7135",
    Longitude: "20.8782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20712",
    WardLookupId: "4634",
    VDNumber: "66000046",
    RegisteredPopulation: "59",
    VotingStationName: "DROOGVOETS FARM",
    Address: "DROOGVOETS FARM  KAROO HOOGLAND MUNICIPALITY  FRASERBURG",
    Latitude: "-31.8884",
    Longitude: "21.4787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20713",
    WardLookupId: "4634",
    VDNumber: "66000057",
    RegisteredPopulation: "30",
    VotingStationName: "TWEERIVIER",
    Address: "TWEERIVIER FARM  KAROO HOOGLAND MUNICIPALITY  SUTHERLAND",
    Latitude: "-32.0809",
    Longitude: "20.5429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20714",
    WardLookupId: "4634",
    VDNumber: "66000068",
    RegisteredPopulation: "39",
    VotingStationName: "EZELFONTEIN FARM",
    Address: "EZELFONTEIN FARM  KAROO HOOGLAND MUNICIPALITY  FRASERBURG",
    Latitude: "-32.1447",
    Longitude: "21.4075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20715",
    WardLookupId: "4634",
    VDNumber: "66000079",
    RegisteredPopulation: "45",
    VotingStationName: "SPRINGFONTEIN FARM",
    Address: "FARM  KAROO HOOGLAND  FRASERBURG",
    Latitude: "-31.5831",
    Longitude: "21.591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20716",
    WardLookupId: "4634",
    VDNumber: "66000080",
    RegisteredPopulation: "24",
    VotingStationName: "TWEEFONTEIN",
    Address: "TWEEFONTEIN FARM  KAROO HOOGLAND  FRASERBURG",
    Latitude: "-32.0548",
    Longitude: "21.8259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20717",
    WardLookupId: "4634",
    VDNumber: "66000091",
    RegisteredPopulation: "38",
    VotingStationName: "HELDERWATER",
    Address: "HELDERWATER FARM  KAROO HOOGLAND  SUTHERLAND",
    Latitude: "-32.0568",
    Longitude: "20.654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20718",
    WardLookupId: "4634",
    VDNumber: "66010025",
    RegisteredPopulation: "61",
    VotingStationName: "MATJESFONTEIN FARM",
    Address: "MATJESFONTEIN FARM  KAROO HOOGLAND  SUTHERLAND",
    Latitude: "-32.2187",
    Longitude: "20.5121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20719",
    WardLookupId: "4634",
    VDNumber: "66010036",
    RegisteredPopulation: "70",
    VotingStationName: "BRANDWACHT FARM",
    Address: "BRANDWACHT FARM  KAROO HOOGLAND MUNICIPALITY  SUTHERLAND",
    Latitude: "-32.2194",
    Longitude: "20.6914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20720",
    WardLookupId: "4634",
    VDNumber: "66010047",
    RegisteredPopulation: "47",
    VotingStationName: "GUNSFONTEIN FARM",
    Address: "GUNSFONTEIN FARM  KAROO HOOGLAND NUNICIPALITY  SUTHERLAND",
    Latitude: "-32.5666",
    Longitude: "20.6828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20721",
    WardLookupId: "4634",
    VDNumber: "66010069",
    RegisteredPopulation: "32",
    VotingStationName: "BLYDEVOORUITSIG",
    Address: "BLYDEVOORUITSIG FARM  KAROO HOOGLAND MUNICIPALITY  SUTHERLAND",
    Latitude: "-32.3928",
    Longitude: "20.9816",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20722",
    WardLookupId: "4634",
    VDNumber: "66010070",
    RegisteredPopulation: "31",
    VotingStationName: "KUILENBURG FARM",
    Address: "KUILENBURG FARM  KAROO HOOGLAND MUNICIPALITY  SUTHERLAND",
    Latitude: "-32.4043",
    Longitude: "20.8422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20723",
    WardLookupId: "4634",
    VDNumber: "66020026",
    RegisteredPopulation: "111",
    VotingStationName: "GOEDERVERWATCHTING FARM",
    Address: "GOEDEVERWATCHTING FARM  KAROO HOOGLAND  WILLISTON",
    Latitude: "-31.4145",
    Longitude: "20.924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20724",
    WardLookupId: "4634",
    VDNumber: "66020037",
    RegisteredPopulation: "41",
    VotingStationName: "GRASKRAAL FARM",
    Address: "GRASKRAAL FARM  KAROO HOOGLAND MUNICIPALITY  WILLISTON",
    Latitude: "-30.8795",
    Longitude: "20.5047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20725",
    WardLookupId: "4634",
    VDNumber: "66020048",
    RegisteredPopulation: "42",
    VotingStationName: "KLIPKOLKS FARM",
    Address: "KLIPKOLKS  KAROO HOOGLAND  WILLISTON",
    Latitude: "-31.0631",
    Longitude: "20.4755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20726",
    WardLookupId: "4634",
    VDNumber: "66020059",
    RegisteredPopulation: "50",
    VotingStationName: "BIESIES",
    Address: "BIESIES FARM  KAROO HOOGLAND MUNICIPALITY  WILLISTON",
    Latitude: "-30.8576",
    Longitude: "21.1346",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20727",
    WardLookupId: "4634",
    VDNumber: "66020060",
    RegisteredPopulation: "91",
    VotingStationName: "ZAKFONTEIN FARM",
    Address: "ZAKFONTEIN FARM  KAROO HOOGLAND MUNICIPALITY  WILLISTON",
    Latitude: "-31.2494",
    Longitude: "21.1581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20728",
    WardLookupId: "4634",
    VDNumber: "66020071",
    RegisteredPopulation: "31",
    VotingStationName: "BENOUDSFONTEIN FARM",
    Address: "BENOUDSFONTEIN FARM  KAROO HOOGLAND MUNICIPALITY  WILLISTON",
    Latitude: "-31.1012",
    Longitude: "20.9687",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20729",
    WardLookupId: "4635",
    VDNumber: "65950016",
    RegisteredPopulation: "1829",
    VotingStationName: "ROGGEVELD PRIMARY SCHOOL",
    Address: "SKOOL STREET  KAROO HOOGLAND MUNICIPALITY  SUTHERLAND",
    Latitude: "-32.3904",
    Longitude: "20.6649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20730",
    WardLookupId: "4635",
    VDNumber: "66010014",
    RegisteredPopulation: "122",
    VotingStationName: "DRIEFONTEIN",
    Address: "FARM  KAROO HOOGLAND [FRASERBURG]",
    Latitude: "-32.4424",
    Longitude: "20.4608",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20731",
    WardLookupId: "4636",
    VDNumber: "64990019",
    RegisteredPopulation: "351",
    VotingStationName: "RAAP EN SKRAAP",
    Address: "KEBOES FARM  KHÃ‚I-MA  RAAP EN SKRAAP FARM",
    Latitude: "-28.6464",
    Longitude: "19.5166",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20732",
    WardLookupId: "4636",
    VDNumber: "64990031",
    RegisteredPopulation: "154",
    VotingStationName: "VRUGBAAR",
    Address: "FARM  KHÃ‚I-MA  VRUGBAAR - FARM",
    Latitude: "-28.7524",
    Longitude: "19.3675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20733",
    WardLookupId: "4636",
    VDNumber: "65080010",
    RegisteredPopulation: "624",
    VotingStationName: "ST PHILOMENA SKOOLSAAL",
    Address: "RC MISSION  ONSEEPKANS  KHÃ‚I-MA [POFADDER]",
    Latitude: "-28.7706",
    Longitude: "19.2664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20734",
    WardLookupId: "4636",
    VDNumber: "65080021",
    RegisteredPopulation: "156",
    VotingStationName: "VILJOENSDRAAI",
    Address: "ERF 730  ONSEEPKANS  VILJOENSDRAAI",
    Latitude: "-28.7515",
    Longitude: "19.2774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20735",
    WardLookupId: "4636",
    VDNumber: "65080032",
    RegisteredPopulation: "485",
    VotingStationName: "MELKBOSRAND COMMUNITY HALL",
    Address: "359 SPRINGBOK STREET  ONSEEPKANS  MELKBOSRAND",
    Latitude: "-28.7503",
    Longitude: "19.3181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20736",
    WardLookupId: "4637",
    VDNumber: "64990020",
    RegisteredPopulation: "1594",
    VotingStationName: "BLYVOORUITSIG GEMEENSKAPSAAL",
    Address: "1090 WATER STREET  BLYVOORUITSIG  POFADDER",
    Latitude: "-29.1251",
    Longitude: "19.3848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20737",
    WardLookupId: "4638",
    VDNumber: "65780017",
    RegisteredPopulation: "1874",
    VotingStationName: "PELLA COMMUNITY HALL",
    Address: "129 KATEDRAAL STR  PELLA  PELLA",
    Latitude: "-29.033",
    Longitude: "19.1537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20738",
    WardLookupId: "4638",
    VDNumber: "65830024",
    RegisteredPopulation: "93",
    VotingStationName: "KLEIN PELLA FARM",
    Address: "NO STREET NAMES  FARM  KLEIN PELLA",
    Latitude: "-28.9446",
    Longitude: "18.9777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20739",
    WardLookupId: "4639",
    VDNumber: "64990042",
    RegisteredPopulation: "639",
    VotingStationName: "BOESMANLAND HIGH SCHOOL",
    Address: "SPRINGBOK STREET - POFADDER   POFADDER  POFADDER",
    Latitude: "-29.1342",
    Longitude: "19.3959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20740",
    WardLookupId: "4639",
    VDNumber: "65830013",
    RegisteredPopulation: "1098",
    VotingStationName: "AGGENEYS HIGH",
    Address: "SCHOOL STREET  AGGENEYS  AGGENEYS",
    Latitude: "-29.2542",
    Longitude: "18.8397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20741",
    WardLookupId: "4639",
    VDNumber: "65850015",
    RegisteredPopulation: "221",
    VotingStationName: "WITBANK COMMUNITY HALL",
    Address: "109 SKOOL STREET  WITBANK  WITBANK",
    Latitude: "-28.8617",
    Longitude: "18.6684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20742",
    WardLookupId: "4639",
    VDNumber: "66030038",
    RegisteredPopulation: "48",
    VotingStationName: "DWAGGAS SOUTPANNE",
    Address: "NO STREETS  FARM  DWAGGAS SOUTPANNE",
    Latitude: "-30.211",
    Longitude: "19.688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20743",
    WardLookupId: "4640",
    VDNumber: "65230017",
    RegisteredPopulation: "1078",
    VotingStationName: "OU SKOUGRONDESAAL",
    Address: "RANDSIGSTRAAT  UBUNTU MUNICIPLATY  VICTORIA WEST",
    Latitude: "-31.4182",
    Longitude: "23.948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20744",
    WardLookupId: "4640",
    VDNumber: "65230028",
    RegisteredPopulation: "1636",
    VotingStationName: "NOMZAMO COMMUNITY HALL SABELO",
    Address: "8 LOOP STREET  UBUNTU MUNICIPALITY  RICHMOND",
    Latitude: "-31.422",
    Longitude: "23.9548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20745",
    WardLookupId: "4641",
    VDNumber: "65260010",
    RegisteredPopulation: "2425",
    VotingStationName: "KAPPERTJIE GEMEENSKAPSAAL",
    Address: "KAPPERTJIESTRAAT  VICTORIA-WES",
    Latitude: "-31.3995",
    Longitude: "23.1254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20746",
    WardLookupId: "4642",
    VDNumber: "65170010",
    RegisteredPopulation: "861",
    VotingStationName: "J J BOOYSEN PRIMARY SCHOOL",
    Address: "MARK STREET  UBUNTU MUNICIPALITY  LOXTON",
    Latitude: "-31.4742",
    Longitude: "22.3485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20747",
    WardLookupId: "4642",
    VDNumber: "65300015",
    RegisteredPopulation: "274",
    VotingStationName: "HUTCHINSON PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS AVAILABLE  URBAN  VICTORIA WEST",
    Latitude: "-31.5",
    Longitude: "23.1846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20748",
    WardLookupId: "4642",
    VDNumber: "65300026",
    RegisteredPopulation: "43",
    VotingStationName: "BULTFONTEIN",
    Address: "BULTFONTEIN FARM  VICTORIA WEST",
    Latitude: "-31.6012",
    Longitude: "23.2999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20749",
    WardLookupId: "4642",
    VDNumber: "65300037",
    RegisteredPopulation: "25",
    VotingStationName: "BIESIESPOORT",
    Address: "BIESISES FARM  VICTORIA WEST  UBUNTU [VICTORIA WEST]",
    Latitude: "-31.7093",
    Longitude: "23.1832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20750",
    WardLookupId: "4642",
    VDNumber: "65350010",
    RegisteredPopulation: "105",
    VotingStationName: "MELTON WORLD HALL",
    Address: "MELTON VACATION FARM  VICTORIA WEST  UBUNTU [VICTORIA WEST]",
    Latitude: "-31.4574",
    Longitude: "22.753",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20751",
    WardLookupId: "4642",
    VDNumber: "65350043",
    RegisteredPopulation: "84",
    VotingStationName: "RENOSTERFONTEIN",
    Address: "RENOSTERFONTEIN FARM  VICTORIA WEST  UBUNTU [VICTORIA WEST]",
    Latitude: "-31.7445",
    Longitude: "24.092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20752",
    WardLookupId: "4642",
    VDNumber: "65350054",
    RegisteredPopulation: "247",
    VotingStationName: "MERRIMAN TENT",
    Address: "TRAIN STATION  VICTORIA WEST  VICTORIA WEST",
    Latitude: "-31.213",
    Longitude: "23.6132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20753",
    WardLookupId: "4642",
    VDNumber: "65350098",
    RegisteredPopulation: "83",
    VotingStationName: "WAGENAARSKRAAL",
    Address: "WAGENAARSKRALL FARM  VICTORIA WEST  UBUNTU [VICTORIA WEST]",
    Latitude: "-31.8054",
    Longitude: "22.8094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20754",
    WardLookupId: "4642",
    VDNumber: "65350100",
    RegisteredPopulation: "43",
    VotingStationName: "SLYPFONTEIN FARM",
    Address: "SLYPFONTEIN   UBUNTU MUNICIPALITY  VICTORIA WEST",
    Latitude: "-31.5582",
    Longitude: "22.6593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20755",
    WardLookupId: "4642",
    VDNumber: "65350111",
    RegisteredPopulation: "50",
    VotingStationName: "PAMPOENPOORT FARM",
    Address: "PAMPOENPOORT  UBUNTU MUNICIPALITY  VICTORIA WEST",
    Latitude: "-31.0687",
    Longitude: "22.6553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20756",
    WardLookupId: "4642",
    VDNumber: "65350144",
    RegisteredPopulation: "90",
    VotingStationName: "SKOTTELFONTEIN FARM",
    Address: "SKOTTELFONTEIN   UBUNTU MUNICIPALITY  VICTORIA WEST",
    Latitude: "-31.174",
    Longitude: "23.9606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20757",
    WardLookupId: "4642",
    VDNumber: "65350155",
    RegisteredPopulation: "61",
    VotingStationName: "VOGELSTRUISFONTEIN FARM",
    Address: "VOGELSTRUISFONTEIN  UBUNTU MUNICIPALITY  VICTORIA WEST",
    Latitude: "-31.4897",
    Longitude: "23.7019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20758",
    WardLookupId: "4642",
    VDNumber: "65350166",
    RegisteredPopulation: "48",
    VotingStationName: "RIETFONTEIN FARM",
    Address: "RIETFONTEIN  UBUNTU MUNICIPALITY  VICTORIA WEST",
    Latitude: "-31.5905",
    Longitude: "23.8365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20759",
    WardLookupId: "4642",
    VDNumber: "65350177",
    RegisteredPopulation: "97",
    VotingStationName: "CYPHERWATER FARM",
    Address: "CYPHERWATER  UBUNTU MUNICIPALITY  VICTORIA WEST",
    Latitude: "-31.4531",
    Longitude: "24.3131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20760",
    WardLookupId: "4643",
    VDNumber: "65260021",
    RegisteredPopulation: "689",
    VotingStationName: "VICTORIA WEST HIGH SCHOOL",
    Address: "VICTORIA WEST  ",
    Latitude: "-31.3937",
    Longitude: "23.121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20761",
    WardLookupId: "4643",
    VDNumber: "65350021",
    RegisteredPopulation: "1481",
    VotingStationName: "MASINYUSANI HALL",
    Address: "BONGWENI  UBUNTU MUNICIPALITY  VICTORIA WEST",
    Latitude: "-31.3953",
    Longitude: "23.1149",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20762",
    WardLookupId: "4643",
    VDNumber: "65350065",
    RegisteredPopulation: "30",
    VotingStationName: "WOLWEPOORT FARM",
    Address: "WOLWEPOORT  UBUNTU MUNICIPALITY  VICTORIA WEST",
    Latitude: "-31.0376",
    Longitude: "23.0082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20763",
    WardLookupId: "4643",
    VDNumber: "65350087",
    RegisteredPopulation: "40",
    VotingStationName: "DRIEFONTEIN FARM",
    Address: "DRIEFONTEIN   UBUNTU MUNICIPALITY  VICTORIA WEST",
    Latitude: "-31.2583",
    Longitude: "23.0554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20764",
    WardLookupId: "4643",
    VDNumber: "65350122",
    RegisteredPopulation: "33",
    VotingStationName: "BLACKSTONE FARM",
    Address: "BLACKSTONE PLAINS  UBUNTU MUNICIPALITY  VICTORIA WEST",
    Latitude: "-30.9314",
    Longitude: "22.845",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20765",
    WardLookupId: "4643",
    VDNumber: "65350133",
    RegisteredPopulation: "54",
    VotingStationName: "RIETPOORT FARM",
    Address: "RIETPOORT  UBUNTU MUNICIPALTY  VICTORIA WEST",
    Latitude: "-31.1362",
    Longitude: "23.1189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20766",
    WardLookupId: "4644",
    VDNumber: "65190023",
    RegisteredPopulation: "1304",
    VotingStationName: "KWA-ZAMUXOLO HALL",
    Address: "MAQUNGU STREET  KWA-ZAMUXOLO  NOUPOORT",
    Latitude: "-31.1917",
    Longitude: "24.9553",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20767",
    WardLookupId: "4644",
    VDNumber: "65190034",
    RegisteredPopulation: "718",
    VotingStationName: "HUTCHINSON HALL",
    Address: "SHORT STREET  NOUPOORT  NOUPOORT",
    Latitude: "-31.179",
    Longitude: "24.9525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20768",
    WardLookupId: "4644",
    VDNumber: "65190056",
    RegisteredPopulation: "743",
    VotingStationName: "ST JOHN`S METHODIST CHURCH",
    Address: "VREDE STRAAT  EUREKAVILLE  NOUPOORT",
    Latitude: "-31.1907",
    Longitude: "24.9493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20769",
    WardLookupId: "4644",
    VDNumber: "65290024",
    RegisteredPopulation: "82",
    VotingStationName: "BOVLEI",
    Address: "NOUPOORT ROAD N10  BOVLEI FARM  NOUPOORT",
    Latitude: "-31.2964",
    Longitude: "24.7781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20770",
    WardLookupId: "4644",
    VDNumber: "65290079",
    RegisteredPopulation: "44",
    VotingStationName: "WILDFONTEIN",
    Address: "NOUPOORT ROAD N9  WILDFONTEIN FARM  NOUPOORT",
    Latitude: "-31.0708",
    Longitude: "24.8334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20771",
    WardLookupId: "4644",
    VDNumber: "65290136",
    RegisteredPopulation: "103",
    VotingStationName: "RIETFONTEIN FARM",
    Address: "(NC072 - UMSOBOMVU [COLESBERG])   ",
    Latitude: "-30.9333",
    Longitude: "25.0177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20772",
    WardLookupId: "4645",
    VDNumber: "65190012",
    RegisteredPopulation: "1069",
    VotingStationName: "J J CLAASEN HALL",
    Address: "CLAASEN STREET  EUREKAVILLE  NOUPOORT",
    Latitude: "-31.1901",
    Longitude: "24.9424",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20773",
    WardLookupId: "4645",
    VDNumber: "65190045",
    RegisteredPopulation: "431",
    VotingStationName: "ENOCH MTHETHO HIGH SCHOOL",
    Address: "NGCAMBU  KWA-ZAMUXOLO  NOUPOORT",
    Latitude: "-31.1961",
    Longitude: "24.9558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20774",
    WardLookupId: "4645",
    VDNumber: "65290068",
    RegisteredPopulation: "50",
    VotingStationName: "GROENKLOOF",
    Address: "NOUPOORT  ",
    Latitude: "-31.2006",
    Longitude: "25.139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20775",
    WardLookupId: "4645",
    VDNumber: "65290103",
    RegisteredPopulation: "187",
    VotingStationName: "HANTAM EDUCARE TRUST PRIMARY SCHOOL",
    Address: "COLESBERG  GROOTFONTEIN  COLESBERG",
    Latitude: "-30.9734",
    Longitude: "25.3508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20776",
    WardLookupId: "4646",
    VDNumber: "65130016",
    RegisteredPopulation: "960",
    VotingStationName: "KUYASA COMMUNITY HALL",
    Address: "MAIN ROAD  ZWELITSHA LOCATION,UMSOBOMVU MUNICIPALITY  COLESBERG",
    Latitude: "-30.7197",
    Longitude: "25.1099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20777",
    WardLookupId: "4646",
    VDNumber: "65130050",
    RegisteredPopulation: "433",
    VotingStationName: "UNITED PENTECOSTAL CHURCH",
    Address: "ANNEMOON  TOWERVALLEI  COLESBERG",
    Latitude: "-30.7093",
    Longitude: "25.1114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20778",
    WardLookupId: "4646",
    VDNumber: "65130083",
    RegisteredPopulation: "544",
    VotingStationName: "LOVELIFE HUB",
    Address: "1968 BONGWENI   COLESBERG",
    Latitude: "-30.7177",
    Longitude: "25.1168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20779",
    WardLookupId: "4647",
    VDNumber: "65130038",
    RegisteredPopulation: "1565",
    VotingStationName: "LOWRYVILLE COMMUNITY HALL",
    Address: "PATRICIA AVE  LOWRYVILLE  COLESBERG",
    Latitude: "-30.7087",
    Longitude: "25.1005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20780",
    WardLookupId: "4647",
    VDNumber: "65130094",
    RegisteredPopulation: "1521",
    VotingStationName: "COLESBERG SPORTS COMPLEX",
    Address: "STADIUM  RIEMVASMAAK  COLESBERG",
    Latitude: "-30.711",
    Longitude: "25.12",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20781",
    WardLookupId: "4647",
    VDNumber: "65290013",
    RegisteredPopulation: "111",
    VotingStationName: "RIEKERTSFONTEIN",
    Address: "COLESBERG ROAD  RIEKERTSFONTEIN FARM  COLESBERG",
    Latitude: "-30.7699",
    Longitude: "24.906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20782",
    WardLookupId: "4647",
    VDNumber: "65290057",
    RegisteredPopulation: "49",
    VotingStationName: "DE EERSTE POORT",
    Address: "PHILLIPSTOWN ROAD  COLESBERG  COLESBERG",
    Latitude: "-30.5253",
    Longitude: "24.9401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20783",
    WardLookupId: "4647",
    VDNumber: "65290080",
    RegisteredPopulation: "39",
    VotingStationName: "DOORNKLOOF TENT",
    Address: "PHILLIPSTOWN ROAD  DOORNKLOOF NATURE RESERVE  COLESBERG",
    Latitude: "-30.4562",
    Longitude: "24.9715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20784",
    WardLookupId: "4648",
    VDNumber: "65130027",
    RegisteredPopulation: "943",
    VotingStationName: "COLESBERG CIVIC CENTRE",
    Address: "CHURCH STREET  UMSOBOMVU MUNICIPALITY  COLESBERG",
    Latitude: "-30.72",
    Longitude: "25.0974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20785",
    WardLookupId: "4648",
    VDNumber: "65130061",
    RegisteredPopulation: "957",
    VotingStationName: "BANTU CONGREGATION BAPTIST CHURCH",
    Address: "MAIN ROAD  OLD LOCATION,UMSOBOMVU MUNICIPALITY  COLESBERG",
    Latitude: "-30.7169",
    Longitude: "25.1048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20786",
    WardLookupId: "4648",
    VDNumber: "65130072",
    RegisteredPopulation: "261",
    VotingStationName: "ISIBANI TRAINING CENTRE",
    Address: "MATANZIMA  ZWELITSHA  UMSOBOMVU [COLESBERG]",
    Latitude: "-30.7202",
    Longitude: "25.1095",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20787",
    WardLookupId: "4649",
    VDNumber: "65130049",
    RegisteredPopulation: "1774",
    VotingStationName: "UMSO HIGH SCHOOL",
    Address: "BENDLELA STREET  KHAYELITSHA  COLESBERG",
    Latitude: "-30.7258",
    Longitude: "25.118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20788",
    WardLookupId: "4649",
    VDNumber: "65290046",
    RegisteredPopulation: "98",
    VotingStationName: "VEEPOS FARM",
    Address: "NORVALSPONT ROAD   COLESBERG  COLESBERG",
    Latitude: "-30.7085",
    Longitude: "25.1918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20789",
    WardLookupId: "4649",
    VDNumber: "65290091",
    RegisteredPopulation: "28",
    VotingStationName: "VLUGFONTEIN PRIMARY SCHOOL",
    Address: "COLESBERG  HANTAM  COLESBERG",
    Latitude: "-31.0287",
    Longitude: "25.147",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20790",
    WardLookupId: "4649",
    VDNumber: "65310016",
    RegisteredPopulation: "871",
    VotingStationName: "NORVALSPONT COMMUNITY HALL",
    Address: "NOXOLO STREET  MASIZAKHE   NORVALSPONT",
    Latitude: "-30.6289",
    Longitude: "25.4525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20791",
    WardLookupId: "4650",
    VDNumber: "65140141",
    RegisteredPopulation: "610",
    VotingStationName: "DE AAR CIVIC CENTRE",
    Address: "DE AAR  ",
    Latitude: "-30.6495",
    Longitude: "24.023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20792",
    WardLookupId: "4650",
    VDNumber: "65340042",
    RegisteredPopulation: "2179",
    VotingStationName: "KAREEVILLE SCHOOL",
    Address: "KAREEVILLE  EMTHANJENI [DE AAR]",
    Latitude: "-30.6478",
    Longitude: "24.0342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20793",
    WardLookupId: "4651",
    VDNumber: "65140051",
    RegisteredPopulation: "1820",
    VotingStationName: "VERITAS SECONDARY SCHOOL",
    Address: "ANGELINE  SUBURB  DE AAR",
    Latitude: "-30.6513",
    Longitude: "24.0342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20794",
    WardLookupId: "4651",
    VDNumber: "65140084",
    RegisteredPopulation: "1502",
    VotingStationName: "CHURCH ASSEMBLY OF GOD",
    Address: "FISKAAL STRAAT  RESIDENTIA  DE AAR",
    Latitude: "-30.6532",
    Longitude: "24.0214",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20795",
    WardLookupId: "4651",
    VDNumber: "65140129",
    RegisteredPopulation: "530",
    VotingStationName: "SHADURE BUSINESS DEVELOPMENT",
    Address: "6 GLADIOLA  HAPPY VALLEY  DE AAR",
    Latitude: "-30.6525",
    Longitude: "24.0278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20796",
    WardLookupId: "4652",
    VDNumber: "65140039",
    RegisteredPopulation: "787",
    VotingStationName: "MONWABISI HIGH SCHOOL",
    Address: "STREET  NONZWAKAZI  EMTHANJENI",
    Latitude: "-30.6682",
    Longitude: "24.028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20797",
    WardLookupId: "4652",
    VDNumber: "65140073",
    RegisteredPopulation: "1348",
    VotingStationName: "NONZWAKAZI COMMUNITY HALL",
    Address: "HLITHANI STREET  NONZWAKAZI  DE AAR",
    Latitude: "-30.672",
    Longitude: "24.023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20798",
    WardLookupId: "4652",
    VDNumber: "65140130",
    RegisteredPopulation: "898",
    VotingStationName: "CCAC CHURCH",
    Address: "24TH  NONZWAKAZI  DE AAR",
    Latitude: "-30.6743",
    Longitude: "24.0282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20799",
    WardLookupId: "4653",
    VDNumber: "65140118",
    RegisteredPopulation: "1504",
    VotingStationName: "DE AAR MULTIPURPOSE CENTRE",
    Address: "MALAYCAMP  DE AAR  EMTHANJENI",
    Latitude: "-30.663",
    Longitude: "24.022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20800",
    WardLookupId: "4653",
    VDNumber: "65140152",
    RegisteredPopulation: "509",
    VotingStationName: "ST THOMAS ANGLICAN CHURCH",
    Address: "EMTHANJENI  ",
    Latitude: "-30.6521",
    Longitude: "24.0181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20801",
    WardLookupId: "4654",
    VDNumber: "65140017",
    RegisteredPopulation: "2428",
    VotingStationName: "DE AAR TOWN HALL",
    Address: "VOORTREKKER STREET  DE AAR  DE AAR",
    Latitude: "-30.6505",
    Longitude: "24.0114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20802",
    WardLookupId: "4654",
    VDNumber: "65140040",
    RegisteredPopulation: "906",
    VotingStationName: "MELANIE`S CAFE",
    Address: "PHILLIPSTOWN ROAD  LOUSVILLE  DE AAR",
    Latitude: "-30.6436",
    Longitude: "24.0198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20803",
    WardLookupId: "4655",
    VDNumber: "65150018",
    RegisteredPopulation: "2455",
    VotingStationName: "KWEZI COMMUNITY HALL",
    Address: "NEPTUNE STREET  KWEZI  HANOVER",
    Latitude: "-31.0658",
    Longitude: "24.4513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20804",
    WardLookupId: "4655",
    VDNumber: "65290035",
    RegisteredPopulation: "135",
    VotingStationName: "KRIEGERSPOORT FARM",
    Address: "KRIEGERSPOORT FARM  HANOVER  HANOVER",
    Latitude: "-31.2534",
    Longitude: "24.5592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20805",
    WardLookupId: "4655",
    VDNumber: "65290114",
    RegisteredPopulation: "58",
    VotingStationName: "DIEPRIVIER",
    Address: "DIEPRIVIER FARM  HANOVER  HANOVER",
    Latitude: "-30.9402",
    Longitude: "24.2754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20806",
    WardLookupId: "4655",
    VDNumber: "65290125",
    RegisteredPopulation: "76",
    VotingStationName: "KAROO GARIEP MIELIEFONTEIN",
    Address: "KAROO GARIEP  DE AAR  EMTHANJENI [DE AAR]",
    Latitude: "-30.9542",
    Longitude: "24.5388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20807",
    WardLookupId: "4655",
    VDNumber: "65340086",
    RegisteredPopulation: "47",
    VotingStationName: "DAMFONTEIN",
    Address: "EMTHANJENI [DE AAR]  ",
    Latitude: "-30.8333",
    Longitude: "23.9378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20808",
    WardLookupId: "4655",
    VDNumber: "65340097",
    RegisteredPopulation: "33",
    VotingStationName: "HELDERWATER FARM",
    Address: "HELDERWATER  DE AAR",
    Latitude: "-30.7397",
    Longitude: "24.361",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20809",
    WardLookupId: "4656",
    VDNumber: "65120015",
    RegisteredPopulation: "2996",
    VotingStationName: "PROTEAVILLE COMMUNITY HALL",
    Address: "DAHLIASTRAAT  PROTEAVILLE  BRITSTOWN",
    Latitude: "-30.5865",
    Longitude: "23.495",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20810",
    WardLookupId: "4656",
    VDNumber: "65330029",
    RegisteredPopulation: "66",
    VotingStationName: "SCHILDERSPAN",
    Address: "SCHILDERSPAN  DE AAR",
    Latitude: "-30.2392",
    Longitude: "23.1056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20811",
    WardLookupId: "4656",
    VDNumber: "65330074",
    RegisteredPopulation: "87",
    VotingStationName: "BRAKDAM",
    Address: "BRITSTOWN  KAMBRO  BRITSTOWN",
    Latitude: "-30.4163",
    Longitude: "23.5656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20812",
    WardLookupId: "4656",
    VDNumber: "65330142",
    RegisteredPopulation: "19",
    VotingStationName: "HOUTWATER SCHUUR FARM",
    Address: "HOUTWATER SCHUUR  EMTHANJENI MUNICIPALITY  BRITSTOWN",
    Latitude: "-30.3635",
    Longitude: "23.2751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20813",
    WardLookupId: "4656",
    VDNumber: "65330164",
    RegisteredPopulation: "50",
    VotingStationName: "MINTAKAROO",
    Address: "142 GIESENSKRAAL  WATERBERRY CROSSPATH  BRITSTOWN",
    Latitude: "-30.5689",
    Longitude: "23.3056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20814",
    WardLookupId: "4657",
    VDNumber: "65140107",
    RegisteredPopulation: "1086",
    VotingStationName: "NORTHERN CAPE RURAL FET COLLEGE",
    Address: "VAN RIEBEECK  DE AAR  DE AAR",
    Latitude: "-30.6631",
    Longitude: "24.0056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20815",
    WardLookupId: "4657",
    VDNumber: "65140163",
    RegisteredPopulation: "710",
    VotingStationName: "ORTHODOX CHURCH",
    Address: "EMTHANJENI  ",
    Latitude: "-30.672",
    Longitude: "24.02",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20816",
    WardLookupId: "4657",
    VDNumber: "65330030",
    RegisteredPopulation: "115",
    VotingStationName: "DOORNKUIL FARM",
    Address: "DOORNKUIL  BRITSTOWN",
    Latitude: "-30.469",
    Longitude: "23.6525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20817",
    WardLookupId: "4657",
    VDNumber: "65330153",
    RegisteredPopulation: "125",
    VotingStationName: "RIETPOORT FARM",
    Address: "FARM  RIETPOORT  BRITSTOWN",
    Latitude: "-30.766",
    Longitude: "23.4699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20818",
    WardLookupId: "4657",
    VDNumber: "65340110",
    RegisteredPopulation: "28",
    VotingStationName: "KWAGGAFONTEIN FARM",
    Address: "KWAGGAFONTEIN FARM  BRITSTOWN  EMTHANJENI",
    Latitude: "-30.5955",
    Longitude: "23.7848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20819",
    WardLookupId: "4657",
    VDNumber: "65340121",
    RegisteredPopulation: "80",
    VotingStationName: "HOUTKRAAL FARM FARM SCHOOL",
    Address: "HOTKRAAL  HOUTKRAAL  DE AAR",
    Latitude: "-30.3906",
    Longitude: "24.105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20820",
    WardLookupId: "4658",
    VDNumber: "65910012",
    RegisteredPopulation: "2261",
    VotingStationName: "CARNARVON HIGH SCHOOL",
    Address: "ZHAN  CARNARVON  CARNARVON",
    Latitude: "-30.9711",
    Longitude: "22.1313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20821",
    WardLookupId: "4658",
    VDNumber: "65910034",
    RegisteredPopulation: "281",
    VotingStationName: "KAREEBERG LIBRARY HALL",
    Address: "LONGSTREET  CARNARVON  CARNARVON",
    Latitude: "-30.9568",
    Longitude: "22.1264",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20822",
    WardLookupId: "4659",
    VDNumber: "65910023",
    RegisteredPopulation: "1981",
    VotingStationName: "CLUBHENSOP",
    Address: "BONTEHEUWEL  CARNARVON  CARNARVON",
    Latitude: "-30.9577",
    Longitude: "22.121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20823",
    WardLookupId: "4660",
    VDNumber: "65270011",
    RegisteredPopulation: "1041",
    VotingStationName: "VOSBURG GEMEENSKAPSAAL",
    Address: "HOUTBOSTRAAT  VOSBURG  VOSBURG",
    Latitude: "-30.5702",
    Longitude: "22.8758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20824",
    WardLookupId: "4660",
    VDNumber: "65990043",
    RegisteredPopulation: "102",
    VotingStationName: "KAREEBOS FARM",
    Address: "FARM  CARNARVON  CARNARVON",
    Latitude: "-30.9134",
    Longitude: "22.2506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20825",
    WardLookupId: "4660",
    VDNumber: "65990054",
    RegisteredPopulation: "59",
    VotingStationName: "ELANDSHOORN",
    Address: "CARNARVON  ",
    Latitude: "-31.0563",
    Longitude: "22.1505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20826",
    WardLookupId: "4660",
    VDNumber: "65990065",
    RegisteredPopulation: "68",
    VotingStationName: "REQUEST FARM",
    Address: "REQUEST FARM  VOSBURG  REQUEST FARM",
    Latitude: "-30.5646",
    Longitude: "22.4743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20827",
    WardLookupId: "4660",
    VDNumber: "65990076",
    RegisteredPopulation: "34",
    VotingStationName: "MODDERFONTEIN",
    Address: "FARM  VOSBURG  VOSBURG",
    Latitude: "-30.3449",
    Longitude: "22.7898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20828",
    WardLookupId: "4660",
    VDNumber: "65990087",
    RegisteredPopulation: "18",
    VotingStationName: "RHENOSTERKOLK",
    Address: "CARNARVON  ",
    Latitude: "-30.7101",
    Longitude: "22.1485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20829",
    WardLookupId: "4661",
    VDNumber: "65960017",
    RegisteredPopulation: "1293",
    VotingStationName: "VANWYKSVLEI GEMEENSKAPSAAL",
    Address: "HANEKAMWEG  VANWYKSVLEI  VANWYKSVLEI",
    Latitude: "-30.3484",
    Longitude: "21.8212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20830",
    WardLookupId: "4661",
    VDNumber: "65990010",
    RegisteredPopulation: "51",
    VotingStationName: "LEEUKOLK FARM",
    Address: "LEEU KOLK FARM  VANWYKSVLEI  VANWYKSVLEI",
    Latitude: "-30.4233",
    Longitude: "21.2954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20831",
    WardLookupId: "4661",
    VDNumber: "65990021",
    RegisteredPopulation: "281",
    VotingStationName: "SKIETFONTEIN HUIS NO 10",
    Address: "SKIETFONTEIN HUIS  CARNARVON  CARNARVON",
    Latitude: "-30.9656",
    Longitude: "22.0993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20832",
    WardLookupId: "4661",
    VDNumber: "65990032",
    RegisteredPopulation: "55",
    VotingStationName: "VOORREG FARM",
    Address: "VOORREG FARM  CARNARVON  CARNARVON",
    Latitude: "-30.9438",
    Longitude: "21.8717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20833",
    WardLookupId: "4662",
    VDNumber: "65210037",
    RegisteredPopulation: "1551",
    VotingStationName: "TOWN HALL PHILIPSTOWN",
    Address: "MARK  RENOSTERBERG [PHILLIPSTOWN]",
    Latitude: "-30.4358",
    Longitude: "24.4734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20834",
    WardLookupId: "4662",
    VDNumber: "65210059",
    RegisteredPopulation: "76",
    VotingStationName: "BOKKRAAL",
    Address: "053 0536652  BOKKRAAL  RENOSTERBERG [PHILLIPSTOWN]",
    Latitude: "-30.3197",
    Longitude: "24.3679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20835",
    WardLookupId: "4663",
    VDNumber: "65200014",
    RegisteredPopulation: "1076",
    VotingStationName: "THEMBINKOSI COMMUNITY HALL",
    Address:
      "1111 CHISANA STREET  THEMBINKOSI [PETRUSVILLE]  RENOSTERBERG [PHILLIPSTOWN]",
    Latitude: "-30.0763",
    Longitude: "24.671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20836",
    WardLookupId: "4663",
    VDNumber: "65250019",
    RegisteredPopulation: "910",
    VotingStationName: "NG KERK HALL UITSIG",
    Address: "CHURCH  STREET  UITSIG  PETRUSVILLE",
    Latitude: "-30.0824",
    Longitude: "24.6693",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20837",
    WardLookupId: "4664",
    VDNumber: "65250020",
    RegisteredPopulation: "485",
    VotingStationName: "KEURTJIESKLOOF GEMEENSKAPSAAL",
    Address: "SKOOL ST  KEURTJIEKLOOF  VANDERKLOOF",
    Latitude: "-30.0115",
    Longitude: "24.729",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20838",
    WardLookupId: "4664",
    VDNumber: "65250031",
    RegisteredPopulation: "597",
    VotingStationName: "THE ETHIOPIAN CHURCH OF SA",
    Address: "MARTHA TSHEMESE  GREEN POINT  PETRUSVILLE",
    Latitude: "-30.0821",
    Longitude: "24.6674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20839",
    WardLookupId: "4664",
    VDNumber: "65340075",
    RegisteredPopulation: "383",
    VotingStationName: "VANDERKLOOF STADSAAL",
    Address: "OLIEN   VADERKLOOF  RENOSTERBERG [PHILLIPSTOWN]",
    Latitude: "-29.9977",
    Longitude: "24.7408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20840",
    WardLookupId: "4665",
    VDNumber: "65200025",
    RegisteredPopulation: "324",
    VotingStationName: "TOWN HALL PETRUSVILLE",
    Address: "570 VISSAGIE  TOWN/ PETRUSVILLE  RENOSTERBERG [PHILLIPSTOWN]",
    Latitude: "-30.0823",
    Longitude: "24.6579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20841",
    WardLookupId: "4665",
    VDNumber: "65210015",
    RegisteredPopulation: "635",
    VotingStationName: "ELUKHANYISWENI",
    Address:
      "MATHANZIMA STREET  PHILIPSTOWN/ELUKAHNYISWENI  RENOSTERBERG [PHILLIPSTOWN]",
    Latitude: "-30.4236",
    Longitude: "24.4626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20842",
    WardLookupId: "4665",
    VDNumber: "65210026",
    RegisteredPopulation: "114",
    VotingStationName: "ROOIPOORT",
    Address: "ROOIPOORT FARM  RENOSTERBERG  PHILIPSTOWN",
    Latitude: "-30.3352",
    Longitude: "24.5125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20843",
    WardLookupId: "4665",
    VDNumber: "65210048",
    RegisteredPopulation: "55",
    VotingStationName: "ZWAGERSHOEK",
    Address: "ZWAGERSHOEK FARM  RENOSTERBERG  PHILIPSTOWN",
    Latitude: "-30.51",
    Longitude: "24.4329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20844",
    WardLookupId: "4665",
    VDNumber: "65340019",
    RegisteredPopulation: "141",
    VotingStationName: "KLOOFSIG",
    Address: "P.O.BOX. 8  PETRUSVILLE  RENOSTERBERG [PHILLIPSTOWN]",
    Latitude: "-29.9908",
    Longitude: "24.5597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20845",
    WardLookupId: "4665",
    VDNumber: "65340020",
    RegisteredPopulation: "93",
    VotingStationName: "POTFONTEIN PRIMARY SCHOOL",
    Address: "POTFONTEIN FARM  RENOSTERBERG MUNICIPALITY  PHILLIPSTOWN",
    Latitude: "-30.1989",
    Longitude: "24.1178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20846",
    WardLookupId: "4665",
    VDNumber: "65340064",
    RegisteredPopulation: "54",
    VotingStationName: "LOCKVIEW",
    Address: "LOCKVIEW FARM  PETRUSVILLE  RENOSTERBERG [PHILLIPSTOWN]",
    Latitude: "-29.9123",
    Longitude: "24.6537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20847",
    WardLookupId: "4666",
    VDNumber: "65240018",
    RegisteredPopulation: "1811",
    VotingStationName: "DR ESSOP COMMUNITY HALL",
    Address: "369 CUPIDO STREET  DEETLEFSVILLE  STRYDENBURG",
    Latitude: "-29.943",
    Longitude: "23.6672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20848",
    WardLookupId: "4666",
    VDNumber: "65330018",
    RegisteredPopulation: "83",
    VotingStationName: "SAAMSTAAN SKOOL",
    Address: "SAAMSTAANSKOOL FARM  PRIESKA  BO KAROO",
    Latitude: "-29.6504",
    Longitude: "23.4136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20849",
    WardLookupId: "4666",
    VDNumber: "65330085",
    RegisteredPopulation: "63",
    VotingStationName: "LUCKY VALLEY",
    Address:
      "LUCKY VALLEY FARM  THEMBELIHLE [HOPETOWN]  THEMBELIHLE [HOPETOWN]",
    Latitude: "-29.1331",
    Longitude: "23.6293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20850",
    WardLookupId: "4666",
    VDNumber: "65330096",
    RegisteredPopulation: "46",
    VotingStationName: "LEEUBERG",
    Address: "LEEUBERG FARM  THEMBELIHLE [HOPETOWN]  THEMBELIHLE [HOPETOWN]",
    Latitude: "-29.5337",
    Longitude: "23.6432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20851",
    WardLookupId: "4666",
    VDNumber: "65330108",
    RegisteredPopulation: "99",
    VotingStationName: "KRAANKUIL",
    Address: "KRAANKUIL FARM  THEMBELIHLE [HOPETOWN]  THEMBELIHLE [HOPETOWN]",
    Latitude: "-29.8816",
    Longitude: "24.1798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20852",
    WardLookupId: "4667",
    VDNumber: "65160020",
    RegisteredPopulation: "1371",
    VotingStationName: "MSC NUWE BIBLIOTEEKSAAL",
    Address: "HOSPITAAL STRAAT  HOPETOWN  HOPETOWN",
    Latitude: "-29.6226",
    Longitude: "24.0903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20853",
    WardLookupId: "4667",
    VDNumber: "65160053",
    RegisteredPopulation: "604",
    VotingStationName: "FULL ARMOUR CHURCH OF GOD",
    Address: "2962 7 DE LAAN  HOPETOWN  HOPETOWN",
    Latitude: "-29.6127",
    Longitude: "24.1051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20854",
    WardLookupId: "4667",
    VDNumber: "65320017",
    RegisteredPopulation: "681",
    VotingStationName: "ORANIA VOLKSKOOL",
    Address: "151 SMARAG  ORANIA  ORANIA",
    Latitude: "-29.8132",
    Longitude: "24.4141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20855",
    WardLookupId: "4667",
    VDNumber: "65330041",
    RegisteredPopulation: "200",
    VotingStationName: "BOLAND FARM",
    Address: "BOLAND AFRM  HOPETOWN  THEMBELIHLE [HOPETOWN]",
    Latitude: "-29.7318",
    Longitude: "24.3664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20856",
    WardLookupId: "4667",
    VDNumber: "65330063",
    RegisteredPopulation: "35",
    VotingStationName: "AMANDELSPAN",
    Address: "AMANDELSPAN  HOPETOWN  HOPETOWN",
    Latitude: "-29.3857",
    Longitude: "23.7061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20857",
    WardLookupId: "4668",
    VDNumber: "65160019",
    RegisteredPopulation: "1610",
    VotingStationName: "STEYNVILLE COMMUNITY HALL",
    Address: "AANDBLOMSTRAAT  STEYNVILLE  HOPETOWN",
    Latitude: "-29.6202",
    Longitude: "24.0998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20858",
    WardLookupId: "4668",
    VDNumber: "65160042",
    RegisteredPopulation: "342",
    VotingStationName: "VUKASIZWE PRIMARY SCHOOL",
    Address: "TRITS STREET  STEYNVILLE  HOPETOWN",
    Latitude: "-29.6168",
    Longitude: "24.1022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20859",
    WardLookupId: "4669",
    VDNumber: "65160031",
    RegisteredPopulation: "2215",
    VotingStationName: "STEYNVILLE HIGH SCHOOL",
    Address: "AANDBLOM STRAAT  STEYNVILLE  HOPETOWN",
    Latitude: "-29.6161",
    Longitude: "24.1045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20860",
    WardLookupId: "4670",
    VDNumber: "65220027",
    RegisteredPopulation: "3244",
    VotingStationName: "ETHEMBENI COMMUNITY HALL",
    Address: "CHOKO STREET  PRIESKA  PRIESKA",
    Latitude: "-29.659",
    Longitude: "22.7306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20861",
    WardLookupId: "4671",
    VDNumber: "65220038",
    RegisteredPopulation: "1544",
    VotingStationName: "OMEGA COMMUNITY HALL",
    Address: "1091 SCHOOL STREET  BONTEHEUWEL  SIYATHEMBA [PRIESKA]",
    Latitude: "-29.6663",
    Longitude: "22.7301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20862",
    WardLookupId: "4671",
    VDNumber: "65220049",
    RegisteredPopulation: "502",
    VotingStationName: "JJ DREYER PRIMERÃŠ SKOOL",
    Address: "MANS STREET  PRIESKA  PRIESKA",
    Latitude: "-29.6643",
    Longitude: "22.7303",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20863",
    WardLookupId: "4672",
    VDNumber: "65280078",
    RegisteredPopulation: "70",
    VotingStationName: "SWEMKUIL",
    Address: "FARM  SWEMKUIL  SIYATHEMBA [PRIESKA]",
    Latitude: "-29.4266",
    Longitude: "23.0178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20864",
    WardLookupId: "4672",
    VDNumber: "65280090",
    RegisteredPopulation: "48",
    VotingStationName: "DE EELT",
    Address: "DE EELT  PRIESKA  SIYATHEMBA NC077",
    Latitude: "-29.582",
    Longitude: "22.8503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20865",
    WardLookupId: "4672",
    VDNumber: "65280135",
    RegisteredPopulation: "128",
    VotingStationName: "HOÃ‹RSKOOL PRIESKA",
    Address: "VICTORIA STREET  PRIESKA TOWN  PRIESKA",
    Latitude: "-29.6714",
    Longitude: "22.754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20866",
    WardLookupId: "4672",
    VDNumber: "65330119",
    RegisteredPopulation: "46",
    VotingStationName: "KLIPFONTEIN",
    Address: "FARM  KLIPFONTEIN  BO KAROO",
    Latitude: "-29.2979",
    Longitude: "23.0364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20867",
    WardLookupId: "4672",
    VDNumber: "65450011",
    RegisteredPopulation: "1342",
    VotingStationName: "NIEKERKSHOOP COMMUNITY HALL",
    Address: "FLAMINK STREET  NIEKERKSHOOP  NIEKERKSHOOP",
    Latitude: "-29.3235",
    Longitude: "22.8392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20868",
    WardLookupId: "4672",
    VDNumber: "65500084",
    RegisteredPopulation: "31",
    VotingStationName: "ROOILAGTE",
    Address: "FARM  NIEKERSHOOP DISTRICT  NIEKERSHOOP",
    Latitude: "-29.3405",
    Longitude: "22.7699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20869",
    WardLookupId: "4672",
    VDNumber: "65500129",
    RegisteredPopulation: "52",
    VotingStationName: "TEMDALE BOERDERY",
    Address: "LOVEDALE  SIYATHEMBA [PRIESKA]",
    Latitude: "-29.4629",
    Longitude: "23.0056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20870",
    WardLookupId: "4673",
    VDNumber: "65180011",
    RegisteredPopulation: "1806",
    VotingStationName: "MARYDALE GEMEENSKAPSAAL",
    Address: "RONDOMSTRAAT  MARYDALE  MARYDALE",
    Latitude: "-29.4043",
    Longitude: "22.1106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20871",
    WardLookupId: "4673",
    VDNumber: "65280012",
    RegisteredPopulation: "93",
    VotingStationName: "COPPERTON CHURCH",
    Address: "AZURITE ST COPPERTON  PRIESKA  BO KAROO",
    Latitude: "-29.9208",
    Longitude: "22.2983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20872",
    WardLookupId: "4673",
    VDNumber: "65280089",
    RegisteredPopulation: "225",
    VotingStationName: "GREEN VALLEY NUTS",
    Address: "GREEN VALLEY NUTS  SIYATHEMBA [PRIESKA]",
    Latitude: "-29.577",
    Longitude: "22.9112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20873",
    WardLookupId: "4673",
    VDNumber: "65280102",
    RegisteredPopulation: "54",
    VotingStationName: "BUNDU",
    Address: "FARM  BO KAROO ,PRIESKA 8940  PRIESKA",
    Latitude: "-29.7496",
    Longitude: "22.2039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20874",
    WardLookupId: "4673",
    VDNumber: "65280113",
    RegisteredPopulation: "29",
    VotingStationName: "GROOT MODDERFONTEIN",
    Address: "GROOT MODDERFONTEIN FARM  PRIESKA  PRIESKA",
    Latitude: "-29.9659",
    Longitude: "22.7173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20875",
    WardLookupId: "4673",
    VDNumber: "65500062",
    RegisteredPopulation: "62",
    VotingStationName: "KOEGAS PUTS",
    Address: "KOEGAS FARM  MARYDALE  MARYDALE",
    Latitude: "-29.304",
    Longitude: "22.359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20876",
    WardLookupId: "4674",
    VDNumber: "65280034",
    RegisteredPopulation: "763",
    VotingStationName: "STADSAAL",
    Address: "01 VICTORIA STREET   PRIESKA MUNICIPALITY  SIYATHEMBA [PRIESKA]",
    Latitude: "-29.6666",
    Longitude: "22.7497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20877",
    WardLookupId: "4674",
    VDNumber: "65280045",
    RegisteredPopulation: "1415",
    VotingStationName: "DEPT,ROADS & PUBLIC WORKS",
    Address: "KROON STREET  PRIESKA  PRIESKA",
    Latitude: "-29.6647",
    Longitude: "22.7402",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20878",
    WardLookupId: "4674",
    VDNumber: "65280124",
    RegisteredPopulation: "884",
    VotingStationName: "TEMPELPOORT CHURCH",
    Address: "HAAKDORING  PRIESKA TOWN  PRIESKA",
    Latitude: "-29.6687",
    Longitude: "22.724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20879",
    WardLookupId: "4675",
    VDNumber: "65410017",
    RegisteredPopulation: "2425",
    VotingStationName: "RAINBOW VALLEY COMMUNITY HALL",
    Address: "840 KRUGER STREET  RAINBOW VALLEY  GRIEKWASTAD",
    Latitude: "-28.8504",
    Longitude: "23.2463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20880",
    WardLookupId: "4675",
    VDNumber: "65500073",
    RegisteredPopulation: "80",
    VotingStationName: "CONE",
    Address: "FARM  GRIEKWASTAD  GRIEKWASTAD",
    Latitude: "-28.7201",
    Longitude: "22.7256",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20881",
    WardLookupId: "4675",
    VDNumber: "65530245",
    RegisteredPopulation: "70",
    VotingStationName: "MOOSFONTEIN",
    Address: "FARM  MOOSFONTEIN  GRIEKWASTAD",
    Latitude: "-28.7788",
    Longitude: "23.1465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20882",
    WardLookupId: "4676",
    VDNumber: "65530021",
    RegisteredPopulation: "3118",
    VotingStationName: "CHURCH OF CHRIST MISSION",
    Address: "2180 DASSIEVOEL STREET  BREIPAL  DOUGLAS",
    Latitude: "-29.0397",
    Longitude: "23.7587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20883",
    WardLookupId: "4677",
    VDNumber: "65400027",
    RegisteredPopulation: "3080",
    VotingStationName: "BONGANI GEMEENSKAPSAAL",
    Address: "NGXILA STR  BONGANI  DOUGLAS",
    Latitude: "-29.0693",
    Longitude: "23.7766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20884",
    WardLookupId: "4678",
    VDNumber: "65400038",
    RegisteredPopulation: "237",
    VotingStationName: "SIYANCUMA TRAFFIC DEPARTMENT",
    Address: "DE VILLIERS STREET  DOUGLAS  DOUGLAS",
    Latitude: "-29.0614",
    Longitude: "23.7742",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20885",
    WardLookupId: "4678",
    VDNumber: "65400049",
    RegisteredPopulation: "1034",
    VotingStationName: "BONGANI SECONDARY SCHOOL",
    Address: "1283 DIRANG STREET  DOUGLAS  BONGANI",
    Latitude: "-29.0727",
    Longitude: "23.7712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20886",
    WardLookupId: "4678",
    VDNumber: "65490015",
    RegisteredPopulation: "521",
    VotingStationName: "BUCKLANDS PRIMARY SCHOOL",
    Address: "DOUGLAS SHOW GROUNDS  ERF 352  DOUGLAS",
    Latitude: "-29.071",
    Longitude: "23.689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20887",
    WardLookupId: "4678",
    VDNumber: "65530032",
    RegisteredPopulation: "258",
    VotingStationName: "VAALLUS PRIMARY SCHOOL",
    Address: "VAALLUS  VAALLUS PLAAS  DOUGLAS",
    Latitude: "-28.9556",
    Longitude: "23.9427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20888",
    WardLookupId: "4678",
    VDNumber: "65530267",
    RegisteredPopulation: "724",
    VotingStationName: "DOUGLAS CHRISTEN NETWERK",
    Address: "BOWKER STREET  DOUGLAS  DOUGLAS",
    Latitude: "-29.0533",
    Longitude: "23.7688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20889",
    WardLookupId: "4679",
    VDNumber: "65400016",
    RegisteredPopulation: "1811",
    VotingStationName: "BREIPAAL GEMEENSKAPSAAL",
    Address: "598 LANGSTRAAT  BREIPAAL  DOUGLAS",
    Latitude: "-29.0463",
    Longitude: "23.7656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20890",
    WardLookupId: "4679",
    VDNumber: "65530346",
    RegisteredPopulation: "885",
    VotingStationName: "WESLAAN SECONDARY SCHOOL",
    Address: "WESLAAN  BREIPAAL  DOUGLAS",
    Latitude: "-29.0473",
    Longitude: "23.7578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20891",
    WardLookupId: "4680",
    VDNumber: "65530043",
    RegisteredPopulation: "192",
    VotingStationName: "DOUGLAS VAKANSIEOORD (AVOCA)",
    Address: "DOUGLAS VAKANSIEOORD (ACOCA)  DOUGLAS  DOUGLAS",
    Latitude: "-29.0403",
    Longitude: "23.8417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20892",
    WardLookupId: "4680",
    VDNumber: "65530054",
    RegisteredPopulation: "242",
    VotingStationName: "PLOOYSBURG SCHOOL",
    Address: "PLOOYSBURG SCHOOL  PLOOYSBURG  DOUGLAS",
    Latitude: "-29.0238",
    Longitude: "24.2383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20893",
    WardLookupId: "4680",
    VDNumber: "65530065",
    RegisteredPopulation: "289",
    VotingStationName: "SALT LAKE SCHOOL",
    Address: "SALTLAKE  SALT LAKE  DOUGLAS",
    Latitude: "-29.3024",
    Longitude: "23.9999",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20894",
    WardLookupId: "4680",
    VDNumber: "65530076",
    RegisteredPopulation: "152",
    VotingStationName: "WITPUT HOTEL (THE GRANGE)",
    Address: "WITPUT STATION  WITPUT  HOPETOWN",
    Latitude: "-29.5281",
    Longitude: "24.2641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20895",
    WardLookupId: "4680",
    VDNumber: "65530087",
    RegisteredPopulation: "169",
    VotingStationName: "WINTERSHOEK GAME LODGE",
    Address: "WINTERSHOEK  MODDERRIVIER  WINTERSHOEK",
    Latitude: "-29.2104",
    Longitude: "24.5286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20896",
    WardLookupId: "4680",
    VDNumber: "65530098",
    RegisteredPopulation: "349",
    VotingStationName: "OLIERIVIER PRIMARY SCHOOL",
    Address: "OLIERIVIER  OLIERIVIER FARM  DOUGLAS",
    Latitude: "-28.925",
    Longitude: "23.9777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20897",
    WardLookupId: "4680",
    VDNumber: "65530212",
    RegisteredPopulation: "803",
    VotingStationName: "SCHMIDTSDRIFT BATLHAPING INTERMEDIATE SCHOOL",
    Address: "SCHMIDSDRIFT NO.4  SCHMIDSDRIFT  KIMBERLEY",
    Latitude: "-28.7025",
    Longitude: "24.0605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20898",
    WardLookupId: "4680",
    VDNumber: "65530223",
    RegisteredPopulation: "263",
    VotingStationName: "NATIVE INDEPENDENT CONGREGATIONAL CHURCH",
    Address: "SCHMIDSDRIFT NO.5  SCHMIDSDRIFT   KIMBERLEY",
    Latitude: "-28.747",
    Longitude: "23.9904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20899",
    WardLookupId: "4680",
    VDNumber: "65530290",
    RegisteredPopulation: "261",
    VotingStationName: "RIETRIVIER NAVORSINGSTASIE",
    Address: "FARM  RIETRIVIER  KIMBERLEY",
    Latitude: "-29.0972",
    Longitude: "24.6043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20900",
    WardLookupId: "4681",
    VDNumber: "65330120",
    RegisteredPopulation: "42",
    VotingStationName: "BRAKKIES",
    Address: "FARM  BRAKKIES  DOUGLAS",
    Latitude: "-29.4295",
    Longitude: "23.3319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20901",
    WardLookupId: "4681",
    VDNumber: "65410028",
    RegisteredPopulation: "840",
    VotingStationName: "MATLHOMOLA COMMUNITY HALL",
    Address: "MONTSIWA  MATLHOMOLA  SIYANCUMA [GRIEKWASTAD]",
    Latitude: "-28.8642",
    Longitude: "23.2673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20902",
    WardLookupId: "4681",
    VDNumber: "65500039",
    RegisteredPopulation: "169",
    VotingStationName: "NG KERK",
    Address: "LIMEWELL  GROBLESHOOP  GROBLERSHOOP",
    Latitude: "-28.8444",
    Longitude: "22.5052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20903",
    WardLookupId: "4681",
    VDNumber: "65500040",
    RegisteredPopulation: "140",
    VotingStationName: "TSEBE PRIMARY SCHOOL",
    Address: "TSEBE  TSEBE PLAAS  GROBLERSHOOP",
    Latitude: "-28.8846",
    Longitude: "22.1087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20904",
    WardLookupId: "4681",
    VDNumber: "65500107",
    RegisteredPopulation: "59",
    VotingStationName: "NEK FARM",
    Address: "FARM  NEK  POSTMASBURG",
    Latitude: "-28.8774",
    Longitude: "22.7707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20905",
    WardLookupId: "4681",
    VDNumber: "65500118",
    RegisteredPopulation: "39",
    VotingStationName: "DUIKERSDAL",
    Address: "FARM  DUIKERSDAL  POSTMASBURG",
    Latitude: "-29.0728",
    Longitude: "22.5616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20906",
    WardLookupId: "4681",
    VDNumber: "65530256",
    RegisteredPopulation: "1336",
    VotingStationName: "CAMPBELL COMMUNITY HALL",
    Address: "ALWYN STR  CAMPBELL  SIYANCUMA [GRIEKWASTAD]",
    Latitude: "-28.7973",
    Longitude: "23.7261",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20907",
    WardLookupId: "4681",
    VDNumber: "65530278",
    RegisteredPopulation: "50",
    VotingStationName: "HOOGFONTEIN",
    Address: "FARM  GRIEKWASTAD  CAMPBELL",
    Latitude: "-28.9333",
    Longitude: "23.5667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20908",
    WardLookupId: "4681",
    VDNumber: "65530289",
    RegisteredPopulation: "36",
    VotingStationName: "WITWATER",
    Address: "FARM  WITWATER  GRIEKWASTAD",
    Latitude: "-28.98",
    Longitude: "22.9972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20909",
    WardLookupId: "4682",
    VDNumber: "65060018",
    RegisteredPopulation: "1670",
    VotingStationName: "AUGRABIES RK SENDING LAERSKOOL",
    Address: "ASSUMPTA RK SENDING  AUGRABIES  AUGRABIES",
    Latitude: "-28.6715",
    Longitude: "20.4247",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20910",
    WardLookupId: "4682",
    VDNumber: "65060041",
    RegisteredPopulation: "771",
    VotingStationName: "ALHEIT VGK LAERSKOOL",
    Address: "ALHEIT PRIMERE SKOOL  VGK PERSEEL  ALHEIT",
    Latitude: "-28.7604",
    Longitude: "20.5365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20911",
    WardLookupId: "4682",
    VDNumber: "65090279",
    RegisteredPopulation: "486",
    VotingStationName: "PERDE-EILAND PRIMÃŠRE SKOOL",
    Address: "SCHOOL GROUNDS  PERDE-EILAND  MARCHAND",
    Latitude: "-28.7243",
    Longitude: "20.5055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20912",
    WardLookupId: "4683",
    VDNumber: "65060030",
    RegisteredPopulation: "1270",
    VotingStationName: "MARCHAND COMMUNITY HALL MARCHAND",
    Address: "03 MADELIEFIE STREET  MARCHAND  MARCHAND",
    Latitude: "-28.7325",
    Longitude: "20.5011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20913",
    WardLookupId: "4683",
    VDNumber: "65070020",
    RegisteredPopulation: "1414",
    VotingStationName: "CILLIE GEMEENSKAPSAAL",
    Address: "64 KANNIEDOOD STR  CILLIE  KAKAMAS",
    Latitude: "-28.7351",
    Longitude: "20.5859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20914",
    WardLookupId: "4684",
    VDNumber: "65060052",
    RegisteredPopulation: "2363",
    VotingStationName: "HIGH SCHOOL KAKAMAS",
    Address: "RAND STREET  LANGVERWAGHT  KAKAMAS",
    Latitude: "-28.7913",
    Longitude: "20.6299",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20915",
    WardLookupId: "4684",
    VDNumber: "65060063",
    RegisteredPopulation: "194",
    VotingStationName: "HARTEBEES STASIE",
    Address: "9-2 PLOT  TRUTER  TRUTER",
    Latitude: "-28.7631",
    Longitude: "20.5483",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20916",
    WardLookupId: "4685",
    VDNumber: "64950026",
    RegisteredPopulation: "1790",
    VotingStationName: "LAERSKOOL KAKAMAS",
    Address: "1225 SONNEBLOM  LANGVERWACHT  KAKAMAS",
    Latitude: "-28.7906",
    Longitude: "20.6329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20917",
    WardLookupId: "4685",
    VDNumber: "65090257",
    RegisteredPopulation: "520",
    VotingStationName: "KROMHOUT BOERDERY",
    Address: "VOORTREKKER  KAKAMAS  KAKAMAS",
    Latitude: "-28.7878",
    Longitude: "20.6476",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20918",
    WardLookupId: "4685",
    VDNumber: "65090370",
    RegisteredPopulation: "119",
    VotingStationName: "NEUS",
    Address: "FARM  KAKAMAS  KAKAMAS",
    Latitude: "-28.7835",
    Longitude: "20.7302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20919",
    WardLookupId: "4686",
    VDNumber: "64930035",
    RegisteredPopulation: "268",
    VotingStationName: "LANGEILAND PRIMÃŠRE SKOOL",
    Address: "KEIMOES EILANDE  KAI !GARIB [KEIMOES]  KAI !GARIB [KEIMOES]",
    Latitude: "-28.7292",
    Longitude: "21.0625",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20920",
    WardLookupId: "4686",
    VDNumber: "64960027",
    RegisteredPopulation: "938",
    VotingStationName: "KEIMOES TOWN HALL",
    Address: "MAIN STREET  KEIMOES  KEIMOES",
    Latitude: "-28.7072",
    Longitude: "20.9695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20921",
    WardLookupId: "4686",
    VDNumber: "65090077",
    RegisteredPopulation: "1516",
    VotingStationName: "LENNERTSVILLE COMMUNITY HALL",
    Address: "LENNERTSVILLE  KEIMOES  KEIMOES",
    Latitude: "-28.7525",
    Longitude: "20.9945",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20922",
    WardLookupId: "4687",
    VDNumber: "64960016",
    RegisteredPopulation: "1956",
    VotingStationName: "BISKOP FAGES HALL",
    Address: "CHURCH STREET  GARDENIA  KEIMOES",
    Latitude: "-28.7012",
    Longitude: "20.9531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20923",
    WardLookupId: "4687",
    VDNumber: "64960038",
    RegisteredPopulation: "1736",
    VotingStationName: "VOLLE EVANGELISTIC CHURCH",
    Address: "1052 ELIZABETH STREET  GARDENIA  KEIMOES",
    Latitude: "-28.6924",
    Longitude: "20.9525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20924",
    WardLookupId: "4688",
    VDNumber: "64950015",
    RegisteredPopulation: "1135",
    VotingStationName: "KAKAMAS LAERSKOOL SENTRAAL",
    Address: "VOORTREKKERSTRAAT  KAKAMAS  KAKAMAS",
    Latitude: "-28.7734",
    Longitude: "20.6203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20925",
    WardLookupId: "4688",
    VDNumber: "65070042",
    RegisteredPopulation: "1015",
    VotingStationName: "LUTZBURG LAERSKOOL",
    Address: "FARM  LUTZBURG  KEIMOES",
    Latitude: "-28.7445",
    Longitude: "20.6318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20926",
    WardLookupId: "4688",
    VDNumber: "65090112",
    RegisteredPopulation: "438",
    VotingStationName: "LOXTONVALE PRIMÃŠRE SKOOL",
    Address: "FARM  EENDUIN  KEIMOES",
    Latitude: "-28.7101",
    Longitude: "20.8737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20927",
    WardLookupId: "4688",
    VDNumber: "65090156",
    RegisteredPopulation: "532",
    VotingStationName: "FRIERSDALE",
    Address:
      "R.C. MISSION FRIERSDALE  KAI !GARIB [KEIMOES]  KAI !GARIB [KEIMOES]",
    Latitude: "-28.7421",
    Longitude: "20.8118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20928",
    WardLookupId: "4688",
    VDNumber: "65090369",
    RegisteredPopulation: "482",
    VotingStationName: "LIVING WORD EVANGELISTIC MOVEMENT",
    Address: "00 WARMSAND  KEIMOES",
    Latitude: "-28.7586",
    Longitude: "20.7731",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20929",
    WardLookupId: "4688",
    VDNumber: "65090404",
    RegisteredPopulation: "642",
    VotingStationName: "GAMAKOR COMMUNITY HALL",
    Address: "01 GAMAKOR  KEIMOES",
    Latitude: "-28.6984",
    Longitude: "20.9468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20930",
    WardLookupId: "4689",
    VDNumber: "64930013",
    RegisteredPopulation: "271",
    VotingStationName: "GEORGE EILAND PRIMÃŠRE SKOOL",
    Address: "NO STREET ADDRESS  KAI !GARIB [KEIMOES]  KAI !GARIB [KEIMOES]",
    Latitude: "-28.7094",
    Longitude: "21.0228",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20931",
    WardLookupId: "4689",
    VDNumber: "64930024",
    RegisteredPopulation: "196",
    VotingStationName: "VYEBOS PRIMÃŠRE SKOOL",
    Address: "KEIMOES EILANDE  KAI !GARIB [KEIMOES]  KAI !GARIB [KEIMOES]",
    Latitude: "-28.7249",
    Longitude: "21.0455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20932",
    WardLookupId: "4689",
    VDNumber: "65090033",
    RegisteredPopulation: "840",
    VotingStationName: "KANONEILAND BIBLIOTEEK",
    Address: "HOOF STREET  KANONEILAND  KANONEILAND",
    Latitude: "-28.6521",
    Longitude: "21.087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20933",
    WardLookupId: "4689",
    VDNumber: "65090134",
    RegisteredPopulation: "522",
    VotingStationName: "BLAAUWSEKOP",
    Address: "BLAAUWSEKOP  KANONEILAND  KANONEILAND",
    Latitude: "-28.6497",
    Longitude: "21.1058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20934",
    WardLookupId: "4689",
    VDNumber: "65090325",
    RegisteredPopulation: "570",
    VotingStationName: "LAERSKOOL SOVERBY",
    Address: "NO STREET ADDRESS  KAI !GARIB [KEIMOES]  KAI !GARIB [KEIMOES]",
    Latitude: "-28.6905",
    Longitude: "21.0146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20935",
    WardLookupId: "4689",
    VDNumber: "65090336",
    RegisteredPopulation: "432",
    VotingStationName: "CURRIESKAMP PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  KAI !GARIB [KEIMOES]  KAI !GARIB [KEIMOES]",
    Latitude: "-28.6763",
    Longitude: "21.0382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20936",
    WardLookupId: "4689",
    VDNumber: "65090347",
    RegisteredPopulation: "242",
    VotingStationName: "MC TAGGARTSKAMP PRIMARY SCHOOL",
    Address: "NO STREET ADDRESS  MCTAGGARTSKAMP  KAI !GARIB [KEIMOES]",
    Latitude: "-28.6029",
    Longitude: "21.1189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20937",
    WardLookupId: "4689",
    VDNumber: "65090358",
    RegisteredPopulation: "291",
    VotingStationName: "BLOEMSMOND PRIMÃŠRE SKOOL",
    Address: "NO STREET ADDRESS  KAI !GARIB [KEIMOES]  KAI !GARIB [KEIMOES]",
    Latitude: "-28.6311",
    Longitude: "21.0794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20938",
    WardLookupId: "4690",
    VDNumber: "64970017",
    RegisteredPopulation: "1935",
    VotingStationName: "HOÃ‹RSKOOL KENHARDT",
    Address: "KERKSTRAAT  KENHARDT  KENHARDT",
    Latitude: "-29.3479",
    Longitude: "21.1551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20939",
    WardLookupId: "4690",
    VDNumber: "64970028",
    RegisteredPopulation: "1250",
    VotingStationName: "KENHARDT COMMUNITY HALL",
    Address: "KENHARDT  ",
    Latitude: "-29.3365",
    Longitude: "21.1569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20940",
    WardLookupId: "4690",
    VDNumber: "65010035",
    RegisteredPopulation: "53",
    VotingStationName: "SANDKOLK",
    Address: "SANDKOLK  KAKAMAS  [BENEDE]",
    Latitude: "-29.5124",
    Longitude: "20.3824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20941",
    WardLookupId: "4690",
    VDNumber: "65090101",
    RegisteredPopulation: "249",
    VotingStationName: "NOOITGEDACHT",
    Address: "KHOMS  KEIMOES  KAI !GARIB RURAL",
    Latitude: "-28.7447",
    Longitude: "20.9628",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20942",
    WardLookupId: "4691",
    VDNumber: "65010068",
    RegisteredPopulation: "272",
    VotingStationName: "SOUTHERN FARMS",
    Address: "SOUTHERN FARMS  BENEDE",
    Latitude: "-28.5215",
    Longitude: "19.6923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20943",
    WardLookupId: "4691",
    VDNumber: "65010080",
    RegisteredPopulation: "29",
    VotingStationName: "BLADGROND FARM",
    Address: "FARM  SOUTHERN FARMS  SOUTHERN FARMS",
    Latitude: "-28.875",
    Longitude: "19.9216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20944",
    WardLookupId: "4691",
    VDNumber: "65030127",
    RegisteredPopulation: "35",
    VotingStationName: "LANGKLIP PLAAS",
    Address: "FARM  LANGKLIP  KEIMOES",
    Latitude: "-28.2139",
    Longitude: "20.3357",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20945",
    WardLookupId: "4691",
    VDNumber: "65070019",
    RegisteredPopulation: "426",
    VotingStationName: "RIEMVASMAAK GEMEENSKAPSAAL",
    Address: "RIEMVASMAAK  ",
    Latitude: "-28.4503",
    Longitude: "20.3708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20946",
    WardLookupId: "4691",
    VDNumber: "65070031",
    RegisteredPopulation: "417",
    VotingStationName: "OMDRAAI",
    Address: "OMDRAAI  KAI !GARIB",
    Latitude: "-28.635",
    Longitude: "20.4195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20947",
    WardLookupId: "4691",
    VDNumber: "65070053",
    RegisteredPopulation: "318",
    VotingStationName: "VREDESVALLEI GEMEENSKAPSAAL",
    Address: "01 MADIBA STREET  VREDESVALLEI  KAKAMAS",
    Latitude: "-28.5049",
    Longitude: "20.1947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20948",
    WardLookupId: "4691",
    VDNumber: "65090088",
    RegisteredPopulation: "364",
    VotingStationName: "LEESGENOT BIBLIOTEEKSAAL",
    Address: "LOOP STREET  KAI !GARIB [KEIMOES]  KAI !GARIB [KEIMOES]",
    Latitude: "-28.7009",
    Longitude: "20.9607",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20949",
    WardLookupId: "4691",
    VDNumber: "65090145",
    RegisteredPopulation: "630",
    VotingStationName: "ST MARIA GORRETTI SCHOOL",
    Address: "ST MARIA CORETTI   NOUDONSIES  NOUDONSIES",
    Latitude: "-28.6531",
    Longitude: "20.3655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20950",
    WardLookupId: "4691",
    VDNumber: "65090167",
    RegisteredPopulation: "42",
    VotingStationName: "LUTZPUTS PRIMÃŠRE SKOOL",
    Address: "LUTZPUTS  KEIMOES",
    Latitude: "-28.3887",
    Longitude: "20.6613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20951",
    WardLookupId: "4691",
    VDNumber: "65090280",
    RegisteredPopulation: "477",
    VotingStationName: "LEERKRANS",
    Address: "BLOUPUTS  BLOUPUTS VALLEI  BLOUPUTS",
    Latitude: "-28.4848",
    Longitude: "20.117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20952",
    WardLookupId: "4692",
    VDNumber: "65020025",
    RegisteredPopulation: "1113",
    VotingStationName: "TOPLINE GEMEENSKAPSAAL",
    Address: "231 PROTEA STREET  TOPLINE  GROBLERSHOOP",
    Latitude: "-28.7512",
    Longitude: "21.8432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20953",
    WardLookupId: "4692",
    VDNumber: "65020069",
    RegisteredPopulation: "33",
    VotingStationName: "PUTSONDERWATER",
    Address: "PUTSONDERWATER  PUTSONDERWATER FARM  GROBLERSHOOP",
    Latitude: "-29.2332",
    Longitude: "21.8801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20954",
    WardLookupId: "4692",
    VDNumber: "65020070",
    RegisteredPopulation: "1223",
    VotingStationName: "WEGDRAAI GEMEENSKAPSAAL",
    Address: "391 JEUGSTRAAT  WEGDRAAI  WEGDRAAI",
    Latitude: "-28.8383",
    Longitude: "21.8643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20955",
    WardLookupId: "4692",
    VDNumber: "65090011",
    RegisteredPopulation: "69",
    VotingStationName: "WITDAM",
    Address: "WITDAM KLEINBEGIN  KLEINBEGIN  GROBLERSHOOP",
    Latitude: "-28.9326",
    Longitude: "21.677",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20956",
    WardLookupId: "4692",
    VDNumber: "65090314",
    RegisteredPopulation: "14",
    VotingStationName: "TROOILAPSPAN",
    Address: "KLEINBEGIN  KENHARDT",
    Latitude: "-28.6938",
    Longitude: "21.5179",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20957",
    WardLookupId: "4693",
    VDNumber: "65020014",
    RegisteredPopulation: "1847",
    VotingStationName: "GROOTDRINK GEMEENSKAPSAAL",
    Address: "GROOTDRINK  KHEIS [GROBLERSHOOP]",
    Latitude: "-28.5639",
    Longitude: "21.7486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20958",
    WardLookupId: "4693",
    VDNumber: "65020036",
    RegisteredPopulation: "550",
    VotingStationName: "GARIEP LAERSKOOL",
    Address: "PLOT 5 GROOTDRINK UPINGTON 8822  GARIEP  KHEIS [GROBLERSHOOP]",
    Latitude: "-28.6132",
    Longitude: "21.7793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20959",
    WardLookupId: "4693",
    VDNumber: "65020104",
    RegisteredPopulation: "47",
    VotingStationName: "LAERSKOOL WILGENHOUTSDRIF",
    Address: "69 PLOT  WILGENHOUTSDRIF  GROBLERSHOOP",
    Latitude: "-28.521",
    Longitude: "21.7319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20960",
    WardLookupId: "4694",
    VDNumber: "64940025",
    RegisteredPopulation: "1887",
    VotingStationName: "NUWE HOOP SENTRUM",
    Address: "206 MADELIEFIE STREET  STERNHAM  GROBLERSHOOP",
    Latitude: "-28.9079",
    Longitude: "22.0059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20961",
    WardLookupId: "4694",
    VDNumber: "65020047",
    RegisteredPopulation: "431",
    VotingStationName: "GROBLERSHOOP HOÃ‹RSKOOL",
    Address: "02 KANAAL  GROBLERSHOOP  GROBLERSHOOP",
    Latitude: "-28.8953",
    Longitude: "21.9884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20962",
    WardLookupId: "4694",
    VDNumber: "65020115",
    RegisteredPopulation: "374",
    VotingStationName: "UITSIG PRIMÃŠR",
    Address: "165 PERSEEL  OPWAG  GROBLERSHOOP",
    Latitude: "-28.8455",
    Longitude: "21.977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20963",
    WardLookupId: "4695",
    VDNumber: "64940036",
    RegisteredPopulation: "902",
    VotingStationName: "STERNHAM INTERMEDIÃ‹RE SKOOL",
    Address: "1 SONNEBLOM  STERNHAM  GROBLERSHOOP",
    Latitude: "-28.907",
    Longitude: "22.0078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20964",
    WardLookupId: "4695",
    VDNumber: "65020058",
    RegisteredPopulation: "88",
    VotingStationName: "WAG N` BIETJIE",
    Address: "GROBLERSHOOP  GROBLERSGOOP",
    Latitude: "-28.8836",
    Longitude: "22.021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20965",
    WardLookupId: "4695",
    VDNumber: "65110014",
    RegisteredPopulation: "1307",
    VotingStationName: "BOEGOEBERG GEMEENSKAPSAAL",
    Address: "1734 SKOOLSTRAAT  BOEGOEBERG  GROBLERSHOOP",
    Latitude: "-28.9273",
    Longitude: "22.1269",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20966",
    WardLookupId: "4696",
    VDNumber: "65600029",
    RegisteredPopulation: "2075",
    VotingStationName: "POSTDENE COMMUNITY CENTRE",
    Address: "19 PLEIN STREET  POSTDENE  POSTMASBURG",
    Latitude: "-28.3004",
    Longitude: "23.0897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20967",
    WardLookupId: "4697",
    VDNumber: "65600018",
    RegisteredPopulation: "2196",
    VotingStationName: "NEW TOWN COMMUNITY HALL",
    Address: "22 AREND STREET  NEWTOWN  TSANTSABANE [POSTMASBURG]",
    Latitude: "-28.3226",
    Longitude: "23.0587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20968",
    WardLookupId: "4698",
    VDNumber: "65600063",
    RegisteredPopulation: "2206",
    VotingStationName: "POSTDENE PRIMARY SCHOOL",
    Address: "SKOOLSTREET  POSTDENE  POSTMASBURG",
    Latitude: "-28.3007",
    Longitude: "23.0848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20969",
    WardLookupId: "4698",
    VDNumber: "65640045",
    RegisteredPopulation: "722",
    VotingStationName: "GROENWATER COMMUNITY HALL",
    Address: "GROENWATER  POSTMANSBURG  POSTMASBURG",
    Latitude: "-28.2915",
    Longitude: "23.3133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20970",
    WardLookupId: "4698",
    VDNumber: "65640067",
    RegisteredPopulation: "520",
    VotingStationName: "JENN HAVEN BIBLIOTEEK",
    Address: "JENN HAVEN  POSTMASBURG",
    Latitude: "-28.2255",
    Longitude: "23.2514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20971",
    WardLookupId: "4698",
    VDNumber: "65660115",
    RegisteredPopulation: "176",
    VotingStationName: "FORUM HALL",
    Address: "LOHATLHA  POSTMANSBURG",
    Latitude: "-28.0297",
    Longitude: "23.1034",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20972",
    WardLookupId: "4699",
    VDNumber: "65600041",
    RegisteredPopulation: "1563",
    VotingStationName: "RATANG THUTO SENIOR SECONDARY SCHOOL",
    Address: "31 MATSOBANE STREET  BOICHOKO  POSTMASBURG [TSANTSABANE]",
    Latitude: "-28.3206",
    Longitude: "23.0385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20973",
    WardLookupId: "4699",
    VDNumber: "65600096",
    RegisteredPopulation: "1055",
    VotingStationName: "BOICHOKO PRE SCHOOL",
    Address: "BOSEALETSE  BOICHOKO  POSTMASBURG",
    Latitude: "-28.321",
    Longitude: "23.0446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20974",
    WardLookupId: "4700",
    VDNumber: "65600030",
    RegisteredPopulation: "1296",
    VotingStationName: "SHILOAH ACADEMY COMBINED SCHOOL",
    Address: "11 NOCHOLAS STREET  POSTMASBURG  POSTMASBURG",
    Latitude: "-28.3361",
    Longitude: "23.0595",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20975",
    WardLookupId: "4700",
    VDNumber: "65600085",
    RegisteredPopulation: "1319",
    VotingStationName: "CHURCH OF CHRIST MISSION",
    Address: "6104 MARANTENG  NEWTOWN  POSTMASBURG",
    Latitude: "-28.3248",
    Longitude: "23.0631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20976",
    WardLookupId: "4701",
    VDNumber: "65030026",
    RegisteredPopulation: "148",
    VotingStationName: "BOPLAAS FARM",
    Address: "BOPLAAS  UPINGTON  UPINGTON",
    Latitude: "-28.2026",
    Longitude: "22.0324",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20977",
    WardLookupId: "4701",
    VDNumber: "65030138",
    RegisteredPopulation: "43",
    VotingStationName: "KOUPAN",
    Address: "TSANTSABANE  POSTMASBURG  POSTMASBURG",
    Latitude: "-27.974",
    Longitude: "21.8531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20978",
    WardLookupId: "4701",
    VDNumber: "65600052",
    RegisteredPopulation: "1290",
    VotingStationName: "KATINKA PRE-PRIMARY SCHOOL",
    Address: "KAGISHO   NEWTOWN  TSANTSABANE [POSTMASBURG]",
    Latitude: "-28.3209",
    Longitude: "23.0563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20979",
    WardLookupId: "4701",
    VDNumber: "65630011",
    RegisteredPopulation: "463",
    VotingStationName: "TSANTSABANE TRAFFIC DEPARTMENT",
    Address: "EIGHTH AVENUE  STATION  POSTMASBURG",
    Latitude: "-28.3169",
    Longitude: "23.066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20980",
    WardLookupId: "4701",
    VDNumber: "65660014",
    RegisteredPopulation: "47",
    VotingStationName: "SAAMBOUSAAL",
    Address: "229 OLIFANTSHOEK  PLAAS VROEGGEDEEL  ",
    Latitude: "-28.0188",
    Longitude: "22.463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20981",
    WardLookupId: "4701",
    VDNumber: "65660036",
    RegisteredPopulation: "91",
    VotingStationName: "WINTONSAAL",
    Address: "WINTON FARM  DEBEN  SIYANDA",
    Latitude: "-27.4967",
    Longitude: "22.6283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20982",
    WardLookupId: "4701",
    VDNumber: "65660047",
    RegisteredPopulation: "386",
    VotingStationName: "MAREMANE CARE CENTRE",
    Address: "NONE  MAREMANE  POSTMASBURG",
    Latitude: "-28.0488",
    Longitude: "23.0492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20983",
    WardLookupId: "4701",
    VDNumber: "65660058",
    RegisteredPopulation: "47",
    VotingStationName: "HEUNINGKOP STOOR",
    Address: "PLAAS HEUNINGKOP  ",
    Latitude: "-28.1796",
    Longitude: "22.3167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20984",
    WardLookupId: "4701",
    VDNumber: "65660069",
    RegisteredPopulation: "46",
    VotingStationName: "MEERLUST",
    Address: "MEERLUST FARM  OLIFANTSHOEK  [BENEDE]",
    Latitude: "-27.6571",
    Longitude: "22.4455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20985",
    WardLookupId: "4701",
    VDNumber: "65660092",
    RegisteredPopulation: "24",
    VotingStationName: "SMITHERS",
    Address: "SMITHERS  SIYANDA",
    Latitude: "-27.8159",
    Longitude: "22.5192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20986",
    WardLookupId: "4701",
    VDNumber: "65660104",
    RegisteredPopulation: "58",
    VotingStationName: "MOSSPLAASSAAL",
    Address: "MOSSPLAAS FARM  UPINGTON  [BENEDE]",
    Latitude: "-27.6186",
    Longitude: "21.9733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20987",
    WardLookupId: "4702",
    VDNumber: "65500017",
    RegisteredPopulation: "65",
    VotingStationName: "FARM STRATHMORE",
    Address: "P O BOX 273  POSTMASBURG  POSTMASBURG",
    Latitude: "-28.3913",
    Longitude: "23.3576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20988",
    WardLookupId: "4702",
    VDNumber: "65500130",
    RegisteredPopulation: "41",
    VotingStationName: "RIETFONTEIN",
    Address: "POSTMAS  POSTMASBURG  POSTMASBURG",
    Latitude: "-28.6005",
    Longitude: "23.5542",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20989",
    WardLookupId: "4702",
    VDNumber: "65600074",
    RegisteredPopulation: "1665",
    VotingStationName: "BOICHOKO CLINIC",
    Address: "MOTLAETSHABA  BOICHOKO  POSTMASBURG",
    Latitude: "-28.3214",
    Longitude: "23.0468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20990",
    WardLookupId: "4702",
    VDNumber: "65600108",
    RegisteredPopulation: "545",
    VotingStationName: "POSTMASBURG CIVIC CENTRE",
    Address: "19 SPRINGBOK STREET  POSTMASBURG",
    Latitude: "-28.3334",
    Longitude: "23.0651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20991",
    WardLookupId: "4702",
    VDNumber: "65640034",
    RegisteredPopulation: "265",
    VotingStationName: "SKEYFONTEIN",
    Address: "SKEYFONTEIN  TSANTSABANE [POSTMASBURG]",
    Latitude: "-28.4899",
    Longitude: "23.1497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20992",
    WardLookupId: "4702",
    VDNumber: "65640078",
    RegisteredPopulation: "104",
    VotingStationName: "SOETFONTEIN",
    Address: "POSTMASBURG  ",
    Latitude: "-28.3733",
    Longitude: "23.0359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20993",
    WardLookupId: "4703",
    VDNumber: "65550034",
    RegisteredPopulation: "2140",
    VotingStationName: "SPORTSAAL TLHAKALATLOU",
    Address: "2 KHUDU STREET  TLHAKALATLOU  DANIELSKUIL",
    Latitude: "-28.1953",
    Longitude: "23.5574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20994",
    WardLookupId: "4704",
    VDNumber: "65550023",
    RegisteredPopulation: "1595",
    VotingStationName: "SMIRNA EVANGELIESE KERK",
    Address: "ROUX  KUILSVILLE  DANIELSKUIL",
    Latitude: "-28.1976",
    Longitude: "23.5326",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20995",
    WardLookupId: "4704",
    VDNumber: "65550045",
    RegisteredPopulation: "384",
    VotingStationName: "DANIELSKUIL TOURISM INFORMATION CENTRE",
    Address: "HOOFSTRAAT  DANIELSKUIL  DANIELSKUIL",
    Latitude: "-28.1814",
    Longitude: "23.5488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20996",
    WardLookupId: "4704",
    VDNumber: "65640089",
    RegisteredPopulation: "37",
    VotingStationName: "MOUNT CARMEL",
    Address: "MOUNT CARMEL STORE  FARM  KGATELOPELE [DANIELSKUIL]",
    Latitude: "-27.9854",
    Longitude: "23.6077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20997",
    WardLookupId: "4705",
    VDNumber: "65550012",
    RegisteredPopulation: "1063",
    VotingStationName: "LIBRARY DANIELSKUIL",
    Address: "BAKER  DANIELSKUIL  DANIELSKUIL",
    Latitude: "-28.1848",
    Longitude: "23.5499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20998",
    WardLookupId: "4705",
    VDNumber: "65550056",
    RegisteredPopulation: "1928",
    VotingStationName: "KUILSVILLE HIGH SCHOOL",
    Address: "SCANLAM  KUILSVILLE  DANIELSKUIL",
    Latitude: "-28.1891",
    Longitude: "23.5408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "20999",
    WardLookupId: "4706",
    VDNumber: "65670015",
    RegisteredPopulation: "1955",
    VotingStationName: "LIME ACRES PRIMARY SCHOOL",
    Address: "14 ADAM  LIME  ACRES  KGATELOPELE [DANIELSKUIL]",
    Latitude: "-28.3625",
    Longitude: "23.4747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21000",
    WardLookupId: "4706",
    VDNumber: "65670026",
    RegisteredPopulation: "437",
    VotingStationName: "SHALEJE HALL",
    Address: "RAE  LIME ACRES  KGATELOPELE [DANIELSKUIL]",
    Latitude: "-28.3582",
    Longitude: "23.5197",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21001",
    WardLookupId: "4707",
    VDNumber: "65000067",
    RegisteredPopulation: "2599",
    VotingStationName: "CHRISTEN GEMEENTES (ND SWARTZ)",
    Address: "45 SCHOOL STREET  ROSEDALE  UPINGTON",
    Latitude: "-28.4586",
    Longitude: "21.2025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21002",
    WardLookupId: "4707",
    VDNumber: "65000157",
    RegisteredPopulation: "817",
    VotingStationName: "FRANCISCUS PRIMARY SCHOOL",
    Address: "KEIMOESWEG  PROGRESS  KHARA HAIS [UPINGTON]",
    Latitude: "-28.4614",
    Longitude: "21.229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21003",
    WardLookupId: "4707",
    VDNumber: "65000203",
    RegisteredPopulation: "1416",
    VotingStationName: "WESTERKIM PRIMARY SCHOOL",
    Address: "NEUSBERG  ROSEDALE  KHARA HAIS [UPINGTON]",
    Latitude: "-28.4543",
    Longitude: "21.1998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21004",
    WardLookupId: "4708",
    VDNumber: "65000089",
    RegisteredPopulation: "2404",
    VotingStationName: "LAERSKOOL SIMBRUNER",
    Address: "00 FRANS VAN ROOI  MORNING GLORY  UPINGTON",
    Latitude: "-28.4623",
    Longitude: "21.208",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21005",
    WardLookupId: "4708",
    VDNumber: "65000236",
    RegisteredPopulation: "1089",
    VotingStationName: "LAERSKOOL KEIDEBEES",
    Address: "7 VOORUITSIG  RAINBOW  KHARA HAIS",
    Latitude: "-28.4598",
    Longitude: "21.2189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21006",
    WardLookupId: "4709",
    VDNumber: "65000090",
    RegisteredPopulation: "2288",
    VotingStationName: "TOLL SPEELMAN CIVIC HALL",
    Address: "KEIMOES ROAD  PROGRESS  UPINGTON",
    Latitude: "-28.465",
    Longitude: "21.219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21007",
    WardLookupId: "4709",
    VDNumber: "65000247",
    RegisteredPopulation: "863",
    VotingStationName: "AGS KERK",
    Address: "178 H/V VOORUITSIG & ROBYN  BELLVUE  UPINGTON",
    Latitude: "-28.4662",
    Longitude: "21.2078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21008",
    WardLookupId: "4710",
    VDNumber: "65000102",
    RegisteredPopulation: "2058",
    VotingStationName: "METHODISTE KERK",
    Address: "01 LEEUBEKKIE  PROGRESS  UPINGTON",
    Latitude: "-28.46",
    Longitude: "21.2224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21009",
    WardLookupId: "4710",
    VDNumber: "65000258",
    RegisteredPopulation: "940",
    VotingStationName: "EVANGELICAL LUTHERAN CHURCH",
    Address: "1 VYGIE  PROGRESS  UPINGTON",
    Latitude: "-28.4576",
    Longitude: "21.2297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21010",
    WardLookupId: "4711",
    VDNumber: "65000124",
    RegisteredPopulation: "1245",
    VotingStationName: "MOSES LINKS CIVIC HALL",
    Address: "ANEMONESINGEL  LOUISVALE  UPINGTON",
    Latitude: "-28.4778",
    Longitude: "21.272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21011",
    WardLookupId: "4711",
    VDNumber: "65000168",
    RegisteredPopulation: "2583",
    VotingStationName: "LOUISVALE INTERMEDIATE SCHOOL",
    Address: "144 ANEMONE  LOUISVALE ROAD  UPINGTON",
    Latitude: "-28.4844",
    Longitude: "21.2784",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21012",
    WardLookupId: "4711",
    VDNumber: "65000214",
    RegisteredPopulation: "514",
    VotingStationName: "MARANATHA GEMEENTE INTERNASIONAAL",
    Address: "WILLEM GREEF  LOUISVALE  UPINGTON",
    Latitude: "-28.4799",
    Longitude: "21.2683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21013",
    WardLookupId: "4712",
    VDNumber: "65000056",
    RegisteredPopulation: "1957",
    VotingStationName: "PABALLELO HIGH SCHOOL",
    Address: "PABALLELO  UPINGTON",
    Latitude: "-28.436",
    Longitude: "21.215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21014",
    WardLookupId: "4712",
    VDNumber: "65000191",
    RegisteredPopulation: "1305",
    VotingStationName: "LUKHANYISO PRIMARY SCHOOL",
    Address: "10348 MAYABA  PABALLELO  KHARA HAIS [UPINGTON]",
    Latitude: "-28.4393",
    Longitude: "21.2194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21015",
    WardLookupId: "4713",
    VDNumber: "65000045",
    RegisteredPopulation: "2316",
    VotingStationName: "J SHIMANE HALL",
    Address: "IMALI STREET  PABALLELO  UPINGTON",
    Latitude: "-28.4436",
    Longitude: "21.2227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21016",
    WardLookupId: "4713",
    VDNumber: "65000179",
    RegisteredPopulation: "691",
    VotingStationName: "UPINGTON BIBLIOTEEK",
    Address: "01 MARK STREET  UPINGTON  KHARA HAIS [UPINGTON]",
    Latitude: "-28.4566",
    Longitude: "21.2456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21017",
    WardLookupId: "4714",
    VDNumber: "65000023",
    RegisteredPopulation: "2277",
    VotingStationName: "UPINGTON MUNICIPAL SWIMMING POOL",
    Address: "H/V BORCHERD & CONRADIE  UPINGTON  KHARA HAIS",
    Latitude: "-28.4474",
    Longitude: "21.2481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21018",
    WardLookupId: "4714",
    VDNumber: "65000269",
    RegisteredPopulation: "2031",
    VotingStationName: "UPINGTON FIRE BRIGADE",
    Address: "DAKOTA ROAD  UPINGTON  UPINGTON",
    Latitude: "-28.4441",
    Longitude: "21.2399",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21019",
    WardLookupId: "4715",
    VDNumber: "65000012",
    RegisteredPopulation: "1912",
    VotingStationName: "DUINEVELD HIGH SCHOOL",
    Address: "RONDOMSTREET  OOSTERVILLE  UPINGTON",
    Latitude: "-28.4428",
    Longitude: "21.2626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21020",
    WardLookupId: "4715",
    VDNumber: "65090022",
    RegisteredPopulation: "167",
    VotingStationName: "PLAAS ZONDERHUIS",
    Address: "PLAAS ZONDERHUIS  UPINGTON",
    Latitude: "-28.5113",
    Longitude: "21.7611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21021",
    WardLookupId: "4715",
    VDNumber: "65090055",
    RegisteredPopulation: "801",
    VotingStationName: "MELKSTROOM",
    Address: "MELKSTROOM  UPINGTON",
    Latitude: "-28.419",
    Longitude: "21.3297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21022",
    WardLookupId: "4715",
    VDNumber: "65090291",
    RegisteredPopulation: "90",
    VotingStationName: "PLAAS UIZIP (TENT)",
    Address: "PLAAS UIZIP  UPINGTON  UPINGTON",
    Latitude: "-28.3819",
    Longitude: "21.4816",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21023",
    WardLookupId: "4715",
    VDNumber: "65090303",
    RegisteredPopulation: "1199",
    VotingStationName: "CASA CALIDA",
    Address: "102 GROENPUNT WEG  KEIDEBEES  UPINGTON",
    Latitude: "-28.4392",
    Longitude: "21.2919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21024",
    WardLookupId: "4716",
    VDNumber: "65000146",
    RegisteredPopulation: "2889",
    VotingStationName: "BABBELBEKKIE",
    Address: "ORANJEWEG  ROSEDALE  UPINGTON",
    Latitude: "-28.4534",
    Longitude: "21.2088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21025",
    WardLookupId: "4717",
    VDNumber: "65030048",
    RegisteredPopulation: "80",
    VotingStationName: "EENSAAMKASTEEL",
    Address: "ASKHAM  ",
    Latitude: "-27.0654",
    Longitude: "21.3253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21026",
    WardLookupId: "4717",
    VDNumber: "65030059",
    RegisteredPopulation: "129",
    VotingStationName: "MANIE CONRADIE KOSHUIS",
    Address: "NOENIEPUT  SIYANDA",
    Latitude: "-27.5107",
    Longitude: "20.1392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21027",
    WardLookupId: "4717",
    VDNumber: "65030060",
    RegisteredPopulation: "38",
    VotingStationName: "PLAAS HARRISDALE",
    Address: "PLAAS HARRISDALE  UPINGTON  SIYANDA",
    Latitude: "-28.0261",
    Longitude: "20.5556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21028",
    WardLookupId: "4717",
    VDNumber: "65030071",
    RegisteredPopulation: "72",
    VotingStationName: "KALAHARI - WES BOEREVERENIGINGSAAL",
    Address: "KALAHARI WES  UPINGTON  UPINGTON",
    Latitude: "-27.5195",
    Longitude: "20.8097",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21029",
    WardLookupId: "4717",
    VDNumber: "65030082",
    RegisteredPopulation: "45",
    VotingStationName: "ARIMAGHAM",
    Address: "ARIMAGHAM FARM  UPINGTON  BENEDE",
    Latitude: "-28.213",
    Longitude: "21.3196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21030",
    WardLookupId: "4717",
    VDNumber: "65030093",
    RegisteredPopulation: "129",
    VotingStationName: "SWARTKOPDAM COMMUNITY HALL",
    Address: "00 FREDERICK DU PLESSIS  SWARTKOPDAM  MIER",
    Latitude: "-27.6725",
    Longitude: "20.2114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21031",
    WardLookupId: "4717",
    VDNumber: "65030105",
    RegisteredPopulation: "38",
    VotingStationName: "PLAAS ALTEMIT",
    Address: "DWAALHOEK  UPINGTON  DWAALHOEK",
    Latitude: "-27.4153",
    Longitude: "21.2117",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21032",
    WardLookupId: "4717",
    VDNumber: "65030116",
    RegisteredPopulation: "923",
    VotingStationName: "ASKHAM COMMUNITY HALL",
    Address: "92 KAMEELDUIN  ASKHAM  MIER",
    Latitude: "-26.9817",
    Longitude: "20.7717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21033",
    WardLookupId: "4717",
    VDNumber: "65050028",
    RegisteredPopulation: "2099",
    VotingStationName: "KALKSLOOT GEMEENSKAPSAAL",
    Address: "KALKSLOOT  UPINGTON",
    Latitude: "-28.5511",
    Longitude: "21.151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21034",
    WardLookupId: "4718",
    VDNumber: "65050039",
    RegisteredPopulation: "1575",
    VotingStationName: "RAASWATER GEMEENSKAPSAAL PRE-SCHOOL",
    Address: "H/V LENSIE & SKORSIE  RAASWATER  UPINGTON",
    Latitude: "-28.5882",
    Longitude: "21.1907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21035",
    WardLookupId: "4718",
    VDNumber: "65050040",
    RegisteredPopulation: "1125",
    VotingStationName: "CONGREGATIONAL CHURCH LOUISVALE",
    Address: "LOUISVALE  UPINGTON",
    Latitude: "-28.5702",
    Longitude: "21.1968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21036",
    WardLookupId: "4718",
    VDNumber: "65090268",
    RegisteredPopulation: "1031",
    VotingStationName: "EVANGELIE KERK VAN ALLE NASIES",
    Address: "SWARTMORE  SWARTMORE-LESEDING  UPINGTON",
    Latitude: "-28.5134",
    Longitude: "21.2048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21037",
    WardLookupId: "4719",
    VDNumber: "65000135",
    RegisteredPopulation: "1469",
    VotingStationName: "ST PAULS METHODIST CHURCH",
    Address: "ALPHA  PABALLELO  UPINGTON",
    Latitude: "-28.4426",
    Longitude: "21.2168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21038",
    WardLookupId: "4719",
    VDNumber: "65000225",
    RegisteredPopulation: "2174",
    VotingStationName: "PABALLELO PRIMARY SCHOOL",
    Address: "66-82 VULINDLELA  PABALLELO  UPINGTON",
    Latitude: "-28.4365",
    Longitude: "21.2044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21039",
    WardLookupId: "4719",
    VDNumber: "65000281",
    RegisteredPopulation: "650",
    VotingStationName: "NORTHERN CAPE COUNTRY COLLEGE",
    Address: "STEVE NAUDE  FLORA PARK  UPINGTON",
    Latitude: "-28.4375",
    Longitude: "21.257",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21040",
    WardLookupId: "4719",
    VDNumber: "65090392",
    RegisteredPopulation: "123",
    VotingStationName: "KARAKOEL NAVORSINGSENTRUM",
    Address: "BORCHERD  UPINGTON  UPINGTON",
    Latitude: "-28.363",
    Longitude: "21.2516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21041",
    WardLookupId: "4720",
    VDNumber: "65000292",
    RegisteredPopulation: "211",
    VotingStationName: "WATERWESE SAAL",
    Address: "LOUISVALEROAD  UPINGTON",
    Latitude: "-28.4723",
    Longitude: "21.259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21042",
    WardLookupId: "4720",
    VDNumber: "65040016",
    RegisteredPopulation: "1126",
    VotingStationName: "LEERKRANS GEMEENSKAPSAAL",
    Address: "SONOP  LEERKRANS  UPINGTON",
    Latitude: "-28.4076",
    Longitude: "21.5241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21043",
    WardLookupId: "4720",
    VDNumber: "65040027",
    RegisteredPopulation: "957",
    VotingStationName: "KAROS GEMEENSKAPSAAL",
    Address: "HOOF STREET  UPINGTON  KHARA HAIS [UPINGTON]",
    Latitude: "-28.4226",
    Longitude: "21.6125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21044",
    WardLookupId: "4720",
    VDNumber: "65040038",
    RegisteredPopulation: "610",
    VotingStationName: "LAMBRECHTSDRIFT",
    Address: "HOOP STREET  UPINGTON  KHARA HAIS [UPINGTON]",
    Latitude: "-28.4928",
    Longitude: "21.685",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21045",
    WardLookupId: "4720",
    VDNumber: "65090066",
    RegisteredPopulation: "874",
    VotingStationName: "CHURCH OF CHRIST 30AD",
    Address: "STRAUSSBURG  UPINGTON",
    Latitude: "-28.4445",
    Longitude: "21.3279",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21046",
    WardLookupId: "4721",
    VDNumber: "65000180",
    RegisteredPopulation: "1150",
    VotingStationName: "ACTS MISSION CHURCH",
    Address: "SKOOL STREET  UPINGTON  KHARA HAIS",
    Latitude: "-28.4588",
    Longitude: "21.2051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21047",
    WardLookupId: "4721",
    VDNumber: "65000270",
    RegisteredPopulation: "2120",
    VotingStationName: "PIET THOLE COMMUNITY HALL",
    Address: "LEEUKOPSTREET  ROSEDALE  UPINGTON",
    Latitude: "-28.4545",
    Longitude: "21.2056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21048",
    WardLookupId: "4722",
    VDNumber: "64980018",
    RegisteredPopulation: "557",
    VotingStationName: "KLEIN MIER COMMUNITY HALL",
    Address: "00 BUITE STREET  KLEIN-MIER   MIER",
    Latitude: "-26.736",
    Longitude: "20.2796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21049",
    WardLookupId: "4722",
    VDNumber: "64980029",
    RegisteredPopulation: "410",
    VotingStationName: "WELKOM PRIMARY SCHOOL",
    Address: "MIER  ",
    Latitude: "-26.5532",
    Longitude: "20.6057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21050",
    WardLookupId: "4722",
    VDNumber: "64980030",
    RegisteredPopulation: "1567",
    VotingStationName: "RIETFONTEIN COMMUNITY HALL",
    Address: "53 DAAN SWANEPOEL  RIETFONTEIN  MIER",
    Latitude: "-26.7418",
    Longitude: "20.0276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21051",
    WardLookupId: "4722",
    VDNumber: "64980041",
    RegisteredPopulation: "549",
    VotingStationName: "LOUBOS COMMUNITY HALL",
    Address: "GORRELSTREET  LOUBOS  MIER",
    Latitude: "-26.7129",
    Longitude: "20.1155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21052",
    WardLookupId: "4722",
    VDNumber: "64980052",
    RegisteredPopulation: "726",
    VotingStationName: "PHILANDERSBRON COMMUNITY HALL",
    Address: "00 VAN DER WESTHUIZEN STREET  MIER  PHILANDERSBRON",
    Latitude: "-26.8145",
    Longitude: "20.0928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21053",
    WardLookupId: "4722",
    VDNumber: "65030015",
    RegisteredPopulation: "50",
    VotingStationName: "MATA MATA VS",
    Address: "MATA MATA  MIER",
    Latitude: "-25.7662",
    Longitude: "20.0021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21054",
    WardLookupId: "4722",
    VDNumber: "65030037",
    RegisteredPopulation: "100",
    VotingStationName: "INKBOSPAN PLAAS",
    Address: "PLAAS VAN W. KNOESEN  UPINGTON  INKBOSPAN",
    Latitude: "-27.0264",
    Longitude: "20.5355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21055",
    WardLookupId: "4722",
    VDNumber: "65030149",
    RegisteredPopulation: "35",
    VotingStationName: "NOSSOB RESERVE",
    Address: "NOSSOB  MIER  NOSSOB",
    Latitude: "-25.421",
    Longitude: "20.5973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21056",
    WardLookupId: "4722",
    VDNumber: "65030150",
    RegisteredPopulation: "333",
    VotingStationName: "ANDRIESVALE COMMUNITY HALL",
    Address: "ANDRIESVALE  MIER",
    Latitude: "-26.9378",
    Longitude: "20.6571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21057",
    WardLookupId: "4723",
    VDNumber: "65440122",
    RegisteredPopulation: "2637",
    VotingStationName: "LUCRETIA PRIMARY SCHOOL",
    Address: "CARRISA  LUCRETIA  KIMBERLEY",
    Latitude: "-28.6613",
    Longitude: "24.7153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21058",
    WardLookupId: "4723",
    VDNumber: "65440481",
    RegisteredPopulation: "1428",
    VotingStationName: "CNR CELOCIA AND STARLING STREET",
    Address: "CNR CELOCIA AND STARLING STREE  PESCODIA  KIMBERLEY",
    Latitude: "-28.6637",
    Longitude: "24.712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21059",
    WardLookupId: "4724",
    VDNumber: "65440267",
    RegisteredPopulation: "2351",
    VotingStationName: "PESCODIA SENIOR SECONDARY SCHOOL",
    Address: "53 ALBATROSSTREET  PESCODIA  KIMBERLEY",
    Latitude: "-28.6673",
    Longitude: "24.7248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21060",
    WardLookupId: "4724",
    VDNumber: "65440492",
    RegisteredPopulation: "2421",
    VotingStationName: "ROODEPAN PRIMARY SCHOOL",
    Address: "STARLING STREET  PESCODIA  SOL PLAATJE [KIMBERLEY]",
    Latitude: "-28.6659",
    Longitude: "24.7177",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21061",
    WardLookupId: "4725",
    VDNumber: "65440021",
    RegisteredPopulation: "2276",
    VotingStationName: "HOMELITE SQUARE",
    Address: "19TH STREET  HOMELITE  KIMBERLEY",
    Latitude: "-28.6978",
    Longitude: "24.7302",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21062",
    WardLookupId: "4725",
    VDNumber: "65440032",
    RegisteredPopulation: "1647",
    VotingStationName: "HOMEVALE PRIMARY SCHOOL",
    Address: "1ST STREET  HOMEVALE  KIMBERLEY",
    Latitude: "-28.6986",
    Longitude: "24.7402",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21063",
    WardLookupId: "4726",
    VDNumber: "65440098",
    RegisteredPopulation: "2444",
    VotingStationName: "MANKURWANE PRIMARY SCHOOL",
    Address: "557 SEHULARO STREET  MANKURWANE  KIMBERLEY",
    Latitude: "-28.7068",
    Longitude: "24.7293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21064",
    WardLookupId: "4726",
    VDNumber: "65440526",
    RegisteredPopulation: "768",
    VotingStationName: "WITDAM",
    Address: "WITDAM  KIMBERLEY",
    Latitude: "-28.7095",
    Longitude: "24.7345",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21065",
    WardLookupId: "4726",
    VDNumber: "65440650",
    RegisteredPopulation: "656",
    VotingStationName: "GALESHEWE COMMUNITY SERVICE CENTRE",
    Address: "22049 SEOCHWARENG ROAD  VERGENOEG  KIMBERLEY",
    Latitude: "-28.71",
    Longitude: "24.7422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21066",
    WardLookupId: "4727",
    VDNumber: "65440065",
    RegisteredPopulation: "2636",
    VotingStationName: "LETSHEGO PRIMARY SCHOOL",
    Address: "MOSHE KANTANE ST  REDIRILE  KIMBERLEY",
    Latitude: "-28.7004",
    Longitude: "24.7174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21067",
    WardLookupId: "4727",
    VDNumber: "65440504",
    RegisteredPopulation: "1421",
    VotingStationName: "PERCIVAL JAS COMMUNITY HALL",
    Address: "OTHNIEL GAETSEWE  REDIRILE  SOL PLAATJE [KIMBERLEY]",
    Latitude: "-28.7011",
    Longitude: "24.7148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21068",
    WardLookupId: "4728",
    VDNumber: "65440043",
    RegisteredPopulation: "1718",
    VotingStationName: "MASAKHANE CLINIC",
    Address: "THUTLO STREET  BOIKHUTSONG  KIMBERLEY",
    Latitude: "-28.7018",
    Longitude: "24.7332",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21069",
    WardLookupId: "4728",
    VDNumber: "65440515",
    RegisteredPopulation: "1693",
    VotingStationName: "FULL GOSPEL CHURCH",
    Address: "TSHWENE STREET  VERGENOEG EXTENTION 10  SOL PLAATJE [KIMBERLEY]",
    Latitude: "-28.7003",
    Longitude: "24.7258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21070",
    WardLookupId: "4729",
    VDNumber: "65440076",
    RegisteredPopulation: "2364",
    VotingStationName: "RC ELLIOT",
    Address: "SELEKA STREET  VERGENOEG  KIMBERLEY",
    Latitude: "-28.7087",
    Longitude: "24.7215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21071",
    WardLookupId: "4729",
    VDNumber: "65440087",
    RegisteredPopulation: "1535",
    VotingStationName: "MASIZA PRIMARY SCHOOL",
    Address: "431 MPIKWANE STREET  VERGENOEG  KIMBERLEY",
    Latitude: "-28.7033",
    Longitude: "24.7244",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21072",
    WardLookupId: "4730",
    VDNumber: "65440234",
    RegisteredPopulation: "3240",
    VotingStationName: "HOUSING SUPPORT CENTRE",
    Address: "MOSHOESHOE  GALESHEWE  KIMBERLEY",
    Latitude: "-28.726",
    Longitude: "24.7347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21073",
    WardLookupId: "4730",
    VDNumber: "65440245",
    RegisteredPopulation: "2089",
    VotingStationName: "KEVIN NKOANE PRIMARY SCHOOL",
    Address: "HOWIE ROAD  VERWOERD PARK  KIMBERLEY",
    Latitude: "-28.7372",
    Longitude: "24.7372",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21074",
    WardLookupId: "4731",
    VDNumber: "65440010",
    RegisteredPopulation: "1544",
    VotingStationName: "IKAGENG PRESBETERIAN CHURCH",
    Address: "1309 MAZABANE STREET  RETSWELELE  KIMBERLEY",
    Latitude: "-28.7154",
    Longitude: "24.7125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21075",
    WardLookupId: "4731",
    VDNumber: "65440111",
    RegisteredPopulation: "2683",
    VotingStationName: "EPLEKHELA SENIOR SECONDARY SCHOOL",
    Address: "MOSAMO ST  RETSWERELE  KIMBERLEY",
    Latitude: "-28.713",
    Longitude: "24.7088",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21076",
    WardLookupId: "4732",
    VDNumber: "65440144",
    RegisteredPopulation: "1780",
    VotingStationName: "TETLANYO SENIOR SECONDARY SCHOOL",
    Address: "01 DITAKE CRESCENT  VERGENOEG  KIMBERLEY",
    Latitude: "-28.7104",
    Longitude: "24.7254",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21077",
    WardLookupId: "4732",
    VDNumber: "65440537",
    RegisteredPopulation: "929",
    VotingStationName: "MOLEABANGWE PRIMARY SCHOOL",
    Address: "MAPUTLE  VERGENOEG EXTENSION 7  SOL PLAATJE [KIMBERLEY]",
    Latitude: "-28.7119",
    Longitude: "24.7224",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21078",
    WardLookupId: "4732",
    VDNumber: "65440672",
    RegisteredPopulation: "724",
    VotingStationName: "THABANE HIGH SCHOOL",
    Address: "NOBENGULA STREET  GALESHEWE  SOL PLAATJE",
    Latitude: "-28.7107",
    Longitude: "24.7206",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21079",
    WardLookupId: "4733",
    VDNumber: "65440166",
    RegisteredPopulation: "2135",
    VotingStationName: "ISAGO PRIMARY SCHOOL",
    Address: "74 STRANGER NKOSI STREET  VERGENOEG  KIMBERLEY",
    Latitude: "-28.7156",
    Longitude: "24.7243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21080",
    WardLookupId: "4733",
    VDNumber: "65440548",
    RegisteredPopulation: "1012",
    VotingStationName: "GASCA HALL",
    Address: "DINGAAN  VERGENOEG EXTENSION 7  SOL PLAATJE [KIMBERLEY]",
    Latitude: "-28.7162",
    Longitude: "24.7287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21081",
    WardLookupId: "4734",
    VDNumber: "65440100",
    RegisteredPopulation: "2009",
    VotingStationName: "GREENPOINT PRIMARY SCHOOL",
    Address: "36 J LIPHUKO STREET  KWANOBANTU  KIMBERLEY",
    Latitude: "-28.7109",
    Longitude: "24.7378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21082",
    WardLookupId: "4734",
    VDNumber: "65440559",
    RegisteredPopulation: "1397",
    VotingStationName: "SOL PLAATJE PRIMARY SCHOOL",
    Address: "PHILLIP MPIWA  VERGENOEG EXTENSION 2  SOL PLAATJE [KIMBERLEY]",
    Latitude: "-28.7145",
    Longitude: "24.732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21083",
    WardLookupId: "4735",
    VDNumber: "65440133",
    RegisteredPopulation: "2241",
    VotingStationName: "BOICHOKO PRIMARY SCHOOL",
    Address: "METHODIST ST  GALESHEWE  KIMBERLEY",
    Latitude: "-28.7186",
    Longitude: "24.7438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21084",
    WardLookupId: "4735",
    VDNumber: "65440278",
    RegisteredPopulation: "1465",
    VotingStationName: "RENEILWE PRIMARY SCHOOL",
    Address: "ETHEL ST  GALESHEWE  KIMBERLEY",
    Latitude: "-28.7197",
    Longitude: "24.7505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21085",
    WardLookupId: "4736",
    VDNumber: "65440289",
    RegisteredPopulation: "1141",
    VotingStationName: "PERSEVERANCE COLLEGE",
    Address: "156 BARKLY RD  HOMESTEAD  KIMBERLEY",
    Latitude: "-28.7106",
    Longitude: "24.7492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21086",
    WardLookupId: "4736",
    VDNumber: "65440302",
    RegisteredPopulation: "2333",
    VotingStationName: "STOCKROOS CIVIC CENTRE",
    Address: "STOCKROOS STR  FLOORS  KIMBERLEY",
    Latitude: "-28.7222",
    Longitude: "24.7586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21087",
    WardLookupId: "4737",
    VDNumber: "65440199",
    RegisteredPopulation: "2394",
    VotingStationName: "BOITUMELO SPECIAL SCHOOL",
    Address: "1913 JOHN DAKA ST  PHUTHANANG  KIMBERLEY",
    Latitude: "-28.7171",
    Longitude: "24.703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21088",
    WardLookupId: "4737",
    VDNumber: "65440683",
    RegisteredPopulation: "973",
    VotingStationName: "TSHWARELELA COMBINED SCHOOL",
    Address: "KWENA STREET  GALESHEWE  SOL PLAATJE",
    Latitude: "-28.7215",
    Longitude: "24.7127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21089",
    WardLookupId: "4738",
    VDNumber: "65440458",
    RegisteredPopulation: "3053",
    VotingStationName: "NEW PHUTANANG ADMINISTRATION BUILDING",
    Address: "PHUTANANG  KIMBERLEY  PHUTANANG",
    Latitude: "-28.7093",
    Longitude: "24.6943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21090",
    WardLookupId: "4738",
    VDNumber: "65440661",
    RegisteredPopulation: "1197",
    VotingStationName: "TSWELELANG OFFICE",
    Address: "PRESIDENT MANGOPE  RETSWELELE  SOL PLAATJE",
    Latitude: "-28.7099",
    Longitude: "24.7052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21091",
    WardLookupId: "4739",
    VDNumber: "65440201",
    RegisteredPopulation: "1055",
    VotingStationName: "ST CHARLES LWANGA CHURCH RC DIOCESE OF KIMBERLEY",
    Address: "MASISI STREET  IPELENG  KIMBERELY",
    Latitude: "-28.7249",
    Longitude: "24.7104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21092",
    WardLookupId: "4739",
    VDNumber: "65440223",
    RegisteredPopulation: "1519",
    VotingStationName: "HELEN JOSEPH WOMENS DEVELOMENT CENTRE",
    Address: "31 LATLHI  MABILO STREET  JOHN MAMPE  KIMBERLEY",
    Latitude: "-28.7211",
    Longitude: "24.7225",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21093",
    WardLookupId: "4739",
    VDNumber: "65440469",
    RegisteredPopulation: "1452",
    VotingStationName: "JESUS ARENA CHURCH",
    Address: "CNR JULIA AND HABAGAE  KUTLWANONG  KIMBERLEY",
    Latitude: "-28.7297",
    Longitude: "24.7157",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21094",
    WardLookupId: "4740",
    VDNumber: "65440188",
    RegisteredPopulation: "1367",
    VotingStationName: "RETLAMELENG SPECIAL NEEDS SCHOOL",
    Address: "JOHN DAKA  GALESHEWE  KIMBERLEY",
    Latitude: "-28.7284",
    Longitude: "24.7421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21095",
    WardLookupId: "4740",
    VDNumber: "65440560",
    RegisteredPopulation: "1491",
    VotingStationName: "ST PHILLIPS AFRICAN CHURCH",
    Address: "MOEKETSI STREET  GREATER NO.2  SOL PLAATJE [KIMBERLEY]",
    Latitude: "-28.7216",
    Longitude: "24.7471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21096",
    WardLookupId: "4741",
    VDNumber: "65440177",
    RegisteredPopulation: "2486",
    VotingStationName: "ZINGISA NO 1 SCHOOL",
    Address: "C/O MONTSHIWA ROAD AND MAFUNSA  GALESHEWE  KIMBERLEY",
    Latitude: "-28.7203",
    Longitude: "24.7338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21097",
    WardLookupId: "4741",
    VDNumber: "65440593",
    RegisteredPopulation: "1074",
    VotingStationName: "UNITING REFORMED CHURCH IN SOUTHERN AFRICA",
    Address: "MONTSHIWA ROAD  GALESHEWE  KIMBERLEY",
    Latitude: "-28.7223",
    Longitude: "24.7398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21098",
    WardLookupId: "4742",
    VDNumber: "65440324",
    RegisteredPopulation: "3621",
    VotingStationName: "NEWTON PRIMARY SCHOOL",
    Address: "HALKETT ROAD  NEW PARK  KIMBERLEY",
    Latitude: "-28.7465",
    Longitude: "24.7507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21099",
    WardLookupId: "4742",
    VDNumber: "65440740",
    RegisteredPopulation: "559",
    VotingStationName: "DIAMANDVELD PRIMARY SCHOOL",
    Address: "BEIT STREET  KIMBERLEY  KIMBERLEY NORTH",
    Latitude: "-28.7324",
    Longitude: "24.7661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21100",
    WardLookupId: "4743",
    VDNumber: "65440313",
    RegisteredPopulation: "2701",
    VotingStationName: "SKILPADSAAL (SHOW GROUNDS)",
    Address: "KENILWORTH ROAD  DE BEERS  KIMBERLEY",
    Latitude: "-28.7271",
    Longitude: "24.7759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21101",
    WardLookupId: "4743",
    VDNumber: "65440605",
    RegisteredPopulation: "2052",
    VotingStationName: "HERLEAR PRIMARY SCHOOL",
    Address: "OLYMPIA STREET  HERLEAR  KIMBERLEY",
    Latitude: "-28.7472",
    Longitude: "24.7863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21102",
    WardLookupId: "4744",
    VDNumber: "65440380",
    RegisteredPopulation: "3359",
    VotingStationName: "GREENPOINT HIGH SCHOOL",
    Address: "REDWOOD STREET  GREENPOINT  KIMBERLEY",
    Latitude: "-28.7791",
    Longitude: "24.7747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21103",
    WardLookupId: "4744",
    VDNumber: "65440391",
    RegisteredPopulation: "1171",
    VotingStationName: "NJ HEYNS HIGH SCHOOL",
    Address: "23 MACDOUGAL STR  MONUMENT HEIGHTS  KIMBERLEY",
    Latitude: "-28.7678",
    Longitude: "24.7593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21104",
    WardLookupId: "4745",
    VDNumber: "65440357",
    RegisteredPopulation: "1950",
    VotingStationName: "LAERSKOOL VOORUITSIG",
    Address: "WILLIAM GASSON STR  NEW PARK  KIMBERLEY",
    Latitude: "-28.7537",
    Longitude: "24.7616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21105",
    WardLookupId: "4745",
    VDNumber: "65440627",
    RegisteredPopulation: "2527",
    VotingStationName: "KIMBERLEY JUNIOR SCHOOL",
    Address: "MEMORIAL  MONUMENT HEIGHTS  SOL PLAATJE [KIMBERLEY]",
    Latitude: "-28.7545",
    Longitude: "24.767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21106",
    WardLookupId: "4746",
    VDNumber: "65440335",
    RegisteredPopulation: "2644",
    VotingStationName: "CLUB HOUSE HOMING SOCIETY",
    Address: "2 PETRUS STREET  HILLCREST  KIMBERLEY",
    Latitude: "-28.7543",
    Longitude: "24.7311",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21107",
    WardLookupId: "4746",
    VDNumber: "65440638",
    RegisteredPopulation: "1776",
    VotingStationName: "LAERSKOOL EUREKA",
    Address: "BARNATO STREET  HADISON PARK  KIMBERLEY",
    Latitude: "-28.7548",
    Longitude: "24.7432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21108",
    WardLookupId: "4747",
    VDNumber: "65440379",
    RegisteredPopulation: "3274",
    VotingStationName: "DU TOITSPAN PRIMARY SCHOOL",
    Address: "53A CENTRAL RD  BEACONSFIELD  KIMBERLEY",
    Latitude: "-28.7567",
    Longitude: "24.7815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21109",
    WardLookupId: "4747",
    VDNumber: "65440728",
    RegisteredPopulation: "1761",
    VotingStationName: "DIAMOND PARK",
    Address: "DIAMOND PARK  KIMBERLEY",
    Latitude: "-28.7696",
    Longitude: "24.7772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21110",
    WardLookupId: "4748",
    VDNumber: "65440706",
    RegisteredPopulation: "831",
    VotingStationName: "BATLELEUR GUEST HOUSE",
    Address: "4 VAN DER STEL STREET  KIMBERLEY  HILLCREST",
    Latitude: "-28.7556",
    Longitude: "24.7349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21111",
    WardLookupId: "4748",
    VDNumber: "65460012",
    RegisteredPopulation: "3423",
    VotingStationName: "MOTSWEDIMOSA",
    Address: "387 RITCHIESTRAAT  MOTSWEDIMOSA  RITCHIE",
    Latitude: "-29.0265",
    Longitude: "24.5873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21112",
    WardLookupId: "4749",
    VDNumber: "65460023",
    RegisteredPopulation: "3560",
    VotingStationName: "RIETRIVIER PRIMARY SCHOOL",
    Address: "219 MURCURY STREET  RIETVALE  RITCHIE",
    Latitude: "-29.0377",
    Longitude: "24.5881",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21113",
    WardLookupId: "4749",
    VDNumber: "65530144",
    RegisteredPopulation: "69",
    VotingStationName: "GROOTDAM",
    Address: "SCHMIDSDRIFT ROAD  FARM   KIMBERLEY",
    Latitude: "-28.7186",
    Longitude: "24.2293",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21114",
    WardLookupId: "4749",
    VDNumber: "65530199",
    RegisteredPopulation: "167",
    VotingStationName: "KLIPHEUWEL",
    Address: "KLIPHEUWEL  KIMBERLEY",
    Latitude: "-28.9419",
    Longitude: "24.2613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21115",
    WardLookupId: "4750",
    VDNumber: "65440290",
    RegisteredPopulation: "2910",
    VotingStationName: "COLVILLE COMMUNITY HALL",
    Address: "ALFRED STREET  COLVILLE  SOL PLAATJE",
    Latitude: "-28.7138",
    Longitude: "24.7564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21116",
    WardLookupId: "4750",
    VDNumber: "65440649",
    RegisteredPopulation: "1106",
    VotingStationName: "SALVATION ARMY CHURCH (BEACONSFIELD)",
    Address: "C/O CONNOLLY AND FREE STATE   BEACONSFIELD  KIMBERLEY",
    Latitude: "-28.7568",
    Longitude: "24.7873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21117",
    WardLookupId: "4750",
    VDNumber: "65440739",
    RegisteredPopulation: "420",
    VotingStationName: "YONDER RESOURCE CENTRE",
    Address: "CENTRAL AVENUE,KENILWORTH  SOL PLAATJE  KIMBERLEY",
    Latitude: "-28.7133",
    Longitude: "24.7793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21118",
    WardLookupId: "4751",
    VDNumber: "65440436",
    RegisteredPopulation: "3124",
    VotingStationName: "ELSHEDAI SOUP KITCHEN",
    Address: "ORCHID STREET  GREGORY PARK  KIMBERLEY",
    Latitude: "-28.6578",
    Longitude: "24.7124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21119",
    WardLookupId: "4751",
    VDNumber: "65440447",
    RegisteredPopulation: "445",
    VotingStationName: "WATERWORKS PRIMARY SCHOOL",
    Address: "RIVERTON  KIMBERLEY",
    Latitude: "-28.5199",
    Longitude: "24.6972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21120",
    WardLookupId: "4752",
    VDNumber: "65440425",
    RegisteredPopulation: "2634",
    VotingStationName: "CHURCH OF CHRIST LERATONG PARK",
    Address: "LERATONG PARK INFORMAL SETTLEM  LERATONG PARK  KIMBERLEY",
    Latitude: "-28.6841",
    Longitude: "24.7205",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21121",
    WardLookupId: "4752",
    VDNumber: "65440717",
    RegisteredPopulation: "1052",
    VotingStationName: "JUBILEE WORSHIP CENTRE(ROODEPAN)",
    Address: "KAMEELDORING AND CORAL STREET  JACKSONVILLE ROODEPAN  KIMBERLEY",
    Latitude: "-28.6738",
    Longitude: "24.725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21122",
    WardLookupId: "4753",
    VDNumber: "65440212",
    RegisteredPopulation: "2776",
    VotingStationName: "KUTLWANONG SQUARE HALL",
    Address: "JOESELAI STREET  KUTLWANONG  KIMBERLEY",
    Latitude: "-28.7296",
    Longitude: "24.7106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21123",
    WardLookupId: "4753",
    VDNumber: "65440470",
    RegisteredPopulation: "1626",
    VotingStationName: "SIPHOKAZI DAY CARE CENTRE",
    Address: "NOGENGULA EXT  SOUL CITY  KIMBERLEY",
    Latitude: "-28.7346",
    Longitude: "24.7141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21124",
    WardLookupId: "4754",
    VDNumber: "65440571",
    RegisteredPopulation: "2400",
    VotingStationName: "NEW PHUTANANG CLINIC",
    Address: "GERALD SEKOTO  PHUTANANG  SOL PLAATJE [KIMBERLEY]",
    Latitude: "-28.7166",
    Longitude: "24.6948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21125",
    WardLookupId: "4754",
    VDNumber: "65440582",
    RegisteredPopulation: "2177",
    VotingStationName: "IXHUKHWE COMBINED SCHOOL",
    Address: "PLATFONTEIN  SOL PLAATJE [KIMBERLEY]",
    Latitude: "-28.7078",
    Longitude: "24.6539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21126",
    WardLookupId: "4755",
    VDNumber: "65440694",
    RegisteredPopulation: "2561",
    VotingStationName: "ABANTU BATHO HALL",
    Address: "TYSON ROAD  GALESHEWE  KIMBERLEY",
    Latitude: "-28.7265",
    Longitude: "24.7469",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21127",
    WardLookupId: "4756",
    VDNumber: "65370012",
    RegisteredPopulation: "2728",
    VotingStationName: "BORESETSE SECONDARY SCHOOL",
    Address: "2310 SOYIZWAPHESTRAAT  MATALENG  BARKLEY WEST",
    Latitude: "-28.5324",
    Longitude: "24.5008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21128",
    WardLookupId: "4757",
    VDNumber: "65370023",
    RegisteredPopulation: "2671",
    VotingStationName: "BARKLY-WES SEKONDÃŠRE SKOOL",
    Address: "629 DAHLIA STREET  DE BEERSHOOGTE  BARKLY WEST",
    Latitude: "-28.5265",
    Longitude: "24.5099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21129",
    WardLookupId: "4757",
    VDNumber: "65370045",
    RegisteredPopulation: "966",
    VotingStationName: "EVANGELICAL UNITED CHURCH OF SA",
    Address: "CAMPBELL STREET  ROOIRAND  BARKLEY WEST",
    Latitude: "-28.5382",
    Longitude: "24.5087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21130",
    WardLookupId: "4757",
    VDNumber: "65370056",
    RegisteredPopulation: "521",
    VotingStationName: "ST MARYS CHURCH",
    Address: "1 KERK  BARKLY WEST  BARKLY WEST",
    Latitude: "-28.5361",
    Longitude: "24.5211",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21131",
    WardLookupId: "4757",
    VDNumber: "65530313",
    RegisteredPopulation: "54",
    VotingStationName: "HOLSDAM PLAAS",
    Address: "HOLSDAM  DIKGATLONG [BARKLEY WEST]",
    Latitude: "-28.507",
    Longitude: "24.552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21132",
    WardLookupId: "4757",
    VDNumber: "65530324",
    RegisteredPopulation: "60",
    VotingStationName: "WILDERKLAWER COMMUNITY HALL",
    Address: "WILDERKLAWER  DIKGATLONG [BARKLEY WEST]",
    Latitude: "-28.5853",
    Longitude: "24.6022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21133",
    WardLookupId: "4758",
    VDNumber: "65370034",
    RegisteredPopulation: "1606",
    VotingStationName: "METHODIST CHURCH IN SA",
    Address: "3338 CLINIC STREET  ROOIRAND  BARKLEY WEST",
    Latitude: "-28.5398",
    Longitude: "24.5054",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21134",
    WardLookupId: "4758",
    VDNumber: "65370067",
    RegisteredPopulation: "145",
    VotingStationName: "HOÃ‹RSKOOL VAALRIVIER",
    Address: "6 BARKLY  BARKLY WEST  DIKGATLONG",
    Latitude: "-28.5374",
    Longitude: "24.517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21135",
    WardLookupId: "4758",
    VDNumber: "65370078",
    RegisteredPopulation: "901",
    VotingStationName: "INDEPENDENT ASSEMBLY OF CHRIST",
    Address: "SPITSKOP WEG NEW STANDS  DE BEERSHOOGTE  BARKLY WEST",
    Latitude: "-28.5252",
    Longitude: "24.5015",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21136",
    WardLookupId: "4758",
    VDNumber: "65530166",
    RegisteredPopulation: "471",
    VotingStationName: "PNIELLANDGOED PRIMÃŠRE SKOOL",
    Address: "PNIEL  PNIELLANDGOED  BARKLY WEST",
    Latitude: "-28.5879",
    Longitude: "24.5156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21137",
    WardLookupId: "4758",
    VDNumber: "65530335",
    RegisteredPopulation: "153",
    VotingStationName: "ST JAMES LUTHERAN CHURCH",
    Address: "PNIEL ESTATE  DIKGATLONG [BARKLEY WEST]",
    Latitude: "-28.575",
    Longitude: "24.5649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21138",
    WardLookupId: "4759",
    VDNumber: "65480014",
    RegisteredPopulation: "1079",
    VotingStationName: "HEBRONPARK CIVIC CENTRE",
    Address: "270 SERING STREET  HEBRONPARK  WINDSORTON",
    Latitude: "-28.3281",
    Longitude: "24.6916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21139",
    WardLookupId: "4759",
    VDNumber: "65480025",
    RegisteredPopulation: "1802",
    VotingStationName: "REAKANTSWE INTERMEDIATE SCHOOL",
    Address: "252 MOTHIBI  KUTLWANO  WINDSORTON",
    Latitude: "-28.3365",
    Longitude: "24.6929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21140",
    WardLookupId: "4759",
    VDNumber: "65480036",
    RegisteredPopulation: "559",
    VotingStationName: "STILLWATER INTERMEDIATE SCHOOL",
    Address: "PHELINDABA  STILLWATER  BARKLY WEST",
    Latitude: "-28.4937",
    Longitude: "24.6892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21141",
    WardLookupId: "4760",
    VDNumber: "65390047",
    RegisteredPopulation: "757",
    VotingStationName: "DELPORTSHOOP PRIMÃŠRE SKOOL",
    Address: "336 GOUSBLOM STREET  PROTEAHOFF  DIKGATLONG [BARKLEY WEST]",
    Latitude: "-28.4172",
    Longitude: "24.3221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21142",
    WardLookupId: "4760",
    VDNumber: "65390081",
    RegisteredPopulation: "1401",
    VotingStationName: "LONGLANDS COMMUNITY CENTRE",
    Address: "1910 NEW SITE  LONGLANDS  BARKLEY WEST",
    Latitude: "-28.451",
    Longitude: "24.3656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21143",
    WardLookupId: "4760",
    VDNumber: "65530111",
    RegisteredPopulation: "311",
    VotingStationName: "BECHUANA METHODIST CHURCH IN SA",
    Address: "57 BLOCK 3 SMITHSMINE  WINDSORTON  BARKLY WEST",
    Latitude: "-28.2187",
    Longitude: "24.5035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21144",
    WardLookupId: "4760",
    VDNumber: "65530155",
    RegisteredPopulation: "381",
    VotingStationName: "ST. ANDREWS ANGLICAN CHURCH",
    Address: "HOLPAN  BARKLY WEST  DIKGATLONG [BARKLEY WEST]",
    Latitude: "-28.3594",
    Longitude: "24.6414",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21145",
    WardLookupId: "4760",
    VDNumber: "65530201",
    RegisteredPopulation: "494",
    VotingStationName: "GONG-GONG CLINIC",
    Address: "GONG-GONG CLININC  GONG-GONG  BARKLY-WEST",
    Latitude: "-28.4851",
    Longitude: "24.4109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21146",
    WardLookupId: "4761",
    VDNumber: "65390025",
    RegisteredPopulation: "643",
    VotingStationName: "HOÃ‹RSKOOL DELPORTSHOOP",
    Address: "35 HANEKOM  DELPORTSHOOP  DELPORTSHOOP",
    Latitude: "-28.4154",
    Longitude: "24.3014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21147",
    WardLookupId: "4761",
    VDNumber: "65390058",
    RegisteredPopulation: "932",
    VotingStationName: "DIAMANT BERG PENTECOSTAL ASSEMBLIES",
    Address: "1950 STEENKAMP STREET  ROOIKOPPIES  DELPORTSHOOP",
    Latitude: "-28.4262",
    Longitude: "24.3081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21148",
    WardLookupId: "4761",
    VDNumber: "65390069",
    RegisteredPopulation: "124",
    VotingStationName: "VAAL GAMAGARA COMMUNITY HALL",
    Address: "VAAL GAMAGARA  DIKGATLONG [BARKLEY WEST]",
    Latitude: "-28.4071",
    Longitude: "24.2661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21149",
    WardLookupId: "4761",
    VDNumber: "65510018",
    RegisteredPopulation: "487",
    VotingStationName: "ALPHA HALL",
    Address: "MAIN STREET  ULCO  ULCO",
    Latitude: "-28.3297",
    Longitude: "24.2105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21150",
    WardLookupId: "4761",
    VDNumber: "65530100",
    RegisteredPopulation: "332",
    VotingStationName: "KOOPMANSFONTEIN RESEARCH STATION",
    Address: "AGRICULTURAL RESEARCH STATION  KOOPMANSFONTEIN  KIMBERLEY",
    Latitude: "-28.2037",
    Longitude: "24.0686",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21151",
    WardLookupId: "4761",
    VDNumber: "65530234",
    RegisteredPopulation: "73",
    VotingStationName: "DANCARL",
    Address: "DANCARL   BARKLY WEST  DANCARL",
    Latitude: "-28.1025",
    Longitude: "24.4004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21152",
    WardLookupId: "4761",
    VDNumber: "65530302",
    RegisteredPopulation: "66",
    VotingStationName: "LEKHADUNG PRIMARY SCHOOL",
    Address: "EIERFONTEIN FARM REIVILO  EIERFONTEIN  DIAMONDFIELDS",
    Latitude: "-27.7564",
    Longitude: "24.2797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21153",
    WardLookupId: "4761",
    VDNumber: "65640012",
    RegisteredPopulation: "198",
    VotingStationName: "KGOTATSANO PRIMARY SCHOOL",
    Address: "BLIKFONTEIN FARM  DANIELSKUIL  KGATELOPELE [DANIELSKUIL]",
    Latitude: "-27.8299",
    Longitude: "23.9283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21154",
    WardLookupId: "4762",
    VDNumber: "65390014",
    RegisteredPopulation: "1469",
    VotingStationName: "AFRICAN CHURCH",
    Address: "574 PHELINDABA  TIDIMALO  DIKGATLONG [BARKLEY WEST]",
    Latitude: "-28.4278",
    Longitude: "24.316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21155",
    WardLookupId: "4762",
    VDNumber: "65390070",
    RegisteredPopulation: "1289",
    VotingStationName: "DIKGATLHONG HIGH SCHOOL",
    Address: "LOUW & ANGELIERS AVENUE  TIDIMALO  DIKGATLONG [BARKLEY WEST]",
    Latitude: "-28.4198",
    Longitude: "24.3164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21156",
    WardLookupId: "4763",
    VDNumber: "65470024",
    RegisteredPopulation: "2099",
    VotingStationName: "AFRICAN CATHOLIC CHURCH",
    Address: "1911 MODISE STREET  IKHUTSENG  WARRENTON",
    Latitude: "-28.1292",
    Longitude: "24.8563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21157",
    WardLookupId: "4763",
    VDNumber: "65470080",
    RegisteredPopulation: "999",
    VotingStationName: "ROLIHLAHLA PRIMARY SCHOOL",
    Address: "IKHUTSENG LOCATION  MAGARENG [WARRENTON]",
    Latitude: "-28.1304",
    Longitude: "24.851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21158",
    WardLookupId: "4764",
    VDNumber: "65470013",
    RegisteredPopulation: "1612",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "ROMAN STREET  IKHUTSENG LOCATION  WARRENTON",
    Latitude: "-28.1196",
    Longitude: "24.86",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21159",
    WardLookupId: "4764",
    VDNumber: "65470091",
    RegisteredPopulation: "437",
    VotingStationName: "TLHATLOGANG PRIMARY SCHOOL",
    Address: "KGADIETE STR  IKHUTSENG LOCATION  MAGARENG [WARRENTON]",
    Latitude: "-28.1265",
    Longitude: "24.857",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21160",
    WardLookupId: "4765",
    VDNumber: "65470068",
    RegisteredPopulation: "833",
    VotingStationName: "IKHUTSENG COMMUNITY HALL",
    Address: "NAMELANG STREET  IKHUTSENG LOC  WARRENTON",
    Latitude: "-28.1251",
    Longitude: "24.8517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21161",
    WardLookupId: "4765",
    VDNumber: "65470103",
    RegisteredPopulation: "1007",
    VotingStationName: "ETHIOPIAN CATHOLIC CHURCH",
    Address: "LESABE STREET  IKHUTSENG LOCATION  MAGARENG [WARRENTON]",
    Latitude: "-28.1284",
    Longitude: "24.8505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21162",
    WardLookupId: "4766",
    VDNumber: "65470035",
    RegisteredPopulation: "1059",
    VotingStationName: "RUGBY STADIUM HALL",
    Address: "STATION ROAD  WARRENTON  WARRENTON",
    Latitude: "-28.1143",
    Longitude: "24.8585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21163",
    WardLookupId: "4766",
    VDNumber: "65470057",
    RegisteredPopulation: "1101",
    VotingStationName: "WARRENVALE COMMUNITY HALL",
    Address: "THIRD STREET  WARRENVALE  WARRENTON",
    Latitude: "-28.1018",
    Longitude: "24.8703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21164",
    WardLookupId: "4766",
    VDNumber: "65470079",
    RegisteredPopulation: "858",
    VotingStationName: "THE SON JOHN REVELATION CHURCH",
    Address: "2930 ZONE D  IKHUTSENG  WARRENTON",
    Latitude: "-28.1253",
    Longitude: "24.844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21165",
    WardLookupId: "4767",
    VDNumber: "65470046",
    RegisteredPopulation: "942",
    VotingStationName: "LIBRARY HALL",
    Address: "10 MARGARET PRINSLOO  WARRENTON",
    Latitude: "-28.1106",
    Longitude: "24.8492",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21166",
    WardLookupId: "4767",
    VDNumber: "65520019",
    RegisteredPopulation: "160",
    VotingStationName: "NAZARETH HOUSE",
    Address: "NAZARETH FARM  FOURTEEN STREAMS  MAGARENG",
    Latitude: "-28.1094",
    Longitude: "24.9113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21167",
    WardLookupId: "4767",
    VDNumber: "65520020",
    RegisteredPopulation: "307",
    VotingStationName: "TSHWARAGANO DAYCARE CENTRE",
    Address: "MAJENG  MAJENG VILLAGE  WARRENTON",
    Latitude: "-28.1355",
    Longitude: "24.6134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21168",
    WardLookupId: "4767",
    VDNumber: "65520109",
    RegisteredPopulation: "111",
    VotingStationName: "REALEBOGA PRIMARY SCHOOL",
    Address: "HARTSVALLEI  MAGARENG [WARRENTON]",
    Latitude: "-28.0243",
    Longitude: "24.6978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21169",
    WardLookupId: "4767",
    VDNumber: "65520110",
    RegisteredPopulation: "165",
    VotingStationName: "AGRICULTURAL HALL BULL HILL",
    Address: "BULL HILL  MAGARENG [WARRENTON]",
    Latitude: "-28.0518",
    Longitude: "24.6474",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21170",
    WardLookupId: "4767",
    VDNumber: "65520121",
    RegisteredPopulation: "98",
    VotingStationName: "SYDNEYS HOPE HALL",
    Address: "SYDNEYS HOPE  MAGARENG [WARRENTON]",
    Latitude: "-28.1093",
    Longitude: "24.8046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21171",
    WardLookupId: "4767",
    VDNumber: "65530188",
    RegisteredPopulation: "91",
    VotingStationName: "WINDSORTON RAND MOTEL",
    Address: "WINSORTON ROAD  WINDSORTON  WINSORTON",
    Latitude: "-28.3458",
    Longitude: "24.813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21172",
    WardLookupId: "4768",
    VDNumber: "86790011",
    RegisteredPopulation: "1599",
    VotingStationName: "LUTHERAN CHURCH",
    Address: "2099 PHAPHAMANG  PAMPIERSTAD  PAMPIERSTAD",
    Latitude: "-27.7874",
    Longitude: "24.6766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21173",
    WardLookupId: "4768",
    VDNumber: "86790066",
    RegisteredPopulation: "1421",
    VotingStationName: "SABATTH MINISTRIES",
    Address: "SAKHILE  PAMPIERSTAD  PAMPIERSTAD",
    Latitude: "-27.7924",
    Longitude: "24.6787",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21174",
    WardLookupId: "4768",
    VDNumber: "86790099",
    RegisteredPopulation: "665",
    VotingStationName: "MOUNTAINVIEW SPORTS GROUND",
    Address: "MOUNTAINVIEW SPORTS GROUND  PAMPIERSTAD  PAMPIERSTAD",
    Latitude: "-27.7928",
    Longitude: "24.6668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21175",
    WardLookupId: "4769",
    VDNumber: "86790022",
    RegisteredPopulation: "1864",
    VotingStationName: "PAMPIERSTAD HIGH SCHOOL",
    Address: "530B EXTENSION 3  PAMPIERSTAD  PAMPIERSTAD",
    Latitude: "-27.7897",
    Longitude: "24.6918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21176",
    WardLookupId: "4769",
    VDNumber: "86790055",
    RegisteredPopulation: "1405",
    VotingStationName: "MOOKI LOBELO PRIMARY",
    Address: "1706 MATANG STREET  PAMPIERSTAD  PAMPIERSTAD",
    Latitude: "-27.774",
    Longitude: "24.6852",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21177",
    WardLookupId: "4770",
    VDNumber: "65520075",
    RegisteredPopulation: "232",
    VotingStationName: "7H14 FARM",
    Address: "7H14 HARTSWATER FARM  HARTSWATER  HARTSWATER",
    Latitude: "-27.7666",
    Longitude: "24.7284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21178",
    WardLookupId: "4770",
    VDNumber: "86790033",
    RegisteredPopulation: "1854",
    VotingStationName: "BONTLENG PRIMARY SCHOOL",
    Address: "1716 BOJOSI STREET  PAMPIERSTAD  PAMPIERSTAD",
    Latitude: "-27.778",
    Longitude: "24.6956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21179",
    WardLookupId: "4770",
    VDNumber: "86790077",
    RegisteredPopulation: "636",
    VotingStationName: "PAMPIERSTAD SHOPPING COMPLEX",
    Address: "LM MANGOPE AVENUE  PAMPIERSTAD  PAMPIERSTAD",
    Latitude: "-27.7782",
    Longitude: "24.7002",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21180",
    WardLookupId: "4771",
    VDNumber: "65520086",
    RegisteredPopulation: "372",
    VotingStationName: "MOTSWEDITHUTO PRIMARY SCHOOL",
    Address: "1168 PLT  HARTSWATER  HARTSWATER",
    Latitude: "-27.7216",
    Longitude: "24.7795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21181",
    WardLookupId: "4771",
    VDNumber: "65520097",
    RegisteredPopulation: "413",
    VotingStationName: "VOORSPOED LAERSKOOL",
    Address: "HOOFSTRAAT  MAGOGONG  MAGOGONG",
    Latitude: "-27.6779",
    Longitude: "24.7689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21182",
    WardLookupId: "4771",
    VDNumber: "65520143",
    RegisteredPopulation: "155",
    VotingStationName: "BANKSDRIFT PRIMARY SCHOOL",
    Address: "5K14 FARM  MAGOGONG  PHOKWANE [HARTSWATER]",
    Latitude: "-27.7089",
    Longitude: "24.7137",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21183",
    WardLookupId: "4771",
    VDNumber: "86790044",
    RegisteredPopulation: "1747",
    VotingStationName: "KGOMOTSO HIGH SCHOOL",
    Address: "2901 MOSIGEDI STREET  PAMPIERSTAD  PAMPIERSTAD",
    Latitude: "-27.7693",
    Longitude: "24.6891",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21184",
    WardLookupId: "4771",
    VDNumber: "86790088",
    RegisteredPopulation: "259",
    VotingStationName: "APOTLES NATIONAL BRETHREN CHURCH",
    Address: "TLAPENG  PAMPIERSTAD  TLAPENG",
    Latitude: "-27.7409",
    Longitude: "24.6879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21185",
    WardLookupId: "4772",
    VDNumber: "65420029",
    RegisteredPopulation: "1893",
    VotingStationName: "BONITA PARK COMMUNITY HALL",
    Address: "5001 PALM WEG  BONITA PARK  HARTWATER",
    Latitude: "-27.7391",
    Longitude: "24.8085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21186",
    WardLookupId: "4772",
    VDNumber: "65420041",
    RegisteredPopulation: "1961",
    VotingStationName: "BANKDRIFT SECONDARY SCHOOL",
    Address: "THAGADIEPELAJANG  HARTWATER",
    Latitude: "-27.7468",
    Longitude: "24.7994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21187",
    WardLookupId: "4773",
    VDNumber: "65420018",
    RegisteredPopulation: "2110",
    VotingStationName: "HARTSWATER LAERSKOOL",
    Address: "172 JAN VILJOEN STREET  HARTSWATER  HARTSWATER",
    Latitude: "-27.7515",
    Longitude: "24.8028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21188",
    WardLookupId: "4773",
    VDNumber: "65420030",
    RegisteredPopulation: "449",
    VotingStationName: "KINDERCARE PRE-SCHOOL",
    Address: "CORNER CONROY & SCHOEMAN   HARTSWATER  HARTSWATER",
    Latitude: "-27.7572",
    Longitude: "24.8044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21189",
    WardLookupId: "4773",
    VDNumber: "65520132",
    RegisteredPopulation: "129",
    VotingStationName: "PLOT 2GX2 -TADCASTER",
    Address: "2GX2 FARM  TADCASTER / HARTSWATER  PHOKWANE [HARTSWATER]",
    Latitude: "-27.7857",
    Longitude: "24.8319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21190",
    WardLookupId: "4774",
    VDNumber: "65430042",
    RegisteredPopulation: "1773",
    VotingStationName: "JAN-KEMP PRIMARY SCHOOL",
    Address: "12 RAMAKATSA STREET  VALSPAN  JAN KEMPDORP",
    Latitude: "-27.9131",
    Longitude: "24.8144",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21191",
    WardLookupId: "4775",
    VDNumber: "65430020",
    RegisteredPopulation: "1574",
    VotingStationName: "TLHWAHALANG SECONDARY SCHOOL",
    Address: "486 R. NKOMO STR  VALSPAN  JAN KEMPDORP",
    Latitude: "-27.9187",
    Longitude: "24.8187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21192",
    WardLookupId: "4775",
    VDNumber: "65430053",
    RegisteredPopulation: "2090",
    VotingStationName: "HOLY APOSTOLIC CHURCH IN ZION",
    Address: "PHOKWANE  ",
    Latitude: "-27.9214",
    Longitude: "24.8076",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21193",
    WardLookupId: "4775",
    VDNumber: "65520042",
    RegisteredPopulation: "704",
    VotingStationName: "ANDALUSIA PRIMARY SCHOOL",
    Address: "KERK STREET  JAN KEMPDORP  JAN KEMPDORP",
    Latitude: "-27.9141",
    Longitude: "24.8481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21194",
    WardLookupId: "4775",
    VDNumber: "65520165",
    RegisteredPopulation: "970",
    VotingStationName: "NEW STANCE STOREROOM",
    Address: "4578K NEW STANCE  VALSPAN   JAN KEMPDORP",
    Latitude: "-27.9175",
    Longitude: "24.8",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21195",
    WardLookupId: "4776",
    VDNumber: "65430031",
    RegisteredPopulation: "2300",
    VotingStationName: "VAALHARTS SECONDARY SCHOOL",
    Address: "6 JAKARANDASTR  ANDALUSIA PARK  JAN KEMPDORP",
    Latitude: "-27.9097",
    Longitude: "24.8111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21196",
    WardLookupId: "4776",
    VDNumber: "65520053",
    RegisteredPopulation: "233",
    VotingStationName: "SENWES TADCASTER HALL",
    Address: "NG KERK STRAAT D ROW  TADCASTER  JAN KEMPDORP",
    Latitude: "-27.8494",
    Longitude: "24.811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21197",
    WardLookupId: "4776",
    VDNumber: "65520064",
    RegisteredPopulation: "350",
    VotingStationName: "TADCASTER PRIMARY SCHOOL",
    Address: "E 6 SPRINGBOKNEK  FARM  HARTSWATER",
    Latitude: "-27.8176",
    Longitude: "24.8009",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21198",
    WardLookupId: "4776",
    VDNumber: "65520176",
    RegisteredPopulation: "43",
    VotingStationName: "ROW 4 G PLOTS",
    Address: "G PLOTS  HARTSWATER  HARTSWATER",
    Latitude: "-27.7865",
    Longitude: "24.7493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21199",
    WardLookupId: "4777",
    VDNumber: "65430019",
    RegisteredPopulation: "742",
    VotingStationName: "RE YA RUTA DAY CARE CENTRE",
    Address: "STEYN VAN NIEKERK  HARTSWATER  JAN KEMPDORP",
    Latitude: "-27.9186",
    Longitude: "24.8322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21200",
    WardLookupId: "4777",
    VDNumber: "65520031",
    RegisteredPopulation: "2091",
    VotingStationName: "ST STEVEN`S ROMAN CATHOLIC CHURCH",
    Address: "D BLOCK  GANSPAN  GANSPAN",
    Latitude: "-27.9664",
    Longitude: "24.7473",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21201",
    WardLookupId: "4777",
    VDNumber: "65520154",
    RegisteredPopulation: "128",
    VotingStationName: "TAUDIARORA PRIMARY",
    Address: "PROEFPLAAS  JAN KEMPDORP  PROEFPLAAS",
    Latitude: "-27.9451",
    Longitude: "24.8325",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21202",
    WardLookupId: "4778",
    VDNumber: "86740892",
    RegisteredPopulation: "322",
    VotingStationName: "BAREKI PRIMARY SCHOOL",
    Address: "KOME  MOSHAWENG [KGALAGADI]",
    Latitude: "-26.4366",
    Longitude: "23.0969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21203",
    WardLookupId: "4778",
    VDNumber: "86741118",
    RegisteredPopulation: "672",
    VotingStationName: "SHALANENG PRIMARY SCHOOL",
    Address: "SHALANENG  SHALANENG VILLAGE  MOSHAWENG [KGALAGADI]",
    Latitude: "-26.3419",
    Longitude: "23.1265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21204",
    WardLookupId: "4778",
    VDNumber: "86743019",
    RegisteredPopulation: "878",
    VotingStationName: "PERTH PRIMARY SCHOOL",
    Address: "PERTH  PERTH VILLAGE  MOSHAWENG [KGALAGADI]",
    Latitude: "-26.4616",
    Longitude: "23.0798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21205",
    WardLookupId: "4778",
    VDNumber: "86743020",
    RegisteredPopulation: "1455",
    VotingStationName: "BAGALOTLHARE HIGH SCHOOL",
    Address: "TSOE  TSOE VILLAGE  MOSHAWENG [KGALAGADI]",
    Latitude: "-26.2942",
    Longitude: "23.1723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21206",
    WardLookupId: "4779",
    VDNumber: "86741871",
    RegisteredPopulation: "398",
    VotingStationName: "GAMOKATEDI PRIMARY SCHOOL",
    Address: "GAMOKATEDI  MOTHIBISTAD",
    Latitude: "-26.7453",
    Longitude: "23.2794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21207",
    WardLookupId: "4779",
    VDNumber: "86741882",
    RegisteredPopulation: "1218",
    VotingStationName: "MOSHAWENG HIGH SCHOOL",
    Address: "LOOPENG  LOOPENG VILLAGE  KURUMAN",
    Latitude: "-26.7922",
    Longitude: "23.36",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21208",
    WardLookupId: "4779",
    VDNumber: "86742029",
    RegisteredPopulation: "427",
    VotingStationName: "MADUO PRIMARY SCHOOL",
    Address: "GANAP VILLAGE  KURUMAN",
    Latitude: "-26.7068",
    Longitude: "23.2112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21209",
    WardLookupId: "4779",
    VDNumber: "86742647",
    RegisteredPopulation: "354",
    VotingStationName: "GAPAAPOANE PRIMARY SCHOOL",
    Address: "CAHAR   CAHAR VILLAGE  MOSHAWENG [KGALAGADI]",
    Latitude: "-26.8135",
    Longitude: "23.4602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21210",
    WardLookupId: "4779",
    VDNumber: "86742669",
    RegisteredPopulation: "504",
    VotingStationName: "MAREMANE PRIMARY SCHOOL",
    Address: "PADSTOW   PADSTOW VILLAGE   MOSHAWENG [KGALAGADI]",
    Latitude: "-26.8255",
    Longitude: "23.2021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21211",
    WardLookupId: "4779",
    VDNumber: "86743671",
    RegisteredPopulation: "528",
    VotingStationName: "SESHENG PRIMARY SCHOOL",
    Address: "LOOPENG  BAGA-TOTO",
    Latitude: "-26.7951",
    Longitude: "23.3491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21212",
    WardLookupId: "4780",
    VDNumber: "86740881",
    RegisteredPopulation: "1072",
    VotingStationName: "MADIBENG MIDDLE SCHOOL",
    Address: "MADIBENG  MADIBENG VILLAGE  BOPHIRIMA",
    Latitude: "-26.5785",
    Longitude: "22.8593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21213",
    WardLookupId: "4780",
    VDNumber: "86741129",
    RegisteredPopulation: "844",
    VotingStationName: "KHUIS PRIMARY SCHOOL",
    Address: "KHUIS  KHUIS VILLAGE  MOSHAWENG [KGALAGADI]",
    Latitude: "-26.8783",
    Longitude: "23.0672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21214",
    WardLookupId: "4780",
    VDNumber: "86741860",
    RegisteredPopulation: "861",
    VotingStationName: "SEGOPOTSO MIDDLE SCHOOL",
    Address: "LAXEY  LAXEY VILLAGE  KURUMAN",
    Latitude: "-26.7254",
    Longitude: "23.1617",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21215",
    WardLookupId: "4780",
    VDNumber: "86742535",
    RegisteredPopulation: "204",
    VotingStationName: "DUTTON PRIMARY SCHOOL",
    Address: "KLEIN EIFFEL  BOPHIRIMA",
    Latitude: "-26.5331",
    Longitude: "22.9842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21216",
    WardLookupId: "4780",
    VDNumber: "86743031",
    RegisteredPopulation: "500",
    VotingStationName: "EIFFEL PRIMARY SCHOOL",
    Address: "EIFFEL    EIFFEL VILLAGE   MOSHAWENG [KGALAGADI]",
    Latitude: "-26.5655",
    Longitude: "22.9077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21217",
    WardLookupId: "4781",
    VDNumber: "65610019",
    RegisteredPopulation: "1037",
    VotingStationName: "VANZYLSRUS MUNISIPALE KANTOOR",
    Address: "31 KORTSTRAAT  VAN ZYLSRUST  VAN ZYLSRUST",
    Latitude: "-26.8781",
    Longitude: "22.0525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21218",
    WardLookupId: "4781",
    VDNumber: "65650013",
    RegisteredPopulation: "653",
    VotingStationName: "HOTAZEL KLUBSAAL",
    Address: "KUPFERBURGER SINGEL  HOTAZEL  HOTAZEL",
    Latitude: "-27.1998",
    Longitude: "22.956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21219",
    WardLookupId: "4781",
    VDNumber: "65660070",
    RegisteredPopulation: "181",
    VotingStationName: "SONSTRAAL",
    Address: "PLAAS SONSTRAAL  VANZYLSRUS",
    Latitude: "-27.1219",
    Longitude: "22.4643",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21220",
    WardLookupId: "4781",
    VDNumber: "65680016",
    RegisteredPopulation: "71",
    VotingStationName: "SUTTON FARM",
    Address: "SUTTON   KURUMAN   SUTTON",
    Latitude: "-27.4352",
    Longitude: "22.8214",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21221",
    WardLookupId: "4781",
    VDNumber: "65690028",
    RegisteredPopulation: "74",
    VotingStationName: "CONCORDIA",
    Address: "CONCORDIA   ",
    Latitude: "-26.5935",
    Longitude: "22.6732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21222",
    WardLookupId: "4781",
    VDNumber: "65690039",
    RegisteredPopulation: "102",
    VotingStationName: "KHUIS ROAD",
    Address: "KHUIS ROAD  JOHN TAOLO GAETSEWE  KURUMAN",
    Latitude: "-26.73",
    Longitude: "22.3135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21223",
    WardLookupId: "4781",
    VDNumber: "65690040",
    RegisteredPopulation: "58",
    VotingStationName: "LETHATING BOEREVEREENIGINGSAAL",
    Address: "DOORINGDRAAI  [KALAHARI [CBDC]",
    Latitude: "-26.9211",
    Longitude: "22.7274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21224",
    WardLookupId: "4781",
    VDNumber: "65690051",
    RegisteredPopulation: "60",
    VotingStationName: "TURKEY FARM HOUSE",
    Address: "MC CATHYS REST  KURUMAN  KALAHARI CBDC",
    Latitude: "-26.2647",
    Longitude: "22.5949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21225",
    WardLookupId: "4781",
    VDNumber: "65690062",
    RegisteredPopulation: "39",
    VotingStationName: "DE DWAAL",
    Address: "VAN ZYLSRUS  KGALAGADI",
    Latitude: "-26.4946",
    Longitude: "22.2872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21226",
    WardLookupId: "4781",
    VDNumber: "65690073",
    RegisteredPopulation: "20",
    VotingStationName: "WESTWARD-HO 2 VAN ZYL`S RUS",
    Address: "KURUMAN   ",
    Latitude: "-26.7462",
    Longitude: "22.5292",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21227",
    WardLookupId: "4781",
    VDNumber: "65700019",
    RegisteredPopulation: "636",
    VotingStationName: "SANTOY REC CLUB",
    Address: "BLACK ROCK   KURUMAN",
    Latitude: "-27.1214",
    Longitude: "22.8417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21228",
    WardLookupId: "4781",
    VDNumber: "86741657",
    RegisteredPopulation: "549",
    VotingStationName: "TSWLELOPELE EDUCARE CENTRE",
    Address: "MAGOJANENG WEST   BAGA-TOTO",
    Latitude: "-27.1914",
    Longitude: "23.0545",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21229",
    WardLookupId: "4781",
    VDNumber: "86743569",
    RegisteredPopulation: "332",
    VotingStationName: "GASEAGELWE PRIMARY SCHOOL",
    Address: "MAGOBING  MOTHIBISTAD  KURUMAN",
    Latitude: "-27.2133",
    Longitude: "23.0493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21230",
    WardLookupId: "4782",
    VDNumber: "86740016",
    RegisteredPopulation: "920",
    VotingStationName: "LEHIKENG PRIMARY SCHOOL",
    Address: "GASESE  GASESE VILLAGE  KURUMAN",
    Latitude: "-27.1169",
    Longitude: "23.0423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21231",
    WardLookupId: "4782",
    VDNumber: "86741152",
    RegisteredPopulation: "1390",
    VotingStationName: "THUSEGO PRIMARY SCHOOL",
    Address: "TSINENG  TSINENG VILLAGE  KURUMAN",
    Latitude: "-27.0955",
    Longitude: "23.089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21232",
    WardLookupId: "4782",
    VDNumber: "86741589",
    RegisteredPopulation: "1260",
    VotingStationName: "MAIPENG PRIMARY SCHOOL",
    Address: "MAIPEING  MAIPEING VILLAGE  KURUMAN",
    Latitude: "-27.1347",
    Longitude: "23.1221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21233",
    WardLookupId: "4783",
    VDNumber: "86741174",
    RegisteredPopulation: "417",
    VotingStationName: "RUSFONTEIN PRIMARY SCHOOL",
    Address: "RUSFONTEIN  MOSHAWENG [KGALAGADI]  MOSHAWENG [KGALAGADI]",
    Latitude: "-26.9965",
    Longitude: "23.2511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21234",
    WardLookupId: "4783",
    VDNumber: "86741196",
    RegisteredPopulation: "716",
    VotingStationName: "MAPHINIKI PRIMARY SCHOOL",
    Address: "MAPHINIKI   MAPHINIKI VILLAGE  KURUMAN",
    Latitude: "-27.0485",
    Longitude: "23.2463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21235",
    WardLookupId: "4783",
    VDNumber: "86741477",
    RegisteredPopulation: "589",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "HERTZOG VILLAGE  MOSHAWENG  KGALAGADI",
    Latitude: "-27.1414",
    Longitude: "23.2799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21236",
    WardLookupId: "4783",
    VDNumber: "86742658",
    RegisteredPopulation: "290",
    VotingStationName: "BATSHWENENG MIDDLE SCHOOL",
    Address: "METSIMANTSI  METSIMANTSI WYK 3  MOSHAWENG [KGALAGADI]",
    Latitude: "-26.9448",
    Longitude: "23.1602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21237",
    WardLookupId: "4783",
    VDNumber: "86742670",
    RegisteredPopulation: "601",
    VotingStationName: "GALORE PRIMARY SCHOOL",
    Address: "GALOTLHARE  GALOTLHARE VILLLAGE  MOSHAWENG [KGALAGADI]",
    Latitude: "-27.1316",
    Longitude: "23.3766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21238",
    WardLookupId: "4783",
    VDNumber: "86742692",
    RegisteredPopulation: "624",
    VotingStationName: "GADIBOE PRIMARY SCHOOL",
    Address: "GADIBOE  GADIBOE VILLAGE  MOSHAWENG [KGALAGADI]",
    Latitude: "-27.0751",
    Longitude: "23.2956",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21239",
    WardLookupId: "4784",
    VDNumber: "86741051",
    RegisteredPopulation: "999",
    VotingStationName: "LOGOBATE PRIMARY SCHOOL",
    Address: "LOGOBATE  MOSHAWENG [KGALAGADI]  MOSHAWENG [KGALAGADI]",
    Latitude: "-27.1718",
    Longitude: "23.4136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21240",
    WardLookupId: "4784",
    VDNumber: "86741073",
    RegisteredPopulation: "279",
    VotingStationName: "MARATADITSE PRIMARY SCHOOL",
    Address: "KLEINIERA VILLAGE   MOSHAWENG [KGALAGADI]  MOSHAWENG [KGALAGADI]",
    Latitude: "-27.2929",
    Longitude: "23.3863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21241",
    WardLookupId: "4784",
    VDNumber: "86741130",
    RegisteredPopulation: "781",
    VotingStationName: "TONGWANE MIDDLE SCHOOL",
    Address: "CHURCHILL  KURUMAN",
    Latitude: "-27.2777",
    Longitude: "23.481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21242",
    WardLookupId: "4784",
    VDNumber: "86741220",
    RegisteredPopulation: "439",
    VotingStationName: "BAGASEHUNELO PRIMARY SCHOOL",
    Address: "GASEHUNELO WYK 5  KURUMAN",
    Latitude: "-27.092",
    Longitude: "23.5511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21243",
    WardLookupId: "4784",
    VDNumber: "86741523",
    RegisteredPopulation: "348",
    VotingStationName: "BOGOSIENG LEKWA PRIMARY SCHOOL",
    Address: "MOSHAWENG [KGALAGADI]  ",
    Latitude: "-27.1247",
    Longitude: "23.6153",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21244",
    WardLookupId: "4784",
    VDNumber: "86741949",
    RegisteredPopulation: "480",
    VotingStationName: "CARDINGTON PRIMARY SCHOOL",
    Address: "CARDINGTON   CARDINGTON VILLAGE   MOSHAWENG [KGALAGADI]",
    Latitude: "-27.2245",
    Longitude: "23.5123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21245",
    WardLookupId: "4784",
    VDNumber: "86742793",
    RegisteredPopulation: "161",
    VotingStationName: "MENTU PRIMARY SCHOOL",
    Address: "MENTU  MENTU VILLAGE  MOSHAWENG [KGALAGADI]",
    Latitude: "-27.2363",
    Longitude: "23.3498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21246",
    WardLookupId: "4784",
    VDNumber: "86742805",
    RegisteredPopulation: "212",
    VotingStationName: "RATANANG PRIMARY SCHOOL",
    Address: "GASEHUNELO WYK 7  MOSHAWENG [KGALAGADI]",
    Latitude: "-27.1705",
    Longitude: "23.5628",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21247",
    WardLookupId: "4784",
    VDNumber: "86742827",
    RegisteredPopulation: "85",
    VotingStationName: "EVANGELICAL LUTHERAN CHURCH SA",
    Address: "MOSHAWENG [KGALAGADI]  ",
    Latitude: "-27.1463",
    Longitude: "23.5281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21248",
    WardLookupId: "4785",
    VDNumber: "86740588",
    RegisteredPopulation: "524",
    VotingStationName: "BATTLEMOUNT PRIMARY SCHOOL",
    Address: "BATTLEMOUNT   BATTLEMOUNT VILLAGE   MOSHAWENG [KGALAGADI]",
    Latitude: "-26.8962",
    Longitude: "23.7168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21249",
    WardLookupId: "4785",
    VDNumber: "86741185",
    RegisteredPopulation: "549",
    VotingStationName: "GAMORONA TRIBAL OFFICE",
    Address: "GAMORONA  GAMORONA VILLAGE  KURUMAN",
    Latitude: "-26.9134",
    Longitude: "23.743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21250",
    WardLookupId: "4785",
    VDNumber: "86741400",
    RegisteredPopulation: "1038",
    VotingStationName: "GATLHOSE PRIMARY SCHOOL",
    Address: "BENDEL  BENDEL VILLAGE  KURUMAN",
    Latitude: "-26.9938",
    Longitude: "23.6452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21251",
    WardLookupId: "4785",
    VDNumber: "86741411",
    RegisteredPopulation: "546",
    VotingStationName: "GAMOCHWAEDE PRIMARY SCHOOL",
    Address: "DEURHAM   DEURHAM VILLAGE   BAGA-TOTO",
    Latitude: "-26.8943",
    Longitude: "23.5093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21252",
    WardLookupId: "4785",
    VDNumber: "86742681",
    RegisteredPopulation: "337",
    VotingStationName: "LONG HERST PRIMARY SCHOOL",
    Address: "LONG HERST  LONG HERST VILLAGE   MOSHAWENG [KGALAGADI]",
    Latitude: "-26.8491",
    Longitude: "23.5755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21253",
    WardLookupId: "4786",
    VDNumber: "86740522",
    RegisteredPopulation: "1023",
    VotingStationName: "SENGAE PRIMARY SCHOOL",
    Address: "BOTHITHONG  BOTHITHONG VILLAGE  MOTHIBITSAD",
    Latitude: "-27.0718",
    Longitude: "23.8415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21254",
    WardLookupId: "4786",
    VDNumber: "86740533",
    RegisteredPopulation: "1142",
    VotingStationName: "DITSHIPENG PRIMARY SCHOOL",
    Address: "DITSHIPENG  MOTHIBISTAD",
    Latitude: "-26.963",
    Longitude: "23.8127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21255",
    WardLookupId: "4786",
    VDNumber: "86742052",
    RegisteredPopulation: "860",
    VotingStationName: "GAA-ESI PRIMARY SCHOOL",
    Address: "BOTHITONG VILLAGE  KURUMAN",
    Latitude: "-27.0508",
    Longitude: "23.828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21256",
    WardLookupId: "4786",
    VDNumber: "86742816",
    RegisteredPopulation: "305",
    VotingStationName: "MATSHANENG PRIMARY SCHOOL",
    Address: "DANOON  DANOON VILLAGE  MOSHAWENG [KGALAGADI]",
    Latitude: "-27.0696",
    Longitude: "23.7573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21257",
    WardLookupId: "4786",
    VDNumber: "86743682",
    RegisteredPopulation: "79",
    VotingStationName: "ST BONIFACE CATHOLIC CHURCH",
    Address: "KIANG KOP   KURUMAN",
    Latitude: "-27.0183",
    Longitude: "23.7457",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21258",
    WardLookupId: "4786",
    VDNumber: "86743862",
    RegisteredPopulation: "242",
    VotingStationName: "H SAANE PRIMARY SCHOOL",
    Address: "GAMAKGATLE VILLAGE   GAMAKGATLE   KURUMAN",
    Latitude: "-27.0452",
    Longitude: "23.8826",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21259",
    WardLookupId: "4787",
    VDNumber: "86740511",
    RegisteredPopulation: "1882",
    VotingStationName: "GLENRED PRIMARY SCHOOL",
    Address: "GLENRED  GLENRED VILLAGE  KURUMAN",
    Latitude: "-26.9718",
    Longitude: "23.9087",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21260",
    WardLookupId: "4787",
    VDNumber: "86742041",
    RegisteredPopulation: "1187",
    VotingStationName: "THLAGANYANE PRIMARY SCHOOL",
    Address: "KGANWANE  KURUMAN",
    Latitude: "-26.9173",
    Longitude: "23.9368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21261",
    WardLookupId: "4787",
    VDNumber: "86743693",
    RegisteredPopulation: "192",
    VotingStationName: "BONANG EARLY LEARNING CENTRE",
    Address: "MASEOHATSHE   KURUMAN",
    Latitude: "-26.8845",
    Longitude: "23.9175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21262",
    WardLookupId: "4788",
    VDNumber: "86740498",
    RegisteredPopulation: "655",
    VotingStationName: "PULELO PRIMARY SCHOOL",
    Address: "CASSEL  CASSEL VILLAGE  KURUMAN",
    Latitude: "-26.9644",
    Longitude: "23.9895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21263",
    WardLookupId: "4788",
    VDNumber: "86740500",
    RegisteredPopulation: "1457",
    VotingStationName: "KOPANO MIDDLE SCHOOL",
    Address: "CASSEL  CASSEL VILLAGE  CASSEL",
    Latitude: "-26.9642",
    Longitude: "23.9806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21264",
    WardLookupId: "4788",
    VDNumber: "86740690",
    RegisteredPopulation: "916",
    VotingStationName: "LOTLHAKAJANENG PRIMARY SCHOOL",
    Address:
      "LOTLHAKAJANENG  LOTLHAKAJANENG VILLAGE,  KURUMAN  MOSHAWENG  KGALAGADI",
    Latitude: "-27.0211",
    Longitude: "24.0161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21265",
    WardLookupId: "4789",
    VDNumber: "86740544",
    RegisteredPopulation: "943",
    VotingStationName: "LERUMO PRIMARY SCHOOL",
    Address: "DITHAKONG  DITHAKONG VILLAGE  KURUMAN",
    Latitude: "-27.0764",
    Longitude: "23.928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21266",
    WardLookupId: "4789",
    VDNumber: "86740555",
    RegisteredPopulation: "1533",
    VotingStationName: "DITHAKONG TRIBAL OFFICE",
    Address: "DITHAKONG  DITHAKONG VILLAGE  KURUMAN",
    Latitude: "-27.0857",
    Longitude: "23.9182",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21267",
    WardLookupId: "4789",
    VDNumber: "86742704",
    RegisteredPopulation: "1032",
    VotingStationName: "OMANG PRIMARY SCHOOL",
    Address: "DITHAKONG  DTHAKONG VILLAGE  MOSHAWENG [KGALAGADI]",
    Latitude: "-27.0933",
    Longitude: "23.9227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21268",
    WardLookupId: "4790",
    VDNumber: "86740566",
    RegisteredPopulation: "1068",
    VotingStationName: "PIETERSHAM PRIMARY SCHOOL",
    Address: "PIETERSHAM  PIETERSHAM VILLAGE  KURUMAN",
    Latitude: "-27.1875",
    Longitude: "23.8747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21269",
    WardLookupId: "4790",
    VDNumber: "86741343",
    RegisteredPopulation: "1247",
    VotingStationName: "KAMDEN TRIBAL OFFICE",
    Address: "KAMDEN  KAMDEN VILLAGE  KURUMAN",
    Latitude: "-27.3608",
    Longitude: "23.8929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21270",
    WardLookupId: "4790",
    VDNumber: "86741398",
    RegisteredPopulation: "882",
    VotingStationName: "COLSTON PRIMARY SCHOOL",
    Address: "COLSTON  COLSTON VILLAGE  KURUMAN",
    Latitude: "-27.2618",
    Longitude: "23.8429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21271",
    WardLookupId: "4790",
    VDNumber: "86741444",
    RegisteredPopulation: "323",
    VotingStationName: "DIKHING PRIMARY SCHOOL",
    Address: "DIKHING   DIKHING VILLAGE   MOSHAWENG [KGALAGADI]",
    Latitude: "-27.3044",
    Longitude: "23.7777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21272",
    WardLookupId: "4790",
    VDNumber: "86742737",
    RegisteredPopulation: "195",
    VotingStationName: "ROMAN CATHOLIC CHURCH KHANKHUDUNG",
    Address: "KHANKHUDUNG VILLAGE  MOSHAWENG  MOSHAWENG",
    Latitude: "-27.3429",
    Longitude: "23.8561",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21273",
    WardLookupId: "4791",
    VDNumber: "86741354",
    RegisteredPopulation: "431",
    VotingStationName: "METSWETSANENG PRIMARY SCHOOL",
    Address: "MECWETSANENG  MECWETSANENG VILLAGE  MOSHAWENG [KGALAGADI]",
    Latitude: "-27.3071",
    Longitude: "23.6768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21274",
    WardLookupId: "4791",
    VDNumber: "86741387",
    RegisteredPopulation: "699",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "BOTHETHELETSA  BOTHETHELETSA VILLAGE  KURUMAN",
    Latitude: "-27.4116",
    Longitude: "23.8081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21275",
    WardLookupId: "4791",
    VDNumber: "86742030",
    RegisteredPopulation: "475",
    VotingStationName: "BOGARE PRIMARY SCHOOL",
    Address: "LOGAGANENG VILLAGE  KURUMAN",
    Latitude: "-27.3411",
    Longitude: "23.583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21276",
    WardLookupId: "4791",
    VDNumber: "86742715",
    RegisteredPopulation: "310",
    VotingStationName: "GOGANANG PRIMARY SCHOOL",
    Address: "KGANUNG  KGANUNG VILLAGE  MOSHAWENG [KGALAGADI]",
    Latitude: "-27.2242",
    Longitude: "23.6466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21277",
    WardLookupId: "4791",
    VDNumber: "86742726",
    RegisteredPopulation: "396",
    VotingStationName: "TSELA NTSHU PRIMARY SCHOOL",
    Address: "TZANEEN   TZANEEN VILLAGE   MOSHAWENG [KGALAGADI]",
    Latitude: "-27.3509",
    Longitude: "23.7872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21278",
    WardLookupId: "4791",
    VDNumber: "86742748",
    RegisteredPopulation: "156",
    VotingStationName: "BADUANA PRIMARY SCHOOL",
    Address: "MAKETLE  MAKETLE VILLAGE  MOSHAWENG [KGALAGADI]",
    Latitude: "-27.3435",
    Longitude: "23.666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21279",
    WardLookupId: "4791",
    VDNumber: "86742759",
    RegisteredPopulation: "411",
    VotingStationName: "BOITSHIRELETSO PRIMARY SCHOOL",
    Address: "WASHINGTON  WASHINGTON VILLAGE  MOSHAWENG [KGALAGADI]",
    Latitude: "-27.3704",
    Longitude: "23.7141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21280",
    WardLookupId: "4791",
    VDNumber: "86742760",
    RegisteredPopulation: "329",
    VotingStationName: "KIKAHELA METHODIST CHURCH",
    Address: "KIKAHELA VILLAGE  MOSHAWENG [KGALAGADI]",
    Latitude: "-27.4412",
    Longitude: "23.8755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21281",
    WardLookupId: "4791",
    VDNumber: "86742782",
    RegisteredPopulation: "412",
    VotingStationName: "NEW SMOUSWANE PRIMARY SCHOOL",
    Address: "ELLENDALE   ELLENDALE VILLAGE  MOSHAWENG [KGALAGADI]",
    Latitude: "-27.2733",
    Longitude: "23.6056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21282",
    WardLookupId: "4792",
    VDNumber: "86741310",
    RegisteredPopulation: "1317",
    VotingStationName: "GASEBONWE MIDDLE SCHOOL",
    Address: "NCWELENGWE  MOTHIBISTAD",
    Latitude: "-27.4543",
    Longitude: "23.6277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21283",
    WardLookupId: "4792",
    VDNumber: "86741321",
    RegisteredPopulation: "920",
    VotingStationName: "BAGA JANTJIE TRIBAL AUTHORITY",
    Address: "MANYEDING  MANYEDING VILLAGE  KURUMAN",
    Latitude: "-27.4952",
    Longitude: "23.6854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21284",
    WardLookupId: "4792",
    VDNumber: "86741332",
    RegisteredPopulation: "507",
    VotingStationName: "OBONTSE PRIMARY SCHOOL",
    Address: "GAMOTHIBI  GAMOTHIBI VILLAGE  MOSHAWENG [KGALAGADI]",
    Latitude: "-27.4381",
    Longitude: "23.6952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21285",
    WardLookupId: "4792",
    VDNumber: "86742771",
    RegisteredPopulation: "578",
    VotingStationName: "GAMASEGO PRIMARY SCHOOL",
    Address: "GAMASEPA  GAMASEPA VILLAGE  MOSHAWENG [KGALAGADI]",
    Latitude: "-27.5328",
    Longitude: "23.7339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21286",
    WardLookupId: "4792",
    VDNumber: "86743705",
    RegisteredPopulation: "178",
    VotingStationName: "MAHUKUBUNG PRIMARY SCHOOL",
    Address: "MAHUKUBUNG   BAGA-JANTIE",
    Latitude: "-27.4874",
    Longitude: "23.7512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21287",
    WardLookupId: "4793",
    VDNumber: "65580015",
    RegisteredPopulation: "2260",
    VotingStationName: "KURUMAN CITY HALL",
    Address: "H/V VOORTREKKER AND SCHOOL STREET  KURUMAN  KURUMAN",
    Latitude: "-27.4606",
    Longitude: "23.4328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21288",
    WardLookupId: "4793",
    VDNumber: "65580048",
    RegisteredPopulation: "1240",
    VotingStationName: "KURUMAN MOFFAT MISSION",
    Address: "TSINENG ROAD  KURUMAN  KURUMAN",
    Latitude: "-27.4213",
    Longitude: "23.4276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21289",
    WardLookupId: "4794",
    VDNumber: "65620010",
    RegisteredPopulation: "1994",
    VotingStationName: "BANKHARA/BODULONG MUNISIPALE KANTOOR",
    Address: "BANKHARA  BANKHARA/BODULONG  BANKHARA",
    Latitude: "-27.4227",
    Longitude: "23.3802",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21290",
    WardLookupId: "4794",
    VDNumber: "65620021",
    RegisteredPopulation: "2038",
    VotingStationName: "BODULONG PRE SCHOOL",
    Address: "BODULONG  BANKHARA/BODULONG  KURUMAN",
    Latitude: "-27.4182",
    Longitude: "23.3783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21291",
    WardLookupId: "4795",
    VDNumber: "86742063",
    RegisteredPopulation: "1133",
    VotingStationName: "NG KERK OF SA",
    Address: "175 JELE  MOTHIBISTAD  MOTHIBISTAD",
    Latitude: "-27.4028",
    Longitude: "23.4885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21292",
    WardLookupId: "4795",
    VDNumber: "86742883",
    RegisteredPopulation: "1537",
    VotingStationName: "BATLHARO TLHAPING HIGH SCHOOL",
    Address: "MM TSHETLHO  MOTHIBISTAD  GA-SEGONYANA [KURUMAN]",
    Latitude: "-27.3947",
    Longitude: "23.4907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21293",
    WardLookupId: "4795",
    VDNumber: "86743547",
    RegisteredPopulation: "1904",
    VotingStationName: "NAPWA SUPPORT  CENTRE",
    Address: "MAGOJANENG  MOTHIBISTAD  KURUMAN",
    Latitude: "-27.3918",
    Longitude: "23.4593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21294",
    WardLookupId: "4796",
    VDNumber: "86741297",
    RegisteredPopulation: "1920",
    VotingStationName: "MAPOTENG PRIMARY SCHOOL",
    Address: "MAPOTENG  MAPOTENG VILLAGE  KURUMAN",
    Latitude: "-27.3858",
    Longitude: "23.5138",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21295",
    WardLookupId: "4796",
    VDNumber: "86741376",
    RegisteredPopulation: "831",
    VotingStationName: "METHODIST CHURCH OF SA",
    Address: "626 MATLAPENG STREET  MOTHIBISTAD  KURUMAN",
    Latitude: "-27.399",
    Longitude: "23.4912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21296",
    WardLookupId: "4796",
    VDNumber: "86743570",
    RegisteredPopulation: "516",
    VotingStationName: "FULL GOSPEL CHURCH OF GOD",
    Address: "173 GAOBEPE STREET  MOTHIBISTAD  KURUMAN",
    Latitude: "-27.3943",
    Longitude: "23.4942",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21297",
    WardLookupId: "4796",
    VDNumber: "86743716",
    RegisteredPopulation: "541",
    VotingStationName: "RELEKILE PRIMARY SCHOOL",
    Address: "DITSHOSWANENG  MOTHIBISTAD  KURUMAN",
    Latitude: "-27.3698",
    Longitude: "23.5538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21298",
    WardLookupId: "4797",
    VDNumber: "86741972",
    RegisteredPopulation: "3242",
    VotingStationName: "MAIKAELELO PRIMARY SCHOOL",
    Address: "MAGOJANENG  MAGOJANENG VILLAGE  KUDUMANE",
    Latitude: "-27.3819",
    Longitude: "23.4618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21299",
    WardLookupId: "4798",
    VDNumber: "86741365",
    RegisteredPopulation: "1770",
    VotingStationName: "REKGARATLHILE HIGH SCHOOL",
    Address: "SEODING  SEODING VILLAGE  KURUMAN",
    Latitude: "-27.3971",
    Longitude: "23.4408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21300",
    WardLookupId: "4798",
    VDNumber: "86742636",
    RegisteredPopulation: "1417",
    VotingStationName: "TSHIMOLOGO MIDDLE SCHOOL",
    Address: "MOTHIBISTAD  KURUMAN",
    Latitude: "-27.3891",
    Longitude: "23.4364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21301",
    WardLookupId: "4798",
    VDNumber: "86743727",
    RegisteredPopulation: "179",
    VotingStationName: "ROMAN CATHOLIC CHURCH",
    Address: "MOKALAMOSESANE  MOTHIBISTAD  KURUMAN",
    Latitude: "-27.3658",
    Longitude: "23.4148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21302",
    WardLookupId: "4799",
    VDNumber: "86741040",
    RegisteredPopulation: "1166",
    VotingStationName: "SEDIBENG PRIMARY SCHOOL",
    Address: "SEDIBENG  KURUMAN",
    Latitude: "-27.1908",
    Longitude: "23.176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21303",
    WardLookupId: "4799",
    VDNumber: "86741062",
    RegisteredPopulation: "1319",
    VotingStationName: "GAMOPEDI PRIMARY SCHOOL",
    Address: "GAMOPEDI  GAMOPEDI VILLAGE  KURUMAN",
    Latitude: "-27.2281",
    Longitude: "23.2162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21304",
    WardLookupId: "4799",
    VDNumber: "86743738",
    RegisteredPopulation: "436",
    VotingStationName: "INEELENG PRIMARY SCHOOL",
    Address: "LOKALENG  MOTHIBISTAD  KURUMAN",
    Latitude: "-27.2115",
    Longitude: "23.1463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21305",
    WardLookupId: "4799",
    VDNumber: "86743749",
    RegisteredPopulation: "503",
    VotingStationName: "KOPANO PRE-SCHOOL",
    Address: "NCWENG  MOTHIBISTAD  KURUMAN",
    Latitude: "-27.2434",
    Longitude: "23.2565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21306",
    WardLookupId: "4800",
    VDNumber: "86741006",
    RegisteredPopulation: "1581",
    VotingStationName: "MAKGOLOKWE MIDDLE SCHOOL",
    Address: "BATLHAROS  KURUMAN",
    Latitude: "-27.3051",
    Longitude: "23.348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21307",
    WardLookupId: "4800",
    VDNumber: "86741028",
    RegisteredPopulation: "1467",
    VotingStationName: "KP TOTO MIDDLE SCHOOL",
    Address: "BATLHAROS MAIN ROAD  BATLHAROS  KURUAMN",
    Latitude: "-27.2931",
    Longitude: "23.3243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21308",
    WardLookupId: "4800",
    VDNumber: "86743750",
    RegisteredPopulation: "222",
    VotingStationName: "MMITSATSHIPI PRIMARY SCHOOL",
    Address: "GARUELE  KURUMAN  BATLHAROS",
    Latitude: "-27.2466",
    Longitude: "23.3113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21309",
    WardLookupId: "4800",
    VDNumber: "86743761",
    RegisteredPopulation: "274",
    VotingStationName: "REITEMOGETSE PRIMARY SCHOOL",
    Address: "GASEHUBANE  KURUMAN  BATLHAROS",
    Latitude: "-27.279",
    Longitude: "23.2735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21310",
    WardLookupId: "4800",
    VDNumber: "86743772",
    RegisteredPopulation: "93",
    VotingStationName: "REKAKGONA PRIMARY SCHOOL",
    Address: "GASEBOLAO  KURUMAN  BATLHAROS",
    Latitude: "-27.3169",
    Longitude: "23.2477",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21311",
    WardLookupId: "4801",
    VDNumber: "86740971",
    RegisteredPopulation: "1800",
    VotingStationName: "GAMOHANA MIDDLE SCHOOL",
    Address: "MARUPING  KURUMAN",
    Latitude: "-27.3478",
    Longitude: "23.3675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21312",
    WardLookupId: "4801",
    VDNumber: "86743783",
    RegisteredPopulation: "932",
    VotingStationName: "GAEGAKE PRIMARY SCHOOL",
    Address: "MARUPING  MOTHIBISTAD  KURUMAN",
    Latitude: "-27.3642",
    Longitude: "23.3806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21313",
    WardLookupId: "4802",
    VDNumber: "86740993",
    RegisteredPopulation: "1039",
    VotingStationName: "MORALADI PRIMARY SCHOOL",
    Address: "BATLHAROS MAIN ROAD  BATLHAROS  KURUMAN",
    Latitude: "-27.3305",
    Longitude: "23.3574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21314",
    WardLookupId: "4802",
    VDNumber: "86741017",
    RegisteredPopulation: "1585",
    VotingStationName: "ROBANYANE TOTO II PRIMARY SCHOOL",
    Address: "BATLHAROS  KURUMAN",
    Latitude: "-27.3117",
    Longitude: "23.3416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21315",
    WardLookupId: "4802",
    VDNumber: "86741039",
    RegisteredPopulation: "1095",
    VotingStationName: "REARATA PRIMARY SCHOOL",
    Address: "VERGENOEG  KURUMAN",
    Latitude: "-27.3634",
    Longitude: "23.2913",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21316",
    WardLookupId: "4803",
    VDNumber: "65680049",
    RegisteredPopulation: "115",
    VotingStationName: "HAAKBOS",
    Address: "HAAKBOS FARM  KURUMAN  GA-SEGONYANA [KURUMAN]",
    Latitude: "-27.6622",
    Longitude: "23.3028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21317",
    WardLookupId: "4803",
    VDNumber: "65680050",
    RegisteredPopulation: "145",
    VotingStationName: "SEKGWA SA PENTSHE",
    Address: "KONO FARM,DANIELSKUIL ROAD  KURUMAN  KURUMAN",
    Latitude: "-27.6877",
    Longitude: "23.5591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21318",
    WardLookupId: "4803",
    VDNumber: "65680061",
    RegisteredPopulation: "84",
    VotingStationName: "GROOTFONTEIN SKOOL",
    Address: "PLAAS GROOTFONTEIN  REIVILO  KURUMAN",
    Latitude: "-27.65",
    Longitude: "23.9748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21319",
    WardLookupId: "4803",
    VDNumber: "65680094",
    RegisteredPopulation: "139",
    VotingStationName: "KHOSIS OLD SCHOOL",
    Address: "GATLHOSE  KURUMAN  KURUMAN",
    Latitude: "-27.873",
    Longitude: "23.2336",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21320",
    WardLookupId: "4803",
    VDNumber: "65680106",
    RegisteredPopulation: "95",
    VotingStationName: "DIPPENAARSHOOP",
    Address: "",
    Latitude: "-27.6066",
    Longitude: "23.6379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21321",
    WardLookupId: "4803",
    VDNumber: "86741309",
    RegisteredPopulation: "2079",
    VotingStationName: "KAGUNG COMMUNITY HALL",
    Address: "KAGUNG  BA GA JANTJIE",
    Latitude: "-27.4294",
    Longitude: "23.5675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21322",
    WardLookupId: "4803",
    VDNumber: "86742838",
    RegisteredPopulation: "1006",
    VotingStationName: "A.B KOLWANE PRIMARY SCHOOL",
    Address: "KURUMAN  KAGUNG  VILLAGE  GA-SEGONYANA [KURUMAN]",
    Latitude: "-27.4189",
    Longitude: "23.5679",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21323",
    WardLookupId: "4804",
    VDNumber: "86740982",
    RegisteredPopulation: "1949",
    VotingStationName: "MAHIKANENG PRIMARY SCHOOL",
    Address: "SEVEN MILES  KURUMAN",
    Latitude: "-27.3795",
    Longitude: "23.3904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21324",
    WardLookupId: "4804",
    VDNumber: "86741950",
    RegisteredPopulation: "1307",
    VotingStationName: "GANTATELANG PRIMARY SCHOOL",
    Address: "DIKGWENG  KURUMAN",
    Latitude: "-27.3512",
    Longitude: "23.4621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21325",
    WardLookupId: "4804",
    VDNumber: "86743794",
    RegisteredPopulation: "339",
    VotingStationName: "BADIHILE PRIMARY SCHOOL",
    Address: "THAMOANCHE  MOTHIBISTAD  KURUMAN",
    Latitude: "-27.3305",
    Longitude: "23.4378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21326",
    WardLookupId: "4804",
    VDNumber: "86743851",
    RegisteredPopulation: "318",
    VotingStationName: "MOKALAMOSESANE COMMUNITY OFFICE",
    Address: "MOKALAMOSESANE  BAGA JANTJIE  KURUMAN",
    Latitude: "-27.3653",
    Longitude: "23.4096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21327",
    WardLookupId: "4805",
    VDNumber: "65580026",
    RegisteredPopulation: "1460",
    VotingStationName: "WRENCHVILLE COMMUNITY CENTRE",
    Address: "C/O BUITEKANT & TORTELDUIF  WRENCHVILLE  KURUMAN",
    Latitude: "-27.4509",
    Longitude: "23.4595",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21328",
    WardLookupId: "4805",
    VDNumber: "65580037",
    RegisteredPopulation: "2448",
    VotingStationName: "EILERD HALL",
    Address: "3 VLEI STRAAT  WRENCHVILLE  KURUMAN",
    Latitude: "-27.4446",
    Longitude: "23.4528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21329",
    WardLookupId: "4806",
    VDNumber: "86742872",
    RegisteredPopulation: "1941",
    VotingStationName: "SEUPE PRIMARY SCHOOL",
    Address: "MARUPING  GA-SEGONYANA [KURUMAN]",
    Latitude: "-27.3445",
    Longitude: "23.3746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21330",
    WardLookupId: "4806",
    VDNumber: "86743378",
    RegisteredPopulation: "1558",
    VotingStationName: "ST CLAIRE CATHOLIC CHURCH",
    Address: "GA-SEGONYANA [KURUMAN]  ",
    Latitude: "-27.3175",
    Longitude: "23.3591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21331",
    WardLookupId: "4807",
    VDNumber: "65570036",
    RegisteredPopulation: "3327",
    VotingStationName: "KATHU HIGH SCHOOL",
    Address: "BEN ALBERTS RD  KATHU  KATHU",
    Latitude: "-27.6937",
    Longitude: "23.0481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21332",
    WardLookupId: "4808",
    VDNumber: "65560013",
    RegisteredPopulation: "3446",
    VotingStationName: "IDT HALL",
    Address: "ERF 799  DEBEN  GAMAGARA (DEBEN)",
    Latitude: "-27.5939",
    Longitude: "22.8874",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21333",
    WardLookupId: "4809",
    VDNumber: "65590027",
    RegisteredPopulation: "1393",
    VotingStationName: "MAIKAELELO PRIMARY SCHOOL",
    Address: "351 TOTO  DIEPKLOOF  OLIFANTSHOEK",
    Latitude: "-27.9559",
    Longitude: "22.7506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21334",
    WardLookupId: "4809",
    VDNumber: "65590038",
    RegisteredPopulation: "555",
    VotingStationName: "LANGEBERG HIGH SCHOOL",
    Address: "MAIN ROAD  OLIFANTSHOEK  OLIFANTSHOEK",
    Latitude: "-27.9439",
    Longitude: "22.7354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21335",
    WardLookupId: "4810",
    VDNumber: "65590016",
    RegisteredPopulation: "3386",
    VotingStationName: "NOORD-KAAP PRIMÃŠRE SKOOL",
    Address: "1987 INDUSTRIA RD  WELGELEE  OLIFANTSHOEK",
    Latitude: "-27.9353",
    Longitude: "22.747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21336",
    WardLookupId: "4811",
    VDNumber: "65570047",
    RegisteredPopulation: "4530",
    VotingStationName: "MAPOTENG MULTI PURPOSE CENTRE",
    Address:
      "FITH STREET MAPOTENG  MAPOTENG/SESHENG  GAMAGARA MUNICIPALITY(KATHU)",
    Latitude: "-27.695",
    Longitude: "23.0116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21337",
    WardLookupId: "4811",
    VDNumber: "65660081",
    RegisteredPopulation: "38",
    VotingStationName: "BREDENKAMPSAAL",
    Address: "BREDENKAMP FARM  BREDENKAMP  GAMAGARA [KATHU]",
    Latitude: "-27.8044",
    Longitude: "22.9344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21338",
    WardLookupId: "4812",
    VDNumber: "65570014",
    RegisteredPopulation: "2926",
    VotingStationName: "KATHU TECHNICAL COLLEGE",
    Address: "HANS COETZEE ROAD  KATHU  KATHU",
    Latitude: "-27.7044",
    Longitude: "23.056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21339",
    WardLookupId: "4812",
    VDNumber: "65570025",
    RegisteredPopulation: "1608",
    VotingStationName: "SIYATHEMBA COMMUNITY HALL",
    Address:
      "SIYATHEMBA(KATHU)  GAMAGARA MUNICIPALITY  VOELVANGER CNR FLAMINK STREET",
    Latitude: "-27.7145",
    Longitude: "23.0657",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21340",
    WardLookupId: "4813",
    VDNumber: "65560035",
    RegisteredPopulation: "362",
    VotingStationName: "HIGH SCHOOL GAMAGARA",
    Address: "KATHU  GAMAGRA  GAMAGARA",
    Latitude: "-27.597",
    Longitude: "22.8772",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21341",
    WardLookupId: "4813",
    VDNumber: "65570069",
    RegisteredPopulation: "56",
    VotingStationName: "KHAI APPLE",
    Address: "DEBEN MAIN ROAD  GAMAGARA MUNICIPALITY KATHU  KATHU",
    Latitude: "-27.6777",
    Longitude: "23.0163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21342",
    WardLookupId: "4813",
    VDNumber: "65570070",
    RegisteredPopulation: "449",
    VotingStationName: "BESTWOOD SHOW HOUSE",
    Address:
      "41 SANDSALIE CRESCENT  BESTWOOD/ GAMAGARA MUNICIPALITY  KAHU/ BESTWOOD",
    Latitude: "-27.7085",
    Longitude: "23.0783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21343",
    WardLookupId: "4813",
    VDNumber: "65680038",
    RegisteredPopulation: "58",
    VotingStationName: "ULSTER PRIMARY SCHOOL",
    Address: "ULSTER FARM  GAMAGARA [KATHU]",
    Latitude: "-27.4125",
    Longitude: "23.0771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21344",
    WardLookupId: "4813",
    VDNumber: "65680072",
    RegisteredPopulation: "46",
    VotingStationName: "HALIFORD",
    Address: "HALIFORD  HALIFORD FARM  GAMAGARA [KATHU]",
    Latitude: "-27.5684",
    Longitude: "22.9847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21345",
    WardLookupId: "4813",
    VDNumber: "65680083",
    RegisteredPopulation: "345",
    VotingStationName: "BROUGHTON FARM GUESTHOUSE",
    Address: "435 BROUGTON FARM GUSETHOUSE  KATHU  BABATAS CPA",
    Latitude: "-27.5398",
    Longitude: "23.2383",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21346",
    WardLookupId: "422",
    VDNumber: "97140012",
    RegisteredPopulation: "5656",
    VotingStationName: "RIVERSIDE COLLEGE",
    Address:
      "CAPE TOWN 7441  BURGUNDY ESTATE  CORNER CARMINE DRIVE AND 6 CELLARS DRIVE",
    Latitude: "-33.8382",
    Longitude: "18.5485",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21347",
    WardLookupId: "422",
    VDNumber: "97140078",
    RegisteredPopulation: "4132",
    VotingStationName: "PANORAMA PRIMARY SCHOOL",
    Address: "2 MALMESBURY ROAD  WELGELEGEN  PAROW",
    Latitude: "-33.874",
    Longitude: "18.5707",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21348",
    WardLookupId: "422",
    VDNumber: "97140089",
    RegisteredPopulation: "3474",
    VotingStationName: "MONTE VISTA PRIMARY SCHOOL",
    Address: "HUISING AVENUE  MONTE VISTA  CAPE TOWN",
    Latitude: "-33.8818",
    Longitude: "18.5533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21349",
    WardLookupId: "422",
    VDNumber: "97140090",
    RegisteredPopulation: "2345",
    VotingStationName: "NORTHLINK COLLEGE",
    Address: "CNR PLATTEKLOOF RD & ROTHCHILD BLVD  WELGELEGEN  PAROW",
    Latitude: "-33.8746",
    Longitude: "18.5756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21350",
    WardLookupId: "422",
    VDNumber: "97140102",
    RegisteredPopulation: "2943",
    VotingStationName: "PANORAMA PALMS",
    Address: "66 HENDRIK VERWORD DRIVE  PANORAMA  PAROW",
    Latitude: "-33.8854",
    Longitude: "18.5765",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21351",
    WardLookupId: "422",
    VDNumber: "97140922",
    RegisteredPopulation: "2948",
    VotingStationName: "WORLD ESTATES INTERNATIONAL TRAINING ROOM",
    Address: "1 KEURBOOM CRESCENT  PLATTEKLOOF II  PAROW",
    Latitude: "-33.8742",
    Longitude: "18.5813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21352",
    WardLookupId: "423",
    VDNumber: "97140663",
    RegisteredPopulation: "2441",
    VotingStationName: "NG KERK PAROW NORTH",
    Address: "71 THEAL  PAROW NORTH  PAROW",
    Latitude: "-33.8886",
    Longitude: "18.5792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21353",
    WardLookupId: "423",
    VDNumber: "97140674",
    RegisteredPopulation: "2785",
    VotingStationName: "PAROW PARK HOMES",
    Address: "OLD FIFTH AVENUE   PAROW  PAROW",
    Latitude: "-33.898",
    Longitude: "18.5695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21354",
    WardLookupId: "423",
    VDNumber: "97140742",
    RegisteredPopulation: "2898",
    VotingStationName: "PAROW SENIOR SENTRUM",
    Address: "62  BEDFORD STREET  PAROW   CAPE TOWN",
    Latitude: "-33.9016",
    Longitude: "18.5846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21355",
    WardLookupId: "423",
    VDNumber: "97140764",
    RegisteredPopulation: "3069",
    VotingStationName: "TYGERBERG HIGH SCHOOL",
    Address: "FAIRFIELD NORTH STR  PAROW",
    Latitude: "-33.8935",
    Longitude: "18.5938",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21356",
    WardLookupId: "423",
    VDNumber: "97140775",
    RegisteredPopulation: "2346",
    VotingStationName: "VREDELUST PRIMARY SCHOOL",
    Address: "BROADWAY STR  VREDELUST  BELLVILLE",
    Latitude: "-33.8979",
    Longitude: "18.6132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21357",
    WardLookupId: "423",
    VDNumber: "97140797",
    RegisteredPopulation: "2866",
    VotingStationName: "TOTIUS PRIMARY SCHOOL",
    Address: "9DE LAAN  BOSTON  BELLVILLE",
    Latitude: "-33.8946",
    Longitude: "18.6244",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21358",
    WardLookupId: "423",
    VDNumber: "97140809",
    RegisteredPopulation: "2455",
    VotingStationName: "DF MALAN HIGH SCHOOL",
    Address: "FRANS CONRADIE DRIVE  BELLVILLE",
    Latitude: "-33.8936",
    Longitude: "18.6261",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21359",
    WardLookupId: "424",
    VDNumber: "97140865",
    RegisteredPopulation: "2957",
    VotingStationName: "EXCELSIOR PRIMARY SCHOOL",
    Address: "VAN DYK STREET  DE LA HAYE  BELLVILLE",
    Latitude: "-33.9001",
    Longitude: "18.6494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21360",
    WardLookupId: "424",
    VDNumber: "97140876",
    RegisteredPopulation: "1527",
    VotingStationName: "FRESHWINDS MINISTRIES",
    Address: "20 SULTANA STREET  SHIRLEY PARK  BELLVILLE",
    Latitude: "-33.9066",
    Longitude: "18.6604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21361",
    WardLookupId: "424",
    VDNumber: "97140887",
    RegisteredPopulation: "3152",
    VotingStationName: "BELVILLE NORTH PRIMARY SCHOOL",
    Address: "VAN DER STEL STREET  BELVILLE  ",
    Latitude: "-33.8924",
    Longitude: "18.6409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21362",
    WardLookupId: "424",
    VDNumber: "97140898",
    RegisteredPopulation: "3759",
    VotingStationName: "NEDERDUITSCH HERVORMDE KERK",
    Address: "CNR MEERLUST & GANZKRAAL  OAKGLEN  BELLVILLE",
    Latitude: "-33.8887",
    Longitude: "18.6631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21363",
    WardLookupId: "424",
    VDNumber: "97140911",
    RegisteredPopulation: "4718",
    VotingStationName: "NG KERK LA ROCHELLE",
    Address: "CNR MEERLUST & HADLEY STREET  LA ROCHELLE  BELLVILLE",
    Latitude: "-33.8842",
    Longitude: "18.6634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21364",
    WardLookupId: "425",
    VDNumber: "97120087",
    RegisteredPopulation: "5830",
    VotingStationName: "MILNERTON HIGH SCHOOL",
    Address: "PIENAAR ROAD  MILNERTON  MILNTERTON",
    Latitude: "-33.8706",
    Longitude: "18.4978",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21365",
    WardLookupId: "425",
    VDNumber: "97120098",
    RegisteredPopulation: "2885",
    VotingStationName: "KIDDZ BUZZ EDUCARE",
    Address: "9 SUMMER GREENS DRIVE  SUMMER GREENS  SUMMER GREENS",
    Latitude: "-33.8798",
    Longitude: "18.5267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21366",
    WardLookupId: "425",
    VDNumber: "97120391",
    RegisteredPopulation: "6577",
    VotingStationName: "SINENJONGO - HIGH SCHOOL",
    Address: "11A FREEDOM WAY  MILNERTON JOE SLOVO  CAPE TOWN",
    Latitude: "-33.874",
    Longitude: "18.5124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21367",
    WardLookupId: "426",
    VDNumber: "97140023",
    RegisteredPopulation: "4499",
    VotingStationName: "WOLRAAD WOLTEMADE PRIMARY SCHOOL",
    Address: "2 MEERHOFF STREET  BOTHASIG  BOTHASIG",
    Latitude: "-33.8542",
    Longitude: "18.5367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21368",
    WardLookupId: "426",
    VDNumber: "97140034",
    RegisteredPopulation: "3684",
    VotingStationName: "NG KERK BOTHASIG (HALL)",
    Address: "CAPE TOWN 7441  BOTHASIG  3 NASSAU STREET",
    Latitude: "-33.8646",
    Longitude: "18.533",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21369",
    WardLookupId: "426",
    VDNumber: "97140045",
    RegisteredPopulation: "3190",
    VotingStationName: "EDGEMEAD PRIMARY SCHOOL",
    Address: "7 DENISON WAY  EDGEMEAD  CAPE TOWN",
    Latitude: "-33.8677",
    Longitude: "18.5493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21370",
    WardLookupId: "426",
    VDNumber: "97140056",
    RegisteredPopulation: "3081",
    VotingStationName: "EDGEMEAD HIGH SCHOOL",
    Address: "LETCHWORTH DRIVE  EDGEMEAD  CAPE TOWN",
    Latitude: "-33.8727",
    Longitude: "18.551",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21371",
    WardLookupId: "426",
    VDNumber: "97140067",
    RegisteredPopulation: "3888",
    VotingStationName: "LINKWAY CHUCH (HALL)",
    Address: "36 LINKWAY CHURCH  EDGEMEAD  CAPE TOWN",
    Latitude: "-33.8806",
    Longitude: "18.5362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21372",
    WardLookupId: "427",
    VDNumber: "97100254",
    RegisteredPopulation: "2799",
    VotingStationName: "MASAKHANE EDUCARE CENTRE",
    Address: "16 LABOHEME AVENUE  WALLACEDENE  KRAAIFONTEIN",
    Latitude: "-33.8619",
    Longitude: "18.734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21373",
    WardLookupId: "427",
    VDNumber: "97100265",
    RegisteredPopulation: "2955",
    VotingStationName: "BERNADINO HEIGHTS SECONDARY SSCHOOL",
    Address: "LA BOHEME ROAD  WATSONIA PARK  KRAAIFONTEIN",
    Latitude: "-33.8658",
    Longitude: "18.7263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21374",
    WardLookupId: "427",
    VDNumber: "97100535",
    RegisteredPopulation: "2398",
    VotingStationName: "ENKULULEKWENI PRIMARY SCHOOL",
    Address: "MATSHOBA ROAD  WALLACEDENE  KRAAIFONTEIN",
    Latitude: "-33.8566",
    Longitude: "18.7352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21375",
    WardLookupId: "427",
    VDNumber: "97100546",
    RegisteredPopulation: "2618",
    VotingStationName: "WALLACEDENE METHODIST CHURCH",
    Address: "BOTFONTEIN ROAD  WALLACEDENE  CAPE TOWN",
    Latitude: "-33.8605",
    Longitude: "18.7277",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21376",
    WardLookupId: "427",
    VDNumber: "97100580",
    RegisteredPopulation: "1885",
    VotingStationName: "HECTOR PETERSON HIGH SCHOOL",
    Address: "60 BOESAK STREET  WALLACEDENE  KRAAIFONTEIN",
    Latitude: "-33.8593",
    Longitude: "18.7322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21377",
    WardLookupId: "427",
    VDNumber: "97100849",
    RegisteredPopulation: "3276",
    VotingStationName: "SHILOH KHANYA KIDS",
    Address: "MTYOBILE  WALLACEDENE  KRAAIFONTEIN",
    Latitude: "-33.856",
    Longitude: "18.7416",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21378",
    WardLookupId: "427",
    VDNumber: "97100850",
    RegisteredPopulation: "3494",
    VotingStationName: "SINETHEMBA EDUCARE",
    Address: "CORNER NQAKULA & BARKER  KRAAIFONTEIN  KRAAIFONTEIN",
    Latitude: "-33.8595",
    Longitude: "18.7386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21379",
    WardLookupId: "427",
    VDNumber: "98420126",
    RegisteredPopulation: "290",
    VotingStationName: "BOTTELARY PRIMARY SCHOOL",
    Address: "BOTTELARY WAY  KOOPMANSKLOOF  KRAAIFONTEIN",
    Latitude: "-33.8877",
    Longitude: "18.7488",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21380",
    WardLookupId: "428",
    VDNumber: "97100018",
    RegisteredPopulation: "2369",
    VotingStationName: "ROTSGEMEENTE AFM BRACKENFELL",
    Address: "CNR DAHLIA & LELIE STREET  PROTEA HEIGHTS  BRACKENFELL",
    Latitude: "-33.881",
    Longitude: "18.704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21381",
    WardLookupId: "428",
    VDNumber: "97100041",
    RegisteredPopulation: "1939",
    VotingStationName: "BROOKLANDS PRIMARY SCHOOL",
    Address: "BROOKLANDS CRESCENT  NORTHPINE  BRACKENFELL",
    Latitude: "-33.8732",
    Longitude: "18.7171",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21382",
    WardLookupId: "428",
    VDNumber: "97100209",
    RegisteredPopulation: "4134",
    VotingStationName: "NORTHPINE PRIMARY SCHOOL",
    Address: "19 EASTWOOD DRIVE  NORTHPINE  KRAAIFONTEIN",
    Latitude: "-33.8681",
    Longitude: "18.7126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21383",
    WardLookupId: "428",
    VDNumber: "97100221",
    RegisteredPopulation: "2447",
    VotingStationName: "PARKDENE PRIMARY SCHOOL",
    Address: "PARK LANE  SCOTTSDENE  KRAAIFONTEIN",
    Latitude: "-33.8606",
    Longitude: "18.7243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21384",
    WardLookupId: "428",
    VDNumber: "97100232",
    RegisteredPopulation: "2488",
    VotingStationName: "CAVALLERIA PRIMARY SCHOOL",
    Address: "JOSEPH AVENUE  SCOTTSDENE  KRAAIFONTEIN",
    Latitude: "-33.8647",
    Longitude: "18.7231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21385",
    WardLookupId: "428",
    VDNumber: "97100636",
    RegisteredPopulation: "2715",
    VotingStationName: "TENT KRUIS & SUIKERBOSSIE CRESCENT",
    Address: "CNR KRUIS AND SUIKERBOSSIE CRS  PROTEA VILLAGE  BRACKENFELL",
    Latitude: "-33.8825",
    Longitude: "18.7134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21386",
    WardLookupId: "429",
    VDNumber: "97100029",
    RegisteredPopulation: "7309",
    VotingStationName: "BASTION PRIMARY SCHOOL",
    Address: "JACARANDA STREET  PROTEA HEIGHTS  BRACKENFELL",
    Latitude: "-33.8875",
    Longitude: "18.6985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21387",
    WardLookupId: "429",
    VDNumber: "97100030",
    RegisteredPopulation: "3356",
    VotingStationName: "BRACKENFELL METHODIST CHURCH",
    Address: "JAKARANDA  PROTEA HOOGTE  BRACKENFELL",
    Latitude: "-33.8862",
    Longitude: "18.6962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21388",
    WardLookupId: "429",
    VDNumber: "97100052",
    RegisteredPopulation: "2375",
    VotingStationName: "GENE LOUW TRAFFIC CENTRE",
    Address: "BRACKENFELL BLVD  EDEN PARK  BRACKENFELL",
    Latitude: "-33.8712",
    Longitude: "18.6819",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21389",
    WardLookupId: "429",
    VDNumber: "97100096",
    RegisteredPopulation: "3732",
    VotingStationName: "NG KERK DE EIKE KUILSRIVER",
    Address: "VAN DEN HEEVER STREET  SONEIKE  KUILS RIVER",
    Latitude: "-33.913",
    Longitude: "18.6822",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21390",
    WardLookupId: "429",
    VDNumber: "97100805",
    RegisteredPopulation: "1354",
    VotingStationName: "BRACKENFELL COMMUNITY CHURCH",
    Address: "ROSLYN  BRACKENFELL  BRACKENFELL",
    Latitude: "-33.8811",
    Longitude: "18.6824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21391",
    WardLookupId: "429",
    VDNumber: "97100816",
    RegisteredPopulation: "1235",
    VotingStationName: "NG KERK DIGTEBIJ KUILSRIVER",
    Address: "25 DIGTEBIJ AVANUE  SAREPTA  KUILSRIVER",
    Latitude: "-33.9208",
    Longitude: "18.6724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21392",
    WardLookupId: "429",
    VDNumber: "97100951",
    RegisteredPopulation: "2533",
    VotingStationName: "CURRO BRACKENFELL PRIVATE SCHOOL",
    Address: "67 VREDEVELD WAY  BRACKENFELL  CAPE TOWN",
    Latitude: "-33.8993",
    Longitude: "18.6875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21393",
    WardLookupId: "430",
    VDNumber: "97140810",
    RegisteredPopulation: "2279",
    VotingStationName: "BELVILLE SOUTH PRIMARY SCHOOL",
    Address: "82 INDUSTRY STR  BELLVILLE SOUTH  BELLVILLE",
    Latitude: "-33.9195",
    Longitude: "18.6411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21394",
    WardLookupId: "430",
    VDNumber: "97140821",
    RegisteredPopulation: "2576",
    VotingStationName: "GOEIE HOOP PRIMARY SCHOOL",
    Address: "GOEIE HOOP STREET  BELLVILLE SOUTH  BELLVILLE",
    Latitude: "-33.9134",
    Longitude: "18.6365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21395",
    WardLookupId: "430",
    VDNumber: "97140832",
    RegisteredPopulation: "2020",
    VotingStationName: "KASSELVLEI PRIMARY SCHOOL",
    Address: "NEETLING STREET  BELLVILLE SOUTH  CAPE TOWN",
    Latitude: "-33.9173",
    Longitude: "18.6421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21396",
    WardLookupId: "430",
    VDNumber: "97141563",
    RegisteredPopulation: "4276",
    VotingStationName: "U W C CASSINGA HALL",
    Address: "MODDERDAM  ROAD  BELLVILLE SOUTH",
    Latitude: "-33.9289",
    Longitude: "18.6312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21397",
    WardLookupId: "430",
    VDNumber: "97141574",
    RegisteredPopulation: "2179",
    VotingStationName: "ATHLONE SCHOOL FOR THE BLIND",
    Address: "ATHLONE STREET  GLEN HAVEN  BELLVILLE",
    Latitude: "-33.9174",
    Longitude: "18.653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21398",
    WardLookupId: "430",
    VDNumber: "97141754",
    RegisteredPopulation: "1268",
    VotingStationName: "LABIANCE PRIMARY SCHOOL",
    Address: "STEINBERG STREET  BELLVILLE  ",
    Latitude: "-33.9113",
    Longitude: "18.6594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21399",
    WardLookupId: "430",
    VDNumber: "97141989",
    RegisteredPopulation: "1661",
    VotingStationName: "ECCLESIA EDUCARE",
    Address: "WATERBARRY AND YELLOW WOOD  GREENLANDS  BELLVILLE SOUTH",
    Latitude: "-33.9228",
    Longitude: "18.6321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21400",
    WardLookupId: "430",
    VDNumber: "97142508",
    RegisteredPopulation: "988",
    VotingStationName: "WINSLEY PRIMARY SCHOOL",
    Address: "WILLIAM TAYLOR  BELLVILLE  CITY OF CAPE TOWN [CAPE TOWN]",
    Latitude: "-33.9184",
    Longitude: "18.6501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21401",
    WardLookupId: "431",
    VDNumber: "97140629",
    RegisteredPopulation: "2003",
    VotingStationName: "PINEDENE PRIMARY SCHOOL",
    Address: "PINEDENE STREET  RAVENSMEAD  PAROW",
    Latitude: "-33.9231",
    Longitude: "18.6022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21402",
    WardLookupId: "431",
    VDNumber: "97140630",
    RegisteredPopulation: "3701",
    VotingStationName: "NORTHWAY PRIMARY SCHOOL",
    Address: "MILDENE ROAD  RAVENSMEAD  PAROW",
    Latitude: "-33.9198",
    Longitude: "18.6075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21403",
    WardLookupId: "431",
    VDNumber: "97140652",
    RegisteredPopulation: "2378",
    VotingStationName: "SAFFIER PRIMARY SCHOOL",
    Address: "ELMWOOD STR  PAROW-VALLEY  PAROW",
    Latitude: "-33.9125",
    Longitude: "18.6061",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21404",
    WardLookupId: "431",
    VDNumber: "97140753",
    RegisteredPopulation: "2808",
    VotingStationName: "PAROW HIGH SCHOOL",
    Address: "TONER STREET  PAROW EAST  CAPE TOWN",
    Latitude: "-33.9017",
    Longitude: "18.6025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21405",
    WardLookupId: "431",
    VDNumber: "97140843",
    RegisteredPopulation: "1722",
    VotingStationName: "PROTEA HALL TYGERBERG HOSPITAL",
    Address: "FRANCIE VAN ZIJL  PAROW  CAPE TOWN",
    Latitude: "-33.909",
    Longitude: "18.609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21406",
    WardLookupId: "431",
    VDNumber: "97140854",
    RegisteredPopulation: "2535",
    VotingStationName: "NORTHLINK COLLEGE PROTEA CAMPUS-BELLVILLE",
    Address: "80 VOORTREKKER ROAD  KEMPENVILLE BELLVILLE  CAPE TOWN",
    Latitude: "-33.9033",
    Longitude: "18.6364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21407",
    WardLookupId: "432",
    VDNumber: "97100085",
    RegisteredPopulation: "2966",
    VotingStationName: "ALTA DU TOIT SPECIAL SCHOOL",
    Address: "PIET FRANSMAN STR  AMANDELSIG  KUILSRIVIER",
    Latitude: "-33.9257",
    Longitude: "18.6977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21408",
    WardLookupId: "432",
    VDNumber: "97100108",
    RegisteredPopulation: "3959",
    VotingStationName: "NG KERK KUILSRIVER",
    Address: "CHURCH STREET  KUILSRIVIER  CAPE TOWN",
    Latitude: "-33.9251",
    Longitude: "18.6804",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21409",
    WardLookupId: "432",
    VDNumber: "97100119",
    RegisteredPopulation: "3635",
    VotingStationName: "KUILSRIVER CARAVAN PARK",
    Address: "VAN RIEBEECK  KUILS RIVER  CAPE TOWN",
    Latitude: "-33.9421",
    Longitude: "18.6904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21410",
    WardLookupId: "432",
    VDNumber: "97100120",
    RegisteredPopulation: "1669",
    VotingStationName: "AGS KERK - KOINONIA CELEBRATION CENTRE",
    Address: "SAMUEL STREET  SAREPTA  KUILS RIVER",
    Latitude: "-33.9263",
    Longitude: "18.6711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21411",
    WardLookupId: "432",
    VDNumber: "97100131",
    RegisteredPopulation: "1496",
    VotingStationName: "WESTERN CAPE SPORTS SCHOOL",
    Address: "NEW NOOIENSFONTEIN ROAD  NOOIENSFONTEIN  KUILSRIVIER",
    Latitude: "-33.9398",
    Longitude: "18.6786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21412",
    WardLookupId: "432",
    VDNumber: "97100142",
    RegisteredPopulation: "1943",
    VotingStationName: "P D PAULSE MEMORIAL LIBRARY",
    Address: "RIETVLEI ROAD  KUILS RIVER  SAREPTA",
    Latitude: "-33.9314",
    Longitude: "18.6718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21413",
    WardLookupId: "432",
    VDNumber: "97100164",
    RegisteredPopulation: "2840",
    VotingStationName: "IRISTA PRIMARY SCHOOL",
    Address: "IRIS STREET  SAREPTA  KUILS RIVER",
    Latitude: "-33.9362",
    Longitude: "18.666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21414",
    WardLookupId: "433",
    VDNumber: "97141473",
    RegisteredPopulation: "2658",
    VotingStationName: "LIGHT HOUSE FAMILY CHURCH",
    Address: "80 ARUNDEL  BELHAR  CAPE TOWN",
    Latitude: "-33.9524",
    Longitude: "18.6541",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21415",
    WardLookupId: "433",
    VDNumber: "97141484",
    RegisteredPopulation: "2117",
    VotingStationName: "MATROOSBERG WEG PRIMARY SCHOOL",
    Address: "HAARLEM AVENUE  BELHAR EXT 13  BELHAR",
    Latitude: "-33.9503",
    Longitude: "18.6371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21416",
    WardLookupId: "433",
    VDNumber: "97141495",
    RegisteredPopulation: "3179",
    VotingStationName: "PERSEVERANCE HIGH SCHOOL",
    Address: "ALABAMA WAY  BELHAR  CAPE TOWN",
    Latitude: "-33.948",
    Longitude: "18.645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21417",
    WardLookupId: "433",
    VDNumber: "97141507",
    RegisteredPopulation: "3538",
    VotingStationName: "ST MARY MAGDALENE CHURCH",
    Address: "WINTER  BELHAR  CAPE TOWN",
    Latitude: "-33.945",
    Longitude: "18.6516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21418",
    WardLookupId: "433",
    VDNumber: "97141518",
    RegisteredPopulation: "2432",
    VotingStationName: "RIEBEECK PRIMARY SCHOOL",
    Address: "RIEBEECK STREET  EXTENTION 11  BELHAR",
    Latitude: "-33.9456",
    Longitude: "18.6411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21419",
    WardLookupId: "433",
    VDNumber: "97141787",
    RegisteredPopulation: "2383",
    VotingStationName: "OASIS SPECIAL SCHOOL",
    Address: "CNR SYMPHONY WAY & ERICA DRIVE  BELHAR 23  CAPE TOWN",
    Latitude: "-33.9409",
    Longitude: "18.6369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21420",
    WardLookupId: "434",
    VDNumber: "97140720",
    RegisteredPopulation: "4483",
    VotingStationName: "THE HAGUE PRIMARY SCHOOL",
    Address: "THE HAGUE DRIVE  THE HAGUE  DELFT",
    Latitude: "-33.9581",
    Longitude: "18.6388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21421",
    WardLookupId: "434",
    VDNumber: "97140731",
    RegisteredPopulation: "3183",
    VotingStationName: "ROSENDAL PRIMARY SCHOOL",
    Address: "53 ROOSENDAAL  AVENUE  ROOSENDAL  DELFT",
    Latitude: "-33.9628",
    Longitude: "18.6507",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21422",
    WardLookupId: "434",
    VDNumber: "97141462",
    RegisteredPopulation: "2735",
    VotingStationName: "ROSENDAAL HIGH SCHOOL",
    Address: "MAIN ROAD  ROOSENDAL  DELFT",
    Latitude: "-33.9605",
    Longitude: "18.6478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21423",
    WardLookupId: "434",
    VDNumber: "97141990",
    RegisteredPopulation: "4300",
    VotingStationName: "LEIDEN AVENUE SECONDARY SCHOOL",
    Address: "LEIDEN AVENUE  LEIDEN  DELFT",
    Latitude: "-33.9706",
    Longitude: "18.6361",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21424",
    WardLookupId: "434",
    VDNumber: "97142205",
    RegisteredPopulation: "2347",
    VotingStationName: "LEIDEN AVENUE PRIMARY SCHOOL",
    Address: "LEIDEN  DELFT  CAPE TOWN",
    Latitude: "-33.9737",
    Longitude: "18.6387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21425",
    WardLookupId: "434",
    VDNumber: "97142227",
    RegisteredPopulation: "2919",
    VotingStationName: "LEIDEN PRIMARY SCHOOL",
    Address: "0 HARTBEES STREET  LEIDEN  DELFT",
    Latitude: "-33.9684",
    Longitude: "18.641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21426",
    WardLookupId: "435",
    VDNumber: "97100175",
    RegisteredPopulation: "1951",
    VotingStationName: "STRATFORD PRIMARY SCHOOL",
    Address: "WUPPERTAL STREET  DEVON PARK  EERSTE RIVER",
    Latitude: "-34.0064",
    Longitude: "18.7272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21427",
    WardLookupId: "435",
    VDNumber: "97100186",
    RegisteredPopulation: "4003",
    VotingStationName: "HAPPY VALLEY COMMUNITY HALL",
    Address: "LUYOLO STREET  HAPPY VALLEY  BLACKHEATH",
    Latitude: "-33.9733",
    Longitude: "18.6844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21428",
    WardLookupId: "435",
    VDNumber: "97100197",
    RegisteredPopulation: "1452",
    VotingStationName: "RUSTDAL (TENT)",
    Address: "DALE STREET  RUSTDAL  BLACKHEATH",
    Latitude: "-33.9604",
    Longitude: "18.6995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21429",
    WardLookupId: "435",
    VDNumber: "97100276",
    RegisteredPopulation: "2869",
    VotingStationName: "BLACKHEATH - PRIMARY SCHOOL",
    Address: "CNR EVERGREEN AND SCHOOL  BLACKHEATH  CAPE TOWN",
    Latitude: "-33.9786",
    Longitude: "18.6992",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21430",
    WardLookupId: "435",
    VDNumber: "97100287",
    RegisteredPopulation: "2327",
    VotingStationName: "THE CHURCH OF THE NATIVITY",
    Address: "CNR CHANDOS CRES & DENNEMERE DR  DENNEMERE  BLACKHEATH",
    Latitude: "-33.9805",
    Longitude: "18.7071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21431",
    WardLookupId: "435",
    VDNumber: "97100300",
    RegisteredPopulation: "1843",
    VotingStationName: "AME CHURCH BLACKHEATH",
    Address: "CNR SPRINGOK & IMPALA STREET  KLEINVLEI  EERSTE RIVER",
    Latitude: "-33.9851",
    Longitude: "18.7151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21432",
    WardLookupId: "435",
    VDNumber: "97100782",
    RegisteredPopulation: "3458",
    VotingStationName: "KUILSRIVER RUGBY CLUB",
    Address: "JAGTERSHOF STREET  JAGTERSHOF  KUILSRIVER",
    Latitude: "-33.9387",
    Longitude: "18.7006",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21433",
    WardLookupId: "436",
    VDNumber: "97110031",
    RegisteredPopulation: "2826",
    VotingStationName: "HELDERBERG COLLEGE GYMNASIUM",
    Address: "ANNANDALE DRIVE  SOMERSET WEST  CAPE TOWN",
    Latitude: "-34.0498",
    Longitude: "18.8425",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21434",
    WardLookupId: "436",
    VDNumber: "97110097",
    RegisteredPopulation: "1226",
    VotingStationName: "FIRGROVE PRIMARY SCHOOL",
    Address: "SEVENTH STREET  FIRGROVE  ",
    Latitude: "-34.058",
    Longitude: "18.7841",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21435",
    WardLookupId: "436",
    VDNumber: "97110187",
    RegisteredPopulation: "3462",
    VotingStationName: "DE HOOP PRIMARY SCHOOL",
    Address: "OLD STELLENBOSCH RD  SOMERSET WEST  ",
    Latitude: "-34.0694",
    Longitude: "18.8367",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21436",
    WardLookupId: "436",
    VDNumber: "97110200",
    RegisteredPopulation: "2584",
    VotingStationName: "DIE WINGERD PRE-PRIMARY SCHOOL",
    Address: "8 PINOTAGE AVENUE  SOMERSET WEST  CAPE TOWN",
    Latitude: "-34.0656",
    Longitude: "18.8288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21437",
    WardLookupId: "436",
    VDNumber: "97110211",
    RegisteredPopulation: "4769",
    VotingStationName: "LIFE CHURCH",
    Address: "CNR LOBELIA & FIRGROVE ROADS  HELDERVUE  SOMERSET WEST",
    Latitude: "-34.0679",
    Longitude: "18.8223",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21438",
    WardLookupId: "436",
    VDNumber: "97110266",
    RegisteredPopulation: "2933",
    VotingStationName: "GORDON HIGH SCHOOL",
    Address: "DE BEERS AVENUE  SOMERSET WEST",
    Latitude: "-34.0863",
    Longitude: "18.8339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21439",
    WardLookupId: "436",
    VDNumber: "97110367",
    RegisteredPopulation: "2539",
    VotingStationName: "TALANI RESTAURANT (HELDERBERG VILLAGE)",
    Address: "BAKKERSKLOOF ROAD  SOMERSET WEST  SOMERSET WEST",
    Latitude: "-34.045",
    Longitude: "18.8192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21440",
    WardLookupId: "437",
    VDNumber: "97100311",
    RegisteredPopulation: "3675",
    VotingStationName: "SPURWING PRIMARY SCHOOL",
    Address: "SPURWING DRIVE  ELECTRIC CITY  EERSTE RIVER",
    Latitude: "-34.0184",
    Longitude: "18.7146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21441",
    WardLookupId: "437",
    VDNumber: "97100355",
    RegisteredPopulation: "2424",
    VotingStationName: "SUPER STRUCTURE (TENT)",
    Address: "SANDPIPER WAY  DEVON PARK  EERSTERIVIER",
    Latitude: "-34.0119",
    Longitude: "18.7287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21442",
    WardLookupId: "437",
    VDNumber: "97100377",
    RegisteredPopulation: "1760",
    VotingStationName: "SHEKINAH TABERNACLE FAMILY WORSHIP CENTRE",
    Address: "FOREST HEIGHTS DRIVE  FOREST HEIGHS  EERSTERIVER",
    Latitude: "-34.0055",
    Longitude: "18.7154",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21443",
    WardLookupId: "437",
    VDNumber: "97100388",
    RegisteredPopulation: "3663",
    VotingStationName: "EERSTERIVIER LIBRARY",
    Address: "CNR BEVERLY & BOBS WAY  BEVERLEY PARK  EERSTE RIVIER",
    Latitude: "-34.0085",
    Longitude: "18.7246",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21444",
    WardLookupId: "437",
    VDNumber: "97100399",
    RegisteredPopulation: "1380",
    VotingStationName: "BLUE DOWNS GOSPEL ASSEMBLY- EERSTERIVER",
    Address: "CNR BEVERLEY & STRATFORD ST.  BEVERLEY PARK  EERSTE RIVER",
    Latitude: "-34.0157",
    Longitude: "18.7304",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21445",
    WardLookupId: "437",
    VDNumber: "97100401",
    RegisteredPopulation: "2715",
    VotingStationName: "EERSTERIVIER SENIOR SECONDARY SCHOOL",
    Address: "BEVERLEY STREET  HIGH PLACES  EERSTE RIVER",
    Latitude: "-34.0185",
    Longitude: "18.7318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21446",
    WardLookupId: "437",
    VDNumber: "97141608",
    RegisteredPopulation: "2554",
    VotingStationName: "MANZOMTHOMBO SECONDARY SCHOOL",
    Address: "SCHOOL  MFULENI  KUILS RIVER",
    Latitude: "-34.008",
    Longitude: "18.6811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21447",
    WardLookupId: "437",
    VDNumber: "97142395",
    RegisteredPopulation: "1605",
    VotingStationName: "NONCEDO CRECHE",
    Address: "1 ZQHAMO STREET  GARDEN CITY  MFULENI",
    Latitude: "-34.0035",
    Longitude: "18.6842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21448",
    WardLookupId: "438",
    VDNumber: "97100298",
    RegisteredPopulation: "1790",
    VotingStationName: "MELTON PRIMARY SCHOOL",
    Address: "DAISY STREET  KLEINVLEI  EERSTE RIVER",
    Latitude: "-33.9902",
    Longitude: "18.7109",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21449",
    WardLookupId: "438",
    VDNumber: "97100322",
    RegisteredPopulation: "2414",
    VotingStationName: "ST PETERS ANGLICAN CHURCH - KLEINVLEI",
    Address: "10 LOERIE STREET  KLEINVLEI  EERSTERIVER",
    Latitude: "-33.9905",
    Longitude: "18.7066",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21450",
    WardLookupId: "438",
    VDNumber: "97100333",
    RegisteredPopulation: "1931",
    VotingStationName: "KINGDOM IMPACT MINISTRIES - EERSTERIVER",
    Address: "FOREST DRIVE  ROSEDALE  EERSTE RIVER",
    Latitude: "-34.0023",
    Longitude: "18.7201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21451",
    WardLookupId: "438",
    VDNumber: "97100344",
    RegisteredPopulation: "1750",
    VotingStationName: "DIE VOLKS KERK VAN AFRIKA - KLEINVLEI",
    Address: "15 WATERMAN STREET  PINE PLACE KLEINVLEI  EERSTE RIVER",
    Latitude: "-34.0006",
    Longitude: "18.7129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21452",
    WardLookupId: "438",
    VDNumber: "97100366",
    RegisteredPopulation: "2819",
    VotingStationName: "FOREST HEIGHTS HIGH SCHOOL",
    Address: "BLOEKOM AVENUE  FOREST HEIGHTS  EERSTE RIVER",
    Latitude: "-34.0026",
    Longitude: "18.7084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21453",
    WardLookupId: "438",
    VDNumber: "97100423",
    RegisteredPopulation: "4319",
    VotingStationName: "MALIBU HIGH SCHOOL",
    Address: "CHATRES CRESCENT  MALIBU VILLAGE  BLUE DOWNS",
    Latitude: "-33.9946",
    Longitude: "18.6953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21454",
    WardLookupId: "438",
    VDNumber: "97100434",
    RegisteredPopulation: "1862",
    VotingStationName: "TUSCANY GLEN HIGH SCHOOL",
    Address: "BLLUE DOWNS RD  TUSCANY GLEN  EERSTERIVIER",
    Latitude: "-34.0027",
    Longitude: "18.6998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21455",
    WardLookupId: "438",
    VDNumber: "97100579",
    RegisteredPopulation: "3091",
    VotingStationName: "PALM PARK PRIMARY SCHOOL",
    Address: "1 HARTOG STREET  MELTON ROSE  EERSTERIVER",
    Latitude: "-33.9956",
    Longitude: "18.72",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21456",
    WardLookupId: "439",
    VDNumber: "97141619",
    RegisteredPopulation: "1889",
    VotingStationName: "SIVILE PRIMARY SCHOOL",
    Address: "60 MORNINGSTAR STR  IKWEZI PARK  KHAYELITSHA",
    Latitude: "-34.0107",
    Longitude: "18.6347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21457",
    WardLookupId: "439",
    VDNumber: "97141620",
    RegisteredPopulation: "1611",
    VotingStationName: "TEMBANI EDUCARE CENTRE",
    Address: "CAPITAL DRIVE  THEMBANI  KHAYELITSHA",
    Latitude: "-34.0146",
    Longitude: "18.6372",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21458",
    WardLookupId: "439",
    VDNumber: "97141642",
    RegisteredPopulation: "1472",
    VotingStationName: "INTLANGANISO SECONDARY SCHOOL",
    Address: "LAWRENCE ROAD  SITE C  KHAYELITSHA",
    Latitude: "-34.0129",
    Longitude: "18.6415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21459",
    WardLookupId: "439",
    VDNumber: "97141653",
    RegisteredPopulation: "3090",
    VotingStationName: "VUSELELA PRIMARY SCHOOL",
    Address: "EAGLE STREET  SITE C  KHAYELITSHA",
    Latitude: "-34.0114",
    Longitude: "18.6397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21460",
    WardLookupId: "439",
    VDNumber: "97141675",
    RegisteredPopulation: "2204",
    VotingStationName: "AFRICAN NATIVE MISSION CHURCH",
    Address: "C616A  SITE C  KHAYELITSHA",
    Latitude: "-34.009",
    Longitude: "18.646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21461",
    WardLookupId: "439",
    VDNumber: "97141686",
    RegisteredPopulation: "2810",
    VotingStationName: "FULANI COMMUNITY HALL",
    Address: "KIYANE CRESCENT  SITE C  KHAYELITSHA",
    Latitude: "-34.0108",
    Longitude: "18.6471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21462",
    WardLookupId: "439",
    VDNumber: "97142014",
    RegisteredPopulation: "1820",
    VotingStationName: "KERIC STEP BY STEP",
    Address: "505555 MPANGELE STREET  KHAYELITSHA SITE C  CAPE TOWN",
    Latitude: "-34.0069",
    Longitude: "18.6435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21463",
    WardLookupId: "439",
    VDNumber: "97142351",
    RegisteredPopulation: "1868",
    VotingStationName: "ENCOTSHENI PRIMARY SCHOOL",
    Address: "/ PRIMROSE ROAD A SECTION   SITE C  KHAYELITSHA",
    Latitude: "-34.0065",
    Longitude: "18.6379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21464",
    WardLookupId: "440",
    VDNumber: "97100063",
    RegisteredPopulation: "4698",
    VotingStationName: "KALKFONTEIN PRIMARY SCHOOL",
    Address: "CNR BELHAR & ISABEL STREET  KALKFONTEIN  BELHAR",
    Latitude: "-33.9429",
    Longitude: "18.6703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21465",
    WardLookupId: "440",
    VDNumber: "97100153",
    RegisteredPopulation: "3316",
    VotingStationName: "KUILSRIVIER TECHNICAL HIGH SCHOOL",
    Address: "DRIEBERGEN STREET  HIGHBURY  CAPE TOWN",
    Latitude: "-33.9498",
    Longitude: "18.6756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21466",
    WardLookupId: "440",
    VDNumber: "97100658",
    RegisteredPopulation: "2491",
    VotingStationName: "HOOFWEG PRIMARY SCHOOL",
    Address: "WATERBLOM STREET  WESBANK  EERSTE RIVER",
    Latitude: "-33.9667",
    Longitude: "18.659",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21467",
    WardLookupId: "440",
    VDNumber: "97100669",
    RegisteredPopulation: "2233",
    VotingStationName: "ST MARKS THE EVANGELIST CHURCH",
    Address: "78 NOOIENSFONTEIN ROAD  KUILSRIVER, CAMELOT  CAPE TOWN",
    Latitude: "-33.961",
    Longitude: "18.6726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21468",
    WardLookupId: "440",
    VDNumber: "97100681",
    RegisteredPopulation: "3055",
    VotingStationName: "WESBANK PRIMARY NO 1",
    Address: "MAIN ROAD  WESBANK  ",
    Latitude: "-33.9738",
    Longitude: "18.6557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21469",
    WardLookupId: "440",
    VDNumber: "97100748",
    RegisteredPopulation: "1597",
    VotingStationName: "WESBANK HIGH SCHOOL",
    Address: "BEAVER  WESBANK, KUILSRIVER  CAPE TOWN",
    Latitude: "-33.9735",
    Longitude: "18.6592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21470",
    WardLookupId: "440",
    VDNumber: "97100759",
    RegisteredPopulation: "2247",
    VotingStationName: "RAINBOW PRIMARY SCHOOL",
    Address: "MAIN ROAD  WESBANK KUILSRIVER  CAPE TOWN",
    Latitude: "-33.962",
    Longitude: "18.6585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21471",
    WardLookupId: "441",
    VDNumber: "97140696",
    RegisteredPopulation: "3085",
    VotingStationName: "EINDHOVEN PRIMARY SCHOOL NO 1",
    Address: "EINDHOVEN STREET  EINDHOVEN  DELFT",
    Latitude: "-33.9836",
    Longitude: "18.6478",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21472",
    WardLookupId: "441",
    VDNumber: "97140708",
    RegisteredPopulation: "2732",
    VotingStationName: "VOORBRUG SECONDARY SCHOOL",
    Address: "VOORBRUG STREET  VOORBRUG,  DELFT",
    Latitude: "-33.9731",
    Longitude: "18.6515",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21473",
    WardLookupId: "441",
    VDNumber: "97140719",
    RegisteredPopulation: "2467",
    VotingStationName: "VERGENOEGD PRIMARY SCHOOL",
    Address: "VERGENOEGD STREET  DELFT, VOORBRUG  CAPE TOWN",
    Latitude: "-33.9666",
    Longitude: "18.6482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21474",
    WardLookupId: "441",
    VDNumber: "97141800",
    RegisteredPopulation: "3781",
    VotingStationName: "DELFT SOUTH PRIMARY SCHOOL",
    Address: "MANGO STREET   DELFT SOUTH  CAPE TOWN",
    Latitude: "-33.9942",
    Longitude: "18.6361",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21475",
    WardLookupId: "441",
    VDNumber: "97141811",
    RegisteredPopulation: "3420",
    VotingStationName: "SIMUNYE HIGH SCHOOL",
    Address: "DELFT MAIN RD  DELFT SOUTH  DELFT",
    Latitude: "-33.985",
    Longitude: "18.6415",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21476",
    WardLookupId: "441",
    VDNumber: "97142463",
    RegisteredPopulation: "846",
    VotingStationName: "DELFT COMMUNITY CENTRE",
    Address: "MAIN ROAD  DELFT  CAPE TOWN",
    Latitude: "-33.9736",
    Longitude: "18.6429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21477",
    WardLookupId: "441",
    VDNumber: "97142621",
    RegisteredPopulation: "1854",
    VotingStationName: "TVS TENT ON CRN DELFT MAIN & FRABRICK STREET",
    Address: "CRN DELFT MAIN & FRABRICK   DELFT  CAPE TOWN",
    Latitude: "-33.9781",
    Longitude: "18.6429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21478",
    WardLookupId: "442",
    VDNumber: "97140900",
    RegisteredPopulation: "3161",
    VotingStationName: "BELLVILLE CRICKET CLUB",
    Address: "DURBAN ROAD  BLOEMHOF  BELLVILLE",
    Latitude: "-33.8797",
    Longitude: "18.6421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21479",
    WardLookupId: "442",
    VDNumber: "97140955",
    RegisteredPopulation: "4358",
    VotingStationName: "STELLENBERG HIGH SCHOOL",
    Address: "CNR PANORAMA & MOUNTAIN VIEW DRIVE  STELLENBERG  DURBANVILLE",
    Latitude: "-33.8635",
    Longitude: "18.6616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21480",
    WardLookupId: "442",
    VDNumber: "97140966",
    RegisteredPopulation: "3139",
    VotingStationName: "EVERSDAL PRIMARY SCHOOL",
    Address: "STEPPING STONES  EVERSDAL  DURBANVILLE",
    Latitude: "-33.862",
    Longitude: "18.6512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21481",
    WardLookupId: "442",
    VDNumber: "97140977",
    RegisteredPopulation: "3932",
    VotingStationName: "GENE LOUW PRIMARY SCHOOL",
    Address: "TULIP STREET  AMANDA GLEN  DURBANVILLE",
    Latitude: "-33.8543",
    Longitude: "18.6585",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21482",
    WardLookupId: "442",
    VDNumber: "97141912",
    RegisteredPopulation: "2341",
    VotingStationName: "FAIRMONT HIGH SCHOOL",
    Address: "MEDWAY   DURBELL  DURBANVILLE",
    Latitude: "-33.8539",
    Longitude: "18.6409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21483",
    WardLookupId: "443",
    VDNumber: "97140573",
    RegisteredPopulation: "2244",
    VotingStationName: "NOEL STANTON CRECHE",
    Address: "1 MYRTLE RD  UITSIG  RAVENSMEAD",
    Latitude: "-33.9381",
    Longitude: "18.6009",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21484",
    WardLookupId: "443",
    VDNumber: "97140618",
    RegisteredPopulation: "2193",
    VotingStationName: "FLORIDA HIGH SCHOOL",
    Address: "STROEBEL  RAVENSMEAD  CAPE TOWN",
    Latitude: "-33.926",
    Longitude: "18.6051",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21485",
    WardLookupId: "443",
    VDNumber: "97141529",
    RegisteredPopulation: "2793",
    VotingStationName: "SYMPHONY SENIOR SECONDARY SCHOOL",
    Address: "SYMPHONY WAY  BELHAR  ",
    Latitude: "-33.944",
    Longitude: "18.6329",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21486",
    WardLookupId: "443",
    VDNumber: "97141530",
    RegisteredPopulation: "2147",
    VotingStationName: "EXCELSIOR SECONDARY SCHOOL",
    Address: "PORTULACA CRESENT  BELHAR  ",
    Latitude: "-33.9452",
    Longitude: "18.6203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21487",
    WardLookupId: "443",
    VDNumber: "97141541",
    RegisteredPopulation: "1949",
    VotingStationName: "BELHAR HIGH SCHOOL",
    Address: "2 SUIKERBOSSIE WAY  BELHAR  ",
    Latitude: "-33.9437",
    Longitude: "18.6118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21488",
    WardLookupId: "443",
    VDNumber: "97141552",
    RegisteredPopulation: "1480",
    VotingStationName: "ERICA PRIMARY SCHOOL",
    Address: "CNR HOMERIA & JASMYN ST.  BELHAR  CAPE TOWN",
    Latitude: "-33.94",
    Longitude: "18.6284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21489",
    WardLookupId: "443",
    VDNumber: "97141710",
    RegisteredPopulation: "1427",
    VotingStationName: "BELHAR PRIMARY SCHOOL",
    Address: "ACANTHUS CIRCLE  BELHAR  BELHAR",
    Latitude: "-33.9486",
    Longitude: "18.6067",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21490",
    WardLookupId: "443",
    VDNumber: "97141901",
    RegisteredPopulation: "706",
    VotingStationName: "TEMPORARY VOTING STATION (MALAWI CAMP TENT)",
    Address: "MALAWI CAMP  BISHOP LAVIS  CAPE TOWN",
    Latitude: "-33.9432",
    Longitude: "18.5939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21491",
    WardLookupId: "443",
    VDNumber: "97142519",
    RegisteredPopulation: "779",
    VotingStationName: "BELVUE PRIMARY SCHOOL",
    Address: "BELHAR  JACARANDA AVENUE",
    Latitude: "-33.9465",
    Longitude: "18.616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21492",
    WardLookupId: "444",
    VDNumber: "97120155",
    RegisteredPopulation: "3126",
    VotingStationName: "BLOUBERG RIDGE PRIMARY SCHOOL",
    Address: "WESSELS ROAD  BLOUBERGRAND  BLAAUBERG",
    Latitude: "-33.8131",
    Longitude: "18.4797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21493",
    WardLookupId: "444",
    VDNumber: "97120166",
    RegisteredPopulation: "4138",
    VotingStationName: "BLOUBERG COMMUNITY HALL",
    Address: "ANDREW FOSTER ROAD  BLAAUWBERG  BLAAUWBERG",
    Latitude: "-33.8011",
    Longitude: "18.4603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21494",
    WardLookupId: "444",
    VDNumber: "97120302",
    RegisteredPopulation: "2357",
    VotingStationName: "MELKBOSSTRAND COUNTRY CLUB",
    Address: "1 ROBBEN ROAD  MELKBOSSTRAND  CAPE TOWN",
    Latitude: "-33.7166",
    Longitude: "18.4487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21495",
    WardLookupId: "444",
    VDNumber: "97120313",
    RegisteredPopulation: "2608",
    VotingStationName: "KOEBERG  LIBRARY",
    Address: "MERCHANT WALK  DUYNEFONTEIN  MELKBOSSTRAND",
    Latitude: "-33.693",
    Longitude: "18.446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21496",
    WardLookupId: "444",
    VDNumber: "97120380",
    RegisteredPopulation: "3510",
    VotingStationName: "SAILS ROAD PARK TENT",
    Address: "SAILS ROAD  BLOUBERG SANDS  CAPE TOWN",
    Latitude: "-33.8032",
    Longitude: "18.4781",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21497",
    WardLookupId: "444",
    VDNumber: "97120469",
    RegisteredPopulation: "3569",
    VotingStationName: "MELKBOSSTRAND HIGH SCHOOL",
    Address: "C/O OTTO DU PLESSIS & 11TH AVE  MELKBOSSTRAND  CAPE TOWN",
    Latitude: "-33.73",
    Longitude: "18.4434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21498",
    WardLookupId: "445",
    VDNumber: "97140416",
    RegisteredPopulation: "2191",
    VotingStationName: "LAVIS UNITING REFORMED CHURCH",
    Address: "CNR LAVIS DRIVE AND KOPPIESDAM  BISHOP LAVIS  CAPE TOWN",
    Latitude: "-33.9448",
    Longitude: "18.5811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21499",
    WardLookupId: "445",
    VDNumber: "97140461",
    RegisteredPopulation: "4398",
    VotingStationName: "BERGVILLE PRIMARY SCHOOL",
    Address: "LEEUBERG ROAD  BISHOP LAVIS  ",
    Latitude: "-33.9529",
    Longitude: "18.5821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21500",
    WardLookupId: "445",
    VDNumber: "97140494",
    RegisteredPopulation: "2122",
    VotingStationName: "JOHN RAMSAY HIGH SCHOOL",
    Address: "LAVIS DRIVE  BISHOP LAVIS  ",
    Latitude: "-33.9492",
    Longitude: "18.5873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21501",
    WardLookupId: "445",
    VDNumber: "97140517",
    RegisteredPopulation: "2503",
    VotingStationName: "GREENLANDS PRIMARY SCHOOL",
    Address: "40 SKY ROAD  BISHOP LAVIS  ",
    Latitude: "-33.9508",
    Longitude: "18.5922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21502",
    WardLookupId: "445",
    VDNumber: "97141776",
    RegisteredPopulation: "882",
    VotingStationName: "FREEDOM FARM COMMUNITY CENTRE",
    Address: "AIRPORT INFORMAL SETTLEMENT  MATROOSFONTEIN  CAPE TOWN",
    Latitude: "-33.9502",
    Longitude: "18.6014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21503",
    WardLookupId: "445",
    VDNumber: "97142632",
    RegisteredPopulation: "3930",
    VotingStationName: "MAMGCINA EDUCARE",
    Address: "CRN DRAKENSBERG AND AMOUR STR  DELFT N2 GATEWAY  CAPE TOWN",
    Latitude: "-33.9626",
    Longitude: "18.6326",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21504",
    WardLookupId: "446",
    VDNumber: "97140539",
    RegisteredPopulation: "1782",
    VotingStationName: "CRAVENBY SECONDARY SCHOOL",
    Address: "YORK STREET  CRAVENBY ESTATE  RAVENSMEAD",
    Latitude: "-33.9249",
    Longitude: "18.5875",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21505",
    WardLookupId: "446",
    VDNumber: "97140540",
    RegisteredPopulation: "1617",
    VotingStationName: "ST ANDREWS HIGH SCHOOL",
    Address: "OWEN RD  CONNAUGHT ESTATE  ELSIES RIVER",
    Latitude: "-33.9346",
    Longitude: "18.5927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21506",
    WardLookupId: "446",
    VDNumber: "97140551",
    RegisteredPopulation: "1920",
    VotingStationName: "SENDING VIR CHRISTUS KERK",
    Address: "CNR 14TH AVENUE & RALPH STREET  RAVENSMEAD  ",
    Latitude: "-33.928",
    Longitude: "18.5966",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21507",
    WardLookupId: "446",
    VDNumber: "97140562",
    RegisteredPopulation: "2863",
    VotingStationName: "CALVARY BAPTIST CHURCH",
    Address: "EUREKA  CAPE TOWN",
    Latitude: "-33.9383",
    Longitude: "18.5924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21508",
    WardLookupId: "446",
    VDNumber: "97140584",
    RegisteredPopulation: "1965",
    VotingStationName: "TYGERSIG PRIMÃŠRE SKOOL",
    Address: "JAKARANDA   UITSIG  CAPE TOWN",
    Latitude: "-33.9338",
    Longitude: "18.5989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21509",
    WardLookupId: "446",
    VDNumber: "97140595",
    RegisteredPopulation: "1627",
    VotingStationName: "VORENTOE PRIMARY SCHOOL",
    Address: "12 SANDHURST STR  RAVENSMEAD  ",
    Latitude: "-33.9267",
    Longitude: "18.6001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21510",
    WardLookupId: "446",
    VDNumber: "97140607",
    RegisteredPopulation: "2033",
    VotingStationName: "WEBNER STREET PRIMARY SCHOOL",
    Address: "WEBNER STREET  RAVENSMEAD  ",
    Latitude: "-33.9236",
    Longitude: "18.5927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21511",
    WardLookupId: "446",
    VDNumber: "97141596",
    RegisteredPopulation: "1682",
    VotingStationName: "EVANGELICAL LUTHERAN CHURCH",
    Address: "35TH AVENUE  ELSIES RIVER  ",
    Latitude: "-33.9337",
    Longitude: "18.5871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21512",
    WardLookupId: "446",
    VDNumber: "97141765",
    RegisteredPopulation: "1885",
    VotingStationName: "UITSIG PRIMARY SCHOOL",
    Address: "UITSIG AVENUE  UITSIG  RAVENSMEAD",
    Latitude: "-33.9373",
    Longitude: "18.5994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21513",
    WardLookupId: "447",
    VDNumber: "97140157",
    RegisteredPopulation: "2610",
    VotingStationName: "FILIA SCHOOL",
    Address: "107 MILTON  VRIZEE  GOODWOOD",
    Latitude: "-33.902",
    Longitude: "18.562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21514",
    WardLookupId: "447",
    VDNumber: "97140168",
    RegisteredPopulation: "2936",
    VotingStationName: "DE VRIJZEE PRIMARY SCHOOL",
    Address: "MOLTENO STR  DE VRIJZEE  GOODWOOD",
    Latitude: "-33.9067",
    Longitude: "18.5647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21515",
    WardLookupId: "447",
    VDNumber: "97140180",
    RegisteredPopulation: "1935",
    VotingStationName: "ROBIN HOOD CRECHE",
    Address: "WARNER CRESCENT   RIVERTON   CAPE TOWN",
    Latitude: "-33.9159",
    Longitude: "18.575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21516",
    WardLookupId: "447",
    VDNumber: "97140203",
    RegisteredPopulation: "2394",
    VotingStationName: "ELDENE PRIMARY SCHOOL",
    Address: "CNR DERBY STR & 10TH AVENUE  AVON  ELSIES RIVIER",
    Latitude: "-33.9196",
    Longitude: "18.5665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21517",
    WardLookupId: "447",
    VDNumber: "97140214",
    RegisteredPopulation: "2425",
    VotingStationName: "LEONSDALE PRIMARY SCHOOL",
    Address: "- 26 TH STREET  LEONSDALE  ELSIES RIVER",
    Latitude: "-33.9185",
    Longitude: "18.5788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21518",
    WardLookupId: "447",
    VDNumber: "97140641",
    RegisteredPopulation: "3139",
    VotingStationName: "DUTCH REFORMED CHURCH PAROW VALLEY",
    Address: "FREDERICK STREET  PAROW VALLEY  PAROW",
    Latitude: "-33.9143",
    Longitude: "18.5931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21519",
    WardLookupId: "447",
    VDNumber: "97140685",
    RegisteredPopulation: "2611",
    VotingStationName: "PAROW CIVIC CENTRE",
    Address: "TALLENT STREET  PAROW  ",
    Latitude: "-33.906",
    Longitude: "18.581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21520",
    WardLookupId: "447",
    VDNumber: "97142531",
    RegisteredPopulation: "849",
    VotingStationName: "PRESIDENT HIGH SCHOOL",
    Address: "TYGERBERG  GOODWOOD  CITY OF CAPE TOWN [CAPE TOWN]",
    Latitude: "-33.9035",
    Longitude: "18.5698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21521",
    WardLookupId: "448",
    VDNumber: "97140113",
    RegisteredPopulation: "3115",
    VotingStationName: "NG KERK GOODWOODPARK",
    Address: "216 TOWNSEND STREET  GOODWOOD PARK  GOODWOOD",
    Latitude: "-33.8972",
    Longitude: "18.5388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21522",
    WardLookupId: "448",
    VDNumber: "97140124",
    RegisteredPopulation: "3169",
    VotingStationName: "GOODWOOD PARK PRIMARY SCHOOL",
    Address: "BEAUFORT STREET  GOODWOOD  ",
    Latitude: "-33.9028",
    Longitude: "18.5427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21523",
    WardLookupId: "448",
    VDNumber: "97140135",
    RegisteredPopulation: "2726",
    VotingStationName: "EMMANUEL CHRISTIAN ACADEMY",
    Address: "7 DINGLE WAY  GOODWOOD  GOODWOOD",
    Latitude: "-33.9116",
    Longitude: "18.5422",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21524",
    WardLookupId: "448",
    VDNumber: "97140146",
    RegisteredPopulation: "3248",
    VotingStationName: "JG MEIRING HIGH SCHOOL",
    Address: "MERRIMANWAY  GOODWOOD  GOODWOOD",
    Latitude: "-33.9089",
    Longitude: "18.5496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21525",
    WardLookupId: "448",
    VDNumber: "97142250",
    RegisteredPopulation: "977",
    VotingStationName: "GOODWOOD ANGLING CLUB",
    Address: "HAMILTON STREET  GOODWOOD",
    Latitude: "-33.9025",
    Longitude: "18.5516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21526",
    WardLookupId: "449",
    VDNumber: "97140225",
    RegisteredPopulation: "2381",
    VotingStationName: "NORWOOD CENTRAL PRIMARY",
    Address: "HALT ROAD  ELSIES RIVIER  ",
    Latitude: "-33.9272",
    Longitude: "18.573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21527",
    WardLookupId: "449",
    VDNumber: "97140236",
    RegisteredPopulation: "2404",
    VotingStationName: "ELSIES RIVIER LIBRARY",
    Address: "CNR HALT ROAD & SALBERAU AVENUE  ELSIES RIVER  ",
    Latitude: "-33.9289",
    Longitude: "18.574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21528",
    WardLookupId: "449",
    VDNumber: "97140405",
    RegisteredPopulation: "2309",
    VotingStationName: "ELNOR PRIMARY SCHOOL",
    Address: "EIGHT STR  ELSIES RIVIER  ",
    Latitude: "-33.9324",
    Longitude: "18.5755",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21529",
    WardLookupId: "449",
    VDNumber: "97140427",
    RegisteredPopulation: "2315",
    VotingStationName: "EDWARD PRIMARY SCHOOL",
    Address: "OWEN ROAD  ELSIES RIVIER  ",
    Latitude: "-33.937",
    Longitude: "18.5825",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21530",
    WardLookupId: "449",
    VDNumber: "97140438",
    RegisteredPopulation: "1374",
    VotingStationName: "MARIAN HIGH SCHOOL",
    Address: "35TH AVENUE  CLARKES ESTATE  ELSIES RIVIER",
    Latitude: "-33.9391",
    Longitude: "18.5871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21531",
    WardLookupId: "449",
    VDNumber: "97140506",
    RegisteredPopulation: "1911",
    VotingStationName: "CLARKE PRIMARY SCHOOL",
    Address: "MELTON RD  CLARKE ESTATE  ELSIES RIVER",
    Latitude: "-33.9425",
    Longitude: "18.5866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21532",
    WardLookupId: "449",
    VDNumber: "97140528",
    RegisteredPopulation: "2383",
    VotingStationName: "J.S. KLOPPER PRIMARY SCHOOL",
    Address: "34TH AVENUE  ELSIES RIVER  ",
    Latitude: "-33.9329",
    Longitude: "18.5809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21533",
    WardLookupId: "449",
    VDNumber: "97142429",
    RegisteredPopulation: "1460",
    VotingStationName: "AVONWOOD SPORTS CLUB",
    Address: "34TH AVE  AVONWOOD  CAPE TOWN",
    Latitude: "-33.9222",
    Longitude: "18.5812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21534",
    WardLookupId: "449",
    VDNumber: "97142542",
    RegisteredPopulation: "717",
    VotingStationName: "ELSIES RIVER HIGH SCHOOL",
    Address: "HALT   ELSIES RIVER  CITY OF CAPE TOWN [CAPE TOWN]",
    Latitude: "-33.9241",
    Longitude: "18.5691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21535",
    WardLookupId: "450",
    VDNumber: "97120188",
    RegisteredPopulation: "2083",
    VotingStationName: "SAXON SEA EDUCARE CENTRE",
    Address: "CNR HERMES AND GROSVENOR ROAD  ATLANTIS  CAPE TOWN",
    Latitude: "-33.5501",
    Longitude: "18.487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21536",
    WardLookupId: "450",
    VDNumber: "97120212",
    RegisteredPopulation: "1055",
    VotingStationName: "WESFLEUR PRIMARY SCHOOL",
    Address: "GROSVENOR AVE  AVONDALE  ATLANTIS",
    Latitude: "-33.5713",
    Longitude: "18.4879",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21537",
    WardLookupId: "450",
    VDNumber: "97120256",
    RegisteredPopulation: "2094",
    VotingStationName: "AVONDALE CIVIC CENTRE",
    Address: " GROSVENOR DRIVE  AVONDALE  ATLANTIS",
    Latitude: "-33.5712",
    Longitude: "18.486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21538",
    WardLookupId: "450",
    VDNumber: "97120267",
    RegisteredPopulation: "2049",
    VotingStationName: "PARKVIEW PRIMARY SCHOOL",
    Address: "PALMER  ATLANTIS  ATLANTIS",
    Latitude: "-33.566",
    Longitude: "18.4889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21539",
    WardLookupId: "450",
    VDNumber: "97120289",
    RegisteredPopulation: "1325",
    VotingStationName: "METHODIST CHURCH OF SA ATLANTIS",
    Address: "ATHENS STREET  ATLANTIS  ATLANTIS",
    Latitude: "-33.5582",
    Longitude: "18.4949",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21540",
    WardLookupId: "450",
    VDNumber: "97120290",
    RegisteredPopulation: "2050",
    VotingStationName: "MOUNT VIEW CRECHE",
    Address: "CNR ZEELAND & VALLEY STREETS  SAXON SEA  ATLANTIS",
    Latitude: "-33.5559",
    Longitude: "18.483",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21541",
    WardLookupId: "450",
    VDNumber: "97120335",
    RegisteredPopulation: "2021",
    VotingStationName: "MORAVIAN CHURCH OF SOUTH AFRICA (LOBEN HALL)",
    Address: "CAPE TOWN  MAMRE  57 MAIN ROAD",
    Latitude: "-33.5057",
    Longitude: "18.4711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21542",
    WardLookupId: "450",
    VDNumber: "97120346",
    RegisteredPopulation: "1993",
    VotingStationName: "MAMRE - PRIMARY SCHOOL",
    Address: "JOHANNES  MAMRE  ATLANTIS",
    Latitude: "-33.5153",
    Longitude: "18.4791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21543",
    WardLookupId: "450",
    VDNumber: "97120436",
    RegisteredPopulation: "916",
    VotingStationName: "PELLA PRIMARY SCHOOL",
    Address: "MAIN ROAD  PELLA,  PELLA",
    Latitude: "-33.5421",
    Longitude: "18.5194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21544",
    WardLookupId: "450",
    VDNumber: "97120559",
    RegisteredPopulation: "1026",
    VotingStationName: "MASAKHANA EDUCARE CENTRE",
    Address: "FIGILANTE STREET  SAXON SEA  ATLANTIS",
    Latitude: "-33.5529",
    Longitude: "18.4853",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21545",
    WardLookupId: "451",
    VDNumber: "97140179",
    RegisteredPopulation: "3880",
    VotingStationName: "DE WAVERN PRIMARY SCHOOL",
    Address: "HUGENOOT  RUYTERWACHT  RYTERWACHT",
    Latitude: "-33.9198",
    Longitude: "18.5591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21546",
    WardLookupId: "451",
    VDNumber: "97140191",
    RegisteredPopulation: "1580",
    VotingStationName: "BADISA SKIEREILAND MAATSKAPLIKE DIENSTE",
    Address: "91 6TH STREET  ELSIES RIVER  ELSIES RIVER",
    Latitude: "-33.9235",
    Longitude: "18.5654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21547",
    WardLookupId: "451",
    VDNumber: "97140326",
    RegisteredPopulation: "2460",
    VotingStationName: "KALKSTEENFONTEIN PRIMARY SCHOOL",
    Address: "SALVIA STREET  KALKSTEENFONTEIN  ",
    Latitude: "-33.9465",
    Longitude: "18.5661",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21548",
    WardLookupId: "451",
    VDNumber: "97140359",
    RegisteredPopulation: "1809",
    VotingStationName: "GOODHOPE EDUCARE (VALHALLA PARK)",
    Address: "61 PAULUS STREET  VALHALLA PARK  CAPE TOWN",
    Latitude: "-33.9511",
    Longitude: "18.573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21549",
    WardLookupId: "451",
    VDNumber: "97140371",
    RegisteredPopulation: "2171",
    VotingStationName: "RANGE ROAD PRIMARY SCHOOL",
    Address: "FOREST RD  ELSIES RIVER  ",
    Latitude: "-33.9359",
    Longitude: "18.5717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21550",
    WardLookupId: "451",
    VDNumber: "97140382",
    RegisteredPopulation: "2610",
    VotingStationName: "ELSBURY PRIMARY SCHOOL",
    Address: "ELSBURY STREET  MATROOSFONTEIN  ELSIES RIVER",
    Latitude: "-33.9388",
    Longitude: "18.5738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21551",
    WardLookupId: "451",
    VDNumber: "97140393",
    RegisteredPopulation: "2348",
    VotingStationName: "RIVERTON PRIMARY SCHOOL",
    Address: "5 WAYSIDE RD  BISHOP LAVIS  ",
    Latitude: "-33.945",
    Longitude: "18.5706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21552",
    WardLookupId: "452",
    VDNumber: "97140281",
    RegisteredPopulation: "4702",
    VotingStationName: "MODDERDAM HIGH SCHOOL",
    Address: "BONTEHEUWEL AVENUE  BONTEHEUWEL  ",
    Latitude: "-33.9556",
    Longitude: "18.5597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21553",
    WardLookupId: "452",
    VDNumber: "97140337",
    RegisteredPopulation: "1261",
    VotingStationName: "INCY WINCY COLLEGE",
    Address: "15 SONNY LEON STREET  CHARLESVILLE  MONTANA",
    Latitude: "-33.9616",
    Longitude: "18.5653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21554",
    WardLookupId: "452",
    VDNumber: "97140360",
    RegisteredPopulation: "1839",
    VotingStationName: "MONTEVIDEO PRIMARY SCHOOL",
    Address: "SKOOL STREET  DURRHEIM ESTATE  MONTANA",
    Latitude: "-33.9593",
    Longitude: "18.572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21555",
    WardLookupId: "452",
    VDNumber: "97140472",
    RegisteredPopulation: "2209",
    VotingStationName: "NOOITGEDACHT PRIMARY SCHOOL",
    Address: "HEILBOT WAY, BISHOP LAVIS  BISHOP LAVIS  CAPE TOWN",
    Latitude: "-33.9551",
    Longitude: "18.5866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21556",
    WardLookupId: "452",
    VDNumber: "97140483",
    RegisteredPopulation: "2430",
    VotingStationName: "THE PEOPLES CHURCH (VALHALLA PARK)",
    Address: "CNR SIMON & AGNES STREET  VALHALLA PARK  CAPE TOWN",
    Latitude: "-33.9543",
    Longitude: "18.5738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21557",
    WardLookupId: "452",
    VDNumber: "97141899",
    RegisteredPopulation: "1856",
    VotingStationName: "NG CHURCH HALL (VALHALLA PARK)",
    Address: "CNR JOHANNES & DOREEN STREET  VALHALLA PARK  CAPE TOWN",
    Latitude: "-33.9566",
    Longitude: "18.5692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21558",
    WardLookupId: "452",
    VDNumber: "97142553",
    RegisteredPopulation: "1059",
    VotingStationName: "KLIPFONTEIN PRIMARY SCHOOL",
    Address: "OUDEHOUT   BONTEHEUWEL  CITY OF CAPE TOWN [CAPE TOWN]",
    Latitude: "-33.9603",
    Longitude: "18.5618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21559",
    WardLookupId: "453",
    VDNumber: "97120199",
    RegisteredPopulation: "802",
    VotingStationName: "CAMPHILL VILLAGE",
    Address: "/ KLEIN DASSENBERG ROAD  SAXONWOLD  ATLANTIS",
    Latitude: "-33.5909",
    Longitude: "18.5653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21560",
    WardLookupId: "453",
    VDNumber: "97120201",
    RegisteredPopulation: "2973",
    VotingStationName: "KERRIA PRIMARY SCHOOL ( HALL)",
    Address: "76 KERRIA AVENUE, PROTEA PARK  ATLANTIS  ATLANTIS",
    Latitude: "-33.5766",
    Longitude: "18.4998",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21561",
    WardLookupId: "453",
    VDNumber: "97120223",
    RegisteredPopulation: "3596",
    VotingStationName: "ROBINVALE HIGH SCHOOL",
    Address: "FISKAAL STR  ROBINVALE  ATLANTIS",
    Latitude: "-33.5661",
    Longitude: "18.5045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21562",
    WardLookupId: "453",
    VDNumber: "97120245",
    RegisteredPopulation: "2234",
    VotingStationName: "PROTEA PARK PRIMARY SCHOOL",
    Address: "MAGNOLIA ROAD  PROTEA PARK  ATLANTIS",
    Latitude: "-33.5717",
    Longitude: "18.4977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21563",
    WardLookupId: "453",
    VDNumber: "97120278",
    RegisteredPopulation: "2556",
    VotingStationName: "EMMANUEL DAY CARE CENTRE",
    Address: "NOTTINGHAM  ATLANTIS  ATLANTIS",
    Latitude: "-33.5569",
    Longitude: "18.4958",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21564",
    WardLookupId: "453",
    VDNumber: "97120357",
    RegisteredPopulation: "5111",
    VotingStationName: "THE METHODIST CHURCH OF SA",
    Address: "WITSAND  ATLANTIS  CAPE TOWN",
    Latitude: "-33.5908",
    Longitude: "18.5027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21565",
    WardLookupId: "453",
    VDNumber: "97120447",
    RegisteredPopulation: "1672",
    VotingStationName: "GROSVENOR PRIMARY SCHOOL",
    Address: "GROSVENOR DRIVE  SHERWOOD  ATLANTIS",
    Latitude: "-33.5494",
    Longitude: "18.491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21566",
    WardLookupId: "453",
    VDNumber: "97120458",
    RegisteredPopulation: "485",
    VotingStationName: "ROBINHILL SPECIAL SCHOOL(DAWN SCHOOL)",
    Address: "CNR MONTREAL DR & SAMPSON WAY  BEACON HILL  ATLANTIS",
    Latitude: "-33.5632",
    Longitude: "18.5064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21567",
    WardLookupId: "454",
    VDNumber: "97091837",
    RegisteredPopulation: "2942",
    VotingStationName: "NALUXOLO PRIMARY SCHOOL",
    Address: "HELEN JOSEPH STREET  SAMORA MACHEL",
    Latitude: "-34.0161",
    Longitude: "18.5727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21568",
    WardLookupId: "454",
    VDNumber: "97092254",
    RegisteredPopulation: "3595",
    VotingStationName: "WELTEVREDEN VALLEY CORE PRIMARY",
    Address: "OLIVER TAMBO DRIVE  PHILLIPI  ",
    Latitude: "-34.0189",
    Longitude: "18.5782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21569",
    WardLookupId: "454",
    VDNumber: "97092300",
    RegisteredPopulation: "3520",
    VotingStationName: "SWEET HOMES FARM",
    Address: "OFF VANGUARD DRIVE  PHILIPPI  ",
    Latitude: "-34.0038",
    Longitude: "18.5601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21570",
    WardLookupId: "454",
    VDNumber: "97092322",
    RegisteredPopulation: "2372",
    VotingStationName: "SOPHUMELELA SECONDARY SCHOOL",
    Address: "C/O VANGUARD & OLIVER TAMBO DRIVE  SAMORA MACHEL  CAPE TOWN",
    Latitude: "-34.0185",
    Longitude: "18.5711",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21571",
    WardLookupId: "454",
    VDNumber: "97092333",
    RegisteredPopulation: "2714",
    VotingStationName: "ZISUKHANYO SENIOR SECONDARY SCHOOL",
    Address: "OLIVER TAMBO DRIVE  SAMORA MACHEL  ",
    Latitude: "-34.018",
    Longitude: "18.5786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21572",
    WardLookupId: "454",
    VDNumber: "97092377",
    RegisteredPopulation: "2572",
    VotingStationName: "SAMORA MACHEL PRIMARY SCHOOL",
    Address: "LANGA MASSACRE STREET  WELTEVREDEN VALLEY  ",
    Latitude: "-34.0209",
    Longitude: "18.5844",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21573",
    WardLookupId: "455",
    VDNumber: "97091590",
    RegisteredPopulation: "2914",
    VotingStationName: "NOLUFEFE COMMUNITY CRECHE",
    Address: "2979 MBOVANE STREET  VILLAGE 4  PHILLIPI",
    Latitude: "-34.0114",
    Longitude: "18.5809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21574",
    WardLookupId: "455",
    VDNumber: "97091769",
    RegisteredPopulation: "2705",
    VotingStationName: "ST JOHNS CHURCH",
    Address: "1685 NTSIKIZI STREET   BROWNS FARM, PHILIPI  CAPE TOWN",
    Latitude: "-34.0106",
    Longitude: "18.5885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21575",
    WardLookupId: "455",
    VDNumber: "97091770",
    RegisteredPopulation: "1657",
    VotingStationName: "MASIVUKE PRIMARY SCHOOL",
    Address: "2398 NTAMBANANE STREET  BROWNS FARM  PHILIPPI",
    Latitude: "-34.0106",
    Longitude: "18.5823",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21576",
    WardLookupId: "455",
    VDNumber: "97091781",
    RegisteredPopulation: "3335",
    VotingStationName: "MZAMOMHLE PRIMARY SCHOOL",
    Address: "SAGONODA STREET  PHILIPPI  CAPE TOWN",
    Latitude: "-34.006",
    Longitude: "18.589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21577",
    WardLookupId: "455",
    VDNumber: "97091972",
    RegisteredPopulation: "3510",
    VotingStationName: "AMY BHIEL ENRICHMENT CENTRE",
    Address: "H54 KOBODI ROAD  PHILLIPI  PHILLIPI",
    Latitude: "-34.0035",
    Longitude: "18.5855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21578",
    WardLookupId: "455",
    VDNumber: "97092423",
    RegisteredPopulation: "2282",
    VotingStationName: "SINETHEMBA SENIOR SECONDARY SCHOOL",
    Address: "SAQWITYI ROAD  PHILLIPI",
    Latitude: "-34.0113",
    Longitude: "18.5903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21579",
    WardLookupId: "455",
    VDNumber: "97093367",
    RegisteredPopulation: "1409",
    VotingStationName: "NTSHONA PRIMARY SCHOOL",
    Address: "SAGODOLA  PHILLIPI  CITY OF CAPE TOWN [CAPE TOWN]",
    Latitude: "-34.0034",
    Longitude: "18.5915",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21580",
    WardLookupId: "456",
    VDNumber: "97091736",
    RegisteredPopulation: "4011",
    VotingStationName: "PHAKAMA HIGH SCHOOL",
    Address: "SINQOLAMTHI ROAD  LOWER CROSSROADS  PHILIPPI",
    Latitude: "-34.0093",
    Longitude: "18.6128",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21581",
    WardLookupId: "456",
    VDNumber: "97091758",
    RegisteredPopulation: "4316",
    VotingStationName: "KWAFAKU PRIMARY SCHOOL",
    Address: "CNR FELISI & NGQWANGI STREETS  LOWER CROSSROADS  PHILLIPI EAST",
    Latitude: "-34.0111",
    Longitude: "18.6189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21582",
    WardLookupId: "456",
    VDNumber: "97092175",
    RegisteredPopulation: "1151",
    VotingStationName: "KLIPFONTEIN METHODIST PRIMARY SCHOOL",
    Address: "DISA ROAD  PHILLIPI EAST  ",
    Latitude: "-33.9994",
    Longitude: "18.6181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21583",
    WardLookupId: "456",
    VDNumber: "97092186",
    RegisteredPopulation: "1177",
    VotingStationName: "UNITING PRESBYTERIAN CHURCH IN SA",
    Address: "SANGOMA ROAD  PHILLIPI EAST  PHILLIPI EAST",
    Latitude: "-34.0041",
    Longitude: "18.6249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21584",
    WardLookupId: "456",
    VDNumber: "97092265",
    RegisteredPopulation: "2367",
    VotingStationName: "PHILLIPI RESOURCE CENTRE",
    Address: "NTABANKULU  PHILLIPI  CITY OF CAPE TOWN [CAPE TOWN]",
    Latitude: "-34.0149",
    Longitude: "18.6078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21585",
    WardLookupId: "456",
    VDNumber: "97092355",
    RegisteredPopulation: "1560",
    VotingStationName: "SINOXOLO EDUCARE CENTRE",
    Address: "CNR THABO MBEKI ROAD  PHILLIPI  ",
    Latitude: "-33.9997",
    Longitude: "18.6241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21586",
    WardLookupId: "456",
    VDNumber: "97092412",
    RegisteredPopulation: "2015",
    VotingStationName: "VUKANI PRIMARY SCHOOL",
    Address: "CNR SINQOLAMTHI RD&LOWER X RDS  PHILLIPI EAST  ",
    Latitude: "-34.0098",
    Longitude: "18.6129",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21587",
    WardLookupId: "456",
    VDNumber: "97093389",
    RegisteredPopulation: "1481",
    VotingStationName: "PHANDULWAZI HIGH SCHOOL",
    Address: "NGWANGI STREET  LOWER CROSS ROADS  CAPE TOWN",
    Latitude: "-34.0061",
    Longitude: "18.6241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21588",
    WardLookupId: "456",
    VDNumber: "97093480",
    RegisteredPopulation: "1798",
    VotingStationName: "LIKAMVA CHRISTIAN EDUCARE",
    Address: "5533 PROTEA ROAD,MARIKANA PHILLIPI  PHILLIPI EAST  CAPE TOWN",
    Latitude: "-34.0021",
    Longitude: "18.6098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21589",
    WardLookupId: "456",
    VDNumber: "97093503",
    RegisteredPopulation: "1074",
    VotingStationName: "LIFE-CENTRE APOSTOLIC CHURCH(TENT)",
    Address: "MARIKANA  PHILLIPI EAST  CAPE TOWN",
    Latitude: "-34.0046",
    Longitude: "18.6163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21590",
    WardLookupId: "457",
    VDNumber: "97091949",
    RegisteredPopulation: "3093",
    VotingStationName: "KUYAKHANYA PRIMARY SCHOOL",
    Address: "CNR EISLEBEN & STULO ROAD  OLD CROSSROADS  CITY OF CAPE TOWN",
    Latitude: "-33.9915",
    Longitude: "18.5919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21591",
    WardLookupId: "457",
    VDNumber: "97091950",
    RegisteredPopulation: "2798",
    VotingStationName: "IMBASA PRIMARY SCHOOL",
    Address: "NEW EISLEBEN ROAD  OLD CROSSROADS",
    Latitude: "-33.9969",
    Longitude: "18.5923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21592",
    WardLookupId: "457",
    VDNumber: "97091961",
    RegisteredPopulation: "2139",
    VotingStationName: "SALVATION ARMY",
    Address: "UNATHI ROAD  OLD CROSS ROADS  CITY OF CAPE TOWN",
    Latitude: "-33.9919",
    Longitude: "18.5971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21593",
    WardLookupId: "457",
    VDNumber: "97092030",
    RegisteredPopulation: "2556",
    VotingStationName: "MFESANE COMMUNITY CENTRE",
    Address: "5 OLD KLIPFONTEIN ROAD  OLD CROSSROADS  ",
    Latitude: "-33.9926",
    Longitude: "18.6023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21594",
    WardLookupId: "457",
    VDNumber: "97092041",
    RegisteredPopulation: "4037",
    VotingStationName: "SIKELELA IMIZAMO PRIMARY SCHOOL",
    Address: "LANSDOWNE ROAD  OLD CROSSROADS  ",
    Latitude: "-33.9972",
    Longitude: "18.605",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21595",
    WardLookupId: "458",
    VDNumber: "97091455",
    RegisteredPopulation: "1137",
    VotingStationName: "WALTER TEKA PRIMARY SCHOOL",
    Address: "4TH STEEET  NYANGA  ",
    Latitude: "-33.9943",
    Longitude: "18.5809",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21596",
    WardLookupId: "458",
    VDNumber: "97091848",
    RegisteredPopulation: "2196",
    VotingStationName: "OSCAR MPETHA HIGH SCHOOL",
    Address: "EMMS DRIVE  NYANGA  ",
    Latitude: "-33.9941",
    Longitude: "18.5837",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21597",
    WardLookupId: "458",
    VDNumber: "97091882",
    RegisteredPopulation: "2666",
    VotingStationName: "SOYISILE PRE-SCHOOL",
    Address: "BUSH ROAD  HLAZO VILLAGE  NYANGA EAST",
    Latitude: "-33.9874",
    Longitude: "18.5855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21598",
    WardLookupId: "458",
    VDNumber: "97091916",
    RegisteredPopulation: "1970",
    VotingStationName: "LINGE PRIMARY SCHOOL",
    Address: "SIBINI AVENUE  NYANGA  ",
    Latitude: "-33.9983",
    Longitude: "18.5866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21599",
    WardLookupId: "458",
    VDNumber: "97091927",
    RegisteredPopulation: "2714",
    VotingStationName: "ASSEMBLY OF GOD",
    Address: "CNRZWELITSHA DRIVE &SINE DRIVE  NYANGA  CAPE TOWN",
    Latitude: "-33.9944",
    Longitude: "18.5876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21600",
    WardLookupId: "458",
    VDNumber: "97091938",
    RegisteredPopulation: "1337",
    VotingStationName: "MONWABISI CLUB",
    Address: "E977 KUQALA AVENUE  NYANGA EAST  ",
    Latitude: "-33.9987",
    Longitude: "18.5887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21601",
    WardLookupId: "458",
    VDNumber: "97092142",
    RegisteredPopulation: "1974",
    VotingStationName: "HLENGISA PRIMARY SCHOOL",
    Address: "HLATI ROAD  WHIE CITY  NYANGA",
    Latitude: "-33.9901",
    Longitude: "18.5889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21602",
    WardLookupId: "459",
    VDNumber: "97091141",
    RegisteredPopulation: "1518",
    VotingStationName: "SIVIWE PRIMARY SCHOOL",
    Address: "NY 82  GUGULETU  CAPE TOWN",
    Latitude: "-33.9913",
    Longitude: "18.5694",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21603",
    WardLookupId: "459",
    VDNumber: "97091152",
    RegisteredPopulation: "1398",
    VotingStationName: "FULL GOSPEL CHURCH",
    Address: "NY 97  GUGULETU  ",
    Latitude: "-33.9984",
    Longitude: "18.5725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21604",
    WardLookupId: "459",
    VDNumber: "97091163",
    RegisteredPopulation: "1750",
    VotingStationName: "SONWABO PRIMARY SCHOOL",
    Address: "NY 73 SECTION  GUGULETHU  CAPE TOWN",
    Latitude: "-33.9995",
    Longitude: "18.5681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21605",
    WardLookupId: "459",
    VDNumber: "97091185",
    RegisteredPopulation: "2337",
    VotingStationName: "EYETHU EDUCARE CENTRE",
    Address: "CNR J NONTULO & KOORNHOF STREETS  NEW CROSS ROADS  NYANGA",
    Latitude: "-33.9982",
    Longitude: "18.5774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21606",
    WardLookupId: "459",
    VDNumber: "97091196",
    RegisteredPopulation: "1569",
    VotingStationName: "ANDILE PRIAMRY SCHOOL",
    Address: "KOORNHOF STREET  CAPE  NYANGA",
    Latitude: "-33.9974",
    Longitude: "18.5749",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21607",
    WardLookupId: "459",
    VDNumber: "97091208",
    RegisteredPopulation: "2311",
    VotingStationName: "ULWAZI INFORMATION CENTRE",
    Address: "NCEBA AVENUE  NEW CROSSROADS  CAPE TOWN",
    Latitude: "-33.9911",
    Longitude: "18.5762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21608",
    WardLookupId: "459",
    VDNumber: "97092131",
    RegisteredPopulation: "2154",
    VotingStationName: "NOBANTU PRIMARY SCHOOL",
    Address: "NY 89  GUGULETU  ",
    Latitude: "-33.9962",
    Longitude: "18.57",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21609",
    WardLookupId: "460",
    VDNumber: "97090083",
    RegisteredPopulation: "3384",
    VotingStationName: "PHAKAMA COMMUNITY HEALTH CENTRE",
    Address: "NY 78  (KTC)  NYANGA",
    Latitude: "-33.9878",
    Longitude: "18.5744",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21610",
    WardLookupId: "460",
    VDNumber: "97090151",
    RegisteredPopulation: "4599",
    VotingStationName: "MVULA PRIMARY SCHOOL",
    Address: "1 MILLER STREET  MILLERS CAMP NYANGA  CAPE TOWN",
    Latitude: "-33.9863",
    Longitude: "18.5896",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21611",
    WardLookupId: "460",
    VDNumber: "97091860",
    RegisteredPopulation: "2906",
    VotingStationName: "STORMONT MADUBELA PRIMARY SCHOOL",
    Address: "TSAMANA STREET  KTC  ",
    Latitude: "-33.9858",
    Longitude: "18.578",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21612",
    WardLookupId: "460",
    VDNumber: "97091871",
    RegisteredPopulation: "2596",
    VotingStationName: "HLAZO MINI HALL",
    Address: "J2821 EMAPLANGENI CRESCENT  KTC  NYANGA",
    Latitude: "-33.9871",
    Longitude: "18.5828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21613",
    WardLookupId: "460",
    VDNumber: "97091905",
    RegisteredPopulation: "2156",
    VotingStationName: "MKHANYISELI PRIMARY SCHOOL",
    Address: "TERMINUS ROAD  NYANGA  ",
    Latitude: "-33.9915",
    Longitude: "18.5798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21614",
    WardLookupId: "461",
    VDNumber: "97091792",
    RegisteredPopulation: "2728",
    VotingStationName: "SIYAZINGISA PRIMARY SCHOOL",
    Address: "NY 132  GUGULETHU  CAPE TOWN",
    Latitude: "-33.971",
    Longitude: "18.5675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21615",
    WardLookupId: "461",
    VDNumber: "97091815",
    RegisteredPopulation: "3018",
    VotingStationName: "SOPHUMELELA EDUCARE",
    Address: "NY 111  GUGULETHU  KANANA INFORMAL SETTLEMENT",
    Latitude: "-33.9672",
    Longitude: "18.5703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21616",
    WardLookupId: "461",
    VDNumber: "97091826",
    RegisteredPopulation: "2008",
    VotingStationName: "XOLANI PRIMARY SCHOOL",
    Address: " NY 141  GUGULETU  ",
    Latitude: "-33.9764",
    Longitude: "18.5735",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21617",
    WardLookupId: "461",
    VDNumber: "97092018",
    RegisteredPopulation: "3373",
    VotingStationName: "UMBELEKO AFTER CARE",
    Address: "BARCELONA  GUGULETHU",
    Latitude: "-33.976",
    Longitude: "18.579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21618",
    WardLookupId: "461",
    VDNumber: "97092052",
    RegisteredPopulation: "3618",
    VotingStationName: "SIVILE EDUCARE",
    Address: "13681 TAMBO STREET  GUGULETHU  CAPE TOWN",
    Latitude: "-33.9645",
    Longitude: "18.5664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21619",
    WardLookupId: "461",
    VDNumber: "97093288",
    RegisteredPopulation: "2574",
    VotingStationName: "EUROPE COMMUNITY HALL",
    Address: "NA  GUGULETU  GUGULETU",
    Latitude: "-33.9806",
    Longitude: "18.5824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21620",
    WardLookupId: "461",
    VDNumber: "97093345",
    RegisteredPopulation: "881",
    VotingStationName: "INTSHINGA PRIMARY SCHOOL",
    Address: "NY 137  GUGULETHU  CAPE TOWN",
    Latitude: "-33.9755",
    Longitude: "18.5692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21621",
    WardLookupId: "462",
    VDNumber: "97090106",
    RegisteredPopulation: "1498",
    VotingStationName: "BONGA PRIMARY SCHOOL",
    Address: "NY 146  GUGULETU  GUGULETU",
    Latitude: "-33.9759",
    Longitude: "18.5647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21622",
    WardLookupId: "462",
    VDNumber: "97090117",
    RegisteredPopulation: "2010",
    VotingStationName: "LUZUKO PRIMARY SCHOOL",
    Address: "CNR NY4 & NY14  GUGULETU  ",
    Latitude: "-33.982",
    Longitude: "18.5613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21623",
    WardLookupId: "462",
    VDNumber: "97090128",
    RegisteredPopulation: "2151",
    VotingStationName: "ABEDNIGO METHODIST CHURCH OF S.A",
    Address: "CNR NY 108 & NY1  GUGULETU  CAPE TOWN",
    Latitude: "-33.9782",
    Longitude: "18.5728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21624",
    WardLookupId: "462",
    VDNumber: "97090139",
    RegisteredPopulation: "1816",
    VotingStationName: "JL ZWANE CENTRE",
    Address: "FEZEKA STREET  GUGULETHU  CAPE TOWN",
    Latitude: "-33.9823",
    Longitude: "18.5678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21625",
    WardLookupId: "462",
    VDNumber: "97090140",
    RegisteredPopulation: "2215",
    VotingStationName: "FEZEKA HIGH SCHOOL",
    Address: "NY 2  GUGULETU  ",
    Latitude: "-33.9846",
    Longitude: "18.5695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21626",
    WardLookupId: "462",
    VDNumber: "97091129",
    RegisteredPopulation: "2369",
    VotingStationName: "LITHA PRIMARY SCHOOL",
    Address: "NY 46  GUGULETU  CAPE TOWN",
    Latitude: "-33.99",
    Longitude: "18.5632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21627",
    WardLookupId: "462",
    VDNumber: "97091859",
    RegisteredPopulation: "2200",
    VotingStationName: "VUKUKHANYE PRIMARY SCHOOL",
    Address: "NY 102  GUGULETU  ",
    Latitude: "-33.9867",
    Longitude: "18.573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21628",
    WardLookupId: "462",
    VDNumber: "97093402",
    RegisteredPopulation: "635",
    VotingStationName: "UNITED METHODIST CHURCH",
    Address: "CNR NY 4 AND NY 1  GUGULETHU  CITY OF CAPE TOWN [CAPE TOWN]",
    Latitude: "-33.9762",
    Longitude: "18.5635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21629",
    WardLookupId: "463",
    VDNumber: "97090230",
    RegisteredPopulation: "1941",
    VotingStationName: "THE DOWNS COMMUNITY CENTRE",
    Address: "U.S.K.  ROAD  MANENBERG  ATHLONE",
    Latitude: "-33.9941",
    Longitude: "18.5534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21630",
    WardLookupId: "463",
    VDNumber: "97091118",
    RegisteredPopulation: "2095",
    VotingStationName: "MANENBERG HIGH SCHOOL",
    Address: "6 TUGELA ROAD  MANENBERG  ",
    Latitude: "-33.9956",
    Longitude: "18.5515",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21631",
    WardLookupId: "463",
    VDNumber: "97091130",
    RegisteredPopulation: "1912",
    VotingStationName: "INTSHUKUMO SECONDARY SCHOOL",
    Address: "NY 50  GUGULETU  CITY OF CAPE TOWN",
    Latitude: "-33.9923",
    Longitude: "18.5618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21632",
    WardLookupId: "463",
    VDNumber: "97091174",
    RegisteredPopulation: "3886",
    VotingStationName: "LUCAS MBEBE EDUCARE CENTRE",
    Address: "CNR NY 3A & NY 61  GUGULETU  ",
    Latitude: "-33.9953",
    Longitude: "18.5626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21633",
    WardLookupId: "463",
    VDNumber: "97092298",
    RegisteredPopulation: "3042",
    VotingStationName: "NONZAME EDUCARE CENTRE",
    Address: "NO 134 & 136  NY66  GUGULETU",
    Latitude: "-33.9973",
    Longitude: "18.564",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21634",
    WardLookupId: "463",
    VDNumber: "97092399",
    RegisteredPopulation: "1564",
    VotingStationName: "LUKHANYO EDUCARE CENTRE",
    Address: "NY 1  PHOLA PARK  GUGULETHU",
    Latitude: "-34.0016",
    Longitude: "18.5638",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21635",
    WardLookupId: "464",
    VDNumber: "97091220",
    RegisteredPopulation: "2482",
    VotingStationName: "PHILIPPI CHILDREN`S CENTRE",
    Address: "786 WELTENVREDEN ROAD  PHILIPPI  CAPE TOWN",
    Latitude: "-34.0258",
    Longitude: "18.5675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21636",
    WardLookupId: "464",
    VDNumber: "97091231",
    RegisteredPopulation: "4085",
    VotingStationName: "STRANDFONTEIN HIGH SCHOOL",
    Address: "FRIGATE ROAD  STRANDFONTEIN  CAPE TOWN",
    Latitude: "-34.0767",
    Longitude: "18.5526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21637",
    WardLookupId: "464",
    VDNumber: "97091242",
    RegisteredPopulation: "4448",
    VotingStationName: "DENNEGEUR AVE PRIMARY SCHOOL",
    Address: "169 DENNEGEUR AVENUE  STRANDFONTEIN  CAPE TOWN",
    Latitude: "-34.0702",
    Longitude: "18.5611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21638",
    WardLookupId: "464",
    VDNumber: "97091253",
    RegisteredPopulation: "3070",
    VotingStationName: "APOSTOLIC FAITH MISSION STRANDFONTEIN",
    Address: "CNR DENNEGEUR & PLAY STREET  STRANDFONTEIN  CAPE TOWN",
    Latitude: "-34.0698",
    Longitude: "18.5713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21639",
    WardLookupId: "464",
    VDNumber: "97091602",
    RegisteredPopulation: "3080",
    VotingStationName: "SEAVIEW BAPTIST CHURCH",
    Address: "C/O SEAFARER & WATUSSI STREET  BAYVIEW, STRANDFONTEIN  CAPE TOWN",
    Latitude: "-34.0709",
    Longitude: "18.5861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21640",
    WardLookupId: "464",
    VDNumber: "97093435",
    RegisteredPopulation: "1609",
    VotingStationName: "SIQALO COMMUNITY HALL",
    Address: "VANGUARD DRIVE  MITCHELLS PLAIN  CAPE TOWN",
    Latitude: "-34.031",
    Longitude: "18.5773",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21641",
    WardLookupId: "465",
    VDNumber: "97090791",
    RegisteredPopulation: "2848",
    VotingStationName: "BRIDGETOWN HIGH SCHOOL",
    Address: "11 BRUSHWOOD ROAD, BRIDGETOWN  BRIDGETOWN  CAPE TOWN",
    Latitude: "-33.9578",
    Longitude: "18.5316",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21642",
    WardLookupId: "465",
    VDNumber: "97090803",
    RegisteredPopulation: "2163",
    VotingStationName: "SILVERTOWN COMMUNITY CENTRE",
    Address: "CNR LINARIA & OLYMPICCRES  SILVERTOWN  ",
    Latitude: "-33.9649",
    Longitude: "18.5322",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21643",
    WardLookupId: "465",
    VDNumber: "97090881",
    RegisteredPopulation: "2075",
    VotingStationName: "VANGUARD PRIMARY SCHOOL",
    Address: " DOMAN ROAD  VANGUARD ESTATE  ",
    Latitude: "-33.9671",
    Longitude: "18.5437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21644",
    WardLookupId: "465",
    VDNumber: "97090892",
    RegisteredPopulation: "2504",
    VotingStationName: "CATHKIN HIGH SCHOOL",
    Address: " FIFTH STREET  HEIDEVELD  ",
    Latitude: "-33.9627",
    Longitude: "18.5491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21645",
    WardLookupId: "465",
    VDNumber: "97090904",
    RegisteredPopulation: "1671",
    VotingStationName: "ST THERESAS PARISH HALL",
    Address: " 4TH STREET  WELCOME ESTATE  ",
    Latitude: "-33.962",
    Longitude: "18.547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21646",
    WardLookupId: "465",
    VDNumber: "97090915",
    RegisteredPopulation: "3239",
    VotingStationName: "HEIDEVELD COMMUNITY CENTRE",
    Address: "41 JONKERSHOEK ROAD  HEIDEVELD  ",
    Latitude: "-33.9694",
    Longitude: "18.56",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21647",
    WardLookupId: "465",
    VDNumber: "97090926",
    RegisteredPopulation: "1313",
    VotingStationName: "HEIDEVELD PRIMARY SCHOOL",
    Address: "WAAIHOEK ROAD, HEIDEVELD  HEIDEVELD  CAPE TOWN",
    Latitude: "-33.9674",
    Longitude: "18.5544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21648",
    WardLookupId: "465",
    VDNumber: "97091804",
    RegisteredPopulation: "2558",
    VotingStationName: "GUGULETU CPOA",
    Address: "NY 124  GUGULETU  CAPE TOWN",
    Latitude: "-33.9737",
    Longitude: "18.5631",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21649",
    WardLookupId: "466",
    VDNumber: "97090184",
    RegisteredPopulation: "3445",
    VotingStationName: "PHOENIX HIGH SCHOOL",
    Address: "SILVERSTREAM ROAD  MANENBERG  ",
    Latitude: "-33.9779",
    Longitude: "18.5573",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21650",
    WardLookupId: "466",
    VDNumber: "97090195",
    RegisteredPopulation: "2909",
    VotingStationName: "DRUIWEVLEI COMMUNITY CENTRE",
    Address: "105 RUIMTE ROAD  MANENBERG  AHTLONE",
    Latitude: "-33.9813",
    Longitude: "18.5501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21651",
    WardLookupId: "466",
    VDNumber: "97090218",
    RegisteredPopulation: "2201",
    VotingStationName: "EDENDALE PRIMARY SCHOOL",
    Address: "MANENBERG ROAD  MANENBERG  ",
    Latitude: "-33.9846",
    Longitude: "18.5535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21652",
    WardLookupId: "466",
    VDNumber: "97090229",
    RegisteredPopulation: "2907",
    VotingStationName: "MANENBERG PEOPLE`S CENTRE",
    Address: "24 SCHELDT ROAD   MANENBERG  CAPE TOWN",
    Latitude: "-33.9896",
    Longitude: "18.5563",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21653",
    WardLookupId: "466",
    VDNumber: "97091095",
    RegisteredPopulation: "2430",
    VotingStationName: "RIO GRANDE PRIMARY SCHOOL",
    Address: " PECOS ROAD  MANENBERG  ",
    Latitude: "-33.9911",
    Longitude: "18.5531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21654",
    WardLookupId: "466",
    VDNumber: "97093312",
    RegisteredPopulation: "854",
    VotingStationName: "MANENBERG PRIMARY SCHOOL",
    Address: "CNR KEI & KASOUGA RD  MANENBERG  ",
    Latitude: "-33.9834",
    Longitude: "18.5545",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21655",
    WardLookupId: "467",
    VDNumber: "97090162",
    RegisteredPopulation: "2966",
    VotingStationName: "DUINEFONTEIN MUSLIM ASSOCIATION",
    Address: "JUPITER ROAD  SURREY ESTATE  ATHLONE",
    Latitude: "-33.9761",
    Longitude: "18.5452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21656",
    WardLookupId: "467",
    VDNumber: "97090173",
    RegisteredPopulation: "2395",
    VotingStationName: "EASTER PEAK PRIMARY SCHOOL",
    Address: "VENSTER ROAD  MANENBERG  ATHLONE",
    Latitude: "-33.9739",
    Longitude: "18.5525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21657",
    WardLookupId: "467",
    VDNumber: "97090207",
    RegisteredPopulation: "1919",
    VotingStationName: "PORTAVUE PRIMARY SCHOOL",
    Address: "25 BEVAN ROAD  GREENHAVEN  ",
    Latitude: "-33.9802",
    Longitude: "18.54",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21658",
    WardLookupId: "467",
    VDNumber: "97090847",
    RegisteredPopulation: "1244",
    VotingStationName: "RYLANDS PRIMARY SCHOOL",
    Address: "CNR COLLEGE & CARNIE RDS  RYLANDS  CAPE TOWN",
    Latitude: "-33.9726",
    Longitude: "18.525",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21659",
    WardLookupId: "467",
    VDNumber: "97090858",
    RegisteredPopulation: "3035",
    VotingStationName: "RYLANDS HIGH SCHOOL",
    Address: "3 BALU PARKER BLVD  RYLANDS  ",
    Latitude: "-33.9715",
    Longitude: "18.5354",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21660",
    WardLookupId: "467",
    VDNumber: "97090870",
    RegisteredPopulation: "1849",
    VotingStationName: "NEWFIELDS PRIMARY SCHOOL",
    Address: "ANDREWS ROAD  NEWFIELDS  CAPE TOWN",
    Latitude: "-33.9846",
    Longitude: "18.5356",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21661",
    WardLookupId: "467",
    VDNumber: "97091107",
    RegisteredPopulation: "1486",
    VotingStationName: "SALVATION ARMY CENTRE MANENBERG",
    Address: "CNR OF RED RIVER & RIO GRANDE  MANENBERG  CAPE TOWN",
    Latitude: "-33.9898",
    Longitude: "18.5503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21662",
    WardLookupId: "468",
    VDNumber: "97091039",
    RegisteredPopulation: "2579",
    VotingStationName: "CHRIST GOSPEL CHURCH (HANOVER PARK)",
    Address: "CNR ATHWOOD & SURRAN RD  HANOVER PARK  CAPE TOWN",
    Latitude: "-33.9887",
    Longitude: "18.53",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21663",
    WardLookupId: "468",
    VDNumber: "97091040",
    RegisteredPopulation: "2762",
    VotingStationName: "LANSPORT COMMUNITY CENTRE",
    Address: "LANSPORT ROAD  HANOVER PARK",
    Latitude: "-33.9936",
    Longitude: "18.535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21664",
    WardLookupId: "468",
    VDNumber: "97091051",
    RegisteredPopulation: "3330",
    VotingStationName: "HANOVER PARK CIVIC CENTRE",
    Address: "HANOVER PARK AVENUE  HANOVER PARK  ",
    Latitude: "-33.9963",
    Longitude: "18.5274",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21665",
    WardLookupId: "468",
    VDNumber: "97091062",
    RegisteredPopulation: "1437",
    VotingStationName: "SULTAN BAHU CENTRE (HANOVER PARK)",
    Address: "CNR LONEDOWN & LANSUR RD  HANOVER PARK  HANOVER PARK",
    Latitude: "-33.9968",
    Longitude: "18.5243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21666",
    WardLookupId: "468",
    VDNumber: "97091073",
    RegisteredPopulation: "2301",
    VotingStationName: "VOORSPOED PRIMARY SCHOOL",
    Address: "CNR LONEDOWN & GREENTURF ROAD  HANOVER PARK  ",
    Latitude: "-33.9961",
    Longitude: "18.5377",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21667",
    WardLookupId: "468",
    VDNumber: "97091084",
    RegisteredPopulation: "2398",
    VotingStationName: "BELMOR PRIMARY SCHOOL",
    Address: "DOWNBERG ROAD  HANOVER PARK  ",
    Latitude: "-34",
    Longitude: "18.5266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21668",
    WardLookupId: "469",
    VDNumber: "97090724",
    RegisteredPopulation: "1944",
    VotingStationName: "THORNTON ROAD PRIMARY SCHOOL",
    Address: "204 THORNTON ROAD  CRAWFORD  ATHLONE",
    Latitude: "-33.9789",
    Longitude: "18.5114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21669",
    WardLookupId: "469",
    VDNumber: "97090735",
    RegisteredPopulation: "2004",
    VotingStationName: "TURFHALL PRIMARY SCHOOL",
    Address: "PENLYN AVENUE  BELTHORN ESTATE  ",
    Latitude: "-33.9829",
    Longitude: "18.5192",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21670",
    WardLookupId: "469",
    VDNumber: "97090746",
    RegisteredPopulation: "2539",
    VotingStationName: "ATHWOOD PRIMARY SCHOOL",
    Address: "BLOMVLEI ROAD  LANSDOWNE  CAPE TOWN",
    Latitude: "-33.9923",
    Longitude: "18.5217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21671",
    WardLookupId: "469",
    VDNumber: "97090825",
    RegisteredPopulation: "2755",
    VotingStationName: "COLLEGE OF CAPE TOWN - CRAWFORD CAMPUS",
    Address: "KROMBOOM ROAD  CRAWFORD  ",
    Latitude: "-33.9733",
    Longitude: "18.5121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21672",
    WardLookupId: "469",
    VDNumber: "97090836",
    RegisteredPopulation: "1761",
    VotingStationName: "LUTHERAN YOUTH CENTRE (BELGRAVIA)",
    Address: "CNR MARTIN LUTHER & 8TH AVE  BELGRAVIA ESTATE  BELGRAVIA",
    Latitude: "-33.967",
    Longitude: "18.5211",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21673",
    WardLookupId: "469",
    VDNumber: "97090869",
    RegisteredPopulation: "2289",
    VotingStationName: "STARLING PRIMARY SCHOOL",
    Address: "CNR REPULSE & STARLING STR  RYLANDS  ",
    Latitude: "-33.9819",
    Longitude: "18.5263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21674",
    WardLookupId: "470",
    VDNumber: "97090689",
    RegisteredPopulation: "2011",
    VotingStationName: "ST FRANCES CENTRE (LANGA)",
    Address: "01 NDABENI  LANGA  CAPE TOWN",
    Latitude: "-33.9469",
    Longitude: "18.5199",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21675",
    WardLookupId: "470",
    VDNumber: "97090690",
    RegisteredPopulation: "2221",
    VotingStationName: "ATHLONE BAPTIST CHURCH",
    Address: "11 PLINY STREET  ATHLONE  CAPE TOWN",
    Latitude: "-33.9682",
    Longitude: "18.5042",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21676",
    WardLookupId: "470",
    VDNumber: "97090702",
    RegisteredPopulation: "2553",
    VotingStationName: "INDEPENDENT CONGREGATIONAL CHURCH HALL (ATHLONE)",
    Address: "40 GRASMERE STREET, ATHLONE  ATHLONE  CAPE TOWN",
    Latitude: "-33.9656",
    Longitude: "18.5056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21677",
    WardLookupId: "470",
    VDNumber: "97090757",
    RegisteredPopulation: "2113",
    VotingStationName: "HAZENDAL PRIMARY SCHOOL",
    Address: "12 HERBRAND STREET, HAZENDAL  HAZENDAL  CAPE TOWN",
    Latitude: "-33.9593",
    Longitude: "18.5043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21678",
    WardLookupId: "470",
    VDNumber: "97090768",
    RegisteredPopulation: "2328",
    VotingStationName: "BABS",
    Address: "PEGASUS CENTRE, ASTER ROAD  KEWTOWN  ",
    Latitude: "-33.9595",
    Longitude: "18.5163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21679",
    WardLookupId: "470",
    VDNumber: "97090779",
    RegisteredPopulation: "1702",
    VotingStationName: "ATHLONE HIGH SCHOOL",
    Address: "CALENDULA ROAD  SILVERTOWN  ",
    Latitude: "-33.9621",
    Longitude: "18.5221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21680",
    WardLookupId: "470",
    VDNumber: "97090780",
    RegisteredPopulation: "1957",
    VotingStationName: "CYPRESS PRIMARY SCHOOL",
    Address: "SIPRES AVENUE  BRIDGETOWN  ",
    Latitude: "-33.9536",
    Longitude: "18.5234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21681",
    WardLookupId: "470",
    VDNumber: "97090814",
    RegisteredPopulation: "1537",
    VotingStationName: "SILVERLEA PRIMARY SCHOOL",
    Address: "CNR ZINNIA & LOWER KLIPFONTEIN ROAD  SILVERTOWN  ",
    Latitude: "-33.9645",
    Longitude: "18.5232",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21682",
    WardLookupId: "470",
    VDNumber: "97092164",
    RegisteredPopulation: "1256",
    VotingStationName: "BOKMAKIERIE PRIMARY SCHOOL",
    Address: "1ST AVENUE BOKMAKIERIE STREET  BOKMAKIERIE  HAZENDAL",
    Latitude: "-33.9516",
    Longitude: "18.5075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21683",
    WardLookupId: "471",
    VDNumber: "97140247",
    RegisteredPopulation: "1689",
    VotingStationName: "EA JANARI PRIMARY SCHOOL",
    Address: "AVOCADO STR  BONTEHEUWEL",
    Latitude: "-33.9436",
    Longitude: "18.547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21684",
    WardLookupId: "471",
    VDNumber: "97140258",
    RegisteredPopulation: "2347",
    VotingStationName: "CEDAR PRIMARY SCHOOL",
    Address: "CAMELLIA STREET  BONTEHEUWEL",
    Latitude: "-33.9474",
    Longitude: "18.5496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21685",
    WardLookupId: "471",
    VDNumber: "97140269",
    RegisteredPopulation: "2004",
    VotingStationName: "MIMOSA PRIMARY SCHOOL",
    Address: "VLAMBOOM RD  BONTEHEUWEL  ",
    Latitude: "-33.9523",
    Longitude: "18.5471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21686",
    WardLookupId: "471",
    VDNumber: "97140270",
    RegisteredPopulation: "1894",
    VotingStationName: "BOUNDARY PRIMARY SCHOOL",
    Address: "TAAIBOS STR  BONTEHEUWEL  ",
    Latitude: "-33.9551",
    Longitude: "18.5451",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21687",
    WardLookupId: "471",
    VDNumber: "97140292",
    RegisteredPopulation: "1810",
    VotingStationName: "NERINA PRIMARY SCHOOL",
    Address: "LEPELHOUT STR  BONTEHEUWEL  ",
    Latitude: "-33.9506",
    Longitude: "18.5579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21688",
    WardLookupId: "471",
    VDNumber: "97140304",
    RegisteredPopulation: "2255",
    VotingStationName: "ARCADIA PRIMARY SCHOOL",
    Address: "JUNIPER STR  BONTEHEUWEL  ",
    Latitude: "-33.9483",
    Longitude: "18.5544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21689",
    WardLookupId: "471",
    VDNumber: "97140315",
    RegisteredPopulation: "2154",
    VotingStationName: "BRAMBLE WAY PRIMARY SCHOOL",
    Address: "BRAMBLE WAY  BONTEHEUWEL",
    Latitude: "-33.9435",
    Longitude: "18.557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21690",
    WardLookupId: "471",
    VDNumber: "97142564",
    RegisteredPopulation: "1178",
    VotingStationName: "BERGSIG PRIMARY",
    Address: "DISSEL  BONTEHEUWEL  CITY OF CAPE TOWN [CAPE TOWN]",
    Latitude: "-33.9472",
    Longitude: "18.5462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21691",
    WardLookupId: "472",
    VDNumber: "97090937",
    RegisteredPopulation: "1681",
    VotingStationName: "MOSHESH PRIMARY SCHOOL",
    Address: "ROSE INNES STREET  LANGA  ",
    Latitude: "-33.9415",
    Longitude: "18.5282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21692",
    WardLookupId: "472",
    VDNumber: "97090948",
    RegisteredPopulation: "2460",
    VotingStationName: "RED CROSS SOCIETY CENTRE",
    Address: "MENDI  LANGA  LANGA",
    Latitude: "-33.9455",
    Longitude: "18.5266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21693",
    WardLookupId: "472",
    VDNumber: "97090959",
    RegisteredPopulation: "2229",
    VotingStationName: "NOLUKHANYO PRE SCHOOL",
    Address: "KWEDI ZINGELWA STREET  LANGA  LANGA",
    Latitude: "-33.9426",
    Longitude: "18.5339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21694",
    WardLookupId: "472",
    VDNumber: "97090971",
    RegisteredPopulation: "873",
    VotingStationName: "WESLEYN CHURCH",
    Address: "REUBEN BEYILE  LANGA  CAPE TOWN",
    Latitude: "-33.9471",
    Longitude: "18.5312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21695",
    WardLookupId: "472",
    VDNumber: "97090982",
    RegisteredPopulation: "1899",
    VotingStationName: "KULANI HIGH SCHOOL",
    Address: "THABO MBEKI STR  ZONE 5  LANGA",
    Latitude: "-33.9478",
    Longitude: "18.5334",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21696",
    WardLookupId: "472",
    VDNumber: "97091006",
    RegisteredPopulation: "2316",
    VotingStationName: "UNITED METHODIST CHURCH",
    Address: "55 VOKWANA STREET  LANGA  ",
    Latitude: "-33.9482",
    Longitude: "18.5272",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21697",
    WardLookupId: "472",
    VDNumber: "97092210",
    RegisteredPopulation: "1908",
    VotingStationName: "IKAMVALETHU SECONDARY SCHOOL",
    Address: "OFF WASHINGTON STREET  ZONE 27  LANGA",
    Latitude: "-33.9433",
    Longitude: "18.5401",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21698",
    WardLookupId: "472",
    VDNumber: "97093457",
    RegisteredPopulation: "723",
    VotingStationName: "MOKONE PRIMARY SCHOOL",
    Address: "BENNIE STREET  LANGA  CAPE TOWN",
    Latitude: "-33.9427",
    Longitude: "18.5194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21699",
    WardLookupId: "473",
    VDNumber: "97090960",
    RegisteredPopulation: "5469",
    VotingStationName: "ISILIMELA COMPREHENSIVE SCHOOL",
    Address: "WASHINGTON DRIVE  LANGA  ",
    Latitude: "-33.9458",
    Longitude: "18.5403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21700",
    WardLookupId: "473",
    VDNumber: "97090993",
    RegisteredPopulation: "2134",
    VotingStationName: "SIZAMILE OLD AGE HOME TEMPORARY VOTING STATION",
    Address: "ZONE 17, LANGA  CAPE TOWN  LANGA",
    Latitude: "-33.9489",
    Longitude: "18.5355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21701",
    WardLookupId: "473",
    VDNumber: "97092232",
    RegisteredPopulation: "1248",
    VotingStationName: "MSHUMPELA PLAY PARK TENT",
    Address: "MSHUMPELA  SETTLERS PHASE ONE  LANGA",
    Latitude: "-33.9501",
    Longitude: "18.5337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21702",
    WardLookupId: "473",
    VDNumber: "97092243",
    RegisteredPopulation: "2226",
    VotingStationName: "31 JOE SLOVO INF SETTLEM (TENT)",
    Address: "31 JOE SLOVO INFORMAL SETTLEMENT,  LANGA  LANGA",
    Latitude: "-33.9514",
    Longitude: "18.5333",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21703",
    WardLookupId: "473",
    VDNumber: "97092311",
    RegisteredPopulation: "2558",
    VotingStationName: "NOMONDE EDUCARE",
    Address: "23 ZONE 23  LANGA  CAPE TOWN",
    Latitude: "-33.9477",
    Longitude: "18.5378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21704",
    WardLookupId: "473",
    VDNumber: "97093424",
    RegisteredPopulation: "826",
    VotingStationName: "LANGA MULTIPURPOSE SPORTS FACILITY",
    Address: "C/O BHUNGA & N2  LANGA  CAPE TOWN",
    Latitude: "-33.9485",
    Longitude: "18.5226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21705",
    WardLookupId: "474",
    VDNumber: "97090555",
    RegisteredPopulation: "2539",
    VotingStationName: "PINELANDS PRIMARY BLUE SCHOOL",
    Address: "10 CENTRAL SQUARE   PINELANDS   CAPE TOWN",
    Latitude: "-33.9374",
    Longitude: "18.4987",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21706",
    WardLookupId: "474",
    VDNumber: "97090656",
    RegisteredPopulation: "3320",
    VotingStationName: "PINEHURST PRIMARY SCHOOL",
    Address: "RHONE RD  PINELANDS  ",
    Latitude: "-33.9336",
    Longitude: "18.5163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21707",
    WardLookupId: "474",
    VDNumber: "97090667",
    RegisteredPopulation: "3821",
    VotingStationName: "THORNTON PRIMARY SCHOOL",
    Address: "23 MANATOKA ROAD  THORNTON  CAPE TOWN",
    Latitude: "-33.9231",
    Longitude: "18.5368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21708",
    WardLookupId: "474",
    VDNumber: "97090678",
    RegisteredPopulation: "3988",
    VotingStationName: "PINELANDS HIGH SCHOOL",
    Address: "FOREST DRIVE  PINELANDS  CAPE TOWN",
    Latitude: "-33.9361",
    Longitude: "18.5071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21709",
    WardLookupId: "474",
    VDNumber: "97092096",
    RegisteredPopulation: "1336",
    VotingStationName: "METHODIST CHURCH",
    Address: "DISCOVERY AVE  GARDEN VILLAGE  MAITLAND",
    Latitude: "-33.9353",
    Longitude: "18.4861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21710",
    WardLookupId: "475",
    VDNumber: "97090016",
    RegisteredPopulation: "3437",
    VotingStationName: "CAMPS BAY CLUB",
    Address: "VICTORIA ROAD  CAMPS BAY  ",
    Latitude: "-33.9519",
    Longitude: "18.3796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21711",
    WardLookupId: "475",
    VDNumber: "97090027",
    RegisteredPopulation: "2483",
    VotingStationName: "SEA FISHERIES GARAGES",
    Address: "BEACH ROAD  SEA POINT  ",
    Latitude: "-33.9215",
    Longitude: "18.38",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21712",
    WardLookupId: "475",
    VDNumber: "97090038",
    RegisteredPopulation: "3116",
    VotingStationName: "ECOLE FRANCAISE DU CAP",
    Address: "KINGS  SEA POINT  CAPE TOWN",
    Latitude: "-33.9226",
    Longitude: "18.3816",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21713",
    WardLookupId: "475",
    VDNumber: "97090049",
    RegisteredPopulation: "3535",
    VotingStationName: "SEA POINT HIGH SCHOOL",
    Address: "MAIN ROAD  SEA POINT  SEA POINT",
    Latitude: "-33.9107",
    Longitude: "18.3931",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21714",
    WardLookupId: "475",
    VDNumber: "97090050",
    RegisteredPopulation: "2729",
    VotingStationName: "SEA POINT PRIMARY SCHOOL",
    Address: "HIGH LEVEL ROAD  SEA POINT  CAPE TOWN",
    Latitude: "-33.912",
    Longitude: "18.3973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21715",
    WardLookupId: "475",
    VDNumber: "97091219",
    RegisteredPopulation: "142",
    VotingStationName: "THE GYMNASIUM",
    Address: "CHURCH  ROBBEN ISLAND  ROBBEN ISLAD",
    Latitude: "-33.8113",
    Longitude: "18.3792",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21716",
    WardLookupId: "475",
    VDNumber: "97092153",
    RegisteredPopulation: "1229",
    VotingStationName: "CLIFTON SCOUT HALL",
    Address: "CLIFF ROAD 4TH BEACH  CLIFTON  ",
    Latitude: "-33.9421",
    Longitude: "18.3746",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21717",
    WardLookupId: "476",
    VDNumber: "97120010",
    RegisteredPopulation: "2728",
    VotingStationName: "YSTERPLAAT PRIMARY SCHOOL",
    Address: "62A KOEBERG  BROOKLYN  CAPE TOWN",
    Latitude: "-33.9112",
    Longitude: "18.4846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21718",
    WardLookupId: "476",
    VDNumber: "97120021",
    RegisteredPopulation: "1363",
    VotingStationName: "BUREN HIGH SCHOOL",
    Address: "POOLE STREET  BROOKLYN  ",
    Latitude: "-33.9052",
    Longitude: "18.4842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21719",
    WardLookupId: "476",
    VDNumber: "97120032",
    RegisteredPopulation: "2145",
    VotingStationName: "MOTH MEMORIAL HALL",
    Address: "KOEBERG RD  BROOKLYN  ",
    Latitude: "-33.9009",
    Longitude: "18.4885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21720",
    WardLookupId: "476",
    VDNumber: "97120043",
    RegisteredPopulation: "2910",
    VotingStationName: "MILNERTON BAPTIST CHURCH",
    Address: "185 PRINGLE ROAD  SANDDRIFT  MILNERTON",
    Latitude: "-33.8855",
    Longitude: "18.5077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21721",
    WardLookupId: "476",
    VDNumber: "97120054",
    RegisteredPopulation: "1896",
    VotingStationName: "WOODBRIDGE PRIMARY SCHOOL",
    Address: "7 UNION ROAD  MILNERTON  ",
    Latitude: "-33.8899",
    Longitude: "18.4897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21722",
    WardLookupId: "476",
    VDNumber: "97120065",
    RegisteredPopulation: "1336",
    VotingStationName: "DE GRENDEL SCHOOL",
    Address: "CO KOEBERG & NOBEL ROAD  MILNERTON  ",
    Latitude: "-33.8819",
    Longitude: "18.4997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21723",
    WardLookupId: "476",
    VDNumber: "97120076",
    RegisteredPopulation: "1909",
    VotingStationName: "MILNERTON PRIMARY SCHOOL",
    Address: "ZASTRON ROAD  MILNERTON  CAPE TOWN",
    Latitude: "-33.874",
    Longitude: "18.4982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21724",
    WardLookupId: "476",
    VDNumber: "97120548",
    RegisteredPopulation: "1898",
    VotingStationName: "CENTURY CITY CLUB",
    Address: "6 PARK LANE  CENTURY CITY  MILNERTON",
    Latitude: "-33.8882",
    Longitude: "18.5127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21725",
    WardLookupId: "477",
    VDNumber: "97090588",
    RegisteredPopulation: "2198",
    VotingStationName: "MAITLAND HIGH SCHOOL",
    Address: "STATION ROAD   MAITLAND   CAPE TOWN",
    Latitude: "-33.9199",
    Longitude: "18.4838",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21726",
    WardLookupId: "477",
    VDNumber: "97090599",
    RegisteredPopulation: "2726",
    VotingStationName: "ST JOHNS AME CHURCH",
    Address: "CNR 5TH AVE & 11TH STREET  KENSINGTON  ",
    Latitude: "-33.9136",
    Longitude: "18.5025",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21727",
    WardLookupId: "477",
    VDNumber: "97090601",
    RegisteredPopulation: "2652",
    VotingStationName: "KENSINGTON HIGH SCHOOL",
    Address: "SUNDERLAND STREET   KENSINGTON   CAPE TOWN",
    Latitude: "-33.9076",
    Longitude: "18.5071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21728",
    WardLookupId: "477",
    VDNumber: "97090612",
    RegisteredPopulation: "2220",
    VotingStationName: "WINDERMERE PRIMARY SCHOOL",
    Address: "10TH AVENUE  KENSINGTON  ",
    Latitude: "-33.9158",
    Longitude: "18.5111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21729",
    WardLookupId: "477",
    VDNumber: "97090623",
    RegisteredPopulation: "2684",
    VotingStationName: "SUNDERLAND PRIMARY SCHOOL",
    Address: "105 SUNDERLAND  WINDERMERE  CAPE TOWN",
    Latitude: "-33.9056",
    Longitude: "18.5142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21730",
    WardLookupId: "477",
    VDNumber: "97090634",
    RegisteredPopulation: "2272",
    VotingStationName: "VG KERK",
    Address: "CNR FACTRETON & LUGMAG RD  FACTRETON  CAPE TOWN",
    Latitude: "-33.9107",
    Longitude: "18.5217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21731",
    WardLookupId: "477",
    VDNumber: "97090645",
    RegisteredPopulation: "1436",
    VotingStationName: "HJ KRONEBERG PRIMARY SCHOOL",
    Address: "CNR 8TH AVENUE & NYMAN STREET  KENSINGTON  ",
    Latitude: "-33.9173",
    Longitude: "18.5082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21732",
    WardLookupId: "477",
    VDNumber: "97093299",
    RegisteredPopulation: "394",
    VotingStationName: "ACACIA PARK PRIMARY SCHOOL",
    Address: "OFF TOWNSEND ROAD; ACACIA PARK  ACACIA PARK  GOODWOOD",
    Latitude: "-33.8905",
    Longitude: "18.5276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21733",
    WardLookupId: "478",
    VDNumber: "97090320",
    RegisteredPopulation: "2321",
    VotingStationName: "QUEENS PARK HIGH SCHOOL",
    Address: "7925 BALFOUR STREET  WOODSTOCK  CITY OF CAPE TOWN [CAPE TOWN]",
    Latitude: "-33.9352",
    Longitude: "18.4535",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21734",
    WardLookupId: "478",
    VDNumber: "97090364",
    RegisteredPopulation: "2408",
    VotingStationName: "ZONNEBLOEM NEST SENIOR SCHOOL",
    Address: "CAMBRIDGE ROAD  WOODSTOCK  CAPE TOWN",
    Latitude: "-33.9347",
    Longitude: "18.4394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21735",
    WardLookupId: "478",
    VDNumber: "97090375",
    RegisteredPopulation: "2840",
    VotingStationName: "OBSERVATORY JUNIOR SCHOOL",
    Address: "CLIFTON ROAD  OBSERVATORY  CAPE TOWN",
    Latitude: "-33.9416",
    Longitude: "18.4708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21736",
    WardLookupId: "478",
    VDNumber: "97090544",
    RegisteredPopulation: "2073",
    VotingStationName: "BISHOPS PREP",
    Address: "01 FIR ROAD  RONDEBOSCH  CAPE TOWN",
    Latitude: "-33.9605",
    Longitude: "18.4757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21737",
    WardLookupId: "478",
    VDNumber: "97090566",
    RegisteredPopulation: "1051",
    VotingStationName: "HARTLEY VALE SPORTS STADIUM",
    Address: "OBSERVATORY ROAD  OBSERVATORY  CAPE TOWN",
    Latitude: "-33.9368",
    Longitude: "18.4747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21738",
    WardLookupId: "478",
    VDNumber: "97090577",
    RegisteredPopulation: "1658",
    VotingStationName: "RHODES HIGH SCHOOL",
    Address: "MONTREAL AVENUE  MOWBRAY  CAPE TOWN",
    Latitude: "-33.945",
    Longitude: "18.4813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21739",
    WardLookupId: "478",
    VDNumber: "97092085",
    RegisteredPopulation: "2882",
    VotingStationName: "BLACK POOL SPORTS CENTRE",
    Address: "SHELLEY ROAD  OBSERVATORY  CITY OF CAPE TOWN [CAPE TOWN]",
    Latitude: "-33.934",
    Longitude: "18.4628",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21740",
    WardLookupId: "478",
    VDNumber: "97092108",
    RegisteredPopulation: "2732",
    VotingStationName: "THANDOKHULU HIGH SCHOOL",
    Address: "CNR ALBERT & MAIN ROAD  MOWBRAY  CAPE TOWN",
    Latitude: "-33.9457",
    Longitude: "18.4705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21741",
    WardLookupId: "478",
    VDNumber: "97092366",
    RegisteredPopulation: "2570",
    VotingStationName: "MOWBRAY BOWLING CLUB",
    Address: "CHAPEL  ROSEBANK  CAPE TOWN",
    Latitude: "-33.9531",
    Longitude: "18.4674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21742",
    WardLookupId: "479",
    VDNumber: "97090410",
    RegisteredPopulation: "3570",
    VotingStationName: "CLAREMONT PRIMARY SCHOOL",
    Address: "CNR PALMYRA & ST LEGER ROAD  CLAREMONT  CAPE TOWN",
    Latitude: "-33.9786",
    Longitude: "18.4696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21743",
    WardLookupId: "479",
    VDNumber: "97090421",
    RegisteredPopulation: "3231",
    VotingStationName: "ROSMEAD CENTRAL PRIMARY SCHOOL",
    Address: "CNR ROSMEAD & GRASSFIELD RD  KENILWORTH  CAPE TOWN",
    Latitude: "-33.9854",
    Longitude: "18.4785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21744",
    WardLookupId: "479",
    VDNumber: "97090432",
    RegisteredPopulation: "2274",
    VotingStationName: "1ST WETTON SCOUT HALL",
    Address: "CNR BELPER RD & KILDARED  WYNBERG  ",
    Latitude: "-34.0022",
    Longitude: "18.4738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21745",
    WardLookupId: "479",
    VDNumber: "97090443",
    RegisteredPopulation: "2587",
    VotingStationName: "BATAVIA SCHOOL",
    Address: "LAURIER ROAD  CLAREMONT  CAPE TOWN",
    Latitude: "-33.9838",
    Longitude: "18.4892",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21746",
    WardLookupId: "479",
    VDNumber: "97090498",
    RegisteredPopulation: "3766",
    VotingStationName: "ROUND TABLE BELVIDERE COMM CENTRE",
    Address: "CHESS RD  RONDEBOSCH  CAPE TOWN",
    Latitude: "-33.9754",
    Longitude: "18.4813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21747",
    WardLookupId: "479",
    VDNumber: "97090522",
    RegisteredPopulation: "3227",
    VotingStationName: "ROSEBANK JUNIOR SCHOOL",
    Address: "MOOLENBERG ROAD  RONDEBOSCH  ",
    Latitude: "-33.9618",
    Longitude: "18.4888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21748",
    WardLookupId: "480",
    VDNumber: "97090353",
    RegisteredPopulation: "3067",
    VotingStationName: "ST PAULS ANGLICAN CHURCH",
    Address: "CHURCH STREET  RONDEBOSCH  CAPE TOWN",
    Latitude: "-33.9621",
    Longitude: "18.4692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21749",
    WardLookupId: "480",
    VDNumber: "97090397",
    RegisteredPopulation: "5940",
    VotingStationName: "THE GROVE PRIMARY SCHOOL",
    Address: "GROVE AVENUE  CLAREMONT  CAPE TOWN",
    Latitude: "-33.9832",
    Longitude: "18.4608",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21750",
    WardLookupId: "480",
    VDNumber: "97090409",
    RegisteredPopulation: "1844",
    VotingStationName: "KENILWORTH COMMUNITY PRESBYTERYAN CHURCH",
    Address: "296 294 MAIN ROAD  KENILWORTH  CAPE TOWN",
    Latitude: "-33.9977",
    Longitude: "18.4683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21751",
    WardLookupId: "480",
    VDNumber: "97090487",
    RegisteredPopulation: "2072",
    VotingStationName: "GROOTE SCHUUR PRIMARY SCHOOL",
    Address: "126 CAMPGROUND ROAD  RONDEBOSCH  CAPE TOWN",
    Latitude: "-33.9694",
    Longitude: "18.4733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21752",
    WardLookupId: "480",
    VDNumber: "97092119",
    RegisteredPopulation: "1757",
    VotingStationName: "CLAREMONT HIGH SCHOOL",
    Address: "MOLTENO ROAD  CLAREMONT  CAPE TOWN",
    Latitude: "-33.988",
    Longitude: "18.4691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21753",
    WardLookupId: "480",
    VDNumber: "97092120",
    RegisteredPopulation: "2707",
    VotingStationName: "WESTERFORD HIGH SCHOOL",
    Address: "220 MAIN ROAD  NEWLANDS  CAPE TOWN",
    Latitude: "-33.9691",
    Longitude: "18.4652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21754",
    WardLookupId: "480",
    VDNumber: "97093413",
    RegisteredPopulation: "1309",
    VotingStationName: "VOORTREKKER HIGH SCHOOL",
    Address: "SALISBURY AND THOMAS  KENILWORTH  CITY OF CAPE TOWN [CAPE TOWN]",
    Latitude: "-33.9994",
    Longitude: "18.4714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21755",
    WardLookupId: "481",
    VDNumber: "97090454",
    RegisteredPopulation: "3269",
    VotingStationName: "SUNLANDS PRIMARY SCHOOL",
    Address: "NEWENT STREET  KENWYN  CAPE TOWN",
    Latitude: "-33.9981",
    Longitude: "18.4987",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21756",
    WardLookupId: "481",
    VDNumber: "97090465",
    RegisteredPopulation: "2185",
    VotingStationName: "SAVIO HALL (LANSDOWNE)",
    Address: "312 IMAM HARON ROAD  LANSDOWNE  CAPE TOWN",
    Latitude: "-33.9854",
    Longitude: "18.4924",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21757",
    WardLookupId: "481",
    VDNumber: "97090476",
    RegisteredPopulation: "2606",
    VotingStationName: "LANSDOWNE CIVIC CENTRE",
    Address: "CHURCH STREET  LANSDOWNE  ",
    Latitude: "-33.992",
    Longitude: "18.5052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21758",
    WardLookupId: "481",
    VDNumber: "97090500",
    RegisteredPopulation: "1526",
    VotingStationName: "VERA SCHOOL",
    Address: "20 ANGLESEY ROAD  RONDEBOSCH EAST  CAPE TOWN",
    Latitude: "-33.979",
    Longitude: "18.4947",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21759",
    WardLookupId: "481",
    VDNumber: "97090511",
    RegisteredPopulation: "2258",
    VotingStationName: "GARLANDALE HIGH SCHOOL",
    Address: "GENERAL STREET  ATHLONE  CAPE TOWN",
    Latitude: "-33.9625",
    Longitude: "18.4974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21760",
    WardLookupId: "481",
    VDNumber: "97090533",
    RegisteredPopulation: "1133",
    VotingStationName: "CHRIST CHURCH SYBRAND PARK",
    Address: "43 ELGIN ROAD  SYBRAND PARK  CAPE TOWN",
    Latitude: "-33.9545",
    Longitude: "18.4994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21761",
    WardLookupId: "481",
    VDNumber: "97090713",
    RegisteredPopulation: "2846",
    VotingStationName: "RONDEBOSCH EAST ISLAMIC CENTRE",
    Address: "CNR TARONGA AND LAWSON  ROADS  RONDEBOSCH EAST  CAPE TOWN",
    Latitude: "-33.9817",
    Longitude: "18.5039",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21762",
    WardLookupId: "481",
    VDNumber: "97091028",
    RegisteredPopulation: "2123",
    VotingStationName: "YORK ROAD PRIMARY SCHOOL",
    Address: "33 LOWER YORK ROAD  LANSDOWNE  CAPE TOWN",
    Latitude: "-33.9976",
    Longitude: "18.5158",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21763",
    WardLookupId: "482",
    VDNumber: "97130134",
    RegisteredPopulation: "1597",
    VotingStationName: "PHOENIX MASONIC LODGE",
    Address: "CNR PHOENIX WAY & BIRKENHEAD STREET  GLENCAIRN  CAPE TOWN",
    Latitude: "-34.1567",
    Longitude: "18.4298",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21764",
    WardLookupId: "482",
    VDNumber: "97130145",
    RegisteredPopulation: "2995",
    VotingStationName: "SIMONSTOWN SCHOOL",
    Address: "HARRINGTON ROAD  SIMONSTOWN  SIMONSTOWN",
    Latitude: "-34.1976",
    Longitude: "18.4458",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21765",
    WardLookupId: "482",
    VDNumber: "97130156",
    RegisteredPopulation: "1768",
    VotingStationName: "DA GAMA PARK COMMUNITY CENTRE",
    Address: "DA GAMA RD  DA GAMA PARK  SIMONSTOWN",
    Latitude: "-34.16",
    Longitude: "18.4063",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21766",
    WardLookupId: "482",
    VDNumber: "97130167",
    RegisteredPopulation: "2185",
    VotingStationName: "KOMMETJIE PRIMARY SCHOOL",
    Address: "TEUBES ROAD  KOMMETJIE  CAPE TOWN",
    Latitude: "-34.1408",
    Longitude: "18.3296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21767",
    WardLookupId: "482",
    VDNumber: "97130235",
    RegisteredPopulation: "2342",
    VotingStationName: "BAPTIST CHURCH - OCEAN VIEW",
    Address: "SATURN WAY  OCEAN VIEW  ",
    Latitude: "-34.1473",
    Longitude: "18.3529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21768",
    WardLookupId: "482",
    VDNumber: "97130246",
    RegisteredPopulation: "1446",
    VotingStationName: "KLEINBERG PRIMARY SCHOOL",
    Address: "AQUILA WAY  OCEAN VIEW  OCEAN VIEW",
    Latitude: "-34.1451",
    Longitude: "18.35",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21769",
    WardLookupId: "482",
    VDNumber: "97130257",
    RegisteredPopulation: "1796",
    VotingStationName: "MARINE PRIMARY SCHOOL",
    Address: "MILKY WAY  OCEAN VIEW  OCEAN VIEW",
    Latitude: "-34.1476",
    Longitude: "18.3554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21770",
    WardLookupId: "482",
    VDNumber: "97130987",
    RegisteredPopulation: "1815",
    VotingStationName: "OCEAN VIEW SECONDARY SCHOOL",
    Address: "HYDRA AVENUE  OCEAN VIEW  ",
    Latitude: "-34.1496",
    Longitude: "18.3498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21771",
    WardLookupId: "482",
    VDNumber: "97131168",
    RegisteredPopulation: "584",
    VotingStationName: "APOSTOLIC FAITH MISSION OF SA - REDHILL ASSEMBLY",
    Address: "RED HILL  SIMONS TOWN  SIMONS TOWN",
    Latitude: "-34.2013",
    Longitude: "18.4089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21772",
    WardLookupId: "483",
    VDNumber: "97090342",
    RegisteredPopulation: "2191",
    VotingStationName: "STONE COTTAGES NO 2",
    Address: "CNR KIRSTENBOSCH  & RHODES DRIVE  NEWLANDS  CAPE TOWN",
    Latitude: "-33.9861",
    Longitude: "18.4375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21773",
    WardLookupId: "483",
    VDNumber: "97130280",
    RegisteredPopulation: "3626",
    VotingStationName: "NG KERK WYNBERG",
    Address: "2 DURBAN ROAD  WYNBERG  CAPE TOWN",
    Latitude: "-34.0045",
    Longitude: "18.462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21774",
    WardLookupId: "483",
    VDNumber: "97130314",
    RegisteredPopulation: "2063",
    VotingStationName: "NORMAN HENSILWOOD HIGH SCHOOL",
    Address: "CNR DENBIGH & CONSTANTIA ROAD  CONSTANTIA  CAPE TOWN",
    Latitude: "-34.015",
    Longitude: "18.459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21775",
    WardLookupId: "483",
    VDNumber: "97130347",
    RegisteredPopulation: "4230",
    VotingStationName: "ALPHEN CIVIC CENTRE",
    Address: "MAIN ROAD  CONSTANTIA  CAPE TOWN",
    Latitude: "-34.0193",
    Longitude: "18.4458",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21776",
    WardLookupId: "483",
    VDNumber: "97130358",
    RegisteredPopulation: "2234",
    VotingStationName: "CONSTANTIA PRIMARY SCHOOL",
    Address: "SPAANSCHEMAT RIVER RD  CONSTANTIA  CAPE TOWN",
    Latitude: "-34.0276",
    Longitude: "18.4441",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21777",
    WardLookupId: "483",
    VDNumber: "97130426",
    RegisteredPopulation: "2436",
    VotingStationName: "TIMOUR HALL PRIMARY SCHOOL",
    Address: "TIMOUR HALL ROAD  PLUMSTEAD  CAPE TOWN",
    Latitude: "-34.0195",
    Longitude: "18.4665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21778",
    WardLookupId: "484",
    VDNumber: "97130291",
    RegisteredPopulation: "2087",
    VotingStationName: "WITTEBOME HGH SCHOOL",
    Address: "OTTERY ROAD  WITTEBOME  CAPE TOWN",
    Latitude: "-34.008",
    Longitude: "18.4751",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21779",
    WardLookupId: "484",
    VDNumber: "97130303",
    RegisteredPopulation: "1274",
    VotingStationName: "ST AUGUSTINES RC PRIMARY SCHOOL",
    Address: "1 CLARE RD  WITTEBOME  WYNBERG",
    Latitude: "-34.0141",
    Longitude: "18.4721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21780",
    WardLookupId: "484",
    VDNumber: "97130325",
    RegisteredPopulation: "1236",
    VotingStationName: "FOCUS COLLEGE",
    Address: "BELFAST RD  WYNBERG  CAPE TOWN",
    Latitude: "-34.0103",
    Longitude: "18.4783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21781",
    WardLookupId: "484",
    VDNumber: "97130437",
    RegisteredPopulation: "1884",
    VotingStationName: "1ST WYNSTEAD SCOUT HALL",
    Address: "2 EXMOUTH ROAD  PLUMSTEAD  CAPE TOWN",
    Latitude: "-34.0223",
    Longitude: "18.4756",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21782",
    WardLookupId: "484",
    VDNumber: "97130459",
    RegisteredPopulation: "2410",
    VotingStationName: "PLUMSTEAD HIGH SCHOOL",
    Address: "BASIL ROAD  PLUMSTEAD  ",
    Latitude: "-34.0218",
    Longitude: "18.4842",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21783",
    WardLookupId: "484",
    VDNumber: "97130752",
    RegisteredPopulation: "2986",
    VotingStationName: "OTTERY FIRE STATION",
    Address: "CNR NEW OTTERY & STRANDFONTEIN ROAD  OTTERY  ",
    Latitude: "-34.0165",
    Longitude: "18.5212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21784",
    WardLookupId: "484",
    VDNumber: "97130763",
    RegisteredPopulation: "3443",
    VotingStationName: "ELM STREET MFMC (STEPHANIANS FC OTTERY)",
    Address: "TRISTANIA  OTTERY  CAPE TOWN",
    Latitude: "-34.0194",
    Longitude: "18.5064",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21785",
    WardLookupId: "484",
    VDNumber: "97130808",
    RegisteredPopulation: "2023",
    VotingStationName: "HYDE PARK PRIMARY SCHOOL",
    Address: "HYDE ROAD  FAIRWAYS  ",
    Latitude: "-34.0269",
    Longitude: "18.4933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21786",
    WardLookupId: "484",
    VDNumber: "97131236",
    RegisteredPopulation: "1501",
    VotingStationName: "OTTERY ISLAMIC SOCIETY",
    Address: "64 OLD STRANDFONTEIN  OTTERY  CITY OF CAPE TOWN [CAPE TOWN]",
    Latitude: "-34.0094",
    Longitude: "18.5168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21787",
    WardLookupId: "485",
    VDNumber: "97130033",
    RegisteredPopulation: "5359",
    VotingStationName: "MUIZENBERG HIGH SCHOOL",
    Address: "CNR DOVER & WINDERMERE ROADS  MUIZENBERG  ",
    Latitude: "-34.0983",
    Longitude: "18.4775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21788",
    WardLookupId: "485",
    VDNumber: "97130044",
    RegisteredPopulation: "2869",
    VotingStationName: "ZANDVLEI IMPERIAL YACHT SPORTS CLUB",
    Address: "PROMENADE  ZANDVLEI  CAPE TOWN",
    Latitude: "-34.0905",
    Longitude: "18.4672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21789",
    WardLookupId: "485",
    VDNumber: "97130055",
    RegisteredPopulation: "1157",
    VotingStationName: "KALK BAY COMMUNITY CENTRE",
    Address: "MAIN ROAD  KALK BAY  ",
    Latitude: "-34.1238",
    Longitude: "18.4522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21790",
    WardLookupId: "485",
    VDNumber: "97130088",
    RegisteredPopulation: "2989",
    VotingStationName: "FISH HOEK CIVIC CENTRE",
    Address: "CENTRAL CIRCLE  FISH HOEK",
    Latitude: "-34.1365",
    Longitude: "18.4267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21791",
    WardLookupId: "485",
    VDNumber: "97130099",
    RegisteredPopulation: "3380",
    VotingStationName: "FISH HOEK PRIMARY SCHOOL",
    Address: "UPPER RECREATION RD,FISH HOEK  FISH HOEK  CAPE TOWN",
    Latitude: "-34.1306",
    Longitude: "18.4218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21792",
    WardLookupId: "485",
    VDNumber: "97130101",
    RegisteredPopulation: "2665",
    VotingStationName: "FALSE BAY COLLEGE FISH HOEK",
    Address: "KOMMETJIE ROAD  FISH HOEK  CITY OF CAPE TOWN",
    Latitude: "-34.1381",
    Longitude: "18.4239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21793",
    WardLookupId: "485",
    VDNumber: "97130381",
    RegisteredPopulation: "1712",
    VotingStationName: "MUIZENBERG 1 CIVIC CENTRE",
    Address: "ATLANTIC ROAD  CAPE TOWN  CITY OF CAPE TOWN",
    Latitude: "-34.1068",
    Longitude: "18.4721",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21794",
    WardLookupId: "486",
    VDNumber: "97130864",
    RegisteredPopulation: "2383",
    VotingStationName: "PERIVALE PRIMARY SCHOOL",
    Address: "CNR KLIP & ZEEKOE RDS  LOTUS RIVER  ",
    Latitude: "-34.0354",
    Longitude: "18.512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21795",
    WardLookupId: "486",
    VDNumber: "97130897",
    RegisteredPopulation: "2037",
    VotingStationName: "LOTUS RIVER PRIMARY SCHOOL",
    Address: "CNR 5TH AVE & LAKE ROAD  GRASSY PARK  ",
    Latitude: "-34.0413",
    Longitude: "18.5075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21796",
    WardLookupId: "486",
    VDNumber: "97130921",
    RegisteredPopulation: "2540",
    VotingStationName: "ST CLEMENTS CATHOLIC PRIMARY SCHOOL",
    Address: "CNR LAKE ROAD & 7TH AVENUE  GRASSY PARK  LOTUS RIVER",
    Latitude: "-34.0439",
    Longitude: "18.5097",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21797",
    WardLookupId: "486",
    VDNumber: "97130932",
    RegisteredPopulation: "2030",
    VotingStationName: "ZEEKOEVLEI PRIMARY SCHOOL",
    Address: "370 6TH AVENUE  CAPE TOWN  LOTUS RIVER",
    Latitude: "-34.0421",
    Longitude: "18.522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21798",
    WardLookupId: "486",
    VDNumber: "97130943",
    RegisteredPopulation: "1709",
    VotingStationName: "RIVER TABERNACLE PENTECOSTAL HOLINESS CHURCH",
    Address: "KUDU AVENUE  LOTUS RIVER  CAPE TOWN",
    Latitude: "-34.0387",
    Longitude: "18.5234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21799",
    WardLookupId: "486",
    VDNumber: "97130965",
    RegisteredPopulation: "1512",
    VotingStationName: "FAIRVIEW PRIMARY SCHOOL",
    Address: "FIRST  AVENUE  GRASSY PARK  ",
    Latitude: "-34.0385",
    Longitude: "18.5017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21800",
    WardLookupId: "486",
    VDNumber: "97130976",
    RegisteredPopulation: "2396",
    VotingStationName: "GRASSDALE HIGH SCHOOL",
    Address: "4TH AVENUE  GRASSY PARK  ",
    Latitude: "-34.0429",
    Longitude: "18.5038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21801",
    WardLookupId: "486",
    VDNumber: "97131157",
    RegisteredPopulation: "1998",
    VotingStationName: "DIE DUINE PRIMARY SCHOOL",
    Address: "CNR 2ND AVE & ANTILOPE RD  LOTUS RIVER  ",
    Latitude: "-34.035",
    Longitude: "18.5243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21802",
    WardLookupId: "486",
    VDNumber: "97131203",
    RegisteredPopulation: "735",
    VotingStationName: "STEPHEN ROAD PRIMARY SCHOOL",
    Address: "STEPHEN ROAD  LOTUS RIVER  ",
    Latitude: "-34.0315",
    Longitude: "18.523",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21803",
    WardLookupId: "487",
    VDNumber: "97130785",
    RegisteredPopulation: "1708",
    VotingStationName: "PLANTATION PRIMARY SCHOOL",
    Address: "GRACE ROAD  GRASSY PARK  ",
    Latitude: "-34.0312",
    Longitude: "18.5056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21804",
    WardLookupId: "487",
    VDNumber: "97130796",
    RegisteredPopulation: "1550",
    VotingStationName: "FERNDALE PRIMARY SCHOOL",
    Address: "OLD OTTERY ROAD  OTTERY  ",
    Latitude: "-34.0196",
    Longitude: "18.5143",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21805",
    WardLookupId: "487",
    VDNumber: "97130819",
    RegisteredPopulation: "2409",
    VotingStationName: "PARKWOOD ASSEMBLY OF GOD",
    Address: "WATTLE ROAD  PARKWOOD  ",
    Latitude: "-34.0278",
    Longitude: "18.4962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21806",
    WardLookupId: "487",
    VDNumber: "97130842",
    RegisteredPopulation: "1717",
    VotingStationName: "EC PRIMARY SCHOOL",
    Address: "VICTORIA ROAD  GRASSY PARK  CAPE TOWN",
    Latitude: "-34.0458",
    Longitude: "18.4943",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21807",
    WardLookupId: "487",
    VDNumber: "97130853",
    RegisteredPopulation: "1766",
    VotingStationName: "MONTAGUES GIFT PRIMARY SCHOOL",
    Address: "TERN ROAD  PARKWOOD  ",
    Latitude: "-34.031",
    Longitude: "18.5036",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21808",
    WardLookupId: "487",
    VDNumber: "97130886",
    RegisteredPopulation: "2143",
    VotingStationName: "WORD OF FAITH COMMUNITY BAPTIST CHURCH",
    Address: "CNR GEORGE ROAD & IVAN WAY  CAPE TOWN  OTTERY",
    Latitude: "-34.0261",
    Longitude: "18.5209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21809",
    WardLookupId: "487",
    VDNumber: "97131090",
    RegisteredPopulation: "2033",
    VotingStationName: "LOTUS HIGH SCHOOL",
    Address: "PARK CRESCENT, LOTUS RIVER  LOTUS RIVER   CAPE TOWN",
    Latitude: "-34.0272",
    Longitude: "18.511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21810",
    WardLookupId: "487",
    VDNumber: "97131135",
    RegisteredPopulation: "3637",
    VotingStationName: "SOUTHERN SUBURBS YOUTH ACADEMY",
    Address: "CNR ACACIA & HOSSIAN PARK AVES  GRASSY PARK  PARKWOOD ESTATES",
    Latitude: "-34.0331",
    Longitude: "18.4982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21811",
    WardLookupId: "488",
    VDNumber: "97130190",
    RegisteredPopulation: "5227",
    VotingStationName: "CAPRICORN PRIMARY SCHOOL",
    Address: "CNR NEVILLE RILEY & VRYGROND ROAD  CAPRICORN PARK  CAPE TOWN",
    Latitude: "-34.0842",
    Longitude: "18.4873",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21812",
    WardLookupId: "488",
    VDNumber: "97130954",
    RegisteredPopulation: "3088",
    VotingStationName: "ZEEKOEIVLEI HIGH SCHOOL",
    Address: "7TH AVENUE  LOTUS RIVER  ",
    Latitude: "-34.0428",
    Longitude: "18.5236",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21813",
    WardLookupId: "488",
    VDNumber: "97131023",
    RegisteredPopulation: "1905",
    VotingStationName: "ROOIKRANTS SPORTS & RECREATION CENTRE",
    Address: "C/O ITALIAN AND ROOIKRANTS AVENUE  ZEEKOEVLEI  CAPE TOWN",
    Latitude: "-34.0589",
    Longitude: "18.4937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21814",
    WardLookupId: "488",
    VDNumber: "97131045",
    RegisteredPopulation: "4986",
    VotingStationName: "PELICAN PARK PRIMARY SCHOOL",
    Address: "- 42 BARBET ROAD, PELIKAN PARK  PELICAN PARK  CAPE TOWN",
    Latitude: "-34.0621",
    Longitude: "18.5265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21815",
    WardLookupId: "488",
    VDNumber: "97131124",
    RegisteredPopulation: "6428",
    VotingStationName: "ZERILDA PARK PRIMARY SCHOOL",
    Address: "STORMVOEL SQUARE  SEAWIND  CAPE TOWN",
    Latitude: "-34.0764",
    Longitude: "18.4872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21816",
    WardLookupId: "489",
    VDNumber: "97130189",
    RegisteredPopulation: "3203",
    VotingStationName: "CONISTON PARK COMMUNITY CENTRE",
    Address: "MILITARY ROAD  STEENBERG  ",
    Latitude: "-34.0756",
    Longitude: "18.472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21817",
    WardLookupId: "489",
    VDNumber: "97130224",
    RegisteredPopulation: "4871",
    VotingStationName: "HILLWOOD PRIMARY SCHOOL",
    Address: "- 4 ST SIMON ST, LAVENDER HILL   LAVENDER HILL  CAPE TOWN",
    Latitude: "-34.0708",
    Longitude: "18.4885",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21818",
    WardLookupId: "489",
    VDNumber: "97130718",
    RegisteredPopulation: "2105",
    VotingStationName: "METROPOLITAN EVANGELISTIC CHURCH",
    Address: "41 HILLARY DRIVE  LAVENDER HILL  CAPE TOWN",
    Latitude: "-34.0728",
    Longitude: "18.4839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21819",
    WardLookupId: "489",
    VDNumber: "97130729",
    RegisteredPopulation: "1612",
    VotingStationName: "ST ANNES CATHOLIC CHURCH",
    Address: "CNR HEYDEN/ORCHESTRA STREETS  STEENBERG  ",
    Latitude: "-34.0734",
    Longitude: "18.4737",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21820",
    WardLookupId: "489",
    VDNumber: "97130730",
    RegisteredPopulation: "2987",
    VotingStationName: "STEENBERG PRIMARY SCHOOL",
    Address: "- 5 BASSOON STREET  STEENBERG  CAPE TOWN",
    Latitude: "-34.0711",
    Longitude: "18.4778",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21821",
    WardLookupId: "490",
    VDNumber: "97130112",
    RegisteredPopulation: "3835",
    VotingStationName: "KING OF KINGS BAPTIST CHURCH",
    Address: "CNR OU KAAPSE ROAD & BULLER LOUW DRIVE  SUNNYDALE  CAPE TOWN",
    Latitude: "-34.1233",
    Longitude: "18.3916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21822",
    WardLookupId: "490",
    VDNumber: "97130123",
    RegisteredPopulation: "2372",
    VotingStationName: "SUN VALLEY PRIMARY SCHOOL",
    Address: "CNR CORVETTE & BRIGANTINE AVE  SUNNYVALE  CAPE TOWN",
    Latitude: "-34.1214",
    Longitude: "18.3985",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21823",
    WardLookupId: "490",
    VDNumber: "97130178",
    RegisteredPopulation: "5744",
    VotingStationName: "UKHANYO PRIMARY SCHOOL",
    Address: "POKELA ROAD  CAPE TOWN  CAPE TOWN",
    Latitude: "-34.129",
    Longitude: "18.3799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21824",
    WardLookupId: "490",
    VDNumber: "97130268",
    RegisteredPopulation: "2994",
    VotingStationName: "SILVERMINE RETIREMENT VILLAGE",
    Address: "SILVERMINE ROAD  NOORDHOEK  CAPE TOWN",
    Latitude: "-34.1081",
    Longitude: "18.3959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21825",
    WardLookupId: "490",
    VDNumber: "97131191",
    RegisteredPopulation: "3652",
    VotingStationName: "KWA NONZONDELELO METHODIST CHURCH",
    Address: "RIVERSIDE ROAD  MASIPHUMELELE  FISH HOEK",
    Latitude: "-34.1291",
    Longitude: "18.3741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21826",
    WardLookupId: "491",
    VDNumber: "97140933",
    RegisteredPopulation: "4049",
    VotingStationName: "WELGEMOED PRIMARY SCHOOL",
    Address: "/ JIP DE JAGER DR  WELGEMOED  BELLVILLE",
    Latitude: "-33.8727",
    Longitude: "18.6176",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21827",
    WardLookupId: "491",
    VDNumber: "97140944",
    RegisteredPopulation: "2552",
    VotingStationName: "BOSTON PRIMARY SCHOOL SPORTS GROUND",
    Address: "LINCOLN STREET  BOSTON  BELLVILLE",
    Latitude: "-33.8876",
    Longitude: "18.6178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21828",
    WardLookupId: "491",
    VDNumber: "97140999",
    RegisteredPopulation: "4297",
    VotingStationName: "KENRIDGE PRIMARY SCHOOL",
    Address: "/ VAN RIEBEECK AVENUE  KENRIDGE  CAPE TOWN",
    Latitude: "-33.8598",
    Longitude: "18.6348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21829",
    WardLookupId: "491",
    VDNumber: "97141002",
    RegisteredPopulation: "4210",
    VotingStationName: "NG KERK KENRIDGE",
    Address: "MAESTRICHT STREET  DE BRON  BELLVILLE",
    Latitude: "-33.8623",
    Longitude: "18.6318",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21830",
    WardLookupId: "491",
    VDNumber: "97141934",
    RegisteredPopulation: "3085",
    VotingStationName: "PROTEA VALLEY CHURCH",
    Address:
      "47 VAN RIEBEECK HOFF ROAD  VAN RIEBEECK HOFF BELLVILLE  CAPE TOWN",
    Latitude: "-33.8534",
    Longitude: "18.6112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21831",
    WardLookupId: "492",
    VDNumber: "97130392",
    RegisteredPopulation: "1751",
    VotingStationName: "CAPE ACADEMY FOR MATHS, SCIENCE & TECHNOLOGY",
    Address: "FIRGROVE WAY  CONSTANTIA  CAPE TOWN",
    Latitude: "-34.0478",
    Longitude: "18.4295",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21832",
    WardLookupId: "492",
    VDNumber: "97130404",
    RegisteredPopulation: "4073",
    VotingStationName: "TOKAI COMMUNITY CHURCH",
    Address: "28 TOKAI ROAD  TOKAI  TOKAI",
    Latitude: "-34.0634",
    Longitude: "18.444",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21833",
    WardLookupId: "492",
    VDNumber: "97130415",
    RegisteredPopulation: "3297",
    VotingStationName: "WESTLAKE UNITED CHURCH COMMANDO HALL",
    Address: "CNR WESTLAKE DRIVE & EGRET RD  WESTLAKE  CAPE TOWN",
    Latitude: "-34.0753",
    Longitude: "18.4398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21834",
    WardLookupId: "492",
    VDNumber: "97130909",
    RegisteredPopulation: "3629",
    VotingStationName: "CHURCH OF CHRIST LAKESIDE",
    Address: "426 MAIN ROAD  LAKESIDE  CAPE TOWN",
    Latitude: "-34.08",
    Longitude: "18.4557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21835",
    WardLookupId: "492",
    VDNumber: "97131180",
    RegisteredPopulation: "1982",
    VotingStationName: "KIRSTENHOF PRIMARY SCHOOL",
    Address: "LENTE STREET  KIRSTENHOF  CITY OF CAPE TOWN [CAPE TOWN]",
    Latitude: "-34.0697",
    Longitude: "18.4543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21836",
    WardLookupId: "492",
    VDNumber: "97131247",
    RegisteredPopulation: "1392",
    VotingStationName: "ZWAANSWYK HIGH SCHOOL",
    Address: "ZWAANSWYK VILLAGE   DREYERSDAL  CITY OF CAPE TOWN [CAPE TOWN]",
    Latitude: "-34.0602",
    Longitude: "18.4587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21837",
    WardLookupId: "493",
    VDNumber: "97130482",
    RegisteredPopulation: "1132",
    VotingStationName: "HEATHFIELD PRIMARY SCHOOL",
    Address: "CHADWIN RD  HEATHFIELD  CAPE TOWN",
    Latitude: "-34.0415",
    Longitude: "18.472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21838",
    WardLookupId: "493",
    VDNumber: "97130516",
    RegisteredPopulation: "2613",
    VotingStationName: "SOUTHFIELD PRIMARY SCHOOL",
    Address: "37 DIRKIE UYS STREET  SOUTHFIELD  CAPE TOWN",
    Latitude: "-34.033",
    Longitude: "18.4867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21839",
    WardLookupId: "493",
    VDNumber: "97130561",
    RegisteredPopulation: "1364",
    VotingStationName: "ST AUGUSTINES CRICKET CLUB",
    Address: "ROSCOMMON ROAD  ELFINDALE  HEATHFIELD",
    Latitude: "-34.0463",
    Longitude: "18.4782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21840",
    WardLookupId: "493",
    VDNumber: "97130572",
    RegisteredPopulation: "2311",
    VotingStationName: "DEAF COMMUNITY OF CAPE TOWN",
    Address: "GORDON ROAD  HEATHFIELD  CAPE TOWN",
    Latitude: "-34.0451",
    Longitude: "18.4682",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21841",
    WardLookupId: "493",
    VDNumber: "97130594",
    RegisteredPopulation: "1839",
    VotingStationName: "ST MARY`S ROMAN CATHOLIC PRIMARY SCHOOL",
    Address: "RETREAT ROAD  RETREAT  CAPE TOWN",
    Latitude: "-34.0562",
    Longitude: "18.4807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21842",
    WardLookupId: "493",
    VDNumber: "97130606",
    RegisteredPopulation: "1171",
    VotingStationName: "THOMAS WILDSCHUT SENIOR SCHOOL",
    Address: "5TH AVENUE  RETREAT  CAPE TOWN",
    Latitude: "-34.055",
    Longitude: "18.4703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21843",
    WardLookupId: "493",
    VDNumber: "97130617",
    RegisteredPopulation: "1988",
    VotingStationName: "THOMAS WILDSCHUTT JUNIOR PRIMARY SCHOOL",
    Address:
      "CNR 2ND AVENUE&LANGVLEI ROAD  RETREAT  (CPT - CITY OF CAPE TOWN [CAPE TOWN])",
    Latitude: "-34.0564",
    Longitude: "18.4681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21844",
    WardLookupId: "493",
    VDNumber: "97130640",
    RegisteredPopulation: "1568",
    VotingStationName: "SQUARE HILL PRIMARY SCHOOL",
    Address: "CNR SCHOOL & BOUNDRY ROAD  RETREAT  CAPE TOWN",
    Latitude: "-34.0614",
    Longitude: "18.4718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21845",
    WardLookupId: "493",
    VDNumber: "97130673",
    RegisteredPopulation: "2184",
    VotingStationName: "SQUARE HILL COMMUNITY CENTRE",
    Address: "CNR ALLENBY & CONCERT BLVD  RETREAT  ",
    Latitude: "-34.0627",
    Longitude: "18.4681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21846",
    WardLookupId: "493",
    VDNumber: "97130695",
    RegisteredPopulation: "1259",
    VotingStationName: "FLOREAT PRIMARY SCHOOL",
    Address: "LUTE ROAD  STEENBERG",
    Latitude: "-34.0689",
    Longitude: "18.4757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21847",
    WardLookupId: "494",
    VDNumber: "97130370",
    RegisteredPopulation: "3698",
    VotingStationName: "SOUTH PENINSULA HIGH SCHOOL",
    Address: "OLD KENDAL ROAD  DIEP RIVER  CITY OF CAPE TOWN",
    Latitude: "-34.0293",
    Longitude: "18.462",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21848",
    WardLookupId: "494",
    VDNumber: "97130448",
    RegisteredPopulation: "1633",
    VotingStationName: "PLUMSTEAD TENNIS CLUB",
    Address: "25 VICTORIA ROAD  PLUMSTEAD  ",
    Latitude: "-34.0259",
    Longitude: "18.4745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21849",
    WardLookupId: "494",
    VDNumber: "97130460",
    RegisteredPopulation: "2414",
    VotingStationName: "SECOND PLUMSTEAD SEA SCOUT GROUP",
    Address: "33 MELVILLE RD  PLUMSTEAD  ",
    Latitude: "-34.0322",
    Longitude: "18.4739",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21850",
    WardLookupId: "494",
    VDNumber: "97130471",
    RegisteredPopulation: "1899",
    VotingStationName: "SOUTHFIELD LIBRARY",
    Address: "CNR VICTORIA & CASSINO ROADS  SOUTHFIELD  ",
    Latitude: "-34.0302",
    Longitude: "18.4806",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21851",
    WardLookupId: "494",
    VDNumber: "97130493",
    RegisteredPopulation: "2565",
    VotingStationName: "MEADOWRIDGE LIBRARY",
    Address: "FIRGROVE WAY  MEADOWRIDGE  ",
    Latitude: "-34.0393",
    Longitude: "18.4543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21852",
    WardLookupId: "494",
    VDNumber: "97130505",
    RegisteredPopulation: "2490",
    VotingStationName: "WESTCOTT PRIMARY SCHOOL",
    Address: "MYBURGH  DIEP RIVER  CAPE TOWN",
    Latitude: "-34.0363",
    Longitude: "18.4613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21853",
    WardLookupId: "494",
    VDNumber: "97130538",
    RegisteredPopulation: "2525",
    VotingStationName: "FIRST BERGVLIET SEA SCOUT GROUP",
    Address: "LADIES MILE SERVICE RD  BERGVLIET  ",
    Latitude: "-34.0481",
    Longitude: "18.4538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21854",
    WardLookupId: "494",
    VDNumber: "97130549",
    RegisteredPopulation: "2198",
    VotingStationName: "BERGVLIET PRIMARY SCHOOL",
    Address: "CHILDRENS WAY  BERGVLIET  CAPE TOWN",
    Latitude: "-34.0465",
    Longitude: "18.4596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21855",
    WardLookupId: "495",
    VDNumber: "97130022",
    RegisteredPopulation: "3312",
    VotingStationName: "GOSPEL OUTREACH MINISTRIES",
    Address: "G SHOPPE STREET  IMIZAMO YETHU  HOUT BAY",
    Latitude: "-34.0269",
    Longitude: "18.3632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21856",
    WardLookupId: "495",
    VDNumber: "97130684",
    RegisteredPopulation: "3221",
    VotingStationName: "HOUT BAY CHRISTIAN COMMUNITY ASSOCIATION",
    Address: "R N MANDELA ROAD  IMZAMO YETHU  HOUT BAY",
    Latitude: "-34.0301",
    Longitude: "18.3599",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21857",
    WardLookupId: "495",
    VDNumber: "97131067",
    RegisteredPopulation: "2090",
    VotingStationName: "ORANJEKLOOF MORAVIAN PRIMARY SCHOOL",
    Address: "CNR HEUGENDON & MAIN ROADS  HOUT BAY  CAPE TOWN",
    Latitude: "-34.0227",
    Longitude: "18.3652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21858",
    WardLookupId: "495",
    VDNumber: "97131078",
    RegisteredPopulation: "1964",
    VotingStationName: "LLANDUDNO PRIMARY SCHOOL",
    Address: "GULLEY ROAD  LLANDUDNO  CAPE TOWN",
    Latitude: "-34.0093",
    Longitude: "18.3455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21859",
    WardLookupId: "495",
    VDNumber: "97131089",
    RegisteredPopulation: "4130",
    VotingStationName: "HOUTBAY LIBRARY",
    Address: "MELKHOUT CRESCENT  HOUT BAY  ",
    Latitude: "-34.0442",
    Longitude: "18.3581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21860",
    WardLookupId: "495",
    VDNumber: "97131102",
    RegisteredPopulation: "2782",
    VotingStationName: "SENTINEL PRIMARY SCHOOL",
    Address: "6 ATLANTIC SKIPPER  HOUTBAY  HOUTBAY",
    Latitude: "-34.0549",
    Longitude: "18.3437",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21861",
    WardLookupId: "496",
    VDNumber: "97091589",
    RegisteredPopulation: "4180",
    VotingStationName: "NORTHWOOD PRIMARY SCHOOL",
    Address: "MIVERVA STREET  WOODLANDS  MITCHELLS PLAIN",
    Latitude: "-34.0346",
    Longitude: "18.5952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21862",
    WardLookupId: "496",
    VDNumber: "97091613",
    RegisteredPopulation: "3016",
    VotingStationName: "WOODVILLE PRIMARY SCHOOL",
    Address: "0 JUNO ROAD  WOODLANDS  MITCHELLS PLAIN",
    Latitude: "-34.0365",
    Longitude: "18.5996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21863",
    WardLookupId: "496",
    VDNumber: "97091624",
    RegisteredPopulation: "4031",
    VotingStationName: "COLORADO COMMUNITY CENTRE",
    Address: "CNR WELTEVREDE PARKWAY & ROSEWOOD DR  WELTEVREDE VALLEY  ",
    Latitude: "-34.0269",
    Longitude: "18.5876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21864",
    WardLookupId: "496",
    VDNumber: "97091703",
    RegisteredPopulation: "2835",
    VotingStationName: "NORTHWOOD COMMUNITY HALL",
    Address:
      "CNR HANS ASCHEBORN ST & WALTER BATISS DRIVE  NEW WOODLANDS  MITCHELLS PLAIN",
    Latitude: "-34.0238",
    Longitude: "18.5993",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21865",
    WardLookupId: "496",
    VDNumber: "97091714",
    RegisteredPopulation: "2515",
    VotingStationName: "EAGLES NEST MINISTRIES",
    Address: "9 JOHANNES MEINTJIES RD  NEW WOODLANDS  MITCHELLS PLAIN",
    Latitude: "-34.0262",
    Longitude: "18.6028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21866",
    WardLookupId: "497",
    VDNumber: "97091635",
    RegisteredPopulation: "2593",
    VotingStationName: "MERRYDALE PRIMARY SCHOOL",
    Address: "CNR MERRYDALE AVE & BERGONIA RD  LENTEGEUR  MITCHELLS PLAIN",
    Latitude: "-34.0378",
    Longitude: "18.6086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21867",
    WardLookupId: "497",
    VDNumber: "97091646",
    RegisteredPopulation: "2577",
    VotingStationName: "SPRINGDALE PRIMARY SCHOOL",
    Address: "MARRYDALE AVENUE  LENTEGEUR  CITY OF CAPE TOWN [CAPE TOWN]",
    Latitude: "-34.0334",
    Longitude: "18.6062",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21868",
    WardLookupId: "497",
    VDNumber: "97091657",
    RegisteredPopulation: "4207",
    VotingStationName: "MEADOWRIDGE PRIMARY SCHOOL",
    Address: "KREUPELHOUT AVENUE  LENTEGEUR  CAPE TOWN",
    Latitude: "-34.0332",
    Longitude: "18.6175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21869",
    WardLookupId: "497",
    VDNumber: "97091668",
    RegisteredPopulation: "2085",
    VotingStationName: "HYACINTH PRIMARY SCHOOL",
    Address: "HYACINTH  LENTEGEUR  CITY OF CAPE TOWN [CAPE TOWN]",
    Latitude: "-34.0328",
    Longitude: "18.6136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21870",
    WardLookupId: "497",
    VDNumber: "97091679",
    RegisteredPopulation: "1678",
    VotingStationName: "WEST END PRIMARY SCHOOL",
    Address: "CNR SNAPDRAGON & MERRYDALE AVE  LENTEGEUR  MTICHELLS PLAIN",
    Latitude: "-34.0306",
    Longitude: "18.6029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21871",
    WardLookupId: "497",
    VDNumber: "97091691",
    RegisteredPopulation: "1523",
    VotingStationName: "LENTEGEUR PSYCHIATRIC HOSPITAL",
    Address: "HIGHLANDS DRIVE  LENTEGEUR  MITCHELLS PLAIN",
    Latitude: "-34.0258",
    Longitude: "18.6167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21872",
    WardLookupId: "497",
    VDNumber: "97093446",
    RegisteredPopulation: "1182",
    VotingStationName: "LIVING STONES ASSEMBLY OF GOD",
    Address: "AZ BERMAN   MITCHELLS PLAIN  CAPE TOWN",
    Latitude: "-34.0229",
    Longitude: "18.6079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21873",
    WardLookupId: "498",
    VDNumber: "97090094",
    RegisteredPopulation: "2408",
    VotingStationName: "SCHOTSCHE KLOOF CIVIC CENTRE",
    Address: "WALE STREET  SCHOTSCHEKLOOF  CAPE TOWN",
    Latitude: "-33.9198",
    Longitude: "18.4133",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21874",
    WardLookupId: "498",
    VDNumber: "97090263",
    RegisteredPopulation: "3896",
    VotingStationName: "JAN VAN RIEBEEK HIGH SCHOOL",
    Address: "WELGEMEEN STREET  TAMBOERSKLOOF  CAPE TOWN",
    Latitude: "-33.9337",
    Longitude: "18.408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21875",
    WardLookupId: "498",
    VDNumber: "97090274",
    RegisteredPopulation: "2875",
    VotingStationName: "GOOD HOPE SEMINARY JUNIOR SCHOOL",
    Address: "21 VREDEHOEK AVENUE  VREDEHOEK  CAPE TOWN",
    Latitude: "-33.937",
    Longitude: "18.4214",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21876",
    WardLookupId: "498",
    VDNumber: "97090285",
    RegisteredPopulation: "2807",
    VotingStationName: "ST CYPRIANS SCHOOL",
    Address: "GORGE ROAD  ORANJEZICHT  CAPE TOWN",
    Latitude: "-33.9419",
    Longitude: "18.4174",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21877",
    WardLookupId: "498",
    VDNumber: "97090296",
    RegisteredPopulation: "3576",
    VotingStationName: "NAZARETH HOUSE",
    Address: "1 DERRY STREET  VREDEHOEK  CAPE TOWN",
    Latitude: "-33.9373",
    Longitude: "18.4249",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21878",
    WardLookupId: "498",
    VDNumber: "97092074",
    RegisteredPopulation: "2710",
    VotingStationName: "TAMBOERSKLOOF PRIMARY SCHOOL",
    Address: "BELLE OMBRE ROAD  TAMBOERSKLOOF  CAPE TOWN",
    Latitude: "-33.9306",
    Longitude: "18.4056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21879",
    WardLookupId: "499",
    VDNumber: "97091286",
    RegisteredPopulation: "3328",
    VotingStationName: "PARKHURST PRIMARY SCHOOL",
    Address: "CNR SILVERSANDS & PARK AVE  WESTRIDGE  CAPE TOWN",
    Latitude: "-34.0485",
    Longitude: "18.5997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21880",
    WardLookupId: "499",
    VDNumber: "97091297",
    RegisteredPopulation: "3243",
    VotingStationName: "MITCHELLS PLEIN PRIMARY SCHOOL NO 1",
    Address: "CNR DAGBREEK & PARK AVE  WESTRIDGE  CAPE TOWN",
    Latitude: "-34.0459",
    Longitude: "18.5942",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21881",
    WardLookupId: "499",
    VDNumber: "97091309",
    RegisteredPopulation: "3101",
    VotingStationName: "PORTLAND HIGH SCHOOL",
    Address: "CNR MORGENSTER & MERRYDALE RDS  PORTLANDS  CAPE TOWN",
    Latitude: "-34.0402",
    Longitude: "18.6108",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21882",
    WardLookupId: "499",
    VDNumber: "97091343",
    RegisteredPopulation: "2549",
    VotingStationName: "WESTVILLE PRIMARY SCHOOL",
    Address: "CNR CEDAR & DE DUIN RD  WESTRIDGE  MITCHELLS PLAIN",
    Latitude: "-34.0568",
    Longitude: "18.6069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21883",
    WardLookupId: "499",
    VDNumber: "97091365",
    RegisteredPopulation: "2278",
    VotingStationName: "WESTRIDGE CIVIC CENTRE",
    Address: "WESPOORT AVE & DE DUIN  WESTRIDGE  CAPE TOWN",
    Latitude: "-34.0517",
    Longitude: "18.6033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21884",
    WardLookupId: "499",
    VDNumber: "97091400",
    RegisteredPopulation: "2367",
    VotingStationName: "PORTLAND PRIMARY SCHOOL",
    Address: "KUNENE WAY  PORTLANDS  MITCHELLS PLAIN",
    Latitude: "-34.0479",
    Longitude: "18.6113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21885",
    WardLookupId: "500",
    VDNumber: "97090072",
    RegisteredPopulation: "1594",
    VotingStationName: "CHRIST THE MEDIATOR ANGLICAN CHURCH",
    Address: "WALL STREET  PORTLAND  CAPE TOWN",
    Latitude: "-34.0499",
    Longitude: "18.617",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21886",
    WardLookupId: "500",
    VDNumber: "97091411",
    RegisteredPopulation: "2570",
    VotingStationName: "EASTRIDGE COMMUNITY CENTRE",
    Address: "CNR SIEGFRIED & DON CARLOS WAY  EASTRIDGE  MITCHELLS PLAIN",
    Latitude: "-34.0469",
    Longitude: "18.6333",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21887",
    WardLookupId: "500",
    VDNumber: "97091422",
    RegisteredPopulation: "2659",
    VotingStationName: "BEACON VIEW PRIMARY SCHOOL",
    Address: "52 WANDERERS STREET  BEACON VALLEY  CAPE TOWN",
    Latitude: "-34.0414",
    Longitude: "18.6252",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21888",
    WardLookupId: "500",
    VDNumber: "97091444",
    RegisteredPopulation: "2466",
    VotingStationName: "BEACONVALE COMMUNITY FRAIL CARE CENTRE",
    Address: "CNR PONTIAC & RAMBLER ROADS  BEACON VALLEY  CAPE TOWN",
    Latitude: "-34.0452",
    Longitude: "18.6229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21889",
    WardLookupId: "500",
    VDNumber: "97091466",
    RegisteredPopulation: "2191",
    VotingStationName: "MITCHELLS PLAIN LIBRARY",
    Address: "MELODY SQUARE TOWN CENTRE  EASTRIDGE  CAPE TOWN",
    Latitude: "-34.0486",
    Longitude: "18.6234",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21890",
    WardLookupId: "500",
    VDNumber: "97091477",
    RegisteredPopulation: "1853",
    VotingStationName: "EASTVILLE PRIMARY SCHOOL",
    Address: "CNR BAOBAB & KATDORING ROADS  EASTRIDGE  MITCHELLS PLAIN",
    Latitude: "-34.0551",
    Longitude: "18.6262",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21891",
    WardLookupId: "500",
    VDNumber: "97091488",
    RegisteredPopulation: "2163",
    VotingStationName: "IMPERIAL PRIMARY SCHOOL",
    Address: "IMPERIAL & CARDALLAC  BEACON VALLEY  MITCHELLS PLAIN",
    Latitude: "-34.0467",
    Longitude: "18.6278",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21892",
    WardLookupId: "500",
    VDNumber: "97091499",
    RegisteredPopulation: "3101",
    VotingStationName: "LITTLEWOOD PRIMARY SCHOOL",
    Address: "100 LOHENGRIN STREET  EASTRIDGE  MITCHELLS PLAIN",
    Latitude: "-34.0504",
    Longitude: "18.6356",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21893",
    WardLookupId: "501",
    VDNumber: "97091275",
    RegisteredPopulation: "3498",
    VotingStationName: "PHILIPPI MUNICIPALITY",
    Address: "SHARON STREET  PHILIPPI  ",
    Latitude: "-34.0052",
    Longitude: "18.5724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21894",
    WardLookupId: "501",
    VDNumber: "97091983",
    RegisteredPopulation: "4417",
    VotingStationName: "INTSEBENZISWANO SENIOR SECONDARY SCHOOL",
    Address: "TYWAKU ROAD  CAPE TOWN  PHILIPPI",
    Latitude: "-34.0066",
    Longitude: "18.5813",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21895",
    WardLookupId: "501",
    VDNumber: "97091994",
    RegisteredPopulation: "3811",
    VotingStationName: "BONGOLWETHU PRIMARY SCHOOL",
    Address: "OFF LANSDOWNE ROAD  PHILIPPI  ",
    Latitude: "-34.0038",
    Longitude: "18.5782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21896",
    WardLookupId: "501",
    VDNumber: "97092344",
    RegisteredPopulation: "1619",
    VotingStationName: "NONCEDO EDUCARE CENTRE",
    Address: "4541 UBUNTU STREET  PHILLIPI  PHILLIPI",
    Latitude: "-34.0097",
    Longitude: "18.5745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21897",
    WardLookupId: "501",
    VDNumber: "97092434",
    RegisteredPopulation: "867",
    VotingStationName: "VICTORIA MXENGE COMMUNITY HALL",
    Address: "CNR PLEIN WELCH & ZP MATOLENGWE  PHILLIPI  ",
    Latitude: "-34.0035",
    Longitude: "18.5649",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21898",
    WardLookupId: "501",
    VDNumber: "97093301",
    RegisteredPopulation: "1591",
    VotingStationName: "HOUSE OF WORSHIP MINISTRIES",
    Address:
      "CNR ASPENDENE & BARRY RD  PHILLIPI (BROWN`S FARM) MONWOOD  CAPE TOWN",
    Latitude: "-34.0049",
    Longitude: "18.5715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21899",
    WardLookupId: "501",
    VDNumber: "97093378",
    RegisteredPopulation: "2500",
    VotingStationName: "RUTH FIRST COMMUNITY CENTRE",
    Address: "SINQOLAMTHI  PHILLIPI  CITY OF CAPE TOWN [CAPE TOWN]",
    Latitude: "-34.0111",
    Longitude: "18.5748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21900",
    WardLookupId: "502",
    VDNumber: "97091264",
    RegisteredPopulation: "3500",
    VotingStationName: "HILLSIDE PRIMARY SCHOOL",
    Address: "- CORMORANT STREET  ROCKLANDS;  MITCHELLS PLAIN",
    Latitude: "-34.0636",
    Longitude: "18.5971",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21901",
    WardLookupId: "502",
    VDNumber: "97091310",
    RegisteredPopulation: "3367",
    VotingStationName: "CARADALE PRIMARY SCHOOL",
    Address: "CNR MERRYDALE   ROCKLANDS  CAPE TOWN",
    Latitude: "-34.0634",
    Longitude: "18.6184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21902",
    WardLookupId: "502",
    VDNumber: "97091321",
    RegisteredPopulation: "2258",
    VotingStationName: "GLENDALE HIGH SCHOOL",
    Address: "CNR CARAVELLE & WELTEVREDEN RDS  ROCKLANDS  MITCHELLS PLAIN",
    Latitude: "-34.0684",
    Longitude: "18.6093",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21903",
    WardLookupId: "502",
    VDNumber: "97091332",
    RegisteredPopulation: "2878",
    VotingStationName: "ROCKLANDS PRIMARY SCHOOL",
    Address: "PARK AVENUE  ROCKLANDS  CITY OF CAPE TOWN [CAPE TOWN]",
    Latitude: "-34.0603",
    Longitude: "18.6047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21904",
    WardLookupId: "502",
    VDNumber: "97091354",
    RegisteredPopulation: "2606",
    VotingStationName: "CARAVELLE PRIMARY SCHOOL",
    Address: "CNR CARAVELLE & WELTEVREDEN ROOADS  ROCKLANDS  CAPE TOWN",
    Latitude: "-34.0683",
    Longitude: "18.6078",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21905",
    WardLookupId: "502",
    VDNumber: "97091376",
    RegisteredPopulation: "3197",
    VotingStationName: "TENT AT MONTAGUE DRIVE, PORTLANDS",
    Address: "MONTAGUE DRIVE   PORTLANDS   MITCHELLS PLAIN",
    Latitude: "-34.0565",
    Longitude: "18.6131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21906",
    WardLookupId: "502",
    VDNumber: "97091387",
    RegisteredPopulation: "2023",
    VotingStationName: "HAZELDENE PRIMARY SCHOOL",
    Address: "HAZELDENE ROAD  PORTLAND  CAPE TOWN",
    Latitude: "-34.0511",
    Longitude: "18.6142",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21907",
    WardLookupId: "503",
    VDNumber: "97091523",
    RegisteredPopulation: "2243",
    VotingStationName: "TAFELSIG PRIMARY SCHOOL",
    Address: "OLIFANTSHOEK STREET  TAFELSIG  MITCHELLS PLAIN",
    Latitude: "-34.0592",
    Longitude: "18.6382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21908",
    WardLookupId: "503",
    VDNumber: "97091534",
    RegisteredPopulation: "2688",
    VotingStationName: "HUGENOT PRIMARY SCHOOL",
    Address: "42 HUGENOT AVENUE  TAFELSIG  MITCHELLS PLAIN",
    Latitude: "-34.0643",
    Longitude: "18.6419",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21909",
    WardLookupId: "503",
    VDNumber: "97091545",
    RegisteredPopulation: "3907",
    VotingStationName: "NEW TAFELSIG COMMUNITY CENTRE",
    Address: "115 CR DOLOMITE RD & KILIMANJARO STR  TAFELSIG  MITCHELLS PLAIN",
    Latitude: "-34.0653",
    Longitude: "18.6287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21910",
    WardLookupId: "503",
    VDNumber: "97091556",
    RegisteredPopulation: "3269",
    VotingStationName: "NELSON MANDELA YOUTH AND FAMILY CENTRE",
    Address: "CNR KILIMANJARO AND PYRENESS  TAFELSIG  CAPE TOWN",
    Latitude: "-34.0618",
    Longitude: "18.6378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21911",
    WardLookupId: "503",
    VDNumber: "97091567",
    RegisteredPopulation: "2139",
    VotingStationName: "YELLOWOOD PRIMARY SCHOOL",
    Address: "C/O YELLOWWOOD & SKELETON RD  EASTRIDGE  MITCHELLS PLAIN",
    Latitude: "-34.0613",
    Longitude: "18.6237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21912",
    WardLookupId: "503",
    VDNumber: "97091578",
    RegisteredPopulation: "2070",
    VotingStationName: "CASCADE PRIMARY SCHOOL",
    Address: "CNR CASCADE STR & SANDRIVER RD  TAFELSIG  MITCHELLS PLAIN",
    Latitude: "-34.0576",
    Longitude: "18.6303",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21913",
    WardLookupId: "504",
    VDNumber: "97110277",
    RegisteredPopulation: "2972",
    VotingStationName: "STRAND HIGH SCHOOL",
    Address: "SAREL CILLIERS STR  STRAND  CAPE TOWN",
    Latitude: "-34.1015",
    Longitude: "18.8389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21914",
    WardLookupId: "504",
    VDNumber: "97110301",
    RegisteredPopulation: "2437",
    VotingStationName: "N G KERK STRAND - NOORD",
    Address: "70 BRAND STREET  STRAND  ",
    Latitude: "-34.1058",
    Longitude: "18.8245",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21915",
    WardLookupId: "504",
    VDNumber: "97110312",
    RegisteredPopulation: "3345",
    VotingStationName: "LOCHNERHOF PRIMARY SCHOOL",
    Address: "80 SAREL CILLIERS STR  STRAND  ",
    Latitude: "-34.1065",
    Longitude: "18.8325",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21916",
    WardLookupId: "504",
    VDNumber: "97110323",
    RegisteredPopulation: "3650",
    VotingStationName: "HENDRIK LOUW PRIMARY SCHOOL",
    Address: "PIET RETIEF  STRAND  CAPE TOWN",
    Latitude: "-34.1132",
    Longitude: "18.8288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21917",
    WardLookupId: "504",
    VDNumber: "97110479",
    RegisteredPopulation: "2772",
    VotingStationName: "SILUKHANYO PRIMARY SCHOOL",
    Address: "XAKI  ASANDA VILLAGE  STRAND",
    Latitude: "-34.1071",
    Longitude: "18.8496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21918",
    WardLookupId: "504",
    VDNumber: "97110480",
    RegisteredPopulation: "2056",
    VotingStationName: "ANOINTED CHRISTIAN MINISTRIES - NOMZAMO",
    Address: "26828 XAKI STREET  ASANDA VILLAGE  STRAND",
    Latitude: "-34.1077",
    Longitude: "18.8495",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21919",
    WardLookupId: "505",
    VDNumber: "97110109",
    RegisteredPopulation: "1492",
    VotingStationName: "ST MATTHEWS ANGLICAN CHURCH - SIR LOWRYS PASS",
    Address: "MAIN ROAD  SIR LOWRYS PASS VILLAGE  SIR LOUWRYS PASS",
    Latitude: "-34.1183",
    Longitude: "18.9126",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21920",
    WardLookupId: "505",
    VDNumber: "97110165",
    RegisteredPopulation: "3294",
    VotingStationName: "SOMERSET WEST TOWN HALL",
    Address: "MAIN ROAD  SOMERSET WEST  ",
    Latitude: "-34.0842",
    Longitude: "18.8511",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21921",
    WardLookupId: "505",
    VDNumber: "97110176",
    RegisteredPopulation: "3082",
    VotingStationName: "SIR LOWRYS PASS PRIMARY SCHOOL",
    Address: "SIR LOWRYS PASS ROAD  SIR LOWRYS PASS VILLAGE  GORDONS BAY",
    Latitude: "-34.1205",
    Longitude: "18.9074",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21922",
    WardLookupId: "505",
    VDNumber: "97110233",
    RegisteredPopulation: "2955",
    VotingStationName: "PAREL VALLEI HIGH SCHOOL",
    Address: "95 PAREL VALLEI ROAD  SOMERSET WEST  ",
    Latitude: "-34.066",
    Longitude: "18.8574",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21923",
    WardLookupId: "505",
    VDNumber: "97110255",
    RegisteredPopulation: "3172",
    VotingStationName: "BIZWENI COMMUNITY CHURCH",
    Address: "BIZWENI  SOMERSET WEST  CAPE TOWN",
    Latitude: "-34.0871",
    Longitude: "18.86",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21924",
    WardLookupId: "505",
    VDNumber: "97110356",
    RegisteredPopulation: "2927",
    VotingStationName: "BEAUMONT PRIMARY SCHOOL",
    Address: "VAN DER MERWE STREET  LAND-EN ZEEZICHT  SOMERSET WEST",
    Latitude: "-34.0752",
    Longitude: "18.8717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21925",
    WardLookupId: "505",
    VDNumber: "97110424",
    RegisteredPopulation: "3854",
    VotingStationName: "CHOICES PREGNANCY CENTRE",
    Address: "1 SCHAPENBERG ROAD  SOMERSET WEST  SOMERSET WEST",
    Latitude: "-34.0939",
    Longitude: "18.8624",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21926",
    WardLookupId: "505",
    VDNumber: "97110491",
    RegisteredPopulation: "646",
    VotingStationName: "AFM CHURCH - CHRIS NISSAN PARK",
    Address: "PLATINUM  CHRIS NISSEN PARK  STRAND",
    Latitude: "-34.1099",
    Longitude: "18.8705",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21927",
    WardLookupId: "505",
    VDNumber: "97110536",
    RegisteredPopulation: "811",
    VotingStationName: "GARDEN VILLAGE COMMUNITY CENTRE",
    Address: "GUM STREET  GARDEN VILLAGE  SOMERSET WEST",
    Latitude: "-34.0865",
    Longitude: "18.8374",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21928",
    WardLookupId: "506",
    VDNumber: "97110086",
    RegisteredPopulation: "5339",
    VotingStationName: "NOMZAMO COMMUNITY HALL",
    Address: "29 SELVEN STREET  NOMZAMO  STRAND",
    Latitude: "-34.1157",
    Longitude: "18.8593",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21929",
    WardLookupId: "506",
    VDNumber: "97110345",
    RegisteredPopulation: "3106",
    VotingStationName: "N G KERK SUIDERSTRAND",
    Address: "21 ALEX PIENAAR STREET  STRAND  ",
    Latitude: "-34.1197",
    Longitude: "18.8352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21930",
    WardLookupId: "506",
    VDNumber: "97110378",
    RegisteredPopulation: "2957",
    VotingStationName: "ACJ PAKADE PRIMARY SCHOOL",
    Address: "MXOLISI STREET  NOMZAMO  STRAND, CAPE TOWN",
    Latitude: "-34.1172",
    Longitude: "18.8565",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21931",
    WardLookupId: "506",
    VDNumber: "97110389",
    RegisteredPopulation: "1960",
    VotingStationName: "SOTERIA PRE-SCHOOL CENTRE OF EXCELLENCE",
    Address: "CHICAGO  ONVERWACHT  STRAND",
    Latitude: "-34.1196",
    Longitude: "18.8506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21932",
    WardLookupId: "506",
    VDNumber: "97110413",
    RegisteredPopulation: "1756",
    VotingStationName: "SOLOMON QATYANA PRIMARY SCHOOL",
    Address: "HLATHI  ASANDA VILLAGE  STRAND",
    Latitude: "-34.1088",
    Longitude: "18.8591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21933",
    WardLookupId: "506",
    VDNumber: "97110435",
    RegisteredPopulation: "1987",
    VotingStationName: "SIMANYENE SECONDARY SCHOOL",
    Address: "HLATHI  ASANDA VILLAGE  STRAND",
    Latitude: "-34.1084",
    Longitude: "18.8582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21934",
    WardLookupId: "506",
    VDNumber: "97110468",
    RegisteredPopulation: "1171",
    VotingStationName: "VOORTREKKERPARK CONFERENCE CENTRE",
    Address: "HOFMEYER  RYNEVELD  STRAND",
    Latitude: "-34.1228",
    Longitude: "18.8342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21935",
    WardLookupId: "507",
    VDNumber: "97110110",
    RegisteredPopulation: "2108",
    VotingStationName: "ST. JOHNS ANGLICAN - STRAND",
    Address: "CNR.BROADLANDS & 5TH STREET  RUSTHOF  STRAND",
    Latitude: "-34.1326",
    Longitude: "18.8499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21936",
    WardLookupId: "507",
    VDNumber: "97110132",
    RegisteredPopulation: "2045",
    VotingStationName: "RUSTHOF SECONDARY SCHOOL",
    Address: "BROADLANDS ROAD  STRAND  ",
    Latitude: "-34.1273",
    Longitude: "18.8579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21937",
    WardLookupId: "507",
    VDNumber: "97110222",
    RegisteredPopulation: "3172",
    VotingStationName: "KHANYOLWETHU HIGH SCHOOL",
    Address: "NOXOLO STREET  LWANDLE  STRAND",
    Latitude: "-34.1182",
    Longitude: "18.8623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21938",
    WardLookupId: "507",
    VDNumber: "97110288",
    RegisteredPopulation: "3262",
    VotingStationName: "LWANDLE COMMUNITY HALL (UMANYANO)",
    Address: "VULINDLELA STREET  LWANDLE  CAPE TOWN, STRAND",
    Latitude: "-34.1211",
    Longitude: "18.8654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21939",
    WardLookupId: "507",
    VDNumber: "97110334",
    RegisteredPopulation: "2752",
    VotingStationName: "DR G J JOUBERT PRIMARY SCHOOL",
    Address: "MYDRECHTSTRAAT  STRAND  CAPE TOWN",
    Latitude: "-34.1257",
    Longitude: "18.8427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21940",
    WardLookupId: "507",
    VDNumber: "97110402",
    RegisteredPopulation: "2183",
    VotingStationName: "HECTOR PIETERSEN MEMORIAL LIBRARY",
    Address: "VULINDLELA STREET  LWANDLE  CAPE TOWN",
    Latitude: "-34.1186",
    Longitude: "18.8648",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21941",
    WardLookupId: "507",
    VDNumber: "97110457",
    RegisteredPopulation: "1612",
    VotingStationName: "METHODIST CHURCH OF SA - LWANDLE",
    Address: "CNR VULINDLELA & MASAKHANE STR  LWANDLE  STRAND",
    Latitude: "-34.1219",
    Longitude: "18.8665",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21942",
    WardLookupId: "507",
    VDNumber: "97110503",
    RegisteredPopulation: "1586",
    VotingStationName: "ST MARYS ANGLICAN CHURCH - LWANDLE",
    Address: "CNR MASAKHANE & NONQUBELA  LWANDLE  STRAND",
    Latitude: "-34.1238",
    Longitude: "18.863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21943",
    WardLookupId: "508",
    VDNumber: "97141372",
    RegisteredPopulation: "1641",
    VotingStationName: "PHILANI NUTRITIONAL CENTRE",
    Address: "WALTER SISULU STREET  SITE C  KHAYELITSHA",
    Latitude: "-34.0153",
    Longitude: "18.6548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21944",
    WardLookupId: "508",
    VDNumber: "97141440",
    RegisteredPopulation: "3223",
    VotingStationName: "ISIKHOKELO PRIMARY SCHOOL",
    Address: "IDADA STREET  SITE C  KHAYELTISHA",
    Latitude: "-34.0135",
    Longitude: "18.6524",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21945",
    WardLookupId: "508",
    VDNumber: "97141631",
    RegisteredPopulation: "2534",
    VotingStationName: "NOLUNGILE PRIMARY SCHOOL",
    Address: "89 LAWRENCE ROAD  SITE C  KHAYELITSHA",
    Latitude: "-34.0139",
    Longitude: "18.6429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21946",
    WardLookupId: "508",
    VDNumber: "97141664",
    RegisteredPopulation: "1592",
    VotingStationName: "BLUE COMMUNITY HALL",
    Address: "6 LAWRENCE ROAD  SITE C  KHAYELITSHA",
    Latitude: "-34.0155",
    Longitude: "18.6494",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21947",
    WardLookupId: "508",
    VDNumber: "97141697",
    RegisteredPopulation: "1837",
    VotingStationName: "HOLY CHURCH OF GOD SABATH",
    Address: "CNR THANDANANI & AMANDLA STREETS  SITE C  KHAYELITSHA",
    Latitude: "-34.0109",
    Longitude: "18.65",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21948",
    WardLookupId: "508",
    VDNumber: "97141721",
    RegisteredPopulation: "1674",
    VotingStationName: "VUZAMANZI PRIMARY SCHOOL",
    Address: "NCUMO STREET  SITEC  KHAYELITSHA",
    Latitude: "-34.0134",
    Longitude: "18.6491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21949",
    WardLookupId: "508",
    VDNumber: "97142025",
    RegisteredPopulation: "1590",
    VotingStationName: "PHILANI NUTRITIONAL CENTRE",
    Address: "LAWRENCE ROAD  SITE C  KHAYELITSHA",
    Latitude: "-34.0162",
    Longitude: "18.647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21950",
    WardLookupId: "508",
    VDNumber: "97142115",
    RegisteredPopulation: "1092",
    VotingStationName: "THE TEMPLE OF TRANSFORMATION CHURCH",
    Address: "BT 86 JAPTHA MASEMOLA ROAD  SITE  C  KHAYELITSHA",
    Latitude: "-34.0167",
    Longitude: "18.6486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21951",
    WardLookupId: "509",
    VDNumber: "97091747",
    RegisteredPopulation: "3617",
    VotingStationName: "HEINZ PARK PRIMARY SCHOOL",
    Address: "HEINZ PARK DRIVE  HEINZ PARK  CAPE TOWN",
    Latitude: "-34.0161",
    Longitude: "18.5968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21952",
    WardLookupId: "509",
    VDNumber: "97092007",
    RegisteredPopulation: "3280",
    VotingStationName: "PHILLIPI K PRIMARY SCHOOL",
    Address: "CNR VIETNAM DRIVE & 5TH AVENUE  PHILLIPI  ",
    Latitude: "-34.0099",
    Longitude: "18.5977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21953",
    WardLookupId: "509",
    VDNumber: "97092276",
    RegisteredPopulation: "5675",
    VotingStationName: "VUKUZENZELE EDUCARE",
    Address: "KRA 80 WELTENVREDEN DRIVE  PHILLIPI  KOSOVO",
    Latitude: "-34.0163",
    Longitude: "18.5851",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21954",
    WardLookupId: "509",
    VDNumber: "97092287",
    RegisteredPopulation: "3278",
    VotingStationName: "TEMPORARY VOTING STATION (PORTA CAMP)",
    Address: "RD 40 & 43 OFF DAVID VANDA DRIVE  PHOLA PARK  PHILLIPI",
    Latitude: "-34.0052",
    Longitude: "18.5961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21955",
    WardLookupId: "509",
    VDNumber: "97093323",
    RegisteredPopulation: "1853",
    VotingStationName: "PHILLIPI INDOOR SPORTS CENTRE",
    Address: "CNR SHEFFIELD & INFORMAL RD  PHILLIPI  CAPE TOWN",
    Latitude: "-34.0078",
    Longitude: "18.6015",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21956",
    WardLookupId: "509",
    VDNumber: "97093334",
    RegisteredPopulation: "1216",
    VotingStationName: "LUTHERAN COMMUNITY CENTRE",
    Address: "CNR EISLEBAN & SHEFFIELD ROADS  PHILIPPI  ",
    Latitude: "-34.0083",
    Longitude: "18.5938",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21957",
    WardLookupId: "510",
    VDNumber: "97141394",
    RegisteredPopulation: "2349",
    VotingStationName: "TENT CORN OF SULANI& BONGA DRIVE",
    Address: "SULAN DRIVE  SITE B  KHAYELITSHA",
    Latitude: "-34.0197",
    Longitude: "18.6651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21958",
    WardLookupId: "510",
    VDNumber: "97141743",
    RegisteredPopulation: "2900",
    VotingStationName: "MATHEW GONIWE MEMORIAL HIGH SCHOOL",
    Address: "NYATHI STR  SITE B  KHAYELITSHA",
    Latitude: "-34.022",
    Longitude: "18.6678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21959",
    WardLookupId: "510",
    VDNumber: "97142036",
    RegisteredPopulation: "1972",
    VotingStationName: "O.R TAMBO COMMUNITY HALL",
    Address: "JAPHTA MASEMOLA ROAD  SITE B  KHAYELITSHA",
    Latitude: "-34.0155",
    Longitude: "18.6602",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21960",
    WardLookupId: "510",
    VDNumber: "97142047",
    RegisteredPopulation: "1663",
    VotingStationName: "ST JOHNS INTERNATIONAL CHURCH",
    Address: "NJIMBA STREET  SITE B  KHAYELITSHA",
    Latitude: "-34.0201",
    Longitude: "18.6613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21961",
    WardLookupId: "510",
    VDNumber: "97142137",
    RegisteredPopulation: "952",
    VotingStationName: "TENT AT SITE B-BM",
    Address: "2076 BM SECTION TENT  KHAYELITSHA  SITE B",
    Latitude: "-34.018",
    Longitude: "18.6695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21962",
    WardLookupId: "510",
    VDNumber: "97142148",
    RegisteredPopulation: "2308",
    VotingStationName: "TEMPORARY VOTING STATION (PORTA CAMP)",
    Address: "QO320 BONGA DRIVE  SITE B  KHAYELITSHA",
    Latitude: "-34.0185",
    Longitude: "18.6658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21963",
    WardLookupId: "510",
    VDNumber: "97142159",
    RegisteredPopulation: "2265",
    VotingStationName: "KUSTA HALL",
    Address: "JAPTHA MASEMOLE ROAD   SITE B  KHAYELITSHA",
    Latitude: "-34.0216",
    Longitude: "18.6719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21964",
    WardLookupId: "510",
    VDNumber: "97142373",
    RegisteredPopulation: "640",
    VotingStationName: "SOYISILE PRIMARY SCHOOL",
    Address: "BONKOLO DRIVE  SITE B  KHAYELITSHA",
    Latitude: "-34.0231",
    Longitude: "18.6684",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21965",
    WardLookupId: "511",
    VDNumber: "97110390",
    RegisteredPopulation: "1154",
    VotingStationName: "UNITING NATIONS INTERNATIONAL MINISTRIES",
    Address: "JAPTHA MASEMOLA ROAD  SITE C  KHAYELITSHA",
    Latitude: "-34.0163",
    Longitude: "18.6545",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21966",
    WardLookupId: "511",
    VDNumber: "97141349",
    RegisteredPopulation: "2315",
    VotingStationName: "BAPHUMELELE EDUCARE CENTRE",
    Address: "Z118 DABULA STREET  SITE B  KHAYELTISHA",
    Latitude: "-34.0296",
    Longitude: "18.654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21967",
    WardLookupId: "511",
    VDNumber: "97141383",
    RegisteredPopulation: "2850",
    VotingStationName: "IKHUSI PRE-PRIMARY SCHOOL",
    Address: "100 NTANDAZO DRIVE  SITE B  KHAYELITSHA",
    Latitude: "-34.0239",
    Longitude: "18.6554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21968",
    WardLookupId: "511",
    VDNumber: "97141417",
    RegisteredPopulation: "3603",
    VotingStationName: "ANDILE MSIZI HALL",
    Address: "BANGISO DRIVE  SITE B  KHAYELITSHA",
    Latitude: "-34.0216",
    Longitude: "18.6555",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21969",
    WardLookupId: "511",
    VDNumber: "97141451",
    RegisteredPopulation: "2248",
    VotingStationName: "BONGANI COMMUNITY HALL",
    Address: "TR SECTION  SITE B  KHAYELITSHA",
    Latitude: "-34.0181",
    Longitude: "18.652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21970",
    WardLookupId: "511",
    VDNumber: "97142249",
    RegisteredPopulation: "826",
    VotingStationName: "MAKUKHANYE EDUCARE CENTRE",
    Address: "W 328 SITE B  KHAYELITSHA  SITE B",
    Latitude: "-34.0192",
    Longitude: "18.6534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21971",
    WardLookupId: "511",
    VDNumber: "97142362",
    RegisteredPopulation: "839",
    VotingStationName: "UMZOMHLE EDUCARE",
    Address: "NEW WAY ROAD  SITE B  KHAYELITSHA",
    Latitude: "-34.0322",
    Longitude: "18.6526",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21972",
    WardLookupId: "512",
    VDNumber: "97140449",
    RegisteredPopulation: "1864",
    VotingStationName: "SITE B HALL",
    Address: "NGQISHA CRESENT  KHAYELITSHA  ",
    Latitude: "-34.0261",
    Longitude: "18.6656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21973",
    WardLookupId: "512",
    VDNumber: "97141350",
    RegisteredPopulation: "2411",
    VotingStationName: "NTWASAHLOBO PRIMARY SCHOOL",
    Address: "BANGISO DRIVE  SITE B  KHAYELITSHA",
    Latitude: "-34.028",
    Longitude: "18.6594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21974",
    WardLookupId: "512",
    VDNumber: "97141361",
    RegisteredPopulation: "2232",
    VotingStationName: "SAKUMLANDELA PRIMARY SCHOOL",
    Address: "BANGISO DRIVE  SITE B  KHAYELITSHA",
    Latitude: "-34.0271",
    Longitude: "18.6592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21975",
    WardLookupId: "512",
    VDNumber: "97141406",
    RegisteredPopulation: "1765",
    VotingStationName: "SOBAMBISANA PUBLIC PRIMARY SCHOOL",
    Address: "BANGISO DRIVE  SITE B  KHAYELITSHA",
    Latitude: "-34.0249",
    Longitude: "18.6582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21976",
    WardLookupId: "512",
    VDNumber: "97141428",
    RegisteredPopulation: "4762",
    VotingStationName: "UMMANGALISO PRIMARY SCHOOL",
    Address: "BANGISO DRIVE   SITE B  KHAYELITSHA",
    Latitude: "-34.0241",
    Longitude: "18.6577",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21977",
    WardLookupId: "512",
    VDNumber: "97141732",
    RegisteredPopulation: "2222",
    VotingStationName: "CHUMISA PRIMARY SCHOOL",
    Address: "BONGA DRIVE  SITE B  KHAYELITSHA",
    Latitude: "-34.0218",
    Longitude: "18.6626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21978",
    WardLookupId: "513",
    VDNumber: "97141103",
    RegisteredPopulation: "3853",
    VotingStationName: "INTSHAYELELO PRIMARY SCHOOL",
    Address: "SINQOLAMTHI STREET  LITHA PARK  KHAYELITSHA",
    Latitude: "-34.0482",
    Longitude: "18.6614",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21979",
    WardLookupId: "513",
    VDNumber: "97141260",
    RegisteredPopulation: "1979",
    VotingStationName: "LUHLAZA HIGH SCHOOL",
    Address: "42 MAKABENI ROAD  KHAYELITSHA  ",
    Latitude: "-34.041",
    Longitude: "18.6619",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21980",
    WardLookupId: "513",
    VDNumber: "97141271",
    RegisteredPopulation: "2169",
    VotingStationName: "KHAYELITSHA SPECIAL SCHOOL",
    Address: "MAKABENI ROAD  KHAYELITSHA  E SECTION KHAYELITSHA",
    Latitude: "-34.0397",
    Longitude: "18.6598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21981",
    WardLookupId: "513",
    VDNumber: "97141282",
    RegisteredPopulation: "2367",
    VotingStationName: "INJONGO PUBLIC PRIMARY SCHOOL",
    Address: "LENGEZI ROAD  C SECTION  KHAYELITSHA",
    Latitude: "-34.0332",
    Longitude: "18.6558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21982",
    WardLookupId: "513",
    VDNumber: "97142126",
    RegisteredPopulation: "1448",
    VotingStationName: "HOMBA PRIMARY SCHOOL",
    Address: "DYANI CRESCENT  D SECTION,  KHAYELITSHA",
    Latitude: "-34.0318",
    Longitude: "18.6616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21983",
    WardLookupId: "513",
    VDNumber: "97142160",
    RegisteredPopulation: "1402",
    VotingStationName: "ILITHA PARK COMMUNITY LIBRARY",
    Address: "NTLAZANE ROAD  ILITHA PARK  KHAYELITSHA",
    Latitude: "-34.0471",
    Longitude: "18.6687",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21984",
    WardLookupId: "513",
    VDNumber: "97142193",
    RegisteredPopulation: "939",
    VotingStationName: "LATHITHA SCHOOL OF SKILLS",
    Address: "MKHONTO CRESCENT  E SECTION  KHAYELITSHA",
    Latitude: "-34.036",
    Longitude: "18.664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21985",
    WardLookupId: "513",
    VDNumber: "97142430",
    RegisteredPopulation: "1844",
    VotingStationName: "MANYANO HIGH SCHOOL",
    Address: "MSOBOMVU DRIVE  LITHA PARK  KHAYELITSHA",
    Latitude: "-34.0467",
    Longitude: "18.6645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21986",
    WardLookupId: "514",
    VDNumber: "97141215",
    RegisteredPopulation: "4239",
    VotingStationName: "THOKOZANI YOUTH CENTRE",
    Address: "94 THABANG BOOKOLANE STREET  TOWN 2  KHAYELITSHA",
    Latitude: "-34.0406",
    Longitude: "18.6849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21987",
    WardLookupId: "514",
    VDNumber: "97141226",
    RegisteredPopulation: "1893",
    VotingStationName: "TENT CORN OF SPINE & JAPTHA MASEMOLA ROAD",
    Address: "SPINE ROAD  GREEN POINT KHAYELITSHA  KHAYELITSHA",
    Latitude: "-34.037",
    Longitude: "18.6807",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21988",
    WardLookupId: "514",
    VDNumber: "97141316",
    RegisteredPopulation: "2316",
    VotingStationName: "YOMELELA PUBLIC PRIMARY SCHOOL",
    Address: "34 BENYA CRESENT  SITE B  KHAYELITSHA",
    Latitude: "-34.0277",
    Longitude: "18.6747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21989",
    WardLookupId: "514",
    VDNumber: "97141327",
    RegisteredPopulation: "2314",
    VotingStationName: "ELUKHUSELWENI EDUCARE CENTRE",
    Address: "K503  SITE B  KHAYELITSHA",
    Latitude: "-34.0281",
    Longitude: "18.6678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21990",
    WardLookupId: "514",
    VDNumber: "97141709",
    RegisteredPopulation: "2391",
    VotingStationName: "SOSEBENZA PRIMARY SCHOOL",
    Address: "111 BONKOLO DRIVE  SITE B  KHAYELITSHA",
    Latitude: "-34.0239",
    Longitude: "18.6688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21991",
    WardLookupId: "514",
    VDNumber: "97142081",
    RegisteredPopulation: "1738",
    VotingStationName: "NOLUTHANDO SCHOOL FOR THE DEAFS",
    Address:
      "GOVAN MBEKI ROAD   TREVOR VILAKAZI,SITE B KHAYELITSHA  KHAYELITSHA",
    Latitude: "-34.0243",
    Longitude: "18.6748",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21992",
    WardLookupId: "514",
    VDNumber: "97142182",
    RegisteredPopulation: "1681",
    VotingStationName: "GREENPOINT COMMUNITY HALL",
    Address: "IGXIYA STREET  KHAYELITSHA  KHAYELITSHA",
    Latitude: "-34.0282",
    Longitude: "18.6769",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21993",
    WardLookupId: "514",
    VDNumber: "97142676",
    RegisteredPopulation: "1128",
    VotingStationName: "WHITE HOUSE (PORTA CAMP)",
    Address: "SHUKUSHUKUMA  MAKHAZA  KHAYELITSHA",
    Latitude: "-34.0447",
    Longitude: "18.6979",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21994",
    WardLookupId: "515",
    VDNumber: "97141114",
    RegisteredPopulation: "3443",
    VotingStationName: "SIVUYISENI PRIMARY SCHOOL",
    Address: "4 THANDI MODISE ROAD  MANDELA PARK  KHAYELITSHA",
    Latitude: "-34.0497",
    Longitude: "18.6788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21995",
    WardLookupId: "515",
    VDNumber: "97141237",
    RegisteredPopulation: "2530",
    VotingStationName: "PHILANI NUTRITIONAL CENTRE-TOWN TWO",
    Address: "BEKELENTLOKO STREET  TOWN TWO  KHAYELITSHA",
    Latitude: "-34.0415",
    Longitude: "18.681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21996",
    WardLookupId: "515",
    VDNumber: "97141248",
    RegisteredPopulation: "1656",
    VotingStationName: "UMTHA WELANGA PRIMARY SCHOOL",
    Address: "2 THOKOZI ROAD  EKUPHUMLENI  KHAYELITSHA",
    Latitude: "-34.0432",
    Longitude: "18.6717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21997",
    WardLookupId: "515",
    VDNumber: "97141259",
    RegisteredPopulation: "2534",
    VotingStationName: "LWANDLE PRIMARY SCHOOL",
    Address: "LAWULO ROAD  KHAYELITSHA  ",
    Latitude: "-34.0358",
    Longitude: "18.6762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21998",
    WardLookupId: "515",
    VDNumber: "97141293",
    RegisteredPopulation: "2692",
    VotingStationName: "ELUXOLWENI PUBLIC PRIMARY SCHOOL",
    Address: "MONGEZI ROAD  SITE B  KHAYELITSHA",
    Latitude: "-34.0297",
    Longitude: "18.6703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "21999",
    WardLookupId: "515",
    VDNumber: "97141305",
    RegisteredPopulation: "781",
    VotingStationName: "IQHAYIYA SECONDARY SCHOOL",
    Address: "MATETA STREET  SITE B  KHAYELITSHA",
    Latitude: "-34.0325",
    Longitude: "18.6766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22000",
    WardLookupId: "515",
    VDNumber: "97142069",
    RegisteredPopulation: "1544",
    VotingStationName: "CHUMA PRIMARY SCHOOL",
    Address: "GOVAN MBEKI STREET  TOWN TWO  KHAYELITSHA",
    Latitude: "-34.042",
    Longitude: "18.6777",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22001",
    WardLookupId: "515",
    VDNumber: "97142171",
    RegisteredPopulation: "963",
    VotingStationName: "BULUMKO SECONDARY SCHOOL",
    Address: "MONGEZI STREET  F SECTION  KHAYELITSHA",
    Latitude: "-34.0387",
    Longitude: "18.672",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22002",
    WardLookupId: "515",
    VDNumber: "97142317",
    RegisteredPopulation: "922",
    VotingStationName: "THEMBELIHLE HIGH SCHOOL",
    Address: "GOVAN MBEKI DRIVE  TOWN TWO  KHAYELITSHA",
    Latitude: "-34.041",
    Longitude: "18.6767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22003",
    WardLookupId: "516",
    VDNumber: "97141136",
    RegisteredPopulation: "4595",
    VotingStationName: "UNITED ETHOPIAN CHURCH OF AFRICA",
    Address: "91 LULAMILE CRESCENT  KHAYELITSHA  MAKHAZA",
    Latitude: "-34.0529",
    Longitude: "18.7049",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22004",
    WardLookupId: "516",
    VDNumber: "97141147",
    RegisteredPopulation: "3147",
    VotingStationName: "UNITED METHODIST CHURCH",
    Address: "CNR FOLOKWE & LWESINE STR  MAKHAZA  KHAYELITSHA",
    Latitude: "-34.0516",
    Longitude: "18.7127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22005",
    WardLookupId: "516",
    VDNumber: "97142441",
    RegisteredPopulation: "3264",
    VotingStationName: "KHULA NGOLWAZI EDUCARE CENTRE",
    Address: "4119 NTLAZANE ROAD  ENKANINI  KHAYELITSHA",
    Latitude: "-34.0563",
    Longitude: "18.7091",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22006",
    WardLookupId: "516",
    VDNumber: "97142452",
    RegisteredPopulation: "3223",
    VotingStationName: "ARK EDUCARE CENTRE",
    Address: "OFF BADEN POWEL DRIVE  ENKANINI  KHAYELITSHA",
    Latitude: "-34.0601",
    Longitude: "18.7068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22007",
    WardLookupId: "516",
    VDNumber: "97142609",
    RegisteredPopulation: "4219",
    VotingStationName: "INKANINI PRIMARY SCHOOL",
    Address: "1 INTLANDLOLO STREET  INKANINI  KHAYELITSHA",
    Latitude: "-34.0624",
    Longitude: "18.6958",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22008",
    WardLookupId: "517",
    VDNumber: "97141158",
    RegisteredPopulation: "3591",
    VotingStationName: "NKAZIMLO PUBLIC PRIMARY SCHOOL",
    Address: "FUKUTA STREET  MAKHAZA  KHAYELITSHA",
    Latitude: "-34.049",
    Longitude: "18.7115",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22009",
    WardLookupId: "517",
    VDNumber: "97141169",
    RegisteredPopulation: "1896",
    VotingStationName: "THUBALETHU PRE-SCHOOL",
    Address: "MHLOPHE CRESCENT  MAKHAZA  KHAYELITSHA",
    Latitude: "-34.0395",
    Longitude: "18.7119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22010",
    WardLookupId: "517",
    VDNumber: "97141170",
    RegisteredPopulation: "3267",
    VotingStationName: "IMPENDULO PRIMARY SCHOOL",
    Address: "CNR KWAHLAZA STREET & SPIRO M AVE  MAKHAZA  KHAYELITSHA",
    Latitude: "-34.0438",
    Longitude: "18.7045",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22011",
    WardLookupId: "517",
    VDNumber: "97141181",
    RegisteredPopulation: "2032",
    VotingStationName: "UKWANDA PRE-SCHOOL",
    Address: "42351 MALUME CRESCENT  MAKHAZA  KHAYELITSHA",
    Latitude: "-34.0394",
    Longitude: "18.698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22012",
    WardLookupId: "517",
    VDNumber: "97142058",
    RegisteredPopulation: "2584",
    VotingStationName: "SINAKO HIGH SCHOOL",
    Address: "TUTU AVENUE  MAKHAZA  KHAYELITSHA",
    Latitude: "-34.0418",
    Longitude: "18.6992",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22013",
    WardLookupId: "517",
    VDNumber: "97142339",
    RegisteredPopulation: "1532",
    VotingStationName: "CHRIS HANI SECONDARY SCHOOL-KHAYELITSHA",
    Address: "2 CEKECA ROAD  MAKHAZA  KHAYELITSHA",
    Latitude: "-34.0465",
    Longitude: "18.7055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22014",
    WardLookupId: "517",
    VDNumber: "97142485",
    RegisteredPopulation: "1496",
    VotingStationName: "TENT AT MHLOMPE STREET MAKHAZA",
    Address: "MHLOMPE STREET  MAKHAZA  KHAYELITSHA",
    Latitude: "-34.0399",
    Longitude: "18.7046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22015",
    WardLookupId: "517",
    VDNumber: "97142496",
    RegisteredPopulation: "1890",
    VotingStationName: "NOXOLO XAUKA PRIMARY SCHOOL",
    Address:
      "C/O TUTU & MHLOPHE CRESCENT  MAKHAZA  CITY OF CAPE TOWN [CAPE TOWN]",
    Latitude: "-34.0427",
    Longitude: "18.7116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22016",
    WardLookupId: "518",
    VDNumber: "97141125",
    RegisteredPopulation: "3517",
    VotingStationName: "HARRY GWALA SECONDARY SCHOOL",
    Address: "5 HLEHLA STREET  MAKHAZA  KHAYELITSHA",
    Latitude: "-34.0515",
    Longitude: "18.6953",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22017",
    WardLookupId: "518",
    VDNumber: "97141192",
    RegisteredPopulation: "2788",
    VotingStationName: "MZAMOMHLE SENIOR CLUB",
    Address: "23039 CHRISMAST THINTO STR  MANDELA PARK  KHAYELITSHA",
    Latitude: "-34.0469",
    Longitude: "18.6928",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22018",
    WardLookupId: "518",
    VDNumber: "97141204",
    RegisteredPopulation: "2827",
    VotingStationName: "NOMSA MAPONGWANA PRIMARY SCHOOL",
    Address: "65 WINNIE MANDELA ROAD  TOWN TWO  KHAYELITSHA",
    Latitude: "-34.0454",
    Longitude: "18.6846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22019",
    WardLookupId: "518",
    VDNumber: "97142092",
    RegisteredPopulation: "2457",
    VotingStationName: "SIZISA UKHANYO PRE-SCHOOL",
    Address: "MQHA ROAD  KUYASA  KHAYELITSHA",
    Latitude: "-34.0577",
    Longitude: "18.6945",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22020",
    WardLookupId: "518",
    VDNumber: "97142597",
    RegisteredPopulation: "2845",
    VotingStationName: "LUDWE  NGAMLANA  PRIMARY",
    Address: "MQHA ROAD  KUYASA  CAPE TOWN",
    Latitude: "-34.06",
    Longitude: "18.69",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22021",
    WardLookupId: "519",
    VDNumber: "97141057",
    RegisteredPopulation: "2514",
    VotingStationName: "NONKOSINATHI EDUCARE CENTRE",
    Address: "34164 HLONELA STREET  HARARE KHAYELITSHA  CAPE TOWN",
    Latitude: "-34.061",
    Longitude: "18.6783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22022",
    WardLookupId: "519",
    VDNumber: "97141068",
    RegisteredPopulation: "2730",
    VotingStationName: "LULEKA PRIMARY SCHOOL",
    Address: "NCUMO ROAD  HARARE  KHAYELITSHA",
    Latitude: "-34.0596",
    Longitude: "18.6717",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22023",
    WardLookupId: "519",
    VDNumber: "97141079",
    RegisteredPopulation: "2319",
    VotingStationName: "ISIPHIWO PRIMARY SCHOOL",
    Address: "HLUTHA STREET  HARARE  KHAYELITSHA",
    Latitude: "-34.0575",
    Longitude: "18.6793",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22024",
    WardLookupId: "519",
    VDNumber: "97141080",
    RegisteredPopulation: "2373",
    VotingStationName: "METHODIST CHURCH OF SOUTH AFRICA",
    Address: "CNR NCUMO & BUKWAYO STEEETS  HARARE  KHAYELITSHA",
    Latitude: "-34.0576",
    Longitude: "18.6704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22025",
    WardLookupId: "519",
    VDNumber: "97141091",
    RegisteredPopulation: "1886",
    VotingStationName: "KWAMFUNDO SENIOR SECONDARY SCHOOL",
    Address: "NCUMO ROAD  HARARE  KHAYELITSHA",
    Latitude: "-34.0555",
    Longitude: "18.6725",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22026",
    WardLookupId: "519",
    VDNumber: "97141855",
    RegisteredPopulation: "3619",
    VotingStationName: "MONWABISI PARK COMMUNITY HALL",
    Address: "MEW WAY  ROAD  MONWABISI PARK  HARARE",
    Latitude: "-34.062",
    Longitude: "18.6747",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22027",
    WardLookupId: "519",
    VDNumber: "97142340",
    RegisteredPopulation: "1861",
    VotingStationName: "RED CROSS CENTRE HARARE",
    Address: "CNR NCUMO & BUKWAYO ROADS  HARARE  KHAYELITSHA",
    Latitude: "-34.058",
    Longitude: "18.6704",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22028",
    WardLookupId: "520",
    VDNumber: "97091501",
    RegisteredPopulation: "3479",
    VotingStationName: "TAFELSIG COMMUNITY CENTRE",
    Address: "1 OLIFANTSHOEK  TAFELSIG  MITCHELLS PLAIN",
    Latitude: "-34.0563",
    Longitude: "18.6373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22029",
    WardLookupId: "520",
    VDNumber: "97091512",
    RegisteredPopulation: "4000",
    VotingStationName: "FREEDOM PARK DEVELOPMENT ASSOCIATION HALL",
    Address: "BAYERN MUNICH ROAD  TAFELSIG  CAPE TOWN",
    Latitude: "-34.0596",
    Longitude: "18.6436",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22030",
    WardLookupId: "520",
    VDNumber: "97141866",
    RegisteredPopulation: "4242",
    VotingStationName: "IBANATHI EDUCARE CENTRE",
    Address: "ENDLOVINI  HARARE  KHAYELITSHA",
    Latitude: "-34.0614",
    Longitude: "18.6604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22031",
    WardLookupId: "520",
    VDNumber: "97142104",
    RegisteredPopulation: "2546",
    VotingStationName: "KUYASA PRIMARY SCHOOL",
    Address: "OSCAR MPETHA ROAD  KUYASA  KHAYELITSHA",
    Latitude: "-34.0614",
    Longitude: "18.6835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22032",
    WardLookupId: "520",
    VDNumber: "97142586",
    RegisteredPopulation: "2126",
    VotingStationName: "SAKHE EDUCARE CENTRE(SUPERSHACK)",
    Address: "60861 ANDRIES MOTSEPE CRESCEN  KUYASA  KHAYELITSHA",
    Latitude: "-34.0639",
    Longitude: "18.6859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22033",
    WardLookupId: "520",
    VDNumber: "97142687",
    RegisteredPopulation: "363",
    VotingStationName: "MARY`S CRECHE",
    Address: "MEW WAY  KHAYELITSHA  CAPE TOWN",
    Latitude: "-34.0547",
    Longitude: "18.656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22034",
    WardLookupId: "521",
    VDNumber: "97110121",
    RegisteredPopulation: "4457",
    VotingStationName: "HARMONY PARK CONFERENCE CENTRE",
    Address: "OCTOPUS  GORDONS BAY  GORDON BAY",
    Latitude: "-34.1431",
    Longitude: "18.8512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22035",
    WardLookupId: "521",
    VDNumber: "97110154",
    RegisteredPopulation: "3792",
    VotingStationName: "GORDONS BAY PRIMARY SCHOOL",
    Address: "AVONDRUST STREET  GORDONS BAY  ",
    Latitude: "-34.1565",
    Longitude: "18.8752",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22036",
    WardLookupId: "521",
    VDNumber: "97110198",
    RegisteredPopulation: "2552",
    VotingStationName: "MASJIED DUL UMMAH COMPLEX DAYCARE",
    Address: "CNR GUSTROUW & HASSAN KHAN AVE  GUSTROUW  STRAND",
    Latitude: "-34.1335",
    Longitude: "18.8529",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22037",
    WardLookupId: "521",
    VDNumber: "97110244",
    RegisteredPopulation: "2707",
    VotingStationName: "TEMPERENCE TOWN PRIMARY SCHOOL",
    Address: "APPOLO WAY  GORDONS BAY  TEMPERENCE TOWN",
    Latitude: "-34.1531",
    Longitude: "18.8827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22038",
    WardLookupId: "521",
    VDNumber: "97110299",
    RegisteredPopulation: "2410",
    VotingStationName: "APOSTOLIC FAITH MISSION CHURCH - SERCOR PARK",
    Address: "C/O VALDA & SURAYAG STR  CASSABLANCA  STRAND",
    Latitude: "-34.1326",
    Longitude: "18.8589",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22039",
    WardLookupId: "521",
    VDNumber: "97110514",
    RegisteredPopulation: "1856",
    VotingStationName: "BROADLANDS PARK COMMNUNITY HALL",
    Address: "CNR FERN & BOSDUIF STR  BROADLANDS PARK  STRAND",
    Latitude: "-34.1297",
    Longitude: "18.8611",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22040",
    WardLookupId: "522",
    VDNumber: "97100557",
    RegisteredPopulation: "3778",
    VotingStationName: "SARFAT COMMUNITY HALL (BLOEKOMBOS)",
    Address: "SAM NJOKOZELA   KRAAIFONTEIN  CAPE TOWN",
    Latitude: "-33.8481",
    Longitude: "18.7506",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22041",
    WardLookupId: "522",
    VDNumber: "97100568",
    RegisteredPopulation: "3498",
    VotingStationName: "MASIBAMBANE HIGH SCHOOL",
    Address: "191 OLD PAARL ROAD  BLOEKOMBOS  KRAAIFONTEIN",
    Latitude: "-33.843",
    Longitude: "18.7482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22042",
    WardLookupId: "522",
    VDNumber: "97100715",
    RegisteredPopulation: "3322",
    VotingStationName: "EKUTHULENI PRIMARY SCHOOL",
    Address: "HEZEKIEL STREET  KRAAIFONTEIN  CAPE TOWN",
    Latitude: "-33.8389",
    Longitude: "18.7496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22043",
    WardLookupId: "522",
    VDNumber: "97100726",
    RegisteredPopulation: "2728",
    VotingStationName: "BLOEKOMBOS PRIMARY SCHOOL",
    Address: "MAGUDUBELA STREET  BLOEKOMBOS  KRAAIFONTEIN",
    Latitude: "-33.8441",
    Longitude: "18.7489",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22044",
    WardLookupId: "522",
    VDNumber: "97100827",
    RegisteredPopulation: "1661",
    VotingStationName: "EAGLES NEST CHRISTIAN SCHOOL",
    Address: "TAMBO  WALLACEDENE  KRAAIFONTEIN",
    Latitude: "-33.8537",
    Longitude: "18.7435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22045",
    WardLookupId: "522",
    VDNumber: "97100838",
    RegisteredPopulation: "2976",
    VotingStationName: "SHILOH THEMBA KIDS",
    Address: "CNR MBEKI & XUMA   WALLACEDENE  KRAAOFONTEIN",
    Latitude: "-33.8542",
    Longitude: "18.7481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22046",
    WardLookupId: "523",
    VDNumber: "97100074",
    RegisteredPopulation: "2847",
    VotingStationName: "PAARL SCHOOL BRACKENFELL",
    Address: "ROGLAND STREET  MORGENSTER HEIGHTS  BRACKENFELL",
    Latitude: "-33.8677",
    Longitude: "18.6887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22047",
    WardLookupId: "523",
    VDNumber: "97100243",
    RegisteredPopulation: "2304",
    VotingStationName: "NGK MOEDERGEMEENTE BRACKENFELL",
    Address: "1 CHURCH LANE  BRACKENFELL  BRACKENFELL",
    Latitude: "-33.8754",
    Longitude: "18.6837",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22048",
    WardLookupId: "523",
    VDNumber: "97100445",
    RegisteredPopulation: "3255",
    VotingStationName: "KRAAIFONTEIN  LIBRARY",
    Address: "BRIGHTON ROAD  ZOO PARK  KRAAIFONTEIN",
    Latitude: "-33.8412",
    Longitude: "18.7055",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22049",
    WardLookupId: "523",
    VDNumber: "97100467",
    RegisteredPopulation: "3135",
    VotingStationName: "LAERSKOOL VREDEKLOOF PRIMARY SCHOOL",
    Address: "46 VREDEKLOOF ROAD,OFF DE BRON RD  VREDEKLOOF  CAPE TOWN",
    Latitude: "-33.8637",
    Longitude: "18.6727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22050",
    WardLookupId: "523",
    VDNumber: "97100603",
    RegisteredPopulation: "2473",
    VotingStationName: "FANIE THERON PRIMARY SCHOOL",
    Address: "CNR KRIGE & BOSMAN STREET  PEERLESS PARK NORTH  KRAAIFONTEIN",
    Latitude: "-33.8381",
    Longitude: "18.7196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22051",
    WardLookupId: "523",
    VDNumber: "97100883",
    RegisteredPopulation: "1648",
    VotingStationName: "BAPTIST CHURCH BRACKENFELL",
    Address: "DE BRON  BRACKENFELL  BRACKENFELL",
    Latitude: "-33.8576",
    Longitude: "18.689",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22052",
    WardLookupId: "523",
    VDNumber: "97100906",
    RegisteredPopulation: "2852",
    VotingStationName: "YINLAN PARK (TENT)",
    Address: "YINLAN STREET  VREDEKLOOF  CAPE TOWN",
    Latitude: "-33.8554",
    Longitude: "18.6888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22053",
    WardLookupId: "524",
    VDNumber: "97100513",
    RegisteredPopulation: "4353",
    VotingStationName: "ARISTEA PRIMARY SCHOOL",
    Address: "BRIGHTON WAY  BONNIE BROOK  KRAAIFONTEIN",
    Latitude: "-33.8407",
    Longitude: "18.7063",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22054",
    WardLookupId: "524",
    VDNumber: "97100524",
    RegisteredPopulation: "2702",
    VotingStationName: "POSDUIF KLUB (KRAAIFONTEIN SPORTS GROUND)",
    Address: "SAREL VAN DEVENTER AVENUE  BONNIE BROOK  KRAAIFONTEIN",
    Latitude: "-33.8238",
    Longitude: "18.7057",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22055",
    WardLookupId: "524",
    VDNumber: "97100625",
    RegisteredPopulation: "5395",
    VotingStationName: "MERIDIAN PRIVATE SCHOOL",
    Address: "OKAVANGO ROAD  UITZICHT  CAPE TOWN",
    Latitude: "-33.826",
    Longitude: "18.6954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22056",
    WardLookupId: "524",
    VDNumber: "97100928",
    RegisteredPopulation: "1720",
    VotingStationName: "DOXA DEO CHURCH",
    Address: "22 STEENBOK STREET  LANGEBERG RIDGE  KRAAIFONTEIN",
    Latitude: "-33.8417",
    Longitude: "18.6898",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22057",
    WardLookupId: "524",
    VDNumber: "97100962",
    RegisteredPopulation: "562",
    VotingStationName: "BUH-REIN ESTATE CLUB HOUSE KRAAIFONTEIN",
    Address: "ZANDVLIET DRIVE  KRAAIFONTEIN  CAPE TOWN",
    Latitude: "-33.8235",
    Longitude: "18.7169",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22058",
    WardLookupId: "524",
    VDNumber: "97141956",
    RegisteredPopulation: "4633",
    VotingStationName: "CURRO PRIVATE SCHOOL (SONSTRAAL HEIGHTS)",
    Address: "MOMENTO AVENUE  DURBANVILLE  CAPE TOWN",
    Latitude: "-33.8438",
    Longitude: "18.6849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22059",
    WardLookupId: "525",
    VDNumber: "97120368",
    RegisteredPopulation: "661",
    VotingStationName: "ZONNEKUS HOLIDAY RESORT",
    Address: "13 OF PORTION 141  MORNINGSTAR  PHILADELPHIA",
    Latitude: "-33.7481",
    Longitude: "18.5376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22060",
    WardLookupId: "525",
    VDNumber: "97120379",
    RegisteredPopulation: "1767",
    VotingStationName: "MASIKHULULEKE CENTRE",
    Address:
      "SITE 5, OFF POTSMANSDAM ROAD,   DU NOON, MILNERTON  CITY OF CAPE TOWN",
    Latitude: "-33.8131",
    Longitude: "18.539",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22061",
    WardLookupId: "525",
    VDNumber: "97120481",
    RegisteredPopulation: "2622",
    VotingStationName: "SOPHAKAMA PRIMARY SCHOOL",
    Address: "THANDABANTU STREET  DU NOON  KILLARNEY GATDENS",
    Latitude: "-33.8193",
    Longitude: "18.5386",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22062",
    WardLookupId: "525",
    VDNumber: "97120492",
    RegisteredPopulation: "3070",
    VotingStationName: "DU NOON PRIMARY SCHOOL",
    Address: "48 SIYABONGA STREET  DU NOON  KILLARNEY GARDENS",
    Latitude: "-33.8212",
    Longitude: "18.5405",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22063",
    WardLookupId: "525",
    VDNumber: "97120515",
    RegisteredPopulation: "2207",
    VotingStationName: "ST LAURENCE CHURCH",
    Address: "8 MNANDI STREET  DU NOON  MILNERTON",
    Latitude: "-33.8196",
    Longitude: "18.5397",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22064",
    WardLookupId: "525",
    VDNumber: "97120571",
    RegisteredPopulation: "2087",
    VotingStationName: "INKWENKWEZI HIGH SCHOOL",
    Address: "91 WAXBERRY STREET  DU NOON  KILLARNEY GARDENS",
    Latitude: "-33.8126",
    Longitude: "18.5421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22065",
    WardLookupId: "525",
    VDNumber: "97120582",
    RegisteredPopulation: "2096",
    VotingStationName: "ZUSAKHE CERCHE",
    Address: "94 WAXBERRY STREET  DU NOON  MILNERTON",
    Latitude: "-33.8131",
    Longitude: "18.5427",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22066",
    WardLookupId: "525",
    VDNumber: "97120593",
    RegisteredPopulation: "1050",
    VotingStationName: "NEW BETHEL ASSEMBLY OF GOD CHURCH DU NOON",
    Address: "280 GQAGA STREET  DUNOON  MILNERTON",
    Latitude: "-33.8113",
    Longitude: "18.5394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22067",
    WardLookupId: "525",
    VDNumber: "97120605",
    RegisteredPopulation: "1228",
    VotingStationName: "ABOMELEZI CENTRE",
    Address: "SITE 5  DOORNBACH  KILLARNEY GARDENS",
    Latitude: "-33.8108",
    Longitude: "18.5372",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22068",
    WardLookupId: "526",
    VDNumber: "97100591",
    RegisteredPopulation: "1010",
    VotingStationName: "CAPE GARDEN CENTRE",
    Address: "56 TARENTAAL STREET  JOOSTENBERG VLAKTE  KRAAIFONTEIN",
    Latitude: "-33.8323",
    Longitude: "18.7376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22069",
    WardLookupId: "526",
    VDNumber: "97141046",
    RegisteredPopulation: "5707",
    VotingStationName: "DURBANVILLE COMMUNITY CHURCH (DCC)",
    Address:
      "BOLAND WAY AND DE KEUR AVENUE,  VIERLANDEN, DURBANVILE  DURBANVILLE",
    Latitude: "-33.8118",
    Longitude: "18.6615",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22070",
    WardLookupId: "526",
    VDNumber: "97141338",
    RegisteredPopulation: "5647",
    VotingStationName: "FISANTEKRAAL HIGH SCHOOL HALL",
    Address: "CAPE TOWN  DURBANVILLE  CORNER DULLA OMAR LITCHENBERG STREET",
    Latitude: "-33.7792",
    Longitude: "18.7215",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22071",
    WardLookupId: "526",
    VDNumber: "97141888",
    RegisteredPopulation: "574",
    VotingStationName: "VISSERSHOK PRIMARY SCHOOL",
    Address:
      "CNR VISSERSHOK ROAD & CONTERMANSKLOOF ROAD  VISSERSHOK  DURBANVILLE",
    Latitude: "-33.7858",
    Longitude: "18.586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22072",
    WardLookupId: "526",
    VDNumber: "97142238",
    RegisteredPopulation: "2166",
    VotingStationName: "GOEDEMOED (TENT)",
    Address: "GOEDEMOED RD  THE CREST  DURBANVILLE",
    Latitude: "-33.82",
    Longitude: "18.6724",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22073",
    WardLookupId: "526",
    VDNumber: "98430082",
    RegisteredPopulation: "976",
    VotingStationName: "BOERESAAL PHILADELPHIA",
    Address: "CHURCH SQUARE  PHILADELPHIA  ",
    Latitude: "-33.6663",
    Longitude: "18.5808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22074",
    WardLookupId: "526",
    VDNumber: "98430273",
    RegisteredPopulation: "1514",
    VotingStationName: "KLIPPIE KIDS PRE-SCHOOL",
    Address: "CLOETE EXTENSION  KLIPHEUWEL  DURBANVILLE",
    Latitude: "-33.6989",
    Longitude: "18.7023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22075",
    WardLookupId: "527",
    VDNumber: "97141798",
    RegisteredPopulation: "2718",
    VotingStationName: "SUNRAY PRIMARY SCHOOL",
    Address: "518 MAIN RD  DELFT SOUTH  ",
    Latitude: "-33.9892",
    Longitude: "18.6379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22076",
    WardLookupId: "527",
    VDNumber: "97142003",
    RegisteredPopulation: "3595",
    VotingStationName: "HINDLE ROAD PRIMARY SHCOOL",
    Address: "COCK STREET  DELFT  CAPE TOWN",
    Latitude: "-33.9816",
    Longitude: "18.6346",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22077",
    WardLookupId: "527",
    VDNumber: "97142216",
    RegisteredPopulation: "3381",
    VotingStationName: "KAIROS PRIMARY SCHOOL",
    Address: "CNR LEIDEN & ELAND STREET  LEIDEN  DELFT",
    Latitude: "-33.9709",
    Longitude: "18.6352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22078",
    WardLookupId: "527",
    VDNumber: "97142306",
    RegisteredPopulation: "2487",
    VotingStationName: "MASONWABE PRIMARY SCHOOL",
    Address: "TAMARISK STREET  DELFT SOUTH  DELFT",
    Latitude: "-33.9899",
    Longitude: "18.6297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22079",
    WardLookupId: "527",
    VDNumber: "97142418",
    RegisteredPopulation: "3158",
    VotingStationName: "MASIBAMBISANE HIGH SCHOOL",
    Address: "669 MAIN ROAD  DELFT SOUTH  ",
    Latitude: "-33.9928",
    Longitude: "18.6332",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22080",
    WardLookupId: "527",
    VDNumber: "97142610",
    RegisteredPopulation: "1709",
    VotingStationName: "HINDLE SECONDARY SCHOOL",
    Address: "CNR OWEN MADIKANE & RUSSIAN OLIVE ST  DELFT SOUTH   DELFT",
    Latitude: "-33.9767",
    Longitude: "18.6363",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22081",
    WardLookupId: "527",
    VDNumber: "97142654",
    RegisteredPopulation: "2128",
    VotingStationName: "GATEWAY PRIMARY SCHOOL",
    Address: "ABEDARE STREET  LEIDEN  DELFT",
    Latitude: "-33.9694",
    Longitude: "18.6288",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22082",
    WardLookupId: "527",
    VDNumber: "97142665",
    RegisteredPopulation: "1708",
    VotingStationName: "GROUND LEVEL MINISTRIES",
    Address: "CNR SYMPHONY WAY & HINDLE ROAD  DELFT BLIKKIESDORP  CAPE TOWN",
    Latitude: "-33.9816",
    Longitude: "18.6306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22083",
    WardLookupId: "528",
    VDNumber: "97120144",
    RegisteredPopulation: "3395",
    VotingStationName: "BLOUBERGRANT HIGH SCHOOL",
    Address: "4     CURLEWIS  BLOUBERG RANT   BLAAUWBERG",
    Latitude: "-33.8178",
    Longitude: "18.4871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22084",
    WardLookupId: "528",
    VDNumber: "97120403",
    RegisteredPopulation: "4664",
    VotingStationName: "SUNNINGDALE PRIMARY SCHOOL",
    Address: "SUNNINGDALE DRIVE  SUNNINGDALE  CAPE TOWN",
    Latitude: "-33.7974",
    Longitude: "18.4831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22085",
    WardLookupId: "528",
    VDNumber: "97120414",
    RegisteredPopulation: "6607",
    VotingStationName: "CBC ST JOHNS SCHOOL",
    Address:
      "CNR DORCHESTER DRIVE & PARKLANDS MAIN ROAD  PARKLANDS  TABLE VIEW",
    Latitude: "-33.8093",
    Longitude: "18.5106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22086",
    WardLookupId: "528",
    VDNumber: "97120537",
    RegisteredPopulation: "3993",
    VotingStationName: "FULL GOSPEL CHURCH OF GOD PARKLANDS ( HALL)",
    Address: "CAPE TOWN  PARKLANDS  141, 139 HUMEWOOD DRIVE",
    Latitude: "-33.8048",
    Longitude: "18.4948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22087",
    WardLookupId: "529",
    VDNumber: "97100412",
    RegisteredPopulation: "2141",
    VotingStationName: "SILVER-SANDS PRIMARY SCHOOL",
    Address: "HOCKENHEIM DRIVE  SILVERSANDS  CAPE TOWN, BLUE DOWNS",
    Latitude: "-33.9774",
    Longitude: "18.6734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22088",
    WardLookupId: "529",
    VDNumber: "97100647",
    RegisteredPopulation: "2027",
    VotingStationName: "BLUE DOWNS SWIMMING POOL",
    Address: "HINDLE  FOUNTAIN VILLAGE  BLUE DOWNS",
    Latitude: "-33.9869",
    Longitude: "18.6863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22089",
    WardLookupId: "529",
    VDNumber: "97100692",
    RegisteredPopulation: "1662",
    VotingStationName: "UNITED REFORMED CHURCH",
    Address: "NOOIENSFONTEIN ROAD  BLUEDOWNS  MFULENI",
    Latitude: "-33.9716",
    Longitude: "18.6763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22090",
    WardLookupId: "529",
    VDNumber: "97100760",
    RegisteredPopulation: "1028",
    VotingStationName: "COMMUNITY FAITH CHURCH - SILVERSANDS",
    Address: "SECKENHEIM  SILVERSANDS  CAPE TOWN",
    Latitude: "-33.9815",
    Longitude: "18.6775",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22091",
    WardLookupId: "529",
    VDNumber: "97100940",
    RegisteredPopulation: "5665",
    VotingStationName: "BARDALE PRIMARY SCHOOL",
    Address: "CNR PARLIAMENT & COUNCILLOR ST  BARDALE  MFULENI",
    Latitude: "-33.9959",
    Longitude: "18.6799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22092",
    WardLookupId: "529",
    VDNumber: "97141822",
    RegisteredPopulation: "751",
    VotingStationName: "BRENTWOOD COMMUNITY CHURCH",
    Address: "INEZ  DELF  DELF",
    Latitude: "-33.9855",
    Longitude: "18.652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22093",
    WardLookupId: "529",
    VDNumber: "97142283",
    RegisteredPopulation: "4176",
    VotingStationName: "MFULENI SECONDARY SCHOOL",
    Address: "CNR TREVOR TOKWANA & NQUBELANI STREET  MFULENI  ",
    Latitude: "-33.9901",
    Longitude: "18.6697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22094",
    WardLookupId: "529",
    VDNumber: "97142294",
    RegisteredPopulation: "3267",
    VotingStationName: "ITSITSA PRIMARY SCHOOL",
    Address: "CNR NQUBELANI & ITSITSA STREET  EXTENTION 6  MFULENI",
    Latitude: "-33.9898",
    Longitude: "18.6719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22095",
    WardLookupId: "530",
    VDNumber: "97110019",
    RegisteredPopulation: "3545",
    VotingStationName: "CHRISTEN GEMEENTE BELL GLEN EDUCARE",
    Address: "3 IRIS WAY  MACASSAR  MACASSAR",
    Latitude: "-34.0594",
    Longitude: "18.7558",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22096",
    WardLookupId: "530",
    VDNumber: "97110042",
    RegisteredPopulation: "2092",
    VotingStationName: "MACASSAR COMMUNITY CENTRE",
    Address: "BURG STREET  BELL GLEN  MACASSAR",
    Latitude: "-34.0609",
    Longitude: "18.7615",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22097",
    WardLookupId: "530",
    VDNumber: "97110053",
    RegisteredPopulation: "2519",
    VotingStationName: "MACASSAR COMMUNITY HALL (OLD)",
    Address: "HOSPITAL STREET  MACASSAR  CAPE TOWN, MACASSAR",
    Latitude: "-34.062",
    Longitude: "18.7635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22098",
    WardLookupId: "530",
    VDNumber: "97110064",
    RegisteredPopulation: "2505",
    VotingStationName: "MACASSAR SECONDARY SCHOOL",
    Address: "RING AVENUE  MACASSAR  ",
    Latitude: "-34.0637",
    Longitude: "18.7652",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22099",
    WardLookupId: "530",
    VDNumber: "97110075",
    RegisteredPopulation: "2461",
    VotingStationName: "MARVIN PARK PRIMARY SCHOOL",
    Address: "BASE  MARVIN PARK  MACASSAR",
    Latitude: "-34.0613",
    Longitude: "18.7722",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22100",
    WardLookupId: "530",
    VDNumber: "97110143",
    RegisteredPopulation: "2056",
    VotingStationName: "CALVARY PENTECOSTAL CHURCH",
    Address: "MAIN ROAD  CROYDON  FAURE",
    Latitude: "-34.0393",
    Longitude: "18.7584",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22101",
    WardLookupId: "530",
    VDNumber: "97110525",
    RegisteredPopulation: "3010",
    VotingStationName: "FALSE BAY PRIMARY SCHOOL",
    Address: "WALTER SISULU  MACASSAR VILLAGE  MACASSAR",
    Latitude: "-34.0551",
    Longitude: "18.7508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22102",
    WardLookupId: "531",
    VDNumber: "97130628",
    RegisteredPopulation: "1507",
    VotingStationName: "EVERY NATION",
    Address: "CNR RETREAT ROAD & PRINCE GEORGE DRIVE  RETREAT  CAPE TOWN",
    Latitude: "-34.0556",
    Longitude: "18.4872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22103",
    WardLookupId: "531",
    VDNumber: "97130639",
    RegisteredPopulation: "1886",
    VotingStationName: "SULLIVAN PRIMARY SCHOOL",
    Address: "118 SULLIVAN STREET  RETREAT  CAPE TOWN",
    Latitude: "-34.0671",
    Longitude: "18.4791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22104",
    WardLookupId: "531",
    VDNumber: "97130651",
    RegisteredPopulation: "1644",
    VotingStationName: "LOURIER PRIMARY SCHOOL",
    Address: "BLAGDEN STREET  RETREAT  ",
    Latitude: "-34.0593",
    Longitude: "18.4821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22105",
    WardLookupId: "531",
    VDNumber: "97130662",
    RegisteredPopulation: "1869",
    VotingStationName: "DELTA PRIMARY SCHOOL",
    Address: "TAMBOURINE STREET  STEENBERG  ",
    Latitude: "-34.0641",
    Longitude: "18.4847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22106",
    WardLookupId: "531",
    VDNumber: "97130998",
    RegisteredPopulation: "1572",
    VotingStationName: "KANNEMEYER PRIMARY SCHOOL",
    Address: "THIRD AVENUE  GRASSY PARK  CAPE TOWN",
    Latitude: "-34.0475",
    Longitude: "18.4927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22107",
    WardLookupId: "531",
    VDNumber: "97131001",
    RegisteredPopulation: "2602",
    VotingStationName: "GRASSY PARK HIGH SCHOOL",
    Address: "CNR VICTORIA & 6TH AVENUE   GRASSY PARK  CAPE TOWN",
    Latitude: "-34.051",
    Longitude: "18.497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22108",
    WardLookupId: "531",
    VDNumber: "97131012",
    RegisteredPopulation: "1643",
    VotingStationName: "UIT LIEFDE HULPGENOOTSKAP",
    Address: "8 FIFTH AVENUE  GRASSY PARK  ",
    Latitude: "-34.0536",
    Longitude: "18.4897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22109",
    WardLookupId: "531",
    VDNumber: "97131034",
    RegisteredPopulation: "1667",
    VotingStationName: "PRINCE GEORGE PRIMARY SCHOOL",
    Address: "PRINCE GEORGE DRIVE  RETREAT  ",
    Latitude: "-34.0643",
    Longitude: "18.4871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22110",
    WardLookupId: "532",
    VDNumber: "97100456",
    RegisteredPopulation: "2238",
    VotingStationName: "METHODIST CHURCH (KRAAIFONTEIN)",
    Address: "MILNER STREET  SCOTTSVILLE, KRAAIFONTEIN  CAPE TOWN",
    Latitude: "-33.8522",
    Longitude: "18.7056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22111",
    WardLookupId: "532",
    VDNumber: "97100478",
    RegisteredPopulation: "2623",
    VotingStationName: "SCOTTSVILLE HIGH SCHOOL",
    Address: "PETUNIA STREET  SCOTTSVILLE  KRAAIFONTEIN",
    Latitude: "-33.8543",
    Longitude: "18.7119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22112",
    WardLookupId: "532",
    VDNumber: "97100489",
    RegisteredPopulation: "1979",
    VotingStationName: "UNITING REFORMED CHURCH IN SOUTHERN AFRICA",
    Address: "PERTH  KRAAIFONTEIN  CAPE TOWN",
    Latitude: "-33.8514",
    Longitude: "18.7195",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22113",
    WardLookupId: "532",
    VDNumber: "97100490",
    RegisteredPopulation: "1716",
    VotingStationName: "NG KERK MOEDERGEMEENTE KRAAIFONTEIN",
    Address: "CNR 9TH AVENUE & STATION STREET  KRAAIFONTEIN  ",
    Latitude: "-33.8485",
    Longitude: "18.7258",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22114",
    WardLookupId: "532",
    VDNumber: "97100502",
    RegisteredPopulation: "2020",
    VotingStationName: "SIMONSBERG PRIMARY SCHOOL",
    Address: "NINTH AVENUE  KRAAIFONTEIN  ",
    Latitude: "-33.8472",
    Longitude: "18.7273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22115",
    WardLookupId: "532",
    VDNumber: "97100614",
    RegisteredPopulation: "2552",
    VotingStationName: "NG KERK KRAAIFONTEIN NOORD",
    Address: "HOFF  KRAAIFONTEIN  CAPE TOWN",
    Latitude: "-33.8455",
    Longitude: "18.7179",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22116",
    WardLookupId: "532",
    VDNumber: "97100737",
    RegisteredPopulation: "2210",
    VotingStationName: "WALLACEDENE HIGH SCHOOL",
    Address: "WALTER SISULU  WALLACEDENE  BRACKENFELL",
    Latitude: "-33.8529",
    Longitude: "18.7395",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22117",
    WardLookupId: "532",
    VDNumber: "97100793",
    RegisteredPopulation: "998",
    VotingStationName: "EIKENDAL PRIMARY SCHOOL",
    Address: "270 8TH AVENUE  EIKENDAL  KRAAIFONTEIN]",
    Latitude: "-33.8567",
    Longitude: "18.7163",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22118",
    WardLookupId: "533",
    VDNumber: "97100917",
    RegisteredPopulation: "2907",
    VotingStationName: "GOEDEMOED BAPTIST CHURCH",
    Address: "GOEDEMOED ROAD  GOEDEMOED  DURBANVILLE",
    Latitude: "-33.8265",
    Longitude: "18.6741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22119",
    WardLookupId: "533",
    VDNumber: "97141013",
    RegisteredPopulation: "3073",
    VotingStationName: "DURBANVILLE METHODIST CHURCH",
    Address: "BOWLERS AVENUE  AURORA  DURBANVILLE",
    Latitude: "-33.8381",
    Longitude: "18.6369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22120",
    WardLookupId: "533",
    VDNumber: "97141024",
    RegisteredPopulation: "4326",
    VotingStationName: "DURBANVILLE HIGH SCHOOL",
    Address: "LANGENHOVEN STREET  DURBANVILLE  CAPE TOWN",
    Latitude: "-33.831",
    Longitude: "18.6434",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22121",
    WardLookupId: "533",
    VDNumber: "97141035",
    RegisteredPopulation: "2717",
    VotingStationName: "ALPHA PRIMARY SCHOOL",
    Address: "CNR SKOOL & CHURCH STR  MORNING STAR  DURBANVILLE",
    Latitude: "-33.8282",
    Longitude: "18.655",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22122",
    WardLookupId: "533",
    VDNumber: "97141923",
    RegisteredPopulation: "2217",
    VotingStationName: "MOSSELBANK RIVER STREET (TENT)",
    Address: "MOSSELBANK RIVER STREET  DURBANVILLE  CAPE TOWN",
    Latitude: "-33.8172",
    Longitude: "18.6393",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22123",
    WardLookupId: "533",
    VDNumber: "97142384",
    RegisteredPopulation: "1934",
    VotingStationName: "DURBANVILLE PRIMARY SCHOOL",
    Address: "WEYERS AVENUE  DURBANVILLE  CAPE TOWN",
    Latitude: "-33.8364",
    Longitude: "18.6482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22124",
    WardLookupId: "534",
    VDNumber: "97120100",
    RegisteredPopulation: "4084",
    VotingStationName: "TABLEVIEW PRIMARY SCHOOL",
    Address: "CAPE TOWN 7441  TABLEVIEW  FAIRWAYS ROAD",
    Latitude: "-33.8345",
    Longitude: "18.5131",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22125",
    WardLookupId: "534",
    VDNumber: "97120111",
    RegisteredPopulation: "2734",
    VotingStationName: "GRACE BIBLE CHURCH",
    Address: "15 SHIRE ROAD  TABLE VIEW  CAPE TOWN",
    Latitude: "-33.823",
    Longitude: "18.5156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22126",
    WardLookupId: "534",
    VDNumber: "97120122",
    RegisteredPopulation: "3352",
    VotingStationName: "EARLY LEARNING CENTRE (TABLEVIEW)",
    Address: "4 CLARET ROAD  TABLE VIEW  CAPE TOWN",
    Latitude: "-33.8235",
    Longitude: "18.5038",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22127",
    WardLookupId: "534",
    VDNumber: "97120133",
    RegisteredPopulation: "3473",
    VotingStationName: "TABLE VIEW HIGH SCHOOL",
    Address: "CNR JANSENS & SCHOOL STREETS  TABLE VIEW  CAPE TOWN",
    Latitude: "-33.8311",
    Longitude: "18.4988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22128",
    WardLookupId: "534",
    VDNumber: "97120560",
    RegisteredPopulation: "2672",
    VotingStationName: "REGENT (TENT)",
    Address: "CNR REGENT ROAD & PARKLANDS WALK  PARKLANDS  TABLE VIEW",
    Latitude: "-33.8183",
    Longitude: "18.5037",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22129",
    WardLookupId: "535",
    VDNumber: "97141585",
    RegisteredPopulation: "480",
    VotingStationName: "GREENPARK COMMUNITY CENTRE",
    Address: "GREENPARK  MFULENI  ",
    Latitude: "-33.993",
    Longitude: "18.6475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22130",
    WardLookupId: "535",
    VDNumber: "97141833",
    RegisteredPopulation: "2828",
    VotingStationName: "MFULENI ASSEMBLIES OF GOD",
    Address: "LUKHANYO  BLUE DOWNS  MFULENI",
    Latitude: "-34.0047",
    Longitude: "18.678",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22131",
    WardLookupId: "535",
    VDNumber: "97141844",
    RegisteredPopulation: "1685",
    VotingStationName: "MZAMOMTSHA PRIMARY SCHOOL",
    Address: "VALANGENTABO  DRIFTSANDS  CAPE TOWN",
    Latitude: "-34.0033",
    Longitude: "18.6516",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22132",
    WardLookupId: "535",
    VDNumber: "97141967",
    RegisteredPopulation: "3565",
    VotingStationName: "NYAMEKO PRIMARY SCHOOL",
    Address: "CNR PONOANE & TOKWANA STREET  EXTENTION 4  MFULENI",
    Latitude: "-33.9979",
    Longitude: "18.6714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22133",
    WardLookupId: "535",
    VDNumber: "97141978",
    RegisteredPopulation: "2258",
    VotingStationName: "SAKHULUNTU EDUCARE",
    Address: "3972 THAFENI STREET  MFULENI  CAPE TOWN",
    Latitude: "-34.0044",
    Longitude: "18.6713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22134",
    WardLookupId: "535",
    VDNumber: "97142261",
    RegisteredPopulation: "1391",
    VotingStationName: "CHRIST THE KING CHURCH - MFULENI",
    Address: "25 LERATO PAONANE  MFULENI  CAPE TOWN",
    Latitude: "-33.9972",
    Longitude: "18.675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22135",
    WardLookupId: "535",
    VDNumber: "97142272",
    RegisteredPopulation: "2064",
    VotingStationName: "ETERNAL LIGHT OF LIFE MINISTRIES - MFULENI",
    Address: "42 MCESHEMBA  MFULENI  CAPE TOWN",
    Latitude: "-34.0006",
    Longitude: "18.6706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22136",
    WardLookupId: "536",
    VDNumber: "97090061",
    RegisteredPopulation: "2749",
    VotingStationName: "HELLENIC COMMUNITY CENTRE",
    Address: "24 BAY ROAD , MOUILLE POINT   MOUILLE POINT  CAPE TOWN",
    Latitude: "-33.9007",
    Longitude: "18.4069",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22137",
    WardLookupId: "536",
    VDNumber: "97090241",
    RegisteredPopulation: "2558",
    VotingStationName: "CAPE TOWN CIVIC CENTRE",
    Address: "12 HERTZOG BOULEVARD  HEERENGRACHT  CAPE TOWN",
    Latitude: "-33.9214",
    Longitude: "18.4282",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22138",
    WardLookupId: "536",
    VDNumber: "97090252",
    RegisteredPopulation: "3539",
    VotingStationName: "JAN VAN RIEBEECK PRIMARY SCHOOL",
    Address: "KLOOFNEK ROAD  TAMBOERSKLOOF  CAPE TOWN",
    Latitude: "-33.9298",
    Longitude: "18.4102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22139",
    WardLookupId: "536",
    VDNumber: "97090308",
    RegisteredPopulation: "2374",
    VotingStationName: "WOODSTOCK TOWN HALL",
    Address: "MAIN ROAD  WOODSTOCK  CAPE TOWN",
    Latitude: "-33.9286",
    Longitude: "18.4499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22140",
    WardLookupId: "536",
    VDNumber: "97090319",
    RegisteredPopulation: "2005",
    VotingStationName: "CHAPEL STREET PRIMARY",
    Address: "CHAPEL STREET  WOODSTOCK  CAPE TOWN",
    Latitude: "-33.9294",
    Longitude: "18.439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22141",
    WardLookupId: "536",
    VDNumber: "97090331",
    RegisteredPopulation: "1181",
    VotingStationName: "SALT RIVER TOWN HALL",
    Address: "VOORTREKKER ROAD  SALT RIVER  ",
    Latitude: "-33.9277",
    Longitude: "18.4608",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22142",
    WardLookupId: "536",
    VDNumber: "97092063",
    RegisteredPopulation: "4347",
    VotingStationName: "ELLERTON PRIMARY SCHOOL",
    Address: "MAIN ROAD  THREE ANCHOR BAY  CAPE TOWN",
    Latitude: "-33.9085",
    Longitude: "18.3981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22143",
    WardLookupId: "537",
    VDNumber: "97091398",
    RegisteredPopulation: "2597",
    VotingStationName: "OVAL NORTH HIGH SCHOOL",
    Address: "OVAL NORTH STREET  BEACON VALLEY  CAPE TOWN",
    Latitude: "-34.0374",
    Longitude: "18.6314",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22144",
    WardLookupId: "537",
    VDNumber: "97091433",
    RegisteredPopulation: "2340",
    VotingStationName: "ALPINE PRIMARY SCHOOL",
    Address: "C/O GLIDER & ALPINE RD  BEACON VALLEY  CAPE TOWN",
    Latitude: "-34.0368",
    Longitude: "18.6263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22145",
    WardLookupId: "537",
    VDNumber: "97091680",
    RegisteredPopulation: "2471",
    VotingStationName: "THE FARM RECREATION CENTRE",
    Address: "CNR ELIZABETH & SAMANTHA STR  MONTROSE PARK  MITCHELLS PLAIN",
    Latitude: "-34.0276",
    Longitude: "18.6301",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22146",
    WardLookupId: "537",
    VDNumber: "97091725",
    RegisteredPopulation: "3613",
    VotingStationName: "MANDALAY PRIMARY SCHOOL",
    Address: "CNR AUBER & KIPLING ROAD  MANDALAY  CAPE TOWN",
    Latitude: "-34.0149",
    Longitude: "18.6297",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22147",
    WardLookupId: "537",
    VDNumber: "97092029",
    RegisteredPopulation: "2382",
    VotingStationName: "AZ BERMAN PRIMARY SCHOOL",
    Address: "CNR A Z BERMAN & KERREM AVE  BEACON VALLEY  MITCHELLS PLAIN",
    Latitude: "-34.0395",
    Longitude: "18.6196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22148",
    WardLookupId: "537",
    VDNumber: "97093479",
    RegisteredPopulation: "1006",
    VotingStationName: "MONTCLAIR PLACE (OLD AGE HOME)",
    Address: "47 MONTCLAIR DRIVE  MONTCLAIR  LENTEGEUR",
    Latitude: "-34.0213",
    Longitude: "18.6287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22149",
    WardLookupId: "2671",
    VDNumber: "97700014",
    RegisteredPopulation: "2175",
    VotingStationName: "UITKYK COMMUNITY HALL",
    Address: "DU TOIT STREET  LUTZVILLE  VREDENDAL",
    Latitude: "-31.55",
    Longitude: "18.3417",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22150",
    WardLookupId: "2671",
    VDNumber: "97700036",
    RegisteredPopulation: "586",
    VotingStationName: "LUTZVILLE HIGH SCHOOL",
    Address: "STATION ROAD  LUTZVILLE  LUTZVILLE",
    Latitude: "-31.5579",
    Longitude: "18.34",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22151",
    WardLookupId: "2671",
    VDNumber: "97860033",
    RegisteredPopulation: "174",
    VotingStationName: "BOOYSENDAL PRIMARY SCHOOL",
    Address: "LIEBENDAL  VREDENDAL  ",
    Latitude: "-31.6083",
    Longitude: "18.4168",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22152",
    WardLookupId: "2671",
    VDNumber: "97860088",
    RegisteredPopulation: "852",
    VotingStationName: "LUTZVILLE WES COMMUNITY HALL",
    Address: "LUTZVILLE-WES  LUTZVILLE  LUTZVILLE",
    Latitude: "-31.5718",
    Longitude: "18.3173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22153",
    WardLookupId: "2672",
    VDNumber: "97620015",
    RegisteredPopulation: "915",
    VotingStationName: "MIRIAM OWIES COMMUNITY HALL DOORNBAAI",
    Address: "01 KUSWEG  DOORNBAAI  DOORNBAAI",
    Latitude: "-31.816",
    Longitude: "18.2361",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22154",
    WardLookupId: "2672",
    VDNumber: "97630016",
    RegisteredPopulation: "1386",
    VotingStationName: "EBENHAESER COMMUNITY HALL",
    Address: "118 LOUISE STREET  NUWESTASIE  EBENHAESER",
    Latitude: "-31.583",
    Longitude: "18.2406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22155",
    WardLookupId: "2672",
    VDNumber: "97860101",
    RegisteredPopulation: "894",
    VotingStationName: "NAASTDRIFT PRIMARY SCHOOL",
    Address: "NAASTDRIFT FARM  PRODUKTE MARK  VREDENDAL",
    Latitude: "-31.6631",
    Longitude: "18.4783",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22156",
    WardLookupId: "2673",
    VDNumber: "97790057",
    RegisteredPopulation: "3909",
    VotingStationName: "VERGENOEG PRIMARY SCHOOL",
    Address: "BUITEKANT  VREDENDAL  MATZIKAMA [VREDENDAL]",
    Latitude: "-31.6345",
    Longitude: "18.5296",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22157",
    WardLookupId: "2674",
    VDNumber: "97790013",
    RegisteredPopulation: "3223",
    VotingStationName: "VREDENDAL THUSONG CENTRE SERVICES",
    Address: "AAS LE FLEUR STREET  VREDENDAL NORTH  VREDENDAL",
    Latitude: "-31.6441",
    Longitude: "18.5281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22158",
    WardLookupId: "2674",
    VDNumber: "97790079",
    RegisteredPopulation: "289",
    VotingStationName: "METHODIST CHURCH VREDENDAL NORTH",
    Address: "BINNESINGEL  VREDENDAL NORTH  VREDENDAL",
    Latitude: "-31.6415",
    Longitude: "18.5344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22159",
    WardLookupId: "2674",
    VDNumber: "97860099",
    RegisteredPopulation: "637",
    VotingStationName: "KLEINRIVIER PRIMARY SCHOOL",
    Address: "KLEINRIVIER  VREDENDAL  VREDENDAL",
    Latitude: "-31.6837",
    Longitude: "18.5375",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22160",
    WardLookupId: "2675",
    VDNumber: "97790068",
    RegisteredPopulation: "611",
    VotingStationName: "VREDENDAL HIGH SCHOOL",
    Address: "8 GRENS STREET  VREDENDAL SOUTH  VREDENDAL",
    Latitude: "-31.662",
    Longitude: "18.5033",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22161",
    WardLookupId: "2675",
    VDNumber: "97860022",
    RegisteredPopulation: "948",
    VotingStationName: "SPRUITDRIFT PRIMARY SCHOOL",
    Address: "SPRUITDRIFT  VREDENDAL  VREDENDAL",
    Latitude: "-31.7135",
    Longitude: "18.5248",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22162",
    WardLookupId: "2675",
    VDNumber: "97860044",
    RegisteredPopulation: "275",
    VotingStationName: "STEILHOOGTE PRIMARY SCHOOL",
    Address: "STEILHOOGTE  KLAWER  KLAWER",
    Latitude: "-31.729",
    Longitude: "18.5556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22163",
    WardLookupId: "2675",
    VDNumber: "97860055",
    RegisteredPopulation: "1757",
    VotingStationName: "VREDENDAL SPORTS HALL",
    Address: "DAMSTREET  VREDENDAL  VREDENDAL",
    Latitude: "-31.6727",
    Longitude: "18.5",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22164",
    WardLookupId: "2676",
    VDNumber: "97660019",
    RegisteredPopulation: "1503",
    VotingStationName: "KLAWER COMMUNITY HALL",
    Address: "ALPHA STREET  KLAWER NORTH  KLAWER",
    Latitude: "-31.7684",
    Longitude: "18.6227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22165",
    WardLookupId: "2676",
    VDNumber: "97660031",
    RegisteredPopulation: "765",
    VotingStationName: "KLAWER PRIMARY SCHOOL HALL",
    Address: "VALLEISTRAAT  KLAWER",
    Latitude: "-31.782",
    Longitude: "18.6273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22166",
    WardLookupId: "2676",
    VDNumber: "97660042",
    RegisteredPopulation: "1665",
    VotingStationName: "ST JAMES ANGLICAN CHURCH KLAWER",
    Address: "ALPHA  KLAWER NORTH  MATZIKAMA [VREDENDAL]",
    Latitude: "-31.7678",
    Longitude: "18.6261",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22167",
    WardLookupId: "2676",
    VDNumber: "97850021",
    RegisteredPopulation: "208",
    VotingStationName: "VREDEHOEK BEWAARSKOOL",
    Address: "VREDEHOEK FARM  TRAWAL  KLAWER",
    Latitude: "-31.84",
    Longitude: "18.6356",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22168",
    WardLookupId: "2676",
    VDNumber: "97860077",
    RegisteredPopulation: "541",
    VotingStationName: "TRAWAL BEWAARSKOOL",
    Address: "MAIN STREET  TRAWAL  KLAWER",
    Latitude: "-31.8863",
    Longitude: "18.634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22169",
    WardLookupId: "2677",
    VDNumber: "97770011",
    RegisteredPopulation: "610",
    VotingStationName: "VANRHYNSDORP TOWN HALL",
    Address: "14 CHURCH STREET  VANRHYNSDORP",
    Latitude: "-31.6087",
    Longitude: "18.7391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22170",
    WardLookupId: "2677",
    VDNumber: "97770022",
    RegisteredPopulation: "1434",
    VotingStationName: "VANRHYNSDORP COMMUNITY HALL",
    Address: "OASIS STREET  VANRHYNSDORP",
    Latitude: "-31.5967",
    Longitude: "18.7519",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22171",
    WardLookupId: "2677",
    VDNumber: "97770033",
    RegisteredPopulation: "992",
    VotingStationName: "VANRHYNSDORP MASIBAMBANE MULTIPURPOSE CENTRE",
    Address: "MASKAMSIG  VANRHYNSDORP",
    Latitude: "-31.5994",
    Longitude: "18.7474",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22172",
    WardLookupId: "2677",
    VDNumber: "97770044",
    RegisteredPopulation: "478",
    VotingStationName: "VANRHYNSDORP HIGH SCHOOL",
    Address: "SKOOLSTRAAT  VANRHYNSDORP  VANRHYNSDORP",
    Latitude: "-31.6064",
    Longitude: "18.7379",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22173",
    WardLookupId: "2677",
    VDNumber: "97850054",
    RegisteredPopulation: "487",
    VotingStationName: "RONDERUG FARM (URIONSKRAAL)",
    Address: "URIONSKRAAL ROAD  URIONSKRAAL  VANRHYNSDORP",
    Latitude: "-31.6876",
    Longitude: "18.8796",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22174",
    WardLookupId: "2677",
    VDNumber: "97850065",
    RegisteredPopulation: "14",
    VotingStationName: "NGK VANRHYNSDORP (GROOTDRIFT)",
    Address: "CALVINIA RD, VANRHYNSDORP  GROOTDRIFT  VANRHYNSDORP",
    Latitude: "-31.4579",
    Longitude: "18.957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22175",
    WardLookupId: "2678",
    VDNumber: "97580010",
    RegisteredPopulation: "590",
    VotingStationName: "BITTERFONTEIN COMMUNITY HALL",
    Address: "MAIN STREET  BITTERFONTEIN  BITTERFONTEIN",
    Latitude: "-31.0486",
    Longitude: "18.2609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22176",
    WardLookupId: "2678",
    VDNumber: "97580021",
    RegisteredPopulation: "342",
    VotingStationName: "NUWERUS COMMUNITY HALL",
    Address: "KOKERBOOM STREET  NUWERUS  NUWERUS",
    Latitude: "-31.1472",
    Longitude: "18.3569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22177",
    WardLookupId: "2678",
    VDNumber: "97700025",
    RegisteredPopulation: "1136",
    VotingStationName: "KOEKENAAP COMMUNITY HALL",
    Address: "PETUNIA STREET  KOEKENAAP  KOEKENAAP",
    Latitude: "-31.5243",
    Longitude: "18.2939",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22178",
    WardLookupId: "2678",
    VDNumber: "97760010",
    RegisteredPopulation: "939",
    VotingStationName: "MOBILE VOTING STATION (STOFKRAAL COMMUNITY HALL)",
    Address: "MAIN ROAD  STOFKRAAL   STOFKRAAL",
    Latitude: "-30.8294",
    Longitude: "18.0188",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22179",
    WardLookupId: "2678",
    VDNumber: "97850010",
    RegisteredPopulation: "82",
    VotingStationName: "KLIPBERG STOOR BITTERFONTEIN",
    Address: "N7 HIGH WAY, KLIPRAND TURNOFF  BITTERFONTEIN  BITTERFONTEIN",
    Latitude: "-31.0029",
    Longitude: "18.2575",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22180",
    WardLookupId: "2678",
    VDNumber: "97850032",
    RegisteredPopulation: "268",
    VotingStationName: "KLIPRAND COMMUNITY HALL",
    Address: "AANDSTERSTRAAT  KLIPRAND  VANRHYNSDORP",
    Latitude: "-30.5896",
    Longitude: "18.6855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22181",
    WardLookupId: "2678",
    VDNumber: "97860011",
    RegisteredPopulation: "52",
    VotingStationName: "KOMKANS FARM NUWERUS",
    Address: "KOMKANS FARM  NUWERUS  NUWERUS",
    Latitude: "-31.2033",
    Longitude: "18.0791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22182",
    WardLookupId: "2679",
    VDNumber: "97820017",
    RegisteredPopulation: "1145",
    VotingStationName: "NOORDHOEK NGK PRIMÃŠRE SKOOL",
    Address: "NOORDHOEK FARM  NOORDHOEK  CITRUSDAL 7340",
    Latitude: "-32.7193",
    Longitude: "19.0654",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22183",
    WardLookupId: "2679",
    VDNumber: "97820073",
    RegisteredPopulation: "463",
    VotingStationName: "HEXRIVIER NGK PRIMÃŠRE SKOOL",
    Address: "HEXRIVIER FARM  HEXRIVIER  CITRUSDAL 7340",
    Latitude: "-32.4428",
    Longitude: "18.9723",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22184",
    WardLookupId: "2679",
    VDNumber: "97820084",
    RegisteredPopulation: "874",
    VotingStationName: "DANCKAERT SAAL",
    Address: "DANCKAERT FARM  CITRUSDAL  CITRUSDAL",
    Latitude: "-32.6114",
    Longitude: "19.0096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22185",
    WardLookupId: "2679",
    VDNumber: "97820095",
    RegisteredPopulation: "597",
    VotingStationName: "SEWE OLIENE - STOOR",
    Address: "SEWE OLIENE FARM  CITRUSDAL",
    Latitude: "-32.6457",
    Longitude: "19.0388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22186",
    WardLookupId: "2679",
    VDNumber: "97820130",
    RegisteredPopulation: "148",
    VotingStationName: "DWARSRIVIER SAAL",
    Address: "DWARSRIVIER FARM  CEDERBERG  CLANWILLIAM",
    Latitude: "-32.5031",
    Longitude: "19.2562",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22187",
    WardLookupId: "2679",
    VDNumber: "97820152",
    RegisteredPopulation: "642",
    VotingStationName: "PAARDEKOP PRIMÃŠRE SKOOL",
    Address: "NUWEDAM FARM  NUWEDAM  CEDERBERG [CITRUSDAL]",
    Latitude: "-32.6029",
    Longitude: "18.9391",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22188",
    WardLookupId: "2679",
    VDNumber: "97820163",
    RegisteredPopulation: "320",
    VotingStationName: "ELANDSKLOOF SKOOLSAAL",
    Address: "ELANDSKLOOF  CITRUSDAL",
    Latitude: "-32.6496",
    Longitude: "19.136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22189",
    WardLookupId: "2680",
    VDNumber: "97590011",
    RegisteredPopulation: "1387",
    VotingStationName: "ORANJEVILLE SPORT COMPLEX",
    Address: "EIKESTRAAT  ORANJEVILLE  CITRUSDAL",
    Latitude: "-32.5835",
    Longitude: "19.0059",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22190",
    WardLookupId: "2680",
    VDNumber: "97590022",
    RegisteredPopulation: "1134",
    VotingStationName: "NG KERKSAAL CITRUSDAL",
    Address: "KERKSTRAAT  CITRUSDAL  CITRUSDAL",
    Latitude: "-32.5904",
    Longitude: "19.0139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22191",
    WardLookupId: "2680",
    VDNumber: "97590033",
    RegisteredPopulation: "1053",
    VotingStationName: "APOSTOLIESE HERLEWING SENTRUM",
    Address: "75 VOORTREKKER  HEUWELSIG  CITRUSDAL",
    Latitude: "-32.5723",
    Longitude: "19.0075",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22192",
    WardLookupId: "2680",
    VDNumber: "97820174",
    RegisteredPopulation: "1368",
    VotingStationName: "UBUNTU CHILD DEVELOPMENT CENTRE",
    Address: "MANDELA ROAD  RIVERVIEW  CITRUSDAL",
    Latitude: "-32.5761",
    Longitude: "19.0056",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22193",
    WardLookupId: "2681",
    VDNumber: "97600013",
    RegisteredPopulation: "3748",
    VotingStationName: "CLANWILLIAM SECONDARY SCHOOL",
    Address: "OU KAAPSEWEG  CLANWILLIAM  CLANWILLIAM",
    Latitude: "-32.1893",
    Longitude: "18.8897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22194",
    WardLookupId: "2681",
    VDNumber: "97600024",
    RegisteredPopulation: "900",
    VotingStationName: "NGK KERKSAAL CLANWILLIAM",
    Address: "LEIPOLDT STREET  CLANWILLIAM  CLANWILLLIAM",
    Latitude: "-32.1794",
    Longitude: "18.8912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22195",
    WardLookupId: "2681",
    VDNumber: "97820039",
    RegisteredPopulation: "414",
    VotingStationName: "ELANDSFONTEIN NGK PRIMÃŠRE SKOOL",
    Address: "ELANDSFONTEIN FARM  ELANDSFONTEN   CLANWILLIAM",
    Latitude: "-32.3104",
    Longitude: "18.8233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22196",
    WardLookupId: "2682",
    VDNumber: "97640017",
    RegisteredPopulation: "2594",
    VotingStationName: "HOÃ‹RSKOOL GRAAFWATER SAAL",
    Address: "VAN DER STEL STREET  GRAAFWATER  GRAAFWATER",
    Latitude: "-32.155",
    Longitude: "18.6048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22197",
    WardLookupId: "2682",
    VDNumber: "97820028",
    RegisteredPopulation: "501",
    VotingStationName: "SANDBERG GEMEENSKAPSAAL",
    Address: "SANDBERG PLAAS  SANDBERG  GRAAFWATER",
    Latitude: "-32.2888",
    Longitude: "18.5604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22198",
    WardLookupId: "2682",
    VDNumber: "97820040",
    RegisteredPopulation: "546",
    VotingStationName: "JP VISSER SAAL",
    Address: "SOMERGROEN FARM  PALEISHEUWEL  CITRUSDAL",
    Latitude: "-32.4745",
    Longitude: "18.7198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22199",
    WardLookupId: "2683",
    VDNumber: "97680011",
    RegisteredPopulation: "1295",
    VotingStationName: "DON BURREL COMMUNITY HALL",
    Address: "POGGENPOEL STREET  LAMBERTSBAY  WESTERN CAPE",
    Latitude: "-32.0966",
    Longitude: "18.3082",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22200",
    WardLookupId: "2683",
    VDNumber: "97680022",
    RegisteredPopulation: "2446",
    VotingStationName: "RECREATION HALL LAMBERTSBAY",
    Address: "MAIN ROAD  LAMBERTS BAY  LAMBERTSBAY",
    Latitude: "-32.0941",
    Longitude: "18.3114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22201",
    WardLookupId: "2683",
    VDNumber: "97680033",
    RegisteredPopulation: "267",
    VotingStationName: "VG KERK LEIPOLDTVILLE",
    Address: "KERK STREET  LEIPOLDTVILLE  LEIPOLDTVILLE",
    Latitude: "-32.2214",
    Longitude: "18.4821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22202",
    WardLookupId: "2683",
    VDNumber: "97730017",
    RegisteredPopulation: "1250",
    VotingStationName: "ELAND BAY COMMUNITY HALL",
    Address: "MAIN ROAD  ELANDS BAY  ELANDS BAY",
    Latitude: "-32.3132",
    Longitude: "18.344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22203",
    WardLookupId: "2684",
    VDNumber: "97820051",
    RegisteredPopulation: "911",
    VotingStationName: "EENDRAGSAAL WUPPERTHAL",
    Address: "TRA TRA STRAAT  WUPPERTHAL  WUPPERTHAL",
    Latitude: "-32.2762",
    Longitude: "19.2162",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22204",
    WardLookupId: "2684",
    VDNumber: "97820062",
    RegisteredPopulation: "646",
    VotingStationName: "AGTERPAKHUIS BOEREVEREENIGING SAAL",
    Address: "ELIZABETHFONTEIN  EIZABETHFONTEIN FARM  CLANWILLIAM",
    Latitude: "-32.0533",
    Longitude: "19.0572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22205",
    WardLookupId: "2684",
    VDNumber: "97820129",
    RegisteredPopulation: "986",
    VotingStationName: "AUGSBURG AGRICULTURAL SCHOOL",
    Address: "GRAAFWATER ROAD  CAMPUS  CLANWILLIAM 8135",
    Latitude: "-32.174",
    Longitude: "18.9017",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22206",
    WardLookupId: "2684",
    VDNumber: "97820141",
    RegisteredPopulation: "170",
    VotingStationName: "ALGERIA GEMEENSKAPSAAL",
    Address: "ALGERIA  CLANWILLIAM",
    Latitude: "-32.3548",
    Longitude: "19.0408",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22207",
    WardLookupId: "4516",
    VDNumber: "97740029",
    RegisteredPopulation: "1350",
    VotingStationName: "PORTERVILLE BIBLIOTEEKSAAL",
    Address: "MARKSTRAAT  PORTERVILLE  PORTERVILLE",
    Latitude: "-33.0124",
    Longitude: "18.9948",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22208",
    WardLookupId: "4516",
    VDNumber: "97840019",
    RegisteredPopulation: "1055",
    VotingStationName: "24-RIVIERE GEMEENSKAPSENTRUM",
    Address: "HALFMANSHOF  PORTERVILLE  PORTERVILLE",
    Latitude: "-33.149",
    Longitude: "18.9613",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22209",
    WardLookupId: "4516",
    VDNumber: "97840165",
    RegisteredPopulation: "369",
    VotingStationName: "NU-HOOP PRIMÃŠRE SKOOL",
    Address: "NU HOOP  PORTERVILLE  PORTERVILLE",
    Latitude: "-32.9146",
    Longitude: "18.9342",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22210",
    WardLookupId: "4516",
    VDNumber: "97840176",
    RegisteredPopulation: "246",
    VotingStationName: "MIDDELDEURSVLEI PRIMÃŠRE SKOOL",
    Address: "MIDDELDEURSVLEI  PIKETBERG  PIKETBERG",
    Latitude: "-32.7944",
    Longitude: "18.9265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22211",
    WardLookupId: "4516",
    VDNumber: "97840187",
    RegisteredPopulation: "219",
    VotingStationName: "BERGHOF PRIMÃŠRE SKOOL",
    Address: "BERGHOF  PORTERVILLE  PORTERVILLE",
    Latitude: "-32.9508",
    Longitude: "19.0474",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22212",
    WardLookupId: "4516",
    VDNumber: "97840198",
    RegisteredPopulation: "277",
    VotingStationName: "VOORBERG MASS HALL",
    Address: "R44 VOORBERG  PORTERVILLE  PORTERVILLE",
    Latitude: "-33.0833",
    Longitude: "19.0003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22213",
    WardLookupId: "4517",
    VDNumber: "97740018",
    RegisteredPopulation: "3156",
    VotingStationName: "MONTE BERTHA GEMEENSKAPSAAL",
    Address: "JAKARANDASTRAAT  MONTE BERTHA  PORTERVILLE",
    Latitude: "-33.0208",
    Longitude: "18.9866",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22214",
    WardLookupId: "4517",
    VDNumber: "97840097",
    RegisteredPopulation: "563",
    VotingStationName: "JAKHALSKLOOF PLAAS",
    Address: "JAKHALSKLOOF  PORTERVILLE  PORTERVILLE",
    Latitude: "-33.029",
    Longitude: "18.9105",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22215",
    WardLookupId: "4518",
    VDNumber: "97730039",
    RegisteredPopulation: "1897",
    VotingStationName: "PIKETBERG LIBRARY HALL",
    Address: "13 KERKSTRAAT  PIKETBERG  PIKETBERG",
    Latitude: "-32.9057",
    Longitude: "18.7537",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22216",
    WardLookupId: "4518",
    VDNumber: "97840042",
    RegisteredPopulation: "1017",
    VotingStationName: "GOEDVERWACHT KUNS & HANDWERKSENTRUM",
    Address: "KERKSTRAAT  GOEDVERWACHT  PIKETBERG",
    Latitude: "-32.8645",
    Longitude: "18.703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22217",
    WardLookupId: "4518",
    VDNumber: "97840064",
    RegisteredPopulation: "858",
    VotingStationName: "WITTEWATER KERKSAAL",
    Address: "3 KERKSTRAAT  WITTEWATER  PIKETBERG",
    Latitude: "-32.9175",
    Longitude: "18.7052",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22218",
    WardLookupId: "4519",
    VDNumber: "97730040",
    RegisteredPopulation: "4161",
    VotingStationName: "PIKETBERG/ALLAN BOESAK COMMUNITY HALL",
    Address: "LOOP STREET  PIKETBERG  PIKETBERG",
    Latitude: "-32.9007",
    Longitude: "18.7596",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22219",
    WardLookupId: "4520",
    VDNumber: "97730028",
    RegisteredPopulation: "1596",
    VotingStationName: "EENDEKUIL COMMUNITY HALL",
    Address: "KLOOF STREET  EENDEKUIL",
    Latitude: "-32.6946",
    Longitude: "18.8794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22220",
    WardLookupId: "4520",
    VDNumber: "97750019",
    RegisteredPopulation: "507",
    VotingStationName: "REDELINGHUYS COMMUNITY HALL",
    Address: "CPR MATHYSLAAN  REDELINGHUYS",
    Latitude: "-32.4773",
    Longitude: "18.5371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22221",
    WardLookupId: "4520",
    VDNumber: "97840053",
    RegisteredPopulation: "759",
    VotingStationName: "STAWELKLIP PRIMÃŠRE SKOOL",
    Address: "STAWELKLIP EAGLES PRIDE  BO-PIKET-BERG  PIKETBERG",
    Latitude: "-32.7843",
    Longitude: "18.7022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22222",
    WardLookupId: "4520",
    VDNumber: "97840110",
    RegisteredPopulation: "393",
    VotingStationName: "GENADENBERG MORAWIESE KERK",
    Address: "VERSFELDPAS  OP-DIE-BERG  PIKETBERG",
    Latitude: "-32.8301",
    Longitude: "18.7287",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22223",
    WardLookupId: "4520",
    VDNumber: "97840121",
    RegisteredPopulation: "348",
    VotingStationName: "KLIPHOEK FARM (OLD SCHOOL BUILDING)",
    Address: "KLIPHOEK  HET KRUIS  PIKETBERG",
    Latitude: "-32.5974",
    Longitude: "18.6858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22224",
    WardLookupId: "4520",
    VDNumber: "97840154",
    RegisteredPopulation: "440",
    VotingStationName: "KAROOKOP PRIMARY SCHOOL",
    Address: "KAROOKOP  HET KRUIS  ",
    Latitude: "-32.6862",
    Longitude: "18.7198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22225",
    WardLookupId: "4521",
    VDNumber: "97570019",
    RegisteredPopulation: "403",
    VotingStationName: "AURORA GEMEENSKAPSAAL",
    Address: "HOOGSTRAAT  AURORA  AURORA",
    Latitude: "-32.7079",
    Longitude: "18.4854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22226",
    WardLookupId: "4521",
    VDNumber: "97780012",
    RegisteredPopulation: "2716",
    VotingStationName: "NOORDHOEK COMMUNITY HALL",
    Address: "LOFDAL ROAD  NOORDHOEK  NOORDHOEK",
    Latitude: "-32.7743",
    Longitude: "18.1664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22227",
    WardLookupId: "4521",
    VDNumber: "97780034",
    RegisteredPopulation: "560",
    VotingStationName: "DWARSKERSBOS CARAVAN PARK",
    Address: "MAIN STREET  DWARSKERSBOS  VELDDRIF",
    Latitude: "-32.6929",
    Longitude: "18.2348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22228",
    WardLookupId: "4521",
    VDNumber: "97840031",
    RegisteredPopulation: "271",
    VotingStationName: "DAGBREEK SHOP",
    Address: "ADAMBOERSKRAAL  VELDDRIF  VELDDRIF",
    Latitude: "-32.8316",
    Longitude: "18.4265",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22229",
    WardLookupId: "4521",
    VDNumber: "97840132",
    RegisteredPopulation: "157",
    VotingStationName: "DRAAIHOEK",
    Address: "R44  VELDDRIF  VELDDRIF",
    Latitude: "-32.6569",
    Longitude: "18.2758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22230",
    WardLookupId: "4522",
    VDNumber: "97780023",
    RegisteredPopulation: "3608",
    VotingStationName: "VELDDRIF MUNICIPAL OFFICE TOWN HALL",
    Address: "VOORTREKKER RD  VELDDRIF  VELDDRIF",
    Latitude: "-32.7844",
    Longitude: "18.1644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22231",
    WardLookupId: "4523",
    VDNumber: "97800082",
    RegisteredPopulation: "1433",
    VotingStationName: "DIAZVILLE HIGH SCHOOL",
    Address: "117 VRAAGOM STREET  SALDANHA  SALDANHA",
    Latitude: "-33.009",
    Longitude: "17.9099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22232",
    WardLookupId: "4523",
    VDNumber: "97800149",
    RegisteredPopulation: "3650",
    VotingStationName: "MIDDELPOS PRIMARY SCHOOL",
    Address: "GOVAN MBEKI STREET  MIDDELPOS  SALDANHA BAY",
    Latitude: "-32.9962",
    Longitude: "17.9186",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22233",
    WardLookupId: "4524",
    VDNumber: "97800161",
    RegisteredPopulation: "2809",
    VotingStationName: "WITTEKLIP DAY CARE",
    Address: "NACKERDIEN AVENUE  WITTEKLIP  VREDENBURG",
    Latitude: "-32.9203",
    Longitude: "18.0106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22234",
    WardLookupId: "4524",
    VDNumber: "97800183",
    RegisteredPopulation: "1932",
    VotingStationName: "WITTEKLIP COMMUNITY HALL",
    Address: "NACKERDIEN STREET  WITTEKLIP  SALDANHA",
    Latitude: "-32.9249",
    Longitude: "18.0098",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22235",
    WardLookupId: "4525",
    VDNumber: "97800105",
    RegisteredPopulation: "2191",
    VotingStationName: "SCHUTER HALL WHITE CITY",
    Address: "BONITO STREET  WHITE CITY  SALDANHA",
    Latitude: "-33.011",
    Longitude: "17.9373",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22236",
    WardLookupId: "4525",
    VDNumber: "97800194",
    RegisteredPopulation: "1202",
    VotingStationName: "BERSÃ‰BA CHURCH WHITE CITY",
    Address: "DOLFYN STREET  WHITE CITY  SALDANHA BAY",
    Latitude: "-33.0129",
    Longitude: "17.9388",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22237",
    WardLookupId: "4526",
    VDNumber: "97800093",
    RegisteredPopulation: "1972",
    VotingStationName: "DIAZVILLE PRIMARY SCHOOL",
    Address: "GALJOEN STREET  DIAZVILLE  SALDANHA",
    Latitude: "-33.0064",
    Longitude: "17.9185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22238",
    WardLookupId: "4526",
    VDNumber: "97800150",
    RegisteredPopulation: "2024",
    VotingStationName: "DIAZVILLE COMMUNITY HALL",
    Address: "GOOD HOPE STREET  DIAZVILLE  SALDANHA",
    Latitude: "-33.0087",
    Longitude: "17.9226",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22239",
    WardLookupId: "4527",
    VDNumber: "97800015",
    RegisteredPopulation: "3074",
    VotingStationName: "SALDANHA RECREATION HALL",
    Address: "ALBATROS STREET  SALDANHA  SALDANHA",
    Latitude: "-32.9981",
    Longitude: "17.9571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22240",
    WardLookupId: "4527",
    VDNumber: "97800240",
    RegisteredPopulation: "472",
    VotingStationName: "TOGRYERSVLEI HALL JACOBSBAAI",
    Address: "TOGRYERSVLEI  JACOBSBAAI  SALDANHA",
    Latitude: "-32.9529",
    Longitude: "17.9255",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22241",
    WardLookupId: "4528",
    VDNumber: "97690012",
    RegisteredPopulation: "4558",
    VotingStationName: "LANGEBAAN TOWN HALL",
    Address: "BREE STREET  LANGEBAAN  LANGEBAAN",
    Latitude: "-33.0909",
    Longitude: "18.0328",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22242",
    WardLookupId: "4529",
    VDNumber: "97650018",
    RegisteredPopulation: "3871",
    VotingStationName: "HOPEFIELD COMMUNITY HALL",
    Address: "SCHOOL STREET  HOPEFIELD  HOPEFIELD",
    Latitude: "-33.0652",
    Longitude: "18.3344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22243",
    WardLookupId: "4529",
    VDNumber: "97830063",
    RegisteredPopulation: "366",
    VotingStationName: "KOPERFONTEIN COMMUNITY HALL",
    Address: "12 GEDEELTE  KOPERFONTEIN  KOPERFONTEIN",
    Latitude: "-33.0999",
    Longitude: "18.4167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22244",
    WardLookupId: "4530",
    VDNumber: "97800037",
    RegisteredPopulation: "3073",
    VotingStationName: "VREDENBURG RECREATION HALL",
    Address: "ESPERIA STR  ESPERIA  VREDENBURG",
    Latitude: "-32.9005",
    Longitude: "17.9895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22245",
    WardLookupId: "4530",
    VDNumber: "97830029",
    RegisteredPopulation: "958",
    VotingStationName: "AFB LANGEBAANWEG",
    Address: "LANGEBAANWEG  HOPEFIELD  HOPEFIELD",
    Latitude: "-32.9707",
    Longitude: "18.1463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22246",
    WardLookupId: "4531",
    VDNumber: "97800127",
    RegisteredPopulation: "2159",
    VotingStationName: "ONGEGUND COMMUNITY HALL",
    Address: "GOUD STREET  ONGEGUND  VREDENBURG",
    Latitude: "-32.9151",
    Longitude: "18.0221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22247",
    WardLookupId: "4531",
    VDNumber: "97800206",
    RegisteredPopulation: "1917",
    VotingStationName: "GEORGE KERRIDGE HALL ONGEGUND",
    Address: "BITUMEN STREET  ONGEGUND  VREDENBURG",
    Latitude: "-32.9193",
    Longitude: "18.0235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22248",
    WardLookupId: "4531",
    VDNumber: "97800217",
    RegisteredPopulation: "1478",
    VotingStationName: "WITTEKLIP METHODIST CHURCH",
    Address: "SAARTJIE BAARTMAN STREET  ONGEGUND  VREDENBURG",
    Latitude: "-32.9189",
    Longitude: "18.021",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22249",
    WardLookupId: "4532",
    VDNumber: "97800048",
    RegisteredPopulation: "2388",
    VotingStationName: "WEST COAST COLLEGE LOUWVILLE",
    Address: "01 ACADEMY STREET  LOUWVILLE  VREDENBURG",
    Latitude: "-32.9138",
    Longitude: "17.9959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22250",
    WardLookupId: "4532",
    VDNumber: "97800059",
    RegisteredPopulation: "2307",
    VotingStationName: "METHODIST CHURCH HALL VREDENBURG",
    Address: "HOSPITAL STREET  VREDENBURG  VREDENBURG",
    Latitude: "-32.9116",
    Longitude: "17.9919",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22251",
    WardLookupId: "4533",
    VDNumber: "97800060",
    RegisteredPopulation: "2429",
    VotingStationName: "ST HELENA BAY RECREATION HALL",
    Address: "ALBATROS STR  ST HELENA BAY  ST HELENA BAY",
    Latitude: "-32.7443",
    Longitude: "18.0058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22252",
    WardLookupId: "4533",
    VDNumber: "97800116",
    RegisteredPopulation: "1428",
    VotingStationName: "PATERNOSTER COMMUNITY HALL",
    Address: "MADELIEFIE STREET  PATERNOSTER  PATERNOSTER",
    Latitude: "-32.81",
    Longitude: "17.8952",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22253",
    WardLookupId: "4534",
    VDNumber: "97800071",
    RegisteredPopulation: "1876",
    VotingStationName: "LAINGVILLE COMMUNITY CENTRE",
    Address: "STRAND STR  LAINGVILLE  ST HELENA BAY",
    Latitude: "-32.7873",
    Longitude: "18.0599",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22254",
    WardLookupId: "4534",
    VDNumber: "97800228",
    RegisteredPopulation: "1534",
    VotingStationName: "THEMBALETU METHODIST CHURCH LAINGVILLE",
    Address: "TONTELBLOM  LAINGVILLE  ST HELENA BAY",
    Latitude: "-32.7946",
    Longitude: "18.0683",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22255",
    WardLookupId: "4534",
    VDNumber: "97800239",
    RegisteredPopulation: "1300",
    VotingStationName: "LAINGVILLE SPORTS GROUND",
    Address: "MAIN ROAD  LAINGVILLE  SALDANHA BAY",
    Latitude: "-32.7899",
    Longitude: "18.0568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22256",
    WardLookupId: "4535",
    VDNumber: "97800026",
    RegisteredPopulation: "1734",
    VotingStationName: "WESTON HIGH SCHOOL",
    Address: "KOOTJIESKLOOF STREET  LOUWVILLE  VREDENBURG",
    Latitude: "-32.9138",
    Longitude: "18.0048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22257",
    WardLookupId: "4535",
    VDNumber: "97800138",
    RegisteredPopulation: "2123",
    VotingStationName: "LOUWVILLE COMMUNITY HALL",
    Address: "PIET-MY-VROU STREET  LOUWVILLE  VREDENBURG",
    Latitude: "-32.9173",
    Longitude: "18.0004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22258",
    WardLookupId: "4536",
    VDNumber: "97690023",
    RegisteredPopulation: "3335",
    VotingStationName: "SEABREEZE HALL LANGEBAAN",
    Address: "SEABREEZE  LANGEBAAN  LANGEBAAN",
    Latitude: "-33.0748",
    Longitude: "18.0382",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22259",
    WardLookupId: "2685",
    VDNumber: "97670010",
    RegisteredPopulation: "790",
    VotingStationName: "UNITING REFORMED CHURCH HALL KORINGBERG",
    Address: "MAIN ROAD  KORINGBERG  KORINGBERG",
    Latitude: "-33.0129",
    Longitude: "18.6728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22260",
    WardLookupId: "2685",
    VDNumber: "97720016",
    RegisteredPopulation: "2038",
    VotingStationName: "MOORREESBURG TOWN HALL",
    Address: "CHURCH STREET  MOORREESBURG",
    Latitude: "-33.1531",
    Longitude: "18.6621",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22261",
    WardLookupId: "2685",
    VDNumber: "97830074",
    RegisteredPopulation: "407",
    VotingStationName: "BROODKRAAL COMMUNITY HALL KORINGBERG",
    Address: "KORINGBERG  BROODKRAAL FARM  KORINGBERG",
    Latitude: "-32.9446",
    Longitude: "18.6854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22262",
    WardLookupId: "2685",
    VDNumber: "97830085",
    RegisteredPopulation: "222",
    VotingStationName: "WITHOOGTE FARM RECREATION HALL",
    Address: "WITHOOGTE FARM  MOORREESBURG",
    Latitude: "-33.0703",
    Longitude: "18.67",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22263",
    WardLookupId: "2685",
    VDNumber: "97830096",
    RegisteredPopulation: "321",
    VotingStationName: "MIDDEL SWARTLAND BOEREVERENIGING SAAL",
    Address: "RUSTASIE FARM  MOORREESBURG",
    Latitude: "-33.2795",
    Longitude: "18.6481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22264",
    WardLookupId: "2686",
    VDNumber: "97720027",
    RegisteredPopulation: "990",
    VotingStationName: "KORINGBEDRYF MUSEUM MOORREESBURG",
    Address: "HOOFWEG  MOORREESBURG  MOORREESBURG",
    Latitude: "-33.1491",
    Longitude: "18.6658",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22265",
    WardLookupId: "2686",
    VDNumber: "97720038",
    RegisteredPopulation: "3378",
    VotingStationName: "MOORREESBURG COMMUNITY HALL",
    Address: "SENTRUM ROAD  MOORREESBURG  MOORREESBURG",
    Latitude: "-33.147",
    Longitude: "18.6703",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22266",
    WardLookupId: "2687",
    VDNumber: "97710082",
    RegisteredPopulation: "2225",
    VotingStationName: "RIEBEEK WEST TOWN HALL",
    Address: "VOORTREKKER ROAD  RIEBEEK WEST",
    Latitude: "-33.3509",
    Longitude: "18.8697",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22267",
    WardLookupId: "2687",
    VDNumber: "97830052",
    RegisteredPopulation: "276",
    VotingStationName: "WELTEVREDE PRIMARY SCHOOL",
    Address: "WELTEVREDE FARM  RIEBEEK WEST",
    Latitude: "-33.3242",
    Longitude: "18.9026",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22268",
    WardLookupId: "2687",
    VDNumber: "97830131",
    RegisteredPopulation: "316",
    VotingStationName: "KLEIN KARNMELKSVLEI PRIMARY SCHOOL",
    Address: "KLEINKARNMELKSVLEI FARM  MOORREESBURG",
    Latitude: "-33.136",
    Longitude: "18.8043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22269",
    WardLookupId: "2687",
    VDNumber: "97830142",
    RegisteredPopulation: "272",
    VotingStationName: "GROOTVLEI PRIMARY SCHOOL",
    Address: "GROOTVLEI  RIEBEEK WEST  RIEBEEK WEST",
    Latitude: "-33.2339",
    Longitude: "18.7884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22270",
    WardLookupId: "2687",
    VDNumber: "97830153",
    RegisteredPopulation: "226",
    VotingStationName: "LANGGEWENS EXPERIMENTAL FARM HALL",
    Address: "LANGGEWENS FARM  MOORREESBURG",
    Latitude: "-33.2761",
    Longitude: "18.7041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22271",
    WardLookupId: "2687",
    VDNumber: "97830210",
    RegisteredPopulation: "329",
    VotingStationName: "PPC RECREATION HALL RIEBEEK WEST",
    Address: "ONGEGUND FARM  RIEBEEK WEST",
    Latitude: "-33.3171",
    Longitude: "18.8496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22272",
    WardLookupId: "2688",
    VDNumber: "97710071",
    RegisteredPopulation: "762",
    VotingStationName: "RIVERLANDS PRIMARY SCHOOL",
    Address: "RIVERLANDS AVENUE  MALMESBURY",
    Latitude: "-33.5251",
    Longitude: "18.5959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22273",
    WardLookupId: "2688",
    VDNumber: "97710138",
    RegisteredPopulation: "1523",
    VotingStationName: "AME CHURCH CHATSWORTH",
    Address: "CEMETERY RD  CHATSWORTH  SWARTLAND [MALMESBURY]",
    Latitude: "-33.5441",
    Longitude: "18.5863",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22274",
    WardLookupId: "2688",
    VDNumber: "97830041",
    RegisteredPopulation: "355",
    VotingStationName: "RIVERLANDS NATURE RESERVE HALL",
    Address: "TIERFONTEIN ROAD  EDENHOF  RIVERLANDS",
    Latitude: "-33.4901",
    Longitude: "18.6151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22275",
    WardLookupId: "2688",
    VDNumber: "97830108",
    RegisteredPopulation: "709",
    VotingStationName: "GOEDEHOOP NGK SENDINGSKOOL",
    Address: "R45, 5.3 KM(NE) MIDDELPOS FARM  MIDDELPOS FARM  MALMESBURY",
    Latitude: "-33.3435",
    Longitude: "18.5785",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22276",
    WardLookupId: "2688",
    VDNumber: "97830186",
    RegisteredPopulation: "285",
    VotingStationName: "BLOEMENDAL PRIMARY SCHOOL",
    Address: "BLOEMENDAL FARM  MALMESBURY",
    Latitude: "-33.4423",
    Longitude: "18.6217",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22277",
    WardLookupId: "2689",
    VDNumber: "97610025",
    RegisteredPopulation: "1887",
    VotingStationName: "DARLING METHODIST CHURCH",
    Address: "3 GREY  DARLING NORTH  DARLING",
    Latitude: "-33.3678",
    Longitude: "18.3869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22278",
    WardLookupId: "2689",
    VDNumber: "97810016",
    RegisteredPopulation: "1575",
    VotingStationName: "YZERFONTEIN COMMUNITY HALL",
    Address: "DOLFYN  STR  YZERFONTEIN",
    Latitude: "-33.3407",
    Longitude: "18.1635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22279",
    WardLookupId: "2689",
    VDNumber: "97830030",
    RegisteredPopulation: "710",
    VotingStationName: "!KHWA TTU GALLERY",
    Address: "!KHWA TTU SAN CENTRE  GROOTWATER FARM, R27  YZERFONTEIN",
    Latitude: "-33.3619",
    Longitude: "18.2715",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22280",
    WardLookupId: "2690",
    VDNumber: "97610014",
    RegisteredPopulation: "1830",
    VotingStationName: "UNITING REFORMED CHURCH DARLING",
    Address: "SOMERSET STREET  DARLING NORTH  DARLING",
    Latitude: "-33.3721",
    Longitude: "18.3831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22281",
    WardLookupId: "2690",
    VDNumber: "97610036",
    RegisteredPopulation: "1772",
    VotingStationName: "DARLING OUTREACH FOUNDATION (DIE NESSIE)",
    Address: "DENNEBOOM STREET  DARLING  DARLING",
    Latitude: "-33.3663",
    Longitude: "18.3914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22282",
    WardLookupId: "2690",
    VDNumber: "97830120",
    RegisteredPopulation: "385",
    VotingStationName: "KORHAANSRUG PRIMARY SCHOOL",
    Address: "KORHAANSRUG FARM  DARLING  DARLING",
    Latitude: "-33.3278",
    Longitude: "18.4235",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22283",
    WardLookupId: "2691",
    VDNumber: "97710060",
    RegisteredPopulation: "1280",
    VotingStationName: "OJ ERASMUS PRIMARY SCHOOL KALBASKRAAL",
    Address: "FRANS AVENUE  KALBASKRAAL",
    Latitude: "-33.5776",
    Longitude: "18.651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22284",
    WardLookupId: "2691",
    VDNumber: "97710116",
    RegisteredPopulation: "1991",
    VotingStationName: "ABBOTSDALE COMMUNITY HALL",
    Address: "DARLING ROAD  ABBOTSDALE",
    Latitude: "-33.4899",
    Longitude: "18.6768",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22285",
    WardLookupId: "2691",
    VDNumber: "97710149",
    RegisteredPopulation: "518",
    VotingStationName: "MESS BUILDING",
    Address: "ROZENBURG STREET  MALMESBURY  MALMESBURY",
    Latitude: "-33.4736",
    Longitude: "18.7295",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22286",
    WardLookupId: "2691",
    VDNumber: "97830164",
    RegisteredPopulation: "386",
    VotingStationName: "WELGEMEEND COMMUNITY HALL",
    Address: "JOUBERTSKLOOF ROAD  WELGEMEEND FARM  MALMESBURY",
    Latitude: "-33.539",
    Longitude: "18.7681",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22287",
    WardLookupId: "2691",
    VDNumber: "98430217",
    RegisteredPopulation: "298",
    VotingStationName: "MÃ”REWAG FARM",
    Address: "MORE WAG FARM  MALMESBURY  MALMESBURY",
    Latitude: "-33.592",
    Longitude: "18.7285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22288",
    WardLookupId: "2692",
    VDNumber: "97710026",
    RegisteredPopulation: "2260",
    VotingStationName: "MALMESBURY TOWN HALL",
    Address: "CHURCH STREET  MALMESBURY",
    Latitude: "-33.4635",
    Longitude: "18.73",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22289",
    WardLookupId: "2692",
    VDNumber: "97710059",
    RegisteredPopulation: "1534",
    VotingStationName: "WESTBANK SECONDARY SCHOOL MALMESBURY",
    Address: "FRESIA STREET  WESTBANK  MALMESBURY",
    Latitude: "-33.4657",
    Longitude: "18.7097",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22290",
    WardLookupId: "2693",
    VDNumber: "97710048",
    RegisteredPopulation: "3558",
    VotingStationName: "THUSONG CENTRE ILINGELETHU",
    Address: "JAKARANDA  ILINGELETHU  MALMESBURY",
    Latitude: "-33.4646",
    Longitude: "18.7008",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22291",
    WardLookupId: "2693",
    VDNumber: "97710150",
    RegisteredPopulation: "1520",
    VotingStationName: "ILINGELETHU SECONDARY SCHOOL",
    Address: "HOLOMISA  ILINGELETHU  MALMESBURY",
    Latitude: "-33.469",
    Longitude: "18.6961",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22292",
    WardLookupId: "2694",
    VDNumber: "97710015",
    RegisteredPopulation: "2688",
    VotingStationName: "DUTCH REFORMED CHURCH HALL SWARTLAND NORTH",
    Address: "40 BERGZICHT STREET  MALMESBURY",
    Latitude: "-33.4556",
    Longitude: "18.7253",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22293",
    WardLookupId: "2694",
    VDNumber: "97710037",
    RegisteredPopulation: "1888",
    VotingStationName: "SCHOONSPRUIT HIGH SCHOOL MALMESBURY",
    Address: "DARLING ROAD  MALMESBURY",
    Latitude: "-33.4594",
    Longitude: "18.7085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22294",
    WardLookupId: "2695",
    VDNumber: "97710105",
    RegisteredPopulation: "1290",
    VotingStationName: "WESTBANK COMMUNITY HALL",
    Address: "JASMYN STREET  WESTBANK  MALMESBURY",
    Latitude: "-33.4624",
    Longitude: "18.7073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22295",
    WardLookupId: "2695",
    VDNumber: "97710127",
    RegisteredPopulation: "3174",
    VotingStationName: "ALPHA STREET, SAAMSTAAN (TENT)",
    Address: "ALPHA  MALMESBURY  MALMESBURY",
    Latitude: "-33.4718",
    Longitude: "18.7",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22296",
    WardLookupId: "2696",
    VDNumber: "97710093",
    RegisteredPopulation: "2868",
    VotingStationName: "ESTERHOF CUM BEWAARSKOOL",
    Address: "1 ANGELIER STREET  MALMESBURY  RIEBEECK KASTEEL",
    Latitude: "-33.3866",
    Longitude: "18.9209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22297",
    WardLookupId: "2696",
    VDNumber: "97830018",
    RegisteredPopulation: "454",
    VotingStationName: "GOEDGEDACHT YOUTH CENTRE",
    Address: "GOEDGEDACHT  GOEDGEDACHT OLIVE FARM  MALMESBURY",
    Latitude: "-33.3862",
    Longitude: "18.8372",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22298",
    WardLookupId: "2696",
    VDNumber: "97830209",
    RegisteredPopulation: "725",
    VotingStationName: "ANNE PIENAAR PRIMARY SCHOOL JAKKALSFONTEIN",
    Address: "JAKKALSFONTEIN FARM  MALMESBURY",
    Latitude: "-33.5362",
    Longitude: "18.8183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22299",
    WardLookupId: "2697",
    VDNumber: "97410089",
    RegisteredPopulation: "2701",
    VotingStationName: "POLOCROSS HALL",
    Address: "ELUXOLWENI AVENUE  NDULI  CERES",
    Latitude: "-33.3554",
    Longitude: "19.3456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22300",
    WardLookupId: "2697",
    VDNumber: "97410090",
    RegisteredPopulation: "987",
    VotingStationName: "NDULI PRIMARY SCHOOL",
    Address: "CHRIS HANI DRIVE  NDUL  CERES",
    Latitude: "-33.3566",
    Longitude: "19.3423",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22301",
    WardLookupId: "2698",
    VDNumber: "97510013",
    RegisteredPopulation: "1023",
    VotingStationName: "W F LOOTS PRIMARY SCHOOL",
    Address: "FOURTH AVENUE  WOLSELEY  WOLSELEY",
    Latitude: "-33.4252",
    Longitude: "19.204",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22302",
    WardLookupId: "2698",
    VDNumber: "97530015",
    RegisteredPopulation: "817",
    VotingStationName: "PETRA GEDENK SCHOOL",
    Address: "WABOOM ROAD  BREERIVIER  BREERIVIER",
    Latitude: "-33.5284",
    Longitude: "19.2063",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22303",
    WardLookupId: "2698",
    VDNumber: "97530037",
    RegisteredPopulation: "864",
    VotingStationName: "BOTHASHALT SCHOOL",
    Address: "BOESMANSVLEI RD  BOTHASHALT  WOLSELEY",
    Latitude: "-33.5583",
    Longitude: "19.2646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22304",
    WardLookupId: "2698",
    VDNumber: "97540072",
    RegisteredPopulation: "933",
    VotingStationName: "LA PLAISANTE TRAINING CENTRE",
    Address: "LA PLAISANTE  WOLSELEY",
    Latitude: "-33.457",
    Longitude: "19.2068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22305",
    WardLookupId: "2699",
    VDNumber: "97410023",
    RegisteredPopulation: "1446",
    VotingStationName: "CERES PRIMARY SCHOOL",
    Address: "LYELL STREET  CERES  CERES",
    Latitude: "-33.3761",
    Longitude: "19.3113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22306",
    WardLookupId: "2699",
    VDNumber: "97410067",
    RegisteredPopulation: "1411",
    VotingStationName: "CERES STADSAAL",
    Address: "46 VOORTREKKER STREET  CERES",
    Latitude: "-33.3714",
    Longitude: "19.3071",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22307",
    WardLookupId: "2699",
    VDNumber: "97540207",
    RegisteredPopulation: "814",
    VotingStationName: "FAIRFIELD SCHOOL",
    Address: "HAMLET ROAD  CERES  CERES",
    Latitude: "-33.3202",
    Longitude: "19.3118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22308",
    WardLookupId: "2700",
    VDNumber: "97410045",
    RegisteredPopulation: "1453",
    VotingStationName: "KOINONIA HALL BELLA VISTA",
    Address: "BUITEN STREET  BELLA VISTA  CERES",
    Latitude: "-33.3243",
    Longitude: "19.3184",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22309",
    WardLookupId: "2700",
    VDNumber: "97450016",
    RegisteredPopulation: "2078",
    VotingStationName: "HAMLET TOWN HALL",
    Address:
      "C/O VOORTREKKER & VAN RIEBEECK STR  PRINCE ALFRED/HAMLET  PRINCE ALFRED HAMLET",
    Latitude: "-33.2922",
    Longitude: "19.327",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22310",
    WardLookupId: "2700",
    VDNumber: "97540252",
    RegisteredPopulation: "445",
    VotingStationName: "CERES AARTAPPELS KOOP ONTSPANNINGSAAL",
    Address: "HAMLET ROAD  P.A. HAMLET  P.A. HAMLET",
    Latitude: "-33.3044",
    Longitude: "19.3227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22311",
    WardLookupId: "2701",
    VDNumber: "97410078",
    RegisteredPopulation: "2240",
    VotingStationName: "MORRISDALE PRIMARY SCHOOL",
    Address: "21 VOS STREET   CERES  CERES",
    Latitude: "-33.3704",
    Longitude: "19.312",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22312",
    WardLookupId: "2701",
    VDNumber: "97540184",
    RegisteredPopulation: "1042",
    VotingStationName: "ACHTERTUIN PRIMÃŠRE SKOOL",
    Address: "PLAAS BUCHULAND  CERES",
    Latitude: "-33.3773",
    Longitude: "19.3517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22313",
    WardLookupId: "2702",
    VDNumber: "97410012",
    RegisteredPopulation: "3083",
    VotingStationName: "BELLA VISTA COMMUNITY HALL",
    Address: "9 KRISANTE STREET  BELLA VISTA  CERES",
    Latitude: "-33.3328",
    Longitude: "19.3198",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22314",
    WardLookupId: "2702",
    VDNumber: "97410034",
    RegisteredPopulation: "1094",
    VotingStationName: "BELLA VISTA URC HALL",
    Address: "GRENS STREET  BELLA VISTA  CERES",
    Latitude: "-33.3301",
    Longitude: "19.3173",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22315",
    WardLookupId: "2703",
    VDNumber: "97500023",
    RegisteredPopulation: "1081",
    VotingStationName: "TULBAGH COMMUNITY HALL",
    Address: "KAREE STR  WITZENVILLE  TULBAGH",
    Latitude: "-33.2846",
    Longitude: "19.1505",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22316",
    WardLookupId: "2703",
    VDNumber: "97510024",
    RegisteredPopulation: "2203",
    VotingStationName: "PINE VALLEY COMMUNITY HALL",
    Address: "AFRIKA STREET  WOLSELEY  CERES",
    Latitude: "-33.4172",
    Longitude: "19.1899",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22317",
    WardLookupId: "2703",
    VDNumber: "97510035",
    RegisteredPopulation: "1679",
    VotingStationName: "WOLSELEY SECONDARY SCHOOL",
    Address: "DEBORAH  MONTANA  WITZENBERG",
    Latitude: "-33.4159",
    Longitude: "19.2073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22318",
    WardLookupId: "2703",
    VDNumber: "97510046",
    RegisteredPopulation: "737",
    VotingStationName: "WOLSELEY - PRIMARY SCHOOL",
    Address: "MILL STREET  WOLSELEY  WOLSELEY",
    Latitude: "-33.4102",
    Longitude: "19.2006",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22319",
    WardLookupId: "2704",
    VDNumber: "97540050",
    RegisteredPopulation: "642",
    VotingStationName: "MORESTER SPORT CLUB",
    Address: "MORESTER FARM  KOUE BOKKEVELD  CERES",
    Latitude: "-32.9732",
    Longitude: "19.4046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22320",
    WardLookupId: "2704",
    VDNumber: "97540106",
    RegisteredPopulation: "1248",
    VotingStationName: "OP DIE BERG VGK SAAL",
    Address: "1 KERKSTRAAT  OP-DIE-BERG  KOUE BOKKEVELD",
    Latitude: "-33.0238",
    Longitude: "19.3106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22321",
    WardLookupId: "2704",
    VDNumber: "97540140",
    RegisteredPopulation: "280",
    VotingStationName: "DRIEFONTEIN VGK PRIMÃŠRE SKOOL",
    Address: "DRIEFONTEIN FARM  DROÃ‹HOEK  CERES",
    Latitude: "-33.1777",
    Longitude: "19.4202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22322",
    WardLookupId: "2704",
    VDNumber: "97540151",
    RegisteredPopulation: "700",
    VotingStationName: "BRONAAR COMMUNITY CENTRE",
    Address: "FARM BRONAAR  POSBUS 4  KOUE BOKKEVELD",
    Latitude: "-32.9902",
    Longitude: "19.3692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22323",
    WardLookupId: "2704",
    VDNumber: "97540218",
    RegisteredPopulation: "1073",
    VotingStationName: "ROCKLANDS RECREATIONAL HALL",
    Address: "ROCKLANDS  KOUE BOKKEVELD  KOUE BOKKEVELD",
    Latitude: "-33.0974",
    Longitude: "19.3241",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22324",
    WardLookupId: "2705",
    VDNumber: "97540061",
    RegisteredPopulation: "746",
    VotingStationName: "TANDFONTEIN BEWAARSKOOL SAAL",
    Address: "R303  TANDFONTEIN FARM  KOUE BOKKEVELD",
    Latitude: "-32.7679",
    Longitude: "19.2504",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22325",
    WardLookupId: "2705",
    VDNumber: "97540117",
    RegisteredPopulation: "1269",
    VotingStationName: "WYDEKLOOF GEMEENSKAPSAAL",
    Address: "R303  WYDEKLOOF FARM  KOUE BOKKEVELD",
    Latitude: "-32.8622",
    Longitude: "19.2641",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22326",
    WardLookupId: "2705",
    VDNumber: "97540162",
    RegisteredPopulation: "1097",
    VotingStationName: "KROMFONTEIN SAAL",
    Address: "R303  KROMFONTEIN FARM  KOUE BOKKEVELD",
    Latitude: "-32.9553",
    Longitude: "19.2498",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22327",
    WardLookupId: "2705",
    VDNumber: "97540263",
    RegisteredPopulation: "700",
    VotingStationName: "VOORSORG PRIMARY SCHOOL",
    Address: "R303  VOORSORG FARM  KOUE BOKKEVELD",
    Latitude: "-32.9031",
    Longitude: "19.2962",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22328",
    WardLookupId: "2706",
    VDNumber: "97540173",
    RegisteredPopulation: "800",
    VotingStationName: "AGTER-WITZENBERG SCHOOL",
    Address: "DIE EIKE ROAD  WITZENBERG VALLEY  CERES",
    Latitude: "-33.2313",
    Longitude: "19.2466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22329",
    WardLookupId: "2706",
    VDNumber: "97540241",
    RegisteredPopulation: "651",
    VotingStationName: "DIE EIKE COMMUNITY HALL",
    Address: "DIE EIKE  P.A. HAMLET",
    Latitude: "-33.2085",
    Longitude: "19.2239",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22330",
    WardLookupId: "2706",
    VDNumber: "97540274",
    RegisteredPopulation: "783",
    VotingStationName: "PAARDEKLOOF SCHOOL",
    Address: "PAARDEKLOOF RD  WITZENBERG VALLEY  P.A. HAMLET",
    Latitude: "-33.2649",
    Longitude: "19.2619",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22331",
    WardLookupId: "2706",
    VDNumber: "97540285",
    RegisteredPopulation: "436",
    VotingStationName: "KOELFONTEIN PRIMARY SCHOOL",
    Address: "R303, 1KM (W)  KOELFONTEIN P.A. HAMLET  WITZENBERG",
    Latitude: "-33.2593",
    Longitude: "19.3368",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22332",
    WardLookupId: "2706",
    VDNumber: "97540296",
    RegisteredPopulation: "1383",
    VotingStationName: "OPPIE KOPPIE CRECHE",
    Address: "WABOOM AVENUE  PHASE 4  P.A. HAMLET",
    Latitude: "-33.2841",
    Longitude: "19.3081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22333",
    WardLookupId: "2707",
    VDNumber: "97500012",
    RegisteredPopulation: "1071",
    VotingStationName: "TULBAGH TOWN HALL",
    Address: "VAN DER STEL STR  TULBAGH  TULBAGH",
    Latitude: "-33.2791",
    Longitude: "19.1403",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22334",
    WardLookupId: "2707",
    VDNumber: "97500045",
    RegisteredPopulation: "2285",
    VotingStationName: "TULBAGH WERKESTOOR",
    Address: "STEINTHAL ROAD  WITZENVILLE  TULBAGH",
    Latitude: "-33.2854",
    Longitude: "19.1471",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22335",
    WardLookupId: "2707",
    VDNumber: "97540049",
    RegisteredPopulation: "265",
    VotingStationName: "TWEE JONGE GEZELLEN SCHOOL",
    Address: "TWEE JONGE GEZELLEN  TULBAGH  TULBAGH",
    Latitude: "-33.2345",
    Longitude: "19.1149",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22336",
    WardLookupId: "2707",
    VDNumber: "97540083",
    RegisteredPopulation: "725",
    VotingStationName: "DE AGEN CRECHE",
    Address: "NO STREET DETAILS  WINTERHOEK  TULBAGH",
    Latitude: "-33.2393",
    Longitude: "19.1439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22337",
    WardLookupId: "2708",
    VDNumber: "97410056",
    RegisteredPopulation: "1429",
    VotingStationName: "NDULI COMMUNITY HALL",
    Address: "7 KHARASI AVENUE  NDULI  CERES",
    Latitude: "-33.3543",
    Longitude: "19.3429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22338",
    WardLookupId: "2708",
    VDNumber: "97540027",
    RegisteredPopulation: "1069",
    VotingStationName: "BO-SWAARMOED GEMEENSKAPSAAL",
    Address: "BO SWAARMOED ROAD  WARM BOKKEVELD  CERES",
    Latitude: "-33.2659",
    Longitude: "19.6496",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22339",
    WardLookupId: "2708",
    VDNumber: "97540038",
    RegisteredPopulation: "149",
    VotingStationName: "TANKWA GEMEENSKAP SENTRUM",
    Address: "CALVINIA ROAD  WARM BOKKEVELD  CERES",
    Latitude: "-32.8704",
    Longitude: "19.7759",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22340",
    WardLookupId: "2708",
    VDNumber: "97540094",
    RegisteredPopulation: "521",
    VotingStationName: "WANGANELLA SCHOOL",
    Address: "ONDER-SWAARMOED ROAD  WANGANELLA FARM  CERES",
    Latitude: "-33.363",
    Longitude: "19.4604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22341",
    WardLookupId: "2708",
    VDNumber: "97540195",
    RegisteredPopulation: "69",
    VotingStationName: "DE BOSCH FARM STORE",
    Address: "DE BOSCH FARM  WARM BOKKEVELD  CERES",
    Latitude: "-32.5648",
    Longitude: "19.8137",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22342",
    WardLookupId: "4537",
    VDNumber: "98430071",
    RegisteredPopulation: "1420",
    VotingStationName: "SIMONDIUM PRIMARY SCHOOL",
    Address: "R45 MAIN ROAD  SIMONDIUM  PAARL",
    Latitude: "-33.8416",
    Longitude: "18.9588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22343",
    WardLookupId: "4537",
    VDNumber: "98430105",
    RegisteredPopulation: "1150",
    VotingStationName: "BERG-EN-DAL PRIMARY SCHOOL",
    Address: "SUID AGTER PAARL ROAD  SUID AGTER PAARL  PAARL",
    Latitude: "-33.7701",
    Longitude: "18.9189",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22344",
    WardLookupId: "4537",
    VDNumber: "98430206",
    RegisteredPopulation: "1549",
    VotingStationName: "ST SIMONS CHURCH HALL",
    Address: "R45 MAIN ROAD  GROOT DRAKENSTEIN  SIMONDIUM",
    Latitude: "-33.8302",
    Longitude: "18.9531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22345",
    WardLookupId: "4538",
    VDNumber: "98410013",
    RegisteredPopulation: "2439",
    VotingStationName: "VOORTREKKER HALL",
    Address: "CHURCH STREET  BERG EN DAL  WELLINGTON",
    Latitude: "-33.6383",
    Longitude: "19.0202",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22346",
    WardLookupId: "4538",
    VDNumber: "98410024",
    RegisteredPopulation: "1644",
    VotingStationName: "HUGENOTE LAERSKOOL",
    Address: "GENL HERTZOG BLVRD  RANZADALE  WELLINGTON",
    Latitude: "-33.6465",
    Longitude: "19.0135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22347",
    WardLookupId: "4539",
    VDNumber: "98380053",
    RegisteredPopulation: "1326",
    VotingStationName: "GIMNASIUM HIGH SCHOOL",
    Address: "416 MAIN STREET  COURTRAI, NORTHERN PAARL  PAARL",
    Latitude: "-33.72",
    Longitude: "18.9583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22348",
    WardLookupId: "4539",
    VDNumber: "98430037",
    RegisteredPopulation: "1083",
    VotingStationName: "ALFONS PRIMARY SCHOOL",
    Address: "VRYGUNS PAD  WINDMEUL  WINDMEUL",
    Latitude: "-33.6539",
    Longitude: "18.8801",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22349",
    WardLookupId: "4539",
    VDNumber: "98430048",
    RegisteredPopulation: "1253",
    VotingStationName: "LAERSKOOL SLOT VAN DIE PAARL",
    Address: "MAIN STREET  WINDMEUL  PAARL",
    Latitude: "-33.6716",
    Longitude: "18.914",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22350",
    WardLookupId: "4539",
    VDNumber: "98430239",
    RegisteredPopulation: "852",
    VotingStationName: "BERGRIVIER PRIMARY SCHOOL",
    Address: "HAASKRAAL ROAD  WELLINGTON  WELLINGTON",
    Latitude: "-33.59",
    Longitude: "18.9493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22351",
    WardLookupId: "4540",
    VDNumber: "98380020",
    RegisteredPopulation: "1982",
    VotingStationName: "PAARL BOYS PRIMARY SCHOOL",
    Address: "DIVINE STREET  PAARL  ",
    Latitude: "-33.74",
    Longitude: "18.9651",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22352",
    WardLookupId: "4540",
    VDNumber: "98380031",
    RegisteredPopulation: "2628",
    VotingStationName: "PAARL TOWN HALL",
    Address: "MAIN STREET  PAARL  PAARL",
    Latitude: "-33.7379",
    Longitude: "18.9623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22353",
    WardLookupId: "4541",
    VDNumber: "98410136",
    RegisteredPopulation: "2614",
    VotingStationName: "COLIBRI SAAL",
    Address: "COLIBRI WAY  CARTERVILLE  WELLINGTON",
    Latitude: "-33.6663",
    Longitude: "18.989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22354",
    WardLookupId: "4541",
    VDNumber: "98410147",
    RegisteredPopulation: "1386",
    VotingStationName: "TENT IN SWARTBERG STREET",
    Address: "SWARTBERG STREET  OR TAMBO  WELLINGTON",
    Latitude: "-33.6691",
    Longitude: "18.989",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22355",
    WardLookupId: "4542",
    VDNumber: "98380301",
    RegisteredPopulation: "2074",
    VotingStationName: "MBEKWENI MUNICIPAL BUILDING",
    Address: "PHOKENG STREET  MBEKWENI  PAARL",
    Latitude: "-33.6772",
    Longitude: "18.9893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22356",
    WardLookupId: "4542",
    VDNumber: "98380390",
    RegisteredPopulation: "1551",
    VotingStationName: "SILVERTOWN COMMUNITY HALL",
    Address: "CELIWE  MBEKWENI  DRAKENSTEIN [PAARL]",
    Latitude: "-33.6719",
    Longitude: "18.9882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22357",
    WardLookupId: "4543",
    VDNumber: "98410080",
    RegisteredPopulation: "2365",
    VotingStationName: "WELTEVREDE SECONDARY SCHOOL",
    Address: "BLOEKOM AVENUE  WELTEVREDE  WELLINGTON",
    Latitude: "-33.6589",
    Longitude: "18.9938",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22358",
    WardLookupId: "4543",
    VDNumber: "98410091",
    RegisteredPopulation: "1414",
    VotingStationName: "WELLINGTON HIGH SCHOOL",
    Address: "DAVIDS AVENUE  VAN WYKS VLEI  WELLINGTON",
    Latitude: "-33.666",
    Longitude: "18.9923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22359",
    WardLookupId: "4544",
    VDNumber: "98380323",
    RegisteredPopulation: "1209",
    VotingStationName: "UNITED CONGREGATION CHURCH",
    Address: "ENTABENI STREET  BLOCK B, MBEKWENI  PAARL",
    Latitude: "-33.6723",
    Longitude: "18.9917",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22360",
    WardLookupId: "4544",
    VDNumber: "98380413",
    RegisteredPopulation: "528",
    VotingStationName: "PRESBYTERIAN CHURCH",
    Address: "MAFILA  MBEKWENI  DRAKENSTEIN [PAARL]",
    Latitude: "-33.6734",
    Longitude: "18.9945",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22361",
    WardLookupId: "4544",
    VDNumber: "98410057",
    RegisteredPopulation: "2025",
    VotingStationName: "MAY AVENUE - WELTEVREDE (TENT)",
    Address: "MAY AVENUE  MBEKWENI  PAARL",
    Latitude: "-33.6699",
    Longitude: "18.9912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22362",
    WardLookupId: "4545",
    VDNumber: "98380334",
    RegisteredPopulation: "1796",
    VotingStationName: "ST CHARLES LWANGA CATHOLIC CHURCH",
    Address: "C/O PHOKENG & PINZI STREET  MBEKWENI  PAARL",
    Latitude: "-33.6782",
    Longitude: "18.9946",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22363",
    WardLookupId: "4545",
    VDNumber: "98380367",
    RegisteredPopulation: "997",
    VotingStationName: "MBEKWENI COMMUNITY HALL",
    Address: "MPHAKALASI STREET  MBEKWENI  PAARL",
    Latitude: "-33.6757",
    Longitude: "18.9921",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22364",
    WardLookupId: "4545",
    VDNumber: "98380424",
    RegisteredPopulation: "1418",
    VotingStationName: "DESMOND MPILO TUTU HIGH SCHOOL",
    Address: "FUNDA STREET  MBEKWENI  DRAKENSTEIN [PAARL]",
    Latitude: "-33.6841",
    Longitude: "18.9929",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22365",
    WardLookupId: "4546",
    VDNumber: "98410079",
    RegisteredPopulation: "1204",
    VotingStationName: "BERGRIVER HIGH SCHOOL",
    Address: "CHAMPAGNE  WELLINGTON  WELLINGTON",
    Latitude: "-33.6484",
    Longitude: "18.9938",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22366",
    WardLookupId: "4546",
    VDNumber: "98410103",
    RegisteredPopulation: "2115",
    VotingStationName: "CHURCH OF THE NASARENE",
    Address: "PELIKAANSTRAAT  HILLCREST  WELLINGTON",
    Latitude: "-33.6553",
    Longitude: "18.995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22367",
    WardLookupId: "4547",
    VDNumber: "98410114",
    RegisteredPopulation: "2049",
    VotingStationName: "WELLINGTON PREPARATORY SCHOOL",
    Address: "BO DAL ROAD  NEWTON  WELLINGTON",
    Latitude: "-33.666",
    Longitude: "19.0041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22368",
    WardLookupId: "4547",
    VDNumber: "98410125",
    RegisteredPopulation: "1831",
    VotingStationName: "SAFMARINE SENTRUM",
    Address: "COODE STRAAT  WELTEVREDEN  WELLINGTON",
    Latitude: "-33.6651",
    Longitude: "18.9968",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22369",
    WardLookupId: "4548",
    VDNumber: "98380299",
    RegisteredPopulation: "2598",
    VotingStationName: "MBEKWENI PRIMARY SCHOOL",
    Address: "PINZI STREET  MBEKWENI  PAARL",
    Latitude: "-33.68",
    Longitude: "18.9927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22370",
    WardLookupId: "4548",
    VDNumber: "98380345",
    RegisteredPopulation: "1209",
    VotingStationName: "ETHIOPIAN EPSCOPAL CHURCH",
    Address: "2 MBIZO  MBEKWENI  PAARL",
    Latitude: "-33.6769",
    Longitude: "18.9907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22371",
    WardLookupId: "4549",
    VDNumber: "98380277",
    RegisteredPopulation: "1411",
    VotingStationName: "GROENHEUWEL PRIMARY SCHOOL",
    Address: "SYMPHONY WAY  GROENHEUWEL  PAARL",
    Latitude: "-33.7016",
    Longitude: "18.997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22372",
    WardLookupId: "4549",
    VDNumber: "98380389",
    RegisteredPopulation: "1784",
    VotingStationName: "BETHEL VGK CHURCH",
    Address: "19 SYMPHONY AVENUE  GROENHEUWEL  PAARL",
    Latitude: "-33.7014",
    Longitude: "18.9908",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22373",
    WardLookupId: "4550",
    VDNumber: "98380266",
    RegisteredPopulation: "1862",
    VotingStationName: "DALWEIDE PRIMARY SCHOOL",
    Address: "SYMPHONY AVENUE  GROENHEUWEL  PAARL",
    Latitude: "-33.697",
    Longitude: "18.9937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22374",
    WardLookupId: "4550",
    VDNumber: "98380446",
    RegisteredPopulation: "3071",
    VotingStationName: "GROENHEUWEL LIBRARY",
    Address: "BARTHOLOMEU  GROENHEUWEL  PAARL",
    Latitude: "-33.6995",
    Longitude: "18.9988",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22375",
    WardLookupId: "4551",
    VDNumber: "98380019",
    RegisteredPopulation: "4091",
    VotingStationName: "DE POORT",
    Address: "PINE STREET  SOUTHERN PAARL  PAARL",
    Latitude: "-33.7676",
    Longitude: "18.9626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22376",
    WardLookupId: "4552",
    VDNumber: "98380288",
    RegisteredPopulation: "2975",
    VotingStationName: "IHLUMELO JUNIOR SECONDARY",
    Address: "1 ZINGISANI STREET  MBEKWENI  PAARL",
    Latitude: "-33.6808",
    Longitude: "18.9869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22377",
    WardLookupId: "4552",
    VDNumber: "98380457",
    RegisteredPopulation: "1952",
    VotingStationName: "DRAKENSTEIN TRAINING CENTRE",
    Address: "DROMMEDARIS  GROENHEUWEL  DRAKENSTEIN [PAARL]",
    Latitude: "-33.6959",
    Longitude: "18.9834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22378",
    WardLookupId: "4553",
    VDNumber: "98380064",
    RegisteredPopulation: "2814",
    VotingStationName: "MONTE RIO AVENUE - NORTHERN PAARL (TENT)",
    Address: "MONTE RIO AVE  NORTHERN PAARL  NULL",
    Latitude: "-33.7067",
    Longitude: "18.9713",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22379",
    WardLookupId: "4553",
    VDNumber: "98430015",
    RegisteredPopulation: "1358",
    VotingStationName: "NIEUWEDRIFT CHURCH",
    Address: "NIEUWEDRIFT  NOORDER PAARL  PAARL",
    Latitude: "-33.6883",
    Longitude: "18.9632",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22380",
    WardLookupId: "4554",
    VDNumber: "98410046",
    RegisteredPopulation: "2889",
    VotingStationName: "NG CHURCH WELLINGTON NORTH",
    Address: "MACCRONE STREET  WELLINGTON",
    Latitude: "-33.6384",
    Longitude: "18.9972",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22381",
    WardLookupId: "4554",
    VDNumber: "98430228",
    RegisteredPopulation: "1187",
    VotingStationName: "SOETENDAL PRIMARY SCHOOL",
    Address: "R45  WELLINGTON  WELLINGTON",
    Latitude: "-33.6039",
    Longitude: "18.984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22382",
    WardLookupId: "4555",
    VDNumber: "98380042",
    RegisteredPopulation: "2208",
    VotingStationName: "PAARL BOLAND COLLEGE",
    Address: "PLEIN STREET  TOWN  PAARL",
    Latitude: "-33.7233",
    Longitude: "18.9674",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22383",
    WardLookupId: "4555",
    VDNumber: "98380075",
    RegisteredPopulation: "1848",
    VotingStationName: "CAPE WINELANDS ROADS DEPARTMENT",
    Address: "HEIDE  PAARL NORTH  PAARL",
    Latitude: "-33.7082",
    Longitude: "18.9698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22384",
    WardLookupId: "4556",
    VDNumber: "98380165",
    RegisteredPopulation: "2450",
    VotingStationName: "MIQLAT CENTRE OF HOPE",
    Address: "PLUMBAGO  KLEIN NEDERBURG  PAARL",
    Latitude: "-33.7277",
    Longitude: "18.9991",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22385",
    WardLookupId: "4556",
    VDNumber: "98380176",
    RegisteredPopulation: "1533",
    VotingStationName: "ST JOSEPHS AME CHURCH",
    Address: "BOUGAARD STREET  RABIES DALE  PAARL",
    Latitude: "-33.7318",
    Longitude: "18.9937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22386",
    WardLookupId: "4557",
    VDNumber: "98380187",
    RegisteredPopulation: "2274",
    VotingStationName: "HUNTER TEMPLE AME CHURCH",
    Address: "C/O MAGNOLIA AND BAUHENIA  KLEIN NEDERBURG  PAARL",
    Latitude: "-33.7239",
    Longitude: "18.9973",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22387",
    WardLookupId: "4557",
    VDNumber: "98380198",
    RegisteredPopulation: "1671",
    VotingStationName: "ORLEANSVALE PRIMARY SCHOOL",
    Address: "DUIKER STREET  NEW ORLEANS  PAARL",
    Latitude: "-33.7195",
    Longitude: "18.9983",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22388",
    WardLookupId: "4558",
    VDNumber: "98380222",
    RegisteredPopulation: "1195",
    VotingStationName: "NEW ORLEANS PRIMARY SCHOOL",
    Address: "WILGER AVE  NEW ORLEANS  PAARL",
    Latitude: "-33.7177",
    Longitude: "18.9865",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22389",
    WardLookupId: "4558",
    VDNumber: "98380233",
    RegisteredPopulation: "2061",
    VotingStationName: "HUGUENOT HALL",
    Address: "JAN VAN RIEBEECK DRIVE  HUGUENOT  PAARL",
    Latitude: "-33.727",
    Longitude: "18.9828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22390",
    WardLookupId: "4558",
    VDNumber: "98380244",
    RegisteredPopulation: "1042",
    VotingStationName: "OUR LITTLE PEOPLES NURSERY SCHOOL",
    Address: "10 CYPRESS   NEW ORLEANS  PAARL",
    Latitude: "-33.715",
    Longitude: "18.9882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22391",
    WardLookupId: "4559",
    VDNumber: "98380097",
    RegisteredPopulation: "2589",
    VotingStationName: "DRAKENSTEIN PRIMARY SCHOOL",
    Address: "UYS  PAARL  PAARL",
    Latitude: "-33.7324",
    Longitude: "18.9817",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22392",
    WardLookupId: "4560",
    VDNumber: "98380200",
    RegisteredPopulation: "1396",
    VotingStationName: "WATERBOKSTRAAT (TENT)",
    Address: "WATERBOKSTRAAT  NEW  ORLEANS  PAARL",
    Latitude: "-33.7151",
    Longitude: "18.9967",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22393",
    WardLookupId: "4560",
    VDNumber: "98380211",
    RegisteredPopulation: "1888",
    VotingStationName: "NEW ORLEANS SECONDARY SCHOOL",
    Address: "SUIKERBOS AVE  NEW ORLEANS  PAARL",
    Latitude: "-33.7152",
    Longitude: "18.9918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22394",
    WardLookupId: "4561",
    VDNumber: "98380132",
    RegisteredPopulation: "1039",
    VotingStationName: "AGS VAN SA CHURCH",
    Address: "BEUKES STREET  EASTERN PAARL  PAARL",
    Latitude: "-33.7311",
    Longitude: "19.0068",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22395",
    WardLookupId: "4561",
    VDNumber: "98380154",
    RegisteredPopulation: "1805",
    VotingStationName: "NEDERBURG PRIMARY SCHOOL",
    Address: "SOLOMON STREET  EASTERN PAARL  PAARL",
    Latitude: "-33.7283",
    Longitude: "19.005",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22396",
    WardLookupId: "4561",
    VDNumber: "98430138",
    RegisteredPopulation: "648",
    VotingStationName: "DALJOSAFAT PRIMARY SCHOOL",
    Address: "DALWEG  HUGUENOT  PAARL",
    Latitude: "-33.694",
    Longitude: "19.0165",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22397",
    WardLookupId: "4562",
    VDNumber: "98380121",
    RegisteredPopulation: "2962",
    VotingStationName: "CONRAD STREET (TENT)",
    Address: "CONRAD STREET  MOUNTAIN VIEW  PAARL",
    Latitude: "-33.7349",
    Longitude: "19.0116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22398",
    WardLookupId: "4562",
    VDNumber: "98380143",
    RegisteredPopulation: "1706",
    VotingStationName: "PAULUS JOUBERT PRIMARY SCHOOL",
    Address: "BEUKES STREET  PAARL",
    Latitude: "-33.734",
    Longitude: "19.0044",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22399",
    WardLookupId: "4563",
    VDNumber: "98380109",
    RegisteredPopulation: "2171",
    VotingStationName: "AMSTELHOF PRIMARY SCHOOL",
    Address: "KWIKKIE STREET  AMSTELHOF  PAARL",
    Latitude: "-33.7395",
    Longitude: "19.0022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22400",
    WardLookupId: "4563",
    VDNumber: "98380110",
    RegisteredPopulation: "1867",
    VotingStationName: "L.K ZEEMAN PRIMARY",
    Address: "SUIKERBEKKIE STREET  AMSTELHOF  PAARL",
    Latitude: "-33.74",
    Longitude: "19.0094",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22401",
    WardLookupId: "4564",
    VDNumber: "98430059",
    RegisteredPopulation: "2624",
    VotingStationName: "E DE WAAL CHURCH",
    Address: "WEMMERSHOEK ROAD  WEMMERSHOEK  PAARL",
    Latitude: "-33.786",
    Longitude: "18.9902",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22402",
    WardLookupId: "4564",
    VDNumber: "98430194",
    RegisteredPopulation: "734",
    VotingStationName: "RONWE PRIMARY SCHOOL",
    Address: "LUSTIGAN ROAD  DRAKENSTEIN-SOUTH  PAARL",
    Latitude: "-33.7617",
    Longitude: "19.0023",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22403",
    WardLookupId: "4564",
    VDNumber: "98430262",
    RegisteredPopulation: "1261",
    VotingStationName: "SONOP PRIMARY SCHOOL",
    Address: "KLEIN DRAKENSTEIN ROAD  PAARL  PAARL",
    Latitude: "-33.738",
    Longitude: "19.0237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22404",
    WardLookupId: "4565",
    VDNumber: "98410035",
    RegisteredPopulation: "1662",
    VotingStationName: "HUGENOTE HOÃ‹RSKOOL KLUBHUIS",
    Address: "BLOUVLEI ROAD  WELLINGTON",
    Latitude: "-33.6479",
    Longitude: "19.0107",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22405",
    WardLookupId: "4565",
    VDNumber: "98410068",
    RegisteredPopulation: "1942",
    VotingStationName: "WELLINGTON PRIMARY SCHOOL",
    Address: "VOOR STREET  WELLINGTON  PAARL",
    Latitude: "-33.6459",
    Longitude: "18.9984",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22406",
    WardLookupId: "4565",
    VDNumber: "98430161",
    RegisteredPopulation: "614",
    VotingStationName: "STUCKI HALL",
    Address: "BLOUVLEI ROAD  BLOUVLEI  WELLINGTON",
    Latitude: "-33.6625",
    Longitude: "19.0321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22407",
    WardLookupId: "4566",
    VDNumber: "97480019",
    RegisteredPopulation: "1574",
    VotingStationName: "CALVYNS PROTESTANT CHURCH",
    Address: "C/O ELIM AND MAMRE STREET  SARON  DRAKENSTEIN",
    Latitude: "-33.1805",
    Longitude: "18.9986",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22408",
    WardLookupId: "4566",
    VDNumber: "97480020",
    RegisteredPopulation: "2423",
    VotingStationName: "SARON COMMUNITY HALL",
    Address: "CLAREMONT ST                 SARON  DRAKENSTEIN",
    Latitude: "-33.1828",
    Longitude: "19.0125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22409",
    WardLookupId: "4567",
    VDNumber: "97540139",
    RegisteredPopulation: "2331",
    VotingStationName: "COMMUNITY HALL GOUDA",
    Address: "MALVA STREET  GOUDA  GOUDA",
    Latitude: "-33.2954",
    Longitude: "19.0431",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22410",
    WardLookupId: "4567",
    VDNumber: "98430060",
    RegisteredPopulation: "1358",
    VotingStationName: "WAGENMAKERSVALLEI PRIMARY SCHOOL",
    Address: "BOVLEI ROAD  WELLINGTON  WELLINGTON",
    Latitude: "-33.6315",
    Longitude: "19.0474",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22411",
    WardLookupId: "4567",
    VDNumber: "98430240",
    RegisteredPopulation: "1102",
    VotingStationName: "GROENBERG PRIMARY SCHOOL",
    Address: "BO-HERMON WEG  WELLINGTON  WELLINGTON",
    Latitude: "-33.5668",
    Longitude: "19.0092",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22412",
    WardLookupId: "4568",
    VDNumber: "98380378",
    RegisteredPopulation: "3224",
    VotingStationName: "RAINBOW EDUCARE CENTRE",
    Address: "SUNMAID ROAD  MILKY TOWN  PAARL",
    Latitude: "-33.7012",
    Longitude: "19.0043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22413",
    WardLookupId: "4568",
    VDNumber: "98380402",
    RegisteredPopulation: "1149",
    VotingStationName: "FAIRYLAND HALL",
    Address: "BARTOLOMEUS  GROENHEUWEL  DRAKENSTEIN [PAARL]",
    Latitude: "-33.7025",
    Longitude: "18.9995",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22414",
    WardLookupId: "4569",
    VDNumber: "98380356",
    RegisteredPopulation: "2558",
    VotingStationName: "WILLIAM LLOYD PRIMARY SCHOOL",
    Address: "MAASDORP STREET  PAARL  PAARL",
    Latitude: "-33.735",
    Longitude: "18.9957",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22415",
    WardLookupId: "2709",
    VDNumber: "98370018",
    RegisteredPopulation: "2613",
    VotingStationName: "DALUBUHLE PRIMARY SCHOOL",
    Address: "MOOIWATER  FRANSCHHOEK  FRANSCHHOEK",
    Latitude: "-33.886",
    Longitude: "19.1084",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22416",
    WardLookupId: "2709",
    VDNumber: "98370029",
    RegisteredPopulation: "1732",
    VotingStationName: "FRANSCHHOEK TOWN HALL",
    Address: "27 HUGENOT STREET  FRANSCHHOEK  FRANSCHHOEK",
    Latitude: "-33.9104",
    Longitude: "19.1196",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22417",
    WardLookupId: "2710",
    VDNumber: "98370030",
    RegisteredPopulation: "2799",
    VotingStationName: "GROENDAL SECONDARY SCHOOL",
    Address: "JAFTHA SINGEL  GROENDAL  FRANSCHHOEK",
    Latitude: "-33.8906",
    Longitude: "19.1028",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22418",
    WardLookupId: "2710",
    VDNumber: "98370041",
    RegisteredPopulation: "918",
    VotingStationName: "WHITEHEAD HALL-GROENDAL SPORT GROUND",
    Address: "LA PROVENCE  GROENDAL  FRANSCHHOEK",
    Latitude: "-33.8947",
    Longitude: "19.1043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22419",
    WardLookupId: "2710",
    VDNumber: "98430251",
    RegisteredPopulation: "1032",
    VotingStationName: "FRANSCHHOEK ESTATE SECURITY & TRAINING CENTRE",
    Address: "142 STINKHOUT STREET  LA MOTTE  FRANSCHHOEK",
    Latitude: "-33.893",
    Longitude: "19.0716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22420",
    WardLookupId: "2711",
    VDNumber: "98430093",
    RegisteredPopulation: "1055",
    VotingStationName: "WEMMERSHOEK PRIMARY SCHOOL",
    Address: "WEMMERSHOEK ROAD  LA MOTTE  GROOT DRAKENSTEIN",
    Latitude: "-33.8769",
    Longitude: "19.0455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22421",
    WardLookupId: "2711",
    VDNumber: "98430172",
    RegisteredPopulation: "1927",
    VotingStationName: "ST GEORGES CHURCH PNIEL",
    Address: "CORNER OF MAIN AND PROTEA ROAD  LYNQUEDOC  PNIEL",
    Latitude: "-33.8984",
    Longitude: "18.964",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22422",
    WardLookupId: "2711",
    VDNumber: "98430183",
    RegisteredPopulation: "522",
    VotingStationName: "GROOT DRAKENSTEIN GAMES CLUB",
    Address: "R45 FRANSCHHOEK PAARL ROAD  GROOT DRAKENSTEIN  STELLENBOSCH",
    Latitude: "-33.8707",
    Longitude: "18.9905",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22423",
    WardLookupId: "2712",
    VDNumber: "98390010",
    RegisteredPopulation: "1656",
    VotingStationName: "PNIEL TLC HALL",
    Address: "MAIN ROAD  PNIEL  GROOT DRAKENSTEIN",
    Latitude: "-33.8932",
    Longitude: "18.9583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22424",
    WardLookupId: "2712",
    VDNumber: "98400157",
    RegisteredPopulation: "1011",
    VotingStationName: "VGK HALL KYLEMORE",
    Address: "CHURCH STREET  KYLEMORE  STELLENBOSCH",
    Latitude: "-33.9133",
    Longitude: "18.9482",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22425",
    WardLookupId: "2712",
    VDNumber: "98400236",
    RegisteredPopulation: "1860",
    VotingStationName: "KYLEMORE COMMUNITY HALL",
    Address: "SKOOL STREET  KYLEMORE  STELLENBOSCH",
    Latitude: "-33.9217",
    Longitude: "18.9546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22426",
    WardLookupId: "2713",
    VDNumber: "98400146",
    RegisteredPopulation: "2498",
    VotingStationName: "KREEFGAT PARK (TENT - C/O HECTOR & DAVY STR)",
    Address: "C/O HECTOR AND DAVY STREET  IDASVALLEI  STELLENBOSCH",
    Latitude: "-33.9199",
    Longitude: "18.8906",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22427",
    WardLookupId: "2713",
    VDNumber: "98420081",
    RegisteredPopulation: "521",
    VotingStationName: "AKKERLAND CRECHE",
    Address: "JONKERSHOEK WEG  JONKERSHOEK  STELLENBOSCH",
    Latitude: "-33.9652",
    Longitude: "18.9269",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22428",
    WardLookupId: "2713",
    VDNumber: "98420115",
    RegisteredPopulation: "423",
    VotingStationName: "RUSTENBERG COMMUNITY HALL",
    Address: "SCHOONGEZICHT FARM  RUSTENBERG ROAD  STELLENBOSCH",
    Latitude: "-33.9075",
    Longitude: "18.8884",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22429",
    WardLookupId: "2714",
    VDNumber: "98400124",
    RegisteredPopulation: "2474",
    VotingStationName: "IDAS VALLEY PRIMARY SCHOOL",
    Address: "BLOEKOM  IDASVALLEI  STELLENBOSCH",
    Latitude: "-33.9194",
    Longitude: "18.8831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22430",
    WardLookupId: "2714",
    VDNumber: "98420069",
    RegisteredPopulation: "697",
    VotingStationName: "VGK KOELENHOF",
    Address: "KROMME RHEE PAD OFF R44  SLALEY FARM  STELLENBOSCH",
    Latitude: "-33.8647",
    Longitude: "18.8456",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22431",
    WardLookupId: "2715",
    VDNumber: "98400135",
    RegisteredPopulation: "4301",
    VotingStationName: "HOÃ‹RSKOOL STELLENBOSCH",
    Address: "JANNASCH STREET  UNIEPARK  STELLENBOSCH",
    Latitude: "-33.9311",
    Longitude: "18.8791",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22432",
    WardLookupId: "2716",
    VDNumber: "98400113",
    RegisteredPopulation: "6137",
    VotingStationName: "DIE STAL",
    Address: "SUIDWAL STRAAT  STELLENBOSCH  COETZENBURG",
    Latitude: "-33.9384",
    Longitude: "18.8729",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22433",
    WardLookupId: "2717",
    VDNumber: "98400089",
    RegisteredPopulation: "2470",
    VotingStationName: "STELLENBOSCH TOWN HALL",
    Address: "PLEIN STREET  CENTRAL  STELLENBOSCH",
    Latitude: "-33.9365",
    Longitude: "18.8615",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22434",
    WardLookupId: "2718",
    VDNumber: "98400078",
    RegisteredPopulation: "2263",
    VotingStationName: "AF LOUW PRIMARY SCHOOL",
    Address: "LA COLLINE STREET  LA COLLINE  STELLENBOSCH",
    Latitude: "-33.9239",
    Longitude: "18.8582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22435",
    WardLookupId: "2718",
    VDNumber: "98400102",
    RegisteredPopulation: "2494",
    VotingStationName: "CHRIST CHURCH HALL",
    Address: "38 BANGHOEK  STELLENBOSCH  STELLENBOSCH",
    Latitude: "-33.9305",
    Longitude: "18.861",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22436",
    WardLookupId: "2719",
    VDNumber: "98400090",
    RegisteredPopulation: "2029",
    VotingStationName: "VAN DER STEL SPORTS CLUB",
    Address: "MERRIMAN AVENUE  CENTRAL/ONDERPAPEGAAIBERG  STELLENBOSCH",
    Latitude: "-33.9342",
    Longitude: "18.855",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22437",
    WardLookupId: "2719",
    VDNumber: "98400292",
    RegisteredPopulation: "1261",
    VotingStationName: "STELLENBOSCH SCOUT`S HALL",
    Address: "TARENTAAL  ONDER PAPEGAAIBERG  STELLENBOSCH",
    Latitude: "-33.9383",
    Longitude: "18.833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22438",
    WardLookupId: "2719",
    VDNumber: "98420070",
    RegisteredPopulation: "822",
    VotingStationName: "OLD APOSTOLIC CHURCH OF SA (HIGHMEAD FARM)",
    Address: "DEVON VALLEY ROAD  DEVON VALLEY  STELLENBOSCH",
    Latitude: "-33.9219",
    Longitude: "18.8185",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22439",
    WardLookupId: "2720",
    VDNumber: "98400258",
    RegisteredPopulation: "3383",
    VotingStationName: "KAYA MANDI HIGH SCHOOL",
    Address: "OLD COROBRICK ROAD  KAYA MANDI  STELLENBOSCH",
    Latitude: "-33.9233",
    Longitude: "18.848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22440",
    WardLookupId: "2720",
    VDNumber: "98400315",
    RegisteredPopulation: "2419",
    VotingStationName: "KAYAMANDI PRIMARY SCHOOL",
    Address: "ERF 183 WATERGANG, THUBELITSHA  KAYA MANDI  STELLENBOSCH",
    Latitude: "-33.9218",
    Longitude: "18.8412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22441",
    WardLookupId: "2721",
    VDNumber: "98400067",
    RegisteredPopulation: "3075",
    VotingStationName: "ST JOHNS ANGLICAN CHURCH",
    Address: "MASITHANDANE  KAYAMANDI  STELLENBOSCH",
    Latitude: "-33.9162",
    Longitude: "18.8495",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22442",
    WardLookupId: "2722",
    VDNumber: "98400214",
    RegisteredPopulation: "4090",
    VotingStationName: "MAKUPULA SECONDARY SCHOOL",
    Address: "MAKUPULA  KAYAMANDI  STELLENBOSCH",
    Latitude: "-33.9172",
    Longitude: "18.8468",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22443",
    WardLookupId: "2723",
    VDNumber: "98400045",
    RegisteredPopulation: "2439",
    VotingStationName: "LEGACY HALL",
    Address: "LONG STREET  KAYAMANDI  STELLENBOSCH",
    Latitude: "-33.9208",
    Longitude: "18.8454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22444",
    WardLookupId: "2723",
    VDNumber: "98400247",
    RegisteredPopulation: "2449",
    VotingStationName: "PRESBYTERIAN CHURCH OF AFRICA",
    Address: "BASSI  KAYAMANDI  KAYAMANDI",
    Latitude: "-33.92",
    Longitude: "18.8472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22445",
    WardLookupId: "2724",
    VDNumber: "98400023",
    RegisteredPopulation: "3346",
    VotingStationName: "EIKESTADSAAL",
    Address: "LONG STREET  CLOETESVILLE  STELLENBOSCH",
    Latitude: "-33.9113",
    Longitude: "18.8546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22446",
    WardLookupId: "2724",
    VDNumber: "98400034",
    RegisteredPopulation: "1415",
    VotingStationName: "CLOETESVILLE PRIMARY SCHOOL",
    Address: "CURRY STREET  CLOETESVILLE  STELLENBOSCH",
    Latitude: "-33.9169",
    Longitude: "18.854",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22447",
    WardLookupId: "2725",
    VDNumber: "98400012",
    RegisteredPopulation: "2481",
    VotingStationName: "RIETENBOSCH PRIMARY SCHOOL",
    Address: "90 LANG STREET  CLOETESVILLE  STELLENBOSCH",
    Latitude: "-33.9099",
    Longitude: "18.8576",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22448",
    WardLookupId: "2725",
    VDNumber: "98400304",
    RegisteredPopulation: "2619",
    VotingStationName: "WELGEVONDEN ESTATE MAIN ENTRANCE (TENT)",
    Address: "WELGEVONDEN  STELLENBOSCH",
    Latitude: "-33.9022",
    Longitude: "18.8567",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22449",
    WardLookupId: "2726",
    VDNumber: "98400203",
    RegisteredPopulation: "5127",
    VotingStationName: "KLAPMUTS PRIMARY SCHOOL",
    Address: "MERCHANT STREET  KLAPMUTS  STELLENBOSCH",
    Latitude: "-33.8105",
    Longitude: "18.8642",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22450",
    WardLookupId: "2727",
    VDNumber: "98400326",
    RegisteredPopulation: "467",
    VotingStationName: "TENT (NEXT CLOETESVILLE SPORTSGROUND)",
    Address: "LONG STREET  CLOETESVILLE  STELLENBOSCH",
    Latitude: "-33.9048",
    Longitude: "18.848",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22451",
    WardLookupId: "2727",
    VDNumber: "98420014",
    RegisteredPopulation: "989",
    VotingStationName: "JJ RHODE PRIMARY SCHOOL",
    Address: "VAALDRAAI ROAD  ELSENBURG  STELLENBOCH",
    Latitude: "-33.8475",
    Longitude: "18.843",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22452",
    WardLookupId: "2727",
    VDNumber: "98420058",
    RegisteredPopulation: "940",
    VotingStationName: "BOTTELARY SPORTS CLUB",
    Address: "BOTTELARY ROAD  BOTTELARY   STELLENBOSCH",
    Latitude: "-33.8848",
    Longitude: "18.7534",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22453",
    WardLookupId: "2727",
    VDNumber: "98420104",
    RegisteredPopulation: "552",
    VotingStationName: "DE NOVO COMMUNITY HALL",
    Address: "OFF R101  DE NOVO  KRAAIFONTEIN",
    Latitude: "-33.8405",
    Longitude: "18.7719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22454",
    WardLookupId: "2727",
    VDNumber: "98420137",
    RegisteredPopulation: "836",
    VotingStationName: "KOELENHOF CATHOLIC PRIMARY SCHOOL",
    Address: "STATION ROAD  KOELENHOF  STELLENBOSCH [STELLENBOSCH]",
    Latitude: "-33.8738",
    Longitude: "18.8152",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22455",
    WardLookupId: "2728",
    VDNumber: "98420025",
    RegisteredPopulation: "896",
    VotingStationName: "MOOIBERGE CHURCH HALL",
    Address: "MOOIBERGE  ANNANDALE RD  STELLENBOSCH",
    Latitude: "-33.9983",
    Longitude: "18.8281",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22456",
    WardLookupId: "2728",
    VDNumber: "98420036",
    RegisteredPopulation: "1221",
    VotingStationName: "RAITHBY METHODIST CHURCH",
    Address: "WATSON WAY  RAITHBY  STELLENBOSCH",
    Latitude: "-34.022",
    Longitude: "18.8065",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22457",
    WardLookupId: "2728",
    VDNumber: "98420047",
    RegisteredPopulation: "1959",
    VotingStationName: "VLOTTENBURG VGK CHURCH HALL",
    Address: "STELLENBOSCH KLOOFPAD  VLOTTENBURG  STELLENBOSCH",
    Latitude: "-33.9513",
    Longitude: "18.7976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22458",
    WardLookupId: "2729",
    VDNumber: "98400168",
    RegisteredPopulation: "2843",
    VotingStationName: "CHRISTIAN BROTHERS CENTRE",
    Address: "1 PARADYSKLOOF ROAD  PARADYSKLOOF  STELLENBOSCH",
    Latitude: "-33.9661",
    Longitude: "18.8635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22459",
    WardLookupId: "2729",
    VDNumber: "98400180",
    RegisteredPopulation: "1983",
    VotingStationName: "STELLENZICHT SEKONDÃŠRE SKOOL",
    Address: "TRIBUTELAAN  JAMESTOWN  STELLENBOSCH",
    Latitude: "-33.9826",
    Longitude: "18.8502",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22460",
    WardLookupId: "2730",
    VDNumber: "98400179",
    RegisteredPopulation: "2490",
    VotingStationName: "RHENISH GIRLS HIGH SCHOOL",
    Address: "KOCH STREET  KRIGEVILLE  STELLENBOSCH",
    Latitude: "-33.9456",
    Longitude: "18.8587",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22461",
    WardLookupId: "2730",
    VDNumber: "98400191",
    RegisteredPopulation: "1920",
    VotingStationName: "EIKESTAD PRIMARY SCHOOL",
    Address: "DOORNBOSCH STREET  KRIGEVILLE  STELLENBOSCH",
    Latitude: "-33.9448",
    Longitude: "18.8554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22462",
    WardLookupId: "4570",
    VDNumber: "97490010",
    RegisteredPopulation: "1848",
    VotingStationName: "STEENVLIET PRIMARY SCHOOL",
    Address: "CHURCH  STEENVLIET  TOUWS RIVER",
    Latitude: "-33.3465",
    Longitude: "20.0221",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22463",
    WardLookupId: "4570",
    VDNumber: "97490021",
    RegisteredPopulation: "1680",
    VotingStationName: "TOUWSRIVER PRIMARY SCHOOL",
    Address: "SUID  TOUWSRIVER  TOUWS RIVER",
    Latitude: "-33.3421",
    Longitude: "20.0341",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22464",
    WardLookupId: "4570",
    VDNumber: "97530060",
    RegisteredPopulation: "78",
    VotingStationName: "COMMUTER TRANSPORT ENGINEERING",
    Address: "N1 OLD LOCO WORKSHOP  BREEDE VALLEY   TOUWS RIVER",
    Latitude: "-33.3189",
    Longitude: "20.1453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22465",
    WardLookupId: "4571",
    VDNumber: "97420035",
    RegisteredPopulation: "3966",
    VotingStationName: "TENT (OPPOSITE ABUSHE MOBILE SPAZA SHOP)",
    Address: "DE DOORNS  ",
    Latitude: "-33.486",
    Longitude: "19.6789",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22466",
    WardLookupId: "4572",
    VDNumber: "97420013",
    RegisteredPopulation: "957",
    VotingStationName: "MUNICIPAL WORKSHOP",
    Address:
      "C/O VOORTREKKER ROAD & OMDRAAI STREET  DE DOORNS NORTH  DE DOORNS",
    Latitude: "-33.4738",
    Longitude: "19.666",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22467",
    WardLookupId: "4572",
    VDNumber: "97420046",
    RegisteredPopulation: "1669",
    VotingStationName: "SPORT CENTRE",
    Address: "OFF VIOLET STREET-SPORTSFIELD  DE DOORNS  DE DOORNS",
    Latitude: "-33.4805",
    Longitude: "19.6757",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22468",
    WardLookupId: "4572",
    VDNumber: "97530228",
    RegisteredPopulation: "1048",
    VotingStationName: "JJ SUPERMARKET",
    Address: "HOOFWEG  DE DOORNS  DE DOORNS",
    Latitude: "-33.4303",
    Longitude: "19.7116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22469",
    WardLookupId: "4572",
    VDNumber: "97530251",
    RegisteredPopulation: "813",
    VotingStationName: "RABIE PRIMARY SCHOOL",
    Address: "VOORTREKKER STREET  DE DOORNS  BUFFELSKRAAL -WES",
    Latitude: "-33.4358",
    Longitude: "19.7027",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22470",
    WardLookupId: "4573",
    VDNumber: "97420024",
    RegisteredPopulation: "682",
    VotingStationName: "DE DOORNS SEKONDÃŠRE SKOOL",
    Address: "GLENCO ROAD  DE DOORNS  WORCESTER",
    Latitude: "-33.4845",
    Longitude: "19.6669",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22471",
    WardLookupId: "4573",
    VDNumber: "97530026",
    RegisteredPopulation: "795",
    VotingStationName: "GLEN OAK FARM HALL",
    Address: "HEX VALLEY  DE DOORNS  DE DOORNS",
    Latitude: "-33.4664",
    Longitude: "19.6522",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22472",
    WardLookupId: "4573",
    VDNumber: "97530240",
    RegisteredPopulation: "2023",
    VotingStationName: "ORCHARD PRIMARY SCHOOL",
    Address: "ORCHARD  DE DOORNS",
    Latitude: "-33.4901",
    Longitude: "19.6359",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22473",
    WardLookupId: "4574",
    VDNumber: "97520294",
    RegisteredPopulation: "916",
    VotingStationName: "BRANDWACHT PRIMARY",
    Address: "BRANDWACHT AVENUE  BRANDWACHT  WORCESTER",
    Latitude: "-33.5908",
    Longitude: "19.4085",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22474",
    WardLookupId: "4574",
    VDNumber: "97530194",
    RegisteredPopulation: "784",
    VotingStationName: "DE WET SPORT GROUNDS",
    Address: "DE WET  WORCESTER",
    Latitude: "-33.6031",
    Longitude: "19.5159",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22475",
    WardLookupId: "4574",
    VDNumber: "97530206",
    RegisteredPopulation: "1466",
    VotingStationName: "SANDHILLS PRIMARY SCHOOL",
    Address: "ROODEZANDT  SANDHILLS  BREEDE VALLEY MUNICIPALITY",
    Latitude: "-33.5179",
    Longitude: "19.552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22476",
    WardLookupId: "4574",
    VDNumber: "97530262",
    RegisteredPopulation: "657",
    VotingStationName: "WELTEVREDE SHED- HEX RIVER",
    Address: "WELTEVREDE FARM  HEX RIVER  HEX RIVER",
    Latitude: "-33.4893",
    Longitude: "19.5878",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22477",
    WardLookupId: "4575",
    VDNumber: "97520047",
    RegisteredPopulation: "1577",
    VotingStationName: "TUINDORP HALL",
    Address: "HEIN STREET  TUINDORP  WORCESTER",
    Latitude: "-33.6378",
    Longitude: "19.4385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22478",
    WardLookupId: "4575",
    VDNumber: "97520148",
    RegisteredPopulation: "1925",
    VotingStationName: "WORCESTER NORTH PRIMARY SCHOOL",
    Address: "JAKARANDA AVENUE  ROUXPARK  WORCESTER",
    Latitude: "-33.6304",
    Longitude: "19.4514",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22479",
    WardLookupId: "4576",
    VDNumber: "97520036",
    RegisteredPopulation: "973",
    VotingStationName: "BOLAND PARK SPORT GROUNDS",
    Address: "FAIRBAIRN STREET  ESSELEN PARK   WORCESTER",
    Latitude: "-33.6469",
    Longitude: "19.4538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22480",
    WardLookupId: "4576",
    VDNumber: "97520070",
    RegisteredPopulation: "2610",
    VotingStationName: "BADENHORSTSAAL (INSTITUTE FOR THE DEAF)",
    Address: "DE LA BATROAD  RE-UNION PARK  WORCESTER",
    Latitude: "-33.6327",
    Longitude: "19.4546",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22481",
    WardLookupId: "4577",
    VDNumber: "97520193",
    RegisteredPopulation: "2006",
    VotingStationName: "PJB CONA SCHOOL",
    Address: "50 MAYINYANA AVENUE  ZWELETEMBA  WORCESTER",
    Latitude: "-33.6479",
    Longitude: "19.4872",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22482",
    WardLookupId: "4578",
    VDNumber: "97520092",
    RegisteredPopulation: "1434",
    VotingStationName: "APOSTOLIESE GELOOFSENDING KERK (AGS)",
    Address: "ARENDSE STREET  ROODEWAL  WORCESTER",
    Latitude: "-33.6514",
    Longitude: "19.4668",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22483",
    WardLookupId: "4578",
    VDNumber: "97520159",
    RegisteredPopulation: "901",
    VotingStationName: "FLAVA YOUTH DEVELOPMENT CENTRE",
    Address: "112 VAN ZYL AVENUE  ESSELEN PARK  WORCESTER",
    Latitude: "-33.6501",
    Longitude: "19.4582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22484",
    WardLookupId: "4578",
    VDNumber: "97520238",
    RegisteredPopulation: "1119",
    VotingStationName: "WORCESTER SENIOR SECONDARY SCHOOL",
    Address: "STYNDER STREET  ROODEWAL  WORCESTER",
    Latitude: "-33.6512",
    Longitude: "19.4616",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22485",
    WardLookupId: "4578",
    VDNumber: "97520272",
    RegisteredPopulation: "1775",
    VotingStationName: "TRAFFIC CENTRE",
    Address: "CNR LEIPOLDT AVENUE & ROBERTSON ROAD  ROODEWAL  WORCESTER",
    Latitude: "-33.6449",
    Longitude: "19.465",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22486",
    WardLookupId: "4579",
    VDNumber: "97520216",
    RegisteredPopulation: "1934",
    VotingStationName: "KIBBUTZ EL SHAMMAH",
    Address: "NEETHLING STREET  ROODEWAL  WORCESTER",
    Latitude: "-33.6475",
    Longitude: "19.4698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22487",
    WardLookupId: "4579",
    VDNumber: "97520227",
    RegisteredPopulation: "2246",
    VotingStationName: "CALVARY AME CHURCH",
    Address: "CNR DUNCAN & EXCELSIOR ROADS  HEX PARK  WORCESTER",
    Latitude: "-33.6539",
    Longitude: "19.4702",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22488",
    WardLookupId: "4580",
    VDNumber: "97520250",
    RegisteredPopulation: "1196",
    VotingStationName: "CHIPROS",
    Address: "11 BREERIVIER CRESCENT  RIVERVIEW  WORCESTER",
    Latitude: "-33.6591",
    Longitude: "19.4527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22489",
    WardLookupId: "4580",
    VDNumber: "97520261",
    RegisteredPopulation: "1824",
    VotingStationName: "DE TUINEN PRIMARY SCHOOL",
    Address: "CUPIDO STREET  PARKERSDAM  WORCESTER",
    Latitude: "-33.6554",
    Longitude: "19.4463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22490",
    WardLookupId: "4581",
    VDNumber: "97520014",
    RegisteredPopulation: "1730",
    VotingStationName: "CHRISTIAN LIGHT MINISTRIES",
    Address: "34 FISANT STREET  AVIAN PARK  WORCESTER",
    Latitude: "-33.664",
    Longitude: "19.4299",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22491",
    WardLookupId: "4581",
    VDNumber: "97520058",
    RegisteredPopulation: "2029",
    VotingStationName: "WORCESTER GYMNASIUM HIGH SCHOOL",
    Address: "TULBAGH STREETS  OU DORP  WORCESTER",
    Latitude: "-33.6421",
    Longitude: "19.439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22492",
    WardLookupId: "4582",
    VDNumber: "97520069",
    RegisteredPopulation: "806",
    VotingStationName: "U R C WORCESTER SOUTH",
    Address: "8 CNR ROMAN & SWARTS STREET  RIVERVIEW  WORCESTER",
    Latitude: "-33.6602",
    Longitude: "19.4481",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22493",
    WardLookupId: "4582",
    VDNumber: "97520171",
    RegisteredPopulation: "1638",
    VotingStationName: "MARANATHA CHRISTIAN CENTRE",
    Address: "CNR ELIZABETH & DENISE STREETS  JOHNSON PARK  WORCESTER",
    Latitude: "-33.659",
    Longitude: "19.4609",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22494",
    WardLookupId: "4582",
    VDNumber: "97520249",
    RegisteredPopulation: "1540",
    VotingStationName: "BREERIVER SENIOR SECONDARY SCHOOL",
    Address: "NOBLE STREET  RIVERVIEW  WORCESTER",
    Latitude: "-33.6641",
    Longitude: "19.4412",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22495",
    WardLookupId: "4583",
    VDNumber: "97520081",
    RegisteredPopulation: "1445",
    VotingStationName: "JULIE NAUDE CRECHE",
    Address: "40 LE SUEUR STREET  VICTORIA PARK  WORCESTER",
    Latitude: "-33.6533",
    Longitude: "19.4429",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22496",
    WardLookupId: "4583",
    VDNumber: "97520137",
    RegisteredPopulation: "1875",
    VotingStationName: "VICTORIA PARK PRIMARY SCHOOL",
    Address: "81 RAINIER STREET  VICTORIA PARK  WORCESTER",
    Latitude: "-33.6597",
    Longitude: "19.4411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22497",
    WardLookupId: "4584",
    VDNumber: "97520025",
    RegisteredPopulation: "2202",
    VotingStationName: "WORCESTER PRIMARY SCHOOL",
    Address: "KRIGE  LANGERUG  WORCESTER",
    Latitude: "-33.646",
    Longitude: "19.4321",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22498",
    WardLookupId: "4584",
    VDNumber: "97520328",
    RegisteredPopulation: "1415",
    VotingStationName: "NG KERK BRANDWACHT",
    Address: "KAROO  WORCESTER WEST  WORCESTER",
    Latitude: "-33.6285",
    Longitude: "19.4136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22499",
    WardLookupId: "4585",
    VDNumber: "97520160",
    RegisteredPopulation: "2286",
    VotingStationName: "MULTIPURPOSE CENTRE",
    Address: "MTWAZI ROAD  ZWELETEMBA  WORCESTER",
    Latitude: "-33.6473",
    Longitude: "19.4954",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22500",
    WardLookupId: "4585",
    VDNumber: "97520339",
    RegisteredPopulation: "915",
    VotingStationName: "CHURCH OF CHRIST",
    Address: "839 MATSILA   ZWELETHEMBA  WORCESTER",
    Latitude: "-33.6456",
    Longitude: "19.4955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22501",
    WardLookupId: "4586",
    VDNumber: "97520115",
    RegisteredPopulation: "1268",
    VotingStationName: "ZWELETEMBA CIVIC CENTRE",
    Address: "MTWAZI ROAD  ZWELETEMBA  WORCESTER",
    Latitude: "-33.6464",
    Longitude: "19.4925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22502",
    WardLookupId: "4586",
    VDNumber: "97520283",
    RegisteredPopulation: "1725",
    VotingStationName: "XOLANI CRECHE",
    Address: "ZWELETHEMBA  BREEDE VALLEY",
    Latitude: "-33.6425",
    Longitude: "19.493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22503",
    WardLookupId: "4587",
    VDNumber: "97530150",
    RegisteredPopulation: "579",
    VotingStationName: "HOUMOED (SCHERPENHEUWEL) SHED",
    Address: "HOUMOED FARM   HOUMOED(SCHERPENHEUWEL)  SCHERPENHEUWEL",
    Latitude: "-33.7834",
    Longitude: "19.6227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22504",
    WardLookupId: "4587",
    VDNumber: "97530161",
    RegisteredPopulation: "871",
    VotingStationName: "OVERHEX COMMUNITY HALL",
    Address: "R60 OVERHEX  OVERHEX  WORCESTER",
    Latitude: "-33.6676",
    Longitude: "19.5438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22505",
    WardLookupId: "4587",
    VDNumber: "97530172",
    RegisteredPopulation: "562",
    VotingStationName: "NUY COMMUNITY HALL (WILGERIVIER)",
    Address: "NONNA ROAD  NUY  WORCESTER",
    Latitude: "-33.6724",
    Longitude: "19.6203",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22506",
    WardLookupId: "4587",
    VDNumber: "97530183",
    RegisteredPopulation: "447",
    VotingStationName: "BELLEVUE CHURCH",
    Address: "DE WET, NONNA ROAD  DE WET  WORCESTER",
    Latitude: "-33.6291",
    Longitude: "19.571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22507",
    WardLookupId: "4587",
    VDNumber: "97530284",
    RegisteredPopulation: "1750",
    VotingStationName: "VUSISIZWE SECONDARY SCHOOL",
    Address: "CONA AVENUE  ZWELETHEMBA  BREEDE VALLEY [WORCESTER]",
    Latitude: "-33.6499",
    Longitude: "19.4922",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22508",
    WardLookupId: "4588",
    VDNumber: "97530105",
    RegisteredPopulation: "639",
    VotingStationName: "WELTEVREDE NGK PRIMÃŠRE SKOOL",
    Address: "LOUWSHOEK, RAWSONVILLE  LOUWSHOEK  RAWSONVILLE",
    Latitude: "-33.7258",
    Longitude: "19.3472",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22509",
    WardLookupId: "4588",
    VDNumber: "97530116",
    RegisteredPopulation: "854",
    VotingStationName: "SKOOLPLAAS VOORSORG",
    Address: "VOORSORG  RAWSONVILLE  WORCESTER",
    Latitude: "-33.6949",
    Longitude: "19.352",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22510",
    WardLookupId: "4588",
    VDNumber: "97530127",
    RegisteredPopulation: "795",
    VotingStationName: "BRANDVLEI CELLAR",
    Address: "BRANDVLEI WINERY  DOORNRIVIER  WORCESTER",
    Latitude: "-33.8046",
    Longitude: "19.4695",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22511",
    WardLookupId: "4588",
    VDNumber: "97530138",
    RegisteredPopulation: "398",
    VotingStationName: "STETTYN HALL",
    Address: "STETTYN FARM  STETTYN  WORCESTER",
    Latitude: "-33.8707",
    Longitude: "19.3671",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22512",
    WardLookupId: "4588",
    VDNumber: "97530149",
    RegisteredPopulation: "403",
    VotingStationName: "DOORNRIVER STORE",
    Address: "DOORNRIVER  HAMMANSHOF  WORCESTER",
    Latitude: "-33.8927",
    Longitude: "19.4486",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22513",
    WardLookupId: "4588",
    VDNumber: "97530273",
    RegisteredPopulation: "349",
    VotingStationName: "LORRAINE PRIMARY SCHOOL",
    Address: "LORRAINE FARM  RAWSONVILLE  BREEDE VALLEY",
    Latitude: "-33.7074",
    Longitude: "19.2646",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22514",
    WardLookupId: "4588",
    VDNumber: "97530295",
    RegisteredPopulation: "280",
    VotingStationName: "BRANDVLEI CORRECTIONAL SERVICES",
    Address: "RAWSONVILLE ROAD  BREEDE VALLEY  WORCESTER",
    Latitude: "-33.7378",
    Longitude: "19.4146",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22515",
    WardLookupId: "4589",
    VDNumber: "97460017",
    RegisteredPopulation: "1574",
    VotingStationName: "GOUDINI HIGH SCHOOL",
    Address: "RIEBEECK  RAWSONVILLE  RAWSONVILLE",
    Latitude: "-33.688",
    Longitude: "19.3136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22516",
    WardLookupId: "4589",
    VDNumber: "97530071",
    RegisteredPopulation: "766",
    VotingStationName: "SLANGHOEK HALL",
    Address: "DRIEFONTEIN , SLANGHOEK  SLANGHOEK  RAWSONVILLE",
    Latitude: "-33.6043",
    Longitude: "19.2307",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22517",
    WardLookupId: "4589",
    VDNumber: "97530082",
    RegisteredPopulation: "1104",
    VotingStationName: "KABOUTERJIELAND CRECHE",
    Address: "MEULPLAAS  RAWSONVILLE  WORCESTER",
    Latitude: "-33.6647",
    Longitude: "19.3046",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22518",
    WardLookupId: "4590",
    VDNumber: "97520126",
    RegisteredPopulation: "2111",
    VotingStationName: "RAINBOW CRECHE",
    Address: "ALBATROS  AVIAN PARK  WORCESTER",
    Latitude: "-33.6679",
    Longitude: "19.439",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22519",
    WardLookupId: "4590",
    VDNumber: "97520317",
    RegisteredPopulation: "2738",
    VotingStationName: "AVIANPARK PRIMARY SCHOOL",
    Address: "ALBATROS  AVIANPARK  WORCESTER",
    Latitude: "-33.6689",
    Longitude: "19.4347",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22520",
    WardLookupId: "2731",
    VDNumber: "97470018",
    RegisteredPopulation: "2362",
    VotingStationName: "ROBERTSON CITY HALL",
    Address: "CHURCH STR  ROBERTSON  ROBERTSON",
    Latitude: "-33.8036",
    Longitude: "19.882",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22521",
    WardLookupId: "2731",
    VDNumber: "97470074",
    RegisteredPopulation: "1085",
    VotingStationName: "DE VILLIERS PRIMARY SCHOOL",
    Address: "LOOP STREET  ROBERTSON  BREEDE RIVER/WINELANDS [ROBERTSON]",
    Latitude: "-33.7946",
    Longitude: "19.8847",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22522",
    WardLookupId: "2732",
    VDNumber: "97470030",
    RegisteredPopulation: "3257",
    VotingStationName: "NKQUBELA COMMUNITY HALL",
    Address: "AUGUST STR  ROBERTSON  ROBERTSON",
    Latitude: "-33.8194",
    Longitude: "19.8959",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22523",
    WardLookupId: "2732",
    VDNumber: "97470063",
    RegisteredPopulation: "1530",
    VotingStationName: "ROBERTSON PRIMARY SCHOOL",
    Address: "DIRKIE UYS   ROBERTSON  ROBERTSON",
    Latitude: "-33.8054",
    Longitude: "19.8894",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22524",
    WardLookupId: "2733",
    VDNumber: "97470029",
    RegisteredPopulation: "1437",
    VotingStationName: "SCHOONGEZIG HALL",
    Address: "COETZEE STR  ROBERTSON  ROBERTSON",
    Latitude: "-33.7951",
    Longitude: "19.8925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22525",
    WardLookupId: "2733",
    VDNumber: "97470052",
    RegisteredPopulation: "2560",
    VotingStationName: "ROBERTSON COMMUNITY HALL",
    Address: "HOSPITAAL AVE  ROBERTSON  ROBERTSON",
    Latitude: "-33.79",
    Longitude: "19.8881",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22526",
    WardLookupId: "2734",
    VDNumber: "97890014",
    RegisteredPopulation: "2237",
    VotingStationName: "HAPPY VALLEY COMMUNITY HALL",
    Address: "NEW CROSS STREET  HAPPY VALLEY  BONNIEVALE",
    Latitude: "-33.9317",
    Longitude: "20.0709",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22527",
    WardLookupId: "2734",
    VDNumber: "97890036",
    RegisteredPopulation: "1192",
    VotingStationName: "BONNIEVALE INTEMEDIATE SCHOOL",
    Address: "NEW CROSS STREET  MOUNTAIN VIEW  BONNIEVALE",
    Latitude: "-33.9332",
    Longitude: "20.0706",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22528",
    WardLookupId: "2735",
    VDNumber: "97430014",
    RegisteredPopulation: "1848",
    VotingStationName: "COMMUNITY HALL MCGREGOR",
    Address: "VOORTREKKER  MCGREGOR  MCGREGOR",
    Latitude: "-33.9445",
    Longitude: "19.8319",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22529",
    WardLookupId: "2735",
    VDNumber: "97550107",
    RegisteredPopulation: "698",
    VotingStationName: "LE CHASSEUR DRC PRIMARY SCHOOL",
    Address:
      "LE CHASSEUR, WORCESTER ROAD  DANIE DE WET  LE CHASSEUR, ROBERTSON",
    Latitude: "-33.8643",
    Longitude: "19.7263",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22530",
    WardLookupId: "2735",
    VDNumber: "97550118",
    RegisteredPopulation: "347",
    VotingStationName: "WANSBEK HALL",
    Address: "LE CHASSEUR  AGTER KLIPHOOGTE  ROBERTSON",
    Latitude: "-33.9039",
    Longitude: "19.6656",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22531",
    WardLookupId: "2735",
    VDNumber: "97550141",
    RegisteredPopulation: "474",
    VotingStationName: "UITNOOD VGK HALL",
    Address: "OFF MCGREGOR ROAD  ROBERTSON  ROBERTSON",
    Latitude: "-33.8696",
    Longitude: "19.8839",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22532",
    WardLookupId: "2736",
    VDNumber: "97470041",
    RegisteredPopulation: "1716",
    VotingStationName: "VGK EAST CHURH HALL, ROBERTSON",
    Address: "PADDY STREET  DAGBREEK  ROBERTSON",
    Latitude: "-33.7903",
    Longitude: "19.8927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22533",
    WardLookupId: "2736",
    VDNumber: "97550073",
    RegisteredPopulation: "998",
    VotingStationName: "MADEBA HALL",
    Address: "GOREE/RIVERSIDE ROAD MADEBA   MADEBA  ROBERTSON",
    Latitude: "-33.8096",
    Longitude: "19.7811",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22534",
    WardLookupId: "2736",
    VDNumber: "97550084",
    RegisteredPopulation: "492",
    VotingStationName: "UITSIG KLEUTERSKOOL",
    Address: "DE HOOP  ROBERTSON",
    Latitude: "-33.7641",
    Longitude: "19.9338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22535",
    WardLookupId: "2736",
    VDNumber: "97550196",
    RegisteredPopulation: "385",
    VotingStationName: "LA COLLINE WAREHOUSE",
    Address: "LA COLLINE FARM R62  LA COLLINE FARM  ROBERTSON",
    Latitude: "-33.8041",
    Longitude: "19.8339",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22536",
    WardLookupId: "2737",
    VDNumber: "97440015",
    RegisteredPopulation: "1450",
    VotingStationName: "HOFMEYRSAAL",
    Address: "BATH STREET  MONTAGU  MONTAGU",
    Latitude: "-33.7875",
    Longitude: "20.1181",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22537",
    WardLookupId: "2737",
    VDNumber: "97440026",
    RegisteredPopulation: "1201",
    VotingStationName: "WA ROSSOUW PRIMARY SCHOOL",
    Address: "DU PREEZ STREET  MONTAGU  MONTAGU",
    Latitude: "-33.7795",
    Longitude: "20.124",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22538",
    WardLookupId: "2737",
    VDNumber: "97440048",
    RegisteredPopulation: "550",
    VotingStationName: "MONTAGU COMMUNITY HALL",
    Address: "WILHELM THYS AVE  FRESH AIR  MONTAGU",
    Latitude: "-33.7789",
    Longitude: "20.1284",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22539",
    WardLookupId: "2737",
    VDNumber: "97550028",
    RegisteredPopulation: "352",
    VotingStationName: "WARDIA PRIMARY SCHOOL",
    Address: "BARRYDALE ROAD  MONTAGU  MONTAGU",
    Latitude: "-33.8825",
    Longitude: "20.3099",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22540",
    WardLookupId: "2737",
    VDNumber: "97550039",
    RegisteredPopulation: "422",
    VotingStationName: "AF KRIEL PRIMARY",
    Address: "DERDE HEUWEL  MONTAGU  MONTAGU",
    Latitude: "-33.8135",
    Longitude: "20.1933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22541",
    WardLookupId: "2738",
    VDNumber: "97550017",
    RegisteredPopulation: "542",
    VotingStationName: "WAKKERSTROOM-WES PRIMÃŠRE SKOOL",
    Address: "WAKKERSTROOM   BONNIEVALE  BONNIEVALE",
    Latitude: "-33.9168",
    Longitude: "19.9912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22542",
    WardLookupId: "2738",
    VDNumber: "97550095",
    RegisteredPopulation: "660",
    VotingStationName: "BOESMANSRIVIER PRIMARY SCHOOL",
    Address: "BOESMANSRIVIER FARM  BONNIEVALE  BONNIEVALE",
    Latitude: "-33.9788",
    Longitude: "20.0201",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22543",
    WardLookupId: "2738",
    VDNumber: "97550130",
    RegisteredPopulation: "670",
    VotingStationName: "DREW COMMUNITY HALL",
    Address: "DREW ROAD  DREW  BONNIEVALE",
    Latitude: "-33.9932",
    Longitude: "20.2141",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22544",
    WardLookupId: "2738",
    VDNumber: "97550174",
    RegisteredPopulation: "379",
    VotingStationName: "WELLVILLE PRIMARY SCHOOL",
    Address: "GELUKSHOOP FARM  BONNIEVALE  BONNIEVALE",
    Latitude: "-33.986",
    Longitude: "20.1714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22545",
    WardLookupId: "2738",
    VDNumber: "97890025",
    RegisteredPopulation: "1164",
    VotingStationName: "CHRIS VAN ZYL HALL",
    Address: "VOORTREKKER STREET  BONNIEVALE  BONNIEVALE",
    Latitude: "-33.927",
    Longitude: "20.1029",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22546",
    WardLookupId: "2739",
    VDNumber: "97400011",
    RegisteredPopulation: "640",
    VotingStationName: "ASHTON TOWN HALL",
    Address: "SWART STREET  CONRADIEDORP  ASHTON",
    Latitude: "-33.832",
    Longitude: "20.0557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22547",
    WardLookupId: "2739",
    VDNumber: "97400033",
    RegisteredPopulation: "2554",
    VotingStationName: "BARNARD HALL",
    Address: "UITSPAN STREET  COGMANSKLOOF  ASHTON",
    Latitude: "-33.8321",
    Longitude: "20.0497",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22548",
    WardLookupId: "2739",
    VDNumber: "97550185",
    RegisteredPopulation: "354",
    VotingStationName: "KLAAS VOOGDS PRIMARY SCHOOL",
    Address: "KLAAS VOOGDS EAST ROAD  ASHTON  ROBERTSON",
    Latitude: "-33.8018",
    Longitude: "19.9904",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22549",
    WardLookupId: "2740",
    VDNumber: "97400022",
    RegisteredPopulation: "3062",
    VotingStationName: "ROLIHLAHLA COMMUNITY HALL",
    Address: "BUILDING  ZOLANI  ASHTON",
    Latitude: "-33.8372",
    Longitude: "20.0869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22550",
    WardLookupId: "2741",
    VDNumber: "97440059",
    RegisteredPopulation: "493",
    VotingStationName: "MONTAGU HIGH SCHOOL",
    Address: "2 KOHLER STREET  MONTAGU SOUTH  MONTAGU",
    Latitude: "-33.7935",
    Longitude: "20.1231",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22551",
    WardLookupId: "2741",
    VDNumber: "97550152",
    RegisteredPopulation: "880",
    VotingStationName: "PROSPECT PRIMARY SCHOOL",
    Address: "EXCELSIOR FARM  ASHTON  ROBERTSON",
    Latitude: "-33.8496",
    Longitude: "20.014",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22552",
    WardLookupId: "2741",
    VDNumber: "97550163",
    RegisteredPopulation: "1240",
    VotingStationName: "GOUDMYN HALL",
    Address: "GOUDMYN  ROBERTSON",
    Latitude: "-33.866",
    Longitude: "19.9916",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22553",
    WardLookupId: "2742",
    VDNumber: "97440037",
    RegisteredPopulation: "2874",
    VotingStationName: "KABOUTERLAND KLEUTERSKOOL",
    Address: "MIMOSA AVE  ASHBURY  MONTAGU",
    Latitude: "-33.7668",
    Longitude: "20.1463",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22554",
    WardLookupId: "2742",
    VDNumber: "97550040",
    RegisteredPopulation: "681",
    VotingStationName: "KOO GEMEENSKAPSAAL",
    Address: "KOO AREA  KOO  MONTAGU",
    Latitude: "-33.688",
    Longitude: "19.8452",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22555",
    WardLookupId: "2742",
    VDNumber: "97550051",
    RegisteredPopulation: "650",
    VotingStationName: "KEISIE PRIMARY SCHOOL",
    Address: "MONTAGU  KEISIE  MONTAGU",
    Latitude: "-33.7015",
    Longitude: "20.0077",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22556",
    WardLookupId: "2742",
    VDNumber: "97550062",
    RegisteredPopulation: "72",
    VotingStationName: "HOEK VAN DIE BERG CHURCH",
    Address: "HOEK VAN DIE BERG  OUBERG  MONTAGU",
    Latitude: "-33.6135",
    Longitude: "20.3103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22557",
    WardLookupId: "2743",
    VDNumber: "98010019",
    RegisteredPopulation: "1477",
    VotingStationName: "RIVIERSONDEREND NG KERKSAAL",
    Address: "7 VOORTREKKER WEG  RIVIERSONDEREND  RIVIERSONDEREND",
    Latitude: "-34.1494",
    Longitude: "19.9136",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22558",
    WardLookupId: "2743",
    VDNumber: "98010020",
    RegisteredPopulation: "2122",
    VotingStationName: "RIVIERSONDEREND COMMUNITY HALL",
    Address: "26 LELIE WEG  OOSTERGLOED  RIVIERSONDEREND",
    Latitude: "-34.1485",
    Longitude: "19.925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22559",
    WardLookupId: "2743",
    VDNumber: "98080027",
    RegisteredPopulation: "439",
    VotingStationName: "BLOEMENHOF PRIMARY SCHOOL",
    Address: "GREYTON ROAD  BLOEMENHOF FARM  RIVIERSONDEREND",
    Latitude: "-34.1268",
    Longitude: "19.8212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22560",
    WardLookupId: "2743",
    VDNumber: "98080038",
    RegisteredPopulation: "508",
    VotingStationName: "JONGENSKLIP PRIMÃŠRE SKOOL",
    Address: "JONGENSKLIP ROAD  JONGENSKLIP FARM  CALEDON",
    Latitude: "-34.266",
    Longitude: "19.6528",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22561",
    WardLookupId: "2744",
    VDNumber: "97940010",
    RegisteredPopulation: "1620",
    VotingStationName: "EMIL WEDER SECONDARY SCHOOL HALL",
    Address: "16 SKOOLRYLAAN  GENADENDAL  GENADENDAL",
    Latitude: "-34.0327",
    Longitude: "19.5598",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22562",
    WardLookupId: "2744",
    VDNumber: "97940021",
    RegisteredPopulation: "882",
    VotingStationName: "VOORSTEKRAAL MORAWIESE KERKSAAL",
    Address: "35 DISALAAN  VOORSTEKRAAL  GENADENDAL",
    Latitude: "-34.0465",
    Longitude: "19.5387",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22563",
    WardLookupId: "2744",
    VDNumber: "97970013",
    RegisteredPopulation: "1726",
    VotingStationName: "OLD MORAVIAN HALL",
    Address: "6 MAIN ROAD  GREYTON  GREYTON",
    Latitude: "-34.0523",
    Longitude: "19.6047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22564",
    WardLookupId: "2744",
    VDNumber: "98080050",
    RegisteredPopulation: "278",
    VotingStationName: "MIDDELPLAAS HALL",
    Address: "MIDDELPLAAS ROAD  MIDDELPLAAS FARM  CALEDON",
    Latitude: "-34.0666",
    Longitude: "19.4531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22565",
    WardLookupId: "2745",
    VDNumber: "97920018",
    RegisteredPopulation: "1248",
    VotingStationName: "VLEI VIEW MUNICIPAL HALL",
    Address: "C/O 1ST AVENUE & CHARTER STREE  VLEI VIEW  CALEDON",
    Latitude: "-34.2431",
    Longitude: "19.4323",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22566",
    WardLookupId: "2745",
    VDNumber: "97920029",
    RegisteredPopulation: "1719",
    VotingStationName: "CALEDON VG KERKSAAL",
    Address: "HOOFWEG  BERGSIG  CALEDON",
    Latitude: "-34.2449",
    Longitude: "19.4378",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22567",
    WardLookupId: "2745",
    VDNumber: "97920041",
    RegisteredPopulation: "420",
    VotingStationName: "MIDDLETON GEMEENSKAPSAAL",
    Address: "226 BERGSTRAAT  MYDDLETON  CALEDON",
    Latitude: "-34.2221",
    Longitude: "19.4579",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22568",
    WardLookupId: "2745",
    VDNumber: "98080061",
    RegisteredPopulation: "679",
    VotingStationName: "TESSELAARSDAL COMMUNITY HALL",
    Address: "TESSELAARSDAL  CALEDON",
    Latitude: "-34.3758",
    Longitude: "19.5259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22569",
    WardLookupId: "2746",
    VDNumber: "97920052",
    RegisteredPopulation: "2648",
    VotingStationName: "UITSIG CRECHE",
    Address: "56 REIERLAAN  UITSIG  CALEDON",
    Latitude: "-34.2448",
    Longitude: "19.4209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22570",
    WardLookupId: "2746",
    VDNumber: "98080049",
    RegisteredPopulation: "2301",
    VotingStationName: "CALEDON TOWN HALL",
    Address: "19 CHURCH STREET  CALEDON  CALEDON",
    Latitude: "-34.2321",
    Longitude: "19.4294",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22571",
    WardLookupId: "2746",
    VDNumber: "98080072",
    RegisteredPopulation: "207",
    VotingStationName: "DIEPGAT PRIMÃŠRE SKOOL",
    Address: "HEMEL-EN-AARDE ROAD   DIEPGAT FARM  CALEDON",
    Latitude: "-34.3383",
    Longitude: "19.3499",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22572",
    WardLookupId: "2747",
    VDNumber: "98060014",
    RegisteredPopulation: "1927",
    VotingStationName: "VILLIERSDORP BIBLIOTEEKSAAL",
    Address: "MAIN ROAD  VILLIERSDORP  VILLIERSDORP",
    Latitude: "-33.9892",
    Longitude: "19.2925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22573",
    WardLookupId: "2747",
    VDNumber: "98080083",
    RegisteredPopulation: "794",
    VotingStationName: "HELDERSTROOM CORRECTIONAL HALL",
    Address: "HELDERSTROOM  CALEDON",
    Latitude: "-34.0672",
    Longitude: "19.3696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22574",
    WardLookupId: "2747",
    VDNumber: "98100110",
    RegisteredPopulation: "855",
    VotingStationName: "HIGHNOON HALL",
    Address: "KAAIMANSGAT ROAD  HIGHNOON FARM  VILLIERSDORP",
    Latitude: "-33.9198",
    Longitude: "19.2782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22575",
    WardLookupId: "2747",
    VDNumber: "98100132",
    RegisteredPopulation: "529",
    VotingStationName: "BO-RADYN SPORTS HALL VILLIERSDORP",
    Address: "KAAIMANSGAT ROAD  BO-RADYN FARM  VILLIERSDORP",
    Latitude: "-33.9695",
    Longitude: "19.2992",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22576",
    WardLookupId: "2748",
    VDNumber: "98060025",
    RegisteredPopulation: "3919",
    VotingStationName: "KOSIE DE WET PRIMARY",
    Address: "6 BUITEKANT  NUWEDORP  VILLIERSDORP",
    Latitude: "-33.9915",
    Longitude: "19.2859",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22577",
    WardLookupId: "2748",
    VDNumber: "98100143",
    RegisteredPopulation: "790",
    VotingStationName: "BENOOKE HALL",
    Address: "VYEBOOM ROAD  BENOOKE FARM  VILLIERSDORP",
    Latitude: "-34.004",
    Longitude: "19.2774",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22578",
    WardLookupId: "2749",
    VDNumber: "97900016",
    RegisteredPopulation: "3360",
    VotingStationName: "BOTRIVIER PRIMARY SCHOOL",
    Address: "PARKSTRAAT  BOTRIVIER  BOTRIVIER",
    Latitude: "-34.2323",
    Longitude: "19.1969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22579",
    WardLookupId: "2749",
    VDNumber: "98100187",
    RegisteredPopulation: "620",
    VotingStationName: "DE RUST FUTURA ACADEMY HALL",
    Address: "KROMCO ROAD  DE RUST FARM  GRABOUW",
    Latitude: "-34.1768",
    Longitude: "19.0782",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22580",
    WardLookupId: "2750",
    VDNumber: "97950011",
    RegisteredPopulation: "3780",
    VotingStationName: "ROOIDAKKE COMMUNITY HALL",
    Address: "PINEVIEW  GRABOUW",
    Latitude: "-34.1469",
    Longitude: "18.9958",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22581",
    WardLookupId: "2750",
    VDNumber: "97950088",
    RegisteredPopulation: "2377",
    VotingStationName: "STEENBRAS HALL",
    Address: "127 STEENBRAS STREET  PINEVIEW  GRABOUW",
    Latitude: "-34.1541",
    Longitude: "18.9936",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22582",
    WardLookupId: "2750",
    VDNumber: "98100176",
    RegisteredPopulation: "300",
    VotingStationName: "ELGIN HOMES HALL",
    Address: "281 INDUSTRIAL RD  GRABOUW  JAGERS VLAKTE",
    Latitude: "-34.1439",
    Longitude: "19.0022",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22583",
    WardLookupId: "2751",
    VDNumber: "98100053",
    RegisteredPopulation: "1004",
    VotingStationName: "GRAYMEAD COMMUNITY HALL",
    Address: "GRAYMEAD FARM  VYEBOOM  VYEBOOM",
    Latitude: "-34.0298",
    Longitude: "19.125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22584",
    WardLookupId: "2751",
    VDNumber: "98100086",
    RegisteredPopulation: "492",
    VotingStationName: "DIE ERF COMMUNITY HALL",
    Address: "VYEBOOM PAD  VYEBOOM  VYEBOOM",
    Latitude: "-34.0664",
    Longitude: "19.1096",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22585",
    WardLookupId: "2751",
    VDNumber: "98100109",
    RegisteredPopulation: "935",
    VotingStationName: "VYEBOOM KERKSAAL",
    Address: "VYEBOOM PAD  VYEBOOM  VYEBOOM",
    Latitude: "-34.0681",
    Longitude: "19.1089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22586",
    WardLookupId: "2751",
    VDNumber: "98100121",
    RegisteredPopulation: "190",
    VotingStationName: "HOTTENTOTSHOLLAND HALL",
    Address: "VYEBOOM ROAD  ELGIN  GRABOUW",
    Latitude: "-34.0732",
    Longitude: "19.0501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22587",
    WardLookupId: "2751",
    VDNumber: "98100154",
    RegisteredPopulation: "599",
    VotingStationName: "EERSTEHOOP KERKSAAL",
    Address: "BOTRIVIER ROAD  EERSTEHOOP FARM  VILLIERSDORP",
    Latitude: "-34.0956",
    Longitude: "19.1977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22588",
    WardLookupId: "2752",
    VDNumber: "98100019",
    RegisteredPopulation: "659",
    VotingStationName: "DENNEGEUR PRIMÃŠRE SKOOL",
    Address: "APPLETISER PAD  ELGIN  GRABOUW",
    Latitude: "-34.1918",
    Longitude: "19.0106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22589",
    WardLookupId: "2752",
    VDNumber: "98100042",
    RegisteredPopulation: "516",
    VotingStationName: "WHITEHALL FARMS COMMUNITY HALL",
    Address: "VALLEY ROAD  ELGIN  GRABOUW",
    Latitude: "-34.2343",
    Longitude: "19.1047",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22590",
    WardLookupId: "2752",
    VDNumber: "98100064",
    RegisteredPopulation: "751",
    VotingStationName: "MONTEITH PRIMARY SCHOOL",
    Address: "HIGHLANDS ROAD  ELGIN  GRABOUW",
    Latitude: "-34.265",
    Longitude: "19.0544",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22591",
    WardLookupId: "2752",
    VDNumber: "98100075",
    RegisteredPopulation: "645",
    VotingStationName: "FINE FARMS COMMUNITY HALL",
    Address: "FARM 64 HIGHLANDS ROAD  ELGIN  GRABOUW",
    Latitude: "-34.2116",
    Longitude: "19.0699",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22592",
    WardLookupId: "2752",
    VDNumber: "98100198",
    RegisteredPopulation: "465",
    VotingStationName: "ARIESKRAAL PRIMARY SCHOOL",
    Address: "VILJOENSHOOP ROAD  ELGIN  GRABOUW",
    Latitude: "-34.2132",
    Longitude: "19.0283",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22593",
    WardLookupId: "2753",
    VDNumber: "97950033",
    RegisteredPopulation: "3509",
    VotingStationName: "GERALD WRIGHT HALL",
    Address: "OLD CAPE ROAD  PINEVIEW  GRABOUW",
    Latitude: "-34.1535",
    Longitude: "19.0053",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22594",
    WardLookupId: "2754",
    VDNumber: "97950055",
    RegisteredPopulation: "2844",
    VotingStationName: "SOULFOOD HARMONY HALL",
    Address: "2076 JAN VAN ECK  SNAKE PARK  GRABOUW",
    Latitude: "-34.1597",
    Longitude: "18.9923",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22595",
    WardLookupId: "2754",
    VDNumber: "98100020",
    RegisteredPopulation: "418",
    VotingStationName: "APPLETHWAITE FARM COMMUNITY HALL",
    Address: "APPLETIZER ROAD  APPLETHWAITE VILLAGE  GRABOUW RURAL",
    Latitude: "-34.1864",
    Longitude: "19.0035",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22596",
    WardLookupId: "2754",
    VDNumber: "98100165",
    RegisteredPopulation: "306",
    VotingStationName: "ST MICHAELS PRIMARY SCHOOL",
    Address: "OUDEBRUG ROAD,  OUDEBRUG FARM  GRABOUW",
    Latitude: "-34.1721",
    Longitude: "18.9996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22597",
    WardLookupId: "2755",
    VDNumber: "97950077",
    RegisteredPopulation: "4196",
    VotingStationName: "SITEVIEW MAKAZA HALL",
    Address: "ERF 4261 PAKAMILE MAKAZA STREET  SITEVIEW  GRABOUW",
    Latitude: "-34.1637",
    Longitude: "18.9937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22598",
    WardLookupId: "2756",
    VDNumber: "97950022",
    RegisteredPopulation: "1096",
    VotingStationName: "GROENBERG SECONDARY SCHOOL HALL",
    Address: "ADAMS STREET  PINEVIEW  GRABOUW",
    Latitude: "-34.1574",
    Longitude: "18.9996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22599",
    WardLookupId: "2756",
    VDNumber: "97950044",
    RegisteredPopulation: "1096",
    VotingStationName: "GRABOUW RUGBY SPORTS HALL",
    Address: "MIMOSA STRAAT  GRABOUW  GRABOUW",
    Latitude: "-34.1571",
    Longitude: "19.0111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22600",
    WardLookupId: "2756",
    VDNumber: "97950066",
    RegisteredPopulation: "535",
    VotingStationName: "GRABOUW LIBRARY HALL",
    Address: "5 RYKE STREET  GRABOUW  GRABOUW",
    Latitude: "-34.1508",
    Longitude: "19.0227",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22601",
    WardLookupId: "2756",
    VDNumber: "98100097",
    RegisteredPopulation: "873",
    VotingStationName: "GLEN ELGIN SPORTS CLUB",
    Address: "VYEBOOM ROAD  THE OAKS VILLAGE  GRABOUW",
    Latitude: "-34.1495",
    Longitude: "19.0421",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22602",
    WardLookupId: "2757",
    VDNumber: "97930042",
    RegisteredPopulation: "3305",
    VotingStationName: "MASAKHANE ECD",
    Address: "32 MANDELA ROAD  MASAKHANE  GANSBAAI",
    Latitude: "-34.5917",
    Longitude: "19.3588",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22603",
    WardLookupId: "2757",
    VDNumber: "97930064",
    RegisteredPopulation: "1362",
    VotingStationName: "FRANSKRAAL BOWLS CLUB",
    Address: "CNR NAUDE & VEAL STREETS  GANSBAAI  GANSBAAI",
    Latitude: "-34.6115",
    Longitude: "19.3835",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22604",
    WardLookupId: "2758",
    VDNumber: "97930019",
    RegisteredPopulation: "2451",
    VotingStationName: "PRETORIUS HALL GANSBAAI",
    Address: "20 MAIN STREET  GANSBAAI  GANSBAAI",
    Latitude: "-34.5808",
    Longitude: "19.3513",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22605",
    WardLookupId: "2758",
    VDNumber: "97930020",
    RegisteredPopulation: "850",
    VotingStationName: "GANSBAAI GOLF CLUB",
    Address: "212 PERLEMOEN STREET  KLEINBAAI  GANSBAAI",
    Latitude: "-34.6185",
    Longitude: "19.3487",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22606",
    WardLookupId: "2758",
    VDNumber: "97930053",
    RegisteredPopulation: "1522",
    VotingStationName: "BLOMPARK COMMUNITY HALL",
    Address: "3190 VIOOLTJIE  GANSBAAI  GANSBAAI",
    Latitude: "-34.5993",
    Longitude: "19.3466",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22607",
    WardLookupId: "2759",
    VDNumber: "97960056",
    RegisteredPopulation: "2741",
    VotingStationName: "HERMANUS MUNICIPAL BUILDINGS",
    Address: "9 MAGNOLIA  AVENUE  HERMANUS  HERMANUS",
    Latitude: "-34.4175",
    Longitude: "19.2381",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22608",
    WardLookupId: "2759",
    VDNumber: "97960113",
    RegisteredPopulation: "1361",
    VotingStationName: "FERNKLOOF NATURE RESERVE",
    Address: "HERMANES  ",
    Latitude: "-34.398",
    Longitude: "19.2664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22609",
    WardLookupId: "2760",
    VDNumber: "97960045",
    RegisteredPopulation: "1018",
    VotingStationName: "MUNICIPAL TRAFFIC DEPARTMENT",
    Address: "C/O KORT STREET & MUSSEL ROAD  INDUSTRIA  HERMANUS",
    Latitude: "-34.4204",
    Longitude: "19.2264",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22610",
    WardLookupId: "2760",
    VDNumber: "97960067",
    RegisteredPopulation: "2477",
    VotingStationName: "MOFFAT HALL",
    Address: "64 DAHLIA STREET  MOUNT PLEASANT  HERMANUS",
    Latitude: "-34.4199",
    Longitude: "19.2134",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22611",
    WardLookupId: "2760",
    VDNumber: "98090017",
    RegisteredPopulation: "378",
    VotingStationName: "ERTJIESVLEI KERKSAAL",
    Address: "HEMEL EN AARDE  ERTJIESVLEI  HERMANUS RURAL",
    Latitude: "-34.351",
    Longitude: "19.286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22612",
    WardLookupId: "2761",
    VDNumber: "97960034",
    RegisteredPopulation: "3264",
    VotingStationName: "QHAYIYA SECONDARY SCHOOL",
    Address: "HLOBO AVENUE  ZWELIHLE  HERMANUS",
    Latitude: "-34.4283",
    Longitude: "19.2151",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22613",
    WardLookupId: "2761",
    VDNumber: "97960135",
    RegisteredPopulation: "1640",
    VotingStationName: "HOU MOED CENTRE",
    Address: "8937 STEVE BIKO STREET  ZWELIHLE  HERMANUS",
    Latitude: "-34.4269",
    Longitude: "19.2178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22614",
    WardLookupId: "2762",
    VDNumber: "97960078",
    RegisteredPopulation: "2058",
    VotingStationName: "LUKHANYO PRIMARY SCHOOL",
    Address: "219 LUSIBA STREET  ZWELIHLE  HERMANUS",
    Latitude: "-34.4309",
    Longitude: "19.2114",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22615",
    WardLookupId: "2762",
    VDNumber: "97960146",
    RegisteredPopulation: "2427",
    VotingStationName: "ZWELIHLE COMMUNITY HALL",
    Address: "LUSIBA STREET  ZWELIHLE  HERMANUS",
    Latitude: "-34.4311",
    Longitude: "19.2155",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22616",
    WardLookupId: "2763",
    VDNumber: "97960023",
    RegisteredPopulation: "4175",
    VotingStationName: "SANDBAAI HALL",
    Address: "CNR JIMMY SMITH & MAIN ROAD  SANDBAAI  SANDBAAI",
    Latitude: "-34.4228",
    Longitude: "19.1974",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22617",
    WardLookupId: "2764",
    VDNumber: "97960012",
    RegisteredPopulation: "3766",
    VotingStationName: "HAWSTON SECONDARY SCHOOL",
    Address: "CHURCH STREET  HAWSTON  HAWSTON",
    Latitude: "-34.3795",
    Longitude: "19.13",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22618",
    WardLookupId: "2764",
    VDNumber: "97960124",
    RegisteredPopulation: "665",
    VotingStationName: "LAKE MARINA YACHT AND BOAT CLUB",
    Address: "ERF 775 RIVERSIDE DRIVE  FISHERHAVEN  OVERSTRAND",
    Latitude: "-34.3554",
    Longitude: "19.1222",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22619",
    WardLookupId: "2764",
    VDNumber: "98080094",
    RegisteredPopulation: "201",
    VotingStationName: "HONINGKLIP FARM",
    Address: "R43 HERMANUS ROAD  KLEINMOND  KLEINMOND",
    Latitude: "-34.2861",
    Longitude: "19.1448",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22620",
    WardLookupId: "2765",
    VDNumber: "97980014",
    RegisteredPopulation: "2812",
    VotingStationName: "KLEINMOND TOWN HALL",
    Address: "4294 MAIN ROAD  KLEINMOND  KLEINMOND",
    Latitude: "-34.3404",
    Longitude: "19.032",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22621",
    WardLookupId: "2765",
    VDNumber: "97980058",
    RegisteredPopulation: "880",
    VotingStationName: "MTHIMKHULU CENTRE",
    Address: "CORNER OF 11TH ST AND 9TH AVE  KLEINMOND  KLEINMOND",
    Latitude: "-34.3391",
    Longitude: "19.019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22622",
    WardLookupId: "2766",
    VDNumber: "97980025",
    RegisteredPopulation: "2550",
    VotingStationName: "CRASSULA COMMUNITY HALL BETTYS BAY",
    Address: "2416 CRASSULA AVENUE  BETTYS BAY  BETTYS BAY",
    Latitude: "-34.3675",
    Longitude: "18.8912",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22623",
    WardLookupId: "2766",
    VDNumber: "97980036",
    RegisteredPopulation: "2468",
    VotingStationName: "COMMUNITY HALL PROTEADORP KLEINMOND",
    Address: "PROTEA ROAD  PROTEADORP  OVERSTRAND [GREATER HERMANUS]",
    Latitude: "-34.34",
    Longitude: "19.0161",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22624",
    WardLookupId: "2767",
    VDNumber: "97930031",
    RegisteredPopulation: "1155",
    VotingStationName: "PEARLY BEACH RECREATION HALL",
    Address: "1780 MAIN STREET  PEARLY BEACH  GANSBAAI",
    Latitude: "-34.6666",
    Longitude: "19.5079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22625",
    WardLookupId: "2767",
    VDNumber: "98020010",
    RegisteredPopulation: "2942",
    VotingStationName: "STANFORD COMMUNITY HALL (DIE STOOR)",
    Address: "BEZUIDENHOUT STREET  STANFORD  STANFORD",
    Latitude: "-34.4449",
    Longitude: "19.4538",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22626",
    WardLookupId: "2767",
    VDNumber: "98070026",
    RegisteredPopulation: "389",
    VotingStationName: "BAARDSKEEDERSBOS COMMUNITY HALL",
    Address: "201/213 SUB DIVISION  GANSBAAI  BAARDSKEEDERSBOS",
    Latitude: "-34.5912",
    Longitude: "19.5718",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22627",
    WardLookupId: "2767",
    VDNumber: "98070093",
    RegisteredPopulation: "193",
    VotingStationName: "VILJOENSHOF NG KERK",
    Address: "VILJOENSHOF  WOLWEGAT",
    Latitude: "-34.6714",
    Longitude: "19.6969",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22628",
    WardLookupId: "2767",
    VDNumber: "98090039",
    RegisteredPopulation: "212",
    VotingStationName: "ST PAULS SKOOL (WITHOOGTE)",
    Address: "PAVIESVLEI PAD  STANFORD DISTRICT  HERMANUS RURAL",
    Latitude: "-34.4879",
    Longitude: "19.5996",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22629",
    WardLookupId: "2768",
    VDNumber: "97960089",
    RegisteredPopulation: "3828",
    VotingStationName: "ZWELIHLE PRIMARY SCHOOL",
    Address: "9720 SISULU STREET  ZWELIHLE  HERMANUS",
    Latitude: "-34.4238",
    Longitude: "19.2079",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22630",
    WardLookupId: "2769",
    VDNumber: "97960090",
    RegisteredPopulation: "2834",
    VotingStationName: "DE WET HALL",
    Address: "ROOS STREET  ONRUS  OVERSTRAND [GREATER HERMANUS]",
    Latitude: "-34.4138",
    Longitude: "19.1733",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22631",
    WardLookupId: "2769",
    VDNumber: "98090040",
    RegisteredPopulation: "1891",
    VotingStationName: "AMANA CONFERENCE CENTRE",
    Address: "939 R43 VERMONT  VERMONT  VERMONT",
    Latitude: "-34.4086",
    Longitude: "19.1606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22632",
    WardLookupId: "2770",
    VDNumber: "98000018",
    RegisteredPopulation: "2854",
    VotingStationName: "NAPIER COMMUNITY HALL",
    Address: "3 VOLHOU STREET  NAPIER  NAPIER",
    Latitude: "-34.4701",
    Longitude: "19.8876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22633",
    WardLookupId: "2770",
    VDNumber: "98070060",
    RegisteredPopulation: "777",
    VotingStationName: "THE WERF",
    Address:
      "1A CHURCH STREET, ELIM  ELIM, BREDASDORP  CAPE AGULHAS MUNICIPALITY",
    Latitude: "-34.5924",
    Longitude: "19.7591",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22634",
    WardLookupId: "2770",
    VDNumber: "98070105",
    RegisteredPopulation: "141",
    VotingStationName: "SPANJAARDSKLOOF",
    Address:
      "TAKE ELIM ROAD TO NAPIER   SPANJAARDSKLOOF, ELIM RURAL  CAPE AGULHAS MUNICIPALITY",
    Latitude: "-34.5431",
    Longitude: "19.7475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22635",
    WardLookupId: "2770",
    VDNumber: "98070116",
    RegisteredPopulation: "79",
    VotingStationName: "HOUTKLOOF MORAVIAN PRIMARY SCHOOL",
    Address: "ON R316 TO CALEDON  NAPIER RURAL  CAPE AGULHAS MUNICIPALITY",
    Latitude: "-34.3587",
    Longitude: "19.758",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22636",
    WardLookupId: "2771",
    VDNumber: "97910051",
    RegisteredPopulation: "1620",
    VotingStationName: "DE HEIDE PRIMARY SCHOOL",
    Address: "RECREATION STREET  BREDASDORP  BREDASDORP",
    Latitude: "-34.5312",
    Longitude: "20.0512",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22637",
    WardLookupId: "2771",
    VDNumber: "97910062",
    RegisteredPopulation: "1075",
    VotingStationName: "METROPOLITAN CHURCH HALL",
    Address: "104 GOLF STREET  BREDASDORP  CAPE AGULHAS [BREDASDORP]",
    Latitude: "-34.5339",
    Longitude: "20.0569",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22638",
    WardLookupId: "2771",
    VDNumber: "98070071",
    RegisteredPopulation: "531",
    VotingStationName: "KLIPDALE COMMUNITY HALL",
    Address: "KLIPDALE - R317  KLIPDALE  KLIPDALE",
    Latitude: "-34.3017",
    Longitude: "19.9754",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22639",
    WardLookupId: "2772",
    VDNumber: "97910040",
    RegisteredPopulation: "2420",
    VotingStationName: "NELSON MANDELA COMMUNITY HALL",
    Address: "BASTIAN STREET  KLEINBEGIN  BREDASDORP",
    Latitude: "-34.5266",
    Longitude: "20.0594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22640",
    WardLookupId: "2772",
    VDNumber: "97910084",
    RegisteredPopulation: "1554",
    VotingStationName: "LESEDI EDUCARE",
    Address: "00 ALBERTINA SISULU STR  BREDASDORP  BREDASDORP",
    Latitude: "-34.5238",
    Longitude: "20.0606",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22641",
    WardLookupId: "2773",
    VDNumber: "97910039",
    RegisteredPopulation: "2769",
    VotingStationName: "COMMUNITY HALL",
    Address: "RECREATION STREET(R316/R319)  BREDASDORP  BREDASDORP",
    Latitude: "-34.54",
    Longitude: "20.0469",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22642",
    WardLookupId: "2773",
    VDNumber: "98070048",
    RegisteredPopulation: "424",
    VotingStationName: "PROTEM COMMUNITY HALL",
    Address: "STORMSVLEI TURN OFF R317  PROTEM  BREDASDORP",
    Latitude: "-34.2688",
    Longitude: "20.0769",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22643",
    WardLookupId: "2773",
    VDNumber: "98070082",
    RegisteredPopulation: "215",
    VotingStationName: "VAN DER STELSKRAAL",
    Address:
      "VIA R319 TO DE HOOP NATURE RES  BREDASDORP RURAL  CAPE AGULHAS MUNICIPALITY",
    Latitude: "-34.4172",
    Longitude: "20.2398",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22644",
    WardLookupId: "2774",
    VDNumber: "97910017",
    RegisteredPopulation: "1828",
    VotingStationName: "SUIDPUNT DUTCH REFORMED CHURCH HALL",
    Address: "00 MAIN ROAD  STRUISBAY  STRUISBAY",
    Latitude: "-34.7999",
    Longitude: "20.0491",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22645",
    WardLookupId: "2774",
    VDNumber: "98030011",
    RegisteredPopulation: "1729",
    VotingStationName: "STRUISBAAI GEMEENSKAPSAAL",
    Address: "21 SEVENTH AVENUE  STRUISBAAI NOORD  STRUISBAAI",
    Latitude: "-34.7781",
    Longitude: "20.0337",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22646",
    WardLookupId: "2774",
    VDNumber: "98070059",
    RegisteredPopulation: "160",
    VotingStationName: "MODDERVLEI BOERDERY",
    Address:
      "BREDASDORP RD, R43  ELIM RURAL, BREDASDORP  CAPE AGULHAS MUNICIPALITY",
    Latitude: "-34.6355",
    Longitude: "19.831",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22647",
    WardLookupId: "2775",
    VDNumber: "97910028",
    RegisteredPopulation: "968",
    VotingStationName: "ARNISTON COMMUNITY HALL",
    Address: "41 CAMP STREET  ARNISTON  ARNISTON",
    Latitude: "-34.662",
    Longitude: "20.229",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22648",
    WardLookupId: "2775",
    VDNumber: "97910073",
    RegisteredPopulation: "1291",
    VotingStationName: "NEW LIFE MISSION CHURCH",
    Address: "LAKEY  BREDASDORP  BREDASDORP",
    Latitude: "-34.5318",
    Longitude: "20.0597",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22649",
    WardLookupId: "4591",
    VDNumber: "98050035",
    RegisteredPopulation: "2340",
    VotingStationName: "SWELLENDAM HIGH SCHOOL",
    Address: "1 AANHUIZEN STREET  SWELLENDAM  SWELLENDAM",
    Latitude: "-34.0224",
    Longitude: "20.4365",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22650",
    WardLookupId: "4591",
    VDNumber: "98110010",
    RegisteredPopulation: "279",
    VotingStationName: "STORMSVLEI RESTAURANT",
    Address: "R317  STORMSVLEI  SWELLENDAM",
    Latitude: "-34.0835",
    Longitude: "20.0976",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22651",
    WardLookupId: "4591",
    VDNumber: "98110021",
    RegisteredPopulation: "263",
    VotingStationName: "KLUITJIESKRAAL",
    Address: "KLUITJIESKRAAL  SWELLENDAM RURAL",
    Latitude: "-34.156",
    Longitude: "20.3726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22652",
    WardLookupId: "4591",
    VDNumber: "98110087",
    RegisteredPopulation: "290",
    VotingStationName: "RHEENENDAL",
    Address: "00 RHEENENDAL  SWELLENDAM  SWELLENDAM",
    Latitude: "-34.0088",
    Longitude: "20.3927",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22653",
    WardLookupId: "4592",
    VDNumber: "97880013",
    RegisteredPopulation: "2360",
    VotingStationName: "FORTSHAVEN HALL",
    Address: "00 C/O HEIDE AND DAHLIA AVENUE  SMITSVILLE  BARRYDALE",
    Latitude: "-33.9129",
    Longitude: "20.7338",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22654",
    WardLookupId: "4592",
    VDNumber: "98110054",
    RegisteredPopulation: "350",
    VotingStationName: "LEMOENSHOEK NGK PRIMARY SCHOOL",
    Address: "OFF R62  LEMOENSHOEK  LEMOENSHOEK",
    Latitude: "-33.8467",
    Longitude: "20.858",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22655",
    WardLookupId: "4592",
    VDNumber: "98110065",
    RegisteredPopulation: "621",
    VotingStationName: "VLEIPLAAS PRIMARY SCHOOL",
    Address: "R62  VLEIPLAAS  BARRYDALE",
    Latitude: "-33.9232",
    Longitude: "20.5832",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22656",
    WardLookupId: "4593",
    VDNumber: "98040012",
    RegisteredPopulation: "835",
    VotingStationName: "SUURBRAAK PRIMARY SCHOOL",
    Address: "00 SCHOOLSTREET  SUURBRAAK  SWELLENDAM",
    Latitude: "-34.0076",
    Longitude: "20.6503",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22657",
    WardLookupId: "4593",
    VDNumber: "98040023",
    RegisteredPopulation: "429",
    VotingStationName: "SUURBRAAK COMMUNITY HALL",
    Address: "00 HOOFWEG  SUURBRAAK  SWELLENDAM",
    Latitude: "-34.0046",
    Longitude: "20.6547",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22658",
    WardLookupId: "4593",
    VDNumber: "98040034",
    RegisteredPopulation: "109",
    VotingStationName: "HOEKRAAL LANDGOED",
    Address: "R324 ROAD  SUURBRAAK  LISMORE FARMS",
    Latitude: "-34.0098",
    Longitude: "20.7167",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22659",
    WardLookupId: "4593",
    VDNumber: "98110032",
    RegisteredPopulation: "247",
    VotingStationName: "MULLERSRUS PRIMÃŠRE SKOOL",
    Address: "00 NO STREET ADDRESS  BUFFELJAGSRIVIER  SWELLENDAM RURAL",
    Latitude: "-34.0679",
    Longitude: "20.5432",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22660",
    WardLookupId: "4593",
    VDNumber: "98110043",
    RegisteredPopulation: "1586",
    VotingStationName: "ST JOHNS PRIMARY SCHOOL",
    Address: "01 REV RL ARENDSE STREET  BUFFELJAGSRIVIER  SWELLENDAM",
    Latitude: "-34.0458",
    Longitude: "20.5266",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22661",
    WardLookupId: "4593",
    VDNumber: "98110076",
    RegisteredPopulation: "485",
    VotingStationName: "BREEDE RIVIER TRADING STORE",
    Address: "KLIPFONTEIN  FARM  MALGAS,SWELLENDAM",
    Latitude: "-34.3174",
    Longitude: "20.5799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22662",
    WardLookupId: "4594",
    VDNumber: "98050024",
    RegisteredPopulation: "1563",
    VotingStationName: "TOWN HALL",
    Address: "47 VOORTREK STREET  SWELLENDAM  SWELLENDAM",
    Latitude: "-34.0208",
    Longitude: "20.4428",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22663",
    WardLookupId: "4594",
    VDNumber: "98050068",
    RegisteredPopulation: "1679",
    VotingStationName: "RONDOMSKRIK PLAYPARK (TENT)",
    Address: "REISIESBAAN  RAILTON  SWELLENDAM",
    Latitude: "-34.0343",
    Longitude: "20.4484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22664",
    WardLookupId: "4595",
    VDNumber: "98050057",
    RegisteredPopulation: "4393",
    VotingStationName: "SWELLENDAM SENIOR SECONDARY SCHOOL",
    Address:
      "2633 VOLLENHOVENSTRAAT  RAILTON  SWELLENDAM [BARRYDALE/SWELLENDAM ]",
    Latitude: "-34.0412",
    Longitude: "20.4447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22665",
    WardLookupId: "4596",
    VDNumber: "98050013",
    RegisteredPopulation: "1893",
    VotingStationName: "COMMUNITY HALL",
    Address: "22 RESIESBAAN STREET  RAILTON  SWELLENDAM",
    Latitude: "-34.0348",
    Longitude: "20.4446",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22666",
    WardLookupId: "2776",
    VDNumber: "98270017",
    RegisteredPopulation: "3636",
    VotingStationName: "TOWERKOP PRIMARY SCHOOL",
    Address: "RAUBENHEIMER  NISSENVILLE  LADISMITH",
    Latitude: "-33.4981",
    Longitude: "21.2766",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22667",
    WardLookupId: "2777",
    VDNumber: "98230013",
    RegisteredPopulation: "2342",
    VotingStationName: "EXCELSIOR PRIMARY SCHOOL",
    Address: "VOORTREKKER STREET  P.O BOX 2, BERGSIG  CALITZDORP",
    Latitude: "-33.5249",
    Longitude: "21.6779",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22668",
    WardLookupId: "2777",
    VDNumber: "98230024",
    RegisteredPopulation: "928",
    VotingStationName: "CALITZDORP TOWN HALL",
    Address: "MILLER STREET  CALITZDORP  KANNALAND",
    Latitude: "-33.5344",
    Longitude: "21.6869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22669",
    WardLookupId: "2777",
    VDNumber: "98320013",
    RegisteredPopulation: "419",
    VotingStationName: "KRUISRIVIER PRIMÃŠRE SKOOL",
    Address: "KRUISRIVIER-WES  KRUISRIVIER  CALITZDORP",
    Latitude: "-33.4421",
    Longitude: "21.8776",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22670",
    WardLookupId: "2777",
    VDNumber: "98320024",
    RegisteredPopulation: "379",
    VotingStationName: "WARMBAD PRIMARY SCHOOL",
    Address: "WARMBAD  WARMBAD / CALITZDORP SPA  CALITZDORP",
    Latitude: "-33.6406",
    Longitude: "21.7714",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22671",
    WardLookupId: "2777",
    VDNumber: "98320035",
    RegisteredPopulation: "570",
    VotingStationName: "GAMKA-OOS PRIMÃŠRE SKOOL",
    Address: "GAMKA-OOS  CALITZDORP",
    Latitude: "-33.5708",
    Longitude: "21.7125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22672",
    WardLookupId: "2778",
    VDNumber: "97350015",
    RegisteredPopulation: "2300",
    VotingStationName: "MAXIS KNIT FACTORY",
    Address: "MAIN ROAD  RENOSTERVLEI  ZOAR",
    Latitude: "-33.4861",
    Longitude: "21.4647",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22673",
    WardLookupId: "2778",
    VDNumber: "97350037",
    RegisteredPopulation: "776",
    VotingStationName: "ZOAR SPORTS GROUND",
    Address: "ROUTE 62  KAROOLANDE  ZOAR",
    Latitude: "-33.497",
    Longitude: "21.4358",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22674",
    WardLookupId: "2779",
    VDNumber: "97350026",
    RegisteredPopulation: "380",
    VotingStationName: "HOEKO PRIMARY SCHOOL",
    Address: "BALMORAL  HOEKO  LADISMITH",
    Latitude: "-33.4889",
    Longitude: "21.3812",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22675",
    WardLookupId: "2779",
    VDNumber: "97360117",
    RegisteredPopulation: "235",
    VotingStationName: "ALGERYNSKRAAL PRIMÃŠRE SKOOL",
    Address: "ALGERYNSKRAAL  LADISMITH",
    Latitude: "-33.5933",
    Longitude: "21.1438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22676",
    WardLookupId: "2779",
    VDNumber: "98270028",
    RegisteredPopulation: "1068",
    VotingStationName: "LADISMITH TOWN HALL",
    Address: "34 VAN RIEBEECK STREET  LADISMITH  LADISMITH",
    Latitude: "-33.494",
    Longitude: "21.2675",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22677",
    WardLookupId: "2779",
    VDNumber: "98300011",
    RegisteredPopulation: "618",
    VotingStationName: "VAN WYKSDORP LUTHERAN CHURCH HALL",
    Address: "LUTHER AVENUE  VAN WYKSDORP  VAN WYKSDORP",
    Latitude: "-33.742",
    Longitude: "21.4619",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22678",
    WardLookupId: "2779",
    VDNumber: "98330014",
    RegisteredPopulation: "470",
    VotingStationName: "BUFFELSVLEI PRIMARY SCHOOL",
    Address: "VAN ZYLSDAMME  LADISMITH",
    Latitude: "-33.4776",
    Longitude: "21.0306",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22679",
    WardLookupId: "2779",
    VDNumber: "98330025",
    RegisteredPopulation: "614",
    VotingStationName: "DANKOORD PRIMÃŠRE SKOOL",
    Address: "DANKOORD  LADISMITH",
    Latitude: "-33.5165",
    Longitude: "21.1635",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22680",
    WardLookupId: "4597",
    VDNumber: "97210010",
    RegisteredPopulation: "708",
    VotingStationName: "GOURITSMOND GEMEENSKAPSAAL",
    Address: "85 VOORTREKKERSTR  GOURITSMOND  GOURITSMOND",
    Latitude: "-34.3519",
    Longitude: "21.8799",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22681",
    WardLookupId: "4597",
    VDNumber: "97330024",
    RegisteredPopulation: "2018",
    VotingStationName: "MELKHOUTFONTEIN COMMUNITY HALL",
    Address: "HEIDE AVENUE  MELKHOUTFONTEIN  STILBAAI",
    Latitude: "-34.3263",
    Longitude: "21.4194",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22682",
    WardLookupId: "4597",
    VDNumber: "97360128",
    RegisteredPopulation: "328",
    VotingStationName: "PUTTSTOP KAFEE DROEVLAKTE",
    Address: "R305  MODDERFONTEIN  STILLBAY",
    Latitude: "-34.2662",
    Longitude: "21.389",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22683",
    WardLookupId: "4598",
    VDNumber: "97160014",
    RegisteredPopulation: "3832",
    VotingStationName: "OKER PRIMARY - SCHOOL",
    Address: "1 RANGER   ALBERTINIA  ALBERTINIA",
    Latitude: "-34.2047",
    Longitude: "21.5763",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22684",
    WardLookupId: "4598",
    VDNumber: "97360038",
    RegisteredPopulation: "422",
    VotingStationName: "KLIPFONTEIN FARM (OLD SCHOOL BUILDING)",
    Address:
      "FARM NO 175, DIVISION RIVERSDALE  15KM NTH OF ALBERTINA ON MR337  ALBERTINIA",
    Latitude: "-34.0608",
    Longitude: "21.5603",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22685",
    WardLookupId: "4599",
    VDNumber: "97330013",
    RegisteredPopulation: "2293",
    VotingStationName: "STILBAAI COMMUNITY HALL",
    Address: "MAIN ROAD WEST  STILBAAI  STILBAAI",
    Latitude: "-34.3769",
    Longitude: "21.4106",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22686",
    WardLookupId: "4599",
    VDNumber: "97330035",
    RegisteredPopulation: "1510",
    VotingStationName: "STILBAAI BOWLING HALL",
    Address: "2 CHURCH STREET  STILBAAI WEST  STILBAAI",
    Latitude: "-34.3687",
    Longitude: "21.4016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22687",
    WardLookupId: "4599",
    VDNumber: "97360050",
    RegisteredPopulation: "565",
    VotingStationName: "MISSION SCHOOL VERMAAKLIKHEID",
    Address: "VERMAAKLIKHEID WAY  VERMAAKLIKHEID  RIVERSDALE",
    Latitude: "-34.3039",
    Longitude: "21.0267",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22688",
    WardLookupId: "4600",
    VDNumber: "97240035",
    RegisteredPopulation: "308",
    VotingStationName: "WITSAND BOWLING CLUB",
    Address: "0 MAIN STREET  WITSAND  WITSAND",
    Latitude: "-34.3931",
    Longitude: "20.8364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22689",
    WardLookupId: "4600",
    VDNumber: "97240046",
    RegisteredPopulation: "1086",
    VotingStationName: "DIEPKLOOF CENTRE",
    Address: "GOLIATH DAVIDS  DIEPKLOOF  HEILDEBERG",
    Latitude: "-34.1088",
    Longitude: "20.9626",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22690",
    WardLookupId: "4600",
    VDNumber: "97320012",
    RegisteredPopulation: "1901",
    VotingStationName: "SLANGRIVIER COMMUNITY HALL",
    Address: "77 SCHOOL STREET  SLANGRIVIER  SLANGRIVIER",
    Latitude: "-34.138",
    Longitude: "20.8581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22691",
    WardLookupId: "4600",
    VDNumber: "97360027",
    RegisteredPopulation: "438",
    VotingStationName: "VONDELING SKOOL",
    Address:
      "PTN 1 OF FARM VONDELING NO 481  23KM SW OF HEIDELBERG ON R322  HEIDELBERG",
    Latitude: "-34.3291",
    Longitude: "20.8376",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22692",
    WardLookupId: "4601",
    VDNumber: "97240024",
    RegisteredPopulation: "2750",
    VotingStationName: "DUIVENHOKS COMMUNITY CENTRE",
    Address: "GELDENHUYS WAY  HEIDELBERG WC  HEIDELBERG",
    Latitude: "-34.1024",
    Longitude: "20.9612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22693",
    WardLookupId: "4602",
    VDNumber: "97300010",
    RegisteredPopulation: "2803",
    VotingStationName: "RIVERVILLE STADIUM HALL",
    Address: "VETTERIVIER AVENUE  RIVERSDALE  RIVERSDALE",
    Latitude: "-34.0981",
    Longitude: "21.2692",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22694",
    WardLookupId: "4602",
    VDNumber: "97360061",
    RegisteredPopulation: "237",
    VotingStationName: "AGLIME STOOR",
    Address:
      "FARM NO 365, DIVISION RIVERSDALE  14KM E OF RIVERSDALE ON N2  RIVERSDALE",
    Latitude: "-34.1707",
    Longitude: "21.3708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22695",
    WardLookupId: "4602",
    VDNumber: "97360072",
    RegisteredPopulation: "555",
    VotingStationName: "KRUISRIVIER PRIMÃŠRE SKOOL",
    Address:
      "FARM BAKENSKOP NO 559, RIVERSDALE  10KM E OF RIVERSDALE ON DR1577  RIVERSDALE",
    Latitude: "-34.081",
    Longitude: "21.3259",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22696",
    WardLookupId: "4603",
    VDNumber: "97300032",
    RegisteredPopulation: "1890",
    VotingStationName: "RIVERSDAL BURGERSENTRUM",
    Address: "20 VAN DEN BERG STREET  RIVERSDALE  RIVERSDALE",
    Latitude: "-34.0916",
    Longitude: "21.2592",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22697",
    WardLookupId: "4603",
    VDNumber: "97300054",
    RegisteredPopulation: "1473",
    VotingStationName: "KWANOKUTHULA COMMUNITY HALL",
    Address: "25 DAHLIA STREET  RIVERSDAL  RIVERSDAL",
    Latitude: "-34.1008",
    Longitude: "21.2447",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22698",
    WardLookupId: "4603",
    VDNumber: "97360083",
    RegisteredPopulation: "661",
    VotingStationName: "MOLEN-RIVIER PRIMARY SCHOOL",
    Address: "N/A  NOVO  RIVERSDALE",
    Latitude: "-34.0385",
    Longitude: "21.233",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22699",
    WardLookupId: "4603",
    VDNumber: "97360094",
    RegisteredPopulation: "195",
    VotingStationName: "MUISKRAAL FARM - SHOP",
    Address: "LADISMITH  MUISKRAAL  RIVERSDALE",
    Latitude: "-33.9175",
    Longitude: "21.2125",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22700",
    WardLookupId: "4604",
    VDNumber: "97300021",
    RegisteredPopulation: "1322",
    VotingStationName: "BOLLIE KONYN KLEUTERSKOOL",
    Address: "98 UITSIG AVENUE  RIVERSDALE  RIVERSDALE",
    Latitude: "-34.104",
    Longitude: "21.2653",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22701",
    WardLookupId: "4604",
    VDNumber: "97300043",
    RegisteredPopulation: "1323",
    VotingStationName: "VERENIGENDE GEREFORMEERDE KERKSAAL",
    Address: "CUMMINGS STREET  RIVERSDALE  RIVERSDALE",
    Latitude: "-34.0975",
    Longitude: "21.2601",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22702",
    WardLookupId: "4604",
    VDNumber: "97300065",
    RegisteredPopulation: "1114",
    VotingStationName: "HA QUA YOUTH CENTRE",
    Address:
      "0 VARKEVISSER STREET  RIVERSDALE  HESSEQUA [HEIDELBERG/RIVERSDALE]",
    Latitude: "-34.1006",
    Longitude: "21.2583",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22703",
    WardLookupId: "4605",
    VDNumber: "97240013",
    RegisteredPopulation: "1987",
    VotingStationName: "HEIDELBERG TOWN HALL",
    Address: "5 NIEKERK STREET  HEIDELBERG  HEIDELBERG",
    Latitude: "-34.089",
    Longitude: "20.9556",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22704",
    WardLookupId: "4605",
    VDNumber: "97360016",
    RegisteredPopulation: "540",
    VotingStationName: "HEIDELBERG SKOUSAAL",
    Address: "ERF 1163, HEIDELBERG  1.2KM NW OF HEIDELBERG ON R324  HEIDELBERG",
    Latitude: "-34.0119",
    Longitude: "20.8207",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22705",
    WardLookupId: "2780",
    VDNumber: "97280051",
    RegisteredPopulation: "3168",
    VotingStationName: "INDWE SECONDARY SCHOOL",
    Address: "SITHELA STREET  KWA-NONQABA  MOSSEL BAY",
    Latitude: "-34.1695",
    Longitude: "22.0907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22706",
    WardLookupId: "2780",
    VDNumber: "97280264",
    RegisteredPopulation: "1324",
    VotingStationName: "ELUXOLWENI EDUCARE",
    Address: "60 NDIBANISO AVENUE  KWANONQABA  MOSSEL BAY",
    Latitude: "-34.1738",
    Longitude: "22.0869",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22707",
    WardLookupId: "2781",
    VDNumber: "97280040",
    RegisteredPopulation: "1714",
    VotingStationName: "KWA-NONQABA COMMUNITY HALL",
    Address: "MAYIXHALE STR  KWA-NONQABA  MOSSEL BAY",
    Latitude: "-34.1767",
    Longitude: "22.0903",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22708",
    WardLookupId: "2781",
    VDNumber: "97280130",
    RegisteredPopulation: "2380",
    VotingStationName: "ASLA PARK THUSONG CENTRE",
    Address: "ADRIAANS   KWANONQABA  MOSSEL BAY",
    Latitude: "-34.1791",
    Longitude: "22.0798",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22709",
    WardLookupId: "2782",
    VDNumber: "97280152",
    RegisteredPopulation: "2637",
    VotingStationName: "ASLA COMMUNITY HALL",
    Address: "MZOLA   ASLA PARK  MOSSEL BAY",
    Latitude: "-34.1801",
    Longitude: "22.0688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22710",
    WardLookupId: "2782",
    VDNumber: "97280196",
    RegisteredPopulation: "1511",
    VotingStationName: "AFRICAN GOSPEL CHURCH STOFILE STREET",
    Address: "STOFILE   KWANONQABA  MOSSEL BAY",
    Latitude: "-34.1769",
    Longitude: "22.0814",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22711",
    WardLookupId: "2782",
    VDNumber: "97280253",
    RegisteredPopulation: "1075",
    VotingStationName: "IMIZAMO YETHU CRECHE",
    Address: "5420 NKOSANA MBANGI  ASLA PARK  MOSSEL BAY",
    Latitude: "-34.1769",
    Longitude: "22.0708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22712",
    WardLookupId: "2783",
    VDNumber: "97230034",
    RegisteredPopulation: "2024",
    VotingStationName: "LAERSKOOL VORENTOE PRIMARY SPORT HALL",
    Address: "LONG STREET  GREATBRAKRIVER  MOSSEL BAY",
    Latitude: "-34.046",
    Longitude: "22.2164",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22713",
    WardLookupId: "2783",
    VDNumber: "97280242",
    RegisteredPopulation: "1471",
    VotingStationName: "KLEINBRAK COMMUNITY HALL",
    Address: "C/O HEYNS AND MEYER STREET  KLEINBRAK RIVER  MOSSEL BAY",
    Latitude: "-34.0873",
    Longitude: "22.1455",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22714",
    WardLookupId: "2783",
    VDNumber: "97380018",
    RegisteredPopulation: "1377",
    VotingStationName: "BRANDWACHT COMMUNITY HALL",
    Address: "LEVENDAL STREET VALLEY VIEW  BRANDWACHT  MOSSEL BAY",
    Latitude: "-34.0512",
    Longitude: "22.058",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22715",
    WardLookupId: "2784",
    VDNumber: "97230012",
    RegisteredPopulation: "2176",
    VotingStationName: "GREATBRAK MUNICIPALITY",
    Address: "CHARLES STREET  GREAT BRAK RIVER  GREAT BRAKRIVER",
    Latitude: "-34.0398",
    Longitude: "22.2216",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22716",
    WardLookupId: "2784",
    VDNumber: "97280129",
    RegisteredPopulation: "2604",
    VotingStationName: "RHEEBOK COMMUNITY HALL",
    Address: "CNR OF KUDU & DE JAAGER ST  RHEEBOK  MOSSEL BAY",
    Latitude: "-34.0759",
    Longitude: "22.1691",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22717",
    WardLookupId: "2785",
    VDNumber: "97280084",
    RegisteredPopulation: "3576",
    VotingStationName: "VOORTREKKERSAAL MOSSEL BAY",
    Address: "SCHOEMANSTRAAT  HEIDERAND  MOSSEL BAY",
    Latitude: "-34.1902",
    Longitude: "22.1212",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22718",
    WardLookupId: "2785",
    VDNumber: "97280208",
    RegisteredPopulation: "1474",
    VotingStationName: "NEW LIFE CHURCH",
    Address: "APIESDORING  HEIDERAND  MOSSELBAY",
    Latitude: "-34.1895",
    Longitude: "22.1043",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22719",
    WardLookupId: "2786",
    VDNumber: "97250014",
    RegisteredPopulation: "699",
    VotingStationName: "LUTHERAN CHURCH HALL -HERBERTSDALE",
    Address: "13 SKOOL STRAAT  HERBERTSDALE  MOSSELBAY",
    Latitude: "-34.017",
    Longitude: "21.7644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22720",
    WardLookupId: "2786",
    VDNumber: "97280163",
    RegisteredPopulation: "2774",
    VotingStationName: "SONSKYNVALLEI COMMUNITY HALL",
    Address: "MANDELA STREET  SONSKYN VALLEI  MOSSEL BAY",
    Latitude: "-34.115",
    Longitude: "22.0833",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22721",
    WardLookupId: "2786",
    VDNumber: "97380029",
    RegisteredPopulation: "692",
    VotingStationName: "VGK CHURCH HALL (VLEESBAAI)",
    Address: "MAIN STREET  VLEESBAAI  MOSSEL BAY",
    Latitude: "-34.2902",
    Longitude: "21.9123",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22722",
    WardLookupId: "2786",
    VDNumber: "97380041",
    RegisteredPopulation: "279",
    VotingStationName: "LUTHERAN CHURCH HALL - BUISPLAAS",
    Address: "HERBERTSDALE ROAD  BUISPLAAS  MOSSELBAY",
    Latitude: "-34.1165",
    Longitude: "21.7137",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22723",
    WardLookupId: "2787",
    VDNumber: "97280073",
    RegisteredPopulation: "3009",
    VotingStationName: "MOSSEL BAY TOWN HALL",
    Address: "101 MARSH STREET  MOSSEL BAY  MOSSEL BAY",
    Latitude: "-34.1818",
    Longitude: "22.1394",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22724",
    WardLookupId: "2788",
    VDNumber: "97280028",
    RegisteredPopulation: "2487",
    VotingStationName: "D ALMEIDA COMMUNITY HALL",
    Address: "CORNER OF ALHOLF DRIVE & MOSSEL STREET  D ALMEIDA  MOSSEL BAY",
    Latitude: "-34.175",
    Longitude: "22.1183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22725",
    WardLookupId: "2788",
    VDNumber: "97280062",
    RegisteredPopulation: "1423",
    VotingStationName: "DIAZ PRIMARY SCHOOL",
    Address: "ALHOF  D`ALMEIDA  MOSSEL BAY",
    Latitude: "-34.1741",
    Longitude: "22.1118",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22726",
    WardLookupId: "2788",
    VDNumber: "97280174",
    RegisteredPopulation: "1020",
    VotingStationName: "LUTHERAN CHURCH HALL - EXTENTION 06",
    Address: "DIAL BRAND  MOSSEL BAY  MOSSEL BAY",
    Latitude: "-34.1767",
    Longitude: "22.127",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22727",
    WardLookupId: "2788",
    VDNumber: "97280231",
    RegisteredPopulation: "978",
    VotingStationName: "SAO BRASS SECONDARY",
    Address: "SKOOL  D ALMEIDA  MOSSEL BAY",
    Latitude: "-34.177",
    Longitude: "22.1156",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22728",
    WardLookupId: "2789",
    VDNumber: "97280039",
    RegisteredPopulation: "4644",
    VotingStationName: "HARTENBOS BOWLING CLUB",
    Address: "RIEMLAND  HARTENBOS  MOSSEL BAY",
    Latitude: "-34.1223",
    Longitude: "22.1086",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22729",
    WardLookupId: "2790",
    VDNumber: "97280107",
    RegisteredPopulation: "3366",
    VotingStationName: "DANABAAI COMMUNITY HALL",
    Address: "A COMOSA  DANABAAI  MOSSELBAY",
    Latitude: "-34.194",
    Longitude: "22.0453",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22730",
    WardLookupId: "2790",
    VDNumber: "97280141",
    RegisteredPopulation: "1765",
    VotingStationName: "RIETVLEI PRIMÃŠRE SKOOL",
    Address: "ADRIAANS AVE  MOSSEL BAY",
    Latitude: "-34.1799",
    Longitude: "22.0728",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22731",
    WardLookupId: "2791",
    VDNumber: "97280220",
    RegisteredPopulation: "2261",
    VotingStationName: "JOE SLOVO COMMUNITY HALL",
    Address: "MPOKI STREET  JOE SLOVO  MOSSEL BAY",
    Latitude: "-34.1706",
    Longitude: "22.1148",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22732",
    WardLookupId: "2792",
    VDNumber: "97280017",
    RegisteredPopulation: "2688",
    VotingStationName: "LUTHERAN CHURCH HALL - EXTENTION 13",
    Address: "MARLIN STREET  EXT 13  MOSSEL BAY",
    Latitude: "-34.1746",
    Longitude: "22.1016",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22733",
    WardLookupId: "2792",
    VDNumber: "97280185",
    RegisteredPopulation: "1510",
    VotingStationName: "MOSSELBAAI BINNEHUIS SPORTSENTRUM",
    Address: "SAMPSON  EXTENTION 23  MOSSELBAAI",
    Latitude: "-34.1783",
    Longitude: "22.0933",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22734",
    WardLookupId: "2793",
    VDNumber: "97190017",
    RegisteredPopulation: "744",
    VotingStationName: "FRIEMERSHEIM COMMUNITY HALL",
    Address: "140 EIKE  FRIEMERSHEIM  MOSSEL BAY",
    Latitude: "-33.9531",
    Longitude: "22.1435",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22735",
    WardLookupId: "2793",
    VDNumber: "97230023",
    RegisteredPopulation: "1753",
    VotingStationName: "SANTA KLEUTERSKOOL",
    Address: "12 WATSONIA LAAN  GREENHAVEN  GREAT BRAKRIVER",
    Latitude: "-34.0387",
    Longitude: "22.2111",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22736",
    WardLookupId: "2793",
    VDNumber: "97230045",
    RegisteredPopulation: "1550",
    VotingStationName: "MULTI PURPOSE CENTRE",
    Address: "4 PETUNIA AVENUE  GROOT BRAKRIVIER  MOSSEL BAY",
    Latitude: "-34.0354",
    Longitude: "22.2024",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22737",
    WardLookupId: "2793",
    VDNumber: "97380030",
    RegisteredPopulation: "597",
    VotingStationName: "DENNEPRAG PRIMARY SCHOOL",
    Address: "HOOF  RUITERBOS  MOSSEL BAY",
    Latitude: "-33.919",
    Longitude: "22.0369",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22738",
    WardLookupId: "2794",
    VDNumber: "97200019",
    RegisteredPopulation: "3326",
    VotingStationName: "BLANCO COMMUNITY HALL",
    Address: "GEORGE AVE  BLANCO  GEORGE",
    Latitude: "-33.9433",
    Longitude: "22.4132",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22739",
    WardLookupId: "2795",
    VDNumber: "97200097",
    RegisteredPopulation: "3555",
    VotingStationName: "DENNEOORD PRIMARY SCHOOL",
    Address: "WELLINGTON STREET  DENNEOORD  GEORGE",
    Latitude: "-33.9454",
    Longitude: "22.4745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22740",
    WardLookupId: "2796",
    VDNumber: "97200031",
    RegisteredPopulation: "3361",
    VotingStationName: "HEATHERLANDS HIGH",
    Address: "68  CJ LANGENHOVEN STREET  HEATHERLANDS  GEORGE",
    Latitude: "-33.9557",
    Longitude: "22.4469",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22741",
    WardLookupId: "2797",
    VDNumber: "97340014",
    RegisteredPopulation: "1960",
    VotingStationName: "NATIONAL SEA RESCUE INSTITUTE",
    Address: "OFF THE N2 WILDERNESS  WILDERNESS  GEORGE",
    Latitude: "-33.9957",
    Longitude: "22.5828",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22742",
    WardLookupId: "2797",
    VDNumber: "97340025",
    RegisteredPopulation: "2013",
    VotingStationName: "TOUWSRANTEN COMMUNITY HALL",
    Address: "499 BESTER STREET  TOUWSRANTEN  WILDERNESS",
    Latitude: "-33.9549",
    Longitude: "22.6208",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22743",
    WardLookupId: "2797",
    VDNumber: "97340036",
    RegisteredPopulation: "404",
    VotingStationName: "KLEINKRANTZ CRECHE",
    Address: "1793 VIOOLTJIE  KLEINKRANTZ  GEORGE",
    Latitude: "-34.0024",
    Longitude: "22.66",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22744",
    WardLookupId: "2798",
    VDNumber: "97200132",
    RegisteredPopulation: "2199",
    VotingStationName: "ROSEMOORE SPORTS GROUND",
    Address: "STUURMAN  ROSEMOORE  GEORGE",
    Latitude: "-33.9695",
    Longitude: "22.4795",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22745",
    WardLookupId: "2798",
    VDNumber: "97200378",
    RegisteredPopulation: "2805",
    VotingStationName: "VAN KERVEL SCHOOL",
    Address: "MEYER STREET  BERGSIG  GEORGE EAST",
    Latitude: "-33.9575",
    Longitude: "22.4762",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22746",
    WardLookupId: "2799",
    VDNumber: "97200154",
    RegisteredPopulation: "1855",
    VotingStationName: "ROSEMOORE COMMUNITY HALL",
    Address: "WOLTEMADE STREET  ROSEMOORE  GEORGE",
    Latitude: "-33.9763",
    Longitude: "22.4834",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22747",
    WardLookupId: "2799",
    VDNumber: "97200165",
    RegisteredPopulation: "2000",
    VotingStationName: "GEORGE CONGREGATIONAL CHURCH",
    Address: "MITCHELL BAKER STREET  URBANSVILLE  GEORGE",
    Latitude: "-33.9802",
    Longitude: "22.4836",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22748",
    WardLookupId: "2800",
    VDNumber: "97200266",
    RegisteredPopulation: "2131",
    VotingStationName: "PARKDENE SENIOR SECONDARY SCHOOL",
    Address: "2 BALLOT STREET  PARKDENE  GEORGE",
    Latitude: "-33.9896",
    Longitude: "22.4827",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22749",
    WardLookupId: "2800",
    VDNumber: "97200413",
    RegisteredPopulation: "1660",
    VotingStationName: "LAWAAIKAMP COMMUNITY HALL",
    Address: "STANFORD MANGALISO STREET  LAWAAIKAMP  GEORGE",
    Latitude: "-33.9922",
    Longitude: "22.4734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22750",
    WardLookupId: "2801",
    VDNumber: "97200200",
    RegisteredPopulation: "1233",
    VotingStationName: "ST. LIBORIUS CATHOLIC CHURCH",
    Address: "C/O MAIN & BALLOT STREETS  PARKDENE  GEORGE",
    Latitude: "-33.988",
    Longitude: "22.4821",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22751",
    WardLookupId: "2801",
    VDNumber: "97200277",
    RegisteredPopulation: "2305",
    VotingStationName: "PARKDENE PRIMARY SCHOOL",
    Address: "4 BALLOT STREET  PARKDENE  GEORGE",
    Latitude: "-33.9906",
    Longitude: "22.4849",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22752",
    WardLookupId: "2802",
    VDNumber: "97200323",
    RegisteredPopulation: "1928",
    VotingStationName: "NEW TYOLORA PRIMARY SCHOOL",
    Address: "SOUTH OF SANDKRAAL ROAD  THEMBALETHU  GEORGE",
    Latitude: "-34.0065",
    Longitude: "22.4918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22753",
    WardLookupId: "2802",
    VDNumber: "97200424",
    RegisteredPopulation: "1884",
    VotingStationName: "ISISEKO EDUCARE CENTRE",
    Address: "27TH STREET  THEMBALETHU  GEORGE",
    Latitude: "-34.0093",
    Longitude: "22.4897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22754",
    WardLookupId: "2803",
    VDNumber: "97200312",
    RegisteredPopulation: "2547",
    VotingStationName: "ILINGELETHU CRECHE",
    Address: "2599 ZABALAZA STREET, ZONE 6,   THEMBALETHU  GEORGE",
    Latitude: "-34.0085",
    Longitude: "22.4937",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22755",
    WardLookupId: "2804",
    VDNumber: "97200301",
    RegisteredPopulation: "2788",
    VotingStationName: "CHRIST THE REDEEMER ANGLICAN CHURCH THEMBALETHU",
    Address: "NGCAKANI STREET  THEMBALETHU  GEORGE",
    Latitude: "-34.0071",
    Longitude: "22.5001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22756",
    WardLookupId: "2804",
    VDNumber: "97370040",
    RegisteredPopulation: "899",
    VotingStationName: "GATEWAY LODGE",
    Address: "KNYSNA ROAD  KRAAIBOSCH  GEORGE",
    Latitude: "-33.9928",
    Longitude: "22.5187",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22757",
    WardLookupId: "2804",
    VDNumber: "97370062",
    RegisteredPopulation: "408",
    VotingStationName: "LUTHERAN CHURCH RONDEVLEI",
    Address: "RONDEVLEI HIGHTS, DR 1629  RONDEVLEI  GEORGE",
    Latitude: "-33.9685",
    Longitude: "22.6981",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22758",
    WardLookupId: "2804",
    VDNumber: "97370107",
    RegisteredPopulation: "1760",
    VotingStationName: "PINE LODGE RESORT",
    Address: "MADIBA DRIVE  GLENWOOD  GEORGE",
    Latitude: "-33.9685",
    Longitude: "22.4886",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22759",
    WardLookupId: "2805",
    VDNumber: "97200345",
    RegisteredPopulation: "2720",
    VotingStationName: "GEORGE COMMUNITY LEARNING CENTRE",
    Address: "4757  TABATA   THEMBALETHU  GEORGE",
    Latitude: "-34.004",
    Longitude: "22.475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22760",
    WardLookupId: "2806",
    VDNumber: "97200288",
    RegisteredPopulation: "2426",
    VotingStationName: "MUNICIPAL OFFICE THEMBALETHU",
    Address: "NGCAKANI STREET  THEMBALETHU  GEORGE",
    Latitude: "-33.9994",
    Longitude: "22.4808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22761",
    WardLookupId: "2806",
    VDNumber: "97200367",
    RegisteredPopulation: "1474",
    VotingStationName: "THEMBALETHU COMMUNITY HALL",
    Address: "BACELA STREET  THEMBALETHU  GEORGE",
    Latitude: "-34.0025",
    Longitude: "22.4925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22762",
    WardLookupId: "2807",
    VDNumber: "97200222",
    RegisteredPopulation: "1314",
    VotingStationName: "PACALTSDORP COMMUNITY HALL",
    Address: "MISSION ROAD  PACALTSDORP  GEORGE",
    Latitude: "-34.0162",
    Longitude: "22.4581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22763",
    WardLookupId: "2807",
    VDNumber: "97200468",
    RegisteredPopulation: "2142",
    VotingStationName: "ROSEDALE HALL",
    Address: "C/O GENESIS & EAST CRUSIAN STR  ROSEDALE  GEORGE",
    Latitude: "-34.0008",
    Longitude: "22.4543",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22764",
    WardLookupId: "2808",
    VDNumber: "97200053",
    RegisteredPopulation: "2656",
    VotingStationName: "THEMBALETHU HIGH SCHOOL",
    Address: "QHAWA STREET  THEMBALETHU  GEORGE",
    Latitude: "-34.001",
    Longitude: "22.4743",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22765",
    WardLookupId: "2808",
    VDNumber: "97200390",
    RegisteredPopulation: "2075",
    VotingStationName: "NOMPUMELELO CRECHE",
    Address: "QHAWA STREET  THEMBALETHU  GEORGE",
    Latitude: "-33.9975",
    Longitude: "22.4701",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22766",
    WardLookupId: "2809",
    VDNumber: "97200255",
    RegisteredPopulation: "3959",
    VotingStationName: "NEW DAWN PARK PRIMARY SCHOOL",
    Address: "PROTEA STREET  NEW DAWN PARK  PACALTSDORP",
    Latitude: "-34.0163",
    Longitude: "22.4767",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22767",
    WardLookupId: "2810",
    VDNumber: "97200143",
    RegisteredPopulation: "1287",
    VotingStationName: "ROSEMOOR CENTRE",
    Address: "4 ATTAKWA STREET  ROSEMORE  GEORGE",
    Latitude: "-33.9711",
    Longitude: "22.4771",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22768",
    WardLookupId: "2810",
    VDNumber: "97200176",
    RegisteredPopulation: "2627",
    VotingStationName: "CONVILLE COMMUNITY HALL",
    Address: "1 PIENAAR STREET  CONVILLE  GEORGE",
    Latitude: "-33.982",
    Longitude: "22.4732",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22769",
    WardLookupId: "2811",
    VDNumber: "97200121",
    RegisteredPopulation: "1576",
    VotingStationName: "MOTH HALL",
    Address: "RIJK TULBACH STREET  LOERIEPARK  GEORGE",
    Latitude: "-33.9673",
    Longitude: "22.4887",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22770",
    WardLookupId: "2811",
    VDNumber: "97200402",
    RegisteredPopulation: "1384",
    VotingStationName: "NG KERK DENNEOORD",
    Address: "C/O WALLIS & CHARLOTTE STREET  DENNEOORD  GEORGE",
    Latitude: "-33.9509",
    Longitude: "22.4738",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22771",
    WardLookupId: "2812",
    VDNumber: "97200042",
    RegisteredPopulation: "1766",
    VotingStationName: "GEORGE COMMUNITY HALL",
    Address: "YORK STREET,   GEORGE CENTRAL  GEORGE",
    Latitude: "-33.961",
    Longitude: "22.454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22772",
    WardLookupId: "2812",
    VDNumber: "97200075",
    RegisteredPopulation: "3794",
    VotingStationName: "NG KERK GEORGE SUID",
    Address: "47 FICHAT  GEORGE SOUTH  GEORGE",
    Latitude: "-33.9695",
    Longitude: "22.4548",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22773",
    WardLookupId: "2813",
    VDNumber: "97200110",
    RegisteredPopulation: "2495",
    VotingStationName: "HEIDEDAL PRIMARY SCHOOL",
    Address: "BADNALL STREET  BORCHARDS  GEORGE",
    Latitude: "-33.9898",
    Longitude: "22.4698",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22774",
    WardLookupId: "2813",
    VDNumber: "97200187",
    RegisteredPopulation: "1144",
    VotingStationName: "YOUTH FOR CHRIST KIDS STOP",
    Address: "04  THOMAS STREET  BORCHARDS  GEORGE",
    Latitude: "-33.9881",
    Longitude: "22.4716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22775",
    WardLookupId: "2814",
    VDNumber: "97200435",
    RegisteredPopulation: "2486",
    VotingStationName: "SAKHINGOMSO CRECHE",
    Address: "SW963 ZWELITHSA INFORMAL SETTLEMENT  THEMBALETHU  GEORGE",
    Latitude: "-34.0116",
    Longitude: "22.501",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22776",
    WardLookupId: "2814",
    VDNumber: "97200446",
    RegisteredPopulation: "2103",
    VotingStationName: "BONGOLWETHU CRECHE",
    Address: "4055 IKOZI STREET  THEMBALETHU  GEORGE",
    Latitude: "-34.014",
    Longitude: "22.4918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22777",
    WardLookupId: "2815",
    VDNumber: "97200389",
    RegisteredPopulation: "1595",
    VotingStationName: "MASONIC HALL",
    Address: "CALEDON STREET  BO-DORP  GEORGE",
    Latitude: "-33.9547",
    Longitude: "22.4586",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22778",
    WardLookupId: "2815",
    VDNumber: "97370017",
    RegisteredPopulation: "845",
    VotingStationName: "NG KERK OUTENIQUA LAND",
    Address: "DR1599 SINKSABRUG  GEORGE",
    Latitude: "-34.0005",
    Longitude: "22.2897",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22779",
    WardLookupId: "2815",
    VDNumber: "97370051",
    RegisteredPopulation: "850",
    VotingStationName: "GEELHOUTBOOM PRIMÃŠRE SKOOL",
    Address: "DR 1631  GEELHOUTBOOM  GEORGE",
    Latitude: "-33.9574",
    Longitude: "22.348",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22780",
    WardLookupId: "2815",
    VDNumber: "98310045",
    RegisteredPopulation: "484",
    VotingStationName: "NG KERKSAAL HEROLD",
    Address: "NEXT TO MONTAGUE PASS ROAD  HEROLD  GEORGE",
    Latitude: "-33.8465",
    Longitude: "22.4479",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22781",
    WardLookupId: "2815",
    VDNumber: "98310078",
    RegisteredPopulation: "476",
    VotingStationName: "WABOOMSKRAAL MULTIPURPOSE CENTRE",
    Address: "N12, 1.2 KM (NW)  GEORGE  GEORGE",
    Latitude: "-33.8758",
    Longitude: "22.3667",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22782",
    WardLookupId: "2816",
    VDNumber: "97200064",
    RegisteredPopulation: "1279",
    VotingStationName: "CARPE DIEM SCHOOL",
    Address: "LANG STREET  GEORGE SOUTH  GEORGE",
    Latitude: "-33.9775",
    Longitude: "22.4493",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22783",
    WardLookupId: "2816",
    VDNumber: "97200211",
    RegisteredPopulation: "1611",
    VotingStationName: "DELLVILLE PARK PRIMARY SCHOOL",
    Address: "42 LYNX STREET  DELLVILLE  PARK  GEORGE",
    Latitude: "-34.0076",
    Longitude: "22.4459",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22784",
    WardLookupId: "2816",
    VDNumber: "97200334",
    RegisteredPopulation: "828",
    VotingStationName: "HEROLDS BAY CARAVAN PARK OFFICE",
    Address: "R404 HEROLDS BAY  HEROLDS BAY  GEORGE",
    Latitude: "-34.0529",
    Longitude: "22.39",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22785",
    WardLookupId: "2817",
    VDNumber: "98260016",
    RegisteredPopulation: "1298",
    VotingStationName: "HAARLEM GEMEENSKAPSAAL",
    Address: "10 HOOPSTR  HAARLEM  HAARLEM",
    Latitude: "-33.7367",
    Longitude: "23.3362",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22786",
    WardLookupId: "2817",
    VDNumber: "98310034",
    RegisteredPopulation: "662",
    VotingStationName: "NEDERDUITS GEREFORMEERDE KERKSAAL NOLL",
    Address: "NOLL  BO-LANGKLOOF DISTRIK",
    Latitude: "-33.7684",
    Longitude: "22.8824",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22787",
    WardLookupId: "2817",
    VDNumber: "98350016",
    RegisteredPopulation: "765",
    VotingStationName: "GEMEENSKAPSAAL MATT ZONDAGH LANDGOED",
    Address: "AVONTUUR  UNIONDALE VOR  UNIONDALE DISTRIK",
    Latitude: "-33.7242",
    Longitude: "23.1618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22788",
    WardLookupId: "2817",
    VDNumber: "98350049",
    RegisteredPopulation: "303",
    VotingStationName: "ONGELEGEN UCC PRIMÃŠRE SKOOL",
    Address: "UNIONDALE VOR  UNIONDALE DISTRIK",
    Latitude: "-33.7367",
    Longitude: "23.4121",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22789",
    WardLookupId: "2818",
    VDNumber: "98290019",
    RegisteredPopulation: "3075",
    VotingStationName: "LYONVILLE GEMEENSKAPSAAL",
    Address: "1 LANGSTR  LYONVILLE  UNIONDALE",
    Latitude: "-33.6627",
    Longitude: "23.113",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22790",
    WardLookupId: "2818",
    VDNumber: "98310023",
    RegisteredPopulation: "331",
    VotingStationName: "EZELJACHT COMMUNITY HALL",
    Address: "EZELJACHT  EZELJACHT FARMS  GEORGE",
    Latitude: "-33.812",
    Longitude: "22.5708",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22791",
    WardLookupId: "2818",
    VDNumber: "98350027",
    RegisteredPopulation: "315",
    VotingStationName: "DIEPKLOOF VOORSKOOL",
    Address: "P/A ROOIRIVIER  ROOIRIVIER  UNIONDALE",
    Latitude: "-33.5354",
    Longitude: "22.8183",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22792",
    WardLookupId: "2818",
    VDNumber: "98350038",
    RegisteredPopulation: "108",
    VotingStationName: "HARTEBEESRIVER UCC PRIMÃŠRE SKOOL",
    Address: "HARTEBEESRIVIER  UNIONDALE VOR  UNIONDALE DISTRIK",
    Latitude: "-33.5871",
    Longitude: "23.3426",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22793",
    WardLookupId: "2818",
    VDNumber: "98350050",
    RegisteredPopulation: "200",
    VotingStationName: "BASSOU PLASE",
    Address: "BASSOU PLASE  UNIONDALE VOR  UNIONDALE DISTRIK",
    Latitude: "-33.7028",
    Longitude: "22.8815",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22794",
    WardLookupId: "2819",
    VDNumber: "97200020",
    RegisteredPopulation: "2774",
    VotingStationName: "NEDERDUITS GEREFORMEERDE KERK BLANCO",
    Address: "2A NAPIER STREET  BLANCO  GEORGE",
    Latitude: "-33.9465",
    Longitude: "22.4089",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22795",
    WardLookupId: "2820",
    VDNumber: "97200233",
    RegisteredPopulation: "1488",
    VotingStationName: "ST VINCENT PALOTTI CATHOLIC CHURCH",
    Address: "MISSION  PACALTSDORP  GEORGE",
    Latitude: "-34.0206",
    Longitude: "22.4517",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22796",
    WardLookupId: "2820",
    VDNumber: "97200244",
    RegisteredPopulation: "1705",
    VotingStationName: "VGK PACALTSDORP",
    Address: "AMARYLLIS  PACALTSDORP  GEORGE",
    Latitude: "-34.0215",
    Longitude: "22.4664",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22797",
    WardLookupId: "2821",
    VDNumber: "98280120",
    RegisteredPopulation: "2179",
    VotingStationName: "NG KERKSAAL WELGELUK",
    Address: "23 VAN DER WESTHUIZEN  WESBANK  OUDTSHOORN",
    Latitude: "-33.6108",
    Longitude: "22.2011",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22798",
    WardLookupId: "2821",
    VDNumber: "98280209",
    RegisteredPopulation: "3504",
    VotingStationName: "SADF HALL",
    Address: "PARKWEG  OUDTSHOORN",
    Latitude: "-33.5839",
    Longitude: "22.1907",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22799",
    WardLookupId: "2822",
    VDNumber: "98280119",
    RegisteredPopulation: "2825",
    VotingStationName: "DE JAGER SPORTS COMPLEX",
    Address: "VOORTREKKER ROAD  OUDTSHOORN",
    Latitude: "-33.5916",
    Longitude: "22.1982",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22800",
    WardLookupId: "2822",
    VDNumber: "98340059",
    RegisteredPopulation: "414",
    VotingStationName: "LATEGANSVLEI PRIMÃŠRE SKOOL",
    Address: "LATEGANSVLEI  OUDTSHOORN  OUDTSHOORN DISTRIK",
    Latitude: "-33.5259",
    Longitude: "22.0331",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22801",
    WardLookupId: "2822",
    VDNumber: "98340071",
    RegisteredPopulation: "453",
    VotingStationName: "WELBEDACHT PRIMÃŠRE SKOOL",
    Address: "WELBEDACHT  OUDTSHOORN",
    Latitude: "-33.6119",
    Longitude: "21.9876",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22802",
    WardLookupId: "2822",
    VDNumber: "98340127",
    RegisteredPopulation: "988",
    VotingStationName: "VOLMOED PRIMÃŠRE SKOOL",
    Address: "VOLMOED ROAD  OUDTSHOORN  OUDTSHOORN",
    Latitude: "-33.6566",
    Longitude: "22.0888",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22803",
    WardLookupId: "2823",
    VDNumber: "98280096",
    RegisteredPopulation: "1277",
    VotingStationName: "KAIROS SKOOL",
    Address: "OOSTELIKE WEG  OUDTSHOORN  OUDTSHOORN",
    Latitude: "-33.6039",
    Longitude: "22.2116",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22804",
    WardLookupId: "2823",
    VDNumber: "98280131",
    RegisteredPopulation: "1462",
    VotingStationName: "OUDTSHOORN SECONDARY SCHOOL",
    Address: "JAN VAN RIEBEECK STREET  OUDTSHOORN",
    Latitude: "-33.5931",
    Longitude: "22.1955",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22805",
    WardLookupId: "2823",
    VDNumber: "98280142",
    RegisteredPopulation: "922",
    VotingStationName: "SOUTH CAPE COLLEGE OUDTSHOORN",
    Address: "41 ADDERLEY STREET  OUDTSHOORN  OUDTSHOORN",
    Latitude: "-33.5928",
    Longitude: "22.2073",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22806",
    WardLookupId: "2824",
    VDNumber: "98280030",
    RegisteredPopulation: "2612",
    VotingStationName: "QUEEN OF PEACE ROMAN CATHOLIC CHURCH HALL",
    Address: "1ST AVENUE  BONGOLETHU  OUDTSHOORN",
    Latitude: "-33.5999",
    Longitude: "22.2385",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22807",
    WardLookupId: "2824",
    VDNumber: "98280153",
    RegisteredPopulation: "860",
    VotingStationName: "BRIDGTON SENIOR SECONDARY SCHOOL",
    Address: "2 BERGIN STREET  BRIDGTON  OUDTSHOORN",
    Latitude: "-33.5976",
    Longitude: "22.2299",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22808",
    WardLookupId: "2825",
    VDNumber: "98280074",
    RegisteredPopulation: "2433",
    VotingStationName: "SONSKYNHOEKIE - CRECHE",
    Address: "18TH AVENUE  BRIDGTON  OUDTSHOORN",
    Latitude: "-33.6049",
    Longitude: "22.23",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22809",
    WardLookupId: "2825",
    VDNumber: "98280197",
    RegisteredPopulation: "1016",
    VotingStationName: "SMARTIE TOWN CRECHE",
    Address: "OLIFANT  OUDTSHOORN  OUDTSHOORN",
    Latitude: "-33.6081",
    Longitude: "22.2271",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22810",
    WardLookupId: "2826",
    VDNumber: "98280029",
    RegisteredPopulation: "3051",
    VotingStationName: "BRIDGTON COMMINITY HALL",
    Address: "ZEBRA ROAD  BRIDGTON  OUDTSHOORN",
    Latitude: "-33.5938",
    Longitude: "22.2364",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22811",
    WardLookupId: "2826",
    VDNumber: "98280210",
    RegisteredPopulation: "1801",
    VotingStationName: "ROSEVALLEY CRECHE",
    Address: "ROSEVALLEY  OUDTSHOORN",
    Latitude: "-33.5913",
    Longitude: "22.2568",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22812",
    WardLookupId: "2827",
    VDNumber: "98280041",
    RegisteredPopulation: "1811",
    VotingStationName: "LAURUS PRIMARY SCHOOL",
    Address: "HOOPSTRAAT  OUDTSHOORN NORTH  OUDTSHOORN",
    Latitude: "-33.5833",
    Longitude: "22.2112",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22813",
    WardLookupId: "2827",
    VDNumber: "98280108",
    RegisteredPopulation: "2164",
    VotingStationName: "NICOLANIA HALL",
    Address: "IMPALA WEG  BRIDGTON  OUDTSHOORN",
    Latitude: "-33.6001",
    Longitude: "22.2178",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22814",
    WardLookupId: "2828",
    VDNumber: "98280052",
    RegisteredPopulation: "3154",
    VotingStationName: "FEZEKILE SECONDARY SCHOOL",
    Address: "15TH AVENUE  OUDTSHOORN  BONGOLETHU",
    Latitude: "-33.6047",
    Longitude: "22.2344",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22815",
    WardLookupId: "2828",
    VDNumber: "98280175",
    RegisteredPopulation: "1094",
    VotingStationName: "VERENIGDE GEREFORMEERDE KERK",
    Address: "C/O PETUNIA AND ARNOLD JAGER   BRIGHTON  OUDTSHOORN [OUDTSHOORN]",
    Latitude: "-33.597",
    Longitude: "22.2454",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22816",
    WardLookupId: "2829",
    VDNumber: "98250037",
    RegisteredPopulation: "1323",
    VotingStationName: "DYSSELSDORP CRECHE",
    Address: "MOMMEN ROAD  DYSSELDORP  OUDTSHOORN",
    Latitude: "-33.5765",
    Longitude: "22.4299",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22817",
    WardLookupId: "2829",
    VDNumber: "98250048",
    RegisteredPopulation: "1814",
    VotingStationName: "KOLPING HOUSE",
    Address: "ADONIS ROAD  DYSSELDORP  OUDTSHOORN",
    Latitude: "-33.5794",
    Longitude: "22.4243",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22818",
    WardLookupId: "2829",
    VDNumber: "98340093",
    RegisteredPopulation: "229",
    VotingStationName: "VOLKSKERK",
    Address: "VOLKSKERK  STOLSVLAKTE  OUDTSHOORN",
    Latitude: "-33.5783",
    Longitude: "22.3135",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22819",
    WardLookupId: "2830",
    VDNumber: "98250015",
    RegisteredPopulation: "1154",
    VotingStationName: "P J BADENHORST PRIMARY SCHOOL",
    Address: "DYSSELSWEG  DYSSELSDORP  OUDTSHOORN",
    Latitude: "-33.5745",
    Longitude: "22.4313",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22820",
    WardLookupId: "2830",
    VDNumber: "98250026",
    RegisteredPopulation: "2575",
    VotingStationName: "DYSSELSDORP GEMEENSKAPSAAL",
    Address: "DYSSELSWEG  DYSSELDORP  DYSSELSDORP",
    Latitude: "-33.5747",
    Longitude: "22.438",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22821",
    WardLookupId: "2831",
    VDNumber: "98240014",
    RegisteredPopulation: "2109",
    VotingStationName: "BLOMNEK GEMEENSKAPSAAL",
    Address: "DWARS STRAAT  BLOMNEK  DE RUST",
    Latitude: "-33.4981",
    Longitude: "22.518",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22822",
    WardLookupId: "2831",
    VDNumber: "98340015",
    RegisteredPopulation: "309",
    VotingStationName: "VLAKTEPLAAS UCC PRIMÃŠRE SKOOL",
    Address: "VLAKTEPLAAS  OUDTSHOORN  OUDTSHOORN",
    Latitude: "-33.4962",
    Longitude: "22.7019",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22823",
    WardLookupId: "2831",
    VDNumber: "98340026",
    RegisteredPopulation: "352",
    VotingStationName: "GROOTKRUIS UCC CHURC",
    Address: "GROOTKRUIS  DE KRUYS  OUDTSHOORN",
    Latitude: "-33.4627",
    Longitude: "22.4355",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22824",
    WardLookupId: "2831",
    VDNumber: "98340048",
    RegisteredPopulation: "370",
    VotingStationName: "CANGO CAVES ESTATE",
    Address: "GROOTKRAAL  OUDTSHOORN",
    Latitude: "-33.3989",
    Longitude: "22.2149",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22825",
    WardLookupId: "2831",
    VDNumber: "98340082",
    RegisteredPopulation: "487",
    VotingStationName: "NELSRIVIER UCC CHURCH",
    Address: "NELSRIVIER  DE RUST",
    Latitude: "-33.5264",
    Longitude: "22.4942",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22826",
    WardLookupId: "2831",
    VDNumber: "98340116",
    RegisteredPopulation: "767",
    VotingStationName: "RODEWAL UCC PRIMÃŠRE SKOOL",
    Address: "RODEWAL  SCHOEMANSHOEK  OUDTSHOORN DISTRIK",
    Latitude: "-33.47",
    Longitude: "22.2572",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22827",
    WardLookupId: "2832",
    VDNumber: "98280164",
    RegisteredPopulation: "1540",
    VotingStationName: "OUDTSHOORN ENRICHMENT CENTRE",
    Address: "MANGO AVENUE  NEPPON  OUDTSHOORN",
    Latitude: "-33.5985",
    Longitude: "22.2502",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22828",
    WardLookupId: "2832",
    VDNumber: "98280186",
    RegisteredPopulation: "488",
    VotingStationName: "DEPO-TEGNIESE DIENSTE",
    Address: "WAGNER STREET  OUDTSHOORN REMAINDER  OUDTSHOORN",
    Latitude: "-33.6102",
    Longitude: "22.2193",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22829",
    WardLookupId: "2832",
    VDNumber: "98310012",
    RegisteredPopulation: "238",
    VotingStationName: "KLIPDRIF PRIMARY SCHOOL",
    Address: "KLIPDRIFT  OUDTSHOORN  OUDTSHOORN",
    Latitude: "-33.775",
    Longitude: "22.3119",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22830",
    WardLookupId: "2832",
    VDNumber: "98340037",
    RegisteredPopulation: "236",
    VotingStationName: "KAMMANASSIE GEMEENSKAPSENTRUM",
    Address: "HOTOMSKLOOF  OUDTSHOORN",
    Latitude: "-33.6395",
    Longitude: "22.356",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22831",
    WardLookupId: "2832",
    VDNumber: "98340060",
    RegisteredPopulation: "346",
    VotingStationName: "ZEEKOEGAT PRIMÃŠRE SKOOL",
    Address: "ZEEKOEGAT  OUDTSHOORN  OUDTSHOORN",
    Latitude: "-33.6268",
    Longitude: "22.1604",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22832",
    WardLookupId: "2832",
    VDNumber: "98340105",
    RegisteredPopulation: "377",
    VotingStationName: "ROOIHEUWEL PRIMÃŠRE SKOOL",
    Address: "ROOIHEUWEL  OUDTSHOORN  OUDTSHOORN",
    Latitude: "-33.6122",
    Longitude: "22.2902",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22833",
    WardLookupId: "2833",
    VDNumber: "98280063",
    RegisteredPopulation: "636",
    VotingStationName: "BRIDGTON TOURIST CAMP HALL",
    Address: "BERGINSTR  BRIDGTON  OUDTSHOORN",
    Latitude: "-33.5979",
    Longitude: "22.2276",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22834",
    WardLookupId: "2833",
    VDNumber: "98280085",
    RegisteredPopulation: "3323",
    VotingStationName: "ST SIMONS ANGLICAN CHURCH",
    Address: "C/O KUDU  BRIDGTON  OUDTSHOORN",
    Latitude: "-33.6015",
    Longitude: "22.2219",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22835",
    WardLookupId: "2834",
    VDNumber: "97220066",
    RegisteredPopulation: "2272",
    VotingStationName: "KURLAND COMMUNITY HALL",
    Address: "ZIMRI STR  KURLAND  PLETTENBERG BAY",
    Latitude: "-33.9501",
    Longitude: "23.4893",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22836",
    WardLookupId: "2834",
    VDNumber: "97220077",
    RegisteredPopulation: "890",
    VotingStationName: "KURLAND CLUB HOUSE",
    Address: "THE CRAGS  CRAGS  PLETTENBERG BAY",
    Latitude: "-33.9432",
    Longitude: "23.4895",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22837",
    WardLookupId: "2835",
    VDNumber: "97220033",
    RegisteredPopulation: "3017",
    VotingStationName: "PLETTENBERG BAY COMMUNITY HALL",
    Address: "HANOIS DRIVE  PLETTENBERG BAY SOUTH  PLETTENBERG BAY",
    Latitude: "-34.063",
    Longitude: "23.366",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22838",
    WardLookupId: "2835",
    VDNumber: "97220088",
    RegisteredPopulation: "1957",
    VotingStationName: "PLETTENBERG BAY PRIMARY SCHOOL",
    Address: "BEACON WAY  PLETT BAY NORTH  PLETTENBERG BAY",
    Latitude: "-34.0402",
    Longitude: "23.3719",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22839",
    WardLookupId: "2835",
    VDNumber: "97220213",
    RegisteredPopulation: "961",
    VotingStationName: "NEW LIFE CENTRE CRECHE",
    Address: "THERON STREET  BOSSIESGIF  PLETTENBERG BAY",
    Latitude: "-34.0481",
    Longitude: "23.3557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22840",
    WardLookupId: "2836",
    VDNumber: "97220055",
    RegisteredPopulation: "2088",
    VotingStationName: "CDC BICYCLE SHED",
    Address: "FARIS STREET  INDUSTRIAL AREA  PLETTENBERG BAY",
    Latitude: "-34.0483",
    Longitude: "23.3531",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22841",
    WardLookupId: "2836",
    VDNumber: "97220202",
    RegisteredPopulation: "791",
    VotingStationName: "MUNICIPAL HOUSE",
    Address:
      "PINE TREES PLETTENBERG BAY  GREATER PLETTENBERG BAY  PLETTENBERG BAY",
    Latitude: "-34.047",
    Longitude: "23.349",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22842",
    WardLookupId: "2837",
    VDNumber: "97220044",
    RegisteredPopulation: "2740",
    VotingStationName: "NEW HORIZONS COMMUNITY HALL",
    Address: "STINKHOUT RD  NEW HORIZONS  PLETTENBERG BAY",
    Latitude: "-34.0509",
    Longitude: "23.3411",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22843",
    WardLookupId: "2837",
    VDNumber: "97220167",
    RegisteredPopulation: "832",
    VotingStationName: "CHILD WELFARE SA PLETTENBERG BAY",
    Address: "PINE ROAD  NEW HORIZONS  PLETTENBERG BAY",
    Latitude: "-34.0532",
    Longitude: "23.3433",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22844",
    WardLookupId: "2838",
    VDNumber: "97220112",
    RegisteredPopulation: "1968",
    VotingStationName: "PHAKAMISANI PRIMARY SCHOOL",
    Address: "3 CNR TSHEMBESE & SISHUBA STREET  KWANOKUTHULA  PLETTENBERG BAY",
    Latitude: "-34.0483",
    Longitude: "23.3237",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22845",
    WardLookupId: "2838",
    VDNumber: "97220145",
    RegisteredPopulation: "2187",
    VotingStationName: "KWANOKUTHULA PRIMARY SCHOOL",
    Address: "SKOSANA  KWANOKUTHULA  PLETTENBERG BAY",
    Latitude: "-34.0499",
    Longitude: "23.3175",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22846",
    WardLookupId: "2839",
    VDNumber: "97220022",
    RegisteredPopulation: "1292",
    VotingStationName: "KWANOKUTHULA COMMUNITY HALL",
    Address: "XHIPHULA STR  KWANOKUTHULA  PLETTENBERG BAY",
    Latitude: "-34.0526",
    Longitude: "23.3218",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22847",
    WardLookupId: "2839",
    VDNumber: "97220156",
    RegisteredPopulation: "1403",
    VotingStationName: "MURRAY HIGH SCHOOL",
    Address:
      "SKHOSANA  KWANOKUTHULA  PLETTENBERG BAY [GREATER PLETTENBERG BAY]",
    Latitude: "-34.0554",
    Longitude: "23.3152",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22848",
    WardLookupId: "2839",
    VDNumber: "97220189",
    RegisteredPopulation: "1532",
    VotingStationName: "ASSEMBLY OF GOD KWANOKUTHULA",
    Address: "PLETTENBERG BAY  ",
    Latitude: "-34.0531",
    Longitude: "23.3104",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22849",
    WardLookupId: "2840",
    VDNumber: "97220011",
    RegisteredPopulation: "2764",
    VotingStationName: "KRANSHOEK MULTIPURPOSE CENTRE",
    Address: "CNR TREKKER &CHARLOTTEROSS WEG  KRANSHOEK  PLETTENBERG BAY",
    Latitude: "-34.0915",
    Longitude: "23.2977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22850",
    WardLookupId: "2840",
    VDNumber: "97220099",
    RegisteredPopulation: "864",
    VotingStationName: "HARKERVILLE PRIMARY SCHOOL",
    Address: "AIRPORTROAD  HARKERVILLE  PLETTENBERG BAY",
    Latitude: "-34.0439",
    Longitude: "23.2484",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22851",
    WardLookupId: "2840",
    VDNumber: "97220101",
    RegisteredPopulation: "586",
    VotingStationName: "WITTEDRIFT HIGH SCHOOL",
    Address: "CHURCH STREET  WITTEDRIFT  PLETTENBERG BAY",
    Latitude: "-34.0069",
    Longitude: "23.3392",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22852",
    WardLookupId: "2840",
    VDNumber: "97220134",
    RegisteredPopulation: "735",
    VotingStationName: "NEW - GREENVALLEY COMMUNITY HALL",
    Address: "ROTTERDAM STREET  WITTEDRIFT  PLETTENBERG BAY",
    Latitude: "-34.0112",
    Longitude: "23.3285",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22853",
    WardLookupId: "2841",
    VDNumber: "97310022",
    RegisteredPopulation: "3087",
    VotingStationName: "SEDGEFIELD PRIMARY SCHOOL",
    Address: "MILKHOOD STREET  SEDGEFIELD  KNYSNA",
    Latitude: "-34.0256",
    Longitude: "22.8139",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22854",
    WardLookupId: "2841",
    VDNumber: "97310044",
    RegisteredPopulation: "798",
    VotingStationName: "SEDGEFIELD BOWLING CLUB",
    Address: "KRUGER  SEDGEFIELD  SEDGEFIELD",
    Latitude: "-34.0148",
    Longitude: "22.786",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22855",
    WardLookupId: "2842",
    VDNumber: "97310011",
    RegisteredPopulation: "2499",
    VotingStationName: "SEDGEFIELD TOWN HALL",
    Address: "FLAMINGO AVENUE  SEDGEFIELD  KNYSNA",
    Latitude: "-34.0143",
    Longitude: "22.8004",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22856",
    WardLookupId: "2842",
    VDNumber: "97370073",
    RegisteredPopulation: "1253",
    VotingStationName: "KARATARA COMMUNITY HALL",
    Address: "HOOFSTRAAT  KARATARA  KNYSNA",
    Latitude: "-33.9164",
    Longitude: "22.8371",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22857",
    WardLookupId: "2843",
    VDNumber: "97270128",
    RegisteredPopulation: "1979",
    VotingStationName: "CHRIS HANI COMMUNITY HALL",
    Address: "OLD UNION ROAD  DAM-SE-BOS  KNYSNA",
    Latitude: "-34.0396",
    Longitude: "23.1102",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22858",
    WardLookupId: "2843",
    VDNumber: "97270173",
    RegisteredPopulation: "1056",
    VotingStationName: "UNCEDO TAXI ASSOCIATION OFFICE",
    Address: "NEKKIES ROAD  DAM SE BOS  KNYSNA",
    Latitude: "-34.0421",
    Longitude: "23.1063",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22859",
    WardLookupId: "2843",
    VDNumber: "97270229",
    RegisteredPopulation: "675",
    VotingStationName: "HLALANI METHODIST CHURCH",
    Address: "HORNLEE ROAD  HLALANI  KNYSNA",
    Latitude: "-34.0423",
    Longitude: "23.1048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22860",
    WardLookupId: "2844",
    VDNumber: "97270094",
    RegisteredPopulation: "2377",
    VotingStationName: "CHRIS NISSEN PRIMARY SCHOOL",
    Address: "CONCORDIA ROAD   WHITE LOCATION  KNYSNA",
    Latitude: "-34.0297",
    Longitude: "23.0595",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22861",
    WardLookupId: "2844",
    VDNumber: "97270106",
    RegisteredPopulation: "1361",
    VotingStationName: "CONCORDIA METHODIST CHURCH",
    Address: "FLENTER LOCATION  FLENTERS  KNYSNA",
    Latitude: "-34.0264",
    Longitude: "23.0644",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22862",
    WardLookupId: "2844",
    VDNumber: "97270195",
    RegisteredPopulation: "579",
    VotingStationName: "ST PETERS ANGLICAN CHURCH",
    Address: "GREENFIELD  KNYSNA  KNYSNA",
    Latitude: "-34.0304",
    Longitude: "23.0687",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22863",
    WardLookupId: "2844",
    VDNumber: "97370118",
    RegisteredPopulation: "140",
    VotingStationName: "BUFFELSNEK - PRIMARY SCHOOL",
    Address: "VYGIE STREET  BUFFELSNEK  KNYSNA",
    Latitude: "-33.8941",
    Longitude: "23.1594",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22864",
    WardLookupId: "2845",
    VDNumber: "97170015",
    RegisteredPopulation: "465",
    VotingStationName: "BELVIDERE ANGLICAN CHURCH",
    Address: "CNR CHURCH & QUEEN STREET  BELVIDERE ESTATE  KNYSNA",
    Latitude: "-34.0414",
    Longitude: "22.9994",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22865",
    WardLookupId: "2845",
    VDNumber: "97180016",
    RegisteredPopulation: "659",
    VotingStationName: "BRENTON COUNCIL OFFICES",
    Address: "252 CR SWART DRIVE  BRENTON ON SEA  KNYSNA",
    Latitude: "-34.0739",
    Longitude: "23.0261",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22866",
    WardLookupId: "2845",
    VDNumber: "97290018",
    RegisteredPopulation: "2075",
    VotingStationName: "RHEENENDAL COMMUNITY CENTRE",
    Address: "HOOF STREET  RHEENENDAL  ",
    Latitude: "-33.945",
    Longitude: "22.9361",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22867",
    WardLookupId: "2845",
    VDNumber: "97310033",
    RegisteredPopulation: "99",
    VotingStationName: "BUFFELSBAY DUTCH REFORMED CHURCH",
    Address: "SAND STREET  BUFFELSBAAI  KNYSNA",
    Latitude: "-34.086",
    Longitude: "22.9727",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22868",
    WardLookupId: "2845",
    VDNumber: "97370084",
    RegisteredPopulation: "552",
    VotingStationName: "QHINTA DE MONTANHA",
    Address: "PORTION 8 FARM 185 LEEUWENBOSCH   RHEENENDAL  KNYSNA",
    Latitude: "-33.9455",
    Longitude: "22.9475",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22869",
    WardLookupId: "2846",
    VDNumber: "97270049",
    RegisteredPopulation: "1708",
    VotingStationName: "VERMONT OLD AGE HOME",
    Address: "GLADSTONE ST  HORNLEE  KNYSNA",
    Latitude: "-34.0478",
    Longitude: "23.0889",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22870",
    WardLookupId: "2846",
    VDNumber: "97270050",
    RegisteredPopulation: "1315",
    VotingStationName: "HORNLEE PRIMARY SCHOOL",
    Address: "WING STREET  HORNLEE  KNYSNA",
    Latitude: "-34.0473",
    Longitude: "23.1003",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22871",
    WardLookupId: "2846",
    VDNumber: "97270139",
    RegisteredPopulation: "929",
    VotingStationName: "HORNLEE COMMUNITY HALL",
    Address: "SUNRIDGE STREET  HORNLEE  KNYSNA",
    Latitude: "-34.0454",
    Longitude: "23.0932",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22872",
    WardLookupId: "2847",
    VDNumber: "97270117",
    RegisteredPopulation: "1902",
    VotingStationName: "PERCY MADALA HIGH SCHOOL",
    Address: "KHAYALETHU  KNYSNA",
    Latitude: "-34.035",
    Longitude: "23.1048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22873",
    WardLookupId: "2847",
    VDNumber: "97270184",
    RegisteredPopulation: "1457",
    VotingStationName: "BONGANI TRAINING CENTRE",
    Address: "CONCORDIA ROAD  BONGANI  KNYSNA",
    Latitude: "-34.0324",
    Longitude: "23.0997",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22874",
    WardLookupId: "2848",
    VDNumber: "97270072",
    RegisteredPopulation: "2734",
    VotingStationName: "CONCORDIA HIGH SCHOOL",
    Address: "5 CONCORDIA ROAD  CONCORDIA  KNYSNA",
    Latitude: "-34.0294",
    Longitude: "23.0867",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22875",
    WardLookupId: "2848",
    VDNumber: "97270230",
    RegisteredPopulation: "1134",
    VotingStationName: "MASIFUNDE LIBRARY",
    Address: "JOODSE KAMP  KNYSNA",
    Latitude: "-34.0287",
    Longitude: "23.0734",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22876",
    WardLookupId: "2849",
    VDNumber: "97270083",
    RegisteredPopulation: "2008",
    VotingStationName: "CEARN HALL",
    Address: "LINKS DRIVE  LEISURE ISLE  KNYSNA",
    Latitude: "-34.0669",
    Longitude: "23.0629",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22877",
    WardLookupId: "2849",
    VDNumber: "97270218",
    RegisteredPopulation: "925",
    VotingStationName: "VINEYARD CHRISTIAN FELLOWSHIP",
    Address: "HOPE  KYNSNA  KNYSNA",
    Latitude: "-34.0434",
    Longitude: "23.0716",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22878",
    WardLookupId: "2849",
    VDNumber: "97370028",
    RegisteredPopulation: "269",
    VotingStationName: "BRACKENHILL NEW BORN MINISTRIES",
    Address: "KARABIA STREET BRACKENHILL  KNYSNA  KNYSNA",
    Latitude: "-34.0271",
    Longitude: "23.1648",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22879",
    WardLookupId: "2850",
    VDNumber: "97270016",
    RegisteredPopulation: "2491",
    VotingStationName: "OAKHILL HIGH SCHOOL",
    Address: "UITSIG  KNYSNA  KNYSNA",
    Latitude: "-34.0327",
    Longitude: "23.041",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22880",
    WardLookupId: "2850",
    VDNumber: "97270027",
    RegisteredPopulation: "1446",
    VotingStationName: "TOWN HALL",
    Address: "CLYDE STREET  KNYSNA",
    Latitude: "-34.0378",
    Longitude: "23.0508",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22881",
    WardLookupId: "2851",
    VDNumber: "97270038",
    RegisteredPopulation: "1653",
    VotingStationName: "SUNRIDGE PRIMARY SCHOOL",
    Address: "SCHOOL STREET  HORNLEE  KNYSNA",
    Latitude: "-34.0519",
    Longitude: "23.0977",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22882",
    WardLookupId: "2851",
    VDNumber: "97270140",
    RegisteredPopulation: "1217",
    VotingStationName: "HORNLEE AFM KOINONIA CHURCH",
    Address: "DAVIDSON STREET   HORNLEE  KNYSNA [KNYSNA]",
    Latitude: "-34.0518",
    Longitude: "23.103",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22883",
    WardLookupId: "2852",
    VDNumber: "98140024",
    RegisteredPopulation: "202",
    VotingStationName: "LAINGSBURG HIGH SCHOOL",
    Address: "KAMBROS  LAINGSBURG  LAINGSBURG",
    Latitude: "-33.1976",
    Longitude: "20.8527",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22884",
    WardLookupId: "2852",
    VDNumber: "98190018",
    RegisteredPopulation: "1126",
    VotingStationName: "BERGSIG COMMUNITY HALL",
    Address: "BAVIAANSWEG  BERGSIG  LAINGSBURG",
    Latitude: "-33.1947",
    Longitude: "20.8409",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22885",
    WardLookupId: "2853",
    VDNumber: "98190029",
    RegisteredPopulation: "463",
    VotingStationName: "MATJIESFONTEIN COMMUNITY HALL",
    Address: "MATJIESFONTEIN  LAINGSBURG",
    Latitude: "-33.2343",
    Longitude: "20.5846",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22886",
    WardLookupId: "2853",
    VDNumber: "98190030",
    RegisteredPopulation: "63",
    VotingStationName: "KRANSRIVIER FARM SHED",
    Address: "KRANSRIVIER  LAINGSBURG  LAINGSBURG",
    Latitude: "-32.8096",
    Longitude: "21.3406",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22887",
    WardLookupId: "2853",
    VDNumber: "98190041",
    RegisteredPopulation: "73",
    VotingStationName: "KORINGPLAATZ KERKSAAL",
    Address: "KORINGPLAATZ  LAINGSBURG",
    Latitude: "-32.7992",
    Longitude: "20.9618",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22888",
    WardLookupId: "2853",
    VDNumber: "98190052",
    RegisteredPopulation: "333",
    VotingStationName: "VLEILAND SPORTS COMPLEX",
    Address: "VLEILAND  LAINGSBURG",
    Latitude: "-33.3726",
    Longitude: "21.1582",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22889",
    WardLookupId: "2854",
    VDNumber: "98140013",
    RegisteredPopulation: "639",
    VotingStationName: "JJ ELLIS - SAAL SPORTKOMPLEKS",
    Address: "MEIRING STREET  LAINGSBURG  LAINGSBURG",
    Latitude: "-33.1989",
    Longitude: "20.8557",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22890",
    WardLookupId: "2855",
    VDNumber: "98140035",
    RegisteredPopulation: "1689",
    VotingStationName: "THUSONG CENTRE",
    Address: "C/O HOOFLAAN AND SESDELAAN  LAINGSBURG  LAINGSBURG",
    Latitude: "-33.1946",
    Longitude: "20.8673",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22891",
    WardLookupId: "4606",
    VDNumber: "98150014",
    RegisteredPopulation: "1804",
    VotingStationName: "LEEU-GAMKA GEMEENSKAPSAAL",
    Address: "GOMPOUSTRAAT  BITTERWATER  LEEU-GAMKA",
    Latitude: "-32.7685",
    Longitude: "21.9712",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22892",
    WardLookupId: "4606",
    VDNumber: "98210044",
    RegisteredPopulation: "57",
    VotingStationName: "KRUIDFONTEIN STOOR",
    Address: "KRUIDFONTEIN  LEEU GAMKA",
    Latitude: "-32.8542",
    Longitude: "21.9552",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22893",
    WardLookupId: "4606",
    VDNumber: "98210055",
    RegisteredPopulation: "114",
    VotingStationName: "PRINCE ALBERTWEG SCHOOL",
    Address: "PRINCE ALBERTWEG  ",
    Latitude: "-32.987",
    Longitude: "21.6871",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22894",
    WardLookupId: "4607",
    VDNumber: "98210011",
    RegisteredPopulation: "667",
    VotingStationName: "KLAARSTROOM COMMUNITY HALL",
    Address: "SCHOOL STREET  PRINCE ALBERT  PRINCE ALBERT",
    Latitude: "-33.3255",
    Longitude: "22.5286",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22895",
    WardLookupId: "4607",
    VDNumber: "98210022",
    RegisteredPopulation: "488",
    VotingStationName: "PRINCE ALBERT GOLF CLUBHOUSE",
    Address: "ADDERLEY STREET  PRINCE ALBERT  PRINCE ALBERT",
    Latitude: "-33.2084",
    Longitude: "22.0273",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22896",
    WardLookupId: "4607",
    VDNumber: "98210033",
    RegisteredPopulation: "71",
    VotingStationName: "SEEKOEGAT SCHOOL",
    Address: "N12, SEEKOEGAT FARM  PRINCE ALBERT  PRINCE ALBERT",
    Latitude: "-33.057",
    Longitude: "22.51",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22897",
    WardLookupId: "4608",
    VDNumber: "98170016",
    RegisteredPopulation: "1746",
    VotingStationName: "PINKSTER PROTESTANTE KERK",
    Address: "CORNER KAMP AND MALVA STREET  PRINCE ALBERT  PRINCE ALBERT",
    Latitude: "-33.2081",
    Longitude: "22.0209",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22898",
    WardLookupId: "4609",
    VDNumber: "98170027",
    RegisteredPopulation: "1289",
    VotingStationName: "SYDWELL WILLIAMS SENTRUM",
    Address: "BUITEKANT  PRINCE ALBERT  PRINCE ALBERT",
    Latitude: "-33.211",
    Longitude: "22.0264",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22899",
    WardLookupId: "4609",
    VDNumber: "98170038",
    RegisteredPopulation: "1390",
    VotingStationName: "RONDOMSKRIK POP CENTRE",
    Address: "24B BOTTERBLOM STREET  RONDOMSKRIK  PRINCE ALBERT",
    Latitude: "-33.2044",
    Longitude: "22.02",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22900",
    WardLookupId: "2856",
    VDNumber: "98160015",
    RegisteredPopulation: "1980",
    VotingStationName: "MURRAYSBURG STADSAAL",
    Address: "BEAUFORTSTRAAT  MURRAYSBURG  BEAUFORT WEST",
    Latitude: "-31.9634",
    Longitude: "23.7634",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22901",
    WardLookupId: "2856",
    VDNumber: "98160026",
    RegisteredPopulation: "1436",
    VotingStationName: "MURRAYSBURG COMMUNITY HALL",
    Address: "CORNER MURRAY & KORT STREET  MURRAYSBURG  MURRAYSBURG",
    Latitude: "-31.9665",
    Longitude: "23.7645",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22902",
    WardLookupId: "2856",
    VDNumber: "98200021",
    RegisteredPopulation: "125",
    VotingStationName: "BRAKVLEI FARM SHED",
    Address: "R63, BRAKVLEI FARM  MURRAYSBURG  BEAUFORT WEST",
    Latitude: "-31.8171",
    Longitude: "23.5808",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22903",
    WardLookupId: "2856",
    VDNumber: "98200032",
    RegisteredPopulation: "188",
    VotingStationName: "VOETPAD FARM SHED",
    Address: "R63  MURRAYSBURG DISTRICT  BEAUFORT WEST MUNICIPALITY",
    Latitude: "-31.9567",
    Longitude: "23.9794",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22904",
    WardLookupId: "2857",
    VDNumber: "98130034",
    RegisteredPopulation: "1987",
    VotingStationName: "BEAUFORT WEST RECREATIONAL HALL",
    Address: "LOCH STREET  MIDTOWN  BEAUFORT WEST",
    Latitude: "-32.3516",
    Longitude: "22.5788",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22905",
    WardLookupId: "2857",
    VDNumber: "98180051",
    RegisteredPopulation: "1147",
    VotingStationName: "NELSPOORT COMMUNITY HALL",
    Address: "NELSPOORT  BEAUFORT WEST",
    Latitude: "-32.089",
    Longitude: "23.0067",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22906",
    WardLookupId: "2857",
    VDNumber: "98180062",
    RegisteredPopulation: "115",
    VotingStationName: "HANEKUIL FARM SHED",
    Address: "R61, HANEKUIL FARM  HANEKUIL  BEAUFORT WEST",
    Latitude: "-32.576",
    Longitude: "23.0571",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22907",
    WardLookupId: "2857",
    VDNumber: "98200010",
    RegisteredPopulation: "249",
    VotingStationName: "KARREEBOSCH FARM SHED",
    Address: "KARREEBOSCH  MURRAYSBURG  BEAUFORT WEST",
    Latitude: "-32.0134",
    Longitude: "23.3925",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22908",
    WardLookupId: "2858",
    VDNumber: "98130067",
    RegisteredPopulation: "1993",
    VotingStationName: "JD CRAWFORD PRIMARY SCHOOL",
    Address: "SCHOOL STREET  RUSTDENE  BEAUFORT WEST",
    Latitude: "-32.3677",
    Longitude: "22.5696",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22909",
    WardLookupId: "2858",
    VDNumber: "98130090",
    RegisteredPopulation: "1583",
    VotingStationName: "BEACON OF HOPE COMMUNITY CHURCH",
    Address: "1 BOWER STREET  NIEUVELD PARK  BEAUFORT WEST",
    Latitude: "-32.3762",
    Longitude: "22.5623",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22910",
    WardLookupId: "2858",
    VDNumber: "98130124",
    RegisteredPopulation: "692",
    VotingStationName: "ESSOPVILLE CHRISTIAN CENTRE",
    Address: "DISA STREET  ESSOPVILLE  BEAUFORT WEST",
    Latitude: "-32.371",
    Longitude: "22.5612",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22911",
    WardLookupId: "2859",
    VDNumber: "98130023",
    RegisteredPopulation: "1284",
    VotingStationName: "BEAUFORT WEST SPORT CENTRE",
    Address: "VOORTREKKER ROAD  TOWN CENTRE  BEAUFORT-WEST",
    Latitude: "-32.3538",
    Longitude: "22.5951",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22912",
    WardLookupId: "2859",
    VDNumber: "98130078",
    RegisteredPopulation: "2053",
    VotingStationName: "KHANYISA EDUCARE CENTRE",
    Address: "32 FALATSA AVENUE  KWA MANDLENKOSI  BEAUFORT WEST",
    Latitude: "-32.3722",
    Longitude: "22.5797",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22913",
    WardLookupId: "2860",
    VDNumber: "98130045",
    RegisteredPopulation: "1069",
    VotingStationName: "RUSTDENE COMMUNITY HALL",
    Address: "DE VRIES STREET  RUSTDENE  BEAUFORT WEST",
    Latitude: "-32.3644",
    Longitude: "22.5745",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22914",
    WardLookupId: "2860",
    VDNumber: "98130056",
    RegisteredPopulation: "1118",
    VotingStationName: "AGAPE CHURCH",
    Address: "3 HEUWEL STREET  RUSTDENE  BEAUFORT WEST",
    Latitude: "-32.3709",
    Longitude: "22.5741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22915",
    WardLookupId: "2860",
    VDNumber: "98130102",
    RegisteredPopulation: "1416",
    VotingStationName: "KWA-MANDLENKOSI AME CHURCH",
    Address: "8 GABA ROAD  KWA-MANDLENKOSI  BEAUFORT WEST",
    Latitude: "-32.363",
    Longitude: "22.581",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22916",
    WardLookupId: "2861",
    VDNumber: "98130012",
    RegisteredPopulation: "1996",
    VotingStationName: "PINKSTER EENHEID",
    Address: "EBENEZER  RUSTDENE  BEAUFORT WEST",
    Latitude: "-32.3755",
    Longitude: "22.5741",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22917",
    WardLookupId: "2861",
    VDNumber: "98130135",
    RegisteredPopulation: "1047",
    VotingStationName: "NEW FOUNTAIN MINISTRIES",
    Address: "CORNER TRUMAN & TATTIES STREET  BEAUFORT WEST  BEAUFORT WEST",
    Latitude: "-32.3806",
    Longitude: "22.5726",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22918",
    WardLookupId: "2861",
    VDNumber: "98180028",
    RegisteredPopulation: "134",
    VotingStationName: "SCHEURFONTEIN",
    Address: "N12, SCHEURFONTEIN  BEAUFORT WEST  BEAUFORT WEST",
    Latitude: "-32.6855",
    Longitude: "22.554",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22919",
    WardLookupId: "2861",
    VDNumber: "98180073",
    RegisteredPopulation: "95",
    VotingStationName: "MADDISON PRIMÃŠRE SKOOL",
    Address: "LETJIESBOS  BEAUFORT WEST",
    Latitude: "-32.5636",
    Longitude: "22.2663",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22920",
    WardLookupId: "2862",
    VDNumber: "98130089",
    RegisteredPopulation: "3226",
    VotingStationName: "BEAUFORT WEST PRIMARY SCHOOL",
    Address: "PASTORIE STREET  HILLSIDE  BEAUFORT WEST",
    Latitude: "-32.3516",
    Longitude: "22.5688",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22921",
    WardLookupId: "2862",
    VDNumber: "98130113",
    RegisteredPopulation: "88",
    VotingStationName: "SOETDORINGSVLEI COMMUNITY HALL",
    Address: "SOETDORINGSVLEI  KAROO NATIONAL PARK  BEAUFORT WEST",
    Latitude: "-32.3305",
    Longitude: "22.5001",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22922",
    WardLookupId: "2862",
    VDNumber: "98180017",
    RegisteredPopulation: "206",
    VotingStationName: "KLAWERVLEI PRIMARY SCHOOL",
    Address: "R381  BEAUFORT WEST  BEAUFORT WEST",
    Latitude: "-32.0879",
    Longitude: "22.4048",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22923",
    WardLookupId: "2862",
    VDNumber: "98180039",
    RegisteredPopulation: "945",
    VotingStationName: "GEORGE FREDERICK PRIMÃŠRE SKOOL",
    Address: "DS PEINAAR STREET  MERWEVILLE  BEAUFORT WEST",
    Latitude: "-32.6698",
    Longitude: "21.5081",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
  {
    VotingStationId: "22924",
    WardLookupId: "2862",
    VDNumber: "98180040",
    RegisteredPopulation: "145",
    VotingStationName: "TWEERIVIERE FARM HOUSE",
    Address: "TWEE RIVIERE  BEAUFORT WEST  BEAUFORT WEST",
    Latitude: "-32.399",
    Longitude: "21.918",
    Audit_AddedBy: null,
    Audit_AddedOn: "2019-11-28 02:45:45",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:45",
  },
];

const mapInit = {};
const convertToMap = (array) => {
  if (Object.keys(mapInit).length === 0) {
    array.forEach((item) => {
      mapInit[item.WardId] = item.WardName;
    });
  }
  return mapInit;
};

export const useVotingStations = () => {
  const [votingStations] = useState(VOTINGSTATIONS);
  const [votingStationMap] = useState(convertToMap(VOTINGSTATIONS));

  return { votingStations, votingStationMap };
};

export default VOTINGSTATIONS;
