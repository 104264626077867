import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";
import ReportLinks from "../../components/ReportLinks/ReportLinks";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { getAuth } from "firebase/auth";

export default function ReportDirectoryPage(props) {
  const { ...rest } = props;
  const authState = useSelector((state) => state);
  const auth = getAuth();
  if (auth.currentUser && auth.currentUser === null) return <Navigate to="/" />;

  return (
    <div>
      <Header
        absolute
        color="dark"
        brand="Membership Administration"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <GridContainer
        style={{
          height: 600,
          width: "90%",
          marginRight: 40,
          marginLeft: 40,
          display: "flex",
          flexDirection: "column",
          marginBottom: 30,
        }}
      >
        <div
          style={{
            marginTop: 120,
            marginRight: 20,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <h1>Member Reports</h1>
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <ReportLinks />
          </div>
        </div>
      </GridContainer>
      <Footer />
    </div>
  );
}
