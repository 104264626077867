import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";
import GridContainer from "../../components/Grid/GridContainer";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { collection, getDocs, where } from "firebase/firestore";
import db from "../../api/firebase";
import Paper from "@mui/material/Paper";
import {
  Chart,
  Legend,
  PieSeries,
} from "@devexpress/dx-react-chart-material-ui";
import {
  ADMINS,
  MUNICIPALITIES,
  PROVINCES,
  REGIONS,
  WARDS,
} from "../../assets/data";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { getDatabase, onValue, query, ref } from "firebase/database";
import { getProvinces } from "../../assets/data/Provinces";
import moment from "moment";
import { Button, CircularProgress } from "@mui/material";
import StopImage from "../../assets/images/pexels-mwabonje-1806900.jpg";
import GridItem from "../../components/Grid/GridItem";
import {
  languageColumns,
  languageMunicipalityColumns,
  languageRegionColumns,
  languageWardColumns,
} from "../Reports/Columns";
import CsvDownload from "react-json-to-csv";
import { LANGUAGES } from "../../assets/data/Languages";
import { getAuth } from "firebase/auth";

export default function LanguageDemographicsPage(props) {
  const [members, setMembers] = useState();
  const [data, setData] = useState([]);
  const [regions, setRegions] = useState();
  const [municipalities, setMunicipalities] = useState();
  const [wards, setWards] = useState();
  const [regionsData, setRegionsData] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalGS, setTotalGS] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [provinceTitle, setProvinceTitle] = useState("");
  const [regionTitle, setRegionTitle] = useState("");
  const [municipalityTitle, setMunicipalityTitle] = useState("");

  const dbRef = collection(db, "members");

  const sumOfMembers = (arrayOfMembers) => {
    let total = 0;
    for (let i = 0; i < arrayOfMembers.length; i++) {
      total = total + arrayOfMembers[i];
    }
    return total;
  };

  const auth = getAuth();

  const rtdb = getDatabase();
  useEffect(() => {
    const countRef = ref(rtdb, "regions");
    setIsLoading(true);
    onValue(countRef, (snapshot) => {
      let regionsArray = [];
      const data = snapshot.val();

      REGIONS.forEach((region) => {
        const selectedRegion = region.Name;
        if (selectedRegion === "ZF MAQCAWU/SIYANDA") {
          const zfdata = data["ZF MAQCAWU"].SIYANDA;
          regionsArray = [...regionsArray, { ...zfdata, id: zfdata.regionId }];
          setRegionsData(regionsArray);
        } else if (data[selectedRegion]) {
          regionsArray = [
            ...regionsArray,
            { ...data[selectedRegion], id: data[selectedRegion].regionId },
          ];
          setRegionsData(regionsArray);
        }
        setIsLoading(false);
      });
    });
  }, [rtdb]);

  useEffect(() => {
    let count = 0;
    let gsCount = 0;
    regionsData.forEach((region) => {
      count = count + region.total;
      gsCount = gsCount + region.goodStanding;
    });
    setTotal(count);
    setTotalGS(gsCount);
  }, [regionsData]);

  const regionDistributions = (province) => {
    setIsLoading(true);
    const provinceId =
      getProvinces().provinceIdMap[province.toLocaleLowerCase().trim()];
    const filteredRegions = regionsData.filter(
      (region) => region.provinceId === provinceId
    );

    const regionDemographics = filteredRegions.map((region) => ({
      region: region.region,
      id: region.id,
      zuluGoodStanding: region.languageDemographics.ZULU.total,
      zuluTotal: region.languageDemographics.ZULU.total,
      ndebeleGoodStanding: region.languageDemographics.NDEBELE.total,
      ndebeleTotal: region.languageDemographics.NDEBELE.total,
      northernSothoGoodStanding:
        region.languageDemographics.NORTHERNSOTHO.total,
      northernSothoTotal: region.languageDemographics.NORTHERNSOTHO.total,
      sothoGoodStanding: region.languageDemographics.SOTHO.total,
      sothoTotal: region.languageDemographics.SOTHO.total,
      swaziGoodStanding: region.languageDemographics.SWAZI.total,
      swaziTotal: region.languageDemographics.SWAZI.total,
      tsongaGoodStanding: region.languageDemographics.TSONGA.total,
      tsongaTotal: region.languageDemographics.TSONGA.total,
      tswanaGoodStanding: region.languageDemographics.TSWANA.total,
      tswanaTotal: region.languageDemographics.TSWANA.total,
      vendaGoodStanding: region.languageDemographics.VENDA.total,
      vendaTotal: region.languageDemographics.VENDA.total,
      xhosaGoodStanding: region.languageDemographics.XHOSA.total,
      xhosaTotal: region.languageDemographics.XHOSA.total,
      afrikaansGoodStanding: region.languageDemographics.AFRIKAANS.total,
      afrikaansTotal: region.languageDemographics.AFRIKAANS.total,
      englishGoodStanding: region.languageDemographics.ENGLISH.total,
      englishTotal: region.languageDemographics.ENGLISH.total,
      notSpecifiedGoodStanding: region.languageDemographics.NOTSPECIFIED.total,
      notSpecifiedTotal: region.languageDemographics.NOTSPECIFIED.total,
      totalGoodStanding: region.goodStanding,
      total: region.total,
    }));

    setRegions(regionDemographics);
    setIsLoading(false);
  };

  const municipalityDistributions = (region) => {
    setIsLoading(true);

    let arrayOfMembers = [];
    const dataQuery = query(dbRef, where("region", "==", region.region));

    getDocs(dataQuery).then((value) => {
      const secondArray = arrayOfMembers.concat(value.docs);
      let uniqueIds = [];
      let uniqueDocs = [];
      let duplicateDocs = [];

      secondArray.forEach((doc) => {
        const id = doc.data().idNumber;
        if (!uniqueIds.includes(id)) {
          uniqueDocs.push(doc);
          uniqueIds.push(id);
        } else {
          duplicateDocs.push(doc);
          // deleteMember(id);
        }
      });

      setMembers(uniqueDocs);

      const municipalityInfo = MUNICIPALITIES.filter(
        (item) => item.Region_RegionId === region.id
      );

      let municpalitiesArray = [];
      municipalityInfo.forEach((muni) => {
        const totalInMuni = uniqueDocs.filter(
          (doc) =>
            doc.data().municipality === muni.Name &&
            doc.data().invalid === false
        );

        const totalInMuniGoodStanding = totalInMuni.filter(
          (doc) =>
            moment(doc.data().lastPayment).format() >
            moment().subtract(2, "years").format()
        );

        const muniDistObject = {
          id: muni.MunicipalityId,
          regionId: muni.Region_RegionId,
          municipality: muni.Name,
          municipalityId: muni.MunicipalityId,
          zuluGoodStanding: totalInMuniGoodStanding.filter(
            (doc) => doc.data().language === "ZULU"
          ).length,
          zuluTotal: totalInMuni.filter((doc) => doc.data().language === "ZULU")
            .length,
          ndebeleGoodStanding: totalInMuniGoodStanding.filter(
            (doc) => doc.data().language === "NDEBELE"
          ).length,
          ndebeleTotal: totalInMuni.filter(
            (doc) => doc.data().language === "NDEBELE"
          ).length,
          northernSothoGoodStanding: totalInMuniGoodStanding.filter(
            (doc) => doc.data().language === "NORTHERN SOTHO"
          ).length,
          northernSothoTotal: totalInMuni.filter(
            (doc) => doc.data().language === "NORTHERN SOTHO"
          ).length,
          sothoGoodStanding: totalInMuniGoodStanding.filter(
            (doc) => doc.data().language === "SOTHO"
          ).length,
          sothoTotal: totalInMuni.filter(
            (doc) => doc.data().language === "SOTHO"
          ).length,
          swaziGoodStanding: totalInMuniGoodStanding.filter(
            (doc) => doc.data().language === "SWAZI"
          ).length,
          swaziTotal: totalInMuni.filter(
            (doc) => doc.data().language === "SWAZI"
          ).length,
          tsongaGoodStanding: totalInMuniGoodStanding.filter(
            (doc) => doc.data().language === "TSONGA"
          ).length,
          tsongaTotal: totalInMuni.filter(
            (doc) => doc.data().language === "TSONGA"
          ).length,
          tswanaGoodStanding: totalInMuniGoodStanding.filter(
            (doc) => doc.data().language === "TSWANA"
          ).length,
          tswanaTotal: totalInMuni.filter(
            (doc) => doc.data().language === "TSWANA"
          ).length,
          vendaGoodStanding: totalInMuniGoodStanding.filter(
            (doc) => doc.data().language === "VENDA"
          ).length,
          vendaTotal: totalInMuni.filter(
            (doc) => doc.data().language === "VENDA"
          ).length,
          xhosaGoodStanding: totalInMuniGoodStanding.filter(
            (doc) => doc.data().language === "XHOSA"
          ).length,
          xhosaTotal: totalInMuni.filter(
            (doc) => doc.data().language === "XHOSA"
          ).length,
          afrikaansGoodStanding: totalInMuniGoodStanding.filter(
            (doc) => doc.data().language === "AFRIKAANS"
          ).length,
          afrikaansTotal: totalInMuni.filter(
            (doc) => doc.data().language === "AFRIKAANS"
          ).length,
          englishGoodStanding: totalInMuniGoodStanding.filter(
            (doc) => doc.data().language === "ENGLISH"
          ).length,
          englishTotal: totalInMuni.filter(
            (doc) => doc.data().language === "ENGLISH"
          ).length,
          notSpecifiedGoodStanding: totalInMuniGoodStanding.filter(
            (doc) => !LANGUAGES.includes(doc.data().language)
          ).length,
          notSpecifiedTotal: totalInMuni.filter(
            (doc) => !LANGUAGES.includes(doc.data().language)
          ).length,
          totalGoodStanding: totalInMuniGoodStanding.length,
          total: totalInMuni.length,
        };
        municpalitiesArray = [...municpalitiesArray, muniDistObject];
        setMunicipalities(municpalitiesArray);
      });
      setIsLoading(false);
    });
  };

  const wardDistributions = (municipality) => {
    setIsLoading(true);
    const wardInfo = WARDS.filter(
      (item) => item.MunicipalityId === municipality.id
    );

    let wardArray = [];
    wardInfo.forEach((ward) => {
      const totalInWard = members.filter(
        (doc) =>
          doc.data().ward === ward.WardName && doc.data().invalid === false
      );

      const totalInWardGoodStanding = totalInWard.filter(
        (doc) =>
          moment(doc.data().lastPayment).format() >
          moment().subtract(2, "years").format()
      );

      const wardDistObject = {
        id: ward.WardName,
        municipalityId: ward.MunicipalityId,
        ward: ward.WardName,
        wardId: ward.WardId,
        zuluGoodStanding: totalInWardGoodStanding.filter(
          (doc) => doc.data().language === "ZULU"
        ).length,
        zuluTotal: totalInWard.filter((doc) => doc.data().language === "ZULU")
          .length,
        ndebeleGoodStanding: totalInWardGoodStanding.filter(
          (doc) => doc.data().language === "NDEBELE"
        ).length,
        ndebeleTotal: totalInWard.filter(
          (doc) => doc.data().language === "NDEBELE"
        ).length,
        northernSothoGoodStanding: totalInWardGoodStanding.filter(
          (doc) => doc.data().language === "NORTHERN SOTHO"
        ).length,
        northernSothoTotal: totalInWard.filter(
          (doc) => doc.data().language === "NORTHERN SOTHO"
        ).length,
        sothoGoodStanding: totalInWardGoodStanding.filter(
          (doc) => doc.data().language === "SOTHO"
        ).length,
        sothoTotal: totalInWard.filter((doc) => doc.data().language === "SOTHO")
          .length,
        swaziGoodStanding: totalInWardGoodStanding.filter(
          (doc) => doc.data().language === "SWAZI"
        ).length,
        swaziTotal: totalInWard.filter((doc) => doc.data().language === "SWAZI")
          .length,
        tsongaGoodStanding: totalInWardGoodStanding.filter(
          (doc) => doc.data().language === "TSONGA"
        ).length,
        tsongaTotal: totalInWard.filter(
          (doc) => doc.data().language === "TSONGA"
        ).length,
        tswanaGoodStanding: totalInWardGoodStanding.filter(
          (doc) => doc.data().language === "TSWANA"
        ).length,
        tswanaTotal: totalInWard.filter(
          (doc) => doc.data().language === "TSWANA"
        ).length,
        vendaGoodStanding: totalInWardGoodStanding.filter(
          (doc) => doc.data().language === "VENDA"
        ).length,
        vendaTotal: totalInWard.filter((doc) => doc.data().language === "VENDA")
          .length,
        xhosaGoodStanding: totalInWardGoodStanding.filter(
          (doc) => doc.data().language === "XHOSA"
        ).length,
        xhosaTotal: totalInWard.filter((doc) => doc.data().language === "XHOSA")
          .length,
        afrikaansGoodStanding: totalInWardGoodStanding.filter(
          (doc) => doc.data().language === "AFRIKAANS"
        ).length,
        afrikaansTotal: totalInWard.filter(
          (doc) => doc.data().language === "AFRIKAANS"
        ).length,
        englishGoodStanding: totalInWardGoodStanding.filter(
          (doc) => doc.data().language === "ENGLISH"
        ).length,
        englishTotal: totalInWard.filter(
          (doc) => doc.data().language === "ENGLISH"
        ).length,
        notSpecifiedGoodStanding: totalInWardGoodStanding.filter(
          (doc) => !LANGUAGES.includes(doc.data().language)
        ).length,
        notSpecifiedTotal: totalInWard.filter(
          (doc) => !LANGUAGES.includes(doc.data().language)
        ).length,
        totalGoodStanding: totalInWardGoodStanding.length,
        goodStanding: totalInWardGoodStanding.length,
        total: totalInWard.length,
      };
      wardArray = [...wardArray, wardDistObject];

      setWards(wardArray);
      setIsLoading(false);
    });
  };
  const { ...rest } = props;

  useEffect(() => {
    let provinceArray = [];
    if (regionsData) {
      PROVINCES.forEach((province) => {
        let provinceZuluTotal = 0;
        let provinceZuluTotalGs = 0;
        let provinceNdebeleTotal = 0;
        let provinceNdebeleTotalGs = 0;
        let provinceNorthernSothoTotal = 0;
        let provinceNorthernSothoTotalGs = 0;
        let provinceSothoTotal = 0;
        let provinceSothoTotalGs = 0;
        let provinceSwaziTotal = 0;
        let provinceSwaziTotalGs = 0;
        let provinceTsongaTotal = 0;
        let provinceTsongaTotalGs = 0;
        let provinceTswanaTotal = 0;
        let provinceTswanaTotalGs = 0;
        let provinceVendaTotal = 0;
        let provinceVendaTotalGs = 0;
        let provinceXhosaTotal = 0;
        let provinceXhosaTotalGs = 0;
        let provinceAfrikaansTotal = 0;
        let provinceAfrikaansTotalGs = 0;
        let provinceEnglishTotal = 0;
        let provinceEnglishTotalGs = 0;
        let provinceUnspecifiedTotal = 0;
        let provinceUnspecifiedTotalGs = 0;
        let total = 0;
        let totalGs = 0;
        regionsData
          .filter((item) => item.provinceId === province.ProvinceId)
          .forEach((region) => {
            provinceZuluTotal =
              provinceZuluTotal + region.languageDemographics.ZULU.total;
            provinceZuluTotalGs =
              provinceZuluTotalGs +
              region.languageDemographics.ZULU.goodStanding;

            provinceNdebeleTotal =
              provinceNdebeleTotal + region.languageDemographics.NDEBELE.total;
            provinceNdebeleTotalGs =
              provinceNdebeleTotalGs +
              region.languageDemographics.NDEBELE.goodStanding;
            provinceNorthernSothoTotal =
              provinceNorthernSothoTotal +
              region.languageDemographics.NORTHERNSOTHO.total;
            provinceNorthernSothoTotalGs =
              provinceNorthernSothoTotalGs +
              region.languageDemographics.NORTHERNSOTHO.goodStanding;
            provinceSothoTotal =
              provinceSothoTotal + region.languageDemographics.SOTHO.total;
            provinceSothoTotalGs =
              provinceSothoTotalGs +
              region.languageDemographics.SOTHO.goodStanding;
            provinceSwaziTotal =
              provinceSwaziTotal + region.languageDemographics.SWAZI.total;
            provinceSwaziTotalGs =
              provinceSwaziTotalGs +
              region.languageDemographics.SWAZI.goodStanding;
            provinceTsongaTotal =
              provinceTsongaTotal + region.languageDemographics.TSONGA.total;
            provinceTsongaTotalGs =
              provinceTsongaTotalGs +
              region.languageDemographics.TSONGA.goodStanding;
            provinceTswanaTotal =
              provinceTswanaTotal + region.languageDemographics.TSWANA.total;
            provinceTswanaTotalGs =
              provinceTswanaTotalGs +
              region.languageDemographics.TSWANA.goodStanding;
            provinceVendaTotal =
              provinceVendaTotal + region.languageDemographics.VENDA.total;
            provinceVendaTotalGs =
              provinceVendaTotalGs +
              region.languageDemographics.VENDA.goodStanding;
            provinceXhosaTotal =
              provinceXhosaTotal + region.languageDemographics.XHOSA.total;
            provinceXhosaTotalGs =
              provinceXhosaTotalGs +
              region.languageDemographics.XHOSA.goodStanding;
            provinceAfrikaansTotal =
              provinceAfrikaansTotal +
              region.languageDemographics.AFRIKAANS.total;
            provinceAfrikaansTotalGs =
              provinceAfrikaansTotalGs +
              region.languageDemographics.AFRIKAANS.goodStanding;
            provinceEnglishTotal =
              provinceEnglishTotal + region.languageDemographics.ENGLISH.total;
            provinceEnglishTotalGs =
              provinceEnglishTotalGs +
              region.languageDemographics.ENGLISH.goodStanding;
            provinceUnspecifiedTotal =
              provinceUnspecifiedTotal +
              region.languageDemographics.NOTSPECIFIED.total;
            provinceUnspecifiedTotalGs =
              provinceUnspecifiedTotalGs +
              region.languageDemographics.NOTSPECIFIED.goodStanding;

            total = total + region.total;
            totalGs = totalGs + region.goodStanding;
          });
        let prvObject = {
          province: province.Name,
          id: province.ProvinceId,
          zuluGoodStanding: provinceZuluTotalGs,
          zuluTotal: provinceZuluTotal,
          ndebeleGoodStanding: provinceNdebeleTotalGs,
          ndebeleTotal: provinceNdebeleTotal,
          northernSothoGoodStanding: provinceNorthernSothoTotalGs,
          northernSothoTotal: provinceNorthernSothoTotal,
          sothoGoodStanding: provinceSothoTotalGs,
          sothoTotal: provinceSothoTotal,
          swaziGoodStanding: provinceSwaziTotalGs,
          swaziTotal: provinceSwaziTotal,
          tsongaGoodStanding: provinceTsongaTotalGs,
          tsongaTotal: provinceTsongaTotal,
          tswanaGoodStanding: provinceTswanaTotalGs,
          tswanaTotal: provinceTswanaTotal,
          vendaGoodStanding: provinceVendaTotalGs,
          vendaTotal: provinceVendaTotal,
          xhosaGoodStanding: provinceXhosaTotalGs,
          xhosaTotal: provinceXhosaTotal,
          afrikaansGoodStanding: provinceAfrikaansTotalGs,
          afrikaansTotal: provinceAfrikaansTotal,
          englishGoodStanding: provinceEnglishTotalGs,
          englishTotal: provinceEnglishTotal,
          notSpecifiedGoodStanding: provinceUnspecifiedTotalGs,
          notSpecifiedTotal: provinceUnspecifiedTotal,
          totalGoodStanding: totalGs,
          total: total,
        };
        provinceArray = [...provinceArray, prvObject];
      });
      setData(provinceArray);
    }
  }, [regionsData]);

  if (auth.currentUser && auth.currentUser === null) return <Navigate to="/" />;

  if (auth.currentUser && !ADMINS.includes(auth.currentUser.email)) {
    return (
      <div
        style={{
          backgroundImage: "url(" + StopImage + ")",
          // backgroundSize: "repeat",
          backgroundPosition: "right 55% bottom 75%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Header
          absolute
          color="dark"
          brand="Membership Administration"
          rightLinks={<HeaderLinks />}
          {...rest}
        />

        <GridContainer
          style={{
            height: 600,
            width: "90%",
            marginTop: 80,
            marginRight: 40,
            marginLeft: 40,
            display: "flex",
            flexDirection: "column",
            marginBottom: 30,
          }}
        >
          <GridItem xs={12} sm={12} md={4}>
            <div>
              <h1 style={{ fontWeight: "bolder" }}>
                YOU DO NOT HAVE PERMISSION TO VIEW THE DISTRIBUTIONS
              </h1>
            </div>
          </GridItem>
        </GridContainer>
        <Footer />
      </div>
    );
  }
  return (
    <div>
      <Header
        absolute
        color="dark"
        brand="Membership Administration"
        rightLinks={<HeaderLinks />}
        {...rest}
      />

      <GridContainer
        style={{
          height: 600,
          width: "90%",
          marginTop: 80,
          marginRight: 40,
          marginLeft: 40,
          display: "flex",
          flexDirection: "column",
          marginBottom: 30,
        }}
      >
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1 style={{ marginTop: 30 }}>Language Demographics</h1>
            <div
              style={{
                width: 250,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <p style={{ fontWeight: "bolder" }}>Total members:</p>
                <p style={{ fontWeight: "bolder", color: "#a50100" }}>
                  {total}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <p style={{ fontWeight: "bolder" }}>Total valid members:</p>
                <p style={{ fontWeight: "bolder", color: "#a50100" }}>
                  {totalGS}
                </p>
              </div>
            </div>
          </div>
          <div style={{ marginBottom: 50 }}>
            <h2 style={{ marginTop: 10 }}>Province</h2>
            <div style={{ height: 600, display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1, marginRight: 20 }}>
                <DataGrid
                  rows={data}
                  columns={languageColumns}
                  pageSize={30}
                  rowsPerPageOptions={[5]}
                  //   checkboxSelection
                  disableSelectionOnClick
                  onRowClick={(data) => {
                    setProvinceTitle(data.row.province);
                    regionDistributions(data.row.province);
                  }}
                />
              </div>
            </div>
          </div>

          {regions && (
            <div style={{ marginBottom: 50 }}>
              <h2 style={{ marginTop: 10 }}>Regions in {provinceTitle}</h2>
              <div
                style={{ height: 600, display: "flex", flexDirection: "row" }}
              >
                <div style={{ flex: 1, marginRight: 20 }}>
                  <DataGrid
                    rows={regions}
                    columns={languageRegionColumns}
                    pageSize={30}
                    rowsPerPageOptions={[5]}
                    //   checkboxSelection
                    disableSelectionOnClick
                    onRowClick={(data) => {
                      setRegionTitle(data.row.region);
                      municipalityDistributions(data.row);
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {municipalities && (
            <div style={{ marginBottom: 50 }}>
              <h2 style={{ marginTop: 10 }}>Municipalities in {regionTitle}</h2>
              <div
                style={{ height: 600, display: "flex", flexDirection: "row" }}
              >
                <div style={{ flex: 1, marginRight: 20 }}>
                  <DataGrid
                    rows={municipalities}
                    columns={languageMunicipalityColumns}
                    pageSize={30}
                    rowsPerPageOptions={[5]}
                    //   checkboxSelection
                    disableSelectionOnClick
                    onRowClick={(data) => {
                      setMunicipalityTitle(data.row.municipality);
                      wardDistributions(data.row);
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {wards && (
            <div style={{ marginBottom: 50 }}>
              <h2 style={{ marginTop: 10 }}>Wards in {municipalityTitle}</h2>
              <div
                style={{ height: 600, display: "flex", flexDirection: "row" }}
              >
                <div style={{ flex: 1, marginRight: 20 }}>
                  <DataGrid
                    rows={wards}
                    columns={languageWardColumns}
                    pageSize={100}
                    rowsPerPageOptions={[5]}
                    //   checkboxSelection
                    disableSelectionOnClick
                    onRowClick={(data) => {
                      // municipalityDistributions(data.row);
                    }}
                  />
                </div>
              </div>
              <Button
                style={{
                  color: "black",
                  backgroundColor: "#a50100",
                  marginTop: 50,
                }}
                onClick={() => {
                  document.getElementById("wardDistributions").click();
                }}
              >
                <h4 style={{ margin: 0, color: "white" }}>
                  Download Ward Distributions
                </h4>
              </Button>
              <div style={{ display: "none" }}>
                <CsvDownload
                  id="wardDistributions"
                  filename={`Ward-Distributions-${municipalityTitle}.csv`}
                  data={wards.map((item) => ({
                    Ward: item.ward,
                    "Valid Members": item.goodStanding,
                    "Total Members": item.total,
                  }))}
                />
              </div>
            </div>
          )}
        </div>
      </GridContainer>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            zIndex: 7,
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            // width: "100vh",
            // height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {/* <div style={{ marginTop: 50 }}>
        <Footer />
      </div> */}
    </div>
  );
}
