const PROVINCES = [
  {
    ProvinceId: "1",
    Name: "Gauteng",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    ProvinceId: "2",
    Name: "Free State",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    ProvinceId: "3",
    Name: "Limpopo",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    ProvinceId: "4",
    Name: "North West",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    ProvinceId: "5",
    Name: "KwaZulu-Natal",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    ProvinceId: "6",
    Name: "Eastern Cape",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    ProvinceId: "7",
    Name: "Mpumalanga",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    ProvinceId: "8",
    Name: "Northern Cape",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
  {
    ProvinceId: "9",
    Name: "Western Cape",
    Audit_AddedBy: "admin",
    Audit_AddedOn: "2019-11-28 02:45:38",
    Audit_ModifiedBy: null,
    Audit_ModifiedOn: "2019-11-28 02:45:38",
  },
];

const convertToMap = (array) => {
  const mapInit = {};
  if (Object.keys(mapInit).length === 0) {
    array.forEach((item) => {
      mapInit[item.ProvinceId] = item.Name;
    });
  }
  return mapInit;
};

const convertToID = (array) => {
  const mapInit = {};
  if (Object.keys(mapInit).length === 0) {
    array.forEach((item) => {
      mapInit[item.Name.toLocaleLowerCase().trim()] = item.ProvinceId;
    });
  }
  return mapInit;
};

export const getProvinces = () => {
  const provinces = PROVINCES;
  const provinceMap = convertToMap(PROVINCES);
  const provinceIdMap = convertToID(PROVINCES);

  return { provinces, provinceMap, provinceIdMap };
};

export default PROVINCES;
