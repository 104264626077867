/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link, NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "../CustomDropdown/CustomDropdown.js";
import Button from "../CustomButtons/Button.js";
import styles from "../../assets/jss/material-kit-react/components/headerLinksStyle.js";
import GridItem from "../Grid/GridItem.js";
// import * as authActions from "../../store/actions/authActions";

const useStyles = makeStyles(styles);

const LinkItem = ({ name, link }) => {
  const classes = useStyles();
  return (
    <ListItem
      className={classes.listItem}
      style={{
        marginRight: 10,
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "100%",
        backgroundColor: "#a50000",
        marginTop: 15,
      }}
    >
      <NavLink
        style={{
          color: "#BABABA",
          textDecorationLine: "none",
          marginRight: 10,
          width: "100%",
        }}
        to={link}
      >
        <Button
          color="transparent"
          target="_blank"
          className={classes.navLink}
          style={{
            fontWeight: "bold",
            fontSize: 14,
            width: "100%",
          }}
        >
          {name}
        </Button>
      </NavLink>
    </ListItem>
  );
};

export default function ReportLinks(props) {
  const classes = useStyles();
  // const dispacth = useDispatch();
  return (
    <GridItem style={{ width: "100%" }} xs={12} sm={12} md={4}>
      <LinkItem name="Member Details" link="/member-details" />
      <LinkItem name="Distributions" link="/distributions" />
      <LinkItem name="Age Demographics" link="/age-demographics" />
      <LinkItem name="Race Demographics" link="/race-demographics" />
      <LinkItem name="Gender Demographics" link="/gender-demographics" />
      <LinkItem name="Language Demographics" link="/language-demographics" />
      {/* <LinkItem name="Unwanted Stats" link="/unwanted-stats" /> */}
    </GridItem>
  );
}
