import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  page: {
    fontSize: 11,
    flexDirection: "column",
    margin: 10,
  },
  row: {
    flexDirection: "row",
    width: "100%",
    height: 20,
  },
  column: {
    width: "12%",
    borderWidth: 1,
    backgroundColor: "#e6e6e6",
    padding: 2,
    fontSize: 10,
    borderLeftWidth: 1,
    borderRight: 0,
    textAlign: "left",
    fontWeight: "extrabold",
  },
  columnLast: {
    width: "14%",
    borderWidth: 1,
    backgroundColor: "#e6e6e6",
    padding: 2,
    fontSize: 10,
    borderLeftWidth: 1,
    textAlign: "left",
  },
  dataColumn: {
    width: "12%",
    borderWidth: 1,
    padding: 2,
    fontSize: 10,
    borderLeftWidth: 1,
    borderRight: 0,
    textAlign: "left",
    height: "100%",
    overflowWrap: "break-word",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  dataColumnLast: {
    width: "14%",
    borderWidth: 1,
    padding: 2,
    fontSize: 10,
    borderLeftWidth: 1,
    textAlign: "left",
    height: "100%",
  },
  dataRow: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 40,
  },
  description: {
    width: "60%",
  },
  xyz: {
    width: "40%",
  },
  heading: {
    fontSize: 25,
    textAlign: "left",
    fontWeight: "ultrabold",
    // fontFamily: "s",
  },
  headerView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
    marginBottom: 5,
    marginTop: 40,
  },
  image: {
    width: "90px",
    marginLeft: 50,
  },
  infoText: {
    marginBottom: 2,
    fontSize: 10,
  },
  infoView: {
    display: "flex",
    width: "90%",
    flexDirection: "row",
    marginBottom: 15,
  },
});
