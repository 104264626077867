import moment from "moment";
import { Button } from "@material-ui/core";
import CSVReader from "react-csv-reader";
import CsvDownload from "react-json-to-csv";
import React, { useEffect, useState } from "react";

import Header from "../../components/Header/Header.js";
import GridItem from "../../components/Grid/GridItem.js";
import { createMember, getIecData } from "../../api/firestoreContext.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CreateMemberForm from "../../components/Forms/CreateMember.js";
import { renewIecToken } from "../../api/checks.js";
import { RenewMembersForm } from "../../components/Forms/RenewMembersForm.js";
import { useSelector } from "react-redux";
// import readXlsxFile from "read-excel-file/web-worker";
import { Navigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer.js";
import { getProvinces } from "../../assets/data/Provinces.js";
import { EditMembersForm } from "../../components/Forms/EditMembersForm.js";
import ADMINS from "../../assets/data/Admins.js";
import { CheckMembersForm } from "../../components/Forms/CheckMembersForm.js";
import { getAuth } from "firebase/auth";

export default function CreateMemberPage(props) {
  const [report, setReport] = useState([]);
  const [csvFile, setCsvFile] = useState([]);
  const [excelFile, setExcelFile] = useState([]);
  const [addMembers, setAddMembers] = useState(false);
  const [uploadingState, setUploadingState] = useState("");
  const [btnText, setBtnText] = useState("Add a CSV file");
  const [excelBtnText, setExcelBtnText] = useState("Add an excel file");
  const { ...rest } = props;
  const auth = getAuth();

  const currentDate = moment().format("MMMM Do YYYY, h:mm:ss a");
  useEffect(() => {
    if (csvFile.length > 0 && report.length === csvFile.length - 1) {
      setUploadingState("Done");
    }
  }, [csvFile, report]);
  // renewIecToken();
  const createMembers = async () => {
    renewIecToken();
    const iecData = await getIecData();
    for (let i = 1; i < csvFile.length; i++) {
      if (i % 100 === 0) {
        setTimeout(function () {}, 1000);
      }

      setUploadingState(`Adding ... ${csvFile[i][7]}`);
      const data = {
        province: csvFile[i][0],
        region: csvFile[i][1],
        municipality: csvFile[i][2],
        votingStation: csvFile[i][3],
        ward: csvFile[i][4],
        name: csvFile[i][5],
        surname: csvFile[i][6],
        idNumber: csvFile[i][7],
        age: csvFile[i][8],
        gender: csvFile[i][9],
        race: csvFile[i][10],
        citizenship: csvFile[i][11],
        language: csvFile[i][12],
        resAddress: csvFile[i][13],
        cellNumber: csvFile[i][14],
        landlineNumber: csvFile[i][15],
        email: csvFile[i][16],
        occupation: csvFile[i][17],
        qualification: csvFile[i][18],
        dateJoined: csvFile[i][19],
        lastPayment: csvFile[i][20],
        subscription: csvFile[i][21],
        membershipAmount: csvFile[i][22],
      };
      createMember({ ...data, token: iecData.token })
        .then((result) => {
          const creationData = {
            ...data,
            status: result.reason,
            province: result.province ? result.province : data.province,
            region: result.region ? result.region : data.region,
            municipality: result.municipality
              ? result.municipality
              : data.municipality,
          };
          setReport((report) => [...report, creationData]);
        })
        .catch((error) => {
          // console.log("THERE HAS BEEN AN ERROR: ", error);
          const creationData = {
            ...data,
            status: "ERROR",
          };
          setReport((report) => [...report, creationData]);
        });
    }
    setBtnText("ADD MEMBERS");
  };

  const authState = useSelector((state) => state);

  if (auth.currentUser && auth.currentUser === null) return <Navigate to="/" />;

  return (
    <div>
      <Header
        absolute
        color="dark"
        brand="Membership Administration"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <GridContainer style={{ marginBottom: 50 }} justifyContent="center">
        <GridItem xs={12} sm={12} md={4}>
          <CreateMemberForm />
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <div style={{ marginTop: 120, marginRight: 20, marginLeft: 20 }}>
            <h1>Create Members</h1>
            <CSVReader
              onFileLoaded={(data) => {
                setCsvFile(data);
                setBtnText("Add Members");
                setAddMembers(true);
              }}
            />
            <p style={{ color: "grey" }}>{uploadingState}</p>
            <Button
              style={{
                color: "black",
                backgroundColor: !addMembers ? "grey" : "#a50100",
                marginTop: 20,
              }}
              onClick={() => {
                setBtnText("Adding members...");
                setReport([]);
                createMembers();
              }}
              fullWidth
              disabled={!addMembers}
            >
              <h4 style={{ margin: 0, color: "white" }}>{btnText}</h4>
            </Button>
            <div>
              <p style={{ fontStyle: "italic" }}>
                Kindly make sure it is a csv file and it is in this format:{" "}
                <a href="https://firebasestorage.googleapis.com/v0/b/eff-menbers.appspot.com/o/DR%20KK%20MEMBERSHIP%20Matlosana%20Data%202021.11.09%202.csv?alt=media&token=6493b660-e030-42e7-8fac-017880a06745">
                  Example CSV File
                </a>
              </p>
            </div>

            {/* <CSVReader
              onFileLoaded={(data) => {
                setCsvFile(data);
                setBtnText("Add Members");
                setAddMembers(true);
              }}
            /> */}
            <input
              onChange={(value) => {
                setExcelFile(value.target.files);
                // readXlsxFile(value.target.files).then((rows) => {
                //   console.log(rows);
                // });
              }}
              type="file"
              id="excelUpload"
              accept=".xlsx, .xls"
            />
            <p style={{ color: "grey" }}></p>
            <Button
              style={{
                color: "black",
                backgroundColor: true ? "grey" : "#a50100",
                marginTop: 20,
              }}
              onClick={() => {
                setExcelBtnText("Adding members...");
                setReport([]);
                // createMembersExcel();
                // const row = readXlsxFile(excelFile).then((rows) => {
                //   return rows;
                // });
              }}
              fullWidth
              disabled
              // disabled={excelFile.length < 0}
            >
              <h4 style={{ margin: 0, color: "white" }}>{excelBtnText}</h4>
            </Button>
            <div>
              <p style={{ fontStyle: "italic" }}>
                Kindly make sure it is a csv file and it is in this format:{" "}
                <a>Example Excel File</a>
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              marginRight: 20,
              marginLeft: 20,
            }}
          >
            {report && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p>Successful</p>
                <p>
                  {report.filter((item) => item.status === "Successful").length}
                </p>
              </div>
            )}
            {report && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p>Invalid</p>
                <p>
                  {
                    report.filter(
                      (item) =>
                        item.status.substr(0, 6) === "The id" ||
                        item.status.substr(0, 6) === "The wa"
                    ).length
                  }
                </p>
              </div>
            )}
            {report && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p>Errors</p>
                <p>
                  {
                    report.filter(
                      (item) => item.status.substr(0, 5) === "ERROR"
                    ).length
                  }
                </p>
              </div>
            )}
            {report && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p>Done</p>
                <p> {report.length}</p>
              </div>
            )}
            {report && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p>Total</p>
                <p>{csvFile.length === 0 ? 0 : csvFile.length - 1}</p>
              </div>
            )}
          </div>
          <div style={{ marginRight: 20, marginLeft: 20 }}>
            <Button
              style={{
                color: "black",
                backgroundColor: report.length === 0 ? "grey" : "#a50100",
              }}
              size="large"
              disabled={report.length === 0}
              onClick={() => {
                document.getElementById("csv").click();
              }}
              fullWidth
            >
              <h4 style={{ margin: 0, color: "white" }}>Download report</h4>
            </Button>
            <div style={{ display: "none" }}>
              <CsvDownload
                id="csv"
                filename={`Member-Creation-Feedback-${currentDate}.csv`}
                data={report}
              />
            </div>
          </div>
          <RenewMembersForm />
          {ADMINS.includes(authState.auth.user) && <EditMembersForm />}
          {ADMINS.includes(authState.auth.user) && <CheckMembersForm />}
        </GridItem>
      </GridContainer>
      <Footer />
    </div>
  );
}
