import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import WARDS from "../../assets/data/Wards.js";
import REGIONS from "../../assets/data/Regions.js";
import PROVINCES from "../../assets/data/Provinces.js";
import {
  createMember,
  deleteMember,
  editMember,
} from "../../api/firestoreContext.js";
import MUNICIPALITIES from "../../assets/data/Municipalities.js";
import VOTINGSTATIONS from "../../assets/data/VotingStations.js";
import { registeredVoterCheck, renewIecToken } from "../../api/checks.js";
import { Alert } from "@mui/material";

export default function EditeMemberForm({ data }) {
  const [id, setId] = useState(data.id);
  const [age, setAge] = useState(data.age);
  const [name, setName] = useState(data.name);
  const [race, setRace] = useState(data.race);
  const [ward, setWard] = useState(data.ward);
  const [email, setEmail] = useState(data.email);
  const [gender, setGender] = useState(data.gender);
  const [region, setRegion] = useState(data.region);
  const [surname, setSurname] = useState(data.surname);
  const [province, setProvince] = useState(data.province);
  const [idNumber, setIdNumber] = useState(data.idNumber);
  const [language, setLanguage] = useState(data.language);
  const [resAddress, setResAddress] = useState(data.residentialAddress);
  const [cellNumber, setCellNumber] = useState(data.cellNumber);
  const [occupation, setOccupation] = useState(data.occupation);
  const [dateJoined, setDateJoined] = useState(data.dateJoined);
  const [wardOptions, setWardOptions] = useState();
  const [citizenship, setCitizenship] = useState(data.citizenship);
  const [lastPayment, setLastPayment] = useState(data.lastPayment);
  const [subscription, setSubscription] = useState(data.subscription);
  const [municipality, setMunicipality] = useState(data.municipality);
  const [regionOptions, setRegionOptions] = useState();
  const [votingStation, setVotingStation] = useState(data.votingStation);
  const [qualification, setQualification] = useState(data.qualification);
  const [landlineNumber, setLandlineNumber] = useState(data.landlineNumber);
  const [membershipAmount, setMembershipAmount] = useState(
    data.membershipAmount
  );
  const [municipalityOptions, setMunicipalityOptions] = useState();
  const [votingStationOptions, setVotingStationOptions] = useState();

  const [buttonStatus, setButtonStatus] = useState("Edit Member");
  const [status, setStatus] = useState("");
  const [deleteStatus, setDeleteStatus] = useState();

  const submitForm = () => {
    renewIecToken();
    const data = {
      id,
      province,
      region,
      municipality,
      ward,
      votingStation,
      name,
      surname,
      idNumber,
      age,
      gender,
      citizenship: "South Africa",
      race,
      language,
      resAddress,
      cellNumber,
      landlineNumber,
      email,
      occupation,
      qualification,
      dateJoined,
      lastPayment,
      subscription,
      membershipAmount,
    };
    editMember(data)
      .then((result) => {
        if (result.reason !== "Successful") {
          setStatus(`Update rejected: ${result.reason}`);
        } else {
          setStatus("Successful");
          setButtonStatus("Done");
          alert(`Member with ID number: ${idNumber} has been updated`);
        }
      })
      .catch((error) => {
        console.log("Error", error);
        setStatus("Error");
        setButtonStatus("Retry");
      });
  };

  useEffect(() => {
    if (
      province &&
      region &&
      municipality &&
      ward &&
      votingStation &&
      name &&
      surname &&
      idNumber &&
      age &&
      gender &&
      race &&
      language &&
      resAddress &&
      cellNumber &&
      landlineNumber &&
      email &&
      occupation &&
      qualification &&
      dateJoined &&
      lastPayment &&
      subscription &&
      membershipAmount
    ) {
      setButtonStatus("Submit");
    }
  }, [
    province,
    region,
    municipality,
    ward,
    votingStation,
    name,
    surname,
    idNumber,
    age,
    gender,
    race,
    language,
    resAddress,
    cellNumber,
    landlineNumber,
    email,
    occupation,
    qualification,
    dateJoined,
    lastPayment,
    subscription,
    membershipAmount,
  ]);

  useEffect(() => {
    const selectedProvince = PROVINCES.find((prov) => prov.Name === province);
    if (selectedProvince) {
      setRegionOptions(
        REGIONS.filter(
          (region) => region.ProvinceId === selectedProvince.ProvinceId
        )
      );
    }
  }, [province]);

  useEffect(() => {
    const selectedRegion = REGIONS.find((prov) => prov.Name === region);
    if (selectedRegion) {
      setMunicipalityOptions(
        MUNICIPALITIES.filter(
          (region) => region.Region_RegionId === selectedRegion.RegionId
        )
      );
    }
  }, [region]);

  useEffect(() => {
    const selectedMunicipality = MUNICIPALITIES.find(
      (prov) => prov.Name === municipality
    );
    if (selectedMunicipality) {
      setWardOptions(
        WARDS.filter(
          (region) =>
            region.MunicipalityId === selectedMunicipality.MunicipalityId
        )
      );
    }
  }, [municipality]);

  useEffect(() => {
    const selectedWard = WARDS.find((prov) => prov.WardName === ward);
    if (selectedWard) {
      setVotingStationOptions(
        VOTINGSTATIONS.filter(
          (region) => region.WardLookupId === selectedWard.WardId
        )
      );
    }
  }, [ward]);

  return (
    <div
      style={{
        marginRight: 10,
        marginLeft: 10,
        // scrollBehavior: "auto",
        height: 600,
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <h1>Edit Member</h1>
          <div style={{ overflowY: "scroll", height: 380 }}>
            <FormControl style={{ marginBottom: 10 }} fullWidth>
              <InputLabel id="demo-simple-select-label">Province</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={province}
                label="province"
                onChange={(value) => setProvince(value.target.value)}
              >
                <MenuItem value="Eastern Cape">Eastern Cape</MenuItem>
                <MenuItem value={"Free State"}>Free State</MenuItem>
                <MenuItem value={"Gauteng"}>Gauteng</MenuItem>
                <MenuItem value={"KwaZulu-Natal"}>KwaZulu-Natal</MenuItem>
                <MenuItem value={"Limpopo"}>Limpopo</MenuItem>
                <MenuItem value={"Mpumalanga"}>Mpumalanga</MenuItem>
                <MenuItem value={"North West"}>North West</MenuItem>
                <MenuItem value={"Northern Cape"}>Northern Cape</MenuItem>
                <MenuItem value={"Western Cape"}>Western Cape</MenuItem>
              </Select>
            </FormControl>

            <FormControl style={{ marginBottom: 10 }} fullWidth>
              <InputLabel id="demo-simple-select-label">Region</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={region}
                defaultValue={""}
                label="region"
                onChange={(value) => setRegion(value.target.value.Name)}
              >
                {regionOptions &&
                  regionOptions.map((item) => {
                    return <MenuItem value={item}>{item.Name}</MenuItem>;
                  })}
              </Select>
            </FormControl>

            <FormControl style={{ marginBottom: 10 }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Municipality
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="municipality"
                value={municipality}
                defaultValue={""}
                label="municipality"
                onChange={(value) => setMunicipality(value.target.value.Name)}
              >
                {municipalityOptions &&
                  municipalityOptions.map((item) => {
                    return <MenuItem value={item}>{item.Name}</MenuItem>;
                  })}
              </Select>
            </FormControl>

            <FormControl style={{ marginBottom: 10 }} fullWidth>
              <InputLabel id="demo-simple-select-label">Ward</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="ward"
                value={ward}
                defaultValue={""}
                label="ward"
                onChange={(value) => setWard(value.target.value.WardName)}
              >
                {wardOptions &&
                  wardOptions.map((item) => {
                    return <MenuItem value={item}>{item.WardName}</MenuItem>;
                  })}
              </Select>
            </FormControl>

            <FormControl style={{ marginBottom: 10 }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Voting Station
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="votingStation"
                value={votingStation}
                label="Voting Station"
                defaultValue={""}
                onChange={(value) =>
                  setVotingStation(value.target.value.VotingStationName)
                }
              >
                {votingStationOptions &&
                  votingStationOptions.map((item) => {
                    return (
                      <MenuItem value={item}>{item.VotingStationName}</MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <TextField
              id="name"
              value={name}
              onChange={(value) => setName(value.target.value)}
              fullWidth
              label="Name"
              variant="filled"
              style={{ marginBottom: 10 }}
            />
            <TextField
              id="surname"
              value={surname}
              onChange={(value) => setSurname(value.target.value)}
              fullWidth
              label="Surname"
              variant="filled"
              style={{ marginBottom: 10 }}
            />
            <TextField
              id="id"
              value={idNumber}
              onChange={(value) => setIdNumber(value.target.value)}
              fullWidth
              label="RSA Identity Number"
              variant="filled"
              style={{ marginBottom: 10 }}
            />
            <TextField
              id="age"
              value={age}
              onChange={(value) => setAge(value.target.value)}
              // fullWidth
              label="Age"
              variant="filled"
              style={{ marginBottom: 10 }}
              type="number"
            />

            <FormControl style={{ marginBottom: 10 }} fullWidth>
              <InputLabel id="demo-simple-select-label">Gender</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={gender}
                label="Gender"
                defaultValue={""}
                onChange={(value) => setGender(value.target.value)}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>

            <TextField
              id="citizenship"
              value={citizenship}
              onChange={(value) => setCitizenship(value.target.value)}
              fullWidth
              label="Citizenship"
              variant="filled"
              style={{ marginBottom: 10 }}
              disabled
            />

            <FormControl style={{ marginBottom: 10 }} fullWidth>
              <InputLabel id="demo-simple-select-label">Race</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="race"
                value={race}
                defaultValue={""}
                label="Race"
                onChange={(value) => setRace(value.target.value)}
              >
                <MenuItem value="Black">Black</MenuItem>
                <MenuItem value="Coloured">Coloured</MenuItem>
                <MenuItem value="Indian">Indian</MenuItem>
                <MenuItem value="White">White</MenuItem>
              </Select>
            </FormControl>

            <FormControl style={{ marginBottom: 10 }} fullWidth>
              <InputLabel id="demo-simple-select-label">Language</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="language"
                value={language}
                label="Language"
                defaultValue={""}
                onChange={(value) => setLanguage(value.target.value)}
              >
                <MenuItem value="Afrikaans">Afrikaans</MenuItem>
                <MenuItem value={"English"}>English</MenuItem>
                <MenuItem value={"Ndebele"}>Ndebele</MenuItem>
                <MenuItem value={"Northern Sotho"}>Northern Sotho</MenuItem>
                <MenuItem value={"Sotho"}>Sotho</MenuItem>
                <MenuItem value={"Swazi"}>Swazi</MenuItem>
                <MenuItem value={"Tsonga"}>Tsonga</MenuItem>
                <MenuItem value={"Tswana"}>Tswana</MenuItem>
                <MenuItem value={"Venda"}>Venda</MenuItem>
                <MenuItem value={"Xhosa"}>Xhosa</MenuItem>
                <MenuItem value={"Zulu"}>Zulu</MenuItem>
              </Select>
            </FormControl>

            <TextField
              id="residentialAddress"
              value={resAddress}
              onChange={(value) => setResAddress(value.target.value)}
              fullWidth
              label="Residential Address"
              variant="filled"
              style={{ marginBottom: 10 }}
            />

            <TextField
              id="cellphoneNumber"
              value={cellNumber}
              onChange={(value) => setCellNumber(value.target.value)}
              fullWidth
              label="Cellphone Number"
              variant="filled"
              style={{ marginBottom: 10 }}
            />

            <TextField
              id="landlineNumber"
              value={landlineNumber}
              onChange={(value) => setLandlineNumber(value.target.value)}
              fullWidth
              label="Landline Number"
              variant="filled"
              style={{ marginBottom: 10 }}
            />

            <TextField
              id="email"
              value={email}
              onChange={(value) => setEmail(value.target.value)}
              fullWidth
              label="Email"
              variant="filled"
              style={{ marginBottom: 10 }}
            />
            <TextField
              id="occupation"
              value={occupation}
              onChange={(value) => setOccupation(value.target.value)}
              fullWidth
              label="Occupation"
              variant="filled"
              required
              style={{ marginBottom: 10 }}
            />
            <TextField
              id="qualification"
              value={qualification}
              onChange={(value) => setQualification(value.target.value)}
              fullWidth
              label="Qualification"
              variant="filled"
              style={{ marginBottom: 10 }}
            />
            <TextField
              id="dateJoined"
              label="Date Joined"
              type="date"
              fullWidth
              variant="filled"
              InputLabelProps={{
                shrink: true,
              }}
              value={dateJoined}
              onChange={(value) => setDateJoined(value.target.value)}
              style={{ marginBottom: 10 }}
            />
            <TextField
              id="lastPaymentRecieved"
              label="Last Payment Received"
              type="date"
              fullWidth
              variant="filled"
              InputLabelProps={{
                shrink: true,
              }}
              value={lastPayment}
              onChange={(value) => setLastPayment(value.target.value)}
              style={{ marginBottom: 10 }}
            />

            <FormControl style={{ marginBottom: 10 }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Subscription
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="Subscription"
                value={subscription}
                label="Subscription"
                defaultValue={""}
                onChange={(value) => setSubscription(value.target.value)}
              >
                <MenuItem value="New Subscription">New Subscription</MenuItem>
                <MenuItem value="Renewal">Renewal</MenuItem>
              </Select>
            </FormControl>

            <FormControl style={{ marginBottom: 10 }} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Membership Amount
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="membership"
                value={membershipAmount}
                defaultValue={""}
                label="Membership Amount"
                onChange={(value) => setMembershipAmount(value.target.value)}
              >
                <MenuItem value={"10.00"}>R 10 (2 Year)</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div style={{ flex: 1, padding: 5 }}>
          <h1>Member Details</h1>
          <div style={{ overflowY: "scroll", height: 380 }}>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Province:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {province}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Region:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {region}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Municipality:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {municipality}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Ward:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {ward}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Voting Station:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {votingStation}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Name:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {name}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Surname:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {surname}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Id Number:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {idNumber}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Age:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {age}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Gender:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {gender}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Citizenship:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {citizenship}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Race:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {race}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Language:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {language}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Residential Address:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {resAddress}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Cellphone Number:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {cellNumber}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Landline Number:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {landlineNumber}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Email:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {email}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Occupation:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {occupation}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Qualification:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {qualification}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Date Joined:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {dateJoined}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Last Payment Recieved:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {lastPayment}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Subscription:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {subscription}
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ flex: 1, fontWeight: "bold", color: "#a50100" }}>
                Membership Amount:
              </p>
              <p style={{ flex: 1, fontWeight: "bold", color: "#737373" }}>
                {membershipAmount}
              </p>
            </div>
          </div>
        </div>
      </div>

      {deleteStatus ? (
        <div>
          <h3 style={{ textAlign: "center" }}>
            Are you sure you want to delete this member?
          </h3>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
            }}
          >
            <div>
              <Button
                // simple
                style={{
                  color: "black",
                  backgroundColor: "#a50100",
                  marginTop: 8,
                }}
                size="large"
                onClick={() => {
                  deleteMember(idNumber)
                    .then(() => {
                      alert(`Member with ID ${idNumber} has been deleted.`);
                    })
                    .catch((error) => {
                      console.log("Error: ", error);
                    });
                }}
                fullWidth
              >
                <h4 style={{ margin: 0, color: "white" }}>Yes</h4>
              </Button>
            </div>
            <div>
              <Button
                // simple
                style={{
                  color: "black",
                  backgroundColor: "black",
                  marginTop: 8,
                }}
                size="large"
                onClick={() => {
                  setDeleteStatus(false);
                }}
                fullWidth
              >
                <h4 style={{ margin: 0, color: "white" }}>No</h4>
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <p>{status}</p>
          <Button
            // simple
            style={{
              color: "black",
              backgroundColor: "#a50100",
              marginTop: 8,
            }}
            size="large"
            onClick={() => {
              setStatus("Adding ...");
              submitForm();
            }}
            fullWidth
          >
            <h4 style={{ margin: 0, color: "white" }}>{buttonStatus}</h4>
          </Button>
          <Button
            // simple
            style={{
              color: "black",
              backgroundColor: "#a50100",
              marginTop: 8,
            }}
            size="large"
            onClick={() => {
              setDeleteStatus(true);
            }}
            fullWidth
          >
            <h4 style={{ margin: 0, color: "white" }}>Delete Member</h4>
          </Button>
        </div>
      )}
    </div>
  );
}
