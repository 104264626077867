import firebase from "../../api/firebase";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
} from "firebase/auth";

export const signin = (credentials, account) => {
  console.log(credentials, account);
  const auth = getAuth();
  return (dispatch, getState, { getFirebase }) => {
    console.log("at firebase");

    signInWithEmailAndPassword(auth, credentials.email, credentials.password)
      .then(() => {
        if (account === "admin") {
          console.log("Admin login");
          dispatch({ type: "LOGIN_SUCCESS_ADMIN" });
        } else {
          console.log("Student login");
          dispatch({ type: "LOGIN_SUCCESS", userEmail: credentials.email });
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
        dispatch({ type: "LOGIN_ERROR", err });
        throw err;
      });
  };
};

export const signupAdmin = (email, password) => {
  // const firebase = firebase
  const auth = getAuth();
  createUserWithEmailAndPassword(auth, email, password)
    .then(() => {
      alert(
        `A new user with email ${email} has been signed up and added as an admin.`
      );
      window.location.reload();
    })
    .catch((err) => {
      console.log("err: ", err);
    });
};

// export const fecthProfiles = () => {
//   return (dispatch, getState, { getFirebase, getFirestore }) => {
//     const firestore = firebase.firestore();

//     firestore.collection("users").onSnapshot((snapshot) => {
//       const profiles = snapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));

//       dispatch({ type: "LOAD_USERS", allProfiles: profiles });
//     });
//   };
// };

export const forgotPassword = (email) => {
  return (dispatch, getState, { getFirebase }) => {
    console.log("sending email");
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert(
          "Please go to your email and follow the link to change your password."
        );
      })
      .catch((error) => {
        // Some error occurred.
        console.log(error);
      });
  };
};

export const logout = () => {
  console.log("Siging out ");
  return (dispatch) => {
    console.log("IN sign out");
    const auth = getAuth();
    signOut(auth).then(() => {
      dispatch({ type: "SIGNOUT_SUCCESS" });
    });
  };
};

export const beginSignUp = (user) => {
  return (dispatch) => {
    dispatch({ type: "START_SIGNUP", userInfo: user });
  };
};
